// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IZOeRvHhlrKEcW6dcZXx {
	display: block !important;
}

.dNU5hh6YPyNBA2h7AhU4 {
	padding: var(--spacing-2);
	width: 100%;
	aspect-ratio: 1 / 1;
	border-radius: var(--radius-6);
	overflow: hidden;
	background-color: var(--palette-gray-300);
	background-color: var(--palette-group-primary-color, var(--palette-gray-300));
}

.dNU5hh6YPyNBA2h7AhU4.DkbigEu0tDp738bIG7eN {
		-webkit-box-shadow:
			0 0 0 3px var(--palette-white),
			0 0 0 5px var(--palette-gray-300);
		        box-shadow:
			0 0 0 3px var(--palette-white),
			0 0 0 5px var(--palette-gray-300);
	}

.aXGYpnchAXQYUSBgM3Gy {
	border-radius: 50px;
}

.VmPbQwzsLOqpWgNuzYFy {
	width: 32px;
	height: 32px;
}

.zzSa4rLGDeEW0KAfw6i_ {
	font-size: var(--font-size-xs);
	color: var(--palette-gray-500);
}

.npiPeCUmu_ZvvJW0g6yX {
	color: var(--palette-gray-500);
}
`, "",{"version":3,"sources":["webpack://./components/navigation/menus/group_switcher/item/styles.css"],"names":[],"mappings":"AAAA;CACC,yBAAyB;AAC1B;;AAEA;CACC,yBAAyB;CACzB,WAAW;CACX,mBAAmB;CACnB,8BAA8B;CAC9B,gBAAgB;CAChB,yCAA6E;CAA7E,6EAA6E;AAO9E;;AALC;EACC;;oCAEkC;UAFlC;;oCAEkC;CACnC;;AAGD;CACC,mBAAmB;AACpB;;AAEA;CACC,WAAW;CACX,YAAY;AACb;;AAEA;CACC,8BAA8B;CAC9B,8BAA8B;AAC/B;;AAEA;CACC,8BAA8B;AAC/B","sourcesContent":[".itemWrapper {\n\tdisplay: block !important;\n}\n\n.badgeWrapper {\n\tpadding: var(--spacing-2);\n\twidth: 100%;\n\taspect-ratio: 1 / 1;\n\tborder-radius: var(--radius-6);\n\toverflow: hidden;\n\tbackground-color: var(--palette-group-primary-color, var(--palette-gray-300));\n\n\t&.active {\n\t\tbox-shadow:\n\t\t\t0 0 0 3px var(--palette-white),\n\t\t\t0 0 0 5px var(--palette-gray-300);\n\t}\n}\n\n.badge {\n\tborder-radius: 50px;\n}\n\n.traverseButton {\n\twidth: 32px;\n\theight: 32px;\n}\n\n.itemSubHeading {\n\tfont-size: var(--font-size-xs);\n\tcolor: var(--palette-gray-500);\n}\n\n.isPending {\n\tcolor: var(--palette-gray-500);\n}\n"],"sourceRoot":""}]);
// Exports
export var itemWrapper = `IZOeRvHhlrKEcW6dcZXx`;
export var badgeWrapper = `dNU5hh6YPyNBA2h7AhU4`;
export var active = `DkbigEu0tDp738bIG7eN`;
export var badge = `aXGYpnchAXQYUSBgM3Gy`;
export var traverseButton = `VmPbQwzsLOqpWgNuzYFy`;
export var itemSubHeading = `zzSa4rLGDeEW0KAfw6i_`;
export var isPending = `npiPeCUmu_ZvvJW0g6yX`;
export default ___CSS_LOADER_EXPORT___;
