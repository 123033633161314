import { ReactNode } from 'react';

export const symbol_holiday_village: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M80-220v-315q0-12.444 5-23.722T98-578l180-180q9-9 20-13t22-4q11 0 22 4t20 13l180 180q8 8 13 19.278 5 11.278 5 23.722v315q0 24.75-17.625 42.375T500-160H140q-24.75 0-42.375-17.625T80-220Zm60 0h150v-90q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T350-310v90h150v-315L320-715 140-535v315Zm520 30v-403L509-744q-16-16-7.636-36 8.363-20 30.945-20Q539-800 545-797.5q6 2.5 11.243 7.743L702-644q8 8 13 19.278 5 11.278 5 23.722v411q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T660-190Zm160 0v-469l-85-85q-16-16-7.636-36 8.363-20 30.945-20Q765-800 771-797.5q6 2.5 11 7.5l80 80q8 8 13 19.278 5 11.278 5 23.722v477q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T820-190Zm-680-30h360-360Zm179.825-230Q307-450 298.5-458.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Z"
		/>
	</svg>
);
