import { ReactNode } from 'react';

export const symbol_chromecast_device: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M840-390q-12.75 0-21.375-8.625T810-420v-120q0-12.75 8.625-21.375T840-570h50q12.75 0 21.375 8.625T920-540v120q0 12.75-8.625 21.375T890-390h-50Zm-130 70H440q-16 0-32.5 4.5T366-300q-39 17-67 23.5t-59 6.5q-78 0-139-46T40-420v-120q0-59 61-104.5T240-690q31 0 59 6.5t67 23.5q25 11 41.5 15.5T440-640h270q24.75 0 42.375 17.625T770-580v200q0 24.75-17.625 42.375T710-320Zm-470-10q24.062 0 47.031-5.5Q310-341 342-355q33-14 54.972-19.5T440-380h270v-200H440q-21.056 0-43.028-5.5Q375-591 342-605q-32-14-54.969-19.5T240-630q-54 0-97 27.643T100-540v45h15q6 0 10.5 4.5T130-480q0 6-4.5 10.5T115-465h-15v45q0 34.714 43 62.357T240-330ZM100-480Z"
		/>
	</svg>
);
