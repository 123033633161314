import { ReactNode } from 'react';

export const symbol_pan_tool: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M402-40q-27 0-51.5-12.5T311-88L68-446q-6-9-4-20t10-18q17-15 39.5-19t44.573 13.188L280-397v-413q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T340-810v468q0 19-16.5 27t-31.5-3l-126-92 195 288q7 11 17.5 16.5T402-100h288q38 0 64-26t26-64v-580q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T840-770v580q0 63-43.5 106.5T690-40H402Zm75.175-880q12.825 0 21.325 8.625T507-890v380q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T447-510v-380q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625Zm167 40q12.825 0 21.325 8.625T674-850v340q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T614-510v-340q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625ZM473-290Z"
		/>
	</svg>
);
