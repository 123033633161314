import { createPortal } from 'react-dom';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { t } from '@transifex/native';

import uuid from 'pkg/uuid';

import Icon from 'components/icon';

import { usePlaybackState } from 'components/video-analytics/PlaybackState';
import { toolConfig } from 'components/annotations/tools/ToolConfig';
import {
	extractPoints,
	Keyframe,
} from 'components/annotations/AnnotationHooks';

import { FloatingButton } from './SingleAnnotation';

const Preferences = styled(motion.div)`
	position: absolute;
	z-index: 1001;
`;

const Box = styled.div`
	min-width: 180px;
	max-width: 100%;
	background: rgba(31, 32, 37, 0.8);
	padding: 3px;
	border: 1px solid rgba(190, 198, 206, 0.4);
	border-radius: var(--radius-5);
	font-size: 13px;
	font-weight: 600;
	color: white;
	user-select: none;

	& > div {
		padding: var(--spacing-3) var(--spacing-2);
		padding-right: var(--spacing-4);
		border-radius: var(--radius-3);

		&:hover {
			background: rgba(255, 255, 255, 0.09);
		}

		svg {
			margin: 0 11px 0 5px;
		}
	}
`;

const Option = styled.div`
	cursor: pointer;
`;

const ButtonRow = styled.div`
	display: flex;
	gap: 2px;
	margin-bottom: 5px;

	&.right button:last-child {
		margin-left: auto;
	}
`;

// todo: set actual types
interface AnnotationPreferenceProps {
	updatePreference: any;
	updateKeyframe: any;
	setAnnotations: any;
	config: any;
	annotations: any;
	annotationId: string;
	singlepointRef: any;
	multipointRef: any;
	closePreferences: any;
	isOutsideRight: boolean;
	style: JSONObject;
	coverSize: JSONObject;
}

const _context = 'video/annotations';

export default function AnnotationPreferences({
	updateKeyframe,
	setAnnotations,
	annotations,
	annotationId,
	singlepointRef,
	multipointRef,
	closePreferences,
	isOutsideRight,
	style,
	coverSize,
}: AnnotationPreferenceProps): JSX.Element {
	const annotation = annotations[annotationId];

	const deleteAnnotation = () => {
		const newAnnotations = { ...annotations };
		delete newAnnotations[annotationId];
		setAnnotations(newAnnotations, true);
	};

	const placeOnBottom = () => {
		// bump all existing z's
		const newAnnotations = Object.entries(annotations).reduce(
			(prev: any, [id, annotation]: any) => {
				const z = annotation.z || 1;
				prev[id] = {
					...annotation,
					z: z + 1,
				};

				return prev;
			},
			{}
		);

		// place current annotation on bottom
		newAnnotations[annotationId].z = 1;

		setAnnotations(newAnnotations, true);
	};

	const placeOnTop = () => {
		// get current max z
		const maxZ: any = Object.values(annotations).reduce(
			(prev, annotation: any) => (annotation.z > prev ? annotation.z : prev),
			1
		);

		// place current annotation on top
		const newAnnotations = { ...annotations };
		newAnnotations[annotationId].z = maxZ + 1;

		setAnnotations(newAnnotations, true);
	};

	const { currentTime } = usePlaybackState();

	const duplicate = () => {
		const id = uuid();

		// first keyframe, get default values from config
		const tool = annotations[annotationId].tool;
		const { left, top, width, height, resize, move, multipoint } =
			toolConfig[tool];
		const firstKeyframe: JSONObject = {
			time: currentTime * 1000,
			left,
			top,
		};

		// add size if available in config
		if (resize) {
			firstKeyframe.width = width;
			firstKeyframe.height = height;
			const w =
				parseInt(getComputedStyle(singlepointRef.current).width, 10) /
				+coverSize.width;
			const h =
				parseInt(getComputedStyle(singlepointRef.current).height, 10) /
				+coverSize.height;

			firstKeyframe.width = w;
			firstKeyframe.height = h;
		}
		if (move) {
			const l =
				parseInt(getComputedStyle(singlepointRef.current).left, 10) /
				+coverSize.width;
			const t =
				parseInt(getComputedStyle(singlepointRef.current).top, 10) /
				+coverSize.height;

			firstKeyframe.left = l + 0.05; // offset 5%
			firstKeyframe.top = t + 0.05;
		}

		// get shape from current position
		if (multipoint) {
			const currentPath = multipointRef?.current?.getAttribute('d');
			const points: any = {};
			extractPoints(currentPath).forEach((point: any, i: number) => {
				const [left, top] = point.split(' ');
				points[i] = {
					left: +left / 100 + 0.05, // offset 5%
					top: +top / 100 + 0.05,
				};
			});

			firstKeyframe.points = points;
		}

		// get shape from current position
		if (multipoint) {
			const currentPath = multipointRef?.current?.getAttribute('d');
			const points: any = {};

			extractPoints(currentPath).forEach((point: any, i: number) => {
				const [left, top] = point.split(' ');
				points[i] = {
					left: +left / 100 + 0.05,
					top: +top / 100 + 0.05,
				};
			});

			firstKeyframe.points = points;
		}

		const keyframes = [firstKeyframe];

		setAnnotations(
			{ ...annotations, [id]: { ...annotations[annotationId], keyframes } },
			true
		);
	};

	const closestKeyframeIsHidden = annotation.keyframes
		.filter((keyframe: Keyframe) => keyframe.time <= currentTime * 1000)
		.pop()?.hide;

	const toggleVisibility = () => {
		updateKeyframe({}, !closestKeyframeIsHidden);
	};

	return createPortal(
		<Preferences
			key={annotationId}
			initial={{ opacity: 0 }}
			exit={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ ease: 'easeOut', duration: 0.2 }}
			style={style}>
			<ButtonRow className={isOutsideRight ? 'right' : 'left'}>
				<FloatingButton onClick={closePreferences}>
					<Icon name="close" fill="white" size={1.4} />
				</FloatingButton>
			</ButtonRow>

			<Box>
				<Option onClick={toggleVisibility}>
					<Icon
						name={closestKeyframeIsHidden ? 'eye-visible' : 'eye-hidden'}
						fill="white"
						size={closestKeyframeIsHidden ? 2 : 1.3}
					/>
					{closestKeyframeIsHidden
						? t('Show at current time', { _context })
						: t('Hide at current time', { _context })}
				</Option>
				<Option onClick={placeOnTop}>
					<Icon name="arrow-up" fill="white" size={1.5} />
					{t('Move to front', { _context })}
				</Option>
				<Option onClick={placeOnBottom}>
					<Icon name="arrow-down" fill="white" size={1.5} />
					{t('Move to back', { _context })}
				</Option>
				<Option onClick={duplicate}>
					<Icon name="duplicate" fill="white" size={1.2} />
					{t('Duplicate', { _context })}
				</Option>
				<Option onClick={deleteAnnotation} className="red">
					<Icon name="delete" fill="white" size={1.3} />
					{t('Delete')}
				</Option>
			</Box>
		</Preferences>,
		document.body
	);
}
