import { ReactNode } from 'react';

export const symbol_fence: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M240-160q-12.75 0-21.375-8.625T210-190v-140h-60q-12 0-21-8.625T120-360q0-12.75 8.5-21.375T150-390h60v-100h-60q-12 0-21-8.625T120-520q0-12.75 8.5-21.375T150-550h60v-127q0-5.913 2-11.457Q214-694 219-699l80-80q9-9 21-9t21 9l59 59 59-59q9.067-9 21.533-9Q493-788 502-779l59 59 58-59q9.067-9 21.533-9Q653-788 662-779l80 80q5 5 7 10.543 2 5.544 2 11.457v127h59q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-490h-59v100h59q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-330h-59v140q0 12.75-8.625 21.375T721-160H240Zm30-390h100v-115l-50-50-50 50v115Zm160 0h100v-115l-50-50-50 50v115Zm161 0h99v-115l-50-50-49 49v116ZM270-390h100v-100H270v100Zm160 0h100v-100H430v100Zm161 0h99v-100h-99v100ZM270-220h100v-110H270v110Zm160 0h100v-110H430v110Zm161 0h99v-110h-99v110Z"
		/>
	</svg>
);
