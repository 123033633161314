import { ReactNode } from 'react';

export const symbol_science_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m588-456-60-60v-264h-96v168l-60-60v-108h-52q-13 0-21.5-8.5T290-810q0-13 8.5-21.5T320-840h320q13 0 21.5 8.5T670-810q0 13-8.5 21.5T640-780h-52v324ZM160-180h535L411-464 160-180ZM797-78l-42-42H172q-42 0-59.5-39t11.5-71l245-276L90-785q-9-9-9-21t9-21q9-9 21-9t21 9l707 707q9 9 9 21t-9 21q-9 9-21 9t-21-9ZM411-464Zm69-100Z"
		/>
	</svg>
);
