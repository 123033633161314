export default {
	name: 'block',
	objects: [
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M12.0489 20.4282C16.603 20.4282 20.2949 16.7363 20.2949 12.1822C20.2949 7.62805 16.603 3.93619 12.0489 3.93619C7.49472 3.93619 3.80286 7.62805 3.80286 12.1822C3.80286 16.7363 7.49472 20.4282 12.0489 20.4282ZM12.9489 11.2285H12.0489H11.1489H8.98489C8.48784 11.2285 8.08489 11.6314 8.08489 12.1285C8.08489 12.6255 8.48784 13.0285 8.98489 13.0285H11.1489H12.0489H12.9489H15.1128C15.6099 13.0285 16.0128 12.6255 16.0128 12.1285C16.0128 11.6314 15.6099 11.2285 15.1128 11.2285L12.9489 11.2285Z',
			},
		},
	],
};
