import { ReactNode } from 'react';

export const symbol_stacked_bar_chart: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M229.911-160Q201-160 180.5-180.562 160-201.125 160-230v-370h140v370q0 28.875-20.589 49.438Q258.823-160 229.911-160ZM160-660v-70.089Q160-759 180.589-779.5q20.588-20.5 49.5-20.5Q259-800 279.5-779.438 300-758.875 300-730v70H160Zm319.911 500Q451-160 430.5-180.562 410-201.125 410-230v-250h140v250q0 28.875-20.589 49.438Q508.823-160 479.911-160ZM410-540v-70.089Q410-639 430.589-659.5q20.588-20.5 49.5-20.5Q509-680 529.5-659.438 550-638.875 550-610v70H410Zm319.911 380Q701-160 680.5-180.562 660-201.125 660-230v-130h140v130q0 28.875-20.589 49.438Q758.823-160 729.911-160ZM660-420v-70.089Q660-519 680.589-539.5q20.588-20.5 49.5-20.5Q759-560 779.5-539.438 800-518.875 800-490v70H660Z"
		/>
	</svg>
);
