import styled, { css } from 'styled-components';

import * as iconStyles from 'components/icon/styles.css';

export const DescriptionRow = styled.div<{ iconTop?: boolean }>`
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: 40px auto;

	${({ iconTop }) =>
		iconTop
			? css`
					align-items: flex-start;

					> div {
						/* Unaligned with Icon if not 3px... */
						margin-top: 3px;
					}
				`
			: css`
					align-items: center;
				`}

	font-size: var(--font-size-base);
	line-height: var(--font-line-height-base);
	color: var(--palette-gray-600);

	.${iconStyles.icon} {
		font-size: var(--font-size-2xl);
	}

	small {
		display: block;
		font-size: var(--font-size-2xs);
	}
`;
