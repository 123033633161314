import { ReactNode } from 'react';

export const symbol_team_dashboard: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-120q-24.75 0-42.375-17.625T120-180v-600q0-24.75 17.625-42.375T180-840h600q24.75 0 42.375 17.625T840-780v600q0 24.75-17.625 42.375T780-120H180Zm230-60v-270H180v270h230Zm60 0h310v-270H470v270ZM180-510h600v-270H180v270Z"
		/>
	</svg>
);
