import { ReactNode } from 'react';

export const symbol_speed_0_75: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M600-277q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T600-337h140v-115H600q-12.75 0-21.375-8.625T570-482v-171q0-12.75 8.625-21.375T600-683h170q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T770-623H630v111h110q24 0 42 18t18 42v115q0 24-18 42t-42 18H600ZM435-623H295q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T295-683h140q24 0 42 16t18 40q0 3-2 13l-84 315q-2 10-10 15.5t-18.316 5.5q-14 0-22.842-11Q349-300 353-314l82-309ZM229.825-277Q217-277 208.5-285.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Z"
		/>
	</svg>
);
