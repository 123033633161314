import { ReactNode } from 'react';

export const symbol_doorbell_3p: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M260-80q-24.75 0-42.375-17.625T200-140v-680q0-24.75 17.625-42.375T260-880h440q24.75 0 42.375 17.625T760-820v680q0 24.75-17.625 42.375T700-80H260Zm0-60h440v-680H260v680Zm220-60q33 0 56.5-23.5T560-280q0-33-23.5-56.5T480-360q-33 0-56.5 23.5T400-280q0 33 23.5 56.5T480-200Zm0-30q-20 0-35-15t-15-35.5q0-20.5 15-35t35.5-14.5q20.5 0 35 14.375T530-280q0 20-14.375 35T480-230Zm0-200q13.5 0 21.75-8.25T510-460h-60q0 14 8.25 22t21.75 8Zm-145-60h290q6 0 10.5-4.5T640-505q0-6-4.5-10.5T625-520h-35v-92q0-45-19.5-75.5T509-730v-14q0-12.25-8.5-20.125T480-772q-12 0-20.5 7.875T451-744v14q-42 15-61.5 44T370-612v92h-35q-6 0-10.5 4.5T320-505q0 6 4.5 10.5T335-490Zm-75 350v-680 680Z"
		/>
	</svg>
);
