import { createContext } from 'react';
import { List, Record } from 'immutable';

import { omit } from 'pkg/objects';
import { confirmNavigation } from 'pkg/router/utils';

const NOOP = () => {};

export const BROWSER_PUSH = 'browser/PUSH';
export const BROWSER_POP = 'browser/POP';
export const BROWSER_REPLACE = 'browser/REPLACE';
export const BROWSER_RESET = 'browser/RESET';

export const RouterContext = createContext({
	state: null,
	dispatch: NOOP,
});

export const RouterState = Record(
	{
		type: null,
		location: '/',
		direction: 0,
		position: 0,
		state: {},
		search: {},
	},
	'RouterState'
);

const RouterRecord = Record(
	{
		history: new List(),
	},
	'RouterRecord'
);

export const initialState = new RouterRecord({
	history: new List([new RouterState()]),
});

export const initializeState =
	(initialHistory = null) =>
	() => {
		if (List.isList(initialHistory)) {
			return new RouterRecord({
				history: initialHistory,
			});
		} else if (Array.isArray(initialHistory)) {
			return new RouterRecord({
				history: new List(initialHistory.map((item) => new RouterState(item))),
			});
		} else {
			return initialState;
		}
	};

export const browserReducer = (
	state,
	{ type, payload, skipMutations = false }
) => {
	const shouldUpdate = (nextLocation) => {
		const history = state.get('history');

		return (
			history.size === 0 || history?.last().get('location') !== nextLocation
		);
	};

	if (!payload || shouldUpdate(payload.location)) {
		const didConfirm = confirmNavigation(() => {
			window.history.forward();
		});

		if (!didConfirm) {
			return state;
		}
	}

	if (!type) return state;

	switch (type) {
		case BROWSER_PUSH: {
			if (shouldUpdate(payload.location)) {
				const nextState = new RouterState({
					...payload,
					type,
					direction: 1,
					position: state.size,
				});

				state = state.updateIn(['history'], (history) =>
					history.push(nextState)
				);

				if (!skipMutations) {
					const historyState = omit(nextState.toJS(), 'state');

					window.history.pushState(
						{ ...historyState, type },
						'',
						nextState.location
					);
				}
			}

			return state;
		}

		case BROWSER_POP: {
			state = state.updateIn(['history'], (history) => history.pop());

			if (!skipMutations) {
				window.history.back();
			}

			const historySize = state.get('history').size;

			if (historySize > 0) {
				state = state.setIn(['history', historySize - 1, 'direction'], -1);
			}

			return state;
		}

		case BROWSER_REPLACE: {
			if (shouldUpdate(payload.location)) {
				const nextState = new RouterState({
					...payload,
					type,
					direction: 0,
				});

				state = state.updateIn(['history'], (history) =>
					history.splice(-1, 1, nextState)
				);

				if (!skipMutations) {
					const historyState = omit(nextState.toJS(), 'state');

					window.history.replaceState(
						{ ...historyState, type },
						'',
						nextState.location
					);
				}
			}

			return state;
		}

		case BROWSER_RESET: {
			if (payload.fullReset) {
				return initialState;
			}

			const last = state.get('history').last();

			state = state.set('history', new List([last]));

			return state;
		}

		default:
			throw new Error(`No such action type: ${type}.`);
	}
};
