import { ReactNode } from 'react';

export const symbol_no_backpack: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M650-880q12.75 0 21.375 8.625T680-850v54q51 9 85.5 49.5T800-650v345q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T740-305v-345q0-38-26-64t-64-26H305l-7-7q-8-8-13-19.278-5-11.278-5-23.722v-60q0-12.75 8.625-21.375T310-880h40q12.75 0 21.375 8.625T380-850v50h200v-50q0-12.75 8.625-21.375T610-880h40ZM490-470l60 60H310q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T310-470h180Zm39-46Zm-53 117ZM204-756l42 42q-12 12-19 28.391-7 16.392-7 35.609v510h520v-80l60 60v20q0 24.75-17.625 42.375T740-80H220q-24.75 0-42.375-17.625T160-140v-510q0-32 12-59t32-47ZM792-83 82-792q-9-8.5-9-21.25T81.609-835q9.391-9 21.858-9 12.466 0 21.533 9l710 710q9 9 9 21t-9 21q-9 9-21.5 9T792-83Z"
		/>
	</svg>
);
