import { ReactNode } from 'react';

export const symbol_credit_card_gear: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m605-154-16 8q-11 5-22.5 1.5T548-158l-4-6q-7-11-5-22.5t12-19.5l15-12q-6-20-6-41.5t6-41.5l-15-12q-10-8.062-12-20.156T544-356l4-6q7-11 18.5-14t22.5 2l16 8q17-14 35.5-25t39.5-17l3.333-22.256Q685-441 693.1-449t18.9-8h7q11.2 0 19.6 7.5Q747-442 749-431l2 23q21 6 39.5 17t35.5 25l17-8q11-5 22.5-2t18.5 14l4 6q7 11 4.5 23T880-313l-15 12q6 20 6 41.5t-6 41.5l16 12q10 7.875 12 19.688 2 11.812-5 22.312l-4 6q-7 10-18.5 13.5T843-146l-17-8q-16 15-35 26t-40 17l-2 22q-2 11-10.4 18.5T719-63h-7q-10.8 0-18.9-8-8.1-8-9.767-18.667L680-111q-21-6-40-17t-35-26Zm109.947-4Q757-158 787-187.947t30-72Q817-302 787.053-332t-72-30Q673-362 643-332.053t-30 72Q613-218 642.947-188t72 30ZM140-220v-320 20-220 520Zm0-411h680v-109H140v109Zm0 471q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v212q0 14-11.5 20t-23.5-1q-28.753-14.88-61.376-22.94Q751-540 716-540q-38 0-74 10t-67 28H140v282h284q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T424-160H140Z"
		/>
	</svg>
);
