import { ReactNode } from 'react';

export const symbol_snowshoeing: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M355-40q-15 0-35.5-14T278-94L178-218q-5-7-4.5-15t6.5-13q7-5 15.5-4.5T209-243l47 58 109-81 72-363-102 46v103q0 13-8.5 21.5T305-450q-13 0-22-8.5t-9-21.5v-123q0-9 5-16.5t13-11.5l159-67q18-8 30.5-11t29.5-3q18 0 36.5 8.5T576-679l42 66q27 43 64.5 68.5T765-512q13 2 21.5 10.5T795-480q0 12-8.5 21t-20.5 7q-57-6-100.5-34.5T578-572l-41 156 82 74q5 5 7.5 10.5T629-320v241q29-2 50-9t34-14q3-2 6-2.5t5-.5q10 0 15.5 6t5.5 14q0 5-2.5 10t-7.5 8q-18 11-41 19t-54 8H515q-8 0-14-6t-6-14q0-8 6-14t14-6h54v-200l-108-98-31 133q-2 5-4.5 10t-7.5 8l-124 90 19 22q11 12 23.5 21T363-79q6 3 9.5 8t3.5 12q0 8-6.5 13.5T355-40Zm227-714q-30 0-51.5-21.5T509-827q0-30 21.5-51.5T582-900q30 0 51.5 21.5T655-827q0 30-21.5 51.5T582-754Z"
		/>
	</svg>
);
