import { ReactNode } from 'react';

export const symbol_function: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M215-120q-41 0-68.5-24T119-204q0-27 15-43.5t39-16.5q21 0 34.5 12t13.5 32q0 11-4.5 20.5T204-184q4 2 8.5 3t9.5 1q13 0 22-12.5t13-32.5l69-355h-96q-13 0-21.5-8.5T200-610q0-13 8.5-21.5T230-640h107l21-106q9-45 36-69.5t67-24.5q41 0 67.5 24t26.5 60q0 27-15 43.5T501-696q-20 0-34-11.5T453-738q0-10 4-19.5t10-15.5q-2-2-6-3.5t-8-1.5q-13 0-22.5 12T417-735l-18 95h159q13 0 21.5 8.5T588-610q0 13-8.5 21.5T558-580h-17l83 96 78-96h-18q-13 0-21.5-8.5T654-610q0-13 8.5-21.5T684-640h126q13 0 21.5 8.5T840-610q0 13-8.5 21.5T810-580h-28L662-439l120 139h28q13 0 21.5 8.5T840-270q0 13-8.5 21.5T810-240H684q-13 0-21.5-8.5T654-270q0-13 8.5-21.5T684-300h18l-80-93-82 93h18q13 0 21.5 8.5T588-270q0 13-8.5 21.5T558-240H432q-13 0-21.5-8.5T402-270q0-13 8.5-21.5T432-300h29l121-139-121-141h-74l-71 366q-8 45-34 69.5T215-120Z"
		/>
	</svg>
);
