import { ReactNode } from 'react';

export const symbol_dashboard_customize: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M150-510q-12.75 0-21.375-8.625T120-540v-270q0-12.75 8.625-21.375T150-840h270q12.75 0 21.375 8.625T450-810v270q0 12.75-8.625 21.375T420-510H150Zm30-271v188-188Zm360 271q-12.75 0-21.375-8.625T510-540v-270q0-12.75 8.625-21.375T540-840h270q12.75 0 21.375 8.625T840-810v270q0 12.75-8.625 21.375T810-510H540Zm53-271v188-188ZM150-120q-12.75 0-21.375-8.625T120-150v-270q0-12.75 8.625-21.375T150-450h270q12.75 0 21.375 8.625T450-420v270q0 12.75-8.625 21.375T420-120H150Zm30-249v189-189Zm494.825 249Q662-120 653.5-128.625T645-150v-105H539q-12 0-20.5-8.675-8.5-8.676-8.5-21.5 0-12.825 8.625-21.325T540-315h105v-106q0-12 8.675-20.5 8.676-8.5 21.5-8.5 12.825 0 21.325 8.625T705-420v105h106q12 0 20.5 8.675 8.5 8.676 8.5 21.5 0 12.825-8.625 21.325T810-255H705v106q0 12-8.675 20.5-8.676 8.5-21.5 8.5ZM570-780v210h210v-210H570Zm-390 0v210h210v-210H180Zm0 390v210h210v-210H180Z"
		/>
	</svg>
);
