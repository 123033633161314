import { ReactNode } from 'react';

export const symbol_electrical_services: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M745-360q-13 0-21.5-8.5T715-390q0-13 8.5-21.5T745-420h65q13 0 21.5 8.5T840-390q0 12-8.5 21t-21.5 9h-65Zm0 160q-13 0-21.5-8.5T715-230q0-13 8.5-21.5T745-260h65q13 0 21.5 8.5T840-230q0 12-8.5 21t-21.5 9h-65Zm-185 40q-29 0-49.5-23.5T490-240h-65q-13 0-21.5-8.5T395-270v-80q0-13 8.5-21.5T425-380h65q0-33 20.5-56.5T560-460h95q13 0 21.5 8.5T685-430v240q0 13-8.5 21.5T655-160h-95ZM275-280q-67 0-111-43.5T120-435q0-68 44-111.5T275-590h65q33 0 54-21t21-54q0-33-21-54t-54-21H190q-12 0-21-9t-9-21q0-13 9-21.5t21-8.5h150q58 0 96.5 38.5T475-665q0 58-38.5 96.5T340-530h-65q-42 0-68.5 26T180-435q0 43 26.5 69t68.5 26h60q13 0 21.5 8.5T365-310q0 13-8.5 21.5T335-280h-60Z"
		/>
	</svg>
);
