import Add from 'assets/icons/add';
import AddConversation from 'assets/icons/add-conversation';
import AddFolder from 'assets/icons/add-folder';
import AddPost from 'assets/icons/add-post';
import AddReaction from 'assets/icons/add-reaction';
import Age from 'assets/icons/age';
import Apple from 'assets/icons/apple';
import Archive from 'assets/icons/archive';
import Area from 'assets/icons/area';
import ArrowDown from 'assets/icons/arrow-down';
import ArrowRight from 'assets/icons/arrow-right';
import ArrowUp from 'assets/icons/arrow-up';
import At from 'assets/icons/at';
import Attachment from 'assets/icons/attachment';
import AvatarUpload from 'assets/icons/avatar-upload';
import AvJumpNext from 'assets/icons/av-jump-next';
import AvJumpPrev from 'assets/icons/av-jump-prev';
import AvPause from 'assets/icons/av-pause';
import AvPlay from 'assets/icons/av-play';
import AvPlaybackSpeed from 'assets/icons/av-playback-speed';
import AvPlayClips from 'assets/icons/av-play-clips';
import AvRecord from 'assets/icons/av-record';
import AvRepeat from 'assets/icons/av-repeat';
import AvReplay from 'assets/icons/av-replay';
import AvSkipBackwards from 'assets/icons/av-skip-backwards';
import AvSkipForwards from 'assets/icons/av-skip-forwards';
import AvStepNext from 'assets/icons/av-step-next';
import AvStepPrev from 'assets/icons/av-step-prev';
import AvStop from 'assets/icons/av-stop';
import AvTheatreMode from 'assets/icons/av-theatre-mode';
import Badge from 'assets/icons/nav-badge';
import Block from 'assets/icons/block';
import Bus from 'assets/icons/bus';
import CalendarClean from 'assets/icons/calendar-clean';
import CalendarContinue from 'assets/icons/calendar-continue';
import Camera from 'assets/icons/camera';
import Card from 'assets/icons/card';
import CaretDown from 'assets/icons/caret-down';
import CaretUp from 'assets/icons/caret-up';
import Check from 'assets/icons/check';
import CheckboxChecked from 'assets/icons/checkbox-checked';
import CheckboxCheckedOutlined from 'assets/icons/checkbox-checked-outlined';
import CheckboxUnchecked from 'assets/icons/checkbox-unchecked';
import CheckCircle from 'assets/icons/check-circle';
import CheckFilled from 'assets/icons/check-filled';
import Chevron from 'assets/icons/chevron';
import ChevronDown from 'assets/icons/chevron-down';
import Clapperboard from 'assets/icons/clapperboard';
import ClipAnnotations from 'assets/icons/clip-annotations';
import ClipAuthor from 'assets/icons/clip-author';
import ClipUserTag from 'assets/icons/clip-user-tag';
import Clock from 'assets/icons/clock';
import Close from 'assets/icons/close';
import CloseCircle from 'assets/icons/close-circle';
import CloseCircleFilled from 'assets/icons/close-circle-filled';
import Coach from 'assets/icons/coach';
import Collapse from 'assets/icons/collapse';
import CollapseReverse from 'assets/icons/collapse-reverse';
import Collection from 'assets/icons/collection';
import ColorPicker from 'assets/icons/color-picker';
import Comment from 'assets/icons/comment';
import Compare from 'assets/icons/compare';
import Cone from 'assets/icons/cone';
import Connect from 'assets/icons/connect';
import ContactCard from 'assets/icons/contact-card';
import ContextMenu from 'assets/icons/context-menu';
import Conversations from 'assets/icons/conversations';
import Create from 'assets/icons/create';
import Cube from 'assets/icons/cube';
import CustomizeContent from 'assets/icons/customize-content';
import Database from 'assets/icons/database';
import DataConnection from 'assets/icons/data-connection';
import Delete from 'assets/icons/delete';
import Download from 'assets/icons/download';
import DownloadSimple from 'assets/icons/download-simple';
import DragHandle from 'assets/icons/drag-handle';
import DrawingArrow from 'assets/icons/drawing-arrow';
import DrawingArrowDashed from 'assets/icons/drawing-arrow-dashed';
import DrawingCircle from 'assets/icons/drawing-circle';
import DrawingCircleDashed from 'assets/icons/drawing-circle-dashed';
import DrawingFreehand from 'assets/icons/drawing-freehand';
import DrawingLine from 'assets/icons/drawing-line';
import DrawingLineDashed from 'assets/icons/drawing-line-dashed';
import DrawingPolygon from 'assets/icons/drawing-polygon';
import DrawingPolygonDashed from 'assets/icons/drawing-polygon-dashed';
import DrawingRectangle from 'assets/icons/drawing-rectangle';
import DrawingRectangleDashed from 'assets/icons/drawing-rectangle-dashed';
import DrawingSquigglyArrow from 'assets/icons/drawing-squiggly-arrow';
import DrawingSquigglyLine from 'assets/icons/drawing-squiggly-line';
import DrawingText from 'assets/icons/drawing-text';
import Dressingroom from 'assets/icons/dressingroom';
import Duplicate from 'assets/icons/duplicate';
import Duration from 'assets/icons/duration';
import Discount from 'assets/icons/discount';
import Edit from 'assets/icons/edit';
import Error from 'assets/icons/error';
import EyeHidden from 'assets/icons/eye-hidden';
import EyeVisible from 'assets/icons/eye-visible';
import Facebook from 'assets/icons/facebook';
import Field from 'assets/icons/field';
import Fields from 'assets/icons/fields';
import FileCsv from 'assets/icons/file-csv';
import FileGeneric from 'assets/icons/file-generic';
import FileImage from 'assets/icons/file-image';
import FilePdf from 'assets/icons/file-pdf';
import Filter from 'assets/icons/filter';
import Flash from 'assets/icons/flash';
import FolderList from 'assets/icons/folder-list';
import Football from 'assets/icons/football';
import Fresh from 'assets/icons/fresh';
import Fullscreen from 'assets/icons/fullscreen';
import FullscreenClose from 'assets/icons/fullscreen-close';
import GoogleMaps from 'assets/icons/google-maps';
import GroupMove from 'assets/icons/group-move';
import HelpCircle from 'assets/icons/help-circle';
import HelpCircleFilled from 'assets/icons/help-circle-filled';
import History from 'assets/icons/history';
import Hourglass from 'assets/icons/hourglass';
import ImageRotate from 'assets/icons/image-rotate';
import InfoCircle from 'assets/icons/info-circle';
import IntercomHelp from 'assets/icons/intercom-help';
import Key from 'assets/icons/key';
import Language from 'assets/icons/language';
import Leave from 'assets/icons/leave';
import Library from 'assets/icons/library';
import Link from 'assets/icons/link';
import ListClear from 'assets/icons/list-clear';
import List from 'assets/icons/list';
import Location from 'assets/icons/location';
import Lock from 'assets/icons/lock';
import LockOpen from 'assets/icons/lock-open';
import Logo from 'assets/icons/logo';
import LongText from 'assets/icons/long-text';
import MagnifyMinus from 'assets/icons/magnify-minus';
import MagnifyPlus from 'assets/icons/magnify-plus';
import Mail from 'assets/icons/mail';
import MarkAllComplete from 'assets/icons/mark-all-complete';
import Menu from 'assets/icons/menu';
import Merge from 'assets/icons/merge';
import Mute from 'assets/icons/mute';
import NavAccountSettings from 'assets/icons/nav-account-settings';
import NavActivity from 'assets/icons/nav-activity';
import NavAssess from 'assets/icons/nav-assess';
import NavAttendance from 'assets/icons/nav-attendance';
import NavBack from 'assets/icons/nav-back';
import NavDialogue from 'assets/icons/nav-dialogue';
import NavEvents from 'assets/icons/nav-events';
import NavFormation from 'assets/icons/nav-formation';
import NavGroup from 'assets/icons/nav-group';
import NavHome from 'assets/icons/nav-home';
import NavLogout from 'assets/icons/nav-logout';
import NavMembers from 'assets/icons/nav-members';
import NavMenu from 'assets/icons/nav-menu';
import NavPrivacySettngs from 'assets/icons/nav-privacy-settings';
import NavProfile from 'assets/icons/nav-profile';
import NavProfileSettings from 'assets/icons/nav-profile-settings';
import NavSettings from 'assets/icons/nav-settings';
import NewWindow from 'assets/icons/new-window';
import Note from 'assets/icons/note';
import Notification from 'assets/icons/notification';
import NotificationOutline from 'assets/icons/notification-outline';
import Number from 'assets/icons/number';
import Order from 'assets/icons/order';
import Organisation from 'assets/icons/nav-organisation';
import Overlap from 'assets/icons/overlap';
import Parent from 'assets/icons/parent';
import Payments from 'assets/icons/nav-payments';
import Person from 'assets/icons/person';
import Pin from 'assets/icons/pin';
import Plane from 'assets/icons/plane';
import Player from 'assets/icons/player';
import PlaylistAdd from 'assets/icons/playlist-add';
import Print from 'assets/icons/print';
import RadioChecked from 'assets/icons/radio-checked';
import RadioUnchecked from 'assets/icons/radio-unchecked';
import Redo from 'assets/icons/redo';
import Registration from 'assets/icons/registration';
import Reload from 'assets/icons/reload';
import Remove from 'assets/icons/remove';
import Reply from 'assets/icons/reply';
import Resource from 'assets/icons/resource';
import RotateLeft from 'assets/icons/rotate-left';
import RotateRight from 'assets/icons/rotate-right';
import Scissors from 'assets/icons/scissors';
import Search from 'assets/icons/search';
import SelectTrigger from 'assets/icons/select-trigger';
import Share from 'assets/icons/share';
import Shirt from 'assets/icons/shirt';
import Shoe from 'assets/icons/shoe';
import ShortText from 'assets/icons/short-text';
import SingleChoice from 'assets/icons/single-choice';
import Sort from 'assets/icons/sort';
import Star from 'assets/icons/star';
import StarHollow from 'assets/icons/star-hollow';
import Statistics from 'assets/icons/statistics';
import Stopwatch from 'assets/icons/stopwatch';
import Stripe from 'assets/icons/stripe';
import Supervisor from 'assets/icons/supervisor';
import Sync from 'assets/icons/sync';
import Tag from 'assets/icons/tag';
import TaskComplete from 'assets/icons/task-complete';
import TaskIncomplete from 'assets/icons/task-incomplete';
import ThumbsUp from 'assets/icons/thumbs-up';
import Timer from 'assets/icons/timer';
import TimerAdd from 'assets/icons/timer-add';
import TrackChanges from 'assets/icons/track-changes';
import Training from 'assets/icons/training';
import TreeView from 'assets/icons/tree-view';
import Trending from 'assets/icons/trending';
import TrendingDown from 'assets/icons/trending-down';
import TrendingFlat from 'assets/icons/trending-flat';
import Trophy from 'assets/icons/trophy';
import Unfold from 'assets/icons/unfold';
import Unlink from 'assets/icons/unlink';
import UnmarkAllComplete from 'assets/icons/unmark-all-complete';
import Upload from 'assets/icons/upload';
import User from 'assets/icons/user';
import UserAdd from 'assets/icons/user-add';
import UserChecked from 'assets/icons/user-checked';
import UserEdit from 'assets/icons/user-edit';
import VideoAnalyze from 'assets/icons/video-analyze';
import VideoCamera from 'assets/icons/video-camera';
import VideoPlaceholder from 'assets/icons/video-placeholder';
import VideoPlaylist from 'assets/icons/video-playlist';
import Videos from 'assets/icons/videos';
import VolumeHigh from 'assets/icons/volume-high';
import VolumeLow from 'assets/icons/volume-low';
import VolumeOff from 'assets/icons/volume-off';
import Wall from 'assets/icons/wall';
import WarningCircle from 'assets/icons/warning-circle';
import Whistle from 'assets/icons/whistle';
import Cards from 'assets/icons/cards';

export interface IconObject {
	type: string;
	attributes: {
		clipRule?: string;
		fillRule?: string;
		d?: string;
		fill?: string;
		x?: string | number;
		y?: string | number;
		rx?: string | number;
		width?: string | number;
		height?: string | number;
		strokeWidth?: string | number;
		stroke?: string;
		transform?: string;
	};
}

export interface IconDefinition {
	name: string;
	objects?: IconObject[];
	paths?: string[];
	viewBox?: string;
}

const library: IconDefinition[] = [
	Add,
	AddConversation,
	AddFolder,
	AddPost,
	AddReaction,
	Age,
	Apple,
	Archive,
	Area,
	ArrowDown,
	ArrowRight,
	ArrowUp,
	At,
	Attachment,
	AvatarUpload,
	AvJumpNext,
	AvJumpPrev,
	AvPause,
	AvPlay,
	AvPlaybackSpeed,
	AvPlayClips,
	AvRecord,
	AvRepeat,
	AvReplay,
	AvSkipBackwards,
	AvSkipForwards,
	AvStepNext,
	AvStepPrev,
	AvStop,
	AvTheatreMode,
	Badge,
	Block,
	Bus,
	CalendarClean,
	CalendarContinue,
	Camera,
	Card,
	Cards,
	CaretDown,
	CaretUp,
	Check,
	CheckboxChecked,
	CheckboxCheckedOutlined,
	CheckboxUnchecked,
	CheckCircle,
	CheckFilled,
	Chevron,
	ChevronDown,
	Clapperboard,
	ClipAnnotations,
	ClipAuthor,
	ClipUserTag,
	Clock,
	Close,
	CloseCircle,
	CloseCircleFilled,
	Coach,
	Collapse,
	CollapseReverse,
	Collection,
	ColorPicker,
	Comment,
	Compare,
	Cone,
	Connect,
	ContactCard,
	ContextMenu,
	Conversations,
	Create,
	Cube,
	CustomizeContent,
	Database,
	DataConnection,
	Delete,
	Download,
	DownloadSimple,
	DragHandle,
	DrawingArrow,
	DrawingArrowDashed,
	DrawingCircle,
	DrawingCircleDashed,
	DrawingFreehand,
	DrawingLine,
	DrawingLineDashed,
	DrawingPolygon,
	DrawingPolygonDashed,
	DrawingRectangle,
	DrawingRectangleDashed,
	DrawingSquigglyArrow,
	DrawingSquigglyLine,
	DrawingText,
	Dressingroom,
	Duplicate,
	Duration,
	Discount,
	Edit,
	Error,
	EyeHidden,
	EyeVisible,
	Facebook,
	Field,
	Fields,
	FileCsv,
	FileGeneric,
	FileImage,
	FilePdf,
	Filter,
	Flash,
	FolderList,
	Football,
	Fresh,
	Fullscreen,
	FullscreenClose,
	GoogleMaps,
	GroupMove,
	HelpCircle,
	HelpCircleFilled,
	History,
	Hourglass,
	ImageRotate,
	InfoCircle,
	IntercomHelp,
	Key,
	Language,
	Leave,
	Library,
	Link,
	ListClear,
	List,
	Location,
	Lock,
	LockOpen,
	Logo,
	LongText,
	MagnifyMinus,
	MagnifyPlus,
	Mail,
	MarkAllComplete,
	Menu,
	Merge,
	Mute,
	NavAccountSettings,
	NavActivity,
	NavAssess,
	NavAttendance,
	NavBack,
	NavDialogue,
	NavEvents,
	NavFormation,
	NavGroup,
	NavHome,
	NavLogout,
	NavMembers,
	NavMenu,
	NavPrivacySettngs,
	NavProfile,
	NavProfileSettings,
	NavSettings,
	NewWindow,
	Note,
	Notification,
	NotificationOutline,
	Number,
	Order,
	Organisation,
	Overlap,
	Parent,
	Payments,
	Person,
	Pin,
	Plane,
	Player,
	PlaylistAdd,
	Print,
	RadioChecked,
	RadioUnchecked,
	Redo,
	Registration,
	Reload,
	Remove,
	Reply,
	Resource,
	RotateLeft,
	RotateRight,
	Scissors,
	Search,
	SelectTrigger,
	Share,
	Shirt,
	Shoe,
	ShortText,
	SingleChoice,
	Sort,
	Star,
	StarHollow,
	Statistics,
	Stopwatch,
	Stripe,
	Supervisor,
	Sync,
	Tag,
	TaskComplete,
	TaskIncomplete,
	ThumbsUp,
	Timer,
	TimerAdd,
	TrackChanges,
	Training,
	TreeView,
	Trending,
	TrendingDown,
	TrendingFlat,
	Trophy,
	Unfold,
	Unlink,
	UnmarkAllComplete,
	Upload,
	User,
	UserAdd,
	UserChecked,
	UserEdit,
	VideoAnalyze,
	VideoCamera,
	VideoPlaceholder,
	VideoPlaylist,
	Videos,
	VolumeHigh,
	VolumeLow,
	VolumeOff,
	Wall,
	WarningCircle,
	Whistle,
];

export default library;
