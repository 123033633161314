import { useState } from 'react';
import { t } from '@transifex/native';

import { Statuses } from 'pkg/models/form';

import { usePaymentProviderContext } from 'pkg/contexts/provider_settings';
import { useEndpoint } from 'pkg/api/use_endpoint';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as actions from 'pkg/actions';
import { FormProductPrice } from 'pkg/api/models/form';
import { UpdateFormPayload } from 'pkg/api/endpoints/forms';
import { replaceState } from 'pkg/router/state';
import * as routes from 'pkg/router/routes';
import { useCurrentOrganization } from 'pkg/identity';

import useFormEngine from 'routes/forms/hooks/useFormEngine';
import SuccessView from 'routes/forms/SuccessView';

import { Spinner } from 'components/loaders/spinner';
import FormComponent, { FormPayload } from 'components/form/Form';

interface EditFormWrapperProps {
	groupId: number;
	formId: number;
}

export default function EditFormWrapper({
	groupId,
	formId,
}: EditFormWrapperProps) {
	const {
		record: form,
		replaceRecord,
		isLoading: isLoadingForm,
	} = useEndpoint<models.form.Form>(endpoints.Forms.Show(formId));

	if (isLoadingForm) {
		return <Spinner />;
	}

	return (
		<EditForm
			form={form}
			groupId={groupId}
			formId={formId}
			replaceRecord={replaceRecord}
		/>
	);
}

interface EditFormProps {
	groupId: number;
	formId: number;

	form: models.form.Form;
	replaceRecord: (record: models.form.Form) => void;
}

function EditForm({ form, groupId, formId, replaceRecord }: EditFormProps) {
	const org = useCurrentOrganization();
	const [isSaved, setIsSaved] = useState(false);
	const providerSettings = usePaymentProviderContext();

	const { formData, FormContent } = useFormEngine({
		prevData: form,
		edit: true,
	});

	const handleSave = async (data: FormPayload) => {
		const payload: UpdateFormPayload = {
			title: data.title as string,
			status: data.status as Statuses,
			fields: formData.fields.map((field, index) => {
				field.sortOrder = index;
				return field;
			}),
			description: data.description as string,
			visibility: data.visibility as string,
			submissionEmailEnabled: formData.submissionEmailEnabled,
			submissionEmailSubject: formData.submissionEmailSubject,
			submissionEmailContent: formData.submissionEmailContent,
			productPrices: [],
			forceAuth: data.forceAuth as boolean,
		};

		if (data.maxSubmissions && data.limitResponses) {
			payload.maxSubmissions = Number.parseInt(
				data.maxSubmissions as string,
				10
			);
		} else if (data.maxSubmissions && !data.limitResponses) {
			payload.maxSubmissions = 0;
		}

		if (data.formCategoryId) {
			payload.formCategoryId = Number.parseInt(
				data.formCategoryId as string,
				10
			);
		} else {
			payload.formCategoryId = null;
		}

		formData.priceOptions.forEach((priceOption, index) => {
			priceOption.productPrices.forEach((productPrice) => {
				const productInfo = formData.selectedProductsInfo.find(
					(prodInfo) => prodInfo.product.id === productPrice.product.id
				);

				if (!productInfo) {
					return;
				}

				const formProductPrice: FormProductPrice = {
					productPriceId: productPrice.id,
					minQuantity: productInfo.minQuantity,
					maxQuantity: productInfo.maxQuantity,
					required: productInfo.required,
					validTo: productInfo.validTo,

					bundleNumber: index + 1,
					installmentCount: priceOption.installmentCount,
				};

				if (providerSettings.settings.canHaveDelayedSubscriptionStart) {
					if (priceOption.subscriptionStartAt) {
						formProductPrice.subscriptionStartAt =
							priceOption.subscriptionStartAt;
						formProductPrice.payFirstOrderNow = true;
					}
				}

				if (productInfo.taxRateId) {
					formProductPrice.taxRateId = productInfo.taxRateId;
				}

				payload.productPrices.push(formProductPrice);
			});
		});

		const [req, res] = await models.update<UpdateFormPayload, models.form.Form>(
			form,
			payload
		);

		if (req.ok) {
			setIsSaved(true);
			replaceRecord(res);
		}
	};

	const handlePublish = async () => {
		await actions.forms.update(formId, {
			status: Statuses.Open,
			title: form.title,
			fields: form.fields,
		});

		actions.flashes.show(
			{
				title: t('Published!'),
				message: t('Registration published successfully'),
			},
			200,
			'check-circle'
		);

		replaceState(routes.Registrations.Index(org.id, groupId, 'active'));
	};

	if (isSaved) {
		return (
			<SuccessView
				groupId={groupId}
				form={form}
				isEdit
				handlePublish={handlePublish}
				setIsSaved={setIsSaved}
			/>
		);
	}

	return <FormComponent onSubmit={handleSave}>{FormContent}</FormComponent>;
}
