// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._tZGjqEY_4BJjppICzC5 {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	font-weight: var(--font-weight-semibold);
	gap: var(--spacing-2);
}
`, "",{"version":3,"sources":["webpack://./components/training-library/modals/share/styles.css"],"names":[],"mappings":"AAAA;CACC,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CACnB,wCAAwC;CACxC,qBAAqB;AACtB","sourcesContent":[".sharedWith {\n\tdisplay: flex;\n\talign-items: center;\n\tfont-weight: var(--font-weight-semibold);\n\tgap: var(--spacing-2);\n}\n"],"sourceRoot":""}]);
// Exports
export var sharedWith = `_tZGjqEY_4BJjppICzC5`;
export default ___CSS_LOADER_EXPORT___;
