import { t } from '@transifex/native';

export function translatedRatingItemCategory(cat: string): string {
	switch (cat) {
		case 'base':
			return t('Base', {
				_context: 'rating-item-category',
			});
		case 'defending-skills':
			return t('Defending', {
				_context: 'rating-item-category',
			});
		case 'dribbling-skills':
			return t('Technique', {
				_context: 'rating-item-category',
			});
		case 'goalkeeping':
			return t('Goalkeeper', {
				_context: 'rating-item-category',
			});
		case 'heading-skills':
			return t('Heading', {
				_context: 'rating-item-category',
			});
		case 'mental':
			return t('Mental', {
				_context: 'rating-item-category',
			});
		case 'pace-skills':
			return t('Pace', {
				_context: 'rating-item-category',
			});
		case 'passing-skills':
			return t('Passing', {
				_context: 'rating-item-category',
			});
		case 'physical':
			return t('Physical', {
				_context: 'rating-item-category',
			});
		case 'physical-skills':
			return t('Physique', {
				_context: 'rating-item-category',
			});
		case 'shooting-skills':
			return t('Attacking', {
				_context: 'rating-item-category',
			});
		case 'skills':
			return t('Skills', {
				_context: 'rating-item-category',
			});
		case 'vision-skills':
			return t('Vision', {
				_context: 'rating-item-category',
			});
		default:
			return t('Unknown category', {
				_context: 'rating-item-category',
			});
	}
}
