import { Fragment, useState } from 'react';

import * as models from 'pkg/api/models';
import { cssClasses } from 'pkg/css/utils';

import { GalleryModal } from 'components/attachment/gallery';
import ImageAttachment from 'components/attachment/ImageAttachment';
import FileAttachment from 'components/attachment/file-attachment';

import * as css from './styles.css';

interface GalleryPreviewProps {
	attachments: models.attachment.Attachment[];
	tight?: boolean;
	disableGallery?: boolean;
}

export default function GalleryPreview({
	attachments = [],
	tight,
	disableGallery,
}: GalleryPreviewProps) {
	const [activeAttachmentId, setActiveAttachmentId] = useState(null);

	const handleOpenModal = (attachmentId: number) => {
		if (disableGallery) return;

		setActiveAttachmentId(attachmentId);
	};

	const handleCloseModal = () => setActiveAttachmentId(null);

	const handleMoreClick = () => {
		const attachmentId = images[3].id;
		handleOpenModal(attachmentId);
	};

	const images = attachments
		.filter((n) => n)
		.filter(
			(attachment) =>
				attachment.previewUrl !== '' || attachment.type === 'image'
		);

	const files = attachments
		.filter((n) => n)
		.filter(({ type }) => type !== 'image');

	const imagesCopy = [...images];
	const renderedImages = imagesCopy
		.splice(0, 4)
		.map((attachment) => (
			<ImageAttachment
				key={attachment.id}
				attachment={attachment}
				toggleGallery={handleOpenModal}
				clickable={!disableGallery}
				className={css.image}
			/>
		));

	const renderedFiles = files.map((attachment) => (
		<FileAttachment
			key={attachment.id}
			attachment={attachment}
			toggleGallery={handleOpenModal}
			clickable={!disableGallery}
		/>
	));

	const moreProps = disableGallery ? {} : { onClick: handleMoreClick };

	let numImageClass = '';

	switch (images.length) {
		case 1:
			numImageClass = css.oneImg;
			break;
		case 2:
			numImageClass = css.twoImg;
			break;
		case 3:
			numImageClass = css.threeImg;
			break;
		default:
			numImageClass = css.fourImg;
			break;
	}

	return (
		<Fragment>
			{images.length > 0 && (
				<div className={cssClasses(css.imagesWrapper, numImageClass)}>
					{renderedImages}
					{images.length > 4 && (
						<div
							className={cssClasses(css.more, css.clickable)}
							{...moreProps}>{`+${images.length - 4}`}</div>
					)}
				</div>
			)}

			{files.length > 0 && (
				<div className={cssClasses(css.filesWrapper, tight ? css.tight : '')}>
					{renderedFiles}
				</div>
			)}

			{!disableGallery && activeAttachmentId && (
				<GalleryModal
					activeId={activeAttachmentId}
					attachments={attachments}
					onClose={handleCloseModal}
				/>
			)}
		</Fragment>
	);
}
