import { Fragment, useContext } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import uuid from 'pkg/uuid';
import * as models from 'pkg/api/models';
import * as actions from 'pkg/actions';

import { RenderPrint } from 'components/MediaQuery';

import FabricCanvas from 'components/drawing/FabricCanvas';
import { FabricContext } from 'components/drawing/FabricContext';
import DisplayActions from 'components/drawing/display/DisplayActions';
import { PlaybackControls } from 'components/drawing/display/PlaybackControls';
import { Thumbnail } from 'components/drawing/edit/StepsPane';

const _context = 'training_library/drawing';

const Display = styled.div`
	& .canvas-container {
		width: 540px !important;
		height: 540px !important;
	}
	& canvas {
		width: 100% !important;
		height: 100% !important;
		border-radius: var(--spacing-4);
	}
`;

const Steps = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	padding: 10px;
`;
export default function DisplayWrapper({
	setIsEditing,
	drawingId,
	drawing,
	drawings,
	setDrawings,
	deleteExistingDrawing,
	isCurrentThumbnail,
	setThumbnail,
}: any) {
	const { canvas, slides } = useContext(FabricContext);

	const edit = (e: any) => {
		e.preventDefault();
		setIsEditing(true);
	};

	const duplicate = (e: any) => {
		e.preventDefault();
		const id = 'tempId-' + uuid();
		setDrawings({ ...drawings, [id]: { ...drawing } });
	};

	const remove = () => {
		const isExisting = !drawingId.startsWith('tempId-');

		if (isExisting) {
			// will be removed from database on exercise save
			deleteExistingDrawing(drawingId);
		} else {
			// instantly removed from state
			const newDrawings = { ...drawings };
			delete newDrawings[drawingId];
			setDrawings(newDrawings);
		}
		if (isCurrentThumbnail) {
			setThumbnail(undefined);
		}
	};

	const getDataURL = (multiplier: number = 4) =>
		canvas.current
			.toDataURL({ multiplier })
			.replace('image/png', 'image/octet-stream');

	const exportImage = (e: any) => {
		e.preventDefault();

		const image = getDataURL(4);
		const link = document.createElement('a');
		link.download =
			'exercise' + ' - ' + new Date().toLocaleString('sv') + '.png';
		link.href = image;
		link.click();
	};

	const useAsThumbnail = async () => {
		const image = getDataURL(1.2);
		const blob = await (await fetch(image)).blob();
		const file = new File([blob], `drawing-${drawingId}.png`, {
			type: 'image/png',
		});

		const attachment = await actions.attachments.upload({
			type: models.attachment.AttachmentType.Image,
			visibility: models.attachment.AttachmentVisibility.Hidden,
			originalFilename: file.name,
			title: file.name,
			file: file,
		});
		setThumbnail(attachment);
	};

	return (
		<Fragment>
			<Interactive>
				<Display>
					<FabricCanvas />
				</Display>
				<Steps>
					{setIsEditing && (
						<DisplayActions
							edit={edit}
							duplicate={duplicate}
							remove={remove}
							exportImage={exportImage}
							setThumbnail={!isCurrentThumbnail && useAsThumbnail}
						/>
					)}

					<PlaybackControls />
				</Steps>
			</Interactive>
			<RenderPrint>
				<Printer>
					<h3>{t('Drawing', { _context })}</h3>
					{slides.map((slideObject, i) => (
						<div key={i} style={{ pageBreakInside: 'avoid' }}>
							{slides.length > 1 && (
								<h5>{t('Step {no}', { _context, no: i + 1 })}</h5>
							)}
							<Thumbnail slide={i} />
						</div>
					))}
				</Printer>
			</RenderPrint>
		</Fragment>
	);
}

const Interactive = styled.div`
	position: relative;

	@media print {
		display: none;
	}
`;

const Printer = styled.div`
	display: none;
	@media print {
		display: initial;
	}
`;
