// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.J53rfl9AiYKxGEpBW4j7 {
	--x: 0px;
	--y: 0px;

	position: absolute;
	left: var(--x);
	top: var(--y);
	z-index: var(--index-tooltip-contents);
}

.J53rfl9AiYKxGEpBW4j7[data-align='center'] {
		-webkit-transform: translate(-50%, calc(-100% - 5px));
		        transform: translate(-50%, calc(-100% - 5px));
	}

.J53rfl9AiYKxGEpBW4j7[data-align='right'] {
		left: auto;
		left: initial;
		right: calc(0px + var(--x));
	}

.OCYNNXMZkYmiIJZSI5az {
	background: var(--palette-gray-800);
	color: var(--palette-gray-200);
	font-size: var(--font-size-xs);
	padding: var(--spacing-2) var(--spacing-3);
	border-radius: var(--radius-2);
	-webkit-user-select: none;
	   -moz-user-select: none;
	            -ms-user-select: none;
	        user-select: none;
	pointer-events: none;
	display: block;
}

.NbyO2rnpMv1FAukqVdTB {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	z-index: var(--index-tooltip-backdrop);
}
`, "",{"version":3,"sources":["webpack://./components/tooltip/styles.css"],"names":[],"mappings":"AAAA;CACC,QAAQ;CACR,QAAQ;;CAER,kBAAkB;CAClB,cAAc;CACd,aAAa;CACb,sCAAsC;AAUvC;;AARC;EACC,qDAA6C;UAA7C,6CAA6C;CAC9C;;AAEA;EACC,UAAa;EAAb,aAAa;EACb,2BAA2B;CAC5B;;AAGD;CACC,mCAAmC;CACnC,8BAA8B;CAC9B,8BAA8B;CAC9B,0CAA0C;CAC1C,8BAA8B;CAC9B,yBAAiB;IAAjB,sBAAiB;aAAjB,qBAAiB;SAAjB,iBAAiB;CACjB,oBAAoB;CACpB,cAAc;AACf;;AAEA;CACC,WAAW;CACX,YAAY;CACZ,eAAe;CACf,MAAQ;CAAR,QAAQ;CAAR,SAAQ;CAAR,OAAQ;CACR,uBAAuB;CACvB,sCAAsC;AACvC","sourcesContent":[".tooltip {\n\t--x: 0px;\n\t--y: 0px;\n\n\tposition: absolute;\n\tleft: var(--x);\n\ttop: var(--y);\n\tz-index: var(--index-tooltip-contents);\n\n\t&[data-align='center'] {\n\t\ttransform: translate(-50%, calc(-100% - 5px));\n\t}\n\n\t&[data-align='right'] {\n\t\tleft: initial;\n\t\tright: calc(0px + var(--x));\n\t}\n}\n\n.textTooltip {\n\tbackground: var(--palette-gray-800);\n\tcolor: var(--palette-gray-200);\n\tfont-size: var(--font-size-xs);\n\tpadding: var(--spacing-2) var(--spacing-3);\n\tborder-radius: var(--radius-2);\n\tuser-select: none;\n\tpointer-events: none;\n\tdisplay: block;\n}\n\n.backdrop {\n\twidth: 100%;\n\theight: 100%;\n\tposition: fixed;\n\tinset: 0;\n\tbackground: transparent;\n\tz-index: var(--index-tooltip-backdrop);\n}\n"],"sourceRoot":""}]);
// Exports
export var tooltip = `J53rfl9AiYKxGEpBW4j7`;
export var textTooltip = `OCYNNXMZkYmiIJZSI5az`;
export var backdrop = `NbyO2rnpMv1FAukqVdTB`;
export default ___CSS_LOADER_EXPORT___;
