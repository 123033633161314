import { ReactNode } from 'react';

export const symbol_ink_highlighter_move: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M617-820H310q-13 0-21.5-8.5T280-850q0-13 8.5-21.5T310-880h367l-60 60ZM457-660H190q-13 0-21.5-8.5T160-690q0-13 8.5-21.5T190-720h327l-60 60ZM297-500H70q-13 0-21.5-8.5T40-530q0-13 8.5-21.5T70-560h287l-60 60Zm307 93L489-522 267-300l115 115 222-222Zm-72-158 115 115 213-213-115-115-213 213Zm-64-21 200 200-234 234q-19 19-52.5 19T329-152l-11-11-34 34q-5 5-10 7t-11 2H156q-10 0-13.5-9.5T146-146l94-94-4-4q-22-22-21.5-55.5T237-355l231-231Zm0 0 237-237q17-17 43-17t43 17l112 112q17 17 16.5 45.5T902-620L668-386 468-586Z"
		/>
	</svg>
);
