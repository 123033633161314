import { ReactNode } from 'react';

export const symbol_no_meeting_room: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m749-319-60-60v-356h-87v269l-60-60v-254H288l-60-60h344q13 0 21.5 8.5T602-810v15h117q13 0 21.5 8.5T749-765v446Zm60 230L602-295v145q0 13-8.5 21.5T572-120H150q-13 0-21.5-8.5T120-150q0-13 8.5-21.5T150-180h62v-506L107-791q-9-9-9.5-21.5T106-834q9-9 21.5-9t21.5 9l703 703q9 9 9 21t-9 21q-9 9-21.5 9T809-89Zm-537-91h270v-176L272-626v446Zm143-473Zm-8 162Z"
		/>
	</svg>
);
