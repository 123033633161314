import { Component, Fragment } from 'react';
import styled, { keyframes, css } from 'styled-components';

import * as palette from 'pkg/config/palette';
import { small } from 'pkg/config/breakpoints';

import rgba from 'pkg/rgba';

import { FormattedContent } from 'components/formatted-content';

import PM from 'components/user/rating/PositionMap';

const PositionMap = styled(PM)`
	margin-top: 2rem;
	height: auto;
	width: 70%;
	flex: 0 0 auto;

	@media ${small} {
		width: 100%;
	}
`;

const ActiveSpot = keyframes`
	0% {
		box-shadow: inset 0 0 0 2px white,
			0 0 0 0 rgba(255, 255, 255, 1),
			0 3px 5px 2px rgba(0, 0, 0, 0.4);
	}

	50% {
		box-shadow: inset 0 0 0 2px white,
			0 0 0 15px rgba(255, 255, 255, 0),
			0 3px 5px 2px rgba(0, 0, 0, 0.4);
	}

	100% {
		box-shadow: inset 0 0 0 2px white,
			0 0 0 15px rgba(255, 255, 255, 0),
			0 3px 5px 2px rgba(0, 0, 0, 0.4);
	}
`;

const Spot = styled.div`
	width: 12%;
	height: 0;
	padding: 3% 0;
	border-radius: 1000px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
	border: 2px solid ${palette.white};
	cursor: pointer;
	background: ${rgba(palette.green[500], 0.7)};
	color: ${palette.white};
	display: inline-flex;
	justify-content: center;
	align-items: center;
	font-size: var(--font-size-sm);
	font-weight: var(--font-weight-normal);

	@media ${small} {
		font-size: 9px;
	}

	${(props) =>
		props.active &&
		css`
			background: ${palette.green[500]};
			animation: ${ActiveSpot} 1200ms ease-out infinite;
		`};
`;

const IndexWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-flow: column;
	width: 100%;
	padding: 20px 16px;

	@media ${small} {
		padding: 16px;
	}
`;

const DescriptionWrapper = styled.div`
	margin-top: 2rem;
	padding-bottom: 2rem;
	max-width: 60%;
	font-weight: var(--font-weight-normal);

	@media ${small} {
		max-width: 100%;
	}
`;

const LongName = styled.h4`
	font-weight: var(--font-weight-normal);
	font-size: var(--font-size-base);
	line-height: 1.1;
	margin: 0 0 0.75rem 0;
	padding: 0;
	color: ${palette.gray[500]};
`;

class PositionSpot extends Component {
	selectPosition = () => {
		this.props.onSelect('position', this.props.position);
	};

	render() {
		const { position } = this.props;
		return (
			<Spot
				className={position.slug}
				onClick={this.selectPosition}
				key={position.id}
				active={this.props.selectedPosition?.id == position.id}
				data-testid={`assessment_modal.position_${position.slug}`}>
				{position.name}
			</Spot>
		);
	}
}

export default class PositionComponent extends Component {
	get positionDescription() {
		if (this.props.position?.description && this.props.position?.longName) {
			return (
				<Fragment>
					<LongName>{this.props.position.longName}</LongName>
					<FormattedContent raw={this.props.position.description} />
				</Fragment>
			);
		}

		return null;
	}

	render() {
		return (
			<IndexWrapper>
				<PositionMap>
					{this.props.positions?.map((position) => (
						<PositionSpot
							position={position}
							selectedPosition={this.props.position}
							onSelect={this.props.onSelect}
							key={position.id}
							active={this.props.position?.id == position.id}
						/>
					))}
				</PositionMap>

				<DescriptionWrapper>{this.positionDescription}</DescriptionWrapper>
			</IndexWrapper>
		);
	}
}
