import { ReactNode } from 'react';

export const symbol_video_stable: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm0-60h680v-520H140v520Zm559-30 83-315q3-12-3-23t-18-14L299-732q-12-4-23 3t-14 19l-83 315q-3 12 3 23t18 14l462 130q12 4 23-3t14-19Zm-51-44L245-408l68-258 403 114-68 258Zm-508 74v-520 520Z"
		/>
	</svg>
);
