import { ReactNode } from 'react';

export const symbol_priority_high: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M479.911-120Q451-120 430.5-140.589q-20.5-20.588-20.5-49.5Q410-219 430.589-239.5q20.588-20.5 49.5-20.5Q509-260 529.5-239.411q20.5 20.588 20.5 49.5Q550-161 529.411-140.5q-20.588 20.5-49.5 20.5Zm0-240Q451-360 430.5-380.562 410-401.125 410-430v-340q0-28.875 20.589-49.438Q451.177-840 480.089-840 509-840 529.5-819.438 550-798.875 550-770v340q0 28.875-20.589 49.438Q508.823-360 479.911-360Z"
		/>
	</svg>
);
