import { ReactNode } from 'react';

export const symbol_call_missed_outgoing: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M780-571 517-308q-9 9-20 13t-22 4q-11 0-22-4t-20-13L140-601q-9-9-9-21.158 0-12.158 8.609-21Q149-652 161.467-652q12.466 0 21.533 9l292 292 266-266H559q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T559-677h251q12.75 0 21.375 8.625T840-647v258q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T780-389v-182Z"
		/>
	</svg>
);
