import { ReactNode } from 'react';

export const symbol_switches: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M270-289q-80 0-135-54.5T80-479q0-81 54.5-135.5T270-669q51 0 90 22t65 63h350q43.75 0 74.375 30.677Q880-522.647 880-478.824 880-435 849.375-404.5T775-374H425q-26 41-65 63t-90 22Zm3-190Zm180 45h322q20 0 32.5-12.5T820-479q0-20-12.5-32.5T775-524H453q4 10 5.5 22t1.5 23q0 11-1.5 23t-5.5 22Zm-183 85q55 0 92.5-37.5T400-479q0-55-37.5-92.5T270-609q-55 0-92.5 37.5T140-479q0 55 37.5 92.5T270-349Z"
		/>
	</svg>
);
