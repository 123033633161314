import {
	Fragment,
	useState,
	useEffect,
	useMemo,
	useRef,
	memo,
	ReactNode,
	MutableRefObject,
	useContext,
	createContext,
} from 'react';
import { t } from '@transifex/native';

import { eventTypeLabels } from 'pkg/models/event';
import { Features } from 'pkg/models/group';

import * as endpoints from 'pkg/api/endpoints/auto';
import {
	startOfMonth,
	endOfMonth,
	getStartOfYear,
	getEndOfYear,
	startOfWeekTimestamp,
	endOfWeekTimestamp,
} from 'pkg/date';
import DateTime, { Calendar, CalendarMonthType } from 'pkg/datetime';
import { pushState, replaceState } from 'pkg/router/state';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { cssClasses } from 'pkg/css/utils';
import { useQueryState } from 'pkg/hooks/query-state';
import {
	useCurrentAccount,
	useCurrentGroup,
	useCurrentMembership,
	useCurrentOrganization,
	useCurrentUser,
} from 'pkg/identity';
import * as models from 'pkg/api/models';
import { CollectionResponse, useCollection } from 'pkg/api/use_collection';
import useMixedState, { MixedStateSetter } from 'pkg/hooks/useMixedState';
import { EventTypes } from 'pkg/api/models/event';
import { useWardsInCurrentGroup } from 'pkg/hooks/useWardsInCurrentGroup';
import { link } from 'pkg/router/utils';
import * as routes from 'pkg/router/routes';

import MonthView from 'routes/group/calendar/views/month';
import YearView from 'routes/group/calendar/views/year';
import WeekView from 'routes/group/calendar/views/week';
import DayView from 'routes/group/calendar/views/day';
import ScheduleView from 'routes/group/calendar/views/schedule';
import CalendarActionbar from 'routes/group/calendar/components/action-bar';
import { CreateEventDragContextProvider } from 'routes/group/calendar/hooks/useCreateEventDrag';
import BookingsView from 'routes/group/calendar/views/Bookings';
import {
	calendarDefaultFilters,
	CalendarFilterState,
	CalendarTypes,
} from 'routes/group/calendar/filters';
import { defaultFilters } from 'routes/scheduling/templates/filters';
import { getCalendarTimestamp } from 'routes/group/calendar/utils';

import { SmallScreen, LargeScreen } from 'components/MediaQuery';

import { MaterialSymbolVariant } from 'components/material-symbols/symbols';
import SmallScreenHeader from 'components/navigation/header/small_screen';
import LargeScreenHeader from 'components/navigation/header/large_screen';
import * as LargeLayout from 'components/navigation/header/large_screen/Styles';
import * as SmallLayout from 'components/navigation/header/small_screen/Styles';
import SmallScreenContent from 'components/layout/SmallScreenContent';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import { ButtonTrigger } from 'components/navigation/header/small_screen/page_actions/ButtonTrigger';
import EventTypeContextMenuItem from 'components/event/TypeContextMenuItem';
import { Spinner } from 'components/loaders/spinner';

import * as ContextMenu from 'design/context_menu';

import * as calendarStyles from './styles.css';

export enum ViewOptions {
	Year = 'year',
	Month = 'month',
	Week = 'week',
	Day = 'day',
	Schedule = 'schedule',
	Bookings = 'bookings',
}

export interface CalendarStateProps {
	// Button to show/hide in the action bar for the schedule view
	showToday: boolean;

	// Date we use in the schedule view
	scheduleDate: Date;
}

interface CalendarContextProps {
	eventTypes?: EventTypes[];
	currentTab?: ViewOptions;
	calendar?: CalendarMonthType;

	// This is the element that is beeing scrolled in the calendar
	scrollItemRef: MutableRefObject<HTMLDivElement>;

	eventsCollection: CollectionResponse<models.event.Event>;

	calendarState: CalendarStateProps;
	setCalendarState: MixedStateSetter<CalendarStateProps>;
}

export const CalendarContext = createContext<CalendarContextProps>({
	currentTab: ViewOptions.Schedule,
	calendar: null,
	scrollItemRef: null,
	eventsCollection: null,
	calendarState: {
		showToday: false,
		scheduleDate: new Date(),
	},
	setCalendarState: null,
	eventTypes: [],
});

export function useCalendarContext() {
	return useContext(CalendarContext);
}

export interface DayProps {
	calculateHeight: boolean;
	calulateOverlap: boolean;
	currentMode: string;
	fixedHeight: boolean;
	showSchedule: boolean;
	timestamp: number;
	children: ReactNode | ReactNode[];
	scrollToClosest?: boolean;
}

export interface ViewProps {
	to?: number;
	from?: number;
	changeView?: (timestamp: number, view: string) => void;
	currentDate: number;
	currentView?: string;
	children?: ReactNode | ReactNode[];
	scrollItemRef?: MutableRefObject<HTMLDivElement>;
	currentAccount?: models.account.Account;
	groupId?: number;
}

interface PageProps {
	groupId: number;
	organizationId?: any;
	currentTab: ViewOptions;
	title: string;
	icon: MaterialSymbolVariant;
}

const Page = memo(
	({ groupId, currentTab, title, icon }: PageProps): JSX.Element => {
		const qs = useQueryState();
		const organizationId = useCurrentOrganization().id;

		let qsFilters = qs.get('filters');

		const queryFilters =
			qsFilters && qsFilters !== 'null'
				? (JSON.parse(qsFilters as string) as CalendarFilterState)
				: calendarDefaultFilters;

		const localStorageFilters = localStorage.getItem('calendarFilters');

		if (currentTab === ViewOptions.Bookings) {
			qsFilters =
				localStorageFilters || JSON.stringify({ ...calendarDefaultFilters });
		}

		const timestamp = getCalendarTimestamp(qs);

		const eventTypes = queryFilters.types;
		const calendars = queryFilters.calendars;

		const showMyCalendar = calendars.includes(CalendarTypes.My);
		const showGroupCalendar = calendars.includes(CalendarTypes.Group);

		const groupsString = queryFilters.groups
			? queryFilters.groups.toString()
			: '';

		const groups =
			groupsString !== '' ? groupsString.split(',').map(Number) : [];

		const currentUser = useCurrentUser();
		const wardUsers = useWardsInCurrentGroup();

		const currentGroup = useCurrentGroup();
		const isOrganization = models.group.isOrganization(currentGroup);
		const activeAccount = useCurrentAccount();
		const activeMembership = useCurrentMembership();
		const isAdminOrStaff = models.membership.isAdminOrStaff(activeMembership);

		const [isSettingFilters, setIsSettingFilters] = useState(true);

		const organization = useCurrentOrganization();

		const wardUserIds = wardUsers.map((u) => u.id) || [];
		const wardCalendars = queryFilters.wardUserIds || wardUserIds;

		const [calendarState, setCalendarState] = useMixedState<CalendarStateProps>(
			{
				showToday: false,
				scheduleDate: new Date(),
			}
		);

		const calendar = useMemo(() => {
			if (currentTab === ViewOptions.Month) {
				const d = new Date(Math.round(timestamp * 1000));
				return Calendar.generate(d.getFullYear(), d.getMonth() + 1);
			}

			return null;
		}, [timestamp, currentTab]);

		const sessionChangeDate = (date: string | number) => {
			sessionStorage.setItem('calendarTimestamp', date.toString());
			qs.set('timestamp', date.toString());
			qs.commit();
		};

		const sessionChangeMode = (mode: string) =>
			sessionStorage.setItem('currentMode', mode);

		const changeView = (currentTimestamp: number, view: string) => {
			sessionChangeDate(currentTimestamp / 1000);
			if (view !== ViewOptions.Schedule) {
				sessionChangeMode(view);
			}

			pushState(routes.Calendar.Index(organizationId, groupId, view), {
				timestamp: (currentTimestamp / 1000).toString(),
				filters: qs.get('filters'),
			});
		};

		const getStartAndEndDates: { from: number; to?: number } = useMemo(() => {
			const yearDates = {
				from: Math.round(getStartOfYear(timestamp) / 1000),
				to: Math.round(getEndOfYear(timestamp) / 1000),
			};

			const monthDates = {
				from: calendar
					? Math.ceil(
							DateTime.fromTimestamp(Math.round(calendar[0].timestamp / 1000))
								.startOfDay / 1000
						)
					: Math.round(startOfMonth(timestamp) / 1000),
				to: calendar
					? Math.floor(
							DateTime.fromTimestamp(
								Math.round(calendar[calendar.length - 1].timestamp / 1000)
							).endOfDay / 1000
						)
					: Math.round(endOfMonth(timestamp) / 1000),
			};
			const weekDates = {
				from: Math.round(startOfWeekTimestamp(timestamp) / 1000),
				to: Math.round(endOfWeekTimestamp(timestamp) / 1000),
			};
			const scheduleDates = {
				from: Math.round(
					new DateTime(calendarState.scheduleDate).startOfDay / 1000
				),
			};

			switch (currentTab) {
				case 'year':
					return yearDates;
				case 'month':
					return monthDates;
				case 'day':
				case 'week':
					return weekDates;
				case 'schedule':
					return scheduleDates;
				case 'bookings':
					return weekDates;
			}
		}, [timestamp, currentTab, calendarState.scheduleDate, calendar]);

		const searchParams: { [key: string]: string } = {
			from: getStartAndEndDates.from.toString(),
			to: getStartAndEndDates.to ? getStartAndEndDates.to?.toString() : '',
			user_ids: showMyCalendar
				? [currentUser.id, ...wardCalendars].toString()
				: wardCalendars.toString(),
			group_ids: showGroupCalendar
				? [groupId, ...groups].toString()
				: groups.toString(),
		};

		const eventsCollection = useCollection<models.event.Event>(
			!isSettingFilters ? endpoints.Events.Index() : '',
			{
				queryParams: new URLSearchParams(searchParams),
				showAllResults: true,
			}
		);

		if (
			eventTypes.length > 0 &&
			eventTypes.length !== Object.keys(EventTypes).length
		) {
			// This can be removed once we have type filter in the endpoint
			eventsCollection.records = eventsCollection.records.filter((r) =>
				eventTypes.includes(r.type)
			);
		}

		const scrollValue = useMemo(() => {
			if (currentTab === ViewOptions.Week || currentTab === ViewOptions.Day) {
				return 520;
			}
		}, [currentTab]);

		const scrollItemRef = useRef(null);

		useEffect(() => {
			setTimeout(() => {
				if (scrollItemRef.current) {
					scrollItemRef.current.scrollTo(0, scrollValue);
				}
			}, 0);
		}, [scrollValue]);

		useComponentDidMount(() => {
			let t = currentTab;

			if (currentTab === ViewOptions.Bookings && isOrganization) {
				t = ViewOptions.Month;
			}

			replaceState(routes.Calendar.Index(organizationId, groupId, t));

			const filters: CalendarFilterState = {
				...calendarDefaultFilters,
				wardUserIds,
			};

			const localFilters: CalendarFilterState = JSON.parse(localStorageFilters);

			if (localStorageFilters !== null) {
				// Check if the localStorage exists, contains any wardUserIds data and if user has wards
				if (!localFilters?.wardUserIds && wardUserIds.length) {
					localFilters.wardUserIds = wardUserIds;
				}

				// Check if there's any ward account ids in the local storage that don't exist in wardAccountIds
				localFilters.wardUserIds = localFilters.wardUserIds?.filter((id) =>
					wardUserIds.includes(id)
				);

				// If wardAccountIds are empty we reset filter
				if (wardUserIds.length === 0) {
					localFilters.wardUserIds = [];
				}
			}

			const f = localStorageFilters !== null ? localFilters : filters;

			if (
				activeMembership.isOrganizationMembership &&
				f.calendars.includes(CalendarTypes.Group)
			) {
				f.calendars = f.calendars.filter((c) => c !== CalendarTypes.Group);
			}

			if (currentTab !== ViewOptions.Bookings) {
				qs.setAll({
					timestamp: timestamp.toString(),
					filters: JSON.stringify({ ...f }),
				});
				qs.commit();
			}

			setIsSettingFilters(false);
		});

		useEffect(() => {
			sessionStorage.setItem('calendarTimestamp', timestamp.toString());
		}, [timestamp]);

		useEffect(() => {
			sessionStorage.setItem('currentMode', currentTab);

			if (ViewOptions.Bookings === currentTab) {
				// Sets the default filters for the booking view
				qs.setAll({
					timestamp: timestamp.toString(),
					schedulingFilters: JSON.stringify({
						...defaultFilters,
						groups: [groupId],
					}),
				});
				qs.commit();
			}
		}, [currentTab]);

		const currentTimestampStartOfDay = new DateTime(new Date(timestamp * 1000))
			.startOfDay;

		const getView = () => {
			switch (currentTab) {
				case ViewOptions.Year:
					return YearView;
				case ViewOptions.Month:
					return MonthView;
				case ViewOptions.Week:
					return WeekView;
				case ViewOptions.Day:
					return DayView;
				case ViewOptions.Schedule:
					return ScheduleView;
				case ViewOptions.Bookings:
					return BookingsView;
			}
		};

		const View = getView();

		const content =
			currentTab === ViewOptions.Bookings ? (
				<View
					currentDate={currentTimestampStartOfDay}
					from={getStartAndEndDates.from}
					to={getStartAndEndDates.to}
					groupId={groupId}
				/>
			) : (
				<Fragment>
					<div ref={scrollItemRef} className={calendarStyles.wrapper}>
						<div
							className={cssClasses(
								calendarStyles['page-wrap'],
								currentTab === ViewOptions.Month
									? calendarStyles['fill-height']
									: ''
							)}>
							<CreateEventDragContextProvider>
								<View
									from={getStartAndEndDates.from}
									to={getStartAndEndDates.to}
									changeView={changeView}
									currentDate={currentTimestampStartOfDay}
									currentView={currentTab}
									scrollItemRef={scrollItemRef}
									currentAccount={activeAccount}
								/>
							</CreateEventDragContextProvider>
						</div>
					</div>
				</Fragment>
			);

		const actionBar =
			currentTab !== ViewOptions.Bookings ? (
				<CalendarActionbar
					currentTab={currentTab}
					getStartAndEndDates={getStartAndEndDates}
					timestamp={timestamp}
					sessionChangeDate={sessionChangeDate}
				/>
			) : null;

		if (isSettingFilters) {
			return <Spinner />;
		}

		return (
			<CalendarContext.Provider
				value={{
					eventsCollection,
					scrollItemRef,
					calendarState,
					setCalendarState,
					eventTypes,
					currentTab,
					calendar,
				}}>
				<Fragment>
					<LargeScreen>
						<LargeScreenHeader title={title} icon={icon}>
							<LargeLayout.SubNavItem
								href={link(
									routes.Calendar.Index(organization.id, groupId, 'schedule'),
									{ timestamp, filters: qsFilters }
								)}
								data-testid="calendar.schedule"
								onClick={() => sessionChangeMode('schedule')}>
								{t('Schedule')}
							</LargeLayout.SubNavItem>
							<LargeLayout.SubNavItem
								href={link(
									routes.Calendar.Index(organization.id, groupId, 'day'),
									{ timestamp, filters: qsFilters }
								)}
								data-testid="calendar.day"
								onClick={() => sessionStorage.setItem('currentMode', 'day')}>
								{t('Day')}
							</LargeLayout.SubNavItem>
							<LargeLayout.SubNavItem
								href={link(
									routes.Calendar.Index(organization.id, groupId, 'week'),
									{ timestamp, filters: qsFilters }
								)}
								data-testid="calendar.week"
								onClick={() => sessionStorage.setItem('currentMode', 'week')}>
								{t('Week')}
							</LargeLayout.SubNavItem>
							<LargeLayout.SubNavItem
								href={link(
									routes.Calendar.Index(organization.id, groupId, 'month'),
									{ timestamp, filters: qsFilters }
								)}
								data-testid="calendar.month"
								onClick={() => sessionStorage.setItem('currentMode', 'month')}>
								{t('Month')}
							</LargeLayout.SubNavItem>
							<LargeLayout.SubNavItem
								href={link(
									routes.Calendar.Index(organization.id, groupId, 'year'),
									{ timestamp, filters: qsFilters }
								)}
								data-testid="calendar.year"
								onClick={() => sessionStorage.setItem('currentMode', 'year')}>
								{t('Year')}
							</LargeLayout.SubNavItem>

							{models.group.hasFeature(organization, Features.Scheduling) &&
								!isOrganization &&
								isAdminOrStaff && (
									<LargeLayout.SubNavItem
										href={link(
											routes.Calendar.Index(
												organization.id,
												groupId,
												'bookings'
											),
											{ timestamp }
										)}>
										{t('Bookings')}
									</LargeLayout.SubNavItem>
								)}
						</LargeScreenHeader>

						<LargeScreenContent.Wrapper key={currentTab} disableScroll>
							{actionBar}
							{content}
						</LargeScreenContent.Wrapper>
					</LargeScreen>

					<SmallScreen>
						<SmallScreenHeader
							title={title}
							actionTrigger={
								isAdminOrStaff && (
									<ContextMenu.Menu toggleWith={<ButtonTrigger icon="add" />}>
										{eventTypeLabels.map((eventType) => (
											<EventTypeContextMenuItem
												eventType={eventType}
												key={eventType}
												startsAt={
													currentTab !== ViewOptions.Schedule &&
													(timestamp + 32400).toString()
												}
												endsAt={
													currentTab !== ViewOptions.Schedule &&
													(timestamp + 36000).toString()
												}
											/>
										))}
									</ContextMenu.Menu>
								)
							}
						/>

						<SmallScreenContent noScroll>
							<SmallLayout.SubNav>
								<SmallLayout.SubNavItem
									href={link(
										routes.Calendar.Index(organization.id, groupId, 'schedule'),
										{ timestamp, filters: qsFilters }
									)}
									data-testid="calendar.schedule"
									onClick={() => sessionChangeMode('schedule')}>
									{t('Schedule')}
								</SmallLayout.SubNavItem>{' '}
								<SmallLayout.SubNavItem
									href={link(
										routes.Calendar.Index(organization.id, groupId, 'day'),
										{ timestamp, filters: qsFilters }
									)}
									data-testid="calendar.day"
									onClick={() => sessionStorage.setItem('currentMode', 'day')}>
									{t('Day')}
								</SmallLayout.SubNavItem>{' '}
								<SmallLayout.SubNavItem
									href={link(
										routes.Calendar.Index(organization.id, groupId, 'week'),
										{ timestamp, filters: qsFilters }
									)}
									data-testid="calendar.week"
									onClick={() => sessionStorage.setItem('currentMode', 'week')}>
									{t('Week')}
								</SmallLayout.SubNavItem>{' '}
								<SmallLayout.SubNavItem
									href={link(
										routes.Calendar.Index(organization.id, groupId, 'month'),
										{ timestamp, filters: qsFilters }
									)}
									data-testid="calendar.month"
									onClick={() =>
										sessionStorage.setItem('currentMode', 'month')
									}>
									{t('Month')}
								</SmallLayout.SubNavItem>
								<SmallLayout.SubNavItem
									href={link(
										routes.Calendar.Index(organization.id, groupId, 'year'),
										{ timestamp, filters: qsFilters }
									)}
									data-testid="calendar.year"
									onClick={() => sessionStorage.setItem('currentMode', 'year')}>
									{t('Year')}
								</SmallLayout.SubNavItem>
								{models.group.hasFeature(organization, Features.Scheduling) &&
									!isOrganization &&
									isAdminOrStaff && (
										<SmallLayout.SubNavItem
											href={link(
												routes.Calendar.Index(
													organization.id,
													groupId,
													'bookings'
												),
												{ timestamp }
											)}>
											{t('Bookings')}
										</SmallLayout.SubNavItem>
									)}
							</SmallLayout.SubNav>
							{actionBar}
							{content}
						</SmallScreenContent>
					</SmallScreen>
				</Fragment>
			</CalendarContext.Provider>
		);
	}
);

export default Page;
