import { ReactNode } from 'react';

export const symbol_co_present: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M860-131v-649H100v290q0 12.75-8.675 21.375Q82.649-460 69.825-460 57-460 48.5-468.625T40-490v-290q0-24.75 17.625-42.375T100-840h760q24.75 0 42.375 17.625T920-780v580q0 26-17 45.5T860-131ZM360-401q-66 0-108-42t-42-108q0-66 42-108t108-42q66 0 108 42t42 108q0 66-42 108t-108 42Zm0-60q39 0 64.5-25.5T450-551q0-39-25.5-64.5T360-641q-39 0-64.5 25.5T270-551q0 39 25.5 64.5T360-461ZM100-80q-24.75 0-42.375-17.625T40-140v-34q0-38 19-65t49-41q67-30 128.5-45T360-340q62 0 123 15.5t127.921 44.694q31.301 14.126 50.19 40.966Q680-212 680-174v34q0 24.75-17.625 42.375T620-80H100Zm0-60h520v-34q0-16-9.5-30.5T587-226q-64-31-117-42.5T360-280q-57 0-111 11.5T132-226q-14 7-23 21.5t-9 30.5v34Zm260-411Zm0 411Z"
		/>
	</svg>
);
