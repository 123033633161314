/**
 * Functions to get commonly used error translations
 */

import { t } from '@transifex/native';

interface ErrorInfo {
	title: string;
	description: string;
}
export function crash(): ErrorInfo {
	return {
		title: t('Something went wrong', { _context: 'errors' }),
		description: t(
			'The issue has been registered. If this keeps happening, please contact us at support@360player.com',
			{ _context: 'errors' }
		),
	};
}

export class ApiError {
	code: number;

	constructor(code: number) {
		this.code = code;
	}

	get title() {
		switch (this.code) {
			case 404:
				return t('Page not found');
			case 403:
				return t('Forbidden');
			case 500:
				return t('Something went wrong');
		}

		return t('Something went wrong');
	}

	get body() {
		switch (this.code) {
			case 404:
				return t('The requested page could not be found.');
			case 403:
				return t(
					'You do not have access to this resource. If you have multiple 360Player accounts, please make sure you are logged in to the correct one.'
				);
			case 500:
				return t(
					'This error has been logged and reported, please contact 360Player support if it keeps happening.'
				);
		}

		return t(
			`If you think this error isn't supposed to happen, please contact 360Player support.`
		);
	}
}
