import { ReactNode } from 'react';

export const symbol_add_task: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q60.086 0 117.043 17.5Q654-845 704-811q11 7 13.5 19t-5.5 22q-8 10-20 11t-23-6q-42-27-90-41t-99-14q-142.375 0-241.188 98.812Q140-622.375 140-480t98.913 241.188Q337.827-140 480.347-140 508-140 535-144.5t53-12.5q13-4 24.5.5T629-140q5 12-.5 23.5T611-101q-32 11-65 16t-66 5Zm290-210h-90q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T680-350h90v-90q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T830-440v90h90q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T920-290h-90v90q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T770-200v-90Zm-349-99 392-392q8.556-9 21.778-9T858-780.5q10 9.5 10 22.5t-10 23L442-319q-9 9-21 9t-21-9L278-442q-9-9-8.5-22.5t9.339-22.5q8.84-9 22.5-9Q315-496 324-487l97 98Z"
		/>
	</svg>
);
