const reload = {
	name: 'reload',
	objects: [
		{
			type: 'path',
			attributes: {
				d: 'M18.581 9.5332L15.1503 12.9639H17.7233C17.7233 14.3287 17.1812 15.6376 16.2161 16.6026C15.251 17.5677 13.9421 18.1099 12.5773 18.1099C11.7197 18.1099 10.8877 17.8955 10.1759 17.5095L8.92367 18.7617C9.9786 19.4307 11.2308 19.8252 12.5773 19.8252C14.3971 19.8252 16.1423 19.1023 17.429 17.8156C18.7158 16.5288 19.4387 14.7836 19.4387 12.9639H22.0117L18.581 9.5332ZM7.43133 12.9639C7.43133 11.5991 7.9735 10.2902 8.93856 9.32509C9.90362 8.36003 11.2125 7.81787 12.5773 7.81787C13.435 7.81787 14.2669 8.03228 14.9788 8.41823L16.231 7.16604C15.1761 6.49706 13.9239 6.10253 12.5773 6.10253C10.7576 6.10253 9.01238 6.82542 7.72563 8.11217C6.43888 9.39892 5.71599 11.1441 5.71599 12.9639H3.14299L6.57366 16.3945L10.0043 12.9639',
			},
		},
	],
};
export default reload;
