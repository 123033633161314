import { Fragment, useContext, useState } from 'react';
import { t } from '@transifex/native';

import { eventTypeToTranslatedString } from 'pkg/models/event';

import * as flashActions from 'pkg/actions/flashes';

import * as models from 'pkg/api/models';
import { cssClasses } from 'pkg/css/utils';

import {
	ConflictTypes,
	getItemResources,
	getWeekDaysOrder,
} from 'routes/scheduling/utils';
import EditScheduleItem from 'routes/scheduling/templates/modals/EditScheduleItem';
import { TemplateContext } from 'routes/scheduling/templates/single';

import Icon from 'components/icon';
import Label from 'components/label';
import * as CardList from 'components/card-list';

import * as Context from 'design/context_menu';
import Button from 'design/button';

import * as css from './styles.css';

const _context = 'scheduling/bookings';

interface ScheduleCardListItemProps {
	conflicts?: ConflictTypes;

	scheduleItem: models.scheduleItem.ScheduleItem;
}

const ScheduleCardListItem = ({
	scheduleItem,
	conflicts,
}: ScheduleCardListItemProps) => {
	const [modal, setModal] = useState('');
	const templateContext = useContext(TemplateContext);

	const scheduleDays = getWeekDaysOrder(
		templateContext.schedule.startingDay,
		templateContext.schedule.days,
		true
	);
	const weekDay = scheduleDays[scheduleItem.day - 1];
	const resources = getItemResources(scheduleItem);

	const showEditModal = () => setModal('edit');
	const hideModal = () => setModal('');

	const handleRemove = async () => {
		const ok = await models.scheduleItem.remove(scheduleItem);

		if (ok) {
			flashActions.show({
				title: t('Successfully removed booking', {
					_context,
				}),
			});
		}
	};

	return (
		<Fragment>
			<CardList.Base className={css['card-base']}>
				<CardList.Image>
					<CardList.IconWrapper caution={!!conflicts}>
						<Icon name="calendar-clean" />
					</CardList.IconWrapper>
				</CardList.Image>
				<CardList.Title>
					<div>
						{weekDay}, {scheduleItem.startsAt} - {scheduleItem.endsAt} ·{' '}
						<div className={css['title-thin']}>
							{scheduleItem.title}{' '}
							{scheduleItem.group && `(${scheduleItem.group.name})`}
						</div>
					</div>
					<CardList.SubTitle className={css.description}>
						{scheduleItem.resource?.location && (
							<div className={css.location}>
								<Icon name="location" actualSize size={1.5} />
								{scheduleItem.resource.location.title}
							</div>
						)}
						{!!resources.length &&
							resources.map((r) => (
								<div
									key={r.id}
									className={cssClasses(
										css.resource,
										conflicts?.resourceConflicts.includes(r.id)
											? css.conflicting
											: ''
									)}>
									{r.title}
								</div>
							))}
					</CardList.SubTitle>
				</CardList.Title>
				<CardList.Col>
					<Label eventType={scheduleItem.type}>
						{eventTypeToTranslatedString(scheduleItem.type)}
					</Label>
				</CardList.Col>
				<CardList.Actions>
					<Context.Menu toggleWith={<Button icon="more_horiz" secondary />}>
						<Context.Item onClick={showEditModal}>
							<Context.ItemIcon name="edit" />
							{t('Edit')}
						</Context.Item>
						<Context.ConfirmItem
							caution
							onConfirm={handleRemove}
							message={t(`Are you sure you want to delete this booking?`)}>
							<Context.ItemIcon name="delete" />
							{t('Remove')}
						</Context.ConfirmItem>
					</Context.Menu>
				</CardList.Actions>
			</CardList.Base>
			{modal === 'edit' && (
				<EditScheduleItem
					schedule={templateContext.schedule}
					scheduleItem={scheduleItem}
					scheduleItems={templateContext.scheduleItems}
					hideModal={hideModal}
				/>
			)}
		</Fragment>
	);
};

export default ScheduleCardListItem;
