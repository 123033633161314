export function shallowEquals<T>(a: T, b: T): boolean {
	if (!a || !b) return false;

	if (Object.keys(a).length !== Object.keys(b).length) {
		return false;
	}

	for (const key in a) {
		if (!Array.isArray(a[key]) && a[key] !== b[key]) {
			return false;
		}
	}

	return true;
}

type ShallowCompareResultKeys = 'inserts' | 'updates' | 'deletes';

export function shallowCompare<T extends object>(
	a: T,
	b: T
): Record<ShallowCompareResultKeys, T> {
	const inserts: T = Object.keys(b).reduce((src: T, key: string) => {
		if (!(key in a)) {
			src[key as keyof T] = b[key as keyof T];
		}

		return src;
	}, {} as T);

	const updates: T = Object.keys(b).reduce((src: T, key: string) => {
		if (key in a && b[key as keyof T] !== a[key as keyof T]) {
			src[key as keyof T] = b[key as keyof T];
		}

		return src;
	}, {} as T);

	const deletes: T = Object.keys(a).reduce((src: T, key: string) => {
		if (!(key in b)) {
			src[key as keyof T] = a[key as keyof T];
		}

		return src;
	}, {} as T);

	return {
		inserts,
		updates,
		deletes,
	};
}

export function unique<T>(items: T[], key: keyof T): T[] {
	return Object.values(
		items.reduce(
			(a: T, o: T) => ({ ...a, [o[key] as unknown as keyof T]: o }) as T,
			{}
		)
	) as T[];
}

export function empty<T>(object: T): boolean {
	return !!object && Object.keys(object).length === 0;
}

/**
 * omit
 *
 * Omits keys from object
 *
 * @param source object
 * @param keys list of keys to pick
 */
export function omit<T, K extends keyof T>(
	source: T,
	...keys: K[]
): Omit<T, K> {
	const result = Object.assign({}, source);

	keys.forEach((key) => delete result[key]);

	return result as any as Omit<T, K>;
}

/**
 * only
 *
 * Only picks selected keys from source object
 *
 * @param source object
 * @param keys list of keys to pick
 */
export function only<T, K extends keyof T>(
	source: T,
	...keys: K[]
): Pick<T, K> {
	return keys.reduce(
		(memo, key) => ({ ...memo, [key]: source[key] }),
		{}
	) as Pick<T, K>;
}

interface RecursiveKeysOfOptions<T> {
	key: T;
	on: T;
	includeRoot?: boolean;
}

/**
 *	Recursively returns the value of a key in deep nested objects.
 *
 *	@param source
 *	@param options
 *
 *	@returns
 */
export function recursiveKeysOf<T, K extends keyof T, R = K>(
	source: T,
	options: RecursiveKeysOfOptions<K>
): R[] {
	const keys: R[] = [];

	if (!source) return keys;

	if (source?.[options.key] && options?.includeRoot === true) {
		keys.push(source[options.key] as unknown as R);
	}

	if (source?.[options.on]) {
		const children =
			Object.values(source?.[options.on] as unknown as T[]) || [];

		for (const child of children) {
			keys.push(
				...recursiveKeysOf<T, K, R>(child, { ...options, includeRoot: true })
			);
		}
	}

	return keys;
}

/**
 *	Filters out a single element from an array of objects
 *
 *	@param items
 *	@param key
 *	@param value
 *
 *	@returns
 */
export function filterByKey<T>(source: T, keyword: string): Partial<T> {
	if (keyword.length === 0) {
		return source;
	}

	return Object.keys(source)
		.filter((key) => key.includes(keyword))
		.reduce(
			(target: any, key) => ({
				...target,
				[key]: source[key as keyof T],
			}),
			{}
		);
}

/**
 *	Returns the difference between to arrays of objects.
 *
 *	@param a
 *	@param b
 *	@param key
 *
 *	@returns
 */
export function diff<T>(a: T[], b: T[], key: keyof T): T[] {
	return a.filter((t: T) => b.findIndex((n: T) => n[key] === t[key]) === -1);
}

/**
 *	Behaves like array.map, but for objects without mutating the source object. This method is generic, where T is source type, R is return type.
 *	The mutator return type must be typed.
 *
 *	@param source
 *	@param mutator
 *
 *	@example Uppercase all object values
 *	`map({ foo: 'foo' }, (value): string => value.toUpperCase())`
 *
 *	@returns object
 */
export function map<T extends object, R = T>(
	source: T,
	mutator: (value: T[keyof T], key: keyof T) => unknown
): R {
	return Object.keys(source).reduce(
		(target: R, key) => ({
			...target,
			[key]: mutator(source[key as keyof T], key as keyof T),
		}),
		{} as R
	);
}

export function serialize<T extends object>(source: T): string {
	return Object.keys(source)
		.map((key: string) => {
			let value: string;
			const sourceValue = source[key as keyof typeof source];

			if (sourceValue !== null && typeof sourceValue === 'object') {
				value = serialize(sourceValue);
			} else {
				value = sourceValue as string;
			}

			if (!value) {
				return '';
			}

			value = encodeURIComponent(value?.toString().replace(/\s/g, '_'));

			return `${key}=${value}`;
		})
		.join('&');
}

export function deepCompareObjects<T extends { [key: string]: any }>(
	obj1: T,
	obj2: T,
	visited = new Set()
): boolean {
	if (!obj1 || !obj2) {
		return false;
	}

	if (obj1 === obj2) {
		return true;
	}

	if (visited.has(obj1) || visited.has(obj2)) {
		return true;
	}

	if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
		return false;
	}

	const keys1 = Object.keys(obj1);
	const keys2 = Object.keys(obj2);
	if (keys1.length !== keys2.length) {
		return false;
	}

	visited.add(obj1);
	visited.add(obj2);

	for (const key of keys1) {
		const value1 = obj1[key];
		const value2 = obj2[key];

		// Check if the values are functions
		if (typeof value1 === 'function' && typeof value2 === 'function') {
			if (value1.toString() !== value2.toString()) {
				return false;
			}
		} else if (!deepCompareObjects(obj1[key], obj2[key], visited)) {
			return false;
		}
	}

	return true;
}
