import { ReactNode } from 'react';

export const symbol_woman: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M420-110v-190h-79q-15 0-24.5-12.5T312-339l90-289q8-25 30-38.5t48-13.5q26 0 48 13.5t30 38.5l90 289q5 14-4.5 26.5T619-300h-79v190q0 12.75-8.646 21.375T509.927-80h-60.146Q437-80 428.5-88.625 420-97.25 420-110Zm60.08-624q-30.08 0-51.58-21.42-21.5-21.421-21.5-51.5 0-30.08 21.42-51.58 21.421-21.5 51.5-21.5 30.08 0 51.58 21.42 21.5 21.421 21.5 51.5 0 30.08-21.42 51.58-21.421 21.5-51.5 21.5Z"
		/>
	</svg>
);
