import { Fragment, ChangeEvent, useState } from 'react';
import { t } from '@transifex/native';

import { Account } from 'pkg/api/models/account';
import * as actions from 'pkg/actions';
import * as models from 'pkg/api/models';

import AccountCard from 'routes/account/settings/privacy/AccountCard';

import InfoBox from 'components/form/info-box';
import * as Inputs from 'components/form/inputs';
import Column from 'components/layout/column';
import Row from 'components/layout/row';

import Button from 'design/button';
interface AccountDeleteFormProps {
	account: Account;
	onClose: () => void;
	onDeletion?: () => void;
	setAccountDeleted: (account: Account) => void;
}

export default function AccountDeleteForm({
	account,
	onClose,
	onDeletion,
	setAccountDeleted,
}: AccountDeleteFormProps): JSX.Element {
	const [confirmText, setConfirmText] = useState('');

	const handleSetConfirmText = (event: ChangeEvent<HTMLInputElement>) => {
		setConfirmText(event.target.value);
	};

	const handleDeleteAccount = async () => {
		const confirm = window.confirm(t(`Are you sure?`));

		if (!confirm) return;

		const deletedAccount = await models.account.remove(account);

		if (!deletedAccount) {
			actions.flashes.show({
				title: t(`Account deletion failed`),
				message: t(`Something went wrong, please try again.`),
			});

			return;
		}

		if (onDeletion) {
			await onDeletion();
		}

		setAccountDeleted(deletedAccount);
	};

	const deleteText = t(`delete`).toUpperCase();

	return (
		<Fragment>
			<InfoBox color="red">
				{t(
					`You are about to permanently delete this account. When confirmed you will not be able to recover this account or any data.`
				)}
			</InfoBox>
			<AccountCard account={account} />
			<Column>
				<Inputs.Group
					label={t(
						`Type the text "{text}" to confirm the deletion of this account.`,
						{ text: deleteText }
					)}>
					<Inputs.Field onChange={handleSetConfirmText} name="delete-text" />
				</Inputs.Group>
			</Column>
			<Row columns="auto auto 1fr" align="center">
				<Button label={t('Cancel')} onClick={onClose} />
				<Button
					label={t(`Confirm delete`)}
					caution
					disabled={deleteText !== confirmText}
					onClick={handleDeleteAccount}
					testid="delete_account_confirm_button"
				/>
			</Row>
		</Fragment>
	);
}
