import {
	Filter,
	FilterGroups,
	FilterType,
	FilterValue,
} from 'pkg/filters/use_filters';
import * as objects from 'pkg/objects';

export enum FilterOperator {
	Equals = 'equals',
	Contains = 'contains',
	NotEquals = 'not_equals',
	Between = 'between',
	Includes = 'includes',
	Excludes = 'excludes',
	GreaterThan = 'greater_than',
	LessThan = 'less_than',
	HasProperty = 'has_property',
	NotHasProperty = 'not_has_property',
	Set = 'set',
	NotSet = 'not_set',
}

export enum PredefinedFilterId {
	GroupRole = 'f_group_role',
}

export interface QueryFilterObject {
	[key: string]: QueryFilter;
}

export interface QueryFilter {
	id: string;
	property?: string;
	type?: FilterType;
	operator?: FilterOperator;
	values?: FilterValue[];
}

export function string(filters: QueryFilter[]) {
	return JSON.stringify(filters);
}

/**
 *	Generates a numerical string hash based on input strings.
 *
 * @param property string
 * @param type string
 * @param operator FilterOperator
 * @returns string
 */
function generateFilterId(property: string, type: string): string {
	return [...`${property}:${type}`]
		.reduce((hash, c) => (Math.imul(31, hash) + c.charCodeAt(0)) | 0, 0)
		.toString();
}

type FilterWithOptionalId = Optional<Filter, 'id'>;

type QueryFilterWithOptionalId = Optional<QueryFilter, 'id'>;

/**
 *	Credates a valid Filter from a partial; Generates a id and appends it.
 *
 *	@param f Omit<Filter, 'id'>
 *
 *	@returns Filter
 */
export function createFilter(f: FilterWithOptionalId): Filter {
	// Pick assigned ID, or generate one
	const id = f?.id ?? generateFilterId(f.property, f.type);

	return {
		...f,
		id,
	};
}

/**
 *	Behaves like {@see createFilter} but for QueryFilter
 *
 *	@param f QueryFilterWithOptionalId
 *
 *	@returns QueryFilter
 */
export function createQueryFilter(f: QueryFilterWithOptionalId): QueryFilter {
	// Pick assigned ID, or generate one
	const id = f?.id ?? generateFilterId(f.property, f.type);

	return {
		...f,
		id,
	};
}

interface UnverifiedQueryFilterObject {
	[key: string]: QueryFilterWithOptionalId;
}

export function createQueryFilters(
	fo: UnverifiedQueryFilterObject
): QueryFilterObject {
	return objects.map(fo, (f) => createQueryFilter(f));
}

interface UnverifiedFilterGroups {
	[key: string]: {
		filters: {
			[key: string]: FilterWithOptionalId;
		};
		hidden?: boolean;
	};
}

export function createFilterGroups(
	filterGroups: UnverifiedFilterGroups
): FilterGroups {
	return objects.map(filterGroups, (fg) => {
		fg.filters = objects.map(fg.filters, (f) => createFilter(f));

		return fg;
	});
}

export type FilterConfig = {
	[key: string]: UnverifiedFilterGroups;
};

export function createFilterConfig(fc: FilterConfig): FilterConfig {
	return objects.map(fc, (fg) => createFilterGroups(fg));
}
