import styled from 'styled-components';

import * as palette from 'pkg/config/palette';

import rgba from 'pkg/rgba';
import Link from 'pkg/router/Link';

const BLACK = 'rgb(0, 0, 0)';

export const DefaultLink = styled(Link)`
	color: ${BLACK};
`;

export const DeleteComment = styled.span`
	color: ${BLACK};
	position: absolute;
	top: 0;
	right: 0;
`;

export const CommentLink = styled(DefaultLink)`
	margin: 1px 0 18px 0;
	font-size: 0.93rem;
	opacity: 1;
	color: ${rgba(palette.main.actionableLinkColor)};
	cursor: pointer;
	margin-bottom: 15px;
	display: inline-block;

	@media (hover: hover) {
		&:hover {
			color: ${rgba(palette.main.actionableLinkColor, 0.7)};
		}
	}
`;
