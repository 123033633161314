import { ReactNode } from 'react';

export const symbol_1x_mobiledata: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M242-620h-52q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T190-680h82q12.75 0 21.375 8.625T302-650v340q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T242-310v-310Zm358 184-84 141q-4.091 7.105-11.045 11.053Q498-280 489.912-280 473-280 464-295t0-30l100-168-86-142q-9-15-.325-30 8.676-15 26.027-15Q512-680 519-676.5t11 10.5l70 116 66-115q3.824-7.105 10.706-11.053Q683.588-680 692-680q17.5 0 26.25 15t-.25 30l-82 142 98 168q9 15 0 30t-26.333 15q-7.945 0-14.806-4Q686-288 682-295l-82-141Z"
		/>
	</svg>
);
