import { ReactNode } from 'react';

export const symbol_motion_sensor_active: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-80q-24 0-42-18t-18-42v-142q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-282v142h142q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q312-97 303.375-88.5T282-80H140ZM80-678v-142q0-24 18-42t42-18h142q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T282-820H140v142q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Q97-648 88.5-656.625T80-678Zm399.567 268Q451-410 430.5-430.562 410-451.125 410-480q0-29 20.562-49.5Q451.125-550 480-550q29 0 49.5 20.5t20.5 49.933Q550-451 529.5-430.5T479.567-410ZM820-80H678q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T678-140h142v-142q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T880-282v142q0 24-18 42t-42 18Zm0-598v-142H678q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T678-880h142q24 0 42 18t18 42v142q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T820-678ZM234-450q-13.737 0-21.368 12.5Q205-425 209-411q19 75 73 128.5T411-210q14 4 26-3.5t12-21.894Q449-246 441.5-255q-7.5-9-18.5-12-58-15-100-57t-57-100q-3-11-11.841-18.5Q245.317-450 234-450Zm1-60q-13.737 0-21.368-12.5Q206-535 210-549q19-75 73-128.5T412-750q14-4 26 3.5t12 21.894Q450-714 442.5-705q-7.5 9-18.5 12-58 15-100 57t-57 100q-3 11-11.841 18.5Q246.317-510 235-510Zm491 60q13.737 0 21.368 12.5Q755-425 751-411q-19 75-73 128.5T549-210q-14 4-26-3.5t-12-21.894Q511-246 518.5-255q7.5-9 18.5-12 58-15 100-57t57-100q3-11 11.841-18.5Q714.683-450 726-450Zm0-60q13.737 0 21.368-12.5Q755-535 751-549q-19-75-73-128.5T549-750q-14-4-26 3.5t-12 21.894Q511-714 518.5-705q7.5 9 18.5 12 58 15 100 57t57 100q3 11 11.841 18.5Q714.683-510 726-510Z"
		/>
	</svg>
);
