import { ReactNode } from 'react';

export const symbol_pin_drop: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-298q103.95-83.86 156.975-161.43Q690-537 690-604q0-59-21.5-100t-53.009-66.88q-31.51-25.881-68.271-37.5Q510.459-820 480-820q-30.459 0-67.22 11.62-36.761 11.619-68.271 37.5Q313-745 291.5-704T270-604q0 67 53.025 144.57T480-298Zm0 64q-7.568 0-14.757-2.5T452-244q-81-63-161.5-155.5T210-604q0-68.208 24.5-119.604T298-810q39-35 86.98-52.5 47.98-17.5 95-17.5T575-862.5q48 17.5 87 52.5t63.5 86.396Q750-672.208 750-604q0 112-80.5 204.5T508-244q-6.054 5-13.243 7.5T480-234Zm.089-306Q509-540 529.5-560.589q20.5-20.588 20.5-49.5Q550-639 529.411-659.5q-20.588-20.5-49.5-20.5Q451-680 430.5-659.411q-20.5 20.588-20.5 49.5Q410-581 430.589-560.5q20.588 20.5 49.5 20.5ZM240-80q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T240-140h480q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q750-97 741.375-88.5T720-80H240Zm240-524Z"
		/>
	</svg>
);
