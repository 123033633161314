import { Fragment } from 'react';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import { formatToCurrency } from 'pkg/i18n/formatters';
import DateTime from 'pkg/datetime';
import { cssClasses } from 'pkg/css/utils';

import { getOrderCostDetails } from 'routes/payments/orders/utils';

import * as Card from 'components/Card';

import * as css from './styles.css';

interface RowInfoProps {
	title: string;
	value: string;

	hideDivider?: boolean;
	bold?: boolean;
}

function RowInfo({
	title,
	value,
	hideDivider = false,
	bold = false,
}: RowInfoProps) {
	return (
		<Fragment>
			<div className={css.row}>
				<div></div>
				<div className={cssClasses(bold ? css.bold : '')}>{title}</div>
				<div className={cssClasses(css.alignRight, bold ? css.bold : '')}>
					{value}
				</div>
			</div>
			{!hideDivider && <Card.Divider />}
		</Fragment>
	);
}

interface DiscountRowProps {
	discount: models.discount.Discount;
	order: models.order.Order;
}

function DiscountRow({ discount, order }: DiscountRowProps) {
	const discountRow = models.orderRow.findDiscountRow(order.rows);

	let discountValue = formatToCurrency(
		discountRow?.amount / 100,
		order.currency
	);

	if (models.discount.isPercentage(discount)) {
		discountValue = `${formatToCurrency(
			discountRow?.amount / 100,
			order.currency
		)} (${discount.percentOff}%)`;
	}

	return (
		<RowInfo
			title={t('Discount ({title})', {
				title: discount.title,
			})}
			value={discountValue}
		/>
	);
}

interface OrderSummaryProps {
	order: models.order.Order;
}

export default function OrderSummary({ order }: OrderSummaryProps) {
	const discount = models.orderRow.findDiscount(order.rows);
	const details = getOrderCostDetails({
		rows: order.rows,
		refunds: order.refunds,
	});

	const subtotal = formatToCurrency(details.subtotal / 100, order.currency);
	const total = formatToCurrency(details.total / 100, order.currency);
	const hasTaxes = order.rows.some((r) => r?.taxRate);

	return (
		<Fragment>
			<Card.Divider />
			<RowInfo title={t('Subtotal')} value={subtotal} />
			{discount && <DiscountRow discount={discount} order={order} />}
			{hasTaxes && (
				<RowInfo
					title={t('Total excluding tax')}
					value={formatToCurrency(
						details.totalExcludingTax / 100,
						order.currency
					)}
				/>
			)}
			{details.taxes.map((tax) => (
				<RowInfo
					title={t('{taxTitle} ({percentage}%)', {
						taxTitle: tax.taxRate.display_name,
						percentage: tax.taxRate.percentage,
					})}
					value={formatToCurrency(tax.amount / 100, order.currency)}
				/>
			))}
			{!!details.serviceFee && (
				<RowInfo
					title={t('Service fee')}
					value={formatToCurrency(details.serviceFee / 100, order.currency)}
				/>
			)}
			<RowInfo
				title={t('Total')}
				value={total}
				hideDivider={!!!order?.refunds}
				bold
			/>
			{order?.refunds && (
				<Fragment>
					{order.refunds?.map((refund) => (
						<RowInfo
							title={t('Refund ({date})', {
								date: DateTime.fromTimestamp(
									refund.createdAt
								).toLocaleDateString(),
							})}
							value={`-${formatToCurrency(
								refund.amount / 100,
								order.currency
							)}`}
						/>
					))}
					<RowInfo
						title={t('Adjusted total')}
						value={formatToCurrency(
							details.adjustedTotal / 100,
							order.currency
						)}
						hideDivider
						bold
					/>
				</Fragment>
			)}
		</Fragment>
	);
}
