import { Record } from 'immutable';
import { schema } from 'normalizr';

import User from 'pkg/models/user';

interface IEventReport {
	absent: number;
	attendance: { [key: string]: number };
	eventType: { [key: string]: number };
	response: { [key: string]: number };
	total: number;
	userId: number;
	isValidForLok: boolean;
	user: User;
}

const EventReportProps: IEventReport = {
	absent: 0,
	attendance: {
		injured: 0,
		other: 0,
		sick: 0,
		unset: 0,
	},
	eventType: {
		camp: 0,
		cup: 0,
		match: 0,
		meeting: 0,
		practice: 0,
		uncategorized: 0,
	},
	response: { accepted: 0, declined: 0, pending: 0 },
	total: 0,
	userId: 0,
	isValidForLok: false,
	user: null,
};

const EventReportSchema = new schema.Entity(
	'eventReports',
	{
		user: User.normalizr(),
	},
	{
		idAttribute: (value) => value.userId,
	}
);

class EventReport
	extends Record(EventReportProps, 'EventReportRecord')
	implements IEventReport
{
	static normalizr(): schema.Entity {
		return EventReportSchema;
	}
}

export default EventReport;
