import { Fragment, ReactNode, useEffect } from 'react';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useEndpoint } from 'pkg/api/use_endpoint';
import { getPaletteFromColor } from 'pkg/css/group-palette';
import { cssVarList } from 'pkg/css/utils';

import Badge from 'components/Badge';
import AssetImage from 'components/AssetImage';

import Column from 'components/layout/column';
import Row from 'components/layout/row';
import { Spinner } from 'components/loaders/spinner';

import * as css from './styles.css';

interface JoinWidgetProps {
	groupCode?: string;
	groupInfo?: models.onboardingInfo.Group;

	heading?: string;
	badgeSize?: string;

	onBeforeLoad?: () => void;
	onDidLoad?: (groupInfo: models.onboardingInfo.Group) => void;
	onError?: (response: Response) => void;

	renderErrorWith?: ReactNode;
	children?: ReactNode | ReactNode[];
}

export default function JoinWidget({
	groupCode,
	groupInfo,

	heading,
	badgeSize = '65px',

	onBeforeLoad,
	onDidLoad,
	onError,

	renderErrorWith,
	children,
}: JoinWidgetProps): JSX.Element {
	const { response, record, isLoading, hasError } =
		useEndpoint<models.onboardingInfo.Group>(
			groupCode?.length >= 6 ? endpoints.Accounts.OnboardingInfo() : null,
			{
				queryParams: new URLSearchParams({
					invite_code: groupCode,
				}),
			},
			onDidLoad
		);

	useEffect(() => {
		if (onBeforeLoad && isLoading === true) {
			onBeforeLoad();
		}
	}, [isLoading]);

	useEffect(() => {
		if (hasError && onError) {
			onError(response);
		}
	}, [hasError]);

	if (!isLoading && Object.keys(record).length > 0) {
		groupInfo = record;
	}

	const hasContent = groupInfo && Object.keys(groupInfo).length > 0;
	const hasBranding = !!groupInfo?.primaryColor;

	if (groupCode && isLoading)
		return (
			<div className={css.wrapper} data-is-branded="false">
				<Spinner />
			</div>
		);

	if (hasError && renderErrorWith) {
		return <Fragment>{renderErrorWith}</Fragment>;
	}

	if (!hasContent || hasError || groupCode?.length < 6) {
		return null;
	}

	const palette = getPaletteFromColor(
		groupInfo?.primaryColor || styles.palette.blue['100']
	);

	return (
		<div
			className={css.wrapper}
			data-is-branded={hasBranding}
			style={cssVarList({ ...palette })}>
			<div className={css.backdrop}>
				{hasBranding ? (
					<Badge badgeUrl={groupInfo.profileImageUrl} />
				) : (
					<AssetImage src="img/logo_black.svg" />
				)}
			</div>

			<Column spacing={styles.spacing._3}>
				{heading && <span className={css.heading}>{heading}</span>}
				<Column spacing={styles.spacing._6}>
					<Row columns={`${badgeSize} 1fr`} align="center">
						<Badge badgeUrl={groupInfo.profileImageUrl} />
						<Column spacing={styles.spacing._1}>
							<strong>{groupInfo.groupName}</strong>
							{groupInfo.organizationName && (
								<span>{groupInfo.organizationName}</span>
							)}
						</Column>
					</Row>
					{children}
				</Column>
			</Column>
		</div>
	);
}
