import { ReactNode } from 'react';

export const symbol_align_horizontal_center: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M450-110v-180H290q-20.833 0-35.417-14.618Q240-319.235 240-340.118 240-361 254.583-375.5 269.167-390 290-390h160v-180H170q-20.833 0-35.417-14.618Q120-599.235 120-620.118 120-641 134.583-655.5 149.167-670 170-670h280v-180q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-850v180h280q20.833 0 35.417 14.618Q840-640.765 840-619.882 840-599 825.417-584.5 810.833-570 790-570H510v180h160q20.833 0 35.417 14.618Q720-360.765 720-339.882 720-319 705.417-304.5 690.833-290 670-290H510v180q0 12.75-8.675 21.375Q492.649-80 479.825-80 467-80 458.5-88.625T450-110Z"
		/>
	</svg>
);
