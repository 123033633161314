import { ReactNode } from 'react';

export const symbol_paragliding: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-287q-30 0-51.5-21.5T407-360q0-30 21.5-51.5T480-433q30 0 51.5 21.5T553-360q0 30-21.5 51.5T480-287ZM370 0v-145q-75-26-112.5-86T212-364q-2-11 7-18.5t21-7.5q12 0 21 8t10 20q8 89 61 130.5T480-190q94 0 146.5-41T688-359q2-13 10.5-22t21.5-9q12 0 21 8.5t7 20.5q-8 72-45.5 131T590-145V0H370ZM203-465l-86-170q-11 5-23 10t-24 5q-12 0-21-8t-9-22v-150q0-66 131-113t309-47q178 0 309 47t131 113v150q0 14-9 22t-21 8q-12 0-24-5t-23-10l-85 169q-4 8-11 12t-15 4h-19q-10 0-18-6t-10-16l-57-228q-37-5-74-7.5t-74-2.5q-37 0-74 2.5t-74 7.5l-57 230q-2 9-9.5 14.5T249-450h-22q-8 0-14-4t-10-11Zm35-38 40-189q-35 8-60.5 16T163-656l75 153Zm484 0 75-153q-29-12-54.5-20T682-692l40 189ZM100-695q77-32 177-53.5T480-770q103 0 203 21.5T860-695v-105q-6-11-34-28t-76.5-33Q701-877 633-888.5T480-900q-85 0-153 11.5T210.5-861Q162-845 134-828t-34 28v105Zm380-205Z"
		/>
	</svg>
);
