const icon = {
	name: 'drawing-arrow',
	objects: [
		{
			type: 'path',

			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M13.2138 5.45993H18.8638V10.9099H17.5638V7.67917L6.67345 18.5695L5.75421 17.6503L16.6446 6.75993H13.2138V5.45993Z',
				fill: 'currentColor',
			},
		},
	],
};

export default icon;
