import { ReactNode } from 'react';

export const symbol_av1: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M100-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h760q24.75 0 42.375 18T920-740v520q0 24-17.625 42T860-160H100Zm0-60h760v-520H100v520Zm0 0v-520 520Zm130-202h80v40q0 10.833 7.116 17.917 7.117 7.083 18 7.083Q346-357 353-364.083q7-7.084 7-17.917v-171q0-21.25-14.375-35.625T310-603h-80q-21.25 0-35.625 14.375T180-553v171q0 10.833 7.116 17.917 7.117 7.083 18 7.083Q216-357 223-364.083q7-7.084 7-17.917v-40Zm0-50v-81h80v81h-80Zm275 52-42-165q-2-8-8.346-13t-14.808-5Q428-603 420.5-593t-4.5 21l54 195q2 9 10 14.5t17.444 5.5h15.112q9.444 0 16.944-5.5Q537-368 539-376l60-197q4-11.351-3.446-20.676Q588.108-603 576.378-603q-8.378 0-14.878 4.5T553-586l-48 166Zm225-133v171q0 10.833 7.116 17.917 7.117 7.083 18 7.083Q766-357 773-364.083q7-7.084 7-17.917v-196q0-10.833-7.083-17.917Q765.833-603 755-603h-69q-10.833 0-17.917 7.116-7.083 7.117-7.083 18Q661-567 668.083-560q7.084 7 17.917 7h44Z"
		/>
	</svg>
);
