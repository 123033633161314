import styled from 'styled-components';
import { t } from '@transifex/native';

const DividerStyles = styled.div`
	text-align: center;
	font-size: var(--font-size-base);
	color: var(--palette-white);
	line-height: var(--font-line-height-lg);
	padding: var(--spacing-3);
	overflow: hidden;
	white-space: nowrap;

	span {
		display: inline-block;
		position: relative;
	}

	span:before,
	span:after {
		position: absolute;
		top: 50%;
		content: '';
		width: 9999px;
		height: 1px;
		background: var(--palette-white);
		opacity: 0.2;
	}

	span:before {
		right: 100%;
		margin-right: 15px;
	}

	span:after {
		left: 100%;
		margin-left: 15px;
	}
`;

interface DividerProps {
	label?: string;
}

export default function Divider({ label }: DividerProps): JSX.Element {
	return (
		<DividerStyles>
			<span>{label ? label : t('Or')}</span>
		</DividerStyles>
	);
}
