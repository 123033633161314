import { ReactNode } from 'react';

export const symbol_lock_reset: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M520-116q-60 0-118.5-21T297-193q-10-8-10.5-20.5T295-235q9-9 21-9.5t23 7.5q38 29 85 45t96 16q125 0 214.5-89.5T824-480q0-125-89.5-214.5T520-784q-125 0-214.5 89.5T216-480v18l49-49q9-9 21-9t21 9q9 9 9 21t-9 21L207-369q-9 9-21 9t-21-9L65-469q-9-9-9-21t9-21q9-9 21-9t21 9l49 49v-18q0-76 28.5-142t78-115.5Q312-787 378-815.5T520-844q76 0 142 28.5t115.5 78Q827-688 855.5-622T884-480q0 152-106 258T520-116Zm-80-209q-14 0-24.5-10.5T405-360v-120q0-14 11.5-24.5T445-515v-45q0-31 22-53t53-22q31 0 53 22t22 53v45q17 0 28.5 10.5T635-480v120q0 14-10.5 24.5T600-325H440Zm35-190h90v-45q0-19-13-32t-32-13q-19 0-32 13t-13 32v45Z"
		/>
	</svg>
);
