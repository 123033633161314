import { Fragment, ReactNode } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';
import { PageWidths } from 'pkg/config/sizes';

import * as models from 'pkg/api/models';
import DateTime from 'pkg/datetime';
import { isToday } from 'pkg/date';
import { cssClasses } from 'pkg/css/utils';

import { useCalendarContext } from 'routes/group/calendar';
import EventWrapper from 'routes/group/calendar/components/events/single';
import { groupByDate } from 'routes/group/calendar/utils';

import Icon from 'components/icon';
import { Trigger } from 'components/ScrollSpy';
import Adslot, { AdslotBoundary } from 'components/adslot';
import { SmallScreen } from 'components/MediaQuery';

import Column from 'components/layout/column';
import { Spinner } from 'components/loaders/spinner';
import { InnerWrapper } from 'components/layout/LargeScreenContent';

import InlinePlaceholder from 'design/placeholders/inline';

import * as css from './styles.css';

interface DayProps {
	dayEvents: [string, models.event.Event[]];

	children: ReactNode;
}

function Day({ dayEvents, children }: DayProps) {
	const today = new Date();
	const calendarCtx = useCalendarContext();
	const dateObj = DateTime.fromTimestamp(
		Math.round(Number.parseInt(dayEvents[0], 10) / 1000)
	);
	const isDateToday = isToday(dateObj);
	const currentYear = today.getFullYear();

	const dayDateOptions: { [key: string]: string } = {
		day: 'numeric',
		month: 'long',
	};

	if (currentYear !== dateObj.getYear()) {
		dayDateOptions.year = 'numeric';
	}

	const events = dayEvents[1].length;

	const handleTodayTrigger = (e: IntersectionObserverEntry) => {
		if (!e.isIntersecting && !calendarCtx.calendarState.showToday) {
			calendarCtx.setCalendarState({
				showToday: true,
			});
		} else if (e.isIntersecting && calendarCtx.calendarState.showToday) {
			calendarCtx.setCalendarState({
				showToday: false,
			});
		}
	};

	let content = (
		<span className={css.planned}>{t('Nothing planned for today')}</span>
	);

	if (events > 0) {
		content = (
			<Fragment>
				{dayEvents[1].map((e) => (
					<EventWrapper key={e.id} event={e} />
				))}
			</Fragment>
		);
	}

	return (
		<div className={css.scheduleDay}>
			<Column spacing={styles.spacing._4}>
				<div
					className={cssClasses(css.dayDate, isDateToday ? css.isToday : '')}>
					<div>{dateObj.toLocaleDateString(dayDateOptions)}</div>
					<span>
						{isDateToday
							? t('Today')
							: dateObj.toLocaleDateString({
									weekday: 'long',
								})}
					</span>
				</div>
				<Column spacing={styles.spacing._3}>{content}</Column>
			</Column>
			{children}
			{isDateToday && (
				<Trigger
					key={
						calendarCtx.calendarState.showToday
							? 'showTodayTrue'
							: 'showTodayFalse'
					}
					onTrigger={handleTodayTrigger}
				/>
			)}
		</div>
	);
}

export default function ScheduleView() {
	const calendarCtx = useCalendarContext();

	const today = new Date();
	const isFutureDate = +calendarCtx.calendarState.scheduleDate > +today;

	const groupedEvents = groupByDate(
		calendarCtx.eventsCollection.records,
		isFutureDate ? false : true
	);

	let showNoEventsText = false;

	if (
		!calendarCtx.eventsCollection.pagination.hasNext &&
		calendarCtx.eventsCollection.pagination.currentPage > 1
	) {
		showNoEventsText = true;
	}

	const fetchNewEvents = (e: IntersectionObserverEntry) => {
		if (e.isIntersecting) {
			calendarCtx.eventsCollection.pagination.fetchNext();
		}
	};

	const groupedEventEntries = Object.entries(groupedEvents);

	let content = (
		<InlinePlaceholder>
			<Icon name="nav-events" />
			{t('No events')}
		</InlinePlaceholder>
	);

	if (groupedEventEntries.length > 0) {
		content = (
			<Fragment>
				{Object.entries(groupedEvents).map((s, i, arr) => (
					<Fragment key={i}>
						<Day dayEvents={s}>
							{!calendarCtx.eventsCollection.isLoading &&
								calendarCtx.eventsCollection.pagination.hasNext &&
								i === arr.length - 1 && <Trigger onTrigger={fetchNewEvents} />}
						</Day>
						{i % 3 === 0 && (
							<SmallScreen>
								<AdslotBoundary>
									<Adslot height={250} />
								</AdslotBoundary>
							</SmallScreen>
						)}
					</Fragment>
				))}
			</Fragment>
		);
	}

	return (
		<InnerWrapper spacious={false} maxWidth={PageWidths.WIDE}>
			<div className={css.scheduleWrapper}>
				{content}
				{calendarCtx.eventsCollection.isLoading && <Spinner />}
				{showNoEventsText && (
					<div className={css.noEvents}>
						{t('You have no more events to show')}
					</div>
				)}
			</div>
		</InnerWrapper>
	);
}
