import * as models from 'pkg/api/models';
import { useCurrentAccountWards, useCurrentOrganization } from 'pkg/identity';

// Does this belong in the identity file?
export function useWardsInCurrentGroup(): models.user.User[] {
	const wards = useCurrentAccountWards();
	const organization = useCurrentOrganization();

	if (!organization || !wards) {
		return [];
	}

	const wardsInGroup = wards
		.filter((rel: models.accountRelation.AccountRelation) => rel.approvedAt > 0)
		.map(
			(rel: models.accountRelation.AccountRelation) => rel.targetAccount.users
		)
		.flat()
		.filter(
			(user: models.user.User) => user?.organizationId === organization.id
		);

	return wardsInGroup;
}
