import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import { formatToCurrency } from 'pkg/i18n/formatters';
import { cssClasses } from 'pkg/css/utils';

import * as css from './styles.css';

interface Props {
	subscription: models.subscription.Subscription;
}

function InstallmentData({ subscription }: Props) {
	return (
		<div className={css.installmentWrapper}>
			<div>
				<strong>
					{t('{paidOrders} of {installmentCount}', {
						paidOrders: models.subscription.getPaidOrders(subscription).length,
						installmentCount: subscription.installmentCount,
					})}
				</strong>{' '}
				<span className={cssClasses(css.gray, css.smallText)}>
					{t('Installment')}
				</span>
			</div>
			<div>
				<strong>
					{formatToCurrency(
						subscription.installmentPaidAmount
							? subscription.installmentPaidAmount / 100
							: 0,
						subscription.currency
					)}
				</strong>{' '}
				<span className={cssClasses(css.gray, css.smallText)}>{t('Paid')}</span>
			</div>
			<div>
				<strong>
					{formatToCurrency(
						subscription.installmentTotalAmount / 100,
						subscription.currency
					)}
				</strong>{' '}
				<span className={cssClasses(css.gray, css.smallText)}>
					{t('Total')}
				</span>
			</div>
		</div>
	);
}

export default InstallmentData;
