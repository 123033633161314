import { ReactNode } from 'react';

export const symbol_device_hub: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M120-150v-120q0-12.75 8.625-21.375T150-300h124l176-176v-149q-35-12-57.5-40.651Q370-694.303 370-730q0-45.833 32.118-77.917 32.117-32.083 78-32.083Q526-840 558-807.917q32 32.084 32 77.917 0 35.697-22.5 64.349Q545-637 510-625v149l176 176h124q12.75 0 21.375 8.625T840-270v120q0 12.75-8.625 21.375T810-120H690q-12.75 0-21.375-8.625T660-150v-86L480-416 300-236v86q0 12.75-8.625 21.375T270-120H150q-12.75 0-21.375-8.625T120-150Z"
		/>
	</svg>
);
