import { ReactNode } from 'react';

export const symbol_alternate_email: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480v53q0 56-39.343 94.5T744-294q-36.082 0-68.041-17.5Q644-329 627-361q-26 34-65.084 50.5T480-294q-77.605 0-132.302-54Q293-402 293-480.014q0-78.014 54.698-133Q402.395-668 480-668q77.605 0 132.302 54.99Q667-558.02 667-480v53q0 30.613 22.5 51.806Q712-354 743.5-354t54-21.194Q820-396.387 820-427v-53q0-142.375-98.812-241.188Q622.375-820 480-820t-241.188 98.812Q140-622.375 140-480t98.812 241.188Q337.625-140 480-140h184q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q694-97 685.375-88.5T664-80H480Zm.059-274Q533-354 570-390.75T607-480q0-54-37.059-91t-90-37Q427-608 390-571t-37 91q0 52.5 37.059 89.25t90 36.75Z"
		/>
	</svg>
);
