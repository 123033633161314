import { useState, Fragment } from 'react';
import { t } from '@transifex/native';

import { useCollection } from 'pkg/api/use_collection';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';

import ListItem from 'routes/post/list/ListItem';

import PostForm from 'containers/group/post/Form';

import AssetImage from 'components/AssetImage';
import Pagination from 'components/pagination';
import { IconName } from 'components/icon';
import { LargeScreen } from 'components/MediaQuery';

import * as ActionBar from 'components/layout/ActionBar';
import { ButtonTrigger } from 'components/navigation/header/small_screen/page_actions/ButtonTrigger';
import { useTemplateContext } from 'components/layout/page-templates/context';

import * as Table from 'design/table';
import Button from 'design/button';

interface PageProps {
	icon: IconName;
	groupId: number;
}

const Page: React.FC<React.PropsWithChildren<PageProps>> = ({ groupId }) => {
	const [postModalActive, setPostModalActive] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const { currentTab, setPageActions } = useTemplateContext();

	const params = {
		status: currentTab,
		filter_text: searchValue,
	};

	const { records, isLoading, pagination, removeRecord, refresh, sort } =
		useCollection<models.groupPost.GroupPost>(
			endpoints.GroupPosts.Index(groupId),
			{
				queryParams: new URLSearchParams(params),
				useLocationPagination: true,
				defaultSortBy: 'createdAt',
				defaultSortByOrder: 'desc',
			}
		);

	const handlePostModal = () => {
		setPostModalActive(!postModalActive);
	};

	const tableColumns = [
		{
			content: t(`Title`),
		},
		{
			content: t(`Published by`),
			width: 'min-content',
		},
		{
			content: t(`Published as`),
			width: 'min-content',
		},
		{
			content: t(`Status`),
			width: 'min-content',
		},
		{
			content: t(`Created at`),
			sortKey: 'createdAt',
		},
		{
			content: t(`Published at`),
			sortKey: 'publishedAt',
		},
		{
			content: t(`Sticky Until`),
			sortKey: 'stickyUntil',
		},
		{
			content: t(`Players`),
			width: 'min-content',
			sortKey: 'playerVisibility',
		},
		{
			content: t(`Parents`),
			width: 'min-content',
			sortKey: 'parentVisibility',
		},
		{
			content: t(`Website`),
			width: 'min-content',
			sortKey: 'websiteVisibility',
		},
		{
			content: t(`Recipients`),
			sortKey: 'recipientCount',
			width: 'min-content',
			align: 'right',
		},
		{
			content: t(`Groups`),
			width: 'min-content',
			align: 'right',
		},
		{
			content: t(`View Count`),
			width: 'min-content',
			align: 'right',
		},
		{
			content: t('Comments'),
		},
		{
			content: '',
			width: 'min-content',
		},
	];

	const emptyState = {
		title: t(`No posts yet!`),
		content: t(
			`Stay up to date with the team and use the wall for fast and easy communication.`
		),
		image: <AssetImage src="img/missing-entities/post.svg" />,
		helpUrl:
			'https://help.360player.com/article/79-writing-posts-to-your-team-or-organization',
		button: (
			<Button secondary icon="add" onClick={handlePostModal}>
				{t(`Write a post!`)}
			</Button>
		),
	};

	const content = (
		<Fragment>
			<Table.Table
				columns={tableColumns}
				isLoading={isLoading}
				sortBy={sort.column}
				sortOrder={sort.order}
				onSort={sort.setSort}
				emptyState={emptyState}>
				{records.map((post) => (
					<ListItem
						key={post.id}
						post={post}
						isScheduled={currentTab === 'scheduled'}
						remove={removeRecord}
						refresh={refresh}
					/>
				))}
			</Table.Table>
			{postModalActive && (
				<PostForm
					isAdminOrStaffForGroup
					groupId={groupId}
					onClose={handlePostModal}
					onSave={refresh}
				/>
			)}

			<Pagination {...pagination} />
		</Fragment>
	);

	const handleSearch = (value: string) => setSearchValue(value);

	useComponentDidMount(() => {
		setPageActions(<ButtonTrigger icon="add" onClick={handlePostModal} />);
	});

	return (
		<Fragment>
			<ActionBar.FilterBar>
				<ActionBar.PrimaryAction>
					<ActionBar.Search
						key={currentTab}
						value={searchValue}
						onChange={handleSearch}
						placeholder={t(`Filter by title or content`)}
					/>
				</ActionBar.PrimaryAction>
				<LargeScreen>
					<Button primary icon="add" onClick={handlePostModal}>
						{t(`New post`)}
					</Button>
				</LargeScreen>
			</ActionBar.FilterBar>
			{content}
		</Fragment>
	);
};

export default Page;
