import { ReactNode } from 'react';

export const symbol_exposure_neg_1: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M370-410H150q-13 0-21.5-8.5T120-440q0-13 8.5-21.5T150-470h220q13 0 21.5 8.5T400-440q0 13-8.5 21.5T370-410Zm280-257-72 52q-11 8-24 5t-21-14q-7-11-5-23t13-20l121-88q3-2 7-3.5t8-1.5h18q11 0 18 7t7 18v500q0 14-10.5 24.5T685-200q-14 0-24.5-10.5T650-235v-432Z"
		/>
	</svg>
);
