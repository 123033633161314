import { ReactNode } from 'react';

export const symbol_settings_power: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M450-534v-316q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-850v316q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T450-534Zm29.5 299Q351-235 261-324.803T171-544q0-68 28-127t80-106q11-10 25-10t24.292 10.292Q337-768 335-755.5q-2 12.5-12 20.5-42 37-67 85.5T231-544q0 103 73 176t176 73q103 0 176-73t73-176q0-57-23.5-108.5T640-738q-10-8-12-20t6.25-20.25Q644-788 658-788t25 9q52 45 79 106t27 129q0 129.394-90.5 219.197T479.5-235ZM310 2q-17.425 0-29.212-11.787Q269-21.575 269-39t11.788-29.213Q292.575-80 310-80q17.425 0 29.212 11.787Q351-56.425 351-39T339.212-9.788Q327.425 2 310 2Zm170 0q-17.425 0-29.212-11.787Q439-21.575 439-39t11.788-29.213Q462.575-80 480-80q17.425 0 29.212 11.787Q521-56.425 521-39T509.212-9.788Q497.425 2 480 2Zm170 0q-17.425 0-29.213-11.787Q609-21.575 609-39t11.787-29.213Q632.575-80 650-80t29.213 11.787Q691-56.425 691-39T679.213-9.788Q667.425 2 650 2Z"
		/>
	</svg>
);
