import { t } from '@transifex/native';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import * as styles from 'pkg/config/styles';

import { useCurrentRoute } from 'pkg/router/hooks';
import * as routes from 'pkg/router/routes';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as api from 'pkg/api';
import * as models from 'pkg/api/models';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import useConfirm from 'pkg/hooks/useConfirm';
import * as actions from 'pkg/actions';
import { pushState } from 'pkg/router/state';
import { useCurrentOrganization } from 'pkg/identity';

import * as Card from 'components/Card';
import SectionTitle from 'components/SectionTitle';
import Modal from 'components/modal';

import { Spinner } from 'components/loaders/spinner';
import Column from 'components/layout/column';
import AccountTarget from 'components/account/claim-invite-modal/AccountTarget';
import GroupTarget from 'components/account/claim-invite-modal/GroupTarget';

import Button from 'design/button';

interface ClaimInviteModalProps {
	inviteId: number;
	onClose: () => void;
}

export default function ClaimInviteModal({
	inviteId,
	onClose,
}: ClaimInviteModalProps): JSX.Element {
	const dispatch = useDispatch();
	const { query } = useCurrentRoute();
	const org = useCurrentOrganization();

	const [invite, setInvite] = useState<any>(null);

	useComponentDidMount(async () => {
		const json = await dispatch(
			actions.groups.fetchInvite(inviteId, query.inviteKey)
		);

		setInvite(json);
	});

	const accept = async () => {
		const [request] = await api.post(
			endpoints.AccountInvites.Claim(inviteId),
			{}
		);

		if (!request.ok) {
			actions.flashes.show({
				title: t('Something went wrong'),
				message: t(
					`If you think this error isn't supposed to happen, please contact 360Player support.`
				),
			});

			return;
		}

		actions.flashes.show({
			title: t('Connection request accepted'),
		});

		await onClose();
		pushState(routes.Organization.Home(org.id));
	};

	const decline = useConfirm({
		message: t(
			'Do you want to decline the invitation? This action can not be reversed.'
		),
		onConfirm: async () => {
			const [request] = await api.delete(
				endpoints.AccountInvites.Show(inviteId),
				{}
			);

			if (!request.ok) {
				actions.flashes.show({
					title: t('Something went wrong'),
					message: t(
						`If you think this error isn't supposed to happen, please contact 360Player support.`
					),
				});

				return;
			}

			actions.flashes.show({
				title: t('Connection request declined'),
			});

			await onClose();
			pushState(routes.Organization.Home(org.id));
		},
	});

	const inviteClaimed: boolean = !!invite?.used;

	if (!invite) return <Spinner />;

	const author = (
		<AccountTarget
			account={invite.author as unknown as models.account.Account}
		/>
	);

	let childTargetsCount = 0;

	const targets = invite.targets.map((target: any) => {
		if (target.type === 'child') {
			childTargetsCount++;

			return (
				<AccountTarget
					account={target.child as unknown as models.account.Account}
				/>
			);
		}

		if (target.type === 'group') {
			return (
				<GroupTarget group={target.group as unknown as models.group.Group} />
			);
		}
	});

	return (
		<Modal onClose={onClose} thin>
			<Card.Base>
				<Card.Header>
					<Card.Heading>{t('Connection request')}</Card.Heading>
				</Card.Header>
				<Card.Divider />
				<Card.Body $narrowBody>
					<Column>
						{inviteClaimed ? (
							<Column spacing={styles.spacing._6}>
								<SectionTitle medium>
									{t('Invite already claimed!')}
								</SectionTitle>
								<Column>
									<SectionTitle thin>{t('You were invited by')}</SectionTitle>
									{author}
								</Column>
								<Column>
									<SectionTitle thin>{t('To connect to')}</SectionTitle>
									{targets}
								</Column>
								{childTargetsCount && (
									<Button
										primary
										block
										large
										href={routes.Account.Settings.Show('children')}>
										{t('Go to your children')}
									</Button>
								)}
							</Column>
						) : (
							<Column spacing={styles.spacing._6}>
								<SectionTitle medium>
									{t('You have a new connection request!')}
								</SectionTitle>
								<Column>
									<SectionTitle thin>
										{t('You have been invited by')}
									</SectionTitle>
									{author}
								</Column>
								<Column>
									<SectionTitle thin>{t('To connect to')}</SectionTitle>
									{targets}
								</Column>
								<Column>
									<Button primary block large onClick={accept}>
										{t('Accept invite')}
									</Button>
									{!!invite.email && (
										<Button block large onClick={decline}>
											{t('Decline invite')}
										</Button>
									)}
								</Column>
							</Column>
						)}
					</Column>
				</Card.Body>
			</Card.Base>
		</Modal>
	);
}
