import { ReactNode } from 'react';

export const symbol_gamepad: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-634Zm154 154Zm-307 0Zm153 153Zm-21-245-81-82q-5-5-7-10.217-2-5.218-2-10.783v-175q0-12.75 8.625-21.375T399-880h163q12.75 0 21.375 8.625T592-850v175q0 5.565-2 10.783Q588-659 583-654l-82 82q-5 5-10.133 6.5-5.134 1.5-11 1.5Q474-564 469-565.5q-5-1.5-10-6.5Zm113 113q-5-5-6.5-10.133-1.5-5.134-1.5-11Q564-486 565.5-491q1.5-5 6.5-10l82-82q5-5 10.217-7 5.218-2 10.783-2h175q12.75 0 21.375 8.625T880-562v163q0 12.75-8.625 21.375T850-369H675q-5.565 0-10.783-2Q659-373 654-378l-82-81ZM80-399v-163q0-12.75 8.625-21.375T110-592h175q5.565 0 10.783 2Q301-588 306-583l82 82q5 5 6.5 10.133 1.5 5.134 1.5 11Q396-474 394.5-469q-1.5 5-6.5 10l-82 81q-5 5-10.217 7-5.218 2-10.783 2H110q-12.75 0-21.375-8.625T80-399Zm289 289v-175q0-5.565 2-10.783Q373-301 378-306l81-82q5-5 10.133-6.5 5.134-1.5 11-1.5Q486-396 491-394.5q5 1.5 10 6.5l82 82q5 5 7 10.217 2 5.218 2 10.783v175q0 12.75-8.625 21.375T562-80H399q-12.75 0-21.375-8.625T369-110Zm111-524 52-52v-134H429v134l51 52ZM140-429h135l52-51-52-52H140v103Zm289 289h103v-135l-52-52-51 52v135Zm257-289h134v-103H686l-52 52 52 51Z"
		/>
	</svg>
);
