import { ReactNode } from 'react';

export const symbol_checked_bag_question: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M391-720h156q0-32-23-53.5T469-795q-32 0-55 21.25T391-720Zm-127 60h-56v360h56v-360ZM100-120q-12.75 0-21.375-8.675Q70-137.351 70-150.175 70-163 78.625-171.5T100-180h386q3 17 8.5 32.5T506-120H100Zm209-540v360h179q-3 12-5.5 29t-2.5 31H208q-24 0-42-18t-18-42v-360q0-24 18-42t42-18h139q0-52 35.618-86 35.617-34 86.5-34Q520-840 555.5-806q35.5 34 35.5 86h139q24 0 42 18t18 42v176q-16-4-30-5t-30-1v-170h-56v177q-13 3-25 7t-20 8v-192H309Zm426 230q83 0 139 56t56 139q0 83-56 139T735-40q-83 0-139-56t-56-139q0-83 56-139t139-56Zm1.108 330Q748-100 756-108.108t8-20Q764-140 755.892-148t-20-8Q724-156 716-147.892t-8 20Q708-116 716.108-108t20 8ZM264-660h45-45Zm0 0h-56 56Zm45 0h320-320Zm320 0h45-45Zm45 0h56-56Zm59.703 299q-21.088 0-38.846 10.043Q677.099-340.914 666-324q-5 7-1.526 14.184 3.473 7.184 11.579 10.5Q683-296 689.5-299q6.5-3 11.5-8.833Q706-316 714.75-320t19.25-4q16.875 0 26.438 8.25Q770-307.5 770-291q0 11-7 19.5T748-255q-8 7-15.5 14T720-226q-4 7-5 13.3-1 6.3-1 14.7 0 8 6 14t14 6q8 0 14-6t6-14q0-8 6.5-17.5t15.227-17.9Q791-246 800.5-258.5 810-271 810-291q0-31.719-21.5-50.859Q767-361 733.703-361Z"
		/>
	</svg>
);
