import { ReactNode } from 'react';

export const symbol_format_text_overflow: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M190-160q-12.75 0-21.375-8.5T160-190v-580q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T220-770v580q0 12-8.625 21T190-160Zm339.825-450Q517-610 508.5-618.625T500-640v-130q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T560-770v130q0 12-8.675 21-8.676 9-21.5 9ZM694-374q-9.067 9-21.533 9Q660-365 651-374q-9-9-9-21t9-21l34-34H350q-12 0-21-8.625T320-480q0-12.75 8.5-21.375T350-510h335l-34-34q-9-9-9-21t9.053-21q9.052-9 21.5-9Q685-595 694-586l85 85q9 9 9 21t-9 21l-85 85ZM530-160q-12.75 0-21.375-8.5T500-190v-130q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T560-320v130q0 12-8.625 21T530-160Z"
		/>
	</svg>
);
