import { useCallback, useRef, useState } from 'react';

type Callback = () => void;

/**
 * @DEVNOTE Do not use in functional components
 * @see useDebounce
 */
export function debounce<T, A extends any[] = []>(
	callback: (...args: A) => T,
	delay: number = 250,
	unbouncedCallback?: Callback
): (...args: A) => void {
	let timeout: number;

	// @NOTE Cannot be an arrow function
	return function (...args) {
		const handler = () => callback.apply(this, args);

		if (typeof unbouncedCallback === 'function') {
			unbouncedCallback.call(this, args);
		}

		window.clearTimeout(timeout);
		timeout = window.setTimeout(handler, delay);
	};
}

export function useDebounce<T, A extends any[] = []>(
	callback: (...args: A) => T,
	delay: number = 250,
	deps: any[] = []
): (...args: A) => T {
	const timeout = useRef<number>();

	return useCallback(
		function (...args: A): T {
			if (timeout.current) {
				window.clearTimeout(timeout.current);
			}

			timeout.current = window.setTimeout(
				(): T => callback(...args) as T,
				delay
			);

			return;
		},
		[callback, ...deps]
	);
}

export function useDebouncedValue<T = any>(
	value: T,
	delay: number = 250,
	onChange?: () => void
): T {
	const [_value, setValue] = useState<T>();

	const changeListener = useDebounce(() => {
		setValue(value);

		if (onChange) {
			onChange();
		}
	}, delay);

	changeListener();

	return _value;
}

export function delay(delay: number): Promise<void> {
	return new Promise((resolve: Callback) => window.setTimeout(resolve, delay));
}

export function useThrottle<T, A extends any[] = []>(
	callback: (...args: A) => T,
	interval: number = 250
): (...args: A) => void {
	const shouldCall = useRef<boolean>(true);
	const timeout = useRef<number>();

	return function (...args: A) {
		if (!shouldCall.current) return;

		shouldCall.current = false;
		callback.apply(this, ...args);

		timeout.current = window.setTimeout(
			() => (shouldCall.current = true),
			interval
		);
	};
}
