import { ReactNode } from 'react';

export const symbol_javascript: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M274-360q-14.167 0-24.083-9.917Q240-379.833 240-394v-42q0-10.4 6.8-17.2 6.8-6.8 17.2-6.8 10.4 0 17.2 6.8 6.8 6.8 6.8 17.2v28h84v-168q0-10.4 6.8-17.2 6.8-6.8 17.2-6.8 10.4 0 17.2 6.8 6.8 6.8 6.8 17.2v182q0 14.167-9.917 24.083Q400.167-360 386-360H274Zm240 0q-14.45 0-24.225-9.775Q480-379.55 480-394v-22q0-9.6 7.2-16.8 7.2-7.2 16.8-7.2 9.6 0 16.8 7.2 7.2 7.2 7.2 16.8v8h104v-53H514q-14.45 0-24.225-9.775Q480-480.55 480-495v-71q0-14.45 9.775-24.225Q499.55-600 514-600h132q14.45 0 24.225 9.775Q680-580.45 680-566v22q0 9.6-7.2 16.8-7.2 7.2-16.8 7.2-9.6 0-16.8-7.2-7.2-7.2-7.2-16.8v-8H528v53h118q14.45 0 24.225 9.775Q680-479.45 680-465v71q0 14.45-9.775 24.225Q660.45-360 646-360H514Z"
		/>
	</svg>
);
