import { useState, Fragment } from 'react';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import useMixedState from 'pkg/hooks/useMixedState';

import { useSmallScreen } from 'components/MediaQuery';

import CollectionModal from 'components/training-library/modals/Collection';
import Group, { GroupWrapperProperties } from 'components/library/Group';
import TrainingCollectionGroupItem from 'components/library/group-items/TrainingCollection';
import { GroupItemAction } from 'components/library/GroupItem';
import ShareCollectionModal from 'components/training-library/modals/share';

import Button from 'design/button';

import * as css from './styles.css';

export default function TrainingCollection({
	title,
	items,
	hasNext,
	isLoading,
	fetchNext,
	removeRecord,
	replaceRecord,
}: GroupWrapperProperties<models.trainingCollection.TrainingCollection>): JSX.Element {
	const [collectionId, setCollectionId] = useState<number>(0);
	const [shareModal, setShareModal] = useMixedState({
		collectionId: 0,
		show: false,
	});

	const handleEdit = (
		collection: models.trainingCollection.TrainingCollection
	) => {
		setCollectionId(collection.id);
	};

	const handleShare = (
		collection: models.trainingCollection.TrainingCollection
	) =>
		setShareModal({
			collectionId: collection.id,
			show: true,
		});

	const handleDidSave = (
		collection: models.trainingCollection.TrainingCollection
	) => {
		replaceRecord(collection);
	};

	const handleDelete = async (
		collection: models.trainingCollection.TrainingCollection
	) => {
		const success = await models.destroy(collection);

		if (success) {
			removeRecord(collection.id);
		}
	};
	const isSmallScreen = useSmallScreen();

	const closeShareModal = () =>
		setShareModal({
			collectionId: 0,
			show: false,
		});

	const closeModal = () => setCollectionId(0);

	return (
		<Fragment>
			<Group title={title} icon="folder" context="training-library">
				{items.map(
					(item: models.trainingCollection.TrainingCollection, n: number) => {
						const actions: GroupItemAction<models.trainingCollection.TrainingCollection>[] =
							[];

						if (
							models.hasAllowedAction(
								item,
								models.Action.TrainingCollectionUpdate
							)
						) {
							actions.push({
								icon: 'edit',
								label: t('Edit'),
								onClick: handleEdit,
							});
						}

						if (
							models.hasAllowedAction(
								item,
								models.Action.TrainingCollectionShare
							)
						) {
							actions.push({
								icon: 'share',
								label: t('Share with other organization'),
								onClick: handleShare,
							});
						}

						if (
							models.hasAllowedAction(
								item,
								models.Action.TrainingCollectionRemove
							)
						) {
							actions.push({
								icon: 'delete',
								label: t('Delete'),
								confirm: t(
									'Do you want to delete {name}? This action cannot be undone.',
									{
										name: item.name,
									}
								),
								onClick: handleDelete,
							});
						}

						return (
							<TrainingCollectionGroupItem
								key={`${item.id}:${n}`}
								item={item}
								actions={actions}
							/>
						);
					}
				)}
			</Group>
			{hasNext && fetchNext && (
				<div className={css.divider}>
					<Button
						block={isSmallScreen}
						label={t('Load more')}
						busy={isLoading}
						onClick={fetchNext}
					/>
				</div>
			)}
			{collectionId !== 0 && (
				<CollectionModal
					collectionId={collectionId}
					onClose={closeModal}
					afterSave={handleDidSave}
				/>
			)}
			{shareModal.show && (
				<ShareCollectionModal
					collectionId={shareModal.collectionId}
					onClose={closeShareModal}
				/>
			)}
		</Fragment>
	);
}
