import { ReactNode } from 'react';

export const symbol_titlecase: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M354-283v-373H247q-9 0-16-6.5t-7-15.5q0-9 7-15.5t16-6.5h263q9 0 15.5 6.5T532-678q0 9-6.5 15.5T510-656H402v373q0 9.758-7.083 16.379-7.084 6.621-17 6.621Q368-260 361-266.621q-7-6.621-7-16.379Zm261-255h-36q-7.862 0-13.431-5.598-5.569-5.597-5.569-13.5 0-7.902 5.569-13.402Q571.138-576 579-576h36v-65q0-9.333 6.266-15.667 6.266-6.333 15.5-6.333t15.734 6.333Q659-650.333 659-641v65h56q7.862 0 13.431 5.598 5.569 5.597 5.569 13.5 0 7.902-5.569 13.402Q722.862-538 715-538h-56v189q0 26 12.5 42.5T704-290q5 0 9-.5t9-.5q8 0 12.5 5.5T739-271q0 8.4-5 13.2-5 4.8-15 6.8-5 2-10.5 1.5T698-250q-35 0-59-25t-24-61v-202Z"
		/>
	</svg>
);
