import { ReactNode } from 'react';

export const symbol_bike_scooter: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M0-196v-60h174q8-72 55.5-124.5T347-447l-58-272H162q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T162-779h116q24.375 0 44.688 14Q343-751 348-728l72 336h-21q-67.65 0-115.825 47.5T235-229v33H0Zm399 76q-45.417 0-77.208-31.792Q290-183.583 290-229t31.792-77.208Q353.583-338 399-338t77.208 31.792Q508-274.417 508-229t-31.792 77.208Q444.417-120 399-120Zm0-60q20 0 34.5-14.5T448-229q0-20-14.5-34.5T399-278q-20 0-34.5 14.5T350-229q0 20 14.5 34.5T399-180Zm371-59q-72.398 0-127.199-44.5T577-398H475l-8-60h110.085Q582-488 595-515t35-46H441l-14-60h236l-54-159h-72q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T537-840h63q22 0 40.45 12.115Q658.9-815.769 667-795l59 171h44q78.85 0 134.425 55.867Q960-512.265 960-433q0 80-55 137t-135 57Zm-.5-60q54.5 0 92.5-38.5t38-93.5q0-54-38-92t-92-38h-20l31 85q5 11-.367 22.186Q775.267-442.629 763-438q-11 4-22-1.5T725-456l-31-83q-27 17-41.5 46.5T638-431.081q0 55.034 38.5 93.558Q715-299 769.5-299ZM399-229Zm370-201Z"
		/>
	</svg>
);
