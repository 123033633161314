import { t } from '@transifex/native';

import * as ContextMenu from 'design/context_menu';

const EditList = ({ item, togglePostModal, handleDeleteItem, maxWidth }) => {
	const canEdit = !!item?.links?.edit;
	const canDelete = !!item?.links?.delete;

	const onEdit = (event) => {
		event.preventDefault();
		togglePostModal();
	};

	const onDelete = (event) => {
		event.preventDefault();

		const confirmMessage = t('Do you want to delete this?', {
			_context: 'confirm messages',
		});
		const userConsent = window.confirm(confirmMessage);

		if (userConsent && canDelete) {
			handleDeleteItem(item.id);
		}
	};

	const items = [];

	if (canEdit) {
		items.push(
			<ContextMenu.Item key="edit" onClick={onEdit}>
				<ContextMenu.ItemIcon name="edit" />
				{t('Edit')}
			</ContextMenu.Item>
		);
	}

	if (canDelete) {
		items.push(
			<ContextMenu.Item key="delete" onClick={onDelete} caution>
				<ContextMenu.ItemIcon name="delete" />
				{t('Delete')}
			</ContextMenu.Item>
		);
	}

	if (!canEdit && !canDelete) return null;

	return (
		<ContextMenu.Menu maxWidth={maxWidth} key="edit-list-menu">
			{items}
		</ContextMenu.Menu>
	);
};

export default EditList;
