import { ReactNode } from 'react';

export const symbol_align_justify_stretch: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M849.825-80Q837-80 828.5-88.625T820-110v-740q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T880-850v740q0 12.75-8.675 21.375Q862.649-80 849.825-80Zm-740 0Q97-80 88.5-88.625T80-110v-740q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-850v740q0 12.75-8.675 21.375Q122.649-80 109.825-80ZM540-570q-12.75 0-21.375-8.625T510-600v-40q0-12.75 8.625-21.375T540-670h140q12.75 0 21.375 8.625T710-640v40q0 12.75-8.625 21.375T680-570H540Zm-260 0q-12.75 0-21.375-8.625T250-600v-40q0-12.75 8.625-21.375T280-670h140q12.75 0 21.375 8.625T450-640v40q0 12.75-8.625 21.375T420-570H280Zm260 280q-12.75 0-21.375-8.625T510-320v-40q0-12.75 8.625-21.375T540-390h140q12.75 0 21.375 8.625T710-360v40q0 12.75-8.625 21.375T680-290H540Zm-260 0q-12.75 0-21.375-8.625T250-320v-40q0-12.75 8.625-21.375T280-390h140q12.75 0 21.375 8.625T450-360v40q0 12.75-8.625 21.375T420-290H280Z"
		/>
	</svg>
);
