import { ReactNode } from 'react';

export const symbol_turn_slight_right: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M389.825-160Q377-160 368.5-168.625T360-190v-268q0-12.089 5-23.044Q370-492 378-500l240-240h-97q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T521-800h169q12.75 0 21.375 8.625T720-770v169q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T660-601v-97L420-458v268q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Z"
		/>
	</svg>
);
