import { ReactNode } from 'react';

export const symbol_procedure: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M760-520q-38 0-81-21.5T599-601q-37-38-58.5-81T519-761q0-17.21 5-31.383 5-14.173 15-24.296Q565-843 644.5-862.5 724-882 794-879q24 1 41 5.5t25 12.5q7 7 11.091 21.5Q875.182-825 877-803q5 70-14 153t-45.27 109.27Q808-531 792.5-525.5T760-520Zm43-143q8-32 12-70.5t5-85.37Q772-821 732-817t-75 14q23 14 44 29.667 21 15.666 39 31.333 22 20 37.5 39.5T803-663Zm-32 84q-4-31-21.5-59T696-698q-36-32-64.5-50T580-771q2 32 19.5 68t41.5 60q30 30 61 45.5t69 18.5ZM667.66-410H293q-12.444 0-23.722-5T250-428L87-591q-9-9-9-21t9-21q9-9 21-9t21 9l163.34 163H667q12.444 0 23.722 5T710-452l163 163q9 9 9 21t-9 21q-9 9-21 9t-21-9L667.66-410ZM360-90q-12.75 0-21.375-8.625T330-120v-130q0-24.75 17.625-42.375T390-310h180q24.75 0 42.375 17.625T630-250v130q0 12.75-8.625 21.375T600-90H360Zm30-60h180v-100H390v100Zm0 0v-100 100Z"
		/>
	</svg>
);
