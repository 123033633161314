import { ReactNode } from 'react';

export const symbol_privacy: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M378-666q5-2 10.5-3t11.5-1q24.75 0 42.375 17.625T460-610v26l-82-82Zm476 365L720-435v111l-60-60v-356H304l-60-60h416q24 0 42 18t18 42v215l134-134q7-7 16.5-3.458Q880-658.917 880-649v338q0 9.917-9.5 13.458Q861-294 854-301ZM660-160H140q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h19l60 60h-79v520h232v-82q-60-10-101-52t-49-100q-2-11 7.225-18.5t20.5-7.5q11.275 0 20.725 6 9.45 6 11.55 17 8 42 41 69.5t77 27.5q31 0 57.5-15t43.5-41l43 43q-21 29-51 47t-65 24v82h232v-79l60 60v19q0 24-18 42t-42 18ZM827-48 440-435q-8 7-18.286 11-10.285 4-21.714 4-24.75 0-42.375-17.625T340-480v-55L60-815q-9-9-9-21t9-21q9-9 21-9t21 9L869-90q9 9 9 21t-9 21q-9 9-21 9t-21-9ZM398-478Zm84-84ZM372-220h56-56Z"
		/>
	</svg>
);
