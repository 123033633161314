import { ReactNode } from 'react';

export const symbol_shield_moon: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M501-330q34.577 0 67.788-14.5Q602-359 628-387q6-7 5.5-11t-9.5-6q-39-8-74-31.5t-57.75-61.467q-21.667-35.915-25.458-77.474Q463-616 477-655q3-9-1-12t-13.9-.867Q400-654 364.5-605.5 329-557 329-500q0 70.833 50 120.417Q429-330 501-330ZM480-84q-5.32 0-9.88-1-4.56-1-9.12-3-139-47-220-168.5t-81-266.606V-719q0-19.257 10.875-34.662Q181.75-769.068 199-776l260-97q11-4 21-4t21 4l260 97q17.25 6.932 28.125 22.338Q800-738.257 800-719v195.894Q800-378 719-256.5T499-88q-4.56 2-9.12 3T480-84Zm0-59q115-38 187.5-143.5T740-523v-196l-260-98-260 98v196q0 131 72.5 236.5T480-143Zm0-337Z"
		/>
	</svg>
);
