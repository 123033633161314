import { Fragment, useState } from 'react';
import { T, useT } from '@transifex/react';

import Link from 'pkg/router/Link';
import * as models from 'pkg/api/models';
import * as actions from 'pkg/actions';
import { crash } from 'pkg/errors/errors';

import * as StepModal from 'components/step-modal';
import MaterialSymbol from 'components/material-symbols';
import { deleteSplashScreenState } from 'components/splash-screen';

import SplashScreenPreview from 'components/splash-screen/preview';
import {
	SettingRow,
	SettingLabel,
	SettingDescription,
	SettingControl,
	SettingRowDivider,
} from 'components/settings/Settings';
import InfoBox from 'components/form/info-box';
import * as Input from 'components/form/inputs';

import * as Context from 'design/context_menu';
import Button from 'design/button';

import * as css from './styles.css';

interface SplashScreenSettingsProps {
	group: models.group.Group;
	refresh: () => void;
}

export default function SplashScreenSettings({
	group,
	refresh,
}: SplashScreenSettingsProps): JSX.Element {
	const t = useT();

	const splashScreen = models.group.getSplashScreenSettings(group);

	const [isEditing, setEditing] = useState<boolean>(false);
	const [isActive, setActive] = useState<boolean>(!!splashScreen?.enabled);

	const handleOpenEditor = () => setEditing(true);
	const handleCloseEditor = () => setEditing(false);

	const toggleSplashScreen = async () => {
		const enabled = !isActive;

		const [success] = await actions.organizations.updateSplashScreen(group.id, {
			enabled,
		});

		if (!success) {
			const { title } = crash();

			actions.flashes.show({
				title,
				message: t('Could not update splash screen visibility.'),
			});
		} else {
			const message = enabled
				? t('Enabled custom splash screen.')
				: t('Disabled custom splash screen');

			actions.flashes.show({
				title: t('Updated Splash Screen'),
				message,
			});

			setActive(enabled);

			if (enabled) {
				actions.organizations.hydrateSplashScreenStateFromGroup(group);
			} else {
				deleteSplashScreenState();
			}
		}

		refresh();
	};

	return (
		<Fragment>
			<SettingRowDivider />
			<SettingRow>
				<SettingLabel>{t('Splash Screen')}</SettingLabel>
				<SettingDescription>
					{isActive ? t('Active') : t('Inactive')}
				</SettingDescription>
				<SettingControl>
					<Context.Menu
						toggleWith={
							<Button small icon="settings">
								{t('Configure')}
							</Button>
						}>
						<Context.Item
							onClick={toggleSplashScreen}
							closeOnClick={false}
							className={css.contextItemWithToggle}>
							<Context.ItemIcon name="settings_applications" />
							{t('Splash screen active?')}
							<Input.Control standalone type="toggle" checked={isActive} />
						</Context.Item>
						<Context.Item onClick={handleOpenEditor}>
							<Context.ItemIcon name="planner_banner_ad_pt" />
							{t('Edit sponsor image')}
						</Context.Item>
					</Context.Menu>
				</SettingControl>
			</SettingRow>
			{isEditing && (
				<StepModal.Base onClose={handleCloseEditor}>
					<StepModal.Step
						title={t('Configure Splash Screen')}
						nextLabel={t('Save')}>
						<InfoBox>
							<p>
								<T
									_str="If you need help with customizing your splash screen, {link} in our help center."
									link={
										<Link href="https://help.360player.com/article/221-customize-your-splash-screen">
											{t('read this article')}{' '}
											<MaterialSymbol
												variant="open_in_new"
												className={css.helpLinkSymbol}
											/>
										</Link>
									}
								/>
							</p>
						</InfoBox>

						<SplashScreenPreview group={group} refresh={refresh} />
					</StepModal.Step>
				</StepModal.Base>
			)}
		</Fragment>
	);
}
