import { ReactNode } from 'react';

export const symbol_open_run: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m480-165 67-67q9-9 21-9t21 9q9 9 9 21.5t-9 21.5l-88 88q-5 5-10 7t-11 2q-6 0-11-2t-10-7l-89-89q-9-9-8.5-21t9.5-21q9-9 21-9t21 9l67 67ZM165-480l67 67q9 9 9 21t-9 21q-9 9-21.5 9t-21.5-9l-88-88q-5-5-7-10t-2-11q0-6 2-11t7-10l89-89q9-9 21-8.5t21 9.5q9 9 9 21t-9 21l-67 67Zm630 0-67-67q-9-9-9-21t9-21q9-9 21.5-9t21.5 9l88 88q5 5 7 10t2 11q0 6-2 11t-7 10l-89 89q-9 9-21 8.5t-21-9.5q-9-9-9-21t9-21l67-67ZM480-795l-67 67q-9 9-21 9t-21-9q-9-9-9-21.5t9-21.5l88-88q5-5 10-7t11-2q6 0 11 2t10 7l89 89q9 9 8.5 21t-9.5 21q-9 9-21 9t-21-9l-67-67Z"
		/>
	</svg>
);
