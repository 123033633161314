import { ReactNode } from 'react';

export const symbol_backlight_low: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M110-370q-12.75 0-21.375-8.675Q80-387.351 80-400.175 80-413 88.625-421.5T110-430h50q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T160-370h-50Zm172-228q-9 9-21 9t-21-9l-43-43q-9-9-9-21t9-21q9-9 21-9t21 9l43 43q9 9 9 21t-9 21Zm48 358q-20.833 0-35.417-14.618Q280-269.235 280-290.118 280-311 294.583-325.5 309.167-340 330-340h300q20.833 0 35.417 14.618Q680-310.765 680-289.882 680-269 665.417-254.5 650.833-240 630-240H330Zm149.825-440Q467-680 458.5-688.625T450-710v-60q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-770v60q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Zm196.784 82Q668-607 668-619t9-21l43-43q9-9 21-8.5t21.391 9.5Q771-673 771-661t-9 21l-43 43q-9 9-21 8.5t-21.391-9.5ZM800-370q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T800-430h50q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-370h-50Z"
		/>
	</svg>
);
