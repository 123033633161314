import { t } from '@transifex/native';

import { days } from 'pkg/date';
import * as models from 'pkg/api/models';

import { startingDaysIndex } from 'routes/scheduling/utils';

import * as Input from 'components/form/inputs';
import Column from 'components/layout/column';

interface TemplateFormProps {
	schedule?: models.schedule.Schedule;
}

const TemplateForm = ({ schedule }: TemplateFormProps) => {
	const defaultValues = {
		title: schedule?.title || '',
		description: schedule?.description || '',
		startingDay: !!schedule ? schedule?.startingDay : 1,
		days: schedule?.days || 7,
	};

	const weekDays = days();

	return (
		<Column>
			<Input.Group label={t('Title')}>
				<Input.Field name="title" required defaultValue={defaultValues.title} />
			</Input.Group>
			<Input.Group label={t('Description')} optional>
				<Input.Field
					name="description"
					defaultValue={defaultValues.description}
				/>
			</Input.Group>
			<Input.Group
				label={t(`Starting day`)}
				disabled={!!schedule?.scheduleItems}>
				<Input.Select
					name="startingDay"
					defaultValue={defaultValues.startingDay}>
					{Object.entries(weekDays).map(([key, value]) => (
						<option
							key={key}
							value={startingDaysIndex[Number.parseInt(key, 10)]}>
							{value[1]}
						</option>
					))}
				</Input.Select>
			</Input.Group>
		</Column>
	);
};

export default TemplateForm;
