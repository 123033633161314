import { ReactNode } from 'react';

export const symbol_co2: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M440-366q-14.45 0-24.225-9.775Q406-385.55 406-400v-160q0-14.45 9.775-24.225Q425.55-594 440-594h120q14.45 0 24.225 9.775Q594-574.45 594-560v160q0 14.45-9.775 24.225Q574.45-366 560-366H440Zm14-48h92v-132h-92v132Zm232 144v-70q0-14.45 9.775-24.225Q705.55-374 720-374h86v-52h-97q-9.517 0-16.259-6.5Q686-439 686-449.92q0-10.08 6.5-17.08 6.5-7 17.5-7h110q14.45 0 24.225 9.775Q854-454.45 854-440v60q0 14.45-9.775 24.225Q834.45-346 820-346h-86v52h96q9.6 0 16.8 7.2 7.2 7.2 7.2 16.8 0 11-7.2 17.5T830-246H710q-11 0-17.5-6.5T686-270Zm-526-90q-14.45 0-24.225-9.775Q126-379.55 126-394v-172q0-14.45 9.775-24.225Q145.55-600 160-600h139q14.45 0 24.225 9.775Q333-580.45 333-566v23q0 9.517-7.2 16.259Q318.6-520 309-520q-11 0-17.5-6.5T285-544v-8H174v144h111v-8q0-9.6 6.5-16.8 6.5-7.2 17.42-7.2 10.08 0 17.08 7.2 7 7.2 7 16.8v22q0 14.45-9.775 24.225Q313.45-360 299-360H160Z"
		/>
	</svg>
);
