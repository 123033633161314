import { t } from '@transifex/native';
import { useRef, useEffect, useContext, useState } from 'react';
import styled from 'styled-components';
import useResizeObserver from 'use-resize-observer';

import { FabricContext } from 'components/drawing/FabricContext';
import { DEFAULT_ZOOM, MAX_ZOOM, MIN_ZOOM } from 'components/drawing/config';
import ToolName from 'components/drawing/edit/ToolName';

import Button from 'design/button';

const _context = 'training_library/drawing';

export default function Artboard({ className, children }: any) {
	const ref = useRef<HTMLDivElement>(null);
	const { width, height } = useResizeObserver<HTMLDivElement>({ ref });

	const {
		canvas,
		zoom,
		setZoom,
		spacebarDown,
		triggeredTool,
		setTriggeredTool,
	} = useContext(FabricContext);

	const [dimension, setDimension] = useState([0, 0]);
	const [outsideArtboard, setOutsideArtboard] = useState(false);

	useEffect(() => {
		if (width) {
			const canvasAspect =
				(canvas.current.backgroundWidth || 750) /
				(canvas.current.backgroundHeight || 750);
			const outerAspect = width / height;
			const isTallerThanArtboard = canvasAspect < outerAspect;

			let w, h;
			if (isTallerThanArtboard) {
				h = height * zoom * DEFAULT_ZOOM;
				w = h * canvasAspect;
			} else {
				w = width * zoom * DEFAULT_ZOOM;
				h = w / canvasAspect;
			}

			const isOutsideArtboard = w > width || h > height;
			setOutsideArtboard(isOutsideArtboard);
			if (w > width) w = width;
			if (h > height) h = height;

			const renderedAspect = w / h;

			// start "cropping" canvas when zooming
			if (isOutsideArtboard) {
				if (isTallerThanArtboard) {
					canvas.current.setWidth(
						canvas.current.backgroundHeight * renderedAspect
					);
				} else {
					canvas.current.setHeight(
						canvas.current.backgroundWidth / renderedAspect
					);
				}
			} else if (
				canvas.current.height !== canvas.current.backgroundHeight ||
				canvas.current.width !== canvas.current.backgroundWidth
			) {
				// reset canvas size when zooming back out
				canvas.current.setWidth(canvas.current.backgroundWidth);
				canvas.current.setHeight(canvas.current.backgroundHeight);
			}

			// set canvas container dimensions
			setDimension([w, h]);
		}
	}, [canvas.current.width, canvas.current.height, width, height, zoom]);

	const onArtboardClick = (e: any) => {
		// clicked the artboard (not a descendant)
		if (e.target === e.currentTarget) {
			canvas.current.discardActiveObject();
			canvas.current.renderAll();
			setTriggeredTool(null);
		}
	};

	const onZoom = (e: any) => {
		const delta = e.deltaY;
		let newZoom = zoom;
		newZoom *= 0.999 ** delta;
		if (newZoom > MAX_ZOOM) newZoom = MAX_ZOOM;
		if (newZoom < MIN_ZOOM) newZoom = MIN_ZOOM;
		setZoom(newZoom);
		// setZoomPoint([e.offsetX, e.offsetY]);
		e.preventDefault();
		e.stopPropagation();
	};

	useEffect(() => {
		const artboard = ref?.current;
		if (!canvas.current || !artboard) return;

		artboard.addEventListener('click', onArtboardClick);
		artboard.addEventListener('wheel', onZoom);

		return () => {
			artboard.removeEventListener('click', onArtboardClick);
			artboard.removeEventListener('wheel', onZoom);
		};
	}, [zoom, canvas.current, ref]);

	return (
		<CanvasWrapper
			ref={ref}
			className={
				className +
				(outsideArtboard ? '' : ' full') +
				(triggeredTool ? ' triggered' : '') +
				(spacebarDown === 'pannable' ? ' pannable' : '') +
				(spacebarDown === 'panning' ? ' panning' : '')
			}>
			<div
				style={{
					width: dimension[0],
					height: dimension[1],
				}}>
				{children}
			</div>

			{triggeredTool && (
				<TriggeredTool>
					<strong>{t('Active tool', { _context })}: </strong>
					<span>
						<ToolName tool={triggeredTool} />
					</span>
					<Button
						small
						primary
						onClick={() => {
							setTriggeredTool(null);
						}}>
						{t('Done')}
					</Button>
				</TriggeredTool>
			)}
		</CanvasWrapper>
	);
}

const CanvasWrapper = styled.div`
	position: relative;
	display: grid;
	align-content: center;
	justify-items: center;

	.canvas-container {
		overflow: hidden;
		box-shadow: 0px 2px 11px 2px rgba(0, 0, 0, 0.1);
		width: 100% !important;
		height: 100% !important;
		transition: border-radius 0.2s;

		canvas {
			width: 100% !important;
			height: 100% !important;
		}
	}

	&.full .canvas-container {
		border-radius: 3%;
	}
	&.triggered .upper-canvas {
		cursor: crosshair !important;
	}
	&.pannable .upper-canvas {
		cursor: grab !important;
	}
	&.panning .upper-canvas {
		cursor: grabbing !important;
	}
`;

const TriggeredTool = styled.div`
	display: flex;
	gap: var(--spacing-3);
	justify-content: center;
	align-items: baseline;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	text-align: center;
	padding: 5px;
	background: var(--palette-blue-200);
	border-bottom: 1px solid var(--palette-gray-300);
`;
