// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aH4p89jQwE4iK8_6Lg8p {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
}

.CBnl510JBA4bv_vJdHPl {
	display: grid;
	grid-auto-flow: column;
	-webkit-box-pack: start;
	    -ms-flex-pack: start;
	        justify-content: start;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	grid-gap: var(--spacing-3);
}

@media (max-width: 768px) {

.CBnl510JBA4bv_vJdHPl {
		grid-gap: var(--spacing-4);
}
	}

.c6aPC1Um7py3sStYSUdg {
	font-size: var(--font-size-sm);
	font-weight: var(--font-weight-semibold);
}

@media (max-width: 768px) {

.c6aPC1Um7py3sStYSUdg {
		font-size: var(--font-size-base);
}
	}

.jEuUMZ_wzlsy2myGql3W {
	font-size: var(--font-size-xs);
	color: var(--palette-gray-500);
}

@media (max-width: 768px) {

.jEuUMZ_wzlsy2myGql3W {
		font-size: var(--font-size-sm);
}
	}

.Y5rgpMhzf8A_8i0b4bTK {
	color: var(--palette-main-actionable-link-color);
	font-weight: var(--font-weight-semibold);
	cursor: pointer;
}

/* Button styles needs !important to apply properly */

.praGdacqaQYQQxovkdYP {
	color: var(--palette-gray-500) !important;
}

@media (hover: hover) {
		.praGdacqaQYQQxovkdYP:hover {
			color: var(--palette-gray-700) !important;
		}
	}
`, "",{"version":3,"sources":["webpack://./routes/event/components/styles.css"],"names":[],"mappings":"AAAA;CACC,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,yBAA8B;KAA9B,sBAA8B;SAA9B,8BAA8B;CAC9B,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;AACpB;;AAEA;CACC,aAAa;CACb,sBAAsB;CACtB,uBAAsB;KAAtB,oBAAsB;SAAtB,sBAAsB;CACtB,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CACnB,0BAA0B;AAK3B;;AAHC;;AAPD;EAQE,0BAA0B;AAE5B;CADC;;AAGD;CACC,8BAA8B;CAC9B,wCAAwC;AAKzC;;AAHC;;AAJD;EAKE,gCAAgC;AAElC;CADC;;AAGD;CACC,8BAA8B;CAC9B,8BAA8B;AAK/B;;AAHC;;AAJD;EAKE,8BAA8B;AAEhC;CADC;;AAGD;CACC,gDAAgD;CAChD,wCAAwC;CACxC,eAAe;AAChB;;AAEA,qDAAqD;;AACrD;CACC,yCAAyC;AAO1C;;AALC;EACC;GACC,yCAAyC;EAC1C;CACD","sourcesContent":[".listItem {\n\tdisplay: flex;\n\tjustify-content: space-between;\n\talign-items: center;\n}\n\n.infoWrapper {\n\tdisplay: grid;\n\tgrid-auto-flow: column;\n\tjustify-content: start;\n\talign-items: center;\n\tgrid-gap: var(--spacing-3);\n\n\t@media (--small-viewport) {\n\t\tgrid-gap: var(--spacing-4);\n\t}\n}\n\n.name {\n\tfont-size: var(--font-size-sm);\n\tfont-weight: var(--font-weight-semibold);\n\n\t@media (--small-viewport) {\n\t\tfont-size: var(--font-size-base);\n\t}\n}\n\n.role {\n\tfont-size: var(--font-size-xs);\n\tcolor: var(--palette-gray-500);\n\n\t@media (--small-viewport) {\n\t\tfont-size: var(--font-size-sm);\n\t}\n}\n\n.loadMore {\n\tcolor: var(--palette-main-actionable-link-color);\n\tfont-weight: var(--font-weight-semibold);\n\tcursor: pointer;\n}\n\n/* Button styles needs !important to apply properly */\n.remove {\n\tcolor: var(--palette-gray-500) !important;\n\n\t@media (hover: hover) {\n\t\t&:hover {\n\t\t\tcolor: var(--palette-gray-700) !important;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var listItem = `aH4p89jQwE4iK8_6Lg8p`;
export var infoWrapper = `CBnl510JBA4bv_vJdHPl`;
export var name = `c6aPC1Um7py3sStYSUdg`;
export var role = `jEuUMZ_wzlsy2myGql3W`;
export var loadMore = `Y5rgpMhzf8A_8i0b4bTK`;
export var remove = `praGdacqaQYQQxovkdYP`;
export default ___CSS_LOADER_EXPORT___;
