import { ReactNode } from 'react';

export const symbol_trail_length_short: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M600-280q-73 0-129-46.5T402-450H190q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T190-510h211.809Q407-542 422-571.5t37-48.5H270q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T270-680h330q83 0 141.5 58.5T800-480q0 83-58.5 141.5T600-280Zm-.235-60Q658-340 699-380.765q41-40.764 41-99Q740-538 699.235-579q-40.764-41-99-41Q542-620 501-579.235q-41 40.764-41 99Q460-422 500.765-381q40.764 41 99 41ZM310-280q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T310-340h60q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T370-280h-60Zm290-200Z"
		/>
	</svg>
);
