import { t } from '@transifex/native';
import styled from 'styled-components';

import * as models from 'pkg/api/models';

import Icon from 'components/icon';

import * as iconStyles from 'components/icon/styles.css';
import Column from 'components/layout/column';

import Button from 'design/button';

const Wrapper = styled.div`
	margin: 0 auto;
	background-color: var(--palette-blue-100);
	padding: var(--spacing-5) var(--spacing-5) var(--spacing-6) var(--spacing-5);
	border-radius: var(--radius-5);
`;

const Description = styled.div`
	font-size: var(--font-size-2xs);
	text-align: center;
	word-break: normal;
`;

const IconWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	font-weight: var(--font-weight-semibold);
	word-break: normal;

	.${iconStyles.icon} {
		height: 55px;
		width: 55px;
		color: var(--palette-blue-500);
	}
`;

const BillingMissingEntity: React.FC<
	React.PropsWithChildren<{
		contact: models.user.User;
		handleOpenModal: (name: string) => void;
	}>
> = ({ contact, handleOpenModal }) => {
	const showSelectContact = () => handleOpenModal('selectContact');

	return (
		<Wrapper>
			<Column>
				<IconWrapper>
					<Icon name="user-add" />
					{t(`Connect this contact to a separate billing contact.`)}
				</IconWrapper>
				<Button
					disabled={!!contact.deletedAt}
					icon="add"
					testid="contacts.single.add_billing_contact"
					onClick={showSelectContact}>
					{t(`Add billing contact`)}
				</Button>
				<Description>
					{t(
						`All invoices/subscriptions assigned to {name} would automatically be sent to the billing contact.`,
						{
							name: models.user.fullName(contact),
						}
					)}
				</Description>
			</Column>
		</Wrapper>
	);
};

export default BillingMissingEntity;
