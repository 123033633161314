import { ReactNode } from 'react';

export const symbol_frame_reload: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-280q-57 0-104-29.043T303-387q-7-13-3-25.5t17-17q11-4.5 22.5-.5t16.5 15q18.099 33.904 51.282 54.452Q440.464-340 479.68-340 538-340 579-381q41-41 41-99t-41-99q-41-41-99-41-28 0-53 10t-45 30h28q13 0 21.5 8.5T440-550q0 13-8.5 21.5T410-520H310q-12.75 0-21.375-8.625T280-550v-100q0-13 8.5-21.5T310-680q13 0 21.5 8.5T340-650v27q29-27 65-42t75-15q83 0 141.5 58.5T680-480q0 83-58.5 141.5T480-280ZM180-120q-24 0-42-18t-18-42v-142q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T180-322v142h142q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T322-120H180Zm600 0H638q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T638-180h142v-142q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T840-322v142q0 24-18 42t-42 18ZM120-638v-142q0-24 18-42t42-18h142q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T322-780H180v142q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T120-638Zm660 0v-142H638q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T638-840h142q24 0 42 18t18 42v142q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T780-638Z"
		/>
	</svg>
);
