import { useDispatch } from 'react-redux';
import { useT } from '@transifex/react';

import * as models from 'pkg/api/models';
import * as actions from 'pkg/actions';
import * as endpoints from 'pkg/api/endpoints/auto';

import { DialogActivator, useDialog } from 'design/dialog';

type ActionCallback = () => Promise<void>;

export function useRemoveAccountRelation(
	accountRelation: models.accountRelation.AccountRelation,
	afterRemove?: ActionCallback
): ActionCallback {
	const t = useT();
	const dispatch = useDispatch();

	const removeAccountRelation: ActionCallback = async () => {
		await dispatch(actions.accountRelations.remove(accountRelation.id));

		const fullName = models.account.fullName(accountRelation.targetAccount);

		actions.flashes.show({
			title: t(`Account relation removed`),
			message: t(`You are no longer connected to {fullName}`, {
				fullName,
			}),
		});

		if (afterRemove) {
			await afterRemove();
		}
	};

	return removeAccountRelation;
}

export function useRemoveSubmission(
	submission: models.formSubmission.FormSubmission,
	afterRemove?: ActionCallback
): DialogActivator {
	const t = useT();

	return useDialog({
		title: t('Delete submission'),
		message: t('This action cannot be undone.'),
		confirmLabel: t('Delete'),
		destructive: true,
		onConfirm: async () => {
			if (models.canDelete(submission)) {
				await models.destroy(submission);

				if (afterRemove) {
					await afterRemove();
				}
			}
		},
	});
}

export function useMarkAsPaid(
	order: models.order.Order,
	afterRemove?: ActionCallback
): DialogActivator {
	const t = useT();

	return useDialog({
		wide: true,
		title: t('Mark as paid?'),
		message: t('Invoice "{id}" will be marked as paid.', {
			id: order.invoiceNumber.toString() ?? t('Unknown ID'),
		}),
		confirmLabel: t('Mark as paid'),
		onConfirm: async () => {
			const didMarkAs = await actions.order.markAs(
				endpoints.Orders.MarkPaid(),
				[order.id],
				t('Invoice successfully marked as paid.')
			);

			if (didMarkAs && afterRemove) {
				await afterRemove();
			}
		},
	});
}

export function useMarkAsUncollectible(
	order: models.order.Order,
	afterRemove?: ActionCallback
): DialogActivator {
	const t = useT();

	return useDialog({
		wide: true,
		title: t('Mark as uncollectible?'),
		message: t('Invoice "{id}" will be marked as uncollectible.', {
			id: order.invoiceNumber.toString() ?? t('Unknown ID'),
		}),
		confirmLabel: t('Mark as uncollectible'),
		onConfirm: async () => {
			const didMarkAs = await actions.order.markAs(
				endpoints.Orders.MarkUncollectible(),
				[order.id],
				t('Invoice marked as uncollectible.')
			);

			if (didMarkAs && afterRemove) {
				await afterRemove();
			}
		},
	});
}

export function useMarkAsRefunded(
	order: models.order.Order,
	afterRemove?: ActionCallback
): DialogActivator {
	const t = useT();

	return useDialog({
		wide: true,
		title: t('Mark as refunded?'),
		message: t(
			'Marking this invoice as refunded is permanent and cannot be undone.'
		),
		confirmLabel: t('Mark as refunded'),
		onConfirm: async () => {
			const didRefund = await actions.order.refund(order.id);

			if (didRefund && afterRemove) {
				await afterRemove();
			}
		},
	});
}

export function useMarkAsVoid(
	order: models.order.Order,
	afterRemove?: ActionCallback
): DialogActivator {
	const t = useT();

	return useDialog({
		wide: true,
		destructive: true,
		title: t('Void invoice?'),
		message: t(
			'You are about to void this invoice, this action cannot be undone.'
		),
		confirmLabel: t('Void invoice'),
		onConfirm: async () => {
			const didMark = await actions.order.markAs(
				endpoints.Orders.MarkVoid(),
				[order.id],
				t('Successfully voided invoice.')
			);

			if (didMark && afterRemove) {
				await afterRemove();
			}
		},
	});
}

export function useRemoveInvoice(
	order: models.order.Order,
	afterRemove?: ActionCallback
): DialogActivator {
	const t = useT();

	return useDialog({
		wide: true,
		destructive: true,
		title: t('Remove invoice?'),
		message: t(
			'You are about to permanently delete this invoice, this action cannot be undone.'
		),
		confirmLabel: t('Delete invoice'),
		onConfirm: async () => {
			const didRemove = await actions.order.remove([order.id]);

			if (didRemove && afterRemove) {
				await afterRemove();
			}
		},
	});
}
