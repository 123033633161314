import { normalize } from 'normalizr';
import { Dispatch } from 'redux';

import Video from 'pkg/models/video';

import * as service from 'pkg/actions/services/user_videos';
import * as actionTypes from 'pkg/actions/action-types';
import { userVideosSchema } from 'pkg/actions/entities/video';
import { forAccountHeaders } from 'pkg/actions/utils';

import * as sdk from 'pkg/core/sdk';

const addVideos = (
	userId: number,
	payload: { entities: Video[]; result: number[] },
	links = { prev: '', next: '' }
) => ({
	type: actionTypes.UserVideos.ADD_ITEMS,
	userId,
	payload,
	links,
});

const resetVideos = () => ({
	type: actionTypes.UserVideos.RESET_ITEMS,
});

export const fetchTaggedVideos =
	(
		userId: number,
		groupId?: number,
		endpointUrlOverride?: string,
		forAccount?: number
	) =>
	async (
		dispatch: Dispatch
	): Promise<
		[
			Response,
			{ collectionOrder: string; recordOrder: string; records: Video[] },
		]
	> => {
		dispatch(resetVideos());

		const headers = forAccountHeaders(forAccount);

		let response;

		if (!endpointUrlOverride) {
			response = await service.getTaggedVideos(userId, groupId, forAccount);
		} else {
			response = await sdk.get(endpointUrlOverride, {}, {}, headers);
		}

		if (response.ok) {
			const result = await response.json();
			const data: {
				entities: Video[];
				result: number[];
			} = normalize(result.records, [userVideosSchema]);

			dispatch(addVideos(userId, data, result.links));

			return [response, result];
		}
		return [response, null];
	};
