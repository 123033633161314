import {
	useState,
	useEffect,
	useRef,
	useMemo,
	createRef,
	cloneElement,
	Children,
	ReactNode,
	ReactElement,
} from 'react';
import styled from 'styled-components';

import useIntersectionObserver from 'pkg/hooks/useIntersectionObserver';

import { SubNavItem } from 'components/navigation/header/large_screen/Styles';

import * as ContextMenu from 'design/context_menu';

const NavList = styled.div`
	display: flex;
	position: relative;

	@media print {
		display: none;
	}
`;

interface LargeScreenSubNavProps {
	toggleText?: string;
	children: ReactNode;
}

export const LargeScreenSubNav = ({
	toggleText,
	children,
}: LargeScreenSubNavProps): JSX.Element => {
	const target = useRef<HTMLDivElement>(null);

	const items = useMemo(
		() =>
			Children.toArray(children)
				.filter((n) => n)
				.map((n: ReactElement) =>
					cloneElement(n, {
						forwardRef: createRef<Element>(),
					})
				),
		[children]
	);

	const [visible, setVisible] = useState<number[]>(items.map(() => 1));

	const [observe] = useIntersectionObserver({
		root: target.current,
		threshold: 1,
		callback: ([entry]) => {
			if (target.current && target.current?.childNodes.length > 0) {
				const index = Array.from(target.current?.childNodes).indexOf(
					entry.target
				);

				if (index >= 0) {
					const visibleCopy = [...visible];

					visibleCopy.splice(index, 1, entry.isIntersecting ? 1 : 0);

					setVisible(visibleCopy);
				}
			}
		},
	});

	useEffect(() => {
		if (items.length && observe) {
			observe(items.map((item: ReactElement) => item.props.forwardRef.current));

			setVisible(items.map(() => 1));
		}
	}, [items]);

	let cutOffIndex = visible.indexOf(0) - 1;

	if (cutOffIndex < -1) cutOffIndex = -1;

	const visibleItems = cutOffIndex > -1 ? items.slice(0, cutOffIndex) : items;

	const hiddenItems = cutOffIndex > -1 ? items.slice(cutOffIndex) : [];

	return (
		<NavList ref={target} data-testid="sub_navigation">
			{visibleItems}
			{hiddenItems.length > 0 && (
				<ContextMenu.Menu
					toggleWith={<SubNavItem as="div">{toggleText}</SubNavItem>}
					appearFrom={ContextMenu.AppearFrom.TopRight}
					offsetHorizontal={15}
					offsetVertical={40}>
					{hiddenItems.map((item, i) => (
						<ContextMenu.LinkItem key={`ctxi-${i}`} {...item.props} />
					))}
				</ContextMenu.Menu>
			)}
		</NavList>
	);
};
