import { ReactNode } from 'react';

export const symbol_man_4: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m416-106-67-499q-4-27 14.286-47.5Q381.571-673 409-673h142q27.429 0 45.714 20.5Q615-632 611-605l-67 499q-2 11-10.605 18.5T513.566-80h-67.342Q435-80 426.5-87.5T416-106Zm64.08-628q-30.08 0-51.58-21.42-21.5-21.421-21.5-51.5 0-30.08 21.42-51.58 21.421-21.5 51.5-21.5 30.08 0 51.58 21.42 21.5 21.421 21.5 51.5 0 30.08-21.42 51.58-21.421 21.5-51.5 21.5Z"
		/>
	</svg>
);
