/* @dependencies */
import { Component } from 'react';
import PropTypes from 'prop-types';

/* @imports */
import ColorConverter from 'pkg/colorconverter';

import ColorPicker from 'components/form/ColorPicker';

/* @components */

const NOOP = () => {};

class ColorPickerController extends Component {
	state = {
		hue: 55,
		saturation: 100,
		lightness: 50,

		h: 55,
		s: 100,
		l: 50,
	};

	constructor(props) {
		super(props);

		this.color = new ColorConverter(55, 100, 50);
	}

	componentDidMount() {
		this.resetColorValues();
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const previousValues = [prevState.h, prevState.s, prevState.l];

		const initialValuesHasChanged = nextProps.hsl.every(
			(n, i) => previousValues[i] !== n
		);

		if (initialValuesHasChanged) {
			let [h, s, l] = nextProps.hsl;

			if (isNaN(h)) {
				h = 55;
				s = 100;
				l = 50;
			}

			return { h, s, l };
		}

		return null;
	}

	componentDidUpdate(prevProps) {
		const didChange = this.props.hsl.every((n) => !prevProps.hsl.includes(n));

		if (didChange) {
			const [hue, saturation, lightness] = this.props.hsl;

			this.setState({
				hue,
				saturation,
				lightness,
			});
		}
	}

	getColorValues() {
		return [this.state.hue, this.state.saturation, this.state.lightness];
	}

	getSelectedColorValues() {
		return [this.state.h, this.state.s, this.state.l];
	}

	resetColorValues() {
		const [h, s, l] = this.props.hsl;

		this.setHue(h);
		this.setSaturation(s);
		this.setLightness(l);
	}

	setHue = (hue) => this.setState({ hue });

	setSaturation = (saturation) => this.setState({ saturation });

	setLightness = (lightness) => this.setState({ lightness });

	setFromHex = (hexString) => {
		const [hue, saturation, lightness] = this.color.fromHEX(hexString);

		this.setState({ hue, saturation, lightness });
	};

	setFromRgb = (rgbString) => {
		const rgb = rgbString.split(',').map((n) => parseInt(n.trim()));

		const [hue, saturation, lightness] = this.color.fromRGB(...rgb);

		this.setState({
			hue,
			saturation,
			lightness,
		});
	};

	handleSelect = (color) => {
		const [h, s, l] = this.color.fromHSL(
			...color.hsl.split(',').map((n) => Number.parseInt(n, 10))
		);

		this.setState({ h, s, l }, () => this.props.onSelect(color));
	};

	handleCancel = () => this.resetColorValues();

	handleValid = () => this.props.onValid();

	handleError = () => this.props.onError();

	render() {
		return (
			<ColorPicker
				initialValues={this.getSelectedColorValues()}
				currentValues={this.getColorValues()}
				onHueChange={this.setHue}
				onSaturationChange={this.setSaturation}
				onLightnessChange={this.setLightness}
				onHexChange={this.setFromHex}
				onRgbChange={this.setFromRgb}
				onSelect={this.handleSelect}
				onValid={this.handleValid}
				onError={this.handleError}
				pickerTrigger={this.props.pickerTrigger}
			/>
		);
	}
}

ColorPickerController.propTypes = {
	hsl: PropTypes.array.isRequired,

	onSelect: PropTypes.func,
	onValid: PropTypes.func,
	onError: PropTypes.func,
};

ColorPickerController.defaultProps = {
	onSelect: NOOP,
	onValid: NOOP,
	onError: NOOP,
};

export default ColorPickerController;
