import { Fragment, useState } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import rgba from 'pkg/rgba';
import * as models from 'pkg/api/models';
import { useCurrentAccountUserIds, useCurrentAccountWards } from 'pkg/identity';
import { useOrganizationIdentity } from 'pkg/identity/organization';

import { ShowMonitoredChatsButton } from 'routes/chat/view/ShowMonitoredChatsButton';

import ChatListItem from 'containers/chat/ChatListItem';
import Search from 'containers/chat/Search';

import Icon from 'components/icon';

import { Spinner } from 'components/loaders/spinner';

import InlinePlaceholder from 'design/placeholders/inline';
import Button from 'design/button';

const Wrapper = styled.div`
	display: flex;
	flex-flow: column;
	grid-area: chatlist;
	position: relative;
	min-height: 100%;
	min-width: 0;
	border-right: var(--palette-gray-300) 1px solid;
	-webkit-overflow-scrolling: touch;
	overflow: scroll;
	background: var(--palette-gray-100);
	padding-bottom: var(--spacing-7);

	@media ${styles.breakpoint.small} {
		background: var(--palette-white);
	}

	${InlinePlaceholder} {
		margin: auto 0;
	}
`;

const ChatSpinner = styled(Spinner)`
	margin: auto 0;
	align-self: center;
`;

const ShowArchivedChats = styled.div`
	display: flex;
	justify-content: center;
	padding: 1rem;
`;

const ArchivedChatsTitle = styled.strong`
	padding: 1rem 1rem 0.5rem 1rem;
	font-weight: var(--font-weight-semibold);
	font-size: 0.86rem;
	color: var(--palette-gray-500);
	border-bottom: ${rgba(styles.palette.black, 0.1)} 1px solid;
`;

interface ChatListProps {
	currentChatId: number;
	loading: boolean;
	replaceState?: boolean;

	chatRecords: models.chat.Chat[];
}

function ChatList({
	currentChatId,
	replaceState,
	loading,
	chatRecords,
}: ChatListProps) {
	const [filterValue, setFilterValue] = useState('');
	const [showArchivedChats, setShowArchivedChats] = useState(false);

	const wards = useCurrentAccountWards();
	const { users } = useOrganizationIdentity();
	const activeUserIds = useCurrentAccountUserIds();

	const chats = models.chat
		.sortByLastMessageId(
			models.chat.findAllActiveChats(chatRecords, activeUserIds)
		)
		.sort((a, b) => Number(!!b.isNew) - Number(!!a.isNew));
	const archivedChats = models.chat.findAllArchivedChats(
		chatRecords,
		activeUserIds
	);
	const filteredChats = models.chat.findAllBySearch(chats, filterValue, users);

	const renderedChats = filterValue.length > 0 ? filteredChats : chats;

	const isSearching = filterValue.length > 0;
	const hasRenderedChats = renderedChats.length > 0;
	const hasArchivedChats = archivedChats.length > 0;
	const renderSearch = hasRenderedChats || isSearching;

	return (
		<Wrapper>
			{renderSearch && <Search onChange={setFilterValue} />}

			{renderedChats.map((chat) => (
				<ChatListItem
					key={chat.id}
					replaceState={replaceState}
					chat={chat}
					currentChatId={currentChatId}
				/>
			))}

			{showArchivedChats && archivedChats.length > 0 && (
				<Fragment>
					<ArchivedChatsTitle>{t('Archived chats')}</ArchivedChatsTitle>

					{archivedChats.map((chat) => (
						<ChatListItem
							key={chat.id}
							replaceState={replaceState}
							chat={chat}
							currentChatId={currentChatId}
						/>
					))}
				</Fragment>
			)}

			<ShowMonitoredChatsButton wards={wards} />

			{!showArchivedChats && hasArchivedChats && (
				<ShowArchivedChats>
					<Button
						transparent
						block
						onClick={() => {
							setShowArchivedChats(true);
						}}
						icon="archive"
						label={t('Show archived chats')}
						testid="chat.show_archived_button"
					/>
				</ShowArchivedChats>
			)}

			{loading && <ChatSpinner />}

			{!isSearching && !hasRenderedChats && !loading && (
				<InlinePlaceholder>
					<Icon name="add-conversation" />
					{t('You have no conversations')}
				</InlinePlaceholder>
			)}

			{isSearching && !hasRenderedChats && (
				<InlinePlaceholder>
					<Icon name="search" />
					{t('No conversations found')}
				</InlinePlaceholder>
			)}
		</Wrapper>
	);
}

export default ChatList;
