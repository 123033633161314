import styled, { css } from 'styled-components';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';

import Avatar from 'components/avatar';

import { Spinner } from 'components/loaders/spinner';

const Card = styled.div`
	background: var(--palette-white);
	padding: var(--spacing-4);
	border-radius: var(--radius-5);
`;

const AccountWrapper = styled.div<{ hideEmail?: boolean }>`
	display: grid;
	gap: var(--spacing-1) var(--spacing-4);

	${({ hideEmail }) =>
		hideEmail
			? css`
					grid-template-areas: 'avatar name';
					grid-template-columns: auto 1fr;
				`
			: css`
					grid-template-areas: 'avatar name' 'avatar text';
					grid-template-columns: auto 1fr;
					grid-template-rows: auto auto;
				`}

	${Avatar} {
		align-self: center;
		grid-area: avatar;
		width: 44px;
		height: 44px;
	}

	strong {
		grid-area: name;
		font-size: var(--font-size-base);
		font-weight: var(--font-weight-semibold);
		color: var(--palette-gray-900);
		word-break: break-all;
		word-wrap: break-word;

		${({ hideEmail }) =>
			hideEmail
				? css`
						align-self: center;
					`
				: css`
						align-self: flex-end;
					`}
	}

	span {
		grid-area: text;
		align-self: flex-start;
		font-size: var(--font-size-sm);
		color: var(--palette-gray-500);
		word-break: break-all;
		word-wrap: break-word;
	}
`;

interface AccountCardProps {
	account?: models.account.Account;
	hideEmail?: boolean;
}

export default function AccountCard({
	account,
	hideEmail = false,
}: AccountCardProps): JSX.Element {
	if (!account) {
		return <Spinner />;
	}

	return (
		<Card>
			<AccountWrapper hideEmail={hideEmail}>
				<Avatar size={44} account={account} />
				<strong data-testid="onboarding.account_card.name">
					{models.account.fullName(account)}
				</strong>
				{!hideEmail && (
					<span data-testid="onboarding.account_card.email">
						{account.email ? account.email : t('Account without login')}
					</span>
				)}
			</AccountWrapper>
		</Card>
	);
}
