import { ReactNode } from 'react';

export const symbol_lyrics: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-300v-520 520Zm480 60H240L131-131q-14 14-32.5 6.344T80-152v-668q0-24.75 17.625-42.375T140-880h480q24.75 0 42.375 17.625T680-820v57q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T620-763v-57H140v520h480v-140q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T680-440v140q0 24.75-17.625 42.375T620-240ZM270-410h100q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T370-470H270q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T270-410Zm489.882-80Q714-490 682-522.118q-32-32.117-32-78Q650-646 682.061-678q32.061-32 77.863-32 10.076 0 22.576 3t27.5 8v-191q0-12.75 8.625-21.375T840-920h90q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T930-860h-60v260q0 45.833-32.118 77.917-32.117 32.083-78 32.083ZM270-530h220q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T490-590H270q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T270-530Zm0-120h220q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T490-710H270q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T270-650Z"
		/>
	</svg>
);
