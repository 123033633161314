import { Map, Record, fromJS } from 'immutable';

import Video from 'pkg/models/video';

import * as actionTypes from 'pkg/actions/action-types';

const initialState = new Record(
	{
		entities: new Map(),
		links: new Map({
			prev: null,
			next: null,
		}),
	},
	'user_videos'
);

const addUserVideosReducer = (state, action) => {
	if (!action.payload.entities.userVideos) {
		return state;
	}

	let entities = action.payload.entities.userVideos;

	entities = fromJS(entities).map((e) => new Video(e));

	return state
		.mergeIn(['entities'], entities)
		.setIn(['links'], new Map(action.links));
};

const userVideos = (state = initialState(), action = {}) => {
	switch (action.type) {
		case actionTypes.UserVideos.ADD_ITEMS:
			return addUserVideosReducer(state, action);

		case actionTypes.UserVideos.RESET_ITEMS:
			return initialState();

		default:
			return state;
	}
};

export default userVideos;
