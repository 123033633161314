import { ChangeEvent, Fragment, useState } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import { Record } from 'pkg/api/models/record';
import { newUserAddress, UserAddress } from 'pkg/api/models/user_address';
import { emailRegexPattern, phoneRegexPattern } from 'pkg/strings';

import { FormFields } from 'routes/payments/hooks/useContactForm';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import CountrySelect from 'components/CountrySelect';

import Section from 'components/form/Section';
import Column from 'components/layout/column';
import * as Inputs from 'components/form/inputs';
import Row from 'components/layout/row';

import Button from 'design/button';

const prefix = 'billingAddress';

interface BillingAddressSectionProps {
	userAddressRecords: Record[];
	showBillingAddressFields: boolean;
	handleToggleBillingAddressFields: () => void;
}

const BillingAddressSection = ({
	userAddressRecords,
	showBillingAddressFields,
	handleToggleBillingAddressFields,
}: BillingAddressSectionProps) => {
	const userAddress = (userAddressRecords[0] as UserAddress) || newUserAddress;

	const [country, setCountry] = useState<string>(
		userAddress.country.toLowerCase()
	);

	const handleCountryChange = (event: ChangeEvent<HTMLOptionElement>) => {
		setCountry(event.target.value);
	};

	return (
		<Section
			icon="mail"
			title={t(`Billing address`)}
			description={t(
				`Add a separate billing address for this contact. This will be automatically used in all invoicing.`
			)}>
			<Column spacing={styles.spacing._5}>
				<div>
					<LargeScreen>
						<Button
							icon={showBillingAddressFields ? 'remove' : 'add'}
							label={
								showBillingAddressFields
									? t(`Remove billing address`)
									: t(`Add billing address`)
							}
							secondary
							onClick={handleToggleBillingAddressFields}
						/>
					</LargeScreen>
					<SmallScreen>
						<Button
							block
							icon={showBillingAddressFields ? 'remove' : 'add'}
							label={
								showBillingAddressFields
									? t(`Remove billing address`)
									: t(`Add billing address`)
							}
							secondary
							onClick={handleToggleBillingAddressFields}
						/>
					</SmallScreen>
				</div>
				{showBillingAddressFields && (
					<Fragment>
						<Row>
							<Inputs.Group label={`${t('First name')}`} optional>
								<Inputs.Field
									name={`${prefix}.${FormFields.firstName}`}
									defaultValue={userAddress.firstName}
								/>
							</Inputs.Group>
							<Inputs.Group label={t('Last name')} optional>
								<Inputs.Field
									name={`${prefix}.${FormFields.lastName}`}
									defaultValue={userAddress.lastName}
								/>
							</Inputs.Group>
						</Row>
						<Row>
							<Inputs.Group label={t('Email')}>
								<Inputs.Field
									name={`${prefix}.${FormFields.email}`}
									type="email"
									pattern={emailRegexPattern}
									required
									defaultValue={userAddress.email}
								/>
							</Inputs.Group>
							<Inputs.Group label={t('Phone number')} optional>
								<Inputs.Field
									name={`${prefix}.${FormFields.phoneNumber}`}
									type="tel"
									pattern={phoneRegexPattern}
									defaultValue={userAddress.phoneNumber}
								/>
							</Inputs.Group>
						</Row>
						<Row>
							<Inputs.Group
								label={t('Country')}
								hint={t('Country of residence')}
								optional>
								<CountrySelect
									name={`${prefix}.${FormFields.country}`}
									value={country}
									onChange={handleCountryChange}
								/>
							</Inputs.Group>
							<Inputs.Group label={t('Company name')} optional>
								<Inputs.Field
									name={`${prefix}.${FormFields.companyName}`}
									defaultValue={userAddress.companyName}
								/>
							</Inputs.Group>
						</Row>
						<Inputs.Group label={t('Street address')} optional>
							<Inputs.Field
								name={`${prefix}.${FormFields.address}`}
								defaultValue={userAddress.address}
							/>
						</Inputs.Group>
						<Row>
							<Inputs.Group label={t('Region')} optional>
								<Inputs.Field
									name={`${prefix}.${FormFields.region}`}
									defaultValue={userAddress.region}
								/>
							</Inputs.Group>
							<Inputs.Group label={t('City')} optional>
								<Inputs.Field
									name={`${prefix}.${FormFields.city}`}
									defaultValue={userAddress.city}
								/>
							</Inputs.Group>
							<Inputs.Group label={t('ZIP code')} optional>
								<Inputs.Field
									name={`${prefix}.${FormFields.postalCode}`}
									defaultValue={userAddress.postalCode}
								/>
							</Inputs.Group>
						</Row>
					</Fragment>
				)}
			</Column>
		</Section>
	);
};

export default BillingAddressSection;
