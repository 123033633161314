import styled, { css } from 'styled-components';

const Hint = styled.div<{ isError?: boolean }>`
	margin-top: var(--spacing-3);
	font-size: var(--font-size-sm);
	color: var(--palette-gray-500);

	${({ isError }) =>
		isError &&
		css`
			color: var(--palette-red-400);
		`}
`;

export default Hint;
