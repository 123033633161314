import { ReactNode } from 'react';

export const symbol_water_ec: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M450-410v147q0 11 10.5 14.5T477-254l129-193q5-8 .5-15.5T593-470h-83v-147q0-11-10.5-14.5T483-626L354-433q-5 8-.5 15.5T367-410h83Zm30 330q-137 0-228.5-94T160-408q0-64 29-127t72.5-121q43.5-58 94-108.5T450-854q7-6 14.5-8.5T480-865q8 0 15.5 2.5T510-854q44 39 94.5 89.5t94 108.5Q742-598 771-535t29 127q0 140-91.5 234T480-80Zm0-60q112 0 186-76.5T740-408q0-79-66.5-179.5T480-800Q353-688 286.5-587.5T220-408q0 115 74 191.5T480-140Zm0-340Z"
		/>
	</svg>
);
