import { Frequency, RRule, Weekday } from 'rrule';
import { t } from '@transifex/native';

import { days, months } from 'pkg/date';

/* RecurringByWeekDayNthPositions is what RRule library expect to recive when lookingfor example the first monday of the month */

export enum RecurringByWeekDayNthPositions {
	First = 1,
	Second = 2,
	Third = 3,
	Fourth = 4,
	Last = -1,
}

export const ruleStrings: { [key: string]: Frequency } = {
	daily: RRule.DAILY,
	weekly: RRule.WEEKLY,
	bi_weekly: RRule.WEEKLY,
	monthly: RRule.MONTHLY,
	yearly: RRule.YEARLY,
};

export const dayRules: { [key: number]: Weekday } = {
	0: RRule.MO,
	1: RRule.TU,
	2: RRule.WE,
	3: RRule.TH,
	4: RRule.FR,
	5: RRule.SA,
	6: RRule.SU,
};

// This function checks whether the rule is a custom created
// rule or if it can be set as one of the standard options e.g.
// daily, monthly etc...
export const ruleFreqString = (rule: RRule): string => {
	const startsAtDate = new Date(rule.options.dtstart);
	const ruleString = Object.keys(ruleStrings).find(
		(key) => ruleStrings[key] === rule.options.freq
	);

	switch (rule.options.freq) {
		case RRule.DAILY:
			if (rule.options.interval > 1) {
				return 'custom';
			}
			return ruleString;
		case RRule.WEEKLY:
			if (
				(rule.options.interval === 1 || rule.options.interval === 2) &&
				JSON.stringify(rule.options.byweekday) !==
					JSON.stringify([startsAtDate.getDay() - 1])
			) {
				return 'custom';
			}
			return ruleString;
		case RRule.MONTHLY:
			if (
				rule.options.interval > 1 ||
				JSON.stringify(rule.options.bymonthday) !==
					JSON.stringify([startsAtDate.getDate()])
			) {
				return 'custom';
			}
			return ruleString;
		case RRule.YEARLY:
			if (
				rule.options.interval > 1 ||
				JSON.stringify(rule.options.bymonthday) !==
					JSON.stringify([startsAtDate.getDate()]) ||
				JSON.stringify(rule.options.bymonth) !==
					JSON.stringify([startsAtDate.getMonth() + 1])
			) {
				return 'custom';
			}

			return ruleString;
	}
};

export const RRuleToText = (rule: RRule): string => {
	// RRules toText function wants sunday as the first item in the dayNames array

	const dayNames = days();
	const language = {
		dayNames: [
			dayNames[6][1],
			dayNames[0][1],
			dayNames[1][1],
			dayNames[2][1],
			dayNames[3][1],
			dayNames[4][1],
			dayNames[5][1],
		],
		monthNames: months().map((m) => m[1]),
		tokens: {
			and: new RegExp(
				t('and', {
					_comment: '"Monday _and_ Thursday..."',
				})
			),
			at: new RegExp(
				t('at', {
					_comment: '"Monday _at_ 10:00"',
				})
			),
			day: new RegExp(
				t('day', {
					_comment: '"every _day_..."',
				})
			),
			days: new RegExp(
				t('days', {
					_comment: '"every 2 _days_ until..."',
				})
			),
			every: new RegExp(
				t('every', {
					_comment: '"_every_ 2 days until..."',
				})
			),
			for: new RegExp(
				t('for', {
					_comment: `"_for_ 2 weeks`,
				})
			),
			hour: new RegExp(
				t('hour', {
					_comment: `"every _hour_"`,
				})
			),
			hours: new RegExp(
				t('hours', {
					_comment: `"every 2 _hours"`,
				})
			),
			in: new RegExp(t('in')),
			last: new RegExp(t('last')),
			minutes: new RegExp(t('minutes')),
			month: new RegExp(t('month')),
			months: new RegExp(t('months')),
			nd: new RegExp(
				t('nd', {
					_comment: `Impossible or impractical to properly translate in many languages.
						"every 2 months on the 2_nd_ until..."`,
				})
			),
			'on the': new RegExp(
				t('on the', {
					_comment: '"every 2 months _on the_ 1st until..."',
				})
			),
			on: new RegExp(
				t('on', {
					_comment: '"every 2 months _on_ the 1st until..."',
				})
			),
			or: new RegExp(t('or')),
			rd: new RegExp(
				t('rd', {
					_comment: `Impossible or impractical to properly translate in many languages.
			"every 2 months on the 3_rd_ until..."`,
				})
			),
			st: new RegExp(
				t('st', {
					_comment: `Impossible or impractical to properly translate in many languages.
						"every 2 months on the 1_st_ until..."`,
				})
			),
			th: new RegExp(
				t('th', {
					_comment: `Impossible or impractical to properly translate in many languages.
						"every 2 months on the 5_th_ until..."`,
				})
			),
			the: new RegExp(
				t('the', {
					_comment: '"every 2 months on _the_ 1st until..."',
				})
			),
			time: new RegExp(
				t('time', {
					_comment: '"one _time_"',
				})
			),
			times: new RegExp(
				t('times', {
					_comment: '"four _times_"',
				})
			),
			until: new RegExp(
				t('until', {
					_comment: 'every 2 months on the 1st _until_ the 29th of march',
				})
			),
			week: new RegExp(
				t('week', {
					_comment: '"every _week_ on Monday until March 29, 2024"',
				})
			),
			weekday: new RegExp(
				t('weekday', {
					_comment: '"every _weekday_ until March 29, 2024"',
				})
			),
			weekdays: new RegExp(
				t('weekdays', {
					_comment: '"every _weekdays_ until March 29, 2024"',
				})
			),
			weeks: new RegExp(
				t('weeks', {
					_comment: '"every _weeks_ until March 29, 2024"',
				})
			),
			year: new RegExp(
				t('year', {
					_comment: '"every _year_ until March 29, 2024"',
				})
			),
			years: new RegExp(
				t('year', {
					_comment: '"every 2 _years_ until March 29, 2024"',
				})
			),
		},
	};

	const getText = (id: keyof typeof language.tokens): string => {
		return language.tokens[id]?.source;
	};

	return rule.toText(getText, language);
};
