import { ReactNode } from 'react';

export const symbol_language_gb_english: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M590-340h190v-110H590v110Zm0-170h190v-110H590v110ZM180-280q-24.75 0-42.375-17.625T120-340v-280q0-24.75 17.625-42.375T180-680h219q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T399-620H180v280h190v-110h-50q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T320-510h80q12.75 0 21.375 8.625T430-480v140q0 24.75-17.625 42.375T370-280H180Zm380 0q-12.75 0-21.375-8.625T530-310v-340q0-12.75 8.625-21.375T560-680h220q24.75 0 42.375 17.625T840-620v90q0 20-15 35t-35 15q20 0 35 15t15 35v90q0 24.75-17.625 42.375T780-280H560Z"
		/>
	</svg>
);
