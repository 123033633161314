import { Map, List, Record, fromJS } from 'immutable';

import { ADD_GROUP_VIDEOS } from 'pkg/actions/action-types';

const initialState = new Record(
	{
		entities: new Map(),
		result: new List([]),
		links: new Map({
			prev: null,
			next: null,
		}),
	},
	'group_videos'
);

const addGroupVideosReducer = (state, action) =>
	state
		.mergeIn(['entities'], fromJS(action.payload.entities.groupVideos))
		.setIn(['links'], new Map(action.links));

const groupVideos = (state = initialState(), action = {}) => {
	switch (action.type) {
		case ADD_GROUP_VIDEOS:
			return addGroupVideosReducer(state, action);

		default:
			return state;
	}
};

export default groupVideos;
