import { ReactNode } from 'react';

export const symbol_flag: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M260-429v279q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T200-150v-620q0-12.75 8.625-21.375T230-800h289q10.5 0 18.75 6.5T548-776l14 62h208q12.75 0 21.375 8.625T800-684v310q0 12.75-8.625 21.375T770-344H568q-10.5 0-18.75-6T539-367l-14-62H260Zm335 25h145v-250H535q-10.5 0-18.75-6.5T506-678l-14-62H260v251h292q10.5 0 18.75 6T581-466l14 62Zm-95-168Z"
		/>
	</svg>
);
