import { ReactNode } from 'react';

export const symbol_grid_4x4: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M215-215H110q-12.75 0-21.375-8.675Q80-232.351 80-245.175 80-258 88.625-266.5T110-275h105v-175H110q-12.75 0-21.375-8.675Q80-467.351 80-480.175 80-493 88.625-501.5T110-510h105v-175H110q-12.75 0-21.375-8.675Q80-702.351 80-715.175 80-728 88.625-736.5T110-745h105v-105q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T275-850v105h175v-105q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-850v105h175v-105q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T745-850v105h105q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-685H745v175h105q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-450H745v175h105q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-215H745v105q0 12.75-8.675 21.375Q727.649-80 714.825-80 702-80 693.5-88.625T685-110v-105H510v105q0 12.75-8.675 21.375Q492.649-80 479.825-80 467-80 458.5-88.625T450-110v-105H275v105q0 12.75-8.675 21.375Q257.649-80 244.825-80 232-80 223.5-88.625T215-110v-105Zm60-60h175v-175H275v175Zm235 0h175v-175H510v175ZM275-510h175v-175H275v175Zm235 0h175v-175H510v175Z"
		/>
	</svg>
);
