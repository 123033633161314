import { Record } from 'immutable';
import { schema } from 'normalizr';

import User from 'pkg/models/user';

export const videoSequenceUsers = new schema.Entity('videoSequenceUsers', {
	user: User.normalizr(),
});

interface SequenceUser {
	id: number | string;
	videoSequenceId: number;
	userId: number;
	user: User;
	description: string;
}

export interface VideoSequenceUserPayload {
	id?: string;
	userId: number;
	videoSequenceId: number;
	description?: string;
}

const videoSequenceUserProps: SequenceUser = {
	id: 0,
	videoSequenceId: 0,
	userId: 0,
	user: new User({}),
	description: '',
};

class VideoSequenceUser
	extends Record(videoSequenceUserProps, 'VideoSequenceUserRecord')
	implements SequenceUser
{
	static normalizr(): schema.Entity {
		return videoSequenceUsers;
	}
}

export default VideoSequenceUser;
