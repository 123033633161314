import { ReactNode } from 'react';

export const symbol_filter_alt_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m580-465-43-43 183-232H305l-60-60h527q21 0 31 19t-4 36L580-465Zm-20 151v114q0 17-11.5 28.5T520-160h-80q-17 0-28.5-11.5T400-200v-274L82-792q-9-9-9-21.5t9-21.5q9-9 21.5-9t21.5 9l710 710q9 9 9 21t-9 21q-9 9-21.5 9T792-83L560-314Zm-23-194Z"
		/>
	</svg>
);
