import { ReactNode } from 'react';

export const symbol_bottom_app_bar: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-203v143h600v-143H548q-14 14-31.165 22t-37 8Q460-293 443-301t-31-22H180Zm300-30q12 0 21-9t9-21q0-12-9-21t-21-9q-12 0-21 9t-9 21q0 12 9 21t21 9Zm-300-30h210q0-36 27-63t63-27q36 0 63 27t27 63h210v-397H180v397Zm0 203h600-600Z"
		/>
	</svg>
);
