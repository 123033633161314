import { t } from '@transifex/native';

import * as flashes from 'pkg/actions/flashes';

import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { omit } from 'pkg/objects';

export interface CreateReactionPayload {
	emojiSlug: string;

	chatId?: number;
	chatMessageId: number;
	userId: number;
}

export async function create(
	payload: CreateReactionPayload
): Promise<models.chatReaction.ChatReaction> {
	const [request, response] = await models.create<
		CreateReactionPayload,
		models.chatReaction.ChatReaction
	>(
		endpoints.Chat.AddReaction(payload.chatId, payload.chatMessageId),
		omit(payload, 'chatId')
	);

	if (!request.ok) {
		flashes.show(
			{
				title: t('Reaction not sent'),
			},
			request.status,
			'warning-circle'
		);
	}

	return response;
}

export async function remove(
	reaction: models.chatReaction.ChatReaction
): Promise<void> {
	await models.destroy(reaction);
}
