import styled from 'styled-components';

export const Form = styled.form`
	width: 100%;

	label {
		font-weight: var(--font-weight-semibold);
	}
`;

export const Error = styled.div`
	font-size: 0.7rem;
	font-weight: var(--font-weight-normal);
	margin-top: 5px;
	color: rgba(245, 52, 26);
`;
