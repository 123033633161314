import Exercise from 'containers/training/exercise/View';

import CardBase, * as Card from 'components/Card';
import Modal from 'components/modal';

export default ({ exerciseId, close }) => {
	const handleClose = async (anim) => {
		await anim;
		close();
	};

	return (
		<Modal onClose={handleClose}>
			<CardBase>
				<Card.Body>
					<Exercise exerciseId={exerciseId} isModalItem={true} />
				</Card.Body>
			</CardBase>
		</Modal>
	);
};
