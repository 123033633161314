import { t } from '@transifex/native';

import { Dateable } from 'pkg/api/models/dateable';
import { MembershipRole } from 'pkg/api/models/membership';
import { AccountRole } from 'pkg/api/models/onboarding_info';
import { Record } from 'pkg/api/models/record';

export enum IdentityUserTrait {
	User = 'user',
	Admin = 'admin',
	Staff = 'staff',
	LegalGuardian = 'legalGuardian',
}

export interface IdentityProvider extends Record, Dateable {
	organizationId: number;
	displayName: string;
	ssoEnabled: boolean;
	syncEnabled: boolean;
	connectionActive: boolean;
	connectionType: string;
	directoryActive: boolean;
	directoryType: string;
	requiredForIdentityUserTraits: IdentityUserTrait[];
	unusedIdentityUserTraits: IdentityUserTrait[];
}

export interface ScrubbedIdentityProvider extends Record {
	displayName: string;
	requiredForIdentityUserTraits: IdentityUserTrait[];
}

/**
 * Finds the idp that is required for passed MembershipRole and if there is none returns undefined
 */
export default function getRequiredIdpForMembershipRole(
	role: MembershipRole,
	idps: ScrubbedIdentityProvider[]
) {
	const mappedMembershipRolesToIdentityTrait = {
		1: IdentityUserTrait.User,
		2: IdentityUserTrait.Admin,
		5: IdentityUserTrait.Staff,
	} as { [key in MembershipRole]: IdentityUserTrait };

	const trait = mappedMembershipRolesToIdentityTrait[role];

	if (!idps) {
		return undefined;
	}

	return idps.find((idp) => idp.requiredForIdentityUserTraits?.includes(trait));
}

/**
 * Finds the idp that is required for passed AccountRole and if there is none returns undefined
 */
export function getRequiredIdpForAccountRole(
	role: AccountRole,
	idps: ScrubbedIdentityProvider[]
): ScrubbedIdentityProvider {
	// We need to map the onboarding account role types to the correct trait
	const mappedAccountRolesToIdentityTrait: {
		[key in AccountRole]: IdentityUserTrait;
	} = {
		player: IdentityUserTrait.User,
		parent: IdentityUserTrait.LegalGuardian,
		staff: IdentityUserTrait.Staff,
	};

	const trait = mappedAccountRolesToIdentityTrait[role];

	if (!idps) {
		return undefined;
	}

	return idps.find((idp) => idp.requiredForIdentityUserTraits?.includes(trait));
}

export function userTraitToTranslatedString(userTrait: IdentityUserTrait) {
	switch (userTrait) {
		case IdentityUserTrait.Admin:
			return t('Administrator');
		case IdentityUserTrait.Staff:
			return t('Staff');
		case IdentityUserTrait.User:
			return t('Player');
		case IdentityUserTrait.LegalGuardian:
			return t('Parent');
	}
}

export function getAvailableUserTraits(idps: IdentityProvider[] = []) {
	if (idps.length === 0) {
		return [
			IdentityUserTrait.Admin,
			IdentityUserTrait.Staff,
			IdentityUserTrait.User,
			IdentityUserTrait.LegalGuardian,
		];
	}

	return idps[0].unusedIdentityUserTraits;
}

export interface AttributeMapping {
	id: number;
	userFieldId: number;
	identityProviderId: number;
	key: string;
}
