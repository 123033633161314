import { t } from '@transifex/native';
import { Fragment } from 'react';

import * as styles from 'pkg/config/styles';

import { formatToCurrency } from 'pkg/i18n/formatters';
import DateTime from 'pkg/datetime';
import { formatPriceIntervalToText } from 'pkg/utils';
import { useCurrentGroup } from 'pkg/identity';
import * as models from 'pkg/api/models';
import { cssClasses } from 'pkg/css/utils';

import { OrderCostDetails } from 'routes/payments/orders/utils';

import Column from 'components/layout/column';
import Row from 'components/layout/row';

import * as css from './styles.css';

interface TotalAmountProps {
	installmentCount: number;
	initialPaymentDate?: number;
	registrationFlow?: boolean;

	// Set if total amount should show discount information
	discount?: models.discount.Discount;
	orderCostDetails: OrderCostDetails;
	productPrices: models.productPrice.ProductPrice[];
	paidOrders?: models.order.Order[];
}

export default function TotalAmount({
	installmentCount,
	initialPaymentDate,
	registrationFlow = false,
	orderCostDetails,
	productPrices = [],
	discount,
	paidOrders = [],
}: TotalAmountProps) {
	const group = useCurrentGroup();

	const hasRecurringPrices = productPrices.some(
		(productPrice) => productPrice.recurring
	);
	const hasRecurringAndNonRecurringPrice =
		hasRecurringPrices &&
		productPrices.some((productPrice) => !productPrice.recurring);
	const firstRecurringProductPrice = productPrices.filter(
		(p) => p.recurring
	)?.[0];

	let billingCycleString = '';

	if (firstRecurringProductPrice) {
		if (
			(firstRecurringProductPrice.recurringInterval === 'year' &&
				firstRecurringProductPrice.recurringIntervalCount === 1) ||
			(firstRecurringProductPrice.recurringInterval === 'month' &&
				firstRecurringProductPrice.recurringIntervalCount === 12)
		) {
			billingCycleString = t('Annual');
		} else {
			billingCycleString = formatPriceIntervalToText(
				firstRecurringProductPrice.recurringInterval,
				firstRecurringProductPrice.recurringIntervalCount,
				true
			);
		}
	}

	const initialPayment = formatToCurrency(
		orderCostDetails.initialPayment / 100,
		group.currency
	);
	const oneInstallment = formatToCurrency(
		orderCostDetails.recurringPayment / 100,
		group.currency
	);

	let installmentTotal = '';
	let showingInstallmentCount = installmentCount;

	if (hasRecurringAndNonRecurringPrice) {
		if (installmentCount) {
			installmentTotal = formatToCurrency(
				(orderCostDetails.recurringPayment * (installmentCount - 1)) / 100,
				group.currency
			);

			showingInstallmentCount = showingInstallmentCount - 1;
		} else {
			installmentTotal = formatToCurrency(
				orderCostDetails.recurringPayment / 100,
				group.currency
			);
		}
	} else {
		let count = 1;

		if (installmentCount) {
			count = installmentCount;
		}

		installmentTotal = formatToCurrency(
			(orderCostDetails.recurringPayment * count) / 100,
			group.currency
		);
	}

	const discountAmount = formatToCurrency(
		orderCostDetails.discount / 100,
		group.currency
	);
	const subTotal = formatToCurrency(
		orderCostDetails.subtotal / 100,
		group.currency
	);
	const totalAmount = formatToCurrency(
		orderCostDetails.total / 100,
		group.currency
	);
	const serviceFee = formatToCurrency(
		orderCostDetails.serviceFee / 100,
		group.currency
	);

	const firstRecurringPrice = productPrices.filter(
		(productPrice) => productPrice.recurring
	)[0];

	let recurringPricesString = t('{billingCycleString} payment', {
		billingCycleString,
	});

	if (installmentCount && firstRecurringPrice) {
		recurringPricesString = t(
			'Then {installmentCycle} payment ({installmentCount} * {installmentCost})',
			{
				installmentCycle: formatPriceIntervalToText(
					firstRecurringPrice.recurringInterval,
					firstRecurringPrice.recurringIntervalCount
				),
				installmentCount: showingInstallmentCount,
				installmentCost: oneInstallment,
			}
		);
	}

	let discountString = t('Discount ({title} - {amount})', {
		title: discount?.title,
		amount: discountAmount,
	});

	if (discount?.percentOff) {
		discountString = t('Discount ({title} - {percent}%)', {
			title: discount?.title,
			percent: discount?.percentOff,
		});
	}

	let startPayment = t('Initial payment');

	if (registrationFlow) {
		startPayment = t('Total payment upon registration');
	} else if (initialPaymentDate) {
		startPayment = t('Initial payment ({date})', {
			date: DateTime.fromTimestamp(initialPaymentDate).toLocaleDateString({
				month: 'short',
				day: 'numeric',
				year: 'numeric',
			}),
		});
	}

	const paidAmount = models.order.getOrdersTotalAmountDue(paidOrders);

	return (
		<div
			className={cssClasses(
				css.wrapper,
				registrationFlow ? css.registrationFlow : ''
			)}>
			<div
				className={cssClasses(
					css.inner,
					registrationFlow ? css.registrationFlow : ''
				)}>
				<Column spacing={styles.spacing._6}>
					{(hasRecurringAndNonRecurringPrice || hasRecurringPrices) && (
						<Column spacing={styles.spacing._2}>
							{hasRecurringAndNonRecurringPrice && (
								<Row justify="end">
									<div className={css.subTotalAmount}>{startPayment}</div>
									<div className={css.subTotalAmount}>{initialPayment}</div>
								</Row>
							)}
							{hasRecurringPrices && (
								<Fragment>
									{paidAmount > 0 && (
										<Row justify="end">
											<div>
												{t('Installments paid ({paid})', {
													paid: paidOrders.length,
												})}
											</div>
											<div>
												{formatToCurrency(paidAmount / 100, group.currency)}
											</div>
										</Row>
									)}
									<Row justify="end">
										<div className={css.subTotalAmount}>
											{recurringPricesString}
										</div>
										<div className={css.subTotalAmount}>{installmentTotal}</div>
									</Row>
								</Fragment>
							)}
						</Column>
					)}

					<Column spacing={styles.spacing._2}>
						<Row justify="end">
							<div className={css.subTotalAmount}>{t('Subtotal amount')}</div>
							<div className={css.subTotalAmount}>{subTotal}</div>
						</Row>
						{discount && (
							<Row justify="end">
								<div className={css.subTotalAmount}>{discountString}</div>
								<div className={css.subTotalAmount}>-{discountAmount}</div>
							</Row>
						)}
						<Row justify="end">
							<div className={css.totalAmount}>{t('Total amount')}</div>
							<div className={css.totalAmount}>{totalAmount}</div>
						</Row>
						{orderCostDetails.taxes.map((tax) => {
							const taxRate = tax.taxRate;
							const totalTaxes = formatToCurrency(
								tax.amount / 100,
								group.currency
							);
							return (
								<Row justify="end">
									<div className={css.tax}>
										{t('{displayName} - {jurisdiction} ({percentage}%)', {
											displayName: taxRate.display_name,
											jurisdiction: taxRate.jurisdiction,
											percentage: taxRate.percentage,
										})}
									</div>
									<div className={css.tax}>{totalTaxes}</div>
								</Row>
							);
						})}
						{!!orderCostDetails.serviceFee && (
							<Row justify="end">
								<div className={css.tax}>
									{t('Service fee ({amount}%)', {
										amount: group.serviceFeePercent,
									})}
								</div>
								<div className={css.tax}>{serviceFee}</div>
							</Row>
						)}
					</Column>
				</Column>
			</div>
		</div>
	);
}
