import { ReactNode } from 'react';

export const symbol_detector_battery: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M320-180h400v-160H320v160Zm-110 60q-12 0-21-9t-9-21v-220q0-12.75 9-21.375T210-400h540q12.75 0 21.375 8.625T780-370v60h30.175q12.825 0 21.325 8.625T840-280v40q0 12.75-8.625 21.375T810-210h-30v60q0 12.75-8.625 21.375T750-120H210Zm-30-660v60h600v-60H180Zm121 120 18 60h322l18-60H301Zm18 120q-19.5 0-35.25-11.625T262-582l-25-78h-57q-24.75 0-42.375-17.625T120-720v-60q0-24.75 17.625-42.375T180-840h600q24.75 0 42.375 17.625T840-780v60q0 24.75-17.625 42.375T780-660h-57l-30 81q-6.932 17.25-22.338 28.125Q655.257-540 636-540H319ZM180-780v60-60Z"
		/>
	</svg>
);
