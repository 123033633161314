import styled from 'styled-components';
import { t } from '@transifex/native';

import Icon from 'components/icon';

import * as Input from 'components/form/inputs';

const SearchWrapper = styled.div`
	padding: 1rem;
`;

interface ListSearchProps {
	showFilter: boolean;
	filterValue: string;
	handleFilterInput: (value: string) => void;
}

export default function ListSearch({
	showFilter,
	filterValue,
	handleFilterInput,
}: ListSearchProps) {
	if (!showFilter) {
		return null;
	}

	return (
		<SearchWrapper>
			<Input.Field
				autoComplete="off"
				value={filterValue}
				onChange={handleFilterInput}
				placeholder={t('Search contacts...')}>
				<Input.Prefix inline>
					<Icon name="search" />
				</Input.Prefix>
			</Input.Field>
		</SearchWrapper>
	);
}
