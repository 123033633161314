import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import { CollectionResponse } from 'pkg/api/use_collection';
import { formatToCurrency } from 'pkg/i18n/formatters';
import DateTime from 'pkg/datetime';
import * as routes from 'pkg/router/routes';
import { pushState } from 'pkg/router/state';
import { useCurrentOrganization } from 'pkg/identity';

import Icon from 'components/icon';

import * as Table from 'design/table';
import * as Context from 'design/context_menu';

interface RefundRowProps {
	refund: models.orderRefund.OrderRefund;
}

function RefundRow({ refund }: RefundRowProps) {
	const org = useCurrentOrganization();
	const handleCellClick = () => {
		pushState(routes.Order.Show(org.id, refund.order.id));
	};

	return (
		<Table.Row>
			<Table.Cell onClick={handleCellClick}>
				{models.user.fullName(refund.order.customerUser)}
			</Table.Cell>
			<Table.Cell onClick={handleCellClick}>{refund.order.email}</Table.Cell>
			<Table.Cell onClick={handleCellClick}>
				{refund.order.invoiceNumber}
			</Table.Cell>
			<Table.Cell onClick={handleCellClick}>
				{refund.paymentProviderId || refund.id}
			</Table.Cell>
			<Table.Cell onClick={handleCellClick}>
				{formatToCurrency(refund.amount / 100, refund.order.currency)}
			</Table.Cell>
			<Table.Cell onClick={handleCellClick}>
				{DateTime.fromTimestamp(refund.createdAt).toLocaleDateString()}
			</Table.Cell>
			<Table.ActionCell>
				<Context.Menu toggleWith={<Icon name="context-menu" />}>
					<Context.LinkItem href={routes.Order.Show(org.id, refund.order.id)}>
						<Context.ItemIcon name="arrow_forward" />
						{t('View invoice')}
					</Context.LinkItem>
				</Context.Menu>
			</Table.ActionCell>
		</Table.Row>
	);
}

interface RefundsTableProps {
	sort: {
		by: string;
		order: 'desc' | 'asc';
	};

	onSortClick: (field: string, sortBy: 'asc' | 'desc') => void;

	refundsCollection: CollectionResponse<models.orderRefund.OrderRefund>;
}

export default function RefundsTable({
	sort,
	onSortClick,
	refundsCollection,
}: RefundsTableProps) {
	const columns = [
		{
			content: t('Recipient'),
		},
		{
			content: t('Email'),
		},
		{
			content: t('Invoice number'),
			sortKey: 'invoice_number',
		},
		{
			content: t('Refund ID'),
		},
		{
			content: t('Refunded amount'),
			sortKey: 'amount',
		},
		{
			content: t('Refunded At'),
			sortKey: 'refunded_at',
		},
		{ content: '', width: 'max-content' },
	];

	return (
		<Table.Table
			columns={columns}
			onSort={onSortClick}
			sortBy={sort.by}
			sortOrder={sort.order}>
			{refundsCollection.records.map((r) => (
				<RefundRow refund={r} />
			))}
		</Table.Table>
	);
}
