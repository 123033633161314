import { ReactNode } from 'react';

export const symbol_phonelink_setup: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M260-130v30h440v-30H260Zm436-248q-12-3-22-9t-17-14l-28 13q-5 2-10.5 1t-8.5-6l-15-23q-3-5-1.833-10.526 1.166-5.527 5.833-9.474l25-19q-5-11-5-27t5-27l-25-19q-4.667-3.75-5.833-9.375Q592-543 595-548l15-23q3-5 8.5-6t10.5 1l28 13q7-8 16-14t23-9l4-33q.694-5.778 4.514-9.389 3.819-3.611 9.375-3.611h30.555q5.556 0 9.223 3.611 3.666 3.611 4.333 9.389l4 33q14 3 23 9t16 14l28-13q5-2 10.5-1t8.5 6l15 23q3 5 1.833 10.625Q863.667-531.75 859-528l-25 19q5 11 5 27t-5 27l25 19q4.667 3.947 5.833 9.474Q866-421 863-416l-15 23q-3 5-8.5 6t-10.5-1l-28-13q-7 8-17 14t-22 9l-4 33q-.694 5.778-4.514 9.389-3.819 3.611-9.375 3.611h-30.555q-5.556 0-9.223-3.611-3.666-3.611-4.333-9.389l-4-33Zm33-38q28 0 47-19t19-47q0-28-19-47t-47-19q-28 0-47 19t-19 47q0 28 19 47t47 19ZM260-830h440v-30H260v30Zm0 0v-30 30Zm0 700v30-30Zm0 90q-24.75 0-42.375-17.625T200-100v-760q0-24.75 17.625-42.375T260-920h440q24.75 0 42.375 17.625T760-860v116q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T700-744v-26H260v580h440v-26q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T760-216v116q0 24.75-17.625 42.375T700-40H260Z"
		/>
	</svg>
);
