import { ReactNode } from 'react';

export const symbol_network_node: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M209.899-80Q156-80 118-118.101t-38-92Q80-264 118.071-302q38.072-38 91.929-38 19 0 36 5.029 17 5.03 32 13.971l172-171v-131q-43-11-71.5-45.992Q350-703.983 350-750q0-53.857 38.101-91.929 38.101-38.071 92-38.071T572-841.929q38 38.072 38 91.929 0 46.017-28.5 81.008Q553-634 510-623v131l172 171q15.455-8.941 32.455-13.971Q731.455-340 750-340q53.857 0 91.929 38.101 38.071 38.101 38.071 92T841.899-118q-38.101 38-92 38T658-118.071Q620-156.143 620-210q0-19 5.029-36 5.03-17 13.971-32L480-438 321-278q8.941 15 13.971 32Q340-229 340-210q0 53.857-38.101 91.929Q263.798-80 209.899-80Zm539.983-60Q779-140 799.5-160.382q20.5-20.383 20.5-49.5Q820-239 799.618-259.5q-20.383-20.5-49.5-20.5Q721-280 700.5-259.618q-20.5 20.383-20.5 49.5Q680-181 700.382-160.5q20.383 20.5 49.5 20.5Zm-270-540Q509-680 529.5-700.382q20.5-20.383 20.5-49.5Q550-779 529.618-799.5q-20.383-20.5-49.5-20.5Q451-820 430.5-799.618q-20.5 20.383-20.5 49.5Q410-721 430.382-700.5q20.383 20.5 49.5 20.5Zm-270 540Q239-140 259.5-160.382q20.5-20.383 20.5-49.5Q280-239 259.618-259.5q-20.383-20.5-49.5-20.5Q181-280 160.5-259.618q-20.5 20.383-20.5 49.5Q140-181 160.382-160.5q20.383 20.5 49.5 20.5Z"
		/>
	</svg>
);
