import { ReactNode } from 'react';

export const symbol_finance_mode: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M318-477v-193q0-18.75 13.176-31.875 13.177-13.125 32-13.125Q382-715 395-701.875T408-670v193q0 18.75-13.176 31.875-13.177 13.125-32 13.125Q344-432 331-445.125T318-477Zm194 39v-397q0-18.75 13.177-31.875Q538.353-880 557.176-880 576-880 589-866.875T602-835v397q0 22.5-13.819 33.75-13.82 11.25-31 11.25Q540-393 526-404.25T512-438ZM124-338v-166q0-18.75 13.176-31.875 13.177-13.125 32-13.125Q188-549 201-535.875T214-504v166q0 22.5-13.819 33.75-13.82 11.25-31 11.25Q152-293 138-304.25T124-338Zm68.633 220Q173-118 165.5-137t6.5-33l174-174q8-8 20-8.5t21 7.5l128 113 262-262h-58q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T719-554h131q12.75 0 21.375 8.625T880-524v130q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T820-394v-58L537-169q-8 8-20 8.5t-21-7.5L368-280 215-127q-5 5-10.663 7-5.664 2-11.704 2Z"
		/>
	</svg>
);
