const short_text = {
	name: 'short-text',
	objects: [
		{
			type: 'path',
			attributes: {
				d: 'M5.09874 10.7873H19.329V12.0873H5.09874V10.7873Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M5.09874 13.8868H14.1313V15.1868H5.09874V13.8868Z',
			},
		},
	],
};

export default short_text;
