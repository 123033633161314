import { ReactNode } from 'react';

export const symbol_nest_cam_iq_outdoor: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M563-150q-11 13-27.5 14.5T506-145L353-273q-50-42-72-102t-11-123l-56-47q-12-11-13.5-26.5T209-600l8-10h-77v110q0 25-17.5 42.5T80-440v-400q25 0 42.5 17.5T140-780v110h127l70-85q11-13 27.5-14.5T394-760l55 46q61-23 124-12t113 53l153 128q13 11 14.5 27.5T844-489L563-150Zm-33-55 256-308-139-114q-44-36-100-38t-110 19l-67-55-102 123 66 55q-11 57 1 112t56 91l139 115Zm-3-248Z"
		/>
	</svg>
);
