import { ReactNode } from 'react';

export const symbol_signpost: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M450-110v-160H255q-12.444 0-23.722-5T212-288l-71-71q-9-9-9-21t9-21l71-71q8-8 19.278-13 11.278-5 23.722-5h195v-90H190q-12.75 0-21.375-8.625T160-610v-160q0-12.75 8.625-21.375T190-800h260v-50q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-850v50h195q12.444 0 23.722 5T748-782l71 71q9 9 9 21t-9 21l-71 71q-8 8-19.278 13-11.278 5-23.722 5H510v90h260q12.75 0 21.375 8.625T800-460v160q0 12.75-8.625 21.375T770-270H510v160q0 12.75-8.675 21.375Q492.649-80 479.825-80 467-80 458.5-88.625T450-110ZM220-640h485l50-50-50-50H220v100Zm35 310h485v-100H255l-50 50 50 50Zm-35-310v-100 100Zm520 310v-100 100Z"
		/>
	</svg>
);
