import styled, { css } from 'styled-components';

import * as styles from 'pkg/config/styles';

interface WrapperProps {
	margin?: typeof styles.spacing;
	small?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
	display: flex;
	justify-content: center;
	width: 100%;
	position: relative;
	line-height: 1;
	font-size: var(--font-size-2xl);

	${({ small }) =>
		small &&
		css`
			font-size: var(--font-size-sm);
		`}

	${({ margin }) =>
		margin &&
		css`
			margin: ${margin} 0;
		`}

	&::after {
		position: absolute;
		content: ' ';
		height: 1px;
		top: 50%;
		left: 0;
		right: 0;
		background: var(--palette-gray-400);
		z-index: 1;
	}
`;

export const Text = styled.div`
	position: relative;
	background: var(--palette-white);
	color: var(--palette-gray-600);
	padding: 0 var(--spacing-4);
	z-index: 2;
`;
