import { ReactNode } from 'react';

export const symbol_view_cozy: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M360-540H180q-24 0-42-18t-18-42v-180q0-24 18-42t42-18h180q24 0 42 18t18 42v180q0 24-18 42t-42 18Zm-180-60h180v-180H180v180Zm180 480H180q-24 0-42-18t-18-42v-180q0-24 18-42t42-18h180q24 0 42 18t18 42v180q0 24-18 42t-42 18Zm-180-60h180v-180H180v180Zm600-360H600q-24 0-42-18t-18-42v-180q0-24 18-42t42-18h180q24 0 42 18t18 42v180q0 24-18 42t-42 18Zm-180-60h180v-180H600v180Zm180 480H600q-24 0-42-18t-18-42v-180q0-24 18-42t42-18h180q24 0 42 18t18 42v180q0 24-18 42t-42 18Zm-180-60h180v-180H600v180ZM360-600Zm0 240Zm240-240Zm0 240Z"
		/>
	</svg>
);
