import { ReactNode } from 'react';

export const symbol_blur_linear: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M197-261q23 0 40.5-17.5T255-319q0-23-17.5-40.5T197-377q-23 0-40.5 17.5T139-319q0 23 17.5 40.5T197-261Zm162.895-184Q376-445 387-455.895q11-10.894 11-27Q398-499 387.105-510q-10.894-11-27-11Q344-521 333-510.105q-11 10.894-11 27Q322-467 332.895-456q10.894 11 27 11Zm0-164Q376-609 387-619.895q11-10.894 11-27Q398-663 387.105-674q-10.894-11-27-11Q344-685 333-674.105q-11 10.894-11 27Q322-631 332.895-620q10.894 11 27 11ZM810-120H150q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-180h660q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-120ZM197-589q23 0 40.5-17.5T255-647q0-23-17.5-40.5T197-705q-23 0-40.5 17.5T139-647q0 23 17.5 40.5T197-589Zm0 164q23 0 40.5-17.5T255-483q0-23-17.5-40.5T197-541q-23 0-40.5 17.5T139-483q0 23 17.5 40.5T197-425Zm162.895 148Q376-277 387-287.895q11-10.894 11-27Q398-331 387.105-342q-10.894-11-27-11Q344-353 333-342.105q-11 10.894-11 27Q322-299 332.895-288q10.894 11 27 11ZM693-296q9 0 16-7t7-16q0-9-7-16t-16-7q-9 0-16 7t-7 16q0 9 7 16t16 7Zm117-484H150q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-840h660q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-780ZM693-624q9 0 16-7t7-16q0-9-7-16t-16-7q-9 0-16 7t-7 16q0 9 7 16t16 7Zm0 164q9 0 16-7t7-16q0-9-7-16t-16-7q-9 0-16 7t-7 16q0 9 7 16t16 7ZM524.895-609Q541-609 552-619.895q11-10.894 11-27Q563-663 552.105-674q-10.894-11-27-11Q509-685 498-674.105q-11 10.894-11 27Q487-631 497.895-620q10.894 11 27 11Zm0 164Q541-445 552-455.895q11-10.894 11-27Q563-499 552.105-510q-10.894-11-27-11Q509-521 498-510.105q-11 10.894-11 27Q487-467 497.895-456q10.894 11 27 11Zm0 168Q541-277 552-287.895q11-10.894 11-27Q563-331 552.105-342q-10.894-11-27-11Q509-353 498-342.105q-11 10.894-11 27Q487-299 497.895-288q10.894 11 27 11ZM120-180v-600 600Z"
		/>
	</svg>
);
