import { ReactNode } from 'react';

export const symbol_multicooker: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M320-760v-60q0-24.75 17.625-42.375T380-880h200q24.75 0 42.375 17.625T640-820v60h140q24.75 0 42.375 17.625T840-700v520q0 24.75-17.625 42.375T780-120H180q-24.75 0-42.375-17.625T120-180v-520q0-24.75 17.625-42.375T180-760h140ZM180-180h600v-390H680v110q0 24.75-17.625 42.375T620-400H340q-24.75 0-42.375-17.625T280-460v-110H180v390Zm129.825-70Q297-250 288.5-258.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Zm170 0Q467-250 458.5-258.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Zm170 0Q637-250 628.5-258.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5ZM340-460h280v-110H340v110ZM180-630h600v-70H180v70Zm200-130h200v-60H380v60Z"
		/>
	</svg>
);
