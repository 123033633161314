import { ReactNode } from 'react';

export const symbol_google_home_devices: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M322-207H132q-24 0-42-18.5T72-268v-512q0-24 18-42t42-18h680q24 0 42 18t18 42v125h-60v-125H132v513h348v147H352q-12.75 0-21.375-8.625T322-150v-57Zm300-84.88Q622-330 648.88-357q26.879-27 65-27Q752-384 779-357.12q27 26.879 27 65Q806-254 779.12-227q-26.879 27-65 27Q676-200 649-226.88q-27-26.879-27-65ZM847.758-625Q864-625 876-613t12 28.39v424.22Q888-144 876-132t-28.242 12H580.242Q564-120 552-132t-12-28.39v-424.22Q540-601 552-613t28.242-12h267.516ZM714-586q-24 0-42 18t-18 42q0 24 18 42t42 18q24 0 42-18t18-42q0-24-18-42t-42-18Zm0 428q59 0 96.5-37.5T848-292q0-59-37.5-96.5T714-426q-59 0-96.5 37.5T580-292q0 59 37.5 96.5T714-158Z"
		/>
	</svg>
);
