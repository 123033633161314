import { ReactNode } from 'react';

export const symbol_screenshot_keyboard: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480.5-390q-37.5 0-64-26.438Q390-442.875 390-480q0-38 26.438-64 26.437-26 63.562-26 38 0 64 26t26 63.5q0 37.5-26 64T480.5-390Zm-.5 60q63 0 106.5-43.5T630-480q0-63-43.5-106.5T480-630q-63 0-106.5 43.5T330-480q0 63 43.5 106.5T480-330ZM140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm0-60h680v-520H140v520Zm0 0v-520 520Z"
		/>
	</svg>
);
