import { ReactNode } from 'react';

export const symbol_process_chart: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M93-241q-11-5-14.5-16.5T80-280l217-427q5-11 17-14t23 2q11 5 14.5 16.5T350-680L133-253q-5 11-17 14t-23-2Zm264 0q-11-5-14.5-16.5T344-280l217-427q5-11 17-14t23 2q11 5 14.5 16.5T614-680L397-253q-5 11-17 14t-23-2Zm264 0q-11-5-14.5-16.5T608-280l217-427q5-11 17-14t23 2q11 5 14.5 16.5T878-680L661-253q-5 11-17 14t-23-2Z"
		/>
	</svg>
);
