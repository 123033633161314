import * as Onboarding from 'routes/public/onboarding';
import * as requiredData from 'routes/public/onboarding/requiredData';
import AccountDetails from 'routes/public/steps/registration/AccountDetails';
import AccountInviteAccountDetails from 'routes/public/steps/account-invite/AccountDetails';
import AccountInviteConfirmation from 'routes/public/steps/account-invite/Confirmation';
import AccountInviteCreateAccount from 'routes/public/steps/account-invite/CreateAccount';
import AccountInviteFinished from 'routes/public/steps/finished/AccountInvite';
import AccountInviteUserInformation from 'routes/public/steps/account-invite/UserInformation';
import AutoJoin from 'routes/public/steps/join-group/AutoJoin';
import WardAccountDetails from 'routes/public/steps/ward-account/WardAccountDetails';
import WardConnect from 'routes/public/steps/ward-account/WardConnect';
import WardFinished from 'routes/public/steps/finished/Ward';
import WardOwnAccount from 'routes/public/steps/ward-account/WardOwnAccount';
import WardUserInformation from 'routes/public/steps/ward-account/WardUserInformation';
import ConfirmUserAndGroup from 'routes/public/steps/registration/ConfirmUserAndGroup';
import CreateAccount from 'routes/public/steps/registration/CreateAccount';
import CreateWardAccount from 'routes/public/steps/ward-account/CreateWardAccount';
import GdprConsent from 'routes/public/steps/gdpr/GdprConsent';
import GdprRequest from 'routes/public/steps/gdpr/GdprRequest';
import GdprRequestPending from 'routes/public/steps/gdpr/GdprRequestPending';
import GroupCode from 'routes/public/steps/registration/GroupCode';
import GroupConfirmation from 'routes/public/steps/registration/GroupConfirmation';
import JoinGroupAccountDetails from 'routes/public/steps/join-group/AccountDetails';
import JoinGroupConfirmation from 'routes/public/steps/join-group/Confirmation';
import JoinGroupCreateAccount from 'routes/public/steps/join-group/CreateAccount';
import JoinGroupRoleSelector from 'routes/public/steps/join-group/RoleSelector';
import JoinGroupUserInformation from 'routes/public/steps/join-group/UserInformation';
import Login from 'routes/public/steps/login/Login';
import LoginEmail from 'routes/public/steps/login/LoginEmail';
import OnboardingWrapper from 'routes/public/styles/OnboardingWrapper';
import OrganizationFinished from 'routes/public/steps/finished/Organization';
import PlayerFinished from 'routes/public/steps/finished/Player';
import ResetPassword from 'routes/public/steps/reset-password/ResetPassword';
import ResetPasswordRequest from 'routes/public/steps/reset-password/ResetPasswordRequest';
import RoleSelector from 'routes/public/steps/registration/RoleSelector';
import StaffFinished from 'routes/public/steps/finished/Staff';
import Start from 'routes/public/steps/Start';
import UserInformation from 'routes/public/steps/registration/UserInformation';
import ParentEmailInvite from 'routes/public/steps/finished/ParentEmailInvite';
import ParentEmailSent from 'routes/public/steps/finished/parent-email-sent';
import SSOSlug from 'routes/public/steps/login/sso/organization-url';
import SSOLogin from 'routes/public/steps/login/sso';
import SSORedirect from 'routes/public/steps/login/sso/redirect';
import SSOSelectAuthMethod from 'routes/public/steps/registration/sso-auth-select';

export default function Wrapper(): JSX.Element {
	return (
		<OnboardingWrapper>
			<Onboarding.Wrapper initialSlug="start" storePrefix="onboarding">
				<Onboarding.Step slug="start" component={Start} />

				{/* Login */}
				<Onboarding.Step slug="login" backSlug="start" component={Login} />
				<Onboarding.Step
					slug="login-email"
					backSlug="login"
					component={LoginEmail}
				/>
				<Onboarding.Step
					slug="sso-org-slug"
					backSlug="login"
					component={SSOSlug}
				/>
				<Onboarding.Step
					slug="sso-login"
					backSlug="sso-org-slug"
					component={SSOLogin}
				/>
				<Onboarding.Step
					slug="/sso/redirect"
					backSlug="sso-login"
					component={SSORedirect}
				/>

				{/* Reset password */}
				<Onboarding.Step
					slug="reset-password-request"
					backSlug="login"
					component={ResetPasswordRequest}
				/>
				<Onboarding.Step slug="reset-password" component={ResetPassword} />

				{/* Registration */}
				<Onboarding.Step
					slug="group-code"
					backSlug="start"
					component={GroupCode}
				/>
				<Onboarding.Step
					slug="group-confirmation"
					backSlug="group-code"
					component={GroupConfirmation}
					progress={0.15}
					requiredData={requiredData.groupConfirmation}
				/>
				<Onboarding.Step
					slug="role-selector"
					backSlug="group-confirmation"
					component={RoleSelector}
					progress={0.3}
					requiredData={requiredData.roleSelector}
				/>
				<Onboarding.Step
					slug="sso-select"
					backSlug="role-selector"
					component={SSOSelectAuthMethod}
					progress={0.35}
				/>
				<Onboarding.Step
					slug="user-information"
					backSlug="role-selector"
					component={UserInformation}
					progress={0.4}
					requiredData={requiredData.userInformation}
				/>
				<Onboarding.Step
					slug="confirm-user-and-group"
					backSlug="user-information"
					component={ConfirmUserAndGroup}
					progress={0.5}
					requiredData={requiredData.confirmUserAndGroup}
				/>
				<Onboarding.Step
					slug="create-account"
					backSlug="confirm-user-and-group"
					progress={0.6}
					component={CreateAccount}
					requiredData={requiredData.createAccount}
				/>
				<Onboarding.Step
					slug="account-details"
					backSlug="create-account"
					component={AccountDetails}
					progress={0.7}
					requiredData={requiredData.accountDetails}
				/>

				{/* GDPR */}
				<Onboarding.Step slug="gdpr-request" component={GdprRequest} />
				<Onboarding.Step
					slug="gdpr-request-pending"
					component={GdprRequestPending}
				/>
				<Onboarding.Step
					slug="parental-consent-request"
					component={GdprConsent}
				/>

				{/* Ward account */}
				<Onboarding.Step
					slug="create-ward-account"
					component={CreateWardAccount}
					progress={0.75}
				/>
				<Onboarding.Step
					slug="ward-connect"
					backSlug="ward-account-details"
					component={WardConnect}
					progress={0.8}
				/>
				<Onboarding.Step
					slug="ward-user-information"
					backSlug="create-ward-account"
					component={WardUserInformation}
					progress={0.8}
				/>
				<Onboarding.Step
					slug="ward-own-account"
					backSlug="ward-user-information"
					component={WardOwnAccount}
					progress={0.9}
				/>
				<Onboarding.Step
					slug="ward-account-details"
					backSlug="ward-own-account"
					component={WardAccountDetails}
					progress={0.95}
				/>

				{/* Account invite */}
				<Onboarding.Step
					slug="claim"
					component={AccountInviteConfirmation}
					progress={0.2}
				/>
				<Onboarding.Step
					slug="account-invite-sso-select"
					backSlug="signup"
					component={SSOSelectAuthMethod}
					progress={0.3}
				/>
				<Onboarding.Step
					slug="account-invite-user-information"
					component={AccountInviteUserInformation}
					progress={0.4}
					requiredData={requiredData.accountInviteUserInformation}
				/>
				<Onboarding.Step
					slug="account-invite-create-account"
					backSlug="account-invite-user-information"
					component={AccountInviteCreateAccount}
					progress={0.6}
					requiredData={requiredData.accountInviteCreateAccount}
				/>
				<Onboarding.Step
					slug="account-invite-account-details"
					backSlug="account-invite-create-account"
					component={AccountInviteAccountDetails}
					progress={0.8}
					requiredData={requiredData.accountInviteAccountDetails}
				/>

				{/* Used for old invite links: /signup=invite_key */}
				<Onboarding.Step
					slug="signup"
					backSlug="start"
					component={AccountInviteConfirmation}
				/>

				{/* Join group */}
				<Onboarding.Step slug="join" component={AutoJoin} />
				<Onboarding.Step
					slug="join-group-confirmation"
					component={JoinGroupConfirmation}
					progress={0.15}
					requiredData={requiredData.joinGroupConfirmation}
				/>
				<Onboarding.Step
					slug="join-group-role-selector"
					backSlug="join-group-confirmation"
					component={JoinGroupRoleSelector}
					progress={0.3}
					requiredData={requiredData.joinGroupRoleSelector}
				/>
				<Onboarding.Step
					slug="join-group-sso-select"
					backSlug="join-group-role-selector"
					component={SSOSelectAuthMethod}
					progress={0.35}
				/>
				<Onboarding.Step
					slug="join-group-user-information"
					backSlug="join-group-role-selector"
					component={JoinGroupUserInformation}
					progress={0.45}
					requiredData={requiredData.joinGroupUserInformation}
				/>
				<Onboarding.Step
					slug="join-group-create-account"
					backSlug="join-group-user-information"
					component={JoinGroupCreateAccount}
					progress={0.6}
					requiredData={requiredData.joinGroupCreateAccount}
				/>
				<Onboarding.Step
					slug="join-group-account-details"
					backSlug="join-group-create-account"
					component={JoinGroupAccountDetails}
					progress={0.75}
					requiredData={requiredData.joinGroupAccountDetails}
				/>

				{/* Finished */}
				<Onboarding.Step slug="ward-finished" component={WardFinished} />
				<Onboarding.Step
					slug="parent-email-invite"
					backSlug="ward-finished"
					component={ParentEmailInvite}
				/>
				<Onboarding.Step
					slug="parent-email-sent"
					backSlug="parent-email-invite"
					component={ParentEmailSent}
				/>
				<Onboarding.Step
					slug="organization-finished"
					component={OrganizationFinished}
				/>
				<Onboarding.Step slug="player-finished" component={PlayerFinished} />
				<Onboarding.Step slug="staff-finished" component={StaffFinished} />
				<Onboarding.Step
					slug="account-invite-finished"
					component={AccountInviteFinished}
				/>
			</Onboarding.Wrapper>
		</OnboardingWrapper>
	);
}
