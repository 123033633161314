import { ReactNode } from 'react';

export const symbol_language_chinese_wubi: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M241-298h180v-176H277q-8 44.388-16.5 87.694Q252-343 241-298Zm287 38H99q-7.917 0-13.458-5.559Q80-271.118 80-279.059t5.542-13.441Q91.083-298 99-298h98q11-45 20-88.5t17-87.5h-81q-8 0-14-6t-6-14q0-8 6-14t14-6h88.778Q247-544 252.5-574q5.5-30 10.5-61H133q-7.917 0-13.458-5.559-5.542-5.559-5.542-13.5t5.542-13.441Q125.083-673 133-673h356q7.917 0 13.458 5.559 5.542 5.559 5.542 13.5t-5.542 13.441Q496.917-635 489-635H307.109Q302-605 296-575t-11 61h144q12.75 0 21.375 8.625T459-484v186h69q7.917 0 13.458 5.559 5.542 5.559 5.542 13.5t-5.542 13.441Q535.917-260 528-260Zm176-60q32.494 0 63.247-15T824-377.508V-499q-29 4-51 8.5T731-480q-50 15.782-75 39.455-25 23.672-25 55.236Q631-356 650.909-338q19.909 18 53.091 18Zm-14 51q-54 0-86.5-31T571-383q0-50 34-83.5T709.719-520Q732-526 760.5-531.5T824-542q0-56-18.5-82.5T739-651q-22 0-44 6.5T644-622q-10 5-19.849 2.667Q614.303-621.667 609-631q-5-10-2.5-20t12.5-15q31-17 64.5-27t67.5-10q69 0 101 42.5T884-540v237q0 10.606-7.078 17.803-7.079 7.197-17.51 7.197h-3.726q-9.686 0-16.186-6.5T831-300l-3-27q-30 28-66.5 43T690-269Z"
		/>
	</svg>
);
