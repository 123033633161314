import { ChangeEvent, useState } from 'react';
import { t } from '@transifex/native';

import { palette } from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import rgba from 'pkg/rgba';
import * as actions from 'pkg/actions';

import { useCommentsContext } from 'routes/event/single/comments';

import Icon from 'components/icon';
import Avatar from 'components/avatar';

import * as Input from 'components/form/inputs';

import * as css from './styles.css';

interface EventCommentFormProps {
	eventId: number;

	// Optional function to run after comment has been created/edited
	onComment?: () => void;

	user: models.user.User;
	comment?: models.eventComment.EventComment;
}

export default function EventCommentForm({
	user,
	eventId,
	comment = null,
	onComment = null,
}: EventCommentFormProps) {
	const commentsCtx = useCommentsContext();
	const [commentValue, setCommentValue] = useState(comment?.comment || '');

	const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
		setCommentValue(e.target.value);
	};

	const handleComment = async () => {
		if (comment) {
			const [ok, updatedComment] = await actions.eventComments.update(
				comment.id,
				commentValue
			);

			if (ok) {
				commentsCtx.commentsCollectionResponse.replaceRecord(updatedComment);
			}
		} else {
			const [ok, newComment] = await actions.eventComments.create(
				eventId,
				commentValue
			);

			if (ok) {
				setCommentValue('');
				commentsCtx.commentsCollectionResponse.addRecord(newComment);
			}
		}

		if (onComment) {
			onComment();
		}
	};

	return (
		<form className={css.commentFormWrapper}>
			<Avatar user={user} />
			<Input.Area
				value={commentValue}
				placeholder={t('Comment')}
				onChange={handleChange}
			/>
			<Icon
				name="plane"
				className={css.iconStyling}
				fill={
					commentValue.length < 1
						? rgba(palette.icon.idleFillColor)
						: rgba(palette.icon.activeFillColor)
				}
				actualSize
				size={1.2}
				onClick={handleComment}
			/>
		</form>
	);
}
