import { ReactNode } from 'react';

export const symbol_dishwasher_gen: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M320.175-690q12.825 0 21.325-8.675 8.5-8.676 8.5-21.5 0-12.825-8.675-21.325-8.676-8.5-21.5-8.5-12.825 0-21.325 8.675-8.5 8.676-8.5 21.5 0 12.825 8.675 21.325 8.676 8.5 21.5 8.5Zm120 0q12.825 0 21.325-8.675 8.5-8.676 8.5-21.5 0-12.825-8.675-21.325-8.676-8.5-21.5-8.5-12.825 0-21.325 8.675-8.5 8.676-8.5 21.5 0 12.825 8.675 21.325 8.676 8.5 21.5 8.5Zm39.927 440Q539-250 580.5-290.085q41.5-40.084 41.5-97.881Q622-415 611.5-440T581-485l-80-80q-9-9-21-9t-21 9l-76 76q-20 20-33 46t-12 55q1.87 57.811 43.005 97.905Q422.14-250 480.102-250Zm.016-57Q446-307 422-330.655t-24-57.448Q398-405 406-421t21-28l53-52 57 56q11 11 18 25.5t7 31.397q0 33.793-23.882 57.448-23.883 23.655-58 23.655ZM220-80q-24.75 0-42.375-17.625T160-140v-680q0-24.75 17.625-42.375T220-880h520q24.75 0 42.375 17.625T800-820v680q0 24.75-17.625 42.375T740-80H220Zm0-60h520v-680H220v680Zm0 0v-680 680Z"
		/>
	</svg>
);
