import { ReactNode } from 'react';

export const symbol_snooze: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m452-381 120-136 4-10v-18q0-10.833-7.083-17.917Q561.833-570 551-570H408q-10.833 0-17.917 7.116-7.083 7.117-7.083 18Q383-534 390.083-527q7.084 7 17.917 7h101L387-382l-4 10v16q0 10.833 7.083 17.917Q397.167-331 408-331h143q10.833 0 17.917-7.116 7.083-7.117 7.083-18Q576-367 568.917-374q-7.084-7-17.917-7h-99Zm27 299q-74 0-139.5-28t-114-76.5q-48.5-48.5-77-114T120-440.733q0-74.733 28.5-140T225.5-695q48.5-49 114-77T479-800q74 0 139.5 28T733-695q49 49 77 114.267t28 140Q838-366 810-300.5t-77 114Q684-138 618.5-110T479-82Zm0-357ZM71-688q-9-9-8.5-21t9.5-21l121-117q9-8 21.5-7.5T235-846q9 9 8.5 21t-9.5 21L113-687q-9 8-21.5 7.5T71-688Zm816 0q-8 8-20.5 8.5T845-687L724-804q-9-8-9.5-20.5T723-846q8-8 20.5-8.5T765-847l121 117q9 8 9.5 20.5T887-688ZM479.043-142Q604-142 691-229.043t87-212Q778-566 690.957-653t-212-87Q354-740 267-652.957t-87 212Q180-316 267.043-229t212 87Z"
		/>
	</svg>
);
