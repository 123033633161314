import { ReactNode } from 'react';

export const symbol_music_cast: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M530-161q-63 0-105-42t-42-105q0-63 42-105t105-42q26 0 46.5 7.5T617-426v-344q0-13 8.5-21.5T647-800h203q13 0 21.5 8.5T880-770v50q0 13-8.5 21.5T850-690H677v382q0 63-42 105t-105 42ZM250-588q-47 47-75.5 109T141-342q-1 14-9.5 24T110-308q-14 0-22-10.5T81-343q6-88 38.5-160.5T207-631q55-55 128-87.5T495-757q14-1 24.5 7.5T530-728q0 13-9.5 21.5T497-697q-75 5-137.5 33.5T250-588Zm84 83q-31 31-49.5 73T261-339q-2 13-10 22t-21 9q-13 0-21.5-9.5T201-340q5-63 28.5-115t62.5-91q39-39 91.5-62.5T499-637q13-1 22 7.5t9 21.5q0 13-9 21.5t-22 9.5q-51 5-92.5 23T334-505Z"
		/>
	</svg>
);
