import { ReactNode } from 'react';

export const symbol_view_module: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M621-510h159v-190H621v190Zm-221 0h161v-190H400v190Zm-219 0h159v-190H181v190Zm0 250h159v-190H181v190Zm219 0h161v-190H400v190Zm221 0h159v-190H621v190Zm-500 0v-440q0-24.75 17.625-42.375T181-760h599q24.75 0 42.375 17.625T840-700v440q0 24.75-17.625 42.375T780-200H181q-24.75 0-42.375-17.625T121-260Z"
		/>
	</svg>
);
