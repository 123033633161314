import { ReactNode } from 'react';

export const symbol_insert_page_break: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M220-80q-24.75 0-42.375-17.625T160-140v-140q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T220-280v140h520v-140q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T800-280v140q0 24.75-17.625 42.375T740-80H220Zm-60-740q0-24.75 17.625-42.375T220-880h336q12.444 0 23.722 5T599-862l183 183q8 8 13 19.278 5 11.278 5 23.722v116q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T740-520v-114H581q-12.75 0-21.375-8.625T551-664v-156H220v300q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T160-520v-300Zm220 450q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T380-430h200q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T580-370H380Zm320 0q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T700-430h190q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T890-370H700Zm-630 0q-12.75 0-21.375-8.675Q40-387.351 40-400.175 40-413 48.625-421.5T70-430h190q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T260-370H70Zm410-120Zm0 180Z"
		/>
	</svg>
);
