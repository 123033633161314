import { Record } from 'immutable';
import { schema } from 'normalizr';

import SessionItem from 'pkg/models/session_item';

const normalizrSchema = new schema.Entity('sessionBlocks', {
	items: [SessionItem.normalizr()],
});

interface ISessionBlock {
	id: number;
	trainingSessionId: number;
	order: number;
	duration: number;
	title: string;
	description: string;
	items: SessionItem[];
}

const sessionBlockProps: ISessionBlock = {
	id: 0,
	trainingSessionId: 0,
	order: 0,
	duration: 0,
	title: '',
	description: '',
	items: [],
};

class SessionBlock
	extends Record(sessionBlockProps, 'SessionBlock')
	implements ISessionBlock
{
	static normalizr(): schema.Entity {
		return normalizrSchema;
	}
}

export default SessionBlock;
