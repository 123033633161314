import { batch } from 'react-redux';
import { normalize } from 'normalizr';

import Account from 'pkg/models/account';
import Match from 'pkg/models/match';
import User from 'pkg/models/user';
import MatchEvent from 'pkg/models/match_event';
import MatchCompetition from 'pkg/models/match_competition';
import MatchOpponent from 'pkg/models/match_opponent';
import Notification from 'pkg/models/notification';
import EventUser from 'pkg/models/event_user';
import Exercise from 'pkg/models/exercise';
import SessionItem from 'pkg/models/session_item';
import SessionBlock from 'pkg/models/session_block';
import Tag from 'pkg/models/tag';
import Session from 'pkg/models/session';
import TrainingCollection from 'pkg/models/training_collection';
import RatingItem from 'pkg/models/rating_item';
import Membership from 'pkg/models/membership';
import Group from 'pkg/models/group';
import Sport from 'pkg/models/sport';
import UserGoal from 'pkg/models/user_goal';
import PerformanceReview from 'pkg/models/performance_review';
import Video from 'pkg/models/video';
import VideoSequence from 'pkg/models/video_sequence';
import VideoSequenceUser from 'pkg/models/video_sequence_user';
import VideoSequenceComment from 'pkg/models/video_sequence_comment';
import VideoPlaylist from 'pkg/models/video_playlist';
import VideoPlaylistSequence from 'pkg/models/video_playlist_sequence';
import AccountRelation from 'pkg/models/account_relation';
import ProductCategory from 'pkg/models/product_category';
import Product from 'pkg/models/product';
import UserProduct from 'pkg/models/user_product';
import Price from 'pkg/models/price';
import TaxRate from 'pkg/models/tax_rates';
import Order from 'pkg/models/order';
import Rating from 'pkg/models/rating';
import EventSeries from 'pkg/models/event_series';
import EventComments from 'pkg/models/event_comments';
import Form from 'pkg/models/form';
import FormSubmission from 'pkg/models/form_submission';
import Country from 'pkg/models/country';
import FormCategory from 'pkg/models/form_category';
import EventSeriesUser from 'pkg/models/event_series_user';

import * as types from './action-types';

// dispatchMap holds default actions for normalizr keys.
// If full is true the entire normalizr result will be sent to the action, if false only the entries from key.
// Set key to override the param name in the reducer. Will default to "payload"
const dispatchMap = {
	[Order.normalizr()._key]: {
		action: types.Orders.SET_ITEMS,
		full: true,
	},
	[Form.normalizr()._key]: {
		action: types.Forms.SET_ITEMS,
		full: true,
	},
	[FormSubmission.normalizr()._key]: {
		action: types.FormSubmissions.SET_ITEMS,
		full: true,
	},
	[Country.normalizr()._key]: {
		action: types.Countries.SET_ITEMS,
		key: 'countries',
		full: false,
	},
	[FormCategory.normalizr()._key]: {
		action: types.FormCategories.SET_ITEMS,
		full: true,
	},
	[TaxRate.normalizr()._key]: {
		action: types.TaxRates.SET_ITEMS,
		full: true,
	},
	[Price.normalizr()._key]: {
		action: types.Prices.SET_ITEMS,
		full: true,
	},
	[Product.normalizr()._key]: {
		action: types.Products.SET_ITEMS,
		full: true,
	},
	[ProductCategory.normalizr()._key]: {
		action: types.ProductCategories.SET_ITEMS,
		full: true,
	},
	[Account.normalizr()._key]: {
		action: types.Accounts.SET_ITEMS,
		full: true,
	},
	[AccountRelation.normalizr()._key]: {
		action: types.AccountRelations.SET_ITEMS,
		full: true,
	},
	[Match.normalizr()._key]: {
		action: types.ADD_MATCHES,
		key: 'matches',
		full: false,
	},
	[UserGoal.normalizr()._key]: {
		action: types.UserGoals.ADD_ITEMS,
		full: true,
	},
	[User.normalizr()._key]: {
		action: types.Users.ADD_USERS,
		full: true,
	},
	[UserProduct.normalizr()._key]: {
		action: types.UserProducts.SET_ITEMS,
		full: true,
	},
	[Group.normalizr()._key]: {
		action: types.Groups.ADD_ITEMS,
		full: true,
	},
	[Membership.normalizr()._key]: {
		action: types.Memberships.SET_ITEMS,
		full: true,
	},
	[MatchEvent.normalizr()._key]: {
		action: types.MatchEvents.ADD_EVENTS,
		full: true,
	},
	[MatchCompetition.normalizr()._key]: {
		action: types.MatchCompetitions.SET_ITEMS,
		full: false,
	},
	[MatchOpponent.normalizr()._key]: {
		action: types.MatchOpponents.SET_ITEMS,
		full: false,
	},
	[PerformanceReview.normalizr()._key]: {
		action: types.PerformanceReviews.SET_ITEMS,
		full: true,
	},
	[EventUser.normalizr()._key]: {
		action: types.EVENT_SET_USERS_SUCCESS,
		full: true,
	},
	[EventSeries.normalizr()._key]: {
		action: types.EventSeries.SET_ITEMS,
		full: true,
	},
	[EventSeriesUser.normalizr()._key]: {
		action: types.EventSeriesUsers.SET_ITEMS,
		full: true,
	},
	[EventComments.normalizr()._key]: {
		action: types.EventComments.SET_ITEMS,
		full: true,
	},
	[Exercise.normalizr()._key]: {
		action: types.Exercises.SET_ITEMS,
		full: true,
	},
	[SessionItem.normalizr()._key]: {
		action: types.SessionItems.SET_ITEMS,
		full: true,
	},
	[SessionBlock.normalizr()._key]: {
		action: types.SessionBlocks.SET_ITEMS,
		full: true,
	},
	[Session.normalizr()._key]: {
		action: types.Sessions.SET_ITEMS,
		full: true,
	},
	[Tag.normalizr()._key]: {
		action: types.Tags.SET_ITEMS,
		full: true,
	},
	[TrainingCollection.normalizr()._key]: {
		action: types.TrainingCollections.SET_ITEMS,
		full: true,
	},
	[Sport.normalizr()._key]: {
		action: types.Sports.SET_ITEMS,
		full: true,
	},
	[RatingItem.normalizr()._key]: {
		action: types.RatingItems.SET_ITEMS,
		full: true,
	},
	[Rating.normalizr()._key]: {
		action: types.Ratings.SetItems,
		key: 'ratings',
		full: false,
	},
	[Notification.normalizr()._key]: {
		action: types.Notifications.SET_ITEMS,
		full: true,
	},
	[Video.normalizr()._key]: {
		action: types.ADD_VIDEOS,
		full: true,
	},
	[VideoSequence.normalizr()._key]: {
		action: types.VideoSequences.SET_ITEMS,
		full: false,
		key: 'payload',
	},
	[VideoSequenceUser.normalizr()._key]: {
		action: types.VideoSequenceUsers.SET_ITEMS,
		full: true,
	},
	[VideoSequenceComment.normalizr()._key]: {
		action: types.VideoSequenceComments.SET_ITEMS,
		full: true,
	},
	[VideoPlaylist.normalizr()._key]: {
		action: types.VideoPlaylists.SET_ITEMS,
	},
	[VideoPlaylistSequence.normalizr()._key]: {
		action: types.VideoPlaylistSequences.SET_ITEMS,
	},
};

// normalizedDispatch normalized and dispatches an API result automatically.
// Any schema that exists in dispatchMap
export const normalizedDispatch =
	(data, schema, inBatch = true) =>
	(dispatch) => {
		const d = normalize(data, schema);

		const dispatchData = () =>
			Object.keys(d.entities).forEach((k) => {
				if (!dispatchMap[k]) {
					return;
				}

				dispatch({
					type: dispatchMap[k].action,
					[dispatchMap[k].key || 'payload']: dispatchMap[k].full
						? d
						: d.entities[k],
				});
			});

		inBatch ? batch(dispatchData) : dispatchData();

		return d;
	};

export const forAccountHeaders = (accountId) => {
	if (!accountId) return {};

	return { 'X-For-Account': accountId.toString() };
};
