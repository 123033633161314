import { ReactNode } from 'react';

export const symbol_air_freshener: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-756q-17.425 0-29.212-11.787Q439-779.575 439-797t11.788-29.213Q462.575-838 480-838q17.425 0 29.212 11.787Q521-814.425 521-797t-11.788 29.213Q497.425-756 480-756Zm0-122q-17.425 0-29.212-11.787Q439-901.575 439-919t11.788-29.213Q462.575-960 480-960q17.425 0 29.212 11.787Q521-936.425 521-919t-11.788 29.213Q497.425-878 480-878ZM329-80q-59.765-36.398-93.382-97.699Q202-239 200-310q1-80 44.5-146.5T360-557v-129q0-12.75 8.625-21.375T390-716h180q12.75 0 21.375 8.625T600-686v129q72 35 115 101t45 146q-1 71-35 132t-94 98H329Zm18-60h266q41-30 64-74.351T700-310q0-60.994-32.5-111.997T580-499l-40-19v-138H420v138l-40 19q-55 26-87.5 77.128T260-309.601Q260-259 283-214.5t64 74.5Zm-67-698q-17.425 0-29.212-11.787Q239-861.575 239-879t11.788-29.213Q262.575-920 280-920q17.425 0 29.212 11.787Q321-896.425 321-879t-11.788 29.213Q297.425-838 280-838Zm400 0q-17.425 0-29.213-11.787Q639-861.575 639-879t11.787-29.213Q662.575-920 680-920t29.213 11.787Q721-896.425 721-879t-11.787 29.213Q697.425-838 680-838Zm-320 82q-17.425 0-29.212-11.787Q319-779.575 319-797t11.788-29.213Q342.575-838 360-838q17.425 0 29.212 11.787Q401-814.425 401-797t-11.788 29.213Q377.425-756 360-756Zm240 0q-17.425 0-29.213-11.787Q559-779.575 559-797t11.787-29.213Q582.575-838 600-838t29.213 11.787Q641-814.425 641-797t-11.787 29.213Q617.425-756 600-756ZM480-310Z"
		/>
	</svg>
);
