import { ReactNode } from 'react';

export const symbol_outbox: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m453-611-58 58q-9 9-21 9t-21.391-9Q344-562 344-574.5t9-21.5l109-110q5-5 10.133-7 5.134-2 11-2Q489-715 494-713q5 2 10 7l110 110q9 9 9 21t-8.609 21q-9.391 9-21.891 9-12.5 0-21.5-9l-58-57v159q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T453-452v-159ZM180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-60h600v-136H634q-26 40-67.5 61.5T480-233q-45 0-86.5-21.5T326-316H180v136Zm300.017-113Q515-293 544.5-310.5 574-328 591-359q7-10 18.5-13.5T633-376h147v-404H180v404h147q12 0 23.5 3.5T369-359q17 31 46.517 48.5 29.517 17.5 64.5 17.5ZM180-180h600-600Z"
		/>
	</svg>
);
