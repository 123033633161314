import { ReactNode } from 'react';

export const symbol_phone_in_talk: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M804-487q-10.927 0-19.463-8Q776-503 774-515q-14-103-87.5-176.5T510-779q-12-2-20-10.488T482-810q0-13.175 9-22.088Q500-841 513-839q127.189 14.132 217.028 103.972Q819.868-645.189 834-518q2 13-7.2 22t-22.8 9Zm-172.151 0q-9.849 0-18.349-6.5T602-511q-10-35-35.5-60.5T506-607q-11-3-17.5-10.593-6.5-7.592-6.5-18.222Q482-651 492-660q10-9 24-6 53.956 12.585 92.978 51.793Q648-575 662-521q3 14-6 24t-24.151 10ZM795-120q-116 0-236.5-56T335-335Q232-438 176-558.5T120-795q0-19.286 12.857-32.143T165-840h140q14 0 24 10t14 25l26.929 125.641Q372-665 369.5-653.5q-2.5 11.5-10.729 19.726L259-533q26 44 55 82t64 72q37 38 78 69.5t86 55.5l95-98q10-11 23.151-15T686-369l119 26q15 4 25 16.044T840-300v135q0 19.286-12.857 32.143T795-120ZM229-588l81-82-23-110H180q2 42 13.5 88.5T229-588Zm369 363q41 19 89 31t93 14v-107l-103-21-79 83ZM229-588Zm369 363Z"
		/>
	</svg>
);
