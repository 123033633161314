import { ReactNode } from 'react';

export const symbol_detector_smoke: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M485-77q-12-4-17.5-15t-1.5-23q10-30 8.5-57.5T456-228q-28-45-30.5-83t12.5-87q4-11 14.5-17t22.5-2q12 4 17.5 15t1.5 23q-11 32-10 58t20 55q27 44 30 83t-12 87q-4 11-14.5 17T485-77Zm-176 0q-12-4-17.5-15t-1.5-23q10-29 8.5-58T280-229q-29-42-30.5-82t12.5-87q4-11 14.5-17t22.5-2q12 4 17.5 15t1.5 23q-10 30-10 58.5t20 55.5q27 41 29.5 82.5T346-96q-4 11-14.5 17T309-77Zm348 0q-12-4-17.5-15t-1.5-23q10-29 8.5-58T628-229q-29-41-30.5-82t12.5-87q4-11 14.5-17t22.5-2q12 4 17.5 15t1.5 23q-11 31-10.5 57.5T676-266q28 42 30 82.5T694-96q-4 11-14.5 17T657-77ZM180-780v60h600v-60H180Zm121 120 18 60h322l18-60H301Zm18 120q-20 0-35.5-11.5T262-582l-25-78h-57q-25 0-42.5-17.5T120-720v-60q0-25 17.5-42.5T180-840h600q25 0 42.5 17.5T840-780v60q0 25-17.5 42.5T780-660h-57l-30 81q-7 17-22.5 28T636-540H319ZM180-780v60-60Z"
		/>
	</svg>
);
