import { useEffect } from 'react';
import { Keyboard, KeyboardInfo } from '@capacitor/keyboard';

import { isApp, isIOS } from 'pkg/platform';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';

function handleKeyboardWillShow(info: KeyboardInfo) {
	const appBody: HTMLElement = document.querySelector('body');
	const focusedElement = document.activeElement;
	const tabBar = document.getElementById('tab-bar');
	const modalWrapper = document.getElementById('modal-wrapper');
	const contextMenuWrapper = document.getElementById('context-menu-wrapper');

	let height: number;

	if (modalWrapper || contextMenuWrapper) {
		// Ignore tab bar if a modal or context menu is open
		height = window.innerHeight - info.keyboardHeight;
	} else if (focusedElement.tagName.toLowerCase() === 'iframe') {
		// Ignore tab bar when the support modal is open
		height = window.innerHeight - info.keyboardHeight;
	} else if (tabBar) {
		// If tab bar is visible, hide it and add its height to body
		const tabBarHeight = tabBar.offsetHeight;
		height = window.innerHeight - info.keyboardHeight + tabBarHeight;
		tabBar.style.visibility = 'hidden';
	} else {
		height = window.innerHeight - info.keyboardHeight;
	}

	appBody.style.maxHeight = height + 'px';
	appBody.style.transition = 'max-height 490ms cubic-bezier(0,0.88,0.22,1)';

	if (
		!focusedElement.hasAttribute('data-keyboard-position') &&
		focusedElement.tagName.toLowerCase() !== 'iframe'
	) {
		focusedElement.scrollIntoView({
			behavior: 'smooth',
			block: 'nearest',
		});
	}
}

function handleKeyboardDidShow() {
	const appBody: HTMLElement = document.querySelector('body');
	appBody.style.transition = 'max-height 490ms cubic-bezier(0,0.88,0.22,1)';

	const focusedElement = document.activeElement;

	if (
		!focusedElement.hasAttribute('data-keyboard-position') &&
		focusedElement.tagName.toLowerCase() !== 'iframe'
	) {
		focusedElement.scrollIntoView({
			behavior: 'smooth',
			block: 'nearest',
		});
	}
}

function handleKeyboardWillHide() {
	const appBody: HTMLElement = document.querySelector('body');
	const tabBar = document.getElementById('tab-bar');

	if (tabBar) {
		tabBar.style.visibility = 'visible';
	}

	appBody.style.maxHeight = '100%';
	appBody.style.transition = 'max-height 490ms cubic-bezier(0,0.88,0.22,1)';
}

function handleKeyboardDidHide() {
	const appBody: HTMLElement = document.querySelector('body');
	appBody.style.maxHeight = '100%';
	appBody.style.transition = 'max-height 490ms cubic-bezier(0,0.88,0.22,1)';
}

export function useKeyboardListener(fn: (open: boolean) => void) {
	useComponentDidMount(
		() => {
			if (!isApp()) {
				return;
			}

			if (isIOS()) {
				Keyboard.addListener('keyboardWillShow', handleKeyboardWillShow);
				Keyboard.addListener('keyboardWillHide', handleKeyboardWillHide);
				Keyboard.addListener('keyboardDidShow', handleKeyboardDidShow);
				Keyboard.addListener('keyboardDidHide', handleKeyboardDidHide);
			}

			window.Beacon('on', 'close', () => {
				Keyboard.hide();
			});
		},
		() => {
			if (isApp()) {
				Keyboard.removeAllListeners();
			}
		}
	);

	useEffect(() => {
		const handleKeyboardOpen = () => {
			document.body.classList.add('keyboard-open');
			fn(true);
		};

		const handleKeyboardClosed = () => {
			document.body.classList.remove('keyboard-open');
			fn(false);
		};

		window.addEventListener('keyboardWillShow', handleKeyboardOpen);
		window.addEventListener('keyboardWillHide', handleKeyboardClosed);

		return () => {
			window.removeEventListener('keyboardWillShow', handleKeyboardOpen);
			window.removeEventListener('keyboardWillHide', handleKeyboardClosed);
		};
	}, []);
}
