import { ReactNode } from 'react';

export const symbol_30fps: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M335-200H120q-16.667 0-28.333-11.735Q80-223.471 80-240.235 80-257 91.667-268.5 103.333-280 120-280h215v-160H123q-16.667 0-28.333-11.735Q83-463.471 83-480.235 83-497 94.667-508.5 106.333-520 123-520h212v-160H120q-16.667 0-28.333-11.735Q80-703.471 80-720.235 80-737 91.667-748.5 103.333-760 120-760h215q33.333 0 56.667 23.333Q415-713.333 415-680v131q0 29-19 50t-48 21q29 0 48 19t19 48v131q0 33.333-23.333 56.667Q368.333-200 335-200Zm247-80h218v-400H582v400Zm0 80q-33.333 0-56.667-23.333Q502-246.667 502-280v-400q0-33.333 23.333-56.667Q548.667-760 582-760h218q33.333 0 56.667 23.333Q880-713.333 880-680v400q0 33.333-23.333 56.667Q833.333-200 800-200H582Z"
		/>
	</svg>
);
