import { useT } from '@transifex/react';

import DateTime from 'pkg/datetime';
import * as models from 'pkg/api/models';

import InfoBox from 'components/form/info-box';

interface RSVPInfoBoxProps {
	event: models.event.Event;
}

export default function RSVPInfoBox({ event }: RSVPInfoBoxProps) {
	const t = useT();

	const now = new DateTime(new Date()).getUnixTimestamp();
	const dt = DateTime.fromTimestamp(event.rsvpBefore);
	const dateString = dt.toLocaleDateString({
		hour: 'numeric',
		minute: 'numeric',
		month: 'short',
		day: 'numeric',
		year: 'numeric',
	});

	let string = t('RSVP before: {dateString}', { dateString });

	if (now > dt.getUnixTimestamp()) {
		string = t('RSVP date has passed');
	}

	return <InfoBox color="orange" icon="notification-outline" text={string} />;
}
