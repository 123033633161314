import { ReactNode } from 'react';

export const symbol_control_camera: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M479.824-375Q436-375 405.5-405.676q-30.5-30.677-30.5-74.5Q375-524 405.676-554.5q30.677-30.5 74.5-30.5Q524-585 554.5-554.323q30.5 30.676 30.5 74.499Q585-436 554.323-405.5 523.647-375 479.824-375ZM437-122 322-237q-9-9-9-21t8.842-21q8.842-9 21-9T364-279l114 115 116-116q9-9 21-9t21 9q9 9 9 21.5t-9 21.5L522-122q-9 9-20.25 13.5t-22.5 4.5q-11.25 0-22.321-4.5Q445.857-113 437-122ZM237-321 122-436q-9-8.833-13.5-19.875t-4.5-22.083q0-11.042 4.5-22.471Q113-511.857 122-521l115-115q8.8-9 20.9-9 12.1 0 21.1 8.842 9 8.842 9 21T279-594L164-479l115 115q9 9 9 21.5t-9 21.5q-9 9-21 9t-21-9Zm84-400 115-115q9-9 20-13t22-4q11 0 22 4t20 13l116 115q9 9 9 21t-8.842 21q-8.842 9-21 9T594-679L479-794 363-678q-9 9-21 8.5t-21-9.5q-9-9-9-21t9-21Zm358 357 114-114-115-116q-9-9-9-21.5t9-21.5q9-9 21.5-9t21.5 9l116 116q9 9 13 20t4 22q0 11-4 22t-13 20L721-321q-9 9-21 8.5t-21-9.342q-9-8.842-9-21T679-364Z"
		/>
	</svg>
);
