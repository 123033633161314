import styled, { css, DefaultTheme } from 'styled-components';
import { ReactNode } from 'react';

import * as styles from 'pkg/config/styles';

import Link from 'pkg/router/Link';
import { addCssVar } from 'pkg/cssvars';

import MaterialSymbol from 'components/material-symbols';
import Icon from 'components/icon';

import * as ContextMenu from 'design/context_menu';

export const Wrap = styled.header`
	grid-area: titlebar;
	min-width: 0;
	z-index: 1;
	background: ${addCssVar((theme: DefaultTheme) =>
		theme.darkMode ? styles.palette.gray[900] : styles.palette.white
	)};
	border-bottom: ${addCssVar((theme: DefaultTheme) =>
		theme.darkMode ? 'none' : `1px solid var(--palette-gray-300)`
	)};
`;

export const Header = styled.div<{
	theme?: DefaultTheme;
}>`
	height: calc(env(safe-area-inset-top) + 60px);
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	padding: 0px var(--spacing-5) 0 var(--spacing-6);
	padding-top: env(safe-area-inset-top);
	overflow-x: hidden;

	${({ theme }) =>
		theme.darkMode &&
		css`
			color: var(--palette-white);
		`}

	@media print {
		display: none;
	}
`;

export const PageTitle = styled.h1<{
	context?: string;
	theme?: DefaultTheme;
}>`
	display: flex;
	flex-direction: column;
	font-weight: var(--font-weight-semibold);
	margin: 0;
	flex: 1 1 0;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: left;
	font-weight: var(--font-weight-semibold);
	color: var(--palette-black);
	font-size: var(--font-size-xl);

	${(props) =>
		props.context?.length > 0 &&
		css`
			&::after {
				margin-top: var(--spacing-1);
				content: '${props.context}';
				display: block;
				color: ${props.theme.accentColor || styles.palette.gray[500]};
				font-size: var(--font-size-sm);
				font-weight: var(--font-weight-normal);
			}
		`};

	${(props) =>
		props.theme?.darkMode &&
		css`
			color: var(--palette-white);

			&::after {
				color: var(--palette-gray-500);
			}
		`}
`;

export const Back = styled(Icon)<{
	disabled?: boolean;
}>`
	transform: rotateZ(180deg);
	height: 32px;
	width: 32px;
	border-radius: var(--radius-3);
	margin-right: var(--spacing-4);
	padding: var(--spacing-2);
	display: block;
	color: ${(props) => props.theme.accentColor || styles.palette.black};
	background-color: ${(props) =>
		props.theme.primaryColor || styles.palette.brand};

	${({ disabled }) =>
		disabled &&
		css`
			cursor: not-allowed;
			opacity: 0.5;
		`};
`;

export const PageIcon = styled(MaterialSymbol)`
	width: 25px;
	height: 25px;
	display: block;
	margin-right: var(--spacing-3);
`;

export const NavWrap = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1 1 0;
	padding: 0px var(--spacing-5) 0 var(--spacing-6);
	overflow: hidden;
	margin-top: -4px;
`;

export const Actions = styled.div`
	display: grid;
	grid-auto-flow: column;
	column-gap: var(--spacing-3);
	align-items: center;
`;

export const SubNavItem = styled(Link)`
	display: block;
	position: relative;
	padding: var(--spacing-3);
	color: var(--palette-gray-600);
	white-space: nowrap;
	margin-right: var(--spacing-5);
	margin-bottom: var(--spacing-4);

	&::after {
		content: '';
		position: absolute;
		bottom: -12px;
		left: 0;
		width: 100%;
		height: 4px;
		background: transparent;
		transition: background-color 0.3s ease;
	}

	&[data-current-page] {
		color: var(--palette-black);
		font-weight: var(--font-weight-semibold);
		&::after {
			background-color: ${(props) =>
				props.theme.primaryColor || styles.palette.brand};
		}
	}

	@media (hover: hover) {
		&:not([data-current-page]):hover {
			color: var(--palette-black);
			background: var(--palette-gray-200);
			border-radius: var(--radius-4);
		}
	}
`;

export const Divider = styled.div`
	margin: 0 var(--spacing-6);
	position: relative;
	height: 30px;
	width: 1px;
	background: var(--palette-gray-300);
`;

const Trigger = styled.div<{ theme?: DefaultTheme }>`
	user-select: none;
	background: ${(props) => props.theme.primaryColor || styles.palette.brand};
	color: ${(props) => props.theme.accentColor || styles.palette.black};
	border-radius: 20px;
	padding: var(--spacing-2);
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	transition: box-shadow 150ms ease-in-out;
	line-height: 1.2em;

	span {
		font-weight: var(--font-weight-semibold);
	}

	@media (hover: hover) {
		&:hover {
			${(props) =>
				props.theme.accentColor
					? css`
							color: ${props.theme.accentColorTextHover};
							box-shadow: ${props.theme.accentColorTextHover} 0 0 0 4px;
						`
					: css`
							color: var(--palette-yellow-900);
							box-shadow: var(--palette-gray-200) 0 0 0 4px;
						`}
		}
	}
`;

const TriggerIcon = styled(Icon)`
	font-size: var(--font-size-2xl);
`;

interface ActionTriggerProps {
	offsetHorizontal: number;
	offsetVertical: number;
	children?: ReactNode | ReactNode[];
}

export const ActionTrigger = (props: ActionTriggerProps): JSX.Element => {
	const toggle = (
		<Trigger data-testid="create_context_menu">
			<TriggerIcon name="add" />
		</Trigger>
	);

	return (
		<ContextMenu.Menu
			offsetHorizontal={props.offsetHorizontal}
			offsetVertical={props.offsetVertical}
			toggleWith={toggle}
			appearFrom={ContextMenu.AppearFrom.TopRight}>
			{props.children}
		</ContextMenu.Menu>
	);
};
