import { cssClasses } from 'pkg/css/utils';

import Icon from 'components/icon';

import * as css from './styles.css';

interface CloseButtonProps {
	className?: string;

	onClick: () => void;
}

export default function CloseButton({ className, onClick }: CloseButtonProps) {
	return (
		<div onClick={onClick} className={cssClasses(className, css.button)}>
			<Icon name="close" />
		</div>
	);
}
