import { useT } from '@transifex/react';

import * as models from 'pkg/api/models';

import DataList, {
	DataListItem,
} from 'routes/organization/user-profile/data-list';

import CollapsiblePanel from 'components/sidebar/collapsible-panel';

interface UserDetailsProps {
	user: models.user.User;
}

export default function UserDetails({ user }: UserDetailsProps): JSX.Element {
	const t = useT();

	const items: DataListItem[] = [];

	items.push({ label: t('Email'), value: user.email || '-' });

	if (models.hasAllowedAction(user, models.Action.UserViewFull)) {
		items.push({
			label: t('Gender'),
			value: models.user.translatedSexString(user.sex) || '-',
		});

		items.push({
			label: t('Birth date'),
			value: user.birthDate || '-',
		});

		items.push({ label: t('Phone number'), value: user.phoneNumber || '-' });

		const addr = (
			<div>
				<p>{user.address || '-'}</p>
				<p>
					{user.postalCode} {user.city}
				</p>
				{user.region && <p>{user.region}</p>}
			</div>
		);

		items.push({
			label: t('Address'),
			value: addr,
		});

		items.push({
			label: t('Country'),
			value: models.user.getCountryString(user) || '-',
		});
	}

	return (
		<CollapsiblePanel heading={t('Details')}>
			<DataList items={items} />
		</CollapsiblePanel>
	);
}
