import { Fragment } from 'react';
import styled, { css, DefaultTheme } from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import * as styles from 'pkg/config/styles';
import * as palette from 'pkg/config/palette';
import { size } from 'pkg/config/fonts';

import * as iconStyles from 'components/icon/styles.css';

import * as ContextMenu from 'design/context_menu';

export const Step = styled.div<{ theme?: DefaultTheme }>`
	display: flex;
	justify-content: center;
	align-items: center;

	width: 36px;
	height: 36px;

	border-right: 1px solid ${palette.gray[300]};
	cursor: pointer;

	&:last-child {
		border-right: none;
	}

	.${iconStyles.icon} {
		font-size: ${size['2xl']};
	}

	@media (hover: hover) {
		&:hover {
			color: var(--palette-blue-500);
			background-color: var(--palette-blue-200);
		}
	}

	${({ theme }) =>
		theme.darkMode &&
		css`
			border-right: 1px solid ${palette.gray[600]};

			&:hover {
				color: var(--palette-white);
				background-color: var(--palette-gray-700);
			}
		`}
`;

const HiddenStep = styled(Step)<{ theme?: DefaultTheme }>`
	border-right: 1px solid
		${({ theme }) => (theme.darkMode ? palette.gray[600] : palette.gray[300])} !important;
`;

const NumberStep = styled(Step)<{ isCurrent?: boolean; theme?: DefaultTheme }>`
	${({ isCurrent }) =>
		isCurrent &&
		css`
			font-size: var(--font-size-lg);
			font-weight: var(--font-weight-semibold);
			cursor: default;
			color: var(--palette-blue-500);

			${({ theme }) =>
				theme.darkMode &&
				css`
					color: var(--palette-white);
					background-color: var(--palette-gray-700);
				`}
		`};
`;

interface NumberStepsProps {
	currentPage: number;
	totalPages: number;
	onStep: (pageNumber: number) => void;
}

const NumberSteps: React.FC<React.PropsWithChildren<NumberStepsProps>> = ({
	currentPage,
	totalPages,
	onStep,
}) => {
	const isSmallScreen: boolean = useMediaQuery({
		maxWidth: styles.breakpoint.toMedium,
	});

	if (!totalPages) return null;

	const AMOUNT_OF_SIBLINGS = isSmallScreen ? 1 : 2;

	const numbers: number[] = [...Array(totalPages).keys()].map(
		(index: number) => index + 1
	);

	// leftside [1, 2, ...], current 3, rightside [..., 6, 7]
	const leftSide = numbers.slice(0, currentPage - 1);

	const rightSide = numbers.slice(currentPage, numbers.length);

	const leftHasHidden = leftSide.length - 1 > AMOUNT_OF_SIBLINGS;

	const rightHasHidden = rightSide.length - 1 > AMOUNT_OF_SIBLINGS;

	// [1, 2]
	const visibleLeftSide = leftHasHidden
		? leftSide.slice(0, AMOUNT_OF_SIBLINGS)
		: leftSide;

	// [6, 7]
	const visibleRightSide = rightHasHidden
		? rightSide.slice(rightSide.length - AMOUNT_OF_SIBLINGS, rightSide.length)
		: rightSide;

	const current = leftSide.length + 1;

	// -> [...], current
	const hiddenLeftSide = leftHasHidden
		? leftSide.slice(AMOUNT_OF_SIBLINGS, currentPage)
		: [];

	// current, [...] <-
	const hiddenRightSide = rightHasHidden
		? rightSide.slice(0, rightSide.length - AMOUNT_OF_SIBLINGS)
		: [];

	// extraVisible will show when there's less then "AMOUNT_OF_SIBLINGS" on the opposit side.
	const extraRightVisible = leftHasHidden
		? []
		: hiddenRightSide.splice(0, AMOUNT_OF_SIBLINGS - visibleLeftSide.length);

	const extraLeftVisible = rightHasHidden
		? []
		: hiddenLeftSide.splice(
				hiddenLeftSide.length - (AMOUNT_OF_SIBLINGS - visibleRightSide.length),
				hiddenLeftSide.length
			);

	return (
		<Fragment>
			{visibleLeftSide.map((pageNumber: number) => (
				<NumberStep key={pageNumber} onClick={() => onStep(pageNumber)}>
					{pageNumber}
				</NumberStep>
			))}
			{hiddenLeftSide.length > 0 && (
				<ContextMenu.Menu toggleWith={<HiddenStep>...</HiddenStep>}>
					{hiddenLeftSide.reverse().map((pageNumber: number) => (
						<ContextMenu.Item
							key={pageNumber}
							onClick={() => onStep(pageNumber)}>
							{pageNumber}
						</ContextMenu.Item>
					))}
				</ContextMenu.Menu>
			)}
			{extraLeftVisible.map((pageNumber: number) => (
				<NumberStep key={pageNumber} onClick={() => onStep(pageNumber)}>
					{pageNumber}
				</NumberStep>
			))}
			<NumberStep key={current} isCurrent>
				{current}
			</NumberStep>
			{extraRightVisible.map((pageNumber: number) => (
				<NumberStep key={pageNumber} onClick={() => onStep(pageNumber)}>
					{pageNumber}
				</NumberStep>
			))}
			{hiddenRightSide.length > 0 && (
				<ContextMenu.Menu toggleWith={<HiddenStep>...</HiddenStep>}>
					{hiddenRightSide.reverse().map((pageNumber: number) => (
						<ContextMenu.Item
							key={pageNumber}
							onClick={() => onStep(pageNumber)}>
							{pageNumber}
						</ContextMenu.Item>
					))}
				</ContextMenu.Menu>
			)}
			{visibleRightSide.map((pageNumber: number) => (
				<NumberStep key={pageNumber} onClick={() => onStep(pageNumber)}>
					{pageNumber}
				</NumberStep>
			))}
		</Fragment>
	);
};

export default NumberSteps;
