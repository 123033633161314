export function Create(): string {
	return `/v1/schedule-items`;
}

export function Delete(ID: number): string {
	return `/v1/schedule-items/${ID}`;
}

export function Update(ID: number): string {
	return `/v1/schedule-items/${ID}`;
}
