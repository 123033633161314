import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';

import Notification from 'pkg/models/notification';

import * as services from 'pkg/actions/services';
import { triggerError } from 'pkg/actions/app';
import { normalizedDispatch } from 'pkg/actions/utils';
import * as actionTypes from 'pkg/actions/action-types';

import * as sdk from 'pkg/core/sdk';
import asyncLocalStorage from 'pkg/asyncLocalStorage';
import * as routes from 'pkg/router/routes';
import { pushState } from 'pkg/router/state';
import store from 'pkg/store/createStore';

const hasPushNotifications = Capacitor.isPluginAvailable('PushNotifications');

const hasRegisteredPushNotifications: boolean = false;

const queuedPushNotificationActions: CallableFunction[] = [];

function callPushNotificationsAction(callable: CallableFunction) {
	if (!hasPushNotifications) return;

	if (hasRegisteredPushNotifications) {
		callable();
	} else {
		queuedPushNotificationActions.push(callable);
	}
}

export function setHasUnread(): void {
	store.dispatch({
		type: actionTypes.Notifications.SET_HAS_UNREAD,
		hasUnread: true,
	});
}

export async function fetchNotifications(accountId: number): Promise<void | {
	links: { [key: string]: string };
	records: Notification[];
}> {
	const queryParams = {
		'only-meta': 1,
	};

	const request = await services.notification.fetchNotifications(
		accountId,
		queryParams
	);

	const response = await request.json();

	if (!request.ok) {
		triggerError(request, response)(store.dispatch);
		return;
	}

	if (response.meta?.unreadNotificationsCount > 0) {
		setHasUnread();
	}

	return response;
}

export interface PushNotificationMetaData {
	'resource-type'?: NotificationTargetTypeValue;
	'resource-id'?: string;
	'notification-id'?: string;
	'trigger-id'?: string;
	'organization-id'?: string;
	foreground?: boolean;
}

export async function markAllAsRead(accountId: number): Promise<void> {
	const request = await services.notification.markAllAsRead(accountId);

	if (request.ok) {
		const notifications = store
			.getState()
			.notifications.entities.filter((n: Notification) => !n.isRead)
			.toList()
			.map((record: Notification) => {
				record = record.set('isRead', true);

				return record;
			});

		normalizedDispatch(notifications.toJS(), [Notification.normalizr()])(
			store.dispatch
		);

		store.dispatch({
			type: actionTypes.Notifications.SET_HAS_UNREAD,
			hasUnread: false,
		});

		callPushNotificationsAction(() =>
			PushNotifications.removeAllDeliveredNotifications()
		);
	}
}

export async function markAsRead(notificationId: number): Promise<void> {
	const request = await services.notification.markAsRead(notificationId);

	if (request.ok) {
		const data = await request.json();

		normalizedDispatch(data, Notification.normalizr())(store.dispatch);

		callPushNotificationsAction(() =>
			PushNotifications.removeDeliveredNotifications({
				notifications: [data],
			})
		);
	}
}

export async function deregisterPush(): Promise<void> {
	if (!hasPushNotifications) {
		return;
	}

	PushNotifications.unregister();

	const pnsID = await asyncLocalStorage.getItem('pnsID');
	if (pnsID === null) {
		return;
	}

	asyncLocalStorage.removeItem('pnsID');

	await sdk.destroy('/me/pns/:id', {
		id: pnsID,
	});
}

/**
 *	Theese match unique targets from AccountNotificationTargetTypes in api/pkg/models/account_notification.go
 */
export enum NotificationTargetType {
	AccountInvite = 'accountInvite',
	ChildSettings = 'childSettings',
	ParentSettings = 'parentSettings',
	GroupHome = 'groupHome',
	UserActivity = 'userActivity',
	UserBillingInvoice = 'userBillingInvoice',
	Booking = 'booking',
	Event = 'event',
	Calendar = 'calendar',
	EventSeries = 'eventSeries',
	EventSession = 'eventSession',
	GroupPost = 'groupPost',
	Video = 'video',
	GroupList = 'groupList',
	ScheduleTemplate = 'scheduleTemplate',
	Chat = 'chat',
	ChatMessage = 'chatMessage',
	GroupImport = 'groupImport',
}

export type NotificationTargetTypeValue = `${NotificationTargetType}`;

interface NotifcationUrlMap {
	[key: string]: string;
}

export function getUrlByType(
	type: NotificationTargetTypeValue,
	targetId: number,
	organizationId: number,
	triggerId?: number
): string {
	const notificationUrlMap: NotifcationUrlMap = {
		accountInvite: routes.Invite.Show(targetId),
		booking: routes.Calendar.Index(organizationId, targetId, 'bookings'),
		calendar: routes.Calendar.Index(organizationId, targetId, ''),
		chat: routes.Chat.Show(organizationId, targetId),
		chatMessage: routes.Chat.Show(organizationId, targetId),
		childSettings: routes.Account.Settings.Show('children'),
		event: routes.Event.View(organizationId, targetId, 'overview'),
		eventSeries: routes.EventSeries.View(organizationId, targetId),
		eventSession: routes.Event.View(organizationId, targetId, 'sessions'),
		groupEdit: routes.Settings.Index(organizationId, targetId, 'general'),
		groupList: routes.Group.Show(organizationId, targetId),
		groupHome: routes.Organization.Home(organizationId),
		groupImport: routes.Settings.Index(organizationId, targetId, 'import'),
		groupPost: routes.Group.Post.Show(organizationId, targetId),
		parentSettings: routes.Account.Settings.Show('parents'),
		scheduleTemplate: routes.Templates.Show(
			organizationId,
			targetId,
			triggerId
		),
		userActivity: routes.User.Activity.Show(organizationId, targetId),
		userBillingInvoice: routes.Self.Invoice(organizationId, targetId),
		video: routes.Video.Show(organizationId, targetId),
	};

	return notificationUrlMap[type] || routes.Organization.Home(organizationId);
}

interface ParsedNotificationData {
	type: NotificationTargetTypeValue;
	targetId: number;
	triggerId: number;
	notificationId: number;
	organizationId: number;
	targetUrl: string;
}

export function parseNotificatioAdditionalData(
	data: PushNotificationMetaData
): ParsedNotificationData {
	const intval = (key: keyof PushNotificationMetaData): number => {
		return Number.parseInt((data?.[key] as string) ?? '0', 10);
	};

	const type = data['resource-type'];
	const targetId = intval('resource-id');
	const triggerId = intval('trigger-id');
	const notificationId = intval('notification-id');
	const organizationId = intval('organization-id');
	const targetUrl = getUrlByType(type, targetId, organizationId, triggerId);

	return {
		type,
		targetId,
		triggerId,
		notificationId,
		organizationId,
		targetUrl,
	};
}

export function navigatePushNotification(data: PushNotificationMetaData) {
	const { notificationId, targetUrl } = parseNotificatioAdditionalData(data);

	if (notificationId) {
		markAsRead(notificationId);
	}

	pushState(targetUrl);
}
