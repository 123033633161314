import { ReactNode } from 'react';

export const symbol_room_preferences: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M694.091-92.627 690-128q-20-6-41-17.5T615-171l-30 14.118q-5 1.882-10.5.882-5.5-1-8.875-5.755l-20.25-32.49Q542-199 543-204q1-5 6-9l27-22q-5-23-5-41.5t5-41.5l-27-22q-5-4-6-9t2.375-9.868l20.25-33.264Q569-397 574.5-398t10.5.882L615-383q13-12 34-24t41-18l4.091-36.235Q695-467 699-470.5q4-3.5 9.625-3.5h33.75q5.625 0 9.625 3.5t4.909 9.265L761-425q20 6 41 18t34 24l30-14.118q5-1.882 10.5-.882 5.5 1 8.875 5.868l20.25 33.264Q909-354 908-349t-6 9l-27 22q5 23 5 41.5t-5 41.5l27 22q5 4 6 9t-2.375 9.755l-20.25 32.49Q882-157 876.5-156t-10.4-1L836-171q-13 14-34 25.5T761-128l-4.091 35.373Q756-87 752-83.5q-4 3.5-9.625 3.5h-33.75Q703-80 699-83.5t-4.909-9.127ZM725-175q44 0 73-29t29-73q0-44-29-73t-73-29q-44 0-73 29t-29 73q0 44 29 73t73 29ZM451-440q-17 0-28.5-11.5T411-480q0-17 11.5-28.5T451-520q17 0 28.5 11.5T491-480q0 17-11.5 28.5T451-440ZM150-120q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-180h62v-595q0-29 17.625-47T272-840h270q21.017 0 37.009 12.5Q595-815 600-795h89q24.75 0 42.375 17.625T749-735v171q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T689-564v-171h-87v215q0 14-9.318 22t-20.5 8Q561-490 551.5-497.5 542-505 542-520v-260H272v600h196q14 0 22 9.318t8 20.5q0 11.182-7.308 20.682Q483.385-120 468-120H150Zm122-60v-600 600Z"
		/>
	</svg>
);
