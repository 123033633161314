import { t } from '@transifex/native';

import FormRow from 'components/form/Row';
import Hint from 'components/form/Hint';
import Label from 'components/form/Label';
import TextField from 'components/form/TextInput';

const Title = ({ dispatch, data }) => {
	const handleTitleChange = (e) => {
		dispatch({
			type: 'set_value',
			payload: { key: 'title', value: e.target.value },
		});
	};

	return (
		<FormRow>
			<Label>{t(`Title`)}</Label>
			<TextField
				name="title"
				placeholder={t(`Write your title here`)}
				onChange={handleTitleChange}
				value={data.title}
			/>
			<Hint>{t(`Post titles are optional`)}</Hint>
		</FormRow>
	);
};

export default Title;
