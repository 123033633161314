import { Fragment, ReactNode, useState } from 'react';

import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCollection } from 'pkg/api/use_collection';
import { useQueryState } from 'pkg/hooks/query-state';

import useInvoiceFilters from 'routes/payments/orders/filters';

import Pagination from 'components/pagination';

import OrdersActionBar from 'components/payment_platform/payments/orders_action_bar';
import OrderTable from 'components/orders/table';

interface OrderListProps {
	organizationId: number;
	userId?: number;
	subscriptionId?: number;
	productId?: number;
	disableNewInvoice?: boolean;
	hideNewInvoice?: boolean;
}

export default function OrderList({
	organizationId,
	userId,
	subscriptionId,
	productId,
	disableNewInvoice,
	hideNewInvoice,
}: OrderListProps) {
	const qs = useQueryState();
	const search = (qs.get('search') as string) || '';

	const filters = useInvoiceFilters();

	const [showOnlySelected, setShowOnlySelected] = useState<boolean>(false);

	filters.queryParam.set('group_id', (organizationId || 0).toString());

	if (userId) {
		filters.queryParam.set('associated_user_id', userId.toString());
	}

	if (subscriptionId) {
		filters.queryParam.set('subscription_id', subscriptionId.toString());
	}

	if (productId) {
		filters.queryParam.set('product_id', productId.toString());
	}

	filters.queryParam.set('search', search);

	const SelectProductsModal: ReactNode = null;

	const orderCollection = useCollection<models.order.Order>(
		endpoints.Orders.Index(),
		{
			showOnlySelected,
			queryParams: filters.queryParam,
			useLocationPagination: true,
		}
	);

	const handleSelectAll = () => {
		orderCollection.selection.selectAll();

		if (showOnlySelected) {
			setShowOnlySelected(false);
		}
	};

	const handleUpdate = (records: models.order.Order[]) =>
		orderCollection.replaceMultipleRecords(records);

	const handleRefresh = () => orderCollection.refresh();

	return (
		<Fragment>
			<OrdersActionBar
				orders={orderCollection.records}
				selectedItems={orderCollection.selection.selectedRecords as number[]}
				selectedRecords={orderCollection.selectedRecords}
				showSelected={showOnlySelected}
				setShowSelected={setShowOnlySelected}
				filters={filters}
				disableNewInvoice={disableNewInvoice}
				hideNewInvoice={hideNewInvoice}
				filterParams={filters.queryParam}
				search={search}
				onUpdateRecords={handleUpdate}
				refresh={handleRefresh}
			/>

			<OrderTable
				sort={orderCollection.sort}
				isAllSelected={orderCollection.selection.isAllSelected}
				handleSelectAll={handleSelectAll}
				isLoading={orderCollection.isLoading}
				orders={orderCollection.records}
				isSelected={orderCollection.selection.isSelected}
				select={orderCollection.selection.selectSingle}
				replaceRecord={orderCollection.replaceRecord}
				removeRecord={orderCollection.removeRecord}
			/>

			{!showOnlySelected && <Pagination {...orderCollection.pagination} />}

			{SelectProductsModal}
		</Fragment>
	);
}
