import { ReactNode } from 'react';

export const symbol_lab_research: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M350-300q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T350-360h80q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T430-300h-80ZM320-80q-83 0-141.5-58.5T120-280v-360q-33 0-56.5-23.5T40-720v-80q0-33 23.5-56.5T120-880h400q33 0 56.5 23.5T600-800v80q0 33-23.5 56.5T520-640v120q0 24.75-17.625 42.375T460-460H350q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T350-520h110v-120H180v360q0 58.333 40.833 99.167Q261.667-140 320-140q28 0 52-10t43-27q8-8 19.5-5.5T453-170q8 11 4.5 25T443-122q-26 20-56.919 31Q355.163-80 320-80ZM100-700h440v-120H100v120Zm569.859 520Q716-180 748-211.859q32-31.859 32-78T748.141-368q-31.859-32-78-32T592-368.141q-32 31.859-32 78T591.859-212q31.859 32 78 32Zm.141 60q-70.833 0-120.417-49.618Q500-219.235 500-290.118 500-361 549.618-410.5q49.617-49.5 120.5-49.5Q741-460 790.5-410.417 840-360.833 840-290q0 26.351-8 50.676Q824-215 809-193l86 86q9 9 9 21t-9 21q-9 9-21 9t-21-9l-86-86q-22 15-46.324 23-24.325 8-50.676 8ZM100-700v-120 120Z"
		/>
	</svg>
);
