import { Map, Record } from 'immutable';

import UserProduct from 'pkg/models/user_product';

import * as actionTypes from 'pkg/actions/action-types';

interface IUserProductReducer {
	entities: Map<number, UserProduct>;
}

class UserProductReducer
	extends Record<IUserProductReducer>(
		{
			entities: Map<number, UserProduct>(),
		},
		'userProductReducer'
	)
	implements IUserProductReducer
{
	entities: Map<number, UserProduct>;
}

const initialState = new UserProductReducer({
	entities: Map<number, UserProduct>(),
});

interface NormalizrPayload {
	result: number[];
	entities: {
		userProduct: { [key: string]: UserProduct };
	};
	userProductId?: number;
}

interface Action {
	type: string;
	payload?: NormalizrPayload;
}

const setItems = (state = initialState, action: Action): UserProductReducer => {
	const entities = Object.values(action.payload.entities.userProduct);

	entities.map((entity: UserProduct) => {
		state = state.setIn(['entities', entity.id], new UserProduct(entity));
	});

	return state;
};

const removeItem = (
	state = initialState,
	action: Action
): UserProductReducer => {
	return state.updateIn(['entities'], (entities) => {
		return (entities as Map<number, UserProduct>).filter(
			(item: UserProduct) => item.get('id') !== action.payload.userProductId
		);
	});
};

const userProductReducer = (
	state = initialState,
	action: Action
): UserProductReducer => {
	switch (action.type) {
		case actionTypes.UserProducts.SET_ITEMS:
			return setItems(state, action);
		case actionTypes.UserProducts.DELETE_ITEM:
			return removeItem(state, action);
		default:
			return state;
	}
};

export default userProductReducer;
