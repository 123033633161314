import { ReactNode } from 'react';

export const symbol_gesture: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M561-120q-52 0-86-36t-34-93q0-76 66-133.5T669-448q-3-55-23.5-81.5T584-556q-42 0-76 26t-88 100q-60 82-99.5 112.5T234-287q-53 0-83.5-34.5T120-416q0-32 23-83t77-140q26-39 37.5-64t11.5-42q0-11-7.5-17.5T240-769q-11 0-22.5 5T193-748q-11 9-25 9.5T144-749q-11-11-10-26t12-25q23-20 46-30t47-10q43 0 72 28t29 70q0 30-14.5 61T263-575q-45 69-58.5 100.5T191-411q0 22 15 37.5t36 15.5q14 0 27.5-7t29.5-24q13-14 29.5-34t39.5-50q66-86 115.5-120T593-627q63 0 102.5 47.5T740-451h65q15 0 25 10.5t10 25.5q0 15-10 25t-25 10h-65q-8 120-56 190t-123 70Zm3-71q43 0 73-52t34-135q-66 7-112.5 47T512-242q0 24 13.5 37.5T564-191Z"
		/>
	</svg>
);
