import { ReactNode } from 'react';

export const symbol_backlight_high_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M634-240H330q-20.833 0-35.417-14.618Q280-269.235 280-290.118 280-311 294.583-325.5 309.167-340 330-340h204L82-792q-9-9.067-9-21.533Q73-826 82.053-835q9.052-9 21.5-9Q116-844 125-835l710 710q9 9 9 21t-9.053 21q-9.052 9-21.5 9Q801-74 792-83L634-240ZM70-370q-12.75 0-21.375-8.675Q40-387.351 40-400.175 40-413 48.625-421.5T70-430h90q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T160-370H70Zm409.825-310Q467-680 458.5-688.625T450-710v-140q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-850v140q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM677-598q-9-9-9-21t9-21l73-73q9-9 21-8.5t21 9.109Q801-703 801-691q0 12-9 21l-73 73q-9 9-21 8.5t-21-9.5Zm123 228q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T800-430h90q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T890-370h-90Z"
		/>
	</svg>
);
