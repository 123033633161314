import { ReactNode } from 'react';

export const symbol_digital_out_of_home: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M60-130v60q0 12.75-8.675 21.375Q42.649-40 29.825-40 17-40 8.5-48.625T0-70v-790q0-24.75 17.625-42.375T60-920h300q24.75 0 42.375 17.625T420-860v790q0 12.75-8.675 21.375Q402.649-40 389.825-40 377-40 368.5-48.625T360-70v-60H60Zm713-208-108 98v170q0 12.75-8.675 21.375Q647.649-40 634.825-40 622-40 613.5-48.625T605-70v-223l92-83-20-137q-38 53-80 74t-89 26q-11 2-19.5-7t-8.5-21q0-12 8.289-20.889Q496.579-470.778 510-473q43-6 73-28.5t53-58.5l39-62q15-23 41.5-31.5T768-651l174 73q8.25 3.889 13.125 11.278Q960-559.333 960-550v123q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Q917-397 908-405.913q-9-8.912-9-22.087v-102l-102-46L906-76q3 14-6 25t-24.882 11Q864-40 856.08-46.188 848.16-52.374 846-62l-73-276ZM60-190h300v-670H60v670Zm117-272 80-45q8-4.5 8-12.75T257-533l-80-45q-8-5-15-.304-7 4.695-7 13.304v90q0 8.609 7 13.304 7 4.696 15-.304Zm505.08-222q-30.08 0-51.58-21.42-21.5-21.421-21.5-51.5 0-30.08 21.42-51.58 21.421-21.5 51.5-21.5 30.08 0 51.58 21.42 21.5 21.421 21.5 51.5 0 30.08-21.42 51.58-21.421 21.5-51.5 21.5ZM60-190h300H60Z"
		/>
	</svg>
);
