import { Record } from 'immutable';
import { schema } from 'normalizr';

import User from 'pkg/models/user';
import Account from 'pkg/models/account';
import Sequence from 'pkg/models/video_sequence';

import { getYouTubeThumbnailUrl } from 'pkg/video';
import * as models from 'pkg/api/models';

import {
	isValidVideoSource,
	isValidYouTubeSource,
} from 'components/video-analytics/source/SourceUrl';

export const video = new schema.Entity('videos', {
	user: User.normalizr(),
	account: Account.normalizr(),
	sequences: [Sequence.normalizr()],
});
export interface VideoSchema {
	id: number;
	private: boolean;
	userId: number;
	accountId: number;
	account?: Account;
	groupId: number;
	thumbnailAttachmentId: number;
	thumbnail: models.attachment.Attachment;
	uri: string;
	duration: number;
	title: string;
	type: string;
	description: string;
	sequences: Sequence[];
	createdAt: number;
	updatedAt?: number;
	links?: {
		delete?: string;
		edit?: string;
	};
}

// @TODO Remove this and use actual responses when converting models to TS.
type GreedyResponseType = { [key: string]: any };

export interface VideoResponse {
	id: number;
	private: boolean;
	userId: number;
	accountId: number;
	groupId: number;
	thumbnailAttachmentId: number;
	thumbnail: models.attachment.Attachment;
	uri: string;
	duration: number;
	title: string;
	type: string;
	description: string;
	sequences: GreedyResponseType;
	createdAt: number;
	updatedAt?: number;
	links?: {
		delete?: string;
		edit?: string;
		'create:video_sequence'?: string;
	};
}

const videoProps: VideoSchema = {
	id: 0,
	private: false,
	userId: 0,
	accountId: 0,
	account: new Account(),
	groupId: 0,
	thumbnailAttachmentId: 0,
	thumbnail: null,
	uri: '',
	updatedAt: 0,
	duration: 0,
	title: null,
	type: null,
	createdAt: 0,
	description: '',
	links: {},
	sequences: [],
};

export class Video
	extends Record(videoProps, 'VideoRecord')
	implements VideoSchema
{
	static normalizr(): schema.Entity {
		return video;
	}

	get thumbnailUrl(): string {
		if (this.get('thumbnail') !== null) {
			return this.getIn(['thumbnail', 'url']) as string;
		}

		if (this.type === 'youtube') {
			return getYouTubeThumbnailUrl(this.uri);
		}

		return null;
	}

	asPayload(): GreedyResponseType {
		const {
			description,
			duration,
			groupId,
			id,
			private: isPrivate,
			thumbnailAttachmentId,
			title,
			type,
			uri,
		} = this.toJS();

		return {
			description,
			duration,
			groupId,
			id,
			private: isPrivate,
			thumbnailAttachmentId,
			title,
			type,
			uri,
		};
	}

	valid(): boolean {
		return !!this.get('id');
	}

	validSource(): boolean {
		if (this.type === 'youtube') {
			return isValidYouTubeSource(this.uri);
		} else {
			return isValidVideoSource(this.uri);
		}
	}
}

export default Video;
