import styled from 'styled-components';

import * as palette from 'pkg/config/palette';

const TextBlock = styled.div`
	color: ${palette.gray[800]};
	text-align: center;
	max-width: 90%;
	margin: 0 auto;
	margin-bottom: var(--spacing-8);
	line-height: var(--font-line-height-base);

	h3 {
		font-size: var(--font-size-xl);
			font-weight: var(--font-weight-semibold);
			margin-bottom: var(--spacing-4);
		}
	}
`;

export default TextBlock;
