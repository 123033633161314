import { t } from '@transifex/native';
import {
	Fragment,
	FunctionComponent,
	useState,
	useEffect,
	MouseEvent,
} from 'react';
import styled from 'styled-components';
import { List } from 'immutable';

import * as styles from 'pkg/config/styles';
import { PageWidths } from 'pkg/config/sizes';

import Tag from 'pkg/models/tag';

import * as models from 'pkg/api/models';
import { Attachment, destroy } from 'pkg/api/models/attachment';
import uuid from 'pkg/uuid';
import { useCurrentSport } from 'pkg/identity';
import * as actions from 'pkg/actions';

import CollectionAddItem from 'containers/training/collection/AddItem';
import TagForm from 'containers/tag/TagForm';

import {
	FinePointerDevice,
	LargeScreen,
	TouchDevice,
	useSmallScreen,
} from 'components/MediaQuery';
import Icon from 'components/icon';

import AttachmentRow from 'components/attachment/FormRow';
import FormAttachment from 'components/attachment/FormItem';
import Section from 'components/form/Section';
import Row from 'components/layout/row';
import { FormActions } from 'components/form/FormActions';
import * as Input from 'components/form/inputs';
import Column from 'components/layout/column';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import { ColumnDivider } from 'components/form/Divider';
import FileInput from 'components/form/File';
import Drawing from 'components/drawing/Drawing';
import { drawingEnabled } from 'components/drawing/config/sport';

import Button from 'design/button';

const IconDivider = styled(Icon)`
	transform: translate(0%, 80%) scale(1.4);
`;

const Thumbnail = styled.img`
	width: 100%;
`;

interface ExerciseFormProps {
	exercise?: models.exercise.Exercise;
	toggleCollectionModal?: (id?: number) => void;
	collectionModalExerciseId?: number;
	ActionBarActions: FunctionComponent;
	onTagUpdate: (tags: List<Tag>) => void;
	newAttachments: Attachment[];
	setNewAttachments: (attachments: Attachment[]) => void;
	deletedAttachments?: number[];
	setDeletedAttachments?: (attachments: number[]) => void;
	drawings: any;
	setDrawings: (drawings: any) => void;
	deletedDrawings?: number[];
	deleteExistingDrawing?: (id: number) => void;
	newThumbnail: Attachment;
	setThumbnail: (thumbnail: Attachment) => void;
}

export default function ExerciseForm({
	exercise,
	toggleCollectionModal,
	collectionModalExerciseId,
	ActionBarActions,
	onTagUpdate,
	newAttachments,
	setNewAttachments,
	deletedAttachments,
	setDeletedAttachments,
	drawings,
	setDrawings,
	deletedDrawings,
	deleteExistingDrawing,
	newThumbnail,
	setThumbnail,
}: ExerciseFormProps) {
	const _context = 'exercises';
	const sport = useCurrentSport();
	const currentThumbnail =
		newThumbnail === undefined ? null : newThumbnail || exercise?.thumbnail;

	const addDrawing = () => {
		const id = 'tempId-' + uuid();
		setDrawings({ ...drawings, [id]: { sport: sport.slug } });
	};

	const updateDrawing = async (id: number, updates: any) => {
		const thumbnailDataURL = updates.thumbnail;
		delete updates.thumbnail;
		const updatedDrawings = { ...drawings };
		updatedDrawings[id] = { ...updatedDrawings[id], ...updates };
		setDrawings(updatedDrawings);

		// set as thumbnail if there is no thumbnail or update if the current drawing is set as the thumbnail
		if (!currentThumbnail || currentThumbnail.title === `drawing-${id}.png`) {
			const blob = await (await fetch(thumbnailDataURL)).blob();
			const file = new File([blob], `drawing-${id}.png`, {
				type: 'image/png',
			});

			const attachment = await actions.attachments.upload({
				type: models.attachment.AttachmentType.Image,
				visibility: models.attachment.AttachmentVisibility.Hidden,
				originalFilename: file.name,
				title: file.name,
				file: file,
			});
			setThumbnail(attachment);
		}
	};

	const openCollectionModal = () => {
		toggleCollectionModal(exercise.id);
	};

	const updateNewAttachment = (attachment: Attachment) => {
		const index = newAttachments.findIndex(
			(a: Attachment) => a.id === attachment.id
		);

		if (index >= 0) {
			newAttachments[index] = attachment;
		} else {
			newAttachments.push(attachment);
		}

		setNewAttachments([...newAttachments]);
	};

	const createAttachment = async (file: File): Promise<Attachment> => {
		const fileType = file.type.startsWith('image/')
			? models.attachment.AttachmentType.Image
			: models.attachment.AttachmentType.File;

		const attachment = await actions.attachments.upload(
			{
				type: fileType,
				visibility: models.attachment.AttachmentVisibility.Hidden,
				originalFilename: file.name,
				title: file.name,
				file,
			},
			{
				onProgress: (uploaded, total, attachment) => {
					updateNewAttachment({ ...attachment, bytesUploaded: uploaded });
				},
			}
		);

		// set as thumbnail if there is no thumbnail
		if (!currentThumbnail && file.type.startsWith('image/')) {
			setThumbnail(attachment);
		}

		return attachment;
	};

	const triggerFileInput = (e: MouseEvent<HTMLElement>) => {
		// trigger click on label parent
		const button = e.target as HTMLButtonElement;
		button.parentElement.click();
	};

	const handleUpload = async (files: FileList) => {
		const attachment = await createAttachment(files[0]);
		updateNewAttachment(attachment);
	};

	const [isUploading, setIsUploading] = useState(false);
	const uploadThumbnail = async (files: FileList): Promise<Attachment> => {
		const file = files[0];
		setIsUploading(true);
		const attachment = await actions.attachments.upload({
			type: models.attachment.AttachmentType.Image,
			visibility: models.attachment.AttachmentVisibility.Hidden,
			originalFilename: file.name,
			title: file.name,
			file,
		});

		setThumbnail(attachment);
		setIsUploading(false);

		return attachment;
	};

	const isSmallScreen = useSmallScreen();

	const tagList = List(exercise?.tags || []).map((tag: Tag) => new Tag(tag));

	return (
		<LargeScreenContent.Inner maxWidth={PageWidths.WIDE}>
			<Column spacing={styles.spacing._8}>
				<Row
					collapseOnSmallScreens
					columns="1fr 1px 0.6fr"
					spacing={isSmallScreen ? styles.spacing._1 : styles.spacing._9}>
					<Column spacing={styles.spacing._1}>
						<Section
							title={t('Exercise information', { _context })}
							icon="info">
							<Column spacing={styles.spacing._6}>
								<Input.Group required label={t('Title')}>
									<Input.Field
										name="title"
										defaultValue={exercise?.title}
										required
										placeholder={t('E.g rondo', { _context })}
									/>
								</Input.Group>
								<Input.Group label={t('Introduction', { _context })}>
									<Input.Area
										name="introduction"
										defaultValue={exercise?.introduction}
										minRows={2}
										placeholder={t('A short introduction of the exercise', {
											_context,
										})}
									/>
								</Input.Group>
								<Input.Group label={t('Description', { _context })}>
									<Input.Area
										name="description"
										defaultValue={exercise?.description}
										minRows={8}
										placeholder={t('A description of the exercise', {
											_context,
										})}
									/>
								</Input.Group>
								<Input.Group label={t('Key coaching points', { _context })}>
									<Input.Area
										name="keyPoints"
										defaultValue={exercise?.keyPoints}
										minRows={2}
										placeholder={t('Key coaching points', { _context })}
									/>
								</Input.Group>
								<Input.Group label={t('Tags', { _context })}>
									<ExerciseTags onChange={onTagUpdate} tagList={tagList} />
								</Input.Group>
							</Column>
						</Section>
						{drawingEnabled.includes(sport.slug) && (
							<Section
								icon="stylus_note"
								isBeta
								title={t('Drawings', { _context: 'drawing' })}
								description={t('Create one or multiple drawings', {
									_context: 'drawing',
								})}>
								<Column spacing={styles.spacing._3}>
									<Fragment>
										{Object.entries(drawings)
											.filter(
												([id]: any) =>
													!deletedDrawings || !deletedDrawings.includes(id)
											)
											.map(([id, drawing]: any) => (
												<Drawing
													key={id}
													drawingId={id}
													drawing={drawing}
													drawings={drawings}
													setDrawings={setDrawings}
													updateDrawing={updateDrawing}
													deleteExistingDrawing={deleteExistingDrawing}
													isCurrentThumbnail={
														currentThumbnail?.title === `drawing-${id}.png`
													}
													setThumbnail={setThumbnail}
													isEditable={true}
												/>
											))}
										<FinePointerDevice>
											<Button secondary icon="draw" onClick={addDrawing}>
												{t('Create drawing', { _context: 'drawing' })}
											</Button>
										</FinePointerDevice>
										<TouchDevice>
											<p>
												{t(
													'Visit 360Player on desktop to create or edit your drawings.',
													{ _context: 'drawing' }
												)}
											</p>
										</TouchDevice>
									</Fragment>
								</Column>
							</Section>
						)}
						<Section
							icon="attachment"
							title={t('Attachments')}
							description={t('Upload a file or image', { _context })}
							hideDivider={isSmallScreen ? false : !exercise?.id}>
							<Column spacing={styles.spacing._3}>
								{!!(
									exercise?.attachments?.length || newAttachments?.length
								) && (
									<AttachmentRow>
										{exercise?.attachments
											?.filter(
												(attachment) =>
													!deletedAttachments.find((id) => id === attachment.id)
											)
											.map((attachment) => (
												<ExerciseAttachment
													key={attachment.id}
													attachment={attachment}
													deletedAttachments={deletedAttachments}
													setDeletedAttachments={setDeletedAttachments}
												/>
											))}
										{newAttachments?.map((attachment) => (
											<ExerciseAttachment
												isNew
												key={attachment.id}
												attachment={attachment}
												newAttachments={newAttachments}
												setNewAttachments={setNewAttachments}
											/>
										))}
									</AttachmentRow>
								)}
								<FileInput allowDrop onChange={handleUpload}>
									<Button
										block
										secondary
										icon="attachment"
										onClick={triggerFileInput}>
										{t('Add file', { _context })}
									</Button>
								</FileInput>
							</Column>
						</Section>
						{exercise?.id && (
							<Section
								icon="folder"
								title={t('Add to collection', { _context })}
								description={t(
									'Add to existing collection or create a new collection',
									{ _context }
								)}>
								<Button
									block
									secondary
									icon="add"
									onClick={openCollectionModal}>
									{t('Add to collection', { _context })}
								</Button>
							</Section>
						)}
						{!!collectionModalExerciseId && (
							<CollectionAddItem
								onClose={toggleCollectionModal}
								type="exercises"
								id={collectionModalExerciseId}
							/>
						)}
					</Column>
					<LargeScreen>
						<ColumnDivider />
					</LargeScreen>

					<Column>
						<Section
							title={t('Thumbnail')}
							icon="image"
							description={t('Upload an image or file')}>
							<Column spacing={styles.spacing._6}>
								{currentThumbnail && <Thumbnail src={currentThumbnail?.url} />}
								<FileInput
									accept="image/jpg, image/jpeg, image/png"
									allowDrop
									multiple={false}
									onChange={uploadThumbnail}
									disabled={isUploading}>
									<Button
										secondary
										block
										icon="upload"
										disabled={isUploading}
										onClick={triggerFileInput}>
										{isUploading
											? t('Uploading…', { _context: 'drawing' })
											: currentThumbnail
												? t('Replace thumbnail', { _context: 'drawing' })
												: t('Add thumbnail', { _context: 'drawing' })}
									</Button>
								</FileInput>
							</Column>
						</Section>

						<Section
							title={t('Additional information', { _context })}
							icon="notes"
							hideDivider>
							<Column spacing={styles.spacing._6}>
								<Input.Group label={t('Duration', { _context })}>
									<Input.Field
										name="minTime"
										defaultValue={exercise?.minTime}
										type="number"
										placeholder="0">
										<Input.Prefix>{t('Minutes', { _context })}</Input.Prefix>
									</Input.Field>
								</Input.Group>
								<Input.Group label={t('Min players', { _context })}>
									<Input.Field
										name="minParticipants"
										defaultValue={exercise?.minParticipants}
										type="number"
										placeholder="0"
									/>
								</Input.Group>
								<Input.Group label={t('Materials', { _context })}>
									<Input.Field
										name="materialRequirements"
										defaultValue={exercise?.materialRequirements}
										placeholder={t('E.g 3 balls, 24 cones', { _context })}
									/>
								</Input.Group>
								<Input.Group label={t('Age span', { _context })}>
									<Row columns="1fr 0.1fr 1fr">
										<Input.Field
											name="minAge"
											defaultValue={exercise?.minAge}
											type="number">
											<Input.Prefix>{t('Min', { _context })}</Input.Prefix>
										</Input.Field>
										<IconDivider name="arrow-right" />
										<Input.Field
											name="maxAge"
											defaultValue={exercise?.maxAge}
											type="number">
											<Input.Prefix>{t('Max', { _context })}</Input.Prefix>
										</Input.Field>
									</Row>
								</Input.Group>
								<Input.Group label={t('Area', { _context })}>
									<Row columns="1fr 0.1fr 1fr">
										<Input.Field
											name="areaWidth"
											defaultValue={exercise?.areaWidth}
											type="number">
											<Input.Prefix>{t('Width')}</Input.Prefix>
										</Input.Field>
										<IconDivider name="close" />

										<Input.Field
											name="areaLength"
											defaultValue={exercise?.areaLength}
											type="number">
											<Input.Prefix>{t('Length')}</Input.Prefix>
										</Input.Field>
									</Row>
								</Input.Group>
							</Column>
						</Section>
					</Column>
				</Row>
				<LargeScreen>
					<FormActions>
						<ActionBarActions />
					</FormActions>
				</LargeScreen>
			</Column>
		</LargeScreenContent.Inner>
	);
}

interface ExerciseAttachmentProps {
	isNew?: boolean;
	attachment: Attachment;
	newAttachments?: Attachment[];
	setNewAttachments?: (attachments: Attachment[]) => void;
	deletedAttachments?: number[];
	setDeletedAttachments?: (attachments: number[]) => void;
}

const ExerciseAttachment = ({
	isNew,
	attachment,
	deletedAttachments,
	setDeletedAttachments,
	newAttachments,
	setNewAttachments,
}: ExerciseAttachmentProps) => {
	const deleteAttachment = async () => {
		if (isNew) {
			// instantly remove for new (uncreated) exercise
			setNewAttachments(
				newAttachments.filter((a: Attachment) => a.id !== attachment.id)
			);
			await destroy(attachment);
		} else {
			// add to delete state, unlink and delete on save
			setDeletedAttachments([...deletedAttachments, attachment.id]);
		}
	};

	return (
		<FormAttachment
			key={attachment.id}
			attachment={attachment}
			onDelete={deleteAttachment}
			bytesUploaded={attachment.bytesUploaded}
		/>
	);
};

interface ExerciseTagsProps {
	onChange: (tags: List<Tag>) => void;
	tagList: List<Tag>;
}

const ExerciseTags = ({ onChange, tagList }: ExerciseTagsProps) => {
	const [tags, setTags] = useState(tagList);

	useEffect(() => {
		onChange(tags);
	}, [onChange, tags]);

	const onAdd = (tag: Tag) => setTags(List([...tags, tag]));

	const onRemove = (removedTag: Tag) => {
		const _tags = tags.filter(
			(tag: Tag) => tag.get('name') !== removedTag.get('name')
		);

		setTags(_tags);
	};

	return (
		<TagForm
			inline
			type="tag"
			location="below"
			tags={tags}
			onSelect={onAdd}
			onCreate={onAdd}
			onRemove={onRemove}
		/>
	);
};
