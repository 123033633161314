import { ReactNode } from 'react';

export const symbol_reviews: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m480-461 87 53q8 5 16-1t6-16l-23-98 77-67q8-7 4.5-16T634-616l-101-8-39-93q-3.871-9-13.935-9Q470-726 466-717l-39 93-101 8q-10 1-13.5 10t4.5 16l77 67-23 98q-2 10 6 16t16 1l87-53ZM240-240 131-131q-14 14-32.5 6.344T80-152v-668q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H240Zm-26-60h606v-520H140v600l74-80Zm-74 0v-520 520Z"
		/>
	</svg>
);
