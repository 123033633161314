import { ForwardRefExoticComponent, Fragment } from 'react';

import * as styles from 'pkg/config/styles';

import Link from 'pkg/router/Link';
import * as models from 'pkg/api/models';
import { cssClasses } from 'pkg/css/utils';
import { AttachmentType } from 'pkg/api/models/attachment';

import Icon from 'components/icon';

import * as css from './styles.css';

interface FileAttachmentProps {
	clickable: boolean;

	toggleGallery: (id: number) => void;

	attachment: models.attachment.Attachment;
}

export default function FileAttachment({
	attachment,
	toggleGallery,
	clickable,
}: FileAttachmentProps) {
	const extension = models.attachment.getExtension(attachment);
	const fileSize = models.attachment.getHumanFileSize(attachment);

	const fileIconName =
		attachment.type === AttachmentType.Video ? 'av-play' : 'file-generic';
	const showDownloadIcon = attachment.type !== AttachmentType.Video;

	const handleOnClick = () => toggleGallery(attachment.id);

	let fileProps: {
		clickable?: boolean;
		onClick?: () => void;
		as?: ForwardRefExoticComponent<any>;
		href?: string;
		target?: string;
	} = { clickable };

	if (attachment.type === AttachmentType.Video) {
		fileProps = { onClick: handleOnClick };
	} else {
		fileProps = { href: attachment.url, target: '_blank' };
	}

	const content = (
		<Fragment>
			<div className={css.fileIcon}>
				<Icon name={fileIconName} fill={styles.palette.white} size={2} />
			</div>
			<strong className={css.title}>{attachment.title}</strong>
			<p className={css.description}>
				{extension} - {fileSize}
			</p>
			{showDownloadIcon && (
				<Icon
					className={css.downloadIcon}
					name="download-simple"
					fill={styles.palette.blue[500]}
				/>
			)}
		</Fragment>
	);

	return attachment.type === AttachmentType.Video ? (
		<div
			className={cssClasses(css.file, clickable ? css.clickable : '')}
			{...fileProps}>
			{content}
		</div>
	) : (
		<Link
			className={cssClasses(css.file, clickable ? css.clickable : '')}
			{...fileProps}>
			{content}
		</Link>
	);
}
