import { MotionValue, useMotionValue } from 'framer-motion';

import { clipOffset, timelineWidth } from 'pkg/video';

import * as config from 'routes/video/analyze/timeline/config';

import { usePlayerState } from 'components/video-analytics/PlayerState';

export function useTimelineWidth(): number {
	const { timelineZoom, timelinePrecision, duration } = usePlayerState();

	return timelineWidth(
		config.getSegmentWidth(timelineZoom),
		duration,
		config.multiplier[timelinePrecision]
	);
}

let motionValueOffset: MotionValue;

export interface TimelineOffsetHook {
	get: () => MotionValue;
	set: (timestamp: number) => void;
}

export function useTimelineOffset(): TimelineOffsetHook {
	const { duration } = usePlayerState();
	const width = useTimelineWidth();
	const value = useMotionValue<number>(0);

	if (!motionValueOffset) {
		motionValueOffset = value;
	}

	const get = (): MotionValue => motionValueOffset;

	const set = (timestamp: number) => {
		let nextOffset = clipOffset(0, duration, timestamp);

		if (Number.isNaN(nextOffset)) {
			nextOffset = 0;
		}

		nextOffset = -((width * nextOffset) / 100);

		motionValueOffset.set(nextOffset);
	};

	return { get, set };
}
