// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.X_k8V0qa8IVnovaTiRIo {
	padding: var(--spacing-4) !important;
}

.K0MbqrdhM6eofpIKHgCS {
	--spacing: 0px !important;
}
`, "",{"version":3,"sources":["webpack://./routes/organization/user-profile/overview/relationships/styles.css"],"names":[],"mappings":"AAAA;CACC,oCAAoC;AACrC;;AAEA;CACC,yBAAyB;AAC1B","sourcesContent":[".relationshipRowCard {\n\tpadding: var(--spacing-4) !important;\n}\n\n.relationshipRowUser {\n\t--spacing: 0px !important;\n}\n"],"sourceRoot":""}]);
// Exports
export var relationshipRowCard = `X_k8V0qa8IVnovaTiRIo`;
export var relationshipRowUser = `K0MbqrdhM6eofpIKHgCS`;
export default ___CSS_LOADER_EXPORT___;
