import { ReactNode } from 'react';

export const symbol_tsunami: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M481-159q-30 20-64 29.5t-70 9.5q-35 0-69.5-10T213-159q-24 14-50 22.5T110-123q-12 2-21-6.5T80-150q0-13 8.5-22.5T110-184q21-4 40.5-12t37.5-19q11-7 25-7.5t25 7.5q25 16 52.5 25t56.5 9q29 0 56.5-8.5T456-214q12-8 26-7.5t26 8.5q24 16 51 24t56 8q32 0 61.5-12t55.5-29q8-5 17-5t17 5q20 13 40.5 23t43.5 15q13 3 21.5 12t8.5 22q0 13-9.5 21t-21.5 6q-26-5-51.5-13.5T749-159q-31 18-65 28.5T615-120q-35 0-69-10.5T481-159ZM80-310v-70q0-95 38.5-178.5t104-146q65.5-62.5 153-99T560-840q17 0 34.5 1t34.5 3q15 2 22 15t1 27q-11 23-16.5 44t-5.5 47q0 59 42 106t101 47h77q13 0 21.5 8.5T880-520q0 13-8.5 21.5T850-490h-77q-85 0-144-64t-59-149q0-14 3.5-33t8.5-41q-85 20-138.5 88T390-540q0 38 13.5 74t38.5 70l21-15q8-5 17-5t17 5q26 17 56 28.5t62 11.5q32 0 62-12t56-29q8-5 16-5t16 5l33 20q13 6 25.5 10.5T850-374q13 3 21.5 12t8.5 22q0 13-9.5 21t-21.5 6q-26-5-51.5-14T749-349q-34 20-66.5 29.5T615-310q-35 0-71-11t-63-28q-32 20-66 29t-68 10q-32 0-67.5-10.5T213-349q-29 17-65.5 27T80-310Zm265-61q6 0 16-1t21-2q-25-41-38.5-82.5T330-540q0-78 41-149t109-106v23q-80 17-141.5 54t-104 88q-42.5 51-66 114T140-382q14-8 28.5-15.5T197-413q8-5 16-4.5t16 5.5q11 8 21.5 14t22.5 11q18 8 35.5 12t36.5 4Zm-35-212Z"
		/>
	</svg>
);
