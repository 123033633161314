import { Fragment, useState, useReducer, useEffect } from 'react';
import { Record, Map } from 'immutable';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { t } from '@transifex/native';

import * as actions from 'pkg/actions';
import { useEndpoint } from 'pkg/api/use_endpoint';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import { tlog } from 'pkg/tlog';

import SendAs from 'containers/group/post/form/SendAs';
import RecipientsStep from 'containers/group/post/form/RecipientsStep';
import Attachments from 'containers/group/post/form/Attachments';
import PreviewStep from 'containers/group/post/form/PreviewStep';

import { useSmallScreen } from 'components/MediaQuery';
import TabSwitcher from 'components/TabSwitcher';
import Icon from 'components/icon';
import StepModal, { Step } from 'components/step-modal';

import * as iconStyles from 'components/icon/styles.css';
import ContentInput from 'components/group/post/form/ContentInput';
import Notification from 'components/group/post/form/Notification';
import Schedule from 'components/group/post/form/Schedule';
import Sticky from 'components/group/post/form/Sticky';
import Title from 'components/group/post/form/Title';
import VisibleTo from 'components/group/post/form/VisibleTo';
import Form from 'components/form/Form';
import Comments from 'components/group/post/form/Comments';

/**
 * @type {React.Element<any>}
 */

const NOOP = () => {};

const TabSwitcherWrapper = styled.div`
	display: flex;
	justify-content: center;
`;

const AdvancedSettings = styled.div`
	display: flex;
	justify-content: left;
	margin-top: var(--spacing-7);
	cursor: pointer;
	align-items: center;
	color: var(--palette-blue-500);

	span {
		font-size: var(--font-size-base);
		font-weight: var(--font-weight-semibold);
		margin-right: var(--spacing-2);
	}

	.${iconStyles.icon} {
		font-size: var(--font-size-2xl);
	}
`;

const ErrorMessage = styled.div`
	display: flex;
	justify-content: flex-end;
	color: var(--palette-red-500);
	font-size: var(--font-size-sm);
	font-weight: var(--font-weight-normal);
`;

const formState = Record({
	newAttachments: Map({}),
	content: '',
	title: '',
	status: 'published',
	authorGroupId: null,
	isSticky: false,
	stickyUntil: 0,
	commentsDisabled: false,
	notify: true,
	publishedAt: 0,
	visibleToLegalGuardians: true,
	visibleToUsers: true,
	visibleToOrganization: false,
	isPublic: false,
	includeGroupIds: [],
	totalGroups: 0,
	totalUsers: 0,
});

const reducer = (state, { type, payload }) => {
	switch (type) {
		case 'set_attachment':
			// removeIn is a workaround for the dispatch not triggering a component update when the attachment is complete.
			return state
				.removeIn(['newAttachments', payload.id])
				.setIn(['newAttachments', payload.id], payload);
		case 'remove_attachment':
			return state.removeIn(['newAttachments', payload]);
		case 'set_value': {
			return state.set(payload.key, payload.value);
		}
		case 'set_values': {
			Object.entries(payload).forEach(([key, val]) => {
				state = state.set(key, val);
			});

			return state;
		}
		case 'set_include_group': {
			return state.set('includeGroupIds', payload);
		}
	}
};

const PostForm = ({
	id,
	isAdminOrStaffForGroup,
	onSave = (post) => {
		tlog.debug(`saved post ${post.id}`);
	},
	groupId,
	onClose,
}) => {
	const [isSaving, setIsSaving] = useState(false);
	const [error, setError] = useState(null);
	const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);

	const { record: post, isLoading: isLoadingPost } = useEndpoint(
		id && endpoints.GroupPosts.Show(id)
	);

	const [postMode, setPostMode] = useState(
		post?.isVisibleToOrganization ? 'club' : 'single'
	);
	const isMultiGroupPost = postMode === 'multi';

	if (post?.groupId) {
		groupId = post.groupId;
	}

	const { record: group, isLoading } = useEndpoint(
		groupId && endpoints.Groups.Show(groupId)
	);

	const [formData, localDispatch] = useReducer(
		reducer,
		formState({
			title: post.title,
			content: post.content,
			isSticky: post.isSticky,
			stickyUntil: post.stickyUntil,
			commentsDisabled: post.commentsDisabled,
			status: post.status,
			authorGroupId: post.authorGroupId,
			notify: post.notify,
			publishedAt: post.publishedAt,
			visibleToLegalGuardians: post.visibleToLegalGuardians,
			visibleToOrganization: post.visibleToOrganization,
			visibleToUsers: post.visibleToUsers,
			isPublic: post.isPublic,
		})
	);

	useEffect(() => {
		if (!post?.id) {
			return;
		}

		localDispatch({
			type: 'set_values',
			payload: {
				title: post.title,
				content: post.content,
				isSticky: post.isSticky,
				stickyUntil: post.stickyUntil,
				commentsDisabled: post.commentsDisabled,
				status: post.status,
				authorGroupId: post.authorGroupId,
				notify: post.notify,
				publishedAt: post.publishedAt,
				visibleToLegalGuardians: post.visibleToLegalGuardians,
				visibleToOrganization: post.visibleToOrganization,
				visibleToUsers: post.visibleToUsers,
				isPublic: post.isPublic,
			},
		});
	}, [post?.id]);

	const isNewPost = !id;

	const numSelectedGroups = formData.includeGroupIds.length;
	const hasSelectedRecipients = numSelectedGroups > 0;

	const formPartProps = { data: formData, dispatch: localDispatch };

	let showNotify = false;

	if (!post.id) {
		showNotify = true;
	} else if (formData.publishedAt > Math.floor(Date.now() / 1000)) {
		showNotify = true;
	}

	const hasIncompleteUploads = formData.newAttachments
		.toList()
		.toArray()
		.some((attachment) => attachment.status !== 'normal');

	const toggleAdvancedSettings = () =>
		setShowAdvancedSettings(!showAdvancedSettings);

	const handleSetPostMode = (val) => {
		setPostMode(val);
		if (val === 'multi' || val === 'club') {
			setShowAdvancedSettings(true);
		}
	};

	// @todo handle errors
	const save = async () => {
		setIsSaving(true);

		let request, response;

		const data = formData.toJS();

		if (!isNewPost && data.status !== 'scheduled') {
			delete data.publishedAt;
		}

		if (isNewPost && postMode === 'club') {
			data.visibleToOrganization = true;
		}

		if (id) {
			[request, response] = await actions.posts.updateGroupPost(id, data);
		} else {
			[request, response] = await actions.posts.createGroupPost(groupId, data);
		}

		let error = '';

		if (!request.ok && response.error) {
			error = response.error;
			setError(error);
		}

		if (request.ok) {
			onSave(response);
		}

		setIsSaving(false);
	};

	const postModeOptions = {
		single: t(`Team post`),
		multi: t(`Multi group`),
	};

	const isSmallScreen = useSmallScreen();

	if (models.group.isOrganization(group)) {
		postModeOptions.club = t('Club lobby');
	}

	let showVisibilityOptions = true;
	if (postMode === 'club') {
		showVisibilityOptions = false;
	} else if (!isNewPost && post.visibleToOrganization) {
		showVisibilityOptions = false;
	}

	if (isLoading || isLoadingPost) return null;

	return (
		<StepModal onClose={onClose}>
			<Step
				title={id ? t(`Edit post`) : t(`New post`)}
				canGoNext={!hasIncompleteUploads && formData.content.length !== 0}
				nextLabel={
					isMultiGroupPost && isNewPost ? t(`Select groups`) : t('Preview')
				}>
				<Form>
					{isNewPost && models.group.canShowChildren(group) ? (
						<Fragment>
							<TabSwitcherWrapper>
								<TabSwitcher
									wide={isSmallScreen}
									active={postMode}
									tabs={postModeOptions}
									onChange={handleSetPostMode}
								/>
							</TabSwitcherWrapper>
							<Title {...formPartProps} />
							<ContentInput {...formPartProps} />
							<Attachments post={post} {...formPartProps} />
						</Fragment>
					) : (
						<Fragment>
							{isAdminOrStaffForGroup && <Title {...formPartProps} />}
							<ContentInput {...formPartProps} />
							<Attachments post={post} {...formPartProps} />
						</Fragment>
					)}

					{isAdminOrStaffForGroup && (
						<Fragment>
							<AdvancedSettings
								onClick={toggleAdvancedSettings}
								data-testid="new_post_form.advanced_settings_toggle">
								<span>{t(`Advanced settings`)}</span>
								<Icon
									name="chevron"
									rotate={showAdvancedSettings ? '90deg' : '0'}
								/>
							</AdvancedSettings>

							{showAdvancedSettings && (
								<Fragment>
									{isNewPost && <SendAs {...formPartProps} />}
									{showVisibilityOptions && (
										<VisibleTo {...formPartProps} group={group} />
									)}
									<Sticky {...formPartProps} />
									<Comments
										data={formData}
										dispatch={localDispatch}
										isNewPost={isNewPost}
									/>
									<Schedule post={post} {...formPartProps} />
									{showNotify && <Notification {...formPartProps} />}
								</Fragment>
							)}
						</Fragment>
					)}
					{error && <ErrorMessage>{error}</ErrorMessage>}
				</Form>
			</Step>
			{isMultiGroupPost && isNewPost && (
				<Step
					title={t(`Recipients`)}
					nextLabel={t('Preview')}
					canGoNext={hasSelectedRecipients}
					skipBody>
					<RecipientsStep
						formData={formData}
						localDispatch={localDispatch}
						groupId={groupId}
					/>
				</Step>
			)}
			<Step
				spaciousBody
				title={t(`Confirm`)}
				canGoPrev={!isSaving}
				canGoNext={!isSaving}
				onNext={save}
				nextLabel={
					formData.status === 'scheduled' ? t('Schedule') : t('Publish')
				}>
				<PreviewStep
					isMultiGroupPost={isMultiGroupPost}
					numSelectedGroups={numSelectedGroups}
					post={formData}
				/>
			</Step>
		</StepModal>
	);
};

PostForm.propTypes = {
	id: PropTypes.number,
	groupId: PropTypes.number,
	isAdminOrStaffForGroup: PropTypes.bool,
	onSave: PropTypes.func,
};

PostForm.defaultProps = {
	isAdminOrStaffForGroup: false,
	onSave: NOOP,
};

export default PostForm;
