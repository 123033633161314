import styled from 'styled-components';
import { MouseEvent } from 'react';

import * as styles from 'pkg/config/styles';

import rgba from 'pkg/rgba';

import AssetImage from 'components/AssetImage';

interface RoleSelectorCardStylesProps {
	selected?: boolean;
}

const RoleSelectorCardStyles = styled.div<RoleSelectorCardStylesProps>`
	display: grid;
	padding: 0 ${styles.spacing._5};
	font-family: ${styles.font.family.default};
	font-size: ${styles.font.size.lg};
	line-height: ${styles.font.lineHeight.base};
	font-weight: ${styles.font.weight.semibold};
	width: 100%;
	cursor: pointer;
	text-decoration: none;
	border-radius: 10px;
	color: ${styles.palette.black};
	background: ${styles.palette.white};
	min-height: 52px;
	transition: box-shadow 120ms ease-in-out;
	padding: 13px;
	grid-template-areas: 'icon label' 'icon description';
	grid-template-rows: auto auto;
	grid-template-columns: auto 1fr;
	gap: ${styles.spacing._2} ${styles.spacing._5};
	text-align: left;
	min-height: 86px;
	border: 3px solid ${styles.palette.white};

	&[data-selected='true'] {
		box-shadow: ${rgba(styles.palette.white, 0.5)} 0 0 0 4px;
		border: 3px solid ${styles.palette.blue[500]};
	}

	@media (hover: hover) {
		&:hover {
			box-shadow: ${rgba(styles.palette.white, 0.5)} 0 0 0 4px;
		}
	}

	span:nth-child(1) {
		grid-area: icon;
		align-self: center;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 8px;
		width: 48px;
		height: 48px;

		img {
			width: 125%;
			height: 125%;
		}
	}

	span:nth-child(2) {
		grid-area: label;
		justify-self: flex-start;
		align-self: flex-end;
	}

	span:nth-child(3) {
		grid-area: description;
		justify-self: flex-start;
		align-self: flex-start;
		color: ${styles.palette.gray[600]};
		font-size: ${styles.font.size.sm};
		line-height: ${styles.font.lineHeight.sm};
		font-weight: ${styles.font.weight.normal};
		white-space: normal;
	}
`;

interface RoleSelectorCardProps extends RoleSelectorCardStylesProps {
	label: string;
	description?: string;
	userRole: 'parent' | 'staff' | 'player';
	onClick?: (event?: MouseEvent | TouchEvent | PointerEvent) => void;
	testid?: string;
}

const RoleSelectorCard = ({
	label,
	description,
	userRole,
	selected,
	onClick,
	testid,
}: RoleSelectorCardProps): JSX.Element => {
	return (
		<RoleSelectorCardStyles
			data-testid={testid}
			data-selected={selected}
			onClick={onClick}>
			<span>
				<AssetImage src={`img/roles/${userRole}.svg`} />
			</span>
			<span>{label}</span>
			<span>{description}</span>
		</RoleSelectorCardStyles>
	);
};

export default RoleSelectorCard;
