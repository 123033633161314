const icon = {
	name: 'sort',
	objects: [
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M7.93995 4.05956C7.84983 4.02122 7.75066 4 7.64655 4C7.54728 4 7.45251 4.01929 7.3658 4.05431C7.27495 4.09093 7.18983 4.14604 7.1162 4.21967L4.21967 7.1162C3.92678 7.4091 3.92678 7.88397 4.21967 8.17686C4.51256 8.46976 4.98744 8.46976 5.28033 8.17686L6.89655 6.56065V18.7656C6.89655 19.1798 7.23233 19.5156 7.64655 19.5156C8.06076 19.5156 8.39655 19.1798 8.39655 18.7656V6.56065L10.0128 8.17687C10.3057 8.46976 10.7806 8.46975 11.0734 8.17686C11.3663 7.88396 11.3663 7.40909 11.0734 7.1162L8.17952 4.22233C8.17084 4.21356 8.16194 4.205 8.15284 4.19667C8.0885 4.13771 8.01637 4.09201 7.93995 4.05956ZM12.4265 15.3387C12.7194 15.0458 13.1943 15.0458 13.4872 15.3387L15.1035 16.9549V4.75C15.1035 4.33579 15.4392 4 15.8535 4C16.2677 4 16.6035 4.33579 16.6035 4.75V16.9549L18.2197 15.3387C18.5126 15.0458 18.9874 15.0458 19.2803 15.3387C19.5732 15.6316 19.5732 16.1065 19.2803 16.3994L16.3838 19.2959C16.2391 19.4406 16.05 19.5138 15.8604 19.5156C15.8581 19.5156 15.8558 19.5156 15.8535 19.5156C15.8511 19.5156 15.8488 19.5156 15.8465 19.5156C15.7466 19.5147 15.6513 19.4942 15.5643 19.4578C15.4807 19.4229 15.4022 19.3723 15.3333 19.3059C15.3295 19.3022 15.3257 19.2985 15.3219 19.2947L12.4266 16.3994C12.1337 16.1065 12.1337 15.6316 12.4265 15.3387Z',
			},
		},
	],
};

export default icon;
