import { ReactNode } from 'react';

export const symbol_interactive_space: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M271-636q5 5 10.5 5t10.5-5l54-53q5-4.636 5-10.818Q351-706 346-711l-54-53q-5-5-10.5-5t-10.5 5l-54 53q-5 4.636-5 10.818Q212-694 217-689l54 53Zm209-24ZM140-400q-24 0-42-18t-18-42v-400q0-24 18-42t42-18h680q24 0 42 18t18 42v400q0 24-18 42t-42 18H708q-12.641 0-20.821-8.675-8.179-8.676-8.179-21.5 0-12.825 8.625-21.325T709-460h111v-400H140v400h111q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T251-400H140Zm520.059-285Q683-685 699-701.059q16-16.059 16-39T698.941-779q-16.059-16-39-16T621-778.941q-16 16.059-16 39T621.059-701q16.059 16 39 16ZM230-40q-12.75 0-21.375-8.625T200-70v-54q0-35 19.5-65t51.5-45q49-23 102-34.5T480-280q54 0 107 11.5T689-234q32 15 51.5 45t19.5 65v54q0 12.75-8.625 21.375T730-40H230Zm30-60h440v-24q0-17.625-10-32.312Q680-171 663-179q-43-20-89-30.5T480-220q-48 0-94 10.5T297-179q-17 8-27 22.688-10 14.687-10 32.312v24Zm220-220q-58 0-99-41t-41-99q0-58 41-99t99-41q58 0 99 41t41 99q0 58-41 99t-99 41Zm-.235-60Q513-380 536.5-403.265q23.5-23.264 23.5-56.5Q560-493 536.735-516.5q-23.264-23.5-56.5-23.5Q447-540 423.5-516.735q-23.5 23.264-23.5 56.5Q400-427 423.265-403.5q23.264 23.5 56.5 23.5Zm.235-80Zm0 360Z"
		/>
	</svg>
);
