import { ReactNode } from 'react';

export const symbol_waterfall_chart: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M171.176-160Q150-160 135-174.875 120-189.75 120-211v-123q0-21.25 14.824-36.125 14.823-14.875 36-14.875Q192-385 207-370.125 222-355.25 222-334v123q0 21.25-14.824 36.125-14.823 14.875-36 14.875Zm206.706-248Q357-408 342-422.875 327-437.75 327-459v-84q0-20.833 14.824-35.417 14.823-14.583 36-14.583Q399-593 413.5-578.125 428-563.25 428-542v84q0 20.833-14.618 35.417Q398.765-408 377.882-408Zm206-208Q563-616 548-630.875 533-645.75 533-667v-83q0-20.833 14.823-35.417Q562.647-800 583.824-800 605-800 619.5-785.125 634-770.25 634-749v83q0 20.833-14.618 35.417Q604.765-616 583.882-616Zm206 456Q769-160 754-174.875 739-189.75 739-211v-539q0-20.833 14.823-35.417Q768.647-800 789.824-800 811-800 825.5-785.125 840-770.25 840-749v539q0 20.833-14.618 35.417Q810.765-160 789.882-160Z"
		/>
	</svg>
);
