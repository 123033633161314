// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.o4ABSobAiHj2zNnd2wN2 {
	color: var(--palette-black);
	cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./routes/event/single/styles.css"],"names":[],"mappings":"AAAA;CACC,2BAA2B;CAC3B,eAAe;AAChB","sourcesContent":[".sendInvites {\n\tcolor: var(--palette-black);\n\tcursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
export var sendInvites = `o4ABSobAiHj2zNnd2wN2`;
export default ___CSS_LOADER_EXPORT___;
