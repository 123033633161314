import { ReactNode } from 'react';

export const symbol_no_accounts: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M588-502 395-695q17-14 38.5-21.5T480-724q58 0 97.5 39.5T617-587q0 25-7.5 46.5T588-502ZM222-255q63-44 123.5-67.5T480-346q37 0 65.5 5.5T597-327L474-450q-54-2-89.5-36.5T344-575L218-701q-37 45-57.5 99.5T140-480q0 61 19 116t63 109Zm521-6q35-42 56-97.5T820-480q0-145-97.5-242.5T480-820q-70 0-124 21t-96 55l483 483ZM480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-155.5t86-127Q252-817 325-848.5T480-880q83 0 155.5 31.5t127 86q54.5 54.5 86 127T880-480q0 82-31.5 155t-86 127.5q-54.5 54.5-127 86T480-80Zm0-60q59 0 109.5-17T691-212q-51-36-104-55t-107-19q-54 0-107 19t-104 55q51 38 102 55t109 17Zm0-73Z"
		/>
	</svg>
);
