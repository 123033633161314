import { ReactNode } from 'react';

export const symbol_hd: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M290-446h100v64q0 10.833 7.116 17.917 7.117 7.083 18 7.083Q426-357 433-364.083q7-7.084 7-17.917v-196q0-10.833-7.116-17.917-7.117-7.083-18-7.083Q404-603 397-595.917q-7 7.084-7 17.917v82H290v-82q0-10.833-7.116-17.917-7.117-7.083-18-7.083Q254-603 247-595.917q-7 7.084-7 17.917v196q0 10.833 7.116 17.917 7.117 7.083 18 7.083Q276-357 283-364.083q7-7.084 7-17.917v-64Zm245 89h135q20 0 35-15t15-35v-146q0-20-15-35t-35-15H535q-6 0-10.5 4.5T520-588v216q0 6 4.5 10.5T535-357Zm35-50v-146h100v146H570ZM140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm0-60h680v-520H140v520Zm0 0v-520 520Z"
		/>
	</svg>
);
