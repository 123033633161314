import {
	Fragment,
	ReactNode,
	ReactElement,
	useState,
	cloneElement,
	memo,
	MouseEvent,
} from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';
import { ErrorBoundary } from '@sentry/react';

import { ApiConfig, useApiConfig } from 'components/application/apiconfig';

const Wrapper = styled.div`
	display: grid;
	grid-auto-flow: row;
	row-gap: var(--spacing-5);
`;

const Block = styled.div`
	white-space: pre-line;
	word-break: break-word;

	a {
		overflow-wrap: break-word;
		word-wrap: break-word;
		word-break: break-word;
		hyphens: auto;
		display: inline-block;
		cursor: pointer;
	}
`;

/**
 *	Formatters
 *
 *	A formatter consists of at least a selector and a decorator. The selector is also used to split the link,
 *	so it cannot have any capture groups except for the top most one, e.g; /(hello (?:world))/gi will capture
 *	"hello" + "word", but only split on "hello world".
 *
 *	Formatters can also be defaultIgnored and needs to be explicitly included in allowedFormatters on use.
 *	Formatters that use embeds (video, iframe, oEmbed) *must* set the isEmbed flag to true.
 *
 *	@example
 *
 *	addFormatter('md:anchors', {
 *		selector: /(\[.*?\]\(.*?\))/gi,
 *		decorator: (guid: string, match: string, selector: RegExp): JSX.Element => {
 *			match.match(/(\[.*?\]\(.*?\))/gi);
 *			return <a key={guid} href={RegExp.$2}>{RegExp.$1}</a>
 *		}
 *	});
 */

export type CompiledSelector = (apiConfig: ApiConfig) => RegExp;

function getCompiledRegExp(
	selector: RegExp | CompiledSelector,
	apiConfig: ApiConfig
): RegExp {
	if (selector instanceof RegExp) {
		return selector;
	} else {
		return selector(apiConfig);
	}
}

type FormatterDecorator = (
	guid: string,
	match: string,
	meta: {
		subject?: string;
		selector?: RegExp;
		matches?: RegExpMatchArray;
		args?: any[];
		apiConfig?: ApiConfig;
	}
) => ReactNode;

interface FormatterDefinition {
	selector: RegExp | CompiledSelector;
	decorator: FormatterDecorator;
	defaultIgnored?: boolean;
	ignoreMatchSplit?: boolean;
	matchIndex?: number;
	isEmbed?: boolean;
}

const loadedFormatters = new Map<string, FormatterDefinition>();

export type FormatterDefinitions = Map<string, FormatterDefinition>;

export function getFormatters(): FormatterDefinitions {
	return loadedFormatters;
}

export function getDefaultFormatters(): FormatterDefinitions {
	const defaultFormatters = new Map<string, FormatterDefinition>();

	for (const [name, formatter] of getFormatters().entries()) {
		if (formatter.defaultIgnored !== true) {
			defaultFormatters.set(name, formatter);
		}
	}

	return defaultFormatters;
}

export function getFormatter(name: string): FormatterDefinition {
	return loadedFormatters.get(name);
}

export function addFormatter(name: string, defs: FormatterDefinition): void {
	loadedFormatters.set(name, defs);
}

export function removeFormatter(name: string): void {
	loadedFormatters.delete(name);
}

function filterAllowedFormatters(
	formatters: FormatterDefinitions,
	allowedFormatters: string[]
): FormatterDefinitions {
	const filteredFormatters = new Map<string, FormatterDefinition>();

	if (allowedFormatters.length === 0) {
		return formatters;
	}

	for (const [name, formatter] of formatters.entries()) {
		if (allowedFormatters.includes(name)) {
			filteredFormatters.set(name, formatter);
		}
	}

	// @NOTE Include formatters that are defaultIgnored
	const allFormatters = getFormatters();

	allowedFormatters.forEach((name: string) => {
		if (allFormatters.has(name) && !filteredFormatters.has(name)) {
			filteredFormatters.set(name, allFormatters.get(name));
		}
	});

	return filteredFormatters;
}

function filterIgnoredFormatters(
	formatters: FormatterDefinitions,
	ignoredFormatters: string[]
): FormatterDefinitions {
	const filteredFormatters = new Map<string, FormatterDefinition>();

	if (ignoredFormatters.length === 0) {
		return formatters;
	}

	for (const [name, formatter] of formatters.entries()) {
		if (ignoredFormatters.includes(name)) {
			filteredFormatters.set(name, formatter);
		}
	}

	return filteredFormatters;
}

function countEmbeds(
	raw: string,
	formatters: FormatterDefinitions,
	apiConfig: ApiConfig
): number {
	if (raw.length === 0) return 0;

	if (!formatters) {
		formatters = getDefaultFormatters();
	}

	let numEmbeds = 0;

	prepareRaw(raw)
		.split(/[\r\n\t]{2,}/gm)
		.filter((n) => n)
		.forEach((item) => {
			for (const [, { selector, isEmbed }] of formatters.entries()) {
				const compiledSelector = getCompiledRegExp(selector, apiConfig);

				if (item.match(compiledSelector)?.length > 0 && isEmbed) {
					numEmbeds++;
				}
			}
		});

	return numEmbeds;
}

interface FormattedBlockProps {
	raw: string;
	wrapWith?: ReactElement;
	allowedFormatters?: string[];
	ignoredFormatters?: string[];
	className?: string;
}

/**
 *	FormattedBlock
 *
 *	Formats a single block (a block is part of a string separated by two line breaks).
 *	Returned element will have data attributes, has-embeds and num-embeds.
 *	FormattedBlock does not handle collapsed content, prefix, suffix nor collapse-triggers.
 *
 * 	@see FormattedContent
 *
 *	@param raw string
 *	@param wrapWith? ReactElement
 *	@param allowedFormatters? string[]
 *	@param ignoredFormatters? string[]
 *
 *	@return JSX.Element
 */
export function FormattedBlock({
	raw,
	wrapWith,
	allowedFormatters,
	ignoredFormatters,
}: FormattedBlockProps): JSX.Element {
	const apiConfig = useApiConfig();

	let formatters = getDefaultFormatters();

	if (allowedFormatters?.length > 0) {
		formatters = filterAllowedFormatters(formatters, allowedFormatters);
	}

	if (ignoredFormatters?.length > 0) {
		formatters = filterIgnoredFormatters(formatters, ignoredFormatters);
	}

	let numEmbeds = 0;

	if (!wrapWith) {
		wrapWith = <Fragment />;
	}

	const formatAll = (text: string): ReactNode => {
		let formatted: string | ReactNode = text;

		for (const [
			name,
			{ selector, decorator, ignoreMatchSplit },
		] of formatters.entries()) {
			const compiledSelector = getCompiledRegExp(selector, apiConfig);
			const matches = compiledSelector.exec(text);
			const match = text.match(compiledSelector)?.length > 0;

			if (match) {
				let match;
				let segments: (string | ReactNode)[] = text.split(compiledSelector);

				if (ignoreMatchSplit) {
					segments = ['', formatted];
				}

				for (let n = 1, l = segments.length; n < l; n += 2) {
					match = segments[n];

					if (!match) continue;

					const guid: string = [name, n].join(':');

					formatted = decorator(guid, match as string, {
						selector: compiledSelector,
						matches,
						subject: text,
						apiConfig,
					});

					segments[n] = formatted;
					numEmbeds++;
				}

				segments = segments.map((item: any, i: number) => {
					if (typeof item === 'string') {
						return <Fragment key={i}>{formatAll(item)}</Fragment>;
					}

					return <Fragment key={i}>{item}</Fragment>;
				});

				return <Fragment>{segments}</Fragment>;
			}
		}

		return <Fragment>{formatted}</Fragment>;
	};

	const formatted = raw
		.split(/[\r\n]{2,}/gm)
		.filter((n) => n)
		.map((item, index) => <Fragment key={index}>{formatAll(item)}</Fragment>);

	return cloneElement(
		wrapWith,
		{
			'data-has-embeds': numEmbeds > 0,
			'data-num-embeds': numEmbeds,
		},
		formatted
	);
}

interface FormattedContentProps {
	raw: string;
	maxLength?: number;
	prefixWith?: ReactElement;
	suffixWith?: ReactElement;
	expandWith?: ReactElement;
	wrapWith?: ReactElement;
	wrapBlockWith?: ReactElement;
	allowedFormatters?: string[];
	ignoredFormatters?: string[];
	wrapperClassName?: string;
	blockClassName?: string;
	rawFormatter?: (blocks: string[]) => string[];
}

/**
 *	Prepares raw by prefixing, and suffiuxing line breaks for possible embed links
 *
 *	@param raw string
 *	@returns string
 */
function prepareRaw(raw: string): string {
	const replace = `\n\n$1\n\n`;

	const regexInternal: RegExp =
		/((https?:\/\/?((localhost\:{\d+}|(app|dev|staging).360player))(?:\S+)?))/gim;

	const regexVimeo: RegExp =
		/((https?:\/\/?(?:www.)?(?:vimeo.com)(?:\S+)?))/gim;

	const regexYouTube: RegExp =
		/(((?:https?:)?\/{2})?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$)/gim;

	return raw
		.replace(regexInternal, replace)
		.replace(regexVimeo, replace)
		.replace(regexYouTube, replace)
		.trim();
}

/**
 *	FormattedContent
 *
 *	Splits a raw string into one or more blocks and formats them {@see FormattedBlock}.
 *	FormattedContent also handles collapsing of blocks if maxLength is set. Additonally,
 *	a custom wrapper for the whole component, and each block can be set if needed.
 *
 *	Wrapped component will have data attributes;
 *
 *	@attr has-embeds boolean
 *	@attr num-embeds number
 *	@attr num-blocks number
 *	@attr num-visible-blocks number
 *
 *	@param raw string
 *	@param maxLength? number (-1 will not collapse)
 *	@param prefixWith? ReactElement Component will prefix (inside) first block
 *	@param suffixWith? ReactElement Component will suffix (inside) last block
 *	@param expandWith? ReactElement Component used to expand content if collapsed
 *	@param wrapWith? ReactElement
 *	@param wrapBlockWith? ReactElement
 *	@param allowedFormatters? string[]
 *	@param ignoredFormatters? string[]
 *	@param rawFormatter? A function that maps over each split item and prepares them for each formatter
 *
 *	@return JSX.Element
 */
export const FormattedContent = memo(function FormattedContent({
	raw,
	maxLength = -1,
	prefixWith,
	suffixWith,
	expandWith,
	wrapWith,
	wrapBlockWith,
	allowedFormatters,
	ignoredFormatters,
	wrapperClassName,
	blockClassName,
	rawFormatter,
}: FormattedContentProps): JSX.Element {
	const apiConfig = useApiConfig();

	let formatters = getDefaultFormatters();

	if (allowedFormatters?.length > 0) {
		formatters = filterAllowedFormatters(formatters, allowedFormatters);
	}

	if (ignoredFormatters?.length > 0) {
		formatters = filterIgnoredFormatters(formatters, ignoredFormatters);
	}

	let blocks = prepareRaw(raw).split(/\r?\n{2,}/gm);

	if (rawFormatter) {
		blocks = rawFormatter(blocks).filter((n: string) => n);
	}

	const numBlocks = blocks.length;
	const numEmbeds = countEmbeds(raw, formatters, apiConfig);

	if (!wrapWith) {
		wrapWith = <div />;
	}

	if (!wrapBlockWith) {
		wrapBlockWith = <Fragment />;
	}

	const wrapProps = {
		className: wrapperClassName,
		'data-has-embeds': numEmbeds > 0,
		'data-num-embeds': numEmbeds,
		'data-num-blocks': numBlocks,
		'data-num-visible-blocks': numBlocks,
	};

	const [isCollapsed, setCollapsed] = useState<boolean>(
		maxLength && blocks.join(' ').length > maxLength
	);

	if (!numBlocks) return null;

	const handleExpand = (event: MouseEvent) => {
		event.preventDefault();

		setCollapsed(false);
	};

	if (!expandWith) {
		expandWith = (
			<a href="#read-more" onClick={handleExpand}>
				{t('Read more')}
			</a>
		);
	} else {
		expandWith = cloneElement(expandWith, {
			onClick: handleExpand,
		});
	}

	let firstBlock = blocks[0];
	let secondBlock = blocks[1] ?? '';

	const firstBlockContainsURL = firstBlock.match(/(https?:\/\/[^\s]+)/g);

	if (isCollapsed && maxLength > 0 && blocks.join(' ').length > maxLength) {
		const ellipsify = (content: string, limit: number, skipSuffix = false) => {
			let suffix = '';

			const trimmed = content.slice(0, limit).trimRight();

			if (!skipSuffix && content.length > limit) {
				suffix = '…';
			}

			return `${trimmed}${suffix}`;
		};

		firstBlock = ellipsify(firstBlock, maxLength);

		// Check if first block contains a URL
		if (firstBlockContainsURL && secondBlock.length > 0) {
			firstBlock = blocks[0];
			secondBlock = ellipsify(secondBlock, maxLength);
		}

		// @NOTE Validate first paragraph length, this hould only occur with a really long URL.
		if (firstBlock.length > maxLength) {
			firstBlock = ellipsify(firstBlock, maxLength, !!firstBlockContainsURL);

			// @NOTE Check if second block should be hidden
			if (firstBlock.length >= maxLength && firstBlock.endsWith('…')) {
				secondBlock = null;
			}
		}

		if (
			!firstBlockContainsURL &&
			firstBlock.length < maxLength &&
			!firstBlock.endsWith('…') &&
			secondBlock.length > 0
		) {
			secondBlock = secondBlock = ellipsify(
				secondBlock,
				maxLength - firstBlock.length
			);
		}

		const formattedBlock = (
			<Wrapper>
				{secondBlock ? (
					<Fragment>
						<Block className={blockClassName}>
							{prefixWith}{' '}
							<FormattedBlock
								raw={firstBlock}
								wrapWith={wrapBlockWith}
								allowedFormatters={allowedFormatters}
								ignoredFormatters={ignoredFormatters}
							/>
						</Block>
						<Block className={blockClassName}>
							<FormattedBlock
								raw={secondBlock}
								wrapWith={wrapBlockWith}
								allowedFormatters={allowedFormatters}
								ignoredFormatters={ignoredFormatters}
							/>{' '}
							{expandWith}
						</Block>
					</Fragment>
				) : (
					<Block className={blockClassName}>
						{prefixWith}{' '}
						<FormattedBlock
							raw={firstBlock}
							wrapWith={wrapBlockWith}
							allowedFormatters={allowedFormatters}
							ignoredFormatters={ignoredFormatters}
						/>{' '}
						{expandWith}
					</Block>
				)}
			</Wrapper>
		);

		return cloneElement(
			wrapWith,
			{
				...wrapProps,
				'data-num-visible-blocks': secondBlock ? 2 : 1,
			},
			formattedBlock
		);
	}

	const formattedBlocks: ReactElement[] = blocks.map((block, key) => (
		<FormattedBlock
			key={`fmb:${key}`}
			raw={block}
			wrapWith={wrapBlockWith}
			allowedFormatters={allowedFormatters}
			ignoredFormatters={ignoredFormatters}
			className={blockClassName}
		/>
	));

	return cloneElement(
		wrapWith,
		wrapProps,
		<ErrorBoundary>
			<Wrapper>
				{formattedBlocks.map((block, key) => {
					const isFirst = key === 0;
					const isLast = key === formattedBlocks.length - 1;

					return (
						<Block key={`block:${key}`} className={blockClassName}>
							{isFirst && prefixWith && <Fragment>{prefixWith} </Fragment>}
							{block}
							{isLast && suffixWith && <Fragment>{suffixWith} </Fragment>}
						</Block>
					);
				})}
			</Wrapper>
		</ErrorBoundary>
	);
});

export const FormattedContentMaxLength = {
	Standard: 280,
	Comments: 280,
	ChatMessages: 360,
};
