import { Fragment } from 'react';
import { t } from '@transifex/native';

import { useCollection } from 'pkg/api/use_collection';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as routes from 'pkg/router/routes';
import { useCurrentOrganization } from 'pkg/identity';

import FormSubmissionRow from 'routes/organization/contacts/single/SubmissionRow';

import CardAnatomy, * as Card from 'components/Card';
import MissingEntities from 'components/missing-entities';
import SectionTitle from 'components/SectionTitle';
import Icon from 'components/icon';

import Column from 'components/layout/column';
import { Spinner } from 'components/loaders/spinner';

import Button from 'design/button';
import * as Table from 'design/table';

interface ContactFormSubmissionsProps {
	groupId: number;
	contactId: number;
}

const ContactFormSubmissions: React.FC<
	React.PropsWithChildren<ContactFormSubmissionsProps>
> = ({ groupId, contactId }) => {
	const org = useCurrentOrganization();

	const params = {
		submitted_for_user_ids: [contactId].toString(),
		relations: 'Form',
		count: '3',
	};
	const { records, isLoading, removeRecord } =
		useCollection<models.formSubmission.FormSubmission>(
			endpoints.FormSubmissions.Index(),
			{
				showAllResults: true,
				queryParams: new URLSearchParams(params),
			}
		);

	const tableColumns: Table.HeaderColumn[] = [
		{ content: t('Title'), align: 'left' },
		{ content: t('Submitted'), align: 'left' },
		{ content: '', width: 'max-content' },
	];

	if (isLoading) {
		return <Spinner />;
	}

	return (
		<Fragment>
			<Column>
				<SectionTitle icon="contact_page">
					{t('Registrations')}
					{records.length > 0 && (
						<Button
							icon="arrow_forward"
							iconPosition="right"
							inline
							noPadding
							href={routes.Management.Contact.Show(org.id, contactId, 'forms')}>
							{t('View all')}
						</Button>
					)}
				</SectionTitle>
				{records.length > 0 ? (
					<Column>
						<Card.Base>
							<Table.Table columns={tableColumns}>
								{records.map((submission) => (
									<FormSubmissionRow
										key={submission.id}
										groupId={groupId}
										submission={submission}
										removeRecord={removeRecord}
									/>
								))}
							</Table.Table>
						</Card.Base>
					</Column>
				) : (
					<CardAnatomy $noBorder>
						<Card.Body>
							<MissingEntities centered>
								<Icon name="file-generic" />
								<p>{t('No submission for the contact found')}</p>
							</MissingEntities>
						</Card.Body>
					</CardAnatomy>
				)}
			</Column>
		</Fragment>
	);
};

export default ContactFormSubmissions;
