import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import { useCurrentAccount } from 'pkg/identity';
import * as models from 'pkg/api/models';

import SectionTitle from 'components/SectionTitle';
import SectionDescription from 'components/SectionDescription';
import Connection from 'components/connection';
import Badge from 'components/Badge';
import Avatar from 'components/avatar';

import Column from 'components/layout/column';
import { useJoinModalContext } from 'components/group/join-modal';

export default function Connected(): JSX.Element {
	const joinContext = useJoinModalContext();
	const currentAccount = useCurrentAccount();

	const isSelf =
		!joinContext.targetAccount ||
		currentAccount.id === joinContext.targetAccount?.id;

	const isPending =
		joinContext.groupRole === models.membership.MembershipRole.Staff;

	const account = isSelf ? currentAccount : joinContext.targetAccount;

	const groupName =
		joinContext.group?.name || joinContext.connectionInfo?.groupName;

	const groupBadgeUrl =
		joinContext.group?.profileImageUrl ||
		joinContext.connectionInfo?.profileImageUrl ||
		'';

	let title = t('Welcome to {group}, {name}!', {
		group: groupName,
		name: account.firstName,
	});

	let description = t('You are now a member of {group}', {
		group: groupName,
	});

	if (!isSelf) {
		title = t('A warm welcome to {name}!', {
			name: account.firstName,
		});

		description = t(
			'{name} is now a member of {group}. As a parent, you also have access to their group.',
			{
				group: groupName,
				name: account.firstName,
			}
		);
	}

	if (isPending) {
		title = t('Almost there {name}!', { name: account.firstName });

		description = t(
			'You have requested to join {group} and an existing admin must approve your request before you get access. You will receive a notification when you have been approved.',
			{
				group: groupName,
			}
		);
	}

	return (
		<Column
			spacing={styles.spacing._8}
			blockSpacing={styles.spacing._8}
			inlineSpacing={styles.spacing._8}>
			<Connection
				pending={isPending}
				leftComponent={<Avatar account={account} />}
				rightComponent={<Badge badgeUrl={groupBadgeUrl} />}
			/>

			<Column spacing={styles.spacing._2}>
				<SectionTitle medium centered>
					{title}
				</SectionTitle>
				<SectionDescription centered>{description}</SectionDescription>
			</Column>
		</Column>
	);
}
