import { Fragment, memo } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';

import * as Card from 'components/Card';
import Icon from 'components/icon';

import * as iconStyles from 'components/icon/styles.css';
import AvatarStack from 'components/avatar/avatar-stack';

const Wrapper = styled.div`
	padding: 0 var(--spacing-5);
	height: 33px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	font-size: var(--font-size-sm);
	font-weight: var(--font-weight-normal);
	color: var(--palette-gray-500);

	strong {
		font-weight: var(--font-weight-bold);
	}

	.${iconStyles.icon} {
		font-size: var(--font-size-xl);
	}
`;

const LikeCount = styled.div`
	cursor: pointer;
	margin-right: auto;

	strong {
		margin-right: var(--spacing-2);
	}
`;

const CommentCount = styled.div`
	text-align: right;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	margin-left: var(--spacing-5);

	.${iconStyles.icon} {
		margin-right: var(--spacing-1);
	}
`;

const ViewCount = styled.div`
	display: grid;
	grid-auto-columns: auto;
	grid-auto-flow: column;
	align-items: center;
	grid-gap: var(--spacing-2);
	cursor: pointer;
`;

interface ImpressionsProps {
	commentCount?: number;
	likeCount?: number;
	likeUsers?: models.user.User[];
	viewCount?: number;
	showLikes?: () => void;
	showSeenBy?: () => void;
}

const Impressions = memo(
	({
		commentCount,
		likeCount,
		likeUsers,
		showLikes,
		showSeenBy,
		viewCount,
	}: ImpressionsProps): JSX.Element => {
		if (commentCount === 0 && likeCount === 0 && viewCount === 0) {
			return null;
		}

		return (
			<Fragment>
				<Wrapper>
					<LikeCount>
						{likeCount > 0 && (
							<AvatarStack
								size={20}
								users={likeUsers}
								onClick={showLikes}
								showRest={false}
								renderRestWith={
									<Fragment>
										<strong>{likeCount}</strong>
										{likeCount === 1 ? t('Like') : t('Likes')}
									</Fragment>
								}
							/>
						)}
					</LikeCount>
					{viewCount > 0 && (
						<ViewCount onClick={showSeenBy}>
							<Icon name="eye-visible" />
							{t(`Seen by`)}
							<strong>{viewCount}</strong>
						</ViewCount>
					)}
					{commentCount > 0 && (
						<CommentCount>
							<Icon name="comment" />
							<strong>{commentCount}</strong>
						</CommentCount>
					)}
				</Wrapper>
				<Card.Divider />
			</Fragment>
		);
	}
);

export default Impressions;
