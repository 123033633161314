import { ReactNode } from 'react';

export const symbol_downhill_skiing: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M741-40q-24 0-48-4t-47-11L99-254q-8-3-11.5-10.5T87-280q3-8 10.5-11.5t15.5-.5l270 98 70-181-159-166q-23-26-18-59t34-50l155-90q14-8 30-8.5t30 4.5q14 5 25.5 16t16.5 26l13 43q17 54 49.5 87t79.5 49l15-46q2-8 9-11.5t15-1.5q8 2 12 9.5t2 15.5l-33 102q-68-14-119-54t-78-108l-132 76 112 131q5 6 7 14.5t-1 16.5l-79 205 143 52 94-292q10 3 19 5.5t18 4.5l-94 296 41 14q19 7 39 10.5t42 3.5q24 0 46-3.5T830-95q8-3 16-.5T856-85q3 9-1.5 17T841-57q-24 8-49 12.5T741-40Zm-73-674q-30 0-51.5-21.5T595-787q0-30 21.5-51.5T668-860q30 0 51.5 21.5T741-787q0 30-21.5 51.5T668-714Z"
		/>
	</svg>
);
