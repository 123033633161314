import { ReactNode } from 'react';

export const symbol_unknown_med: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M130-450q-20.833 0-35.417-14.618Q80-479.235 80-500.118 80-521 94.583-535.5 109.167-550 130-550h260q20.833 0 35.417 14.618Q440-520.765 440-499.882 440-479 425.417-464.5 410.833-450 390-450H130Zm440 0q-20.833 0-35.417-14.618Q520-479.235 520-500.118 520-521 534.583-535.5 549.167-550 570-550h260q20.833 0 35.417 14.618Q880-520.765 880-499.882 880-479 865.417-464.5 850.833-450 830-450H570Z"
		/>
	</svg>
);
