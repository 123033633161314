export default {
	name: 'nav-dialogue',
	objects: [
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M4.83566 4.02225C3.73109 4.02225 2.83566 4.91768 2.83566 6.02225V19.4094C2.83566 20.5139 3.73109 21.4094 4.83566 21.4094H9.02768V20.1094H4.83566C4.44906 20.1094 4.13566 19.796 4.13566 19.4094V6.02225C4.13566 5.63565 4.44906 5.32225 4.83566 5.32225H15.3884C15.775 5.32225 16.0884 5.63565 16.0884 6.02225V8.57595H17.3884V6.02225C17.3884 4.91768 16.493 4.02225 15.3884 4.02225L12.7089 4.02225V3.28574C12.7089 3.193 12.6669 3.11009 12.6008 3.05506C12.5488 3.01178 12.4819 2.98574 12.4089 2.98574H11.3008V2.50093C11.3008 2.33524 11.1664 2.20093 11.0008 2.20093H9.22332C9.05763 2.20093 8.92332 2.33524 8.92332 2.50093V2.98574H7.81511C7.74217 2.98574 7.67532 3.01177 7.62332 3.05504C7.5572 3.11007 7.51511 3.19299 7.51511 3.28574V4.02225L4.83566 4.02225Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M13.8533 7.99403C13.8533 8.31542 13.5928 8.57595 13.2714 8.57595H8.49839C8.17701 8.57595 7.91647 8.31542 7.91647 7.99403C7.91647 7.67264 8.17701 7.41211 8.49839 7.41211H13.2714C13.5928 7.41211 13.8533 7.67264 13.8533 7.99403ZM12.1303 10.4762C12.1303 10.7976 11.8697 11.0581 11.5484 11.0581H8.49839C8.17701 11.0581 7.91647 10.7976 7.91647 10.4762C7.91647 10.1548 8.17701 9.8943 8.49839 9.8943H11.5484C11.8697 9.8943 12.1303 10.1548 12.1303 10.4762ZM11.1507 12.9584C11.1507 13.2798 10.8902 13.5403 10.5688 13.5403H8.49839C8.17701 13.5403 7.91647 13.2798 7.91647 12.9584C7.91647 12.637 8.17701 12.3765 8.49839 12.3765H10.5688C10.8902 12.3765 11.1507 12.637 11.1507 12.9584Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M7.12414 7.99403C7.12414 8.31542 6.8636 8.57595 6.54222 8.57595C6.22083 8.57595 5.96029 8.31542 5.96029 7.99403C5.96029 7.67264 6.22083 7.41211 6.54222 7.41211C6.8636 7.41211 7.12414 7.67264 7.12414 7.99403Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M7.12414 10.4762C7.12414 10.7976 6.8636 11.0581 6.54222 11.0581C6.22083 11.0581 5.96029 10.7976 5.96029 10.4762C5.96029 10.1548 6.22083 9.89429 6.54222 9.89429C6.8636 9.89429 7.12414 10.1548 7.12414 10.4762Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M7.12414 12.9588C7.12414 13.2801 6.8636 13.5407 6.54222 13.5407C6.22083 13.5407 5.96029 13.2801 5.96029 12.9588C5.96029 12.6374 6.22083 12.3768 6.54222 12.3768C6.8636 12.3768 7.12414 12.6374 7.12414 12.9588Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M16.6691 15.5189C17.599 15.5189 18.3529 14.765 18.3529 13.8351C18.3529 12.9051 17.599 12.1513 16.6691 12.1513C15.7391 12.1513 14.9853 12.9051 14.9853 13.8351C14.9853 14.765 15.7391 15.5189 16.6691 15.5189ZM16.6691 16.8189C18.317 16.8189 19.6529 15.483 19.6529 13.8351C19.6529 12.1872 18.317 10.8513 16.6691 10.8513C15.0212 10.8513 13.6853 12.1872 13.6853 13.8351C13.6853 15.483 15.0212 16.8189 16.6691 16.8189Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M11.4259 21.4094C11.4259 19.1849 13.2292 17.3816 15.4537 17.3816H17.8845C20.1089 17.3816 21.9122 19.1849 21.9122 21.4094H20.6122C20.6122 19.9029 19.391 18.6816 17.8845 18.6816H15.4537C13.9472 18.6816 12.7259 19.9029 12.7259 21.4094H11.4259Z',
			},
		},
	],
};
