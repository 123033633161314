import { Fragment, ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';

import { useCurrentAccount } from 'pkg/identity';

import { ParentalConsentRequestForm } from 'components/page/parental-consent-guard/ParentalConsentRequestForm';

/**
 * Will render the parental consent form instead of the app if the account holder
 * is under aged.
 */
export function ParentalConsentGuard({ children }: { children: ReactNode }) {
	const account = useCurrentAccount();
	if (account.needsParentalConsent) {
		return (
			<ThemeProvider theme={{}}>
				<ParentalConsentRequestForm />
			</ThemeProvider>
		);
	}

	return <Fragment>{children}</Fragment>;
}
