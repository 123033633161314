import { ReactNode } from 'react';

export const symbol_light_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-80q-34 0-56.5-23T399-161h162q-2 35-24.5 58T480-80Zm0-757q-58 0-106 25t-82 67l-42-42q41-48 100.5-79T480-897q122 0 211 89t89 211q0 72-29.5 130T670-368l-43-43q44-34 68.5-82.5T720-597q0-99-70.5-169.5T480-837Zm42 493H323q-68-45-105.5-108.5T180-597q0-21 3-41t8-37L78-788q-9-9-9-21.5t9-21.5q9-9 21.5-9t21.5 9l711 711q9 9 9 21t-9 21q-9 9-21.5 9T789-78L522-344Zm-177-60h118L242-625q-2 6-2 13v15q0 57 27 107.5t78 85.5Zm-1-119Zm114-56ZM348-223q-13 0-21.5-8.5T318-253q0-13 8.5-21.5T348-283h264q13 0 21.5 8.5T642-253q0 13-8.5 21.5T612-223H348Z"
		/>
	</svg>
);
