export default {
	name: 'collection',
	objects: [
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M16.2959 6C16.2959 4.89543 15.4005 4 14.2959 4H6C4.89543 4 4 4.89543 4 6V14.2959C4 15.4005 4.89543 16.2959 6 16.2959H14.2959C15.4005 16.2959 16.2959 15.4005 16.2959 14.2959V6ZM14.2959 5.3C14.6825 5.3 14.9959 5.6134 14.9959 6V14.2959C14.9959 14.6825 14.6825 14.9959 14.2959 14.9959H6C5.6134 14.9959 5.3 14.6825 5.3 14.2959V6C5.3 5.6134 5.6134 5.3 6 5.3H14.2959Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M9.36053 19.1271C8.3424 19.1271 7.50196 18.3664 7.37665 17.3823H8.70849C8.81048 17.6427 9.06396 17.8271 9.36053 17.8271H17.6564C18.043 17.8271 18.3564 17.5137 18.3564 17.1271V8.83121C18.3564 8.44461 18.043 8.13124 17.6564 8.13124H17.633V6.83125H17.6564C18.761 6.83125 19.6564 7.72668 19.6564 8.83121V17.1271C19.6564 18.2317 18.761 19.1271 17.6564 19.1271H9.36053Z',
			},
		},
	],
};
