import { ReactNode } from 'react';

export const symbol_screen_rotation_up: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M604-160H310q-28.875 0-49.438-20.562Q240-201.125 240-230v-344q0-13.5 9.5-21.75t20.652-8.25Q282-604 291-595.219q9 8.78 9 21.219v354h304l-62-62q-5-5-7-10.5t-2-11q0-5.5 2-10.5t6.545-10q5.455-5 10.778-7 5.322-2 11-2Q569-333 574-331t10 7l114 113q5 5 7 10.091 2 5.091 2 10.909 0 5-2 10.5t-7 10.5L584-56q-5 5-10 7t-10.677 2q-5.678 0-11-2Q547-51 542-56t-7-10.5q-2-5.5-2-11t2-10.5q2-5 7-10l62-62Zm116-570v344q0 13.5-9.5 21.75T689.849-356Q678-356 669-364.78q-9-8.781-9-21.22v-354H355l62 62q9 9 9 21.158 0 12.158-8.609 21Q408-627 395.9-627t-20.9-9L261-749q-5-5-7-10.5t-2-11q0-5.5 2-10.5t7-10l114-113q9-8 21-8.5t21.391 8.5Q426-895 426-883t-9 21l-62 62h295q28.875 0 49.438 20.562Q720-758.875 720-730Z"
		/>
	</svg>
);
