import { ReactNode } from 'react';

export const symbol_cardio_load: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m440-159-58-53q-94-86-152.5-145.5T138-462q-33-45-45-83.123Q81-583.246 81-625q0-91 61-153t149-62q57 0 106 26.5t84 77.5q36-51 84-77.5T670-840q88 0 149 62t61 153q0 41.754-12 79.877T823-462q-33 45-91.5 104.5T579-212l-57 53q-9 8-19.75 12t-21.5 4Q470-143 459-147t-19-12ZM156-550h650q8-20 11-37.5t3-37.5q0-66.906-42.5-110.953T670.333-780q-47.649 0-88.491 26.5T514-679q-5 9-14.032 13.5Q490.935-661 481-661q-11 0-20-4.5T447-679q-27-48-67.842-74.5T290.667-780Q226-780 183.5-735.953T141-625q0 20 3.5 37.5T156-550Zm105 140h440q22-23 39-43t30-37H192q13 17 29.5 37t39.5 43Zm220 210q49-45 88.5-81.5T641-350H321q32 32 71.5 68.5T481-200Zm0-350Z"
		/>
	</svg>
);
