import { ReactNode } from 'react';

export const symbol_view_list: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M120-260v-440q0-24.75 17.625-42.375T180-760h600q24.75 0 42.375 17.625T840-700v440q0 24.75-17.625 42.375T780-200H180q-24.75 0-42.375-17.625T120-260Zm60-333h106v-107H180v107Zm166 0h434v-107H346v107Zm0 166h434v-106H346v106Zm0 167h434v-107H346v107Zm-166 0h106v-107H180v107Zm0-167h106v-106H180v106Z"
		/>
	</svg>
);
