import { ReactNode } from 'react';

export const symbol_spatial_tracking: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-441q-66 0-108-42t-42-108q0-66 42-108t108-42q66 0 108 42t42 108q0 66-42 108t-108 42ZM160-214q0-38 19-64.5t49-41.5q51-26 120.5-43T480-380q62 0 131 17t120 43q30 15 49.5 41.5T800-214v34q0 24.75-17.625 42.375T740-120H220q-24.75 0-42.375-17.625T160-180v-34Zm60 34h520v-34q0-16.31-8.5-29.655Q723-257 707-266q-48-27-109-40.5T480-320q-57 0-118.5 14.5T252-266q-14 7-23 21.5t-9 30.5v34Zm260-321q39 0 64.5-25.5T570-591q0-39-25.5-64.5T480-681q-39 0-64.5 25.5T390-591q0 39 25.5 64.5T480-501ZM137.875-820.419q-19.25 19.388-44.625 30.403Q67.875-779 39-779q-12.75 0-21.375-8.675Q9-796.351 9-809.175 9-822 17.625-830.5T39-839q17 0 31-6.581T95-863.3q11-11.138 17-25.312 6-14.175 6-31.388 0-12.854 9.103-21.927 9.104-9.073 22-9.073 12.897 0 21.397 9.073Q179-932.854 179-920q0 29.081-10.938 54.637-10.937 25.557-30.187 44.944ZM238-721q-38 38-89.027 60Q97.945-639 39-639q-12.75 0-21.375-8.675Q9-656.351 9-669.175 9-682 17.625-690.5T39-699q46 0 86.21-17.394Q165.42-733.789 195-764q30-30 47-70t17-86q0-12.854 9.103-21.927 9.104-9.073 22-9.073 12.897 0 21.397 9.073Q320-932.854 320-920q0 58.066-22 109.533T238-721Zm483 0q-38-38-60-89t-22-110q0-12.854 9.103-21.927 9.104-9.073 22-9.073 12.897 0 21.397 9.073Q700-932.854 700-920q0 45.882 17.127 85.8T764-764q30.25 29.746 70.125 46.873T920-699q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T920-639q-58.945 0-109.973-22Q759-683 721-721Zm100.037-100.125q-19.267-19.25-30.214-44.625T779-920q0-12.854 9.103-21.927 9.104-9.073 22-9.073 12.897 0 21.397 9.073Q840-932.854 840-920q0 17 6.5 31t17.5 25q11 11 25 17.5t31 6.5q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T920-780q-28.901 0-54.298-10.938-25.398-10.937-44.665-30.187ZM480-591Zm0 411Z"
		/>
	</svg>
);
