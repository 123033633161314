import { ReactNode, useState } from 'react';
import styled, { css } from 'styled-components';

import Account from 'pkg/models/account';

import Icon from 'components/icon';
import Avatar from 'components/avatar';

const Wrapper = styled.div<{ isCollapsed?: boolean }>`
	margin-top: 10px;
	display: grid;
	grid-auto-flow: row;
	row-gap: var(--spacing-3);

	&:first-of-type {
		margin-top: 0;
	}

	&:not(:last-of-type) {
		padding-bottom: var(--spacing-5);
		border-bottom: 1px solid var(--palette-gray-800);
	}

	${({ isCollapsed }) =>
		isCollapsed &&
		css`
			&:not(:last-of-type) {
				padding-bottom: var(--spacing-4);
			}
		`}
`;

const Container = styled(Wrapper)`
	margin: 0;
	padding: 0;
	border: none;
`;

const Header = styled.div`
	display: grid;
	grid-template-columns: 1fr auto;
	grid-template-rows: repeat(2, auto);
	gap: var(--spacing-1);
	column-gap: var(--spacing-3);
	user-select: none;
	align-items: center;
	cursor: pointer;
`;

const HeaderWithButton = styled.div`
	display: grid;
	grid-template-columns: 35px auto;
	gap: var(--spacing-4);
	align-items: center;
`;

const Title = styled.div<{ isHighlightGroup?: boolean }>`
	font-size: var(--font-size-base);
	font-weight: var(--font-weight-semibold);
	color: var(--palette-white);
	grid-area: 1 / 1 / 2 / 2;
	display: grid;
	grid-auto-flow: column;
	gap: var(--spacing-2);
	align-items: center;
	justify-content: start;
	align-content: start;
	justify-items: start;

	span {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		display: inline-block;
		width: 100%;
	}

	${({ isHighlightGroup }) =>
		isHighlightGroup &&
		css`
			color: var(--palette-yellow-500);
		`}

	${Avatar} {
		margin-right: 2px;
	}
`;

const SubTitle = styled.div`
	font-size: var(--font-size-sm);
	color: var(--palette-gray-500);
	grid-area: 2 / 1 / 3 / 2;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const Chevron = styled(Icon)<{ isCollapsed: boolean }>`
	grid-area: 1 / 2 / 3 / 3;
	color: var(--palette-white);
	justify-self: end;
	font-size: var(--font-size-2xl);
	transform: rotate(0deg);
	transition: transform 150ms ease-in-out;

	${({ isCollapsed }) =>
		!isCollapsed &&
		css`
			transform: rotate(90deg);
		`}
`;

interface GroupProps {
	title?: string;
	subTitle?: string;
	isCollapsible?: boolean;
	isHighlightGroup?: boolean;
	defaultCollapsed?: boolean;
	account?: Account;
	headerButton?: ReactNode;
	children?: ReactNode | ReactNode[];
}

export default function Group({
	title,
	subTitle,
	isCollapsible,
	isHighlightGroup,
	defaultCollapsed = false,
	account,
	headerButton,
	children,
}: GroupProps): JSX.Element {
	const [isCollapsed, setCollapsed] = useState<boolean>(defaultCollapsed);

	const toggleCollapsed = () => {
		if (isCollapsible) {
			setCollapsed(!isCollapsed);
		}
	};

	const header = (
		<Header onClick={toggleCollapsed}>
			<Title isHighlightGroup={isHighlightGroup}>
				{isHighlightGroup && <Icon name="star" />}
				{account && <Avatar size={16} account={account} />}
				<span>{title}</span>
			</Title>
			{subTitle && <SubTitle>{subTitle}</SubTitle>}
			{isCollapsible && <Chevron name="chevron" isCollapsed={isCollapsed} />}
		</Header>
	);

	const headerWithButton = (
		<HeaderWithButton>
			{headerButton}
			{header}
		</HeaderWithButton>
	);

	return (
		<Wrapper isCollapsed={isCollapsed}>
			{title && (headerButton ? headerWithButton : header)}
			{!isCollapsed && <Container>{children}</Container>}
		</Wrapper>
	);
}
