import { ReactNode } from 'react';

export const symbol_snowmobile: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M80-230h240q38 0 64-28.494 26-28.493 26-68.506l-121-37L76-250q-3 2-4.5 4.5T70-240q0 4 3 7t7 3Zm331-160h332q31.737 0 49.368-10Q810-410 810-434L622-609 460-487l-339-38-21 41 311 94Zm44-110Zm485 260q0 5.667-2 11.333Q936-223 931-218q-16 21-39.707 34.5T840-170H640q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T640-230h101L641-330H470q0 66-43.5 113T320-170H80q-33.25 0-51.625-21.75T10-239.6q0-17.4 9-33.9T47-301l161-88-142-42q-20-6-29-25t1-38l34-67q5.902-13.281 18.443-20.141Q102.984-588 117-586l326 36 135-100-44-40h-54q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T480-750h54q11 0 22 4t19 12l265 246q17 16 23.5 30.505 6.5 14.506 6.5 27.693Q870-392 842.5-364 815-336 764-331l-41 2 106 99h11q12.857 0 24.429-5.5Q876-241 882-252q4-8 11.479-13 7.478-5 16.138-5 13.383 0 21.883 8.625T940-240Z"
		/>
	</svg>
);
