export default {
	name: 'user-add',
	objects: [
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M9.41365 12.4967C10.682 12.4967 11.7102 11.4685 11.7102 10.2001C11.7102 8.93174 10.682 7.90352 9.41365 7.90352C8.14528 7.90352 7.11706 8.93174 7.11706 10.2001C7.11706 11.4685 8.14528 12.4967 9.41365 12.4967ZM9.41365 13.7967C11.4 13.7967 13.0102 12.1865 13.0102 10.2001C13.0102 8.21377 11.4 6.60352 9.41365 6.60352C7.42731 6.60352 5.81706 8.21377 5.81706 10.2001C5.81706 12.1865 7.42731 13.7967 9.41365 13.7967Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M3.22725 19.3299C3.22725 16.7223 5.3411 14.6085 7.94866 14.6085H10.8787C13.4862 14.6085 15.6001 16.7223 15.6001 19.3299H14.3001C14.3001 17.4403 12.7683 15.9085 10.8787 15.9085H7.94866C6.05907 15.9085 4.52725 17.4403 4.52725 19.3299H3.22725Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M18.0961 9.90521C18.0961 9.54623 17.8051 9.25521 17.4461 9.25521C17.0871 9.25521 16.7961 9.54623 16.7961 9.90521V11.9318H14.7695C14.4105 11.9318 14.1195 12.2229 14.1195 12.5818C14.1195 12.9408 14.4105 13.2318 14.7695 13.2318H16.7961V15.2585C16.7961 15.6174 17.0871 15.9085 17.4461 15.9085C17.8051 15.9085 18.0961 15.6174 18.0961 15.2585V13.2318H20.1227C20.4817 13.2318 20.7727 12.9408 20.7727 12.5818C20.7727 12.2229 20.4817 11.9318 20.1227 11.9318H18.0961V9.90521Z',
			},
		},
	],
};
