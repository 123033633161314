import { ReactNode } from 'react';

export const symbol_globe_asia: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M442-144q-35.062 0-60.031-24.5T357-228.044q0-35.045 24.969-60Q406.938-313 442-313l24.75-23.742Q475-345 486.156-350q11.156-5 22.844-5 25 0 42 17.5t17 42.225V-277q0 20 14.5 34.5T617-228q15.804 0 28.446-9.554 12.643-9.554 17.911-24.415L676-297q9-26 30.039-42.025 21.039-16.026 48.39-16.026Q766-384 772.5-416q6.5-32 6.5-65 0-94-46.5-170.5T610-773v39q0 34.65-24.675 59.325Q560.65-650 526-650h-42v85q0 17-12.5 29.5T442-523h-42v71.022Q400-429 384.135-413 368.269-397 345-397q-14 0-26.5-6.5T298-421l-67.077-102H189v41.69q0 32.31-21.5 55.81T115-398q28 109 118.749 181.5T442-144Zm84.459-253Q509-397 496.5-409.5T484-439q0-17 12.5-29.5t29.959-12.5h41.082Q585-481 597.5-468.5T610-439q0 17-12.5 29.5T567.541-397h-41.082Zm97.926-126Q604-523 591-539.5q-13-16.5-6.429-36.405L601-622q5-13 15.192-20.5 10.193-7.5 22.423-7.5Q659-650 672-633.5q13 16.5 6.429 36.405L662-551q-5 13-15.192 20.5-10.193 7.5-22.423 7.5ZM442.266-80q-82.734 0-155.5-31.5t-127.266-86Q105-252 73.5-324.841 42-397.681 42-480.5q0-82.819 31.5-155.659Q105-709 159.5-763t127.341-85.5Q359.681-880 442.5-880q82.819 0 155.659 31.5Q671-817 725-763t85.5 127Q842-563 842-480.266q0 82.734-31.5 155.5T725-197.684q-54 54.316-127 86Q525-80 442.266-80Z"
		/>
	</svg>
);
