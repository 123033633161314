import { t } from '@transifex/native';
import * as React from 'react';
import styled from 'styled-components';

import User from 'pkg/models/user';

import DateTime from 'pkg/datetime';
import * as routes from 'pkg/router/routes';
import { useCurrentOrganization } from 'pkg/identity';

import CardAnatomy, * as Card from 'components/Card';
import MissingEntities from 'components/missing-entities';
import Icon from 'components/icon';

import ColorHints from 'components/user/profile/graph/ColorHints';
import HeightBar from 'components/user/profile/graph/Bar';

import Button from 'design/button';

const Wrapper = styled(Card.Body)`
	height: 400px;
	padding: var(--spacing-5);
`;

const Growth = styled.p`
	color: var(--palette-gray-600);
	font-weight: var(--font-weight-thin);
	text-align: center;
`;

type HeightProps = { user: User; isSelf: boolean };

const Height: React.FC<React.PropsWithChildren<HeightProps>> = ({
	user,
	isSelf,
}) => {
	const { meta } = user;
	const org = useCurrentOrganization();

	const userHeight: number = Number.parseInt(meta.get('height') || '0', 10);

	const estimatedHeight: number = user.getEstimatedHeight();

	if (!userHeight || !estimatedHeight) {
		return (
			<CardAnatomy $noBorder>
				<Wrapper>
					<MissingEntities centered>
						<Icon name="user" />
						<p>{t(`No height information added`)}</p>

						{isSelf && (
							<Button
								icon="add"
								secondary
								href={routes.User.Settings(org.id, user.id)}>
								{t(`Add information`)}
							</Button>
						)}
					</MissingEntities>
				</Wrapper>
			</CardAnatomy>
		);
	}

	return (
		<CardAnatomy $noBorder>
			<Wrapper>
				<HeightBar currentValue={userHeight} estimatedValue={estimatedHeight} />
				<ColorHints primary={t(`Your height`)} secondary={t(`Estimated`)} />
				<Growth>
					{t(`You have {value} to grow`, {
						value: new Intl.NumberFormat(DateTime.getLocale(), {
							style: 'unit',
							unit: 'centimeter',
						}).format(estimatedHeight - userHeight),
					})}
				</Growth>
			</Wrapper>
		</CardAnatomy>
	);
};

export default Height;
