import { ReactNode } from 'react';

export const symbol_quickreply: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M240-240 131-131q-14 14-32.5 6.344T80-152v-668q0-24 18-42t42-18h680q24 0 42 18t18 42v250q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T820-570v-250H140v600l74-80h402q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T616-240H240Zm526 0h-30.175Q723-240 714.5-248.625T706-270v-180q0-12.75 8.625-21.375T736-480h84q11 0 18 9.5t3 20.5l-48 140h53q12 0 19 10t2 21L780-74q-2 6-8 4.5t-6-7.5v-163Zm-626-60v-520 520Z"
		/>
	</svg>
);
