import { ReactNode } from 'react';

export const symbol_oncology: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M120-320q0-45.833 32.083-77.917Q184.167-430 230-430h80q75 0 102.5-33t27.5-77q0-32-17.25-51T380-610q-24.75 0-42.375-17.625T320-670v-180q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T380-850v180h-1 1q50 0 85 36.5t35 93.5q0 69-45 119.5T310-370h-80q-21.25 0-35.625 14.375T180-320v210q0 12.75-8.675 21.375Q162.649-80 149.825-80 137-80 128.5-88.625T120-110v-210Zm260-350v-210 210h-1 1q50 0 85 36.5t35 93.5q0 69-45 119.5T310-370h-80q-21.25 0-35.625 14.375T180-320v240-240q0-21.25 14.375-35.625T230-370h80q100 0 145-50.5T500-540q0-57-35-93.5T380-670h-1 1Zm460 225q-14-11-28.603-21.016Q796.794-476.032 780-483v-37q0-88-66-149t-154-61q-24.75 0-42.375-17.625T500-790v-60q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T560-850v60q112 0 196 79t84 191v75ZM360-160v50q0 12.75-8.675 21.375Q342.649-80 329.825-80 317-80 308.5-88.625T300-110v-50q0-45.833 32.083-77.917Q364.167-270 410-270h50q0 16 2 30.915 2 14.916 6 29.085h-58q-21.25 0-35.625 14.375T360-160Zm330-21q38 0 64-26t26-64q0-38-26-64t-64-26q-38 0-64 26t-26 64q0 38 26 64t64 26Zm0 60q-63 0-106.5-43.5T540-271q0-63 43.5-106.5T690-421q63 0 106.5 43.5T840-271q0 24.078-7 45.539Q826-204 813-186l78 78q9 9 9 21t-9 21q-9 9-21 9t-21-9l-78-78q-17 11-37.318 17-20.317 6-43.682 6Z"
		/>
	</svg>
);
