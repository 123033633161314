import { ReactNode } from 'react';

export const symbol_screen_rotation_alt: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M501-90 180-410q-5-5-7-10.5t-2-10.5q0-12.098 8.25-21.549Q187.5-462 201-462q6 0 11.5 2t10.5 7l321 321 246-246H682q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T682-438h180q12.75 0 21.375 8.625T892-408v180q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T832-228v-108L586-90q-8.211 8.13-19.035 12.565Q556.141-73 544.197-73 533-73 521.571-77.435 510.143-81.87 501-90ZM98-522q-12.75 0-21.375-8.625T68-552v-180q0-12.75 8.675-21.375Q85.351-762 98.175-762q12.825 0 21.325 8.625T128-732v108l246-246q9.041-8.87 20.342-12.935Q405.644-887 416.945-887q12.055 0 22.93 4.065T459-870l321 320q5 5 7 10.5t2 10.5q0 12.098-8.25 21.549Q772.5-498 759-498q-6 0-11.5-2t-10.5-7L416-828 170-582h108q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T278-522H98Z"
		/>
	</svg>
);
