const location = {
	name: 'location',
	objects: [
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M13.9104 16.1767C15.3802 14.0368 16.6641 11.5054 16.6641 9.35341C16.6641 6.77751 14.5759 4.68932 12 4.68932C9.42407 4.68932 7.33589 6.77751 7.33589 9.35341C7.33589 11.4893 8.61895 14.0201 10.0901 16.1659C10.7793 17.171 11.4708 18.0365 12.002 18.6628C12.5326 18.0393 13.2226 17.178 13.9104 16.1767ZM12.8738 19.6433C14.5305 17.7191 17.9641 13.2598 17.9641 9.35341C17.9641 6.05954 15.2939 3.38932 12 3.38932C8.7061 3.38932 6.03589 6.05954 6.03589 9.35341C6.03589 13.2377 9.47225 17.7113 11.1282 19.6415C11.6538 20.2542 12 20.6107 12 20.6107C12 20.6107 12.3471 20.2551 12.8738 19.6433Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M14.4719 9.42287C14.4719 10.7881 13.3652 11.8948 12 11.8948C10.6348 11.8948 9.52808 10.7881 9.52808 9.42287C9.52808 8.05768 10.6348 6.95097 12 6.95097C13.3652 6.95097 14.4719 8.05768 14.4719 9.42287Z',
			},
		},
	],
};

export default location;
