import { ReactNode } from 'react';

export const symbol_metabolism: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M399-247v-140h-59v-143q0-21 28.5-34t74.5-13q46 0 74.5 13t28.5 34v143.493h-59V-247h-88Zm44-359q-23.4 0-38.7-15.3Q389-636.6 389-660q0-23.4 15.3-38.7Q419.6-714 443-714q23.4 0 38.7 15.3Q497-683.4 497-660q0 23.4-15.3 38.7Q466.4-606 443-606Zm0 490q-151.98 0-257.99-106.113Q79-328.226 79-479.816q0-75.795 28.5-141.99Q136-688 185.5-737.5t115.636-78Q367.272-844 443-844q75.728 0 141.864 28.5Q651-787 700.5-737.5t78 115.636Q807-555.728 807-480v18l49-49q5-5 10-7t11-2q6 0 11.455 2.211 5.454 2.21 9.818 6.631 8.727 8.842 8.727 21T898-469L798-369q-9 9-21 9t-21-9L656-469q-9-9-9-21t9-21.391Q665-520 677-520t21 9l49 49v-18q0-125-89.5-214.5T443-784q-125 0-214.5 89.5T139-480q0 125 89.5 214.5T443-176q49 0 96-16t85-45q11-8 22.5-7.5T667-235q9 9 9 21.5T666-193q-46 35-104.5 56T443-116Z"
		/>
	</svg>
);
