import { ReactNode } from 'react';

export const symbol_local_mall: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-80q-24.75 0-42.375-17.625T120-140v-530q0-24.75 17.625-42.375T180-730h110q0-78 53.5-134T475-920q80.925 0 137.963 55Q670-810 670-730h110q24.75 0 42.375 17.625T840-670v530q0 24.75-17.625 42.375T780-80H180Zm0-60h600v-530H180v530Zm170-590h260q0-55-37.5-92.5T480-860q-55 0-92.5 37.5T350-730ZM180-140v-530 530Zm299.724-290q73.724 0 133.5-51.5Q673-533 673-597q0-11-9-19.5t-22.151-8.5q-10.83 0-19.34 7.5Q614-610 612-598q-10 47-47.5 77.5T480-490q-47 0-84.5-30.5T348-598q-2-12-11-19.5t-20-7.5q-12 0-21 8.5t-9 19.5q0 64.069 59.5 115.535Q406-430 479.724-430Z"
		/>
	</svg>
);
