import { ReactNode } from 'react';

export const symbol_move_selection_up: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M300-400q-24.75 0-42.375-17.625T240-460v-360q0-24.75 17.625-42.375T300-880h360q24.75 0 42.375 17.625T720-820v360q0 24.75-17.625 42.375T660-400H300Zm0-60h360v-360H300v360Zm-30.175 220Q257-240 248.5-248.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Zm0 160Q257-80 248.5-88.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5Q300-97 291.325-88.5q-8.676 8.5-21.5 8.5Zm210 0Q467-80 458.5-88.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5Q510-97 501.325-88.5q-8.676 8.5-21.5 8.5Zm210 0Q677-80 668.5-88.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5Q720-97 711.325-88.5q-8.676 8.5-21.5 8.5Zm0-160Q677-240 668.5-248.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5ZM480-640Z"
		/>
	</svg>
);
