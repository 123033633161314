import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import { CollectionResponse } from 'pkg/api/use_collection';
import { formatToCurrency } from 'pkg/i18n/formatters';
import DateTime from 'pkg/datetime';
import * as routes from 'pkg/router/routes';
import { useCurrentOrganization } from 'pkg/identity';

import Icon from 'components/icon';

import * as Table from 'design/table';

interface PayoutsTableProps {
	sort: {
		by: string;
		order: 'desc' | 'asc';
	};

	onSortClick: (field: string, sortBy: 'asc' | 'desc') => void;

	payoutsCollection: CollectionResponse<models.payout.Payout>;
}

interface PayoutRow {
	payout: models.payout.Payout;
}

function PayoutRow({ payout }: PayoutRow) {
	const org = useCurrentOrganization();

	const amount = formatToCurrency(payout.amount / 100, payout.currency);
	const netAmount = formatToCurrency(payout.netAmount / 100, payout.currency);
	const payoutLink = routes.Payout.Index(org.id, payout.id);

	return (
		<Table.Row>
			<Table.LinkCell href={payoutLink} linkStyle>
				{payout.paymentProviderId}
			</Table.LinkCell>
			<Table.LinkCell href={payoutLink}>{amount}</Table.LinkCell>
			<Table.LinkCell href={payoutLink}>{netAmount}</Table.LinkCell>
			<Table.LinkCell href={payoutLink}>
				{models.payout.getPayoutTypeTranslation(payout.type)}
			</Table.LinkCell>
			<Table.LinkCell href={payoutLink}>
				{DateTime.fromTimestamp(payout.paidOutAt).toLocaleDateString()}
			</Table.LinkCell>
			<Table.LinkCell href={payoutLink}>{payout.accountNumber}</Table.LinkCell>
		</Table.Row>
	);
}

export default function PayoutsTable({
	payoutsCollection,
	onSortClick,
	sort,
}: PayoutsTableProps) {
	const columns: Table.HeaderColumn[] = [
		{
			content: t('Payout number'),
		},
		{ content: t('Gross payout amount'), sortKey: 'amount' },
		{ content: t('Net payout amount'), sortKey: 'net_amount' },
		{ content: t('Type'), sortKey: 'type' },
		{ content: t('Date'), sortKey: 'paid_out' },
		{ content: t('Account number') },
	];

	return (
		<Table.Table
			onSort={onSortClick}
			sortBy={sort.by}
			sortOrder={sort.order}
			emptyState={{
				title: t('No payouts'),
				content: t('There has been not payouts yet'),
				image: (
					<Icon
						size={3.4}
						name="nav-payments"
						fill={styles.palette.gray[500]}
					/>
				),
			}}
			columns={columns}
			isLoading={payoutsCollection.isLoading}>
			{payoutsCollection.records.map((r) => (
				<PayoutRow key={r.id} payout={r} />
			))}
		</Table.Table>
	);
}
