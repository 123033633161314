import { ReactNode } from 'react';

export const symbol_concierge: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M430-80q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T430-140h460q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q920-97 911.375-88.5T890-80H430Zm10-120q0-81 51-141.5t129-74.25v-24.554Q620-457 631.5-468.5T660-480q17 0 28.5 11.5T700-440v24.359Q777-402 828.5-341.5 880-281 880-200H440Zm71-60h297q-18-44-58-72t-89.789-28Q609-360 569-332t-58 72Zm149 0ZM40-502v-274q0-24.75 17.625-42.375T100-836h98q23 0 40 17t17 40l294-84q8.171-2 17.086-2Q575-865 583-863l276 85q9.75 2.947 15.375 10.684Q880-759.579 880-750v25q0 37.5-29 63.75T782-635H668q0 32-17 59t-47 38l-245 92q-5 2-10.5 3t-10.5 1H100q-24.75 0-42.375-17.625T40-502Zm60-137v137h94v-274h-94v137Zm155 30v107h84l245-93q9-3 14.5-17t5.5-23h-77l-87 29q-11 4-22-1.5T402-624q-5-13.455.5-25.227Q408-661 421-665l98-30h263q14 0 25-9.75T818-728l-256-78-307 90v107Z"
		/>
	</svg>
);
