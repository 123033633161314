import { Component } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import * as palette from 'pkg/config/palette';

import AssessmentCreate from 'containers/user/assessment/Create';

import Backdrop from 'components/Backdrop';
import Modal from 'components/modal';

import SwiperBody from 'components/swiper/Swiper';
import Slide, { SlideImageWrapper, SlideText } from 'components/swiper/Slide';
import Assessment from 'components/rating/intro/Assessment';
import Assess from 'components/rating/intro/Assess';
import IndividualTraining from 'components/rating/intro/IndividualTraining';
import PositionMatch from 'components/rating/intro/PositionMatch';
import Compare from 'components/rating/intro/Compare';

const BlurredBackdrop = styled(Backdrop)`
	background: ${palette.white} url('${TS.assetUrl}/img/signup-bg.jpg') no-repeat
		center center / cover;
`;

const SlideHeading = styled.h1`
	font-size: 1.8rem;
	margin-bottom: 20px;
`;

const SlideParagraph = styled.p``;

class IntroModal extends Component {
	state = {
		currentSlide: '',
		showAssessment: false,
		forAccount: null,
	};

	componentDidMount() {
		const currentSlide = this.slides[0].slideId;
		this.setState({ currentSlide });
	}

	get slides() {
		if (this.props.isAdminOrStaff) {
			return [
				{
					slideId: 'assessment',
					title: t('Assessment'),
					description: t(
						`By assessing a player's skills - 360Player can match the player with their best positions and give them individual training priority.`
					),
					svg: Assessment,
				},
				{
					slideId: 'assess',
					title: t('How to assess'),
					description: t(
						'Estimate the player on each skill in the guide between 1 and 10. When assessing, imagine a 10 being the best in the age group.'
					),
					svg: Assess,
				},
				{
					slideId: 'positionMatch',
					title: t('PositionMatch'),
					description: t(
						`Based on your assessment 360Player will match the player with all the positions on the field. The best is highlighted with a star.`
					),
					svg: PositionMatch,
				},
				{
					slideId: 'individual-training',
					title: t('Individual training'),
					description: t(
						'The player will get individual training priority based on the position you choose.'
					),
					svg: IndividualTraining,
				},
				{
					slideId: 'compare',
					title: t('Compare'),
					description: t(
						'You can compare assessments from the player and other coaches to create an important dialogue for development.'
					),
					svg: Compare,
				},
			];
		}

		return [
			{
				slideId: 'assessment',
				title: t('Assessment'),
				description: t(
					'By assessing your skills - 360Player can match you with your best positions and give you individual training priority.'
				),
				svg: Assessment,
			},
			{
				slideId: 'positionMatch',
				title: t('PositionMatch'),
				description: t(
					'Based on your assessment 360Player will match you with all the positions on the field. Your best is highlighted with a star.'
				),
				svg: PositionMatch,
			},
			{
				slideId: 'individual-training',
				title: t('Individual training'),
				description: t(
					'You will get individual training priority based on the position you choose. You can find exercises in your profile.'
				),
				svg: IndividualTraining,
			},
		];
	}

	handleChangedSlide = (c) => {
		this.setState({ currentSlide: c });
	};

	handleOnComplete = () => this.setState({ showAssessment: true });

	renderSlide = (slide) => (
		<Slide
			key={`slide-${slide.slideId}`}
			id={slide.slideId}
			active={slide.slideId === this.state.currentSlide}>
			<SlideImageWrapper align={slide.align} flex={!!slide.flex}>
				<slide.svg active={slide.slideId === this.state.currentSlide} />
			</SlideImageWrapper>

			<SlideText>
				<SlideHeading>{slide.title}</SlideHeading>
				<SlideParagraph>{slide.description}</SlideParagraph>
			</SlideText>
		</Slide>
	);
	get modalBody() {
		if (this.state.showAssessment) {
			return (
				<AssessmentCreate
					groupId={this.props.groupId}
					userId={this.props.userId}
					onComplete={this.props.onClose}
					forAccount={this.props.forAccount}
				/>
			);
		}

		return (
			<SwiperBody
				slides={this.slides}
				activeSlide={this.state.currentSlide}
				onChangedSlide={this.handleChangedSlide}
				onComplete={this.handleOnComplete}
				completeText={t(`Ok, I get it - let's go`)}>
				{this.slides.map(this.renderSlide)}
			</SwiperBody>
		);
	}

	render() {
		return (
			<Modal
				hideClose={this.props.hideClose}
				onClose={this.props.onClose}
				customBackdrop={this.props.blurredBackground && <BlurredBackdrop />}>
				{this.modalBody}
			</Modal>
		);
	}
}

export default IntroModal;
