import { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import * as boxShadow from 'pkg/config/box-shadow';

import { pushState } from 'pkg/router/state';
import * as selectors from 'pkg/selectors';
import * as routes from 'pkg/router/routes';
import { useCurrentGroup, useCurrentOrganization } from 'pkg/identity';

import Tag from 'components/Tag';
import Heading from 'components/Heading';

import DeletedExercise from 'components/training/session/DeletedExercise';
import ExerciseInfo from 'components/training/library/ExerciseInfo';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	background-color: var(--palette-white);
	border-radius: var(--radius-5);
	position: relative;
	cursor: pointer;
	border: 1px solid var(--palette-gray-300);

	@media (hover: hover) {
		&:hover {
			box-shadow: ${boxShadow.cardHover};
		}
	}
`;

const Header = styled.div`
	position: relative;
`;

const HeaderImage = styled.img`
	border-radius: 0 0 10px 10px;
	object-fit: contain;
	width: 100%;
	max-height: 80vh;
	object-position: center center;
	background: var(--palette-gray-300);
	display: block;
`;

HeaderImage.defaultProps = {
	loading: 'lazy',
	'data-pin-nopin': 'true',
};

const Title = styled(Heading)`
	font-size: var(--font-size-base);
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
`;

const HeaderContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	height: 100%;
	padding: var(--spacing-5);
	position: absolute;
	left: 0;
	top: 0;
`;

const Actions = styled.div`
	display: flex;
	justify-content: flex-start;
`;

const Tags = styled.div`
	display: flex;
	flex-flow: wrap;
	align-items: center;
	gap: var(--spacing-2);

	span {
		font-size: 0.7rem;
		color: var(--palette-white);
	}
`;

const Body = styled.div`
	padding: var(--spacing-5);
	border-radius: 0 0 10px 10px;
`;

const Introduction = styled.div`
	padding-right: 5%;
	color: var(--palette-gray-600);
	font-size: var(--font-size-sm);
	line-height: 18px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;

	&:not(:last-child) {
		margin-bottom: var(--spacing-4);
	}
`;

const Item = memo(
	({ id, actions, onClick = null, noTagSearch = false, children }) => {
		const org = useCurrentOrganization();
		const groupId = useCurrentGroup().id;

		const tags = useSelector((state) =>
			selectors.exercises.findAllTags(state, id)
		);

		const item = useSelector((state) => selectors.exercises.find(state, id));

		const images = item.attachments?.filter(
			(attachment) => attachment.type === 'image'
		);

		if (!item.id) {
			return <DeletedExercise />;
		}

		const getCroppedTags = () => {
			const numTags = tags.size;
			if (numTags <= 3) return { display: tags };

			let display = tags.slice(0, 3);
			let restAmount = `+${numTags - display.size}`;

			return { display, restAmount };
		};

		const handleClick = (event) => {
			event.preventDefault();

			if (onClick) {
				onClick(item.id);
			} else {
				pushState(routes.Exercise.Show(org.id, item.id));
			}
		};

		return (
			<Wrapper>
				<Body>
					<Title onClick={handleClick}>{item.title}</Title>
					<ExerciseInfo item={item} onClick={handleClick} />
					{item.introduction && (
						<Introduction onClick={handleClick}>
							{item.introduction}
						</Introduction>
					)}
					{children}
				</Body>
				<Header>
					<HeaderImage
						src={
							item.thumbnail?.url ||
							images?.[0]?.url ||
							`${TS.assetUrl}/img/library-placeholder.svg`
						}
					/>
					<HeaderContent onClick={handleClick}>
						{actions !== null && <Actions>{actions}</Actions>}
						<Tags>
							{getCroppedTags().display.map(({ name }) =>
								noTagSearch ? (
									<Tag small key={name} name={name} />
								) : (
									<Tag
										small
										key={name}
										name={name}
										replace="training-planner.search"
										params={{
											tags: name,
											contentType: 'exercises',
											currentPage: 'all',
											groupId,
										}}
									/>
								)
							)}
							<span>{getCroppedTags()?.restAmount}</span>
						</Tags>
					</HeaderContent>
				</Header>
			</Wrapper>
		);
	}
);

Item.propTypes = {
	onClick: PropTypes.func,
};

Item.defaultProps = {
	actions: null,
};

export default Item;
