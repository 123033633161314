import { ReactNode } from 'react';

export const symbol_dry: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M230-80q-63 0-106.5-43.5T80-230v-216q0-28 13.5-53t36.5-40l304-196q11-8 21.5-4.5T474-728q15 15 17.5 35.5T483-654l-59 94h346q13 0 21.5 8.5T800-530q0 12-8.5 21t-21.5 9H370q-18 0-26.5-15.5t.5-30.5l63-101-244 159q-11 7-17 18t-6 24v216q0 37 26.5 63.5T230-140h500q13 0 21.5 8.5T760-110q0 12-8.5 21T730-80H230Zm620-280H510q-13 0-21.5-8.5T480-390q0-13 8.5-21.5T510-420h340q13 0 21.5 8.5T880-390q0 12-8.5 21t-21.5 9Zm-40 140H510q-13 0-21.5-8.5T480-250q0-13 8.5-21.5T510-280h300q13 0 21.5 8.5T840-250q0 12-8.5 21t-21.5 9ZM310-330Zm268-455q-20-26-29.5-53t-7.5-62q1-12 8.5-21t19.5-9q12 0 20 8.5t7 20.5q-2 26 9 49.5t38 60.5q20 26 29 52.5t7 58.5q-1 12-9 21t-20 9q-12 0-20-8.5t-8-20.5q0-28-10.5-52T578-785Zm160 0q-20-26-29.5-53t-7.5-62q1-12 8.5-21t19.5-9q12 0 20 8.5t7 20.5q-2 26 9 49.5t38 60.5q20 26 29 52.5t7 58.5q-1 12-9 21t-20 9q-12 0-20-8.5t-8-20.5q0-28-10.5-52T738-785Z"
		/>
	</svg>
);
