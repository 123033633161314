import * as React from 'react';

import * as models from 'pkg/api/models';

import Badge from 'components/Badge';
import Avatar from 'components/avatar';

import {
	Branding,
	Inner,
	Outer,
	Team,
	Title,
	UserInfo,
} from 'components/user/profile/overview/Header';

type HeaderProps = {
	user: models.user.User;
};

export function Header({ user }: HeaderProps): JSX.Element {
	return (
		<Outer>
			<Inner>
				<Branding badgeUrl={user.organization.profileImageUrl} />
				<Avatar user={user} size={100} />
				<UserInfo>
					<Title>{models.user.fullName(user)}</Title>
					<Team>
						<Badge badgeUrl={user.organization.profileImageUrl} size="30px" />
						<span>{user.organization.name}</span>
					</Team>
				</UserInfo>
			</Inner>
		</Outer>
	);
}
