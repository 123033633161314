// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bwTC6C9O64750XdCSsau {
	font-size: var(--font-size-lg);
	font-weight: var(--font-weight-semibold);
}

.CaA0zpoKnyXkUq_8SYR8 {
	font-size: var(--font-size-sm);
	color: var(--palette-blue-500);
	font-weight: var(--font-weight-semibold);
}
`, "",{"version":3,"sources":["webpack://./routes/organization/user-profile/payments/styles.css"],"names":[],"mappings":"AAAA;CACC,8BAA8B;CAC9B,wCAAwC;AACzC;;AAEA;CACC,8BAA8B;CAC9B,8BAA8B;CAC9B,wCAAwC;AACzC","sourcesContent":[".tableTitle {\n\tfont-size: var(--font-size-lg);\n\tfont-weight: var(--font-weight-semibold);\n}\n\n.newAction {\n\tfont-size: var(--font-size-sm);\n\tcolor: var(--palette-blue-500);\n\tfont-weight: var(--font-weight-semibold);\n}\n"],"sourceRoot":""}]);
// Exports
export var tableTitle = `bwTC6C9O64750XdCSsau`;
export var newAction = `CaA0zpoKnyXkUq_8SYR8`;
export default ___CSS_LOADER_EXPORT___;
