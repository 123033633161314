import { ReactNode } from 'react';

export const symbol_wb_sunny: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M450-840v-50q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-890v50q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T450-840Zm0 770v-50q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-120v50q0 12.75-8.675 21.375Q492.649-40 479.825-40 467-40 458.5-48.625T450-70Zm440-380h-50q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T840-510h50q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T890-450Zm-770 0H70q-12.75 0-21.375-8.675Q40-467.351 40-480.175 40-493 48.625-501.5T70-510h50q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T120-450Zm667-295-24 24q-9 9-21 8.5t-21-9.5q-9-9-9-21.5t9-21.5l23-23q9-9 21.603-9 12.604 0 21.5 9Q796-779 796-766.5t-9 21.5ZM240-196l-24 24q-9 9-21 8.5t-21-9.5q-9-9-9-21.5t9-21.5l23-23q8.5-9 21.25-9t21.75 9q9 9 9 21.5t-9 21.5Zm504 23-24-23q-9-9-8.5-21.5T721-239q9-9 21.5-9t21.5 9l23 24q9 9 9 21.196 0 12.195-9 20.804-9 9-21.5 9t-21.5-9ZM197-722l-24-23q-9-9-8.5-21.5T174-788q9-9 21.5-9t21.5 9l23 24q9 9 9 21.1 0 12.1-9 20.9-9 9-21.5 9t-21.5-9Zm282.824 467Q386-255 320.5-320.676q-65.5-65.677-65.5-159.5Q255-574 320.676-639.5q65.677-65.5 159.5-65.5Q574-705 639.5-639.323 705-573.647 705-479.824 705-386 639.323-320.5 573.647-255 479.824-255Zm.11-60Q549-315 597-362.934t48-117Q645-549 597.066-597t-117-48Q411-645 363-597.066t-48 117Q315-411 362.934-363t117 48ZM480-480Z"
		/>
	</svg>
);
