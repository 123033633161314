import { t } from '@transifex/native';

import { pushState } from 'pkg/router/state';
import * as actions from 'pkg/actions';
import * as routes from 'pkg/router/routes';
import {
	useCurrentGroup,
	useCurrentMembership,
	useCurrentOrganization,
} from 'pkg/identity';
import * as models from 'pkg/api/models';

import Icon from 'components/icon';

import * as ContextMenu from 'design/context_menu';

const Actions = ({
	group,
	isRoot,
	onShowDialog,
	onCreateSubGroup,
	toggleAddUserModal,
	removeRecord,
}) => {
	const currentMembership = useCurrentMembership();
	const activeGroup = useCurrentGroup();
	const org = useCurrentOrganization();

	const handleSetActive = () => {
		pushState(routes.Group.Change(), {
			groupId: group.id.toString(),
			membershipData: JSON.stringify({
				userId: currentMembership.userId,
				role: currentMembership.role,
				capabilities: currentMembership.capabilities,
			}),
		});
	};

	const handleCreateSubGroup = () => onCreateSubGroup(group);

	const handleMove = () => {
		onShowDialog('move', group);
	};

	const handleDelete = async () => {
		await actions.groups.deleteGroup(group.id);

		removeRecord(group.id);
	};

	const contextActions = [];

	if (
		activeGroup.paymentProviderActive &&
		models.membership.hasCapability(
			currentMembership,
			models.membership.Capabilities.PaymentAdmin
		)
	) {
		contextActions.push(
			<ContextMenu.LinkItem
				key="view_payments"
				href={routes.Management.SingleOrganization(org.id, group.id)}>
				<ContextMenu.ItemIcon name="arrow_forward" />
				{t('View')}
			</ContextMenu.LinkItem>
		);
	}

	contextActions.push(
		<ContextMenu.LinkItem key="add_user" onClick={toggleAddUserModal}>
			<ContextMenu.ItemIcon name="group_add" />
			{t('Add user to group')}
		</ContextMenu.LinkItem>
	);

	if (models.membership.isAdmin(currentMembership)) {
		contextActions.push(
			<ContextMenu.Item key="sub-group" onClick={handleCreateSubGroup}>
				<ContextMenu.ItemIcon name="add" />
				{t('Create subgroup')}
			</ContextMenu.Item>
		);
	}

	if (!isRoot) {
		contextActions.push(
			<ContextMenu.LinkItem key="go_to" onClick={handleSetActive}>
				<ContextMenu.ItemIcon name="arrow_forward" />
				{t('Go to group')}
			</ContextMenu.LinkItem>
		);

		if (models.membership.isAdmin(currentMembership)) {
			contextActions.push(
				<ContextMenu.LinkItem
					key="subgroup-settings"
					href={routes.Organization.SubGroup.Settings(org.id, group.id)}>
					<ContextMenu.ItemIcon name="edit" />
					{t('Edit {groupName}', { groupName: group.name })}
				</ContextMenu.LinkItem>,
				<ContextMenu.Item key="move" onClick={handleMove}>
					<ContextMenu.ItemIcon name="move_group" />
					{t('Move group')}
				</ContextMenu.Item>,
				<ContextMenu.LinkItem
					key="manage-users"
					href={routes.Group.Show(org.id, group.id)}>
					<ContextMenu.ItemIcon name="groups" />
					{t('Manage users')}
				</ContextMenu.LinkItem>
			);
		}
	}

	if (
		!models.group.canShowChildren(group) &&
		!isRoot &&
		models.membership.isAdmin(currentMembership)
	) {
		contextActions.push(
			<ContextMenu.ConfirmItem
				key="delete"
				message={t(
					'This will delete the group {groupName}, and remove all users from the group. ',
					{ groupName: group.name }
				)}
				caution
				onConfirm={handleDelete}>
				<ContextMenu.ItemIcon name="delete" />
				{t('Delete')}
			</ContextMenu.ConfirmItem>
		);
	}

	if (contextActions.length === 0) {
		return null;
	}

	return (
		<ContextMenu.Menu
			toggleWith={
				<ContextMenu.TableTrigger data-testid="organization.tree_row_action_trigger">
					<Icon name="context-menu" />
				</ContextMenu.TableTrigger>
			}>
			{contextActions}
		</ContextMenu.Menu>
	);
};

export default Actions;
