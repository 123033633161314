import { ReactNode } from 'react';

export const symbol_add_moderator: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-877q5 0 10.5 1t10.5 3l260 97q17.25 6.932 28.125 22.338Q800-738.257 800-719v187q0 12.325-9 20.663Q782-503 770-503t-21-9q-9-9-9-21v-186l-260-96-260 96v197q0 70 21 129.5T295-286q33 47 74.5 80.5T451-154q11 5 16 16.5t.385 22.629Q462-103 449.5-98 437-93 425-98q-125-51-195-170.741Q160-388.483 160-522v-197q0-19.257 10.875-34.662Q181.75-769.068 199-776l260-97q5.25-2.4 10.5-3.2 5.25-.8 10.5-.8ZM692.5-80Q615-80 560-135.5T505-267q0-78.435 54.99-133.717Q614.98-456 693-456q77 0 132.5 55.283Q881-345.435 881-267q0 76-55.5 131.5T692.5-80ZM480-479Zm198 229v73.636q0 7.364 5.143 11.864 5.143 4.5 12 4.5t11.357-5.1q4.5-5.1 4.5-11.9v-73h73.636q7.364 0 11.864-5.143 4.5-5.143 4.5-12t-4.5-11.357q-4.5-4.5-11.864-4.5H711v-73.636q0-7.364-4.5-11.864-4.5-4.5-11.357-4.5-6.857 0-12 4.5T678-356.636V-283h-73.636q-7.364 0-11.864 4.5-4.5 4.5-4.5 11.357 0 6.857 5.1 12T605-250h73Z"
		/>
	</svg>
);
