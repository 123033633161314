import { ReactNode } from 'react';

export const symbol_frame_source: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m299-480 66 66q9 9 9 21.158 0 12.158-8.609 21Q356-363 343.533-363q-12.466 0-21.533-9l-87-87q-9-9-9-21t9-21l88-88q9-9 21-8.5t21.391 9.5Q374-579 374-567t-9 21l-66 66Zm362 0-66-66q-9-9-9-21.158 0-12.158 8.609-21Q604-597 616.467-597q12.466 0 21.533 9l87 87q9 9 9 21t-9 21l-88 88q-9 9-21 8.5t-21.391-9.5Q586-381 586-393t9-21l66-66ZM180-120q-24 0-42-18t-18-42v-142q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T180-322v142h142q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T322-120H180Zm600 0H638q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T638-180h142v-142q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T840-322v142q0 24-18 42t-42 18ZM120-638v-142q0-24 18-42t42-18h142q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T322-780H180v142q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T120-638Zm660 0v-142H638q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T638-840h142q24 0 42 18t18 42v142q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T780-638Z"
		/>
	</svg>
);
