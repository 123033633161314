import { Fragment, ReactNode, CSSProperties } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import { cssClasses } from 'pkg/css/utils';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import * as css from './styles.css';

interface Props {
	brand: CSSProperties;
	backdropImage: string;
	children: ReactNode;
}

const BrandedWrapper = ({ brand, backdropImage, children }: Props) => {
	const videoUrl = `${window.TS.assetUrl}/videos/video-portrait.mp4`;

	const contentStyle: CSSProperties = { ...brand };

	if (!backdropImage) {
		contentStyle['backgroundImage'] =
			`url(${window.TS.assetUrl}/img/onboarding-backdrop.svg)`;
	}

	return (
		<Fragment>
			<div className={css.wrapper}>
				<div style={contentStyle} className={css.content}>
					{children}
				</div>
				<SmallScreen>
					<img
						src={backdropImage}
						className={cssClasses(css.backdropImg, css.smallScreenBackdropImg)}
					/>
				</SmallScreen>
			</div>
			<LargeScreen>
				<div style={brand} className={css.backgroundWrapper}>
					{backdropImage ? (
						<img
							src={backdropImage}
							className={cssClasses(
								css.backdropImg,
								css.largeScreenBackdropImg
							)}
						/>
					) : (
						<AnimatePresence>
							<motion.div
								className={css.backdropVideo}
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}>
								<div
									dangerouslySetInnerHTML={{
										__html: `<video loop playsinline muted autoplay src="${videoUrl}" />`,
									}}
								/>
							</motion.div>
						</AnimatePresence>
					)}
				</div>
			</LargeScreen>
		</Fragment>
	);
};

export default BrandedWrapper;
