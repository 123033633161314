import { ReactNode } from 'react';

export const symbol_curtains_closed: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M160-180v-600q0-24.75 17.625-42.375T220-840h520q24.75 0 42.375 17.625T800-780v600h50q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-120H110q-12.75 0-21.375-8.675Q80-137.351 80-150.175 80-163 88.625-171.5T110-180h50Zm60 0h145v-600H220v600Zm205 0h110v-600H425v600Zm170 0h145v-600H595v600Zm-375 0v-600 600Zm520 0v-600 600Z"
		/>
	</svg>
);
