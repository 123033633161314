import { ReactNode } from 'react';

export const symbol_restaurant: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M285-600v-250q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T345-850v250h65v-250q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T470-850v249.73q0 58.27-36.5 99.77Q397-459 345-448v338q0 12.75-8.675 21.375Q327.649-80 314.825-80 302-80 293.5-88.625T285-110v-338q-52-11-88.5-52.5T160-600.27V-850q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T220-850v250h65Zm415 200h-85q-12.75 0-21.375-8.625T585-430v-275q0-69 42.5-122t98.5-53q14 0 24 10.128 10 10.127 10 23.872v736q0 12.75-8.675 21.375Q742.649-80 729.825-80 717-80 708.5-88.625T700-110v-290Z"
		/>
	</svg>
);
