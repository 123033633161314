import { ReactNode } from 'react';

export const symbol_swipe_down: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M146-470q-8-32-12-64t-4-64q0-71 21-137.5T212-860q5-8 14-10t15 4q7 7 6.5 16t-5.5 17q-35 52-53.5 112T170-598q0 34 6 67t14 65l64-63q6-6 14-6t14 6q6 6 6 14t-6 14l-91 91q-9 9-21 9t-21-9l-91-91q-6-6-6-14t6-14q6-6 14-6t14 6l60 59Zm500 344q-20 8-42 7t-42-11L318-243q-10-5-14.5-14.5T302-278v-2q5-19 19-31t34-14l99-8-117-320q-5-12 .5-22.5T355-691q12-5 23 .5t16 17.5l131 357q5 14-3 26.5T499-275l-89 8 178 83q9 4 19 4t19-3l159-58q39-14 56.5-51.5T845-369l-62-169q-5-12 .5-23t17.5-16q12-5 23 .5t16 17.5l61 169q23 63-4.5 122.5T806-185l-160 59ZM523-603q12-5 22.5.5T561-585l48 132q5 11-.5 22T591-415q-11 5-22-1t-16-17l-48-132q-5-12 .5-22.5T523-603Zm132-6q12-5 22.5.5T693-591l35 94q5 12-1 23t-18 16q-12 4-22.5-1T671-476l-34-94q-5-12 .5-23t17.5-16Zm22 265Z"
		/>
	</svg>
);
