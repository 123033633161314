import Row from 'components/layout/row';

import Button from 'design/button';

interface ButtonCountProps {
	count: number;
	increment: () => void;
	decrement: () => void;
	disabled?: boolean;
}

export default function ButtonCount({
	count,
	increment,
	decrement,
	disabled = false,
}: ButtonCountProps) {
	return (
		<Row columns="40px 1fr 40px" align="center">
			<Button
				icon="remove"
				secondary
				large
				onClick={decrement}
				disabled={disabled}
			/>
			<span>{count}</span>
			<Button
				icon="add"
				secondary
				large
				onClick={increment}
				disabled={disabled}
			/>
		</Row>
	);
}
