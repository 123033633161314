import { ReactNode } from 'react';

export const symbol_history_edu: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M321-160q-24 0-42-18t-18-42v-70q0-12.75 8.625-21.375T291-320h94v-127q-36 3-70.5-9T252-491q-5.077-4.571-8.038-10.286Q241-507 241-514v-44h-38q-5.565 0-10.783-2Q187-562 182-567l-98-98q-10-10-9.5-23.5T86-710q32-26 76.5-41t85.5-15q30 0 68.5 9.5T385-726v-14q0-25.091 17.455-42.545Q419.909-800 445-800h335q24.75 0 42.375 17.625T840-740v475q0 43.75-30.625 74.375T735-160H321Zm124-160h215q12.75 0 21.375 8.625T690-290v25q0 20 12.5 32.5T735-220q20 0 32.5-12.5T780-265v-475H445v57l232 232q8 8 9 16.5t-2 16.5q-3 8-9.975 13.5T656.2-399q-6.2 0-11.7-2-5.5-2-10.5-7L517-525l-17 20q-13 15-26 23t-29 15v147ZM218-618h53q12.75 0 21.375 8.625T301-588v59q17 11 33.25 16.5T368-507q25 0 51-13.5t38-27.5l17-20-69-69q-32-32-71.823-50.5Q293.354-706 248-706q-27 0-49 6.5T154-682l64 64Zm412 358H321v40h323q-6-6-10-16.5t-4-23.5Zm-309 40v-40 40Z"
		/>
	</svg>
);
