import { ReactNode } from 'react';

export const symbol_where_to_vote: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m438-527-44-44q-9-9-21.5-8.5T351-570q-9 9-9 21.5t9 21.5l66 65q9 9 21 9t21-9l150-150q9-9 9-21.5t-9-21.5q-9-9-21.5-9t-21.5 9L438-527Zm42 368q133-121 196.5-219.5T740-552q0-118-75.5-193T480-820q-109 0-184.5 75T220-552q0 75 65 173.5T480-159Zm0 59q-11 0-21.5-3.5T440-115q-42-38-91-87.5T258-309q-42-57-70-119t-28-124q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 62-28 124t-70 119q-42 57-91 106.5T520-115q-8 8-18.5 11.5T480-100Zm0-460Z"
		/>
	</svg>
);
