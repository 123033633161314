import { t } from '@transifex/native';
import styled from 'styled-components';
import { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { T } from '@transifex/react';

import * as styles from 'pkg/config/styles';

import * as actions from 'pkg/actions';
import { useCollection } from 'pkg/api/use_collection';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import useConfirm from 'pkg/hooks/useConfirm';
import DateTime from 'pkg/datetime';
import { AccountRelationTypes } from 'pkg/api/models/account_relation';
import { useCurrentAccount } from 'pkg/identity';

import * as Card from 'components/Card';
import Heading from 'components/Heading';
import RelativeDateTime from 'components/RelativeDateTime';
import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import Icon from 'components/icon';
import Avatar from 'components/avatar';

import Column from 'components/layout/column';
import Row from 'components/layout/row';

import Button from 'design/button';
import * as ContextMenu from 'design/context_menu';

const Group = styled(Column)`
	&:not(:last-of-type) {
		padding-bottom: var(--spacing-8);
		margin-bottom: var(--spacing-8);
		border-bottom: 1px solid var(--palette-gray-300);
	}

	&:empty {
		padding: 0;
		margin: 0;
		height: 0px;
		border-bottom: none;
	}
`;

const ItemColumn = styled(Column)`
	p {
		line-height: 1;
	}

	strong {
		font-weight: var(--font-weight-semibold);
	}

	span,
	time {
		font-size: var(--font-size-sm);
		color: var(--palette-gray-500);
		line-height: 1.35;
	}
`;

interface ActiveRelationItemProps {
	relation: models.accountRelation.AccountRelation;
	refreshRelations: () => Promise<void>;
}

function ActiveRelationItem({
	relation,
	refreshRelations,
}: ActiveRelationItemProps): JSX.Element {
	const dispatch = useDispatch();

	const userName = models.account.fullName(relation.targetAccount);

	const remove = async (relationId: number) => {
		await dispatch(actions.accountRelations.remove(relationId));
		await refreshRelations();

		actions.flashes.show({
			title: t('Successfully saved!'),
			message: t('You are no longer connected to {userName}', {
				userName,
			}),
		});
	};

	const renderItem = (
		relation: models.accountRelation.AccountRelation
	): JSX.Element => {
		const removeItem = async () => {
			remove(relation.id);
		};

		const actions = [];

		if (actions.length > 0) {
			actions.push(<ContextMenu.Divider key="remove-connection-divider" />);
		}

		actions.push(
			<ContextMenu.ConfirmItem
				caution
				closeOnClick
				onConfirm={removeItem}
				confirmLabel={t('Remove connection')}
				message={t(
					'You are about to remove {userName} as a connection, this cannot be undone.',
					{
						userName,
					}
				)}
				key="remove-connection">
				<ContextMenu.ItemIcon name="link_off" />
				{t('Remove connection')}
			</ContextMenu.ConfirmItem>
		);

		return (
			<Row
				columns="35px 1fr auto"
				align="center"
				key={relation.targetAccount.id}>
				<Avatar account={relation.targetAccount} />
				<ItemColumn spacing={styles.spacing._1}>
					<strong>{models.account.fullName(relation.targetAccount)}</strong>
					<span>{relation.targetAccount.email}</span>
				</ItemColumn>
				<ContextMenu.Menu
					toggleWith={<Button icon="more_horiz" small secondary />}>
					{actions}
				</ContextMenu.Menu>
			</Row>
		);
	};

	return (
		<Fragment>
			<Card.Base key={relation.id}>
				<Card.Body $narrowBody>{renderItem(relation)}</Card.Body>
			</Card.Base>
		</Fragment>
	);
}

interface AccountRelationsProps {
	relations: models.accountRelation.AccountRelation[];
	refreshRelations: () => Promise<void>;
}

function ActiveRelations({
	relations,
	refreshRelations,
}: AccountRelationsProps): JSX.Element {
	relations = relations.filter(
		(relation: models.accountRelation.AccountRelation) =>
			!relation.targetAccount.willBeDeletedAt
	);

	if (relations.length === 0) return null;

	return (
		<Group>
			<Heading>{t('Parent or guardian connections')}</Heading>
			{relations.map((relation: models.accountRelation.AccountRelation) => (
				<ActiveRelationItem
					key={relation.targetAccount.id}
					relation={relation}
					refreshRelations={refreshRelations}
				/>
			))}
		</Group>
	);
}

interface AccountRelationRowProps {
	relation: models.accountRelation.AccountRelation;
	refreshRelations?: () => Promise<void>;
}

function AccountRelationRow({
	relation,
	refreshRelations,
}: AccountRelationRowProps): JSX.Element {
	const dispatch = useDispatch();

	const userName = models.account.fullName(relation.targetAccount);

	const remove = useConfirm({
		message: t(
			'You are about to remove {userName} as a connection, this cannot be undone.',
			{ userName }
		),
		onConfirm: async () => {
			await dispatch(actions.accountRelations.remove(relation.id));

			actions.flashes.show({
				title: t('Successfully saved!'),
				message: t('You are no longer connected to {userName}', {
					userName,
				}),
			});

			refreshRelations();
		},
	});

	return (
		<Card.Base>
			<Card.Body $narrowBody>
				<LargeScreen>
					<Row columns="35px 1fr auto" align="center">
						<Avatar account={relation.targetAccount} />
						<ItemColumn spacing={styles.spacing._1}>
							<strong>{models.account.fullName(relation.targetAccount)}</strong>
							<RelativeDateTime dateTime={relation.createdAt} />
						</ItemColumn>
						<Button caution onClick={remove}>
							{t('Remove request')}
						</Button>
					</Row>
				</LargeScreen>
				<SmallScreen>
					<Column>
						<Row columns="35px 1fr">
							<Avatar account={relation.targetAccount} />
							<ItemColumn spacing={styles.spacing._1}>
								<strong>
									{models.account.fullName(relation.targetAccount)}
								</strong>
								<RelativeDateTime dateTime={relation.createdAt} />
							</ItemColumn>
						</Row>
						<Button caution onClick={remove}>
							{t('Remove request')}
						</Button>
					</Column>
				</SmallScreen>
			</Card.Body>
		</Card.Base>
	);
}

function PendingRelations({
	relations,
	refreshRelations,
}: AccountRelationsProps): JSX.Element {
	if (relations.length === 0) return null;

	return (
		<Group>
			<Heading>{t('Pending connection requests')}</Heading>
			{relations.map((relation: models.accountRelation.AccountRelation) => (
				<AccountRelationRow
					key={relation.id}
					relation={relation}
					refreshRelations={refreshRelations}
				/>
			))}
		</Group>
	);
}

const AvatarRow = styled.div`
	--avarar-size: 40px;
	--icon-size: 28px;

	margin: 0 auto;
	position: relative;
	width: calc((var(--avarar-size) * 2) + var(--spacing-6));
`;

const ConnectionIconWrapper = styled.div`
	--size: 28px;

	width: var(--size);
	height: var(--size);
	border-radius: 1000px;
	position: absolute;
	left: 50%;
	top: 50%;
	background: var(--palette-blue-300);
	color: var(--palette-blue-600);
	box-shadow: var(--palette-white) 0 0 0 2px;
	transform: translate(-50%, -50%);
	display: flex;
	place-content: center;
	align-items: center;
`;

interface AccountConnectionProps {
	a: models.account.Account;
	b: models.account.Account;
}

function AccountConnection({ a, b }: AccountConnectionProps): JSX.Element {
	return (
		<AvatarRow>
			<Row columns="repeat(2, 40px)" spacing={styles.spacing._6}>
				<Avatar account={a} />
				<Avatar account={b} />
			</Row>
			<ConnectionIconWrapper>
				<Icon name="link" size={1.25} />
			</ConnectionIconWrapper>
		</AvatarRow>
	);
}

interface IncomingRelationItemProps {
	account?: models.account.Account;
	relation: models.accountRelation.AccountRelation;
	refreshRelations: () => Promise<void>;
}

function IncomingRelationItem({
	account,
	relation,
	refreshRelations,
}: IncomingRelationItemProps): JSX.Element {
	const dispatch = useDispatch();
	const currentAccount = useCurrentAccount();

	const wardAccount = account ? account : relation.account;

	let guardianAccount = account ? relation.account : relation.targetAccount;

	if (!guardianAccount) {
		guardianAccount = currentAccount;
	}

	const userName = guardianAccount
		? models.account.fullName(guardianAccount)
		: '';

	const accept = async () => {
		// Await these so that refreshRelations update the view properly
		await dispatch(actions.accountRelations.approveRequest(relation.id));
		await refreshRelations();

		actions.flashes.show({
			title: t('Successfully saved!'),
			message: t('You and {userName} are now connected', {
				userName,
			}),
		});
	};

	const remove = useConfirm({
		message: t(
			'You are about to remove {userName} as a connection, this cannot be undone.',
			{ userName }
		),
		onConfirm: async () => {
			// Await these so that refreshRelations update the view properly
			await dispatch(actions.accountRelations.remove(relation.id));

			actions.flashes.show({
				title: t('Successfully saved!'),
				message: t('You are no longer connected to {userName}', {
					userName,
				}),
			});

			await refreshRelations();
		},
	});

	let label = (
		<T
			_str="{parent_name} wants to connect to you as a parent or legal guardian"
			parent_name={<strong>{models.account.fullName(guardianAccount)}</strong>}
		/>
	);

	if (account) {
		label = (
			<T
				_str="{parent_name} wants to connect to {child_name} as a parent or legal guardian"
				parent_name={
					<strong>{models.account.fullName(guardianAccount)}</strong>
				}
				child_name={<strong>{models.account.fullName(wardAccount)}</strong>}
			/>
		);
	}

	if (!account) return null;

	return (
		<Card.Base>
			<Card.Body $narrowBody>
				<LargeScreen>
					<Row
						columns="auto 1fr auto"
						align="center"
						spacing={styles.spacing._7}>
						<AccountConnection a={guardianAccount} b={wardAccount} />
						<div>{label}</div>
						<Row>
							<Button caution onClick={remove}>
								{t('Remove')}
							</Button>
							<Button primary onClick={accept}>
								{t('Accept')}
							</Button>
						</Row>
					</Row>
				</LargeScreen>
				<SmallScreen>
					<Column spacing={styles.spacing._7}>
						<AccountConnection a={guardianAccount} b={wardAccount} />
						<div>{label}</div>
						<Row>
							<Button caution onClick={remove}>
								{t('Remove')}
							</Button>
							<Button primary onClick={accept}>
								{t('Accept')}
							</Button>
						</Row>
					</Column>
				</SmallScreen>
			</Card.Body>
		</Card.Base>
	);
}

interface ConnectedIncomingRelationsProps {
	account: models.account.Account;
	refreshRelations: () => Promise<void>;
}

function ConnectedIncomingRelations({
	account,
	refreshRelations,
}: ConnectedIncomingRelationsProps): JSX.Element {
	const incoming = useCollection<models.accountRelation.AccountRelation>(
		endpoints.AccountRelations.Index() + `?requests=1&account_id=${account.id}`
	);

	if (incoming.records.length === 0) return null;

	return (
		<Fragment>
			{incoming.records.map(
				(relation: models.accountRelation.AccountRelation) => (
					<IncomingRelationItem
						key={relation.id}
						account={account}
						relation={relation}
						refreshRelations={refreshRelations}
					/>
				)
			)}
		</Fragment>
	);
}

interface AccountUnderDeletionProps {
	account: models.account.Account;
	refreshRelations: () => Promise<void>;
}

function AccountUnderDeletion({
	account,
	refreshRelations,
}: AccountUnderDeletionProps): JSX.Element {
	const cancelDeletion = async () => {
		const confirm = window.confirm(t('Are you sure?'));

		if (!confirm) return;

		await models.account.cancelDelete(account);

		refreshRelations();
	};

	const willBeDeletedAt = new DateTime(
		new Date(account.willBeDeletedAt * 1000)
	).toLocaleDateString({
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
	});

	return (
		<Column>
			<p>
				{t(
					'This account has been scheduled for deletion, and will be deleted on {date}, you can cancel the deletion at any time before that date.',
					{
						date: willBeDeletedAt,
					}
				)}
			</p>
			<Card.Base key={account.id}>
				<Card.Body $narrowBody>
					<Row columns="35px 1fr auto auto" align="center">
						<Avatar account={account} />
						<ItemColumn spacing={styles.spacing._1}>
							<strong>{models.account.fullName(account)}</strong>
							<span>{account.email}</span>
						</ItemColumn>
						<Button
							caution
							label={t('Cancel user delete request')}
							onClick={cancelDeletion}
						/>
					</Row>
				</Card.Body>
			</Card.Base>
		</Column>
	);
}

interface AccountsUnderDeletionProps {
	accounts: models.account.Account[];
	refreshRelations: () => Promise<void>;
}

function AccountsUnderDeletion({
	accounts,
	refreshRelations,
}: AccountsUnderDeletionProps): JSX.Element {
	if (accounts.length === 0) return;

	return (
		<Fragment>
			{accounts.map((account: models.account.Account) => (
				<AccountUnderDeletion
					key={account.id}
					account={account}
					refreshRelations={refreshRelations}
				/>
			))}
		</Fragment>
	);
}

function IncomingRelations({
	relations,
	refreshRelations,
}: AccountRelationsProps): JSX.Element {
	if (relations.length === 0) return null;

	return (
		<Column>
			{relations.map((relation: models.accountRelation.AccountRelation) => (
				<IncomingRelationItem
					key={`incoming:${relation.id}`}
					relation={relation}
					refreshRelations={refreshRelations}
				/>
			))}
		</Column>
	);
}

export default function AccountRelations(): JSX.Element {
	const account = useCurrentAccount();

	const relations = useCollection<models.accountRelation.AccountRelation>(
		endpoints.AccountRelations.Index()
	);

	const incoming = useCollection<models.accountRelation.AccountRelation>(
		endpoints.AccountRelations.Index(),
		{
			queryParams: new URLSearchParams({
				requests: '1',
			}),
		}
	);

	const active = relations.records.filter(
		(r) => r.type === AccountRelationTypes.Ward
	);
	const pending = relations.records.filter((r) =>
		models.accountRelation.isPending(r)
	);

	const wardAccounts: models.account.Account[] = [];
	const wardAccountsUnderDeletion: models.account.Account[] = [];

	active.forEach((relation: models.accountRelation.AccountRelation) => {
		if (relation.targetAccount.willBeDeletedAt) {
			wardAccountsUnderDeletion.push(relation.targetAccount);
		} else {
			wardAccounts.push(relation.targetAccount);
		}
	});

	const showEmptyState =
		incoming.records.length === 0 &&
		relations.records.length === 0 &&
		!models.account.isAnAdult(account);

	const refreshAll = async (): Promise<void> => {
		await relations.refresh();
		await incoming.refresh();
	};

	if (showEmptyState) {
		return null;
	}

	return (
		<Fragment>
			<PendingRelations refreshRelations={refreshAll} relations={pending} />
			<IncomingRelations
				refreshRelations={refreshAll}
				relations={incoming.records}
			/>
			{wardAccounts.length > 0 && (
				<Group data-no-border>
					{wardAccounts.map((account: models.account.Account) => (
						<ConnectedIncomingRelations
							key={account.id}
							account={account}
							refreshRelations={refreshAll}
						/>
					))}
				</Group>
			)}
			<ActiveRelations refreshRelations={refreshAll} relations={active} />
			{wardAccountsUnderDeletion.length > 0 && (
				<Group data-no-border>
					<AccountsUnderDeletion
						key={account.id}
						accounts={wardAccountsUnderDeletion}
						refreshRelations={refreshAll}
					/>
				</Group>
			)}
		</Fragment>
	);
}
