import { useState } from 'react';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import * as actions from 'pkg/actions';
import * as sdk from 'pkg/core/sdk';
import * as endpoints from 'pkg/api/endpoints/auto';

import CardAnatomy, * as Card from 'components/Card';
import Modal from 'components/modal';

import TextInput from 'components/form/TextInput';

import Button from 'design/button';

interface TitleModalProps {
	membership: models.membership.Membership;
	onClose: () => void;
}

const TitleModal = ({ membership, onClose }: TitleModalProps) => {
	const [title, setTitle] = useState(membership.title);

	const handleChange = ({ target }: any) => setTitle(target.value);

	const handleSave = async () => {
		const request = await sdk.patch(
			endpoints.Groups.UpdateUser(membership.groupId, membership.userId),
			{},
			{ title }
		);

		if (!request.ok) {
			actions.flashes.show({
				title: t('Could not set user title'),
			});
		}
		onClose();
	};

	return (
		<Modal onClose={onClose}>
			<CardAnatomy>
				<Card.Header>
					<Card.Heading>{t('Set title')}</Card.Heading>
				</Card.Header>

				<Card.Divider />

				<Card.Body $flex>
					<TextInput
						value={title}
						onChange={handleChange}
						placeholder={t(`E.g. Head Coach`)}
					/>
				</Card.Body>

				<Card.Footer>
					<Button primary onClick={handleSave}>
						{t(`Save and close`)}
					</Button>
				</Card.Footer>
			</CardAnatomy>
		</Modal>
	);
};

export default TitleModal;
