import VimeoPlayer from '@vimeo/player';

import * as fs from 'pkg/fullscreen';
import uuid from 'pkg/uuid';

export enum ControllerType {
	Video = 'video',
	Vimeo = 'vimeo',
	YouTube = 'youtube',
}

export type ControllerInterfaceType =
	| any
	| VimeoPlayer
	| YT.Player
	| HTMLVideoElement;

function isYouTube(controllerInterface: ControllerInterfaceType): boolean {
	return controllerInterface && 'cueVideoById' in controllerInterface;
}

export function typeOfController(
	controllerInterface: ControllerInterfaceType
): ControllerType {
	if (controllerInterface instanceof VimeoPlayer) {
		return ControllerType.Vimeo;
	}

	if (isYouTube(controllerInterface)) {
		return ControllerType.YouTube;
	}

	// Fall back to videojs
	return ControllerType.Video;
}

export type TimeChangeObserver = () => void;

const __signature = uuid();

export default abstract class Controller {
	public signature: string;

	constructor(protected controllerInterface: ControllerInterfaceType) {
		this.signature = __signature;
	}

	public abstract setSource(source: unknown): void;

	public abstract getDuration(): Promise<number>;

	public abstract getCurrentTime(): Promise<number>;

	public abstract play(): Promise<void>;

	public abstract pause(): Promise<void>;

	public abstract isPlaying(): boolean;

	public abstract skip(
		fraction: number,
		resumeAfterSkip: boolean
	): Promise<number>;

	public abstract seekTo(
		fraction: number,
		resumeAfterSeek: boolean
	): Promise<number>;

	public abstract setVolume(fraction: number): Promise<void>;

	public abstract getVolume(fraction: number): Promise<number>;

	public abstract mute(): Promise<void>;

	public abstract unmute(): Promise<void>;

	public abstract getAvailablePlaybackRates(): Promise<readonly number[]>;

	public abstract setPlaybackRate(playbackRate: number): Promise<void>;

	public abstract getPlaybackRate(): Promise<number>;

	// @todo Refactor when videojs controls YouTube.
	public async getNode(): Promise<HTMLVideoElement | HTMLIFrameElement> {
		if ('getIframe' in this.controllerInterface) {
			const node = await this.controllerInterface.getIframe();

			return Promise.resolve(node);
		}

		return Promise.resolve(this.controllerInterface as HTMLVideoElement);
	}

	// @todo Refactor when videojs controls YouTube.
	public async requestFullscreen(): Promise<void> {
		const target = await this.getNode();

		return fs.requestFullscreen(target);
	}

	// @todo Refactor when videojs controls YouTube.
	public async toggleFullscreen(): Promise<void> {
		const target = await this.getNode();

		if (fs.canRequestFullscreen(target)) {
			if (fs.inFullscreen()) {
				await fs.exitFullscreen();
			} else {
				await this.requestFullscreen();
			}
		}
	}

	public observeTimeChange(observer: TimeChangeObserver): void {
		if (!this.controllerInterface) return null;

		const isYouTube =
			typeOfController(this.controllerInterface) === ControllerType.YouTube;

		const isVimeo =
			typeOfController(this.controllerInterface) === ControllerType.Vimeo;

		if (isVimeo) {
			(this.controllerInterface as any).on('timeupdate', observer);
		} else if (!isVimeo && !isYouTube) {
			(this.controllerInterface as any).on('timeupdate', observer);
		}
	}
}
