import { ReactNode } from 'react';

export const symbol_swap_horizontal_circle: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M327-359h163q13 0 21.5-8.5T520-389q0-13-8.5-21.5T490-419H327l63-63q9-9 9-21.5t-9-21.5q-9-9-21.5-9t-21.5 9L232-410q-9 9-9 21t9 21l115 115q9 9 21.5 8.5T390-254q9-9 9-21.5t-9-21.5l-63-62Zm306-174-63 63q-9 9-9 21.5t9 21.5q9 9 21.5 9t21.5-9l115-115q9-9 9-21t-9-21L614-698q-9-9-21.5-9.5T571-699q-9 9-9 22t9 22l62 62H470q-13 0-21.5 8.5T440-563q0 13 8.5 21.5T470-533h163ZM480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm0-60q142 0 241-99.5T820-480q0-142-99-241t-241-99q-141 0-240.5 99T140-480q0 141 99.5 240.5T480-140Zm0-340Z"
		/>
	</svg>
);
