import { ChangeEvent } from 'react';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';

import * as Input from 'components/form/inputs';

interface SelectBillingCycleProps {
	value: string;
	onChange: (event: ChangeEvent<HTMLSelectElement>) => void;

	providerSettings: models.providerSettings.ProviderSettings;

	disabled?: boolean;
}

const SelectBillingCycle = ({
	value,
	onChange,
	disabled = false,
	providerSettings,
}: SelectBillingCycleProps) => {
	return (
		<Input.Select
			name="billingCycle"
			value={value}
			onChange={onChange}
			disabled={disabled}
			required>
			<option value="">{t('Select')}</option>
			{Object.keys(providerSettings.availableRecurringOptions).map(
				(item: models.providerSettings.RecurringOptionStrings, index) => (
					<option key={index} value={item}>
						{models.providerSettings.getRecuringOptionTranslation(item)}
					</option>
				)
			)}
			{providerSettings.allowCustomRecurringCombinations && (
				<option value="custom">{t('Custom')}</option>
			)}
		</Input.Select>
	);
};

export default SelectBillingCycle;
