import { ReactNode } from 'react';

export const symbol_battery_horiz_000: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M174-280q-12.75 0-21.375-8.625T144-310v-90h-34q-12.75 0-21.375-8.625T80-430v-100q0-12.75 8.625-21.375T110-560h34v-90q0-12.75 8.625-21.375T174-680h676q12.75 0 21.375 8.625T880-650v340q0 12.75-8.625 21.375T850-280H174Zm30-60h616v-280H204v280Z"
		/>
	</svg>
);
