import { ReactNode } from 'react';

export const symbol_schema: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M160-100v-100q0-24.75 17.625-42.375T220-260h40v-110h-40q-24.75 0-42.375-17.625T160-430v-100q0-24.75 17.625-42.375T220-590h40v-110h-40q-24.75 0-42.375-17.625T160-760v-100q0-24.75 17.625-42.375T220-920h140q24.75 0 42.375 17.625T420-860v100q0 24.75-17.625 42.375T360-700h-40v110h40q24.75 0 42.375 17.625T420-530v20h160v-20q0-24.75 17.625-42.375T640-590h140q24.75 0 42.375 17.625T840-530v100q0 24.75-17.625 42.375T780-370H640q-24.75 0-42.375-17.625T580-430v-20H420v20q0 24.75-17.625 42.375T360-370h-40v110h40q24.75 0 42.375 17.625T420-200v100q0 24.75-17.625 42.375T360-40H220q-24.75 0-42.375-17.625T160-100Zm60 0h140v-100H220v100Zm0-330h140v-100H220v100Zm420 0h140v-100H640v100ZM220-760h140v-100H220v100Zm70-50Zm0 330Zm420 0ZM290-150Z"
		/>
	</svg>
);
