import { ReactNode } from 'react';

export const symbol_person_raised_hand: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M110-40q-12.75 0-21.375-8.675Q80-57.351 80-70.175 80-83 88.625-91.5T110-100h740q12.75 0 21.375 8.675Q880-82.649 880-69.825 880-57 871.375-48.5T850-40H110Zm80-120q-12.75 0-21.375-8.625T160-190v-180q-39-91-54-147.5T91-631q0-58 14.5-122T146-874q10-22 26.448-33.5Q188.897-919 209-919q27 0 41 17.5t14 52.5v131q0 44 17 83t46.5 68q29.5 29 68.292 45.5Q434.585-505 480-505q81 0 141 12t100 35q40 23 59.5 58.5T800-316v126q0 12.75-8.625 21.375T770-160H190Zm30-60h520v-96q0-33-16-57t-48-40q-32-16-81-24t-115-8q-57 0-107.5-21.5t-88-58.5Q247-562 225-612t-22-107v-130q-23 45-37.5 104.5T151-631q0 51 15.5 105.5T220-387v167Zm260-340q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm0-60q42 0 71-29t29-71q0-42-29-71t-71-29q-42 0-71 29t-29 71q0 42 29 71t71 29ZM330-160v-37q0-63 43.594-108T480-350h120q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T600-290H480q-38.25 0-64.125 27.452T390-197v37h-60Zm150-60Zm0-500Z"
		/>
	</svg>
);
