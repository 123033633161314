import { memo } from 'react';
import styled from 'styled-components';

import RelativeTimeImport from 'components/RelativeDateTime';

const Wrapper = styled.div`
	padding: var(--spacing-5) 0;
	font-size: var(--font-size-sm);
	color: var(--palette-gray-500);
	text-align: center;
	position: relative;

	&::after {
		display: block;
		content: '';
		border-top: 1px solid var(--palette-gray-200);
		z-index: 1;
		position: absolute;
		top: 24px;
		left: 0;
		right: 0;
	}
`;

const RelativeTime = styled(RelativeTimeImport)`
	padding: 0 var(--spacing-5);
	position: relative;
	background: var(--palette-white);
	z-index: 2;
`;

interface TimestampReplyProps {
	time: number;
}

const TimestampReply = memo(({ time }: TimestampReplyProps) => (
	<Wrapper>
		<RelativeTime dateTime={time} />
	</Wrapper>
));

export default TimestampReply;
