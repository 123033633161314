import { Map, Record } from 'immutable';

import TrainingCollection, {
	TrainingCollectionExercise,
	TrainingCollectionSession,
} from 'pkg/models/training_collection';

import { TrainingCollections } from 'pkg/actions/action-types';

const initialState = new Record(
	{
		entities: new Map({}),
		exercises: new Map({}),
		sessions: new Map({}),
		inherited: new Map({}),
	},
	'trainingCollectionsReducer'
);

const setItems = (state, action) => {
	const {
		trainingCollections,
		trainingCollectionsExercises,
		trainingCollectionsSessions,
	} = action.payload.entities;

	if (trainingCollections) {
		Object.values(trainingCollections).forEach((tc) => {
			state = state.setIn(['entities', tc.id], new TrainingCollection(tc));
		});
	}

	if (trainingCollectionsExercises) {
		Object.entries(trainingCollectionsExercises).forEach(([key, tce]) => {
			state = state.setIn(
				['exercises', key],
				new TrainingCollectionExercise(tce)
			);
		});
	}

	if (trainingCollectionsSessions) {
		Object.entries(trainingCollectionsSessions).forEach(([key, tcs]) => {
			state = state.setIn(
				['sessions', key],
				new TrainingCollectionSession(tcs)
			);
		});
	}

	return state;
};

const setInheritedItems = (state, action) => {
	if (action.collections.length === 0) {
		return state;
	}

	return state.setIn(
		['inherited', action.groupId],
		action.collections.map((collection) => collection.id)
	);
};

const deleteCollection = (state, { id }) => {
	const exerciseMap = state.getIn(['entities', id, 'exercises']);
	const sessionMap = state.getIn(['entities', id, 'sessions']);

	exerciseMap.forEach((mapId) => {
		state = state.deleteIn(['exercises', mapId]);
	});

	sessionMap.forEach((mapId) => {
		state = state.deleteIn(['sessions', mapId]);
	});

	return state.deleteIn(['entities', id]);
};

const addExercise = (state, { id, exerciseId }) => {
	const tce = `tc${id}te${exerciseId}`;
	const exercises = [...state.getIn(['entities', id, 'exercises']), tce];

	state = state.setIn(
		['exercises', tce],
		new TrainingCollectionExercise({
			trainingCollectionId: id,
			exerciseId,
		})
	);

	state = state.setIn(['entities', id, 'exercises'], exercises);

	return state;
};

const addSession = (state, { id, sessionId }) => {
	const tcs = `tc${id}ts${sessionId}`;
	const sessions = [...state.getIn(['entities', id, 'sessions']), tcs];

	state = state.setIn(
		['sessions', tcs],
		new TrainingCollectionSession({
			trainingCollectionId: id,
			trainingSessionId: sessionId,
		})
	);

	state = state.setIn(['entities', id, 'sessions'], sessions);

	return state;
};

const removeExercise = (state, { id, exerciseId }) => {
	for (const [key, values] of state.exercises.entries()) {
		if (
			values.exerciseId === exerciseId &&
			id === values.trainingCollectionId
		) {
			const exercises = state.getIn(['entities', id, 'exercises']);

			exercises.splice(exercises.indexOf(key), 1);

			state = state.setIn(['entities', id, 'exercises'], exercises);
			state = state.deleteIn(['exercises', key]);

			return state;
		}
	}

	return state;
};

const removeSession = (state, { id, sessionId }) => {
	for (const [key, values] of state.sessions.entries()) {
		if (
			values.trainingSessionId === sessionId &&
			id === values.trainingCollectionId
		) {
			const sessions = state.getIn(['entities', id, 'sessions']);

			sessions.splice(sessions.indexOf(key), 1);

			state = state.setIn(['entities', id, 'sessions'], sessions);
			state = state.deleteIn(['sessions', key]);

			return state;
		}
	}

	return state;
};

const trainingCollectionsReducer = (state = initialState(), action) => {
	switch (action.type) {
		case TrainingCollections.SET_ITEMS:
			return setItems(state, action);
		case TrainingCollections.SET_INHERITED_ITEMS:
			return setInheritedItems(state, action);
		case TrainingCollections.DELETE_ITEM:
			return deleteCollection(state, action);
		case TrainingCollections.ADD_EXERCISE:
			return addExercise(state, action);
		case TrainingCollections.ADD_SESSION:
			return addSession(state, action);
		case TrainingCollections.REMOVE_EXERCISE:
			return removeExercise(state, action);
		case TrainingCollections.REMOVE_SESSION:
			return removeSession(state, action);
		default:
			return state;
	}
};

export default trainingCollectionsReducer;
