const circleFilled = {
	name: 'close-circle-filled',
	objects: [
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M20.244 12.0011C20.244 16.5547 16.5526 20.2461 11.999 20.2461C7.44544 20.2461 3.75403 16.5547 3.75403 12.0011C3.75403 7.44749 7.44544 3.75607 11.999 3.75607C16.5526 3.75607 20.244 7.44749 20.244 12.0011ZM9.53443 8.27287C9.20012 7.93856 8.6581 7.93856 8.32379 8.27287C7.98948 8.60718 7.98948 9.1492 8.32379 9.48351L10.7894 11.9492L8.3238 14.4148C7.98949 14.7491 7.98949 15.2912 8.3238 15.6255C8.6581 15.9598 9.20013 15.9598 9.53444 15.6255L12.0001 13.1598L14.4657 15.6254C14.8 15.9597 15.342 15.9597 15.6763 15.6254C16.0107 15.2911 16.0107 14.7491 15.6763 14.4148L13.2107 11.9492L15.6764 9.48354C16.0107 9.14923 16.0107 8.60721 15.6764 8.2729C15.342 7.9386 14.8 7.9386 14.4657 8.2729L12.0001 10.7385L9.53443 8.27287Z',
			},
		},
	],
};

export default circleFilled;
