import { ReactNode } from 'react';

export const symbol_phone_disabled: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M792-78 578-291q-107 87-211.5 129T165-120q-23 0-34-12.5T120-165v-135q0-16 10-28t25-15l119-26q13-3 26 1.5t23 15.5l95 98q29-14 60.5-37t56.5-43L85-785q-9-9-9-21t9-21q9-9 22.222-9 13.222 0 21.778 9l706 707q9 9 8.5 21T834-78.5q-9 8.5-21 9T792-78ZM362-225l-79-83-103 21v107q45-2 93-14t89-31Zm301-152-43-43q21-25 42.5-56t38.5-57l-99.771-100.774Q593-642 590-653.5q-3-11.5 0-25.5l27-126q3-16 13.5-25.5T655-840h140q19.286 0 32.143 12.857T840-795q0 102-48 214.5T663-377Zm68-211q26-60 38-106.5t11-85.5H673l-23 110 81 82Zm0 0ZM362-225Z"
		/>
	</svg>
);
