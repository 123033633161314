import { ReactNode } from 'react';

export const symbol_in_home_mode: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-513ZM220-160q-24.75 0-42.375-17.625T160-220v-340l-60 46q-9.609 8-21.804 6Q66-510 58-520.37q-8-9.63-6.056-21.82Q53.89-554.381 64-562l380-291q8.082-6 17.265-9 9.184-3 18.735-3t18.735 3q9.183 3 17.265 9l381 291q10 8 11.5 20t-6.5 22q-8 10-20 12t-22-6L480-805 220-605.667V-220h162q13.077 0 21.538 8.675 8.462 8.676 8.462 21.5 0 12.825-8.625 21.325T382-160H220Zm390.867 68Q605-92 600-94q-5-2-10-7L477-214q-9-9-9-21t9-21q9-9 21-9t21 9l92 92 206-205q9-9 21-9t21 9q9 9 9 21t-9 21L632-101q-5 5-10.133 7-5.134 2-11 2Z"
		/>
	</svg>
);
