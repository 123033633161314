import { ReactNode } from 'react';

export const symbol_airline_seat_legroom_reduced: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M627-120q-27.429 0-46.714-23Q561-166 568-192l55-218H320q-28.875 0-49.438-20.562Q250-451.125 250-480v-330q0-12.75 8.625-21.375T280-840h160q12.75 0 21.375 8.625T470-810v220h230q30 0 48 23.5t10 52.5l-84 294h74q22.645 0 37.323 13.75Q800-192.5 800-170t-13.75 36.25Q772.5-120 750-120H627ZM180-280q-24.75 0-42.375-17.625T120-340v-470q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T180-810v470h270q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T450-280H180Z"
		/>
	</svg>
);
