import { t } from '@transifex/native';
import { ChangeEvent, Fragment, useState } from 'react';

import { useAccountIdentity } from 'pkg/identity/account';
import { localeIncludes } from 'pkg/strings';
import { getUnreadChatsForOtherOrganizations } from 'pkg/api/models/group';

import Badge from 'components/Badge';
import Icon from 'components/icon';

import OrganizationListItem from 'components/navigation/menus/organization_switcher/list_item';
import NotificationBadge from 'components/navigation/notification_badge';
import * as Input from 'components/form/inputs';
import { useContextSwitchFooter } from 'components/navigation/menus/context_switch';
import OrganizationSearchEmptyState from 'components/navigation/menus/organization_switcher/empty_state';

import * as Context from 'design/context_menu';

import * as css from './styles.css';

export default function OrganizationSwitcher() {
	const { organizations, organization } = useAccountIdentity();
	const sortedOrganizations = organizations.sort((a, b) =>
		a.name.localeCompare(b.name)
	);

	const [search, setSearch] = useState<string>('');
	const { Footer, JoinModal } = useContextSwitchFooter();

	const handleSearch = (event: ChangeEvent<HTMLInputElement>) =>
		setSearch(event.target.value);

	const clearSearch = () => setSearch('');

	const accountIdentity = useAccountIdentity();
	const totalUnreadChatMessages = getUnreadChatsForOtherOrganizations(
		organization,
		organizations
	);

	const showOrganizationSearch = sortedOrganizations.length > 5;

	const filteredOrgs = sortedOrganizations.filter((group) =>
		localeIncludes(group.name, search)
	);

	const orgsToList = search
		? filteredOrgs.map((org) => org.id)
		: sortedOrganizations.map((org) => org.id);

	const showEmptyState = search && filteredOrgs.length === 0;

	let content = null;

	if (showEmptyState) {
		content = (
			<OrganizationSearchEmptyState search={search} clearSearch={clearSearch} />
		);
	} else {
		content = (
			<Fragment>
				<Context.SegmentHeading>{t('Organisations')}</Context.SegmentHeading>
				<div className={css.orgListWrapper}>
					{sortedOrganizations.map((org, i, arr) => (
						<OrganizationListItem
							key={org.id}
							hideContent={!orgsToList.includes(org.id)}
							organizationData={org}
							active={org.id === accountIdentity.organization.id}
							lastItem={arr.length - 1 === i && !search}
						/>
					))}
				</div>
				{Footer}
			</Fragment>
		);
	}

	return (
		<Fragment>
			<Context.Menu
				toggleWith={
					<div className={css.container}>
						{totalUnreadChatMessages > 0 && <NotificationBadge />}
						<div className={css.badgeWrapper}>
							<Badge badgeUrl={accountIdentity.organization.profileImageUrl} />
						</div>
					</div>
				}>
				{showOrganizationSearch && (
					<div className={css.searchWrapper}>
						<Input.Field
							value={search}
							onChange={handleSearch}
							placeholder={t('Search organizations')}>
							<Input.Prefix inline>
								<Icon name="search" />
							</Input.Prefix>
						</Input.Field>
					</div>
				)}
				{content}
			</Context.Menu>
			{JoinModal}
		</Fragment>
	);
}
