import { ReactNode } from 'react';

export const symbol_stadia_controller: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M189-160q-60 0-102.5-43T42-307q0-9 1-18t3-18l84-336q14-54 57-87.5t98-33.5h390q55 0 98 33.5t57 87.5l84 336q2 9 3.5 18.5T919-306q0 61-43.5 103.5T771-160q-42 0-78-22t-54-60l-28-58q-5-10-15-15t-21-5H385q-11 0-21 5t-15 15l-28 58q-18 38-54 60t-78 22Zm2.663-60q24.337 0 45.004-12.973Q257.333-245.946 268-268l28-57q13-26 36.5-40.5T385-380h190q29 0 52.5 15t37.5 40l28 57q10.667 22.054 31.333 35.027Q745-220 769.255-220 805-220 831-244.5t27-60.5q0-4-3-24l-84-335q-8-33-34.4-54.5T675-740H285q-34.704 0-61.352 21Q197-698 189-664l-84 335q-1 4-3 23 0 36.485 26.258 61.242Q154.517-220 191.663-220Zm348.512-310q12.825 0 21.325-8.675 8.5-8.676 8.5-21.5 0-12.825-8.675-21.325-8.676-8.5-21.5-8.5-12.825 0-21.325 8.675-8.5 8.676-8.5 21.5 0 12.825 8.675 21.325 8.676 8.5 21.5 8.5Zm80-80q12.825 0 21.325-8.675 8.5-8.676 8.5-21.5 0-12.825-8.675-21.325-8.676-8.5-21.5-8.5-12.825 0-21.325 8.675-8.5 8.676-8.5 21.5 0 12.825 8.675 21.325 8.676 8.5 21.5 8.5Zm0 160q12.825 0 21.325-8.675 8.5-8.676 8.5-21.5 0-12.825-8.675-21.325-8.676-8.5-21.5-8.5-12.825 0-21.325 8.675-8.5 8.676-8.5 21.5 0 12.825 8.675 21.325 8.676 8.5 21.5 8.5Zm80-80q12.825 0 21.325-8.675 8.5-8.676 8.5-21.5 0-12.825-8.675-21.325-8.676-8.5-21.5-8.5-12.825 0-21.325 8.675-8.5 8.676-8.5 21.5 0 12.825 8.675 21.325 8.676 8.5 21.5 8.5Zm-360.059 65Q351-465 358-472.083q7-7.084 7-17.917v-45h45q10.833 0 17.917-7.116 7.083-7.117 7.083-18Q435-571 427.917-578q-7.084-7-17.917-7h-45v-45q0-10.833-7.116-17.917-7.117-7.083-18-7.083Q329-655 322-647.917q-7 7.084-7 17.917v45h-45q-10.833 0-17.917 7.116-7.083 7.117-7.083 18Q245-549 252.083-542q7.084 7 17.917 7h45v45q0 10.833 7.116 17.917 7.117 7.083 18 7.083ZM480-480Z"
		/>
	</svg>
);
