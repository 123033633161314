import styled from 'styled-components';

import Icon, { IconName } from 'components/icon';

const ActionItem = styled.div`
	background: var(--palette-gray-700);
	display: grid;
	grid-template-columns: auto 1fr;
	justify-content: start;
	align-items: center;
	align-content: start;
	font-size: var(--font-size-sm);
	color: var(--palette-gray-500);
	gap: var(--spacing-2);
	border-radius: var(--radius-3);
	cursor: pointer;
	transition: box-shadow 150ms ease-in-out;

	@media (hover: hover) {
		&:hover {
			box-shadow:
				0 0 0 1px var(--palette-gray-800),
				0 0 0 4px var(--palette-gray-600);
		}
	}
`;

const ActionIcon = styled.div`
	border-right: 1px solid var(--palette-gray-800);
	display: grid;
	place-items: center;
	height: 100%;
	width: 50px;
	position: relative;

	svg {
		padding: var(--spacing-3);
		width: 100%;
		height: 100%;
	}
`;

const ActionCount = styled.div`
	padding: 3px 6px 2px;
	min-height: 20px;
	min-width: 20px;
	text-align: center;
	background-color: var(--palette-blue-500);
	color: var(--palette-white);
	font-weight: var(--font-weight-semibold);
	font-size: var(--font-size-xs);
	position: absolute;
	right: var(--spacing-2);
	top: var(--spacing-2);
	border-radius: var(--radius-circle);
	line-height: var(--font-line-height-2xs);
`;

const ActionLabel = styled.div`
	padding: var(--spacing-3);
	display: grid;
	grid-auto-flow: row;
	justify-content: start;
	align-items: center;

	span:first-of-type {
		color: var(--palette-white);
		font-weight: var(--font-weight-semibold);
	}

	span:last-of-type {
		display: block;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
`;

interface FormActionProps {
	icon: IconName;
	label: string;
	description: string;
	count?: number;
	onClick?: () => void;
}

export default function FormAction({
	icon,
	label,
	description,
	count,
	onClick,
}: FormActionProps): JSX.Element {
	return (
		<ActionItem onClick={onClick}>
			<ActionIcon>
				<Icon name={icon} />
				{count > 0 && <ActionCount>{count}</ActionCount>}
			</ActionIcon>
			<ActionLabel>
				<span>{label}</span>
				<span>{description}</span>
			</ActionLabel>
		</ActionItem>
	);
}
