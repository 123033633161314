import { ReactNode } from 'react';

export const symbol_looks_6: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M425-277h110q24 0 42-18t18-42v-115q0-24-18-42t-42-18H425v-111h97q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T522-683h-97q-24 0-42 18t-18 42v286q0 24 18 42t42 18Zm0-175h110v115H425v-115ZM180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-60h600v-600H180v600Zm0-600v600-600Z"
		/>
	</svg>
);
