import { ReactNode } from 'react';

export const symbol_how_to_reg: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-160q-24.75 0-42.375-17.625T80-220v-34q0-34 17-62.5t51-43.5q72-32 132-46t120-14q14 0 29 .75t27 2.25q12.121 1.552 20.561 11.638 8.439 10.086 6.439 22.5Q481-372 471.5-364.5 462-357 450-358q-14-1-27-1.5t-23-.5q-58 0-105.5 10.5T172-306q-17 8-24.5 23t-7.5 29v34h319q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T459-160H140Zm485-68 192-192q9-9 21-9t21 9q9 9 9 21t-9 21L646-165q-9 9-21 9t-21-9l-99-99q-9-9-9-21t9-21q9-9 21-9t21 9l78 78ZM400-482q-66 0-108-42t-42-108q0-66 42-108t108-42q66 0 108 42t42 108q0 66-42 108t-108 42Zm45 248Zm-45-308q39 0 64.5-25.5T490-632q0-39-25.5-64.5T400-722q-39 0-64.5 25.5T310-632q0 39 25.5 64.5T400-542Zm0-90Z"
		/>
	</svg>
);
