import {
	createContext,
	Dispatch,
	MutableRefObject,
	SetStateAction,
	useContext,
	useMemo,
	useState,
} from 'react';

import DateTime, { Granularity } from 'pkg/datetime';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';

import { minuteHeight } from 'routes/group/calendar/config';

export const CreateEventDragContext = createContext({
	disabled: false,
	disable: () => {
		// do nothing
	},
	enable: () => {
		// do nothing
	},
});

export const CreateEventDragContextProvider = ({
	children,
}: {
	children: JSX.Element;
}): JSX.Element => {
	const [disabled, setDisabled] = useState(false);

	const disable = () => {
		setDisabled(true);
	};

	const enable = () => {
		setDisabled(false);
	};

	const values = useMemo(
		() => ({
			disabled,
			disable,
			enable,
		}),
		[disabled]
	);

	return (
		<CreateEventDragContext.Provider value={values}>
			{children}
		</CreateEventDragContext.Provider>
	);
};

export const DisableCreateEventDragConsumer = ({
	children,
}: {
	children: JSX.Element;
}): JSX.Element => {
	const ctx = useContext(CreateEventDragContext);

	useComponentDidMount(
		() => {
			ctx.disable();
		},
		() => {
			ctx.enable();
		}
	);

	return children;
};

export interface IMousePosition {
	start?: number;
	current?: number;
	x?: number;
	onTouchDevice?: boolean;
}

export interface IEventData {
	start: number;
	end: number;
	showContextMenu: boolean;
}

export interface ICreateEventDrag {
	mousePosition: IMousePosition;
	setMousePosition: Dispatch<SetStateAction<IMousePosition>>;
	getTimeFromPosition: (
		day: Date,
		startPos: number,
		endPos: number
	) => { startTime: number; endTime: number };
	eventData: IEventData;
	setEventData: Dispatch<SetStateAction<IEventData>>;
	handleMouseDown: (e: any, currentDateObj: Date) => void;
	handleMove: (e: any, currentDateObj: Date) => void;
	handleMouseUp: (e: any, currentDateObj: Date) => void;
	handleLongPress: (e: any, currentDateObj: Date) => void;
	handleTouchMove: (e: any, currentDateObj: Date) => void;
	handleTouchEnd: () => void;
	closeModal: () => void;
}

export const getPositionFromDate = (date: DateTime): number =>
	Math.round((date.getTimestamp() / 1000 - date.startOfDay / 1000) / 60) *
	minuteHeight;

let isScrolling = 0;

const useCreateEventDrag = (
	ref: MutableRefObject<HTMLDivElement>,
	scrollRef?: MutableRefObject<HTMLDivElement>
): ICreateEventDrag => {
	const [mousePosition, setMousePosition] = useState<IMousePosition>({
		start: 0,
		current: 0,
		x: 0,
		onTouchDevice: false,
	});
	const [eventData, setEventData] = useState<IEventData>({
		showContextMenu: false,
		start: 0,
		end: 0,
	});

	const getTimeFromPosition = (
		date: Date,
		startPos: number,
		endPos: number
	) => {
		// Set date to start of day
		date.setHours(0, 0, 0, 0);

		const start = Number.parseFloat(
			(startPos / (minuteHeight * 60)).toFixed(2)
		);
		const end = Number.parseFloat((endPos / (minuteHeight * 60)).toFixed(2));

		const startHour =
			start >= 10
				? Number.parseInt(start.toString().substr(0, 2), 10) * 60
				: Number.parseInt(start.toString().substr(0, 1), 10) * 60;
		const endHour =
			end >= 10
				? Number.parseInt(end.toString().substr(0, 2), 10) * 60
				: Number.parseInt(end.toString().substr(0, 1), 10) * 60;

		// If start is a integer we can set minutes to 0 since it means
		// that we're at the start of an hour e.g. 14.00
		const startMinutes = Number.isInteger(start)
			? 0
			: start > 10
				? Number.parseFloat(start.toString().substr(2, 3))
				: Number.parseFloat(start.toString().substr(1, 3));
		const endMinutes = Number.isInteger(end)
			? 0
			: end > 10
				? Number.parseFloat(end.toString().substr(2, 3))
				: Number.parseFloat(end.toString().substr(1, 3));

		// Round the numbers to the nearest multiple of 5.
		const startTime = new DateTime(date)
			.next(
				Granularity.minute,
				startHour + Math.round((startMinutes * 60) / 5) * 5
			)
			.getUnixTimestamp();
		const endTime = new DateTime(date)
			.next(Granularity.minute, endHour + Math.round((endMinutes * 60) / 5) * 5)
			.getUnixTimestamp();

		return { startTime, endTime };
	};

	const startScrolling = (direction: number) => {
		isScrolling = direction;
		scroll();
	};

	const scroll = () => {
		if (isScrolling === 0) {
			return;
		} else if (isScrolling === -1) {
			scrollRef.current.scrollTop = scrollRef.current.scrollTop - 1;
		} else if (isScrolling === 1) {
			scrollRef.current.scrollTop = scrollRef.current.scrollTop + 1;
		}

		requestAnimationFrame(() => scroll());
	};

	const stopScrolling = () => {
		isScrolling = 0;
	};

	const handleTouchMove = (e: any, currentDateObj: Date) => {
		if (mousePosition.current === 0) return;

		const target = ref.current;
		const rect = target.getBoundingClientRect();
		const scrollRect = scrollRef.current.getBoundingClientRect();
		const y = e.touches[0].clientY - rect.top;

		const topThreshold =
			e.touches[0].clientY - rect.top - scrollRef.current.scrollTop < 15;
		const bottomThreshold = e.touches[0].clientY > scrollRect.bottom - 15;

		if (topThreshold) {
			startScrolling(-1);
		} else if (bottomThreshold) {
			startScrolling(1);
		} else {
			stopScrolling();
		}

		if (y > 0) {
			setMousePosition({
				...mousePosition,
				current: y,
			});
		}

		const dates = getTimeFromPosition(currentDateObj, y, y);

		let endDate = new DateTime(new Date(dates.endTime * 1000))
			.next(Granularity.hour, 1)
			.getUnixTimestamp();

		if (new Date(dates.startTime * 1000).getHours() === 23) {
			endDate = new DateTime(new Date(dates.endTime * 1000)).endOfDay / 1000;
		}

		if (!!dates.startTime && !!dates.endTime) {
			setEventData({
				...eventData,
				start: dates.startTime,
				end: endDate,
			});
		}
	};

	const handleLongPress = (e: any, currentDateObj: Date) => {
		scrollRef.current.style.overflow = 'hidden';
		const target = ref.current;
		const rect = target.getBoundingClientRect();
		const y = e.touches[0].clientY - rect.top;

		setMousePosition({
			...mousePosition,
			start: y,
			current: y,
			onTouchDevice: true,
		});

		const dates = getTimeFromPosition(currentDateObj, y, y);
		setEventData({
			...eventData,
			start: dates.startTime,
			end: new DateTime(new Date(dates.endTime * 1000))
				.next(Granularity.hour, 1)
				.getUnixTimestamp(),
		});
	};

	const handleTouchEnd = () => {
		if (mousePosition.current > 0) {
			scrollRef.current.style.overflow = 'auto';
			setEventData({ ...eventData, showContextMenu: true });
		}
	};

	const handleMouseDown = (e: any, currentDateObj: Date) => {
		if (eventData.showContextMenu) return;

		const target = ref.current;
		const rect = target.getBoundingClientRect();
		const y = e.clientY - rect.top;

		setMousePosition({
			...mousePosition,
			start: y,
			current: y,
		});

		const dates = getTimeFromPosition(currentDateObj, y, y);
		setEventData({
			...eventData,
			start: dates.startTime,
			end: dates.endTime,
		});
	};

	const handleMove = (e: any, currentDateObj: Date) => {
		if (eventData.showContextMenu) return;

		const target = ref.current;
		const rect = target.getBoundingClientRect();
		const y = e.clientY - rect.top;

		setMousePosition({
			...mousePosition,
			current: y,
			x: e.clientX - rect.x,
		});

		const dates = getTimeFromPosition(currentDateObj, mousePosition.start, y);
		setEventData({
			...eventData,
			end: dates.endTime,
		});
	};

	const handleMouseUp = (e: any, currentDateObj: Date) => {
		if (eventData.showContextMenu) return;

		const target = ref.current;
		const rect = target.getBoundingClientRect();
		const y = e.clientY - rect.top;

		if (mousePosition.start !== mousePosition.current) {
			const dates = getTimeFromPosition(currentDateObj, mousePosition.start, y);
			setMousePosition({
				...mousePosition,
				current: y,
			});
			setEventData({
				...eventData,
				end: dates.endTime,
				showContextMenu: true,
			});
		} else {
			setMousePosition({
				...mousePosition,
				start: 0,
				current: 0,
			});
			setEventData({
				...eventData,
				start: 0,
				end: 0,
			});
		}
	};

	const closeModal = () => {
		setEventData({
			...eventData,
			showContextMenu: false,
			start: 0,
			end: 0,
		});
		setMousePosition({
			...mousePosition,
			start: 0,
			current: 0,
		});
	};

	return {
		mousePosition,
		setMousePosition,
		getTimeFromPosition,
		eventData,
		setEventData,
		handleMouseDown,
		handleMove,
		handleMouseUp,
		closeModal,
		handleLongPress,
		handleTouchMove,
		handleTouchEnd,
	};
};

export default useCreateEventDrag;
