import { ReactNode } from 'react';

export const symbol_attach_file_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M481-80q-103 0-175.5-71.5T233-326v-311L84-786q-9-9-9-21.5t9-21.5q9-9 21.5-9t21.5 9l709 709q9 9 8.5 21.5T835-77q-9 9-21 9t-21-9L684-186q-34 48-87 77T481-80ZM293-577v251q0 78 55 132t133 54q51 0 93-24t67-65l-70-70q-13 24-37 39.5T481-244q-43 0-73-29.5T378-346v-146l-85-85Zm145 145v86q0 17 12.5 29.5T481-304q18 0 30-12.5t12-29.5v-1l-85-85Zm230 59v-313q0-13 8.5-21.5T698-716q13 0 21.5 8.5T728-686v373l-60-60ZM523-518v-188q0-48-33.5-81T408-820q-38 0-68 22t-41 56l-46-46q23-42 63.5-67t91.5-25q72 0 123.5 51T583-706v248l-60-60ZM378-663v-23q0-13 8.5-21.5T408-716q13 0 21.5 8.5T438-686v83l-60-60Z"
		/>
	</svg>
);
