import { ReactNode } from 'react';

export const symbol_call_quality: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M755-80q-116 0-236.5-56T295-295Q192-398 136-518.5T80-755q0-19.286 12.857-32.143T125-800h140q14 0 24 10t14 25l26.929 125.641Q332-625 329.5-613.5q-2.5 11.5-10.729 19.726L219-493q26 44 55 82t64 72q37 38 78 69.5t86 55.5l95-98q10-11 23.151-15T646-329l119 26q15 4 25 16.044T800-260v135q0 19.286-12.857 32.143T755-80ZM189-548l81-82-23-110H140q2 42 13.5 88.5T189-548Zm369 363q41 19 89 31t93 14v-107l-103-21-79 83ZM189-548Zm369 363Zm131.894-315Q611-500 555.5-555.606t-55.5-134.5Q500-769 555.606-824.5t134.5-55.5Q769-880 824.5-824.394t55.5 134.5Q880-611 824.394-555.5t-134.5 55.5Zm.106-70q8 0 14-6t6-14v-120q0-8-6-14t-14-6q-8 0-14 6t-6 14v120q0 8 6 14t14 6Zm0-200q8 0 14-6t6-14q0-8-6-14t-14-6q-8 0-14 6t-6 14q0 8 6 14t14 6Z"
		/>
	</svg>
);
