import { Record } from 'immutable';
import { schema } from 'normalizr';

import { taxRates } from 'pkg/models/schemas';

interface ITaxRate {
	id: number;
	active: boolean;
	createdAt: number;
	description: string;
	display_name: string;
	inclusive: boolean;
	country: string;
	jurisdiction: string;
	state: string;
	percentage: number;
}

const taxRateProps: ITaxRate = {
	id: 0,
	active: null,
	createdAt: 0,
	description: '',
	display_name: '',
	inclusive: null,
	country: '',
	state: '',
	jurisdiction: '',
	percentage: null,
};

class TaxRate
	extends Record(taxRateProps, 'TaxRateRecord')
	implements ITaxRate
{
	constructor(args?: any) {
		if (args?.id) {
			args.id = Number.parseInt(args.id, 10);
		}

		super(args);
	}
	static normalizr(): schema.Entity {
		return taxRates;
	}
}

export default TaxRate;
