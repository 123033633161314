import { ReactNode } from 'react';

export const symbol_explore_nearby: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-202q40-41 71.652-84.098 27.131-36.813 49.739-80.808Q624-490.902 624-534q0-60-42-102t-102-42q-60 0-102 42t-42 101.632q0 43.368 22.609 87.389 22.608 44.022 49.739 80.856Q440-323 480-282Zm0-198q-22.5 0-38.25-15.75T426-534q0-22.5 15.75-38.25T480-588q22.5 0 38.25 15.75T534-534q0 22.5-15.75 38.25T480-480Z"
		/>
	</svg>
);
