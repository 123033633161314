import { useContext } from 'react';
import { t } from '@transifex/native';

import { FabricContext } from 'components/drawing/FabricContext';
import { Section } from 'components/drawing/edit/Pane';
import ToggleSwitch from 'components/form/Toggle';
import { Flex, Label } from 'components/drawing/edit/Tools';
import RangeSlider from 'components/form/RangeSlider';

import Button from 'design/button';

const _context = 'training_library/drawing';

export default function SettingsPane({ className }: any) {
	const {
		canvas,
		settings,
		setSetting,
		setSlide,
		slides,
		setSlides,
		duration,
		setDuration,
	} = useContext(FabricContext);

	const openBackgroundModal = () => {
		setSetting('backgroundModalOpen', true);
	};

	const clear = () => {
		// remove all objects, keep background and grid
		if (window.confirm(t(`Are you sure you want to clear the drawing?`))) {
			canvas.current.remove(
				...canvas.current
					.getObjects()
					.filter((obj: any) => obj.id !== 'background' && obj.id !== 'grid')
			);
			setSlides([{}]);
			setSlide(0);
		}
	};

	return (
		<div className={className}>
			<Section spaced>
				<Button block icon="image" onClick={openBackgroundModal}>
					{t(`Select background`)}
				</Button>
				<Button block icon="block" onClick={clear}>
					{t(`Clear drawing`)}
				</Button>
			</Section>
			{slides.length > 1 && (
				<Section spaced>
					<Flex>
						<Label>{t('Step duration', { _context })}</Label>
						<RangeSlider
							type="range"
							step={100}
							min={500}
							max={10000}
							value={duration}
							onChange={(value: number) => {
								setDuration(value);
							}}
						/>
						<div style={{ width: '10ch' }}>{duration / 1000}s</div>
					</Flex>
				</Section>
			)}
			<Section spaced>
				<ToggleSwitch
					label={t(`Toggle grid`)}
					standAlone
					isActive={!!settings.snapping}
					onChange={(active: boolean) => {
						setSetting('snapping', !active ? 0 : 40);
					}}
				/>
				<ToggleSwitch
					label={t(`Visible grid`)}
					standAlone
					disabled={!settings.snapping}
					isActive={settings.snappingVisible && !!settings.snapping}
					onChange={(active: boolean) => {
						setSetting('snappingVisible', active);
					}}
				/>
				<Flex>
					<Label>{t(`Grid size`)}</Label>
					<RangeSlider
						type="range"
						step={1}
						min={10}
						max={100}
						value={settings.snapping}
						onChange={(value: number) => {
							setSetting('snapping', value);
						}}
					/>
				</Flex>
			</Section>
		</div>
	);
}
