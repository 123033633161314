import { t } from '@transifex/native';
import styled, { css } from 'styled-components';
import { useEffect, useState } from 'react';

import * as styles from 'pkg/config/styles';

const Message = styled.div<{ failed?: boolean }>`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin: var(--spacing-2) 0 var(--spacing-3);
	color: var(--palette-gray-500);
	font-size: var(--font-size-sm);
	min-height: 16px;

	@media ${styles.breakpoint.small} {
		font-size: var(--font-size-xs);
	}

	${({ failed }) =>
		failed &&
		css`
			color: var(--palette-red-400);
		`}
`;

export const MessageFailed = ({
	onClick,
}: {
	onClick: () => void;
}): JSX.Element => {
	return (
		<Message onClick={onClick} failed>
			{t('Not delivered - Tap to retry')}
		</Message>
	);
};

export const MessageSent = (): JSX.Element => {
	return <Message>{t('Sent')}</Message>;
};

export const MessageSending = (): JSX.Element => {
	const [showSending, setShowSending] = useState(false);

	useEffect(() => {
		setInterval(() => setShowSending(true), 1000);
	}, []);

	if (!showSending) {
		return null;
	}

	return <Message>{t('Sending')}</Message>;
};
