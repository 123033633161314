import { ReactNode } from 'react';

export const symbol_backpack: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M220-80q-24.75 0-42.375-17.625T160-140v-510q0-55 34-95.5t86-50.5v-54q0-12.75 8.625-21.375T310-880h40q12.75 0 21.375 8.625T380-850v50h200v-50q0-12.75 8.625-21.375T610-880h40q12.75 0 21.375 8.625T680-850v54q52 10 86 50.5t34 95.5v510q0 24.75-17.625 42.375T740-80H220Zm0-60h520v-510q0-38-26-64t-64-26H310q-37.125 0-63.562 26Q220-688 220-650v510Zm400-270v50q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T680-360v-80q0-12.75-8.625-21.375T650-470H310q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T310-410h310Zm-140-30Z"
		/>
	</svg>
);
