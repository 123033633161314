import {
	APP_SET_DARK_MODE,
	APP_SET_TABBAR_VISIBILITY,
} from 'pkg/actions/action-types';

import * as actions from 'pkg/actions';
import * as fs from 'pkg/fullscreen';
import store from 'pkg/store/createStore';

import { getCurrentAppStateContextValues } from 'components/application';

import * as drawer from 'components/application/drawer';

import { RouteProps } from './index';

export const useDarkMode = (props: RouteProps): RouteProps => {
	const state = store.getState();

	if (state.app.darkMode === false) {
		store.dispatch({
			type: APP_SET_DARK_MODE,
			darkMode: true,
		});
	}

	return props;
};

export const unsetDarkMode = (props: RouteProps): RouteProps => {
	const state = store.getState();

	if (state.app.darkMode === true && !props?.name.includes('kitchen-sink')) {
		store.dispatch({
			type: APP_SET_DARK_MODE,
			darkMode: false,
		});
	}

	return props;
};

export const showTabBar = (props: RouteProps): RouteProps => {
	const state = store.getState();

	if (state.app.tabBarVisible === false) {
		store.dispatch({
			type: APP_SET_TABBAR_VISIBILITY,
			tabBarVisible: true,
		});
	}

	return props;
};

export const hideTabBar = (props: RouteProps): RouteProps => {
	const state = store.getState();

	if (state.app.tabBarVisible === true) {
		store.dispatch({
			type: APP_SET_TABBAR_VISIBILITY,
			tabBarVisible: false,
		});
	}

	return props;
};

export const exitFullscreen = (props: RouteProps): RouteProps => {
	if (fs.inFullscreen()) {
		fs.exitFullscreen();
	}

	return props;
};

export const hideDrawer = (props: RouteProps) => {
	const state = store.getState();

	if (state.app.visibleDrawer === true) {
		actions.app.setDrawerVisibility(false);
	}

	return props;
};

export const expandDrawer = () => {
	const state = getCurrentAppStateContextValues();

	drawer.expandDrawer(state, false);
};

export const collapseDrawer = () => {
	const state = getCurrentAppStateContextValues();

	drawer.collapseDrawer(state, false);
};
