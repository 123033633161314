import { ReactNode } from 'react';

export const symbol_playing_cards: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m610-409 29-105q2-9-1-18t-11-14l-90-62q-6-5-13.341-2.118Q516.318-607.235 514-599l-29 105q-2 9 1 18t11 14l90 62q6 5 13.341 2.118Q607.682-400.765 610-409ZM195-160l-66-27q-32.17-14.026-43.585-48.013Q74-269 92-302l103-245v387Zm142 58q-33.825 0-57.913-24Q255-150 255-184v-313l137 368q3 8 6.5 14t9.5 13h-71Zm183-25q-23 8-45.932-2.203Q451.137-139.407 443-162L251-684q-8-23 2.441-45.875Q263.881-752.75 287-761l319-115q23-8 46.5 2t31.5 33l193 521q8 23-2.203 45.875Q864.593-251.25 842-243L520-127Zm-20-57 318-116-191-519-318 115 191 520Zm64-318Z"
		/>
	</svg>
);
