// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aZ2u6E21bEdAvhYT7URr {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-flow: column nowrap;
	        flex-flow: column nowrap;
	max-height: 100%;
	min-height: 100%;
	min-width: 0;
	overflow: hidden;
}
.aZ2u6E21bEdAvhYT7URr.N3Xn5TsYr5F8PWXYHoRW {
		padding: 0 var(--spacing-4);
	}
`, "",{"version":3,"sources":["webpack://./components/layout/side-bar-page/styles.css"],"names":[],"mappings":"AAAA;CACC,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,4BAAwB;CAAxB,6BAAwB;KAAxB,4BAAwB;SAAxB,wBAAwB;CACxB,gBAAgB;CAChB,gBAAgB;CAChB,YAAY;CACZ,gBAAgB;AAKjB;AAHC;EACC,2BAA2B;CAC5B","sourcesContent":[".table {\n\tdisplay: flex;\n\tflex-flow: column nowrap;\n\tmax-height: 100%;\n\tmin-height: 100%;\n\tmin-width: 0;\n\toverflow: hidden;\n\n\t&.fullPageTable {\n\t\tpadding: 0 var(--spacing-4);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var table = `aZ2u6E21bEdAvhYT7URr`;
export var fullPageTable = `N3Xn5TsYr5F8PWXYHoRW`;
export default ___CSS_LOADER_EXPORT___;
