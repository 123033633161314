import { ReactNode } from 'react';

export const symbol_save_as: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-120q-24.75 0-42.375-17.625T120-180v-600q0-24.75 17.625-42.375T180-840h478q12.444 0 23.722 5T701-822l121 121q8 8 13 19.278 5 11.278 5 23.722v168q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T780-490v-166L656-780H180v600h250q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T430-120H180Zm0-660v600-600ZM520-70v-81q0-5.565 2-10.783Q524-167 529-172l211.612-210.773q9.113-9.12 20.25-13.174Q772-400 783-400q12 0 23 4.5t20 13.5l37 37q9 9 13 20t4 22q0 11-4.5 22.5t-13.583 20.624L652-49q-5 5-10.217 7-5.218 2-10.783 2h-81q-12.75 0-21.375-8.625T520-70Zm300-233-37-37 37 37ZM580-100h38l121-122-18-19-19-18-122 121v38Zm141-141-19-18 37 37-18-19ZM263-584h298q12.75 0 21.375-8.625T591-614v-83q0-12.75-8.625-21.375T561-727H263q-12.75 0-21.375 8.625T233-697v83q0 12.75 8.625 21.375T263-584Zm217 339q14 0 27-3t24-10l39-40q7-11 10.5-24t3.5-27q0-43.333-30.265-73.667Q523.471-453 480.235-453 437-453 406.5-422.735q-30.5 30.264-30.5 73.5Q376-306 406.333-275.5 436.667-245 480-245Z"
		/>
	</svg>
);
