import { Record, Map } from 'immutable';

import Account from 'pkg/models/account';

import { Accounts, AUTH_LOGOUT_SUCCESS } from 'pkg/actions/action-types';

interface IAccountReducer {
	entities: Map<number, Account>;
}

class AccountReducer
	extends Record<IAccountReducer>(
		{
			entities: Map<number, Account>(),
		},
		'accounts'
	)
	implements IAccountReducer
{
	entities: Map<number, Account>;
}

const initialState = new AccountReducer({
	entities: Map<number, Account>(),
});

const accounts = (state = initialState, action: any): AccountReducer => {
	switch (action.type) {
		case Accounts.SET_ITEMS: {
			const accounts = action.payload.entities.accounts;

			for (const idString in accounts) {
				const id = Number.parseInt(idString, 10);
				const existing = state.entities.get(id);

				if (
					existing &&
					((existing._s && !accounts[id]._s) ||
						(existing._s && accounts[id]._s) ||
						(!existing._s && !accounts[id]._s))
				) {
					state = state.updateIn(
						['entities', id],
						() => new Account(accounts[id])
					);
				} else if (!existing) {
					state = state.setIn(['entities', id], new Account(accounts[id]));
				}
			}

			return state;
		}

		case AUTH_LOGOUT_SUCCESS:
			return initialState;

		default:
			return state;
	}
};

export default accounts;
