import { ReactNode } from 'react';

export const symbol_video_settings: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M483-140H140q-24 0-42-18t-18-42v-540q0-24 18-42t42-18h694q24 0 42 18t18 42v216q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T834-524v-216H140v540h343q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T483-140Zm-88-199v-262q0-13.886 12-20.443Q419-628 430-620l199 131q11 6.805 11 18.902Q640-458 629-451L430-320q-11 8-23 1.443T395-339ZM729.091-52.627 725-88q-20-6-41-17.5T650-131l-30.1 13.867Q615-115 609.5-116t-8.875-5.846l-20.25-32.308Q577-159 578-164t6-9l27-22q-5-23-5-41.5t5-41.5l-27-22q-5-4-6-9t2.375-9.962l20.25-33.076Q604-357 609.5-358t10.4 1.133L650-343q13-12 34-24t41-18l4.091-36.235Q730-427 734-430.5q4-3.5 9.625-3.5h33.75q5.625 0 9.625 3.5t4.909 9.265L796-385q20 6 41 18t34 24l30-14.118q5-1.882 10.5-.882 5.5 1 8.875 5.778l20.25 33.444Q944-314 943-309q-1 5-6 9l-27 22q5 23 5 41.5t-5 41.5l27 22q5 4 6 9.25t-2 9.75l-20.571 32.174Q917-117 911.5-116t-10.4-1.133L871-131q-13 14-34 25.5T796-88l-4.091 35.373Q791-47 787-43.5q-4 3.5-9.625 3.5h-33.75Q738-40 734-43.5t-4.909-9.127ZM760-135q44 0 73-29t29-73q0-44-29-73t-73-29q-44 0-73 29t-29 73q0 44 29 73t73 29Z"
		/>
	</svg>
);
