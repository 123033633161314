import { ReactNode } from 'react';

export const symbol_bus_alert: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-724h299-299Zm82 293h-82 520-438ZM178.941-80Q161-80 148.5-92.542 136-105.083 136-123v-68q-26-15-41-44.5T80-301v-397q0-71 70.5-101.5T407-835q13.175 0 22.087 8Q438-819 438-805.877q0 13.123-8.289 22Q421.421-775 408-775q-136 2-195 13.5T140-724h255q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T395-664H140v173h431q13.077 0 21.538 8.675 8.462 8.676 8.462 21.5 0 12.825-8.625 21.325T571-431H140v120q0 36 24.5 62.5T222-222h356q33 0 57.5-26.5T660-311v-82q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T720-393v92q0 36-15 65.5T664-191v68q0 17.917-12.559 30.458Q638.882-80 620.941-80 603-80 590.5-92.542 578-105.083 578-123v-39H222v39q0 17.917-12.559 30.458Q196.882-80 178.941-80Zm510.246-418Q610-498 554-554.011q-56-56.01-56-135.478Q498-768 554-824t135-56q80 0 135.5 55.5T880-689q0 80-55.813 135.5-55.812 55.5-135 55.5ZM692-659q8 0 12.5-5t4.5-13v-108q0-8-5-12.5t-13-4.5q-8 0-12.5 5t-4.5 13v108q0 8 5 12.5t13 4.5Zm-.5 85q9.5 0 16-6t6.5-16q0-10-6.6-16t-15.4-6q-11 1-16.5 7t-5.5 15.5q0 9.5 6 15.5t15.5 6ZM246.059-272Q269-272 285-288.059q16-16.059 16-39T284.941-366q-16.059-16-39-16T207-365.941q-16 16.059-16 39T207.059-288q16.059 16 39 16Zm308 0Q577-272 593-288.059q16-16.059 16-39T592.941-366q-16.059-16-39-16T515-365.941q-16 16.059-16 39T515.059-288q16.059 16 39 16Z"
		/>
	</svg>
);
