import { ReactNode } from 'react';

export const symbol_mimo_disconnect: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m139-840 59 60h-58v480h455L52-842q-9-9-8.5-21.5t9.5-21q9-8.5 21.467-8.5 12.466 0 20.533 9l741 748q7 7 7.5 17t-5.5 18q-8 10-19.5 10.5T798-98L652-240l39 38q5 5 7 10.543 2 5.544 2 11.457v30q0 12.75-8.625 21.375T670-120H290q-12.75 0-21.375-8.625T260-150v-31q0-5.565 2-10.783Q264-197 269-202l38-38H140q-24 0-42-18t-18-42v-480q0-24 18-42t41-18Zm681 0q12.196 0 22.598 5t18.902 13.5q8.5 8.5 13.5 18.902 5 10.402 5 22.598v510q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T820-270v-510H297q-14.5 0-21.75-9.318t-7.25-20.5q0-11.182 7.25-20.682Q282.5-840 297-840h523ZM553-513Zm-186-14Z"
		/>
	</svg>
);
