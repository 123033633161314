import { Map } from 'immutable';
import { createSelector } from 'reselect';

import AccountRelation from 'pkg/models/account_relation';

import * as accounts from 'pkg/selectors/accounts';

import { RootState } from 'pkg/reducers';

const emptyRelation = new AccountRelation();

function findAll(state: RootState): Map<number, AccountRelation> {
	return state.accountRelations.entities;
}

function find(state: RootState, accountRelationId: number): AccountRelation {
	const relation = findAll(state).get(accountRelationId);

	if (!relation) {
		return emptyRelation;
	}

	return relation
		.set('account', accounts.find(state, relation.accountId))
		.set('targetAccount', accounts.find(state, relation.targetAccountId));
}

export const findActiveRequest = createSelector(
	(state: RootState) => state,
	(state: RootState) => state.accountRelations.activeRequestItemId,
	(state: RootState, itemId: number) => find(state, itemId)
);
