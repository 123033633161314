// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._a7uYusZcf1PzN5ExFPG {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	gap: var(--spacing-6);
	position: relative;
}

.DUlvo51ecdTP2fKYUTjw {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
}

.FCsHd5JF8KPOYWzKlX6g {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: end;
	    -ms-flex-align: end;
	        align-items: flex-end;
	gap: var(--spacing-4);
}

.FCsHd5JF8KPOYWzKlX6g span {
		color: var(--palette-gray-600);
		font-size: var(--font-size-sm);
		text-transform: lowercase;
		padding-bottom: 1px;
	}

.FCsHd5JF8KPOYWzKlX6g.CuVw7IICJXjWZY8X3y8z {
		color: var(--palette-blue-500);
	}

.FCsHd5JF8KPOYWzKlX6g.CuVw7IICJXjWZY8X3y8z span {
			color: var(--palette-blue-500);
		}

.FCsHd5JF8KPOYWzKlX6g div {
		font-weight: var(--font-weight-semibold);
		font-size: var(--font-size-lg);
	}

.DPFdr0IAjP6ccSaMNJCG {
	color: var(--palette-gray-500);
}

.OTKPnp81JYau153kIqfQ {
	color: var(--palette-gray-500);
	font-weight: var(--font-weight-thin);
	text-align: center;
}
`, "",{"version":3,"sources":["webpack://./routes/group/calendar/views/schedule/styles.css"],"names":[],"mappings":"AAAA;CACC,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,4BAAsB;CAAtB,6BAAsB;KAAtB,0BAAsB;SAAtB,sBAAsB;CACtB,qBAAqB;CACrB,kBAAkB;AACnB;;AAEA;CACC,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,4BAAsB;CAAtB,6BAAsB;KAAtB,0BAAsB;SAAtB,sBAAsB;AACvB;;AAEA;CACC,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,sBAAqB;KAArB,mBAAqB;SAArB,qBAAqB;CACrB,qBAAqB;AAqBtB;;AAnBC;EACC,8BAA8B;EAC9B,8BAA8B;EAC9B,yBAAyB;EACzB,mBAAmB;CACpB;;AAEA;EACC,8BAA8B;CAK/B;;AAHC;GACC,8BAA8B;EAC/B;;AAGD;EACC,wCAAwC;EACxC,8BAA8B;CAC/B;;AAGD;CACC,8BAA8B;AAC/B;;AAEA;CACC,8BAA8B;CAC9B,oCAAoC;CACpC,kBAAkB;AACnB","sourcesContent":[".scheduleWrapper {\n\tdisplay: flex;\n\tflex-direction: column;\n\tgap: var(--spacing-6);\n\tposition: relative;\n}\n\n.scheduleDay {\n\tdisplay: flex;\n\tflex-direction: column;\n}\n\n.dayDate {\n\tdisplay: flex;\n\talign-items: flex-end;\n\tgap: var(--spacing-4);\n\n\t& span {\n\t\tcolor: var(--palette-gray-600);\n\t\tfont-size: var(--font-size-sm);\n\t\ttext-transform: lowercase;\n\t\tpadding-bottom: 1px;\n\t}\n\n\t&.isToday {\n\t\tcolor: var(--palette-blue-500);\n\n\t\t& span {\n\t\t\tcolor: var(--palette-blue-500);\n\t\t}\n\t}\n\n\t& div {\n\t\tfont-weight: var(--font-weight-semibold);\n\t\tfont-size: var(--font-size-lg);\n\t}\n}\n\n.planned {\n\tcolor: var(--palette-gray-500);\n}\n\n.noEvents {\n\tcolor: var(--palette-gray-500);\n\tfont-weight: var(--font-weight-thin);\n\ttext-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
export var scheduleWrapper = `_a7uYusZcf1PzN5ExFPG`;
export var scheduleDay = `DUlvo51ecdTP2fKYUTjw`;
export var dayDate = `FCsHd5JF8KPOYWzKlX6g`;
export var isToday = `CuVw7IICJXjWZY8X3y8z`;
export var planned = `DPFdr0IAjP6ccSaMNJCG`;
export var noEvents = `OTKPnp81JYau153kIqfQ`;
export default ___CSS_LOADER_EXPORT___;
