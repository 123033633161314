import { UserFieldType } from 'pkg/api/models/user_fields';
import { FilterGroups, FilterValue } from 'pkg/filters/use_filters';
import { FilterOperator, createFilter } from 'pkg/filters';
import { useCurrentOrganization } from 'pkg/identity';
import { useCollection } from 'pkg/api/use_collection';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';

const useUserFieldsFilter = (): FilterGroups => {
	const organization = useCurrentOrganization();
	const { records: userFields } = useCollection<models.userFields.UserField>(
		endpoints.UserField.Index(),
		{
			queryParams: new URLSearchParams({
				group_id: organization.id.toString(),
			}),
		}
	);

	const filterGroups: FilterGroups = {};

	for (const field of (userFields || []).filter(
		(field) =>
			field.type !== UserFieldType.Reserved &&
			field.type !== UserFieldType.Attachment
	)) {
		switch (field.type) {
			case UserFieldType.Choice:
			case UserFieldType.MultiChoice:
				const values: { [key: string]: FilterValue } = {};

				for (const value of field.values) {
					values[value.label] = value.label;
				}

				filterGroups[field.label] = {
					filters: {
						[field.label]: createFilter({
							type: 'checkbox',
							property: `field.${field.key}`,
							operator: FilterOperator.Includes,
							values,
						}),
					},
				};
				break;
			case UserFieldType.Date:
				filterGroups[field.label] = {
					filters: {
						[field.label]: createFilter({
							type: 'date',
							property: `field.${field.key}`,
						}),
					},
				};
				break;
			// default will be all types that use "FilterOperator.Contains"
			default:
				filterGroups[field.label] = {
					filters: {
						[field.label]: createFilter({
							type: 'text',
							property: `field.${field.key}`,
							operator: FilterOperator.Contains,
						}),
					},
				};
				break;
		}
	}

	return filterGroups;
};

export default useUserFieldsFilter;
