import { t } from '@transifex/native';
import * as React from 'react';
import { List } from 'immutable';

import User from 'pkg/models/user';

import * as routes from 'pkg/router/routes';
import { useCurrentOrganization } from 'pkg/identity';

import CardAnatomy, * as Card from 'components/Card';
import SectionTitle from 'components/SectionTitle';

import Column from 'components/layout/column';
import PhysicalStrainIndicator from 'components/physical-strain/Bar';

import Button from 'design/button';

type PhysicalStrainProps = {
	user: User;
	users: List<User>;
	groupId?: number;
	isSelfOrParentToUserOrAdmin?: boolean;
};

const PhysicalStrain: React.FC<
	React.PropsWithChildren<PhysicalStrainProps>
> = ({ user, users, groupId, isSelfOrParentToUserOrAdmin }) => {
	const org = useCurrentOrganization();

	const averageStrain: number = Math.floor(
		users.reduce(
			(average: string, user: User) =>
				(average =
					parseInt(average, 10) + user.meta.get('current-physical-strain') ||
					0),
			0
		) / users.size
	);

	const getTeamOffset = (): number => {
		if (averageStrain <= 25 && averageStrain > 5) {
			return averageStrain;
		}

		if (averageStrain >= 85 && averageStrain < 95) {
			return 50 + averageStrain / 2;
		}

		if (averageStrain <= 5) {
			return 0;
		}

		if (averageStrain >= 95) {
			return 100;
		}

		return 50;
	};

	return (
		<Column>
			<SectionTitle>
				{t(`Physical strain`)}

				{isSelfOrParentToUserOrAdmin && (
					<Button
						inline
						noPadding
						icon="chevron_right"
						iconPosition="right"
						href={routes.User.Profile.Show(org.id, groupId, user.id, 'stats')}>
						{t(`View activities`)}
					</Button>
				)}
			</SectionTitle>
			<CardAnatomy $noBorder>
				<Card.Body>
					<PhysicalStrainIndicator
						userStrainLevel={user.meta.get('current-physical-strain')}
						teamStrainLevel={getTeamOffset()}
					/>
				</Card.Body>
			</CardAnatomy>
		</Column>
	);
};

export default PhysicalStrain;
