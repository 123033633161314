import { useEffect, useMemo, Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { List } from 'immutable';
import { t } from '@transifex/native';

import * as palette from 'pkg/config/palette';
import { small, large, nolimit } from 'pkg/config/breakpoints';
import { size } from 'pkg/config/fonts';
import { PageWidths } from 'pkg/config/sizes';

import Session from 'pkg/models/session';
import SessionBlock from 'pkg/models/session_block';

import * as routes from 'pkg/router/routes';
import { setConfirmNavigation } from 'pkg/router/utils';
import { replaceState, pushState, popState } from 'pkg/router/state';
import { only } from 'pkg/objects';
import rgba from 'pkg/rgba';
import uuid from 'pkg/uuid';
import * as multisort from 'pkg/multisort';
import * as url from 'pkg/url';
import * as selectors from 'pkg/selectors';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import store from 'pkg/store/createStore';
import * as actions from 'pkg/actions';
import { useCurrentOrganization, useCurrentUser } from 'pkg/identity';

import TagForm from 'containers/tag/TagForm';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import DelayedTextInput from 'components/form/DelayedTextInput';
import Toggle from 'components/form/Toggle';
import * as ActionBar from 'components/layout/ActionBar';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import { Spinner } from 'components/loaders/spinner';

import Button from 'design/button';

import Block from './Block';

const Blocks = styled.div`
	display: grid;
	grid-auto-flow: row;
	grid-gap: 30px;
`;

const MovableBlocksWrapper = styled.div`
	display: grid;
	grid-auto-flow: row;
	grid-gap: 30px;

	@media ${small} {
		grid-gap: 0px;
	}
`;

const AddButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	@media ${small} {
		padding-bottom: 140px;
		margin-top: -20px;
	}

	@media ${large}, ${nolimit} {
		max-width: calc(100% - 70px);
	}
`;

const AddButton = styled(Button)`
	&& {
		padding: 0;
		width: 50px;
		height: 50px;
		display: inline-block;
		position: relative;

		svg {
			font-size: ${size['2xl']};
		}

		&::before {
			content: '';
			display: block;
			width: 0;
			height: 30px;
			position: absolute;
			left: 50%;
			bottom: 100%;
			transform: translateX(-1px);
			border-left: 2px dashed ${rgba(palette.gray[500], 0.7)};
		}
	}
`;

AddButton.defaultProps = {
	large: true,
	icon: 'add',
};

const SessionData = styled.div`
	display: grid;
	grid-auto-flow: row;
	grid-gap: 1rem;

	@media ${large}, ${nolimit} {
		max-width: calc(100% - 70px);
	}
`;

const EstimatedDuration = styled.p`
	font-size: 13px;
	text-align: center;

	time {
		font-weight: var(--font-weight-bold);
	}
`;

const Divider = styled.hr`
	display: block;
	height: 0;
	width: 25%;
	margin: 20px auto;
	border: none;
	border-bottom: 1px solid ${palette.gray[300]};

	@media ${small} {
		margin: 0px auto;
	}
`;

const Edit = (props) => {
	const org = useCurrentOrganization();

	let isCreate = false;
	if (!props.sessionId) {
		isCreate = true;
	}

	const sessionId = useMemo(
		() => (props.sessionId ? props.sessionId : uuid()),
		[]
	);

	const session = useSelector((state) =>
		selectors.sessions.find(state, { sessionId })
	);

	const [sessionLoaded, setSessionLoaded] = useState(!!session?.id || isCreate);

	const sessionMode = useSelector((state) =>
		selectors.sessions.findSessionMode(state, { sessionId })
	);
	const sessionTags = useSelector((state) =>
		selectors.sessions.findAllTags(state, { sessionId })
	);
	const sessionDuration = useSelector((state) =>
		selectors.sessionBlocks.combinedDuration(state, { sessionId })
	);
	const sessionBlocks = useSelector((state) =>
		selectors.sessionBlocks.findAll(state, { sessionId })
	);
	const sessionItems = useSelector((state) =>
		selectors.sessionItems.findAllBySession(state, { sessionId })
	);
	const hasChangedSessionItems = useSelector((state) =>
		selectors.sessionItems.hasChanges(state, { sessionId })
	);

	const isKeyboardOpen = useSelector((state) => state.app.keyboardOpen);
	const isEventSession = session?.eventId || !!url.query().eventId;

	const [isSaving, setIsSaving] = useState(false);
	const [hasTagChanges, setHasTagChanges] = useState(false);
	const [tags, setTags] = useState(new List([]));
	const [playerVisible, setPlayerVisible] = useState(false);

	useComponentDidMount(
		async () => {
			if (!isCreate) {
				await store.dispatch(actions.sessions.fetchSession(org.id, sessionId));
			} else {
				await store.dispatch(
					actions.sessions.createSession(
						org.id,
						new Session({ id: sessionId }).toJS()
					)
				);

				if (sessionBlocks.size === 0) {
					store.dispatch(
						actions.sessionBlocks.createSessionBlock(
							org.id,
							sessionId,
							new SessionBlock({
								id: uuid(),
								duration: 20,
								order: 1,
							}).toJS()
						)
					);
				}
			}
		},
		() => {
			setConfirmNavigation(null);
			store.dispatch(actions.sessions.flushSession(session?.id));
		}
	);

	useEffect(() => {
		if (!session?.id) {
			return;
		}

		if (!sessionLoaded) {
			setSessionLoaded(true);
		}

		if (session.eventId) {
			actions.events.getEventUsers(session.eventId);
		}

		if (session.playerVisible) {
			setPlayerVisible(session.playerVisible);
		}

		if (sessionTags?.size > 0) {
			setTags(sessionTags);
		}
	}, [session?.id]);

	const onTagSelect = (tag) => {
		let newTags = tags;
		newTags = tags.push(tag);
		setTags(newTags);
		setHasTagChanges(true);
	};

	const onTagCreate = (tag) => {
		let newTags = tags;
		newTags = tags.push(tag);
		setTags(newTags);
		setHasTagChanges(true);
	};

	const onTagRemove = (removedTag) => {
		let newTags = tags;
		newTags = tags.filter((tag) => tag.get('name') !== removedTag.get('name'));
		setTags(newTags);
		setHasTagChanges(true);

		if (!isCreate && removedTag.get('id') !== 0) {
			store.dispatch(
				actions.sessions.deleteSessionTag(
					org.id,
					session?.id,
					removedTag.get('id')
				)
			);
		}
	};

	const onPlayerVisibleChange = (visible) => {
		setPlayerVisible(visible);
		setHasTagChanges(true);
	};

	const handleUpdateSessionTitle = (title) => {
		store.dispatch(
			actions.sessions.updateSession(org.id, session?.id, { title })
		);
	};

	const handleUpdateSessionDescription = (description) => {
		store.dispatch(
			actions.sessions.updateSession(org.id, session?.id, { description })
		);
	};

	const handleUpdateBlock = (sessionBlockId, payload) => {
		payload.trainingSessionId = session?.id;
		store.dispatch(
			actions.sessionBlocks.updateSessionBlock(org.id, sessionBlockId, payload)
		);
	};

	const handleCreateBlock = () => {
		store.dispatch(actions.sessions.setSessionModeHasChanges(session?.id));

		store.dispatch(
			actions.sessionBlocks.createSessionBlock(org.id, session?.id, {
				id: uuid(),
				duration: 20,
				order: (sessionBlocks.last()?.get('order') || 0) + 1,
			})
		);
	};

	const handleDeleteBlock = (id) => {
		setConfirmNavigation(null);
		store.dispatch(actions.sessionBlocks.deleteSessionBlock(org.id, id));
	};

	const canSave = () => {
		if (isSaving || isKeyboardOpen) {
			return false;
		}

		const hasChanges = sessionMode?.get('hasChanges') === true;
		const hasTitle = session?.get('title').length > 2;

		if (hasTitle && hasTagChanges === true) {
			return true;
		}

		return (hasTitle && hasChanges) || hasChangedSessionItems;
	};

	const create = async () => {
		let { eventId } = url.query();

		if (eventId) {
			eventId = Number.parseInt(eventId, 10);
		}

		const sessionPayload = session
			.set('id', null)
			.set('playerVisible', playerVisible)
			.toJS();

		if (eventId !== 0) {
			sessionPayload.eventId = eventId;
		}

		// Attach tags to payload
		sessionPayload.tags = tags.map((tag) => tag.get('name'));

		sessionPayload.blocks = sessionBlocks.map((block) => {
			const items = sessionItems
				.filter((i) => i.get('trainingSessionBlockId') === block.get('id'))
				.map((i) => i.set('trainingSessionBlockId', null).set('id', null));
			return block
				.set('id', null)
				.set('trainingSessionId', null)
				.set('items', items)
				.toJS();
		});

		const newSession = await store.dispatch(
			actions.sessions.createSession(org.id, sessionPayload)
		);

		return newSession;
	};

	const update = async () => {
		// Only pass fields that we can change
		const sessionPayload = only(
			session.toJS(),
			'title',
			'description',
			'tags',
			'userId',
			'eventId',
			'playerVisible'
		);

		sessionPayload.tags = tags
			.filter((tag) => !sessionPayload.tags.includes(tag))
			.map((tag) => tag.get('name'));
		sessionPayload.playerVisible = playerVisible;

		sessionPayload.blocks = sessionBlocks.map((block) => {
			const items = sessionItems
				.filter((i) => i.get('trainingSessionBlockId') === block.get('id'))
				.map((i) => {
					if (typeof i.get('id') === 'string') {
						return i.set('id', null);
					}
					return i;
				});
			if (typeof block.get('id') === 'string') {
				block = block.set('id', null);
			}
			return block.set('items', items).toJS();
		});

		await store.dispatch(
			actions.sessions.updateSession(org.id, session.id, sessionPayload, true)
		);

		return session;
	};

	const save = async () => {
		let session;
		setIsSaving(true);

		if (isCreate) {
			session = await create();
		} else {
			session = await update();
		}

		setConfirmNavigation(null);
		store.dispatch(actions.sessions.flushSession(sessionId));

		if (isEventSession) {
			if (isCreate) {
				replaceState(routes.Event.View(org.id, session.eventId, 'sessions'));
			} else {
				pushState(routes.Event.View(org.id, session.eventId, 'sessions'));
			}
		} else {
			if (isCreate) {
				replaceState(routes.TrainingSession.Show(org.id, session.id));
			} else {
				pushState(routes.TrainingSession.Show(org.id, session.id));
			}
		}
	};

	const cancel = () => popState();
	const currentUser = useCurrentUser();

	if (!sessionLoaded) {
		return <Spinner />;
	}

	const blocks = sessionBlocks.sort(multisort.asc('order')).map((block, n) => {
		const canAscend = n > 0;
		const canDescend = n < sessionBlocks.size - 1;

		const prev = sessionBlocks.get(n - 1);
		const next = sessionBlocks.get(n + 1);

		const onAscend = () => {
			if (prev) {
				store.dispatch(
					actions.sessionBlocks.swapSessionBlockOrder(block, prev)
				);
			}
		};

		const onDescend = () => {
			if (next) {
				store.dispatch(
					actions.sessionBlocks.swapSessionBlockOrder(block, next)
				);
			}
		};

		return (
			<Block
				key={`session-block-${block.id}`}
				sessionId={session?.id}
				blockId={block.id}
				eventId={session.eventId}
				block={block}
				groupId={props.groupId}
				onRemove={handleDeleteBlock}
				onUpdate={handleUpdateBlock}
				canAscend={canAscend}
				onAscend={onAscend}
				canDescend={canDescend}
				onDescend={onDescend}
				user={currentUser}
			/>
		);
	});

	const sessionData = (
		<SessionData>
			{isEventSession && (
				<Toggle
					label={t('Visible to players?', {
						_context: 'training_library/session_builder',
					})}
					description={t(
						'When active, everyone - including players - can view this session',
						{ _context: 'training_library/session_builder' }
					)}
					onChange={onPlayerVisibleChange}
					isActive={playerVisible}
				/>
			)}
			<DelayedTextInput
				placeholder={t('Session title', {
					_context: 'training_library/session_builder',
				})}
				value={session?.title}
				onChange={handleUpdateSessionTitle}
				highlighted
				weighted
			/>
			<DelayedTextInput
				minRows={2}
				maxRows={8}
				name="description"
				value={session?.description}
				onChange={handleUpdateSessionDescription}
				placeholder={t('Session description', {
					_context: 'training_library/session_builder',
				})}
			/>

			<TagForm
				location="below"
				type="session"
				tags={tags}
				onSelect={onTagSelect}
				onCreate={onTagCreate}
				onRemove={onTagRemove}
			/>

			<EstimatedDuration>
				{t('Estimated session duration:', {
					_context: 'training_library/session_builder',
				})}{' '}
				<time>
					{t('{num} min', {
						num: sessionDuration,
						_context: 'training_library/session_builder',
					})}
				</time>
			</EstimatedDuration>
			<Divider />
		</SessionData>
	);

	const addButton = (
		<AddButtonWrapper>
			<Button block large icon="add" onClick={handleCreateBlock}>
				{t('Add block', { _context: 'training_library/session_builder' })}
			</Button>
		</AddButtonWrapper>
	);

	const content = (
		<Blocks>
			{sessionData}

			<MovableBlocksWrapper>{blocks}</MovableBlocksWrapper>

			{addButton}
		</Blocks>
	);

	return (
		<Fragment>
			<ActionBar.SaveBar maxWidth={PageWidths.STANDARD}>
				<LargeScreen>
					<Button onClick={cancel}>{t('Cancel')}</Button>
					<Button primary disabled={!canSave()} onClick={canSave() && save}>
						{t('Save session', {
							_context: 'training_library/session_builder',
						})}
					</Button>
				</LargeScreen>
				<SmallScreen>
					<Button large block onClick={cancel}>
						{t('Cancel')}
					</Button>
					<Button
						large
						block
						primary
						disabled={!canSave()}
						onClick={canSave() && save}>
						{t('Save session', {
							_context: 'training_library/session_builder',
						})}
					</Button>
				</SmallScreen>
			</ActionBar.SaveBar>
			<LargeScreen>
				<LargeScreenContent.Inner maxWidth={PageWidths.STANDARD}>
					{content}
				</LargeScreenContent.Inner>
			</LargeScreen>
			<SmallScreen>
				<LargeScreenContent.Inner>{content}</LargeScreenContent.Inner>
			</SmallScreen>
		</Fragment>
	);
};

export default Edit;
