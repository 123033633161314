import { t } from '@transifex/native';
import { Component } from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';

import * as palette from 'pkg/config/palette';

import {
	getCurrentRating,
	getRatingItemsEntities,
	makeGetComparisonRating,
} from 'pkg/selectors/ratings.selector';

import { fetchRatingItems } from 'pkg/actions/rating_items';

import { IdentityContext } from 'pkg/identity';

import {
	TD,
	TBody,
	Categories,
	CategoryHeader,
	HeaderRow,
	ComparisonIndicator,
	CategoryLabel,
	CategoryAverage,
	CategoryAverageBar,
} from 'components/user/rating/Table';
import { Table, TableRow as TR } from 'components/legacy/Table';

const CategoryWrapper = styled.div`
	position: relative;

	${Table} {
		table-layout: fixed;
	}

	${(props) =>
		props.hasComparison &&
		css`
			tr > td:last-of-type {
				background: rgba(241, 244, 247, 0.5);
				color: rgba(41, 44, 52, 0.55);
			}

			thead {
				tr:last-child > th {
					border-top: 1px solid transparent;
				}

				${CategoryAverageBar} {
					padding: 0;
				}

				${CategoryLabel} {
					padding-top: 3rem;
				}

				${CategoryAverage} {
					padding-top: 3rem;

					&:last-of-type {
						background: rgba(241, 244, 247, 0.5);

						h4 {
							color: rgba(41, 44, 52, 0.75);
						}
					}
				}
			}
		`};
`;

const FauxText = styled.div`
	width: 80%;
	margin: 0 auto;
	background: ${palette.gray[300]};
	height: 1rem;
	border-radius: var(--radius-2);
`;

const Trait = styled.div`
	color: ${palette.gray[600]};
	font-weight: var(--font-weight-normal);
`;

class PropertyTable extends Component {
	static contextType = IdentityContext;

	static defaultProps = {
		author: {
			firstName: '',
		},
		user: {
			firstName: '',
		},
	};

	componentDidMount() {
		this.props.fetchRatingItems(this.context.membership.targetUser?.accountId);
	}

	get hasComparison() {
		if (!this.props.comparison) {
			return false;
		}

		return !!this.props.comparison.id;
	}

	get categoryLabels() {
		return {
			0: t(`Base`),
			1: t(`Physical`),
			2: t(`Mental`),
			3: t(`Skills`),

			4: t(`Goalkeeper`),

			5: t(`Pace `),
			6: t(`Attacking`),
			7: t(`Passing `),
			8: t(`Technique`),
			9: t(`Defending `),
			10: t(`Physique`),
			11: t(`Heading`),
			12: t(`Vision`),
		};
	}

	getRatingItem = (ratingItemId) => {
		return this.props.ratingItemEntities.get(ratingItemId);
	};

	get ratingInputItemsByCategory() {
		const ratingInputItems = {};

		for (let i = 0; i < this.props.ratingInputItems.length; i++) {
			const inputItem = this.props.ratingInputItems[i];
			const ratingItem = this.getRatingItem(inputItem.ratingItemId);

			if (!ratingItem) {
				continue;
			}

			if (!ratingInputItems[ratingItem.category]) {
				ratingInputItems[ratingItem.category] = {
					categorySlug: ratingItem.categorySlug,
					items: [],
				};
			}

			ratingInputItems[ratingItem.category].items.push({
				...ratingItem.toJS(),
				...inputItem,
			});
		}

		return ratingInputItems;
	}

	get comparisonItemsByCategory() {
		const comparisonRatingItems = {};

		for (let i = 0; i < this.props.comparisonRatingItems.length; i++) {
			const inputItem = this.props.comparisonRatingItems[i];

			const ratingItem = this.getRatingItem(inputItem.ratingItemId);

			if (!ratingItem) {
				continue;
			}

			if (!comparisonRatingItems[ratingItem.category]) {
				comparisonRatingItems[ratingItem.category] = {
					categorySlug: ratingItem.categorySlug,
					items: [],
				};
			}

			comparisonRatingItems[ratingItem.category].items.push({
				...ratingItem.toJS(),
				...inputItem,
			});
		}

		return comparisonRatingItems;
	}

	get tableRows() {
		let rows = [];

		let traits = this.ratingInputItemsByCategory;
		let comparisonTraits = this.comparisonItemsByCategory;

		if (Object.keys(comparisonTraits).length > Object.keys(traits).length) {
			traits = this.comparisonItemsByCategory;
		}

		for (let id in traits) {
			const category = traits[id];
			const items = [];
			let average = 0;
			let comparisonAverage = 0;
			let min = 100;
			let max = 0;

			let actualTraits = [];
			let actualComparisonTraits = [];

			if (this.ratingInputItemsByCategory[id]?.items) {
				actualTraits = this.ratingInputItemsByCategory[id].items;
			}

			if (comparisonTraits[id]?.items) {
				actualComparisonTraits = comparisonTraits[id].items;
			}

			const traitItems = category.items;

			for (let j = 0; j < traitItems.length; j++) {
				let item = actualTraits.find((item) => item.id === traitItems[j].id);
				let comparisonItem = actualComparisonTraits.find(
					(item) => item.ratingItemId === traitItems[j].ratingItemId
				);

				let value = item?.value ? item?.value : <FauxText />;

				average += item?.value ? item?.value : 0;
				comparisonAverage += comparisonItem?.value ? comparisonItem.value : 0;

				min = min >= item?.value ? item?.value : min;
				max = max <= item?.value ? item?.value : max;

				let name = item?.name ? (
					item.name
				) : this.hasComparison ? (
					comparisonItem.name
				) : (
					<FauxText />
				);

				items.push(
					<TR key={`item--row-${traitItems[j]?.id}`}>
						<TD active textAlign="left">
							<Trait>{name}</Trait>
						</TD>

						<TD textAlign={this.hasComparison ? 'center' : 'right'}>{value}</TD>
						{this.hasComparison ? (
							<TD textAlign="center">
								{comparisonItem?.value || <FauxText />}
							</TD>
						) : null}
					</TR>
				);
			}

			average = Math.round(average / traitItems.length);
			comparisonAverage = Math.round(comparisonAverage / traitItems.length);

			rows.push(
				<CategoryWrapper
					key={`rating--${id}`}
					hasComparison={this.hasComparison}>
					<Table>
						<CategoryHeader>
							<HeaderRow>
								<CategoryLabel>{this.categoryLabels[id]}</CategoryLabel>

								<CategoryAverage
									average={average}
									textAlign={this.hasComparison ? 'center' : 'right'}>
									{this.hasComparison && <ComparisonIndicator />}
									<span>{average}</span>
									{this.hasComparison ? (
										<h4>{this.props.ratingAuthor.firstName}</h4>
									) : null}
								</CategoryAverage>

								{this.hasComparison ? (
									<CategoryAverage
										average={comparisonAverage}
										textAlign="center">
										<ComparisonIndicator comparison />
										<span>{comparisonAverage}</span>
										<h4>{this.props.comparisonAuthor.firstName}</h4>
									</CategoryAverage>
								) : null}
							</HeaderRow>

							<HeaderRow>
								<CategoryAverageBar
									colSpan={this.hasComparison ? 3 : 2}
									average={average}
									hasComparison={this.hasComparison}
								/>
							</HeaderRow>

							{this.hasComparison ? (
								<HeaderRow>
									<CategoryAverageBar
										colSpan={3}
										comparison
										average={comparisonAverage}
										secondRow
										hasComparison
									/>
								</HeaderRow>
							) : null}
						</CategoryHeader>
						<TBody>{items}</TBody>
					</Table>
				</CategoryWrapper>
			);
		}

		return rows;
	}

	render() {
		const { ratingInputItems, ratingItemEntities, comparisonRatingItems } =
			this.props;

		if (!ratingInputItems) {
			return null;
		}

		if (ratingInputItems.length === 0) {
			return null;
		}

		if (ratingItemEntities.size === 0) {
			return null;
		}

		if (this.hasComparison && comparisonRatingItems.length === 0) {
			return null;
		}

		if (this.hasComparison) {
			if (comparisonRatingItems.length === 0) {
				return null;
			}

			return <Categories single={false}>{this.tableRows}</Categories>;
		}

		return <Categories single>{this.tableRows}</Categories>;
	}
}

const getRatingItemsLoading = (state) => state.ratingItems.loading;

const mapStateToProps = () => {
	const getComparisonRating = makeGetComparisonRating();

	return (state, props) => {
		const ratingInputItems = getRatingItemsEntities(state, props);

		const comparisonRatingItems =
			getRatingItemsEntities(state, {
				ratingId: state.ratings.comparisonRatingId,
			}) || [];

		return {
			ratingInputItems,
			loadingRatingItems: getRatingItemsLoading(state),
			ratingAuthor: getCurrentRating(state, props)?.author,
			comparisonAuthor: getComparisonRating(state, props)?.author,
			comparisonRatingId: state.ratings.comparisonRatingId,
			comparisonRatingItems,
			ratingItemEntities: state.ratingItems.entities,
		};
	};
};

const actions = {
	fetchRatingItems,
};

export default connect(mapStateToProps, actions)(PropertyTable);
