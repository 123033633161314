import styled, { css, DefaultTheme } from 'styled-components';
import { t } from '@transifex/native';

interface LabelProps {
	optional?: boolean;
	small?: boolean;
	theme?: DefaultTheme;
}

const Label = styled.label<LabelProps>`
	display: block;
	margin: 0 0 var(--spacing-3) 0;
	font-weight: var(--font-weight-semibold);
	color: var(--palette-gray-800);
	display: block;
	font-size: var(--font-size-base);

	strong {
		display: block;
		margin-bottom: 0.5rem;
	}

	&:first-child {
		margin-top: 0;
	}

	&::after {
		content: '${({ optional }) => optional && t('Optional')}';
		color: var(--palette-gray-500);
		font-weight: var(--font-weight-normal);
		margin-left: var(--spacing-3);
		font-size: var(--font-size-sm);
	}

	${({ small }) =>
		small &&
		css`
			font-size: var(--font-size-sm);
		`};

	${({ theme }) =>
		theme.darkMode &&
		css`
			color: var(--palette-white);
		`}
`;

export default Label;
