import { ReactNode } from 'react';

export const symbol_shopping_basket: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M206-120q-20 0-36.5-12T148-164L38-562q-4-14 4.826-26Q51.65-600 67-600h202l185-270q5-6 11-9.5t14-3.5q8 0 14 3.5t11 9.5l184 270h206q15.349 0 24.174 12Q927-576 923-562L812-164q-5 20-21.5 32T754-120H206Zm0-60h548l101-360H106l100 360Zm274.177-120Q505-300 522.5-317.677t17.5-42.5Q540-385 522.323-402.5t-42.5-17.5Q455-420 437.5-402.323t-17.5 42.5Q420-335 437.677-317.5t42.5 17.5ZM342-600h273L479-800 342-600Zm139 240Z"
		/>
	</svg>
);
