import { useState } from 'react';
import styled from 'styled-components';

import * as palette from 'pkg/config/palette';
import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import rgba from 'pkg/rgba';
import { AttachmentType } from 'pkg/api/models/attachment';

import Icon from 'components/icon';

import * as iconStyles from 'components/icon/styles.css';
import { Spinner } from 'components/loaders/spinner';

const Remove = styled.div`
	width: 20px;
	height: 20px;
	top: 5px;
	right: 5px;
	background: ${palette.black};
	border-radius: var(--radius-5);
	z-index: 3;
	position: absolute;
	cursor: pointer;

	.${iconStyles.icon} {
		height: 20px;
		width: 20px;
		padding: 3px;
		color: ${palette.white};
	}
`;

const Inner = styled.div`
	position: relative;
	width: 100%;
	height: 100%;

	& > * {
		position: absolute;
		width: 100%;
		height: 100%;
	}
`;

const Item = styled.figure`
	display: block;
	width: 100px;
	height: 100px;
	border-radius: var(--radius-3);
	overflow: hidden;
	background: ${palette.gray[300]};
	position: relative;
	margin: 0;

	> .${iconStyles.icon} {
		color: #000;
		opacity: 0.2;
		width: 50px;
		height: 50px;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
`;

const Uploading = styled.div`
	bottom: 0;
	left: 0;
	background: ${palette.blue[300]};
	transition: height 50ms;
	z-index: 1;
`;

const FileIcon = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;

	.${iconStyles.icon} {
		font-size: 30px;
		color: ${palette.gray[500]};
		flex: 0;
	}
`;

const FileInfo = styled.div`
	z-index: 2;
	display: flex;
	flex-flow: column;

	figcaption {
		margin-top: auto;
		padding: 4px 8px 4px 8px;
	}
`;

const ImageSource = styled.img<{ loaded: boolean }>`
	object-fit: cover;
	display: block;
	width: 100%;
	height: 100%;
	transition: opacity 250ms ease-in-out;
	opacity: ${(props) => (props.loaded ? 1 : 0)};
	z-index: 1;

	& + ${FileInfo} figcaption {
		background: ${rgba(palette.white, 0.9)};
	}
`;

const FileName = styled.div`
	font-size: 0.65rem;
	font-weight: var(--font-weight-semibold);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: ${palette.gray[800]};
	line-height: 1.1;
`;

const FileMeta = styled.div`
	font-size: 0.5rem;
	font-weight: var(--font-weight-normal);
	line-height: 1.1;
	margin-top: 3px;
	color: ${palette.gray[600]};
	text-transform: uppercase;
`;

const Image = ({ src }: { src: string }) => {
	const [isLoaded, setIsLoaded] = useState(false);
	const handleDidLoad = () => setIsLoaded(true);

	return (
		<ImageSource
			src={src}
			onLoad={handleDidLoad}
			loaded={isLoaded}
			loading="lazy"
		/>
	);
};

interface FormItemProps {
	attachment: models.attachment.Attachment;
	bytesUploaded?: number;
	onDelete: (attachment: models.attachment.Attachment) => void;
}

export default function FormItem({
	attachment,
	bytesUploaded,
	onDelete,
}: FormItemProps) {
	let icon = null;

	switch (attachment.type) {
		case 'image':
			icon = <Icon name="file-image" />;
			break;
		default:
			icon = <Icon name="file-generic" />;
			break;
	}

	if (bytesUploaded >= attachment.size && attachment.status === 'uploading') {
		icon = <Spinner color={styles.palette.blue[500]} size="50%" />;
	}

	const fileInfo = (
		<FileInfo>
			{!attachment.previewUrl && <FileIcon>{icon}</FileIcon>}

			<figcaption>
				<FileName>{attachment.title}</FileName>
				<FileMeta>
					{models.attachment.getExtension(attachment)} -{' '}
					{models.attachment.getHumanFileSize(attachment)}
				</FileMeta>
			</figcaption>
		</FileInfo>
	);

	let content = <Inner>{fileInfo}</Inner>;
	if (attachment.status === 'uploading') {
		content = (
			<Inner>
				<Uploading
					style={{
						height: `${((bytesUploaded || 0) / attachment.size) * 100}}%`,
					}}
				/>
				{fileInfo}
			</Inner>
		);
	} else {
		switch (attachment.type) {
			case AttachmentType.Image:
				content = (
					<Inner>
						<Image src={attachment.previewUrl || attachment.url} />
						{fileInfo}
					</Inner>
				);
				break;
			default:
				content = <Inner>{fileInfo}</Inner>;
				break;
		}
	}

	const handleDelete = () => {
		onDelete?.(attachment);
	};

	return (
		<Item>
			{content}

			<Remove onClick={handleDelete}>
				<Icon name="delete" />
			</Remove>
		</Item>
	);
}
