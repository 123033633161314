import { ReactNode } from 'react';

export const symbol_ar_stickers: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-40q-169 0-269.5-41T110-192q0-32 30.5-63.5T231-307l52 46q-76 24-94.5 41.5T170-191.579Q170-169 250.5-137T480-105q149 0 229.5-32t80.5-54.579Q790-202 771.5-219.5T677-261l53-46q60 20 90 51.5t30 63.5q0 70-100.5 111T480-40Zm0-161q-20 0-37-8.5T410-230L134-467q-11-10-17.5-22.735Q110-502.469 110-516v-78q0-14 6.5-25t16.5-20l276-256q14-14 32.375-20t38.5-6q20.125 0 38.625 6t32.5 20l276 256q10 9 16.5 20t6.5 25v78q0 13.531-6.5 26.265Q837-477 826-467L550-230q-16 12-33 20.5t-37 8.5Zm-2-171q15.4 0 29.15-4.974Q520.9-381.947 533-393l239-218-263-238q-5-7-11.5-9.5T483-860q-8 1-16 3.5t-16 7.5L187-609l236.087 215.818Q435-382 448.782-377q13.782 5 29.218 5ZM349.887-557Q370-557 384-570.887q14-13.888 14-34Q398-625 384.113-639q-13.888-14-34-14Q330-653 316-639.113q-14 13.888-14 34Q302-585 315.887-571q13.888 14 34 14ZM415-496q45 20 93 10.5t84-42.5q33-29 43.5-71t-6.5-83L415-496Zm64.887-187Q500-683 514-696.887q14-13.888 14-34Q528-751 514.113-765q-13.888-14-34-14Q460-779 446-765.113q-14 13.888-14 34Q432-711 445.887-697q13.888 14 34 14Zm.113 67Z"
		/>
	</svg>
);
