import { ReactNode } from 'react';

export const symbol_woman_2: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M428-110v-190h-87q-15 0-24.5-12.5T312-339l90-289q8-25 30-38.5t48-13.5q26 0 48 13.5t30 38.5l90 289q5 14-4.5 26.5T619-300h-87v190q0 12.75-8.625 21.375T502-80h-44q-12.75 0-21.375-8.625T428-110Zm52.08-624q-30.08 0-51.58-21.42-21.5-21.421-21.5-51.5 0-30.08 21.42-51.58 21.421-21.5 51.5-21.5 30.08 0 51.58 21.42 21.5 21.421 21.5 51.5 0 30.08-21.42 51.58-21.421 21.5-51.5 21.5Z"
		/>
	</svg>
);
