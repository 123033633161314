import { ReactNode } from 'react';

export const symbol_data_object: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M600-160q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T600-220h90q21.25 0 35.625-14.375T740-270v-100q0-37 22.5-66t57.5-40v-8q-35-10-57.5-39.5T740-590v-100q0-21.25-14.375-35.625T690-740h-90q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T600-800h90q45.833 0 77.917 32.083Q800-735.833 800-690v100q0 21.25 14.375 35.625T850-540q12.75 0 21.375 8.646T880-509.927v60.146q0 12.781-8.625 21.281Q862.75-420 850-420q-21.25 0-35.625 14.375T800-370v100q0 45.833-32.083 77.917Q735.833-160 690-160h-90Zm-330 0q-45.833 0-77.917-32.083Q160-224.167 160-270v-100q0-21.25-14.375-35.625T110-420q-12.75 0-21.375-8.646T80-450.073v-60.146Q80-523 88.625-531.5 97.25-540 110-540q21.25 0 35.625-14.375T160-590v-100q0-45.833 32.083-77.917Q224.167-800 270-800h90q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T360-740h-90q-21.25 0-35.625 14.375T220-690v100q0 37-22.5 66.5T140-484v8q35 11 57.5 40t22.5 66v100q0 21.25 14.375 35.625T270-220h90q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T360-160h-90Z"
		/>
	</svg>
);
