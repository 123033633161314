import { ReactNode } from 'react';

export const symbol_slab_serif: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m341-300 36-95h199l37 95h-54q-13 0-21.5 8.5T529-270q0 13 8.5 21.5T559-240h141q13 0 21.5-8.5T730-270q0-13-8.5-21.5T700-300h-11L532-695q-5-11-14.576-18-9.577-7-21.765-7h-30.471Q453-720 443-713q-10 7-15 18L272-300h-12q-13 0-21.5 8.5T230-270q0 13 8.5 21.5T260-240h136q13 0 21.5-8.5T426-270q0-13-9-21.5t-22-8.5h-54Zm59-155 71-185h10l72 185H400ZM140-80q-24 0-42-18t-18-42v-680q0-24 18-42t42-18h680q24 0 42 18t18 42v680q0 24-18 42t-42 18H140Zm0-60h680v-680H140v680Zm0 0v-680 680Z"
		/>
	</svg>
);
