import { t } from '@transifex/native';

import { Record } from 'pkg/api/models/record';
import * as models from 'pkg/api/models';
import { Linkable } from 'pkg/api/models/linkable';
import { Event } from 'pkg/api/models/event';

export enum AttendanceStatuses {
	Attended = 'attended',
	Unset = 'unset',
	Injured = 'injured',
	Sick = 'sick',
	Other = 'other',
}

export enum EventUserStatus {
	Pending = 'pending',
	Accepted = 'accepted',
	Declined = 'declined',
}

export enum EventUserFlags {
	LokHasHandicap = 'lok_has_handicap',
	LokLeader = 'lok_leader',
}

export enum EventUserRole {
	Organizer = 'organizer',
	Participant = 'participant',
}

export interface EventUser extends Record, Linkable {
	eventId: number;
	comment: string;
	userId: number;
	attendanceReporterId: number;
	attendanceLastUpdated: number;

	attendanceStatus: AttendanceStatuses;
	status: EventUserStatus;
	flags: EventUserFlags[];
	role: EventUserRole;

	user: models.user.User;
	attendanceReporter: models.user.User;
}

export function lokLeader(eventUser: EventUser): boolean {
	return eventUser.flags.includes(EventUserFlags.LokLeader);
}

export function lokHasHandicap(eventUser: EventUser): boolean {
	return eventUser.flags.includes(EventUserFlags.LokHasHandicap);
}

export function findAllEventUsers(
	eventUsers: EventUser[],
	userIds: number[]
): EventUser[] {
	return eventUsers?.filter((eu) => userIds.includes(eu.userId)) || [];
}

export function findMyEventUser(
	eventUsers: EventUser[],
	activeUserIds: number[]
): EventUser {
	return eventUsers?.find((eu) => activeUserIds.includes(eu.userId));
}

export function isUnhandled(eventUser: EventUser): boolean {
	return eventUser?.status === EventUserStatus.Pending;
}

export function eventUserIsCurrentUser(
	eventUser: EventUser,
	activeUserIds: number[]
): boolean {
	return activeUserIds.includes(eventUser.user.id);
}

export function sortEventUsers(
	eventUsers: EventUser[],
	myEventUsers: EventUser[] = [],
	event: Event
): EventUser[] {
	const myEventUserIds = myEventUsers.map((eu) => eu.id);
	const eventUserId = event?.userId;

	return eventUsers.sort((a, b) => {
		if (a.userId === eventUserId) {
			return -1;
		}
		if (b.userId === eventUserId) {
			return 1;
		}
		if (myEventUserIds.includes(a.id)) {
			return -1;
		}
		if (myEventUserIds.includes(b.id)) {
			return 1;
		}

		const statusComparison = getPriority(a.status) - getPriority(b.status);

		if (statusComparison !== 0) {
			return statusComparison;
		}

		return a.user.firstName.localeCompare(b.user.firstName);
	});
}

function getPriority(status: EventUserStatus): number {
	switch (status) {
		case EventUserStatus.Accepted:
			return 1;
		case EventUserStatus.Declined:
			return 2;
		default:
			return 0;
	}
}

export function getDeclineReasonTranslation(
	reason: AttendanceStatuses
): string {
	switch (reason) {
		case AttendanceStatuses.Sick:
			return t('illness');
		case AttendanceStatuses.Injured:
			return t('injured');
		case AttendanceStatuses.Other:
			return t('other');
	}
}

export function getStatusText(
	eventUser: EventUser,
	isCurrentUser: boolean,
	eventHasEnded: boolean
): string {
	let text = '';

	const name = `${eventUser.user.firstName} ${eventUser.user.lastName.substring(
		0,
		1
	)}.`;

	if (eventHasEnded) {
		switch (eventUser.attendanceStatus) {
			case 'attended':
				if (isCurrentUser) {
					text = t('You attended');
				} else {
					text = t('{name} attended', { name });
				}
				break;
			case 'injured':
			case 'sick':
			case 'other':
				if (isCurrentUser) {
					text = t(`You didn't attend ({reason})`, {
						reason: getDeclineReasonTranslation(eventUser.attendanceStatus),
					});
				} else {
					text = t(`{name} didn't attend ({reason})`, {
						name,
						reason: getDeclineReasonTranslation(eventUser.attendanceStatus),
					});
				}
				break;
			default:
				if (isCurrentUser) {
					text = t('No response');
				} else {
					text = t('{name} no response', { name });
				}
		}
	} else {
		switch (eventUser.status) {
			case models.eventUser.EventUserStatus.Accepted:
				if (isCurrentUser) {
					text = t('You accepted');
				} else {
					text = t('{name} accepted', { name });
				}
				break;
			case models.eventUser.EventUserStatus.Declined:
				if (isCurrentUser) {
					if (eventUser.attendanceStatus === 'unset') {
						text = t('You declined');
					} else {
						text = t('You declined ({reason})', {
							reason: getDeclineReasonTranslation(eventUser.attendanceStatus),
						});
					}
				} else {
					if (eventUser.attendanceStatus === 'unset') {
						text = t('{name} declined', { name });
					} else {
						text = t('{name} declined ({reason})', {
							name,
							reason: getDeclineReasonTranslation(eventUser.attendanceStatus),
						});
					}
				}
				break;
			default:
				if (isCurrentUser) {
					text = t('No response');
				} else {
					text = t('{name} no response', { name });
				}
		}
	}

	return text;
}
