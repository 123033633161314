import { ReactNode } from 'react';

export const symbol_sports_tennis: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M520-392q-89 0-139.5 13T297-333L144-180q-9 9-21 8.5t-21-9.109q-9-9.391-9-21.858 0-12.466 9-21.533l152-152q33-33 47-82.5T315-597q0-56.976 25-111.988T416-815q88-88 193.086-100Q714.172-927 782-859q68 68 56 174T738-491q-51 51-106.012 75T520-392Zm-107-99q49 49 133 36.5T697-534q66.876-66.583 79.938-149.292Q790-766 741-815t-132.5-36.5Q525-839 458-772t-80.5 149.587Q364-539.825 413-491ZM736-40q-60 0-102-42t-42-102q0-60 42-102t102-42q60 0 102 42t42 102q0 60-42 102T736-40Zm0-60q36 0 60-24t24-60q0-36-24-60t-60-24q-36 0-60 24t-24 60q0 36 24 60t60 24Zm0-84Z"
		/>
	</svg>
);
