import { Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import * as breakpoints from 'pkg/config/breakpoints';
import * as palette from 'pkg/config/palette';
import * as shadow from 'pkg/config/box-shadow';

import Link from 'pkg/router/Link';
import * as selectors from 'pkg/selectors';
import * as routes from 'pkg/router/routes';
import { useCurrentGroup, useCurrentOrganization } from 'pkg/identity';

import Avatar from 'components/avatar';

import { TableBodyCell } from 'components/group/statistics/Table';

const ItemTableBodyCell = styled(TableBodyCell)`
	&:last-child {
		padding-right: 26px;
	}
`;

const UserLinkWrapper = styled(Link)`
	display: flex;
	align-items: center;
	position: relative;
`;

const UserWrapper = styled.div`
	display: flex;
	align-items: center;
	position: relative;
`;

const UserPosition = styled.div`
	width: 28px;
	color: ${palette.gray[600]};

	&::after {
		content: '.';
	}

	@media ${breakpoints.small} {
		position: absolute;
		top: -4px;
		left: -8px;
		background: ${palette.brand};
		background: #fff;
		color: #000;
		box-shadow: ${shadow.flat};
		width: 14px;
		height: 14px;
		border-radius: 50%;
		z-index: 3;
		font-size: 0.5rem;
		display: flex;
		justify-content: center;
		align-items: center;

		&::after {
			content: '';
		}
	}
`;

const UserAvatar = styled(Avatar)`
	width: 22px;
	margin-right: 0.7rem;
	font-size: var(--font-size-sm);

	@media ${breakpoints.small} {
		width: 20px;
		font-size: 0.7rem;
		margin-right: 0.5rem;
	}
`;

const FirstName = styled.div`
	margin-right: 3px;

	@media ${breakpoints.small} {
		display: none;
	}
`;

const Initial = styled.div`
	margin-right: 3px;
	display: none;

	@media ${breakpoints.small} {
		display: block;
	}
`;

const LastName = styled.div`
	flex: 1;

	@media ${breakpoints.small} {
		overflow: hidden;
		text-overflow: ellipsis;
	}
`;

const Item = ({ user, index, item }) => {
	const org = useCurrentOrganization();
	const group = useCurrentGroup();
	if (!user) {
		return null;
	}

	const userItem = (
		<Fragment>
			<UserPosition>{index}</UserPosition>
			<UserAvatar user={user} />
			<Initial>{user.firstName[0]}.</Initial>
			<FirstName>{user.firstName}</FirstName>
			<LastName>{user.lastName}</LastName>
		</Fragment>
	);

	return (
		<Fragment>
			<ItemTableBodyCell alignLeft grow>
				{user.deletedAt ? (
					<UserWrapper>{userItem}</UserWrapper>
				) : (
					<UserLinkWrapper
						href={routes.User.Profile.Show(
							org.id,
							group.id,
							user.id,
							'overview'
						)}>
						{userItem}
					</UserLinkWrapper>
				)}
			</ItemTableBodyCell>
			<ItemTableBodyCell>{item.get('goals')}</ItemTableBodyCell>
			<ItemTableBodyCell>{item.get('assists')}</ItemTableBodyCell>
			<ItemTableBodyCell>{item.get('points')}</ItemTableBodyCell>
		</Fragment>
	);
};

const mapStateToProps = (state, props) => ({
	user: selectors.users.find(state, props.item.get('userId')),
});

export default connect(mapStateToProps)(Item);
