import { t } from '@transifex/native';
import { Fragment } from 'react';

import * as models from 'pkg/api/models';
import * as routes from 'pkg/router/routes';
import {
	useCurrentGroup,
	useCurrentMembership,
	useCurrentOrganization,
} from 'pkg/identity';

import { LargeScreen } from 'components/MediaQuery';

import GroupLinkFolders from 'components/navigation/menus/group_link_folders';
import { NavigationItemsProps } from 'components/navigation/menus/NavigationItems';

import * as Drawer from 'design/navigation/drawer';

const _context = 'navigation';

function ClubNavigation({
	displayChildren,
	handleDisplayChildren,
}: NavigationItemsProps): JSX.Element {
	const activeMembership = useCurrentMembership();
	const group = useCurrentGroup();
	const org = useCurrentOrganization();

	const groupHasCustomLinksEnabled =
		group && models.group.hasFeature(group, models.group.Features.CustomLinks);

	return (
		<Fragment>
			<Drawer.ItemGroup>
				<LargeScreen>
					<Drawer.Item
						href={routes.Organization.Home(org.id)}
						name={t('Home', { _context })}
						icon="home"
					/>
					<Drawer.Item
						href={routes.Calendar.Index(
							org.id,
							group.id,
							sessionStorage.getItem('currentMode') || 'schedule'
						)}
						name={t('Calendar')}
						icon="calendar_today"
					/>
				</LargeScreen>
				{activeMembership.isLegalGuardian && (
					<Drawer.Item
						href={routes.Account.Settings.Show('children')}
						name={t('My children', { _context })}
						icon="supervisor_account"
					/>
				)}
				<Drawer.Item
					href={routes.User.Registrations(org.id, 'open')}
					name={t('Registrations')}
					icon="contact_page"
				/>
			</Drawer.ItemGroup>
			{groupHasCustomLinksEnabled && (
				<GroupLinkFolders
					displayChildren={displayChildren}
					handleDisplayChildren={handleDisplayChildren}
					clubLobby={true}
				/>
			)}
		</Fragment>
	);
}

export default ClubNavigation;
