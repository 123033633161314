import { ReactNode } from 'react';

export const symbol_file_copy_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m626-902 196 196q8 8 13 19.278 5 11.278 5 23.722v390q0 14.872-9.318 21.936t-20.5 7.064Q799-244 789.5-251.25 780-258.5 780-273v-389H623q-18.75 0-31.875-13.125T578-707v-153H230q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T230-920h353q12.444 0 23.722 5T626-902ZM260-220h394L260-614v394Zm454 60H260q-24 0-42-18t-18-42v-454L62-812q-9-9-9-21.5t9-21.5q9-9 21.5-9t21.5 9l751 751q9 9 9 21t-9 21q-9 9-21.5 9T813-62l-99-98ZM520-561Zm-63 144ZM140-40q-24 0-42-18t-18-42v-549q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-649v549h508q12.75 0 21.375 8.675Q678-82.649 678-69.825 678-57 669.375-48.5T648-40H140Z"
		/>
	</svg>
);
