import { ReactNode } from 'react';

export const symbol_school: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M220-262q-14-8-22.5-21.875T189-315v-204L89-574q-8-5-12-11.525t-4-14.5Q73-608 77-614.5T89-626l361-198q7-4 14.207-5.5Q471.414-831 479-831q7.586 0 14.793 1.5Q501-828 508-824l396 215q8 5 12 11.967 4 6.966 4 15.033v269q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T860-313v-252l-91 46v204q0 17.25-8.5 31.125T738-262L508-136q-7 4-14.207 5.5Q486.586-129 479-129q-7.586 0-14.793-1.5Q457-132 450-136L220-262Zm259-166 315-172-315-169-313 169 313 172Zm0 240 230-127v-168L508-375q-7 4-14 5.5t-15 1.5q-8 0-14.5-1.5T451-375L249-485v170l230 127Zm1-240Zm-1 90Zm0 0Z"
		/>
	</svg>
);
