import { t } from '@transifex/native';
import styled from 'styled-components';

import FormRow from 'components/form/Row';
import Label from 'components/form/Label';
import TextAreaImport from 'components/form/TextArea';

const TextArea = styled(TextAreaImport)`
	min-height: 6em;
`;

const ContentInput = ({ dispatch, data }) => {
	const handleContentChange = (e) => {
		dispatch({
			type: 'set_value',
			payload: { key: 'content', value: e.target.value },
		});
	};

	return (
		<FormRow>
			<Label>{t(`Content`)}</Label>
			<TextArea
				rows="3"
				name="content"
				placeholder={t(`Write a post, link a YouTube video`)}
				onChange={handleContentChange}
				defaultValue={data.content}
			/>
		</FormRow>
	);
};

export default ContentInput;
