import { ReactNode } from 'react';

export const symbol_step_into: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M479.882-80Q434-80 402-112.118q-32-32.117-32-78Q370-236 402.118-268q32.117-32 78-32Q526-300 558-267.882q32 32.117 32 78Q590-144 557.882-112q-32.117 32-78 32ZM450-525v-324q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-849v324l92-92q9-9 21.158-9 12.158 0 21 8.609Q653-608 653-595.533q0 12.466-9 21.533L501-431q-9 9-21 9t-21-9L315-575q-9-9-9-21t9-21q9-9 21-9t21 9l93 92Z"
		/>
	</svg>
);
