import {
	ReactNode,
	ChangeEvent,
	SyntheticEvent,
	SelectHTMLAttributes,
} from 'react';
import styled, { css } from 'styled-components';
import { useFormContext } from 'react-hook-form';

import * as styles from 'pkg/config/styles';

import Icon from 'components/icon';

const Wrapper = styled.div`
	position: relative;
`;

export const SelectIconWrapper = styled.div`
	position: absolute;
	right: 7px;
	top: 50%;
	transform: translateY(-50%);
	display: flex;
	flex-direction: column;
	pointer-events: none;

	svg {
		color: var(--palette-gray-500);
		font-size: 20px;

		&:first-child {
			transform: translateY(25%) rotate(-90deg);
		}

		&:last-child {
			transform: translateY(-25%) rotate(90deg);
		}
	}
`;

interface StyledSelectProps {
	hasError?: boolean;
}

const StyledSelect = styled.select<StyledSelectProps>`
	cursor: pointer;
	appearance: none;
	resize: none;
	border: none;
	text-indent: none;
	width: 100%;
	background: var(--palette-white);
	height: 52px;
	line-height: 52px;
	border-radius: var(--radius-5);
	padding: 0 var(--spacing-5);
	font-size: var(--font-size-lg);
	font-weight: var(--font-weight-semibold);

	@media ${styles.breakpoint.small} {
		font-size: var(--font-size-base);
	}

	&::placeholder {
		color: var(--palette-gray-500);
	}

	&::-webkit-search-cancel-button,
	&::-webkit-search-decoration {
		appearance: none;
	}

	&:focus {
		box-shadow: none;
		outline: none;
		border: none;
		box-shadow: hsl(0deg 0% 100% / 50%) 0 0 0 4px;
	}

	${({ hasError }) =>
		hasError &&
		css`
			background: var(--palette-red-200);
		`}
`;

type InputChangeEvent = (
	event: SyntheticEvent | ChangeEvent<HTMLInputElement | HTMLSelectElement>
) => void;

interface SelectProps
	extends Omit<SelectHTMLAttributes<HTMLSelectElement>, 'onChange'> {
	children: ReactNode;
	name?: string;
	testid?: string;
	required?: boolean;
	value?: string | number;
	defaultValue?: string | number;
	onChange?: InputChangeEvent;
	forwardedRef?: any;
}

export default function Select({
	children,
	name,
	testid,
	required,
	value,
	defaultValue,
	onChange,
	forwardedRef,
	...props
}: SelectProps): JSX.Element {
	const methods = useFormContext();

	const validationRules =
		name &&
		methods &&
		methods.register(name, {
			required,
		});

	let changeHandler: InputChangeEvent;
	if (onChange) {
		changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
			if (validationRules) {
				validationRules.onChange(event);
			}

			onChange(event);
		};
	} else if (!onChange && validationRules) {
		changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
			validationRules.onChange(event);
		};
	}

	const hasValidationError = methods && methods.formState.errors[name];

	let ref = forwardedRef;
	if (forwardedRef && validationRules) {
		ref = (e: HTMLInputElement) => {
			validationRules.ref(e);
			forwardedRef.current = e;
		};
	} else if (validationRules) {
		ref = validationRules.ref;
	}

	if (!testid) {
		testid = name ? `select-${name}` : 'select';
	}

	return (
		<Wrapper>
			<SelectIconWrapper>
				<Icon name="chevron" />
				<Icon name="chevron" />
			</SelectIconWrapper>
			<StyledSelect
				{...props}
				data-testid={testid}
				value={value}
				defaultValue={defaultValue}
				name={name}
				ref={ref}
				onChange={changeHandler}
				hasError={!!hasValidationError}>
				{children}
			</StyledSelect>
		</Wrapper>
	);
}
