import { css } from 'styled-components';

import * as palette from 'pkg/config/palette';
import * as breakpoints from 'pkg/config/breakpoints';

import rgba from 'pkg/rgba';

import * as iconStyles from 'components/icon/styles.css';

const Horizontal = {};

Horizontal.Outer = (props) => css`
	display: ${props.theme.hiddenHorizontalTabs ? 'none' : 'flex'};
	justify-content: flex-start;
	position: relative;
	white-space: nowrap;
	overflow: auto;
	width: 100%;
	background-color: transparent;
	-webkit-overflow-scrolling: touch;
	padding: ${props.theme.horizontalType === 'buttons'
		? '0.75rem 15px 0.75rem 15px'
		: '0 15px'};
	border-bottom: 1px solid ${palette.gray[400]};

	margin: 0 0 1rem 0;

	@media ${breakpoints.large}, ${breakpoints.nolimit} {
		margin: 0 0 1.5rem 0;
	}

	${props.theme.horizontalType !== 'buttons' &&
	css`
		@media ${breakpoints.small} {
			margin-top: -1rem;
		}
	`};

	${props.theme.horizontalType === 'buttons' &&
	css`
		margin-bottom: 1rem;
	`};

	&::after {
		content: '';
		padding: ${props.theme.horizontalType === 'buttons' ? '5px' : 0};
	}

	${(props) =>
		props.theme.horizontalType === 'buttons' &&
		css`
			border-bottom: 0px;
		`};
`;

const buttonItem = (props) => css`
	width: 130px;
	min-width: 130px;
	height: 100px;
	margin-right: 6px;
	background-color: ${props.theme.primaryColor || palette.brand};
	border-radius: 11px;
	padding: 8px 11px 15px 11px;

	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	text-align: center;

	color: ${props.theme.accentColor || palette.black};
	font-weight: var(--font-weight-normal);
	font-size: var(--font-size-sm);
	white-space: normal;

	.${iconStyles.icon} {
		align-self: center;
		font-size: 3em;
		color: ${props.theme.accentColor || rgba(palette.black, 0.75)};
	}

	@media (hover: hover) {
		&:hover {
			color: ${palette.white};
		}
	}

	${(props) =>
		props.hidden.includes('horizontal') &&
		css`
			display: none;
		`};
`;

Horizontal.ActiveItem = (props) =>
	props.theme.horizontalType === 'buttons'
		? buttonItem(props)
		: css`
				background: transparent;
				margin-top: 0;
				color: ${palette.black};
				position: relative;

				&::after {
					content: '';
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					height: 4px;
					background: ${palette.black};
					transform: translateY(0);
				}

				.${iconStyles.icon} {
					color: ${palette.black};
				}

				@media (hover: hover) {
					&:hover {
						color: ${palette.black};
					}
				}

				${(props) =>
					props.hidden.includes('horizontal') &&
					css`
						display: none;
					`};
			`;

Horizontal.Item = (props) =>
	props.theme.horizontalType === 'buttons'
		? buttonItem(props)
		: css`
				padding: 15px 0 12px 0;
				margin: 0 22px 0 0;
				position: relative;
				overflow: hidden;
				z-index: 1;

				display: block;
				flex: 0 0 auto;

				font-weight: var(--font-weight-semibold);
				font-size: var(--font-size-base);
				text-align: center;
				user-select: none;
				color: ${palette.gray[600]};

				&::after {
					content: '';
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					height: 4px;
					background: ${palette.black};
					transform: translateY(100%);
					transition: transform 150ms;
				}

				@media (hover: hover) {
					&:hover {
						color: ${palette.gray[600]};

						&::after {
							transform: translateY(0);
						}
					}
				}

				cursor: pointer;

				.${iconStyles.icon} {
					font-size: 1rem;
					margin-right: 5px;
					flex: 0 0 auto;
					display: inline-block;
					color: ${palette.gray[600]};
				}

				@media ${breakpoints.small} {
					font-size: var(--font-size-base);
					line-height: 1.3;
					letter-spacing: 0;

					.${iconStyles.icon} {
						font-size: var(--font-size-lg);
					}
				}

				${(props) =>
					props.hidden.includes('horizontal') &&
					css`
						display: none;
					`};
			`;

export default Horizontal;
