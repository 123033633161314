import { ReactNode } from 'react';

export const symbol_fork_left: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m528-726-69 69q-9 9-21 9t-21-9q-9-9-9-21t9-21l120-120q9-9 21-9t21 9l120 120q9 9 9 21t-9 21q-9 9-21 9t-21-9l-69-69v576q0 13-8.5 21.5T558-120q-13 0-21.5-8.5T528-150v-140q-19-63-66.5-97T357-421q-23 0-45 3t-38 8l69 69q9 9 9 21t-9 21q-9 9-21 9t-21-9L181-419q-9-9-9-21t9-21l120-120q9-9 21-9t21 9q9 9 9 21t-9 21l-69 69q19-5 41-8t38-3q62 0 103.5 21t71.5 54v-320Z"
		/>
	</svg>
);
