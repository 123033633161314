import { Fragment, Component } from 'react';
import { List } from 'immutable';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { t } from '@transifex/native';

import {
	makeGetSingleActivity,
	makeGetActivityLikes,
} from 'pkg/selectors/activities.selector';

import { toggleLikeActvity } from 'pkg/actions/activities';
import { addActivityLikes } from 'pkg/actions/comments';

import * as sdk from 'pkg/core/sdk';

import * as Item from 'components/FeedItem';
import Icon from 'components/icon';

import Impressions from 'components/comment/Impressions';
import LikeModal from 'components/comment/LikeModal';

const MetaActionIcon = styled(Icon)`
	margin-right: 0.5rem;
`;

class CommentActions extends Component {
	static propTypes = {
		onCommentTrigger: PropTypes.func,
		likeCount: PropTypes.number.isRequired,
		commentCount: PropTypes.number.isRequired,
		id: PropTypes.number.isRequired,
		commentTriggerTarget: PropTypes.string,
	};

	static defaultProps = {
		likedByCurrentUser: false,
		user: {},
		commentCount: 0,
		comments: [],
		likeCount: 0,
	};

	state = {
		likeRequestInProgress: false,
		fetchingLikes: false,
		fetchedLikes: false,
		showLikesModal: false,
	};

	get commentUsers() {
		let commentUsers = this.props.comments.map((comment) => comment.user);

		return new List(commentUsers);
	}

	get likeUsers() {
		const likes = this.props.likes?.records || [];

		let likeUsers = likes.map((like) => like.user);

		return new List(likeUsers);
	}

	componentDidMount() {
		this.preloadLikes();
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (nextState.likeRequestInProgress !== this.state.likeRequestInProgress)
			return true;
		if (nextProps.commentTriggerTarget !== this.props.commentTriggerTarget)
			return true;
		if (nextProps.likedByCurrentUser !== this.props.likedByCurrentUser)
			return true;
		if (nextProps.commentCount !== this.props.commentCount) return true;
		if (nextProps.likeCount !== this.props.likeCount) return true;
		if (nextProps.id !== this.props.id) return true;
		if (nextState.showLikesModal !== this.state.showLikesModal) return true;
		if (nextState.fetchingLikes !== this.state.fetchingLikes) return true;
		if (nextState.fetchedLikes !== this.state.fetchedLikes) return true;

		return false;
	}

	commentTrigger = async (event) => {
		event.preventDefault();

		await new Promise((resolve) => {
			resolve(this.props.onCommentTrigger());
		});

		if (this.props.commentTriggerTarget) {
			const target = document.querySelector(this.props.commentTriggerTarget);

			if (target) {
				const bounds = target.getBoundingClientRect();
				const nextOffset = Math.ceil(
					Math.abs(bounds.top + window.scrollY) - 200
				);

				const targetTextArea = target.querySelector('textarea:first-of-type');

				targetTextArea.focus();

				window.scrollTo({
					left: 0,
					top: nextOffset,
					behavior: 'smooth',
				});
			}
		}
	};

	handleToggleLike = async (e) => {
		e.preventDefault();

		if (this.state.likeRequestInProgress) {
			return;
		}

		this.setState({ likeRequestInProgress: true });

		if (this.props.stateKey === 'activities') {
			await this.props.toggleLikeActvity(this.props.id);
		}

		await this.preloadLikes();

		this.setState({ likeRequestInProgress: false });
	};

	get likesModal() {
		const { showLikesModal } = this.state;
		const { likes } = this.props;

		if (!showLikesModal) {
			return null;
		}

		const fetchNext = this.fetchLikes(likes?.links?.next);

		return (
			<LikeModal
				closeModal={this.hideLikes}
				collection={likes}
				fetchLikes={fetchNext}
				fetching={this.state.fetchingLikes}
				groupId={this.props.groupId}
			/>
		);
	}

	async fetchLikes(endpoint) {
		if (!endpoint) {
			return;
		}

		this.setState({
			fetchingLikes: true,
		});

		const response = await sdk.get(endpoint);
		const collection = await response.json();

		switch (this.props.stateKey) {
			case 'activities':
				await this.props.addActivityLikes(this.props.id, collection);
				break;
			case 'posts':
				await this.props.addPostLikes(this.props.id, collection);
				break;
		}

		if (!this.state.fetchedLikes) {
			this.setState({
				fetchedLikes: true,
			});
		}

		this.setState({
			fetchingLikes: false,
		});
	}

	async preloadLikes() {
		const resourceType =
			this.props.stateKey === 'activities' ? 'user-activities' : 'group-posts';

		await this.fetchLikes(`/${resourceType}/${this.props.id}/likes`);
	}

	showLikes = () => {
		if (this.props.likeCount === 0) return;

		this.setState({
			showLikesModal: true,
		});
	};

	hideLikes = () => {
		this.setState({ showLikesModal: false });
	};

	render() {
		const { likedByCurrentUser, likeCount, commentCount } = this.props;

		return (
			<Fragment>
				<Impressions
					commentCount={commentCount}
					commentUsers={this.commentUsers}
					likeCount={likeCount}
					likeUsers={this.likeUsers}
					showLikes={this.showLikes}
				/>
				<Item.MetaWrapper>
					<Item.MetaAction
						key="item-meta-action-like"
						onClick={this.handleToggleLike}
						$active={likedByCurrentUser}>
						<MetaActionIcon name="thumbs-up" />
						{t('Like')}
					</Item.MetaAction>

					<Item.MetaAction
						key="item-meta-action-comment"
						onClick={this.commentTrigger}>
						<MetaActionIcon name="comment" />
						{t('Comment')}
					</Item.MetaAction>
					{this.likesModal}
				</Item.MetaWrapper>
			</Fragment>
		);
	}
}

const mapStateToProps = () => {
	const getActivityLikes = makeGetActivityLikes();
	const getSingleActivity = makeGetSingleActivity();

	return (state, props) => {
		let sectionEntities = null;
		let entity;
		let likes;

		if (props.stateKey === 'activities') {
			sectionEntities = state.activities;
			likes = getActivityLikes(state, props);
			entity = getSingleActivity(state, props);
		}

		const commentSection = sectionEntities.comments[props.id];

		return {
			commentCount: entity?.commentCount,
			comments: commentSection?.records,
			likes: likes,
			likeCount: entity?.likeCount,
			likedByCurrentUser: entity?.likedByCurrentUser,
		};
	};
};

const actions = {
	toggleLikeActvity,
	addActivityLikes,
};

export default connect(mapStateToProps, actions)(CommentActions);
