import { Fragment, ReactNode } from 'react';
import { t } from '@transifex/native';

import {
	SettingControl,
	SettingDescription,
	SettingLabel,
	SettingRow,
} from 'components/settings/Settings';

import Button from 'design/button';

interface EditableRowProps {
	label: string;
	hasError?: boolean;
	isSaving?: boolean;
	isEditing?: boolean;
	isDisabled?: boolean;

	onEdit: () => void;
	onSubmit: () => void;
	onCancel: () => void;

	staticComponent: ReactNode;
	editableComponent: ReactNode;
}

export default function EditableRow({
	label,
	hasError,
	isSaving,
	isEditing,
	isDisabled,

	onEdit,
	onSubmit,
	onCancel,

	staticComponent,
	editableComponent,
}: EditableRowProps): JSX.Element {
	return (
		<SettingRow isEditing={isEditing}>
			<SettingLabel>{label}</SettingLabel>
			<SettingDescription>
				{isEditing ? editableComponent : staticComponent}
			</SettingDescription>
			<SettingControl>
				{!isEditing && (
					<Button small icon="edit" onClick={onEdit}>
						{t('Change')}
					</Button>
				)}

				{isEditing && (
					<Fragment>
						<Button transparent onClick={onCancel}>
							{t('Cancel')}
						</Button>
						<Button
							primary
							onClick={onSubmit}
							disabled={isDisabled || hasError || isSaving}
							isLoading={isSaving}>
							{t('Save')}
						</Button>
					</Fragment>
				)}
			</SettingControl>
		</SettingRow>
	);
}
