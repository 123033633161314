import { ReactNode } from 'react';

export const symbol_sports_volleyball: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M806-579q-29-97-108.5-163.5T510-819v69l296 171ZM297-412l153-90v-317q-41 5-79.5 17T297-767v355Zm-126 75 66-39v-343q-47 48-72 110.5T140-480q0 39 8 73.5t23 69.5Zm153 160 313-181-157-92-280 165q24 33 55.5 61.5T324-177Zm156 37q86 0 159-38.5T760-285l-65-38-297 173q20 5 41 7.5t41 2.5Zm309-198q18-38 25-77t6-83L510-679v177l279 164ZM480-480Zm0 400q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z"
		/>
	</svg>
);
