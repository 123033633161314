import { ReactNode } from 'react';

export const symbol_stacked_line_chart: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m117-154 234-234q17-17 42.5-17.5T436-388l118 118 278-312q8-9 20.5-10t21.37 7.87q8.13 8.13 8.63 20.025.5 11.896-7.5 21.105L596-228q-17 20-43 20.5T509-225L394-339 163-108q-9.957 10-22.979 10Q127-98 117-107.967q-10-9.966-10-23Q107-144 117-154Zm0-269 234-234q17-17 42.5-17.5T436-657l118 118 278-312q8-9 20.5-10t21.37 7.87q8.13 8.13 8.63 20.025.5 11.896-7.5 21.105L596-497q-17 20-43 20.5T509-494L394-608 163-377q-9.957 10-22.979 10Q127-367 117-376.967q-10-9.966-10-23Q107-413 117-423Z"
		/>
	</svg>
);
