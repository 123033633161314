import { ReactNode } from 'react';

export const symbol_skillet_cooktop: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M240-370q-51 0-85.5-34.5T120-490v-119q0-13.175 8.913-22.088Q137.825-640 151-640h509q0-33 14-58.5t47-36.5l161-54q12-4 23.143 1.367Q916.286-782.267 920-770q4 12-1.565 23.143Q912.871-735.714 901-732l-161 53q-8.889 3.053-14.444 11.066Q720-659.921 720-650v160q0 50-35 85t-85 35H240Zm0-60h360q25.5 0 42.75-17.25T660-490v-90H180v90q0 25.5 17.25 42.75T240-430Zm150 280v-100H110q-12.75 0-21.375-8.675Q80-267.351 80-280.175 80-293 88.625-301.5T110-310h280q24.75 0 42.375 17.625T450-250v100q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T390-150Zm120 0v-100q0-24.75 17.625-42.375T570-310h280q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-250H570v100q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T510-150Zm-90-355Z"
		/>
	</svg>
);
