import { ReactNode } from 'react';

export const symbol_dry_cleaning: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M340-80q-24.75 0-42.375-17.625T280-140v-189h-71q-36 0-62.5-24.5T120-414q0-27 13.5-50.5T172-499l281-120v-42q-37-10-60.5-40.5T369-770q0-45.833 33-77.917Q435-880 480.93-880 514-880 542-862t42 48q2 4 2 13 0 12.75-8.5 21.375T556.407-771q-9.629 0-16.666-5.32Q532.704-781.64 529-790q-6-14-19.5-22t-28.397-8Q460-820 444.5-805.625T429-770q0 22.95 15.525 38.475Q460.05-716 483-716q12 0 21 8.338 9 8.337 9 20.662v68l275 120q25 11 38.5 34.5T840-414q0 36-26.5 60.5T751-329h-71v189q0 24.75-17.625 42.375T620-80H340ZM209-389h72q3-22 19.5-36.5T340-440h280q23 0 39.5 14.5T679-389h72q11 0 20-7.5t9-18.5q0-9-5-16t-13-11L483-568 198-442q-8 4-13 11t-5 15q0 11 8.5 19t20.5 8Zm131 249h280v-240H340v240Zm0-240h280-280Z"
		/>
	</svg>
);
