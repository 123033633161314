import { t } from '@transifex/native';

import { FilterOperator, createFilter } from 'pkg/filters';
import { Filters, useFilters } from 'pkg/filters/use_filters';
import * as models from 'pkg/api/models';

import AppliedToModal from 'routes/payments/orders/filters/applied_modal';
import ProductsSelectModal from 'routes/payments/products/modals/select';

import { GroupSelectModal } from 'components/group/select_modal';

export default function useInvoiceFilters(): Filters {
	return useFilters({
		filterBarMobileTrigger: true,
		groups: {
			[t('Has product')]: {
				filters: {
					HasProduct: createFilter({
						type: 'modal',
						property: 'product_id',
						operator: FilterOperator.Includes,
						settings: {
							modal: <ProductsSelectModal />,
							buttonLabel: t('Select product'),
							hideButton: false,
						},
					}),
				},
			},
			[t('Date sent')]: {
				filters: {
					DueDate: createFilter({
						type: 'date',
						property: 'sent_at',
					}),
				},
			},
			[t('Due date')]: {
				filters: {
					DueDate: createFilter({
						type: 'date',
						property: 'due_date',
					}),
				},
			},
			[t('Paid date')]: {
				filters: {
					DueDate: createFilter({
						type: 'date',
						property: 'paid_at',
					}),
				},
			},
			[t('Refund date')]: {
				filters: {
					DueDate: createFilter({
						type: 'date',
						property: 'refunded_at',
					}),
				},
			},
			[t('Amount')]: {
				filters: {
					Amount: createFilter({
						type: 'number',
						property: 'amount',
						settings: { money: true },
					}),
				},
			},
			[t('Status')]: {
				filters: {
					Status: createFilter({
						type: 'checkbox',
						property: 'status',
						operator: FilterOperator.Includes,
						values: {
							[t('Draft')]: models.order.Statuses.Draft,
							[t('Open')]: models.order.Statuses.Open,
							[t('Paid')]: models.order.Statuses.Paid,
							[t('Past due')]: models.order.Statuses.PastDue,
							[t('Void')]: models.order.Statuses.Void,
							[t('Uncollectible')]: models.order.Statuses.Uncollectible,
						},
					}),
				},
			},
			[t('Group')]: {
				filters: {
					Group: createFilter({
						type: 'modal',
						property: 'assigned_contact_group_id',
						operator: FilterOperator.Includes,
						settings: {
							modal: <GroupSelectModal />,
							buttonLabel: t('Select group'),
							hideButton: false,
						},
					}),
				},
			},
			[t('Has discount')]: {
				filters: {
					Applied: createFilter({
						type: 'modal',
						property: 'discount_id',
						operator: FilterOperator.Includes,
						settings: {
							modal: <AppliedToModal />,
							buttonLabel: t('Select discounts'),
							hideButton: false,
						},
					}),
				},
			},
		},
	});
}
