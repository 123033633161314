export function AddVideo(ID: number, videoID: number): string {
	return `/v1/video-collections/${ID}/videos/${videoID}`;
}

export function Create(): string {
	return `/v1/video-collections`;
}

export function Delete(ID: number): string {
	return `/v1/video-collections/${ID}`;
}

export function Index(): string {
	return `/v1/video-collections`;
}

export function RemoveVideo(ID: number, videoID: number): string {
	return `/v1/video-collections/${ID}/videos/${videoID}`;
}

export function Show(ID: number): string {
	return `/v1/video-collections/${ID}`;
}

export function ShowVideos(ID: number): string {
	return `/v1/video-collections/${ID}/videos`;
}

export function Update(ID: number): string {
	return `/v1/video-collections/${ID}`;
}
