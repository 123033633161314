export default function Spotlight(): JSX.Element {
	return (
		<svg
			viewBox="0 0 25 25"
			width="25"
			height="25"
			preserveAspectRatio="xMidYMid meet"
			fill="none">
			<defs>
				<linearGradient
					id="mask"
					x1="12.7149"
					y1="5.22565"
					x2="12.7149"
					y2="18.3426"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="currentColor" />
					<stop offset="1" stopColor="currentColor" stopOpacity="0" />
				</linearGradient>
			</defs>
			<path
				d="M11.4842 5.96385C11.7267 5.50945 12.1998 5.22565 12.7149 5.22565V5.22565C13.2299 5.22565 13.7031 5.50945 13.9456 5.96385L20.5516 18.3426H4.87811L11.4842 5.96385Z"
				fill="url(#mask)"
			/>
		</svg>
	);
}
