import { ReactNode } from 'react';

export const symbol_functions: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M515-480 250-728q-4.615-4.696-7.308-10.565-2.692-5.87-2.692-12.131v-18Q240-782 248.913-791q8.912-9 22.087-9h399q20.833 0 35.417 14.618Q720-770.765 720-749.882 720-729 705.417-714.5 690.833-700 670-700H396l212 198q10 9.13 10 22.065T608-458L396-260h274q20.833 0 35.417 14.618Q720-230.765 720-209.882 720-189 705.417-174.5 690.833-160 670-160H263q-9.517 0-16.259-6.724Q240-173.448 240-182.94V-213q0-4.941 1.5-9.471Q243-227 247-230l268-250Z"
		/>
	</svg>
);
