import { Fragment, useRef } from 'react';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';

import * as Input from 'components/form/inputs';

interface LocationFormProps {
	location?: models.resourceLocation.ResourceLocation;
}

const LocationForm = ({ location }: LocationFormProps) => {
	const titleRef = useRef(null);
	const defaultValues = {
		title: location ? location.title : '',
	};

	useComponentDidMount(() => {
		titleRef.current.focus();
	});

	return (
		<Fragment>
			<Input.Group label={t('Title')}>
				<Input.Field
					name="title"
					required
					defaultValue={defaultValues.title}
					ref={titleRef}
				/>
			</Input.Group>
		</Fragment>
	);
};

export default LocationForm;
