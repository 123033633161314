import { ReactNode } from 'react';

export const symbol_receipt: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M120-99v-762q0-5.25 4.5-7.125T133-866l36 36q4.636 5 10.818 5Q186-825 191-830l38-38q4.636-5 10.818-5Q246-873 251-868l38 38q4.636 5 10.818 5Q306-825 311-830l38-38q4.636-5 10.818-5Q366-873 371-868l38 38q4.636 5 10.818 5Q426-825 431-830l38-38q4.636-5 10.818-5Q486-873 491-868l38 38q4.636 5 10.818 5Q546-825 551-830l38-38q4.636-5 10.818-5Q606-873 611-868l38 38q4.636 5 10.818 5Q666-825 671-830l38-38q4.636-5 10.818-5Q726-873 731-868l38 38q4.636 5 10.818 5Q786-825 791-830l36-36q4-4 8.5-2.125T840-861v762q0 5.25-4.5 7.125T827-94l-36-36q-4.636-5-10.818-5Q774-135 769-130l-38 38q-4.636 5-10.818 5Q714-87 709-92l-38-38q-4.636-5-10.818-5Q654-135 649-130l-38 38q-4.636 5-10.818 5Q594-87 589-92l-38-38q-4.636-5-10.818-5Q534-135 529-130l-38 38q-4.636 5-10.818 5Q474-87 469-92l-38-38q-4.636-5-10.818-5Q414-135 409-130l-38 38q-4.636 5-10.818 5Q354-87 349-92l-38-38q-4.636-5-10.818-5Q294-135 289-130l-38 38q-4.636 5-10.818 5Q234-87 229-92l-38-38q-4.636-5-10.818-5Q174-135 169-130l-36 36q-4 4-8.5 2.125T120-99Zm147-197h430q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T697-356H267q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T267-296Zm0-154h430q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T697-510H267q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T267-450Zm0-155h430q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T697-665H267q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T267-605Zm-87 423h600v-596H180v596Zm0-596v596-596Z"
		/>
	</svg>
);
