import { ReactNode } from 'react';

export const symbol_gif_box: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-60h600v-600H180v600Zm0-600v600-600Zm150 380h61q12.325 0 20.663-9Q420-418 420-430v-25q0-6-4.5-10.5T405-470q-6 0-10.5 4.5T390-455v25h-60v-100h73q6 0 10.5-4.5T418-545q0-6-4.5-10.5T403-560h-73q-12.75 0-21.375 8.625T300-530v100q0 12.75 8.625 21.375T330-400Zm150 0q6 0 10.5-4.5T495-415v-130q0-6-4.5-10.5T480-560q-6 0-10.5 4.5T465-545v130q0 6 4.5 10.5T480-400Zm75 0q6 0 10.5-4.5T570-415v-45h55q6 0 10.5-4.5T640-475q0-6-4.5-10.5T625-490h-55v-40h75q6 0 10.5-4.5T660-545q0-6-4.5-10.5T645-560h-90q-6 0-10.5 4.5T540-545v130q0 6 4.5 10.5T555-400Z"
		/>
	</svg>
);
