import * as sdk from 'pkg/core/sdk';
import { Dateable } from 'pkg/api/models/dateable';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { Record } from 'pkg/api/models/record';

export interface Resource extends Dateable, Record {
	title: string;
	description?: string;
	resourceCategoryId: number;
	resourceLocationId: number;
	imageAttachmentId: number;
	parentResourceId?: number;
	groupId: number;
	userId: number;
	deletedAt?: number;

	category: models.resourceCategory.ResourceCategory;
	location: models.resourceLocation.ResourceLocation;

	children?: models.resource.Resource[];
}

function findChildren(resource: Resource, resources: Resource[]): Resource[] {
	const children = resources
		.filter((r) => r.parentResourceId === resource.id)
		.sort((a, b) => a.title.localeCompare(b.title));

	children.forEach((r) => {
		r.children = findChildren(r, resources);
	});

	return children;
}

export function buildResourceTree(
	resources: Resource[],
	resourceCategories: models.resourceCategory.ResourceCategory[],
	flatten?: boolean
): Resource[] {
	const rootItems = resources.filter((r) => r.parentResourceId === null);

	// Find all the root items that has a category tied to it
	let rootItemsWithCategories: Resource[] = [];
	resourceCategories
		.sort((a, b) => a.title.localeCompare(b.title))
		.forEach((c) => {
			const resources = rootItems
				.filter((r) => r.resourceCategoryId === c.id)
				.sort((a, b) => a.title.localeCompare(b.title));

			if (resources.length > 0) {
				rootItemsWithCategories = [...rootItemsWithCategories, ...resources];
			}
		});

	// Combine the root items with and without categories into a new set
	// to remove duplicates
	const allRootItems: Resource[] = [
		...new Set([
			...rootItemsWithCategories,
			...rootItems.sort((a, b) => a.title.localeCompare(b.title)),
		]),
	];

	allRootItems.forEach((r) => {
		const children = findChildren(r, resources);
		r.children = children;
	});

	if (flatten) {
		// Array with root items that we manipulate
		const flattenedResources: Resource[] = [...allRootItems];

		const flattenChildren = (resource: Resource) => {
			const index = flattenedResources.findIndex((r) => r.id === resource.id);

			if (resource.children.length > 0) {
				flattenedResources.splice(index + 1, 0, ...resource.children);
				resource.children.forEach((r) => flattenChildren(r));
			}
		};

		allRootItems.forEach((r) => flattenChildren(r));

		return flattenedResources;
	}

	return allRootItems;
}

export interface ResourceCreatePayload {
	title: string;
	groupId: number;
	description?: string;
	resourceCategoryId?: number;
	resourceLocationId?: number;
	imageAttachmentId?: number;
	parentResourceId?: number;
}

export async function create(
	payload: ResourceCreatePayload
): Promise<[Response, Resource, models.APIError?]> {
	if (!payload.title) {
		return Promise.resolve([new Response(null, { status: 400 }), null, null]);
	}

	return models.create(endpoints.Resource.Create(), payload);
}

export interface ResourceUpdatePayload {
	title: string;
	description: string;
	resourceCategoryId?: number;
	resourceLocationId?: number;
	imageAttachmentId?: number;
	parentResourceId?: number;
}

export async function update(
	resource: Resource,
	payload: ResourceUpdatePayload
): Promise<[Response, Resource]> {
	const req = await sdk.patch(
		endpoints.Resource.Update(resource.id),
		{},
		payload
	);

	if (!req.ok) {
		return [req, null];
	}

	const response: Resource = await req.json();

	return [req, response];
}

export async function remove(resource: Resource): Promise<boolean> {
	const req = await sdk.destroy(endpoints.Resource.Delete(resource.id));

	if (req.ok) {
		return true;
	}

	return false;
}
