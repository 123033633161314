import { ReactNode } from 'react';

export const symbol_oxygen_saturation: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M320-80q-20 0-36.5-11.5T257-120H150q-45.833 0-77.917-32.083Q40-184.167 40-230v-120q0-12.75 8.675-21.375Q57.351-380 70.175-380 83-380 91.5-371.375T100-350v120q0 21.25 14.375 35.625T150-180h107q10-17 26.5-28.5T320-220h50v-50q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T430-270v50h100v-50q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T590-270v50h50q20 0 36.5 11.5T703-180h107q21.25 0 35.625-14.375T860-230v-120q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T920-350v120q0 45.833-32.083 77.917Q855.833-120 810-120H703q-10 17-26.5 28.5T640-80H320ZM120-600.5q0-65.5 47-112.5t113-47h40v-130q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T380-890v120q0 28.875-20.562 49.438Q338.875-700 310-700h-30q-42 0-71 29t-29 71q0 40 29 67t71 32v61q-66-5-113-50t-47-110.5Zm720 .5q0 65-47 110t-113 50v-61q42-5 71-32t29-67q0-42-29-71t-71-29h-30q-28.875 0-49.438-20.562Q580-741.125 580-770v-120q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T640-890v130h40q66 0 113 47t47 113ZM480-440Zm-11 40q-40.557 0-73.278-24Q363-448 350-486q-5-15-18.5-24.5T302-520h-22v-60h22q35 0 63.98 20.579Q394.96-538.841 406-505q7 20 24.5 32.5T469-460h22q21 0 38.5-12.5T554-505q11.04-33.841 40.02-54.421Q623-580 658-580h22v60h-22q-17 0-30.02 9.444Q614.96-501.111 610-486q-13 38-45.722 62-32.721 24-73.278 24h-22Z"
		/>
	</svg>
);
