import { ReactNode } from 'react';

export const symbol_nest_multi_room: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M220-180h310v-145H220v145Zm370 0h150v-145H590v145ZM220-385h150v-145H220v145Zm210 0h310v-145H430v145ZM247-590h466L480-763 247-590Zm-27 470q-24.75 0-42.375-17.625T160-180v-390q0-14.25 6.375-27T184-618l260-195q8.295-6 17.344-9 9.049-3 18.853-3 9.803 0 18.717 3 8.915 3 17.086 9l260 195q11.25 8.25 17.625 21T800-570v390q0 24.75-17.625 42.375T740-120H220Z"
		/>
	</svg>
);
