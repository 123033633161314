import { memo } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import { useCurrentAccountWards } from 'pkg/identity';
import * as models from 'pkg/api/models';

import Avatar from 'components/avatar';

const Wrapper = styled.div`
	padding: 0 1rem;
	grid-area: initiate;
	border-bottom: var(--palette-gray-300) 1px solid;
	box-shadow: inset var(--palette-gray-300) -1px 0 0 0;

	display: grid;
	grid-template: 'avatar title' 'avatar name';
	grid-template-columns: 40px 1fr;
	align-items: center;
	align-content: center;
	row-gap: var(--spacing-1);
	column-gap: var(--spacing-4);

	strong {
		font-weight: var(--font-weight-semibold);
		grid-area: name;
	}

	span {
		font-size: var(--font-size-sm);
		color: var(--palette-gray-500);
		grid-area: title;
	}

	${Avatar} {
		grid-area: avatar;
	}
`;

interface MonitoringAccountProps {
	accountId: number;
}

const MonitoringAccount = memo(({ accountId }: MonitoringAccountProps) => {
	const wards = useCurrentAccountWards();
	const account = wards.find(
		(w) => w.targetAccount.id === accountId
	).targetAccount;

	return (
		<Wrapper>
			<Avatar account={account} />
			<span>{t('Monitoring chats for')}</span>
			<strong>{models.account.fullName(account)}</strong>
		</Wrapper>
	);
});

export default MonitoringAccount;
