import { t } from '@transifex/native';
import { Component } from 'react';
import styled from 'styled-components';

import { small } from 'pkg/config/breakpoints';

import Icon from 'components/icon';

import BigSelectButton from 'components/rating/create/stacks/big-select-button';

import Button from 'design/button';

const Wrapper = styled.div`
	width: 100%;
`;

const CompleteWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-flow: column wrap;
	padding: 20px 16px;

	@media ${small} {
		padding: 16px;
	}
`;

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding-bottom: 1rem;
	margin-top: 2rem;
`;

const Message = styled.p`
	margin: 0 auto 2rem;
	text-align: center;
	max-width: 60%;
`;

export default class CompleteComponent extends Component {
	state = {
		type: null,
	};

	PUBLISH_TYPES = {
		draft: 'draft',
		publish: 'publish',
	};

	componentDidMount() {
		if (!this.props.isAdmin) {
			this.props.onSave(null);
		}
	}

	get saveMessage() {
		if (this.props.isPrimaryRating === false) {
			return t(`Draft`);
		}

		if (this.props.isPrimaryRating === true) {
			return t(`Publish`);
		}

		return '';
	}

	setDraft = () => this.props.setPrimaryRating(false);

	setPublish = () => this.props.setPrimaryRating(true);

	get isDraft() {
		const { isPrimaryRating } = this.props;
		if (isPrimaryRating === null) {
			return false;
		}

		return isPrimaryRating === false;
	}

	get isPrimary() {
		const { isPrimaryRating } = this.props;

		if (isPrimaryRating === null) {
			return false;
		}

		return isPrimaryRating === true;
	}

	get renderAdmin() {
		return (
			<Wrapper>
				<ButtonWrapper>
					<BigSelectButton
						active={this.isDraft}
						onClick={this.setDraft}
						testid="assessment_modal.draft_button"
						text={t(`Draft`)}>
						<Icon name="nav-members" />
					</BigSelectButton>

					<BigSelectButton
						active={this.isPrimary}
						text={t(`Publish`)}
						onClick={this.setPublish}
						testid="assessment_modal.publish_button">
						<Icon name="nav-members" />
					</BigSelectButton>
				</ButtonWrapper>
				{this.props.isPrimaryRating !== null && (
					<Message>{this.saveMessage}</Message>
				)}
			</Wrapper>
		);
	}

	get renderPlayer() {
		return (
			<CompleteWrapper>
				<Button primary block onClick={this.onSave}>
					{t('Save')}
				</Button>
			</CompleteWrapper>
		);
	}

	render() {
		if (this.props.isAdmin) {
			return this.renderAdmin;
		}

		return this.renderPlayer;
	}
}
