import { Component } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import * as palette from 'pkg/config/palette';
import { small } from 'pkg/config/breakpoints';

import Slider from 'components/form/RangeSlider';

const MaturityWrapper = styled.div`
	padding: 0;
	display: grid;
	height: 100%;
	display: flex;
	flex-flow: column wrap;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	max-width: 60%;
	padding: 20px 1.5rem;
	margin-left: auto;
	margin-right: auto;

	@media ${small} {
		padding: 16px 1.5rem;
		max-width: 80%;
	}
`;

const OptionsWrapper = styled.div`
	display: flex;
	justify-content: space-between;

	@media ${small} {
		margin: 1rem 0;
	}

	span {
		color: ${palette.gray[600]};
		font-size: var(--font-size-sm);
		margin-bottom: 5px;

		@media ${small} {
			font-size: 0.7rem;
		}
	}
`;

const SliderWrapper = styled.div`
	width: 100%;
`;

const MaturityDescription = styled.div`
	margin-top: 1rem;
	text-align: center;
	font-weight: 300;

	@media ${small} {
		margin-top: 3rem;
	}
`;

export default class DevelopmentComponent extends Component {
	maturityLevels = [
		{
			key: 'very_early',
			value: -2,
			scaleValue: 4,
		},
		{
			key: 'early',
			value: -1,
			scaleValue: 3,
		},
		{
			key: 'average',
			value: 0,
			scaleValue: 2,
		},
		{
			key: 'late',
			value: 1,
			scaleValue: 1,
		},
		{
			key: 'very_late',
			value: 2,
			scaleValue: 0,
		},
	];

	componentDidMount() {
		this.props.onSelect('maturityAdjustment', 0);
	}

	handleOnChange = (value) => {
		const nextVal = this.maturityLevels.find((l) => l.scaleValue === value);

		this.props.onSelect('maturityAdjustment', nextVal.value);
	};

	get maturityAdjustment() {
		const foundMaturityLevel = this.maturityLevels.find(
			(l) => l.value === this.props.maturityAdjustment
		);

		if (foundMaturityLevel) {
			return foundMaturityLevel;
		}

		return {};
	}

	get sliderProps() {
		return {
			min: 0,
			max: 4,
			step: 1,
			value: this.maturityAdjustment.scaleValue,
			onChange: this.handleOnChange,
		};
	}

	get description() {
		const descriptions = {
			very_early: t(
				'The player is much earlier than average compared to the other players of the team.'
			),
			early: t(
				'The player is earlier than average compared to the other players of the team.'
			),
			average: t(
				'The player is at the same level of physical maturity as most players in the team.'
			),
			late: t(
				'The player is less physically matured than most players in the team.'
			),
			very_late: t(
				'The player is much less physically matured than most players in the team.'
			),
		};

		return descriptions[this.maturityAdjustment.key];
	}

	render() {
		return (
			<MaturityWrapper>
				<SliderWrapper>
					<OptionsWrapper>
						<span>{t('Very Late')}</span>
						<span>{t('Very Early')}</span>
					</OptionsWrapper>

					<Slider {...this.sliderProps} />
				</SliderWrapper>
				<MaturityDescription>
					<p>{this.description}</p>
				</MaturityDescription>
			</MaturityWrapper>
		);
	}
}
