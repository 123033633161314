import * as models from 'pkg/api/models';

import Badge from 'components/Badge';
import Icon from 'components/icon';

import * as css from './styles.css';

interface TableHeaderProps {
	match: models.match.Match;
}

export default function TableHeader({ match }: TableHeaderProps) {
	const matchOpponent = match.opponent;

	const rows = [<td key="label"></td>];

	if (match?.isHome) {
		rows.unshift(
			<td key="home-group-badge">
				<div className={css.badgeWrapper}>
					<Badge badgeUrl={match.group?.profileImageUrl} />
				</div>
			</td>
		);

		rows.push(
			<td key="home-empty-cell">
				<div className={css.badgeWrapper}>
					{matchOpponent?.imageUrl ? (
						<Badge badgeUrl={matchOpponent.imageUrl} />
					) : (
						<Icon className={css.placeHolderIcon} name="nav-group" />
					)}
				</div>
			</td>
		);
	} else if (!match?.isHome) {
		rows.push(
			<td key="away-group-badge">
				<div className={css.badgeWrapper}>
					<Badge badgeUrl={match.group?.profileImageUrl} />
				</div>
			</td>
		);

		rows.unshift(
			<td key="away-empty-cell">
				<div className={css.badgeWrapper}>
					{matchOpponent?.imageUrl ? (
						<Badge badgeUrl={matchOpponent.imageUrl} />
					) : (
						<Icon className={css.placeHolderIcon} name="nav-group" />
					)}
				</div>
			</td>
		);
	}

	return <tr className={css.tableRow}>{rows}</tr>;
}
