import styled, { css } from 'styled-components';

import * as styles from 'pkg/config/styles';

import Link from 'pkg/router/Link';
import * as models from 'pkg/api/models';
import { useCurrentOrganization } from 'pkg/identity';
import * as routes from 'pkg/router/routes';
import * as routerState from 'pkg/router/state';
import { useOrganizationIdentity } from 'pkg/identity/organization';

import MostRecentReply from 'containers/chat/MostRecentReply';
import Avatars from 'containers/chat/Avatars';

import Icon from 'components/icon';
import Avatar from 'components/avatar';

import * as iconStyles from 'components/icon/styles.css';

const ContentArea = styled.div`
	grid-area: content;
	display: grid;
	grid-template-areas:
		'title timestamp'
		'message message';
	grid-template-columns: 1fr auto;
	box-shadow: inset 0 -1px 0 0 var(--palette-gray-300);
	padding-bottom: var(--spacing-4);
`;

const Chat = styled(Link)`
	padding: var(--spacing-3) var(--spacing-5) 0 var(--spacing-5);
	display: grid;
	grid-template-areas: 'avatar content content';
	grid-template-columns: 3rem 1fr;
	grid-template-rows: auto;
	grid-column-gap: var(--spacing-3);
	color: var(--palette-gray-800);
	cursor: pointer;

	@media ${styles.breakpoint.small} {
		grid-column-gap: var(--spacing-2);
	}

	&:hover {
		background: var(--palette-gray-200);
		box-shadow: 0 -1px 0 0 var(--palette-gray-200);

		${ContentArea} {
			box-shadow: none;
		}
	}

	${(props) =>
		props.unread &&
		css`
			font-weight: var(--font-weight-semibold);
			color: var(--palette-black);
		`};

	${(props) =>
		props.active &&
		css`
			box-shadow: 0 -1px 0 0 var(--palette-gray-250);
			background: var(--palette-gray-250);

			${ContentArea} {
				box-shadow: none;
			}
		`};
`;

const AvatarsWrapper = styled.div<{ numberOfAvatars: number }>`
	position: relative;
	width: 42px;
	height: 42px;

	${(props) =>
		props.numberOfAvatars === 1 &&
		css`
			${Avatar} {
				width: 42px;
				height: 42px;
			}
		`}

	${(props) =>
		props.numberOfAvatars === 2 &&
		css`
			${Avatar} {
				position: absolute;
				width: 32px;
				height: 32px;

				&:first-child {
					top: 0;
					right: 0;
				}

				&:last-child {
					bottom: 0;
					left: 0;
				}
			}
		`}

	${(props) =>
		props.numberOfAvatars === 3 &&
		css`
			${Avatar} {
				position: absolute;
				width: 26px;
				height: 26px;

				&:nth-child(1) {
					bottom: 0;
					left: 0;
				}

				&:nth-child(2) {
					bottom: 0;
					right: 0;
				}

				&:nth-child(3) {
					top: 0;
					left: 50%;
					transform: translateX(-50%);
				}

				&:nth-child(n + 4) {
					display: none;
				}
			}
		`}
`;

const ChatTitle = styled.div`
	display: flex;
	height: 22px;
	align-items: center;
	grid-area: title;
	align-self: start;
	font-weight: var(--font-weight-semibold);
	color: var(--palette-gray-800);
	min-width: 0;

	.${iconStyles.icon} {
		flex: 0;
		margin-right: var(--spacing-1);
		margin-left: -2px;
	}

	span {
		flex: 1;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
`;

interface ChatListItemProps {
	chat: models.chat.Chat;
	currentChatId: number;
	monitoredAccountId?: number;
	replaceState: boolean;
}

function ChatListItem({
	chat,
	currentChatId,
	monitoredAccountId = null,
	replaceState = false,
}: ChatListItemProps) {
	const org = useCurrentOrganization();
	const { users } = useOrganizationIdentity();
	const activeAccountUserIds = users.map((u) => u.id);
	const chatTitle = models.chat.getChatTitle(chat, activeAccountUserIds);
	const lastReadMessageId = models.chat.getLastReadMessageId(
		chat,
		activeAccountUserIds
	);
	const myChatUser = models.chat.findMyChatUser(chat, activeAccountUserIds);

	const active = currentChatId === chat.id;
	const isUnread = !active && chat.lastMessageId > lastReadMessageId;

	let href = routes.Chat.Show(org.id, chat.id);

	if (monitoredAccountId) {
		href = routes.Chat.Monitored.Show(org.id, monitoredAccountId, chat.id);
	}

	const navigate = () => {
		if (replaceState) {
			routerState.replaceState(href);
		} else {
			routerState.pushState(href);
		}
	};

	const isArchived = chat?.isArchived || myChatUser?.hasArchived;
	const testid = isArchived ? 'chat.archived_list_item' : 'chat.list_item';

	return (
		<Chat
			onClick={navigate}
			active={active}
			unread={isUnread}
			archived={isArchived}>
			<Avatars chat={chat} item={AvatarsWrapper} myChatUser={myChatUser} />
			<ContentArea>
				<ChatTitle data-testid={testid}>
					{models.chat.isGroup(chat) && <Icon name="nav-members" size={0.95} />}
					<span>{chatTitle}</span>
				</ChatTitle>
				<MostRecentReply chat={chat} unread={isUnread} />
			</ContentArea>
		</Chat>
	);
}

export default ChatListItem;
