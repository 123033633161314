import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import { cssClasses } from 'pkg/css/utils';

import OrderRow from 'routes/payments/orders/single/invoice_description/row';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import * as InfoList from 'components/payment_platform/InfoList';

import * as css from './styles.css';

interface DescriptionTableProps {
	order: models.order.Order;
}

const DescriptionTable: React.FC<
	React.PropsWithChildren<DescriptionTableProps>
> = ({ order }) => {
	const orderRows = models.orderRow.findProductRows(order.rows);

	return (
		<div>
			<div className={css.wrapper}>
				<LargeScreen>
					<table className={css.table}>
						<thead>
							<tr className={css.tr}>
								<th className={css.th}>{t('Product')}</th>
								<th className={css.th}>{t('Type')}</th>
								<th className={cssClasses(css.th, css.alignRight)}>
									{t('Valid until')}
								</th>
								<th className={cssClasses(css.th, css.alignRight)}>
									{t('Quantity')}
								</th>
								<th className={cssClasses(css.th, css.alignRight)}>
									{t('TAX')}
								</th>
								<th className={cssClasses(css.th, css.alignRight)}>
									{t('Unit price')}
								</th>
								<th className={cssClasses(css.th, css.alignRight)}>
									{t('Total amount')}
								</th>
							</tr>
						</thead>
						<tbody>
							{orderRows?.map((row: models.orderRow.OrderRow) => (
								<OrderRow
									key={row.id}
									row={row}
									orderCurrency={order.currency}
								/>
							))}
						</tbody>
					</table>
				</LargeScreen>

				<SmallScreen>
					<InfoList.List>
						{orderRows?.map((row: models.orderRow.OrderRow) => (
							<OrderRow key={row.id} row={row} orderCurrency={order.currency} />
						))}
					</InfoList.List>
				</SmallScreen>
			</div>
		</div>
	);
};

export default DescriptionTable;
