import { ReactNode } from 'react';

export const symbol_nest_secure_alarm: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480.5-450q12.5 0 21-9t8.5-21.5q0-12.5-8.625-21T480-510q-12 0-21 8.625T450-480q0 12 9 21t21.5 9Zm120 0q12.5 0 21-9t8.5-21.5q0-12.5-8.625-21T600-510q-12 0-21 8.625T570-480q0 12 9 21t21.5 9Zm-240 0q12.5 0 21-9t8.5-21.5q0-12.5-8.625-21T360-510q-12 0-21 8.625T330-480q0 12 9 21t21.5 9Zm120 120q12.5 0 21-9t8.5-21.5q0-12.5-8.625-21T480-390q-12 0-21 8.625T450-360q0 12 9 21t21.5 9Zm120 0q12.5 0 21-9t8.5-21.5q0-12.5-8.625-21T600-390q-12 0-21 8.625T570-360q0 12 9 21t21.5 9Zm-240 0q12.5 0 21-9t8.5-21.5q0-12.5-8.625-21T360-390q-12 0-21 8.625T330-360q0 12 9 21t21.5 9Zm120-240q12.5 0 21-9t8.5-21.5q0-12.5-8.625-21T480-630q-12 0-21 8.625T450-600q0 12 9 21t21.5 9Zm120 0q12.5 0 21-9t8.5-21.5q0-12.5-8.625-21T600-630q-12 0-21 8.625T570-600q0 12 9 21t21.5 9Zm-240 0q12.5 0 21-9t8.5-21.5q0-12.5-8.625-21T360-630q-12 0-21 8.625T330-600q0 12 9 21t21.5 9ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm-.221-60Q621-140 720.5-239.279q99.5-99.28 99.5-240.5Q820-621 720.721-720.5t-240.5-99.5Q339-820 239.5-720.721t-99.5 240.5Q140-339 239.279-239.5q99.28 99.5 240.5 99.5ZM480-480Z"
		/>
	</svg>
);
