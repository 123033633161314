import { List, Map } from 'immutable';

import Membership, { MembershipRole } from 'pkg/models/membership';
import User from 'pkg/models/user';

import { RootState } from 'pkg/reducers';

const emptyUser = new User({});

export const findAll = (state: RootState): Map<number, User> =>
	state.users.entities;

export const find = (state: RootState, userId: number): User => {
	return findAll(state).get(userId) || emptyUser;
};

export const findAllByIds = (
	state: RootState,
	userIds: number[] = []
): List<User> =>
	List([...new Set(userIds)].map((userId) => find(state, userId)));

export const findAllByAccountId = (
	state: RootState,
	accountId: number
): List<User> =>
	findAll(state)
		.filter((u: User) => u.accountId === accountId)
		.toList();

export const findAllByGroup = (
	state: RootState,
	groupId: number,
	role?: MembershipRole
): Map<number, User> => {
	let memberships: Map<number, Membership> = state.memberships.get('items');
	const users = findAll(state);

	memberships = memberships.filter(
		(member: Membership) =>
			member.groupId === groupId && users.get(member.userId)
	);

	if (role) {
		memberships = memberships.filter((member) => member.role === role);
	}

	return memberships.map((member: Membership) => users.get(member.userId));
};

/**
 * Finds all actual memberships for the given user IDs.
 * These are groups where the user IDs has an active membership, as opposed to inherited
 * memberships through a node, or a ward's memberships.
 */
export const findAllMemberships = (
	state: RootState,
	userIds: number[] | List<number>,
	includePending: boolean = true
): List<Membership> => {
	const memberships: Map<string, Membership> = state.memberships.items;
	const users = state.users.entities;
	const groups = state.groups.entities;

	return memberships
		.filter((membership) => {
			if (!userIds.includes(membership.userId)) {
				return false;
			}

			if (!membership.valid()) {
				return false;
			}

			if (!includePending && !membership.isActive()) {
				return false;
			}

			return true;
		})
		.toList()
		.map((membership) => {
			const user = users.get(membership.userId);
			const group = groups.get(membership.groupId);

			membership = membership.set('user', user).set('group', group);

			return membership;
		})
		.sortBy((membership) => membership.group?.name);
};

export const findByOrganizationIdAndAccountId = (
	state: RootState,
	organizationId: number,
	accountId: number
): User => {
	const users = findAllByAccountId(state, accountId);

	return users.find((user) => user.organizationId === organizationId);
};

export const findAllByOrganizationId = (
	state: RootState,
	organizationId: number
): List<User> => {
	const users = findAll(state);

	return users
		.filter((user) => user.organizationId === organizationId)
		.toList();
};
