import { ReactNode } from 'react';

export const symbol_smoke_free: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M125-337q-20.833 0-35.417-14.618Q75-366.235 75-387.118 75-408 89.583-422.5 104.167-437 125-437h337L90-809q-9-9-9-21.5t9-21.5q9-9 21.5-9t21.5 9l732 732q9 9 9 21t-9 21q-9 9-21.5 9T822-78L562-337H125Zm630.116-100Q766-437 773-429.917q7 7.084 7 17.917v50q0 11-7.212 18-7.211 7-17.788 7h-22l-3-3v-72q0-10.833 7.116-17.917 7.117-7.083 18-7.083Zm95 0Q861-437 868-429.903t7 17.952v50.097Q875-351 867.884-344q-7.117 7-18 7Q839-337 832-344.097t-7-17.952v-50.097Q825-423 832.116-430q7.117-7 18-7ZM633-437h27q10.833 0 17.917 7.083Q685-422.833 685-412v27l-52-52Zm121.884-45Q744-482 737-489.083q-7-7.084-7-17.917v-31q0-42-24.5-66T646-628h-71q-54 0-90.5-41.5T448-765q0-54 36.5-90.5T575-892q10.833 0 17.917 7.116 7.083 7.117 7.083 18Q600-856 592.917-849q-7.084 7-17.917 7-35 0-56 21t-21 56q0 35 21 61t55.565 26H646q54.377 0 94.188 37Q780-604 780-550v43q0 10.833-7.116 17.917-7.117 7.083-18 7.083Zm95 0Q839-482 832-489.083q-7-7.084-7-17.917v-75q0-75-50-123t-125-48q-10.833 0-17.917-7.116-7.083-7.117-7.083-18Q625-789 632.083-796q7.084-7 17.917-7 35 0 56-24t21-58q0-10.833 7.116-17.917 7.117-7.083 18-7.083Q763-910 770-902.917q7 7.084 7 17.917 0 33-12.5 57T732-787q58 20 100.5 75T875-582v75q0 10.833-7.116 17.917-7.117 7.083-18 7.083Z"
		/>
	</svg>
);
