import * as React from 'react';
import styled from 'styled-components';

import Position from 'pkg/models/position';
import Rating from 'pkg/models/rating';

const Wrapper = styled.div`
	position: relative;
	width: 315px;
	min-height: 220px;

	display: grid;
	grid-template-rows: 20% 45% 35%;

	margin: var(--spacing-8) auto var(--spacing-9) auto;

	div {
		z-index: 2;
	}

	img {
		position: absolute;
		width: 100%;
		z-index: 1;
	}
`;

const PlayerPosition = styled.div<{ justifyContent: string; column: string }>`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: ${(props) => props.justifyContent};
	grid-column-start: ${(props) => props.column};

	height: 100%;

	text-align: center;
	color: var(--palette-white);
`;

const Slug = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 45px;
	height: 45px;
	margin: var(--spacing-2);

	background-color: #39824f;
	border: 2px solid var(--palette-white);
	border-radius: 50%;

	font-size: var(--font-size-sm);
	font-weight: var(--font-weight-semibold);
`;

const Layer = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	align-items: center;
	text-align: center;
	margin: 0 auto;
`;

const ThirdLayer = styled(Layer)`
	width: 230px;
`;

const SecondLayer = styled(Layer)`
	width: 260px;
`;

const FirstLayer = styled(Layer)`
	width: 300px;
`;

/*

The football field is divided in to a 3x3 grid.
flex-end (deff) / flex-start (off) determines deffensive/offensive on
the targeted posiiton.

[ |   |   | ]
	---------
	|   |   |
	---------
	|   |   |
[ --------- ]

*/

type gridPositionProps = {
	layer: string;
	column: string;
	justifyContent?: string;
};

const GRID_POSITIONS: { [key: string]: gridPositionProps } = {
	gk: { layer: 'first', column: '2' },
	lb: { layer: 'first', column: '1' },
	cb: { layer: 'first', column: '2' },
	rb: { layer: 'first', column: '3' },
	lm: { layer: 'second', column: '1' },
	cdm: { layer: 'second', column: '2', justifyContent: 'flex-end' },
	cm: { layer: 'second', column: '2' },
	cam: {
		layer: 'second',
		column: '2',
		justifyContent: 'flex-start',
	},
	rm: { layer: 'second', column: '3' },
	lw: { layer: 'third', column: '1' },
	st: { layer: 'third', column: '2' },
	cf: { layer: 'third', column: '2', justifyContent: 'flex-end' },
	rw: { layer: 'third', column: '3' },
};

type PositionGroupProps = {
	position: Position;
	score: number;
	fieldGroup: string;
	rating: Rating;
};

const PositionGroup: React.FC<React.PropsWithChildren<PositionGroupProps>> = ({
	position,
	score,
	fieldGroup,
	rating,
}) => {
	const gridPosition: gridPositionProps = GRID_POSITIONS[position?.slug];

	return gridPosition?.layer === fieldGroup ? (
		<PlayerPosition
			column={gridPosition.column}
			justifyContent={gridPosition.justifyContent || 'center'}>
			{position.slug === 'gk' && !rating.hiddenScores && <p>{score}</p>}
			<Slug>{position.name}</Slug>
			{position.slug !== 'gk' && !rating.hiddenScores && <p>{score}</p>}
		</PlayerPosition>
	) : null;
};

type FootbalFieldProps = {
	position: Position;
	score: number;
	rating: Rating;
};

const FootballField: React.FC<React.PropsWithChildren<FootbalFieldProps>> = ({
	position,
	score,
	rating,
}) => (
	<Wrapper>
		<img src={`${window.TS['assetUrl']}/img/football-field.png`} />

		<ThirdLayer>
			<PositionGroup
				position={position}
				score={score}
				rating={rating}
				fieldGroup="third"
			/>
		</ThirdLayer>

		<SecondLayer>
			<PositionGroup
				position={position}
				score={score}
				rating={rating}
				fieldGroup="second"
			/>
		</SecondLayer>

		<FirstLayer>
			<PositionGroup
				position={position}
				score={score}
				rating={rating}
				fieldGroup="first"
			/>
		</FirstLayer>
	</Wrapper>
);

export default FootballField;
