import { Dispatch, SetStateAction, useRef } from 'react';
import styled, { css } from 'styled-components';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';

import Icon, { IconName } from 'components/icon';

import * as Input from 'components/form/inputs';
import Column from 'components/layout/column';
import Row from 'components/layout/row';

const icons: IconName[] = [
	'nav-home',
	'field',
	'cone',
	'bus',
	'dressingroom',
	'video-camera',
	'camera',
];

const IconWrapper = styled.div<{ selected: boolean }>`
	height: 40px;
	width: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: var(--palette-gray-400) 0 0 0 1px;
	border-radius: var(--radius-3);
	cursor: pointer;

	&:hover {
		box-shadow:
			var(--palette-gray-400) 0 0 0 1px,
			var(--palette-gray-300) 0 0 0 4px;
	}

	${({ selected }) =>
		selected &&
		css`
			box-shadow: var(--palette-blue-500) 0 0 0 2px;
			color: var(--palette-blue-500);
			&:hover {
				box-shadow:
					var(--palette-blue-500) 0 0 0 2px,
					var(--palette-gray-300) 0 0 0 4px;
			}
		`};
`;

interface CategoryFormProps {
	icon: IconName | '';

	setIcon: Dispatch<SetStateAction<'' | IconName>>;

	category?: models.resourceCategory.ResourceCategory;
}

const CategoryForm = ({ category, icon, setIcon }: CategoryFormProps) => {
	const titleRef = useRef(null);
	const defaultValues = {
		title: category ? category.title : '',
	};

	useComponentDidMount(() => {
		titleRef.current.focus();
	});

	const handleIconClick = (name: IconName) => {
		setIcon((prev) => {
			if (prev === name) {
				return '';
			} else {
				return name;
			}
		});
	};

	return (
		<Column>
			<Input.Group label={t('Title')}>
				<Input.Field
					name="title"
					required
					defaultValue={defaultValues.title}
					ref={titleRef}
				/>
			</Input.Group>
			<Input.Group label={t(`Select icon`)}>
				<Row autoColumns="max-content">
					{icons.map((i, key) => (
						<IconWrapper
							key={key}
							selected={i === icon}
							onClick={() => handleIconClick(i)}>
							<Icon name={i} size={1.5} actualSize />
						</IconWrapper>
					))}
				</Row>
			</Input.Group>
		</Column>
	);
};

export default CategoryForm;
