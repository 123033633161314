import { ReactNode } from 'react';

export const symbol_home_repair_service: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-400ZM80-190v-390q0-24 18-42t42-18h140v-100q0-24 18-42t42-18h280q24 0 42 18t18 42v100h140q24 0 42 18t18 42v390q0 12.75-8.625 21.375T850-160H110q-12.75 0-21.375-8.625T80-190Zm220-180v30q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T240-340v-30H140v150h680v-150H720v30q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T660-340v-30H300ZM140-580v150h100v-30q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T300-460v30h360v-30q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T720-460v30h100v-150H140Zm200-60h280v-100H340v100Z"
		/>
	</svg>
);
