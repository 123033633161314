import { ReactNode } from 'react';

export const symbol_foggy: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M720-205q-14 0-24.5-10.5T685-240q0-14 10.5-24.5T720-275q14 0 24.5 10.5T755-240q0 14-10.5 24.5T720-205ZM280-80q-14 0-24.5-10.5T245-115q0-14 10.5-24.5T280-150q14 0 24.5 10.5T315-115q0 14-10.5 24.5T280-80Zm-40-125q-14 0-24.5-10.5T205-240q0-14 10.5-24.5T240-275h360q14 0 24.5 10.5T635-240q0 14-10.5 24.5T600-205H240ZM400-80q-14 0-24.5-10.5T365-115q0-14 10.5-24.5T400-150h280q14 0 24.5 10.5T715-115q0 14-10.5 24.5T680-80H400ZM290-340q-86.864 0-148.432-61.52Q80-463.04 80-549.835 80-629 136.5-691 193-753 277-759q32-56 84.5-88.5T480.423-880Q571-880 632.5-822.5T708-680q79 4 125.5 53.5T880-510.377Q880-440 830.417-390 780.833-340 710-340H290Zm0-60h420q46.2 0 78.1-32.5 31.9-32.5 31.9-78T788.1-588q-31.9-32-78.1-32h-60v-30q0-71-49.5-120.5T480.212-820q-51.481 0-93.847 27.5Q344-765 324-718l-8 18h-28q-62 2-105 45.393t-43 104.464Q140-488 183.929-444 227.857-400 290-400Zm190-210Z"
		/>
	</svg>
);
