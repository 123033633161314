import { ReactNode } from 'react';

export const symbol_carry_on_bag_checked: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m698-206-45-45q-5.6-6-14-6t-14 6q-6 5.6-6 14t6 14l52 52q9 9 21 9t21-9l106-107q6-5.6 6-14t-6-14q-5.6-6-14-6t-14 6l-99 100Zm-340 11v-409h-66v409h66Zm130 60H292q-24 0-42-18t-18-42v-409q0-24 18-42t42-18h281v-156h-54q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T519-880h84q12.75 0 21.375 8.625T633-850v373q-14 5-30.5 14T573-443v-161H418v409h54q1 15 5 30.5t11 29.5Zm237-294q80.51 0 137.255 56.745Q919-315.51 919-235q0 80.51-56.745 137.255Q805.51-41 725-41q-80.51 0-137.255-56.745Q531-154.49 531-235q0-80.51 56.745-137.255Q644.49-429 725-429Zm-337 29Zm-30 205v-409 409Zm60 0v-409 409Z"
		/>
	</svg>
);
