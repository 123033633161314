import { ReactNode } from 'react';

export const symbol_bar_chart_4_bars: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M110-130q-12.75 0-21.375-8.675Q80-147.351 80-160.175 80-173 88.625-181.5T110-190h740q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-130H110Zm59.882-120Q149-250 134.5-264.583 120-279.167 120-300v-170q0-20.833 14.618-35.417Q149.235-520 170.118-520 191-520 205.5-505.417 220-490.833 220-470v170q0 20.833-14.618 35.417Q190.765-250 169.882-250Zm206 0Q355-250 340.5-264.583 326-279.167 326-300v-370q0-20.833 14.618-35.417Q355.235-720 376.118-720 397-720 411.5-705.417 426-690.833 426-670v370q0 20.833-14.618 35.417Q396.765-250 375.882-250Zm207 0Q562-250 547.5-264.583 533-279.167 533-300v-250q0-20.833 14.618-35.417Q562.235-600 583.118-600 604-600 618.5-585.417 633-570.833 633-550v250q0 20.833-14.618 35.417Q603.765-250 582.882-250Zm207 0Q769-250 754.5-264.583 740-279.167 740-300v-490q0-20.833 14.618-35.417Q769.235-840 790.118-840 811-840 825.5-825.417 840-810.833 840-790v490q0 20.833-14.618 35.417Q810.765-250 789.882-250Z"
		/>
	</svg>
);
