import { createElement } from 'react';

import * as models from 'pkg/api/models';

import {
	LineupContainer,
	Sections,
	Field,
	FieldBody,
	PlayerSections,
	Lines,
} from 'components/football-field';

import StartingPlayersBySection from 'components/match/starting-field/starting-players-by-section';

interface StartingFieldProps {
	matchEvents: models.matchEvent.MatchEvent[];
	positions: models.position.Position[];

	className?: string;
}

export default function StartingField({
	matchEvents,
	positions = [],
	className,
}: StartingFieldProps) {
	return (
		<FieldBody className={className}>
			<LineupContainer>
				<Field>
					<Lines.goalCircle />
					<Lines.goalAreaOuter />
					<Lines.goalAreaInner />

					<Lines.centerCircle />
					<Lines.centerLine />

					<Lines.goalCircle bottom />
					<Lines.goalAreaOuter bottom />
					<Lines.goalAreaInner bottom />
				</Field>

				<Sections>
					{positions.map((position) => {
						return createElement(
							PlayerSections[position.slug],
							{ key: position.slug },
							<StartingPlayersBySection
								matchEvents={matchEvents}
								position={position}
							/>
						);
					})}
				</Sections>
			</LineupContainer>
		</FieldBody>
	);
}
