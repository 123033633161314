import { ReactNode } from 'react';

export const symbol_line_end_arrow_notch: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M495-235q-10 7-18.5-1t-2.5-19l119-195H120q-13 0-21.5-8.5T90-480q0-13 8.5-21.5T120-510h473L474-705q-6-11 2.5-19t18.5-1l345 220q14 9 14 25t-14 25L495-235Z"
		/>
	</svg>
);
