import { ReactNode } from 'react';

export const symbol_manage_history: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M477-780q-68 0-127.5 31T246-667h75q13 0 21.5 8.5T351-637q0 13-8.5 21.5T321-607H172q-13 0-21.5-8.5T142-637v-148q0-13 8.5-21.5T172-815q13 0 21.5 8.5T202-785v76q52-61 123.5-96T477-840q75 0 140.5 27.5T733-736q50 49 75.5 107.5T839-503q1 12-8 21t-22 9q-12 0-20.5-8.5T779-502q-4-56-25-103.5T691-694q-42-40-96.5-63T477-780ZM150-481q12 0 20.5 8.5T181-451q11 107 85 182.5T446-182q16 2 23 11t7 20q0 12-8 22t-22 8q-131-11-222.5-104.5T121-451q-1-12 7.5-21t21.5-9Zm361-13 64 62q11 11 9.5 23t-9.5 20q-8 8-19.5 9.5T533-389l-73-71q-5-5-7-10.5t-2-11.5v-171q0-13 8.5-21.5T481-683q13 0 21.5 8.5T511-653v159ZM758-10q-10 0-17-6.5T732-33l-7-43q-18-5-34-13.5T661-112l-38 8q-8 2-15-1t-11-10l-6-10q-5-8-3.5-16t7.5-14l33-31q-3-12-3-25t3-25l-33-31q-6-5-7.5-13.5T591-296l6-12q4-7 10.5-9.5t14.5-.5l39 8q14-14 30-22.5t34-13.5l7-43q2-10 9-16.5t17-6.5h8q10 0 17 6.5t9 16.5l7 43q18 5 34 13.5t30 22.5l38-8q8-2 15 1t11 10l6 10q5 8 3.5 16t-7.5 14l-33 31q3 12 3 25t-3 25l33 31q6 5 7.5 13.5T933-126l-6 12q-4 7-10.5 9.5t-14.5.5l-39-8q-14 14-30 22.5T799-76l-7 43q-2 10-9 16.5T766-10h-8Zm4-121q36 0 58-22t22-58q0-36-22-58t-58-22q-36 0-58 22t-22 58q0 36 22 58t58 22Z"
		/>
	</svg>
);
