import { QueryObject } from 'pkg/hooks/query-state';
import { tlog } from 'pkg/tlog';

export interface WorkOSError {
	error: string;
	description: string;
	hasError: boolean;
}

/**
 * Takes the query and structures potential errors workOS provides into an object
 * @param query
 * @returns
 */
export default function getWorkOSErrors(query: QueryObject): WorkOSError {
	const error = query?.error as string;
	const errorDescription = query?.error_description as string;
	const hasWorkOSErrors = error || errorDescription;

	if (hasWorkOSErrors) {
		tlog.error(error, {
			errorDescription,
		});
	}

	return {
		error,
		description: errorDescription,
		hasError: !!hasWorkOSErrors,
	};
}
