import { ReactNode } from 'react';

export const symbol_slow_motion_video: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m418-332 202-129q11-7 11-19t-11-19L418-628q-11-8-23-1.5T383-609v258q0 14 12 20.5t23-1.5ZM112-450q14 0 23.5 8.5T148-419q6 30 17 59t28 55q8 12 7 25t-11 23q-10 9-22.5 8.5T146-260q-24-35-39.5-75T85-417q-2-13 6-23t21-10Zm77-274q10 10 11.5 23.5T194-675q-17 26-28.5 55T148-561q-3 13-12.5 22t-23.5 9q-13 0-21.5-9.5T84-562q6-42 21.5-82.5T146-720q8-11 20.5-12t22.5 8Zm59 528q10-11 24-12.5t27 6.5q26 15 54.5 27t57.5 20q13 3 21.5 13t8.5 23q0 12-10 19.5T409-94q-42-8-81-22.5T254-153q-11-7-13-20t7-23Zm196-665q0 13-8.5 23T414-825q-30 7-58 18.5T302-779q-13 8-27.5 7T249-784q-10-10-8-23t13-21q36-23 76-36.5t83-21.5q12-2 21.5 5.5T444-861Zm376 371q0-122-76.5-216T549-826q-11-2-18-12.5t-7-22.5q0-12 9-20t21-6q143 25 234.5 137T880-490q0 148-91.5 259.5T554-93q-12 2-21-6t-9-20q0-12 7-22.5t18-12.5q118-26 194.5-120T820-490Z"
		/>
	</svg>
);
