import { ReactNode } from 'react';

export const symbol_flaky: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-80q-85 0-158-30.5T195-195q-54-54-84.5-127T80-480q0-84 30.5-157T195-764q54-54 127-85t158-31q84 0 157 31t127 85q54 54 85 127t31 157q0 85-31 158t-85 127q-54 54-127 84.5T480-80ZM350-575l38 39q8 8 18 8t18-8q8-8 8-18t-8-18l-39-38 39-38q8-8 8-18t-8-18q-8-8-18-8t-18 8l-38 39-38-39q-8-8-18-8t-18 8q-8 8-8 18t8 18l39 38-39 38q-8 8-8 18t8 18q8 8 18 8t18-8l38-39Zm130 435q145 0 242.5-97.5T820-480q0-72-26-134t-72-108L238-238q46 46 108 72t134 26Zm82-194 83-82q8-8 17-7.5t17 8.5q8 8 8 17.5t-8 17.5l-96 96q-9 9-21 9t-21-9l-51-51q-8-8-8-17.5t8-17.5q8-8 18-8t18 8l36 36Z"
		/>
	</svg>
);
