import { Map, Record, fromJS } from 'immutable';

import { ADD_MATCH_VIDEOS, REMOVE_MATCH_VIDEO } from 'pkg/actions/action-types';

const initialState = new Record(
	{
		entities: new Map(),
	},
	'match_videos'
);

const addMatchVideoReducer = (state, action) =>
	state.mergeIn(['entities'], fromJS(action.payload));

const removeMatchVideoReducer = (state, action) => {
	const { matchId, videoId } = action;

	return state.deleteIn(['entities', `m${matchId}v${videoId}`]);
};

const matchVideos = (state = initialState(), action = {}) => {
	switch (action.type) {
		case ADD_MATCH_VIDEOS:
			return addMatchVideoReducer(state, action);

		case REMOVE_MATCH_VIDEO:
			return removeMatchVideoReducer(state, action);

		default:
			return state;
	}
};

export default matchVideos;
