import { Record } from 'pkg/api/models/record';
import { Dateable } from 'pkg/api/models/dateable';
import { useCollection } from 'pkg/api/use_collection';
import * as endpoints from 'pkg/api/endpoints/auto';

export enum ConnectionStatus {
	Unknown = 'unknown',
	Pending = 'pending',
	Active = 'active',
	Deactivated = 'deactivated',
}

export enum ExternalServiceProvider {
	Wingfield = 'wingfield',
}

export interface ExternalService extends Record, Dateable {
	externalService: ExternalServiceProvider;
	externalData?: string;
	connectionStatus: ConnectionStatus;
}

interface ExternalServiceConnectionOptions {
	userId: number;
	serviceProvider: ExternalServiceProvider;
	connectionStatus?: ConnectionStatus;
}

interface ExternalServiceConnectionHook {
	isLoading: boolean;
	records: ExternalService[];
	fetchConnections: () => Promise<void>;
}

export function useExternalServiceConnections({
	userId,
	serviceProvider,
	connectionStatus,
}: ExternalServiceConnectionOptions): ExternalServiceConnectionHook {
	const { isLoading, records, refresh } = useCollection<ExternalService>(
		userId ? endpoints.Users.ListExternalConnections(userId) : null
	);

	let filteredRecords = records.filter(
		(record: ExternalService) => record.externalService === serviceProvider
	);

	if (connectionStatus) {
		filteredRecords = filteredRecords.filter(
			(record: ExternalService) => record.connectionStatus === connectionStatus
		);
	}

	return {
		isLoading,
		records: filteredRecords,
		fetchConnections: refresh,
	};
}
