import { ReactNode } from 'react';

export const symbol_airline_seat_legroom_extra: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M490-280H140q-24.75 0-42.375-17.625T80-340v-470q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-810v470h350q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T490-280Zm116-130H340q-45 0-77.5-32.5T230-520v-290q0-12.75 8.625-21.375T260-840h160q12.75 0 21.375 8.625T450-810v220h130q20.361 0 37.18 11Q634-568 643-550l146 300 58-26q19-8 38.5-3t30.5 25q11 19 3.775 38.932Q912.55-195.136 893-186l-119 54q-11 5-23 1t-17-15L606-410Z"
		/>
	</svg>
);
