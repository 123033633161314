import { ReactNode } from 'react';

export const symbol_sticky_note_2: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-180h400v-170q0-12.75 8.625-21.375T610-380h170v-400H180v600Zm0 60q-24.75 0-42.375-17.625T120-180v-600q0-24.75 17.625-42.375T180-840h600q24.75 0 42.375 17.625T840-780v395q0 12.444-5 23.722T822-342L618-138q-8 8-19.278 13-11.278 5-23.722 5H180Zm260-300H330q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T330-480h110q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T440-420Zm190-160H330q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T330-640h300q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T630-580ZM180-180v-600 600Z"
		/>
	</svg>
);
