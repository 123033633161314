import { ReactNode } from 'react';

export const symbol_pool: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-162q-38 0-66.5 21T343-120q-42 0-71-21t-67-21q-28 0-49 12.5T112-128q-11 5-21.5-2.5T80-152q0-14 9-24.5t22-15.5q21-9 40-20t54-11q46 0 75 21.5t67 21.5q38 0 62.5-21.5T480-223q46 0 75 21.5t67 21.5q38 0 63-21.5t71-21.5q35 0 54 11t39 20q13 5 22 15.5t9 24.5q0 14-10.5 21.5T848-128q-23-9-43-21.5T756-162q-38 0-65 21t-69 21q-42 0-73-21t-69-21Zm0-188q-38 0-64.5 21T347-308q-42 0-73-21t-69-21q-29 0-50.5 13T110-315q-11 5-20.5-3T80-338q0-13 9-22.5t21-14.5q21-8 40.5-19.5T205-406q46 0 73 19t65 19q38 0 64.5-19t72.5-19q46 0 73 19t65 19q38 0 65-19t73-19q35 0 54.5 11.5T851-375q12 5 20.5 14.5T880-338q0 12-9 20t-20 3q-23-9-44.5-22T756-350q-38 0-65 21t-69 21q-42 0-73-21t-69-21ZM288-516l135-135-54-54q-27-27-54-39t-64-16q-17-2-28.5-13.5T211-802q0-17 11.5-28t28.5-9q54 5 93 23t76 55l253 253q-11 9-25.5 13t-29.5 4q-38 0-65.5-21T480-533q-45 0-72 21t-65 21q-18 0-32.5-7T288-516Zm380-321q40 0 67.5 27.5T763-742q0 40-27.5 67.5T668-647q-40 0-67.5-27.5T573-742q0-40 27.5-67.5T668-837Z"
		/>
	</svg>
);
