import { Fragment, useEffect, useState, MouseEvent } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import * as routes from 'pkg/router/routes';
import { pushState } from 'pkg/router/state';
import { translatedSexString } from 'pkg/api/models/user';
import { useToggleBoolean } from 'pkg/hooks/useToggleBoolean';
import { useCurrentMembership, useCurrentOrganization } from 'pkg/identity';

import TreeActions from 'containers/group/settings/organization/tree/Actions';

import Badge from 'components/Badge';
import Label from 'components/label';

import SelectedStatus from 'components/form/SelectedStatus';
import Row from 'components/layout/row';
import { AddUserModal } from 'components/group/add-users-modal';

import * as Table from 'design/table';

const Title = styled.div`
	display: flex;
	align-items: center;
`;

const BadgeWrapper = styled.div`
	width: 34px;
	height: 34px;
	margin-right: var(--spacing-4);
	flex: 0 0 auto;
`;

const GroupCode = styled.span`
	font-feature-settings: 'ss02' 1;
	letter-spacing: 2px;
`;

const GroupTitle = styled.span<{ selectable: boolean }>`
	color: ${(props) =>
		props.selectable ? styles.palette.blue[500] : styles.palette.black};
`;

interface TreeRowProps {
	group: models.group.Group;
	rootGroupId?: number;
	selectable?: boolean;
	selectedGroup?: models.group.Group;
	excludeGroupTree?: number | boolean;
	expandAll: boolean;
	onShowDialog: (type: string, group: models.group.Group) => void;
	onCreateSubGroup: (selectedGroup: models.group.Group) => void;
	onSelectGroup?: (group: models.group.Group) => void;
	showActions?: boolean;
	level?: number;
	refresh: () => void;
	removeRecord: (recordId: number) => void;
}
export default function TreeRow({
	group,
	rootGroupId,
	selectable,
	selectedGroup,
	excludeGroupTree,
	expandAll,
	onShowDialog,
	onCreateSubGroup,
	onSelectGroup,
	showActions,
	level = 0,
	refresh,
	removeRecord,
}: TreeRowProps): JSX.Element {
	const isRoot = rootGroupId === group.id;
	const org = useCurrentOrganization();

	const [isExpanded, setIsExpanded] = useState(false);
	const [addUserModal, toggleAddUserModal] = useToggleBoolean();

	useEffect(() => {
		setIsExpanded(isRoot ? true : expandAll);
	}, [expandAll]);

	const totalAmountOfUsers = group.totalPlayerCount + group.totalAdminCount;
	const membership = useCurrentMembership();

	const handleSelectedGroup = (e: MouseEvent<HTMLElement>) => {
		e.stopPropagation();

		onSelectGroup(group);
	};

	const handleTraverse = async () => {
		setIsExpanded((prev) => !prev);
	};

	const handleNavigation = (): void => {
		if (
			models.membership.hasCapability(
				membership,
				models.membership.Capabilities.PaymentAdmin
			)
		) {
			pushState(routes.Management.SingleOrganization(org.id, group.id));
		}
		return;
	};

	if (excludeGroupTree === group.id) {
		return null;
	}

	return (
		<Fragment>
			<Table.ExpandableRow
				key={group.id}
				canExpand={
					models.group.canShowChildren(group) || group.children?.length > 0
				}
				isExpanded={isExpanded}
				level={level}
				actions={
					showActions ? (
						<TreeActions
							group={group}
							isRoot={isRoot}
							onShowDialog={onShowDialog}
							onCreateSubGroup={onCreateSubGroup}
							toggleAddUserModal={toggleAddUserModal}
							removeRecord={removeRecord}
						/>
					) : selectable ? (
						<SelectedStatus
							onClick={handleSelectedGroup}
							isSelected={group.id === selectedGroup?.id}
						/>
					) : null
				}
				onClick={handleTraverse}>
				<Table.Cell onClick={handleNavigation}>
					<Title>
						<BadgeWrapper>
							<Badge badgeUrl={group.profileImageUrl} />
						</BadgeWrapper>
						<GroupTitle
							selectable={models.membership.hasCapability(
								membership,
								models.membership.Capabilities.PaymentAdmin
							)}>
							{group.name}
						</GroupTitle>
					</Title>
				</Table.Cell>

				<Table.Cell onClick={handleTraverse}>
					<GroupCode>{group.inviteCode}</GroupCode>
				</Table.Cell>
				<Table.Cell onClick={handleTraverse}>
					{group.totalChildGroupCount}
				</Table.Cell>
				<Table.Cell onClick={handleTraverse}>{totalAmountOfUsers}</Table.Cell>
				<Table.Cell onClick={handleTraverse}>
					{group.totalPlayerCount}
				</Table.Cell>
				<Table.Cell onClick={handleTraverse}>
					<Row spacing={styles.spacing._3} align="center">
						{group.totalAdminCount}
						{group.totalPendingMembersCount > 0 && (
							<Label color="orange">
								{t('{num} pending requests', {
									num: group.totalPendingMembersCount,
								})}
							</Label>
						)}
					</Row>
				</Table.Cell>
				<Table.Cell>{translatedSexString(group.sex)}</Table.Cell>
				<Table.Cell>
					{group.birthYearFrom !== group.birthYearTo
						? group.birthYearFrom + ' - ' + group.birthYearTo
						: group.birthYearFrom}
				</Table.Cell>
			</Table.ExpandableRow>

			{isExpanded && group.children?.length > 0
				? group.children?.map((child: models.group.Group) => (
						<TreeRow
							showActions={showActions}
							key={child.id}
							group={child}
							level={level + 1}
							selectable={selectable}
							selectedGroup={selectedGroup}
							excludeGroupTree={excludeGroupTree}
							expandAll={expandAll}
							onShowDialog={onShowDialog}
							onCreateSubGroup={onCreateSubGroup}
							onSelectGroup={onSelectGroup}
							refresh={refresh}
							removeRecord={removeRecord}
						/>
					))
				: null}
			{addUserModal && (
				<AddUserModal onClose={toggleAddUserModal} groupId={group.id} />
			)}
		</Fragment>
	);
}
