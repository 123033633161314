import { ReactNode } from 'react';

export const symbol_nest_cam_indoor: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-500q-42 0-71-29t-29-71q0-42 29-71t71-29q42 0 71 29t29 71q0 42-29 71t-71 29ZM280-80q0-25 17.625-42.5T340-140h110q0-69-33-128.5T326-366q-60-39-93-101t-33-133q0-117 81.5-198.5T480-880q117 0 198.5 81.5T760-600q0 72-34 134t-94 101q-57 37-89.5 96.368Q510-209.263 510-140h110q24.75 0 42.375 17.625T680-80H280Zm200-300q92 0 156-64t64-156q0-92-64-156t-156-64q-92 0-156 64t-64 156q0 92 64 156t156 64Z"
		/>
	</svg>
);
