import * as Sentry from '@sentry/react';

import { isDev } from 'pkg/flags';

let sentryEnabled = false;
const sentryConfig: Sentry.BrowserOptions = {
	dsn: window.TS.sentryDsn,
	integrations: [
		Sentry.captureConsoleIntegration({
			levels: isDev()
				? ['log', 'info', 'warn', 'error', 'assert']
				: ['error', 'warn'],
		}),
	],
};

// inits Sentry logger
function init() {
	if (window.TS.version) {
		sentryConfig.release = window.TS.version;
	}

	if (window.TS.sentryDsn) {
		sentryEnabled = true;
		Sentry.init(sentryConfig);
	}
}

interface ErrorInfo {
	[key: string]: unknown;
}

function captureMessage(
	msg: string | Error,
	level: 'fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug',
	data?: ErrorInfo
) {
	// only send errors to Sentry
	if (sentryEnabled && ['fatal', 'error', 'warning'].includes(level)) {
		Sentry.withScope((s) => {
			if (data) {
				s.setExtras(data);
			}
			if (msg instanceof Error) {
				Sentry.captureException(msg);
			} else {
				Sentry.captureMessage(msg, level);
			}
		});

		return;
	}

	if (!isDev()) {
		return;
	}

	switch (level) {
		case 'debug':
			console.debug(msg, data);
			break;
		case 'warning':
			console.warn(msg, data);
			break;
		case 'error':
			console.error(msg, data);
			break;
		default:
			console.info(msg, data);
			break;
	}
}

function setUserContext(identity: string) {
	if (sentryEnabled) {
		Sentry.setUser({
			id: identity,
		});
	}
}

function debug(msg: string, data?: ErrorInfo) {
	captureMessage(msg, 'debug', data);
}

function message(msg: string, data?: ErrorInfo) {
	captureMessage(msg, 'info', data);
}

function warning(msg: string, data?: ErrorInfo) {
	captureMessage(msg, 'warning', data);
}

function error(msg: string, data?: ErrorInfo) {
	captureMessage(msg, 'error', data);
}

function exception(e: Error, data?: ErrorInfo) {
	captureMessage(e, 'error', data);
}

// this is named "tlog" to avoid strange auto import issues in VS Code when
// it's named "logger".
const tlog = {
	init,
	setUserContext,
	debug,
	message,
	warning,
	error,
	exception,
};

export { tlog };
