import { t } from '@transifex/native';
import styled from 'styled-components';
import { useEffect, useState, useRef } from 'react';

import * as styles from 'pkg/config/styles';

import Icon from 'components/icon';

import RangeSlider from 'components/form/RangeSlider';

import * as ContextMenu from 'design/context_menu';

const p = styles.palette;
const defaultColors = [
	p.white + '00',
	p.red[600] + '44',
	p.yellow[600] + '44',
	p.orange[500] + '44',
	p.green[500] + '44',
	p.blue[500] + '44',
	p.purple[500] + '44',
	'#AE29C3' + '44',

	p.white,
	p.red[600] + '88',
	p.yellow[600] + '88',
	p.orange[500] + '88',
	p.green[500] + '88',
	p.blue[500] + '88',
	p.purple[500] + '88',
	'#AE29C3' + '88',

	p.gray[400],
	p.red[600],
	p.yellow[600],
	p.orange[500],
	p.green[500],
	p.blue[500],
	p.purple[500],
	'#AE29C3',

	p.black,
	p.red[800],
	p.yellow[800],
	p.orange[700],
	p.green[600],
	p.blue[700],
	p.purple[800],
	'#7B1186',
];
const defaultColorsSolid = [
	p.white,
	p.red[300],
	p.yellow[300],
	p.orange[200],
	p.green[300],
	p.blue[300],
	p.purple[300],
	'#f13ae2',

	p.gray[400],
	p.red[600],
	p.yellow[600],
	p.orange[500],
	p.green[500],
	p.blue[500],
	p.purple[500],
	'#AE29C3',

	p.black,
	p.red[800],
	p.yellow[800],
	p.orange[700],
	p.green[600],
	p.blue[700],
	p.purple[800],
	'#7B1186',
];

export default function ColorPicker(props: any) {
	return props.inline ? (
		<Palette {...props} />
	) : (
		<ContextMenu.Menu width="254px" toggleWith={<Preview hex={props.value} />}>
			<ContextMenu.Pane>
				<Palette {...props} />
			</ContextMenu.Pane>
		</ContextMenu.Menu>
	);
}

const Palette = ({ value, onChange, opacity: opacityEnabled }: any) => {
	const [color, setColor] = useState(styles.palette.white);
	const [opacity, setOpacity] = useState(null);

	useEffect(() => {
		const valueColor = value.substring(0, 7);
		if (valueColor !== color) setColor(valueColor);

		const valueOpacity = parseInt(value.substring(7, 9) || 'FF', 16);
		if (valueOpacity !== opacity) setOpacity(valueOpacity);
	}, [value]);

	const handleColor = (e: any) => {
		setColor(e.target.value);
	};

	const handleOpacity = (value: number) => {
		setOpacity(value);
	};

	const opacityHex = opacity?.toString(16).toUpperCase() || 'FF';
	const hex = color + (opacityHex.length === 1 ? '0' : '') + opacityHex;
	useEffect(() => {
		if (color && opacity !== null && hex !== value) onChange(hex);
	}, [hex, value]);

	return (
		<div>
			<Preview hex={hex} color={color} handleColor={handleColor} />
			<Swatches>
				{(opacityEnabled ? defaultColors : defaultColorsSolid).map((color) => (
					<SingleColor
						key={color}
						style={{ background: color }}
						onClick={() => {
							setColor(color.substring(0, 7));
							setOpacity(parseInt(color.substring(7, 9) || 'FF', 16));
						}}>
						{hex === (color + 'FF').substring(0, 9) && (
							<Icon name="check" size={1.6} />
						)}
						{color === '#FFFFFF00' && <TransparentColor />}
					</SingleColor>
				))}
			</Swatches>
			{opacityEnabled && (
				<Opacity>
					<label>{t(`Opacity`)}</label>
					<RangeSlider
						type="range"
						min={0}
						max={255}
						step={1}
						value={opacity || 0}
						onChange={handleOpacity}
					/>
				</Opacity>
			)}
		</div>
	);
};

const Preview = ({ hex, color, handleColor }: any) => {
	const el = useRef<HTMLInputElement>();
	const [open, setOpen] = useState(false);

	const toggle = () => {
		if (!handleColor || !el.current) return;
		if (!open) el.current.click();
		setOpen(!open);
	};

	return (
		<PreviewOuter style={{ background: hex }} onClick={toggle}>
			{handleColor && color && (
				<input
					ref={el}
					type="color"
					value={color}
					onChange={handleColor}
					onFocus={focus}
					onBlur={blur}
				/>
			)}
		</PreviewOuter>
	);
};

const PreviewOuter = styled.div`
	padding: var(--spacing-1);
	box-shadow: 0px 0px 2px 0px #00000040;
	border: 2px solid var(--palette-white);
	border-radius: var(--radius-2);
	min-width: 30px;
	min-height: 18px;
	cursor: pointer;

	input {
		width: 100%;
		height: 22px;
		opacity: 0;
		pointer-events: none;
	}
`;

const Swatches = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: var(--spacing-2);
	margin-top: var(--spacing-4);
	color: var(--palette-gray-600);
`;

const SingleColor = styled.div`
	position: relative;
	display: grid;
	place-items: center;
	width: 24px;
	height: 24px;
	border-radius: var(--radius-3);
	outline: 1px solid #00000011;
	overflow: hidden;
`;

const TransparentColor = styled.div`
	position: absolute;
	height: 2px;
	background: var(--palette-red-400);
	width: 150%;
	rotate: -45deg;
`;

const Opacity = styled.div`
	margin-top: var(--spacing-3);
	display: flex;
	align-items: center;

	label {
		font-size: var(--font-size-sm);
		font-weight: var(--font-weight-bold);
		margin-right: 10px;
	}
`;
