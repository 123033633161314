import React, { Fragment } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import PositionScore from 'pkg/models/position_score';
import User from 'pkg/models/user';
import Rating from 'pkg/models/rating';

import DateTime from 'pkg/datetime';
import { useCurrentGroup } from 'pkg/identity';

import CardAnatomy, * as Card from 'components/Card';

const CardBody = styled(Card.Body)`
	@media ${styles.breakpoint.small} {
		padding-bottom: var(--spacing-1);
	}
`;

const StatsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	padding: 0 var(--spacing-5);

	@media ${styles.breakpoint.small} {
		padding: 0;
		flex-wrap: wrap;
	}
`;

const Birthday = styled.span`
	font-weight: var(--font-weight-normal);
`;

const Stat = styled.div`
	font-weight: var(--font-weight-semibold);
	margin-right: var(--spacing-8);

	@media ${styles.breakpoint.small} {
		margin-right: 0;
		margin-bottom: var(--spacing-4);
		padding: 0;
		flex: 0 0 50%;
	}

	div:first-child {
		color: var(--palette-gray-500);
		margin-bottom: var(--spacing-2);
		font-weight: var(--font-weight-normal);
	}
`;

type AboutProps = {
	user: User;
	rating: Rating;
};

const About: React.FC<React.PropsWithChildren<AboutProps>> = ({
	user,
	rating,
}) => {
	const group = useCurrentGroup();
	const sportHasRatingModel = group.sport?.hasRatingModel;
	let positionScore: PositionScore = new PositionScore();

	if (rating?.positionScores) {
		positionScore = rating.positionScores.find(
			(positionScore: PositionScore) =>
				positionScore.positionId === rating.suggestedPositionId
		);
	}

	const { birthDate } = user;

	const formatter = new Intl.NumberFormat(DateTime.getLocale(), {
		style: 'unit',
		unit: 'centimeter',
	});

	let foot = '-';

	if (rating?.primaryFoot === 'right') {
		foot = t('Right foot');
	} else if (rating?.primaryFoot === 'left') {
		foot = t('Left foot');
	}

	return (
		<CardAnatomy $noBorder>
			<CardBody>
				<StatsWrapper>
					{sportHasRatingModel && (
						<Fragment>
							<Stat>
								<div>{t('Position')}</div>
								<div>{positionScore?.position.name || '-'}</div>
							</Stat>
							<Stat>
								<div>{t('Foot')}</div>
								<div>{foot}</div>
							</Stat>
						</Fragment>
					)}
					<Stat>
						<div>{t('Height')}</div>
						<div>
							{user.meta.get('height')
								? formatter.format(user.meta.get('height'))
								: '-'}
						</div>
					</Stat>
					<Stat>
						<div>{t('Age')}</div>
						<div>
							{user.age || '-'}
							{birthDate && <Birthday> ({birthDate})</Birthday>}
						</div>
					</Stat>
				</StatsWrapper>
			</CardBody>
		</CardAnatomy>
	);
};

export default About;
