import { ReactNode } from 'react';

export const symbol_autorenew: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M220-478q0 20 2.5 39t7.5 36q3 12-1.5 24T213-362q-11 5-22.5 0T175-378q-8-25-11.5-49.5T160-478q0-131 94.5-225.5T480-798h43l-61-61q-8-8-8-19t8-19q8-8 19.5-8t19.5 8l109 108q9 9 9 21t-9 21L502-639q-8 8-20 8t-20-8q-8-8-8-20t8-20l59-59h-41q-107 0-183.5 76.5T220-478Zm520-1q0-20-2.5-39t-7.5-37q-4-12 .5-24t15.5-17q11-5 22 0t15 16q9 25 13 50t4 51q0 131-94.5 225.5T480-159h-45l61 61q8 8 8 19t-8 19q-8 8-19.5 8T457-60L348-168q-9-9-9-21t9-21l109-109q8-8 19.5-8t19.5 8q8 8 8 19.5t-8 19.5l-61 61h45q107 0 183.5-76.5T740-479Z"
		/>
	</svg>
);
