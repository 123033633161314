import { ReactNode } from 'react';

export const symbol_line_start_arrow_notch: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m367-450 119 195q6 11-2.5 19t-18.5 1L120-455q-14-9-14-25t14-25l345-220q10-7 18.5 1t2.5 19L367-510h473q13 0 21.5 8.5T870-480q0 13-8.5 21.5T840-450H367Z"
		/>
	</svg>
);
