import { ReactNode } from 'react';

export const symbol_bungalow: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M310-120q-12.75 0-21.375-8.625T280-150v-256l-45 72q-7 11-18.5 14t-22.389-3.84q-10.889-6.84-14-19Q177-355 184-366l271-433q5-7 11-10.5t14-3.5q8 0 14 3.5t11 10.5l271 433q7 11 3.909 23.098Q776.818-330.805 766-324q-11 6-22.5 3.5T725-334l-45-72v256q0 12.75-8.625 21.375T650-120H310Zm30-60h110v-90q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-270v90h110v-322L480-726 340-502v322Zm0 0h280-280Zm139.825-230Q467-410 458.5-418.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Z"
		/>
	</svg>
);
