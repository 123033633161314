import { ReactNode } from 'react';

export const symbol_feature_search: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M137-80q-24 0-42-18t-18-42v-590q0-24 18-42t42-18h221q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T358-730H137v590h590v-241q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T787-381v241q0 24-18 42t-42 18H137Zm627-526 111 111q9 9 9 21t-9 21q-9 9-21.5 9t-21.5-9L720-564q-21 16-46.5 24t-52.5 8q-72.5 0-123.25-50.75T447-706q0-72.5 50.75-123.25T621-880q72.5 0 123.25 50.75T795-706q0 26-8 52t-23 48Zm-142.915 14Q669-592 702-625.084q33-33.085 33-81.001Q735-754 701.916-787q-33.085-33-81.001-33Q573-820 540-786.916q-33 33.085-33 81.001Q507-658 540.084-625q33.085 33 81.001 33ZM137-441v301-590 258-8 39Z"
		/>
	</svg>
);
