import { ReactNode } from 'react';

export const symbol_attach_email: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M103-240q-25.987 0-44.494-17Q40-274 40-300v-520q0-24.75 17.625-42.375T100-880h680q24.75 0 42.375 17.625T840-820v210q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T780-610v-150L456-553q-4 2-7.5 3t-8.5 1q-5 0-8.5-1t-7.5-3L100-760v460h470q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T570-240H103Zm337-367 340-213H100l340 213ZM100-760v7-43.623.754V-820v24.131-.754V-753v-7 460-460ZM792-80q-56 0-94-39.95T660-216v-224q0-33 23.49-56.5T741-520q33.18 0 56.09 23.5Q820-473 820-440v200q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T760-240v-203q0-11.2-4.5-19.6Q751-471 741-471t-15.5 8.4q-5.5 8.4-5.5 19.6v229q0 33 20 58t52 25q30 0 49-23.5t19-55.5v-167q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T920-377v167q0 53.625-37 91.812Q846-80 792-80Z"
		/>
	</svg>
);
