import { ReactNode } from 'react';

export const symbol_iron: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-300h480v-160H240q-42 0-71 29t-29 71v60Zm480 0v-160 160Zm230-420q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-660q-21.25 0-35.625 14.375T800-610v170q0 48-35.5 79T680-330v60q0 12.75-8.625 21.375T650-240H110q-12.75 0-21.375-8.625T80-270v-90q0-66 47-113t113-47h380v-49.825Q620-591 605.625-605.5 591.25-620 570-620H391q-11 0-19.5 3.5T356-606q-5 5-10 7.5t-12 2.5q-13.175 0-22.087-8.912Q303-613.825 303-627q0-7 2.5-12t7.5-10q15-14 34.603-22.5T390-680h180q45.833 0 77.917 32.083Q680-615.833 680-570v180q23 0 41.5-13.5T740-440v-170q0-45.833 32.083-77.917Q804.167-720 850-720Z"
		/>
	</svg>
);
