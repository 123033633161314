import { createSelector } from 'reselect';

import Position from 'pkg/models/position';

const emptyPosition = new Position();

export const getPositions = (state) => state.positions.entities;

const POSITION_SECTIONS = {
	gk: 'goalKeeper',
	cb: 'defence',
	lb: 'defence',
	rb: 'defence',
	cdm: 'midfield',
	cam: 'midfield',
	cm: 'midfield',
	lm: 'midfield',
	rm: 'midfield',
	cf: 'forward',
	lw: 'forward',
	rw: 'forward',
	st: 'forward',
};

export const getPositionsBySection = createSelector(getPositions, (positions) =>
	positions.reduce(
		(sections, position) => {
			const section = POSITION_SECTIONS[position.slug];
			sections[section].push(position);
			return sections;
		},
		{
			forward: [],
			midfield: [],
			defence: [],
			goalKeeper: [],
		}
	)
);

export const getCurrentPositionId = (state) =>
	getPositions(state).find(({ id }) => id === state.ratings.currentPosition) ||
	emptyPosition;
