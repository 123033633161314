import { ReactNode } from 'react';

export const symbol_warning_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M483.825-237Q471-237 462.5-245.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5ZM454-378v-128l60 60v68q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T454-378Zm26.025-472q7.975 0 14.475 3.5T506-835l293 507q8 13 3.5 24T788-288q-10 5-21.5 4T747-299L480-760l-52 90q-8 13-19.5 14t-21.5-4q-10-5-14.5-16t3.5-24l78-135q5-8 11.525-11.5t14.5-3.5ZM144-180h550L346-528 144-180ZM816-59l-62-61H92q-17 0-26-15t0-30l236-407L58-816q-9-9.067-8.5-21.533Q50-850 59.053-859q9.052-9 21.5-9Q93-868 102-859l757 758q9 9 9 21t-9.053 21q-9.052 9-21.5 9Q825-50 816-59ZM520-354Zm58-113Z"
		/>
	</svg>
);
