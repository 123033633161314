import { ReactNode } from 'react';

export const symbol_inbox_customize: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-160q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v194q0 12.641-8.675 20.821-8.676 8.179-21.5 8.179-12.825 0-21.325-8.625T780-627v-193H180v404h147q16 0 26 3t15.625 14.077Q381-378 400.5-362.5 420-347 443-339q9 3 15 10.692 6 7.693 6 16.923Q464-296 452-286q-12 10-27 5-30-9-55.5-28T326-356H180v136h254q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T434-160H180Zm0-60h284-284Zm512 52q-23-7-42-18.5T617-211l-30.1 13.867Q582-195 576.5-196t-8.875-5.846l-20.25-32.308Q544-239 545-244t6-9l27-22q-5-19.2-5-41.6 0-22.4 5-41.4l-27-22q-5-4-6-9t2.375-9.962l20.25-33.076Q571-437 576.5-438t10.4 1.133L617-423q14-12 33-23.5t42-18.5l4.091-36.235Q697-507 701-510.5q4-3.5 9.625-3.5h33.75q5.625 0 9.625 3.5t4.909 9.265L763-465q23 7 42 18.5t33 23.5l30.1-13.867Q873-439 878.5-438t8.875 5.962l20.25 33.076Q911-394 910-389t-6 9l-27 22q5 19.2 5 41.6 0 22.4-5 41.4l27 22q5 4 6 9.25t-2 9.75l-20.571 32.174Q884-197 878.5-196t-10.4-1.133L838-211q-14 13-33 24.5T763-168l-4.091 35.373Q758-127 754-123.5q-4 3.5-9.625 3.5h-33.75Q705-120 701-123.5t-4.909-9.127L692-168Zm35-47q44 0 73-29t29-73q0-44-29-73t-73-29q-44 0-73 29t-29 73q0 44 29 73t73 29Z"
		/>
	</svg>
);
