import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as sdk from 'pkg/core/sdk';

export async function update(
	groupId: number,
	payload: models.groupContactInformation.GroupContactInformation
): Promise<
	[
		Response,
		models.groupContactInformation.GroupContactInformation,
		models.APIError?,
	]
> {
	if (groupId <= 0 || payload.length === 0) {
		return [new Response(null, { status: 400 }), null, null];
	}

	const endpoint = endpoints.GroupInfo.ShowByGroupID(groupId);
	const request: Response = await sdk.put(endpoint, {}, payload);

	if (!request.ok) {
		const response: models.APIError = await request.json();

		return [request, null, response];
	}

	const response: models.groupContactInformation.GroupContactInformation =
		await request.json();

	return [request, response, null];
}
