import { ReactNode } from 'react';

export const symbol_bedroom_child: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M288-345h384v38q0 10.833 7.116 17.917 7.117 7.083 18 7.083Q708-282 715-289.083q7-7.084 7-17.917v-139q0-29-18-51t-46-27v-72q0-29-20-49t-49-20H371q-29 0-49 20t-20 49v72q-28 5-46 27t-18 51v139q0 10.833 7.116 17.917 7.117 7.083 18 7.083Q274-282 281-289.083q7-7.084 7-17.917v-38Zm0-50v-51q0-14 9-23t23-9h320q14 0 23 9t9 23v51H288Zm64-132v-88h256v88H352ZM140-80q-24 0-42-18t-18-42v-680q0-24 18-42t42-18h680q24 0 42 18t18 42v680q0 24-18 42t-42 18H140Zm0-60h680v-680H140v680Zm0 0v-680 680Z"
		/>
	</svg>
);
