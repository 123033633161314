import { ReactNode } from 'react';

export const symbol_search_check: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m357-572 79-79q9-9 21-9t21 9q9 9 9 21t-9 21l-99 100q-9 9-21 9t-21-9l-55-54q-9-8-9-20.5t9-21.5q9-9 21-9t21 9l33 33Zm21 243q-108.162 0-183.081-75Q120-479 120-585t75-181q75-75 181.5-75t181 75Q632-691 632-584.85 632-542 618-502q-14 40-42 75l242 240q9 8.556 9 21.778T818-143q-9 9-22.222 9-13.222 0-21.778-9L533-384q-30 26-69.959 40.5T378-329Zm-1-60q81.25 0 138.125-57.5T572-585q0-81-56.875-138.5T377-781q-82.083 0-139.542 57.5Q180-666 180-585t57.458 138.5Q294.917-389 377-389Z"
		/>
	</svg>
);
