const icon = {
	name: 'collapse',
	objects: [
		{
			type: 'path',
			attributes: {
				d: 'M15.0286 15.0978L11.7099 11.8891L15.0286 8.6805L15.9684 9.58915L13.5895 11.8891L15.9684 14.1891L15.0286 15.0978Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M19.2427 6.18213C19.2427 5.07756 18.3473 4.18213 17.2427 4.18213H7C5.89543 4.18213 5 5.07756 5 6.18213V17.2319C5 18.3365 5.89543 19.2319 7 19.2319H17.2427C18.3473 19.2319 19.2427 18.3365 19.2427 17.2319V6.18213ZM17.9427 17.2319V6.18213C17.9427 5.79553 17.6293 5.48213 17.2427 5.48213L10.4276 5.48213V17.9319H17.2427C17.6293 17.9319 17.9427 17.6185 17.9427 17.2319ZM9.07761 17.9319H7C6.6134 17.9319 6.3 17.6185 6.3 17.2319L6.3 6.18213C6.3 5.79553 6.6134 5.48213 7 5.48213H9.07761V17.9319Z',
			},
		},
	],
};

export default icon;
