import { ReactNode } from 'react';

export const symbol_arrow_drop_down: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M464-376 306-533q-2-2-3.5-4.763t-1.5-5.921q0-6.316 4.125-10.816Q309.25-559 316-559h328q6.75 0 10.875 4.636Q659-549.727 659-543.545 659-542 654-533L496-376q-3 3-7.062 5-4.063 2-8.938 2-4.875 0-8.938-2-4.062-2-7.062-5Z"
		/>
	</svg>
);
