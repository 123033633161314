import { ReactNode } from 'react';

export const symbol_select_window: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-80q-24 0-42-18t-18-42v-387q0-24 18-42t42-18h115v-233q0-24 18-42t42-18h505q24 0 42 18t18 42v387q0 24-18 42t-42 18H706v233q0 24-18 42t-42 18H140Zm0-60h506v-327H140v327Zm566-293h114v-327H315v173h317q32 0 53 21t21 53v80Z"
		/>
	</svg>
);
