import { ReactNode } from 'react';

export const symbol_box: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-674v494h600v-494H640v293q0 17.5-14 26.25t-29 .75l-117-58-117 58q-15 8-29-.75T320-381v-293H180Zm0 554q-24.75 0-42.375-17.625T120-180v-529q0-9.882 3-19.059 3-9.176 9-16.941l52-71q8-11 20.941-17.5Q217.882-840 232-840h495q14.118 0 27.059 6.5T775-816l53 71q6 7.765 9 16.941 3 9.177 3 19.059v529q0 24.75-17.625 42.375T780-120H180Zm17-614h565l-36.409-46H233l-36 46Zm183 60v245l100-50 100 50v-245H380Zm-200 0h600-600Z"
		/>
	</svg>
);
