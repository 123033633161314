import { ReactNode } from 'react';

export const symbol_rheumatology: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M411-249Zm332-86q-11.613-3.714-17.806-14.857Q719-361 722.636-371.781 727-384 738-390q11-6 23-2l138 47q11.871 3.824 17.435 14.912Q922-319 918-307q-4 12-15 17.5t-22 1.5l-138-47Zm7-115q-12 0-21-9t-9-21.5q0-12.5 9-21t21-8.5h140q12.75 0 21.375 8.625T920-480q0 12-8.625 21T890-450H750Zm13-119q-12 4-23.571-1.367Q727.857-575.733 724-588q-4-12 1.806-23.088Q731.613-622.176 744-626l137-46q11-4 22.088 1.806Q914.176-664.387 918-652q4 11-1.367 22.143Q911.267-618.714 899-615l-136 46ZM285-400q-52 0-88.5-36.5T160-525q0-26 10-48.5t27-39.5l83-84v-153q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T340-850v153q0 12.174-4.5 23.587T323-654l-84 83q-8.769 8.939-13.885 20.857Q220-538.224 220-524.816 220-498 239.421-479q19.421 19 45.316 19 17.263 0 27.763-6t23.5-17q14-11 32.5-21t41.5-10q17 0 35.5 7t39.5 24q13 11 22.956 17 9.955 6 27.022 6Q562-460 581-479t19-46.333q0-13.667-4.846-25.863Q590.308-563.391 582-572l-84-83q-8-8-13-19.5t-5-23.5v-152q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T540-850v152l84 84q17 17 26.5 40t9.5 49q0 52-36 88.5T535-400q-33 0-53.5-12.5T447-437q-15-12-23.5-14.5T410-454q-9 0-18.5 6T374-437q-15 12-35.227 24.5Q318.547-400 285-400Zm255 146v144q0 12.75-8.675 21.375Q522.649-80 509.825-80 497-80 488.5-88.625T480-110v-144q0-12.174 5-23.587T498-297l84-83q8.308-8.634 13.154-20.146Q600-411.659 600-424.61q0-14.39-4.846-26.39T582-472l42-42q17 17 26.5 40t9.5 49q0 26-9.5 48T624-338l-84 84Zm-260 0-83-84q-17-17-27-39t-10-48q0-26 10-49t27-40l42 42q-9 10-14 21.429-5 11.428-5 25.714 0 12.857 5.5 24.357T239-380l84 83q8 8 12.5 19.413T340-254v144q0 12.75-8.675 21.375Q322.649-80 309.825-80 297-80 288.5-88.625T280-110v-144Zm130-401Z"
		/>
	</svg>
);
