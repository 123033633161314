const Connect = {
	name: 'connect',
	viewBox: '0 0 54 55',
	objects: [
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M14.7221 15.3349C14.7221 16.9714 13.3954 18.2981 11.7589 18.2981C10.1223 18.2981 8.79567 16.9714 8.79567 15.3349C8.79567 13.6984 10.1223 12.3717 11.7589 12.3717C13.3954 12.3717 14.7221 13.6984 14.7221 15.3349ZM13.3758 15.3349C13.3758 16.2279 12.6519 16.9518 11.7589 16.9518C10.8659 16.9518 10.142 16.2279 10.142 15.3349C10.142 14.4419 10.8659 13.718 11.7589 13.718C12.6519 13.718 13.3758 14.4419 13.3758 15.3349Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M18.9253 17.6099C18.7725 19.2563 18.0652 20.7419 16.992 21.8781C16.9929 21.9103 16.9934 21.9425 16.9934 21.9749H16.8989C15.5926 23.3074 13.7723 24.1341 11.7589 24.1341C9.74554 24.1341 7.92524 23.3074 6.61896 21.9749H6.52425C6.52425 21.9424 6.52474 21.9101 6.52571 21.878C5.30805 20.5888 4.56152 18.8499 4.56152 16.9367C4.56152 12.9616 7.78391 9.73926 11.7589 9.73926C15.507 9.73926 18.5859 12.6041 18.9253 16.2635H39.289C42.9401 16.2635 45.9 19.2234 45.9 22.8746C45.9 26.5257 42.9401 29.4856 39.289 29.4856H33.3224C33.0977 31.2572 31.5851 32.627 29.7525 32.627H28.313V25.4296H29.7525C31.4331 25.4296 32.8447 26.5817 33.2405 28.1392H39.289C42.1966 28.1392 44.5536 25.7822 44.5536 22.8746C44.5536 19.9669 42.1966 17.6099 39.289 17.6099H18.9253ZM11.7589 22.7877C13.2056 22.7877 14.5296 22.2627 15.5509 21.3928C15.3089 20.6848 14.6379 20.1757 13.8479 20.1757H9.66977C8.87984 20.1757 8.2088 20.6848 7.96685 21.3927C8.98813 22.2627 10.3122 22.7877 11.7589 22.7877ZM13.8479 18.8293C14.981 18.8293 15.9743 19.4285 16.528 20.3273C17.2093 19.3708 17.61 18.2005 17.61 16.9367C17.61 13.7052 14.9904 11.0856 11.7589 11.0856C8.52747 11.0856 5.90786 13.7052 5.90786 16.9367C5.90786 18.2005 6.30854 19.3707 6.98979 20.3272C7.54352 19.4285 8.53668 18.8293 9.66977 18.8293H13.8479ZM29.7525 26.776H29.6593V31.2807H29.7525C30.9964 31.2807 32.0048 30.2723 32.0048 29.0283C32.0048 27.7844 30.9964 26.776 29.7525 26.776Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M41.2682 42.0496C42.9048 42.0496 44.2314 40.7229 44.2314 39.0864C44.2314 37.4499 42.9048 36.1232 41.2682 36.1232C39.6317 36.1232 38.305 37.4499 38.305 39.0864C38.305 40.7229 39.6317 42.0496 41.2682 42.0496ZM41.2682 40.7033C42.1612 40.7033 42.8851 39.9794 42.8851 39.0864C42.8851 38.1934 42.1612 37.4695 41.2682 37.4695C40.3753 37.4695 39.6514 38.1934 39.6514 39.0864C39.6514 39.9794 40.3753 40.7033 41.2682 40.7033Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M36.0336 45.7263C36.0336 45.6939 36.0341 45.6616 36.0351 45.6294C34.8174 44.3402 34.0709 42.6013 34.0709 40.6881C34.0709 36.7131 37.2933 33.4907 41.2683 33.4907C45.2433 33.4907 48.4657 36.7131 48.4657 40.6881C48.4657 42.6014 47.7191 44.3404 46.5013 45.6296C46.5023 45.6617 46.5028 45.6939 46.5028 45.7263H46.4082C45.102 47.0588 43.2817 47.8855 41.2683 47.8855C39.2549 47.8855 37.4346 47.0588 36.1283 45.7263H36.0336ZM39.1791 42.5808C38.046 42.5808 37.0529 43.1799 36.4991 44.0786C35.8179 43.1221 35.4172 41.9519 35.4172 40.6881C35.4172 37.4566 38.0368 34.837 41.2683 34.837C44.4997 34.837 47.1193 37.4566 47.1193 40.6881C47.1193 41.9519 46.7186 43.1222 46.0373 44.0788C45.4836 43.1799 44.4904 42.5808 43.3572 42.5808H39.1791ZM41.2683 46.5391C42.7149 46.5391 44.0389 46.0141 45.0602 45.1443C44.8183 44.4362 44.1472 43.9271 43.3572 43.9271H39.1791C38.3892 43.9271 37.7182 44.4362 37.4762 45.1441C38.4975 46.0141 39.8216 46.5391 41.2683 46.5391Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M34.0709 40.015V40.6881V41.3613H13.3783C9.72717 41.3613 6.76731 38.4014 6.76731 34.7503C6.76731 31.0991 9.72717 28.1392 13.3783 28.1392H19.7867C20.1825 26.5817 21.5941 25.4296 23.2748 25.4296H24.7142V26.9157H26.1537C26.5255 26.9157 26.8269 27.2171 26.8269 27.5888C26.8269 27.9606 26.5255 28.262 26.1537 28.262H24.7142V29.7946H26.1537C26.5255 29.7946 26.8269 30.096 26.8269 30.4678C26.8269 30.8396 26.5255 31.141 26.1537 31.141H24.7142V32.627H23.2748C21.4421 32.627 19.9295 31.2572 19.7048 29.4856H13.3783C10.4707 29.4856 8.11365 31.8427 8.11365 34.7503C8.11365 37.6579 10.4707 40.015 13.3783 40.015H34.0709ZM23.3679 26.776H23.2748C22.0308 26.776 21.0224 27.7844 21.0224 29.0283C21.0224 30.2723 22.0308 31.2807 23.2748 31.2807H23.3679V26.776Z',
			},
		},
	],
};

export default Connect;
