import { ReactNode } from 'react';

export const symbol_punch_clock: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-80q-23 0-41.5-18.5T120-140v-520q0-23 18.5-41.5T180-720h70v-140q0-24.75 17.625-42.375T310-920h340q24.75 0 42.375 17.625T710-860v140h70q23 0 41.5 18.5T840-660v520q0 23-18.5 41.5T780-80H180Zm130-640h340v-140H310v140ZM180-140h600v-520H180v520Zm300.053-65Q561-205 618-262.053t57-138Q675-481 617.947-538t-138-57Q399-595 342-537.947t-57 138Q285-319 342.053-262t138 57Zm-.002-50Q420-255 377.5-297.449q-42.5-42.45-42.5-102.5Q335-460 377.449-502.5q42.45-42.5 102.5-42.5Q540-545 582.5-502.551q42.5 42.45 42.5 102.5Q625-340 582.551-297.5q-42.45 42.5-102.5 42.5ZM500-408v-72q0-8-6-14t-14-6q-8 0-14 6t-6 14v76q0 5.565 2 10.783Q464-388 469-383l43 43q6 6 14 6t14-6q6-6 6-14t-6-14l-40-40Zm-20 8Z"
		/>
	</svg>
);
