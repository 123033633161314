import { ReactNode } from 'react';

export const symbol_insert_chart: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-60h600v-600H180v600Zm0-600v600-600Zm133.825 228Q301-552 292.5-543.375T284-522v215q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T344-307v-215q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625Zm166-131Q467-683 458.5-674.375T450-653v346q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T510-307v-346q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625Zm166 258Q633-425 624.5-416.375T616-395v88q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T676-307v-88q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625Z"
		/>
	</svg>
);
