import { ReactNode } from 'react';

export const symbol_power_settings_new: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M450-468v-346q0-13 8.5-21.5T480-844q13 0 21.5 8.5T510-814v346q0 13-8.5 21.5T480-438q-13 0-21.5-8.5T450-468Zm30 350q-74 0-139.5-28.5T226-224q-49-49-77.5-114.5T120-478q0-72 27.5-136T226-729q9-9 22.5-9.5T271-730q9 9 7.5 21.5T268-687q-42 42-65 95.5T180-478q0 125 87.5 212.5T480-178q125 0 212.5-87.5T780-478q0-61-23-115t-64-95q-9-9-10-21.5t8-20.5q10-10 23.5-8.5T738-727q49 50 75.5 114T840-478q0 74-28.5 139.5t-77 114.5q-48.5 49-114 77.5T480-118Z"
		/>
	</svg>
);
