import { ReactNode } from 'react';

export const symbol_commute: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M479-227v34q0 14.025-9.482 23.512-9.483 9.488-23.5 9.488-14.018 0-23.518-9.488Q413-178.975 413-193v-206q0-4.667.5-9.333Q414-413 416-418l46-135q6-18.222 21.75-29.611T519-594h255q19.5 0 35.25 11.389T831-553l46 135q2 5 2.5 9.667.5 4.666.5 9.333v206q0 14.025-9.482 23.512-9.483 9.488-23.5 9.488-14.018 0-23.518-9.488Q814-178.975 814-193v-34H479Zm-13-212h361l-38-115H504l-38 115Zm-13 40v132-132Zm67 106q16.575 0 27.787-11.212Q559-315.425 559-332q0-16.575-11.213-27.788Q536.575-371 520-371t-27.788 11.212Q481-348.575 481-332q0 16.575 11.212 27.788Q503.425-293 520-293Zm253 0q16.575 0 27.787-11.212Q812-315.425 812-332q0-16.575-11.213-27.788Q789.575-371 773-371t-27.787 11.212Q734-348.575 734-332q0 16.575 11.213 27.788Q756.425-293 773-293ZM179-160q-9.917 0-13.458-9.5Q162-179 169-186l52-52q-57 0-99-34.5T80-361v-328q0-66 52-88.5T338-800q143 0 200.5 23t57.5 76v47q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T536-654v-47H140v269h213q12.75 0 21.375 8.625T383-402v212q0 12.75-8.625 21.375T353-160H179Zm12-162q16.575 0 27.788-11.212Q230-344.425 230-361q0-16.575-11.212-27.788Q207.575-400 191-400q-16.575 0-27.788 11.212Q152-377.575 152-361q0 16.575 11.212 27.788Q174.425-322 191-322Zm262 55h387v-132H453v132Z"
		/>
	</svg>
);
