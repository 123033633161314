import { ReactNode } from 'react';

export const symbol_line_start_square: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-320h320v-320H140v320Zm-30 60q-12.75 0-21.375-8.625T80-290v-380q0-12.75 8.625-21.375T110-700h380q12.75 0 21.375 8.625T520-670v160h330q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-450H520v160q0 12.75-8.625 21.375T490-260H110Zm190-220Z"
		/>
	</svg>
);
