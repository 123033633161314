import { Record, Map, List } from 'immutable';

import Exercise from 'pkg/models/exercise';

import * as actionTypes from 'pkg/actions/action-types';

const initialState = new Record(
	{
		entities: new Map({}),
		result: new List([]),
	},
	'exercisesReducer'
);

const setExercisesReducer = (state, action) => {
	const data = action.payload.entities;

	const entities = Object.values(data.exercises);

	entities.forEach((entity) => {
		state = state.setIn(['entities', entity.id], new Exercise(entity));
	});

	return state;
};

const removeExerciseReducer = (state, action) =>
	state.deleteIn(['entities', action.exerciseId]);

const exercises = (state = initialState(), action) => {
	switch (action.type) {
		case actionTypes.Exercises.SET_ITEMS:
			return setExercisesReducer(state, action);

		case actionTypes.Exercises.DELETE_ITEM:
			return removeExerciseReducer(state, action);

		default:
			return state;
	}
};

export default exercises;
