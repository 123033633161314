import { t } from '@transifex/native';

import { palette } from 'pkg/config/styles';

import * as models from 'pkg/api/models';

import * as Chart from 'design/chart';
import * as DataCard from 'design/data_card';

interface AttendanceBreakdownWidget {
	dashboardData: models.eventReport.AttendanceDashboardData;
}

export default function AttendanceBreakdownWidget({
	dashboardData,
}: AttendanceBreakdownWidget) {
	const chatItems: Chart.ChartItem[] = [
		{
			title: t('Attended'),
			value: dashboardData.attendedAmount,
			fillColor: palette.green[200],
			textColor: palette.green[600],
		},
		{
			title: t(`Didn't attend`),
			value:
				dashboardData.illnessAmount +
				dashboardData.injuredAmount +
				dashboardData.otherReasonAmount,
			fillColor: palette.red[200],
			textColor: palette.red[600],
		},
		{
			title: t('No response'),
			value: dashboardData.unhandledAmount,
			fillColor: palette.gray[200],
			textColor: palette.gray[600],
		},
	];

	return (
		<DataCard.Base
			title={t('Breakdown attendance')}
			titleIcon="sound_detection_glass_break">
			<Chart.Donut items={chatItems} />
		</DataCard.Base>
	);
}
