import { ReactNode } from 'react';

export const symbol_trending_flat: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M765-449H150q-13 0-21.5-8.5T120-479q0-13 8.5-21.5T150-509h616l-85-85q-9-9-9-21t9-21q9-9 21-9t21 9l136 136q9 9 9 21t-9 21L723-322q-9 9-21 9t-21-9q-9-9-9-21.5t9-21.5l84-84Z"
		/>
	</svg>
);
