import { ReactNode } from 'react';

export const symbol_disabled_visible: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M680-140q64 0 121-32.5t93-87.5q-36-55-93-87.5T680-380q-64 0-121 32.5T466-260q36 55 93 87.5T680-140Zm0 60q-82 0-150-38T422-218q-6-9-9-19.5t-3-21.5q0-11 3-22t9-21q39-63 107.5-100.5T680-440q82 0 150 38t108 100q6 9 9 19.5t3 21.5q0 11-3 22t-9 21q-39 63-107.5 100.5T680-80Zm0-120q-25 0-42.5-17.5T620-260q0-25 17.5-42.5T680-320q25 0 42.5 17.5T740-260q0 25-17.5 42.5T680-200ZM350-100q-123-40-196.5-143.5T80-480q0-84 30.5-157T195-764q54-54 127-85t158-31q162 0 277 108.5T880-501q1 15-8 22.5t-20 8.5q-11 1-21-6t-11-22q-6-139-103.5-230.5T480-820q-66 0-123 21.5T258-740l248 248q10 10 9 20t-9 18q-8 7-18.5 8.5T467-454L219-702q-37 43-58 100t-21 122q0 115 63.5 202T369-158q15 5 19.5 16.5t.5 22.5q-4 11-14 17.5t-25 1.5Z"
		/>
	</svg>
);
