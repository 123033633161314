import { ReactNode } from 'react';

export const symbol_pulmonology: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m480-518-83 83q-8.8 9-20.9 9-12.1 0-21.1-9.053-9-9.052-9-21.5Q346-469 355-478l95-94v-278q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-850v278l95 95q9 8.8 9 20.9 0 12.1-9.053 21.1-9.052 9-21.5 9Q571-426 562-435l-82-83ZM190-120q-46 0-78-32t-32-78v-174q0-5 1-10.5t3-10.5l104-269q12-31 40.071-48.5 28.072-17.5 60.822-17.5Q331-760 360.5-730.428T390-659v29q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T330-630v-29q0-17.194-12.825-29.097Q304.35-700 287.25-700 273-700 261-693.5T244-674L140-405v175q0 21.25 14.375 35.625T190-180h140q21.25 0 35.625-14.375T380-230v-40q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T440-270v40q0 46-32.083 78-32.084 32-77.917 32H190Zm580 0H630q-45.833 0-77.917-32Q520-184 520-230v-40q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T580-270v40q0 21.25 14.375 35.625T630-180h140q21.25 0 35.625-14.375T820-230v-175L716-674q-5-13-17-19.5t-26-6.5q-17 0-30 11.903T630-659v29q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T570-630v-29q0-41.856 29.517-71.428Q629.035-760 671.203-760 704-760 732-742.5t40 48.5l104 269q2 5 3 10.5t1 10.5v174q0 46-32.083 78-32.084 32-77.917 32ZM355-435Zm250 0Z"
		/>
	</svg>
);
