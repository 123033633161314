import { ReactNode } from 'react';

export const symbol_step_out: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M479.882-80Q434-80 402-112.118q-32-32.117-32-78Q370-236 402.118-268q32.117-32 78-32Q526-300 558-267.882q32 32.117 32 78Q590-144 557.882-112q-32.117 32-78 32ZM450-764l-93 92q-9 8-21.1 8.5-12.1.5-20.9-8.5-9-9-9-21t9-21l144-144q9-9 21-9t21 9l144 144q9 9 8.5 21t-9.5 21.391Q635-663 623-663t-21-9l-92-92v324q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T450-440v-324Z"
		/>
	</svg>
);
