import { ReactNode } from 'react';

export const symbol_folded_hands: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M691-437v87q0 13-8.5 21.5T661-320q-13 0-21.5-8.5T631-350v-72l-49-90q-18 2-29.5 15.5T541-465v227l69 113q9 15 .5 30T584-80q-8 0-15-3.5T558-94l-77-127v-244q0-35 20-62.5t52-38.5l-66-122q-20-38-16.5-80t32.5-71l37-37q9-9 22.5-8.5T584-874l244 284q6 8 10 16t5 18l35 443q1 14-7.5 23.5T848-80q-12 0-20.5-8t-9.5-20l-36-444-222-259-14 14q-14 14-17 33.5t6 36.5l156 290Zm-421 0 156-290q9-17 6-36.5T415-797l-14-14-222 259-36 444q-1 12-9.5 20T113-80q-14 0-22.5-9.5T83-113l36-443q1-10 4.5-18t9.5-16l244-284q8-10 21.5-10.5T421-876l37 37q29 29 32.5 71T474-688l-66 122q32 11 52 38.5t20 62.5v244L403-94q-4 7-11 10.5T377-80q-17 0-25.5-15t.5-30l68-113v-227q0-18-11.5-31.5T379-512l-49 90v72q0 13-8.5 21.5T300-320q-13 0-21.5-8.5T270-350v-87Z"
		/>
	</svg>
);
