import { ReactNode } from 'react';

export const symbol_edit_square: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h333q15 0 22.5 9.318t7.5 20.5q0 11.182-7.683 20.682Q527.634-780 513-780H180v600h600v-336q0-15 9.318-22.5t20.5-7.5q11.182 0 20.682 7.5Q840-531 840-516v336q0 24-18 42t-42 18H180Zm300-360Zm-120 90v-115q0-12.444 5-23.722T378-548l364-364q9-9 20-13t22-4q11 0 22.317 4.5T827-911l83 84q8.609 8.958 13.304 19.782Q928-796.394 928-785.197q0 11.197-4.5 22.697T910-742L548-378q-8 8-19.278 13-11.278 5-23.722 5H390q-12.75 0-21.375-8.625T360-390Zm508-395-84-84 84 84ZM420-420h85l253-253-43-42-43-42-252 251v86Zm295-295-43-42 43 42 43 42-43-42Z"
		/>
	</svg>
);
