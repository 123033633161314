import { ReactNode } from 'react';

export const symbol_edit_location: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m540-577-43-43-138 137q-5 5-7 10t-2 11v17q0 6 4.5 10.5T365-430h17q6 0 11-2t10-7l137-138Zm33-33 27-27q3-3 3-7.5t-3-7.5l-28-28q-3-3-7.5-3t-7.5 3l-27 27 43 43Zm-93 451q133-121 196.5-219.5T740-552q0-118-75.5-193T480-820q-109 0-184.5 75T220-552q0 75 65 173.5T480-159Zm0 59q-11 0-21.5-3.5T440-115q-42-38-91-87.5T258-309q-42-57-70-119t-28-124q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 62-28 124t-70 119q-42 57-91 106.5T520-115q-8 8-18.5 11.5T480-100Zm0-460Z"
		/>
	</svg>
);
