import VideoSequenceComment, {
	VideoSequenceCommentPayload,
} from 'pkg/models/video_sequence_comment';
import { PlaylistSequencePermissions } from 'pkg/models/video_playlist';
import {
	VideoSequencePayload,
	VideoSequenceResponse,
} from 'pkg/models/video_sequence';
import VideoSequenceUser from 'pkg/models/video_sequence_user';

import * as sdk from 'pkg/core/sdk';
import * as endpoints from 'pkg/api/endpoints/auto';

/* Videos */

interface VideoPayload {
	groupId?: number;
	thumbnailAttachmentId?: number;
	title: string;
	description?: string;
	duration: number;
	private: boolean;
	uri: string;
	type: string;
}

export const getVideo = (videoId: number): Promise<Response> =>
	sdk.get(endpoints.Video.Show(videoId));

export const getAccountVideos = (
	accountId: number,
	limit: number,
	headers: any
): Promise<Response> => {
	return sdk.get(
		endpoints.Video.Index(),
		{ account_id: accountId, limit },
		{},
		headers
	);
};

export const updateVideo = (
	videoId: number,
	payload: VideoPayload
): Promise<Response> => sdk.patch(endpoints.Video.Update(videoId), {}, payload);

export const destroyVideo = (videoId: number): Promise<Response> =>
	sdk.destroy(endpoints.Video.Delete(videoId));

/* Sequences */

export const createSequence = async (
	videoId: number,
	payload: VideoSequencePayload
): Promise<[Response, VideoSequenceResponse]> => {
	const request = await sdk.post(
		endpoints.Video.CreateSequence(videoId),
		{},
		payload
	);

	const response: VideoSequenceResponse = await request.json();

	return [request, response];
};

export const updateSequence = async (
	sequenceId: number,
	payload: VideoSequencePayload
): Promise<[Response, VideoSequenceResponse]> => {
	const request = await sdk.patch(
		endpoints.Video.UpdateSequence(sequenceId),
		{},
		payload
	);

	const response: VideoSequenceResponse = await request.json();

	return [request, response];
};

export const destroySequence = (sequenceId: number): Promise<Response> =>
	sdk.destroy(endpoints.Video.DeleteSequence(sequenceId));

export const deleteSequenceTag = (
	sequenceId: number,
	tagId: number
): Promise<Response> =>
	sdk.destroy(endpoints.Video.RemoveSequenceTag(sequenceId, tagId));

/* Sequence Users */

interface SequenceUserPayload {
	userId: number;
	description?: string;
}

export const createSequenceUser = async (
	sequenceId: number,
	payload: SequenceUserPayload
): Promise<[Response, VideoSequenceUser]> => {
	const request = await sdk.post(
		endpoints.Video.AddVideoSequenceUser(sequenceId),
		{},
		payload
	);

	const response = await request.json();

	return [request, response];
};

export const updateSequenceUser = async (
	sequenceUserId: number,
	payload: { description?: string }
): Promise<[Response, VideoSequenceUser]> => {
	const request = await sdk.patch(
		endpoints.Video.UpdateVideoSequenceUser(sequenceUserId),
		{},
		payload
	);

	const response = await request.json();

	return [request, response];
};

export const destroySequenceUser = (
	sequenceUserId: number
): Promise<Response> =>
	sdk.destroy(endpoints.Video.DeleteVideoSequenceUser(sequenceUserId));

/* Sequence Comments */

export const createSequenceComment = async (
	sequenceId: number,
	payload: VideoSequenceCommentPayload
): Promise<[Response, VideoSequenceComment]> => {
	const request = await sdk.post(
		endpoints.Video.CreateVideoSequenceComment(sequenceId),
		{},
		payload
	);

	const response: VideoSequenceComment = await request.json();

	return [request, response];
};

export const updateSequenceComment = async (
	sequenceCommentId: number,
	payload: VideoSequenceCommentPayload
): Promise<[Response, VideoSequenceComment]> => {
	const request = await sdk.patch(
		endpoints.Video.UpdateVideoSequenceComment(sequenceCommentId),
		{},
		payload
	);

	const response: VideoSequenceComment = await request.json();

	return [request, response];
};

export const destroySequenceComment = async (
	sequenceCommentId: number
): Promise<[Response, VideoSequenceComment]> => {
	const request = await sdk.destroy(
		endpoints.Video.DeleteVideoSequenceComment(sequenceCommentId)
	);

	const response: VideoSequenceComment = await request.json();

	return [request, response];
};

/* Playlists */

export interface PlaylistResponse {
	id: number;
	accountId: number;
	groupId?: number;
	title: string;
	isPrivate: boolean;
	sequences?: JSONObject;
	createdAt?: number;
	updatedAt?: number;
	links?: {
		delete?: string;
		edit?: string;
	};
}

export const fetchPlaylist = async (
	playlistId: number
): Promise<[Response, PlaylistResponse]> => {
	const request = await sdk.get(endpoints.VideoPlaylist.Show(playlistId));

	const response: PlaylistResponse = await request.json();

	return [request, response];
};

export interface PlaylistCreatePayload {
	groupId?: number;
	title: string;
	sequencePermissions: PlaylistSequencePermissions[];
}

export const createPlaylist = async (
	payload: PlaylistCreatePayload
): Promise<[Response, PlaylistResponse]> => {
	const request = await sdk.post(endpoints.VideoPlaylist.Create(), {}, payload);

	const response: PlaylistResponse = await request.json();

	return [request, response];
};

export interface PlaylistUpdatePayload {
	groupId?: number;
	title?: string;
	isPrivate?: boolean;
}

export const updatePlaylist = async (
	playlistId: number,
	payload: PlaylistUpdatePayload
): Promise<[Response, PlaylistResponse]> => {
	const request = await sdk.patch(
		endpoints.VideoPlaylist.Update(playlistId),
		{},
		payload
	);

	const response: PlaylistResponse = await request.json();

	return [request, response];
};

export const destroyPlaylist = async (
	playlistId: number
): Promise<[Response, PlaylistResponse]> => {
	const request = await sdk.destroy(endpoints.VideoPlaylist.Delete(playlistId));

	const response: PlaylistResponse = await request.json();

	return [request, response];
};

export const addPlaylistSequences = async (
	playlistId: number,
	...sequenceIds: number[]
): Promise<[Response, PlaylistResponse]> => {
	const request = await sdk.post(
		endpoints.VideoPlaylist.AddSequences(playlistId),
		{},
		sequenceIds
	);

	const response: PlaylistResponse = await request.json();

	return [request, response];
};

export const removePlaylistSequences = async (
	playlistId: number,
	...sequenceIds: number[]
): Promise<[Response, PlaylistResponse]> => {
	const request = await sdk.destroy(
		endpoints.VideoPlaylist.RemoveSequences(playlistId),
		{},
		sequenceIds
	);

	const response: PlaylistResponse = await request.json();

	return [request, response];
};

export const patchPlaylistSequences = async (
	playlistId: number,
	sequences: number[]
): Promise<[Response, PlaylistResponse]> => {
	const request = await sdk.patch(
		endpoints.VideoPlaylist.UpdateSequenceOrder(playlistId),
		{},
		{ sequences }
	);

	const response: PlaylistResponse = await request.json();

	return [request, response];
};
