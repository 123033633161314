import { ReactNode } from 'react';

export const symbol_airline_seat_flat_angled: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m377-479 65-179q8-23 31-33t46-2l290 105q59 21 85 77.5t5 116.5l-34 93q-5 12-15.5 17.5t-22.5.5L395-441q-12-5-17.5-15.5T377-479Zm122-158q-8 23-15.5 43.5T468-551l-24 64 55-150Zm287 467L91-424q-11-5-17-15.887t-1-22.5Q78-474 89-479.5t22-.5l696 253q12 5 17 15.5t.5 22.5q-4.5 12-15.5 18t-23 1ZM258-498q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35Zm0-60q25.5 0 42.75-17.25T318-618q0-25.5-17.25-42.75T258-678q-25.5 0-42.75 17.25T198-618q0 25.5 17.25 42.75T258-558Zm186 71 375 137 24-65q13-35-3.5-69.5T788-532L499-637l-55 150ZM258-618Z"
		/>
	</svg>
);
