import { ReactNode } from 'react';

export const symbol_window_closed: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M220-180h520v-270H220v270Zm0 60q-24.75 0-42.375-17.625T160-180v-600q0-24.75 17.625-42.375T220-840h520q24.75 0 42.375 17.625T800-780v600q0 24.75-17.625 42.375T740-120H220Zm0-390h220v-25q0-6 4.5-10.5T455-550h50q6 0 10.5 4.5T520-535v25h220v-270H220v270Zm0 330h520-520Z"
		/>
	</svg>
);
