import { useState } from 'react';
import { t } from '@transifex/native';

import Pricing from 'routes/payments/products/modals/Pricing';
import { AssignedProductProps } from 'routes/payments/orders/create';

import StepModal, { Step } from 'components/step-modal';

interface EditProductModalProps {
	data: AssignedProductProps;
	productId: number;
	onSave: (formState: AssignedProductProps) => void;
	onClose: () => void;
}

const EditProductModal: React.FC<
	React.PropsWithChildren<EditProductModalProps>
> = ({ data, productId, onSave, onClose }) => {
	const [formState, setFormState] = useState<AssignedProductProps>({ ...data });

	const handleSave = async () => await onSave(formState);

	return (
		<StepModal onClose={onClose} wide>
			<Step title={t('Edit product')} onNext={handleSave}>
				<Pricing
					productId={productId}
					formState={formState}
					setFormState={setFormState}
				/>
			</Step>
		</StepModal>
	);
};

export default EditProductModal;
