import { ReactNode } from 'react';

export const symbol_nest_cam_stand: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M360-668q-50 29-81 78t-35 109l-25 301h523l-26-302q-4-60-35-108.5T600-668v108q0 50-35 85t-85 35q-50 0-85-35t-35-85v-108Zm120 168q25.5 0 42.75-17.25T540-560v-160q0-25.5-17.25-42.75T480-780q-25.5 0-42.75 17.25T420-720v160q0 25.5 17.25 42.75T480-500ZM218-120q-26.145 0-44.072-19.5Q156-159 158-185l26-303q6-85 54.273-150 48.272-65 122.693-97Q367-780 400.5-810t79.5-30q46 0 79.5 29.784Q593-780.433 599-735q74 31.871 122.5 97.107T776-488l26 303q2 26-15.928 45.5Q768.145-120 742-120H218Z"
		/>
	</svg>
);
