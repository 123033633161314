import { ReactNode } from 'react';

export const symbol_conveyor_belt: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M201-120q-50 0-85-35t-35-85q0-50 35-85t85-35h560q50 0 85 35t35 85q0 50-35 85t-85 35H201Zm0-60h560q25.5 0 42.75-17.25T821-240q0-25.5-17.25-42.75T761-300H201q-25.5 0-42.75 17.25T141-240q0 25.5 17.25 42.75T201-180Zm190-260q-12.75 0-21.375-8.625T361-470v-340q0-12.75 8.625-21.375T391-840h340q12.75 0 21.375 8.625T761-810v340q0 12.75-8.625 21.375T731-440H391Zm30-60h280v-280H421v280Zm-312-30q-12.632 0-21.316-8.5T79-560q0-13 8.5-21.5T109-590h161q12.632 0 21.316 8.684T300-560q0 13-8.684 21.5T270-530H109Zm401-120h102q13.077 0 21.538-8.675 8.462-8.676 8.462-21.5 0-12.825-8.462-21.325Q625.077-710 612-710H510q-13.077 0-21.538 8.446-8.462 8.447-8.462 21.5 0 13.054 8.625 21.554Q497.25-650 510-650Zm-319 0q-13.077 0-21.538-8.675-8.462-8.676-8.462-21.5 0-12.825 8.625-21.325T191-710h87q13.077 0 21.538 8.446 8.462 8.447 8.462 21.5 0 13.054-8.462 21.554Q291.077-650 278-650h-87Zm230 150v-280 280Z"
		/>
	</svg>
);
