import { Map, Record } from 'immutable';

import Sport from 'pkg/models/sport';

import * as actionTypes from 'pkg/actions/action-types';

interface SportsState {
	entities: Map<string, Sport>;
}

const initialState = Record<SportsState>(
	{
		entities: Map<string, Sport>(),
	},
	'sports'
);

interface NormalizrPayload {
	result: number;
	entities: {
		sports: { [key: string]: Sport };
	};
}

interface Action {
	type: string;
	payload?: NormalizrPayload;
}

const addSports = (state = initialState(), action: Action) => {
	let entities = state.entities;

	Object.values(action.payload.entities.sports)
		.filter((s) => s)
		.forEach(
			(sport: Sport) => (entities = entities.set(sport.type, new Sport(sport)))
		);

	return state.set('entities', entities);
};

const sports = (
	state = initialState(),
	action: Action
): Record<SportsState> => {
	switch (action.type) {
		case actionTypes.Sports.SET_ITEMS:
			return addSports(state, action);
		default:
			return state;
	}
};

export default sports;
