import { t } from '@transifex/native';

interface CountryCode {
	code: string;
	country: string;
	nationality: string;
}

export function ISO3166Alpha2Codes(): CountryCode[] {
	return [
		{
			code: 'ad',
			nationality: t('Andorran', {
				_context: 'nationality',
			}),
			country: t('Andorra', {
				_context: 'country',
			}),
		},
		{
			code: 'ae',
			nationality: t('Emirian', {
				_context: 'nationality',
			}),
			country: t('United Arab Emirates', {
				_context: 'country',
			}),
		},
		{
			code: 'af',
			nationality: t('Afghani', {
				_context: 'nationality',
			}),
			country: t('Afghanistan', {
				_context: 'country',
			}),
		},
		{
			code: 'ag',
			nationality: t('Antiguan', {
				_context: 'nationality',
			}),
			country: t('Antigua and Barbuda', {
				_context: 'country',
			}),
		},
		{
			code: 'ai',
			nationality: t('Anguillan', {
				_context: 'nationality',
			}),
			country: t('Anguilla', {
				_context: 'country',
			}),
		},
		{
			code: 'al',
			nationality: t('Albanian', {
				_context: 'nationality',
			}),
			country: t('Albania', {
				_context: 'country',
			}),
		},
		{
			code: 'am',
			nationality: t('Armenian', {
				_context: 'nationality',
			}),
			country: t('Armenia', {
				_context: 'country',
			}),
		},
		{
			code: 'ao',
			nationality: t('Angolan', {
				_context: 'nationality',
			}),
			country: t('Angola', {
				_context: 'country',
			}),
		},
		{
			code: 'aq',
			nationality: t('Antarctic', {
				_context: 'nationality',
			}),
			country: t('Antarctica', {
				_context: 'country',
			}),
		},
		{
			code: 'ar',
			nationality: t('Argentine', {
				_context: 'nationality',
			}),
			country: t('Argentina', {
				_context: 'country',
			}),
		},
		{
			code: 'as',
			nationality: t('Samoan', {
				_context: 'nationality',
			}),
			country: t('American Samoa', {
				_context: 'country',
			}),
		},
		{
			code: 'at',
			nationality: t('Austrian', {
				_context: 'nationality',
			}),
			country: t('Austria', {
				_context: 'country',
			}),
		},
		{
			code: 'au',
			nationality: t('Australian', {
				_context: 'nationality',
			}),
			country: t('Australia', {
				_context: 'country',
			}),
		},
		{
			code: 'aw',
			nationality: t('Arubian', {
				_context: 'nationality',
			}),
			country: t('Aruba', {
				_context: 'country',
			}),
		},
		{
			code: 'ax',
			nationality: t('Ålandic', {
				_context: 'nationality',
			}),
			country: t('Åland Islands', {
				_context: 'country',
			}),
		},
		{
			code: 'az',
			nationality: t('Azerbaijani', {
				_context: 'nationality',
			}),
			country: t('Azerbaijan', {
				_context: 'country',
			}),
		},
		{
			code: 'ba',
			nationality: t('Bosnian', {
				_context: 'nationality',
			}),
			country: t('Bosnia and Herzegovina', {
				_context: 'country',
			}),
		},
		{
			code: 'bb',
			nationality: t('Barbadian', {
				_context: 'nationality',
			}),
			country: t('Barbados', {
				_context: 'country',
			}),
		},
		{
			code: 'bd',
			nationality: t('Bangladeshi', {
				_context: 'nationality',
			}),
			country: t('Bangladesh', {
				_context: 'country',
			}),
		},
		{
			code: 'be',
			nationality: t('Belgian', {
				_context: 'nationality',
			}),
			country: t('Belgium', {
				_context: 'country',
			}),
		},
		{
			code: 'bf',
			nationality: t('Burkinabe', {
				_context: 'nationality',
			}),
			country: t('Burkina Faso', {
				_context: 'country',
			}),
		},
		{
			code: 'bg',
			nationality: t('Bulgarian', {
				_context: 'nationality',
			}),
			country: t('Bulgaria', {
				_context: 'country',
			}),
		},
		{
			code: 'bh',
			nationality: t('Bahrainian', {
				_context: 'nationality',
			}),
			country: t('Bahrain', {
				_context: 'country',
			}),
		},
		{
			code: 'bi',
			nationality: t('Burundian', {
				_context: 'nationality',
			}),
			country: t('Burundi', {
				_context: 'country',
			}),
		},
		{
			code: 'bj',
			nationality: t('Beninese', {
				_context: 'nationality',
			}),
			country: t('Benin', {
				_context: 'country',
			}),
		},
		{
			code: 'bl',
			nationality: t('Barthélemois', {
				_context: 'nationality',
			}),
			country: t('Saint Barthélemy', {
				_context: 'country',
			}),
		},
		{
			code: 'bm',
			nationality: t('Bermudan', {
				_context: 'nationality',
			}),
			country: t('Bermuda', {
				_context: 'country',
			}),
		},
		{
			code: 'bn',
			nationality: t('Bruneian', {
				_context: 'nationality',
			}),
			country: t('Brunei Darussalam', {
				_context: 'country',
			}),
		},
		{
			code: 'bo',
			nationality: t('Bolivian', {
				_context: 'nationality',
			}),
			country: t('Bolivia', {
				_context: 'country',
			}),
		},
		{
			code: 'bq',
			nationality: t('Caribbean Netherlands', {
				_context: 'nationality',
			}),
			country: t('Caribbean Netherlands', {
				_context: 'country',
			}),
		},
		{
			code: 'br',
			nationality: t('Brazilian', {
				_context: 'nationality',
			}),
			country: t('Brazil', {
				_context: 'country',
			}),
		},
		{
			code: 'bs',
			nationality: t('Bahameese', {
				_context: 'nationality',
			}),
			country: t('Bahamas', {
				_context: 'country',
			}),
		},
		{
			code: 'bt',
			nationality: t('Bhutanese', {
				_context: 'nationality',
			}),
			country: t('Bhutan', {
				_context: 'country',
			}),
		},
		{
			code: 'bv',
			nationality: t('Bouvet Island', {
				_context: 'nationality',
			}),
			country: t('Bouvet Island', {
				_context: 'country',
			}),
		},
		{
			code: 'bw',
			nationality: t('Motswana', {
				_context: 'nationality',
			}),
			country: t('Botswana', {
				_context: 'country',
			}),
		},
		{
			code: 'by',
			nationality: t('Belarusian', {
				_context: 'nationality',
			}),
			country: t('Belarus', {
				_context: 'country',
			}),
		},
		{
			code: 'bz',
			nationality: t('Belizean', {
				_context: 'nationality',
			}),
			country: t('Belize', {
				_context: 'country',
			}),
		},
		{
			code: 'ca',
			nationality: t('Canadian', {
				_context: 'nationality',
			}),
			country: t('Canada', {
				_context: 'country',
			}),
		},
		{
			code: 'cc',
			nationality: t('Cocossian', {
				_context: 'nationality',
			}),
			country: t('Cocos (Keeling) Islands', {
				_context: 'country',
			}),
		},
		{
			code: 'cd',
			nationality: t('Congolese', {
				_context: 'nationality',
			}),
			country: t('Congo', {
				_context: 'country',
			}),
		},
		{
			code: 'cf',
			nationality: t('Central African', {
				_context: 'nationality',
			}),
			country: t('Central African Republic', {
				_context: 'country',
			}),
		},
		{
			code: 'cg',
			nationality: t('Congolese', {
				_context: 'nationality',
			}),
			country: t('Congo', {
				_context: 'country',
			}),
		},
		{
			code: 'ch',
			nationality: t('Swiss', {
				_context: 'nationality',
			}),
			country: t('Switzerland', {
				_context: 'country',
			}),
		},
		{
			code: 'ci',
			nationality: t('Ivorian', {
				_context: 'nationality',
			}),
			country: t("Côte d'Ivoire", {
				_context: 'country',
			}),
		},
		{
			code: 'ck',
			nationality: t('Cook Islander', {
				_context: 'nationality',
			}),
			country: t('Cook Islands', {
				_context: 'country',
			}),
		},
		{
			code: 'cl',
			nationality: t('Chilean', {
				_context: 'nationality',
			}),
			country: t('Chile', {
				_context: 'country',
			}),
		},
		{
			code: 'cm',
			nationality: t('Cameroonian', {
				_context: 'nationality',
			}),
			country: t('Cameroon', {
				_context: 'country',
			}),
		},
		{
			code: 'cn',
			nationality: t('Chinese', {
				_context: 'nationality',
			}),
			country: t('China', {
				_context: 'country',
			}),
		},
		{
			code: 'co',
			nationality: t('Colombian', {
				_context: 'nationality',
			}),
			country: t('Colombia', {
				_context: 'country',
			}),
		},
		{
			code: 'cr',
			nationality: t('Costa Rican', {
				_context: 'nationality',
			}),
			country: t('Costa Rica', {
				_context: 'country',
			}),
		},
		{
			code: 'cu',
			nationality: t('Cuban', {
				_context: 'nationality',
			}),
			country: t('Cuba', {
				_context: 'country',
			}),
		},
		{
			code: 'cv',
			nationality: t('Cape Verdean', {
				_context: 'nationality',
			}),
			country: t('Cabo Verde', {
				_context: 'country',
			}),
		},
		{
			code: 'cw',
			nationality: t('Curaįaoan', {
				_context: 'nationality',
			}),
			country: t('Curaçao', {
				_context: 'country',
			}),
		},
		{
			code: 'cx',
			nationality: t('Christmas Islander', {
				_context: 'nationality',
			}),
			country: t('Christmas Island', {
				_context: 'country',
			}),
		},
		{
			code: 'cy',
			nationality: t('Cypriot', {
				_context: 'nationality',
			}),
			country: t('Cyprus', {
				_context: 'country',
			}),
		},
		{
			code: 'cz',
			nationality: t('Czech', {
				_context: 'nationality',
			}),
			country: t('Czechia', {
				_context: 'country',
			}),
		},
		{
			code: 'de',
			nationality: t('German', {
				_context: 'nationality',
			}),
			country: t('Germany', {
				_context: 'country',
			}),
		},
		{
			code: 'dj',
			nationality: t('Djiboutian', {
				_context: 'nationality',
			}),
			country: t('Djibouti', {
				_context: 'country',
			}),
		},
		{
			code: 'dk',
			nationality: t('Danish', {
				_context: 'nationality',
			}),
			country: t('Denmark', {
				_context: 'country',
			}),
		},
		{
			code: 'dm',
			nationality: t('Dominican', {
				_context: 'nationality',
			}),
			country: t('Dominica', {
				_context: 'country',
			}),
		},
		{
			code: 'do',
			nationality: t('Dominican', {
				_context: 'nationality',
			}),
			country: t('Dominican Republic', {
				_context: 'country',
			}),
		},
		{
			code: 'dz',
			nationality: t('Algerian', {
				_context: 'nationality',
			}),
			country: t('Algeria', {
				_context: 'country',
			}),
		},
		{
			code: 'ec',
			nationality: t('Ecuadorean', {
				_context: 'nationality',
			}),
			country: t('Ecuador', {
				_context: 'country',
			}),
		},
		{
			code: 'ee',
			nationality: t('Estonian', {
				_context: 'nationality',
			}),
			country: t('Estonia', {
				_context: 'country',
			}),
		},
		{
			code: 'eg',
			nationality: t('Egyptian', {
				_context: 'nationality',
			}),
			country: t('Egypt', {
				_context: 'country',
			}),
		},
		{
			code: 'eh',
			nationality: t('Western Saharan', {
				_context: 'nationality',
			}),
			country: t('Western Sahara', {
				_context: 'country',
			}),
		},
		{
			code: 'er',
			nationality: t('Eritrean', {
				_context: 'nationality',
			}),
			country: t('Eritrea', {
				_context: 'country',
			}),
		},
		{
			code: 'es',
			nationality: t('Spanish', {
				_context: 'nationality',
			}),
			country: t('Spain', {
				_context: 'country',
			}),
		},
		{
			code: 'et',
			nationality: t('Ethiopian', {
				_context: 'nationality',
			}),
			country: t('Ethiopia', {
				_context: 'country',
			}),
		},
		{
			code: 'fi',
			nationality: t('Finnish', {
				_context: 'nationality',
			}),
			country: t('Finland', {
				_context: 'country',
			}),
		},
		{
			code: 'fj',
			nationality: t('Fijian', {
				_context: 'nationality',
			}),
			country: t('Fiji', {
				_context: 'country',
			}),
		},
		{
			code: 'fk',
			nationality: t('Falkland Islander', {
				_context: 'nationality',
			}),
			country: t('Falkland Islands (Malvinas)', {
				_context: 'country',
			}),
		},
		{
			code: 'fm',
			nationality: t('Micronesian', {
				_context: 'nationality',
			}),
			country: t('Micronesia', {
				_context: 'country',
			}),
		},
		{
			code: 'fo',
			nationality: t('Faroese', {
				_context: 'nationality',
			}),
			country: t('Faroe Islands', {
				_context: 'country',
			}),
		},
		{
			code: 'fr',
			nationality: t('French', {
				_context: 'nationality',
			}),
			country: t('France', {
				_context: 'country',
			}),
		},
		{
			code: 'ga',
			nationality: t('Gabonese', {
				_context: 'nationality',
			}),
			country: t('Gabon', {
				_context: 'country',
			}),
		},
		{
			code: 'gb',
			nationality: t('British', {
				_context: 'nationality',
			}),
			country: t('United Kingdom of Great Britain and Northern Ireland', {
				_context: 'country',
			}),
		},
		{
			code: 'gd',
			nationality: t('Grenadian', {
				_context: 'nationality',
			}),
			country: t('Grenada', {
				_context: 'country',
			}),
		},
		{
			code: 'ge',
			nationality: t('Georgian', {
				_context: 'nationality',
			}),
			country: t('Georgia', {
				_context: 'country',
			}),
		},
		{
			code: 'gf',
			nationality: t('French Guianese', {
				_context: 'nationality',
			}),
			country: t('French Guiana', {
				_context: 'country',
			}),
		},
		{
			code: 'gg',
			nationality: t('Guernsey', {
				_context: 'nationality',
			}),
			country: t('Guernsey', {
				_context: 'country',
			}),
		},
		{
			code: 'gh',
			nationality: t('Ghanaian', {
				_context: 'nationality',
			}),
			country: t('Ghana', {
				_context: 'country',
			}),
		},
		{
			code: 'gi',
			nationality: t('Gibraltarian', {
				_context: 'nationality',
			}),
			country: t('Gibraltar', {
				_context: 'country',
			}),
		},
		{
			code: 'gl',
			nationality: t('Greenlander', {
				_context: 'nationality',
			}),
			country: t('Greenland', {
				_context: 'country',
			}),
		},
		{
			code: 'gm',
			nationality: t('Gambian', {
				_context: 'nationality',
			}),
			country: t('Gambia', {
				_context: 'country',
			}),
		},
		{
			code: 'gn',
			nationality: t('Guinean', {
				_context: 'nationality',
			}),
			country: t('Guinea', {
				_context: 'country',
			}),
		},
		{
			code: 'gp',
			nationality: t('Guadeloupean', {
				_context: 'nationality',
			}),
			country: t('Guadeloupe', {
				_context: 'country',
			}),
		},
		{
			code: 'gq',
			nationality: t('Equatorial Guinean', {
				_context: 'nationality',
			}),
			country: t('Equatorial Guinea', {
				_context: 'country',
			}),
		},
		{
			code: 'gr',
			nationality: t('Greek', {
				_context: 'nationality',
			}),
			country: t('Greece', {
				_context: 'country',
			}),
		},
		{
			code: 'gs',
			nationality: t('South Georgia and the South Sandwich Islands', {
				_context: 'nationality',
			}),
			country: t('South Georgia and the South Sandwich Islands', {
				_context: 'country',
			}),
		},
		{
			code: 'gt',
			nationality: t('Guatemalan', {
				_context: 'nationality',
			}),
			country: t('Guatemala', {
				_context: 'country',
			}),
		},
		{
			code: 'gu',
			nationality: t('Guamanian', {
				_context: 'nationality',
			}),
			country: t('Guam', {
				_context: 'country',
			}),
		},
		{
			code: 'gw',
			nationality: t('Guinean', {
				_context: 'nationality',
			}),
			country: t('Guinea-Bissau', {
				_context: 'country',
			}),
		},
		{
			code: 'gy',
			nationality: t('Guyanese', {
				_context: 'nationality',
			}),
			country: t('Guyana', {
				_context: 'country',
			}),
		},
		{
			code: 'hk',
			nationality: t('Hong Konger', {
				_context: 'nationality',
			}),
			country: t('Hong Kong', {
				_context: 'country',
			}),
		},
		{
			code: 'hm',
			nationality: t('Heard Island and McDonald Islands', {
				_context: 'nationality',
			}),
			country: t('Heard Island and McDonald Islands', {
				_context: 'country',
			}),
		},
		{
			code: 'hn',
			nationality: t('Honduran', {
				_context: 'nationality',
			}),
			country: t('Honduras', {
				_context: 'country',
			}),
		},
		{
			code: 'hr',
			nationality: t('Croatian', {
				_context: 'nationality',
			}),
			country: t('Croatia', {
				_context: 'country',
			}),
		},
		{
			code: 'ht',
			nationality: t('Haitian', {
				_context: 'nationality',
			}),
			country: t('Haiti', {
				_context: 'country',
			}),
		},
		{
			code: 'hu',
			nationality: t('Hungarian', {
				_context: 'nationality',
			}),
			country: t('Hungary', {
				_context: 'country',
			}),
		},
		{
			code: 'id',
			nationality: t('Indonesian', {
				_context: 'nationality',
			}),
			country: t('Indonesia', {
				_context: 'country',
			}),
		},
		{
			code: 'ie',
			nationality: t('Irish', {
				_context: 'nationality',
			}),
			country: t('Ireland', {
				_context: 'country',
			}),
		},
		{
			code: 'il',
			nationality: t('Israeli', {
				_context: 'nationality',
			}),
			country: t('Israel', {
				_context: 'country',
			}),
		},
		{
			code: 'im',
			nationality: t('Manx', {
				_context: 'nationality',
			}),
			country: t('Isle of Man', {
				_context: 'country',
			}),
		},
		{
			code: 'in',
			nationality: t('Indian', {
				_context: 'nationality',
			}),
			country: t('India', {
				_context: 'country',
			}),
		},
		{
			code: 'io',
			nationality: t('British Indian Ocean Territory', {
				_context: 'nationality',
			}),
			country: t('British Indian Ocean Territory', {
				_context: 'country',
			}),
		},
		{
			code: 'iq',
			nationality: t('Iraqi', {
				_context: 'nationality',
			}),
			country: t('Iraq', {
				_context: 'country',
			}),
		},
		{
			code: 'ir',
			nationality: t('Iranian', {
				_context: 'nationality',
			}),
			country: t('Iran', {
				_context: 'country',
			}),
		},
		{
			code: 'is',
			nationality: t('Icelander', {
				_context: 'nationality',
			}),
			country: t('Iceland', {
				_context: 'country',
			}),
		},
		{
			code: 'it',
			nationality: t('Italian', {
				_context: 'nationality',
			}),
			country: t('Italy', {
				_context: 'country',
			}),
		},
		{
			code: 'je',
			nationality: t('Jersey', {
				_context: 'nationality',
			}),
			country: t('Jersey', {
				_context: 'country',
			}),
		},
		{
			code: 'jm',
			nationality: t('Jamaican', {
				_context: 'nationality',
			}),
			country: t('Jamaica', {
				_context: 'country',
			}),
		},
		{
			code: 'jo',
			nationality: t('Jordanian', {
				_context: 'nationality',
			}),
			country: t('Jordan', {
				_context: 'country',
			}),
		},
		{
			code: 'jp',
			nationality: t('Japanese', {
				_context: 'nationality',
			}),
			country: t('Japan', {
				_context: 'country',
			}),
		},
		{
			code: 'ke',
			nationality: t('Kenyan', {
				_context: 'nationality',
			}),
			country: t('Kenya', {
				_context: 'country',
			}),
		},
		{
			code: 'kg',
			nationality: t('Kyrgyzstani', {
				_context: 'nationality',
			}),
			country: t('Kyrgyzstan', {
				_context: 'country',
			}),
		},
		{
			code: 'kh',
			nationality: t('Cambodian', {
				_context: 'nationality',
			}),
			country: t('Cambodia', {
				_context: 'country',
			}),
		},
		{
			code: 'ki',
			nationality: t('I-Kiribati', {
				_context: 'nationality',
			}),
			country: t('Kiribati', {
				_context: 'country',
			}),
		},
		{
			code: 'km',
			nationality: t('Comoran', {
				_context: 'nationality',
			}),
			country: t('Comoros', {
				_context: 'country',
			}),
		},
		{
			code: 'kn',
			nationality: t('Kittian', {
				_context: 'nationality',
			}),
			country: t('Saint Kitts and Nevis', {
				_context: 'country',
			}),
		},
		{
			code: 'kp',
			nationality: t('North Korean', {
				_context: 'nationality',
			}),
			country: t('North Korea', {
				_context: 'country',
			}),
		},
		{
			code: 'kr',
			nationality: t('South Korean', {
				_context: 'nationality',
			}),
			country: t('South Korea', {
				_context: 'country',
			}),
		},
		{
			code: 'kw',
			nationality: t('Kuwaiti', {
				_context: 'nationality',
			}),
			country: t('Kuwait', {
				_context: 'country',
			}),
		},
		{
			code: 'ky',
			nationality: t('Caymanian', {
				_context: 'nationality',
			}),
			country: t('Cayman Islands', {
				_context: 'country',
			}),
		},
		{
			code: 'kz',
			nationality: t('Kazakhstani', {
				_context: 'nationality',
			}),
			country: t('Kazakhstan', {
				_context: 'country',
			}),
		},
		{
			code: 'la',
			nationality: t('Laotian', {
				_context: 'nationality',
			}),
			country: t("Lao People's Democratic Republic", {
				_context: 'country',
			}),
		},
		{
			code: 'lb',
			nationality: t('Lebanese', {
				_context: 'nationality',
			}),
			country: t('Lebanon', {
				_context: 'country',
			}),
		},
		{
			code: 'lc',
			nationality: t('Saint Lucian', {
				_context: 'nationality',
			}),
			country: t('Saint Lucia', {
				_context: 'country',
			}),
		},
		{
			code: 'li',
			nationality: t('Liechtensteiner', {
				_context: 'nationality',
			}),
			country: t('Liechtenstein', {
				_context: 'country',
			}),
		},
		{
			code: 'lk',
			nationality: t('Sri Lankan', {
				_context: 'nationality',
			}),
			country: t('Sri Lanka', {
				_context: 'country',
			}),
		},
		{
			code: 'lr',
			nationality: t('Liberian', {
				_context: 'nationality',
			}),
			country: t('Liberia', {
				_context: 'country',
			}),
		},
		{
			code: 'ls',
			nationality: t('Mosotho', {
				_context: 'nationality',
			}),
			country: t('Lesotho', {
				_context: 'country',
			}),
		},
		{
			code: 'lt',
			nationality: t('Lithunian', {
				_context: 'nationality',
			}),
			country: t('Lithuania', {
				_context: 'country',
			}),
		},
		{
			code: 'lu',
			nationality: t('Luxembourger', {
				_context: 'nationality',
			}),
			country: t('Luxembourg', {
				_context: 'country',
			}),
		},
		{
			code: 'lv',
			nationality: t('Latvian', {
				_context: 'nationality',
			}),
			country: t('Latvia', {
				_context: 'country',
			}),
		},
		{
			code: 'ly',
			nationality: t('Libyan', {
				_context: 'nationality',
			}),
			country: t('Libya', {
				_context: 'country',
			}),
		},
		{
			code: 'ma',
			nationality: t('Moroccan', {
				_context: 'nationality',
			}),
			country: t('Morocco', {
				_context: 'country',
			}),
		},
		{
			code: 'mc',
			nationality: t('Monacan', {
				_context: 'nationality',
			}),
			country: t('Monaco', {
				_context: 'country',
			}),
		},
		{
			code: 'md',
			nationality: t('Moldovan', {
				_context: 'nationality',
			}),
			country: t('Moldova', {
				_context: 'country',
			}),
		},
		{
			code: 'me',
			nationality: t('Montenegrin', {
				_context: 'nationality',
			}),
			country: t('Montenegro', {
				_context: 'country',
			}),
		},
		{
			code: 'mf',
			nationality: t('Saint Martin (France)', {
				_context: 'nationality',
			}),
			country: t('Saint Martin (French part)', {
				_context: 'country',
			}),
		},
		{
			code: 'mg',
			nationality: t('Malagasy', {
				_context: 'nationality',
			}),
			country: t('Madagascar', {
				_context: 'country',
			}),
		},
		{
			code: 'mh',
			nationality: t('Marshallese', {
				_context: 'nationality',
			}),
			country: t('Marshall Islands', {
				_context: 'country',
			}),
		},
		{
			code: 'mk',
			nationality: t('Macedonian', {
				_context: 'nationality',
			}),
			country: t('North Macedonia', {
				_context: 'country',
			}),
		},
		{
			code: 'ml',
			nationality: t('Malian', {
				_context: 'nationality',
			}),
			country: t('Mali', {
				_context: 'country',
			}),
		},
		{
			code: 'mm',
			nationality: t('Myanmarese', {
				_context: 'nationality',
			}),
			country: t('Myanmar', {
				_context: 'country',
			}),
		},
		{
			code: 'mn',
			nationality: t('Mongolian', {
				_context: 'nationality',
			}),
			country: t('Mongolia', {
				_context: 'country',
			}),
		},
		{
			code: 'mo',
			nationality: t('Macanese', {
				_context: 'nationality',
			}),
			country: t('Macao', {
				_context: 'country',
			}),
		},
		{
			code: 'mp',
			nationality: t('Northern Mariana Islander', {
				_context: 'nationality',
			}),
			country: t('Northern Mariana Islands', {
				_context: 'country',
			}),
		},
		{
			code: 'mq',
			nationality: t('Martinican', {
				_context: 'nationality',
			}),
			country: t('Martinique', {
				_context: 'country',
			}),
		},
		{
			code: 'mr',
			nationality: t('Mauritanian', {
				_context: 'nationality',
			}),
			country: t('Mauritania', {
				_context: 'country',
			}),
		},
		{
			code: 'ms',
			nationality: t('Montserratian', {
				_context: 'nationality',
			}),
			country: t('Montserrat', {
				_context: 'country',
			}),
		},
		{
			code: 'mt',
			nationality: t('Maltese', {
				_context: 'nationality',
			}),
			country: t('Malta', {
				_context: 'country',
			}),
		},
		{
			code: 'mu',
			nationality: t('Mauritian', {
				_context: 'nationality',
			}),
			country: t('Mauritius', {
				_context: 'country',
			}),
		},
		{
			code: 'mv',
			nationality: t('Maldivan', {
				_context: 'nationality',
			}),
			country: t('Maldives', {
				_context: 'country',
			}),
		},
		{
			code: 'mw',
			nationality: t('Malawian', {
				_context: 'nationality',
			}),
			country: t('Malawi', {
				_context: 'country',
			}),
		},
		{
			code: 'mx',
			nationality: t('Mexican', {
				_context: 'nationality',
			}),
			country: t('Mexico', {
				_context: 'country',
			}),
		},
		{
			code: 'my',
			nationality: t('Malaysian', {
				_context: 'nationality',
			}),
			country: t('Malaysia', {
				_context: 'country',
			}),
		},
		{
			code: 'mz',
			nationality: t('Mozambican', {
				_context: 'nationality',
			}),
			country: t('Mozambique', {
				_context: 'country',
			}),
		},
		{
			code: 'na',
			nationality: t('Namibian', {
				_context: 'nationality',
			}),
			country: t('Namibia', {
				_context: 'country',
			}),
		},
		{
			code: 'nc',
			nationality: t('New Caledonian', {
				_context: 'nationality',
			}),
			country: t('New Caledonia', {
				_context: 'country',
			}),
		},
		{
			code: 'ne',
			nationality: t('Nigerien', {
				_context: 'nationality',
			}),
			country: t('Niger', {
				_context: 'country',
			}),
		},
		{
			code: 'nf',
			nationality: t('Norfolk Islander', {
				_context: 'nationality',
			}),
			country: t('Norfolk Island', {
				_context: 'country',
			}),
		},
		{
			code: 'ng',
			nationality: t('Nigerian', {
				_context: 'nationality',
			}),
			country: t('Nigeria', {
				_context: 'country',
			}),
		},
		{
			code: 'ni',
			nationality: t('Nicaraguan', {
				_context: 'nationality',
			}),
			country: t('Nicaragua', {
				_context: 'country',
			}),
		},
		{
			code: 'nl',
			nationality: t('Dutch', {
				_context: 'nationality',
			}),
			country: t('Netherlands', {
				_context: 'country',
			}),
		},
		{
			code: 'no',
			nationality: t('Norwegian', {
				_context: 'nationality',
			}),
			country: t('Norway', {
				_context: 'country',
			}),
		},
		{
			code: 'np',
			nationality: t('Nepalese', {
				_context: 'nationality',
			}),
			country: t('Nepal', {
				_context: 'country',
			}),
		},
		{
			code: 'nr',
			nationality: t('Nauruan', {
				_context: 'nationality',
			}),
			country: t('Nauru', {
				_context: 'country',
			}),
		},
		{
			code: 'nu',
			nationality: t('Niuean', {
				_context: 'nationality',
			}),
			country: t('Niue', {
				_context: 'country',
			}),
		},
		{
			code: 'nz',
			nationality: t('New Zealander', {
				_context: 'nationality',
			}),
			country: t('New Zealand', {
				_context: 'country',
			}),
		},
		{
			code: 'om',
			nationality: t('Omani', {
				_context: 'nationality',
			}),
			country: t('Oman', {
				_context: 'country',
			}),
		},
		{
			code: 'pa',
			nationality: t('Panamanian', {
				_context: 'nationality',
			}),
			country: t('Panama', {
				_context: 'country',
			}),
		},
		{
			code: 'pe',
			nationality: t('Peruvian', {
				_context: 'nationality',
			}),
			country: t('Peru', {
				_context: 'country',
			}),
		},
		{
			code: 'pf',
			nationality: t('French Polynesian', {
				_context: 'nationality',
			}),
			country: t('French Polynesia', {
				_context: 'country',
			}),
		},
		{
			code: 'pg',
			nationality: t('Papua New Guinean', {
				_context: 'nationality',
			}),
			country: t('Papua New Guinea', {
				_context: 'country',
			}),
		},
		{
			code: 'ph',
			nationality: t('Filipino', {
				_context: 'nationality',
			}),
			country: t('Philippines', {
				_context: 'country',
			}),
		},
		{
			code: 'pk',
			nationality: t('Pakistani', {
				_context: 'nationality',
			}),
			country: t('Pakistan', {
				_context: 'country',
			}),
		},
		{
			code: 'pl',
			nationality: t('Polish', {
				_context: 'nationality',
			}),
			country: t('Poland', {
				_context: 'country',
			}),
		},
		{
			code: 'pm',
			nationality: t('Saint-Pierrais', {
				_context: 'nationality',
			}),
			country: t('Saint Pierre and Miquelon', {
				_context: 'country',
			}),
		},
		{
			code: 'pn',
			nationality: t('Pitcairn Islander', {
				_context: 'nationality',
			}),
			country: t('Pitcairn', {
				_context: 'country',
			}),
		},
		{
			code: 'pr',
			nationality: t('Puerto Rican', {
				_context: 'nationality',
			}),
			country: t('Puerto Rico', {
				_context: 'country',
			}),
		},
		{
			code: 'ps',
			nationality: t('Palestinian', {
				_context: 'nationality',
			}),
			country: t('Palestine', {
				_context: 'country',
			}),
		},
		{
			code: 'pt',
			nationality: t('Portuguese', {
				_context: 'nationality',
			}),
			country: t('Portugal', {
				_context: 'country',
			}),
		},
		{
			code: 'pw',
			nationality: t('Palauan', {
				_context: 'nationality',
			}),
			country: t('Palau', {
				_context: 'country',
			}),
		},
		{
			code: 'py',
			nationality: t('Paraguayan', {
				_context: 'nationality',
			}),
			country: t('Paraguay', {
				_context: 'country',
			}),
		},
		{
			code: 'qa',
			nationality: t('Qatari', {
				_context: 'nationality',
			}),
			country: t('Qatar', {
				_context: 'country',
			}),
		},
		{
			code: 're',
			nationality: t('Réunion', {
				_context: 'nationality',
			}),
			country: t('Réunion', {
				_context: 'country',
			}),
		},
		{
			code: 'ro',
			nationality: t('Romanian', {
				_context: 'nationality',
			}),
			country: t('Romania', {
				_context: 'country',
			}),
		},
		{
			code: 'rs',
			nationality: t('Serbian', {
				_context: 'nationality',
			}),
			country: t('Serbia', {
				_context: 'country',
			}),
		},
		{
			code: 'ru',
			nationality: t('Russian', {
				_context: 'nationality',
			}),
			country: t('Russian Federation', {
				_context: 'country',
			}),
		},
		{
			code: 'rw',
			nationality: t('Rwandan', {
				_context: 'nationality',
			}),
			country: t('Rwanda', {
				_context: 'country',
			}),
		},
		{
			code: 'sa',
			nationality: t('Saudi Arabian', {
				_context: 'nationality',
			}),
			country: t('Saudi Arabia', {
				_context: 'country',
			}),
		},
		{
			code: 'sb',
			nationality: t('Solomon Islander', {
				_context: 'nationality',
			}),
			country: t('Solomon Islands', {
				_context: 'country',
			}),
		},
		{
			code: 'sc',
			nationality: t('Seychellois', {
				_context: 'nationality',
			}),
			country: t('Seychelles', {
				_context: 'country',
			}),
		},
		{
			code: 'sd',
			nationality: t('Sudanese', {
				_context: 'nationality',
			}),
			country: t('Sudan', {
				_context: 'country',
			}),
		},
		{
			code: 'se',
			nationality: t('Swedish', {
				_context: 'nationality',
			}),
			country: t('Sweden', {
				_context: 'country',
			}),
		},
		{
			code: 'sg',
			nationality: t('Singaporean', {
				_context: 'nationality',
			}),
			country: t('Singapore', {
				_context: 'country',
			}),
		},
		{
			code: 'sh',
			nationality: t('Saint Helenian', {
				_context: 'nationality',
			}),
			country: t('Saint Helena Ascension and Tristan da Cunha', {
				_context: 'country',
			}),
		},
		{
			code: 'si',
			nationality: t('Slovenian', {
				_context: 'nationality',
			}),
			country: t('Slovenia', {
				_context: 'country',
			}),
		},
		{
			code: 'sj',
			nationality: t('Svalbard and Jan Mayen Islands', {
				_context: 'nationality',
			}),
			country: t('Svalbard and Jan Mayen', {
				_context: 'country',
			}),
		},
		{
			code: 'sk',
			nationality: t('Slovakian', {
				_context: 'nationality',
			}),
			country: t('Slovakia', {
				_context: 'country',
			}),
		},
		{
			code: 'sl',
			nationality: t('Sierra Leonean', {
				_context: 'nationality',
			}),
			country: t('Sierra Leone', {
				_context: 'country',
			}),
		},
		{
			code: 'sm',
			nationality: t('Sanmarinese', {
				_context: 'nationality',
			}),
			country: t('San Marino', {
				_context: 'country',
			}),
		},
		{
			code: 'sn',
			nationality: t('Senegalese', {
				_context: 'nationality',
			}),
			country: t('Senegal', {
				_context: 'country',
			}),
		},
		{
			code: 'so',
			nationality: t('Somali', {
				_context: 'nationality',
			}),
			country: t('Somalia', {
				_context: 'country',
			}),
		},
		{
			code: 'sr',
			nationality: t('Surinamer', {
				_context: 'nationality',
			}),
			country: t('Suriname', {
				_context: 'country',
			}),
		},
		{
			code: 'ss',
			nationality: t('Sudanese', {
				_context: 'nationality',
			}),
			country: t('South Sudan', {
				_context: 'country',
			}),
		},
		{
			code: 'st',
			nationality: t('São Tomean', {
				_context: 'nationality',
			}),
			country: t('Sao Tome and Principe', {
				_context: 'country',
			}),
		},
		{
			code: 'sv',
			nationality: t('Salvadorean', {
				_context: 'nationality',
			}),
			country: t('El Salvador', {
				_context: 'country',
			}),
		},
		{
			code: 'sx',
			nationality: t('Saint Martin (Netherlands)', {
				_context: 'nationality',
			}),
			country: t('Sint Maarten (Dutch part)', {
				_context: 'country',
			}),
		},
		{
			code: 'sy',
			nationality: t('Syrian', {
				_context: 'nationality',
			}),
			country: t('Syrian Arab Republic', {
				_context: 'country',
			}),
		},
		{
			code: 'sz',
			nationality: t('Swazi', {
				_context: 'nationality',
			}),
			country: t('Eswatini', {
				_context: 'country',
			}),
		},
		{
			code: 'tc',
			nationality: t('Turks and Caicos Islander', {
				_context: 'nationality',
			}),
			country: t('Turks and Caicos Islands', {
				_context: 'country',
			}),
		},
		{
			code: 'td',
			nationality: t('Chadian', {
				_context: 'nationality',
			}),
			country: t('Chad', {
				_context: 'country',
			}),
		},
		{
			code: 'tf',
			nationality: t('French Southern Territories', {
				_context: 'nationality',
			}),
			country: t('French Southern Territories', {
				_context: 'country',
			}),
		},
		{
			code: 'tg',
			nationality: t('Togolese', {
				_context: 'nationality',
			}),
			country: t('Togo', {
				_context: 'country',
			}),
		},
		{
			code: 'th',
			nationality: t('Thai', {
				_context: 'nationality',
			}),
			country: t('Thailand', {
				_context: 'country',
			}),
		},
		{
			code: 'tj',
			nationality: t('Tajikistani', {
				_context: 'nationality',
			}),
			country: t('Tajikistan', {
				_context: 'country',
			}),
		},
		{
			code: 'tk',
			nationality: t('Tokelauan', {
				_context: 'nationality',
			}),
			country: t('Tokelau', {
				_context: 'country',
			}),
		},
		{
			code: 'tl',
			nationality: t('Timorese', {
				_context: 'nationality',
			}),
			country: t('Timor-Leste', {
				_context: 'country',
			}),
		},
		{
			code: 'tm',
			nationality: t('Turkmen', {
				_context: 'nationality',
			}),
			country: t('Turkmenistan', {
				_context: 'country',
			}),
		},
		{
			code: 'tn',
			nationality: t('Tunisian', {
				_context: 'nationality',
			}),
			country: t('Tunisia', {
				_context: 'country',
			}),
		},
		{
			code: 'to',
			nationality: t('Tongan', {
				_context: 'nationality',
			}),
			country: t('Tonga', {
				_context: 'country',
			}),
		},
		{
			code: 'tr',
			nationality: t('Turkish', {
				_context: 'nationality',
			}),
			country: t('Turkey', {
				_context: 'country',
			}),
		},
		{
			code: 'tt',
			nationality: t('Trinidadian', {
				_context: 'nationality',
			}),
			country: t('Trinidad and Tobago', {
				_context: 'country',
			}),
		},
		{
			code: 'tv',
			nationality: t('Tuvaluan', {
				_context: 'nationality',
			}),
			country: t('Tuvalu', {
				_context: 'country',
			}),
		},
		{
			code: 'tw',
			nationality: t('Taiwanese', {
				_context: 'nationality',
			}),
			country: t('Taiwan', {
				_context: 'country',
			}),
		},
		{
			code: 'tz',
			nationality: t('Tanzanian', {
				_context: 'nationality',
			}),
			country: t('Tanzania', {
				_context: 'country',
			}),
		},
		{
			code: 'ua',
			nationality: t('Ukrainian', {
				_context: 'nationality',
			}),
			country: t('Ukraine', {
				_context: 'country',
			}),
		},
		{
			code: 'ug',
			nationality: t('Ugandan', {
				_context: 'nationality',
			}),
			country: t('Uganda', {
				_context: 'country',
			}),
		},
		{
			code: 'um',
			nationality: t('United States Minor Outlying Islands', {
				_context: 'nationality',
			}),
			country: t('United States Minor Outlying Islands', {
				_context: 'country',
			}),
		},
		{
			code: 'us',
			nationality: t('American', {
				_context: 'nationality',
			}),
			country: t('United States of America', {
				_context: 'country',
			}),
		},
		{
			code: 'uy',
			nationality: t('Uruguayan', {
				_context: 'nationality',
			}),
			country: t('Uruguay', {
				_context: 'country',
			}),
		},
		{
			code: 'uz',
			nationality: t('Uzbekistani', {
				_context: 'nationality',
			}),
			country: t('Uzbekistan', {
				_context: 'country',
			}),
		},
		{
			code: 'va',
			nationality: t('Vatican', {
				_context: 'nationality',
			}),
			country: t('Holy See', {
				_context: 'country',
			}),
		},
		{
			code: 'vc',
			nationality: t('Saint Vincentian', {
				_context: 'nationality',
			}),
			country: t('Saint Vincent and the Grenadines', {
				_context: 'country',
			}),
		},
		{
			code: 've',
			nationality: t('Venezuelan', {
				_context: 'nationality',
			}),
			country: t('Venezuela', {
				_context: 'country',
			}),
		},
		{
			code: 'vg',
			nationality: t('Virgin Islander', {
				_context: 'nationality',
			}),
			country: t('Virgin Islands British', {
				_context: 'country',
			}),
		},
		{
			code: 'vi',
			nationality: t('Virgin Islander', {
				_context: 'nationality',
			}),
			country: t('Virgin Islands U.S.', {
				_context: 'country',
			}),
		},
		{
			code: 'vn',
			nationality: t('Vietnamese', {
				_context: 'nationality',
			}),
			country: t('Viet Nam', {
				_context: 'country',
			}),
		},
		{
			code: 'vu',
			nationality: t('Ni-Vanuatu', {
				_context: 'nationality',
			}),
			country: t('Vanuatu', {
				_context: 'country',
			}),
		},
		{
			code: 'wf',
			nationality: t('Wallisian', {
				_context: 'nationality',
			}),
			country: t('Wallis and Futuna', {
				_context: 'country',
			}),
		},
		{
			code: 'ws',
			nationality: t('Samoan', {
				_context: 'nationality',
			}),
			country: t('Samoa', {
				_context: 'country',
			}),
		},
		{
			code: 'ye',
			nationality: t('Yemeni', {
				_context: 'nationality',
			}),
			country: t('Yemen', {
				_context: 'country',
			}),
		},
		{
			code: 'yt',
			nationality: t('Mahoran', {
				_context: 'nationality',
			}),
			country: t('Mayotte', {
				_context: 'country',
			}),
		},
		{
			code: 'za',
			nationality: t('South African', {
				_context: 'nationality',
			}),
			country: t('South Africa', {
				_context: 'country',
			}),
		},
		{
			code: 'zm',
			nationality: t('Zambian', {
				_context: 'nationality',
			}),
			country: t('Zambia', {
				_context: 'country',
			}),
		},
		{
			code: 'zw',
			nationality: t('Zimbabwean', {
				_context: 'nationality',
			}),
			country: t('Zimbabwe', {
				_context: 'country',
			}),
		},
	];
}

export function findCountryByCode(code: string): CountryCode {
	return ISO3166Alpha2Codes().find((c) => c.code === code.toLocaleLowerCase());
}
