import { useContext, useState } from 'react';
import { t } from '@transifex/native';

import { Features } from 'pkg/models/group';

import * as endpoints from 'pkg/api/endpoints/auto';
import { useQueryState } from 'pkg/hooks/query-state';
import { FilterGroups, useFilters } from 'pkg/filters/use_filters';
import * as models from 'pkg/api/models';
import { useCollection } from 'pkg/api/use_collection';
import { FilterOperator } from 'pkg/filters';
import { useCurrentGroup, useCurrentOrganization } from 'pkg/identity';

import { AddOrderContext } from 'routes/payments/orders/create';
import {
	usersFilterConfig,
	getFilters,
} from 'routes/organization/contacts/utils';

import Pagination from 'components/pagination';
import StepModal, { Step } from 'components/step-modal';

import ContactTable from 'components/invoices/add_form/contacts/Table';
import * as ActionBar from 'components/layout/ActionBar';

interface ContactModalProps {
	hideModal: () => void;
}

const DISPLAY_COUNT_PER_PAGE = 30;

const ContactModal: React.FC<React.PropsWithChildren<ContactModalProps>> = ({
	hideModal,
}) => {
	const OrderContext = useContext(AddOrderContext);

	const qs = useQueryState();
	const org = useCurrentOrganization();
	const group = useCurrentGroup();

	const filterGroups: FilterGroups = {
		...getFilters([
			usersFilterConfig().groups,
			models.group.hasFeature(group, Features.Payments) &&
				usersFilterConfig().products,
			models.group.hasFeature(group, Features.Payments) &&
				usersFilterConfig().registrations,
			models.group.hasFeature(group, Features.Payments) &&
				usersFilterConfig().product_status,
			usersFilterConfig().group_role,
			models.group.hasFeature(group, Features.Payments) &&
				usersFilterConfig().product_valid,
			models.group.hasFeature(group, Features.Payments) &&
				usersFilterConfig().product_created,
			usersFilterConfig().birth_date,
			usersFilterConfig().account,
			usersFilterConfig().parent_connection,
			usersFilterConfig().child_connection,
			usersFilterConfig().archived,
			models.group.isLOKActive(org) && usersFilterConfig().lok_data,
		]),
	};

	const filters = useFilters({
		filterBarMobileTrigger: true,
		groups: filterGroups,
	});

	const [showOnlySelected, setShowOnlySelected] = useState(false);
	const [sort, setSort] = useState<{ by: string; order: 'asc' | 'desc' }>({
		by: 'id',
		order: 'desc',
	});

	const initialState = OrderContext.formState.assignedContacts.map(
		(user) => user.id
	);

	const {
		records: users,
		isLoading,
		pagination,
		selection,
	} = useCollection<models.user.User>(
		endpoints.Organizations.ListUsers(org.id),
		{
			queryParams: filters.queryParam,
			count: DISPLAY_COUNT_PER_PAGE,
			defaultSelectedRecords: initialState,
			showOnlySelected,
		}
	);

	const handleNext = async () => {
		OrderContext.setFormState({
			assignedContacts: selection.selectedRecords.map((id) => {
				const existing = OrderContext.formState.assignedContacts.find(
					(user) => user.id === id
				);

				if (existing) {
					return existing;
				}

				return {
					id,
				} as models.user.User;
			}),
		});
	};

	const handleSortClick = (field: string, sortOrder: 'asc' | 'desc') => {
		setSort({ by: field, order: sortOrder });
	};

	const handleClose = () => {
		qs.flush(true);

		hideModal();
	};

	return (
		<StepModal onClose={handleClose} wide>
			<Step title={t('Select contact')} skipBody onNext={handleNext}>
				<ActionBar.IntegratedFilterBar
					fullWidth
					filters={filters}
					searchFilter={{
						type: 'text',
						operator: FilterOperator.Contains,
						property: 'name',
					}}
				/>
				<ActionBar.BulkActions
					numSelected={selection.selectedRecords.length}
					showSelected={showOnlySelected}
					setShowSelected={setShowOnlySelected}
				/>
				<ContactTable
					users={users}
					sort={sort}
					isLoading={isLoading}
					onSort={handleSortClick}
					selection={selection}
				/>
				<Pagination {...pagination} />
			</Step>
		</StepModal>
	);
};

export default ContactModal;
