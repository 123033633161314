import { t } from '@transifex/native';
import { MouseEvent } from 'react';

import spacing from 'pkg/config/spacing';

import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCollection } from 'pkg/api/use_collection';
import {
	ConnectionStatus,
	ExternalServiceProvider,
	useExternalServiceConnections,
} from 'pkg/api/models/external_service';
import { useCurrentUser } from 'pkg/identity';

import Icon from 'components/icon';
import Avatar from 'components/avatar';

import * as UserCard from 'components/user/UserCard';
import Column from 'components/layout/column';
import Row from 'components/layout/row';
import InfoBox from 'components/form/info-box';
import { Spinner } from 'components/loaders/spinner';

import Button from 'design/button';

interface ListSelfProps {
	onClick: (event: MouseEvent<HTMLDivElement>) => void;
}

function ListSelf({ onClick }: ListSelfProps): JSX.Element {
	const self = useCurrentUser();

	const { isLoading, records } = useExternalServiceConnections({
		userId: self.id,
		serviceProvider: ExternalServiceProvider.Wingfield,
	});

	const hasActiveConnections =
		records.filter(
			(record: models.externalService.ExternalService) =>
				record.connectionStatus === ConnectionStatus.Active
		)?.length > 0;

	const hasPendingConnections =
		records.filter(
			(record: models.externalService.ExternalService) =>
				record.connectionStatus === ConnectionStatus.Pending
		)?.length > 0;

	let handleClick = onClick;
	let label = t('View');
	let status = 'active';

	if (!hasActiveConnections && !hasPendingConnections) {
		label = t('Link account', { _context: 'wingfield video modal' });
		status = 'not-connected';
	}

	if (isLoading) {
		handleClick = null;
	}

	if (hasPendingConnections) {
		label = t('Pending');
		status = 'pending';
	}

	return (
		<UserCard.Base
			clickable
			key={self.id}
			data-user-id={self.id}
			data-connection-status={status}
			onClick={handleClick}>
			<UserCard.User>
				<Avatar user={self} />
				<strong>{models.user.fullName(self)}</strong>
			</UserCard.User>
			<UserCard.Actions>
				{isLoading ? (
					<Spinner size="24px" />
				) : (
					<Row
						columns="1fr auto"
						align="center"
						justify="center"
						spacing={spacing._2}>
						<strong>{label}</strong>
						<Icon name="chevron" size={1.5} />
					</Row>
				)}
			</UserCard.Actions>
		</UserCard.Base>
	);
}

interface ListUsersProps {
	currentUserId: number;
	setSelectedUserId: (selectedUserId: number) => void;
	setConnectionStatus: (connectionStatus: string) => void;
}

export default function ListUsers({
	currentUserId,
	setSelectedUserId,
	setConnectionStatus,
}: ListUsersProps): JSX.Element {
	const { isLoading, records, pagination } = useCollection<models.user.User>(
		endpoints.Users.ByExternalSource(),
		{
			queryParams: new URLSearchParams({
				source: 'wingfield',
			}),
			showAllResults: true,
		}
	);

	const handleItemClick = (event: MouseEvent<HTMLDivElement>) => {
		setSelectedUserId(Number.parseInt(event.currentTarget.dataset.userId, 10));

		if (event.currentTarget.hasAttribute('data-connection-status')) {
			setConnectionStatus(event.currentTarget.dataset.connectionStatus);
		} else {
			setConnectionStatus('');
		}
	};

	return (
		<Column spacing={spacing._7}>
			<InfoBox>
				{t(
					"Select a user with a linked Wingfield account, or create a connection to your Wingfield account if you haven't already done so.",
					{ _context: 'wingfield video modal' }
				)}
			</InfoBox>
			<Column>
				{!isLoading && <ListSelf onClick={handleItemClick} />}
				{records
					.filter((user: models.user.User) => user.id !== currentUserId)
					.map((user: models.user.User) => (
						<UserCard.Base
							clickable
							key={user.id}
							data-user-id={user.id}
							onClick={handleItemClick}>
							<UserCard.User>
								<Avatar user={user} />
								<strong>{models.user.fullName(user)}</strong>
							</UserCard.User>
							<UserCard.Actions>
								<Row
									columns="1fr auto"
									align="center"
									justify="center"
									spacing={spacing._2}>
									<strong>{t('View')}</strong>
									<Icon name="chevron" size={1.5} />
								</Row>
							</UserCard.Actions>
						</UserCard.Base>
					))}
			</Column>
			{isLoading && <Spinner />}
			{pagination.hasNext && (
				<Button onClick={pagination.fetchNext} busy={isLoading}>
					{t('Fetch more')}
				</Button>
			)}
		</Column>
	);
}
