import { ReactNode } from 'react';

export const symbol_newsmode: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h680q24 0 42 18t18 42v600q0 24-18 42t-42 18H140Zm0-60h680v-600H140v600Zm139-106h402q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T681-346H279q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T279-286Zm0-166h95q12.75 0 21.375-8.625T404-482v-162q0-12.75-8.625-21.375T374-674h-95q-12.75 0-21.375 8.625T249-644v162q0 12.75 8.625 21.375T279-452Zm259 0h143q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T681-512H538q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T538-452Zm0-162h143q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T681-674H538q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T538-614ZM140-180v-600 600Z"
		/>
	</svg>
);
