import { Component } from 'react';
import styled from 'styled-components';

import * as palette from 'pkg/config/palette';

import rgba from 'pkg/rgba';

import TextField from 'components/form/TextInput';

const Item = styled.div`
	flex: 0 0 auto;
	display: flex;
	justify-content: space-between;
	padding: 5px 0;
`;

const Label = styled.div`
	flex: 0 0 auto;
	display: flex;
	align-items: center;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	width: 40%;
`;

const Button = styled.button`
	background: rgb(233, 233, 233);
	width: 40px;
	height: 100%;
	font-weight: var(--font-weight-bold);
	font-size: 24px;
	color: rgb(30, 30, 30);
	border: none;
	display: inline-block;
	cursor: pointer;
	box-shadow:
		transparent 0 0 0 2px,
		inset ${rgba(palette.textField.borderColor)} 0 -1px 0 0;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-bottom: 1px;

	&:active {
		background: rgb(200, 200, 200);
	}
`;

const InputWrapper = styled.div`
	flex: 1 1 auto;
	margin: 0;
	display: flex;
	align-items: stretch;

	${TextField} {
		border-left: none;
		border-right: none;
		text-align: center;
	}
`;

export default class extends Component {
	shouldComponentUpdate(nextProps) {
		if (nextProps.value !== this.props.value) return true;

		return false;
	}

	checkConstraints(val) {
		if (parseFloat(val) > 10) {
			val = 10;
		}

		if (parseFloat(val) < 0) {
			val = 0;
		}

		if (isNaN(val)) {
			val = this.props.value;
		}

		if (val.length === 0) {
			val = 0;
		}

		return parseFloat(val);
	}

	increase = (e) => {
		e.preventDefault();
		let val = (parseFloat(this.props.value) + 0.1).toFixed(1);
		val = this.checkConstraints(val);
		this.props.onInput(this.props.item.id, val);
	};

	decrease = (e) => {
		e.preventDefault();
		let val = (parseFloat(this.props.value) - 0.1).toFixed(1);
		val = this.checkConstraints(val);
		this.props.onInput(this.props.item.id, val);
	};

	setValue = (e) => {
		let val = this.checkConstraints(e.target.value);
		this.props.onInput(this.props.item.id, val);
	};

	render() {
		return (
			<Item>
				<Label>{this.props.item.name}</Label>

				<InputWrapper>
					<Button primary type="button" onClick={this.decrease} tabIndex="-1">
						&ndash;
					</Button>
					<TextField
						type="text"
						onChange={this.setValue}
						value={this.props.value}
					/>
					<Button
						type="button"
						onClick={this.increase}
						tabIndex="-1"
						data-testid="assassment_modal.rating_item_add_button">
						+
					</Button>
				</InputWrapper>
			</Item>
		);
	}
}
