import { ReactNode } from 'react';

export const symbol_print_lock: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M685-120q-14 0-24.5-10.5T650-155v-120q0-14 11.5-24.5T690-310v-45q0-30.938 22.044-52.969 22.045-22.031 53-22.031Q796-430 818-407.969q22 22.031 22 52.969v45q17 0 28.5 10.5T880-275v120q0 14-10.5 24.5T845-120H685Zm35-190h90v-44.912Q810-374 797.088-387q-12.913-13-32-13Q746-400 733-387.062 720-374.125 720-355v45ZM140-588h680-680Zm-60 46q0-45.05 30.5-75.525Q141-648 186-648h588q45.05 0 75.525 30.475Q880-587.05 880-542v67q0 12.75-8.625 21.375T850-445q-13 0-21.5-8.625T820-475v-67q0-19.55-13.225-32.775Q793.55-588 774-588H186q-19.55 0-32.775 13.225Q140-561.55 140-542v186h102v-16q0-24.75 17.625-42.375T302-432h267q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T569-372H302v192h267q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T569-120H302q-24.75 0-42.375-17.625T242-180v-116H140q-24.75 0-42.375-17.625T80-356v-186Zm162-106v-132q0-24.75 17.625-42.375T302-840h356q24.75 0 42.375 17.625T718-780v132h-60v-132H302v132h-60Z"
		/>
	</svg>
);
