import { ReactNode } from 'react';

export const symbol_page_info: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M710-140q-63 0-106.5-43.5T560-290q0-63 43.5-106.5T710-440q63 0 106.5 43.5T860-290q0 63-43.5 106.5T710-140Zm0-60q38 0 64-26t26-64q0-38-26-64t-64-26q-38 0-64 26t-26 64q0 38 26 64t64 26Zm-260-60H150q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-320h300q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T450-260ZM250-520q-63 0-106.5-43.5T100-670q0-63 43.5-106.5T250-820q63 0 106.5 43.5T400-670q0 63-43.5 106.5T250-520Zm0-60q38 0 64-26t26-64q0-38-26-64t-64-26q-38 0-64 26t-26 64q0 38 26 64t64 26Zm560-60H510q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T510-700h300q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-640ZM710-290ZM250-670Z"
		/>
	</svg>
);
