import { ReactNode } from 'react';

export const symbol_move_up: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M320-160q-117 0-198.5-81.5T40-440q0-118 83.5-197.5T323-711l-64-65q-9-9-9-21.5t9-21.5q9-9 21-9t21 9l118 118q9 9 9 21t-9 21L302-542q-9 9-21.5 9t-21.5-9q-9-9-9.5-21t8.5-21l68-68q-92-2-159 59.5T100-440q0 92 64 156t156 64h90q13 0 21.5 8.5T440-190q0 13-8.5 21.5T410-160h-90Zm230-360q-13 0-21.5-8.5T520-550v-220q0-13 8.5-21.5T550-800h300q13 0 21.5 8.5T880-770v220q0 13-8.5 21.5T850-520H550Zm0 360q-13 0-21.5-8.5T520-190v-220q0-13 8.5-21.5T550-440h300q13 0 21.5 8.5T880-410v220q0 13-8.5 21.5T850-160H550Zm30-60h240v-160H580v160Z"
		/>
	</svg>
);
