import { useT } from '@transifex/react';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import { useCollection } from 'pkg/api/use_collection';
import * as numbers from 'pkg/numbers';

import * as Card from 'components/Card';
import Section from 'components/section';

import PhysicalStrainBar from 'components/physical-strain/Bar';

interface PhysicalStrainProps {
	user: models.user.User;
}

export default function PhysicalStrain({
	user,
}: PhysicalStrainProps): JSX.Element {
	const t = useT();

	const { records: userActivities } =
		useCollection<models.userActivity.UserActivity>(
			endpoints.UserActivities.UserIndex(user.id)
		);

	const physicalStrainValues: number[] = userActivities.map(
		(ua) => ua.physicalStrain
	);

	return (
		<Section title={t('Physical strain')} icon="monitor_heart">
			<Card.Base $noBorder>
				<Card.Body>
					<PhysicalStrainBar
						hideLabels
						teamStrainLevel={0}
						userStrainLevel={numbers.average(physicalStrainValues)}
					/>
				</Card.Body>
			</Card.Base>
		</Section>
	);
}
