import { t } from '@transifex/native';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import DateTime from 'pkg/datetime';
import * as selectors from 'pkg/selectors';
import * as routes from 'pkg/router/routes';

import Result from 'containers/match/Result';

import * as Card from 'components/Card';
import SectionTitle from 'components/SectionTitle';
import Icon from 'components/icon';

import Column from 'components/layout/column';
import {
	ResultCardBase as ResultCardBaseImport,
	BottomCardBody,
	BottomCardSection,
} from 'components/match/ResultStyles';

import Button from 'design/button';

const ResultCardBase = styled(ResultCardBaseImport)`
	margin-bottom: 0;
`;

const LastGameResultContainer = ({ groupId }) => {
	const season = useSelector((state) =>
		selectors.groups.getCurrentSeason(state, { groupId })
	);

	const match = useSelector((state) =>
		selectors.matches.getLastMatch(state, {
			groupId,
			before: DateTime.now().getUnixTimestamp(),
			after: season.start,
		})
	);

	const showStats =
		match.goalCount !== null && match.opponentGoalCount !== null;

	if (!match.id) {
		return null;
	}

	return (
		<Column>
			<SectionTitle icon="calendar_clock">
				{t(`Match result`)}{' '}
				{showStats && (
					<Button
						inline
						icon="chevron"
						iconPosition="right"
						noPadding
						href={routes.Match.View(match.eventId, match.id, 'summary')}
						label={t(`View match`)}
					/>
				)}
			</SectionTitle>
			<ResultCardBase $noBorder key="LastGameResultCardBase">
				<Result matchId={match.id} />

				<Card.Divider />

				<BottomCardBody key="bottom-row">
					<BottomCardSection>
						<Icon name="clock" />
						{new DateTime(
							new Date(match.get('startsAt') * 1000)
						).toLocaleDateString({
							weekday: 'short',
							month: 'short',
							day: 'numeric',
						})}
					</BottomCardSection>
					<BottomCardSection>
						<Icon name="location" />
						{match.get('location')}
					</BottomCardSection>
				</BottomCardBody>
			</ResultCardBase>
		</Column>
	);
};

export default LastGameResultContainer;
