import { ReactNode } from 'react';

export const symbol_spatial_audio: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M664-664q-46-46-73-103.5T559-888q-1-13 8-22.5t22-9.5q13 0 21.5 9t10.5 22q5 52 27 98.5t59 83.5q37 37 83.5 59t98.5 27q13 2 22 10.5t9 21.5q0 13-9.5 21.5T888-560q-63-5-120.5-31.5T664-664Zm128-127q-20-20-33-45.5T741-890q-2-12 6.5-21t21.5-9q12 0 21 8.5t12 20.5q4 16 12 30.5t20 26.5q12 12 26.5 20.5T892-801q12 3 20.5 12t8.5 21q0 13-9 21.5t-21 6.5q-28-5-53.5-18T792-791ZM400-441q-66 0-108-42t-42-108q0-66 42-108t108-42q66 0 108 42t42 108q0 66-42 108t-108 42ZM80-180v-34q0-38 19-64.5t49-41.5q51-26 120.5-43T400-380q62 0 131 17t120 43q30 15 49.5 41.5T720-214v34q0 25-17.5 42.5T660-120H140q-25 0-42.5-17.5T80-180Zm60 0h520v-34q0-16-8.5-29.5T627-266q-48-27-109-40.5T400-320q-57 0-118.5 14.5T172-266q-14 7-23 21.5t-9 30.5v34Zm260-321q39 0 64.5-25.5T490-591q0-39-25.5-64.5T400-681q-39 0-64.5 25.5T310-591q0 39 25.5 64.5T400-501Zm0-90Zm0 411Z"
		/>
	</svg>
);
