import { Record } from 'immutable';
import { schema } from 'normalizr';

import Exercise from 'pkg/models/exercise';
import User from 'pkg/models/user';

const normalizrSchema = new schema.Entity('sessionItems', {
	exercise: Exercise.normalizr(),
	users: [User.normalizr()],
});

interface ISessionItem {
	id: number;
	trainingSessionBlockId: number;
	exerciseId: number;
	order: number;
	description: string;
	users: User[];
}

const sessionItemProps: ISessionItem = {
	id: 0,
	trainingSessionBlockId: 0,
	exerciseId: 0,
	order: 0,
	description: '',
	users: [],
};

class SessionItem
	extends Record(sessionItemProps, 'SessionItem')
	implements ISessionItem
{
	static normalizr(): schema.Entity {
		return normalizrSchema;
	}
}

export default SessionItem;
