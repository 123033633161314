import { ReactNode } from 'react';

export const symbol_privacy_tip: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480.175-284q12.825 0 21.325-8.625T510-314v-197q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T450-511v197q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625ZM480-607q14.45 0 24.225-9.775Q514-626.55 514-641q0-14.45-9.775-24.225Q494.45-675 480-675q-14.45 0-24.225 9.775Q446-655.45 446-641q0 14.45 9.775 24.225Q465.55-607 480-607Zm0 523q-5.32 0-9.88-1-4.56-1-9.12-3-139-47-220-168.5t-81-266.606V-719q0-19.257 10.875-34.662Q181.75-769.068 199-776l260-97q11-4 21-4t21 4l260 97q17.25 6.932 28.125 22.338Q800-738.257 800-719v195.894Q800-378 719-256.5T499-88q-4.56 2-9.12 3T480-84Zm0-59q115-38 187.5-143.5T740-523v-196l-260-98-260 98v196q0 131 72.5 236.5T480-143Zm0-337Z"
		/>
	</svg>
);
