import { ReactNode } from 'react';

export const symbol_currency_pound: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M270-120q-13 0-21.5-8.5T240-150v-20q0-7 3-12.5t8-10.5q44-36 73.5-70.5T354-360q0-13-1.5-25t-4.5-25h-78q-13 0-21.5-8.5T240-440q0-13 8.5-21.5T270-470h48q-39-54-53.5-95.5T250-650q0-88 61-149t149-61q57 0 104.5 25.5T641-765q6 10 2 21t-15 16q-11 5-22.5 1.5T586-740q-21-28-54.5-44T460-800q-62 0-106 43.5T310-650q0 39 16 76.5T390-470h140q13 0 21.5 8.5T560-440q0 13-8.5 21.5T530-410H409q3 13 4 26t1 24q0 53-23 99t-58 81h227q36 0 65-17.5t41-58.5q3-11 10.5-18t18.5-7q12 0 20.5 8.5T724-252q0 60-56 96t-108 36H270Z"
		/>
	</svg>
);
