import { ReactNode } from 'react';

export const symbol_format_quote: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m275-287 67-115q-5 1-11 1.5t-11 .5q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 21-5.5 41.184Q469-498.632 458-480L329-255q-4 8-11.5 11.5t-15.991 3.5q-17.755 0-26.632-15.5Q266-271 275-287Zm360 0 67-115q-5 1-11 1.5t-11 .5q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 21-5.5 41.184Q829-498.632 818-480L689-255q-4 8-11.5 11.5t-15.991 3.5q-17.755 0-26.632-15.5Q626-271 635-287ZM319.765-480Q353-480 376.5-503.265q23.5-23.264 23.5-56.5Q400-593 376.735-616.5q-23.264-23.5-56.5-23.5Q287-640 263.5-616.735q-23.5 23.264-23.5 56.5Q240-527 263.265-503.5q23.264 23.5 56.5 23.5Zm360 0Q713-480 736.5-503.265q23.5-23.264 23.5-56.5Q760-593 736.735-616.5q-23.264-23.5-56.5-23.5Q647-640 623.5-616.735q-23.5 23.264-23.5 56.5Q600-527 623.265-503.5q23.264 23.5 56.5 23.5Zm.235-80Zm-360 0Z"
		/>
	</svg>
);
