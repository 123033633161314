// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rzSImdYhLSYB0L49k0Zo {
	margin-top: var(--spacing-4);
	margin-bottom: var(--spacing-4);
}
`, "",{"version":3,"sources":["webpack://./components/archived-contact/styles.css"],"names":[],"mappings":"AAAA;CACC,4BAA8B;CAA9B,+BAA8B;AAC/B","sourcesContent":[".cta {\n\tmargin-block: var(--spacing-4);\n}\n"],"sourceRoot":""}]);
// Exports
export var cta = `rzSImdYhLSYB0L49k0Zo`;
export default ___CSS_LOADER_EXPORT___;
