import { ReactNode } from 'react';

export const symbol_tools_ladder: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M224-120q-14 0-22-11t-4-25l184-663q3-10 10-15.5t17-5.5q14 0 22.5 11t4.5 25l-26 94h266l31-109q3-10 10.5-15.5T735-840q14 0 22 11t4 25L578-141q-2 10-9.5 15.5T551-120q-14 0-22.5-11t-4.5-25l25-94H283l-31 109q-2 10-10 15.5t-18 5.5Zm131-390h266l39-140H394l-39 140Zm-56 200h267l39-140H338l-39 140Z"
		/>
	</svg>
);
