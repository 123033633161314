import { ReactNode } from 'react';

export const symbol_luggage: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M260-120q-24.75 0-42.375-17.625T200-180v-480q0-24.75 17.625-42.375T260-720h105v-100q0-24.75 17.625-42.375T425-880h110q24.75 0 42.375 17.625T595-820v100h105q24.75 0 42.375 17.625T760-660v480q0 24.75-17.625 42.375T700-120q0 17-11.5 28.5T660-80q-17 0-28.5-11.5T620-120H340q0 17-11.5 28.5T300-80q-17 0-28.5-11.5T260-120Zm0-60h440v-480H260v480Zm134.825-420Q382-600 373.5-591.375T365-570v300q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T425-270v-300q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625Zm170 0Q552-600 543.5-591.375T535-570v300q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T595-270v-300q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625ZM425-720h110v-100H425v100Zm55 300Z"
		/>
	</svg>
);
