import { ReactNode } from 'react';

export const symbol_flight: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-117 376-87q-10 3-18-3.5t-8-16.5q0-9 2-13.5t6-7.5l72-54v-251l-314 93q-14 4-25-4.5T80-367q0-11 4-18t10-11l336-198v-236q0-21 14.5-35.5T480-880q21 0 35.5 14.5T530-830v236l336 198q6 4 10 11t4 18q0 14-11 22.5t-25 4.5l-314-93v251l72 54q4 3 6 7.5t2 13.5q0 10-8 16.5T584-87l-104-30Z"
		/>
	</svg>
);
