import { ReactNode } from 'react';

export const symbol_sleep_score: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M856-617q-13 0-21.5-8.5T824-647q-5-33-19-62.5T768-762l-40 66q-9 16-20 35t-24 32q-12 12-28.5 12T627-629q-12-12-11.5-28t12.5-28q13-13 31.5-24.5T694-730l67-39q-24-23-54.5-38T641-826q-11-2-18-10.5t-7-20.5q0-12 8-21.5t20-7.5q94 10 161 75t79 159q2 14-6 24.5T856-617ZM483-80q-84 0-157.5-32t-128-86.5Q143-253 111-326.5T79-484q0-129 74.5-234T351-865q11-4 21.5-2t18.5 8q6 5 9.5 15t2.5 24q-3 85 27 161.5T520-522q60 60 137 90.5T819-404q14-1 23.5 2.5T858-391q6 8 8 18t-2 21q-42 122-146.5 197T483-80Zm0-60q100 0 182-57t132-145q-90-8-173-41.5T477-480q-63-63-96-145.5T340-797q-88 48-144.5 130.5T139-484q0 143 100.5 243.5T483-140Zm-6-340Z"
		/>
	</svg>
);
