import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { isIOS } from 'pkg/platform';

import { useDefaultAdslotData } from 'components/adslot';

import initCMP from 'components/adslot/cmp';

function detectInitialCMPLoad() {
	const hasSeenCMP = !!localStorage.getItem('hasSeenCMP');

	if (window.__cmp && isIOS() && !hasSeenCMP) {
		localStorage.setItem('hasSeenCMP', '1');
		window.__cmp('showScreen');
	}
}

export default function AdScripts(): JSX.Element {
	useDefaultAdslotData();

	useComponentDidMount(
		() => {
			initCMP();
			detectInitialCMPLoad();

			const holidScript = document.createElement('script');
			holidScript.src = window.TS.beta
				? 'https://ads.holid.io/auto/staging.360player.com/holid.js'
				: 'https://ads.holid.io/auto/360player.com/holid.js';
			holidScript.id = 'holidjs';
			holidScript.defer = true;
			holidScript.async = true;
			document.body.appendChild(holidScript);
		},
		() => {
			const holidScript = document.querySelector('#holidjs');
			if (!holidScript) {
				return;
			}
			document.body.removeChild(holidScript);
		}
	);

	return null;
}
