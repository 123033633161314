import { ReactNode } from 'react';

export const symbol_battery_status_good: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m638-212 120-120q9-9 21-9t21 9.053q9 9.052 9 21.5Q809-298 800-289L659-148q-9.067 9-21.533 9Q625-139 616-148l-56-57q-9-9-9-21t9-21q9-9 21-9t21 9l36 35Zm-298 72Zm-30 60q-12.75 0-21.375-8.625T280-110v-676q0-12.75 8.625-21.375T310-816h90v-34q0-12.75 8.625-21.375T430-880h100q12.75 0 21.375 8.625T560-850v34h90q12.75 0 21.375 8.625T680-786v298q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T620-488v-268H340v616h169q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q539-97 530.375-88.5T509-80H310Z"
		/>
	</svg>
);
