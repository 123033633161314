import { Linkable } from 'pkg/api/models/linkable';
import { Record } from 'pkg/api/models/record';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { Dateable } from 'pkg/api/models/dateable';

export enum UserAddressTypes {
	Billing = 'billing',
}

export interface UserAddress extends Linkable, Record, Dateable {
	userId: number;
	type: string;
	firstName: string;
	lastName: string;
	companyName: string;
	email: string;
	address: string;
	city: string;
	region: string;
	postalCode: string;
	country: string;
	phoneNumber: string;
}

export function fullName(userAddress: UserAddress): string {
	return `${userAddress.firstName} ${userAddress.lastName}`;
}

export interface UserAddressPayload {
	type: string;
	firstName?: string;
	lastName?: string;
	companyName?: string;
	email?: string;
	address?: string;
	city?: string;
	region?: string;
	postalCode?: string;
	country?: string;
	phoneNumber?: string;
}

export interface UserAddressCreatePayload extends UserAddressPayload {
	userId: number;
}

export const newUserAddress = {
	id: 0,
	userId: 0,
	type: 'billing',
	firstName: '',
	lastName: '',
	companyName: '',
	email: '',
	address: '',
	city: '',
	region: '',
	postalCode: '',
	country: '',
	phoneNumber: '',
	createdAt: 0,
	updatedAt: 0,
	deletedAt: 0,
};

export const create = async (
	payload: UserAddressCreatePayload
): Promise<[Response, UserAddress, models.APIError?]> => {
	let validPayload = true;

	if (!payload.userId || !payload.type) validPayload = false;

	if (!validPayload) {
		return Promise.resolve([new Response(null, { status: 403 }), null]);
	}

	return models.create(endpoints.UserAddress.Create(), payload);
};

export const update = async (
	payload: UserAddressPayload,
	userAddress: UserAddress
): Promise<[Response, UserAddress, models.APIError?]> => {
	const validPayload = payload.type ? true : false;

	if (!validPayload) {
		return Promise.resolve([new Response(null, { status: 403 }), null]);
	}

	return models.update(userAddress, payload);
};

export const destroy = async (userAddress: UserAddress) => {
	return models.destroy(userAddress);
};
