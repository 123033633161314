import PropTypes from 'prop-types';
import { memo } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import DateTime from 'pkg/datetime';

const Time = styled.time`
	white-space: nowrap;
`;

export const formatRelativeDateTimeString = (dateTime, hideTime = false) => {
	const dt = new DateTime(new Date(dateTime * 1000), true);
	const now = new DateTime(new Date(), true);

	const unixTime = dateTime;
	const nowUnixTime = Math.round(Date.now() / 1000);

	const nowDate = now.toDate();
	const dtDate = dt.toDate();

	const sameYear = () => nowDate.getFullYear() === dtDate.getFullYear();

	const sameWeek = () => nowUnixTime - unixTime < 60 * 60 * 24 * 7;

	const sameMonth = () =>
		sameYear() && nowDate.getMonth() === dtDate.getMonth();

	const sameDay = () =>
		sameYear() && sameMonth() && nowDate.getDate() === dtDate.getDate();

	let timeOptions = hideTime || {
		hour: 'numeric',
		minute: 'numeric',
	};

	//Future
	if (unixTime > nowUnixTime) {
		return dt.toLocaleDateString({
			day: 'numeric',
			month: 'short',
			year: 'numeric',
			...timeOptions,
		});
	}

	// Just now
	if (nowUnixTime - unixTime < 60 * 3) {
		return t('Just now');
	}

	// Today
	if (sameDay()) {
		return t('Today {value}', {
			value: dt.toTimeString(),
		});
	}

	// Same week
	if (sameWeek()) {
		return dt.toLocaleDateString({
			weekday: 'long',
			...timeOptions,
		});
	}

	// Same year
	if (sameYear()) {
		return dt.toLocaleDateString({
			weekday: 'short',
			day: 'numeric',
			month: 'short',
			...timeOptions,
		});
	}

	// Another year
	return dt.toLocaleDateString({
		day: 'numeric',
		month: 'short',
		year: 'numeric',
		...timeOptions,
	});
};

/**
 * @type {React.Element<any>}
 */

const RelativeDateTime = memo(
	({ dateTime, hideTime = false, className, prefix, suffix }) => {
		const dt = new DateTime(new Date(dateTime * 1000), true);

		const formattedDateTime = dt.toDate().toISOString();

		return (
			<Time
				className={className}
				dateTime={formattedDateTime}
				title={formattedDateTime}>
				{prefix} {formatRelativeDateTimeString(dateTime, hideTime)} {suffix}
			</Time>
		);
	}
);

RelativeDateTime.propTypes = {
	dateTime: PropTypes.number.isRequired,
	hideTime: PropTypes.bool,
	className: PropTypes.string,
};

export default RelativeDateTime;
