import { ReactNode } from 'react';

export const symbol_file_present: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480.264-220Q539-220 579.5-261.125 620-302.25 620-360v-120q0-8.5-5.75-14.25T600-500q-8.5 0-14.25 5.75T580-480v120q0 42-28.75 71T480-260q-42 0-71-29t-29-71v-220q0-18 11.5-29t28.5-11q18 0 29 11t11 29v180q0 8.5 5.75 14.25T480-380q8.5 0 14.25-5.75T500-400v-180q0-33.6-23.077-56.8-23.078-23.2-56.5-23.2Q387-660 363.5-636.8T340-580v220q0 57.75 41.203 98.875Q422.405-220 480.264-220ZM220-80q-24 0-42-18t-18-42v-680q0-24 18-42t42-18h361l219 219v521q0 24-18 42t-42 18H220Zm0-60h520v-494H584q-12.75 0-21.375-8.625T554-664v-156H220v680Zm0-680v186-186 680-680Z"
		/>
	</svg>
);
