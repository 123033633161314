import { ReactNode } from 'react';

export const symbol_subway: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M80-140v-474q0-82 42.5-144T241-850q56-21 119-25.5t120-4.5q57 0 120.5 4.5T720-850q76 30 118 92t42 144v474q0 24.75-17.625 42.375T820-80H140q-24.75 0-42.375-17.625T80-140Zm205-288v-175h390v175H285Zm335 127q-16.575 0-27.787-11.212Q581-323.425 581-340q0-16.575 11.213-27.788Q603.425-379 620-379t27.787 11.212Q659-356.575 659-340q0 16.575-11.213 27.788Q636.575-301 620-301Zm-280 0q-16.575 0-27.788-11.212Q301-323.425 301-340q0-16.575 11.212-27.788Q323.425-379 340-379q16.575 0 27.788 11.212Q379-356.575 379-340q0 16.575-11.212 27.788Q356.575-301 340-301ZM140-140h680v-474q0-66-31.5-109.5t-93.937-69.375Q648-812 591.172-816t-111-4Q426-820 369.5-816t-103.063 23.125Q204-767 172-723.5T140-614v474Zm288-81h104l74 74q3.864 3.889 8.114 5.444Q618.364-140 623-140q17 0 22.5-15t-5.5-26l-40-40q47 0 83.5-31.5T720-340v-263q0-72-75-94.5T480-720q-106 0-173 22.5T240-603v263q0 60 41 89.5t79 29.5l-40 40q-11 11-4.963 26 6.036 15 22.134 15 4.829 0 9.329-2 4.5-2 7.5-5l74-74Zm-288 81v-474q0-66 32-109.5t94.437-69.375Q313-812 369.572-816t110.5-4Q534-820 591-816t103.563 23.125Q757-767 788.5-723.5T820-614v474H140Z"
		/>
	</svg>
);
