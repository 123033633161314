import { ReactNode } from 'react';

export const symbol_donut_large: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M182-480q0 106 65.5 189.5T410-189q17 3 27 16.5t10 31.5q0 26-19.5 40.5T382-92q-133-33-217.5-142T80-480q0-137 84.5-246T382-868q26-6 45.5 8.5T447-819q0 18-10 31.5T410-771q-97 18-162.5 101.5T182-480Zm581 284q-37 38-82.5 64T582-93q-26 6-46-9t-20-40q0-17 10.5-31t27.5-18q39-9 74-28.5t64-48.5q29-29 48.5-63.5T769-406q4-17 18-27.5t31-10.5q25 0 40 20t9 46q-13 53-39.5 99T763-196Zm0-568q38 37 64.5 83t39.5 99q6 26-9 46t-40 20q-17 0-31-10.5T769-554q-9-40-28.5-74.5T692-692q-29-29-64-48.5T554-769q-17-4-27.5-18T516-818q0-25 20-40t46-9q53 13 98.5 39t82.5 64Z"
		/>
	</svg>
);
