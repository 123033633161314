import { useSelector } from 'react-redux';
import { Fragment } from 'react';

import { PageWidths } from 'pkg/config/sizes';

import * as selectors from 'pkg/selectors';
import * as routes from 'pkg/router/routes';
import { useCurrentOrganization } from 'pkg/identity';

import View from 'containers/training/session/View';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import SmallScreenHeader from 'components/navigation/header/small_screen';
import LargeScreenHeader from 'components/navigation/header/large_screen';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import SmallScreenContent from 'components/layout/SmallScreenContent';
import { useRedirectOnPrint } from 'components/layout/printable';

const Page = ({ sessionId, title, icon }) => {
	const org = useCurrentOrganization();

	const session = useSelector((state) =>
		selectors.sessions.find(state, { sessionId })
	);

	useRedirectOnPrint(routes.TrainingSession.Print(org.id, sessionId));

	return (
		<Fragment>
			<LargeScreen>
				<LargeScreenHeader title={session?.title} icon={icon} />
				<LargeScreenContent.Wrapper>
					<LargeScreenContent.Inner maxWidth={PageWidths.STANDARD}>
						<View sessionId={sessionId} />
					</LargeScreenContent.Inner>
				</LargeScreenContent.Wrapper>
			</LargeScreen>

			<SmallScreen>
				<SmallScreenHeader title={title} />

				<SmallScreenContent>
					<LargeScreenContent.Inner>
						<View sessionId={sessionId} />
					</LargeScreenContent.Inner>
				</SmallScreenContent>
			</SmallScreen>
		</Fragment>
	);
};
export default Page;
