import { Fragment, useState } from 'react';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';

import * as StepModal from 'components/step-modal';

import useGroupSelectTable from 'components/group/use-select-table';

interface GroupConditionModalSelect {
	groupId: number;
	onClose: () => void;
	handleGroupIdSelect: (groupIds: number[]) => Promise<void>;

	previousGroupIds: number[];
}

export default function GroupConditionSelectModal({
	groupId,
	onClose,
	handleGroupIdSelect,
	previousGroupIds,
}: GroupConditionModalSelect): JSX.Element {
	const [groupIds, setGroupIds] = useState<number[]>([]);

	const selectTable = useGroupSelectTable({
		groupId,
		defaultSelectedGroupIds: previousGroupIds,
		onChange: (groups: models.group.Group[]) => {
			setGroupIds(groups.map((group: models.group.Group) => group.id));
		},
	});

	const handleNext = async () => {
		handleGroupIdSelect(groupIds);
	};

	return (
		<Fragment>
			<StepModal.Base onClose={onClose}>
				<StepModal.Step
					skipBody
					title={t('Select groups')}
					nextLabel={t('Confirm')}
					onNext={handleNext}>
					{selectTable.Component}
				</StepModal.Step>
			</StepModal.Base>
		</Fragment>
	);
}
