import { ReactElement } from 'react';
import styled from 'styled-components';

import * as routes from 'pkg/router/routes';
import RouterLink from 'pkg/router/Link';
import * as models from 'pkg/api/models';
import { useCurrentMembership, useCurrentOrganization } from 'pkg/identity';

interface UserLinkProps {
	user: models.user.User;
	// Memberships to check if the target is included in
	memberships: models.membership.Membership[];
	children?: ReactElement | ReactElement[];
}

const Name = styled.span`
	font-weight: var(--font-weight-semibold);
`;

const Link = styled(RouterLink)`
	font-weight: var(--font-weight-semibold);
`;

export default function UserLink({
	user,
	children,
	memberships,
}: UserLinkProps): JSX.Element {
	const activeMembership = useCurrentMembership();
	const org = useCurrentOrganization();

	const targetUserMembership = memberships.find(
		(membership) => membership.userId === user.id
	);

	const targetUser = user;
	const fullName = models.user.fullName(targetUser);

	if (!activeMembership || !targetUser) return null;

	if (!targetUserMembership) {
		return <Name>{children ?? fullName}</Name>;
	}

	// Only admins can view parent profiles
	if (
		models.membership.isParent(targetUserMembership) &&
		!models.membership.isAdminOrStaff(activeMembership)
	) {
		return <Name>{children ?? fullName}</Name>;
	}

	return (
		<Link
			href={routes.User.Profile.Show(
				org.id,
				activeMembership.groupId,
				targetUser.id,
				'overview'
			)}>
			{children ?? fullName}
		</Link>
	);
}
