import styled from 'styled-components';
import { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import { useCollection } from 'pkg/api/use_collection';
import allSettled, { SettledPromise } from 'pkg/allSettled';
import useMixedState from 'pkg/hooks/useMixedState';
import { useEndpoint } from 'pkg/api/use_endpoint';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import {
	VideoPlaylistPermission,
	VideoPlaylistSequencePermissions,
	permissionLabel,
} from 'pkg/api/models/video_playlist';
import * as actions from 'pkg/actions';
import { useComponentWillUnmount } from 'pkg/hooks/useComponentDidMount';
import {
	useCurrentAccount,
	useCurrentGroup,
	useCurrentMembership,
} from 'pkg/identity';

import RelativeDateTime from 'components/RelativeDateTime';
import Icon from 'components/icon';
import * as StepModal from 'components/step-modal';

import Pagination from 'components/pagination/index';
import * as Input from 'components/form/inputs';
import InfoBox from 'components/form/info-box';

import * as ContextMenu from 'design/context_menu';
import Button from 'design/button';
import * as Table from 'design/table';

const PlaylistRow = styled.div`
	display: grid;
	grid-template-rows: repeat(2, auto);
	gap: var(--spacing-2);
	font-size: var(--font-size-sm);

	span {
		font-weight: var(--font-weight-semibold);
	}
`;

const Actions = styled.div`
	padding: var(--spacing-3);
	text-align: right;
`;

const Wrapper = styled.div`
	display: grid;
	grid-auto-flow: row;
	gap: var(--spacing-6);
`;

const ShareWrapper = styled.div`
	display: grid;
	grid-template-rows: repeat(2, auto);
	place-items: start;
	place-content: start;
	gap: var(--spacing-2);
	row-gap: var(--spacing-5);
`;

const Description = styled.div`
	font-size: var(--font-size-sm);
`;

const Options = styled.div`
	display: inline-grid;
	gap: var(--spacing-3);
	grid-auto-flow: column;

	@media ${styles.breakpoint.small} {
		grid-auto-flow: row;
	}
`;

const Option = styled.div`
	position: relative;
`;

const ButtonLabel = styled.div`
	display: grid;
	grid-template-columns: auto 1fr;
	gap: var(--spacing-3);
	align-items: center;
	justify-content: center;
`;

interface PlaylistListProps {
	clipIds?: number[];
}

function PlaylistList({ clipIds }: PlaylistListProps): JSX.Element {
	const account = useCurrentAccount();
	const group = useCurrentGroup();
	const ctx = StepModal.useStepModalContext();

	const [selectedPlaylists, setSelectedPlaylists] = useState<number[]>([]);

	const { records: playlists, pagination } =
		useCollection<models.videoPlaylist.VideoPlaylist>(
			endpoints.VideoPlaylist.Index(),
			{
				queryParams: new URLSearchParams({
					group_id: group.id.toString(),
					account_id: account.id.toString(),
					count: '10',
				}),
			}
		);

	const allSelected = selectedPlaylists.length === playlists.length;

	const handleToggle = (playlistId: number) => {
		if (selectedPlaylists.includes(playlistId)) {
			setSelectedPlaylists(
				selectedPlaylists.filter((id: number) => id !== playlistId)
			);
		} else {
			setSelectedPlaylists([...selectedPlaylists, playlistId]);
		}
	};

	const handleToggleAll = () => {
		if (allSelected) {
			setSelectedPlaylists([]);
		} else {
			setSelectedPlaylists(
				playlists.map((p: models.videoPlaylist.VideoPlaylist) => p.id)
			);
		}
	};

	const columns = [
		{
			content: (
				<Input.Control standalone type="checkbox" checked={allSelected} />
			),
			width: 'max-content',
			onClick: handleToggleAll,
		},
		{
			content: t(`Playlist`),
			width: '1fr',
		},
		{
			content: t(`Shared with`),
			align: 'right',
			width: 'max-content',
		},
	];

	const goToCreate = () => ctx.goTo('create');

	ctx.withNext(async (): Promise<boolean> => {
		if (selectedPlaylists.length > 0) {
			const promises = selectedPlaylists.map((playlistId: number) =>
				actions.videoPlaylists.addSequences(playlistId, ...clipIds)
			);

			const settled = await allSettled(promises);

			const numSettled = settled.filter(
				(promise: SettledPromise) => promise.fulfilled
			).length;

			actions.flashes.show({
				title: t(`Added clip to {num} playlist(s)`, {
					num: numSettled,
				}),
			});

			return numSettled > 0;
		}
	});

	useComponentWillUnmount(() => ctx.withNext(null));

	return (
		<Fragment>
			<Actions>
				<Button small icon="add" onClick={goToCreate}>
					{t(`Create new playlist`)}
				</Button>
			</Actions>
			<Table.Table columns={columns}>
				{playlists.map((playlist: models.videoPlaylist.VideoPlaylist) => {
					let sharedWith = t(`Only you`);

					if (playlist.groupId) {
						sharedWith = t(`Team`);
					}

					return (
						<Table.Row key={playlist.id}>
							<Table.Cell>
								<Input.Control
									standalone
									type="checkbox"
									onChange={handleToggle}
									value={playlist.id}
									checked={selectedPlaylists.includes(playlist.id)}
								/>
							</Table.Cell>
							<Table.Cell>
								<PlaylistRow>
									<span>{playlist.title}</span>
									<RelativeDateTime dateTime={playlist.createdAt} />
								</PlaylistRow>
							</Table.Cell>
							<Table.Cell align="right">{sharedWith}</Table.Cell>
						</Table.Row>
					);
				})}
			</Table.Table>
			<Pagination {...pagination} />
		</Fragment>
	);
}

interface PlaylistData {
	title: string;
	sharedWithTeam: boolean;
	editableBy: VideoPlaylistPermission;
}

interface PlaylistProps {
	playlistId?: number;
	clipIds?: number[];

	onClose?: () => void;
	afterSave?: (playlist: models.videoPlaylist.VideoPlaylist) => void;
}

export default function Playlist({
	playlistId,
	clipIds,

	onClose,
	afterSave,
}: PlaylistProps): JSX.Element {
	const group = useCurrentGroup();
	const membership = useCurrentMembership();

	const [isBusy, setBusy] = useState<boolean>(false);
	const [data, setData] = useMixedState<PlaylistData>({
		title: '',
		sharedWithTeam: true,
		editableBy: 'only_you',
	});

	const isAddingToPlaylists = clipIds?.length > 0;

	const handleTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
		setData({ title: event.target.value });
	};

	const handleShareWithTeam = () => setData({ sharedWithTeam: true });

	const handleShareWithMe = () =>
		setData({
			sharedWithTeam: false,
			editableBy: 'only_you',
		});

	const handleEditableByMe = () => setData({ editableBy: 'only_you' });

	const handleEditableByStaff = () => setData({ editableBy: 'group_admins' });

	const handleEditableByGroup = () => setData({ editableBy: 'group_members' });

	const { record: playlist } = useEndpoint<models.videoPlaylist.VideoPlaylist>(
		endpoints.VideoPlaylist.Show(playlistId)
	);

	useEffect(() => {
		if (playlist.id) {
			setData({
				title: playlist.title,
				sharedWithTeam: !!playlist.groupId,
			});

			const perms = playlist.sequencePermissions;

			if (
				perms.includes(VideoPlaylistSequencePermissions.GroupAdmin) &&
				perms.includes(VideoPlaylistSequencePermissions.GroupMember)
			) {
				setData({ editableBy: 'group_members' });
			} else if (perms.includes(VideoPlaylistSequencePermissions.GroupAdmin)) {
				setData({ editableBy: 'group_admins' });
			}
		}
	}, [playlist?.id]);

	const save = async () => {
		setBusy(true);

		let sequencePermissions: VideoPlaylistSequencePermissions[] = [];

		switch (data.editableBy) {
			case 'group_admins':
				sequencePermissions = [VideoPlaylistSequencePermissions.GroupAdmin];
				break;
			case 'group_members':
				sequencePermissions = [
					VideoPlaylistSequencePermissions.GroupAdmin,
					VideoPlaylistSequencePermissions.GroupMember,
				];
				break;
		}

		if (playlistId) {
			const [req, p] = await models.videoPlaylist.update(playlist, {
				title: data.title,
				sequencePermissions,
			});

			if (afterSave && req.ok) {
				afterSave(p);
			}
		} else {
			const payload = {
				title: data.title,
				groupId: data.sharedWithTeam ? group.id : null,
				sequencePermissions,
			};

			if (models.membership.isPlayer(membership)) {
				payload.groupId = null;
				payload.sequencePermissions = [];
			}

			const [req, playlist] = await models.videoPlaylist.create(payload);

			if (afterSave && req.ok) {
				afterSave(playlist);
			}
		}

		return true;
	};

	const shareLabel = (
		<ButtonLabel>
			<Icon name={data.sharedWithTeam ? 'nav-members' : 'user'} />
			<span>{data.sharedWithTeam ? t(`Shared with team`) : t(`Only me`)}</span>
		</ButtonLabel>
	);

	return (
		<StepModal.Base onClose={onClose}>
			{isAddingToPlaylists && (
				<StepModal.Step
					skipBody
					closeOnNext
					slug="add"
					title={t(`Add to playlist`)}
					nextLabel={t('Finish')}>
					<PlaylistList clipIds={clipIds} />
				</StepModal.Step>
			)}
			<StepModal.Step
				busy={isBusy}
				slug="create"
				title={playlistId ? t(`Edit playlist`) : t(`Create new playlist`)}
				nextLabel={playlistId ? t('Save') : t('Create')}
				prevLabel={isAddingToPlaylists ? t('Back') : t('Cancel')}
				canGoNext={data.title.length > 0}
				nextSlug={isAddingToPlaylists ? 'add' : undefined}
				onNext={save}>
				<Wrapper>
					<Input.Field
						placeholder={t(`Give your playlist a name…`)}
						value={data.title}
						onChange={handleTitleChange}>
						<Input.Prefix inline>
							<Icon name="playlist-add" size={1.4} />
						</Input.Prefix>
					</Input.Field>
					{models.membership.isAdminOrStaff(membership) ? (
						<InfoBox>
							<ShareWrapper>
								<Description>
									{data.sharedWithTeam
										? t(
												`A shared playlist will always be available to the team and cannot be made private once it has been shared. You may also give edit rights to yourself, group staff or the entire team.`
											)
										: t(
												`The playlist will only be available to you unless you add it to a group or club collection. In that case group admins may see your playlist.`
											)}
								</Description>

								<Options>
									<Option>
										<ContextMenu.Menu
											toggleWith={
												<Button
													small
													primary
													icon="expand_more"
													iconPosition="right"
													disabled={!!playlistId}>
													{shareLabel}
												</Button>
											}>
											<ContextMenu.Item onClick={handleShareWithMe}>
												<ContextMenu.ItemIcon name="person" />
												{t(`Only me`)}
											</ContextMenu.Item>
											<ContextMenu.Item onClick={handleShareWithTeam}>
												<ContextMenu.ItemIcon name="groups" />
												{t(`Shared with team`)}
											</ContextMenu.Item>
										</ContextMenu.Menu>
									</Option>

									{data.sharedWithTeam && (
										<Option>
											<ContextMenu.Menu
												toggleWith={
													<Button small icon="expand_more" iconPosition="right">
														<ButtonLabel>
															<Icon name="nav-members" />
															<span>
																{t(`Editable by {editable_by}`, {
																	editable_by: t(
																		permissionLabel(
																			data.editableBy
																		).toLocaleLowerCase()
																	),
																})}
															</span>
														</ButtonLabel>
													</Button>
												}>
												<ContextMenu.Item onClick={handleEditableByMe}>
													<ContextMenu.ItemIcon name="person" />
													{t(`Only me`)}
												</ContextMenu.Item>
												<ContextMenu.Item onClick={handleEditableByStaff}>
													<ContextMenu.ItemIcon name="person_add" />
													{t(`group admins`)}
												</ContextMenu.Item>
												<ContextMenu.Item onClick={handleEditableByGroup}>
													<ContextMenu.ItemIcon name="groups" />
													{t(`group members`)}
												</ContextMenu.Item>
											</ContextMenu.Menu>
										</Option>
									)}
								</Options>
							</ShareWrapper>
						</InfoBox>
					) : (
						<InfoBox
							text={t(
								`The playlist will only be available to you and cannot be shared with your team.`
							)}
						/>
					)}
				</Wrapper>
			</StepModal.Step>
		</StepModal.Base>
	);
}
