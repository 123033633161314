import { memo } from 'react';
import { Browser } from '@capacitor/browser';
import { AppLauncher } from '@capacitor/app-launcher';

import { isAndroid, isApp } from 'pkg/platform';
import { fromEntries } from 'pkg/utils';
import withForwardedRef from 'pkg/withForwardedRef';
import { transformPath, isLocalPathOrUrl } from 'pkg/router/utils';
import {
	useRouterState,
	useRouterDispatch,
	useCurrentRouterState,
	useCurrentRoute,
} from 'pkg/router/hooks';

const isOpenInNewTabEvent = (event) =>
	event.ctrlKey || event.shiftKey || event.metaKey || event.button === 1;

const Link = ({
	href,
	to,
	replace,
	back,
	params = {},
	query = {},
	onClick,
	children,
	resetHistory = false,
	onMouseEnter,
	...props
}) => {
	const { pushState, popState, replaceState } = useRouterState();
	const [currentState] = useCurrentRouterState('/');
	const dispatch = useRouterDispatch();

	const currentRoute = useCurrentRoute();
	const routeName = to || replace || '';

	params = { ...params, ...query };

	const nextState = transformPath({ href, to, replace, params }, currentRoute);

	let nextLocation = nextState.href;

	const shouldNavigate = isLocalPathOrUrl(nextLocation);

	if (shouldNavigate) {
		nextLocation = nextLocation.replace(
			/^(https|capacitor?)\:(\/{2})app\.360player\.com/,
			''
		);
	}

	let inlineStyles = {
		...props.style,
	};

	const navigate = (event) => {
		if (typeof onClick === 'function') {
			onClick(event, shouldNavigate);
		}

		if (isOpenInNewTabEvent(event)) return;

		if (props.stopPropagation) {
			event.stopPropagation();
		}

		if (shouldNavigate) {
			event.preventDefault();

			if (back === true) {
				popState();
			} else if (to || href) {
				pushState(nextLocation, nextState.route);

				if (resetHistory === true) {
					dispatch('RESET');
				}
			} else if (replace) {
				replaceState(nextLocation, nextState.route);

				if (resetHistory === true) {
					dispatch('RESET');
				}
			}
		}
	};

	const getProperties = () => {
		const isLocal = isLocalPathOrUrl(href ?? '');
		const { className, title, name, target, forwardRef, disabled } = props;

		const dataAttributes = Object.entries(props).filter(([key]) =>
			key.startsWith('data-')
		);

		let newProps = {
			href: nextLocation,
			onClick: navigate,
			title,
			className,
			disabled,
			onMouseEnter,
			...fromEntries(dataAttributes),
		};

		if (!title && name) {
			title = name;
		}

		if (target) {
			newProps.target = target;
		}

		if (routeName) {
			newProps['data-route-name'] = routeName;
		}

		const currentLocation = currentState.location;

		const currentWithoutSearch = currentLocation.split('?')[0];

		const nextWithoutSearch = nextLocation.split('?')[0];

		if (currentWithoutSearch === nextWithoutSearch) {
			newProps['aria-current'] = 'page';
			newProps['data-current-page'] = true;
		}

		const currentLocationWithoutSlash = currentLocation.substring(1);
		const nextLocationWithoutSlash = nextLocation.substring(1);

		if (
			currentLocationWithoutSlash.length > 0 &&
			nextLocationWithoutSlash.length > 0 &&
			currentLocationWithoutSlash.startsWith(nextLocationWithoutSlash)
		) {
			newProps['data-current-tree'] = true;
		}

		if (currentLocation === '/' && nextLocation === currentLocation) {
			newProps['data-current-tree'] = true;
		}

		if (!isLocal) {
			newProps.rel = 'noreferrer noopener';
			newProps.target = '_blank';
			newProps.onClick = null;
		} else {
			// Strip away domain from local urls
			if (nextLocation.match(/https?:/i)) {
				const nextUrl = new URL(nextLocation || '');
				const domainName = `${nextUrl.protocol}//${nextUrl.host}`;

				newProps.href = nextLocation.replace(domainName, '');
			}
		}

		if (isApp() && newProps.target === '_blank') {
			newProps.onClick = (event) => {
				event.preventDefault();

				Browser.open({ url: newProps.href });
			};
		}

		// Open Webcal links with App Launcher in iOS
		if (isApp() && !isAndroid() && newProps.href?.includes('webcal://')) {
			newProps.onClick = async (event) => {
				event.preventDefault();

				const { value: canOpenUrl } = await AppLauncher.canOpenUrl({
					url: newProps.href,
				});

				if (canOpenUrl) {
					await AppLauncher.openUrl({
						url: newProps.href,
					});
				}
			};
		}

		// Open mailto links with App Launcher in iOS
		if (isApp() && !isAndroid() && newProps.href?.includes('mailto:')) {
			newProps.onClick = async (event) => {
				event.preventDefault();

				const { value: canOpenUrl } = await AppLauncher.canOpenUrl({
					url: newProps.href,
				});

				if (canOpenUrl) {
					await AppLauncher.openUrl({
						url: newProps.href,
					});
				}
			};
		}

		newProps.ref = forwardRef;

		if (disabled) {
			newProps.href = null;
			newProps.onClick = null;
			newProps.target = null;
		}

		if (props.back === true) {
			newProps.href = '#';
		}

		if ('data-testid' in props || 'testid' in props) {
			newProps['data-testid'] = props['data-testid'] || props['testid'];
		} else {
			newProps['data-testid'] = routeName || name || 'router.link';
		}

		return newProps;
	};

	return (
		<a {...getProperties()} style={inlineStyles}>
			{children}
		</a>
	);
};

export default withForwardedRef(memo(Link));
