import { ReactNode } from 'react';

export const symbol_gif: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M492-360q-10.4 0-17.2-6.8-6.8-6.8-6.8-17.2v-192q0-10.4 6.8-17.2 6.8-6.8 17.2-6.8 10.4 0 17.2 6.8 6.8 6.8 6.8 17.2v192q0 10.4-6.8 17.2-6.8 6.8-17.2 6.8Zm-258 0q-15.3 0-24.65-9.5Q200-379 200-394v-172q0-15 9.35-24.5T234-600h134q10.4 0 17.2 6.8 6.8 6.8 6.8 17.2 0 10.4-6.8 17.2-6.8 6.8-17.2 6.8H248v144h96v-48q0-10.4 6.8-17.2 6.8-6.8 17.2-6.8 10.4 0 17.2 6.8 6.8 6.8 6.8 17.2v62q0 15-9.35 24.5T358-360H234Zm378 0q-10.4 0-17.2-6.8-6.8-6.8-6.8-17.2v-192q0-10.4 6.8-17.2 6.8-6.8 17.2-6.8h124q10.4 0 17.2 6.8 6.8 6.8 6.8 17.2 0 10.4-6.8 17.2-6.8 6.8-17.2 6.8H636v60h57q10.4 0 17.2 6.8 6.8 6.8 6.8 17.2 0 10.4-6.8 17.2-6.8 6.8-17.2 6.8h-57v60q0 10.4-6.8 17.2-6.8 6.8-17.2 6.8Z"
		/>
	</svg>
);
