import { ReactNode } from 'react';

export const symbol_insert_text: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M450-580h-90q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T360-640h240q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T600-580h-90v230q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T450-350v-230ZM40-70v-153q0-12.75 8.625-21.375T70-253h49v-454H70q-12.75 0-21.375-8.625T40-737v-153q0-12.75 8.625-21.375T70-920h153q12.75 0 21.375 8.625T253-890v49h454v-49q0-12.75 8.625-21.375T737-920h153q12.75 0 21.375 8.625T920-890v153q0 12.75-8.625 21.375T890-707h-49v454h49q12.75 0 21.375 8.625T920-223v153q0 12.75-8.625 21.375T890-40H737q-12.75 0-21.375-8.625T707-70v-49H253v49q0 12.75-8.625 21.375T223-40H70q-12.75 0-21.375-8.625T40-70Zm213-109h454v-44q0-12.75 8.625-21.375T737-253h44v-454h-44q-12.75 0-21.375-8.625T707-737v-44H253v44q0 12.75-8.625 21.375T223-707h-44v454h44q12.75 0 21.375 8.625T253-223v44ZM100-767h93v-93h-93v93Zm667 0h93v-93h-93v93Zm0 667h93v-93h-93v93Zm-667 0h93v-93h-93v93Zm93-667Zm574 0Zm0 574Zm-574 0Z"
		/>
	</svg>
);
