import { ReactNode } from 'react';

export const symbol_enterprise: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M479-345q32 0 54-21.5t22-53.5q0-32-22-53.5T479.5-495q-31.5 0-53 22.044-21.5 22.045-21.5 53Q405-389 426.737-367q21.738 22 52.263 22ZM140-120q-24 0-42-18t-18-42v-480q0-24 18-42t42-18h180v-100q0-24 18-42t42-18h200q24 0 42 18t18 42v100h180q24 0 42 18t18 42v480q0 24-18 42t-42 18H140Zm0-60h680v-480H140v480Zm240-540h200v-100H380v100ZM140-180v-480 480Z"
		/>
	</svg>
);
