import { ReactNode } from 'react';

export const symbol_airplane_ticket: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m479-438-111 31-45-38q-1-1-15-2l-4 1q-7 2-9.5 9t.5 13l47 88q2 5 7.5 7.5t10.5.5l356-98q17-5 25-16t3-28q-5-17-18-23.5t-28.796-2.367L594-469 438.227-620.586Q433-626 426.667-628q-6.334-2-12.667 0l-4.737 1Q396-624 391.5-612q-4.5 12 1.5 23l86 151ZM140-160q-24.75 0-42.375-17.625T80-220v-132q0-10.185 5.5-17.593Q91-377 100-380q31-11 48.5-39.5t17.5-60q0-31.5-17.5-60.5T100-580q-9-3-14.5-10.407Q80-597.815 80-608v-132q0-24.75 17.625-42.375T140-800h680q24.75 0 42.375 17.625T880-740v520q0 24.75-17.625 42.375T820-160H140Zm0-60h680v-520H140v109q39 26 62.5 65t23.5 86q0 47-23.5 86T140-329v109Zm340-260Z"
		/>
	</svg>
);
