import { t } from '@transifex/native';
import styled from 'styled-components';
import { CSSProperties, MouseEvent, PointerEvent } from 'react';

import * as styles from 'pkg/config/styles';

import * as routes from 'pkg/router/routes';
import * as models from 'pkg/api/models';
import { useCurrentOrganization } from 'pkg/identity';

import { useTrainingLibraryFilters } from 'routes/training/library/hooks';

import TextOverflow from 'components/TextOverflow';
import Tag from 'components/Tag';
import Icon from 'components/icon';

import GroupItem, {
	GroupItemAction,
	GroupItemType,
} from 'components/library/GroupItem';
import Column from 'components/layout/column';

const SessionGroupItem = styled(GroupItem)`
	background: var(--palette-orange-100);
	aspect-ratio: unset;
	box-shadow: inset 0 0 0 1px hsla(0, 0%, 0%, 0.1);
	display: grid;
	grid-template-rows: 1fr repeat(2, auto);

	@supports not (aspect-ratio: 16 / 9) {
		&::before,
		&::after {
			display: none;
		}
	}
`;

const Container = styled(Column)`
	color: var(--palette-black);
	padding: var(--spacing-5) var(--spacing-5) var(--spacing-3) var(--spacing-5);
`;

const Title = styled(TextOverflow)`
	font-size: var(--font-size-lg);
	font-weight: var(--font-weight-semibold);

	// Adjust for "new" label
	max-width: calc(100% - 60px);

	@media ${styles.breakpoint.small} {
		font-size: var(--font-size-base);
		line-height: var(--font-line-height-base);
	}
`;

const Description = styled(TextOverflow)`
	font-size: var(--font-size-sm);
	line-height: var(--font-line-height-base);

	@media ${styles.breakpoint.small} {
		font-size: var(--font-size-xs);
		line-height: var(--font-line-height-sm);
	}
`;

const TagsContainer = styled.div`
	margin: var(--spacing-2) var(--spacing-5);
	display: inline-flex;
	gap: var(--spacing-2);
`;

const MetaDataContainer = styled.div`
	padding: var(--spacing-4) var(--spacing-5) var(--spacing-5) var(--spacing-5);
	color: var(--palette-black);
	font-size: var(--font-size-sm);
	display: block;
	column-gap: var(--spacing-4);
	gap: var(--spacing-5);

	@media ${styles.breakpoint.small} {
		font-size: var(--font-size-xs);
	}
`;

const MetaDataItem = styled.div`
	display: inline-flex;
	flex-flow: row;
	column-gap: var(--spacing-2);
	place-items: center;

	&:not(:last-child) {
		margin-right: var(--spacing-5);
	}
`;

const MetaDataIcon = styled.div`
	height: 100%;
	width: 16px;
	display: flex;
	align-items: center;

	svg {
		width: 100%;
		height: 100%;
	}
`;

const MetaDataValue = styled.span`
	font-weight: var(--font-weight-normal);
`;

const MetaDataLabel = styled.span`
	font-size: var(--font-size-sm);

	@media ${styles.breakpoint.small} {
		font-size: var(--font-size-xs);
	}
`;

interface TrainingSessionGroupItemProps {
	item: models.session.Session;
	actions?: GroupItemAction<models.session.Session>[];
	onClick?: (
		item: models.session.Session,
		event?: PointerEvent<HTMLDivElement>
	) => void;
}

export default function TrainingSessionGroupItem({
	item,
	actions,
	onClick,
}: TrainingSessionGroupItemProps): JSX.Element {
	const filters = useTrainingLibraryFilters();
	const numBlocks = models.session.numBlocks(item);
	const numExercises = models.session.numExercises(item);
	const duration = models.session.duration(item);
	const org = useCurrentOrganization();

	const handleTagClick = (event: MouseEvent<HTMLDivElement>) => {
		event.preventDefault();
		event.stopPropagation();

		filters.reset();
		filters.addTag(event.currentTarget.getAttribute('name'));
	};

	const handleClick = (event: PointerEvent<HTMLDivElement>) => {
		if (onClick) {
			onClick(item, event);
		}
	};

	const capTagsAt = 4;
	let tags = item.tags;
	let rest = '';

	if (tags?.length >= capTagsAt) {
		const restAmount = tags.length - capTagsAt;

		tags = tags.slice(0, 4);

		if (restAmount > 0) {
			rest = t(`+{restAmount} more`, { restAmount });
		}
	}

	return (
		<SessionGroupItem
			item={item}
			itemType={GroupItemType.TrainingSession}
			href={onClick ? undefined : routes.TrainingSession.Show(org.id, item.id)}
			actions={actions}
			title={item.title}
			author={item.author}
			accountAsByline
			createdAt={item.createdAt}
			backdropColor={styles.palette.white}
			aspectRatio="auto"
			onClick={handleClick}
			style={{ '--content-row': '1fr' } as CSSProperties}>
			<Container
				spacing={styles.spacing._2}
				blockSpacing={styles.spacing._5}
				inlineSpacing={styles.spacing._5}>
				<Title maxLines={2}>{item.title}</Title>
				<Description maxLines={3}>{item.description}</Description>
			</Container>
			{tags?.length > 0 && (
				<TagsContainer>
					{tags.map((tag: models.tag.Tag) => (
						<Tag
							small
							key={tag.name}
							name={tag.name}
							type="session"
							onClick={handleTagClick}
						/>
					))}
					{rest && <Tag small type="rest" name={rest} />}
				</TagsContainer>
			)}
			<MetaDataContainer>
				<MetaDataItem>
					<MetaDataIcon>
						<Icon name="duration" />
					</MetaDataIcon>
					<MetaDataValue>{duration}</MetaDataValue>
					<MetaDataLabel>{t(`Minutes`)}</MetaDataLabel>
				</MetaDataItem>

				<MetaDataItem>
					<MetaDataIcon>
						<Icon name="collection" />
					</MetaDataIcon>
					<MetaDataValue>{numBlocks}</MetaDataValue>
					<MetaDataLabel>
						{numBlocks === 1 ? t(`Block`) : t(`Blocks`)}
					</MetaDataLabel>
				</MetaDataItem>

				<MetaDataItem>
					<MetaDataIcon>
						<Icon name="library" />
					</MetaDataIcon>
					<MetaDataValue>{numExercises}</MetaDataValue>
					<MetaDataLabel>
						{numExercises === 1 ? t(`Exercise`) : t(`Exercises`)}
					</MetaDataLabel>
				</MetaDataItem>
			</MetaDataContainer>
		</SessionGroupItem>
	);
}
