import { ReactNode } from 'react';

export const symbol_hematology: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M520-40q-73 0-116.5-61T360-240q0-78 43.5-139T520-440q73 0 116.5 61T680-240q0 78-43.5 139T520-40ZM220-480q0 94-11.5 186.5T172-110q-3.75 13.813-15 22.406Q145.75-79 133-84q-13-5-18-17t-1-26q23-87 34.5-175T160-480.5q0-90.5-11.5-178.5T114-834q-4-14 1-26t17.75-17q12.75-5 24 3.5T172-851q24 91 36 184t12 187Zm580 0q0 90 11.5 178T846-127q4 14-1 26t-17.75 17q-12.75 5-24-3.5T788-110q-25-91-36.5-183.5T740-480q0-94 12-187t36-184q3.75-13.812 15-22.406T827-877q13 5 18 17t1 26q-23 87-34.5 174.965T800-480ZM520-100q48 0 74-43t26-97q0-54-26-97t-74-43q-48 0-74 43t-26 97q0 54 26 97t74 43Zm-31-68q9 8 21 8t21-9q14-14 21.5-32.5T560-240q0-20-7-38.5T532-311q-8-9-20.5-9t-21.5 8q-9 8-9.5 21t7.5 22l6 8.5q6 8.5 6 20.5t-6 20.5l-6 8.5q-9 9-8.5 22t9.5 21Zm-49-352q-73 0-116.5-61T280-720q0-78 43.5-139T440-920q73 0 116.5 61T600-720q0 78-43.5 139T440-520Zm0-60q48 0 74-43t26-97q0-54-26-97t-74-43q-48 0-74 43t-26 97q0 54 26 97t74 43Zm30-68q9-8 9.5-21t-7.5-22q-5-6-8.5-13.5T460-720q0-8 3.5-15.5T472-749q8-9 8-21.5t-9-21.5q-9-8-21-8t-21 9q-14 14-21.5 32.5T400-720q0 20 7 38.5t21 32.5q9 9 21 9t21-8Zm-30-72Zm80 480Z"
		/>
	</svg>
);
