import { useT } from '@transifex/react';

import * as actions from 'pkg/actions';
import * as models from 'pkg/api/models';
import { PaymentProvider } from 'pkg/api/models/group';

import * as Card from 'components/Card';

import BlockPlaceholder from 'design/placeholders/block';
import Button from 'design/button';

interface StartAdyenOnboardingProps {
	group: models.group.Group;
}

export default function StartAdyenOnboarding({
	group,
}: StartAdyenOnboardingProps) {
	const t = useT();

	const adyenOnboardingConfig = group.paymentConfigs.find(
		(cf) => cf.paymentProvider === PaymentProvider.Adyen
	);

	const handleStartAdyenOnboarding = async () =>
		actions.adyen.getOnboardingLink(group.id, adyenOnboardingConfig.id);

	return (
		<Card.Base $noBorder>
			<Card.Body>
				<BlockPlaceholder
					title={t(
						'Set up payments to start accepting transactions in your organization.'
					)}
					description={t(
						'In order to start accepting payments you have to go through a quick onboarding process that verifies your business.'
					)}
					icon="nav-payments"
					noBorder
					actions={
						<Button
							label={t('Start payment onboarding')}
							primary
							onClick={handleStartAdyenOnboarding}
						/>
					}
				/>
			</Card.Body>
		</Card.Base>
	);
}
