import { ReactNode } from 'react';

export const symbol_stack_star: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m600-293 68 41q5 2 9-.5t3-7.5l-18-78 60-52q4-3 2-7.5t-7-5.5l-79-7-31-74q-1.5-5-6.75-5t-7.25 5l-31 74-79 7q-5 1-7 5.5t2 7.5l60 52-18 78q-1 5 3 7.5t9 .5l68-41Zm-460-27q-24.75 0-42.375-17.625T80-380v-440q0-24.75 17.625-42.375T140-880h440q24.75 0 42.375 17.625T640-820v90q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T580-730v-90H140v440h90q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T230-320h-90ZM380-80q-24.75 0-42.375-17.625T320-140v-440q0-24.75 17.625-42.375T380-640h440q24.75 0 42.375 17.625T880-580v440q0 24.75-17.625 42.375T820-80H380Zm0-60h440v-440H380v440Zm220-220Z"
		/>
	</svg>
);
