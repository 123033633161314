import { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import Icon from 'components/icon';

import * as Table from 'design/table';
import Row from 'design/table/table-row/Row';

interface ExpandableRowProps {
	onClick?: () => void;
	canExpand?: boolean;
	isExpanded?: boolean;
	level?: number;
	actions?: JSX.Element;
	children: ReactNode;
}

const Wrapper = styled(Row)<ExpandableRowProps>`
	&:hover {
		background: var(--palette-gray-200);
	}

	td:first-of-type {
		${({ level }) =>
			level &&
			css`
				padding-left: ${level * 34 + 12}px !important;
			`};
	}
`;

export default function ExpandableRow({
	onClick,
	canExpand = false,
	isExpanded = false,
	level = 0,
	actions,
	children,
}: ExpandableRowProps): JSX.Element {
	// keep chevron in the dom but set it to size 0, to keep the positoning of the potentiol actions
	return (
		<Wrapper level={level} canExpand={canExpand}>
			{children}
			<Table.ActionCell onClick={onClick} align="right">
				{actions}
				<Icon
					name="chevron"
					rotate={isExpanded ? '90deg' : '0deg'}
					size={canExpand ? 1.75 : 0}
				/>
			</Table.ActionCell>
		</Wrapper>
	);
}
