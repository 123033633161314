import { List } from 'immutable';

import UserGoal from 'pkg/models/user_goal';

const findAllUserGoalEntities = (state: any) => state.userGoals.entities;

export const findAllUserGoals = (
	state: any,
	userId: number
): List<UserGoal> => {
	return findAllUserGoalEntities(state)
		.filter((userGoal: UserGoal) => +userGoal.userId === +userId)
		.sort((a: UserGoal, b: UserGoal) => b.createdAt - a.createdAt)
		.toList();
};

export const findAllUserActiveGoals = (
	state: any,
	userId: number
): List<UserGoal> => {
	return findAllUserGoals(state, userId).filter(
		(userGoal: UserGoal) => userGoal.completedAt === null
	);
};

export const findAllUserCompletedGoals = (
	state: any,
	userId: number
): List<UserGoal> => {
	return findAllUserGoals(state, userId).filter(
		(userGoal: UserGoal) => userGoal.completedAt !== null
	);
};
