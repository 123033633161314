import { Record } from 'immutable';

import { match } from 'pkg/models/schemas';

export default class Match extends Record(
	{
		createdAt: null,
		description: '',
		duration: 90,
		eventId: null,
		externalId: '',
		fullTimeAddition: null,
		goalCount: null,
		goalCountHalfTime: null,
		groupId: null,
		group: Record({}),
		halfTimeAddition: null,
		id: null,
		isHome: false,
		links: {},
		location: '',
		matchOpponentId: null,
		matchCompetitionId: null,
		opponentGoalCount: null,
		opponentGoalCountHalfTime: null,
		opponentName: '',
		overtime: 0,
		report: '',
		notes: '',
		startsAt: null,
		title: '',
		type: '',
		updatedAt: null,
		userId: null,
	},
	'Match'
) {
	static normalizr() {
		return match;
	}

	canEdit() {
		return this.hasIn(['links', 'edit']);
	}
}
