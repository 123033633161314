import { ReactNode } from 'react';

export const symbol_ink_eraser_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M792-83 605-269l-85 90q-8.298 8.708-19.237 13.854Q489.825-160 477-160H204q-12.444 0-23.722-4.5T161-178l-63-66q-18-17-17.5-41.5T97-327l216-234L82-792q-9-9.067-9-21.533Q73-826 82.053-835q9.052-9 21.5-9Q116-844 125-835l710 710q9 9 9 21t-9.053 21q-9.052 9-21.5 9Q801-74 792-83ZM206-220h274l83-91-209-209-212 234 64 66Zm654-348q1 11-3.065 21.963Q852.87-535.073 844-526L709-380q-8 9-20.833 9-12.834 0-22.167-9-8-8-8.5-20t7.5-21l139-151-207-217-141 157q-8 9-20 9.5t-20.87-8.37Q407-639 406.5-651q-.5-12 7.5-20l141-154q8-9 18.574-13 10.574-4 21.147-4 11.279 0 22.779 4t19.5 13l205 215q9 8 13.5 19.5T860-568Zm-319 63Zm-82 89Z"
		/>
	</svg>
);
