import { ReactNode } from 'react';

export const symbol_speed_0_5: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M435-277q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T435-337h140v-115H435q-12.75 0-21.375-8.625T405-482v-171q0-12.75 8.625-21.375T435-683h170q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T605-623H465v111h110q24 0 42 18t18 42v115q0 24-18 42t-42 18H435Zm-120.175 0Q302-277 293.5-285.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Z"
		/>
	</svg>
);
