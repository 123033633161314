import { ReactNode } from 'react';

export const symbol_wifi_calling: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M652-577 489-740q-15-15-8-29.5t21-22.5q39-24 82.5-36t88.5-12q47 0 91 12t82 36q14 8 20 22.5t-8 28.5L694-577q-9 9-21 9t-21-9Zm21-64 114-114q-26-13-54.5-19t-59.5-6q-29 0-57.5 6.5T559-755l114 114Zm0 0Zm122 521q-116 0-236.5-56T335-335Q232-438 176-558.5T120-795q0-19.286 12.857-32.143T165-840h140q14 0 24 10t14 25l26.929 125.641Q372-665 369.5-653.5q-2.5 11.5-10.729 19.726L259-533q26 44 55 82t64 72q37 38 78 69.5t86 55.5l95-98q10-11 23.151-15T686-369l119 26q15 4 25 16.044T840-300v135q0 19.286-12.857 32.143T795-120ZM229-588l81-82-23-110H180q2 42 13.5 88.5T229-588Zm369 363q41 19 89 31t93 14v-107l-103-21-79 83ZM229-588Zm369 363Z"
		/>
	</svg>
);
