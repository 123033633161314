export default {
	name: 'list',
	objects: [
		{
			type: 'path',
			attributes: {
				d: 'M3.43281 7.86561C4.22412 7.86561 4.86561 7.22412 4.86561 6.43281C4.86561 5.64149 4.22412 5 3.43281 5C2.64149 5 2 5.64149 2 6.43281C2 7.22412 2.64149 7.86561 3.43281 7.86561Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M22.1595 5.59241H9.25006C8.78592 5.59241 8.40967 5.96866 8.40967 6.4328V6.4328C8.40967 6.89694 8.78592 7.27319 9.25006 7.27319H22.1595C22.6236 7.27319 22.9999 6.89694 22.9999 6.4328C22.9999 5.96866 22.6236 5.59241 22.1595 5.59241Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M3.43281 13.7304C4.22412 13.7304 4.86561 13.0889 4.86561 12.2976C4.86561 11.5063 4.22412 10.8648 3.43281 10.8648C2.64149 10.8648 2 11.5063 2 12.2976C2 13.0889 2.64149 13.7304 3.43281 13.7304Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M22.159 11.4572H9.24957C8.78543 11.4572 8.40918 11.8335 8.40918 12.2976V12.2976C8.40918 12.7617 8.78543 13.138 9.24957 13.138H22.159C22.6232 13.138 22.9994 12.7617 22.9994 12.2976C22.9994 11.8335 22.6232 11.4572 22.159 11.4572Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M3.43281 19.5952C4.22412 19.5952 4.86561 18.9537 4.86561 18.1624C4.86561 17.371 4.22412 16.7296 3.43281 16.7296C2.64149 16.7296 2 17.371 2 18.1624C2 18.9537 2.64149 19.5952 3.43281 19.5952Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M22.159 17.3221H9.24957C8.78543 17.3221 8.40918 17.6983 8.40918 18.1625V18.1625C8.40918 18.6266 8.78543 19.0029 9.24957 19.0029H22.159C22.6232 19.0029 22.9994 18.6266 22.9994 18.1625C22.9994 17.6983 22.6232 17.3221 22.159 17.3221Z',
			},
		},
	],
};
