import { ReactNode } from 'react';

export const symbol_format_color_reset: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-120q-132 0-226-91.5T160-435q0-57 17-106t53-91L78-784q-9-9-9-21.5t9-21.5q9-9 21.5-9t21.5 9l707 707q9 9 9 21t-9 21q-9 9-21.5 9T785-78L676-186q-39 32-90.5 49T480-120Zm0-60q46 0 85.5-12t68.5-37L273-590q-24 29-38.5 67.5T220-435q0 107 76.5 181T480-180Zm-26-242Zm251-236q72 71 88.5 160T777-320q-4 9-11 15.5t-18 6.5q-17 0-25.5-14t-2.5-28q29-72 13.5-147T661-617L480-796 380-695q-9 9-21 9t-21-9q-9-9-9-21.5t9-21.5l110-111q7-7 15.5-10t16.5-3q8 0 16.5 3t15.5 10l193 191Zm-136 90Z"
		/>
	</svg>
);
