import { ReactNode } from 'react';

export const symbol_signal_cellular_nodata: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M152-80q-14 0-22-9.5t-8-20.5q0-5 2-10.5t7-10.5l698-698q5-5 10.5-7t10.5-2q11 0 20.5 8t9.5 22v350q0 13-8.5 21.5T850-428q-13 0-21.5-8.5T820-458v-278L224-140h318q13 0 21.5 8.5T572-110q0 13-8.5 21.5T542-80H152Zm608-117-63 62q-9 8-21 8.5t-21-8.5q-9-9-9-21t9-21l63-63-63-63q-9-9-9-21t9-21q9-9 21-9t21 9l63 63 63-63q9-9 21-9t21 9q9 9 8.5 21t-8.5 21l-62 63 62 63q8 9 8.5 21t-8.5 21q-9 9-21 8.5t-21-8.5l-63-62Zm-536 57 596-596-183 183-140.5 140.5-128 128L224-140Z"
		/>
	</svg>
);
