import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import { InheritedMembership } from 'pkg/actions/users';

import * as actions from 'pkg/actions';
import { useEndpoint } from 'pkg/api/use_endpoint';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCurrentRoute } from 'pkg/router/hooks';
import { replaceState } from 'pkg/router/state';
import * as routes from 'pkg/router/routes';

import { Spinner } from 'components/loaders/spinner';

export default function GroupChange() {
	const dispatch = useDispatch();
	const route = useCurrentRoute();
	const groupId = Number.parseInt(route.query.groupId as string, 10);
	const userId = Number.parseInt(route.query.userId as string, 10) || 0;
	const organizationId = Number.parseInt(
		route.query.organizationId as string,
		10
	);

	const { record: group, isLoading } = useEndpoint<models.group.Group>(
		endpoints.Groups.Show(groupId || organizationId)
	);

	const queryMembershipData =
		route.query.membershipData && (route.query.membershipData as string);
	let membershipData: InheritedMembership = null;

	if (route.query.membershipData) {
		membershipData = JSON.parse(queryMembershipData);
	}

	useEffect(() => {
		const setGroup = async () => {
			if (!isLoading) {
				if (organizationId) {
					await dispatch(
						actions.users.setActiveOrganization(organizationId, true)
					);
				} else {
					await dispatch(
						actions.users.setActiveGroup(group, userId, membershipData)
					);
				}

				if (group.organizationId) {
					replaceState(routes.Organization.Home(group.organizationId));
				} else {
					replaceState(routes.Home());
				}
			}
		};

		setGroup();
	}, [isLoading]);

	return <Spinner />;
}
