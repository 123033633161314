import { ReactNode } from 'react';

export const symbol_bia: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M250-220v-100q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T310-320v100h100v-100q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T470-320v100h100v-100q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T630-320v100h190v-310q-35 25-82 37.5T640-480q-56 0-107-15t-86-45H140v320h110Zm10-380h146q-3-9-4.5-19t-1.5-21q0-17 4-31.5t11-28.5H260v100Zm380 60q66 0 123-21.5t57-78.5q0-57-57-78.5T640-740q-66 0-123 21.5T460-640q0 57 57 78.5T640-540ZM140-160q-24.75 0-42.375-17.625T80-220v-320q0-24.75 17.625-42.375T140-600h60v-100q0-24.75 17.625-42.375T260-760h215q34-20 77-30t88-10q91 0 165.5 39.5T880-640v420q0 24.75-17.625 42.375T820-160H140Zm110-60h570-680 110Z"
		/>
	</svg>
);
