import styled from 'styled-components';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import Icon from 'components/icon';

const Wrapper = styled('div')`
	display: flex;
	align-items: center;
	cursor: pointer;

	span {
		color: var(--palette-blue-500);
		margin-left: var(--spacing-2);
	}
`;

interface CollapseExpandableProps {
	isExpanded: boolean;
	onClick: () => void;
}

const CollapseExpandable = ({
	isExpanded,
	onClick,
}: CollapseExpandableProps) => {
	return (
		<Wrapper onClick={onClick}>
			<Icon
				name="chevron"
				rotate={isExpanded ? '-90deg' : '0deg'}
				size={1.75}
				fill={styles.palette.blue[500]}
			/>
			<span>{isExpanded ? t('Collapse all') : t('Expand all')}</span>
		</Wrapper>
	);
};

export default CollapseExpandable;
