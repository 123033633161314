import styled from 'styled-components';

import { Tool, useToolbar } from 'routes/video/analyze/ToolbarState';
import { getSymbol } from 'routes/video/analyze/tool-symbols';

import { usePlaybackState } from 'components/video-analytics/PlaybackState';
import { usePlayerState } from 'components/video-analytics/PlayerState';
import { useClipState } from 'components/video-analytics/ClipState';

const Wrapper = styled.div`
	margin-bottom: var(--spacing-2);
	padding: var(--spacing-1);
	padding-bottom: var(--spacing-3);
	display: grid;
	grid-template-rows: 32px auto;
	gap: 0;
	place-items: center;
	font-size: var(--font-size-2xs);
	color: var(--palette-white);
	cursor: pointer;
	border-radius: var(--radius-3);
	transition: opacity 250ms ease-in-out;

	&[data-is-active='true'] {
		background: var(--palette-blue-600);
	}

	&[data-is-disabled='true'] {
		cursor: not-allowed;
		opacity: 0.3;
	}

	&:not([data-is-disabled='true']):hover {
		background: var(--palette-gray-700);
	}

	&[data-is-active='true']:hover {
		background: var(--palette-blue-500);
	}

	svg {
		padding: var(--spacing-2);
		width: 100%;
		height: 100%;
	}

	span {
		width: 100%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		display: block;
		text-align: center;
	}
`;

export default function ToolbarItem({
	guid,
	symbol,
	label,
	onClick,
}: Tool): JSX.Element {
	const { currentTime } = usePlaybackState();
	const { isTrimming } = usePlayerState();
	const { getStartsAt, getEndsAt } = useClipState();
	const { activeTool, setActiveTool } = useToolbar();

	const insideClipBounds =
		currentTime >= getStartsAt() && currentTime <= getEndsAt();

	const isActive = guid === activeTool;
	const isDisabled = !insideClipBounds || !isTrimming;

	const handleClick = () => {
		if (!isDisabled) {
			setActiveTool(!isActive ? guid : '');

			if (onClick) {
				onClick();
			}
		}
	};

	return (
		<Wrapper
			onClick={handleClick}
			data-is-active={isActive}
			data-is-disabled={isDisabled}
			data-testid={`video.annotations_toolbar.${guid}`}>
			{getSymbol(symbol)}
			<span>{label}</span>
		</Wrapper>
	);
}
