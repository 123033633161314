import { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import * as palette from 'pkg/config/palette';

import CardWrapper, * as Card from 'components/Card';

const FeedItemPlaceholderSvg = styled.svg`
	margin-bottom: 10px;
	max-width: 400px;
`;

const UserRatingsWrapper = styled.div`
	margin-bottom: 10px;
	background: transparent;
`;

const CardBody = styled(Card.Body)`
	padding-top: '40px';
`;

export const CardHeader = styled.div`
	height: 20px;
	width: 150px;
	background-color: ${palette.gray[300]};
	border-radius: var(--radius-2);
`;

export default class Placeholder extends Component {
	static propTypes = {
		numItems: PropTypes.number,
	};

	static defaultProps = {
		numItems: 3,
	};

	render() {
		let placeholderCards = [];

		for (let n = 0, l = this.props.numItems; n < l; n++) {
			placeholderCards.push(this.getPlaceholderItem(n));
		}

		return placeholderCards;
	}
}

export class UserRating extends Placeholder {
	get placeholder() {
		return (
			<Card.Base>
				<Card.Body>
					<svg
						viewBox="0 0 221 40"
						xmlns="http://www.w3.org/2000/svg"
						style={{ maxWidth: '225px' }}>
						<ellipse
							cx="19.9902"
							cy="20"
							rx="19.9902"
							ry="20"
							transform="translate(0.493416 40) scale(1 -1)"
							fill="#C4C4C4"
						/>
						<rect
							width="157.423"
							height="17"
							rx="8.5"
							transform="translate(52.9678 28) scale(1 -1)"
							fill="#C4C4C4"
						/>
					</svg>
				</Card.Body>
			</Card.Base>
		);
	}

	getPlaceholderItem(key) {
		return (
			<UserRatingsWrapper key={`skeleton-card-${key}`}>
				{this.placeholder}
			</UserRatingsWrapper>
		);
	}
}

export class FeedItem extends Placeholder {
	get placeholder() {
		return (
			<Card.Body key="placeholder-feed-item-body">
				<FeedItemPlaceholderSvg
					viewBox="0 0 335 94"
					xmlns="http://www.w3.org/2000/svg">
					<g fill="#CCD5DE">
						<path
							id="Avatar"
							fillRule="evenodd"
							clipRule="evenodd"
							d="M22.5 45C34.9264 45 45 34.9264 45 22.5C45 10.0736 34.9264 0 22.5 0C10.0736 0 0 10.0736 0 22.5C0 34.9264 10.0736 45 22.5 45Z"
						/>
						<path
							id="Author"
							fillRule="evenodd"
							clipRule="evenodd"
							d="M0 6.5C0 2.91015 2.91015 0 6.5 0H105.5C109.09 0 112 2.91015 112 6.5C112 10.0899 109.09 13 105.5 13H6.5C2.91015 13 0 10.0899 0 6.5Z"
							transform="translate(54 8)"
						/>
						<path
							id="Timestamp"
							fillRule="evenodd"
							clipRule="evenodd"
							d="M0 5C0 2.23858 2.23858 0 5 0H77C79.7614 0 82 2.23858 82 5C82 7.76142 79.7614 10 77 10H5C2.23858 10 0 7.76142 0 5Z"
							transform="translate(54 28)"
						/>
						<path
							id="ParagraphOne"
							fillRule="evenodd"
							clipRule="evenodd"
							d="M0 6C0 2.68629 2.68629 0 6 0H329C332.314 0 335 2.68629 335 6C335 9.31371 332.314 12 329 12H6C2.68629 12 0 9.31371 0 6Z"
							transform="translate(0 62)"
						/>
						<path
							id="ParagraphTwo"
							fillRule="evenodd"
							clipRule="evenodd"
							d="M0 6C0 2.68629 2.68629 0 6 0H329C332.314 0 335 2.68629 335 6C335 9.31371 332.314 12 329 12H6C2.68629 12 0 9.31371 0 6Z"
							transform="translate(0 82)"
						/>
					</g>
				</FeedItemPlaceholderSvg>
			</Card.Body>
		);
	}

	get placeholderActions() {
		return [
			<Card.Divider key="placeholder-actions-divider" />,
			<CardBody key="placeholder-actions-body" />,
		];
	}

	getPlaceholderItem(key) {
		return (
			<CardWrapper key={`skeleton-card-${key}`}>
				{[this.placeholder, this.placeholderActions]}
			</CardWrapper>
		);
	}
}
