import { ReactNode } from 'react';

export const symbol_splitscreen_vertical_add: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M780-780H620h4-32 188Zm-248 0q0-24 18-42t42-18h188q24 0 42 18t18 42v410q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T780-370v-410H592v660q-24 0-42-18t-18-42v-600ZM180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h187q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm187-660H180v600h187v-600Zm0 0H180h187Zm413 660h-50q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T730-180h50v-50q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T840-230v50h50q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T890-120h-50v50q0 12.75-8.675 21.375Q822.649-40 809.825-40 797-40 788.5-48.625T780-70v-50Z"
		/>
	</svg>
);
