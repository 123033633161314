import { Fragment } from 'react';

import { PageWidths } from 'pkg/config/sizes';
import spacing from 'pkg/config/spacing';

import * as routes from 'pkg/router/routes';
import * as models from 'pkg/api/models';
import { useCollection } from 'pkg/api/use_collection';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCurrentOrganization } from 'pkg/identity';

import TableHeader from 'routes/event/single/tabs/summary/table-header';
import SummarisedEvents from 'routes/event/single/tabs/summary/summarised-events';
import ResultSummary from 'routes/event/single/tabs/summary/result';
import MatchEvents from 'routes/event/single/tabs/summary/match-events';

import * as Card from 'components/Card';

import Column from 'components/layout/column';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import {
	PageAction,
	usePageActions,
} from 'components/navigation/header/small_screen/page_actions/Context';

import * as css from './styles.css';

interface MatchSummaryProps {
	event: models.event.Event;
	match: models.match.Match;
}

export default function MatchSummary({ event, match }: MatchSummaryProps) {
	const org = useCurrentOrganization();
	const matchEventsCollection = useCollection<models.matchEvent.MatchEvent>(
		endpoints.MatchEvent.Index(match.id)
	);

	let pageActions: PageAction[];

	if (models.canEdit(match)) {
		pageActions = [
			{
				label: 'Edit',
				href: routes.Match.Edit(org.id, event.id, match.id, 'result'),
			},
		];
	}

	usePageActions(pageActions, 'edit');

	return (
		<Fragment>
			<LargeScreenContent.Inner maxWidth={PageWidths.WIDE}>
				<Column spacing={spacing._5}>
					<ResultSummary
						match={match}
						event={event}
						matchEvents={matchEventsCollection.records}
					/>

					<Card.Base key="opponent-table">
						<Card.Body>
							<table className={css.table}>
								<tbody>
									<TableHeader match={match} />

									<SummarisedEvents
										matchEvents={matchEventsCollection.records}
										isHome={match.isHome}
									/>
								</tbody>
							</table>
						</Card.Body>
					</Card.Base>

					<MatchEvents
						key="match-events"
						match={match}
						matchEvents={matchEventsCollection.records}
					/>
				</Column>
			</LargeScreenContent.Inner>
		</Fragment>
	);
}
