import { t } from '@transifex/native';
import { Fragment } from 'react';

import * as styles from 'pkg/config/styles';

import { Account, Group } from 'pkg/api/models/onboarding_info';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';

import * as publicStyles from 'routes/public/styles/styles.css';
import { useOnboardingState } from 'routes/public/hooks';
import Title from 'routes/public/styles/Title';
import CancelButton from 'routes/public/styles/CancelButton';
import UserInformationForm from 'routes/public/steps/shared/UserInformationForm';
import { useOnboardingContext } from 'routes/public/onboarding';

import Column from 'components/layout/column';

export default function UserInformation(): JSX.Element {
	const onboardingState = useOnboardingState();
	const onboardingCtx = useOnboardingContext();

	const accountFromState = onboardingState.get<Account>('account');
	const group = onboardingState.get<Group>('group');

	let title = t('Sign up in {group}', {
		group: group.groupName,
	});
	let description: string = null;

	let role = t(`player`);

	if (accountFromState?.role === 'parent') {
		role = t(`parent`);
	} else if (accountFromState?.role === 'staff') {
		role = t(`coach or staff`);
	}

	if (accountFromState?.role) {
		title = t('Sign up as a {role} in {group}', {
			role,
			group: group.groupName,
		});

		if (accountFromState.role === 'parent') {
			description = t('First, lets create your (parent) account');
		}
	}

	useComponentDidMount(() => {
		// If we're in the SSO flow we need to change back slug
		if (accountFromState?.ssoToken) {
			onboardingCtx.overrideBackSlug('sso-select');
		}
	});

	return (
		<Fragment>
			<Column spacing={styles.spacing._8} className={publicStyles.main}>
				<Title title={title} description={description} />

				<UserInformationForm goToSlug="confirm-user-and-group" />
			</Column>

			<div className={publicStyles.footer}>
				<CancelButton />
			</div>
		</Fragment>
	);
}
