import { Fragment } from 'react';
import { t } from '@transifex/native';

import { useCollection } from 'pkg/api/use_collection';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as routes from 'pkg/router/routes';
import { useCurrentGroup, useCurrentOrganization } from 'pkg/identity';
import { link } from 'pkg/router/utils';

import InvoiceItem from 'routes/organization/contacts/single/list/invoice-item';

import CardAnatomy, * as Card from 'components/Card';
import MissingEntities from 'components/missing-entities';
import SectionTitle from 'components/SectionTitle';
import Icon from 'components/icon';

import Column from 'components/layout/column';

import * as Table from 'design/table';
import Button from 'design/button';

interface OrderListProps {
	user: models.user.User;
	contact: models.user.User;
}

const OrderList: React.FC<React.PropsWithChildren<OrderListProps>> = ({
	user,
	contact,
}) => {
	const org = useCurrentOrganization();

	const params = {
		associated_user_id: user.id,
		group_id: user.organizationId,
		count: '5',
	};

	const orderCollection = useCollection<models.order.Order>(
		endpoints.Orders.Index(),
		{
			showAllResults: true,
			queryParams: new URLSearchParams(params as any),
		}
	);

	const group = useCurrentGroup();

	const tableColumns: Table.HeaderColumn[] = [
		{ content: t('Invoice number'), width: '180px', align: 'left' },
		{ content: t('Amount due'), align: 'left' },
		{ content: t('Status'), width: 'max-content', align: 'left' },
		{ content: t('Billed to'), align: 'left' },
		{ content: t('Products for'), align: 'left' },
		{ content: t('Due date'), align: 'left' },
		{ content: '', width: 'max-content' },
	];

	const content = !orderCollection.records.length ? (
		<CardAnatomy $noBorder>
			<Card.Body>
				<MissingEntities centered>
					<Icon name="order" />
					<p>{t('No invoices found')}</p>
				</MissingEntities>
			</Card.Body>
		</CardAnatomy>
	) : (
		<Card.Base>
			<Table.Table columns={tableColumns}>
				{orderCollection.records
					.sort((a, b) => b.createdAt - a.createdAt)
					.map((order) => (
						<InvoiceItem
							key={order.id}
							order={order}
							userId={user.id}
							currency={group.currency}
							orderCollection={orderCollection}
						/>
					))}
			</Table.Table>
		</Card.Base>
	);

	return (
		<Fragment>
			<Column>
				<SectionTitle icon="request_quote">
					{t('Recent invoices')}
					{orderCollection.records.length === 0 ? (
						<Button
							icon="add"
							iconPosition="left"
							inline
							noPadding
							href={link(routes.Invoice.New(org.id), {
								userIds: [contact.id],
							})}>
							{t('New order')}
						</Button>
					) : (
						<Button
							icon="arrow_forward"
							iconPosition="right"
							inline
							noPadding
							href={routes.Management.Contact.Show(
								org.id,
								user.id,
								'invoices'
							)}>
							{t('View all')}
						</Button>
					)}
				</SectionTitle>
				{content}
			</Column>
		</Fragment>
	);
};

export default OrderList;
