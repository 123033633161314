import { ReactNode } from 'react';

export const symbol_shelf_position: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-121q-24 0-42-18t-18-42v-599q0-24 18-42t42-18h600q24 0 42 18t18 42v599q0 24-18 42t-42 18H180Zm600-201H180v141h600v-141Zm-130-60h130v-398H650v398Zm-470 0h130v-398H180v398Zm190 0h220v-398H370v398Z"
		/>
	</svg>
);
