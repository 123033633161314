import { ReactNode } from 'react';

export const symbol_emergency_share: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-80q85-80 132.5-152T660-354q0-77-52-131.5T480-540q-76 0-128 54.5T300-354q0 50 47.5 122T480-80Zm0-520q101 0 170.5 72T720-354q0 46-21 93t-52.5 89.5q-31.5 42.5-68 80T510-26q-7 6-14.5 8.5T480-15q-8 0-15.5-2.5T450-26q-32-28-68.5-65.5t-68-80Q282-214 261-261t-21-93q0-102 69.5-174T480-600Zm0 290q21 0 35.5-14.5T530-360q0-21-14.5-35.5T480-410q-21 0-35.5 14.5T430-360q0 21 14.5 35.5T480-310Zm1-420q-35 0-67 10.5T355-689q-10 8-22.5 8t-21.5-9q-9-9-9-21t10-20q35-29 78.5-44t90.5-15q47 0 90.5 15t78.5 44q10 8 10 20t-9 21q-9 9-21.5 9t-22.5-8q-27-20-59-30.5T481-730Zm0-160q-66 0-126.5 22T244-804q-10 8-23.5 9t-22.5-8q-9-8-8-20.5t10-20.5q59-51 131-78.5T481-950q78 0 149.5 27T761-846q11 9 12 22t-8 22q-9 9-22 8t-24-9q-50-42-111-64.5T481-890Zm-1 530Z"
		/>
	</svg>
);
