import { ReactNode } from 'react';

export const symbol_sd: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm0-60h680v-520H140v520Zm133-137h127q14 0 27-9.487 13-9.488 13-23.513v-73q0-14.025-13-23.513Q414-496 400-496H290v-57h100q0 7 5 12t11.667 5h16.666Q430-536 435-540.95t5-11.55V-569q0-14.45-13-24.225Q414-603 400-603H273q-14.025 0-23.512 9.775Q240-583.45 240-569v73q0 14.025 9.488 23.513Q258.975-463 273-463h117v56H290q0-7-4.5-11.5t-11.333-4.5h-17.084q-6.833 0-11.958 4.95T240-406.5v16.5q0 14.025 9.488 23.513Q258.975-357 273-357Zm262 0h135q20 0 35-15t15-35v-146q0-20-15-35t-35-15H535q-6 0-10.5 4.5T520-588v216q0 6 4.5 10.5T535-357Zm35-50v-146h100v146H570ZM140-220v-520 520Z"
		/>
	</svg>
);
