import { ReactNode } from 'react';

export const symbol_adaptive_audio_mic_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M785-77 77-784q-9-9-9-21.158 0-12.158 8.609-21Q86-835 98.467-835q12.466 0 21.533 9l707 707q9 9 9 21t-9 21q-9 9-21 9t-21-9ZM680-640q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35Zm0-60q25.5 0 42.75-17.25T740-760q0-25.5-17.25-42.75T680-820q-25.5 0-42.75 17.25T620-760q0 25.5 17.25 42.75T680-700Zm0-60ZM251-454ZM70-394q-12.75 0-21.375-8.625T40-424v-30q0-32 16-58t44-40q42-22 87-33t93-11q16.763 0 32.882 1.5Q329-593 346-590v60q-17-3-33.118-4.5Q296.763-536 280-536q-42 0-80 9t-73 28q-13 7-20 19t-7 26h120q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T220-394H70Zm295-451q17 17 26 38.5t9 46.5q0 20-6.5 39T376-687q-5 8-14.5 7.5T345-687L207-825q-7-7-7-16t7-15q15-11 34-17.5t39-6.5q25 0 46.5 9t38.5 26Zm114.911 562Q451-283 430.5-303.562 410-324.125 410-353v-122q0-13 4.174-24.487Q418.348-510.974 426-520l124 124v43q0 28.875-20.589 49.438Q508.823-283 479.911-283ZM480-80q-8 0-14-6t-6-14v-62q-67-7-114.5-54.5T291-332q-1-8 5-14.5t14-6.5q8.148 0 14.074 6T331-333q8 56 50.189 94T480-201q54.205 0 94.602-34Q615-269 626-320l33 33q-18 51-61 85t-98 40v62q0 8-6 14t-14 6Zm229-374Zm31 60q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T740-454h120q0-14-7-26t-20-19q-35-19-73-27.5t-80-9.5h-29q-12 0-20.5-7.857t-8.5-20Q622-576 630.5-585t20.5-10q8 0 14.5-.5t14.5-.5q48 0 93 11t87 33q28 14 44 40t16 58v30q0 12.75-8.625 21.375T890-394H740Z"
		/>
	</svg>
);
