import { ReactNode } from 'react';

export const symbol_attach_file_add: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M473-80q-103.168 0-175.584-71.5T225-326v-380q0-72.5 51.5-123.25T400-880q72 0 123.5 50.75T575-706v316q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T515-390v-316q0-48-33.5-81t-81.711-33q-48.212 0-81.5 33.06Q285-753.88 285-706v380q0 78 54.971 132T473-140q26 0 50.5-6.5T569-166q11-7 22.5-4t18.5 14q7 11 4 22.5T600-115q-28 17-60 26t-67 9Zm217-40q-12.75 0-21.375-8.5T660-150v-90h-90q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T570-300h90v-90q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T720-390v90h90q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-240h-90v90q0 12-8.625 21T690-120ZM399.825-270Q387-270 378.5-278.625T370-300v-386q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T430-686v386q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Zm290-210Q677-480 668.5-488.625T660-510v-176q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T720-686v176q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Z"
		/>
	</svg>
);
