import { t } from '@transifex/native';
import { useState } from 'react';

import useMixedState from 'pkg/hooks/useMixedState';
import * as models from 'pkg/api/models';
import { useCurrentOrganization } from 'pkg/identity';

import ProductDetails from 'routes/forms/create/products/ProductDetails';
import { SelectedProductInfo } from 'routes/forms/hooks/useFormEngine';

import StepModal, { Step } from 'components/step-modal';

import SelectProduct from 'components/products/SelectProduct';
import useNewProduct from 'components/products/hooks/useNewProduct';

export interface ProductForm {
	singleChoiceValue: string;
	item: string;
	category: string;
	quantity: number;
	taxRateId?: number;
	cost: number;
	billingCycle: string;
	recurringInterval: string;
	recurringIntervalCount: number;
}

interface AddProductsModalProps {
	onClose: () => void;
	onDone: (formState: SelectedProductInfo) => void;
}

const AddProductModal: React.FC<
	React.PropsWithChildren<AddProductsModalProps>
> = ({ onClose, onDone }) => {
	const org = useCurrentOrganization();
	const { onCreateNewProduct, NewProductForm, canSaveCreateProductForm } =
		useNewProduct(org.id);

	const defaultState: SelectedProductInfo = {
		minQuantity: 1,
		maxQuantity: null,
		taxRate: {} as models.taxRate.TaxRate,
		taxRateId: null,
		required: true,
		validTo: 0,
		product: {} as models.product.Product,
		subscriptionStartAt: 0,
		payFirstOrderNow: false,
	};

	const [formState, setFormState] =
		useMixedState<SelectedProductInfo>(defaultState);
	const [createNew, setCreateNew] = useState<boolean>(false);

	const handleDone = async () => {
		onDone(formState);
	};

	const handleCreateNewProduct = async () => {
		const product = await onCreateNewProduct();

		handleSelectProduct(product);
	};

	const handleSelectProduct = (product: models.product.Product) =>
		setFormState({
			product,
		});

	const handlePrev = async () => {
		if (createNew) {
			setCreateNew(false);
			return false;
		}

		return true;
	};

	const resetState = async () => setFormState(defaultState);

	return (
		<StepModal onClose={onClose}>
			{createNew ? (
				<Step
					title={t('Create product')}
					canGoNext={canSaveCreateProductForm}
					onNext={handleCreateNewProduct}
					onPrev={handlePrev}
					nextLabel={t('Create')}>
					{NewProductForm}
				</Step>
			) : (
				<Step
					title={t('Select product')}
					tableStep
					skipBody
					canGoNext={!!formState.product?.id}>
					<SelectProduct
						organizationId={org.id}
						onSelect={handleSelectProduct}
						selectedProductId={formState.product.id || 0}
						setCreateNew={setCreateNew}
						showPrices={false}
					/>
				</Step>
			)}
			<Step
				nextLabel={t('Save')}
				onNext={handleDone}
				title={t('Select product')}
				onPrev={resetState}>
				<ProductDetails formState={formState} setFormState={setFormState} />
			</Step>
		</StepModal>
	);
};

export default AddProductModal;
