export function BundlesPreview(): string {
	return `/v1/payment/bundles`;
}

export function Currencies(): string {
	return `/v1/payment/currencies`;
}

export function Preview(): string {
	return `/v1/payment/cart`;
}
