import styled, { css } from 'styled-components';

import * as breakpoints from 'pkg/config/breakpoints';

const Wrapper = styled.div<{ desktopLeft?: boolean }>`
	background: var(--palette-white);
	overflow: auto;

	@media (max-width: ${breakpoints.toMedium}px) {
		border-top: 1px solid var(--palette-gray-300);
	}

	@media (min-width: ${breakpoints.fromMedium}px) {
		${({ desktopLeft }) =>
			css`
				border-${desktopLeft ? 'right' : 'left'}: 1px solid var(--palette-gray-300);
			`}
	}
`;

export default function Pane({
	visible,
	desktopLeft,
	className,
	children,
}: any) {
	return visible ? (
		<Wrapper desktopLeft={desktopLeft} className={`${className}`}>
			{children}
		</Wrapper>
	) : null;
}

export const Section = styled.div<{ spaced?: boolean; tight?: boolean }>`
	display: flex;
	flex-direction: column;
	padding: var(--spacing-4);
	padding-left: calc(var(--spacing-4) + 1px);
	gap: ${({ spaced }: any) => (spaced ? 'var(--spacing-3)' : '0')};

	p {
		font-size: var(--font-size-sm);
		font-weight: var(--font-weight-bold);
	}

	${({ tight }) =>
		tight
			? css`
					border-top: none;
					padding-top: 0;
				`
			: css`
					&:not(:first-child) {
						border-top: 1px solid var(--palette-gray-300);
					}
				`}
`;
