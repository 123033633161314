import { ReactNode } from 'react';

export const symbol_network_wifi_1_bar_locked: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-740q-108 0-207.5 40T88-594l256 256q28-22 61.5-34t68.5-14q14-1 29-.5t29 4.5q14 4 20.5 17.5T555-336q-3 14-6 28.5t-3 29.5q0 10 1.5 19t3.5 19q2 10 3 19.5t1 19.5q0 28-19.5 44.5T490-140q-14 0-27.5-5.5T438-162L47-553q-8-8-13-19.5T29-596q0-14 5.5-25.5T51-642q91-76 201-117t228-41q118 0 228 41t201 117q11 9 16.5 20.5T931-596q0 12-5 23.5T913-553l-57 57q-8 8-20.5 8t-20.5-8q-8-8-8-20.5t8-20.5l57-57q-85-66-184.5-106T480-740Zm202 620q-17 0-28.5-11.5T642-160v-118q0-17 11.5-28.5T682-318h1v-39q0-34 24-58t58-24q34 0 58 24t24 58v39h1q17 0 28.5 11.5T888-278v118q0 17-11.5 28.5T848-120H682Zm41-198h84v-39q0-19-11.5-30.5T765-399q-19 0-30.5 11.5T723-357v39Z"
		/>
	</svg>
);
