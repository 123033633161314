import { ReactNode } from 'react';

export const symbol_edgesensor_high: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M29.825-280Q17-280 8.5-288.625T0-310v-220q0-12.75 8.675-21.375Q17.351-560 30.175-560 43-560 51.5-551.375T60-530v220q0 12.75-8.675 21.375Q42.649-280 29.825-280Zm120-120Q137-400 128.5-408.625T120-430v-220q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T180-650v220q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM301-80q-24 0-42-18t-18-42v-680q0-24 18-42t42-18h359q24 0 42 18t18 42v680q0 24-18 42t-42 18H301Zm359-90H301v30h359v-30ZM301-790h359v-30H301v30Zm508.825 510Q797-280 788.5-288.625T780-310v-220q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T840-530v220q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Zm120-120Q917-400 908.5-408.625T900-430v-220q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T960-650v220q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM301-790v-30 30Zm0 620v30-30Zm0-60h359v-500H301v500Z"
		/>
	</svg>
);
