// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tnV4nXD_U9sivxp7WyP2 {
	display: grid;
	grid-template-rows: auto auto auto;
	grid-gap: var(--spacing-3);
	gap: var(--spacing-3);
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	text-align: center;
}
.tnV4nXD_U9sivxp7WyP2 svg {
		font-size: 50px;
		margin: 0 auto;
	}
.tnV4nXD_U9sivxp7WyP2 span {
		font-size: var(--font-size-lg);
		font-weight: var(--font-weight-semibold);
	}
.tnV4nXD_U9sivxp7WyP2 p {
		font-size: var(--font-size-base);
		color: var(--palette-gray-600);
	}
`, "",{"version":3,"sources":["webpack://./components/layout/full-page-message/styles.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,kCAAkC;CAClC,0BAAqB;CAArB,qBAAqB;CACrB,wBAAuB;KAAvB,qBAAuB;SAAvB,uBAAuB;CACvB,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CACnB,kBAAkB;AAgBnB;AAdC;EACC,eAAe;EACf,cAAc;CACf;AAEA;EACC,8BAA8B;EAC9B,wCAAwC;CACzC;AAEA;EACC,gCAAgC;EAChC,8BAA8B;CAC/B","sourcesContent":[".wrapper {\n\tdisplay: grid;\n\tgrid-template-rows: auto auto auto;\n\tgap: var(--spacing-3);\n\tjustify-content: center;\n\talign-items: center;\n\ttext-align: center;\n\n\t& svg {\n\t\tfont-size: 50px;\n\t\tmargin: 0 auto;\n\t}\n\n\t& span {\n\t\tfont-size: var(--font-size-lg);\n\t\tfont-weight: var(--font-weight-semibold);\n\t}\n\n\t& p {\n\t\tfont-size: var(--font-size-base);\n\t\tcolor: var(--palette-gray-600);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var wrapper = `tnV4nXD_U9sivxp7WyP2`;
export default ___CSS_LOADER_EXPORT___;
