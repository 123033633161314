import { Fragment, useContext, useState } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import DateTime from 'pkg/datetime';
import * as routes from 'pkg/router/routes';
import { cssClasses } from 'pkg/css/utils';
import { getWeekNumberForDay, isBetween } from 'pkg/date';
import { useCurrentOrganization } from 'pkg/identity';

import RemoveBookingModal from 'routes/scheduling/templates/modals/publish/unpublish-period';
import {
	BookingsContext,
	useBookingsContext,
} from 'routes/scheduling/bookings';

import { LargeScreen } from 'components/MediaQuery';
import Icon from 'components/icon';

import Row from 'components/layout/row';

import * as Context from 'design/context_menu';

import * as css from './styles.css';

type Modals = '' | 'removeBookings';

interface DaysProps {
	index: number;
	dayDate: Date;
	today: Date;

	publishedPeriods: models.schedule.PublishedPeriod[];
	schedules: models.schedule.Schedule[];
}

const Days = ({
	dayDate,
	today,
	publishedPeriods,
	index,
	schedules,
}: DaysProps) => {
	const org = useCurrentOrganization();
	const [modal, setModal] = useState<Modals>('');
	const { refreshPublishedPeriods, refreshBookings } =
		useContext(BookingsContext);
	const dayDateTime = new DateTime(dayDate);
	const todayDateTime = new DateTime(today);

	const startOfDay = Math.round(dayDateTime.startOfDay / 1000);
	const endOfDay = Math.floor(dayDateTime.endOfDay / 1000);

	// Check if there's any published periods this day (you can't publish multiple on the same day)
	const publishedPeriod = publishedPeriods.find(
		(period) =>
			isBetween(startOfDay, period.startsAt, period.endsAt) ||
			dayDateTime.sameDay(period.startsAt * 1000)
	);

	const hasContentOnRightSide = publishedPeriod?.endsAt <= endOfDay;
	const hasContentOnLeftSide =
		publishedPeriod?.startsAt >= startOfDay || index === 0;

	const showRemoveBookingsModal = () => setModal('removeBookings');
	const hideModal = () => setModal('');

	// If the rendered date is in the future or is in the same week as current date and doesn't end before current date
	// we can show the context menu
	const showEditContext =
		dayDateTime.startOfDay > todayDateTime.endOfDay ||
		(getWeekNumberForDay(dayDate) === getWeekNumberForDay(today) &&
			publishedPeriod?.endsAt > Math.round(todayDateTime.startOfDay / 1000));

	return (
		<div className={cssClasses(css['day-wrapper'])}>
			<div
				className={cssClasses(
					css.wrapper,
					!publishedPeriod?.id ? css.empty : '',
					hasContentOnRightSide ? css['right-border'] : '',
					hasContentOnLeftSide ? css['left-border'] : ''
				)}>
				{hasContentOnLeftSide && publishedPeriod?.id && (
					<Row
						className={css['template-row']}
						spacing={styles.spacing._2}
						autoColumns="max-content">
						<Icon
							fill={!publishedPeriod?.id && 'transparent'}
							name="collection"
						/>
						<div className={css.title}>{publishedPeriod.schedule?.title}</div>
						{publishedPeriod.schedule?.links && showEditContext && (
							<Context.Menu toggleWith={<Icon name="context-menu" />}>
								<Context.LinkItem
									href={routes.Templates.Show(
										org.id,
										publishedPeriod.groupId,
										publishedPeriod.schedule.id
									)}>
									<Context.ItemIcon name="edit" />
									{t('Edit')}
								</Context.LinkItem>
								<Context.Item caution onClick={showRemoveBookingsModal}>
									<Context.ItemIcon name="delete" />
									{t('Remove from period')}
								</Context.Item>
							</Context.Menu>
						)}
					</Row>
				)}
			</div>
			{modal === 'removeBookings' && (
				<RemoveBookingModal
					hideModal={hideModal}
					schedule={schedules.find((s) => s.id === publishedPeriod.scheduleId)}
					refreshBookings={refreshBookings}
					refreshPublishedPeriods={refreshPublishedPeriods}
				/>
			)}
		</div>
	);
};

const PublishedPeriods = () => {
	const { publishedPeriods, weekDates, schedules } = useBookingsContext();
	const today = new Date();

	if (publishedPeriods.length === 0) {
		return null;
	}

	return (
		<Fragment>
			<div className={css['week-wrapper']}>
				<LargeScreen>
					<div className={cssClasses(css['day-wrapper'])} />
				</LargeScreen>
				{weekDates.map((w, i) => (
					<Days
						key={i}
						index={i}
						dayDate={w}
						publishedPeriods={publishedPeriods}
						today={today}
						schedules={schedules}
					/>
				))}
			</div>
		</Fragment>
	);
};

export default PublishedPeriods;
