import { useT } from '@transifex/react';

import spacing from 'pkg/config/spacing';

import * as models from 'pkg/api/models';
import { useCurrentAccount } from 'pkg/identity';

import FormInputField from 'routes/forms/registration/form/FormInputField';
import Summary from 'routes/forms/registration/form/products/summary';
import { useRegistrationFormContext } from 'routes/forms/registration/form';

import { useSmallScreen } from 'components/MediaQuery';

import Section from 'components/form/Section';
import Row from 'components/layout/row';
import Column from 'components/layout/column';

interface Props {
	fields: models.form.Field[];
	group: models.group.Group;

	user?: models.user.User;
}

const billingFieldKeys = [
	'user_firstName',
	'user_lastName',
	'user_email',
	'user_phone',
];

const Billing = ({ fields, group }: Props) => {
	const isSmallScreen = useSmallScreen();
	const t = useT();
	const account = useCurrentAccount();

	const discount = useRegistrationFormContext().state.discount;

	let billingUser: models.user.User;

	if (account.users) {
		billingUser = account.users.find(
			(user) => user.organizationId === models.group.getOrganizationId(group)
		);
	}

	const billingFields = fields
		.filter((field) => billingFieldKeys.includes(field.key))
		.map((field) => ({
			...field,
			key: `billing_${field.key}`,
		}));

	return (
		<Row columns="3fr 1.5fr" spacing={spacing._7} collapseOnSmallScreens>
			<Section title={t('Billing contact')}>
				<Column>
					{billingFields.map((field) => (
						<FormInputField
							key={field.key}
							field={field}
							user={billingUser}
							account={account}
						/>
					))}
				</Column>
			</Section>
			<Section title={t('Your cart')} hideDivider={!isSmallScreen}>
				<Summary
					discount={discount}
					currency={group.currency}
					serviceFee={group.serviceFeePercent}
				/>
			</Section>
		</Row>
	);
};

export default Billing;
