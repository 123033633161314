import { t } from '@transifex/native';
import styled from 'styled-components';

import * as styles from 'pkg/config/styles';

import Link from 'pkg/router/Link';
import * as routes from 'pkg/router/routes';
import { useCurrentOrganization } from 'pkg/identity';

import { TableBodyCell } from 'components/group/statistics/Table';

const StatisticsMissingWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	@media ${styles.breakpoint.small} {
		justify-content: flex-start;
	}
`;

const AddLink = styled(Link)`
	margin-left: 5px;

	&::before {
		content: '- ';
	}
`;

const StatisticsMissing = ({ eventId, matchId }) => {
	const org = useCurrentOrganization();

	return (
		<TableBodyCell colSpan="9">
			<StatisticsMissingWrapper>
				{t(`Statistics missing`)}
				<AddLink href={routes.Match.View(org.id, eventId, matchId, 'summary')}>
					{t('Add')}+
				</AddLink>
			</StatisticsMissingWrapper>
		</TableBodyCell>
	);
};

export default StatisticsMissing;
