import { ReactNode } from 'react';

export const symbol_language_pinyin: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M660-620H560v140h100v-140ZM270.175-800q12.825 0 21.325 8.625T300-770v90h40q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T340-620h-40v135l16-10 16-10q8-5 16 .704 8 5.703 8 16.296 0 15-5.339 28.333Q345.321-446.333 333-438q-8 5-16 9.5t-17 9.5v209q0 20-15 35t-35 15h-49q-10.5 0-18.75-6.5t-10.5-16.682Q168-197 177-208.5q9-11.5 24-11.5h9q12 0 21-9t9-21v-140q-10 5-20 8.5t-20 7.5q-11 5-21-1t-12-17.152Q165-404 171.5-414.5 178-425 190-430t24.667-10.409Q227.333-445.818 240-452v-168h-30q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T210-680h30v-90q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625ZM671-684q-11-5-14-15.5t3-20.5q8-12.324 15-25.162T689-771q6.154-10.714 18.077-15.357Q719-791 730.871-786.636 742-783 745.5-772.5T743-752q-8 14.35-16 27.675Q719-711 711-698q-7 11-18 15t-22-1Zm-132-10.5q-11 5.5-22.5 3T497-705q-7.758-10.75-16-21.5T465-748q-8-9-6-20.5t12.733-16.633Q484-791 496.5-788t20.5 14q8 10.333 15 20.667Q539-743 547-733q7 10 5 21.5t-13 17ZM405.435-161q-12.935 0-21.685-9-8.75-9-8.75-21.24 0-7.92 3.611-14.04 3.611-6.12 9.389-9.72 46-35 76-91.5T499-420h-49q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T450-480h50v-140h-30q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T470-680h280q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T750-620h-30v140h50q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T770-420h-50v230q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T660-190v-230H559q-6 70-42.5 140.5T423-167q-4.062 2.571-8.531 4.286Q410-161 405.435-161Z"
		/>
	</svg>
);
