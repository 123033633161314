import { ReactNode } from 'react';

export const symbol_back_hand: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M507-40q-93 0-171.5-47.5T209-215L72-443q-5-9-4-19t9-18l3-3q16-16 38-17.5t40 11.5l122 90v-411q0-12 8.5-21t21.5-9q12 0 21 9t9 21v469q0 19-16.5 27t-31.5-3l-131-97 98 164q38 69 104 109.5T507-100q113 0 193-78t80-190v-402q0-12 8.5-21t21.5-9q12 0 21 9t9 21v402q0 137-97.5 232.5T507-40Zm-30-880q13 0 21.5 9t8.5 21v370q0 13-8.5 21.5T477-490q-13 0-21.5-8.5T447-520v-370q0-12 9-21t21-9Zm167 40q12 0 21 9t9 21v330q0 13-8.5 21.5T644-490q-13 0-21.5-8.5T614-520v-330q0-12 8.5-21t21.5-9ZM471-295Z"
		/>
	</svg>
);
