import { ReactNode } from 'react';

export const symbol_filter_8: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M485-338h110q24 0 42-18t18-42v-95q0-27-14.5-43T606-552q20 0 34.5-13.5T655-606v-99q0-24-18-42t-42-18H485q-24 0-42 18t-18 42v99q0 27 15 40.5t35 13.5q-20 0-35 16t-15 43v95q0 24 18 42t42 18Zm0-367h110v123H485v-123Zm0 307v-124h110v124H485ZM260-200q-24 0-42-18t-18-42v-560q0-24 18-42t42-18h560q24 0 42 18t18 42v560q0 24-18 42t-42 18H260Zm0-60h560v-560H260v560ZM140-80q-24 0-42-18t-18-42v-590q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-730v590h590q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q760-97 751.375-88.5T730-80H140Zm120-740v560-560Z"
		/>
	</svg>
);
