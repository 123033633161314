import { ReactNode } from 'react';

export const symbol_contrast_circle: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm-.189-60Q622-140 721-238.812 820-337.625 820-480q0-68-25.5-130T721-720L240-240q47.75 47.872 109.825 73.936Q411.9-140 479.811-140ZM514-319q-10.833 0-17.917-7.116-7.083-7.117-7.083-18Q489-355 496.083-362q7.084-7 17.917-7h146q10.833 0 17.917 7.116 7.083 7.117 7.083 18Q685-333 677.917-326q-7.084 7-17.917 7H514ZM324-587v62q0 10.833 7.116 17.917 7.117 7.083 18 7.083Q360-500 367-507.083q7-7.084 7-17.917v-62h63q10.833 0 17.917-7.116 7.083-7.117 7.083-18Q462-623 454.917-630q-7.084-7-17.917-7h-63v-62q0-10.833-7.116-17.917-7.117-7.083-18-7.083Q338-724 331-716.917q-7 7.084-7 17.917v62h-62q-10.833 0-17.917 7.116-7.083 7.117-7.083 18Q237-601 244.083-594q7.084 7 17.917 7h62Z"
		/>
	</svg>
);
