import { ReactNode } from 'react';

export const symbol_gradient: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M437-438v-86h85v86h-85Zm-86 86v-86h86v86h-86Zm171 0v-86h86v86h-86Zm86-86v-86h86v86h-86Zm-342 0v-86h85v86h-85Zm-86 318q-24.75 0-42.375-17.625T120-180v-600q0-24.75 17.625-42.375T180-840h600q24.75 0 42.375 17.625T840-780v600q0 24.75-17.625 42.375T780-120H180Zm86-60h85v-86h-85v86Zm171 0h85v-86h-85v86Zm343 0v-86 86Zm-600-86h86v-86h85.333v86h85.334v-86H522v86h86v-86h86v86h86v-86h-86v-86h86v-342H180v342h86v86h-86v86Zm0 86v-600 600Zm600-258v86-86ZM608-266v86h86v-86h-86Z"
		/>
	</svg>
);
