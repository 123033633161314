import { Fragment, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import UserGoal from 'pkg/models/user_goal';

import { updateGoal, removeGoal } from 'pkg/actions/user_goals';

import useConfirm from 'pkg/hooks/useConfirm';
import { useCheckRelationToUser, useUser } from 'pkg/hooks/selectors';

import * as Card from 'components/Card';
import { FormattedContent } from 'components/formatted-content';
import Label from 'components/label';

import { UserGoalForm } from 'components/user/profile/development/goals/Form';
import ProgressBar from 'components/user/profile/development/goals/Progress';
import Column from 'components/layout/column';

import * as ContextMenu from 'design/context_menu';

const ActionsWrapper = styled.div`
	display: grid;
	grid-gap: var(--spacing-3);
	grid-auto-flow: column;
	align-items: center;
`;

const colors: { [key: string]: string } = {
	general: 'gray',
	tactical: 'green',
	technical: 'orange',
	physical: 'blue',
	mental: 'purple',
	social: 'yellow',
};

type GoalProps = {
	goal: UserGoal;
};

const SingleGoal = ({ goal }: GoalProps): JSX.Element => {
	const [showModal, setShowModal] = useState(false);
	const dispatch = useDispatch();

	const user = useUser(goal.userId);

	const { isParentToUser } = useCheckRelationToUser(goal.userId);

	const forAccount = isParentToUser ? user.accountId : null;

	const handleIncrement = (): void => {
		const updatedGoal = goal.set('progress', goal.progress + 10);

		dispatch(updateGoal(updatedGoal, forAccount));
	};

	const handleDecrement = (): void => {
		if (goal.progress <= 0) {
			return;
		}

		const updatedGoal = goal.set('progress', goal.progress - 10);

		dispatch(updateGoal(updatedGoal, forAccount));
	};

	const handleCompleteGoal = (): void => {
		const completedGoal = goal.set('progress', 100);

		dispatch(updateGoal(completedGoal, forAccount));
	};

	const updateUserGoal = async (goal: UserGoal): Promise<void> => {
		await dispatch(updateGoal(goal, forAccount));
		setShowModal(false);
	};

	const deleteGoal = useConfirm({
		message: t(`Are you sure you want to delete this goal?`),
		onConfirm: async () => {
			dispatch(removeGoal(goal, forAccount));
		},
	});

	const toggleModal = (): void => {
		if (showModal) {
			setShowModal(false);
		} else {
			setShowModal(true);
		}
	};

	const translatedGoalCategory = (cat: string): string => {
		switch (cat) {
			case 'general':
				return t('General');
			case 'mental':
				return t('Mental');
			case 'physical':
				return t('Physical');
			case 'social':
				return t('Social');
			case 'tactical':
				return t('Tactical');
			case 'technical':
				return t('Technical');
		}
	};

	const headerContent = (
		<Fragment>
			<Card.Heading>{goal.goal}</Card.Heading>

			<ActionsWrapper>
				<Label color={colors[goal.category]}>
					{translatedGoalCategory(goal.category)}
				</Label>
				<ContextMenu.Menu
					toggleWith={
						<ContextMenu.ButtonTrigger
							icon="more_horiz"
							testid="goals.open_actions_button"
						/>
					}>
					<ContextMenu.Item onClick={toggleModal} icon="edit">
						{t('Edit')}
					</ContextMenu.Item>
					<ContextMenu.Item onClick={handleCompleteGoal} icon="check">
						{t(`Complete goal`)}
					</ContextMenu.Item>
					<ContextMenu.Divider />
					{goal?.links.delete && (
						<ContextMenu.Item caution onClick={deleteGoal} icon="delete">
							{t('Delete')}
						</ContextMenu.Item>
					)}
				</ContextMenu.Menu>
			</ActionsWrapper>
		</Fragment>
	);

	return (
		<Fragment>
			<Card.Base data-user-goal $noBorder>
				{goal.description.length > 0 ? (
					<Fragment>
						<Card.Header>{headerContent}</Card.Header>
						<Card.Divider />
					</Fragment>
				) : (
					<Card.Header $slim>{headerContent}</Card.Header>
				)}

				<Card.Body key="description-body">
					<Column spacing={styles.spacing._5}>
						<FormattedContent raw={goal.description} />
						<ProgressBar
							goal={goal}
							handleIncrement={handleIncrement}
							handleDecrement={handleDecrement}
						/>
					</Column>
				</Card.Body>
			</Card.Base>
			{showModal && (
				<UserGoalForm
					activeGoal={goal}
					close={toggleModal}
					saveGoal={updateUserGoal}
				/>
			)}
		</Fragment>
	);
};

export default SingleGoal;
