import { ReactNode } from 'react';

export const symbol_museum: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M80-80v-60h83v-411h-54q-12.352 0-20.676-9Q80-569 80-581q0-8.308 3.667-15.231Q87.333-603.154 94-608l351-248q8-5 16.809-8 8.808-3 18-3Q489-867 498-864q9 3 17 8l351 248q6.667 4.9 10.333 12.25Q880-588.4 880-580q0 12.352-8.324 20.676Q863.352-551 851-551h-53v411h82v60H80Zm658-60v-485L480-806 223-625v485h515ZM480-304q8 0 14-3t11-10l72-108v154q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T637-271v-217q0-11.455-7.773-19.227Q621.455-515 610-515h-23.404q-6.456 0-12.508 3.2-6.053 3.2-10.088 8.8l-84 128-83-126q-5-7-11.75-10.5T371-515h-15.136q-13.542 0-22.703 9.2T324-483v212q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T384-271v-154l71 107q4.545 7 10.985 10.5Q472.424-304 480-304Zm258 164H223h515Z"
		/>
	</svg>
);
