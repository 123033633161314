import { ReactNode } from 'react';

export const symbol_nest_thermostat_gen_3: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm-.221-60Q621-140 720.5-239.279q99.5-99.28 99.5-240.5Q820-621 720.721-720.5t-240.5-99.5Q339-820 239.5-720.721t-99.5 240.5Q140-339 239.279-239.5q99.28 99.5 240.5 99.5Zm.221-60q-116 0-198-82t-82-198q0-116 82-198t198-82q116 0 198 82t82 198q0 116-82 198t-198 82Zm.177-60Q571-260 635.5-324.677q64.5-64.677 64.5-155.5T635.323-635.5q-64.677-64.5-155.5-64.5T324.5-635.323q-64.5 64.677-64.5 155.5T324.677-324.5q64.677 64.5 155.5 64.5Z"
		/>
	</svg>
);
