import { ReactNode } from 'react';

export const symbol_microbiology: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M600-90q-12 0-21-9t-9-21v-41q-28-3-53.017-12.911Q491.966-183.821 470-201l-39 39q-9.067 8.25-21.533 8.625Q397-153 388-162.053q-9-9.052-9-21.5Q379-196 388-205l40-39q-4.667-6.875-9.333-14.438Q414-266 410-274l-31-62-51 51q-9.273 8.25-21.636 8.625Q294-276 285-285t-9-21q0-12 9-21l51-52-62-31q-7-4-13.5-7.5T248-426l-35 35q-9.067 8.25-21.533 8.625Q179-382 170-391q-9-9-9-21t9-21l34-34q-17-22-28.5-48T161-570h-41q-12 0-21-9t-9-21.5q0-12.5 9-21t21-8.5h43.2q4.8-24 14.3-46t23.5-41l-34-34q-9-9-9-21t9-21q9-9 21-9t21 9l34 34q19-14 41-23.5t46-14.3V-840q0-12.75 9-21.375T360.5-870q12.5 0 21 8.625T390-840v41q29 3 55.008 14.531Q471.016-772.938 494-755l34-34q9.067-9 21.533-9Q562-798 571-789q9 9 9 21t-9 21l-36 36q4 6 7.929 11.846Q546.857-693.308 550-686l30 60 48-49q9.067-9 21.533-9Q662-684 671-674.947q9 9.052 9 21.5Q680-641 671-632l-50 49 65 33 16 10 16 10 39-39q9-9 21-9t21 9.053q9 9.052 9 21.5Q808-535 799-526l-39 38q16 21 26 46t13 52h41q12.75 0 21.375 8.625T870-360q0 12-8.625 21T840-330h-43.2q-4.8 24-13.8 46t-23 41l32 32q9 9.067 9 21.533Q801-177 791.947-168q-9.052 8.25-21.5 8.625Q758-159 749-168l-32-33q-19 14-41 23.5t-46 14.3v43.2q0 12-8.625 21T600-90Zm-6-130q69 0 112-51.5T738-389q-5.875-35-26.438-63Q691-480 659-496l-66-34q-19.933-10.531-35.967-26.765Q541-573 530-593l-34-66q-19-38-53.688-59.5Q407.625-740 366-740q-69 0-112 51.5T222-571q5.875 35 26.438 63Q269-480 301-464l66 33q20.317 10.776 36.658 27.388Q420-387 431-367l33 66q19 38 53.688 59.5Q552.375-220 594-220ZM380-540q25 0 42.5-17.5T440-600q0-25-17.5-42.5T380-660q-25 0-42.5 17.5T320-600q0 25 17.5 42.5T380-540Zm200 250q21 0 35.5-14.5T630-340q0-21-14.5-35.5T580-390q-21 0-35.5 14.5T530-340q0 21 14.5 35.5T580-290ZM480-480Z"
		/>
	</svg>
);
