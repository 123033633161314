import { ReactNode } from 'react';

export const symbol_humerus_alt: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M393-339q13 0 21.5-9t8.5-21q0-2-.5-5t-1.5-5q3 2 7 3t8 1q13 0 21.5-9t8.5-21q0-12-9-21t-21-9q-7 0-12.5 3t-10.5 8L300-543q7-4 11.5-11t4.5-15q0-12-9-21t-21-9q-12 0-21 8.5t-9 21.5q0 3 .5 6t1.5 5q-3-2-6.5-3t-8.5-1q-12 0-21 8.5t-9 21.5q0 12 9 21t21 9q8 0 15-3.5t11-10.5l113 119q-8 3-13.5 10.5T363-369q0 12 9 21t21 9Zm101 219q-28 0-55.5-10T390-160L99-432q-8-8-9.5-18.5T94-470q8-12 22-14t25 8l290 272q13 12 30 18t34 6q14 0 25-2.5t23-10.5l291-183q15-10 30.5-1.5T880-351q0 8-3.5 14.5T866-325L575-143q-18 11-38 17t-43 6Zm41-441q38-45 80.5-65.5T739-669q26-7 48-14t42-15q11-5 22.5 0t16.5 16q5 11 0 22.5T852-643q-22 8-46 15.5T754-612q-86 23-127.5 47.5T548-479q-4 6-10.5 9.5T523-466q-10 0-18.5-7T494-490q-19-71-73.5-136.5T294-735q-11-6-14-17.5t4-22.5q7-11 19-14.5t23 3.5q68 41 125.5 100.5T535-561Zm-421 59q72 67 158.5 149T431-204q13 12 30 18t34 6q14 0 25-2.5t23-10.5q75-48 167.5-106T880-405L543-193q-12 8-23 10.5t-25 2.5q-17 0-34-6t-30-18L114-502Z"
		/>
	</svg>
);
