import { ReactNode } from 'react';

export const symbol_ophthalmology: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M400-840q9-9 21.5-9t21.5 9l28 28q10 10 13.5 23.5T483-761q-5 14-15.5 23T443-727q-72 11-127 58.5T240-551q-3 10-10.5 15.5T212-530h-62q-12 0-21-8.5t-9-21.5q0-13 8.5-21.5T150-590h40q30-72 89.5-122T414-783l-14-14q-9-9-9-21.5t9-21.5Zm210 360q0-44 19-82.5t56-60.5q-22-27-47.5-49.5T580-709q-11-5-17.5-16t-2.5-23q4-12 15.5-17.5t23.5-.5q50 21 89 58t65 85q39 18 58 58.5t19 84.5q0 45-18.5 85T753-337q-26 48-65 85t-89 58q-12 5-23.5-.5T560-212q-4-12 2.5-23t18.5-16q32-14 57-35.5t47-50.5q-42-25-58.5-60.5T610-480Zm110 90q20 0 35-27.5t15-62.5q0-35-15-62.5T720-570q-20 0-35 27.5T670-480q0 35 15 62.5t35 27.5ZM395-115q-9-9-9-21.5t9-21.5l19-19q-75-21-135-71t-89-122h-40q-12 0-21-8.5t-9-21.5q0-13 8.5-21.5T150-430h62q10 0 17.5 5.5T240-409q21 70 75.5 117.5T442-233q14 2 25 11t16 23q5 14 1.5 27T471-149l-34 34q-9 9-21 9t-21-9Zm325-365Z"
		/>
	</svg>
);
