import { ReactNode } from 'react';

export const symbol_south_east: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M658-260 181-737q-9-9-9-21t9-21q9-9 21-9t21 9l477 477v-264q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T760-566v336q0 12.75-8.625 21.375T730-200H394q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T394-260h264Z"
		/>
	</svg>
);
