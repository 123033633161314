import { t } from '@transifex/native';

import { useOrderReport } from 'routes/payments/overview/hooks';
import { DateSpanLabel } from 'routes/payments/overview/widgets';

import * as DataCard from 'design/data_card';

export default function RefundsWidget(): JSX.Element {
	const { refundRatio } = useOrderReport();
	const { paid, refunded } = refundRatio;

	const ratio = paid && refunded ? (refunded / paid) * 100 : 0;

	return (
		<DataCard.WithLargeIcon
			icon="redo"
			title={t('Refund ratio')}
			footer={<DateSpanLabel />}
			noFooterDivider
			heading={`${ratio.toFixed(1)}%`}
			headingSuffix={t('{refunded} of {paid}', { refunded, paid })}
		/>
	);
}
