import { ReactNode } from 'react';

export const symbol_build: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M354-370q-97.083 0-165.042-67.667Q121-505.333 121-602q0-20 3-37.5t11-36.5q3-8 8.938-12.167Q149.875-692.333 157-694q6.75-2 13.875.091T184-686l113 113 92-86-118-118q-5.818-6-7.909-13.125T263-804q1.667-6.75 5.833-12.375Q273-822 281-825q19-8 36-11.5t36.846-3.5q99.231 0 168.692 69.417Q592-701.167 592-602q0 24-5 47t-13 46l221 221q27 26 26.5 63.5T793-161q-26 24-61.5 23.5T670-164L447-388q-23 8-46 13t-47 5Zm0-60q25 0 53-8t49-24l257 257q8 8 20 8t20-8q8-8 8-20t-8-20L500-498q16-21 24-49.5t8-54.5q0-75-55.5-127T350-782l102 104q9 9 8.5 21.5T451-635L318-510q-9.273 8-21.636 8Q284-502 276-510l-98-97q3 77 54.668 127T354-430Zm117-58Z"
		/>
	</svg>
);
