/* Static required flags, do not edit these */
export const isDev = (): boolean => window.TS.dev;
export const isBeta = (): boolean => window.TS.beta || isDev();
export const isDevServer = (): boolean => window.TS.devServer;
export const isCypress = (): boolean => !!window.Cypress;

/**
 *	Flags created with createFlag, used to list them in the developer menu.
 */
export const availableFlags: string[] = [];

/**
 * @type FlagCondition boolean or computed boolean
 */
type FlagCondition = boolean | (() => boolean);

/**
 * 	Creates a flag with initial value, the value is stored in localstorage to allow for a toggleable state.
 *	Returns a function that computes it's current value (which in turn returns a boolean).
 *
 *	@param flagName string
 *	@param flagCondition boolean | Flag
 *
 *	@returns () => boolean
 */
export function createFlag(
	flagName: string,
	flagCondition: FlagCondition
): () => boolean {
	const flagKey = `ts:flag:${flagName}`;
	let computedFlagCondition: boolean;

	availableFlags.push(flagName);

	if (typeof flagCondition === 'boolean') {
		computedFlagCondition = flagCondition;
	} else {
		computedFlagCondition = flagCondition();
	}

	if (!sessionStorage.getItem(flagKey)) {
		sessionStorage.setItem(flagKey, computedFlagCondition.toString());
	}

	return () => (sessionStorage.getItem(flagKey) ?? 'false') === 'true';
}

export function getFlag(flagName: string): boolean {
	const flagKey = `ts:flag:${flagName}`;
	return (sessionStorage.getItem(flagKey) ?? 'false') === 'true';
}

export function setFlag(flagName: string, value: boolean) {
	const flagKey = `ts:flag:${flagName}`;
	sessionStorage.setItem(flagKey, value.toString());
}

/* Add developer flags below this line */

export const aggregatedUserProfile = createFlag(
	'aggregatedUserProfile',
	isDev()
);

export const userFieldCategoriesEnabled = createFlag(
	'userFieldCategoriesEnabled',
	isDev()
);

export const paymentMethodsFlag = createFlag('paymentMethodsEnabled', isDev());
export const revenueTableFlag = createFlag('revenueTable', isDev());

export const intercom = createFlag('Intercom', isDev());
