import { ReactNode } from 'react';

export const symbol_developer_mode_tv: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m164-520 97-97q9-9 9-21t-9-21q-9-9-21-9t-21 9L101-541q-9 9-9 21t9 21l118 118q9 9 21 9t21-9q9-9 9-21t-9-21l-97-97Zm632 0-97 97q-9 9-9 21t9 21q9 9 21 9t21-9l118-118q9-9 9-21t-9-21L741-659q-9-9-21-9t-21 9q-9 9-9 21t9 21l97 97ZM80-693v-87q0-23 18-41.5t42-18.5h680q23 0 41.5 18.5T880-780v87q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T820-693v-87H140v87q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Q97-663 88.5-671.625T80-693Zm280 573q-12.75 0-21.375-8.625T330-150v-50H140q-24 0-42-18.5T80-260v-93q0-13.077 8.675-21.538 8.676-8.462 21.5-8.462 12.825 0 21.325 8.625T140-353v93h680v-93q0-13.077 8.675-21.538 8.676-8.462 21.5-8.462 12.825 0 21.325 8.625T880-353v93q0 23-18.5 41.5T820-200H630v50q0 12.75-8.625 21.375T600-120H360Zm120-403Z"
		/>
	</svg>
);
