import { ReactNode } from 'react';

export const symbol_open_in_new_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m792-83-38-37H180q-24 0-42-18t-18-42v-574l-38-38q-9-9.067-9-21.533Q73-826 82.053-835q9.052-9 21.5-9Q116-844 125-835l710 710q9 9 9 21t-9.053 21q-9.052 9-21.5 9Q801-74 792-83Zm-612-97h514L459-416l-56 56q-9 9-21 8.5t-21-9.5q-9-9-9-21t9-21l55-55-236-236v514Zm249-600H267q-15 0-22.5-9.318t-7.5-20.5q0-11.182 7.5-20.682Q252-840 267-840h162q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T429-780Zm351 43L566-523q-9 9-21 9t-21-9q-9-9-9-21.5t9-21.5l214-214H549q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T549-840h261q12.75 0 21.375 8.625T840-810v261q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T780-549v-188Zm60 308v161q0 15-9.318 22.5t-20.5 7.5Q799-238 789.5-245.683 780-253.366 780-268v-161q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T840-429Z"
		/>
	</svg>
);
