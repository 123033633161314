import { t } from '@transifex/native';
import { Fragment, useEffect, useReducer, useCallback, useState } from 'react';
import { List } from 'immutable';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import * as styles from 'pkg/config/styles';

import Video from 'pkg/models/video';

import * as selectors from 'pkg/selectors/index';

import * as actions from 'pkg/actions/index';

import { RootState } from 'pkg/reducers';
import * as models from 'pkg/api/models';
import { pushState } from 'pkg/router/state';
import * as routes from 'pkg/router/routes';
import { useCheckRelationToUser, useUser } from 'pkg/hooks/selectors';
import { useCurrentMembership, useCurrentOrganization } from 'pkg/identity';

import VideoList from 'containers/video/List';

import TabSwitcher from 'components/TabSwitcher';
import { LargeScreen } from 'components/MediaQuery';
import Icon from 'components/icon';

import VideoModal from 'components/video-library/modals/Video';

import * as ContextMenu from 'design/context_menu';
import Button from 'design/button';

const Top = styled.div`
	display: grid;
	grid-template-columns: 100px auto 100px;
	margin-bottom: var(--spacing-6);

	${TabSwitcher} {
		grid-column-start: 2;
	}

	@media ${styles.breakpoint.small} {
		grid-template-columns: auto 1fr;
		margin: 0 auto var(--spacing-6) auto;
	}
`;

interface VideosProps {
	userId: number;
	isSelf: boolean;
	setPageActions: (trigger: JSX.Element) => void;
}

interface ResultProps {
	type: string;
	payload?: number[];
	videoId?: number;
}

const Videos = ({
	userId,
	isSelf,
	setPageActions,
}: VideosProps): JSX.Element => {
	const dispatch = useDispatch();

	const org = useCurrentOrganization();
	const [isFetching, setIsFetching] = useState<boolean>(true);
	const [currentTab, setCurrentTab] = useState<string>('tagged');
	const [showModal, setShowModal] = useState<boolean>(false);
	const [nextLink, setNextLink] = useState<string>('');

	const [resultIDs, dispatchResultIDs] = useReducer(
		(state: number[], { type, payload, videoId }: ResultProps) => {
			switch (type) {
				case 'append':
					return (state = [...new Set([...state, ...payload])]);
				case 'reset':
					return (state = [...new Set([...payload])]);
				case 'remove': {
					const index = state.indexOf(videoId);
					if (index > -1) {
						state.splice(index, 1);
					}

					return (state = [...new Set([...state])]);
				}
			}
		},
		[]
	);

	const taggedVideos: List<Video> = useSelector((state: RootState) =>
		selectors.userVideos.findTaggedVideos(state)
	);

	const createdVideos: List<Video> = useSelector((state: RootState) =>
		selectors.videos.findAll(state, resultIDs)
	);

	const loadMoreEndpointUrl = useSelector((state: RootState) =>
		selectors.userVideos.getNextLink(state)
	);

	const activeMembership = useCurrentMembership();
	const user = useUser(userId);

	const { isParentToUser } = useCheckRelationToUser(userId);
	const forAccount = isParentToUser ? user.accountId : null;

	const fetchData = useCallback(
		async (isNext = false) => {
			if (isNext) {
				setIsFetching(true);
			}

			const [req, col] =
				currentTab === 'tagged'
					? await actions.userVideos.fetchTaggedVideos(
							userId,
							activeMembership.groupId,
							isNext ? nextLink : '',
							forAccount
						)(dispatch)
					: await actions.videos.fetchPersonalVideos(
							10,
							isNext ? nextLink : '',
							forAccount
						)(dispatch);

			if (req.ok) {
				dispatchResultIDs({
					type: isNext ? 'append' : 'reset',
					payload: Object.values(col.records).map((r: Video) => r.id),
				});
			}

			if (col.links?.next) {
				setNextLink(col.links.next);
			} else {
				setNextLink(null);
			}

			setIsFetching(false);
		},
		[dispatch, currentTab, userId, nextLink, activeMembership.groupId]
	);

	useEffect((): void => {
		dispatchResultIDs({
			type: 'reset',
			payload: [],
		});

		fetchData();
	}, [fetchData]);

	useEffect((): (() => void) => {
		if (isSelf) {
			setPageActions(
				<ContextMenu.Menu toggleWith={<Icon name="context-menu" />}>
					<ContextMenu.Item onClick={handleAddVideo}>
						<ContextMenu.ItemIcon name="smart_display" />
						{t(`Add video`)}
					</ContextMenu.Item>
				</ContextMenu.Menu>
			);
		}

		return () => {
			setPageActions(null);
		};
	}, [isSelf]);

	const fetchMoreVideos = (): Promise<void> => fetchData(true);

	const handleChangeTab = (): void =>
		setCurrentTab((prev: string) => (prev === 'tagged' ? 'my' : 'tagged'));

	const handleAddVideo = (): void => setShowModal(true);

	const handleCloseModal = (): void => setShowModal(false);

	const afterSave = (video: models.video.Video) => {
		pushState(routes.Video.Show(org.id, video.id));
	};

	return (
		<Fragment>
			{isSelf && (
				<Top>
					<TabSwitcher
						dark
						active={currentTab}
						tabs={{
							tagged: t(`Tagged in`),
							my: t(`My videos`),
						}}
						onChange={handleChangeTab}
					/>
					<LargeScreen>
						<Button
							onClick={handleAddVideo}
							testid="user_profile.add_video_button">
							{t(`Add video`)}
						</Button>
					</LargeScreen>
				</Top>
			)}

			<VideoList
				linkToTaggedUser
				title={t(`Videos`)}
				videos={currentTab === 'tagged' ? taggedVideos : createdVideos}
				activeMembership={activeMembership}
				isFetching={isFetching}
				next={!!loadMoreEndpointUrl}
				openAddVideo={isSelf && handleAddVideo}
				onNext={fetchMoreVideos}
			/>

			{showModal && (
				<VideoModal onClose={handleCloseModal} afterSave={afterSave} />
			)}
		</Fragment>
	);
};

export default Videos;
