import { useRef, useEffect } from 'react';
import styled from 'styled-components';
import VimeoPlayer, { Error as VimeoError } from '@vimeo/player';

import { ContainerProps } from 'components/video-analytics/Container';
import { usePlayerState } from 'components/video-analytics/PlayerState';
import { usePlaybackState } from 'components/video-analytics/PlaybackState';
import { VimeoUrl } from 'components/video-analytics/source/SourceUrl';
import { useCueState } from 'components/video-analytics/CueState';
import { ControllerInterfaceType } from 'components/video-analytics/Controller';

const Wrapper = styled.div`
	width: 100%;
	height: 100%;
`;

interface VimeoContainerProps extends ContainerProps {
	source: VimeoUrl;
}

interface VimeoTimeData {
	seconds: number;
	percent: number;
	duration: number;
}

export default function VimeoContainer({
	source,

	onReady,
	onBuffering,
	onError,
	onHasDuration,
	onPlay,
	onPause,
	onTimeUpdate,
}: VimeoContainerProps): JSX.Element {
	const nodeRef = useRef<HTMLDivElement>();

	const cueState = useCueState();
	const playerState = usePlayerState();
	const playbackState = usePlaybackState();

	const playerRef = useRef<VimeoPlayer>();

	const handleTimeUpdate = async ({ seconds }: VimeoTimeData) => {
		if (playerRef.current) {
			playbackState.setCurrentTime(seconds);

			if (cueState.currentCue && !playerState.gaplessPlayback) {
				const { endsAt } = cueState.currentCue;

				if (endsAt && seconds >= endsAt) {
					cueState.setCueActive(false);
				}
			}

			if (onTimeUpdate) {
				onTimeUpdate(seconds);
			}
		}
	};

	const handlePlay = () => {
		playerState.setPlaying(true);

		if (onPlay) {
			onPlay();
		}
	};

	const handlePause = () => {
		playerState.setPlaying(false);

		if (onPause) {
			onPause();
		}
	};

	const handleError = (error: VimeoError) => {
		const playbackAbortingErrors: string[] = [
			'PrivacyError',
			'PasswordError',
			'UnsupportedError',
		];

		const autoPlaybackErrors: string[] = ['NotAllowedError'];

		// @NOTE Suppress error when auto play is blocked
		if (autoPlaybackErrors.includes(error.name)) return;

		if (playbackAbortingErrors.includes(error.name)) {
			playerState.setHasError(true);

			if (onError) {
				onError();
			}
		}
	};

	const handleBuffering = () => {
		playerState.setBuffering(true);

		if (onBuffering) {
			onBuffering();
		}
	};

	const handleResume = () => {
		playerState.setBuffering(false);
	};

	useEffect(() => {
		const asyncEffect = async () => {
			playerRef.current = new VimeoPlayer(nodeRef.current, {
				url: source.getSourceUrl(),
				responsive: true,
				controls: false,
				title: false,
				byline: false,
				autoplay: true,
			});

			await playerRef.current.ready();

			playerState.setReady(true);

			if (onReady) {
				onReady();
			}

			playerRef.current.on('timeupdate', handleTimeUpdate);
			playerRef.current.on('play', handlePlay);
			playerRef.current.on('pause', handlePause);
			playerRef.current.on('error', handleError);
			playerRef.current.on('bufferstart', handleBuffering);
			playerRef.current.on('bufferend', handleResume);

			const duration = await playerRef.current.getDuration();

			if (duration !== 0 && duration !== playerState.duration) {
				playerState.setDuration(duration);

				if (onHasDuration) {
					onHasDuration(duration);
				}
			}

			playerState.setController(
				playerRef.current as unknown as ControllerInterfaceType
			);
		};

		asyncEffect();
	}, [source.getSourceUrl()]);

	if (!source.isValid()) {
		return null;
	}

	return (
		<Wrapper>
			<div ref={nodeRef} data-testid="video.source.vimeo" />
		</Wrapper>
	);
}
