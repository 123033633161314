import { ReactNode } from 'react';

export const symbol_network_locked: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m131-131 698-698q14-14 32.5-6.344T880-808v306q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T820-502v-234L224-140h359q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q613-97 604.375-88.5T583-80H152q-19.688 0-27.344-18.5Q117-117 131-131Zm584 51q-15 0-28.5-13.5T673-122v-122q0-15 13.5-28.5T715-286v-40q0-36 23.5-61t58.5-25q35 0 58 25t23 61v40h1q14 0 27.5 13.5T920-244v122q0 15-13.5 28.5T879-80H715Zm40-206h84v-40q0-19.55-11.5-32.775Q816-372 796.5-372T766-358.775Q755-345.55 755-326v40ZM224-140l596-596Q685.419-601.164 522-438T224-140Z"
		/>
	</svg>
);
