const icon = {
	name: 'warning-circle',
	objects: [
		{
			type: 'path',
			attributes: {
				d: 'M12 5.054C8.16386 5.054 5.05403 8.16383 5.05403 12C5.05403 15.8362 8.16386 18.946 12 18.946C15.8362 18.946 18.946 15.8362 18.946 12C18.946 8.16383 15.8362 5.054 12 5.054ZM12 3.754C7.44589 3.754 3.75403 7.44586 3.75403 12C3.75403 16.5542 7.44589 20.246 12 20.246C16.5542 20.246 20.246 16.5542 20.246 12C20.246 7.44586 16.5542 3.754 12 3.754Z',
				fillRule: 'evenodd',
				clipRule: 'evenodd',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M12.8101 8.48251C12.8101 8.01873 12.4341 7.64276 11.9703 7.64276C11.5066 7.64276 11.1306 8.01873 11.1306 8.48252L11.1306 12.8587C11.1306 13.3225 11.5066 13.6985 11.9703 13.6985C12.4341 13.6985 12.8101 13.3225 12.8101 12.8587L12.8101 8.48251ZM11.9664 14.4791C11.4657 14.4791 11.0557 14.8615 11.0557 15.3307C11.0557 15.7959 11.4657 16.1783 11.9664 16.1783C12.4632 16.1783 12.8732 15.7959 12.8732 15.3307C12.8732 14.8615 12.4632 14.4791 11.9664 14.4791Z',
			},
		},
	],
};

export default icon;
