import { ReactNode } from 'react';

export const symbol_joystick: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m225-436 255 147 254-148-224-129v146h-60v-146L225-436Zm225-199v-28q-43-11-71.5-45.992Q350-743.983 350-790q0-53.857 38.101-91.929 38.101-38.071 92-38.071T572-881.929q38 38.072 38 91.929 0 46.017-28.5 81.008Q553-674 510-663v28l300 173q14.25 8.171 22.125 21.914Q840-426.343 840-410v100q0 16.343-7.875 30.086Q824.25-266.171 810-258L510-85q-14.328 8-30.164 8Q464-77 450-85L150-258q-14.25-8.171-22.125-21.914Q120-293.657 120-310v-100q0-16.343 7.875-30.086Q135.75-453.829 150-462l300-173Zm0 397L180-393v83l300 173 300-173v-84L510-238q-14.328 8-30.164 8Q464-230 450-238Zm29.882-482Q509-720 529.5-740.382q20.5-20.383 20.5-49.5Q550-819 529.618-839.5q-20.383-20.5-49.5-20.5Q451-860 430.5-839.618q-20.5 20.383-20.5 49.5Q410-761 430.382-740.5q20.383 20.5 49.5 20.5ZM480-137Z"
		/>
	</svg>
);
