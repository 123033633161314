import { ReactNode } from 'react';

export const symbol_done_outline: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m381-242 453-453-43-43-410 410-211-211-43 43 254 254Zm-42 43L84-454q-8.87-8.958-12.935-19.782Q67-484.606 67-495.803q0-11.197 4.065-22.268Q75.13-529.143 84-538l43.647-43.647Q137-591 148-595q11-4 22-4t22 4q11 4 20 13l169 169 368-368q9-9 20-13t22-4q11 0 22 4t20.185 12.633l43.63 43.949Q886-728 890-717t4 22q0 11-4.065 21.963Q885.87-662.073 877-653L423-199q-9 9-20 13t-22 4q-11 0-22-4t-20-13Z"
		/>
	</svg>
);
