// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Uh8ULW__rlr9D4D4Z4NK p,
.Uh8ULW__rlr9D4D4Z4NK a {
	font-size: var(--font-size-sm);
}

.Uh8ULW__rlr9D4D4Z4NK p:first-of-type {
	color: var(--palette-gray-500);
}

.bILZ9q37x9cpqMDSJhGR {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
	font-weight: var(--font-weight-semibold);
	margin-bottom: var(--spacing-3);
}

.sUDwx1Af9x_Dg1CLefMp {
	padding: var(--spacing-6);
	padding-bottom: var(--spacing-9);
}

@media (max-width: 768px) {

.sUDwx1Af9x_Dg1CLefMp {
		padding-bottom: var(--spacing-6);
}
	}
`, "",{"version":3,"sources":["webpack://./routes/organization/contacts/single/sidebar/styles.css"],"names":[],"mappings":"AAAA;;CAEC,8BAA8B;AAC/B;;AAEA;CACC,8BAA8B;AAC/B;;AAEA;CACC,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,yBAA8B;KAA9B,sBAA8B;SAA9B,8BAA8B;CAC9B,wCAAwC;CACxC,+BAA+B;AAChC;;AAEA;CACC,yBAAyB;CACzB,gCAAgC;AAKjC;;AAHC;;AAJD;EAKE,gCAAgC;AAElC;CADC","sourcesContent":[".list p,\n.list a {\n\tfont-size: var(--font-size-sm);\n}\n\n.list p:first-of-type {\n\tcolor: var(--palette-gray-500);\n}\n\n.sidebarHeading {\n\tdisplay: flex;\n\tjustify-content: space-between;\n\tfont-weight: var(--font-weight-semibold);\n\tmargin-bottom: var(--spacing-3);\n}\n\n.sidebarSection {\n\tpadding: var(--spacing-6);\n\tpadding-bottom: var(--spacing-9);\n\n\t@media (--small-viewport) {\n\t\tpadding-bottom: var(--spacing-6);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var list = `Uh8ULW__rlr9D4D4Z4NK`;
export var sidebarHeading = `bILZ9q37x9cpqMDSJhGR`;
export var sidebarSection = `sUDwx1Af9x_Dg1CLefMp`;
export default ___CSS_LOADER_EXPORT___;
