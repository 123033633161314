import { ReactNode } from 'react';

export const symbol_glucose: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M566-80q-32.525 0-62.263-14Q474-108 452-133L238-378q-9-10-7.5-23t12.5-21l23-17q15.143-11.471 34.071-12.235Q319-452 335-441l85 56v-425q0-12.75 9-21.375T450.5-840q12.5 0 21 8.625T480-810v480q0 18.261-15.5 26.63Q449-295 434-305l-119-77 194 225q7 8 16.92 12.5 9.92 4.5 21.08 4.5h223q38 0 64-26.438 26-26.437 26-63.562v-300q0-12.75 9-21.375T890.5-560q12.5 0 21 8.625T920-530v300q0 63-43.5 106.5T770-80H566Zm34.5-600q12.5 0 21 8.625T630-650v170q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T570-480v-170q0-12.75 9-21.375T600.5-680Zm140 40q12.5 0 21 8.625T770-610v130q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T710-480v-130q0-12.75 9-21.375T740.5-640ZM180-560q-59 0-99.5-40T40-697.626Q40-732 53.5-757t63.5-82l40-46q9.25-11 23.125-11T203-885l40 47q51 60 64 83.826 13 23.826 13 56.587Q320-641 279-600.5T180-560Zm.157-60Q213-620 236.5-642.862T260-698q0-22-9.5-38T199-797l-20-21-19 21q-35 39-47.5 60T100-698q0 33 23 55.5t57.157 22.5ZM180-698Zm680 558H509h351Z"
		/>
	</svg>
);
