import { ReactNode } from 'react';

export const symbol_nordic_walking: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m174-64 70-311q2-7 7.463-11 5.464-4 12.976-4 9.561 0 15.061 7t3.5 17L213-55q-2 7-7.463 11-5.464 4-12.976 4Q183-40 177.5-47T174-64Zm111-12 110-553-102 46v103q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T233-480v-123q0-9.333 4.875-16.722Q242.75-627.111 251-631l173-73q26-11 59.5-6t50.5 31l42 66q23 37 61.5 65t86.5 36q12.325 2.213 20.662 11.066Q753-492.082 753-479.541t-8.551 21.041Q735.897-450 724-452q-57-6-103.5-36T536-572l-41 156 82 74q5 4.552 7.5 10.241 2.5 5.69 2.5 11.759v250q0 12.75-8.675 21.375Q569.649-40 556.825-40 544-40 535.5-48.625T527-70v-210l-108-98-74 315q-2 11-10.328 17-8.327 6-19.189 6Q301-40 291.5-51T285-76Zm255.08-671q-30.08 0-51.58-21.42-21.5-21.421-21.5-51.5 0-30.08 21.42-51.58 21.421-21.5 51.5-21.5 30.08 0 51.58 21.42 21.5 21.421 21.5 51.5 0 30.08-21.42 51.58-21.421 21.5-51.5 21.5ZM713-60v-310q0-8 6-14t14-6q8 0 14 6t6 14v310q0 8-6 14t-14 6q-8 0-14-6t-6-14Z"
		/>
	</svg>
);
