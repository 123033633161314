import { createSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';

import Match from 'pkg/models/match';

const emptyMatch = new Match();

const getMatchEntities = (state) => state.matches.entities;

const filteredMatches = createSelector(
	getMatchEntities,
	({ groups }) => groups.entities,
	(_, props) => props,
	(
		matches,
		groups,
		{ before, after, matchCompetitionIds, isHome, types, groupId, withGoalData }
	) => {
		if (Number.isInteger(before)) {
			matches = matches.filter((m) => m.startsAt < before);
		}

		if (withGoalData) {
			matches = matches.filter((m) => m.goalCount !== null);
		}

		if (Number.isInteger(after)) {
			matches = matches.filter((m) => m.startsAt > after);
		}

		if (Array.isArray(matchCompetitionIds) && matchCompetitionIds.length > 0) {
			matches = matches.filter((m) =>
				matchCompetitionIds.includes(m.matchCompetitionId)
			);
		}

		if (Number.isInteger(groupId)) {
			matches = matches.filter((m) => m.groupId === groupId);
		}

		if (typeof isHome === typeof true) {
			matches = matches.filter((m) => m.isHome === isHome);
		}

		if (Array.isArray(types)) {
			matches = matches.filter((m) => types.includes(m.type));
		}

		matches = matches.withMutations((me) =>
			me.forEach((m) => me.setIn([m.id, 'group'], groups.get(m.groupId)))
		);

		return matches;
	}
);

export const getMatchById = (state, { matchId }) => {
	const matches = state.matches.entities;
	const groups = state.groups.entities;

	let match = matches.get(matchId);

	if (!match) {
		return emptyMatch;
	}

	return match.set('group', groups.get(match.groupId));
};

export const getEventMatch = (state, { eventId }) => {
	const matches = getMatchEntities(state);
	const groups = state.groups.entities;

	let match = matches.find((match) => match.get('eventId') === eventId);

	if (!match) {
		return emptyMatch;
	}

	return match.set('group', groups.get(match.groupId));
};

export const getMatchesPlayed = (state, props) => {
	return filteredMatches(state, props);
};

export const getWins = createSelector(
	filteredMatches,
	(matches) =>
		matches.filter(
			(match) => match.get('goalCount') > match.get('opponentGoalCount')
		).size
);
export const getDraws = createSelector(
	filteredMatches,
	(matches) =>
		matches.filter(
			(match) => match.get('goalCount') == match.get('opponentGoalCount')
		).size
);
export const getLosses = createSelector(
	filteredMatches,
	(matches) =>
		matches.filter(
			(match) => match.get('goalCount') < match.get('opponentGoalCount')
		).size
);
export const getGoals = createSelector(filteredMatches, (matches) =>
	matches
		.map((match) => match.get('goalCount'))
		.reduce((total, goalCount) => total + goalCount, 0)
);

export const getGoalsConceded = createSelector(filteredMatches, (matches) =>
	matches
		.map((match) => match.get('opponentGoalCount'))
		.reduce((total, goalCount) => total + goalCount, 0)
);
export const getGoalsDifference = createSelector(filteredMatches, (matches) =>
	matches
		.map((match) => match.get('goalCount') - match.get('opponentGoalCount'))
		.reduce((total, goalCount) => total + goalCount, 0)
);

export const getCleanSheets = createSelector(
	filteredMatches,
	(matches) =>
		matches.filter((match) => match.get('opponentGoalCount') === 0).size
);

export const getLastMatch = createSelector(
	filteredMatches,
	(matches) =>
		matches
			.filter((match) => match.get('startsAt') < Math.ceil(Date.now() / 1000))
			.maxBy((match) => match.get('startsAt')) || emptyMatch
);

export const getMatches = createSelector(filteredMatches, (matches) =>
	matches.sortBy((m) => m.startsAt * -1)
);

export const getMatchResults = createSelector(getMatchById, (match) => {
	return match.get('isHome')
		? [match.get('goalCount'), match.get('opponentGoalCount')]
		: [match.get('opponentGoalCount'), match.get('goalCount')];
});

export const getLastMatches = createSelector(
	filteredMatches,
	(_, { numberOfMatches = 1 }) => numberOfMatches,
	(matches, numberOfMatches) =>
		matches
			.filter((match) => match.get('startsAt') < Math.ceil(Date.now() / 1000))
			.sortBy((match) => match.get('startsAt'))
			.reverse()
			.take(numberOfMatches)
);

/*
Use prop:goalType to choose between goalCount or opponentGoalCount data.

If prop:numberOfMatches is used you will also get trend data if there
are enough matches available.

Example:
Send in numberOfMatches=4 and you will get the average goalCount from
matches 1, 2, 3, 4. If there are numberOfMatches+1 matches available
you will get trend data from matches 2, 3, 4, 5. You can then use that
data to calculate if its a up/flat/down trend in goals or conceded goals.
*/
export const getAverageGoals = createCachedSelector(
	getMatches,
	(_, props) => props.goalType || 'goalCount',
	(_, props) => props.numberOfMatches || false,
	(matches, goalType, numberOfMatches) => {
		let matchesWithGoals = matches.filter((match) => match[goalType] !== null);
		let trendMatches = false;
		let averageGoals = 0;
		let averageGoalsTrend = null;

		if (numberOfMatches) {
			if (numberOfMatches + 1 <= matchesWithGoals.size) {
				trendMatches = matchesWithGoals.skip(1).take(numberOfMatches);
			}

			matchesWithGoals = matchesWithGoals.take(numberOfMatches);
		}

		averageGoals = matchesWithGoals.reduce((goals, match) => {
			return goals + match[goalType];
		}, 0);

		if (averageGoals > 0) {
			averageGoals /= matchesWithGoals.size;
			averageGoals = averageGoals.toFixed(1);
		}

		if (trendMatches) {
			averageGoalsTrend = trendMatches.reduce((goals, match) => {
				return goals + match[goalType];
			}, 0);

			if (averageGoalsTrend > 0) {
				averageGoalsTrend /= matchesWithGoals.size;
				averageGoalsTrend = averageGoalsTrend.toFixed(1);
			}
		}

		return { averageGoals, averageGoalsTrend };
	}
)((_, props) => `${props.goalType}${props.numberOfMatches}`);

export const getWinPercentage = createSelector(filteredMatches, (matches) => {
	const totalMatches = matches.size;

	if (totalMatches === 0) {
		return 0;
	}

	const totalWins = matches.filter(
		(match) => match.goalCount > match.opponentGoalCount
	).size;

	return Math.ceil((totalWins / totalMatches) * 100);
});

export const findMatchByVideoId = (state, { videoId }) => {
	const matches = state.matches.get('entities');
	const matchVideos = state.matchVideos.get('entities');

	const matchId = matchVideos
		.filter((mv) => mv.get('videoId') === videoId)
		.first()
		?.get('matchId');

	return matches.filter((match) => match.get('id') === matchId).first();
};
