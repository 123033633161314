import { ReactNode } from 'react';

export const symbol_vertical_align_top: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M190-780q-13 0-21.5-8.5T160-810q0-13 8.5-21.5T190-840h580q13 0 21.5 8.5T800-810q0 13-8.5 21.5T770-780H190Zm290 660q-13 0-21.5-8.5T450-150v-454L350-504q-9 9-21.5 9t-21.5-9q-9-9-9-21.5t9-21.5l152-152q5-5 10-7t11-2q6 0 11 2t10 7l147 147q9 9 9 21.5t-9 21.5q-9 9-21 9.5t-21-8.5l-96-96v454q0 13-8.5 21.5T480-120Z"
		/>
	</svg>
);
