import { ReactNode } from 'react';

export const symbol_query_stats: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M63.063-268.412q-10.157-6.882-12.11-18.735Q49-299 55-309l151-241q8-13 23.5-14t25.5 10l93 109 135-218q8-14 24.5-14.5T533-664l60 89q8 12 5 23.5T585-534q-10 6-21.81 4.875Q551.381-530.25 543-543l-33-50-131 214q-8 13-23.5 14T330-375l-94-110-130 208q-7 11-19.5 14t-23.438-5.412ZM647-143q-70.833 0-120.417-49.618Q477-242.235 477-313.118 477-384 526.618-433.5q49.617-49.5 120.5-49.5Q718-483 767.5-433.417 817-383.833 817-313q0 26-8.5 50.5T786-216l112 112q9.231 9 9.615 21 .385 12-8.668 21-9.052 9-21.5 9Q865-53 856-62L743-174q-21 15-45.5 23t-50.5 8Zm-.141-60Q693-203 725-234.859q32-31.859 32-78T725.141-391q-31.859-32-78-32T569-391.141q-32 31.859-32 78T568.859-235q31.859 32 78 32ZM690-539q-10-6-13.5-17.5T681-581l173-272q7-11 19.5-13.5t23.53 5.912q10.243 6.882 12.606 18.735Q912-830 905-820L732-548q-8 13-20 14t-22-5Z"
		/>
	</svg>
);
