import { ReactNode } from 'react';

export const symbol_sports_basketball: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M141-510h137q-7-48-28-90.5T198-674q-23 35-39 76t-18 88Zm541 0h137q-2-47-18-88t-39-76q-34 34-53.5 75T682-510ZM198-287q34-34 53.5-74.5T278-450H141q2 47 18 87.5t39 75.5Zm564 0q23-35 39-75.5t18-87.5H682q7 48 26.5 88.5T762-287ZM339-510h111v-309q-62 7-115.5 32T238-720q41 41 67 95t34 115Zm171 0h111q8-61 34.5-115t67.5-95q-43-42-97-67t-116-32v309Zm-60 369v-309H339q-8 61-34 114.5T238-241q43 42 94 67.5T450-141Zm60 0q67-7 118.5-32.5T723-241q-41-41-67.5-94.5T621-450H510v309Zm-30-334Zm0 395q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z"
		/>
	</svg>
);
