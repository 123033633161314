import { ReactNode } from 'react';

export const symbol_masks: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-240q-37 0-71-10.5T345-281q-43-3-89.5-18.5t-85.5-51q-39-35.5-64.5-94T80-590v-40q0-38 26.5-64t63.5-26q35 0 61 27.5t29 67.5q32-8 57.5-18t43.5-20q30-17 57-27t62-10q35 0 62 10t57 27q18 10 43.5 20t57.5 18q3-41 29-68t61-27q38 0 64 26t26 64v40q0 87-25.5 145.5t-64.5 94q-39 35.5-85.5 51T615-281q-30 20-64 30.5T480-240Zm-198-97q-26-32-44-74.5T220-500v-130q0-21-14.5-35.5T170-680q-20 0-35 14.5T120-630v40q0 129 55.5 187.5T282-337Zm198 57q88 0 154-66.5T700-500v-84q-38-8-69.5-20T579-628q-29-17-52-24.5t-47-7.5q-24 0-47 7.5T381-628q-20 12-51.5 24T260-584v84q0 87 66.5 153.5T480-280Zm0-290q15 0 26.5 5.5T532-551q8 5 16.5 10t19.5 10q7 3 13.5.5T592-539q3-5 .5-11t-8.5-8q-9-4-16.5-8t-15.5-9q-16-10-32.5-17.5T480-600q-23 0-39.5 7.5T407-575q-8 5-15.5 9t-16.5 8q-6 3-8 8.5t1 10.5q4 6 10.5 8t13.5-1q11-5 19.5-9.5T428-551q14-8 25.5-13.5T480-570Zm198 233q51-7 106.5-65.5T840-590v-40q0-21-14.5-35.5T790-680q-20 0-35 14.5T740-630v130q0 46-16.5 88.5T678-337ZM480-470Z"
		/>
	</svg>
);
