import { ReactNode } from 'react';

export const symbol_screenshot_frame: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M229.825-690Q217-690 208.5-698.625T200-720v-100q0-24 18-42t42-18h100q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T360-820H260v100q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM260-80q-24 0-42-18t-18-42v-100q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T260-240v100h100q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q390-97 381.375-88.5T360-80H260Zm469.825-610Q717-690 708.5-698.625T700-720v-100H600q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T600-880h100q24 0 42 18t18 42v100q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM600-80q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T600-140h100v-100q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T760-240v100q0 24-18 42t-42 18H600Z"
		/>
	</svg>
);
