import { ReactNode } from 'react';

export const symbol_headphones_battery: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M190-240h-40q-28.875 0-49.438-20.562Q80-281.125 80-310v-170q0-100 70-170t170-70q100 0 170 70t70 170v170q0 28.875-20.562 49.438Q518.875-240 490-240h-40q-12.75 0-21.375-8.625T420-270v-120q0-12.75 8.625-21.375T450-420h70v-60q0-83.333-58.265-141.667Q403.471-680 320.235-680 237-680 178.5-621.667 120-563.333 120-480v60h70q12.75 0 21.375 8.625T220-390v120q0 12.75-8.625 21.375T190-240Zm547-447v-18q0-6 4.5-10.5T752-720h30q6 0 10.5 4.5T797-705v18h50q14.025 0 23.513 9.487Q880-668.025 880-654v381q0 14.025-9.487 23.512Q861.025-240 847-240H692q-14.025 0-23.513-9.488Q659-258.975 659-273v-381q0-14.025 9.487-23.513Q677.975-687 692-687h45Zm-18 387h101-101Zm0 0h101v-327H719v327Z"
		/>
	</svg>
);
