import { ReactNode } from 'react';

export const symbol_view_real_size: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M313.825-280Q301-280 292.5-288.625T284-310v-310h-54q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T230-680h69q18.75 0 31.875 13.125T344-635v325q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Zm376 0Q677-280 668.5-288.625T660-310v-310h-54q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T606-680h69q18.75 0 31.875 13.125T720-635v325q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Zm-209-170Q468-450 459.5-458.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Zm0 170Q468-280 459.5-288.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Z"
		/>
	</svg>
);
