const icon = {
	name: 'calendar-clean',
	objects: [
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M6.91662 2.63641C7.27561 2.63641 7.56662 2.92743 7.56662 3.28641V5.03812H16.6739V3.28641C16.6739 2.92743 16.965 2.63641 17.3239 2.63641C17.6829 2.63641 17.9739 2.92743 17.9739 3.28641V5.03854C19.4153 5.06424 20.5758 6.24065 20.5758 7.68811V18.9656C20.5758 20.4291 19.3893 21.6156 17.9258 21.6156H6.44822C4.98466 21.6156 3.79822 20.4291 3.79822 18.9656V7.68812C3.79822 6.2856 4.88777 5.13756 6.26662 5.04424V3.28641C6.26662 2.92743 6.55764 2.63641 6.91662 2.63641ZM6.44822 6.33812C5.70263 6.33812 5.09822 6.94253 5.09822 7.68812V9.24106H19.2758V7.68811C19.2758 6.94253 18.6714 6.33812 17.9258 6.33812H6.44822ZM19.2758 10.5411H5.09822V18.9656C5.09822 19.7112 5.70263 20.3156 6.44822 20.3156H17.9258C18.6714 20.3156 19.2758 19.7112 19.2758 18.9656V10.5411Z',
			},
		},
	],
};

export default icon;
