import { ReactNode } from 'react';

export const symbol_add_home_work: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M198-180v-201h226v201-201H198v201Zm443-365ZM310.809-690q9.191 0 17.985 2.567Q337.588-684.867 346-679l212 152q11 8 12.5 20.5T564-484q-8 10-19.5 11.5T523-478L311-630 100-478.578V-180h98v-141q0-24.75 17.625-42.375T258-381h106q24.75 0 42.375 17.625T424-321v171q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T364-150v-171H258v141q0 24.75-17.625 42.375T198-120h-98q-24.75 0-42.375-17.625T40-180v-299q0-14 6.5-27.5T65-528l211-151q8-5 16.809-8 8.808-3 18-3ZM860-840q24.75 0 42.375 17.625T920-780v310q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T860-470v-310H465v70q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T405-710v-70q0-24.615 17.692-42.308Q440.385-840 465-840h395ZM732.5-40Q655-40 600-95.5T545-227q0-78.435 54.99-133.717Q654.98-416 733-416q77 0 132.5 55.283Q921-305.435 921-227q0 76-55.5 131.5T732.5-40ZM718-210v94q0 7 5.143 11.5t12 4.5q6.857 0 11.357-5.1 4.5-5.1 4.5-11.9v-93h94q7 0 11.5-5.143t4.5-12q0-6.857-4.5-11.357Q852-243 845-243h-94v-94q0-7-4.5-11.5t-11.357-4.5q-6.857 0-12 4.5T718-337v94h-94q-7 0-11.5 4.5t-4.5 11.357q0 6.857 5.1 12T625-210h93Zm-6-413h30q6 0 10.5-4.5T757-638v-30q0-6-4.5-10.5T742-683h-30q-6 0-10.5 4.5T697-668v30q0 6 4.5 10.5T712-623Z"
		/>
	</svg>
);
