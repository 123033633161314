import { ReactNode } from 'react';

export const symbol_air_purifier_gen: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M120-730q0-45.833 32.5-77.917Q185-840 230-840h170q45.833 0 77.917 32.083Q510-775.833 510-730v580q0 12.75-8.625 21.375T480-120H150q-12.75 0-21.375-8.625T120-150v-580Zm270 390h60v-240h-60v240ZM180-180h270v-100h-60q-24.75 0-42.375-17.625T330-340v-240q0-24.75 17.625-42.375T390-640h60v-90q0-21.25-14.375-35.625T400-780H230q-20 0-35 14.375T180-730v550Zm519.769-247q-14.769 0-29.609-3-14.841-3-30.16-8-12-4-17.5-15t-1.5-23.219Q625-487 636-492.5q11-5.5 23-1.5 11 3 21.688 5 10.687 2 20.812 2 13.5 0 27-4.5T756-505q23-15 44.943-21 21.943-6 42.057-6 14.524 0 30.016 2.5Q888.508-527 904-522q12.387 3.857 18.194 15.429Q928-495 923-483q-3.857 12.267-15.429 17.633Q896-460 884-464q-11-3-21.204-5.5Q852.593-472 844-472q-14 0-29.467 4.95Q799.067-462.1 782-450q-20 14-40.5 18.5t-41.731 4.5Zm1.088-166Q686-593 670.5-595.5T640-603q-11.733-3.857-16.867-15.429Q618-630 622-642.219 626-653 636.5-659q10.5-6 22.5-2t22.714 6q10.715 2 19.286 2 13.667 0 27.333-4Q742-661 756-671q23-15 45.5-21t42.184-6q14.316 0 29.816 2.5Q889-693 905-688q11.733 3.714 16.867 14.857Q927-662 923-649.636 919-638 908-632t-23 2q-12-4-22.213-6-10.212-2-18.812-2-13.975 0-29.475 4.5Q799-629 781-616q-17 11-37.893 17t-42.25 6ZM701-261q-15 0-30.5-2.5T640-271q-12-4-17.5-15.5t-1.5-23.719Q625-321 636-326.5q11-5.5 23-1.5 11 4 21.5 5.5t20.1 1.5q14.4 0 27.697-3.938Q741.595-328.875 756-339q21.536-14.897 45.882-20.948Q826.227-366 844.955-366q14.045 0 29.545 2.5Q890-361 905-356q12 4 17 15.5t1 23.864Q919-305 908-299.5t-23 1.5q-13-4-23-6t-18.364-2Q829-306 813-300.5T781-284q-15 11-36.196 17-21.195 6-43.804 6Zm-251 81v-560.5 10.5-10.5V-180Z"
		/>
	</svg>
);
