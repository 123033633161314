import { ReactNode } from 'react';

export const symbol_quick_reorder: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M70-160q-12.75 0-21.375-8.675Q40-177.351 40-190.175 40-203 48.625-211.5T70-220h169v-101H110q-12.75 0-21.375-8.675Q80-338.351 80-351.175 80-364 88.625-372.5T110-381h129v-101h-83q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T156-542h83v-130l-66-156q-5-11-.5-22.5T188-867q11-5 23-1t17 16l74 174h512l-68-150q-5-11-.5-22.5T761-867q11-5 23-.5t17 15.5l74 162q2 6 3.5 12.5T880-665v445q0 24.75-17.625 42.375T820-160H70Zm403-292h174q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T647-512H473q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T473-452ZM299-220h521v-398H299v398Zm0 0v-398 398Z"
		/>
	</svg>
);
