import React, { ReactNode, MouseEvent, useContext, Fragment } from 'react';
import styled from 'styled-components';

import useTooltip from 'pkg/hooks/useTooltip';

import { LinkCell, TableContext } from 'design/table';
import { LinkCellProps } from 'design/table/table-data/LinkCell';

interface RowProps {
	id?: string;
	onClick?: (event?: MouseEvent) => void;
	children: ReactNode;
	className?: string;
	tooltipText?: string;
	disabled?: boolean;
	// This prop will set the href of all LinkCell children of the row
	href?: string;
}

function Row({
	id,
	onClick,
	children,
	className,
	tooltipText,
	disabled,
	href,
}: RowProps): JSX.Element {
	const { hideIndexes } = useContext(TableContext);

	const content = React.Children.map(children, (child, i) => {
		if (hideIndexes.includes(i)) {
			return null;
		}

		if (
			href &&
			React.isValidElement(child) &&
			child.type === LinkCell &&
			!child.props.href
		) {
			return React.cloneElement(child as React.ReactElement<LinkCellProps>, {
				href,
			});
		}

		return child;
	});

	const { onMouseEnter, tooltip } = useTooltip(tooltipText);

	return (
		<Fragment>
			<tr
				id={id}
				onClick={onClick}
				className={className}
				data-interactable={!!onClick}
				data-disabled={disabled}
				onMouseEnter={onMouseEnter}>
				{content}
			</tr>
			{tooltip}
		</Fragment>
	);
}

export default styled(Row)<RowProps>``;
