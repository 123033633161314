import { Dispatch } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import Group from 'pkg/models/group';

import FormRow from 'components/form/Row';
import Label from 'components/form/Label';
import * as Input from 'components/form/inputs';
import Column from 'components/layout/column';

interface Props {
	dispatch: Dispatch<any>;
	data: any;
	group: Group;
}

const VisibleTo = ({ dispatch, data, group }: Props) => {
	const handleCheckboxChange = (_: string, target: HTMLInputElement) => {
		const { name } = target;

		dispatch({
			type: 'set_value',
			payload: {
				key: name,
				value: !data[name],
			},
		});
	};

	return (
		<FormRow>
			<Label>{t('Visibility options')}</Label>

			<Column spacing={styles.spacing._2}>
				<Input.Control
					type="checkbox"
					name="visibleToAdmins"
					label={t('Admin and staff')}
					description={t('Admin and staff will be able to see this post')}
					checked={true}
					disabled={true}
				/>
				<Input.Control
					type="checkbox"
					name="visibleToUsers"
					label={t(`Players`)}
					description={t(`Players will see this post on the wall.`)}
					checked={data.visibleToUsers}
					value={data.visibleToUsers}
					onChange={handleCheckboxChange}
				/>

				<Input.Control
					type="checkbox"
					name="visibleToLegalGuardians"
					label={t(`Parents`)}
					description={t(`Parents will see this post on the wall.`)}
					checked={data.visibleToLegalGuardians}
					value={data.visibleToLegalGuardians}
					onChange={handleCheckboxChange}
				/>

				<Input.Control
					type="checkbox"
					name="isPublic"
					label={t(`Public`)}
					description={t(
						`If {group} is displayed on your organization's website the post will be shown there after publishing.`,
						{
							group: group.name,
						}
					)}
					checked={data.isPublic}
					value={data.isPublic}
					onChange={handleCheckboxChange}
				/>
			</Column>
		</FormRow>
	);
};

export default VisibleTo;
