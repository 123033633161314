import { ReactNode } from 'react';

export const symbol_join: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M640-260q92 0 156-64.495 64-64.494 64-156Q860-572 796-636t-156-64q-30.51 0-59.325 8.037T527-669q34 38 53.5 86T600-480q0 55-19.5 103T527-291q24.86 14.926 53.675 22.963Q609.49-260 640-260Zm-160-69q28-29 44-68.248 16-39.248 16-83T524-563q-16-39-44-68-28 29-44 68.248-16 39.248-16 83T436-397q16 39 44 68Zm-160 69q30.51 0 59.325-8.037T433-291q-34-38-53.5-86T360-480q0-55 19.5-103t53.5-86q-24.86-14.926-53.675-22.963Q350.51-700 320-700q-91.3 0-155.65 64Q100-572 100-480.495q0 91.506 64.35 156Q228.7-260 320-260Zm0 60q-117 0-198.5-81.5T40-480q0-117 81.5-198.5T320-760q45 0 85.5 13t74.5 37q34-24 74.5-37t85.5-13q117 0 198.5 81.5T920-480q0 117-81.5 198.5T640-200q-45 0-85.5-13T480-250q-34 24-74.5 37T320-200Z"
		/>
	</svg>
);
