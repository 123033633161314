import { ReactNode } from 'react';

export const symbol_nest_clock_farsight_digital: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M265-404h24v-152h-17l-45 32 13 19 25-18v119Zm57 1h100v-23h-66v-2l39-40q12-12 18-24t6-24q0-20-13.5-32T371-560q-18 0-31 9t-18 25l21 10q4-10 11-15t17-5q11 0 17.5 5.5T395-515q0 12-17 31l-56 58v23Zm141-1q8 0 13-5t5-13q0-8-5-13t-13-5q-8 0-13 5t-5 13q0 8 5 13t13 5Zm0-81q8 0 13-5t5-13q0-8-5-13t-13-5q-8 0-13 5t-5 13q0 8 5 13t13 5Zm92 85q23 0 37-13t14-33q0-14-7-23.5T580-484v-1q10-5 15.5-13.5T601-518q0-17-14-29.5T553-560q-17 0-29.5 8.5T505-528l22 9q4-9 10.5-13.5T553-537q11 0 17 5.5t6 13.5q0 10-7.5 16.5T550-495h-12v23h13q14 0 21.5 5.5T580-450q0 11-8 18.5t-20 7.5q-11 0-17.5-6T524-449l-23 9q6 19 20 29.5t34 10.5Zm125 0q22 0 37.5-15t15.5-37q0-20-13-34.5T687-501q-2 0-16 4l-1-1 35-48-20-14-39 56q-12 17-16 28t-4 21q0 23 15.5 39t38.5 16Zm-1-23q-12 0-20.5-8.5T650-452q0-12 8.5-20.5T679-481q12 0 20.5 8.5T708-452q0 12-8.5 20.5T679-423ZM480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-82 31.5-155t86-127.5Q252-817 325-848.5T480-880q82 0 155 31.5t127.5 86Q817-708 848.5-635T880-480q0 82-31.5 155t-86 127.5Q708-143 635-111.5T480-80Zm0-400Zm0 340q140 0 240-100t100-240q0-140-100-240T480-820q-140 0-240 100T140-480q0 140 100 240t240 100Z"
		/>
	</svg>
);
