export function AddTag(
	groupID: number,
	trainingSessionID: number,
	tagID: number
): string {
	return `/v1/organizations/${groupID}/training-sessions/${trainingSessionID}/tags/${tagID}`;
}

export function Create(groupID: number): string {
	return `/v1/organizations/${groupID}/training-sessions`;
}

export function Delete(groupID: number, trainingSessionID: number): string {
	return `/v1/organizations/${groupID}/training-sessions/${trainingSessionID}`;
}

export function Duplicate(groupID: number, trainingSessionID: number): string {
	return `/v1/organizations/${groupID}/training-sessions/${trainingSessionID}/duplicate`;
}

export function GroupIndex(groupID: number): string {
	return `/v1/groups/${groupID}/training-sessions`;
}

export function Index(groupID: number): string {
	return `/v1/organizations/${groupID}/training-sessions`;
}

export function RemoveTag(
	groupID: number,
	trainingSessionID: number,
	tagID: number
): string {
	return `/v1/organizations/${groupID}/training-sessions/${trainingSessionID}/tags/${tagID}`;
}

export function SetItemUsers(
	groupID: number,
	trainingSessionItemID: number
): string {
	return `/v1/organizations/${groupID}/training-session-items/${trainingSessionItemID}/users`;
}

export function SetItemUsers1(trainingSessionItemID: number): string {
	return `/v1/training-session-items/${trainingSessionItemID}/users`;
}

export function Show(groupID: number, trainingSessionID: number): string {
	return `/v1/organizations/${groupID}/training-sessions/${trainingSessionID}`;
}

export function Update(groupID: number, trainingSessionID: number): string {
	return `/v1/organizations/${groupID}/training-sessions/${trainingSessionID}`;
}
