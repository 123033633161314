import { ReactNode } from 'react';

export const symbol_format_shapes: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m320-341 131-343q2-6 7.5-10t12.475-4h17.05q6.975 0 12.475 4t7.5 10l133 346q5 11-2 21.5T618.875-306q-7.875 0-14.191-4.4-6.316-4.4-8.684-11.6l-34-92H403l-34 91q-2.591 8.053-9.5 12.526Q352.591-306 344.818-306 331-306 323-317.098q-8-11.097-3-23.902Zm97-119h126l-60-166h-7l-59 166ZM40-70v-153q0-12.75 8.625-21.375T70-253h49v-454H70q-12.75 0-21.375-8.625T40-737v-153q0-12.75 8.625-21.375T70-920h153q12.75 0 21.375 8.625T253-890v49h454v-49q0-12.75 8.625-21.375T737-920h153q12.75 0 21.375 8.625T920-890v153q0 12.75-8.625 21.375T890-707h-49v454h49q12.75 0 21.375 8.625T920-223v153q0 12.75-8.625 21.375T890-40H737q-12.75 0-21.375-8.625T707-70v-49H253v49q0 12.75-8.625 21.375T223-40H70q-12.75 0-21.375-8.625T40-70Zm213-109h454v-44q0-12.75 8.625-21.375T737-253h44v-454h-44q-12.75 0-21.375-8.625T707-737v-44H253v44q0 12.75-8.625 21.375T223-707h-44v454h44q12.75 0 21.375 8.625T253-223v44ZM100-767h93v-93h-93v93Zm667 0h93v-93h-93v93Zm0 667h93v-93h-93v93Zm-667 0h93v-93h-93v93Zm93-667Zm574 0Zm0 574Zm-574 0Z"
		/>
	</svg>
);
