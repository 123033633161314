import { ReactNode } from 'react';

export const symbol_label_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M735-333q-9 12-20.733 13-11.734 1-20.267-6-9.143-6.75-12.571-18.375Q678-356 686-368l79-112-154-220H331q-15 0-22.5-9.318t-7.5-20.5q0-11.182 7.5-20.682Q316-760 331-760h269q26 0 41.5 10.5T671-719l144 204q5.867 8.345 8.433 17.069Q826-489.207 826-480.103q0 9.103-2.567 17.897Q820.867-453.412 815-445l-80 112ZM180-200q-23 0-41.5-18.5T120-260v-420q0-14 3-22t7-16l-53-53q-9-9-9-21t9-21q9-9 21-9t21 9l694 693q9 9 9 21t-9 21q-9 9-21.5 9T770-78L638-210q-5 5-17 7.5t-23.482 2.5H180Zm203-260Zm117-60Zm88 260L180-668v408h408Z"
		/>
	</svg>
);
