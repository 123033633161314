import { ReactNode } from 'react';

export const symbol_data_thresholding: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-120q-24.75 0-42.375-17.625T120-180v-600q0-24.75 17.625-42.375T180-840h600q24.75 0 42.375 17.625T840-780v600q0 24.75-17.625 42.375T780-120H180Zm517-60h83v-83l-83 83Zm-483 0h90l120-120h71L375-180h90l120-120h71L536-180h90l120-120h34v-480H180v563l83-83h71L214-180Zm208-354L302-415q-9 8-21.1 8.5-12.1.5-20.9-8.5-9-9-9-21t9-21l141-141q9-9 21-9t21 9l69 69 146-146q9-9 21-9t21 9q9 9 9 21t-9 21L533-465q-9 9-21 9t-21-9l-69-69ZM180-180v-600 600Z"
		/>
	</svg>
);
