import { ReactNode } from 'react';

export const symbol_accessible_forward: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M313-80q-79 0-136-57t-57-136q0-70 46-124t115-66q12-2 22.5 5t12.5 19q2 12-5 22.5T292-404q-48 8-80 45.5T180-273q0 54 39.5 93.5T313-140q49 0 83-29t44-78q2-12 13-18.5t23-4.5q12 2 18.5 12.5T499-235q-14 70-65.5 112.5T313-80Zm319-634q-30 0-51.5-21.5T559-787q0-30 21.5-51.5T632-860q30 0 51.5 21.5T705-787q0 30-21.5 51.5T632-714ZM430-314q-49 0-73.5-38t-4.5-81l86-189h-94l-16 44q-5 12-16.5 17.5T288-559q-13-5-19-16.5t-1-24.5l17-44q7-17 19.5-27.5T339-682h192q40 0 62.5 32t6.5 66l-90 191h162q31 0 53 22t22 53v168q0 13-8.5 21.5T717-120q-13 0-21.5-8.5T687-150v-164H430Z"
		/>
	</svg>
);
