import { ReactNode } from 'react';

export const symbol_vertical_align_bottom: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M190-120q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T190-180h580q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T770-120H190Zm289.867-132Q474-252 469-254q-5-2-10-7L307-413q-9-9-8.5-21.5T308-456q9-9 21.5-9t21.5 9l99 100v-454q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-810v454l96-96q9-9 21-8.5t21 9.5q9 9 9 21.5t-9 21.5L501-261q-5 5-10.133 7-5.134 2-11 2Z"
		/>
	</svg>
);
