import { ReactNode } from 'react';

export const symbol_dishwasher: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-560v380h600v-380H180Zm0-60h600v-160H180v160Zm299.911 353Q451-267 430.5-287.562 410-308.125 410-337q0-14 4.5-28.5T428-397q9-17 19.047-31.97 10.046-14.969 20.93-29.939Q473-466 480-466t12.023 7.091q10.884 14.97 20.93 29.939Q523-414 532-397t13.5 31.5Q550-351 550-337q0 28.875-20.589 49.438Q508.823-267 479.911-267ZM700-670q12 0 21-9t9-21q0-12-9-21t-21-9q-12 0-21 9t-9 21q0 12 9 21t21 9ZM180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-500v-160 160Z"
		/>
	</svg>
);
