import { ReactNode } from 'react';

export const symbol_mp: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M283-550h57v102q0 10.833 7.116 17.917 7.117 7.083 18 7.083Q376-423 383-430.083q7-7.084 7-17.917v-102h56v165q0 10.833 7.116 17.917 7.117 7.083 18 7.083Q482-360 489-367.083q7-7.084 7-17.917v-181q0-14.45-9.487-24.225Q477.025-600 463-600H266q-14.025 0-23.512 9.775Q233-580.45 233-566v181q0 10.833 7.116 17.917 7.117 7.083 18 7.083Q269-360 276-367.083q7-7.084 7-17.917v-165Zm317 127h93q14.025 0 23.513-9.487Q726-441.975 726-456v-110q0-14.45-9.487-24.225Q707.025-600 693-600H583q-14.025 0-23.513 9.487Q550-581.025 550-567v182q0 10.833 7.116 17.917 7.117 7.083 18 7.083Q586-360 593-367.083q7-7.084 7-17.917v-38Zm0-50v-77h76v77h-76ZM180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-60h600v-600H180v600Zm0-600v600-600Z"
		/>
	</svg>
);
