import { ReactNode } from 'react';

export const symbol_nest_thermostat_zirconium_eu: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M583-348q49 0 73.5-37.5T681-480q0-57-25-94.5T583-612q-49 0-73 37.5T486-480q0 57 24 94.5t73 37.5Zm0-30q-28 0-47.5-24.5T516-480q0-46 17-74t50-28q33 0 50.5 28t17.5 74q0 53-19.5 77.5T583-378ZM480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm0-60q142 0 241-99.5T820-480q0-142-99-241t-241-99q-141 0-240.5 99T140-480q0 141 99.5 240.5T480-140Zm0-340Zm-55 126q6 0 10.5-4.5T440-369q0-6-4.5-10.5T425-384H319l-1-3q21-19 40.5-34.5T387-445q28-24 39.5-45.5T438-538q0-28-21-51t-58-23q-31 0-54 15t-34 43q-1 2-1 6 0 6 4 10.5t10 4.5q5 0 8.5-2.5t5.5-7.5q8-19 23.5-29t36.5-10q23 0 36.5 12t13.5 32q0 21-11 37.5T357-458q-16 14-30.5 25.5T295-407l-17 15q-1 1-5 11v12q0 6 4.5 10.5T288-354h137Z"
		/>
	</svg>
);
