import { ReactNode } from 'react';

export const symbol_no_transfer: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M302-202v39q0 17.917-12.559 30.458Q276.882-120 258.941-120 241-120 228.5-132.542 216-145.083 216-163v-68q-29-16-42.5-46T160-341v-373L47-827q-9-9.067-9-21.533Q38-861 47.053-870q9.052-9 21.5-9Q81-879 90-870l765 765q9 9 9 21t-9.053 21q-9.052 9-21.5 9Q821-54 812-63L659-215h86v52q0 17.746-12.68 30.373Q719.64-120 701.82-120T671-132.921q-13-12.92-13-31.079v-38H302Zm0-60h310L403-471H220v120q0 35 23.5 62t58.5 27Zm179-618q166 0 242.5 34T800-738v431q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T740-307v-164H587q-15 0-22.5-9.318t-7.5-20.5q0-11.182 7.5-20.682Q572-531 587-531h153v-173H342q-14.5 0-21.75-9.318t-7.25-20.5q0-11.182 7.25-20.682Q327.5-764 342-764h398q-24-26-92-41t-167-15q-78.49 0-131.245 4.5Q297-811 265-799q-11 4-19.5-1.5T234-816q-3-10 1-21.5t20-17.5q39-14 95-19.5t131-5.5ZM326.059-312Q349-312 365-328.059q16-16.059 16-39T364.941-406q-16.059-16-39-16T287-405.941q-16 16.059-16 39T287.059-328q16.059 16 39 16ZM220-531h123L220-654v123Zm62-233h458-458Zm121 293Zm172 0Z"
		/>
	</svg>
);
