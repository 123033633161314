export function Cancel(): string {
	return `/v1/subscriptions/cancel`;
}

export function CreateBatch(): string {
	return `/v1/subscriptions/batch`;
}

export function ExportSubscriptions(): string {
	return `/v1/subscriptions/export`;
}

export function Index(): string {
	return `/v1/subscriptions`;
}

export function ListPossiblePaymentMethods(subscriptionID: number): string {
	return `/v1/subscriptions/${subscriptionID}/payment-methods`;
}

export function ListSubscriptions(): string {
	return `/v1/accounts/self/subscriptions`;
}

export function ListUserAndWardsSubscriptions(): string {
	return `/v1/users/self/subscriptions`;
}

export function Reactivate(ID: number): string {
	return `/v1/subscriptions/${ID}/reactivate`;
}

export function Send(subscriptionID: number): string {
	return `/v1/subscriptions/${subscriptionID}/send`;
}

export function SetPaymentMethod(subscriptionID: number): string {
	return `/v1/subscriptions/${subscriptionID}/set-payment-method`;
}

export function Show(ID: number): string {
	return `/v1/subscriptions/${ID}`;
}

export function Update(ID: number): string {
	return `/v1/subscriptions/${ID}`;
}
