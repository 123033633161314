import { useT } from '@transifex/react';

import * as models from 'pkg/api/models';
import * as actions from 'pkg/actions';

import Button, { ButtonGroup } from 'design/button';
import * as Context from 'design/context_menu';

import * as css from './styles.css';

interface MultipleEventUsersActionsProps {
	eventUsers: models.eventUser.EventUser[];
	event: models.event.Event;

	refresh: () => void;
}

export default function MultipleEventUsersActions({
	eventUsers,
	event,
	refresh,
}: MultipleEventUsersActionsProps) {
	const t = useT();

	const handleSingleAction = async (
		eventUser: models.eventUser.EventUser,
		status: models.eventUser.EventUserStatus
	) => {
		const success = await actions.eventUsers.patchEventUsers(event.id, {
			users: [
				{
					userId: eventUser.userId,
					status,
				},
			],
		});

		if (success) {
			refresh();
		}
	};

	return (
		<ButtonGroup className={css.fullWidth} justifyContent="end">
			<Context.Menu toggleWith={<Button label={t('Decline')} />}>
				{eventUsers.map((eu) => (
					<Context.Item
						onClick={() =>
							handleSingleAction(eu, models.eventUser.EventUserStatus.Declined)
						}>
						{t('Decline for {name}', { name: eu.user.firstName })}
					</Context.Item>
				))}
			</Context.Menu>
			<Context.Menu toggleWith={<Button label={t('Accept')} primary />}>
				{eventUsers.map((eu) => (
					<Context.Item
						onClick={() =>
							handleSingleAction(eu, models.eventUser.EventUserStatus.Accepted)
						}>
						{t('Accept for {name}', { name: eu.user.firstName })}
					</Context.Item>
				))}
			</Context.Menu>
		</ButtonGroup>
	);
}
