import { ReactNode } from 'react';

export const symbol_swap_vert: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M352.825-450Q340-450 331.5-458.625T323-480v-286L223-666q-9 9-21.158 9-12.158 0-21-8.609Q172-675 172-687.1t9-20.9l151-151q5-5 10.133-7 5.134-2 11-2Q359-868 364-866q5 2 10 7l151 151q9 9 9 21t-9 21.391Q516-657 504-657t-21-9L383-766v286q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM606.867-92Q601-92 596-94q-5-2-10-7L435-252q-9-9-9-21t9-21.391Q444-303 456-303t21 9l100 100v-286q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T637-480v286l100-100q9-9 21.158-9 12.158 0 21 8.609Q788-285 788-272.9t-9 20.9L628-101q-5 5-10.133 7-5.134 2-11 2Z"
		/>
	</svg>
);
