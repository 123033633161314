// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GbAhe8MSPSiH2o8pvcGL {
	height: 40px;
}
`, "",{"version":3,"sources":["webpack://./routes/event/single/tabs/summary/summarised-events/styles.css"],"names":[],"mappings":"AAAA;CACC,YAAY;AACb","sourcesContent":[".tableRow {\n\theight: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
export var tableRow = `GbAhe8MSPSiH2o8pvcGL`;
export default ___CSS_LOADER_EXPORT___;
