export default {
	name: 'playlist-add',
	objects: [
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M4.21387 15.1128H10.6139V13.447H4.21387V15.1128ZM17.0139 13.447V10.1154H15.4139V13.447H12.2139V15.1128H15.4139V18.4444H17.0139V15.1128H20.2139V13.447H17.0139ZM13.8139 6.78384H4.21387V8.44964H13.8139V6.78384ZM13.8139 10.1154H4.21387V11.7812H13.8139V10.1154Z',
			},
		},
	],
};
