import { useSelector } from 'react-redux';
import { createContext, ReactNode, useContext } from 'react';
import { t } from '@transifex/native';

import { RootState } from 'pkg/reducers';
import * as selectors from 'pkg/selectors';
import DateTime, { Granularity } from 'pkg/datetime';
import { replaceState } from 'pkg/router/state';
import useDateFilter, { DateQueryFilters } from 'pkg/hooks/use-date-filter';
import * as models from 'pkg/api/models';
import { formatToCurrency } from 'pkg/i18n/formatters';
import { useCurrentGroup } from 'pkg/identity';
import * as routes from 'pkg/router/routes';

const _context = 'payments dashboard';

interface ReportDateFilterHook {
	DateFilter: ReactNode;
	startOfRange: number;
	endOfRange: number;
}

type LocalizedCurrencyFormatter = (
	value: number,
	isAbsolute?: boolean
) => string;

export function useLocalizedCurrencyFormatter(): LocalizedCurrencyFormatter {
	const { currency } = useCurrentGroup();

	return (value: number, isAbsolute: boolean = true): string => {
		value = value / 100;

		if (!isAbsolute) {
			value = -value;
		}

		return formatToCurrency(value, currency);
	};
}

export function usePaymentsDateFilter(): ReportDateFilterHook {
	const groupId = useCurrentGroup()?.id;

	const dateTime = DateTime.now();

	const currentSeason = useSelector((state: RootState) =>
		selectors.groups.getCurrentSeason(state, { groupId })
	);

	const handleUpdateUrl = ({ startOfRange, endOfRange }: DateQueryFilters) =>
		replaceState(routes.Payments.Overview(groupId), {
			from: startOfRange,
			to: endOfRange,
		});

	const now = DateTime.now();

	const customDatePresets = [
		{
			name: t('Last 3 months', { _context }),
			startOfRangeDate: dateTime.prev(Granularity.day, 90).getUnixTimestamp(),
		},
		{
			name: t('Last 12 months', { _context }),
			startOfRangeDate: dateTime
				.prev(Granularity.day, DateTime.daysInYear(now.getYear()))
				.getUnixTimestamp(),
			default: true,
		},
		{
			name: t('This month', { _context }),
			startOfRangeDate: now.startOfMonth / 1000,
			endOfRangeDate: now.getUnixTimestamp(),
		},
		{
			name: t('This quarter', { _context }),
			startOfRangeDate: now.startOfQuarter / 1000,
			endOfRangeDate: now.getUnixTimestamp(),
		},
		{
			name: t('This year', { _context }),
			startOfRangeDate: now.startOfYear / 1000,
			endOfRangeDate: now.getUnixTimestamp(),
		},
		{
			name: t('This season', { _context }),
			startOfRangeDate: currentSeason.start,
		},
	];

	const { DateFilter, dates } = useDateFilter({
		setUrl: handleUpdateUrl,
		customDatePresets,
	});

	return {
		DateFilter,
		...dates,
	};
}

interface OrderReportState extends models.orderReport.OrderReport {
	isLoading: boolean;
	startOfRange: number;
	endOfRange: number;
}

export const OrderReportContext = createContext<OrderReportState>({
	...models.orderReport.EmptyOrderReport,
	isLoading: true,
	startOfRange: 0,
	endOfRange: 0,
});

export function useOrderReport(): OrderReportState {
	return useContext(OrderReportContext);
}
