import { ReactNode } from 'react';

export const symbol_serif: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M280-253h-26l-6 7q0 2 6 6h102l6-6q0-3-1.5-5t-4.5-2h-55l51-147h153l51 147h-52l-6 7q0 2 6 6h202l6-6q0-3-1.5-5t-4.5-2h-25L517-700q-3-9-11-14.5t-17.6-5.5h-22.8q-9.6 0-18.1 6-8.5 6-11.5 15L280-253Zm77-162 71-205 72 205H357ZM140-80q-24 0-42-18t-18-42v-680q0-24 18-42t42-18h680q24 0 42 18t18 42v680q0 24-18 42t-42 18H140Zm0-60h680v-680H140v680Zm0 0v-680 680Z"
		/>
	</svg>
);
