import { Fragment, useState, ChangeEvent } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as actions from 'pkg/actions';
import * as models from 'pkg/api/models';
import { EndpointResponse } from 'pkg/api/use_endpoint';

import { FormattedContent } from 'components/formatted-content';
import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import CardBase, * as Card from 'components/Card';
import SectionTitle from 'components/SectionTitle';

import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import Column from 'components/layout/column';
import Label from 'components/form/Label';
import * as Input from 'components/form/inputs';

import Button from 'design/button';

interface EventNotesProps {
	eventEndpoint: EndpointResponse<models.event.Event>;
}

export default function EventNotes({
	eventEndpoint,
}: EventNotesProps): JSX.Element {
	const [isEditing, setIsEditing] = useState(false);
	const [notes, setNotes] = useState(eventEndpoint.record?.notes || '');

	const handleSave = async () => {
		const [ok] = await actions.events.addNotes(eventEndpoint.record.id, notes);
		if (ok) {
			eventEndpoint.refresh();
		}

		setIsEditing(false);
	};

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		setNotes(e.target.value);
	};

	if (!eventEndpoint.record.id) {
		return null;
	}

	const content = isEditing ? (
		<Column>
			<SectionTitle>{t('Notes')}</SectionTitle>
			<CardBase>
				<Card.Body>
					<Label>{t('Match notes (only visible for team admins)')}</Label>
					<Input.Area
						minRows={4}
						name="notes"
						value={notes}
						type="text"
						onChange={handleChange}
					/>
				</Card.Body>
				<Card.Footer>
					<Button onClick={() => setIsEditing(false)}>{t('Cancel')}</Button>
					<Button primary onClick={handleSave}>
						{t('Save')}
					</Button>
				</Card.Footer>
			</CardBase>
		</Column>
	) : (
		<Column>
			<SectionTitle>
				{t('Notes')}
				<Button icon="edit" inline noPadding onClick={() => setIsEditing(true)}>
					{t('Edit')}
				</Button>
			</SectionTitle>
			<CardBase>
				<Card.Body>
					<FormattedContent
						raw={notes || t('You have not entered any notes yet')}
					/>
				</Card.Body>
			</CardBase>
		</Column>
	);

	return (
		<Fragment>
			<LargeScreen>
				<LargeScreenContent.Inner maxWidth={styles.size.PageWidths.STANDARD}>
					{content}
				</LargeScreenContent.Inner>
			</LargeScreen>
			<SmallScreen>
				<LargeScreenContent.Inner>{content}</LargeScreenContent.Inner>
			</SmallScreen>
		</Fragment>
	);
}
