import { CSSProperties } from 'react';

export function cssClasses(...classes: string[]): string {
	return classes.filter((s) => !!s).join(' ');
}

export function toggleCssClass(
	condition: boolean,
	elem: HTMLElement,
	className: string
) {
	if (condition) {
		elem.classList.add(className);
	} else {
		elem.classList.remove(className);
	}
}

interface CSSVariables {
	[key: string]: string | number;
}

export function cssVarList(vars: CSSVariables, ...ignoredKeys: string[]) {
	const convertedVars: { [key: string]: string | number } = {};

	for (const [key, value] of Object.entries(vars)) {
		if (value) {
			let _key = key;

			if (!ignoredKeys.includes(key)) {
				_key = `--${key}`;
			}

			convertedVars[_key] = value;
		}
	}

	return convertedVars as CSSProperties;
}
