import { tx } from '@transifex/native';

import { isCypress } from 'pkg/flags';

class MissingTranslations {
	handle(sourceString: string) {
		return sourceString;
	}
}

tx.init({
	token: '1/c1edd1fd5ba16deef65bb4e4192bb7d35b7b23a6',
	missingPolicy: new MissingTranslations(),
	filterStatus: 'reviewed',
});

export async function setTranslations(language: string): Promise<void> {
	if (isCypress()) {
		return;
	}
	await tx.setCurrentLocale(language);
}
