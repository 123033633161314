import { Fragment, ReactNode, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as json from 'pkg/json';
import * as actions from 'pkg/actions';
import { useEventListener } from 'pkg/hooks/events';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import * as fs from 'pkg/fullscreen';
import { formatTime } from 'pkg/timeline';
import { useCurrentRoute } from 'pkg/router/hooks';
import { RootState } from 'pkg/reducers';
import * as selectors from 'pkg/selectors';
import { usesHardwareVolumeControls } from 'pkg/platform';
import {
	cuePointsFromSequences,
	getStartsAt,
	VideoSequenceRecordingId,
} from 'pkg/video';
import useTooltip from 'pkg/hooks/useTooltip';
import { useCurrentFilteredClips } from 'pkg/hooks/selectors';

import { useTimelineOffset } from 'routes/video/shared/hooks/timeline';
import ClipGroupSelector from 'routes/video/analyze/ClipGroupSelector';
import PrecisionControl from 'routes/video/analyze/timeline/PrecisionControl';
import { Precision } from 'routes/video/analyze/timeline/config';

import {
	FinePointerDevice,
	LargeScreen,
	SmallScreen,
} from 'components/MediaQuery';
import Icon, { IconName } from 'components/icon';

import { useCueState } from 'components/video-analytics/CueState';
import { usePlaybackState } from 'components/video-analytics/PlaybackState';
import { usePlayerState } from 'components/video-analytics/PlayerState';
import ControlButton from 'components/video/ControlButton';
import RangeSlider from 'components/form/RangeSlider';
import { useClipState } from 'components/video-analytics/ClipState';

import * as ContextMenu from 'design/context_menu';

import useClipRecording from './hooks/use-clip-recording';

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 0.25fr auto 0.25fr;
	background: var(--palette-gray-900);

	&:first-child {
		align-items: center;
	}

	@media ${styles.breakpoint.small} {
		grid-template-columns: 60px auto 60px;
	}

	&[data-context='analyze'] {
		padding: 0 var(--spacing-3);
		grid-template-columns: 1fr auto 1fr;
	}
`;

const Timestamp = styled.div`
	display: grid;
	align-items: center;
	justify-content: start;
	padding-left: var(--spacing-5);
	grid-auto-flow: column;
	gap: var(--spacing-2);

	span {
		color: var(--palette-gray-500);
	}

	&[data-standalone-controls='true'] {
		padding-left: 0;
	}
`;

const Actions = styled.div`
	padding-right: var(--spacing-3);
	display: grid;
	align-items: center;
	justify-content: end;
	grid-auto-flow: column;
	gap: 1px;

	@media ${styles.breakpoint.small} {
		padding-right: var(--spacing-5);
	}

	&[data-standalone-controls='true'] {
		padding-right: 0;
	}
`;

const ActionItem = styled.div<{ noPadding?: boolean }>`
	padding: 0 var(--spacing-3);
	min-width: 30px;
	height: 30px;
	background: var(--palette-gray-700);
	color: var(--palette-gray-300);
	display: grid;
	place-items: center;
	font-size: var(--font-size-xs);
	cursor: pointer;
	position: relative;

	${({ noPadding }) =>
		noPadding &&
		css`
			padding: 0;
		`}

	span {
		padding: 0 var(--spacing-3);
		min-width: 30px;
		height: 30px;
		line-height: 30px;
		display: block;
	}

	@media (hover: hover) {
		&:hover {
			background: var(--palette-gray-600);
			color: var(--palette-white);
		}
	}

	&[data-active='true'] {
		color: var(--palette-green-400);
	}
`;

const ActionGroup = styled.div`
	display: grid;
	grid-auto-flow: column;
	gap: 1px;

	& > :first-child {
		border-radius: 3px 0 0 3px;
	}

	& > :last-child {
		border-radius: 0 3px 3px 0;
	}

	& > :only-child {
		border-radius: 3px;
	}
`;

interface ActionProps {
	children: ReactNode;
	active?: boolean;
	noPadding?: boolean;
	tooltip?: string;
	onClick?: () => void | Promise<void>;
}

const Action = ({
	children,
	active,
	noPadding,
	tooltip,
	onClick,
}: ActionProps) => {
	const tool = useTooltip(tooltip || '');

	return (
		<ActionItem
			noPadding={noPadding}
			onClick={onClick}
			onMouseEnter={tool.onMouseEnter}
			data-active={active}>
			<LargeScreen>{tool.tooltip}</LargeScreen>
			{children}
		</ActionItem>
	);
};

const Container = styled.div`
	display: grid;
	place-items: center;
	grid-auto-flow: column;
	justify-content: center;
	gap: var(--spacing-3);

	@media ${styles.breakpoint.large} {
		gap: var(--spacing-1);
	}

	@media ${styles.breakpoint.small} {
		gap: var(--spacing-2);
	}
`;

const VolumeControlPane = styled(ContextMenu.Pane)`
	width: 100%;
	display: grid;
	grid-template-columns: 16px 1fr 16px;
	justify-content: center;
	align-items: center;
	column-gap: var(--spacing-4);
	color: var(--palette-white);

	span:last-child {
		justify-self: end;
	}
`;

function VolumeAction(): JSX.Element {
	const playerState = usePlayerState();

	const [volume, setVolume] = useState<number>(playerState.volume);

	const handleVolumeChange = async () => {
		await playerState.controller.setVolume(volume);

		playerState.setVolume(volume);
	};

	const setMinVolume = async () => {
		await playerState.controller.setVolume(0);
		playerState.setVolume(0);
		setVolume(0);
	};

	const setMaxVolume = async () => {
		await playerState.controller.setVolume(1);
		playerState.setVolume(1);
		setVolume(1);
	};

	let icon: IconName = 'volume-high';

	if (volume < 0.5 && volume !== 0) {
		icon = 'volume-low';
	} else if (volume === 0) {
		icon = 'volume-off';
	}

	if (usesHardwareVolumeControls()) return null;

	return (
		<FinePointerDevice>
			<LargeScreen>
				<Action
					noPadding
					tooltip={t('Volume ({volume}%)', {
						volume: Math.ceil(volume * 100),
					})}>
					<ContextMenu.Menu
						appearFrom={ContextMenu.AppearFrom.BottomRight}
						toggleWith={<Icon name={icon} size={1.6} />}>
						<VolumeControlPane>
							<Icon name="volume-low" onClick={setMinVolume} />
							<RangeSlider
								min={0}
								max={1}
								step={0.01}
								value={volume}
								onChange={setVolume}
								onAfterChange={handleVolumeChange}
							/>
							<Icon name="volume-high" onClick={setMaxVolume} />
						</VolumeControlPane>
					</ContextMenu.Menu>
				</Action>
			</LargeScreen>
		</FinePointerDevice>
	);
}

const ContextMenuPlaybackRateIcon = styled(ContextMenu.ItemIcon)<{
	active: boolean;
}>`
	font-size: var(--font-size-sm);
	opacity: 0;

	${({ active }) =>
		active &&
		css`
			opacity: 1;
		`}
`;

function PlaybackRateAction(): JSX.Element {
	const [playbackRates, setPlaybackRates] = useState<readonly number[]>([
		0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2,
	]);

	const { controller, playbackRate, setPlaybackRate } = usePlayerState();

	useComponentDidMount(async () => {
		const availableRates = await controller.getAvailablePlaybackRates();

		setPlaybackRates(availableRates);
	});

	const changePlaybackRate = (rate: number) => {
		setPlaybackRate(rate);

		controller.setPlaybackRate(rate);
	};

	if (!playbackRates) return null;

	return (
		<Action noPadding tooltip={t('Playback speed')}>
			<ContextMenu.Menu
				appearFrom={ContextMenu.AppearFrom.BottomRight}
				toggleWith={<span>{playbackRate}×</span>}>
				{playbackRates.map((rateValue: number) => (
					<ContextMenu.Item
						key={`r:${rateValue}`}
						onClick={() => changePlaybackRate(rateValue)}>
						<ContextMenuPlaybackRateIcon
							name="check"
							active={rateValue === playbackRate}
						/>
						{rateValue === 1 ? t('Normal') : rateValue}
					</ContextMenu.Item>
				))}
			</ContextMenu.Menu>
		</Action>
	);
}

function FullscreenAction(): JSX.Element {
	const [isFullscreen, setFullscreen] = useState<boolean>(false);
	const [showFullscreen, setShowFullscreen] = useState<boolean>(false);

	const isSmallScreen = useMediaQuery({
		maxWidth: styles.breakpoint.toMedium,
	});

	const { controller } = usePlayerState();

	fs.useFullscreenChangeObserver(() => {
		if (fs.inFullscreen()) {
			setFullscreen(true);

			actions.app.setDrawerVisibility(false);

			document.querySelector('header').style.display = 'none';
		} else {
			setFullscreen(false);

			actions.app.setDrawerVisibility(true);

			document.querySelector('header').style.display = 'block';
		}
	});

	const toggleFullscreen = async () => {
		const targetNode = isSmallScreen
			? await controller.getNode()
			: document.body;

		if (fs.inFullscreen()) {
			await fs.exitFullscreen();
		} else {
			await fs.requestFullscreen(targetNode);
		}
	};

	useEffect(() => {
		const verifyFullscreen = async () => {
			const node = await controller.getNode();
			const supportsFullscreen = await fs.canRequestFullscreen(node);

			setShowFullscreen(supportsFullscreen);
		};

		verifyFullscreen();
	}, [controller]);

	if (!showFullscreen) return null;

	return (
		<Action tooltip={t('Toggle fullscreen')} onClick={toggleFullscreen}>
			<Icon
				name={isFullscreen ? 'fullscreen-close' : 'fullscreen'}
				size={1.8}
			/>
		</Action>
	);
}

function AutoPlayAction(): JSX.Element {
	const playerState = usePlayerState();
	const cueState = useCueState();
	const clips = useCurrentFilteredClips();
	const cues = cuePointsFromSequences(clips);

	const toggleAutoPlay = () => {
		const nextState = !playerState.gaplessPlayback;

		if (nextState === false) {
			cueState.setCueActive(false);
		}

		if (cues.length > 0) {
			const firstCue = cues[0];

			cueState.setCuePoints(cues);

			playerState.controller.seekTo(getStartsAt(firstCue), true);
		}

		playerState.setGaplessPlayback(nextState);
	};

	return (
		<Action
			active={playerState.gaplessPlayback}
			tooltip={t('Auto-play clips')}
			onClick={toggleAutoPlay}>
			<Icon name="av-replay" size={2} />
		</Action>
	);
}

interface ControlsProps {
	context: 'video' | 'cue' | 'analyze';
}

export default function Controls({
	context = 'video',
}: ControlsProps): JSX.Element {
	const { videoId } = useCurrentRoute();
	const { currentTime, setCurrentTime } = usePlaybackState();
	const playerState = usePlayerState();
	const timelineOffset = useTimelineOffset();
	const record = useClipRecording();
	const clipState = useClipState();

	const {
		controller,
		setSource,
		isReady,
		isPlaying,
		setEditing,
		isRecording,
		isTrimming,
		setTrimming,
		setTimelinePrecision,
		setTimelineZoom,
		gaplessPlayback,
	} = playerState;

	const cueState = useCueState();
	const { prevCue, nextCue, currentCue, cuePoints } = cueState;

	let startsAt: number, endsAt: number, pointedAt: number, duration: number;

	const canGoPrev: boolean = !!prevCue && !isRecording && cuePoints.length > 0;
	const canGoNext: boolean = !!nextCue && !isRecording && cuePoints.length > 0;

	const isSmallScreen: boolean = useMediaQuery({
		maxWidth: styles.breakpoint.toMedium,
	});

	const useStandalone: boolean = !isSmallScreen;

	const video = useSelector((state: RootState) =>
		selectors.videos.find(state, videoId)
	);

	const canRecord: boolean = video?.hasIn(['links', 'create:video_sequence']);

	const getCurrentTime = () => currentTime;

	if (context === 'cue') {
		const cue = cueState.currentCue;

		if (cue) {
			startsAt = cue.startsAtMs ? cue.startsAtMs / 1000 : cue.startsAt;
			endsAt = cue.endsAtMs ? cue.endsAtMs / 1000 : cue.endsAt;

			pointedAt = Math.abs(currentTime - startsAt);
			duration = Math.abs(endsAt - startsAt);
		}
	} else {
		startsAt = 0;
		endsAt = playerState.duration;
		pointedAt = currentTime;
		duration = playerState.duration;
	}

	const skipBy = clipState.activeAnnotationId ? 1 : 10;

	const skipBackwards = () => {
		let nextCurrentTime = getCurrentTime() - skipBy;

		if (nextCurrentTime < startsAt) {
			nextCurrentTime = startsAt;
		}

		timelineOffset.set(nextCurrentTime);
		setCurrentTime(nextCurrentTime);

		controller.seekTo(nextCurrentTime, controller.isPlaying());
	};

	const skipForwards = () => {
		let nextCurrentTime = getCurrentTime() + skipBy;

		if (nextCurrentTime > endsAt) {
			nextCurrentTime = endsAt;
		}

		timelineOffset.set(nextCurrentTime);
		setCurrentTime(nextCurrentTime);

		controller.seekTo(nextCurrentTime, controller.isPlaying());
	};

	const togglePlayback = async () => {
		const currentEndsAt = currentCue?.endsAtMs
			? currentCue?.endsAtMs / 1000
			: currentCue?.endsAt;

		if (
			gaplessPlayback &&
			!nextCue &&
			Math.floor(currentEndsAt) === Math.floor(currentTime)
		) {
			const firstCue = cueState.cuePoints[0];

			cueState.setCueId(firstCue.cueId as number);

			const firstStartsAt = firstCue.startsAtMs
				? firstCue.startsAtMs / 1000
				: firstCue.startsAt;

			setCurrentTime(firstStartsAt);

			await controller.seekTo(firstStartsAt, true);
		} else {
			if (controller.isPlaying()) {
				await controller.pause();
			} else {
				await controller.play();
			}
		}
	};

	const goPrev = () => {
		if (prevCue) {
			if (prevCue.sourceUrl) {
				setSource(prevCue.sourceUrl, {
					startsAt: prevCue.startsAt,
				});
			}

			const nextStartsAt = prevCue.startsAtMs / 1000 || prevCue.startsAt;

			timelineOffset.set(nextStartsAt);
			controller.seekTo(nextStartsAt, playerState.isPlaying);

			if (prevCue.annotations) {
				const annotations = json.parse(prevCue.annotations);

				if (annotations) {
					clipState.setAnnotations(annotations);
				}
			} else {
				clipState.setAnnotations(null);
			}

			cueState.prev();
		}
	};

	const goNext = () => {
		if (nextCue) {
			if (nextCue.sourceUrl) {
				setSource(nextCue.sourceUrl, {
					startsAt: nextCue.startsAt,
				});
			}

			const nextStartsAt = nextCue.startsAtMs / 1000 || nextCue.startsAt;

			timelineOffset.set(nextStartsAt);
			controller.seekTo(nextStartsAt, playerState.isPlaying);

			if (nextCue.annotations) {
				const annotations = json.parse(nextCue.annotations);

				if (annotations) {
					clipState.setAnnotations(annotations);
				}
			} else {
				clipState.setAnnotations(null);
			}

			cueState.next();
		}
	};

	const skipToStartOfClip = () => {
		const startsAt = clipState.getStartsAt();

		setCurrentTime(startsAt);
		controller.seekTo(startsAt);
		timelineOffset.set(startsAt);
	};

	const skiptoEndOfClip = () => {
		const endsAt = clipState.getEndsAt();

		setCurrentTime(endsAt);
		controller.seekTo(endsAt);
		timelineOffset.set(endsAt);
	};

	const handleSkipPrev = isTrimming
		? skipToStartOfClip
		: canGoPrev
			? goPrev
			: null;

	const handleSkipNext = isTrimming
		? skiptoEndOfClip
		: canGoNext
			? goNext
			: null;

	const deleteActiveClip = () => {
		if (currentCue?.cueId !== VideoSequenceRecordingId) {
			const confirmMessage = t(
				`Do you want to delete "{name}"? This action cannot be undone.`,
				{
					name: currentCue.title,
				}
			);

			if (confirm(confirmMessage)) {
				actions.videoSequences.destroy(currentCue?.cueId as number);

				actions.flashes.show({
					title: t('Successfully deleted clip'),
				});

				setEditing(false);
				setTrimming(false);

				clipState.reset();
			}
		}
	};

	const triggerClick = (id: string) => {
		const node = document.getElementById(id);

		if (node) {
			node.click();
		}
	};

	useEventListener('video-arrow-up', () => triggerClick('video-jump-prev'));
	useEventListener('video-arrow-down', () => triggerClick('video-jump-next'));
	useEventListener('video-spacebar', () => triggerClick('video-playpause'));
	useEventListener('video-delete', deleteActiveClip, [currentCue]);
	useEventListener('video-record', () => triggerClick('video-record'));
	useEventListener('video-arrow-left', () =>
		triggerClick('video-skip-backwards')
	);
	useEventListener('video-arrow-right', () =>
		triggerClick('video-skip-forwards')
	);

	const handlePrecisionChange = async (
		precision: Precision,
		zoomLevel: number
	) => {
		setTimelinePrecision(precision);
		setTimelineZoom(zoomLevel);

		if (!isPlaying) {
			timelineOffset.set(currentTime);
		}
	};

	if (!controller) return null;

	return (
		<Wrapper
			data-context={context}
			data-testid={
				isReady ? 'video.controls.is_ready' : 'video.controls.is_waiting'
			}>
			{context !== 'analyze' ? (
				<Timestamp data-standalone-controls={useStandalone}>
					<LargeScreen>
						{formatTime(pointedAt)}
						<span> / {formatTime(duration)}</span>
					</LargeScreen>
					{context === 'video' && (
						<SmallScreen>
							<ActionGroup>
								<AutoPlayAction />
							</ActionGroup>
						</SmallScreen>
					)}
				</Timestamp>
			) : (
				<Fragment>
					<LargeScreen>
						<ClipGroupSelector disabled={isTrimming && !isRecording} />
					</LargeScreen>
					<SmallScreen>
						<span />
					</SmallScreen>
				</Fragment>
			)}

			<Container>
				<ControlButton
					id="video-jump-prev"
					icon="av-jump-prev"
					isDisabled={!canGoPrev && !isTrimming}
					onClick={handleSkipPrev}
					testid="video.controls.jump_prev"
				/>
				<ControlButton
					id="video-skip-backwards"
					mediumIcon
					icon={
						clipState.activeAnnotationId ? 'av-step-prev' : 'av-skip-backwards'
					}
					onClick={skipBackwards}
					testid={`video.controls.${
						clipState.activeAnnotationId ? 'step_prev' : 'skip_backwards'
					}`}
				/>
				<ControlButton
					id="video-playpause"
					largeIcon
					icon={isPlaying ? 'av-pause' : 'av-play'}
					onClick={togglePlayback}
					testid={`video.controls.${isPlaying ? 'pause' : 'play'}`}
				/>
				<LargeScreen>
					{context === 'analyze' && canRecord && !isTrimming && (
						<ControlButton
							id="video-record"
							smallIcon={!isRecording}
							mediumIcon={isRecording}
							recordButton
							isActive={isRecording}
							icon={isRecording ? 'av-stop' : 'av-record'}
							onClick={!isRecording ? record.start : record.stop}
							testid={`video.controls.${isRecording ? 'stop' : 'record'}`}
						/>
					)}
				</LargeScreen>
				<ControlButton
					id="video-skip-forwards"
					mediumIcon
					icon={
						clipState.activeAnnotationId ? 'av-step-next' : 'av-skip-forwards'
					}
					onClick={skipForwards}
					testid={`video.controls.${
						clipState.activeAnnotationId ? 'step_next' : 'skip_forwards'
					}`}
				/>
				<ControlButton
					id="video-jump-next"
					icon="av-jump-next"
					isDisabled={!canGoNext && !isTrimming}
					onClick={handleSkipNext}
					testid="video.controls.jump_next"
				/>
			</Container>
			<Actions data-standalone-controls={useStandalone}>
				{context === 'analyze' && (
					<LargeScreen>
						<PrecisionControl
							onChange={handlePrecisionChange}
							initialValue={0.3}
						/>
					</LargeScreen>
				)}
				<ActionGroup>
					{context === 'video' && (
						<LargeScreen>
							<AutoPlayAction />
						</LargeScreen>
					)}
					<VolumeAction />
					<PlaybackRateAction />
					<FullscreenAction />
				</ActionGroup>
			</Actions>
		</Wrapper>
	);
}
