import { ReactNode } from 'react';

export const symbol_sprint: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M482-425 237-181q-8.8 9-20.9 9-12.1 0-21.1-9.053-9-9.052-9-21.5Q186-215 195-224l387-386H440v50q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T380-560v-80q0-12.75 8.625-21.375T410-670h198q12.089 0 23.044 5Q642-660 650-652l123 121q23 23 52.5 37t61.5 20q12.952 2.25 22.476 11.625T917-440q-2 14-12.5 21t-24.5 5q-41-6-77.5-22.5T736-484l-46-46-114 114 58 58q11 11 9 25t-15 22L444-204q-11 6-23.122 2.889-12.123-3.111-18-14Q397-226 400-238t14-18l150-87-82-82Zm-332-15q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-500h140q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T290-440H150ZM70-570q-12.75 0-21.375-8.675Q40-587.351 40-600.175 40-613 48.625-621.5T70-630h140q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T210-570H70Zm720-80q-28.518 0-49.259-20.589Q720-691.177 720-720.089 720-749 740.741-769.5T790-790q28.518 0 49.259 20.589Q860-748.823 860-719.911 860-691 839.259-670.5T790-650Zm-640-50q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-760h140q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T290-700H150Z"
		/>
	</svg>
);
