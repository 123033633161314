import ArchivedList from 'routes/forms/list/archived';
import ActiveList from 'routes/forms/list/active';

interface Props {
	groupId: number;
	currentTab: string;
}

const FormList: React.FC<Props> = ({ groupId, currentTab }) => {
	if (currentTab === 'active') {
		return <ActiveList groupId={groupId} />;
	}

	return <ArchivedList groupId={groupId} />;
};

export default FormList;
