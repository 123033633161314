import { Map, Record } from 'immutable';
import { schema } from 'normalizr';

export const ratingItemSchema = new schema.Entity('ratingItems');

const RatingItemRecord = Record(
	{
		id: 0,
		name: '',
		slug: '',
		category: null,
		categorySlug: '',
		description: '',
		shortDescription: '',
		meta: Map({}),
	},
	'RatingItem'
);

class RaitingItem extends RatingItemRecord {
	static normalizr() {
		return ratingItemSchema;
	}
}

export default RaitingItem;
