import { ReactNode } from 'react';

export const symbol_folder_special: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-160q-24 0-42-18.5T80-220v-520q0-23 18-41.5t42-18.5h256q12.444 0 23.722 5t19.366 13.088L481-740h339q23 0 41.5 18.5T880-680v460q0 23-18.5 41.5T820-160H140Zm0-60h680v-460H456l-60-60H140v520Zm0 0v-520 520Zm455.5-142 69.5 48q5 3 9 .067 4-2.934 2-8.067l-26-85 71-60q4-3.611 2-8.306-2-4.694-7-4.694h-85l-28-83q-2-5-7-5t-7 5l-29 83h-85q-5 0-7 4.694-2 4.695 2 8.306l71 60-27 85q-2 5 2.5 8.5t9.5.5l69.5-49Z"
		/>
	</svg>
);
