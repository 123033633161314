import { ReactNode } from 'react';

export const symbol_devices_fold: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M470-120q-24.75 0-42.375-17.625T410-180v-625q0-17.905 9.5-32.452Q429-852 446-860l126-55q35-15 66.5 3t31.5 59v13h150q24.75 0 42.375 17.625T880-780v600q0 24.75-17.625 42.375T820-120H470Zm0-96 140-60v-589l-140 60v589Zm67 36h283v-600H670v497q0 21.081-11.375 39.088T628-218l-91 38Zm-67-36v-589 589ZM109.825-780Q97-780 88.5-788.675q-8.5-8.676-8.5-21.5Q80-823 88.675-831.5q8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Zm0 165Q97-615 88.5-623.675q-8.5-8.676-8.5-21.5Q80-658 88.675-666.5q8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5ZM80-480.175Q80-493 88.675-501.5q8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Q97-450 88.5-458.675q-8.5-8.676-8.5-21.5ZM109.825-285Q97-285 88.5-293.675q-8.5-8.676-8.5-21.5Q80-328 88.675-336.5q8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Zm0 165Q97-120 88.5-128.675q-8.5-8.676-8.5-21.5Q80-163 88.675-171.5q8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Zm165 0Q262-120 253.5-128.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Zm0-660Q262-780 253.5-788.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Z"
		/>
	</svg>
);
