import * as models from 'pkg/api/models';
import { EventSeries } from 'pkg/api/models/event_series';
import { Linkable } from 'pkg/api/models/linkable';
import { Record } from 'pkg/api/models/record';

export enum EventSeriesUserResponseStatus {
	Pending = 'pending',
	Accepted = 'accepted',
	Declined = 'declined',
}

export enum EventSeriesUserFlags {
	LokLeader = 'lok_leader',
	LokHasHandicap = 'lok_has_handicap',
}

export enum EventSeriesUserRole {
	Organizer = 'organizer',
	Participant = 'participant',
}

export interface EventSeriesUser extends Record, Linkable {
	userId: number;
	eventSeriesId: number;

	status: EventSeriesUserResponseStatus;
	flags: EventSeriesUserFlags;
	role: EventSeriesUserRole;
	user: models.user.User;
	eventSeries: models.eventSeries.EventSeries;
}

export function findAllEventSeriesUsers(
	eventSeriesUsers: EventSeriesUser[],
	userIds: number[]
) {
	return eventSeriesUsers?.filter((eu) => userIds.includes(eu.userId)) || [];
}

export function findMyEventSeriesUser(
	eventSeriesUsers: EventSeriesUser[],
	activeUserIds: number[]
) {
	return eventSeriesUsers?.find((eu) => activeUserIds.includes(eu.userId));
}

export function sortEventSeriesUsers(
	eventSeriesUsers: EventSeriesUser[],
	myEventSeriesUsers: EventSeriesUser[] = [],
	eventSeries: EventSeries
) {
	const myEventUserIds = myEventSeriesUsers.map((eu) => eu.id);
	const eventSeriesUserId = eventSeries?.userId;

	return eventSeriesUsers.sort((a, b) => {
		if (a.userId === eventSeriesUserId) {
			return -1;
		}
		if (b.userId === eventSeriesUserId) {
			return 1;
		}
		if (myEventUserIds.includes(a.id)) {
			return -1;
		}
		if (myEventUserIds.includes(b.id)) {
			return 1;
		}

		const statusComparison = getPriority(a.status) - getPriority(b.status);

		if (statusComparison !== 0) {
			return statusComparison;
		}

		return a.user.firstName.localeCompare(b.user.firstName);
	});
}

function getPriority(status: EventSeriesUserResponseStatus): number {
	switch (status) {
		case 'accepted':
			return 1;
		case 'declined':
			return 2;
		default:
			return 0;
	}
}
