import { t } from '@transifex/native';
import { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import * as styles from 'pkg/config/styles';

import rgba from 'pkg/rgba';
import ColorConverter from 'pkg/colorconverter';

import Input from 'components/form/TextInput';

import * as ContextMenu from 'design/context_menu';
import Button from 'design/button';

const Wrapper = styled.div`
	position: relative;
`;

const SelectedColorAttrs = ({ color }) => ({
	style: {
		backgroundColor: color || 'hsl(180, 100%, 50%)',
	},
});

const SelectedColor = styled.span.attrs(SelectedColorAttrs)`
	width: 1.5rem;
	height: 1.5rem;
	border-radius: 100rem;
	box-shadow: inset 0 0 0 1px ${rgba(styles.palette.black, 0.1)};
`;

const SelectedColorLabel = styled.span`
	color: var(--palette-gray-500);
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;

	@media (hover: hover) {
		&:hover {
			color: var(--palette-gray-500);
		}
	}

	${Button} {
		margin-left: auto;
	}

	${SelectedColor} {
		margin-right: 0.5rem;
	}
`;

const ValuesWrapper = styled.div`
	padding: 0.5rem 1rem;
	width: 100%;
	display: grid;
	grid-auto-flow: row;
	grid-gap: 2rem;

	@media ${styles.breakpoint.small} {
		padding: 1rem 2rem;
		grid-gap: 3rem;
	}
`;

const SliderControl = styled.input`
	padding: 0;
	width: 100%;
	height: 1rem;
	-webkit-appearance: none;
	appearance: none;
	background-color: currentColor;
	background-origin: content-box;
	background-repeat: no-repeat;
	display: block;
	color: inherit;
	border-radius: 100rem;
	box-shadow: inset 0 0 0 1px ${rgba(styles.palette.black, 0.1)};

	&:focus {
		outline: none;
	}

	&::-webkit-slider-runnable-track {
		margin: 0 -12px;
	}

	&::-moz-range-track {
		background-color: transparent;
	}

	&::-webkit-slider-thumb {
		appearance: none;
		background-color: transparent;
		border-radius: 100%;
		box-shadow:
			inset 0 0 0 4px var(--palette-white),
			0 0 1px 1px ${rgba(styles.palette.black, 0.3)},
			0 0 0.5rem ${rgba(styles.palette.black, 0.3)};
		height: 24px;
		width: 24px;
	}

	&::-moz-range-thumb {
		background-color: transparent;
		border-radius: 100%;
		box-shadow:
			inset 0 0 0 2px var(--palette-white),
			0 0 1px 1px ${rgba(styles.palette.black, 0.3)},
			0 0 0.5rem ${rgba(styles.palette.black, 0.3)};
		height: 24px;
		width: 24px;
	}
`;

SliderControl.defaultProps = {
	type: 'range',
	step: 1,
	min: 0,
	max: 100,
};

const HueSlider = styled(SliderControl)`
	background-image: linear-gradient(
		to right,
		#f00,
		#ff0,
		#0f0,
		#0ff,
		#00f,
		#f0f,
		#f00
	);
`;

HueSlider.defaultProps = {
	max: 360,
};

const SaturationSlider = styled(SliderControl)`
	background-image: linear-gradient(to right, #888, currentColor);
`;

const LightnessSlider = styled(SliderControl)`
	background-image: linear-gradient(to right, #000, currentColor, #fff);
`;

const SliderAttrs = (props) => {
	const [h, s, l] = props.hsl;

	return {
		style: {
			color: `hsl( ${h}, ${s}%, ${l}% )`,
		},
	};
};

const Slider = styled.div.attrs(SliderAttrs)`
	width: 100%;
`;

const Label = styled.label`
	display: inline-block;
	margin-right: 1rem;
	color: var(--palette-gray-500);
	cursor: pointer;
`;

const ContextActions = styled(ContextMenu.Item)`
	display: grid;
	grid-auto-flow: column;
	justify-content: end;
	grid-gap: var(--spacing-3);
	padding: var(--spacing-4) var(--spacing-6);
`;

const LabeledMenuItem = styled.div`
	display: grid;
	grid-auto-flow: column;
	grid-gap: var(--spacing-3);
	padding: var(--spacing-6);
	grid-template-columns: auto 1fr;
	justify-content: start;
	align-items: center;
`;

const NOOP = () => {};

export default class extends Component {
	static propTypes = {
		initialValues: PropTypes.arrayOf(PropTypes.number).isRequired,
		currentValues: PropTypes.arrayOf(PropTypes.number).isRequired,

		onSelect: PropTypes.func,
		onCancel: PropTypes.func,

		onHueChange: PropTypes.func.isRequired,
		onSaturationChange: PropTypes.func.isRequired,
		onLightnessChange: PropTypes.func.isRequired,

		onHexChange: PropTypes.func,
		onHslChange: PropTypes.func,
		onRgbChange: PropTypes.func,

		onValid: PropTypes.func,
		onError: PropTypes.func,
		pickerTrigger: PropTypes.element,
	};

	static defaultProps = {
		onSelect: NOOP,
		onCancel: NOOP,

		onHexChange: NOOP,
		onHslChange: NOOP,
		onRgbChange: NOOP,

		onValid: NOOP,
		onError: NOOP,
	};

	contextMenuRef = createRef();
	hexFieldRef = createRef();
	rgbFieldRef = createRef();

	didEmitValid = false;
	didEmitError = false;

	state = {
		canSelect: true,
	};

	color = new ColorConverter();

	componentDidMount() {
		let [h, s, l] = this.props.initialValues;

		if (isNaN(h)) {
			h = 55;
			s = 100;
			l = 50;
		}

		this.color.fromHSL(h, s, l);
	}

	normalizeValues(values) {
		let [h, s, l] = values;

		if (isNaN(h)) h = 55;
		if (isNaN(s)) s = 100;
		if (isNaN(l)) l = 50;

		if (h > 360) h = 360;
		if (h < 0) h = 0;

		if (s > 100) s = 100;
		if (s < 0) s = 0;

		if (l > 100) l = 100;
		if (l < 0) l = 0;

		return [h, s, l];
	}

	getColorValue(values) {
		let [h, s, l] = this.normalizeValues(values);

		return `hsl( ${h}, ${s}%, ${l}% )`;
	}

	getExport() {
		return {
			hex: this.color.toHEX(true),
			hsl: this.color.toHSL(true),
			rgb: this.color.toRGB(true),
		};
	}

	get initialColorValue() {
		return this.getColorValue(this.props.initialValues);
	}

	get currentColorValue() {
		return this.getColorValue(this.props.currentValues);
	}

	updateColorFields() {
		this.hexFieldRef.current.value = this.color.toHEX(true);
		this.rgbFieldRef.current.value = this.color.toRGB(true);

		if (!this.state.canSelect) {
			this.setState({ canSelect: true });
		}
	}

	validate(target) {
		const isValid = target.checkValidity();

		if (!isValid && !this.didEmitError) {
			this.didEmitError = true;
			this.didEmitValid = false;

			this.props.onError();

			this.setState({ canSelect: false });
		}

		if (isValid && !this.didEmitValid) {
			this.didEmitError = false;
			this.didEmitValid = true;

			this.props.onValid();

			this.setState({ canSelect: true });
		}
	}

	handleHueChange = (event) => {
		const { onHueChange } = this.props;
		const h = parseInt(event.target.value);

		onHueChange(h);

		this.color.setHue(h);
		this.updateColorFields();
	};

	handleSaturationChange = (event) => {
		const { onSaturationChange } = this.props;
		const s = parseInt(event.target.value);

		onSaturationChange(s);

		this.color.setSaturation(s);
		this.updateColorFields();
	};

	handleLightnessChange = (event) => {
		const { onLightnessChange } = this.props;
		const l = parseInt(event.target.value);

		onLightnessChange(l);

		this.color.setLightness(l);
		this.updateColorFields();
	};

	handleHexChange = (event) => {
		const hex = event.target.value;

		this.validate(event.target);

		if (ColorConverter.isHEX(hex)) {
			const { onHueChange, onSaturationChange, onLightnessChange } = this.props;

			const [h, s, l] = this.color.fromHEX(hex);

			onHueChange(h);
			onSaturationChange(s);
			onLightnessChange(l);

			this.rgbFieldRef.current.value = this.color.toRGB(true);
		}
	};

	handleRgbChange = (event) => {
		const rgb = event.target.value;

		this.validate(event.target);

		if (ColorConverter.isRGB(rgb)) {
			const { onHueChange, onSaturationChange, onLightnessChange } = this.props;

			const [r, g, b] = rgb.split(',').map((n) => parseInt(n));
			const [h, s, l] = this.color.fromRGB(r, g, b);

			onHueChange(h);
			onSaturationChange(s);
			onLightnessChange(l);

			this.hexFieldRef.current.value = this.color.toHEX(true);
		}
	};

	handleSelect = () => {
		if (!this.state.canSelect) return;

		this.props.onSelect(this.getExport());
		this.contextMenuRef.current?.close();
	};

	handleCancel = () => {
		const {
			onHueChange,
			onSaturationChange,
			onLightnessChange,
			onCancel,
			initialValues,
		} = this.props;

		const [h, s, l] = initialValues;

		onHueChange(h);
		onSaturationChange(s);
		onLightnessChange(l);

		onCancel();

		this.setState({ canSelect: true }, () =>
			this.contextMenuRef.current?.close()
		);
	};

	get pickerTrigger() {
		if (this.props.pickerTrigger) {
			return this.props.pickerTrigger;
		}

		return (
			<Button small icon="colorize">
				{t(`Color picker`)}
			</Button>
		);
	}

	get selectedColorIndicator() {
		return (
			<ContextMenu.Item closeOnClick={false} noLink>
				<SelectedColorLabel>
					<SelectedColor color={this.currentColorValue} />
					{t(`Current color`)}
				</SelectedColorLabel>
			</ContextMenu.Item>
		);
	}

	render() {
		let [h, s, l] = this.normalizeValues(this.props.currentValues);

		this.color.fromHSL(h, s, l);

		return (
			<Wrapper>
				<ContextMenu.Menu
					toggleWith={this.pickerTrigger}
					onClick={this.handleCancel}>
					{this.selectedColorIndicator}
					<ContextMenu.Pane>
						<ValuesWrapper>
							<Slider hsl={[h, 100, 50]}>
								<HueSlider onChange={this.handleHueChange} value={h} />
							</Slider>
							<Slider hsl={[h, 100, 50]}>
								<SaturationSlider
									onChange={this.handleSaturationChange}
									value={s}
								/>
							</Slider>
							<Slider hsl={[h, s, 50]}>
								<LightnessSlider
									onChange={this.handleLightnessChange}
									value={l}
								/>
							</Slider>
						</ValuesWrapper>
					</ContextMenu.Pane>
					<LabeledMenuItem>
						<Label htmlFor="hexValue">HEX</Label>
						<Input
							id="hexValue"
							required
							pattern="^#([a-fA-F\d]{3}|[a-fA-F\d]{6})$"
							ref={this.hexFieldRef}
							defaultValue={this.color.toHEX()}
							onChange={this.handleHexChange}
						/>
					</LabeledMenuItem>
					<LabeledMenuItem>
						<Label htmlFor="rgbValue">RGB</Label>
						<Input
							id="rgbValue"
							required
							pattern="^(?:(?:^|,\s*)([01]?\d\d?|2[0-4]\d|25[0-5])){3}$"
							ref={this.rgbFieldRef}
							defaultValue={this.color.toRGB()}
							onChange={this.handleRgbChange}
						/>
					</LabeledMenuItem>
					<ContextActions noLink>
						<Button onClick={this.handleCancel}>{t('Cancel')}</Button>
						<Button
							primary
							onClick={this.handleSelect}
							disabled={!this.state.canSelect}>
							{t('Save')}
						</Button>
					</ContextActions>
				</ContextMenu.Menu>
			</Wrapper>
		);
	}
}
