import { ReactNode } from 'react';

export const symbol_nest_cam_magnet_mount: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M930-318q-28.875 0-49.438-20.562Q860-359.125 860-388v-160q0-29 20.562-49.5Q901.125-618 930-618h30v300h-30Zm-640.25 97q-11.25 0-22.5-4.5T248-238L50-435q-9-8.958-13.5-19.782T32-476.803Q32-488 36.5-499.5T49-520l129-130q29-29 65.5-44t76.5-15q84 0 142 58t58 142q0 40-15 76.5T461-367L332-238q-8 8-19.5 12.5t-22.75 4.5Zm30.083-428Q293-649 267.5-638.5T221-608L92-478l198 198 130-129q20-21 30.5-46.542 10.5-25.543 10.5-52.246Q461-567 420.167-608q-40.834-41-100.334-41ZM277-465Zm402 103-28-28 56-56H570v-40h142l-60-60 28-28 106 106-107 106Z"
		/>
	</svg>
);
