import { Country } from 'pkg/api/models/country';
import * as models from 'pkg/api/models';

export type AccountRole = 'staff' | 'parent' | 'player';

export enum AccountLoginMethod {
	Email = 'email',
	Apple = 'apple',
}

export interface Account {
	id?: number;
	role?: AccountRole;
	firstName?: string;
	lastName?: string;
	countryId?: number;
	loginType?: AccountLoginMethod;
	email?: string;
	password?: string;
	profileImage?: string;

	year?: number;
	month?: number;
	day?: number;

	createChildAccount?: boolean;
	appleAuthCode?: string;
	inviteKey?: string;
	ssoToken?: string;
}

export interface Group {
	groupName: string;
	organizationName: string;
	isOrganization?: boolean;
	profileImageUrl: string;
	primaryColor: string;
	country: Country;
	organizationId: number;
	organizationSlug?: string;
}

export interface Invite extends Group {
	inviteId: number;
	author: any;
	targetChildren: any;
	targetGroups: any;
	targetUsers: any;
	targetGroupRoles: models.membership.MembershipRole[];
}

export interface OnboardingInfo {
	invite?: Invite;
	group?: Group;
	account?: Account;
	targetAccount?: Account;
	identityProviders?: models.identityProvider.ScrubbedIdentityProvider[];
}

/**
 *	Validates whether or not a onboarding account can be created into an actual account.
 *
 *	@param account Account
 *	@returns boolean
 */
export function canCreate(account: Account): boolean {
	const validLength = (
		key: keyof Account,
		min: number = 0,
		max?: number
	): boolean => {
		if (key in account) {
			const len = account[key].toLocaleString().trim().length;

			if (!max) {
				return len >= min;
			} else {
				return len >= min && len <= max;
			}
		}

		return false;
	};

	if (!validLength('firstName', 2)) return false;
	if (!validLength('lastName', 2)) return false;
	if (!validLength('year', 4)) return false;
	if (!validLength('month', 1, 2)) return false;
	if (!validLength('day', 1, 2)) return false;

	if (!(account?.email.includes('@') && validLength('email', 3))) return false;

	return true;
}
