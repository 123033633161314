import * as React from 'react';
import styled from 'styled-components';
import {
	ResponsiveContainer,
	PolarAngleAxis,
	RadarChart,
	PolarGrid,
	Radar,
} from 'recharts';

import * as styles from 'pkg/config/styles';

const ChartContainer = styled(ResponsiveContainer)`
	max-width: 80%;
	margin: auto;

	@media ${styles.breakpoint.small} {
		max-width: 80%;
	}

	@media ${styles.breakpoint.small} {
		margin-top: var(--spacing-5);
	}

	.recharts-polar-angle-axis-tick-value {
		transform: translateY(3px);

		tspan {
			font-size: var(--font-size-xs);
			font-weight: var(--font-weight-normal);
			fill: var(--palette-gray-500);
		}
	}

	.recharts-default-legend {
		display: flex;
		flex-flow: column-reverse nowrap;

		li {
			padding: var(--spacing-1);

			span {
				margin-left: var(--spacing-2);
				vertical-align: middle;
				font-size: var(--font-size-xs);
				color: var(--palette-gray-500);
				font-weight: var(--font-weight-normal);
			}
		}
	}

	svg.recharts-surface {
		overflow: visible;
	}
`;

type PositionChartProps = {
	data: any[];
	idealName: string;
	primaryName: string;
};

const PositionChart: React.FC<React.PropsWithChildren<PositionChartProps>> = ({
	data,
	idealName,
	primaryName,
}) => {
	return (
		<ChartContainer height="auto" aspect={1}>
			<RadarChart outerRadius="100%" data={data} margin={{ top: 50 }}>
				<PolarGrid />
				<PolarAngleAxis dataKey="category" />

				<Radar
					name={idealName}
					dataKey="ideal"
					stroke={styles.palette.gray[500]}
					fill={styles.palette.gray[300]}
					fillOpacity={0.6}
				/>

				<Radar
					name={primaryName}
					dataKey="primary"
					stroke={styles.palette.blue[600]}
					fill={styles.palette.blue[500]}
					fillOpacity={0.7}
				/>
			</RadarChart>
		</ChartContainer>
	);
};

export default PositionChart;
