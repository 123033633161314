import { ReactNode } from 'react';

export const symbol_view_kanban: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-60h600v-600H180v600Zm0-600v600-600Zm128.825 97Q296-683 287.5-674.375T279-653v346q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T339-307v-346q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625Zm342 0Q638-683 629.5-674.375T621-653v266q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T681-387v-266q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625Zm-171 0Q467-683 458.5-674.375T450-653v146q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T510-507v-146q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625Z"
		/>
	</svg>
);
