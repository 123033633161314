import { useT } from '@transifex/react';

import * as styles from 'pkg/config/styles';

import Section from 'components/form/Section';
import Column from 'components/layout/column';
import * as Input from 'components/form/inputs';

interface AvailabilitySectionProps {
	active: boolean;

	handleActiveChange: () => void;
}

export default function AvailabilitySection({
	active,
	handleActiveChange,
}: AvailabilitySectionProps) {
	const t = useT();

	return (
		<Section title={t('Availability')} hideDivider>
			<Column>
				<Column spacing={styles.spacing._3}>
					<Input.Control
						type="radio"
						checked={active}
						label={t('Available for customers to use')}
						onChange={handleActiveChange}
					/>
					<Input.Control
						type="radio"
						checked={!active}
						label={t('Not available')}
						onChange={handleActiveChange}
					/>
				</Column>
			</Column>
		</Section>
	);
}
