import { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import * as selectors from 'pkg/selectors';
import * as models from 'pkg/api/models';
import { IdentityContext } from 'pkg/identity';

import CardWrapper, * as Card from 'components/Card';
import Modal from 'components/modal';

import RatingForm from 'components/rating/Form';

const NOOP = () => {};

const CardBase = styled(CardWrapper)`
	height: 100%;
	width: 100%;
	max-height: 100%;
`;

class RatingFormModal extends Component {
	static contextType = IdentityContext;
	static defaultProps = {
		closeModal: NOOP,
		handleSaved: NOOP,
	};

	render() {
		const { user } = this.props;

		return (
			<Modal open onClose={this.props.closeModal}>
				<CardBase>
					<Card.Header centered>
						<Card.Heading>
							{user?.firstName} {user?.lastName}
						</Card.Heading>
					</Card.Header>

					<Card.Divider />

					<RatingForm
						key="rating-form"
						onSave={this.props.handleSaved}
						ratingId={this.props.primaryRatingId}
						userId={this.props.userId}
						groupId={this.props.groupId}
						organizationId={this.props.organizationId}
						isAdminOrStaff={models.membership.isAdminOrStaff(
							this.context.membership
						)}
						forAccount={this.props.forAccount}
					/>
				</CardBase>
			</Modal>
		);
	}
}

const mapStateToProps = () => {
	return (state, props) => {
		return {
			primaryRatingId: props.ratingId
				? props.ratingId
				: selectors.ratings.getUserPrimaryRating(state, props)?.id,
			hasRatingItems: state.ratingItems.entities.length > 0,
			positions: state.positions.entities,
			user: selectors.users.find(state, props),
		};
	};
};

export default connect(mapStateToProps)(RatingFormModal);
