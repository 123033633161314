import { ReactNode } from 'react';

export const symbol_join_inner: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M40-480q0-56 21-107t61-91q54-54 127.5-73t147.5 2q12 4 18.5 14.5T418-712q-4 12-14.5 18t-22.5 2q-58-17-115.5-2T165-636q-31 31-48 71t-17 85q0 44 17 84t48 71q43 43 100.5 58.5T381-268q12-4 22.5 2t14.5 18q4 12-2 22.5T398-211q-74 22-148 2.5T122-282q-40-40-61-91T40-480Zm880 0q0 56-21 107t-61 91q-54 54-128 73.5T562-211q-12-4-18-14.5t-2-22.5q4-12 14.5-18t22.5-2q58 17 116 1.5T796-325q31-31 47.5-71t16.5-84q0-44-16.5-84.5T796-636q-43-43-101-58t-116 2q-12 4-22.5-2T542-712q-4-12 2-22.5t18-14.5q74-22 148-2.5T838-678q40 40 61 91t21 107ZM480-259q-5 0-9.5-1.5T461-266q-48-41-74.5-95.5T360-480q0-64 26.5-118.5T461-694q5-4 9.5-5.5t9.5-1.5q5 0 9.5 1.5t9.5 5.5q48 41 74.5 95.5T600-480q0 64-26.5 118.5T499-266q-5 4-9.5 5.5T480-259Z"
		/>
	</svg>
);
