import { ReactNode } from 'react';

export const symbol_movie_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-160q-24 0-42-18t-18-42v-520q0-24.75 17.625-42.375T140-800h20l212 212H140v368h600l60 60H140Zm725-21-45-45v-362H458L246-800h78l58 119q8 15 22 24t31 9q32.459 0 49.229-27.5Q501-703 487-732l-33-68h130l58 119q7.729 15.4 22.083 24.2Q678.438-648 695-648q32.5 0 49.25-27.462Q761-702.923 747-732l-33-68h106q24 0 42 18t18 42v520.163Q880-209 876-199t-11 18Zm-73 98L82-792q-9-9.067-9-21.533Q73-826 82.053-835q9.052-9 21.5-9Q116-844 125-835l710 710q9 9 9 21t-9.053 21q-9.052 9-21.5 9Q801-74 792-83ZM470-404Zm169-3Z"
		/>
	</svg>
);
