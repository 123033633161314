import { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { t } from '@transifex/native';

import * as breakpoints from 'pkg/config/breakpoints';

import {
	getCurrentRating,
	getCurrentRatings,
} from 'pkg/selectors/ratings.selector';

import {
	addRating,
	addRatings,
	setComparisonRatingId,
	setUserRatingItems,
	setRatingsError,
} from 'pkg/actions/ratings';
import { forAccountHeaders } from 'pkg/actions/utils';

import DateTime from 'pkg/datetime';
import * as sdk from 'pkg/core/sdk';
import * as routes from 'pkg/router/routes';
import { AccountIdentityContext } from 'pkg/identity/account';

import CardBase, * as Card from 'components/Card';
import ModalBase from 'components/modal';

import {
	ResponsiveTable,
	Table,
	TableRow as TR,
	TableBody as TBody,
	TableCell,
} from 'components/legacy/Table';
import { Spinner } from 'components/loaders/spinner';

import Button from 'design/button';

const ButtonText = styled.span`
	display: inline-block;
	width: 100%;
`;

const TD = styled(TableCell)`
	padding-top: 5px;
	padding-bottom: 6px;

	@media ${breakpoints.small} {
		padding: 5px 0 6px 0;
	}
`;

class ComparisonModalContainer extends Component {
	static contextType = AccountIdentityContext;

	state = {
		links: {},
		loading: false,
	};

	async componentDidMount() {
		this.setState({
			loading: true,
		});

		await this.fetchRatings();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.comparisonRatingId !== this.props.comparisonRatingId) {
			this.props.onClose();
		}
	}

	fetchRatings = async () => {
		const {
			user: { id: userId },
			activeGroupId,
		} = this.props;

		const params = {};

		const { groupId } = this.props.currentRating;

		if (groupId) {
			params.group_id = groupId;
		} else {
			params.group_id = activeGroupId;
		}

		let url = `users/${userId}/ratings`;

		if (this.state.links?.next) {
			url = this.state.links?.next;
		}

		const headers = forAccountHeaders(this.props.forAccount);
		const response = await sdk.get(url, params, {}, headers);
		const ratings = await response.json();

		const prevRatingIds = [this.props.currentRating, ...this.props.ratings].map(
			(rating) => rating.id
		);

		// filter out already fetched ratings to keep their "hiddenScores"-value
		const records = ratings.records?.filter(
			(rating) => !prevRatingIds.includes(rating.id)
		);

		if (records?.length) {
			this.props.addRatings(records);
		}

		this.setState({
			links: ratings.links,
			loading: false,
		});
	};

	getLongName(positionScores, suggestedPositionId) {
		return positionScores?.find(
			(score) => score.positionId === suggestedPositionId
		)?.position?.name;
	}

	renderRating = (rating) => {
		const longName = this.getLongName(
			rating.positionScores,
			rating.suggestedPositionId
		);

		const { activeGroupId: groupId, userId, ratingId } = this.props;
		const organizationId = this.context.organization?.id;

		return (
			<TR key={rating.id}>
				<TD textAlign="left" active>
					<p>
						{new DateTime(new Date(rating.createdAt * 1000)).toDateString()}
					</p>
				</TD>
				<TD textAlign="left" active>
					<p>{`${rating.author.firstName} ${rating.author.lastName}`}</p>
				</TD>
				<TD textAlign="left" active>
					<p>{longName}</p>
				</TD>
				<TD textAlign="left" active>
					<Button
						block
						href={routes.User.Profile.PositionMatchCompare(
							organizationId,
							groupId,
							userId,
							ratingId,
							rating.id
						)}>
						<ButtonText>{t(`Compare`)}</ButtonText>
					</Button>
				</TD>
			</TR>
		);
	};

	get cardFooter() {
		if (this.props.comparisonRatingId || this.state.links?.next) {
			const loadMoreMargins = {};
			if (!this.props.comparisonRatingId) {
				loadMoreMargins.margin = '0 auto';
			}

			const { activeGroupId: groupId, userId, ratingId } = this.props;
			const organizationId = this.context.organization?.id;

			return (
				<Card.Footer>
					{this.state.links?.next && (
						<Button large onClick={this.fetchRatings} style={loadMoreMargins}>
							{t('Load more')}
						</Button>
					)}
					{!!this.props.comparisonRatingId && (
						<Button
							primary
							large
							icon="close"
							href={routes.User.Profile.PositionMatch(
								organizationId,
								groupId,
								userId,
								ratingId
							)}>
							{t(`Clear`)}
						</Button>
					)}
				</Card.Footer>
			);
		}

		return null;
	}

	get cardBody() {
		if (this.props.ratings.length > 0) {
			return (
				<ResponsiveTable>
					<Table>
						<TBody>
							{this.props.ratings
								.filter((r) => r.id != this.props.ratingId)
								.map(this.renderRating)}
						</TBody>
					</Table>
				</ResponsiveTable>
			);
		}

		return t(`No assessments to compare with`);
	}

	get card() {
		return (
			<CardBase>
				<Card.Header centered>
					<Card.Heading>{t(`Compare assessment`)}</Card.Heading>
				</Card.Header>

				<Card.Body $flex>
					{this.state.loading ? <Spinner /> : this.cardBody}
				</Card.Body>

				{this.cardFooter}
			</CardBase>
		);
	}
	render() {
		return (
			<ModalBase open onClose={this.props.onClose}>
				{this.card}
			</ModalBase>
		);
	}
}

export default connect(
	() => {
		return (state, props) => ({
			currentRating: getCurrentRating(state, props),
			user: getCurrentRating(state, props).user,
			ratings: getCurrentRatings(state, props),
			currentPosition: state.ratings.currentPosition,
			comparisonRatingId: state.ratings.comparisonRatingId,
		});
	},
	{
		addRatings,
		addRating,
		setComparisonRatingId,
		setUserRatingItems,
		setRatingsError,
	}
)(ComparisonModalContainer);
