import {
	Children,
	memo,
	useRef,
	useMemo,
	useState,
	useEffect,
	useReducer,
	createElement,
	createContext,
} from 'react';
import PropTypes from 'prop-types';

import { omit } from 'pkg/objects';
import { UrlParser } from 'pkg/url';
import { fromEntries } from 'pkg/utils';
import {
	RouterContext,
	initialState,
	initializeState,
	browserReducer,
	BROWSER_POP,
	BROWSER_PUSH,
	BROWSER_REPLACE,
	BROWSER_RESET,
} from 'pkg/router/store';
import parse from 'pkg/router/parser';
import * as utils from 'pkg/router/utils';
import { useCurrentRouterState } from 'pkg/router/hooks';
import { useEventListener } from 'pkg/hooks/events';
import { isDev, isDevServer } from 'pkg/flags';
import notifyRouterChanges from 'pkg/router/tools/notifyRouterChanges';
import { tlog } from 'pkg/tlog';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';

export const CurrentRouteContext = createContext();

/**
 *	Validates component children as valid Route components.
 *
 *	@param object props
 *	@param string propName
 *	@param string componentName
 *
 *	@return bool
 */
const validateChildrenAsRouteComponent = (props, propName, componentName) => {
	if (propName === 'children') {
		const property = props[propName];
		const children = Children.toArray(property);

		if (children.length === 0) return null;

		let invalidChildren = children.filter((child) => {
			if (child.type === Route) return false;
			if (child.type === IndexRoute) return false;

			return true;
		});

		const hasInvalidChildren = invalidChildren.length > 0;
		const invalidChildError = new Error(
			`${componentName} can only Route or IndexRoute component as children.`
		);

		return hasInvalidChildren && invalidChildError;
	}
};

/**
 *	Validates component children empty.
 *
 *	@param object props
 *	@param string propName
 *	@param string componentName
 *
 *	@return bool
 */
const validateZeroChildrenRouteComponent = (props, propName, componentName) => {
	if (propName === 'children') {
		const property = props[propName];
		const invalidChildren = Children.toArray(property).length > 0;
		const invalidChildError = new Error(
			`${componentName} cannot have any children.`
		);

		return invalidChildren && invalidChildError;
	}
};

/**
 *	Validates IndexRoute path attribute.
 *
 *	@param object props
 *	@param string propName
 *	@param string componentName
 *
 *	@return bool
 */
const validateIndexRoutePath = (props, propName, componentName) => {
	if (propName === 'path') {
		const property = props[propName];

		if (property !== '/') {
			return new Error(
				`IndexRoute path cannot be overridden. Defined in ${componentName}`
			);
		}

		return null;
	}
};

const urlParser = new UrlParser();

/**
 *	Helper function to invoke route middlewares.
 *
 *	@param []function middlewares
 *	@param object props
 *	@param bool (true) manipulateProps
 *
 *	@return object
 */
const invokeMiddlewares = (middlewares, props, manipulateProps = true) => {
	if (!middlewares) return props;

	const assumeLiteralObject = (value) =>
		value !== null &&
		typeof value === 'object' &&
		Array.isArray(value) === false;

	Array.from(middlewares).forEach((middleware) => {
		if (!manipulateProps) {
			middleware(props);
		} else {
			try {
				let result = middleware(props);

				if (assumeLiteralObject(result)) {
					props = { ...props, ...result };
				}
			} catch (error) {
				if (isDev()) {
					tlog.exception(error);
					tlog.error(
						`The above error occured in middleware: ${middleware.name}`
					);
				}

				tlog.exception(error);
			}
		}
	});

	return props;
};

/**
 *	Delegates current path to the corresponding parsed route object.
 *
 *	@param object parsedRoutes
 *	@param object props
 *
 *	@return object
 */
function delegate(parsedRoutes, props) {
	let {
		currentPath,
		include,
		notFoundComponent,
		errorComponent,
		beforeEach,
		afterEach,
	} = props;

	if (!notFoundComponent) notFoundComponent = null;
	if (!errorComponent) errorComponent = null;

	if (!include) include = {};

	if (!currentPath) currentPath = '/';

	if (currentPath.substring(0, 1) !== '/') {
		currentPath = `/${currentPath}`;
	}

	let component = null,
		routeMatch = null;

	let hasMatch = false,
		hasError = false,
		notFound = false;

	let params = {};

	let route = parsedRoutes.find(({ path }) => {
		routeMatch = urlParser.parse(path, currentPath);

		return routeMatch.match;
	});

	/**
	 *	Handles defined routes redirects
	 *
	 *	<Route path="old/path/somewhere" redirectTo="path/somewhere" />
	 */
	if (route && route.redirectTo) {
		const parsed = urlParser.parse(route.path, currentPath);
		const redirectPath = utils.transform(route.redirectTo, parsed.params);

		route = parsedRoutes.find(({ path }) => path === route.redirectTo);

		if (route) {
			window.history.replaceState({}, null, `/${redirectPath}`);
		}
	}

	try {
		if (route) {
			hasError = false;

			params = omit(
				{
					...route,
					...routeMatch,
					...routeMatch.params,
					...include,
				},
				'params',
				'match'
			);

			params.query = {
				...params.query,
				...fromEntries(new URLSearchParams(window.location.search)),
			};

			const pathParts = params.pattern
				.split('/')
				.map((n) => n.replace(':', ''));

			// Validates and optionally transform each param in route
			hasMatch = pathParts.every((n) => {
				const morphedProp = `match${n.charAt(0).toUpperCase() + n.slice(1)}`;
				const pattern = params[morphedProp] || null;

				if (!pattern) return true;

				return new RegExp(`^${pattern}$`, 'i').test(params[n]);
			});

			if (!hasMatch) {
				notFound = true;

				component = notFoundComponent;

				return;
			}

			pathParts.forEach((n) => {
				const morphedProp = `transform${
					n.charAt(0).toUpperCase() + n.slice(1)
				}`;

				const transform = params[morphedProp] || null;

				if (transform && typeof transform === 'function') {
					params[n] = transform(params[n]);
				} else if (!transform && n.endsWith('Id')) {
					params[n] = Number.parseInt(params[n], 10);
				}
			});

			params = invokeMiddlewares(beforeEach, params);

			if (route.before) {
				params = invokeMiddlewares(Array.from(route.before), params);
			}

			params = omit(params, 'before', 'after');

			if (route.after) {
				invokeMiddlewares(Array.from(route.after), params, false);
			}

			invokeMiddlewares(afterEach, params, false);
		} else {
			notFound = true;
			hasMatch = false;

			if (isDev()) {
				console.error('Route path "%s" not found!', props.currentPath);
			}

			component = notFoundComponent;
		}
	} catch (error) {
		hasError = true;

		if (isDev()) {
			console.error(error);
		}

		component = errorComponent;
	} finally {
		return {
			notFound,
			hasError,
			component,
			hasMatch,
			...params,
		};
	}
}

/**
 *	Route
 *
 *	@param string *path
 *	@param React.Element component
 *	@param function before - Middleware executed *before* match
 *	@param function after - Middleware executed *after* match
 *  @type {React.Element<any>}
 */
export const Route = memo(() => null);

Route.propTypes = {
	path: PropTypes.string.isRequired,
	redirectTo: PropTypes.string,
	component: PropTypes.elementType,
	children: validateChildrenAsRouteComponent,

	before: PropTypes.arrayOf(PropTypes.func),
	after: PropTypes.arrayOf(PropTypes.func),
};

Route.defaultProps = {
	component: null,
};

/**
 *	IndexRoute
 *
 *	Behaves the same as <Route>, assumes node match of "/" and cannot contain any children.
 *
 *	@see Route
 *
 *	@param React.Element *component
 *
 *  @type {React.Element<any>}
 *
 */
export const IndexRoute = memo(() => null);

IndexRoute.propTypes = {
	path: validateIndexRoutePath,
	component: PropTypes.elementType.isRequired,
	children: validateZeroChildrenRouteComponent,

	before: PropTypes.arrayOf(PropTypes.func),
	after: PropTypes.arrayOf(PropTypes.func),
};

IndexRoute.defaultProps = {
	path: '/',
};

/**
 *	Router
 *
 *  @type {React.Element<any>}
 */
const Router = memo(
	({
		proxiedChildren,
		children,
		currentPath,
		include,
		wrapAllWith,
		onSetup,
		onTearDown,
		beforeEach,
		afterEach,
		onNotFound,
		onError,
		notFoundComponent = null,
		errorComponent = null,
	}) => {
		const parsedRoutes = useRef();

		const [delegated, setDelegated] = useState(false);
		const [currentState] = useCurrentRouterState();

		useComponentDidMount(() => {
			if (isDevServer()) {
				window.updateRoutes = () => {
					notifyRouterChanges(parse(children));
				};
			}
		});

		useEffect(() => {
			if (!parsedRoutes.current) {
				const routes = parse(children);
				parsedRoutes.current = routes;
			}

			setDelegated(
				delegate(parsedRoutes.current, {
					notFoundComponent,
					errorComponent,
					currentPath,
					include,
					wrapAllWith,
					beforeEach,
					afterEach,
				})
			);
		}, [currentPath]);

		useEffect(() => {
			if (!delegated && onSetup instanceof Function) {
				onSetup();
			}

			return () => {
				if (delegated && onTearDown instanceof Function) {
					onTearDown();
				}
			};
		}, [delegated, onSetup, onTearDown]);

		const contextValue = useMemo(
			() => ({
				delegated,
			}),
			[delegated]
		);

		if (!delegated) return null;

		if (delegated.notFound && onNotFound instanceof Function) {
			onNotFound(currentPath);
		}

		if (delegated.hasError && onError instanceof Function) {
			onError(currentPath);
		}

		if (!delegated.component && !delegated.redirectTo) return null;

		let component = createElement(delegated.component, {
			currentState,
			...delegated,
		});

		if (delegated.wrapWith) {
			component = createElement(
				delegated.wrapWith,
				{ key: delegated.name, currentState, ...delegated },
				component
			);
		}

		if (wrapAllWith) {
			component = createElement(
				wrapAllWith,
				{ key: delegated.name, currentState, ...delegated },
				component
			);
		}

		if (delegated.subNavItems) {
			delegated.subNavItems.forEach((item) => {
				item.replaceParams?.forEach((param) => {
					item.params = {
						...item.params,
						[param]: delegated[param],
					};
				});

				if (item.keepQueryString) {
					item.query = delegated.query;
				}
			});
		}

		if (delegated.redirectTo) {
			setDelegated(
				delegate(parsedRoutes.current, {
					notFoundComponent,
					errorComponent,
					currentPath: delegated.redirectTo,
					include,
					wrapAllWith,
					beforeEach,
					afterEach,
				})
			);

			// @NOTE This will change the URL, but not trigger events
			window.history.replaceState({}, null, delegated.redirectTo);
		}

		return (
			<CurrentRouteContext.Provider value={contextValue}>
				{proxiedChildren}
				{component}
			</CurrentRouteContext.Provider>
		);
	}
);

export default Router;

/**
 *	HoC for browser state router.
 */
export const withBrowserState =
	(WrappedComponent) =>
	({ children = null, ...props }) => {
		let location = window.location.pathname;

		if (window.location.search.length > 0) {
			location += window.location.search;
		}

		const [store, dispatch] = useReducer(
			browserReducer,
			initialState,
			initializeState([{ location }])
		);

		const contextValue = useMemo(
			() => ({
				store,
				dispatch,
				stateType: 'browser',
			}),
			[store, dispatch]
		);

		const handleBrowserPopState = () => {
			let state = window.history.state;

			dispatch({
				skipMutations: true,
				type: BROWSER_REPLACE,
				payload: {
					location: state?.location || '/',
				},
			});
		};

		useEventListener('popstate', handleBrowserPopState);

		// @NOTE Router specific events to manipulate store outside of hooks

		useEventListener('router:pop', (event) => {
			const { location, state } = event.detail;

			dispatch({
				type: BROWSER_POP,
				payload: {
					location,
					state,
				},
			});
		});

		useEventListener('router:push', (event) => {
			const { location, state } = event.detail;

			dispatch({
				type: BROWSER_PUSH,
				payload: {
					location,
					state,
				},
			});
		});

		useEventListener('router:replace', (event) => {
			const { location, state } = event.detail;

			dispatch({
				type: BROWSER_REPLACE,
				payload: {
					location,
					state,
				},
			});
		});

		useEventListener('router:reset', (event) => {
			const { fullReset } = event.detail;

			dispatch({
				type: BROWSER_RESET,
				payload: {
					fullReset,
				},
			});
		});

		return (
			<RouterContext.Provider value={contextValue}>
				<WrappedComponent {...props}>{children}</WrappedComponent>
			</RouterContext.Provider>
		);
	};
