import { t } from '@transifex/native';
import styled from 'styled-components';

import {
	Table as Tbl,
	TableHead,
	TableBody,
	TableRow,
	TableHeadCell,
	TableBodyCell,
} from 'components/group/statistics/Table';

const Table = styled(Tbl)`
	border-radius: var(--radius-2);
`;

const LegendTable = ({ items }) => {
	const tableRows = items.map((item, index) => (
		<TableRow key={`${index}-${item.short}`}>
			<TableBodyCell alignLeft>{item.short}</TableBodyCell>
			<TableBodyCell alignLeft>{item.long}</TableBodyCell>
		</TableRow>
	));

	return (
		<Table>
			<TableHead>
				<TableRow>
					<TableHeadCell alignLeft>{t(`Short name`)}</TableHeadCell>
					<TableHeadCell alignLeft>{t(`Long name`)}</TableHeadCell>
				</TableRow>
			</TableHead>
			<TableBody>{tableRows}</TableBody>
		</Table>
	);
};

export default LegendTable;
