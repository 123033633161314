import { ReactNode } from 'react';

export const symbol_crowdsource: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M660-570q-26 0-43-17t-17-43q0-26 17-43t43-17q26 0 43 17t17 43q0 26-17 43t-43 17Zm-360 0q-26 0-43-17t-17-43q0-26 17-43t43-17q26 0 43 17t17 43q0 26-17 43t-43 17Zm180 110q-26 0-43-17t-17-43q0-26 17-43t43-17q26 0 43 17t17 43q0 26-17 43t-43 17Zm0-220q-26 0-43-17t-17-43q0-26 17-43t43-17q26 0 43 17t17 43q0 26-17 43t-43 17Zm0 520q-19 0-39-3t-39-8v-144q0-35 22-60t56-25q34 0 56 25t22 60v144q-19 5-39 8t-39 3Zm-138-31q-19-8-38-18t-36-22q-28-19-44.5-51T207-351q0-26-5-49.5T182-444q-11-12-38.5-38T93-531q-13-14-13-29.5T93-589q14-14 28-14t28 14l154 145q19 18 29 43t10 51v159Zm276 0v-159q0-26 12.5-51t31.5-43l149-145q12-11 28.5-11t27.5 11q13 13 13 28.5T867-531q-23 23-50.5 48T778-444q-15 20-20 43.5t-5 49.5q0 37-16.5 69T692-231q-17 11-36 21.5T618-191Z"
		/>
	</svg>
);
