import { ReactNode } from 'react';

export const symbol_timer_3_select: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M421-200H200q-16.667 0-28.333-11.735Q160-223.471 160-240.235 160-257 171.667-268.5 183.333-280 200-280h221v-160H202q-16.667 0-28.333-11.735Q162-463.471 162-480.235 162-497 173.667-508.5 185.333-520 202-520h219v-160H200q-16.667 0-28.333-11.735Q160-703.471 160-720.235 160-737 171.667-748.5 183.333-760 200-760h221q33.333 0 56.667 23.333Q501-713.333 501-680v131q0 29-19.25 50T435-478q27.5 0 46.75 19.542Q501-438.917 501-411v131q0 33.333-23.333 56.667Q454.333-200 421-200Zm377 0H630q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T630-260h150v-70H642q-17 0-29.5-12.5T600-372v-106q0-17 12.5-29.5T642-520h168q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-460H660v70h138q17 0 29.5 12.5T840-348v106q0 17-12.5 29.5T798-200Z"
		/>
	</svg>
);
