import { ReactNode } from 'react';

export const symbol_inpatient: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-80q-24.75 0-42.375-17.625T80-140v-680q0-24.75 17.625-42.375T140-880h400q24.75 0 42.375 17.625T600-820v680q0 24.75-17.625 42.375T540-80H140Zm0-445q20-17 45.5-26t54.5-9h200q29 0 54.5 9t45.5 26v-295H140v295Zm199.911-85Q311-610 290.5-630.589q-20.5-20.588-20.5-49.5Q270-709 290.589-729.5q20.588-20.5 49.5-20.5Q369-750 389.5-729.411q20.5 20.588 20.5 49.5Q410-651 389.411-630.5q-20.588 20.5-49.5 20.5ZM140-140h400v-260q0-42-29-71t-71-29H240q-42 0-71 29t-29 71v260Zm170-150v50q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T370-240v-50h50q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T420-350h-50v-50q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T310-400v50h-50q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T260-290h50ZM140-140h400-400Zm651.158-221q-8.842 9-21 9T749-361l-97-98q-9-9-9-21t9-21l97-98q8.8-9 20.9-9 12.1 0 21.1 9 9 9 9.5 21t-8.5 21l-47 47h135q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T880-450H745l46 46q9 9.067 9 21.533Q800-370 791.158-361Z"
		/>
	</svg>
);
