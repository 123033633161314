import { ReactNode } from 'react';

export const symbol_cameraswitch: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M313-294q-24.75 0-42.375-17.625T253-354v-252q0-24.75 17.625-42.375T313-666h45l21-21q5-5 10.217-7 5.218-2 10.783-2h163q5.565 0 10.783 2Q579-692 584-687l21 21h45q24.75 0 42.375 17.625T710-606v252q0 24.75-17.625 42.375T650-294H313Zm0-60h337v-252H313v252Zm168.177-65Q506-419 523.5-436.677t17.5-42.5Q541-504 523.323-521.5t-42.5-17.5Q456-539 438.5-521.323t-17.5 42.5Q421-454 438.677-436.5t42.5 17.5Zm.823-61ZM481 0Q303.884 0 170.543-112.204 37.203-224.409 5-395q-2-14 4.5-26t20-14q13.5-2 22.914 6.013Q61.828-420.974 64-408q23 142 137 241.5T459-59l-55-55q-9-9-8.5-21.5T405-157q9-9 21.5-9t21.5 9L575-29q5 5 3.5 12T570-8q-22 5-45 6.5T481 0Zm0-960q176.798 0 309.899 112.5T955-564q2 14-4 25.5T931-525q-12 2-22-6.5T897-552q-23-142-137-241.5T502-901l55 55q9 9 9 21.5t-9 21.5q-9 9-21.5 9t-21.5-9L386-931q-5-5-3.036-12.2 1.965-7.2 9.036-8.8 22-5 44.5-6.5T481-960Z"
		/>
	</svg>
);
