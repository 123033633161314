import { useState } from 'react';
import { useT } from '@transifex/react';

import SectionTitle from 'components/SectionTitle';

import Column from 'components/layout/column';
import JoinGroupModal from 'components/group/join-modal';

import Button from 'design/button';
import MissingEntities from 'design/placeholders/block';

export default function Children(): JSX.Element {
	const [joinGroupModalOpen, setJoinGroupModalOpen] = useState<boolean>(false);

	const t = useT();

	const openJoinGroupModal = () => setJoinGroupModalOpen(true);
	const closeJoinGroupModal = () => setJoinGroupModalOpen(false);

	return (
		<Column>
			<SectionTitle>
				{t('Child connections')}
				<Button
					inline
					noPadding
					icon="add"
					label={t('Add a child')}
					onClick={openJoinGroupModal}
				/>
			</SectionTitle>

			<MissingEntities
				noBorder
				title={t('No children')}
				description={t('Get started by creating an account for your child.')}
				icon="user-add"
				actions={
					<Button
						secondary
						icon="add"
						label={t('Create child account')}
						onClick={openJoinGroupModal}
					/>
				}
			/>

			{joinGroupModalOpen && (
				<JoinGroupModal onlyAccountCreate onClose={closeJoinGroupModal} />
			)}
		</Column>
	);
}
