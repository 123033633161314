import { ReactNode } from 'react';

export const symbol_hotel_class: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m668-380 172-148 13 1q13 1 20.5 10.097t7.5 19.709q0 6.065-3 12.129Q875-479 870-474L736-359l41 177q1 1.538 1 3.462v3.461Q778-162 768.933-154q-9.066 8-21.155 8-3.778 0-7.903-1T732-150l-13-8-51-222Zm-93-289-57-134 5-11q3.654-8.64 11.327-13.32T550.25-832q8.25 0 16.125 4.5T578-814l64 151-67-6ZM263-245l157-94 157 95-42-178 138-120-182-16-71-168-71 167-182 16 138 120-42 178Zm-74 56 49-212L74-544q-5-5-8-10.667-3-5.666-3-11.333 0-10.684 7.5-19.842T91-596l217.078-19.092L392-815q3.75-9 11.625-13.5t16.125-4.5q8.25 0 16.327 4.5Q444.154-824 448-815l83.922 199.908L749-596q13 1 20.5 10.13 7.5 9.131 7.5 19.783 0 6.087-3 11.587t-8 10.5L602-401l49 212q1 2.265 1 6.796 0 12.837-8.988 21.52Q634.024-152 622.214-152 620-152 606-157L420-269 234-157q-4 2-8.125 3.5T218-152q-13.5 0-23.25-11T189-189Zm231-276Z"
		/>
	</svg>
);
