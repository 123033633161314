import { ReactNode } from 'react';

export const symbol_whatshot: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M81-510q11-155 126-262.5T480-880q83 0 155.5 31.5t127 86q54.5 54.5 86 127T880-480q0 82-31.5 155t-86 127.5q-54.5 54.5-127 86T480-80q-57 0-109.5-14.5T272-137q-13-8-14-22.5t10-25.5q7-7 17.5-8t19.5 4q41 24 85.5 36.5T480-140q143 0 241.5-98.5T820-480q0-143-98.5-241.5T480-820q-136 0-232 88.5T141-510q-1 13-9.5 21.5T110-480q-13 0-21.5-9T81-510Zm399 30Zm179-54v102q0 13 8.5 21.5T689-402q13 0 21.5-8.5T719-432v-170q0-13-8.5-21.5T689-632H519q-13 0-21.5 8.5T489-602q0 13 8.5 21.5T519-572h102L436-387l-106-93q-9-8-21-7t-20 9L134-323q-7 7-8.5 16t3.5 17q7 11 20.5 12.5T172-285l139-139 106 93q9 8 21 7t20-9l201-201Z"
		/>
	</svg>
);
