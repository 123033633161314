import { ReactNode } from 'react';

export const symbol_audio_description: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M798-252q-12.75 0-21.375-8.625T768-282q0-7 2-12.5t7-9.5q34-34 53.5-79.128 19.5-45.129 19.5-97Q850-532 830.5-577T777-656q-5-4-7-9.5t-2-12.5q0-12.75 8.625-21.375T798-708q6 0 11.5 2t9.5 6q42 42 66.5 99T910-479.87q0 64.129-24 120Q862-304 820-262q-4 5-9.5 7.5T798-252ZM684.7-366q-11.9 0-20.3-9t-8.4-21.24q0-5.76 2-11.26t6-9.5q12-12 19-28.017t7-35Q690-499 683-515q-7-16-19-28-4.154-3.723-6.577-10.053Q655-559.383 655-565.34q0-12.66 8.5-21.16t21.25-8.5q6 0 11.625 2.5T707-585q20 20 31.5 47.219T750-480q0 30.562-12 57.781Q726-395 706-375q-3.75 4.154-9.375 6.577T684.7-366ZM79.825-330Q67-330 58.5-338.625T50-360v-210q0-24.75 17.625-42.375T110-630h100q24.75 0 42.375 17.625T270-570v210q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T210-360v-50H110v50q0 12.75-8.675 21.375Q92.649-330 79.825-330ZM110-470h100v-100H110v100Zm285 140q-10.833 0-17.917-7.083Q370-344.167 370-355v-250q0-10.833 7.083-17.917Q384.167-630 395-630h135q24.75 0 42.375 17.625T590-570v180q0 24.75-17.625 42.375T530-330H395Zm35-60h100v-180H430v180Z"
		/>
	</svg>
);
