import { ReactNode } from 'react';

export const symbol_group_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M812-63 621-253h57v33q0 25-17.5 42.5T618-160H98q-25 0-42.5-17.5T38-220v-34q0-35 18-63.5t50-42.5q73-32 131.5-46T358-420q28 0 54.5 3t52.5 8l-75-75q-8 2-16 2.5t-16 .5q-66 0-108-42t-42-108q0-8 .5-16t2.5-16L47-827q-9-9-9-21.5t9-21.5q9-9 21.5-9t21.5 9l765 765q9 9 9 21t-9 21q-9 9-21.5 9T812-63ZM624-422q69 8 129 23t98 35q34 18 52.5 46.5T922-254v94h-36L727-319q-14-35-40.5-60.5T624-422Zm-266 62q-57 0-106.5 11T130-306q-14 6-23 21t-9 31v34h520v-36l-63-63q-59-24-102.5-32.5T358-360Zm178-150q16-23 24-54t8-67q0-45-12.5-79.5T519-774q11-3 24.5-5t24.5-2q66 0 108 42t42 108q0 66-43.5 108T565-481l-29-29Zm-46-46-46-46q2-7 3-14t1-15q0-39-25.5-64.5T358-721q-8 0-15 1t-14 3l-46-46q16-9 35-13.5t40-4.5q66 0 108 42t42 108q0 21-4.5 40T490-556ZM364-220Zm23-440Z"
		/>
	</svg>
);
