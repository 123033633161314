import { ReactNode } from 'react';

export const symbol_reminder: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M260-620h60v-60q0-25.5-17.25-42.75T260-740q-25.5 0-42.75 17.25T200-680q0 25.5 17.25 42.75T260-620Zm180 0q25.5 0 42.75-17.25T500-680q0-25.5-17.25-42.75T440-740q-25.5 0-42.75 17.25T380-680v60h60Zm48 290ZM445-80q-29 0-56-12t-45-35L143-383q-7-9-7-20t8-19l4-4q14-15 34.5-18.5T221-438l99 53v-175h-60q-50 0-85-35t-35-85q0-50 35-85t85-35q16 0 31 4t29 12v-66q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T380-850v66q14.237-8 28.983-12T440-800q50 0 85 35t35 85q0 50-35 85t-85 35h-60v225q0 17-14.5 25.5t-29.5.5l-100-53 156 198q10 12 23.76 18 13.76 6 29.24 6h205q38 0 64-26t26-64v-170q0-25.5-17.25-42.75T680-460H490q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T490-520h190q50 0 85 35t35 85v170q0 63-43.5 106.5T650-80H445Z"
		/>
	</svg>
);
