import { isImmutable } from 'immutable';
export const addComment = (state, action) => {
	const comments = state.comments[action.id];
	const records = [...comments?.records];

	const { recordOrder } = comments;

	if (recordOrder === 'ascending') {
		comments.records = [...records, action.comment];
	} else if (recordOrder === 'descending') {
		comments.records = [action.comment, ...records];
	} else {
		comments.records = [...records, action.comment];
	}

	const { recordCount } = comments;

	comments.recordCount = recordCount + 1;

	const immutable = isImmutable(state.entities);

	let entity = immutable
		? state.entities.get(action.id)
		: state.entities[action.id];
	const commentCount = entity.commentCount;
	entity = entity.set('commentCount', commentCount + 1);

	let entities = state.entities;

	if (immutable) {
		entities = entities.set(action.id, entity);
	} else {
		entities = { ...entities };
		entities[action.id] = entity;
	}

	return {
		...state,
		comments: { ...state.comments },
		entities,
	};
};

export const removeComment = (state, action) => {
	const withoutComment = state.comments[action.id].records.filter(
		(e) => e.id !== action.comment.id
	);
	const comments = { ...state.comments };

	comments[action.id] = {
		...comments[action.id],
		records: withoutComment,
	};

	const { recordCount } = comments[action.id];
	comments[action.id].recordCount = recordCount - 1;

	const immutable = isImmutable(state.entities);

	let entity = immutable
		? state.entities.get(action.id)
		: state.entities[action.id];

	const commentCount = entity.commentCount;
	entity = entity.set('commentCount', commentCount - 1);

	let entities = state.entities;

	if (immutable) {
		entities = entities.set(action.id, entity);
	} else {
		entities = { ...entities };
		entities[action.id] = entity;
	}

	return { ...state, comments, entities };
};

export const updateComment = (state, action) => {
	const comments = { ...state.comments };
	const entityComments = comments[action.id];

	if (entityComments.records.length === 0) {
		return state;
	}

	const commentIndex = entityComments.records
		.map((e) => e.id)
		.indexOf(action.comment.id);

	if (commentIndex === -1) {
		return state;
	}

	const prevComment = { ...entityComments.records[commentIndex] };

	entityComments.records[commentIndex] = {
		...prevComment,
		...action.comment,
	};

	const newComments = {
		...entityComments,
		records: [...entityComments.records],
	};

	comments[action.id] = newComments;

	return {
		...state,
		comments,
	};
};

export const setEntityLikedByUserStatus = (
	state,
	action,
	likedByCurrentUser
) => {
	let entity = state.entities[action.id];

	entity = entity
		.set('likedByCurrentUser', likedByCurrentUser)
		.set('likeCount', entity.likeCount + (likedByCurrentUser ? 1 : -1));

	state.entities[action.id] = entity;

	return {
		...state,
		entities: { ...state.entities },
	};
};

export const addLikesCollection = (state, action) => {
	const { id, collection } = action;

	if (!collection?.records) {
		return state;
	}

	let filteredRecords = [
		...state.likes[id].records,
		...collection.records,
	].reduce((records, record) => {
		if (!records.find((n) => n.userId === record.userId)) {
			records.push(record);
		}

		return records;
	}, []);

	state.likes[id] = {
		...state.likes[id],
		links: collection.links,
		records: filteredRecords,
	};

	return {
		...state,
		likes: { ...state.likes },
	};
};
