import { ReactNode } from 'react';

export const symbol_branding_watermark: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M435-274h301q12.75 0 21.375-8.625T766-304v-198q0-12.75-8.625-21.375T736-532H435q-12.75 0-21.375 8.625T405-502v198q0 12.75 8.625 21.375T435-274ZM140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm0-60h680v-520H140v520Zm0 0v-520 520Z"
		/>
	</svg>
);
