import { ReactNode } from 'react';

export const symbol_emoji_transportation: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M446.018-80Q432-80 422.5-89.487 413-98.975 413-113v-206q0-4.667.5-9.333Q414-333 416-338l46-135q6-18.222 21.75-29.611T519-514h255q19.5 0 35.25 11.389T831-473l46 135q2 5 2.5 9.667.5 4.666.5 9.333v206q0 14.025-9.482 23.513Q861.035-80 847.018-80 833-80 823.5-89.487 814-98.975 814-113v-34H479v34q0 14.025-9.482 23.513Q460.035-80 446.018-80ZM466-359h361l-38-115H504l-38 115Zm-13 40v132-132Zm67 106q16.575 0 27.787-11.212Q559-235.425 559-252q0-16.575-11.213-27.788Q536.575-291 520-291t-27.788 11.212Q481-268.575 481-252q0 16.575 11.212 27.788Q503.425-213 520-213Zm253 0q16.575 0 27.787-11.212Q812-235.425 812-252q0-16.575-11.213-27.788Q789.575-291 773-291t-27.787 11.212Q734-268.575 734-252q0 16.575 11.213 27.788Q756.425-213 773-213ZM240-388v-73h73v73h-73Zm207-259v-73h73v73h-73ZM240-234v-73h73v73h-73Zm0 154v-73h73v73h-73ZM80-80v-481q0-24.75 17.625-42.375T140-621h147v-199q0-24.75 17.625-42.375T347-880h273q24.75 0 42.375 17.625T680-820v200h-60v-200H347v259H140v481H80Zm373-107h387v-132H453v132Z"
		/>
	</svg>
);
