import { ReactNode } from 'react';

export const symbol_signal_cellular_alt_1_bar: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M249.882-160Q229-160 214.5-174.583 200-189.167 200-210v-140q0-20.833 14.618-35.417Q229.235-400 250.118-400 271-400 285.5-385.417 300-370.833 300-350v140q0 20.833-14.618 35.417Q270.765-160 249.882-160Z"
		/>
	</svg>
);
