import { ReactNode } from 'react';

export const symbol_bottom_panel_close: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m501-548 89-89q7-7 3.458-16.5Q589.917-663 580-663H380q-9.917 0-13.458 9.5Q363-644 370-637l89 89q9 9 21 9t21-9Zm279-292q24.75 0 42.375 17.625T840-780v600q0 24.75-17.625 42.375T780-120H180q-24.75 0-42.375-17.625T120-180v-600q0-24.75 17.625-42.375T180-840h600ZM180-327v147h600v-147H180Zm600-60v-393H180v393h600Zm-600 60v147-147Z"
		/>
	</svg>
);
