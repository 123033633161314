import { ReactNode } from 'react';

export const symbol_scale: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M290-80H112q-13 0-22-9.5T82-112q10-150 90.5-263T410-511v-139q-42-5-93-18t-97.5-36.5Q173-728 137.5-762T90-843q-4-14 5-25.5t24-11.5h722q15 0 24 11.5t5 25.5q-12 47-47.5 81t-82 57.5Q694-681 643-668t-93 18v139q157 23 237.5 136T878-112q1 13-8 22.5T848-80H670q-13 0-21.5-8.5T640-110q0-13 8.5-21.5T670-140h147q-18-152-111-234.5T480-457q-133 0-226 82.5T143-140h147q13 0 21.5 8.5T320-110q0 13-8.5 21.5T290-80Zm190-625q106 0 196-36.5T795-820H165q29 42 119 78.5T480-705Zm0 625q-33 0-56.5-23.5T400-160q0-17 6.5-31t17.5-25q12-12 33-24.5t50-25.5l100-41q9-4 16 3.5t3 16.5l-41 100q-13 29-25 50t-24 33q-11 11-25 17.5T480-80Zm0-625Z"
		/>
	</svg>
);
