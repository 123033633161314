import { ReactNode } from 'react';

export const symbol_podiatry: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m671-361 207-207 21 21q9 9 9 21t-9 21L734-340q-9 9-21 9t-21-9l-21-21ZM30-285q0-22 12.5-40T76-351q26-10 50-23.5t46-31.5l47 47q3 5 10 5t12-5q4-4 3.5-10.5T241-381l-43-44q10-8 19-17.5t18-20.5l43 43q3 5 10 5t12-5q4-4 3.5-10.5T300-442l-45-45 16-22q8-11 13-25l52 54q3 6 10 5.5t12-5.5q4-4 4-10.5t-3-11.5l-62-61 58-103q2-4 4.5-7t5.5-6l159-159q17-17 42-17t42 17l242 242-408 408q-8 8-19.5 13t-23.5 5H90q-25 0-42.5-17.5T30-230v-55Zm369 55 367-366-367 366Zm-307 0h307l367-366-200-200-159 160-34 63 24 25q22 24 24 55t-20 53L279-318q-22 22-52.5 22T175-318l-10-10q-18 11-36 19t-37 16v63Z"
		/>
	</svg>
);
