// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KWHEecEzeI_WegDEYuH6 {
	width: 100%;
}
`, "",{"version":3,"sources":["webpack://./routes/event/user-actions/multiple-user-actions/styles.css"],"names":[],"mappings":"AAAA;CACC,WAAW;AACZ","sourcesContent":[".fullWidth {\n\twidth: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export var fullWidth = `KWHEecEzeI_WegDEYuH6`;
export default ___CSS_LOADER_EXPORT___;
