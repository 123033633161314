import * as endpoints from 'pkg/api/endpoints/auto';
import * as sdk from 'pkg/core/sdk';

export const getMatchEventTypes = () =>
	sdk.get(endpoints.MatchEvent.AvailableTypes());

export const createMatchEvents = (matchId, data) =>
	sdk.post(endpoints.MatchEvent.Create(matchId), {}, data);

export const deleteMatchEvent = (matchEventId) =>
	sdk.destroy(endpoints.MatchEvent.Destroy(matchEventId));

export const getMatchEvents = (matchId) =>
	sdk.get(endpoints.MatchEvent.Index(matchId));

export const createMatchEvent = (matchId, payload) =>
	sdk.post(endpoints.MatchEvent.Create(matchId), {}, payload);

export const updateMatchEvent = (matchEventId, payload) =>
	sdk.patch(endpoints.MatchEvent.Update(matchEventId), {}, payload);
