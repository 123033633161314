import { ReactNode } from 'react';

export const symbol_chair_alt: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M200-150v-278q0-26 17-43t43-17h60v-90h-60q-26 0-43-17t-17-43v-142q0-26 17-43t43-17h440q26 0 43 17t17 43v142q0 26-17 43t-43 17h-60v90h60q26 0 43 17t17 43v278q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T700-150v-92H260v92q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T200-150Zm60-488h440v-142H260v142Zm120 150h200v-90H380v90ZM260-302h440v-126H260v126Zm0-336v-142 142Zm0 336v-126 126Z"
		/>
	</svg>
);
