import { ReactNode } from 'react';

export const symbol_swipe_down_alt: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M479.867-92Q474-92 469-94q-5-2-10-7L340-220q-9-9-9-21.158 0-12.158 8.609-21Q349-271 361.1-271t20.9 9l68 68v-207q-69-14-119.5-65.5T280-600q0-83 58.5-141.5T480-800q83 0 141.5 58.5T680-600q0 82-50.5 133.5T510-401v207l68-68q9-9 21.1-9 12.1 0 20.9 9 9 9 9 21t-9 21L501-101q-5 5-10.133 7-5.134 2-11 2Zm-.102-368Q538-460 579-500.765q41-40.764 41-99Q620-658 579.235-699q-40.764-41-99-41Q422-740 381-699.235q-41 40.764-41 99Q340-542 380.765-501q40.764 41 99 41ZM480-600Z"
		/>
	</svg>
);
