const icon = {
	name: 'drawing-arrow-dashed',
	objects: [
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M13 5.35001H18.65V10.8H17.35V7.56925L16.4307 6.65001H13V5.35001Z',
				fill: 'currentColor',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M5.04999 18L6.4642 16.5858L7.38344 17.505L5.96923 18.9192L5.04999 18Z',
				fill: 'currentColor',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M7.17131 15.8787L8.58552 14.4645L9.50476 15.3837L8.09055 16.7979L7.17131 15.8787Z',
				fill: 'currentColor',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M9.29263 13.7574L10.7068 12.3432L11.6261 13.2624L10.2119 14.6766L9.29263 13.7574Z',
				fill: 'currentColor',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M11.4139 11.636L12.8282 10.2218L13.7474 11.1411L12.3332 12.5553L11.4139 11.636Z',
				fill: 'currentColor',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M13.5353 9.51473L14.9495 8.10051L15.8687 9.01975L14.4545 10.434L13.5353 9.51473Z',
				fill: 'currentColor',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M15.6566 7.39341L17.0708 5.97919L17.99 6.89843L16.5758 8.31264L15.6566 7.39341Z',
				fill: 'currentColor',
			},
		},
	],
};

export default icon;
