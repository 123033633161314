import { useEffect } from 'react';

import './norrsken.css';

interface NorrskenVariant {
	[tokenName: string]: {
		match: RegExp;
		replace?: RegExp;
	};
}

const __defaultVariant: NorrskenVariant = {
	callable: {
		match: /([a-z0-9\_]+)\(.*?\)/gi,
		replace: /([a-z0-9\_]+)/gi,
	},
	bracket: {
		match: /(\{|\}|\[|\]|\(|\))/gi,
	},
	'comment-multiline': {
		match: /\/\*[\w\W]*?\*\//g,
	},
	comment: {
		match: /(?:^|[^\\])\/\/.*$/gm,
	},
	global: {
		match: /\b(declare|default|import|const|let|var|new|global|export)\b/gi,
	},
	keyword: {
		match:
			/\b(from|and|as|case|catch|class|clone|do|else|elseif|extends|final|for|foreach|goto|if|implements|interface|import|instanceof|new|or|static|switch|while|xor|function)\b/gi,
	},
	break: {
		match: /\b(break|return|continue)\b/gi,
	},
	value: {
		match: /\b(true|false|null|unknown|undefined)\b/gi,
	},
	string: {
		match: /("|'|\`)(\\?.)*?\1/gm,
	},
	property: {
		match: /([a-z0-9\_]+)\: /gi,
		replace: /([a-z0-9\_]+)/gi,
	},
	'return-type': {
		match: /\: ([a-z0-9\_\.]+)/gi,
		replace: /([a-z0-9\_\.]+)/gi,
	},
};

function toToken(token: string, raw: string): string {
	if (raw.includes('<') || raw.includes('>')) {
		return raw;
	}

	return `<span data-norrsken-token data-norrsken-${token}>${raw}</span>`;
}

function tokenize(raw: string, variant?: NorrskenVariant): string {
	if (!variant) {
		variant = __defaultVariant;
	}

	let tokenized = raw;

	for (const [token, options] of Object.entries(variant)) {
		const { match: regex, replace } = options;

		if (replace && tokenized.match(regex)) {
			tokenized = tokenized.replace(regex, (match) =>
				match.replace(replace, (m) => toToken(token, m))
			);
		} else {
			tokenized = tokenized.replace(regex, (match) => toToken(token, match));
		}
	}

	return tokenized;
}

interface NorrskenOptions {
	selector?: string;
	variant?: NorrskenVariant;
	watch?: any[];
}

export function useNorrsken({
	selector = 'pre[data-code-block]',
	variant,
	watch = [],
}: NorrskenOptions = {}) {
	useEffect(() => {
		const nodes = Array.from(document.querySelectorAll(selector));

		nodes.forEach((node) => {
			if (!node.hasAttribute('data-norrsken')) {
				node.innerHTML = tokenize(
					(node as HTMLPreElement).innerText
						.replace(/&/g, '&amp;')
						.replace(/</g, '&lt;')
						.replace(/>/g, '&gt;')
						.replace(/\u00a0/g, ' '),
					variant
				);

				node.setAttribute('data-norrsken', 'true');
			}
		});
	}, watch);
}
