import { ReactNode } from 'react';

export const symbol_work_history: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-180v-480 480-21.75V-180Zm0 60q-24 0-42-18t-18-42v-480q0-24 18-42t42-18h180v-100q0-23 18-41.5t42-18.5h200q24 0 42 18.5t18 41.5v100h180q24 0 42 18t18 42v177q0 12.641-8.675 20.82-8.676 8.18-21.5 8.18-12.825 0-21.325-8.625T820-484v-176H140v480h328q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T468-120H140Zm240-600h200v-100H380v100ZM720-47q-79 0-136-57t-57-136q0-79 57-136t136-57q79 0 136 57t57 136q0 79-57 136T720-47Zm17-195v-103.04q0-8.96-6-14.96t-15-6q-9 0-15 6t-6 14.97V-243q0 6.069 2.5 11.759Q700-225.552 705-221l76 70q5 5 13 4.5t13-5.5q6-6 5.5-14t-6.5-13l-69-63Z"
		/>
	</svg>
);
