import { t } from '@transifex/native';
import { useState } from 'react';

import * as endpoints from 'pkg/api/endpoints/auto';
import { useCollection } from 'pkg/api/use_collection';
import * as models from 'pkg/api/models';
import { FilterOperator } from 'pkg/filters';
import { useCurrentUser } from 'pkg/identity';

import InvoiceItem from 'routes/user/billing/invoice_list/InvoiceItem';

import CardAnatomy, * as Card from 'components/Card';
import Icon from 'components/icon';
import MissingEntities from 'components/missing-entities';

import Column from 'components/layout/column';
import { Spinner } from 'components/loaders/spinner';

import Button from 'design/button';

export default function InvoiceList() {
	const currentUser = useCurrentUser();
	const [sortBy] = useState('due_date');
	const [sortOrder] = useState<'asc' | 'desc'>('asc');

	const params = {
		group_id: currentUser.organizationId.toString(),
		sort_by: sortBy,
		sort_by_order: sortOrder,
		filters: JSON.stringify([
			{
				property: 'status',
				operator: FilterOperator.Includes,
				values: ['open', 'past_due', 'uncollectible'],
			},
		]),
	};

	const {
		records: invoices,
		isLoading,
		pagination,
	} = useCollection<models.order.Order>(
		endpoints.Orders.ListUserAndWardsOrders(),
		{
			queryParams: new URLSearchParams(params),
			showAllResults: true,
		}
	);

	if (isLoading) {
		return <Spinner />;
	}

	const content = !invoices.length ? (
		<CardAnatomy $noBorder>
			<Card.Body>
				<MissingEntities centered>
					<Icon name="order" />
					<p>{t('No invoices found')}</p>
				</MissingEntities>
			</Card.Body>
		</CardAnatomy>
	) : (
		<Column>
			{invoices.map((order) => (
				<InvoiceItem
					key={order.id}
					order={order}
					userId={currentUser.id}
					currency={order.currency}
				/>
			))}
			{pagination.hasNext && (
				<Button label={t('Load more')} onClick={pagination.fetchNext} />
			)}
		</Column>
	);

	return content;
}
