import { ReactNode } from 'react';

export const symbol_car_tag: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M245.765-314Q269-314 284.5-329.75T300-368q0-23.333-15.75-39.667Q268.5-424 246-424q-23.333 0-39.667 16.265Q190-391.471 190-368.235 190-345 206.265-329.5q16.264 15.5 39.5 15.5ZM637-314q23 0 38.5-15.5T691-368q0-6-1.5-12t-3.5-11q-16-2-30-9.5T631-419l-5-4q-20 4-32.5 19.5T581-368q0 22.5 16.333 38.25Q613.667-314 637-314Zm-497 50v-210 82-3 131ZM80-464q0-4.667.5-9.333Q81-478 83-483l82-247q5-14 16.5-22t26.5-8h152q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T360-700H218l-55 166h352q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T515-474H140v210h600v-110q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T800-374v214q0 16.667-11.735 28.333Q776.529-120 759.765-120 743-120 731.5-131.667 720-143.333 720-160v-44H160v44q0 16.667-11.735 28.333Q136.529-120 119.765-120 103-120 91.5-131.667 80-143.333 80-160v-304Zm520.175-266q12.825 0 21.325-8.675 8.5-8.676 8.5-21.5 0-12.825-8.675-21.325-8.676-8.5-21.5-8.5-12.825 0-21.325 8.675-8.5 8.676-8.5 21.5 0 12.825 8.675 21.325 8.676 8.5 21.5 8.5ZM673-461 463-671q-5.778-5.953-9.389-14.512Q450-694.07 450-703v-162q0-18 13.5-31.5T495-910h162q8.93 0 17.488 3.611Q683.047-902.778 689-897l210 210q13 12.75 13 31.875T899-623L737-461q-12.75 13-31.875 13T673-461Zm32-53 141-141-195-195H510v141l195 195Zm-27-168Z"
		/>
	</svg>
);
