import { ReactNode } from 'react';

export const symbol_call_made: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M700-658 223-181q-9 9-21 9t-21-9q-9-9-9-21t9-21l477-477H394q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T394-760h336q12.75 0 21.375 8.625T760-730v336q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T700-394v-264Z"
		/>
	</svg>
);
