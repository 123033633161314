import { useCurrentAccount, useCurrentMembership } from 'pkg/identity';
import { useWebSocketSubscription } from 'pkg/websocket';

export default function SubscriptionsObserver(): JSX.Element {
	const account = useCurrentAccount();
	const membership = useCurrentMembership();

	useWebSocketSubscription(`p.account.${account.id}`);
	useWebSocketSubscription(`p.group.${membership.groupId}`);
	useWebSocketSubscription(`p.group.update.${membership.groupId}`);

	return null;
}
