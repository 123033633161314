import { ReactNode } from 'react';

export const symbol_perm_contact_calendar: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M479.5-240Q414-240 354-218.5T240-151v11h480v-5q-50-45-112.5-70t-128-25ZM180-180q58-57 135.5-88.5T480-300q87 0 164 31.5T780-180v-580H180v580Zm300-214q-57 0-96.5-39.5T344-530q0-57 39.5-96.5T480-666q57 0 96.5 39.5T616-530q0 57-39.5 96.5T480-394Zm.235-60Q512-454 534-476.235q22-22.236 22-54Q556-562 533.765-584q-22.236-22-54-22Q448-606 426-583.765q-22 22.236-22 54Q404-498 426.235-476q22.236 22 54 22ZM180-80q-24 0-42-18t-18-42v-620q0-24 18-42t42-18h65v-28q0-13.6 9.2-22.8 9.2-9.2 22.8-9.2 14.025 0 23.513 9.2Q310-861.6 310-848v28h340v-28q0-13.6 9.2-22.8 9.2-9.2 22.8-9.2 14.025 0 23.513 9.2Q715-861.6 715-848v28h65q24 0 42 18t18 42v620q0 24-18 42t-42 18H180Zm300-450Zm0 390h240-480 240Z"
		/>
	</svg>
);
