import { t } from '@transifex/native';
import { batch } from 'react-redux';
import { Dispatch } from 'redux';

import TrainingCollection from 'pkg/models/training_collection';

import * as trainingCollectionsService from 'pkg/actions/services/training_collections';
import * as actionTypes from 'pkg/actions/action-types';
import { normalizedDispatch } from 'pkg/actions/utils';
import { triggerError } from 'pkg/actions/app';
import { show } from 'pkg/actions/flashes';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as sdk from 'pkg/core/sdk';
import * as selectors from 'pkg/selectors';
import { RootState } from 'pkg/reducers';

const setInheritedItems = (collections: any[], groupId: number) => ({
	type: actionTypes.TrainingCollections.SET_INHERITED_ITEMS,
	collections,
	groupId,
});

const addExerciseToCollection = (id: number, exerciseId: number) => ({
	type: actionTypes.TrainingCollections.ADD_EXERCISE,
	id,
	exerciseId,
});

const addSessionToCollection = (id: number, sessionId: number) => ({
	type: actionTypes.TrainingCollections.ADD_SESSION,
	id,
	sessionId,
});

export const fetchCollections =
	(
		organizationId: number,
		queryParams: trainingCollectionsService.CollectionQueryParams,
		groupId: number = null
	) =>
	async (dispatch: Dispatch): Promise<void> => {
		const request = await trainingCollectionsService.fetchCollections(
			organizationId,
			queryParams
		);

		if (!request.ok) {
			triggerError(request)(dispatch);
			return;
		}

		const response = await request.json();

		normalizedDispatch(response.records, [TrainingCollection.normalizr()])(
			dispatch
		);

		if (groupId) {
			dispatch(setInheritedItems(response.records, groupId));
		}
	};

export const createCollection =
	(
		organizationId: number,
		payload: trainingCollectionsService.CreateCollectionPayload
	) =>
	async (dispatch: Dispatch): Promise<TrainingCollection> => {
		const request = await trainingCollectionsService.createCollection(
			organizationId,
			payload
		);

		if (!request.ok) {
			triggerError(request)(dispatch);
			return;
		}

		const response = await request.json();
		normalizedDispatch(response, TrainingCollection.normalizr())(dispatch);

		return response;
	};

export const addExercise =
	(organizationId: number, id: number, exerciseId: number) =>
	async (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
		const request = await trainingCollectionsService.addExercise(
			organizationId,
			id,
			exerciseId
		);
		const exercise = selectors.exercises.find(getState(), exerciseId);
		const collection = selectors.trainingCollections.find(getState(), {
			collectionId: id,
		});

		if (!request.ok) {
			show(
				{
					title: t(`Failed to save item.`),
					message: t(
						`{item} couldn't be added to {collection}, maybe it's already there?`,
						{
							item: exercise.title,
							collection: collection.name,
						}
					),
				},
				request.status
			);
			return;
		}

		batch(() => {
			show(
				{
					title: t(`Saved!`),
					message: t(`{item} was added to {collection}`, {
						item: exercise.title,
						collection: collection.name,
					}),
				},
				request.status
			);
			dispatch(addExerciseToCollection(id, exerciseId));
		});
	};

export const addSession =
	(organizationId: number, id: number, sessionId: number) =>
	async (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
		const request = await trainingCollectionsService.addSession(
			organizationId,
			id,
			sessionId
		);
		const session = selectors.sessions.find(getState(), { sessionId });
		const collection = selectors.trainingCollections.find(getState(), {
			collectionId: id,
		});

		if (!request.ok) {
			show(
				{
					title: t(`Failed to save item.`),
					message: t(
						`{item} couldn't be added to {collection}, maybe it's already there?`,
						{
							item: session.title,
							collection: collection.name,
						}
					),
				},
				request.status
			);
			return;
		}

		batch(() => {
			show(
				{
					title: t(`Saved!`),
					message: t(`{item} was added to {collection}`, {
						item: session.title,
						collection: collection.name,
					}),
				},
				request.status
			);
			dispatch(addSessionToCollection(id, sessionId));
		});
	};

interface ShareSessionPayload {
	targetOrganizationIds: number[];
}

export const shareSession = async (
	groupId: number,
	trainingCollectionId: number,
	payload: ShareSessionPayload
) => {
	const request = await sdk.put(
		endpoints.TrainingCollections.UpdateShare(groupId, trainingCollectionId),
		{},
		payload
	);

	if (!request.ok) {
		show({
			title: t('Something went wrong!'),
			message: request.statusText,
		});

		return false;
	}

	show({
		title: t('Success!'),
		message: t(
			'Your training collections shared organizations has been updated'
		),
	});

	return true;
};
