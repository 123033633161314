import { ReactNode } from 'react';

export const symbol_hotel: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M69.825-200Q57-200 48.5-208.625T40-230v-525q0-12.75 8.675-21.375Q57.351-785 70.175-785 83-785 91.5-776.375T100-755v364h353v-249q0-24.75 17.625-42.375T513-700h262q59.812 0 102.406 42.594Q920-614.812 920-555v325q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T860-230v-101H100v101q0 12.75-8.675 21.375Q82.649-200 69.825-200ZM270-449q-45 0-75.5-30.5T164-555q0-45 30.5-75.5T270-661q45 0 75.5 30.5T376-555q0 45-30.5 75.5T270-449Zm243 58h347v-164q0-35.062-24.969-60.031T775-640H513v249ZM270-509q19 0 32.5-13.5T316-555q0-19-13.5-32.5T270-601q-19 0-32.5 13.5T224-555q0 19 13.5 32.5T270-509Zm0-46Zm243-85v249-249Z"
		/>
	</svg>
);
