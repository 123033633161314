const checkFilled = {
	name: 'check-filled',
	objects: [
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M12 20.245C16.5536 20.245 20.245 16.5536 20.245 12C20.245 7.44642 16.5536 3.755 12 3.755C7.44642 3.755 3.755 7.44642 3.755 12C3.755 16.5536 7.44642 20.245 12 20.245ZM10.5429 13.4664L8.08859 11.1928L6.89825 12.2917L10.5429 15.6564L17.1017 9.44246L15.9114 8.34358L10.5429 13.4664Z',
			},
		},
	],
};

export default checkFilled;
