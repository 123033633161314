import { ReactNode } from 'react';

export const symbol_missing_controller: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m200-806-43-43q81-61 159.5-86T480-960q85 0 163.5 25T803-849l-43 43q-63-48-131-71t-149-23q-81 0-149 23t-131 71Zm126 133-43-43q39-30 88.5-47T480-780q59 0 108.5 17t88.5 47l-43 43q-31-21-70.5-34T480-720q-44 0-83.5 13T326-673ZM480 0q-60 0-102-42t-42-102v-312q0-60 42-102t102-42q60 0 102 42t42 102v312q0 60-42 102T480 0Zm0-364q39 0 65.5-26.5T572-456q0-39-26.5-65.5T480-548q-39 0-65.5 26.5T388-456q0 39 26.5 65.5T480-364Z"
		/>
	</svg>
);
