import { ReactNode } from 'react';

export const symbol_send_time_extension: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M536-130v-76q0-10 6-18t16-11l136-38-136-38q-10-3-16-11t-6-18v-76q0-17 14-26t29-1l287 143q17 8 17 27t-17 27L579-103q-15 8-29-1t-14-26Zm-356 10q-24 0-42-18t-18-42v-176q44-5 75.5-34.5T227-463q0-43-31.5-72.5T120-570v-176q0-24 18-42t42-18h177q11-40 39.5-67t68.5-27q40 0 68.5 27t39.5 67h173q24 0 42 18t18 42v349l-60-30v-319H511v-48q0-20-13-33t-33-13q-20 0-33 13t-13 33v48H180v130q48 18 77.5 60t29.5 93q0 51-29.5 93T180-310v130h126q25-52 68-81.5T476-286v60q-49-5-82 26.5T356-120H180Zm283-390Z"
		/>
	</svg>
);
