import { ReactNode } from 'react';

export const symbol_download_for_offline: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M310-280h340q13 0 21.5-8.5T680-310q0-13-8.5-21.5T650-340H310q-13 0-21.5 8.5T280-310q0 13 8.5 21.5T310-280Zm138-241-64-63q-9-8-21-8.5t-21 8.5q-9 9-9 21t9 21l114 115q9 9 21 9t21-9l116-115q9-9 9-21t-9-21q-9-9-21-8.5t-21 8.5l-64 63v-169q0-13-8.5-21.5T478-720q-13 0-21.5 8.5T448-690v169Zm32 441q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm0-60q142 0 241-99.5T820-480q0-142-99-241t-241-99q-141 0-240.5 99T140-480q0 141 99.5 240.5T480-140Zm0-340Z"
		/>
	</svg>
);
