import { Record } from 'pkg/api/models/record';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import * as sdk from 'pkg/core/sdk';

export interface ResourceLocation extends Record {
	groupId: number;
	title: string;
}

export interface ResourceLocationCreatePayload {
	title: string;
	groupId: number;
}

export async function create(
	payload: ResourceLocationCreatePayload
): Promise<[Response, ResourceLocation, models.APIError?]> {
	if (!payload.title) {
		return Promise.resolve([new Response(null, { status: 400 }), null]);
	}

	return models.create(endpoints.ResourceLocation.Create(), payload);
}

export interface ResourceLocationUpdatePayload {
	title?: string;
}

export async function update(
	location: ResourceLocation,
	payload: ResourceLocationUpdatePayload
): Promise<[Response, ResourceLocation]> {
	if (Object.keys(payload).length === 0) {
		return Promise.resolve([new Response(null, { status: 400 }), null]);
	}

	const request = await sdk.patch(
		endpoints.ResourceLocation.Update(location.id),
		{},
		payload
	);

	const response: ResourceLocation = await request.json();

	return [request, response];
}

export async function remove(location: ResourceLocation): Promise<boolean> {
	const request = await sdk.destroy(
		endpoints.ResourceLocation.Delete(location.id)
	);

	if (request.ok) {
		return true;
	}

	return false;
}
