import { t } from '@transifex/native';
import { Component } from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';

import * as breakpoints from 'pkg/config/breakpoints';
import * as palette from 'pkg/config/palette';

import { getPlayTimeItems } from 'pkg/selectors/match_events';

import rgba from 'pkg/rgba';

import CardBase, * as Card from 'components/Card';
import SectionTitle from 'components/SectionTitle';

import Item from 'components/group/statistics/play-time/Item';
import NoDataTableRow from 'components/group/statistics/NoDataTableRow';
import {
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableHeadCell as TblHeadCell,
} from 'components/group/statistics/Table';
import Column from 'components/layout/column';

import Button from 'design/button';

const Body = styled(Card.Body)`
	display: flex;
	justify-content: space-between;
	padding: 0;
	overflow: auto;

	@media ${breakpoints.small} {
		padding: 0;
	}
`;

const ScoringLeagueCardBase = styled(CardBase)`
	border-radius: var(--radius-5);
	overflow: hidden;
`;

const ViewMore = styled.div`
	display: flex;
	justify-content: center;
	border-top: 1px solid ${rgba(palette.card.innerDividerColor)};
	padding: 1rem;
`;

const TableHeadCell = styled(TblHeadCell)`
	cursor: pointer;

	&:last-child {
		padding-right: 26px;
	}

	${(props) =>
		props.sortBy &&
		css`
			color: ${palette.black};
			font-weight: var(--font-weight-semibold);
		`}

	${(props) =>
		props.sortBy &&
		props.reverseItems &&
		css`
			&::after {
				top: 21px;
				right: -3px;
				border: solid transparent;
				content: ' ';
				height: 0;
				width: 0;
				position: absolute;
				pointer-events: none;
				border-color: rgba(0, 0, 0, 0);
				border-top-color: ${palette.black};
				border-width: 4px;
				margin-left: -4px;
			}

			&:last-child::after {
				right: 15px;
			}
		`}

	${(props) =>
		props.sortBy &&
		!props.reverseItems &&
		css`
			&::after {
				top: 17px;
				right: -3px;
				border: solid transparent;
				content: ' ';
				height: 0;
				width: 0;
				position: absolute;
				pointer-events: none;
				border-color: rgba(0, 0, 0, 0);
				border-bottom-color: ${palette.black};
				border-width: 4px;
				margin-left: -4px;
			}

			&:last-child::after {
				right: 15px;
			}
		`}
`;

class PlayTime extends Component {
	state = {
		numberOfItems: 10,
		sortBy: 'minutes',
		reverseItems: true,
		showLegendModal: false,
	};

	handleSortByFirstNameClick = () => {
		if (this.state.sortBy === 'firstName') {
			this.toggleReverseItems();
		} else {
			this.setState({ sortBy: 'firstName', reverseItems: false });
		}
	};

	handleSortByMatchesClick = () => {
		if (this.state.sortBy === 'matches') {
			this.toggleReverseItems();
		} else {
			this.setState({ sortBy: 'matches', reverseItems: true });
		}
	};

	handleSortByStartClick = () => {
		if (this.state.sortBy === 'start') {
			this.toggleReverseItems();
		} else {
			this.setState({ sortBy: 'start', reverseItems: true });
		}
	};

	handleSortByMinutesClick = () => {
		if (this.state.sortBy === 'minutes') {
			this.toggleReverseItems();
		} else {
			this.setState({ sortBy: 'minutes', reverseItems: true });
		}
	};

	handleSortByAverageClick = () => {
		if (this.state.sortBy === 'average') {
			this.toggleReverseItems();
		} else {
			this.setState({ sortBy: 'average', reverseItems: true });
		}
	};

	handleViewMoreClick = () => {
		const numberOfItems = this.state.numberOfItems + 10;
		this.setState({ numberOfItems });
	};

	toggleReverseItems = () => {
		this.setState({ reverseItems: !this.state.reverseItems });
	};

	get viewMore() {
		if (
			this.props.playTimeItems.isEmpty() ||
			this.props.playTimeItems.size < this.state.numberOfItems
		) {
			return;
		}

		return (
			<ViewMore>
				<Button onClick={this.handleViewMoreClick}>{t(`View more`)}</Button>
			</ViewMore>
		);
	}

	render() {
		let PlayTimeItems = this.props.playTimeItems.sortBy(
			(item) => item[this.state.sortBy]
		);

		if (this.state.reverseItems) {
			PlayTimeItems = PlayTimeItems.reverse();
		}

		PlayTimeItems = PlayTimeItems.take(this.state.numberOfItems)
			.valueSeq()
			.map((item, index) => {
				return (
					<TableRow key={item.user.id}>
						<Item groupId={this.props.groupId} item={item} index={index + 1} />
					</TableRow>
				);
			});

		return (
			<Column>
				<SectionTitle icon="timelapse">{t(`Play time`)}</SectionTitle>
				<ScoringLeagueCardBase $noBorder>
					<Body>
						<Table>
							<TableHead>
								<TableRow>
									<TableHeadCell
										alignLeft
										isSticky
										onClick={this.handleSortByFirstNameClick}
										sortBy={this.state.sortBy === 'firstName'}
										reverseItems={this.state.reverseItems}>
										{t(`Player`)}
									</TableHeadCell>
									<TableHeadCell
										onClick={this.handleSortByMatchesClick}
										sortBy={this.state.sortBy === 'matches'}
										reverseItems={this.state.reverseItems}>
										{t(`Matches`)}
									</TableHeadCell>
									<TableHeadCell
										onClick={this.handleSortByStartClick}
										sortBy={this.state.sortBy === 'start'}
										reverseItems={this.state.reverseItems}>
										{t(`Start`)}
									</TableHeadCell>
									<TableHeadCell
										onClick={this.handleSortByMinutesClick}
										sortBy={this.state.sortBy === 'minutes'}
										reverseItems={this.state.reverseItems}>
										{t(`Minutes`)}
									</TableHeadCell>
									<TableHeadCell
										onClick={this.handleSortByAverageClick}
										sortBy={this.state.sortBy === 'average'}
										reverseItems={this.state.reverseItems}>
										{t(`Average`)}
									</TableHeadCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{PlayTimeItems.isEmpty() ? (
									<NoDataTableRow span="5" text={t(`No players`)} />
								) : (
									PlayTimeItems
								)}
							</TableBody>
						</Table>
					</Body>
					{this.viewMore}
				</ScoringLeagueCardBase>
			</Column>
		);
	}
}

const mapStateToProps = (state, props) => {
	const statProps = { ...props.filters.toJS(), groupId: props.groupId };

	return {
		playTimeItems: getPlayTimeItems(props.groupId)(state, statProps),
	};
};

export default connect(mapStateToProps)(PlayTime);
