import { ReactNode } from 'react';

export const symbol_dinner_dining: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M159-120q-8.958 0-17.542-3.696-8.585-3.695-14.557-9.608L106-154q-7-7-3.458-16.5Q106.083-180 116-180h728q9.917 0 13.458 9.5Q861-161 854-154l-20.901 20.696q-5.972 5.913-14.557 9.608Q809.958-120 801-120H159Zm-39-121q5-17 16-30.5t24-25.5v-325h-15q-10.833 0-17.917-7.116-7.083-7.117-7.083-18Q120-658 127.083-665q7.084-7 17.917-7h15v-34h-15q-10.833 0-17.917-7.116-7.083-7.117-7.083-18Q120-742 127.083-749q7.084-7 17.917-7h15v-34h-15q-10.833 0-17.917-7.116-7.083-7.117-7.083-18Q120-826 127.083-833q7.084-7 17.917-7h265q24.75 0 42.375 17.625T470-780v24h345q10.833 0 17.917 7.116 7.083 7.117 7.083 18Q840-720 832.917-713q-7.084 7-17.917 7H470v24q0 24.75-17.625 42.375T410-622h-95v271q21 2 39.5 10t35.5 22q17-69 74-109.5T593-469q88 0 147.5 63.5T800-254v13H120Zm330-60h283q-10-49-49.5-78.5T593-409q-51 0-92 29t-51 79ZM315-756h105v-34H315v34Zm0 84h105v-34H315v34Zm-105-84h55v-34h-55v34Zm0 84h55v-34h-55v34Zm0 340q12-9 26-14t29-6v-270h-55v290Zm382 31Z"
		/>
	</svg>
);
