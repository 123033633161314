import { Fragment } from 'react';
import styled from 'styled-components';

import * as styles from 'pkg/config/styles';

import Link from 'pkg/router/Link';
import * as routes from 'pkg/router/routes';
import { useCurrentOrganization } from 'pkg/identity';

import Avatar from 'components/avatar';

import { TableBodyCell } from 'components/group/statistics/Table';

const ItemTableBodyCell = styled(TableBodyCell)`
	&:last-child {
		padding-right: 26px;
	}
`;

const UserWrapper = styled(Link)`
	display: flex;
	align-items: center;
	position: relative;
`;

const UserPosition = styled.div`
	width: 28px;
	color: var(--palette-gray-600);

	&::after {
		content: '.';
	}

	@media ${styles.breakpoint.small} {
		position: absolute;
		top: -4px;
		left: -8px;
		background: ${styles.palette.brand};
		background: #fff;
		color: #000;
		box-shadow: ${styles.boxShadow.flat};
		width: 14px;
		height: 14px;
		border-radius: 50%;
		z-index: 3;
		font-size: 0.5rem;
		display: flex;
		justify-content: center;
		align-items: center;

		&::after {
			content: '';
		}
	}
`;

const UserAvatar = styled(Avatar)`
	width: 22px;
	margin-right: 0.7rem;
	font-size: var(--font-size-sm);

	@media ${styles.breakpoint.small} {
		width: 20px;
		font-size: 0.7rem;
		margin-right: 0.5rem;
	}
`;

const FirstName = styled.div`
	margin-right: 3px;

	@media ${styles.breakpoint.small} {
		display: none;
	}
`;

const Initial = styled.div`
	margin-right: 3px;
	display: none;

	@media ${styles.breakpoint.small} {
		display: block;
	}
`;

const LastName = styled.div`
	flex: 1;

	@media ${styles.breakpoint.small} {
		overflow: hidden;
		text-overflow: ellipsis;
	}
`;

const Item = ({ index, groupId, item }) => {
	const org = useCurrentOrganization();
	if (!item.user) {
		return null;
	}

	const { user } = item;

	return (
		<Fragment>
			<ItemTableBodyCell alignLeft isSticky>
				<UserWrapper
					href={routes.User.Profile.Show(org.id, groupId, user.id, 'overview')}>
					<UserPosition>{index}</UserPosition>
					<UserAvatar user={user} />
					<Initial>{user.firstName[0]}.</Initial>
					<FirstName>{user.firstName}</FirstName>
					<LastName>{user.lastName}</LastName>
				</UserWrapper>
			</ItemTableBodyCell>
			<ItemTableBodyCell>{item.matches}</ItemTableBodyCell>
			<ItemTableBodyCell>{item.start}</ItemTableBodyCell>
			<ItemTableBodyCell>{item.minutes}</ItemTableBodyCell>
			<ItemTableBodyCell>{item.average}</ItemTableBodyCell>
		</Fragment>
	);
};

export default Item;
