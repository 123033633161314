import { t } from '@transifex/native';
import { Fragment } from 'react';

import { MAX_FILE_UPLOADS } from 'pkg/config/files';
import * as styles from 'pkg/config/styles';

import * as numbers from 'pkg/numbers';
import * as models from 'pkg/api/models';
import * as actions from 'pkg/actions';

import AttachmentRow from 'components/attachment/FormRow';
import Label from 'components/form/Label';
import Hint from 'components/form/Hint';
import Column from 'components/layout/column';
import useAttachments from 'components/attachment/hooks/useAttachments';

// todo(jbfm): lazy
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Attachments = ({ dispatch, data, post }: any) => {
	const handleUploadedFile = (attachment: models.attachment.Attachment) => {
		dispatch({ type: 'set_attachment', payload: attachment });
	};

	const handleDeleteFile = async (attachmentId: number) => {
		if (
			post?.id &&
			post?.attachments?.find(
				(a: models.attachment.Attachment) => a.id === attachmentId
			)
		) {
			await actions.posts.deleteAttachment(attachmentId, post.id);
		}
		dispatch({ type: 'remove_attachment', payload: attachmentId });
		return true;
	};

	const numAttachments =
		data.newAttachments?.size + (post.attachments || []).length;

	const numAvailableSlots = numbers.clamp(
		MAX_FILE_UPLOADS - numAttachments,
		0,
		MAX_FILE_UPLOADS
	);

	const { attachments, FileUploader, AttachmentItems } = useAttachments({
		attachments: [
			...(post.attachments || []),
			...data.newAttachments?.toList().toArray(),
		],
		maxAttachments: numAvailableSlots,
		onNewAttachment: handleUploadedFile,
		onRemoveAttachment: handleDeleteFile,
	});

	const showAttachments = attachments?.length > 0;

	const canUpload = numAttachments < MAX_FILE_UPLOADS;

	return (
		<Fragment>
			<Label>{t(`Attachments`)}</Label>
			<Column spacing={styles.spacing._2}>
				<Hint>
					{t(`Attachments are limited to {num} files.`, {
						num: MAX_FILE_UPLOADS,
					})}
				</Hint>
				{canUpload && FileUploader}
			</Column>

			{showAttachments && <AttachmentRow>{AttachmentItems}</AttachmentRow>}
		</Fragment>
	);
};

export default Attachments;
