import { ReactNode } from 'react';

export const symbol_water: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M205-297q-27 0-44 13t-35 21q-19 9-32.5 4T80-281q0-11 8-20t25-21q24-17 49-26t47-9q46 0 71 27t64 27q39 0 65-27t71-27q45 0 70.5 27t64.5 27q39 0 64.5-27t72.5-27q23 0 48 9t49 26q17 11 24 21t7 20q0 17-13.5 22t-32.5-4q-18-8-35-21t-44-13q-42 0-68.5 27T615-243q-45 0-71-27t-64-27q-38 0-64.5 27T344-243q-45 0-71-27t-68-27Zm0-160q-27 0-44 13t-35 21q-19 9-32.5 4T80-441q0-11 9.5-21.5T121-488q24-16 42.5-22.5T202-517q51 0 77 27t65 27q39 0 65-27t71-27q45 0 70.5 27t64.5 27q39 0 64.5-27t72.5-27q23 0 48 9t49 26q17 11 24 21t7 20q0 17-13.5 22t-32.5-4q-18-8-35-21t-44-13q-42 0-68.5 27T615-403q-45 0-71-27t-64-27q-38 0-64.5 27T344-403q-45 0-71-27t-68-27Zm0-160q-27 0-44 13t-35 21q-19 9-32.5 4T80-601q0-11 8-20.5t26-21.5q24-16 49-25t47-9q45 0 70 27t64 27q39 0 65-27t71-27q45 0 70.5 27t64.5 27q39 0 64.5-27t71.5-27q22 0 47 9t49 26q17 12 25 21t8 20q0 17-13.5 22t-32.5-4q-18-8-35-21t-44-13q-42 0-68.5 27T615-563q-45 0-71-27t-64-27q-38 0-64.5 27T344-563q-45 0-71-27t-68-27Z"
		/>
	</svg>
);
