import { useState } from 'react';

import { FilterOperator } from 'pkg/filters';
import { FilterModalProps } from 'pkg/filters/types/Modal';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import { useCollection } from 'pkg/api/use_collection';
import { useCurrentGroupId } from 'pkg/identity';

import SelectDiscountsModal from 'components/discount/select_discounts_modal';

const AppliedToModal = (filterProps: FilterModalProps) => {
	const [search, setSearch] = useState('');

	const groupId = useCurrentGroupId();

	const {
		records: discounts,
		pagination,
		isLoading,
	} = useCollection<models.discount.Discount>(
		endpoints.Discounts.AppliedToSubscriptions(),
		{
			queryParams: new URLSearchParams({
				group_id: groupId.toString(),
				filters: JSON.stringify([
					{
						property: 'search',
						operator: FilterOperator.Contains,
						values: [search],
					},
				]),
			}),
		}
	);

	const handleSearch = (value: string) => setSearch(value);

	return (
		<SelectDiscountsModal
			discounts={discounts}
			pagination={pagination}
			isLoading={isLoading}
			handleSearch={handleSearch}
			{...filterProps}
		/>
	);
};

export default AppliedToModal;
