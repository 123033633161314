import { Component } from 'react';
import styled from 'styled-components';

import Slider from 'components/form/Slider';

const Wrapper = styled.div``;

const Label = styled.div`
	font-weight: var(--font-weight-semibold);
	font-size: var(--font-size-2xl);
	margin: 1px 0;
	color: #333;
	border-top: ${(props) =>
		props.borderless
			? '1px solid rgba(0, 0, 0, 0)'
			: '1px dotted rgb(233, 233, 233)'};
	padding: 1rem 0 0.5rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-flow: row wrap;

	span {
		display: inline-flex;
		width: 100%;
	}
`;

const Average = styled.div`
	flex: 0 0 100%;
	position: relative;
	display: flex;
	align-items: flex-end;
	flex-flow: column;
	padding: 5px 0;

	input {
		width: calc(100% - 50px);
		background: transparent;
		border-bottom: none;
		padding-left: 0;
		padding-right: 0;
	}

	&::before {
		content: attr(data-value);
		position: absolute;
		top: 50%;
		right: 100%;
		transform: translate(37px, -50%);
		text-align: center;
		font-weight: var(--font-weight-semibold);
		font-size: 12px;
		display: block;
		background: #eee;
		border-radius: 3px 3px 3px 3px;
		padding: 3px 6px;
	}

	&::after {
		content: '';
		width: 0;
		height: 0;
		display: block;
		border: 4px solid transparent;
		border-left-color: #eee;
		position: absolute;
		top: 50%;
		left: 45px;
		transform: translate(-100%, -50%);
	}
`;

export default class ItemCategory extends Component {
	shouldComponentUpdate(nextProps) {
		for (let i = 0; i < nextProps.children.length; i++) {
			const newItem = nextProps.children[i];
			const currentItem = this.props.children.find(
				(child) => child.props.item.id === newItem.props.item.id
			);

			if (currentItem.props.value !== newItem.props.value) {
				return true;
			}
		}

		return false;
	}

	setAverage = (e) => {
		this.props.onInput(e.target.value, this.props.itemIds, this.props.parentId);
	};

	render() {
		return (
			<Wrapper>
				<Label borderless={this.props.borderless}>
					<span>{this.props.label}</span>

					<Average data-value={this.props.average.toFixed(1)}>
						<Slider
							tabIndex="-1"
							type="range"
							value={this.props.average}
							className="input"
							min="0"
							max="10"
							step="0.01"
							onChange={this.setAverage}
						/>
					</Average>
				</Label>

				{this.props.children}
			</Wrapper>
		);
	}
}

ItemCategory.defaultProps = {
	parentId: 0,
};
