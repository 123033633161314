import { ReactNode } from 'react';

export const symbol_linked_camera: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M833-703q0-71.465-49.268-120.732Q734.465-873 663-873q-10 0-16.5-6.5T640-896q0-10 6.5-16.5T663-919q89.66 0 152.83 63.17Q879-792.66 879-703q0 10-6.5 16.5T856-680q-10 0-16.5-6.5T833-703Zm-88 0q0-34.44-23.78-58.22T663-785q-9.731 0-16.365-6.5Q640-798 640-808t6.5-16.5Q653-831 663-831q54 0 91 37t37 91q0 10-6.5 16.5T768-680q-10 0-16.5-6.635Q745-693.269 745-703ZM480-437ZM140-120q-24 0-42-18t-18-42v-513q0-23 18-41.5t42-18.5h147l55-66q8-11 20-16t26-5h182q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T570-780H388l-73 87H140v513h680v-430q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T880-610v430q0 24-18.5 42T820-120H140Zm339.5-147q72.5 0 121.5-49t49-121.5q0-72.5-49-121T479.5-607q-72.5 0-121 48.5t-48.5 121q0 72.5 48.5 121.5t121 49Zm0-60q-47.5 0-78.5-31.5t-31-79q0-47.5 31-78.5t78.5-31q47.5 0 79 31t31.5 78.5q0 47.5-31.5 79t-79 31.5Z"
		/>
	</svg>
);
