type SportSlugs = 'football';

export enum SportType {
	Unknown = '',
	Football = 'football',
}

export interface Sport {
	type: SportType;
	name?: string;
	slug?: SportSlugs;
	defaultMatchDuration: number;
	defaultMatchPeriods: number;
	hasMatchModel: boolean;
	hasRatingModel: boolean;
}

export function hasMatchesEnabled(sport: Sport): boolean {
	return sport?.hasMatchModel;
}

export function hasRatingsEnabled(sport: Sport): boolean {
	return sport?.hasRatingModel;
}

export function isFootball(sport: Sport): boolean {
	return sport.type === SportType.Football;
}
