import { ReactNode } from 'react';

export const symbol_sync_disabled: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M220-477q0 55 22.5 105t64.5 85l30 21v-94q0-13 8.5-21.5T367-390q13 0 21.5 8.5T397-360v170q0 13-8.5 21.5T367-160H197q-13 0-21.5-8.5T167-190q0-13 8.5-21.5T197-220h100l-15-12q-57-45-90-109t-32-136q1-47 14-92t39-83L77-788q-8-8-7.5-20.5T78-829q8-8 20.5-8t20.5 8l711 712q8 8 8 20t-8 20q-8 8-20.5 8T789-77L653-212q-12 8-24.5 14.5T603-186q-12 5-23-.5T565-204q-4-12 2-23.5t18-16.5q11-5 22-11.5t22-13.5l-11 22-362-362q-18 30-27 63.5t-9 68.5Zm517 185-42-42q21-34 33-71.5t12-77.5q0-54-23.5-100.5T655-668l-29-26v94q0 13-8.5 21.5T596-570q-13 0-21.5-8.5T566-600v-170q0-13 8.5-21.5T596-800h170q13 0 21.5 8.5T796-770q0 13-8.5 21.5T766-740H665l15 14q52 48 86 110t34 133q0 52-16 100.5T737-292ZM334-695l-41-41q15-11 31.5-20.5T358-774q11-5 22.5.5T396-756q4 11-3 22t-18 16q-11 5-21 10.5T334-695Z"
		/>
	</svg>
);
