import { ReactNode } from 'react';

export const symbol_sports_rugby: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M299-100q-63 0-107-12t-58-26q-15-14-24.5-57T100-301q0-115 41-222t116-185q69-77 179-114.5T667-860q62 0 106.5 10.5T831-827q14 11 21.5 52t7.5 111q0 119-39.5 226T708-257q-75 78-184 117.5T299-100ZM157-278q32-88 85-168.5T367-599q57-60 136-108.5T687-804q-106-5-212.5 34T301-664q-66 69-106.5 173T157-278Zm122 121q119-5 221.5-43.5T665-301q66-69 103.5-173.5T804-688q-43 105-93.5 181.5T599-367q-56 60-133.5 108.5T279-157Zm-80-42q93-26 187-82.5T555-410q71-74 126.5-166.5T766-766q-97 29-190.5 85.5T410-555q-67 69-124.5 163.5T199-199Zm284-284Z"
		/>
	</svg>
);
