import { ReactNode } from 'react';

export const symbol_view_compact_alt: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M355-325h80q12.75 0 21.375-8.625T465-355v-80q0-12.75-8.625-21.375T435-465h-80q-12.75 0-21.375 8.625T325-435v80q0 12.75 8.625 21.375T355-325Zm0-170h80q12.75 0 21.375-8.625T465-525v-80q0-12.75-8.625-21.375T435-635h-80q-12.75 0-21.375 8.625T325-605v80q0 12.75 8.625 21.375T355-495Zm170 170h80q12.75 0 21.375-8.625T635-355v-80q0-12.75-8.625-21.375T605-465h-80q-12.75 0-21.375 8.625T495-435v80q0 12.75 8.625 21.375T525-325Zm0-170h80q12.75 0 21.375-8.625T635-525v-80q0-12.75-8.625-21.375T605-635h-80q-12.75 0-21.375 8.625T495-605v80q0 12.75 8.625 21.375T525-495ZM140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm0-60h680v-520H140v520Zm0 0v-520 520Z"
		/>
	</svg>
);
