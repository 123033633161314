import { ReactNode } from 'react';

export const symbol_touchpad_mouse_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M660-360Zm0 0ZM812-63 47-827q-9-9.067-9-21.533Q38-861 47.053-870q9.052-9 21.5-9Q81-879 90-870l765 765q9 9 9 21t-9.053 21q-9.052 9-21.5 9Q821-54 812-63ZM660-360Zm0 0Zm0 280q-92 0-156-64t-64-156v-120q0-23 4.193-43.5 4.192-20.5 11.646-40.5L590-370h-90v70q0 66.286 46.823 113.143Q593.645-140 659.882-140q33.118 0 62.261-12.31Q751.286-164.619 773-187l43 43q-30 29.538-70 46.77Q706-80 660-80Zm189.825-121Q837-201 828.5-209.625T820-231v-139H676l-46-47v-160q-24 5-45.5 16.5T546-532q-8 8-18.5 5.5T509-538q-9-11-6.5-25t13.5-24q30-26 67.174-39.5Q620.348-640 660-640q92 0 156 64t64 156v189q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM690-430h130q-4-55-40-95.5T690-577v147ZM411-549ZM140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h20l60 60h-80v520h215q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T355-160H140Zm160-580q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T300-800h550q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-740H300Zm146 140Z"
		/>
	</svg>
);
