import { ReactNode } from 'react';

export const symbol_toll: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M600-160q-134 0-227-93t-93-227q0-134 93-227t227-93q134 0 227 93t93 227q0 134-93 227t-227 93Zm-340-19q-99-26-159.5-110.5T40-480q0-106 60.5-190.5T260-781q14-4 24 4.5t10 23.5q0 9-6.5 17T272-725q-78 23-125 91.5T100-480q0 85 47 153.5T272-235q9 3 15.5 10.5T294-207q0 15-10 23.5t-24 4.5Zm340-301Zm0 260q107 0 183.5-76.5T860-480q0-107-76.5-183.5T600-740q-107 0-183.5 76.5T340-480q0 107 76.5 183.5T600-220Z"
		/>
	</svg>
);
