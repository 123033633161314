import { ReactNode } from 'react';

export const symbol_night_sight_max: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M690-650h-90q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T600-710h90v-90q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T750-800v90h90q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T840-650h-90v90q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T690-560v-90ZM440.247-190Q496-190 548-216.5t93-73.5q-130.62-7.749-220.81-96.374Q330-475 330-600q0-12 1.5-29t6.5-40q-66 37-107 97.5T190-440q0 103 73.5 176.5T440.247-190ZM719-307q-39 81-113.531 129T440-130q-129 0-219.5-90.5T130-440q0-108 66-192t170-109q11-3 20.5 0t15.5 9q6 6 8.5 15t-1.5 19q-9 23-14 47.5t-5 50.5q0 104.167 72.789 177.083Q535.578-350 639.562-350q11.438 0 22.938-1 11.5-1 22.5-3t19.081 1.5q8.081 3.5 13 10.5Q722-335 723-326t-4 19Zm-298-80Z"
		/>
	</svg>
);
