import { ReactNode } from 'react';

export const symbol_mode_heat_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-507Zm0 0Zm0 0ZM792-83 82-792q-9-9.067-9-21.533Q73-826 82.053-835q9.052-9 21.5-9Q116-844 125-835l710 710q9 9 9 21t-9.053 21q-9.052 9-21.5 9Q801-74 792-83ZM561.271-608Q500-608 460-649.5T420-754v-20q-16 11-29.5 23T363-726q-9 9-22 8.5t-22-9.5q-9-9-8.5-21.5T320-769q24-23 51-43.5t57-38.5q17-11 34.5-.75T480-821v73q0 34 23.5 57t57.5 23q18 0 33.5-7.5t27.5-22.167Q630-708 641.5-710t22.5 5q63 45 99.5 115T800-440q0 28.605-5 55.302Q790-358 781-332q-5 11-16 15.5t-22-.5q-6-3-9.5-7.5t-6-10Q725-340 725-346t2-12q7-20 10-40.269T740-440q0-54-23-105.5T651-640q-21 15-44 23.5t-45.729 8.5ZM480-120q-134 0-227-93t-93-227q0-69 25-135.5T258-702l44 44q-39 52-60.5 108T220-440q0 63 28.5 118.5T328-229q-4-12-6-24.5t-2-24.5q0-32 12-60t35-51l103-101 42 42-103 102q-13.87 13.538-21.435 30.769Q380-298 380-278q0 41 29 69.5t71 28.5q42 0 71-28.5t29-69.167Q580-298 572.5-315T551-346l31-32 143 143q-44 53-106.812 84Q555.375-120 480-120Z"
		/>
	</svg>
);
