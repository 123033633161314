import { Map, Record } from 'immutable';

import VideoSequence from 'pkg/models/video_sequence';

import * as actionTypes from 'pkg/actions/action-types';

interface ISequenceReducer {
	entities: Map<number, VideoSequence>;
}

class SequenceReducer
	extends Record<ISequenceReducer>(
		{
			entities: Map<number, VideoSequence>(),
		},
		'videoSequences'
	)
	implements ISequenceReducer
{
	entities: Map<number, VideoSequence>;
}

const initialState = new SequenceReducer({
	entities: Map<number, VideoSequence>(),
});

interface NormalizrPayload {
	result: number[];
	entities: {
		videoSequences: { [key: string]: VideoSequence };
	};
}

interface SequenceAction {
	type: string;
	payload?: NormalizrPayload;
	Id?: number;
	sequenceId?: number;
}

const setItems = (
	state = initialState,
	action: SequenceAction
): SequenceReducer => {
	const videoSequences = action.payload;

	Object.values(videoSequences).forEach((sequence: VideoSequence) => {
		state = state.updateIn(
			['entities', sequence.id],
			(existing: VideoSequence) => {
				if (!existing) {
					existing = new VideoSequence(sequence);
				}

				existing = existing.merge(sequence);

				// Make sure tags are properly removed after merge
				if (existing.tags && !sequence.tags) {
					existing = existing.set('tags', []);
				}

				return existing;
			}
		);
	});

	return state;
};

const deleteItem = (
	state = initialState,
	action: SequenceAction
): SequenceReducer => {
	if (action.sequenceId) {
		state = state.deleteIn(['entities', action.sequenceId]);
	}

	return state;
};

const videoCollectionsReducer = (
	state = initialState,
	action: SequenceAction
): SequenceReducer => {
	switch (action.type) {
		case actionTypes.VideoSequences.SET_ITEMS:
		case actionTypes.VideoSequences.UPDATE_ITEM:
			return setItems(state, action);
		case actionTypes.VideoSequences.DELETE_ITEM:
			return deleteItem(state, action);
		default:
			return state;
	}
};

export default videoCollectionsReducer;
