import { ReactNode } from 'react';

export const symbol_credit_card_heart: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-220v-320 20-220 520Zm575.867 88Q710-132 705-134q-5-2-10-7L576-260q-13-13-18.5-28t-5.5-30q0-32 23-57t59-25q28 0 44 13t38 35q20-20 36.5-34t45.5-14q37 0 59.5 25.5T880-317q0 15-6 30t-18 27L737-141q-5 5-10.133 7-5.134 2-11 2ZM140-631h680v-109H140v109Zm0 471q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v212q0 14-11.5 20t-23.5-1q-28.753-14.88-61.376-22.94Q751-540 716-540q-38 0-74 10t-67 28H140v282h284q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T424-160H140Z"
		/>
	</svg>
);
