export default {
	name: 'edit',
	objects: [
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M16.2202 3.96344C17.2713 2.94508 18.949 2.97168 19.9673 4.02284L20.2403 4.30456C21.2586 5.35572 21.2321 7.0334 20.1809 8.05176L9.12789 18.7599C8.77817 19.0987 8.34187 19.3346 7.86687 19.4417L4.30139 20.2456C4.08033 20.2955 3.84926 20.2266 3.69157 20.0638C3.53389 19.9011 3.47236 19.6679 3.52919 19.4485L4.44572 15.9103C4.56782 15.439 4.81743 15.0104 5.16715 14.6716L16.2202 3.96344ZM19.0337 4.92739C18.5149 4.3919 17.6602 4.37835 17.1247 4.89713L16.2393 5.75496L18.3909 7.97589L19.2763 7.11807C19.8118 6.59928 19.8254 5.74462 19.3066 5.20912L19.0337 4.92739ZM17.4572 8.88045L15.3056 6.65951L7.13669 14.5735L9.28833 16.7944L17.4572 8.88045ZM6.0717 15.6052L6.203 15.478L8.35464 17.699L8.22333 17.8262C8.04518 17.9988 7.82291 18.119 7.58093 18.1735L5.05483 18.7431L5.70418 16.2363C5.76639 15.9962 5.89354 15.7778 6.0717 15.6052Z',
			},
		},
	],
};
