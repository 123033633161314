import { ReactNode } from 'react';

export const symbol_connected_tv: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-200q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H630v50q0 12.75-8.625 21.375T600-120H360q-12.75 0-21.375-8.625T330-150v-50H140Zm0-60h680v-520H140v520Zm0 0v-520 520Zm95-60q14 0 24.5-10.5T270-355q0-14-10.5-24.5T235-390q-14 0-24.5 10.5T200-355q0 14 10.5 24.5T235-320Zm211.049 0Q456-320 463-327.034q7-7.035 7-16.966 0-103-71.5-174.5T224.195-590Q214-590 207-582.951t-7 17Q200-556 207.151-549t17.246 7Q306-542 364-484.215 422-426.429 422-344q0 9.931 7.049 16.966 7.049 7.034 17 7.034Zm-100 0Q356-320 363-327.034q7-7.035 7-16.966 0-60.62-42.623-103.31Q284.754-490 224.23-490 214-490 207-482.951t-7 17Q200-456 207.034-449q7.035 7 16.966 7 40.761 0 69.381 28.512Q322-384.976 322-344.368 322-334 329.049-327q7.049 7 17 7Z"
		/>
	</svg>
);
