import { ReactNode } from 'react';

export const symbol_music_video: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M430.141-290Q468-290 494-316.5t26-63.5v-218h71.753Q607-598 617.5-608.289q10.5-10.29 10.5-25.5Q628-649 617.675-659.5 607.35-670 592.088-670h-71.825Q505-670 494.5-659.65 484-649.3 484-634v180q-10-7-25.5-11.5T430-470q-37.8 0-63.9 26.5T340-380q0 37 26.141 63.5t64 26.5ZM140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm0-60h680v-520H140v520Zm0 0v-520 520Z"
		/>
	</svg>
);
