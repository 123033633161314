import { ReactNode } from 'react';

export const symbol_fiber_new: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M100-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h760q24.75 0 42.375 18T920-740v520q0 24-17.625 42T860-160H100Zm0-60h760v-520H100v520Zm75-307 110 161q3 5 7.474 7 4.473 2 9.842 2h10.737Q322-357 328-363.125T334-378v-203q0-9-6.5-15.5T312-603q-9 0-16 6.5t-7 15.5v148L182-593q-3-5-8.5-7.5t-10.466-2.5h-9.931Q144-603 137-596.5q-7 6.5-7 15.5v202q0 9 7 15.5t16 6.5q9 0 15.5-7t6.5-16v-147Zm219 170h120q9 0 15.5-7t6.5-16q0-9-6.5-15.5T514-402h-85v-54h85q9 0 15.5-7t6.5-16q0-9-6.5-15.5T514-501h-85v-56h84q10.12 0 16.56-6.417 6.44-6.416 6.44-16.5 0-10.083-6.44-16.583-6.44-6.5-16.56-6.5H394q-6 0-10.5 4.5T379-588v216q0 6 4.5 10.5T394-357Zm233 0h170q14.45 0 24.225-9.487Q831-375.975 831-390v-191q0-9-6.5-15.5T809-603q-9 0-16 6.5t-7 15.5v174h-53v-133q0-9-6.5-15.5T711-562q-9 0-16 6.5t-7 15.5v133h-49v-174q0-9-6.5-15.5T617-603q-9 0-16 6.5t-7 15.5v191q0 14.025 9.487 23.513Q612.975-357 627-357ZM100-220v-520 520Z"
		/>
	</svg>
);
