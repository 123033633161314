import { t } from '@transifex/native';
import { ReactNode } from 'react';

import * as arrays from 'pkg/arrays';
import * as objects from 'pkg/objects';
import * as models from 'pkg/api/models';
import { useQueryState } from 'pkg/hooks/query-state';
import { popState } from 'pkg/router/state';
import { useCurrentOrganization } from 'pkg/identity';
import { Weekday } from 'pkg/datetime';

import ScheduleItem from 'routes/scheduling/templates/print/ScheduleItem';
import {
	ConflictingItems,
	findGroupsByFilterByScheduleItems,
	findResourcesByFilterByScheduleItems,
} from 'routes/scheduling/utils';
import {
	ScheduleTemplateProvider,
	useScheduleTemplateContext,
} from 'routes/scheduling/templates/single';
import { FilterState } from 'routes/scheduling/templates/filters';

import Badge from 'components/Badge';

import * as Printable from 'components/layout/printable';
import InfoBox from 'components/form/info-box';
import Column from 'components/layout/column';
import Row from 'components/layout/row';

import * as css from './styles.css';

interface GroupedGridItems<T> {
	[Weekday.MONDAY]: T[];
	[Weekday.TUESDAY]: T[];
	[Weekday.WEDNESDAY]: T[];
	[Weekday.THURSDAY]: T[];
	[Weekday.FRIDAY]: T[];
	[Weekday.SATURDAY]: T[];
	[Weekday.SUNDAY]: T[];
}

interface GridItem<T> {
	source: T;
	items: GroupedGridItems<ReactNode> | null;
}

const getGroupedGridItems = (
	scheduleItems: models.scheduleItem.ScheduleItem[],
	filters: FilterState,
	conflicts: ConflictingItems,
	group?: models.group.Group
): GroupedGridItems<ReactNode> => {
	const grouped: GroupedGridItems<models.scheduleItem.ScheduleItem> = {
		[Weekday.MONDAY]: [],
		[Weekday.TUESDAY]: [],
		[Weekday.WEDNESDAY]: [],
		[Weekday.THURSDAY]: [],
		[Weekday.FRIDAY]: [],
		[Weekday.SATURDAY]: [],
		[Weekday.SUNDAY]: [],
	};

	if (scheduleItems.length === 0) {
		return;
	}

	if (filters.types?.length > 0) {
		scheduleItems = scheduleItems.filter(
			(item: models.scheduleItem.ScheduleItem) =>
				filters.types.includes(item.type)
		);
	}

	const hasConflicts = (scheduleItemId: number): boolean => {
		return (
			conflicts[scheduleItemId]?.groupConflicts.length > 0 ||
			conflicts[scheduleItemId]?.resourceConflicts.length > 0
		);
	};

	scheduleItems.forEach((scheduleItem: models.scheduleItem.ScheduleItem) => {
		const conflicts = hasConflicts(scheduleItem.id);

		if (filters.conflicts && !conflicts) {
			return;
		}

		let weekday: Weekday = scheduleItem.day;

		// Normalize days to properly match how Date works
		if (scheduleItem.day === 7) {
			weekday = 0;
		}

		grouped[weekday].push(scheduleItem);

		grouped[weekday] = grouped[weekday].sort(
			(
				a: models.scheduleItem.ScheduleItem,
				b: models.scheduleItem.ScheduleItem
			) => Number.parseInt(a.startsAt, 10) - Number.parseInt(b.startsAt, 10)
		);
	});

	return objects.map(
		grouped,
		(items: models.scheduleItem.ScheduleItem[]): JSX.Element[] => {
			return items.map((item: models.scheduleItem.ScheduleItem) => (
				<ScheduleItem
					key={item.id}
					group={group}
					scheduleItem={item}
					hasConflicts={hasConflicts(item.id)}
				/>
			));
		}
	);
};

function useGridGroupedByGroup(): Map<number, GridItem<models.group.Group>> {
	const { flattenedGroups, scheduleItems, search, filters, conflicts } =
		useScheduleTemplateContext();

	let groups: models.group.Group[] = findGroupsByFilterByScheduleItems(
		flattenedGroups,
		scheduleItems,
		filters,
		conflicts
	);

	if (search?.length > 0) {
		groups = groups.filter((group: models.group.Group) =>
			group.name.toLocaleLowerCase().includes(search)
		);
	}

	const getScheduleItemsForGroup = (
		group: models.group.Group
	): GroupedGridItems<ReactNode> => {
		const groupScheduleItems = scheduleItems.filter(
			(item: models.scheduleItem.ScheduleItem) => item.groupId === group.id
		);

		return getGroupedGridItems(groupScheduleItems, filters, conflicts);
	};

	const grid = new Map<number, GridItem<models.group.Group>>();

	groups.forEach((group: models.group.Group) => {
		const items = getScheduleItemsForGroup(group);

		if (items !== undefined) {
			grid.set(group.id, { source: group, items });
		}
	});

	return grid;
}

function useGridGroupedByResource(): Map<
	number,
	GridItem<models.resource.Resource>
> {
	const {
		flattenedGroups,
		resources: flattenedResources,
		scheduleItems,
		search,
		filters,
		conflicts,
	} = useScheduleTemplateContext();

	let resources: models.resource.Resource[] =
		findResourcesByFilterByScheduleItems(
			flattenedResources,
			scheduleItems,
			filters,
			conflicts
		);

	if (search) {
		resources = resources.filter((resource: models.resource.Resource) =>
			resource.title.toLocaleLowerCase().includes(search)
		);
	}

	const grid = new Map<number, GridItem<models.resource.Resource>>();

	const getBookingsForResource = (
		resource: models.resource.Resource
	): GroupedGridItems<ReactNode> => {
		const resourceGroup = flattenedGroups.find(
			(g: models.group.Group) => g.id === resource.groupId
		);

		const resourceBookings = scheduleItems.filter(
			(booking: models.scheduleItem.ScheduleItem) => {
				const resourceIds = arrays.unique<number>(
					[
						booking.resourceId,
						booking.resources?.map(
							(r: models.booking.BookingResource) => r.resourceId
						),
					].flat()
				);

				return resourceIds.includes(resource.id);
			}
		);

		return getGroupedGridItems(
			resourceBookings,
			filters,
			conflicts,
			resourceGroup
		);
	};

	resources.forEach((resource: models.resource.Resource) => {
		const items = getBookingsForResource(resource);

		if (items !== undefined) {
			grid.set(resource.id, { source: resource, items });
		}
	});

	return grid;
}

function GroupCalendar(): JSX.Element {
	const org = useCurrentOrganization();
	const grid = useGridGroupedByGroup();
	const { schedule } = useScheduleTemplateContext();

	const calendarData: Printable.CalendarGroup[] = [];

	const goBack = (): string => {
		popState();
		return;
	};

	Array.from(grid.values()).forEach((item: GridItem<models.group.Group>) => {
		calendarData.push({
			title: item.source.name,
			items: item.items,
		});
	});

	const title = t('{org} Bookings Template', {
		org: org.name,
		_context: 'bookings printout',
	});

	const caption = (
		<Row columns="1fr auto" justify="start" align="center">
			<Row columns="50px auto" align="center">
				<Badge badgeUrl={org.profileImageUrl} />
				<span className={css.calendarCaption}>{org.name}</span>
			</Row>
			<span>{schedule.title}</span>
		</Row>
	);

	if (calendarData.length === 0) {
		return (
			<Printable.Sheet canPrint={false} backAction={goBack}>
				<InfoBox color="orange">
					<Column>
						<strong>{t('Nothing to print')}</strong>
						<div>{t('Data filtering returned nothing to print.')}</div>
					</Column>
				</InfoBox>
			</Printable.Sheet>
		);
	}

	return (
		<Printable.Sheet title={title} backAction={goBack} orientation="landscape">
			<Printable.Calendar
				data={calendarData}
				caption={caption}
				weekStartsAt={schedule.startingDay}
			/>
		</Printable.Sheet>
	);
}

function ResourceCalendar(): JSX.Element {
	const org = useCurrentOrganization();
	const grid = useGridGroupedByResource();
	const { schedule } = useScheduleTemplateContext();

	const calendarData: Printable.CalendarGroup[] = [];

	const goBack = (): string => {
		popState();
		return;
	};

	Array.from(grid.values()).forEach(
		(item: GridItem<models.resource.Resource>) => {
			calendarData.push({
				title: item.source.title,
				items: item.items,
			});
		}
	);

	const title = t('{org} Bookings Template', {
		org: org.name,
		_context: 'bookings printout',
	});

	const caption = (
		<Row columns="1fr auto" justify="start" align="center">
			<Row columns="50px auto" align="center">
				<Badge badgeUrl={org.profileImageUrl} />
				<span className={css.calendarCaption}>{org.name}</span>
			</Row>
			<span>{schedule.title}</span>
		</Row>
	);

	if (calendarData.length === 0) {
		return (
			<Printable.Sheet canPrint={false} backAction={goBack}>
				<InfoBox color="orange">
					<Column>
						<strong>{t('Nothing to print')}</strong>
						<div>{t('Data filtering returned nothing to print.')}</div>
					</Column>
				</InfoBox>
			</Printable.Sheet>
		);
	}

	return (
		<Printable.Sheet title={title} backAction={goBack} orientation="landscape">
			<Printable.Calendar
				data={calendarData}
				caption={caption}
				weekStartsAt={schedule.startingDay}
			/>
		</Printable.Sheet>
	);
}

interface ScheduleTemplatePrintProps {
	groupId: number;
	id: string;
}

export default function ScheduleTemplatePrint({
	groupId,
	id,
}: ScheduleTemplatePrintProps): JSX.Element {
	const qs = useQueryState();

	return (
		<ScheduleTemplateProvider id={id} groupId={groupId}>
			{qs.is('view', 'resource') ? <ResourceCalendar /> : <GroupCalendar />}
		</ScheduleTemplateProvider>
	);
}
