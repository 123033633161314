import styled from 'styled-components';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import CardWrapper, * as Card from 'components/Card';
import Modal from 'components/modal';

import { Spinner } from 'components/loaders/spinner';
import Hint from 'components/form/Hint';

export default function ExerciseDuplicationModal() {
	const _context = 'training_library';

	return (
		<Modal thin hideClose centered>
			<CardWrapper>
				<Card.Body>
					<Wrapper>
						<Spinner size="48px" color={styles.palette.gray[800]} />
						<Card.Heading>
							<HeadingSpacer>
								{t('Duplicating exercise', { _context })}
							</HeadingSpacer>
						</Card.Heading>
						<Hint>{t('It will soon be ready for editing', { _context })}</Hint>
					</Wrapper>
				</Card.Body>
			</CardWrapper>
		</Modal>
	);
}

const Wrapper = styled.div`
	padding: var(--spacing-8);
	text-align: center;
`;

const HeadingSpacer = styled.span`
	display: block;
	margin-top: var(--spacing-4);
	text-align: center;
`;
