import { ReactNode } from 'react';

export const symbol_balance: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M110-120q-13 0-21.5-8.5T80-150q0-13 8.5-21.5T110-180h340v-484q-26-9-46.5-29.5T374-740H215l118 285q4 8 4.5 17t-1.5 18q-9 45-46 67.5T210-330q-43 0-80-22.5T84-420q-2-9-1.5-18t4.5-17l118-285h-55q-13 0-21.5-8.5T120-770q0-13 8.5-21.5T150-800h224q12-35 41-57.5t65-22.5q36 0 65 22.5t41 57.5h224q13 0 21.5 8.5T840-770q0 13-8.5 21.5T810-740h-55l118 285q4 8 4.5 17t-1.5 18q-9 45-46 67.5T750-330q-43 0-80-22.5T624-420q-2-9-1.5-18t4.5-17l118-285H586q-9 26-29.5 46.5T510-664v484h340q13 0 21.5 8.5T880-150q0 13-8.5 21.5T850-120H110Zm565-320h150l-75-184-75 184Zm-540 0h150l-75-184-75 184Zm345-280q21 0 35.5-15t14.5-35q0-21-14.5-35.5T480-820q-20 0-35 14.5T430-770q0 20 15 35t35 15Z"
		/>
	</svg>
);
