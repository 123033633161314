import { t } from '@transifex/native';

import * as sdk from 'pkg/core/sdk';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as ls from 'pkg/local_storage';
import { flashes } from 'pkg/actions';
import * as models from 'pkg/api/models';
import { stringify } from 'pkg/json';
import { isApp } from 'pkg/platform';

export interface StartLoginPayload {
	idp: number;
	redirect_uri?: string;
	state?: string;
}

export interface StartLoginResponse {
	url: string;
}

interface StartLoginProps {
	identityProvider: models.identityProvider.ScrubbedIdentityProvider;
	state?: { [key: string]: unknown };
}

export enum StartLoginStateFlows {
	Login = 'login',
	Registration = 'registration',
}

export async function startLogin({
	identityProvider,
	state = {},
}: StartLoginProps): Promise<boolean> {
	// Always set the state identity provider to be the idp we auth against
	// because we need to use this value after the login action to save last login
	state.identityProvider = identityProvider;
	let redirect_uri = `${window.location.origin}/sso/redirect`;

	if (isApp()) {
		redirect_uri = 'https://app.360player.com/sso/redirect';
	}

	const request = await sdk.get(endpoints.Auth.SSOStart(), {
		idp: identityProvider.id,
		redirect_uri,
		state: stringify(state),
	});

	if (!request.ok) {
		flashes.show({
			title: t('Something went wrong'),
			message: t(
				`If you think this error isn't supposed to happen, please contact 360Player support.`
			),
		});

		return false;
	}

	const json: StartLoginResponse = await request.json();

	window.location.replace(json.url);

	return true;
}

interface LoginPayload {
	code?: string;
}

interface LoginResponse {
	accountToken: string;
	orgToken: string;
	account: models.account.Account;
}

export enum LoginErrorCodes {
	UserNotFound = 'unable_to_find_user',
}

export interface LoginErrorResponse {
	error_code: LoginErrorCodes;
}

export async function login(
	payload: LoginPayload
): Promise<[Response, LoginResponse, LoginErrorResponse?]> {
	const request = await sdk.post(endpoints.Auth.SSOLogin(), {}, payload);
	const response = await request.json();

	if (!request.ok) {
		ls.removeItem(ls.LocalStorageKeys.UserLogin);

		return [request, null, response];
	}

	return [request, response, null];
}

interface ValidateResponse {
	token: string;
	profile: {
		connectionId: number;
		externalId: number;
		firstName: string;
		lastName: string;
		email: string;
		groups: string[];
	};
}

interface ValidatePayload {
	code: string;
}

export async function validate(
	payload: ValidatePayload
): Promise<[Response, ValidateResponse, models.APIError?]> {
	const request = await sdk.post(endpoints.Auth.SSOValidate(), {}, payload);
	const response = await request.json();

	if (!request.ok) {
		return [request, null, response];
	}

	return [request, response, null];
}
