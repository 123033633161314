import { ReactNode } from 'react';

export const symbol_domain_verification: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm0-60h680v-436H140v436Zm298-180 146-146q9.13-9 22.065-9T628-545.897q9 9.104 9 22Q637-511 628-502L459-333q-9 9-21 9t-21-9l-85-85q-9-9.13-9-22.065T332.103-462q9.104-9 22-9Q367-471 376-462l62 62ZM140-220v-520 520Z"
		/>
	</svg>
);
