import { ReactNode } from 'react';

export const symbol_free_cancellation: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m661-145 163-163q9-9 21-8.5t20.87 9.5q8.13 9 8.63 21t-8.5 21L683-81q-9 9-21 9t-21-9L541-181q-9-9-9-21t9-21q9-9 21-9t21 9l78 78ZM360-358l-50 50q-9 9-21 9t-21-9q-9-9-9-21t9-21l50-50-50-50q-9-9-9-21t9-21q9-9 21-9t21 9l50 50 50-50q9-9 21-9t21 9q9 9 9 21t-9 21l-50 50 50 50q9 9 9 21t-9 21q-9 9-21 9t-21-9l-50-50ZM180-80q-24 0-42-18t-18-42v-620q0-24 18-42t42-18h65v-28q0-13.6 9.2-22.8 9.2-9.2 22.8-9.2 14.025 0 23.513 9.2Q310-861.6 310-848v28h340v-28q0-13.6 9.2-22.8 9.2-9.2 22.8-9.2 14.025 0 23.513 9.2Q715-861.6 715-848v28h65q24 0 42 18t18 42v357q0 13.077-8.675 21.538-8.676 8.462-21.5 8.462-12.825 0-21.325-8.625T780-403v-167H180v430h307q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q517-97 508.375-88.5T487-80H180Zm0-550h600v-130H180v130Zm0 0v-130 130Z"
		/>
	</svg>
);
