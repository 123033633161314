import { Dispatch } from 'redux';
import { t } from '@transifex/native';

import Group from 'pkg/models/group';
import Membership, { Capabilities } from 'pkg/models/membership';
import Activity from 'pkg/models/activity';
import Sport from 'pkg/models/sport';

import { forAccountHeaders, normalizedDispatch } from 'pkg/actions/utils';
import * as actionTypes from 'pkg/actions/action-types';
import * as appActions from 'pkg/actions/app';
import * as flashActions from 'pkg/actions/flashes';
import * as ratingActions from 'pkg/actions/ratings';
import * as services from 'pkg/actions/services';

import * as models from 'pkg/api/models';
import * as selectors from 'pkg/selectors';
import store from 'pkg/store/createStore';
import { pushState } from 'pkg/router/state';
import * as routes from 'pkg/router/routes';
import * as sdk from 'pkg/core/sdk';
import { RootState } from 'pkg/reducers';
import { getOrganizationIdentityContextValues } from 'pkg/identity/organization';
import { MembershipRole, MembershipStatus } from 'pkg/api/models/membership';
import * as endpoints from 'pkg/api/endpoints/auto';

type Action =
	| { type: actionTypes.Groups.ADD_ITEM; group: Group }
	| { type: actionTypes.Groups.DELETE_ITEM; groupId: number }
	| {
			type: actionTypes.Groups.CREATE_IMPORT_CONNECTION;
			groupId: number;
			externalId: number;
	  }
	| {
			type: actionTypes.Groups.DELETE_IMPORT_CONNECTION;
			groupId: number;
	  };

interface IncludeCountProps {
	include_sub_group_count?: string;
	include_admin_count?: string;
	include_player_count?: string;
	include_pending_count?: string;
}

const addSport = (sport: Sport) => {
	return {
		type: actionTypes.Sports.SET_ITEMS,
		payload: {
			entities: {
				sports: {
					1: sport,
				},
			},
		},
	};
};

export const addGroup = (group: Group): Action => {
	return {
		type: actionTypes.Groups.ADD_ITEM,
		group,
	};
};

export const updateGroupKeyValue = (
	groupId: number,
	key: string,
	value: string
) => ({
	type: actionTypes.Groups.UPDATE_GROUP_KEY_VALUE,
	groupId,
	key,
	value,
});

export const removeGroup = (groupId: number): Action => ({
	type: actionTypes.Groups.DELETE_ITEM,
	groupId,
});

const addGroupActivitiesSummation = (
	groupId: number,
	userId: number,
	summation: (typeof Activity)[]
): {
	type: string;
	userId: number;
	groupId: number;
	summation: (typeof Activity)[];
} => ({
	type: 'ADD_ACTIVITIES_SUMMATION',
	userId,
	groupId,
	summation,
});

export const fetchChildGroups =
	(
		groupId: number,
		queryParams?: IncludeCountProps & {
			name?: string;
			include_deleted?: string;
			recursive?: boolean;
		}
	) =>
	async (dispatch: Dispatch): Promise<void> => {
		const response = await services.group.getChildren(groupId, queryParams);

		if (!response.ok) {
			return appActions.triggerError(response, null)(dispatch);
		}

		const result = await response.json();

		normalizedDispatch(result.records, [Group.normalizr()])(dispatch);
	};

export const fetchGroup = async (
	groupId: number,
	queryParams?: IncludeCountProps
): Promise<models.group.Group> => {
	const response = await services.group.getGroup(groupId, queryParams);

	if (!response.ok) {
		appActions.triggerError(response, null)(store.dispatch);
		return null;
	}

	const group = await response.json();

	store.dispatch(addGroup(group));
	store.dispatch(addSport(group.sport));

	return group;
};

interface groupPayload {
	parentGroupId?: number;
	name?: string;
	slug?: string;
	externalId?: string;
	allowInvites?: boolean;
	countryId?: number;
	badge?: string;
	meta?: { key: string; value: string }[];
	currency?: string;
	seasonStartMonth?: number;
	primaryColor?: string;
	showRatingResults?: boolean;
}

export async function update(
	groupId: number,
	payload: groupPayload
): Promise<void> {
	// It's impossible to set a group to a top group after it's created, to avoid potential issues with that we remove parentgroupId from the payload if it's null.
	if (
		payload.hasOwnProperty('parentGroupId') &&
		payload.parentGroupId === null
	) {
		delete payload['parentGroupId'];
	}

	const response = await services.group.updateGroup(groupId, payload);

	if (!response.ok) {
		return Promise.reject(response);
	}

	const group = await response.json();

	store.dispatch(addGroup(group));
}

export const searchChildren =
	(groupId: number, name: string, params = {}) =>
	async (dispatch: Dispatch): Promise<number[]> => {
		const request = await services.group.searchChildren(groupId, {
			name,
			...params,
		});

		if (!request.ok) {
			return [];
		}

		const result = await request.json();

		const normalizedResult = normalizedDispatch(result.records, [
			Group.normalizr(),
		])(dispatch);

		return normalizedResult.result;
	};

export const fetchGroupActivities =
	(groupId: number, user_id: number, forAccount?: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		const headers = forAccountHeaders(forAccount);

		const response = await sdk.get(
			`activity-statisics/${groupId}?user_id=${user_id}`,
			{},
			{},
			headers
		);

		const activities = await response.json();

		dispatch(addGroupActivitiesSummation(groupId, user_id, activities));
	};

const continuousFetchGroupUsers = async (
	url: string,
	dispatch: Dispatch,
	getState: () => RootState
) => {
	const response = await sdk.get(url);

	if (!response.ok) {
		return Promise.reject(response);
	}

	const result = await response.json();
	const { records } = result;

	normalizedDispatch(records, [Membership.normalizr()])(dispatch);

	if (result.links?.next) {
		continuousFetchGroupUsers(result.links.next, dispatch, getState);
	}
};

export const fetchGroupUsers = (groupId: number) => {
	return async (
		dispatch: Dispatch,
		getState: () => RootState
	): Promise<void> => {
		continuousFetchGroupUsers(`groups/${groupId}/users`, dispatch, getState);
	};
};

export const fetchBannedMembers =
	(groupId: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		const response = await sdk.get(`groups/${groupId}/users?status=banned`);

		if (!response.ok) {
			appActions.triggerError(response, null)(dispatch);
			return;
		}

		const result = await response.json();
		const { records } = result;

		dispatch({
			type: actionTypes.Groups.SET_BANNED_MEMBERS,
			records,
		});
	};

export const fetchPendingMembers =
	(groupId: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		const response = await sdk.get(`groups/${groupId}/users?status=pending`);

		if (!response.ok) {
			appActions.triggerError(response, null)(dispatch);
			return;
		}

		const result = await response.json();
		const { records } = result;

		dispatch({
			type: actionTypes.Groups.SET_PENDING_MEMBERS,
			records,
		});
	};

interface FetchSquadParams {
	includeLegalGuardians?: number;
	includeRatings?: number;
	preloadactivities?: number;
}

export async function fetchSquad(
	groupId: number,
	params?: FetchSquadParams
): Promise<boolean> {
	const requestParams: FetchSquadParams = {
		includeLegalGuardians: 0,
		includeRatings: 0,
		preloadactivities: 0,
		...params,
	};

	const request = await sdk.get(`groups/${groupId}/users`, requestParams);

	if (!request.ok) {
		appActions.triggerError(request)(store.dispatch);

		return false;
	}

	const response = await request.json();

	normalizedDispatch(response.records, [Membership.normalizr()])(
		store.dispatch
	);

	const ratingRecords = response.records
		.map((rating: Membership) =>
			rating.lastSelfRating && !rating.lastRating
				? rating.lastSelfRating
				: rating.lastRating
		)
		.filter((rating: Membership) => rating);

	store.dispatch(ratingActions.addRatings(ratingRecords));

	return true;
}

interface UpdatePerformanceReviewTemplatePayload {
	groupId: number;
	template: string;
}

export const updatePerformanceReviewTemplate = async ({
	groupId,
	template,
}: UpdatePerformanceReviewTemplatePayload) => {
	const req = await sdk.put(
		endpoints.Groups.SetPerformanceReviewTemplate(groupId),
		{},
		{
			template,
		}
	);

	if (!req.ok) {
		flashActions.show({
			title: t(`Save failed`),
			message: t(`Failed to save, make sure you have written something.`),
		});
		return false;
	}

	flashActions.show(
		{
			title: t(`Template saved`),
			message: t(`Template was saved successfully.`),
		},
		200
	);
	return true;
};

export const fetchInvites =
	(groupId: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		const req = await sdk.get('groups/:groupId/invites', {
			groupId,
		});

		if (!req.ok) {
			appActions.triggerError(req, null)(dispatch);
			return;
		}

		const result = await req.json();

		dispatch({
			type: actionTypes.Groups.SET_INVITES,
			invites: result.records,
		});
	};

export const createInvites =
	(
		groupId: number,
		emails: Array<{ targetEmail: string; language: string; role: number }>
	) =>
	async (dispatch: Dispatch): Promise<void> => {
		let numInvitesCreated: number = 0;

		for (const pendingInvite of Object.values(emails)) {
			const payload = {
				groupId: groupId,
				email: pendingInvite.targetEmail,
				role: pendingInvite.role,
				languageCode: pendingInvite.language,
			};

			const response = await sdk.post('account-invites', {}, payload);

			if (!response.ok) {
				continue;
			}

			numInvitesCreated = numInvitesCreated + 1;
		}

		if (numInvitesCreated > 0) {
			flashActions.show({
				title: t(`Users invited`),
				message: t(`Sent {num} invites`, {
					num: numInvitesCreated,
				}),
			});

			fetchInvites(groupId)(dispatch);
		}
	};

export const fetchInvite =
	(inviteId: number, inviteKey?: string) =>
	async (dispatch: Dispatch): Promise<any> => {
		const invite_key = inviteKey ? inviteKey : null;

		const request = await sdk.get('/account-invites/:inviteId', {
			inviteId,
			invite_key,
		});

		const response = await request.json();

		if (!request.ok) {
			appActions.triggerError(request, null)(dispatch);
			return;
		}

		return response;
	};

export interface UpdateGroupMemberPayload {
	id?: number;
	role?: MembershipRole;
	status?: MembershipStatus;
	title?: string;
	removeFromFutureEvents?: boolean;
}

interface UpdateGroupMemberParams {
	groupId: number;
	userId: number;
	payload: UpdateGroupMemberPayload;
}

export const updateGroupMember = async ({
	groupId,
	userId,
	payload,
}: UpdateGroupMemberParams): Promise<
	[boolean, models.membership.Membership]
> => {
	const req = await sdk.patch(
		endpoints.Groups.UpdateUser(groupId, userId),
		{},
		payload
	);
	const resp = await req.json();

	if (!req.ok) {
		flashActions.show({ title: t('Something went wrong') }, req.status);
		return [false, resp];
	}

	return [true, resp];
};

interface UpdateUserCapabilities {
	groupId: number;
	userId: number;
	payload: { capabilities: Capabilities[] };
}

export const updateUserCapabilities = async ({
	groupId,
	userId,
	payload,
}: UpdateUserCapabilities): Promise<boolean> => {
	const req = await sdk.put(
		endpoints.Groups.UpdateUserCapabilities(groupId, userId),
		{},
		payload
	);

	if (!req.ok) {
		flashActions.show({ title: t('Something went wrong') }, req.status);

		return false;
	}

	return true;
};

export const deprecatedUpdateGroupMember =
	(groupId: number, userId: number, changes: { [key: string]: any } = {}) =>
	async (
		dispatch: Dispatch,
		getState: () => RootState
	): Promise<models.membership.Membership> => {
		const req = await services.group.updateUser(groupId, userId, changes);
		if (!req.ok) {
			dispatch({
				type: actionTypes.Users.USER_PATCH_ERROR,
			});

			return null;
		}

		const groupMembership = await req.json();
		const { user } = getOrganizationIdentityContextValues();

		if (changes.status === 3) {
			const currentAccountUsers = selectors.users
				.findAllByAccountId(getState(), user.accountId)
				.map((u) => u.id);

			// User removed their own membership
			if (currentAccountUsers.includes(userId)) {
				localStorage.removeItem('activeMembership');
				pushState(routes.Home());
				window.location.reload();
				return groupMembership;
			}
		}

		fetchGroupUsers(groupId)(dispatch, getState);

		if (changes.status === 3) {
			dispatch({
				type: actionTypes.Memberships.DELETE_ITEM,
				userId,
				groupId,
			});
		}

		if (changes.hasOwnProperty('status')) {
			fetchPendingMembers(groupId)(dispatch);
		}

		if (changes.status === 2 || changes.status === 3) {
			fetchBannedMembers(groupId)(dispatch);
		}

		return groupMembership;
	};

// @todo: return the same type if success or not
export const createGroup =
	(payload: Group) =>
	async (dispatch: Dispatch): Promise<boolean> => {
		const req = await services.group.createGroup(payload);

		if (!req.ok) {
			appActions.triggerError(req, null)(dispatch);
			return false;
		}

		const group = await req.json();

		dispatch(addGroup(group));

		return group;
	};

export const deleteGroup = async (groupId: number): Promise<boolean> => {
	const req = await services.group.removeGroup(groupId);

	if (!req.ok) {
		appActions.triggerError(req, null)(store.dispatch);
		return false;
	}

	store.dispatch(removeGroup(groupId));

	return true;
};

export const connectToStripe = async (groupId: number): Promise<void> => {
	const req = await services.group.setStripeConnection(groupId, {
		returnUrl: location.href,
	});

	if (req.ok) {
		const result = await req.json();
		location.href = result.redirect_url;
	} else {
		flashActions.show({
			title: t('Something went wrong'),
			message: t(
				"If you think this error isn't supposed to happen, please contact 360Player support."
			),
		});
	}
};

export const fetchCurrencies =
	(groupId: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		const request = await services.group.getCurrencies({ group_id: groupId });

		if (!request.ok) {
			return;
		}

		const response = await request.json();

		dispatch({
			type: actionTypes.Currencies.SET_ITEMS,
			payload: response,
		});
	};

export async function createCalendarSubscriptionToken(
	accountId: number
): Promise<string> {
	const endpoint = '/accounts/:id/tokens/calendar-subscription';
	const request = await sdk.post(endpoint, { id: accountId });

	if (!request.ok) {
		return '';
	}

	const response = await request.json();

	if (response.token) {
		return response.token;
	}

	return '';
}
