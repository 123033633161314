// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.e7U65EgcayLTWZKQqzxk {
	font-size: var(--font-size-sm);
	font-weight: var(--font-weight-semibold);
}

@media (max-width: 768px) {

.e7U65EgcayLTWZKQqzxk {
		font-size: var(--font-size-base);
}
	}

.n4BHzBteL5HGue2OqoD5 {
	font-size: var(--font-size-xs);
	color: var(--palette-gray-500);
}

@media (max-width: 768px) {

.n4BHzBteL5HGue2OqoD5 {
		font-size: var(--font-size-sm);
}
	}

.n4BHzBteL5HGue2OqoD5 span {
		color: var(--palette-red-500);
	}

.n4BHzBteL5HGue2OqoD5 span::after {
			content: ' ';
		}
`, "",{"version":3,"sources":["webpack://./routes/event/single/tabs/lok/user-row/styles.css"],"names":[],"mappings":"AAAA;CACC,8BAA8B;CAC9B,wCAAwC;AAKzC;;AAHC;;AAJD;EAKE,gCAAgC;AAElC;CADC;;AAGD;CACC,8BAA8B;CAC9B,8BAA8B;AAa/B;;AAXC;;AAJD;EAKE,8BAA8B;AAUhC;CATC;;AAEA;EACC,6BAA6B;CAK9B;;AAHC;GACC,YAAY;EACb","sourcesContent":[".name {\n\tfont-size: var(--font-size-sm);\n\tfont-weight: var(--font-weight-semibold);\n\n\t@media (--small-viewport) {\n\t\tfont-size: var(--font-size-base);\n\t}\n}\n\n.flags {\n\tfont-size: var(--font-size-xs);\n\tcolor: var(--palette-gray-500);\n\n\t@media (--small-viewport) {\n\t\tfont-size: var(--font-size-sm);\n\t}\n\n\t& span {\n\t\tcolor: var(--palette-red-500);\n\n\t\t&::after {\n\t\t\tcontent: ' ';\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var name = `e7U65EgcayLTWZKQqzxk`;
export var flags = `n4BHzBteL5HGue2OqoD5`;
export default ___CSS_LOADER_EXPORT___;
