export function Create(): string {
	return `/v1/user-fields`;
}

export function Delete(ID: number): string {
	return `/v1/user-fields/${ID}`;
}

export function Index(): string {
	return `/v1/user-fields`;
}

export function Update(ID: number): string {
	return `/v1/user-fields/${ID}`;
}

export function UpdateSortOrder(): string {
	return `/v1/user-fields/sort-order`;
}
