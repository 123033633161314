import { ReactNode } from 'react';

export const symbol_candle: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M240-140h480q25.5 0 42.75-17.25T780-200v-20H180v19q0 25.925 17.25 43.462Q214.5-140 240-140Zm239-500q-46 0-77.5-32.5T370-751q0-48 28-84.5t61-65.5q5.053-4 10.526-6Q475-909 480-909t10 2q5 2 10 7 31 31 60.5 66t29.5 83q0 46-32.5 78.5T479-640Zm-60 360h121v-260H419v260Zm61-420q20 0 35-13.875t15-33.917q0-26.208-15.833-45.671Q498.333-812.927 480-831q-18.333 18.073-34.167 37.537Q430-774 430-747.792q0 20.042 15 33.917T480-700Zm338 420q17 0 29.5-12.5T860-322q0-17-12.5-29T818-363q-17 0-29.5 12T776-322q0 17 12.5 29.5T818-280ZM720-80H240q-50 0-85-35t-35-86v-49q0-12.75 8.625-21.375T150-280h209v-260q0-24.75 17.625-42.375T419-600h121q24.75 0 42.375 17.625T600-540v260h125q-5-10-7-20.5t-2-21.5q0-42.236 30-71.618Q776-423 818-423t72 29.382q30 29.382 30 71.618 0 37-22.5 64.5T840-222v22q0 50-35 85t-85 35Zm-240-60Zm-61-140h121-121Zm61-486Z"
		/>
	</svg>
);
