import { ReactNode } from 'react';

export const symbol_hls_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M786-600q15 0 24.5 9.5T820-566v22q0 10-9 14t-15 10q-10 0-17-7t-7-17v-8H668v53h118q14 0 24 10t10 24v71q0 15-9.5 24.5T786-360H685l-48-48h135v-53H654q-14 0-24-10t-10-24v-71q0-15 9.5-24.5T654-600h132ZM120-384v-192q0-10 7-17t17-7q10 0 17 7t7 17v65h98v-65q0-10 7-17t17-7q10 0 17 7t7 17v192q0 10-7 17t-17 7q-10 0-17-7t-7-17v-79h-98v79q0 10-7 17t-17 7q-10 0-17-7t-7-17ZM792-83 82-792q-9-9-9-21.5t9-21.5q9-9 21.5-9t21.5 9l710 710q9 9 9 21t-9 21q-9 9-21.5 9T792-83ZM396-383v-139l48 48v66h66l48 48H419q-9 0-16-7t-7-16Z"
		/>
	</svg>
);
