import { ReactNode } from 'react';

export const symbol_headphones: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M268-120h-88q-24 0-42-18t-18-42v-300q0-75 28.5-140.5t77-114q48.5-48.5 114-77T480-840q75 0 140.5 28.5t114 77q48.5 48.5 77 114T840-480v300q0 24-18 42t-42 18h-88q-24.75 0-42.375-17.625T632-180v-176q0-24.75 17.625-42.375T692-416h88v-64q0-125.357-87.321-212.679Q605.357-780 480-780t-212.679 87.321Q180-605.357 180-480v64h88q24.75 0 42.375 17.625T328-356v176q0 24.75-17.625 42.375T268-120Zm0-236h-88v176h88v-176Zm424 0v176h88v-176h-88Zm-424 0h-88 88Zm424 0h88-88Z"
		/>
	</svg>
);
