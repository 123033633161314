import { ReactNode } from 'react';

export const symbol_beenhere: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M479.803-55q-9.803 0-18.717-3-8.915-3-17.086-9L184-262q-11.25-8.25-17.625-21T160-310v-510q0-24 18-42t42-18h520q24 0 42 18t18 42v510q0 14.25-6.375 27T776-262L516-67q-8.295 6-17.344 9-9.049 3-18.853 3Zm.197-60 260-200v-505H220v505l260 200Zm-43-329-78-78q-9-9-21-9t-21 8.609Q308-513 308-501q0 12 9 21l100 99q9 9 21 9t21-9l184-184q9-9 8.5-21t-9.109-21Q633-616 621-616q-12 0-21 9L437-444Zm43-376H220h520-260Z"
		/>
	</svg>
);
