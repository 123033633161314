import { ReactNode } from 'react';

export const symbol_live_help: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M360-160H180q-24 0-42-18.5T120-220v-600q0-24 18-42t42-18h600q23 0 41.5 18t18.5 42v600q0 23-18.5 41.5T780-160H600l-99 99q-9 9-21 9t-21-9l-99-99Zm-180-60h204l96 96 96-96h204v-600H180v600Zm300-297Zm6.028 232Q502-285 513-296.028q11-11.028 11-27T512.972-350q-11.028-11-27-11T459-349.972q-11 11.028-11 27T459.028-296q11.028 11 27 11ZM557-638.4q0 20.4-11 38.9T509-556q-18 17-28.5 30T464-500q-4 8-6 17.5t-4 20.5q-2 11 6.5 20t20.786 9q10.75 0 19.196-7.838Q508.929-448.676 512-462q4-17 13.5-31.5T555-528q33-33 46-58.5t13-53.801Q614-693 580.184-724T488-755q-43 0-76 17.944-33 17.945-53 50.056-6 10-1.5 20t15.907 15q10.593 5 21.093 1.5T413-663q14-17 33-26t42-9q32 0 50.5 16t18.5 43.6Z"
		/>
	</svg>
);
