import { ReactNode } from 'react';

export const symbol_set_meal: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M100-360q-24.75 0-42.375-17.625T40-420v-400q0-24.75 17.625-42.375T100-880h760q24.75 0 42.375 17.625T920-820v400q0 24.75-17.625 42.375T860-360H100Zm0-460v400h760v-400H100Zm712 564-659 34q-13 1-22-7t-10-21q-1-13 7.5-22t21.5-10l659-34q13-1 22 7t10 21q1 13-7.5 22T812-256Zm-2 135H150q-13 0-21.5-8.5T120-151q0-13 8.5-21.5T150-181h660q13 0 21.5 8.5T840-151q0 13-8.5 21.5T810-121ZM419.731-510q70.938 0 136.603-24.5Q622-559 672-610q4 29 25.5 48.5T749-535q8 2 19.5 1.5T780-548v-144q0-14-11.5-14.5T749-705q-31 8-53 27t-25 49q-50.824-48.63-115.553-74.815Q490.718-730 419.757-730 344-730 284.5-707T172-630q-2 2-4 9.5 0 2.5 4 10.5 53 54 112.5 77t135.231 23ZM100-820v400-400Z"
		/>
	</svg>
);
