import { ReactNode } from 'react';

export const symbol_allergy: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M768-66q-15 0-27-7.5T723-95q-125-17-219-104.5T371-424q-96 39-156.5 122.5T142-112q-2 14-11 23t-22 9q-13 0-21.5-9.5T81-112q12-127 86-226t191-144q-21-116-.5-210.5T435-844v-4q0-21 14.5-35.5T485-898q21 0 35.5 14.5T535-848q0 21-14.5 35.5T485-798h-6q-3 0-6-1-35 41-49.5 83T408-612q32-42 87-63t130-21q14 0 27 .5t21 1.5q7-8 16.5-12t20.5-4q21 0 35.5 14.5T760-660q0 21-14.5 35.5T710-610q-13 0-24.5-6.5T667-634q-17-2-26.5-2H620q-87 0-135.5 34T431-503q35-7 73-8t84 4q7-11 18-17t24-6q21 0 35.5 14.5T680-480q0 21-14.5 35.5T630-430q-12 0-21.5-5T591-449q-40-5-82-3t-74 9q13 48 55.5 71T608-349q27 0 61-3.5t72-10.5q5-15 18-24t29-9q21 0 35.5 14.5T838-346q0 21-14.5 35.5T788-296q-8 0-15-2t-13-6q-45 8-83 11.5t-69 3.5q-34 0-64-4.5T489-308q45 68 108.5 107.5T736-154q7-5 15-8.5t17-3.5q21 0 35.5 14.5T818-116q0 21-14.5 35.5T768-66Z"
		/>
	</svg>
);
