import { ReactNode } from 'react';

export const symbol_equal: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M210-290q-20.833 0-35.417-14.618Q160-319.235 160-340.118 160-361 174.583-375.5 189.167-390 210-390h540q20.833 0 35.417 14.618Q800-360.765 800-339.882 800-319 785.417-304.5 770.833-290 750-290H210Zm0-280q-20.833 0-35.417-14.618Q160-599.235 160-620.118 160-641 174.583-655.5 189.167-670 210-670h540q20.833 0 35.417 14.618Q800-640.765 800-619.882 800-599 785.417-584.5 770.833-570 750-570H210Z"
		/>
	</svg>
);
