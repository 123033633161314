import { ReactNode } from 'react';

export const symbol_chat_paste_go: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M751-240H600q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T600-300h151l-52-53q-9-9-9-21t9-21q9-9 21-9t21 9l104 104q9 9 9 21t-9 21L741-145q-9 9-21 9t-21-9q-9-9-9-21.5t9-21.5l52-52Zm-511 0-94 94q-7 7-16.5 3.458Q120-146.083 120-156v-584q0-24.75 17.625-42.375T180-800h520q24.75 0 42.375 17.625T760-740v190q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T700-550v-190H180v440h270q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T450-240H240Zm70-340h260q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T570-640H310q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T310-580Zm0 170h140q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T450-470H310q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T310-410ZM180-300v-440 440Z"
		/>
	</svg>
);
