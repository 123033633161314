import { ReactNode } from 'react';

export const symbol_local_shipping: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M224.118-161Q175-161 140.5-195.417 106-229.833 106-279H70q-12.75 0-21.375-8.625T40-309v-431q0-24 18-42t42-18h519q24.75 0 42.375 17.625T679-740v107h75q14.25 0 27 6.375T802-609l112 149q3 3.75 4.5 8.25T920-442v133q0 12.75-8.625 21.375T890-279h-41q0 49-34.382 83.5-34.383 34.5-83.5 34.5Q682-161 647.5-195.417 613-229.833 613-279H342q0 49-34.382 83.5-34.383 34.5-83.5 34.5ZM224-221q24 0 41-17t17-41q0-24-17-41t-41-17q-24 0-41 17t-17 41q0 24 17 41t41 17ZM100-339h22q17-27 43.041-43 26.041-16 58-16t58.459 16.5Q308-365 325-339h294v-401H100v401Zm631 118q24 0 41-17t17-41q0-24-17-41t-41-17q-24 0-41 17t-17 41q0 24 17 41t41 17Zm-52-204h186L754-573h-75v148ZM360-540Z"
		/>
	</svg>
);
