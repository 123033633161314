import styled, { css, keyframes } from 'styled-components';

import { small } from 'pkg/config/breakpoints';
import * as palette from 'pkg/config/palette';

import rgba from 'pkg/rgba';

import Icon from 'components/icon';

import * as iconStyles from 'components/icon/styles.css';

const ActiveSpot = keyframes`
	0% {
		box-shadow: inset 0 0 0 20px ${rgba(palette.green[200], 0.2)},
		0 0 0 0 ${rgba(palette.green[200], 1)};
	}

	50% {
		box-shadow: inset 0 0 0 20px ${rgba(palette.green[200], 0.2)},
		0 0 0 50px ${rgba(palette.green[200], 0.2)};
	}

	100% {
		box-shadow: inset 0 0 0 20px ${rgba(palette.green[200], 0.2)},
		0 0 0 50px ${rgba(palette.green[200], 0)};
	}
`;

// This Spot is an illustration.
// The color is not part of the palette
const Spot = styled.div`
	background-color: #dbffd4;
	width: 62px;
	height: 34px;
	border-radius: 1000px;
	box-shadow:
		inset 0 0 0 2px white,
		0 2px 5px ${rgba(palette.black, 0.2)};
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #1a9601;
	font-weight: var(--font-weight-normal);
`;

const Field = () => (
	<svg
		width="549"
		height="249"
		viewBox="0 0 549 249"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M120.492 248H2L64.4356 96.0381M120.492 248L136.366 179.196H411.335L427.776 248M120.492 248H207.234M427.776 248H547.402L483.92 96.0381M427.776 248H341.6M207.234 248L211.203 219.291H338.199L341.6 248M207.234 248H341.6M64.4356 96.0381L103.483 1.00001H177.753M64.4356 96.0381H483.92M483.92 96.0381L444.218 1.00001H370.515M177.753 1.00001L170.95 31.6894H377.885L370.515 1.00001M177.753 1.00001H232.18M370.515 1.00001H316.088M316.088 1.00001L317.222 12.8798H230.479L232.18 1.00001M316.088 1.00001H232.18"
			stroke={palette.gray[300]}
			strokeOpacity="0.7"
			strokeWidth="2"
		/>
		<path
			d="M311.686 97.028C311.686 103.705 307.693 109.865 301 114.401C294.312 118.932 285.017 121.768 274.701 121.768C264.385 121.768 255.09 118.932 248.402 114.401C241.709 109.865 237.716 103.705 237.716 97.028C237.716 90.3511 241.709 84.1907 248.402 79.6553C255.09 75.1237 264.385 72.2885 274.701 72.2885C285.017 72.2885 294.312 75.1237 301 79.6553C307.693 84.1907 311.686 90.3511 311.686 97.028Z"
			stroke={palette.gray[300]}
			strokeOpacity="0.7"
			strokeWidth="2"
		/>
		<path
			d="M312.686 179.196C312.686 170.995 295.68 164.347 274.701 164.347C253.722 164.347 236.716 170.995 236.716 179.196"
			stroke={palette.gray[300]}
			strokeOpacity="0.7"
			strokeWidth="2"
		/>
		<path
			d="M244.653 31.6891C244.653 37.1566 258.106 41.5888 274.701 41.5888C291.296 41.5889 304.749 37.1566 304.749 31.6891"
			stroke={palette.gray[300]}
			strokeOpacity="0.7"
			strokeWidth="2"
		/>
	</svg>
);

const FieldWrapper = styled.div`
	position: relative;
	width: 100%;
	max-width: 100vw;
	height: 100%;
	overflow: visible;
	transform: translateX(30%);
`;

const Position = styled.div`
	position: absolute;
	top: 50px;
	left: 50%;
	transform: translateX(-50%);
	height: 170px;
	width: 170px;
	border-radius: 50%;
	background: ${rgba(palette.green[500], 0.6)};
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10;

	${(props) =>
		props.active &&
		css`
			animation: ${ActiveSpot} 2000ms ease-out infinite;
		`}
`;

const PlayerIconWrapper = styled.div`
	height: 85px;
	width: 85px;
	background: ${palette.white};
	box-shadow: 0px 0px 30px ${rgba(palette.black, 0.2)};
	border-radius: var(--radius-3);
	background: ${palette.white};
	position: absolute;
	top: -35px;
	z-index: 3;
	display: flex;
	align-items: center;
	justify-content: center;

	&::after {
		content: '';
		display: block;
		width: 0;
		height: 0;
		border-left: 12px solid transparent;
		border-right: 12px solid transparent;

		border-top: 12px solid ${palette.white};

		position: absolute;
		bottom: -10px;
	}
`;

const PlayerIcon = styled.div`
	background: ${palette.blue[500]};
	height: 58px;
	width: 58px;
	border-radius: 58px;
	display: flex;
	justify-content: center;
	align-items: center;

	.${iconStyles.icon} {
		font-size: 3rem;
		color: ${palette.white};
	}
`;

const Wrapper = styled.div`
	position: relative;
	padding-top: 30px;
	overflow: visible;

	@media ${small} {
		height: 250px;
	}

	${Position} {
		transition: opacity 300ms ease-in-out 400ms;
	}

	${Spot} {
		transition: opacity 300ms ease-in-out 500ms;
	}

	${PlayerIconWrapper} {
		transition: opacity 300ms ease-in-out 600ms;
	}

	${FieldWrapper} {
		transition: opacity 300ms ease-in-out 200ms;
	}

	${Position},
	${Spot},
	${PlayerIconWrapper},
	${FieldWrapper} {
		opacity: 0;
	}

	${(props) =>
		props.active &&
		css`
			${Position},
			${Spot},
			${PlayerIconWrapper},
			${FieldWrapper} {
				opacity: 1;
			}
		`}
`;

const PositionMatch = (props) => {
	return (
		<Wrapper active={props.active}>
			<Position active={props.active}>
				<PlayerIconWrapper>
					<PlayerIcon>
						<Icon name="nav-profile" />
					</PlayerIcon>
				</PlayerIconWrapper>
				<Spot>LM</Spot>
			</Position>
			<FieldWrapper>
				<Field />
			</FieldWrapper>
		</Wrapper>
	);
};

export default PositionMatch;
