import { ReactNode } from 'react';

export const symbol_temple_hindu: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M80-140v-350q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-490v50h107l122-403.977V-890q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T429-890v50h101v-50q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T590-890v40.909L713-440h107v-50q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T880-490v350q0 24.75-17.625 42.375T820-80H560q-12.75 0-21.375-8.625T530-110v-180H430v180q0 12.75-8.625 21.375T400-80H140q-24.75 0-42.375-17.625T80-140Zm255-380h290l-30-100H365l-30 100Zm48-160h194l-30-100H413l-30 100ZM140-140h230v-150q0-24.75 17.625-42.375T430-350h100q24.75 0 42.375 17.625T590-290v150h230v-240H712q-19.594 0-35.797-12Q660-404 654-423l-11-37H317l-11 37q-6 19-22.203 31T248-380H140v240Zm340-320Z"
		/>
	</svg>
);
