import { ReactNode } from 'react';

export const symbol_total_dissolved_solids: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-800q-94 83-155 160t-87 145q-5 11-15.5 16.5T200-476q-12-4-18-14.5t-2-21.5q37-102 119.5-192.5T460-863q5-4 10-6t10-2q5 0 10.5 2t9.5 6q76 67 159.5 157.5T780-512q4 11-2 21.5T760-476q-12 3-22.5-2.5T722-495q-26-68-87-145T480-800Zm319 392q0 25-3 50t-10 49L568-91q-24 6-48.5 9T470-80l329-328Zm-213 0h86L364-100q-15-6-29.5-13T306-129l280-279Zm-213 0h86L238-188q-10-11-18.5-23.5T203-238l170-170Zm-127 0-78 78q-2-11-4-22.5t-3-22.5q-1-14 8.5-23.5T193-408h53Zm234-60Z"
		/>
	</svg>
);
