import { ReactNode } from 'react';

export const symbol_component_exchange: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m193-120-6-49-14-7q-6-3-11-7l-46 19-37-64 39-29v-29l-39-30 37-64 46 19q15-10 18-11.5l7-3.5 6-48h74l6 49q4 2 7.5 3.5t6.5 3.5q3 2 5.5 3.5t5.5 3.5l46-19 37 64-39 29q2 8 1 19t-4 15q2 2 3 2t3 2l254-131-38 69-189 97-28 50-46-19q-15 9-18 11t-7 4l-6 48h-74Zm37-220q-28 0-48 20t-20 48q0 28 20 48t48 20q28 0 48-20t20-48q0-28-20-48t-48-20Zm173-236-5-48q-6-4-12-7.5t-12-7.5l-2 1v279l-22-39-22 10v-240l-38-56 38-29v-29l-39-30 37-64 46 19q15-10 18-11.5l7-3.5 6-48h74l6 49 14 7q6 3 11 7l46-19 37 64-39 29v29l39 30-37 64-46-19q-15 9-18 11t-7 4l-6 48h-74Zm37-220q-28 0-48 20t-20 48q0 28 20 48t48 20q28 0 48-20t20-48q0-28-20-48t-48-20Zm253 676-6-49-14-7q-6-3-11-7l-46 19-37-64 39-29v-29l-39-29 37-65 46 19q6-6 10-9t9-5l3-12-235-175h40l3-23 216 161h59l6 49q4 2 7.5 3.5t6.5 3.5q3 2 5.5 3.5t5.5 3.5l46-19 37 64-39 29v29l39 30-37 64-46-19q-15 9-18 11t-7 4l-6 48h-74Zm37-220q-28 0-48 20t-20 48q0 28 20 48t48 20q28 0 48-20t20-48q0-28-20-48t-48-20Z"
		/>
	</svg>
);
