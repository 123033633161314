// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.D8aIg6lO4HL2VWc02u02 {
	cursor: pointer;
	color: var(--palette-gray-900);
	-webkit-transition: color 0.3s ease;
	transition: color 0.3s ease;
}

.D8aIg6lO4HL2VWc02u02:hover {
	color: var(--palette-blue-500);
}

.w0N_dTy5BovZZi6B_wim {
	-ms-flex-item-align: center;
	    align-self: center;
}
`, "",{"version":3,"sources":["webpack://./routes/user_fields/settings/category_fields/styles.css"],"names":[],"mappings":"AAAA;CACC,eAAe;CACf,8BAA8B;CAC9B,mCAA2B;CAA3B,2BAA2B;AAC5B;;AAEA;CACC,8BAA8B;AAC/B;;AAEA;CACC,2BAAkB;KAAlB,kBAAkB;AACnB","sourcesContent":[".headingTitle {\n\tcursor: pointer;\n\tcolor: var(--palette-gray-900);\n\ttransition: color 0.3s ease;\n}\n\n.headingTitle:hover {\n\tcolor: var(--palette-blue-500);\n}\n\n.drag {\n\talign-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
export var headingTitle = `D8aIg6lO4HL2VWc02u02`;
export var drag = `w0N_dTy5BovZZi6B_wim`;
export default ___CSS_LOADER_EXPORT___;
