import {
	FLASH_SET_VISIBILITY,
	FLASH_SET_MESSAGE,
} from 'pkg/actions/action-types';

const defaultState = {
	visible: false,
	message: null,
	icon: null,
	status: 200,
};

const flashes = (state = defaultState, action) => {
	switch (action.type) {
		case FLASH_SET_VISIBILITY: {
			const { visible } = action;

			if (visible === state.visible) return state;

			return { ...state, visible };
		}

		case FLASH_SET_MESSAGE: {
			const { message, status, icon } = action;

			const nextState = {
				message,
				status,
				icon,
			};

			return { ...state, ...nextState };
		}

		default:
			return state;
	}
};

export default flashes;
