import { ReactNode } from 'react';

export const symbol_water_lock: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M220-140v-434 434-18.75V-140Zm130-494h260v-96q0-54.167-37.882-92.083-37.883-37.917-92-37.917Q426-860 388-822.083 350-784.167 350-730v96ZM220-80q-24.75 0-42.375-17.625T160-140v-434q0-24.75 17.625-42.375T220-634h70v-96q0-78.85 55.606-134.425Q401.212-920 480.106-920T614.5-864.425Q670-808.85 670-730v96h70q24.75 0 42.375 17.625T800-574v163q0 14.5-9.318 21.75t-20.5 7.25Q759-382 749.5-389.5 740-397 740-412v-162H220v434h350q15 0 22.5 9.318t7.5 20.5Q600-99 592.5-89.5 585-80 570-80H220Zm550 0q-37 0-63.5-26.5T680-169.581q0-17.419 6-32.919 6-15.5 17-27.5l50-56q6.943-8 16.971-8Q780-294 787-286l50 56q11 12 17 27.5t6 32.919Q860-133 833.9-106.5T770-80ZM480.168-280Q512-280 534.5-302.031T557-355q0-30-22.668-54.5t-54.5-24.5Q448-434 425.5-409.5t-22.5 55q0 30.5 22.668 52.5t54.5 22Z"
		/>
	</svg>
);
