import styled, { keyframes, css } from 'styled-components';

import * as palette from 'pkg/config/palette';
import * as breakpoints from 'pkg/config/breakpoints';

import rgba from 'pkg/rgba';

const fadeIn = keyframes`
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
`;

const fadeOut = keyframes`
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
`;

/**
 * @type {React.Element<any>}
 */
const Backdrop = styled.div`
	display: flex;
	justify-content: center;
	align-items: flex-start;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: ${({ index }) => index - 1};
	background: ${rgba(palette.black, 0.3)};
	animation: ${(props) =>
			props.animationDirection === 'in' ? fadeIn : fadeOut}
		100ms ease-out;

	${(props) =>
		props.animationDirection === 'in'
			? css`
					animation-name: ${fadeIn};
				`
			: css`
					pointer-events: none;
					opacity: 0;
					animation-name: ${fadeOut};
				`};

	@media ${breakpoints.small} {
		overflow: hidden;
	}
`;

Backdrop.defaultProps = {
	animationDirection: 'in',
};

export default Backdrop;
