import { ReactNode } from 'react';

export const symbol_raven: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m391-244-56 134q-5 11-16.5 15.5T296-95q-11-5-16-16t0-22l49-118q-110-24-179.5-111.5T80-562.242V-727q0-64 45.238-108.5Q170.475-880 234-880q17.387 0 33.194 4Q283-872 299-865l199 82q10 3.75 9.5 13.875T497-755l-110 41v76l442 281q5 3 8.6 7.952 3.6 4.953 5.4 11.048l28 134q4 17-7.5 30.5T835-160h-12q-11.407 0-19.704-5.5Q795-171 790-181l-28-63H552v134q0 12.75-8.675 21.375Q534.649-80 521.825-80 509-80 500.5-88.625T492-110v-134H391ZM234.175-697q-12.824 0-21.5-8.675-8.675-8.676-8.675-21.5Q204-740 212.675-748q8.676-8 21.5-8Q247-756 255-748t8 20.825q0 12.824-8 21.5Q247-697 234.175-697ZM398-368Zm0 64h402l-101-64H398q-37.818 0-70.909-14.5Q294-397 271-422q-20-21-31.5-48T228-528q0-12.75 8.625-21.375T258-558q13 0 21.5 8.5T288-528q0 43 32.5 71.5T398-428h207L327-605v-122q0-38.362-27.451-65.681-27.452-27.319-66-27.319Q195-820 167.5-793 140-766 140-727v165q0 107.5 75.25 182.75T398-304Z"
		/>
	</svg>
);
