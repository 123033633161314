import { Record } from 'immutable';

import {
	AUTH_GET_ME_SUCCESS,
	APP_API_ERROR,
	APP_RESET_API_ERROR,
	APP_SET_NAVBAR_HEIGHT,
	APP_SET_URL,
	APP_SET_TABBAR_HEIGHT,
	APP_SET_DARK_MODE,
	AUTH_LOGOUT_SUCCESS,
	APP_SET_TABBAR_VISIBILITY,
	APP_SET_PARENTAL_CONSENT,
	APP_SET_FORCE_NAVIGATE_BACK_LINK,
	APP_SET_COLLAPSED_DRAWER,
	APP_SET_DRAWER_VISIBILITY,
} from 'pkg/actions/action-types';

import { ApiError } from 'pkg/errors/errors';

interface AppState {
	apiError: any;
	maintenanceMode: boolean;
	url: string;
	appActionsUpdatedAt: number;
	parentalConsentIsRequired: boolean;
	collapsedDrawer: boolean;
	visibleDrawer: boolean;
	darkMode: boolean;
	navBarHeight: number;
	tabBarHeight: number;
	tabBarVisible: boolean;
	// List of user IDs belonging to the current accoun;
	activeAccountUserIds: number[];
	// Current membership mode;
	forceNavBarBackLink: '';
}

class AppReducer
	extends Record<AppState>(
		{
			apiError: null,
			maintenanceMode: false,
			url: location.pathname + location.search + location.hash,

			appActionsUpdatedAt: Date.now(),

			parentalConsentIsRequired: false,
			collapsedDrawer: window.matchMedia('(max-width: 950px)').matches,
			visibleDrawer: true,
			darkMode: false,
			navBarHeight: 0,
			tabBarHeight: 0,
			tabBarVisible: true,
			// List of user IDs belonging to the current account
			activeAccountUserIds: [],

			forceNavBarBackLink: '',
		},
		'appReducer'
	)
	implements AppState
{
	apiError: any;
	maintenanceMode: boolean;
	url: string;
	appActionsUpdatedAt: number;
	parentalConsentIsRequired: boolean;
	collapsedDrawer: boolean;
	visibleDrawer: boolean;
	darkMode: boolean;
	navBarHeight: number;
	tabBarHeight: number;
	tabBarVisible: boolean;
	// List of user IDs belonging to the current accoun;
	activeAccountUserIds: number[];
	// Current membership mode;
	forceNavBarBackLink: '';
}

const initialState = new AppReducer();

const setMe = (state: AppReducer, action: any) => {
	const { needsParentalConsent } = action.payload;

	state = state.set('parentalConsentIsRequired', needsParentalConsent);

	if (action.payload.users) {
		state = state.set(
			'activeAccountUserIds',
			action.payload.users.map((u: any) => u.id)
		);
	}

	return state;
};

const app = (state = initialState, action: any) => {
	switch (action.type) {
		case APP_RESET_API_ERROR: {
			return state.set('apiError', null);
		}

		case APP_SET_COLLAPSED_DRAWER: {
			return state.set('collapsedDrawer', action.collapsedDrawer);
		}

		case APP_SET_DRAWER_VISIBILITY: {
			return state.set('visibleDrawer', action.visibleDrawer);
		}

		case APP_API_ERROR: {
			const { request, response } = action;
			let { apiError, parentalConsentIsRequired } = state;

			if (response && response.parentalConsentIsRequired) {
				apiError = null;
				parentalConsentIsRequired = true;
			} else {
				apiError = new ApiError(request.status);
			}

			return state
				.set('apiError', apiError)
				.set('parentalConsentIsRequired', parentalConsentIsRequired);
		}

		case APP_SET_PARENTAL_CONSENT:
			return state.set('parentalConsentIsRequired', action.payload);

		case APP_SET_URL:
			return state.set('url', action.url);

		case APP_SET_TABBAR_VISIBILITY:
			return state.set('tabBarVisible', action.tabBarVisible);

		case APP_SET_NAVBAR_HEIGHT:
			return state.set('navBarHeight', action.navBarHeight);

		case APP_SET_TABBAR_HEIGHT:
			return state.set('tabBarHeight', action.height);

		case AUTH_GET_ME_SUCCESS:
			return setMe(state, action);

		case APP_SET_DARK_MODE:
			return state.set('darkMode', action.darkMode);

		case APP_SET_FORCE_NAVIGATE_BACK_LINK:
			return state.set('forceNavBarBackLink', action.payload);

		case AUTH_LOGOUT_SUCCESS:
			return initialState;
	}

	return state;
};

export default app;
