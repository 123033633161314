import { ReactNode } from 'react';

export const symbol_tv_options_edit_channels: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M265-330q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T265-390h101q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T366-330H265Zm0-165q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T265-555h481q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T746-495H265Zm0-165q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T265-720h481q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T746-660H265Zm375.867 487Q635-173 630-175q-5-2-10-7L497-307q-9-9-9-21t9.053-21q9.052-9 21.5-9Q531-358 540-349l102 103 214-214q9-9 21-9t21.391 9Q907-451 907-438.5t-9 21.5L662-181q-5 5-10.133 6.5-5.134 1.5-11 1.5ZM109.825-660Q97-660 88.5-668.675q-8.5-8.676-8.5-21.5Q80-703 88.675-711.5q8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Zm0 165Q97-495 88.5-503.675q-8.5-8.676-8.5-21.5Q80-538 88.675-546.5q8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Zm0 165Q97-330 88.5-338.675q-8.5-8.676-8.5-21.5Q80-373 88.675-381.5q8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Z"
		/>
	</svg>
);
