import { ReactNode } from 'react';

export const symbol_hard_drive_2: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M540-200q17 0 28.5-11.5T580-240q0-17-11.5-28.5T540-280q-17 0-28.5 11.5T500-240q0 17 11.5 28.5T540-200Zm140 0q17 0 28.5-11.5T720-240q0-17-11.5-28.5T680-280q-17 0-28.5 11.5T640-240q0 17 11.5 28.5T680-200ZM120-430v-390q0-24 18-42t42-18h600q24 0 42 18t18 42v390h-60v-390H180v390h-60Zm60 60v230h600v-230H180Zm0 290q-24 0-42-18t-18-42v-290h720v290q0 24-18 42t-42 18H180Zm0-350h600-600Zm0 60h600-600Z"
		/>
	</svg>
);
