import { ReactNode } from 'react';

export const symbol_sip: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M483.175-357q12.825 0 21.325-8.625T513-387v-186q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T453-573v186q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625Zm114.941 0Q609-357 616-364.083q7-7.084 7-17.917v-64h110q14 0 27-13.5t13-27.5v-75q0-14-13-27.5T733-603H588q-6 0-10.5 4.5T573-588v206q0 10.833 7.116 17.917 7.117 7.083 18 7.083ZM211-357h135q14 0 27-13.5t13-27.5v-66q0-14-13-27.5T346-505H236v-48h125q10.833 0 17.917-7.116 7.083-7.117 7.083-18Q386-589 378.917-596q-7.084-7-17.917-7H226q-14 0-27 13.5T186-562v66q0 14 13 27.5t27 13.5h110v48H211q-10.833 0-17.917 7.116-7.083 7.117-7.083 18Q186-371 193.083-364q7.084 7 17.917 7Zm412-139v-57h100v57H623ZM140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm0-60h680v-520H140v520Zm0 0v-520 520Z"
		/>
	</svg>
);
