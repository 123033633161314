import { ReactNode } from 'react';

export const symbol_visibility_lock: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M479.765-388Q433-388 400.5-420.735q-32.5-32.736-32.5-79.5Q368-547 400.735-579.5q32.736-32.5 79.5-32.5Q527-612 559.5-579.265q32.5 32.736 32.5 79.5Q592-453 559.265-420.5q-32.736 32.5-79.5 32.5ZM480-200q-140 0-253-75T53-473q-3-6.326-4.5-13.047-1.5-6.72-1.5-13.837Q47-507 48.5-513.8 50-520.6 53-527q61-123 174-198t253-75q128 0 235.5 64.5T889-562q6 11 3 22.5T878-523q-11 5-22.5 1.5T838-536q-57-94-152.203-149Q590.595-740 480-740q-121.232 0-222.616 65.5Q156-609 102-500q54 109 155.384 174.5Q358.768-260 480-260q16 0 30.667-1 14.666-1 29.333-3 12-2 21 5.514 9 7.515 9 18.486 0 13.6-8.625 24T540-204q-15 2-30 3t-30 1Zm0-130q26.19 0 50.595-7.5Q555-345 575-359q8-34 26.5-63t45.5-49q2-8 2.5-14.5t.5-14.5q0-70.833-49.618-120.417Q550.765-670 479.882-670 409-670 359.5-620.382q-49.5 49.617-49.5 120.5Q310-429 359.583-379.5 409.167-330 480-330Zm-3-170Zm208 380q-14 0-24.5-10.5T650-155v-120q0-14 11.5-24.5T690-310v-45q0-30.938 22.044-52.969 22.045-22.031 53-22.031Q796-430 818-407.969q22 22.031 22 52.969v45q17 0 28.5 10.5T880-275v120q0 14-10.5 24.5T845-120H685Zm35-190h90v-44.912Q810-374 797.088-387q-12.913-13-32-13Q746-400 733-387.062 720-374.125 720-355v45Z"
		/>
	</svg>
);
