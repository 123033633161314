import { Fragment, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';

import { toMedium } from 'pkg/config/breakpoints';

import { getWeekDates } from 'pkg/date';
import { useCollection } from 'pkg/api/use_collection';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import { useEndpoint } from 'pkg/api/use_endpoint';
import { useQueryState } from 'pkg/hooks/query-state';
import { cssVarList } from 'pkg/css/utils';
import DateTime from 'pkg/datetime';

import { ViewProps } from 'routes/group/calendar';
import BookingsActionBar from 'routes/scheduling/bookings/ActionBar';
import {
	BookingsContext,
	DefaultBookingsContext,
} from 'routes/scheduling/bookings';
import { defaultFilters } from 'routes/scheduling/templates/filters';
import PublishedPeriods from 'routes/scheduling/bookings/published_periods';
import GroupGrid from 'routes/scheduling/bookings/groups/Grid';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import * as SchedulingLayout from 'components/scheduling/PageLayout';
import WeekDay from 'components/scheduling/WeekDay';
import { Spinner } from 'components/loaders/spinner';
import { TemplateWeekWrapper } from 'components/scheduling/TemplateWeekWrapper';
import { SchedulingViews } from 'components/scheduling/SelectView';
import * as headerRowCss from 'components/scheduling/grid/header-row/styles.css';

interface BookingsProps {
	to: number;
	from: number;
	currentDate: number;
	group: models.group.Group;
}

const Bookings = ({ to, from, currentDate, group }: BookingsProps) => {
	const qs = useQueryState();
	const qsFilters = qs.get('schedulingFilters');
	const filters =
		qsFilters !== 'undefined' && qsFilters
			? JSON.parse(qsFilters as string)
			: defaultFilters;
	const timestampDate = new Date(currentDate);
	const isSmallScreen = useMediaQuery({ maxWidth: toMedium });

	const weekDates = useMemo(() => getWeekDates(currentDate), [currentDate]);
	const startOfWeek = new DateTime(weekDates[0]);
	const endOfWeek = new DateTime(weekDates[6]);
	const startOfWeekTimestamp = Math.round(startOfWeek.startOfDay / 1000);
	const endOfWeekTimestamp = Math.floor(endOfWeek.endOfDay / 1000);

	const bookingCollection = useCollection<models.booking.Booking>(
		endpoints.Booking.Index(),
		{
			queryParams: new URLSearchParams({
				by_group_id: group.organizationId?.toString(),
				from: from.toString(),
				to: to.toString(),
			}),
		}
	);
	const resourceCollection = useCollection<models.resource.Resource>(
		endpoints.Resource.Index(),
		{
			queryParams: new URLSearchParams({
				group_id: group.organizationId?.toString(),
			}),
		}
	);

	const groupCollection = useCollection<models.group.Group>(
		endpoints.Groups.ShowChildren(group.organizationId),
		{
			queryParams: new URLSearchParams({
				recursive: 'true',
			}),
		}
	);

	const organization = models.group.isOrganization(group)
		? group
		: group.organization;

	const { records: schedules } = useCollection<models.schedule.Schedule>(
		endpoints.Schedule.Index(),
		{
			queryParams: new URLSearchParams({
				group_id: organization.id.toString(),
			}),
		}
	);

	const { records: publishedPeriods, refresh: refreshPublishedPeriods } =
		useCollection<models.schedule.PublishedPeriod>(
			endpoints.Schedule.PublishedPeriods(),
			{
				queryParams: new URLSearchParams({
					group_id: organization.id.toString(),
					from: startOfWeekTimestamp.toString(),
					to: endOfWeekTimestamp.toString(),
				}),
			}
		);

	const flattenedGroups = models.group.buildGroupTree(
		organization,
		groupCollection.records,
		true
	) as models.group.Group[];
	// We need non flattened groups for the select groups filter
	const groups = models.group.buildGroupTree(
		organization,
		groupCollection.records
	);

	if (
		bookingCollection.isLoading ||
		resourceCollection.isLoading ||
		groupCollection.isLoading
	) {
		return <Spinner />;
	}

	const content = (
		<Fragment>
			<BookingsActionBar
				groups={groups}
				resources={resourceCollection.records}
				bookings={bookingCollection.records}
				timestampDate={timestampDate}
			/>
			<SchedulingLayout.Container>
				<SchedulingLayout.Wrapper>
					<TemplateWeekWrapper>
						<PublishedPeriods />
						<div
							className={headerRowCss.wrapper}
							style={cssVarList({
								cols: 7,
							})}>
							<LargeScreen>
								<WeekDay />
							</LargeScreen>
							{weekDates.map((wd, i) => (
								<WeekDay key={i} day={wd} />
							))}
						</div>
					</TemplateWeekWrapper>
					<GroupGrid />
				</SchedulingLayout.Wrapper>
			</SchedulingLayout.Container>
		</Fragment>
	);

	return (
		<BookingsContext.Provider
			value={{
				...DefaultBookingsContext,
				calendarView: true,
				flattenedGroups: flattenedGroups,
				groups,
				resources: resourceCollection.records,
				bookings: bookingCollection.records,
				refreshBookings: bookingCollection.refresh,
				view: SchedulingViews.Group,
				weekDates,
				filters,
				groupId: group.organizationId,
				isSmallScreen,
				conflicts: {},
				schedules,
				publishedPeriods,
				refreshPublishedPeriods,
			}}>
			<LargeScreen>
				<SchedulingLayout.ContentWrapper templateRows="auto auto 1fr">
					{content}
				</SchedulingLayout.ContentWrapper>
			</LargeScreen>
			<SmallScreen>{content}</SmallScreen>
		</BookingsContext.Provider>
	);
};

const BookingsView = ({ to, from, currentDate, groupId }: ViewProps) => {
	const group = useEndpoint<models.group.Group>(endpoints.Groups.Show(groupId));

	if (group.isLoading) {
		return <Spinner />;
	}

	return (
		<Bookings
			to={to}
			from={from}
			currentDate={currentDate}
			group={group.record}
		/>
	);
};

export default BookingsView;
