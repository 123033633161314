import { Map, Record } from 'immutable';
import { schema } from 'normalizr';
import { t } from '@transifex/native';

import Group from 'pkg/models/group';
import * as schemas from 'pkg/models/schemas';
import Scrubbable from 'pkg/models/scrubbable';

import { only } from 'pkg/objects';
import { yearsSince } from 'pkg/date';

export enum UserSex {
	Unknown = 0,
	Male = 1,
	Female = 2,
	NotApplicable = 9,
}

export interface GroupsProps {
	id: number;
	role?: string | number;
	hasLicense?: boolean;
}

export interface IUser extends Scrubbable {
	id?: number;
	accountId?: number;
	organizationId: number;
	billingUserId?: number;
	firstName: string;
	lastName?: string;
	companyName?: string;
	email?: string;
	sex?: UserSex;
	address?: string;
	region?: string;
	postalCode?: string;
	city?: string;
	country: string;
	phoneNumber?: string;
	birthDate?: string;
	profileImageUrl?: string;
	updatedAt?: number;
	createdAt?: number;
	deletedAt?: number;
	meta?: any;
	flags?: string[];
	groups?: string[];
	account?: any;
	paymentProviderId?: any;
	taxIdType?: string;
	taxIdValue?: string;
	language?: string;
	languageCode?: string;
	nationality?: string;
	lokSwedishPersonalId?: string;
	lokValid?: boolean;

	addresses: any[];
	billingUser: User;
	organization: Group;

	/**
	 *  Ugly workaround to avoid import issues to new models. @see models.user.Field
	 */
	fields: any[];
}

const UserProps: IUser = {
	id: 0,
	accountId: 0,
	organizationId: 0,
	billingUserId: 0,
	firstName: '',
	lastName: '',
	companyName: '',
	email: '',
	sex: UserSex.Unknown,
	address: '',
	region: '',
	postalCode: '',
	city: '',
	country: '',
	phoneNumber: '',
	birthDate: '',
	profileImageUrl: '',
	updatedAt: 0,
	createdAt: 0,
	deletedAt: 0,
	meta: [],
	flags: [],
	fields: [],
	groups: [],
	account: null,
	paymentProviderId: null,
	taxIdType: '',
	taxIdValue: '',
	language: '',
	languageCode: '',
	nationality: '',
	lokSwedishPersonalId: '',
	lokValid: false,
	addresses: [],
	billingUser: null,
	organization: null,

	_s: undefined,
};

class User extends Record(UserProps, 'UserRecord') implements IUser {
	constructor(args: any) {
		if (!args) args = {};

		if (Array.isArray(args.meta)) {
			args.meta = Map(
				args.meta.map(({ key, value }: { key: number; value: any }) => [
					key,
					value,
				])
			);
		} else if (args.meta) {
			args.meta = Map(args.meta);
		} else {
			args.meta = Map();
		}

		super(args);
	}

	isScrubbed(): boolean {
		return !!this.get('_s');
	}

	get fullName(): string {
		return `${this.get('firstName')} ${this.get('lastName')}`;
	}

	get sexString(): string {
		let sexString = 'not_set';

		if (this.get('sex') === UserSex.Male) {
			sexString = 'male';
		} else if (this.get('sex') === UserSex.Female) {
			sexString = 'female';
		} else if (this.get('sex') === UserSex.NotApplicable) {
			sexString = 'not_applicable';
		}

		return sexString;
	}

	get translatedSexString(): string {
		if (this.get('sex') === UserSex.Male) {
			return t('Male');
		} else if (this.get('sex') === UserSex.Female) {
			return t('Female');
		} else if (this.get('sex') === UserSex.NotApplicable) {
			return t('Not applicable');
		}

		return t('Not set');
	}

	get initials(): string {
		return [
			this.get('firstName').substring(0, 1),
			this.get('lastName').substring(0, 1),
		].join('');
	}

	getProfileImageUrl(): string {
		if (this.get('profileImageUrl')) {
			return this.get('profileImageUrl');
		}

		return (this.getIn(['meta', 'profile-image-url']) as string) || '';
	}

	get age(): number {
		return yearsSince(new Date(this.get('birthDate')));
	}

	get isAnAdult(): boolean {
		return this.age >= 18;
	}

	get hasHandicap(): boolean {
		return this.get('flags').includes('has_handicap');
	}

	get height(): string {
		return this.getIn(['meta', 'height']) as string;
	}

	getEstimatedHeight(): number {
		const checkFormat = (rawValue: string): number => {
			rawValue = `${Math.round(parseFloat(rawValue))}`;
			if (Number.isNaN(rawValue.charAt(1))) {
				rawValue = rawValue.replace(/\D/g, '');
			}
			return Number.parseInt(rawValue, 10);
		};

		const parents = {
			mother: checkFormat(this.getIn(['meta', 'mother-height']) as string),
			father: checkFormat(this.getIn(['meta', 'father-height']) as string),
		};

		if (!parents.mother || !parents.father) {
			return 0;
		}

		if (!this.sex || this.sex === UserSex.NotApplicable) {
			return 0;
		}

		return (
			(parents.mother +
				parents.father +
				(this.sex === UserSex.Male ? 13 : -13)) /
			2
		);
	}

	get allergies(): string {
		return this.getIn(['meta', 'allergies']) as string;
	}

	get legalGuardianFor(): string {
		return this.getIn(['meta', 'legal-guardian-for']) as string;
	}

	get misc(): string {
		return this.getIn(['meta', 'misc']) as string;
	}

	get getBillingEmail() {
		return this.addresses?.length > 0
			? this.addresses.filter((a) => a.type === 'billing')[0].email
			: this.email;
	}

	getPayload(): any {
		const user = this.set(
			'meta',
			this.get('meta')
				.map((value: any, key: number) => ({
					key,
					value,
				}))
				.toList()
		);

		return only(
			user.toJS(),
			'firstName',
			'lastName',
			'email',
			'birthDate',
			'meta'
		);
	}

	static normalizr(): schema.Entity {
		return schemas.users;
	}
}

export default User;
