export function AccountIndex(accountID: number): string {
	return `/v1/accounts/${accountID}/events`;
}

export function AccountIndex1(): string {
	return `/v1/me/events`;
}

export function AccountIndexICS(): string {
	return `/v1/ics/me/events.ics`;
}

export function AddAttachment(eventID: number, attachmentID: number): string {
	return `/v1/events/${eventID}/attachments/${attachmentID}`;
}

export function AddComment(eventID: number): string {
	return `/v1/events/${eventID}/comments`;
}

export function AddTrainingSession(eventID: number, sessionID: number): string {
	return `/v1/events/${eventID}/sessions/${sessionID}`;
}

export function AddUsers(eventID: number): string {
	return `/v1/events/${eventID}/users`;
}

export function Create(): string {
	return `/v1/events`;
}

export function Delete(eventID: number): string {
	return `/v1/events/${eventID}`;
}

export function DeleteComment(ID: number): string {
	return `/v1/event-comments/${ID}`;
}

export function DeprecatedListPending(accountID: number): string {
	return `/v1/accounts/${accountID}/pending-events`;
}

export function Export(): string {
	return `/v1/events/export`;
}

export function GroupEventsICS(groupID: number): string {
	return `/v1/ics/groups/${groupID}/events.ics`;
}

export function GroupIndex(groupID: number): string {
	return `/v1/groups/${groupID}/events`;
}

export function Index(): string {
	return `/v1/events`;
}

export function IndexEventsICS(): string {
	return `/v1/ics/events.ics`;
}

export function ListPending(): string {
	return `/v1/events/pending`;
}

export function RemoveAttachment(
	eventID: number,
	attachmentID: number
): string {
	return `/v1/events/${eventID}/attachments/${attachmentID}`;
}

export function RemoveUsers(eventID: number): string {
	return `/v1/events/${eventID}/users`;
}

export function Show(eventID: number): string {
	return `/v1/events/${eventID}`;
}

export function ShowComments(eventID: number): string {
	return `/v1/events/${eventID}/comments`;
}

export function ShowTrainingSessions(eventID: number): string {
	return `/v1/events/${eventID}/sessions`;
}

export function ShowTrainingSessions1(eventID: number): string {
	return `/v1/events/${eventID}/training-sessions`;
}

export function ShowUsers(eventID: number): string {
	return `/v1/events/${eventID}/users`;
}

export function Update(eventID: number): string {
	return `/v1/events/${eventID}`;
}

export function UpdateBatch(groupID: number): string {
	return `/v1/events/${groupID}`;
}

export function UpdateComment(ID: number): string {
	return `/v1/event-comments/${ID}`;
}

export function UpdateUsers(eventID: number): string {
	return `/v1/events/${eventID}/users`;
}
