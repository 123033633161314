import { ReactNode } from 'react';

export const symbol_share_windows: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M329.825-370Q317-370 308.5-378.625T300-400v-210q0-24.75 17.625-42.375T360-670h350l-92-92q-9-9-8.5-21t9.342-21q8.842-9 21-9T661-804l143 143q9 9 9 21t-9 21L661-475q-9 9-21 9t-21.12-9.053q-9.12-9.052-9.5-21.5Q609-509 618-518l92-92H360v210q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM180-120q-24.75 0-42.375-17.625T120-180v-610q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T180-790v610h520v-160q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T760-340v160q0 24.75-17.625 42.375T700-120H180Z"
		/>
	</svg>
);
