import { ReactNode } from 'react';

export const symbol_download: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M479.867-325Q474-325 469-327q-5-2-10-7L308-485q-9-9.273-8.5-21.636Q300-519 308.609-528q9.391-9 21.891-9 12.5 0 21.5 9l98 99v-341q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-770v341l99-99q8.8-9 20.9-8.5 12.1.5 21.491 9.5Q660-518 660-505.5t-9 21.5L501-334q-5 5-10.133 7-5.134 2-11 2ZM220-160q-24 0-42-18t-18-42v-113q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T220-333v113h520v-113q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T800-333v113q0 24-18 42t-42 18H220Z"
		/>
	</svg>
);
