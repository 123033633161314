import { ReactNode } from 'react';

export const symbol_mode_comment: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-240q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v668q0 19.688-18.5 27.344Q843-117 829-131L720-240H140Zm606-60 74 80v-600H140v520h606Zm-606 0v-520 520Z"
		/>
	</svg>
);
