import { ReactNode } from 'react';

export const symbol_timer_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-801q68 0 126.5 22.5T711-716l30-30q9-9 21-9t21 9q9 9 9 21t-9 21l-30 30q45 49 66 108.5T840-441q0 38-7.5 75T808-294q-8 17-20 21t-23-1q-11-5-16-14.5t0-20.5q14-27 22.5-60.5T780-441q0-125-87.5-212.5T480-741q-37 0-71.5 8.5T347-710q-11 6-21 1t-15-16q-5-11-1.5-23t20.5-20q35-17 73.5-25t76.5-8Zm30 276v-88q0-13-8.5-21.5T480-643q-13 0-21.5 8.5T450-613v28l60 60ZM480-81q-74 0-139.5-28.5T226-187q-49-49-77.5-114.5T120-441q0-61 18.5-116T199-665L78-786q-9-9-9-21.5t9-21.5q9-9 21.5-9t21.5 9l709 709q9 9 9 21t-9 21q-9 9-21.5 9T787-78l-83-82q-48 38-103.5 58.5T480-81Zm0-60q52 0 98.5-16t85.5-47L243-625q-31 38-47 86t-16 98q0 125 87.5 212.5T480-141Zm-90-719q-13 0-21.5-8.5T360-890q0-13 8.5-21.5T390-920h180q13 0 21.5 8.5T600-890q0 13-8.5 21.5T570-860H390Zm64 445Zm83-83Z"
		/>
	</svg>
);
