import styled from 'styled-components';

import * as styles from 'pkg/config/styles';
import * as breakpoints from 'pkg/config/breakpoints';

import * as models from 'pkg/api/models';

import { LargeScreen } from 'components/MediaQuery';
import Icon from 'components/icon';
import Avatar from 'components/avatar';

import UserActions from 'components/navigation/UserActions';
import * as iconStyles from 'components/icon/styles.css';

import * as ContextMenu from 'design/context_menu';

const UserWrapper = styled.div`
	display: grid;
	grid-template-columns: auto auto auto;
	gap: var(--spacing-3);
	align-items: center;
	height: 50px;
	padding: 0 var(--spacing-3) 0 var(--spacing-4);
	border-radius: var(--radius-5);
	box-shadow: 0 0 0 1px var(--palette-group-accent-color-hover);

	&:hover {
		background: var(
			--palette-group-accent-color-hover,
			rgb(--palette-gray-600 0.5)
		);
	}

	@media ${breakpoints.small} {
		height: 58px;
		padding-right: var (--spacing-2);
	}

	${Avatar} {
		width: 24px;

		@media ${breakpoints.small} {
			width: 34px;
		}
	}

	.${iconStyles.icon} {
		color: ${({ theme }) => theme.accentColor || styles.palette.white};
		font-size: var(--font-size-xl);
	}

	@media ${breakpoints.extraLarge} {
		height: 54px;
	}
`;

const FirstName = styled.span`
	color: ${({ theme }) => theme.accentColor || styles.palette.white};
	font-size: var(--font-size-sm);
	font-weight: var(--font-weight-semibold);
`;

interface UserItemProps {
	user?: models.user.User;
	account?: models.account.Account;
	missingUserInformation?: boolean;
	archivedUserScreen?: boolean;
}

export default function UserMenuItem({
	user,
	account,
}: UserItemProps): JSX.Element {
	if (!user?.id && !account?.id) {
		return null;
	}

	return (
		<ContextMenu.Menu
			appearFrom={ContextMenu.AppearFrom.TopRight}
			offsetHorizontal={4}
			offsetVertical={58}
			toggleWith={
				<UserWrapper data-testid="user_dropdown.trigger">
					<Avatar account={account} />
					<LargeScreen>
						<FirstName>{account.firstName}</FirstName>
					</LargeScreen>
					<Icon name="chevron" rotate="90deg" />
				</UserWrapper>
			}>
			<UserActions />
		</ContextMenu.Menu>
	);
}
