import { ReactNode } from 'react';

export const symbol_sprinkler: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-478q-17.425 0-29.212-11.788Q439-501.575 439-519q0-17.425 11.788-29.213Q462.575-560 480-560q17.425 0 29.212 11.787Q521-536.425 521-519t-11.788 29.212Q497.425-478 480-478Zm0-160q-17.425 0-29.212-11.787Q439-661.575 439-679t11.788-29.213Q462.575-720 480-720q17.425 0 29.212 11.787Q521-696.425 521-679t-11.788 29.213Q497.425-638 480-638Zm0-160q-17.425 0-29.212-11.787Q439-821.575 439-839t11.788-29.213Q462.575-880 480-880q17.425 0 29.212 11.787Q521-856.425 521-839t-11.788 29.213Q497.425-798 480-798Zm119 399q-17.425 0-29.213-11.788Q558-422.575 558-440q0-17.425 11.787-29.212Q581.575-481 599-481t29.213 11.788Q640-457.425 640-440q0 17.425-11.787 29.212Q616.425-399 599-399Zm113-114q-17.425 0-29.213-11.787Q671-536.575 671-554t11.787-29.213Q694.575-595 712-595t29.213 11.787Q753-571.425 753-554t-11.787 29.213Q729.425-513 712-513Zm113-112q-17.425 0-29.213-11.787Q784-648.575 784-666t11.787-29.213Q807.575-707 825-707t29.213 11.787Q866-683.425 866-666t-11.787 29.213Q842.425-625 825-625ZM361-399q-17.425 0-29.212-11.788Q320-422.575 320-440q0-17.425 11.788-29.212Q343.575-481 361-481q17.425 0 29.212 11.788Q402-457.425 402-440q0 17.425-11.788 29.212Q378.425-399 361-399ZM248-513q-17.425 0-29.212-11.787Q207-536.575 207-554t11.788-29.213Q230.575-595 248-595q17.425 0 29.212 11.787Q289-571.425 289-554t-11.788 29.213Q265.425-513 248-513ZM135-625q-17.425 0-29.213-11.787Q94-648.575 94-666t11.787-29.213Q117.575-707 135-707t29.212 11.787Q176-683.425 176-666t-11.788 29.213Q152.425-625 135-625Zm315 375H320q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T320-310h320q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T640-250H510v140q0 12.75-8.675 21.375Q492.649-80 479.825-80 467-80 458.5-88.625T450-110v-140Z"
		/>
	</svg>
);
