import { ReactNode } from 'react';

export const symbol_align_stretch: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M320-570q-12.75 0-21.375-8.625T290-600v-220H110q-12.75 0-21.375-8.675Q80-837.351 80-850.175 80-863 88.625-871.5T110-880h740q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-820H670v220q0 12.75-8.625 21.375T640-570H320ZM110-80q-12.75 0-21.375-8.675Q80-97.351 80-110.175 80-123 88.625-131.5T110-140h180v-220q0-12.75 8.625-21.375T320-390h320q12.75 0 21.375 8.625T670-360v220h180q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q880-97 871.375-88.5T850-80H110Z"
		/>
	</svg>
);
