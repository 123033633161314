import { t } from '@transifex/native';
import { Fragment, useState } from 'react';

import spacing from 'pkg/config/spacing';

import { useEndpoint } from 'pkg/api/use_endpoint';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import Link from 'pkg/router/Link';
import { useCurrentGroup } from 'pkg/identity';

import {
	OrderReportContext,
	usePaymentsDateFilter,
} from 'routes/payments/overview/hooks';
import RequiresActionWidget from 'routes/payments/overview/widgets/requires_action';
import EstimatedPaymentsWidget from 'routes/payments/overview/widgets/estimated_payments';
import OrderStatusWidget from 'routes/payments/overview/widgets/order_status';
import CreatedOrdersWidget from 'routes/payments/overview/widgets/created_orders';
import PaymentDueTimings from 'routes/payments/overview/widgets/payment_due_timings';
import AveragePaymentsDueWidget from 'routes/payments/overview/widgets/average_payments_due';
import AveragePaymentsOverWidget from 'routes/payments/overview/widgets/average_payments_overdue';
import PaymentTotalWidget from 'routes/payments/overview/widgets/payment_total';
import RefundsWidget from 'routes/payments/overview/widgets/refunds';
import NewSubscriptionsWidget from 'routes/payments/overview/widgets/new_subscriptions';
import TotalNetWidget from 'routes/payments/overview/widgets/total_net';
import SubscriptionsWidget from 'routes/payments/overview/widgets/subscriptions';
import EstimatedRevenueWidget from 'routes/payments/overview/widgets/estimated_revenue';
import Help from 'routes/payments/overview/help';
import SoldProductsTableWidget from 'routes/payments/overview/widgets/sold_products_table';
import SoldProductsGraphWidget from 'routes/payments/overview/widgets/sold_products_graph';

import SectionTitle from 'components/SectionTitle';
import Icon from 'components/icon';
import * as StepModal from 'components/step-modal';

import Column from 'components/layout/column';
import { Spinner } from 'components/loaders/spinner';
import Row from 'components/layout/row';

import * as css from './styles.css';

export default function PaymentsOverview(): JSX.Element {
	const group = useCurrentGroup();

	const [showHelpModal, setShowHelpModal] = useState<boolean>(false);
	const { DateFilter, startOfRange, endOfRange } = usePaymentsDateFilter();

	const {
		isInitialLoad,
		isLoading,
		record: data,
	} = useEndpoint<models.orderReport.OrderReport>(
		endpoints.Organizations.PaymentDashboard(group.id),
		{
			queryParams: new URLSearchParams({
				from_date: startOfRange.toString(),
				to_date: endOfRange.toString(),
			}),
		}
	);

	const showHelp = () => setShowHelpModal(true);
	const hideHelp = () => setShowHelpModal(false);

	const orderReport = {
		...data,
		isLoading,
		startOfRange,
		endOfRange,
	};

	const hasProducts =
		Object.values(data?.soldProducts?.products || {}).length > 0;

	if (isInitialLoad) {
		return <Spinner />;
	}

	return (
		<Fragment>
			<OrderReportContext.Provider value={orderReport}>
				<main className={css.wrapper}>
					<Column spacing={spacing._9}>
						<section>
							<Column>
								<SectionTitle>{t("Today's insights")}</SectionTitle>

								<Row collapseOnSmallScreens align="stretch">
									<RequiresActionWidget />
									<EstimatedPaymentsWidget />
									<EstimatedRevenueWidget />
								</Row>
							</Column>
						</section>

						<Column>
							<Column>
								<Row columns="1fr auto" collapseOnSmallScreens>
									<Row
										columns="auto auto"
										align="center"
										justifyContent="start"
										spacing={spacing._3}>
										<SectionTitle>{t('Payments report')}</SectionTitle>
										<Link onClick={showHelp}>
											<Icon name="help-circle" size={1.5} />
										</Link>
									</Row>
									{DateFilter}
								</Row>

								<Row collapseOnSmallScreens align="stretch">
									<CreatedOrdersWidget />
									<OrderStatusWidget />
								</Row>
							</Column>

							<Row collapseOnSmallScreens align="stretch">
								<PaymentTotalWidget />
								<RefundsWidget />
								<NewSubscriptionsWidget />
							</Row>

							<Row collapseOnSmallScreens align="stretch">
								<TotalNetWidget />
								<SubscriptionsWidget />
							</Row>

							{hasProducts && (
								<Row collapseOnSmallScreens align="stretch">
									<SoldProductsTableWidget />
									<SoldProductsGraphWidget />
								</Row>
							)}

							<Row collapseOnSmallScreens align="stretch">
								<PaymentDueTimings />

								<Column>
									<AveragePaymentsDueWidget />
									<AveragePaymentsOverWidget />
								</Column>
							</Row>
						</Column>
					</Column>
				</main>
			</OrderReportContext.Provider>
			{showHelpModal && (
				<StepModal.Base wide onClose={hideHelp}>
					<StepModal.Step
						title={t('Payments report at a glance...')}
						hideNext
						hidePrev>
						<Help />
					</StepModal.Step>
				</StepModal.Base>
			)}
		</Fragment>
	);
}
