import { ReactNode } from 'react';

export const symbol_receipt_long: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M222-80q-43.75 0-74.375-30.625T117-185v-95q0-12.75 8.625-21.375T147-310h97v-552q0-5.25 4.5-7.125T257-867l36 36q4.636 5 10.818 5Q310-826 315-831l38-38q4.636-5 10.818-5Q370-874 375-869l37 38q4.636 5 10.818 5Q429-826 434-831l38-38q4.636-5 10.818-5Q489-874 494-869l38 38q4.636 5 10.818 5Q549-826 554-831l38-38q4.636-5 10.818-5Q609-874 614-869l38 38q4.636 5 10.818 5Q669-826 674-831l38-38q4.636-5 10.818-5Q729-874 734-869l38 38q4.636 5 10.818 5Q789-826 794-831l36-36q4-4 8.5-2.125T843-862v677q0 43.75-30.625 74.375T738-80H222Zm516-60q20 0 32.5-12.5T783-185v-595H304v470h359q12.75 0 21.375 8.625T693-280v94.845Q693-165 705.5-152.5T738-140ZM387-682h180q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T567-622H387q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T387-682Zm0 134h180q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T567-488H387q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T387-548Zm303-74q-12 0-21-9t-9-21q0-12 9-21t21-9q12 0 21 9t9 21q0 12-9 21t-21 9Zm0 129q-12 0-21-9t-9-21q0-12 9-21t21-9q12 0 21 9t9 21q0 12-9 21t-21 9ZM221-140h412v-110H177v65q0 20 12.65 32.5T221-140Zm-44 0v-110 110Z"
		/>
	</svg>
);
