import Activity from 'pkg/models/activity';

import {
	ACTIVITY_POST_SUCCESS,
	ACTIVITY_REMOVE_SUCCESS,
	ACTIVITY_UPDATE_SUCCESS,
	AUTH_LOGOUT_SUCCESS,
	Users,
} from 'pkg/actions/action-types';

import * as helpers from 'pkg/reducers/entities_helpers';

const initialState = {
	entities: {},
	comments: {},
	likes: {},
};

const activities = (state = initialState, { payload, ...action }) => {
	switch (action.type) {
		case Users.SET_ACTIVE_MEMBERSHIP: {
			return initialState;
		}

		case ACTIVITY_POST_SUCCESS: {
			state.entities[payload.result] = Activity.new(
				payload.entities.activities[payload.result]
			);

			return {
				...state,
				entities: {
					...state.entities,
				},
			};
		}

		case ACTIVITY_UPDATE_SUCCESS: {
			const updatedActivity = Activity.new(payload);
			state.entities[payload.id] =
				state.entities[payload.id].mergeDeep(updatedActivity);

			return {
				...state,
				entities: { ...state.entities },
			};
		}

		case 'SET_ACTIVITIES_ENTITIES': {
			const { collection } = action;
			const activities = {};

			collection.records.map((item) => {
				activities[item.id] = Activity.new(item);
			});

			return {
				...state,
				entities: {
					...state.entities,
					...activities,
				},
			};
		}
		case 'SET_ACTIVITIES_COMMENTS': {
			const { collection } = action;
			const comments = {};

			for (let i = 0; i < collection.records.length; i++) {
				const item = collection.records[i];
				if (item.ratingItem !== undefined) {
					comments[item.id] = {};

					comments[item.id] = {
						id: item.id,
						recordCount: 0,
						links: {},
						records: [],
						collectionOrder: 'descending',
						recordOrder: 'ascending',
					};

					if (item?.comments?.recordCount) {
						comments[item.id].recordCount = item.comments.recordCount;
					}

					if (item?.comments?.links) {
						comments[item.id].links = item.comments.links;
					}

					if (item?.comments?.records) {
						comments[item.id].records = item.comments.records;
					}
				}
			}

			return {
				...state,
				comments: {
					...state.comments,
					...comments,
				},
			};
		}
		case 'SET_ACTIVITIES_LIKES': {
			const { collection } = action;
			const likes = {};

			for (let i = 0; i < collection.records.length; i++) {
				const item = collection.records[i];
				if (item.ratingItem !== undefined) {
					likes[item.id] = {
						id: item.id,
						likeCount: +item?.likeCount || 0,
						links: item?.comments?.links || {},
						records: [],
					};
				}
			}

			return {
				...state,
				likes: {
					...state.likes,
					...likes,
				},
			};
		}

		case 'ADD_COMMENTS_BATCH': {
			const isActivity = state.entities[action.id];

			if (!isActivity) {
				return state;
			}

			const prevComments = state.comments[action.id];

			const nextComments = {
				...prevComments,
				records: [...action.collection.records, ...prevComments.records],
				links: action.collection.links,
			};

			state.comments[action.id] = nextComments;
			return {
				...state,
				comments: { ...state.comments },
			};
		}

		case ACTIVITY_REMOVE_SUCCESS: {
			delete state.entities[payload];

			return {
				...state,
				entities: { ...state.entities },
			};
		}

		case 'SET_ACTIVITY': {
			const { activity } = action;

			state.entities[activity.id] = Activity.new(activity);

			state.comments[activity.id] = {
				...activity?.comments,
				id: activity.id,
				links: activity?.comments?.links || {},
				records: activity?.comments?.records || [],
			};

			state.likes[activity.id] = {
				id: activity.id,
				links: {},
				likeCount: +activity?.likeCount || 0,
				records: [],
			};

			return {
				...state,
				entities: { ...state.entities },
				comments: { ...state.comments },
				likes: { ...state.likes },
			};
		}

		case 'UPDATE_ACTIVITY_COMMENT':
			return helpers.updateComment(state, action);

		case 'ADD_ACTIVITY_COMMENT':
			return helpers.addComment(state, action);

		case 'REMOVE_ACTIVITY_COMMENT':
			return helpers.removeComment(state, action);

		case 'ADD_ACTIVITY_LIKES_COLLECTION':
			return helpers.addLikesCollection(state, action, 'posts');

		case 'ADD_LIKE_ACTIVITY':
			return helpers.setEntityLikedByUserStatus(state, action, true);

		case 'REMOVE_LIKE_ACTIVITY':
			return helpers.setEntityLikedByUserStatus(state, action, false);

		case AUTH_LOGOUT_SUCCESS:
			return initialState;

		default:
			return state;
	}
};

export default activities;
