import { ReactNode } from 'react';

export const symbol_notification_add: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-80q-33 0-56.5-23.5T400-160h160q0 33-23.5 56.5T480-80ZM190-200q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T190-260h50v-304q0-83 49-150t131-84v-22q0-25 17.5-42.5T480-880q25 0 42.5 17.5T540-820v24.267q8 1.733 15 3.233t12 3.5q11 3 17 14t4 23q-2 12-13 19t-23 3q-23-7-37-10.5t-35-3.5q-75 0-127.5 52.5T300-564v304h360v-96q0-15 9.318-22.5t20.5-7.5q11.182 0 20.682 7.5Q720-371 720-356v96h50q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T770-200H190Zm540-370H630q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T630-630h100v-100q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T790-730v100h100q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T890-570H790v100q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T730-470v-100Zm-250 68Z"
		/>
	</svg>
);
