export default {
	name: 'logo',
	viewBox: '0 0 55 40',
	paths: [
		'M15.4 37.2C10.5 36.3 6.3 33.7 3.4 29.6 0.6 25.5-0.5 20.6 0.3 15.7 1.1 10.7 3.7 6.4 7.7 3.5 11.7 0.6 16.5-0.5 21.4 0.3 25.9 1.1 29.9 3.5 32.8 7.1L31.5 8.2C28.9 4.8 25.2 2.6 21.1 1.9 16.7 1.2 12.3 2.2 8.6 4.9 5 7.5 2.6 11.4 1.9 15.9 1.1 20.4 2.1 24.9 4.7 28.6 7.4 32.3 11.2 34.8 15.6 35.5L15.4 37.2',
		'M20.3 37.4L22.1 29.3 25.7 29.3C27 29.3 28 30.3 28 31.5 28 32.7 27.2 34.5 24.7 34.5L22.7 34.5 22 37.4 20.3 37.4 20.3 37.4ZM24.8 33C25.6 33 26.2 32.4 26.2 31.7 26.2 31.2 25.8 30.8 25.2 30.8L23.5 30.8 23 33 24.8 33 24.8 33Z',
		'M26.9 37.4L28.6 29.3 30.2 29.3 28.4 37.4 26.9 37.4',
		'M34.1 31.9L34.2 31.1 35.8 31.1 34.5 37 33 37 33.1 36.3C32.6 36.9 32.1 37.2 31.4 37.2 30.1 37.2 29.2 36.3 29.2 34.7 29.2 32.9 30.3 31 32.3 31 33 31 33.7 31.3 34.1 31.9L34.1 31.9ZM30.8 34.4C30.8 35.2 31.3 35.8 32.1 35.8 32.6 35.8 33.1 35.5 33.4 35.1L33.8 33C33.6 32.6 33.1 32.4 32.5 32.4 31.5 32.4 30.8 33.3 30.8 34.4L30.8 34.4Z',
		'M35.3 37.9C35.4 38 35.6 38 35.8 38 36.2 38 36.4 37.9 36.6 37.6L36.9 37 35.9 31.1 37.5 31.1 38.1 35.2 40.5 31.1 42.2 31.1 38 38C37.3 39 36.7 39.4 35.7 39.4 35.4 39.4 35 39.4 34.8 39.3L35.3 37.9',
		'M40.9 34.5C40.9 32.6 42.3 31 44.2 31 45.7 31 46.8 32 46.8 33.6 46.8 34 46.8 34.4 46.7 34.6L42.4 34.6 42.4 34.7C42.4 35.2 42.9 35.9 44 35.9 44.5 35.9 45.1 35.7 45.5 35.5L45.9 36.6C45.4 36.9 44.6 37.2 43.9 37.2 42.1 37.2 40.9 36.2 40.9 34.5L40.9 34.5ZM45.5 33.5C45.5 33.5 45.5 33.4 45.5 33.4 45.5 32.8 45.1 32.2 44.2 32.2 43.3 32.2 42.7 32.9 42.6 33.5L45.5 33.5 45.5 33.5Z',
		'M46.4 37L47.7 31.1 49.2 31.1 49 31.9C49.5 31.3 50.1 31 51.1 31L50.7 32.5C50.6 32.5 50.4 32.4 50.2 32.4 49.6 32.4 49.1 32.8 48.7 33.2L47.9 37 46.4 37 46.4 37',
		'M11.1 24.6L13.5 22.5C14.5 24.1 16.5 24.9 18.2 24.9 20.1 24.9 21.1 23.9 21.1 22.6 21.1 21.7 20.3 20.9 18.1 20.9 17.4 20.9 16.4 20.9 16.2 20.9L16.9 17.8C17.1 17.8 18.5 17.8 19.1 17.8 20.9 17.8 22.3 17.3 22.3 15.9 22.3 14.8 21.1 14.1 19.1 14.1 17.7 14.1 16.3 14.5 15.2 15.4L13.9 13C15.2 11.8 17.2 11 19.4 11 23.3 11 25.8 12.6 25.8 15.3 25.8 17.5 23.6 19.1 21.5 19.4 23.1 19.8 24.5 20.9 24.5 23 24.5 25.9 21.7 28 18.2 28 15.3 28 12.4 26.8 11.1 24.6',
		'M25 21.6C25 16.3 28.1 11 33.7 11 36.1 11 37.9 11.8 39.1 13.2L37 15.7C36.2 14.7 34.9 14.1 33.5 14.1 31.1 14.1 29.6 16.1 28.9 18.1 28.8 18.4 28.8 18.6 28.7 18.8 29.6 17.9 31.2 17.1 32.9 17.1 35.8 17.1 38.1 18.8 38.1 21.6 38.1 25.1 35 28 31.3 28 27.3 28 25 25.7 25 21.6L25 21.6ZM34.4 22.1C34.4 20.7 33.1 19.9 31.3 19.9 30.2 19.9 29.2 20.4 28.4 21.1 28.3 21.3 28.3 21.7 28.3 22 28.3 23.8 29.6 24.9 31.3 24.9 33.1 24.9 34.4 23.6 34.4 22.1L34.4 22.1Z',
		'M38.4 21.4C38.4 16 41.6 11 46.4 11 49.9 11 52.3 13.4 52.3 17.6 52.3 22.9 49 28 44.2 28 40.7 28 38.4 25.6 38.4 21.4L38.4 21.4ZM48.8 17.4C48.8 15.5 48 14.1 46.1 14.1 43.4 14.1 41.9 18.3 41.9 21.5 41.9 23.5 42.6 24.9 44.5 24.9 47.2 24.9 48.8 20.6 48.8 17.4L48.8 17.4Z',
		'M53.1 31.6C52.1 31.6 51.3 30.8 51.3 29.8 51.3 28.7 52.1 27.9 53.1 27.9 54.1 27.9 54.9 28.7 54.9 29.8 54.9 30.8 54.1 31.6 53.1 31.6L53.1 31.6ZM53.1 28.2C52.3 28.2 51.6 28.9 51.6 29.8 51.6 30.6 52.3 31.3 53.1 31.3 54 31.3 54.7 30.6 54.7 29.8 54.7 28.9 54 28.2 53.1 28.2L53.1 28.2ZM53.6 30.8L53.1 30 52.7 30 52.7 30.8 52.4 30.8 52.4 28.7 53.3 28.7C53.6 28.7 53.9 29 53.9 29.4 53.9 29.8 53.5 30 53.4 30L53.9 30.8 53.6 30.8 53.6 30.8ZM53.3 29L52.7 29 52.7 29.7 53.3 29.7C53.4 29.7 53.6 29.6 53.6 29.4 53.6 29.1 53.4 29 53.3 29L53.3 29Z',
	],
};
