import { ReactNode } from 'react';

export const symbol_kitesurfing: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M120-40h-10q-13 0-21.5-8.5T80-70q0-13 8.5-21.5T110-100h10q26 0 51-7t49-25q8-7 20-7t20 7q24 18 49 25t51 7q25 0 50.5-7.5T460-132q9-6 20.5-6t19.5 6q24 18 49 25t51 7q25 0 50.5-7.5T700-132q8-6 20-6t20 6q24 17 49.5 24.5T840-100h10q13 0 21.5 8.5T880-70q0 13-8.5 21.5T850-40h-10q-29 0-59.5-8.5T720-80q-30 23-60.5 31.5T600-40q-29 0-59.5-8.5T480-80q-30 23-60.5 31.5T360-40q-29 0-59.5-8.5T240-80q-30 23-60.5 31.5T120-40Zm240-156q-23 0-47.5-10T268-233q16-14 32-25.5t33-24.5l-66-127q-8-16-12.5-34t-4.5-36v-160q0-29 20.5-49.5T320-710h120q32 0 62.5-9.5T559-746q11-7 23-6t21 10q9 9 8.5 20.5T601-702q-35 26-75.5 39T440-650h-66v160h105q7 0 12 2.5t10 7.5l60 67q69-29 119.5-43t85.5-14q30 0 47 10.5t17 30.5q0 41-54.5 101T623-203q-5 2-11.5 4.5T600-196q-26 0-51-11t-48-32q-8-9-20.5-9.5T460-240q-23 22-48.5 33T360-196Zm28-125q20-12 54.5-31.5T504-386l-40-44-119 5 43 104Zm237-486 113-113q16 0 13.5 23.5T683-807q-12 12-29 12t-29-12Zm-305 40q-30 0-51.5-21.5T247-840q0-30 21.5-51.5T320-913q30 0 51.5 21.5T393-840q0 30-21.5 51.5T320-767Z"
		/>
	</svg>
);
