import { ReactNode } from 'react';

export const symbol_electric_scooter: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M191-240q-46.25 0-78.625-32.324Q80-304.647 80-350.824 80-397 112.375-429.5T191-462q38 0 69 21t39 57h240q8-72 57-124.5T717-574l-63-286H526q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T526-920h130q21 0 37.5 13t21.5 34l71 326q2 7.966-3.435 14.483Q777.13-526 769-526q-67 0-117.5 45.5T596-370q-2 19-14.218 32.5T552-324H299q-8 38-39 61t-69 23Zm0-60q21 0 36-15t15-36q0-21-15-36t-36-15q-21 0-36 15t-15 36q0 21 15 36t36 15Zm578.176 60Q723-240 690.5-272.324q-32.5-32.323-32.5-78.5Q658-397 690.323-429.5q32.324-32.5 78.501-32.5Q815-462 847.5-429.676q32.5 32.323 32.5 78.5Q880-305 847.677-272.5 815.353-240 769.176-240ZM769-300q21 0 36-15t15-36q0-21-15-36t-36-15q-21 0-36 15t-15 36q0 21 15 36t36 15ZM523-118v53q0 8-7 13t-15 1l-179-92q-5-3-3.909-8.5 1.091-5.5 6.909-5.5h119v-54q0-8 7-13t15-1l180 93q5 2.947 3.5 8.474Q648-118 642-118H523ZM191-351Zm578 0Z"
		/>
	</svg>
);
