import { ReactNode } from 'react';

export const symbol_helicopter: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M176-624q-35 35-55.5 82.5T100-440v10h270v-270h-10q-54.118 0-101.741 20.338Q210.635-659.324 176-624Zm-76 364h440v-440H430v270q0 24.75-17.625 42.375T370-370H100v110Zm500-126 260-28v-86H600v114ZM150-80q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-140h340q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q520-97 511.375-88.5T490-80H150Zm-50-120q-24.75 0-42.375-17.625T40-260v-180q0-134 93-227t227-93h180q24.75 0 42.375 17.625T600-700v140h200l29-58q5-11 15-16.5t21-5.5h15q17 0 28.5 11.5T920-600v186q0 23-15.375 40.344T866-354l-266 28v66q0 24.75-17.625 42.375T540-200H100Zm50-620q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-880h580q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T730-820H150Zm450 434v-114 114Zm-60 126Zm60-126v-114 114Z"
		/>
	</svg>
);
