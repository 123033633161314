import { ReactNode } from 'react';

export const symbol_keyboard_tab_rtl: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M109.825-240Q97-240 88.5-248.625T80-270v-420q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-690v420q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM354-450l147 147q9 9.067 9 21.533 0 12.467-8.842 21.858-8.842 8.609-21 8.609T459-260L260-459q-5-5-7-10.133-2-5.134-2-11Q251-486 253-491q2-5 7-10l199-199q9-9 21-9t21 9q9 9 9 21t-9 21L354-510h496q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-450H354Z"
		/>
	</svg>
);
