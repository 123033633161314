import { ReactNode } from 'react';

export const symbol_highlight_mouse_cursor: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M782-100 650-231l-35 106q-4 11-15 10.5T586-126l-98-327q-2-8 4.5-14.5T507-472l327 98q11 3 11.5 14T835-345l-106 35 132 132q17 17 17 39t-17 39q-17 17-39.5 17T782-100ZM480-820q-142 0-241 99t-99 241q0 141 94.5 236T468-141q13 1 21.5 10t8.5 22q0 12-9 20t-21 8q-81-2-151.5-34t-123-86q-52.5-54-83-126T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q163 0 278.5 112.5T879-492q0 12-8 20t-20 9q-13 1-22-7.5T819-492q-10-140-103-234t-236-94Z"
		/>
	</svg>
);
