import { ReactNode } from 'react';

export const symbol_sound_detection_glass_break: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-120q-24.75 0-42.375-17.625T120-180v-600q0-24.75 17.625-42.375T180-840h600q24.75 0 42.375 17.625T840-780v600q0 24.75-17.625 42.375T780-120H180Zm199.355-417q10.645 0 22.074 4.5Q412.857-528 422-519l116 116 242-271v-106H180v418l157-157q9.073-9 20.037-13.5Q368-537 379.355-537Zm158.24 194q-10.946 0-21.892-4.304Q504.757-351.609 496-361L380-477 180-276v96h600v-404L583-363q-9 10-21 15t-24.405 5Z"
		/>
	</svg>
);
