export default {
	name: 'radio-checked',
	objects: [
		{
			type: 'path',
			attributes: {
				fill: 'none',
				d: 'M0 0h24v24H0V0z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z',
			},
		},
		{
			type: 'circle',
			attributes: {
				cx: '12',
				cy: '12',
				r: '5',
			},
		},
	],
};
