import { useT } from '@transifex/react';
import { useEffect, useState } from 'react';

import { EventReportPayload } from 'pkg/actions/event_reports';

import * as actions from 'pkg/actions';
import * as models from 'pkg/api/models';
import { useCurrentOrganization } from 'pkg/identity';
import { useEndpoint } from 'pkg/api/use_endpoint';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as objects from 'pkg/objects';

import Section from 'components/section';

import Row from 'components/layout/row';

import * as DataCard from 'design/data_card';

export function _useCurrentSeasonAttendanceReport(
	userId: number
): [models.eventReport.UserDetailReport[], boolean] {
	const org = useCurrentOrganization();

	const [records, setRecords] = useState<models.eventReport.UserDetailReport[]>(
		[]
	);

	const [isLoading, setLoading] = useState<boolean>(true);

	const currentSeason = models.group.getCurrentSeason(org);

	const eventTypes: models.event.EventTypes[] = [
		models.event.EventTypes.Camp,
		models.event.EventTypes.Cup,
		models.event.EventTypes.Match,
		models.event.EventTypes.Meeting,
		models.event.EventTypes.Practice,
		models.event.EventTypes.Uncategorized,
	];

	const payload: EventReportPayload = {
		groupId: org.id,
		userId,
		eventTypes,
		startsAt: currentSeason.start,
		endsAt: Math.floor(Date.now() / 1000),
	};

	const fetchEventReport = async () => {
		setLoading(true);

		const response = await actions.eventReports.fetchByUser(payload);

		if (response) {
			setRecords(response);
		}

		setLoading(false);
	};

	useEffect(() => {
		fetchEventReport();
	}, [userId]);

	return [records, isLoading];
}

interface AttendanceProps {
	user: models.user.User;
}

export default function Attendance({ user }: AttendanceProps): JSX.Element {
	const t = useT();

	const canViewStats: boolean = models.hasAllowedAction(
		user,
		models.Action.UserListAttendanceStats
	);

	const { record: attendanceStats } =
		useEndpoint<models.user.UserAttendanceStats>(
			canViewStats ? endpoints.Users.AttendanceStats(user.id) : null
		);

	if (!canViewStats || objects.empty(attendanceStats)) {
		return null;
	}

	const {
		attendancePercentage = 0,
		matchCount = 0,
		practiceCount = 0,
		totalEventCount = 0,
	} = attendanceStats ?? {};

	return (
		<Section title={t('Season Attendance')} icon="person_check">
			<Row collapseOnSmallScreens columns="repeat(3, 1fr)">
				<DataCard.WithLargeIcon
					title={t('Matches')}
					symbol="trophy"
					heading={matchCount.toString()}
				/>
				<DataCard.WithLargeIcon
					title={t('Practices')}
					symbol="cardiology"
					heading={practiceCount.toString()}
				/>
				<DataCard.WithLargeIcon
					title={t('Attendance')}
					symbol="event_available"
					heading={`${Math.floor(attendancePercentage)}%`}
					headingSuffix={t('of {num} events', { num: totalEventCount })}
				/>
			</Row>
		</Section>
	);
}
