import { Fragment, useRef, useState } from 'react';
import { t } from '@transifex/native';
import { useFormContext } from 'react-hook-form';
import { useT } from '@transifex/react';

import spacing from 'pkg/config/spacing';

import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as sdk from 'pkg/core/sdk';

import { Rule } from 'routes/group/settings/tabs/group_links/GroupLinkFolder';
import RulesDisplay from 'routes/group/settings/tabs/group_links/RulesDisplay';

import SectionTitle from 'components/SectionTitle';
import * as Card from 'components/Card';
import Icon from 'components/icon';

import * as Input from 'components/form/inputs';
import Column from 'components/layout/column';
import * as Sortable from 'components/dnd/sortable';
import Row from 'components/layout/row';
import Form, { FormPayload, asString } from 'components/form/Form';

import Button, { ButtonGroup } from 'design/button';
import { useDialog } from 'design/dialog';

import * as css from './styles.css';

interface GroupLinkProps {
	groupId: number;
	link: models.groupLink.GroupLink;
	refresh: () => void;
	handleDeleteRule: (index: number) => void;
	isEditingLink: number;
	setIsEditingLink: React.Dispatch<React.SetStateAction<number>>;

	newLink?: boolean;
}
interface LinkPayload {
	groupLinkFolderId: number;
	rules: Rule[];
	title: string;
	url: string;
	embed: boolean;
}
interface Condition {
	id: string;
	type: string;
	value: any;

	allMustMatch?: string;
	from?: string;
	to?: string;
	ids?: string;
}

interface Conditions {
	[key: string]: Condition;
}

export enum ConditionType {
	AllMustMatch = 'allMustMatch',
	Groups = 'groups',
	ClubLobby = 'clubLobby',
	Parent = 'parent',
	Age = 'age',
	Capability = 'capability',
	Gender = 'gender',
	Role = 'role',
}

function LinkURL({ link }: { link: models.groupLink.GroupLink }) {
	const t = useT();
	const formContext = useFormContext();
	const urlValue = formContext.watch('linkURL', link.url);
	const formattedURL = urlValue.startsWith(`https://`)
		? urlValue
		: `https://${urlValue}`;
	const url = URL.parse(formattedURL);

	const validateUrl = (val: string) => {
		const formattedURL = val.startsWith(`https://`) ? val : `https://${val}`;
		const url = URL.parse(formattedURL);
		return !url?.hostname.includes('360player');
	};

	return (
		<Input.Group
			label="URL"
			hint={t('Type the URL for the link, ex: "https://www.google.com"')}
			errorMessage={
				url?.hostname.includes('360player') &&
				t(`You can't create links to 360Player resources.`)
			}>
			<Input.Field
				name="linkURL"
				defaultValue={link.url}
				type="url"
				required
				validate={validateUrl}>
				<Input.Prefix inline>https://</Input.Prefix>
			</Input.Field>
		</Input.Group>
	);
}

function Submit() {
	const formContext = useFormContext();

	return (
		<Button
			type="submit"
			large
			primary
			disabled={!formContext.formState.isValid}>
			{t('Save link')}
		</Button>
	);
}

export default function GroupLink({
	link,
	refresh,
	groupId,
	newLink,
	isEditingLink,
	setIsEditingLink,
}: GroupLinkProps): JSX.Element {
	const hideEdit = () => {
		setIsEditingLink(0);
	};
	const openEdit = () => {
		setIsEditingLink(link.id);
	};

	const [embedState, setEmbedState] = useState<boolean>(link.embed ?? false);

	const handleRadioChange = (value: string) => {
		setEmbedState(JSON.parse(value));
	};

	const [rules, setRules] = useState<Conditions[]>(() => {
		const ruleArray: Conditions[] = [];
		link.rules.forEach((rule, i) => {
			ruleArray.push(ruleToConditions(rule, i));
		});
		return ruleArray;
	});

	const handleAddRule = () => {
		setRules([
			...rules,
			{
				[`rules.rule${rules.length + 1}.conditions.cond0`]: {
					value: true,
					type: 'allMustMatch',
					id: `rules.rule${rules.length + 1}.conditions.cond0`,
				},
			},
		]);
	};

	const formRef = useRef(null);
	const onLinkSave = async (data: FormPayload) => {
		const rulesPayload = rules.length > 0 ? buildRulesPayload(data) : [];
		const url = asString(data.linkURL);
		const formattedURL = url.startsWith(`https://`) ? url : `https://${url}`;
		const payload: LinkPayload = {
			groupLinkFolderId: link.groupLinkFolderId,
			title: asString(data.linkTitle),
			rules: rulesPayload,
			url: formattedURL,
			embed: embedState,
		};

		const request = await sdk.post(endpoints.GroupLinks.Create(), {}, payload);

		if (!request.ok) {
			return false;
		}
		refresh();
		return true;
	};

	const onUpdate = async (data: FormPayload) => {
		const rulesPayload = rules.length > 0 ? buildRulesPayload(data) : [];
		const url = asString(data.linkURL);

		const formattedURL = url.startsWith(`https://`) ? url : `https://${url}`;
		const payload: LinkPayload = {
			groupLinkFolderId: link.groupLinkFolderId,
			rules: rulesPayload,
			title: asString(data.linkTitle),
			url: formattedURL,
			embed: embedState,
		};

		const request = await sdk.patch(
			endpoints.GroupLinks.Update(link.id),
			{},
			payload
		);

		if (!request.ok) {
			return false;
		}
		refresh();
		hideEdit();
		return true;
	};
	const onDelete = async () => {
		await sdk.destroy(endpoints.GroupLinks.Delete(link.id));
		refresh();
	};
	const removeDialog = useDialog({
		message: t('Are you sure you want to remove this link?'),
		onConfirm: onDelete,
	});

	if (!link) {
		return null;
	}
	function idStringToIdArray(inputString: string): number[] {
		const numberStrings: string[] = inputString.split(',');

		const numbers: number[] = numberStrings.map((numString) =>
			Number.parseInt(numString)
		);

		return numbers;
	}

	const generateRule = (rule: Conditions) => {
		const ruleTemplate: Rule = {};

		Object.values(rule).map((condition: Condition) => {
			switch (condition.type) {
				case ConditionType.Groups:
					if (condition.ids) {
						ruleTemplate.displayInGroups = ruleTemplate.displayInGroups || [];
						ruleTemplate.displayInGroups.push(
							...idStringToIdArray(condition.ids)
						);
					}
					break;
				case ConditionType.Age:
					ruleTemplate.userAgeRange = [
						Number.parseInt(condition.from),
						Number.parseInt(condition.to),
					];
					break;
				case ConditionType.Capability:
					if (condition.value) {
						if (!Array.isArray(condition.value)) {
							condition.value = [condition.value];
						}
						ruleTemplate.userCapability = ruleTemplate.userCapability || [];
						condition.value.forEach(
							(capability: models.membership.Capabilities) => {
								ruleTemplate.userCapability.push(capability);
							}
						);
					}
					break;
				case ConditionType.ClubLobby:
					ruleTemplate.displayInClubLobby = JSON.parse(condition.value);
					break;
				case ConditionType.Parent:
					ruleTemplate.isParent = JSON.parse(condition.value);
					break;
				case ConditionType.Gender:
					if (!Array.isArray(condition.value)) {
						condition.value = [condition.value];
					}
					ruleTemplate.userSex = ruleTemplate.userSex || [];
					condition.value
						.map((string: string) => Number.parseInt(string))
						.forEach((gender: number) => {
							ruleTemplate.userSex.push(gender);
						});
					break;
				case ConditionType.Role:
					if (!Array.isArray(condition.value)) {
						condition.value = [condition.value];
					}
					ruleTemplate.userGroupRole = ruleTemplate.userGroupRole || [];
					condition.value
						.map((string: string) => Number.parseInt(string))
						.forEach((role: number) => {
							ruleTemplate.userGroupRole.push(role);
						});
					break;
				default:
					let value;
					if (condition.allMustMatch === 'all') {
						value = true;
					} else {
						value = false;
					}
					ruleTemplate.allMustMatch = value;
					break;
			}
		});

		return ruleTemplate;
	};

	const buildRulesPayload = (data: FormPayload) => {
		const rulePayload: Rule[] = [];

		Object.values(data.rules)?.map((rule: Conditions) => {
			const singleSet = Object.values(rule.conditions);

			rulePayload.push(generateRule(singleSet as unknown as Conditions));
		});
		return rulePayload;
	};

	function ruleToConditions(rule: Rule, index: number): Conditions {
		const conditions: Conditions = {};

		conditions[
			`rules.rule${index}.conditions.cond${Object.keys(conditions).length}`
		] = {
			id: `rules.rule${index}.conditions.cond${Object.keys(conditions).length}`,
			type: ConditionType.AllMustMatch,
			value: rule.allMustMatch,
		};

		if (rule.displayInGroups) {
			conditions[
				`rules.rule${index}.conditions.cond${Object.keys(conditions).length}`
			] = {
				id: `rules.rule${index}.conditions.cond${
					Object.keys(conditions).length
				}`,
				type: ConditionType.Groups,
				value: rule.displayInGroups,
			};
		}

		if (rule.displayInClubLobby) {
			conditions[
				`rules.rule${index}.conditions.cond${Object.keys(conditions).length}`
			] = {
				id: `rules.rule${index}.conditions.cond${
					Object.keys(conditions).length
				}`,
				type: ConditionType.ClubLobby,
				value: rule.displayInClubLobby,
			};
		}
		if (rule.isParent) {
			conditions[
				`rules.rule${index}.conditions.cond${Object.keys(conditions).length}`
			] = {
				id: `rules.rule${index}.conditions.cond${
					Object.keys(conditions).length
				}`,
				type: ConditionType.Parent,
				value: rule.isParent,
			};
		}

		if (rule.userAgeRange) {
			conditions[
				`rules.rule${index}.conditions.cond${Object.keys(conditions).length}`
			] = {
				id: `rules.rule${index}.conditions.cond${
					Object.keys(conditions).length
				}`,
				type: ConditionType.Age,
				value: rule.userAgeRange,
			};
		}

		if (rule.userCapability?.length > 0) {
			conditions[
				`rules.rule${index}.conditions.cond${Object.keys(conditions).length}`
			] = {
				id: `rules.rule${index}.conditions.cond${
					Object.keys(conditions).length
				}`,
				type: ConditionType.Capability,
				value: rule.userCapability,
			};
		}

		if (rule.userSex) {
			conditions[
				`rules.rule${index}.conditions.cond${Object.keys(conditions).length}`
			] = {
				id: `rules.rule${index}.conditions.cond${
					Object.keys(conditions).length
				}`,
				type: ConditionType.Gender,
				value: rule.userSex,
			};
		}

		if (rule.userGroupRole) {
			conditions[
				`rules.rule${index}.conditions.cond${Object.keys(conditions).length}`
			] = {
				id: `rules.rule${index}.conditions.cond${
					Object.keys(conditions).length
				}`,
				type: ConditionType.Role,
				value: rule.userGroupRole,
			};
		}

		return conditions;
	}

	const deleteRule = (index: number) => {
		const updatedRules = [...rules];

		updatedRules.splice(index, 1);

		setRules(updatedRules);
	};

	const getCreateRuleButtonText = () => {
		if (rules.length > 0) {
			return t('Add another rule');
		} else {
			return t('Create rule');
		}
	};

	const linkCard = (
		<Sortable.Item id={link.id}>
			<div className={css.link}>
				<Row columns="auto auto 1fr  auto" className={css.linkTitle}>
					<Icon name="drag-handle" size={1.4} />
					<Icon name="link" />
					<Column spacing={spacing._1}>
						<div className={css.linkTitle}>{link.title}</div>
						<div className={css.childLink}>{link.url}</div>
					</Column>
					<Button secondary onClick={openEdit} icon="edit" />
				</Row>
			</div>
		</Sortable.Item>
	);

	const linkEditForm = (
		<Form formRef={formRef} onSubmit={newLink ? onLinkSave : onUpdate}>
			<Card.Base className={css.item}>
				<Card.Body>
					<Column spacing={spacing._5}>
						<Input.Group
							label={t('Title')}
							hint={t(
								'This link title will appear in the navigation for the user'
							)}>
							<Input.Field
								name="linkTitle"
								required
								defaultValue={link.title}
							/>
						</Input.Group>
						<LinkURL link={link} />
						<Input.Group>
							<Input.Control
								type="radio"
								value="false"
								defaultChecked={!link.embed}
								checked={embedState === false}
								onChange={handleRadioChange}
								label={t('Open link in new tab')}
								description={t('This will open a new tab in the browser')}
							/>
							<Input.Control
								type="radio"
								value="true"
								defaultChecked={link.embed}
								checked={embedState === true}
								onChange={handleRadioChange}
								label={t('Embed link in 360Player')}
								description={t(
									'This will open the link inside the 360Player app'
								)}
							/>
						</Input.Group>
						<Column className={css.rulesWrapper}>
							<Column spacing={spacing._1}>
								<SectionTitle icon="visibility">
									{t('Visibility rules')}
								</SectionTitle>
								<div className={css.rulesInfo}>
									{t(
										`Customize when this link appears in your club's navigation. If you don't create any rules, the link won't be shown in the navigation.`
									)}
								</div>
							</Column>
							{rules.map((rule, i) => {
								return (
									<RulesDisplay
										key={i}
										existingConditions={rule}
										ruleNumber={i}
										groupId={groupId}
										deleteRule={deleteRule}
									/>
								);
							})}
							<Button secondary onClick={handleAddRule}>
								<Icon name="add" />
								{getCreateRuleButtonText()}
							</Button>
						</Column>
						<Card.Divider />
						<Row columns="auto auto" justifyContent="space-between">
							<Button caution large onClick={removeDialog}>
								{t('Delete')}
							</Button>
							<ButtonGroup>
								<Button large onClick={hideEdit}>
									{t('Cancel')}
								</Button>
								<Submit />
							</ButtonGroup>
						</Row>
					</Column>
				</Card.Body>
			</Card.Base>
		</Form>
	);

	return (
		<Fragment>{isEditingLink !== link.id ? linkCard : linkEditForm}</Fragment>
	);
}
