import { ReactNode } from 'react';

export const symbol_network_intelligence_update: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M495-595v-175q0-12.75 8.625-21.375T525-800h118q8.171 0 15.229 3.929Q665.286-792.143 669-785l84 150h-71l-34-64q-3.611-8-10.833-12-7.223-4-15.167-4h-57q-6 0-10.5 4.5T550-700q0 6 4.5 10.5T565-685h56.727L656-621q3.611 8 10.833 12 7.223 4 15.167 4h87l38 66q8 15-2.472 29T776-500q-11-2-22.5-3t-23.5-1q-61 0-114 25.5T525-408q-8 10-19 5.5T495-418v-147h85q6 0 10.5-4.5T595-580q0-6-4.5-10.5T580-595h-85Zm-60 435H317q-8.171 0-15.229-3.929Q294.714-167.857 291-175l-84-150h71l34 64q3.611 8 10.833 12 7.223 4 15.167 4h57q6 0 10.5-4.5T410-260q0-6-4.5-10.5T395-275h-56.727L304-339q-3.611-8-10.833-12-7.223-4-15.167-4h-87.132L128-465q-1.6-3.75-2.8-7.5-1.2-3.75-1.2-7.5 0-2 4-15l62.868-110H278q7.944 0 15.167-4 7.222-4 10.833-12l34.273-64H395q6 0 10.5-4.5T410-700q0-6-4.5-10.5T395-715h-57q-7.944 0-15.167 4-7.222 4-10.833 12l-34 64h-71l84-150q3.714-7.143 10.771-11.071Q308.829-800 317-800h118q12.75 0 21.375 8.625T465-770v175h-85q-6 0-10.5 4.5T365-580q0 6 4.5 10.5T380-565h85v130h-98l-31-63q-4-8-11.419-12.5-7.42-4.5-15.581-4.5h-79q-6 0-10.5 4.5T215-500q0 6 4.5 10.5T230-485h78l32 63q4 8 11.419 12.5 7.42 4.5 15.581 4.5h98v121q-2 13-3.5 25t-1.5 26q0 12 1.5 24t3.5 24q-2 11-10.5 18t-19.5 7Zm280-17-67-67q-5-5-10-4.5t-10 5.5q-5 5-5 10.5t5 10.5l91 91q4.636 5 10.818 5Q736-126 741-131l92-92q5-5 5-10.5t-5-10.5q-5-5-10.5-5t-10.5 5l-67 67v-156q0-6-4.5-10.5T730-348q-6 0-10.5 4.5T715-333v156Zm15 133q-78 0-134-55.4-56-55.399-56-133.999Q540-312 596-368t134-56q78 0 134 55.867Q920-312.265 920-233q0 78.435-56 133.718Q808-44 730-44Z"
		/>
	</svg>
);
