import { ReactNode } from 'react';

export const symbol_hourglass_disabled: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M812-62.914 794-80H190q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T190-140h58v-127q0-70 40.5-128.5T394-480q-41-17-76.5-51T264-610L47-827q-9-9.067-9-21.533Q38-861 47.053-870q9.052-9 21.5-9Q81-879 90-870l765 765q9 9 9 21t-9.053 21q-9.052 9-21.5 9Q821-54 812-62.914ZM652-820H238q-15 0-22.5-9.318t-7.5-20.5q0-11.182 7.5-20.682Q223-880 238-880h532q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T770-820h-58v126q0 64-33.5 118.5T589-491q-10 5-19.5-.5T555-508q-5-11-2-22t13-17q38-23 62-62t24-85v-126ZM308-140h344v-82L441-433q-57 14-95 60.5T308-267v127Zm404 0h22l-22-22v22Z"
		/>
	</svg>
);
