import { Record } from 'immutable';
import { schema } from 'normalizr';

import User from 'pkg/models/user';
import Account from 'pkg/models/account';
import SequenceUser from 'pkg/models/video_sequence_user';
import SequenceComment from 'pkg/models/video_sequence_comment';
import Tag from 'pkg/models/tag';

const video = new schema.Entity('videos', {
	user: User.normalizr(),
	account: Account.normalizr(),
});
export const videoSequences = new schema.Entity('videoSequences', {
	user: User.normalizr(),
	users: [SequenceUser.normalizr()],
	account: Account.normalizr(),
	comments: [SequenceComment.normalizr()],
	video,
	tags: [Tag.normalizr()],
});

video.define({
	sequences: videoSequences,
});

interface Sequence {
	id: number;
	userId: number;
	accountId: number;
	videoId: number;
	startsAt: number;
	endsAt: number;
	startsAtMs: number;
	endsAtMs: number;
	private: boolean;
	reviewed: boolean;
	title: string;
	description: string;
	annotations: string;
	user: User;
	users: User[];
	account: Account;
	createdAt: number;
	updatedAt?: number;
	tags?: number[];
	links?: {
		delete?: string;
		edit?: string;
	};
}

// @TODO Remove this and use actual responses when converting models to TS.
type GreedyResponseType = { [key: string]: any };

export interface VideoSequenceResponse {
	id: number;
	userId: number;
	accountId?: number;
	videoId: number;
	startsAt: number;
	endsAt: number;
	startsAtMs: number;
	endsAtMs: number;
	private: boolean;
	reviewed: boolean;
	title: string;
	description: string;
	annotations: string;
	users?: GreedyResponseType[];
	video: GreedyResponseType;
	createdAt: number;
	updatedAt?: number;
	tags?: number[];
	links?: {
		delete?: string;
		edit?: string;
	};
}

export interface VideoSequencePayload {
	videoId: number;
	startsAt?: number;
	startsAtMs?: number;
	endsAt?: number;
	endsAtMs?: number;
	private?: boolean;
	reviewed?: boolean;
	title?: string;
	description?: string;
	annotations?: string;
	tags?: string[];
}

const videoSequenceProps: Sequence = {
	id: 0,
	userId: 0,
	accountId: 0,
	videoId: 0,
	startsAt: 0,
	endsAt: 0,
	startsAtMs: 0,
	endsAtMs: 0,
	private: false,
	reviewed: false,
	title: '',
	description: '',
	annotations: '',
	user: null,
	users: null,
	account: null,
	createdAt: 0,
	updatedAt: 0,
	tags: [],
	links: {},
};

class VideoSequence
	extends Record(videoSequenceProps, 'VideoSequenceRecord')
	implements Sequence
{
	static normalizr(): schema.Entity {
		return videoSequences;
	}

	valid(): boolean {
		return this.get('id') !== 0;
	}

	/**
	 * Returns starts at with decimals for more accurate calculations.
	 *
	 * @returns number
	 */
	getStartsAt(): number {
		return this.startsAtMs ? this.startsAtMs / 1000 : this.startsAt;
	}

	/**
	 * Returns ends at with decimals for more accurate calculations.
	 *
	 * @returns number
	 */
	getEndsAt(): number {
		return this.endsAtMs ? this.endsAtMs / 1000 : this.endsAt;
	}

	getDuration(): number {
		return Math.abs(this.getEndsAt() - this.getStartsAt());
	}

	getAnnotationsObject(): JSONObject | null {
		return this.annotations ? JSON.parse(this.annotations) : null;
	}
}

export default VideoSequence;
