import { Fragment, useEffect, useState } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import { eventTypeToTranslatedString } from 'pkg/models/event';

import { useQueryState } from 'pkg/hooks/query-state';
import { eventTypeLabels, EventTypes } from 'pkg/api/models/event';
import * as models from 'pkg/api/models';
import useMixedState from 'pkg/hooks/useMixedState';
import {
	useCurrentAccount,
	useCurrentGroup,
	useCurrentMembership,
} from 'pkg/identity';
import { useWardsInCurrentGroup } from 'pkg/hooks/useWardsInCurrentGroup';

import CalendarExport from 'routes/group/calendar/components/export';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import * as Input from 'components/form/inputs';
import Row from 'components/layout/row';
import FilterGroup from 'components/filters/FilterGroup';
import Column from 'components/layout/column';
import { GroupSelectModal } from 'components/group/select_modal';

import * as Context from 'design/context_menu';
import Button, { FilterButton } from 'design/button';

export enum CalendarTypes {
	My = 'my',
	Group = 'group',
}

export interface CalendarFilterState {
	groups: number[];
	types: EventTypes[];
	calendars: CalendarTypes[];
	// Account id of wards
	wardCalendars: number[];
	// User id of wards (use in dev mode)
	wardUserIds: number[];
}

export const calendarDefaultFilters: CalendarFilterState = {
	groups: [],
	types: [
		EventTypes.Camp,
		EventTypes.Cup,
		EventTypes.Match,
		EventTypes.Meeting,
		EventTypes.Practice,
		EventTypes.Uncategorized,
	],
	calendars: [CalendarTypes.My, CalendarTypes.Group],
	wardCalendars: [],
	wardUserIds: [],
};

export const hasBeenFiltered = (queryFilters: CalendarFilterState) =>
	JSON.stringify(queryFilters) !== JSON.stringify(calendarDefaultFilters) &&
	!!queryFilters;

interface CalendarFiltersProps {
	setMyModal: () => void;
	setGroupModal: () => void;
	setChildModal: (id: number) => void;
}

const CalendarFilters = ({
	setMyModal,
	setGroupModal,
	setChildModal,
}: CalendarFiltersProps) => {
	const [modal, setModal] = useState('');
	const qs = useQueryState();

	const group = useCurrentGroup();
	const account = useCurrentAccount();
	const activeMembership = useCurrentMembership();

	const wards = useWardsInCurrentGroup();

	const qsFilters = qs.get('filters') as string;
	const queryFilters =
		qsFilters && qsFilters !== 'null'
			? (JSON.parse(qsFilters) as CalendarFilterState)
			: calendarDefaultFilters;

	const [filterState, setFilterState] =
		useMixedState<CalendarFilterState>(queryFilters);

	useEffect(() => {
		setFilterState(queryFilters);
	}, [qsFilters]);

	const onSelectGroupsClose = async (groups: number[]) => {
		setFilterState({
			groups,
		});
		setModal('');
	};

	const handleClose = () => setModal('');

	const hasSelectedGroups = filterState.groups.length > 0;

	const handleCalendarChange = (type: CalendarTypes) => {
		setFilterState({
			...filterState,
			calendars: filterState.calendars.includes(type)
				? filterState.calendars.filter((t) => t !== type)
				: [...filterState.calendars, type],
		});
	};

	const handleWardUserIdChange = (wardUserId: number) => {
		setFilterState({
			...filterState,
			wardUserIds: filterState.wardUserIds.includes(wardUserId)
				? filterState.wardUserIds.filter((w) => w !== wardUserId)
				: [...filterState.wardUserIds, wardUserId],
		});
	};

	const handleTypeChange = (type: EventTypes) => {
		setFilterState({
			...filterState,
			types: filterState.types.includes(type)
				? filterState.types.filter((t) => t !== type)
				: [...filterState.types, type],
		});
	};

	const handleApply = (
		close: (event?: React.MouseEvent<Element, MouseEvent>) => void
	) => {
		const filters = JSON.stringify({ ...filterState });

		localStorage.setItem('calendarFilters', filters);
		qs.set('filters', filters);
		qs.commit();

		close();
	};

	const showGroupsModal = () => setModal('selectGroups');

	const clearFilter = (filter: string) => {
		setFilterState({
			[filter]: [],
		});
	};

	const handleClear = (
		close: (event?: React.MouseEvent<Element, MouseEvent>) => void
	) => {
		setFilterState({
			groups: [],
			types: [],
			calendars: [],
			wardUserIds: [],
			wardCalendars: [],
		});

		qs.set(
			'filters',
			JSON.stringify({
				groups: [],
				types: [],
				calendars: [],
				wardUserIds: [],
				wardCalendars: [],
			})
		);
		qs.commit();
		close();
	};

	const filterButton = (
		<Fragment>
			<LargeScreen>
				<Button
					label={t('Filter')}
					iconPosition="left"
					icon="tune"
					testid="calendar.actionbar.filter"
				/>
			</LargeScreen>
			<SmallScreen>
				<FilterButton testid="calendar.actionbar.filter" />
			</SmallScreen>
		</Fragment>
	);

	const mappedWards = wards.map((ward) => (
		<Input.Control
			key={ward.id}
			type="checkbox"
			label={models.user.fullName(ward)}
			checked={filterState.wardUserIds.includes(ward.id)}
			value={ward.id}
			onChange={handleWardUserIdChange}
		/>
	));

	return (
		<Context.Menu
			offsetHorizontal={0}
			offsetVertical={35}
			toggleWith={filterButton}>
			<Context.Heading>
				{t('Filters')}
				<Row spacing={styles.spacing._2}>
					<Context.MenuContext.Consumer>
						{({ close }) => (
							<Fragment>
								{queryFilters && (
									<Button
										label={t('Clear')}
										onClick={() => handleClear(close)}
									/>
								)}
								<Button
									label={t('Apply')}
									primary
									onClick={() => handleApply(close)}
								/>
							</Fragment>
						)}
					</Context.MenuContext.Consumer>
				</Row>
			</Context.Heading>

			<SmallScreen>
				<FilterGroup isActive={false} label={t('Export')}>
					<CalendarExport />
				</FilterGroup>
			</SmallScreen>

			<FilterGroup
				isActive={!!filterState.calendars.length}
				label={t('Calendars')}
				onClearFilter={() => clearFilter('calendars')}>
				<Column>
					{!activeMembership.isOrganizationMembership && (
						<Input.Control
							type="checkbox"
							label={group.name}
							checked={filterState.calendars.includes(CalendarTypes.Group)}
							value={CalendarTypes.Group}
							onChange={handleCalendarChange}
						/>
					)}
					<Input.Control
						type="checkbox"
						label={models.account.fullName(account)}
						checked={filterState.calendars.includes(CalendarTypes.My)}
						value={CalendarTypes.My}
						onChange={handleCalendarChange}
					/>
					{mappedWards}
				</Column>
			</FilterGroup>

			{models.group.canShowChildren(group) && (
				<FilterGroup
					isActive={!!filterState.groups.length}
					label={t('Groups')}
					onClearFilter={() => clearFilter('groups')}>
					<Button
						label={
							hasSelectedGroups
								? t('{num} selected', {
										num: filterState.groups.length,
									})
								: t('Select groups')
						}
						block
						onClick={showGroupsModal}
						active={hasSelectedGroups}
					/>
					{modal === 'selectGroups' && (
						<GroupSelectModal
							handleClose={handleClose}
							selected={filterState.groups}
							handleNext={onSelectGroupsClose}
						/>
					)}
				</FilterGroup>
			)}

			<FilterGroup
				isActive={!!filterState.types.length}
				label={t('Event')}
				onClearFilter={() => clearFilter('types')}>
				<Column>
					{eventTypeLabels.map((type, index) => {
						const active = filterState.types.includes(type);
						return (
							<Input.Control
								key={index}
								type="checkbox"
								label={eventTypeToTranslatedString(type)}
								checked={active}
								value={type}
								onChange={handleTypeChange}
							/>
						);
					})}
				</Column>
			</FilterGroup>

			<SmallScreen>
				<Context.Divider />
				<Context.Label>{t('Share')}</Context.Label>
				<Context.Item
					onClick={setGroupModal}
					testid="events.subscribe.group_calendar">
					<Context.ItemIcon name="share" />
					{group.name}
				</Context.Item>
				<Context.Item
					onClick={setMyModal}
					testid="events.subscribe.my_calendar">
					<Context.ItemIcon name="share" />
					{models.account.fullName(account)}
				</Context.Item>
				{wards.map((w) => (
					<Context.Item key={w.id} onClick={() => setChildModal(w.id)}>
						{models.user.fullName(w)}
					</Context.Item>
				))}
			</SmallScreen>
		</Context.Menu>
	);
};

export default CalendarFilters;
