import { ReactNode } from 'react';

export const symbol_last_page: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M421-480 250-651q-9-9-8.5-21t9.5-21q9-9 21.5-9t21.5 9l192 192q5 5 7 10t2 11q0 6-2 11t-7 10L293-266q-9 9-21 8.5t-21-9.5q-9-9-9-21.5t9-21.5l170-170Zm269-240q13 0 21.5 8.5T720-690v420q0 13-8.5 21.5T690-240q-13 0-21.5-8.5T660-270v-420q0-13 8.5-21.5T690-720Z"
		/>
	</svg>
);
