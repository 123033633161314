import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';
import { CSSProperties } from 'react';

import * as styles from 'pkg/config/styles';

import rgba from 'pkg/rgba';
import * as numbers from 'pkg/numbers';

import { useOnboardingContext } from 'routes/public/onboarding';

import Icon from 'components/icon';

const Wrapper = styled(motion.div)`
	position: absolute;
	top: calc(env(safe-area-inset-top));
	left: var(--spacing-7);
	right: var(--spacing-7);
	height: 80px;
	z-index: 2;
	display: grid;
	grid-template-columns: 55px 1fr;
	align-items: center;

	@media ${styles.breakpoint.large}, ${styles.breakpoint.nolimit} {
		grid-template-columns: 65px 1fr;
	}

	> :first-child {
		display: flex;
		justify-items: start;
	}
`;

const BackButton = styled(Icon)`
	cursor: pointer;
	color: var(--palette-white);
	height: 30px;
	width: 30px;

	@media ${styles.breakpoint.large}, ${styles.breakpoint.nolimit} {
		height: 40px;
		width: 40px;
	}

	@media (hover: hover) {
		&:hover:not([disabled]) {
			background: ${rgba(styles.palette.white, 0.3)};
			border-radius: 100%;
		}
	}

	&:active {
		opacity: 0.8;
	}
`;

BackButton.defaultProps = {
	rotate: '-180deg',
};

const ProgressBar = styled(motion.div)`
	--progress: 0%;

	height: 10px;
	border-radius: var(--radius-5);
	background: ${rgba(styles.palette.white, 0.3)};
	position: relative;
	overflow: hidden;

	&::before {
		background: ${rgba(styles.palette.white, 0.3)};
		display: block;
		content: '';
		height: 100%;
		width: var(--progress);
		transition: width 250ms ease-in-out;
	}
`;

interface OnboardingHeaderProps {
	progress?: number;
	backSlug?: string;
}

export function OnboardingHeader({
	progress,
	backSlug,
}: OnboardingHeaderProps): JSX.Element {
	const { back } = useOnboardingContext();

	const animationStyles = {
		initial: { y: -60, opacity: 0 },
		animate: { y: 0, opacity: 1 },
		exit: { y: -60, opacity: 0 },
		transition: { duration: 0.25, delay: 0.1 },
	};

	progress = numbers.clamp(progress || 0, 0, 1);

	if (!progress && !backSlug) return null;

	return (
		<Wrapper key="onboarding::header" {...animationStyles}>
			{backSlug ? <BackButton name="arrow-right" onClick={back} /> : <span />}
			<AnimatePresence>
				{progress > 0 && (
					<ProgressBar
						{...animationStyles}
						style={{ '--progress': `${progress * 100}%` } as CSSProperties}
					/>
				)}
			</AnimatePresence>
		</Wrapper>
	);
}
