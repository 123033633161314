import { ReactNode } from 'react';

export const symbol_line_start_circle: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M320-300q75 0 127.5-52.5T500-480q0-75-52.5-127.5T320-660q-75 0-127.5 52.5T140-480q0 75 52.5 127.5T320-300Zm0 60q-100 0-170-70T80-480q0-100 70-170t170-70q92 0 159.5 60.5T558-510h292q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-450H558q-11 89-78.693 149.5T320-240Zm0-240Z"
		/>
	</svg>
);
