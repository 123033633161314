import { ReactNode } from 'react';

export const symbol_tab: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-220h680v-347H554q-12.75 0-21.375-8.625T524-597v-143H140v520Zm0 60q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm0-60v-520 520Z"
		/>
	</svg>
);
