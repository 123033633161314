import { ReactNode } from 'react';

export const symbol_keyboard_option_key: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M632-200q-16.69 0-30.345-8.5Q588-217 580-231L320-700H150q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-760h169q16.69 0 30.345 8.5Q363-743 371-729l260 469h179q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-200H632Zm8-500q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T640-760h170q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-700H640Z"
		/>
	</svg>
);
