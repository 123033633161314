import { ReactNode } from 'react';

export const symbol_speed_1_5: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M316.825-277Q304-277 295.5-285.625T287-307v-316h-53q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T234-683h53q24.75 0 42.375 17.625T347-623v316q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM557-277q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T557-337h140v-115H587q-24.75 0-42.375-17.625T527-512v-111q0-24.75 17.625-42.375T587-683h140q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T727-623H587v111h110q24 0 42 18t18 42v115q0 24-18 42t-42 18H557Zm-120.175 0Q424-277 415.5-285.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Z"
		/>
	</svg>
);
