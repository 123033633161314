import { ReactNode } from 'react';

export const symbol_charging_station: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M460-440h-56q-8.13 0-12.565-7-4.435-7-.435-15l95-200q2-5 8-3.5t6 7.5v138h56q8.13 0 12.565 7 4.435 7 .435 15l-95 200q-2 5-8 3.5t-6-7.5v-138ZM260-40q-24 0-42-18t-18-42v-760q0-24 18-42t42-18h440q24 0 42 18t18 42v760q0 24-18 42t-42 18H260Zm0-90v30h440v-30H260Zm0-60h440v-580H260v580Zm0-640h440v-30H260v30Zm0 0v-30 30Zm0 700v30-30Z"
		/>
	</svg>
);
