export function AddComment(activityID: number): string {
	return `/v1/user-activities/${activityID}/comments`;
}

export function AddLike(activityID: number): string {
	return `/v1/user-activities/${activityID}/likes`;
}

export function Create(userID: number): string {
	return `/v1/users/${userID}/activities`;
}

export function Delete(activityID: number): string {
	return `/v1/user-activities/${activityID}`;
}

export function DeleteComment(commentID: number): string {
	return `/v1/user-activity-comments/${commentID}`;
}

export function EventIndex(eventID: number): string {
	return `/v1/events/${eventID}/activities`;
}

export function GroupIndex(groupID: number): string {
	return `/v1/groups/${groupID}/activities`;
}

export function RemoveLike(activityID: number): string {
	return `/v1/user-activities/${activityID}/likes`;
}

export function Show(activityID: number): string {
	return `/v1/user-activities/${activityID}`;
}

export function ShowComments(activityID: number): string {
	return `/v1/user-activities/${activityID}/comments`;
}

export function ShowLikes(activityID: number): string {
	return `/v1/user-activities/${activityID}/likes`;
}

export function Update(activityID: number): string {
	return `/v1/user-activities/${activityID}`;
}

export function UpdateComment(commentID: number): string {
	return `/v1/user-activity-comments/${commentID}`;
}

export function UserIndex(userID: number): string {
	return `/v1/users/${userID}/activities`;
}
