import styled from 'styled-components';
import { t } from '@transifex/native';
import { Fragment } from 'react';

import * as styles from 'pkg/config/styles';

import { updateGroupKeyValue } from 'pkg/actions/groups';

import ColorConverter from 'pkg/colorconverter';
import rgba from 'pkg/rgba';
import * as models from 'pkg/api/models';
import store from 'pkg/store/createStore';
import { cssVarList } from 'pkg/css/utils';

import SplashScreenSettings from 'routes/group/settings/SplashScreenSettings';
import BadgeEditor from 'routes/group/settings/badge';

import SectionTitle from 'components/SectionTitle';
import CardAnatomy, * as Card from 'components/Card';

import ColorPickerController from 'components/form/ColorPickerController';
import Column from 'components/layout/column';
import {
	SettingRow,
	SettingLabel,
	SettingDescription,
	SettingControl,
	SettingRowDivider,
} from 'components/settings/Settings';

interface AppearanceSettingsProps {
	group: models.group.Group;
	refresh: () => void;
}

const SelectedColorDescription = styled(SettingDescription)`
	display: flex;
`;

const SelectedColorAttrs = ({ color }: { color?: string }) => ({
	style: {
		backgroundColor: color || 'hsl(180, 100%, 50%)',
	},
});

const SelectedColor = styled.span.attrs(SelectedColorAttrs)`
	margin-right: 0.5rem;
	width: 1rem;
	height: 1rem;
	border-radius: 100rem;
	box-shadow: inset 0 0 0 1px ${rgba(styles.palette.black, 0.1)};
	transform: translateY(1px);
	display: inline-block;
`;

const GroupBadgeImage = styled.img`
	background-color: var(--color, var(--palette-blue-500));
	box-shadow: 0 0 0 5px var(--color, var(--palette-blue-500));
	border-radius: 3px;
	width: 50px;
	height: 50px;
`;

export default function AppearanceSettings({
	group,
	refresh,
}: AppearanceSettingsProps) {
	const groupBadgeUrl = group?.profileImageUrl;

	let colorValue = models.group.getPrimaryColor(group);

	const handleSetPrimaryColor = async ({ hsl }: { hsl: string }) => {
		await models.update(group, {
			primaryColor: `hsl:${hsl}`,
		});

		// Need to update the state for certain components
		store.dispatch(updateGroupKeyValue(group.id, 'primaryColor', `hsl:${hsl}`));

		refresh();
	};

	if (colorValue === null) {
		colorValue = '55,100%,50%';
	}

	const conv = ColorConverter.from(colorValue);

	const showSplashScreenSettings =
		models.group.isOrganization(group) &&
		models.group.hasFeature(group, models.group.Features.SplashScreen);

	return (
		<Column>
			<SectionTitle>{t('Appearance settings')}</SectionTitle>
			<CardAnatomy $noBorder>
				<Card.Body>
					<SettingRow>
						<SettingLabel>
							{groupBadgeUrl ? t('Badge') : t('Upload your badge')}
						</SettingLabel>
						<SettingDescription>
							{groupBadgeUrl ? (
								<GroupBadgeImage
									src={groupBadgeUrl}
									alt="Badge"
									style={cssVarList({
										color: conv.toStylesheetString(),
									})}
								/>
							) : (
								t('Badge not set')
							)}
						</SettingDescription>
						<SettingControl>
							<BadgeEditor group={group} />
						</SettingControl>
					</SettingRow>

					<Fragment>
						<SettingRowDivider />

						<SettingRow>
							<SettingLabel>{t('Color')}</SettingLabel>
							<SelectedColorDescription>
								<SelectedColor color={conv.toStylesheetString()} />
								RGB: {conv.toRGB(true)}
							</SelectedColorDescription>
							<SettingControl>
								<ColorPickerController
									hsl={conv.toHSL()}
									onSelect={handleSetPrimaryColor}
								/>
							</SettingControl>
						</SettingRow>
					</Fragment>
					{showSplashScreenSettings && (
						<SplashScreenSettings group={group} refresh={refresh} />
					)}
				</Card.Body>
			</CardAnatomy>
		</Column>
	);
}
