import { ReactNode } from 'react';

export const symbol_vital_signs: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M363.206-190Q347-190 334-199t-18-23l-97-228H78q-12.75 0-21.375-8.675Q48-467.351 48-480.175 48-493 56.625-501.5T78-510h163q9.486 0 16.419 4.846Q264.351-500.308 268-492l95 223 187-467q5-14 17.794-23t29-9Q613-768 626-759t18 23l97 226h141q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T882-450H719q-9.487 0-16.419-4.846T692-468l-95-221-187 467q-5 14-17.794 23t-29 9Z"
		/>
	</svg>
);
