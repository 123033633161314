import Scrubbable from 'pkg/models/scrubbable';

import { AccountRelation } from 'pkg/api/models/account_relation';
import { Dateable } from 'pkg/api/models/dateable';
import { Linkable } from 'pkg/api/models/linkable';
import { Record } from 'pkg/api/models/record';
import * as models from 'pkg/api/models';
import { yearsSince } from 'pkg/date';
import * as sdk from 'pkg/core/sdk';

export enum AccountNotificationSettings {
	Push = 'push',
	Email = 'email',
}

export enum AccountFlags {
	CompletedFirstLogin = 'completed-first-login',
	OptOutAnalytics = 'optout-ga',
}

export enum AccountClearFlags {
	OptOutAnalytics = '!optout-ga',
}

export enum AccountHourFormat {
	'24h' = 0,
	'12h' = 1,
}

export interface Account extends Scrubbable, Record, Dateable, Linkable {
	firstName: string;
	lastName: string;
	email: string;
	birthDate: string;
	profileImageUrl: string;
	languageCode: string;
	hourFormat: AccountHourFormat;
	countryId: number;
	activitySummary: any;
	needsParentalConsent: boolean;
	parentalConsentEmail: string;
	parentalConsentGranted?: number;
	parentalConsentRevoked?: number;
	hasAppleAuth?: boolean;
	hasPassword?: boolean;
	hasEmail?: boolean;
	trackingHash: any;
	helpScoutSignature: any;

	wards: AccountRelation[];
	flags: AccountFlags[] | AccountClearFlags;
	notificationSettings: AccountNotificationSettings[];

	users: models.user.User[];
	willBeDeletedAt: number;

	country?: models.country.Country;
}

export function getCuratorAccount(): Account {
	return {
		firstName: '360Player',
		lastName: '',
		email: '',
		birthDate: '0000-00-00',
		profileImageUrl: window.TS?.assetUrl + '/img/360avatar.png',
		languageCode: 'en',
		hourFormat: AccountHourFormat['24h'],
		countryId: 0,
		activitySummary: '',
		needsParentalConsent: false,
		parentalConsentEmail: '',
		trackingHash: '',
		helpScoutSignature: '',
		wards: [],
		flags: [AccountFlags.CompletedFirstLogin],
		users: [],
		notificationSettings: [],
		willBeDeletedAt: null,
	};
}

export function fullName(account: Account): string {
	return `${account.firstName} ${account.lastName}`;
}

export function initials(account: Account): string {
	return `${account.firstName.substring(0, 1)}${account.lastName.substring(
		0,
		1
	)}`;
}

export function age(account: Account): number {
	return yearsSince(new Date(account.birthDate));
}

export function isAnAdult(account: Account): boolean {
	return age(account) >= 18;
}

export function hasFlag(account: Account, flag: AccountFlags): boolean {
	return account.flags.includes(flag);
}

export function hasNotificationSetting(
	account: Account,
	notification: AccountNotificationSettings
): boolean {
	return account.notificationSettings.includes(notification);
}

export function needsParentalConsent(
	account: Account,
	consentAgeThreshold: number = 16
): boolean {
	return age(account) < consentAgeThreshold;
}

export function hasParentalConsent(account: Account): boolean {
	return (
		account.hasOwnProperty('parentalConsentGranted') ||
		account.hasOwnProperty('parentalConsentRevoked')
	);
}

export function parentalConsentGrantedAt(account: Account): number {
	return account?.parentalConsentGranted || 0;
}

export function parentalConsentRevokedAt(account: Account): number {
	return account?.parentalConsentRevoked || 0;
}

export async function remove(account: Account): Promise<Account> {
	if (models.canDelete(account)) {
		const request = await sdk.destroy(account.links.delete);

		const response = await request.json();

		return response;
	}

	return null;
}

export async function cancelDelete(account: Account): Promise<boolean> {
	const request = await sdk.post(account.links.undelete);

	if (!request.ok) {
		return false;
	}

	return true;
}
