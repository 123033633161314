import { t } from '@transifex/native';

import {
	AccountClearNotificationSettings,
	AccountNotificationSettings,
} from 'pkg/models/account';

import * as models from 'pkg/api/models';
import * as actions from 'pkg/actions';
import { useFetchIdentity } from 'pkg/identity';

import SectionTitle from 'components/SectionTitle';
import * as Card from 'components/Card';

import FormOption from 'components/form/Toggle';
import Column from 'components/layout/column';

interface NotificationSettingsProps {
	account: models.account.Account;
}

const NotificationSettings = ({ account }: NotificationSettingsProps) => {
	const fetchIdentity = useFetchIdentity();

	const hasPushNotifications = models.account.hasNotificationSetting(
		account,
		models.account.AccountNotificationSettings.Push
	);
	const hasEmailNotifications = models.account.hasNotificationSetting(
		account,
		models.account.AccountNotificationSettings.Email
	);

	const handlePushChange = async () => {
		await actions.accounts.patchAccount(account.id, {
			notificationSettings: hasPushNotifications
				? [AccountClearNotificationSettings.Push]
				: [AccountNotificationSettings.Push],
		});

		fetchIdentity();
	};

	const handleEmailNotificationChange = async () => {
		await actions.accounts.patchAccount(account.id, {
			notificationSettings: hasEmailNotifications
				? [AccountClearNotificationSettings.Email]
				: [AccountNotificationSettings.Email],
		});

		fetchIdentity();
	};

	return (
		<Column>
			<SectionTitle>{t(`Notifications`)}</SectionTitle>
			<Card.Base $noBorder>
				<Card.Body>
					<Column>
						<FormOption
							standAlone
							label={t(`Email notifications`)}
							description={t(
								`Receive notifications regarding posts, events and more in your email inbox.`
							)}
							onChange={handleEmailNotificationChange}
							isActive={hasEmailNotifications}
						/>
						<FormOption
							standAlone
							label={t(`Push notifications`)}
							description={t(
								`Get instant push notifications to your mobile device.`
							)}
							onChange={handlePushChange}
							isActive={hasPushNotifications}
						/>
					</Column>
				</Card.Body>
			</Card.Base>
		</Column>
	);
};

export default NotificationSettings;
