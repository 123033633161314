import { Map, List, Record } from 'immutable';

/* @imports */
import SessionBlock from 'pkg/models/session_block';

import { SessionBlocks } from 'pkg/actions/action-types';

/* @actions */

const initialState = new Record(
	{
		entities: new Map(),
		result: new List([]),
		modes: new Map({}),
	},
	'sessionBlocks'
);

const initialMode = new Map({
	isNew: false,
	hasChanges: false,
	isDiscarded: false,
});

const setModeReducer = (state, action) =>
	state.setIn(
		['modes', action.blockId],
		state.getIn(['modes', action.blockId], initialMode).merge(action.payload)
	);

const unsetModeReducer = (state, action) =>
	state.deleteIn(['modes', action.sessionId]);

const setBlocksReducer = (state, action) => {
	const data = action.payload.entities.sessionBlocks;
	const entities = Object.values(data);

	entities.forEach((entity) => {
		state = setModeReducer(state, {
			blockId: entity.id,
			payload: {},
		}).setIn(['entities', entity.id], new SessionBlock(entity));
	});

	return state;
};

const deleteBlockReducer = (state, { blockId }) =>
	state
		.setIn(
			['modes', blockId],
			state.getIn(['modes', blockId]).merge({
				isDiscarded: true,
			})
		)
		.deleteIn(['entities', blockId]);

const deleteBlocksBySessionReducer = (state, action) =>
	state.set(
		'entities',
		state
			.get('entities')
			.filter((entity) => entity.get('trainingSessionId') !== action.sessionId)
	);

const sessionBlocksReducer = (state = initialState(), action = {}) => {
	switch (action.type) {
		case SessionBlocks.SET_ITEMS:
			return setBlocksReducer(state, action);
		case SessionBlocks.DELETE_ITEM:
			return deleteBlockReducer(state, action);
		case SessionBlocks.DELETE_ITEMS:
			return deleteBlocksBySessionReducer(state, action);

		case SessionBlocks.SET_MODE:
			return setModeReducer(state, action);
		case SessionBlocks.UNSET_MODE:
			return unsetModeReducer(state, action);
		default:
			return state;
	}
};

export default sessionBlocksReducer;
