import { ReactNode } from 'react';

export const symbol_splitscreen_add: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-180v-188 33-5 160Zm0 60q-24 0-42-18t-18-42v-188q0-24 18-42t42-18h600q24 0 42 18t18 42H180v188h410q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T590-120H180Zm0-413q-24 0-42-18t-18-42v-187q0-24 18-42t42-18h600q24 0 42 18t18 42v187q0 24-18 42t-42 18H180Zm0-60h600v-187H180v187Zm0 0v-187 187Zm600 473h-50q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T730-180h50v-50q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T840-230v50h50q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T890-120h-50v50q0 12.75-8.675 21.375Q822.649-40 809.825-40 797-40 788.5-48.625T780-70v-50Z"
		/>
	</svg>
);
