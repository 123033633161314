import { ReactNode } from 'react';

export const symbol_footprint: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M315-240q-77 0-117-57t-38-128l-18-27q-11-17-36.5-77T80-680q0-103 51-171.5T260-920q85 0 132.5 75.5T440-680q0 58-16 107t-28 79l8 13q8 14 22 44.5t14 63.5q0 57-35.5 95T315-240ZM195-481l144-30q14-38 27.5-84t13.5-85q0-67-31.5-123.5T260-860q-52 0-86 51t-34 129q0 78 23 131.5t32 67.5Zm120 181q28 0 46.5-20.5T380-373q0-20-9-41.5T351-453l-131 27q-1 43 21.5 84.5T315-300ZM645-40q-54 0-89.5-38T520-173q0-33 14-63.5t22-44.5l8-13q-12-30-28-79t-16-107q0-89 47.5-164.5T700-720q78 0 129 68.5T880-480q0 91-25.5 150.5T818-253l-18 28q1 71-38.5 128T645-40Zm120-242q10-15 32.5-68T820-480q0-78-34-129t-86-51q-57 0-88.5 56.5T580-480q0 40 14 85.5t28 83.5l143 29ZM645-100q51 0 73-42t22-85l-131-26q-10 17-19.5 38.5T580-173q0 32 18.5 52.5T645-100Z"
		/>
	</svg>
);
