// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xxMdjUK_zvAuqlIGofBs {
	text-decoration: underline;
	cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./routes/payments/products/single/styles.css"],"names":[],"mappings":"AAAA;CACC,0BAA0B;CAC1B,eAAe;AAChB","sourcesContent":[".active {\n\ttext-decoration: underline;\n\tcursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
export var active = `xxMdjUK_zvAuqlIGofBs`;
export default ___CSS_LOADER_EXPORT___;
