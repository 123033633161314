import { ReactNode } from 'react';

export const symbol_e911_emergency: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M240-160q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T240-220h31l84-277q6-19 21.5-31t35.5-12h136q20 0 35.5 12t21.5 31l84 277h31q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T720-160H240Zm95-60h290l-78-260H413l-78 260Zm115-470v-120q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-810v120q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T450-690Zm214 64 85-85q9-8 21.1-8.5 12.1-.5 20.9 8.5 9 9 9 21t-9 21l-85 86q-9 9-21 9t-21-9.053q-9-9.052-9-21.5Q655-617 664-626Zm106 196h120q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T890-370H770q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T770-430ZM254-584l-85-85q-8-9-8.5-21.1-.5-12.1 8.5-20.9 9-9 21-9t21 9l86 85q9 9 9 21t-9.053 21q-9.052 9-21.5 9Q263-575 254-584ZM70-370q-12.75 0-21.375-8.675Q40-387.351 40-400.175 40-413 48.625-421.5T70-430h120q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T190-370H70Zm410 150Z"
		/>
	</svg>
);
