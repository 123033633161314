import { ReactNode } from 'react';

export const symbol_compare_arrows: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M396-323H110q-13 0-21.5-8.5T80-353q0-13 8.5-21.5T110-383h286L296-483q-9-9-9-21t9-21q9-9 21-9t21 9l151 151q5 5 7 10t2 11q0 6-2 11t-7 10L338-181q-9 9-21 9t-21-9q-9-9-9-21t9-21l100-100Zm168-254 100 100q9 9 9 21t-9 21q-9 9-21 9t-21-9L471-586q-5-5-7-10t-2-11q0-6 2-11t7-10l151-151q9-9 21-9t21 9q9 9 9 21t-9 21L564-637h286q13 0 21.5 8.5T880-607q0 13-8.5 21.5T850-577H564Z"
		/>
	</svg>
);
