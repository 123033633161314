import { t } from '@transifex/native';

import { palette } from 'pkg/config/styles';

import { AbsenceDataTypes } from 'pkg/api/models/event_report';
import * as models from 'pkg/api/models';
import DateTime from 'pkg/datetime';

import Row from 'components/layout/row';

import * as Chart from 'design/chart';
import * as DataCard from 'design/data_card';

interface DatePeriodMap {
	[key: string]: { [key in AbsenceDataTypes]: number };
}

interface AbsenceReasonWidgetProps {
	fromDateTime: DateTime;
	toDateTime: DateTime;
	localeOptions: Intl.DateTimeFormatOptions;

	dashboardData: models.eventReport.AttendanceDashboardData;
}

export default function AbsenceReasonWidget({
	dashboardData,
	fromDateTime,
	toDateTime,
	localeOptions,
}: AbsenceReasonWidgetProps) {
	let totalInjured = 0;
	let totalSick = 0;
	let totalOtherAbsence = 0;

	const datePeriods: DatePeriodMap = {};

	if (dashboardData.periodizedAbsenceData?.length > 0) {
		dashboardData.periodizedAbsenceData.forEach((d) => {
			switch (d.dataType) {
				case AbsenceDataTypes.Injured:
					totalInjured = d.periodizedData.total.currentPeriodTotal;
					break;
				case AbsenceDataTypes.Other:
					totalOtherAbsence = d.periodizedData.total.currentPeriodTotal;
					break;
				default:
					totalSick = d.periodizedData.total.currentPeriodTotal;
			}

			Object.entries(d.periodizedData.datePeriods).forEach(([key, value]) => {
				if (key in datePeriods) {
					datePeriods[key][d.dataType] += value.currentPeriod;
				} else {
					datePeriods[key] = {
						sick: 0,
						injured: 0,
						other: 0,
					};

					datePeriods[key][d.dataType] += value.currentPeriod;
				}
			});
		});
	}

	const linearChartItems: Chart.ChartItem[] = Object.entries(datePeriods).map(
		([key, value]) => {
			return {
				title: new DateTime(new Date(key)).toLocaleDateString(localeOptions),
				injuredValue: value.injured,
				sickValue: value.sick,
				otherValue: value.other,
			} as Chart.ChartItem;
		}
	);

	const statusBreakdown: DataCard.BreakdownItem[] = [
		{
			title: t('Injured'),
			color: 'red',
			variant: 'label',
			value: totalInjured.toString(),
			diff: t('{amount} events', {
				amount: dashboardData.absenceInjuredEventCount,
			}),
		},
		{
			title: t('Illness'),
			color: 'orange',
			variant: 'label',
			value: totalSick.toString(),
			diff: t('{amount} events', {
				amount: dashboardData.absenceIllnessEventCount,
			}),
		},
		{
			title: t('Other'),
			color: 'gray',
			variant: 'label',
			value: totalOtherAbsence.toString(),
			diff: t('{amount} events', {
				amount: dashboardData.absenceOtherEventCount,
			}),
		},
	];

	return (
		<DataCard.Base
			title={t(`Didn't attend reasons`)}
			titleIcon="personal_injury"
			heading={(totalInjured + totalSick + totalOtherAbsence).toString()}
			headingSuffix={t('non-attendees')}
			footer={
				<Row autoColumns="max-content" justifyContent="space-between">
					<span>
						{fromDateTime.toLocaleDateString({
							month: 'short',
							day: 'numeric',
							year: 'numeric',
						})}
					</span>
					<span>
						{toDateTime.toLocaleDateString({
							month: 'short',
							day: 'numeric',
							year: 'numeric',
						})}
					</span>
				</Row>
			}>
			<DataCard.Breakdown items={statusBreakdown} />
			<Chart.MultiLine
				items={linearChartItems}
				lineProps={[
					{
						value: 'injuredValue',
						fillColor: palette.red[400],
						tooltipTranslation: t('Injured'),
					},
					{
						value: 'sickValue',
						fillColor: palette.orange[400],
						tooltipTranslation: t('Illness'),
					},
					{
						value: 'otherValue',
						fillColor: palette.gray[500],
						tooltipTranslation: t('Other'),
					},
				]}
			/>
		</DataCard.Base>
	);
}
