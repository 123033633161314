import styled from 'styled-components';
import { ReactNode } from 'react';

import Icon, { IconName } from 'components/icon';

const Wrapper = styled.div`
	padding: var(--spacing-8) 0;
	display: grid;
	grid-auto-flow: row;
	gap: var(--spacing-5);
	place-items: center;
`;

const Heading = styled.div`
	font-size: var(--font-size-lg);

	mark {
		background-color: var(--palette-green-300);
		border-radius: var(--radius-3);
		box-shadow: 0 0 0 2px var(--palette-green-300);
	}
`;

interface EmptySearchProps {
	icon: IconName;
	heading: string | ReactNode;
	children?: ReactNode | ReactNode[];
}

export default function EmptySearch({
	icon,
	heading,
	children,
}: EmptySearchProps): JSX.Element {
	return (
		<Wrapper>
			<Icon name={icon} size={2} />
			<Heading>{heading}</Heading>
			{children}
		</Wrapper>
	);
}
