import { t } from '@transifex/native';
import { normalize } from 'normalizr';
import { batch } from 'react-redux';

import MatchEvent from 'pkg/models/match_event';

import { MatchEvents } from 'pkg/actions/action-types';
import { show } from 'pkg/actions/flashes';
import { addUserEntities } from 'pkg/actions/users';
import {
	getMatchEvents,
	getMatchEventTypes,
	createMatchEvent,
	updateMatchEvent,
	deleteMatchEvent,
} from 'pkg/actions/services/match_events.service';
import { updateMatch, fetchMatchById } from 'pkg/actions/matches_deprecated';

import store from 'pkg/store/createStore';

export const addMatchEventTypes = (payload) => ({
	type: MatchEvents.ADD_EVENT_TYPES,
	payload,
});

export const removeMatchEventGuids = (matchEventGuids) => ({
	type: MatchEvents.REMOVE_GUIDS,
	matchEventGuids,
});

export const addMatchEventGuids = (matchEvent, guids) => ({
	type: MatchEvents.ADD_GUIDS,
	matchEvent,
	guids,
});

export const addLeadingMatchEventGuid = (
	matchEventId,
	leadingMatchEventId
) => ({
	type: MatchEvents.ADD_LEADING_GUID,
	matchEventId,
	leadingMatchEventId,
});

export const updateMatchEventUser = (matchEventId, userId) => ({
	type: MatchEvents.UPDATE_USER,
	matchEventId,
	userId,
});

export const updateMatchEventMinute = (
	matchEventId,
	minute,
	extraMinute = 0
) => ({
	type: MatchEvents.UPDATE_MINUTE,
	matchEventId,
	minute,
	extraMinute,
});

export const updateMatchEventComment = (matchEventId, comment) => ({
	type: MatchEvents.UPDATE_COMMENT,
	matchEventId,
	comment,
});

export const updateMatchEventPosition = (matchEventId, positionId) => ({
	type: MatchEvents.UPDATE_POSITION,
	matchEventId,
	positionId,
});

export const updateMatchEventSubstitutionUser = (matchEventId, userId) => ({
	type: MatchEvents.UPDATE_SUBSTITUTION_USER,
	matchEventId,
	userId,
});

export const openEventEditModal = (eventType, eventId) => ({
	type: MatchEvents.EDIT_MODAL_OPEN,
	eventType,
	eventId,
});

export const submitEventEdit = () => ({
	type: MatchEvents.EDIT_DONE,
});

export const cancelEventEdit = () => ({
	type: MatchEvents.EDIT_CANCEL,
});

export const fetchMatchEvents = (matchId) => async (dispatch) => {
	const request = await getMatchEvents(matchId);

	if (!request.ok) {
		return;
	}

	const response = await request.json();
	const data = normalize(response.records, [MatchEvent.normalizr()]);

	if (data.entities.matchEvents) {
		dispatch(addMatchEvents(data));
	}

	if (data.entities.users) {
		dispatch(addUserEntities(data));
	}
};

export const addMatchEvents = (payload) => ({
	type: MatchEvents.ADD_EVENTS,
	payload,
});

export const fetchMatchEventTypes = () => async (dispatch) => {
	const request = await getMatchEventTypes();

	if (!request.ok) {
		return;
	}

	const response = await request.json();

	dispatch(addMatchEventTypes(response.typeKeys));
};

export const createMatchEvents = (matchId, payload) => {
	return async (dispatch) => {
		const request = await createMatchEvent(matchId, payload);

		if (request.ok) {
			const response = await request.json();

			if (response.events) {
				await dispatch(fetchMatchEvents(matchId));

				const flash = {
					title: t(`Match events successfully created!`),
				};

				show(flash);

				return response;
			}
		}
	};
};

export const removeGuids = (eventsToRemove) => (dispatch) => {
	const guids = eventsToRemove.map((item) => item.guid);

	dispatch(removeMatchEventGuids(guids));
};

export const removeMatchEvent = (matchEventId) => {
	return async (dispatch) => {
		const state = store.getState();
		const matchEvent = state.matchEvents.entities.get(matchEventId);

		if (state.matchEvents.getIn(['modes', matchEventId, 'newEntity'])) {
			dispatch({
				type: MatchEvents.REMOVE_SINGLE_EVENT,
				matchEventId,
			});

			return;
		}

		const request = await deleteMatchEvent(matchEventId);

		if (request.ok) {
			dispatch({
				type: MatchEvents.REMOVE_SINGLE_EVENT,
				matchEventId,
			});

			dispatch(updateMatch(matchEvent.matchId, false));
		}
	};
};

export const updateMatchEvents = (matchId, payload) => async (dispatch) => {
	let results = payload.events.map((event) =>
		updateMatchEvent(event.id, event)
	);

	results = await Promise.all(results);
	dispatch(fetchMatchEvents(matchId));

	return results;
};

export const resetMatchEvents = (matchId) => async (dispatch) => {
	const { modes } = store.getState().matchEvents;
	const newEntities = modes.filter((mode) => mode.get('newEntity'));

	batch(() => {
		dispatch(removeMatchEventGuids([...newEntities.keys()]));
		dispatch(fetchMatchEvents(matchId));
		dispatch(fetchMatchById(matchId));
	});
};
