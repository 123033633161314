import styled, { css } from 'styled-components';
import { ReactNode } from 'react';

import * as breakpoints from 'pkg/config/breakpoints';
import * as palette from 'pkg/config/palette';

import rgba from 'pkg/rgba';
import { Slugs } from 'pkg/api/models/position';

import { Body } from 'components/Card';

export const FieldBody = styled(Body)``;

export const AvatarWrapper = styled.div`
	width: 35px;
	height: 35px;
	margin-bottom: 5px;

	@media ${breakpoints.small} {
		width: 30px;
		height: 30px;
		margin-bottom: 4px;
	}
`;

export const Section = styled.div<{ allowOverflow?: boolean }>`
	position: absolute;
	text-align: center;
	padding: 0;
	font-weight: var(--font-weight-semibold);
	color: ${palette.gray[800]};
	border-radius: 0;
	z-index: 0;
	border-radius: var(--radius-3);
	overflow: ${(props) => (props.allowOverflow ? 'unset' : 'hidden')};
	display: flex;
	justify-content: space-around;
	align-items: center;
`;

export const LineupContainer = styled.div<{
	outline?: boolean;
	opacity?: number;
}>`
	background-color: ${(props) =>
		props.outline ? 'transparent' : palette.green[500]};
	width: 100%;
	position: relative;
	user-select: none;
	font-size: var(--font-size-base);
	border-radius: var(--radius-2);

	&::before {
		content: '';
		display: block;
		padding-top: 120%;

		@media ${breakpoints.small} {
			padding-top: 140%;
		}
	}

	${(props) =>
		props.outline &&
		css`
			border: 3px solid ${rgba(palette.gray[300], props.opacity)};
			border-radius: 0;
		`};
`;

export const Sections = styled.div`
	position: absolute;
	top: 16px;
	bottom: 16px;
	left: 16px;
	right: 16px;
	border-color: ${rgba(palette.white, 0.3)};
	border-style: solid;
	border-width: 4px;

	@media ${breakpoints.small} {
		border-width: 2px;
		top: 12px;
		bottom: 12px;
		left: 12px;
		right: 12px;
	}
`;

export const Player = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: row wrap;
	color: ${palette.white};
	font-weight: var(--font-weight-normal);

	${AvatarWrapper} {
		@media ${breakpoints.small} {
			display: none;
		}
	}

	p {
		width: 100%;
		font-size: 0.933rem;
		margin-bottom: 3px;

		@media ${breakpoints.small} {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 70px;
		}
	}

	small {
		font-size: 0.733rem;
		color: ${rgba(palette.white, 0.5)};
	}
`;

export const Field = styled.div<{ outline?: boolean }>`
	position: absolute;
	top: 20px;
	right: 20px;
	bottom: 20px;
	left: 20px;

	@media ${breakpoints.small} {
		top: 14px;
		right: 12px;
		bottom: 14px;
		left: 12px;
	}

	${(props) =>
		props.outline &&
		css`
			top: 0px;
			right: 0px;
			bottom: 0px;
			left: 0px;

			@media ${breakpoints.small} {
				top: 0px;
				right: 0px;
				bottom: 0px;
				left: 0px;
			}
		`};
`;

export const PlayerSections: { [key in Slugs]?: any } = {};

const WingBase = styled(Section)`
	width: 22.5%;
	height: calc((100% / 7) * 2);
	flex-flow: column wrap;

	@media ${breakpoints.small} {
		width: 20%;
	}
`;

const MidBase = styled(Section)`
	flex-flow: row wrap;
	width: 55%;
	height: calc(100% / 7);
	left: 22.5%;

	@media ${breakpoints.small} {
		width: 60%;
		left: 20%;
	}

	${Player} {
		@media ${breakpoints.small} {
			flex: 0;
		}
	}
`;

PlayerSections.lw = styled(WingBase)`
	top: 0;
	left: 0;
`;

PlayerSections.st = styled(MidBase)`
	top: 0;
`;

PlayerSections.cf = styled(MidBase)`
	bottom: calc(100% / 7 * 5);
`;

PlayerSections.rw = styled(WingBase)`
	top: 0;
	right: 0;
`;

PlayerSections.lm = styled(WingBase)`
	left: 0;
	bottom: calc((100% / 7) * 3);
`;

PlayerSections.cam = styled(MidBase)`
	bottom: calc(100% / 7 * 4);
`;

PlayerSections.cm = styled(MidBase)`
	bottom: calc(100% / 7 * 3);
`;

PlayerSections.rm = styled(WingBase)`
	right: 0;
	bottom: calc((100% / 7) * 3);
`;

PlayerSections.lb = styled(WingBase)`
	left: 0;
	bottom: calc(100% / 7);
`;

PlayerSections.cdm = styled(MidBase)`
	bottom: calc(100% / 7 * 2);
`;

PlayerSections.cb = styled(MidBase)`
	bottom: calc(100% / 7);
`;

PlayerSections.rb = styled(WingBase)`
	right: 0;
	bottom: calc(100% / 7);
`;

PlayerSections.gk = styled(MidBase)`
	bottom: 0;
`;

export const SelectablePlayerSections: { [key in Slugs]?: any } = {};

const selectableStyles = css<{ selected?: boolean }>`
	border: 1px solid ${palette.white};
	cursor: pointer;
	flex-flow: column wrap;
	font-weight: var(--font-weight-normal);
	background: ${(props) =>
		props.selected ? palette.green[600] : palette.green[500]};
`;

SelectablePlayerSections.lw = styled(PlayerSections.lw)`
	${selectableStyles};
`;

SelectablePlayerSections.st = styled(PlayerSections.st)`
	${selectableStyles};
`;

SelectablePlayerSections.cf = styled(PlayerSections.cf)`
	${selectableStyles};
`;

SelectablePlayerSections.rw = styled(PlayerSections.rw)`
	${selectableStyles};
`;

SelectablePlayerSections.lm = styled(PlayerSections.lm)`
	${selectableStyles};
`;

SelectablePlayerSections.cam = styled(PlayerSections.cam)`
	${selectableStyles};
`;

SelectablePlayerSections.cm = styled(PlayerSections.cm)`
	${selectableStyles};
`;

SelectablePlayerSections.rm = styled(PlayerSections.rm)`
	${selectableStyles};
`;

SelectablePlayerSections.lb = styled(PlayerSections.lb)`
	${selectableStyles};
`;

SelectablePlayerSections.cdm = styled(PlayerSections.cdm)`
	${selectableStyles};
`;

SelectablePlayerSections.cb = styled(PlayerSections.cb)`
	${selectableStyles};
`;

SelectablePlayerSections.rb = styled(PlayerSections.rb)`
	${selectableStyles};
`;

SelectablePlayerSections.gk = styled(PlayerSections.gk)`
	${selectableStyles};
`;

export const Line = styled.div<{ outline?: boolean; opacity?: number }>`
	position: absolute;
	border: 4px solid
		${(props) =>
			props.outline
				? rgba(palette.gray[300], props.opacity)
				: rgba(palette.white, 0.3)};

	${(props) =>
		props.outline &&
		css`
			border-width: 3px;
		`};

	@media ${breakpoints.small} {
		border-width: 2px;
	}
`;

export const Lines: {
	goalCircle?: any;
	goalAreaOuter?: any;
	goalAreaInner?: any;
	centerLine?: any;
	centerCircle?: any;
} = {};

interface LineProps {
	bottom?: boolean;
	outline?: boolean;
	size?: string;
}

Lines.goalCircle = styled(Line)<LineProps>`
	display: none;
	margin-left: 40%;
	top: ${(props) => (props.bottom ? '82.7%' : '10%')};
	width: 20%;
	height: 7%;
	border-radius: ${(props) => (props.bottom ? '48%' : '50%')};
`;

Lines.goalAreaOuter = styled(Line)<LineProps>`
	margin-left: 25%;
	width: 50%;
	height: calc(100% / 7);
	background-color: ${(props) =>
		props.outline ? 'transparent' : palette.green[500]};

	${(props) =>
		!props.bottom &&
		css`
			border-top-width: 0;

			@media ${breakpoints.small} {
				border-top-width: 0;
			}
		`};

	${(props) =>
		props.bottom &&
		css`
			top: calc((100% / 7) * 6);
			border-bottom-width: 0;

			@media ${breakpoints.small} {
				border-bottom-width: 0;
			}
		`};
`;

Lines.goalAreaInner = styled(Line)<LineProps>`
	margin-left: 34%;
	width: 32%;
	height: 8.6%;

	${(props) =>
		!props.bottom &&
		css`
			border-top-width: 0;

			@media ${breakpoints.small} {
				border-top-width: 0;
			}
		`};

	${(props) =>
		props.bottom &&
		css`
			bottom: 0;
			border-bottom-width: 0;

			@media ${breakpoints.small} {
				border-bottom-width: 0;
			}
		`};
`;

Lines.centerLine = styled(Line)<LineProps>`
	position: absolute;
	width: 100%;
	height: 3px;
	border: none;
	background-color: ${(props) =>
		props.outline
			? rgba(palette.gray[300], props.opacity)
			: rgba(palette.white, 0.3)};

	margin-top: auto;
	margin-bottom: auto;
	top: 0;
	bottom: 0;

	@media ${breakpoints.small} {
		border-width: 1px;
		${(props) =>
			!props.outline &&
			css`
				width: calc(100% - 4px);
				left: 2px;
			`};
	}

	${(props) =>
		props.outline &&
		css`
			width: calc(100% - 4px);
			left: 2px;
		`};
`;

Lines.centerCircle = styled(Line)<LineProps>`
	margin-top: auto;
	margin-bottom: auto;
	top: 0;
	bottom: 0;
	margin-left: 37.5%;
	width: 25%;
	padding-top: calc(25% - 8px);
	height: 0;
	border-radius: 50%;

	${(props) =>
		props.size === 'small' &&
		css`
			padding-top: calc(29% - 4px);
			width: 29%;
			margin-left: 35.75%;
		`};

	@media ${breakpoints.small} {
		padding-top: calc(29% - 4px);
		width: 29%;
		margin-left: 35.75%;
	}
`;

interface FootballFieldProps {
	size?: string;
	outline?: boolean;
	opacity?: number;
	className?: string;
	children: ReactNode;
}

export const FootballField = ({
	size,
	outline,
	opacity = 1,
	className,
	children,
}: FootballFieldProps) => {
	const fieldProps = {
		size: size,
		outline: outline,
		opacity: opacity,
	};

	return (
		<LineupContainer {...fieldProps} className={className}>
			<Field {...fieldProps}>
				<Lines.goalCircle {...fieldProps} />
				<Lines.goalAreaOuter {...fieldProps} />
				<Lines.goalAreaInner {...fieldProps} />

				<Lines.centerCircle {...fieldProps} />
				<Lines.centerLine {...fieldProps} />

				<Lines.goalCircle bottom {...fieldProps} />
				<Lines.goalAreaOuter bottom {...fieldProps} />
				<Lines.goalAreaInner bottom {...fieldProps} />
			</Field>
			{children}
		</LineupContainer>
	);
};
