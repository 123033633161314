import { ReactNode } from 'react';

export const symbol_wifi_notification: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M693-620q-46 0-87 15t-74 41q-10 8-22.5 8t-21.5-9q-9-9-8-22t11-21q42-34 93.5-53T694-680q58 0 109 19t93 53q10 8 10.5 21t-8.5 22q-9 9-21 9t-22-8q-33-26-74.5-41T693-620Zm1 120q-21 0-40.5 6T617-477q-11 7-22.5 6.5T574-480q-9-9-8.5-21t10.5-20q25-18 54.5-28.5T693-560q33 0 63.5 10.5T812-521q10 8 10.5 20t-8.5 21q-9 9-21 9t-23-7q-17-11-36-16.5t-40-5.5Zm0 140q-17 0-28.5-11.5T654-400q0-17 11.5-28.5T694-440q17 0 28.5 11.5T734-400q0 17-11.5 28.5T694-360ZM480-80q-33 0-56.5-23.5T400-160h160q0 33-23.5 56.5T480-80ZM240-260v-304q0-84 49.5-150.5T420-798v-22q0-25 17.5-42.5T480-880q25 0 42.5 17.5T540-820v22q32 7 59.5 22t50.5 38q-18.909 4.148-37.455 9.074Q594-724 576-717q-22-14-45.871-20.5Q506.258-744 480-744q-75 0-127.5 52.5T300-564v304h470q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T770-200H190q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T190-260h50Zm265-242Z"
		/>
	</svg>
);
