import { ReactNode } from 'react';

export const symbol_quick_phrases: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M370-102q-11 0-20.5-8t-9.5-22v-149h-10q-106 0-178-76.5T80-540q0-109 75.5-184.5T340-800h53l-56-57q-8-9-8.5-21t8.5-21q9-9 21-9t21 9l108 108q5 5 7 10t2 11q0 6-2 11t-7 10L379-641q-9 9-21 9t-21-9q-9-9-8.5-21t8.5-21l56-57h-53q-83 0-141.5 58.5T140-540q0 82 54.5 141T330-340h70v135l136-135h85q83 0 141-58.5T820-541q0-83-58.5-141T620-740q-13 0-21.5-8.5T590-770q0-13 8.5-21.5T620-800q108 0 184 75.5T880-541q0 109-75.5 185T621-280h-60L391-111q-5 5-10.5 7t-10.5 2Z"
		/>
	</svg>
);
