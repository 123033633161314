import { ReactNode } from 'react';

export const symbol_mic_external_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M356-683 193-846q17-17 37.5-25.5T274-880q48 0 81 33.5t33 81.5q0 26-8 47t-24 35Zm444 443-60-60v-426q0-39-28-66.5T645-820q-39 0-67 27.5T550-726v237l-60-60v-177q0-64 45.5-109T645-880q64 0 109.5 45T800-726v486Zm13 183L550-320v85q0 65-45 110T396-80q-65 0-110-45t-45-110h-7q-11 0-20-8t-10-19l-34-356q-2-14 7-23.5t23-9.5h18L49-821q-9-9-9-21t9-21q9-9 21-9t21 9L855-99q9 9 9 21t-9 21q-9 9-21 9t-21-9ZM261-295h26l25-262-35-34h-44l28 296Zm135 155q39 0 66.5-28t27.5-67v-145L367-503l-23 241q-1 11-10 19t-20 8h-13q0 39 28 67t67 28ZM261-591h-28 79-51Z"
		/>
	</svg>
);
