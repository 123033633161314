import { ReactNode } from 'react';

export const symbol_deck: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M450-110v-490H128q-11 0-14.5-10.5T119-627l344-241q8.171-5 17.086-5Q489-873 497-868l344 241q9 6 5.5 16.5T832-600H510v490q0 12.75-8.675 21.375Q492.649-80 479.825-80 467-80 458.5-88.625T450-110Zm30-550h210-420 210ZM120-110v-181.075L93-437q-2-12 5.5-22t19.864-12q11.636-2 21.136 4.5T151-448l27 138h132q12.75 0 21.375 8.625T340-280v170q0 12.75-8.675 21.375Q322.649-80 309.825-80 297-80 288.5-88.625T280-110v-140H180v140q0 12.75-8.675 21.375Q162.649-80 149.825-80 137-80 128.5-88.625T120-110Zm500 0v-170q0-12.75 8.625-21.375T650-310h132l27-138q2-12 12-18.5t22-4.5q12 2 19 12t5 22l-27 145.925V-110q0 12.75-8.675 21.375Q822.649-80 809.825-80 797-80 788.5-88.625T780-110v-140H680v140q0 12.75-8.675 21.375Q662.649-80 649.825-80 637-80 628.5-88.625T620-110ZM270-660h420L480-807 270-660Z"
		/>
	</svg>
);
