import { ReactNode } from 'react';

export const symbol_cloudy_snowing: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M240-200q-14 0-24.5-10.5T205-235q0-14 10.5-24.5T240-270q14 0 24.5 10.5T275-235q0 14-10.5 24.5T240-200Zm480 0q-14 0-24.5-10.5T685-235q0-14 10.5-24.5T720-270q14 0 24.5 10.5T755-235q0 14-10.5 24.5T720-200ZM360-40q-14 0-24.5-10.5T325-75q0-14 10.5-24.5T360-110q14 0 24.5 10.5T395-75q0 14-10.5 24.5T360-40Zm120-160q-14 0-24.5-10.5T445-235q0-14 10.5-24.5T480-270q14 0 24.5 10.5T515-235q0 14-10.5 24.5T480-200ZM600-40q-14 0-24.5-10.5T565-75q0-14 10.5-24.5T600-110q14 0 24.5 10.5T635-75q0 14-10.5 24.5T600-40ZM290-340q-86.864 0-148.432-61.52Q80-463.04 80-549.835 80-629 136.5-691 193-753 277-759q32-56 84.5-88.5T480.423-880Q571-880 632.5-822.5T708-680q79 4 125.5 53.5T880-510.377Q880-440 830.417-390 780.833-340 710-340H290Zm0-60h420q46.2 0 78.1-32.5 31.9-32.5 31.9-78T788.1-588q-31.9-32-78.1-32h-60v-30q0-71-49.5-120.5T480.212-820q-51.481 0-93.847 27.5Q344-765 324-718l-8 18h-28q-62 2-105 45.393t-43 104.464Q140-488 183.929-444 227.857-400 290-400Zm190-210Z"
		/>
	</svg>
);
