import { t } from '@transifex/native';
import { Dispatch } from 'redux';

import UserGoal from 'pkg/models/user_goal';

import * as goalEndpoints from 'pkg/actions/services/user_goals.service';
import { triggerError } from 'pkg/actions/app';
import { forAccountHeaders, normalizedDispatch } from 'pkg/actions/utils';
import * as actionTypes from 'pkg/actions/action-types';
import { show } from 'pkg/actions/flashes';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as sdk from 'pkg/core/sdk';

interface DeleteUserGoalAction {
	type: string;
	goalId: number;
}

export const removeUserGoal = (goalId: number): DeleteUserGoalAction => ({
	type: actionTypes.UserGoals.REMOVE_ITEM,
	goalId,
});

export const fetchUserGoals =
	(userId: number, completed?: number, forAccount?: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		const request = await goalEndpoints.getGoals(
			userId,
			{
				'include-completed': completed ? 1 : 0,
			},
			forAccount
		);

		if (!request.ok) {
			triggerError(request)(dispatch);
			return;
		}

		const response = await request.json();
		normalizedDispatch(response.records, [UserGoal.normalizr()])(dispatch);
	};

export const createGoal =
	(userId: number, goal: UserGoal, forAccount?: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		const response = await goalEndpoints.createGoal(userId, goal, forAccount);

		if (!response.ok) {
			show({
				title: t(`Failed to create goal`),
			});

			return;
		}

		show(
			{
				title: t(`Goal created`),
			},
			200
		);

		const savedGoal = await response.json();

		normalizedDispatch(savedGoal, UserGoal.normalizr())(dispatch);
	};

export const updateGoal =
	(goal: UserGoal, forAccount?: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		const request = await goalEndpoints.patchGoal(goal.id, goal, forAccount);

		if (!request.ok) {
			show({
				title: t(`Failed trying to update goal`),
			});

			return;
		}

		show({
			title: t(`Goal updated`),
		});

		const response = await request.json();

		normalizedDispatch(response, UserGoal.normalizr())(dispatch);
	};

export const removeGoal =
	(goal: UserGoal, forAccount?: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		const headers = forAccountHeaders(forAccount);

		const request = await sdk.destroy(
			endpoints.UserGoals.Delete(goal.id),
			{},
			{},
			headers
		);

		if (!request.ok) {
			show({
				title: t(`Could not remove goal`),
			});

			return;
		}

		show(
			{
				title: t(`Successfully removed goal`),
			},
			200
		);

		dispatch(removeUserGoal(goal.id));
	};
