import { createRef, Component } from 'react';
import styled from 'styled-components';

import * as styles from 'pkg/config/styles';

import { SliderOuter } from 'components/swiper/Slide';

const TopSwiper = styled.div.attrs(({ x, itemWidth }) => ({
	style: {
		transform: `translateX(${x}px)`,
		width: `${itemWidth}px`,
	},
}))`
	grid-area: top;
	display: grid;
	position: relative;
	grid-template-columns: ${(props) =>
		`repeat(${props.items}, ${props.itemWidth}px)`};

	overflow-x: scroll;
	overflow-y: hidden;
	align-items: center;
	-webkit-overflow-scrolling: touch;

	@media (pointer: coarse) {
		scroll-snap-type: x mandatory;
		scroll-snap-destination: 0% 100%;
		scroll-snap-points-x: repeat(100%);
	}

	@media (pointer: fine) {
		overflow: hidden;
	}

	${SliderOuter} {
		scroll-snap-align: center;
	}
`;

export default class Swipeable extends Component {
	refNode = createRef();

	static defaultProps = {
		onDrag: () => {},
	};

	state = {
		elementWidth: 0,
	};

	componentDidMount() {
		setTimeout(() => {
			this.setState({
				elementWidth:
					window.innerWidth <= styles.size.PageWidths.STANDARD
						? window.innerWidth
						: styles.size.PageWidths.STANDARD,
			});
		}, 150);
	}

	scrollToIndex = (index) => {
		const translateX = index * this.swiperWidth;
		this.refNode.current.scrollLeft = translateX;

		this.setState({
			originalX: 0,
			animating: true,
		});
	};

	scrollToNext = () => {
		let index = this.currentIndex;

		if (index >= this.props.children.length) {
			index = this.props.children.length;
		} else {
			index += 1;
		}

		this.scrollToIndex(index);
		this.props.onChangedSlide(index);
	};

	scrollToPrev = () => {
		let index = this.currentIndex;

		if (index <= 0) {
			index = 0;
		} else {
			index -= 1;
		}

		this.scrollToIndex(index);
		this.props.onChangedSlide(index);
	};

	get swiperWidth() {
		if (this.refNode.current) {
			return this.refNode.current.clientWidth;
		}

		return 0;
	}

	handleOnScroll = (event) => {
		const { scrollLeft } = event.currentTarget;
		this.props.onChangedSlide(Math.round(scrollLeft / this.swiperWidth));
	};

	get currentIndex() {
		return Math.round(this.refNode.current.scrollLeft / this.swiperWidth);
	}

	render() {
		return (
			<TopSwiper
				items={this.props.children.length}
				ref={this.refNode}
				itemWidth={this.state.elementWidth}
				onScroll={this.handleOnScroll}>
				{this.swiperWidth !== undefined && this.props.children}
			</TopSwiper>
		);
	}
}
