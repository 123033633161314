import { ReactNode } from 'react';

export const symbol_media_bluetooth_on: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M270-120q-63 0-106.5-43.5T120-270q0-63 43.5-106.5T270-420q26 0 49.5 7.5T360-390v-420q0-13 8.5-21.5T390-840h174q13 0 21.5 8.5T594-810v75q0 13-8.5 21.5T564-705H420v435q0 63-43.5 106.5T270-120Zm400-247L545-492q-7-7-6.5-15.5T546-523q7-7 15.5-7t15.5 7l103 104v-145q0-14 9.5-22t20.5-8q5 0 10.5 2t10.5 7l89 89q5 5 7 10t2 11q0 6-2 11t-7 10l-87 87 87 87q5 5 7 10t2 11q0 6-2 11t-7 10l-88 88q-5 5-10.5 7t-10.5 2q-11 0-20.5-8t-9.5-22v-145L576-211q-7 7-15 6.5t-15-7.5q-7-7-7-15.5t7-15.5l124-124Zm51 173 65-65-65-65v130Zm-1-216 65-65-65-65v130Z"
		/>
	</svg>
);
