import { ReactNode } from 'react';

export const symbol_heart_plus: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M433-490ZM40-625q0-90 60.5-152.5T250-840q57 0 105.5 26.5T440-736q42-54 89-79t101-25q78 0 132 52t68 127q2 12-5 21.5T806-628q-12 2-21.5-5T773-652q-9-55-46.5-91.5T630-780q-53 0-95 31t-67 79q-5 8-12.68 13T439-652q-9 0-16-5t-12-13q-25-48-66.5-79t-94.646-31Q184-780 142-735.5T100-625q0 39 15.5 76t53.888 84.067q38.388 47.068 104.5 110Q340-292 440-200l97-85q8-8 19.927-7.28 11.928.72 20.128 8.64Q586-275 586-262t-10 21l-95 84q-9 8-19.75 11.5t-21.5 3.5q-10.75 0-21.16-3.81Q408.179-149.619 400-158q-44-41-105.5-94t-118-113.5Q120-426 80-492.5T40-625Zm690 215H630q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T630-470h100v-100q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T790-570v100h100q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T890-410H790v100q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T730-310v-100Z"
		/>
	</svg>
);
