// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
	.TaHh5lRdM1WaOST14tow input {
		color: var(--palette-blue-500);
		cursor: pointer;
	}

.Ujijq5hRxZZcvQdyyzz_ {
	cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./components/form/date-picker/styles.css"],"names":[],"mappings":";CACC;EACC,8BAA8B;EAC9B,eAAe;CAChB;;AAGD;CACC,eAAe;AAChB","sourcesContent":[".date {\n\t& input {\n\t\tcolor: var(--palette-blue-500);\n\t\tcursor: pointer;\n\t}\n}\n\n.wrapper {\n\tcursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
export var date = `TaHh5lRdM1WaOST14tow`;
export var wrapper = `Ujijq5hRxZZcvQdyyzz_`;
export default ___CSS_LOADER_EXPORT___;
