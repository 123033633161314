import { createSelector } from 'reselect';

export const getRatingItemsEntities = (state) => state.ratingItems.entities;

export const getRatingItemsByCategory = createSelector(
	getRatingItemsEntities,
	(ratingItems) => {
		ratingItems = ratingItems.toList();

		const byCategory = {};

		for (let i = 0; i < ratingItems.size; i++) {
			const ratingItem = ratingItems.get(i);

			if (!byCategory[ratingItem.categorySlug]) {
				byCategory[ratingItem.categorySlug] = [];
			}

			byCategory[ratingItem.categorySlug].push(ratingItem);
		}

		return byCategory;
	}
);
