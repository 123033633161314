import { t } from '@transifex/native';
import { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import * as breakpoints from 'pkg/config/breakpoints';

import { makeGetActivitiesByUser } from 'pkg/selectors/activities.selector';
import { getActivitiesLinks } from 'pkg/selectors/activity_list.selector';

import {
	fetchUserActivities,
	fetchActivitiesPage,
} from 'pkg/actions/activities';

import { IdentityContext } from 'pkg/identity';
import { ApiError } from 'pkg/errors/errors';

import Icon from 'components/icon';

import { Spinner } from 'components/loaders/spinner';
import SingleActivity from 'components/user/activity/Single';

import NoEntities from 'design/placeholders/inline';

const ActivityGrid = styled.div`
	display: grid;
	grid-row-gap: 0.5rem;
	height: 100%;
	@media ${breakpoints.small} {
		grid-row-gap: 1rem;
	}
`;

const LoaderWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const MissingEntities = styled(NoEntities)`
	justify-content: center;
`;

class RecentTraining extends Component {
	static contextType = IdentityContext;

	_mounted = false;

	state = {
		loading: true,
	};

	constructor() {
		super();
	}

	async componentDidMount() {
		this._mounted = true;

		this._scrollContainer = document.querySelector('.page-scrollable');
		this._scrollContainer.addEventListener('scroll', this.handleScroll, {
			passive: true,
			capture: false,
		});

		await this.props.fetchUserActivities(
			this.props.userId,
			true,
			true,
			this.context.membership.targetUser?.accountId
		);

		if (this._mounted) {
			this.setState({ loading: false });
		}
	}

	async componentDidUpdate(prevProps) {
		if (prevProps.userId !== this.props.userId) {
			this.setState({ loading: true });

			await this.props.fetchUserActivities(
				this.props.userId,
				true,
				true,
				this.context.membership.targetUser?.accountId
			);

			if (this._mounted) {
				this.setState({ loading: false });
			}
		}
	}

	componentWillUnmount() {
		this._mounted = false;
		this._scrollContainer.removeEventListener('scroll', this.handleScroll);
	}

	handleScroll = () => {
		const scrollBarrier =
			this._scrollContainer.scrollHeight > 1000
				? this._scrollContainer.scrollHeight - 350
				: this._scrollContainer.scrollHeight - 100;

		const scrollTop =
			this._scrollContainer.getBoundingClientRect().height +
			this._scrollContainer.scrollTop;

		if (
			scrollTop >= scrollBarrier &&
			!this.state.isLoading &&
			this.props.links?.next
		) {
			this.fetchNextActivities({
				preventDefault: () => {},
				stopPropagation: () => {},
			});
		}
	};

	async fetchNextActivities(e) {
		e.preventDefault();
		e.stopPropagation();

		this.setState({ isLoading: true });

		try {
			await this.props.fetchActivitiesPage(
				this.props.links.next,
				this.context.membership.targetUser?.accountId
			);
		} catch (error) {
			throw new ApiError(error.statusText);
		} finally {
			this.setState({
				isLoading: false,
			});
		}
	}

	singleActivity = (activity) => {
		return (
			<SingleActivity
				activity={activity}
				key={activity.id}
				comments={activity.comments}
				forAccount={this.context.membership.targetUser?.accountId}
			/>
		);
	};

	render() {
		if (this.state.loading) {
			return (
				<LoaderWrapper>
					<Spinner />
				</LoaderWrapper>
			);
		}

		if (this.props.activities.length > 0) {
			return (
				<ActivityGrid>
					{this.props.activities.map(this.singleActivity)}
				</ActivityGrid>
			);
		}

		return (
			<MissingEntities>
				<Icon name="trending" />
				{t(`No activities yet`)}
			</MissingEntities>
		);
	}
}

const makeMapStateToProps = () => {
	const getActivitiesByUser = makeGetActivitiesByUser();

	return (state, props) => ({
		activities: getActivitiesByUser(state, props),
		links: getActivitiesLinks(state),
	});
};

const actions = {
	fetchUserActivities,
	fetchActivitiesPage,
};

export default connect(makeMapStateToProps, actions)(RecentTraining);
