import { ReactNode } from 'react';

export const symbol_vr180_create2d_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M820-540q24 0 42 18t18 42v204q0 20.106-18.5 27.553Q843-241 829-255q-5-5-7-10.217-2-5.218-2-10.783v-204H616q-15 0-22.5-9.318t-7.5-20.5q0-11.182 7.5-20.682Q601-540 616-540h204ZM612-272ZM480-80q-24 0-42-18t-18-42v-324L180-704q-19 29-29.5 63.726T140-568.151Q140-480 194.5-412.5 249-345 331-314q12 5 18 16t3 23q-4.216 13.333-15.108 19.167Q326-250 314-254q-101-34-167.5-119T80-568q0-51 15-96t42-83l-73-73q-9-9-8.5-21.5T65-863q9-9 21.5-9t21.5 9L878-92q9 9 9 21t-9 21q-9 9-21.5 9T835-50l-31-30H480Zm-79-490Zm123 386q-5 0-7.5-4t.5-8l51-71q2.308-3 6.154-3t5.846 3l51 63 57-78 98 98H524Zm-44 44h264L480-404v264Zm232-232Zm-8-270q4 12-2.5 23T682-605.818Q669-604 659-611t-14-19q-26-84-95-137t-158-53q-20.122 0-39.061 3T315-808q-12 4-23.367-.429-11.366-4.428-16.5-15.5Q270-835 274.074-847.056 278.148-859.111 290-863q25-8 50.329-12.5T392-880q112 0 196 67.5T704-642Zm-220 42Z"
		/>
	</svg>
);
