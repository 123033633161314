import { ReactNode } from 'react';

export const symbol_maps_ugc: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M112-295q-20-43-29-88.626-9-45.626-9-93.374 0-84 31.5-157T192-762q55-55 128-86.5T477-880q84 0 157 31.5T762-762q55 55 86.5 128T880-477q0 84-31.5 157T762-192q-55 55-128 86.5T477-74q-47.748 0-93.374-9T295-112L92-55q-17 5-29.5-7.5T55-92l57-203Zm19 164 138-38q16-5 30-3.5t29 7.5q36 14 73.333 22.5 37.334 8.5 75.734 8.5Q620-134 720-234t100-243q0-143-100-243T477-820q-143 0-243 99.792T134-476.629Q134-438 139.5-400q5.5 38 25.5 72 7 13.696 7.5 28.973T169-269l-38 138Zm316-312v97q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T507-346v-97h98q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T605-503h-98v-98q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T447-601v98h-98q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T349-443h98Zm29-33Z"
		/>
	</svg>
);
