import { t } from '@transifex/native';

import DateTime from 'pkg/datetime';
import * as models from 'pkg/api/models';
import { canDelete, destroy } from 'pkg/api/models';
import * as routes from 'pkg/router/routes';
import { useCurrentOrganization } from 'pkg/identity';

import Icon from 'components/icon';

import * as ContextMenu from 'design/context_menu';
import * as Table from 'design/table';

interface SubmissionRow {
	groupId: number;
	submission: models.formSubmission.FormSubmission;
	removeRecord: (recordId: number) => void;
}

const FormSubmissionRow: React.FC<React.PropsWithChildren<SubmissionRow>> = ({
	groupId,
	submission,
	removeRecord,
}) => {
	const org = useCurrentOrganization();
	const createdAt = new DateTime(new Date(submission.createdAt * 1000));

	const handleDelete = async () => {
		if (
			window.confirm(
				t('Do you want to delete this?', { _context: 'confirm messages' })
			)
		) {
			await destroy(submission);
			removeRecord(submission.id);
		}
	};

	const submissionShowUrl = routes.Form.Submissions.Single(
		org.id,
		groupId,
		submission.formId,
		submission.id
	);

	const submissionContext = (
		<ContextMenu.Menu
			toggleWith={
				<ContextMenu.TableTrigger>
					<Icon name="context-menu" />
				</ContextMenu.TableTrigger>
			}>
			<ContextMenu.LinkItem
				href={routes.Form.Submissions.Single(
					org.id,
					groupId,
					submission.form.id,
					submission.id
				)}>
				<ContextMenu.ItemIcon name="visibility" />
				{t('View')}
			</ContextMenu.LinkItem>
			{submission.orderId && (
				<ContextMenu.LinkItem
					icon="draft"
					href={routes.Order.Show(org.id, submission.orderId)}>
					{t('View order')}
				</ContextMenu.LinkItem>
			)}
			{canDelete(submission) && (
				<ContextMenu.Item icon="archive" onClick={handleDelete} caution>
					{t('Delete submission')}
				</ContextMenu.Item>
			)}
		</ContextMenu.Menu>
	);

	return (
		<Table.Row>
			<Table.LinkCell href={submissionShowUrl}>
				{submission.form.title}
			</Table.LinkCell>

			<Table.LinkCell href={submissionShowUrl}>
				{createdAt.toLocaleDateString({
					year: 'numeric',
					month: 'numeric',
					day: 'numeric',
				})}
			</Table.LinkCell>
			<Table.ActionCell>{submissionContext}</Table.ActionCell>
		</Table.Row>
	);
};

export default FormSubmissionRow;
