import { t } from '@transifex/native';

import * as flashActions from 'pkg/actions/flashes';

import { Linkable } from 'pkg/api/models/linkable';
import { Record } from 'pkg/api/models/record';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import * as sdk from 'pkg/core/sdk';

type ChatTypes = 'group' | 'personal';

export interface Chat extends Record, Linkable {
	createdAt?: number;
	isArchived?: boolean;
	isMonitored?: boolean;
	isNew: boolean;
	lastMessageId?: number;
	replyToMessageId?: number;
	title?: string;
	type: ChatTypes;
	userId?: number;
	rootGroupId: number;

	lastMessage?: models.chatMessage.ChatMessage;
	users: models.chatUser.ChatUser[];
}

export function isGroup(chat: Chat) {
	return chat.type === 'group';
}

// Group chat: returns chat.title or comma-separated list of first names (except activeUser)
// Personal chat: returns full name of the other user
export function getChatTitle(chat: Chat, activeUserIds: number[]) {
	const chatUsers = chat.users;
	const allUsers = chatUsers.map((cu) => cu.user);

	if (chat?.title?.length > 0) {
		return chat.title;
	}

	const isChatMember =
		chatUsers.filter((cu) => activeUserIds.includes(cu.userId)).length > 0;

	if (isGroup(chat) || !isChatMember) {
		const activeChatUsers = chatUsers.filter(
			(cu) => !activeUserIds.includes(cu.userId) && !cu.deletedAt
		);
		const generatedTitle = activeChatUsers
			.map((cu) => cu.user.firstName)
			.join(', ');

		return generatedTitle?.length > 0 ? generatedTitle : t('Unnamed chat');
	}

	const userId = chatUsers.find(
		({ userId }) => !activeUserIds.includes(userId)
	)?.userId;

	return userId
		? models.user.fullName(allUsers.find((u) => u.id === userId))
		: '';
}

// Returns all chats that's active
export function findAllActiveChats(chats: Chat[], activeUserIds: number[]) {
	return chats.filter((chat) => {
		const chatUser = findMyChatUser(chat, activeUserIds);
		if (
			isGroup(chat) &&
			!chat.isArchived &&
			isGroup(chat) &&
			!chatUser?.hasArchived
		) {
			return chat;
		}

		if (!isGroup(chat) && !chatUser?.hasArchived) {
			return chat;
		}

		return;
	});
}

// Returns all archived chats
export function findAllArchivedChats(chats: Chat[], activeUserIds: number[]) {
	return chats.filter((chat) => {
		const chatUser = findMyChatUser(chat, activeUserIds);
		if ((isGroup(chat) && chat?.isArchived) || chatUser?.hasArchived) {
			return true;
		}

		return false;
	});
}

// Checks if chat user is able to unarchive chat
export function canUnarchiveChat(chat: Chat, activeUserIds: number[]) {
	const chatUser = findMyChatUser(chat, activeUserIds);
	let canUnArchive = false;

	if (isGroup(chat) && chatUser.isAdmin) {
		canUnArchive = true;
	} else if (isGroup(chat) && !chat.isArchived && chatUser.hasArchived) {
		canUnArchive = true;
	} else if (!isGroup(chat) && chatUser.hasArchived) {
		canUnArchive = true;
	}

	return canUnArchive;
}

export function findAllBySearch(
	chats: Chat[],
	search: string,
	users: models.user.User[]
): Chat[] {
	if (search === '') {
		return [];
	}

	const searchBy = search.toLowerCase();
	const activeAccountUserIds = users.map((u) => u.id);

	return chats.filter((chat) => {
		const title = getChatTitle(chat, activeAccountUserIds);

		if (title.toLowerCase().includes(searchBy)) {
			return true;
		}

		for (const cu of chat.users) {
			if (
				models.user.fullName(cu.user).toLocaleLowerCase().includes(searchBy)
			) {
				return true;
			}
		}

		return false;
	});
}

export function sortByLastMessageId(chats: Chat[]) {
	return chats.sort((a, b) => b.lastMessageId - a.lastMessageId);
}

export function getLastReadMessageId(
	chat: Chat,
	activeAccountUserIds: number[]
): number {
	const chatUser = chat.users.find(({ userId }) =>
		activeAccountUserIds.includes(userId)
	);

	return chatUser?.lastReadMessageId;
}

export function findMyChatUser(chat: Chat, activeUserIds: number[]) {
	return chat?.users.find((chatUser) =>
		activeUserIds.includes(chatUser.userId)
	);
}

export function findByMembers(
	chats: Chat[],
	activeUserId: number,
	targetUserId: number
): Chat {
	const personalChats = chats.filter((c) => !isGroup(c));

	for (const c of personalChats) {
		const chatUserIds = c.users.map((cu) => cu.userId);

		if (
			chatUserIds.includes(activeUserId) &&
			chatUserIds.includes(targetUserId)
		) {
			return c;
		}
	}

	return null;
}

interface CreatePayload {
	title: string;
	groupId: number;
	type: ChatTypes;
	users: number[];
}

export async function create(payload: CreatePayload): Promise<[boolean, Chat]> {
	const [req, resp] = await models.create<CreatePayload, Chat>(
		endpoints.Chat.Create(),
		payload
	);

	if (!req.ok && req.status !== 409) {
		flashActions.show({ title: t('Failed to create new chat') }, req.status);

		return [false, null];
	}

	return [true, resp];
}

export interface UpdateChatPayload {
	title?: string;
	isArchived?: boolean;
}

export async function update(
	chat: Chat,
	payload: UpdateChatPayload
): Promise<[boolean, Chat]> {
	const request = await sdk.patch(endpoints.Chat.Show(chat.id), {}, payload);

	if (!request.ok) {
		return [false, null];
	}

	const result = await request.json();

	return [true, result];
}

export async function archive(chat: Chat, isArchived: boolean) {
	const [ok, resp] = await models.chat.update(chat, {
		isArchived,
	});

	let flashTitle = '';

	if (!ok) {
		if (isArchived) {
			flashTitle = t('Could not archive chat');
		} else {
			flashTitle = t('Could not unarchive chat');
		}

		flashActions.show({ title: flashTitle });

		return null;
	}

	if (isArchived) {
		flashTitle = t('Chat archived');
	} else {
		flashTitle = t('Chat unarchived');
	}

	flashActions.show({ title: flashTitle });

	return resp;
}

export async function remove(chat: Chat) {
	const request = await sdk.destroy(endpoints.Chat.Delete(chat.id));

	if (request.ok) {
		return true;
	}

	return false;
}

export async function sendEvent(
	chat: Chat,
	type: 'started-typing' | 'stopped-typing'
) {
	return await sdk.post(endpoints.Chat.SendEvent(chat.id), {}, { type });
}
