import * as endpoints from 'pkg/api/endpoints/auto';
import * as sdk from 'pkg/core/sdk';

export const fetchMatchForEvent = (eventId) =>
	sdk.get(endpoints.Match.EventIndex(eventId));

export const patchMatch = (matchId, match) =>
	sdk.patch(endpoints.Match.Update(matchId), {}, match);

export const fetchSingleMatch = (matchId) =>
	sdk.get(endpoints.Match.Show(matchId));

export const fetchUserLatestMatch = (userId, queryParams) =>
	sdk.get(endpoints.Match.ShowLatestByUser(userId), queryParams);

export const fetchGroupMatches = (queryParams) =>
	sdk.get(endpoints.Match.Index(), queryParams);
