import { ReactNode } from 'react';

export const symbol_text_rotate_up: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m704-726-31 31q-9 9-21 9t-21-9q-9-9-9-21t9-21l82-82q9-9 21-9t21 9l83 81q9 9 8.5 21.5T837-695q-9 8-21 8.5t-21-8.5l-31-31v536q0 13-8.5 21.5T734-160q-13 0-21.5-8.5T704-190v-536ZM452-377l91 33q8 3 12.5 9.5T560-320q0 14-11 21.5t-24 2.5L140-440q-9-3-14.5-10.5T120-468v-24q0-10 5.5-17.5T140-521l386-143q13-5 23.5 2.5T560-640q0 8-4.5 14.5T543-616l-91 33v206Zm-47-17v-172l-233 84v4l233 84Z"
		/>
	</svg>
);
