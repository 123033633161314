import { ReactNode } from 'react';

export const symbol_emergency_heat: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M440-80q-134 0-227-93t-93-227q0-116 71.5-225T388-811q17-11 34.5-.5T440-780v72q0 34 23.5 57t57.5 23q18 0 33.5-7.5T582-658q8-9 18-12.5t19 2.5q66 45 103.5 116T760-400q0 134-93 227T440-80ZM180-400q0 63 28.5 118.5T288-189q-4-12-6-24.5t-2-24.5q0-32 12-60t35-51l113-111 113 111q23 23 35 51t12 60q0 12-2 24.5t-6 24.5q51-37 79.5-92.5T700-400q0-54-23-105.5T611-600q-21 15-44 23.5t-46 8.5q-61 0-101-41.5T380-714v-20q-92 66-146 156.5T180-400Zm260 24-71 70q-14 14-21.5 31t-7.5 37q0 41 29 69.5t71 28.5q42 0 71-28.5t29-69.5q0-20-7.5-37T511-306l-71-70Zm410-154q-12 0-21-9t-9-21q0-13 9-21.5t21-8.5q13 0 21.5 8.5T880-560q0 12-8.5 21t-21.5 9Zm0-120q-13 0-21.5-8.5T820-680v-130q0-13 8.5-21.5T850-840q13 0 21.5 8.5T880-810v130q0 13-8.5 21.5T850-650Z"
		/>
	</svg>
);
