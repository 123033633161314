import Controller from 'components/video-analytics/Controller';
import { YouTubeUrl } from 'components/video-analytics/source/SourceUrl';

let __playing: boolean = false;

export default class YouTubeController extends Controller {
	public signature: string;

	constructor(protected controllerInterface: YT.Player) {
		super(controllerInterface);
	}

	public setSource(source: YouTubeUrl): void {
		this.controllerInterface.cueVideoById(source.getSourceId());
	}

	public async getCurrentTime(): Promise<number> {
		return Promise.resolve(this.controllerInterface.getCurrentTime());
	}

	public async getDuration(): Promise<number> {
		return Promise.resolve(this.controllerInterface.getDuration());
	}

	public async play(): Promise<void> {
		if (this.isPlaying()) return;

		__playing = true;

		return Promise.resolve(this.controllerInterface.playVideo());
	}

	public async pause(): Promise<void> {
		if (!this.isPlaying()) return;

		__playing = false;

		return Promise.resolve(this.controllerInterface.pauseVideo());
	}

	public isPlaying(): boolean {
		return __playing;
	}

	public async skip(
		fraction: number,
		resumeAfterSkip: boolean = false
	): Promise<number> {
		const currentTime: number = await this.getCurrentTime();
		const nextFraction: number = currentTime + fraction;

		await this.seekTo(nextFraction, resumeAfterSkip);

		return Promise.resolve(nextFraction);
	}

	public async seekTo(
		fraction: number,
		resumeAfterSeek: boolean = false
	): Promise<number> {
		const duration: number = await this.getDuration();

		let nextFraction: number = fraction;

		// @NOTE There's a bug in the YouTube embed that ignores seekTo at 0.
		if (nextFraction === 0) {
			nextFraction = 0.1;
		}

		if (nextFraction > duration) {
			nextFraction = duration;
		}

		this.controllerInterface.seekTo(nextFraction, true);

		if (!this.isPlaying() && resumeAfterSeek) {
			await this.play();
		} else if (this.isPlaying() && !resumeAfterSeek) {
			await this.pause();
		}

		return Promise.resolve(nextFraction);
	}

	public async setVolume(fraction: number): Promise<void> {
		let nextFraction: number = fraction * 100;

		if (nextFraction > 100) {
			nextFraction = 100;
		}

		this.controllerInterface.setVolume(nextFraction);

		return Promise.resolve();
	}

	public async getVolume(): Promise<number> {
		const volume: number = await this.controllerInterface.getVolume();

		return Promise.resolve(volume / 100);
	}

	public async mute(): Promise<void> {
		this.controllerInterface.mute();

		return Promise.resolve();
	}

	public async unmute(): Promise<void> {
		this.controllerInterface.unMute();

		return Promise.resolve();
	}

	public async getAvailablePlaybackRates(): Promise<readonly number[]> {
		return Promise.resolve(
			this.controllerInterface.getAvailablePlaybackRates()
		);
	}

	public async setPlaybackRate(playbackRate: number): Promise<void> {
		const availableRates = await this.getAvailablePlaybackRates();

		if (availableRates.includes(playbackRate)) {
			this.controllerInterface.setPlaybackRate(playbackRate);
		}

		return Promise.resolve();
	}

	public async getPlaybackRate(): Promise<number> {
		return Promise.resolve(this.controllerInterface.getPlaybackRate());
	}
}
