import { t } from '@transifex/native';

import * as routes from 'pkg/router/routes';
import { pushState } from 'pkg/router/state';
import { useCurrentOrganization } from 'pkg/identity';

import Button from 'design/button';

interface AnalyzeProps {
	videoId: number;
}

export default function Analyze({ videoId }: AnalyzeProps): JSX.Element {
	const org = useCurrentOrganization();

	const navigate = () => {
		pushState(routes.Video.Analyze(org.id, videoId));
	};

	return (
		<Button
			small
			primary
			icon="smart_display"
			onClick={navigate}
			testid="video.analyze_button">
			{t(`Analyze video`)}
		</Button>
	);
}
