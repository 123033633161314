import { forAccountHeaders } from 'pkg/actions/utils';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as sdk from 'pkg/core/sdk';

export const togglePublishedToPlayer = (id) =>
	sdk.patch(endpoints.UserRatings.Show(id));

export const saveRating = (userId, payload, forAccount) => {
	const headers = forAccountHeaders(forAccount);

	return sdk.post(endpoints.UserRatings.Create(userId), {}, payload, headers);
};
