import { ReactNode } from 'react';

export const symbol_cooking: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M419.825-120Q407-120 398.5-128.625T390-150v-100H110q-12.75 0-21.375-8.675Q80-267.351 80-280.175 80-293 88.625-301.5T110-310h280q24.75 0 42.375 17.625T450-250v100q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Zm120 0Q527-120 518.5-128.625T510-150v-100q0-24.75 17.625-42.375T570-310h280q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-250H570v100q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM230-370q-45.833 0-77.917-32.083Q120-434.167 120-480v-150q0-12.75 8.625-21.375T150-660h660q12.75 0 21.375 8.625T840-630v150q0 45.833-32.083 77.917Q775.833-370 730-370H230Zm0-60h500q21.25 0 35.625-14.375T780-480v-120H180v120q0 21.25 14.375 35.625T230-430Zm-80-270q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-760h220v-50q0-12.75 8.625-21.375T400-840h160q12.75 0 21.375 8.625T590-810v50h220q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-700H150Zm30 270v-170 170Z"
		/>
	</svg>
);
