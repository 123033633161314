import { ReactNode } from 'react';

export const symbol_account_child: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-880q83.728 0 156.864 31Q710-818 764-764t85 127.136Q880-563.728 880-480q0 84-31 157t-85 127q-54 54-127.136 85T480-80q-84 0-157-31t-127-85q-54-54-85-127T80-480q0-83.728 31-156.864Q142-710 196-764t127-85q73-31 157-31Zm0 740q142 0 241-99t99-241q0-142-99-241t-241-99q-142 0-241 99t-99 241q0 142 99 241t241 99Zm-86-546q0 38 24 62t62 24q38 0 62-24t24-62q0-38-24-62t-62-24q-38 0-62 24t-24 62Zm86.244 126Q407-560 343.5-536.35T280-450v159q0 62.816 63.333 85.408Q406.667-183 480-183q16.575 0 27.788-11.212Q519-205.425 519-222q0-16.575-11.212-27.788Q496.575-261 480-261q-24-2-47-7.5T388-284q-8-4-12-11t0-15q15-26 44.5-32.5T480-349q38 0 73.5 6.5T589-303v64q0 11 7 18.5t18 7.5q29 0 47.5-23.5T680-291v-159q0-62.7-63.256-86.35Q553.488-560 480.244-560ZM480-371q-23.52 0-39.76-16.24T424-427q0-23.52 16.24-39.76T480-483q23.52 0 39.76 16.24T536-427q0 23.52-16.24 39.76T480-371Zm0-112Z"
		/>
	</svg>
);
