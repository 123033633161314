import { ReactNode } from 'react';

export const symbol_book_online: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M360-320q-17 0-28.5-11.5T320-360v-67q0-5 4-9.5t9-5.5q12-4.167 19.5-14.583Q360-467 360-480q0-13-7.5-23.417Q345-513.833 333-518q-5-1-9-5.308-4-4.307-4-9.692v-67q0-17 11.5-28.5T360-640h240q17 0 28.5 11.5T640-600v67q0 5-4 9.5t-9 5.5q-12 4.167-19.5 14.583Q600-493 600-480q0 13 7.5 23.417Q615-446.167 627-442q5 1 9 5.308 4 4.307 4 9.692v67q0 17-11.5 28.5T600-320H360Zm120-60q8 0 14-6t6-14q0-8-6-14t-14-6q-8 0-14 6t-6 14q0 8 6 14t14 6Zm0-80q8 0 14-6t6-14q0-8-6-14t-14-6q-8 0-14 6t-6 14q0 8 6 14t14 6Zm0-80q8 0 14-6t6-14q0-8-6-14t-14-6q-8 0-14 6t-6 14q0 8 6 14t14 6ZM260-40q-24 0-42-18t-18-42v-760q0-24 18-42t42-18h440q24 0 42 18t18 42v760q0 24-18 42t-42 18H260Zm0-90v30h440v-30H260Zm0-60h440v-580H260v580Zm0-640h440v-30H260v30Zm0 0v-30 30Zm0 700v30-30Z"
		/>
	</svg>
);
