import { ReactNode } from 'react';

export const symbol_line_start_arrow: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M474-229 120-455q-14-8.786-14-24.893T120-505l354-226q15-10 30.5-1.192Q520-723.383 520-705v195h330q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-450H520v195q0 18.383-15.5 27.191Q489-219 474-229Zm-14-80v-342L192-480l268 171Zm0-171Z"
		/>
	</svg>
);
