import { t } from '@transifex/native';
import { Component } from 'react';
import styled from 'styled-components';

import * as palette from 'pkg/config/palette';

import { isDev } from 'pkg/flags';

import Lottie from 'components/Lottie';

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	flex-flow: column;
	margin: 1rem auto;
	min-height: 300px;

	${Lottie} {
		max-height: 150px;
	}
`;

const IconWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin: 50px 0;
`;

const Message = styled.p`
	font-size: 1.1rem;
	font-weight: var(--font-weight-semibold);
	color: ${palette.gray[400]};
`;

export default class AnalyzingComponent extends Component {
	timeout = null;
	state = {
		rating: {},
		complete: false,
	};

	async componentDidMount() {
		const requestStart = Date.now();
		const rating = await this.props.ratingRequest;

		const minAnimationTime = isDev() ? 0 : 3500;

		const requestEnd = Math.abs(Date.now() - requestStart);
		const timeout =
			requestEnd >= minAnimationTime ? 0 : minAnimationTime - requestEnd;

		this.timeout = setTimeout(this.complete, timeout);

		this.setState({ rating });
	}

	componentWillUnmount() {
		clearTimeout(this.timeout);
	}

	complete = () => this.setState({ complete: true });

	showPreview = () => {
		const { rating } = this.state;

		this.props.setPreview(rating.id);
	};

	render() {
		return (
			<Wrapper>
				<IconWrapper>
					{!this.state.complete ? (
						<Lottie name="working" key="working-anim" autoplay loop />
					) : (
						<Lottie
							name="done"
							key="done-anim"
							autoplay
							onComplete={this.showPreview}
						/>
					)}
				</IconWrapper>

				<Message>{t(`Finding position`)}</Message>
			</Wrapper>
		);
	}
}
