import { ReactNode } from 'react';

export const symbol_euro_symbol: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M602-120q-122 0-213-68T269-367H150q-13 0-21.5-8.5T120-397q0-13 8.5-21.5T150-427h105q-2-12-3-26.5t-1-26.5q0-12 1-26.5t3-26.5H150q-13 0-21.5-8.5T120-563q0-13 8.5-21.5T150-593h119q32-115 121.5-181T602-840q60 0 113 16.5t98 50.5q12 9 12.5 23.5T815-724q-11 11-25 11.5t-27-7.5q-35-24-76.5-36.5T602-769q-96 0-163.5 46.5T345-593h228q13 0 21.5 8.5T603-563q0 13-8.5 21.5T573-533H327q-3 12-4 26.5t-1 26.5q0 12 1 26.5t4 26.5h246q13 0 21.5 8.5T603-397q0 13-8.5 21.5T573-367H345q25 85 96 130.5T602-191q43 0 84.5-12.5T763-240q12-8 27-8t26 11q11 11 9.5 25.5T813-189q-48 35-100.5 52T602-120Z"
		/>
	</svg>
);
