// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eJ1zuLsBs3Kqz221vrgw {
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: 40px auto;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	font-size: var(--font-size-base);
	line-height: var(--font-line-height-base);
	color: var(--palette-gray-600);
}
.eJ1zuLsBs3Kqz221vrgw small {
		display: block;
		font-size: var(--font-size-2xs);
	}
`, "",{"version":3,"sources":["webpack://./routes/event/single/tabs/lok/styles.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,sBAAsB;CACtB,4BAA4B;CAC5B,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CACnB,gCAAgC;CAChC,yCAAyC;CACzC,8BAA8B;AAM/B;AAJC;EACC,cAAc;EACd,+BAA+B;CAChC","sourcesContent":[".descriptionRow {\n\tdisplay: grid;\n\tgrid-auto-flow: column;\n\tgrid-auto-columns: 40px auto;\n\talign-items: center;\n\tfont-size: var(--font-size-base);\n\tline-height: var(--font-line-height-base);\n\tcolor: var(--palette-gray-600);\n\n\tsmall {\n\t\tdisplay: block;\n\t\tfont-size: var(--font-size-2xs);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var descriptionRow = `eJ1zuLsBs3Kqz221vrgw`;
export default ___CSS_LOADER_EXPORT___;
