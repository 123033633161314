import { ReactNode } from 'react';

export const symbol_emoji_food_beverage: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M190-120q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T190-180h580q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T770-120H190Zm121-140q-63 0-107-43.5T160-410v-363q0-27.854 19.573-47.427T227-840h593q24.75 0 42.375 17.625T880-780v160q0 24.75-17.625 42.375T820-560h-96v150q0 63-44 106.5T573-260H311Zm0-520h353-444 91Zm413 160h96v-160h-96v160ZM572.978-320Q609-320 636.5-347.5T664-410v-370H397v33l71 58q1 1 9 18v150q0 9.6-7 16.8-7 7.2-18 7.2H301q-11 0-18-7.2t-7-16.8v-150q0-4 9-18l72-58v-33H220v370q0 35 28 62.5t63 27.5h261.978ZM357-780h40-40Z"
		/>
	</svg>
);
