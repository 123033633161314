import { Fragment } from 'react';
import { t } from '@transifex/native';

import { FontWeights } from 'pkg/config/fonts';

import { useAccountIdentity } from 'pkg/identity/account';
import * as models from 'pkg/api/models';
import { cssVarList } from 'pkg/css/utils';
import * as routes from 'pkg/router/routes';

import Badge from 'components/Badge';

import * as Context from 'design/context_menu';

import * as css from './styles.css';

interface ListItemProps {
	organization: models.group.OrganizationData;
}

function ListItem({ organization }: ListItemProps) {
	const primaryColor = models.group.getPrimaryColorStylesheetString(
		organization as models.group.Group
	);

	return (
		<Context.LinkItem
			href={routes.Chat.Index(organization.id)}
			fontWeight={FontWeights.SemiBold}>
			<div
				className={css.badgeWrapper}
				style={cssVarList({
					primaryColor,
				})}>
				<Badge badgeUrl={organization.profileImageUrl} />
			</div>
			{t('{amount} unread in {name}', {
				amount: organization.unreadChatCount,
				name: organization.name,
			})}
		</Context.LinkItem>
	);
}

export default function UnreadChatList() {
	const { organizations } = useAccountIdentity();
	const filteredOrgs = organizations.filter((o) => o.unreadChatCount > 0);

	return (
		<Fragment>
			{filteredOrgs.map((o) => (
				<ListItem key={o.id} organization={o} />
			))}
		</Fragment>
	);
}
