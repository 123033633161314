import * as endpoints from 'pkg/api/endpoints/auto';
import { useEndpoint } from 'pkg/api/use_endpoint';
import * as models from 'pkg/api/models';
import { PaymentProvider } from 'pkg/api/models/group';
import { isApp } from 'pkg/platform';
import { OnboardingStatus } from 'pkg/api/models/group_payment_config';

import ServiceFee from 'routes/settings/payments/service-fee';
import StartAdyenOnboarding from 'routes/group/settings/adyen';
import PaymentSettings from 'routes/settings/payments/payment';

import { Spinner } from 'components/loaders/spinner';
import Column from 'components/layout/column';

interface PaymentsSettingsProps {
	organizationId: number;
}

export default function PaymentsSettings({
	organizationId,
}: PaymentsSettingsProps) {
	const {
		record: organization,
		isLoading,
		refresh,
	} = useEndpoint<models.group.Group>(
		endpoints.Organizations.Show(organizationId)
	);

	const { record: providerSettings } =
		useEndpoint<models.providerSettings.ProviderSettings>(
			organization.id
				? endpoints.Groups.PaymentProviderSettings(organization.id)
				: undefined
		);

	if (isLoading) {
		return <Spinner />;
	}

	let shouldShowAdyenOnboarding = false;

	const adyenConfig = organization.paymentConfigs?.find(
		(c) => c.paymentProvider === PaymentProvider.Adyen
	);

	if (
		organization.id &&
		!isApp() &&
		adyenConfig?.onboardingStatus === OnboardingStatus.OnboardingStatusPrepared
	) {
		shouldShowAdyenOnboarding = models.hasAllowedAction(
			adyenConfig,
			models.Action.GroupPaymentConfigOnboard
		);
	}

	if (
		!organization.paymentProviderActive &&
		!shouldShowAdyenOnboarding &&
		!adyenConfig
	) {
		return null;
	} else if (shouldShowAdyenOnboarding) {
		return <StartAdyenOnboarding group={organization} />;
	}

	return (
		<Column>
			<PaymentSettings organization={organization} />
			<ServiceFee
				group={organization}
				providerSettings={providerSettings}
				refresh={refresh}
			/>
		</Column>
	);
}
