import { ReactNode } from 'react';

export const symbol_close_small: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-438 321-279q-9 9-21 9t-21-9q-9-9-9-21t9-21l159-159-159-158q-9-9-9-21t9-21q9-9 21-9t21 9l159 159 158-159q8-9 20.5-9t21.5 9q9 9 9 21t-9 21L521-480l159 159q9 9 9 21t-9 21q-9 9-21 9t-21-9L480-438Z"
		/>
	</svg>
);
