import { ReactNode } from 'react';

export const symbol_psychology: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m446-418 2 30q.736 6.222 4.785 10.111 4.048 3.889 9.937 3.889h32.389q5.889 0 10.014-3.889Q509.25-381.778 510-388l2-30q12-2 22.472-8.462Q544.944-432.923 553-441l30 10q5 2 10 0t7.8-6.846l15.4-26.308Q619-469 618.5-474t-5.188-9.167L593-499q5-14 5-29t-5-29l20.312-15.833Q618-577 618.5-582t-2.3-9.846l-15.4-26.308Q598-623 593-625t-10 0l-30 10q-8.333-7.692-19.167-13.846Q523-635 512-638l-2-30q-.736-6.222-4.785-10.111-4.048-3.889-9.937-3.889h-32.389q-5.889 0-10.014 3.889Q448.75-674.222 448-668l-2 30q-11 3-21.833 9.154Q413.333-622.692 405-615l-30-10q-5-2-10 0t-7.8 6.846l-15.4 26.308Q339-587 339.5-582t5.188 9.167L365-557q-5 14-5 29t5 29l-20.312 15.833Q340-479 339.5-474t2.3 9.846l15.4 26.308Q360-433 365-431t10 0l30-10q8.056 8.077 18.528 14.538Q434-420 446-418Zm33.118-40Q450-458 429.5-478.382q-20.5-20.383-20.5-49.5Q409-557 429.382-577.5q20.383-20.5 49.5-20.5Q508-598 528.5-577.618q20.5 20.383 20.5 49.5Q549-499 528.618-478.5q-20.383 20.5-49.5 20.5ZM240-252q-57-52-88.5-121.5T120-520q0-150 105-255t255-105q125 0 221.5 73.5T827-615l55 218q4 14-5 25.5T853-360h-93v140q0 24.75-17.625 42.375T700-160H600v50q0 12.75-8.675 21.375Q582.649-80 569.825-80 557-80 548.5-88.625T540-110v-80q0-12.75 8.625-21.375T570-220h130v-170q0-12.75 8.625-21.375T730-420h84l-45-180q-24-97-105-158.5T480-820q-125 0-212.5 86.5T180-522.46q0 64.417 26.324 122.392Q232.649-342.092 281-297l19 18v169q0 12.75-8.675 21.375Q282.649-80 269.825-80 257-80 248.5-88.625T240-110v-142Zm257-198Z"
		/>
	</svg>
);
