import * as models from 'pkg/api/models';

import UserProfileCalendar from 'routes/user/profile/calendar';

interface OrganizationUserProfileCalendarProps {
	user: models.user.User;
}

export default function OrganizationUserProfileCalendar({
	user,
}: OrganizationUserProfileCalendarProps): JSX.Element {
	return <UserProfileCalendar userId={user.id} />;
}
