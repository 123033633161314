import { ReactNode } from 'react';

export const symbol_brunch_dining: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M80-160h570v50q0 12.75-8.625 21.375T620-80H110q-12.75 0-21.375-8.625T80-110v-50Zm0-64v-50q0-12.75 8.625-21.375T110-304h182v-43q0-12.75 8.625-21.375T322-377h86q12.75 0 21.375 8.625T438-347v43h182q12.75 0 21.375 8.625T650-274v50H80Zm664-120q-31-26-53.5-69T668-510v-340q0-12.75 8.625-21.375T698-880h152q12.75 0 21.375 8.625T880-850v340q0 54-22.5 97T804-344v204h46q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q880-97 871.375-88.5T850-80h-76q-12.75 0-21.375-8.625T744-110v-234Zm-16-297h92v-179h-92v179Zm46 244q23-20 34.5-50.253T820-510v-71h-92v71q0 32.494 11.5 62.747T774-397Zm0 0Z"
		/>
	</svg>
);
