const resource = {
	name: 'resource',
	objects: [
		{
			type: 'path',
			attributes: {
				d: 'M15.9101 6.84847C15.9101 8.59793 14.8338 10.6558 13.6016 12.3954C13.025 13.2095 12.4465 13.9096 12.0016 14.4165C11.5563 13.9073 10.9766 13.2037 10.3989 12.3866C9.1655 10.6423 8.08985 8.5848 8.08985 6.84847C8.08985 4.75441 9.84048 3.05683 12 3.05683C14.1595 3.05683 15.9101 4.75441 15.9101 6.84847ZM17 6.84847C17 10.0241 14.1215 13.6493 12.7326 15.2136C12.291 15.711 12 16 12 16C12 16 11.7097 15.7102 11.2691 15.2122C9.88087 13.643 6.99999 10.0062 6.99999 6.84847C6.99999 4.17074 9.23857 2 12 2C14.7614 2 17 4.17074 17 6.84847ZM12 8.91446C13.1445 8.91446 14.0723 8.01477 14.0723 6.90494C14.0723 5.79511 13.1445 4.89542 12 4.89542C10.8555 4.89542 9.92767 5.79511 9.92767 6.90494C9.92767 8.01477 10.8555 8.91446 12 8.91446ZM4.05439 11.5L0.276611 21.5H23.7234L19.9456 11.5H17V12.5H19.2544L20.5766 16H18V17H20.9544L22.2766 20.5H12.5V18H11.5V20.5H1.72337L3.04559 17H5.99999V16H3.42337L4.74559 12.5H6.99999V11.5H4.05439Z',
				fillRule: 'evenodd',
				clipRule: 'evenodd',
			},
		},
	],
};

export default resource;
