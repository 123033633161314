import { ReactNode } from 'react';

export const symbol_barcode_scanner: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M222-149.825q0 12.825-8.625 21.325T192-120H70q-12.75 0-21.375-8.625T40-150v-122q0-12.75 8.675-21.375Q57.351-302 70.175-302 83-302 91.5-293.375T100-272v92h92q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5ZM889.175-302q12.825 0 21.325 8.625T919-272v122q0 12.75-8.625 21.375T889-120H767q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T767-180h92v-92q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625ZM168-231q-6 0-10.5-4.5T153-246v-469q0-6 4.5-10.5T168-730h50q6 0 10.5 4.5T233-715v469q0 6-4.5 10.5T218-231h-50Zm127 0q-9 0-15-6.238-6-6.237-6-14.554v-457.416q0-8.317 6-14.555Q286-730 295-730t15 6.237q6 6.238 6 14.555v457.416q0 8.317-6 14.554Q304-231 295-231Zm116 0q-6 0-10.5-4.5T396-246v-469q0-6 4.5-10.5T411-730h53q6 0 10.5 4.5T479-715v469q0 6-4.5 10.5T464-231h-53Zm125 0q-6 0-10.5-4.5T521-246v-469q0-6 4.5-10.5T536-730h91q6 0 10.5 4.5T642-715v469q0 6-4.5 10.5T627-231h-91Zm169 0q-9 0-15-6.238-6-6.237-6-14.554v-457.416q0-8.317 6-14.555Q696-730 705-730t15 6.237q6 6.238 6 14.555v457.416q0 8.317-6 14.554Q714-231 705-231Zm81 0q-7.6 0-13.3-5.7-5.7-5.7-5.7-13.3v-460.625q0-8.375 5.7-13.875T786-730q7.6 0 13.3 5.7 5.7 5.7 5.7 13.3v460.625q0 8.375-5.7 13.875T786-231ZM222-809.825q0 12.825-8.625 21.325T192-780h-92v92q0 12.75-8.675 21.375Q82.649-658 69.825-658 57-658 48.5-666.625T40-688v-122q0-12.75 8.625-21.375T70-840h122q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Zm515-.35q0-12.825 8.625-21.325T767-840h122q12.75 0 21.375 8.625T919-810v122q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T859-688v-92h-92q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5Z"
		/>
	</svg>
);
