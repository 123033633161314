import { ReactNode } from 'react';

export const symbol_speed_0_2x: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M440-277H270q-12.75 0-21.375-8.625T240-307v-145q0-24 18-42t42-18h110v-111H270q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T270-683h140q24 0 42 18t18 42v111q0 24-18 42t-42 18H300v115h140q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T440-277Zm249-157-77 139q-4.286 7.059-10.714 11.029Q594.857-280 587.143-280 570-280 562-294t0-28l91-164-85-152q-8-14-.5-28t24.643-14q7.714 0 14.143 3.971Q612.714-672.059 617-665l72 128 71-128q4.286-7.059 10.714-11.029Q777.143-680 784.857-680 802-680 810-666t0 28l-85 152 92 165q8 14 0 27.5T791.759-280Q784-280 778-283.706T768-294l-79-140ZM149.825-277Q137-277 128.5-285.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Z"
		/>
	</svg>
);
