import { t } from '@transifex/native';

import { PlayerAgeDistributionData } from 'pkg/api/dashboards/management';

import * as Chart from 'design/chart';
import * as DataCard from 'design/data_card';

interface PlayerAgeDistributionWidgetProps {
	data: PlayerAgeDistributionData;
}

export default function PlayerAgeDistributionWidget({
	data,
}: PlayerAgeDistributionWidgetProps) {
	const chartItems: Chart.ChartItem[] = [];

	for (const [key, value] of Object.entries(data)) {
		if (Number.parseInt(key) > 0) {
			chartItems.push({
				title: key.toString(),
				value: Array.isArray(value) ? value.length : value,
			});
		}
	}

	return (
		<DataCard.Base
			title={t('Player age distribution')}
			titleIcon="account_circle">
			<Chart.VerticalBar items={chartItems} hideLegend />
		</DataCard.Base>
	);
}
