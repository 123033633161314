export function Exercises(ratingItemID: number): string {
	return `/v1/rating-items/${ratingItemID}/exercises`;
}

export function Index(): string {
	return `/v1/rating-items`;
}

export function Show(ratingItemID: number): string {
	return `/v1/rating-items/${ratingItemID}`;
}
