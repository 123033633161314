import { ReactNode } from 'react';

export const symbol_hive: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M324.038-203H224q-17 0-30.49-8.049Q180.021-219.098 172-233l-53-95q-8-14-8-29t8-29l52-94-52-94q-8-14-8-29t8-29l53-95q8.021-13.902 21.51-21.951Q207-757 224-757h100.038L377-850q8.021-13.902 21.51-21.951Q412-880 429-880h102q17 0 30.49 8.049Q574.979-863.902 583-850l52.962 93H736q17 0 30.49 8.049Q779.979-740.902 788-727l53 95q8 14 8 29t-8 29l-52 94 52 94q8 14 8 29t-8 29l-53 95q-8.021 13.902-21.51 21.951Q753-203 736-203H635.962L583-110q-8.021 13.902-21.51 21.951Q548-80 531-80H429q-17 0-30.49-8.049Q385.021-96.098 377-110l-52.962-93ZM637-509h100l54-94-54-94H637l-54 94 54 94ZM429.721-388H530l54-92-53.721-92H430l-54 92 53.721 92Zm.771-244H530l54-94-54-94H430l-54 94 54.492 94ZM378-603l-54-94H224l-54 94 54 94h100l54-94Zm0 246-55-94H223l-54 94 55 94h100l54-94Zm52 29-54 94 54 94h100l54-94-54.492-94H430Zm207 65h100l54-94-54-94H637l-51 94 51 94Z"
		/>
	</svg>
);
