import { ReactNode } from 'react';

export const symbol_barefoot: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M219.825-650Q207-650 198.5-658.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5ZM400-80q-66 0-113-47t-47-113v-240q0-100 70-170t170-70h73q69 0 118 46.5T720-559q0 46-24.5 84.5T629-416q-32 15-50.5 44.5T560-307v67q0 67-46.5 113.5T400-80Zm-80.175-640Q307-720 298.5-728.625T290-750v-40q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T350-790v40q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM400-140q42 0 71-29t29-71v-67q0-52.232 27.557-96.359Q555.114-447.486 603-470q26-13 41.5-37.218Q660-531.435 660-560q0-42-31.5-71T553-660h-73q-75 0-127.5 52.5T300-480v240q0 42 29 71t71 29Zm39.825-620Q427-760 418.5-768.625T410-790v-40q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T470-830v40q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Zm120 0Q547-760 538.5-768.625T530-790v-60q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T590-850v60q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Zm140.057 40Q679-720 664.5-734.583 650-749.167 650-770v-60q0-20.833 14.618-35.417Q679.235-880 700.118-880 721-880 735.5-865.417 750-850.833 750-830v60q0 20.833-14.618 35.417Q720.765-720 699.882-720Z"
		/>
	</svg>
);
