import { t } from '@transifex/native';
import { useState } from 'react';
import { useDispatch, batch } from 'react-redux';
import styled from 'styled-components';

import spacing from 'pkg/config/spacing';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as actions from 'pkg/actions';
import * as models from 'pkg/api/models';
import { useCollection } from 'pkg/api/use_collection';
import { useCurrentOrganization } from 'pkg/identity';

import Tree from 'containers/group/settings/organization/tree/Base';

import { useSmallScreen } from 'components/MediaQuery';
import Dialog from 'components/Dialog';

import TextInput from 'components/form/TextInput';
import Hint from 'components/form/Hint';

import Button from 'design/button';

const Help = styled(Hint)`
	padding: ${spacing._1} 0;
`;

const TopWrapper = styled.div`
	padding: ${spacing._6};
`;

const OrganizationDialog = ({
	type,
	group,
	defaultSelected,
	refresh,
	onClose,
}) => {
	const dispatch = useDispatch();
	const org = useCurrentOrganization();

	const [selectedGroup, setSelectedGroup] = useState(defaultSelected);
	const [newGroupName, setNewGroupName] = useState('');

	const handleSelect = (group) => setSelectedGroup(group);

	const handleChangeName = ({ target }) => setNewGroupName(target.value);

	const handleFetchGroups = (groupId) => {
		batch(() => {
			actions.groups.fetchGroup(groupId, {
				include_sub_group_count: true,
				include_player_count: true,
				include_admin_count: true,
			});
			dispatch(
				actions.groups.fetchChildGroups(groupId, {
					include_sub_group_count: true,
					include_player_count: true,
					include_admin_count: true,
					recursive: true,
				})
			);
		});
	};

	const handleConfirm = (groupId) => {
		handleFetchGroups(groupId);
		onClose();
	};

	const handleCreate = async () => {
		await dispatch(
			actions.groups.createGroup({
				name: newGroupName,
				parentGroupId: selectedGroup.id,
			})
		);

		actions.flashes.show(
			{
				title: t('Group created'),
				message: t('Successfully created new group {newGroupName}', {
					newGroupName,
				}),
			},
			200
		);

		handleConfirm(selectedGroup.id);
		refresh();
	};

	const handleMove = async () => {
		await actions.groups.update(group.id, {
			parentGroupId: selectedGroup.id,
		});

		handleFetchGroups(selectedGroup.id);
		handleConfirm(group.parentGroupId);
	};

	const organization = useCollection(endpoints.Groups.ShowChildren(org.id), {
		queryParams: new URLSearchParams({
			recursive: 'true',
		}),
	});
	const listedGroups = models.group.buildGroupTree(org, organization.records);
	const isSmallScreen = useSmallScreen();

	const createProps = {
		heading: t(`Create a new group under {parentName}`, {
			parentName: selectedGroup.name,
		}),
		confirmButton: (
			<Button
				primary
				block={isSmallScreen}
				large={isSmallScreen}
				disabled={3 > newGroupName.length}
				testid="organizations.save">
				{t(`New group`)}
			</Button>
		),
		onConfirm: handleCreate,
	};

	const moveProps = {
		heading: t(`Select new group parent for {groupName}`, {
			groupName: group.name,
		}),
		confirmButton: <Button primary>{t(`Move group`)}</Button>,
		onConfirm: handleMove,
	};

	const allProps = {
		create: createProps,
		move: moveProps,
	};

	return (
		<Dialog
			{...allProps[type]}
			cancelButton={
				<Button block={isSmallScreen} large={isSmallScreen} default>
					{t('Cancel')}
				</Button>
			}
			noSpacing
			onClose={onClose}>
			{type === 'create' && (
				<TopWrapper>
					<TextInput
						required
						pattern=".{3,}"
						onChange={handleChangeName}
						name="groupName"
						placeholder={t(`Give a name to the new group`)}
					/>
					<Help>{t(`Team names must be at least 3 characters long`)}</Help>
				</TopWrapper>
			)}

			<Tree
				groups={listedGroups}
				selectable
				excludeGroupTree={type === 'move' ? group.id : 0}
				selectedGroup={selectedGroup}
				onSelectGroup={handleSelect}
			/>
		</Dialog>
	);
};

export default OrganizationDialog;
