import { ReactNode, useEffect, useState } from 'react';

import Icon from 'components/icon';

import * as Input from 'components/form/inputs';
import * as InputStyles from 'components/form/inputs/styles';
import Column from 'components/layout/column';

import * as Context from 'design/context_menu';

import * as css from './styles.css';

interface OptionProps<T> {
	value: T;
	// Translation that will show in the context menu item
	translation: string;
	// Translation that shows when the context menu is closed
	contextMenuTranslation?: string;

	children?: ReactNode;
	onClick?: (value: T) => void;
}

interface SelectableOptionsProps<T> {
	// Options that will be displayed in the context menu
	selectableOptions: OptionProps<T>[];
	// Param to set alternative default value
	defaultSelectedValue?: T;
}

// Input that looks like a Select input but opens a context menu with different options
export default function SelectableOptions<T>({
	selectableOptions,
	defaultSelectedValue,
}: SelectableOptionsProps<T>) {
	const [selected, setSelected] = useState<OptionProps<T>>(
		defaultSelectedValue
			? selectableOptions.find((so) => so.value === defaultSelectedValue)
			: selectableOptions[0]
	);

	useEffect(() => {
		if (defaultSelectedValue) {
			setSelected(
				selectableOptions.find((so) => so.value === defaultSelectedValue)
			);
		}
	}, [defaultSelectedValue]);

	const handleSelect = (option: OptionProps<T>) => {
		setSelected(option);

		if (option?.onClick && selected.value !== option.value) {
			option.onClick(option.value);
		}
	};

	const selectedOption = selectableOptions.find(
		(so) => so.value === selected.value
	);

	return {
		selected,
		Content: (
			<Context.Menu
				toggleWith={
					<InputStyles.Wrapper className={css.wrapper}>
						<InputStyles.SelectIconWrapper>
							<Icon name="chevron" />
							<Icon name="chevron" />
						</InputStyles.SelectIconWrapper>
						{selectedOption?.contextMenuTranslation ||
							selectedOption.translation}
					</InputStyles.Wrapper>
				}>
				{selectableOptions.map((option, index) => {
					return (
						<Context.Item
							key={index}
							closeOnClick={false}
							onClick={() => handleSelect(option)}>
							<Column>
								<Input.Control
									type="radio"
									label={option.translation}
									checked={selected.value === option.value}
								/>
								{selected.value === option.value && option?.children}
							</Column>
						</Context.Item>
					);
				})}
			</Context.Menu>
		),
	};
}
