import { ReactNode } from 'react';

export const symbol_developer_board_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M828-79 63-843q-9-9.067-9-21.533Q54-877 63.053-886q9.052-9 21.5-9Q97-895 106-886l765 765q9 9 9 21t-9.053 21q-9.052 9-21.5 9Q837-70 828-79ZM236-840h504q23 0 41.5 18.5T800-780v60.146h30q12.75 0 21.375 8.646T860-689.781q0 12.781-8.625 21.281Q842.75-660 830-660h-30v135h30.175q12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.625 21.325T830-465h-30v135h30.175q12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.625 21.325T830-270h-24l-66-66v-444H296l-60-60Zm264 264-17-17v-97q0-12.75 8.625-21.375T513-720h137q12.75 0 21.375 8.625T680-690v84q0 12.75-8.625 21.375T650-576H500Zm180 179L531-546h119q12.75 0 21.375 8.625T680-516v119Zm-246-43Zm69-103ZM200-270v-140q0-12.75 8.625-21.375T230-440h193q12.75 0 21.375 8.625T453-410v140q0 12.75-8.625 21.375T423-240H230q-12.75 0-21.375-8.625T200-270Zm-48-570 60 60h-62v600h590v-72l60 60q-2 28-18 50t-42 22H150q-24 0-42-18t-18-42v-600q0-24 18-40.5t44-19.5Zm331 331 197 197v42q0 12.75-8.625 21.375T650-240H513q-12.75 0-21.375-8.625T483-270v-239ZM272-720l181 181v39q0 12.75-8.625 21.375T423-470H230q-12.75 0-21.375-8.625T200-500v-190q0-12.75 8.625-21.375T230-720h42Z"
		/>
	</svg>
);
