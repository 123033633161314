import { ReactNode } from 'react';

export const symbol_heap_snapshot_multiple: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M430.141-491Q447-491 458.5-502.641t11.5-28.5Q470-548 458.359-559.5t-28.5-11.5Q413-571 401.5-559.359t-11.5 28.5Q390-514 401.641-502.5t28.5 11.5ZM459-337l166-166q9-9.067 9-21.533Q634-537 624.947-546q-9.052-9-21.5-9Q591-555 582-546L415-379q-9 9-8.5 21t9.553 21q9.052 9 21.5 9Q450-328 459-337Zm151.141 26Q627-311 638.5-322.641t11.5-28.5Q650-368 638.359-379.5t-28.5-11.5Q593-391 581.5-379.359t-11.5 28.5Q570-334 581.641-322.5t28.5 11.5ZM780-160H260q-24 0-42-18t-18-42v-640q0-24 18-42t42-18h323q12.444 0 23.722 5T626-902l196 196q8 8 13 19.278 5 11.278 5 23.722v443q0 24-18 42t-42 18Zm0-502H623q-18.75 0-31.875-13.125T578-707v-153H260v640h520v-442ZM140-40q-24 0-42-18t-18-42v-589q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-689v589h468q12.75 0 21.375 8.675Q638-82.649 638-69.825 638-57 629.375-48.5T608-40H140Zm120-820v198-198 640-640Z"
		/>
	</svg>
);
