import Icon from 'components/icon';

import * as css from './styles.css';

const NewBooking = ({ onClick }: { onClick: () => void }) => {
	return (
		<div className={css.wrapper} onClick={onClick}>
			<Icon name="add" />
		</div>
	);
};

export default NewBooking;
