import { ReactNode } from 'react';

export const symbol_domino_mask: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M312-240q-51 0-97.5-18T131-311q-48-45-69.5-106.5T40-545q0-78 38-126.5T189-720q14 0 26.5 2.5T241-710l239 89 239-89q13-5 25.5-7.5T771-720q73 0 111 48.5T920-545q0 66-21.5 127.5T829-311q-37 35-83.5 53T648-240q-66 0-112-30l-46-30h-20l-46 30q-46 30-112 30Zm0-60q40 0 73.5-17t62.5-43h64q29 26 62.5 43t73.5 17q39 0 76.5-14.5T791-357q36-37 52.5-87T860-545q0-49-21.5-82T769-660q-9 0-29 6l-260 97-260-97q-7-3-14.5-4.5T190-660q-48 0-69 33t-21 82q0 52 16 101.5t54 87.5q29 28 65.5 42t76.5 14Zm49-80q37 0 58-16.5t21-45.5q0-49-64.5-93.5T239-580q-37 0-58 16.5T160-518q0 49 64.5 93.5T361-380Zm-6-55q-39 0-86-27.5T214-519q8-3 15-5t15-2q39 0 86.5 28t55.5 57q-8 3-15.5 4.5T355-435Zm244 56q72 0 136.5-45t64.5-94q0-29-20.5-46T721-581q-72 0-136.5 45T520-442q0 29 21 46t58 17Zm5-56q-8 0-14.5-1.5T575-441q9-29 55-56.5t86-27.5q8 0 15 1.5t15 4.5q-9 29-55.5 56.5T604-435Zm-124-45Z"
		/>
	</svg>
);
