import { ReactNode } from 'react';

export const symbol_question_exchange: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-40q-112 0-216-66T100-257v107q0 13-8.5 21.5T70-120q-13 0-21.5-8.5T40-150v-180q0-13 8.5-21.5T70-360h180q13 0 21.5 8.5T280-330q0 13-8.5 21.5T250-300H143q51 77 145.5 138.5T480-100q150 0 258.5-101T859-449q1-13 9.5-22t21.5-9q12 0 21 8.5t8 20.5q-6 86-42.5 161T781-159.5Q722-104 644.5-72T480-40Zm0-820q-150 0-258.5 101T101-511q-1 13-9.5 22T70-480q-12 0-21-8.5T41-509q6-86 42.5-161T179-800.5Q238-856 315.5-888T480-920q112 0 216 66t164 151v-107q0-13 8.5-21.5T890-840q13 0 21.5 8.5T920-810v180q0 13-8.5 21.5T890-600H710q-13 0-21.5-8.5T680-630q0-13 8.5-21.5T710-660h107q-51-77-145-138.5T480-860Zm-3 614q16 0 27-11t11-27q0-16-11-27t-27-11q-16 0-27 11t-11 27q0 16 11 27t27 11Zm69-243q33-33 46-58.5t13-53.5q0-52-36-83.5T479-716q-41 0-73.5 16T353-652q-7 11-3 22.5t15 16.5q11 5 23 .5t20-14.5q14-15 32-23.5t39-8.5q29 0 49 16t20 44q0 20-11 38.5T500-517q-29 27-40.5 47.5T445-424q-2 12 7 21t21 9q12 0 20-8.5t11-20.5q4-18 14-33t28-33Z"
		/>
	</svg>
);
