import { t } from '@transifex/native';
import { Fragment } from 'react';

import { useSmallScreen } from 'components/MediaQuery';

import SearchInput from 'components/form/SearchInput';

interface SearchProps {
	onChange: (value: string) => void;
}

export default function IconSearch({ onChange }: SearchProps) {
	const isSmallScreen = useSmallScreen();
	const handleChange = (value: string) => {
		onChange(value);
	};

	return (
		<Fragment>
			<SearchInput
				small={!isSmallScreen && true}
				onChange={handleChange}
				placeholder={t('Search icons...')}
			/>
		</Fragment>
	);
}
