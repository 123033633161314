import { ReactNode } from 'react';

export const symbol_update_disabled: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M792-83 688-186q-44 32-95.5 49T483-120q-75 0-141-28.5T226.5-226q-49.5-49-78-115T120-482q0-58 17-110t49-96L82-792q-9-9-9-21.5t9-21.5q9-9 21.5-9t21.5 9l710 710q9 9 9 21t-9 21q-9 9-21.5 9T792-83Zm-309-97q45 0 86-13t76-36L229-645q-23 35-36 75t-13 85q0 127 88 216t215 89Zm156-427q-13 0-21.5-8.5T609-637q0-13 8.5-21.5T639-667h75q-44-51-103.5-82T483-780q-39 0-77 9t-73 28q-11 6-22.5 2.5T293-755q-6-11-3-23t14-17q42-21 87-33t92-12q80 0 151.5 35T758-709v-76q0-13 8.5-21.5T788-815q13 0 21.5 8.5T818-785v148q0 13-8.5 21.5T788-607H639Zm-158 30q-13 0-21.5-8.5T451-607v-46q0-13 8.5-21.5T481-683q13 0 21.5 8.5T511-653v46q0 13-8.5 21.5T481-577Zm273 285q-11-8-13-21t5-25q13-23 22-54.5t11-58.5q2-13 10.5-21.5T810-481q13 0 21.5 9t7.5 21q-3 37-15.5 79.5T792-297q-5 10-16.5 11t-21.5-6Z"
		/>
	</svg>
);
