import { ReactNode } from 'react';

export const symbol_airline_seat_recline_normal: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M590-171H300q-24.75 0-42.375-17.625T240-231v-431q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T300-662v431h290q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T590-171ZM438-718q-34 0-57.5-23.5T357-799q0-34 23.5-57.5T438-880q34 0 57.5 23.5T519-799q0 34-23.5 57.5T438-718Zm222 608v-161H415q-30.938 0-52.969-21.737Q340-314.475 340-345v-228q0-40.74 28.387-68.87 28.388-28.13 69.5-28.13Q479-670 507.5-641.87T536-573v202h109q30.938 0 52.969 21.737Q720-327.525 720-297v187q0 12.75-8.675 21.375Q702.649-80 689.825-80 677-80 668.5-88.625T660-110Z"
		/>
	</svg>
);
