import { css, createGlobalStyle, DefaultTheme } from 'styled-components';
import { useSelector } from 'react-redux';

import * as styles from 'pkg/config/styles';

import { addCssVar } from 'pkg/cssvars';
import useGroupPalette from 'pkg/hooks/useGroupPalette';
import { RootState } from 'pkg/reducers';

import { LargeScreen } from 'components/MediaQuery';

const trackColor = addCssVar(
	(theme: DefaultTheme) => styles.palette.gray[theme.darkMode ? 900 : 100]
);

const thumbColor = addCssVar(
	(theme: DefaultTheme) => styles.palette.gray[theme.darkMode ? 700 : 400]
);

const thumbHoverColor = addCssVar(
	(theme: DefaultTheme) => styles.palette.gray[theme.darkMode ? 600 : 500]
);

interface ScrollbarProps {
	darkMode: boolean;
	primaryColor?: string;
	scrollbarColor?: string;
	backgroundColor?: string;
}

const Styles = createGlobalStyle<ScrollbarProps>`
	body {
		scrollbar-width: thin;
		scrollbar-color: ${thumbColor} ${trackColor};
	}

	::-webkit-scrollbar {
		width: 10px;
	}

	::-webkit-scrollbar-track {
		background: ${trackColor};
	}

	::-webkit-scrollbar-thumb {
		background: ${thumbColor};
		border-radius: var(--radius-5);
		border: 2px solid ${trackColor};

		&:hover {
			background: ${thumbHoverColor};
		}
	}

	${({ darkMode, backgroundColor }) =>
		!darkMode &&
		backgroundColor &&
		css`
			::-webkit-scrollbar-track {
				background: ${backgroundColor};
			}
		`}

		${({ primaryColor, scrollbarColor }) =>
			primaryColor &&
			scrollbarColor &&
			css`
				nav ::-webkit-scrollbar-track {
					background: ${primaryColor};
					border-radius: 100px;
				}

				nav ::-webkit-scrollbar-thumb {
					background-color: ${scrollbarColor};
					border-color: ${primaryColor};
				}
			`}
`;

export default function Scrollbars(): JSX.Element {
	const groupPalette = useGroupPalette();
	const darkMode = useSelector((state: RootState) => state.app.darkMode);

	return (
		<LargeScreen>
			<Styles
				darkMode={darkMode}
				primaryColor={groupPalette.primaryColor}
				scrollbarColor={groupPalette.scrollbarColor}
				backgroundColor={groupPalette.backgroundColor}
			/>
		</LargeScreen>
	);
}
