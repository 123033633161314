import { ReactNode } from 'react';

export const symbol_wifi_add: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M740-240h-90q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T650-300h90v-90q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T800-390v90h90q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T890-240h-90v90q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T740-150v-90ZM480-800q120 0 228.5 40.5T909-645q11 9.097 16.5 21.226T931-598q0 12-4.5 24T912-552l-66 66q-8 8-20.5 8t-20.5-8q-8-8-8-20.5t8-20.5l67-67q-87-68-184.5-107T480-740q-110 0-207.5 39T88-594l392 392 39-39q8-8 20.5-8t20.5 8q8 8 8 20.5t-8 20.5l-38 38q-9 9-20 13t-22 4q-11 0-22-4t-20-13L48-552q-9-9-14-21t-5-24q0-14 5.31-26.565Q39.62-636.13 51-645q92-74 200.5-114.5T480-800Zm0 329Z"
		/>
	</svg>
);
