import { useEffect, useRef } from 'react';

enum ModifierKeys {
	Alt = 'Alt',
	Control = 'Control',
	Meta = 'Meta',
	Shift = 'Shift',
}

const PlatformSpecificModifierKey = 'mod';

function getPlatformAcceleratorKey(): ModifierKeys {
	let platform: string = navigator?.platform || 'unknown';

	// @see https://developer.mozilla.org/en-US/docs/Web/API/Navigator/userAgentData
	if (navigator?.userAgentData) {
		platform = navigator?.userAgentData?.platform;
	}

	if (platform.toUpperCase().indexOf('MAC') >= 0) {
		return ModifierKeys.Meta;
	}

	return ModifierKeys.Control;
}

type KeyBindingHandler = (event?: KeyboardEvent) => void;

type KeyBindingTrigger = () => KeyBindingHandler;

/**
 *	Not all KeyboardEvent.code values are present
 *
 *	@see https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/code/code_values
 */
interface DefaultKeyBindings {
	onArrowUp?: KeyBindingTrigger;
	preventDefaultArrowUp?: boolean;

	onArrowDown?: KeyBindingTrigger;
	preventDefaultArrowDown?: boolean;

	onArrowLeft?: KeyBindingTrigger;
	preventDefaultArrowLeft?: boolean;

	onArrowRight?: KeyBindingTrigger;
	preventDefaultArrowRight?: boolean;

	onSpacebar?: KeyBindingTrigger;
	preventDefaultSpacebar?: boolean;

	onBackspace?: KeyBindingTrigger;
	preventDefaultBackspace?: boolean;

	onDelete?: KeyBindingTrigger;
	preventDefaultDelete?: boolean;

	onEnter?: KeyBindingTrigger;
	preventDefaultEnter?: boolean;

	onTab?: KeyBindingTrigger;
	preventDefaultTab?: boolean;

	onEscape?: KeyBindingTrigger;
	preventDefaultEscape?: boolean;

	onShift?: KeyBindingTrigger;
	preventDefaultShift?: boolean;

	onControl?: KeyBindingTrigger;
	preventDefaultControl?: boolean;

	onAlt?: KeyBindingTrigger;
	preventDefaultAlt?: boolean;

	onMeta?: KeyBindingTrigger;
	preventDefaultMeta?: boolean;

	onAudioVolumeMute?: KeyBindingTrigger;
	preventDefaultAudioVolumeMute?: boolean;

	onMediaPlayPause?: KeyBindingTrigger;
	preventDefaultMediaPlayPause?: boolean;

	onMediaTrackPrevious?: KeyBindingTrigger;
	preventDefaultMediaTrackPrevious?: boolean;

	onMediaTrackNext?: KeyBindingTrigger;
	preventDefaultMediaTrackNext?: boolean;
}

interface UserDefinedKeyBindings {
	[key: string]: KeyBindingTrigger;
}

interface UserDefinedKeyBindingsMappings {
	[key: string]: string;
}

export interface KeyBindings extends DefaultKeyBindings {
	// Non-standard triggers

	onZoomIn?: KeyBindingTrigger;
	preventDefaultZoomIn?: boolean;

	onZoomOut?: KeyBindingTrigger;
	preventDefaultZoomOut?: boolean;

	keyBindings?: UserDefinedKeyBindings;
}

export default function useKeyBindings(
	keyBindings: KeyBindings,
	target: EventTarget = window,
	deps: any[] = []
): void {
	const metaStrokeRef = useRef<string[]>([]);
	const keyStrokeRef = useRef<string[]>([]);

	const userDefinedKeyBindings = keyBindings?.keyBindings;
	const normalizedBindings = useRef<UserDefinedKeyBindingsMappings>({});

	const observedDependencies = [...deps, target];

	useEffect(() => {
		/**
		 *	Normalizes user defined key bindings and sorts modifier keys.
		 */
		if (userDefinedKeyBindings) {
			const bindings: UserDefinedKeyBindingsMappings = {};

			Object.entries(userDefinedKeyBindings).forEach(([key]) => {
				const sequence = key
					.split('+')
					.map((key: string) => key.trim().toLocaleLowerCase());

				const modifiers = sequence.filter((key: string) => key.length > 1);
				const keys = sequence.filter((key: string) => key.length === 1);

				if (modifiers.includes(PlatformSpecificModifierKey)) {
					modifiers[modifiers.indexOf(PlatformSpecificModifierKey)] =
						getPlatformAcceleratorKey().toLocaleLowerCase();
				}

				const bindingSequenceString = [...modifiers.sort(), ...keys].join('+');

				bindings[bindingSequenceString] = key;
			});

			normalizedBindings.current = bindings;
		}
	}, [target, userDefinedKeyBindings]);

	/**
	 *	Normalizes event key type
	 *	@param event KeyboardEvent
	 *	@returns string
	 */
	const normalizedKey = (event: KeyboardEvent): string => {
		switch (event.key) {
			case 'Up':
				return 'ArrowUp';
			case 'Down':
				return 'ArrowDown';
			case 'Left':
				return 'ArrowLeft';
			case 'Right':
				return 'ArrowRight';
			case ' ':
				return 'Spacebar';
			default:
				return event.key;
		}
	};

	/**
	 *	Normalizes parts of a key segment
	 *
	 *	@param key string
	 *
	 *	@returns string
	 */
	const normalizedKeyString = (key: string): string => {
		// If key is whitespace, it's most likely just spacebar
		if (!/\S/.test(key)) {
			return 'Spacebar';
		}

		return key;
	};

	/**
	 *	Normalizes modifier key
	 *	@param modifierKey string
	 */
	const normalizeModifierTrigger = (modifierKey: string): string => {
		const modifierTrigger = `on${modifierKey}`;

		switch (modifierTrigger) {
			case 'on-':
				return 'onZoomIn';
			case 'on+':
				return 'onZoomOut';
			default:
				return modifierTrigger;
		}
	};

	/**
	 *	Calls single modifier key types, e.g. "onEnter", "onSpacebar"
	 *	@param event KeyboardEvent
	 */
	const callSingleModifierKeyStroke = (event: KeyboardEvent) => {
		const modifierKey = normalizedKey(event);
		const modifierTrigger: string = normalizeModifierTrigger(modifierKey);

		if (modifierTrigger in keyBindings) {
			const boundTrigger = keyBindings[modifierTrigger as keyof KeyBindings];

			if (typeof boundTrigger === 'function') {
				const trigger: KeyBindingHandler = boundTrigger();

				event.preventDefault();

				trigger(event);
			}
		}
	};

	/**
	 *	Prevents default behaviour of modifier keys and key bindings, e.g. "preventDefaultEnter" will intercept and cancel an "Enter" event.
	 *	@param event KeyboardEvent
	 */
	const preventDefaultUserDefinedBehaviour = (event: KeyboardEvent) => {
		const modifierKey = normalizedKey(event);
		const modifierTrigger = `preventDefault${modifierKey}`;

		const keyStroke = [...metaStrokeRef.current, ...keyStrokeRef.current];
		const keyStrokeString = keyStroke.join('+').toLocaleLowerCase();

		if (
			modifierTrigger in keyBindings ||
			keyStrokeString in normalizedBindings.current
		) {
			event.preventDefault();
		}
	};

	/**
	 *	Observes one, or multiple key stroke event
	 *	@param event KeyboardEvent
	 */
	const observeKeyStrokes = (event: KeyboardEvent) => {
		Object.values(ModifierKeys).forEach((modifierKey: ModifierKeys) => {
			if (
				event.key === modifierKey &&
				!metaStrokeRef.current.includes(modifierKey)
			) {
				metaStrokeRef.current.push(modifierKey);
			}
		});

		if (
			!metaStrokeRef.current.includes(event.key) &&
			!keyStrokeRef.current.includes(event.key)
		) {
			keyStrokeRef.current.push(event.key);
		}

		// Prevent default for user defined key bindings
		preventDefaultUserDefinedBehaviour(event);
	};

	/**
	 *	Calls either single modifier key events, or a sequence of events on keyup.
	 *	@param event KeyboardEvent
	 */
	const observeKeyBindings = (event: KeyboardEvent) => {
		// Do not trigger on prevented events
		if (event.defaultPrevented) return;

		// If target is window, event target will be document.body, this prevents listening on events that are not "global", such as for input fields.
		if (target === window && event.target !== document.body) return;

		if (keyStrokeRef.current.length > 0) {
			const keyStroke = [...metaStrokeRef.current, ...keyStrokeRef.current].map(
				(key: string) => normalizedKeyString(key)
			);

			const keyStrokeString = keyStroke.join('+').toLocaleLowerCase();

			if (keyStrokeString in normalizedBindings.current) {
				const triggerKey = normalizedBindings.current[keyStrokeString];

				if (triggerKey in userDefinedKeyBindings) {
					event.preventDefault();

					const trigger = userDefinedKeyBindings[triggerKey]();

					trigger(event);
				}
			} else {
				callSingleModifierKeyStroke(event);
			}
		}

		metaStrokeRef.current = [];
		keyStrokeRef.current = [];

		event.preventDefault();
	};

	useEffect(() => {
		target?.addEventListener('keydown', observeKeyStrokes, false);
		target?.addEventListener('keyup', observeKeyBindings, false);

		return () => {
			target?.removeEventListener('keydown', observeKeyStrokes, false);
			target?.removeEventListener('keyup', observeKeyBindings, false);
		};
	}, observedDependencies);
}
