import { MouseEvent } from 'react';

import spacing from 'pkg/config/spacing';

import { cssClasses } from 'pkg/css/utils';

import * as Input from 'components/form/inputs';
import Column from 'components/layout/column';

import * as css from './styles.css';

interface SelectableCardProps {
	onClick?: (event: MouseEvent<HTMLDivElement>) => void;
	active?: boolean;
	title: string;
	description?: string;
	infoTitle?: string;
	infoDescription?: string;
	summaryTitle?: string;
	summaryDescription?: string;
	testid?: string;
}

export default function SelectableCard({
	onClick,
	active,
	title,
	description,
	infoTitle,
	infoDescription,
	summaryTitle,
	summaryDescription,
	testid,
	...rest
}: SelectableCardProps): JSX.Element {
	const handleClick = (event: MouseEvent<HTMLDivElement>) => {
		if (onClick) {
			onClick(event);
		}
	};

	const hasInfo = infoTitle || infoDescription;

	return (
		<div
			{...rest}
			onClick={handleClick}
			className={cssClasses(
				css.wrapper,
				active ? css.active : null,
				!hasInfo && css.noInfo
			)}>
			<div className={css.titleWrapper}>
				<Input.Control
					type="checkbox"
					standalone
					large
					rounded
					value={active ? 1 : 0}
					checked={active}
					testid={testid}
				/>
				<Column spacing={spacing._1}>
					{title && <div className={css.title}>{title}</div>}
					{description && <div className={css.description}>{description}</div>}
				</Column>
			</div>
			{hasInfo && (
				<Column spacing={spacing._2} className={css.info}>
					{infoTitle && <div className={css.infoTitle}>{infoTitle}</div>}
					{infoDescription && (
						<div className={css.description}>{infoDescription}</div>
					)}
				</Column>
			)}
			{(summaryTitle || summaryDescription) && (
				<Column spacing={spacing._1} className={css.summary}>
					{summaryTitle && <div className={css.title}>{summaryTitle}</div>}
					{summaryDescription && (
						<div className={css.description}>{summaryDescription}</div>
					)}
				</Column>
			)}
		</div>
	);
}
