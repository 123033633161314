import { ReactNode } from 'react';

export const symbol_shadow_minus: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-80q-24 0-42-18t-18-42v-508q0-24 18-42t42-18h112v-112q0-24 18-42t42-18h508q24 0 42 18t18 42v508q0 24-18 42t-42 18H708v112q0 24-18 42t-42 18H140Zm172-232h508v-508H312v508Zm128-218q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T440-590h240q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T680-530H440Z"
		/>
	</svg>
);
