export const toSmall = 640;
export const fromSmall = toSmall + 1;

export const toMedium = 768;
export const fromMedium = toMedium + 1;

export const toLarge = 1100;
export const fromLarge = toLarge + 1;

export const fromExtraLarge = 1920;

export const small = `all and (max-width: ${toMedium}px)`;
export const large = `all and (min-width: ${fromMedium}px) and (max-width: ${toLarge}px)`;
export const extraLarge = `all and (min-width: ${fromExtraLarge}px)`;
export const nolimit = `all and (min-width: ${fromLarge}px)`;
