import { ReactNode } from 'react';

export const symbol_cloud_download: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M451-448v-289q-86 11-135 75.5T267-522h-19q-61 0-104.5 43T100-371q0 65 45 108t106 43h500q45 0 77-32t32-77q0-45-32-77t-77-32h-63v-84q0-68-33-117.5T570-718v-65q81 29 129.5 101T748-522v24q72-2 122 46t50 123q0 69-50 119t-119 50H251q-88 0-149.5-61.5T40-371q0-79 50.5-137.5T217-579q15-84 82-148.5T451-792q24 0 42 13.5t18 36.5v294l62-62q9-9 21-9t21 9q9 9 9 21.5t-9 21.5L502-353q-9 9-21 9t-21-9L346-467q-9-9-8.5-21.5T347-510q9-9 21.5-9t21.5 9l61 62Zm29-59Z"
		/>
	</svg>
);
