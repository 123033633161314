import { ReactNode } from 'react';

export const symbol_broadcast_on_home: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M190-820q-13 0-21.5-8.5T160-850q0-13 8.5-21.5T190-880h630q24 0 42 18t18 42v122q0 15-9.5 22.5T850-668q-11 0-20.5-8t-9.5-22v-122H190Zm-80 580q-13 0-21.5-9T80-270v-360q0-13 8.5-21.5T110-660h200q12 0 21 8.5t9 21.5v360q0 12-9 21t-21 9H110Zm560 60v-185q-9-6-14.5-15t-5.5-20q0-17 11.5-28.5T690-440q17 0 28.5 11.5T730-400q0 11-5 20t-15 15v185q0 8-6 14t-14 6q-8 0-14-6t-6-14Zm20-330q-45 0-77.5 32T580-400q0 19 7 37t19 32q5 6 5.5 14t-4.5 14q-6 6-14.5 6t-14.5-6q-18-20-28-45t-10-52q0-63 44-106.5T690-550q63 0 106.5 43.5T840-400q0 27-9 52.5T804-302q-6 7-15 6.5t-15-6.5q-6-5-5.5-13t5.5-15q12-15 19-33t7-37q0-46-32-78t-78-32Zm0-100q-87 0-148.5 61T480-400q0 39 14.5 75.5T535-260q6 6 6.5 14.5T536-231q-6 6-14.5 5.5T507-232q-32-35-49.5-78T440-400q0-105 73-177.5T690-650q105 0 177.5 72.5T940-400q0 47-17 90.5T874-232q-6 6-14.5 6t-14.5-6q-6-6-5.5-14t6.5-14q26-28 40-64.5t14-75.5q0-88-61-149t-149-61ZM140-300h140v-300H140v300Zm0 0h140-140Z"
		/>
	</svg>
);
