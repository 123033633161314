import React, {
	useMemo,
	useReducer,
	useCallback,
	createContext,
	useContext,
	ReactNode,
} from 'react';

import { only } from 'pkg/objects';
import * as json from 'pkg/json';

import { Annotation, Keyframe } from 'components/annotations/AnnotationHooks';

export interface ClipState {
	id?: number;
	title?: string;
	description?: string;
	startsAt?: number;
	startsAtMs?: number;
	endsAt?: number;
	endsAtMs?: number;
	reviewed?: boolean;
	private?: boolean;
	taggedUsers?: TaggedUsers;
	annotations?: string;
	tags?: (string | number)[];
	activeAnnotationId?: string;
	activeKeyframeIndex?: number;
	hasChanges?: boolean;
}

export enum ClipAction {
	Reset = 'reset',
	ID = 'id',
	Title = 'title',
	Description = 'description',
	StartsAt = 'startsAt',
	StartsAtMs = 'startsAtMs',
	EndsAt = 'endsAt',
	EndsAtMs = 'endsAtMs',
	IsHighlight = 'reviewed',
	IsPrivate = 'private',
	TaggedUsers = 'taggedUsers',
	Annotations = 'annotations',
	Tags = 'tags',
	ActiveAnnotation = 'activeAnnotationId',
	ActiveKeyframe = 'activeKeyframeIndex',
	HasChanges = 'hasChanges',
}

export type Action =
	| { type: ClipAction.Reset; payload: ClipState }
	| { type: ClipAction.ID; payload: ClipState }
	| { type: ClipAction.Title; payload: ClipState }
	| { type: ClipAction.Description; payload: ClipState }
	| { type: ClipAction.StartsAt; payload: ClipState }
	| { type: ClipAction.StartsAtMs; payload: ClipState }
	| { type: ClipAction.EndsAt; payload: ClipState }
	| { type: ClipAction.EndsAtMs; payload: ClipState }
	| { type: ClipAction.IsHighlight; payload: ClipState }
	| { type: ClipAction.IsPrivate; payload: ClipState }
	| { type: ClipAction.TaggedUsers; payload: ClipState }
	| { type: ClipAction.Annotations; payload: ClipState }
	| { type: ClipAction.Tags; payload: ClipState }
	| { type: ClipAction.ActiveAnnotation; payload: ClipState }
	| { type: ClipAction.ActiveKeyframe; payload: ClipState }
	| { type: ClipAction.HasChanges; payload: ClipState };

type ClipPayload = number | string | boolean | null | ClipState | TaggedUsers;

interface ClipStateDispatch {
	state: ClipState;
	dispatch?: React.Dispatch<Action>;
	emit?: (type: ClipAction, payload: ClipPayload) => void;
}

const initialState: ClipState = {
	id: 0,
	title: '',
	description: '',
	startsAt: 0,
	startsAtMs: 0,
	endsAt: 0,
	endsAtMs: 0,
	reviewed: false,
	private: false,
	taggedUsers: {},
	annotations: '',
	tags: [],
	activeAnnotationId: '',
	activeKeyframeIndex: null,
	hasChanges: false,
};

const ClipContext = createContext<ClipStateDispatch>({
	state: initialState,
});

interface TaggedUsers {
	[userId: number]: string;
}

export interface ParsedAnnotations {
	[key: string]: Annotation;
}

interface ClipHookResponse extends ClipState {
	reset: (nextState?: ClipState) => void;
	setId: (id: number) => void;
	setTitle: (title: string) => void;
	setDescription: (description: string) => void;
	setStartsAt: (startsAt: number) => void;
	getStartsAt: () => number;
	setEndsAt: (endsAt: number) => void;
	getEndsAt: () => number;
	getDuration: () => number;
	setIsHighlight: (isHighlight: boolean) => void;
	setIsPrivate: (isPrivate: boolean) => void;
	setTaggedUsers: (taggedUsers: TaggedUsers) => void;
	setAnnotations: (annotations: JSONObject) => void;
	setAnnotation: (annotationId: string, annotation: Annotation) => void;
	getAnnotation: (annotationId: string) => Annotation;
	removeAnnotation: (annotationId: string) => void;
	setActiveAnnotation: (annotationId: string) => void;
	getActiveAnnotation: () => Annotation;
	getParsedAnnotations: () => ParsedAnnotations;
	getTags: () => string[];
	setTags: (tags: string[]) => void;
	setActiveKeyframe: (keyframeIndex: number) => void;
	getPayload: () => ClipState;

	numAnnotations: number;
	numTaggedUsers: number;
}

export const useClipState = (): ClipHookResponse => {
	const { state, emit } = useContext(ClipContext);

	const reset = (nextState?: ClipState): void =>
		emit(ClipAction.Reset, nextState);

	const setId = (id: number): void => emit(ClipAction.ID, id);

	const setTitle = (title: string): void => emit(ClipAction.Title, title);

	const setDescription = (description: string): void =>
		emit(ClipAction.Description, description);

	const setStartsAt = (startsAt: number): void => {
		emit(ClipAction.StartsAtMs, startsAt * 1000);
	};

	const getStartsAt = () =>
		state.startsAtMs ? state.startsAtMs / 1000 : state.startsAt;

	const setEndsAt = (endsAt: number): void => {
		emit(ClipAction.EndsAtMs, endsAt * 1000);
	};

	const getEndsAt = () =>
		state.endsAtMs ? state.endsAtMs / 1000 : state.endsAt;

	const setIsHighlight = (isHighlight: boolean): void =>
		emit(ClipAction.IsHighlight, isHighlight);

	const setIsPrivate = (isPrivate: boolean): void =>
		emit(ClipAction.IsPrivate, isPrivate);

	const setTaggedUsers = (taggedUsers: TaggedUsers): void =>
		emit(ClipAction.TaggedUsers, taggedUsers);

	const setAnnotations = (annotations: JSONObject): void =>
		emit(
			ClipAction.Annotations,
			annotations ? JSON.stringify(annotations) : ''
		);

	const getTags = (): (string | number)[] => state.tags || [];

	const setTags = (tags: string[]): void => emit(ClipAction.Tags, tags);

	const getPayload = (): ClipState => {
		const payload = only(
			state,
			'startsAt',
			'startsAtMs',
			'endsAt',
			'endsAtMs',
			'title',
			'description',
			'reviewed',
			'private',
			'annotations',
			'tags'
		);

		if (payload.startsAt && !payload.endsAtMs) {
			payload.startsAtMs = payload.startsAt * 1000;
		}

		if (payload.endsAt && !payload.endsAtMs) {
			payload.endsAtMs = payload.endsAt * 1000;
		}

		payload.startsAtMs = Math.floor(payload.startsAtMs);
		payload.endsAtMs = Math.ceil(payload.endsAtMs);

		return payload;
	};

	const getDuration = (): number =>
		Number.parseFloat(Math.abs(getEndsAt() - getStartsAt()).toFixed(2));

	const getParsedAnnotations = (): ParsedAnnotations => {
		const annotations = json.parse<ParsedAnnotations>(state.annotations);

		if (!annotations) {
			return {} as ParsedAnnotations;
		}

		return annotations;
	};

	const setAnnotation = (
		annotationId: string,
		annotation: Partial<Annotation>
	) => {
		const annotations = json.parse<ParsedAnnotations>(state.annotations);

		if (!annotations) return;

		const currentAnnotation = annotations[annotationId];
		const nextAnnotation: Annotation = { ...currentAnnotation, ...annotation };

		setAnnotations({
			...annotations,
			[annotationId]: nextAnnotation,
		} as unknown as JSONObject);
	};

	const getAnnotation = (annotationId: string): Annotation => {
		const annotations = json.parse<ParsedAnnotations>(state.annotations);

		if (!annotations) return;

		if (annotationId in annotations) {
			return annotations[annotationId] as Annotation;
		}

		return null;
	};

	const removeAnnotation = (annotationId: string) => {
		const annotations = json.parse(state.annotations);

		if (!annotations) return;

		if (annotationId in annotations) {
			delete annotations[annotationId];

			setAnnotations(annotations);

			if (state.activeAnnotationId === annotationId) {
				setActiveAnnotation('');
			}
		}
	};

	const setActiveAnnotation = (annotationId: string): void => {
		emit(ClipAction.ActiveAnnotation, annotationId);
		emit(ClipAction.ActiveKeyframe, 0);
	};

	const getActiveAnnotation = (): Annotation | null => {
		const annotations = getParsedAnnotations();

		if (state.activeAnnotationId && state.activeAnnotationId in annotations) {
			return annotations[state.activeAnnotationId];
		}

		return null;
	};

	const setActiveKeyframe = (keyframeIndex: number): void =>
		emit(ClipAction.ActiveKeyframe, keyframeIndex);

	const numAnnotations = Object.keys(getParsedAnnotations()).filter(
		(annotation) => annotation !== 'playback'
	).length;
	const numTaggedUsers = Object.keys(state.taggedUsers).length;

	return {
		...state,

		reset,
		setId,
		setTitle,
		setDescription,
		setStartsAt,
		getStartsAt,
		setEndsAt,
		getEndsAt,
		getDuration,
		setIsHighlight,
		setIsPrivate,
		setTaggedUsers,
		setAnnotations,
		setAnnotation,
		getAnnotation,
		setTags,
		getTags,
		removeAnnotation,
		getParsedAnnotations,
		setActiveAnnotation,
		getActiveAnnotation,
		setActiveKeyframe,
		getPayload,
		numAnnotations,
		numTaggedUsers,
	} as ClipHookResponse;
};

interface StateProviderProps {
	children: ReactNode;
}

const reducer = (state: ClipState, action: Action) => {
	const mutateState = (state: ClipState, nextState: ClipState): ClipState => ({
		...state,
		...nextState,
		hasChanges: true,
	});

	const actionType = action.type;

	switch (actionType) {
		case ClipAction.Reset:
			return only(
				{
					...initialState,
					...(action.payload as any).reset,
					hasChanges: false,
				},
				'startsAt',
				'startsAtMs',
				'endsAt',
				'endsAtMs',
				'title',
				'description',
				'reviewed',
				'private',
				'annotations',
				'taggedUsers',
				'hasChanges'
			);
		case ClipAction.ID:
		case ClipAction.Title:
		case ClipAction.Description:
		case ClipAction.StartsAt:
		case ClipAction.StartsAtMs:
		case ClipAction.EndsAt:
		case ClipAction.EndsAtMs:
		case ClipAction.TaggedUsers:
		case ClipAction.Tags:
		case ClipAction.IsHighlight:
		case ClipAction.IsPrivate:
		case ClipAction.Annotations:
		case ClipAction.ActiveAnnotation:
		case ClipAction.ActiveKeyframe:
			return mutateState(state, action.payload);
		default:
			throw new Error(`Invalid action '${actionType}'.`);
	}
};

const ClipStateProvider = ({ children }: StateProviderProps): JSX.Element => {
	const [state, dispatch] = useReducer(reducer, initialState);

	const emit = useCallback(
		(type: ClipAction, payload: ClipPayload) =>
			dispatch({
				type,
				payload: {
					[type]: payload,
				},
			}),
		[dispatch]
	);

	const store: ClipStateDispatch = useMemo(
		() => ({
			state,
			dispatch,
			emit,
		}),
		[state, dispatch, emit]
	);

	return <ClipContext.Provider value={store}>{children}</ClipContext.Provider>;
};

export default ClipStateProvider;

interface KeyframesHook {
	first?: Keyframe;
	goFirst: () => void;

	last?: Keyframe;
	goLast: () => void;

	current?: Keyframe;

	canGoNext: boolean;
	next?: Keyframe;
	goNext: () => void;

	canGoPrev: boolean;
	prev?: Keyframe;
	goPrev: () => void;
}

export function useKeyframes(): KeyframesHook {
	const clipState = useClipState();
	const annotation = clipState.getActiveAnnotation();

	let keyframes: Keyframe[] = [];

	if (annotation && annotation.keyframes) {
		keyframes = annotation.keyframes;
	}

	const hasKeyframes = keyframes.length >= 1;
	const index = clipState.activeKeyframeIndex;

	const first = hasKeyframes ? keyframes[0] : null;
	const goFirst = () => clipState.setActiveKeyframe(0);

	const last = hasKeyframes ? keyframes[keyframes.length - 1] : null;
	const goLast = () => clipState.setActiveKeyframe(keyframes.length - 1);

	const current = hasKeyframes ? keyframes[index] : null;

	const canGoNext = keyframes.hasOwnProperty(index + 1);
	const next = hasKeyframes && canGoNext ? keyframes[index + 1] : null;
	const goNext = () => {
		if (canGoNext) {
			clipState.setActiveKeyframe(index + 1);
		}
	};

	const canGoPrev = keyframes.hasOwnProperty(index - 1);
	const prev = hasKeyframes && canGoPrev ? keyframes[index - 1] : null;
	const goPrev = () => {
		if (canGoPrev) {
			clipState.setActiveKeyframe(index - 1);
		}
	};

	return {
		first,
		goFirst,

		last,
		goLast,

		current,

		canGoNext,
		next,
		goNext,

		canGoPrev,
		prev,
		goPrev,
	};
}
