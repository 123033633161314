import { ReactNode } from 'react';

export const symbol_share_reviews: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M580.089-370Q609-370 629.5-390.589q20.5-20.588 20.5-49.5Q650-469 629.438-489.5 608.875-510 580-510q-14.643 0-27.821 5.5Q539-499 529-488l-120-60q1-3 1-6v-12q0-3-1-6l120-60q10 11 23.179 16.5Q565.357-610 580-610q28.875 0 49.438-20.589Q650-651.177 650-680.089 650-709 629.411-729.5q-20.588-20.5-49.5-20.5Q551-750 530.5-729.438 510-708.875 510-680v6q0 3 1 6l-120 60q-10-11-23.179-16.5Q354.643-630 340-630q-28.875 0-49.438 20.589Q270-588.823 270-559.911 270-531 290.562-510.5 311.125-490 340-490q14.643 0 27.821-5.5Q381-501 391-512l120 60q-1 3-1 6v6q0 28.875 20.589 49.438Q551.177-370 580.089-370ZM240-240 131-131q-14 14-32.5 6.344T80-152v-668q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H240Zm-26-60h606v-520H140v600l74-80Zm-74 0v-520 520Z"
		/>
	</svg>
);
