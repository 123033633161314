import { Record } from 'immutable';
import { schema } from 'normalizr';

import User from 'pkg/models/user';
import Account from 'pkg/models/account';

export const videoSequenceComments = new schema.Entity(
	'videoSequenceComments',
	{
		user: User.normalizr(),
		account: Account.normalizr(),
	}
);

interface SequenceComment {
	id: number;
	videoSequenceId: number;
	userId: number;
	accountId: number;
	replyCommentId: number;
	content: string;
	createdAt?: number;
	updatedAt?: number;
	links?: { [key: string]: string };
}

export interface VideoSequenceCommentPayload {
	replyCommentId?: number;
	content: string;
}

const videoSequenceCommentProps: SequenceComment = {
	id: 0,
	videoSequenceId: 0,
	userId: 0,
	accountId: 0,
	replyCommentId: 0,
	content: '',
	createdAt: 0,
	updatedAt: 0,
	links: {},
};

class VideoSequenceComment
	extends Record(videoSequenceCommentProps, 'VideoSequenceCommentRecord')
	implements SequenceComment
{
	static normalizr(): schema.Entity {
		return videoSequenceComments;
	}
}

export default VideoSequenceComment;
