import { ReactNode } from 'react';

export const symbol_report_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M605-840q12.444 0 23.722 5T648-822l174 174q8 8 13 19.278 5 11.278 5 23.722v254q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T780-351v-254L604.52-780H352q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T352-840h253Zm-95 318v-136q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T450-658v76l60 60ZM355-120q-12.444 0-23.722-5T312-138L138-312q-8-8-13-19.278-5-11.278-5-23.722v-250q0-12.444 5-23.722T138-648l39-38-99-99q-9-9-9-21t9-21q9-9 21-9t21 9l707 707q9 9 9 21t-9 21q-9 9-21 9t-21-9l-98-98-39 39q-8.317 8.16-19.659 12.58Q617-120 605-120H355Zm161-396ZM355-180h250l39-38-426-426-38 39v250l175 175Zm125-101q-14 0-24.5-10.5T445-316q0-14 10.5-24.5T480-351q14 0 24.5 10.5T515-316q0 14-10.5 24.5T480-281Zm-49-150Z"
		/>
	</svg>
);
