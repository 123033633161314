import { ReactNode } from 'react';

export const symbol_south_west: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M230-200q-12.75 0-21.375-8.625T200-230v-336q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T260-566v264l477-477q9-9 21-9t21 9q9 9 9 21t-9 21L302-260h264q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T566-200H230Z"
		/>
	</svg>
);
