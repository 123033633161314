import { Fragment, useState } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as actions from 'pkg/actions';
import * as endpoints from 'pkg/api/endpoints/auto';
import { canDelete, destroy } from 'pkg/api/models';
import { replaceState } from 'pkg/router/state';
import * as routes from 'pkg/router/routes';
import DateTime from 'pkg/datetime';
import * as models from 'pkg/api/models';
import Link from 'pkg/router/Link';
import { useCurrentMembership, useCurrentOrganization } from 'pkg/identity';
import { link } from 'pkg/router/utils';

import AssignProductModal from 'containers/payment_platform/contacts/AssignProductModal';
import AddToSingleGroupModal from 'containers/payment_platform/contacts/AddToSingleGroupModal';

import Heading from 'components/Heading';
import Icon from 'components/icon';
import Avatar from 'components/avatar';

import Column from 'components/layout/column';
import * as SideBarLayout from 'components/layout/side-bar-page';
import OrderStatusLabel from 'components/payment_platform/status/label';

import * as ContextMenu from 'design/context_menu';
import Button from 'design/button';

import * as css from './styles.css';

const SectionColumn = styled(Column)`
	p {
		font-size: var(--font-size-sm);
	}

	p:first-child {
		color: var(--palette-gray-500);
	}
`;

interface SideBarProps {
	groupId: number;
	submission: models.formSubmission.FormSubmission;
}

const SubmissionSideBar: React.FC<React.PropsWithChildren<SideBarProps>> = ({
	groupId,
	submission,
}) => {
	const organization = useCurrentOrganization();
	const membership = useCurrentMembership();

	const isAdminOrStaff = models.membership.isAdminOrStaff(membership);

	const handleDelete = () => {
		if (
			window.confirm(
				t('Do you want to delete this?', { _context: 'confirm messages' })
			)
		) {
			destroy(submission);

			replaceState(
				routes.Registrations.Single(
					organization.id,
					groupId,
					submission.formId,
					'overview'
				)
			);
		}
	};

	const { order, submittedForUser, createdAt } = submission;
	const [showAssignModal, setShowAssignModal] = useState(false);
	const [showAddGroupModal, setShowAddGroupModal] = useState(false);

	const handleAssignProduct = () => {
		setShowAssignModal(true);
	};
	const handleCloseAssignModal = () => {
		setShowAssignModal(false);
	};

	const handleAddUsersToGroup = () => {
		setShowAddGroupModal(true);
	};

	const handleCloseGroupModal = () => {
		setShowAddGroupModal(false);
	};

	const handleAddToGroup = async (groupId: number, role: number) => {
		const [request] = await models.create(
			endpoints.UserGroups.AddUsersToGroup(groupId),
			[{ id: submittedForUser.id, role }]
		);

		if (request.ok) {
			actions.flashes.show({
				title: t('Added users to group'),
			});
		} else {
			actions.flashes.show({
				title: t('Unable to add users to group'),
			});
		}
	};

	const currentMembership = useCurrentMembership();

	const createdAtAsDateTime = new DateTime(new Date(createdAt * 1000));

	const invoiceLink = (
		<Link href={routes.Order.Show(organization.id, order?.id)}>
			{t('View')}
		</Link>
	);

	const submissionActions = (
		<ContextMenu.Menu
			toggleWith={
				<Button primary hasIcon block>
					{t(`Actions`)}
					<Icon name="chevron" rotate="90deg" />
				</Button>
			}>
			{submission.submittedForUser && (
				<Fragment>
					<ContextMenu.LinkItem
						href={routes.Management.Contact.Show(
							organization.id,
							submittedForUser.id,
							'overview'
						)}>
						<ContextMenu.ItemIcon name="visibility_off" />
						{t(`View contact`)}
					</ContextMenu.LinkItem>
					<ContextMenu.LinkItem
						href={link(routes.Invoice.New(organization.id), {
							userIds: [submission.submittedForUserId],
						})}
						icon="add">
						{t('New order')}
					</ContextMenu.LinkItem>
					<ContextMenu.Item onClick={handleAssignProduct} icon="sell">
						{t('Assign product')}
					</ContextMenu.Item>
					{submission?.submittedForUser?.email &&
						submission.submittedForUserId !== currentMembership.user?.id && (
							<ContextMenu.LinkItem
								href={`mailto:${submission.submittedForUser.email}`}
								icon="mail">
								{t('Send Email')}
							</ContextMenu.LinkItem>
						)}
					<ContextMenu.LinkItem onClick={handleAddUsersToGroup}>
						<ContextMenu.ItemIcon name="groups" />
						{t('Add users to group')}
					</ContextMenu.LinkItem>
				</Fragment>
			)}

			{canDelete(submission) && (
				<ContextMenu.Item caution onClick={handleDelete}>
					<ContextMenu.ItemIcon name="archive" />
					{t(`Delete submission`)}
				</ContextMenu.Item>
			)}
		</ContextMenu.Menu>
	);

	return (
		<Fragment>
			<SideBarLayout.TopWrapper>
				<Column>
					<SideBarLayout.AvatarWrapper>
						<Avatar user={submittedForUser} size={100} />
					</SideBarLayout.AvatarWrapper>
					<div>
						{submission.order && (
							<div className={css.statusLabel}>
								<OrderStatusLabel
									status={models.order.getStatus(submission.order)}
								/>
							</div>
						)}

						<Heading center>{models.user.fullName(submittedForUser)}</Heading>
						<p>
							{t(`Submitted {date}`, {
								date: createdAtAsDateTime.toLocaleDateString({
									year: 'numeric',
									month: 'numeric',
									day: 'numeric',
								}),
							})}
						</p>
					</div>
					{isAdminOrStaff && submissionActions}
				</Column>
			</SideBarLayout.TopWrapper>

			<Fragment>
				<ContextMenu.Divider />
				<SideBarLayout.SideBarBody>
					<Column spacing={styles.spacing._8}>
						<SectionColumn>
							<Heading>{t(`Submitted by`)}</Heading>
							<div>
								<p>{t(`Name`)}</p>

								{isAdminOrStaff ? (
									<Link
										href={routes.Management.Contact.Show(
											organization.id,
											submission.submittedByUserId,
											'overview'
										)}>
										{models.user.fullName(submission.submittedByUser)}
									</Link>
								) : (
									models.user.fullName(submission.submittedByUser)
								)}
							</div>
							<div>
								<p>{t(`Email`)}</p>
								<p>{submission.submittedByUser.email}</p>
							</div>
						</SectionColumn>
						{!!order && (
							<SectionColumn>
								<Heading>{t(`Order details`)}</Heading>
								{order.invoiceNumber && (
									<div>
										<p>{t('Invoice number')}</p>
										<p>
											{order.invoiceNumber} {invoiceLink}
										</p>
									</div>
								)}
								<div>
									<p>{t(`First name`)}</p>
									<p>{order.firstName}</p>
								</div>
								<div>
									<p>{t(`Last name`)}</p>
									<p>{order.lastName}</p>
								</div>
							</SectionColumn>
						)}
					</Column>
				</SideBarLayout.SideBarBody>
			</Fragment>
			{showAssignModal && (
				<AssignProductModal
					organizationId={organization.id}
					userIds={[submission.submittedForUserId]}
					onClose={handleCloseAssignModal}
				/>
			)}
			{showAddGroupModal && (
				<AddToSingleGroupModal
					groupId={groupId}
					onConfirm={handleAddToGroup}
					onClose={handleCloseGroupModal}
				/>
			)}
		</Fragment>
	);
};

export default SubmissionSideBar;
