import { ReactNode } from 'react';

export const symbol_coronavirus: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M450-80q-8 0-14-6t-6-14q0-8 6-14t14-6h10v-91q-45-5-85-21.5T303-276l-64 64 8 8q5 5 6.5 12.5T247-176q-6 6-14 6t-14-6l-43-42q-6-6-6-14t6-15q6-6 13.5-6t15.5 7l6 6 64-64q-26-33-42.5-72T211-460h-91v10q0 8-6 14t-14 6q-8 0-14-6t-6-14v-60q0-8 6-14t14-6q8 0 14 6t6 14v10h91q5-45 21.5-84t42.5-72l-64-64-7 7q-6 6-14 6t-14-6q-6-6-6-14.5t6-14.5l42-42q6-6 14.5-6t14.5 6q6 5 6 13.5t-6 13.5l-8 8 64 64q33-26 72-42.5t84-21.5v-91h-9q-8 0-14-6t-6-14q0-8 6-14t14-6h60q8 0 14 6t6 14q0 8-6 14t-14 6h-10v91q45 5 84 21.5t72 42.5l64-64-6-6q-7-6-7.5-14t6.5-15q6-6 14-6t14 6l44 44q6 6 5.5 13.5T784-713q-6 6-14 5t-15-7l-6-6-64 64q26 33 42.5 72.5T749-500h91v-10q0-8 6-14t14-6q8 0 14 6t6 14v60q0 8-6 14t-14 6q-8 0-14-6t-6-14v-10h-91q-5 45-21.5 84.5T685-303l64 64 7-7q6-6 14-6.5t14 5.5q6 6 6 14.5t-6 14.5l-42 42q-6 6-14.5 6t-14.5-6q-7-7-6-14.5t5-12.5l8-8-64-64q-33 26-72 43t-84 22v90h10q8 0 14 6t6 14q0 8-6 14t-14 6h-60Zm30-190q88 0 149-61.5T690-480q0-88-61-149t-149-61q-87 0-148.5 61T270-480q0 87 61.5 148.5T480-270Zm-70-60q12 0 21-9t9-21q0-12-9-21t-21-9q-12 0-21 9t-9 21q0 12 9 21t21 9Zm140 0q13 0 21.5-9t8.5-21q0-12-8.5-21t-21.5-9q-12 0-21 9t-9 21q0 12 9 21t21 9ZM340-450q13 0 21.5-9t8.5-21q0-12-8.5-21t-21.5-9q-12 0-21 9t-9 21q0 12 9 21t21 9Zm140 0q12 0 21-9t9-21q0-12-9-21t-21-9q-12 0-21 9t-9 21q0 12 9 21t21 9Zm140 0q13 0 21.5-9t8.5-21q0-12-8.5-21t-21.5-9q-12 0-21 9t-9 21q0 12 9 21t21 9ZM410-570q12 0 21-9t9-21q0-12-9-21t-21-9q-12 0-21 9t-9 21q0 12 9 21t21 9Zm140 0q13 0 21.5-9t8.5-21q0-12-8.5-21t-21.5-9q-12 0-21 9t-9 21q0 12 9 21t21 9Zm-70 90Z"
		/>
	</svg>
);
