import { ReactNode } from 'react';

export const symbol_schedule_send: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M120-205v-550q0-16 13.5-24.5T162-782l622 262h-59q-24 0-45.5 1t-37.5 4L180-710v168l242 62-242 60v167l282-118q-8 17-13.5 39.5T441-295L162-178q-15 6-28.5-2.5T120-205ZM690-80q-78 0-134-55.5T500-269q0-79 56-135t134-56q78 0 134 56t56 135q0 78-56 133.5T690-80Zm17-199v-100q0-8-6-14t-14-6q-8 0-14.5 6.5T666-378v106q0 6 2 11t7 10l70 70q6 6 15 6t15-7q6-6 5.5-15t-6.5-15l-67-67Zm-527-92v-339 457-118Z"
		/>
	</svg>
);
