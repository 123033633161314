import { t } from '@transifex/native';
import { useState } from 'react';

import * as models from 'pkg/api/models';
import { useCurrentOrganization } from 'pkg/identity';

import * as Card from 'components/Card';
import SectionTitle from 'components/SectionTitle';
import Modal from 'components/modal';

import InfoBox from 'components/form/info-box';
import Column from 'components/layout/column';
import SelectableList from 'components/user/selectable_list';

import Button, { ButtonGroup } from 'design/button';

interface ArchiveConfirmModalProps {
	users: models.user.User[];

	handleArchiveConfirm: (users: models.user.User[]) => void;
	closeModal: () => void;
}

export default function ArchiveConfirmModal({
	users,
	handleArchiveConfirm,
	closeModal,
}: ArchiveConfirmModalProps) {
	const organization = useCurrentOrganization();
	const [selectedUsers, setSelectedUsers] = useState(users);

	// We will get one user here because this is a single select
	const handleChange = (users: models.user.User[]) => {
		const userId = users.map((u) => u.id)[0];
		const selectedUserIds = selectedUsers.map((u) => u.id);

		if (selectedUserIds.includes(userId)) {
			setSelectedUsers(selectedUsers.filter((u) => u.id !== userId));
		} else {
			setSelectedUsers([...selectedUsers, users[0]]);
		}
	};

	// Return all users we want to remove from the selection
	const onConfirm = () => {
		handleArchiveConfirm(
			users.filter((u) => !selectedUsers.map((su) => su.id).includes(u.id))
		);
		closeModal();
	};

	return (
		<Modal onClose={closeModal}>
			<Card.Base>
				<Card.Header>
					<Card.Heading>{t('Confirm')}</Card.Heading>
				</Card.Header>
				<Card.Divider />
				<Card.Body $flex>
					<Column>
						<InfoBox
							color="orange"
							text={t(
								'Important: These users have active accounts. If archived they will lose access to their accounts in {organization}.',
								{ organization: organization.name }
							)}
						/>
						<div>
							<SectionTitle>{t('Accounts that will be archived')}</SectionTitle>
							<SelectableList
								users={users}
								selectedUsers={selectedUsers}
								onChange={handleChange}
							/>
						</div>
					</Column>
				</Card.Body>
				<Card.Footer>
					<ButtonGroup>
						<Button label={t('Cancel')} onClick={closeModal} />
						<Button label={t('Confirm')} primary onClick={onConfirm} />
					</ButtonGroup>
				</Card.Footer>
			</Card.Base>
		</Modal>
	);
}
