import { ReactNode } from 'react';

export const symbol_nest_found_savings: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M360-160H180q-24 0-42-18.5T120-220v-600q0-24 18-42t42-18h600q23 0 41.5 18t18.5 42v600q0 23-18.5 41.5T780-160H600l-99 99q-9 9-21 9t-21-9l-99-99Zm-180-60h204l96 96 96-96h204v-600H180v600Zm300-297Zm.102 187Q561-330 615.5-387T670-520v-160q0-12.75-8.625-21.375T640-710H480q-76.95 0-133.475 54.274Q290-601.452 290-519.565q0 28.565 9 55.065 9 26.5 26 52.5l-22 22q-9 9-9 21t9 21q9 9 21 9t21-9l21-20q26 18 55 28t59.102 10ZM480-390q-17 0-35-6t-36-15l114-114q9-9.067 9-21.533Q532-559 523-568q-9-9-21-9t-21 9L366-453q-8-19-12-36t-4-31.2q0-56.8 39-93.3t91-36.5h130v130q0 54.167-37.917 92.083Q534.167-390 480-390Zm-25-110Z"
		/>
	</svg>
);
