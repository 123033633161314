import { ReactNode } from 'react';

export const symbol_playlist_play: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M150-330q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-390h246q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T396-330H150Zm0-165q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-555h413q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T563-495H150Zm0-165q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-720h413q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T563-660H150Zm536 522q-4 3-8 3.5t-8-1.5q-4-2-6-5.6-2-3.6-2-8.4v-262q0-4.8 2-8.4 2-3.6 6-5.6 4-2 8-1.5t8 3.5l178 131q3.333 2.118 4.667 5.294Q870-284.529 870-281q0 3.529-1.333 6.706-1.334 3.176-4.667 5.294L686-138Z"
		/>
	</svg>
);
