import { ReactNode } from 'react';

export const symbol_bedroom_baby: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M481.615-219Q557-219 627-243.5 697-268 754-316q11-9.257 12-22.629Q767-352 757-360q-10-8-22-8t-23 8q-7 5-13.5 9t-13.5 8l-41-76v-103h31q6.8 0 11.9-5.143 5.1-5.143 5.1-12T686.9-551q-5.1-5-11.9-5H451l-68-117q-3.714-6.75-10.771-10.875Q365.171-688 357-688h-87q-5 0-7 5.5t3 8.5l16 11-53 56q-7 7.135-7 16.5t7 16.5l4 4q6.192 6.222 14.596 7.111Q256-562 264-567l52-36v184l-40 76q-8-4-15.5-9T246-362q-10-8-22-7t-20.941 8.529Q193-352 193.5-339.121 194-326.242 204-318q59 49 130.077 74t147.538 25Zm.885-59q-40.5 0-82.5-8.5T316-318l46-76q31 12 60.177 18T480-370q29 0 58-6t60-18l46 76q-42 22-81.5 31t-80 9ZM140-80q-24 0-42-18t-18-42v-680q0-24 18-42t42-18h680q24 0 42 18t18 42v680q0 24-18 42t-42 18H140Zm0-60h680v-680H140v680Zm0 0v-680 680Z"
		/>
	</svg>
);
