import { ReactNode } from 'react';

export const symbol_release_alert: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m332-84-62-106-124-25q-11-2-18.5-12t-5.5-21l14-120-79-92q-8-8-8-20t8-20l79-91-14-120q-2-11 5.5-21t18.5-12l124-25 62-107q6-10 17-14t22 1l109 51 109-51q11-5 22-1.5t17 13.5l63 108 123 25q11 2 18.5 12t5.5 21l-14 120 79 91q8 8 8 20t-8 20l-79 92 14 120q2 11-5.5 21T814-215l-123 25-63 107q-6 10-17 13.5T589-71l-109-51-109 51q-11 5-22 1t-17-14Zm41-55 107-45 110 45 67-100 117-30-12-119 81-92-81-94 12-119-117-28-69-100-108 45-110-45-67 100-117 28 12 119-81 94 81 92-12 121 117 28 70 100Zm107-341Zm0 200q14 0 23.5-9.5T513-313q0-14-9.5-23.5T480-346q-14 0-23.5 9.5T447-313q0 14 9.5 23.5T480-280Zm3-153q13 0 21.5-8.5T513-463v-193q0-13-8.5-21.5T483-686q-13 0-21.5 8.5T453-656v193q0 13 8.5 21.5T483-433Z"
		/>
	</svg>
);
