import { ReactNode } from 'react';

export const symbol_playlist_remove: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m684-194-92 93q-9 9-21 9t-21-9q-9-9-9-21.5t9-21.5l92-92-93-92q-9-9-9-21t9-21q9-9 21.5-9t21.5 9l92 92 92-93q9-9 21-9t21 9q9 9 9 21.5t-9 21.5l-92 92 93 92q9 9 9 21t-9 21q-9 9-21.5 9t-21.5-9l-92-92ZM150-330q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-390h240q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T390-330H150Zm0-165q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-555h410q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T560-495H150Zm0-165q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-720h410q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T560-660H150Z"
		/>
	</svg>
);
