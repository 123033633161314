import { ReactNode } from 'react';

export const symbol_wifi_password: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M754 0q-17 0-28.5-11.5T714-40v-118q0-17 11.5-28.5T754-198h1v-39q0-35 23.5-58.5T837-319q35 0 58.5 23.5T919-237v39h1q17 0 28.5 11.5T960-158v118q0 17-11.5 28.5T920 0H754Zm41-198h84v-39q0-19-11.5-30.5T837-279q-19 0-30.5 11.5T795-237v39Zm-315 71q-36 0-63-27t-27-63q0-36 27-63t63-27q36 0 63 27t27 63q0 36-27 63t-63 27Zm0-433q78 0 146 27.5T757-451q14 12 15 30.5T759-388q-14 14-32 13t-33-13q-53-44-106.5-63T480-470q-54 0-107.5 19T266-388q-15 12-33 13t-32-13q-14-14-13-32.5t15-30.5q63-54 131-81.5T480-560Zm0-240q128 0 241.5 48.5T926-622q14 13 15.5 31.5T929-558q-14 14-33.5 13.5T861-558q-83-70-178-111t-203-41q-108 0-203 41T99-558q-15 13-34 13.5T32-558q-14-14-13-32.5T34-622q91-81 204.5-129.5T480-800Z"
		/>
	</svg>
);
