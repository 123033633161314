import { ChangeEvent, Fragment, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import { ScheduleUpdatePayload, slot } from 'pkg/api/models/schedule';
import uuid from 'pkg/uuid';
import useMixedState from 'pkg/hooks/useMixedState';

import { getLatestEndTime, getWeekDaysOrder } from 'routes/scheduling/utils';

import { Divider } from 'components/Card';
import Icon from 'components/icon';
import * as StepModal from 'components/step-modal';

import * as Input from 'components/form/inputs';
import Column from 'components/layout/column';
import Row from 'components/layout/row';
import Form from 'components/form/Form';

import Button from 'design/button';

const AddSlotWrapper = styled.div`
	text-align: right;
	color: var(--palette-blue-500);
	margin-top: auto;
`;

const RemoveSlot = styled(Icon)`
	color: var(--palette-blue-500);
`;

interface TimeInputsProps {
	slotKey: string;

	handleSlotChange: (slotKey: string, from?: string, to?: string) => void;

	slots: { [key: string]: slot };
}

const TimeInputs = ({ slots, slotKey, handleSlotChange }: TimeInputsProps) => {
	return (
		<Fragment>
			<Input.Field
				name={`start${slotKey}`}
				type="time"
				defaultValue={slots[slotKey][0]}
				onChange={(e: ChangeEvent<HTMLInputElement>) =>
					handleSlotChange(slotKey, e.target.value)
				}
				required
			/>
			<span>-</span>
			<Input.Field
				name={`end${slotKey}`}
				type="time"
				defaultValue={slots[slotKey][1]}
				onChange={(e: ChangeEvent<HTMLInputElement>) =>
					handleSlotChange(slotKey, null, e.target.value)
				}
				required
			/>
		</Fragment>
	);
};

const WeekDay = ({
	day,
	dayIndex,
	slots,
	addDaySlot,
	handleSlotChange,
	removeSlot,
}: {
	day: string;
	dayIndex: number;

	handleSlotChange: (slotKey: string, from?: string, to?: string) => void;
	addDaySlot: (dayNumber: number) => void;
	removeSlot: (slotKey: string) => void;

	slots: models.schedule.SlotConfig;
}) => {
	const slotTimes = Object.keys(slots.days[dayIndex]);

	const handleAddSlot = () => addDaySlot(dayIndex);

	return (
		<Fragment>
			<Row columns="100px auto 1fr" spacing={styles.spacing._8}>
				<span>{day}</span>
				<Column>
					{slotTimes.map((slotKey) => (
						<Row align="center" key={slotKey}>
							<Row align="center" autoColumns="max-content">
								<TimeInputs
									slots={slots.days[dayIndex]}
									slotKey={slotKey}
									handleSlotChange={handleSlotChange}
								/>
								<RemoveSlot
									name="delete"
									onClick={() => removeSlot(slotKey)}
									size={1.6}
								/>
							</Row>
						</Row>
					))}
				</Column>
				<AddSlotWrapper>
					<Icon name="add" size={1.6} onClick={handleAddSlot} />
				</AddSlotWrapper>
			</Row>
		</Fragment>
	);
};

interface SlotConfigFieldsProps {
	addSlot: () => void;
	addDaySlot: (dayIndex: number) => void;
	removeSlot: (slotKey: string) => void;
	handleSlotChange: (slotKey: string, from?: string, to?: string) => void;
	handleSlotsToggle: () => void;

	slots: models.schedule.SlotConfig;
	schedule: models.schedule.Schedule;
}

const SlotConfigFields = ({
	addSlot,
	addDaySlot,
	handleSlotChange,
	handleSlotsToggle,
	removeSlot,
	slots,
	schedule,
}: SlotConfigFieldsProps) => {
	const slotTimes = Object.keys(slots.times);
	const weekDays = getWeekDaysOrder(schedule.startingDay, schedule.days, true);

	const content = slots.sameSlotsAllDays ? (
		<Fragment>
			{slotTimes.map((slotKey, index, arr) => {
				return (
					<Row align="center" key={slotKey}>
						<Row key={slotKey} align="center" autoColumns="max-content">
							<TimeInputs
								slots={slots.times}
								slotKey={slotKey}
								handleSlotChange={handleSlotChange}
							/>
							<RemoveSlot
								name="delete"
								onClick={() => removeSlot(slotKey)}
								size={1.6}
							/>
						</Row>
						<AddSlotWrapper>
							{arr.length - 1 === index && (
								<Icon name="add" size={1.6} onClick={addSlot} />
							)}
						</AddSlotWrapper>
					</Row>
				);
			})}
			{slotTimes.length === 0 && (
				<Button label={t(`Add slot`)} icon="add" onClick={addSlot} secondary />
			)}
		</Fragment>
	) : (
		<Column spacing={styles.spacing._6}>
			{weekDays.map((day, index, arr) => (
				<Fragment key={index}>
					<WeekDay
						day={day}
						dayIndex={index}
						slots={slots}
						addDaySlot={addDaySlot}
						handleSlotChange={handleSlotChange}
						removeSlot={removeSlot}
					/>
					{index < arr.length - 1 && <Divider />}
				</Fragment>
			))}
		</Column>
	);

	return (
		<Column spacing={styles.spacing._8}>
			<Input.Control
				type="checkbox"
				label={t(`Same slots for all days`)}
				defaultChecked={slots.sameSlotsAllDays}
				onChange={handleSlotsToggle}
			/>
			<Column>{content}</Column>
		</Column>
	);
};

interface SlotConfigModalProps {
	hideModal: () => void;
	refreshSchedule: () => Promise<void>;

	schedule: models.schedule.Schedule;
}

const SlotConfigModal = ({
	schedule,
	hideModal,
	refreshSchedule,
}: SlotConfigModalProps) => {
	const ref = useRef(null);

	const handleNext = async () =>
		ref.current.dispatchEvent(
			new Event('submit', {
				cancelable: true,
				bubbles: true,
			})
		);

	const parsed = useMemo(() => {
		const slots = models.schedule.parseSlots(schedule);

		return slots;
	}, []);

	const [slots, setSlots] = useMixedState<models.schedule.SlotConfig>(parsed);

	const handleSave = async () => {
		const payload: ScheduleUpdatePayload = {
			slots: JSON.stringify(slots),
		};
		const [ok] = await models.schedule.update(schedule, payload);
		if (ok) {
			refreshSchedule();
			hideModal();
		}
	};

	const handleSlotChange = (slotKey: string, from?: string, to?: string) => {
		if (slots.sameSlotsAllDays) {
			const times = { ...slots.times };

			if (from !== null) {
				times[slotKey][0] = from;
			} else if (to !== null) {
				times[slotKey][1] = to;
			}

			setSlots({
				times,
			});
		} else {
			const days = [...slots.days];

			// Check for empty keys in array (e.g. skips in selected days)
			const dayIndex = days.findIndex((day) =>
				Object.keys(day).includes(slotKey)
			);
			const times = { ...days[dayIndex] };

			if (from !== null) {
				times[slotKey][0] = from;
			} else if (to !== null) {
				times[slotKey][1] = to;
			}

			setSlots({
				days,
			});
		}
	};

	const handleSlotsToggle = () => {
		setSlots({
			sameSlotsAllDays: !slots.sameSlotsAllDays,
		});
	};

	const addSlot = () => {
		const times = { ...slots.times };
		const latestEndTime =
			Object.keys(times).length > 0
				? getLatestEndTime(times)
				: (['12:00', '13:00'] as slot);

		times[uuid()] = latestEndTime;
		setSlots({
			times,
		});
	};

	const addDaySlot = (dayIndex: number) => {
		const days = [...slots.days];

		const times = { ...days[dayIndex] };
		const latestEndTime =
			Object.keys(times).length > 0
				? getLatestEndTime(times)
				: (['12:00', '13:00'] as slot);

		times[uuid()] = latestEndTime;
		days[dayIndex] = times;
		setSlots({
			days,
		});
	};

	const removeSlot = (slotKey: string) => {
		if (slots.sameSlotsAllDays) {
			const times = { ...slots.times };
			delete times[slotKey];

			setSlots({
				times,
			});
		} else {
			const days = [...slots.days];

			const dayIndex = days.findIndex((day) =>
				Object.keys(day).includes(slotKey)
			);

			delete days[dayIndex][slotKey];

			setSlots({
				days,
			});
		}
	};

	return (
		<StepModal.Base onClose={hideModal}>
			<StepModal.Step
				nextLabel={t('Save')}
				onNext={handleNext}
				title={t(`Configure time slots`)}>
				<Form formRef={ref} onSubmit={handleSave}>
					<SlotConfigFields
						schedule={schedule}
						slots={slots}
						addSlot={addSlot}
						addDaySlot={addDaySlot}
						handleSlotChange={handleSlotChange}
						handleSlotsToggle={handleSlotsToggle}
						removeSlot={removeSlot}
					/>
				</Form>
			</StepModal.Step>
		</StepModal.Base>
	);
};

export default SlotConfigModal;
