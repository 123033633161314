// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HoVQAuovbgoxBY3ldqPC {
	max-width: 470px;
}

.jprZG5YCDlQ1m3z8Gjx9 {
	max-width: 209px;
}
`, "",{"version":3,"sources":["webpack://./routes/payments/prices/forms/create/styles.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;AACjB;;AAEA;CACC,gBAAgB;AACjB","sourcesContent":[".inputWrapper {\n\tmax-width: 470px;\n}\n\n.priceWrapper {\n\tmax-width: 209px;\n}\n"],"sourceRoot":""}]);
// Exports
export var inputWrapper = `HoVQAuovbgoxBY3ldqPC`;
export var priceWrapper = `jprZG5YCDlQ1m3z8Gjx9`;
export default ___CSS_LOADER_EXPORT___;
