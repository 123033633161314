import { ChangeEvent } from 'react';
import { AnimatePresence } from 'framer-motion';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import Button from 'routes/public/styles/Button';
import Field from 'routes/public/styles/inputs/field';
import Form from 'routes/public/styles/forms';

import Column from 'components/layout/column';
import InfoBox from 'components/form/info-box';

export interface AccountDetailsPayload {
	email?: string;
	emailConfirmation?: string;
	password?: string;
	passwordConfirmation?: string;
}

interface AccountDetailsFormProps {
	handleSave: () => void;
	payload: AccountDetailsPayload;
	setPayload: any;
	error?: { title: string; message: string };
	canContinue: boolean;
	isSaving: boolean;
	isWard?: boolean;
}

export default function AccountDetailsForm({
	handleSave,
	payload,
	setPayload,
	error,
	canContinue,
	isSaving,
	isWard = false,
}: AccountDetailsFormProps) {
	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		const nextPayload = { [event.target.name]: event.target.value };

		const isConfirmation = event.target.name.endsWith('Confirmation');
		const hasConfirmation =
			payload[
				`${event.target.name}Confirmation` as keyof AccountDetailsPayload
			] !== '';

		// Empty confirmation field if connected field changes and confirmation is set
		if (!isConfirmation && hasConfirmation) {
			nextPayload[`${event.target.name}Confirmation`] = '';
		}

		setPayload(nextPayload);
	};

	const placeholders = isWard
		? {
				email: t(`Your child's email`),
				emailConfirmation: t('Confirm email'),
				passowrd: t(`Your child's password`),
				passwordConfirmation: t('Confirm password'),
			}
		: {
				email: t('Enter your email'),
				emailConfirmation: t('Confirm email'),
				passowrd: t('Enter a password'),
				passwordConfirmation: t('Confirm password'),
			};

	return (
		<Form onSubmit={handleSave}>
			<Column spacing={styles.spacing._7}>
				<AnimatePresence>
					{error?.title && (
						<InfoBox color="red">
							{error.title && <strong>{error.title}</strong>}
							{error?.message && <span>{error.message}</span>}
						</InfoBox>
					)}
				</AnimatePresence>

				<Column>
					<Field
						required
						type="email"
						name="email"
						placeholder={placeholders.email}
						errorMessage={t('Please enter a valid email')}
						value={payload.email}
						onChange={handleInputChange}
						tabIndex={1}
						inputMode="email"
					/>
					<Field
						required
						type="email"
						name="emailConfirmation"
						placeholder={placeholders.emailConfirmation}
						errorMessage={t('Emails do not match')}
						pattern={payload.email?.replace('+', '\\+')}
						value={payload.emailConfirmation}
						onChange={handleInputChange}
						tabIndex={2}
						inputMode="email"
					/>
					<Field
						required
						name="password"
						type="password"
						placeholder={placeholders.passowrd}
						minLength={8}
						value={payload.password}
						onChange={handleInputChange}
						tabIndex={3}
					/>
					<Field
						required
						name="passwordConfirmation"
						type="password"
						placeholder={placeholders.passwordConfirmation}
						errorMessage={t('Passwords do not match')}
						minLength={8}
						validateOnChange
						data-must-match={payload.password}
						value={payload.passwordConfirmation}
						onChange={handleInputChange}
						tabIndex={4}
					/>
				</Column>
				<Button
					primary
					busy={isSaving}
					disabled={!canContinue}
					label={isWard ? t('Create child account') : t('Create account')}
					type="submit"
				/>
			</Column>
		</Form>
	);
}
