import { ReactNode } from 'react';

export const symbol_turn_sharp_left: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M650-150v-220H310q-24.75 0-42.375-17.625T250-430v-296l-69 69q-9 9-21 9t-21-9q-9-9-9-21t9-21l120-120q9-9 21-9t21 9l120 120q9 9 9 21t-9 21q-9 9-21 9t-21-9l-69-69v296h340q24.75 0 42.375 17.625T710-370v220q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T650-150Z"
		/>
	</svg>
);
