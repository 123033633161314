/* @dependencies */
import { Map, List, Record, fromJS } from 'immutable';

/* @imports */
import Video from 'pkg/models/video';

import {
	ADD_VIDEO,
	ADD_VIDEOS,
	UPDATE_VIDEO,
	REMOVE_VIDEO,
} from 'pkg/actions/action-types';

const initialState = new Record(
	{
		entities: new Map(),
		result: new List([]),
		links: new Map({
			prev: null,
			next: null,
		}),
	},
	'videos'
);

const addVideosReducer = (state, action) => {
	let entities = action.payload.entities.videos;

	if (!entities) return state;

	entities = fromJS(entities).map((e) => new Video(e));

	return state
		.mergeIn(['entities'], entities)
		.setIn(['links'], new Map(action.links));
};

const updateVideoReducer = (state, action) => {
	const videoId = action.videoId?.toString();
	const { payload } = action;

	return state.updateIn(['entities', videoId], (video) => {
		const entity = fromJS(payload.entities.videos[videoId]);

		return video.merge(new Video(entity));
	});
};

const removeVideoReducer = (state, action) => {
	const videoId = action.videoId?.toString();

	return state.deleteIn(['entities', videoId]);
};

const matchVideos = (state = initialState(), action = {}) => {
	switch (action.type) {
		case ADD_VIDEO:
		case ADD_VIDEOS:
			return addVideosReducer(state, action);

		case UPDATE_VIDEO:
			return updateVideoReducer(state, action);

		case REMOVE_VIDEO:
			return removeVideoReducer(state, action);

		default:
			return state;
	}
};

export default matchVideos;
