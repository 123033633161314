import { t } from '@transifex/native';

import {
	useLocalizedCurrencyFormatter,
	useOrderReport,
} from 'routes/payments/overview/hooks';
import { DateSpanLabel } from 'routes/payments/overview/widgets';

import * as DataCard from 'design/data_card';

export default function PaymentTotalWidget(): JSX.Element {
	const { totalPayments } = useOrderReport();
	const { ordersPaid, paidAmount } = totalPayments;

	const fmt = useLocalizedCurrencyFormatter();

	return (
		<DataCard.WithLargeIcon
			icon="nav-payments"
			title={t('Payments')}
			footer={<DateSpanLabel />}
			noFooterDivider
			heading={ordersPaid.toString()}
			headingSuffix={fmt(paidAmount)}
		/>
	);
}
