import { t } from '@transifex/native';
import { ChangeEvent, useState } from 'react';

import spacing from 'pkg/config/spacing';

import * as actions from 'pkg/actions';
import Link from 'pkg/router/Link';

import ThreeSixtyLogo from 'routes/public/styles/logo';
import Title from 'routes/public/styles/Title';
import Button from 'routes/public/styles/Button';
import Form from 'routes/public/styles/forms';
import Field from 'routes/public/styles/inputs/field';
import FooterNote from 'routes/public/styles/FooterNote';
import { useOnboardingState } from 'routes/public/hooks';
import { useOnboardingContext } from 'routes/public/onboarding';

import Column from 'components/layout/column';

export default function SSOSlug() {
	const onboardingState = useOnboardingState();
	const onboardingContext = useOnboardingContext();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [slug, setSlug] = useState<string>('');

	const handleSubmit = async () => {
		setIsLoading(true);

		const [ok, resp] = await actions.auth.fetchAuthOptions(slug);

		if (!ok) {
			setIsLoading(false);

			return;
		}

		onboardingState.set({
			group: {
				organizationId: resp.organizationId,
				groupName: resp.organizationName,
				organizationName: resp.organizationName,
				isOrganization: true,
				profileImageUrl: resp.profileImageUrl,
				primaryColor: resp.primaryColor,
				country: null,
			},
			identityProviders: resp.identityProviders,
		});

		onboardingContext.goTo('sso-login');
	};

	const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
		setSlug(event.target.value);

	return (
		<Column spacing={spacing._8}>
			<ThreeSixtyLogo />

			<Column spacing={spacing._8}>
				<Title title={t(`Enter organization's slug`)} center />

				<Form onSubmit={handleSubmit}>
					<Column spacing={spacing._4}>
						<Field
							required
							type="text"
							name="url"
							placeholder="app.360player.com/"
							value={slug}
							onChange={handleChange}
							tabIndex={1}
							autoFocus
						/>
						<Button
							primary
							type="submit"
							label={t('Continue')}
							busy={isLoading}
						/>
					</Column>
				</Form>
				<Column>
					<FooterNote>
						<Link href="https://help.360player.com/article/213-login-using-sso">
							{t(`I don't have a slug`)}
						</Link>
					</FooterNote>
				</Column>
			</Column>
		</Column>
	);
}
