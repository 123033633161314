import {
	Children,
	cloneElement,
	useRef,
	useEffect,
	useState,
	useMemo,
	ReactNode,
} from 'react';

import * as css from './styles.css';

interface EventContentProps {
	children: ReactNode[];
}

export default function EventContent({ children }: EventContentProps) {
	const contentRef = useRef(null);
	const [clipContent, setClipContent] = useState(false);
	const amountOfChildren = Children.count(children);
	const spanHeight = 13;

	useEffect(() => {
		if (
			amountOfChildren * spanHeight >
			contentRef.current.getBoundingClientRect().height
		) {
			setClipContent(true);
		} else if (
			amountOfChildren * spanHeight <
			contentRef.current.getBoundingClientRect().height
		) {
			setClipContent(false);
		}
	}, [amountOfChildren, spanHeight]);

	let items = children;

	items = useMemo(() => {
		if (clipContent) {
			const amountToShow = Math.floor(
				contentRef.current.getBoundingClientRect().height / spanHeight
			);

			const visibileItems = [];
			if (amountToShow === 0) {
				const singleItem: any = Children.toArray(items)[0];

				visibileItems.push(
					cloneElement(singleItem, { smallFont: true }, [singleItem.children])
				);
			} else {
				for (const [i, child] of Children.toArray(items).entries()) {
					if (i < amountToShow) {
						visibileItems.push(child);
					} else {
						break;
					}
				}
			}
			return visibileItems;
		}
		return items;
	}, [clipContent, items, spanHeight]);

	return (
		<div className={css.contentWrapper} ref={contentRef}>
			{items}
		</div>
	);
}
