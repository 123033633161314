import { Fragment, ReactNode } from 'react';

import { useEndpoint } from 'pkg/api/use_endpoint';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import Link from 'pkg/router/Link';
import * as routes from 'pkg/router/routes';

import MaterialSymbol from 'components/material-symbols';
import Badge from 'components/Badge';

import { Spinner } from 'components/loaders/spinner';
import Row from 'components/layout/row';
import Column from 'components/layout/column';

import * as embedCss from '../styles.css';
import * as css from './styles.css';

interface FormEmbedProps {
	guid: string;
	fallback: ReactNode;
}

interface ShowFormResponse {
	form: models.form.Form;
	paymentProviderSettings: models.providerSettings.ProviderSettings;
}

export default function FormEmbed({
	guid,
	fallback,
}: FormEmbedProps): JSX.Element {
	const {
		record: payload,
		response,
		hasError,
	} = useEndpoint<ShowFormResponse>(endpoints.Forms.PublicShow(guid));

	let content = <Spinner />;

	if (hasError || (response?.ok && !payload?.form?.group)) {
		return <Fragment>{fallback}</Fragment>;
	}

	if (response?.ok) {
		content = (
			<Link
				href={routes.Registration.Index(
					payload.form.group.slug,
					payload.form.guid
				)}
				className={css.wrapper}>
				<Row columns="40px 1fr auto" align="center">
					<div
						className={css.badge}
						style={{
							backgroundColor: models.group.getPrimaryColorStylesheetString(
								payload.form.group
							),
						}}>
						<Badge badgeUrl={payload.form.group.profileImageUrl} />
					</div>
					<div>
						<Column className={css.column}>
							<strong className={css.title}>{payload.form.title}</strong>
							<span className={css.description}>
								{payload.form.description}
							</span>
						</Column>
					</div>
					<MaterialSymbol variant="chevron_right" actualSize scale={2} />
				</Row>
			</Link>
		);
	}

	return <div className={embedCss.wrapper}>{content}</div>;
}
