import { Map, Record } from 'immutable';

import EventUser from 'pkg/models/event_user';

import * as actionTypes from 'pkg/actions/action-types';

const initalState = new Record(
	{
		selectedUsers: new Map(),

		// users holds the current events users
		users: new Map(),
	},
	'event_users'
);

const eventUsers = (state = initalState(), action = {}) => {
	switch (action.type) {
		case actionTypes.EVENT_SET_USERS_SUCCESS: {
			let s = state;
			Object.values(action.payload.entities.eventUsers).forEach((eventUser) => {
				s = s.setIn(['users', eventUser.id], new EventUser(eventUser));
			});

			return s;
		}

		case actionTypes.EVENT_REMOVE_EVENT_USER: {
			let s = state;
			action.userIds.forEach((userId) => {
				const item = state.users.find(
					(eventUser) =>
						eventUser.eventId === action.eventId && eventUser.userId === userId
				);

				s = s.removeIn(['users', item.id]);
			});

			return s;
		}

		default:
			return state;
	}
};

export default eventUsers;
