import { ReactNode } from 'react';

export const symbol_forward_circle: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-290q72 0 124.5-46.5T668-452q2-8-4-13t-14-5q-8 0-14 4t-8 12q-9 54-51 89t-97 35q-63 0-106.5-43.5T330-480q0-63 43.5-106.5T480-630h6l-45 46q-6 6-6 14t6 14q6 6 14.5 6t14.5-6l80-80q5-5 5-11t-5-11l-81-81q-6-6-14-6t-14 6q-6 6-6 14.5t6 14.5l39 40q-79 2-134.5 57T290-480q0 79 55.5 134.5T480-290Zm0 210q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm0-60q142 0 241-99.5T820-480q0-142-99-241t-241-99q-141 0-240.5 99T140-480q0 141 99.5 240.5T480-140Zm0-340Z"
		/>
	</svg>
);
