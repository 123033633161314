import { ReactNode } from 'react';

export const symbol_solar_power: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M150-820q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-880h60q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T210-820h-60Zm26 570-22 110h296v-110H176Zm37-414 42-42q9-9 21.158-9 12.158 0 21 8.609Q306-697 306-684.533q0 12.466-9 21.533l-42 42q-9 9-21.158 9-12.158 0-21-8.609Q204-630 204-642.467q0-12.466 9-21.533Zm-25 354h262v-110H210l-22 110Zm292-390q-74 0-125-52.5T300-880h60q3 50 37.125 85T480-760q48.75 0 82.875-35T600-880h60q-4 75-55 127.5T480-700Zm0-180Zm-30 330v-60q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-610v60q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T450-550Zm60 410h296l-22-110H510v110Zm0-170h262l-22-110H510v110Zm195-313-43-42q-9-9-9-21t9-21q9-9 21-8.5t21 8.5l43 42q9 9 9.5 21t-8.553 21q-9.052 9-21.5 9Q714-614 705-623Zm105-197h-90v-60h90q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-820ZM94-152l56-280q5-21 21-34.5t38-13.5h542q22 0 38 13.5t21 34.5l56 280q5 28-12.5 50T807-80H153q-29 0-46.5-22T94-152Z"
		/>
	</svg>
);
