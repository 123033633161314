import { ReactNode } from 'react';

export const symbol_move: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M440-280q-7 0-12-4t-7-10q-14-42-34-70t-40-54q-20-26-33.5-54T300-540q0-58 41-99t99-41q58 0 99 41t41 99q0 40-13.5 68T533-418q-20 26-40 54t-34 70q-2 6-7 10t-12 4Zm0-112q9-14 18-26t17-23q23-30 34-50t11-49q0-33-23.5-56.5T440-620q-33 0-56.5 23.5T360-540q0 29 11 49t34 50q8 11 17 23t18 26Zm0-98q21 0 35.5-14.5T490-540q0-21-14.5-35.5T440-590q-21 0-35.5 14.5T390-540q0 21 14.5 35.5T440-490Zm0-50Zm3 424q-152 0-258-106T79-480q0-76 28.5-142t78-115.5Q235-787 301-815.5T443-844q76 0 142 28.5t115.5 78Q750-688 778.5-622T807-480v18l49-49q9-9 21-9t21 9q9 9 9 21t-9 21L798-369q-9 9-21 9t-21-9L656-469q-9-9-9-21t9-21q9-9 21-9t21 9l49 49v-18q0-125-89.5-214.5T443-784q-125 0-214.5 89.5T139-480q0 125 89.5 214.5T443-176q49 0 96-16t85-45q11-8 22.5-7.5T667-235q9 9 9 21.5T666-193q-46 35-104.5 56T443-116Z"
		/>
	</svg>
);
