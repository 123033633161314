import { ReactNode } from 'react';

export const symbol_movie_edit: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-220v-368 368Zm0 60q-24 0-42-18t-18-42v-520q0-24 18-42t42-18l58 119q7.729 15.4 22.083 24.2Q234.438-648 251-648q32.5 0 49.25-27.462Q317-702.923 303-732l-33-68h89l58 119q7.729 15.4 22.083 24.2Q453.438-648 470-648q32.5 0 49.25-27.462Q536-702.923 522-732l-33-68h89l58 119q7.729 15.4 22.083 24.2Q672.438-648 689-648q32.5 0 49.25-27.462Q755-702.923 741-732l-33-68h112q24 0 42 18t18 42v170q0 12.75-8.625 21.375T850-540h-30v-48H140v368h330q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T470-160H140Zm420 10v-81q0-5.565 2-10.783Q564-247 569-252l211.612-210.773q9.113-9.12 20.25-13.174Q812-480 823-480q12 0 23 4.5t20 13.5l37 37q9 9 13 20t4 22q0 11-4.5 22.5t-13.583 20.624L692-129q-5 5-10.217 7-5.218 2-10.783 2h-81q-12.75 0-21.375-8.625T560-150Zm300-233-37-37 37 37ZM620-180h38l121-122-18-19-19-18-122 121v38Zm141-141-19-18 37 37-18-19Z"
		/>
	</svg>
);
