import { ReactNode } from 'react';

export const symbol_speed_2x: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M405-277H265q-24.75 0-42.375-17.625T205-337v-115q0-24 18-42t42-18h110v-111H235q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T235-683h140q24 0 42 18t18 42v111q0 24-18 42t-42 18H265v115h140q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T405-277Zm235-157-77 139q-4.286 7.059-10.714 11.029Q545.857-280 538.143-280 521-280 513-294t0-28l91-164-85-152q-8-14-.5-28t24.643-14q7.714 0 14.143 3.971Q563.714-672.059 568-665l72 128 71-128q4.286-7.059 10.714-11.029Q728.143-680 735.857-680 753-680 761-666t0 28l-85 152 92 165q8 14 0 27.5T742.759-280Q735-280 729-283.706T719-294l-79-140Z"
		/>
	</svg>
);
