import { ReactNode } from 'react';

export const symbol_event_upcoming: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M780-80H596q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T596-140h184v-430H180v190q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T120-380v-380q0-24 18-42t42-18h65v-28q0-13.6 9.2-22.8 9.2-9.2 22.8-9.2 14.025 0 23.513 9.2Q310-861.6 310-848v28h340v-28q0-13.6 9.2-22.8 9.2-9.2 22.8-9.2 14.025 0 23.513 9.2Q715-861.6 715-848v28h65q24 0 42 18t18 42v620q0 24-18 42t-42 18Zm-389-90H80q-12.75 0-21.375-8.675Q50-187.351 50-200.175 50-213 58.625-221.5T80-230h311l-92-93q-8-9-8.5-21.1-.5-12.1 8.5-20.9 9-9 21-9t21 9l144 144q9 9 9 21t-9 21L341-35q-9 9-21 9t-21-9q-9-9-9-21t9-21l92-93ZM180-630h600v-130H180v130Zm0 0v-130 130Z"
		/>
	</svg>
);
