import { ReactNode } from 'react';

export const symbol_readiness_score: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M295-119q-36-1-68.5-18.5T165-189q-40-48-62.5-114.5T80-440q0-83 31.5-156T197-723q54-54 127-85.5T480-840q83 0 156 31.5T763-722q54 55 85.5 129T880-434q0 75-24 142t-69 113q-29 30-60.5 45T662-119q-18 0-36-4.5T590-137l-56-28q-13-7-26.5-10t-27.5-3q-14 0-27.5 3T426-165l-56 28q-19 10-37.5 14.5T295-119Zm3-60q11 0 22.5-3t22.5-8l56-28q19-9 39-14t41-5q21 0 41 4.5t40 14.5l57 28q11 5 22 8t22 3q23 0 44.5-11t41.5-33q35-38 54-93.5T820-434q0-146-98.5-246T480-780q-143 0-241.5 101T140-432q0 63 19 117.5t54 92.5q20 22 41 32.5t44 10.5Zm182-301Zm0 110q29 0 49.5-20.5T550-440q0-8-1.5-16t-5.5-15l64-82q12 14 21 29.5t14 33.5q3 11 11.5 19.5T673-462q16 0 24-13.5t3-29.5q-23-73-83.5-119T480-670q-77 0-137.5 45.5T260-505q-5 16 3.5 29.5T287-462q11 0 19-8.5t12-19.5q17-54 61.5-87T480-610q21 0 41 5t39 15l-64 82q-4-1-8-1.5t-8-.5q-29 0-49.5 20.5T410-440q0 29 20.5 49.5T480-370Z"
		/>
	</svg>
);
