export function Create(userID: number): string {
	return `/v1/users/${userID}/goals`;
}

export function Delete(goalID: number): string {
	return `/v1/user-goals/${goalID}`;
}

export function Update(goalID: number): string {
	return `/v1/user-goals/${goalID}`;
}

export function UserIndex(userID: number): string {
	return `/v1/users/${userID}/goals`;
}
