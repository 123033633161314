import { ReactNode } from 'react';

export const symbol_videogame_asset_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M394-480Zm-107 33h-74q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T213-507h74v-74q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T347-581v74h74q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T421-447h-74v74q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T287-373v-74ZM140-240q-24 0-42-18t-18-42v-360q0-24 18-42t42-18h100l60 60H140v360h434L82-792q-9-9.067-9-21.533Q73-826 82.053-835q9.052-9 21.5-9Q116-844 125-835l710 710q9 9 9 21t-9.053 21q-9.052 9-21.5 9Q801-74 792-83L634-240H140Zm543-267q-18 0-31.5-13.5T638-552q0-18 13.5-31.5T683-597q18 0 31.5 13.5T728-552q0 18-13.5 31.5T683-507Zm137-213q24 0 42 18t18 42v338q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T820-322v-338H402q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T402-720h418ZM603-450Z"
		/>
	</svg>
);
