import { Fragment } from 'react';
import styled from 'styled-components';

import * as styles from 'pkg/config/styles';

import { msToSeconds } from 'pkg/date';
import rgba from 'pkg/rgba';

import { useTimelineOffset } from 'routes/video/shared/hooks/timeline';

import { usePlayerState } from 'components/video-analytics/PlayerState';
import { useClipState } from 'components/video-analytics/ClipState';
import { useCueState } from 'components/video-analytics/CueState';
import { IS_EDITING, IS_OUTSIDE_CLIP } from 'components/annotations/constants';
import { Keyframe } from 'components/annotations/AnnotationHooks';

const KeyframeWrapper = styled.div`
	position: absolute;
	top: 0;
	color: var(--palette-orange-500);

	&:hover,
	&.is-active {
		color: var(--palette-orange-200);
	}

	&.${IS_OUTSIDE_CLIP} {
		color: var(--palette-red-500);
	}

	.${IS_EDITING} & {
		cursor: pointer;
	}
`;

const Marker = styled.div`
	position: absolute;
	height: 10px;
	width: 10px;
	border-radius: var(--radius-3);
	background: currentColor;
	left: 0;
	top: 0;
	transform: translateX(-50%);
	z-index: 1;

	&:last-child {
		left: 100%;
	}

	&::after {
		position: absolute;
		top: 95%;
		border-width: 5px 5px 0 5px;
		border-color: currentColor transparent transparent transparent;
		border-style: solid;
		content: '';
	}
`;

const Duration = styled.div`
	position: absolute;
	height: 15px;
	background: ${rgba(styles.palette.orange[500], 0.3)};
	top: 0;
	width: 100%;
	color: var(--palette-orange-500);
	font-size: var(--font-size-xs);
	padding: 1px 0 0 var(--spacing-3);
	overflow: hidden;
`;

interface PlaybackKeyframesProps {
	duration: number;
}

export default function PlaybackKeyframes({
	duration,
}: PlaybackKeyframesProps) {
	const clipState = useClipState();
	const annotations = clipState.getParsedAnnotations();

	return (
		<Fragment>
			{annotations?.playback?.keyframes?.map((keyframe, index) => (
				<PlaybackKeyframe
					index={index}
					key={keyframe.time}
					keyframe={keyframe}
					duration={duration}
				/>
			))}
		</Fragment>
	);
}

interface PlaybackKeyframeProps {
	index: number;
	keyframe: Keyframe;
	duration: number;
}

const PlaybackKeyframe = ({
	index,
	keyframe,
	duration,
}: PlaybackKeyframeProps) => {
	const clipState = useClipState();
	const { controller, isEditing } = usePlayerState();
	const timelineOffset = useTimelineOffset();

	const { currentCue } = useCueState();

	const setPlaybackKeyframe = (annotationId: string, index: number) => {
		clipState.setActiveAnnotation(null);
		clipState.setActiveKeyframe(index);

		const annotation = clipState.getAnnotation('playback');
		const keyframe = annotation.keyframes[index];

		if (keyframe) {
			controller.seekTo(msToSeconds(keyframe.time));
			timelineOffset.set(msToSeconds(keyframe.time));
		}
	};

	const start = keyframe.time / (duration * 10);
	const end =
		keyframe.action !== 'pause' &&
		keyframe.duration &&
		(keyframe.time + keyframe.duration) / (duration * 10);

	// check if marker is active
	const classes = [];
	if (!clipState.activeAnnotationId && index === clipState.activeKeyframeIndex)
		classes.push('is-active');

	// check if marker is outside clip duration
	const startsAtMs =
		clipState.getStartsAt() * 1000 ||
		currentCue.startsAtMs ||
		currentCue.startsAt * 1000;
	const endsAtMs =
		clipState.getEndsAt() * 1000 ||
		currentCue.endsAtMs ||
		currentCue.endsAt * 1000;
	const withinClip =
		keyframe.time >= startsAtMs &&
		keyframe.time + ((keyframe.action !== 'pause' && keyframe.duration) || 0) <=
			endsAtMs;
	if (!withinClip) classes.push(IS_OUTSIDE_CLIP);

	return (
		<KeyframeWrapper
			key={keyframe.time}
			className={classes.join(' ')}
			onClick={
				isEditing
					? () => {
							setPlaybackKeyframe('playback', index);
						}
					: null
			}
			style={{ left: start + '%', width: end - start + '%' }}>
			<Marker />
			{end && <Duration>{keyframe.speed}&times;</Duration>}
			{end && <Marker />}
		</KeyframeWrapper>
	);
};
