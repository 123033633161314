import { ReactNode } from 'react';

export const symbol_swap_vertical_circle: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M363-629v163q0 13 8.5 21.5T393-436q13 0 21.5-8.5T423-466v-163l63 63q9 9 21.5 9t21.5-9q9-9 9-21.5t-9-21.5L414-724q-9-9-21-9t-21 9L257-609q-9 9-8.5 21.5T258-566q9 9 21.5 9t21.5-9l62-63Zm174 306-62-62q-9-9-21.5-9t-21.5 9q-9 9-9 21.5t9 21.5l114 114q9 9 21 9t21-9l115-113q9-9 9-22t-9-22q-9-9-22-9t-22 9l-62 62v-163q0-13-8.5-21.5T567-516q-13 0-21.5 8.5T537-486v163ZM480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm0-60q142 0 241-99.5T820-480q0-142-99-241t-241-99q-141 0-240.5 99T140-480q0 141 99.5 240.5T480-140Zm0-340Z"
		/>
	</svg>
);
