import { ReactNode } from 'react';

export const symbol_safety_check_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M649-389q11-20 17.5-43.5T673-481q0-79-57-136t-136-57q-25 0-48.5 6.5T388-650l261 261Zm94 94-45-46q20-35 31-89.5t11-93.5v-196l-260-96-188 69-46-46 213-80q11-4 21-4t21 4l260 96q17 7 28 22.5t11 34.5v196q0 59-15 117.5T743-295ZM480-84q-7 0-19-4-140-50-220.5-169T160-524v-183l-84-84q-9-9-9-21.5t9-21.5q9-9 21.5-9t21.5 9l713 713q9 9 8.5 21.5T831-78q-9 9-21 9t-21-9L670-195q-38 38-78.5 64.5T500-87q-5 2-10 2.5t-10 .5Zm19-457Zm-75 98Zm56 300q42-14 80.5-39.5T628-238l-68-68q-19 9-39 13.5t-41 4.5q-79 0-136-57t-57-136q0-22 4.5-42t13.5-39l-85-85v123q0 130 73 236.5T480-143Z"
		/>
	</svg>
);
