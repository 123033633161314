const newWindow = {
	name: 'new-window',
	objects: [
		{
			type: 'path',
			attributes: {
				d: 'M6.23915 17.8117V9.38859H11.5209V8.08859H5.93915C5.38686 8.08859 4.93915 8.5363 4.93915 9.08859V18.1117C4.93915 18.664 5.38686 19.1117 5.93915 19.1117H14.9623C15.5146 19.1117 15.9623 18.664 15.9623 18.1117V12.4229H14.6623V17.8117H6.23915Z',
				fillRule: 'evenodd',
				clipRule: 'evenodd',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M11.2031 5.78366C11.2069 5.40674 11.5156 5.10432 11.8925 5.10819L18.531 5.17639C18.9025 5.1802 19.2027 5.4804 19.2065 5.85185L19.2747 12.4903C19.2786 12.8673 18.9762 13.176 18.5992 13.1798C18.2223 13.1837 17.9136 12.8813 17.9097 12.5044L17.8582 7.48988L10.0723 15.2758C9.80581 15.5423 9.37367 15.5423 9.10713 15.2758C8.84059 15.0092 8.84059 14.5771 9.10713 14.3105L16.893 6.52466L11.8785 6.47315C11.5016 6.46927 11.1992 6.16058 11.2031 5.78366Z',
				fillRule: 'evenodd',
				clipRule: 'evenodd',
			},
		},
	],
};
export default newWindow;
