// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jljRwyQbiExVpPfhr2Em {
	font-size: var(--font-size-xl);
	font-weight: var(--font-weight-semibold);
}

.QWJ9ijTR9zsCt9vGK2cC {
	color: var(--palette-gray-500);
	font-size: var(--font-size-sm);
}

.AMqCgYk358uxliyrFouU {
	border: 1px solid var(--palette-gray-300);
	border-top: none;
	margin: var(--spacing-5) 0 var(--spacing-5) 0;
}
`, "",{"version":3,"sources":["webpack://./routes/forms/registration/form/styles.css"],"names":[],"mappings":"AAAA;CACC,8BAA8B;CAC9B,wCAAwC;AACzC;;AAEA;CACC,8BAA8B;CAC9B,8BAA8B;AAC/B;;AAEA;CACC,yCAAyC;CACzC,gBAAgB;CAChB,6CAA6C;AAC9C","sourcesContent":[".sectionTitle {\n\tfont-size: var(--font-size-xl);\n\tfont-weight: var(--font-weight-semibold);\n}\n\n.sectionDescription {\n\tcolor: var(--palette-gray-500);\n\tfont-size: var(--font-size-sm);\n}\n\n.sectionDivider {\n\tborder: 1px solid var(--palette-gray-300);\n\tborder-top: none;\n\tmargin: var(--spacing-5) 0 var(--spacing-5) 0;\n}\n"],"sourceRoot":""}]);
// Exports
export var sectionTitle = `jljRwyQbiExVpPfhr2Em`;
export var sectionDescription = `QWJ9ijTR9zsCt9vGK2cC`;
export var sectionDivider = `AMqCgYk358uxliyrFouU`;
export default ___CSS_LOADER_EXPORT___;
