import { ReactNode } from 'react';

export const symbol_open_in_phone: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M260-40q-24 0-42-18t-18-42v-230q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T260-330v140h440v-580H260v140q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T200-630v-230q0-24 18-42t42-18h440q24 0 42 18t18 42v760q0 24-18 42t-42 18H260Zm0-90v30h440v-30H260Zm179-320H110q-12.75 0-21.375-8.675Q80-467.351 80-480.175 80-493 88.625-501.5T110-510h329l-58-58q-9-9-9-21.1 0-12.1 9-20.9 9-9 21.5-9t21.5 9l110 109q9 9 9 21t-9 21L424-349q-9 9-21 8.5t-21-9.5q-9-9-9-21.5t9-21.5l57-57ZM260-830h440v-30H260v30Zm0 0v-30 30Zm0 700v30-30Z"
		/>
	</svg>
);
