import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setForceNavBarBackLink } from 'pkg/actions/app';

import * as models from 'pkg/api/models';
import { useWebSocketSubscription } from 'pkg/websocket';

import Settings from 'containers/chat/Settings';
import Header from 'containers/chat/Header';

import Thread from 'components/chat/thread';

interface CurrentChatProps {
	targetChat: models.chat.Chat;
	forceBack?: boolean;
	autoFocus?: boolean;
}

export default function CurrentChat({
	targetChat,
	forceBack,
	autoFocus = false,
}: CurrentChatProps) {
	const dispatch = useDispatch();

	useEffect(() => {
		if (forceBack) {
			dispatch(setForceNavBarBackLink('/chat'));
		}

		return () => {
			if (forceBack) {
				dispatch(setForceNavBarBackLink(''));
			}
		};
	}, [forceBack, dispatch]);

	const isNewChat = !targetChat || targetChat.isNew;

	useWebSocketSubscription(`p.chat.${targetChat.id}`, {
		dependencies: [targetChat.id, isNewChat],
		condition: !isNewChat,
	});

	if (!targetChat) {
		return null;
	}

	return (
		<React.Fragment>
			<Header chat={targetChat}>
				{!targetChat.isNew && <Settings chat={targetChat} />}
			</Header>
			<Thread autoFocus={autoFocus} chat={targetChat} />
		</React.Fragment>
	);
}
