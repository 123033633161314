import { t } from '@transifex/native';
import { Component } from 'react';
import styled from 'styled-components';

import * as breakpoints from 'pkg/config/breakpoints';

import { DropdownList as dropdown } from 'components/Form';

const DropdownList = styled(dropdown)`
	margin: 0;
`;

const SelectRow = styled.div`
	display: flex;
	flex-flow: row nowrap;
	align-items: flex-start;
	flex: 1;
	width: 100%;

	@media ${breakpoints.small} {
		flex-flow: column wrap;
	}

	> div {
		flex-grow: 1;
		flex-shrink: 1;
		flex-basis: 0;
		max-width: 100%;
		margin-bottom: 15px;
		margin-left: 15px;

		&:first-child {
			margin-left: 0;
		}

		@media ${breakpoints.small} {
			margin-left: 0;
			width: 100%;
		}
	}
`;

const Label = styled.label`
	display: inline-block;
`;

export default class Options extends Component {
	shouldComponentUpdate(nextProps) {
		if (nextProps.position !== this.props.position) {
			return true;
		}

		return false;
	}

	setPosition = (_, position) => {
		this.props.onOptionChange('position', parseInt(position, 10));
	};

	render() {
		return (
			<SelectRow>
				<div>
					<Label>{t(`Choose position`)}</Label>
					<DropdownList
						name="position"
						onChange={this.setPosition}
						initialOption={this.props.position}
						options={this.props.positionOptions.map((position) => [
							position.id,
							position.longName,
						])}
					/>
				</div>
			</SelectRow>
		);
	}
}
