import { t } from '@transifex/native';

import { EventTypes, eventTypeToTranslatedString } from 'pkg/models/event';

import * as routes from 'pkg/router/routes';
import { pushState } from 'pkg/router/state';
import DateTime from 'pkg/datetime';
import { useCurrentOrganization } from 'pkg/identity';

import Label from 'components/label';

import * as Table from 'design/table';

interface Report {
	eventId: number;
	eventTitle: string;
	eventType: string;
	eventEnd: number;
	eventStart: number;
	userAttendanceStatus: string;
	userComment: string;
	userStatus: string;
}

interface UserReportRow {
	report: Report;
}

export default function UserReportRow({ report }: UserReportRow): JSX.Element {
	const org = useCurrentOrganization();

	const date = new DateTime(
		new Date(report.eventStart * 1000)
	).toLocaleDateString();

	const startTime = new DateTime(
		new Date(report.eventStart * 1000)
	).toLocaleTimeString({ timeStyle: 'short' });

	const endTime = new DateTime(
		new Date(report.eventEnd * 1000)
	).toLocaleTimeString({ timeStyle: 'short' });

	// Default status label to 'gray' (covers 'other' status)
	let attendanceColor = 'gray';

	switch (report.userAttendanceStatus) {
		case 'injured':
		case 'sick':
			attendanceColor = 'red';
			break;

		case 'unset':
			attendanceColor = 'orange';
			break;

		case 'attended':
			attendanceColor = 'green';
			break;
	}

	const handleRowClick = () =>
		pushState(routes.Event.View(org.id, report.eventId, 'overview'));

	// @todo should live on the event user model
	const translatedAttendanceStatus = (status: string): string => {
		switch (status) {
			case 'attended':
				return t('Attended');
			case 'injured':
				return t('Injured');
			case 'other':
				return t('Other');
			case 'sick':
				return t('Illness');
			case 'unset':
				return t('Unhandled');
		}
	};

	return (
		<Table.Row onClick={handleRowClick}>
			<Table.Cell>{report.eventTitle}</Table.Cell>
			<Table.Cell>
				<Label color="gray">
					{eventTypeToTranslatedString(report.eventType as EventTypes)}
				</Label>
			</Table.Cell>
			<Table.Cell align="right">{date}</Table.Cell>
			<Table.Cell align="right">{`${startTime}-${endTime}`}</Table.Cell>
			<Table.Cell>
				<Label color={attendanceColor}>
					{translatedAttendanceStatus(report.userAttendanceStatus)}
				</Label>
			</Table.Cell>
			<Table.Cell>{report.userComment}</Table.Cell>
		</Table.Row>
	);
}
