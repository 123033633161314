import { ReactNode } from 'react';

export const symbol_filter_retrolux: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m579-103-76-76q-8 47-42 73t-82 26q-56 0-92-36t-36-92q0-56 36-92t92-36q20 0 37 4t31 12L253-620q-6-9-5-19t9-18l202-202q5-5 10-7t11-2q6 0 11 2t10 7l202 202q8 8 9 18t-5 19L512-318v60l111 111q9 9 9 22t-9 22q-9 9-22 9t-22-9Zm-200-37q30 0 49-19t19-49q0-30-19-49t-49-19q-30 0-49 19t-19 49q0 30 19 49t49 19Zm101-237 160-257-160-160-160 160 160 257Zm0-209Z"
		/>
	</svg>
);
