const print = {
	name: 'print',
	objects: [
		{
			type: 'path',
			attributes: {
				d: 'M16.7778 6.11792H7.6499V9.16056H16.7778V6.11792ZM17.5385 12.9639C17.3368 12.9639 17.1433 12.8837 17.0006 12.7411C16.858 12.5984 16.7778 12.4049 16.7778 12.2032C16.7778 12.0015 16.858 11.808 17.0006 11.6653C17.1433 11.5227 17.3368 11.4425 17.5385 11.4425C17.7402 11.4425 17.9337 11.5227 18.0764 11.6653C18.219 11.808 18.2992 12.0015 18.2992 12.2032C18.2992 12.4049 18.219 12.5984 18.0764 12.7411C17.9337 12.8837 17.7402 12.9639 17.5385 12.9639ZM15.2565 18.2885H9.17123V14.4852H15.2565V18.2885ZM17.5385 9.92122H6.88924C6.28402 9.92122 5.70359 10.1616 5.27564 10.5896C4.84768 11.0176 4.60726 11.598 4.60726 12.2032V16.7672H7.6499V19.8098H16.7778V16.7672H19.8205V12.2032C19.8205 11.598 19.5801 11.0176 19.1521 10.5896C18.7241 10.1616 18.1437 9.92122 17.5385 9.92122Z',
			},
		},
	],
};

export default print;
