import { ReactNode } from 'react';

export const symbol_subdirectory_arrow_right: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M544-142q-9-9-9-21t9-21l141-141H260q-24.75 0-42.375-17.625T200-385v-385q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T260-770v385h426L546-525q-9-9-9-21.5t8.609-21.5Q555-577 567-577.5q12-.5 21 8.5l191 191q5 5 7 10.133 2 5.134 2 11Q788-351 786-346q-2 5-7 10L587-142q-9.067 9-21.533 9Q553-133 544-142Z"
		/>
	</svg>
);
