import { Fragment } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import { useOnboardingContext } from 'routes/public/onboarding';
import Button from 'routes/public/styles/Button';
import Title from 'routes/public/styles/Title';
import CancelButton from 'routes/public/styles/CancelButton';
import * as publicStyles from 'routes/public/styles/styles.css';

import Column from 'components/layout/column';

export default function CreateWardAccount(): JSX.Element {
	const { goTo } = useOnboardingContext();

	return (
		<Fragment>
			<Column spacing={styles.spacing._8} className={publicStyles.main}>
				<Title title={t('Create or connect to a player account')} />

				<Column>
					<Button
						label={t('Create a new player account')}
						description={t(
							`If your child doesn't already have an account in 360Player.`
						)}
						icon="player"
						iconColor={styles.palette.green[500]}
						onClick={() => goTo('ward-user-information')}
						testid="onboarding.create_child_account.create_account_button"
					/>
					<Button
						label={t('Connect to an existing player account')}
						description={t('If your child already has a 360Player account.')}
						icon="connect"
						iconColor={styles.palette.orange[500]}
						onClick={() => goTo('ward-connect')}
						testid="onboarding.create_child_account.connect_button"
					/>
				</Column>
			</Column>

			<div className={publicStyles.footer}>
				<CancelButton refresh />
			</div>
		</Fragment>
	);
}
