import { Fragment } from 'react';
import { t } from '@transifex/native';
import { useFormContext } from 'react-hook-form';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import { validatePersonalNumber } from 'pkg/api/models/user';

import { CustomFieldInput } from 'routes/payments/hooks/contact_form_sections/CustomFields';

import CountrySelect from 'components/CountrySelect';

import * as Input from 'components/form/inputs';
import Column from 'components/layout/column';
import { checkIfShouldValidatePersonalNumber } from 'components/user/missing-information';

interface FieldsProps {
	missingUserFields: models.userFields.UserField[];
	user: models.user.User;
}

export function Fields({ missingUserFields, user }: FieldsProps) {
	const formContext = useFormContext();
	const personalIdWatch = formContext.watch(
		'lokSwedishPersonalId',
		user.lokSwedishPersonalId
	);
	const nationalityWatch = formContext.watch('nationality', '');

	const shouldValidatePersonalNumber = checkIfShouldValidatePersonalNumber(
		nationalityWatch,
		personalIdWatch,
		false
	);

	return missingUserFields.map((field) => {
		let component = null;

		// special inputs for a few reserved types
		if (field.type === models.userFields.UserFieldType.Reserved) {
			field.label = models.userFields.getReservedTranslation(
				field.key.split('.').pop() as models.userFields.ReservedUserField
			);

			if (field.key.endsWith('.nationality')) {
				component = (
					<Input.Group label={t('Nationality')} required>
						<CountrySelect
							required
							role="nationality"
							name="nationality"
							testid="user.missing_information.nationality"
							suggestedCountry={user.country}
						/>
					</Input.Group>
				);
			} else if (field.key.endsWith('.country')) {
				component = (
					<Input.Group label={t('Country')} required>
						<CountrySelect
							required
							name="country"
							testid="user.missing_information.country"
						/>
					</Input.Group>
				);
			} else if (field.key.endsWith('.lokSwedishPersonalId')) {
				component = (
					<Input.Group
						label={t('Personal ID Number')}
						hint={t('Same as in IdrottOnline for LOK. (YYYYMMDD-XXXX)')}
						required={shouldValidatePersonalNumber && field.required}>
						<Input.Field
							name="lokSwedishPersonalId"
							defaultValue={user.lokSwedishPersonalId}
							placeholder="YYYYMMDD-XXXX"
							required={shouldValidatePersonalNumber && field.required}
							validate={shouldValidatePersonalNumber && validatePersonalNumber}
							testid="user.missing_information.lok_personal_id"
						/>
					</Input.Group>
				);
			} else if (field.key.endsWith('.sex')) {
				component = (
					<Input.Group label={t('Gender')} required>
						<Column spacing={styles.spacing._3}>
							<Input.Control
								value={models.user.UserSex.Unknown}
								defaultChecked={user.sex === models.user.UserSex.Unknown}
								type="radio"
								label={t('Not set')}
								name="sex"
								testid="genderNotSet"
							/>
							<Input.Control
								value={models.user.UserSex.Male}
								defaultChecked={user.sex === models.user.UserSex.Male}
								type="radio"
								label={t('Male')}
								name="sex"
								testid="genderMale"
							/>
							<Input.Control
								value={models.user.UserSex.Female}
								defaultChecked={user.sex === models.user.UserSex.Female}
								type="radio"
								label={t('Female')}
								name="sex"
								testid="genderFemale"
							/>
							<Input.Control
								value={models.user.UserSex.NotApplicable}
								defaultChecked={user.sex === models.user.UserSex.NotApplicable}
								type="radio"
								label={t('Not applicable')}
								name="sex"
								testid="genderNotApplicable"
							/>
						</Column>
					</Input.Group>
				);
			}
		}

		if (component === null) {
			component = <CustomFieldInput key={field.key} field={field} />;
		}

		return <Fragment key={field.key}>{component}</Fragment>;
	});
}
