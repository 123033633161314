import { ReactNode } from 'react';

export const symbol_left_click: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M782-100 650-231l-35 106q-4 11-15 10.5T586-126l-98-327q-2-8 4.5-14.5T507-472l327 98q11 3 11.5 14T835-345l-106 35 132 132q17 17 17 39t-17 39q-17 17-39.5 17T782-100ZM240-480q0-100 70-170t170-70q81 0 142.5 46.5T708-554q4 12-1.5 23T689-516q-12 4-23-2t-15-18q-17-54-63.5-89T480-660q-75 0-127.5 52.5T300-480q0 60 35 107t89 64q12 4 18 15t2 23q-4 12-15 18t-23 2q-74-24-120-87t-46-142Z"
		/>
	</svg>
);
