import styled from 'styled-components';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import { useCurrentAccountUserIds } from 'pkg/identity';

import { useChatContext } from 'routes/chat/view';

import Button from 'design/button';
import * as buttonStyles from 'design/button/styles.css';

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	grid-area: footer;
	background: var(--palette-white);
	border-top: var(--palette-gray-300) 1px solid;
	padding: 0 var(--spacing-5);
	height: 52px; /* px height to match ReplyForm height */
	color: var(--palette-gray-500);
	font-size: var(--font-size-sm);

	.${buttonStyles.button} {
		margin-left: 0.7rem;
	}
`;

interface ArchivedNoticeProps {
	chat: models.chat.Chat;
}

const ArchivedNotice = ({ chat }: ArchivedNoticeProps) => {
	const activeUserIds = useCurrentAccountUserIds();
	const myChatUser = models.chat.findMyChatUser(chat, activeUserIds);
	const chatContext = useChatContext();

	const handleUnarchiveChat = async () => {
		if (chat.isArchived && myChatUser.isAdmin) {
			models.chat.archive(chat, false);
		} else {
			const [req, resp] = await models.chatUser.update(chat, myChatUser, {
				hasArchived: false,
			});

			if (req) {
				chatContext.chatCollection.replaceInRecord(chat.id, 'users', resp);
			}
		}
	};

	return (
		<Wrapper>
			{t('This chat is archived')}{' '}
			{models.chat.canUnarchiveChat(chat, activeUserIds) && (
				<Button onClick={handleUnarchiveChat}>{t('Unarchive')}</Button>
			)}
		</Wrapper>
	);
};

export default ArchivedNotice;
