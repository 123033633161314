import { Fragment } from 'react';
import { t } from '@transifex/native';

import DateTime from 'pkg/datetime';
import * as models from 'pkg/api/models';
import { formatToCurrency } from 'pkg/i18n/formatters';
import { cssClasses } from 'pkg/css/utils';

import * as css from 'routes/payments/orders/single/invoice_description/styles.css';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import * as InfoList from 'components/payment_platform/InfoList';

interface OrderRowProps {
	row: models.orderRow.OrderRow;
	orderCurrency: string;
}

function DiscountRow({ row }: { row: models.orderRow.OrderRow }) {
	return (
		<Fragment>
			<LargeScreen>
				<tr className={css.tr} key={row.id}>
					<td className={cssClasses(css.td, css.bold)}>
						{t('Discount ({discountTitle})', {
							discountTitle: row.discount?.title,
						})}
					</td>
					<td className={css.td}>
						{models.discount?.isPercentage(row.discount)
							? t('Discount {percent}%', { percent: row.discount?.percentOff })
							: t('Discount')}
					</td>
					<td></td>
					<td></td>
					<td></td>
					<td className={cssClasses(css.td, css.alignRight)}>
						{formatToCurrency(row.amount / 100, row.discount.currency)}
					</td>
				</tr>
			</LargeScreen>
			<SmallScreen>
				<Fragment>
					<InfoList.Row translation={t('Product')}>
						<span>
							{t('Discount ({discountTitle})', {
								discountTitle: row.discount?.title,
							})}
						</span>
					</InfoList.Row>
					<InfoList.Row translation={t('Type')}>
						<span>
							{models.discount?.isPercentage(row.discount)
								? t('Discount {percent}%', {
										percent: row.discount?.percentOff,
									})
								: t('Discount')}
						</span>
					</InfoList.Row>
					<InfoList.Row spaceBetween translation={t('Unit price')}>
						<span>
							{formatToCurrency(row.amount / 100, row.discount.currency)}
						</span>
					</InfoList.Row>
				</Fragment>
			</SmallScreen>
		</Fragment>
	);
}

export default function OrderRow({ row, orderCurrency }: OrderRowProps) {
	if (row.type === 'discount') {
		return <DiscountRow row={row} />;
	}

	const price = row?.productPrice;

	const taxAmount = row.taxInclusive
		? (row.amount / 100 / (1 + row.taxPercentage / 100)) *
			(row.taxPercentage / 100)
		: (row.amount / 100) * (row.taxPercentage / 100);

	const rowDescription = row.description;
	const rowAmount = row.amount;

	let validUntil = '';

	if (row.validTo > 0) {
		validUntil = new DateTime(new Date(row.validTo * 1000)).toLocaleDateString({
			year: 'numeric',
			month: 'numeric',
			day: 'numeric',
		});
	}

	return (
		<Fragment>
			<LargeScreen>
				<tr className={css.tr} key={row.id}>
					<td className={cssClasses(css.td, css.bold)}>{rowDescription}</td>
					<td className={css.td}>
						{price
							? price.recurring
								? t('Recurring')
								: t('One-time')
							: t('One-time')}
					</td>
					<td className={cssClasses(css.td, css.alignRight)}>{validUntil}</td>
					<td className={cssClasses(css.td, css.alignRight)}>{row.quantity}</td>
					<td className={cssClasses(css.td, css.alignRight)}>
						{!!row.taxRateId ? (
							<span>
								{`${formatToCurrency(taxAmount, orderCurrency)} (${
									row.taxPercentage
								}%)`}
							</span>
						) : (
							'-'
						)}
					</td>
					<td className={cssClasses(css.td, css.alignRight)}>
						{formatToCurrency(rowAmount / 100, orderCurrency)}
					</td>
					<td className={cssClasses(css.td, css.alignRight)}>
						{formatToCurrency((rowAmount * row.quantity) / 100, orderCurrency)}
					</td>
				</tr>
			</LargeScreen>
			<SmallScreen>
				<Fragment>
					<InfoList.Row translation={t('Product')}>
						<span>{rowDescription}</span>
					</InfoList.Row>
					<InfoList.Row translation={t('Type')}>
						<span>
							{price
								? price.recurring
									? t('Recurring')
									: t('One-time')
								: t('One-time')}
						</span>
					</InfoList.Row>
					<InfoList.Row translation={t('Valid until')}>
						<span>{validUntil}</span>
					</InfoList.Row>
					<InfoList.Row spaceBetween translation={t('Quantity')}>
						<span>{row.quantity}</span>
					</InfoList.Row>
					<InfoList.Row spaceBetween translation={t('Unit price')}>
						<span>{formatToCurrency(rowAmount / 100, orderCurrency)}</span>
					</InfoList.Row>
					<InfoList.Row spaceBetween translation={t('Total amount')}>
						<span>
							{formatToCurrency(
								(rowAmount * row.quantity) / 100,
								orderCurrency
							)}
						</span>
					</InfoList.Row>
				</Fragment>
			</SmallScreen>
		</Fragment>
	);
}
