import { ReactNode } from 'react';

export const symbol_format_color_text: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M110 0Q97 0 88.5-8.5T80-30v-100q0-13 8.5-21.5T110-160h740q13 0 21.5 8.5T880-130v100q0 13-8.5 21.5T850 0H110Zm161-280q-19 0-29-15.5t-3-32.5l192-488q5-11 14-17.5t20-6.5h30q11 0 20 6.5t14 17.5l192 489q7 17-3.5 32T689-280q-11 0-20-6t-13-17l-48-127H352l-49 128q-4 10-12.5 16t-19.5 6Zm105-214h208L482-765h-4L376-494Z"
		/>
	</svg>
);
