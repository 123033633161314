import ProgressBar from 'components/form/ProgressBar';
import Column from 'components/layout/column';

const FormProgressBar = () => {
	return (
		<Column>
			<ProgressBar
				completed={false}
				progress={50}
				label="Completed on June 21"
			/>
			<ProgressBar
				completed={true}
				progress={100}
				label="Completed on June 21"
			/>
		</Column>
	);
};
export default FormProgressBar;
