// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Qx0vjJIDzQMpfhvBt1xE {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
}

.DBb985fVEO4efNi69L88 {
	font-weight: var(--font-weight-semibold);
	font-size: var(--font-size-sm);
}

.NW3hdg051I5R4MMX4mxX {
	color: var(--palette-gray-500);
	font-size: var(--font-size-xs);
}

.oLTZv38qUVIO43PQrpPz {
	font-size: var(--font-size-sm);
}
`, "",{"version":3,"sources":["webpack://./routes/account/settings/login/auth-option-card/styles.css"],"names":[],"mappings":"AAAA;CACC,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,yBAA8B;KAA9B,sBAA8B;SAA9B,8BAA8B;CAC9B,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;AACpB;;AAEA;CACC,wCAAwC;CACxC,8BAA8B;AAC/B;;AAEA;CACC,8BAA8B;CAC9B,8BAA8B;AAC/B;;AAEA;CACC,8BAA8B;AAC/B","sourcesContent":[".providerWrapper {\n\tdisplay: flex;\n\tjustify-content: space-between;\n\talign-items: center;\n}\n\n.name {\n\tfont-weight: var(--font-weight-semibold);\n\tfont-size: var(--font-size-sm);\n}\n\n.hint {\n\tcolor: var(--palette-gray-500);\n\tfont-size: var(--font-size-xs);\n}\n\n.connected {\n\tfont-size: var(--font-size-sm);\n}\n"],"sourceRoot":""}]);
// Exports
export var providerWrapper = `Qx0vjJIDzQMpfhvBt1xE`;
export var name = `DBb985fVEO4efNi69L88`;
export var hint = `NW3hdg051I5R4MMX4mxX`;
export var connected = `oLTZv38qUVIO43PQrpPz`;
export default ___CSS_LOADER_EXPORT___;
