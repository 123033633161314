const filePdf = {
	name: 'file-pdf',
	objects: [
		{
			type: 'path',
			attributes: {
				d: 'M12.3924 13.7532L12.3982 13.7258C12.5117 13.2582 12.6545 12.6752 12.543 12.147C12.4687 11.7303 12.1615 11.5679 11.8994 11.5562C11.5903 11.5425 11.3144 11.7186 11.246 11.9749C11.1168 12.4444 11.2323 13.086 11.4436 13.9038C11.1775 14.5376 10.753 15.4591 10.4419 16.0068C9.86285 16.3061 9.08619 16.7678 8.97077 17.3508C8.94729 17.4584 8.97468 17.5954 9.03924 17.7186C9.11162 17.8555 9.22704 17.9612 9.36203 18.0121C9.42072 18.0336 9.49115 18.0512 9.57331 18.0512C9.91762 18.0512 10.4752 17.7734 11.2186 16.4979C11.332 16.4607 11.4494 16.4216 11.5629 16.3824C12.095 16.2025 12.6467 16.0147 13.1455 15.9305C13.6972 16.2259 14.3252 16.4157 14.7517 16.4157C15.1742 16.4157 15.3405 16.1653 15.4031 16.0147C15.5127 15.7506 15.4599 15.418 15.2818 15.24C15.0236 14.9856 14.3956 14.9191 13.4175 15.0404C12.9362 14.747 12.6213 14.3479 12.3924 13.7532ZM10.2463 16.7248C9.97436 17.12 9.76894 17.3176 9.65743 17.4036C9.78851 17.163 10.0448 16.9087 10.2463 16.7248ZM11.96 12.1177C12.0617 12.2918 12.0481 12.818 11.9698 13.0841C11.8739 12.6948 11.8602 12.1431 11.917 12.0785C11.9326 12.0805 11.9463 12.0922 11.96 12.1177ZM11.9287 14.475C12.138 14.837 12.4021 15.148 12.6936 15.3789C12.2711 15.4747 11.8857 15.6332 11.5414 15.774C11.4592 15.8073 11.379 15.8405 11.3007 15.8718C11.5609 15.4004 11.7781 14.8663 11.9287 14.475ZM14.9727 15.7564C14.9747 15.7603 14.9767 15.7662 14.9649 15.774H14.961L14.9571 15.7799C14.9414 15.7897 14.781 15.8836 14.0904 15.6117C14.8847 15.5745 14.9708 15.7545 14.9727 15.7564Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M12.8312 5.23442L15.4568 7.82092L18.3233 10.7265H12.8312V5.23442Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M12.8312 5.23442H6.09906V20.2933H18.3233L18.3233 10.7265H17.0233V18.9933H7.39906V6.53442H12.8312V5.23442Z',
			},
		},
	],
};

export default filePdf;
