import { ReactNode } from 'react';

export const symbol_home_max: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-250h600q33 0 56.5-23.5T860-330v-290q0-33-23.5-56.5T780-700H180q-33 0-56.5 23.5T100-620v290q0 33 23.5 56.5T180-250Zm100 60H180q-59 0-99.5-40.5T40-330v-290q0-59 40.5-99.5T180-760h600q59 0 99.5 40.5T920-620v290q0 59-40.5 99.5T780-190H680q0 13-8.625 21.5T650-160H310q-12.75 0-21.375-8.625T280-190Zm200-285Z"
		/>
	</svg>
);
