import { t } from '@transifex/native';
import { Fragment, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import User, { IUser } from 'pkg/models/user';

import { useEndpoint } from 'pkg/api/use_endpoint';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as selectors from 'pkg/selectors';
import * as actions from 'pkg/actions';
import { RootState } from 'pkg/reducers';

import StepModal, { Step } from 'components/step-modal';

import { Spinner } from 'components/loaders/spinner';
import * as InfoList from 'components/payment_platform/InfoList';
import RadioButton from 'components/form/RadioButton';
import InfoBox from 'components/form/info-box';

const Wrapper = styled.div`
	display: grid;
	grid-auto-flow: row;
	margin-top: var(--spacing-8);
`;

const Row = styled.div`
	display: grid;
	grid-template-columns: 125px 1fr 1fr;
	border-bottom: 1px solid var(--palette-gray-200);

	&:first-child {
		border-bottom: none;
	}
`;

const Col = styled.div`
	padding: var(--spacing-4);

	div {
		display: flex;
		align-items: center;

		span {
			margin-left: var(--spacing-3);
		}
	}
`;

const ListWrapper = styled.div`
	margin-top: var(--spacing-8);
`;

const InfoRowInner = styled.div`
	display: flex;
	align-items: center;

	span:first-child {
		width: 225px;
	}

	span:last-child {
		color: var(--palette-gray-400);
		text-decoration: line-through;
	}
`;

const GrayCol = styled(Col)`
	background-color: var(--palette-gray-100);
	color: var(--palette-gray-500);
`;

const BoldCol = styled(Col)`
	font-weight: var(--font-weight-semibold);
`;

interface MergeContactsProps {
	users: number[];
	onMergeFinished: () => void;
	onClose: () => void;
}

interface SelectableRowProps {
	translation: string;
	property: string;
	values: string[];
	selectedValue: string;
	onSelect: (property: string, value: string) => void;
}

interface BillingUserRowProps {
	users: number[];
	selectedValue: string;
	onSelect: (property: string, value: string) => void;
}

const SelectableRow: React.FC<React.PropsWithChildren<SelectableRowProps>> = ({
	translation,
	property,
	values,
	selectedValue,
	onSelect,
}) => (
	<Row>
		<BoldCol>{translation}</BoldCol>
		<Col>
			<div>
				<RadioButton
					checked={values[0] === selectedValue}
					onChange={() => onSelect(property, values[0])}
				/>
				<span>{values[0]}</span>
			</div>
		</Col>
		<Col>
			<div>
				<RadioButton
					checked={values[1] === selectedValue}
					onChange={() => onSelect(property, values[1])}
				/>
				<span>{values[1]}</span>
			</div>
		</Col>
	</Row>
);

const BillingUserRow: React.FC<
	React.PropsWithChildren<BillingUserRowProps>
> = ({ users, selectedValue, onSelect }) => {
	const billingUserOne = useSelector((state: RootState) =>
		selectors.users.find(state, users[0])
	);

	const billingUserTwo = useSelector((state: RootState) =>
		selectors.users.find(state, users[1])
	);

	const selectedId: number = Number.parseInt(selectedValue, 10);

	const handleSelect = (id: number): void =>
		onSelect('billingUserId', id.toString());

	return (
		<Row>
			<BoldCol>{t(`Billing user`)}</BoldCol>
			<Col>
				<div>
					<RadioButton
						checked={billingUserOne.id === selectedId}
						onChange={() => handleSelect(billingUserOne.id)}
					/>
					{billingUserOne.id && <span>{billingUserOne.fullName}</span>}
				</div>
			</Col>
			<Col>
				<div>
					<RadioButton
						checked={billingUserTwo.id === selectedId}
						onChange={() => handleSelect(billingUserTwo.id)}
					/>
					{billingUserTwo.id && <span>{billingUserTwo.fullName}</span>}
				</div>
			</Col>
		</Row>
	);
};

const MergeContacts: React.FC<React.PropsWithChildren<MergeContactsProps>> = ({
	users,
	onMergeFinished,
	onClose,
}) => {
	const { record: userOne, isLoading: isUserOneLoading } =
		useEndpoint<models.user.User>(endpoints.Users.Show(users[0]));
	const { record: userTwo, isLoading: isUserTwoLoading } =
		useEndpoint<models.user.User>(endpoints.Users.Show(users[1]));

	const [isMerged, setIsMerged] = useState<boolean>(false);
	const [selectedValues, setSelectedValues] = useState<{
		[key: string]: string;
	}>({});

	const handleSelect = (key: string, value: string): void =>
		setSelectedValues((prev) => {
			const selected = { ...prev };
			selected[key] = value;

			return selected;
		});

	const mergeContact = new User({ ...selectedValues });

	const handleMerge = async (): Promise<boolean> => {
		if (userOne.accountId) {
			await actions.users.mergeUsers(userOne.id, userTwo.id, selectedValues);
		} else {
			await actions.users.mergeUsers(userTwo.id, userOne.id, selectedValues);
		}
		setIsMerged(true);

		return true;
	};

	const mergeProps: string[] = [
		'firstName',
		'lastName',
		'companyName',
		'email',
		'address',
		'region',
		'postalCode',
		'city',
		'country',
		'phoneNumber',
		'birthDate',
		'language',
	];

	const conflictingProps: string[] = Object.entries(userOne)
		.filter(([key, value]: [key: keyof IUser, value: string]) => {
			if (!mergeProps.includes(key)) {
				return false;
			}

			return userTwo[key] !== value;
		})
		.map(([key]) => key);

	const getTranslation = (prop: string) => {
		const snakeCase = prop.replace(
			/[A-Z]/g,
			(letter) => `_${letter.toLowerCase()}`
		);

		switch (snakeCase) {
			case 'birth_date':
				return t('Date of birth');
			case 'city':
				return t('City');
			case 'company_name':
				return t('Company name');
			case 'country':
				return t('Country');
			case 'description':
				return t('Fill in the personal information about the contact');
			case 'email':
				return t('Email');
			case 'first_name':
				return t('First name');
			case 'language':
				return t('Language');
			case 'last_name':
				return t('Last name');
			case 'lok_personal_id':
				return t('Personal ID Number');
			case 'nationality':
				return t('Nationality');
			case 'phone_number':
				return t('Phone number');
			case 'postal_code':
				return t('ZIP code');
			case 'region':
				return t('Region');
			case 'sex':
				return t('Gender');
			case 'tax_id_type':
				return t('Tax value');
			case 'tax_id_value':
				return t('Tax ID');
		}
	};

	const getSelectableRow = (prop: keyof IUser) => (
		<SelectableRow
			key={prop}
			translation={getTranslation(prop)}
			property={prop}
			values={[userOne[prop], userTwo[prop]]}
			selectedValue={selectedValues[prop]}
			onSelect={handleSelect}
		/>
	);

	const handleClose = () => {
		onClose();

		// only deselect users from the list if/when the modal is closed
		if (isMerged) {
			onMergeFinished();
		}
	};

	return (
		<StepModal onClose={handleClose}>
			<Step
				title={t(`Merge contacts`)}
				canGoNext={
					Object.keys(selectedValues).length === conflictingProps.length
				}
				nextLabel={t(`Preview merge`)}>
				{isUserOneLoading || isUserTwoLoading ? (
					<Spinner />
				) : (
					<Fragment>
						<InfoBox
							text={t(
								`We found the {amount} following differences between the contacts`,
								{
									amount: conflictingProps.length,
								}
							)}
						/>
						<Wrapper>
							<Row>
								<Col />
								<GrayCol>{t(`Contact 1`)}</GrayCol>
								<GrayCol>{t(`Contact 2`)}</GrayCol>
							</Row>
							<Row>
								<GrayCol>{t(`Property`)}</GrayCol>
								<BoldCol>{models.user.fullName(userOne)}</BoldCol>
								<BoldCol>{models.user.fullName(userTwo)}</BoldCol>
							</Row>
							{conflictingProps.map(
								(conflict: keyof IUser) =>
									conflict !== 'billingUserId' && getSelectableRow(conflict)
							)}
							{conflictingProps.includes('billingUserId') && (
								<BillingUserRow
									users={[userOne.billingUserId, userTwo.billingUserId]}
									selectedValue={selectedValues['billingUserId']}
									onSelect={handleSelect}
								/>
							)}
						</Wrapper>
					</Fragment>
				)}
			</Step>
			<Step
				title={t(`Merge contacts`)}
				onNext={handleMerge}
				nextLabel={t(`Merge`)}>
				<InfoBox text={t(`This is a preview of the merged contact`)} />
				<ListWrapper>
					<InfoList.List title={t(`Contact`)}>
						{Object.entries(mergeContact.toJS()).map(
							([key, value]: [key: keyof IUser, value: string]) => {
								if (!mergeProps.includes(key)) {
									return;
								}

								return (
									<InfoList.Row key={key} translation={getTranslation(key)}>
										<InfoRowInner>
											<span>{value}</span>
											{value && (
												<span>
													{userOne[key] === value ? userTwo[key] : userOne[key]}
												</span>
											)}
										</InfoRowInner>
									</InfoList.Row>
								);
							}
						)}
					</InfoList.List>
				</ListWrapper>
			</Step>
		</StepModal>
	);
};

export default MergeContacts;
