import { ReactNode } from 'react';

export const symbol_attractions: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m257-116 36-83q-17-12-30.5-23.5T235-248q-8 4-17.5 6t-17.5 2q-32 0-54.5-22.5T123-317q0-20 9.5-37.5T156-380q-7-25-11-50t-4-51q0-27 3.5-51.5T156-582q-14-10-23.5-26.5T123-645q0-32 22.5-54.5T200-722q8 0 17 2t17 6q35-36 76.5-60t92.5-37q5-32 26.5-50.5T480-880q29 0 51 19t26 50q51 13 96 34.5t79 59.5q7-2 14-3.5t14-1.5q32 0 54.5 22.5T837-645q0 22-9.5 38T804-582q8 26 11.5 50t3.5 51q0 26-3.5 50.5T804-381q17 11 25 29t8 35q0 32-22.5 54.5T760-240q-8 0-17.5-2t-16.5-6q-13 14-27 26t-30 23l36 83q5 13-2.5 24.5T680-80q-8 0-14.5-4T656-95l-35-77q-17 6-32 11t-32 10q-5 33-26.5 51T480-82q-29 0-50.5-18T403-151q-17-5-32.5-10T341-173l-37 78q-3 7-9.5 11T280-80q-14 0-21-11.5t-2-24.5Zm58-134 71-155q-14-16-21-34.5t-7-40.5q0-50 37.5-86t87.5-36q50 0 84.5 36t34.5 86q0 22-6.5 40.5T574-405l71 155q11-8 22.5-17.5T689-288q-2-6-4-13.5t-2-15.5q0-27 18-49t50-28q7-20 10.5-42t3.5-45q0-24-3.5-45.5T752-568q-30-4-49.5-26T683-645q0-9 1.5-16t4.5-15q-32-31-67-51t-79-32q-8 13-24.5 23T480-726q-22 0-38-10t-25-23q-44 12-80.5 32T271-674q4 8 5 14.5t1 14.5q0 32-20.5 52.5T209-568q-7 20-10.5 41.5T195-481q0 23 3.5 45t10.5 42q32 6 50 28t18 49q0 9-1.5 16t-3.5 12q10 11 20.5 20.5T315-250Zm45 27q12 5 26.5 10.5T417-203q11-14 26-23.5t37-9.5q22 0 38.5 9.5T543-203q17-4 30.5-9t24.5-10l-65-151q-12 8-26 11.5t-28 3.5q-14 0-28-4t-26-12l-65 151Zm120-195q26 0 44-18t18-44q0-26-18-44t-44-18q-26 0-44 18t-18 44q0 26 18 44t44 18Zm0-62Z"
		/>
	</svg>
);
