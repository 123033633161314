import { ReactNode } from 'react';

export const symbol_mark_chat_unread: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M240-240 131-131q-14 14-32.5 6.344T80-152v-668q0-24 18-42t42-18h399q13.6 0 22.8 9.5Q571-861 569-847q-2 11-10.5 19t-19.5 8H140v600l74-80h606v-315q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T880-615v315q0 24-18 42t-42 18H240Zm519.882-479Q714-719 682-751.118q-32-32.117-32-78Q650-875 682.118-907q32.117-32 78-32Q806-939 838-906.882q32 32.117 32 78Q870-783 837.882-751q-32.117 32-78 32ZM140-820v520-520Z"
		/>
	</svg>
);
