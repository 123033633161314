import styled from 'styled-components';

import * as styles from 'pkg/config/styles';

import rgba from 'pkg/rgba';

import * as Input from 'components/form/inputs';

export const SelectEventType = styled(Input.Select)<{ eventType: string }>`
	select {
		padding-left: 28px;
	}

	&::before {
		content: '';
		position: absolute;
		left: 11px;
		top: 50%;
		transform: translateY(-50%);
		width: 9px;
		height: 9px;
		background-color: ${({ eventType }) =>
			eventType !== ''
				? rgba(styles.palette.events[eventType].foregroundColor)
				: styles.palette.gray[300]};
		border-radius: 100%;
	}
`;
