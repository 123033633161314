export default class ColorConverter {
	// @note These are used to create a color contrast.
	// @see https://www.w3.org/TR/AERT/#color-contrast
	static contrastThreshold = 123;
	static darkenContrastValue = 5;
	static brightenContrastValue = 100;

	constructor(h = 180, s = 0, l = 0) {
		this.fromHSL(h, s, l);
	}

	setHue(h) {
		if (h > 360) h = 360;
		if (h < 0) h = 0;

		this.h = Math.abs(h);
		return this;
	}

	getHue() {
		return this.h;
	}

	setSaturation(s) {
		if (s > 100) s = 100;
		if (s < 0) s = 0;

		this.s = Math.abs(s);
		return this;
	}

	getSaturation() {
		return this.s;
	}

	setLightness(l) {
		if (l > 100) l = 100;
		if (l < 0) l = 0;

		this.l = Math.abs(l);
		return this;
	}

	getLightness() {
		return this.l;
	}

	fromHSL(h, s, l) {
		this.setHue(Number.parseInt(h, 10));
		this.setSaturation(s);
		this.setLightness(l);

		return [h, s, l];
	}

	toHSL(convertToString = false) {
		if (convertToString === true) {
			return `${this.h},${this.s}%,${this.l}%`;
		}

		return [this.h, this.s, this.l];
	}

	static isHSL(hslString) {
		const regex = /\s*(\d+)\s*,\s*(\d+(?:\.\d+)?%)\s*,\s*(\d+(?:\.\d+)?%)/g;

		const matches = regex
			.exec(hslString)
			?.slice(1)
			.map((m) => parseInt(m));

		if (!matches) return false;

		const [h, s, l] = matches;

		if (h < 0 || h > 360) return false;
		if (s < 0 || s > 100) return false;
		if (l < 0 || l > 100) return false;

		return true;
	}

	fromRGB(r, g, b) {
		r /= 255;
		g /= 255;
		b /= 255;

		let max = Math.max(r, g, b);
		let min = Math.min(r, g, b);

		let d = max - min;
		let h;

		if (d === 0) h = 0;
		else if (max === r) h = ((g - b) / d) % 6;
		else if (max === g) h = (b - r) / d + 2;
		else if (max === b) h = (r - g) / d + 4;

		let l = (min + max) / 2;
		let s = d === 0 ? 0 : d / (1 - Math.abs(2 * l - 1));

		return this.fromHSL(h * 60, s * 100, l * 100);
	}

	toRGB(convertToString = false) {
		let [h, s, l] = this.toHSL();

		s /= 100;
		l /= 100;

		let n;
		let c = (1 - Math.abs(2 * l - 1)) * s;
		let hp = h / 60.0;
		let x = c * (1 - Math.abs((hp % 2) - 1));

		if (isNaN(h)) n = [0, 0, 0];
		else if (hp <= 1) n = [c, x, 0];
		else if (hp <= 2) n = [x, c, 0];
		else if (hp <= 3) n = [0, c, x];
		else if (hp <= 4) n = [0, x, c];
		else if (hp <= 5) n = [x, 0, c];
		else if (hp <= 6) n = [c, 0, x];

		let m = l - c * 0.5;

		const rgb = [
			Math.round(255 * (n[0] + m)),
			Math.round(255 * (n[1] + m)),
			Math.round(255 * (n[2] + m)),
		].map(Math.abs);

		if (convertToString === true) {
			return rgb.join(',');
		}

		return rgb;
	}

	static isRGB(rgbString) {
		if (!rgbString) return false;

		return (
			rgbString.match(
				/(( *0*([1]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5]) *),){2}( *0*([1]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5]) *)/
			)?.length > 0
		);
	}

	fromHEX(hex) {
		if (!hex) return this;

		const [r, g, b] = hex
			.replace(
				/^#?([a-f\d])([a-f\d])([a-f\d])$/i,
				(m, r, g, b) => '#' + r + r + g + g + b + b
			)
			.substring(1)
			.match(/.{2}/g)
			.map((n) => parseInt(n, 16))
			.map(Math.abs);

		return this.fromRGB(r, g, b);
	}

	toHEX() {
		const [r, g, b] = this.toRGB();
		const rgb = b | (g << 8) | (r << 16);

		return '#' + (0x1000000 + rgb).toString(16).slice(1).toUpperCase();
	}

	static isHEX(hexString) {
		if (!hexString) return false;

		if (!hexString.startsWith('#')) {
			hexString = '#' + hexString;
		}

		return hexString.match(/^#(?:[0-9a-f]{3}){1,2}$/i);
	}

	static from(colorString) {
		const conv = new ColorConverter();

		if (ColorConverter.isHEX(colorString)) {
			conv.fromHEX(colorString);
		} else if (ColorConverter.isHSL(colorString)) {
			conv.fromHSL(
				...colorString.replace('hsl:', '').split(',').map(parseFloat)
			);
		} else if (ColorConverter.isRGB(colorString)) {
			conv.fromRGB(...colorString.split(',').map(parseFloat));
		}

		return conv;
	}

	copy() {
		return new ColorConverter(this.h, this.s, this.l);
	}

	getBrightness() {
		const [r, g, b] = this.toRGB();

		// @see https://www.w3.org/TR/AERT/#color-contrast
		const brightness = (r * 299 + g * 587 + b * 114) / 1000;

		return brightness;
	}

	get isBright() {
		return this.getBrightness() >= ColorConverter.contrastThreshold;
	}

	get isDark() {
		return this.getBrightness() < ColorConverter.contrastThreshold;
	}

	getAccentColor() {
		const conv = this.copy();

		if (conv.isBright) {
			// Convert to dark
			conv.setLightness(ColorConverter.darkenContrastValue);
		} else {
			// Convert to bright
			conv.setLightness(ColorConverter.brightenContrastValue);
		}

		return conv;
	}

	getInverseColor() {
		const conv = this.copy();

		conv.setHue((this.h + 180) % 360);

		return conv;
	}

	rotate(deg) {
		return this.setHue((this.h + deg) % 360);
	}

	saturate(amount) {
		return this.setSaturation(this.s + amount);
	}

	desaturate(amount) {
		return this.setSaturation(this.s - amount);
	}

	brighten(amount) {
		return this.setLightness(this.l + amount);
	}

	darken(amount) {
		return this.setLightness(this.l - amount);
	}

	toString() {
		return this.toHSL(true);
	}

	toStylesheetString() {
		return `hsl(${this.toHSL(true)})`;
	}
}

const cssColor = (type, value, alpha = 1) => {
	if (type === 'hsl' || type === 'rgb') {
		if (alpha < 1) {
			type += 'a';
			value += `,${alpha}`;
		}
		return `${type}(${value})`;
	} else {
		if (alpha < 1) {
			const a = Math.round((Math.round(alpha * 100) / 100) * 255);
			const hexAlpha = (a + 0x10000).toString(16).substr(-2).toUpperCase();

			return `#${value}${hexAlpha}`;
		}

		return `#${value}`;
	}
};

export const primaryColor = (color, alpha = 1) => {
	return cssColor('hsl', color, alpha);
};

export const accentColor = (color, alpha = 1) => {
	const conv = ColorConverter.from(color);

	return cssColor('hsl', conv.getAccentColor().toHSL(true), alpha);
};

export const backgroundColor = (color) => {
	const conv = ColorConverter.from(color);

	conv.setLightness(97);
	conv.setSaturation(13);
	return cssColor('hsl', conv.toHSL(true));
};

export const scrollbarColor = (color) => {
	const conv = ColorConverter.from(color).getAccentColor();

	if (conv.isDark) {
		conv.brighten(25).desaturate(25);
	} else {
		conv.darken(55).desaturate(25);
	}

	return cssColor('hsl', conv.toHSL(true));
};
