import { ReactNode } from 'react';

export const symbol_cleaning_bucket: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M333-40q-22.785 0-39.873-14.696Q276.038-69.39 273-92l-68-514q-2-13.6 6.5-23.8Q220-640 234-640h492q14 0 22.5 10.2T755-606L687-92q-3.038 22.609-20.127 37.304Q649.785-40 627-40H333Zm0-60h295l63-480H269l64 480Zm147.118-230Q526-330 558-362.083q32-32.084 32-77.917v-40q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T530-480v40q0 21.25-14.325 35.625Q501.351-390 480.175-390 459-390 444.5-404.375 430-418.75 430-440v-40q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T370-480v40q0 45.833 32.118 77.917 32.117 32.083 78 32.083ZM600-700q-20 0-35-15t-15-35.5q0-20.5 15-35t35.5-14.5q20.5 0 35 14.583Q650-770.833 650-750q0 20-14.583 35-14.584 15-35.417 15Zm-200-40q-37 0-63.5-26.5t-26.5-64q0-37.5 26.5-63.5t64-26q37.5 0 63.5 26.1t26 63.9q0 37-26.1 63.5T400-740Zm228 640H333h295Z"
		/>
	</svg>
);
