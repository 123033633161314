import { ReactNode } from 'react';

export const symbol_blind: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M458-774q-30 0-51.5-21.5T385-847q0-30 21.5-51.5T458-920q30 0 51.5 21.5T531-847q0 30-21.5 51.5T458-774Zm252 274q0 13-8.5 21.5T680-470h-46L856-88q3 5 1 11.5t-7 9.5q-5 3-11.5 1t-9.5-7L595-478q-42-10-86.5-40.5T441-585q-12 34-18.5 73.5T418-440l97 137v213q0 13-8.5 21.5T485-60q-13 0-21.5-8.5T455-90v-189l-85-95-10 154L258-84q-8 10-20 12t-22-6q-10-8-12-20t6-22l86-115-8-191q-2-51 5.5-109T317-637l-97 56v81q0 13-8.5 21.5T190-470q-13 0-21.5-8.5T160-500v-99q0-8 4-15t11-11l152-87q17-10 33-15t31-5q24 0 42.5 11.5T461-687l26 59q19 43 73 70.5T680-530q13 0 21.5 8.5T710-500Z"
		/>
	</svg>
);
