import { ReactNode } from 'react';

export const symbol_open_with: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M450-198v-159q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-357v159l67-67q9.13-9 22.065-9T621-264.897q9 9.104 9 22Q630-230 621-221L501-101q-5 5-10.133 7-5.134 2-11 2Q474-92 469-94q-5-2-10-7L339-221q-9-9-9-22t9-22q9-9 21.833-9 12.834 0 22.167 9l67 67ZM198-450l62 62q9 9.13 9 22.065T259.897-344q-9.104 9-22 9Q225-335 216-344L101-459q-5-5-7-10.133-2-5.134-2-11Q92-486 94-491q2-5 7-10l116-116q9.333-9 22.167-9Q252-626 261-617q9 9 9 21.833 0 12.834-9 22.167l-63 63h159q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T357-450H198Zm564 0H604q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T604-510h158l-62-62q-9-9.13-9-22.065T700.103-616q9.104-9 22-9Q735-625 744-616l115 115q5 5 7 10.133 2 5.134 2 11Q868-474 866-469q-2 5-7 10L744-344q-9 9-22 9t-22-9q-9-9-9-21.833 0-12.834 9-22.167l62-62ZM450-762l-62 62q-9 9-22 9t-22-9q-9-9-9-21.833 0-12.834 9-22.167l115-115q5-5 10.133-7 5.134-2 11-2Q486-868 491-866q5 2 10 7l115 115q9 9 9 22t-9 22q-9 9-21.833 9-12.834 0-22.167-9l-62-62v158q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T450-604v-158Z"
		/>
	</svg>
);
