import { ReactNode } from 'react';

export const symbol_forward_5: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-80q-75 0-140.5-28T225-185q-49-49-77-114.5T120-440q0-75 28-140.5T225-695q49-49 114.5-77T480-800h23l-57-57q-8-8-8-20.5t7.652-20.5Q454-906 466-906.5q12-.5 20 7.5l106 106q9 9 9 21t-9 21L487-646q-9 9-21 9t-21.391-9Q436-655 436-667t9-21l52-52h-22q-125 0-210 87.321Q180-565.357 180-440t87.321 212.679Q354.643-140 480-140t212.679-87.321Q780-314.643 780-440q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T840-440q0 75-28 140.5T735-185q-49 49-114.5 77T480-80Zm42-230H405q-10.833 0-17.917-7.116-7.083-7.117-7.083-18Q380-346 387.083-353q7.084-7 17.917-7h102v-56H405q-10.833 0-17.917-7.083Q380-430.167 380-441v-106q0-11 7.083-17.5Q394.167-571 405-571h127q10.4 0 17.2 6.5 6.8 6.5 6.8 17.384 0 10.883-6.5 18Q543-522 532-522H430v57h102q11 0 17.5 6.5T556-441v97q0 14.45-9.775 24.225Q536.45-310 522-310Z"
		/>
	</svg>
);
