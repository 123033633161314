export const IS_PLAYING = 'is-playing';
export const IS_OUTSIDE_CLIP = 'is-outside';
export const IS_PAUSED = 'is-paused';
export const IS_EDITING = 'is-editing';
export const IS_DRAGGING = 'is-dragging';
export const IS_DISPLAYING = 'is-displaying';
export const IS_TRIGGERED = 'is-triggered';
export const ANNOTATION_PREFIX = 'annotation-';
export const MULTIPOINT = 'multipoint-annotation';
export const MULTIPOINT_CIRCLE_PREFIX = 'multipoint-annotation-circle-';
