import { Fragment } from 'react';

import { useCurrentOrganization } from 'pkg/identity';
import { PaymentProvider } from 'pkg/api/models/group';
import useMixedState from 'pkg/hooks/useMixedState';
import { useCollection } from 'pkg/api/use_collection';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';

import OrganizationPayouts from 'routes/payments/payouts/organization_payouts';
import PayoutsTable from 'routes/payments/payouts/table';

import Pagination from 'components/pagination';

interface PayoutsListProps {
	organizationId: number;
}

function PayoutsList({ organizationId }: PayoutsListProps) {
	const [sort, setSort] = useMixedState<{
		by: string;
		order: 'desc' | 'asc';
	}>({
		by: 'paid_out',
		order: 'desc',
	});

	const payoutsCollection = useCollection<models.payout.Payout>(
		endpoints.Payout.Index(),
		{
			queryParams: new URLSearchParams({
				group_id: organizationId.toString(),
				sort_by: sort.by,
				sort_by_order: sort.order,
			}),
		}
	);

	const handleSortClick = (field: string, sortBy: 'asc' | 'desc') => {
		setSort({
			by: field,
			order: sortBy,
		});
	};

	return (
		<Fragment>
			<PayoutsTable
				payoutsCollection={payoutsCollection}
				sort={sort}
				onSortClick={handleSortClick}
			/>
			<Pagination {...payoutsCollection.pagination} />
		</Fragment>
	);
}

interface PayoutsProps {
	organizationId: number;
}

export default function Payouts({ organizationId }: PayoutsProps) {
	const group = useCurrentOrganization();

	if (group.paymentProvider === PaymentProvider.Purspot) {
		return <PayoutsList organizationId={organizationId} />;
	}

	// This is stripe legacy, will be removed when stripe payouts has been normalised
	return <OrganizationPayouts organizationId={organizationId} />;
}
