import { ReactNode } from 'react';

export const symbol_medical_information: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M290-330v60q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T350-270v-60h60q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T410-390h-60v-60q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T290-450v60h-60q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T230-330h60Zm250-60h200q8 0 14-6t6-14q0-8-6-14t-14-6H540q-8 0-14 6t-6 14q0 8 6 14t14 6Zm0 120h120q8 0 14-6t6-14q0-8-6-14t-14-6H540q-8 0-14 6t-6 14q0 8 6 14t14 6ZM140-80q-24 0-42-18t-18-42v-480q0-24 18-42t42-18h250v-140q0-24 18-42t42.411-18h59.178Q534-880 552-862t18 42v140h250q24 0 42 18t18 42v480q0 24-18 42t-42 18H140Zm0-60h680v-480H570v30q0 28-18 44t-42.411 16h-59.178Q426-530 408-546t-18-44v-30H140v480Zm310-450h60v-230h-60v230Zm30 210Z"
		/>
	</svg>
);
