import { ReactNode } from 'react';

export const symbol_sports_hockey: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M80-200v-45q0-17 11.5-28.5T120-285h20v125h-20q-17 0-28.5-11.5T80-200Zm104 40v-125h175l43-94 49 97-46 101q-5 11-15 16t-21 5H184Zm696-40q0 17-11.5 28.5T840-160h-20v-125h20q17 0 28.5 11.5T880-245v45Zm-104 40H591q-11 0-21-5t-15-16L300-740q-10-21 3-40.5t36-19.5q13 0 23 7t15 18l103 225 103-225q5-11 15-18t23-7q23 0 36 19.5t3 40.5L527-447l74 162h175v125Z"
		/>
	</svg>
);
