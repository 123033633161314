import { List } from 'immutable';

import Video from 'pkg/models/video';

import { RootState } from 'pkg/reducers';

export const findTaggedVideos = (state: RootState): List<Video> =>
	state.userVideos.get('entities').toList();

export const getNextLink = (state: RootState): string =>
	state.userVideos.getIn(['links', 'next']);
