import { ReactNode } from 'react';

export const symbol_east: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M766-450H110q-13 0-21.5-8.5T80-480q0-13 8.5-21.5T110-510h656L579-697q-9-9-9-21t9-21q9-9 21-9t21 9l238 238q5 5 7 10t2 11q0 6-2 11t-7 10L621-221q-9 9-21 9t-21-9q-9-9-9-21t9-21l187-187Z"
		/>
	</svg>
);
