import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as routes from 'pkg/router/routes';
import { Group } from 'pkg/api/models/onboarding_info';
import * as actions from 'pkg/actions';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';

import {
	fetchCurrentAccount,
	useOnboardingContext,
} from 'routes/public/onboarding';
import AccountCard from 'routes/public/styles/AccountCard';
import Button from 'routes/public/styles/Button';
import Title from 'routes/public/styles/Title';
import * as formStyles from 'routes/public/styles/forms/styles.css';
import { useOnboardingState } from 'routes/public/hooks';
import InviteParent from 'routes/public/steps/finished/invite-parent';
import Divider from 'routes/public/styles/Divider';
import { useFetchAccountRelations } from 'routes/public/steps/Start';

import Column from 'components/layout/column';
import { Spinner } from 'components/loaders/spinner';

export default function WardFinished(): JSX.Element {
	const { goTo, resetStore, account, setAccount } = useOnboardingContext();
	const onboardingState = useOnboardingState();

	const groupFromState = onboardingState.get<Group>('group');
	const returnToForms = onboardingState.get<string>('returnToForms');

	const { wards } = useFetchAccountRelations();

	const wardAccount = wards.find(
		(accountRelation) => accountRelation.approvedAt
	)?.targetAccount;

	useComponentDidMount(async () => {
		const [ok, parentAccount] = await fetchCurrentAccount();

		if (ok) {
			setAccount(parentAccount);
		} else {
			resetStore();
			goTo('start');
		}
	});

	if (!account && !wardAccount) {
		return <Spinner />;
	}

	const save = async () => {
		if (account.id) {
			const hasReturnToForms = returnToForms;

			resetStore();

			if (hasReturnToForms) {
				window.history.pushState(
					{},
					'',
					routes.Registration.Form(
						groupFromState.organizationSlug,
						onboardingState.get<string>('formGUID')
					)
				);
				window.location.reload();
			} else {
				window.history.pushState({}, '', '/');
				window.location.reload();
			}
		} else {
			resetStore();

			goTo('start');

			actions.flashes.show({
				title: t('Something went wrong'),
				message: t(
					"If you think this error isn't supposed to happen, please contact 360Player support."
				),
			});
		}
	};
	let buttonLabel = '';

	if (returnToForms) {
		buttonLabel = 'Continue to registration';
	} else if (groupFromState?.isOrganization) {
		buttonLabel = t('Continue');
	} else {
		buttonLabel = t('Take me to the team!');
	}

	const handleGoToEmailInvite = () => goTo('parent-email-invite');

	return (
		<Column spacing={styles.spacing._8}>
			<Title
				title={returnToForms ? t('Account created') : t('Success!')}
				description={
					returnToForms
						? t(
								'Your account is now created and you may continue with the registration'
							)
						: t('You are now connected')
				}
			/>

			<Column spacing={styles.spacing._4}>
				<span className={formStyles.label}>{t('Your account')}</span>
				<AccountCard account={account} />
			</Column>

			<Column spacing={styles.spacing._4}>
				<span className={formStyles.label}>{t('Child account')}</span>
				<AccountCard account={wardAccount} />
			</Column>

			<Title title={t('Next step')} />
			<Button primary label={buttonLabel} onClick={save} />

			<Divider />

			<InviteParent
				account={account}
				wardAccount={wardAccount}
				handleGoToEmailInvite={handleGoToEmailInvite}
			/>
		</Column>
	);
}
