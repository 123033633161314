import { ReactNode } from 'react';

export const symbol_foot_bones: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M160-170q-29 0-49.5-20.5T90-240v-40q0-29 20.5-49.5T160-350q29 0 49.5 20.5T230-280v40q0 29-20.5 49.5T160-170Zm682 0h-56q-8 0-12.5-6t-2.5-13l9-32q2-5 6-8.5t10-2.5l50 7q11 2 17.5 9t6.5 18q0 11-8.5 19.5T842-170Zm-150-15-104-35q-6-2-9-8t0-12l26-59q3-6 9-8.5t12 1.5l88 51q5 2 6.5 6.5t.5 9.5l-9 43q-2 7-8 10t-12 1Zm-186-70-73-19q-10-3-16.5-10.5T410-303v-37q0-17 5-33t17-27q5-5 11-6.5t12 1.5l94 52q5 3 6.5 8.5T555-334l-31 71q-2 5-7.5 7.5t-10.5.5Zm-202-35q-6 0-9.5-4.5T289-306q-9-48-44.5-76T160-410h-20q-5 0-7.5-2.5T130-420q0-5 2.5-7.5t7.5-2.5h36q21 0 41-5.5t37-16.5q3-2 6-2t6 2q21 13 44 17.5t40 4.5q9 0 14 7t1 15q-8 16-11.5 33.5T350-340v35q0 6-4.5 10.5T335-290h-31ZM180-490q-38 0-64-25t-26-62q0-17 6.5-33t18.5-28l55-55v-77q0-13 8.5-21.5T200-800q13 0 21.5 8.5T230-770v74q0 14-5.5 26.5T209-647l-51 52q-4 4-6 8.5t-2 9.5q0 11 8 19t19 8q8 0 23-11 13-11 28-18t32-7q17 0 32 7t28 18q5 5 10.5 8t12.5 3q11 0 19-8t8-19q0-5-2-9.5t-6-8.5l-51-52q-10-10-15.5-22.5T290-696v-74q0-13 8.5-21.5T320-800q13 0 21.5 8.5T350-770v77l55 55q12 12 18.5 28t6.5 33q0 37-26 62t-64 25q-17-1-31.5-8T281-516q-5-4-10-7t-11-3q-7 0-21 10-13 11-27.5 18t-31.5 8Zm80-310Z"
		/>
	</svg>
);
