import { useContext, useRef } from 'react';
import { t } from '@transifex/native';

import store from 'pkg/store/createStore';
import * as actions from 'pkg/actions';
import * as models from 'pkg/api/models';
import { UserAddressTypes } from 'pkg/api/models/user_address';

import useContactForm from 'routes/payments/hooks/useContactForm';
import { AddOrderContext } from 'routes/payments/orders/create';
import { generatePayload } from 'routes/payments/contacts/Create';

import StepModal, { Step } from 'components/step-modal';

import Form, { FormPayload } from 'components/form/Form';

interface NewContactProps {
	hideModal: () => void;
	groupId: number;
}

const NewContact: React.FC<React.PropsWithChildren<NewContactProps>> = ({
	hideModal,
	groupId,
}) => {
	const formRef = useRef(null);
	const OrderContext = useContext(AddOrderContext);

	const {
		contactData,
		billingData,
		billingContactData,
		DetailsSection,
		UserGroupsSection,
		BillingUserSection,
		BillingAddressSection,
		Modals,
	} = useContactForm({ groupId, emailRequired: true });

	const handleNext = async () => {
		return await formRef.current.dispatchEvent(
			new Event('submit', {
				cancelable: true,
				bubbles: true,
			})
		);
	};

	const handleSave = async (data: FormPayload) => {
		const addBillingUser = billingData.firstName && billingData.email;

		let billingUserId: number = contactData.billingUserId;

		if (addBillingUser) {
			const [, billingResult] = await actions.users.createUser(
				generatePayload(billingData as FormPayload, billingContactData, groupId)
			)(store.dispatch);

			if (billingResult) {
				billingUserId = billingResult.id;
			}
		}

		const result = await actions.users.createUser(
			generatePayload(data, { ...contactData, billingUserId }, groupId)
		)(store.dispatch);

		const billingAddressData = data.billingAddress as FormPayload;

		if (result) {
			if (billingAddressData?.email) {
				await models.userAddress.create({
					...billingAddressData,
					userId: result[1].id,
					type: UserAddressTypes.Billing,
				});
			}

			OrderContext.setFormState({
				assignedContacts: [
					...OrderContext.formState.assignedContacts,
					result[1],
				],
			});

			hideModal();
			return true;
		}
	};

	return (
		<StepModal wide onClose={hideModal}>
			<Step title={t('New contact')} onNext={handleNext}>
				<Form onSubmit={handleSave} formRef={formRef} stopPropagation>
					{DetailsSection}
					{UserGroupsSection}
					{BillingAddressSection}
					{BillingUserSection}
					{Modals}
				</Form>
			</Step>
		</StepModal>
	);
};

export default NewContact;
