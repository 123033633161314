import { t } from '@transifex/native';
import { cloneElement, Fragment, SyntheticEvent, useState } from 'react';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import { flattenGroups } from 'pkg/api/models/group';

import * as Input from 'components/form/inputs';
import Row from 'components/layout/row';

import * as Table from 'design/table';

import * as css from './styles.css';

interface SearchRowProps {
	select: (groupId: number) => void;

	selectedGroups: number[];

	group: models.group.Group;
	groups: models.group.Group[];

	extraColumns: Table.ExtraColumn[];
}

const SearchRow = ({
	group,
	groups,
	select,
	selectedGroups,
	extraColumns,
}: SearchRowProps) => {
	const parent = groups.find((g) => g.id === group.parentGroupId);

	const handleClick = (e: SyntheticEvent<HTMLDivElement>) => {
		e.stopPropagation();

		select(group.id);
	};

	const columnComponents = (
		<Fragment>
			{extraColumns.map((c, i) => (
				<Table.Cell key={i} onClick={handleClick}>
					{cloneElement(c.component, {
						group,
					})}
				</Table.Cell>
			))}
		</Fragment>
	);

	return (
		<Table.Row>
			<Table.Cell onClick={handleClick}>
				<Row spacing={styles.spacing._2} autoColumns="auto" align="center">
					<div>
						<Input.Control
							type="checkbox"
							checked={selectedGroups.includes(group.id)}
						/>
					</div>
					<span>
						{group.name}{' '}
						{parent && <span className={css.parents}>({parent.name})</span>}
					</span>
				</Row>
			</Table.Cell>
			{columnComponents}
		</Table.Row>
	);
};

interface GroupRowProps {
	level?: number;
	selectedGroups: number[];

	group: models.group.Group;
	groups: models.group.Group[];

	select: (groupId: number) => void;

	extraColumns: Table.ExtraColumn[];
}

const GroupRow = ({
	group,
	groups = [],
	level = 0,
	selectedGroups,
	select,
	extraColumns,
}: GroupRowProps) => {
	const [isExpanded, setIsExpanded] = useState(true);

	const handleTraverse = () => {
		setIsExpanded(!isExpanded);
	};

	const childGroups = group.children;

	const handleClick = (e: SyntheticEvent<HTMLDivElement>) => {
		e.stopPropagation();

		select(group.id);
	};

	const columnComponents = (
		<Fragment>
			{extraColumns.map((c, i) => (
				<Table.Cell key={i} onClick={handleClick}>
					{cloneElement(c.component, {
						group,
					})}
				</Table.Cell>
			))}
		</Fragment>
	);

	return (
		<Fragment>
			<Table.ExpandableRow
				onClick={handleTraverse}
				isExpanded={isExpanded}
				canExpand={childGroups.length > 0}
				level={level}>
				<Table.Cell onClick={handleClick}>
					<Row spacing={styles.spacing._2} autoColumns="auto" align="center">
						<div>
							<Input.Control
								type="checkbox"
								checked={selectedGroups.includes(group.id)}
							/>
						</div>
						<span>{group.name}</span>
					</Row>
				</Table.Cell>
				{columnComponents}
			</Table.ExpandableRow>
			{isExpanded &&
				childGroups.length > 0 &&
				childGroups.map((g) => (
					<GroupRow
						key={g.id}
						group={g}
						groups={groups}
						level={level + 1}
						selectedGroups={selectedGroups}
						select={select}
						extraColumns={extraColumns}
					/>
				))}
		</Fragment>
	);
};

interface GroupsTableProps {
	search?: string;
	selected?: number[];

	select?: (id: number) => void;

	groups: models.group.Group[];

	extraColumns?: Table.ExtraColumn[];
}

const GroupsTable = ({
	groups = [],
	search = '',
	select,
	selected,
	extraColumns = [],
}: GroupsTableProps) => {
	// We need to flatten the groups that's passed in order for the search
	// to work as intended.
	const flattenedGroups = flattenGroups(groups);

	const additionalColumns = extraColumns.map((c) => ({
		content: c.content,
		width: c.width,
		onClick: c.onClick,
		hide: c.hide,
		align: c.align,
	}));

	const columns = [
		{
			content: t(`Name`),
		},
		...additionalColumns,
		{
			content: '',
			width: 'max-content',
			hide: !!search,
		},
	];

	const hasUnsortedGroups = groups.some(
		(group: models.group.Group) => group.order === -1
	);

	if (hasUnsortedGroups) {
		groups = groups.sort((a: models.group.Group, b: models.group.Group) =>
			a.name.localeCompare(b.name)
		);
	} else {
		groups = groups.sort(
			(a: models.group.Group, b: models.group.Group) => a.order - b.order
		);
	}

	return (
		<Table.Table columns={columns}>
			{search
				? flattenedGroups
						.filter((g) =>
							g.name
								.toLocaleLowerCase()
								.includes(search.toString().toLocaleLowerCase())
						)
						.map((g) => (
							<SearchRow
								key={g.id}
								group={g}
								select={select}
								selectedGroups={selected}
								extraColumns={extraColumns}
								groups={flattenedGroups}
							/>
						))
				: groups.map((g) => (
						<GroupRow
							key={g.id}
							group={g}
							groups={groups}
							selectedGroups={selected}
							select={select}
							extraColumns={extraColumns}
						/>
					))}
		</Table.Table>
	);
};

export default GroupsTable;
