import { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { t } from '@transifex/native';
import { useMediaQuery } from 'react-responsive';

import * as styles from 'pkg/config/styles';

import {
	fetchGroupActivities,
	fetchActivitiesPage,
} from 'pkg/actions/activities';

import * as selectors from 'pkg/selectors';

import * as Card from 'components/Card';
import { Trigger } from 'components/ScrollSpy';
import SectionTitle from 'components/SectionTitle';
import AssetImage from 'components/AssetImage';

import { PlaceholderType, Skeleton } from 'components/loaders/skeleton';
import SingleActivity from 'components/user/activity/Single';
import Column from 'components/layout/column';

import * as css from './styles.css';

const LatestActivities = ({ groupId }: { groupId: number }) => {
	const [fetching, setFetching] = useState(false);
	const dispatch = useDispatch();

	const activities = useSelector((state) =>
		selectors.activities.getActivities(state)
	).sort((a, b) => b.completedAt - a.completedAt);

	const nextLink = useSelector(
		(state) => selectors.activityList.getActivitiesLinks(state)?.next
	);

	const loadMoreActivities = async (e: IntersectionObserverEntry) => {
		if (!nextLink || !e.isIntersecting) {
			return;
		}
		setFetching(true);
		await dispatch(fetchActivitiesPage(nextLink));
		setFetching(false);
	};

	useEffect(() => {
		(async () => {
			await dispatch(fetchGroupActivities(groupId, false, true));
		})();
	}, [dispatch, groupId]);

	const isSmallScreen = useMediaQuery({
		maxWidth: styles.breakpoint.toMedium,
	});

	if (isSmallScreen && activities.length == 0) {
		return null;
	}

	const content = (
		<Fragment>
			<div className={css.activitygrid}>
				{activities.map((activity) => (
					<SingleActivity
						key={activity.id}
						id={activity.id}
						activity={activity}
						comments={activity.comments}
					/>
				))}
				{fetching && <Skeleton placeholderType={PlaceholderType.FeedItem} />}
				{nextLink && (
					<Trigger
						key={nextLink}
						offsetY={-10}
						onTrigger={loadMoreActivities}
					/>
				)}
			</div>
		</Fragment>
	);

	const emptyState = (
		<div className={css.activityEmptyState}>
			<div>
				<AssetImage src="img/missing-entities/match-statistics.svg" />
				<Column spacing={styles.spacing._3}>
					<div className={css.emptystateTitle}>
						{t('No exercise data found!')}
					</div>
				</Column>
			</div>
		</div>
	);

	return (
		<Column>
			<SectionTitle>{t('Latest activities')}</SectionTitle>
			<Card.Base>
				<Card.Body>{activities.length !== 0 ? content : emptyState}</Card.Body>
			</Card.Base>
		</Column>
	);
};

export default LatestActivities;
