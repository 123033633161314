import { ReactNode } from 'react';

export const symbol_remember_me: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M260-40q-24.75 0-42.375-17.625T200-100v-760q0-24.75 17.625-42.375T260-920h440q24.75 0 42.375 17.625T760-860v760q0 24.75-17.625 42.375T700-40H260Zm0-90v30h440v-30H260Zm0-700h440v-30H260v30Zm220 530q-59.538 0-115.769 18Q308-264 260-235v45h440v-45q-48-29-104.231-47Q539.538-300 480-300Zm0-60q60 0 115.5 15.5T700-302v-468H260v468q50-27 105-42.5T480-360Zm.765-63Q529-423 563-456.765q34-33.764 34-82Q597-587 563.235-621q-33.764-34-82-34Q433-655 399-621.235q-34 33.764-34 82Q365-491 398.765-457q33.764 34 82 34Zm.235-60q-23 0-39.5-16.5T425-539q0-23 16.5-39.5T481-595q23 0 39.5 16.5T537-539q0 23-16.5 39.5T481-483Zm-1 293h220-440 220Zm1-349Zm-1-291Zm0 700Z"
		/>
	</svg>
);
