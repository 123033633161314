import { ReactNode } from 'react';

export const symbol_roundabout_left: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M610-150v-212q0-23 13.5-40t36.5-20q69-8 114.5-58.5T820-600q0-75-52.5-127.5T640-780q-69 0-120 46t-59 115q-3 20-21.5 34.5T401-570H194l69 69q9 9 9 21t-9 21q-9 9-21 9t-21-9L101-579q-5-5-7-10t-2-11q0-6 2-11t7-10l120-120q9-9 21-9t21 9q9 9 9 21t-9 21l-69 69h208q11-91 79.5-150.5T640-840q100 0 170 70t70 170q0 90-59.5 158.5T670-362v212q0 13-8.5 21.5T640-120q-13 0-21.5-8.5T610-150Z"
		/>
	</svg>
);
