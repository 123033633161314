import { Fragment } from 'react';
import { T, useT } from '@transifex/react';

import { eventTypeToTranslatedString } from 'pkg/models/event';

import Link from 'pkg/router/Link';
import * as models from 'pkg/api/models';
import { cssClasses } from 'pkg/css/utils';
import DateTime, { Granularity } from 'pkg/datetime';
import { isSameDay } from 'pkg/date';
import * as routes from 'pkg/router/routes';
import { useCurrentOrganization, useCurrentUser } from 'pkg/identity';

import { getCalendarEventColorVars } from 'routes/group/calendar/config';
import EventUserActions from 'routes/event/user-actions';
import MultipleEventUsersActions from 'routes/event/user-actions/multiple-user-actions';

import Icon from 'components/icon';
import Avatar from 'components/avatar';

import AvatarStack from 'components/avatar/avatar-stack';

import * as css from './styles.css';

interface DashboardEventProps {
	isPending: boolean;
	event: models.event.Event;
	// User id of event user we want to search for
	userIds: number[];

	refresh: () => Promise<void>;
}

export default function DashboardEvent({
	isPending = false,
	userIds,
	event,
	refresh,
}: DashboardEventProps) {
	const t = useT();
	const org = useCurrentOrganization();
	const user = useCurrentUser();

	const eventUsers = event?.users
		.filter((u) => userIds.includes(u.userId))
		.filter((eu) => eu.status === models.eventUser.EventUserStatus.Pending);
	const startsAtDateTime = DateTime.fromTimestamp(event.startsAt);
	const endsAtDateTime = DateTime.fromTimestamp(event.endsAt);

	const startsAtDate = new Date(event.startsAt * 1000);

	const isNotSameDay = !isSameDay(startsAtDate, endsAtDateTime);

	const startDate = DateTime.fromTimestamp(event?.startsAt);
	const calendarEventStyles = getCalendarEventColorVars(event.type);

	const formattedDate = startDate.toLocaleDateString({ day: 'numeric' });
	const formattedMonth = startDate.toLocaleDateString({ month: 'short' });
	const formattedDay = startDate.toLocaleDateString({ weekday: 'short' });

	const hasMultipleEventUsers = eventUsers.length > 1;

	const meetingTime = startsAtDateTime.prev(
		Granularity.minute,
		event.meetBeforeMinutes
	);

	const { id, title, location } = event;

	let actions = null;

	if (hasMultipleEventUsers) {
		actions = (
			<div className={cssClasses(css.actionWrapper)}>
				<AvatarStack users={eventUsers.map((eu) => eu.user)} threshold={3} />
				<MultipleEventUsersActions
					event={event}
					eventUsers={eventUsers}
					refresh={refresh}
				/>
			</div>
		);
	} else if (eventUsers.length === 1) {
		const eventUser = eventUsers[0];
		const isEventUser = eventUser.userId === user.id;

		actions = (
			<div
				className={cssClasses(
					css.actionWrapper,
					isEventUser ? css.isSelf : ''
				)}>
				{!isEventUser && (
					<div className={css.avatar}>
						<Avatar user={eventUser.user} size={24} />
						<div className={css.userName}>{eventUser.user.firstName}</div>
					</div>
				)}
				<EventUserActions
					eventUser={eventUser}
					refresh={refresh}
					event={event}
					rowStyles={{
						justifyItems: 'end',
						gridAutoColumns: 'max-content',
						justifyContent: 'flex-end',
					}}
				/>
			</div>
		);
	}

	return (
		<div
			className={cssClasses(css.eventWrapper, isPending ? css.isPending : '')}
			style={{
				...calendarEventStyles,
			}}>
			<Link
				className={cssClasses(css.eventLink, isPending ? css.isPending : '')}
				style={{
					...calendarEventStyles,
				}}
				href={routes.Event.View(org.id, id, 'overview')}>
				<div className={css.eventHeader}>
					<div
						className={css.type}
						style={{
							...calendarEventStyles,
						}}>
						{eventTypeToTranslatedString(event.type)}
					</div>
					<div className={css.groupName}>{event.group?.name}</div>
				</div>
				<div className={css.eventContent}>
					<div className={css.eventDate}>
						<div className={css.dateNumber}>{formattedDate}</div>
						<div className={css.month}>{formattedMonth}</div>
					</div>
					<div className={css.eventInfo}>
						<div className={css.eventTitle}>
							{models.event.hasEventSeries(event) && (
								<Icon size={0.9} name="sync" />
							)}

							{models.event.isCanceled(event) ? (
								<Fragment>
									<span className={css.allCaps}>{t('Canceled')} </span>
									<span className={css.strikeThrough}>{title}</span>
								</Fragment>
							) : (
								title
							)}
						</div>
						<div className={css.eventTimeWrapper}>
							<div className={css.eventTime}>
								<span>
									{formattedDay}{' '}
									{startsAtDateTime.toLocaleTimeString({
										hour: '2-digit',
										minute: '2-digit',
									})}
									–
									{endsAtDateTime.toLocaleTimeString({
										hour: '2-digit',
										minute: '2-digit',
									})}
									{isNotSameDay && (
										<Fragment>
											{' '}
											(
											{endsAtDateTime.toLocaleDateString({
												day: 'numeric',
												month: 'short',
											})}
											)
										</Fragment>
									)}
								</span>
							</div>
							{event.meetBeforeMinutes > 0 && (
								<div className={css.meetTime}>
									{t('Meet time {time}', {
										time: meetingTime.toTimeString(),
									})}
								</div>
							)}
						</div>

						<div className={css.eventLocation}>
							<Icon name="location" />
							{location}
						</div>
					</div>
				</div>
				{event.rsvpBefore && (
					<div
						className={css.rsvpWrapper}
						style={{
							...calendarEventStyles,
						}}>
						<Fragment>
							<Icon name="notification-outline" />
							<T
								_str="{bold} {date}"
								date={DateTime.fromTimestamp(
									event.rsvpBefore
								).toLocaleDateString({
									hour: 'numeric',
									minute: 'numeric',
									day: 'numeric',
									month: 'short',
								})}
								bold={
									<span className={css.rsvpText}>
										<T _str="RSVP BEFORE:" />
									</span>
								}
							/>
						</Fragment>
					</div>
				)}
			</Link>
			{actions}
		</div>
	);
}
