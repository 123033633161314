import { t } from '@transifex/native';
import styled from 'styled-components';

import * as styles from 'pkg/config/styles';

import Link from 'pkg/router/Link';
import DateTime from 'pkg/datetime';
import * as routes from 'pkg/router/routes';
import { useCurrentOrganization } from 'pkg/identity';

import { TableBodyCell } from 'components/group/statistics/Table';

const OpponentWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	text-align: left;
	line-height: 1.1;
	width: 150px;

	@media ${styles.breakpoint.small} {
		width: 120px;
	}
`;

const OpponentName = styled(Link)`
	flex: 0 0 100%;
	padding-bottom: 5px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const OpponentDate = styled.div`
	font-size: 0.7rem;
	margin-right: 4px;
	color: var(--palette-gray-500);
`;

const OpponentLocation = styled.div`
	font-size: 0.7rem;
`;

const Opponent = ({ match }) => {
	const org = useCurrentOrganization();
	const matchId = match.get('id');
	const opponentName = match.get('opponentName');
	const isHome = match.get('isHome');
	const date = new DateTime(
		new Date(match.get('startsAt') * 1000)
	).toLocaleDateString();

	return (
		<TableBodyCell isSticky>
			<OpponentWrapper>
				<OpponentName
					href={routes.Match.View(org.id, match.eventId, matchId, 'summary')}>
					{opponentName || t(`Opponent`)}
				</OpponentName>
				<OpponentDate>{date}</OpponentDate>
				<OpponentLocation>{isHome ? t(`Home`) : t(`Away`)}</OpponentLocation>
			</OpponentWrapper>
		</TableBodyCell>
	);
};

export default Opponent;
