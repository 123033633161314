import { ReactNode } from 'react';

export const symbol_exposure_neg_2: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M810-200H508q-10 0-17-7t-7-17v-36q0-4 7-17l200-204q34-35 49.5-64t15.5-60q0-42-25-66.5T662-696q-29 0-53 11.5T570-653q-8 11-20.5 14.5T525-640q-13-5-19-16t1-22q23-38 64.5-60t90.5-22q71 0 115.5 43T822-605q0 41-19 79t-64 83L563-263l2 3h245q13 0 21.5 8.5T840-230q0 13-8.5 21.5T810-200ZM370-410H150q-13 0-21.5-8.5T120-440q0-13 8.5-21.5T150-470h220q13 0 21.5 8.5T400-440q0 13-8.5 21.5T370-410Z"
		/>
	</svg>
);
