import { ReactNode } from 'react';

export const symbol_pen_size_4: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M236-236.141q-26-26.141-26-64T236-364l360-360q26.177-26 64.089-26Q698-750 724-723.859t26 64Q750-622 724-596L364-236q-26.177 26-64.089 26Q262-210 236-236.141Z"
		/>
	</svg>
);
