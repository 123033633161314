import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import * as routes from 'pkg/router/routes';
import Link from 'pkg/router/Link';
import { useCollection } from 'pkg/api/use_collection';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCurrentOrganization } from 'pkg/identity';

import EmptySession from 'routes/event/single/tabs/overview/EmptySession';

import * as Card from 'components/Card';
import Icon from 'components/icon';
import SectionTitle from 'components/SectionTitle';

import Column from 'components/layout/column';
import Row from 'components/layout/row';

import * as css from './styles.css';

interface SessionProps {
	session: models.session.Session;
}

function Session({ session }: SessionProps): JSX.Element {
	const org = useCurrentOrganization();

	let duration: number = 0;
	let exerciseLength: number = 0;
	if (session.blocks?.length > 0) {
		duration = session.blocks
			.map((block) => block.duration)
			.reduce((a, b) => a + b, 0);
		exerciseLength = session.blocks
			.map((block) => block.items?.length || 0)
			.reduce((a, b) => a + b, 0);
	}

	return (
		<Card.Base>
			<Card.Body $narrowBody>
				<Link href={routes.Event.View(org.id, session.eventId, 'sessions')}>
					<Row columns="1fr auto">
						<Column spacing={styles.spacing._1}>
							<Row className={css.sessionTitle}>{session.title}</Row>
							<Row className={css.infoRow}>
								<div className={css.info}>
									<Icon name="duration" size={1.8} actualSize />
									{duration} {t('min')}
								</div>
								<div className={css.info}>
									<Icon name="collection" size={1.8} actualSize />
									{session.blocks?.length || 0}{' '}
									{session.blocks?.length == 1 ? t('block') : t('blocks')}
								</div>
								<div className={css.info}>
									<Icon name="library" size={1.8} actualSize />
									{exerciseLength}{' '}
									{exerciseLength == 1 ? t('exercise') : t('exercises')}
								</div>
							</Row>
						</Column>
					</Row>
				</Link>
			</Card.Body>
		</Card.Base>
	);
}

interface SessionListProps {
	event: models.event.Event;
}

export default function SessionList({ event }: SessionListProps): JSX.Element {
	const { records: sessions, isLoading } =
		useCollection<models.session.Session>(
			endpoints.Events.ShowTrainingSessions(event.id),
			{ showAllResults: true }
		);

	if (isLoading) {
		return null;
	}

	const content = sessions.map((s) => {
		return <Session key={s.id} session={s} />;
	});

	return (
		(content.length > 0 || models.canEdit(event)) && (
			<Column spacing={styles.spacing._3}>
				<SectionTitle icon="view_list">{t('Session')}</SectionTitle>

				{content.length === 0 && models.canEdit(event) && (
					<EmptySession eventId={event.id} />
				)}
				{content}
			</Column>
		)
	);
}
