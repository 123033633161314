export default {
	name: 'file-generic',
	objects: [
		{
			type: 'path',
			attributes: {
				d: 'M12.8312 4.58048L15.4568 7.16698L18.3233 10.0726H12.8312V4.58048Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M12.8312 4.58048H6.09906V19.6394H18.3233L18.3233 10.0726H17.0233V18.3394H7.39906V5.88048H12.8312V4.58048Z',
			},
		},
	],
};
