import { ReactNode } from 'react';

export const symbol_3g_mobiledata_badge: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M100-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h760q24 0 42 18t18 42v600q0 24-18 42t-42 18H100Zm0-60h760v-600H100v600Zm0 0v-600 600Zm450-100h151q25 0 42-17.625T760-340v-142q0-12.75-8.625-21.375T730-512h-60q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T670-452h31v112H550v-280h210q-3-24-19.45-42T701-680H550q-24.75 0-42.375 17.625T490-620v280q0 24.75 17.625 42.375T550-280Zm-183 0q24.75 0 42.375-17.625T427-340v-83q0-23.333-14-39.667Q399-479 378-479q22 0 35.5-17t13.5-40v-84q0-24.75-17.625-42.375T367-680H230q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T230-620h137v110H232q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T232-450h135v110H230q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T230-280h137Z"
		/>
	</svg>
);
