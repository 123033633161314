import { ReactNode } from 'react';

export const symbol_align_flex_end: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M110-80q-12.75 0-21.375-8.675Q80-97.351 80-110.175 80-123 88.625-131.5T110-140h740q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q880-97 871.375-88.5T850-80H110Zm350-170q-12.75 0-21.375-8.625T430-280v-510q0-12.75 8.625-21.375T460-820h40q12.75 0 21.375 8.625T530-790v510q0 12.75-8.625 21.375T500-250h-40Z"
		/>
	</svg>
);
