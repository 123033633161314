import { useEffect, useRef, useState } from 'react';

type Effect = () => void;

export function usePrevious<T>(value: T): T {
	const valueRef = useRef<T>();

	useEffect(() => {
		valueRef.current = value;
	}, [value]);

	return valueRef.current;
}

export function useForceUpdate() {
	const [, s] = useState<number>(0);
	return () => s((v) => ++v);
}

export function useEffectOnce(effect: Effect): void {
	const destroyEffectRef = useRef<void | Effect>(null);
	const didCallEffectRef = useRef<boolean>(false);
	const renderAfterEffectRef = useRef<boolean>(false);

	const forceUpdate = useForceUpdate();

	if (didCallEffectRef.current) {
		renderAfterEffectRef.current = true;
	}

	useEffect(() => {
		if (!didCallEffectRef.current) {
			destroyEffectRef.current = effect();
			didCallEffectRef.current = true;
		}

		forceUpdate();

		return () => {
			// Ignore dummy useEffect issue with React 18.
			if (!renderAfterEffectRef.current) {
				return;
			}

			if (destroyEffectRef.current) {
				destroyEffectRef.current();
			}
		};
	}, []);
}
