import { ReactNode } from 'react';

export const symbol_swipe_vertical: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M173-120q-62-78-97.5-169T40-480q0-100 35.5-191T173-840h-73q-8 0-14-6t-6-14q0-8 6-14t14-6h130q13 0 21.5 8.5T260-850v130q0 8-6 14t-14 6q-8 0-14-6t-6-14v-113q-65 75-102.5 164.5T80-480q0 99 37.5 188.5T220-127v-113q0-8 6-14t14-6q8 0 14 6t6 14v130q0 13-8.5 21.5T230-80H100q-8 0-14-6t-6-14q0-8 6-14t14-6h73Zm473-6q-20 8-42 7t-42-11L318-243q-10-5-14.5-14.5T302-278v-2q5-19 19-31t34-14l99-8-117-320q-5-12 .5-22.5T355-691q12-5 23 .5t16 17.5l131 357q5 14-3 26.5T499-275l-89 8 178 83q9 4 19 4t19-3l159-58q39-14 56.5-51.5T845-369l-62-169q-5-12 .5-23t17.5-16q12-5 23 .5t16 17.5l61 169q23 63-4.5 122.5T806-185l-160 59ZM505-565q-5-12 .5-22.5T523-603q12-5 22.5.5T561-585l48 132q5 11-.5 22T591-415q-11 5-22-1t-16-17l-48-132Zm132-5q-5-12 .5-23t17.5-16q12-5 22.5.5T693-591l35 94q5 12-1 23t-18 16q-12 4-22.5-1T671-476l-34-94Zm40 226Z"
		/>
	</svg>
);
