import { ReactNode } from 'react';

export const symbol_dirty_lens: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-120q-24 0-42-18t-18-42v-513q0-23 18-41.5t42-18.5h147l55-66q8-11 20-16t26-5h184q14 0 26 5t20 16l55 66h147q23 0 41.5 18.5T880-693v513q0 24-18.5 42T820-120H140Zm0-60h680v-513H645l-73-87H388l-73 87H140v513Zm340-257Zm260 211q13.588 0 23.294-9.706Q773-245.412 773-259q0-13.588-9.706-23.294Q753.588-292 740-292q-13.588 0-23.294 9.706Q707-272.588 707-259q0 13.588 9.706 23.294Q726.412-226 740-226Zm-304-74q9 0 15.5 9.5T458-264q0 17-4 34.5t-4 49.5h68q0-34-6-52.5t-6-37.5q0-17 7.5-25.5T530-304q9 0 17 3t23 11q20 11 33.5 15.5T626-270q21 0 33.5-15.5T672-318q0-14-10-22t-30-16q-17-6-24.5-12t-7.5-14q0-9 12.5-12.5T660-400q30-2 47-8t17-24q0-17-9.5-30.5T690-476q-7 0-36 10-11 5-17 6.5t-11 1.5q-8 0-13-5.5t-5-14.5q0-14 13-25.5t51-28.5q41-18 54.5-32t13.5-28q0-20-17.5-40T690-652q-14 0-26.5 13T622-584q-20 29-34.5 39.5T558-534q-14 0-27-11t-27-41q-12-27-21.5-38.5T458-636q-18 0-30 7.5T416-612q0 9 20 42 9 14 12.5 21t3.5 15q0 8-9.5 14t-18.5 6q-11 0-21-5.5T376-542q-18-17-26-22.5t-20-5.5q-15 0-25.5 10.5T294-538q0 6 2 11.5t8 14.5q6 9 9 15.5t3 12.5q0 9-8 14t-22 10q-17 6-23.5 13t-6.5 19q0 8 2.5 17.5T268-396q10 8 25 9t27 1q23 0 29.5 8.5T356-352q0 12-5 24t-17 26q-14 15-17 21t-3 11q0 14 12.5 23t23.5 9q12 0 22.5-6.5T400-272q15-18 21.5-23t14.5-5Z"
		/>
	</svg>
);
