import { ReactNode } from 'react';

export const symbol_kebab_dining: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M280-40q-8 0-14-6t-6-14v-150h-50q-37 0-63.5-26.5t-26.5-64q0-37.5 26.5-63.5t63.5-26h50v-60H150q-12.75 0-21.375-8.625T120-480v-120q0-12.75 8.625-21.375T150-630h110v-60h-50q-37 0-63.5-26.5t-26.5-64q0-37.5 26.5-63.5t63.5-26h50v-30q0-8 6-14t14-6q8 0 14 6t6 14v30h50q37.8 0 63.9 26.1T440-780q0 37-26.1 63.5T350-690h-50v60h110q12.75 0 21.375 8.625T440-600v120q0 12.75-8.625 21.375T410-450H300v60h50q37.8 0 63.9 26.1T440-300q0 37-26.1 63.5T350-210h-50v150q0 8-6 14t-14 6Zm400 0q-8 0-14-6t-6-14v-150h-50q-37 0-63.5-26.5t-26.5-64q0-37.5 26.5-63.5t63.5-26h50v-60H550q-12.75 0-21.375-8.625T520-480v-120q0-12.75 8.625-21.375T550-630h110v-60h-50q-37 0-63.5-26.5t-26.5-64q0-37.5 26.5-63.5t63.5-26h50v-30q0-8 6-14t14-6q8 0 14 6t6 14v30h50q37.8 0 63.9 26.1T840-780q0 37-26.1 63.5T750-690h-50v60h110q12.75 0 21.375 8.625T840-600v120q0 12.75-8.625 21.375T810-450H700v60h50q37.8 0 63.9 26.1T840-300q0 37-26.1 63.5T750-210h-50v150q0 8-6 14t-14 6ZM210-750h140q13 0 21.5-9t8.5-21q0-13-8.5-21.5T350-810H210q-12 0-21 8.5t-9 21q0 12.5 9 21.5t21 9Zm400 0h140q13 0 21.5-9t8.5-21q0-13-8.5-21.5T750-810H610q-12 0-21 8.5t-9 21q0 12.5 9 21.5t21 9ZM180-510h200v-60H180v60Zm400 0h200v-60H580v60ZM210-270h140q13 0 21.5-9t8.5-21q0-13-8.5-21.5T350-330H210q-12 0-21 8.5t-9 21q0 12.5 9 21.5t21 9Zm400 0h140q13 0 21.5-9t8.5-21q0-13-8.5-21.5T750-330H610q-12 0-21 8.5t-9 21q0 12.5 9 21.5t21 9ZM180-750v-60 60Zm400 0v-60 60ZM180-510v-60 60Zm400 0v-60 60ZM180-270v-60 60Zm400 0v-60 60Z"
		/>
	</svg>
);
