const icon = {
	name: 'video-playlist',
	viewBox: '0 0 26 22',
	objects: [
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M10.106 12.6139H19.2342V11.5399H10.106V12.6139ZM10.106 14.7617H19.2342V13.6878H10.106V14.7617ZM10.106 10.466H19.2342V9.39212H10.106V10.466ZM10.106 7.24429V8.31821H19.2342V7.24429H10.106Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M8.23437 10.7422L2.59633 13.9974L2.59633 7.48712L8.23437 10.7422Z',
			},
		},
	],
};

export default icon;
