import { ReactNode } from 'react';

export const symbol_grid_view: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-510q-24.75 0-42.375-17.625T120-570v-210q0-24.75 17.625-42.375T180-840h210q24.75 0 42.375 17.625T450-780v210q0 24.75-17.625 42.375T390-510H180Zm0 390q-24.75 0-42.375-17.625T120-180v-210q0-24.75 17.625-42.375T180-450h210q24.75 0 42.375 17.625T450-390v210q0 24.75-17.625 42.375T390-120H180Zm390-390q-24.75 0-42.375-17.625T510-570v-210q0-24.75 17.625-42.375T570-840h210q24.75 0 42.375 17.625T840-780v210q0 24.75-17.625 42.375T780-510H570Zm0 390q-24.75 0-42.375-17.625T510-180v-210q0-24.75 17.625-42.375T570-450h210q24.75 0 42.375 17.625T840-390v210q0 24.75-17.625 42.375T780-120H570ZM180-570h210v-210H180v210Zm390 0h210v-210H570v210Zm0 390h210v-210H570v210Zm-390 0h210v-210H180v210Zm390-390Zm0 180Zm-180 0Zm0-180Z"
		/>
	</svg>
);
