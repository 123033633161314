import { ReactNode } from 'react';

export const symbol_sensors_krx_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m687-359-44-44q8-17 12.5-37t4.5-40q0-75-52.5-127.5T480-660q-20 0-40 4.5T403-643l-44-44q27-16 57.5-24.5T480-720q100 0 170 70t70 170q0 33-8.5 63.5T687-359Zm-207 59q20 0 39-4t36-12L316-555q-8 17-12 36t-4 39q0 75 52.5 127.5T480-300ZM794-78 599-272q-26 15-56 23.5t-63 8.5q-100 0-170-70t-70-170q0-33 8.5-63t23.5-56L87-784q-9-9-9-21.5t9-21.5q9-9 21.5-9t21.5 9l707 707q9 9 8.5 21T836-78q-9 9-21 9t-21-9ZM514-532Zm-70 105Zm-304-53q0 45 11 86t31 77q6 11 4 23.5T173-274q-11 8-23.5 4.5T131-284q-25-44-38-93T80-480q0-54 13-102.5t38-92.5q7-11 19-15t23 4q11 7 13 19.5t-4 23.5q-20 36-31 77t-11 86Zm679 0q0-45-11-86t-31-77q-6-11-4-23.5t13-19.5q11-8 23-4t19 15q25 44 38 92.5T879-480q0 54-13 103t-38 93q-7 11-19 14.5t-23-4.5q-11-7-13-19.5t4-23.5q20-36 31-77t11-86Z"
		/>
	</svg>
);
