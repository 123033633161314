import { MouseEvent, TouchEvent, useRef, useState, useCallback } from 'react';
import styled, { css } from 'styled-components';

import * as styles from 'pkg/config/styles';

import { useEventListener } from 'pkg/hooks/events';
import {
	clipOffset,
	timelineOffsetToTime,
	timelineOffsetToTimestamp,
} from 'pkg/video';
import rgba from 'pkg/rgba';
import { isTouchDevice } from 'pkg/platform';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import { usePlaybackState } from 'components/video-analytics/PlaybackState';
import { usePlayerState } from 'components/video-analytics/PlayerState';
import { CueType, useCueState } from 'components/video-analytics/CueState';

const Wrapper = styled.svg<{ disabled?: boolean }>`
	padding: 0 5px;
	width: 100%;
	height: 10px;
	position: relative;
	overflow: visible;
	z-index: ${styles.zIndex.videoAnalytics + 100};
	user-select: none;

	${({ disabled }) =>
		disabled &&
		css`
			opacity: 0.25;
			pointer-events: none;
		`}
`;

interface TrackProps {
	scope: string;
	color: string;
	start?: number;
	offset: number;
	rounded?: boolean;
}

const TrackAttrs = ({
	start = 0,
	offset,
	color,
	rounded = true,
}: TrackProps) => {
	let slc: 'inherit' | 'round' | 'butt' | 'square' = 'square';
	if (rounded) {
		slc = 'round';
	}
	return {
		x1: start + '%',
		x2: offset + '%',
		stroke: color,
		strokeLinecap: slc,
	};
};

const Track = styled.line.attrs<TrackProps>(TrackAttrs)<TrackProps>``;

Track.defaultProps = {
	x1: 0,
	y1: '50%',
	x2: 0,
	y2: '50%',
	strokeWidth: 10,
};

interface PointerProps {
	color: string;
	offset: number;
}

const PointerAttrs = ({ offset, color }: PointerProps) => ({
	x1: offset + '%',
	x2: offset + '%',
	stroke: color,
});

const Pointer = styled.line.attrs<PointerProps>(PointerAttrs)<PointerProps>``;

Pointer.defaultProps = {
	y1: -1,
	y2: 11,
	strokeWidth: 2,
	strokeLinecap: 'round',
};

interface ThumbProps {
	scope: string;
	offset: number;
	size?: number;
}

const ThumbAttrs = ({ offset, size = 6 }: ThumbProps) => ({
	cx: offset + '%',
	r: size,
});

const Thumb = styled.circle.attrs<ThumbProps>(ThumbAttrs)<ThumbProps>`
	stroke-width: 1px;
	stroke: ${rgba(styles.palette.black, 0.3)};
`;

Thumb.defaultProps = {
	cy: '50%',
};

const CuePoints = styled.g``;

interface CueProps {
	offset: number;
	dimmed: boolean;
	highlight?: boolean;
	disabled?: boolean;
}

const CueAttrs = ({ offset, highlight, dimmed, disabled }: CueProps) => {
	let fill: string = highlight
		? styles.palette.yellow[500]
		: styles.palette.green[500];

	if (disabled) {
		fill = styles.palette.gray[600];
	}

	if (dimmed) {
		fill = styles.palette.gray[600];
	}

	return {
		cx: `${offset}%`,
		fill,
		strokeWidth: 1,
		stroke: dimmed ? styles.palette.gray[500] : styles.palette.gray[700],
	};
};

const Cue = styled.circle.attrs<CueProps>(CueAttrs)<CueProps>``;

Cue.defaultProps = {
	cy: '50%',
	r: 3,
};

interface TooltipProps {
	offset: number;
	width: number;
}

const TooltipAttrs = ({ offset, width }: TooltipProps) => ({
	x: offset + '%',
	width,
});

const Tooltip = styled.svg.attrs<TooltipProps>(TooltipAttrs)<TooltipProps>`
	overflow: visible;
`;

Tooltip.defaultProps = {
	height: 21,
	y: '-30',
};

interface TimestampTooltipProps extends TooltipProps {
	timestamp: string;
}

function TimestampTooltip({
	timestamp,
	width,
	offset,
}: TimestampTooltipProps): JSX.Element {
	return (
		<Tooltip width={width} offset={offset}>
			<svg width="100%" height="100%" x="-50%">
				<rect
					width="100%"
					height="100%"
					rx={4}
					fill={rgba(styles.palette.gray[800], 0.9)}
					x={0}
				/>
				<text
					x="50%"
					y="55%"
					fill={styles.palette.white}
					textAnchor="middle"
					dominantBaseline="middle"
					fontSize={styles.font.size.xs}>
					{timestamp}
				</text>
			</svg>
		</Tooltip>
	);
}

export enum TimelineContext {
	Video = 'video',
	Playlist = 'playlist',
}

interface TimelineProps {
	startsAt?: number;
	endsAt: number;

	context?: TimelineContext;
	plotCuePoints?: boolean;
	disabled?: boolean;
}

export default function Timeline({
	startsAt = 0,
	endsAt,

	context = TimelineContext.Video,
	plotCuePoints = true,
	disabled,
}: TimelineProps): JSX.Element {
	const wrapperRef = useRef();
	const [offset, setOffset] = useState<number>(0);
	const [lookAhead, setLookAhead] = useState<boolean>(false);

	const { cuePoints } = useCueState();
	const { currentTime, setCurrentTime } = usePlaybackState();
	const {
		isPlaying,
		isScrubbing,
		setScrubbing,
		isRecording,
		setGaplessPlayback,
		controller,
	} = usePlayerState();

	let elapsedOffset: number = clipOffset(startsAt, endsAt, currentTime);

	if (elapsedOffset < 0) {
		elapsedOffset = 0;
	} else if (elapsedOffset >= 100) {
		elapsedOffset = 100;
	}

	const pointerOffset: number = Math.abs(offset);

	const timestamp: string = timelineOffsetToTimestamp(
		pointerOffset,
		0,
		endsAt - startsAt
	);

	const longTimestamp: boolean = timestamp.split(':').length === 3;

	const calcOffset = useCallback(
		(target: HTMLElement, offset: number): number => {
			const rect = target.getBoundingClientRect();
			const left: number = offset - Math.floor(rect.left);

			let calculatedOffset: number = Number.parseFloat(
				((left / rect.width) * 100).toPrecision(3)
			);

			if (calculatedOffset <= 0) {
				calculatedOffset = 0;
			} else if (calculatedOffset >= 100) {
				calculatedOffset = 100;
			}

			return calculatedOffset;
		},
		[]
	);

	const setRelativeOffset = useCallback(
		(target: HTMLElement, offset: number) =>
			setOffset(calcOffset(target, offset)),
		[setOffset, calcOffset]
	);

	const seekToNearestFraction = useCallback(
		async (target: HTMLElement, offset: number) => {
			const nearestFraction: number = timelineOffsetToTime(
				calcOffset(target, offset),
				startsAt,
				endsAt
			);

			await controller.seekTo(nearestFraction);

			setCurrentTime(nearestFraction);

			if (context === TimelineContext.Video) {
				setGaplessPlayback(false);
			}

			if (!isPlaying) {
				await controller.play();
			}

			deactivateScrubbing();
		},
		[calcOffset, startsAt, endsAt, controller]
	);

	const activateScrubbing = () => setScrubbing(true);

	const deactivateScrubbing = () => setScrubbing(false);

	let wrapperProps, thumbProps;

	if (isTouchDevice()) {
		wrapperProps = {
			onTouchEnd: (event: TouchEvent) => {
				const offset: number = event.changedTouches[0]?.clientX ?? 0;

				seekToNearestFraction(wrapperRef.current, offset);
				deactivateScrubbing();
			},
		};

		thumbProps = {
			onTouchStart: activateScrubbing,
			onTouchEnd: deactivateScrubbing,
			onTouchMove: (event: TouchEvent) => {
				const offset: number = event.changedTouches[0]?.clientX ?? 0;

				setRelativeOffset(wrapperRef.current, offset);
			},
		};
	} else {
		wrapperProps = {
			onClick: (event: MouseEvent) =>
				seekToNearestFraction(wrapperRef.current, event.clientX),
			onMouseMove: (event: MouseEvent) =>
				setRelativeOffset(wrapperRef.current, event.clientX),
			onMouseOver: () => setLookAhead(true),
			onMouseLeave: () => setLookAhead(false),
		};

		thumbProps = {
			onMouseDown: activateScrubbing,
			onMouseUp: deactivateScrubbing,
		};
	}

	useEventListener('mousemove', (event: MouseEvent) => {
		if (!isTouchDevice() && isScrubbing) {
			setRelativeOffset(wrapperRef.current, event.clientX);
		}
	});

	useEventListener('mouseup', (event: MouseEvent) => {
		if (!isTouchDevice() && isScrubbing) {
			seekToNearestFraction(wrapperRef.current, event.clientX);
		}
	});

	const backdropColor = styles.palette.gray[700];

	let elapsedColor = isScrubbing ? 'transparent' : styles.palette.gray[500];
	let trackColor = styles.palette.gray[isScrubbing ? 500 : 600];
	let seekColor = styles.palette.gray[500];
	let thumbColor = styles.palette.white;

	if (isRecording) {
		plotCuePoints = false;

		elapsedColor = 'transparent';
		trackColor = elapsedColor;
		seekColor = elapsedColor;

		thumbColor = styles.palette.red[300];
	}

	return (
		<Wrapper {...wrapperProps} ref={wrapperRef} disabled={disabled}>
			<Track scope="backdrop" offset={100} color={backdropColor} rounded />
			<LargeScreen>
				{(lookAhead || isScrubbing) && (
					<Track scope="seekAhead" offset={pointerOffset} color={trackColor} />
				)}
			</LargeScreen>
			<SmallScreen>
				{isScrubbing && (
					<Track scope="seekAhead" offset={pointerOffset} color={seekColor} />
				)}
			</SmallScreen>
			<Track
				scope="elapsed"
				offset={elapsedOffset}
				color={elapsedColor}
				rounded
			/>
			<CuePoints>
				{plotCuePoints &&
					cuePoints.map((cuePoint: CueType) => {
						const clipStartsAt = cuePoint.startsAtMs
							? cuePoint.startsAtMs / 1000
							: cuePoint.startsAt;
						const offset: number = clipOffset(0, endsAt, clipStartsAt);
						const dimmed: boolean =
							(isScrubbing && offset <= pointerOffset) ||
							offset <= elapsedOffset;

						return (
							<Cue
								key={`cue:${cuePoint.cueId}`}
								offset={offset}
								dimmed={dimmed}
								highlight={cuePoint.isHighlight}
							/>
						);
					})}
			</CuePoints>
			<Pointer
				color={isScrubbing ? styles.palette.white : 'transparent'}
				offset={elapsedOffset}
			/>
			<Thumb
				scope="elapsed"
				offset={elapsedOffset}
				{...thumbProps}
				size={isScrubbing ? 0 : 7}
				fill={thumbColor}
			/>
			<Thumb
				scope="seekAhead"
				offset={pointerOffset}
				size={isScrubbing ? 9 : 0}
				fill={thumbColor}
			/>
			<SmallScreen>
				{isScrubbing && (
					<TimestampTooltip
						offset={pointerOffset}
						width={longTimestamp ? 50 : 40}
						timestamp={timestamp}
					/>
				)}
			</SmallScreen>
			<LargeScreen>
				{(lookAhead || isScrubbing) && (
					<TimestampTooltip
						offset={pointerOffset}
						width={longTimestamp ? 50 : 40}
						timestamp={timestamp}
					/>
				)}
			</LargeScreen>
		</Wrapper>
	);
}
