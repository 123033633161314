import styled from 'styled-components';

import * as numbers from 'pkg/numbers';
import { formatTime } from 'pkg/timeline';
import { segmentCount } from 'pkg/video';

import * as config from 'routes/video/analyze/timeline/config';
import PlaybackKeyframes from 'routes/video/analyze/timeline/PlaybackKeyframes';

const Wrapper = styled.div`
	position: relative;
	overflow-x: hidden;
`;

const Segments = styled.div`
	display: grid;
	grid-auto-flow: column;
`;

const Segment = styled.div`
	height: 40px;
	background-image: radial-gradient(
		circle closest-side,
		var(--palette-gray-600) calc(100% - 0.5px),
		transparent 100%
	);
	background-repeat: repeat-x;
	background-size: 13px 3px;
	background-position: center;
	display: grid;
	align-items: center;
	justify-content: start;

	&:first-child > span {
		display: none;
	}

	&:not(:first-child) > span {
		transform: translateX(-50%);
	}
`;

const Time = styled.span`
	background: var(--palette-gray-700);
	display: inline-block;
	padding: var(--spacing-2) var(--spacing-4);
	font-size: var(--font-size-sm);
	transform: translateX(1px);
`;

interface TimeSegmentsProps {
	precision: config.Precision;
	zoom: number;
	duration: number;
}

export default function TimeSegments({
	precision,
	zoom = 1,
	duration,
}: TimeSegmentsProps): JSX.Element {
	const numSegments: number = segmentCount(
		duration,
		config.multiplier[precision]
	);

	const maxSegments: number = Math.ceil(numSegments);

	return (
		<Wrapper>
			<Segments>
				{numbers.fillCount(maxSegments, true).map((n: number) => (
					<Segment
						key={n}
						style={{ width: `${config.getSegmentWidth(zoom)}px` }}>
						<Time>{formatTime(n * config.granularity[precision])}</Time>
					</Segment>
				))}
			</Segments>

			<PlaybackKeyframes duration={duration} />
		</Wrapper>
	);
}
