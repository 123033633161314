import { Component, Fragment } from 'react';
import { connect, useSelector } from 'react-redux';
import styled from 'styled-components';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import {
	createSessionItem,
	deleteSessionItem,
	swapSessionItemOrder,
	setSessionItemUsers,
} from 'pkg/actions/session_items';

import * as actions from 'pkg/actions';
import rgba from 'pkg/rgba';
import uuid from 'pkg/uuid';
import * as selectors from 'pkg/selectors';
import { setConfirmNavigation } from 'pkg/router/utils';
import { AccountIdentityContext } from 'pkg/identity/account';

import ExerciseItem from 'containers/training/library/SessionExerciseItem';
import ViewExerciseModal from 'containers/training/session/ViewExerciseModal';

import { LargeScreen } from 'components/MediaQuery';
import Icon from 'components/icon';

import ExerciseModal from 'components/training/add-modal/exercise';
import EditExerciseForm from 'components/training/exercise/EditExerciseForm';
import CreateExerciseForm from 'components/training/exercise/CreateExerciseForm';
import Block from 'components/training/session/edit/Block';
import MovableBlock from 'components/training/session/edit/MovableBlock';
import MovableContainer from 'components/training/session/edit/MovableContainer';
import DelayedTextInput from 'components/form/DelayedTextInput';
import DeletedExercise from 'components/training/session/DeletedExercise';
import UserSelectableListModal from 'components/user/SelectableListModal';
import FauxPageModal, {
	FAUX_MODAL_PARENT,
} from 'components/modals/FauxPageModal';
import ExerciseDuplicationModal from 'components/training/exercise/ExerciseDuplicationModal';

import * as ContextMenu from 'design/context_menu';
import Button from 'design/button';

const BlockForm = styled.div`
	display: grid;
	grid-template-columns: 60px 1fr;
	grid-template-rows: auto 1fr;
	grid-template-areas: '. .' 'text text';
	grid-gap: 10px;
`;

const GroupLabel = styled.p`
	font-size: var(--font-size-sm);
	line-height: 1;
	color: var(--palette-gray-800);
	text-align: left;
`;

const DurationGroup = styled.div`
	display: grid;
	grid-auto-flow: row;
	grid-gap: 0.5rem;
`;

const TitleGroup = styled(DurationGroup)`
	${GroupLabel} {
		text-align: left;
	}
`;

const DurationInput = styled(DelayedTextInput)`
	input {
		text-align: center;
	}
`;

const DescriptionInput = styled(DelayedTextInput)`
	grid-area: text;
`;

const EditAction = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 38px;
	height: 27px;
	background-color: var(--palette-white);
	border-radius: 200px;
	box-shadow: 0px 1px 3px ${rgba(styles.palette.gray[800], 0.1)};

	svg {
		margin: 0;
	}
`;

const ExerciseActions = ({ exerciseId, onEdit, asContextItem }) => {
	const exercise = useSelector((state) =>
		selectors.exercises.find(state, exerciseId)
	);

	if (!exercise.hasIn(['links', 'edit'])) return null;

	return asContextItem ? (
		<ContextMenu.Item onClick={onEdit} icon="edit">
			{t('Edit')}
		</ContextMenu.Item>
	) : (
		<LargeScreen>
			<EditAction onClick={onEdit}>
				<Icon name="edit" />
			</EditAction>
		</LargeScreen>
	);
};

class SessionBlock extends Component {
	static contextType = AccountIdentityContext;

	state = {
		exerciseId: 0,
		modal: '',
		isDuplicating: false,
	};

	handleCreateItem = (exerciseId) => {
		const { sessionId, blockId, createSessionItem, sessionItems } = this.props;
		const orgId = this.context.organization.id;

		createSessionItem(orgId, sessionId, blockId, {
			id: uuid(),
			exerciseId,
			order: (sessionItems.last()?.get('order') || 0) + 1,
			users: [],
		});

		// load new exercise into redux state
		actions.exercises.getSingleExercise(exerciseId);

		this.handleCloseModal();
	};

	openModal = (modal, exerciseId = 0) => {
		this.setState({ modal, exerciseId });
		document.querySelector('.' + FAUX_MODAL_PARENT)?.scrollTo(0, 0);
	};

	handleOpenAddModal = () => this.openModal('add');

	handleOpenCreateModal = () => this.openModal('create');

	handleCloseModal = () => {
		this.setState({ modal: '', exerciseId: 0 });
		setConfirmNavigation(
			t(
				'You are about to leave the Session Builder, unsaved changes will be discarded.',
				{ _context: 'training_library/session_builder' }
			)
		);
	};

	handleUpdatedExercise = (exerciseId) => {
		actions.exercises.getSingleExercise(exerciseId, true);
		this.handleCloseModal();
	};

	handleDeleteItem = (id) => {
		const orgId = this.context.organization.id;
		this.props.deleteSessionItem(orgId, id);
	};

	get items() {
		const orgId = this.context.organization.id;
		let items = this.props.sessionItems;
		const numItems = items.size;

		const eventId = this.props.eventId;

		let canAscend = false;
		let canDescend = false;

		return items.map((item, n) => {
			canAscend = n > 0;
			canDescend = n < numItems - 1;

			const prev = items.get(n - 1);
			const next = items.get(n + 1);

			const onAscend = () => {
				if (prev) {
					this.props.swapSessionItemOrder(item, prev);
				}
			};

			const onDescend = () => {
				if (next) {
					this.props.swapSessionItemOrder(item, next);
				}
			};

			const handleView = (exerciseId) => {
				this.openModal('view', exerciseId);
			};

			const handleEdit = (e) => {
				e.stopPropagation();

				this.openModal('edit', item.exerciseId);
			};

			const handleTaggedUsers = (taggedUsers) => {
				if (/^\d+$/.test(item.id)) {
					this.props.setSessionItemUsers(
						orgId,
						item.id,
						taggedUsers.map((user) => user.id).toArray()
					);
				}
			};

			const handleDuplicateItem = async () => {
				this.setState({ isDuplicating: true });
				const newExercise = await actions.exercises.duplicateExercise(
					item.exerciseId
				);

				if (newExercise) {
					this.handleCreateItem(newExercise.id);
				}
				this.setState({ isDuplicating: false });
			};

			const exerciseActions = (
				<ExerciseActions exerciseId={item.exerciseId} onEdit={handleEdit} />
			);

			const contextExerciseActions = (
				<ExerciseActions
					exerciseId={item.exerciseId}
					onEdit={handleEdit}
					asContextItem
				/>
			);

			return (
				<MovableBlock
					inner
					key={`session-block-item-${item.id}`}
					contextId={item.id}
					canAscend={canAscend}
					onAscend={onAscend}
					canDescend={canDescend}
					onDescend={onDescend}
					exerciseActions={contextExerciseActions}
					hasActions={item.exerciseId !== 0}
					onRemove={this.handleDeleteItem}
					onDuplicate={handleDuplicateItem}>
					{item.exerciseId ? (
						<ExerciseItem
							id={item.exerciseId}
							noTagSearch
							onClick={handleView}
							actions={exerciseActions}>
							{eventId !== 0 && (
								<UserSelectableListModal
									users={this.props.users}
									selectedUserIds={[...item.users]}
									onChange={handleTaggedUsers}
								/>
							)}
						</ExerciseItem>
					) : (
						<DeletedExercise key={`del-${n}`} />
					)}
				</MovableBlock>
			);
		});
	}

	get addExerciseModal() {
		if (this.state.modal !== 'add') return;

		return (
			<ExerciseModal
				onSelect={this.handleCreateItem}
				onClose={this.handleCloseModal}
			/>
		);
	}

	handleNewExerciseId = (exerciseId) => {
		this.setState({ exerciseId });
	};

	get createExerciseModal() {
		if (this.state.modal !== 'create') return;

		return (
			<FauxPageModal>
				<CreateExerciseForm
					isInSession
					onCancel={this.handleCloseModal}
					onSave={this.handleCreateItem}
				/>
			</FauxPageModal>
		);
	}

	get editExerciseModal() {
		if (this.state.modal !== 'edit') return;

		return (
			<FauxPageModal>
				<EditExerciseForm
					isInSession
					exerciseId={this.state.exerciseId}
					onCancel={this.handleCloseModal}
					onSave={this.handleUpdatedExercise}
				/>
			</FauxPageModal>
		);
	}

	get exerciseModal() {
		if (this.state.modal !== 'view') return;

		return (
			<ViewExerciseModal
				exerciseId={this.state.exerciseId}
				close={this.handleCloseModal}
			/>
		);
	}

	handleDurationUpdate = (duration) => {
		this.props.onUpdate(this.props.block, {
			duration: Number.parseInt(duration, 10),
		});
	};

	handleTitleChange = (title) => {
		this.props.onUpdate(this.props.block, { title });
	};

	handleDescriptionChange = (description) => {
		this.props.onUpdate(this.props.block, {
			description,
		});
	};

	render() {
		const { block, canAscend, canDescend, onAscend, onDescend, onRemove } =
			this.props;

		const addSessionButton = (
			<Button large icon="add">
				{t('Add exercise', { _context: 'training_library/session_builder' })}
			</Button>
		);

		return (
			<Fragment>
				<MovableBlock
					key={`session-block-${block.id}`}
					contextId={block.id}
					hasActions
					canAscend={canAscend}
					onAscend={onAscend}
					canDescend={canDescend}
					onDescend={onDescend}
					onRemove={onRemove}>
					<Block>
						<BlockForm>
							<DurationGroup>
								<GroupLabel>
									{t('Min', { _context: 'training_library/session_builder' })}
								</GroupLabel>
								<DurationInput
									type="number"
									pattern="[0-9]+"
									placeholder={0}
									step={5}
									min={0}
									highlighted="true"
									weighted
									value={block.duration}
									onChange={this.handleDurationUpdate}
								/>
							</DurationGroup>
							<TitleGroup>
								<GroupLabel>
									{t('Block title (ex. Warmup)', {
										_context: 'training_library/session_builder',
									})}
								</GroupLabel>
								<DelayedTextInput
									value={block.title}
									highlighted="true"
									weighted
									placeholder={t('Block title', {
										_context: 'training_library/session_builder',
									})}
									onChange={this.handleTitleChange}
								/>
							</TitleGroup>
							<DescriptionInput
								value={block.description}
								minRows={1}
								maxRows={5}
								highlighted="true"
								placeholder={t('Block description', {
									_context: 'training_library/session_builder',
								})}
								onChange={this.handleDescriptionChange}
							/>
						</BlockForm>
						<MovableContainer>
							{this.items}
							<Block highlighted="true" centered>
								<ContextMenu.Menu toggleWith={addSessionButton}>
									<ContextMenu.Item onClick={this.handleOpenAddModal}>
										<ContextMenu.ItemIcon name="tactic" />
										{t('Add from library', { _context: 'training_library' })}
									</ContextMenu.Item>
									<ContextMenu.Item onClick={this.handleOpenCreateModal}>
										<ContextMenu.ItemIcon name="add" />
										{t('Create new exercise', {
											_context: 'training_library/session_builder',
										})}
									</ContextMenu.Item>
								</ContextMenu.Menu>
							</Block>
						</MovableContainer>
					</Block>
				</MovableBlock>
				{this.addExerciseModal}
				{this.createExerciseModal}
				{this.editExerciseModal}
				{this.exerciseModal}
				{this.state.isDuplicating && <ExerciseDuplicationModal />}
			</Fragment>
		);
	}
}

const propsFromState = (state, props) => ({
	sessionItems: selectors.sessionItems.findAllByBlock(state, props),
	users: selectors.eventUsers.findAllUsers(state, props),
});

const componentActions = {
	createSessionItem,
	deleteSessionItem,
	swapSessionItemOrder,
	setSessionItemUsers,
};

export default connect(propsFromState, componentActions)(SessionBlock);
