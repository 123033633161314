import { useGroupedTaggableUsers } from 'pkg/hooks/selectors';

import UserTags from 'routes/video/shared/UserTags';

export default function UserTagsForm(): JSX.Element {
	const groupedTaggableUsers = useGroupedTaggableUsers();

	return (
		<UserTags
			availableUsers={groupedTaggableUsers.users}
			groupBy={groupedTaggableUsers.groupBy}
		/>
	);
}
