import { t } from '@transifex/native';

import * as models from 'pkg/api/models';

import UserActions from 'routes/event/single/tabs/lok/user-actions';

import Avatar from 'components/avatar';

import Row from 'components/layout/row';

import * as css from './styles.css';

interface UserRowProps {
	event: models.event.Event;
	eventUser: models.eventUser.EventUser;
}

export default function UserRow({
	event,
	eventUser,
}: UserRowProps): JSX.Element {
	return (
		<Row justifyContent="space-between" align="center">
			<Row autoColumns="max-content" align="center">
				<Avatar user={eventUser.user} size={30} />
				<span>
					<div className={css.name}>{models.user.fullName(eventUser.user)}</div>
					<div className={css.flags}>
						{models.eventUser.lokHasHandicap(eventUser) && t('Has handicap')}
					</div>
				</span>
			</Row>
			<UserActions event={event} eventUser={eventUser} />
		</Row>
	);
}
