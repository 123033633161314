import { ReactNode } from 'react';

export const symbol_straight: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m450-726-69 69q-9 9-21 9t-21-9q-9-9-9-21t9-21l120-120q9-9 21-9t21 9l120 120q9 9 9 21t-9 21q-9 9-21 9t-21-9l-69-69v576q0 13-8.5 21.5T480-120q-13 0-21.5-8.5T450-150v-576Z"
		/>
	</svg>
);
