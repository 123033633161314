import { ReactNode } from 'react';

export const symbol_2d: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M313-410v-53h87q14.025 0 23.513-9.487Q433-481.975 433-496v-70q0-14.45-9.487-24.225Q414.025-600 400-600H288q-10.833 0-17.917 7.116-7.083 7.117-7.083 18Q263-564 270.083-557q7.084 7 17.917 7h95v54h-87q-14.025 0-23.513 9.487Q263-477.025 263-463v78q0 10.833 7.083 17.917Q277.167-360 288-360h120q10.833 0 17.917-7.116 7.083-7.117 7.083-18Q433-396 425.917-403q-7.084-7-17.917-7h-95Zm232 50h123q18.75 0 31.875-13.125T713-405v-150q0-18.75-13.125-31.875T668-600H545q-10.833 0-17.917 7.083Q520-585.833 520-575v190q0 10.833 7.083 17.917Q534.167-360 545-360Zm25-50v-140h93v140h-93ZM180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-60h600v-600H180v600Zm0-600v600-600Z"
		/>
	</svg>
);
