import { Fragment } from 'react';

import * as endpoints from 'pkg/api/endpoints/auto';
import { useEndpoint } from 'pkg/api/use_endpoint';
import { ManagementDashboardData } from 'pkg/api/dashboards/management';
import * as models from 'pkg/api/models';
import { useCurrentGroup } from 'pkg/identity';
import { Features } from 'pkg/api/models/group';

import ContactsCountWidget from 'routes/management/overview/total-contacts';
import ConnectedContacts from 'routes/management/overview/connected-contacts';
import ContactsWithoutGroupsWidget from 'routes/management/overview/contacts-without-groups';
import PlayerAgeDistributionWidget from 'routes/management/overview/player-age-distribution';
import PlayerGenderDistribution from 'routes/management/overview/player-gender-distribution';
import ContactsWithUserProductsWidget from 'routes/management/overview/product-statuses';
import ArchivedContactsWidget from 'routes/management/overview/archived-contacts';
import ContactsWithMissingInformationWidget from 'routes/management/overview/missing-information-contacts';
import NewContactsWidget from 'routes/management/overview/new-contacts';

import { Spinner } from 'components/loaders/spinner';
import Row from 'components/layout/row';
import Column from 'components/layout/column';

interface ManagementOverviewProps {
	organizationId: number;
}

export default function ManagementOverview({
	organizationId,
}: ManagementOverviewProps) {
	const groupId = organizationId;
	const group = useCurrentGroup();

	const { record: data, isLoading } = useEndpoint<ManagementDashboardData>(
		endpoints.Organizations.ManagementDashboard(groupId)
	);

	const hasPaymentsEnabled = models.group.hasFeature(group, Features.Payments);
	const groupCurrencyIsSet = group?.currency;
	const showUserProductsWidget = hasPaymentsEnabled && !!groupCurrencyIsSet;

	const archivedContactsAndIncompleteInfoWidgets = (
		<Fragment>
			<ArchivedContactsWidget
				data={data.archivedContacts}
				hasPaymentsEnabled={hasPaymentsEnabled}
			/>
			<ContactsWithMissingInformationWidget
				data={data.contactsWithMissingInformation}
			/>
		</Fragment>
	);

	if (isLoading) {
		return <Spinner />;
	}

	return (
		<Column>
			<Row align="stretch" collapseOnSmallScreens>
				<ContactsCountWidget data={data.contactsCount} />
				<NewContactsWidget data={data.newContacts} />
			</Row>
			<Row align="stretch" collapseOnSmallScreens>
				<ConnectedContacts data={data.playersWithoutConnectedParents} />
				<ContactsWithoutGroupsWidget data={data.contactsWithoutGroups} />
			</Row>
			<Row align="stretch" collapseOnSmallScreens>
				<PlayerAgeDistributionWidget data={data.playerAgeDistribution} />
				<PlayerGenderDistribution data={data.playerGenderDistribution} />
			</Row>
			<Row align="stretch" collapseOnSmallScreens>
				{showUserProductsWidget ? (
					<Fragment>
						<ContactsWithUserProductsWidget
							data={data.contactsWithUserProducts}
						/>
						<Column>{archivedContactsAndIncompleteInfoWidgets}</Column>
					</Fragment>
				) : (
					archivedContactsAndIncompleteInfoWidgets
				)}
			</Row>
		</Column>
	);
}
