import { Fragment, useCallback, Dispatch, SetStateAction } from 'react';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';

import OrganizationDialog from 'containers/group/settings/organization/Dialog';
import Tree from 'containers/group/settings/organization/tree/Base';

import AssetImage from 'components/AssetImage';
import Icon from 'components/icon';

import InlinePlaceholder from 'design/placeholders/inline';
import Button from 'design/button';

import * as css from './styles.css';

export interface DialogProps {
	show: boolean;
	group: models.group.Group;
	type: string;
	defaultSelected?: models.group.Group;
}

interface OrganizationTableProps {
	dialog: DialogProps;
	setDialog: Dispatch<SetStateAction<DialogProps>>;
	isSearch?: boolean;
	isLoading?: boolean;
	groups: models.group.Group[];
	rootGroup: models.group.Group;
	refresh: () => void;
	removeRecord: (recordId: number) => void;
}

const OrganizationTable: React.FC<
	React.PropsWithChildren<OrganizationTableProps>
> = ({
	dialog,
	setDialog,
	groups,
	isLoading,
	rootGroup,
	refresh,
	removeRecord,
}) => {
	const initialDialog = {
		show: false,
		type: '',
		group: {} as models.group.Group,
		defaultSelected: {} as models.group.Group,
	};
	const handleShowDialog = useCallback(
		(type: any, group = {} as models.group.Group) => {
			setDialog({ show: true, type, group, defaultSelected: rootGroup });
		},
		[rootGroup]
	);

	const handleCloseDialog = () => setDialog(initialDialog);

	const handleCreateSubGroup = (selectedGroup: models.group.Group) => {
		setDialog({
			show: true,
			type: 'create',
			group: {} as models.group.Group,
			defaultSelected: selectedGroup,
		});
	};

	const handleEmptyCreate = () => handleShowDialog('create');

	const emptyState = {
		title: t('No groups found'),
		content: t('No groups were found here'),
		image: <AssetImage src="img/missing-entities/post.svg" />,
		button: (
			<Button primary onClick={handleEmptyCreate}>
				{t('Create group')}
			</Button>
		),
	};

	if (groups.length === 0) {
		return (
			<div className={css.wrapperPlaceholder}>
				<InlinePlaceholder>
					<Icon name="nav-members" />
					{t('No groups under {groupName}', {
						groupName: rootGroup.name,
					})}
				</InlinePlaceholder>
			</div>
		);
	}

	return (
		<Fragment>
			<Tree
				showActions
				groups={groups}
				rootGroupId={rootGroup.id}
				onShowDialog={handleShowDialog}
				onCreateSubGroup={handleCreateSubGroup}
				isLoading={isLoading}
				emptyState={emptyState}
				refresh={refresh}
				removeRecord={removeRecord}
			/>
			{dialog.show && (
				<OrganizationDialog
					refresh={refresh}
					type={dialog.type}
					group={dialog.group}
					defaultSelected={dialog.defaultSelected}
					onClose={handleCloseDialog}
				/>
			)}
		</Fragment>
	);
};

export default OrganizationTable;
