import { ReactNode } from 'react';

export const symbol_scoreboard: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M608-365q-14 0-24.5-10.5T573-400v-160q0-14 10.5-24.5T608-595h99q14 0 24.5 10.5T742-560v160q0 14-10.5 24.5T707-365h-99Zm15-50h69v-130h-69v130Zm-355 0h94q10.833 0 17.917 7.116 7.083 7.117 7.083 18Q387-379 379.917-372q-7.084 7-17.917 7H253q-14 0-24.5-10.5T218-400v-69q0-14 10.5-24.5T253-504h84v-41h-94q-10.833 0-17.917-7.116-7.083-7.117-7.083-18Q218-581 225.083-588q7.084-7 17.917-7h109q14 0 24.5 10.5T387-560v69q0 14-10.5 24.5T352-456h-84v41ZM140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h150v-50q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T350-850v50h260v-50q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T670-850v50h150q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm10-70h305v-35q0-10.833 7.116-17.917 7.117-7.083 18-7.083Q491-290 498-282.917q7 7.084 7 17.917v35h305v-500H505v35q0 10.833-7.116 17.917-7.117 7.083-18 7.083Q469-670 462-677.083q-7-7.084-7-17.917v-35H150v500Zm0 0v-500 500Zm330-293q-13 0-21.5-8.5T450-553q0-13 8.5-21.5T480-583q13 0 21.5 8.5T510-553q0 13-8.5 21.5T480-523Zm0 146q-13 0-21.5-8.5T450-407q0-13 8.5-21.5T480-437q13 0 21.5 8.5T510-407q0 13-8.5 21.5T480-377Z"
		/>
	</svg>
);
