import { Set } from 'immutable';

import MatchCompetition from 'pkg/models/match_competition';

import { RootState } from 'pkg/reducers';

export const getMatchCompetitionsByMatchIDs = (
	state: RootState,
	{ matchIDs }: { matchIDs: number[] }
): Set<MatchCompetition> => {
	const matches = state.matches.entities;
	const matchCompetitions = state.matchCompetitions.entities;

	return matches
		.filter((m) => matchIDs.includes(m.id) && m.matchCompetitionId !== null)
		.toList()
		.map((m) => m.matchCompetitionId)
		.toSet()
		.map((competitionId) => matchCompetitions.get(competitionId))
		.filter((c) => c)
		.sort((a, b) => a.title.localeCompare(b.title));
};
