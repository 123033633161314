import { Fragment } from 'react';

import SmallScreenTimeline from 'routes/video/play/SmallScreenTimeline';
import LargeScreenTimeline, {
	TimelineContext,
} from 'routes/video/play/Timeline';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import { usePlayerState } from 'components/video-analytics/PlayerState';
import { useCueState } from 'components/video-analytics/CueState';

interface TimelineProps {
	cueOnly?: boolean;
	disabled?: boolean;
	context?: TimelineContext;
}

export default function Timeline({
	cueOnly,
	disabled,
	context,
}: TimelineProps): JSX.Element {
	const { controller, duration } = usePlayerState();
	const { currentCue } = useCueState();

	let startsAt = 0,
		endsAt = duration || 1;

	if (cueOnly && currentCue) {
		startsAt = currentCue.startsAtMs
			? currentCue.startsAtMs / 1000
			: currentCue.startsAt;

		endsAt = currentCue.endsAtMs
			? currentCue.endsAtMs / 1000
			: currentCue.endsAt;
	}

	if (!controller) return null;

	return (
		<Fragment>
			<LargeScreen>
				<LargeScreenTimeline
					context={context}
					plotCuePoints={!cueOnly}
					startsAt={startsAt}
					endsAt={endsAt}
					disabled={disabled}
				/>
			</LargeScreen>
			<SmallScreen>
				<SmallScreenTimeline
					context={context}
					plotCuePoints={!cueOnly}
					startsAt={startsAt}
					endsAt={endsAt}
					disabled={disabled}
				/>
			</SmallScreen>
		</Fragment>
	);
}
