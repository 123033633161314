import { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import * as styles from 'pkg/config/styles';
import { size } from 'pkg/config/fonts';

import rgba from 'pkg/rgba';

import Toggle from 'components/Toggle';
import Icon from 'components/icon';

import * as iconStyles from 'components/icon/styles.css';

const Wrapper = styled.div`
	background: var(--palette-white);
	cursor: pointer;
	color: var(--palette-gray-800);
	box-shadow:
		inset 0 0 0 1px var(--palette-gray-300),
		0px 1px 3px ${rgba(styles.palette.black, 0.1)};
	border-radius: var(--radius-3);
	user-select: none;

	${({ isDisabled, standAlone }) =>
		!isDisabled &&
		!standAlone &&
		css`
			@media (hover: hover) {
				&:hover {
					box-shadow:
						inset 0 0 0 1px ${rgba(styles.palette.black, 0.7)},
						0px 1px 3px ${rgba(styles.palette.black, 0.1)};
				}
			}
		`}

	${({ standAlone }) =>
		standAlone &&
		css`
			box-shadow: none;
		`}


	${({ isDisabled }) =>
		isDisabled &&
		css`
			opacity: 0.5;
			cursor: not-allowed;
		`}

	${({ theme }) =>
		theme.darkMode &&
		css`
			background: var(--palette-gray-800);
			color: var(--palette-white);

			box-shadow:
				inset 0 0 0 1px var(--palette-gray-600),
				0px 1px 3px ${rgba(styles.palette.black, 0.1)};

			@media (hover: hover) {
				&:hover {
					box-shadow:
						inset 0 0 0 1px var(--palette-gray-500),
						0px 1px 3px ${rgba(styles.palette.black, 0.1)};
				}
			}
		`}
`;

const Inner = styled.div`
	display: grid;
	grid-template-rows: auto 1fr;
	grid-column-gap: 1rem;
	align-items: start;
	justify-items: start;
	align-content: start;
	padding: 0.8rem;

	${({ controlPosition }) =>
		controlPosition === 'left'
			? css`
					grid-template-columns: 34px auto;
					grid-template-areas:
						'control label'
						'control description';
				`
			: css`
					grid-template-columns: auto 34px;
					grid-template-areas:
						'label control'
						'description control';
				`}

	${({ hasGraphic }) =>
		hasGraphic &&
		css`
			grid-template-areas:
				'graphic label control'
				'graphic description control';
			grid-template-columns: auto 1fr 40px;
		`}

	${({ standAlone }) =>
		standAlone &&
		css`
			padding: 0;
		`}

	${({ hasDescription }) =>
		hasDescription &&
		css`
			grid-row-gap: 0.2rem;
		`}
`;

const Graphic = styled.div`
	grid-area: graphic;
`;

const Label = styled.p`
	grid-area: label;
	font-size: ${size[400]};
	display: inline-grid;
	grid-auto-flow: column;
	align-items: start;
	justify-content: start;
	column-gap: 0px;
	font-weight: var(--font-weight-semibold);

	.${iconStyles.icon} {
		margin-left: -3px;
		font-size: 20px;
	}

	${({ standAlone }) =>
		standAlone &&
		css`
			line-height: 1.5;
			font-weight: var(--font-weight-semibold);
		`}
`;

const Description = styled.p`
	grid-area: description;
	font-size: var(--font-size-sm);
	color: var(--palette-gray-500);
`;

const Control = styled(Toggle)`
	grid-area: control;
	margin-top: 1px;

	${({ isDisabled }) =>
		isDisabled &&
		css`
			opacity: 0.5;
		`}
`;

const Children = styled.div`
	padding: 0.8rem;

	${({ standAlone }) =>
		standAlone &&
		css`
			padding: 0;
		`}
`;

export default class ToggleSwitch extends Component {
	static propTypes = {
		label: PropTypes.string.isRequired,
		icon: PropTypes.string,
		description: PropTypes.string,
		formName: PropTypes.string,
		isActive: PropTypes.bool,
		disabled: PropTypes.bool,
		standAlone: PropTypes.bool,
		graphic: PropTypes.element,
		controlPosition: PropTypes.string,

		onChange: PropTypes.func.isRequired,
	};

	static defaultProps = {
		isActive: false,
		disabled: false,
		standAlone: false,
		graphic: null,
		controlPosition: 'right',
		formName: null,
	};

	handleChange = () => {
		const { formName, isActive, disabled, onChange } = this.props;

		if (!disabled) {
			onChange(!isActive, formName);
		}
	};

	render() {
		const {
			icon,
			label,
			description,
			formName,
			isActive,
			disabled,
			children,
			standAlone,
			controlPosition,
			graphic,
		} = this.props;

		return (
			<Wrapper
				isActive={isActive}
				isDisabled={disabled}
				standAlone={standAlone}>
				<Inner
					onClick={this.handleChange}
					hasGraphic={!!graphic}
					hasDescription={!!description}
					standAlone={standAlone}
					controlPosition={controlPosition}
					data-testid="toggle">
					{graphic && <Graphic>{graphic}</Graphic>}
					<Label isActive={isActive} standAlone={standAlone}>
						{icon && <Icon name={icon} />} {label}
					</Label>

					{description && <Description>{description}</Description>}

					<Control active={isActive} />

					{formName && (
						<input type="hidden" name={formName} value={Number(isActive)} />
					)}
				</Inner>

				{children && <Children standAlone={standAlone}>{children}</Children>}
			</Wrapper>
		);
	}
}
