import { ReactNode } from 'react';

export const symbol_social_leaderboard: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-140q83 0 141.5-58.5T680-340q0-83-58.5-141.5T480-540q-83 0-141.5 58.5T280-340q0 83 58.5 141.5T480-140ZM346-563q28-17 60-26.5t67-10.5L363-820H217l129 257Zm-73 380q-25-33-39-72.5T220-340q0-45 14-84.5t39-72.5q-57 10-95 53.5T140-340q0 60 38 103.5t95 53.5Zm414 0q57-10 95-53.5T820-340q0-60-38-103.5T687-497q25 33 39 72.5t14 84.5q0 45-14 84.5T687-183ZM480-80q-40 0-76.5-11.5T336-123q-9 2-18 2.5t-19 .5q-91 0-155-64T80-339q0-87 58-149t143-69L142-837q-8-15 1-29t26-14h194q17 0 31.5 9t22.5 24l63 127 63-127q8-15 22.5-24t31.5-9h194q17 0 26 14t1 29L680-559q85 8 142.5 70T880-340q0 92-64 156t-156 64q-9 0-18.5-.5T623-123q-31 20-67 31.5T480-80Zm0-260ZM346-563 217-820l129 257Zm134 277-55 42q-5 3-9.5 0t-2.5-8l21-69-55-39q-5-3-3-8.5t7-5.5h68l22-72q2-5 7-5t7 5l22 72h68q5 0 7 5.5t-3 8.5l-55 39 21 69q2 5-2.5 8t-9.5 0l-55-42Zm134-277 129-257H597l-83 167 30 60q19 5 36.5 12.5T614-563Z"
		/>
	</svg>
);
