import { ReactNode } from 'react';

export const symbol_format_size: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M570-700H410q-20.833 0-35.417-14.618Q360-729.235 360-750.118 360-771 374.583-785.5 389.167-800 410-800h420q20.833 0 35.417 14.618Q880-770.765 880-749.882 880-729 865.417-714.5 850.833-700 830-700H670v490q0 20.833-14.618 35.417Q640.765-160 619.882-160 599-160 584.5-174.583 570-189.167 570-210v-490ZM210-500h-80q-20.833 0-35.417-14.618Q80-529.235 80-550.118 80-571 94.583-585.5 109.167-600 130-600h260q20.833 0 35.417 14.618Q440-570.765 440-549.882 440-529 425.417-514.5 410.833-500 390-500h-80v290q0 20.833-14.618 35.417Q280.765-160 259.882-160 239-160 224.5-174.583 210-189.167 210-210v-290Z"
		/>
	</svg>
);
