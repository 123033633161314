import React, { createContext, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCollection, CollectionResponse } from 'pkg/api/use_collection';
import useMixedState, { MixedStateSetter } from 'pkg/hooks/useMixedState';
import { replaceState } from 'pkg/router/state';
import * as routes from 'pkg/router/routes';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { useCurrentUser } from 'pkg/identity';
import { MessageType, useWebSocketClient } from 'pkg/websocket';
import { useResumeListener } from 'pkg/events';

import Initiate from 'routes/chat/view/Initiate';
import ChatList from 'routes/chat/view/ChatList';
import CurrentChat from 'routes/chat/view/CurrentChat';

import {
	SmallScreen,
	LargeScreen,
	useSmallScreen,
} from 'components/MediaQuery';
import Icon from 'components/icon';

import { Spinner } from 'components/loaders/spinner';

import InlinePlaceholder from 'design/placeholders/inline';

export const LargeLayout = styled.div`
	min-height: 0;
	height: 100%;
	display: grid;
	grid-template-columns: 400px 1fr;
	grid-template-rows: 4rem 1fr auto;
	grid-template-areas:
		'initiate header'
		'chatlist thread'
		'chatlist thread'
		'chatlist footer';

	${InlinePlaceholder} {
		grid-area: thread;
		align-self: center;
	}
`;

export const SmallLayoutInChat = styled.div`
	height: 100%;
	min-height: 0;
	display: grid;
	grid-template-areas: 'header' 'thread' 'footer';
	grid-template-rows: 3.4rem 1fr auto;

	${InlinePlaceholder} {
		grid-area: thread;
		align-self: center;
	}
`;

export const SmallLayoutInList = styled.div`
	height: 100%;
	min-height: 0;
	display: grid;
	grid-template-areas: 'chatlist';
	grid-template-rows: 1fr;
	overflow: scroll;
	-webkit-overflow-scrolling: touch;
`;

interface ChatMixedState {
	quotedMessageId: number;
	newChats: models.chat.Chat[];
}

const emptyState: ChatMixedState = {
	quotedMessageId: null,
	newChats: [],
};

interface ChatContextProps {
	state: ChatMixedState;
	setState: MixedStateSetter<ChatMixedState>;
	chatCollection: CollectionResponse<models.chat.Chat>;
}

const ChatContext = createContext<ChatContextProps>({
	state: emptyState,
	setState: () => {
		return;
	},
	chatCollection: null,
});

export function useChatContext() {
	return useContext(ChatContext);
}

function lastOpenChatId(): number {
	return Number.parseInt(localStorage.getItem('lastOpenChatId') || '0', 10);
}

function rememberOpenChatId(chatId: number) {
	if (lastOpenChatId() !== chatId && !!chatId) {
		localStorage.setItem('lastOpenChatId', chatId.toString());
	}
}

interface ViewProps {
	targetChatId: number;
	organizationId: number;
}

function View({ targetChatId, organizationId }: ViewProps) {
	const isSmallScreen = useSmallScreen();
	const currentUser = useCurrentUser();
	const [state, setState] = useMixedState<ChatMixedState>(emptyState);
	const ws = useWebSocketClient();
	const endpoint = endpoints.Chat.List1(organizationId);

	const chatCollection = useCollection<models.chat.Chat>(endpoint);

	const recentChatId = lastOpenChatId();
	const allChats = [...chatCollection.records];
	const targetChat = allChats.find((chat) => chat.id === targetChatId);

	useEffect(() => {
		if (lastOpenChatId() !== targetChatId) {
			rememberOpenChatId(targetChatId);
		}
	}, [targetChatId]);

	useComponentDidMount(() => {
		if (!targetChatId && recentChatId !== 0 && !isSmallScreen) {
			replaceState(routes.Chat.Show(organizationId, recentChatId));
		}
	});

	useResumeListener(chatCollection.refresh);

	ws.onResume(chatCollection.refresh);

	ws.onMessage<models.chat.Chat>(MessageType.ChatUpdated, (event) => {
		// If we get a update from another org exit
		if (event.data.rootGroupId !== organizationId) {
			return;
		}

		if (chatCollection.records.map((chat) => chat.id).includes(event.data.id)) {
			chatCollection.replaceRecord(event.data);
		} else {
			chatCollection.addRecord(event.data);
		}
	});

	ws.onMessage<models.chatUser.ChatUser>(
		MessageType.ChatUserUpdated,
		(event) => {
			chatCollection.replaceInRecord(event.data.chatId, 'users', event.data);
		}
	);

	ws.onMessage<models.chatUser.ChatUser>(
		MessageType.ChatUserDeleted,
		(event) => {
			if (currentUser.id === event.data.userId) {
				chatCollection.removeRecord(event.data.chatId);
			}
		}
	);

	if (chatCollection.isLoading) {
		return <Spinner />;
	}

	return (
		<ChatContext.Provider value={{ state, setState, chatCollection }}>
			<LargeScreen>
				<LargeLayout>
					<Initiate />
					<ChatList
						replaceState
						currentChatId={targetChat?.id}
						chatRecords={allChats}
						loading={chatCollection.isLoading}
					/>
					{targetChat?.id ? (
						<CurrentChat autoFocus targetChat={targetChat} />
					) : (
						<InlinePlaceholder>
							<Icon name="add-conversation" />
							{t('Select a conversation!')}
						</InlinePlaceholder>
					)}
				</LargeLayout>
			</LargeScreen>

			<SmallScreen>
				{!targetChatId && (
					<SmallLayoutInList>
						<Initiate asPageActions />
						<ChatList
							currentChatId={targetChatId}
							loading={chatCollection.isLoading}
							chatRecords={allChats}
						/>
					</SmallLayoutInList>
				)}

				{targetChat?.id && (
					<SmallLayoutInChat>
						<CurrentChat forceBack targetChat={targetChat} />
					</SmallLayoutInChat>
				)}
			</SmallScreen>
		</ChatContext.Provider>
	);
}

export default View;
