import { useEffect } from 'react';

import { isIOS } from 'pkg/platform';

import { useAppState } from 'components/application/state';

export function HelpScoutKeyboardWatcher(): JSX.Element {
	const { keyboardOpen } = useAppState();

	useEffect(() => {
		const helpscoutWrapper = document.querySelector<HTMLDivElement>(
			'body #beacon-container .hsds-beacon .BeaconContainer'
		);

		if (!helpscoutWrapper || !isIOS()) {
			return;
		}

		if (keyboardOpen) {
			helpscoutWrapper.style.top = 'var(--sat)';
			helpscoutWrapper.style.bottom = '0';
			helpscoutWrapper.style.height = 'auto';
		} else {
			helpscoutWrapper.style.top = 'auto';
			helpscoutWrapper.style.bottom = 'var(--sab)';
			helpscoutWrapper.style.height = '';
		}
	}, [keyboardOpen]);

	return null;
}
