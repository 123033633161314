import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import { Record } from 'pkg/api/models/record';

export enum MatchEventType {
	MatchEventTypeStartingUser = 'starting_user',
	MatchEventTypeSubstitutionIn = 'substitution_in',
	MatchEventTypeSubstitutionOut = 'substitution_out',
	MatchEventTypePositionSwap = 'position_swap',
	MatchEventTypeShotOnGoal = 'shot_on_goal',
	MatchEventTypeShotOffGoal = 'shot_off_goal',
	MatchEventTypeGoal = 'goal',
	MatchEventTypeOpponentGoal = 'opponent_goal',
	MatchEventTypeAssist = 'assist',
	MatchEventTypeFreeKick = 'free_kick',
	MatchEventTypeCorner = 'corner',
	MatchEventTypePenalty = 'penalty',
	MatchEventTypeOffside = 'offside',
	MatchEventTypeCardYellow = 'card_yellow',
	MatchEventTypeCardRed = 'card_red',
	MatchEventTypeOther = 'other',
	MatchEventTypePass = 'pass',
}

export interface MatchEvent extends Record {
	matchId: number;
	leadingMatchEventId: number;
	substitutionUserId: number;
	authorId: number;
	userId: number;
	positionId: number;
	type: MatchEventType;
	isReviewed: boolean;
	isOpponent: boolean;
	comment: string;
	minute: number;
	extraMinute: number;
	x: number;
	y: number;
	guid: string;
	leadingGuid: string;

	user: models.user.User;
}

export function getEventTypeTranslation(type: MatchEventType) {
	let string = 'Missing translation';
	switch (type) {
		case MatchEventType.MatchEventTypeShotOnGoal:
			string = t('Shot on goal');
			break;
		case MatchEventType.MatchEventTypeShotOffGoal:
			string = t('Shot off goal');
			break;
		case MatchEventType.MatchEventTypeFreeKick:
			string = t('Free kick');
			break;
		case MatchEventType.MatchEventTypePenalty:
			string = t('Penalty');
			break;
		case MatchEventType.MatchEventTypePass:
			string = t('Passes');
			break;
		case MatchEventType.MatchEventTypeOffside:
			string = t('Offside');
			break;
		case MatchEventType.MatchEventTypeCorner:
			string = t('Corner');
			break;
		case MatchEventType.MatchEventTypeCardYellow:
			string = t('Yellow card');
			break;
		case MatchEventType.MatchEventTypeCardRed:
			string = t('Red card');
			break;
		case MatchEventType.MatchEventTypeSubstitutionIn:
		case MatchEventType.MatchEventTypeSubstitutionOut:
			string = t('Substitution');
			break;
		case MatchEventType.MatchEventTypeGoal:
			string = t('Goal');
			break;
		case MatchEventType.MatchEventTypeOpponentGoal:
			string = t('Conceded goal');
			break;
		case MatchEventType.MatchEventTypePositionSwap:
			string = t('Change position');
			break;
	}

	return string;
}
