import { ReactNode } from 'react';

export const symbol_flight_land: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M767-331 142-505q-10-3-16-11t-6-18v-164q0-8 6.5-13t14.5-2l10 3q4 2 6 4.5t4 6.5l29 88 219 61v-298q0-12 9.5-19.5T440-872l13 3q7 2 11.5 6t6.5 11l111 350 215 61q19 5 31 20.5t12 35.5q0 28-22.5 45t-50.5 9ZM150-120q-13 0-21.5-8.5T120-150q0-13 8.5-21.5T150-180h660q13 0 21.5 8.5T840-150q0 13-8.5 21.5T810-120H150Z"
		/>
	</svg>
);
