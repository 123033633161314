import { ReactNode } from 'react';

export const symbol_swap_driving_apps: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M641-778H342q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T342-838h299l-38-38q-9-9-9-22t8.609-22Q612-929 625-929q13 0 22 9l91 91q9 9 9 21t-9 21l-91 91q-9 9-22 9t-22-8.609Q594-705 594-718q0-13 9-22l38-38ZM180-190h600v-214H180v214Zm105-52q23 0 39-16t16-39q0-23-16-39t-39-16q-23 0-39 16t-16 39q0 23 16 39t39 16Zm390 0q23 0 39-16t16-39q0-23-16-39t-39-16q-23 0-39 16t-16 39q0 23 16 39t39 16ZM237-627l38 38q9 9 9 22t-8.609 22Q266-536 253-536q-13 0-22-9l-91-91q-9-9-9-21t9-21l91-91q9-9 22-9t22.391 9Q284-760 284-747t-9 22l-38 38h463q19.5 0 35.25 11.389T757-646l80 233q2 5 2.5 9.667.5 4.666.5 9.333v314q0 16.667-11.735 28.333Q816.529-40 799.765-40 783-40 771.5-51.667 760-63.333 760-80v-50H200v50q0 16.667-11.735 28.333Q176.529-40 159.765-40 143-40 131.5-51.667 120-63.333 120-80v-324q0-24.75 17.625-42.375T180-464h574l-54-163H237Zm-57 223v214-214Z"
		/>
	</svg>
);
