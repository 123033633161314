import { Fragment, ReactNode, useRef } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import { cssClasses } from 'pkg/css/utils';
import { useEventListener } from 'pkg/hooks/events';
import { replaceState } from 'pkg/router/state';
import * as routes from 'pkg/router/routes';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import DateTime, { Weekday } from 'pkg/datetime';
import { fillCount } from 'pkg/numbers';
import { useCurrentOrganization } from 'pkg/identity';

import Column from 'components/layout/column';
import Row from 'components/layout/row';

import Button from 'design/button';

import * as css from './styles.css';

export function useRedirectOnPrint(printUrl: string): void {
	useEventListener('beforeprint', () => {
		replaceState(printUrl);
	});
}

interface WithChildren {
	children: ReactNode | ReactNode[];
}

interface SheetProps extends WithChildren {
	title?: string;
	backLabel?: string;
	backUrl?: string;
	backAction?: () => void;
	canPrint?: boolean;
	orientation?: 'portrait' | 'landscape';
}

export function Sheet({
	title,
	backLabel,
	backUrl,
	backAction,
	canPrint = true,
	orientation = 'portrait',
	children,
}: SheetProps): JSX.Element {
	const handlePrint = () => window.print();
	const org = useCurrentOrganization();

	const titleRef = useRef<string>(document.title);

	const setDocumentOrientation = () => {
		document.documentElement.style.setProperty('--orientation', orientation);
		document.documentElement.style.setProperty('--app-overflow', 'auto');
	};

	const resetDocumentOrientation = () => {
		document.documentElement.style.setProperty('--orientation', 'portrait');
		document.documentElement.style.setProperty('--app-overflow', 'hidden');
	};

	useComponentDidMount(
		() => {
			setDocumentOrientation();

			if (title) {
				document.title = title;
			}
		},
		() => {
			resetDocumentOrientation();

			document.title = titleRef.current;
		}
	);

	let buttonHref: string;

	if (!backAction) {
		buttonHref = backUrl || routes.Organization.Home(org.id);
	}

	return (
		<Fragment>
			<div className={css.actions}>
				<Row
					autoColumns="auto"
					align="center"
					justify="center"
					justifyContent="center"
					spacing={styles.spacing._3}
					className={css.actionsButtons}>
					<Button href={buttonHref} onClick={backAction}>
						{backLabel || t('Back')}
					</Button>
					{canPrint && (
						<Button primary icon="print" onClick={handlePrint}>
							{t('Print')}
						</Button>
					)}
				</Row>
			</div>
			<div className={css.wrapper}>
				<Column className={cssClasses(css.sheet, css[orientation])}>
					{children}
				</Column>
			</div>
		</Fragment>
	);
}

export function Block({ children }: WithChildren): JSX.Element {
	return <Column className={css.block}>{children}</Column>;
}

interface HeadingProps extends WithChildren {
	size?: 'large' | 'medium' | 'small';
	withDivider?: boolean;
	withCounter?: boolean;
}

export function Heading({
	size = 'large',
	withDivider,
	withCounter,
	children,
}: HeadingProps): JSX.Element {
	let node = (
		<h1 data-with-counter={withCounter} className={css.headingLarge}>
			{children}
		</h1>
	);

	switch (size) {
		case 'medium':
			node = (
				<h2 data-with-counter={withCounter} className={css.headingMedium}>
					{children}
				</h2>
			);
			break;
		case 'small':
			node = (
				<h3 data-with-counter={withCounter} className={css.headingSmall}>
					{children}
				</h3>
			);
			break;
	}

	if (withDivider) {
		return <div className={css.dividedHeading}>{node}</div>;
	}

	return node;
}

export function SectionHeading({ children }: WithChildren): JSX.Element {
	return <div className={css.sectionHeading}>{children}</div>;
}

interface TextProps extends WithChildren {
	size?: 'small' | 'normal';
	italic?: boolean;
}

interface TextProps extends WithChildren {
	size?: 'small' | 'normal';
	italic?: boolean;
}

export function Text({
	size = 'normal',
	italic = false,
	children,
}: TextProps): JSX.Element {
	const textStyle = italic ? css.italicText : '';

	if (size === 'small') {
		return (
			<p className={cssClasses(css.bodyTextSmall, textStyle)}>{children}</p>
		);
	}

	return <p className={cssClasses(css.bodyText, textStyle)}>{children}</p>;
}

export function Divider(): JSX.Element {
	return <hr className={css.divider} />;
}

export interface DataTableMap {
	[key: string]: ReactNode;
}

interface DataTableProps {
	data: DataTableMap;
}

export function DataTable({ data }: DataTableProps): JSX.Element {
	return (
		<table className={css.dataTable}>
			{Object.entries(data).map(([key, val]) => (
				<tr key={key}>
					<th>{key}</th>
					<td>{val}</td>
				</tr>
			))}
		</table>
	);
}

export function Tag({ children }: WithChildren): JSX.Element {
	return <span className={css.tag}>{children}</span>;
}

export interface CalendarItems {
	[Weekday.MONDAY]: ReactNode[];
	[Weekday.TUESDAY]: ReactNode[];
	[Weekday.WEDNESDAY]: ReactNode[];
	[Weekday.THURSDAY]: ReactNode[];
	[Weekday.FRIDAY]: ReactNode[];
	[Weekday.SATURDAY]: ReactNode[];
	[Weekday.SUNDAY]: ReactNode[];
}

interface WeekdayTranslationMap {
	[Weekday.MONDAY]: string;
	[Weekday.TUESDAY]: string;
	[Weekday.WEDNESDAY]: string;
	[Weekday.THURSDAY]: string;
	[Weekday.FRIDAY]: string;
	[Weekday.SATURDAY]: string;
	[Weekday.SUNDAY]: string;
}

const WeekdayTranslation = Object.assign(
	{},
	DateTime.localeWeekdayStrings('short')
) as unknown as WeekdayTranslationMap;

export interface CalendarGroup {
	title?: string;
	items: CalendarItems;
}

interface CalendarProps {
	caption?: ReactNode;
	data: CalendarGroup[];
	weekStartsAt?: Weekday;
}

export function Calendar({
	caption,
	data,
	weekStartsAt = Weekday.MONDAY,
}: CalendarProps): JSX.Element {
	// Filters out groups with no entries in them
	const cleanData = data.filter((group: CalendarGroup) => {
		return (
			Object.values(group.items)
				.flat()
				.filter((n) => n).length > 0
		);
	});

	const week: number[] = fillCount(7, true);

	const headings: ReactNode[] = week.map((n: number) => (
		<th key={`w:${n}`} className={css.calendarHeading}>
			<span>{WeekdayTranslation[((n + weekStartsAt) % 7) as Weekday]}</span>
		</th>
	));

	const renderCalendarSlots = (items: CalendarItems): ReactNode[] => {
		return week.map((n: number) => {
			const weekday: Weekday = (n + weekStartsAt) % 7;

			return (
				<td key={`${weekday}:${n}`} className={css.calendarCell}>
					{items[weekday]}
				</td>
			);
		});
	};

	return (
		<table border={0} cellSpacing={0} className={css.calendar}>
			{caption && <caption className={css.calendarCaption}>{caption}</caption>}
			<thead>
				<tr className={css.calendarRow}>{headings}</tr>
			</thead>
			{cleanData.map((group, index) => (
				<tbody key={`${group.title}:${index}`} className={css.calendarBody}>
					{group.title?.length > 0 && (
						<tr className={css.calendarGroup}>
							<td colSpan={7} className={css.calendarGroupTitle}>
								{group.title}
							</td>
						</tr>
					)}
					<tr className={css.calendarRow}>
						{renderCalendarSlots(group.items)}
					</tr>
				</tbody>
			))}
		</table>
	);
}
