import { ReactNode } from 'react';

export const symbol_shelves: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M149.825-40Q137-40 128.5-48.625T120-70v-820q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T180-890v50h600v-50q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T840-890v820q0 12.75-8.675 21.375Q822.649-40 809.825-40 797-40 788.5-48.625T780-70v-50H180v50q0 12.75-8.675 21.375Q162.649-40 149.825-40ZM180-509h110v-130q0-12.75 8.625-21.375T320-669h160q12.75 0 21.375 8.625T510-639v130h270v-271H180v271Zm0 329h270v-130q0-12.75 8.625-21.375T480-340h160q12.75 0 21.375 8.625T670-310v130h110v-269H180v269Zm170-329h100v-100H350v100Zm160 329h100v-100H510v100ZM350-509h100-100Zm160 329h100-100Z"
		/>
	</svg>
);
