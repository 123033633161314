import { ReactNode } from 'react';

export const symbol_brightness_2: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M376-140q140.066 0 238.533-98Q713-336 713-480t-99.5-242Q514-820 374-820q-20 0-41 2t-32 5q64 72 98 157t34 176q0 91-34 176.5T302-148q11 3 31 5.5t43 2.5Zm5 60q-37.667 0-75.333-7.5Q268-95 233-109q-9-3-14.5-10.812Q213-127.625 213-137q0-6.12 2.5-11.56T223-159q68-63 109-145t41-175q0-93-41.5-175T222-800q-5-5-7.5-10.44-2.5-5.44-2.5-11.56 0-9.375 5.5-17.188Q223-847 232-850q36-14 73.5-22t76.5-8q81.276 0 152.76 31.684 71.483 31.684 124.362 86Q712-708 742.5-635.702t30.5 154.5Q773-399 742.5-326T659-198q-53 55-124.725 86.5T381-80Zm52-399Z"
		/>
	</svg>
);
