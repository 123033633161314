// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ldj4b2jmzRDycwCxrlmE {
	margin-left: var(--spacing-2);
	color: var(--palette-gray-500);
}
`, "",{"version":3,"sources":["webpack://./components/group/table/styles.css"],"names":[],"mappings":"AAAA;CACC,6BAA6B;CAC7B,8BAA8B;AAC/B","sourcesContent":[".parents {\n\tmargin-left: var(--spacing-2);\n\tcolor: var(--palette-gray-500);\n}\n"],"sourceRoot":""}]);
// Exports
export var parents = `ldj4b2jmzRDycwCxrlmE`;
export default ___CSS_LOADER_EXPORT___;
