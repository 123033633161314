import { Fragment, useState } from 'react';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import * as actions from 'pkg/actions';

import Video from 'components/video/list-item';
import VideoModal from 'components/video-library/modals/Video';

import * as Context from 'design/context_menu';
import { useDialog } from 'design/dialog';

interface VideoListItemProps {
	canEdit?: boolean;

	matchVideo: models.matchVideo.MatchVideo;

	onUpdate: (matchVideo: models.matchVideo.MatchVideo) => void;
	onRemove: (matchVideoId: number) => void;
}

export default function VideoListItem({
	canEdit = false,
	matchVideo,
	onUpdate,
	onRemove,
}: VideoListItemProps) {
	const handleRemoveConnection = async () => {
		const ok = await actions.matchVideos.removeMatchVideo(
			matchVideo.matchId,
			matchVideo.video.id
		);

		if (ok) {
			onRemove(matchVideo.id);
		}
	};

	const removeConnection = useDialog({
		message: t(
			'Are you sure you want to remove the connection between this video and match?'
		),
		onConfirm: handleRemoveConnection,
	});

	const [editModal, setEditModal] = useState(false);

	const openEditModal = () => setEditModal(true);
	const closeEditModal = () => {
		setEditModal(false);
	};

	const handleAfterSave = (video: models.video.Video) =>
		onUpdate({ ...matchVideo, video } as models.matchVideo.MatchVideo);

	let videoActions = null;

	if (canEdit) {
		videoActions = (
			<Context.Menu>
				<Context.Item icon="edit" onClick={openEditModal}>
					{t('Edit')}
				</Context.Item>
				<Context.Item caution icon="delete" onClick={removeConnection}>
					{t('Remove connection')}
				</Context.Item>
			</Context.Menu>
		);
	}

	return (
		<Fragment>
			<Video video={matchVideo.video} actions={videoActions} />

			{editModal && (
				<VideoModal
					videoId={matchVideo.video.id}
					onClose={closeEditModal}
					afterSave={handleAfterSave}
				/>
			)}
		</Fragment>
	);
}
