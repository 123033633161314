import { ReactNode } from 'react';

export const symbol_airplanemode_inactive: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-117 371-86q-8 2-14.5-3t-6.5-13v-12q0-4 2-7.5t5-5.5l73-55v-251l-318 94q-12 4-22-3.5T80-363v-11q0-7 3-12.5t9-8.5l236-139L77-785q-9-9-9-21t9-21q9-9 21-9t21 9l708 709q9 9 9 21.5T827-76q-8 8-21 7.5T785-77L530-332v150l72 54q4 3 6 7t2 9v5q0 10-8 16.5T584-87l-104-30Zm0-763q21 0 35.5 14.5T530-830v236l336 198q6 4 10 10t4 14v5q0 14-11 22.5t-25 4.5l-219-65-195-195v-230q0-21 14.5-35.5T480-880Z"
		/>
	</svg>
);
