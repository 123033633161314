import styled, { css } from 'styled-components';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';

import Badge from 'components/Badge';

const Card = styled.div`
	background: var(--palette-white);
	padding: var(--spacing-4);
	border-radius: var(--radius-5);
`;

const GroupWrapper = styled.div<{ isOrganization?: boolean }>`
	display: grid;
	gap: ${styles.spacing._1} ${styles.spacing._4};

	${({ isOrganization }) =>
		isOrganization
			? css`
					grid-template-areas: 'avatar group';
					grid-template-columns: auto 1fr;
				`
			: css`
					grid-template-areas: 'avatar organization' 'avatar group';
					grid-template-columns: auto 1fr;
					grid-template-rows: auto auto;
				`}

	${Badge} {
		align-self: center;
		grid-area: avatar;
		width: 44px;
		height: 44px;
	}

	span {
		grid-area: organization;
		align-self: flex-end;
		font-size: var(--font-size-sm);
		color: var(--palette-gray-500);
		word-break: break-all;
		word-wrap: break-word;
	}

	strong {
		grid-area: group;

		${({ isOrganization }) =>
			isOrganization
				? css`
						align-self: center;
					`
				: css`
						align-self: flex-start;
					`}

		font-size: ${styles.font.size.base};
		font-weight: ${styles.font.weight.semibold};
		color: ${styles.palette.gray[900]};
		word-break: break-all;
		word-wrap: break-word;
	}
`;

interface GroupCardProps {
	group?: models.group.Group;
}

export default function GroupCard({ group }: GroupCardProps): JSX.Element {
	const isOrganization = models.group.isOrganization(group);

	return (
		<Card>
			<GroupWrapper isOrganization={isOrganization}>
				<Badge badgeUrl={group.profileImageUrl} size="44px" />
				{!isOrganization && <span>{group.organization.name}</span>}
				<strong data-testid="onboarding.group_card.name">{group.name}</strong>
			</GroupWrapper>
		</Card>
	);
}
