import styled from 'styled-components';

export const Strain = styled.div`
	width: 100%;
`;

export const StrainDot = styled.div.attrs({
	style: ({ offset }) => ({
		left: `calc(${offset}% - 4px)`,
	}),
})`
	display: block;
	width: 14px;
	height: 14px;
	position: absolute;
	top: 100%;
	border-radius: var(--radius-4);
	transform: translate(-50%, -11px);
	background: transparent;
	border: 3px solid #ffffff;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.4);
	z-index: 10;
`;

StrainDot.defaultProps = {
	offset: 0,
};

export const IndicatorOverlay = styled.div.attrs({
	style: ({ offset }) => ({
		left: `${offset}%`,
	}),
})`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	background: rgba(235, 235, 235, 0.95);
`;

IndicatorOverlay.defaultProps = {
	offset: 0,
};
