import { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import * as palette from 'pkg/config/palette';

import { debounce } from 'pkg/timings';
import rgba from 'pkg/rgba';
import withForwardedRef from 'pkg/withForwardedRef';

const Wrapper = styled.div`
	width: 100%;
	height: 30px;
	position: relative;
`;

const ControllerThumb = css`
	appearance: none;
	background: transparent;
	width: 30px;
	height: 30px;
	border: none;
	box-shadow: none;
`;

const Controller = styled.input`
	background: transparent;
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 50;
	appearance: none;
	outline: none;
	left: 0;

	&:focus {
		outline: none;
	}

	&::-moz-focus-inner {
		outline: none;
	}

	&::-webkit-slider-thumb {
		${ControllerThumb};
	}

	&::-moz-range-thumb {
		${ControllerThumb};
	}

	&::-ms-thumb {
		${ControllerThumb};
	}
`;

const Slider = styled.svg`
	background: transparent;
	width: calc(100% - 6px);
	margin: 0 3px;
	height: 30px;
	left: 0;
	position: absolute;
	overflow: visible;
	z-index: 40;
`;

const Track = styled.g``;

const Range = styled.line`
	fill: none;
	stroke-width: 5px;
	stroke-linecap: round;
	stroke-linejoin: round;
	stroke-miterlimit: 10;
`;

const Progress = styled(Range)``;

const Thumb = styled.circle`
	stroke-width: 1px;
	stroke: ${rgba(palette.black, 0.3)};
`;

const NOOP = () => {};

class RangeSlider extends Component {
	calculateProgress(value) {
		const { min, max } = this.props;

		if (value > max) value = max;

		if (value < min) value = min;

		let progress = ((value - min) * 100) / (max - min);

		if (Number.isNaN(progress)) {
			progress = 0;
		}

		return progress;
	}

	handleControllerChange = (event) => {
		const value = Number(event.currentTarget.value);
		const progress = this.calculateProgress(value);

		this.props.onChange(value, progress);
		this.debouncedChangeHandler();
	};

	handleInputStart = () => this.props.onBeforeChange();

	disableUserKeyInput = (event) => event.preventDefault();

	debouncedChangeHandler = debounce(() => {
		this.props.onAfterChange();
	}, 300);

	get progresPercentage() {
		const progress = this.calculateProgress(this.props.value);

		return `${progress}%`;
	}

	get controllerProperties() {
		const { min, max, step, value, forwardedRef } = this.props;
		const type = 'range';

		return {
			type,
			min,
			max,
			step,
			ref: forwardedRef,
			value,
			tabIndex: '-1',
			onChange: this.handleControllerChange,
			onMouseDown: this.handleInputStart,
			onMouseUp: this.handleInputAbort,
			onKeyDown: this.disableUserKeyInput,
		};
	}

	get rangeProperties() {
		return {
			x1: '100%',
			y1: '50%',
			x2: '0%',
			y2: '50%',
			stroke: this.props.trackColor,
		};
	}

	get progressProperties() {
		return {
			x1: this.progresPercentage,
			y1: '50%',
			x2: '0%',
			y2: '50%',
			stroke: this.props.progressColor,
		};
	}

	get thumbProperties() {
		const { thumbSize, thumbColor } = this.props;

		const thumbSizes = {
			small: 6,
			medium: 10,
			large: 16,
		};

		return {
			cx: this.progresPercentage,
			cy: '50%',
			fill: thumbColor,
			r: thumbSizes[thumbSize],
		};
	}

	render() {
		return (
			<Wrapper className={this.props.className}>
				<Controller {...this.controllerProperties} />
				<Slider>
					<Track>
						<Range {...this.rangeProperties} />
						<Progress {...this.progressProperties} />
					</Track>
					<Thumb {...this.thumbProperties} />
				</Slider>
			</Wrapper>
		);
	}
}

RangeSlider.propTypes = {
	min: PropTypes.number,
	max: PropTypes.number,
	step: PropTypes.number,
	value: PropTypes.number.isRequired,
	onChange: PropTypes.func.isRequired,
	onBeforeChange: PropTypes.func,
	onAfterChange: PropTypes.func,

	thumbSize: PropTypes.oneOf(['small', 'medium', 'large']),
	thumbColor: PropTypes.string,
	trackColor: PropTypes.string,
	progressColor: PropTypes.string,
};

RangeSlider.defaultProps = {
	min: 0,
	max: 100,
	step: 1,

	thumbSize: 'medium',
	thumbColor: palette.white,
	trackColor: palette.gray[300],
	progressColor: palette.blue[500],

	onBeforeChange: NOOP,
	onAfterChange: NOOP,
};

export default withForwardedRef(RangeSlider);
