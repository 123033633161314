import { Component, Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import { fetchLogs, removeUserLog } from 'pkg/actions/user_logs';

import DateTime from 'pkg/datetime';
import { IdentityContext } from 'pkg/identity';

import EditList from 'components/EditList';
import { FormattedContent } from 'components/formatted-content';
import Avatar from 'components/avatar';

import Button from 'design/button';

const LogsWrapper = styled.div`
	padding-top: 10px;

	@media ${styles.breakpoint.small} {
		padding-top: 0;
	}
`;

const LogsFooter = styled.div`
	padding: 16px 16px 18px;
`;

const LogWrapper = styled.div`
	display: grid;
	position: relative;
	border-bottom: 1px solid #eee;
	grid-template-areas: 'avatar stats' 'avatar content';
	grid-template-columns: 40px 1fr;
	align-items: center;
	padding: 16px 20px;

	@media ${styles.breakpoint.small} {
		padding: 8px 16px;
		grid-template-columns: 46px 1fr;
	}
`;

const Log = {};

Log.DateTime = styled.time`
	font-weight: var(--font-weight-normal);
	font-size: var(--font-size-sm);
	color: var(--palette-gray-500);
	margin-right: 3px;

	@media ${styles.breakpoint.small} {
		flex: 1 1 100%;
		margin-bottom: 5px;
	}
`;

Log.Author = styled.div`
	font-size: var(--font-size-sm);
	font-weight: var(--font-weight-normal);
	color: var(--palette-gray-500);
	margin-right: 5px;
`;

Log.Group = styled.div`
	font-size: var(--font-size-sm);
	font-weight: var(--font-weight-normal);
	color: var(--palette-gray-500);
`;

Log.Content = styled.div`
	grid-area: content;
	font-size: var(--font-size-base);
	line-height: 1.35;
	margin: 3px 0 0 0;
	flex: 1 1 100%;
	font-weight: normal;

	@media ${styles.breakpoint.small} {
		margin-bottom: 10px;
	}
`;

Log.Stats = styled.div`
	grid-area: stats;
	display: flex;
	align-items: center;
	flex-flow: row wrap;

	@media ${styles.breakpoint.small} {
		padding: 10px 0 5px;
	}
`;

Log.Avatar = styled.div`
	grid-area: avatar;
	display: flex;
	width: 30px;
	margin-right: 0.5rem;
`;

class SingleLog extends Component {
	state = {
		activeEditList: false,
	};

	openEditList = (e) => {
		e.stopPropagation();
		if (this.state.activeEditList) {
			return;
		}

		this.setState(
			{ activeEditList: true },
			document.addEventListener('click', this.closeEditList, { once: true })
		);
	};

	closeEditList = () => {
		this.setState({ activeEditList: false });
	};

	get editListProps() {
		return {
			main: true,
			openEditList: this.openEditList,
			active: this.state.activeEditList,
			togglePostModal: () =>
				this.setState({ activeEditList: !this.state.activeEditList }),
			handleDeleteItem: (id) => this.props.removeUserLog(this.props.userId, id),
			item: this.props.log,
			deletePath: this.props.log.links?.delete,
			theme: 'default',
			deleteConfirm: t(`Do you want to delete this event?`),
		};
	}

	render() {
		const {
			log: { author, group, content, createdAt },
		} = this.props;

		return (
			<LogWrapper>
				<Log.Avatar>
					<Avatar user={author} />
				</Log.Avatar>

				<Log.Stats>
					<Log.DateTime>
						{new DateTime(new Date(createdAt * 1000)).toLongDateString()}
					</Log.DateTime>

					<Log.Author>
						{t(`by {name}`, {
							name: `${author.firstName} ${author.lastName}`,
						})}
					</Log.Author>

					<Log.Group>
						{t(`in {team_name}`, { team_name: group.name })}
					</Log.Group>
				</Log.Stats>

				<Log.Content>
					<FormattedContent raw={content} />
				</Log.Content>

				<EditList
					{...this.editListProps}
					styles={{ top: '15px', right: '15px' }}
				/>
			</LogWrapper>
		);
	}
}

class Logs extends Component {
	static contextType = IdentityContext;

	static propTypes = {
		user: PropTypes.object.isRequired,
		groupId: PropTypes.number.isRequired,
		fetchLogs: PropTypes.func,
	};

	static defaultProps = {
		expanded: false,
	};

	componentDidMount() {
		this.fetchLogs(null, false);
	}

	fetchLogs = (e, fetchNext = true) => {
		this.props.fetchLogs(this.props.user.id, this.props.groupId, fetchNext);
	};

	get loadMore() {
		if (!this.props.expanded || !this.props.links?.next) {
			return null;
		}

		return (
			<Button
				transparent
				key="read-more"
				onClick={this.fetchLogs}
				type="primary">
				{t('Show more')}
			</Button>
		);
	}

	get readMore() {
		if (this.props.expanded) {
			return null;
		}

		if (Object.keys(this.props.links) === 0) {
			return null;
		}

		return (
			<Button
				transparent
				key="read-more"
				href={`/user/logs/${this.props.user.id}`}
				type="primary">
				{t('Show more')}
			</Button>
		);
	}

	get isSelf() {
		return this.props.user.id === this.context.membership.userId;
	}

	render() {
		if (this.props.logs.length === 0 && !this.isSelf) {
			return null;
		}

		return (
			<Fragment>
				<LogsWrapper key="logs">
					{this.props.logs.map((log) => (
						<SingleLog
							key={log.id}
							log={log}
							userId={this.props.user.id}
							removeUserLog={this.props.removeUserLog}
						/>
					))}
				</LogsWrapper>
				{(this.readMore || this.loadMore) && (
					<LogsFooter key="logs-footer">
						{this.readMore}
						{this.loadMore}
					</LogsFooter>
				)}
			</Fragment>
		);
	}
}

const mapStateToProps = (state, { user }) => ({
	logs: state.userLogs.entities[user.id]?.records || [],
	links: state.userLogs.entities[user.id]?.links || {},
});

const actions = {
	fetchLogs,
	removeUserLog,
};

export default connect(mapStateToProps, actions)(Logs);
