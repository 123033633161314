import { ReactNode } from 'react';

export const symbol_shutter_speed_minus: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M650-160q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T650-220h245q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T895-160H650ZM381-860q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T381-920h198q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T579-860H381Zm95 344h294q-20-68-65-121.5T594-718L476-516Zm-68 45 152-259q-19.918-5-40.459-7.5Q499-740 479-740q-51 0-99.5 16.5T292-673l116 202Zm-224 76h226L264-649q-48 52-69.5 118.5T184-395Zm186 235 113-199H192q20 68 66.5 120T370-160Zm115-280Zm77 289Zm216-250ZM480-80q-75 0-140.5-28.5t-114-77q-48.5-48.5-77-114T120-440q0-75 28.5-140.5t77-114q48.5-48.5 114-77T480-800q68 0 127.5 23T715-712l35-35q9-9 22-9t22 9q9 9 8.5 22t-9.5 22l-35 35q47 57 64.5 123.5T840-402q0 16-10 24.5t-22.5 8.5q-12.5 0-21-9.487Q778-387.975 778-402q2-26 1.5-44.5t-2.77-33.5H594L406-149q19 5.143 37.406 7.071Q461.812-140 482-140q20 0 40-3t40-8q12-3 23 3t14 18q3 12-3 23t-18 14q-23 6-47.375 9.5T480-80Z"
		/>
	</svg>
);
