import { ReactNode } from 'react';

export const symbol_wb_incandescent: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M450-110v-58q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-168v58q0 12.75-8.675 21.375Q492.649-80 479.825-80 467-80 458.5-88.625T450-110ZM110-510h58q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T168-450h-58q-12.75 0-21.375-8.675Q80-467.351 80-480.175 80-493 88.625-501.5T110-510Zm682 0h58q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-450h-58q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T792-510Zm-74 310-40-39q-9-9-8.5-21t9.5-21q9-9 21.5-9t21.5 9l39 39q9 9 9 21t-9 21q-9 9-21.5 9t-21.5-9Zm-518-43 39-39q9-9 21-8.5t21 9.5q9 9 9 21.5t-9 21.5l-39 39q-9 9-21 8.5t-21-9.5q-9-9-9-21.5t9-21.5Zm280-55q-75 0-128.5-53.5T298-480q0-49 21-88t59-64v-148q0-24.75 17.625-42.375T438-840h84q24.75 0 42.375 17.625T582-780v148q38 25 59 64t21 88q0 75-53.5 128.5T480-298Zm-42-355q10.5-3 21-4.5t21-1.5q10.5 0 21 1.5t21 4.5v-127h-84v127Zm42.118 295Q531-358 566.5-393.618q35.5-35.617 35.5-86.5Q602-531 566.382-566.5q-35.617-35.5-86.5-35.5Q429-602 393.5-566.382q-35.5 35.617-35.5 86.5Q358-429 393.618-393.5q35.617 35.5 86.5 35.5ZM480-480Z"
		/>
	</svg>
);
