import { ReactNode } from 'react';

export const symbol_policy: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-84q-5 0-10-.75T460-87q-138-47-219-168.5t-81-266.606V-718q0-19.257 10.875-34.662Q181.75-768.068 199-775l260-97q11-4 21-4t21 4l260 97q17.25 6.932 28.125 22.338Q800-737.257 800-718v196q0 70-17 139t-59 130q-8 11-21.5 12.5T679-248L563-359q-19 11-40.161 18-21.162 7-42.839 7-62 0-105.5-43T331-482.5q0-62.5 43.5-106T480-632q62 0 105.5 43.5T629-482q0 21-6 42t-19 38l88 84q24-43 36-96.5T740-522v-198.484L480-815l-260 94.516V-522q0 131 72.5 236.5T480-142q23-6 54.5-23.5T592-203q10-8 22-7t20 11.5q8 10.5 6 23.5t-13 21q-29 22-60.5 38T500-87q-5 1.5-10 2.25T480-84Zm.204-310Q517-394 543-419.5t26-63q0-37.5-26.204-63.5-26.203-26-63-26Q443-572 417-546t-26 63.5q0 37.5 26.204 63 26.203 25.5 63 25.5ZM480-479Z"
		/>
	</svg>
);
