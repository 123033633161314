import { ReactNode } from 'react';

export const symbol_file_map: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-60h600v-600H180v600Zm300-541q-76.792 0-135.396 53Q286-615 286-524q0 76 61 148.5T460-257q4.5 4 9.75 6t10.25 2q5 0 10-1.5t10-5.5q53-46 113.5-119T674-524q0-91-58.604-144T480-721Zm0 253q-23 0-39.5-16.5T424-524q0-23 16.5-39.5T480-580q23 0 39.5 16.5T536-524q0 23-16.5 39.5T480-468ZM180-180v-600 600Z"
		/>
	</svg>
);
