import { Fragment, useState } from 'react';
import { t } from '@transifex/native';

import * as StepModal from 'components/step-modal';

import * as Input from 'components/form/inputs';
import Row from 'components/layout/row';

import * as Table from 'design/table';

export interface ExportField {
	key: string;
	label: string;
}

interface ExportHook {
	modal: JSX.Element;
	open: () => void;
}

/**
 * The useExport hook has helper methods to create export requests
 */
export function useExport(
	fields: ExportField[],
	handleCreate: (fields: string[]) => Promise<void>
): ExportHook {
	const [selectedFields, setSelectedFields] = useState(
		fields.map((f) => f.key)
	);
	const [isOpen, setIsOpen] = useState(false);

	const handleChangeSelectedFields = (field: string) => {
		if (selectedFields.includes(field)) {
			setSelectedFields(selectedFields.filter((f) => f !== field));
		} else {
			setSelectedFields([...selectedFields, field]);
		}
	};

	const handleToggleAll = () => {
		setSelectedFields(
			selectedFields.length === fields.length ? [] : fields.map((f) => f.key)
		);
	};

	const handleOpenExport = () => {
		setIsOpen(true);
	};

	const hideModal = () => {
		setIsOpen(false);
	};

	const handleNext = async () => {
		await handleCreate(selectedFields);
	};

	return {
		open: handleOpenExport,
		modal: isOpen && (
			<StepModal.Base onClose={hideModal}>
				<StepModal.Step title={t('Select fields')} onNext={handleNext} skipBody>
					<Table.Table
						columns={[
							{
								content: (
									<Row autoColumns="max-content" align="center">
										<Input.Control
											type="checkbox"
											standalone
											checked={selectedFields.length === fields.length}
											onChange={handleToggleAll}
										/>
										{t('Select all')}
									</Row>
								),
							},
						]}>
						{fields.map((f, i) => (
							<Table.Row
								key={i}
								onClick={() => handleChangeSelectedFields(f.key)}>
								<Table.Cell>
									<Row autoColumns="max-content" align="center">
										<Input.Control
											type="checkbox"
											standalone
											checked={selectedFields.includes(f.key)}
										/>
										<Fragment>{f.label}</Fragment>
									</Row>
								</Table.Cell>
							</Table.Row>
						))}
					</Table.Table>
				</StepModal.Step>
			</StepModal.Base>
		),
	};
}
