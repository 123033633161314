import { ReactNode } from 'react';

export const symbol_smart_card_reader_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M697-930q24 0 42 18t18 42v460q0 12.75-9 21.375T726.5-380q-12.5 0-21-8.5T697-410v-460H255q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T255-930h442ZM554-541l-71-71q0-29 21-50t50-21q29.287 0 50.144 20.856Q625-641.287 625-612q0 29-21 50t-50 21ZM323-687l110 110q-1.667 22.971-8.333 44.986Q418-510 404-490.588 393-477 378.067-468q-14.934 9-33.067 9-9 0-15.5-6.5T323-481v-206ZM140-190h594l-60-60H140v60Zm-30 60q-12.75 0-21.375-8.625T80-160v-90q0-24.75 17.625-42.375T140-310h474L263-661v241q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T203-420v-301L47-877q-9-9-9-21.5t9-21.5q9-9 21.5-9t21.5 9l765 765q9 9.273 8.5 21.636Q863-121 854-112t-21.5 8.5q-12.5-.5-21.414-9.414L794-130H110Zm327-60Zm24-444Zm-62 108Z"
		/>
	</svg>
);
