import { ReactNode } from 'react';

export const symbol_portable_wifi_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M792-83 317-557q-8 17-12.5 36.5T300-480q0 32 10.5 60t28.5 51q8 10 8 23t-9 22q-9 9-21.5 8.5T296-326q-26-32-41-70.5T240-480q0-33 8.5-63.5T273-601l-73-73q-28 42-44 91t-16 103q0 65 22 122t62 102q9 10 10 22.5t-8 21.5q-9 9-22.5 9T182-213q-48-54-75-121.5T80-480q0-67 20.5-127T157-717l-75-75q-9-9-9-21.5t9-21.5q9-9 21.5-9t21.5 9l710 710q9 9 9 21t-9 21q-9 9-21.5 9T792-83Zm28-397q0-68-25.5-130.5T721-721q-48-48-110.5-73.5T480-820q-50 0-97.5 14.5T293-764q-11 7-23 5.5T250-770q-8-10-6.5-22t12.5-19q50-35 107-52t117-17q80 0 153 30t130 87q57 57 87 130t30 153q0 60-17 117.5T811-256q-7 10-18.5 12t-21.5-5q-11-8-13.5-20t4.5-23q29-42 43.5-89.5T820-480ZM608-608q-26-26-59-39t-69-13q-20 0-39.5 4.5T403-643q-11 5-23 1t-17-15q-5-11-1-23t15-17q24-11 50-17t53-6q48 0 92 18t78 52q34 34 52 78t18 92q0 27-6 53t-17 50q-5 11-17 15t-23-1q-11-5-15-17t1-23q8-18 12.5-37.5T660-480q0-36-13-69t-39-59Z"
		/>
	</svg>
);
