import { ReactNode } from 'react';

export const symbol_delete_history: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M568-163q0 14-8 25t-22 13q-15 3-30.5 4t-30.5 1q-67 0-130-24.5T234-216q-48-45-76.5-103T122-445q-2-12 6-21.5t20-11.5q12-2 21.5 6.5T181-451q6 57 31 106t65 86q42 39 93.5 59T477-180q15 0 30.5-1.5T538-186q11-2 20.5 5t9.5 18Zm186-40-63 62q-9 8-21 8.5t-21-8.5q-9-9-9-21t9-21l63-63-63-63q-9-9-9-21t9-21q9-9 21-9t21 9l63 63 63-63q9-9 21-9t21 9q9 9 8.5 21t-8.5 21l-62 63 62 63q8 9 8.5 21t-8.5 21q-9 9-21 8.5t-21-8.5l-63-62ZM477-780q-68 0-127.5 31T246-667h75q13 0 21.5 8.5T351-637q0 13-8.5 21.5T321-607H172q-13 0-21.5-8.5T142-637v-148q0-13 8.5-21.5T172-815q13 0 21.5 8.5T202-785v76q52-61 123.5-96T477-840q75 0 140.5 27.5T733-736q48 48 74 104t31 121q1 12-7 22t-20 11q-12 1-21.5-7.5T779-506q-5-54-25.5-101T691-694q-42-40-96.5-63T477-780Zm34 286 64 62q11 11 9.5 23t-9.5 20q-8 8-19.5 9.5T533-389l-73-71q-5-5-7-10.5t-2-11.5v-171q0-13 8.5-21.5T481-683q13 0 21.5 8.5T511-653v159Z"
		/>
	</svg>
);
