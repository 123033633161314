import { t } from '@transifex/native';

import DateTime from 'pkg/datetime';
import { cssClasses, cssVarList } from 'pkg/css/utils';

import { hourHeight } from 'routes/group/calendar/config';

import * as eventStyles from 'components/event/calendar-styles/styles.css';

import * as css from './styles.css';

interface MeetTimeProps {
	meetBeforeMinutes: number;
	time: DateTime;
}

const MeetTime = ({ meetBeforeMinutes, time }: MeetTimeProps) => {
	const height = (meetBeforeMinutes / 60) * hourHeight;
	const displayTime = time.toTimeString();

	return (
		<div
			style={cssVarList({
				height: `${height}px`,
			})}
			className={cssClasses(css.time, eventStyles['time-color-styles'])}>
			{height > 12 && (
				<small>{t(`Meet time {time}`, { time: displayTime })}</small>
			)}
		</div>
	);
};

export default MeetTime;
