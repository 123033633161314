import { stringify, parse } from 'pkg/json';

export enum LocalStorageKeys {
	AuthToken = 'authToken',
	AccountToken = 'accountToken',
	Organizations = 'organizations',
	ActiveMembership = 'activeMembership',
	ActiveOrganizationId = 'activeOrganizationId',
	CalendarFilters = 'calendarFilters',
	UserLogin = 'userLogin',
	SplashScreen = 'splashScreen',
	CollapsedDrawer = 'collapsedDrawer',
	FormCategoriesView = 'formCategoriesView',
}

export function getItem(key: LocalStorageKeys) {
	return localStorage.getItem(key);
}

export function getJsonItem<T>(key: LocalStorageKeys) {
	return parse<T>(localStorage.getItem(key));
}

export function setItem(key: LocalStorageKeys, value: string) {
	return localStorage.setItem(key, value);
}

export function setJsonItem<T>(key: LocalStorageKeys, values: T) {
	return localStorage.setItem(key, stringify(values));
}

export function removeItem(key: LocalStorageKeys) {
	return localStorage.removeItem(key);
}

export function removeItems(...keys: LocalStorageKeys[]) {
	return keys.forEach((key) => localStorage.removeItem(key));
}
