import { ReactNode } from 'react';

export const symbol_swipe_left_alt: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M600-280q-82 0-133.5-50.5T401-450H194l68 68q9 9 9 21.1 0 12.1-9 20.9-9 9-21 9t-21-9L101-459q-5-5-7-10.133-2-5.134-2-11Q92-486 94-491q2-5 7-10l119-119q9-9 21.158-9 12.158 0 21 8.609Q271-611 271-598.9t-9 20.9l-68 68h207q14-69 65.5-119.5T600-680q83 0 141.5 58.5T800-480q0 83-58.5 141.5T600-280Zm-.235-60Q658-340 699-380.765q41-40.764 41-99Q740-538 699.235-579q-40.764-41-99-41Q542-620 501-579.235q-41 40.764-41 99Q460-422 500.765-381q40.764 41 99 41ZM600-480Z"
		/>
	</svg>
);
