// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.w57goojvbYEiRB7J0xZp {
	font-weight: var(--font-weight-semibold);
	margin-bottom: var(--spacing-1);
}

.Ai1EURDV8dXb5WuXHjdL {
	font-size: var(--font-size-sm);
	margin-top: var(--spacing-2);
	color: var(--palette-gray-600);
}
`, "",{"version":3,"sources":["webpack://./routes/organization/contacts/single/sidebar/top/styles.css"],"names":[],"mappings":"AAAA;CACC,wCAAwC;CACxC,+BAA+B;AAChC;;AAEA;CACC,8BAA8B;CAC9B,4BAA4B;CAC5B,8BAA8B;AAC/B","sourcesContent":[".contactInfoName {\n\tfont-weight: var(--font-weight-semibold);\n\tmargin-bottom: var(--spacing-1);\n}\n\n.createdDate {\n\tfont-size: var(--font-size-sm);\n\tmargin-top: var(--spacing-2);\n\tcolor: var(--palette-gray-600);\n}\n"],"sourceRoot":""}]);
// Exports
export var contactInfoName = `w57goojvbYEiRB7J0xZp`;
export var createdDate = `Ai1EURDV8dXb5WuXHjdL`;
export default ___CSS_LOADER_EXPORT___;
