import { ReactNode } from 'react';

export const symbol_blur_short: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M599.765-340Q658-340 699-380.765q41-40.764 41-99Q740-538 699.235-579q-40.764-41-99-41Q542-620 501-579.235q-41 40.764-41 99Q460-422 500.765-381q40.764 41 99 41Zm.235 60q-73.582 0-129.791-48.5T402-450H270q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T270-510h132q4-32 18-60t37-50H190q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T190-680h410q83 0 141.5 58.5T800-480q0 83-58.5 141.5T600-280Z"
		/>
	</svg>
);
