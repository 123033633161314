import { ReactNode } from 'react';

export const symbol_family_restroom: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M720.08-727q-30.08 0-51.58-21.42-21.5-21.421-21.5-51.5 0-30.08 21.42-51.58 21.421-21.5 51.5-21.5 30.08 0 51.58 21.42 21.5 21.421 21.5 51.5 0 30.08-21.42 51.58-21.421 21.5-51.5 21.5ZM660-110v-306q0-30-15-54t-44-35l41-120q8-25 29.5-40t48.5-15q27 0 48.5 15t29.5 40l88 255q5 14-3.5 27T858-330h-68v220q0 12.75-8.625 21.375T760-80h-70q-12.75 0-21.375-8.625T660-110ZM495-500q-25 0-42.5-17.5T435-560q0-25 17.5-42.5T495-620q25 0 42.5 17.5T555-560q0 25-17.5 42.5T495-500ZM220.08-727q-30.08 0-51.58-21.42-21.5-21.421-21.5-51.5 0-30.08 21.42-51.58 21.421-21.5 51.5-21.5 30.08 0 51.58 21.42 21.5 21.421 21.5 51.5 0 30.08-21.42 51.58-21.421 21.5-51.5 21.5ZM150-110v-260h-40q-12.75 0-21.375-8.625T80-400v-220q0-24.75 17.625-42.375T140-680h160q24.75 0 42.375 17.625T360-620v220q0 12.75-8.625 21.375T330-370h-40v260q0 12.75-8.625 21.375T260-80h-80q-12.75 0-21.375-8.625T150-110Zm290 0v-140h-20q-12.75 0-21.375-8.625T390-280v-136q0-18.333 12.833-31.167Q415.667-460 434-460h122q18.333 0 31.167 12.833Q600-434.333 600-416v136q0 12.75-8.625 21.375T570-250h-20v140q0 12.75-8.625 21.375T520-80h-50q-12.75 0-21.375-8.625T440-110Z"
		/>
	</svg>
);
