import { ReactNode } from 'react';

export const symbol_heap_snapshot_large: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M580.5-230q20.5 0 35-15t14.5-35.5q0-20.5-14.583-35Q600.833-330 580-330q-20 0-35 14.583-15 14.584-15 35.417 0 20 15 35t35.5 15ZM357-257q9 9 21 9t21-9l204-204q9-9 9-21t-9-21q-9-9-21-9t-21 9L357-299q-9 9-9 21t9 21Zm23.5-173q20.5 0 35-15t14.5-35.5q0-20.5-14.583-35Q400.833-530 380-530q-20 0-35 14.583-15 14.584-15 35.417 0 20 15 35t35.5 15ZM220-80q-24 0-42-18t-18-42v-680q0-24 18-42t42-18h361l219 219v521q0 24-18 42t-42 18H220Zm331-614v-126H220v680h520v-494H611q-24.75 0-42.375-17.625T551-694ZM220-820v186-186 680-680Z"
		/>
	</svg>
);
