export default {
	name: 'nav-profile',
	objects: [
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M12 12.0365C13.5558 12.0365 14.8171 10.7753 14.8171 9.21947C14.8171 7.66365 13.5558 6.40242 12 6.40242C10.4442 6.40242 9.18297 7.66365 9.18297 9.21947C9.18297 10.7753 10.4442 12.0365 12 12.0365ZM12 13.3365C14.2738 13.3365 16.1171 11.4933 16.1171 9.21947C16.1171 6.94568 14.2738 5.10242 12 5.10242C9.72624 5.10242 7.88297 6.94568 7.88297 9.21947C7.88297 11.4933 9.72624 13.3365 12 13.3365Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M5.01242 19.6704C5.01242 16.7375 7.39005 14.3598 10.323 14.3598H13.677C16.61 14.3598 18.9876 16.7375 18.9876 19.6704H17.6876C17.6876 17.4554 15.892 15.6598 13.677 15.6598H10.323C8.10802 15.6598 6.31242 17.4554 6.31242 19.6704H5.01242Z',
			},
		},
	],
};
