import { ReactNode } from 'react';

export const symbol_nearby_error: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M844.825-213Q832-213 823.5-221.625T815-243v-283q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T875-526v283q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM845-73q-14 0-24.5-10.5T810-108q0-14 10.5-24.5T845-143q14 0 24.5 10.5T880-108q0 14-10.5 24.5T845-73ZM479.814-83q-11.187 0-22-5Q447-93 438-101L101-438q-8-9-12.5-20.305t-4.5-23Q84-493 88.5-504t12.5-19l338-338q9-8.5 19.875-12.75T481-878q11.25 0 22.125 4T522-861l222 222q5 5 8 12t3 15.088Q755-595 743.057-584q-11.942 11-27.352 11-7.705 0-14.282-2.567Q694.846-578.133 689-584L480-793 167-480l313 313 209-209q6-6.4 13.125-9.2Q709.25-388 716-388q15 0 27 11t12 27.912Q755-341 752-334t-8 12L523-101q-9.366 9-20.683 13.5T479.814-83ZM459-278 278-459q-5-5-7-10.5t-2-11q0-5.5 2-10.5t7-10l181-181q5-5 10.5-7t11-2q5.5 0 10.5 2t10 7l181 181q5 5 7 10.091 2 5.091 2 10.909 0 5-2 10.5t-7 10.5L501-278q-5 5-10.091 7-5.091 2-10.909 2-5 0-10.5-2t-10.5-7Z"
		/>
	</svg>
);
