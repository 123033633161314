import { ReactNode } from 'react';

export const symbol_domain_verification_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m812-63-98-97H140q-24.75 0-42.375-17.625T80-220v-520q0-24.75 17.625-42.375T140-800h20v86L47-827q-9-9.067-8.5-21.533Q39-861 48.053-870q9.052-9 21.5-9Q82-879 91-870l764 765q9 9 9 21t-9.053 21q-9.052 9-21.5 9Q821-54 812-63ZM140-220h514L218-656h-78v436Zm446-240-44-44 42-42q9.13-9 22.065-9T628-545.897q9 9.104 9 22Q637-511 628-502l-42 42Zm-43 43-84 84q-9 9-21 9t-21-9l-85-85q-9-9.13-9-22.065T332.103-462q9.104-9 22-9Q367-471 376-462l62 62 61-61 44 44Zm337-323v512q0 15-9.318 22.5t-20.5 7.5Q839-198 829.5-205.683 820-213.366 820-228v-428H415q-12.444 0-23.722-5T372-674l-75-75q-6.923-7.812-8.462-16.406Q287-774 290-782q3-8 10.5-13t17.5-5h502q24.75 0 42.375 17.625T880-740Z"
		/>
	</svg>
);
