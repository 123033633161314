import { Record } from 'immutable';

import { matchCompetition } from 'pkg/models/schemas';

export default class extends Record(
	{
		id: null,
		groupId: null,
		title: '',
		description: '',
		startsAt: null,
		endsAt: null,
		externalId: null,
	},
	'matchCompetition'
) {
	static normalizr() {
		return matchCompetition;
	}
}
