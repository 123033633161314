import { ReactNode } from 'react';

export const symbol_water_damage: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M261-220h438v-377L480-765 261-597v377Zm0 60q-24.75 0-42.375-17.625T201-220v-332l-101 78q-9.609 8-21.804 6Q66-470 58-480.37q-8-9.63-6.056-21.82Q53.89-514.381 64-522l380-291q17-12 36-12t36 12l381 291q10 8 11.5 20t-6.5 22q-8 10-20 12t-22-6l-101-78v332q0 24.75-17.625 42.375T699-160H261Zm219.089-170Q509-330 529.5-350.562 550-371.125 550-400q0-26-10-46t-60-92q-50 72-60 92t-10 46q0 28.875 20.589 49.438Q451.177-330 480.089-330ZM480-400Z"
		/>
	</svg>
);
