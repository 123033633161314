import { ReactNode } from 'react';

export const symbol_motion_photos_on: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-34 5.5-67.01Q91-580.021 102-612q4-11 15-14.5t21 2.798q10.714 6.298 15.357 18.5Q158-593 155-581q-6 25-10.5 50.147T140-480q0 142.375 98.812 241.188Q337.625-140 480-140t241.188-98.812Q820-337.625 820-480t-98.812-241.188Q622.375-820 480-820q-27.574 0-54.574 4-27 4-53.426 12-12 4-24-1t-17-16q-5-11 1-22t17-15q31-10 63-16t64-6q83 0 156.5 31.5T760.947-763q54.948 54 87 127Q880-563 880-480t-31.5 156Q817-251 763-197t-127 85.5Q563-80 480-80ZM212.882-699Q192-699 177.5-713.618q-14.5-14.617-14.5-35.5Q163-770 177.618-784.5q14.617-14.5 35.5-14.5Q234-799 248.5-784.382q14.5 14.617 14.5 35.5Q263-728 248.382-713.5q-14.617 14.5-35.5 14.5ZM480-480Z"
		/>
	</svg>
);
