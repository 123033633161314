import { ReactNode } from 'react';

export const symbol_folder_supervised: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M591-160q-12.75 0-21.375-8.625T561-190q0-33 42.5-55T721-267q75 0 117.5 22t42.5 55q0 12.75-8.625 21.375T851-160H591Zm130.08-174q-30.08 0-51.58-21.42-21.5-21.421-21.5-51.5 0-30.08 21.42-51.58 21.421-21.5 51.5-21.5 30.08 0 51.58 21.42 21.5 21.421 21.5 51.5 0 30.08-21.42 51.58-21.421 21.5-51.5 21.5ZM140-220v-520 220-20 320Zm0 60q-24 0-42-18.5T80-220v-520q0-23 18-41.5t42-18.5h256q12.444 0 23.722 5t19.366 13.088L481-740h339q23 0 41.5 18.5T880-680v158q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T820-522v-158H456l-60-60H140v520h335q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T475-160H140Z"
		/>
	</svg>
);
