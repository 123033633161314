import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import { CollectionSort } from 'pkg/api/use_collection';
import { EventTypes, eventTypeLabels } from 'pkg/api/models/event';
import { useCurrentGroupId, useCurrentOrganization } from 'pkg/identity';
import { precise } from 'pkg/numbers';
import * as routes from 'pkg/router/routes';
import { pushState } from 'pkg/router/state';
import { useCurrentRoute } from 'pkg/router/hooks';
import { UrlParser } from 'pkg/url';

import Label from 'components/label';

import * as Table from 'design/table';

const url = new UrlParser();

interface GroupReportTableProps {
	isLoading: boolean;
	sort: CollectionSort;

	groupReports: models.eventReport.GroupReport[];
}

interface GroupReportRowProps {
	groupReport: models.eventReport.GroupReport;
}

function GroupReportRow({ groupReport }: GroupReportRowProps) {
	const currentGroupId = useCurrentGroupId();
	const route = useCurrentRoute();
	const org = useCurrentOrganization();

	const query: { [key: string]: string } = {
		targetGroupId: groupReport.groupId.toString(),
	};

	if (route.query?.dateLabel) {
		query.dateLabel = route.query.dateLabel;
	}

	if (route.query?.startOfRange) {
		query.startOfRange = route.query.startOfRange;
	}

	if (route.query?.endOfRange) {
		query.endOfRange = route.query.endOfRange;
	}

	const handleClick = () => {
		pushState(
			url.transform(
				routes.Statistics.AttendanceReport.Show(
					org.id,
					currentGroupId,
					'event-report'
				),
				query
			)
		);
	};

	return (
		<Table.Row onClick={handleClick}>
			<Table.Cell>{groupReport.group.name}</Table.Cell>
			<Table.Cell>{groupReport.events?.length || 0}</Table.Cell>
			<Table.Cell>
				{`${groupReport.approvedLokEvents} / ${
					groupReport.events?.length || 0
				}`}
			</Table.Cell>
			{eventTypeLabels.map((type, i) => {
				let count = 0;

				switch (type) {
					case EventTypes.Camp:
						count = groupReport.campEventsCount;
						break;
					case EventTypes.Cup:
						count = groupReport.cupEventsCount;
						break;
					case EventTypes.Match:
						count = groupReport.matchEventsCount;
						break;
					case EventTypes.Meeting:
						count = groupReport.meetingEventsCount;
						break;
					case EventTypes.Practice:
						count = groupReport.practiceEventsCount;
						break;
					case EventTypes.Uncategorized:
						count = groupReport.uncategorizedEventsCount;
						break;
				}

				return <Table.Cell key={i}>{count}</Table.Cell>;
			})}
			<Table.Cell>{groupReport.totalEventUsers}</Table.Cell>
			<Table.Cell>
				<Label color="green">{`${precise(
					groupReport.attendanceRate,
					2
				)}%`}</Label>
			</Table.Cell>
			<Table.Cell>
				<Label color="red">{`${groupReport.injuredAmount} (${precise(
					groupReport.injuredRate,
					2
				)}%)`}</Label>
			</Table.Cell>
			<Table.Cell>
				<Label color="red">{`${groupReport.illnessAmount} (${precise(
					groupReport.illnessRate,
					2
				)}%)`}</Label>
			</Table.Cell>
			<Table.Cell>
				<Label color="red">{`${groupReport.otherAbsenceAmount} (${precise(
					groupReport.otherAbsenceRate,
					2
				)}%)`}</Label>
			</Table.Cell>
			<Table.Cell>
				<Label color="orange">{`${precise(
					groupReport.unhandledRate,
					2
				)}%`}</Label>
			</Table.Cell>
		</Table.Row>
	);
}

export default function GroupReportTable({
	isLoading,
	sort,
	groupReports,
}: GroupReportTableProps) {
	const org = useCurrentOrganization();

	return (
		<Table.Table
			columns={[
				{
					content: t('Group'),
					sortKey: 'group_name',
				},
				{
					content: t('Total events'),
					sortKey: 'total_events',
				},
				{
					content: 'Godkända LOK-events',
					hide: !models.group.isLOKActive(org),
					sortKey: 'approved_lok_events',
				},
				...eventTypeLabels.map((type) => {
					return {
						content: models.event.getTypeTranslation(type),
						sortKey: type,
					} as Table.HeaderColumn;
				}),
				{ content: t('Total invited users'), sortKey: 'total_event_users' },
				{
					content: t('Attendance rate'),
					sortKey: 'attendance_rate',
				},
				{
					content: t('Absence: Injured'),
					sortKey: 'injured_rate',
				},
				{
					content: t('Absence: Illness'),
					sortKey: 'illness_rate',
				},
				{ content: t('Absence: Other'), sortKey: 'other_absence_rate' },
				{
					content: t('Unhandled rate'),
					sortKey: 'unhandled_rate',
				},
			]}
			sortBy={sort.column}
			sortOrder={sort.order}
			onSort={sort.setSort}
			isLoading={isLoading}>
			{groupReports.map((report) => (
				<GroupReportRow key={report.id} groupReport={report} />
			))}
		</Table.Table>
	);
}
