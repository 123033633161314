import { ReactNode } from 'react';

export const symbol_sports_motorsports: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-480Zm88 260q106 0 179-77.5T820-482q0-110-78.5-184T553-740q-56 0-105 13.5T345-684l121 45q51 19 69.5 54.5T554-504q0 60-40 100.5T416-363H146q-5 23-5.5 61.5T140-220h428ZM153-423h257q37 0 60.5-23.5T494-504q0-29-13.5-49T441-582l-154-64q-50 44-83 99t-51 124Zm415 263H140q-24 0-42-18t-18-42v-75q0-108 36-200.5t99.5-160q63.5-67.5 150-106T553-800q67 0 126.5 24.5t104 67.5Q828-665 854-607t26 125q0 66-24.5 125t-67 102.5q-42.5 43.5-99 69T568-160Z"
		/>
	</svg>
);
