import { ReactNode } from 'react';

export const symbol_rotate_90_degrees_cw: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M440-80q-150 0-255-105T80-440q0-151 105-257.5T441-804h31l-53-53q-9-9-9-21t9-21q9-9 21-9t21 9l104 104q5 5 7 10t2 11q0 6-2 11t-7 10L461-649q-9 9-21 9t-21-9q-9-9-9-21t9-21l53-53h-31q-126 0-213.5 89T140-440q0 125 87.5 212.5T440-140q40 0 78-11t73-31q11-6 23-2t17 15q6 11 2.5 23.5T619-127q-42 23-86.5 35T440-80Zm237-161L497-421q-5-5-7-10t-2-11q0-6 2-11t7-10l180-180q5-5 10-7t11-2q6 0 11 2t10 7l180 180q5 5 7 10t2 11q0 6-2 11t-7 10L719-241q-5 5-10 7t-11 2q-6 0-11-2t-10-7Zm21-61 136-136-136-136-136 136 136 136Zm0-136Z"
		/>
	</svg>
);
