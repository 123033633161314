/* @dependencies */
import { Component } from 'react';
import PropTypes from 'prop-types';

import { isApp } from 'pkg/platform';

const NOOP = () => {};

/**
 * @deprecated
 * Use `useKeyBindings` instead
 */
export default class KeyboardObserver extends Component {
	static propTypes = {
		target: PropTypes.any.isRequired,
		onArrowUp: PropTypes.func,
		onArrowDown: PropTypes.func,
		onArrowLeft: PropTypes.func,
		onArrowRight: PropTypes.func,
		onSpacebar: PropTypes.func,
		onBackspace: PropTypes.func,
		onDelete: PropTypes.func,
		onEnter: PropTypes.func,
		onTab: PropTypes.func,
		onEscape: PropTypes.func,
		keyBindings: PropTypes.object,
	};

	static defaultProps = {
		onArrowUp: NOOP,
		onArrowDown: NOOP,
		onArrowLeft: NOOP,
		onArrowRight: NOOP,
		onSpacebar: NOOP,
		onBackspace: NOOP,
		onDelete: NOOP,
		onEnter: NOOP,
		onTab: NOOP,
		onEscape: NOOP,
		keyBindings: {},
	};

	componentDidMount() {
		const { target } = this.props;

		if (this.isGlobal && isApp()) return;

		target.addEventListener('keyup', this.observeKeyboardInput, false);
	}

	componentWillUnmount() {
		if (!isApp()) {
			this.props.target.removeEventListener(
				'keyup',
				this.observeKeyboardInput,
				false
			);
		}
	}

	get isGlobal() {
		return this.props.target === window;
	}

	observeKeyboardInput = (event) => {
		if (event.defaultPrevented) return;
		if (this.isGlobal && event.target !== document.body) return;

		const {
			onArrowUp,
			onArrowDown,
			onArrowLeft,
			onArrowRight,
			onSpacebar,
			onBackspace,
			onDelete,
			onEnter,
			onTab,
			onEscape,
			keyBindings,
		} = this.props;

		let didEmit = false;

		const keyString = event.key.toString();
		const keyHandler = keyBindings[keyString] || undefined;

		switch (event.key) {
			case 'Up':
			case 'ArrowUp':
				onArrowUp(event);
				didEmit = true;
				break;

			case 'Down':
			case 'ArrowDown':
				onArrowDown(event);
				didEmit = true;
				break;

			case 'Left':
			case 'ArrowLeft':
				onArrowLeft(event);
				didEmit = true;
				break;

			case 'Right':
			case 'ArrowRight':
				onArrowRight(event);
				didEmit = true;
				break;

			case ' ':
			case 'Spacebar':
				onSpacebar(event);
				didEmit = true;
				break;

			case 'Backspace':
				onBackspace(event);
				didEmit = true;
				break;

			case 'Delete':
				onDelete(event);
				didEmit = true;
				break;

			case 'Enter':
				onEnter(event);
				didEmit = true;
				break;

			case 'Tab':
				onTab(event);
				didEmit = true;
				break;

			case 'Escape':
				onEscape(event);
				didEmit = true;
				break;
		}

		if (!didEmit && typeof keyHandler === 'function') {
			keyHandler.call();
		}

		event.preventDefault();
	};

	render = () => null;
}
