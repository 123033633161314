import { ReactNode } from 'react';

export const symbol_phone_locked: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M628-559q-12.75 0-21.375-8.625T598-589v-143q0-12.75 8.625-21.375T628-762h15v-39.333q0-32.45 22-55.559Q687-880 719-880t54 23.108q22 23.109 22 55.559V-762h15q12.75 0 21.375 8.625T840-732v143q0 12.75-8.625 21.375T810-559H628Zm49-203h85v-39q0-19.125-12.5-32.062Q737-846 720-846t-30 12.938Q677-820.125 677-801v39Zm118 642q-116 0-236.5-56T335-335Q232-438 176-558.5T120-795q0-19.286 12.857-32.143T165-840h140q14 0 24 10t14 25l26.929 125.641Q372-665 369.5-653.5q-2.5 11.5-10.729 19.726L259-533q26 44 55 82t64 72q37 38 78 69.5t86 55.5l95-98q10-11 23.151-15T686-369l119 26q15 4 25 16.044T840-300v135q0 19.286-12.857 32.143T795-120ZM229-588l81-82-23-110H180q2 42 13.5 88.5T229-588Zm369 363q41 19 89 31t93 14v-107l-103-21-79 83ZM229-588Zm369 363Z"
		/>
	</svg>
);
