import { ReactNode } from 'react';

export const symbol_watch_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M789-77 635-230l-32 116q-4 15-16 24.5T559-80H401q-16 0-27.5-9.5T357-114l-38-137q-52-31-85.5-91.5T200-480q0-36 9.5-75t29.5-71L77-788q-8-8-8-20.5t8-20.5q8-8 20.5-8t20.5 8l712 712q8 8 8 20t-8 20q-8 8-20.5 8T789-77ZM480-260q28 0 53-6t48-18L284-581q-12 24-18 49t-6 52q0 92 64 156t156 64Zm232-60-44-44q14-23 23-52t9-64q0-92-64-156t-156-64q-35 0-64 9t-52 23l-44-44 37-134q4-15 16-24.5t28-9.5h158q16 0 27.5 9.5T603-846l38 137q57 35 88 96t31 133q0 42-12.5 85T712-320ZM411-140h138l18-76q-14 7-37.5 11.5T480-200q-26 0-49.5-4.5T393-216l18 76Zm-18-604q14-6 37.5-11t49.5-5q25 0 49 5t38 11l-18-76H411l-18 76Zm18 604h-18 174-156Zm-18-680h174-174Z"
		/>
	</svg>
);
