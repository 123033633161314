// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.D7XHlK0ATDnlk0J8nr4h {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
	padding: var(--spacing-2);
	font-size: var(--font-size-xs);
	line-height: var(--font-line-height-xs);
	border-right: 1px solid var(--palette-gray-400);
	border-bottom: 1px solid var(--palette-gray-400);
	min-height: 40px;
}

.D7XHlK0ATDnlk0J8nr4h:last-of-type {
		border-right: none;
	}

@media print {

.D7XHlK0ATDnlk0J8nr4h {
		padding: 0;
		border-right: 1px solid var(--palette-black);
		border-top: 1px solid var(--palette-black);
		border-bottom: 1px solid var(--palette-black);
}

		.D7XHlK0ATDnlk0J8nr4h:last-of-type {
			border-right: 1px solid var(--palette-black);
		}
	}

.n5EzZcJRABoPU_yORwNX {
	display: grid;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-flow: column;
	        flex-flow: column;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
	-webkit-box-align: stretch;
	    -ms-flex-align: stretch;
	        align-items: stretch;
	grid-template-rows: 1fr auto;
	grid-template-columns: 1fr;
	height: 100%;
	width: 100%;
	grid-gap: var(--spacing-2);
	gap: var(--spacing-2);
}
`, "",{"version":3,"sources":["webpack://./components/scheduling/grid/day-item/styles.css"],"names":[],"mappings":"AAAA;CACC,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,4BAAsB;CAAtB,6BAAsB;KAAtB,0BAAsB;SAAtB,sBAAsB;CACtB,yBAA8B;KAA9B,sBAA8B;SAA9B,8BAA8B;CAC9B,yBAAyB;CACzB,8BAA8B;CAC9B,uCAAuC;CACvC,+CAA+C;CAC/C,gDAAgD;CAChD,gBAAgB;AAgBjB;;AAdC;EACC,kBAAkB;CACnB;;AAEA;;AAfD;EAgBE,UAAU;EACV,4CAA4C;EAC5C,0CAA0C;EAC1C,6CAA6C;AAM/C;;EAJE;GACC,4CAA4C;EAC7C;CACD;;AAGD;CACC,aAAa;CACb,4BAAiB;CAAjB,6BAAiB;KAAjB,qBAAiB;SAAjB,iBAAiB;CACjB,yBAA8B;KAA9B,sBAA8B;SAA9B,8BAA8B;CAC9B,0BAAoB;KAApB,uBAAoB;SAApB,oBAAoB;CACpB,4BAA4B;CAC5B,0BAA0B;CAC1B,YAAY;CACZ,WAAW;CACX,0BAAqB;CAArB,qBAAqB;AACtB","sourcesContent":[".gridItem {\n\tdisplay: flex;\n\tflex-direction: column;\n\tjustify-content: space-between;\n\tpadding: var(--spacing-2);\n\tfont-size: var(--font-size-xs);\n\tline-height: var(--font-line-height-xs);\n\tborder-right: 1px solid var(--palette-gray-400);\n\tborder-bottom: 1px solid var(--palette-gray-400);\n\tmin-height: 40px;\n\n\t&:last-of-type {\n\t\tborder-right: none;\n\t}\n\n\t@media print {\n\t\tpadding: 0;\n\t\tborder-right: 1px solid var(--palette-black);\n\t\tborder-top: 1px solid var(--palette-black);\n\t\tborder-bottom: 1px solid var(--palette-black);\n\n\t\t&:last-of-type {\n\t\t\tborder-right: 1px solid var(--palette-black);\n\t\t}\n\t}\n}\n\n.itemWrapper {\n\tdisplay: grid;\n\tflex-flow: column;\n\tjustify-content: space-between;\n\talign-items: stretch;\n\tgrid-template-rows: 1fr auto;\n\tgrid-template-columns: 1fr;\n\theight: 100%;\n\twidth: 100%;\n\tgap: var(--spacing-2);\n}\n"],"sourceRoot":""}]);
// Exports
export var gridItem = `D7XHlK0ATDnlk0J8nr4h`;
export var itemWrapper = `n5EzZcJRABoPU_yORwNX`;
export default ___CSS_LOADER_EXPORT___;
