import { Fragment } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { small } from 'pkg/config/breakpoints';
import * as palette from 'pkg/config/palette';

import { updateMatchEventPosition } from 'pkg/actions/match_events_deprecated';

import * as selectors from 'pkg/selectors';
import { getEventTypeTranslation } from 'pkg/api/models/match_event';

import { LineupContainer as LC, FieldBody } from 'components/football-field';

import PositionSection from 'components/match/substitutions/PositionSection';

const Label = styled.label`
	padding: 6px 12px;
	font-size: 12px;
	border-radius: 55px;
`;

const Type = styled(Label)`
	background-color: ${palette.green[500]};
	color: ${palette.white};
`;

const Player = styled.span`
	font-weight: var(--font-weight-semibold);
`;

const Position = styled(Label)`
	background-color: ${palette.white};
	color: ${palette.gray[500]};
`;

const LineupBody = styled(FieldBody)`
	padding: 80px 50px;
	@media ${small} {
		display: flex;
		align-items: center;
		height: 100%;
		padding: 0px;
	}
`;

const LineupContainer = styled(LC)`
	background: transparent;
`;

const Header = styled.div`
	display: grid;
	grid-template-columns: auto 1fr auto;
	grid-column-gap: 1rem;
	padding: 1rem;
	align-items: center;
	background-color: ${palette.green[200]};
`;

const PosSelection = ({ event, changes, eventUser }) => {
	const dispatch = useDispatch();

	const positions = useSelector((state) =>
		selectors.positions.getPositions(state)
	);

	const handlePosition = (position) =>
		dispatch(updateMatchEventPosition(event.id, position.id));

	const getPositionId = () => changes.get('positionId') || event.positionId;

	return (
		<Fragment>
			<Header>
				<Type>{getEventTypeTranslation(event.type)}</Type>
				<Player>{`${eventUser.firstName} ${eventUser.lastName}`}</Player>
				{getPositionId() && (
					<Position>
						{positions.find((pos) => pos.id === getPositionId())?.slug}
					</Position>
				)}
			</Header>
			<LineupBody>
				<LineupContainer key="lineupField">
					{positions.map((position) => (
						<PositionSection
							key={position.id}
							position={position}
							onClick={handlePosition}
							selected={position.id === getPositionId()}
						/>
					))}
				</LineupContainer>
			</LineupBody>
		</Fragment>
	);
};

export default PosSelection;
