import { ReactNode } from 'react';

export const symbol_cycle: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-480q0 105 57 190t153 125q12 5 17.5 16.5T367-126q-8 12-21 15.5t-27-2.5q-110-48-174.5-147.5T80-480q0-32 5-64.5t16-63.5l-40 23q-11 6-22.5 3.5T21-595q-6-11-3-23t14-18l121-70q11-6 23-3t18 14l70 120q6 11 3 23t-14 18q-11 6-23 3t-18-14l-43-73q-14 33-21.5 67.5T140-480Zm340-340q-57 0-109.5 18T273-749q-11 8-23.5 7.5T231-753q-7-12-4-26t14-22q52-38 113-58.5T480-880q87 0 165 35.5T780-744v-46q0-13 8.5-21.5T810-820q13 0 21.5 8.5T840-790v140q0 13-8.5 21.5T810-620H670q-13 0-21.5-8.5T640-650q0-13 8.5-21.5T670-680h85q-48-66-120.5-103T480-820Zm252 569q51-57 73-129t11-147q-2-13 5.5-23t19.5-10q14 0 24 10.5t12 25.5q8 81-14 157.5T790-227q-44 54-102 89.5T562-88l38 22q11 6 13.5 18T610-25q-6 11-17.5 13.5T570-15L448-85q-11-6-14-18t3-23l70-120q6-11 18-14t23 3q11 6 13.5 17.5T558-217l-43 76q63-6 119-34.5t98-75.5Z"
		/>
	</svg>
);
