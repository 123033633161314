import { ReactNode } from 'react';

export const symbol_circle_notifications: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480.235-216q23.236 0 39.5-17Q536-250 536-273H423q0 23 17 40t40.235 17ZM386-393v-127q0-40.425 27-69.213Q440-618 479.5-618t66.5 28.787q27 28.788 27 69.213v127H386Zm94.266 313q-82.734 0-155.5-31.5t-127.266-86q-54.5-54.5-86-127.341Q80-397.681 80-480.5q0-82.819 31.5-155.659Q143-709 197.5-763t127.341-85.5Q397.681-880 480.5-880q82.819 0 155.659 31.5Q709-817 763-763t85.5 127Q880-563 880-480.266q0 82.734-31.5 155.5T763-197.684q-54 54.316-127 86Q563-80 480.266-80Zm.234-60Q622-140 721-239.5t99-241Q820-622 721.188-721 622.375-820 480-820q-141 0-240.5 98.812Q140-622.375 140-480q0 141 99.5 240.5t241 99.5Zm-.5-340ZM316-333h327q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T643-393h-10v-115q0-60-31.5-110.5T513-680v-30q0-14.025-9.482-23.513-9.483-9.487-23.5-9.487Q466-743 456-733.513q-10 9.488-10 23.513v30q-57 11-88.5 59T326-514v121h-10q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T316-333Z"
		/>
	</svg>
);
