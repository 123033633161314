// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dK2XoocQqib5cz28ZXbN {
	display: grid;
	grid-template-columns: auto 1fr;
	padding-bottom: var(--spacing-4);
	font-size: var(--font-size-xl);
	line-height: var(--font-line-height-xl);
	font-weight: var(--font-weight-semibold);
	color: var(--palette-gray-800);
	letter-spacing: -0.3px;
	margin: 0 var(--spacing-3) var(--spacing-5) 0;
}

.MUYOBRUHOLxNrksF8ICK {
	color: var(--palette-main-actionable-link-color);
	margin-left: 5px;
}

@media (hover: hover) {
		.MUYOBRUHOLxNrksF8ICK:hover {
			cursor: pointer;
		}
	}
`, "",{"version":3,"sources":["webpack://./routes/event/create/styles.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,+BAA+B;CAC/B,gCAAgC;CAChC,8BAA8B;CAC9B,uCAAuC;CACvC,wCAAwC;CACxC,8BAA8B;CAC9B,sBAAsB;CACtB,6CAA6C;AAC9C;;AAEA;CACC,gDAAgD;CAChD,gBAAgB;AAOjB;;AALC;EACC;GACC,eAAe;EAChB;CACD","sourcesContent":[".title {\n\tdisplay: grid;\n\tgrid-template-columns: auto 1fr;\n\tpadding-bottom: var(--spacing-4);\n\tfont-size: var(--font-size-xl);\n\tline-height: var(--font-line-height-xl);\n\tfont-weight: var(--font-weight-semibold);\n\tcolor: var(--palette-gray-800);\n\tletter-spacing: -0.3px;\n\tmargin: 0 var(--spacing-3) var(--spacing-5) 0;\n}\n\n.displayList {\n\tcolor: var(--palette-main-actionable-link-color);\n\tmargin-left: 5px;\n\n\t@media (hover: hover) {\n\t\t&:hover {\n\t\t\tcursor: pointer;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var title = `dK2XoocQqib5cz28ZXbN`;
export var displayList = `MUYOBRUHOLxNrksF8ICK`;
export default ___CSS_LOADER_EXPORT___;
