import { ReactNode } from 'react';

export const symbol_crop_9_16: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M335-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h290q24 0 42 18t18 42v600q0 24-18 42t-42 18H335Zm0-660v600h290v-600H335Zm0 0v600-600Z"
		/>
	</svg>
);
