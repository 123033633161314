import { ReactNode } from 'react';

export const symbol_energy_savings_leaf: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-180q125 0 212.5-87.5T780-480v-300H480q-125 0-212.5 87.5T180-480q0 125 87.5 212.5T480-180Zm-53-105 207-185q10-9 6-21.5T622-506l-162-16 97-133q4-5 3.5-10.5T556-676q-5-5-11.5-5t-11.5 5L327-491q-10 9-6 21.5t18 14.5l162 16-98 133q-4 5-3.5 10.5T405-285q5 5 11 5t11-5Zm53 165q-67 0-126-22.5T247-205l-76 76q-5 5-10 7t-11 2q-12 0-21-9t-9-21q0-6 2-11t7-10l76-76q-40-48-62.5-107T120-480q0-150 105-255t255-105h300q25 0 42.5 17.5T840-780v300q0 150-105 255T480-120Zm0-360Z"
		/>
	</svg>
);
