import { ReactNode } from 'react';

import spacing from 'pkg/config/spacing';

import { LargeScreen } from 'components/MediaQuery';

import Column from 'components/layout/column';

import * as css from './styles.css';

interface ContentHeadingProps {
	title: string;
	// Set as any because I want to be able to pass a t string and also the <T /> component
	description?: ReactNode;
}

export default function ContentHeading({
	title,
	description,
}: ContentHeadingProps) {
	return (
		<Column className={css.textWrapper} spacing={spacing._3}>
			<LargeScreen>
				<div className={css.pageHeading}>{title}</div>
			</LargeScreen>
			{description && <div className={css.description}>{description}</div>}
		</Column>
	);
}
