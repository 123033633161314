import { ReactNode } from 'react';

export const symbol_blanket: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M150-120q-45.833 0-77.917-32.083Q40-184.167 40-230v-500q0-45.833 32.083-77.917Q104.167-840 150-840h330q45.833 0 77.917 32.083Q590-775.833 590-730v282h60q28.875 0 49.438 20.562Q720-406.875 720-378v128q0 13 8.5 21.5T750-220q13 0 21.5-8.5T780-250v-290q-32 0-56-19.5T700-610v-80q0-12.75 8.625-21.375T730-720h10v-60q0-8 6-14t14-6q8 0 14 6t6 14v60h60v-60q0-8 6-14t14-6q8 0 14 6t6 14v60h10q12.75 0 21.375 8.625T920-690v80q0 31-24 50.5T840-540v286q0 37.8-26.141 63.9t-64 26.1Q712-164 686-190.1T660-254v-138h-70v122q0 63-43.5 106.5T440-120H150Zm0-60h290q38 0 64-26t26-64q0-38-26-64t-64-26H250q-13 0-21.5 8.5T220-330q0 13 8.5 21.5T250-300h190q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T440-240H250q-37.8 0-63.9-26.141t-26.1-64Q160-368 186.1-394t63.9-26h190q23.71 0 46.855 8Q510-404 530-390v-340q0-21.25-14.375-35.625T480-780H150q-21.25 0-35.625 14.375T100-730v500q0 21.25 14.375 35.625T150-180Zm0 0h290-340 50Z"
		/>
	</svg>
);
