import { cssClasses } from 'pkg/css/utils';

import * as css from './styles.css';

interface NotificationBadgeProps {
	amount?: number;
}

export default function NotificationBadge({
	amount = null,
}: NotificationBadgeProps) {
	if (!amount) {
		return <div className={cssClasses(css.notifications, css.pill)} />;
	}

	return (
		<div className={cssClasses(css.notifications, css.amount)}>{amount}</div>
	);
}
