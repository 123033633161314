import { ReactNode } from 'react';

export const symbol_aq_indoor: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M550.026-275Q569-275 587-282.5q18-7.5 32-21.062L637-321q8-8 7.5-17.5T636-356q-8-8-17.5-8t-17.833 8.333L584-339q-6.789 7.091-15.842 10.045Q559.105-326 550.053-326q-9.053 0-18.144-2.955Q522.818-331.909 516-339l-37.269-37.269Q465-390 446.974-397q-18.025-7-37-7Q391-404 373-397q-18 7-32 20.562L323-359q-8 8-7.5 17.5T324-324q8 8 17.5 8t17.833-8.333L376-341q6.789-7.091 15.842-10.045Q400.895-354 409.947-354q9.053 0 18.144 2.955Q437.182-348.091 444-341l37.269 37.269Q495-290 513.026-282.5q18.025 7.5 37 7.5Zm0-120Q569-395 587-402.5q18-7.5 32-21.062L637-441q8-8 7.5-17.5T636-476q-8-8-17.5-8t-17.833 8.333L584-459q-6.789 7.091-15.842 10.045Q559.105-446 550.053-446q-9.053 0-18.144-2.955Q522.818-451.909 516-459l-37.269-37.269Q465-510 446.974-517q-18.025-7-37-7Q391-524 373-517q-18 7-32 20.562L323-479q-8 8-7.5 17.5T324-444q8 8 17.5 8t17.833-8.333L376-461q6.789-7.091 15.842-10.045Q400.895-474 409.947-474q9.053 0 18.144 2.955Q437.182-468.091 444-461l37.269 37.269Q495-410 513.026-402.5q18.025 7.5 37 7.5Zm0-120Q569-515 587-522.5q18-7.5 32-21.062L637-561q8-8 7.5-17.5T636-596q-8-8-17.5-8t-17.833 8.333L584-579q-6.789 7.091-15.842 10.045Q559.105-566 550.053-566q-9.053 0-18.144-2.955Q522.818-571.909 516-579l-37.269-37.269Q465-630 446.974-636.5q-18.025-6.5-37-6.5Q391-643 373-636.5q-18 6.5-32 20.062L323-599q-8 8-7.5 17.5T324-564q8 8 17.5 8t17.833-8.333L376-581q7-6 16-9.5t18-3.5q9 0 18.091 2.955Q437.182-588.091 444-581l37.269 37.269Q495-530 513.026-522.5q18.025 7.5 37 7.5ZM220-160q-24.75 0-42.375-17.625T160-220v-390q0-14.25 6.375-27T184-658l260-195q15.68-12 35.84-12Q500-865 516-853l260 195q11.25 8.25 17.625 21T800-610v390q0 24.75-17.625 42.375T740-160H220Zm0-60h520v-394L480-803 220-614v394Zm260-292Z"
		/>
	</svg>
);
