export default {
	name: 'notification-outline',
	objects: [
		{
			type: 'path',
			attributes: {
				d: 'M8.57143 17.6428C8.57143 19.5364 10.1065 21.0714 12 21.0714C13.8936 21.0714 15.4286 19.5364 15.4286 17.6428H14.1286C14.1286 18.8184 13.1756 19.7714 12 19.7714C10.8244 19.7714 9.87143 18.8184 9.87143 17.6428H8.57143Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M15.2104 5.4747C13.4858 3.58836 10.5143 3.58836 8.7896 5.47469L8.64674 5.63095C7.91368 6.43273 7.50717 7.4798 7.50717 8.56619V12.4539C7.50717 13.5922 6.97642 14.6615 6.07866 15.35H17.9214C17.0236 14.6615 16.4929 13.5922 16.4929 12.4539V8.56619C16.4929 7.4798 16.0864 6.43273 15.3533 5.63095L15.2104 5.4747ZM7.83016 4.59749C10.0702 2.14742 13.9298 2.14742 16.1699 4.5975L16.3127 4.75375C17.2649 5.79515 17.7929 7.15513 17.7929 8.56619V12.4539C17.7929 13.2264 18.1725 13.9495 18.8084 14.3882L20.3691 15.465C20.6033 15.6265 20.7052 15.9217 20.6206 16.1933C20.536 16.465 20.2845 16.65 20 16.65H4.00002C3.7155 16.65 3.46405 16.465 3.37943 16.1933C3.29482 15.9217 3.39671 15.6265 3.63091 15.465L5.19166 14.3882C5.82752 13.9495 6.20717 13.2264 6.20717 12.4539V8.56619C6.20717 7.15514 6.73517 5.79515 7.6873 4.75375L7.83016 4.59749Z',
				fillRule: 'evenodd',
				clipRule: 'evenodd',
			},
		},
	],
};
