import { t } from '@transifex/native';
import { SyntheticEvent } from 'react';

import * as models from 'pkg/api/models';

import * as Input from 'components/form/inputs';

import * as Table from 'design/table';

interface ProductsTableProps {
	products: models.product.Product[];
	isAllSelected: boolean;
	selected?: number[];
	select: (id: number) => void;
	selectAll: () => void;
}

interface ProductRowProps {
	product: models.product.Product;
	selectedProducts: number[];

	select?: (id: number) => void;
}

function ProductRow({ product, select, selectedProducts }: ProductRowProps) {
	const handleClick = (e: SyntheticEvent<HTMLDivElement>) => {
		e.stopPropagation();

		select(product.id);
	};

	return (
		<Table.Row>
			<Table.Cell onClick={handleClick}>
				<Input.Control
					type="checkbox"
					checked={selectedProducts.includes(product.id)}
				/>
			</Table.Cell>
			<Table.Cell>
				<span>{product.name}</span>
			</Table.Cell>
		</Table.Row>
	);
}

export default function ProductsTable({
	products,
	isAllSelected,
	select,
	selected,
	selectAll,
}: ProductsTableProps) {
	const columns = [
		{
			content: (
				<Input.Control
					type="checkbox"
					standalone
					checked={isAllSelected}
					onChange={selectAll}
				/>
			),
			width: '50px',
		},
		{
			content: t('Name'),
		},
	];

	return (
		<Table.Table columns={columns}>
			{products.map((product) => (
				<ProductRow
					key={product.id}
					product={product}
					select={select}
					selectedProducts={selected}
				/>
			))}
		</Table.Table>
	);
}
