import { ReactNode } from 'react';

export const symbol_contact_emergency: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M60-120q-24 0-42-18T0-180v-600q0-24 18-42t42-18h840q24 0 42 18t18 42v600q0 24-18 42t-42 18H60Zm531-60h309v-600H60v600h7q44-69 112.5-109T329-329q81 0 149.5 40T591-180ZM329-400q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35ZM143-180h372q-35.606-42.275-84.303-65.637Q382-269 329-269t-101.5 23.5Q179-222 143-180Zm186-280q-25.5 0-42.75-17.25T269-520q0-25.5 17.25-42.75T329-580q25.5 0 42.75 17.25T389-520q0 25.5-17.25 42.75T329-460Zm151-20Zm220-85v55q0 8 6 14t14 6q8 0 14-6t6-14v-55l48 27q7 4 14.5 2.5t12.5-9q5-7.5 2.5-15T808-572l-48-28 48-28q7-4 9.5-11.5T815-655q-4-7.118-12-9.059Q795-666 788-662l-48 27v-55q0-8-6-14t-14-6q-8 0-14 6t-6 14v55l-48-27q-7-4-14.5-2.5t-12.5 9q-5 7.5-2.5 15T632-628l48 28-48 28q-7 4-9.5 11.5T625-545q4 7.118 12 9.059Q645-534 652-538l48-27Z"
		/>
	</svg>
);
