import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import { PageWidths } from 'pkg/config/sizes';

import { useCurrentGroup } from 'pkg/identity';
import * as models from 'pkg/api/models';

import { ProductItem } from 'routes/organization/contacts/single/list/AssignedProducts';

import CardAnatomy, * as Card from 'components/Card';
import MissingEntities from 'components/missing-entities';
import SectionTitle from 'components/SectionTitle';
import Icon from 'components/icon';

import Column from 'components/layout/column';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';

import * as Table from 'design/table';
import Button from 'design/button';

const ButtonWrapper = styled.div`
	text-align: center;
	margin-top: var(--spacing-4);
`;

interface ProductListProps {
	contact: models.user.User;
	handleOpenModal: (name: string) => void;
}

const ProductList: React.FC<React.PropsWithChildren<ProductListProps>> = ({
	contact,
	handleOpenModal,
}) => {
	const [showExpired, setShowExpired] = useState(false);

	const userProducts =
		contact?.products?.sort((a, b) => b.createdAt - a.createdAt) || [];
	const excludeExpiredProducts = userProducts.filter(
		(userProduct) => userProduct.status !== 'expired'
	);

	const showingProducts = showExpired ? userProducts : excludeExpiredProducts;

	const group = useCurrentGroup();

	useEffect(() => {
		if (excludeExpiredProducts.length === 0 && userProducts.length > 0) {
			setShowExpired(true);
		}
	}, [excludeExpiredProducts.length, userProducts.length]);

	const handleAssignProduct = () => handleOpenModal('product');
	const handleShowExpired = () => setShowExpired(true);

	const tableColumns: Table.HeaderColumn[] = [
		{ content: t('Product'), width: '180px', align: 'left' },
		{ content: t('Price'), align: 'left' },
		{ content: t('Status'), width: 'max-content', align: 'left' },
		{ content: t('Valid until'), align: 'left' },
		{ content: t('Created'), align: 'left' },
		{ content: '', width: 'max-content' },
	];

	const content =
		userProducts.length === 0 ? (
			<CardAnatomy $noBorder>
				<Card.Body>
					<MissingEntities centered>
						<Icon name="tag" />
						<p>{t('No assigned products')}</p>
					</MissingEntities>
				</Card.Body>
			</CardAnatomy>
		) : (
			<Fragment>
				<Card.Base>
					<Table.Table columns={tableColumns}>
						{showingProducts.map((userProduct) => (
							<ProductItem
								key={userProduct.id}
								userProduct={userProduct}
								currency={group.currency}
							/>
						))}
					</Table.Table>
				</Card.Base>
			</Fragment>
		);

	return (
		<LargeScreenContent.Inner maxWidth={PageWidths.STANDARD} spacious>
			<Column>
				<SectionTitle>
					{t('Assigned products')}
					<Button
						disabled={!!contact.deletedAt}
						inline
						noPadding
						icon="add"
						onClick={handleAssignProduct}>
						{t('Assign products')}
					</Button>
				</SectionTitle>
				{content}
			</Column>
			{!showExpired &&
				userProducts.length !== excludeExpiredProducts.length && (
					<ButtonWrapper>
						<Button onClick={handleShowExpired}>
							{t('View expired', {
								amount: userProducts.length - excludeExpiredProducts.length,
							})}
						</Button>
					</ButtonWrapper>
				)}
		</LargeScreenContent.Inner>
	);
};

export default ProductList;
