import { ReactNode } from 'react';

export const symbol_dashboard: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M510-600v-210q0-12.75 8.625-21.375T540-840h270q12.75 0 21.375 8.625T840-810v210q0 12.75-8.625 21.375T810-570H540q-12.75 0-21.375-8.625T510-600ZM120-480v-330q0-12.75 8.625-21.375T150-840h270q12.75 0 21.375 8.625T450-810v330q0 12.75-8.625 21.375T420-450H150q-12.75 0-21.375-8.625T120-480Zm390 330v-330q0-12.75 8.625-21.375T540-510h270q12.75 0 21.375 8.625T840-480v330q0 12.75-8.625 21.375T810-120H540q-12.75 0-21.375-8.625T510-150Zm-390 0v-210q0-12.75 8.625-21.375T150-390h270q12.75 0 21.375 8.625T450-360v210q0 12.75-8.625 21.375T420-120H150q-12.75 0-21.375-8.625T120-150Zm60-360h210v-270H180v270Zm390 330h210v-270H570v270Zm0-450h210v-150H570v150ZM180-180h210v-150H180v150Zm210-330Zm180-120Zm0 180ZM390-330Z"
		/>
	</svg>
);
