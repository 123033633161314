import { t } from '@transifex/native';

import { formatPriceIntervalToText } from 'pkg/utils';
import { formatToCurrency } from 'pkg/i18n/formatters';
import * as models from 'pkg/api/models';
import { getSubscriptionRowsTotalCost } from 'pkg/api/models/subscription';

import Icon from 'components/icon';
import * as CardList from 'components/card-list';

interface ProductItemProps {
	row: models.subscription.SubscriptionRow;
	currency: string;
}

const ProductRow: React.FC<React.PropsWithChildren<ProductItemProps>> = ({
	row,
	currency,
}) => {
	const productPrice = row.productPrice;
	const product = productPrice?.product;
	const taxRate = productPrice?.taxRate;
	const total = getSubscriptionRowsTotalCost([row]);

	let title = product?.name;

	if (row.type === 'fee') {
		title = t('Service fee');
	} else if (row.type === 'discount') {
		title = t('Discount ({title})', {
			title: row.discount?.title,
		});
	}

	return (
		<CardList.Base noBorder>
			<CardList.IconWrapper>
				<Icon name="nav-badge" />
			</CardList.IconWrapper>
			<CardList.Title>
				{title}
				<CardList.SubTitle>x {row.quantity}</CardList.SubTitle>
			</CardList.Title>
			{!!taxRate && (
				<CardList.Col>
					<CardList.TextContent>
						{t('TAX')}
						<CardList.SmallText>
							{taxRate.display_name} {taxRate.percentage}%{' '}
							{taxRate.inclusive && t('incl.')}
						</CardList.SmallText>
					</CardList.TextContent>
				</CardList.Col>
			)}
			<CardList.Col>
				<CardList.TextContent>
					{formatToCurrency(total, currency)}
					{models.subscription.isProductRow(row) && (
						<CardList.SmallText>
							{formatPriceIntervalToText(
								productPrice.recurringInterval,
								productPrice.recurringIntervalCount
							)}
						</CardList.SmallText>
					)}
				</CardList.TextContent>
			</CardList.Col>
		</CardList.Base>
	);
};

export default ProductRow;
