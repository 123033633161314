import styled from 'styled-components';

const ColoredDot = styled.div<{ color: string }>`
	&:before {
		content: '';
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background: ${(props) => props.color};
		display: inline-block;
		margin-right: var(--spacing-2);
		align-items: center;
	}
`;

export default ColoredDot;
