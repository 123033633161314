import { Map, List, Record } from 'immutable';

import Group from 'pkg/models/group';
import Membership from 'pkg/models/membership';

import {
	AUTH_LOGOUT_SUCCESS,
	Groups as groupTypes,
} from 'pkg/actions/action-types';

const initialState = new Record(
	{
		parentGroup: {},
		entities: new Map({}), // this is the groups
		bannedMembers: new List([]),
		pendingMembers: new List([]),
		invites: new List([]),
	},
	'groups'
);

const groups = (state = initialState(), action) => {
	switch (action.type) {
		case groupTypes.SET_INVITES: {
			return state.set('invites', new List(action.invites));
		}

		case groupTypes.UPDATE_GROUP_KEY_VALUE: {
			return state.setIn(
				['entities', action.groupId, action.key],
				action.value
			);
		}

		case groupTypes.SET_BANNED_MEMBERS: {
			return state.set(
				'bannedMembers',
				new List(action.records.map((r) => new Membership(r)))
			);
		}

		case groupTypes.SET_PENDING_MEMBERS: {
			return state.set(
				'pendingMembers',
				new List(action.records.map((r) => new Membership(r)))
			);
		}

		case 'ADD_ACTIVITIES_SUMMATION': {
			if (state.hasIn(['entities', action.groupId])) {
				return state.updateIn(['entities', action.groupId], (entity) =>
					entity.mergeDeep({ activitySummary: action.summation.group })
				);
			}
			return state;
		}

		case groupTypes.ADD_ITEMS: {
			const { groups } = action.payload.entities;

			if (!groups) return state;

			let entities = state.entities;

			for (let id in groups) {
				id = Number.parseInt(id, 10);

				if (entities.has(id)) {
					const existingLinks = entities.getIn([id, 'links'], new Map({}));
					const existingUserFields = entities.getIn([id, 'userFields'], []);

					entities = entities.update(id, (group) =>
						group
							.mergeDeep(new Group(groups[id]))
							.set('links', existingLinks)
							.set('userFields', existingUserFields)
					);
				} else {
					entities = entities.set(id, new Group(groups[id]));
				}
			}

			return state.set('entities', entities);
		}

		case groupTypes.ADD_ITEM: {
			if (state.hasIn(['entities', action.group.id])) {
				return state.updateIn(['entities', action.group.id], (group) => {
					let mergedGroup = group.mergeDeep(new Group(action.group));

					// make canShowChildren false if all childs have been deleted
					if (group.hasIn(['links', 'show:children']) && !action.group.links) {
						mergedGroup = mergedGroup.set('links', new Map({}));
					}
					mergedGroup = mergedGroup.set('meta', action.group.meta);

					return mergedGroup;
				});
			}

			return state.setIn(
				['entities', action.group.id],
				new Group(action.group)
			);
		}

		case groupTypes.DELETE_ITEM: {
			if (state.hasIn(['entities', action.groupId])) {
				return state.deleteIn(['entities', action.groupId]);
			}

			return state;
		}

		case AUTH_LOGOUT_SUCCESS:
			return initialState();

		case groupTypes.CREATE_IMPORT_CONNECTION: {
			return state.updateIn(['entities', action.groupId], (entity) =>
				entity.set('externalId', action.externalId)
			);
		}

		case groupTypes.DELETE_IMPORT_CONNECTION: {
			return state.updateIn(['entities', action.groupId], (entity) =>
				entity.set('externalId', '')
			);
		}

		default:
			return state;
	}
};

export default groups;
