import { ReactNode } from 'react';

export const symbol_router: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-120q-24 0-42-18t-18-42v-204q0-24 18-42t42-18h436v-146q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T676-590v146h104q24 0 42 18t18 42v204q0 24-18 42t-42 18H180Zm0-60h600v-204H180v204Zm102.105-60Q300-240 312-252.105q12-12.106 12-30Q324-300 311.895-312q-12.106-12-30-12Q264-324 252-311.895q-12 12.106-12 30Q240-264 252.105-252q12.106 12 30 12Zm148 0Q448-240 460-252.105q12-12.106 12-30Q472-300 459.895-312q-12.106-12-30-12Q412-324 400-311.895q-12 12.106-12 30Q388-264 400.105-252q12.106 12 30 12Zm148 0Q596-240 608-252.105q12-12.106 12-30Q620-300 607.895-312q-12.106-12-30-12Q560-324 548-311.895q-12 12.106-12 30Q536-264 548.105-252q12.106 12 30 12ZM646-700q-15 0-29.5 4T589-684q-11.429 6-23.214 5Q554-680 546-688q-9-9-9-20.5t10-18.5q21.3-15.889 46.15-24.444Q618-760 645.962-760 675-760 700-751q25 9 46 25 9 7 9 18.5t-8 19.5q-8 8-20.5 9t-23.5-5q-12.148-8-26.574-12Q662-700 646-700Zm0-120q-44 0-80.5 13T503-773q-10 8-22.5 8t-21.5-9q-9-9-8.5-22t10.5-21q34-26 81.5-44.5T646-880q56 0 103.5 18.5T831-817q10 8 10.5 21t-8.5 22q-9 9-21.5 8.5T788-774q-26-20-62-33t-80-13ZM180-180v-204 204Z"
		/>
	</svg>
);
