import { ReactNode } from 'react';

export const symbol_5k_plus: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M676-463v42.5q0 8.5 6 14.5t14 6q8 0 14-6t6-14.5V-463h48q7 0 11.5-5.143t4.5-12q0-6.857-4.5-11.357Q771-496 764-496h-48v-44q0-8-6-14t-14-6q-8 0-14 6t-6 14v44h-47q-7 0-11.5 4.5t-4.5 11.357q0 6.857 5.1 12T630-463h46Zm-160 13 77 82q2 2 17 8 16 0 22.5-15t-4.5-26l-74-79 74-80q11-11 4.5-25.5T610-600q-4.789 0-8.895 2-4.105 2-8.105 6l-77 82v-65q0-10.833-7.116-17.917-7.117-7.083-18-7.083Q480-600 473-592.917q-7 7.084-7 17.917v190q0 10.833 7.116 17.917 7.117 7.083 18 7.083Q502-360 509-367.083q7-7.084 7-17.917v-65Zm-166 40h-92q-10.833 0-17.917 7.116-7.083 7.117-7.083 18Q233-374 240.083-367q7.084 7 17.917 7h108q14.45 0 24.225-9.487Q400-378.975 400-393v-70q0-14.025-9.775-23.513Q380.45-496 366-496h-83v-54h92q10.833 0 17.917-7.116 7.083-7.117 7.083-18Q400-586 392.917-593q-7.084-7-17.917-7H258q-10.833 0-17.917 7.083Q233-585.833 233-575v87q0 10.833 7.083 17.917Q247.167-463 258-463h92v53ZM180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-60h600v-600H180v600Zm0-600v600-600Z"
		/>
	</svg>
);
