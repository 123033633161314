import { ReactNode } from 'react';

export const symbol_cognition: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M490.74-349Q557-349 603-391.078T649-493q0-52-33.58-89T534-619q-42 0-72 27.5t-30 66.611Q432-508 438-491q6 17 20 29 11 11 23.509 9.147 12.509-1.854 20.327-10.009 7.819-8.155 8.991-19.647Q512-494 502-504q-5-5-7.5-9.5T492-524q0-14 11-24t31-10q23 0 39.5 18.5T590-492q0 35.189-28 59.595Q534-408 492-408q-51.196 0-86.598-40.5T370-549q0-23.014 6.5-44.817T396-635q7-11 6.5-23t-9.5-21q-9-9-21-9t-20 10q-21 27-32 60t-11 68q0 83.829 53.008 142.415Q415.015-349 490.74-349ZM240-252q-57-52-88.5-121.5T120-520q0-150 105-255t255-105q125 0 221.5 73.5T827-615l55 218q4 14-5 25.5T853-360h-93v140q0 24.75-17.625 42.375T700-160H600v50q0 12.75-8.675 21.375Q582.649-80 569.825-80 557-80 548.5-88.625T540-110v-80q0-12.75 8.625-21.375T570-220h130v-170q0-12.75 8.625-21.375T730-420h84l-45-180q-24-97-105-158.5T480-820q-125 0-212.5 86.5T180-522.46q0 64.417 26.324 122.392Q232.649-342.092 281-297l19 18v169q0 12.75-8.675 21.375Q282.649-80 269.825-80 257-80 248.5-88.625T240-110v-142Zm257-198Z"
		/>
	</svg>
);
