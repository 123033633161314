import * as React from 'react';
import { t } from '@transifex/native';

import User from 'pkg/models/user';
import Group, { Features } from 'pkg/models/group';

import * as routes from 'pkg/router/routes';
import { useCheckRelationToUser } from 'pkg/hooks/selectors';
import { useCurrentMembership, useCurrentOrganization } from 'pkg/identity';
import * as models from 'pkg/api/models';
import { newChatParams } from 'pkg/chat';
import { link } from 'pkg/router/utils';

import { SmallScreen } from 'components/MediaQuery';

import * as ContextMenu from 'design/context_menu';

type ActionsProps = {
	toggle: React.ReactNode;
	group: Group;
	user: User;
};
const OverviewActions: React.FC<React.PropsWithChildren<ActionsProps>> = ({
	toggle,
	group,
	user,
}) => {
	const org = useCurrentOrganization();
	const activeMembership = useCurrentMembership();

	const { isSelfOrParentToUser, isParentToUser } = useCheckRelationToUser(
		user.id
	);

	const showChatButton =
		activeMembership.userId !== user.id && // Can not chat with yourself
		user.accountId && // User is connected to an existing account
		group.hasFeature(Features.Chat); // Chat enabled in group

	return (
		<ContextMenu.Menu toggleWith={toggle}>
			<SmallScreen>
				{showChatButton && (
					<ContextMenu.LinkItem
						icon="add_comment"
						href={link(
							routes.Chat.New(org.id),
							newChatParams([user.toJS() as models.user.User])
						)}>
						{t('Send message')}
					</ContextMenu.LinkItem>
				)}
			</SmallScreen>
			{isSelfOrParentToUser && (
				<ContextMenu.LinkItem
					icon="edit"
					href={routes.User.Settings(org.id, user.id)}
					testid="user_profile.settings_link">
					{t('Edit profile')}
				</ContextMenu.LinkItem>
			)}
			{isParentToUser && (
				<ContextMenu.LinkItem
					icon="add_comment"
					href={routes.Chat.Monitored.Index(org.id, user.accountId)}>
					{t('Show monitored chats')}
				</ContextMenu.LinkItem>
			)}

			{models.membership.isAdminOrStaff(activeMembership) && (
				<ContextMenu.LinkItem
					icon="settings"
					href={routes.Settings.User.Show(org.id, group.id, user.id)}>
					{t('Manage user')}
				</ContextMenu.LinkItem>
			)}
		</ContextMenu.Menu>
	);
};

export default OverviewActions;
