import { ReactNode } from 'react';

export const symbol_r_mobiledata: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-675v85q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Q97-560 88.5-568.625T80-590v-260q0-12.75 8.625-21.375T110-880h191q24 0 42 18t18 42v85q0 19-12 36t-45 21l30 68q8 17.647-2 33.823Q322-560 302-560q-11 0-19.609-6-8.608-6-13.391-16l-41-93h-88Zm0-60h161v-85H140v85Z"
		/>
	</svg>
);
