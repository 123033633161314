import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Membership from 'pkg/models/membership';

import * as routes from 'pkg/router/routes';
import RouterLink from 'pkg/router/Link';
import * as selectors from 'pkg/selectors';
import { RootState } from 'pkg/reducers';
import { useCurrentMembership, useCurrentOrganization } from 'pkg/identity';
import * as models from 'pkg/api/models';

interface UserLinkProps {
	userId: number;
	children?: ReactElement | ReactElement[];
}

const Name = styled.span`
	font-weight: var(--font-weight-semibold);
`;

const Link = styled(RouterLink)`
	font-weight: var(--font-weight-semibold);
`;

export default function UserLink({
	userId,
	children,
}: UserLinkProps): JSX.Element {
	const org = useCurrentOrganization();
	const activeMembership = useCurrentMembership();

	const activeMemberships = useSelector(
		selectors.groups.getMembershipsForGroup(activeMembership.groupId)
	);

	const targetUserMembership = activeMemberships.find(
		(membership: Membership) => membership.userId === userId
	);

	const targetUser = useSelector((state: RootState) =>
		selectors.users.find(state, userId)
	);

	if (!activeMembership || !targetUser) return null;

	if (!targetUserMembership) {
		return <Name>{children ?? targetUser?.fullName}</Name>;
	}

	// Only admins can view parent profiles
	if (
		targetUserMembership.isParent() &&
		!models.membership.isAdminOrStaff(activeMembership)
	) {
		return <Name>{children ?? targetUser?.fullName}</Name>;
	}

	const profileRoute = activeMembership.isOrganizationMembership
		? routes.Profile.Show(org.id, activeMembership.userId)
		: routes.User.Profile.Show(
				org.id,
				activeMembership.groupId,
				userId,
				'overview'
			);

	return <Link href={profileRoute}>{children ?? targetUser.fullName}</Link>;
}
