import { ReactNode } from 'react';

export const symbol_location_away: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M352-473ZM140-120q-24.75 0-42.375-17.625T80-180v-390q0-14.25 6.375-27T104-618l260-195q8.295-6 17.344-9 9.049-3 18.853-3 9.803 0 18.717 3 8.915 3 17.086 9l152 114q12.235 8.8 12.618 20.9Q601-666 594-657q-7 9-18.5 12t-23.5-6L400-765 140-570v390h170q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T310-120H140Zm500-200q54 0 104.5 14t96.5 41q18 11 28.5 29.5T880-196v16q0 25.263-17.368 42.632Q845.263-120 820-120H460q-25.263 0-42.632-17.368Q400-154.737 400-180v-16q0-21 10.5-39.5T439-265q46-27 96.5-41T640-320ZM460-180h360v-29q-42-24-87.227-37.5Q687.546-260 640-260t-92.773 14Q502-232 460-208v28Zm180-180q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35Zm0-60q25.5 0 42.75-17.25T700-480q0-25.5-17.25-42.75T640-540q-25.5 0-42.75 17.25T580-480q0 25.5 17.25 42.75T640-420Zm0 240Z"
		/>
	</svg>
);
