export function Create(groupID: number): string {
	return `/v1/organizations/${groupID}/identity-providers`;
}

export function Delete(groupID: number, identityProviderID: number): string {
	return `/v1/organizations/${groupID}/identity-providers/${identityProviderID}`;
}

export function Index(groupID: number): string {
	return `/v1/organizations/${groupID}/identity-providers`;
}

export function IndexMapping(
	groupID: number,
	identityProviderID: number
): string {
	return `/v1/organizations/${groupID}/identity-providers/${identityProviderID}/mapping`;
}

export function PortalSSO(groupID: number, identityProviderID: number): string {
	return `/v1/organizations/${groupID}/identity-providers/${identityProviderID}/portal-sso`;
}

export function PortalSync(
	groupID: number,
	identityProviderID: number
): string {
	return `/v1/organizations/${groupID}/identity-providers/${identityProviderID}/portal-sync`;
}

export function Show(groupID: number, identityProviderID: number): string {
	return `/v1/organizations/${groupID}/identity-providers/${identityProviderID}`;
}

export function UpdateMapping(
	groupID: number,
	identityProviderID: number
): string {
	return `/v1/organizations/${groupID}/identity-providers/${identityProviderID}/mapping`;
}

export function UpdateSSO(groupID: number, identityProviderID: number): string {
	return `/v1/organizations/${groupID}/identity-providers/${identityProviderID}/sso`;
}

export function UpdateSync(
	groupID: number,
	identityProviderID: number
): string {
	return `/v1/organizations/${groupID}/identity-providers/${identityProviderID}/sync`;
}
