import { ReactNode } from 'react';

export const symbol_photo_prints: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M570-364q38-2 72.5-17t61.5-40q24-22 40.355-49.887Q760.71-498.775 767-531q2-8-3.5-14t-13.5-5q-32 4-62.5 18T633-496q-28 26-45 59.5T570-364Zm0 0q-1-39-18-72.5T507-496q-24-22-54.5-36T390-550q-8-1-13.5 5t-3.5 14q6.29 32.225 22.645 60.113Q412-443 436-421q27 25 61.5 40t72.5 17Zm0-134q21 0 34-13t13-34v-10l10 3q25 8 41.5 4t24.5-18q8-14 6.5-34T675-631l-11-5 11-6q23-12 24.5-31t-6.5-33q-8-14-25-18t-41 4l-10 3v-10q0-21-13-34t-34-13q-21 0-34.5 13T522-727v10l-10-3q-25-8-41-4t-25 18q-9 14-7 33t25 31l11 6-11 5q-23 11-24.5 31t6.5 34q8 14 25 18t41-4l10-3v10q0 21 13.5 34t34.5 13Zm-.018-91Q550-589 536.5-602.518q-13.5-13.517-13.5-33.5Q523-656 536.518-669.5q13.517-13.5 33.5-13.5Q590-683 603.5-669.482q13.5 13.517 13.5 33.5Q617-616 603.482-602.5q-13.517 13.5-33.5 13.5ZM214-81q-25 3-44.5-11.5T147-132L83-630q-3-25 12-45t40-23l32-3q14-2 23 7.143T199-671q0 12-7.583 19.92Q183.833-643.16 173-641l-31 3 65 498 569-69q12-2 22 6t12 20.39q2 12.39-5.5 21.5T784-150L214-81Zm105-177q-24 0-42-18t-18-42v-502q0-24 18-42t42-18h502q24 0 42 18t18 42v502q0 24-18 42t-42 18H319Zm0-60h502v-502H319v502ZM207-140Zm363-429Z"
		/>
	</svg>
);
