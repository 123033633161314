import { ReactNode } from 'react';

export const symbol_bento: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-200q-24 0-42-18t-18-42v-440q0-24 18-42t42-18h680q24 0 42 18t18 42v440q0 24-18 42t-42 18H140Zm410-310h270v-190H550v190ZM140-260h350v-440H140v440Zm180-170q-20 0-35-15t-15-35.5q0-20.5 15-35t35.5-14.5q20.5 0 35 14.583Q370-500.833 370-480q0 20-14.583 35-14.584 15-35.417 15Zm230 170h270v-190H550v190Z"
		/>
	</svg>
);
