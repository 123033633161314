import { ReactNode } from 'react';

export const symbol_vape_free: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M79-828q9-9 21.5-9t21.5 9L850-99l-43 43-144-144H315v-100h248L78-785q-9-9-8.5-21.5T79-828Zm206 531v94q-39-8-74.5-12.5T135-220h-25q-13 0-21.5-8.5T80-250q0-13 8.5-21.5T110-280h25q40 0 75.5-4.5T285-297Zm449-3h96q21 0 35.5 14.5T880-250q0 20-13 34t-33 16L734-300Zm-314 30q-8 0-14 6t-6 14q0 8 6 14t14 6q8 0 14-6t6-14q0-8-6-14t-14-6Zm160-485q11 0 18 7t7 18q0 11-7 18t-18 7q-35 0-56 21t-21 56q0 35 21 61t56 26h71q55 0 94.5 37t39.5 91v68h-50v-56q0-42-24.5-66T651-491h-71q-9 0-22.5-2t-24.5-8l-67-67q-8-16-10.5-32.5T453-628q0-54 36.5-90.5T580-755Zm75-125q54 0 90.5 39t36.5 93q0 31-12 56t-33 42q65 23 104 80.5T880-445v100h-50v-100q0-74-49.5-122.5T655-616q-11 0-18-7t-7-18q0-11 7-18t18-7q31 0 54-23t23-59q0-34-22-58t-55-24q-11 0-18-7t-7-18q0-11 7-18t18-7Z"
		/>
	</svg>
);
