import {
	SET_EXISTING_FORMATION,
	SET_CURRENT_FORMATION,
	SET_NEW_FORMATION,
	SET_SAVING_STATUS,
} from 'pkg/actions/group_formations';
import { AUTH_LOGOUT_SUCCESS } from 'pkg/actions/action-types';

const initialState = {
	currentFormation: 'new',
	saving: false,
	entities: [],
};

const groupFormations = (state = initialState, action) => {
	switch (action.type) {
		case SET_EXISTING_FORMATION: {
			const newState = {
				...state,
			};

			const { formation } = action;

			if (!formation.id) {
				return newState;
			}

			const existingFormationIndex = newState.entities.findIndex(
				(f) => f.id === formation.id
			);

			if (existingFormationIndex === -1) {
				newState.entities.unshift(formation);
			} else {
				newState.entities[existingFormationIndex] = formation;
			}

			return newState;
		}
		case SET_NEW_FORMATION: {
			const newState = {
				...state,
			};

			const { formation } = action;
			newState.entities.unshift(formation);

			return newState;
		}
		case SET_CURRENT_FORMATION: {
			return {
				...state,
				currentFormation: action.currentFormation,
			};
		}
		case SET_SAVING_STATUS: {
			return {
				...state,
				saving: action.saving,
			};
		}
		case AUTH_LOGOUT_SUCCESS:
			return initialState;

		default:
			return {
				...state,
			};
	}
};

export default groupFormations;
