import { ReactNode } from 'react';

export const symbol_tv_remote: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M340-100h280v-237q-27 26-62.5 41.5T480-280q-42 0-77.5-15.5T340-337v237Zm139.765-240Q538-340 579-380.765q41-40.764 41-99Q620-538 579.235-579q-40.764-41-99-41Q422-620 381-579.235q-41 40.764-41 99Q340-422 380.765-381q40.764 41 99 41ZM340-623q27-26 62.5-41.5T480-680q42 0 77.5 15.5T620-623v-237H340v237Zm0 583q-24 0-42-18t-18-42v-760q0-24 18-42t42-18h280q24 0 42 18t18 42v760q0 24-18 42t-42 18H340Zm139.941-385Q457-425 441-441.059q-16-16.059-16-39T441.059-519q16.059-16 39-16T519-518.941q16 16.059 16 39T518.941-441q-16.059 16-39 16Zm.234-305q12.825 0 21.325-8.675 8.5-8.676 8.5-21.5 0-12.825-8.675-21.325-8.676-8.5-21.5-8.5-12.825 0-21.325 8.675-8.5 8.676-8.5 21.5 0 12.825 8.675 21.325 8.676 8.5 21.5 8.5ZM480-280Zm0-400Z"
		/>
	</svg>
);
