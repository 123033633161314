import { List } from 'immutable';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import { Tag } from 'pkg/api/models/tag';

import { FormPayload } from 'components/form/Form';

type ExercisePayloadValue = string | number | string[];

export interface ExerciseFormPayload {
	[key: string]: ExercisePayloadValue;
}

export interface ExerciseData {
	data: ExerciseFormPayload | FormPayload;
	tags: List<Tag>;
	thumbnail: models.attachment.Attachment;
}

export function normalizeExerciseData({ data, tags, thumbnail }: ExerciseData) {
	Object.entries(data).forEach(([key, val]: [string, ExercisePayloadValue]) => {
		if (
			key.startsWith('min') ||
			key.startsWith('max') ||
			key.startsWith('area')
		) {
			val = Number.parseInt('' + val, 10) || 0;
		}

		data[key] = val;
	});

	// @NOTE Set min/max values accordingly where it has been simplified (e.g duration and players)
	data.maxParticipants = data.minParticipants;
	data.maxTime = data.minTime;

	// add tags
	if (tags?.size) {
		data.tags = tags.map((tag: Tag) => tag.name).toArray();
	}

	// link thumbnail
	if (thumbnail) {
		data.thumbnailAttachmentId = thumbnail.id;
	} else if (thumbnail === undefined) {
		data.thumbnailAttachmentId = null;
	}

	return data;
}

export const duplicationConfirmString = t(
	'Duplication will create a new exercise - are you sure?',
	{ _context: 'exercise' }
);
