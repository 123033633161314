import { ReactNode } from 'react';

export const symbol_nature_people: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M163-484q-17 0-30-13t-13-30q0-17 13-30t30-13q17 0 30 13t13 30q0 17-13 30t-30 13Zm44 344h368v-243h-81q-65 0-111-43.968Q337-470.935 337-534q0-46 27.5-88t77.5-59q7-70 55-114.5T608.5-840q63.5 0 111 44.5T775-681q50 17 77.5 59t27.5 88q0 63.065-46.5 107.032Q787-383 722-383h-87v243h181q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q846-97 837.375-88.5T816-80H150q-12.75 0-21.375-8.625T120-110v-156h-10q-13 0-21.5-8.5T80-296v-135q0-12 9.55-19.5 9.552-7.5 23.67-7.5h99.661q14.119 0 23.619 7.5T246-431v135q0 13-8.5 21.5T216-266h-9v126Zm287-303h228q38 0 68-26t30-65q0-31.183-20-56.592Q780-616 751-628l-36-15v-31q0-45-32-75.5T608.264-780q-42.737 0-74.5 30.5Q502-719 502-674v31l-36 15q-29 12-49 37.408-20 25.409-20 56.592 0 39 29.5 65t67.5 26Zm115-169Z"
		/>
	</svg>
);
