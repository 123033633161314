import { ReactNode } from 'react';

export const symbol_attach_money: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M481-120q-13 0-21.5-8.5T451-150v-54q-48-8-82-34t-53-66q-5-11 0-23t17-17q11-5 22.5 0t17.5 16q18 34 47 51t67 17q48 0 79-24t31-66q0-44-27.5-68T466-467q-72-23-107.5-61T323-623q0-55 35.5-92t92.5-42v-53q0-13 8.5-21.5T481-840q13 0 21.5 8.5T511-810v53q36 5 64.5 22t47.5 44q7 10 3 21.5T610-653q-11 5-23 1t-20-15q-14-18-34.5-26.5T483-702q-46 0-73 21t-27 57q0 38 30 61.5T524-514q68 21 100.5 60.5T657-354q0 63-37 101.5T511-203v53q0 13-8.5 21.5T481-120Z"
		/>
	</svg>
);
