import * as models from 'pkg/api/models';

import Avatar from 'components/avatar';

import * as UserCard from 'components/user/UserCard';

interface AccountTargetProps {
	account: models.account.Account;
}

export default function AccountTarget({
	account,
}: AccountTargetProps): JSX.Element {
	return (
		<UserCard.Base>
			<UserCard.User>
				<Avatar account={account} size={44} />
				<div>
					<strong>{models.account.fullName(account)}</strong>
				</div>
			</UserCard.User>
		</UserCard.Base>
	);
}
