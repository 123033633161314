import { useSelector } from 'react-redux';

import User from 'pkg/models/user';

import * as selectors from 'pkg/selectors';
import { RootState } from 'pkg/reducers';

import { useClipState } from 'components/video-analytics/ClipState';

export interface UserTag {
	comment: string;
	user: User;
}

export default function useUserTags(): Set<UserTag> {
	const { taggedUsers, numTaggedUsers } = useClipState();
	const users = useSelector((state: RootState) =>
		selectors.users.findAllByIds(
			state,
			Object.keys(taggedUsers).map((stringId) => Number.parseInt(stringId, 10))
		)
	);

	const data = new Set<UserTag>();

	if (!numTaggedUsers) {
		return data;
	}

	for (const [userId, comment] of Object.entries(taggedUsers)) {
		const user = users.find(
			(user: User) => user.id === Number.parseInt(userId, 10)
		);

		if (user) {
			data.add({
				user,
				comment,
			});
		}
	}

	return data;
}
