import { ReactNode } from 'react';

export const symbol_reduce_capacity: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M130-80v-13q0-21.081 11.408-38.649Q152.816-149.216 173-158q25.078-10.522 51.603-16.261Q251.128-180 280.064-180t55.417 5.739Q361.963-168.522 387-158q20.184 8.784 31.592 26.351Q430-114.081 430-93v13H130Zm400 0v-13q0-21.081 11.408-38.649Q552.816-149.216 573-158q25.078-10.522 51.603-16.261Q651.128-180 680.064-180t55.417 5.739Q761.963-168.522 787-158q20.184 8.784 31.592 26.351Q830-114.081 830-93v13H530ZM279.956-235Q249-235 227-257.044q-22-22.045-22-53Q205-341 227.044-363q22.045-22 53-22Q311-385 333-362.956q22 22.045 22 53Q355-279 332.956-257q-22.045 22-53 22Zm400 0Q649-235 627-257.044q-22-22.045-22-53Q605-341 627.044-363q22.045-22 53-22Q711-385 733-362.956q22 22.045 22 53Q755-279 732.956-257q-22.045 22-53 22ZM505-422l36-36q7.5-8 17.5-8t17.5 8q8 8 8 17.5t-8 17.5l-75 75q-9 9-21 9t-21-9l-75-75q-8-8-7.5-17.5T385-458q8-8 17.5-8t17.5 8l35 36v-68q0-10.833 7.116-17.917 7.117-7.083 18-7.083Q491-515 498-507.917q7 7.084 7 17.917v68ZM80-575v-13q0-21.081 11.408-38.649Q102.816-644.216 123-653q25.063-10.522 51.572-16.261Q201.081-675 230-675q25 0 51.5 5t46.5 13q-14 16-21 31t-7 38v13H80Zm250 0v-13q0-21.081 11.408-38.649Q352.816-644.216 373-653q25.078-10.522 51.603-16.261Q451.128-675 480.064-675t55.417 5.739Q561.963-663.522 587-653q20.184 8.784 31.592 26.351Q630-609.081 630-588v13H330Zm330 0v-13q0-23-6.5-38T633-657q20-8 46-13t51-5q28.919 0 55.428 5.739Q811.937-663.522 837-653q20.184 8.784 31.592 26.351Q880-609.081 880-588v13H660ZM229.956-730Q199-730 177-752.044q-22-22.045-22-53Q155-836 177.044-858q22.045-22 53-22Q261-880 283-857.956q22 22.045 22 53Q305-774 282.956-752q-22.045 22-53 22Zm250 0Q449-730 427-752.044q-22-22.045-22-53Q405-836 427.044-858q22.045-22 53-22Q511-880 533-857.956q22 22.045 22 53Q555-774 532.956-752q-22.045 22-53 22Zm250 0Q699-730 677-752.044q-22-22.045-22-53Q655-836 677.044-858q22.045-22 53-22Q761-880 783-857.956q22 22.045 22 53Q805-774 782.956-752q-22.045 22-53 22Z"
		/>
	</svg>
);
