import { ReactNode } from 'react';

export const symbol_heap_snapshot_thumbnail: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M100-200q-24.75 0-42.375-17.625T40-260v-440q0-24.75 17.625-42.375T100-760h440q24.75 0 42.375 17.625T600-700v440q0 24.75-17.625 42.375T540-200H100Zm620-320q-17 0-28.5-11.5T680-560v-160q0-17 11.5-28.5T720-760h160q17 0 28.5 11.5T920-720v160q0 17-11.5 28.5T880-520H720Zm20-60h120v-120H740v120ZM100-260h440v-440H100v440Zm620 60q-17 0-28.5-11.5T680-240v-160q0-17 11.5-28.5T720-440h160q17 0 28.5 11.5T920-400v160q0 17-11.5 28.5T880-200H720Zm20-60h120v-120H740v120Zm-640 0v-440 440Zm640-320v-120 120Zm0 320v-120 120Zm-319.5-70q20.5 0 35-15t14.5-35.5q0-20.5-14.583-35Q440.833-430 420-430q-20 0-35 14.583-15 14.584-15 35.417 0 20 15 35t35.5 15ZM239-357l204-204q9-9 9-21t-9-21q-9-9-21-9t-21 9L197-399q-9 9-9 21t9 21q9 9 21 9t21-9Zm-18.5-173q20.5 0 35-15t14.5-35.5q0-20.5-14.583-35Q240.833-630 220-630q-20 0-35 14.583-15 14.584-15 35.417 0 20 15 35t35.5 15Z"
		/>
	</svg>
);
