import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import * as breakpoints from 'pkg/config/breakpoints';

import { MembershipRole } from 'pkg/models/membership';

import { RootState } from 'pkg/reducers';
import * as selectors from 'pkg/selectors';
import * as actions from 'pkg/actions';
import { useUser, useCheckRelationToUser } from 'pkg/hooks/selectors';
import store from 'pkg/store/createStore';
import { useCurrentGroup } from 'pkg/identity';

import RecentTraining from 'components/user/profile/RecentTraining';
import PhysicalStatistics from 'components/user/profile/statistics/PhysicalStrain';

const StatsWrapper = styled.div`
	display: grid;
	grid-row-gap: 0.5rem;

	@media ${breakpoints.small} {
		padding: 0 0 10px 0;
	}
`;

interface StatsProps {
	userId: number;
}

export default function Stats({ userId }: StatsProps): JSX.Element {
	const user = useUser(userId);
	const group = useCurrentGroup();

	const groupUsers = useSelector((state: RootState) =>
		selectors.users.findAllByGroup(state, group.id, MembershipRole.User)
	);

	const { isParentToUser } = useCheckRelationToUser(userId);

	useEffect(() => {
		if (!group.id) {
			return;
		}

		const forAccount = isParentToUser ? user.accountId : null;

		actions.groups.fetchGroupActivities(
			group.id,
			userId,
			forAccount
		)(store.dispatch);
	}, [userId, group.id]);

	let physicalStrainStats = null;

	if (group.id) {
		physicalStrainStats = (
			<PhysicalStatistics user={user} users={groupUsers.toList()} />
		);
	}

	return (
		<StatsWrapper>
			{physicalStrainStats}
			<RecentTraining userId={userId} user={user} groupId={group.id} />
		</StatsWrapper>
	);
}
