const defaultPathAttrs = {
	stroke: 'currentColor',
	strokeWidth: '1.6',
	strokeLinecap: 'round',
	strokeLinejoin: 'round',
};

export default {
	name: 'create',
	objects: [
		{
			type: 'circle',
			attributes: {
				cx: '12.8389',
				cy: '12.0534',
				r: '7.59601',
				stroke: 'currentColor',
				strokeWidth: '1.3',
				fill: 'none',
			},
		},
		{
			type: 'path',
			attributes: {
				...defaultPathAttrs,
				d: 'M12.8389 8.93567V15.0636',
			},
		},
		{
			type: 'path',
			attributes: {
				...defaultPathAttrs,
				d: 'M15.9028 11.9996L9.77492 11.9996',
			},
		},
	],
};
