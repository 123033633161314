import { ReactNode } from 'react';

export const symbol_chess: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-140h600v-120H180v120Zm132-180h336l-24-170H336l-24 170ZM150-80q-12.75 0-21.375-8.625T120-110v-150q0-24.75 17.625-42.375T180-320h72l24-170h-76q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T200-550h560q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T760-490h-76l24 170h72q24.75 0 42.375 17.625T840-260v150q0 12.75-8.625 21.375T810-80H150Zm330-789q5.419 0 10.839 2 5.419 2 10.161 6 27 22 56 33t60 11q24 0 48.5-6.5T713-844q7-4 13.5-4t11.5 4q5 4 8 9.667 3 5.666 1 12.333l-60 272h-62l46-213q-16 3-30.5 5t-28.5 2q-36 0-69-10.5T480-797q-30 20-63 30.5T348-756q-14 0-28.5-2t-30.5-5l46 213h-62l-60-272q-2-7.071 1-12.536 3-5.464 8.111-9.555 5.111-4.091 11.5-4.5Q240-849 247-844q23 13 47.5 20t48.5 7q31 0 60-11t56-33q4.742-4 10.161-6 5.42-2 10.839-2Zm0 379Zm0-60Zm0 410Z"
		/>
	</svg>
);
