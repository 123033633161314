import { ReactNode } from 'react';

export const symbol_doorbell: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-270q13 0 23-8.5t12-21.5h-70q2 13 12 21.5t23 8.5Zm-140-50h280q6 0 10.5-4.5T635-335q0-6-4.5-10.5T620-350h-25v-99q0-42.603-24-77.46-24-34.857-66-44.54v-14q0-10.833-7.116-17.917-7.117-7.083-18-7.083Q469-610 462-602.917q-7 7.084-7 17.917v14q-42 9.683-66 44.54T365-449v99h-25q-6 0-10.5 4.5T325-335q0 6 4.5 10.5T340-320ZM160-180v-390q0-14.25 6.375-27T184-618l260-195q8.295-6 17.344-9 9.049-3 18.853-3 9.803 0 18.717 3 8.915 3 17.086 9l260 195q11.25 8.25 17.625 21T800-570v390q0 24.75-17.625 42.375T740-120H220q-24.75 0-42.375-17.625T160-180Zm60 0h520v-394L480-763 220-574v394Zm260-292Z"
		/>
	</svg>
);
