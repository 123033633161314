import { ReactNode } from 'react';

export const symbol_wifi_calling_2: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M660-541q-17 0-28.5-11.5T620-581q0-17 11.5-28.5T660-621q17 0 28.5 11.5T700-581q0 17-11.5 28.5T660-541Zm0-135q-20 0-37.5 5.5T589-654q-9.778 6-20.889 5.5Q557-649 549-657q-8-8.182-7.5-19.091t9.667-17.766Q575-711 602.5-721t57.5-10q30 0 57.5 10t51.333 27.143Q778-687 778.5-675.727 779-664.455 771-656q-8 8-19 8t-21-6q-16-11-33.5-16.5T660-676Zm0-110q-41.087 0-78.065 13.205Q544.957-759.589 513-735q-9 8-21 8t-20-8q-8-8-7.5-19t9.5-19q39.211-32.162 86.628-50.081Q608.046-841 660.023-841t99.378 17.919Q806.803-805.162 846-773q9 8 9.5 19t-7.5 19q-8 8-19.5 8t-20.611-7.543Q776-760 738.714-773 701.429-786 660-786Zm135 666q-116 0-236.5-56T335-335Q232-438 176-558.5T120-795q0-19.286 12.857-32.143T165-840h140q14 0 24 10t14 25l26.929 125.641Q372-665 369.5-653.5q-2.5 11.5-10.729 19.726L259-533q26 44 55 82t64 72q37 38 78 69.5t86 55.5l95-98q10-11 23.151-15T686-369l119 26q15 4 25 16.044T840-300v135q0 19.286-12.857 32.143T795-120ZM229-588l81-82-23-110H180q2 42 13.5 88.5T229-588Zm369 363q41 19 89 31t93 14v-107l-103-21-79 83ZM229-588Zm369 363Z"
		/>
	</svg>
);
