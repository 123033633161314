import { ReactNode } from 'react';

export const symbol_recent_actors: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M100-200q-24 0-42-18t-18-42v-440q0-24 18-42t42-18h440q24 0 42 18t18 42v440q0 24-18 42t-42 18H100Zm0-112q49-32 105.5-48T320-376q58 0 114.5 16T540-312v-388H100v388Zm220-4q-53 0-101 13.5T130-260h380q-43-29-92-42.5T320-316Zm0-113q-46 0-78.5-32.5T209-540q0-46 32.5-78.5T320-651q46 0 78.5 32.5T431-540q0 46-32.5 78.5T320-429Zm-.5-53q24.5 0 41.5-16.5t17-41q0-24.5-16.917-41.5-16.916-17-41.083-17-25 0-41.5 16.917Q262-564.167 262-540q0 25 16.5 41.5t41 16.5Zm410.325 282Q717-200 708.5-208.625T700-230v-500q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T760-730v500q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Zm160 0Q877-200 868.5-208.625T860-230v-500q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T920-730v500q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM320-540Zm0 280Z"
		/>
	</svg>
);
