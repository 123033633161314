import { replaceState } from 'pkg/router/state';
import * as routes from 'pkg/router/routes';
import { useCurrentOrganization } from 'pkg/identity';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';

export default function OrganizationRedirect(): JSX.Element {
	const organization = useCurrentOrganization();

	useComponentDidMount(() => {
		replaceState(routes.Organization.Home(organization.id));
	});

	return null;
}
