import { ReactNode } from 'react';

export const symbol_dresser: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M160-120v-660q0-24.75 17.625-42.375T220-840h520q24.75 0 42.375 17.625T800-780v660h-60v-80H220v80h-60Zm60-400h230v-260H220v260Zm290-160h230v-100H510v100Zm0 160h230v-100H510v100ZM410-330h140v-60H410v60ZM220-460v200h520v-200H220Zm0 0v200-200Z"
		/>
	</svg>
);
