import useComponentDidMount from 'pkg/hooks/useComponentDidMount';

export function canRequestFullscreen(
	node: HTMLElement = document.body
): boolean {
	const supportsFullscreen =
		node.requestFullscreen !== undefined ||
		node.webkitEnterFullscreen !== undefined ||
		node.webkitRequestFullscreen !== undefined;

	return supportsFullscreen;
}

export async function requestFullscreen(
	node: HTMLElement = document.body
): Promise<void> {
	if (!canRequestFullscreen(node)) {
		return Promise.reject();
	}

	try {
		if (node.webkitRequestFullscreen) {
			await node.webkitRequestFullscreen();
		} else if (node.webkitEnterFullscreen) {
			await node.webkitEnterFullscreen();
		} else if (node.requestFullscreen) {
			await node.requestFullscreen();
		} else {
			throw new Error('Fullscreen API not supported');
		}

		return Promise.resolve();
	} catch (e: unknown) {
		console.error(e);

		return Promise.reject();
	}
}

export function inFullscreen(): boolean {
	return !!document.fullscreenElement || !!document.webkitFullscreenElement;
}

export async function exitFullscreen(): Promise<void> {
	if (inFullscreen()) {
		if (document.fullscreenElement) {
			document.exitFullscreen();
		} else if (document.webkitFullscreenElement) {
			document.webkitExitFullscreen();
		}
	}

	return Promise.resolve();
}

type FullscreenChangeObserver = () => void;

export function useFullscreenChangeObserver(
	callback: FullscreenChangeObserver
) {
	// Chrome and FF needs the prefix
	const prefixes = ['', 'moz', 'webkit'];

	useComponentDidMount(
		() => {
			prefixes.forEach((prefix: string) => {
				document.addEventListener(`${prefix}fullscreenchange`, callback);
			});
		},
		() => {
			prefixes.forEach((prefix: string) => {
				document.removeEventListener(`${prefix}fullscreenchange`, callback);
			});
		}
	);
}
