import { ReactNode } from 'react';

export const symbol_bolt: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m393-165 279-335H492l36-286-253 366h154l-36 255Zm-33-195H217q-17.872 0-26.436-16T193-407l338-488q8-11 20-15t24 1q12 5 19 16.17 7 11.17 5 23.83l-39 309h176q19.362 0 27.181 17Q771-526 759-511L388-66q-8.442 9.905-20.721 12.952Q355-50 344-55.25t-17.5-16.125Q320-82.25 322-95l38-265Zm113-115Z"
		/>
	</svg>
);
