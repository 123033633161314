import { ReactNode } from 'react';

export const symbol_blood_pressure: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M80-600v-140q0-24 18-42t42-18h680q24 0 42 18t18 42v230q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T820-510v-230H140v140q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Q97-570 88.5-578.625T80-600Zm200 310q-9 0-16-4t-12-12l-71-144h-71q-12.75 0-21.375-8.675Q80-467.351 80-480.175 80-493 88.625-501.5T110-510h90q8.171 0 15.229 4.636Q222.286-500.727 226-493l54 106 133-266q4-8 11.381-11.5t15.5-3.5q8.119 0 15.619 3.5T467-653l80 161q-13 8-25 18.5T499-452l-59-120-134 266q-3.611 8-10.833 12-7.223 4-15.167 4ZM140-160q-24 0-42-18t-18-42v-140q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-360v140h279q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T419-160H140Zm340-320ZM679.894-90Q601-90 545.5-145.606t-55.5-134.5Q490-359 545.606-414.5t134.5-55.5Q759-470 814.5-414.394t55.5 134.5Q870-201 814.394-145.5T679.894-90ZM765-337q6-6 6-14t-6-14q-6-6-14-6t-14 6l-63 62q-6 6-6 14.5t6 14.5q6 6 14.5 6t14.5-6l62-63Z"
		/>
	</svg>
);
