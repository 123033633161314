import { ReactNode } from 'react';

export const symbol_app_badging: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-480Zm-400 0q0-95 40.5-175T228-790q67-55 153.5-78.5T562-872q12 2 18.5 13t2.5 23q-4 12-15 18t-24 4q-78-15-150 4.5T265-743q-57 47-91 114.5T140-480q0 141 99.5 240.5T480-140q80 0 147.5-33.5T742-264q49-59 68-132t4-148q-2-13 4-24t18-15q12-4 23 2.5t13 18.5q19 92-4 178.5T791-229q-57 72-138 110.5T480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480Zm640-130q-45 0-77.5-32.5T610-720q0-46 32.5-78t77.5-32q46 0 78 32t32 78q0 45-32 77.5T720-610Z"
		/>
	</svg>
);
