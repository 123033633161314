import { Linkable } from './linkable';

export interface Collection<T> extends Linkable {
	meta: { [key: string]: unknown };
	records: T[];
}

export function hasPrev(linkable: Linkable): boolean {
	return !!linkable.links && 'previous' in linkable.links;
}

export function prev(linkable: Linkable): string {
	return linkable.links?.previous;
}

export function hasNext(linkable: Linkable): boolean {
	return !!linkable?.links && 'next' in linkable.links;
}

export function next(linkable: Linkable): string {
	return linkable.links?.next;
}

export function byPage(linkable: Linkable, page: number): string {
	const [link, params] = linkable.links.next
		? linkable.links.next.split('?')
		: linkable.links.previous.split('?');

	const linkParams: URLSearchParams = new URLSearchParams(params);

	linkParams.set('page', page.toString());

	return `${link}?${linkParams.toString()}`;
}
