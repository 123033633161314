import { Fragment, useContext, useState } from 'react';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';

import ResourceColumn from 'routes/scheduling/templates/single/resources/ResourceColumn';
import { TemplateContext } from 'routes/scheduling/templates/single';
import {
	getAvailableSlots,
	getItemResourceIds,
	getTotalHeightOfDay,
	ScheduleGridItem,
} from 'routes/scheduling/utils';
import TemplateScheduleItem from 'routes/scheduling/templates/schedule_items/template_item';
import { FilterState } from 'routes/scheduling/templates/filters';
import CreateScheduleItem from 'routes/scheduling/templates/modals/CreateScheduleItem';
import { SlotItem } from 'routes/scheduling/templates/schedule_items/slot_item';

import Column from 'components/layout/column';
import GridRow from 'components/scheduling/grid/row';
import * as dayStyles from 'components/scheduling/grid/day-item/styles.css';
import NewBooking from 'components/scheduling/grid/new-booking';

interface DayProps {
	isInView: boolean;
	dayNumber: number;
	filters: FilterState;

	resource: models.resource.Resource;

	scheduleItems: models.scheduleItem.ScheduleItem[];
}

const Day = ({
	filters,
	scheduleItems,
	dayNumber,
	resource,
	isInView,
}: DayProps) => {
	const templateContext = useContext(TemplateContext);
	const [modal, setModal] = useState('');

	const showBookingModal = () => setModal('create');
	const hideModal = () => setModal('');

	const slots = models.schedule.parseSlots(templateContext.schedule);

	const availableSlots = getAvailableSlots(slots, scheduleItems, dayNumber);

	if (!isInView) {
		const totalHeight = getTotalHeightOfDay(
			scheduleItems as ScheduleGridItem[],
			templateContext.conflicts,
			templateContext.isSmallScreen,
			templateContext.view,
			availableSlots
		);

		return <div style={{ height: `${totalHeight}px` }} />;
	}

	return (
		<Fragment>
			<div className={dayStyles.itemWrapper}>
				<Column spacing={styles.spacing._2}>
					{templateContext.showSlots &&
						availableSlots.map((slot, i) => (
							<SlotItem
								key={i}
								slot={slot}
								resourceId={resource.id}
								dayNumber={dayNumber}
								slots={slots}
							/>
						))}
					{scheduleItems.map((item, i) => (
						<TemplateScheduleItem
							key={i}
							scheduleItem={item}
							schedule={templateContext.schedule}
							filters={filters}
						/>
					))}
				</Column>
				<NewBooking onClick={showBookingModal} />
				{modal === 'create' && (
					<CreateScheduleItem
						groups={templateContext.groups}
						resources={templateContext.resources}
						schedule={templateContext.schedule}
						day={dayNumber}
						onBeforeSave={templateContext.onBeforeScheduleItemSave}
						onSuccess={templateContext.onSuccessScheduleItemSave}
						onFail={templateContext.onFailScheduleItemSave}
						scheduleItems={templateContext.scheduleItems}
						resourceId={resource.id}
						hideModal={hideModal}
					/>
				)}
			</div>
		</Fragment>
	);
};

interface ResourceRowProps {
	filters: FilterState;
	resource: models.resource.Resource;
	scheduleItems: models.scheduleItem.ScheduleItem[];
}

const ResourceRow = ({
	scheduleItems,
	filters,
	resource,
}: ResourceRowProps) => {
	const [isInView, setIsInView] = useState(false);
	const templateContext = useContext(TemplateContext);

	const days = [];
	for (let i = 1; i <= templateContext.schedule.days; i++) {
		days.push(
			<div key={i} className={dayStyles.gridItem}>
				<Day
					dayNumber={i}
					scheduleItems={scheduleItems.filter(
						(item) =>
							item.day === i && getItemResourceIds(item).includes(resource.id)
					)}
					filters={filters}
					resource={resource}
					isInView={isInView}
				/>
			</div>
		);
	}

	return (
		<GridRow cols={7} setIsInView={setIsInView}>
			{isInView && <ResourceColumn resource={resource} filters={filters} />}
			{days}
		</GridRow>
	);
};

export default ResourceRow;
