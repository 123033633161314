import { ReactNode } from 'react';

export const symbol_ventilator: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M500-660h200v-120H500v120ZM160-260q25.5 0 42.75-17.25T220-320q0-25.5-17.25-42.75T160-380q-25.5 0-42.75 17.25T100-320q0 25.5 17.25 42.75T160-260Zm320 130q-73.491 0-139.245-22.5Q275-175 221-217q-14 8-29 12.5t-32 4.5q-50 0-85-35t-35-85q0-25.556 10.5-47.778Q61-390 78-407q-6-17-11.133-34.034Q61.733-458.069 58-476q-2-12 5-22t19.966-12q12.966-2 22.5 6T117-483q2 11.647 5.5 23.824Q126-447 130-436q7-2 14.393-3 7.393-1 15.607-1 50 0 85 35t35 85q0 16.872-4 31.936T264-259q40 29 86.851 46.062Q397.701-195.875 450-191v-209q0-21.25-14.375-35.625T400-450q-63 0-106.5-43.5T250-600q0-63 43.5-106.5T400-750h40v-30q0-24.75 17.625-42.375T500-840h200q24.75 0 42.375 17.625T760-780v30h40q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T800-690h-40v30q0 24.75-17.625 42.375T700-600H500q-24.75 0-42.375-17.625T440-660v-30h-40q-38 0-64 26t-26 64q0 38 26 64t64 26q45.833 0 77.917 32.083Q510-445.833 510-400v209q96-8 173.5-60T805-384l-23-11q-6-3-9.5-7.5t-5-9.5q-1.5-5-1.5-11t3-11.6q3-6.4 7.385-9.971 4.384-3.572 9.5-5Q791-451 797-451t12 3l20 10 7.333-22Q840-471 842-482q3.048-13 12.571-21.5 9.524-8.5 22.477-6.5Q890-508 897-498q7 10 5 22-4 17-8.5 33T883-411l15 7q5.778 3 9.389 7.5Q911-392 912.5-387q1.5 5 1.5 11t-3 12.4q-3 5.6-7.385 8.933-4.384 3.334-9.5 5Q889-348 883-348t-12-3l-12-6q-55 102-156.064 164.5T480-130Zm20-530v-120 120ZM160-320Z"
		/>
	</svg>
);
