import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { t } from '@transifex/native';

import * as palette from 'pkg/config/palette';

import * as commentActions from 'pkg/actions/comments';

import { OrganizationIdentityContext } from 'pkg/identity/organization';
import rgba from 'pkg/rgba';
import * as sdk from 'pkg/core/sdk';

import Icon from 'components/icon';
import Avatar from 'components/avatar';

import TextArea from 'components/form/TextArea';

/* @components */

const CommentFormWrapper = styled.form`
	display: grid;
	grid-template-columns: 2rem 1fr 1.5rem;
	align-items: center;
	justify-content: center;
	grid-gap: 0.5rem;
`;

const CommentSendIcon = styled(Icon)`
	color: ${(props) =>
		props.disabled
			? rgba(palette.icon.idleFillColor)
			: rgba(palette.icon.activeFillColor)};
	font-size: 1.4rem;
	transition: color 250ms ease-in-out;
`;

/**
 * @type {React.Element<any>}
 */
class CommentForm extends Component {
	static contextType = OrganizationIdentityContext;

	static propTypes = {
		id: PropTypes.number.isRequired,
		user: PropTypes.shape({
			id: PropTypes.number.isRequired,
		}).isRequired,
		commentLink: PropTypes.string.isRequired,
		commentColumnName: PropTypes.oneOf(['comment', 'content']),
		addComment: PropTypes.any,
		type: PropTypes.any,
	};

	static defaultProps = {
		commentColumnName: 'comment',
	};

	state = {
		comment: '',
	};

	get formProps() {
		return {
			id: this.props.targetId,
			onSubmit: this.handleSubmit,
		};
	}

	handleChange = (e) => {
		this.setState({ comment: e.target.value });
	};

	handleSubmit = async (e) => {
		e.preventDefault();
		e.stopPropagation();

		const { commentLink } = this.props;

		if (this.state.comment.trim() === '') {
			return;
		}

		const response = this.props.createEventComment
			? await this.props.createEventComment(this.state.comment)
			: await sdk.post(
					commentLink,
					{},
					{
						userId: this.context.user.id,
						comment: this.state.comment,
					}
				);

		if (response.ok) {
			if (!response.bodyUsed) {
				const result = await response.json();

				const comment = {
					...result,
					user: this.context.user,
				};

				this.setState({ comment: '' }, this.addComment.bind(this, comment));
				return;
			}

			this.setState({
				comment: '',
			});
		}
	};

	addComment(comment) {
		this.props.addComment(this.props.id, comment);
	}

	get disabled() {
		return this.state.comment.length < 1;
	}

	render() {
		return (
			<CommentFormWrapper {...this.formProps}>
				<Avatar user={this.context.user} />
				<TextArea
					value={this.state.comment}
					placeholder={t('Comment')}
					onChange={this.handleChange}
				/>
				<CommentSendIcon
					onClick={this.handleSubmit}
					name="plane"
					disabled={this.disabled}
				/>
			</CommentFormWrapper>
		);
	}
}

const mapStateToProps = (state, props) => {
	let { stateKey, id } = props;

	if (!stateKey) {
		return {};
	}

	let commentLink = '';

	// Helper function perhaps?
	if (stateKey === 'activities') {
		commentLink = `/user-activities/${id}/comments`;
	} else if (stateKey === 'posts') {
		commentLink = `/group-posts/${id}/comments`;
	}

	return {
		id,
		commentLink,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	if (!ownProps.stateKey) {
		return {};
	}

	return bindActionCreators(
		{
			addComment:
				ownProps.stateKey === 'activities'
					? commentActions.addActivityComment
					: commentActions.addPostComment,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
	forwardRef: true,
})(CommentForm);
