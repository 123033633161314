import { ReactNode } from 'react';

export const symbol_view_in_ar_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M794-80H691q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T691-140h55v8L126-748h14v57q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Q97-661 88.5-669.625T80-691v-103l-33-33q-9-9.067-9-21.533Q38-861 47.053-870q9.052-9 21.5-9Q81-879 90-870l765 765q9 9 9 21t-9.053 21q-9.052 9-21.5 9Q821-54 812-62.914L794-80Zm86-189v103l-60-60v-43q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T880-269ZM166-880h103q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T269-820h-43l-60-60Zm683.825 219Q837-661 828.5-669.625T820-691v-129H691q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T691-880h129q24.75 0 42.375 17.625T880-820v129q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM140-80q-24.75 0-42.375-17.625T80-140v-129q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-269v129h129q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q299-97 290.375-88.5T269-80H140Zm396-430Zm-54 32Zm260 174L329-717l120-71q14-8 31-8t31 8l221 131q13.775 8.29 21.388 22.232Q761-620.826 761-605v257q0 12.774-5 24.387Q751-312 742-304ZM448-167 228-296q-13.775-8.29-21.387-22.232Q199-332.174 199-348v-257q0-15.826 7.613-29.768Q214.225-648.71 228-657l47-28 207 207 207 207-181 104q-14.328 8-30.164 8Q462-159 448-167Zm65-366Zm-63 73Zm108-28Zm-48 38ZM374-672l139 139 157-91-190-109-106 61Zm76 436v-224L260-569v219l190 114Zm251-109v-224l-143 81 143 143ZM510-236l134-80-134-134v214Z"
		/>
	</svg>
);
