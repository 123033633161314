import { ReactNode } from 'react';

export const symbol_emoji_people: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M368-110v-508q-88-18-144.5-77T159-832q-1-11 8-17.5t21-6.5q11 0 20.692 6.375Q218.385-843.25 220-832q10 69 68 114.5T426-672h100q38 0 56 6.5t46 30.5l161 151q10 9 10 21.5t-9 21.5q-9 9-21 9t-21-8L584-594v484q0 12.75-8.675 21.375Q566.649-80 553.825-80 541-80 532.5-88.625T524-110v-225h-96v225q0 12.75-8.675 21.375Q410.649-80 397.825-80 385-80 376.5-88.625T368-110Zm108.08-624q-30.08 0-51.58-21.42-21.5-21.421-21.5-51.5 0-30.08 21.42-51.58 21.421-21.5 51.5-21.5 30.08 0 51.58 21.42 21.5 21.421 21.5 51.5 0 30.08-21.42 51.58-21.421 21.5-51.5 21.5Z"
		/>
	</svg>
);
