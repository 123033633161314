import { ReactNode } from 'react';

export const symbol_time_auto: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M412.971-355H545l16 50q3.462 9.167 11.25 14.583Q580.038-285 589.558-285 606-285 614.5-298t3.5-28l-94-265q-4-10-12.75-16T492-613h-23.947Q457-613 448.5-607T436-590.04L341-324q-5 14 3.615 26.5Q353.231-285 368-285q9.862 0 17.931-6T397-306l15.971-49ZM429-403l51-153 50 153H429Zm-39-457q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T390-920h180q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T570-860H390Zm90 779q-74 0-139.5-28.5T226-187q-49-49-77.5-114.5T120-441q0-74 28.5-139.5T226-695q49-49 114.5-77.5T480-801q67 0 126 22.5T711-716l30-30q9-9 21-9t21 9q9 9 9 21t-9 21l-30 30q36 40 61.5 97T840-441q0 74-28.5 139.5T734-187q-49 49-114.5 77.5T480-81Zm0-60q125 0 212.5-87.5T780-441q0-125-87.5-212.5T480-741q-125 0-212.5 87.5T180-441q0 125 87.5 212.5T480-141Zm0-299Z"
		/>
	</svg>
);
