// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vbxQMdIyAXmfOJP_lryz {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	    -ms-flex-pack: end;
	        justify-content: flex-end;
	gap: var(--spacing-3);
	padding: var(--spacing-4);
	background-color: var(--palette-blue-100);
	color: var(--palette-blue-500);
	border-radius: var(--radius-3);
}
`, "",{"version":3,"sources":["webpack://./routes/user/settings/style.css"],"names":[],"mappings":"AAAA;CACC,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,qBAAyB;KAAzB,kBAAyB;SAAzB,yBAAyB;CACzB,qBAAqB;CACrB,yBAAyB;CACzB,yCAAyC;CACzC,8BAA8B;CAC9B,8BAA8B;AAC/B","sourcesContent":[".formActions {\n\tdisplay: flex;\n\tjustify-content: flex-end;\n\tgap: var(--spacing-3);\n\tpadding: var(--spacing-4);\n\tbackground-color: var(--palette-blue-100);\n\tcolor: var(--palette-blue-500);\n\tborder-radius: var(--radius-3);\n}\n"],"sourceRoot":""}]);
// Exports
export var formActions = `vbxQMdIyAXmfOJP_lryz`;
export default ___CSS_LOADER_EXPORT___;
