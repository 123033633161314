import { ReactNode } from 'react';

export const symbol_crop_7_5: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-200q-24 0-42-18t-18-42v-440q0-23 18-41.5t42-18.5h600q24 0 42 18.5t18 41.5v440q0 24-18 42t-42 18H180Zm0-60h600v-440H180v440Zm0 0v-440 440Z"
		/>
	</svg>
);
