import { useT } from '@transifex/react';
import { useEffect, useState } from 'react';

import { palette, spacing } from 'pkg/config/styles';

import * as endpoints from 'pkg/api/endpoints/auto';
import { useCurrentOrganization } from 'pkg/identity';
import { useCollection } from 'pkg/api/use_collection';
import * as actions from 'pkg/actions';

import MaterialSymbol from 'components/material-symbols';
import Modal from 'components/modal';
import * as Card from 'components/Card';

import Row from 'components/layout/row';
import Column from 'components/layout/column';
import SelectOrganizationsModal from 'components/training-library/modals/share/select-organizations';
import { Spinner } from 'components/loaders/spinner';

import BlockPlaceholder from 'design/placeholders/block';
import Button from 'design/button';

import * as css from './styles.css';

interface TrainingCollectionOrg {
	id: number;
	name: string;
	profileImageUrl: string;
}

export interface TrainingCollectionShareEntryResponse {
	id: number;
	organization: TrainingCollectionOrg;
	isShared: boolean;
}

interface ShareCollectionModalProps {
	collectionId: number;
	onClose: () => void;
}

export default function ShareCollectionModal({
	collectionId,
	onClose,
}: ShareCollectionModalProps) {
	const t = useT();
	const organization = useCurrentOrganization();
	const [showOrganizationSelect, setShowOrganizationSelect] = useState(false);

	const { records, selection, selectedRecords, isLoading, refresh } =
		useCollection<TrainingCollectionShareEntryResponse>(
			endpoints.TrainingCollections.ShareIndex(organization.id, collectionId)
		);

	useEffect(() => {
		if (!isLoading) {
			selection.selectMultiple(
				records.filter((r) => r.isShared).map((r) => r.id)
			);
		}
	}, [isLoading]);

	const handleAddClick = () => setShowOrganizationSelect(true);

	const handleCloseOrganizationSelect = () => setShowOrganizationSelect(false);

	const canGoNext = false;

	const sharedRecords = records.filter((r) => r.isShared);

	const handleRemove = async (targetOrganizationId: number) => {
		const ok = await actions.trainingCollections.shareSession(
			organization.id,
			collectionId,
			{
				targetOrganizationIds: sharedRecords
					.filter((r) => r.organization.id !== targetOrganizationId)
					.map((r) => r.id),
			}
		);

		if (ok) {
			refresh();
		}
	};

	let content = (
		<Column spacing={spacing._5}>
			<Button
				label={t('Add more organizations')}
				icon="add"
				variant="secondary"
				onClick={handleAddClick}
			/>
			<span className={css.sharedWith}>
				<MaterialSymbol variant="ios_share" scale={1.2} actualSize />{' '}
				{t('Shared with')}
			</span>
			<Column spacing={spacing._3}>
				{sharedRecords.map((r) => (
					<Row
						key={r.id}
						autoColumns="max-content"
						align="center"
						justifyContent="space-between">
						<span>{r.organization.name}</span>
						<span
							onClick={() => handleRemove(r.id)}
							style={{ cursor: 'pointer' }}>
							<MaterialSymbol
								variant="delete"
								fill={palette.red[500]}
								scale={1.3}
								actualSize
							/>
						</span>
					</Row>
				))}
			</Column>
		</Column>
	);

	if (sharedRecords.length === 0) {
		content = (
			<BlockPlaceholder
				noBorder
				title={t('No organizations')}
				icon="nav-organisation"
				description={t(
					'This collection is not shared with any other organization.'
				)}
				actions={
					<Button
						label={t('Add organizations')}
						icon="add"
						primary
						onClick={handleAddClick}
					/>
				}
			/>
		);
	}

	if (isLoading) {
		content = <Spinner />;
	}

	return (
		<Modal onClose={onClose}>
			<Card.Base>
				<Card.Header>
					<Card.Heading>{t('Share with other organizations')}</Card.Heading>
				</Card.Header>
				<Card.Divider />
				<Card.Body>{content}</Card.Body>
				<Card.Divider />
				<Card.Footer>
					<Button label={t('Cancel')} onClick={onClose} />
					<Button
						label={t('Done')}
						primary
						disabled={canGoNext}
						onClick={onClose}
					/>
				</Card.Footer>
			</Card.Base>
			{showOrganizationSelect && (
				<SelectOrganizationsModal
					records={records}
					selection={selection}
					onClose={handleCloseOrganizationSelect}
					selectedRecords={selectedRecords}
					organizationId={organization.id}
					collectionId={collectionId}
					refreshRecords={refresh}
				/>
			)}
		</Modal>
	);
}
