import { ReactNode } from 'react';

export const symbol_ink_highlighter: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M554-407 439-522 217-300l115 115 222-222Zm-72-158 115 115 213-213-115-115-213 213Zm-64-21 200 200-234 234q-19 19-52.5 19T279-152l-11-11-25 25q-8 8-19.5 13t-23.5 5h-94q-10 0-13.5-9.5T96-146l94-94-4-4q-22-22-21.5-55.5T187-355l231-231Zm0 0 237-237q17-17 43-17t43 17l112 112q17 17 16.5 45.5T852-620L618-386 418-586Z"
		/>
	</svg>
);
