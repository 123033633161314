import { ReactNode } from 'react';

export const symbol_family_star: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-320q48 0 85.5-28.5T620-422H340q17 45 54.5 73.5T480-320ZM380-480q25 0 42.5-17.5T440-540q0-25-17.5-42.5T380-600q-25 0-42.5 17.5T320-540q0 25 17.5 42.5T380-480Zm200 0q25 0 42.5-17.5T640-540q0-25-17.5-42.5T580-600q-25 0-42.5 17.5T520-540q0 25 17.5 42.5T580-480ZM306.837-700.822 424-853q11-14 25.587-21t30.5-7q15.913 0 30.413 7t25.5 21l117.163 152.178L831-641q23 8 36 27.111t13 42.222q0 10.667-3.033 21.296Q873.933-539.743 867-530L753-367l4 173q0 31-21 52.5T685.824-120Q684-120 667-123l-187-52-186.783 51.923Q288-121 282.49-120.5q-5.51.5-10.102.5Q243-120 222.5-141.687 202-163.373 203-195l4-173.25L93-531q-6.933-9.83-9.967-20.553Q80-562.277 80-573q0-23 12.918-41.606T129-641l177.837-59.822ZM343-649l-209 70 134 194-5 207 217-60 217 61-5-208 134-192-209-72-137-178-137 178Zm137 147Z"
		/>
	</svg>
);
