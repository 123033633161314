import { ReactNode } from 'react';

export const symbol_front_loader: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M150-170q-46.218 0-78.11-32.083Q40-234.167 40-280v-270q0-24.75 17.625-42.375T100-610h230v-170q0-12.75 8.625-21.375T360-810h177q45 0 77 32.083 32 32.084 32 77.917v180h61v-60q0-11.789 4.604-22.842Q716.208-613.895 724-622l41-42q11-11 26-9t22 16l124 233q8 15-.75 29.5T910-380H767q-24.75 0-42.375-17.625T707-440v-20h-61v95q20 16.056 30.5 38.25T687-280q0 45.833-32.083 77.917Q622.833-170 577-170q-37 0-65.5-22T472-249H255q-11 35-39.5 57T150-170Zm-.19-60q20.811 0 35.5-14.325 14.69-14.324 14.69-35.5Q200-301 185.675-315.5q-14.324-14.5-35.5-14.5Q129-330 114.5-315.675q-14.5 14.324-14.5 35.5Q100-259 114.5-244.5 129-230 149.81-230Zm427.015 0Q598-230 612.5-244.325q14.5-14.324 14.5-35.5Q627-301 612.675-315.5q-14.324-14.5-35.5-14.5Q556-330 541.5-315.675q-14.5 14.324-14.5 35.5Q527-259 541.325-244.5q14.324 14.5 35.5 14.5ZM330-310v-240H100v173q11-6 24-9.5t26-3.5q37 0 66 22.5t39 57.5h75Zm60 0h81q10-35 39.5-57.5T577-390h10v-70H390v150Zm471-130-84-154q-5 3-7.5 8t-2.5 11v135h94Zm-471-80h197v-180q0-21.25-14.375-35.625T537-750H390v230Zm-60 210v-80 13-173 240Zm60 0v-150 150Z"
		/>
	</svg>
);
