import { ReactNode } from 'react';

export const symbol_settings_photo_camera: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M576-80q-25 0-42.5-17.5T516-140v-200q0-25 17.5-42.5T576-400h60l31-31q5-5 10.217-7 5.218-2 10.783-2h56q5.565 0 10.783 2Q760-436 765-431l31 31h60q25 0 42.5 17.5T916-340v200q0 25-17.5 42.5T856-80H576Zm140-80q33 0 56.5-23.5T796-240q0-33-23.5-56.5T716-320q-33 0-56.5 23.5T636-240q0 33 23.5 56.5T716-160ZM586-493q-11 6-23 3t-18-14q-11-19-27.715-32.5-16.716-13.5-37.466-13.5Q451-550 430.5-530.119 410-510.237 410-481q0 22.596 14 40.798T460-414q11 5 16.5 16.5t.5 22.5q-5 11-16 16t-23 1q-39-14-63.5-47.691T350-481q0-54 37.5-91.5t91.038-37.5q38.77 0 70.616 20.5Q581-569 598-534q5 11 2 23t-14 18ZM418-80q-14 0-23.5-9T383-112l-15-94q-19-7-40-19t-37-25l-86 40q-14 6-28 1.5T155-226L97-330q-8-13-4.546-26.942Q95.91-370.884 108-380l80-59q-2-9-2.5-20.5T185-480q0-9 .5-20.5T188-521l-80-59q-12.09-9.116-15.546-23.058Q89-617 97-630l58-104q8-13 22-17.5t28 1.5l86 40q16-13 37-25t40-18l15-95q1.674-13.935 12.837-22.968Q407-880 421-880h118q14 0 25.163 9.032Q575.326-861.935 577-848l15 94q19 7 40.5 18.5T669-710l86-40q14-6 27.5-1.5T804-734l59 104q8 13 4.5 27.5T852-580l-87 63q-10 7-21 5.5T725-523q-8-10-6.5-22t11.5-20l70-51-40-72-106 46q-23-26-52-43.5T538-708l-14.413-112H436l-14 112q-34 7-63.5 24T306-642l-106-46-40 72 94 69q-4 17-6.5 33.5T245-480q0 17.391 2.5 33.696Q250-430 254-413l-94 69 40 72 106-46q26 26 59.5 43.5T436-249v149q0 8.235-4.5 14.118Q427-80 418-80Zm62-401Zm0 0Z"
		/>
	</svg>
);
