import { useState } from 'react';
import { t } from '@transifex/native';

import DateTime from 'pkg/datetime';

import Icon from 'components/icon';

import Calendar from 'components/form/calendar';
import * as Input from 'components/form/inputs';
import Column from 'components/layout/column';

import * as css from './styles.css';

interface InputDatePickerProps {
	dates: Date[];
	name?: string;
	multipleDates?: boolean;
	disableDatesBefore?: Date;
	highlightedDates?: Date[][];
	handleChange: (dates: Date[], name: string) => void;
}
const DatePicker: React.FC<React.PropsWithChildren<InputDatePickerProps>> = ({
	dates = [new Date(), new Date()],
	name,
	disableDatesBefore,
	multipleDates,
	handleChange,
	highlightedDates = [],
}) => {
	const [datePicker, setDatePicker] = useState(false);

	const showDatePicker = () => setDatePicker(true);
	const beforeDate = new DateTime(dates[0]);
	const afterDate = new DateTime(dates[1]);

	const beforeLocaleString = beforeDate.toLocaleDateString({
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
	});
	const afterLocaleString = afterDate.toLocaleDateString({
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
	});

	const handleDateChange = (dates: Date[]) => {
		handleChange(dates, name);
	};

	return (
		<Column>
			<div className={css.wrapper} onClick={showDatePicker}>
				<Input.Field
					className={css.date}
					value={
						multipleDates
							? t('{beforeDate} and {afterDate}', {
									beforeDate: beforeLocaleString,
									afterDate: afterLocaleString,
								})
							: beforeLocaleString
					}>
					<Input.Prefix>
						<Icon name="nav-events" />
					</Input.Prefix>
				</Input.Field>
			</div>
			{datePicker && (
				<Calendar
					selectedDates={dates}
					onDateChange={handleDateChange}
					range={multipleDates}
					disableDatesBefore={disableDatesBefore}
					highlightedDates={highlightedDates}
				/>
			)}
		</Column>
	);
};

export default DatePicker;
