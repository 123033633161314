import { ReactNode } from 'react';

export const symbol_search_hands_free: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-155.5t86-127Q252-817 325-848.5T480-880q83 0 155.5 31.5t127 86q54.5 54.5 86 127T880-480q0 82-31.5 155t-86 127.5q-54.5 54.5-127 86T480-80Zm-30-62v-154q-58-10-99.5-53T296-450H142q10 124 97 210.5T450-142Zm60 0q125-10 211.5-97T818-450H664q-14 58-54.5 101T510-296v154ZM142-510h152l94-99q8-9 20-14t24-5h96q12 0 24 5t20 14l94 99h152q-11-132-108.5-221T480-820q-132 0-229.5 89T142-510Z"
		/>
	</svg>
);
