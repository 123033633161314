import { Component } from 'react';
import { connect } from 'react-redux';

import { makeGetSingleActivity } from 'pkg/selectors/activities.selector';

import * as activityActions from 'pkg/actions/activities';

import * as sdk from 'pkg/core/sdk';
import { ApiError } from 'pkg/errors/errors';

import { ErrorPage } from 'components/Error';

import { Spinner } from 'components/loaders/spinner';
import SingleActivity from 'components/user/activity/Single';

class SingleUserActivity extends Component {
	state = {
		error: null,
		loading: false,
	};

	async componentDidMount() {
		try {
			const response = await sdk.get('user-activities/:activityId', {
				activityId: this.props.id,
			});

			if (!response.ok) {
				throw new ApiError(response.statusText);
			}

			const activity = await response.json();
			this.props.setActivity(activity);
			this.setState({ loading: false });
		} catch (e) {
			const error = new ApiError(e.message);
			this.setState({ error, loading: false });
		}
	}

	render() {
		if (this.state.loading || !this.props.activity) {
			return <Spinner />;
		}

		if (this.state.error) {
			return <ErrorPage error={this.state.error} />;
		}

		return (
			<SingleActivity
				id={this.props.id}
				activity={this.props.activity}
				comments={this.props.activity.comments}
			/>
		);
	}
}

const mapStateToProps = () => {
	const getActivity = makeGetSingleActivity();

	return (state, props) => {
		const activity = getActivity(state, { id: props.activityId });

		return {
			id: props.activityId,
			activity,
			forceNavBarBackLink: state.app.forceNavBarBackLink,
		};
	};
};

const mapDispatchToProps = {
	setActivity: activityActions.setActivity,
	removeActivity: activityActions.removeActivity,
};

const ConnectedActivity = connect(
	mapStateToProps,
	mapDispatchToProps
)(SingleUserActivity);

export default ConnectedActivity;
