import { ReactNode } from 'react';

export const symbol_pan_tool_alt: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M398-121q-24.513 0-46.757-10.5Q329-142 313-162L75-453q-8-10-4.5-22.5T83-499q19-20 47-21.5t51 15.5l99 68v-413q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T340-850v471q0 17.872-16 26.436T293-355l-136-94 204 250q7.161 8.4 16.71 13.2 9.548 4.8 20.29 4.8h292q38 0 64-26t26-64v-280q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T840-551v280q0 63-43.5 106.5T690-121H398Zm71-215Zm8.175-404q12.825 0 21.325 8.625T507-710v239q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T447-471v-239q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625Zm167 60q12.825 0 21.325 8.625T674-650v179q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T614-471v-179q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625Z"
		/>
	</svg>
);
