import { Dateable } from 'pkg/api/models/dateable';
import { Linkable } from 'pkg/api/models/linkable';
import { ProductPrice } from 'pkg/api/models/product_price';
import { ProductCategory } from 'pkg/api/models/product_category';
import { Record } from 'pkg/api/models/record';

export interface ProductMetadata extends Record {
	key: string;
	value: string;
	productId: number;

	product?: Product;
}

export interface Product extends Record, Dateable, Linkable {
	accountId: number;
	archivedAt: number;
	description: string;
	groupId: number;
	isDefault: boolean;
	name: string;
	paymentProviderId: string;
	productCategoryId: number;
	metadata: ProductMetadata[];
	prices: ProductPrice[];
	category: ProductCategory;
}

export interface ProductCreatePayload {
	name: string;
	groupId: number;
	isDefault: boolean;

	metadata?: { key: string; value: string }[];
	description?: string;
	productCategoryId?: number;
}

export interface ProductUpdatePayload {
	name: string;
	groupId: number;
	isDefault: boolean;

	metadata?: { key: string; value: string }[];
	description?: string;
	productCategoryId?: number;
}

export function getActiveProductPrices(product: Product) {
	return product.prices?.filter((p) => !p.archivedAt);
}

export function getActiveRecurringPrices(product: Product) {
	return getActiveProductPrices(product).filter((p) => p?.recurring);
}

export function getActiveOneTimePrices(product: Product) {
	return getActiveProductPrices(product).filter((p) => !p?.recurring);
}
