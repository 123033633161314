import { ReactNode } from 'react';

export const symbol_swipe_up: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M190-839q-8 32-14 65t-6 67q0 63 18.5 123T242-472q5 8 5.5 16.5T241-440q-6 6-15 4.5t-14-9.5q-40-58-61-124.5T130-707q0-32 4-64t12-64l-60 59q-6 6-14 6t-14-6q-6-6-6-14t6-14l91-91q9-9 21-9t21 9l91 91q6 6 6 14t-6 14q-6 6-14 6t-14-6l-64-63Zm456 713q-20 8-42 7t-42-11L318-243q-10-5-14.5-14.5T302-278v-2q5-19 19-31t34-14l99-8-117-320q-5-12 .5-22.5T355-691q12-5 23 .5t16 17.5l131 357q5 14-3 26.5T499-275l-89 8 178 83q9 4 19 4t19-3l159-58q39-14 56.5-51.5T845-369l-62-169q-5-12 .5-23t17.5-16q12-5 23 .5t16 17.5l61 169q23 63-4.5 122.5T806-185l-160 59ZM523-603q12-5 22.5.5T561-585l48 132q5 11-.5 22T591-415q-11 5-22-1t-16-17l-48-132q-5-12 .5-22.5T523-603Zm132-6q12-5 22.5.5T693-591l35 94q5 12-1 23t-18 16q-12 4-22.5-1T671-476l-34-94q-5-12 .5-23t17.5-16Zm22 265Z"
		/>
	</svg>
);
