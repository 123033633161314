import { ReactNode } from 'react';

export const symbol_alt_route: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M450-110v-170q0-48-16-79t-49-64l43-43q13 11 27.5 30t24.5 35q17-26 33.5-45t31.5-32q58-47 83.5-113.5T648-766l-69 69q-9 9-21 9t-21-9q-9-9-9-21t9-21l120-120q5-5 10-7t11-2q6 0 11 2t10 7l120 120q9 9 9 21t-9 21q-9 9-21 9t-21-9l-69-69q5 126-24.5 198.5T585-432q-44 40-59.5 73T510-280v170q0 13-8.5 21.5T480-80q-13 0-21.5-8.5T450-110ZM258-636q-4-18-6.5-52.5T251-765l-68 68q-9 9-21 9t-21-9q-9-9-9-21t9-21l120-120q5-5 10-7t11-2q6 0 11 2t10 7l120 120q9 9 9 21t-9 21q-9 9-21 9t-21-9l-69-69q-2 38-1 66.5t5 49.5l-58 14Zm84 171q-17-18-37.5-47.5T273-577l59-15q9 25 24 48t28 37l-42 42Z"
		/>
	</svg>
);
