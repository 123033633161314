import { Record } from 'immutable';
import { schema } from 'normalizr';

import { formCategories } from 'pkg/models/schemas';

export interface IFormCategory {
	id: number;
	groupId: number;
	title: string;
	description: string;
	createdAt: number;
	updatedAt: number;
	links?: { [key: string]: string };
}

const formCategoryProps: IFormCategory = {
	id: 0,
	groupId: 0,
	title: '',
	description: '',
	createdAt: 0,
	updatedAt: 0,
	links: {},
};

class FormCategory
	extends Record(formCategoryProps, 'FormCategoryRecord')
	implements IFormCategory
{
	static normalizr(): schema.Entity {
		return formCategories;
	}
}

export default FormCategory;
