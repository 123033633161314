import { ReactNode } from 'react';

export const symbol_tap_and_play: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M260-830h440v-30H260v30Zm0 0v-30 30ZM229.825-40Q217-40 208.5-48.675q-8.5-8.676-8.5-21.5Q200-83 208.675-91.5q8.676-8.5 21.5-8.5Q243-100 251.5-91.325q8.5 8.676 8.5 21.5Q260-57 251.325-48.5q-8.676 8.5-21.5 8.5ZM260-770v205q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T200-565v-295q0-24 18-42t42-18h440q24 0 42 18t18 42v790q0 12.75-8.675 21.375Q742.649-40 729.825-40 717-40 708.5-48.625T700-70v-700H260Zm62 606q-20-20-43.5-33T229-218q-13-4-21-14t-8-22.5q0-12.5 9-20t20-5.5q38 8 72.5 26.5T364-207q29 29 48 64t27 74q2 11-6.05 20T413-40q-13 0-22.5-8T377-69q-8-27-21.5-51T322-164Zm115-113q-42-42-95-67t-112-30q-13-2-21.5-10t-8.5-20.825q0-12.824 8.5-21.5Q217-435 230-434q71 5 134.5 34.5T479-319q51 51 80.5 114.5T594-70q1 13-7.446 21.5-8.447 8.5-21.5 8.5Q552-40 544-48.5 536-57 534-70q-5-59-30.19-111.857Q478.619-234.714 437-277Z"
		/>
	</svg>
);
