import { ReactNode } from 'react';

export const symbol_corporate_fare: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-120q-24.75 0-42.375-17.625T80-180v-600q0-24.75 17.625-42.375T140-840h270q24.75 0 42.375 17.625T470-780v105h350q24.75 0 42.375 17.625T880-615v435q0 24.75-17.625 42.375T820-120H140Zm0-60h270v-105H140v105Zm0-165h270v-105H140v105Zm0-165h270v-105H140v105Zm0-165h270v-105H140v105Zm330 495h350v-435H470v435Zm110-270q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T580-510h105q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T685-450H580Zm0 165q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T580-345h105q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T685-285H580Z"
		/>
	</svg>
);
