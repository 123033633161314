import { ReactNode } from 'react';

export const symbol_outbox_alt: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M642-455q17-8.295 17-27.148Q659-501 642-509L288-682q-15-8-29 .75T245-655v346q0 17.5 14 26.25t29 .75l354-173ZM305-351v-74l132-57-132-57v-74l283 131-283 131ZM180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-60h600v-600H180v600Zm0-600v600-600Z"
		/>
	</svg>
);
