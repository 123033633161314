import { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { t } from '@transifex/native';

import * as breakpoints from 'pkg/config/breakpoints';
import * as palette from 'pkg/config/palette';
import * as shadow from 'pkg/config/box-shadow';

import { removeActivity } from 'pkg/actions/activities';
import { addCommentsBatch } from 'pkg/actions/comments';

import rgba from 'pkg/rgba';
import Link from 'pkg/router/Link';
import * as selectors from 'pkg/selectors';
import * as routes from 'pkg/router/routes';
import { physicalStrainLabel } from 'pkg/api/models/user_activity';
import { IdentityContext } from 'pkg/identity';

import EditList from 'components/EditList';
import CardWrapper, * as Card from 'components/Card';
import * as Item from 'components/FeedItem';
import RelativeDateTime from 'components/RelativeDateTime';
import {
	FormattedContent,
	FormattedContentMaxLength,
} from 'components/formatted-content';
import Avatar from 'components/avatar';

import ActivityForm from 'components/user/activity/Form';
import CommentActions from 'components/comment/Actions';
import CommentsList from 'components/comment/List';
import CommentForm from 'components/comment/Form';
import PhysicalStrainIndicator from 'components/legacy/PhysicalStrainIndicator';

const AuthorWrapper = styled.div`
	margin-bottom: 1rem;
	display: grid;
	grid-template-areas:
		'avatar author'
		'avatar timestamp';
	grid-template-columns: 45px 1fr;
	grid-column-gap: 10px;
	align-items: center;
`;

const AuthorLink = styled(Link)`
	margin-bottom: 0;
	grid-area: author;
	text-decoration: none;
	font-size: var(--font-size-base);
	display: inline-block;
	color: ${rgba(palette.main.actionableLinkColor)};

	@media (hover: hover) {
		&:hover {
			color: ${rgba(palette.main.actionableLinkColor, 0.7)};
		}
	}
`;

const AuthorTimestamp = styled(Link)`
	grid-area: timestamp;
	text-decoration: none;
	font-weight: var(--font-weight-normal);

	time {
		color: ${rgba(palette.card.timestampColor)};
	}
`;

const PlayerSvg = styled.svg`
	background: rgba(149, 159, 169, 0.2);
	border-radius: 50%;
	height: 42px;
	width: 42px;
`;

const PhysicalStrainInnerCard = styled.div`
	padding: 16px;
	background: #fff;
	border-radius: var(--radius-3);
	grid-template-areas: 'icon desc' 'indicator indicator';
	grid-template-columns: 42px 1fr;
	grid-column-gap: 12px;
	display: grid;
	box-shadow: ${shadow.flat};

	#IconPlayer {
		fill: #959fa9;
	}
`;

const ItemBody = styled(Card.Body)`
	padding: 16px;
`;

PhysicalStrainInnerCard.defaultProps = {
	theme: { mode: 'default' },
	strainLevel: 'very_low',
};

const PhysicalStrainIndicatorItem = styled(PhysicalStrainIndicator)`
	grid-area: indicator;
	width: 100%;
	margin-top: 16px;
`;

const PhysicalStrainIcon = styled.div`
	grid-area: icon;
	display: flex;
	align-items: center;
	justify-content: center;

	@media ${breakpoints.small} {
		svg {
			max-width: 42px;
			max-height: 42px;
		}
	}
`;

const PhysicalStrainDesc = styled.div`
	grid-area: desc;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	@media all and (max-width: 370px) {
		align-items: flex-start;
	}
`;

const ActivityTitle = styled.h2`
	font-size: var(--font-size-sm);
	font-weight: var(--font-weight-semibold);
	display: block;
`;

const ActivityTitleLegend = styled.span`
	font-size: var(--font-size-xs);
	width: 100%;
	color: ${rgba(palette.card.heading.foregroundColor)};
	display: inline-block;
	font-weight: var(--font-weight-normal);
`;

const ActivityStrain = styled.h3`
	font-size: var(--font-size-sm);
	font-weight: var(--font-weight-semibold);
	display: block;
	text-align: right;
`;

const ActivityDescription = styled.div`
	margin-top: 16px;
`;

class Data extends Component {
	get description() {
		if (this.props.activity.description.length > 0) {
			return (
				<ActivityDescription key="activity-description">
					<FormattedContent
						raw={this.props.activity.description}
						maxLength={FormattedContentMaxLength.Standard}
					/>
				</ActivityDescription>
			);
		}

		return null;
	}

	get icon() {
		return (
			<PlayerSvg viewBox="0 0 57 57" fill="none">
				<path
					id="IconPlayer"
					d="M34.6093143,39.9355102 C33.3809857,39.9355102 32.3852286,38.9313854 32.3852286,37.6927347 C32.3852286,36.454084 33.3809857,35.4499592 34.6093143,35.4499592 C35.8376429,35.4499592 36.8334,36.454084 36.8334,37.6927347 C36.8334,38.9313854 35.8376429,39.9355102 34.6093143,39.9355102 Z M30.9800571,19.3317551 C29.7938442,19.3317551 28.8322286,18.3620587 28.8322286,17.1658776 C28.8322286,15.9696964 29.7938442,15 30.9800571,15 C32.1662701,15 33.1278857,15.9696964 33.1278857,17.1658776 C33.1278857,18.3620587 32.1662701,19.3317551 30.9800571,19.3317551 Z M32.9676,28.2764082 C33.7719429,26.1732245 33.3838571,24.5995102 32.0209429,23.4489796 C30.6497714,26.5777959 24.2140571,38.7061224 24.2140571,38.7061224 C23.9386571,39.331102 23.1989143,39.6068571 22.5611714,39.3208163 C21.9239143,39.0352653 21.6295714,38.2966531 21.9049714,37.6716735 L26.1637143,29.9466122 C26.1637143,29.9466122 26.3468286,29.5601633 25.9388286,29.3833469 C25.5308286,29.2065306 25.3166286,29.5915102 25.3166286,29.5915102 L22.4829714,34.444898 C22.2075714,35.0698776 21.4673429,35.3451429 20.8300857,35.0595918 C20.1923429,34.7740408 19.9621143,34.0628571 20.2370286,33.4383673 L22.5529143,28.7632653 L26.001,21.9012245 C24.5530857,21.9810612 23.1061429,22.0858776 21.6558,22.0976327 C20.1855429,22.1098776 20.1865143,19.8102857 21.6558,19.7985306 C23.9391429,19.7794286 26.2132571,19.5301224 28.4961143,19.5110204 C28.5476,19.5105306 28.5927714,19.5193469 28.6413429,19.5242449 C29.3237714,19.4537143 30.1698857,19.6177959 31.2010571,20.2329796 C31.3462857,20.3191837 31.4754857,20.4078367 31.5901143,20.498449 C35.0362571,22.0687347 36.5628571,25.2357551 35.1664286,28.8876735 C34.6438,30.2556735 32.4391429,29.6625306 32.9676,28.2764082 Z"
					fillRule="nonzero"
				/>
				<path
					id="IconBorder"
					d="M28.5,3.21126761 C14.5558732,3.21126761 3.21126761,14.5558732 3.21126761,28.5 C3.21126761,42.4441268 14.5558732,53.7887324 28.5,53.7887324 C42.4441268,53.7887324 53.7887324,42.4441268 53.7887324,28.5 C53.7887324,14.5558732 42.4441268,3.21126761 28.5,3.21126761 M28.5,57 C12.7848592,57 0,44.2151408 0,28.5 C0,12.7848592 12.7848592,0 28.5,0 C44.2151408,0 57,12.7848592 57,28.5 C57,44.2151408 44.2151408,57 28.5,57"
				/>
			</PlayerSvg>
		);
	}

	get activityTitle() {
		const { activity } = this.props;

		if (activity.ratingItem) {
			return activity.ratingItem.name;
		}

		if (activity.type === 'group') {
			return t(`Team training`);
		}

		if (activity.type === 'match') {
			return t(`Match`);
		}

		if (activity.type === 'other') {
			return t(`Other activity`);
		}

		return t(`Exercise`);
	}

	render() {
		return [
			<PhysicalStrainInnerCard key="physical-strain-inner-card">
				<PhysicalStrainIcon>{this.icon}</PhysicalStrainIcon>

				<PhysicalStrainDesc>
					<ActivityTitle>
						<ActivityTitleLegend>{t(`Training`)}</ActivityTitleLegend>
						{this.activityTitle}
					</ActivityTitle>

					<ActivityStrain>
						<ActivityTitleLegend>{t('Physical strain')}</ActivityTitleLegend>
						{physicalStrainLabel(this.props.activity.physicalStrain)}
					</ActivityStrain>
				</PhysicalStrainDesc>

				<PhysicalStrainIndicatorItem
					key="strain-indicator"
					className="activity-strain-indicator"
					strainLevel={this.props.activity.physicalStrain}
				/>
			</PhysicalStrainInnerCard>,

			this.description,
		];
	}
}

class SingleActivity extends Component {
	static contextType = IdentityContext;

	static defaultProps = {
		activity: {},
		user: {},
		likes: [],
	};

	state = {
		activeEdit: false,
		discussionIsVisible: false,
		likeRequestInProgress: false,
	};

	get userFullName() {
		const { activity } = this.props;
		return `${activity?.user?.firstName} ${activity?.user?.lastName}`;
	}

	get activityFormProps() {
		return {
			onClose: () => this.setState({ activityFormActive: false }),
			asModal: true,
			modalVisible: this.state.activityFormActive,
			userActivityId: this.props.activity.id,
			description: this.props.activity.description,
			physicalStrain: this.props.activity.physicalStrain,
			ratingItem: this.props.activity.ratingItem || {},
			completedAt: this.props.activity.completedAt,
			handleSuccess: this.handleActionRowSuccess,
		};
	}

	deleteItem() {
		this.props.removeActivity(this.props.activity.id);
	}

	onCommentTrigger = () => {
		if (!this.state.discussionIsVisible) {
			this.setState({ discussionIsVisible: true });
		}
	};

	handleSavedComment(payload) {
		if (this.props.handleSavedComment) {
			this.props.handleSavedComment(payload);
		}
	}

	handleActionRowSuccess = () => {
		this.setState({ activityFormActive: false });
	};

	openEditList = (e) => {
		e.stopPropagation();
		if (this.state.activeEdit) {
			return;
		}

		this.setState(
			{ activeEdit: true },
			document.addEventListener('click', this.closeEditList, { once: true })
		);
	};

	closeEditList = () => {
		this.setState({ activeEdit: false });
	};

	toggleModal = () => {
		this.setState({ activityFormActive: !this.state.activityFormActive });
	};

	get renderActivityFormModal() {
		if (this.state.activityFormActive) {
			const { activity } = this.props;
			return (
				<ActivityForm
					key="activity-form-modal"
					{...this.activityFormProps}
					{...activity}
				/>
			);
		}

		return null;
	}

	get discussion() {
		if (
			this.props.activity?.commentCount > 0 ||
			this.state.discussionIsVisible
		) {
			return (
				<Card.Body>
					<CommentsList
						key="posts-comment-list"
						stateKey="activities"
						id={this.props.id}
						addCommentsBatch={this.props.addCommentsBatch}
						comments={this.props.comments}
						links={this.props.comments?.links}
					/>
					<CommentForm
						targetId={`comment-form-${this.props.id}`}
						key="comment-form"
						stateKey="activities"
						id={this.props.activity.id}
					/>
				</Card.Body>
			);
		}

		return null;
	}

	render() {
		const { activity, forAccount } = this.props;

		if (!activity.id) {
			return null;
		}

		return [
			<CardWrapper $noBorder key={`user-activity-${activity.id}`}>
				{!forAccount && (
					<EditList
						main
						openEditList={this.openEditList}
						togglePostModal={this.toggleModal}
						active={this.state.activeEdit}
						handleDeleteItem={this.props.removeActivity}
						item={this.props.activity}
					/>
				)}
				<ItemBody>
					<Item.Parent>
						{this.postEditModal}

						<AuthorWrapper>
							<Item.AuthorLink
								href={routes.User.Profile.Show(
									this.props.activity?.user?.id,
									this.context.membership.groupId,
									activity.userId,
									'overview'
								)}>
								<Avatar user={activity.user} />
							</Item.AuthorLink>

							<Item.Title>
								<AuthorLink
									href={routes.User.Profile.Show(
										this.props.activity?.user?.id,
										this.context.membership.groupId,
										activity.userId,
										'overview'
									)}>
									{this.userFullName}
								</AuthorLink>
								<Item.DateTime dateTime={activity.completedAt}>
									{activity.completedAt && (
										<AuthorTimestamp href={`/user-activity/${activity.id}`}>
											<RelativeDateTime dateTime={activity.completedAt} />
										</AuthorTimestamp>
									)}
								</Item.DateTime>
							</Item.Title>
						</AuthorWrapper>

						<Data activity={activity} />
					</Item.Parent>
				</ItemBody>

				<Card.Divider />

				<div>
					<CommentActions
						id={activity.id}
						itemBase="/user-activity"
						dateTime={activity.completedAt}
						commentTriggerTarget={`#comment-form-${activity.id}`}
						onCommentTrigger={this.onCommentTrigger}
						groupId={this.context.membership.groupId}
						stateKey="activities"
					/>
				</div>

				{this.discussion}
			</CardWrapper>,
			this.renderActivityFormModal,
		];
	}
}

export default connect(
	(state, props) => ({
		comments: selectors.activities.findAllComments(state, props),
	}),
	{ removeActivity, addCommentsBatch }
)(SingleActivity);
