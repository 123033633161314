import { ReactNode } from 'react';

export const symbol_voice_over_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M501-535 304-732q9-3 24-6t32-3q66 0 108 42t42 108q0 13-2.5 28.5T501-535ZM40-180v-35q0-38 21-65.5t47-39.5q69-32 129.5-46T360-380q34 0 73.5 5.5T506-359l-91-91q-11 4-24.5 6.5T360-441q-66 0-108-42t-42-108q0-14 2-29t6-26L61-803q-9-9-8.5-21.5T62-846q9-9 21.5-9t21.5 9l735 736q9 9 9 21t-9 21q-9 9-21.5 9T797-68L684-180h-4q0 25-17.5 42.5T620-120H100q-25 0-42.5-17.5T40-180Zm60 0h520v-33q0-16-9-31t-24-22q-61-27-115.5-40.5T360-320q-57 0-112.5 13T132-266q-15 7-23.5 22.5T100-212v32Zm260 0Zm560-411q0 80-24.5 142T826-333q-10 12-24.5 13T776-330q-11-11-10.5-26t10.5-27q36-44 55-92.5T850-591q0-67-19-115.5T776-799q-10-12-10.5-27t10.5-26q11-11 25.5-10t24.5 13q45 54 69.5 116T920-591Zm-200 0q0 32-9.5 61T684-475q-8 12-22 13t-25-10q-11-11-12-25t7-27q8-14 13-31t5-36q0-19-5-36t-13-31q-8-13-7-27t12-25q11-11 25-10t22 13q17 26 26.5 55t9.5 61Z"
		/>
	</svg>
);
