import { ReactNode } from 'react';

export const symbol_vr180_create2d: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M80-568q0-130 91-221t221-91q109 0 193.5 65.5T703-646q4 13-2 24t-18.903 14Q670-605 659.5-611.5 649-618 645-630q-26-84-95.5-137T392-820q-104 0-178 74t-74 178q0 88 54.5 156T331-313q12 5 17.5 15.5t1.598 22.5q-4.683 12.8-16.391 18.4Q322-251 309-256q-100-35-164.5-119.5T80-568ZM480-80q-24 0-42-18t-18-42v-340q0-24 18-42t42-18h340q24 0 42 18t18 42v340q0 24-18 42t-42 18H480Zm0-60h340v-340H480v340Zm151-64-45-56q-5-6-12-5.5t-12 6.5l-36 51q-5 8-.75 16t12.75 8h224q9 0 13.5-8t-1.5-16l-59-77q-5-6-12-6t-12 6l-60 81Zm19-106ZM396-562Z"
		/>
	</svg>
);
