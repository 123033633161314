import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import { cssClasses } from 'pkg/css/utils';
import * as actions from 'pkg/actions';
import * as models from 'pkg/api/models';

import Label from 'components/label';
import AssetImage from 'components/AssetImage';
import MaterialSymbol from 'components/material-symbols';

import Column from 'components/layout/column';
import Row from 'components/layout/row';

import * as ContextMenu from 'design/context_menu';

import * as css from './styles.css';

interface Props {
	subscription: models.subscription.Subscription;
	activePaymentMethod: models.paymentMethod.PaymentMethod;
	paymentMethods: models.paymentMethod.PaymentMethod[];
	setNewActivePaymentMethod: (
		method: models.paymentMethod.PaymentMethod
	) => void;
}

const SelectPaymentMethod = ({
	subscription,
	activePaymentMethod,
	paymentMethods,
	setNewActivePaymentMethod,
}: Props) => {
	const sortedMethods = paymentMethods.sort((a, b) => {
		if (a.id === activePaymentMethod?.id) {
			return -1;
		}

		if (b.id === activePaymentMethod?.id) {
			return 1;
		}

		return a.paymentMethodData.cardBrand.localeCompare(
			b.paymentMethodData.cardBrand
		);
	});

	const handleSetPaymentMethod = async (paymentMethodId: number) => {
		const isOk = await actions.subscriptions.setPaymentMethod(
			subscription.id,
			paymentMethodId
		);

		if (isOk) {
			const newMethod = paymentMethods.find(
				(method) => method.id === paymentMethodId
			);
			setNewActivePaymentMethod(newMethod);
		}
	};

	return (
		<ContextMenu.Menu
			toggleWith={
				<Column spacing={styles.spacing._2}>
					<span className={cssClasses(css.smallText, css.gray)}>
						{t('Payment method')}
					</span>
					<Row columns="auto 1fr" spacing={styles.spacing._3} align="center">
						<AssetImage
							className={css.cardImg}
							src={models.paymentMethod.getCardImgUrl(activePaymentMethod)}
						/>
						<Row columns="auto 1fr" align="center" spacing={styles.spacing._2}>
							<span className={css.smallText}>
								{models.paymentMethod.getCardBrandWithDigits(
									activePaymentMethod
								)}
							</span>
							<MaterialSymbol variant="chevron_right" rotate={90} />
						</Row>
					</Row>
				</Column>
			}>
			<ContextMenu.Heading>{t('Select payment method')}</ContextMenu.Heading>
			{sortedMethods.map((method) => (
				<ContextMenu.Item
					justifyContent="normal"
					onClick={() => handleSetPaymentMethod(method.id)}>
					<Row columns="auto 1fr auto" align="center">
						<AssetImage
							className={css.cardImg}
							src={models.paymentMethod.getCardImgUrl(method)}
						/>
						<Column spacing={styles.spacing._2}>
							<div className={css.smallText}>
								{models.paymentMethod.getCardBrandWithDigits(method)}
							</div>
							<span className={cssClasses(css.extraSmallText, css.gray)}>
								{models.paymentMethod.getFormattedExpirationDate(method)}
							</span>
						</Column>
						{activePaymentMethod.id === method.id && (
							<Label color="green">{t('Active')}</Label>
						)}
					</Row>
				</ContextMenu.Item>
			))}
		</ContextMenu.Menu>
	);
};

export default SelectPaymentMethod;
