import { ReactNode } from 'react';

export const symbol_sensors_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M792-83 317-557q-8 17-12.5 37t-4.5 40q0 29 8.5 55.5T333-376q8 11 7.5 23.5T331-331q-9 9-21 8.5T290-333q-23-30-36.5-67.5T240-480q0-34 8.5-64.5T273-601l-72-72q-29 42-45 90.5T140-480q0 63 21 117.5t58 99.5q8 10 8.5 22.5T219-219q-9 9-22.5 8.5T174-222q-45-52-69.5-117.5T80-480q0-67 20.5-127T157-717l-75-75q-9-9-9-21.5t9-21.5q9-9 21.5-9t21.5 9l710 710q9 9 9 21t-9 21q-9 9-21.5 9T792-83Zm-8-187q-10-7-13-18.5t3-22.5q22-38 34-80t12-89q0-63-21-117.5T741-697q-8-10-8.5-23t8.5-22q9-9 23-8t22 12q45 53 69.5 118.5T880-480q0 56-14.5 107T824-277q-6 11-17.5 12.5T784-270Zm-127-93q-11-5-15-17t1-23q8-17 12.5-37t4.5-40q0-29-8.5-55.5T627-584q-8-11-7.5-23.5T629-629q9-9 21-8.5t20 10.5q23 30 36.5 67.5T720-480q0 28-6 54t-17 49q-5 11-17 15t-23-1Z"
		/>
	</svg>
);
