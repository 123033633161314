export function AddReaction(ID: number, messageID: number): string {
	return `/v1/chat/${ID}/messages/${messageID}/reactions`;
}

export function Create(): string {
	return `/v1/chat`;
}

export function CreateMessage(ID: number): string {
	return `/v1/chat/${ID}/messages`;
}

export function CreateUser(ID: number): string {
	return `/v1/chat/${ID}/users`;
}

export function Delete(ID: number): string {
	return `/v1/chat/${ID}`;
}

export function DeleteMessage(ID: number, messageID: number): string {
	return `/v1/chat/${ID}/messages/${messageID}`;
}

export function DeleteUser(ID: number, userID: number): string {
	return `/v1/chat/${ID}/users/${userID}`;
}

export function List(): string {
	return `/v1/chat`;
}

export function List1(groupID: number): string {
	return `/v1/organizations/${groupID}/chat`;
}

export function ListMessages(ID: number): string {
	return `/v1/chat/${ID}/messages`;
}

export function ListMonitored(accountID: number): string {
	return `/v1/accounts/${accountID}/monitored-chats`;
}

export function RemoveReaction(
	ID: number,
	messageID: number,
	reactionID: number
): string {
	return `/v1/chat/${ID}/messages/${messageID}/reactions/${reactionID}`;
}

export function SendEvent(ID: number): string {
	return `/v1/chat/${ID}/events`;
}

export function Show(ID: number): string {
	return `/v1/chat/${ID}`;
}

export function UnreadCount(): string {
	return `/v1/me/unread-chats-count`;
}

export function Update(ID: number): string {
	return `/v1/chat/${ID}`;
}

export function UpdateMessage(ID: number, messageID: number): string {
	return `/v1/chat/${ID}/messages/${messageID}`;
}

export function UpdateUser(ID: number, userID: number): string {
	return `/v1/chat/${ID}/users/${userID}`;
}
