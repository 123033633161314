import { ReactNode } from 'react';

export const symbol_crop_3_2: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-240q-24 0-42-18t-18-42v-360q0-24 18-42t42-18h600q24 0 42 18t18 42v360q0 24-18 42t-42 18H180Zm0-60h600v-360H180v360Zm0 0v-360 360Z"
		/>
	</svg>
);
