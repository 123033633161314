import { ReactNode } from 'react';

export const symbol_view_array: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M120-250v-460q0-20.833 14.618-35.417Q149.235-760 170.118-760 191-760 205.5-745.417 220-730.833 220-710v460q0 20.833-14.618 35.417Q190.765-200 169.882-200 149-200 134.5-214.583 120-229.167 120-250Zm210 50q-20.833 0-35.417-14.583Q280-229.167 280-250v-460q0-20.833 14.583-35.417Q309.167-760 330-760h300q20.833 0 35.417 14.583Q680-730.833 680-710v460q0 20.833-14.583 35.417Q650.833-200 630-200H330Zm410-50v-460q0-20.833 14.618-35.417Q769.235-760 790.118-760 811-760 825.5-745.417 840-730.833 840-710v460q0 20.833-14.618 35.417Q810.765-200 789.882-200 769-200 754.5-214.583 740-229.167 740-250Zm-400-10h280v-440H340v440Zm140-220Z"
		/>
	</svg>
);
