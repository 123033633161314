import { ReactNode } from 'react';

export const symbol_line_style: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M149.25-160q-12.75 0-21-9T120-190.5q0-12.5 9-21t21.75-8.5q12.75 0 21 9t8.25 21.5q0 12.5-9 21t-21.75 8.5Zm165 0q-12.75 0-21-9T285-190.5q0-12.5 9-21t21.75-8.5q12.75 0 21 9t8.25 21.5q0 12.5-9 21t-21.75 8.5Zm165 0q-12.75 0-21-9T450-190.5q0-12.5 8.675-21 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-9 21.325t-21.75 8.5Zm165.575 0Q632-160 623.5-168.675q-8.5-8.676-8.5-21.5 0-12.825 9-21.325t21.75-8.5q12.75 0 21 9t8.25 21.5q0 12.5-8.675 21-8.676 8.5-21.5 8.5Zm165 0Q797-160 788.5-168.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5ZM149-280q-12 0-20.5-9t-8.5-21.5q0-12.5 8.625-21T150-340h150q12 0 20.5 9t8.5 21.5q0 12.5-8.625 21T299-280H149Zm255 0q-12 0-20.5-9t-8.5-21.5q0-12.5 8.625-21T405-340h151q12 0 20.5 9t8.5 21.5q0 12.5-8.625 21T555-280H404Zm256 0q-12 0-20.5-9t-8.5-21.5q0-12.5 8.625-21T661-340h150q12 0 20.5 9t8.5 21.5q0 12.5-8.625 21T810-280H660ZM149-400q-12 0-20.5-9t-8.5-21.5q0-12.5 8.625-21T150-460h271q12 0 20.5 9t8.5 21.5q0 12.5-8.625 21T420-400H149Zm390 0q-12 0-20.5-9t-8.5-21.5q0-12.5 8.625-21T540-460h271q12 0 20.5 9t8.5 21.5q0 12.5-8.625 21T810-400H539ZM150-520q-12 0-21-9t-9-21v-220q0-12 9-21t21-9h660q12 0 21 9t9 21v220q0 12-9 21t-21 9H150Z"
		/>
	</svg>
);
