import { Dateable } from 'pkg/api/models/dateable';
import { Record } from 'pkg/api/models/record';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { Linkable } from 'pkg/api/models/linkable';
import * as sdk from 'pkg/core/sdk';

export type slot = [string, string];
type day = { [key: string]: slot };
export type TemplateModals = 'edit' | 'create' | 'duplicate' | 'publish';

export interface ParsedPublishSettings {
	timezone: string;
	createEvents: boolean;
	inviteUsers: boolean;
	autoInviteNewUsers: boolean;
}

export interface SlotConfig {
	// Set to true to use the same sime slots for all days.
	// If `sameSlotsAllDays` is set, use `times` to render items.
	sameSlotsAllDays: boolean;
	// Day specific slot config, only used if `sameSlotsAlldays` is false.
	days?: day[];
	// Global time slot config, used if `sameSlotsAllDays` is true.
	times?: { [key: string]: slot };
}

export interface Schedule extends Record, Dateable, Linkable {
	groupId: number;
	userId: number;
	title: string;
	description: string;
	days: number;
	startingDay: number;
	slots: string;
	publishedAt: number;
	createdAt: number;
	updatedAt: number;
	publishSettings: string;

	schedulePublishedPeriods: PublishedPeriod[];
	scheduleItems: models.scheduleItem.ScheduleItem[];
}

export interface PublishedPeriod extends Record {
	groupId: number;
	scheduleId: number;
	endsAt: number;
	startsAt: number;
	clientId: string;

	schedule?: Schedule;
	group?: models.group.Group;
}

export interface PublishedPeriodInfo {
	createdBookingsInPeriod: number;
	createdEventsInPeriod: number;
	deletedBookingsInPeriod: number;
	deletedEventsInPeriod: number;
	updatedBookingsInPeriod: number;
	updatedEventsInPeriod: number;
	periodChangesPerGroup: { [key: number]: GroupPublishedPeriodChanges };
	publishPeriodId: number;
	clientId: string;
}

interface CompiledPublishedPeriodsInfo {
	newBookings: number;
	newEvents: number;
	changedBookings: number;
	changedEvents: number;
	deletedBookings: number;
	deletedEvents: number;
}

export function getCompiledPublishedPeriodsInfo(
	periods: PublishedPeriodInfo[]
) {
	const compiledPeriodsInfo: CompiledPublishedPeriodsInfo = {
		newBookings: periods
			.map((p) => p.createdBookingsInPeriod)
			.reduce((acc, value) => acc + value, 0),
		newEvents: periods
			.map((p) => p.createdEventsInPeriod)
			.reduce((acc, value) => acc + value, 0),
		changedBookings: periods
			.map((p) => p.updatedBookingsInPeriod)
			.reduce((acc, value) => acc + value, 0),
		changedEvents: periods
			.map((p) => p.updatedEventsInPeriod)
			.reduce((acc, value) => acc + value, 0),
		deletedBookings: periods
			.map((p) => p.deletedBookingsInPeriod)
			.reduce((acc, value) => acc + value, 0),
		deletedEvents: periods
			.map((p) => p.deletedEventsInPeriod)
			.reduce((acc, value) => acc + value, 0),
	};

	return compiledPeriodsInfo;
}

interface GroupPublishedPeriodChanges {
	bookingsToCreate: number;
	bookingsToDelete: number;
	bookingsToUpdate: number;
	eventsToCreate: number;
	eventsToDelete: number;
	eventsToUpdate: number;
}

export interface ScheduleCreatePayload {
	title: string;
	groupId: number;
	description?: string;
	days: number;
	startingDay: number;
	slots?: string;
	publishSettings?: string;
}

export async function create(
	payload: ScheduleCreatePayload
): Promise<[Response, Schedule, models.APIError?]> {
	return models.create(endpoints.Schedule.Create(), payload);
}

export interface ScheduleUpdatePayload {
	title?: string;
	description?: string;
	slots?: string;
	startingDay?: number;
	publishSettings?: string;
}

export async function update(
	schedule: Schedule,
	payload: ScheduleUpdatePayload
): Promise<[Response, Schedule, models.APIError?]> {
	return models.update(schedule, payload);
}

export async function remove(schedule: Schedule): Promise<boolean> {
	return models.destroy(schedule);
}

export interface PublishSchedulePayload {
	publishedPeriods: {
		id: number;
		// We send startsAt and endsAt like this '2023-02-15'
		startDate: string;
		endDate: string;
		clientId?: string;
	}[];
	timezone?: string;
	dryRun: boolean;
	createEvents?: boolean;
	eventFlags?: models.event.EventFlags[];
	inviteUsersToEvent?: boolean;
	inviteAdminAndStaffToEvent?: boolean;
}

interface PublishScheduleResponse {
	collectionOrder: string;
	meta: { totalCount: number };
	recordOrder: string;
	records: PublishedPeriodInfo[];
}

export async function publish(
	scheduleId: number,
	payload: PublishSchedulePayload
): Promise<[Response, PublishScheduleResponse, models.APIError?]> {
	return models.create(endpoints.Schedule.Publish(scheduleId), payload);
}

export async function copy(
	id: number,
	payload: { title: string }
): Promise<[boolean, Schedule]> {
	const req = await sdk.post(
		endpoints.Schedule.DuplicateSchedule(id),
		{},
		payload
	);
	const resp = await req.json();

	if (!req.ok) {
		return [false, null];
	}

	return [true, resp];
}

// Parse raw JSON config for schedule slots.
export function parseSlots(schedule: Schedule): SlotConfig {
	if (!schedule.slots) {
		const days = [];

		for (let i = 0; i < schedule.days; i++) {
			days.push({});
		}

		return { sameSlotsAllDays: true, times: {}, days };
	}

	return JSON.parse(schedule.slots) as SlotConfig;
}

// Check if any of the publishedPeriods overlap
export function overlappingPeriods(periods: PublishedPeriod[]): boolean {
	let overlaps;

	periods.forEach((period, index) => {
		const periodOverlaps = periods
			.filter((_, innerIndex) => index !== innerIndex)
			.find(
				(p) =>
					(period.startsAt >= p.startsAt && period.startsAt <= p.endsAt) ||
					(period.endsAt >= p.startsAt && period.endsAt <= p.endsAt)
			);

		if (periodOverlaps) {
			overlaps = true;
			return;
		}
	});

	return overlaps;
}
