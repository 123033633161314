export interface SettledPromise<T = any> {
	fulfilled: boolean;
	rejected: boolean;
	value?: T;
	reason?: string;
}

/**
 * Polyfill for Promise.allSettled
 *
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/allSettled
 *
 *
 * @param promises
 */
export default function allSettled<T>(
	promises: Array<Promise<any>>
): Promise<SettledPromise<T>[]> {
	const list = promises.map(
		(promise: Promise<SettledPromise<T>>) =>
			new Promise((resolve) => {
				promise.then(
					(value) =>
						resolve({
							fulfilled: true,
							rejected: false,
							value,
						} as SettledPromise),
					(reason) =>
						resolve({
							fulfilled: false,
							rejected: true,
							reason,
						} as SettledPromise)
				);
			})
	);

	return Promise.all(list) as Promise<SettledPromise[]>;
}
