import { ReactNode } from 'react';

export const symbol_hdr_on_select: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-482q69 0 119-50t50-119q0-69-50-119t-119-50q-69 0-119 50t-50 119q0 69 50 119t119 50Zm0 60q-95.417 0-162.208-66.792Q251-555.583 251-651t66.792-162.208Q384.583-880 480-880t162.208 66.792Q709-746.417 709-651t-66.792 162.208Q575.417-422 480-422Zm0-229Zm351 474h-56q-10.4 0-17.2-7.116-6.8-7.117-6.8-18 0-10.884 7.083-17.384Q765.167-226 776-226h55v-56q0-10.4 7.116-17.2 7.117-6.8 18-6.8 10.884 0 17.384 6.5T880-282v56h56q10.4 0 17.2 6.5 6.8 6.5 6.8 17.384 0 10.883-6.5 18Q947-177 936-177h-56v56q0 10.4-6.5 17.2Q867-97 856.116-97q-10.883 0-18-7.083Q831-111.167 831-122v-55Zm-304 73v-203q0-10.4 6.8-17.2 6.8-6.8 17.2-6.8h119q22 0 35.5 13t13.5 35v52q0 22-11.5 35T676-175l25 62q5 12-1.75 22.5T679-80q-8 0-14-4.5T656-96l-29-73h-52v65q0 10.4-6.8 17.2Q561.4-80 551-80q-10.4 0-17.2-6.8-6.8-6.8-6.8-17.2Zm48-113h96v-66h-96v66ZM48-196v92q0 10.4-6.8 17.2Q34.4-80 24-80q-10.4 0-17.2-6.8Q0-93.6 0-104v-203q0-10.4 6.8-17.2Q13.6-331 24-331q10.4 0 17.2 6.8Q48-317.4 48-307v63h93v-63q0-10.4 6.8-17.2 6.8-6.8 17.2-6.8 10.4 0 17.2 6.8 6.8 6.8 6.8 17.2v203q0 10.4-6.8 17.2Q175.4-80 165-80q-10.4 0-17.2-6.8-6.8-6.8-6.8-17.2v-92H48Zm215 92v-203q0-10.4 6.8-17.2 6.8-6.8 17.2-6.8h116q22 0 35 13t13 35v155q0 22-13 35t-35 13H287q-10.4 0-17.2-6.8-6.8-6.8-6.8-17.2Zm48-24h92v-155h-92v155Z"
		/>
	</svg>
);
