import { ReactNode } from 'react';

export const symbol_experiment: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M172-120q-41.777 0-59.388-39Q95-198 124-230l248-280v-270h-52q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T320-840h320q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T640-780h-52v270l248 280q29 32 11.388 71-17.611 39-59.388 39H172Zm70-90h476L558-395H402L242-210Zm-82 30h640L528-488v-292h-96v292L160-180Zm320-300Z"
		/>
	</svg>
);
