import { ReactNode } from 'react';

export const symbol_outdoor_grill: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M651-299q46 0 78 32t32 78q0 46-32 78t-78 32q-36 0-65-21.5T547-157H254l-41 63q-7 11-18.5 14T173-84q-11-8-14.5-19.5T162-126l182-276q-66-25-110.5-76.5T175-599q-3-14 6.5-25t23.5-11h472q14 0 23 11t6 25q-14 68-58.5 119T538-403l58 90q-14 7-27 16t-21 20l-71-110q-6 2-16 2h-39q-10 0-17-2L294-217h253q10-36 38.5-59t65.5-23Zm0 160q21 0 35.5-14.5T701-189q0-21-14.5-35.5T651-239q-21 0-35.5 14.5T601-189q0 21 14.5 35.5T651-139ZM440-445q65 0 118.5-35t79.5-95H244q24 60 77.5 95T440-445ZM334-774q-19-25-26-45t-5-45q1-7 5.5-11.5T320-880q6 0 10 5t4 11q-1 25 6 42.5t25 41.5q17 24 24.5 44.5T396-689q0 7-4.5 11.5T380-673q-6 0-10-5t-3-11q2-21-5.5-40T334-774Zm78 0q-19-24-26.5-44t-5.5-46q1-7 5.5-11.5T397-880q6 0 10 5t3 11q-2 20 5.5 39t26.5 45q17 23 24.5 44t6.5 47q0 7-4.5 11.5T457-673q-6 0-10-4.5t-4-11.5q1-28-5.5-45T412-774Zm77 0q-20-26-27.5-46t-5.5-44q1-7 5.5-11.5T473-880q6 0 10 5t3 11q-2 20 5.5 39t27.5 45q17 23 24.5 44t5.5 47q0 7-4.5 11.5T533-673q-6 0-10-5t-4-12q2-26-5-43.5T489-774Zm-49 329Z"
		/>
	</svg>
);
