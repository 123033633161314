import { ReactNode } from 'react';

export const symbol_code_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m166-482 176 176q9 9 9 21t-9 21q-9 9-21.5 9t-21.5-9L101-461q-5-5-7-10t-2-11q0-6 2-11t7-10l135-135L82-792q-9-9-9-21.5t9-21.5q9-9 21.5-9t21.5 9l710 710q9 9 9 21t-9 21q-9 9-21.5 9T792-83L279-595 166-482Zm535 136q-9-9-9-21.5t9-21.5l93-93-176-176q-9-9-8.5-21t9.5-21q9-9 21.5-9t21.5 9l197 197q5 5 7 10t2 11q0 6-2 11t-7 10L743-345q-9 9-21 8.5t-21-9.5Z"
		/>
	</svg>
);
