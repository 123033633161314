import { ReactNode } from 'react';

export const symbol_minor_crash: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M341-749q-9 9-21 9t-21-9l-64-64q-9-9-9-21t9-21q9-9 21-9t21 9l64 64q9 9 9 21t-9 21Zm278 0q-9-9-9-21t9-21l64-64q9-9 21-9t21 9q9 9 9 21t-9 21l-64 64q-9 9-21 9t-21-9Zm-139.175-31Q467-780 458.5-788.625T450-810v-120q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-930v120q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM120-40v-304q0-4.667.5-9.333Q121-358 123-363l78-236q6-19 21.75-30T258-640h444q19.5 0 35.25 11T759-599l78 236q2 5 2.5 9.667.5 4.666.5 9.333v304q0 16.667-11.735 28.333Q816.529 0 799.765 0 783 0 771.5-11.667 760-23.333 760-40v-44H200v44q0 16.667-11.735 28.333Q176.529 0 159.765 0 143 0 131.5-11.667 120-23.333 120-40Zm83-374h554l-55-166H258l-55 166Zm82.765 220Q309-194 324.5-209.75T340-248q0-23.333-15.75-39.667Q308.5-304 286-304q-23.333 0-39.667 16.265Q230-271.471 230-248.235 230-225 246.265-209.5q16.264 15.5 39.5 15.5ZM675-194q23.333 0 39.667-15.75Q731-225.5 731-248q0-23.333-16.265-39.667Q698.471-304 675.235-304 652-304 636.5-287.735q-15.5 16.264-15.5 39.5Q621-225 636.75-209.5T675-194Zm-495 50h600v-210H180v210Zm0 0v-210 210Z"
		/>
	</svg>
);
