export default {
	name: 'close-circle',
	objects: [
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M16.9116 17.2844C19.6242 14.5718 19.6242 10.1738 16.9116 7.46123C14.199 4.74865 9.80107 4.74865 7.08849 7.46123C4.3759 10.1738 4.3759 14.5718 7.08849 17.2844C9.80107 19.997 14.199 19.997 16.9116 17.2844ZM17.8309 18.2036C21.0511 14.9833 21.0511 9.76226 17.8309 6.54199C14.6106 3.32173 9.38952 3.32173 6.16925 6.54199C2.94898 9.76226 2.94898 14.9833 6.16925 18.2036C9.38952 21.4239 14.6106 21.4239 17.8309 18.2036Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M8.96404 9.33685C9.21788 9.08301 9.62944 9.08301 9.88328 9.33685L15.036 14.4896C15.2899 14.7434 15.2899 15.155 15.036 15.4088C14.7822 15.6627 14.3706 15.6627 14.1168 15.4088L8.96404 10.2561C8.7102 10.0022 8.7102 9.59069 8.96404 9.33685Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M15.036 9.33684C15.2899 9.59069 15.2899 10.0022 15.036 10.2561L9.88327 15.4088C9.62943 15.6627 9.21788 15.6627 8.96404 15.4088C8.7102 15.155 8.7102 14.7434 8.96404 14.4896L14.1168 9.33684C14.3706 9.083 14.7822 9.083 15.036 9.33684Z',
			},
		},
	],
};
