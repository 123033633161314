import { useT } from '@transifex/react';
import { Fragment } from 'react/jsx-runtime';

import * as styles from 'pkg/config/styles';

import { ApiError } from 'pkg/errors/errors';
import * as routes from 'pkg/router/routes';
import { useEndpoint } from 'pkg/api/use_endpoint';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';

import { useBrandedCSSVariables } from 'routes/public/hooks';
import ThreeSixtyLogo from 'routes/public/styles/logo';
import BrandedWrapper from 'routes/public/branded_wrapper';

import AssetImage from 'components/AssetImage';
import {
	FormattedContent,
	FormattedContentMaxLength,
} from 'components/formatted-content';
import InfoScreen from 'components/InfoScreen';
import Label from 'components/label';
import * as Card from 'components/Card';

import Row from 'components/layout/row';
import Column from 'components/layout/column';
import { Spinner } from 'components/loaders/spinner';
import AvailableSpots from 'components/forms/available-spots';

import * as css from './styles.css';

interface Props {
	guid: string;
}

export interface UnauthenticatedPublicShowGroup {
	name: string;
	profileImageUrl: string;
	primaryColor: string;
	slug: string;
	inviteCode: string;
	allowInvites: boolean;
}

export interface UnauthenticatedPublicShowForm {
	guid: string;
	title: string;
	description: string;
	status: models.form.Statuses;
	maxSubmissions: number;
	guestSubmitUrl: string;
	submissionCount: number;
	isFull: boolean;
	spotsLeft: number;
	attachment: models.attachment.Attachment;
	group: UnauthenticatedPublicShowGroup;
}

interface UnauthenticatedPublicShowResponse {
	form: UnauthenticatedPublicShowForm;
	showGuestButton: boolean;
}

const Registration = ({ guid }: Props) => {
	const t = useT();

	const {
		record: data,
		isLoading,
		hasError,
		response,
	} = useEndpoint<UnauthenticatedPublicShowResponse>(
		endpoints.Forms.UnauthenticatedPublicShow(guid)
	);

	const { form, showGuestButton } = data;

	const brand = useBrandedCSSVariables(
		form?.group.primaryColor.replace('hsl:', '').split(',')
	);

	if (hasError) {
		const error = new ApiError(response.status);

		return (
			<InfoScreen icon="error" heading={error.title} message={error.body} />
		);
	}

	if (isLoading) {
		return <Spinner />;
	}

	const isOpen = form.status === models.form.Statuses.Open;

	const returnToRegistrationAfterRoute = (route: string) =>
		`${route}?return=forms&organizationSlug=${data.form.group.slug}&guid=${data.form.guid}`;

	return (
		<BrandedWrapper brand={brand} backdropImage={form.group.profileImageUrl}>
			<div className={css.outer}>
				<ThreeSixtyLogo />
				<div className={css.wrapper}>
					<Column className={css.content} spacing={styles.spacing._8}>
						<div className={css.title}>
							{t('Registration in {group}', { group: form.group.name })}
						</div>
						<Card.Base $noBorder>
							{form.attachment && (
								<div className={css.image}>
									<img src={form.attachment.url} />
								</div>
							)}
							<Card.Body $narrowBody>
								<Column spacing={styles.spacing._3}>
									<Row columns={!form.attachment && 'auto 1fr'} align="center">
										{!form.attachment && (
											<AssetImage
												className={css.logo}
												src={form.group.profileImageUrl}
											/>
										)}
										<Column spacing={styles.spacing._3}>
											<div className={css.formTitle}>{form.title}</div>
											<div>
												<Row
													align="center"
													autoColumns="max-content"
													spacing={styles.spacing._2}>
													<Label
														color={isOpen ? 'green' : 'gray'}
														className={css.extraSmallText}>
														{models.form.getTranslatedStatus(form.status)}
													</Label>
													{!!form.maxSubmissions && (
														<AvailableSpots form={form} />
													)}
												</Row>
											</div>
										</Column>
									</Row>
									<FormattedContent
										raw={form.description}
										maxLength={FormattedContentMaxLength.Standard}
										wrapperClassName={css.smallText}
										allowedFormatters={[
											'emails',
											'line-breaks',
											'links',
											'video',
											'vimeo',
											'youtube',
										]}
									/>
								</Column>
							</Card.Body>
						</Card.Base>
						<Column>
							{isOpen ? (
								<Fragment>
									{data.form.group.allowInvites &&
										data.form.group.inviteCode && (
											<Fragment>
												<p>{t('To access the registration')}</p>
												<a
													className={css.primary}
													href={returnToRegistrationAfterRoute(
														routes.Join(data.form.group.inviteCode)
													)}>
													{t('Create a 360player account')}
												</a>
												<span className={css.withLine}>{t('Or')}</span>
											</Fragment>
										)}
									<a
										className={css.transparent}
										href={returnToRegistrationAfterRoute(
											routes.LoginRedirector.Index()
										)}>
										{t('Log in to your account')}
									</a>
									{showGuestButton && (
										<a className={css.transparent} href={form.guestSubmitUrl}>
											{t('Register as guest')}
										</a>
									)}
								</Fragment>
							) : (
								<Fragment>
									<p>{t('Registration is closed')}</p>
									<a className={css.transparent} href={routes.Home()}>
										{t('Back to 360Player')}
									</a>
								</Fragment>
							)}
						</Column>
					</Column>
				</div>
			</div>
		</BrandedWrapper>
	);
};

export default Registration;
