import spacing from 'pkg/config/spacing';

import * as models from 'pkg/api/models';
import { useCurrentAccountUserIds } from 'pkg/identity';

import MaterialSymbol from 'components/material-symbols';
import Avatar from 'components/avatar';

import * as eventStyles from 'components/event/calendar-styles/styles.css';
import Row from 'components/layout/row';

import * as css from './styles.css';

interface EventUserRowProps {
	eventUser: models.eventUser.EventUser;
	event: models.event.Event;
}

export default function EventUserRow({ event, eventUser }: EventUserRowProps) {
	const userIds = useCurrentAccountUserIds();
	const eventUserIsCurrentUser = userIds.includes(eventUser.user.id);
	const eventHasEnded = models.event.hasEnded(event);

	const text = models.eventUser.getStatusText(
		eventUser,
		eventUserIsCurrentUser,
		models.event.hasEnded(event)
	);

	const getIconVariant = () => {
		if (eventHasEnded) {
			if (
				eventUser.attendanceStatus ===
				models.eventUser.AttendanceStatuses.Attended
			) {
				return 'task_alt';
			} else {
				return 'block';
			}
		} else {
			switch (eventUser.status) {
				case models.eventUser.EventUserStatus.Accepted:
					return 'task_alt';
				case models.eventUser.EventUserStatus.Pending:
					return 'help';
				case models.eventUser.EventUserStatus.Declined:
				default:
					return 'block';
			}
		}
	};

	return (
		<Row autoColumns="max-content" align="center" spacing={spacing._3}>
			<Avatar size={17} user={eventUser.user} className={eventStyles.avatar} />
			<div className={css.name}>
				<MaterialSymbol variant={getIconVariant()} scale={1.1} />
				{text}
			</div>
		</Row>
	);
}
