import { ReactNode } from 'react';

export const symbol_forward_10: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-80q-75 0-140.5-28T225-185q-49-49-77-114.5T120-440q0-75 28-140.5T225-695q49-49 114.5-77T480-800h23l-57-57q-8-8-8-20.5t7.652-20.5Q454-906 466-906.5q12-.5 20 7.5l106 106q9 9 9 21t-9 21L487-646q-9 9-21 9t-21.391-9Q436-655 436-667t9-21l52-52h-22q-125 0-210 87.321Q180-565.357 180-440t87.321 212.679Q354.643-140 480-140t212.679-87.321Q780-314.643 780-440q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T840-440q0 75-28 140.5T735-185q-49 49-114.5 77T480-80ZM360-522h-30q-10.4 0-17.2-7.116-6.8-7.117-6.8-18 0-10.884 7.083-17.384Q320.167-571 331-571h55q11 0 17.5 6.5T410-547v212q0 10.833-7.116 17.917-7.117 7.083-18 7.083Q374-310 367-317.083q-7-7.084-7-17.917v-187Zm147 212q-18.7 0-31.35-12.65Q463-335.3 463-354v-173q0-18.7 12.65-31.35Q488.3-571 507-571h83q18.7 0 31.35 12.65Q634-545.7 634-527v173q0 18.7-12.65 31.35Q608.7-310 590-310h-83Zm6-50h71v-162h-71v162Z"
		/>
	</svg>
);
