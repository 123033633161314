import { ReactNode } from 'react';

export const symbol_draw_abstract: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M142-139q-5 5-10.5 7t-11.5 2q-6 0-11-2t-10-7q-9-8-9-20.5t8-21.5q17-17 41.5-20.5t48.5.5q11 2 20 3.5t16-5.5q8-8 7-19t-3-22q-4-24-1.5-48.5T246-335q17-17 41.5-20.5t48.5.5q11 2 20.5 3.5T373-357q8-8 6.5-19t-3.5-22q-4-24-1.5-48.5T394-489q17-17 41.5-20.5t48.5.5q11 2 20.5 3.5T521-511q8-8 6.5-19t-3.5-22q-4-25-1-49t20-42q17-17 41.5-20.5t48.5.5q11 2 20.5 3.5T670-665q8-8 6.5-19t-3.5-22q-4-25-1-49t20-42q17-18 41.5-21t48.5 1q11 2 20 3.5t17-6.5q8-9 20.5-9t21.5 8q9 8 9 20.5t-8 21.5q-17 18-41.5 21.5T772-758q-11-2-20.5-3.5T735-756q-8 8-6.5 19t3.5 22q4 25 1 49t-20 42q-17 17-41.5 20.5T623-604q-11-2-20-3.5t-16 5.5q-8 8-7 19t3 22q4 24 1.5 48.5T565-470q-17 17-41.5 20.5T475-450q-11-2-20-3t-16 6q-8 8-7 18.5t3 21.5q4 24 1.5 48.5T417-316q-17 18-42 21t-49-1q-11-2-20-3t-16 6q-8 8-6.5 18.5T287-253q4 25 1 49t-20 42q-17 17-41.5 20.5T178-142q-11-2-20-3t-16 6Zm98-381q-83 0-141.5-58.5T40-720q0-84 58.5-142T240-920q84 0 142 58t58 142q0 83-58 141.5T240-520Zm0-60q59 0 99.5-41t40.5-99q0-59-40.5-99.5T240-860q-58 0-99 40.5T100-720q0 58 41 99t99 41ZM620-40q-25 0-42.5-17.5T560-100v-240q0-25 17.5-42.5T620-400h240q25 0 42.5 17.5T920-340v240q0 25-17.5 42.5T860-40H620Zm0-60h240v-240H620v240Zm120-120ZM240-720Z"
		/>
	</svg>
);
