import { ReactNode } from 'react';

export const symbol_wifi_find: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M460-141 46-554q-9-9-13.5-21T29-599q1-12 6-23.5T50-642q91-76 201.5-117T480-800q120 0 231.5 42T914-639q9 8 13.5 18.5T933-599q1 11-3 22t-13 20q-91-81-200-132t-237-51q-115 0-215 42T88-594l413 413q8 8 8 20t-8 20q-8 8-20.5 8t-20.5-8Zm391-48-88-88q-18 13-39 20t-44 7q-62 0-106-44t-44-106q0-62 44-106t106-44q62 0 106 44t44 106q0 23-6.5 44T803-321l90 90q9 9 9 21t-9 21q-9 9-21 9t-21-9ZM680-310q37 0 63.5-26.5T770-400q0-37-26.5-63.5T680-490q-37 0-63.5 26.5T590-400q0 37 26.5 63.5T680-310ZM480-161Z"
		/>
	</svg>
);
