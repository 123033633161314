import { t } from '@transifex/native';

import PerformanceReview from 'pkg/models/performance_review';

import { forAccountHeaders, normalizedDispatch } from 'pkg/actions/utils';
import { show } from 'pkg/actions/flashes';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as sdk from 'pkg/core/sdk';

export const setLinks = (links) => ({
	type: 'SET_LINKS',
	links,
});

export const addPerformanceReview = (userId, performanceReview) => ({
	type: 'ADD_PERFORMANCE_REVIEW',
	userId,
	performanceReview,
});

export const removePerformanceReview = (performanceReviewId) => ({
	type: 'REMOVE_PERFORMANCE_REVIEW',
	performanceReviewId,
});

export const fetchPerformanceReviews =
	(userId, groupId, fetchNext = false, forAccount = null) =>
	async (dispatch, getState) => {
		let url =
			endpoints.PerformanceReview.Index() +
			`?user_id=${userId}&group_id=${groupId}`;

		if (fetchNext) {
			const { next } = getState().performanceReviews.links;
			url = next;
		}

		if (!url) {
			return;
		}

		const headers = forAccountHeaders(forAccount);

		const response = await sdk.get(url, {}, {}, headers);

		if (!response.ok) {
			throw response;
		}

		const performanceReview = await response.json();

		dispatch(setLinks(performanceReview.links));
		dispatch(
			normalizedDispatch(performanceReview.records, [
				PerformanceReview.normalizr(),
			])
		);
	};

export const deletePerformanceReview = (id, forAccount) => async (dispatch) => {
	const headers = forAccountHeaders(forAccount);

	const res = await sdk.destroy(
		endpoints.PerformanceReview.Delete(id),
		{},
		{},
		headers
	);

	if (res.ok) {
		show(
			{
				title: '',
				message: t(`Deleted player dialogue`),
			},
			200
		);

		dispatch(removePerformanceReview(id));
	}
};

export const updatePerformanceReview =
	(payload, forAccount = null) =>
	async (dispatch) => {
		const headers = forAccountHeaders(forAccount);

		const response = await sdk.patch(
			endpoints.PerformanceReview.Update(payload.id),
			{},
			payload,
			headers
		);

		if (!response.ok) {
			throw response;
		}

		const performanceReview = await response.json();

		performanceReview.groupId = payload.groupId;
		performanceReview.userId = payload.userId;

		dispatch(
			normalizedDispatch(performanceReview, PerformanceReview.normalizr())
		);
	};

export const savePerformanceReview =
	(payload, forAccount = null) =>
	async (dispatch) => {
		const p = payload?.performanceReview?.trim();

		const headers = forAccountHeaders(forAccount);

		if (!p) {
			show({
				title: t(`Save failed`),
				message: t(`Failed to save, make sure you have written something.`),
			});

			return false;
		}

		const response = await sdk.post(
			endpoints.PerformanceReview.Create(),
			{},
			payload,
			headers
		);

		if (!response.ok) {
			throw response;
		}

		const performanceReview = await response.json();
		performanceReview.userId = payload.userId;
		performanceReview.groupId = payload.groupId;

		dispatch(addPerformanceReview(performanceReview.userId, performanceReview));
		show(
			{
				title: '',
				message: t(`Player dialogue saved successfully`),
			},
			200
		);

		return true;
	};
