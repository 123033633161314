import { ReactNode } from 'react';

export const symbol_markunread_mailbox: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M240-565H140v425h680v-425H390q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T390-625h430q24 0 42 18t18 42v425q0 24-18 42t-42 18H140q-24 0-42-18t-18-42v-425q0-24 18-42t42-18h100v-225q0-12.75 8.625-21.375T270-880h240q12.75 0 21.375 8.625T540-850v100q0 12.75-8.625 21.375T510-720H300v273q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T240-447v-118Zm-100 0v425-425 148-148Z"
		/>
	</svg>
);
