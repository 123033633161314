import { ReactNode } from 'react';

export const symbol_text_select_move_up: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M800-120H160q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T160-180h640q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T800-120ZM450-551l-53 52q-9 8-21 8.5t-21-8.5q-9-9-9-21t9-21l104-104q9-9 21-9t21 9l104 104q9 9 9 21t-9 21q-9 9-21 8.5t-21-8.5l-53-52v231q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T450-320v-231Zm350-229H160q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T160-840h640q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T800-780Z"
		/>
	</svg>
);
