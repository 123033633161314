import { useRef, useContext, useLayoutEffect } from 'react';

import { FabricContext } from 'components/drawing/FabricContext';

export default function FabricCanvas({ isEditing }: any) {
	const canvasEl = useRef();

	const { initCanvas, canvas } = useContext(FabricContext);

	useLayoutEffect(() => {
		initCanvas(canvasEl.current, isEditing);

		return () => {
			canvas.current?.dispose();
		};
	}, []);

	return <canvas ref={canvasEl} width="750" height="750" />;
}
