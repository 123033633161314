import { ReactNode } from 'react';

export const symbol_shop: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m430-275 182-119q14-8.786 14-24.893T612-444L431-564q-15-10-31-1.63-16 8.369-16 26.63v239q0 18.261 15.5 26.63Q415-265 430-275ZM140-120q-24 0-42-18t-18-42v-510q0-12.75 8.625-21.375T110-720h210v-100q0-24 18-42t42-18h200q24 0 42 18t18 42v100h210q12.75 0 21.375 8.625T880-690v510q0 24-18 42t-42 18H140Zm0-60h680v-480H140v480Zm240-540h200v-100H380v100ZM140-180v-480 480Z"
		/>
	</svg>
);
