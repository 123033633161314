import { Fragment, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as actions from 'pkg/actions';
import rgba from 'pkg/rgba';
import { useCurrentAccount } from 'pkg/identity';
import { useAccountIdentity } from 'pkg/identity/account';

import Backdrop from 'components/Backdrop';
import StepModal, { Step } from 'components/step-modal';

import { Spinner } from 'components/loaders/spinner';
import Label from 'components/form/Label';
import TextField from 'components/form/TextInput';
import FormRow from 'components/form/Row';
import * as Forms from 'components/public/Form';
import { ButtonRow } from 'components/public/Styles';

import Button from 'design/button';

const OnboardingBackdrop = styled(Backdrop).attrs({
	animationDirection: 'in',
})`
	background-image: url('${window.TS.assetUrl}/img/signup-bg.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	pointer-events: auto;
`;

const Text = styled.div<{ stepText?: boolean }>`
	color: var(--palette-gray-800);
	text-align: center;
	margin: 0 auto;
	margin-bottom: 20px;

	div {
		font-weight: 400;
		line-height: 19px;

		&:nth-child(1) {
			font-size: 21px;
			line-height: 25px;
			font-weight: var(--font-weight-semibold);
			margin-bottom: 10px;
		}
	}

	${({ stepText }) =>
		stepText &&
		css`
			text-align: left;
			div {
				&:nth-child(1) {
					margin-bottom: 5px;
				}

				&:nth-child(2) {
					font-weight: 400;
				}
			}
		`}
`;

const PendingBox = styled.div`
	display: grid;
	grid-template-columns: 1fr 0fr;
	align-items: center;
	padding: 10px 15px;
	min-height: 60px;
	border: 1px solid var(--palette-gray-300);
	border-radius: var(--radius-3);
	box-shadow: 0px 1px 3px ${rgba(styles.palette.black, 0.1)};
	margin-bottom: 20px;

	@media ${styles.breakpoint.small} {
		display: block;
	}

	&:last-child {
		margin-bottom: 0px;
	}
`;

const PendingText = styled.div`
	overflow: hidden;
	div {
		font-weight: var(--font-weight-semibold);

		&:nth-child(2) {
			font-weight: var(--font-weight-normal);
			color: var(--palette-gray-500);
			font-size: 12px;
			line-height: 15px;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
`;

const PendingButtonsWrapper = styled.div`
	display: flex;

	@media ${styles.breakpoint.small} {
		margin-top: var(--spacing-4);
	}

	button {
		&:nth-child(1) {
			margin-right: var(--spacing-3);
		}
	}
`;

export function ParentalConsentRequestForm() {
	const [email, setEmail] = useState('');
	const [pending, setPending] = useState(false);
	const [removed, setRemoved] = useState(false);
	const [approved, setApproved] = useState(false);
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);
	const account = useCurrentAccount();
	const { refreshAccountIdentity } = useAccountIdentity();

	const dispatch = useDispatch();

	const isSmallScreen = useMediaQuery({
		maxWidth: styles.breakpoint.toMedium,
	});

	useEffect(() => {
		const emailMeta = account.parentalConsentEmail;
		const removedMeta = account.parentalConsentRevoked;

		if (emailMeta) {
			setEmail(emailMeta);
		}

		if (emailMeta && emailMeta.length > 0) {
			setPending(true);
		} else if (
			removedMeta &&
			removedMeta > 0 &&
			emailMeta &&
			emailMeta.length > 0
		) {
			setRemoved(true);
		} else if (removedMeta && removedMeta > 0) {
			setRemoved(true);
		}
	}, [account.parentalConsentEmail, account.parentalConsentRevoked]);

	useEffect(() => {
		let parentalCheckInterval = null;

		const consentCheck = async () => {
			const req = await actions.accounts.checkParentalConsent();
			if (req) {
				refreshAccountIdentity();
			}
		};

		consentCheck();

		clearInterval(parentalCheckInterval);

		if (account.needsParentalConsent) {
			parentalCheckInterval = setInterval(consentCheck, 10000);
		} else {
			clearInterval(parentalCheckInterval);
		}

		return () => clearInterval(parentalCheckInterval);
	}, [account.needsParentalConsent, dispatch]);

	const requestPermission = async (e: any) => {
		e.preventDefault();

		setLoading(true);

		const req = await actions.accounts.requestParentalConsent(email);
		if (req.ok) {
			setPending(true);
		} else {
			setError(true);
		}

		setLoading(false);
	};

	const handleChange = (e: any) => {
		setEmail(e.target.value);
	};

	const removeConsentRequest = () => {
		setPending(false);
	};

	const getTitleText = () => {
		if (pending && !approved) {
			return t(`Consent request pending`);
		} else if (removed && !approved) {
			return t(`Your permission was removed.`);
		} else if (approved) {
			return t(`Consent ok!`);
		} else {
			return t(`You need legal guardian consent to use 360Player`);
		}
	};

	const getDescriptionText = () => {
		if (pending && !approved) {
			return t(
				`Your parent now have to accept the consent email before you can use 360Player.`
			);
		} else if (removed && !approved) {
			return t(
				`To continue using 360Player you need to request permission again.`
			);
		} else if (approved) {
			return t(`You can now start using 360Player!`);
		} else {
			return t(`Enter your parents email to send a consent request.`);
		}
	};

	const closeModal = async () => {
		const req = await dispatch(actions.accounts.setParentalConsent());

		if (!req) {
			setApproved(false);
		}
	};

	return (
		<StepModal thin customBackdrop={<OnboardingBackdrop />} hideCloseButton>
			<Step
				hideLargeScreenTitle
				prevLabel={t(`Log out`)}
				onPrev={() => actions.auth.logout()(dispatch)}
				nextLabel={!pending ? t('Help') : ' '}
				canGoNext={!pending ? true : false}
				hideNext={pending ? true : false}
				spaciousBody>
				{loading ? (
					<Spinner />
				) : (
					<Fragment>
						<Text>
							<div>{getTitleText()}</div>
							<div>{getDescriptionText()}</div>
						</Text>
						{pending || approved ? (
							<PendingBox>
								<PendingText>
									<div>{t(`Parent`)}</div>
									<div>{email}</div>
								</PendingText>
								<PendingButtonsWrapper>
									<Button
										label={!approved ? t('Remove') : t(`Consent ok!`)}
										small
										caution={account.needsParentalConsent && !approved}
										primary={approved}
										onClick={removeConsentRequest}
									/>
									{!approved && <Button disabled label={t(`Pending`)} small />}
								</PendingButtonsWrapper>
							</PendingBox>
						) : (
							<Forms.Form>
								<FormRow>
									<Label>{t(`Enter your parents email`)}</Label>
									<TextField
										name="username"
										type="email"
										value={email}
										onChange={handleChange}
									/>
								</FormRow>
								<ButtonRow>
									<Button
										label={t(`Request permission`)}
										large
										onClick={requestPermission}
										disabled={email.indexOf('@') === -1 || loading}
									/>
								</ButtonRow>
								<Forms.Error>{error}</Forms.Error>
							</Forms.Form>
						)}

						{approved && (
							<ButtonRow>
								<Button
									label={t(`Start using 360Player`)}
									primary
									large
									onClick={closeModal}
								/>
							</ButtonRow>
						)}

						{pending && isSmallScreen && (
							<ButtonRow>
								<Button
									label={t(`Log out`)}
									onClick={() => dispatch(actions.auth.logout())}
									primary
									large
								/>
							</ButtonRow>
						)}
					</Fragment>
				)}
			</Step>
			<Step
				title={t('Help')}
				hideNext
				canGoNext={false}
				prevLabel={t('Back')}
				spaciousBody>
				<Text stepText>
					<div>{t(`Why did this happen?`)}</div>
					<div>
						{t(
							`Since you are underage, we need consent from a legal guardian to process your personal information.`
						)}
					</div>
				</Text>
				<Text stepText>
					<div>{t(`How to get access`)}</div>
					<div>
						{t(
							`Enter a parent's or guardian's email address here and we will notify them you want to access 360Player. After they have given their consent you can start using 360Player. Make sure you enter a valid email address.`
						)}
					</div>
				</Text>
			</Step>
		</StepModal>
	);
}
