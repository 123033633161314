import { ReactNode } from 'react';

export const symbol_baby_changing_station: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M150-80q-12.75 0-21.375-8.625T120-110v-358q0-4.9.5-9.45.5-4.55 2.5-9.55l59-175q8-23 26.5-35.5t40.868-12.5q7.158 0 14.316 1.286Q270.842-707.429 278-704l168 74h64q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T510-570h-65q-6.25 0-12.5-1.429-6.25-1.428-12.5-3.571l-104-46-56 169v342q0 12.75-8.625 21.375T230-80h-80Zm250-130q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T400-270h410q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-210H400Zm380-110q-25 0-42.5-17.5T720-380q0-25 17.5-42.5T780-440q25 0 42.5 17.5T840-380q0 25-17.5 42.5T780-320Zm-270-10q-24.75 0-42.375-17.625T450-390v-60h-50q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T400-510h80q12.75 0 21.375 8.625T510-480v50h120v-50q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T690-480v90q0 24.75-17.625 42.375T630-330H510ZM313.08-774q-30.08 0-51.58-21.42-21.5-21.421-21.5-51.5 0-30.08 21.42-51.58 21.421-21.5 51.5-21.5 30.08 0 51.58 21.42 21.5 21.421 21.5 51.5 0 30.08-21.42 51.58-21.421 21.5-51.5 21.5Z"
		/>
	</svg>
);
