import { ReactNode } from 'react';

export const symbol_hub: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M234-40q-47.5 0-80.75-33.25T120-154q0-47.5 33.25-80.75T234-268q14 0 24.5 2.5T280-258l85-106q-19-23-29-52.5t-5-61.5l-121-41q-15 25-39.5 39T114-466q-47.5 0-80.75-33.25T0-580q0-47.5 33.25-80.75T114-694q47.5 0 80.75 33.25T228-580v4l122 42q18-32 43.5-49t56.5-24v-129q-39-11-61.5-43T366-846q0-47.5 33.25-80.75T480-960q47.5 0 80.75 33.25T594-846q0 35-23 67t-61 43v129q31 7 57 24t44 49l121-42v-4q0-47.5 33.25-80.75T846-694q47.5 0 80.75 33.25T960-580q0 47.5-33.25 80.75T846-466q-32 0-57-14t-39-39l-121 41q5 32-4.5 61.5T595-364l85 106q11-5 21.5-7.5t24.062-2.5Q774-268 807-234.75T840-154q0 47.5-33.25 80.75T726-40q-47.5 0-80.75-33.25T612-154q0-20 5.5-36t15.5-31l-85-106q-32.127 17-68.563 17Q443-310 411-327l-84 107q10 15 15.5 30.5T348-154q0 47.5-33.25 80.75T234-40ZM114.035-526Q137-526 152.5-541.535q15.5-15.535 15.5-38.5T152.465-618.5q-15.535-15.5-38.5-15.5T75.5-618.465Q60-602.93 60-579.965T75.535-541.5q15.535 15.5 38.5 15.5Zm120 426Q257-100 272.5-115.535q15.5-15.535 15.5-38.5T272.465-192.5q-15.535-15.5-38.5-15.5T195.5-192.465q-15.5 15.535-15.5 38.5t15.535 38.465q15.535 15.5 38.5 15.5Zm246-692Q503-792 518.5-807.535q15.5-15.535 15.5-38.5T518.465-884.5q-15.535-15.5-38.5-15.5T441.5-884.465q-15.5 15.535-15.5 38.5t15.535 38.465q15.535 15.5 38.5 15.5Zm.465 422q37.5 0 63.5-26.5t26-64q0-37.5-26.1-63.5T480-550q-37 0-63.5 26.1T390-460q0 37 26.5 63.5t64 26.5Zm245.535 270Q749-100 764.5-115.535q15.5-15.535 15.5-38.5T764.465-192.5q-15.535-15.5-38.5-15.5T687.5-192.465q-15.5 15.535-15.5 38.5t15.535 38.465q15.535 15.5 38.5 15.5Zm120-426Q869-526 884.5-541.535q15.5-15.535 15.5-38.5T884.465-618.5q-15.535-15.5-38.5-15.5T807.5-618.465q-15.5 15.535-15.5 38.5t15.535 38.465q15.535 15.5 38.5 15.5ZM480-846ZM114-580Zm366 120Zm366-120ZM234-154Zm492 0Z"
		/>
	</svg>
);
