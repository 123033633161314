// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ppg1Vg5pSbN8l_5lqRoU {
	margin: 0 auto;
	width: 100px;
	aspect-ratio: 1/0.6;
	color: var(--palette-gray-400);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-line-pack: center;
	    align-content: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
}
.Ppg1Vg5pSbN8l_5lqRoU.Zqd9XMTYhkWiIg8rhZVq {
		-webkit-transform: translate(-50%, -50%);
		        transform: translate(-50%, -50%);
		position: fixed;
		left: 50%;
		top: 50%;
	}
`, "",{"version":3,"sources":["webpack://./components/loaders/spinner/styles.css"],"names":[],"mappings":"AAAA;CACC,cAAc;CACd,YAAY;CACZ,mBAAmB;CACnB,8BAA8B;CAC9B,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,0BAAqB;KAArB,qBAAqB;CACrB,wBAAuB;KAAvB,qBAAuB;SAAvB,uBAAuB;AAQxB;AANC;EACC,wCAAgC;UAAhC,gCAAgC;EAChC,eAAe;EACf,SAAS;EACT,QAAQ;CACT","sourcesContent":[".spinner {\n\tmargin: 0 auto;\n\twidth: 100px;\n\taspect-ratio: 1/0.6;\n\tcolor: var(--palette-gray-400);\n\tdisplay: flex;\n\talign-content: center;\n\tjustify-content: center;\n\n\t&.center {\n\t\ttransform: translate(-50%, -50%);\n\t\tposition: fixed;\n\t\tleft: 50%;\n\t\ttop: 50%;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var spinner = `Ppg1Vg5pSbN8l_5lqRoU`;
export var center = `Zqd9XMTYhkWiIg8rhZVq`;
export default ___CSS_LOADER_EXPORT___;
