import { ReactNode } from 'react';

export const symbol_planner_banner_ad_pt: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M220-80q-24 0-42-18t-18-42v-680q0-24 18-42t42-18h520q24 0 42 18t18 42v680q0 24-18 42t-42 18H220Zm0-60h520v-680H220v680Zm228-132-58-73q-5-6-12-6t-12 6l-64 84q-6 8-1.5 16t13.5 8h337q9.208 0 13.104-8Q668-253 663-261l-98-131q-5-6-12-6t-12 6l-93 120ZM220-140v-680 680Z"
		/>
	</svg>
);
