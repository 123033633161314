import { ReactNode } from 'react';

export const symbol_perm_camera_mic: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-120q-24 0-42-18t-18-42v-513q0-24 18-42t42-18h147l55-66q8-11 20-16t26-5h184q14 0 26 5t20 16l55 66h147q24 0 42 18t18 42v513q0 24-18 42t-42 18H603q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T603-180h217v-513H645l-73-87H388l-73 87H140v513h216q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T356-120H140Zm340-300q29 0 47.5-18.5T546-486v-174q0-29-18.5-47.5T480-726q-29 0-48 18.5T413-660v174q0 29 19 47.5t48 18.5ZM140-180h680-680Zm340-140q-63 0-110-38t-60-94q-3-14-11.5-24t-22-10q-13.5 0-21.5 10t-6 24q12 76 67.5 128.5T449-261v111q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T509-150v-111q77-10 133-62.5T710-452q2-14-6.179-24-8.18-10-20.821-10-14 0-22.5 10T649-452q-13 56-59.5 94T480-320Z"
		/>
	</svg>
);
