import styled from 'styled-components';

import * as palette from 'pkg/config/palette';

import * as iconStyles from 'components/icon/styles.css';

const InlinePlaceholder = styled.div`
	display: flex;
	flex-flow: column;
	align-items: center;
	align-content: center;
	color: ${palette.gray[400]};
	font-weight: var(--font-weight-normal);
	text-align: center;
	padding: 15px 0 25px 0;
	user-select: none;
	cursor: ${(props) =>
		typeof props.onClick === 'function' ? 'pointer' : 'initial'};
	gap: var(--spacing-3);

	.${iconStyles.icon} {
		font-size: 4rem;
		color: ${palette.gray[400]};
	}
`;

export default InlinePlaceholder;
