const icon = {
	name: 'drawing-squiggly-line',
	objects: [
		{
			type: 'path',
			attributes: {
				d: 'M9.33998 13.8257C9.44206 14.3532 8.97858 14.8167 8.45113 14.7146C6.96631 14.4272 5.68886 15.7855 6.06672 17.2499L6.30217 18.1624L7.56095 17.8376L7.32549 16.9251C7.18626 16.3855 7.65697 15.885 8.2041 15.9909C9.63551 16.268 10.8933 15.0101 10.6163 13.5787L10.6026 13.5078C10.4939 12.9465 10.9872 12.4532 11.5486 12.5618C13.0139 12.8454 14.3015 11.5578 14.0179 10.0925L13.9957 9.9775C13.8977 9.47115 14.3426 9.0262 14.849 9.1242L15.087 9.17027C16.4741 9.43873 17.6511 8.1395 17.2473 6.78561C17.1225 6.36729 17.4862 5.96586 17.9147 6.04881L18.4613 6.15459L18.7083 4.87828L18.1618 4.77249C16.7747 4.50403 15.5977 5.80326 16.0015 7.15715C16.1263 7.57547 15.7626 7.9769 15.334 7.89395L15.096 7.84789C13.6857 7.57492 12.4464 8.81421 12.7194 10.2245L12.7416 10.3395C12.8503 10.9009 12.357 11.3942 11.7956 11.2855C10.3303 11.0019 9.04264 12.2895 9.32626 13.7549L9.33998 13.8257Z',
				fill: 'currentColor',
			},
		},
	],
};

export default icon;
