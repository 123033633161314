import { ReactNode } from 'react';

export const symbol_aq: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M657-275q-78 0-132-54t-54-132q0-77 54-131.5T656-647q77 0 130.5 54.5T840-461q0 40-16 76t-45 63l14 20q5 8 3.5 17t-9.029 15q-8.471 6-18.971 4.5T753-275l-13-20q-19.713 10-40.463 15T657-275ZM209-375l-26.897 74.7q-3.103 8.3-9.665 12.8Q165.875-283 158-283q-13.889 0-21.944-11.218Q128-305.436 133-318l112.599-301.883Q248.615-629 256.658-634t17.091-5h22.117q9.048 0 17.091 5 8.043 5 11.095 14.088L438-319q5 12.923-2.788 24.462Q427.423-283 413-283q-8.036 0-15.179-4.95Q390.679-292.9 388-301l-26.637-74H209Zm447.211 52q14.789 0 28.289-3 13.5-3 27.5-10l-30-46q-5-8-3.5-17.5t9.5-15q8-5.5 18-3.5t15 10l29 43q19-20 29.5-44.744t10.5-51.551Q790-518 751.483-558.5q-38.516-40.5-95.5-40.5Q599-599 560-558.321 521-517.642 521-461q0 56.642 39.084 97.321Q599.169-323 656.211-323ZM226-423h118l-56.564-160H283l-57 160Z"
		/>
	</svg>
);
