import { ReactNode } from 'react';

export const symbol_offline_share: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M220-40q-24 0-42-18t-18-42v-625q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T220-725v625h457q12.75 0 21.375 8.675Q707-82.649 707-69.825 707-57 698.375-48.5T677-40H220Zm120-120q-24 0-42-18t-18-42v-640q0-24 18-42t42-18h400q24 0 42 18t18 42v640q0 24-18 42t-42 18H340Zm0-120v60h400v-60H340Zm0-60h400v-400H340v400Zm238-170H461v53q0 10.833-7.116 17.917-7.117 7.083-18 7.083-10.884 0-18.384-7.367Q410-446.733 410-458v-67q0-17 10-26.5t27-9.5h131l-23-23q-8-7.636-8-17.818Q547-612 555-620q8-8 18.182-8 10.182 0 17.818 8l74 74q5 4.636 5 10.818Q670-529 665-524l-74 74q-7.636 8-17.818 8Q563-442 555-450q-8-8-8-18.182 0-10.182 8-17.818l23-24ZM340-800h400v-60H340v60Zm0 0v-60 60Zm0 520v60-60Z"
		/>
	</svg>
);
