import { ReactNode } from 'react';

export const symbol_keep_public: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M401-576Zm-71 265H150q-12.75 0-21.375-8.585Q120-328.169 120-340.859v-17.169q0-5.972 2-11.472 2-5.5 7-10.5l71-68v-332h-20q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T180-840h313q-15.319 12.825-27.66 27.912Q453-797 442-780H260v354l-59 55h399v30q0 12.75-8.625 21.375T570-311H390v229q0 5.565-2 10.783Q386-66 381-61l-10 10q-4.636 5-10.818 5Q354-46 349-51l-10-10q-5-5-7-10.217-2-5.218-2-10.783v-229Zm323-341q27.917 0 47.458-19.559Q720-691.118 720-719.059T700.458-766Q680.917-785 653-785q-27.5 0-46.75 19.353t-19.25 47Q587-691 606.25-671.5T653-652Zm-.214 133Q686-519 715-534.5t47-42.5q-26-14-53-22.5t-56-8.5q-29 0-56 8.5T544-577q18 27 46.786 42.5 28.785 15.5 62 15.5Zm.228 55Q575-464 520-518.698 465-573.395 465-651q0-78.435 54.986-133.718Q574.972-840 652.986-840 731-840 786-784.718 841-729.435 841-651q0 77.605-54.986 132.302Q731.028-464 653.014-464Z"
		/>
	</svg>
);
