import { ReactNode } from 'react';

export const symbol_person_cancel: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m780-537-63 62q-9 8-21 8.5t-21-8.5q-9-9-8.5-21t8.5-21l62-63-62-62q-9-9-9-21t9-21q9-9 21-8.5t21 8.5l63 62 62-62q9-9 21-9t21 9q9 9 9 21t-9 21l-62 62 62 63q8 9 8.5 21t-8.5 21q-9 9-21 9t-21-9l-62-62Zm-420 56q-66 0-108-42t-42-108q0-66 42-108t108-42q66 0 108 42t42 108q0 66-42 108t-108 42ZM40-220v-34q0-35 17.5-63.5T108-360q75-33 133.5-46.5T360-420q60 0 118 13.5T611-360q33 15 51 43t18 63v34q0 25-17.5 42.5T620-160H100q-25 0-42.5-17.5T40-220Zm60 0h520v-34q0-16-9-30.5T587-306q-71-33-120-43.5T360-360q-58 0-107.5 10.5T132-306q-15 7-23.5 21.5T100-254v34Zm260-321q39 0 64.5-25.5T450-631q0-39-25.5-64.5T360-721q-39 0-64.5 25.5T270-631q0 39 25.5 64.5T360-541Zm0-90Zm0 411Z"
		/>
	</svg>
);
