import { ReactNode } from 'react';

export const symbol_arrow_drop_up: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M316-400q-6.75 0-10.875-4.636Q301-409.273 301-415.455 301-417 306-426l158-157q3-3 7.062-5 4.063-2 8.938-2 4.875 0 8.938 2 4.062 2 7.062 5l158 157q2 2 3.5 4.763t1.5 5.921q0 6.316-4.125 10.816Q650.75-400 644-400H316Z"
		/>
	</svg>
);
