import { ReactNode } from 'react';

export const symbol_language_international: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M69.825-320Q57-320 48.5-328.625T40-350v-260q0-12.75 8.675-21.375Q57.351-640 70.175-640 83-640 91.5-631.375T100-610v260q0 12.75-8.675 21.375Q82.649-320 69.825-320Zm130 0Q187-320 178.5-328.625T170-350v-262q0-11 8.5-19.5T198-640h20q8.148 0 14.074 3.5T242-626l108 190v-174q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T410-610v264q0 10.833-7.583 18.417Q394.833-320 384-320h-19q-7.333 0-12.667-3.5Q347-327 343-333L230-531v181q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Zm370 0Q557-320 548.5-328.625T540-350v-230h-60q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T480-640h180q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T660-580h-60v230q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM760-320q-12.75 0-21.375-8.625T730-350v-260q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T790-610v230h100q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T890-320H760Z"
		/>
	</svg>
);
