import { ReactNode } from 'react';

export const symbol_account_balance_wallet: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-233v53-600 547Zm0 113q-24.75 0-42.375-17.625T120-180v-600q0-24.75 17.625-42.375T180-840h600q24.75 0 42.375 17.625T840-780v134h-60v-134H180v600h600v-133h60v133q0 24.75-17.625 42.375T780-120H180Zm358-173q-30.525 0-52.263-21.444Q464-335.888 464-366v-227q0-30.112 21.737-51.556Q507.475-666 538-666h270q30.525 0 52.263 21.444Q882-623.112 882-593v227q0 30.112-21.737 51.556Q838.525-293 808-293H538Zm284-60v-253H524v253h298Zm-169.765-63Q679-416 697.5-434.958 716-453.917 716-481q0-26.25-19-44.625T652-544q-26 0-45 18.375T588-481q0 27.083 18.735 46.042Q625.471-416 652.235-416Z"
		/>
	</svg>
);
