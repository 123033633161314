import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import { useCurrentAccountUserIds, useCurrentAccountWards } from 'pkg/identity';
import * as models from 'pkg/api/models';
import { EventSeriesUserResponseStatus } from 'pkg/api/models/event_series_user';

import StatusLabel from 'routes/event/single/event-users/StatusLabel';
import UserRow from 'routes/event/series/event_series_users/user-row';

import SectionTitle from 'components/SectionTitle';

import Column from 'components/layout/column';
import Row from 'components/layout/row';

import Button from 'design/button';

interface InvitedListProps {
	variant: 'participants' | 'organizers';

	eventSeries: models.eventSeries.EventSeries;
	eventSeriesUsers: models.eventSeriesUser.EventSeriesUser[];

	toggleInviteModal: () => void;
}

export default function InvitedList({
	variant,

	eventSeries,
	eventSeriesUsers,

	toggleInviteModal,
}: InvitedListProps) {
	const activeUserIds = useCurrentAccountUserIds();
	const wardAccountRelations = useCurrentAccountWards();
	const wardUserIds =
		models.accountRelation.findAllUserIds(wardAccountRelations);
	const allMyEventSeriesUsers = models.eventSeriesUser.findAllEventSeriesUsers(
		eventSeriesUsers,
		[...activeUserIds, ...wardUserIds]
	);

	const sortedUsers = models.eventSeriesUser.sortEventSeriesUsers(
		eventSeriesUsers,
		allMyEventSeriesUsers,
		eventSeries
	);

	const usersAccepted = sortedUsers
		.filter((user) => user.status === EventSeriesUserResponseStatus.Accepted)
		.map((user) => (
			<UserRow key={user.id} eventSeries={eventSeries} eventSeriesUser={user} />
		));

	const usersDeclined = sortedUsers
		.filter((user) => user.status === EventSeriesUserResponseStatus.Declined)

		.map((user) => (
			<UserRow key={user.id} eventSeries={eventSeries} eventSeriesUser={user} />
		));

	const usersNoResponse = sortedUsers
		.filter((user) => user.status === EventSeriesUserResponseStatus.Pending)
		.map((user) => (
			<UserRow key={user.id} eventSeries={eventSeries} eventSeriesUser={user} />
		));

	const usersCount = [...usersAccepted, ...usersDeclined, ...usersNoResponse]
		.length;

	const sectionTitle =
		variant === 'organizers'
			? t('Organizers ({number})', {
					number: usersCount,
				})
			: t('Participants ({number})', {
					number: usersCount,
				});

	return (
		<Column spacing={styles.spacing._6}>
			<Column spacing={styles.spacing._2}>
				<Row columns="1fr auto" align="center" justifyContent="center">
					<SectionTitle>{sectionTitle}</SectionTitle>
					{models.canEdit(eventSeries) && (
						<Button
							inline
							icon="add"
							label={t('Add')}
							onClick={toggleInviteModal}
						/>
					)}
				</Row>
			</Column>

			{usersAccepted.length > 0 && (
				<Column spacing={styles.spacing._5}>
					<StatusLabel
						color={styles.palette.green[400]}
						label={t('Accepted ({number})', { number: usersAccepted.length })}
					/>
					{usersAccepted}
				</Column>
			)}

			{usersDeclined.length > 0 && (
				<Column spacing={styles.spacing._5}>
					<StatusLabel
						color={styles.palette.red[400]}
						label={t('Declined ({number})', {
							number: usersDeclined.length,
						})}
					/>
					{usersDeclined}
				</Column>
			)}

			{usersNoResponse.length > 0 && (
				<Column spacing={styles.spacing._5}>
					<StatusLabel
						label={t('No response ({number})', {
							number: usersNoResponse.length,
						})}
						color={styles.palette.orange[400]}
					/>
					{usersNoResponse}
				</Column>
			)}
		</Column>
	);
}
