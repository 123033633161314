import { t } from '@transifex/native';
import { ChangeEvent, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { FormCategoryPayload } from 'pkg/actions/services/form_categories';

import * as Card from 'components/Card';
import StepModal, { Step } from 'components/step-modal';

import * as Input from 'components/form/inputs';

import * as Form from 'styles/Form';

interface CategoryProps {
	onDone: (
		category: FormCategoryPayload,
		postCreate: (formCategoryId: number) => void
	) => Promise<void>;
	onClose: () => void;
}

const Category: React.FC<React.PropsWithChildren<CategoryProps>> = ({
	onDone,
	onClose,
}) => {
	const [categoryData, setCategoryData] = useState<FormCategoryPayload>({
		groupId: 0,
		title: '',
		description: '',
	});
	const { setValue, watch, reset } = useFormContext();
	const category = watch('formCategoryId');

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const name =
			event.target.name === 'categoryTitle' ? 'title' : 'description';

		setCategoryData({
			...categoryData,
			[name]: event.target.value,
		});
	};

	const postCreate = (formCategoryId: number) => {
		setValue('formCategoryId', formCategoryId);
		reset({ formCategoryId }, { keepDirty: true });
	};

	const handleNext = async () => {
		await onDone(categoryData, postCreate);
		setValue('categoryTitle', '');
		setValue('categoryDescription', '');
	};

	const handleClose = () => {
		if (category === 'new_category') {
			setValue('formCategoryId', '');
		}

		onClose();
	};

	return (
		<StepModal onClose={handleClose}>
			<Step
				title={t(`Create new category`)}
				skipBody
				canGoNext={!!categoryData.title}
				nextLabel={t('Done')}
				onNext={handleNext}>
				<Card.Body>
					<Form.Wrapper>
						<Input.Group label={t(`Title`)}>
							<Input.Field
								name="categoryTitle"
								onChange={handleChange}
								testid="form.create.category_title"
							/>
						</Input.Group>
						<Input.Group label={t(`Description`)} optional>
							<Input.Field
								name="categoryDescription"
								onChange={handleChange}
								testid="form.create.category_description"
							/>
						</Input.Group>
					</Form.Wrapper>
				</Card.Body>
			</Step>
		</StepModal>
	);
};

export default Category;
