import { ReactNode } from 'react';

export const symbol_text_fields_alt: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M110-180h740q13 0 21.5 8.5T880-150q0 13-8.5 21.5T850-120H110q-13 0-21.5-8.5T80-150q0-13 8.5-21.5T110-180Zm670-120v-520q0-8 6-14t14-6q8 0 14 6t6 14v520q0 8-6 14t-14 6q-8 0-14-6t-6-14ZM292-431l-49 129q-4 10-12.5 16t-19.5 6q-19 0-29-15.5t-3-32.5l192-488q5-11 14-17.5t20-6.5h30q11 0 20 6.5t14 17.5l192 489q7 17-3.5 32T629-280q-11 0-20-6t-13-17l-48-128H292Zm24-63h208L422-765h-4L316-494Z"
		/>
	</svg>
);
