import { t } from '@transifex/native';
import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { List } from 'immutable';

import * as palette from 'pkg/config/palette';

import rgba from 'pkg/rgba';

import Icon from 'components/icon';
import Avatar from 'components/avatar';

import * as iconStyles from 'components/icon/styles.css';
import * as Input from 'components/form/inputs';

import InlinePlaceholder from 'design/placeholders/inline';
import Button from 'design/button';

const ListItem = styled.li`
	display: grid;
	grid-gap: 1rem;
	grid-template-columns: 2rem auto 1.4rem;
	padding: var(--spacing-3);
	align-items: center;
	position: relative;
	user-select: none;
	cursor: pointer;
	border-bottom: 1px solid var(--palette-gray-300);

	@media (hover: hover) {
		&:hover {
			background: var(--palette-gray-200);
		}
	}

	${({ theme }) =>
		theme.darkMode &&
		css`
			@media (hover: hover) {
				&:hover {
					background: var(--palette-gray-700);
				}
			}
		`}

	${(props) =>
		props.selected &&
		css`
			font-weight: var(--font-weight-semibold);
		`}
`;

const ListAvatar = styled(Avatar)`
	width: 2rem;
	height: 2rem;
`;

const ListItemStatus = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: inset 0 0 0 2px var(--palette-gray-400);
	border-radius: 100rem;
	transition: all 250ms ease-in-out;

	.${iconStyles.icon} {
		width: 100%;
		height: 100%;
		color: ${palette.blue[500]};
		opacity: 0;
		transition: opacity 250ms ease-in-out;
	}

	${(props) =>
		props.selected &&
		css`
			background: ${palette.white};
			color: ${palette.blue[500]};
			box-shadow: inset 0 0 0 2px var(--palette-blue-500);

			.${iconStyles.icon} {
				opacity: 1;
			}
		`};
`;

const SelectableListContainer = styled.div`
	line-height: 1.2;

	flex: ${(props) => (props.flex ? 1 : '0 1 auto')};

	overflow: auto;

	ul {
		&:last-child {
			${ListItem}:last-child {
				border-bottom: 0;
			}
		}
	}
`;

const SelectedContainer = styled.div`
	margin: 0;
	padding: 0.5rem 0;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
`;

const CloseIcon = styled(Icon)`
	padding: 5px;
	font-size: 1.5rem;
	background: var(--palette-red-400);
	color: var(--palette-white);
	border-radius: 10rem;
	box-shadow: 0 0 0 2px var(--palette-white);
	position: absolute;
	z-index: 2;
	right: 0;
	top: 0;
`;

const SelectedList = styled.ol`
	padding: 1rem;
	margin-bottom: 1rem;
	display: grid;
	grid-gap: 1rem;
	grid-auto-flow: column;
	grid-auto-columns: 4rem;
`;

const SelectedListItem = styled.li`
	display: grid;
	grid-gap: 0.5rem;
	grid-auto-flow: row;
	grid-auto-rows: 4rem auto;
	position: relative;
	cursor: pointer;
	border-radius: var(--radius-3);

	${CloseIcon} {
		transition: all 150ms ease-in-out;
	}

	@media (hover: hover) {
		&:hover {
			color: var(--palette-blue-400);
			background: linear-gradient(
				to bottom,
				${rgba(palette.blue[400], 0)},
				${rgba(palette.blue[400], 0.1)}
			);

			${CloseIcon} {
				background: var(--palette-black);
				transform: scale(1.2);
			}
		}
	}
`;

const SelectedListItemLabel = styled.span`
	text-align: center;
`;

const ListControls = styled.div`
	padding: 10px;
	background: var(--palette-gray-100);
	border-bottom: 1px solid var(--palette-gray-300);
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	justify-content: center;
	position: sticky;
	top: 0;
	z-index: 3;

	${({ theme }) =>
		theme.darkMode &&
		css`
			background: var(--palette-gray-800);
			border-bottom-color: var(--palette-gray-600);
		`}
`;

const ListControlsSelector = styled.div`
	display: grid;
	grid-auto-flow: column;
	grid-gap: 5px;
	align-items: center;
	justify-content: end;
`;

const ListMeta = styled.div`
	padding-left: 10px;
	font-weight: var(--font-weight-normal);
	font-size: var(--font-size-sm);
	color: var(--palette-gray-600);
`;

const GroupLabel = styled.h3`
	font-weight: var(--font-weight-bold);
	font-size: var(--font-size-sm);
	letter-spacing: 1px;
	color: var(--palette-gray-400);
	text-transform: uppercase;
	margin-top: 2rem;
	padding: 0 1rem;

	&:first-of-type {
		margin-top: 0;
	}
`;

const SearchWrapper = styled.div`
	padding: 1rem;
`;

const NOOP = () => {};

/**
 * @deprecated
 * There is a copy of this component that is converted to typescript and use the new models
 * in 'components/user/selectable_list
 */

class SelectableList extends Component {
	state = {
		filterValue: '',
	};

	get hasUsers() {
		return this.users.size > 0;
	}

	get users() {
		return this.props.users.sort((a, b) =>
			a.fullName.localeCompare(b.fullName)
		);
	}

	get selectedUsers() {
		return this.props.selectedUserIds.map((id) =>
			this.users.find((u) => u.get('id') === id)
		);
	}

	get numSelectedUsers() {
		return this.props.selectedUserIds.length;
	}

	get groupedUsers() {
		const { groupBy, users } = this.props;
		const groupedIds = [];
		const groups = [];

		if (groupBy !== null) {
			for (const [label, userIds] of Object.entries(groupBy)) {
				groupedIds.push(...userIds);

				const groupUsers = new List(
					userIds.map((userId) => users.find((u) => u.get('id') === userId))
				)
					.filter((n) => n)
					.sort((a, b) => a.fullName.localeCompare(b.fullName));

				if (groupUsers.size > 0) {
					groups.push(
						<Fragment key={`group:${label}`}>
							<GroupLabel>{label}</GroupLabel>
							<ul>{groupUsers.map(this.renderListItem)}</ul>
						</Fragment>
					);
				}
			}
		}

		const remainingUsers = users.filter(
			(n) => !groupedIds.includes(n.get('id'))
		);

		if (remainingUsers.size > 0) {
			const remainingGroup = (
				<Fragment key="group:uncategorized">
					<GroupLabel>{this.props.ungroupedLabel}</GroupLabel>
					<ul>{remainingUsers.map(this.renderListItem)}</ul>
				</Fragment>
			);

			if (this.props.ungroupedPosition === 'above') {
				groups.unshift(remainingGroup);
			} else {
				groups.push(remainingGroup);
			}
		}

		return groups;
	}

	get filteredUsers() {
		const { filterValue } = this.state;

		const searchResults = this.users.filter((user) => {
			const searchableFields = ['firstName', 'lastName']
				.map((n) => user.get(n))
				.join(' ')
				.toLowerCase();

			return searchableFields.includes(filterValue);
		});

		return searchResults.map(this.renderListItem);
	}

	isSelected(user) {
		return this.props.selectedUserIds.includes(user.get('id'));
	}

	select(user) {
		if (this.isSelected(user) === false) {
			let { selectedUserIds } = this.props;

			selectedUserIds.push(user.get('id'));

			this.props.onChange(this.props.selectedUserIds);
		}
	}

	selectAll() {
		const selectedUserIds = this.users.map((n) => n.get('id')).toArray();

		this.props.onChange(selectedUserIds);
	}

	deselect(user) {
		let { selectedUserIds } = this.props;

		selectedUserIds = selectedUserIds.filter((id) => id != user.get('id'));

		this.props.onChange(selectedUserIds);
	}

	deselectAll() {
		this.props.onChange([]);
	}

	selectItem = (event) => {
		const userId = event.currentTarget.getAttribute('data-user-id');
		const user = this.users.find((u) => u.get('id') == userId);

		if (!this.props.multipleChoice) {
			this.props.onChange([user.get('id')]);
		} else if (this.isSelected(user)) {
			this.deselect(user);
		} else {
			this.select(user);
		}
	};

	handleSelectAll = () => this.selectAll();

	handleDeselectAll = () => this.deselectAll();

	renderListItem = (user) => (
		<ListItem
			key={`selectableList-item-${user.get('id')}`}
			data-user-id={user.get('id')}
			onClick={this.props.selectable ? this.selectItem : NOOP}
			selected={this.props.selectable && this.isSelected(user)}
			data-testid="selectable_user_list.item">
			<ListAvatar user={user} />
			<div>{user.fullName}</div>
			{this.props.selectable && (
				<ListItemStatus selected={this.isSelected(user)}>
					<Icon name="check-filled" />
				</ListItemStatus>
			)}
		</ListItem>
	);

	renderListItem(user) {
		const { selectable } = this.props;

		return (
			<ListItem
				key={`selectableList-item-${user.get('id')}`}
				data-user-id={user.get('id')}
				onClick={selectable ? this.selectItem : NOOP}
				selected={selectable && this.isSelected(user)}>
				<ListAvatar user={user} />
				<div>{user.fullName}</div>
				{selectable && (
					<ListItemStatus selected={this.isSelected(user)}>
						<Icon name="check-filled" />
					</ListItemStatus>
				)}
			</ListItem>
		);
	}

	get listItems() {
		if (!this.hasUsers) {
			return (
				<ul>
					<li>
						<InlinePlaceholder>
							<Icon name="nav-members" />
							{this.props.missingEntitiesText}
						</InlinePlaceholder>
					</li>
				</ul>
			);
		}

		if (this.state.filterValue !== '') {
			return <ul>{this.filteredUsers}</ul>;
		}

		if (this.props.groupBy !== null) {
			return this.groupedUsers;
		}

		return <ul>{this.users.map(this.renderListItem)}</ul>;
	}

	get listPreview() {
		if (!this.props.showPreview || this.numSelectedUsers === 0) return null;

		return (
			<SelectedContainer>
				<SelectedList>
					{this.selectedUsers.map((user) => (
						<SelectedListItem
							key={`selected-list-user-${user.get('id')}`}
							onClick={() => this.deselect(user)}>
							<CloseIcon name="close" />
							<Avatar user={user} />
							<SelectedListItemLabel>
								{user.get('firstName')}
							</SelectedListItemLabel>
						</SelectedListItem>
					))}
				</SelectedList>
			</SelectedContainer>
		);
	}

	get listControls() {
		if (!this.props.showControls || !this.props.multipleChoice) return null;

		return (
			<ListControls>
				<ListMeta>
					{t(`{num} selected users`, {
						num: this.numSelectedUsers,
					})}
				</ListMeta>
				<ListControlsSelector>
					{this.numSelectedUsers > 0 && (
						<Button
							transparent
							caution
							icon="unmark-all-complete"
							onClick={this.handleDeselectAll}>
							{t(`Clear selection`)}
						</Button>
					)}
					<Button
						transparent
						icon="mark-all-complete"
						onClick={this.handleSelectAll}>
						{t(`Select all`)}
					</Button>
				</ListControlsSelector>
			</ListControls>
		);
	}

	handleFilterInput = (value) => {
		this.setState({ filterValue: value.toLocaleLowerCase().trim() });
	};

	get listSearch() {
		if (!this.props.showFilter) return null;

		return (
			<SearchWrapper>
				<Input.Field
					autoComplete="off"
					value={this.state.filterValue}
					onChange={this.handleFilterInput}>
					<Input.Prefix inline>
						<Icon name="search" />
					</Input.Prefix>
				</Input.Field>
			</SearchWrapper>
		);
	}

	render() {
		return (
			<SelectableListContainer>
				{this.listControls}
				{this.listSearch}
				{this.listPreview}
				{this.listItems}
			</SelectableListContainer>
		);
	}
}

SelectableList.propTypes = {
	users: PropTypes.instanceOf(List),
	multipleChoice: PropTypes.bool,
	showControls: PropTypes.bool,
	showPreview: PropTypes.bool,
	showFilter: PropTypes.bool,
	groupBy: PropTypes.object,
	ungroupedLabel: PropTypes.string,
	ungroupedPosition: PropTypes.oneOf(['above', 'below']),
	onChange: PropTypes.func,
	selectable: PropTypes.bool,
};

SelectableList.defaultProps = {
	users: List([]),
	selectedUserIds: [],
	multipleChoice: false,
	showControls: false,
	showPreview: false,
	showFilter: false,
	groupBy: null,
	missingEntitiesText: t(`No users available`),
	ungroupedLabel: t(`Ungrouped users`),
	ungroupedPosition: 'below',
	onChange: NOOP,
	selectable: true,
};

export default SelectableList;
