import { ReactNode } from 'react';

export const symbol_fluid: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M520-40q-28.875 0-49.438-20.563Q450-81.124 450-110v-92q-106-11-178-90t-72-188v-380q0-24.75 17.625-42.375T260-920h440q24.75 0 42.375 17.625T760-860v380q0 109-72 188t-178 90v102h220q12.75 0 21.375 8.675Q760-82.649 760-69.825 760-57 751.375-48.5T730-40H520Zm40-390h134q3-12 4.5-24.5T700-480v-50H550q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T550-590h150v-100H510q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T510-750h190v-110H260v350h110q32 0 59.5 10t47.5 29q22 21 42.5 31t40.5 10Zm-80 170q61 0 111.5-30t79.5-80H560q-29 0-56-10t-48-29q-23-21-44.5-31T370-450H262q11 81 72.5 135.5T480-260Zm-24-190Z"
		/>
	</svg>
);
