import { FC } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import { AccountFlags, AccountClearFlags } from 'pkg/api/models/account';
import * as models from 'pkg/api/models';
import * as actions from 'pkg/actions';
import { useFetchIdentity } from 'pkg/identity';

import * as Card from 'components/Card';
import SectionTitle from 'components/SectionTitle';
import { AdslotBoundary } from 'components/adslot';

import Column from 'components/layout/column';
import FormOption from 'components/form/Toggle';
import Row from 'components/layout/row';

import Button from 'design/button';

import * as css from './styles.css';

interface ThirdPartyServicesProps {
	account?: models.account.Account;
}

const ThirdPartyServices: FC<ThirdPartyServicesProps> = ({
	account,
}: ThirdPartyServicesProps): JSX.Element => {
	const fetchIdentity = useFetchIdentity();

	const hasOptOutFlag = models.account.hasFlag(
		account,
		AccountFlags.OptOutAnalytics
	);

	const updateMixpanelOptOut = async (): Promise<void> => {
		if (hasOptOutFlag) {
			await actions.accounts.patchAccount(account.id, {
				flags: [AccountClearFlags.OptOutAnalytics],
			});

			fetchIdentity();
		} else {
			await actions.accounts.patchAccount(account.id, {
				flags: [AccountFlags.OptOutAnalytics],
			});

			fetchIdentity();
		}
	};

	const handleAdPerferences = () => {
		if (window.__cmp) {
			window.__cmp('showScreen');
		}
	};

	return (
		<Column>
			<SectionTitle>{t('Third party services')}</SectionTitle>
			<Card.Base $noBorder>
				<Card.Body>
					{t(
						"We use various third party services to give you improved support and to better understand how you use 360Player. If you don't want us to track your usage, you can opt out of these services here."
					)}
				</Card.Body>
				<Card.Divider />
				<Card.Body>
					<FormOption
						standAlone
						label="Mixpanel"
						description={t(
							'We use Mixpanel to track anonymized usage patterns.'
						)}
						isActive={!hasOptOutFlag}
						onChange={updateMixpanelOptOut}
					/>
				</Card.Body>
				<AdslotBoundary>
					<Card.Divider />
					<Card.Body>
						<Row columns="1fr auto">
							<Column spacing={styles.spacing._2}>
								<strong>{t('Ad preferences')}</strong>
								<span className={css.hint}>
									{t('You can review and update your preferences any time')}
								</span>
							</Column>
							<Button onClick={handleAdPerferences}>{t('Manage')}</Button>
						</Row>
					</Card.Body>
				</AdslotBoundary>
			</Card.Base>
		</Column>
	);
};

export default ThirdPartyServices;
