import styled, { css } from 'styled-components';

import * as styles from 'pkg/config/styles';

import * as labelStyles from 'components/label/styles.css';
import Column from 'components/layout/column';

export const ItemColumn = styled(Column)<{ isGroup?: boolean }>`
	p {
		line-height: 1;
	}

	strong {
		font-weight: var(--font-weight-semibold);
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	span {
		font-size: var(--font-size-sm);
		color: var(--palette-gray-500);
		line-height: 1.35;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	.${labelStyles.label} {
		margin-top: var(--spacing-1);
	}

	${({ isGroup }) =>
		isGroup &&
		css`
			@media ${styles.breakpoint.small} {
				strong {
					font-size: var(--font-size-sm);
				}

				span {
					font-size: var(--font-size-xs);
				}
			}
		`}
`;

export const Group = styled(Column)`
	&:not(:last-of-type) {
		padding-bottom: var(--spacing-8);
		margin-bottom: var(--spacing-8);
		border-bottom: 1px solid var(--palette-gray-300);
	}

	&:empty {
		padding: 0;
		margin: 0;
		height: 0px;
		border-bottom: none;
	}
`;
