import * as endpoints from 'pkg/api/endpoints/auto';
import * as sdk from 'pkg/core/sdk';

export const getGroup = (groupId: number, queryParams: any): Promise<any> => {
	return sdk.get(endpoints.Groups.Show(groupId), queryParams);
};

export const updateGroup = (groupId: number, data: any): Promise<any> => {
	return sdk.patch(endpoints.Groups.Update(groupId), {}, data);
};

export const removeGroup = (groupId: number): Promise<any> =>
	sdk.destroy(endpoints.Groups.Delete(groupId));

export const createGroup = (payload: any): Promise<any> =>
	sdk.post(endpoints.Groups.Create(), {}, payload);

export const getChildren = (
	groupId: number,
	queryParams: any
): Promise<any> => {
	return sdk.get(endpoints.Groups.ShowChildren(groupId), queryParams);
};

export const searchChildren = (
	groupId: number,
	queryParams: any
): Promise<any> => {
	return sdk.get(endpoints.Groups.SearchAll(groupId), queryParams);
};

export const updateUser = (
	groupId: number,
	userId: number,
	data: any
): Promise<any> =>
	sdk.patch(endpoints.Groups.UpdateUser(groupId, userId), {}, data);

export const setStripeConnection = (groupId: number, data: any): Promise<any> =>
	sdk.post(endpoints.Stripe.CreateConnectAccountLink(groupId), {}, data);

export const getTaxRates = (groupId: number): Promise<any> =>
	sdk.get(endpoints.TaxRates.Index(groupId));

export interface CreateTaxPayload {
	display_name: string;
	inclusive: boolean;
	percentage: number;
	active: boolean;
	description: string;
	jurisdiction: string;
}

export const createTax = (
	groupId: number,
	payload: CreateTaxPayload
): Promise<any> => sdk.post(endpoints.TaxRates.Create(groupId), {}, payload);

export interface UpdateTaxPayload {
	display_name: string;
	active: boolean;
	description: string;
	jurisdiction: string;
}

export const updateTax = (
	groupId: number,
	taxRateId: number,
	payload: UpdateTaxPayload
): Promise<any> =>
	sdk.patch(
		endpoints.TaxRates.Update(groupId, taxRateId.toString()),
		{},
		payload
	);

export const removeTax = (groupId: number, taxRateId: number): Promise<any> =>
	sdk.destroy(endpoints.TaxRates.Delete(groupId, taxRateId.toString()));

export const getCurrencies = (queryParams: any): Promise<any> =>
	sdk.get(endpoints.Payments.Currencies(), queryParams);
