import { ReactNode } from 'react';

export const symbol_thumbs_up_down: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M60-400q-24 0-42-18T0-460v-270q0-10 3-18t11-17l145-145q6.882-7 15.294-10.5t16.824-3.5Q211-924 225.5-908.5 240-893 235-870l-21 110h216q21.25 0 35.625 14.375T480-710v39q0 7-1 12.5t-2 7.5l-93 220q-6.079 14.639-19.539 22.819Q351-400 330-400H60Zm270-60 90-209v-31H139l28-133L60-725v265h270Zm200 260q-21.25 0-35.625-14.375T480-250v-39q0-7 1-12.5t2-7.5l93-220q6-15 19.5-23t34.5-8h270q24 0 42 18t18 42v270q0 10-3 18t-11 17L801-50q-6.882 7-15.294 10.5T768.882-36Q749-36 734.5-51.5 720-67 725-90l21-110H530Zm100-300-90 209v31h281l-28 133 107-108v-265H630ZM60-472v-253 253Zm840-16v253-253Z"
		/>
	</svg>
);
