import { ReactNode } from 'react';

export const symbol_specific_gravity: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M262-80q-23.25 0-40.5-14.93Q204.25-109.859 202-133l-78-714q-2-13.5 6.5-23.25T153-880h654q14 0 22.5 9.75T836-847l-78 714q-2.25 23.141-19.5 38.07Q721.25-80 698-80H262Zm-54.164-550H378q20.44-19 46.72-29.5Q451-670 480-670t55.28 10.5Q561.56-649 582-630h170l21-190H187l20.836 190ZM480-430q38 0 64-26.438 26-26.437 26-63.562 0-38-26-64t-63.5-26q-37.5 0-64 26T390-520.5q0 37.5 26.438 64Q442.875-430 480-430ZM214-570l47.964 430H698l47-430H621q5 12 7 24.033 2 12.034 2 25.967 0 63-43.5 106.5T480-370q-63 0-106.5-43.5T330-520q0-13.933 2-25.967Q334-558 339-570H214Zm48 430h77-9 300-9 77-436Z"
		/>
	</svg>
);
