import { useT } from '@transifex/react';

import { MixedStateSetter } from 'pkg/hooks/useMixedState';
import { formatToCurrency } from 'pkg/i18n/formatters';
import { useCurrentOrganization } from 'pkg/identity';
import * as models from 'pkg/api/models';

import {
	DiscountFormState,
	DiscountType,
} from 'routes/payments/discounts/create';

import * as Input from 'components/form/inputs';
import Column from 'components/layout/column';
import Row from 'components/layout/row';
import Section from 'components/form/Section';
import InfoBox from 'components/form/info-box';
import ButtonCount from 'components/form/button-counter';

export enum RecurringConfigOptions {
	Initial = 'initial',
	Every = 'every',
	SetAmount = 'setAmount',
}

interface RecurringSectionProps {
	discountValue: string;
	recurringConfigValue: RecurringConfigOptions;
	formState: DiscountFormState;
	setFormState: MixedStateSetter<DiscountFormState>;
	discount?: models.discount.Discount;
}

export default function RecurringSection({
	formState,
	setFormState,
	discountValue,
	recurringConfigValue,
	discount,
}: RecurringSectionProps) {
	const t = useT();
	const org = useCurrentOrganization();

	const discountValueInCurrency = formatToCurrency(
		Number.parseFloat(discountValue) || 0,
		org.currency
	);

	const incrementMaxRecurringCount = () => {
		setFormState({ maxRecurringCount: formState.maxRecurringCount + 1 });
	};

	const decrementMaxRecurringCount = () => {
		if (formState.maxRecurringCount === 1) {
			return;
		}

		setFormState({
			maxRecurringCount: formState.maxRecurringCount - 1,
		});
	};

	const handleSplitAmountChange = () =>
		setFormState({
			splitAmount: !formState.splitAmount,
		});

	const disableInput = !!discount;

	const recurringConfigSelect = (
		<Input.Select
			name="recurringConfig"
			defaultValue={recurringConfigValue}
			disabled={disableInput}>
			<option value={RecurringConfigOptions.Initial}>
				{t('Only apply to the first payment')}
			</option>
			<option value={RecurringConfigOptions.Every}>
				{t('Apply discount to every installment')}
			</option>
			<option value={RecurringConfigOptions.SetAmount}>
				{t('Select a number of installments')}
			</option>
		</Input.Select>
	);

	let recurringConfigRow = recurringConfigSelect;

	if (recurringConfigValue === RecurringConfigOptions.SetAmount) {
		recurringConfigRow = (
			<Column>
				<Row columns="1fr max-content">
					{recurringConfigSelect}
					<ButtonCount
						count={formState.maxRecurringCount}
						decrement={decrementMaxRecurringCount}
						increment={incrementMaxRecurringCount}
						disabled={disableInput}
					/>
				</Row>
				{formState.type === DiscountType.FixedAmount && (
					<Column>
						<Input.Control
							type="radio"
							checked={!formState.splitAmount}
							onChange={handleSplitAmountChange}
							label={t(
								'Discount selected number of installments ({amount} each)',
								{ amount: discountValueInCurrency }
							)}
							disabled={disableInput}
						/>
						<Input.Control
							type="radio"
							checked={formState.splitAmount}
							onChange={handleSplitAmountChange}
							label={t('Divide {amount} over selected number of installments', {
								amount: discountValueInCurrency,
							})}
							description={t(
								'If the installment plan has fewer than {installmentAmount} payments, the discount will be evenly distributed across those payments.',
								{ installmentAmount: formState.maxRecurringCount }
							)}
							disabled={disableInput}
						/>
					</Column>
				)}
			</Column>
		);
	}

	return (
		<Section title={t('Installments and subscriptions')}>
			<Column>
				<InfoBox color="green">
					{t(
						'Configure how this discount code behaves on recurring payments. You can apply this to every payment or a limited number within the plan.'
					)}
				</InfoBox>
				{recurringConfigRow}
			</Column>
		</Section>
	);
}
