import { Fragment, memo, useState } from 'react';
import styled, { css } from 'styled-components';

import * as styles from 'pkg/config/styles';

import { onlyEmojis } from 'pkg/emoji';
import * as models from 'pkg/api/models';
import * as actions from 'pkg/actions';
import { useCurrentAccountUserIds } from 'pkg/identity';

import { useChatContext } from 'routes/chat/view';

import QuotedMessage from 'containers/chat/QuotedMessage';
import LastReadAvatars, { ReplyAvatars } from 'containers/chat/LastReadAvatars';
import UserContainer from 'containers/chat/UserContainer';
import MessageContent, {
	MessageContentProps,
} from 'containers/chat/MessageContent';
import { clearChatCache } from 'containers/chat/utils';
import * as chatMessageActionsStyles from 'containers/chat/chat_message_actions/styles.css';
import {
	MessageFailed,
	MessageSending,
	MessageSent,
} from 'containers/chat/SentStatusMessages';

import RelativeTimeImport from 'components/RelativeDateTime';
import { FormattedContent } from 'components/formatted-content';
import Avatar from 'components/avatar';

import { useThreadContext } from 'components/chat/thread';
import Attachment from 'components/chat/AttachmentThreadItem';
import Reactions, {
	ReactionPayload,
	findBySlugAndUserId,
} from 'components/emoji/reactions';

const MessageAuthor = styled.div`
	flex: 0 0 100%;
	color: var(--palette-gray-500);
	font-weight: var(--font-weight-semibold);
	font-size: var(--font-size-xs);
	margin-bottom: var(--spacing-2);
`;

const Wrapper = styled.div<{
	isLastGroupItem: boolean;
	align: string;
	failed: boolean;
}>`
	display: block;
	margin-bottom: ${({ isLastGroupItem }) =>
		isLastGroupItem ? styles.spacing._5 : styles.spacing._1};
	color: var(--palette-black);

	@media (hover: hover) {
		&:hover {
			.${chatMessageActionsStyles.wrapper} {
				opacity: 1;
			}
		}
	}

	${({ align }) =>
		align === 'right' &&
		css`
			padding-right: 25px;
			padding-left: 52px;
			color: var(--palette-white);

			${MessageAuthor} {
				text-align: right;
			}
		`}

	${({ align }) =>
		align === 'left' &&
		css`
			padding-left: 52px;
			padding-right: 25px;
		`}

	${({ failed }) =>
		failed &&
		css`
			cursor: pointer;
		`}
`;

const MessageContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	position: relative;

	> ${Avatar} {
		position: absolute;
		left: -42px;
		bottom: 0;
		height: 32px;
		width: 32px;
	}
`;

const AvatarStatusWrapper = styled.div`
	margin: var(--spacing-2) 0 var(--spacing-3);
	min-height: 16px;
`;

const EmojiContent = styled.span`
	${styles.font.pair('4xl')};
`;

const Timestamp = styled.div<{ expanded: boolean; align: string }>`
	color: var(--palette-gray-500);
	font-weight: var(--font-weight-semibold);
	font-size: var(--font-size-xs);
	position: relative;
	height: ${({ expanded }) => (expanded ? styles.font.lineHeight.base : 0)};
	overflow: hidden;

	transition: height 150ms;
	display: flex;
	align-items: center;

	${({ align }) =>
		align === 'right' &&
		css`
			justify-content: flex-end;
			text-align: right;
		`}
`;

const MessageReactions = styled.div<{ align: string }>`
	margin-top: var(--spacing-3);
	margin-bottom: var(--spacing-5);
	display: flex;
	align-items: center;
	color: var(--palette-black);

	${({ align }) =>
		align === 'right' &&
		css`
			justify-content: flex-end;
			text-align: right;
		`}

	& + ${ReplyAvatars} {
		margin-top: -10px;
	}
`;

const AuthorName = ({ user }: { user?: models.user.User }) => {
	if (!user) {
		return null;
	}

	return <MessageAuthor>{models.user.fullName(user)}</MessageAuthor>;
};

interface ReplyProps {
	isLastGroupItem: boolean;
	isFirstGroupItem: boolean;
	isLastMessage: boolean;
	showAuthor: boolean;
	activeUserId: number;
	isMonitoredByLegalGuardian: boolean;

	attachmentOnClick: (attachment: models.attachment.Attachment) => void;
	onContentReflow: () => void;
	goToMessage: (messageId: number) => void;

	chat: models.chat.Chat;
	item: models.chatMessage.ChatMessage;
	activeMembership: models.membership.Membership;
	lastReadUsers: models.user.User[];
}

const Reply = memo(
	({
		item,
		lastReadUsers,
		isLastGroupItem,
		isFirstGroupItem,
		isLastMessage,
		showAuthor,
		attachmentOnClick,
		activeUserId,
		onContentReflow,
		goToMessage,
		isMonitoredByLegalGuardian,
		activeMembership,
		chat,
	}: ReplyProps) => {
		const threadContext = useThreadContext();
		const chatContext = useChatContext();

		const activeUserIds = useCurrentAccountUserIds();
		const selfChatUser = models.chat.findMyChatUser(chat, activeUserIds);

		const [isExpanded, setIsExpanded] = useState(false);
		const currentAccountUserIds = useCurrentAccountUserIds();

		const handleReplyItemClick = () => {
			if (isAttachment) return;

			setIsExpanded(!isExpanded);
			onContentReflow();
		};

		const attachment = item.attachment;

		const onCreatedTemporaryMessage = async (
			message: models.chatMessage.ChatMessage
		) => {
			threadContext.chatMessageCollection.addRecord(message);

			if (chatContext.state.quotedMessageId !== 0) {
				chatContext.setState({
					quotedMessageId: 0,
				});
			}
		};

		const onComplete = async (chatMessage: models.chatMessage.ChatMessage) => {
			if (chatMessage.failed) {
				threadContext.chatMessageCollection.replaceRecord(
					chatMessage,
					'clientMessageId'
				);
			}

			clearChatCache(chat.id);
		};

		const handleReaction = async (reaction: ReactionPayload) => {
			const chatReaction = findBySlugAndUserId(
				item.reactions,
				reaction.slug,
				activeUserId
			);

			if (!chatReaction) {
				const tempId = Number.MAX_SAFE_INTEGER;

				const reactionPayload = {
					emojiSlug: reaction.slug,
					chatId: chat.id,
					chatMessageId: item.id,
					userId: activeUserId,
				};

				const chatMessage = threadContext.chatMessageCollection.records.find(
					(chatMessage: models.chatMessage.ChatMessage) =>
						chatMessage?.id === reactionPayload.chatMessageId
				);

				const tempReaction: models.chatReaction.ChatReaction = {
					id: tempId,
					...reactionPayload,
					user: selfChatUser.user,
				};

				// @NOTE Add a temporary reaction
				chatMessage.reactions = [
					...(chatMessage.reactions || []),
					tempReaction,
				];

				threadContext.chatMessageCollection.replaceRecord(chatMessage);

				const newReaction = await actions.chatReactions.create(reactionPayload);

				// Filter out matching reaction without links and temp reaction
				const nextReactions = [...chatMessage.reactions].filter(
					(reaction: models.chatReaction.ChatReaction) =>
						reaction.id !== newReaction.id && reaction.id !== tempId
				);

				chatMessage.reactions = [...nextReactions, newReaction];

				threadContext.chatMessageCollection.replaceRecord(chatMessage);
			} else {
				actions.chatReactions.remove(chatReaction);
			}
		};

		const createChatMessage = () => {
			models.chatMessage.createChatMessage({
				chatMessage: item,
				chatUser: models.chat.findMyChatUser(chat, currentAccountUserIds),
				chat,
				onCreatedTemporaryMessage,
				onComplete,
			});
		};

		let align: 'left' | 'right' =
			item.userId === activeUserId ? 'right' : 'left';

		if (
			activeMembership.targetUserId === item.userId &&
			isMonitoredByLegalGuardian
		) {
			align = 'right';
		}

		const isAttachment = item.attachmentId > 0;

		const messageContainerProps = {
			showAuthor,
			isExpanded,
		};

		const emojiOnly = onlyEmojis(item.content);
		const testid = 'chat.reply';

		const messageContentProps: MessageContentProps = {
			isFirstGroupItem,
			isLastGroupItem,
			align,
			isAttachment,
			attachment,
			emojiOnly,
			isMonitoredByLegalGuardian,
			item,
			testid,
			chat,
			onClick: () => {
				return;
			},
		};

		if (item.failed) {
			messageContentProps.onClick = createChatMessage;
		} else {
			messageContentProps.onClick = handleReplyItemClick;
		}

		const avatarStatus = (
			<Fragment>
				{item.failed && <MessageFailed onClick={createChatMessage} />}

				{lastReadUsers && <LastReadAvatars users={lastReadUsers} />}

				{!lastReadUsers && isLastMessage && !item.failed && !item.temporary && (
					<MessageSent />
				)}

				{!lastReadUsers &&
					!item.failed &&
					!item.delivered &&
					item.temporary && <MessageSending />}
			</Fragment>
		);

		return (
			<Fragment>
				<Wrapper
					isLastGroupItem={isLastGroupItem}
					align={align}
					failed={item.failed}
					id={`chat-message-${item.id}`}>
					{item.quotedMessageId && (
						<QuotedMessage
							message={item}
							align={align}
							activeUserId={activeUserId}
							onClick={goToMessage}
							chat={chat}
						/>
					)}
					<MessageContainer {...messageContainerProps}>
						{!item.quotedMessageId && showAuthor && (
							<UserContainer item={AuthorName} user={item.user} />
						)}

						{item.archivedAt ? (
							<FormattedContent
								wrapWith={<MessageContent {...messageContentProps} />}
								raw={models.chatMessage.showMessageContent(selfChatUser, item)}
							/>
						) : (
							<Fragment>
								{isAttachment && attachment && (
									<MessageContent {...messageContentProps}>
										<Attachment
											onClick={attachmentOnClick}
											attachment={attachment}
											onLoad={onContentReflow}
											isFirstGroupItem={isFirstGroupItem}
											isLastGroupItem={isLastGroupItem}
											align={align}
										/>
									</MessageContent>
								)}

								{emojiOnly && (
									<MessageContent {...messageContentProps}>
										<EmojiContent>
											{models.chatMessage.showMessageContent(
												selfChatUser,
												item
											)}
										</EmojiContent>
									</MessageContent>
								)}

								{!emojiOnly && !isAttachment && (
									<FormattedContent
										wrapWith={<MessageContent {...messageContentProps} />}
										raw={models.chatMessage.showMessageContent(
											selfChatUser,
											item
										)}
									/>
								)}
							</Fragment>
						)}

						{isLastGroupItem && align === 'left' && (
							<UserContainer item={Avatar} user={item.user} />
						)}
					</MessageContainer>

					<Timestamp expanded={isExpanded} align={align}>
						{isExpanded && <RelativeTimeImport dateTime={item.createdAt} />}
					</Timestamp>

					{item.reactions?.length > 0 && (
						<MessageReactions align={align}>
							<Reactions
								align={align}
								currentUserId={activeUserId}
								reactions={item.reactions}
								onReaction={handleReaction}
							/>
						</MessageReactions>
					)}

					{isLastMessage ? (
						<AvatarStatusWrapper>{avatarStatus}</AvatarStatusWrapper>
					) : (
						avatarStatus
					)}
				</Wrapper>
			</Fragment>
		);
	}
);

export default Reply;
