import { ReactNode } from 'react';

export const symbol_mobile_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M829-49 49-843q-8-9-8.5-21t8.5-21q9-9 21-9t21 9L872-91q9 9 8.5 21T871-49q-9 9-21 9t-21-9ZM200-732l60 61v481h433l66 67v23q1 25-16.919 42.5T699-40H260q-24.75 0-42.375-17.625T200-100v-632Zm60 602v30h440v-30H260Zm0 0v30-30Zm44-640q-13.077 0-21.538-8.675-8.462-8.676-8.462-21.5 0-12.825 8.625-21.325T304-830h396v-30H250q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T250-920h450q24.75 0 42.375 18T760-860v479q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T700-381v-389H304Zm146-60Z"
		/>
	</svg>
);
