const icon = {
	name: 'av-replay',
	viewBox: '0 0 25 25',
	paths: [
		'M15.1954 6.71538L15.9113 4.97929L18.3096 9.01526L13.7635 10.1876L14.4794 8.45147C13.0981 7.88184 11.5471 7.88428 10.1676 8.45823C8.78805 9.03219 7.69303 10.1306 7.12341 11.512C6.55379 12.8933 6.55622 14.4443 7.13018 15.8238C7.70413 17.2033 8.8026 18.2984 10.1839 18.868C11.5652 19.4376 13.1163 19.4352 14.4958 18.8612C15.8753 18.2873 16.9703 17.1888 17.5399 15.8075L19.276 16.5234C18.5165 18.3652 17.0565 19.8298 15.2171 20.595C13.3778 21.3603 11.3097 21.3636 9.46799 20.6041C7.62624 19.8446 6.16162 18.3846 5.39635 16.5452C4.63107 14.7058 4.62783 12.6378 5.38732 10.796C6.14682 8.95429 7.60684 7.48968 9.4462 6.7244C11.2856 5.95913 13.3536 5.95588 15.1954 6.71538Z',
		'M10.8025 10.8313V16.3995L15.1775 13.6154L10.8025 10.8313Z',
	],
};

export default icon;
