// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Rqa6xuvl3dbihpO6g0sg {
	color: var(--palette-green-500);
	font-weight: var(--font-weight-semibold);
}
`, "",{"version":3,"sources":["webpack://./components/account/invite-parent-modal/styles.css"],"names":[],"mappings":"AAAA;CACC,+BAA+B;CAC/B,wCAAwC;AACzC","sourcesContent":[".inviteLinkCopied {\n\tcolor: var(--palette-green-500);\n\tfont-weight: var(--font-weight-semibold);\n}\n"],"sourceRoot":""}]);
// Exports
export var inviteLinkCopied = `Rqa6xuvl3dbihpO6g0sg`;
export default ___CSS_LOADER_EXPORT___;
