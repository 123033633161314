import { ReactNode } from 'react';

import { cssClasses } from 'pkg/css/utils';

import * as css from './styles.css';

interface BigSelectButtonProps {
	active: boolean;
	text?: string;
	children: ReactNode;

	onClick: () => void;
}

// Check this naming, maybe this should be placed somewhere ells? Button for selecting right/left foot
// and publish/draft when creating an assessment
const BigSelectButton = ({
	onClick,
	active,
	text,
	children,
}: BigSelectButtonProps) => {
	return (
		<div
			onClick={onClick}
			className={cssClasses(css.wrapper, active ? css.active : '')}>
			{children}
			{text && <span className={css.text}>{text}</span>}
		</div>
	);
};

export default BigSelectButton;
