import { ReactNode } from 'react';

export const symbol_soap: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M629.899-660Q605-660 587.5-677.601t-17.5-42.5Q570-745 587.601-762.5t42.5-17.5Q655-780 672.5-762.399t17.5 42.5Q690-695 672.399-677.5t-42.5 17.5Zm210.012 0Q811-660 790.5-680.589q-20.5-20.588-20.5-49.5Q770-759 790.589-779.5q20.588-20.5 49.5-20.5Q869-800 889.5-779.411q20.5 20.588 20.5 49.5Q910-701 889.411-680.5q-20.588 20.5-49.5 20.5ZM699.882-820Q679-820 664.5-834.618q-14.5-14.617-14.5-35.5Q650-891 664.618-905.5q14.617-14.5 35.5-14.5Q721-920 735.5-905.382q14.5 14.617 14.5 35.5Q750-849 735.382-834.5q-14.617 14.5-35.5 14.5ZM230-80q-63 0-106.5-43.5T80-230v-216q0-28 13.5-53t36.5-40l304-196q11-8 21.5-4.5T474-728q14.815 14.933 17.407 35.467Q494-672 483-654l-59 94h346q12.75 0 21.375 8.625T800-530q0 12-8.625 21T770-500H370q-18 0-26.5-15.5t.5-30.5l63-101-244 159q-11 7-17 17.862-6 10.862-6 24.138v216q0 37.125 26.438 63.562Q192.875-140 230-140h500q12.75 0 21.375 8.625T760-110q0 12-8.625 21T730-80H230Zm620-280H510q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T510-420h340q12.75 0 21.375 8.625T880-390q0 12-8.625 21T850-360Zm-40 140H510q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T510-280h300q12.75 0 21.375 8.625T840-250q0 12-8.625 21T810-220ZM310-330Z"
		/>
	</svg>
);
