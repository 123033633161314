import { t } from '@transifex/native';
import { SyntheticEvent } from 'react';

import * as models from 'pkg/api/models';

import * as Input from 'components/form/inputs';

interface TaxSelectProps {
	value?: string | number;

	handleChange: (event: SyntheticEvent<HTMLSelectElement>) => void;

	taxRates: models.taxRate.TaxRate[];
}

// Should replace 'components/taxes/TaxSelect.tsx' component because it uses redux.
export default function TaxSelect({
	value = '',
	handleChange,
	taxRates,
}: TaxSelectProps) {
	return (
		<Input.Select value={value} onChange={handleChange}>
			<option value="">{t('No tax selected')}</option>
			{taxRates.map((tax) => (
				<option key={tax.id} value={tax.id}>
					{tax.display_name} {tax.percentage}% {tax.inclusive && t('incl.')}
				</option>
			))}
		</Input.Select>
	);
}
