import { ReactNode } from 'react';

export const symbol_pill_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M840-615q0 45-17 86t-49 73l-74 74q-9 9-21 9t-21-9q-9-9-9-21t9-21l74-74q22.857-22.718 35.429-53.388Q780-582.058 780-615q0-69-48-117t-116.764-48q-33.236 0-63.885 12.571Q520.703-754.857 498-732l-74 74q-9 9-21 9t-21-9q-9-9-9-21t9-21l74-74q32-32 73-49t86-17q94 0 159.5 65.5T840-615ZM345-180q32 0 63.014-12.571Q439.027-205.143 462-228l90-90-234-234-89.701 89.701Q205-439 192.5-408.422 180-377.843 180-345q0 69 48 117t117 48Zm249-96-90 90q-32 32-73 49t-86 17q-94 0-159.5-65.5T120-345q0-45 17-86t49-73l90-90L85-785q-9-9-9-21t9-21q9-9 21.5-9t21.5 9l707 707q9 9 8.5 21T834-78q-9 9-21 9t-21-9L594-276ZM435-435Zm85-85Z"
		/>
	</svg>
);
