import { Record } from 'immutable';
import { schema } from 'normalizr';

import { eventSeries } from 'pkg/models/schemas';

export interface IEventSeriesAttachments {
	attachment: number;
	attachmentId: number;
	eventSeriesId: number;
	id: number;
}

interface IEventSeries {
	description: string;
	duration: number;
	events: number[];
	groupId: number;
	id: number;
	location: string;
	rRuleString: string;
	status: string;
	timezone: string;
	title: string;
	type: string;
	userId: number;
	seriesUsers: number[];
	isPrivate: boolean;
	isPublic: boolean;
	eventSeriesAttachments: IEventSeriesAttachments[];
	meetBeforeMinutes: number;
	links: {
		delete?: string;
		edit?: string;
	};
}

const EventSeriesProps: IEventSeries = {
	description: null,
	duration: null,
	events: [],
	groupId: null,
	id: null,
	location: null,
	rRuleString: null,
	status: null,
	timezone: null,
	title: null,
	type: null,
	userId: null,
	isPrivate: false,
	isPublic: false,
	seriesUsers: [],
	eventSeriesAttachments: [],
	links: {},
	meetBeforeMinutes: 0,
};

class EventSeries
	extends Record(EventSeriesProps, 'EventSeriesRecord')
	implements IEventSeries
{
	canEdit(): boolean {
		return !!this.get('links').edit;
	}

	canDelete(): boolean {
		return !!this.get('links').delete;
	}

	static normalizr(): schema.Entity {
		return eventSeries;
	}
}

export default EventSeries;
