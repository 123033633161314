import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as actions from 'pkg/actions';
import * as models from 'pkg/api/models';

import { useEventContext } from 'routes/event/single';

import Icon from 'components/icon';

import Row from 'components/layout/row';

import * as ContextMenu from 'design/context_menu';

import * as css from './styles.css';

interface UserActionsProps {
	event: models.event.Event;
	eventUser: models.eventUser.EventUser;
}

export default function UserActions({
	event,
	eventUser,
}: UserActionsProps): JSX.Element {
	const eventCtx = useEventContext();

	const lokHasHandicap = models.eventUser.lokHasHandicap(eventUser);
	const lokLeader = models.eventUser.lokLeader(eventUser);

	const setEventUserFlags = async (
		flags: models.eventUser.EventUserFlags[]
	) => {
		const ok = await actions.eventUsers.patchEventUsers(event.id, {
			users: [
				{
					userId: eventUser.userId,
					attendanceStatus: eventUser.attendanceStatus,
					comment: eventUser.comment,
					status: eventUser.status,
					flags,
				},
			],
		});

		if (ok) {
			eventCtx.eventEndpointResponse.replaceInRecord(event, 'users', {
				...eventUser,
				flags,
			} as models.eventUser.EventUser);
		}
	};

	const handleToggleHandicap = () => {
		let flags = [...eventUser.flags];

		if (lokHasHandicap) {
			flags = flags.filter(
				(i) => i !== models.eventUser.EventUserFlags.LokHasHandicap
			);
		} else {
			flags.push(models.eventUser.EventUserFlags.LokHasHandicap);
		}

		setEventUserFlags(flags);
	};

	const handleToggleLeader = () => {
		let flags = [...eventUser.flags];

		if (lokLeader) {
			flags = flags.filter(
				(i) => i !== models.eventUser.EventUserFlags.LokLeader
			);
		} else {
			flags.push(models.eventUser.EventUserFlags.LokLeader);
		}

		setEventUserFlags(flags);
	};

	return (
		<Row spacing={styles.spacing._3}>
			<ContextMenu.Menu
				toggleWith={
					<div className={css.userWrapper}>
						<Icon name="edit" fill={styles.palette.black} />
						<Icon
							name="chevron"
							rotate="90deg"
							fill={styles.palette.gray[400]}
						/>
					</div>
				}>
				<ContextMenu.Item onClick={handleToggleLeader} caution={lokLeader}>
					<ContextMenu.ItemIcon name={lokLeader ? 'delete' : 'add'} />
					{lokLeader ? t('Remove leader') : t('Set leader')}
				</ContextMenu.Item>
				<ContextMenu.Item
					onClick={handleToggleHandicap}
					caution={lokHasHandicap}>
					<ContextMenu.ItemIcon name={lokHasHandicap ? 'delete' : 'add'} />
					{lokHasHandicap ? t('Remove handicap flag') : t('Set handicap flag')}
				</ContextMenu.Item>
			</ContextMenu.Menu>
		</Row>
	);
}
