import DOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';

import * as selectors from 'pkg/selectors';
import * as actions from 'pkg/actions';

import Flash, { FlashAnimationWrapper, FlashVariant } from 'components/flash';

const Snackbar = (): JSX.Element => {
	const dispatch = useDispatch();

	const status = useSelector((state) => selectors.flash.getStatus(state));
	const message = useSelector((state) => selectors.flash.getMessage(state));
	const icon = useSelector((state) => selectors.flash.getFlashIcon(state));
	const isVisible = useSelector((state) => selectors.flash.getVisible(state));

	if (message === null) {
		return null;
	}

	const handleSetVisibility = (value: boolean) =>
		dispatch(actions.flashes.setVisibility(value));

	let variant: FlashVariant = 'info';

	if (status >= 400) {
		variant = 'error';
	} else if (status === 200) {
		variant = 'success';
	}

	return DOM.createPortal(
		<FlashAnimationWrapper isVisible={isVisible}>
			<Flash
				icon={icon}
				variant={variant}
				title={message.title}
				message={message.message}
				additionalData={message.additionalData}
				testid={`status_${status}`}
				setVisibility={handleSetVisibility}
			/>
		</FlashAnimationWrapper>,
		document.getElementById('flash-portal-container')
	);
};

export default Snackbar;
