import { t } from '@transifex/native';

import User from 'pkg/models/user';

import * as models from 'pkg/api/models';

import {
	AttachmentRow,
	CustomFieldRow,
} from 'containers/user/profile/info-contact/DataRow';

import SectionTitle from 'components/SectionTitle';
import * as Card from 'components/Card';

import Column from 'components/layout/column';

const CustomUserFields = ({ user }: { user: User }): JSX.Element => {
	return (
		<Column>
			<SectionTitle icon="attachment">
				{t('Additional information')}
			</SectionTitle>
			<Card.Base $noBorder>
				<Card.Body>
					{user.fields
						.filter(
							(f) =>
								f.visibility.includes(models.userFields.Visibility.Profile) &&
								f.type !== models.userFields.UserFieldType.Reserved
						)
						.map((field: models.user.Field) =>
							field.attachments ? (
								<AttachmentRow
									key={field.key}
									label={field.label}
									attachments={field.attachments}
								/>
							) : (
								<CustomFieldRow key={field.key} field={field} />
							)
						)}
				</Card.Body>
			</Card.Base>
		</Column>
	);
};

export default CustomUserFields;
