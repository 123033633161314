import { ReactNode } from 'react';

export const symbol_forward: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M794-524 623-696q-9-9-9-21.5t9-21.5q9-9 21-9t21 9l194 194q5 5 7 10t2 11q0 6-2 11t-7 10L666-310q-9 9-21.5 9t-21.5-9q-9-9-9.5-21t8.5-21l172-172Zm-210 30H279q-60 0-99 39t-39 99v126q0 13-8.5 21.5T111-200q-13 0-21.5-8.5T81-230v-126q0-85 56.5-141.5T279-554h305L443-696q-9-9-9-21.5t9-21.5q9-9 21-9t21 9l194 194q5 5 7 10t2 11q0 6-2 11t-7 10L486-310q-9 9-21.5 9t-21.5-9q-9-9-9.5-21t8.5-21l142-142Z"
		/>
	</svg>
);
