import { useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { t } from '@transifex/native';

import { MAX_FILE_UPLOADS } from 'pkg/config/files';

import { AttachmentCreatePayload } from 'pkg/actions/attachments';

import * as models from 'pkg/api/models';
import * as actions from 'pkg/actions';
import { useClipboardAttachments } from 'pkg/hooks/attachments';
import { AttachmentVisibility } from 'pkg/api/models/attachment';

import { useSmallScreen } from 'components/MediaQuery';

import File from 'components/form/File';
import FormAttachment from 'components/attachment/FormItem';

import Button from 'design/button';

import * as css from './styles.css';

interface UploadAttachmentStateOptions {
	onProgress?: (a: models.attachment.Attachment) => void;
	onComplete?: (a: models.attachment.Attachment) => void;
}

interface UploadAttachmentState {
	upload: (payload: AttachmentCreatePayload) => void;
	deleteAttachment: (attachment: models.attachment.Attachment) => void;
	set: (attachment: models.attachment.Attachment) => void;
	reset: () => void;
	attachments: newAttachment[];
}

export function useUploadAttachmentsState({
	onProgress,
	onComplete,
}: UploadAttachmentStateOptions): UploadAttachmentState {
	const [newAttachments, setNewAttachments] = useState<newAttachment[]>([]);

	const storeAttachmentInState = (
		attachment: models.attachment.Attachment,
		bytesLoaded: number
	) => {
		setNewAttachments((current) => {
			const index = current.findIndex((i) => i.attachment.id === attachment.id);
			if (index === -1) {
				return [
					...current,
					{
						attachment: attachment,
						bytesUploaded: bytesLoaded,
					},
				];
			}

			current[index] = {
				bytesUploaded: bytesLoaded,
				attachment: attachment,
			};

			return [...current];
		});
	};

	const handleUpload = (payload: AttachmentCreatePayload) => {
		actions.attachments
			.upload(payload, {
				onProgress: (bytesLoaded, _, attachment) => {
					storeAttachmentInState(attachment, bytesLoaded);
					onProgress?.(attachment);
				},
			})
			.then((attachment) => {
				if (attachment === null) {
					return;
				}

				storeAttachmentInState(attachment, attachment.size);
				onComplete?.(attachment);
			});
	};

	const handleDeleteNewFile = (attachment: models.attachment.Attachment) => {
		setNewAttachments(
			newAttachments.filter((a) => a.attachment.id !== attachment.id)
		);
	};

	return {
		upload: handleUpload,
		deleteAttachment: handleDeleteNewFile,
		set: (a: models.attachment.Attachment) => {
			storeAttachmentInState(a, a.size);
		},
		reset: () => {
			setNewAttachments([]);
		},
		attachments: newAttachments,
	};
}

export interface UseAttachmentsResults {
	attachments: models.attachment.Attachment[];
	FileUploader: JSX.Element;
	AttachmentItems: JSX.Element;
}

interface UseAttachmentsProps {
	attachments?: models.attachment.Attachment[];
	// Limit the attachment type for the upload, e.g. AttachmentTypeImage will allow image/*
	type?: models.attachment.AttachmentType;
	onRemoveAttachment?: (id: number) => Promise<boolean>;
	onNewAttachment?: (attachment: models.attachment.Attachment) => void;
	maxAttachments?: number;
}

interface newAttachment {
	attachment: models.attachment.Attachment;
	bytesUploaded: number;
}

const useAttachments = ({
	attachments = [],
	type,
	onRemoveAttachment = null,
	onNewAttachment = null,
	maxAttachments = MAX_FILE_UPLOADS,
}: UseAttachmentsProps): UseAttachmentsResults => {
	const context = useFormContext();

	const [prevAttachments, setPrevAttachments] = useState(attachments);

	const ref = useRef(null);

	const {
		upload,
		attachments: newAttachments,
		deleteAttachment,
	} = useUploadAttachmentsState({
		onProgress: onNewAttachment,
		onComplete: onNewAttachment,
	});

	const handleNew = async (files: File[]) => {
		for (let i = 0; i < files.length; i++) {
			upload({
				visibility: AttachmentVisibility.Hidden,
				file: files[i],
			});
		}
	};

	const onFilesPicked = (fileList: FileList) => {
		const files: File[] = [];
		for (let i = 0; i < fileList.length; i++) {
			files.push(fileList.item(i));
		}
		handleNew(files);
	};

	useClipboardAttachments(async (files) => {
		handleNew(files);
	});

	const handleDeleteNewFile = (attachment: models.attachment.Attachment) => {
		deleteAttachment(attachment);
		onRemoveAttachment?.(attachment.id);
	};

	const handleDeletePrevFile = async (attachmentId: number) => {
		if (context) context.reset();

		if (onRemoveAttachment) {
			const attachmentHasBeenRemoved = await onRemoveAttachment(attachmentId);

			if (attachmentHasBeenRemoved) {
				setPrevAttachments(
					prevAttachments.filter((attachment) => attachment.id !== attachmentId)
				);
			}
		} else {
			setPrevAttachments(
				prevAttachments.filter((attachment) => attachment.id !== attachmentId)
			);
		}
	};

	const handleAttachmentClick = () => ref.current.click();

	let uploadLabel = t('Add attachments');
	let fileType = '*';
	switch (type) {
		case models.attachment.AttachmentType.Image:
			uploadLabel = t('Add images');
			fileType = 'image/*';
			break;
		case models.attachment.AttachmentType.Video:
			uploadLabel = t('Add videos');
			fileType = 'video/*';
			break;
		case models.attachment.AttachmentType.Audio:
			uploadLabel = t('Add audio');
			fileType = 'audio/*';
			break;
	}
	const isSmallScreen = useSmallScreen();

	const allAttachments = [
		...newAttachments.map((a) => a.attachment),
		...prevAttachments,
	];

	let FileUploader = (
		<File inputRef={ref} accept={fileType} multiple onChange={onFilesPicked}>
			<Button
				secondary
				icon="add"
				onClick={handleAttachmentClick}
				testid="attachment.upload.button"
				block={isSmallScreen}>
				{uploadLabel}
			</Button>
		</File>
	);

	if (allAttachments.length >= maxAttachments) {
		FileUploader = null;
	}

	const AttachmentItems = (
		<div className={css.wrapper}>
			{newAttachments.map((a) => (
				<FormAttachment
					key={a.attachment.id}
					attachment={a.attachment}
					bytesUploaded={a.bytesUploaded}
					onDelete={handleDeleteNewFile}
				/>
			))}
			{prevAttachments?.map((attachment) => (
				<FormAttachment
					key={attachment.id}
					attachment={attachment}
					onDelete={() => handleDeletePrevFile(attachment.id)}
				/>
			))}
		</div>
	);

	return {
		attachments: allAttachments,
		FileUploader,
		AttachmentItems,
	};
};

export default useAttachments;
