import { Component } from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { t } from '@transifex/native';

import * as breakpoints from 'pkg/config/breakpoints';
import * as palette from 'pkg/config/palette';

import { getCardsItems } from 'pkg/selectors/match_events';

import rgba from 'pkg/rgba';

import Item from 'containers/group/statistics/cards/Item';

import CardBase, * as Card from 'components/Card';
import SectionTitle from 'components/SectionTitle';

import NoDataTableRow from 'components/group/statistics/NoDataTableRow';
import {
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableHeadCell as TblHeadCell,
} from 'components/group/statistics/Table';
import Column from 'components/layout/column';

import Button from 'design/button';

const Body = styled(Card.Body)`
	display: flex;
	justify-content: space-between;
	padding: 0;
	overflow: auto;

	@media ${breakpoints.small} {
		padding: 0;
	}
`;

const ViewMore = styled.div`
	display: flex;
	justify-content: center;
	border-top: 1px solid ${rgba(palette.card.innerDividerColor)};
	padding: 1rem;
`;

const TableHeadCell = styled(TblHeadCell)`
	cursor: pointer;
	position: relative;

	&:last-child {
		padding-right: 26px;
	}

	${(props) =>
		props.sortBy &&
		css`
			color: ${palette.black};
			font-weight: var(--font-weight-semibold);
		`}

	${(props) =>
		props.sortBy &&
		props.reverseItems &&
		css`
			&::after {
				top: 21px;
				right: -3px;
				border: solid transparent;
				content: ' ';
				height: 0;
				width: 0;
				position: absolute;
				pointer-events: none;
				border-color: rgba(0, 0, 0, 0);
				border-top-color: ${palette.black};
				border-width: 4px;
				margin-left: -4px;
			}

			&:last-child::after {
				right: 15px;
			}
		`}

	${(props) =>
		props.sortBy &&
		!props.reverseItems &&
		css`
			&::after {
				top: 17px;
				right: -3px;
				border: solid transparent;
				content: ' ';
				height: 0;
				width: 0;
				position: absolute;
				pointer-events: none;
				border-color: rgba(0, 0, 0, 0);
				border-bottom-color: ${palette.black};
				border-width: 4px;
				margin-left: -4px;
			}

			&:last-child::after {
				right: 16px;
			}
		`}
`;

class Cards extends Component {
	state = {
		numberOfItems: 10,
		sortBy: 'total',
		reverseItems: true,
		showLegendModal: false,
	};

	handleSortByRedCardsClick = () => {
		if (this.state.sortBy === 'card_red') {
			this.toggleReverseItems();
		} else {
			this.setState({ sortBy: 'card_red', reverseItems: true });
		}
	};

	handleSortByYellowCardsClick = () => {
		if (this.state.sortBy === 'card_yellow') {
			this.toggleReverseItems();
		} else {
			this.setState({ sortBy: 'card_yellow', reverseItems: true });
		}
	};

	handleSortByFirstNameClick = () => {
		if (this.state.sortBy === 'firstName') {
			this.toggleReverseItems();
		} else {
			this.setState({ sortBy: 'firstName', reverseItems: false });
		}
	};
	handleSortByTotalClick = () => {
		if (this.state.sortBy === 'total') {
			this.toggleReverseItems();
		} else {
			this.setState({ sortBy: 'total', reverseItems: true });
		}
	};

	handleViewMoreClick = () => {
		const numberOfItems = this.state.numberOfItems + 10;
		this.setState({ numberOfItems });
	};

	toggleReverseItems = () => {
		this.setState({ reverseItems: !this.state.reverseItems });
	};

	get viewMore() {
		if (
			this.props.cardsItems.isEmpty() ||
			this.props.cardsItems.size < this.state.numberOfItems
		) {
			return;
		}

		return (
			<ViewMore>
				<Button onClick={this.handleViewMoreClick}>{t('View more')}</Button>
			</ViewMore>
		);
	}

	render() {
		let cardsItems = this.props.cardsItems.sort((a, b) => {
			if (a.get(this.state.sortBy) < b.get(this.state.sortBy)) {
				return -1;
			}
			if (a.get(this.state.sortBy) > b.get(this.state.sortBy)) {
				return 1;
			}
			return 0;
		});

		if (this.state.reverseItems) {
			cardsItems = cardsItems.reverse();
		}

		cardsItems = cardsItems
			.take(this.state.numberOfItems)
			.valueSeq()
			.map((item, index) => (
				<TableRow key={index}>
					<Item item={item} index={index + 1} />
				</TableRow>
			));

		return (
			<Column>
				<SectionTitle icon="content_copy">{t('Cards')}</SectionTitle>
				<CardBase $noBorder>
					<Body>
						<Table>
							<TableHead>
								<TableRow>
									<TableHeadCell
										alignLeft
										grow
										onClick={this.handleSortByFirstNameClick}
										sortBy={this.state.sortBy === 'firstName'}
										reverseItems={this.state.reverseItems}>
										{t('Player')}
									</TableHeadCell>
									<TableHeadCell
										onClick={this.handleSortByRedCardsClick}
										sortBy={this.state.sortBy === 'card_red'}
										reverseItems={this.state.reverseItems}>
										{t('Red')}
									</TableHeadCell>
									<TableHeadCell
										onClick={this.handleSortByYellowCardsClick}
										sortBy={this.state.sortBy === 'card_yellow'}
										reverseItems={this.state.reverseItems}>
										{t('Yellow')}
									</TableHeadCell>
									<TableHeadCell
										onClick={this.handleSortByTotalClick}
										sortBy={this.state.sortBy === 'total'}
										reverseItems={this.state.reverseItems}>
										{t('Total')}
									</TableHeadCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{cardsItems.isEmpty() ? (
									<NoDataTableRow span="4" text={t('No players')} />
								) : (
									cardsItems
								)}
							</TableBody>
						</Table>
					</Body>
					{this.viewMore}
				</CardBase>
			</Column>
		);
	}
}

const mapStateToProps = (state, props) => {
	const statProps = { ...props.filters.toJS(), groupId: props.groupId };

	return {
		cardsItems: getCardsItems(state, statProps),
	};
};

export default connect(mapStateToProps)(Cards);
