import { useEffect, useRef, useState } from 'react';
import anime from 'animejs';
import { t } from '@transifex/native';

import * as json from 'pkg/json';
import { tlog } from 'pkg/tlog';

import { usePlayerState } from 'components/video-analytics/PlayerState';
import { usePlaybackState } from 'components/video-analytics/PlaybackState';
import { useCueState } from 'components/video-analytics/CueState';
import {
	ParsedAnnotations,
	useClipState,
} from 'components/video-analytics/ClipState';
import { toolConfig } from 'components/annotations/tools/ToolConfig';
import {
	ANNOTATION_PREFIX,
	MULTIPOINT,
	MULTIPOINT_CIRCLE_PREFIX,
} from 'components/annotations/constants';

export interface Annotation {
	tool: string;
	color?: string;
	text?: string;
	strokeWidth?: string;
	circleSize?: number;
	raised?: boolean; // draw line in a simulated trajectory path
	keyframes: Keyframe[];
}

export interface Keyframe {
	time: number;
	hide: boolean;
	left?: number; // default position
	top?: number;
	width?: number; // default size
	height?: number;
	points?: JSONObject;
	action?: string; // playback action (pause, speed)
	duration?: number; // playback action duration
	speed?: number; // playback speed
}

interface CoverSize {
	width: number;
	height: number;
}

export function useTimeline(coverSize: CoverSize): any {
	// store timeline instance in ref
	const timeline = useRef<anime.AnimeTimelineInstance>();

	// get some video state
	const { isPlaying, playbackRate, setPlaybackRate, duration, controller } =
		usePlayerState();
	const { currentTime } = usePlaybackState();
	const currentMs = currentTime * 1000;
	const { annotations } = useClipState();

	const changePlaybackRate = (rate: number) => {
		setPlaybackRate(rate);
		controller.setPlaybackRate(rate);
	};

	const [tooltipText, setTooltipText] = useState('');

	// create annotation timeline animations
	useEffect(() => {
		if (!annotations || !duration) return;
		const parsedAnnotations = json.parse<ParsedAnnotations>(annotations);

		const tl = anime.timeline({
			easing: 'linear',
			autoplay: false,
		});

		if (!parsedAnnotations) return;

		// set up all annotations
		Object.entries(parsedAnnotations)
			.filter(([, annotation]) => toolConfig[annotation.tool]) // filter out deprecated tools
			.forEach(([annotationId, a]) => {
				const annotation = a as Annotation;
				const visibilityTransitionDuration = 100;

				// set up keyframe animations
				annotation.keyframes.forEach((keyframe: Keyframe, ki: number) => {
					const targets = '.' + ANNOTATION_PREFIX + annotationId;
					const previousKeyframe = ki && annotation.keyframes[ki - 1];
					const isLastKeyframe = !annotation.keyframes[ki + 1];
					const config = toolConfig[annotation.tool];

					// convert relative value to pixel value
					const absoluteValue = Object.entries(
						keyframe.left ? keyframe : previousKeyframe
					).reduce((prev: JSONObject, [key, value]: any) => {
						if (key === 'left' || key === 'width') {
							value = value * coverSize.width;
						}
						if (key === 'top' || key === 'height') {
							value = value * coverSize.height;
						}
						prev[key] = value;

						return prev;
					}, {});

					// construct anime.js keyframe values
					const keyframeValues: any = {
						targets,
					};

					if (config.move && !config.multipoint) {
						keyframeValues.left = absoluteValue.left;
						keyframeValues.top = absoluteValue.top;
					}

					if (config.resize) {
						keyframeValues.width = absoluteValue.width;
						keyframeValues.height = absoluteValue.height;
					}

					const circleSize: any =
						annotation.circleSize ||
						config.preferences.circleSize?.defaultValue;

					if (!previousKeyframe) {
						// first keyframe, start position and quick fade in
						// position at beginning of timeline

						const start =
							keyframe.time / playbackRate - visibilityTransitionDuration;
						tl.add({ ...keyframeValues, opacity: 0, duration: 1 }, 0).add(
							{
								targets,
								opacity: 1,
								duration: visibilityTransitionDuration,
							},
							start
						);

						// multipoint start position
						if (config.multipoint && keyframe.points) {
							tl.add(
								{
									// target svg content
									targets: targets + ' .' + MULTIPOINT,
									d: generatePath(keyframe.points, {
										raised: annotation.raised,
									}),
									duration: visibilityTransitionDuration,
								},
								start
							);

							// animate corner circles
							if (config.preferences.circleCorners) {
								Object.values(keyframe.points).forEach((p: any, i) => {
									tl.add(
										{
											// target svg content
											targets: targets + ' .' + MULTIPOINT_CIRCLE_PREFIX + i,
											cx: p.left * 100, // plain circle is positioned by cx/cy
											cy: p.top * 100,
											x: p.left * 100 - circleSize, // animated circle is positioned by x/y
											y: p.top * 100 - circleSize,
											duration: visibilityTransitionDuration,
										},
										start
									);
								});
							}
						}
					} else {
						// set animation for following keyframes
						const durationSincePreviousKeyframe =
							(keyframe.time - previousKeyframe.time) / playbackRate;
						const previousKeyframeStart = previousKeyframe.time / playbackRate;

						// actual animation keyframe
						tl.add(
							{
								...keyframeValues,
								duration: durationSincePreviousKeyframe,
							},
							previousKeyframeStart
						);

						// animate multipoint path
						if (config.multipoint && keyframe.points) {
							tl.add(
								{
									// target svg content
									targets: targets + ' .' + MULTIPOINT,
									d: generatePath(keyframe.points, {
										raised: annotation.raised,
									}),
									duration: durationSincePreviousKeyframe,
								},
								previousKeyframeStart
							);

							// animate corner circles
							if (config.preferences.circleCorners) {
								Object.values(keyframe.points).forEach((p: any, i) => {
									tl.add(
										{
											// target svg content
											targets: targets + ' .' + MULTIPOINT_CIRCLE_PREFIX + i,
											cx: p.left * 100, // plain circle is positioned by cx/cy
											cy: p.top * 100,
											x: p.left * 100 - circleSize, // rotating circle is positioned by x/y
											y: p.top * 100 - circleSize,
											duration: durationSincePreviousKeyframe,
										},
										previousKeyframeStart
									);
								});
							}
						}
					}

					// add quick visibility transition if hidden status is opposite of last frame, or last frame was hidden and this frame has no explicit hidden status
					const toggleHidden =
						(keyframe.hide !== undefined &&
							keyframe.hide !== previousKeyframe.hide) ||
						(keyframe.hide === undefined && previousKeyframe.hide);

					if (toggleHidden) {
						tl.add(
							{
								...keyframeValues,
								opacity: keyframe.hide ? 0 : 1,
								duration: visibilityTransitionDuration,
							},
							keyframe.time / playbackRate - visibilityTransitionDuration
						);
					}

					if (isLastKeyframe) {
						// add fake keyframe at end of clip to keep object, animejs will discard it otherwise
						tl.add(
							{
								...keyframeValues,
								duration: (duration * 1000) / playbackRate,
							},
							keyframe.time / playbackRate
						);
					}
				});
			});

		// set timeline position
		tl.seek(currentMs / playbackRate);

		// store timeline in ref
		timeline.current = tl;

		// retrigger timeline when store has updated or annotation cover size has changed
	}, [duration, annotations, coverSize.width, coverSize.height, playbackRate]);

	// sync playing state and current position with video timeline
	const [isOutOfSync, setIsOutOfSync] = useState(false);
	const [isWithinClip, setIsWithinClip] = useState(false);
	const [lastTime, setLastTime] = useState(0);
	const { currentCue } = useCueState();

	useEffect(() => {
		const parsedAnnotations = json.parse<ParsedAnnotations>(annotations);
		const keyframes = parsedAnnotations?.playback?.keyframes || [];

		// did we pass a playback pause keyframe since last tick?
		if (
			keyframes.length &&
			lastTime > currentMs - 500 && // compare some times to avoid triggering pause when skipping in the timeline
			lastTime < currentMs
		) {
			// check for pause keyframe
			const pause = keyframes.find(
				(frame) =>
					frame.action === 'pause' &&
					frame.time > lastTime &&
					frame.time < currentMs
			);
			if (pause) {
				controller.pause();

				if (pause.duration) {
					// countdown to automatic resume
					const start = Date.now();
					const interval = setInterval(() => {
						const secondsToResume = Math.ceil(
							(pause.duration - Math.ceil(Date.now() - start)) / 1000
						);
						if (secondsToResume) {
							setTooltipText(
								t('Paused, resuming in {num}s', {
									_context: 'video/annotations',
									num: secondsToResume,
								})
							);
						}
					}, 100); // count every 1/10 second to get a better rounded second count

					// resume
					setTimeout(() => {
						clearInterval(interval);
						setTooltipText('');
						controller.play();
					}, pause.duration);
				}
			}
		}
		setLastTime(currentMs);

		// is the playback speed correct?
		if (keyframes.length) {
			const speed = keyframes.find(
				(frame) =>
					frame.action === 'speed' &&
					frame.time < currentMs &&
					frame.time + frame.duration > currentMs
			);

			const supposedSpeed = speed?.speed || 1;

			if (supposedSpeed !== playbackRate) {
				changePlaybackRate(supposedSpeed);
			}
		}

		// resync when animations and video is more than 100ms apart.
		const currentAnimeTime = timeline?.current?.currentTime;
		const syncDiff = Math.abs(currentAnimeTime - currentMs);
		if (syncDiff > 100) setIsOutOfSync(true);

		// is playhead within the clip duration?
		if (currentCue) {
			const startsAtMs = currentCue.startsAtMs || currentCue.startsAt * 1000;
			const endsAtMs = currentCue.endsAtMs || currentCue.endsAt * 1000;
			const withinClip = currentMs >= startsAtMs && currentMs <= endsAtMs;
			if (withinClip != isWithinClip) setIsWithinClip(withinClip);
		}
	}, [currentMs]);

	useEffect(() => {
		if (!timeline.current) return;

		// only sync if anime timeline duration is long enough = contains animations at current time
		// will restart from 0 if seeking past total duration
		if (isOutOfSync && timeline.current.duration > currentMs) {
			timeline.current.pause();
			timeline.current.seek(currentMs / playbackRate);
			setIsOutOfSync(false);
		}

		if (isPlaying) {
			timeline.current.play();
		} else {
			timeline.current.pause();
		}
	}, [isPlaying, isOutOfSync]);

	return {
		timeline,
		isWithinClip,
		tooltipText,
	};
}

export const generatePath = (points: unknown, options: any = {}): string => {
	if (!points) {
		tlog.message('missing points to generate path');
		return '';
	}

	const pointsArray = Object.values(points);

	return pointsArray.reduce((prev: string, curr: any, i): string => {
		let coordinate: string = prev ? 'L' : 'M';

		if (coordinate === 'L' && options.raised) {
			const halfwayHorizontally =
				((curr.left + Object.values(points)[i - 1].left) / 2) * 100;
			coordinate = `Q${halfwayHorizontally} 0 `;
		}

		coordinate += curr.left * 100 + ' ';
		coordinate += curr.top * 100 + ' ';

		return prev + coordinate;
	}, '');
};

export const extractPoints = (path: string): Array<any> => {
	if (!path) return [];
	// extract points and positions from path. backwards way of doing this, but easiest way to get currently calculated timeline animated position for each point.

	// scrub out raised path
	let points: any = path.replace(/Q\d+\.?\d* \d+\.?\d* /g, 'L');

	// extract points
	points = points.substr(1).split(' L');

	return points;
};
