import { ReactNode } from 'react';

export const symbol_move_group: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M260-200q-24 0-42-18t-18-42v-130q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T260-390v130h560v-500H260v130q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T200-630v-190q0-24 18-42t42-18h560q24 0 42 18t18 42v560q0 24-18 42t-42 18H260ZM140-80q-24 0-42-18t-18-42v-590q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-730v590h590q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q760-97 751.375-88.5T730-80H140Zm411-400H230q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T230-540h321l-72-73q-8-9-8.5-21t8.5-21q9-9 21-9t21 9l124 124q9 9 9 21t-9 21L521-365q-9 9-21 9t-21-9q-9-9-8.5-21t8.5-21l72-73Z"
		/>
	</svg>
);
