import { ReactNode } from 'react';

export const symbol_fingerprint_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M846-74 460-460q-34 7-55 32t-21 57q0 96 58.5 168.5T604-100q5 2 7 4.5t1 7.5q-1 5-4 7t-8 0q-108-28-172-106.5T364-371q0-36 22-65t58-40l-62-62q-48 26-75.5 70T279-371q0 78 26.5 138.5T396-106q3 4 3 7.5t-3 6.5q-3 3-7.5 3t-7.5-3q-64-67-93.5-133.5T258-371q0-57 29-105t80-77l-58-58q-66 41-102 104t-36 136q0 35 7.5 73.5T200-221q2 4 .5 7t-5.5 5q-5 2-9 .5t-6-5.5q-14-42-21-81.5t-7-75.5q0-75 37.5-142.5T294-626l-58-58q-29 20-53.5 43.5T138-589q-4 5-7.5 6t-7.5-1q-3-2-3.5-6t1.5-9q21-29 46.5-54t54.5-45L59-861q-4-4-4.5-9.5T59-881q5-5 10-5t10 5L866-94q4 4 4.5 9.5T866-74q-5 5-10 5t-10-5ZM696-361q-5 0-8-2.5t-3-7.5q0-81-60.5-136T481-562q-7 0-13 .5t-12 1.5l-18-18q11-2 21.5-3t21.5-1q92 0 158.5 61T706-371q0 5-2.5 7.5T696-361ZM481-880q65 0 127.5 16T728-819q5 2 5.5 6t-1.5 7q-2 3-5.5 5t-8.5 0q-55-27-115-42.5T481-859q-68 0-132 17.5T227-789l-14-14q60-38 129-57.5T481-880Zm0 101q108 0 203.5 46T843-601q3 5 2.5 8t-3.5 6q-3 3-7.5 3t-8.5-5q-59-82-150.5-126T481-759q-49 0-95 10t-89 30l-15-15q46-22 96-33.5T481-779Zm0 96q136 0 233.5 85T812-381q0 33-18 65.5T748-269l-14-15q23-12 40-40t17-57q0-123-91.5-201T481-660q-28 0-54.5 4.5T374-642l-17-17q29-11 60.5-17.5T481-683Zm206 518q-88 0-152.5-58.5T470-371q0-5 2.5-8t7.5-3q5 0 7.5 3t2.5 8q0 81 59.5 133.5T687-185q8 0 19-1t24-3q5-1 8 1.5t4 5.5q1 4-.5 7t-6.5 4q-18 5-31.5 5.5t-16.5.5Z"
		/>
	</svg>
);
