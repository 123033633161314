import { ReactNode } from 'react';

export const symbol_no_stroller: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M239.823-80Q215-80 197.5-97.677t-17.5-42.5Q180-165 197.677-182.5t42.5-17.5Q265-200 282.5-182.323t17.5 42.5Q300-115 282.323-97.5t-42.5 17.5ZM681-363l-60-60v-257l8 3-120 141-43-43 182-214q18-21 45-34t53-13q56 0 95 39t39 95v1q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.695t-8.5-21.549V-706q0-31-21.5-52.5T746-780q-16 0-29.5 7T690-749l-9 11v375Zm-41 43 42 42q-8.25 8.25-18.75 13.125T640-260H281q-26 0-36.5-23t6.5-43l137-160L47-827q-9-9-9-21.5t8.609-21.5q9.391-9 21.858-9 12.466 0 21.533 9l765 765q9 9 9 21t-9 21.391Q846-54 833.5-54T812-63L430-444 325-320h315ZM629.823-80Q605-80 587.5-97.677t-17.5-42.5Q570-165 587.677-182.5t42.5-17.5Q655-200 672.5-182.323t17.5 42.5Q690-115 672.323-97.5t-42.5 17.5ZM430-444Zm79-92Zm-78-78-42-42 95-112q-20-6-40.5-9t-42.5-3q-30 0-59.5 5.5T286-758l-45-46q34-17 76-26.5t84-9.5q49 0 95.5 12.5T583-793L431-614Zm-42-42Z"
		/>
	</svg>
);
