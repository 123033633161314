import { ReactNode } from 'react';

export const symbol_loyalty: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M863-404 557-97q-9 8.5-20.25 12.75T514.25-80Q503-80 492-84.5T472-97L98-472q-8-8-13-18.956-5-10.955-5-23.044v-306q0-24.75 17.625-42.375T140-880h307q12.07 0 23.386 4.875Q481.702-870.25 490-862l373 373q9.391 9 13.696 20.25Q881-457.5 881-446.25t-4.5 22.75Q872-412 863-404ZM516-138l306-307-375-375H140v304l376 378ZM245-664q21 0 36.5-15.5T297-716q0-21-15.5-36.5T245-768q-21 0-36.5 15.5T193-716q0 21 15.5 36.5T245-664Zm236 185Zm63 195 119-119q11-11 16-24.404 5-13.405 5-28.596 0-31.927-22.537-54.463Q638.927-533 607-533q-20.357 0-40.179 13Q547-507 523-478q-24-29-43.288-42-19.289-13-39.712-13-31.927 0-54.463 22.537Q363-487.927 363-456q0 15.191 5 28.596Q373-414 384-403l118 119q9 9 21 9t21-9Z"
		/>
	</svg>
);
