import { ReactNode } from 'react';

export const symbol_gate: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M109.825-280Q97-280 88.5-288.625T80-310v-340q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-650v340q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM230-200q-12.75 0-21.375-8.625T200-230v-380q0-63 44.062-106.5Q288.125-760 350-760h260q63 0 106.5 43.5T760-610v380q0 12.75-8.625 21.375T730-200H230Zm619.825-80Q837-280 828.5-288.625T820-310v-340q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T880-650v340q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM480-480ZM260-260h190v-190h-50q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T400-510h50v-190H350q-37.125 0-63.562 26Q260-648 260-610v350Zm250 0h190v-350q0-38-26-64t-64-26H510v190h50q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T560-450h-50v190Z"
		/>
	</svg>
);
