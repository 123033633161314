import { ReactNode } from 'react';

export const symbol_crisis_alert: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-117 62-214.5T310-842q1 16 3 30.5t6 31.5q-83 45-131 125.5T140-480q0 142 99 241t241 99q142 0 241-99t99-241q0-95-48-175T640-780q4-18 6.5-32.5T650-842q106 50 168 147.5T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-160q-100 0-170-70t-70-170q0-61 28-113.5t79-86.5q4 11 8.5 25.5T367-620q-32 26-49.5 62T300-480q0 75 52.5 127.5T480-300q75 0 127.5-52.5T660-480q0-42-17.5-78T593-620q6-19 10.5-33.5T612-680q51 34 79.5 86.5T720-480q0 100-70 170t-170 70Zm-10-390q-10 0-18-5.5T441-651l-40-131q-6-25-8.5-42.5T390-860q0-38 26-64t64-26q38 0 64 26t26 64q0 18-2.5 35.5T559-782l-40 131q-3 10-11 15.5t-18 5.5h-20Zm10 220q-29 0-49.5-20.5T410-480q0-29 20.5-49.5T480-550q29 0 49.5 20.5T550-480q0 29-20.5 49.5T480-410Z"
		/>
	</svg>
);
