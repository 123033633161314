// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TcA8JZ1nhMtglu2zO0Wg {
	display: grid;
	grid-template-columns: 26% 1fr auto;
	padding: var(--spacing-5) 0;
}

.TcA8JZ1nhMtglu2zO0Wg:first-of-type {
		padding-top: 0;
	}

.TcA8JZ1nhMtglu2zO0Wg:last-of-type {
		padding-bottom: 0;
	}

.GMWfelp0nEEsV5_y8SqM {
	font-weight: var(--font-weight-semibold);
}
`, "",{"version":3,"sources":["webpack://./components/card/card-row/styles.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,mCAAmC;CACnC,2BAA2B;AAS5B;;AAPC;EACC,cAAc;CACf;;AAEA;EACC,iBAAiB;CAClB;;AAGD;CACC,wCAAwC;AACzC","sourcesContent":[".container {\n\tdisplay: grid;\n\tgrid-template-columns: 26% 1fr auto;\n\tpadding: var(--spacing-5) 0;\n\n\t&:first-of-type {\n\t\tpadding-top: 0;\n\t}\n\n\t&:last-of-type {\n\t\tpadding-bottom: 0;\n\t}\n}\n\n.label {\n\tfont-weight: var(--font-weight-semibold);\n}\n"],"sourceRoot":""}]);
// Exports
export var container = `TcA8JZ1nhMtglu2zO0Wg`;
export var label = `GMWfelp0nEEsV5_y8SqM`;
export default ___CSS_LOADER_EXPORT___;
