export function CreateFolder(): string {
	return `/v1/group-link-folders`;
}

export function Delete(groupLinkFolderID: number): string {
	return `/v1/group-link-folders/${groupLinkFolderID}`;
}

export function UpdateFolder(groupLinkFolderID: number): string {
	return `/v1/group-link-folders/${groupLinkFolderID}`;
}
