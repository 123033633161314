import { ReactNode } from 'react';

export const symbol_shuffle: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M606-160q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T606-220h90L543-372q-9-9-9-21t9-21q9-9 21.5-9.5T586-415l154 153v-91q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T800-353v163q0 12.75-8.625 21.375T770-160H606Zm-436-10q-9-9-9-21t9-21l528-528h-92q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T606-800h164q12.75 0 21.375 8.625T800-770v163q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T740-607v-90L212-169q-9 9-21 8.5t-21-9.5Zm-1-579q-9-9-9-21.5t9-21.5q9-9 21.1-9 12.1 0 20.9 9l205 205q9 9 9.5 21.1.5 12.1-8.5 20.9-9 9-21.5 9t-21.5-9L169-749Z"
		/>
	</svg>
);
