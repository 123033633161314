// GENERATED CONTENTS. DO NOT EDIT!
export enum Action {
	UserView = 'user_view',
	UserViewFull = 'user_view_full',
	UserViewBillingAddress = 'user_view_billing_address',
	UserCreate = 'user_create',
	UserUpdate = 'user_update',
	UserRemove = 'user_remove',
	UserAddToGroup = 'user_add_to_group',
	UserCreateInvoice = 'user_create_invoice',
	UserCreateChat = 'user_create_chat',
	UserCreateUserLog = 'user_create_user_log',
	UserCreateUserProduct = 'user_create_user_product',
	UserCreateUserGoal = 'user_create_user_goal',
	UserCreateUserGroupPerformanceReview = 'user_create_user_group_performance_review',
	UserCreateUserRating = 'user_create_user_rating',
	UserListGroups = 'user_list_groups',
	UserListAssessments = 'user_list_assessments',
	UserListEvents = 'user_list_events',
	UserListGoals = 'user_list_goals',
	UserListOrders = 'user_list_orders',
	UserListRelationships = 'user_list_relationships',
	UserListNotes = 'user_list_notes',
	UserListPerformanceReview = 'user_list_performance_review',
	UserListProducts = 'user_list_products',
	UserListAttendanceStats = 'user_list_attendance_stats',
	UserListFormSubmissions = 'user_list_form_submissions',
	UserListSubscriptions = 'user_list_subscriptions',
	UserListVideos = 'user_list_videos',
	UserListPhysicalStrainStats = 'user_list_physical_strain_stats',
	UserListPaymentMethods = 'user_list_payment_methods',
	UserListActivePaymentMethods = 'user_list_active_payment_methods',
	PaymentMethodArchive = 'payment_method_archive',
	PaymentMethodMakePrimary = 'payment_method_make_primary',
	SubscriptionSetPaymentMethod = 'subscription_change_payment_method',
	SubscriptionView = 'subscription_view',
	SubscriptionUpdate = 'subscription_update',
	SubscriptionCancel = 'subscription_cancel',
	SubscriptionViewCustomerUser = 'subscription_view_customer_user',
	GroupView = 'group_view',
	GroupRemoveUser = 'group_remove_user',
	GroupBanUser = 'group_ban_user',
	GroupListOrders = 'group_list_orders',
	GroupUpdateUserCapabilities = 'group_update_user_capabilities',
	OrderView = 'order_view',
	OrderMarkAsPaid = 'order_mark_as_paid',
	OrderMarkAsUncollectible = 'order_mark_as_uncollectible',
	OrderMarkAsVoid = 'order_mark_as_void',
	OrderSendReminder = 'order_send_reminder',
	OrderSendReceipt = 'order_send_receipt',
	OrderSendInvoice = 'order_send_invoice',
	OrderRefund = 'order_refund',
	OrderRemove = 'order_remove',
	OrderDuplicate = 'order_duplicate',
	ExerciseDuplicate = 'exercise_duplicate',
	ExerciseView = 'exercise_view',
	AttachmentDuplicate = 'attachment_duplicate',
	OrganizationView = 'organization_view',
	OrganizationUpdateLOKSettings = 'organization_update_lok_settings',
	OrganizationViewPaymentConfig = 'organization_view_payment_config',
	UserFieldCategoryIndex = 'user_field_category_index',
	UserFieldCategoryCreate = 'user_field_category_create',
	UserFieldCategoryUpdate = 'user_field_category_update',
	UserFieldCategoryRemove = 'user_field_category_remove',
	OrganizationLinkCreate = 'organization_link_create',
	OrganizationLinkRemove = 'organization_link_remove',
	TrainingCollectionView = 'training_collection_view',
	TrainingCollectionShare = 'training_collection_share',
	TrainingCollectionRemove = 'training_collection_remove',
	TrainingCollectionUpdate = 'training_collection_update',
	TrainingCollectionContribute = 'training_collection_contribute',
	IdentityProviderCreate = 'identity_provider_create',
	IdentityProviderDuplicate = 'identity_provider_duplicate',
	UserRatingView = 'user_rating_view',
	UserRatingViewScores = 'user_rating_view_scores',
	UserRatingTogglePrimary = 'user_rating_toggle_primary',
	UserRatingCreate = 'user_rating_create',
	UserProductView = 'user_product_view',
	UserProductUpdate = 'user_product_update',
	UserProductDelete = 'user_product_delete',
	UserProductViewOrder = 'user_product_view_order',
	GroupPaymentConfigOnboard = 'group_payment_config_onboard',
	UserGoalUpdate = 'user_goal_update',
	UserGoalRemove = 'user_goal_remove',
	UserGroupPerformanceReviewUpdate = 'user_group_performance_review_update',
	UserGroupPerformanceReviewRemove = 'user_group_performance_review_remove',
	UserGroupPerformanceReviewView = 'user_group_performance_review_view',
}
