import { ReactNode } from 'react';

export const symbol_castle: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M40-180v-380q0-12.75 8.675-21.375Q57.351-590 70.175-590 83-590 91.5-581.375T100-560v50h110v-300q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T270-810v50h100v-50q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T430-810v50h100v-50q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T590-810v50h100v-50q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T750-810v300h110v-50q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T920-560v380q0 24.75-17.625 42.375T860-120H600q-12.75 0-21.375-8.625T570-150v-90q0-38-26-64t-64-26q-38 0-64 26t-26 64v90q0 12.75-8.625 21.375T360-120H100q-24.75 0-42.375-17.625T40-180Zm60 0h230v-60q0-63 43.5-106.5T480-390q63 0 106.5 43.5T630-240v60h230v-270H720q-12.75 0-21.375-8.625T690-480v-220H270v220q0 12.75-8.625 21.375T240-450H100v270Zm278-310h44.571q3.715 0 5.572-2.5Q430-495 430-498v-72q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T370-570v72q0 3 2.5 5.5t5.5 2.5Zm160 0h44.571q3.715 0 5.572-2.5Q590-495 590-498v-72q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T530-570v72q0 3 2.5 5.5t5.5 2.5Zm-58 50Z"
		/>
	</svg>
);
