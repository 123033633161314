import { ReactNode } from 'react';

export const symbol_disc_full: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M478.118-310Q549-310 598.5-359.618q49.5-49.617 49.5-120.5Q648-551 598.382-600.5q-49.617-49.5-120.5-49.5Q407-650 357.5-600.382q-49.5 49.617-49.5 120.5Q308-409 357.618-359.5q49.617 49.5 120.5 49.5ZM478-440q-17 0-28.5-11.5T438-480q0-17 11.5-28.5T478-520q17 0 28.5 11.5T518-480q0 17-11.5 28.5T478-440Zm0 360q-83 0-156-31.5T195-197q-54-54-85.5-127T78-480q0-83 31.5-156T195-763q54-54 127-85.5T478-880q113 0 207 57.5T833-668q6 11 2 23.5T820-627q-11 5-23 1t-19-15q-48-81-127-130t-173-49q-142.375 0-241.188 98.812Q138-622.375 138-480t98.812 241.188Q335.625-140 478-140q60 0 114.5-20T694-216q10-8 22.5-7.5T737-214q8 9 7.5 21t-9.5 20q-54 45-120 69T478-80Zm368.825-147Q834-227 825.5-235.625T817-257v-267q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T877-524v267q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Zm3.193 140Q836-87 826.5-96.483q-9.5-9.482-9.5-23.499 0-14.018 9.482-23.518 9.483-9.5 23.5-9.5 14.018 0 23.518 9.482 9.5 9.483 9.5 23.5Q883-106 873.518-96.5q-9.483 9.5-23.5 9.5ZM478-480Z"
		/>
	</svg>
);
