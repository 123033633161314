import { useState, useContext } from 'react';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import { useCurrentOrganization } from 'pkg/identity';

import Pricing from 'routes/payments/products/modals/Pricing';
import {
	AddOrderContext,
	AssignedProductProps,
} from 'routes/payments/orders/create';

import StepModal, { Step } from 'components/step-modal';

import SelectProduct from 'components/products/SelectProduct';
import useNewProduct from 'components/products/hooks/useNewProduct';

interface AddProductsModalProps {
	hideAddModal: () => void;
}

export default function AddProductModal({
	hideAddModal,
}: AddProductsModalProps) {
	const org = useCurrentOrganization();

	const { canSaveCreateProductForm, onCreateNewProduct, NewProductForm } =
		useNewProduct(org.id);

	const OrderContext = useContext(AddOrderContext);

	const [formState, setFormState] = useState<AssignedProductProps>({
		product: {} as models.product.Product,
		productPrice: {} as models.productPrice.ProductPrice,
		quantity: 1,
		taxRateId: null,
		validTo: 0,
		taxRate: {} as models.taxRate.TaxRate,
	});

	const [createNew, setCreateNew] = useState<boolean>(false);

	const handleDone = async () => {
		OrderContext.newAssignedProduct(formState);
	};

	const handleSelectProduct = (product: models.product.Product) =>
		setFormState({ ...formState, product });

	const handlePrev = async () => {
		if (createNew) {
			setCreateNew(false);
			return false;
		}

		return true;
	};

	const handlePrevPriceStep = async () => {
		setFormState({
			...formState,
			productPrice: {} as models.productPrice.ProductPrice,
		});
	};

	const handleCreateNew = async () => {
		const product = await onCreateNewProduct();

		setFormState((prev) => {
			return { ...prev, product, productPrice: product.prices[0] };
		});
		setCreateNew(false);
	};

	return (
		<StepModal onClose={hideAddModal} wide>
			{createNew ? (
				<Step
					title={t('Create product')}
					canGoNext={canSaveCreateProductForm}
					onNext={handleCreateNew}
					onPrev={handlePrev}
					nextLabel={t('Create')}>
					{NewProductForm}
				</Step>
			) : (
				<Step
					title={t('Add product')}
					skipBody
					canGoNext={!!formState.product?.id}>
					<SelectProduct
						organizationId={org.id}
						onSelect={handleSelectProduct}
						selectedProductId={formState.product.id}
						setCreateNew={setCreateNew}
					/>
				</Step>
			)}
			<Step
				nextLabel={t('Save')}
				onNext={handleDone}
				title={t('Select price')}
				onPrev={handlePrevPriceStep}
				canGoNext={!!formState.productPrice?.id}>
				<Pricing
					productId={formState.product.id}
					formState={formState}
					setFormState={setFormState}
				/>
			</Step>
		</StepModal>
	);
}
