import { ReactNode } from 'react';

export const symbol_file_open: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M220-80q-24 0-42-18t-18-42v-680q0-24 18-42t42-18h315q12.444 0 23.722 5T578-862l204 204q8 8 13 19.278 5 11.278 5 23.722v215q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T740-400v-200H550q-12.75 0-21.375-8.625T520-630v-190H220v680h380q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q630-97 621.375-88.5T600-80H220Zm530-127v95q0 12.75-8.675 21.375Q732.649-82 719.825-82 707-82 698.5-90.625T690-112v-168q0-12.75 8.625-21.375T720-310h168q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T888-250h-96l107 107q9 9 9 21.158 0 12.158-8.609 21Q890-92 877.533-92q-12.466 0-21.533-9L750-207Zm-530 67v-680 680Z"
		/>
	</svg>
);
