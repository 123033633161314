import { List } from 'immutable';

import Account from 'pkg/models/account';
import Video from 'pkg/models/video';

import { RootState } from 'pkg/reducers';

export const findAll = (state: RootState, ids?: number[]): List<Video> => {
	let videos = state.videos
		.get('entities')
		.sortBy((video: Video) => -video.createdAt)
		.map((video: Video) =>
			video.set(
				'account',
				state.accounts.entities.get(video.accountId, new Account())
			)
		);

	if (ids) {
		videos = videos.filter((video: Video) => ids.includes(video.id));
	}

	return videos.toList();
};

export const find = (state: RootState, videoId: number): Video => {
	const video = state.videos.entities.get(videoId?.toString());

	if (!video) {
		return new Video();
	}

	return video;
};
