import { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import User from 'pkg/models/user';
import { MembershipRole } from 'pkg/models/membership';

import * as actions from 'pkg/actions';
import * as selectors from 'pkg/selectors';
import store from 'pkg/store/createStore';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import * as models from 'pkg/api/models';

import * as Card from 'components/Card';
import Icon from 'components/icon';
import StepModal, { Step } from 'components/step-modal';
import Avatar from 'components/avatar';

import * as Input from 'components/form/inputs';
import Column from 'components/layout/column';
import { useAppState } from 'components/application/state';

import Button from 'design/button';

const ListItem = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const InfoWrapper = styled.div`
	display: grid;
	grid-auto-flow: column;
	justify-content: start;
	align-items: center;
	grid-gap: var(--spacing-3);
	@media ${styles.breakpoint.small} {
		grid-gap: var(--spacing-4);
	}
`;

const Name = styled.div`
	font-size: var(--font-size-base);
	font-weight: var(--font-weight-semibold);
`;

const Role = styled.div`
	font-size: var(--font-size-sm);
	color: var(--palette-gray-500);
`;

const InviteForm = styled.form`
	display: grid;
	grid-gap: var(--spacing-5);
	grid-template-columns: 1fr 1fr auto;
	align-items: flex-end;
`;

const ErrorMessage = styled.span`
	margin-bottom: var(--spacing-3);
	font-size: var(--font-size-sm);
	color: var(--palette-red-400);
	display: inline-block;
`;
const MembershipInvite = ({ invite, isDisabled, onRemove }) => {
	if (!invite) return null;

	return (
		<ListItem>
			<InfoWrapper>
				<Avatar
					user={new User({ firstName: invite.targetEmail.toUpperCase() })}
					size={40}
				/>
				<span>
					<Name>{invite.targetEmail}</Name>
					<Role>{models.membership.roleToTranslatedString(invite.role)}</Role>
				</span>
			</InfoWrapper>
			<Icon
				onClick={onRemove}
				fill={styles.palette.red[500]}
				name="close-circle"
				size={2}
				disabled={isDisabled}
			/>
		</ListItem>
	);
};

const EmailInviteModal = ({ onClose, groupId, onFinish }) => {
	const [isSendingInvites, setIsSendingInvites] = useState(false);
	const [outgoingInvites, setOutgoingInvites] = useState([]);
	const [role, setRole] = useState(1);
	const [error, setError] = useState(null);
	const { language } = useAppState();

	useComponentDidMount(() => {
		actions.groups.fetchSquad(groupId);
	});

	const activeMemberships = useSelector((state) =>
		selectors.groups.findAllMembershipsByGroupId(state, { groupId })
	);

	const emailExists = (email) =>
		activeMemberships.findIndex(
			(membership) => membership.getIn(['user', 'email']) === email
		) !== -1;

	const handleResetErrors = () => {
		if (error) {
			setError(null);
		}
	};

	const closeModal = () => {
		setIsSendingInvites(false);
		setOutgoingInvites([]);

		onClose();
	};

	const handleRoleChange = (event) => {
		const roleNumber = parseInt(event.target.value, 10);
		setRole(roleNumber);
	};

	const addInvite = (event) => {
		event.preventDefault();

		const data = new FormData(event.target);

		const invite = {
			targetEmail: data.get('email'),
			language,
			role,
		};

		event.target.reset();

		let invites = outgoingInvites;

		const alreadyAdded =
			invites.findIndex((n) => n.email === invite.targetEmail) > -1;

		if (alreadyAdded) {
			setError(t(`Email is already added in pending invites list`));

			return;
		}

		const alreadyExistingMember = emailExists(invite.targetEmail);

		if (alreadyExistingMember) {
			setError(
				t(`Email already associated with an active member of this group`)
			);

			return;
		}

		if (invite.targetEmail.length > 3) {
			invites.unshift(invite);
		}

		setOutgoingInvites([...invites]);
		setRole(1);

		setError(null);
	};

	const removeInvite = (email) => {
		let invites = outgoingInvites;

		invites = invites.filter((n) => n.targetEmail != email);

		setOutgoingInvites(invites);
	};

	const sendInvites = async () => {
		if (isSendingInvites) return false;

		setIsSendingInvites(true);

		const payload = {};

		outgoingInvites.forEach((invite) => {
			payload[invite.targetEmail] = invite;
		});

		await actions.groups.createInvites(groupId, payload)(store.dispatch);

		setIsSendingInvites(false);

		if (onFinish) {
			onFinish();
		}

		closeModal();
	};

	const roles = [
		{
			key: MembershipRole.User,
			title: t(`Player`),
		},
		{
			key: MembershipRole.Staff,
			title: t(`Staff`),
		},
		{
			key: MembershipRole.Admin,
			title: t(`Admin`),
		},
	];

	return (
		<StepModal onClose={closeModal}>
			<Step
				title={t(`Create email invites`)}
				skipBody
				canGoPrev={!isSendingInvites}
				canGoNext={!isSendingInvites && outgoingInvites.length > 0}
				onNext={sendInvites}
				nextLabel={t(`Send invites`)}>
				<Card.Body $flex={outgoingInvites.length === 0}>
					<Column spacing={styles.spacing._7}>
						{error && <ErrorMessage>{error}</ErrorMessage>}
						{!isSendingInvites && (
							<InviteForm onSubmit={addInvite}>
								<Input.Group label={t(`Email address`)}>
									<Input.Field
										type="email"
										name="email"
										pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$"
										required
										onChange={handleResetErrors}
										placeholder="john.doe@example.com"
									/>
								</Input.Group>
								<Input.Group label={t(`Role`)}>
									<Input.Select
										name="country"
										defaultValue={roles[0]}
										required
										onChange={handleRoleChange}>
										<option value="">{t('Select')}</option>
										{roles.map((role) => (
											<option key={role.key} value={role.key}>
												{role.title}
											</option>
										))}
									</Input.Select>
								</Input.Group>
								<Button type="submit" label={t('Add')} secondary large />
							</InviteForm>
						)}
						{outgoingInvites.length > 0 && (
							<Column spacing={styles.spacing._6}>
								{outgoingInvites.map((invite) => (
									<MembershipInvite
										key={`invite-${invite.targetEmail}`}
										invite={invite}
										isDisabled={isSendingInvites}
										onRemove={() => removeInvite(invite.targetEmail)}
									/>
								))}
							</Column>
						)}
					</Column>
				</Card.Body>
			</Step>
		</StepModal>
	);
};

export default EmailInviteModal;
