import { ReactNode } from 'react';

export const symbol_horizontal_distribute: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M109.825-80Q97-80 88.5-88.625T80-110v-740q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-850v740q0 12.75-8.675 21.375Q122.649-80 109.825-80Zm370.057-200Q459-280 444.5-294.583 430-309.167 430-330v-300q0-20.833 14.618-35.417Q459.235-680 480.118-680 501-680 515.5-665.417 530-650.833 530-630v300q0 20.833-14.618 35.417Q500.765-280 479.882-280ZM849.825-80Q837-80 828.5-88.625T820-110v-740q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T880-850v740q0 12.75-8.675 21.375Q862.649-80 849.825-80Z"
		/>
	</svg>
);
