import { ReactNode } from 'react';

export const symbol_wash: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M710.072-660Q675-660 647.5-687.45T620-750q0-42 27-83.5t51-73.5q4.75-6 11.875-6T722-907q24 32 51 73.5t27 83.5q0 35.1-27.428 62.55-27.428 27.45-62.5 27.45ZM230-80q-63 0-106.5-43.5T80-230v-216q0-28 13.5-53t36.5-40l304-196q11-8 21.5-4.5T474-728q14.815 14.933 17.407 35.467Q494-672 483-654l-59 94h346q12.75 0 21.375 8.625T800-530q0 12-8.625 21T770-500H370q-18 0-26.5-15.5t.5-30.5l63-101-244 159q-11 7-17 17.862-6 10.862-6 24.138v216q0 37.125 26.438 63.562Q192.875-140 230-140h500q12.75 0 21.375 8.625T760-110q0 12-8.625 21T730-80H230Zm620-280H510q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T510-420h340q12.75 0 21.375 8.625T880-390q0 12-8.625 21T850-360Zm-40 140H510q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T510-280h300q12.75 0 21.375 8.625T840-250q0 12-8.625 21T810-220ZM310-330Z"
		/>
	</svg>
);
