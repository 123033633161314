import { ReactNode } from 'react';

export const symbol_energy_program_saving: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M460-290q70.833 0 120.417-49.583Q630-389.167 630-460v-170H460q-70.833 0-120.417 49.583Q290-530.833 290-460q0 26.351 8.5 50.676Q307-385 321-363l-22 22q-9 9-9 21t9 21q9 9 21 9t21-9l22-22q22 14 46.324 22.5Q433.649-290 460-290Zm0-60q-14 0-27-4t-27-9l95-96q9-9 9-21t-9-21q-9-9-21-9t-21 9l-96 95q-5-14-9-27t-4-27q0-46.2 31.9-78.1Q413.8-570 460-570h110v110q0 46.2-31.9 78.1Q506.2-350 460-350Zm20 310L342-148l-173-21-21-173L40-480l108-138 21-173 173-21 138-108 138 108 173 21 21 173 108 138-108 138-21 173-173 21L480-40Zm0-77 113.84-88.065 143.911-18.257 17.184-142.838L843-480l-88.065-113.84-18.258-142.837-142.837-18.258L480-843l-113.84 88.065-143.911 18.258-17.184 142.837L117-480l88.065 113.84 18.257 143.911 142.838 17.184L480-117Zm0-363Zm0 0Z"
		/>
	</svg>
);
