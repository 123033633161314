import { Fragment, memo } from 'react';
import styled, { css } from 'styled-components';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import rgba from 'pkg/rgba';

import { useChatContext } from 'routes/chat/view';

import Icon from 'components/icon';

import * as iconStyles from 'components/icon/styles.css';

const QuotedMessageContainer = styled.div<{ isAttachment: boolean }>`
	grid-area: reply-to-message;
	display: grid;
	${({ isAttachment }) =>
		isAttachment
			? css`
					grid-template-areas: 'title attachment icon' 'content attachment icon';
					grid-template-columns: 1fr 40px 20px;
				`
			: css`
					grid-template-areas: 'title icon' 'content icon';
					grid-template-columns: 1fr 20px;
				`}

	gap: 5px 1rem;
	padding: 1rem;
	border-bottom: 1px solid ${rgba(styles.palette.black, 0.1)};

	strong {
		grid-area: title;
		font-weight: var(--font-weight-semibold);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		min-width: 0;
	}

	> p {
		grid-area: content;
		color: var(--palette-gray-500);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		min-width: 0;
	}

	.${iconStyles.icon} {
		grid-area: icon;
		font-size: 20px;
	}
`;

const AttachmentImage = styled.div`
	grid-area: attachment;
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		display: block;
		max-width: 40px;
		height: auto;
		max-height: 40px;
	}
`;

const Attachment = ({
	attachment,
}: {
	attachment: models.attachment.Attachment;
}) => {
	if (models.attachment.hasThumbnail(attachment)) {
		return (
			<Fragment>
				<p>{t('Image')}</p>
				<AttachmentImage>
					<img src={attachment.previewUrl || attachment.url} />
				</AttachmentImage>
			</Fragment>
		);
	}

	return <p>{t('Attachment: {filename}', { filename: attachment.title })}</p>;
};

interface QuotedMessageFormProps {
	item: models.chatMessage.ChatMessage;
}

const QuotedMessageForm = memo(({ item }: QuotedMessageFormProps) => {
	const chatContext = useChatContext();

	const isAttachment = item.attachmentId > 0;
	const user = item.user;
	const attachment = item.attachment;

	const cancelReplyTo = () =>
		chatContext.setState({
			quotedMessageId: 0,
		});

	return (
		<QuotedMessageContainer isAttachment={isAttachment}>
			<strong>
				{t('Replying to {name}', {
					name: user.firstName,
				})}
			</strong>
			{isAttachment ? (
				attachment && <Attachment attachment={attachment} />
			) : (
				<p>{item.content}</p>
			)}
			<Icon
				onClick={cancelReplyTo}
				name="close"
				fill={styles.palette.gray[500]}
			/>
		</QuotedMessageContainer>
	);
});

export default QuotedMessageForm;
