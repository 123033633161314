import { ReactNode } from 'react';

export const symbol_reply_all: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m166-524 172 172q9 9 8.5 21t-9.5 21q-9 9-21.5 9t-21.5-9L101-503q-5-5-7-10t-2-11q0-6 2-11t7-10l194-194q9-9 21.5-9t21.5 9q9 9 9 21.5t-9 21.5L166-524Zm210 30 142 142q9 9 8.5 21t-9.5 21q-9 9-21.5 9t-21.5-9L281-503q-5-5-7-10t-2-11q0-6 2-11t7-10l194-194q9-9 21.5-9t21.5 9q9 9 9 21.5t-9 21.5L376-554h305q85 0 141.5 56.5T879-356v126q0 13-8.5 21.5T849-200q-13 0-21.5-8.5T819-230v-126q0-60-39-99t-99-39H376Z"
		/>
	</svg>
);
