import { Record, Map, List } from 'immutable';

import RatingItem from 'pkg/models/rating_item';

import * as actionTypes from 'pkg/actions/action-types';

const initialState = new Record(
	{
		entities: new Map({}),
		exercises: new Map({}),
	},
	'rating_items'
);

const setRatingItemsReducer = (state, action) => {
	const data = action.payload.entities;

	if (!data.ratingItems) return state;

	const entities = Object.values(data.ratingItems);

	entities.forEach((entity) => {
		if (!entity.id) {
			return;
		}

		state = state.setIn(['entities', entity.id], new RatingItem(entity));
	});

	if (!data.exercises) {
		return state;
	}

	Object.values(data.ratingItems).forEach((n) => {
		state = state.updateIn(
			['exercises', n.ratingItemId],
			new List(),
			(list) => {
				const id = n.exerciseId;

				if (!list.includes(id)) {
					return list.push(id);
				}

				return list;
			}
		);
	});

	return state;
};

const ratingItems = (state = initialState(), action) => {
	switch (action.type) {
		case actionTypes.RatingItems.SET_ITEMS:
			return setRatingItemsReducer(state, action);

		case actionTypes.AUTH_LOGOUT_SUCCESS:
			return initialState();

		default:
			return state;
	}
};

export default ratingItems;
