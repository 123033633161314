import * as bp from 'pkg/config/breakpoints';

/**
 *	Returns estimated number of columns based on viewport size.
 *
 *	@returns number
 */
export function useNumLibraryColumns(): number {
	// Use matchMedia to to retain match value on component load.

	const threeColumns = window.matchMedia(bp.large).matches;
	const fourColumns = window.matchMedia(bp.nolimit).matches;
	const fiveColumns = window.matchMedia('all and (min-width: 1600px)').matches;

	if (fiveColumns) {
		return 5;
	} else if (fourColumns) {
		return 4;
	} else if (threeColumns) {
		return 3;
	} else {
		return 1;
	}
}

/**
 *	Returns a fetch limit based on estimated number of columns.
 *
 *	@returns number
 */
export function useViewportFetchLimit(): number {
	const columns = useNumLibraryColumns();

	switch (columns) {
		case 5:
			return 9;
		case 4:
			return 7;
		case 3:
			return 5;
		case 1:
			return 5;
	}
}
