import { ReactNode } from 'react';

export const symbol_not_listed_location: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-325q15 0 26-11t11-26q0-15-11-26t-26-11q-15 0-26 11t-11 26q0 15 11 26t26 11Zm109-291q0-45-27.5-72T487-715q-39 0-72.5 21T381-649q0 7 3.5 12t9.5 7q8 4 18 .5t19-13.5q10-11 24-17.5t30-6.5q25 0 39.5 14t14.5 37q0 17-8 30t-29 32q-23 21-35 41t-12 37q0 11 7 19t18 8q10 0 17.5-7t8.5-18q2-18 14-32t26-26q23-22 33-41t10-43ZM480-159q133-121 196.5-219.5T740-552q0-118-75.5-193T480-820q-109 0-184.5 75T220-552q0 75 65 173.5T480-159Zm0 59q-11 0-21.5-3.5T440-115q-42-38-91-87.5T258-309q-42-57-70-119t-28-124q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 62-28 124t-70 119q-42 57-91 106.5T520-115q-8 8-18.5 11.5T480-100Zm0-460Z"
		/>
	</svg>
);
