import { ReactNode } from 'react';

export const symbol_hail: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M167-110v-163q0-12.75 8.625-21.375T197-303h44q12.75 0 21.375 8.625T271-273v163q0 12.75-8.625 21.375T241-80h-44q-12.75 0-21.375-8.625T167-110Zm212 0v-485q-49 22-75.5 59T272-440q-1 11-10.5 17.5t-20.682 6.5q-11.927 0-20.873-8Q211-432 212-444q9-109 80.36-168.5Q363.719-672 482-672q100 0 157-34t66-119q2-13 10.675-22 8.676-9 21.5-9 12.825 0 21.325 8.5T766-827q-7 82-50.5 133T590-623v513q0 12.75-8.675 21.375Q572.649-80 559.825-80 547-80 538.5-88.625T530-110v-225h-91v225q0 12.75-8.675 21.375Q421.649-80 408.825-80 396-80 387.5-88.625T379-110Zm103.08-624q-30.08 0-51.58-21.42-21.5-21.421-21.5-51.5 0-30.08 21.42-51.58 21.421-21.5 51.5-21.5 30.08 0 51.58 21.42 21.5 21.421 21.5 51.5 0 30.08-21.42 51.58-21.421 21.5-51.5 21.5Z"
		/>
	</svg>
);
