// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Aqw4jjxVq6toKPXz9DW4 {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	height: 25px;
	border-radius: var(--radius-2);
	margin-bottom: 3px;
	position: relative;
	padding: 0 var(--spacing-3) 0 0;
	gap: var(--spacing-2);
}

.Aqw4jjxVq6toKPXz9DW4::before {
		content: '';
		display: block;
		position: relative;
		width: 3px;
		min-width: 3px;
		height: 100%;
	}

@media (min-width: 769px) and (max-height: 680px) {

.Aqw4jjxVq6toKPXz9DW4 {
		height: 20px;
		font-size: 10px;
}
	}

@media (max-width: 768px) {

.Aqw4jjxVq6toKPXz9DW4 {
		height: 14px;
		font-size: 8px;
}
	}

.ISUINJfnoE8DxNL8x1Th {
	display: block;
	width: 100%;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

._4O8i6r86gTxF0dmznRPw {
	text-transform: uppercase;
}

.sZXWIl5PhA_deWTRocwz {
	text-decoration: line-through;
}
`, "",{"version":3,"sources":["webpack://./routes/group/calendar/components/events/month/styles.css"],"names":[],"mappings":"AAAA;CACC,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CACnB,YAAY;CACZ,8BAA8B;CAC9B,kBAAkB;CAClB,kBAAkB;CAClB,+BAA+B;CAC/B,qBAAqB;AAoBtB;;AAlBC;EACC,WAAW;EACX,cAAc;EACd,kBAAkB;EAClB,UAAU;EACV,cAAc;EACd,YAAY;CACb;;AAEA;;AAnBD;EAoBE,YAAY;EACZ,eAAe;AAOjB;CANC;;AAEA;;AAxBD;EAyBE,YAAY;EACZ,cAAc;AAEhB;CADC;;AAGD;CACC,cAAc;CACd,WAAW;CACX,uBAAuB;CACvB,mBAAmB;CACnB,gBAAgB;AACjB;;AAEA;CACC,yBAAyB;AAC1B;;AAEA;CACC,6BAA6B;AAC9B","sourcesContent":[".monthWrapper {\n\tdisplay: flex;\n\talign-items: center;\n\theight: 25px;\n\tborder-radius: var(--radius-2);\n\tmargin-bottom: 3px;\n\tposition: relative;\n\tpadding: 0 var(--spacing-3) 0 0;\n\tgap: var(--spacing-2);\n\n\t&::before {\n\t\tcontent: '';\n\t\tdisplay: block;\n\t\tposition: relative;\n\t\twidth: 3px;\n\t\tmin-width: 3px;\n\t\theight: 100%;\n\t}\n\n\t@media (--small-desktop) {\n\t\theight: 20px;\n\t\tfont-size: 10px;\n\t}\n\n\t@media (--small-viewport) {\n\t\theight: 14px;\n\t\tfont-size: 8px;\n\t}\n}\n\n.monthText {\n\tdisplay: block;\n\twidth: 100%;\n\ttext-overflow: ellipsis;\n\twhite-space: nowrap;\n\toverflow: hidden;\n}\n\n.allCaps {\n\ttext-transform: uppercase;\n}\n\n.strikeThrough {\n\ttext-decoration: line-through;\n}\n"],"sourceRoot":""}]);
// Exports
export var monthWrapper = `Aqw4jjxVq6toKPXz9DW4`;
export var monthText = `ISUINJfnoE8DxNL8x1Th`;
export var allCaps = `_4O8i6r86gTxF0dmznRPw`;
export var strikeThrough = `sZXWIl5PhA_deWTRocwz`;
export default ___CSS_LOADER_EXPORT___;
