import { ReactNode } from 'react';

export const symbol_link_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m594-447-60-60h71q12.75 0 21.375 8.625T635-477q0 13-8.5 21.5T605-447h-11ZM836-78q-9 9-21.5 9T793-78L84-786q-9-9-9-21.5t9-21.5q9-9 21.5-9t21.5 9l709 709q9 9 9 21t-9 21ZM280-280q-83 0-141.5-58.5T80-480q0-72 44.5-127T238-676l56 56h-14q-58.333 0-99.167 40.765-40.833 40.764-40.833 99Q140-422 180.833-381q40.834 41 99.167 41h140q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T420-280H280Zm75-170q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T355-510h49l60 60H355Zm366 139.5q-5-11.5-.5-23.5t15.5-17q38-17 61-50.5t23-75.5q0-58.333-40.833-99.167Q738.333-617 680-617H555q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T555-677h125q83 0 141.5 58.5T880-477q0 59-32.5 106.5T763-297q-11 6-24 2t-18-15.5Z"
		/>
	</svg>
);
