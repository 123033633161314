import * as models from 'pkg/api/models';
import { AllowedActionable } from 'pkg/api/models/allowed_actionable';
import { Record } from 'pkg/api/models/record';

export enum OnboardingStatus {
	OnboardingStatusUnknown = 'unknown',
	OnboardingStatusPrepared = 'prepared',
	OnboardingStatusApproved = 'approved',
	OnboardingStatusPending = 'pending',
	OnboardingStatusInvalid = 'invalid',
	OnboardingStatusRejected = 'rejected',
}

export interface GroupPaymentConfig extends Record, AllowedActionable {
	groupId: number;
	onboardingStatus: OnboardingStatus;
	active: boolean;
	paymentProvider: models.group.PaymentProvider;
	paymentProviderId: string;
}

export function getStatusTranslation(config: GroupPaymentConfig, t: any) {
	let statusText = '';
	let labelColor = '';
	let description = '';

	switch (config.onboardingStatus) {
		case OnboardingStatus.OnboardingStatusInvalid:
			statusText = t('Information requested');
			labelColor = 'gray';
			description = t(
				'Your payment setup requires additional information. Please edit your settings or contact support for assistance.'
			);
			break;
		case OnboardingStatus.OnboardingStatusPending:
			statusText = t('Pending approval');
			labelColor = 'orange';
			description = t(
				'Your payment setup is being verified and you will get a notice when this is done.'
			);
			break;
		case OnboardingStatus.OnboardingStatusPrepared:
			statusText = t('Prepared');
			labelColor = 'gray';
			description = t(`You're all set to begin entering your payment details!`);
			break;
		case OnboardingStatus.OnboardingStatusRejected:
			statusText = t('Rejected');
			labelColor = 'red';
			description = t(
				'Your payment setup was denied, please contact our support for more information.'
			);
			break;
		case OnboardingStatus.OnboardingStatusUnknown:
			statusText = t('Unknown');
			labelColor = 'gray';
			break;
		case OnboardingStatus.OnboardingStatusApproved:
			statusText = t('Approved');
			labelColor = 'green';
			description = t(
				'Your payment setup is verified and you are ready to receive payments.'
			);
			break;
	}

	return { statusText, labelColor, description };
}
