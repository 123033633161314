import { ReactNode } from 'react';

export const symbol_pace: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-80q-82 0-155-31.5t-127.5-86q-54.5-54.5-86-127.341Q80-397.681 80-480.5q0-82.819 31.5-155.659Q143-709 197.5-763t127.341-85.5Q397.681-880 480.5-880q83.817 0 156.158 31.5Q709-817 763-763t85.5 126.5Q880-564 880-480q0 17-1 34t-4 34q-2 12-13 19t-23 4q-12-3-18.5-13.889T816-427q2-13 3-26t1-27q0-71.393-26-133.197Q768-675 721-721q-46-47-107.803-73Q551.393-820 480-820q-141 0-240.5 98.812Q140-622.375 140-480q0 141 99.5 240.5T480-140q48.516 0 93.258-13.5T657-190q11-7 23-4.5t19.579 12.5q8.421 10 5.754 22.179Q702.667-147.643 692-141q-47 29-100.144 45Q538.712-80 480-80Zm299.859-161Q763-241 751.5-252.641t-11.5-28.5Q740-298 751.641-309.5t28.5-11.5Q797-321 808.5-309.359t11.5 28.5Q820-264 808.359-252.5t-28.5 11.5ZM513-492l137 138q9 8.556 9 21.778T650-310q-10 10-23.222 10-13.222 0-21.778-10L461-459q-4-5-6-10.217-2-5.218-2-10.783v-183q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T513-663v171Z"
		/>
	</svg>
);
