import { ReactNode } from 'react';

export const symbol_qr_code_scanner: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M109.825-707Q97-707 88.5-715.625T80-737v-113q0-12.75 8.625-21.375T110-880h113q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T223-820h-83v83q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM110-80q-12.75 0-21.375-8.625T80-110v-113q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-223v83h83q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q253-97 244.375-88.5T223-80H110Zm627 0q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T737-140h83v-83q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T880-223v113q0 12.75-8.625 21.375T850-80H737Zm112.825-627Q837-707 828.5-715.625T820-737v-83h-83q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T737-880h113q12.75 0 21.375 8.625T880-850v113q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM708-188v-63h63v63h-63Zm0-126v-63h63v63h-63Zm-63 63v-63h63v63h-63Zm-63 63v-63h63v63h-63Zm-63-63v-63h63v63h-63Zm126-126v-63h63v63h-63Zm-63 63v-63h63v63h-63Zm-63-63v-63h63v63h-63Zm30-143q-12.75 0-21.375-8.625T519-550v-192q0-12.75 8.625-21.375T549-772h192q12.75 0 21.375 8.625T771-742v192q0 12.75-8.625 21.375T741-520H549ZM218-188q-12.75 0-21.375-8.625T188-218v-192q0-12.75 8.625-21.375T218-440h192q12.75 0 21.375 8.625T440-410v192q0 12.75-8.625 21.375T410-188H218Zm0-332q-12.75 0-21.375-8.625T188-550v-192q0-12.75 8.625-21.375T218-772h192q12.75 0 21.375 8.625T440-742v192q0 12.75-8.625 21.375T410-520H218Zm20 282h152v-152H238v152Zm0-332h152v-152H238v152Zm331 0h152v-152H569v152Z"
		/>
	</svg>
);
