import { ReactNode } from 'react';

export const symbol_location_disabled: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M918-478q0 13-8.5 21.5T888-448h-45q-5 38-15.5 75.5T797-299q-6 11-18 13.5t-23-5.5q-11-8-13-20t4-23q17-32 27-68.5t10-75.5q0-125-89.5-214.5T480-782q-38 0-75 10t-69 27q-11 6-23.5 4.5T292-753q-8-11-5.5-23.5T300-795q34-20 73-30.5t77-15.5v-45q0-13 8.5-21.5T480-916q13 0 21.5 8.5T510-886v45q137 14 228 105t105 228h45q13 0 21.5 8.5T918-478ZM450-70v-45q-137-14-228-105T117-448H72q-13 0-21.5-8.5T42-478q0-13 8.5-21.5T72-508h45q6-51 21.5-95.5T181-687L75-793q-9-9-9-21.5t9-21.5q9-9 21.5-9t21.5 9l720 720q9 9 9 21t-9 21q-9 9-21.5 9T795-74L688-180q-40 29-83.5 44T510-115v45q0 13-8.5 21.5T480-40q-13 0-21.5-8.5T450-70Zm30-104q45 0 86-12.5t78-36.5L225-642q-23 35-36 75.5T176-478q0 125 89.5 214.5T480-174Z"
		/>
	</svg>
);
