import { ReactNode } from 'react';

export const symbol_sentiment_calm: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-260q34 0 64-12.5t56-35.5q9-8 9-21t-9-21q-9-8-21.5-8t-22.5 8q-17 13-36 21t-40 8q-21 0-40-8t-36-21q-10-8-22.5-8t-21.5 8q-9 8-9 21t9 21q26 22 56 35t64 13Zm0 180q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-400Zm0 340q142.375 0 241.188-98.812Q820-337.625 820-480t-98.812-241.188Q622.375-820 480-820t-241.188 98.812Q140-622.375 140-480t98.812 241.188Q337.625-140 480-140ZM339.835-480q32.835 0 59-21.5T439-554q5-11.289-1-22.145Q432-587 419.542-590 409-593 398-587.5q-11 5.5-16 16.5-7 13-17.5 22t-24.5 9q-14 0-25-9.5T298-572q-5-11-16-16t-22-2q-12 3-17.5 14.5T242-552q14 31 39.5 51.5t58.335 20.5Zm280 0q32.835 0 59-21.5T719-554q5-11.289-1-22.145Q712-587 699.542-590 689-593 678-587.5q-11 5.5-16 16.5-7 13-17.5 22t-24.5 9q-14 0-25-9.5T578-572q-5-11-16-16t-22-2q-12 3-17.5 14.5T522-552q14 31 39.5 51.5t58.335 20.5Z"
		/>
	</svg>
);
