import { ReactNode } from 'react';

export const symbol_format_letter_spacing: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m276-196 52 54q8 8 8 20t-8 21q-8.067 9-20.533 9Q295-92 286-101L182-205q-9-9-9-21t9-21l104-103q9-8 21-8.5t21 8.109q9 9.391 9 21.491t-9 20.9l-53 52h410l-51-52q-8-9-8.5-21t8.5-21q9-9 21-9t21 9l103 103q9 9 9 21t-9 21L675-101q-9 9-21.158 9-12.158 0-21-8.609Q624-110 624-122.1t9-20.9l53-53H276Zm-46.175-284Q217-480 208.5-488.625T200-510v-340q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T260-850v340q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Zm250 0Q467-480 458.5-488.625T450-510v-340q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-850v340q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Zm250 0Q717-480 708.5-488.625T700-510v-340q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T760-850v340q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Z"
		/>
	</svg>
);
