import { ReactNode } from 'react';

export const symbol_brightness_auto: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M402-414h159l32 93q2 8 8.538 12 6.539 4 14.385 4Q629-305 636.5-315.897 644-326.795 639-339L510-682q-2.237-7.105-8.203-11.053Q495.831-697 489.119-697h-16.407q-6.712 0-12.607 3.947Q454.211-689.105 452-682L323-339q-5 11.9 2 22.95Q332-305 345.538-305q8.462 0 15.545-4.95Q368.167-314.9 371-323l31-91Zm15-48 61.286-163H483l62 163H417Zm-70.837 302H220q-24.75 0-42.375-17.625T160-220v-125.587L68-438q-9-9-13-19.814-4-10.813-4-22Q51-491 55-502t13-20l92-92.413V-740q0-24.75 17.625-42.375T220-800h125.587L438-892q9-9 20.5-13t22.697-4q11.197 0 22.021 4.696Q514.042-899.609 523-891l91 91h126q24.75 0 42.375 17.625T800-740v125.587L892-522q9 9 13 19.814 4 10.813 4 22Q909-469 905-458t-13 20l-92 92.413V-220q0 24.75-17.625 42.375T740-160H614l-91 90q-8.958 8.13-19.782 12.565Q492.394-53 481.197-53q-11.197 0-22.16-4.435Q448.073-61.87 439-70l-92.837-90ZM370-220l111 107 107.917-107H740v-151l109-109-109-109v-151H589L481-849 371-740H220v151L111-480l109 109v151h150Zm111-261Z"
		/>
	</svg>
);
