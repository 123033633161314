import { ReactNode } from 'react';

export const symbol_mode_of_travel: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-100q-11 0-21.5-3.5T440-115q-42-38-91-87.5T258-309q-42-57-70-119t-28-124q0-149 97-238.5T480-880q134 0 228 94t94 258l73-73q9-9 21-9t21 9q9 9 9 21t-9 21L793-435q-9 9-21 9t-21-9L627-559q-9-9-9-21t9-21q9-9 21-9t21 9l73 73q0-132-73-212t-189-80q-109 0-184.5 75.5T220-552q0 82 66 179t194 214q20-18 43-41l40-40q-6-12-9.5-24t-3.5-23q0-38 26.5-64t63.5-26q38 0 64 26t26 64q0 38-26 64t-64 26q-7 0-14.5-1t-16.5-4q-17 17-35.5 36T520-115q-8 8-18.5 11.5T480-100Z"
		/>
	</svg>
);
