import { ReactNode } from 'react';

export const symbol_gynecology: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M328-232.211q0-21.789 6.019-42.558Q340.037-295.538 353-314q8-13 13-26.782T371-370q0-17.091-6-32.045Q359-417 351-432q-8-18-14.5-37.016Q330-488.033 330-508v-162q0-29.167-18.5-49.583Q293-740 265-740q-26 0-44.5 16.5T200-683q34.872 13.08 57.436 43.769Q280-608.543 280-570.309 280-520 245-485t-85 35q-50 0-85-35t-35-85q0-45 28.5-78t71.5-40q4-48 39.936-80T265-800q7.667 0 15.333 1 7.667 1 14.392 3 42.275-25 89.06-34.5 46.786-9.5 95.983-9.5Q528-840 575-830.5t89.275 34.5q6.725-2 14.392-3 7.666-1 15.333-1 49 0 85 31.5t40 79.5q43 7 72 40.5t29 78.5q0 50-35 85t-85 35q-50 0-85-35t-35-85q0-39 22-69.5t57-43.5q-2-25-20.667-41-18.666-16-44.333-16-27.119 0-45.559 20.417Q630-699.167 630-670v162q0 19.95-7 38.95-7 19-16 37.05-7 14.972-12.5 29.944-5.5 14.973-5.5 32.084 0 14.972 4.5 29.472Q598-326 606-314q13 18 19.5 39.207t6.5 42.885q0 17.908-4.235 34.889Q623.529-180.038 616-164l-18 35q-6 15-23.5 18l-17.5 3q-10-10-14.5-23t1.5-25l17-35q5-10 8-20.125t3-21.375q0-13.5-4-25.5t-12-23q-14-20-20.5-42.25t-6.5-45.427q0-21.323 6.5-40.823T552-448q7-14 12.5-29.146Q570-492.293 570-508v-162.411q0-25.644 9-48.616Q588-742 604-759q-29-11-60.464-16t-63.5-5Q448-780 416.5-775T356-759q16 17 25 39.973 9 22.972 9 48.616V-508q0 16 5 31t13 29q9 19 16 38.5t7 40.583q0 22.917-7.241 45.38Q416.517-301.074 403-281q-8 11-11.5 23.25t-3.5 25.614Q388-221 390.5-211t7.5 20l17 35q5 11 1 23t-15 17q-11 5-22.5 1.5T361-129l-18-35q-7.941-16.105-11.471-33.158Q328-214.211 328-232.211ZM160-510q25.5 0 42.75-17.25T220-570q0-25.5-17.25-42.75T160-630q-25.5 0-42.75 17.25T100-570q0 25.5 17.25 42.75T160-510Zm640 0q25.5 0 42.75-17.25T860-570q0-25.5-17.25-42.75T800-630q-25.5 0-42.75 17.25T740-570q0 25.5 17.25 42.75T800-510Zm0-60Zm-640 0Z"
		/>
	</svg>
);
