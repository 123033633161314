import { ReactNode } from 'react';

export const symbol_lan: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M120-140v-150q0-24.75 17.625-42.375T180-350h60v-100q0-24.75 17.625-42.375T300-510h150v-100h-60q-24.75 0-42.375-17.625T330-670v-150q0-24.75 17.625-42.375T390-880h180q24.75 0 42.375 17.625T630-820v150q0 24.75-17.625 42.375T570-610h-60v100h150q24.75 0 42.375 17.625T720-450v100h60q24.75 0 42.375 17.625T840-290v150q0 24.75-17.625 42.375T780-80H600q-24.75 0-42.375-17.625T540-140v-150q0-24.75 17.625-42.375T600-350h60v-100H300v100h60q24.75 0 42.375 17.625T420-290v150q0 24.75-17.625 42.375T360-80H180q-24.75 0-42.375-17.625T120-140Zm270-530h180v-150H390v150ZM180-140h180v-150H180v150Zm420 0h180v-150H600v150ZM480-670ZM360-290Zm240 0Z"
		/>
	</svg>
);
