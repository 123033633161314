import { ReactNode } from 'react';

export const symbol_unfold_more: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m480-208 114-114q9.13-9 22.065-9T638-321.897q9 9.104 9 22Q647-287 638-278L501-141q-5 5-10.133 7-5.134 2-11 2Q474-132 469-134q-5-2-10-7L322-278q-9-9.13-9-22.065T322.103-322q9.104-9 22-9Q357-331 366-322l114 114Zm0-540L366-634q-9.13 9-22.065 9T322-634.103q-9-9.104-9-22Q313-669 322-678l137-137q5-5 10.133-7 5.134-2 11-2Q486-824 491-822q5 2 10 7l137 137q9 9.13 9 22.065T637.897-634q-9.104 9-22 9Q603-625 594-634L480-748Z"
		/>
	</svg>
);
