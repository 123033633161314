import { ReactNode } from 'react';

export const symbol_format_h4: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M129.825-280Q117-280 108.5-288.625T100-310v-340q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T160-650v140h180v-140q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T400-650v340q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T340-310v-140H160v140q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Zm620 0Q737-280 728.5-288.625T720-310v-100H550q-12.75 0-21.375-8.625T520-440v-210q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T580-650v180h140v-180q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T780-650v180h50q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T830-410h-50v100q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Z"
		/>
	</svg>
);
