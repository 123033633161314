import { Map, Record } from 'immutable';

import Match from 'pkg/models/match';

import {
	ADD_MATCHES,
	REMOVE_MATCH,
	AUTH_LOGOUT_SUCCESS,
	MATCH_UPDATE_PROPERTY,
} from 'pkg/actions/action-types';

const initialState = Record(
	{
		entities: Map({}),
	},
	'matches'
);

const matches = (state = initialState(), action = {}) => {
	switch (action.type) {
		case ADD_MATCHES: {
			let entities = state.entities.withMutations((e) =>
				Object.values(action.matches).forEach((m) => {
					e.set(m.id, new Match(m));
				})
			);

			return state.set('entities', entities);
		}

		case REMOVE_MATCH: {
			return state.deleteIn(['entities', action.matchId]);
		}

		case MATCH_UPDATE_PROPERTY: {
			const { matchId, name, value } = action;

			return state.setIn(['entities', matchId, name], value);
		}

		case AUTH_LOGOUT_SUCCESS:
			return initialState();

		default:
			return state;
	}
};

export default matches;
