import { t } from '@transifex/native';

import VideoSequence from 'pkg/models/video_sequence';
import VideoPlaylist from 'pkg/models/video_playlist';
import VideoPlaylistSequence from 'pkg/models/video_playlist_sequence';

import * as actionTypes from 'pkg/actions/action-types';
import * as services from 'pkg/actions/services';
import { normalizedDispatch } from 'pkg/actions/utils';
import * as flashes from 'pkg/actions/flashes';

import store from 'pkg/store/createStore';

export const fetch = async (
	playlistId: number,
	showFlash: boolean = true
): Promise<[Response, services.video.PlaylistResponse]> => {
	const [request, response] = await services.video.fetchPlaylist(playlistId);

	if (!request.ok && showFlash) {
		flashes.show({ title: t(`Could not fetch playlist`) }, request.status);
		return;
	}

	normalizedDispatch(response, VideoPlaylist.normalizr())(store.dispatch);

	return [request, response];
};

export const create = async (
	payload: services.video.PlaylistCreatePayload
): Promise<services.video.PlaylistResponse> => {
	const [request, response] = await services.video.createPlaylist(payload);

	if (!request.ok) {
		flashes.show({ title: t(`Could not create playlist`) }, request.status);
		return;
	} else {
		flashes.show({ title: t(`Created new playlist`) }, request.status);
	}

	normalizedDispatch(response, VideoPlaylist.normalizr())(store.dispatch);

	return response;
};

export const update = async (
	playlistId: number,
	payload: services.video.PlaylistUpdatePayload
): Promise<services.video.PlaylistResponse> => {
	const [request, response] = await services.video.updatePlaylist(
		playlistId,
		payload
	);

	if (!request.ok) {
		flashes.show({ title: t(`Could not update playlist`) }, request.status);
		return;
	}

	normalizedDispatch(response, VideoPlaylist.normalizr())(store.dispatch);

	return response;
};

export const destroy = async (
	playlistId: number
): Promise<services.video.PlaylistResponse> => {
	const [request] = await services.video.destroyPlaylist(playlistId);

	if (request.ok) {
		flashes.show({ title: t(`Removed playlist`) }, request.status);

		store.dispatch({
			type: actionTypes.VideoPlaylists.DELETE_ITEM,
			playlistId,
		});
		return;
	} else {
		flashes.show({ title: t(`Could not delete playlist`) }, request.status);
		return;
	}
};

export const addSequences = async (
	playlistId: number,
	...sequenceIds: number[]
): Promise<services.video.PlaylistResponse> => {
	const [request, response] = await services.video.addPlaylistSequences(
		playlistId,
		...sequenceIds
	);

	if (!request.ok) {
		flashes.show(
			{ title: t(`Could not add clips to playlist`) },
			request.status
		);
		return;
	}

	normalizedDispatch(response, VideoSequence.normalizr())(store.dispatch);

	return response;
};

export const removeSequences = async (
	playlistId: number,
	...sequenceIds: number[]
): Promise<boolean> => {
	const [request] = await services.video.removePlaylistSequences(
		playlistId,
		...sequenceIds
	);

	if (!request.ok) {
		flashes.show(
			{ title: t(`Could not remove clips from playlist`) },
			request.status
		);
		return false;
	}

	sequenceIds.forEach((sequenceId: number) => {
		store.dispatch({
			type: actionTypes.VideoPlaylistSequences.DELETE_ITEM,
			playlistId,
			sequenceId,
		});
	});

	return true;
};

export const updateSequenceOrders = async (
	playlistId: number,
	sequences: number[]
): Promise<services.video.PlaylistResponse> => {
	const [request, response] = await services.video.patchPlaylistSequences(
		playlistId,
		sequences
	);

	if (!request.ok) {
		flashes.show(
			{ title: t(`Could not rearrange order of clips`) },
			request.status
		);
		return;
	}

	normalizedDispatch(response, [VideoPlaylistSequence.normalizr()])(
		store.dispatch
	);

	return response;
};
