import { ReactNode } from 'react';

export const symbol_star_rate_half: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-711v324l137 104-55-173 126-82H537l-57-173Zm0 403L307-176q-9 7-18 6t-17-6q-8-5-11.5-13.5T260-209l66-215-170-122q-9-6-11.5-15.5t.5-17.5q3-8 10-14.5t18-6.5h211l67-224q3-11 11.5-16.5T480-846q9 0 17.5 5.5T509-824l67 224h211q11 0 18 6.5t10 14.5q3 8 .5 17.5T804-546L634-424l66 215q3 11-.5 19.5T688-176q-8 5-17 6t-18-6L480-308Z"
		/>
	</svg>
);
