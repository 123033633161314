import { ReactNode } from 'react';

export const symbol_distance: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-80q-106 0-173-31t-67-79q0-27 24.5-51t67.5-39l18 58q-16 5-29.5 14T299-190q17 20 70.5 35T480-140q57 0 111-15t71-35q-8-8-21-17t-30-15l17-58q43 15 67.5 39t24.5 51q0 48-67 79T480-80Zm0-215q21.103-39 44.552-71.5Q548-399 571-428q44-57 69.5-98T666-634.074q0-77.666-54.214-131.796-54.215-54.13-132-54.13Q402-820 348-765.87t-54 131.796Q294-567 319.5-526t69.5 98q23 29 46.448 61.5Q458.897-334 480-295Zm0 109q-12 0-21-6.771T446-211q-24-73-60.019-121-36.02-48-69.981-92-34-44-58-91.5t-24-118.541Q234-737 305.319-808.5 376.639-880 480-880q103.361 0 174.681 71.319Q726-737.361 726-634q0 71-23.873 118.341Q678.253-468.319 644-424q-34 44-70 92t-59.852 120.732Q510-200 501-193t-21 7Zm.208-388Q505-574 522.5-591.708q17.5-17.709 17.5-42.5Q540-659 522.292-676.5q-17.709-17.5-42.5-17.5Q455-694 437.5-676.292q-17.5 17.709-17.5 42.5Q420-609 437.708-591.5q17.709 17.5 42.5 17.5ZM480-634Z"
		/>
	</svg>
);
