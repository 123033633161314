import { ReactNode } from 'react';

export const symbol_stop_screen_share: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M552-485 444-593h86v-52q0-5.25 4.5-7.125t8.75 2.375l75.65 75.65q5.1 5.1 5.1 11.282T619-552l-67 67Zm253 254-60-60h69v-483H262l-60-60h612q24 0 42 18t18 42v483q0 26-20 44t-49 16Zm-65 106H70q-12.75 0-21.375-8.675Q40-142.351 40-155.175 40-168 48.625-176.5T70-185h610l-46-46H147q-24 0-42-18t-18-42v-487l-34-34q-9-9.273-9-21.636Q44-846 53-855t21.5-9q12.5 0 21.5 9L859-92q9 9 9 21t-9 21q-9 9-21.5 9T816-50l-76-75ZM393-472v55q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T333-417v-60q0-10 2-22.5t7-23.034L147-718v427h427L393-472Zm111-61Zm-143 28Z"
		/>
	</svg>
);
