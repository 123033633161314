import { ReactNode } from 'react';

export const symbol_destruction: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M190-80q-28.875 0-49.438-20.562Q120-121.125 120-150v-180q0-28.875 20.562-49.438Q161.125-400 190-400h580q28.875 0 49.438 20.562Q840-358.875 840-330v180q0 28.875-20.562 49.438Q798.875-80 770-80H190Zm-10-60h600v-200H180v200Zm261-564 90-161q4-7 10-10t13-1q7 2 12.2 6 5.2 4 6.8 12l30 182 175-49q8-2 14.5 1t10.5 8q4 5 4.5 11.5T803-691L697-539l50 29q11 7 13.5 19t-4.5 23q-7 11-19.5 13.5T713-459l-73-47q-11-7-13-19t5-23l58-87-135 29-24-132-72 123-116-85 37 139-141 25 43 26q11 6 14 17.5t-3 22.5q-6 11-18 14t-23-3l-135-78q-7-4-10-10t-1-13q2-7 6.5-11.5T125-578l180-31-48-178q-2-8 .5-14t7.5-10q5-4 12-4.5t14 4.5l150 107Zm35 245Zm4 219Z"
		/>
	</svg>
);
