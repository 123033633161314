import { ReactNode } from 'react';

export const symbol_format_list_bulleted_add: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M735.143-134q6.857 0 11.357-5.1 4.5-5.1 4.5-11.9v-93h94q7 0 11.5-5.143t4.5-12q0-6.857-4.5-11.357Q852-277 845-277h-94v-94q0-7-4.5-11.5t-11.357-4.5q-6.857 0-12 4.5T718-371v94h-94q-7 0-11.5 4.5t-4.5 11.357q0 6.857 5.1 12T625-244h93v94q0 7 5.143 11.5t12 4.5ZM732.5-74Q655-74 600-129.5T545-261q0-78.435 54.99-133.717Q654.98-450 733-450q77 0 132.5 55.283Q921-339.435 921-261q0 76-55.5 131.5T732.5-74ZM407-703q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T407-763h403q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-703H407ZM189-161q-28.05 0-48.025-19Q121-199 121-227.5t19.5-48q19.5-19.5 48-19.5t47.5 19.975Q255-255.05 255-227q0 27.225-19.387 46.613Q216.225-161 189-161Zm0-252q-28.05 0-48.025-19.681Q121-452.362 121-480t19.975-47.319Q160.95-547 189-547q27.225 0 46.613 19.681Q255-507.638 255-480t-19.387 47.319Q216.225-413 189-413Zm-1-253q-27.637 0-47.319-19.681Q121-705.362 121-733t19.681-47.319Q160.363-800 188-800q27.637 0 47.319 19.681Q255-760.638 255-733t-19.681 47.319Q215.637-666 188-666Zm219 216q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T407-510h131q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T538-450H407Zm-.175 252Q394-198 385.5-206.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Z"
		/>
	</svg>
);
