import { ReactNode } from 'react';

export const symbol_css: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M532-408v-53H414q-14.45 0-24.225-9.775Q380-480.55 380-495v-71q0-15 9.5-24.5T414-600h142q10.4 0 17.2 6.8 6.8 6.8 6.8 17.2 0 10.4-6.8 17.2-6.8 6.8-17.2 6.8H428v53h118q14.45 0 24.225 9.775Q580-479.45 580-465v71q0 15-9.5 24.5T546-360H404q-10.4 0-17.2-6.8-6.8-6.8-6.8-17.2 0-10.4 6.8-17.2 6.8-6.8 17.2-6.8h128Zm260 0v-53H674q-14.45 0-24.225-9.775Q640-480.55 640-495v-71q0-15 9.5-24.5T674-600h142q10.4 0 17.2 6.8 6.8 6.8 6.8 17.2 0 10.4-6.8 17.2-6.8 6.8-17.2 6.8H688v53h118q14.45 0 24.225 9.775Q840-479.45 840-465v71q0 15-9.5 24.5T806-360H664q-10.4 0-17.2-6.8-6.8-6.8-6.8-17.2 0-10.4 6.8-17.2 6.8-6.8 17.2-6.8h128Zm-638 48q-14.45 0-24.225-9.775Q120-379.55 120-394v-172q0-14.45 9.775-24.225Q139.55-600 154-600h139q14.45 0 24.225 9.775Q327-580.45 327-566v23q0 9.517-7.2 16.259Q312.6-520 303-520q-11 0-17.5-6.5T279-544v-8H168v144h111v-8q0-9.6 6.5-16.8 6.5-7.2 17.42-7.2 10.08 0 17.08 7.2 7 7.2 7 16.8v22q0 14.45-9.775 24.225Q307.45-360 293-360H154Z"
		/>
	</svg>
);
