export function offsetStart(beginsAt, endsAt, pointedAt) {
	return ((pointedAt - beginsAt) * 100) / (endsAt - beginsAt);
}

export function offsetWidth(beginsAt, endsAt, startsAt, stopsAt) {
	const offset = (startsAt / endsAt) * 100;
	return 100 - ((endsAt - stopsAt) / endsAt) * 100 - offset;
}

export function formatTime(seconds, showSeconds = true) {
	if (Number.isNaN(seconds)) {
		seconds = 0;
	}

	const h = Math.floor(seconds / 3600);
	const m = Math.floor((seconds % 3600) / 60);
	const s = Math.floor(seconds % 60);

	let timeParts = [];

	timeParts.push(m);

	if (showSeconds) {
		timeParts.push(s);
	}

	timeParts = timeParts.map((t) => t.toString().padStart(2, '0'));

	if (h > 0) {
		timeParts.unshift(h);
	}

	return timeParts.join(':');
}

export function formatMatchTime(seconds) {
	return Math.floor(seconds / 60);
}

export function parseTime(timeString) {
	return timeString.split(':').reduce((rest, time) => 60 * rest + +time);
}
