import * as styles from 'pkg/config/styles';

import Markdown from 'routes/kitchen_sink/components/Markdown';

import Icon from 'components/icon';

import Column from 'components/layout/column';
import Row from 'components/layout/row';

import Button from 'design/button';

import readme from './README.md';

const Buttons = () => {
	return (
		<Column spacing={styles.spacing._7}>
			<Markdown raw={readme} />

			<Column>
				<Row autoColumns="max-content">
					<Button label="Primary" small primary />
					<Button label="Secondary" small secondary />
					<Button label="Default" small />
					<Button label="Transparent" small transparent />
					<Button label="Caution" small caution />
					<Button label="Inline" small inline />
				</Row>
				<Row autoColumns="max-content">
					<Button label="Primary" primary />
					<Button label="Secondary" secondary />
					<Button label="Default" />
					<Button label="Transparent" transparent />
					<Button label="Caution" caution />
					<Button label="Inline" inline />
				</Row>
				<Row autoColumns="max-content">
					<Button label="Primary" large primary />
					<Button label="Secondary" large secondary />
					<Button label="Default" large />
					<Button label="Transparent" large transparent />
					<Button label="Caution" large caution />
					<Button label="Inline" large inline />
				</Row>
				<Row autoColumns="max-content">
					<Button isLoading label="Long loading label" primary icon="add" />
					<Button isLoading label="Loading" secondary />
					<Button isLoading>
						With hidden childs
						<Icon name="add" />
					</Button>
					<Button isLoading label="Loading" transparent />
					<Button isLoading label="Loading" caution />
					<Button isLoading label="Loading" inline />
				</Row>
				<Row autoColumns="max-content">
					<Button label="Icon" icon="add_comment" iconPosition="left" small />
					<Button label="Icon" icon="add_comment" iconPosition="left" />
					<Button
						label="Icon"
						icon="delete"
						caution
						iconPosition="left"
						large
					/>
				</Row>
				<Row autoColumns="max-content">
					<Button label="Icon" icon="delete" iconPosition="right" small />
					<Button label="Icon" icon="delete" iconPosition="right" />
					<Button
						label="Icon"
						icon="add_comment"
						iconPosition="right"
						caution
						large
					/>
				</Row>

				<Row autoColumns="max-content">
					<Button
						label="Icon label is very long"
						icon="delete"
						iconPosition="top"
						small
					/>
					<Button
						label="Icon label is very long"
						icon="delete"
						iconPosition="top"
					/>
					<Button
						label="Icon label is very long"
						icon="add_comment"
						caution
						iconPosition="top"
						large
					/>
				</Row>

				<Row autoColumns="max-content">
					<Button icon="add_comment" />
					<Button caution icon="delete" />
					<Button icon="check_box" />
					<Button transparent icon="smart_display" />
				</Row>
				<Row autoColumns="max-content">
					<Button large icon="add_comment" />
					<Button large caution icon="delete" />
					<Button large icon="check_box" />
					<Button large transparent icon="smart_display" />
				</Row>
			</Column>
		</Column>
	);
};

export default Buttons;
