export default function Arrow(): JSX.Element {
	return (
		<svg
			viewBox="0 0 25 25"
			width="25"
			height="25"
			preserveAspectRatio="xMidYMid meet"
			fill="none">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="currentColor"
				d="M13.2138 5.45993H18.8638V10.9099H17.5638V7.67917L6.67345 18.5695L5.75421 17.6503L16.6446 6.75993H13.2138V5.45993Z"
			/>
		</svg>
	);
}
