import { ReactNode } from 'react';

export const symbol_present_to_all: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M451-522v165q0 12.325 9 20.663Q469-328 481.228-328q12.228 0 20.5-9T510-358v-164l58 59q9 9.167 21 9.583 12 .417 21-8.636 9-9.052 9-21.5Q619-496 610-505L500-615q-9-9-21-9t-21 9L348-505q-9 9-8.5 21t9.5 21.391Q358-454 370.5-454t21.5-9l59-59ZM140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm0-60h680v-520H140v520Zm0 0v-520 520Z"
		/>
	</svg>
);
