import { ReactNode } from 'react';

export const symbol_rainy_heavy: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M333-213q-11 6-23 2t-17-15L53-706q-5-11-1.5-23.5T66-747q11-5 23.5-1.5T107-734l240 480q5 11 1 23t-15 18Zm187 0q-11 6-23 2t-17-15L240-706q-5-11-1.5-23.5T253-747q11-5 23-1.5t17 14.5l241 480q5 11 1 23t-15 18Zm187-1q-11 6-23 2t-17-15L427-707q-5-11-1.5-23t14.5-17q11-5 23-1.5t17 14.5l241 480q5 11 1 22.5T707-214Zm187 1q-11 5-23.5 1T853-227L613-707q-5-11-1-23t15-17q11-5 23-1.5t17 14.5l240 480q5 11 1.5 23.5T894-213Z"
		/>
	</svg>
);
