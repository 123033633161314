import { ReactNode } from 'react';

export const symbol_outpatient_med: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M110-780q-12.75 0-21.375-8.675Q80-797.351 80-810.175 80-823 88.625-831.5T110-840h420q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T530-780H110Zm210.118 510Q341-270 355.5-284.583 370-299.167 370-320v-50h50q20.833 0 35.417-14.618Q470-399.235 470-420.118 470-441 455.417-455.5 440.833-470 420-470h-50v-50q0-20.833-14.618-35.417Q340.765-570 319.882-570 299-570 284.5-555.417 270-540.833 270-520v50h-50q-20.833 0-35.417 14.618Q170-440.765 170-419.882 170-399 184.583-384.5 199.167-370 220-370h50v50q0 20.833 14.618 35.417Q299.235-270 320.118-270ZM100-120q-24 0-42-18t-18-42v-480q0-24 18-42t42-18h440q24 0 42 18t18 42v480q0 24-18 42t-42 18H100Zm0-60h440v-480H100v480Zm659-180.842q-9-8.842-9-21T759-403l47-47H669q-12.325 0-20.662-8.272-8.338-8.272-8.338-20.5T649-500q9-9 21-9h135l-47-47q-9-9-9-21t9.053-21q9.052-9 21.5-9Q792-607 801-598l98 98q9 9 9 21t-9 21l-97 97q-9.067 9-21.533 9Q768-352 759-360.842ZM320-420Z"
		/>
	</svg>
);
