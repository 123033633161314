// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.za_zoRf6BRQIr7lmXsPy {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	height: 40px;
	width: 40px;
	background-color: var(--palette-blue-100);
	border-radius: var(--radius-3);
}

.za_zoRf6BRQIr7lmXsPy svg {
		width: 24px;
		height: 24px;
		color: var(--palette-blue-500);
	}

.kSnXxtviFOI6mWG2AWwP {
	font-weight: var(--font-weight-semibold);
	word-wrap: break-word;
}

.kSnXxtviFOI6mWG2AWwP + span {
		color: var(--palette-gray-500);
		font-weight: var(--font-weight-normal);
		font-size: var(--font-size-sm);
	}

.QZkptS5c8A4Vs7FgT94G {
	font-weight: var(--font-weight-normal);
	font-size: var(--font-size-sm);
}

.BzYD0dmLGwW56vUD2CNt {
	font-size: var(--font-size-xs);
}

.WFGtNtJ8hsVaDOQehY6f {
	color: var(--palette-gray-500);
}

.P0spoSgk0BLJnhiS_9yP {
	width: 25px;
}

.E4UazSEFapjDgAsFLlgD {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	gap: var(--spacing-4);
}

.E4UazSEFapjDgAsFLlgD strong {
		font-weight: var(--font-weight-semibold);
	}

.uTaY83JNGoDPGzZZZVX9 {
	margin-top: var(--spacing-4);
}

.PkufOs9hElucBuRckk34 {
	margin-bottom: var(--spacing-4);
}
`, "",{"version":3,"sources":["webpack://./routes/user/billing/subscription_list/styles.css"],"names":[],"mappings":"AAAA;CACC,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,wBAAuB;KAAvB,qBAAuB;SAAvB,uBAAuB;CACvB,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CACnB,YAAY;CACZ,WAAW;CACX,yCAAyC;CACzC,8BAA8B;AAO/B;;AALC;EACC,WAAW;EACX,YAAY;EACZ,8BAA8B;CAC/B;;AAGD;CACC,wCAAwC;CACxC,qBAAqB;AAOtB;;AALC;EACC,8BAA8B;EAC9B,sCAAsC;EACtC,8BAA8B;CAC/B;;AAGD;CACC,sCAAsC;CACtC,8BAA8B;AAC/B;;AAEA;CACC,8BAA8B;AAC/B;;AAEA;CACC,8BAA8B;AAC/B;;AAEA;CACC,WAAW;AACZ;;AAEA;CACC,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,qBAAqB;AAKtB;;AAHC;EACC,wCAAwC;CACzC;;AAGD;CACC,4BAA4B;AAC7B;;AAEA;CACC,+BAA+B;AAChC","sourcesContent":[".iconwrapper {\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\theight: 40px;\n\twidth: 40px;\n\tbackground-color: var(--palette-blue-100);\n\tborder-radius: var(--radius-3);\n\n\t& svg {\n\t\twidth: 24px;\n\t\theight: 24px;\n\t\tcolor: var(--palette-blue-500);\n\t}\n}\n\n.title {\n\tfont-weight: var(--font-weight-semibold);\n\tword-wrap: break-word;\n\n\t+ span {\n\t\tcolor: var(--palette-gray-500);\n\t\tfont-weight: var(--font-weight-normal);\n\t\tfont-size: var(--font-size-sm);\n\t}\n}\n\n.smallText {\n\tfont-weight: var(--font-weight-normal);\n\tfont-size: var(--font-size-sm);\n}\n\n.extraSmallText {\n\tfont-size: var(--font-size-xs);\n}\n\n.gray {\n\tcolor: var(--palette-gray-500);\n}\n\n.cardImg {\n\twidth: 25px;\n}\n\n.installmentWrapper {\n\tdisplay: flex;\n\tgap: var(--spacing-4);\n\n\t& strong {\n\t\tfont-weight: var(--font-weight-semibold);\n\t}\n}\n\n.expirationWarning {\n\tmargin-top: var(--spacing-4);\n}\n\n.showPaymentMethod {\n\tmargin-bottom: var(--spacing-4);\n}\n"],"sourceRoot":""}]);
// Exports
export var iconwrapper = `za_zoRf6BRQIr7lmXsPy`;
export var title = `kSnXxtviFOI6mWG2AWwP`;
export var smallText = `QZkptS5c8A4Vs7FgT94G`;
export var extraSmallText = `BzYD0dmLGwW56vUD2CNt`;
export var gray = `WFGtNtJ8hsVaDOQehY6f`;
export var cardImg = `P0spoSgk0BLJnhiS_9yP`;
export var installmentWrapper = `E4UazSEFapjDgAsFLlgD`;
export var expirationWarning = `uTaY83JNGoDPGzZZZVX9`;
export var showPaymentMethod = `PkufOs9hElucBuRckk34`;
export default ___CSS_LOADER_EXPORT___;
