export default {
	name: 'video-analyze',
	objects: [
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M21.3972 6.20883L20.6821 2.96693L2.59314 6.55737L2.65744 6.88134L2.6463 6.8832L2.74111 7.30285L2.78783 7.53824L2.79401 7.53701L3.12281 8.99239L3.12277 18.3374C3.12277 19.442 4.16578 20.3374 5.4524 20.3374H19.1466C20.4333 20.3374 21.4763 19.442 21.4763 18.3374L21.4763 9.74473H21.4826V8.74473H8.39761L21.3972 6.20883ZM19.7658 9.74473H16.4302L15.3508 10.6714H18.688L19.7658 9.74473ZM14.3435 9.74473H11.0055L9.9261 10.6714H13.2641L14.3435 9.74473ZM8.91884 9.74473H5.58445L4.50502 10.6714H7.83941L8.91884 9.74473ZM3.15037 8.99239H3.12281L3.14994 8.98787L3.15037 8.99239ZM5.97228 8.199L9.12497 7.58399L7.44954 6.61294L4.31068 7.23597L5.97228 8.199ZM9.41458 6.22291L11.1006 7.19859L14.254 6.58344L12.5561 5.59935L9.41458 6.22291ZM14.5204 5.20946L17.6627 4.58576L19.3831 5.58289L16.227 6.19856L14.5204 5.20946ZM4.63703 18.3374V11.9714H19.962V18.3374C19.962 18.724 19.597 19.0374 19.1466 19.0374H5.4524C5.00208 19.0374 4.63703 18.724 4.63703 18.3374Z',
			},
		},
	],
};
