import { t } from '@transifex/native';

export enum EventTypes {
	Camp = 'camp',
	Cup = 'cup',
	Match = 'match',
	Meeting = 'meeting',
	Practice = 'practice',
	Uncategorized = 'uncategorized',
}

export const eventTypeLabels = [
	EventTypes.Match,
	EventTypes.Practice,
	EventTypes.Meeting,
	EventTypes.Camp,
	EventTypes.Cup,
	EventTypes.Uncategorized,
];

export function eventTypeToTranslatedString(eventType: EventTypes) {
	switch (eventType) {
		case EventTypes.Match:
			return t('Match');
		case EventTypes.Practice:
			return t('Practice');
		case EventTypes.Meeting:
			return t('Meeting');
		case EventTypes.Camp:
			return t('Camp');
		case EventTypes.Cup:
			return t('Cup');
		case EventTypes.Uncategorized:
			return t('Uncategorized');
	}
}
