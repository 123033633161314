import { ReactNode } from 'react';

export const symbol_chat_apps_script: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M586-160q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T586-220h145l-118-83q-10-7-12.5-19t4.5-22q7-10 18.826-12.5T646-352l119 82-50-136q-5-11 1-22t18-16q12-5 23 .5t16 17.5l48 136 39-139q3-12 13-18t22-3q12 3 18.5 13t3.5 22l-62 233q-2.88 10.267-10.44 16.133Q837-160 826-160H586ZM240-280l-94 94q-7 7-16.5 3.458Q120-186.083 120-196v-584q0-24.75 17.625-42.375T180-840h520q24.75 0 42.375 17.625T760-780v209q0 13.175-9 22.088Q742-540 729-541q-12-1-20.5-9.338Q700-558.675 700-571v-209H180v440h289q12.325 0 20.663 8.474 8.337 8.473 8.337 21Q498-298 490-289t-21 9H240Zm70-340h260q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T570-680H310q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T310-620Zm0 170h140q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T450-510H310q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T310-450ZM180-340v-440 440Z"
		/>
	</svg>
);
