// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zHKx7ntu7ZXJq562tqJ2 {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	height: 40px;
	width: 40px;
	background-color: var(--palette-blue-100);
	border-radius: var(--radius-3);
}

.zHKx7ntu7ZXJq562tqJ2 svg {
		width: 24px;
		height: 24px;
		color: var(--palette-blue-500);
	}

.hbnDQqNOjYubsjCqI7BG {
	font-weight: var(--font-weight-semibold);
	word-wrap: break-word;
}

.hbnDQqNOjYubsjCqI7BG + span {
		color: var(--palette-gray-500);
		font-weight: var(--font-weight-normal);
		font-size: var(--font-size-sm);
	}

.ZQciJbXqn8V_eZg_cn4i {
	font-weight: var(--font-weight-normal);
	font-size: var(--font-size-sm);
}

.GFdrpTtwxnMuUVNKj8jC {
	font-size: var(--font-size-xs);
}

.nZRR1L2W6ECXq225AaWj {
	color: var(--palette-gray-500);
}
`, "",{"version":3,"sources":["webpack://./routes/user/billing/invoice_list/styles.css"],"names":[],"mappings":"AAAA;CACC,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,wBAAuB;KAAvB,qBAAuB;SAAvB,uBAAuB;CACvB,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CACnB,YAAY;CACZ,WAAW;CACX,yCAAyC;CACzC,8BAA8B;AAO/B;;AALC;EACC,WAAW;EACX,YAAY;EACZ,8BAA8B;CAC/B;;AAGD;CACC,wCAAwC;CACxC,qBAAqB;AAOtB;;AALC;EACC,8BAA8B;EAC9B,sCAAsC;EACtC,8BAA8B;CAC/B;;AAGD;CACC,sCAAsC;CACtC,8BAA8B;AAC/B;;AAEA;CACC,8BAA8B;AAC/B;;AAEA;CACC,8BAA8B;AAC/B","sourcesContent":[".iconwrapper {\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\theight: 40px;\n\twidth: 40px;\n\tbackground-color: var(--palette-blue-100);\n\tborder-radius: var(--radius-3);\n\n\t& svg {\n\t\twidth: 24px;\n\t\theight: 24px;\n\t\tcolor: var(--palette-blue-500);\n\t}\n}\n\n.title {\n\tfont-weight: var(--font-weight-semibold);\n\tword-wrap: break-word;\n\n\t+ span {\n\t\tcolor: var(--palette-gray-500);\n\t\tfont-weight: var(--font-weight-normal);\n\t\tfont-size: var(--font-size-sm);\n\t}\n}\n\n.smallText {\n\tfont-weight: var(--font-weight-normal);\n\tfont-size: var(--font-size-sm);\n}\n\n.extraSmallText {\n\tfont-size: var(--font-size-xs);\n}\n\n.sub {\n\tcolor: var(--palette-gray-500);\n}\n"],"sourceRoot":""}]);
// Exports
export var iconwrapper = `zHKx7ntu7ZXJq562tqJ2`;
export var title = `hbnDQqNOjYubsjCqI7BG`;
export var smallText = `ZQciJbXqn8V_eZg_cn4i`;
export var extraSmallText = `GFdrpTtwxnMuUVNKj8jC`;
export var sub = `nZRR1L2W6ECXq225AaWj`;
export default ___CSS_LOADER_EXPORT___;
