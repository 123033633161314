import { ReactNode } from 'react';

export const symbol_communities: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M358-319q30.938 0 52.969-21.737Q433-362.475 433-393t-22.031-52.263Q388.938-467 358-467q-30.112 0-51.556 21.737Q285-423.525 285-393t21.444 52.263Q327.888-319 358-319Zm244 0q30.525 0 52.263-21.737Q676-362.475 676-393t-21.737-52.263Q632.525-467 602-467t-52.263 21.737Q528-423.525 528-393t21.737 52.263Q571.475-319 602-319ZM479.956-533q30.955 0 53-22.044 22.044-22.045 22.044-53Q555-639 532.969-660 510.938-681 480-681q-30.112 0-51.556 21.444T407-608q0 30.938 21 52.969Q449-533 479.956-533Zm.31 453q-82.734 0-155.5-31.5t-127.266-86q-54.5-54.5-86-127.341Q80-397.681 80-480.5q0-82.819 31.5-155.659Q143-709 197.5-763t127.341-85.5Q397.681-880 480.5-880q82.819 0 155.659 31.5Q709-817 763-763t85.5 127Q880-563 880-480.266q0 82.734-31.5 155.5T763-197.684q-54 54.316-127 86Q563-80 480.266-80Zm.234-60Q622-140 721-239.5t99-241Q820-622 721.188-721 622.375-820 480-820q-141 0-240.5 98.812Q140-622.375 140-480q0 141 99.5 240.5t241 99.5Zm-.5-340Z"
		/>
	</svg>
);
