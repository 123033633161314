import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import { IdentityUserTrait } from 'pkg/api/models/identity_provider';

import * as Input from 'components/form/inputs';
import UserTraitsInputGroup from 'components/identity-provider/role-select-group';
import Column from 'components/layout/column';

interface CreateIdentityProviderFormProps {
	availableUserTraits: IdentityUserTrait[];
}

export default function CreateIdentityProviderForm({
	availableUserTraits,
}: CreateIdentityProviderFormProps) {
	return (
		<Column spacing={styles.spacing._6}>
			<Input.Group
				label={t('Display name')}
				required
				description={t(
					'This is the name of the login method that will be shown to the user'
				)}>
				<Input.Field name="displayName" required />
			</Input.Group>
			<UserTraitsInputGroup availableUserTraits={availableUserTraits} />
		</Column>
	);
}
