import { ReactNode } from 'react';

export const symbol_pause_presentation: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M402.175-321q12.825 0 21.325-8.625T432-351v-258q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T372-609v258q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625Zm156 0q12.825 0 21.325-8.625T588-351v-258q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T528-609v258q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625ZM140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm0-60h680v-520H140v520Zm0 0v-520 520Z"
		/>
	</svg>
);
