import { Map, List, Record } from 'immutable';

/* @imports */
import SessionItem from 'pkg/models/session_item';

import { SessionItems } from 'pkg/actions/action-types';

/* @actions */

const initialState = new Record(
	{
		entities: new Map(),
		result: new List([]),
		modes: new Map({}),
	},
	'sessionItems'
);

const initialMode = new Map({
	isNew: false,
	hasChanges: false,
	isDiscarded: false,
});

const setModeReducer = (state, action) =>
	state.setIn(
		['modes', action.itemId],
		state.getIn(['modes', action.itemId], initialMode).merge(action.payload)
	);

const unsetModeReducer = (state, action) =>
	state.deleteIn(['modes', action.itemId]);

const setItemsReducer = (state, action) => {
	const data = action.payload.entities.sessionItems;
	const entities = Object.values(data);

	entities.forEach((entity) => {
		state = setModeReducer(state, {
			itemId: entity.id,
			payload: {},
		}).setIn(['entities', entity.id], new SessionItem(entity));
	});

	return state;
};

const deleteItemReducer = (state, { itemId }) =>
	state.deleteIn(['entities', itemId]);

const sessionItemsReducer = (state = initialState(), action = {}) => {
	switch (action.type) {
		case SessionItems.SET_ITEMS:
			return setItemsReducer(state, action);
		case SessionItems.DELETE_ITEM:
			return deleteItemReducer(state, action);

		case SessionItems.SET_MODE:
			return setModeReducer(state, action);
		case SessionItems.UNSET_MODE:
			return unsetModeReducer(state, action);
		default:
			return state;
	}
};

export default sessionItemsReducer;
