import { ReactNode } from 'react';

export const symbol_encrypted: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M447-360h66q6.75 0 11.875-5.625T528-378l-22-127q20.465-9.155 32.233-26.549Q550-548.944 550-570q0-28.875-20.589-49.438Q508.823-640 479.911-640 451-640 430.5-619.438 410-598.875 410-570q0 21.056 11.767 38.451Q433.535-514.155 454-505l-22 127q-2 6.75 3.125 12.375T447-360Zm33 276q-5.32 0-9.88-1-4.56-1-9.12-3-139-47-220-168.5t-81-266.606V-719q0-19.257 10.875-34.662Q181.75-769.068 199-776l260-97q11-4 21-4t21 4l260 97q17.25 6.932 28.125 22.338Q800-738.257 800-719v195.894Q800-378 719-256.5T499-88q-4.56 2-9.12 3T480-84Zm0-59q115-38 187.5-143.5T740-523v-196l-260-98-260 98v196q0 131 72.5 236.5T480-143Zm0-337Z"
		/>
	</svg>
);
