import { ReactNode } from 'react';

export const symbol_stat_minus_3: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m480-167 177-177q9-9 21-9t21 9q9 9 9 21t-9 21L522-125q-17 17-42 17t-42-17L261-302q-9-9-9-21t9-21q9-9 21-9t21 9l177 177Zm0-246 177-177q9-9 21-9t21 9q9 9 9 21t-9 21L522-371q-17 17-42 17t-42-17L261-548q-9-9-9-21t9-21q9-9 21-9t21 9l177 177Zm0-246 177-177q9-9 21-9t21 9q9 9 9 21t-9 21L522-617q-17 17-42 17t-42-17L261-794q-9-9-9-21t9-21q9-9 21-9t21 9l177 177Z"
		/>
	</svg>
);
