import { ReactNode } from 'react';

export const symbol_airwave: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M743-631q-26 26-58 38.5T619-580q-34 0-66-12.5T495-631l-75-75q-17-17-37.5-25t-42.5-8q-22 0-42.5 8T260-706l-47 47q-9 9-21 8.5t-21-9.5q-9-9-9-21.5t9-21.5l46-46q26-26 58-38.5t65-12.5q33 0 64.5 12.5T462-749l75 75q17 17 37.5 25.5T618-640q23 0 44-8.5t38-25.5l47-47q9-9 21.5-9t21.5 9q9 9 9 21.5t-9 21.5l-47 47Zm0 210q-26 26-58 38.5T619-370q-34 0-66-12.5T495-421l-75-75q-17-17-37.5-25t-42.5-8q-22 0-42.5 8T260-496l-47 47q-9 9-21 9t-21-9q-9-9-9.5-21.5T170-492l47-47q26-26 58-38.5t65-12.5q33 0 64.5 12.5T462-539l75 75q17 17 38 25.5t44 8.5q23 0 43.5-8.5T700-464l47-47q9-9 21.5-9t21.5 9q9 9 9 21.5t-9 21.5l-47 47Zm-1 210q-26 26-57.5 38.5T619-160q-34 0-66-12.5T495-211l-76-75q-17-17-37.5-25t-42.5-8q-22 0-42.5 8T259-286l-47 47q-9 9-21 9t-21-9q-9-9-8.5-21t8.5-21l47-48q26-26 57.5-38.5T339-380q33 0 65 12.5t58 38.5l75 75q17 17 38 25.5t44 8.5q23 0 43.5-8.5T700-254l47-47q9-9 21-8.5t21 9.5q9 9 9 21t-9 21l-47 47Z"
		/>
	</svg>
);
