import { Fragment, useState } from 'react';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import * as routes from 'pkg/router/routes';
import { TemplateModals } from 'pkg/api/models/schedule';
import { useCurrentOrganization } from 'pkg/identity';

import EditTemplateModal from 'routes/scheduling/templates/modals/EditTemplate';
import DuplicateModal from 'routes/scheduling/templates/modals/Duplicate';

import RelativeDateTime from 'components/RelativeDateTime';
import Icon from 'components/icon';
import Label from 'components/label';

import * as ContextMenu from 'design/context_menu';
import * as Table from 'design/table';

interface ScheduleRowProps {
	groupId: number;

	refreshList: () => Promise<void>;

	schedule: models.schedule.Schedule;
}

const ScheduleRow = ({ groupId, refreshList, schedule }: ScheduleRowProps) => {
	const [modal, setModal] = useState<TemplateModals | ''>('');
	const org = useCurrentOrganization();

	const setEditModal = () => setModal('edit');
	const setDuplicateModal = () => setModal('duplicate');
	const hideModal = () => setModal('');

	const handleDelete = async () => {
		const confirm = window.confirm(
			t(
				`Are you absolutely sure you want to remove this template? This action can't be undone`
			)
		);

		if (confirm) {
			const ok = await models.schedule.remove(schedule);

			if (ok) {
				refreshList();
			}
		}
	};

	let deleteConfirm = t('Are you sure you want to delete this template?');

	if (schedule.publishedAt) {
		deleteConfirm = t(
			'Are you sure you want to delete this template? This templates upcoming connected events/bookings will be removed.'
		);
	}

	return (
		<Fragment>
			<Table.Row>
				<Table.LinkCell
					href={routes.Templates.Show(org.id, groupId, schedule.id)}>
					{schedule.title}
				</Table.LinkCell>
				<Table.LinkCell
					href={routes.Templates.Show(org.id, groupId, schedule.id)}>
					<Label color={schedule.publishedAt !== 0 ? 'green' : 'gray'}>
						{schedule.publishedAt !== 0 ? t('Published') : t('Never published')}
					</Label>
				</Table.LinkCell>
				<Table.LinkCell
					href={routes.Templates.Show(org.id, groupId, schedule.id)}>
					{!!schedule.publishedAt && (
						<RelativeDateTime dateTime={schedule.publishedAt} />
					)}
				</Table.LinkCell>
				<Table.LinkCell
					href={routes.Templates.Show(org.id, groupId, schedule.id)}>
					<RelativeDateTime dateTime={schedule.updatedAt} />
				</Table.LinkCell>
				<Table.LinkCell
					href={routes.Templates.Show(org.id, groupId, schedule.id)}>
					<RelativeDateTime dateTime={schedule.createdAt} />
				</Table.LinkCell>

				<Table.ActionCell>
					<ContextMenu.Menu
						toggleWith={
							<ContextMenu.TableTrigger>
								<Icon name="context-menu" />
							</ContextMenu.TableTrigger>
						}>
						<ContextMenu.Item onClick={setEditModal}>
							<ContextMenu.ItemIcon name="edit" />
							{t('Edit')}
						</ContextMenu.Item>
						<ContextMenu.Item onClick={setDuplicateModal}>
							<ContextMenu.ItemIcon name="content_copy" />
							{t('Duplicate')}
						</ContextMenu.Item>
						<ContextMenu.Divider />
						<ContextMenu.ConfirmItem
							caution
							onConfirm={handleDelete}
							message={deleteConfirm}>
							<ContextMenu.ItemIcon name="delete" />
							{t('Delete')}
						</ContextMenu.ConfirmItem>
					</ContextMenu.Menu>
				</Table.ActionCell>
			</Table.Row>
			{modal === 'duplicate' && (
				<DuplicateModal schedule={schedule} hideModal={hideModal} />
			)}
			{modal === 'edit' && (
				<EditTemplateModal
					schedule={schedule}
					refresh={refreshList}
					hideModal={hideModal}
				/>
			)}
		</Fragment>
	);
};

export default ScheduleRow;
