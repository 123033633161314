import { ReactNode } from 'react';

export const symbol_phonelink_ring_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M829-49 49-843q-8-9-8.5-21t8.5-21q9-9 21-9t21 9L872-91q9 9 8.5 21T871-49q-9 9-21 9t-21-9ZM200-732l60 61v481h433l66 67v23q1 25-16.919 42.5T699-40H260q-24.75 0-42.375-17.625T200-100v-632Zm60 602v30h440v-30H260Zm469.825-584Q717-714 708.5-722.625T700-744v-26H344q-13.077 0-21.538-8.675-8.462-8.676-8.462-21.5 0-12.825 8.625-21.325T344-830h356v-30H270q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T270-920h430q24.75 0 42.375 17.625T760-860v116q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM260-130v30-30Zm660-350.554Q920-429 902.5-381T852-295q-8 10-20 10t-20.87-9.391Q803-303 802.5-315.042 802-327.083 810-337q24.359-31.075 37.179-67.906Q860-441.736 860-480.868 860-520 847-556q-13-36-39-65-9-10-10-23.5t8.087-22.587Q816-677 829.5-677t22.5 11q33 38 50.5 85.946 17.5 47.945 17.5 99.5ZM700-860v30-30Zm100 377q0 35.368-14.096 67.974Q771.809-382.421 747-357q-3.587 3.75-9.326 6.375Q731.935-348 726.196-348 714-348 705-356.816q-9-8.817-9-21.306 0-5.878 2.5-11.378T706-400q17-17 25.5-38t8.5-44q0-23-8.5-45T706-566q-5-4-7-9.659-2-5.658-2-11.317 0-12.024 8.816-20.524 8.817-8.5 21.306-8.5 5.878 0 11.378 2t9.5 7q25 25 38.5 57.093Q800-517.813 800-483Z"
		/>
	</svg>
);
