import { ReactNode } from 'react';

export const symbol_account_circle_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M437-437Zm43 297q57 0 111.5-18.5T691-212q-47-33-100.5-53.5T480-286q-57 0-110.5 20.5T269-212q45 35 99.5 53.5T480-140Zm97-544q37 37 40.5 88T589-503q-10 13-24.5 17t-26.5-5q-10-8-9.5-21t9.5-23q21-23 20-54t-23-53q-22-22-53-23t-54 19q-10 8-22 7.5T387-650q-8-13-3-27.5t18-23.5q41-29 90.5-23.5T577-684ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-64 19.5-124.5T157-716L47-827q-9-9-8.5-21.5T48-870q9-9 21.5-9t21.5 9l764 765q9 9 8.5 21.5T854-62q-9 9-21 9t-21-9L199-674q-30 43-44.5 92.5T140-480q0 62 21.5 119.5T222-255q58-40 123-65.5T480-346q46 0 89.5 11t85.5 31l107 107q-57 57-129.5 87T480-80Zm73-473ZM275-824q47-28 99-42t106-14q80 0 153 30t130 87q57 57 87 130t30 153q0 54-13.5 105T827-277q-6 11-18 13.5t-23-4.5q-11-7-13-19.5t4-23.5q21-39 32-81.5t11-87.5q0-142-99-241t-241-99q-45 0-88 11t-82 33q-11 6-22.5 4T268-784q-8-10-6-22t13-18Z"
		/>
	</svg>
);
