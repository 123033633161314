import { Map } from 'immutable';

import VideoSequenceComment from 'pkg/models/video_sequence_comment';

import { RootState } from 'pkg/reducers';

export const findAll = (state: RootState): Map<number, VideoSequenceComment> =>
	state.videoSequenceComments.entities;

export const find = (
	state: RootState,
	commentId: number
): VideoSequenceComment =>
	findAll(state).get(commentId, new VideoSequenceComment({}));

export const findAllBySequenceId = (
	state: RootState,
	sequenceId: number
): Map<number, VideoSequenceComment> =>
	findAll(state).filter(
		(comment: VideoSequenceComment) => comment.videoSequenceId === sequenceId
	);

export const findAllTopLevelComments = (
	state: RootState,
	sequenceId: number
): Map<number, VideoSequenceComment> =>
	findAllBySequenceId(state, sequenceId).filter(
		(comment: VideoSequenceComment) => !comment.replyCommentId
	);

export const findAllReplies = (
	state: RootState,
	commentId: number
): Map<number, VideoSequenceComment> =>
	findAll(state).filter(
		(comment: VideoSequenceComment) => comment.replyCommentId === commentId
	);
