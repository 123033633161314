import styled, { css, DefaultTheme } from 'styled-components';

import * as styles from 'pkg/config/styles';

import rgba from 'pkg/rgba';
import { addCssVar } from 'pkg/cssvars';

export const Wrapper = styled.div<{ $isEditing?: boolean }>`
	margin-top: var(--spacing-3);
	display: inline-grid;
	grid-template-columns: 30px 1fr;
	column-gap: var(--spacing-3);
	max-width: 640px;

	${({ $isEditing }) =>
		$isEditing &&
		css`
			width: 100%;
		`}
`;

export const CompactWrapper = styled(Wrapper)`
	grid-template-columns: 20px 1fr;
`;

export const Container = styled.div<{ $isEditing?: boolean }>`
	display: grid;
	grid-auto-flow: row;
	row-gap: var(--spacing-3);

	${({ $isEditing }) =>
		$isEditing &&
		css`
			width: 100%;
		`}
`;

export const Avatar = styled.div``;

export const UserName = styled.span<{ theme?: DefaultTheme }>`
	margin-right: var(--spacing-1);
	display: inline-block;
	color: var(--palette-gray-600);
	font-weight: var(--font-weight-semibold);

	${({ theme }) =>
		theme?.darkMode &&
		css`
			color: var(--palette-gray-500);
		`}

	+ div {
		margin-top: var(--spacing-1);
	}
`;

const bodyBackgroundColor = addCssVar(
	(theme: DefaultTheme) => styles.palette.gray[theme.darkMode ? 700 : 200]
);

const mentionBackgroundColor = addCssVar(
	(theme: DefaultTheme) => styles.palette.blue[theme.darkMode ? 800 : 500]
);

const bodyTextColor = addCssVar((theme: DefaultTheme) =>
	theme.darkMode ? styles.palette.white : styles.palette.gray[900]
);

export const Body = styled.div`
	display: inline-block;
	padding: var(--spacing-3) var(--spacing-4);
	border-radius: var(--radius-7);
	background: ${bodyBackgroundColor};
	color: ${bodyTextColor};
	font-size: var(--font-size-sm);
	font-weight: var(--font-weight-normal);

	&[data-mentioned='true'] {
		background: ${mentionBackgroundColor};
	}

	&[data-editing='true'] {
		width: 100%;
	}

	&[data-num-embeds='1'][data-num-blocks='1'] {
		padding: 0;
		background: none;
		width: 100%;
		min-width: 280px;
	}
`;

export const Footer = styled.div<{ theme?: DefaultTheme }>`
	font-size: var(--font-size-xs);
	color: var(--palette-gray-500);
	display: inline-grid;
	grid-auto-flow: column;
	align-items: start;
	justify-content: start;
	gap: var(--spacing-2);

	${({ theme }) =>
		theme?.darkMode &&
		css`
			color: var(--palette-gray-500);
		`}
`;

export const FooterAction = styled.div<{ caution?: boolean }>`
	font-weight: var(--font-weight-semibold);
	cursor: pointer;
	color: var(--palette-blue-500);

	@media (hover: hover) {
		&:hover {
			color: ${rgba(styles.palette.blue[500], 0.7)};
		}
	}

	${({ caution }) =>
		caution &&
		css`
			color: var(--palette-red-500);

			@media (hover: hover) {
				&:hover {
					color: ${rgba(styles.palette.red[500], 0.7)};
				}
			}
		`}
`;

export const FooterContextTrigger = styled.div`
	font-weight: var(--font-weight-semibold);
	cursor: pointer;
	padding: 0 var(--spacing-2);

	@media (hover: hover) {
		&:hover {
			color: ${rgba(styles.palette.blue[500], 0.7)};
		}
	}
`;

export const FooterActionDivider = (): JSX.Element => <span>&bull;</span>;
