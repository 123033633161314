import { ReactNode } from 'react';

export const symbol_follow_the_signs: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M388.08-754q-30.08 0-51.58-21.42-21.5-21.421-21.5-51.5 0-30.08 21.42-51.58 21.421-21.5 51.5-21.5 30.08 0 51.58 21.42 21.5 21.421 21.5 51.5 0 30.08-21.42 51.58-21.421 21.5-51.5 21.5ZM267-378 193-63q-2 11-10.328 17-8.327 6-19.189 6Q149-40 139.5-51T133-76l110-553-103 46v103q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Q97-450 88.5-458.625T80-480v-123q0-9.081 5.04-16.649Q90.08-627.216 98-631l174-73q30-13 62-6.5t48 31.5l42 66q26 41 63.5 67.5T571-512q13 2 21.5 10.675 8.5 8.676 8.5 21.5 0 12.825-9 21.325t-21 6.5q-56-7-102.5-36.5T384-572l-41 156 72 65q10 8 15 19.922T435-306v236q0 12.75-8.675 21.375Q417.649-40 404.825-40 392-40 383.5-48.625T375-70v-210l-108-98Zm413-227H560q-12.75 0-21.375-8.625T530-635v-215q0-12.75 8.625-21.375T560-880h290q12.75 0 21.375 8.625T880-850v215q0 12.75-8.625 21.375T850-605H730v540q0 10.833-7.116 17.917-7.117 7.083-18 7.083Q694-40 687-47.083q-7-7.084-7-17.917v-540Zm38-112-13 13q-8 7.636-8 17.818Q697-676 705-668q8 8 18 8t18-8l53-53q5-5 7-10.133 2-5.134 2-11Q803-748 801-753q-2-5-7-10l-53-53q-7.636-8-17.818-8Q713-824 705-816q-8 8-8 18t8 18l13 13H612q-10.833 0-17.917 7.116-7.083 7.117-7.083 18Q587-731 594.083-724q7.084 7 17.917 7h106Z"
		/>
	</svg>
);
