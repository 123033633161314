import { ReactNode } from 'react';

export const symbol_videogame_asset: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-240q-24 0-42-18t-18-42v-360q0-24 18-42t42-18h680q24 0 42 18t18 42v360q0 24-18 42t-42 18H140Zm0-60h680v-360H140v360Zm150-150v74q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T350-376v-74h74q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T424-510h-74v-74q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T290-584v74h-74q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T216-450h74Zm303 86q18 0 31.5-13.5T638-409q0-18-13.5-31.5T593-454q-18 0-31.5 13.5T548-409q0 18 13.5 31.5T593-364Zm90-143q18 0 31.5-13.5T728-552q0-18-13.5-31.5T683-597q-18 0-31.5 13.5T638-552q0 18 13.5 31.5T683-507ZM140-300v-360 360Z"
		/>
	</svg>
);
