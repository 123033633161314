import { ReactNode } from 'react';

export const symbol_cinematic_blur: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m140-840 58 119q7.729 15.4 22.083 24.2Q234.438-688 251-688q32.5 0 49.25-27.462Q317-742.923 303-772l-33-68h89l58 119q7.729 15.4 22.083 24.2Q453.438-688 470-688q32.5 0 49.25-27.462Q536-742.923 522-772l-33-68h89l58 119q7.729 15.4 22.083 24.2Q672.438-688 689-688q32.5 0 49.25-27.462Q755-742.923 741-772l-33-68h112q24 0 42 18t18 42v600q0 24-18 42t-42 18H140q-24 0-42-18t-18-42v-600q0-24 18-42t42-18Zm0 212v448h680v-448H140Zm0 0v448-448Zm203 388h274q9.517 0 16.259-6.469Q640-252.938 640-263q-1-24-12-43.5T598-337q-22-12-49.5-17.5t-68-5.5q-40.5 0-68 5.5T363-336.615Q343-326 332-306.5T320-263q0 10.062 6.5 16.531Q333-240 343-240Zm137-180q30.525 0 52.263-21.737Q554-463.475 554-494t-21.737-52.263Q510.525-568 480-568t-52.263 21.737Q406-524.525 406-494t21.737 52.263Q449.475-420 480-420Z"
		/>
	</svg>
);
