import { useAccountIdentity } from 'pkg/identity/account';
import { MessageType, useWebSocketClient } from 'pkg/websocket';

interface UnreadChatCountMessage {
	organizationId: number;
	unreadChatCount: number;
}

// This component just listenes to the websocket message and updates the account identity state
// with unreadChatCount for each organization
export default function ChatUnreadCountByOrganizationObserver(): JSX.Element {
	const ws = useWebSocketClient();
	const { organizations, setOrganizations } = useAccountIdentity();

	ws.onMessage<UnreadChatCountMessage[]>(
		MessageType.ChatUnreadCountByOrganizationId,
		(message) => {
			const grouped = message.data.reduce(
				(gr, data) => {
					gr[data.organizationId] = data.unreadChatCount;
					return gr;
				},
				{} as { [key: string]: number }
			);
			organizations.forEach((org) => {
				org.unreadChatCount = grouped[org.id] || 0;
			});

			setOrganizations(organizations);
		}
	);

	return null;
}
