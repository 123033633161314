import { t } from '@transifex/native';
import { Fragment, SyntheticEvent } from 'react';

import * as models from 'pkg/api/models';
import uuid from 'pkg/uuid';

import Icon from 'components/icon';

import Column from 'components/layout/column';
import Row from 'components/layout/row';
import * as Input from 'components/form/inputs';
import SimpleAutoComplete, {
	SimpleAutoCompleteSuggestion,
} from 'components/form/inputs/simple-autocomplete';

import * as css from './styles.css';

interface MetadataRowProps {
	metadataKeys: string[];

	onUpdateMetadata: (metadata: models.product.ProductMetadata) => void;
	handleRemoveMetadata: (id: number | string) => void;

	row: models.product.ProductMetadata;
}

function MetadataRow({
	metadataKeys,
	row,
	onUpdateMetadata,
	handleRemoveMetadata,
}: MetadataRowProps) {
	const suggestions: SimpleAutoCompleteSuggestion = {
		label: t('Existing keys'),
		items: metadataKeys,
		onSelect: (value: string) => onKeyChange(value),
		renderWith: (item: string) => <span>{item}</span>,
	};

	const onKeyChange = (value: string) => {
		onUpdateMetadata({
			key: value,
			value: row.value,
			id: row.id,
		} as models.product.ProductMetadata);
	};

	const onValueChange = (event: SyntheticEvent<HTMLInputElement>) => {
		onUpdateMetadata({
			key: row.key,
			value: event.currentTarget.value,
			id: row.id,
		} as models.product.ProductMetadata);
	};

	const handleRemove = () => {
		handleRemoveMetadata(row.id);
	};

	return (
		<Row align="center" columns="0.6fr 1fr auto">
			<Input.Group label={t('Key')}>
				<SimpleAutoComplete defaultValue={row.key} suggestions={suggestions} />
			</Input.Group>
			<Input.Group label={t('Field')}>
				<Input.Field required value={row.value} onChange={onValueChange} />
			</Input.Group>
			<Icon name="delete" className={css.icon} onClick={handleRemove} />
		</Row>
	);
}

interface ProductMetadataProps {
	metadataKeys: string[];

	metadata: models.product.ProductMetadata[];

	onUpdateMetadata: (metadata: models.product.ProductMetadata) => void;
	handleRemoveMetadata: (id: number | string) => void;
}

function ProductMetadata({
	metadata,
	onUpdateMetadata,
	handleRemoveMetadata,
	metadataKeys,
}: ProductMetadataProps) {
	const handleNew = () => {
		onUpdateMetadata({
			id: uuid(),
			key: '',
			value: '',
		} as models.product.ProductMetadata);
	};

	return (
		<Fragment>
			<Column>
				{metadata.map((row) => (
					<MetadataRow
						key={row.id}
						row={row}
						onUpdateMetadata={onUpdateMetadata}
						handleRemoveMetadata={handleRemoveMetadata}
						metadataKeys={metadataKeys}
					/>
				))}
				<span onClick={handleNew} className={css.newItem}>
					<Icon name="add" />
					{t('Add item')}
				</span>
			</Column>
		</Fragment>
	);
}

export default ProductMetadata;
