import { t } from '@transifex/native';
import { Fragment, useState } from 'react';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';

import uuid from 'pkg/uuid';
import * as models from 'pkg/api/models';
import Link from 'pkg/router/Link';
import { Field } from 'pkg/api/models/form';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';

import { getContactFields } from 'routes/forms/hooks/sections/fields';

import * as Card from 'components/Card';
import Icon from 'components/icon';
import StepModal, { Step } from 'components/step-modal';

import * as iconStyles from 'components/icon/styles.css';
import Column from 'components/layout/column';
import Row from 'components/layout/row';
import InfoBox from 'components/form/info-box';
import * as Input from 'components/form/inputs';

import * as Table from 'design/table';

const AddOption = styled(Link)`
	.${iconStyles.icon} {
		margin-right: var(--spacing-2);
	}
`;

interface FieldsModalProps {
	userFields: models.userFields.UserField[];
	prevFields: Field[];
	onDone: (fields: Field[]) => Promise<void>;
	onClose: () => void;
}

const EditOption = styled.div`
	display: flex;
	align-items: center;
	gap: var(--spacing-4);
`;

export const FieldsModal = ({
	userFields,
	prevFields = [],
	onDone,
	onClose,
}: FieldsModalProps) => {
	const [selectedFields, setSelectedFields] = useState<Field[]>([]);

	const fields = [
		...getContactFields(),
		...userFields
			.filter(
				(field) =>
					field.type !== models.userFields.UserFieldType.Attachment &&
					field.type !== models.userFields.UserFieldType.Reserved
			)
			.map((field) => ({
				key: field.key,
				type: field.type,
				label: field.label,
				description: field.description,
				values: field.values,
				sortOrder: field.sortOrder,
				required: false,
				userFieldId: field.id,
			})),
	] as Field[];

	const requiredSelectedFields = selectedFields.filter((item) => item.required);

	const selectableFields = fields.filter(
		(field) => !prevFields.find((prevField) => prevField.key === field.key)
	);

	const handleSelect = (fieldKey: string) => {
		setSelectedFields((prev: Field[]) => {
			if (prev.find((field) => field.key === fieldKey)) {
				return prev.filter((field) => field.key !== fieldKey);
			}

			return [
				...prev,
				selectableFields.find((field) => field.key === fieldKey),
			];
		});
	};

	const handleSelectAllFields = () => {
		setSelectedFields(
			selectedFields.length === selectableFields.length ? [] : selectableFields
		);
	};

	const handleSetAllRequired = () => {
		setSelectedFields((prev) => {
			return prev.map((field) => ({ ...field, required: !field.required }));
		});
	};

	const handleRequired = (fieldKey: string) => {
		setSelectedFields((prev: Field[]) => {
			const field =
				prev.find((field) => field.key === fieldKey) ||
				selectableFields.find((field) => field.key === fieldKey);

			return [
				...prev.filter((prevField) => prevField.key !== fieldKey),
				{ ...field, required: field.required ? !field.required : true },
			];
		});
	};

	const handleNext = async () => {
		// only use sortOrder-numbers in the range of selectedFields.length to prevent duplicate sortOrders when rearanging
		return onDone(
			selectedFields
				.sort((a, b) => a.sortOrder - b.sortOrder)
				.map((field, index) => {
					field.sortOrder = prevFields.length + index;
					return field;
				})
		);
	};

	return (
		<StepModal onClose={onClose}>
			<Step
				title={t('Add fields')}
				description={t(
					'Default fields populate contacts, and will be displayed in the contact lists'
				)}
				skipBody
				canGoNext={!!selectedFields.length}
				nextLabel={t('Done')}
				onNext={handleNext}>
				<Table.Table
					columns={[
						{
							content: (
								<Input.Control
									type="checkbox"
									checked={selectedFields.length === selectableFields.length}
									onChange={handleSelectAllFields}
									testid="form.create.select_all_fields"
									label={t('Field')}
								/>
							),
						},
						{
							content: (
								<Input.Control
									type="checkbox"
									onChange={handleSetAllRequired}
									checked={
										selectedFields.length > 0 &&
										selectableFields.length === requiredSelectedFields.length
									}
									disabled={selectableFields.length === 0}
									label={t('Required')}
								/>
							),
						},
					]}>
					{selectableFields.map((selectableField: Field) => {
						const selectedField = selectedFields.find(
							(field: Field) => field.key === selectableField.key
						);

						return (
							<Table.Row key={selectableField.key}>
								<Table.Cell>
									<Input.Control
										type="checkbox"
										checked={!!selectedField}
										label={selectableField.label}
										onChange={() => handleSelect(selectableField.key)}
									/>
								</Table.Cell>
								<Table.Cell>
									<Input.Control
										type="checkbox"
										checked={selectedField ? !!selectedField.required : false}
										onChange={() => handleRequired(selectableField.key)}
										standalone
									/>
								</Table.Cell>
							</Table.Row>
						);
					})}
				</Table.Table>
			</Step>
		</StepModal>
	);
};

interface CustomFieldProps {
	addedFields: Field[];
	prevData?: Field;
	onDone: (data: Field, isEdit: boolean) => void;
	onClose: () => void;
}

export const CustomFieldModal: React.FC<CustomFieldProps> = ({
	addedFields,
	prevData,
	onDone,
	onClose,
}) => {
	// GET_OPTION_NAME will act as both field-name and field-value
	const GET_OPTION_NAME = (index: number): string => `option_${index}`;
	const GET_OPTION_DEFAULT_VALUE = (index: number) =>
		t('Option number {number}', { number: index + 1 });

	const { getValues, setValue, watch } = useFormContext();
	const [isRequired, setRequired] = useState<boolean>(!!prevData?.required);
	const [options, setOptions] = useState<number[]>(
		prevData?.values ? [...Array(prevData.values.length).keys()] : [0]
	);
	const [optionInEdit, setOptionInEdit] = useState<number>(null);
	const [nextIsClicked, setNextIsClicked] = useState<boolean>(false);

	const typeSelect = watch('field_type', prevData?.type);
	const fieldLabel = watch('field_label');

	const hasChoices =
		typeSelect === models.form.FieldTypes.Choice ||
		typeSelect === models.form.FieldTypes.MultiChoice;

	const isEdit = !!prevData;

	const takenFieldName = addedFields
		.filter((field) => {
			if (isEdit) {
				return prevData.label !== field.label;
			}

			return true;
		})
		.map((field) => field.label.toLowerCase())
		.includes(fieldLabel?.toLowerCase());

	// useeffect will ignore to set the values when the component rerenders. we only want to update the fields the first render.
	useComponentDidMount(() => {
		if (isEdit) {
			setValue('field_type', prevData.type);
			setValue('field_label', prevData.label);
			setValue('field_description', prevData.description);
			setValue('field_required', prevData.required);
			setValue('field_values', prevData.values);

			// TODO: sortorder TBI
			if (prevData?.values?.length > 0) {
				for (const [index, value] of prevData.values.entries()) {
					setValue(GET_OPTION_NAME(index), value.label);
				}
			}
		}
	});

	const handleNext = async () => {
		if (!typeSelect || !fieldLabel || takenFieldName) {
			setNextIsClicked(true);
			return false;
		}

		const customFieldData: Field = {
			type: getValues('field_type'),
			key: prevData?.key ? prevData.key : uuid(),
			label: getValues('field_label'),
			description: getValues('field_description'),
			required: getValues('field_required') || false,
			sortOrder: addedFields.length,
		};

		if (hasChoices) {
			customFieldData.values = options.map((option) => ({
				label:
					getValues(GET_OPTION_NAME(option)) ||
					GET_OPTION_DEFAULT_VALUE(option),
				value: GET_OPTION_NAME(option),
			}));
		}

		onDone(customFieldData, isEdit);
		setValue('field_label', '');
		setValue('field_description', '');
	};

	const handleClose = () => {
		setValue('field_type', '');
		setValue('field_label', '');
		setValue('field_description', '');
		onClose();
	};

	const handleToggleRequired = () => {
		const next = isRequired ? false : true;

		setRequired(next);
		setValue('field_required', next);
	};

	const handleAddOption = () => {
		setOptions((prev) => [...prev, options.at(-1) + 1]);
	};

	const handleRemoveOption = (index: number) => {
		setOptions((prev) => prev.filter((option) => option != index));
		setOptionInEdit(null);
	};

	const handleOptionInEdit = (index: number) => {
		setOptionInEdit(index);
	};

	const handleResetEditOption = () => {
		setOptionInEdit(null);
	};

	const fieldTypes = Object.values(models.form.FieldTypes).filter(
		(ft) => ft != models.form.FieldTypes.Section
	);

	return (
		<StepModal onClose={handleClose}>
			<Step
				title={t(isEdit ? 'Edit' : 'Create')}
				skipBody
				nextLabel={t('Done')}
				closeOnNext={false}
				onNext={handleNext}>
				<Card.Body>
					<Column>
						<InfoBox>
							{typeSelect === models.form.FieldTypes.Section
								? t(
										'A section will show a title, a optional description and a divider from where it is put in the fields list'
									)
								: t(
										'This question will not appear on the contact card, only on the form submission'
									)}
						</InfoBox>
						{typeSelect !== models.form.FieldTypes.Section && (
							<Input.Group
								label={t('Type')}
								errorMessage={
									nextIsClicked && !typeSelect ? t('Required') : ''
								}>
								<Input.Select name="field_type">
									<option value="">{t('Select type')}</option>
									{fieldTypes.map((type: models.form.FieldTypes) => (
										<option key={type} value={type}>
											{models.form.getTranslatedType(type)}
										</option>
									))}
								</Input.Select>
							</Input.Group>
						)}
						<Input.Group
							label={t('Title')}
							errorMessage={
								nextIsClicked
									? takenFieldName
										? t('The field labels has to be unique')
										: !fieldLabel
											? t('Required')
											: ''
									: ''
							}>
							<Input.Field name="field_label" />
						</Input.Group>
						<Input.Group label={t('Description')} optional>
							<Input.Field name="field_description" />
						</Input.Group>
						{typeSelect !== models.form.FieldTypes.Section && (
							<Input.Control
								name="field_required"
								label={t('Required')}
								description={t('Required for registration submission')}
								type="checkbox"
								checked={isRequired}
								onChange={handleToggleRequired}
							/>
						)}
						{hasChoices && (
							<Fragment>
								<Card.Divider />
								{options.map((option) => (
									<Row
										key={option}
										align="center"
										onClick={() => handleOptionInEdit(option)}
										columns={`1fr${optionInEdit === option ? '' : ' auto'}`}>
										<EditOption>
											<Icon
												name={
													typeSelect === models.form.FieldTypes.Choice
														? 'radio-unchecked'
														: 'checkbox-unchecked'
												}
												size={1.4}
											/>
											{optionInEdit === option ? (
												<Input.Field
													name={GET_OPTION_NAME(option)}
													defaultValue={GET_OPTION_DEFAULT_VALUE(option)}
													onBlur={handleResetEditOption}
												/>
											) : (
												<Fragment>
													<p>
														{getValues(GET_OPTION_NAME(option)) ||
															GET_OPTION_DEFAULT_VALUE(option)}
													</p>
													<Icon name="edit" size={1.4} />
												</Fragment>
											)}
										</EditOption>
										{option > 0 && optionInEdit !== option && (
											<Icon
												name="close"
												onClick={() => handleRemoveOption(option)}
												size={1.4}
											/>
										)}
									</Row>
								))}
								<AddOption onClick={handleAddOption}>
									<Icon name="add" />
									{t('Add option')}
								</AddOption>
							</Fragment>
						)}
					</Column>
				</Card.Body>
			</Step>
		</StepModal>
	);
};

export const EditDefaultFieldModal: React.FC<CustomFieldProps> = ({
	addedFields,
	prevData,
	onDone,
	onClose,
}) => {
	const { getValues, setValue, watch } = useFormContext();
	const [isRequired, setRequired] = useState<boolean>(!!prevData?.required);
	const [nextIsClicked, setNextIsClicked] = useState<boolean>(false);

	const typeSelect = watch('field_type');
	const fieldLabel = watch('field_label');

	const isEdit = !!prevData;

	const takenFieldName = addedFields
		.filter((field) => {
			if (isEdit) {
				return prevData.label !== field.label;
			}

			return true;
		})
		.map((field) => field.label.toLowerCase())
		.includes(fieldLabel?.toLowerCase());

	// useeffect will ignore to set the values when the component rerenders. we only want to update the fields the first render.
	useComponentDidMount(() => {
		if (isEdit) {
			setValue('field_type', prevData.type);
			setValue('field_label', prevData.label);
			setValue('field_description', prevData.description);
			setValue('field_required', prevData.required);
		}
	});

	const handleNext = async () => {
		if (!typeSelect || !fieldLabel || takenFieldName) {
			setNextIsClicked(true);
			return false;
		}

		const fieldData: Field = {
			type: getValues('field_type'),
			key: prevData ? prevData.key : uuid(),
			label: getValues('field_label'),
			description: getValues('field_description'),
			required: getValues('field_required') || false,
			sortOrder: addedFields.length,
		};

		onDone(fieldData, isEdit);
		setValue('field_label', '');
		setValue('field_description', '');
	};

	const handleClose = () => {
		onClose();
		setValue('field_label', '');
		setValue('field_description', '');
	};

	const handleToggleRequired = () => {
		const next = isRequired ? false : true;

		setRequired(next);
		setValue('field_required', next);
	};

	return (
		<StepModal onClose={handleClose}>
			<Step
				title={t(isEdit ? 'Edit' : 'Create')}
				skipBody
				nextLabel={t('Done')}
				closeOnNext={false}
				onNext={handleNext}>
				<Card.Body>
					<Column>
						<Input.Group
							label={t('Title')}
							errorMessage={
								nextIsClicked
									? takenFieldName
										? t('The field labels has to be unique')
										: !fieldLabel
											? t('Required')
											: ''
									: ''
							}>
							<Input.Field name="field_label" />
						</Input.Group>
						<Input.Group label={t('Description')} optional>
							<Input.Field name="field_description" />
						</Input.Group>
						<Input.Control
							name="field_required"
							label={t('Required')}
							description={t('Required for registration submission')}
							type="checkbox"
							checked={isRequired}
							onChange={handleToggleRequired}
						/>
					</Column>
				</Card.Body>
			</Step>
		</StepModal>
	);
};
