import { AUTH_LOGOUT_SUCCESS } from 'pkg/actions/action-types';

const initialState = {
	entities: [],
};

const positions = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_POSITIONS': {
			return {
				...state,
				entities: [...action.positions],
			};
		}

		case AUTH_LOGOUT_SUCCESS:
			return initialState;
		default:
			return {
				...state,
			};
	}
};

export default positions;
