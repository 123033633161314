import styled, { css } from 'styled-components';

import { small } from 'pkg/config/breakpoints';
import * as palette from 'pkg/config/palette';

import rgba from 'pkg/rgba';

const Wrapper = styled.div`
	width: 100%;
	padding: 20px;
	border-radius: var(--radius-5);
	position: relative;
	border: 2px dashed ${rgba(palette.gray[500], 0.7)};

	${(props) =>
		props.highlighted &&
		css`
			background: ${rgba(palette.white, 0.5)};
			border-color: ${rgba(palette.gray[400], 0.9)};

			@media ${small} {
				padding: 0;
				background: none;
				border-color: transparent;
			}
		`}

	${(props) =>
		props.centered &&
		css`
			display: flex;
			align-items: center;
			justify-content: center;
		`}
`;

export default ({ highlighted, centered, children }) => (
	<Wrapper highlighted={highlighted} centered={centered}>
		{children}
	</Wrapper>
);
