const icon = {
	name: 'google-maps',
	objects: [
		{
			type: 'path',
			attributes: {
				d: 'M11.5952 12.4177L19.2319 3.88259C19.1058 3.84354 18.9719 3.82248 18.8333 3.82248H4.35714C3.61071 3.82248 3 4.43319 3 5.17962V19.6558C3 19.7944 3.02106 19.9282 3.06011 20.0544L11.5952 12.4177Z',
				fill: '#0F9D58',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M11.5954 12.4177L3.95874 20.9529C4.08491 20.9919 4.21878 21.013 4.35734 21.013H18.8335C18.9721 21.013 19.106 20.9919 19.2321 20.9529L11.5954 12.4177Z',
				fill: '#4285F4',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M11.5955 12.4177L20.1306 20.0544C20.1696 19.9283 20.1907 19.7944 20.1907 19.6558V5.17963C20.1907 5.04108 20.1696 4.9072 20.1306 4.78104L11.5955 12.4177Z',
				fill: '#D2D2D2',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M20.1905 19.6558L12.0477 11.5129L10.4644 12.8701L18.6072 21.0129H18.8334C19.5798 21.0129 20.1905 20.4022 20.1905 19.6558Z',
				fill: '#F1F1F1',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M18.8333 3.82248L3 19.6558C3 20.4022 3.61071 21.013 4.35714 21.013H4.58333L20.1905 5.40581V5.17962C20.1905 4.43319 19.5798 3.82248 18.8333 3.82248Z',
				fill: '#FFDE48',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M18.8333 3.82248H4.35714C3.61071 3.82248 3 4.43319 3 5.17962V5.29272C3 4.54629 3.61071 3.93557 4.35714 3.93557H18.8333C19.5798 3.93557 20.1905 4.54629 20.1905 5.29272V5.17962C20.1905 4.43319 19.5798 3.82248 18.8333 3.82248Z',
				fill: 'white',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M18.8333 20.8999H4.35714C3.61071 20.8999 3 20.2892 3 19.5427V19.6558C3 20.4023 3.61071 21.013 4.35714 21.013H18.8333C19.5798 21.013 20.1905 20.4023 20.1905 19.6558V19.5427C20.1905 20.2892 19.5798 20.8999 18.8333 20.8999Z',
				fill: '#263238',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M6.84503 7.21531V8.17888H8.18407C8.07776 8.75114 7.57562 9.16733 6.84503 9.16733C6.033 9.16733 5.37253 8.47971 5.37253 7.66995C5.37253 6.86019 6.033 6.17257 6.84503 6.17257C7.21145 6.17257 7.53717 6.29924 7.79729 6.54352L8.50979 5.83103C8.07777 5.42614 7.51682 5.1796 6.84503 5.1796C5.46979 5.1796 4.35693 6.29245 4.35693 7.66769C4.35693 9.04293 5.46979 10.1558 6.84503 10.1558C8.28134 10.1558 9.2336 9.14471 9.2336 7.72424C9.2336 7.54781 9.21777 7.37817 9.18836 7.21531H6.84503V7.21531Z',
				fill: '#EEEEEE',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M17.4762 2.01294C14.9768 2.01294 12.9524 4.03734 12.9524 6.53675C12.9524 9.94544 16.7592 11.721 17.2093 16.5864C17.2229 16.7221 17.3382 16.8284 17.4762 16.8284C17.6142 16.8284 17.7318 16.7221 17.7431 16.5864C18.1932 11.721 22 9.94544 22 6.53675C22 4.03734 19.9756 2.01294 17.4762 2.01294Z',
				opacity: '0.25',
				fill: '#DB4437',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M17.4762 2.01294C14.9768 2.01294 12.9524 4.03734 12.9524 6.53675C12.9524 9.94544 16.7592 11.721 17.2093 16.5864C17.2229 16.7221 17.3382 16.8284 17.4762 16.8284C17.6142 16.8284 17.7318 16.7221 17.7431 16.5864C18.1932 11.721 22 9.94544 22 6.53675C22 4.03734 19.9756 2.01294 17.4762 2.01294Z',
				fill: '#DB4437',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M17.4764 8.12007C18.3509 8.12007 19.0597 7.41118 19.0597 6.53673C19.0597 5.66228 18.3509 4.9534 17.4764 4.9534C16.6019 4.9534 15.8931 5.66228 15.8931 6.53673C15.8931 7.41118 16.6019 8.12007 17.4764 8.12007Z',
				fill: '#7B231E',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M17.4762 2.12603C19.953 2.12603 21.9638 4.11651 21.9977 6.58425C21.9977 6.56842 22 6.55258 22 6.53675C22 4.03734 19.9756 2.01294 17.4762 2.01294C14.9768 2.01294 12.9524 4.03734 12.9524 6.53675C12.9524 6.55258 12.9547 6.56842 12.9547 6.58425C12.9886 4.11651 14.9994 2.12603 17.4762 2.12603Z',
				fill: 'white',
				fillOpacity: '0.2',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M17.7431 16.4733C17.7318 16.609 17.6142 16.7153 17.4762 16.7153C17.3382 16.7153 17.2206 16.609 17.2093 16.4733C16.7637 11.6306 12.9908 9.84819 12.9547 6.47116C12.9547 6.49378 12.9524 6.51414 12.9524 6.53676C12.9524 9.94545 16.7592 11.721 17.2093 16.5864C17.2229 16.7221 17.3382 16.8284 17.4762 16.8284C17.6142 16.8284 17.7318 16.7221 17.7431 16.5864C18.1932 11.721 22 9.94545 22 6.53676C22 6.51414 21.9977 6.49378 21.9977 6.47116C21.9616 9.84818 18.191 11.6328 17.7431 16.4733Z',
				fill: '#3E2723',
				fillOpacity: '0.2',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M17.4762 2.01294C15.9969 2.01294 14.6827 2.72318 13.8571 3.82246H4.35714C3.61071 3.82246 3 4.43318 3 5.1796V19.6558C3 20.4022 3.61071 21.0129 4.35714 21.0129H18.8333C19.5798 21.0129 20.1905 20.4022 20.1905 19.6558V10.7959C21.1405 9.4071 22 8.15627 22 6.53675C22 4.03734 19.9756 2.01294 17.4762 2.01294Z',
				fill: 'url(#paint0_radial_9100_13438)',
			},
		},
		{
			type: 'radialGradient',
			attributes: {
				id: 'paint0_radial_9100_13438',
				cx: 0,
				cy: 0,
				r: '1',
				gradientUnits: 'userSpaceOnUse',
				gradientTransform: 'translate(3.47161 4.19703) scale(23.1723)',
			},
		},
	],
};

export default icon;
