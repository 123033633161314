import { ReactNode } from 'react';

export const symbol_speed_1_75: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M680-277q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T680-337h140v-115H680q-12.75 0-21.375-8.625T650-482v-171q0-12.75 8.625-21.375T680-683h170q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-623H710v111h110q24 0 42 18t18 42v115q0 24-18 42t-42 18H680ZM515-623H375q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T375-683h140q24 0 42 16t18 40q0 3-2 13l-84 315q-2 10-10 15.5t-18.316 5.5q-14 0-22.842-11Q429-300 433-314l82-309ZM309.825-277Q297-277 288.5-285.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5ZM160-623h-53q-12.75 0-21.375-8.675Q77-640.351 77-653.175 77-666 85.625-674.5T107-683h83q12.75 0 21.375 8.625T220-653v346q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T160-307v-316Z"
		/>
	</svg>
);
