import { useT } from '@transifex/react';

import * as styles from 'pkg/config/styles';

import { useCurrentOrganization } from 'pkg/identity';
import { formatToCurrency } from 'pkg/i18n/formatters';

import {
	DiscountFormState,
	DiscountType,
} from 'routes/payments/discounts/create';
import { RecurringConfigOptions } from 'routes/payments/discounts/create/form/recurring-section';

import InfoBox from 'components/form/info-box';
import Column from 'components/layout/column';

import * as css from './styles.css';

interface PreviewInfoBoxProps {
	formState: DiscountFormState;
	code: string;
	discountValue: string;
	recurringConfigValue: RecurringConfigOptions;
	maxUsageCount: string;
}

export default function PreviewInfoBox({
	formState,
	code,
	discountValue,
	recurringConfigValue,
	maxUsageCount,
}: PreviewInfoBoxProps) {
	const t = useT();
	const org = useCurrentOrganization();
	const parsedDiscountValue = Number.parseFloat(discountValue);

	const discountValueInCurrency = formatToCurrency(
		parsedDiscountValue,
		org.currency
	);

	let discountValueText = t('Not selected');

	if (discountValue !== '' && discountValue !== undefined) {
		discountValueText =
			formState.type === DiscountType.FixedAmount
				? discountValueInCurrency
				: `${discountValue}%`;
	}

	let summaryRecurringText = 'Will only be applied to initial invoice';

	if (recurringConfigValue === RecurringConfigOptions.Every) {
		summaryRecurringText = t('Apply discount to every installment');
	} else if (recurringConfigValue === RecurringConfigOptions.SetAmount) {
		summaryRecurringText = t('Apply to {maxRecurringCount} installments', {
			maxRecurringCount: formState.maxRecurringCount,
		});
	}

	let discountPerInstallmentText = '';

	if (
		recurringConfigValue === RecurringConfigOptions.SetAmount &&
		formState.type === DiscountType.FixedAmount
	) {
		let amount = discountValueInCurrency;
		let totalAmount = formatToCurrency(
			parsedDiscountValue * formState.maxRecurringCount,
			org.currency
		);

		if (formState.splitAmount) {
			amount = formatToCurrency(
				parsedDiscountValue / formState.maxRecurringCount,
				org.currency
			);
			totalAmount = discountValueInCurrency;
		}

		discountPerInstallmentText = t('{amount} (total {totalAmount})', {
			amount,
			totalAmount,
		});
	}

	return (
		<InfoBox color="yellow" colorSwatch={100} className={css.previewBox}>
			<Column justify="start">
				<div className={css.summary}>{t('Summary')}</div>
				{code !== '' && code !== undefined && (
					<div className={css.textRow}>
						<Column justify="start" spacing={styles.spacing._2}>
							<div>{t('Discount code')}</div>
							<code className={css.code}>{code}</code>
						</Column>
					</div>
				)}
				<div className={css.textRow}>
					{t('Discount value')}
					<div className={css.textRowValue}>{discountValueText}</div>
				</div>

				<div className={css.textRow}>
					{t('Recurrence')}
					<div className={css.textRowValue}>{summaryRecurringText}</div>
				</div>

				{formState.showUsageCount && (
					<div className={css.textRow}>
						{t('Redemption limit')}
						<div className={css.textRowValue}>
							{t('{maxUsageCount} time(s)', {
								maxUsageCount: maxUsageCount,
							})}
						</div>
					</div>
				)}

				{recurringConfigValue === RecurringConfigOptions.SetAmount &&
					formState.type === DiscountType.FixedAmount && (
						<div className={css.textRow}>
							{t('Discount per installment')}
							<div className={css.textRowValue}>
								{discountPerInstallmentText}
							</div>
						</div>
					)}
			</Column>
		</InfoBox>
	);
}
