import { Fragment, useState } from 'react';
import { useT } from '@transifex/react';

import spacing from 'pkg/config/spacing';
import { palette } from 'pkg/config/styles';

import * as routes from 'pkg/router/routes';
import { useCurrentOrganization, useCurrentUser } from 'pkg/identity';
import * as models from 'pkg/api/models';
import * as actions from 'pkg/actions';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';

import Badge from 'components/Badge';
import * as Card from 'components/Card';
import MaterialSymbol from 'components/material-symbols';

import Row from 'components/layout/row';
import Column from 'components/layout/column';

import Button from 'design/button';

import * as css from './styles.css';

interface IdentityProviderItemProps {
	provider: models.identityProvider.IdentityProvider;
	userIdentityData: models.user.IdentityData[];
}

function IdentityProviderItem({
	provider,
	userIdentityData = null,
}: IdentityProviderItemProps) {
	const t = useT();

	const isConnected = userIdentityData?.some(
		(data) => data.identityProviderId === provider.id
	);

	const handleClick = (
		identityProvider: models.identityProvider.IdentityProvider
	) => {
		actions.sso.startLogin({
			identityProvider,
			state: { nextRoute: routes.Account.Settings.Show('general') },
		});
	};

	return (
		<div className={css.providerWrapper}>
			<Column spacing={spacing._2}>
				<div className={css.name}>{provider.displayName}</div>
				<div className={css.hint}>
					{t('If connected, you can sign in using {displayName}', {
						displayName: provider.displayName,
					})}
				</div>
			</Column>
			{isConnected ? (
				<Row spacing={spacing._3} autoColumns="max-content" align="center">
					<MaterialSymbol
						actualSize
						scale={1.3}
						fill={palette.green[500]}
						variant="check_circle"
					/>
					<span className={css.connected}>{t('Connected')}</span>
				</Row>
			) : (
				<Button
					icon="key"
					label={t('Connect')}
					onClick={() => handleClick(provider)}
				/>
			)}
		</div>
	);
}

export default function OrganizationAuthOptions() {
	const organization = useCurrentOrganization();
	const [stateOrg, setStateOrg] = useState<{
		name: string;
		profileImageUrl: string;
		identityProviders: models.identityProvider.IdentityProvider[];
	}>({
		name: '',
		profileImageUrl: '',
		identityProviders: [],
	});
	const user = useCurrentUser();

	useComponentDidMount(async () => {
		if (!organization) {
			return;
		}

		const [ok, resp] = await actions.auth.fetchAuthOptions(organization.slug);

		if (!ok) {
			return;
		}

		setStateOrg({
			name: resp.organizationName,
			profileImageUrl: resp.profileImageUrl,
			identityProviders: resp.identityProviders,
		});
	});

	if (stateOrg.identityProviders.length === 0) {
		return null;
	}

	return (
		<Card.Base>
			<Card.Header>
				<Row autoColumns="max-content" spacing={spacing._2} align="center">
					<Badge size="40px" badgeUrl={stateOrg.profileImageUrl} />
					<Card.Heading>{stateOrg.name}</Card.Heading>
				</Row>
			</Card.Header>
			<Card.Divider />
			<Card.Body>
				<Column spacing={spacing._6}>
					{stateOrg.identityProviders.map((p, i, arr) => (
						<Fragment key={i}>
							<IdentityProviderItem
								provider={p}
								userIdentityData={user.identityData}
							/>
							{i !== arr.length - 1 && <Card.Divider />}
						</Fragment>
					))}
				</Column>
			</Card.Body>
		</Card.Base>
	);
}
