import { Fragment } from 'react';
import { t } from '@transifex/native';

import useConfirm from 'pkg/hooks/useConfirm';
import * as routes from 'pkg/router/routes';
import * as actions from 'pkg/actions';
import {
	useCurrentAccount,
	useCurrentMembership,
	useCurrentOrganization,
	useCurrentUser,
} from 'pkg/identity';
import store from 'pkg/store/createStore';
import { pushState } from 'pkg/router/state';
import Link from 'pkg/router/Link';
import { cssClasses } from 'pkg/css/utils';
import * as models from 'pkg/api/models';

import Avatar from 'components/avatar';

import NavigationItems from 'components/navigation/menus/NavigationItems';
import ContextSwitcher from 'components/navigation/menus/context_switch';
import { useAppState } from 'components/application/state';
import { toggleDrawer } from 'components/application/drawer';

import * as Drawer from 'design/navigation/drawer';

import * as css from './styles.css';

function BottomItems() {
	const membership = useCurrentMembership();
	const account = useCurrentAccount();
	const org = useCurrentOrganization();
	const user = useCurrentUser();

	const appState = useAppState();
	const collapsedDrawer = appState.collapsedDrawer;

	const handleCollapseToggle = () => toggleDrawer(appState);

	const onLogout = useConfirm({
		message: t('Are you sure you want to log out?'),
		confirmLabel: t('Log out'),
		onConfirm: () => {
			actions.auth.logout()(store.dispatch);
			pushState(routes.Home());
		},
	});

	let profileRoute: string;

	if (user) {
		profileRoute = membership.isOrganizationMembership
			? routes.Profile.Show(org.id, membership.userId)
			: routes.User.Profile.Show(
					org.id,
					membership.groupId,
					membership.userId,
					'overview'
				);
	}

	return (
		<Drawer.ItemGroup>
			<div className={css.myPages}>
				{user ? (
					<Link
						className={cssClasses(css.user, css.clickable)}
						href={profileRoute}>
						<div
							data-testid="navigation.first_name"
							className={css.nameAndTitle}>
							{t('Logged in as')} {account.firstName}
						</div>
					</Link>
				) : (
					<div className={cssClasses(css.user)}>
						<div
							data-testid="navigation.first_name"
							className={css.nameAndTitle}>
							{t('Logged in as')} {account.firstName}
						</div>
					</div>
				)}

				<div className={css.itemGroup}>
					{user && (
						<Drawer.Item
							href={profileRoute}
							name={t('Profile')}
							small
							myPages
							avatar={<Avatar size={18} user={user} />}
						/>
					)}

					{(models.membership.isParent(membership) ||
						models.account.isAnAdult(account)) && (
						<Drawer.Item
							href={routes.Account.Settings.Show('children')}
							name={t('My children')}
							icon="supervisor_account"
							small
							myPages
						/>
					)}
					<Drawer.Item
						href={routes.Account.Settings.Show('general')}
						name={t('Account settings')}
						icon="settings"
						testid="navigation.settings"
						small
						myPages
					/>
					{user && (
						<Drawer.Item
							href={routes.Self.Billing(org.id)}
							name={t('Billing')}
							icon="credit_card"
							small
							myPages
						/>
					)}
					<Drawer.Item
						href={routes.Support()}
						name={t('Support')}
						icon="help"
						small
						myPages
					/>
					<Drawer.Item
						onClick={onLogout}
						name={t('Log out')}
						icon="logout"
						testid="navigation.logout"
						small
						myPages
					/>
				</div>
				<div className={css.divider} />
				<div className={css.logout}>
					<Drawer.Item
						onClick={handleCollapseToggle}
						name={t('Collapse drawer')}
						icon={collapsedDrawer ? 'left_panel_open' : 'left_panel_close'}
						small
						myPages
					/>
				</div>
			</div>
		</Drawer.ItemGroup>
	);
}

export default function LargeScreenNavigation(): JSX.Element {
	return (
		<Drawer.Menu>
			<Fragment>
				<ContextSwitcher />
				<Drawer.Items>
					<NavigationItems />
				</Drawer.Items>
				<BottomItems />
			</Fragment>
		</Drawer.Menu>
	);
}
