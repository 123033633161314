import { ReactNode } from 'react';

export const symbol_gpp_bad: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m480-436 72 69q9 9 22 9t22-9q10-10 10-23t-10-22l-70-69 70-70q10-9 10-22t-10-23q-9-9-22-9t-22 9l-72 69-72-69q-9-9-21.5-9.5T364-596q-9 9-9.5 22.5T364-551l70 70-70 69q-10 9-10 22t10 23q9 9 22 9t22-9l72-69Zm0 352q-5 0-9.5-1t-9.5-3q-139-47-220-168.5T160-523v-196q0-19 11-34.5t28-22.5l260-97q11-4 21-4t21 4l260 97q17 7 28 22.5t11 34.5v196q0 145-81 266.5T499-88q-5 2-9.5 3t-9.5 1Zm0-59q115-38 187.5-143.5T740-523v-196l-260-98-260 98v196q0 131 72.5 236.5T480-143Zm0-337Z"
		/>
	</svg>
);
