import { ReactNode } from 'react';

export const symbol_diversity_4: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-140v-320 320Zm680 0v-320 320Zm-60-480q25.5 0 42.75-17.25T820-680q0-25.5-17.25-42.75T760-740q-25.5 0-42.75 17.25T700-680q0 25.5 17.25 42.75T760-620Zm0 60q-51 0-85.5-34.5T640-680q0-50 34.5-85t85.5-35q50 0 85 35t35 85q0 51-35 85.5T760-560ZM480.157-660Q513-660 536.5-683t23.5-57.157Q560-773 536.667-796.5 513.333-820 480-820q-34 0-57 23.333-23 23.334-23 56.667 0 34 23 57t57.157 23ZM480-600q-59 0-99.5-40.5T340-740q0-58 40.5-99t99.5-41q58 0 99 41t41 99q0 59-41 99.5T480-600ZM300-425q0 34.636 36.5 80.818Q373-298 480-201q105.75-96.333 142.875-141.667Q660-388 660-425q0-32-20.769-53.5T588-500q-17.59 0-33.295 9Q539-482 525-470l-45 42-46-42q-14-12-29.5-21t-32.833-9Q341-500 320.5-478.182T300-425Zm-60 0q0-53 40-94t92-41q30 0 58.873 14 28.872 14 49.134 38 20.261-24 48.627-38Q557-560 588-560q51 0 91.5 41t40.5 94q0 53-38.5 104.5T524-160l-44 40-44-40Q315-270 277.5-321T240-425Zm-40-195q25.5 0 42.75-17.25T260-680q0-25.5-17.25-42.75T200-740q-25.5 0-42.75 17.25T140-680q0 25.5 17.25 42.75T200-620ZM483-80v-60h337v-320H678v-60h142q24.75 0 42.375 17.625T880-460v350q0 12.75-8.625 21.375T850-80H483Zm-343-60h343v60H110q-12.75 0-21.375-8.625T80-110v-350q0-24.75 17.25-42.375T140-520h141v60H140v320Zm60-420q-51 0-85.5-34.5T80-680q0-50 34.5-85t85.5-35q50 0 85 35t35 85q0 51-35 85.5T200-560Zm280-180Zm-280 60Zm560 0Z"
		/>
	</svg>
);
