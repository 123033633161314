const AvatarUpload = {
	name: 'avatar-upload',
	viewBox: '0 0 48 48',
	objects: [
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M23.0497 19.6998C22.1301 19.6998 21.231 19.9725 20.4663 20.4834C19.7016 20.9944 19.1056 21.7206 18.7537 22.5703C18.4017 23.42 18.3096 24.3549 18.4891 25.257C18.6685 26.159 19.1114 26.9875 19.7617 27.6378C20.412 28.2882 21.2406 28.731 22.1426 28.9105C23.0446 29.0899 23.9795 28.9978 24.8292 28.6458C25.6789 28.2939 26.4051 27.6979 26.9161 26.9332C27.427 26.1685 27.6998 25.2695 27.6998 24.3498C27.6998 23.1165 27.2098 21.9338 26.3378 21.0617C25.4657 20.1897 24.283 19.6998 23.0497 19.6998ZM18.9663 18.2385C20.175 17.4308 21.596 16.9998 23.0497 16.9998C24.9991 16.9998 26.8686 17.7741 28.247 19.1525C29.6254 20.5309 30.3998 22.4004 30.3998 24.3498C30.3998 25.8035 29.9687 27.2245 29.1611 28.4332C28.3534 29.6419 27.2055 30.584 25.8625 31.1403C24.5194 31.6966 23.0416 31.8422 21.6158 31.5586C20.1901 31.275 18.8804 30.575 17.8525 29.547C16.8246 28.5191 16.1245 27.2095 15.8409 25.7837C15.5573 24.3579 15.7029 22.8801 16.2592 21.537C16.8155 20.194 17.7576 19.0461 18.9663 18.2385Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M15.1899 12.8922C15.1899 10.6597 16.9997 8.84988 19.2322 8.84988L26.273 8.84985C27.0186 8.84985 27.623 9.45427 27.623 10.1999C27.623 10.9454 27.0186 11.5499 26.273 11.5499L19.2322 11.5499C18.4909 11.5499 17.8899 12.1509 17.8899 12.8922C17.8899 15.1247 16.0801 16.9345 13.8476 16.9345H10.7799C9.40467 16.9345 8.28985 18.0493 8.28985 19.4245V34.2C8.28985 35.6635 9.4763 36.85 10.9399 36.85H34.94C36.4035 36.85 37.59 35.6635 37.59 34.2V23C37.59 22.2544 38.1944 21.65 38.94 21.65C39.6856 21.65 40.29 22.2544 40.29 23V34.2C40.29 37.1547 37.8947 39.55 34.94 39.55H10.9399C7.98513 39.55 5.58984 37.1547 5.58984 34.2V19.4245C5.58984 16.5581 7.91349 14.2345 10.7799 14.2345H13.8476C14.5889 14.2345 15.1899 13.6335 15.1899 12.8922Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M31.6001 10.3505C31.6001 9.60491 32.2045 9.00049 32.9501 9.00049H44.9101C45.6557 9.00049 46.2601 9.60491 46.2601 10.3505C46.2601 11.0961 45.6557 11.7005 44.9101 11.7005H32.9501C32.2045 11.7005 31.6001 11.0961 31.6001 10.3505Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M38.9503 3.00024C39.6959 3.00024 40.3004 3.60466 40.3004 4.35025V16.3303C40.3004 17.0759 39.6959 17.6803 38.9503 17.6803C38.2048 17.6803 37.6003 17.0759 37.6003 16.3303V4.35025C37.6003 3.60466 38.2048 3.00024 38.9503 3.00024Z',
			},
		},
	],
};

export default AvatarUpload;
