import { ReactNode } from 'react';

export const symbol_vertical_align_center: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m450-282-64 64q-8.556 9-21.778 9T342-218q-9-9-9-22.222 0-13.222 9-21.778l117-117q5-5 10.133-7 5.134-2 11-2Q486-388 491-386q5 2 10 7l117 117q9 8.556 9 21.778T618-218q-9 9-22.222 9-13.222 0-21.778-9l-64-64v172q0 12.75-8.675 21.375Q492.649-80 479.825-80 467-80 458.5-88.625T450-110v-172ZM190-450q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T190-510h580q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T770-450H190Zm260-228v-172q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-850v172l64-64q8.556-9 21.778-9T618-742q9 9 9 22.222 0 13.222-9 21.778L501-581q-5 5-10.133 7-5.134 2-11 2Q474-572 469-574q-5-2-10-7L342-698q-9-8.556-9-21.778T342-742q9-9 22.222-9 13.222 0 21.778 9l64 64Z"
		/>
	</svg>
);
