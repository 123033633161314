import { ReactNode } from 'react';

export const symbol_tour: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M260-410v300q0 12.75-8.675 21.375Q242.649-80 229.825-80 217-80 208.5-88.625T200-110v-740q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T260-850v54h535q15.867 0 24.933 13.5Q829-769 823-754l-64 151 64 151q6 15-3.067 28.5Q810.867-410 795-410H260Zm0-326v266-266Zm241.212 205Q531-531 552-552.212q21-21.213 21-51Q573-633 551.788-654q-21.213-21-51-21Q471-675 450-653.788q-21 21.213-21 51Q429-573 450.212-552q21.213 21 51 21ZM260-470h491l-55-133 55-133H260v266Z"
		/>
	</svg>
);
