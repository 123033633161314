import { ReactNode } from 'react';

export const symbol_pin: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm0-60h680v-520H140v520Zm0 0v-520 520Zm127.909-134Q277-354 283-359.935T289-375v-209q0-9.308-6.774-15.654T265.516-606Q261-606 258-605t-7 3l-51 34q-7 5-9 12.5t3 14.5q5 7 13 9t15-3l25-17v177q0 9.13 5.909 15.065 5.909 5.935 15 5.935Zm132.576 0h123.03q8.485 0 13.985-5.7T543-373q0-7.6-5.7-13.3-5.7-5.7-13.3-5.7h-90v-2q14-12 30.5-26.5T494-448q21-20 33-38.5t12-44.5q0-31.985-22-53.493Q495-606 460-606q-24 0-42 9.058t-29 25.268q-5 6.674-2 14.174 3 7.5 11 11.5t16 1.5q8-2.5 15-7.5t15-8.5q8-3.5 16-3.5 18 0 28 10t10 24q0 20-10 33.5T461-468q-11 11-20.5 19.5T421-431l-35 32q-2 1.957-6 13.696v11.739q0 7.826 6 13.695 6 5.87 14.485 5.87ZM694-354q36 0 59.5-17.5T777-426q0-25-13-41t-34-18.4v-.6q19-6 28.5-19.5T768-541q0-30-21.284-47.5T693-606q-18 0-35 8t-30 24q-5 6-2.5 13t9.5 11q7 4 14.5 2.5T663-554q7-5 14.263-8T693-565q14.733 0 24.367 8.5Q727-548 727-533q0 17-11.667 23-11.666 6-30.333 6-7.6 0-13.3 5.7-5.7 5.7-5.7 13.3 0 8 5.7 14t13.3 6q19 0 35 10t16 29.185Q736-408 724.5-398.5T694-389q-11 0-22.5-6T652-412q-5-6-11-8t-13.059.143Q620-417 617-410.5q-3 6.5 0 13.5 11 20 29.5 31.5T694-354Z"
		/>
	</svg>
);
