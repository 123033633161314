const icon = {
	viewBox: '0 0 21 25',
	name: 'av-skip-forwards',
	paths: [
		'M10.5678 3.47284V0.887161L15.7392 4.76568L10.5678 8.6442V6.05852C8.51055 6.05852 6.53752 6.87578 5.08279 8.3305C3.62806 9.78523 2.81081 11.7583 2.81081 13.8156C2.81081 15.8729 3.62806 17.8459 5.08279 19.3006C6.53752 20.7553 8.51055 21.5726 10.5678 21.5726C12.6251 21.5726 14.5982 20.7553 16.0529 19.3006C17.5076 17.8459 18.3249 15.8729 18.3249 13.8156H20.9106C20.9106 16.5586 19.8209 19.1893 17.8813 21.129C15.9416 23.0686 13.3109 24.1583 10.5678 24.1583C7.82479 24.1583 5.19408 23.0686 3.25444 21.129C1.3148 19.1893 0.225128 16.5586 0.225128 13.8156C0.225128 11.0725 1.3148 8.44179 3.25444 6.50215C5.19408 4.56252 7.82479 3.47284 10.5678 3.47284Z',
		'M9.04717 11.3298H8.01058C7.99836 11.6354 7.56808 12.1562 6.92511 12.1562V12.9972C7.47763 12.9972 7.83701 12.7918 7.94458 12.6256H7.99103V16.3367H9.04717V11.3298Z',
		'M12.1979 16.4467C13.4569 16.4467 14.2172 15.4884 14.2197 13.8382C14.2221 12.2002 13.452 11.2614 12.1979 11.2614C10.9412 11.2614 10.1785 12.1977 10.176 13.8382C10.1711 15.4835 10.9364 16.4443 12.1979 16.4467ZM12.1979 15.5691C11.6233 15.5691 11.2517 14.9921 11.2542 13.8382C11.2566 12.7014 11.6258 12.1293 12.1979 12.1293C12.7675 12.1293 13.1391 12.7014 13.1391 13.8382C13.1415 14.9921 12.7699 15.5691 12.1979 15.5691Z',
	],
};

export default icon;
