import { ReactNode } from 'react';

export const symbol_nest_remote_comfort_sensor: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-120q-63 0-106.5-43.5T330-270q0-63 43.5-106.5T480-420q63 0 106.5 43.5T630-270q0 63-43.5 106.5T480-120Zm0-60q38 0 64-26t26-64q0-38-26-64t-64-26q-38 0-64 26t-26 64q0 38 26 64t64 26Zm0-600q-97 0-181.5 31T145-660q-10 8-23 8.5t-22-8.5q-9-8-8.5-21t9.5-21q78-66 173.5-102T480-840q110 0 205.5 36T859-702q9 8 9.5 21t-8.5 21q-9 9-22 8.5t-23-8.5q-69-58-153.5-89T480-780Zm0 210q-53 0-100 16t-88 44q-11 8-23.5 7T246-513q-9-8-8.5-20t10.5-20q49-36 108-56.5T480-630q65 0 123.5 20.5T711-553q10 8 10.5 20t-8.5 20q-9 9-21.5 10t-23.5-7q-40-28-87.5-44T480-570Zm0 300Z"
		/>
	</svg>
);
