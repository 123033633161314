import { ReactNode } from 'react';

export const symbol_format_underlined: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M230-140q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T230-200h500q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T730-140H230Zm250-140q-100 0-156.5-58.5T267-497v-302q0-16.882 12.527-28.941Q292.055-840 309.027-840 326-840 338-827.941T350-799v302q0 63 34 101t96 38q62 0 96-38t34-101v-302q0-16.882 12.527-28.941Q635.055-840 652.027-840 669-840 681-827.941T693-799v302q0 100-56.5 158.5T480-280Z"
		/>
	</svg>
);
