import { useRef } from 'react';
import { t } from '@transifex/native';

import FormCategory from 'pkg/models/form_category';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';

import StepModal, { Step } from 'components/step-modal';

import Form, { FormPayload } from 'components/form/Form';
import * as Input from 'components/form/inputs';
import Column from 'components/layout/column';

interface AddCategoryModalProps {
	onClose: () => void;
	handleNewCategory?: (id: string) => void;
	groupId: number;
	formCategory?: models.formCategory.FormCategory;
	refresh?: () => void;
}
interface payloadProps {
	groupId: number;
	title: string;
	description?: string;
}
const AddFormCategoryModal = ({
	onClose: hideCategoryModal,
	handleNewCategory,
	formCategory,
	groupId,
	refresh,
}: AddCategoryModalProps): JSX.Element => {
	const formRef = useRef<HTMLFormElement>();

	const handleCreateCategory = async (
		data: FormPayload
	): Promise<boolean | void> => {
		const payload = {
			title: data.title as string,
			description: data.description.toString(),
			groupId: groupId,
		};
		const [, category] = await models.create<payloadProps, FormCategory>(
			endpoints.FormCategories.Index(),
			payload
		);
		await refresh();

		if (handleNewCategory) {
			handleNewCategory(category.id.toString());
		}

		return true;
	};

	const handleNext = async () => {
		await formRef.current.dispatchEvent(
			new Event('submit', {
				cancelable: true,
				bubbles: true,
			})
		);
		return true;
	};

	const handleUpdateCategory = async (data: FormPayload) => {
		const payload = {
			title: data.title,
			description: data.description,
			groupId,
		};
		await models.update(formCategory, payload);
		refresh();

		return true;
	};

	return (
		<Form
			onSubmit={formCategory ? handleUpdateCategory : handleCreateCategory}
			formRef={formRef}>
			<StepModal onClose={hideCategoryModal}>
				<Step
					title={t('New category')}
					nextLabel={t('Done')}
					closeOnNext={true}
					onNext={handleNext}>
					<Column>
						<Input.Group label={t('New category')} required>
							<Input.Field
								name="title"
								defaultValue={formCategory?.title}></Input.Field>
						</Input.Group>
						<Input.Group label={t('Description')}>
							<Input.Field
								minRows={3}
								name="description"
								defaultValue={formCategory?.description}></Input.Field>
						</Input.Group>
					</Column>
				</Step>
			</StepModal>
		</Form>
	);
};

export default AddFormCategoryModal;
