import * as models from 'pkg/api/models';
import { ApiError } from 'pkg/errors/errors';

import SubscriptionTable from 'routes/payments/subscriptions/list';
import { UserProfileLayoutCrash } from 'routes/organization/user-profile/layout';

import * as SideBarLayout from 'components/layout/side-bar-page';
import { Spinner } from 'components/loaders/spinner';

interface OrganizationUserProfileSubscriptionsProps {
	user: models.user.User;
	organizationId: number;
	isLoading: boolean;
}

export default function OrganizationUserProfileSubscriptions({
	organizationId,
	user,
	isLoading,
}: OrganizationUserProfileSubscriptionsProps) {
	if (isLoading) {
		return <Spinner />;
	}

	if (!models.hasAllowedAction(user, models.Action.UserListSubscriptions)) {
		return <UserProfileLayoutCrash error={new ApiError(403)} />;
	}

	return (
		<SideBarLayout.Table>
			<SubscriptionTable organizationId={organizationId} userId={user.id} />
		</SideBarLayout.Table>
	);
}
