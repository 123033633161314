import { Record } from 'immutable';
import { schema } from 'normalizr';

import User from 'pkg/models/user';

interface IEventComment {
	comment: string;
	createdAt: number;
	deletedAt: number;
	eventId: number;
	id: number;
	updatedAt: number;
	user: User;
	userId: number;
	links: { delete?: string; edit?: string };
}

const EventCommentProps: IEventComment = {
	comment: null,
	createdAt: null,
	deletedAt: null,
	eventId: null,
	id: null,
	updatedAt: null,
	user: new User({}),
	userId: null,
	links: {},
};

const EventCommentSchema = new schema.Entity('eventComments', {
	user: User.normalizr(),
});

class EventComment
	extends Record(EventCommentProps, 'EventCommentsRecord')
	implements IEventComment
{
	static normalizr(): schema.Entity {
		return EventCommentSchema;
	}
}

export default EventComment;
