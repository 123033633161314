import { ReactNode } from 'react';

export const symbol_biotech: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M408-180v-104h-15q-81 0-137-56t-56-137q0-61 35-111t92-70q4-40 35-65t72-22l-11-31q-5-11.733 1-22.367Q430-809 441-814l13-5-1-4q-5-14 .645-26.843T473-868q13.636-5 26.818.5Q513-862 518-848l2 5 12-4q11-4 22 1t16 16l92 239q5 12-.5 23T644-552l-14 5 2 5q5 14-.871 26.5T611-498q-12.955 5-25.477-1Q573-505 568-518l-2-6-15 5q-12 5-22.5 0T513-536l-15-40q-15 17-35.5 24.5t-43.833 6.5Q393-546 371-561t-35-38q-35 17-55.5 49.965T260-477q0 55.417 38.792 94.208Q337.583-344 393-344h317q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T710-284H508v104h222q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T730-120H230q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T230-180h178Zm148-392 53-19-80-206-53 19 80 206Zm-130.175-23Q447-595 461.5-609.325q14.5-14.324 14.5-35.5Q476-666 461.675-680.5q-14.324-14.5-35.5-14.5Q405-695 390.5-680.675q-14.5 14.324-14.5 35.5Q376-624 390.325-609.5q14.324 14.5 35.5 14.5ZM556-572Zm-130-75Zm2 0Z"
		/>
	</svg>
);
