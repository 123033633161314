import { ReactNode } from 'react';

export const symbol_person_play: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M185-499 85-599q-9-9-9-21t9-21l100-99q9-9 21-9t21 9l99 99q9 9 9 21t-9 21l-99 100q-9 9-21 9t-21-9ZM403-80q-12.75 0-21.375-8.625T373-110v-183.415Q317-298 262-304.5 207-311 152-323q-13-3-20-13.5t-4-23.5q3-13 15-19t26-3q77 16 154.826 22.5T480-353q78.348 0 156.174-6.5Q714-366 791-382q14-3 26.5 3t15.5 19q3 13-5.566 24.029Q818.868-324.941 805-322q-54 11-108.5 17.5T587-293.415V-110q0 12.75-8.625 21.375T557-80H403ZM206-561l58-59-58-58-59 58 59 59Zm273.882-139Q434-700 402-732.118q-32-32.117-32-78Q370-856 402.118-888q32.117-32 78-32Q526-920 558-887.882q32 32.117 32 78Q590-764 557.882-732q-32.117 32-78 32Zm.198 286q-30.08 0-51.58-21.42-21.5-21.421-21.5-51.5 0-30.08 21.42-51.58 21.421-21.5 51.5-21.5 30.08 0 51.58 21.42 21.5 21.421 21.5 51.5 0 30.08-21.42 51.58-21.421 21.5-51.5 21.5Zm-.255-346Q501-760 515.5-774.325q14.5-14.324 14.5-35.5Q530-831 515.675-845.5q-14.324-14.5-35.5-14.5Q459-860 444.5-845.675q-14.5 14.324-14.5 35.5Q430-789 444.325-774.5q14.324 14.5 35.5 14.5ZM689-505l-45-80q-4-6.677-4-14.839Q640-608 644-615l45-80q4-7 11.5-11t15.5-4h89q8 0 15.5 4t11.5 11l45 80q4 6.677 4 14.839Q881-592 877-585l-45 80q-4 7-11.5 11t-15.5 4h-89q-8 0-15.5-4T689-505Zm43.667-45H788l28-50-27.667-50H733l-28 50 27.667 50ZM206-620Zm274-190Zm281 210Z"
		/>
	</svg>
);
