export function Create(): string {
	return `/v1/resource-locations`;
}

export function Delete(ID: number): string {
	return `/v1/resource-locations/${ID}`;
}

export function Index(): string {
	return `/v1/resource-locations`;
}

export function Show(ID: number): string {
	return `/v1/resource-locations/${ID}`;
}

export function Update(ID: number): string {
	return `/v1/resource-locations/${ID}`;
}
