import { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { t } from '@transifex/native';

import { Features } from 'pkg/models/group';

import * as actions from 'pkg/actions/index';

import * as models from 'pkg/api/models';
import { RootState } from 'pkg/reducers';
import store from 'pkg/store/createStore';
import useConfirm from 'pkg/hooks/useConfirm';
import { useCurrentGroup } from 'pkg/identity';

import SectionTitle from 'components/SectionTitle';
import AssetImage from 'components/AssetImage';
import * as Card from 'components/Card';

import Column from 'components/layout/column';
import InfoBox from 'components/form/info-box';
import Select from 'components/form/Select';
import Row from 'components/form/Row';
import Label from 'components/form/Label';

import MissingEntities from 'design/placeholders/block';
import Button from 'design/button';

const Text = styled.div`
	div {
		color: var(--palette-gray-700);
		font-weight: var(--font-weight-semibold);
		font-size: var(--font-size-lg);
		margin-bottom: var(--spacing-4);
		white-space: pre-line;
	}

	span {
		display: block;
		color: var(--palette-gray-500);
		margin-bottom: var(--spacing-6);
		white-space: pre-line;
	}
`;

interface DashboardProps {
	groupId: number;
}

const PaymentSetup = ({ groupId }: DashboardProps): JSX.Element => {
	const currencies = useSelector((state: RootState) => state.currencies.items);

	const activeGroup = useCurrentGroup();

	const [currency, setCurrency] = useState<string>('');

	useEffect(() => {
		if (activeGroup.paymentProviderActive) {
			actions.groups.fetchCurrencies(groupId)(store.dispatch);
		}
	}, [activeGroup.paymentProviderActive]);

	const handleStripeConnect = () => actions.groups.connectToStripe(groupId);

	const handleCurrencyChange = (e: ChangeEvent<HTMLSelectElement>) =>
		setCurrency(e.target.value);

	const handleSetCurrency = useConfirm({
		message: t(
			`You are about to set {currency} as the currency of your payment platform. Are you sure this is the correct currency?`,
			{
				currency: currency.toUpperCase(),
			}
		),
		onConfirm: () => actions.groups.update(groupId, { currency }),
	});

	const missingEntitiesImage = <AssetImage src="img/Stripe-connect.svg" />;

	return (
		<Fragment>
			{!activeGroup.paymentProviderActive &&
				activeGroup.paymentProvider === models.group.PaymentProvider.Stripe &&
				models.group.hasFeature(activeGroup, Features.Payments) && (
					<Column>
						<SectionTitle>{t(`Connect and get started`)}</SectionTitle>
						<MissingEntities
							noBorder
							title={t(`Connect and get started`)}
							description={t(
								`Before you can use the platform for payments and memberships you need to setup and connect your Stripe Account.`
							)}
							image={missingEntitiesImage}
							actions={
								<Button secondary onClick={handleStripeConnect}>
									{t(`Connect with Stripe`)}
								</Button>
							}
						/>
					</Column>
				)}
			{activeGroup.paymentProviderActive && !!!activeGroup.currency && (
				<Card.Base $noBorder>
					<Card.Body>
						<Text>
							<div>
								{t(`You’re now connected to Stripe!
 Please set your store currency.`)}
							</div>
							<span>
								{t(`Before you can add products you need to set your store currency.
This is the currency you will show on your products.`)}
							</span>
						</Text>
						<InfoBox
							text={t(
								`When you saved your store currency you cannot change it`
							)}
						/>
						<Row>
							<Label>{t(`Store currency`)}</Label>
							<Select
								name="currency"
								onChange={handleCurrencyChange}
								value={currency}>
								<option value="">{t('Select')}</option>
								{currencies
									.sort((a, b) => a.localeCompare(b))
									.map((currency, i) => (
										<option key={i} value={currency}>
											{currency.toUpperCase()}
										</option>
									))}
							</Select>
						</Row>
					</Card.Body>
					<Card.Divider />
					<Card.Footer>
						<Button disabled={currency === ''} onClick={handleSetCurrency}>
							{t(`Set store currency`)}
						</Button>
					</Card.Footer>
				</Card.Base>
			)}
		</Fragment>
	);
};

export default PaymentSetup;
