import { ReactNode } from 'react';

export const symbol_nest_detect: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M369.899-80Q316-80 278-118.071 240-156.143 240-210v-610q0-24.75 17.625-42.375T300-880h140q24.75 0 42.375 17.625T500-820v610q0 53.857-38.101 91.929Q423.798-80 369.899-80ZM300-320q20-12 37.5-16t32.5-4q15 0 32.5 4t37.5 16v-500H300v500Zm69.882 180Q399-140 419.5-160.382q20.5-20.383 20.5-49.5Q440-239 419.618-259.5q-20.383-20.5-49.5-20.5Q341-280 320.5-259.618q-20.5 20.383-20.5 49.5Q300-181 320.382-160.5q20.383 20.5 49.5 20.5ZM660-240q-24.75 0-42.375-17.625T600-300v-360q0-24.75 17.625-42.375T660-720h80q24.75 0 42.375 17.625T800-660v360q0 24.75-17.625 42.375T740-240h-80Zm0-60h80v-360h-80v360ZM300-820h140-140Zm360 520h80-80Z"
		/>
	</svg>
);
