import { ReactNode } from 'react';

export const symbol_data_usage: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M481-80q-83 0-156-31.5t-127.5-86q-54.5-54.5-86-127T80-479q0-137 84.5-246.5T382-868q26-6 45.5 8.5T447-819q0 18-10 31.5T410-771q-97 18-162.5 101.5T182-479q0 123 87 210t212 87q60 0 116-24t102-70q12-12 29-14t30 8q20 15 22 39t-15 44q-51 57-124.5 88T481-80Zm298-400q0-104-63-185T554-769q-17-4-27.5-18T516-818q0-25 20-40t46-9q131 32 215 140.5T881-484q0 28-3.5 55.5T866-374q-7 24-28 34t-44-1q-17-8-24.5-23.5T767-394q6-17 9-37.5t3-48.5Z"
		/>
	</svg>
);
