import { Fragment } from 'react';

import { withBrowserState } from 'pkg/router/Router';
import { PaymentProviderContextProvider } from 'pkg/contexts/provider_settings';
import { isCypress } from 'pkg/flags';
import { WebSocketProvider } from 'pkg/websocket';
import { IdentityProvider } from 'pkg/identity';
import { AccountIdentityProvider } from 'pkg/identity/account';
import { OrganizationIdentityProvider } from 'pkg/identity/organization';
import { PushNotificationsObserver } from 'pkg/notifications';
import ApiToken from 'pkg/identity/api_token';

import Snackbar from 'containers/Snackbar';
import AccountParentalRequestModal from 'containers/account/ParentalRequestModal';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import { AdslotBoundary } from 'components/adslot';
import { Analytics } from 'components/analytics';
import { ArchivedContactGuard } from 'components/archived-contact';

import AdScripts from 'components/adslot/AdScripts';
import LargeScreenNavigation from 'components/navigation/menus/large_screen';
import SmallScreenNavigation from 'components/navigation/menus/small_screen';
import { PageActionsContextProvider } from 'components/navigation/header/small_screen/page_actions/Context';
import SubscriptionsObserver from 'components/account/SubscriptionsObserver';
import { HelpScoutKeyboardWatcher } from 'components/page/HelpScoutKeyboardWatcher';
import ChatUnreadCountByOrganizationObserver from 'components/chat/unread-count-observer';
import MaintenanceModeObserver from 'components/application/maintenance_mode/observer';
import { OrganizationChangeObserver } from 'components/organization/OrganizationChangeObserver';
import { ThemeWrapper } from 'components/page/ThemeWrapper';
import { CSSVariables } from 'components/page/CSSVariables';
import { ParentalConsentGuard } from 'components/page/parental-consent-guard';
import { IdentityObserver } from 'components/page/IdentityObserver';

import Routes from 'routes';

import 'css/global.css';

const Navigation = () => {
	return (
		<Fragment>
			<SmallScreen>
				<SmallScreenNavigation />
			</SmallScreen>
			<LargeScreen>
				<LargeScreenNavigation />
			</LargeScreen>
		</Fragment>
	);
};

function getCurrentUrl() {
	let currentUrl = window.location.pathname;

	if (window.location.search.length > 0) {
		currentUrl += window.location.search;
	}

	return currentUrl;
}

// Page is responsible for all things present on all pages. E.g. Navigation, Group Demo checks and so on
function PageContent() {
	return (
		<AccountIdentityProvider>
			<CSSVariables />
			<ParentalConsentGuard>
				<WebSocketProvider>
					<PushNotificationsObserver />
					<ApiToken>
						<OrganizationIdentityProvider>
							<IdentityProvider>
								<IdentityObserver>
									<ThemeWrapper>
										<HelpScoutKeyboardWatcher />
										<SubscriptionsObserver />
										<ChatUnreadCountByOrganizationObserver />
										<MaintenanceModeObserver />
										<AdslotBoundary>
											<AdScripts />
										</AdslotBoundary>
										<ArchivedContactGuard>
											<Snackbar key="app-snackbar" />
											<PaymentProviderContextProvider>
												<PageActionsContextProvider>
													<Routes currentUrl={getCurrentUrl()}>
														<OrganizationChangeObserver />
														{!isCypress() && <Analytics />}
														<Navigation />
													</Routes>
												</PageActionsContextProvider>
											</PaymentProviderContextProvider>
											<AccountParentalRequestModal key="parental-request-modal" />
										</ArchivedContactGuard>
									</ThemeWrapper>
								</IdentityObserver>
							</IdentityProvider>
						</OrganizationIdentityProvider>
					</ApiToken>
				</WebSocketProvider>
			</ParentalConsentGuard>
		</AccountIdentityProvider>
	);
}

export const Page = withBrowserState(PageContent);
