import styled, { css } from 'styled-components';

import * as styles from 'pkg/config/styles';

import { Group } from 'pkg/api/models/onboarding_info';

import Badge from 'components/Badge';

interface TeamBadgeWrapperProps {
	hasOrganization?: boolean;
}

const TeamBadgeWrapper = styled.div<TeamBadgeWrapperProps>`
	display: grid;
	gap: var(--spacing-3) var(--spacing-5);
	line-height: 1;
	color: var(--palette-white);

	${({ hasOrganization }) =>
		hasOrganization
			? css`
					grid-template-areas: 'image organization' 'image group';
					grid-template-columns: auto 1fr;
					grid-template-rows: auto auto;

					span {
						grid-area: organization;
						align-self: flex-end;
					}

					strong {
						grid-area: group;
						align-self: flex-start;
					}
				`
			: css`
					grid-template-areas: 'image group';
					grid-template-columns: auto 1fr;

					strong {
						grid-area: group;
						align-self: center;
					}
				`}

	${Badge} {
		grid-area: image;
		width: 55px;
	}

	span {
		font-size: var(--font-size-base);
		opacity: 0.5;

		@media ${styles.breakpoint.small} {
			font-size: var(--font-size-sm);
		}
	}

	strong {
		font-size: var(--font-size-2xl);
		font-weight: var(--font-weight-bold);
		@media ${styles.breakpoint.small} {
			font-size: var(--font-size-xl);
		}
	}
`;

interface TeamBadgeProps {
	group: Group;
}

export default function TeamBadge({ group }: TeamBadgeProps): JSX.Element {
	const hasOrganization = !!group.organizationName;

	return (
		<TeamBadgeWrapper
			data-testid="onboarding.team_badge"
			hasOrganization={hasOrganization}>
			<Badge badgeUrl={group.profileImageUrl} />
			{hasOrganization && (
				<span data-testid="onboarding.team_badge.organization_name">
					{group.organizationName}
				</span>
			)}
			<strong data-testid="onboarding.team_badge.group_name">
				{group.groupName}
			</strong>
		</TeamBadgeWrapper>
	);
}
