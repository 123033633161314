import { ReactNode } from 'react';

export const symbol_calendar_clock: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-630h600v-130H180v130Zm0 0v-130 130Zm0 550q-24 0-42-18t-18-42v-620q0-24 18-42t42-18h65v-28q0-13.6 9.482-22.8 9.483-9.2 23.5-9.2Q292-880 301-870.8t9 22.8v28h340v-28q0-13.6 9.482-22.8 9.483-9.2 23.5-9.2Q697-880 706-870.8t9 22.8v28h65q24 0 42 18t18 42v277q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T780-483v-87H180v430h306q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q516-97 507.375-88.5T486-80H180Zm551 40q-78.435 0-133.718-55.282Q542-150.565 542-229q0-78.435 55.282-133.717Q652.565-418 731-418q78.435 0 133.718 55.283Q920-307.435 920-229q0 78.435-55.282 133.718Q809.435-40 731-40Zm11-191v-92.92q0-8.08-5.7-13.58T723-343q-8 0-14 5.7t-6 13.3v95q0 5.565 2 10.783Q707-213 711-208l64 66q6 6 14 6t14-6q6-6 6-14t-6-14l-61-61Z"
		/>
	</svg>
);
