import { ReactNode } from 'react';

export const symbol_symptoms: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M481-500Zm-431 0q0-10.63 2.308-20.88Q54.615-531.13 60-541q63-118 174.5-188.5T480.447-800Q619-800 733-724.5T908-525q5 11 1.5 22T895-487q-11 5-22 1.5T857-500q-26-54-65.5-98.5t-88.5-76Q654-706 597-723t-117.067-17q-121.207 0-222.57 65.5T102-500q57 117 168.5 183T513-261q12-1 21 6.5t11 19.5q2 12-5.5 22T520-201q-144 14-268.5-59.5T60-459q-5.385-9.87-7.692-20.12Q50-489.37 50-500Zm430 170q-70.833 0-120.417-49.618Q310-429.235 310-500.118 310-571 359.618-620.5q49.617-49.5 120.5-49.5Q551-670 601-620.5T651-499q0 16-3 32t-10 30l-30 28q-13.184 12.258-23.592 26.129Q574-369 566-353q-19 11-40.814 17T480-330Zm.235-58Q527-388 559.5-420.735q32.5-32.736 32.5-79.5Q592-547 559.265-579.5q-32.736-32.5-79.5-32.5Q433-612 400.5-579.265q-32.5 32.736-32.5 79.5Q368-453 400.735-420.5q32.736 32.5 79.5 32.5Zm271.239 283Q688-105 644.468-149.093 600.936-193.186 600-256.041 600-287 613-315.5q13-28.5 36-49.5l61-57q17.184-17 41.092-17Q775-439 792-422l60 57q23 21 35.536 48.897Q900.071-288.207 901-257q0 63-43.5 107.5T751.474-105ZM751-165q38 0 64-26.909t25-65.03q0-19.061-8-35.561T810-322l-59-56-60 56q-14 13-22.5 29.5T661-256q0 38.198 25.875 64.599Q712.75-165 751-165Zm0-107Z"
		/>
	</svg>
);
