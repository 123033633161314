import { ReactNode } from 'react';

export const symbol_door_open: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M440.175-450q12.825 0 21.325-8.675 8.5-8.676 8.5-21.5 0-12.825-8.675-21.325-8.676-8.5-21.5-8.5-12.825 0-21.325 8.675-8.5 8.676-8.5 21.5 0 12.825 8.675 21.325 8.676 8.5 21.5 8.5ZM260-120v-60l280-49v-466q0-14-7.5-24.5T512-732l-252-39v-59l266 46q32 5 53 29.9t21 58.1v466q0 21.658-14.179 38.462Q571.642-174.734 550-171l-290 51Zm0-60h440v-590H260v-1 591Zm-110 60q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-180h50v-590q0-24.75 17.625-42.375T260-830h440q24.75 0 42.375 17.625T760-770v590h50q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-120H150Z"
		/>
	</svg>
);
