import { ReactNode } from 'react';

export const symbol_6_ft_apart: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M300-80q-6 0-10.5-4.5T285-95v-120q0-6 4.5-10.5T300-230h80q6 0 10.5 4.5T395-215q0 6-4.5 10.5T380-200h-65v30h65q6 0 10.5 4.5T395-155v60q0 6-4.5 10.5T380-80h-80Zm195-60v45q0 6-4.5 10.5T480-80q-6 0-10.5-4.5T465-95v-120q0-6 4.5-10.5T480-230h60q6 0 10.5 4.5T555-215q0 6-4.5 10.5T540-200h-45v30h25q6 0 10.5 4.5T535-155q0 6-4.5 10.5T520-140h-25Zm130-60h-25q-6 0-10.5-4.5T585-215q0-6 4.5-10.5T600-230h80q6 0 10.5 4.5T695-215q0 6-4.5 10.5T680-200h-25v105q0 6-4.5 10.5T640-80q-6 0-10.5-4.5T625-95v-105Zm-310 90h50v-30h-50v30ZM180-372l38 38q8 8 8 17t-8 17q-8 8-17.5 8t-17.5-8l-82-81q-9-9-9-21t9-21l82-82q8-8 17.5-7.5T218-504q8 8 8 17t-8 17l-38 38h600l-38-38q-8-7.5-8-17.5t8-17.5q8-8 17.5-8t17.5 8l82 82q9 9 9 21t-9 21l-82 82q-8 8-17 7.5t-17-8.5q-8-8-8-17.5t8-17.5l37-37H180ZM80-575v-13q0-21.081 11.408-38.649Q102.816-644.216 123-653q25.078-10.522 51.603-16.261Q201.128-675 230.064-675t55.417 5.739Q311.963-663.522 337-653q20.184 8.784 31.592 26.351Q380-609.081 380-588v13H80Zm500 0v-13q0-21.081 11.408-38.649Q602.816-644.216 623-653q25.078-10.522 51.603-16.261Q701.128-675 730.064-675t55.417 5.739Q811.963-663.522 837-653q20.184 8.784 31.592 26.351Q880-609.081 880-588v13H580ZM229.956-730Q199-730 177-752.044q-22-22.045-22-53Q155-836 177.044-858q22.045-22 53-22Q261-880 283-857.956q22 22.045 22 53Q305-774 282.956-752q-22.045 22-53 22Zm500 0Q699-730 677-752.044q-22-22.045-22-53Q655-836 677.044-858q22.045-22 53-22Q761-880 783-857.956q22 22.045 22 53Q805-774 782.956-752q-22.045 22-53 22Z"
		/>
	</svg>
);
