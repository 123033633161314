export default {
	name: 'age',
	objects: [
		{
			type: 'path',
			attributes: {
				d: 'M14.607 11.7545C12.8083 11.7545 11.3503 13.2125 11.3503 15.0112V15.5103C10.8054 15.1 10.1276 14.8568 9.39305 14.8568H7.51712C5.71849 14.8568 4.26041 16.3148 4.26041 18.1135V19.5493H5.56041V18.1135C5.56041 17.0328 6.43646 16.1568 7.51712 16.1568H9.39305C10.4737 16.1568 11.3497 17.0328 11.3497 18.1135V19.5493L12.6503 19.5494V15.0112C12.6503 13.9305 13.5263 13.0545 14.607 13.0545H16.4829C17.5635 13.0545 18.4396 13.9305 18.4396 15.0112V19.5494H19.7396V15.0112C19.7396 13.2125 18.2815 11.7545 16.4829 11.7545H14.607Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M8.45531 13.2145C9.11954 13.2145 9.65801 12.676 9.65801 12.0117C9.65801 11.3475 9.11954 10.809 8.45531 10.809C7.79107 10.809 7.2526 11.3475 7.2526 12.0117C7.2526 12.676 7.79107 13.2145 8.45531 13.2145ZM8.45531 14.3145C9.72706 14.3145 10.758 13.2835 10.758 12.0117C10.758 10.74 9.72706 9.70905 8.45531 9.70905C7.18356 9.70905 6.1526 10.74 6.1526 12.0117C6.1526 13.2835 7.18356 14.3145 8.45531 14.3145Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M15.5451 10.0658C16.2579 10.0658 16.8357 9.48801 16.8357 8.77526C16.8357 8.0625 16.2579 7.4847 15.5451 7.4847C14.8324 7.4847 14.2546 8.0625 14.2546 8.77526C14.2546 9.48801 14.8324 10.0658 15.5451 10.0658ZM15.5451 11.1658C16.8654 11.1658 17.9357 10.0955 17.9357 8.77526C17.9357 7.45499 16.8654 6.3847 15.5451 6.3847C14.2248 6.3847 13.1546 7.45499 13.1546 8.77526C13.1546 10.0955 14.2248 11.1658 15.5451 11.1658Z',
			},
		},
	],
};
