import Embed from 'components/formatted-content/Embed';
import { addFormatter } from 'components/formatted-content/FormattedContent';

addFormatter('video', {
	isEmbed: true,
	selector:
		/((?:https?:\/{2})?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+\%,;=.]+\.mp4(\?(?:&?[^=&]*=[^=&]*)*)?)/gi,
	decorator: (guid: string, match: string): JSX.Element => {
		return (
			<Embed key={guid}>
				<video src={match} controls width="100%" height="100%" />
			</Embed>
		);
	},
});
