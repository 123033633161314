export function CreateConnectAccountLink(groupID: number): string {
	return `/v1/stripe/account-link/${groupID}`;
}

export function CustomerPortalLink(): string {
	return `/v1/stripe/customer-portal`;
}

export function Refresh(): string {
	return `/v1/stripe/refresh`;
}

export function Webhooks(): string {
	return `/v1/stripe/connect-webhooks`;
}
