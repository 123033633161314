import { ReactNode } from 'react';

export const symbol_landslide: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-80h620q37 0 54-33t-6-63L650-387q-5-7-11.5-11.5T624-406l-184-74-102-136q-8-11-21-17.5t-27-6.5H140q-25 0-42.5 17.5T80-580v440q0 25 17.5 42.5T140-80Zm0-60v-101l100 32 375-124 145 193H140Zm100-131-100-34v-96l100 32 173-56 122 55-295 99Zm525-140 128-57q12-5 19.5-16t7.5-25v-95q0-16-9.5-28T885-648l-124-27q-10-2-20-.5t-18 8.5l-66 53q-8 7-12.5 16t-4.5 20v76q0 11 4.5 20t12.5 16l62 49q10 8 22.5 9.5T765-411Zm-525-20-100-34v-115h150l82 105-132 44Zm508-27-58-45v-73l63-50 117 26v87l-122 55ZM502-649l150-60q13-5 20.5-16t7.5-25v-133q0-16-10-28.5T644-927l-146-29q-9-2-17.5-.5T464-950l-84 57q-10 6-15 16t-5 21v112q0 11 5 21t15 16l80 54q9 6 20 7.5t22-3.5Zm-17-47-75-51v-106l81-54 139 28v125l-145 58Z"
		/>
	</svg>
);
