import { ReactNode } from 'react';

export const symbol_wifi_home: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M510-513Zm-350-48-60 46q-10.348 8-22.174 6Q66-511 58-521t-6.056-22.19Q53.89-555.381 64-563l380-289q8-6 17.211-9.5 9.21-3.5 18.789-3.5t18.789 3.5Q508-858 516-852l381 290q10 8 11.5 20t-6.5 22q-8 10-19.5 11.5T861-515L480-805 220-607v387h210q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T430-160H220q-24.75 0-42.375-17.625T160-220v-341ZM570-40q-13 0-21.5-8.5T540-70q0-13 8.5-21.5T570-100h27q-26-27-41.5-63T540-240q0-61 33-110.5t87-72.5q11-5 20.5 0t14.5 16q5 11 1 22.5T677-365q-35 17-56 50.5T600-240q0 29 10.5 54t29.5 44v-28q0-13 8.5-21.5T670-200q13 0 21.5 8.5T700-170v100q0 12.75-8.625 21.375T670-40H570Zm231-17q-11 5-21 0t-15-16q-5-11-1-22.5t19-19.5q35-18 56-51t21-74q0-29-10.5-54T820-338v28q0 13-8.5 21.5T790-280q-13 0-21.5-8.5T760-310v-100q0-12.75 8.625-21.375T790-440h100q13 0 21.5 8.5T920-410q0 13-8.5 21.5T890-380h-27q26 27 41.5 63t15.5 77q0 61-33 110.5T801-57Z"
		/>
	</svg>
);
