import { Component } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import * as palette from 'pkg/config/palette';

import * as sdk from 'pkg/core/sdk';

import CardWrapper, * as Card from 'components/Card';
import SectionTitle from 'components/SectionTitle';

import Column from 'components/layout/column';

const MainNumber = styled.p`
	line-height: 16px;
	font-size: 13px;
	color: ${palette.gray[800]};
	font-weight: var(--font-weight-normal);

	> span {
		font-weight: var(--font-weight-semibold);
		line-height: 1;
		font-size: 1.8rem;
		color: ${palette.black};
		margin-top: 6px;
		margin-bottom: 4px;
		display: block;
		width: 100%;
	}
`;

const Numbers = styled.div`
	align-items: flex-start;
	justify-content: center;
	margin-bottom: 0.5rem;
	flex-flow: column;
	display: flex;
	text-align: center;
	align-items: center;
`;

const CardBase = styled(CardWrapper)`
	display: flex;
	flex: 1 1;
	flex-flow: column wrap;
	height: 100%;

	${Card.Body} {
		height: calc(100% - 34px);
		width: 100%;
		height: 100%;
		display: flex;
		flex: 1 1;
	}
`;

const getColor = (diff) => {
	if (diff === 0) {
		return palette.black;
	} else if (diff >= 1) {
		palette.green[500];
	} else {
		return palette.red[400];
	}
};

const numberProps = ({ diff }) => ({
	style: {
		color: getColor(diff),
	},
});

const AverageNumber = styled.span.attrs(numberProps)`
	line-height: 16px;
	font-size: 11px;
	color: ${palette.gray[500]};
	font-size: 1.5rem;
	justify-content: flex-start;
	align-items: center;
`;

const Body = styled(Card.Body)`
	justify-content: space-around;
	display: flex;
`;

const Diff = styled.span`
	font-size: 13px;
`;

const StyledIcon = styled.span`
	font-size: 14px;
	margin-right: 2px;
`;

export default class Trends extends Component {
	_mounted = false;

	state = {
		dataset: {},
	};

	static defaultProps = {
		groupId: 0,
	};

	componentDidMount() {
		this._mounted = true;
		this.fetchData();
	}

	componentWillUnmount() {
		this._mounted = false;
	}

	async fetchData() {
		const { userId, groupId } = this.props;

		let endpoint = `/users/:id/activities`;
		let endpointId = userId;

		if (!userId && groupId) {
			endpoint = `/groups/:id/activities`;
			endpointId = groupId;
		}

		const response = await sdk.get(endpoint, {
			id: endpointId,
			'include-stats': 1,
		});
		const json = await response.json();

		if (!this._mounted) {
			return;
		}

		this.setState({
			dataset: json.meta.logStats,
		});
	}

	componentDidUpdate(prevProps) {
		if (this.props.posts && prevProps.posts !== this.props.posts) {
			this.fetchData();
		}
	}

	get maxValue() {
		const maxInArray = Math.max(...Object.values(this.state.dataset));
		const maxVal = maxInArray === 0 ? 10 : maxInArray;

		if (maxVal < 10 || maxVal % 10 === 0) {
			return maxVal + 2;
		}
		return Math.ceil(maxVal / 10, 0) * 10;
	}

	get labels() {
		return {
			'0_7': t('Last week'),
			'7_14': t('The week before that'),
			'0_30': t('Last month'),
			'30_60': t('The month before that'),
		};
	}

	get sections() {
		const sections = [];
		let index = 0;

		const inverted = {
			'0_7': '7_14',
			'0_30': '30_60',
		};

		const calcDiff = (val, inv) => {
			const comp = this.state.dataset[inv];
			if (comp === 0) {
				return val * 100;
			}

			return Math.round((val / comp - 1) * 100);
		};

		for (let [key, label] of Object.entries(this.labels)) {
			if (inverted[key]) {
				const value = this.state.dataset[key];
				const invertedVal = this.state.dataset[inverted[key]];
				const diffNumber = calcDiff(value, inverted[key]);
				const diffPercentage =
					invertedVal === 0 ? '—' : `${diffNumber}%`.replace('-', '');

				const bar = (
					<Numbers key={index}>
						<MainNumber>
							{label}
							<span>{value}</span>
						</MainNumber>
						<AverageNumber diff={diffNumber}>
							<StyledIcon>
								{diffNumber === 0 ? '→' : diffNumber > 0 ? '↑' : '↓'}
							</StyledIcon>
							<Diff>{diffPercentage}</Diff>
						</AverageNumber>
					</Numbers>
				);

				index++;
				sections.push(bar);
			}
		}

		return sections;
	}

	get renderChart() {
		if (Object.keys(this.state.dataset).length === 0) {
			return null;
		}

		return this.sections;
	}

	render() {
		return (
			<Column>
				<SectionTitle>{t('Activity trends')}</SectionTitle>
				<CardBase>
					<Body>{this.renderChart}</Body>
				</CardBase>
			</Column>
		);
	}
}
