import { t } from '@transifex/native';
import { useEffect, useState } from 'react';

import * as organizationServices from 'pkg/actions/services/organization';

import DateTime from 'pkg/datetime';
import * as actions from 'pkg/actions';
import { formatToCurrency } from 'pkg/i18n/formatters';

import AssetImage from 'components/AssetImage';

import StatusLabel from 'components/payment_platform/status/label';

import * as Table from 'design/table';

// This file can be removed when stripe payouts has been normalised
interface RowProps {
	payout: organizationServices.IPayout;
}

function Row({ payout }: RowProps) {
	const amount = formatToCurrency(payout.amount / 100, payout.currency);
	const date = DateTime.fromTimestamp(payout.createdAt);

	return (
		<Table.Row>
			<Table.Cell>{amount}</Table.Cell>
			<Table.Cell>
				<StatusLabel status={{ name: payout.status }} />
			</Table.Cell>
			<Table.Cell>{payout.destination}</Table.Cell>
			<Table.Cell>
				{date.toLocaleDateString({
					year: 'numeric',
					month: 'numeric',
					day: 'numeric',
					minute: 'numeric',
					second: 'numeric',
				})}
			</Table.Cell>
		</Table.Row>
	);
}

interface PayoutsTableProps {
	payouts: organizationServices.IPayout[];
	loading: boolean;
}

function PayoutsTable({ payouts, loading }: PayoutsTableProps) {
	return (
		<Table.Table
			emptyState={{
				title: t(`No payouts`),
				content: t(`There are currently no payouts to view`),
				image: <AssetImage src="img/missing-entities/Invoice.svg" />,
			}}
			stickyHeader
			columns={[
				{
					content: t(`Amount`),
				},
				{
					content: t(`Status`),
				},
				{
					content: t(`Bank/Card`),
				},
				{
					content: t(`Date`),
				},
			]}
			isLoading={loading}>
			{payouts?.map((payout, index) => <Row key={index} payout={payout} />)}
		</Table.Table>
	);
}

interface OrganizationPayoutsProps {
	organizationId: number;
}

export default function OrganizationPayouts({
	organizationId,
}: OrganizationPayoutsProps) {
	const [payouts, setPayouts] = useState<organizationServices.IPayout[]>([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			const resp = await actions.organizations.fetchPayouts(organizationId);

			setLoading(false);
			setPayouts(resp);
		};

		if (organizationId) {
			fetchData();
		}
	}, [organizationId]);

	return <PayoutsTable payouts={payouts} loading={loading} />;
}
