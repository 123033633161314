import { ReactNode } from 'react';

export const symbol_headset_mic: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-154q-24 0-42-18t-18-42v-304q0-74.733 28.5-140.881Q177-725.03 226-774.515T341.204-852.5q66.205-28.5 141-28.5Q557-881 622.5-852.5 688-824 736.052-774.515q48.051 49.485 76 115.634Q840-592.733 840-518v418q0 24-18 42t-42 18H512q-12.75 0-21.375-8.675Q482-57.351 482-70.175 482-83 490.625-91.5T512-100h268v-54h-88q-24.75 0-42.375-17.625T632-214v-176q0-24.75 17.625-42.375T692-450h88v-68q0-124-87-213.5T482-821q-124 0-213 89.5T180-518v68h88q24.75 0 42.375 17.625T328-390v176q0 24.75-17.625 42.375T268-154h-88Zm0-60h88v-176h-88v176Zm512 0h88v-176h-88v176ZM180-390h88-88Zm512 0h88-88Z"
		/>
	</svg>
);
