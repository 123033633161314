import { ReactNode } from 'react';

export const symbol_north: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-80q-13 0-21.5-8.5T450-110v-656L263-579q-9 9-21 9t-21-9q-9-9-9-21t9-21l238-238q5-5 10-7t11-2q6 0 11 2t10 7l238 238q9 9 9 21t-9 21q-9 9-21 9t-21-9L510-766v656q0 13-8.5 21.5T480-80Z"
		/>
	</svg>
);
