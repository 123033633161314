import { ReactNode } from 'react';

export const symbol_tooltip: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M374-240H140q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H586l-81 122q-9.111 14-25.056 14Q464-104 455-118l-81-122Zm-234-60h680v-520H140v520Zm0 0v-520 520Z"
		/>
	</svg>
);
