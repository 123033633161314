import { Set } from 'immutable';
import { ReactNode, ChangeEvent, Fragment, useState } from 'react';
import { t } from '@transifex/native';

import User from 'pkg/models/user';
import Account from 'pkg/models/account';

import { localeIncludes } from 'pkg/strings';

import Icon from 'components/icon';
import Avatar from 'components/avatar';

import * as Input from 'components/form/inputs';

import Button from 'design/button';

import * as ListStyles from 'styles/SelectableList';

type ChangeHandler = (selectedIds: number[]) => void;

type CommentChangeHandler = (itemId: number, comment: string) => void;

interface Comments {
	[itemId: number]: string;
}

export enum UngroupedPosition {
	Above,
	Below,
}

interface ListPreviewProps {
	items: Set<User | Account>;
	selectedIds: number[];
	onChange: ChangeHandler;
}

function ListPreview({
	items,
	selectedIds,
	onChange,
}: ListPreviewProps): JSX.Element {
	const selectedItems = items.filter((item: User | Account) =>
		selectedIds.includes(item.id)
	);

	const deselect = (itemId: number): void => {
		if (selectedIds.includes(itemId)) {
			onChange(selectedIds.filter((id: number) => id !== itemId));
		}
	};

	return (
		<ListStyles.PreviewWrapper>
			<ListStyles.PreviewContainer>
				{selectedItems.map((item: Account) => (
					<ListStyles.PreviewItem
						key={item.id}
						onClick={() => deselect(item.id)}>
						<ListStyles.PreviewCloseIcon name="close" />
						<Avatar account={item} />
						<span>{item.fullName}</span>
					</ListStyles.PreviewItem>
				))}
			</ListStyles.PreviewContainer>
		</ListStyles.PreviewWrapper>
	);
}

interface ListControlProps {
	items: Set<User | Account>;
	selectedIds: number[];
	onChange: ChangeHandler;
}

function ListControl({
	items,
	selectedIds,
	onChange,
}: ListControlProps): JSX.Element {
	const selectAll = (): void =>
		onChange(items.map((item: User | Account) => item.id).toArray());

	const deselectAll = (): void => onChange([]);

	const allSelected: boolean = selectedIds.length === items.size;

	return (
		<ListStyles.ControlsWrapper>
			<span>
				{t('{num} selected users', {
					num: selectedIds.length,
				})}
			</span>
			{selectedIds.length > 0 && !allSelected && (
				<Button inline noPadding onClick={deselectAll}>
					<Icon name="close" />
					{t('Clear')}
				</Button>
			)}
			<Button inline onClick={allSelected ? deselectAll : selectAll}>
				{allSelected ? (
					<Fragment>
						<Icon name="unmark-all-complete" />
						{t('Deselect all')}
					</Fragment>
				) : (
					<Fragment>
						<Icon name="mark-all-complete" />
						{t('Select all')}
					</Fragment>
				)}
			</Button>
		</ListStyles.ControlsWrapper>
	);
}

interface ListItemProps {
	item: User | Account;
	selectable: boolean;
	multipleChoice: boolean;
	selectedIds: number[];
	comments?: Comments;

	onChange: ChangeHandler;
	onCommentChange?: CommentChangeHandler;
}

function ListItem({
	item,
	selectable,
	multipleChoice,
	selectedIds,
	comments,

	onChange,
	onCommentChange,
}: ListItemProps): JSX.Element {
	const selected = selectedIds.includes(item.id);
	const comment = comments ? comments[item.id] : '';

	const toggleSelected = (itemId: number): void => {
		if (!selectable) return;

		if (selectedIds.includes(itemId)) {
			onChange(selectedIds.filter((id: number) => id !== itemId));
		} else {
			if (!multipleChoice) {
				onChange([itemId]);
			} else {
				onChange([...selectedIds, itemId]);
			}
		}
	};

	const toggle = () => toggleSelected(item.id);

	const handleCommentChange = (event: ChangeEvent<HTMLInputElement>) => {
		onCommentChange(item.id, event.target.value);
	};

	let onClick = toggle;
	if (selected && onCommentChange) {
		onClick = null;
	}

	return (
		<ListStyles.Item onClick={onClick}>
			<Avatar account={item as Account} user={item as User} />
			{selected && onCommentChange ? (
				<div>
					<Input.Field
						small
						autoFocus={!comment}
						defaultValue={comment}
						changeDelay={500}
						onChange={handleCommentChange}
						placeholder={t('Leave a comment for {user_name}', {
							user_name: item.fullName,
						})}
					/>
				</div>
			) : (
				<span>{item.fullName}</span>
			)}
			{selectable ? (
				<Input.Control
					large
					rounded
					standalone
					type={multipleChoice ? 'checkbox' : 'radio'}
					checked={selected}
					onChange={selected ? toggle : null}
				/>
			) : (
				<span />
			)}
		</ListStyles.Item>
	);
}

export interface ListGroupBy {
	[key: string]: number[];
}

type UngroupedSorter = (a: User | Account, b: User | Account) => number;

interface ListGroupProps {
	items: Set<User | Account>;
	selectedIds: number[];
	selectable: boolean;
	multipleChoice: boolean;

	groupBy?: ListGroupBy;
	ungroupedLabel?: string;
	ungroupedPosition?: UngroupedPosition;
	ungroupedSorter?: UngroupedSorter;
	showUngrouped?: boolean;
	noItemsLabel?: string;
	comments?: Comments;

	onChange: ChangeHandler;
	onCommentChange?: CommentChangeHandler;
}

function ListGroup({
	items,
	selectedIds,
	selectable,
	multipleChoice,

	groupBy,
	ungroupedLabel,
	ungroupedPosition,
	ungroupedSorter,
	showUngrouped,
	noItemsLabel,
	comments,

	onChange,
	onCommentChange,
}: ListGroupProps): JSX.Element {
	const groupedIds = [].concat(...Object.values(groupBy || {}));

	let ungrouped = items.filter(
		(item: User | Account) => !groupedIds.includes(item.id)
	);

	if (ungroupedSorter) {
		ungrouped = ungrouped.sort(ungroupedSorter);
	}

	const ungroupedItems: ReactNode = ungrouped.map((item: User | Account) => (
		<ListItem
			key={item.id}
			selectable={selectable}
			selectedIds={selectedIds}
			item={item}
			onChange={onChange}
			multipleChoice={multipleChoice}
			onCommentChange={onCommentChange}
			comments={comments}
		/>
	));

	if (items.size === 0) {
		return (
			<ListStyles.EmptyState>
				<Icon name="nav-members" size={3} />
				<span>{noItemsLabel}</span>
			</ListStyles.EmptyState>
		);
	}

	if (!groupBy) {
		return <Fragment>{ungroupedItems}</Fragment>;
	} else {
		return (
			<Fragment>
				{ungroupedPosition === UngroupedPosition.Above &&
					ungrouped.size > 0 &&
					showUngrouped && (
						<Fragment>
							<ListStyles.Heading>{ungroupedLabel}</ListStyles.Heading>
							{ungroupedItems}
						</Fragment>
					)}
				{Object.entries(groupBy).map(
					([label, itemIds]) =>
						itemIds.length > 0 && (
							<Fragment key={label}>
								<ListStyles.Heading>{label}</ListStyles.Heading>
								{itemIds.map((id: number) => {
									const item: User | Account = items.find(
										(i: User | Account) => i.id === id
									);

									if (!item) return null;

									return (
										<ListItem
											key={item.id}
											selectable={selectable}
											selectedIds={selectedIds}
											item={item}
											onChange={onChange}
											multipleChoice={multipleChoice}
											onCommentChange={onCommentChange}
											comments={comments}
										/>
									);
								})}
							</Fragment>
						)
				)}
				{ungroupedPosition === UngroupedPosition.Below &&
					ungrouped.size > 0 &&
					showUngrouped && (
						<Fragment>
							<ListStyles.Heading>{ungroupedLabel}</ListStyles.Heading>
							{ungroupedItems}
						</Fragment>
					)}
			</Fragment>
		);
	}
}

interface SelectableListProps {
	items: Set<User | Account>;
	selectable?: boolean;
	selectedIds?: number[];
	multipleChoice?: boolean;

	showControls?: boolean;
	showPreview?: boolean;
	showFilter?: boolean;

	groupBy?: ListGroupBy;
	ungroupedLabel?: string;
	ungroupedPosition?: UngroupedPosition;
	ungroupedSorter?: UngroupedSorter;
	showUngrouped?: boolean;
	noItemsLabel?: string;
	comments?: Comments;

	onChange?: ChangeHandler;
	onCommentChange?: CommentChangeHandler;
}

export default function SelectableList({
	items,
	selectable = true,
	selectedIds,
	multipleChoice,

	showControls,
	showPreview,
	showFilter,

	groupBy,
	ungroupedLabel = t('Ungrouped users'),
	ungroupedPosition = UngroupedPosition.Below,
	ungroupedSorter,
	showUngrouped = true,
	noItemsLabel = t('No users available'),
	comments,

	onChange,
	onCommentChange,
}: SelectableListProps): JSX.Element {
	const [filter, setFilter] = useState<string>('');

	items = items.sortBy((item: User | Account) => item.id);

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		setFilter(event.target.value);
	};

	const filteredItems = !filter.length
		? items
		: items.filter((item: User | Account) =>
				localeIncludes(item.fullName, filter)
			);

	return (
		<ListStyles.Wrapper>
			{selectable && (
				<ListStyles.Header>
					{showFilter && (
						<ListStyles.SearchWrapper>
							<Input.Field
								small
								spellcheck="false"
								autoComplete="off"
								value={filter}
								onChange={handleChange}>
								<Input.Prefix inline>
									<Icon name="search" size={1.2} />
								</Input.Prefix>
							</Input.Field>
						</ListStyles.SearchWrapper>
					)}
					{showPreview && selectedIds.length > 0 && multipleChoice && (
						<ListPreview
							items={filteredItems}
							selectedIds={selectedIds}
							onChange={onChange}
						/>
					)}
				</ListStyles.Header>
			)}
			<ListStyles.Container>
				{selectable &&
					showControls &&
					multipleChoice &&
					filteredItems.size > 0 && (
						<ListControl
							items={filteredItems}
							selectedIds={selectedIds}
							onChange={onChange}
						/>
					)}
				<ListGroup
					selectable={selectable}
					selectedIds={selectedIds}
					onChange={onChange}
					items={filteredItems}
					groupBy={groupBy}
					ungroupedLabel={ungroupedLabel}
					ungroupedPosition={ungroupedPosition}
					ungroupedSorter={ungroupedSorter}
					showUngrouped={showUngrouped}
					multipleChoice={multipleChoice}
					noItemsLabel={noItemsLabel}
					onCommentChange={onCommentChange}
					comments={comments}
				/>
			</ListStyles.Container>
		</ListStyles.Wrapper>
	);
}
