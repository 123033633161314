import { ReactNode } from 'react';

export const symbol_unfold_less: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-297 364-181q-9 9-21 8.5t-21-9.5q-9-9-9-21.5t9-21.5l137-137q5-5 10-7t11-2q6 0 11 2t10 7l138 138q9 9 9 21t-9 21q-9 9-21.5 9t-21.5-9L480-297Zm0-366 116-116q9-9 21-8.5t21 9.5q9 9 9 21.5t-9 21.5L501-598q-5 5-10 7t-11 2q-6 0-11-2t-10-7L321-736q-9-9-8.5-21.5T322-779q9-9 21.5-9t21.5 9l115 116Z"
		/>
	</svg>
);
