import { ReactNode } from 'react';

export const symbol_pregnancy: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M411-110v-150h-61q-12.75 0-21.375-8.625T320-290v-284q0-50 28-78t77.5-28q49.5 0 76 33t26.5 73q52 6 82 41.5t30 89.5v153q0 12.75-8.625 21.375T610-260H485v150q0 12.75-8.625 21.375T455-80h-14q-12.75 0-21.375-8.625T411-110Zm15.08-624q-30.08 0-51.58-21.42-21.5-21.421-21.5-51.5 0-30.08 21.42-51.58 21.421-21.5 51.5-21.5 30.08 0 51.58 21.42 21.5 21.421 21.5 51.5 0 30.08-21.42 51.58-21.421 21.5-51.5 21.5Z"
		/>
	</svg>
);
