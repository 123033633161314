import { ReactNode } from 'react';

export const symbol_home_speaker: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M375-120q-72 0-117-54.5T223-301l59-421q2-8 6-14t12-9l330-132q14-5 26.5 2t14.5 22l67 556q8 70-37 123.5T585-120H375Zm0-60h210q44 0 71.5-32.4T679-288l-14.568-120H298l-17 119q-6 44 21.5 76.5T375-180Zm-37-516-32 228h351l-41-339-278 111Zm142 288Zm2-60Zm-1 60v-60 60Z"
		/>
	</svg>
);
