import { Map, Record } from 'immutable';

import Session from 'pkg/models/session';

import { Sessions } from 'pkg/actions/action-types';

const initialState = new Record(
	{
		entities: new Map({}),
		modes: new Map({}),
	},
	'sessionsReducer'
);

const initialMode = new Map({
	isNew: false,
	hasChanges: false,
});

const setItemsReducer = (state, action) => {
	const data = action.payload.entities.sessions;
	const entities = Object.values(data);

	entities.forEach((entity) => {
		state = setModeReducer(state, {
			sessionId: entity.id,
			payload: {},
		}).setIn(['entities', entity.id], new Session(entity));
	});

	return state;
};

const deleteItemReducer = (state, action) =>
	state.deleteIn(['entities', action.sessionId]);

const setModeReducer = (state, action) =>
	state.setIn(
		['modes', action.sessionId],
		state.getIn(['modes', action.sessionId], initialMode).merge(action.payload)
	);

const unsetModeReducer = (state, action) =>
	state.deleteIn(['modes', action.sessionId]);

const sessionsReducer = (state = initialState(), action = {}) => {
	switch (action.type) {
		case Sessions.SET_ITEMS:
			return setItemsReducer(state, action);
		case Sessions.DELETE_ITEM:
			return deleteItemReducer(state, action);

		case Sessions.SET_MODE:
			return setModeReducer(state, action);
		case Sessions.UNSET_MODE:
			return unsetModeReducer(state, action);
		default:
			return state;
	}
};

export default sessionsReducer;
