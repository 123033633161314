import { ReactNode } from 'react';

export const symbol_switch_video: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M250-453h300l-45 45q-8 7.822-8 19.911Q497-376 504.857-368q7.857 8 20 8T545-368l94-94q5-5 7-10.133 2-5.134 2-11Q648-489 646-494q-2-5-7-10l-94-94q-7.822-8-19.911-8Q513-606 505-598.143q-8 7.857-8 20T505-558l45 45H250l45-45q8-7.822 8-19.911Q303-590 295.143-598q-7.857-8-20-8T255-598l-94 94q-5 5-7 10.133-2 5.134-2 11Q152-477 154-472q2 5 7 10l94 94q7.822 8 19.911 8Q287-360 295-367.857q8-7.857 8-20T295-408l-45-45ZM140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h520q24 0 42 18t18 42v215l134-134q7-7 16.5-3.458Q880-658.917 880-649v338q0 9.917-9.5 13.458Q861-294 854-301L720-435v215q0 24-18 42t-42 18H140Zm0-60h520v-520H140v520Zm0 0v-520 520Z"
		/>
	</svg>
);
