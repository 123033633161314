export function AddAttachment(
	eventSeriesID: number,
	attachmentID: number
): string {
	return `/v1/event-series/${eventSeriesID}/attachments/${attachmentID}`;
}

export function AddUsers(eventSeriesID: number): string {
	return `/v1/event-series/${eventSeriesID}/users`;
}

export function Create(): string {
	return `/v1/event-series`;
}

export function Delete(eventSeriesID: number): string {
	return `/v1/event-series/${eventSeriesID}`;
}

export function RemoveAttachment(
	eventSeriesID: number,
	attachmentID: number
): string {
	return `/v1/event-series/${eventSeriesID}/attachments/${attachmentID}`;
}

export function RemoveUsers(eventSeriesID: number): string {
	return `/v1/event-series/${eventSeriesID}/users`;
}

export function Show(eventSeriesID: number): string {
	return `/v1/event-series/${eventSeriesID}`;
}

export function Update(eventSeriesID: number): string {
	return `/v1/event-series/${eventSeriesID}`;
}

export function UpdateUsers(eventSeriesID: number): string {
	return `/v1/event-series/${eventSeriesID}/users`;
}
