import { ReactNode } from 'react';

export const symbol_grid_3x3_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m712-333-60-60h118q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T770-333h-58Zm-85-85L418-627h149v-143q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T627-770v143h143q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T770-567H627v149ZM393-653l-60-60v-57q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T393-770v117Zm174 346-26-26H393v143q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T333-190v-143H190q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T190-393h143v-148l-26-26H190q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T190-627h56L91-782q-9-9-9-21t9.053-21q9.052-9 21.5-9Q125-833 134-824l708 707q9 9 9 21t-9 21q-9 9-21 9t-21-9L627-247v57q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T567-190v-117Z"
		/>
	</svg>
);
