import { ReactNode } from 'react';

export const symbol_doorbell_chime: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M479.765-140Q538-140 579-180.833q41-40.834 41-99.167v-400q0-58.333-40.765-99.167-40.764-40.833-99-40.833Q422-820 381-779.167 340-738.333 340-680v400q0 58.333 40.765 99.167 40.764 40.833 99 40.833Zm.323-135Q461-275 448-287.912q-13-12.913-13-32Q435-339 447.912-352q12.913-13 32-13Q499-365 512-352.088q13 12.913 13 32Q525-301 512.088-288q-12.913 13-32 13ZM480-80q-83 0-141.5-58.5T280-280v-400q0-83 58.5-141.5T480-880q83 0 141.5 58.5T680-680v400q0 83-58.5 141.5T480-80Zm0-160q33 0 56.5-23.5T560-320q0-33-23.5-56.5T480-400q-33 0-56.5 23.5T400-320q0 33 23.5 56.5T480-240Zm0-320q33 0 56.5-23.5T560-640q0-33-23.5-56.5T480-720q-33 0-56.5 23.5T400-640q0 33 23.5 56.5T480-560Zm276.75 190Q744-370 735-378.625T726-400q0-6 2.455-11.625Q730.909-417.25 735-421q12-12 18.5-26.857t6.5-32Q760-497 753.5-512T735-539q-5-5-7-10.205t-2-11.154q0-12.641 8.865-21.141 8.864-8.5 21.968-8.5 6.167 0 11.667 2t9.5 7q20 20 31 45.855 11 25.854 11 55Q820-451 809-425q-11 26-31 46-4 5-9.625 7t-11.625 2Zm107.717 80Q852-290 843-299q-9-9-9-21.467 0-5.866 2.625-11.366T843-341q28-28 42.5-64.189 14.5-36.189 14.5-75T885-555q-15-36-42-64-3.75-3.667-6.375-9.167T834-639.533Q834-652 843-661q9-9 21.467-9 5.866 0 11.366 2.625T885-661q36 36 55.5 83t19.5 98q0 51-19.5 98T885-299q-3.667 3.75-9.167 6.375T864.467-290ZM480-480Z"
		/>
	</svg>
);
