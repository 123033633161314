import { ReactNode } from 'react';

export const symbol_rtt: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M423-120q-19 0-31-14t-9-33q2-15 13.5-25t26.5-10h58l89-556H439l-27 165q-2 15-14 25t-28 10q-20 0-32-14.5t-9-34.5l31-193q3-17 16.5-28.5T408-840h421q23 0 37 17t10 39l-31 191q-2 15-14 25t-28 10q-20 0-32-15t-9-34l24-151H655l-89 556h45q19 0 31 14t9 33q-2 15-13.5 25T611-120H423ZM189-700q-14 0-22.5-10.5T160-735q2-11 10-18t19-7h104l-10 60h-94Zm-26 165q-14 0-22.5-10.5T134-570q2-11 10-18t19-7h104l-10 60h-94Zm-53 330q-14 0-22.5-10.5T81-240q2-11 10-18t19-7h204l-9 60H110Zm27-165q-14 0-22.5-10.5T108-405q2-11 10-18t19-7h204l-10 60H137Z"
		/>
	</svg>
);
