import { ReactNode } from 'react';

export const symbol_sports_esports: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M147-200q-38 0-59.5-25.5T72-296l48-335q8-53 49.5-91t94.5-38h433q53 0 94.5 38t49.5 91l47 335q6 45-15.5 70.5T813-200q-23 0-39-7.5T747-226L643-330H317L213-226q-11 11-27 18.5t-39 7.5Zm18-64 126-126h378l126 126q5 5 18 9 9 0 13.5-9t2.5-18l-48-339q-5-35-29.5-57T697-700H263q-30 0-54.5 22T179-621l-48 339q-2 9 2.5 18t13.5 9q7 0 18-9Zm535-176q16 0 28-12t12-28q0-16-12-28t-28-12q-16 0-28 12t-12 28q0 16 12 28t28 12Zm-85-130q16 0 28-12t12-28q0-16-12-28t-28-12q-16 0-28 12t-12 28q0 16 12 28t28 12Zm-135 92Zm-180-42v50q0 10.833 7.116 17.917 7.117 7.083 18 7.083Q336-445 343-452.083q7-7.084 7-17.917v-50h50q10.833 0 17.917-7.116 7.083-7.117 7.083-18Q425-556 417.917-563q-7.084-7-17.917-7h-50v-50q0-10.833-7.116-17.917-7.117-7.083-18-7.083Q314-645 307-637.917q-7 7.084-7 17.917v50h-50q-10.833 0-17.917 7.116-7.083 7.117-7.083 18Q225-534 232.083-527q7.084 7 17.917 7h50Z"
		/>
	</svg>
);
