import { ReactNode } from 'react';

export const symbol_diagnosis: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M350-210h260q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T610-270H350q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T350-210Zm0-120h260q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T610-390H350q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T350-330Zm130-382q-15-19-31-28.5t-41-9.5q-31.909 0-54.955 23.045Q330-703.909 330-672q0 52 47.5 95.5T460-502q8.186 8 20.093 8T500-502q35-31 82.5-75t47.5-95q0-31.909-23.045-54.955Q583.909-750 552-750q-25 0-41 9.5T480-712ZM740-80H220q-24 0-42-18t-18-42v-680q0-24 18-42t42-18h520q24 0 42 18t18 42v680q0 24-18 42t-42 18Zm-520-60h520v-680H220v680Zm0 0v-680 680Z"
		/>
	</svg>
);
