import { ReactNode } from 'react';

export const symbol_checklist_rtl: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m659-299 158-158q9-9 21-8.5t21.391 9.5Q868-447 868-435t-9 21L680-235q-9 9-21 9t-21-9L538-335q-9-9-9-21t9-21q9-9 21-8.5t21 8.5l79 78Zm0-320 158-158q9-9 21-8.5t21.391 9.5Q868-767 868-755t-9 21L680-555q-9 9-21 9t-21-9L538-655q-9-9-9-21t9-21q9-9 21-8.5t21 8.5l79 78ZM110-290q-12.75 0-21.375-8.675Q80-307.351 80-320.175 80-333 88.625-341.5T110-350h300q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T410-290H110Zm0-320q-12.75 0-21.375-8.675Q80-627.351 80-640.175 80-653 88.625-661.5T110-670h300q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T410-610H110Z"
		/>
	</svg>
);
