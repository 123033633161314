import { ReactNode } from 'react';

export const symbol_arrows_more_down: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M230-120q-12.75 0-21.375-8.625T200-150v-360q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T260-510v330h330q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T590-120H230Zm220-220q-12.75 0-21.375-8.625T420-370v-360q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T480-730v330h330q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-340H450Z"
		/>
	</svg>
);
