import { ReactNode } from 'react';

export const symbol_short_stay: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-80q-26 0-43-17t-17-43v-680q0-24.75 17-42.375T180-880h400q24.75 0 42.375 17.625T640-820v211q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Q597-579 588-587.912q-9-8.913-9-22.088v-210H180v296q20-17 45.5-26.5T280-560h204q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T484-500H280.062Q238-500 209-471t-29 71v260h275q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q485-97 476.375-88.5T455-80H180Zm199.911-530Q351-610 330.5-630.589q-20.5-20.588-20.5-49.5Q310-709 330.589-729.5q20.588-20.5 49.5-20.5Q409-750 429.5-729.411q20.5 20.588 20.5 49.5Q450-651 429.411-630.5q-20.588 20.5-49.5 20.5ZM350-290v50q0 12.75 8.625 21.375T380-210q13 0 21.5-8.5T410-240v-160q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T350-400v50h-50q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T300-290h50ZM679.894-90Q601-90 545.5-145.606t-55.5-134.5Q490-359 545.606-414.5t134.5-55.5Q759-470 814.5-414.394t55.5 134.5Q870-201 814.394-145.5T679.894-90ZM700-288v-92q0-8-6-14t-14-6q-8 0-14 6t-6 14v102q0 1 4 11l68.836 67.918Q739-193 747-193t14-6q6-6 6-14t-6-14l-61-61ZM280-140h191-291 100Z"
		/>
	</svg>
);
