import { ReactNode } from 'react';

export const symbol_controller_gen: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480.5-250q95.5 0 162.5-67.5t67-163q0-95.5-67.083-162.5Q575.833-710 480-710q-95 0-162.5 67.083Q250-575.833 250-480q0 95 67.5 162.5t163 67.5Zm0-60Q410-310 360-359.938 310-409.875 310-480q0-71 49.938-120.5Q409.875-650 480-650q71 0 120.5 49.5t49.5 120q0 70.5-49.5 120.5t-120 50ZM459-459q9 9 21 9t21-9l56-56q9-9 9-21t-9-21q-9-9-21-9t-21 9l-56 56q-9 9-9 21t9 21ZM180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-60h600v-600H180v600Zm0-600v600-600Z"
		/>
	</svg>
);
