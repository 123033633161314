// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
	@media (max-width: 768px) {
    .EX53ICRUEyzsHcB4A8dA {
		padding-bottom: var(--spacing-8);
}
	}
`, "",{"version":3,"sources":["webpack://./routes/payments/discounts/create/form/styles.css"],"names":[],"mappings":";CACC;IADD;EAEE,gCAAgC;AAElC;CADC","sourcesContent":[".wrapper {\n\t@media (--small-viewport) {\n\t\tpadding-bottom: var(--spacing-8);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var wrapper = `EX53ICRUEyzsHcB4A8dA`;
export default ___CSS_LOADER_EXPORT___;
