import * as models from 'pkg/api/models';

interface OrganizationUserProfileDevelopmentProps {
	user: models.user.User;
}

export default function OrganizationUserProfileDevelopment({
	user,
}: OrganizationUserProfileDevelopmentProps): JSX.Element {
	return <p>Showing Development for {models.user.fullName(user)}</p>;
}
