import { ReactNode } from 'react';

export const symbol_directions_railway: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M160-340v-380q0-83 77-121.5T480-880q172 0 246 37t74 123v380q0 59-40.5 99.5T660-200l79 53q9 6 5.5 16.5T730-120H230q-11 0-14.5-10.5T221-147l79-53q-59 0-99.5-40.5T160-340Zm60-205h520v-155H220v155Zm440 60H220h520-80ZM480-315q23 0 39-16t16-39q0-23-16-39t-39-16q-23 0-39 16t-16 39q0 23 16 39t39 16Zm-180 60h360q34 0 57-25t23-60v-145H220v145q0 35 23 60t57 25Zm180-565q-120 0-173 15.5T231-760h501q-18-27-76.5-43.5T480-820Zm0 60h252-501 249Z"
		/>
	</svg>
);
