import { forwardRef } from 'react';

// withForwardedRef simplifies passing on refs, and sets proper display names for dev tools.
// Sets props.forwardedRef
const withForwardedRef = (Component) => {
	const instance = (props, ref) => <Component {...props} forwardedRef={ref} />;

	const name = Component.displayName || Component.name;

	instance.displayName = `withForwardedRef(${name})`;

	return forwardRef(instance);
};

export default withForwardedRef;
