import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import { EventUserStatus } from 'pkg/api/models/event_user';
import { useCurrentAccountUserIds, useCurrentAccountWards } from 'pkg/identity';

import StatusLabel from 'routes/event/single/event-users/StatusLabel';
import UserRow from 'routes/event/single/event-users/user-row';

import SectionTitle from 'components/SectionTitle';

import Column from 'components/layout/column';
import Row from 'components/layout/row';

import Button from 'design/button';

interface InvitedListProps {
	event: models.event.Event;
	eventUsers: models.eventUser.EventUser[];

	variant?: 'organizers' | 'participants';

	toggleInviteModal: () => void;
}

export default function InvitedList({
	event,
	eventUsers,
	variant,

	toggleInviteModal,
}: InvitedListProps): JSX.Element {
	const activeUserIds = useCurrentAccountUserIds();
	const wardRelations = useCurrentAccountWards();
	const wardUserIds = models.accountRelation.findAllUserIds(
		wardRelations.filter((ar) => models.accountRelation.isApproved(ar))
	);

	const allEventUsers = models.eventUser.findAllEventUsers(eventUsers, [
		...activeUserIds,
		...wardUserIds,
	]);

	const sortedEventUsers = models.eventUser.sortEventUsers(
		eventUsers,
		allEventUsers,
		event
	);

	const usersAccepted = sortedEventUsers
		.filter((eventUser) => eventUser.status === EventUserStatus.Accepted)
		.map((eventUser, i: number) => (
			<UserRow key={i} event={event} eventUser={eventUser} />
		));

	const usersDeclined = sortedEventUsers
		.filter((eventUser) => eventUser.status === EventUserStatus.Declined)
		.map((eventUser, i: number) => (
			<UserRow key={i} event={event} eventUser={eventUser} />
		));

	const usersNoResponse = sortedEventUsers
		.filter((eventUser) => eventUser.status === EventUserStatus.Pending)
		.map((eventUser, i: number) => (
			<UserRow key={i} event={event} eventUser={eventUser} />
		));

	if (eventUsers.length === 0 && !models.canEdit(event)) {
		return null;
	}

	const usersCount = [...usersAccepted, ...usersDeclined, ...usersNoResponse]
		.length;

	const sectionTitle =
		variant === 'organizers'
			? t('Organizers ({number})', {
					number: usersCount,
				})
			: t('Participants ({number})', {
					number: usersCount,
				});

	return (
		<Column spacing={styles.spacing._6}>
			<Column spacing={styles.spacing._2}>
				<Row columns="1fr auto" align="center" justifyContent="center">
					<SectionTitle>{sectionTitle}</SectionTitle>
					{models.canEdit(event) && (
						<Button
							inline
							icon="add"
							disabled={models.event.isCanceled(event)}
							label={t('Add')}
							onClick={toggleInviteModal}
						/>
					)}
				</Row>
			</Column>

			{usersAccepted.length > 0 && (
				<Column spacing={styles.spacing._5}>
					<StatusLabel
						color={styles.palette.green[400]}
						label={t('Accepted ({number})', {
							number: usersAccepted.length,
						})}
					/>
					{usersAccepted}
				</Column>
			)}

			{usersDeclined.length > 0 && (
				<Column spacing={styles.spacing._5}>
					<StatusLabel
						color={styles.palette.red[400]}
						label={t('Declined ({number})', {
							number: usersDeclined.length,
						})}
					/>
					{usersDeclined}
				</Column>
			)}

			{usersNoResponse.length > 0 && (
				<Column spacing={styles.spacing._5}>
					<StatusLabel
						label={t('No response ({number})', {
							number: usersNoResponse.length,
						})}
						color={styles.palette.orange[400]}
					/>
					{usersNoResponse}
				</Column>
			)}
		</Column>
	);
}
