import { useT } from '@transifex/react';

import spacing from 'pkg/config/spacing';

import useMixedState from 'pkg/hooks/useMixedState';

import * as Input from 'components/form/inputs';
import Column from 'components/layout/column';

import * as Context from 'design/context_menu';
import { DialogActivator, useDialog } from 'design/dialog';

interface RemoveUserItemProps {
	dialog: DialogActivator;
	bulk?: boolean;
}

export function RemoveUserItem({ dialog, bulk = false }: RemoveUserItemProps) {
	const t = useT();

	let copy = t('Remove user from group');

	if (bulk) {
		copy = t('Remove users from group');
	}

	return (
		<Context.Item onClick={dialog} caution>
			<Context.ItemIcon name="delete" />
			{copy}
		</Context.Item>
	);
}

interface RemoveUserDialogProps {
	handleRemoveUsers: (
		removeUsersRecursively: boolean,
		removeFromEvents: boolean,
		userId: number
	) => void;
	userId?: number;
	bulk?: boolean;
}

export function useRemoveUserDialog({
	handleRemoveUsers,
	userId = 0,
	bulk = false,
}: RemoveUserDialogProps) {
	const t = useT();

	const [state, setState] = useMixedState({
		removeUsersRecursively: false,
		removeFromEvents: false,
	});

	const handleRecursiveChange = () =>
		setState({
			removeUsersRecursively: !state.removeUsersRecursively,
		});

	const handleEventChange = () =>
		setState({ removeFromEvents: !state.removeFromEvents });

	let title = t(
		'Are you sure you want to remove this user? They can rejoin using the group code or an email invite.'
	);
	let subGroupsDescription = t(
		'This will remove the user from the current group and all its subgroups.'
	);
	let removeEventsDescription = t(
		'Would you like to remove this user from future events?'
	);

	if (bulk) {
		title = t(
			'Are you sure you want to remove these users? They can rejoin using the group code or an email invite.'
		);
		subGroupsDescription = t(
			'This will remove the users from the current group and all its subgroups.'
		);
		removeEventsDescription = t(
			'Would you like to remove these users from future events?'
		);
	}

	const dialog = useDialog(
		{
			destructive: true,
			symbol: 'delete',
			wide: true,
			title: title,
			spacing: spacing._4,
			message: (
				<Column spacing={spacing._2}>
					<Input.Control
						type="checkbox"
						label={t('Remove from subgroups')}
						description={subGroupsDescription}
						checked={state.removeUsersRecursively}
						onChange={handleRecursiveChange}
					/>
					<Input.Control
						type="checkbox"
						label={t('Remove from future events')}
						description={removeEventsDescription}
						checked={state.removeFromEvents}
						onChange={handleEventChange}
					/>
				</Column>
			),
			onConfirm: () =>
				handleRemoveUsers(
					state.removeUsersRecursively,
					state.removeFromEvents,
					userId
				),
		},
		[state.removeUsersRecursively, state.removeFromEvents]
	);

	return dialog;
}
