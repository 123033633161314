import styled from 'styled-components';
import { MouseEvent } from 'react';

import * as styles from 'pkg/config/styles';

import rgba from 'pkg/rgba';

import Icon from 'components/icon';

import Row from 'components/layout/row';

import Button from 'design/button';

interface LabelProps {
	active?: boolean;
}

const Label = styled.div<LabelProps>`
	font-weight: var(--font-weight-semibold);

	color: ${({ active }) =>
		active
			? rgba(styles.palette.main.actionableLinkColor)
			: styles.palette.black};
`;

interface FilterLabelProps {
	label: string;
	isActive: boolean;
	isSelected: boolean;
	onClearFilter: (e: MouseEvent<HTMLElement>) => void;
	isOpen: boolean;
}

const FilterLabel = ({
	label,
	isActive = false,
	isSelected = false,
	onClearFilter,
	isOpen = false,
}: FilterLabelProps) => {
	return (
		<Row autoColumns="auto auto" align="center" justifyContent="space-between">
			<Label active={isSelected}>{label}</Label>
			<Row autoColumns="auto auto" align="center">
				{isActive && (
					<Button label="Clear" secondary onClick={onClearFilter} small />
				)}
				<Icon name={isOpen ? 'chevron-down' : 'chevron'} size={2} />
			</Row>
		</Row>
	);
};

export default FilterLabel;
