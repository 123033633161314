import { ReactNode } from 'react';

export const symbol_rainy_light: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M193-493q-11 6-23 2t-17-15L53-706q-5-11-1.5-23.5T66-747q11-5 23.5-1.5T107-734l100 200q5 11 1 23.5T193-493Zm140 280q-11 6-22.5 1.5T293-227l-80-160q-5-11-1-22.5t15-17.5q11-5 22.5-1.5T267-414l80 160q5 11 1 23t-15 18Zm82-200q-11 6-23 2t-17-15L235-706q-5-11-1.5-23.5T248-747q11-5 23-1.5t18 14.5l140 280q5 11 1 23.5T415-413Zm87-200q-11 6-23 2t-18-15l-39-81q-5-11-1.5-22.5T435-747q11-5 22.5-1.5T475-734l40 80q5 11 1.5 23T502-613Zm23 399q-11 6-22.5 2T485-227l-40-80q-5-11-1.5-22.5T458-347q11-5 23-1t18 15l40 79q5 11 1 22.5T525-214Zm187 0q-11 6-23 2t-18-15L531-507q-5-11-1-22.5t15-17.5q11-5 22.5-1.5T585-534l140 280q5 11 1.5 22.5T712-214Zm61-239q-11 6-22.5 1.5T733-467L613-707q-5-11-1.5-22.5T626-747q11-5 23-1.5t18 14.5l120 240q5 11 1 23.5T773-453Zm120 240q-11 6-22.5 1.5T853-227l-60-120q-5-11-1.5-22.5T806-387q11-5 23-1.5t18 14.5l60 120q5 11 1 23.5T893-213Z"
		/>
	</svg>
);
