import { ReactNode } from 'react';

export const symbol_carpenter: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M615-99q-8.25 8.13-19.125 12.565Q585-82 573-82t-23.341-4.435Q538.317-90.87 530-99l-79-78q-8-8-12.583-18.207Q433.833-205.415 433-216q-1-11 2.5-21t10.5-20l16-21-339-479 143-143 533 532q9 9 13.5 20t4.5 22q0 11-4.5 22.5T799-283L615-99ZM506-320l126-127-366-368-65 65 305 430Zm66 179 185-185-81-80-184 185 80 80Zm-66-179 126-127-126 127Z"
		/>
	</svg>
);
