import { t } from '@transifex/native';
import { CSSProperties, Fragment, useState } from 'react';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import { EventUserStatus } from 'pkg/api/models/event_user';
import { EventSeriesUserResponseStatus } from 'pkg/api/models/event_series_user';
import * as actions from 'pkg/actions';

import EventUserCommentModal from 'routes/event/single/comment-modal';
import AttendanceModal from 'routes/event/single/attendance-modal';
import { useCalendarContext } from 'routes/group/calendar';

import Row from 'components/layout/row';

import * as Context from 'design/context_menu';
import { useDialog } from 'design/dialog';
import Button from 'design/button';

type Modals = '' | 'attendance' | 'comment';

interface EventUserActionsProps {
	// If set we return a context menu with all the actions otherwise just decline/accept buttons
	toggleWith?: JSX.Element;

	event: models.event.Event;
	refresh: () => Promise<void>;
	eventUser: models.eventUser.EventUser;
	rowStyles?: CSSProperties;
}

/**
 * Component that handles all the actions we can do on a event/eventSeries user.
 */

export default function EventUserActions({
	eventUser,
	refresh,
	event,
	toggleWith = null,
	rowStyles,
}: EventUserActionsProps): JSX.Element {
	const calendarCtx = useCalendarContext();

	const [modal, setModal] = useState<Modals>('');

	const eventSeriesUser = event?.eventSeries?.seriesUsers?.find(
		(su) => su.userId === eventUser.userId
	);

	const hasEventSeries = models.event.hasEventSeries(event);

	const hasPendingInvite = eventUser.status === EventUserStatus.Pending;
	const hasAccepted = eventUser.status === EventUserStatus.Accepted;
	const hasDeclined = eventUser.status === EventUserStatus.Declined;

	const hasPendingSeriesInvite =
		eventSeriesUser?.status === EventSeriesUserResponseStatus.Pending;
	const hasDeclinedSeries =
		eventSeriesUser?.status === EventSeriesUserResponseStatus.Declined;
	const hasAcceptedSeries =
		eventSeriesUser?.status === EventSeriesUserResponseStatus.Accepted;

	const updateEventAttendance = async (
		status: models.eventUser.EventUserStatus,
		role?: models.eventUser.EventUserRole
	) => {
		const attendanceStatus = models.event.hasEnded(event)
			? models.eventUser.AttendanceStatuses.Attended
			: models.eventUser.AttendanceStatuses.Unset;

		const success = await actions.eventUsers.patchEventUsers(event.id, {
			users: [
				{
					userId: eventUser.userId,
					attendanceStatus,
					status,
					role,
				},
			],
		});

		if (success) {
			refresh();

			if (calendarCtx.eventsCollection) {
				calendarCtx.eventsCollection.refresh();
			}
		}
	};

	const handleAcceptEvent = () => {
		updateEventAttendance(models.eventUser.EventUserStatus.Accepted);
	};

	const handleRemoveAsOrganizer = () => {
		updateEventAttendance(
			eventUser.status,
			models.eventUser.EventUserRole.Participant
		);
	};

	const handleAddAsOrganizer = () => {
		updateEventAttendance(
			eventUser.status,
			models.eventUser.EventUserRole.Organizer
		);
	};

	const handleAcceptEventSeries = async () => {
		const [ok] = await actions.eventSeriesUsers.update(event.eventSeriesId, {
			userIDs: [eventUser.userId],
			status: EventSeriesUserResponseStatus.Accepted,
		});

		if (ok) {
			refresh();
		}
	};

	const handleDeclineEventSeries = async () => {
		const [ok] = await actions.eventSeriesUsers.update(event.eventSeriesId, {
			userIDs: [eventUser.userId],
			status: EventSeriesUserResponseStatus.Declined,
		});

		if (ok) {
			refresh();
		}
	};

	const handleRemove = async () => {
		const ok = await actions.eventUsers.deleteEventUsers(event.id, [
			eventUser.userId,
		]);

		if (ok) {
			refresh();
		}
	};

	const showAttendanceModal = () => setModal('attendance');
	const showCommentModal = () => setModal('comment');

	const closeModal = () => setModal('');

	const removeDialog = useDialog({
		title: t('Uninvite user'),
		message: t(
			'This will remove {name} from this event. This action cannot be undone.',
			{
				name: eventUser.user.firstName,
			}
		),
		confirmLabel: t('Uninvite'),
		onConfirm: handleRemove,
	});

	if (!models.canEdit(eventUser)) {
		return null;
	}

	let acceptText = t('Accept this occasion');
	let declineText = t('Decline this occasion');

	const eventHasStarted = models.event.hasStarted(event);
	if (eventHasStarted) {
		acceptText = t('Attended');
		declineText = t(`Didn't attend`);
	}

	let content = null;

	// If we're in the pending invite state and don't have a specific toggleWith set
	// we return accept/decline buttons instead
	if (hasPendingInvite && !toggleWith) {
		const declineButton = (
			<Button
				block
				label={t('Decline')}
				onClick={!hasEventSeries ? showAttendanceModal : undefined}
			/>
		);
		const acceptButton = (
			<Button
				block
				primary
				label={t('Accept')}
				onClick={!hasEventSeries ? handleAcceptEvent : undefined}
			/>
		);

		let declineContent = declineButton;
		let acceptContent = acceptButton;

		if (hasEventSeries) {
			declineContent = (
				<Context.Menu toggleWith={declineButton}>
					<Context.Item onClick={showAttendanceModal}>
						<Context.ItemIcon fill={styles.palette.red[500]} name="block" />
						{t('Decline occasion')}
					</Context.Item>
					<Context.Item onClick={handleDeclineEventSeries}>
						<Context.ItemIcon
							fill={styles.palette.red[500]}
							name="sync_disabled"
						/>
						{t('Decline series')}
					</Context.Item>
				</Context.Menu>
			);

			acceptContent = (
				<Context.Menu toggleWith={acceptButton}>
					<Context.Item onClick={handleAcceptEvent}>
						<Context.ItemIcon
							fill={styles.palette.green[500]}
							name="task_alt"
						/>
						{t('Accept occasion')}
					</Context.Item>
					<Context.Item onClick={handleAcceptEventSeries}>
						<Context.ItemIcon
							fill={styles.palette.green[500]}
							name="published_with_changes"
						/>
						{t('Accept series')}
					</Context.Item>
				</Context.Menu>
			);
		}

		content = (
			<Row spacing={styles.spacing._2} style={rowStyles}>
				{declineContent}
				{acceptContent}
			</Row>
		);
	} else {
		const showSeriesEventActions =
			hasEventSeries && !eventHasStarted && hasPendingSeriesInvite;

		content = (
			<Context.Menu toggleWith={toggleWith}>
				<Fragment>
					{(hasPendingInvite || hasDeclined) && (
						<Context.Item onClick={handleAcceptEvent}>
							<Context.ItemIcon name="task_alt" />
							{acceptText}
						</Context.Item>
					)}
					{(hasPendingInvite || hasAccepted) && (
						<Context.Item onClick={showAttendanceModal}>
							<Context.ItemIcon name="block" />
							{declineText}
						</Context.Item>
					)}
				</Fragment>

				{showSeriesEventActions && (
					<Fragment>
						<Context.Divider />
						{(hasPendingSeriesInvite || hasDeclinedSeries) && (
							<Context.Item onClick={handleAcceptEventSeries}>
								<Context.ItemIcon name="published_with_changes" />
								{t('Accept this series')}
							</Context.Item>
						)}
						{(hasPendingSeriesInvite || hasAcceptedSeries) && (
							<Context.Item onClick={handleDeclineEventSeries}>
								<Context.ItemIcon name="sync_disabled" />
								{t('Decline this series')}
							</Context.Item>
						)}
					</Fragment>
				)}

				{(hasPendingInvite || showSeriesEventActions) && <Context.Divider />}

				<Context.Item onClick={showCommentModal}>
					<Context.ItemIcon
						name={eventUser.comment ? 'chat' : 'add_comment'}
						fill={styles.palette.black}
					/>
					{eventUser.comment ? t('Edit comment') : t('Add comment')}
				</Context.Item>
				{models.canEdit(event) && (
					<Fragment>
						{eventUser.role === 'organizer' ? (
							<Context.Item onClick={handleRemoveAsOrganizer}>
								<Context.ItemIcon name="person_remove" />
								<span>{t('Remove as organizer')}</span>
							</Context.Item>
						) : (
							<Context.Item onClick={handleAddAsOrganizer}>
								<Context.ItemIcon name="person_edit" />
								<span>{t('Make organizer')}</span>
							</Context.Item>
						)}
						<Context.Divider />
						<Context.Item onClick={removeDialog} caution>
							<Context.ItemIcon name="person_off" />
							{t('Uninvite')}
						</Context.Item>
					</Fragment>
				)}
			</Context.Menu>
		);
	}

	return (
		<Fragment>
			{content}
			{modal === 'comment' && (
				<EventUserCommentModal eventUser={eventUser} closeModal={closeModal} />
			)}

			{modal === 'attendance' && (
				<AttendanceModal
					event={event}
					refresh={refresh}
					eventUser={eventUser}
					closeModal={closeModal}
				/>
			)}
		</Fragment>
	);
}
