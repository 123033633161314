import { useState } from 'react';
import styled, { css } from 'styled-components';

export const Image = styled.img`
	display: block;
	object-fit: cover;
	width: 100%;
	height: 100%;
	transition: opacity 250ms ease-in-out;
	opacity: ${({ $loaded }) => ($loaded ? 1 : 0)};
	image-orientation: none;

	${({ $clickable }) =>
		$clickable &&
		css`
			cursor: pointer;
		`}
`;

/**
 * @type {React.Element<any>}
 */

const ImageAttachment = ({
	attachment,
	toggleGallery,
	clickable,
	className,
}) => {
	const [isLoaded, setIsLoaded] = useState(false);

	const handleDidLoad = () => setIsLoaded(true);
	const handleOnClick = () => toggleGallery(attachment.id);

	return (
		<Image
			src={attachment.previewUrl || attachment.url}
			onClick={handleOnClick}
			onLoad={handleDidLoad}
			$loaded={isLoaded}
			loading="lazy"
			$clickable={clickable}
			className={className}
		/>
	);
};

export default ImageAttachment;
