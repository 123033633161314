import { ReactNode } from 'react';

export const symbol_camera: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-602h319q-28-74-89-130.5T571-810L467-624q-5 8 0 15t13 7Zm-132 61q4.5 8 12.75 8t13.25-8l161-277q-12-1-27-1.5t-28-.5q-72 0-132 26t-108 72l108 181ZM149.771-393H361q8.25 0 13.125-7T374-415L217-696q-38 45-57.5 100.5T140-480q0 21 2 44t7.771 43ZM390-151l107-184q5-8 .5-15.5T484-358H162q28 74 88.5 130.5T390-151Zm90.077 11Q552-140 612.5-166T720-238L613-419q-4.5-8-12.75-8T587-419L425-142q13 1 27.5 1.5t27.577.5ZM744-264q34-42 55-99t21-116.636q0-22.364-2-44.864-2-22.5-7-43.5H600q-8 0-13.5 7t-.5 15l158 282ZM480-480Zm0 400q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-155.5t86-127Q252-817 325-848.5T480-880q83 0 155.5 31.5t127 86q54.5 54.5 86 127T880-480q0 82-31.5 155t-86 127.5q-54.5 54.5-127 86T480-80Z"
		/>
	</svg>
);
