import { ReactNode } from 'react';

export const symbol_laptop_chromebook: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M30-160q-12.75 0-21.375-8.675Q0-177.351 0-190.175 0-203 8.625-211.5T30-220h50v-560q0-24.75 17.625-42.375T140-840h680q24.75 0 42.375 17.625T880-780v560h50q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T930-160H30Zm386.588-60h128.824Q554-220 560-226t6-15q0-9-6-15t-14.588-6H416.588Q408-262 402-256t-6 15q0 9 6 15t14.588 6ZM144-322h680v-458H144v458Zm340-229Z"
		/>
	</svg>
);
