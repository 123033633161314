import { ReactNode } from 'react';

export const symbol_travel_luggage_and_bags: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M200-185v-475q0-24.75 17.625-42.375T260-720h105v-125q0-14 10.5-24.5T400-880h160q14 0 24.5 10.5T595-845v125h105q24.75 0 42.375 17.625T760-660v475q0 24.75-17.625 42.375T700-125v15q0 12.75-8.625 21.375T670-80q-13 0-21.5-8.5T640-110v-15H320v15q0 12.75-8.625 21.375T290-80q-13 0-21.5-8.5T260-110v-15q-24.75 0-42.375-17.625T200-185Zm225-535h110v-100H425v100Zm55 235q58 0 114.5-13T700-542v-118H260v118q49 31 105.5 44T480-485Zm-30 75v-11q-51-3-99-16.5T260-477v292h440v-292q-43 26-91 39.5T510-421v11q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T450-410Zm30 30Zm0-105Zm0 8Z"
		/>
	</svg>
);
