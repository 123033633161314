import { ReactNode } from 'react';

export const symbol_cloud_sync: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M220-477q0 64 27.5 117.5T337-266v-94q0-13 8.5-21.5T367-390q13 0 21.5 8.5T397-360v170q0 13-8.5 21.5T367-160H197q-13 0-21.5-8.5T167-190q0-13 8.5-21.5T197-220h100q-64-48-100.5-108.5T160-477q0-89 46.5-167.5T340-765q11-5 21.5 0t15.5 16q4 11 0 22t-15 16q-62 32-102 94.5T220-477Zm380 317q-50 0-85-35t-35-85q0-48 33-83t81-37q17-36 50.5-58t75.5-22q53 0 91.5 34.5T858-360h2q42 0 71 30t29 69q0 42-29 71.5T860-160H600Zm26-534v94q0 13-8.5 21.5T596-570q-13 0-21.5-8.5T566-600v-170q0-13 8.5-21.5T596-800h170q13 0 21.5 8.5T796-770q0 13-8.5 21.5T766-740H665q41 34 74 80.5t49 97.5q4 12-2.5 23T766-526q-12 2-23-5t-15-19q-14-42-39-77t-63-67Zm-26 474h260q17 0 28.5-11.5T900-260q0-17-11.5-28.5T860-300h-50v-30q0-38-26-64t-64-26q-38 0-64 24.5T630-340h-30q-25 0-42.5 17.5T540-280q0 25 17.5 42.5T600-220Zm120-100Z"
		/>
	</svg>
);
