import styled from 'styled-components';

const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
	user-select: none;
	font-size: var(--font-size-base);

	display: grid;
	grid-template-areas:
		'lw st rw'
		'lw cf rw'
		'lm cam rm'
		'lm cm rm'
		'lb cdm rb'
		'lb cb rb';

	grid-template-rows: repeat(6, 1fr);
	grid-template-columns: 1fr 1.5fr 1fr;

	&.heatmap--with-goalkeeper {
		grid-template-areas:
			'lw st rw'
			'lw cf rw'
			'lm cam rm'
			'lm cm rm'
			'lb cdm rb'
			'lb cb rb'
			'. gk .';

		grid-template-rows: repeat(7, 1fr);
	}

	.heatmap__spot.lw {
		grid-area: lw;
	}
	.heatmap__spot.st {
		grid-area: st;
	}
	.heatmap__spot.cf {
		grid-area: cf;
	}
	.heatmap__spot.cam {
		grid-area: cam;
	}
	.heatmap__spot.cm {
		grid-area: cm;
	}
	.heatmap__spot.cdm {
		grid-area: cdm;
	}
	.heatmap__spot.cb {
		grid-area: cb;
	}
	.heatmap__spot.gk {
		grid-area: gk;
	}
	.heatmap__spot.lm {
		grid-area: lm;
	}
	.heatmap__spot.lb {
		grid-area: lb;
	}
	.heatmap__spot.rb {
		grid-area: rb;
	}
	.heatmap__spot.rm {
		grid-area: rm;
	}
	.heatmap__spot.rw {
		grid-area: rw;
	}

	&.heatmap--small {
		font-size: 9px;
	}
	&.heatmap--small .heatmap__spot--chosen::after {
		border-width: 10px;
		border-top-width: 0;
	}
	&.heatmap--small .heatmap__spot--chosen::before {
		border-width: 12px;
		border-top-width: 0;
	}
	&.heatmap--interactive .heatmap__spot {
		cursor: pointer;
	}

	.heatmap__spot {
		background-color: #ccc;
		text-align: center;
		padding: 0;
		font-weight: var(--font-weight-semibold);
		color: #222;
		border-radius: 0;
		border: 1px solid #fff;
		z-index: 0;
		border-radius: var(--radius-3);
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.heatmap__spot--border-less {
		border: 0px solid;
		border-radius: 0;
	}
	.heatmap__spot--chosen {
		overflow: hidden;
		z-index: 1;
	}
	.heatmap__spot--chosen::after {
		content: '';
		width: 0;
		height: 0;
		border: 15px solid transparent;
		border-right-color: #1e1e1e;
		border-top-width: 0;
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		z-index: 2;
	}
	.heatmap__spot--chosen::before {
		content: '';
		width: 0;
		height: 0;
		border: 17px solid transparent;
		border-right-color: #fff;
		border-top-width: 0;
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		z-index: 1;
	}
	.heatmap__spot.active {
		border-color: #1e1e1e;
		z-index: 2;
		border-radius: var(--radius-2);
		box-shadow: inset 0 -5px 0 0 rgba(0, 0, 0, 0.5);
	}
	.heatmap__spot.active .info {
		margin-top: -4px;
	}
	.heatmap__spot .info .position {
		font-weight: var(--font-weight-semibold);
		font-size: 1.5em;
		letter-spacing: 1px;
	}
	.heatmap__spot .info .score {
		font-size: 0.85em;
	}
`;

export default Wrapper;
