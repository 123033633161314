import { ReactNode } from 'react';

export const symbol_cleaning_services: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-40q-24.75 0-42.375-17.625T120-100v-230q0-78.85 55.575-134.425Q231.15-520 310-520h60v-340q0-24.75 17.625-42.375T430-920h100q24.75 0 42.375 17.625T590-860v340h60q78.85 0 134.425 55.575Q840-408.85 840-330v230q0 24.75-17.625 42.375T780-40H180Zm0-60h105v-130q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T345-230v130h105v-130q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-230v130h105v-130q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T675-230v130h105v-230q0-54.167-37.917-92.083Q704.167-460 650-460H310q-54.167 0-92.083 37.917Q180-384.167 180-330v230Zm350-420v-340H430v340h100Zm0 0H430h100Z"
		/>
	</svg>
);
