import { t } from '@transifex/native';
import { useState } from 'react';

import * as models from 'pkg/api/models';
import { useCollection } from 'pkg/api/use_collection';
import { FilterModalProps } from 'pkg/filters/types/Modal';
import * as endpoints from 'pkg/api/endpoints/auto';
import { FilterOperator } from 'pkg/filters';
import { useCurrentGroup } from 'pkg/identity';

import Pagination from 'components/pagination';
import * as StepModal from 'components/step-modal';

import * as ActionBar from 'components/layout/ActionBar';
import FormsTable from 'components/forms/table';
import SearchInput from 'components/form/SearchInput';

export default function SelectFormModal({
	handleClose,
	handleNext,

	selected = [],
	handleSelectItem,
}: FilterModalProps) {
	const [search, setSearch] = useState('');
	const group = useCurrentGroup();

	const { records: forms, pagination } = useCollection<models.form.Form>(
		endpoints.Forms.Index(),
		{
			queryParams: new URLSearchParams({
				group_id: group.id.toString(),
				filters: JSON.stringify([
					{
						property: 'title',
						operator: FilterOperator.Contains,
						values: [search],
					},
				]),
			}),
		}
	);

	const handleSearch = (value: string) => {
		setSearch(value);
	};

	return (
		<StepModal.Base onClose={handleClose}>
			<StepModal.Step title={t('Registrations')} skipBody onNext={handleNext}>
				<ActionBar.Bar>
					<ActionBar.PrimaryAction>
						<SearchInput
							onChange={handleSearch}
							placeholder={t('Search registrations')}
						/>
					</ActionBar.PrimaryAction>
				</ActionBar.Bar>
				<FormsTable
					forms={forms}
					selected={selected}
					select={handleSelectItem}
				/>
				<Pagination {...pagination} />
			</StepModal.Step>
		</StepModal.Base>
	);
}
