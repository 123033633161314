import { ReactNode } from 'react';

export const symbol_bid_landscape_disabled: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M747-120H180q-24 0-42-18t-18-42v-567l-79-79q-9-8.8-9-20.9 0-12.1 8.842-21.1 8.842-9 21-9T83-868L873-78q9 9 9 21t-8.842 21q-8.842 9-21 9T831-36l-84-84ZM364-474 180-290v110h506L554-312q-8 4-17.5 2.5T520-318L364-474ZM180-687v312l156-156-156-156Zm660-93v524q0 15-9.318 22.5t-20.5 7.5Q799-226 789.5-233.683 780-241.366 780-256v-280L661-417q-9 9-21 8.5t-21-9.5q-9-9-9-21t9-21l161-161v-159H255q-15 0-22.5-9.318t-7.5-20.5q0-11.182 7.5-20.682Q240-840 255-840h525q24 0 42 18t18 42ZM445-421ZM336-531Zm17 68Zm165-55Z"
		/>
	</svg>
);
