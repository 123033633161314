import { ReactNode } from 'react';

export const symbol_pip: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M110-520q-12.75 0-21.375-8.675Q80-537.351 80-550.175 80-563 88.625-571.5T110-580h148L79-759q-9-9.067-9-21.533Q70-793 79.053-802q9.052-9 21.5-9Q113-811 122-802l178 179v-147q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T360-770v220q0 12.75-8.625 21.375T330-520H110Zm30 360q-24 0-42-18t-18-42v-200q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-420v200h310q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T450-160H140Zm709.825-280Q837-440 828.5-448.625T820-470v-270H460q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T460-800h360q24 0 42 18t18 42v270q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM570-160q-12.75 0-21.375-8.625T540-190v-160q0-12.75 8.625-21.375T570-380h280q12.75 0 21.375 8.625T880-350v160q0 12.75-8.625 21.375T850-160H570Z"
		/>
	</svg>
);
