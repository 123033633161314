import { ReactNode } from 'react';

export const symbol_camera_front: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M415-160H230q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T230-220h187l-38-38q-9-9-9-21t9-21q9-9 21.5-9t21.5 9l89 88q9 9 9 21t-9 21l-89 89q-9 9-21 8.5T380-82q-9-9-9-21.5t9-21.5l35-35ZM200-820q0-24 18-42t42-18h440q24 0 42 18t18 42v518q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T700-302v-53q-44-26-97.5-39.5T480-408q-69 0-122 13.5T260-355v53q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T200-302v-518Zm60 398q52-23 107.5-34.5T480-468q57 0 112.245 11.5Q647.49-445 700-422v-398H260v398Zm330 262q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T590-220h140q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T730-160H590ZM480-408Zm-.235-118Q433-526 400.5-558.735q-32.5-32.736-32.5-79.5Q368-685 400.735-717.5q32.736-32.5 79.5-32.5Q527-750 559.5-717.265q32.5 32.736 32.5 79.5Q592-591 559.265-558.5q-32.736 32.5-79.5 32.5Zm.165-60Q502-586 517-600.93t15-37Q532-660 517.07-675t-37-15Q458-690 443-675.07t-15 37Q428-616 442.93-601t37 15Zm.07-52Z"
		/>
	</svg>
);
