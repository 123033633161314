import { normalize } from 'normalizr';

import Country from 'pkg/models/country';

import * as actionTypes from 'pkg/actions/action-types';

import * as sdk from 'pkg/core/sdk';
import store from 'pkg/store/createStore';

export async function fetchCountries() {
	const req = await sdk.get('countries');

	if (!req.ok) {
		return;
	}

	const resp = await req.json();

	const normalized = normalize(resp, [Country.normalizr()]);

	store.dispatch({
		type: actionTypes.Countries.SET_ITEMS,
		countries: normalized.entities.countries,
	});
}
