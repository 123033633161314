import { t } from '@transifex/native';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import CardWrapper, * as Card from 'components/Card';
import AssetImage from 'components/AssetImage';
import Modal from 'components/modal';

import { FabricContext } from 'components/drawing/FabricContext';

export default function BackgroundModal({}: any) {
	const { drawing, setDrawing, setSetting, settings, config } =
		useContext(FabricContext);

	const onClose = () => {
		setSetting('backgroundModalOpen', false);
	};

	const [backgrounds, setBackgrounds] = useState({});
	useEffect(() => {
		if (config) setBackgrounds(config.backgrounds);
	}, [config]);

	return drawing.background && !settings.backgroundModalOpen ? null : (
		<Modal hideClose={!drawing.background} onClose={onClose}>
			<CardWrapper>
				<Card.Header>
					<Card.Heading>{t(`Select background`)}</Card.Heading>
				</Card.Header>
				<Card.Divider />
				<Card.Body $flex $noSpacing>
					<BGWrapper>
						{Object.entries(backgrounds).map(([id, config]: any) => (
							<BG
								key={id}
								onClick={() => {
									setDrawing({ ...drawing, background: id });
									setSetting('backgroundModalOpen', false);
								}}
								className={drawing.background === id ? 'current' : ''}>
								<div>
									<AssetImage src={config.url + '_thumb.png'} />
								</div>
								<span>{config.title}</span>
							</BG>
						))}
					</BGWrapper>
				</Card.Body>
			</CardWrapper>
		</Modal>
	);
}

const BGWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(4, auto);
	padding: var(--spacing-4);
	gap: var(--spacing-5);
`;

const BG = styled.div`
	border: 2px solid #eee;
	border-radius: var(--radius-3);
	overflow: hidden;
	cursor: pointer;
	padding: var(--spacing-4);

	&:hover,
	&.current {
		border-color: var(--palette-blue-500);
	}

	div {
		display: flex;
		width: 100%;
		place-items: center;
		justify-content: center;
		aspect-ratio: 1/1;
	}

	img {
		display: block;
		max-width: 100%;
		max-height: 100%;
		border-radius: 5%;
		border: 1px solid #eee;
	}

	span {
		display: block;
		font-size: var(--font-size-sm);
		font-weight: var(--font-weight-semibold);
		text-align: center;
		margin-top: var(--spacing-4);
	}
`;
