const icon = {
	name: 'calendar-continue',
	objects: [
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M4.28344 5C4.54631 5 4.7594 5.21309 4.7594 5.47596V6.75864H11.4282V5.47596C11.4282 5.21309 11.6413 5 11.9042 5C12.167 5 12.3801 5.21309 12.3801 5.47596V6.75895C13.4355 6.77777 14.2853 7.63919 14.2853 8.69909V10.7772H11.0716V10.7882H2.95192V16.957C2.95192 17.5029 3.3945 17.9455 3.94045 17.9455H11.0716V17.9532H14.0104C13.6713 18.5189 13.0523 18.8974 12.3449 18.8974H3.94045C2.86877 18.8974 2 18.0287 2 16.957V8.69909C2 7.6721 2.79782 6.83145 3.80748 6.76312V5.47596C3.80748 5.21309 4.02057 5 4.28344 5ZM3.94045 7.71056C3.3945 7.71056 2.95192 8.15314 2.95192 8.69909V9.83623H13.3334V8.69909C13.3334 8.15314 12.8908 7.71056 12.3449 7.71056H3.94045Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M18.2212 10.2052C18.0153 9.99503 17.678 9.99156 17.4678 10.1975C17.2576 10.4034 17.2541 10.7407 17.4601 10.9509L20.1995 13.7472H11.6043C11.3101 13.7472 11.0716 13.9857 11.0716 14.2799C11.0716 14.5742 11.3101 14.8127 11.6043 14.8127H20.1995L17.4601 17.609C17.2541 17.8192 17.2576 18.1565 17.4678 18.3624C17.678 18.5683 18.0153 18.5649 18.2212 18.3547L21.8478 14.6528C22.0507 14.4456 22.0507 14.1142 21.8478 13.9071L18.2212 10.2052Z',
			},
		},
	],
};

export default icon;
