import styled from 'styled-components';

import { palette } from 'pkg/config/styles';

import rgba from 'pkg/rgba';
import * as models from 'pkg/api/models';

import Icon from 'components/icon';
import Avatar from 'components/avatar';

const CloseIcon = styled(Icon)`
	padding: 5px;
	font-size: 1.5rem;
	background: ${palette.red[400]};
	color: ${palette.white};
	border-radius: 10rem;
	box-shadow: 0 0 0 2px ${palette.white};
	position: absolute;
	z-index: 2;
	right: 0;
	top: 0;
`;

const SelectedContainer = styled.div`
	margin: 0;
	padding: 0.5rem 0;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
`;

const SelectedList = styled.ol`
	padding: 1rem;
	margin-bottom: 1rem;
	display: grid;
	grid-gap: 1rem;
	grid-auto-flow: column;
	grid-auto-columns: 4rem;
`;

const SelectedListItem = styled.li`
	display: grid;
	grid-gap: 0.5rem;
	grid-auto-flow: row;
	grid-auto-rows: 4rem auto;
	position: relative;
	cursor: pointer;
	border-radius: var(--radius-3);

	${CloseIcon} {
		transition: all 150ms ease-in-out;
	}

	@media (hover: hover) {
		&:hover {
			color: ${palette.blue[400]};
			background: linear-gradient(
				to bottom,
				${rgba(palette.blue[400], 0)},
				${rgba(palette.blue[400], 0.1)}
			);

			${CloseIcon} {
				background: ${palette.black};
				transform: scale(1.2);
			}
		}
	}
`;

const SelectedListItemLabel = styled.span`
	text-align: center;
`;

interface ListPreviewProps {
	showPreview: boolean;
	numSelectedUsers: number;

	deselect: (user: models.user.User) => void;

	selectedUsers: models.user.User[];
}

export default function ListPreview({
	showPreview,
	numSelectedUsers,
	deselect,
	selectedUsers,
}: ListPreviewProps) {
	if (!showPreview || numSelectedUsers === 0) {
		return null;
	}

	return (
		<SelectedContainer>
			<SelectedList>
				{selectedUsers.map((user) => (
					<SelectedListItem key={user.id} onClick={() => deselect(user)}>
						<CloseIcon name="close" />
						<Avatar user={user} />
						<SelectedListItemLabel>{user.firstName}</SelectedListItemLabel>
					</SelectedListItem>
				))}
			</SelectedList>
		</SelectedContainer>
	);
}
