import { ReactNode } from 'react';

export const symbol_shield_question: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-81q-140-35-230-162.5T160-523v-238l320-120 320 120v238q0 152-90 279.5T480-81Zm0-62q115-38 187.5-143.5T740-523v-196l-260-98-260 98v196q0 131 72.5 236.5T480-143Zm0-337Zm0 195q15 0 26-11t11-26q0-15-11-26t-26-11q-15 0-26 11t-11 26q0 15 11 26t26 11Zm2-128q9 0 16-6t9-15q1-7 3-13t6-12q5-8 11.5-15t13.5-13q20-18 34-41t14-50q0-42-31.5-69.5T483-675q-31 0-57.5 14T383-621q-5 8-1.5 17.5T395-590q9 4 18 0t16-12q10-12 24-18.5t30-6.5q23 0 39.5 14.5T539-576q0 19-11 34t-25 27q-10 9-18.5 19T469-475q-5 8-8.5 17.5T457-438q0 11 7 18t18 7Z"
		/>
	</svg>
);
