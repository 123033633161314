import { ReactNode } from 'react';

export const symbol_flutter_dash: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M295-320q-3-4 19.5-31.5T366-410q33-37 77-82 12-18 33-18t35 16q12 16 9 36.5T498-428q-55 33-98 58-38 21-70 37.5T295-320ZM430-40q-5 0-10-2t-8-7q-4-6-8-16.5T400-90q0-9 1-16.5t3-15.5q-27-5-45.5-27T340-200q0-4 3-21-74-26-119.5-83T165-451q-5 5-11 8t-14 3q-15 0-37.5-22.5T80-560q0-69 40.5-124.5T200-740q13 0 16.5 11.5T220-706q38-54 95.5-89T442-838q6-29 29-45.5t49-16.5v40q4-6 10-10 5-4 12.5-7t17.5-3q10 0 17.5 3t12.5 7q6 4 10 10-15 0-25 9t-13 22q54 14 100 46t78 77q0-11 3.5-22.5T760-740q39 0 79.5 55.5T880-560q0 75-22.5 97.5T820-440q-7 0-13.5-2.5T795-450q-14 100-68.5 159.5T582-211q3 14 13.5 22.5T620-180h23q7 0 12 4t7 10q5 16 17.5 30t23.5 23q8 7 7.5 16.5T702-82q-11 8-28 14t-44 8q-5 0-10-2t-8-7q-4-6-8-16.5t-4-24.5q0-9 1-16.5t3-15.5q-24-5-40.5-22T542-204q-15 2-30 3t-32 1q-26 0-50.5-2.5T382-210l-2 10q0 17 11.5 28.5T420-160h23q7 0 12 4t7 10q5 16 17.5 30T503-93q8 7 7.5 16.5T502-62q-11 8-28 14t-44 8Zm320-391q5-20 7.5-42t2.5-47q0-116-82-198t-198-82q-116 0-198 82t-82 198q0 24 2.5 46t7.5 42q5-20 13.5-39.5T243-508q-6-14-9.5-29.5T230-570q0-63 43.5-106.5T380-720q29 0 54.5 10.5T480-681q20-18 45.5-28.5T580-720q63 0 106.5 43.5T730-570q0 17-3.5 32.5T716-508q11 17 20 36.5t14 40.5ZM480-240q87 0 146.5-27t92.5-81q0-3 .5-6t.5-6q0-30-7-58t-21-53q-21 23-49.5 37T580-420q-8 0-16-1t-16-3q5-7 7.5-14.5T559-454q1-2 1-4v-4q5 1 10 1.5t10 .5q46 0 78-32t32-78q0-46-32-78t-78-32q-20 0-39 7.5T507-651l-27 24-27-24q-15-14-34-21.5t-39-7.5q-46 0-78 32t-32 78q0 41 25.5 70.5T359-462l-30 33q-18-7-33.5-17.5T268-471q-14 25-21 53t-7 58v10q34 54 94 82t146 28Zm100-260q-25 0-42.5-20.5T520-570q0-29 17.5-49.5T580-640q25 0 42.5 20.5T640-570q0 29-17.5 49.5T580-500Zm15-90q7 0 11-4.5t4-10.5q0-6-4.5-10.5T595-620q-7 0-11 4.5t-4 10.5q0 7 4.5 11t10.5 4Zm-215 90q-25 0-42.5-20.5T320-570q0-29 17.5-49.5T380-640q25 0 42.5 20.5T440-570q0 29-17.5 49.5T380-500Zm15-90q7 0 11-4.5t4-10.5q0-6-4.5-10.5T395-620q-7 0-11 4.5t-4 10.5q0 7 4.5 11t10.5 4Z"
		/>
	</svg>
);
