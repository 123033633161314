import { ReactNode } from 'react';

export const symbol_cheer: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m312-782-22-63q-3-8 1-15t12-10q8-3 15 .5t10 11.5l22 63q3 8-1 15t-12 10q-8 3-15-.5T312-782Zm148-48v-60q0-8 6-14t14-6q8 0 14 6t6 14v60q0 8-6 14t-14 6q-8 0-14-6t-6-14Zm150 35 22-63q3-8 10-11.5t15-.5q8 3 12 10t1 15l-22 63q-3 8-10 11.5t-15 .5q-8-3-12-10t-1-15ZM114-44q-13 2-22.5-5T80-69v-1q-2-12 5.5-21.5T105-103l182-28q14-2 24.5-11.5T327-166l33-106q7-21-3.5-40T324-336l-25 77q-4 12-14.5 17.5T262-240q-12-4-18-15t-2-23l80-250q4-13 1-26t-13-21L169-309q-14 27-40 43t-58 16h-1q-13 0-21.5-8.5T40-280q0-13 8.5-21.5T70-310h2q14 0 26-7t18-20l158-297q6-11 18-15t23 2l14 8q34 20 48 56t2 74l-37 116q46 11 67.5 52.5T417-253l-33 106q-10 30-33.5 50T296-72L114-44Zm550-28q-31-5-54.5-25T576-147l-33-106q-14-46 7.5-87.5T618-393l-37-116q-12-38 2-74t48-56l14-8q11-6 23-2t18 15l158 297q6 13 18 20t26 7h2q13 0 21.5 8.5T920-280q0 13-8.5 21.5T890-250h-1q-32 0-58-16t-40-43L650-575q-10 8-13 21t1 26l80 251q4 12-2 22.5T698-240q-12 4-22.5-1.5T661-259l-25-77q-22 5-32.5 24t-3.5 40l33 106q5 14 15.5 23.5T673-131l182 28q12 2 19.5 11.5T880-70v1q-2 13-11.5 20T846-44L664-72Zm-28-264Zm-312 0Zm-62 96Zm436 0Z"
		/>
	</svg>
);
