import { ReactNode } from 'react';

export const symbol_id_card: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M730-450q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T730-510H610q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T610-450h120Zm0-120q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T730-630H610q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T610-570h120ZM360-434q-38 0-66.638 6.5Q264.725-421 243-407q-20 11-31 28.135-11 17.134-11 36.865 0 9.429 6.677 15.714Q214.355-320 224-320h272q9.645 0 16.323-6.517Q519-333.033 519-343q0-17.694-10.5-34.347Q498-394 478-407q-22-14-51-20.5t-67-6.5Zm-.08-60q30.08 0 51.58-21.42 21.5-21.421 21.5-51.5 0-30.08-21.42-51.58-21.421-21.5-51.5-21.5-30.08 0-51.58 21.42-21.5 21.421-21.5 51.5 0 30.08 21.42 51.58 21.421 21.5 51.5 21.5ZM140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm0-60h680v-520H140v520Zm0 0v-520 520Z"
		/>
	</svg>
);
