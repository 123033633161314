import { ReactNode } from 'react';

export const symbol_data_array: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M630-160q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T630-220h110v-520H630q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T630-800h110q24.75 0 42.375 17.625T800-740v520q0 24.75-17.625 42.375T740-160H630Zm-410 0q-24.75 0-42.375-17.625T160-220v-520q0-24.75 17.625-42.375T220-800h110q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T330-740H220v520h110q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T330-160H220Z"
		/>
	</svg>
);
