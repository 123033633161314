import { ReactNode } from 'react';

export const symbol_wrist: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M248-280H70q-12.75 0-21.375-8.675Q40-297.351 40-310.175 40-323 48.625-331.5T70-340h177q12.286 0 23.42 4.435Q281.554-331.13 290-323l150 150-50-104q-8-15 .75-29T417-320h393q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-260H465l19 39q13 26 9.5 54.5T470-118l-9 9q-9 9-21 9t-21-9L248-280ZM40-650.175Q40-663 48.625-671.5T70-680h158l53-54q12.8-12.629 29.365-19.314Q326.929-760 345-760h425q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T770-700H353q-9.818 0-18.409 4T318-685l-48 48q-8.446 8.13-19.58 12.565Q239.286-620 227-620H70q-12.75 0-21.375-8.675Q40-637.351 40-650.175ZM40-431Zm850 26H600q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T600-465h290q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T890-405Zm-40-150H600q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T600-615h250q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-555Z"
		/>
	</svg>
);
