import { ReactNode } from 'react';

export const symbol_airplay: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M80-260v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24.75-17.625 42.375T820-200h-90q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T730-260h90v-520H140v520h89q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T229-200h-89q-24.75 0-42.375-17.625T80-260Zm248 141q-10.733 0-16.867-7Q305-133 305-142q0-5 1.5-8.5t5.5-7.5l147-147q5-5 10.5-7t11-2q5.5 0 10.5 2t10 7l147 147q3.889 3.906 5.444 8.203Q655-145.5 655-141.594q0 8.594-6 15.094t-17 7.5H328Zm152-371Z"
		/>
	</svg>
);
