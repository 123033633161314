import { ReactNode } from 'react';

export const symbol_logo_dev: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M315.541-357Q337-357 351.5-371.674 366-386.348 366-407v-146q0-20.652-14.674-35.326T316-603h-75q-10.833 0-17.917 7.083Q216-588.833 216-578v196q0 10.833 7.083 17.917Q230.167-357 241-357h74.541ZM266-407v-146h50v146h-50Zm166 50h78q9.818 0 16.909-7.583Q534-372.167 534-383q0-11-6.5-17.5T510-407h-58v-52h31q9.13 0 15.065-6.5Q504-472 504-481.091t-5.935-15Q492.13-502 483-502h-31v-51h57q10.87 0 17.935-7.091 7.065-7.091 7.065-18Q534-589 526.935-596q-7.065-7-17.935-7h-76.705Q419-603 410.5-594.5 402-586 402-573v185.596q0 13.404 8.5 21.904T432-357Zm231-1q14.219 0 24.609-8.5Q698-375 702-388l56-183q4-12-3.5-22T734-603q-8 0-14.5 4.5T711-586l-48 153-48-153q-2.091-8-8.364-12.5Q600.364-603 592-603q-13 0-20.5 10t-3.5 22l56 183q4 13 14.391 21.5Q648.781-358 663-358ZM180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-60h600v-600H180v600Zm0-600v600-600Z"
		/>
	</svg>
);
