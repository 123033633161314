import { ReactNode } from 'react';

export const symbol_screen_rotation: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M516-191 191-516q-18-20-18-46t18-46l166-165q18-20 44.988-20T449-773l325 325q19 19 19 46t-19 46L608-191q-18 20-44.988 20T516-191Zm46-39 172-172-331-332-172 172 331 332Zm-79-252Zm47-410 107 107q9 9 9 21t-9 21q-9 9-21.5 9t-21.5-9L419-917q-11-11-6-24t18.71-16q11.29-2 23.29-2.5t23-.5q100 0 187.5 38t153 103.5Q884-753 922-665.5T960-478q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T900-478q0-80-28-151.5T794.5-757Q745-813 677-848.5T530-892ZM433-66 324-175q-9-9-8.5-21t9.5-21q9-9 21.5-9t21.5 9L540-45q11 11 6 25.5T526-2q-12.444 2-24 2.5T478 1q-98.511 0-185.579-37.5-87.068-37.5-152.244-103Q75-205 37.5-292.181 0-379.361 0-478q0-12.75 8.675-21.375Q17.351-508 30.175-508 43-508 51.5-499.375T60-478q0 80 28.5 151.5t78.5 127Q217-144 285.5-109T433-66Z"
		/>
	</svg>
);
