import { ReactNode } from 'react';

export const symbol_outdoor_garden: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M80-120v-600l160-120 120 90 120-90 120 90 120-90 160 120v600H80Zm60-60h187v-517l-87-68-100 75v510Zm247 0h186v-517l-93-68-93 68v517Zm246 0h187v-510l-100-75-87 68v517Z"
		/>
	</svg>
);
