import { ReactNode } from 'react';

export const symbol_view_carousel: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M80-355v-250q0-28.875 20.589-49.438Q121.177-675 150.089-675 179-675 199.5-654.438 220-633.875 220-605v250q0 28.875-20.589 49.438Q178.823-285 149.911-285 121-285 100.5-305.562 80-326.125 80-355Zm260 155q-24 0-42-18t-18-42v-440q0-24 18-42t42-18h280q24 0 42 18t18 42v440q0 24-18 42t-42 18H340Zm400-155v-250q0-28.875 20.589-49.438Q781.177-675 810.089-675 839-675 859.5-654.438 880-633.875 880-605v250q0 28.875-20.589 49.438Q838.823-285 809.911-285 781-285 760.5-305.562 740-326.125 740-355Zm-400 95h280v-440H340v440Zm140-220Z"
		/>
	</svg>
);
