import { ReactNode } from 'react';

export const symbol_mouse_lock_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M450-593Zm33 33Zm-66 103Zm-16-103Zm49 0Zm60-60Zm-30-280q118 0 199 81t81 199v167q-15-4-30-5t-30 1v-103H483l-33-33v-245q-48 6-87 30.5T298-745l-43-43q38-51 96.5-81.5T480-900ZM225-737l176 177H260v200q0 91.3 64.35 155.65Q388.7-140 480-140q16 0 31.5-2t29.5-7v63q-15 3-30 4.5T480-80q-118 0-199-81t-81-199v-260q0-32.172 6.5-61.586T225-737Zm285 117h190q0-81-53-144t-137-74v218ZM820-54 56-820q-9-8.8-9-20.9 0-12.1 9.214-21.1 9.215-9 21.5-9Q90-871 99-862L863-96q9 9 9 21t-9.214 21q-9.215 9-21.5 9Q829-45 820-54ZM635-80q-15 0-28.5-13.5T593-121.9V-256q0-12.75 8.625-21.375T623-286h51l166 167q-2 14-14.5 26.5T799-80H635Zm163-166-39-40v-40q0-19.55-11.5-32.775Q736-372 717-372q-11 0-19 3.5T685-358l-28-28q11-12 26.5-19t33.5-7q35 0 58 25t23 61v80Z"
		/>
	</svg>
);
