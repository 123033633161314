import { ReactNode } from 'react';

export const symbol_skillet: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-692q-21-31-30.5-58.5T101-810q0-13 8.5-21.5T131-840q11 0 19 8.5t8 20.5q1 26 10 48.5t31 53.5q21 31 31 58.5t8 61.5q-1 12-10 20.5t-22 8.5q-11 0-18.5-8.5T180-589q0-26-9.5-49T140-692Zm170 0q-21-31-31-58.5t-9-59.5q0-13 9-21.5t22-8.5q11 0 19 8.5t8 20.5q1 26 10 48.5t31 53.5q21 31 30.5 58.5T407-589q-1 12-9.5 20.5T376-560q-11 0-18.5-8.5T350-589q0-26-9.5-49T310-692Zm170 0q-21-31-31-58.5t-9-59.5q0-13 9-21.5t22-8.5q11 0 19 8.5t8 20.5q1 26 10 48.5t31 53.5q21 31 30.5 58.5T577-589q-1 12-9.5 20.5T546-560q-11 0-18.5-8.5T520-589q0-26-9.5-49T480-692ZM200-160q-51 0-85.5-34.5T80-280v-169q0-13 9-22t22-9h549q0-32 16-58.5t45-36.5l161-54q12-4 23 1.5t15 17.5q4 12-1.5 23T901-572l-161 53q-11 4-15.5 15.5T720-480v200q0 50-34.5 85T600-160H200Zm0-60h400q26 0 43-17.5t17-42.5v-140H140v140q0 26 17 43t43 17Zm200-100Z"
		/>
	</svg>
);
