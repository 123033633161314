import { ReactNode } from 'react';

export const symbol_house: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M261-160q-24.75 0-42.375-17.625T201-220v-332L99-475q-10.043 8-21.522 6.5Q66-470 58-480q-8-10-6-22t12-20l380-290q8-6 17.211-9.5 9.21-3.5 18.789-3.5t18.789 3.5Q508-818 516-812l142 108v-46q0-20.833 14.618-35.417Q687.235-800 708.118-800 729-800 743.5-785.417 758-770.833 758-750v122l138 106q10 8 11.5 20t-6.5 22q-8 10-19.478 11.5Q870.043-467 860-475l-102-78v333q0 24.75-17.625 42.375T698-160H590q-24.75 0-42.375-17.625T530-220v-180H430v180q0 24.75-17.625 42.375T370-160H261Zm0-60h109v-180q0-24.75 17.625-42.375T430-460h100q24.75 0 42.375 17.625T590-400v180h108v-378L480-765 261-598v378Zm134-350h170q0-33-25.5-54.5T480-646q-34 0-59.5 21.342Q395-603.315 395-570Zm-25 350v-180q0-24.75 17.625-42.375T430-460h100q24.75 0 42.375 17.625T590-400v180-180q0-24.75-17.625-42.375T530-460H430q-24.75 0-42.375 17.625T370-400v180Z"
		/>
	</svg>
);
