import { ReactNode } from 'react';

export const symbol_adaptive_audio_mic: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M709-454Zm31 60q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T740-454h120q0-14-7-26t-20-19q-35-19-73-27.5t-80-9.5h-29q-12 0-20.5-7.857t-8.5-20Q622-576 630.5-585t20.5-10q8 0 14.5-.5t14.5-.5q48 0 93 11t87 33q28 14 44 40t16 58v30q0 12.75-8.625 21.375T890-394H740Zm-60-246q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35Zm0-60q25.5 0 42.75-17.25T740-760q0-25.5-17.25-42.75T680-820q-25.5 0-42.75 17.25T620-760q0 25.5 17.25 42.75T680-700Zm0-60ZM251-454ZM70-394q-12.75 0-21.375-8.625T40-424v-30q0-32 16-58t44-40q42-22 87-33t93-11q8 0 14.5.5t14.5.5q12 1 20.5 10t8.5 21.143q0 12.143-8.5 20Q321-536 309-536h-29q-42 1-80 9.5T127-499q-13 7-20 19t-7 26h120q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T220-394H70Zm210-246q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35Zm0-60q25.5 0 42.75-17.25T340-760q0-25.5-17.25-42.75T280-820q-25.5 0-42.75 17.25T220-760q0 25.5 17.25 42.75T280-700Zm0-60Zm199.911 477Q451-283 430.5-303.562 410-324.125 410-353v-122q0-28.875 20.589-49.438Q451.177-545 480.089-545 509-545 529.5-524.438 550-503.875 550-475v122q0 28.875-20.589 49.438Q508.823-283 479.911-283ZM480-80q-8 0-14-6t-6-14v-62q-67-7-114.5-54.5T291-332q-1-8 5-14.5t14-6.5q8.148 0 14.074 6T331-333q8 56 50.189 94T480-201q56.622 0 98.811-38Q621-277 629-333q1-8 6.926-14T650-353q8 0 14 6.5t5 14.5q-7 68-54.5 115.5T500-162v62q0 8-6 14t-14 6Z"
		/>
	</svg>
);
