import { ReactNode } from 'react';

export const symbol_manage_accounts: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M400-485q-66 0-108-42t-42-108q0-66 42-108t108-42q66 0 108 42t42 108q0 66-42 108t-108 42ZM80-224v-33.969Q80-293 97.5-322q17.5-29 50.5-44 58-26 119.344-42Q328.688-424 393-424q12.75 0 21.375 8.5T423-394.175q0 12.824-8.625 21.5Q405.75-364 393-364q-58 0-113.5 14T172-310q-14 8-23 21.655-9 13.655-9 30.345v34h254q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T394-164H140q-24.75 0-42.375-17.625T80-224Zm584 81-7-43q-17-5-34.5-14.5T593-222l-38 8q-8 2-15.031-1.095Q532.938-218.19 529-225l-5.867-9.692Q518-243 519.5-251t7.5-14l33-31q-2-9-2-25t2-25l-33-31q-6-5-7.5-13.5T523-406l6-12q4-7 10.5-9.5t14.5-.5l39 8q12-12 29.5-21.5T657-456l7-43q2.053-10.387 9.237-16.694Q680.421-522 690-522h8q9.579 0 16.763 6.469Q721.947-509.062 724-499l7 43q17 5 34.5 14.5T795-420l38-8q8-2 15.091.955Q855.182-424.091 859-417l5.867 9.692Q870-399 868.5-391t-7.5 14l-33 31q2 9 2 25t-2 25l33 31q6 5 7.5 13.5T865-236l-6 12q-4 7-10.5 9.5t-14.5.5l-39-8q-12 12-29.5 21.5T731-186l-7 43q-2.053 10.387-9.237 16.694Q707.579-120 698-120h-8q-9.579 0-16.763-6.469Q666.053-132.938 664-143Zm30-98q36 0 58-22t22-58q0-36-22-58t-58-22q-36 0-58 22t-22 58q0 36 22 58t58 22ZM400-545q39 0 64.5-25.5T490-635q0-39-25.5-64.5T400-725q-39 0-64.5 25.5T310-635q0 39 25.5 64.5T400-545Zm0-90Zm9 411Z"
		/>
	</svg>
);
