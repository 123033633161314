import { ReactNode } from 'react';

export const symbol_taunt: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M204-418q-21 11-44 5.5T126-439q-13-25-4.5-52t35.5-36l313-106q11-4 21.432 1.17 10.432 5.171 17.386 18.694Q515-602 510.82-590.077 506.64-578.154 496-572L204-418Zm106 298q-24.75 0-42.375-17.625T250-180v-194l317.872-167.908Q582-550 587.5-565.5 593-581 585-595l193-96q11-5 22.948-.054 11.948 4.945 19.656 20.163Q826-661 823.5-651q-2.5 10-11.5 17L550-438v258q0 24.75-17.625 42.375T490-120H310Zm-10.101-460Q246-580 208-618.101t-38-92Q170-764 208.101-802t92-38Q354-840 392-801.899t38 92Q430-656 391.899-618t-92 38Z"
		/>
	</svg>
);
