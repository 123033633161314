import { ReactNode } from 'react';

export const symbol_3d_rotation: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M634.714-352H524q-6 0-10.5-4.5T509-367v-227q0-6 4.5-10.5T524-609h110.714q18.822 0 31.554 12.65T679-565v169q0 18.7-12.732 31.35Q653.536-352 634.714-352ZM408-352H306q-10.4 0-17.2-7.116-6.8-7.117-6.8-18 0-10.884 7.083-17.384Q296.167-401 307-401h95v-60h-58q-7.6 0-13.3-6-5.7-6-5.7-14t6-13.5q6-5.5 14-5.5h57v-59h-95q-10.833 0-17.917-7.116-7.083-7.117-7.083-18Q282-595 289.083-602q7.084-7 17.917-7h100.935Q427-609 439.5-596.35T452-565v169q0 18.7-12.65 31.35Q426.7-352 408-352Zm150-49h71v-158h-71v158Zm291.825-49Q837-450 828.5-458.625T820-480q0-128-83.5-224T525-817l40 40q8 7.822 8 19.911Q573-745 565.143-737q-7.857 8-20 8T525-737L410-852q-6-6-3.5-14.5T417-877q16-2 31.667-2.5 15.666-.5 31.333-.5 82.638 0 155.319 31.5Q708-817 762.5-762.5t86 127.181Q880-562.638 880-480q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-480q0 127 84 223.5T436-143l-41-41q-8-7.822-8-19.911Q387-216 394.857-224q7.857-8 20-8T435-224l115 115q6 6 3.5 14.5T543-84q-16 2-31.667 3-15.666 1-31.333 1Z"
		/>
	</svg>
);
