import { ReactNode } from 'react';

export const symbol_bug_report: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-120q-65.369 0-121.185-31Q303-182 276-240h-86q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T190-300h62q-7-26-7-52.667V-406h-56q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T189-466h56q0-29 .5-57.5T254-580h-64q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T190-640h90q14-28 37-49t51-35l-57-56q-8-8-8-19.786 0-11.785 8.421-20.357Q319.14-828 331.07-828t19.93 8l74 74q27.674-10 56.337-10Q510-756 538-746l74-74q8-8 19.786-8 11.785 0 20.357 8.421Q660-811.86 660-799.93T652-780l-56 56q28 14 49.402 35.667Q666.804-666.667 683-640h88q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T771-580h-65q9 28 8.5 56.667-.5 28.666-.5 57.333h57q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T771-406h-57q0 27 .5 53.5T708-300h63q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T771-240h-86q-26 59-82.446 89.5T480-120Zm.159-60Q552-180 603-230.5T654-353v-167q0-72-51.159-122.5-51.16-50.5-123-50.5Q408-693 357-642.5T306-520v167q0 72 51.159 122.5 51.16 50.5 123 50.5ZM430-320h100q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T530-380H430q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T430-320Zm0-173h100q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T530-553H430q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T430-493Zm50 57h.5-.5.5-.5.5-.5.5-.5Z"
		/>
	</svg>
);
