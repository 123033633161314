import { ReactNode } from 'react';

export const symbol_autopause: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M400-360q-13 0-21.5-8.5T370-390v-180q0-13 8.5-21.5T400-600q13 0 21.5 8.5T430-570v180q0 13-8.5 21.5T400-360Zm160 0q-13 0-21.5-8.5T530-390v-180q0-13 8.5-21.5T560-600q13 0 21.5 8.5T590-570v180q0 13-8.5 21.5T560-360ZM487-40q-121 0-221-56.5T100-257v106q0 13-8.5 21.5T70-121q-13 0-21.5-8.5T40-151v-179q0-13 8.5-21.5T70-360h178q13 0 21.5 8.5T278-330q0 13-8.5 21.5T248-300H144q57 95 147 147.5T487-100q111 0 202.5-60T826-320q7-15 17.5-24t25.5-6q14 3 18.5 16.5T885-306q-51 121-158.5 193.5T487-40ZM72-520q-14 0-21-9.5T46-553q8-51 26.5-94t48.5-86q8-12 20.5-14.5T164-740q11 11 10 24t-9 26q-22 33-36 66t-22 72q-3 14-12 23t-23 9Zm196-273q-12 8-25 8t-23-11q-9-9-7-21.5t13-20.5q43-31 86.5-49t95.5-27q14-2 24 5t10 21q0 14-9.5 23T409-853q-40 8-73.5 22.5T268-793Zm424-1q-34-23-67-37t-73-22q-14-2-23-11.5t-9-23.5q0-14 9.5-21t23.5-5q51 8 94.5 26t85.5 49q11 8 14 20.5t-7 22.5q-10 11-23 10.5t-25-8.5Zm196 274q-14 0-23-9.5T853-553q-8-40-23.5-73.5T791-694q-8-12-8.5-24.5T792-742q10-10 22-8t20 13q32 43 51.5 86.5T914-554q2 14-5 24t-21 10Z"
		/>
	</svg>
);
