import { ReactText } from 'react';
import styled from 'styled-components';

const SingleLine = styled.span`
	display: inline-block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
`;

interface MultiLineProps {
	maxLines?: number;
	children?: ReactText;
	className?: string;
}

const MultiLine = styled.div<MultiLineProps>`
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: ${({ maxLines }) => maxLines};
	line-clamp: ${({ maxLines }) => maxLines};
	-webkit-box-orient: vertical;
	width: 100%;
`;

export default function TextOverflow({
	maxLines,
	children,
	className,
}: MultiLineProps): JSX.Element {
	if (maxLines && maxLines > 1) {
		return (
			<MultiLine className={className} maxLines={maxLines}>
				{children}
			</MultiLine>
		);
	}

	return <SingleLine className={className}>{children}</SingleLine>;
}
