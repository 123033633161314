import { ReactNode } from 'react';

export const symbol_camera_rear: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M200-820q0-24 18-42t42-18h440q24 0 42 18t18 42v518q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T700-302v-518H260v518q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T200-302v-518Zm280.177 240Q505-580 522.5-597.677t17.5-42.5Q540-665 522.323-682.5t-42.5-17.5Q455-700 437.5-682.323t-17.5 42.5Q420-615 437.677-597.5t42.5 17.5ZM415-160H230q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T230-220h187l-38-38q-9-9-9-21t9-21q9-9 21.5-9t21.5 9l89 88q9 9 9 21t-9 21l-89 89q-9 9-21 8.5T380-82q-9-9-9-21.5t9-21.5l35-35Zm175 0q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T590-220h140q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T730-160H590ZM480-640Z"
		/>
	</svg>
);
