import { ReactNode } from 'react';

export const symbol_desktop_access_disabled: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m140-840 60 60h-60v480h455L49-845q-9-9-8.5-21.5T50-888q9-9 21-9t21 9l748 747q9 9 9 21t-9 21q-9 9-21.5 9T797-99L655-240H540v100h56q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q626-97 617.375-88.5T596-80H364q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T364-140h56v-100H140q-24 0-42-18t-18-42v-480q0-24 18.5-42t41.5-18Zm741 60v474q0 15-9.318 22.5t-20.5 7.5Q840-276 830.5-283.683 821-291.366 821-306v-474H326q-15 0-22.5-9.318t-7.5-20.5q0-11.182 7.5-20.682Q311-840 326-840h495q23 0 41.5 18.5T881-780ZM553-512Zm-185-15Z"
		/>
	</svg>
);
