import { ReactNode } from 'react';

export const symbol_military_tech: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m480-161-73 54q-9 7-17.5.5T384-124l28-90-73-54q-9-7-5.25-17T348-295h90l25-97-140-82q-20.368-11.702-31.684-30.851Q280-524 280-547v-273q0-24.75 17.625-42.375T340-880h280q24.75 0 42.375 17.625T680-820v273q0 23-11.316 42.149T637-474l-141 82 26 97h89q10.5 0 14.25 10T620-268l-73 54 28 90q3 11-5.5 17t-17.5-1l-72-53ZM340-820v273q0 7 4.5 13t13.5 11l96 53v-350H340Zm280 0H514v350l88-53q9-5 13.5-11t4.5-13v-273ZM484-637Zm-30-8Zm60 0Z"
		/>
	</svg>
);
