import { t } from '@transifex/native';
import { Fragment, memo, useState } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import styled, { css } from 'styled-components';

import * as palette from 'pkg/config/palette';
import { small } from 'pkg/config/breakpoints';

import {
	addMatchEventGuids,
	addLeadingMatchEventGuid,
	removeMatchEventGuids,
	removeMatchEvent,
	updateMatchEventMinute,
	updateMatchEventComment,
	openEventEditModal,
} from 'pkg/actions/match_events_deprecated';
import { decreaseScore } from 'pkg/actions/matches_deprecated';

import * as selectors from 'pkg/selectors';
import { getEventTypeTranslation } from 'pkg/api/models/match_event';

import * as Card from 'components/Card';
import Icon from 'components/icon';
import MaterialSymbol from 'components/material-symbols';

import FieldStyles from 'components/form/FieldStyles';
import * as Input from 'components/form/inputs';
import * as iconStyles from 'components/icon/styles.css';

import Button from 'design/button';

const Wrapper = styled.div`
	padding: 16px;

	&:last-of-type {
		padding-bottom: 32px;
	}

	${(props) =>
		props.edited &&
		css`
			border-left: 2px solid ${palette.blue[500]};
			padding-left: 14px;
		`}
`;

const Labels = styled.div`
	display: grid;
	grid-template-columns: 45px 1fr auto;
	grid-column-gap: 1rem;
	margin-bottom: 5px;
	font-size: 12px;

	${(props) =>
		props.isOpponent &&
		css`
			grid-template-columns: 1fr auto;
		`}

	${(props) =>
		props.hasLeadingEvent &&
		css`
			grid-template-columns: 45px 200px 1fr auto;
		`}

	@media ${small} {
		display: none;
	}
`;

const Actions = styled.div`
	display: grid;
	grid-template-columns: 45px 1fr auto;
	grid-column-gap: 1rem;

	${(props) =>
		props.isOpponent &&
		css`
			grid-template-columns: 1fr auto;
		`}

	${(props) =>
		props.hasLeadingEvent &&
		css`
			grid-template-columns: 45px 200px 1fr auto;
		`}


	@media ${small} {
		grid-template-columns: 1fr 1fr;
		grid-template-areas:
			'minute buttons'
			'select-first select-first';
		grid-row-gap: 10px;
		grid-column-gap: 0.7rem;

		${(props) =>
			props.hasLeadingEvent &&
			css`
				grid-template-areas:
					'minute buttons'
					'select-first select-second';
			`}

		${(props) =>
			props.isOpponent &&
			css`
				grid-template-areas: 'minute buttons';
				grid-template-columns: 1fr auto;
			`}
	}
`;

const MinuteInput = styled.input`
	${FieldStyles};

	width: 45px;
	height: 35px;
	text-align: center;
	font-size: var(--font-size-sm);

	::-webkit-outer-spin-button,
	::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	${(props) =>
		props.withExtraMinute &&
		css`
			padding: 0;
		`}

	@media ${small} {
		grid-area: minute;
	}
`;

const EventButton = styled(Button)`
	display: flex;
	align-items: center;
	justify-content: ${(props) =>
		props.multipleChildren ? 'space-between' : 'center'};
	width: 200px;
	height: 35px;
	color: ${palette.gray[800]};

	.${iconStyles.icon} {
		margin-right: 5px;
	}

	@media ${small} {
		width: 100%;
		grid-area: select-first;
	}
`;

const LeadingEventButton = styled(EventButton)`
	@media ${small} {
		grid-area: select-second;
	}
`;

const Position = styled.span`
	padding: 3px 7px;
	color: ${palette.gray[500]};
	border: 1px solid ${palette.gray[500]};
	border-radius: 55px;
	font-size: 0.7rem;
`;

const Comments = styled.div`
	display: grid;
	grid-column-gap: 1rem;
	grid-template-columns: 200px 200px;
	margin: 10px 0 0 calc(45px + 1rem);

	@media ${small} {
		display: block;
		margin: 10px 0 0 0;

		${(props) =>
			props.hasLeadingEvent &&
			css`
				display: grid;
				grid-column-gap: 0.7rem;
				grid-template-columns: 1fr 1fr;
			`}
	}
`;

const Buttons = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	${Button} {
		justify-content: center;
		align-items: center;
		display: inline-flex;
		padding: 4px;
	}

	@media ${small} {
		justify-content: flex-end;
		grid-area: buttons;
	}
`;

const CommentButton = styled(Button)`
	height: 37px;
	width: 37px;
`;

const DeleteButton = styled(Button)`
	width: 37px;
	height: 37px;
	font-size: var(--font-size-lg);
	margin-left: 1rem;
`;

const LeadingEventWrapper = styled.div`
	display: flex;
	align-items: center;
	grid-column-gap: var(--spacing-3);
`;

const Item = memo(({ event, matchId, generateEvent, handleSave }) => {
	const dispatch = useDispatch();

	const users = useSelector((state) => state.users.entities);
	const positions = useSelector((state) =>
		selectors.positions.getPositions(state)
	);
	const leadingEvent = useSelector((state) =>
		selectors.matchEvents.find(
			state,
			event.leadingGuid || event.leadingMatchEventId
		)
	);
	const modes = useSelector((state) =>
		selectors.matchEvents.getMatchEventModes(state)
	);

	const mode = modes.get(event.id);

	const [isCommentActive, setIsCommentActive] = useState(
		event.comment || leadingEvent?.comment ? true : false
	);

	const IS_TYPE_GOAL = event.type === 'goal';
	const IS_TYPE_SUB_IN = event.type === 'substitution_in';
	const subInUserId = mode.get('newEntity')
		? event.userId
		: leadingEvent.userId;
	const subOutUserId = mode.get('newEntity')
		? event.substitutionUserId
		: event.userId;

	const hasLeadingEvent = IS_TYPE_GOAL || IS_TYPE_SUB_IN;

	const getUserName = (userId) => {
		if (!userId) return t(`Select player`);

		const user = users.get(userId);

		if (!user) {
			return '';
		}

		return `${user.firstName[0]}. ${user.lastName}`;
	};

	const formatMinute = (minute, extraMinute) => {
		return !extraMinute ? minute || 0 : `${minute}+${extraMinute}`;
	};

	const handleRemoveEvent = (e) => {
		e.preventDefault();

		if (event.type === 'goal') {
			dispatch(decreaseScore(matchId, event.isOpponent));
		}

		if (handleSave && modes.filter((mode) => mode.get('newEntity'))) {
			handleSave(e);
		}

		if (!mode.get('newEntity')) {
			dispatch(removeMatchEvent(event.id, true));
		}

		if (leadingEvent.id) {
			handleRemoveLeadingEvent();
		}

		dispatch(removeMatchEventGuids([event.id]));
	};

	const handleRemoveAssist = () => {
		dispatch(removeMatchEvent(leadingEvent.id));
	};

	const handleRemoveLeadingEvent = () => {
		batch(() => {
			dispatch(removeMatchEvent(leadingEvent.id));
			dispatch(removeMatchEventGuids([leadingEvent.id]));
		});
	};

	const handleMinuteOnFocus = ({ target }) => {
		if (target.value === '0') {
			target.value = '';
		}
	};

	const handleMinuteOnBlur = (e) => {
		if (!e.target.value) {
			e.target.value = '0';
		}

		const [minute, extraMinute] = e.target.value.split('+');

		if (
			formatMinute(minute, extraMinute) ===
			formatMinute(event.minute, event.extraMinute).toString()
		) {
			return;
		}

		if (Number.isNaN(minute) || (extraMinute && Number.isNaN(extraMinute))) {
			e.target.value = formatMinute(event.minute, event.extraMinute);
			return;
		}

		dispatch(
			updateMatchEventMinute(
				event.id,
				Number.parseInt(minute, 10),
				Number.parseInt(extraMinute, 10)
			)
		);

		if (IS_TYPE_SUB_IN) {
			dispatch(
				updateMatchEventMinute(
					leadingEvent.id,
					Number.parseInt(minute, 10),
					Number.parseInt(extraMinute, 10)
				)
			);
		}
	};

	const handleOpenSelect = (e) => {
		e.preventDefault();

		dispatch(openEventEditModal(event.type, event.id));
	};

	const handleOpenAssist = (e) => {
		e.preventDefault();

		dispatch(openEventEditModal(leadingEvent.type, leadingEvent.id));
	};

	const handleAddAssist = (e) => {
		e.preventDefault();

		const newAssistEvent = generateEvent('assist');

		batch(() => {
			dispatch(addMatchEventGuids('assist', [newAssistEvent]));
			dispatch(addLeadingMatchEventGuid(event.id, newAssistEvent.guid));

			dispatch(openEventEditModal('assist', newAssistEvent.guid));
		});
	};

	const handleToggleComment = (e) => {
		e.preventDefault();

		setIsCommentActive((prev) => !prev);
	};

	const handleCommentOnBlur = ({ target }) => {
		if (!target.value) return;

		const commentEvent =
			target.name === 'mainEventComment' ? event : leadingEvent;
		updateEventComment(commentEvent, target.value);
	};

	const updateEventComment = (event, comment) =>
		comment !== event.comment &&
		dispatch(updateMatchEventComment(event.id, comment));

	const getLeadingEventLabel = () => {
		if (IS_TYPE_SUB_IN) {
			return <span>{t(`Player in`)}</span>;
		}

		if (IS_TYPE_GOAL) {
			return <span>{t(`Assist`)}</span>;
		}

		return '';
	};

	if (event.isOpponent) {
		return (
			<Wrapper edited={mode.get('handled')}>
				<Labels isOpponent>
					<span>{t(`Minute`)}</span>
				</Labels>
				<Actions isOpponent>
					<MinuteInput
						type="text"
						name="minute"
						onFocus={handleMinuteOnFocus}
						onBlur={handleMinuteOnBlur}
						pattern="^[0-9][0-9]{0,}(?:\+\d+){0,1}$"
						defaultValue={formatMinute(event.minute, event.extraMinute)}
						withExtraMinute={event.extraMinute}
					/>
					<Buttons>
						<DeleteButton icon="remove" caution onClick={handleRemoveEvent} />
					</Buttons>
				</Actions>
			</Wrapper>
		);
	}

	return (
		<Wrapper edited={mode.get('handled')}>
			<Labels hasLeadingEvent={hasLeadingEvent}>
				<span>{t(`Minute`)}</span>
				<span>
					{IS_TYPE_SUB_IN
						? t(`Player out`)
						: getEventTypeTranslation(event.type)}
				</span>
				{getLeadingEventLabel()}
			</Labels>

			<Actions hasLeadingEvent={hasLeadingEvent}>
				<MinuteInput
					type="text"
					name="minute"
					onFocus={handleMinuteOnFocus}
					onBlur={handleMinuteOnBlur}
					pattern="^[0-9][0-9]{0,}(?:\+\d+){0,1}$"
					defaultValue={formatMinute(event.minute, event.extraMinute)}
					withExtraMinute={event.extraMinute}
				/>

				{!IS_TYPE_SUB_IN ? (
					<EventButton
						multipleChildren={event.positionId}
						onClick={handleOpenSelect}>
						{getUserName(event.userId)}
						{event.positionId && (
							<Position>
								{
									positions.find((position) => position.id === event.positionId)
										.slug
								}
							</Position>
						)}
					</EventButton>
				) : null}

				{IS_TYPE_SUB_IN ? (
					<EventButton
						multipleChildren={mode.newEntity && event.positionId}
						onClick={handleOpenSelect}>
						{getUserName(subInUserId)}
						{mode.newEntity && event.positionId && (
							<Position>
								{
									positions.find((position) => position.id === event.positionId)
										.slug
								}
							</Position>
						)}
					</EventButton>
				) : null}

				{IS_TYPE_SUB_IN ? (
					<LeadingEventButton
						multipleChildren={!mode.newEntity && event.positionId}
						onClick={handleOpenSelect}>
						{getUserName(subOutUserId)}
						{!mode.newEntity && event.positionId && (
							<Position>
								{
									positions.find((position) => position.id === event.positionId)
										.slug
								}
							</Position>
						)}
					</LeadingEventButton>
				) : null}

				{leadingEvent.id && IS_TYPE_GOAL ? (
					<LeadingEventWrapper>
						<LeadingEventButton onClick={handleOpenAssist}>
							{getUserName(leadingEvent.userId)}
						</LeadingEventButton>
						<Icon name="close-circle" size={1.6} onClick={handleRemoveAssist} />
					</LeadingEventWrapper>
				) : null}

				{!leadingEvent.id && IS_TYPE_GOAL ? (
					<LeadingEventButton onClick={handleAddAssist}>
						<Icon name="add" />
						{t(`Add assist`)}
					</LeadingEventButton>
				) : null}

				<Buttons>
					<CommentButton icon="sticky_note_2" onClick={handleToggleComment} />
					<DeleteButton icon="remove" caution onClick={handleRemoveEvent} />
				</Buttons>
			</Actions>

			{isCommentActive && (
				<Comments hasLeadingEvent={hasLeadingEvent}>
					<Input.Field
						name="mainEventComment"
						defaultValue={event.comment}
						onBlur={handleCommentOnBlur}>
						<Input.Prefix>
							<MaterialSymbol variant="sticky_note_2" scale={1.5} />
						</Input.Prefix>
					</Input.Field>
					{leadingEvent.id && (
						<Input.Field
							defaultValue={leadingEvent.comment}
							onBlur={handleCommentOnBlur}
							disabled={!leadingEvent.id}>
							<Input.Prefix>
								<MaterialSymbol variant="sticky_note_2" scale={1.5} />
							</Input.Prefix>
						</Input.Field>
					)}
				</Comments>
			)}
		</Wrapper>
	);
});

const TypeBody = ({
	items,
	matchId,
	persisted = true,
	generateNewEvent,
	handleSave,
}) => {
	if (items.size === 0) return null;

	return (
		<Fragment>
			{!persisted && (
				<Card.Header $slim>{t(`Unsaved match events`)}</Card.Header>
			)}
			{items.map((event) => (
				<Item
					key={event.id}
					event={event}
					matchId={matchId}
					generateEvent={generateNewEvent}
					handleSave={handleSave}
				/>
			))}
		</Fragment>
	);
};

export default TypeBody;
