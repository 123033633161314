import { ReactNode } from 'react';

export const symbol_dataset_linked: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-160q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v270q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T780-550v-270H180v600h121q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T301-160H180Zm0-120v60-600 540Zm110-160v80q0 12.75 8.625 21.375T320-330q11-37 36-74t57-66h-93q-12.75 0-21.375 8.625T290-440Zm0-240v80q0 12.75 8.625 21.375T320-570h80q12.75 0 21.375-8.625T430-600v-80q0-12.75-8.625-21.375T400-710h-80q-12.75 0-21.375 8.625T290-680Zm270 560q-66 0-113-47t-47-113q0-66 47-113t113-47h40q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T600-380h-40q-42 0-71 29t-29 71q0 42 29 71t71.041 29H611q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T611-120h-51Zm0-450h80q12.75 0 21.375-8.625T670-600v-80q0-12.75-8.625-21.375T640-710h-80q-12.75 0-21.375 8.625T530-680v80q0 12.75 8.625 21.375T560-570Zm30 320q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T590-310h180q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T770-250H590Zm210 130h-50q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T750-180h50.411Q842-180 871-209t29-71q0-42-29-71t-71-29h-40q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T760-440h40q66 0 113 46.5T960-280q0 66-47 113t-113 47Z"
		/>
	</svg>
);
