import { t } from '@transifex/native';
import {
	createRef,
	Dispatch,
	memo,
	SetStateAction,
	SyntheticEvent,
	useEffect,
	useMemo,
} from 'react';

import * as styles from 'pkg/config/styles';

import DateTime, {
	Calendar as DateTimeCalendar,
	CalendarDayType,
	CalendarMonthType,
	Granularity,
} from 'pkg/datetime';
import useMixedState from 'pkg/hooks/useMixedState';
import { cssClasses, cssVarList } from 'pkg/css/utils';
import rgba from 'pkg/rgba';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';

import Icon from 'components/icon';

import * as Inputs from 'components/form/inputs';
import Row from 'components/layout/row';
import Column from 'components/layout/column';

import * as css from './styles.css';

const dayHighlight = {
	disabled: 'transparent',
	active: rgba(styles.palette.main.actionableLinkColor),
	idle: 'transparent',
	'outside-range': 'transparent',
	'inside-selected-range': styles.palette.blue[400],
};

const dayBackgroundColor = {
	disabled: styles.palette.white,
	active: styles.palette.white,
	idle: styles.palette.white,
	'outside-range': styles.palette.white,
	'inside-selected-range': styles.palette.blue[400],
};

const dayForeground = {
	disabled: 'rgb( 165, 165, 165 )',
	active: styles.palette.white,
	idle: 'rgb( 30, 30, 30 )',
	'outside-range': 'rgb( 215, 215, 215 )',
	'inside-selected-range': styles.palette.white,
};

const dayCursor = {
	disabled: 'not-allowed',
	active: 'pointer',
	idle: 'pointer',
	'outside-range': 'default',
	'inside-selected-range': 'default',
};

const dayWeight = {
	disabled: styles.font.weight.normal,
	active: styles.font.weight.bold,
	idle: styles.font.weight.normal,
	'outside-range': styles.font.weight.normal,
	'inside-selected-range': styles.font.weight.semibold,
};

enum DAY_STYLES_CLASSNAMES {
	disabled = 'disabled',
	active = 'active',
	idle = 'idle',
	outsideRange = 'outside-range',
	insideSelectedRange = 'inside-selected-range',
}

enum RANGE_OPTION {
	from = 'from',
	to = 'to',
}

interface State {
	fromDateRefVal: string;
	toDateRefVal: string;
	yearInputRefVal: string;
	renderedDate: Date;
	currentRangeOption: RANGE_OPTION;
	prevDate: Date;
}

interface CalendarProps {
	range?: boolean;
	selectable?: boolean;

	disableDatesBefore?: Date;
	disableDatesAfter?: Date;
	selectedDates?: Date[];
	highlightedDates?: Date[][];

	setIsNavigating?: Dispatch<SetStateAction<boolean>>;
	onDateChange: (dates: Date[] | Date) => void;
	onRenderedDateChange?: (date: Date) => void;
}

const Calendar = memo(
	({
		selectedDates = [],
		highlightedDates = [],
		range = false,
		disableDatesAfter,
		disableDatesBefore,
		onDateChange,
		setIsNavigating,
		selectable = true,
		onRenderedDateChange,
	}: CalendarProps) => {
		const currentDate = new Date();
		const currentDateTime = new DateTime(currentDate);

		let prevSelectedYear: string = '';
		let _sortedDates: Date[] = [];
		let shouldGenerateCalendarArray = true;
		let lastGeneratedCalendarArray: CalendarMonthType = [];

		const [state, setState] = useMixedState<State>({
			fromDateRefVal: new DateTime(selectedDates[0]).toLocaleDateString({
				day: 'numeric',
				month: 'short',
				year: 'numeric',
			}),
			toDateRefVal: new DateTime(selectedDates[1]).toLocaleDateString({
				day: 'numeric',
				month: 'short',
				year: 'numeric',
			}),
			yearInputRefVal: new DateTime(selectedDates[0]).toLocaleDateString({
				year: 'numeric',
			}),
			renderedDate: selectedDates[0] || new Date(),
			currentRangeOption: RANGE_OPTION.from,
			prevDate: new Date(),
		});

		const fromDateRef = createRef<HTMLInputElement>();
		const toDateRef = createRef<HTMLInputElement>();
		const yearInputRef = createRef<HTMLInputElement>();

		useEffect(() => {
			if (range) {
				setState({
					fromDateRefVal: new DateTime(selectedDates[0]).toLocaleDateString({
						day: 'numeric',
						month: 'short',
						year: 'numeric',
					}),
					toDateRefVal: new DateTime(selectedDates[1]).toLocaleDateString({
						day: 'numeric',
						month: 'short',
						year: 'numeric',
					}),
				});
			}

			_sortedDates = [];
		}, [selectedDates[0], selectedDates[1]]);

		useEffect(() => {
			if (fromDateRef.current !== null) {
				fromDateRef.current.value = state.fromDateRefVal;
			}
		}, [state.fromDateRefVal]);

		useEffect(() => {
			if (toDateRef.current !== null) {
				toDateRef.current.value = state.toDateRefVal;
			}
		}, [state.toDateRefVal]);

		useEffect(() => {
			if (!range) {
				setState({
					yearInputRefVal: new DateTime(state.renderedDate).toLocaleDateString({
						year: 'numeric',
					}),
				});
			}

			shouldGenerateCalendarArray = true;
		}, [state.renderedDate]);

		useEffect(() => {
			if (yearInputRef.current !== null && !range) {
				yearInputRef.current.value = state.yearInputRefVal;
			}
		}, [state.yearInputRefVal, range]);

		useComponentDidMount(() => {
			if (onRenderedDateChange) {
				onRenderedDateChange(state.renderedDate);
			}
		});

		const isSelectable = (dateTime: DateTime) => {
			if (!selectable) return false;

			const hasDisableBeforeRange = disableDatesBefore instanceof Date;
			const hasDisableAfterRange = disableDatesAfter instanceof Date;

			disableDatesBefore?.setHours(0, 0, 0, 1);
			disableDatesAfter?.setHours(23, 59, 59, 999);

			if (hasDisableBeforeRange && hasDisableAfterRange) {
				// @NOTE Disable dates outside specified range.
				return dateTime.isBetween(+disableDatesBefore, +disableDatesAfter);
			} else if (hasDisableBeforeRange && !hasDisableAfterRange) {
				// @NOTE To disable dates *before* current date, we need to make sure current date is *after* reference date.
				return dateTime.isAfter(+disableDatesBefore);
			} else if (!hasDisableBeforeRange && hasDisableAfterRange) {
				// @NOTE To disable dates *after* current date, we need to make sure current date is *before* reference date.
				return dateTime.isBefore(+disableDatesAfter);
			}

			return true;
		};

		const emitDateChange = (selectedDate: Date, changeRangeOption = true) => {
			let dates = [selectedDate];

			if (range) {
				const { currentRangeOption } = state;

				dates = [...getSelectedDates()];

				if (currentRangeOption === RANGE_OPTION.from) {
					dates = [selectedDate, selectedDates[1]];
				} else {
					dates = [selectedDates[0], selectedDate];
				}

				dates.sort((a, b) => +a - +b);

				if (changeRangeOption) {
					setState({
						currentRangeOption:
							currentRangeOption === RANGE_OPTION.from
								? RANGE_OPTION.to
								: RANGE_OPTION.from,
					});
				}
			}

			onDateChange(dates);
		};

		const onNavigatePrev = () => {
			const { renderedDate } = state;
			const prevRenderedDate = new DateTime(renderedDate)
				.setDay(1)
				.prev(Granularity.month)
				.toDate();

			setState({
				renderedDate: prevRenderedDate,
			});

			if (onRenderedDateChange) {
				onRenderedDateChange(prevRenderedDate);
			}
		};

		const onNavigateNext = () => {
			const { renderedDate } = state;
			const nextRenderedDate = new DateTime(renderedDate)
				.setDay(1)
				.next(Granularity.month)
				.toDate();

			setState({
				renderedDate: nextRenderedDate,
			});

			if (onRenderedDateChange) {
				onRenderedDateChange(nextRenderedDate);
			}
		};

		const onNavigateYear = () => {
			const { renderedDate } = state;
			const val = yearInputRef.current.value;
			const nextRenderedDate = new DateTime(renderedDate)
				.setYear(Number.parseInt(val, 10))
				.toDate() as unknown;

			if (nextRenderedDate != 'Invalid Date' && val !== '') {
				setState({
					renderedDate: nextRenderedDate as Date,
				});
			} else {
				yearInputRef.current.value = prevSelectedYear;
			}
		};

		const savePrevYear = () => {
			prevSelectedYear = yearInputRef.current.value;
		};

		const onNavigateToday = () => {
			const nextRenderedDate = DateTime.now().toDate();

			setState({
				renderedDate: nextRenderedDate,
			});
		};

		const renderedCalendarIsCurrent = useMemo(
			() =>
				state.renderedDate.getMonth() === currentDate.getMonth() &&
				state.renderedDate.getFullYear() === currentDate.getFullYear(),
			[currentDate, state.renderedDate]
		);

		const renderNavigationTodayButton = useMemo(() => {
			const ts = currentDateTime.getUnixTimestamp();
			const disableAfterDate = disableDatesAfter
				? Math.ceil(disableDatesAfter.setHours(23, 59, 59, 999) / 1000)
				: ts;

			const disableBeforeDate = disableDatesBefore
				? Math.ceil(disableDatesBefore.setHours(0, 0, 0, 1) / 1000)
				: ts;

			return (
				!renderedCalendarIsCurrent &&
				ts <= disableAfterDate &&
				ts >= disableBeforeDate
			);
		}, [
			currentDateTime,
			disableDatesAfter,
			disableDatesBefore,
			renderedCalendarIsCurrent,
		]);

		const calendar = (): CalendarMonthType => {
			if (!shouldGenerateCalendarArray) {
				return lastGeneratedCalendarArray;
			}

			const { renderedDate } = state;
			const year = renderedDate.getFullYear();
			const month = renderedDate.getMonth() + 1;

			lastGeneratedCalendarArray = DateTimeCalendar.generate(year, month);
			shouldGenerateCalendarArray = false;

			return lastGeneratedCalendarArray;
		};

		const onInputFocus = (event: SyntheticEvent<HTMLInputElement>) => {
			const range = event.currentTarget.dataset.rangeoption as RANGE_OPTION;
			const d = new Date(event.currentTarget.value);

			setState({ renderedDate: d, prevDate: d, currentRangeOption: range });
		};

		const onInputBlur = (event: SyntheticEvent<HTMLInputElement>) => {
			const d = new DateTime(new Date(event.currentTarget.value));
			const pd = new DateTime(new Date(state.prevDate));
			if (d.startOfDay !== pd.startOfDay) {
				// date can be 'Invalid date'
				const date = d.dateTime as unknown;
				if (date != 'Invalid Date') {
					emitDateChange(d.dateTime, false);
					setState({ renderedDate: d.dateTime });
				} else {
					emitDateChange(pd.dateTime, false);
					setState({ renderedDate: pd.dateTime });
					event.currentTarget.value = pd.toLocaleDateString({
						day: 'numeric',
						month: 'short',
						year: 'numeric',
					});
				}
			}
		};

		const onMouseDown = () => {
			if (setIsNavigating) {
				setIsNavigating(true);
			}
		};

		const calendarNavigation = () => {
			const calendarDate = new DateTime(state.renderedDate);

			return (
				<Column>
					{range && (
						<Row>
							<Inputs.Field
								data-rangeoption={RANGE_OPTION.from}
								defaultValue={state.fromDateRefVal}
								onFocus={onInputFocus}
								onBlur={onInputBlur}
								ref={fromDateRef}
								active={state.currentRangeOption === RANGE_OPTION.from}
							/>
							<Inputs.Field
								defaultValue={state.toDateRefVal}
								data-rangeoption={RANGE_OPTION.to}
								onFocus={onInputFocus}
								onBlur={onInputBlur}
								ref={toDateRef}
								active={state.currentRangeOption === RANGE_OPTION.to}
							/>
						</Row>
					)}
					<Row columns="1fr auto" align="center">
						<div className={css['month-controls']}>
							<span
								className={css['navigation-button']}
								onMouseDown={onMouseDown}
								onClick={onNavigatePrev}>
								<Icon name="chevron" actualSize size={1.4} rotate="180deg" />
							</span>
							<span
								className={css['navigation-button']}
								onMouseDown={onMouseDown}
								onClick={onNavigateNext}>
								<Icon name="chevron" actualSize size={1.4} />
							</span>

							{range ? (
								<time className={css['navigation-location']}>
									{calendarDate.toLocaleDateString({
										month: 'short',
										year: 'numeric',
									})}
								</time>
							) : (
								<time className={css['navigation-location']}>
									{calendarDate.toLocaleDateString({
										month: 'short',
									})}
									<input
										className={css['year-input']}
										defaultValue={state.yearInputRefVal}
										onBlur={onNavigateYear}
										onFocus={savePrevYear}
										ref={yearInputRef}
									/>
								</time>
							)}
						</div>

						{renderNavigationTodayButton && (
							<span
								className={css['navigation-today']}
								onClick={onNavigateToday}
								onMouseDown={onMouseDown}
								data-testid="datetime_picker.today">
								{t(`Today`)}
							</span>
						)}
					</Row>
				</Column>
			);
		};

		const renderCalendarWeekDay = (
			calendarDay: CalendarDayType,
			index: number
		) => {
			const date = new Date(calendarDay.timestamp);
			const dateTime = new DateTime(date);

			return (
				<div className={css['calendar-weekday']} key={index}>
					{dateTime.toWeekdayString()}
				</div>
			);
		};

		const getSelectedDates = () => {
			if (_sortedDates.length > 0) {
				return _sortedDates;
			}

			_sortedDates = [...selectedDates].sort((a, b) => +a - +b);

			return _sortedDates;
		};

		const calendarWeekDays = () => {
			const c = calendar();

			if (c.length === 0) return null;

			// @NOTE Get first week of current month
			const weekdays = c.slice(0, 7);

			return (
				<header className={css.weekdays}>
					{weekdays.map((wd, index) => renderCalendarWeekDay(wd, index))}
				</header>
			);
		};

		const renderDay = (calendarDay: CalendarDayType, index: number) => {
			const { day, timestamp, isCurrentMonth, isToday } = calendarDay;
			const dateTime = new DateTime(new Date(timestamp));

			const localSelectable = isSelectable(dateTime) && isCurrentMonth;
			const isSelected = selectedDates.some((d) => dateTime.sameDay(+d));
			const isWithinAppliedRange = highlightedDates.some((da) =>
				dateTime.isBetween(+da[0], +da[1])
			);
			const appliedRangeStart = highlightedDates.some((da) =>
				dateTime.sameDay(+da[0])
			);
			const appliedRangeEnd = highlightedDates.some((da) =>
				dateTime.sameDay(+da[1])
			);
			let isInSelectedRange = false;

			const isStartOfRange =
				range &&
				selectedDates[1] !== undefined &&
				dateTime.sameDay(+selectedDates[0]);

			const isEndOfRange = range && dateTime.sameDay(+selectedDates[1]);

			let calendarDayState: DAY_STYLES_CLASSNAMES = DAY_STYLES_CLASSNAMES.idle;

			if (!localSelectable) {
				calendarDayState = DAY_STYLES_CLASSNAMES.disabled;
			}

			// @NOTE If calendar is marked as selectable={false} just mark is as idle.
			if (!selectable && isCurrentMonth) {
				calendarDayState = DAY_STYLES_CLASSNAMES.idle;

				// @NOTE Make sure today is still marked as active.
				if (isToday) {
					calendarDayState = DAY_STYLES_CLASSNAMES.active;
				}
			}

			if (!isCurrentMonth) {
				calendarDayState = DAY_STYLES_CLASSNAMES.outsideRange;
			}

			if (isSelected) {
				calendarDayState = DAY_STYLES_CLASSNAMES.active;
			}

			if (
				!isSelected &&
				selectedDates.length === 2 &&
				dateTime.isBetween(+selectedDates[0], +selectedDates[1])
			) {
				calendarDayState = DAY_STYLES_CLASSNAMES.insideSelectedRange;
				isInSelectedRange = true;
			}

			const onMouseDown = () => {
				if (localSelectable) {
					emitDateChange(dateTime.toDate());
				}
			};

			const properties = {
				onMouseDown,
			};

			return (
				<time
					key={index}
					{...properties}
					style={cssVarList({
						'day-highlight': dayHighlight[calendarDayState],
						'day-background-color': dayBackgroundColor[calendarDayState],
						'day-foreground': dayForeground[calendarDayState],
						'day-cursor': dayCursor[calendarDayState],
						'day-weight': dayWeight[calendarDayState],
					})}
					className={cssClasses(
						css.day,
						isToday ? css['is-today'] : '',
						isStartOfRange ? css['start-of-range'] : '',
						isEndOfRange ? css['end-of-range'] : '',
						isInSelectedRange ? css['inside-selected-range'] : '',
						isWithinAppliedRange ? css['within-applied-range'] : '',
						appliedRangeStart ? css['start-applied-range'] : '',
						appliedRangeEnd ? css['end-applied-range'] : '',
						!range ? css['single'] : ''
					)}>
					{day}
					{isToday && <div className={css.today}>Today</div>}
				</time>
			);
		};

		const calendarMonth = () => {
			const c = calendar();
			return (
				<article className={css.body}>
					<div className={css.month}>
						{c.map((wd, index) => renderDay(wd, index))}
					</div>
				</article>
			);
		};

		return (
			<div className={css['wrapper']}>
				{calendarNavigation()}
				<div className={css['month-wrapper']}>
					{calendarWeekDays()}
					{calendarMonth()}
				</div>
			</div>
		);
	}
);

export default Calendar;
