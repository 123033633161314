import { ReactNode } from 'react';

export const symbol_contact_mail: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M592-524h208q12.75 0 21.375-8.625T830-554v-126q0-12.75-8.625-21.375T800-710H592q-12.75 0-21.375 8.625T562-680v126q0 12.75 8.625 21.375T592-524Zm104-77 79-60q7.692-6 16.346-1.5T800-648q0 1-6 13l-79 60q-8 6-18 6t-18-6l-81-60q-2-2-6-13 0-10 9-14.5t17 1.5l78 60ZM60-120q-24 0-42-18T0-180v-600q0-24 18-42t42-18h840q24 0 42 18t18 42v600q0 24-18 42t-42 18H60Zm531-60h309v-600H60v600h7q44-69 112.5-109T329-329q81 0 149.5 40T591-180ZM329-400q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35ZM143-180h372q-35.606-42.275-84.303-65.637Q382-269 329-269t-101.5 23.5Q179-222 143-180Zm186-280q-25.5 0-42.75-17.25T269-520q0-25.5 17.25-42.75T329-580q25.5 0 42.75 17.25T389-520q0 25.5-17.25 42.75T329-460Zm151-20Z"
		/>
	</svg>
);
