import { ReactNode } from 'react';

export const symbol_fork_right: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M402-120q-13 0-21.5-8.5T372-150v-576l-69 69q-9 9-21 9t-21-9q-9-9-9-21t9-21l120-120q9-9 21-9t21 9l120 120q9 9 9 21t-9 21q-9 9-21 9t-21-9l-69-69v320q30-33 71.5-54T607-481q16 0 38 3t41 8l-69-69q-9-9-9-21t9-21q9-9 21-9t21 9l120 120q9 9 9 21t-9 21L659-299q-9 9-21 9t-21-9q-9-9-9-21t9-21l69-69q-16-5-38-8t-45-3q-57 0-104.5 34T432-290v140q0 13-8.5 21.5T402-120Z"
		/>
	</svg>
);
