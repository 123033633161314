// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* This is css that is used to wrap the page in the payment platforms order creation and subscription edit to allow
 for a bottom bar (in this case total amount bar) to be shown. */
.xIfgPuFQMxreYivwPSwG {
	display: grid;
	grid-auto-flow: row;
	position: relative;
	min-height: 0;
	min-width: 0;
	background-color: var(--palette-white);
	grid-template-rows: auto -webkit-max-content;
	grid-template-rows: auto max-content;
}
@media (max-width: 768px) {
.xIfgPuFQMxreYivwPSwG {
		height: calc(
			100% -
				(var(--action-bar-height-standard) + env(safe-area-inset-bottom))
		);
		height: calc(
			100% -
				calc(var(--action-bar-height-standard) + env(safe-area-inset-bottom))
		);
		padding-top: calc(60px + env(safe-area-inset-top));
}
	}
@media (max-width: 768px) {
.LgEYpXkYFCygkjOYtmbF {
		height: 100%;
}
	}
.Pi8MqUy6NweNQtF4EXvv {
	overflow: auto;
}
`, "",{"version":3,"sources":["webpack://./components/payment_platform/form-page/styles.css"],"names":[],"mappings":"AAAA;gEACgE;AAChE;CACC,aAAa;CACb,mBAAmB;CACnB,kBAAkB;CAClB,aAAa;CACb,YAAY;CACZ,sCAAsC;CACtC,4CAAoC;CAApC,oCAAoC;AASrC;AAPC;AATD;EAUE;;;GAGC;EAHD;;;GAGC;EACD,kDAAkD;AAEpD;CADC;AAIA;AADD;EAEE,YAAY;AAEd;CADC;AAGD;CACC,cAAc;AACf","sourcesContent":["/* This is css that is used to wrap the page in the payment platforms order creation and subscription edit to allow\n for a bottom bar (in this case total amount bar) to be shown. */\n.wrapper {\n\tdisplay: grid;\n\tgrid-auto-flow: row;\n\tposition: relative;\n\tmin-height: 0;\n\tmin-width: 0;\n\tbackground-color: var(--palette-white);\n\tgrid-template-rows: auto max-content;\n\n\t@media (--small-viewport) {\n\t\theight: calc(\n\t\t\t100% -\n\t\t\t\tcalc(var(--action-bar-height-standard) + env(safe-area-inset-bottom))\n\t\t);\n\t\tpadding-top: calc(60px + env(safe-area-inset-top));\n\t}\n}\n\n.fullHeight {\n\t@media (--small-viewport) {\n\t\theight: 100%;\n\t}\n}\n\n.contentWrapper {\n\toverflow: auto;\n}\n"],"sourceRoot":""}]);
// Exports
export var wrapper = `xIfgPuFQMxreYivwPSwG`;
export var fullHeight = `LgEYpXkYFCygkjOYtmbF`;
export var contentWrapper = `Pi8MqUy6NweNQtF4EXvv`;
export default ___CSS_LOADER_EXPORT___;
