import { ReactNode } from 'react';

export const symbol_bluetooth_drive: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M248-314q23.333 0 39.667-15.75Q304-345.5 304-368q0-23.333-16.265-39.667Q271.471-424 248.235-424 225-424 209.5-407.735q-15.5 16.264-15.5 39.5Q194-345 209.75-329.5T248-314Zm391 0q23.333 0 39.667-15.75Q695-345.5 695-368q0-23.333-16.265-39.667Q662.471-424 639.235-424 616-424 600.5-407.735q-15.5 16.264-15.5 39.5Q585-345 600.75-329.5T639-314ZM164-204v44q0 16.667-11.735 28.333Q140.529-120 123.765-120 107-120 95.5-131.667 84-143.333 84-160v-310q0-4.667.5-9.333Q85-484 87-489l79-230q6-18.222 21.75-29.611T223-760h351q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T574-700H222l-55 166h407q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T574-474H144v210h600v-180q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T804-444v284q0 16.667-11.735 28.333Q780.529-120 763.765-120 747-120 735.5-131.667 724-143.333 724-160v-44H164Zm585-516L647-822q-5-5-4.5-10.5T648-843q5-5 10.5-5t10.5 5l86 87v-135q0-10 9.5-14.5T781-902l85 87q5 4.636 5 10.818Q871-798 866-793l-75 73 75 75q5 4.636 5 10.818Q871-628 866-623l-85 85q-7 7-16.5 3.458Q755-538.083 755-548v-136l-87 87q-5 5-10 4.5t-10-5.5q-5-5-5-10.5t5-10.5l101-101Zm41-48 38-36-38-38v74Zm0 172 38-38-38-38v76ZM144-474v210-210Z"
		/>
	</svg>
);
