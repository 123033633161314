import { ReactNode } from 'react';

export const symbol_stroller: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M629.823-80Q605-80 587.5-97.677t-17.5-42.5Q570-165 587.677-182.5t42.5-17.5Q655-200 672.5-182.323t17.5 42.5Q690-115 672.323-97.5t-42.5 17.5Zm-390 0Q215-80 197.5-97.677t-17.5-42.5Q180-165 197.677-182.5t42.5-17.5Q265-200 282.5-182.323t17.5 42.5Q300-115 282.323-97.5t-42.5 17.5ZM325-320h275v-323L325-320Zm-44 60q-26 0-36.5-23t6.5-43l397-467q17-20 43.5-33.5T746-840q56 0 95 39t39 95v1q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.695t-8.5-21.549V-706q0-31-21.5-52.5T746-780q-16.893 0-29.946 8Q703-764 689-748l-29 33v395q0 24.75-17.625 42.375T600-260H281Zm131-317L239-750q-11-10.511-8.5-24.255Q233-788 245-796q35-18 76.852-28 41.853-10 85.148-10 47 0 93.5 12.5T590-786L412-577Zm51 95Zm-55-183 81-96q-20-8-41-10.5t-42-2.5q-23 0-46 3.5T314-760l94 95Zm0 0Z"
		/>
	</svg>
);
