import { cloneElement, Fragment, Component } from 'react';
import styled from 'styled-components';

import * as palette from 'pkg/config/palette';

import CardBase, * as Card from 'components/Card';
import { FormattedContent } from 'components/formatted-content';
import Icon from 'components/icon';
import ModalBase from 'components/modal';

const ExplainIconModal = styled(Icon)`
	margin: 0 3px;
	cursor: pointer;
	font-size: 1.3rem;
	transition: all 0.15s ease-in-out;
	color: ${palette.gray[600]};

	@media (hover: hover) {
		&:hover {
			color: ${palette.blue[500]};
		}
	}
`;

class Explanation extends Component {
	state = {
		modalOpen: false,
	};

	openModal = () => {
		this.setState({ modalOpen: true });
	};

	closeModal = async (p) => {
		await p;

		this.setState({ modalOpen: false });
	};

	get trigger() {
		if (this.props.trigger) {
			return cloneElement(this.props.trigger, {
				onClick: this.openModal,
			});
		}

		return <ExplainIconModal name="help-circle" onClick={this.openModal} />;
	}

	render() {
		const { title, body } = this.props;
		const { modalOpen } = this.state;

		return (
			<Fragment>
				{this.trigger}

				{modalOpen && (
					<ModalBase key="feature-help-modal" onClose={this.closeModal}>
						<CardBase>
							<Card.Header>
								<Card.Heading>{title}</Card.Heading>
							</Card.Header>

							<Card.Divider />

							<Card.Body $flex>
								<FormattedContent raw={body} />
							</Card.Body>
						</CardBase>
					</ModalBase>
				)}
			</Fragment>
		);
	}
}

export default Explanation;
