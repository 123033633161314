import { ReactNode } from 'react';

export const symbol_newspaper: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-120q-24.75 0-42.375-17.625T80-180v-642q0-5.25 4.5-7.125T93-827l54 54 55-56q4.636-5 10.818-5Q219-834 224-829l56 56 56-56q4.636-5 10.818-5Q353-834 358-829l55 56 56-56q4.636-5 10.818-5Q486-834 491-829l56 56 55-56q4.636-5 10.818-5Q619-834 624-829l56 56 56-56q4.636-5 10.818-5Q753-834 758-829l55 56 54-54q4-4 8.5-2.125T880-822v642q0 24.75-17.625 42.375T820-120H140Zm0-60h310v-280H140v280Zm370 0h310v-110H510v110Zm0-170h310v-110H510v110ZM140-520h680v-120H140v120Z"
		/>
	</svg>
);
