import { Rgb } from 'pkg/config/palette';

import ColorConverter from 'pkg/colorconverter';

export default function rgba(
	colorValues: string | number[] | Rgb,
	alphaChannelValue: number = 1
): string {
	let r: number, b: number, g: number, a: number;

	if (Array.isArray(colorValues)) {
		[r, g, b, a] = colorValues;
	} else {
		const converter: ColorConverter = new ColorConverter();

		const hsl: number[] = converter.fromHEX(colorValues) as number[];

		converter.setHue(hsl[0]);
		converter.setSaturation(hsl[1]);
		converter.setLightness(hsl[2]);

		const rgb: number[] = converter.toRGB() as number[];

		r = rgb[0];
		g = rgb[1];
		b = rgb[2];
	}

	if (!a) {
		a = alphaChannelValue;
	}

	return `rgba(${r}, ${g}, ${b}, ${a})`;
}
