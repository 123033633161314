import { ReactNode } from 'react';

export const symbol_no_meals: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m760-286-60-60v-54h-54l-61-61v-244q0-33 12-64.5t31.5-56q19.5-24.5 45-39.5t52.5-15q14 0 24 10t10 24v560Zm95 223q-9 9-21.5 9T812-63l-52-51v4q0 13-8.5 21.5T730-80q-13 0-21.5-8.5T700-110v-64L48-826q-9-9-9-22t9-22q9-9 21.5-9t21.5 9l764 765q9 9 9 21t-9 21ZM470-578l-60-58v-214q0-13 8.5-21.5T440-880q13 0 21.5 8.5T470-850v272ZM345-701l-60-60v-89q0-13 8.5-21.5T315-880q13 0 21.5 8.5T345-850v149ZM220-826l-54-54q23 0 38.5 15.5T220-826Zm95 746q-13 0-21.5-8.5T285-110v-338q-52-11-88.5-52.5T160-600v-200l60 60v140h65v-75l60 60v15h15l85 85q-17 26-43 43.5T345-448v338q0 13-8.5 21.5T315-80Z"
		/>
	</svg>
);
