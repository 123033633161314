import { t } from '@transifex/native';
import { Component } from 'react';

import * as sdk from 'pkg/core/sdk';

import SingleComment from 'containers/comment/Single';

import FeedItem, { Comments } from 'components/FeedItem';
import { CommentLink } from 'components/Links';

export default class CommentsList extends Component {
	static defaultProps = {
		comments: [],
		links: { next: '' },
	};

	state = {
		showLoadMoreLink: true,
	};

	/**
	 * Do this in an async redux action perhaps?
	 */
	getNextCommentPage = async (e) => {
		e.preventDefault();
		e.stopPropagation();

		const { links } = this.props;

		const response = await sdk.get(links.next);
		const collection = await response.json();

		await this.props.addCommentsBatch(this.props.id, collection);
	};

	renderSingleComment = (comment) => {
		return (
			<SingleComment
				key={comment.id}
				id={comment.id}
				comment={comment}
				user={comment.user}
				resourceId={this.props.id}
				stateKey={this.props.stateKey}
			/>
		);
	};

	get renderPager() {
		const { links } = this.props;

		if (!links.next) {
			return null;
		}

		return (
			<FeedItem key="feedPager">
				<CommentLink onClick={this.getNextCommentPage}>
					{t(`View previous comments`)}
				</CommentLink>
			</FeedItem>
		);
	}

	render() {
		const { comments } = this.props;

		if (comments.length === 0) {
			return null;
		}

		return [
			this.renderPager,
			<Comments key="comments" className={[this.props.className]}>
				{comments.length > 0
					? this.props.comments.map(this.renderSingleComment)
					: null}
			</Comments>,
		];
	}
}
