import { t } from '@transifex/native';
import { Fragment, useRef, useState } from 'react';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import DateTime from 'pkg/datetime';
import { useEndpoint } from 'pkg/api/use_endpoint';
import { cssClasses } from 'pkg/css/utils';
import { isOrganization } from 'pkg/api/models/group';
import { useCurrentMembership, useCurrentOrganization } from 'pkg/identity';
import * as actions from 'pkg/actions';

import BirthYearSetting from 'routes/group/settings/core_settings/BirthYearSetting';
import GenderSettings from 'routes/group/settings/core_settings/GenderSettings';
import EditableRow from 'routes/group/settings/editable-row';
import InheritParentSettings from 'routes/group/settings/core_settings/InheritParentSettings';

import Label from 'components/label';
import CardAnatomy, * as Card from 'components/Card';
import SectionTitle from 'components/SectionTitle';
import MaterialSymbol from 'components/material-symbols';
import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import Column from 'components/layout/column';
import TextInput from 'components/form/TextInput';
import {
	SettingControl,
	SettingDescription,
	SettingLabel,
	SettingRow,
	SettingRowDivider,
} from 'components/settings/Settings';
import { Spinner } from 'components/loaders/spinner';
import * as Input from 'components/form/inputs';

import * as ContextMenu from 'design/context_menu';
import Button from 'design/button';
import { useDialog } from 'design/dialog';

import * as css from '../styles.css';

interface PrimarySettingsProps {
	group: models.group.Group;
	replaceRecord?: (record: models.group.Group) => void;
	refresh?: () => void;
}

export default function CoreSettings({
	group,
	replaceRecord,
	refresh,
}: PrimarySettingsProps): JSX.Element {
	const { isLoading: isLoadingCountries, record: countries } = useEndpoint<
		models.country.Country[]
	>(endpoints.Country.Index());

	const { record: parentGroup } = useEndpoint<models.group.Group>(
		endpoints.Groups.ShowParent(group?.id)
	);

	const organization = useCurrentOrganization();
	const membership = useCurrentMembership();

	const showSlugSetting =
		membership.groupId === organization.id &&
		models.membership.isAdmin(membership);

	const [isEditingName, setIsEditingName] = useState(false);
	const [nameInput, setNameInput] = useState(group.name);

	const [isEditingSlug, setIsEditingSlug] = useState(false);
	const [slugInput, setSlugInput] = useState(group.slug);

	const [seasonStartMonth, setSeasonStartMonth] = useState(
		group.seasonStartMonth
	);

	const handleIsEditingName = () => {
		setIsEditingName(true);
	};

	const handleNameSubmit = async () => {
		await models.update(group, {
			name: nameInput,
		});

		location.reload();

		setIsEditingName(false);
	};

	const handleNameChange = (event: any) => {
		setNameInput(event.target.value);
	};

	const handleNameCancel = () => {
		setIsEditingName(false);
		setNameInput(group.slug);
	};

	const handleIsEditingSlug = () => {
		setIsEditingSlug(true);
	};

	const handleSlugSubmit = useDialog({
		wide: true,
		destructive: true,
		symbol: null,
		confirmLabel: t('Yes, continue'),
		message: (
			<Column className={css.slugChangeWrapper} justify="center">
				<MaterialSymbol variant="emergency_home" actualSize scale={2.5} />

				<p>
					<strong>
						{t(
							'Changing the slug of your organization will change all outstanding links to forms and login pages.'
						)}
					</strong>
				</p>

				<p>{t('Are you sure you want to continue with these changes?')}</p>

				<p className={css.slugChange}>
					<Label color="red">{group.slug}</Label>
					<LargeScreen>
						<MaterialSymbol variant="arrow_right_alt" scale={1.5} />
					</LargeScreen>
					<SmallScreen>
						<MaterialSymbol variant="arrow_downward_alt" scale={1.5} />
					</SmallScreen>
					<Label color="green">{slugInput}</Label>
				</p>
			</Column>
		),
		onConfirm: async () => {
			const [response] = await models.update<
				Partial<models.group.Group>,
				models.group.Group
			>(group, {
				slug: slugInput,
			});

			if (response.ok) {
				actions.flashes.show(
					{
						title: t('{groupName} updated', { groupName: group.name }),
						message: t('Successfully updated organization slug'),
					},
					response.status
				);

				refresh();
			} else {
				actions.flashes.show(
					{
						title: t('Could not update slug'),
					},
					response.status
				);
			}

			setIsEditingSlug(false);
		},
		onCancel: handleNameCancel,
	});

	const handleSlugChange = (event: any) => {
		setSlugInput(event.target.value?.toLocaleLowerCase());
	};

	const handleSlugCancel = () => {
		setIsEditingSlug(false);
		setSlugInput(group.slug);
	};

	// match alpha numeric string that is between 3 and 50 characters and starts and ends with alpha-numeric character
	const validSlug =
		slugInput?.match(/^[a-z0-9][\w\-0-9]{1,48}[a-z0-9]$/gi)?.length > 0;

	const handleSeasonStartChange = async (event: any) => {
		const seasonStart = Number.parseInt(
			event.target.getAttribute('data-season-number'),
			10
		);

		await models.update(group, { seasonStartMonth: seasonStart });

		setSeasonStartMonth(seasonStart);
	};
	const handleGroupCountry = async (countryId: any) => {
		const [request, response] = await models.update(group, { countryId });
		if (request.ok) {
			replaceRecord(response as models.group.Group);
		}
	};

	const nameRef = useRef(null);
	const slugRef = useRef(null);

	const monthNames = [...Array(12).keys()].map((n) => {
		const y = new Date().getFullYear();
		const d = new DateTime(new Date(y, n, 15, 13, 37, 0));

		return [
			n + 1,
			d.toLocaleDateString({
				month: 'long',
			}),
		];
	});

	const getSeasonStart = () => {
		let seasonStart = seasonStartMonth - 1;

		if (seasonStart < 0) {
			seasonStart = 0;
		}

		return monthNames[seasonStart]?.[1];
	};

	const contextMenuToggle = () => {
		return (
			<Button small icon="edit">
				{t('Change')}
			</Button>
		);
	};

	const seasonStartContextMenuItems = () => {
		return monthNames.map(([num, label]) => (
			<li
				className={cssClasses(
					num == seasonStartMonth
						? css.activeSeasonContextItem
						: css.seasonContextItem
				)}
				key={`season-start-month-${num}`}
				data-season-number={num}
				onClick={handleSeasonStartChange}>
				<span className={css.seasonStartContextMenuLabel}>{label}</span>
			</li>
		));
	};

	const countryContextMenuItems = () => {
		if (isLoadingCountries) {
			return <Spinner />;
		}
		return countries
			.sort((country: any) => country.name)
			.map((country: any) => (
				<ContextMenu.Item
					key={country.id}
					onClick={() => handleGroupCountry(country.id)}>
					{country.name}
				</ContextMenu.Item>
			));
	};

	const handleDidInheritSettings = async (group: models.group.Group) => {
		replaceRecord(group);
	};

	return (
		<Column>
			<SectionTitle>{t('Core settings')}</SectionTitle>
			<CardAnatomy $noBorder>
				<Card.Body>
					<EditableRow
						label={t('Name')}
						isEditing={isEditingName}
						staticComponent={nameInput}
						editableComponent={
							<TextInput
								ref={nameRef}
								value={nameInput}
								onChange={handleNameChange}
								autoFocus
							/>
						}
						onEdit={handleIsEditingName}
						onSubmit={handleNameSubmit}
						onCancel={handleNameCancel}
					/>

					{showSlugSetting && (
						<Fragment>
							<SettingRowDivider />
							<EditableRow
								isDisabled={slugInput === group.slug}
								hasError={!validSlug}
								label={t('Slug')}
								isEditing={isEditingSlug}
								staticComponent={slugInput}
								editableComponent={
									<Input.Group
										hasError={!validSlug}
										hint={t(
											'The slug needs to be between 5 and 50 characters long. You can add dashes (-), underscores (_) and numbers.'
										)}>
										<Input.Field
											ref={slugRef}
											value={slugInput}
											onChange={handleSlugChange}
											autoFocus>
											<Input.Suffix>{slugInput?.length || 0}/50</Input.Suffix>
										</Input.Field>
									</Input.Group>
								}
								onEdit={handleIsEditingSlug}
								onSubmit={handleSlugSubmit}
								onCancel={handleSlugCancel}
							/>
						</Fragment>
					)}

					<SettingRowDivider />

					<SettingRow>
						<SettingLabel>{t('Season start')}</SettingLabel>
						<SettingDescription>{getSeasonStart()}</SettingDescription>
						<SettingControl>
							<div>
								<ContextMenu.Menu
									toggleWith={contextMenuToggle()}
									maxWidth="150px">
									<div>{seasonStartContextMenuItems()}</div>
								</ContextMenu.Menu>
							</div>
						</SettingControl>
					</SettingRow>

					<SettingRowDivider />

					<SettingRow>
						<SettingLabel>{t('Country')}</SettingLabel>
						<SettingDescription>
							{group.country ? group.country.name : t('Select country')}
						</SettingDescription>
						<SettingControl>
							<div>
								<ContextMenu.Menu toggleWith={contextMenuToggle()}>
									{countryContextMenuItems()}
								</ContextMenu.Menu>
							</div>
						</SettingControl>
					</SettingRow>

					{!isOrganization(group) && (
						<Fragment>
							<SettingRowDivider />
							<BirthYearSetting group={group} refresh={refresh} />
							<SettingRowDivider />
							<GenderSettings group={group} refresh={refresh} />
						</Fragment>
					)}

					{parentGroup?.id && (
						<Fragment>
							<SettingRowDivider />

							<div className={css.inheritWrapper}>
								<div className={css.inheritLabel}>
									{t('Inherit properties from parent team')}
								</div>
								<div className={css.inheritDescription}>
									{t(
										'You can choose to inherit certain settings from {parentName}, to do so click the button below.',
										{
											parentName: parentGroup.name,
										}
									)}
								</div>
								<div className={css.inheritAction}>
									<InheritParentSettings
										group={group}
										parentGroup={parentGroup}
										onDidInherit={handleDidInheritSettings}
									/>
								</div>
							</div>
						</Fragment>
					)}
				</Card.Body>
			</CardAnatomy>
		</Column>
	);
}
