import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { t } from '@transifex/native';

import Account from 'pkg/models/account';
import VideoSequence from 'pkg/models/video_sequence';

import { RootState } from 'pkg/reducers';
import * as selectors from 'pkg/selectors';
import { useCurrentRoute } from 'pkg/router/hooks';
import { cuePointsFromSequences } from 'pkg/video';
import { useCurrentAccount } from 'pkg/identity';

import Icon from 'components/icon';
import Avatar from 'components/avatar';

import * as iconStyles from 'components/icon/styles.css';
import * as Inputs from 'components/form/inputs';
import { CueGroupType, useCueState } from 'components/video-analytics/CueState';
import {
	getCurrentFilterState,
	useFilterState,
} from 'components/video-analytics/FilterState';

import * as ContextMenu from 'design/context_menu';

const Wrapper = styled.div`
	position: relative;

	&[data-disabled='true'] {
		pointer-events: none;
		opacity: 0.6;
	}
`;

const Item = styled(ContextMenu.Item)`
	display: grid;
	grid-template-columns: 20px 1fr auto;
`;

const Trigger = styled.div`
	padding: var(--spacing-2) 0;
	display: inline-grid;
	grid-template-columns: 16px auto auto;
	gap: var(--spacing-2);
	place-items: center;
	cursor: pointer;
	font-size: var(--font-size-sm);
	color: var(--palette-white);

	span:nth-child(2) {
		display: inline-block;
		margin-left: var(--spacing-2);
	}

	.${iconStyles.icon} {
		transform: scale(1.1) translateY(0px);
	}
`;

interface ClipGroupSelectorProps {
	disabled?: boolean;
}

export default function ClipGroupSelector({
	disabled,
}: ClipGroupSelectorProps): JSX.Element {
	const { videoId } = useCurrentRoute();

	const { cueContextId, cueGroupType, setCuePoints, setCueContextId } =
		useCueState();

	const filterState = useFilterState();
	const filters = getCurrentFilterState();
	const account = useCurrentAccount();

	const authors: any = useSelector((state: RootState) =>
		selectors.videoSequences.findAllAvailableAuthors(state, videoId)
	);

	const filteredSequences: any = useSelector((state: RootState) =>
		selectors.videoSequences.findAllFilteredSequences(state, videoId, filters)
	);

	if (!authors.size) return <span />;

	const selectAccount = (accountId: number) => {
		filterState.reset();

		const sequences = filteredSequences.filter(
			(sequence: VideoSequence) => sequence.accountId === accountId
		);

		setCueContextId(accountId);
		setCuePoints(cuePointsFromSequences(sequences), CueGroupType.Account);
	};

	const isActiveCueGroup = (accountId?: number) => {
		if (!accountId) {
			return cueGroupType === CueGroupType.Highlight;
		} else {
			return (
				cueGroupType === CueGroupType.Account && cueContextId === accountId
			);
		}
	};

	let trigger = null;

	if (cueGroupType === CueGroupType.Account) {
		const author = authors.find(
			(account: Account) => account.id === cueContextId
		);

		if (author) {
			trigger = (
				<Trigger>
					<Avatar account={author} size={16} />
					<span>
						{author.id === account.id ? t('My clips') : author.firstName}
					</span>
					<Icon name="unfold" />
				</Trigger>
			);
		}
	}

	if (!trigger) {
		disabled = false;

		trigger = (
			<Trigger>
				<Avatar account={account} size={16} />
				<span>{t('My clips')}</span>
				<Icon name="unfold" />
			</Trigger>
		);
	}

	return (
		<Wrapper data-disabled={disabled}>
			<ContextMenu.Menu toggleWith={trigger}>
				<ContextMenu.Label>{t('Clip authors')}</ContextMenu.Label>
				{authors.map((author: Account) => (
					<Item key={author.id} onClick={() => selectAccount(author.id)}>
						<Avatar account={author} size={20} />
						<span>
							{author.id === account.id ? t('My clips') : author.fullName}
						</span>
						<Inputs.Control
							type="radio"
							standalone
							checked={isActiveCueGroup(author.id)}
						/>
					</Item>
				))}
			</ContextMenu.Menu>
		</Wrapper>
	);
}
