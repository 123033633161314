import { findAll } from 'pkg/selectors/videos';

export const getGroupVideos = (state, { groupId }) => {
	return findAll(state)
		.filter(
			(entity) =>
				Number.parseInt(entity.get('groupId'), 10) ===
				Number.parseInt(groupId, 10)
		)
		.toList()
		.sortBy((n) => -n.get('createdAt'));
};
