import { ReactNode } from 'react';

export const symbol_phone_missed: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m136-146-94-94q-9-9-8.5-21t8.5-22q82-96 197-146.5T480-480q126 0 241 50.5T918-283q8 10 8.5 22t-8.5 21l-94 94q-8 8-23 9t-24-6l-114-85q-6-4.5-9-10.5t-3-13.5v-139q-42-16-85.5-22.5T480-420q-42 0-85.5 6.5T309-391v139q0 7.5-3 13.5t-9 10.5l-114.188 85.278Q171-134 158.312-134.923 145.625-135.846 136-146Zm108-224q-38 19-73 45.5T104-268l57 59 83-61v-100Zm467-4v98l87 66 58-58q-32-33-68.5-58.5T711-374Zm-467 4Zm467-4ZM237.825-615Q225-615 216.5-623.625T208-645v-165q0-12.75 8.625-21.375T238-840h165q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T403-780h-96l173 171 210-210q9-9 21.5-9.5t21.5 8.553q9 9.052 9 21.5Q742-786 733-777L522-566q-18 18-43.033 18T437-566L268-737v92q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Z"
		/>
	</svg>
);
