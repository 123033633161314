import { ReactNode } from 'react';

export const symbol_stack_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m880-166-60-60v-354H466l-60-60h414q24.75 0 42.375 17.625T880-580v414ZM226-820l-60-60h414q24.75 0 42.375 17.625T640-820v120h-60v-120H226Zm417 417ZM380-140h354L380-494v354Zm0 60q-25.5 0-42.75-17.25T320-140v-414L140-734v354h120v60H140q-25.5 0-42.75-17.25T80-380v-414l-21.75-21.946q-9.25-9.081-8.75-21.568Q50-850 59.053-859q9.052-9 21.5-9Q93-868 102-859l757 758q9 9 9 21t-9.053 21q-9.052 9-21.5 9-12.447 0-21.52-9L794-80H380Zm177-237Z"
		/>
	</svg>
);
