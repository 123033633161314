import { normalize } from 'normalizr';

import EventReport from 'pkg/models/event_report';

import * as services from 'pkg/actions/services';
import * as appActions from 'pkg/actions/app';
import { addUserEntities } from 'pkg/actions/users';

import store from 'pkg/store/createStore';

export interface EventReportPayload {
	userId?: number;
	groupId: number;
	eventTypes?: string[];
	startsAt?: number;
	endsAt?: number;
	attendanceStatus?: string[];
	response?: string[];
}

export const fetchGroupedByUser = async (
	payload: EventReportPayload
): Promise<any> => {
	const [request, response] =
		await services.eventReport.fetchGroupedByUser(payload);

	if (!request.ok) {
		appActions.triggerError(request, null)(store.dispatch);
		return;
	}

	const data = normalize(response.records, [EventReport.normalizr()]);

	if (data.entities.users) {
		store.dispatch(addUserEntities(data));
	}

	if (data.entities.eventReports) {
		return data.entities.eventReports;
	}

	return [];
};

export const fetchByUser = async (
	payload: EventReportPayload
): Promise<any> => {
	const [request, response] = await services.eventReport.fetchByUser(payload);

	if (!request.ok) {
		appActions.triggerError(request, null)(store.dispatch);
		return;
	}

	return response.records;
};
