import { ReactNode } from 'react';

export const symbol_sword_rose: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M880-850v118q0 6-2 11t-7 10L644-484q-9 9-21 9t-21-9q-9-9-9-21.5t9-21.5l218-218v-75h-75L525-602q-9 8-21 8.5t-21-8.5q-8-8-7.5-21t8.5-21l227-227q5-5 10-7t11-2h118q13 0 21.5 8.5T880-850ZM149-84l-65-65q-9-9-9-21t9-21l124-124-67-67q-9-9-9-21t9-21l22-22q17-17 41.5-17t42.5 17l20 19 48-48q9-9 21.5-9t20.5 9q8 8 7.5 21t-8.5 21l-48 48 75 75 48-48q9-9 21.5-9t21.5 9q9 9 9 22t-9 22l-47 47 19 20q17 18 17 42.5T446-163l-22 22q-9 9-21 9t-21-9l-67-67L191-84q-9 9-21 9t-21-9Zm709-305q8-35-3-67t-24-63q-26 24-47 48.5T755-410q-7 35 3.5 66t23.5 62q26-23 47-47.5t29-59.5ZM193-612q27 27 63 31.5t68-12.5l-37-36q-28-27-62-30.5T162-643l31 31Zm87-222L149-704q44-21 93-13t87 46l37 37q18-32 13.5-69T348-767l-68-67ZM828-89 366-550q-50 35-110.5 30.5T150-569l-89-90q-5-5-7-10t-2-11q0-6 2-11t7-10l198-197q5-5 10-7t11-2q6 0 11 2t10 7l89 89q45 45 49 106t-31 111l303 302q-13-32-17.5-65t2.5-67q11-54 46-95t81-69q10-6 21.5-3.5T863-577q31 44 47.5 94.5T916-378q-11 54-45.5 95T788-213l82 82q9 9 9 21t-9 21q-9 9-21 9t-21-9ZM282-678Z"
		/>
	</svg>
);
