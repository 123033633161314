import { ReactNode } from 'react';

export const symbol_health_metrics: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M340-80q-24.75 0-42.375-17.625T280-140v-140H140q-24.75 0-42.375-17.625T80-340v-280q0-24.75 17.625-42.375T140-680h140v-140q0-24.75 17.625-42.375T340-880h280q24.75 0 42.375 17.625T680-820v140h140q24.75 0 42.375 17.625T880-620v280q0 24.75-17.625 42.375T820-280H680v140q0 24.75-17.625 42.375T620-80H340ZM140-510h220q7.576 0 14.394 3.611Q381.212-502.778 385-497l46 70 60-182q3.083-9 11.177-15t18.115-6Q528-630 534-626t11 10l71 106h204v-110H650q-12.75 0-21.375-8.625T620-650v-170H340v170q0 12.75-8.625 21.375T310-620H140v110Zm0 60v110h170q12.75 0 21.375 8.625T340-310v170h280v-170q0-12.75 8.625-21.375T650-340h170v-110H600q-8 0-14-4t-11-10l-46-69-61 182q-2.974 9-11.154 15-8.179 6-17.846 6-8 0-14-4t-10-10l-71-106H140Zm340-30Z"
		/>
	</svg>
);
