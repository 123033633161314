import { ReactNode } from 'react';

export const symbol_auto_stories: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-171q-6.531 0-12.766-1.5Q461-174 456-178q-44-29-93.565-45.5T260-240q-42 0-82.5 11T100-198q-21 11-40.5-1T40-234v-482q0-11 5.5-21T62-752q46-24 96-36t102-12q58 0 113.5 15T480-740v506q51-33 107-49.5T700-300q36 0 78.5 7t81.5 29v-505q9.886 3.75 19.443 7.875Q889-757 898-752q10 6 16 15.677 6 9.678 6 20.323v482q0 23-19.5 35t-40.5 1q-37-20-77.5-31T700-240q-52.87 0-102.435 16.5Q548-207 504-178q-5 4-11.234 5.5Q486.531-171 480-171Zm85-178q-8 6-16.5 2.5T540-360v-314q0-2.857 1-5.357t3-4.643l230-230q7-7 16.5-3.458Q800-913.917 800-904v344q0 3-1.429 5.8-1.428 2.8-3.571 5.2L565-349Zm-145 85v-439q-34-19-79-28t-81-9q-47 0-87.5 10T100-704.467V-264q35-17 75.5-26.5t85-9.5q44.5 0 84.5 9.5t75 26.5Zm0 0v-439 439Z"
		/>
	</svg>
);
