import { createSelector } from 'reselect';

import Rating from 'pkg/models/rating';

const emptyRating = new Rating();

export const getSingleRating = (state, props) =>
	state.ratings.entities[props.id] || emptyRating;

const ratingsSelector = createSelector(
	[(state) => state.ratings.entities],
	(entities) => Object.keys(entities).map((key) => entities[key])
);

const ratingIdSelector = (_, props) => props.ratingId;
const comparisonRatingIdSelector = (state) => state.ratings.comparisonRatingId;
const positionScoresSelector = (state) => state.ratings.positionScores;

export const getRatings = createSelector(ratingsSelector, (ratings) =>
	ratings.sort((a, b) => b.createdAt - a.createdAt)
);

export const getUserRatingsForGroup = createSelector(
	(state) =>
		Object.keys(state.ratings.entities).map(
			(key) => state.ratings.entities[key]
		),
	(_, { userId }) => userId,
	(_, { groupId }) => groupId,

	(ratings, userId, groupId) =>
		ratings
			.filter(
				(rating) =>
					+rating.userId === +userId &&
					(+rating.groupId === +groupId || rating.groupId === null)
			)
			.sort((a, b) => b.createdAt - a.createdAt)
);

export const getUserPrimaryRating = (state, props) => {
	const ratings = getUserRatingsForGroup(state, props);

	if (ratings.length === 0) {
		return emptyRating;
	}

	return ratings.filter((r) => r.isPrimaryRating).length === 0
		? ratings.find((rating) => rating.groupId === null)
		: ratings.find((rating) => rating.isPrimaryRating);
};

export const getCurrentRating = createSelector(
	ratingsSelector,
	ratingIdSelector,
	(ratings, ratingId) => ratings.find((rating) => +rating.id === +ratingId)
);

export const makeGetComparisonRating = () =>
	createSelector(
		ratingsSelector,
		comparisonRatingIdSelector,
		(ratings, comparisonRatingId) => {
			return ratings.find((rating) => rating.id === comparisonRatingId);
		}
	);

export const getPositionScores = (state, props) => {
	const positionScores = positionScoresSelector(state);

	return positionScores[props?.ratingId] || [];
};

export const makeGetUserRatings = () =>
	createSelector(
		ratingsSelector,
		(_, props) => props.userId,
		(ratings, userId) =>
			ratings
				.filter((rating) => +rating.userId === +userId)
				.sort((a, b) => b.createdAt - a.createdAt)
	);

/*
 * Old selectors
 */
const _getCurrentRating = (state, props) =>
	getRatings(state).find((rating) => +rating.id === +props.ratingId) || {};

export const getCurrentRatings = (state, props) =>
	getRatings(state)
		.filter(
			(rating) =>
				rating.id !== props.ratingId &&
				rating.userId === _getCurrentRating(state, props)?.user?.id
		)
		.sort((a, b) => b.createdAt - a.createdAt) || [];

export const getRatingScores = ({ ratings }, ratingId, positionId) => {
	if (
		ratings.ratingScores[ratingId] &&
		ratings.ratingScores[ratingId][positionId]
	) {
		return ratings.ratingScores[ratingId][positionId];
	}

	return [];
};

export const getRatingItemsEntities = (
	{ ratings: { entities } },
	{ ratingId }
) => {
	if (entities[ratingId]) {
		return entities[ratingId].ratingItems;
	}
	return [];
};
