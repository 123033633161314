import { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { t } from '@transifex/native';

import * as palette from 'pkg/config/palette';
import * as breakpoints from 'pkg/config/breakpoints';

import { getMatches } from 'pkg/selectors/matches.selector';

import rgba from 'pkg/rgba';

import Statistics from 'containers/group/statistics/history/Statistics';

import CardBase, * as Card from 'components/Card';
import SectionTitle from 'components/SectionTitle';
import Modal from 'components/modal';

import LegendTable from 'components/group/statistics/LegendTable';
import NoDataTableRow from 'components/group/statistics/NoDataTableRow';
import Opponent from 'components/group/statistics/history/Opponent';
import StatisticsMissing from 'components/group/statistics/history/StatisticsMissing';
import {
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableHeadCell,
} from 'components/group/statistics/Table';
import Column from 'components/layout/column';

import Button from 'design/button';

const Body = styled(Card.Body)`
	display: flex;
	justify-content: space-between;
	padding: 0;
	overflow: auto;

	@media ${breakpoints.small} {
		padding: 0;
	}
`;

const ViewMore = styled.div`
	display: flex;
	justify-content: center;
	border-top: 1px solid ${rgba(palette.card.innerDividerColor)};
	padding: 1rem;
`;

class MatchHistory extends Component {
	state = {
		numberOfMatches: 10,
		showLegendModal: false,
	};

	handleViewMoreClick = () => {
		const numberOfMatches = this.state.numberOfMatches + 20;
		this.setState({ numberOfMatches });
	};

	get viewMore() {
		if (
			this.props.matches.isEmpty() ||
			this.props.matches.size < this.state.numberOfMatches
		) {
			return;
		}

		return (
			<ViewMore>
				<Button onClick={this.handleViewMoreClick}>{t(`View more`)}</Button>
			</ViewMore>
		);
	}

	handleLegendClick = () => {
		this.setState({ showLegendModal: !this.state.showLegendModal });
	};

	get legendModal() {
		const items = [
			{
				short: t(`R`),
				long: t(`Results`),
			},
			{
				short: t(`S`),
				long: t(`Shots (on/off goal)`),
			},
			{
				short: t(`OG`),
				long: t(`Shots on goal`),
			},
			{
				short: t(`C`),
				long: t(`Corners`),
			},
			{
				short: t(`FK`),
				long: t(`Free kicks`),
			},
			{
				short: t(`OS`),
				long: t(`Offsides`),
			},
			{
				short: t(`F`),
				long: t(`Fouls`),
			},
			{
				short: t(`YC`),
				long: t(`Yellow cards`),
			},
			{
				short: t(`RC`),
				long: t(`Red cards`),
			},
		];

		return (
			this.state.showLegendModal && (
				<Modal onClose={this.handleLegendClick}>
					<CardBase>
						<Card.Header>
							<Card.Heading>{t('Legend')}</Card.Heading>
						</Card.Header>
						<Card.Divider />
						<Body>
							<LegendTable items={items} />
						</Body>
					</CardBase>
				</Modal>
			)
		);
	}

	render() {
		const tableRows = this.props.matches
			.take(this.state.numberOfMatches)
			.valueSeq()
			.map((match, index) => {
				const statisticsMissing =
					match.get('goalCount') === null ||
					match.get('opponentGoalCount') === null;

				return (
					<TableRow key={`match-history-${match.get('id')}-${index}`}>
						<Opponent match={match} />
						{statisticsMissing ? (
							<StatisticsMissing
								matchId={match.get('id')}
								eventId={match.eventId}
							/>
						) : (
							<Statistics match={match} />
						)}
					</TableRow>
				);
			});

		return (
			<Column>
				<SectionTitle icon="calendar_today">
					{t(`Match history`)}

					<Button
						inline
						noPadding
						iconPosition="right"
						icon="help-circle"
						onClick={this.handleLegendClick}
						label={t(`Legend`)}
					/>
					{this.legendModal}
				</SectionTitle>
				<CardBase $noBorder>
					<Body>
						<Table>
							<TableHead>
								<TableRow>
									<TableHeadCell alignLeft isSticky>
										{t(`VS`)}
									</TableHeadCell>
									<TableHeadCell>{t(`R`)}</TableHeadCell>
									<TableHeadCell>{t(`S`)}</TableHeadCell>
									<TableHeadCell>{t(`OG`)}</TableHeadCell>
									<TableHeadCell>{t(`C`)}</TableHeadCell>
									<TableHeadCell>{t(`FK`)}</TableHeadCell>
									<TableHeadCell>{t(`OS`)}</TableHeadCell>
									<TableHeadCell>{t(`F`)}</TableHeadCell>
									<TableHeadCell>{t(`YC`)}</TableHeadCell>
									<TableHeadCell>{t(`RC`)}</TableHeadCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{tableRows.isEmpty() ? (
									<NoDataTableRow span="10" text={t(`No Matches`)} />
								) : (
									tableRows
								)}
							</TableBody>
						</Table>
					</Body>
					{this.viewMore}
				</CardBase>
			</Column>
		);
	}
}

const mapStateToProps = (state, props) => {
	const statProps = { ...props.filters.toJS(), groupId: props.groupId };

	return {
		matches: getMatches(state, statProps),
	};
};

export default connect(mapStateToProps)(MatchHistory);
