import { ReactNode } from 'react';

export const symbol_unknown_document: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-820v295-4 389-680 186-186Zm129 400h134q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T443-480H309q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T309-420Zm0 170h74q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T383-310h-74q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T309-250ZM180-80q-24 0-42-18t-18-42v-680q0-24 18-42t42-18h336q12.444 0 23.722 5T559-862l183 183q8 8 13 19.278 5 11.278 5 23.722v91q0 13-8.675 21-8.676 8-21.5 8-12.825 0-21.325-8.625T700-546v-88H541q-12.75 0-21.375-8.625T511-664v-156H180v680h264q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q474-97 465.375-88.5T444-80H180Zm475-389q80.51 0 137.255 56.745Q849-355.51 849-275q0 80.51-56.745 137.255Q735.51-81 655-81q-80.51 0-137.255-56.745Q461-194.49 461-275q0-80.51 56.745-137.255Q574.49-469 655-469Zm1.108 318Q668-151 676-159.108t8-20Q684-191 675.892-199t-20-8Q644-207 636-198.892t-8 20Q628-167 636.108-159t20 8ZM656-412q-20 0-38.286 10.043Q599.429-391.914 588-375q-5 7-1.5 14t11.5 11q6.643 3 13.286-.2t12.178-8.867Q629-367 637.311-371t18.284-4Q670-375 681-365.65q11 9.35 11 23.65 0 11-7 19.5T670-306q-8 7-15.5 13.5T642-277q-4 6-5 13.172-1 7.173-1 14.897Q636-239 641.556-231q5.555 8 14.444 8 8 0 14-6.387t6-14.903q0-11.71 6.5-21.71t15.7-18.421Q712-296 722-310.442T732-342q0-29.531-22.353-49.766Q687.294-412 656-412Z"
		/>
	</svg>
);
