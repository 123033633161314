import { ReactNode } from 'react';

export const symbol_rubric: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M160-180v-600q0-24.75 17.625-42.375T220-840h520q24.75 0 42.375 17.625T800-780v270q0 24.75-17.625 42.375T740-450H220v105h200q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T420-285H220v105h200q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T420-120H220q-24.75 0-42.375-17.625T160-180Zm474-26 131-131q9-9 21-8.5t21 9.5q9 9 9 21.5t-9 21.5L655-141q-9 9-21 9t-21-9l-72-72q-9-9-8.5-21.5T542-256q9-9 21.5-9t21.5 9l49 50ZM220-510h230v-105H220v105Zm290 0h230v-105H510v105ZM220-675h230v-105H220v105Zm290 0h230v-105H510v105Z"
		/>
	</svg>
);
