import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';

import Label from 'components/label';
import { useSmallScreen } from 'components/MediaQuery';
import Avatar from 'components/avatar';

import * as Input from 'components/form/inputs';
import Row from 'components/layout/row';
import Column from 'components/layout/column';

import * as Table from 'design/table';

import * as css from './styles.css';

interface UserItemProps {
	user: models.user.User;
	selectUser: (user: models.user.User) => void;
	deselectUser: (user: models.user.User) => void;
	selectedUsers: models.user.User[];
	currentGroupId: number;
}

export const UserItem = ({
	user,
	selectUser,
	deselectUser,
	selectedUsers,
	currentGroupId,
}: UserItemProps) => {
	const isSmallScreen = useSmallScreen();

	const isSelected = !!selectedUsers.filter((u) => u.id === user.id).length;

	const alreadyMemberOfGroup = !!user.groups?.filter(
		(m) => m.groupId === currentGroupId
	).length;

	const handleUserClick = () => {
		if (isSelected) {
			deselectUser(user);
		} else {
			selectUser(user);
		}
	};

	return (
		<Table.Row
			onClick={alreadyMemberOfGroup ? null : handleUserClick}
			disabled={alreadyMemberOfGroup}>
			<Table.Cell>
				<Input.Control
					standalone
					type="checkbox"
					value={user.id}
					onChange={handleUserClick}
					checked={isSelected}
					disabled={alreadyMemberOfGroup}
				/>
			</Table.Cell>
			<Table.Cell className={css.userRow}>
				<Row
					columns="30px 1fr"
					align="center"
					className={alreadyMemberOfGroup ? css.disabled : ''}>
					<Avatar user={user} />
					<Column spacing={styles.spacing._1}>
						<span className={css.userItemName}>
							{models.user.fullName(user)}
						</span>
						<span className={css.userItemEmail}>{user.email}</span>
					</Column>
				</Row>
				{alreadyMemberOfGroup && (
					<div className={css.alreadyMemberLabel}>
						<Label color="gray" small={isSmallScreen}>
							{t('Already a member')}
						</Label>
					</div>
				)}
			</Table.Cell>
		</Table.Row>
	);
};
