import { t } from '@transifex/native';
import React, { Fragment, useRef } from 'react';
import styled, { css } from 'styled-components';
import { List } from 'immutable';

import TrainingCollection from 'pkg/models/training_collection';

import { useSmallScreen } from 'components/MediaQuery';
import TabSwitcher from 'components/TabSwitcher';
import Icon from 'components/icon';

import * as iconStyles from 'components/icon/styles.css';
import { CollectionPlaceholder } from 'components/training/library/Placeholders';
import * as Input from 'components/form/inputs';
import Row from 'components/layout/row';

import Button from 'design/button';

const Heading = styled.div<{ tight: boolean }>`
	margin: var(--spacing-4) auto 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;

	${({ tight }) =>
		tight &&
		css`
			margin: 0 auto;
		`}
`;

const CreateWrapper = styled.div<{ tight: boolean }>`
	padding: var(--spacing-4)
		${({ tight }) =>
			tight &&
			css`
				padding: var(--spacing-4) 0;
			`};
`;

const ItemStatus = styled.div<{ selected: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: inset 0 0 0 2px var(--palette-gray-300);
	border-radius: var(--radius-3);
	transition: all 250ms ease-in-out;
	width: 20px;
	height: 20px;

	.${iconStyles.icon} {
		color: var(--palette-blue-400);
		opacity: 0;
		transition: opacity 150ms ease-in-out;
		width: 100%;
		height: 100%;
	}

	${(props) =>
		props.selected &&
		css`
			color: var(--palette-blue-400);
			box-shadow: inset 0 0 0 3px var(--palette-blue-400);

			.${iconStyles.icon} {
				opacity: 1;
			}
		`};
`;

const CollectionRow = styled.div`
	display: grid;
	grid-template-columns: auto 1fr;
	grid-gap: var(--spacing-4);
	padding: var(--spacing-3);
	cursor: pointer;

	&:not(:last-child) {
		border-bottom: 1px solid var(--palette-gray-300);
	}
`;

interface CollectionListProps {
	collections: List<TrainingCollection>;
	selectedCollections: number[];
	onChange: (key: number) => void;
}

const CollectionList = ({
	collections,
	selectedCollections,
	onChange,
}: CollectionListProps): JSX.Element => {
	const handleChange = (event: React.MouseEvent<HTMLDivElement>): void => {
		onChange(Number.parseInt(event.currentTarget.dataset.id, 10));
	};

	return (
		<Fragment>
			{collections.size === 0 && <CollectionPlaceholder />}

			{collections.map((col: TrainingCollection) => (
				<CollectionRow key={col.id} data-id={col.id} onClick={handleChange}>
					<ItemStatus selected={selectedCollections.includes(col.id)}>
						<Icon name="checkbox-checked" />
					</ItemStatus>
					{col.name}
				</CollectionRow>
			))}
		</Fragment>
	);
};

interface AddToCollectionProps {
	onChange: (id: number) => void;
	handleCreateCollection: (name: string) => void;
	onTabChange: (key: string) => void;
	collections: List<TrainingCollection>;
	selectedCollections: number[];
	tight?: boolean;
	tab: string;
}

const AddToCollection = ({
	onChange,
	handleCreateCollection,
	onTabChange,
	tight,
	tab,
	collections,
	selectedCollections,
}: AddToCollectionProps): JSX.Element => {
	const textInputRef = useRef(null);

	const handleCreate = () => {
		if (!textInputRef.current.value.trim()) {
			return;
		}

		handleCreateCollection(textInputRef.current.value);

		textInputRef.current.value = '';
	};

	const isSmallScreen = useSmallScreen();

	return (
		<Fragment>
			<Heading tight={tight}>
				<TabSwitcher
					onChange={onTabChange}
					active={tab}
					tabs={{
						my: t(`My`),
						team: t(`Team`),
						club: t(`Club`),
					}}
				/>
			</Heading>

			<CreateWrapper tight={tight}>
				{t(`New collection`)}
				<Row columns="1fr auto" align="center">
					<Input.Field
						ref={textInputRef}
						type="text"
						placeholder={t(`Collection title`)}
					/>
					<Button
						secondary
						large={!isSmallScreen}
						block={isSmallScreen}
						onClick={handleCreate}>
						{t('Create')}
					</Button>
				</Row>
			</CreateWrapper>
			<CollectionList
				collections={collections}
				selectedCollections={selectedCollections}
				onChange={onChange}
			/>
		</Fragment>
	);
};

export default AddToCollection;
