import { ReactNode } from 'react';

export const symbol_closed_caption: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h600q24 0 42 18t18 42v520q0 24-18 42t-42 18H180Zm0-60h600v-520H180v520Zm90-141h142q12.75 0 21.375-8.625T442-391v-20q0-9.857-6.571-16.429Q428.857-434 419-434h-4q-9.857 0-16.429 6.571Q392-420.857 392-411H290v-138h102q0 10 6.571 16.5Q405.143-526 415-526h4q9.857 0 16.429-6.571Q442-539.143 442-549v-20q0-12.75-8.625-21.375T412-599H270q-12.75 0-21.375 8.625T240-569v178q0 12.75 8.625 21.375T270-361Zm421-238H549q-12.75 0-21.375 8.625T519-569v178q0 12.75 8.625 21.375T549-361h142q12.75 0 21.375-8.625T721-391v-20q0-9.857-6.571-16.429Q707.857-434 698-434h-4q-9.857 0-16.429 6.571Q671-420.857 671-411H569v-138h102q0 10 6.571 16.5Q684.143-526 694-526h4q9.857 0 16.429-6.571Q721-539.143 721-549v-20q0-12.75-8.625-21.375T691-599ZM180-220v-520 520Z"
		/>
	</svg>
);
