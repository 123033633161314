import { useState } from 'react';
import styled, { css } from 'styled-components';

import * as palette from 'pkg/config/palette';

import Logo from 'components/Logo';

const Image = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;

	position: absolute;
	left: 0;
	top: 0;
`;

const DefaultImage = styled(Logo)`
	width: 100%;
	height: 100%;
	border-radius: var(--radius-2);

	position: absolute;
	left: 0;
	top: 0;
`;

const Wrapper = styled.div<{
	size: string;
	color: string;
}>`
	display: block;
	position: relative;

	&[data-is-branded='true'] {
		border-radius: var(--spacing-3);
		background-color: var(
			--palette-group-primary-color,
			var(--palette---palette-gray-300)
		);
	}

	&::before {
		content: '';
		display: block;
		padding-top: 100%;
		position: relative;
	}

	${(props) =>
		props.size &&
		css`
			width: ${props.size};
		`}
`;

type BadgeProps = {
	badgeUrl: string;
	branded?: boolean;
	size?: string;
	className?: string;
};

function Badge({ badgeUrl, branded, size = '100%', className }: BadgeProps) {
	const [isError, setIsError] = useState(false);

	const handleError = (): void => setIsError(true);
	const hasImage: boolean = badgeUrl && !isError;

	return (
		<Wrapper
			className={className}
			size={size}
			color={palette.gray[800]}
			data-is-branded={branded}>
			{hasImage ? (
				<Image src={badgeUrl} loading="lazy" onError={handleError} />
			) : (
				<DefaultImage data-testid="default-img" />
			)}
		</Wrapper>
	);
}

export default styled(Badge)``;
