import { ReactNode } from 'react';

export const symbol_password_2: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M151.765-454Q105-454 72.5-486.735 40-519.471 40-566.235 40-613 72.735-645.5q32.736-32.5 79.5-32.5Q199-678 231.5-645.265q32.5 32.736 32.5 79.5Q264-519 231.265-486.5q-32.736 32.5-79.5 32.5ZM110-200q-12.75 0-21.375-8.675Q80-217.351 80-230.175 80-243 88.625-251.5T110-260h740q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-200H110Zm369.765-255Q433-455 400.5-487.375T368-566q0-47.083 32.735-80.042Q433.471-679 480.235-679 527-679 559.5-646.042 592-613.083 592-566q0 46.25-32.735 78.625Q526.529-455 479.765-455Zm328 0Q761-455 728.5-487.375T696-566q0-47.083 32.735-80.042Q761.471-679 808.235-679 855-679 887.5-646.042 920-613.083 920-566q0 46.25-32.735 78.625Q854.529-455 807.765-455Z"
		/>
	</svg>
);
