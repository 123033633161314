import { t } from '@transifex/native';

import Session from 'pkg/models/session';
import EventUser from 'pkg/models/event_user';

import * as flashActions from 'pkg/actions/flashes';
import { REMOVE_MATCH } from 'pkg/actions/action-types';
import { show } from 'pkg/actions/flashes';
import * as eventService from 'pkg/actions/services/event.services';
import * as eventUserServices from 'pkg/actions/services/event_users.service';
import { normalizedDispatch } from 'pkg/actions/utils';
import { MatchPayload } from 'pkg/actions/matches';

import * as routes from 'pkg/router/routes';
import store from 'pkg/store/createStore';
import { replaceState } from 'pkg/router/state';
import { getTimeZoneByGmtString } from 'pkg/timezone';
import * as sdk from 'pkg/core/sdk';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import { EventFlags, EventTypes, RSVPInterval } from 'pkg/api/models/event';
import DateTime from 'pkg/datetime';

export const getEventUsers = async (eventId: number) => {
	const request = await eventService.getEventUsers(eventId);
	const res = await request.json();

	if (request.ok) {
		normalizedDispatch(res.records, [EventUser.normalizr()])(store.dispatch);

		return res.records;
	}

	return null;
};

export const fetchEventSessions = async (eventId: number) => {
	const request = await eventService.getEventSessions(eventId);
	const result = await request.json();

	if (request.ok) {
		normalizedDispatch(result.records, [Session.normalizr()])(store.dispatch);
	}
};

export const connectEventSession = async (
	eventId: number,
	sessionId: number
): Promise<boolean> => {
	const request = await eventService.connectEventSession(eventId, sessionId);

	if (request.ok) {
		show({
			title: t('Your session was successfully connected to the event!'),
		});

		return true;
	}

	show({
		title: t('Something went wrong'),
		message: t(
			`If you think this error isn't supposed to happen, please contact 360Player support.`
		),
	});

	return false;
};

interface CreateEventPayload {
	groupId: number;
	startsAt: number;
	endsAt: number;
	/**
	 * startsAtLocal is the date time string for the event in the event's timezone.
	 * this is basically a direct representation of the values in the form inputs, as opposed
	 * to the UNIX timestamp that startsAt is
	 */
	startsAtLocal?: string;
	/**
	 * endsAtLocal is the date time string for the event in the event's timezone.
	 * this is basically a direct representation of the values in the form inputs, as opposed
	 * to the UNIX timestamp that endsAt is
	 */
	endsAtLocal?: string;
	title: string;
	location: string;
	timezone: string;
	description?: string;
	physicalStrainDefault?: number;
	type?: string;
	notes?: string;
	isPrivate?: boolean;
	isPublic?: boolean;
	hideParticipants?: boolean;
	meetBeforeMinutes?: number;
	rsvpIntervalCount?: number;
	rsvpInterval?: RSVPInterval;
	flags?: EventFlags[];
	match?: MatchPayload;
}

export interface CreateEventData extends CreateEventPayload {
	match?: MatchPayload;
	selectedUsers?: models.user.User[];
	organizer: { [key: number]: boolean };
	sendInvites?: boolean;
	rsvpBefore?: boolean;
}

export const createEvent = async (
	organizationId: number,
	data: CreateEventData,
	attachments: models.attachment.Attachment[] = []
) => {
	const eventData: CreateEventPayload = {
		groupId: data.groupId,
		startsAt: data.startsAt,
		endsAt: data.endsAt,
		title: data.title,
		location: data.location,
		description: data.description,
		type: data.type,
		timezone: data.timezone ? getTimeZoneByGmtString(data.timezone).name : '',
		isPrivate: data.isPrivate,
		isPublic: data.isPublic,
		hideParticipants: data.hideParticipants,
		meetBeforeMinutes: data.meetBeforeMinutes,
		flags: data.flags,
	};

	eventData.startsAtLocal = DateTime.fromTimestamp(
		data.startsAt
	).toDateTimeString();
	eventData.endsAtLocal = DateTime.fromTimestamp(
		data.endsAt
	).toDateTimeString();

	if (data.type === EventTypes.Match || data.type === EventTypes.Practice) {
		eventData.physicalStrainDefault = data.physicalStrainDefault;
	}

	if (data.type === EventTypes.Match) {
		const matchStart = DateTime.fromTimestamp(
			data.match?.startsAt ? data.match?.startsAt : eventData.startsAt
		);

		eventData.match = {
			...data.match,
			startsAtLocal: matchStart.toDateTimeString(),
			location: data.match?.location || eventData.location,
		};
	}

	if (data.rsvpBefore) {
		eventData.rsvpInterval = data.rsvpInterval;
		eventData.rsvpIntervalCount = data.rsvpIntervalCount;
	}

	const req = await sdk.post(endpoints.Events.Create(), {}, eventData);
	if (!req.ok) {
		flashActions.show({
			title: t('Something went wrong'),
			message: t(
				"If you think this error isn't supposed to happen, please contact 360Player support."
			),
		});
		return;
	}

	const event = await req.json();

	attachments.forEach((a) => eventService.addAttachment(event.id, a.id));

	const shouldSendInvites = data.sendInvites;

	if (data.selectedUsers.length > 0) {
		await eventService.addUsersToEvent(event.id, {
			users: data.selectedUsers.map((u) => u.id),
			sendInvites: shouldSendInvites,
			organizer: data.organizer,
		});
	}

	replaceState(routes.Event.View(organizationId, event.id, 'overview'));
};

export interface UpdateEventPayload {
	id: number;
	startsAt?: number;
	endsAt?: number;
	/**
	 * startsAtLocal is the date time string for the event in the event's timezone.
	 * this is basically a direct representation of the values in the form inputs, as opposed
	 * to the UNIX timestamp that startsAt is
	 */
	startsAtLocal?: string;
	/**
	 * endsAtLocal is the date time string for the event in the event's timezone.
	 * this is basically a direct representation of the values in the form inputs, as opposed
	 * to the UNIX timestamp that endsAt is
	 */
	endsAtLocal?: string;
	title?: string;
	description?: string;
	location?: string;
	timezone?: string;
	physicalStrainDefault?: number;
	notes?: string;
	isPrivate?: boolean;
	isPublic?: boolean;
	hideParticipants?: boolean;
	status?: string;
	meetBeforeMinutes?: number;
	rsvpInterval?: RSVPInterval | '';
	rsvpIntervalCount?: number;
	flags?: EventFlags[];

	sendInvites?: boolean;
	selectedUsers?: models.user.User[];
	organizer?: { [key: number]: boolean };
	match?: MatchPayload;
}

// Note (liamoberg): Feels like this need a fair bit cleanup, maybe also the documentation in the API
export const updateEvent = async (
	event: models.event.Event,
	organizationId: number,
	data: UpdateEventPayload,
	newAttachments: models.attachment.Attachment[] = [],
	eventUsers: models.eventUser.EventUser[] = []
) => {
	if (data.timezone) {
		data.timezone = getTimeZoneByGmtString(data.timezone).name;
	}

	if (data.meetBeforeMinutes) {
		data.meetBeforeMinutes = data.meetBeforeMinutes;
	} else {
		data.meetBeforeMinutes = 0;
	}

	if (data.startsAt) {
		const startsAtDate = DateTime.fromTimestamp(data.startsAt);
		data.startsAtLocal = startsAtDate.toDateTimeString();
	}
	if (data.endsAt) {
		const endsAtDate = DateTime.fromTimestamp(data.endsAt);
		data.endsAtLocal = endsAtDate.toDateTimeString();
	}

	if (event.type === EventTypes.Match) {
		if (data.match.startsAt) {
			const matchStart = DateTime.fromTimestamp(data.match?.startsAt);
			data.match.startsAtLocal = matchStart.toDateTimeString();
		}
	} else {
		data.match = undefined;
	}

	const req = await sdk.patch(endpoints.Events.Update(event.id), {}, data);
	if (!req.ok) {
		return;
	}

	const updatedEvent = await req.json();

	await Promise.all(
		newAttachments.map(
			async (a) => await eventService.addAttachment(updatedEvent.id, a.id)
		)
	);

	const selectedUsers = data.selectedUsers
		?.map((u) => u.id)
		.sort((a, b) => a - b);

	const prevUsers = eventUsers
		.map((eventUser) => eventUser.userId)
		.sort((a, b) => a - b);

	const userDiff = JSON.stringify(selectedUsers) !== JSON.stringify(prevUsers);

	if (userDiff && data.selectedUsers) {
		const selectedUsersIds = data.selectedUsers.map((u) => u.id);

		const removeUsers = eventUsers
			.filter((eventUser) => !selectedUsersIds.includes(eventUser.userId))
			.map((eventUser) => eventUser.userId);

		const addUsers = selectedUsersIds.filter(
			(id) => !eventUsers.map((eventUser) => eventUser.userId).includes(id)
		);

		if (removeUsers.length > 0) {
			await eventUserServices.deleteEventUsers(updatedEvent.id, {
				users: removeUsers,
			});
		}

		if (addUsers.length > 0) {
			await eventUserServices.inviteEventUsers(updatedEvent.id, {
				users: addUsers,
				sendInvites: data.sendInvites,
				organizer: data.organizer,
			});
		}
	}

	replaceState(routes.Event.View(organizationId, updatedEvent.id, 'overview'));
};

export const addNotes = async (eventId: number, notes: string) => {
	const request = await sdk.patch(
		endpoints.Events.Update(eventId),
		{},
		{ notes }
	);
	const result = await request.json();

	if (!request.ok) {
		return [false, null];
	}

	return [true, result];
};

export const deleteEvent = async (eventId: number) => {
	const state = store.getState();

	const match = state.matches
		.get('entities')
		.find((entity) => entity.eventId === eventId);

	const req = await eventService.deleteEvent(eventId);

	if (!req.ok) {
		store.dispatch({
			type: 'EVENT_DELETE_ERROR',
		});

		return;
	}

	if (match) {
		store.dispatch({
			type: REMOVE_MATCH,
			matchId: match.id,
		});
	}

	show({
		title: t(`Event deleted`),
		message: t(`Your event was successfully deleted`),
	});
};

export async function deleteEventAttachment(
	eventId: number,
	attachmentId: number
) {
	const request = await sdk.destroy(
		endpoints.Events.RemoveAttachment(eventId, attachmentId)
	);

	if (request.ok) {
		return true;
	}

	show({
		title: t('Something went wrong'),
	});

	return false;
}

export interface EventExportPayload {
	group_ids: string;
	from: number;
	to: number;
}

export async function eventExport(
	payload: EventExportPayload
): Promise<[boolean, string]> {
	const req = await sdk.post(endpoints.Events.Export(), payload);

	if (req.ok) {
		const text = await req.text();

		return [true, text];
	}

	return [false, ''];
}
