import { ReactNode } from 'react';

export const symbol_view_headline: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M189-372q-12 0-20.5-9t-8.5-21q0-13 8.5-21.5T190-432h581q12 0 20.5 9t8.5 21q0 13-8.5 21.5T770-372H189Zm0 160q-12 0-20.5-9t-8.5-21q0-13 8.5-21.5T190-272h581q12 0 20.5 9t8.5 21q0 13-8.5 21.5T770-212H189Zm0-316q-12 0-20.5-9t-8.5-21q0-13 8.5-21.5T190-588h581q12 0 20.5 9t8.5 21q0 13-8.5 21.5T770-528H189Zm0-160q-12 0-20.5-9t-8.5-21q0-13 8.5-21.5T190-748h581q12 0 20.5 9t8.5 21q0 13-8.5 21.5T770-688H189Z"
		/>
	</svg>
);
