import { ReactNode } from 'react';

export const symbol_book_4: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M240-80q-50 0-85-35t-35-85v-560q0-50 35-85t85-35h360q24.75 0 42.375 17.625T660-820v500q0 24.75-17.625 42.375T600-260H240q-25.5 0-42.75 17.25T180-200q0 25.5 17.25 42.75T240-140h540v-630q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T840-770v630q0 24.75-17.625 42.375T780-80H240Zm100-240h260v-500H340v500Zm-60 0v-500h-40q-26 0-43 17.25T180-760v457q13-8 27.883-12.5Q222.767-320 240-320h40ZM180-820v517-517Z"
		/>
	</svg>
);
