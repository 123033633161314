import { t } from '@transifex/native';

import { pushState } from 'pkg/router/state';
import * as routes from 'pkg/router/routes';
import {
	useCurrentMembership,
	useCurrentOrganization,
	useCurrentUser,
} from 'pkg/identity';
import * as models from 'pkg/api/models';

import PostForm from 'containers/group/post/Form';
import OrganizationDialog from 'containers/group/settings/organization/Dialog';

import CardWrapper, * as Card from 'components/Card';
import Modal from 'components/modal';

import ActivityForm from 'components/user/activity/Form';
import AssessmentCreate from 'components/assessment/Create';
import JoinGroupModal from 'components/group/join-modal';
import VideoModal from 'components/video-library/modals/Video';

const ActionModal = ({ actionModal, setActionModal, groups, defaultGroup }) => {
	const org = useCurrentOrganization();
	const user = useCurrentUser();
	const membership = useCurrentMembership();
	const isAdminOrStaff = models.membership.isAdminOrStaff(membership);

	const closeActionModal = async (p) => {
		if (p) {
			await p;
		}

		setActionModal(null);
	};

	const handleModalSuccess = (href) => {
		setActionModal(null);
		href && pushState(href);
	};

	const handleVideoSave = (video) => {
		pushState(routes.Video.Show(org.id, video.id));
	};

	switch (actionModal) {
		case 'action-post':
			return (
				<PostForm
					key="action-modal"
					groupId={membership.groupId}
					isAdminOrStaffForGroup={isAdminOrStaff}
					onClose={closeActionModal}
				/>
			);

		case 'action-training':
			return (
				<Modal key="action-modal" onClose={closeActionModal}>
					<CardWrapper>
						<Card.Header>
							<Card.Heading>{t('Log training')}</Card.Heading>
						</Card.Header>
						<Card.Divider />

						<ActivityForm
							contentOnly
							userId={user.id}
							handleSuccess={handleModalSuccess}
						/>
					</CardWrapper>
				</Modal>
			);

		case 'action-video':
			return (
				<VideoModal
					key="action-add-video"
					onClose={closeActionModal}
					afterSave={handleVideoSave}
				/>
			);

		case 'action-assessment':
			return (
				<AssessmentCreate
					userId={user.id}
					userRole={1}
					onClose={closeActionModal}
				/>
			);

		case 'group-code':
			return <JoinGroupModal onClose={closeActionModal} />;

		case 'action-create-group':
			return (
				<OrganizationDialog
					type="create"
					groups={groups}
					group={defaultGroup}
					defaultSelected={defaultGroup}
					onClose={closeActionModal}
				/>
			);

		default:
			return null;
	}
};

export default ActionModal;
