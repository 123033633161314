import { t } from '@transifex/native';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import User from 'pkg/models/user';

import * as selectors from 'pkg/selectors';
import * as actions from 'pkg/actions';
import { RootState } from 'pkg/reducers';
import * as models from 'pkg/api/models';

import SectionTitle from 'components/SectionTitle';
import CardAnatomy, * as Card from 'components/Card';
import Icon from 'components/icon';

import Column from 'components/layout/column';
import UserLogList from 'components/user/UserLogList';
import TextField from 'components/form/TextInput';

const CardSubHeading = styled(Card.SubHeading)`
	width: 100%;
	font-size: var(--font-size-sm);
	font-weight: var(--font-weight-normal);
`;

const MessageForm = styled.div`
	display: flex;
	padding: var(--spacing-5) var(--spacing-4) var(--spacing-5) var(--spacing-6);
	align-items: center;
	justify-content: space-between;
	text-align: right;
`;

const FormButton = styled(Icon)`
	font-size: var(--font-size-xl);
	margin-left: var(--spacing-4);
	color: var(--palette-blue-500);
`;

interface LogsProps {
	userId: number;
	activeMembership: models.membership.Membership;
}

const Logs: React.FC<React.PropsWithChildren<LogsProps>> = ({
	userId,
	activeMembership,
}) => {
	const dispatch = useDispatch();

	const [message, setMessage] = useState<string>('');

	const user: User = useSelector((state: RootState) =>
		selectors.users.find(state, userId)
	);

	const handleChange = (e: React.FormEvent<HTMLInputElement>): void =>
		setMessage(e.currentTarget.value);

	const handleSave = (): void => {
		if (message.trim().length === 0) {
			return;
		}

		dispatch(
			actions.userLogs.postUserLog(userId, {
				content: message,
				groupId: activeMembership.groupId,
				authorId: activeMembership.userId,
			})
		);
	};

	return (
		<Column>
			<SectionTitle>{t(`Notes`)}</SectionTitle>
			<CardAnatomy $noBorder>
				<Card.Header>
					<CardSubHeading>
						{t(`Player notes are only visible to you and other admins.`)}
					</CardSubHeading>
				</Card.Header>
				<Card.Divider />
				<MessageForm>
					<TextField onChange={handleChange} />
					<FormButton onClick={handleSave} name="add" />
				</MessageForm>
				<Card.Divider />
				<UserLogList
					user={user}
					groupId={activeMembership.groupId}
					expanded={true}
				/>
			</CardAnatomy>
		</Column>
	);
};

export default Logs;
