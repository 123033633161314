import { ReactNode } from 'react';

export const symbol_waving_hand: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M690-40q-12 0-21-8.897-9-8.896-9-21.5Q660-83 668.625-91.5 677.25-100 690-100q72 0 121-49.5T860-270q0-13 8.69-21.5 8.689-8.5 21-8.5 12.31 0 21.31 8.78 9 8.781 9 21.22 0 96-67.282 163T690-40ZM70-659q-12.439 0-21.22-9Q40-677 40-689q0-95.851 67.282-163.425Q174.564-920 270-920q12.854 0 21.927 9T301-889.69q0 12.311-8.5 21Q284-860 271-860q-70.538 0-120.769 49.5Q100-761 100-690q0 13.175-8.625 22.088Q82.75-659 70-659Zm682-114q9 9 9 21t-9 21L497-476q-9 9-21.1 8.583-12.1-.416-20.9-9.583-9-8.8-9-20.9 0-12.1 9-21.1l255-254q9-9 21-9t21 9Zm76 131.842q9 8.842 9 21T828-599L601-372q-9 9-21 8.5t-21-9.109Q550-382 550-394q0-12 9-21l226-226q9.067-9 21.533-9Q819-650 828-641.158ZM195-199q-91-91-91.5-218T194-635l101-101q9-9 21-9t21 9l21 21q12 12 19.5 28t8.5 30l163-164q9.067-9 21.533-9Q583-830 592-820.947q9 9.052 9 21.5Q601-787 592-778L389-575l-65 65 26 26q42 42 40.5 100.5t-43.604 100.604L346-282q-9 9-21 8.5t-21-9.109q-9-9.391-9-21.491t9-20.9l1-1q25-25 25.5-58.5T307-442l-47-47q-9-9.067-9-21.533Q251-523 260-532l56-55q17-17 17-42.5T316-672l-80 80q-73 73-72.5 175T237-242q74 74 177 75.5T590-238l241-241q9.067-9 21.533-9Q865-488 874-479.158q9 8.842 9 21T874-437L633-196q-91 91-219 89.5T195-199Zm217-217Z"
		/>
	</svg>
);
