import { Dispatch, SetStateAction, useState } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import DateTime from 'pkg/datetime';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import { useCollection } from 'pkg/api/use_collection';

import PublishedPeriodCardListItem from 'routes/scheduling/templates/modals/publish/published-period-card-list';
import { StatePublishPeriod } from 'routes/scheduling/templates/hooks/useManagePublishedPeriods';

import Icon from 'components/icon';
import Label from 'components/label';

import * as Input from 'components/form/inputs';
import Column from 'components/layout/column';
import Row from 'components/layout/row';

import Button, { ButtonGroup } from 'design/button';

import * as css from './styles.css';

interface PublishedPeriodsProps {
	showPublishedPeriods: boolean;

	publishedPeriod: StatePublishPeriod;
	statePublishedPeriods: StatePublishPeriod[];
	setStatePublishedPeriods: Dispatch<SetStateAction<StatePublishPeriod[]>>;

	index: number;
}

const PublishedPeriod = ({
	index,
	showPublishedPeriods,
	publishedPeriod,
	statePublishedPeriods,
	setStatePublishedPeriods,
}: PublishedPeriodsProps) => {
	const startsAtDate = new Date(publishedPeriod.startsAt * 1000);
	const endsAtDate = new Date(publishedPeriod.endsAt * 1000);

	const [localDates, setLocalDates] = useState<Date[]>(
		publishedPeriod.startsAt && publishedPeriod.endsAt
			? [startsAtDate, endsAtDate]
			: []
	);
	const [renderedDate, setRenderedDate] = useState(new DateTime(startsAtDate));

	const { records: publishedPeriods } =
		useCollection<models.schedule.PublishedPeriod>(
			endpoints.Schedule.PublishedPeriods(),
			{
				queryParams: new URLSearchParams({
					group_id: publishedPeriod.groupId.toString(),
					from: (renderedDate.startOfMonth / 1000).toString(),
					end: (renderedDate.endOfMonth / 1000).toString(),
				}),
			}
		);

	const highlightedDates: Date[][] = publishedPeriods.map((period) => {
		return [
			new Date(period.startsAt * 1000),
			new Date(new Date(period.endsAt * 1000).setHours(23, 59, 59)),
		];
	});

	const handleRenderedDateChange = (date: Date) => {
		setRenderedDate(new DateTime(date));
	};

	const handleStartDateChange = (date: Date) => {
		setLocalDates((prev) => {
			if (+date > +prev[1]) {
				return [date, date];
			}
			return [date, prev[1]];
		});
	};

	const handleEndDateChange = (date: Date) => {
		setLocalDates((prev) => {
			return [prev[0], date];
		});
	};

	const handleRemove = () => {
		setStatePublishedPeriods((prev) => prev.filter((_, i) => i !== index));
	};

	const handleSave = () => {
		setStatePublishedPeriods((prev) => {
			const newState = [...prev];

			newState[index].startsAt = new DateTime(localDates[0]).getUnixTimestamp();
			newState[index].endsAt = new DateTime(localDates[1]).getUnixTimestamp();
			newState[index].editMode = false;

			return newState;
		});
	};

	const handleEdit = () => {
		setStatePublishedPeriods((prev) => {
			const newState = [...prev];
			newState[index].editMode = !newState[index].editMode;

			return newState;
		});
	};

	const handleCancel = () => {
		setStatePublishedPeriods((prev) => {
			const newState = [...prev];
			newState[index].editMode = !newState[index].editMode;

			return newState;
		});
	};

	const overlaps = statePublishedPeriods
		.filter((_, innerIndex) => index !== innerIndex)
		.find(
			(p) =>
				(publishedPeriod.startsAt >= p.startsAt &&
					publishedPeriod.startsAt <= p.endsAt) ||
				(publishedPeriod.endsAt >= p.startsAt &&
					publishedPeriod.endsAt <= p.endsAt)
		);

	if (!publishedPeriod.editMode) {
		return (
			<PublishedPeriodCardListItem
				publishedPeriod={publishedPeriod}
				showPublishedPeriods={showPublishedPeriods}
				actions={
					<Row align="center">
						{overlaps && <Label color="orange">{t('Overlaps')}</Label>}
						<Button
							secondary
							label={t('Edit')}
							icon="edit"
							onClick={handleEdit}
						/>
					</Row>
				}
			/>
		);
	}

	return (
		<div className={css['date-card']}>
			<Column>
				<Row spacing={styles.spacing._6} columns="1fr auto 1fr">
					<Input.Group label={t('Start time')}>
						<div>
							<Input.DateTimePicker
								date={localDates[0]}
								hideTimeInput
								onDateChange={handleStartDateChange}
								hideValue={!localDates[0]}
								placeholder={t('Select day')}
								onRenderedDateChange={handleRenderedDateChange}
								highlightedDates={highlightedDates}
							/>
						</div>
					</Input.Group>
					<Icon className={css.icon} name="arrow-right" size={1.6} />
					<Input.Group label={t('End time')}>
						<div>
							<Input.DateTimePicker
								date={localDates[1] || localDates[0]}
								hideTimeInput
								onDateChange={handleEndDateChange}
								disableDatesBefore={localDates[0]}
								hideValue={!localDates[1]}
								placeholder={t('Select day')}
								onRenderedDateChange={handleRenderedDateChange}
								highlightedDates={highlightedDates}
							/>
						</div>
					</Input.Group>
				</Row>
				<div className={css.buttons}>
					<Button label={t('Remove')} caution onClick={handleRemove} />
					<ButtonGroup>
						<Button label={t('Cancel')} onClick={handleCancel} />
						<Button
							label={t('Save')}
							primary
							onClick={handleSave}
							disabled={!localDates[0] || !localDates[1]}
						/>
					</ButtonGroup>
				</div>
			</Column>
		</div>
	);
};

export default PublishedPeriod;
