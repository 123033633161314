import { ReactNode } from 'react';

export const symbol_electric_meter: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M360-110v-72q-106-37-173-129.5T120-522q0-75 28.5-140.5t77-114q48.5-48.5 114-77T479-882q74 0 140 28.5t115 77q49 48.5 77.5 114T840-522q0 118-67.5 209.5T600-183v73q0 12.75-8.675 21.375Q582.649-80 569.825-80 557-80 548.5-88.625T540-110v-57q-15 2-29.878 3.5t-31.244 1.5Q464-162 449-163.5t-29-3.5v57q0 12.75-8.675 21.375Q402.649-80 389.825-80 377-80 368.5-88.625T360-110Zm120-110q125 0 212.5-87.5T780-520q0-125-87.5-212.5T480-820q-125 0-212.5 87.5T180-520q0 125 87.5 212.5T480-220ZM350-610h260q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T610-670H350q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T350-610Zm104 220-34 34q-10 9.957-10 22.979Q410-320 419.967-310q9.966 10 23 10Q456-300 466-310l69-69q9-9 9-21t-9-21l-29-29 34-34q10-9.957 10-22.979Q550-520 540.033-530q-9.966-10-23-10Q504-540 494-530l-69 69q-9 9-9 21t9 21l29 29Zm26-130Z"
		/>
	</svg>
);
