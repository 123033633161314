import { List, Record } from 'immutable';
import { schema } from 'normalizr';

import User from 'pkg/models/user';

export const ratingSchema = new schema.Entity('ratings');

const RatingRecord = Record(
	{
		id: 0,
		groupId: 0,
		hiddenScores: false,
		isPrimaryRating: false,
		maturityAdjustment: 0,
		positionScores: new List(),
		ratingScores: new List(),
		primaryFoot: '',
		raterUserId: 0,
		suggestedPositionId: 0,
		userId: 0,
		author: new User(),
		createdAt: 0,
	},
	'Rating'
);

class Rating extends RatingRecord {
	static normalizr() {
		return ratingSchema;
	}
}

export default Rating;
