import { Fragment } from 'react';
import { useT } from '@transifex/react';
import { useMediaQuery } from 'react-responsive';

import { FontWeights } from 'pkg/config/fonts';
import { toMedium } from 'pkg/config/breakpoints';

import { useAccountIdentity } from 'pkg/identity/account';
import * as routes from 'pkg/router/routes';
import { cssClasses, cssVarList } from 'pkg/css/utils';
import * as models from 'pkg/api/models';
import * as actions from 'pkg/actions';
import store from 'pkg/store/createStore';

import Badge from 'components/Badge';
import MaterialSymbol from 'components/material-symbols';

import * as contextSwitchStyles from 'components/navigation/menus/context_switch/styles.css';
import * as orgSwitchItemStyles from 'components/navigation/menus/organization_switcher/list_item/styles.css';
import * as groupSwitchStyles from 'components/navigation/menus/group_switcher/styles.css';
import Row from 'components/layout/row';

import * as Context from 'design/context_menu';
import Button from 'design/button';

import * as css from './styles.css';

export default function AuthGuardHeader() {
	const t = useT();

	const isSmallScreen = useMediaQuery({ maxWidth: toMedium });

	const accountIdentity = useAccountIdentity();

	const handleClick = (organizationId: number) => {
		if (organizationId !== accountIdentity.organization.id) {
			window.location.assign(routes.Organization.Home(organizationId));
		}
	};

	const handleLogout = () => actions.auth.logout()(store.dispatch);

	const toggle = isSmallScreen ? (
		<div className={css.smallScreenTrigger}>
			<Badge badgeUrl={accountIdentity.organization.profileImageUrl} />
		</div>
	) : (
		<Row
			align="center"
			justifyContent="center"
			columns="auto 1fr auto"
			className={cssClasses(groupSwitchStyles.trigger, css.orgTrigger)}>
			<Badge
				size="30px"
				badgeUrl={accountIdentity.organization.profileImageUrl}
			/>
			<strong className={groupSwitchStyles.triggerName}>
				{accountIdentity.organization.name}
			</strong>
			<MaterialSymbol actualSize scale={1.7} variant="expand_more" />
		</Row>
	);

	return (
		<Row
			autoColumns="max-content"
			align="center"
			justifyContent="space-between"
			className={css.wrapper}>
			<div className={contextSwitchStyles.wrapper}>
				<Context.Menu toggleWith={toggle}>
					<Context.SegmentHeading>{t('Organisations')}</Context.SegmentHeading>
					{accountIdentity.organizations.map((org, index, arr) => {
						const primaryColor = models.group.getPrimaryColorStylesheetString(
							org as models.group.Group
						);

						return (
							<Fragment key={index}>
								<Context.Item
									skipFirstItemTopSpacing
									fontWeight={FontWeights.SemiBold}
									tight
									onClick={() => handleClick(org.id)}>
									<Row columns="40px 1fr" align="center">
										<div
											className={orgSwitchItemStyles.badgeWrapper}
											style={cssVarList({
												primaryColor,
											})}>
											<div className={orgSwitchItemStyles.badgeInnerWrapper}>
												<Badge
													badgeUrl={org.profileImageUrl}
													className={orgSwitchItemStyles.badge}
												/>
											</div>
										</div>
										<span>{org.name}</span>
									</Row>
								</Context.Item>
								{index !== arr.length && <Context.Divider tight />}
							</Fragment>
						);
					})}
				</Context.Menu>
			</div>
			<Button
				small={isSmallScreen}
				icon="logout"
				label={t('Log out')}
				onClick={handleLogout}
			/>
		</Row>
	);
}
