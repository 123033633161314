import { ReactNode } from 'react';

export const symbol_no_food: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m917-707-49 462q-2 19-19 25t-31.25-8.25Q813-233 810.5-239.212 808-245.423 809-252l42-428H481q-13 0-21-8.675-8-8.676-8-21.5 0-12.825 8.625-21.325T482-740h169v-150q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T711-890v150h176q13.5 0 22.75 9.75T917-707ZM636-409ZM812-63 47-827q-9-9.067-9-21.533Q38-861 47.053-870q9.052-9 21.5-9Q81-879 90-870l765 765q9 9 9 21t-9.053 21q-9.052 9-21.5 9Q821-54 812-63ZM70-176q-12.75 0-21.375-8.675Q40-193.351 40-206.175 40-219 48.625-227.5T70-236h548q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T618-176H70Zm0 136q-12.75 0-21.375-8.675Q40-57.351 40-70.175 40-83 48.625-91.5T70-100h548q12.75 0 21.375 8.675Q648-82.649 648-69.825 648-57 639.375-48.5T618-40H70Zm360-490v60q-20-4-41.5-5.5T344-477q-101 0-157 31t-75 82h484l60 60H76q-13.818 0-22.91-10.5Q44-325 46-339q9-57 36.5-95.5t68-61q40.5-22.5 90.5-32t103-9.5q23 0 44.5 2t41.5 5Zm-86 166Z"
		/>
	</svg>
);
