import { ReactNode } from 'react';

export const symbol_move_to_inbox: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M450-538v-159q0-13 8.5-21.5T480-727q13 0 21.5 8.5T510-697v159l59-59q5-5 10.5-7t11-2q5.5 0 11.5 2t10 7q9 9 9 21.5t-9 21.5L501-443q-5 5-10 7t-11 2q-6 0-11-2t-10-7L348-554q-4-5-6.5-10.5t-2-11q.5-5.5 3-11.5t6.5-10q9-9 21.5-9t21.5 9l58 59ZM180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-60h600v-136H634q-26 40-67.5 61.5T480-233q-45 0-86.5-21.5T326-316H180v136Zm300-113q35 0 64.5-17.5T591-359q7-10 18.5-13.5T633-376h147v-404H180v404h147q12 0 23.5 3.5T369-359q17 31 46.5 48.5T480-293ZM180-180h600-600Z"
		/>
	</svg>
);
