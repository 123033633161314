import { ReactNode } from 'react';

export const symbol_text_fields: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M339.882-160Q319-160 304.5-174.583 290-189.167 290-210v-490H130q-20.833 0-35.417-14.618Q80-729.235 80-750.118 80-771 94.583-785.5 109.167-800 130-800h420q20.833 0 35.417 14.618Q600-770.765 600-749.882 600-729 585.417-714.5 570.833-700 550-700H390v490q0 20.833-14.618 35.417Q360.765-160 339.882-160Zm360 0Q679-160 664.5-174.583 650-189.167 650-210v-290h-80q-20.833 0-35.417-14.618Q520-529.235 520-550.118 520-571 534.583-585.5 549.167-600 570-600h260q20.833 0 35.417 14.618Q880-570.765 880-549.882 880-529 865.417-514.5 850.833-500 830-500h-80v290q0 20.833-14.618 35.417Q720.765-160 699.882-160Z"
		/>
	</svg>
);
