import { ReactNode } from 'react';

export const symbol_motion_mode: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M200-752v544q-17-18.36-32-38.88-15-20.52-28-42.12v-382q13-21.6 28-42.12 15-20.52 32-38.88Zm140-92v728q-21-8.4-41-18.6-20-10.2-39-23.4v-644q19-13.2 39-23.4t41-18.6Zm240 741v-754q126 33 208 136.5T870-480q0 137-82 240.5T580-103ZM480-90q-20 0-40-2t-40-6v-764q20-4 40-6t40-2q11 0 20.667.5 9.666.5 19.333 1.5v776q-9.667 1-19.333 1.5Q491-90 480-90Z"
		/>
	</svg>
);
