import { Fragment } from 'react';
import { t } from '@transifex/native';

import { getGmtStrings, validateTimeZone } from 'pkg/timezone';

import Icon from 'components/icon';

import * as Input from 'components/form/inputs';
import Row from 'components/layout/row';
import SimpleAutoComplete, {
	SimpleAutoCompleteSuggestion,
} from 'components/form/inputs/simple-autocomplete';

interface TimeZoneSearchProps {
	timezone: string;

	onSelect: (value: string) => void;
	onBlur: () => void;
}

const TimeZoneSearch = ({
	timezone,
	onSelect,
	onBlur,
}: TimeZoneSearchProps) => {
	const suggestions: SimpleAutoCompleteSuggestion = {
		items: getGmtStrings(),
		label: t('Time zone'),
		onSelect: (tz: string) => onSelect(tz),
		renderWith: (item: string) => (
			<Row columns="auto 1fr" align="center">
				<Icon name="clock" size={1.3} />
				<span>{item}</span>
			</Row>
		),
	};

	const handleBlur = () => {
		if (timezone && validateTimeZone(timezone)) {
			onBlur();
		}
	};

	const validate = (timezone: string) => {
		if (
			(timezone.length > 0 && !validateTimeZone(timezone)) ||
			timezone.length === 0
		) {
			return false;
		}

		return true;
	};

	return (
		<Fragment>
			<SimpleAutoComplete
				name="timezone"
				autoFocus
				validate={validate}
				placeholder={t('Select time zone')}
				suggestions={suggestions}
				onBlur={handleBlur}>
				<Input.Prefix inline>
					<Icon name="search" />
				</Input.Prefix>
			</SimpleAutoComplete>
		</Fragment>
	);
};

export default TimeZoneSearch;
