import { ReactNode } from 'react';

export const symbol_device_unknown: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-278q-14.571 0-25.286-10.714Q444-299.429 444-314q0-14.571 10.714-25.286Q465.429-350 480-350q14.571 0 25.286 10.714Q516-328.571 516-314q0 14.571-10.714 25.286Q494.571-278 480-278ZM379-595q-8-4-11.5-12.5T369-624q17-31 46.5-50t64.5-19q51 0 84 30.5t33 79.5q0 32-17.5 58T536-480q-12 9-20.5 21.5T506-431q-1 11-8 17.5t-18 6.5q-11 0-17.5-7.245-6.5-7.246-6.5-18.113Q456-455 467-475q11-20 31-33 20-14 34.5-32.5T547-583q0-26-20-43.5T480-644q-20 0-37 11t-28 28q-5 10-15.485 12.5Q389.029-590 379-595ZM260-40q-24 0-42-18t-18-42v-760q0-24 18-42t42-18h440q24 0 42 18t18 42v760q0 24-18 42t-42 18H260Zm0-90v30h440v-30H260Zm0-60h440v-580H260v580Zm0-640h440v-30H260v30Zm0 0v-30 30Zm0 700v30-30Z"
		/>
	</svg>
);
