import { ReactNode } from 'react';

export const symbol_new_label: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M600-200H470q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T470-260h141l154-220-154-220H180v150q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T120-550v-150q0-24.75 17.625-42.375T180-760h420q22 0 40 11.5t31 29.5l145 204q11 15.972 11 34.986Q827-461 816-445L671-241q-13 18-31 29.5T600-200ZM473-480ZM200-280h-90q-12.75 0-21.375-8.675Q80-297.351 80-310.175 80-323 88.625-331.5T110-340h90v-90q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T260-430v90h90q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T350-280h-90v90q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T200-190v-90Z"
		/>
	</svg>
);
