import { CSSProperties, ReactElement, useCallback, useRef } from 'react';
import Editor from 'react-avatar-editor';

import * as styles from 'pkg/config/styles';

import useMixedState from 'pkg/hooks/useMixedState';
import * as numbers from 'pkg/numbers';
import ColorConverter from 'pkg/colorconverter';
import { cssClasses } from 'pkg/css/utils';

import * as css from './style.css';

export type LoadableImage = File | string;
export type SaveableImage = Blob | string;

export enum ImageEditorFormat {
	Base64,
	Blob,
}

interface CurrentState {
	scale: number;
	rotation: number;
	image?: LoadableImage;
}

interface ImageEditorHook {
	canvas: ReactElement;
	hasImage: boolean;

	load: (image: LoadableImage) => Promise<void>;
	unload: () => Promise<void>;
	save: () => Promise<void>;

	rotate: (degrees: number) => void;
	scale: (scale: number) => void;
}

interface ImageEditorProps {
	width: number;
	height: number;

	// Image width sets the *actual* image width whereas width and height is the size of the editor.
	// It's height is calculated using the aspect ratio from height / width.
	imageWidth?: number;

	minScale?: number;
	maxScale?: number;

	transparentCanvas?: boolean;
	backgroundColor?: string;
	maskColor?: string;
	maskOpacity?: number;
	gutterSize?: number;
	gutterRoundness?: number;

	saveFormat?: ImageEditorFormat;

	onSave: (image: SaveableImage) => Promise<void>;
	onSuccess?: (image?: LoadableImage) => void;
	onFailure?: () => void;
}

export function useImageEditor({
	width,
	height,
	imageWidth,

	minScale = 0,
	maxScale = 10,

	transparentCanvas,
	backgroundColor = styles.palette.white,
	maskColor = styles.palette.white,
	maskOpacity = 0.6,
	gutterSize = 0,
	gutterRoundness = 0,

	saveFormat = ImageEditorFormat.Blob,
	onSave,
	onSuccess,
	onFailure,
}: ImageEditorProps): ImageEditorHook {
	const editorRef = useRef<Editor>();

	const [current, set] = useMixedState<CurrentState>({
		scale: 1,
		rotation: 0,
	});

	if (!imageWidth) {
		imageWidth = width;
	}

	const imageAspectRatio =
		Math.round((height / width) * 100 + Number.EPSILON) / 100;

	let imageHeight = imageWidth * imageAspectRatio;

	const handleLoadSuccess = () => {
		if (onSuccess) {
			onSuccess(current.image);
		}
	};

	const handleLoadFailure = () => {
		if (onFailure) {
			onFailure();
		}
	};

	const load = async (image: LoadableImage) => set({ image, scale: 1 });

	const unload = async () => set({ image: null, scale: 1 });

	const save = useCallback(async () => {
		if (editorRef.current) {
			const canvas = editorRef.current.getImageScaledToCanvas();

			let image: SaveableImage;

			if (saveFormat === ImageEditorFormat.Base64) {
				image = await Promise.resolve(
					canvas.toDataURL('image/png').replace('data:image/png;base64,', '')
				);
			} else {
				image = await new Promise((resolve) => canvas.toBlob(resolve));
			}

			if (onSave) {
				onSave(image);
			}
		}
	}, [editorRef.current]);

	const rotate = (rotation: number) => {
		set({ rotation: numbers.clamp(Math.abs((360 + rotation) % 360), 0, 360) });
	};

	const scale = (scale: number) => {
		set({ scale: numbers.clamp(scale, minScale, maxScale) });
	};

	const [r, g, b] = ColorConverter.from(maskColor).toRGB() as number[];
	const maskColorValues: number[] = [r, g, b, maskOpacity];

	imageWidth -= gutterSize * 2;
	imageHeight -= gutterSize * 2;

	const editorStyles = {
		width: `${width}px`,
		height: `${height}px`,
	};

	const backgroundColorValues = {
		'--bg': backgroundColor,
		...editorStyles,
	};

	const canvas = current.image ? (
		<div
			className={cssClasses(
				css.wrapper,
				transparentCanvas ? css.transparent : null
			)}
			style={backgroundColorValues as CSSProperties}>
			<Editor
				ref={editorRef}
				image={current.image}
				width={imageWidth}
				height={imageHeight}
				border={gutterSize}
				scale={current.scale}
				rotate={current.rotation}
				color={maskColorValues}
				crossOrigin="anonymous"
				borderRadius={gutterRoundness}
				onLoadSuccess={handleLoadSuccess}
				onLoadFailure={handleLoadFailure}
				style={editorStyles}
			/>
		</div>
	) : null;

	return {
		canvas,
		hasImage: !!current.image,

		load,
		unload,
		save,

		rotate,
		scale,
	};
}

function blobToBase64(blob: Blob): Promise<string> {
	return new Promise((resolve) => {
		const reader = new FileReader();

		reader.onloadend = () => resolve(reader.result as string);
		reader.readAsDataURL(blob);
	});
}

export async function imagePreviewURL(image: SaveableImage): Promise<string> {
	if (image instanceof Blob) {
		const data = await blobToBase64(image);

		return data;
	} else {
		return `data:image/png;base64,${image}`;
	}
}
