import { ReactNode } from 'react';

export const symbol_water_ph: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M400-870q5 0 10.132 2 5.131 2 9.868 6 45 41 97.5 92t97 109Q659-603 689-540t31 129q0 13-8.912 22-8.913 9-22.088 9-11.9 0-19.95-9-8.05-9-9.05-21-2-48-21.5-93T593-588q-33-50-81.5-103T400-800Q273-688 206.5-587.5T140-408q0 97 56.5 171T350-145q17 3 24 14t4.964 22.083q-2.035 11.084-9.5 19Q362-82 350-84q-123-18-196.5-110T80-408q0-65.005 30-129.503Q140-602 184.921-660.532q44.922-58.532 97.5-110Q335-822 380-862q4.737-4 9.868-6Q395-870 400-870Zm0 400Zm192 310H488v56q0 10.4-6.8 17.2Q474.4-80 464-80q-10.4 0-17.2-6.8-6.8-6.8-6.8-17.2v-194q0-9 7-15.5t16-6.5h129q20 0 34 14t14 34v64q0 20-14 34t-34 14Zm134-23v79q0 10.4-6.8 17.2Q712.4-80 702-80q-10.4 0-17.2-6.8-6.8-6.8-6.8-17.2v-192q0-10.4 6.8-17.2 6.8-6.8 17.2-6.8 10.4 0 17.2 6.8 6.8 6.8 6.8 17.2v65h108v-65q0-10.4 6.8-17.2 6.8-6.8 17.2-6.8 10.4 0 17.2 6.8 6.8 6.8 6.8 17.2v192q0 10.4-6.8 17.2Q868.4-80 858-80q-10.4 0-17.2-6.8-6.8-6.8-6.8-17.2v-79H726Zm-238-25h104v-64H488v64Z"
		/>
	</svg>
);
