import { ReactNode } from 'react';

export const symbol_stethoscope: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M540-81q-112 0-186-78.5T280-347v-35q-85-11-142.5-75.711T80-610v-200q0-12.75 8.625-21.375T110-840h90v-10q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T260-850v80q0 12-8.675 21-8.676 9-21.5 9-12.825 0-21.325-8.625T200-770v-10h-60v170q0 71 49.5 120.5T310-440q71 0 120.5-49.5T480-610v-170h-60v10q0 12-8.675 21-8.676 9-21.5 9-12.825 0-21.325-8.625T360-770v-80q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T420-850v10h90q12.75 0 21.375 8.625T540-810v200q0 87.578-57.5 152.289Q425-393 340-382v35q0 85 56.5 145.5T540-141q81 0 140.5-60.152T740-347.235V-424q-35-10-57.5-39T660-530q0-45.833 32.118-77.917 32.117-32.083 78-32.083Q816-640 848-607.917q32 32.084 32 77.917 0 38-22.5 67T800-424v77q0 111-76.5 188.5T540-81Zm229.825-399Q791-480 805.5-494.325q14.5-14.324 14.5-35.5Q820-551 805.675-565.5q-14.324-14.5-35.5-14.5Q749-580 734.5-565.675q-14.5 14.324-14.5 35.5Q720-509 734.325-494.5q14.324 14.5 35.5 14.5Zm.175-50Z"
		/>
	</svg>
);
