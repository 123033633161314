import { ReactNode } from 'react';

export const symbol_photo_frame: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M190-120q-12.75 0-21.375-8.625T160-150v-50h-60q-24 0-42-18t-18-42v-480q0-24 18-42t42-18h760q24 0 42 18t18 42v480q0 24-18 42t-42 18h-60v50q0 12.75-8.625 21.375T770-120H190Zm-90-140h760v-480H100v480Zm151-110h459q8.5 0 12.75-8t-.75-16L592-568q-5-6-12-6t-12 6L440-404l-88-106q-5-5-11.5-5t-11.5 6l-90 115q-6 8-1.5 16t13.5 8ZM100-260v-480 480Z"
		/>
	</svg>
);
