import { ReactNode } from 'react';

export const symbol_flood: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M344-140q38 0 60.5-20t75.5-20q53 0 76 20t60 20q38 0 60.5-25t75.5-25q40 0 60 15t40 26q11 6 19.5 16t8.5 23q0 13-9 21t-20 4q-29-9-50.5-27T752-130q-33 0-58 25t-78 25q-53 0-78-20t-58-20q-33 0-58 20t-78 20q-53 0-78-20t-58-20q-26 0-47.5 14T111-84q-12 3-21.5-5T80-110q0-13 8.5-23t20.5-15q20-9 40-20.5t59-11.5q53 0 75.5 20t60.5 20Zm0-130q-53 0-78-20t-58-20q-26 0-47.5 14T111-274q-12 3-21.5-5T80-300q0-13 8.5-23t20.5-15q20-9 40-20.5t59-11.5q11 0 22.5 1.5T251-364l-51-185-43 54q-8 10-20 11t-22-7q-10-8-11.5-20.5T110-534l254-312q12-15 31-20t37 2l376 143q12 5 17 16t0 23q-5 11-16.5 16.5t-22.5.5l-64-24 86 321q11 6 22 14t22 14q11 7 19.5 17t8.5 23q0 13-10 21t-21 4q-28-10-49.5-27.5T752-320q-33 0-58 25t-78 25q-53 0-78-20t-58-20q-33 0-58 20t-78 20Zm0-60q31 0 55.5-17.5T456-369l-33-121q-2-6 1.5-11.5t9.5-7.5l106-28q6-2 11.5 1.5t7.5 9.5l51 196q38 4 62.5-22t69.5-28l-90-336-241-93-164 202 74 274q7 2 12 2.5t11 .5Zm151-237Z"
		/>
	</svg>
);
