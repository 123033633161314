import { useMemo, Fragment } from 'react';
import { t } from '@transifex/native';

import DateTime, { Granularity } from 'pkg/datetime';
import { cssClasses } from 'pkg/css/utils';
import * as models from 'pkg/api/models';
import useTooltip from 'pkg/hooks/useTooltip';

import EventContent from 'routes/group/calendar/components/events';
import {
	getCalendarEventColorVars,
	getCalendarEventDisplayVars,
} from 'routes/group/calendar/config';
import TimezoneTime from 'routes/group/calendar/components/events/TimezoneTime';
import { SharedEventProps } from 'routes/group/calendar/components/events/single';
import { ClonedEventProps } from 'routes/group/calendar/components/day-item';
import EventIcons from 'routes/group/calendar/components/events/event-icons';
import * as eventContentStyles from 'routes/group/calendar/components/events/styles.css';

import * as eventStyles from 'components/event/calendar-styles/styles.css';
import MeetTime from 'components/event/calendar-styles/meet-time';

import * as styling from './styles.css';

interface DayStyleProps extends SharedEventProps, ClonedEventProps {}

export default function DayStyle({
	dayDateTime,
	overlappingEvents,
	event,
	handleEventClick,
	previewContextMenu,
	isUnhandled,
}: DayStyleProps) {
	const { tooltip, onMouseEnter } = useTooltip(
		t('Group: {group_name}', { group_name: event.group.name })
	);
	const transformAmount = overlappingEvents[event.id].indexOf(event.id);
	const overlapCount = overlappingEvents[event.id].length;

	const startsAt = new DateTime(new Date(event.startsAt * 1000));
	const endsAt = new DateTime(new Date(event.endsAt * 1000));
	const meetingTimeStartsAt = startsAt.prev(
		Granularity.minute,
		event.meetBeforeMinutes
	);
	const shortEvent = event.endsAt - event.startsAt <= 1800;

	const calendarEventStyles = getCalendarEventColorVars(event.type);
	const calendarEventDisplayStyles = useMemo(
		() =>
			getCalendarEventDisplayVars(
				event,
				dayDateTime,
				transformAmount,
				overlapCount
			),
		[
			event.startsAt,
			event.endsAt,
			event.meetBeforeMinutes,
			transformAmount,
			overlapCount,
			dayDateTime.startOfDay,
		]
	);

	// This is only visible if the local timezone differs from the events timezone
	const showTimezone = useMemo(
		() => models.event.timezoneIsDifferent(event),
		[event?.timezone]
	);

	const isCanceled = models.event.isCanceled(event);
	const hasEnded = models.event.hasEnded(event);

	return (
		<Fragment>
			<div
				className={cssClasses(
					styling.wrapper,
					eventStyles['event-color-styles'],
					isUnhandled && !hasEnded ? eventStyles.unhandled : '',
					hasEnded ? eventStyles['has-finished'] : ''
				)}
				style={{
					...calendarEventDisplayStyles,
					...calendarEventStyles,
				}}
				onClick={handleEventClick}
				onMouseEnter={onMouseEnter}>
				{/* Return empty span for grid styling on parent class */}
				{event.meetBeforeMinutes > 0 ? (
					<MeetTime
						time={meetingTimeStartsAt}
						meetBeforeMinutes={event.meetBeforeMinutes}
					/>
				) : (
					<span></span>
				)}
				<div
					className={cssClasses(styling['event-wrapper'])}
					data-event-type={event.type}
					data-testid={`calendar.event.${event.id}`}>
					<div
						className={cssClasses(
							styling['day-event'],
							shortEvent ? styling.shortEvent : ''
						)}>
						<EventContent>
							{isCanceled ? (
								<div className={eventContentStyles.titleWrapper}>
									<EventIcons event={event} />
									<div className={eventStyles.uppercase}>{t('Canceled')}</div>
									<div
										className={`${eventContentStyles.title} ${eventStyles['strike-through']}`}>
										{event.title}
									</div>
								</div>
							) : (
								<div className={eventContentStyles.titleWrapper}>
									<EventIcons event={event} />
									<div className={eventContentStyles.title}>{event.title}</div>
								</div>
							)}

							<span className={eventContentStyles.contentWrapperChild}>
								{startsAt.toTimeString()}
								{' – '}
								{endsAt.toTimeString()}
							</span>
							{showTimezone && (
								<TimezoneTime
									timezone={event.timezone}
									starts={startsAt}
									ends={endsAt}
									useAbbreviation
								/>
							)}
							<span className={eventContentStyles.contentWrapperChild}>
								{event.location}
							</span>
							<span className={eventContentStyles.contentWrapperChild}>
								{event.group.name}
							</span>
						</EventContent>
					</div>
					{previewContextMenu}
				</div>
			</div>
			{tooltip}
		</Fragment>
	);
}
