import { useT } from '@transifex/react';
import { Fragment } from 'react';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import { cssClasses } from 'pkg/css/utils';
import { useCurrentGroup } from 'pkg/identity';
import { formatToCurrency } from 'pkg/i18n/formatters';
import { formatPriceIntervalToText } from 'pkg/utils';
import { RecurringInterval } from 'pkg/api/models/provider_settings';
import {
	paymentPreviewTotal,
	PaymentRowType,
} from 'pkg/api/models/payment_preview';

import Column from 'components/layout/column';
import Row from 'components/layout/row';

import * as css from './styles.css';

function createBillingCycleString(
	interval: RecurringInterval,
	count: number,
	t: any
) {
	if (!interval) {
		return '';
	}

	if (
		(interval === RecurringInterval.Year && count === 1) ||
		(interval === RecurringInterval.Month && count === 12)
	) {
		return t('Annual');
	} else {
		return formatPriceIntervalToText(interval, count, true);
	}
}

export interface ExtraPreviewValues {
	paidInstallments?: number;
}

interface PaymentPreviewProps {
	preview: models.paymentPreview.PaymentPreview;
	productsWithTaxesExists?: boolean;
	registrationFlow?: boolean;
	editSubscriptionView?: boolean;

	extraPreviewValues?: ExtraPreviewValues;
}

export default function PaymentPreview({
	preview,
	productsWithTaxesExists = false,
	registrationFlow = false,
	editSubscriptionView = false,
	extraPreviewValues = {},
}: PaymentPreviewProps) {
	const t = useT();
	const group = useCurrentGroup();
	const serviceFee = !!group.serviceFeePercent;

	if (!preview) {
		return null;
	}

	const initialLineItems = preview.initialPayment.lineItems.filter(
		(i) => i.type === PaymentRowType.PaymentRowTypeDiscount
	);

	const isSubscription =
		preview.recurringPayment.salePrice > 0 && !preview.totalInstallmentAmount;
	const hasRecurringPrices = preview.recurringPayment.salePrice > 0;
	const hasRecurringAndNonRecurringPrices =
		hasRecurringPrices &&
		initialLineItems.some((li) => !li.productPrice?.recurring);

	const billingCycleString = createBillingCycleString(
		preview.recurringInterval,
		preview.recurringIntervalCount,
		t
	);

	const initialPayment = formatToCurrency(
		preview.initialPayment.salePrice / 100,
		group.currency
	);

	let totalAmount = paymentPreviewTotal(
		preview,
		group.currency,
		extraPreviewValues
	);

	const recurringPaymentPrice = formatToCurrency(
		preview.recurringPayment.salePrice / 100,
		group.currency
	);

	let startPayment = t('Initial payment');

	if (registrationFlow) {
		startPayment = t('Total payment upon registration');
	}

	let recurringPricesString = t('{billingCycleString} payment', {
		billingCycleString,
	});

	let recurringPriceTotal = recurringPaymentPrice;

	if (preview.recurringPayment.recurringCount && preview.subscriptionStartAt) {
		recurringPricesString = t('Then {installmentCycle} payment', {
			installmentCycle: formatPriceIntervalToText(
				preview.recurringInterval,
				preview.recurringIntervalCount
			),
		});
	}

	if (preview.recurringPayment.recurringCount) {
		recurringPriceTotal = `${preview.recurringPayment.recurringCount} * ${recurringPaymentPrice}`;
	}

	if (editSubscriptionView && preview.installmentCount) {
		recurringPriceTotal = `${preview.installmentCount} * ${recurringPaymentPrice}`;
	}

	let taxFootNote = '';

	if (productsWithTaxesExists && !serviceFee) {
		taxFootNote = t('Incl. taxes');
	} else if (!productsWithTaxesExists && serviceFee) {
		taxFootNote = t('Incl. service fee');
	} else if (productsWithTaxesExists && serviceFee) {
		taxFootNote = t('Incl. service fee and taxes');
	}

	let totalAmountString = t('Total amount');

	if (isSubscription) {
		totalAmountString = billingCycleString;
		totalAmount = formatToCurrency(
			preview.recurringPayment.salePrice / 100,
			group.currency
		);
	}

	return (
		<div
			className={cssClasses(
				css.wrapper,
				registrationFlow ? css.registrationFlow : ''
			)}>
			<div
				className={cssClasses(
					css.inner,
					registrationFlow ? css.registrationFlow : ''
				)}>
				<Column spacing={styles.spacing._6}>
					{preview.recurringPayment.salePrice > 0 && (
						<Column spacing={styles.spacing._2}>
							{extraPreviewValues?.paidInstallments && (
								<Row justify="end">
									<div className={css.subTotalAmount}>
										{t('Paid until now')}
									</div>
									<div className={css.subTotalAmount}>
										{formatToCurrency(
											extraPreviewValues.paidInstallments / 100,
											group.currency
										)}
									</div>
								</Row>
							)}
							{hasRecurringAndNonRecurringPrices && (
								<Row justify="end">
									<div className={css.subTotalAmount}>{startPayment}</div>
									<div className={css.subTotalAmount}>{initialPayment}</div>
								</Row>
							)}
							{hasRecurringPrices && !isSubscription && (
								<Fragment>
									<Row justify="end">
										<div className={css.subTotalAmount}>
											{recurringPricesString}
										</div>
										<div className={css.subTotalAmount}>
											{recurringPriceTotal}
										</div>
									</Row>
								</Fragment>
							)}
						</Column>
					)}
					<Column spacing={styles.spacing._2}>
						<Row justify="end">
							<div className={css.totalAmount}>{totalAmountString}</div>
							<div className={css.totalAmount}>{totalAmount}</div>
						</Row>
						{!!taxFootNote && (
							<Row justify="end">
								<div className={css.tax}>{taxFootNote}</div>
							</Row>
						)}
					</Column>
				</Column>
			</div>
		</div>
	);
}
