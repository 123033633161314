import { createSelector } from 'reselect';

import { getMatchById } from 'pkg/selectors/matches.selector';

export const getEventUsersEntities = (state) => state.eventUsers.get('users');

export const getEventUsersForMatch = (state, { matchId }) => {
	const eventUsers = getEventUsersEntities(state);
	const match = getMatchById(state, { matchId });

	if (!match) {
		return null;
	}

	return eventUsers.filter(
		(eventUser) => eventUser.eventId === match.get('eventId')
	);
};

export const findAllUsers = createSelector(
	(state) => state.eventUsers.users,
	(state) => state.users.entities,
	(_, { eventId }) => eventId,
	(eventUserEntities, users, eventId) =>
		eventUserEntities
			.filter((eventUser) => eventUser.eventId === eventId)
			.toList()
			.map((eventUser) => users.get(eventUser.userId))
			.sort((a, b) =>
				`${a.firstName} ${a.lastName}`.localeCompare(
					`${b.firstName} ${b.lastName}`
				)
			)
);
