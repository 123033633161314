import { ReactNode } from 'react';

export const symbol_line_curve: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M760-160q-14 0-22-11t-9-25q-7-107-51-201T563-562.5Q492-634 398-678t-201-51q-14-1-25.5-9T160-760q0-14 12.5-22t28.5-7q118 7 222 56t182.5 127.5Q684-527 733-423t56 223q1 15-7 27.5T760-160Z"
		/>
	</svg>
);
