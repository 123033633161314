const downloadSimple = {
	name: 'download-simple',
	objects: [
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				d: 'M16.4219 12.1182C16.6316 12.3322 16.6281 12.6756 16.414 12.8853L12.6449 16.5778C12.434 16.7844 12.0966 16.7844 11.8857 16.5778L8.11654 12.8853C7.90253 12.6756 7.899 12.3322 8.10866 12.1182C8.31831 11.9042 8.66175 11.9006 8.87576 12.1103L11.7228 14.8995L11.7228 6.14819C11.7228 5.84861 11.9657 5.60574 12.2653 5.60574C12.5649 5.60574 12.8077 5.84861 12.8077 6.14819V14.8995L15.6548 12.1103C15.8688 11.9006 16.2123 11.9042 16.4219 12.1182Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				d: 'M5.73608 18.2828C5.73608 17.9975 5.96738 17.7662 6.25269 17.7662H18.6761C18.9614 17.7662 19.1927 17.9975 19.1927 18.2828C19.1927 18.5681 18.9614 18.7994 18.6761 18.7994H6.25269C5.96738 18.7994 5.73608 18.5681 5.73608 18.2828Z',
			},
		},
	],
};

export default downloadSimple;
