import { useState } from 'react';

import * as models from 'pkg/api/models';
import DateTime from 'pkg/datetime';
import { useCurrentAccountUserIds, useCurrentAccountWards } from 'pkg/identity';

import ScheduleStyle from 'routes/group/calendar/components/events/schedule';
import { ViewOptions, useCalendarContext } from 'routes/group/calendar';
import MonthStyle from 'routes/group/calendar/components/events/month';
import WeekStyle from 'routes/group/calendar/components/events/week';
import { ClonedEventProps } from 'routes/group/calendar/components/day-item';
import DayStyle from 'routes/group/calendar/components/events/day';

import EventPreview from 'components/event/preview';

import * as Context from 'design/context_menu';

export interface SharedEventProps extends ClonedEventProps {
	previewContextMenu: JSX.Element;
	dayDateTime: DateTime;
	isUnhandled: boolean;

	handleEventClick: (e: React.MouseEvent<HTMLDivElement>) => void;

	event: models.event.Event;
}

export interface EventWrapperProps extends ClonedEventProps {
	dayDateTime?: DateTime;

	event: models.event.Event;
}

let contextMenuPosition = 0;

// This component is a wrapper for events in the calendar views.
// Use this to implement things that will be shared throughout all the views.
export default function EventWrapper({
	event,
	hideInfo,
	overlappingEvents,
	dayDateTime,
}: EventWrapperProps) {
	const [showPreview, setShowPreview] = useState(false);
	const calendarCtx = useCalendarContext();

	const wardAccountRelations = useCurrentAccountWards();
	const activeUserIds = useCurrentAccountUserIds();
	const wardUserIds =
		models.accountRelation.findAllUserIds(wardAccountRelations);

	const eventUsers = models.eventUser.findAllEventUsers(event.users, [
		...activeUserIds,
		...wardUserIds,
	]);

	const isUnhandled = eventUsers.some((ev) => models.eventUser.isUnhandled(ev));

	let EventStyle = ScheduleStyle;

	switch (calendarCtx.currentTab) {
		case ViewOptions.Month:
			EventStyle = MonthStyle;
			break;
		case ViewOptions.Week:
			EventStyle = WeekStyle;
			break;
		case ViewOptions.Day:
			EventStyle = DayStyle;
			break;
	}

	const handleShowPreview = () => setShowPreview(true);
	const handleClosePreview = () => setShowPreview(false);

	const handleEventClick = (e: React.MouseEvent<HTMLDivElement>) => {
		const rect = e.currentTarget.getBoundingClientRect();
		contextMenuPosition = e.clientX - rect.x;

		handleShowPreview();
	};

	const previewContextMenu = showPreview ? (
		<Context.Menu
			togglePosition={{
				left: `${contextMenuPosition}px`,
			}}
			toggleWith={<span></span>}
			defaultOpen={true}
			width="470px"
			onClose={handleClosePreview}>
			<EventPreview eventId={event.id} />
		</Context.Menu>
	) : null;

	return (
		<EventStyle
			event={event}
			previewContextMenu={previewContextMenu}
			handleEventClick={handleEventClick}
			isUnhandled={isUnhandled}
			overlappingEvents={overlappingEvents}
			hideInfo={hideInfo}
			dayDateTime={dayDateTime}
		/>
	);
}
