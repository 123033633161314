import { ReactNode } from 'react';

export const symbol_heat_pump_balance: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M846-676h-81q-21.25 0-35.625 14.375T715-626v287q0 47.083-32.735 80.042Q649.529-226 602.765-226 556-226 523-258.958 490-291.917 490-339v-244q0-22-14.93-37.5t-37-15.5q-22.07 0-37.57 15.5T385-583v244q0 47.083-32.735 80.042Q319.529-226 272.765-226 226-226 193-258.958 160-291.917 160-339v-327q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T220-666v327q0 22 15.5 37.5t37.57 15.5q22.07 0 37-15.5T325-339v-244q0-47.083 32.941-80.042 32.941-32.958 80-32.958t79.559 32.958Q550-630.083 550-583v244q0 22 15.5 37.5t37.57 15.5q22.07 0 37-15.5T655-339v-287q0-45.833 32.083-77.917Q719.167-736 765-736h80l-36-36q-9-9-9-21.158 0-12.158 8.609-21Q818-823 830.467-823q12.466 0 21.533 9l87 87q9 9 9 21t-9 21l-88 87q-9 9-21 9t-21-9q-9-9-8.5-21.5T810-641l36-35ZM100-96q-24.75 0-42.375-17.625T40-156v-305q0-12.75 8.625-21.375T70-491h820q12.75 0 21.375 8.625T920-461v305q0 24.75-17.625 42.375T860-96H100Zm0-60h760v-275H100v275Zm760-275H100h760Z"
		/>
	</svg>
);
