import { ReactNode } from 'react';

export const symbol_zone_person_idle: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M812-113 527-397l-27 110H357l46-234-100 46v104q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T243-371v-125q0-9.333 4.875-16.722Q252.75-520.111 261-524l98-41L47-877q-9-9-9-21.5t9.053-21.5q9.052-9 21.5-9Q81-929 90-920l765 765q9 9 9 21t-9.053 21q-9.052 9-21.5 9Q821-104 812-113Zm-672-19q-24 0-42-18t-18-42v-102q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-294v102h102q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T242-132H140Zm680-638v-102H718q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T718-932h102q24 0 42 18t18 42v102q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T820-770ZM80-740v-132q0-12 4.522-22.875Q89.043-905.75 96-914l42 42v132H80Zm638 608q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T718-192h80l42 42q-7.143 8.25-17.5 13.125T800-132h-82ZM224-872l-60-60h108q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T272-872h-48Zm656 656-60-60v-48q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T880-324v108ZM540.08-646q-30.08 0-51.58-21.42-21.5-21.421-21.5-51.5 0-30.08 21.42-51.58 21.421-21.5 51.5-21.5 30.08 0 51.58 21.42 21.5 21.421 21.5 51.5 0 30.08-21.42 51.58-21.421 21.5-51.5 21.5Z"
		/>
	</svg>
);
