import DateTime from 'pkg/datetime';
import { delay } from 'pkg/timings';
import { tlog } from 'pkg/tlog';
import { UrlParser } from 'pkg/url';
import uuid from 'pkg/uuid';

const { apiUrl } = window.TS;
const urlParser = new UrlParser();
let token = '';

interface Headers {
	[key: string]: string;
}

const defaultRequestHeaders: Headers = {
	'Content-Type': 'application/json',
	'X-Client-Version': window.TS.version,
	'X-Correlation-Id': uuid(),
};

export function setToken(t: string) {
	token = t;
}

export function setDefaultRequestHeader(h: string, val: string) {
	if (!val) {
		delete defaultRequestHeaders[h];
		return;
	}

	defaultRequestHeaders[h] = val;
}

export async function request(
	requestUrl = '',
	method = 'GET',
	payload = {},
	additionalHeaders = {},
	retryable = false
) {
	const requestOptions: RequestInit = {
		credentials: 'include',
		mode: 'cors',
		method,
	};

	const authorizationHeaders: Headers = {};

	const accessToken = token;

	if (accessToken.length > 0) {
		authorizationHeaders['Authorization'] = `Bearer ${accessToken}`;
	}

	const requestHeaders = {
		...defaultRequestHeaders,
		...authorizationHeaders,
		...additionalHeaders,
		'X-Time-Zone': DateTime.getTimeZone(),
	};

	requestOptions.headers = requestHeaders;

	if (method !== 'GET' && method !== 'HEAD') {
		if (requestOptions.headers['Content-Type'] === 'application/json') {
			requestOptions.body = JSON.stringify(payload);
		} else {
			const params = new URLSearchParams();
			Object.entries(payload).forEach(([key, val]) => {
				params.append(key, `${val}`);
			});
			requestOptions.body = params;
		}
	}

	// Explicitly delete content-type header if we're sending multipart form-data, allowing the browser to take control over the content-type and boundary
	if (requestOptions.headers['Content-Type'] === 'multipart/form-data') {
		delete requestOptions.headers['Content-Type'];
	}

	const parts = [apiUrl];

	if (!requestUrl.startsWith('/v1/')) {
		parts.push('v1');
	}

	parts.push(requestUrl);

	requestUrl = parts.join('/').replace(/([^:])(\/\/+)/g, '$1/');

	let retries = 0;
	const maxRetries = 10;

	const makeRequest = async () => {
		let request;
		try {
			request = await fetch(requestUrl, requestOptions);
		} catch (e) {
			if (retryable && retries < maxRetries) {
				retries++;
				tlog.debug(`retrying request to`, {
					url: requestUrl,
					delay: 50 * retries,
				});

				await delay(50 * retries);
				return await makeRequest();
			}

			tlog.error(e, {
				retries: retries,
				url: requestUrl,
			});
			throw e;
		}

		return request;
	};

	return await makeRequest();
}

export async function get(
	uriPattern: string,
	uriParams = {},
	requestPayload = {},
	additionalHeaders = {}
) {
	const targetUrl = urlParser.transform(uriPattern, uriParams);

	return await request(
		targetUrl,
		'GET',
		requestPayload,
		additionalHeaders,
		true
	);
}

export async function post(
	uriPattern: string,
	uriParams = {},
	requestPayload = {},
	additionalHeaders = {}
) {
	const targetUrl = urlParser.transform(uriPattern, uriParams);

	return await request(
		targetUrl,
		'POST',
		requestPayload,
		additionalHeaders,
		false
	);
}

export async function postWithRetry(
	uriPattern: string,
	uriParams = {},
	requestPayload = {},
	additionalHeaders = {}
) {
	const targetUrl = urlParser.transform(uriPattern, uriParams);

	return await request(
		targetUrl,
		'POST',
		requestPayload,
		additionalHeaders,
		true
	);
}

export async function put(
	uriPattern: string,
	uriParams = {},
	requestPayload = {},
	additionalHeaders = {}
) {
	const targetUrl = urlParser.transform(uriPattern, uriParams);

	return await request(
		targetUrl,
		'PUT',
		requestPayload,
		additionalHeaders,
		false
	);
}

export async function patch(
	uriPattern: string,
	uriParams = {},
	requestPayload = {},
	additionalHeaders = {}
) {
	const targetUrl = urlParser.transform(uriPattern, uriParams);

	return await request(
		targetUrl,
		'PATCH',
		requestPayload,
		additionalHeaders,
		false
	);
}

export async function destroy(
	uriPattern: string,
	uriParams = {},
	requestPayload = {},
	additionalHeaders = {}
) {
	const targetUrl = urlParser.transform(uriPattern, uriParams);

	return await request(
		targetUrl,
		'DELETE',
		requestPayload,
		additionalHeaders,
		false
	);
}
