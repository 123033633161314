import { Fragment } from 'react';
import styled from 'styled-components';

import { ToolProps } from 'components/annotations/tools/ToolProps';
import { getPreferenceValue } from 'components/annotations/tools/ToolConfig';

const Cone = styled.svg`
	position: absolute;
	bottom: 50%;
	left: 0;
	width: 100%;
`;
const Target = styled.svg`
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;

export default function Circle({
	annotationId,
	annotation,
	coverSize,
}: ToolProps): JSX.Element {
	const color = getPreferenceValue(annotation, 'color');

	return (
		<Fragment>
			<Target
				viewBox="0 0 100 100"
				preserveAspectRatio="none"
				xmlns="http://www.w3.org/2000/svg">
				<circle cx="50" cy="50" r="49" fill={color} fillOpacity="0.14" />
			</Target>
			<Cone
				viewBox="0 0 108 386"
				preserveAspectRatio="none"
				style={{ height: coverSize.height + 80 }}
				xmlns="http://www.w3.org/2000/svg">
				<path
					d="M60.3099 6.06338C59.8861 2.8449 57.1425 0.438965 53.8963 0.438965V0.438965C50.65 0.438965 47.9065 2.84489 47.4826 6.06338L0.764697 360.815H107.028L60.3099 6.06338Z"
					fill={'url(#gradient' + annotationId + ')'}
				/>
				<defs>
					<linearGradient
						id={'gradient' + annotationId}
						x1="53.8963"
						y1="0.438965"
						x2="53.8963"
						y2="360.815"
						gradientUnits="userSpaceOnUse">
						<stop stopColor={color} />
						<stop offset="1" stopColor={color} stopOpacity="0" />
					</linearGradient>
				</defs>
			</Cone>
		</Fragment>
	);
}
