import { ReactNode } from 'react';

export const symbol_mobile_screen_share: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M400.825-361Q388-361 379.5-369.625T371-391v-26q0-48 33.833-80 33.834-32 82.167-32h43v-48q0-5.25 4.5-7.125t8.55 2.175l72.09 72.09Q620-505 620-498.818q0 6.182-5 10.818l-71.957 71.957Q539-412 534.5-413.875 530-415.75 530-421v-48h-43q-23 0-39.5 14.95T431-417v26q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM260-40q-24 0-42-18t-18-42v-760q0-24 18-42t42-18h440q24 0 42 18t18 42v760q0 24-18 42t-42 18H260Zm0-90v30h440v-30H260Zm0-60h440v-580H260v580Zm0-640h440v-30H260v30Zm0 0v-30 30Zm0 700v30-30Z"
		/>
	</svg>
);
