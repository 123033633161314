import { ReactNode } from 'react';

export const symbol_fluorescent: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M216-436v-88q0-24.75 17.625-42.375T276-584h408q24.75 0 42.375 17.625T744-524v88q0 24.75-17.625 42.375T684-376H276q-24.75 0-42.375-17.625T216-436Zm236-364v-50q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T512-850v50q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T452-800Zm276 74 26-26q9-9 21-9t21 9q9 9 9 21t-9 21l-26 26q-9 9-21 9t-21-9q-9-9-9-21t9-21ZM452-110v-50q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T512-160v50q0 12.75-8.675 21.375Q494.649-80 481.825-80 469-80 460.5-88.625T452-110Zm302-101-26-26q-9-9-9-21t9-21q9-9 21-9t21 9l26 26q9 9 9 21t-9 21q-9 9-21 9t-21-9ZM190-684l-26-26q-9-9-9-21t9-21q9-9 21-9t21 9l26 26q9 9 9 21t-9 21q-9 9-21 9t-21-9Zm-26 431 26-26q9-9 21-9t21 9q9 9 9 21t-9 21l-26 26q-9 9-21 9t-21-9q-9-9-9-21t9-21Zm112-183h408v-88H276v88Zm204-44Z"
		/>
	</svg>
);
