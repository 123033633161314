import { useMemo, Fragment } from 'react';
import { useMediaQuery } from 'react-responsive';
import { t } from '@transifex/native';

import useTooltip from 'pkg/hooks/useTooltip';
import DateTime, { Granularity } from 'pkg/datetime';
import { cssClasses } from 'pkg/css/utils';
import * as models from 'pkg/api/models';

import EventContent from 'routes/group/calendar/components/events';
import TimezoneTime from 'routes/group/calendar/components/events/TimezoneTime';
import {
	getCalendarEventColorVars,
	getCalendarEventDisplayVars,
} from 'routes/group/calendar/config';
import EventIcons from 'routes/group/calendar/components/events/event-icons';
import { SharedEventProps } from 'routes/group/calendar/components/events/single';
import { ClonedEventProps } from 'routes/group/calendar/components/day-item';
import * as eventContentStyles from 'routes/group/calendar/components/events/styles.css';

import * as eventStyles from 'components/event/calendar-styles/styles.css';
import MeetTime from 'components/event/calendar-styles/meet-time';

import * as css from './styles.css';

interface WeekStyleProps extends SharedEventProps, ClonedEventProps {}

export default function WeekStyle({
	dayDateTime,
	event,
	overlappingEvents = {},
	isUnhandled,
	handleEventClick,
	previewContextMenu,
	hideInfo = false,
}: WeekStyleProps) {
	const { tooltip, onMouseEnter } = useTooltip(
		t('Group: {group_name}', { group_name: event.group.name })
	);
	const dateObjDay = dayDateTime;
	const overlap = overlappingEvents[event.id];
	const transformAmount = overlap ? overlap.indexOf(event.id) : 0;
	const overlapCount = overlap ? overlappingEvents[event.id].length : 0;

	const startsAt = new DateTime(new Date(event.startsAt * 1000));
	const endsAt = new DateTime(new Date(event.endsAt * 1000));
	const meetingTimeStartsAt = startsAt.prev(
		Granularity.minute,
		event.meetBeforeMinutes
	);
	const shortEvent = event.endsAt - event.startsAt <= 1800;

	const s =
		useMediaQuery({ maxWidth: 1700 }) &&
		overlappingEvents?.[event.id]?.length > 2;
	const a =
		useMediaQuery({
			maxWidth: 1500,
		}) && overlappingEvents?.[event.id]?.length > 1;
	const addPadding = s || a;

	const calendarEventStyles = getCalendarEventColorVars(event.type);
	const calendarEventDisplayStyles = useMemo(
		() =>
			getCalendarEventDisplayVars(
				event,
				dateObjDay,
				transformAmount,
				overlapCount
			),
		[
			event.startsAt,
			event.endsAt,
			event.meetBeforeMinutes,
			transformAmount,
			overlapCount,
		]
	);

	// This is only visible if the local timezone differs from the events timezone
	const showTimezone = useMemo(
		() => models.event.timezoneIsDifferent(event),
		[event?.timezone]
	);

	const isCanceled = models.event.isCanceled(event);

	return (
		<Fragment>
			<div
				className={cssClasses(
					css.wrapper,
					eventStyles['event-color-styles'],
					isUnhandled && !models.event.hasEnded(event)
						? eventStyles.unhandled
						: '',
					models.event.hasEnded(event) ? eventStyles['has-finished'] : ''
				)}
				style={{
					...calendarEventDisplayStyles,
					...calendarEventStyles,
				}}
				onClick={handleEventClick}>
				{/* Return empty span for grid styling on parent class */}
				{event.meetBeforeMinutes > 0 ? (
					<MeetTime
						time={meetingTimeStartsAt}
						meetBeforeMinutes={event.meetBeforeMinutes}
					/>
				) : (
					<span></span>
				)}
				<div
					className={cssClasses(css['event-wrapper'])}
					onClick={handleEventClick}
					onMouseEnter={onMouseEnter}
					data-event-type={event.type}>
					<div
						className={cssClasses(
							css['details-wrapper'],
							addPadding ? css.padding : '',
							shortEvent ? css.shortEvent : ''
						)}>
						{!hideInfo && (
							<EventContent>
								{isCanceled ? (
									<span className={eventContentStyles.titleWrapper}>
										<EventIcons event={event} size={1.3} />
										<div className={css['all-caps']}>{t('Canceled')}</div>
										<div
											className={`${eventContentStyles.title} ${css['strike-through']}`}>
											{event.title}
										</div>
									</span>
								) : (
									<span className={eventContentStyles.titleWrapper}>
										<EventIcons event={event} size={1.3} />
										<div className={eventContentStyles.title}>
											{event.title}
										</div>
									</span>
								)}

								<span className={eventContentStyles.contentWrapperChild}>
									{startsAt.toTimeString()}
									{' – '}
									{endsAt.toTimeString()}
								</span>
								{showTimezone && (
									<TimezoneTime
										timezone={event.timezone}
										starts={startsAt}
										ends={endsAt}
										useAbbreviation
									/>
								)}
								<span className={eventContentStyles.contentWrapperChild}>
									{event.location}
								</span>
								<span className={eventContentStyles.contentWrapperChild}>
									{event.group.name}
								</span>
							</EventContent>
						)}
					</div>
					{previewContextMenu}
				</div>
			</div>
			{tooltip}
		</Fragment>
	);
}
