import { ReactNode } from 'react';

export const symbol_sunny_snowing: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M170-420H70q-12.75 0-21.375-8.675Q40-437.351 40-450.175 40-463 48.625-471.5T70-480h100q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T170-420Zm112-228q-9 9-21 9t-21-9l-71-71q-9-9-9-21t9-21.391Q178-770 190-770t21 9l71 71q9 9 9 21t-9 21Zm-42.141 443Q223-205 211.5-216.641t-11.5-28.5Q200-262 211.641-273.5t28.5-11.5Q257-285 268.5-273.359t11.5 28.5Q280-228 268.359-216.5t-28.5 11.5Zm240.259-375Q426-580 388-542.083 350-504.167 350-450q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T290-450q0-78.85 55.606-134.425Q401.212-640 480.106-640T614.5-584.425Q670-528.85 670-450q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T610-450q0-54.167-37.882-92.083-37.883-37.917-92-37.917ZM359.859-40Q343-40 331.5-51.64 320-63.283 320-80.14q0-16.86 11.641-28.36t28.5-11.5Q377-120 388.5-108.359q11.5 11.64 11.5 28.5Q400-63 388.359-51.5t-28.5 11.5Zm120-165Q463-205 451.5-216.641t-11.5-28.5Q440-262 451.641-273.5t28.5-11.5Q497-285 508.5-273.359t11.5 28.5Q520-228 508.359-216.5t-28.5 11.5Zm-.034-525Q467-730 458.5-738.625T450-760v-100q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-860v100q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM599.859-40Q583-40 571.5-51.64 560-63.283 560-80.14q0-16.86 11.641-28.36t28.5-11.5Q617-120 628.5-108.359q11.5 11.64 11.5 28.5Q640-63 628.359-51.5t-28.5 11.5Zm76.983-606.609Q668-656 668-668q0-12 9-21l72-72q9-9 21-9t21 9q9 9 9 21t-9 21l-72 72q-9 9-21.158 9-12.158 0-21-8.609ZM719.859-205Q703-205 691.5-216.641t-11.5-28.5Q680-262 691.641-273.5t28.5-11.5Q737-285 748.5-273.359t11.5 28.5Q760-228 748.359-216.5t-28.5 11.5ZM790-420q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T790-480h100q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T890-420H790Zm-310 0Z"
		/>
	</svg>
);
