import { normalize } from 'normalizr';

import Tag from 'pkg/models/tag';

import { triggerError } from 'pkg/actions/app';
import * as tagService from 'pkg/actions/services/tags';

export const searchTag = (tag) => async (dispatch) => {
	const request = await tagService.searchTag(tag);

	if (!request.ok) {
		dispatch(triggerError(request));
		return;
	}

	const response = await request.json();

	const data = normalize(response.records, [Tag.normalizr()]);

	return data;
};
