import { ReactNode } from 'react';

export const symbol_alarm_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M762-282q-12-5-17-16t0-21q13-26 19.5-56.5T771-441q2-124-87.5-211T466-739q-32 0-61 6.5T350-714q-10 5-20.5 0T314-730q-5-12 .5-24.5T333-772q32-14 65-20.5t68-6.5q75 0 141.5 27.5T724-696q50 48 79 113.5T831-441q-1 37-8 71.5T803-303q-5 14-17.5 20t-23.5 1Zm-51-523q-9-9-8.5-21.5T714-848q9-8 20.5-7.5T754-847l122 117q10 9 10 22t-10 23q-10 10-23 10t-23-10L711-805ZM472-80q-74 0-139-28t-113-77q-48-49-76-115t-28-142q0-64 21-121t65-111l-52-52-37 37q-9 9-22 8.5T69-690q-9-9-9-22.5t9-22.5l36-36-49-49q-9-9-9-21t9-21q9-9 21.5-9t21.5 9L863-96q9 9 9 21t-9 21q-9 9-21.5 9T820-54L708-165q-45 40-107 62.5T472-80Zm0-62q52-1 101-18.5t90-50.5L242-632q-34 41-51.5 89.5T173-441q0 125 86 213t213 86Zm-19-280Zm84-84Z"
		/>
	</svg>
);
