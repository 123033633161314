import styled, { css } from 'styled-components';
import { Children, ReactNode } from 'react';

const Heading = styled.h2<{
	marginBottom?: boolean;
	center?: boolean;
	children: ReactNode;
}>`
	display: flex;
	font-size: var(--font-size-lg);
	font-weight: var(--font-weight-semibold);
	letter-spacing: -0.3px;

	${({ marginBottom }) =>
		marginBottom &&
		css`
			margin-bottom: var(--spacing-3);
		`}

	${({ center }) =>
		center &&
		css`
			justify-content: center;
		`}

	${({ children }) =>
		Children.count(children) > 1 &&
		css`
			justify-content: space-between;
			align-items: center;
		`}

	@media print {
		font-size: 12pt;
	}
`;

export default Heading;
