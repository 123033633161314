import { ReactNode } from 'react';

export const symbol_remote_gen: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M300-40q-24.75 0-42.375-17.625T240-100v-760q0-24.75 17.625-42.375T300-920h360q24.75 0 42.375 17.625T720-860v760q0 24.75-17.625 42.375T660-40H300Zm0-60h360v-760H300v760Zm180.118-470Q526-570 558-602.118q32-32.117 32-78Q590-726 557.882-758q-32.117-32-78-32Q434-790 402-757.882q-32 32.117-32 78Q370-634 402.118-602q32.117 32 78 32Zm.057-60Q459-630 444.5-644.325q-14.5-14.324-14.5-35.5Q430-701 444.325-715.5q14.324-14.5 35.5-14.5Q501-730 515.5-715.675q14.5 14.324 14.5 35.5Q530-659 515.675-644.5q-14.324 14.5-35.5 14.5Zm-80 220q12.825 0 21.325-8.675 8.5-8.676 8.5-21.5 0-12.825-8.675-21.325-8.676-8.5-21.5-8.5-12.825 0-21.325 8.675-8.5 8.676-8.5 21.5 0 12.825 8.675 21.325 8.676 8.5 21.5 8.5Zm160 0q12.825 0 21.325-8.675 8.5-8.676 8.5-21.5 0-12.825-8.675-21.325-8.676-8.5-21.5-8.5-12.825 0-21.325 8.675-8.5 8.676-8.5 21.5 0 12.825 8.675 21.325 8.676 8.5 21.5 8.5Zm-160 120q12.825 0 21.325-8.675 8.5-8.676 8.5-21.5 0-12.825-8.675-21.325-8.676-8.5-21.5-8.5-12.825 0-21.325 8.675-8.5 8.676-8.5 21.5 0 12.825 8.675 21.325 8.676 8.5 21.5 8.5Zm160 0q12.825 0 21.325-8.675 8.5-8.676 8.5-21.5 0-12.825-8.675-21.325-8.676-8.5-21.5-8.5-12.825 0-21.325 8.675-8.5 8.676-8.5 21.5 0 12.825 8.675 21.325 8.676 8.5 21.5 8.5Zm-160 120q12.825 0 21.325-8.675 8.5-8.676 8.5-21.5 0-12.825-8.675-21.325-8.676-8.5-21.5-8.5-12.825 0-21.325 8.675-8.5 8.676-8.5 21.5 0 12.825 8.675 21.325 8.676 8.5 21.5 8.5Zm160 0q12.825 0 21.325-8.675 8.5-8.676 8.5-21.5 0-12.825-8.675-21.325-8.676-8.5-21.5-8.5-12.825 0-21.325 8.675-8.5 8.676-8.5 21.5 0 12.825 8.675 21.325 8.676 8.5 21.5 8.5ZM300-100v-760 760Z"
		/>
	</svg>
);
