import { ReactNode } from 'react';

export const symbol_deceased: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M455-137q-29-84-70-126.5T259-335q4 48 18 84.5t36 58.5q17 17 32.5 26.5T378-150q17 6 36 8.5t41 4.5Zm50 0q97-9 143-55t55-143q-43 15-75 33t-52 38q-15 15-25.5 29.5T532-205q-8 15-14 31.5T505-137Zm-25-363q75 0 127.5-52.5T660-680v-91l-87 74-93-112-93 112-87-74v91q0 75 52.5 127.5T480-500ZM440-80q-100 0-170-70t-70-170v-80q71-1 134 29t106 81v-152.084Q354-456 297-522.798T240-680v-157q0-19.915 18-27.957Q276-873 290-860l90 78 77-93q8.88-11 22.94-11T503-875l77 93 90-78q14-13 32-4.957 18 8.042 18 27.957v157q0 90.38-57 157.16-57 66.781-143 80.84v152q43-51 106-80.988Q689-400.977 760-400v80q0 100-70 170T520-80h-80Zm40-575Zm124 419Zm-247 0Z"
		/>
	</svg>
);
