import { createSelector } from 'reselect';

import * as multisort from 'pkg/multisort';

import * as exercises from './exercises';
import * as sessionItems from './session_items';

const getSessionBlockEntities = (state) => state.sessionBlocks.get('entities');

export const findAll = createSelector(
	getSessionBlockEntities,
	(_, { sessionId }) => sessionId,
	(entities, sessionId) =>
		entities
			.filter((item) => item.get('trainingSessionId') === sessionId)
			.sort(multisort.asc('order'))
			.toList()
);

export const find = createSelector(
	getSessionBlockEntities,
	(_, { blockId }) => blockId,
	(entities, blockId) => entities.find((item) => item.get('id') === blockId)
);

export const findAllExercisesOf = createSelector(
	sessionItems.findAllByBlock,
	exercises.findAll,
	(items, exerciseEntities) =>
		items.map((item) => exerciseEntities.get(item.get('exerciseId')))
);

export const combinedDuration = createSelector(findAll, (entities) =>
	entities.reduce((duration, entity) => duration + entity.get('duration'), 0)
);
