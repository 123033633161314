const icon = {
	viewBox: '0 0 21 25',
	name: 'av-skip-backwards',
	paths: [
		'M10.5225 3.47284V0.887161L5.35111 4.76568L10.5225 8.6442V6.05852C12.5798 6.05852 14.5528 6.87578 16.0075 8.3305C17.4623 9.78523 18.2795 11.7583 18.2795 13.8156C18.2795 15.8729 17.4623 17.8459 16.0075 19.3006C14.5528 20.7553 12.5798 21.5726 10.5225 21.5726C8.46517 21.5726 6.49214 20.7553 5.03741 19.3006C3.58269 17.8459 2.76543 15.8729 2.76543 13.8156H0.179749C0.179749 16.5586 1.26942 19.1893 3.20906 21.129C5.1487 23.0686 7.77941 24.1583 10.5225 24.1583C13.2655 24.1583 15.8962 23.0686 17.8359 21.129C19.7755 19.1893 20.8652 16.5586 20.8652 13.8156C20.8652 11.0725 19.7755 8.44179 17.8359 6.50215C15.8962 4.56252 13.2655 3.47284 10.5225 3.47284Z',
		'M9.00173 11.3245H7.96514C7.95292 11.6301 7.52264 12.1509 6.87967 12.1509V12.9919C7.43218 12.9919 7.79156 12.7865 7.89913 12.6203H7.94559V16.3314H9.00173V11.3245Z',
		'M12.1524 16.4414C13.4115 16.4414 14.1718 15.4831 14.1742 13.8329C14.1767 12.1949 13.4066 11.2561 12.1524 11.2561C10.8958 11.2561 10.133 12.1924 10.1306 13.8329C10.1257 15.4782 10.8909 16.439 12.1524 16.4414ZM12.1524 15.5638C11.5779 15.5638 11.2063 14.9868 11.2087 13.8329C11.2112 12.696 11.5803 12.124 12.1524 12.124C12.7221 12.124 13.0937 12.696 13.0937 13.8329C13.0961 14.9868 12.7245 15.5638 12.1524 15.5638Z',
	],
};

export default icon;
