import { Fragment } from 'react';
import { t } from '@transifex/native';

import * as routes from 'pkg/router/routes';
import * as models from 'pkg/api/models';
import { cssClasses } from 'pkg/css/utils';
import { useCurrentOrganization } from 'pkg/identity';

import Badge from 'components/Badge';
import Icon from 'components/icon';

import Button from 'design/button';

import * as css from './styles.css';

interface NoStatsButtonProps {
	organizationId: number;
	matchId: number;
	eventId: number;
	canEdit: string;
}

const NoStatsButton = ({
	organizationId,
	matchId,
	eventId,
	canEdit,
}: NoStatsButtonProps) => (
	<div className={css.buttonWrapper}>
		<Button
			secondary
			icon={canEdit ? 'add' : null}
			href={
				canEdit
					? routes.Match.Edit(organizationId, eventId, matchId, 'result')
					: routes.Match.View(organizationId, eventId, matchId, 'summary')
			}
			label={canEdit ? t('Add statistics') : t('View match')}
			testid="add_statistics_button"
		/>
	</div>
);

const ResultScore = ({ match }: { match: models.match.Match }) => (
	<Fragment>
		<div className={cssClasses(css.fullTime, match.isHome ? css.isHome : '')}>
			<div className={css.score}>
				{match.goalCount === null ? '?' : match.goalCount}
			</div>
			<div className={cssClasses(css.score, css.center)}>-</div>
			<div className={css.score}>
				{match.opponentGoalCount === null ? '?' : match.opponentGoalCount}
			</div>
		</div>

		<div
			className={cssClasses(
				css.fullTime,
				css.halfTime,
				match.isHome ? css.isHome : ''
			)}>
			<div className={css.score}>
				{match.goalCountHalfTime === null ? '?' : match.goalCountHalfTime}
			</div>
			<div className={cssClasses(css.score, css.center)}>-</div>
			<div className={css.score}>
				{match.opponentGoalCountHalfTime === null
					? '?'
					: match.opponentGoalCountHalfTime}
			</div>
		</div>
	</Fragment>
);

interface MatchResultProps {
	match: models.match.Match;
}

export default function MatchResult({ match }: MatchResultProps) {
	const org = useCurrentOrganization();

	const showStats = !(
		match.goalCount === null && match.opponentGoalCount === null
	);

	return (
		<Fragment>
			<div className={cssClasses(css.outer, match.isHome ? css.isHome : '')}>
				<div className={css.team}>
					<div className={css.groupBadge}>
						<Badge badgeUrl={match.group?.profileImageUrl} />
					</div>
					<div className={css.teamName}>{match.group.name}</div>
				</div>

				{showStats ? (
					<ResultScore match={match} />
				) : (
					<NoStatsButton
						organizationId={org.id}
						matchId={match.id}
						eventId={match.eventId}
						canEdit={match.links?.edit}
					/>
				)}

				<div className={cssClasses(css.team, css.opponent)}>
					<div className={css.groupBadge}>
						{match.opponent?.imageUrl ? (
							<Badge badgeUrl={match.opponent?.imageUrl} />
						) : (
							<Icon className={css.placeholderIcon} name="nav-group" />
						)}{' '}
					</div>
					<div className={css.teamName}>
						{match.opponentName || t(`Opponent`)}
					</div>
				</div>
			</div>
		</Fragment>
	);
}
