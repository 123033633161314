import { ReactNode } from 'react';

export const symbol_box_edit: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M197-734h565l-36.409-46H233l-36 46Zm183 305 100-50 100 50v-245H380v245ZM180-120q-24.75 0-42.375-17.625T120-180v-529q0-9.882 3-19.059 3-9.176 9-16.941l52-71q8-11 20.941-17.5Q217.882-840 232-840h495q14.118 0 27.059 6.5T775-816l53 71q6 7.765 9 16.941 3 9.177 3 19.059v147q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T780-562v-112H640v267l-51 50-109-55-117 58q-15 8-29-.75T320-381v-293H180v494h290q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T470-120H180Zm460-554h140-140Zm-460 0h409-409Zm380 524v-81q0-5.565 2-10.783Q564-247 569-252l211.612-210.773q9.113-9.12 20.25-13.174Q812-480 823-480q12 0 23 4.5t20 13.5l37 37q9 9 13 20t4 22q0 11-4.5 22.5t-13.583 20.624L692-129q-5 5-10.217 7-5.218 2-10.783 2h-81q-12.75 0-21.375-8.625T560-150Zm300-233-37-37 37 37ZM620-180h38l121-122-18-19-19-18-122 121v38Zm141-141-19-18 37 37-18-19Z"
		/>
	</svg>
);
