import { Record } from 'pkg/api/models/record';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as sdk from 'pkg/core/sdk';
import * as models from 'pkg/api/models';

export interface MatchVideo extends Record {
	matchId: number;
	videoId: number;
	// This is not used anywhere
	timeOffset?: number;

	video: models.video.Video;
}

export interface MatchVideoCreatePayload {
	matchId: number;
	videoId: number;
}

/**
 *
 * @param number matchId
 * @param number videoId
 * @returns Promise<boolean>
 */
export async function link(
	matchId: number,
	videoId: number
): Promise<[boolean, MatchVideo]> {
	const request = await sdk.post(endpoints.Match.AddVideo(matchId), {}, {
		matchId,
		videoId,
	} as MatchVideoCreatePayload);

	if (!request.ok) {
		return Promise.reject([false, null]);
	}

	const response = await request.json();

	return Promise.resolve([request.ok, response]);
}

export async function unlink(
	matchId: number,
	videoId: number
): Promise<boolean> {
	const request = await sdk.destroy(
		endpoints.Match.DeleteVideo(matchId, videoId)
	);

	return request.ok;
}
