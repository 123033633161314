import { useViewportFetchLimit } from 'pkg/hooks/library';
import { useCurrentUser, useCurrentOrganization } from 'pkg/identity';

import { useUserLibrary } from 'routes/training/library/hooks';
import Library from 'routes/training/library/Library';

export default function Account(): JSX.Element {
	const user = useCurrentUser();
	const org = useCurrentOrganization();

	const fetchLimit = useViewportFetchLimit();
	const library = useUserLibrary(org.id, user.id, fetchLimit);

	return <Library library={library} />;
}
