import { ReactNode } from 'react';

export const symbol_tab_close: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M260-200q-24 0-42-18t-18-42v-560q0-24 18-42t42-18h560q24 0 42 18t18 42v560q0 24-18 42t-42 18H260Zm0-60h560v-560H260v560ZM140-80q-24 0-42-18t-18-42v-590q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-730v590h590q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q760-97 751.375-88.5T730-80H140Zm120-740v560-560Zm217 385 63-63 63 63q9 9 21 9t21-9q9-9 9-21t-9-21l-63-63 63-63q9-9 9-21t-9-21q-9-9-21-9t-21 9l-63 63-63-63q-9-9-21-9t-21 9q-9 9-9 21t9 21l63 63-63 63q-9 9-9 21t9 21q9 9 21 9t21-9Z"
		/>
	</svg>
);
