import { ReactNode } from 'react';

export const symbol_ecg: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-480ZM140-800h680q24 0 42 18t18 42v230q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T820-510v-230H140v140q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Q97-570 88.5-578.625T80-600v-140q0-24 18-42t42-18Zm0 640q-24 0-42-18t-18-42v-140q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-360v140h269q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T409-160H140Zm380-130q0-45 32-77.5t78-32.5q26 0 49.5 12t40.5 35q16-23 39.5-35t50.5-12q45 0 77.5 32.5T920-290q0 36-24.5 68.5T793-123l-54 46q-5 4-9.75 5.5t-9.5 1.5q-4.75 0-9.882-1.556Q704.737-73.11 700-77l-61-52q-72-62-95.5-93.5T520-290Zm200 151 32-28q66-56 87-79.5t21-43.5q0-20-15-35t-35-15q-12.19 0-22.095 5T767-319l-47 44-48-44q-11-11-21.075-16T630-340q-21 0-35.5 15T580-290q0 20 27.5 51t78.5 72l34 28ZM279.936-290Q271-290 264-294t-12-12l-71-144h-71q-12.75 0-21.375-8.675Q80-467.351 80-480.175 80-493 88.625-501.5T110-510h90q8.171 0 15.229 4.636Q222.286-500.727 226-493l54 106 133-266q4-8 11.381-11.5t15.5-3.5q8.119 0 15.619 3.5T467-653l71 143h102q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T640-450H520q-9 0-16.125-4T493-466l-54-106-133 266q-3.723 8-10.798 12-7.074 4-15.266 4ZM720-240Z"
		/>
	</svg>
);
