import { Map, Record } from 'immutable';

import UserGoal from 'pkg/models/user_goal';

import { UserGoals as goalTypes } from 'pkg/actions/action-types';

const initialState = Record(
	{
		entities: Map({}),
	},
	'userGoalsReducer'
);

const setItems = (state = initialState(), payload: any) => {
	const entities = Object.values(payload.entities.userGoal);

	entities.map((entity: any) => {
		state = state.setIn(['entities', entity.id], new UserGoal(entity));
	});

	return state;
};

const deleteItem = (state = initialState(), action: any) =>
	state.deleteIn(['entities', action.goalId]);

const userGoalsReducer = (
	state = initialState(),
	action: { type: string; payload: any }
) => {
	switch (action.type) {
		case goalTypes.ADD_ITEMS:
			return setItems(state, action.payload);
		case goalTypes.REMOVE_ITEM:
			return deleteItem(state, action);
		default:
			return state;
	}
};

export default userGoalsReducer;
