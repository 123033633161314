import { ReactNode } from 'react';

export const symbol_layers_clear: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M779-609q23 17.882 23 46.941T779-515l-109 84q-9 7-19.61 6.308-10.609-.693-18.39-8.308-10-10-9.5-23.5T634-478l108-84-262-204-81 63q-9.143 7-20.571 6-11.429-1-19.679-9.25-9.75-9.75-8.603-23.065Q351.294-742.63 362-751l81-63q17-13 37-12.5t37 13.5l262 204Zm30 522L641-255l-124 96q-16.5 13-36.75 13T443-159L151-386q-12-8.941-11.5-23.471Q140-424 152.075-433q8.302-6 18.114-6Q180-439 188-433l292 227 118-92-55-55h28l-54 42q-17 13-37 13t-37-13L181-515q-23-17.882-23-46.941T181-609l59-47L95-801q-9-9-9-21t9.053-21q9.052-9 21.5-9Q129-852 138-843l714 714q9 9 9 21t-9 21q-9 9-21.5 9T809-87ZM493-572Zm315.462 139.069Q820-423.828 820-409.414T809-386l-52 41q-9.143 7-20.571 6Q725-340 717-348.115q-10-9.59-9-23.237Q709-385 720-393l52-40q8.105-6 18.053-6 9.947 0 18.409 6.069Z"
		/>
	</svg>
);
