import { useSelector } from 'react-redux';
import { Fragment, ChangeEvent, useState } from 'react';
import { t } from '@transifex/native';

import { PlaylistSequencePermissions } from 'pkg/models/video_playlist';

import { PlaylistCreatePayload } from 'pkg/actions/services/videos';

import * as models from 'pkg/api/models';
import * as routes from 'pkg/router/routes';
import { pushState } from 'pkg/router/state';
import * as selectors from 'pkg/selectors';
import * as actions from 'pkg/actions';
import { RootState } from 'pkg/reducers';
import {
	useCurrentGroup,
	useCurrentMembership,
	useCurrentOrganization,
} from 'pkg/identity';
import {
	VideoPlaylistPermission,
	permissionLabel,
} from 'pkg/api/models/video_playlist';

import * as StepModal from 'components/step-modal';

import * as Input from 'components/form/inputs';

import * as Form from 'styles/Form';

interface PlaylistProps {
	playlistId?: number;
	onClose: () => void;

	defaultShare?: boolean;
}

export default function Playlist({
	playlistId,
	onClose,

	defaultShare = true,
}: PlaylistProps): JSX.Element {
	const modalContext = StepModal.useStepModalContext();
	const org = useCurrentOrganization();

	const playlist = useSelector((state: RootState) =>
		selectors.videoPlaylists.find(state, playlistId)
	);

	const group = useCurrentGroup();

	const membership = useCurrentMembership();

	const persisted = playlistId !== 0 && playlistId === playlist.id;

	const heading = persisted
		? t(`Edit {name}`, { name: playlist.title })
		: t(`Create new playlist`);

	defaultShare = defaultShare || playlist.groupId ? true : false;

	if (
		(!persisted && models.membership.isPlayer(membership)) ||
		(persisted && !playlist.groupId)
	) {
		defaultShare = false;
	}

	const availablePermissions = ['only_you', 'group_admins', 'group_members'];

	let defaultPermissions: string = 'only_you';

	if (persisted && playlist.sequencePermissions?.length > 0) {
		if (
			playlist.sequencePermissions.includes(
				PlaylistSequencePermissions.GroupAdmin
			)
		) {
			defaultPermissions = 'group_admins';
		} else if (
			playlist.sequencePermissions.includes(
				PlaylistSequencePermissions.GroupMember
			)
		) {
			defaultPermissions = 'group_members';
		}
	}

	const [title, setTitle] = useState<string>(playlist.title);
	const [share, setShare] = useState<boolean>(defaultShare);
	const [permissions, setPermissions] = useState<string>(defaultPermissions);

	const handleTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
		setTitle(event.currentTarget.value);
	};

	const handleShareChange = () => setShare(!share);

	const handlePermissionChange = (perms: string) => setPermissions(perms);

	const save = async (): Promise<boolean> => {
		let sequencePermissions: PlaylistSequencePermissions[] = [];

		if (permissions === 'group_admins') {
			sequencePermissions = [PlaylistSequencePermissions.GroupAdmin];
		} else if (permissions === 'group_members') {
			sequencePermissions = [PlaylistSequencePermissions.GroupMember];
		}

		const payload: PlaylistCreatePayload = {
			title,
			groupId: share ? group.id : null,
			sequencePermissions,
		};

		const resource = persisted
			? await actions.videoPlaylists.update(playlistId, payload)
			: await actions.videoPlaylists.create(payload);

		if (!persisted && !modalContext) {
			pushState(routes.VideoPlaylist.Show(org.id, resource.id));
		}

		return true;
	};

	if (modalContext?.withNext) {
		modalContext.withNext(save);
	}

	const step = (
		<Form.Wrapper>
			<Input.Group label={t(`Give your playlist a name…`)}>
				<Input.Field value={title} onChange={handleTitleChange} />
			</Input.Group>

			{models.membership.isAdminOrStaff(membership) && !persisted && (
				<Fragment>
					<Input.Control
						disabled={!!playlist.groupId}
						type="toggle"
						label={t(`Share playlist with team`)}
						description={t(
							`Members of {team_name} will be able to view this playlist`,
							{
								team_name: group.name,
							}
						)}
						onChange={handleShareChange}
						checked={share}
					/>
				</Fragment>
			)}

			{share && (
				<Fragment>
					<p>{t(`Who can add and remove clips in this playlist?`)}</p>

					{availablePermissions.map((perm: string) => (
						<Input.Control
							type="radio"
							name="sequencePermission[]"
							label={permissionLabel(perm as VideoPlaylistPermission)}
							value={perm}
							checked={permissions === perm}
							onChange={handlePermissionChange}
						/>
					))}
				</Fragment>
			)}
		</Form.Wrapper>
	);

	if (modalContext) {
		return step;
	}

	return (
		<StepModal.Base onClose={onClose}>
			<StepModal.Step
				title={heading}
				canGoNext={title.length > 0}
				nextLabel={persisted ? t('Save') : t('Create')}
				onNext={!modalContext && save}>
				{step}
			</StepModal.Step>
		</StepModal.Base>
	);
}
