import UserGoal from 'pkg/models/user_goal';

import { forAccountHeaders } from 'pkg/actions/utils';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as sdk from 'pkg/core/sdk';

export const getGoals = (
	userId: number,
	queryparams: { 'include-completed': number; before?: number; page?: number },
	forAccount?: number
): Promise<Response> => {
	const headers = forAccountHeaders(forAccount);

	return sdk.get(
		endpoints.UserGoals.UserIndex(userId),
		queryparams,
		{},
		headers
	);
};

export const patchGoal = (
	goalId: number,
	goal: UserGoal,
	forAccount?: number
): Promise<Response> => {
	const headers = forAccountHeaders(forAccount);

	return sdk.patch(endpoints.UserGoals.Update(goalId), {}, goal, headers);
};

export const createGoal = (
	userId: number,
	goal: UserGoal,
	forAccount?: number
): Promise<Response> => {
	const headers = forAccountHeaders(forAccount);

	return sdk.post(endpoints.UserGoals.Create(userId), {}, goal, headers);
};
