// todo: get languages from api
const AllowedLangauges: string[] = [
	'en_US',
	'en_GB',
	'ca',
	'eu_ES',
	'fr',
	'de',
	'it',
	'pt',
	'es',
	'sv',
	'ar',
	'fi',
];

interface LanguageAliasList {
	[key: string]: string;
}

const LangugeAliases: LanguageAliasList = {
	en: 'en_US',
};

const DefaultLanguage: string = 'en_US';

export function detect(): string {
	for (let clientLanguage of navigator.languages) {
		clientLanguage = clientLanguage.replace('-', '_');

		const [language, locale] = clientLanguage.split('_');

		if (locale) {
			clientLanguage = `${language}_${locale.toUpperCase()}`;
		}

		// Exact match, e.g. es_ES
		if (AllowedLangauges.includes(clientLanguage)) {
			return clientLanguage;
		}

		// We have the generic definition, e.g. es for spain
		if (AllowedLangauges.includes(language)) {
			return language;
		}

		if (LangugeAliases[clientLanguage]) {
			return LangugeAliases[clientLanguage];
		}
	}

	return DefaultLanguage;
}

export function set(language: string): void {
	localStorage.setItem('userLanguage', language);
}

export function get(): string {
	const storedLangauge: string = localStorage.getItem('userLanguage');

	if (storedLangauge) {
		return storedLangauge;
	}

	return detect();
}
