import { ReactNode } from 'react';

export const symbol_sports_gymnastics: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m489-108-19-370-149-52H70q-12.75 0-21.375-8.675Q40-547.351 40-560.175 40-573 48.625-581.5T70-590h213l253-181q10-7 20.962-5.533 10.961 1.466 19 10.266Q584-756 583-743t-12 21l-123 88 97 34 320-188q8-5 17.918-2.871 9.919 2.129 18 11.355Q909-771 907.471-759.659 905.941-748.317 896-741L574-507l-22 399q-.8 11.79-10.4 19.895T518.153-80q-11.661 0-20.043-8.105Q489.729-96.21 489-108ZM240.08-647q-30.08 0-51.58-21.42-21.5-21.421-21.5-51.5 0-30.08 21.42-51.58 21.421-21.5 51.5-21.5 30.08 0 51.58 21.42 21.5 21.421 21.5 51.5 0 30.08-21.42 51.58-21.421 21.5-51.5 21.5Z"
		/>
	</svg>
);
