import { ReactNode } from 'react';

export const symbol_inactive_order: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M703-289q0-8-6-14t-14-6q-8 0-14 6t-6 14v120q0 8 6 14t14 6q8 0 14-6t6-14v-120Zm60 0v120q0 8 6 14t14 6q8 0 14-6t6-14v-120q0-8-6-14t-14-6q-8 0-14 6t-6 14ZM270-620h420q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T690-680H270q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T270-620ZM732.5-41Q655-41 600-96.5T545-228q0-78.435 54.99-133.717Q654.98-417 733-417q77 0 132.5 55.283Q921-306.435 921-228q0 76-55.5 131.5T732.5-41ZM120-87v-693q0-24.75 17.625-42.375T180-840h600q24.75 0 42.375 17.625T840-780v327q-14.169-6.857-29.085-11.429Q796-469 780-472v-308H180v599h310q2.885 18.172 8.942 34.586Q505-130 513-114l-24 22q-5 4-10.5 4t-9.74-4.24L420-141l-49 49q-4.636 5-10.818 5Q354-87 349-92l-49-49-49 49q-4.636 5-10.818 5Q234-87 229-92l-49-49-49 49q-1 1-11 5Zm150-193h221.916q4.084-16 8.584-31t12.5-29H270q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T270-280Zm0-170h354q22-11 46-17.5t50-8.5q0-14-8.5-24T690-510H270q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T270-450Zm-90 269v-599 599Z"
		/>
	</svg>
);
