import { ReactNode } from 'react';

export const symbol_reply: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m236-494 142 142q9 9 8.5 21t-9.5 21q-9 9-21.5 9t-21.5-9L141-503q-9-9-9-21t9-21l194-194q9-9 21.5-9t21.5 9q9 9 9 21.5t-9 21.5L236-554h406q85 0 141.5 56.5T840-356v126q0 13-8.5 21.5T810-200q-13 0-21.5-8.5T780-230v-126q0-60-39-99t-99-39H236Z"
		/>
	</svg>
);
