import { t } from '@transifex/native';
import * as React from 'react';
import styled from 'styled-components';

import PositionScore from 'pkg/models/position_score';
import RatingScore from 'pkg/models/rating_score';
import Rating from 'pkg/models/rating';

import { translatedRatingItemCategory } from 'pkg/api/models/user_rating';

import CardAnatomy, * as Card from 'components/Card';
import MissingEntities from 'components/missing-entities';
import Icon from 'components/icon';

import PositionChart from 'components/user/profile/graph/Chart';
import ColorHints from 'components/user/profile/graph/ColorHints';

const Container = styled(Card.Body)`
	min-height: 400px;
	display: flex;
	align-items: center;
	text-align: center;
	flex-direction: column;
	justify-content: center;
`;

const Wrapper = styled(Container)`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

interface IDiagram {
	ideal: number;
	primary: number;
	fullMark: number;
	category: string;
}

const DEFAULT_DIAGRAM_DATA: IDiagram[] = [
	t(`Pace `),
	t(`Attacking`),
	t(`Passing `),
	t(`Technique`),
	t(`Defending `),
	t(`Physique`),
	t(`Heading`),
	t(`Vision`),
].map((category) => ({ ideal: 0, primary: 0, fullMark: 0, category }));

type ProfilePositionProps = {
	rating: Rating;
};

const ProfilePosition: React.FC<
	React.PropsWithChildren<ProfilePositionProps>
> = ({ rating }) => {
	let suggestedPosition: PositionScore = new PositionScore();

	if (rating?.positionScores) {
		suggestedPosition = rating.positionScores.find(
			(positionScore: PositionScore) =>
				positionScore.positionId === rating.suggestedPositionId
		);
	}

	const getRatingData = (): IDiagram[] => {
		const ratingScores: [] = rating.ratingScores || [];

		if (ratingScores.length === 0) {
			return DEFAULT_DIAGRAM_DATA;
		}

		const scoreAverages: {
			[key: string]: {
				categorySlug: string;
				value: number;
				ideal: number;
				values: number[];
				ideals: number[];
			};
		} = {};

		for (let k = 0; k < ratingScores.length; k++) {
			const item: RatingScore = ratingScores[k];
			const { category, categorySlug } = item.ratingItem;

			if (!scoreAverages[category]) {
				scoreAverages[category] = {
					categorySlug,
					value: 0,
					ideal: 0,
					values: [],
					ideals: [],
				};
			}

			scoreAverages[category].values.push(item.value);
			scoreAverages[category].ideals.push(item.difference);
		}

		for (const category in scoreAverages) {
			let values: number = 0;
			let diffs: number = 0;

			values = scoreAverages[category].values.reduce(
				(total: number, item: number) => total + item,
				0
			);

			diffs = scoreAverages[category].ideals.reduce(
				(total, item) => total + item,
				0
			);

			scoreAverages[category].value =
				values / scoreAverages[category].values.length;
			scoreAverages[category].ideal =
				scoreAverages[category].value -
				diffs / scoreAverages[category].ideals.length;
		}

		const data: IDiagram[] = [];

		const fullMark = Math.max(
			...Object.keys(scoreAverages).map((c) => scoreAverages[c].ideal),
			...Object.keys(scoreAverages).map((c) => scoreAverages[c].value)
		);

		for (const category in scoreAverages) {
			const score = scoreAverages[category];

			data.push({
				ideal: score.ideal,
				primary: score.value,
				fullMark,
				category: translatedRatingItemCategory(score.categorySlug),
			});
		}

		return data;
	};

	return (
		<CardAnatomy $noBorder>
			{rating?.id ? (
				<Wrapper>
					<PositionChart
						data={getRatingData()}
						idealName={`${t(`Ideal profile`)} ${
							suggestedPosition.position.name
						} ${suggestedPosition.position.name}`}
						primaryName={`${t(`Your profile`)} ${
							suggestedPosition.position.name
						} ${suggestedPosition.position.name}`}
					/>
					<ColorHints
						primary={t(`Your profile`)}
						secondary={t(`Ideal profile`)}
					/>
				</Wrapper>
			) : (
				<Container>
					<MissingEntities centered>
						<Icon name="field" />
						<p>{t(`Create a PositionMatch to see your profile position`)}</p>
					</MissingEntities>
				</Container>
			)}
		</CardAnatomy>
	);
};

export default ProfilePosition;
