import { ReactNode } from 'react';

export const symbol_rainy_snow: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M332.976-213.128Q322-207 310-211.5q-12-4.5-17-15.5l-60-120q-5-11-1.5-23t14.5-17q11-5 23.5-1.5T287-374l60 120q5 11 .976 22.872-4.025 11.873-15 18Zm360 0Q682-207 670-211.5q-12-4.5-17-15.5L473-587q-5-11-1.5-23t14.5-17q11-5 23.5-1.5T527-614l180 360q5 11 .976 22.872-4.025 11.873-15 18ZM509.825-210Q497-210 488.5-218.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Zm383.151-3.128Q882-207 870-211.5q-12-4.5-17-15.5l-20-40q-5-11-1.5-23t14.5-17q11-5 23.5-1.5T887-294l20 40q5 11 .976 22.872-4.025 11.873-15 18Zm-440-120Q442-327 430-331q-12-4-17-15L233-706q-5-11-1.5-23.5T246-747q11-5 23.5-1.5T287-734l180 360q5 11 .976 22.872-4.025 11.873-15 18ZM799.825-370Q787-370 778.5-378.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Zm-600-80Q187-450 178.5-458.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Zm553.151-43.128Q742-487 730.5-491.5 719-496 713-507L613-707q-5-11-1.5-23t14.5-17q11-5 23.5-1.5T667-734l100 200q5 11 .976 22.872-4.025 11.873-15 18Zm-600-80Q142-567 130-571q-12-4-17-15L53-706q-5-11-1.5-23.5T66-747q11-5 23.5-1.5T107-734l60 120q5 11 .976 22.872-4.025 11.873-15 18ZM449.825-690Q437-690 428.5-698.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Z"
		/>
	</svg>
);
