import { ReactNode } from 'react';

export const symbol_rebase_edit: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M392-110v-128q0-5.818 2-10.909Q396-254 401-259l293-293q9-9 20.5-13t22.5-4q11 0 21.963 4.5Q769.927-560 779-551l84 84q9 9 13 20.5t4 22.686q0 11.187-4 22Q872-391 863-382L571-89q-5 5-10.091 7-5.091 2-10.909 2H422q-12.75 0-21.375-8.625T392-110Zm428-315-84-84 84 84ZM452-140h85l173-173-43-42-43-42-172 171v86Zm215-215-43-42 43 42 43 42-43-42ZM200-80q-50 0-85-35t-35-85q0-41.758 25.5-73.879T170-316v-328q-39-10-64.5-42T80-760q0-50 35-85t85-35q42 0 74 25.5t42 64.5h155l-62-62q-9-9-9-21t8.842-21q8.842-9 21-9T451-894l114 113q9 9 9 21t-9 21L451-626q-9 8-21 8.5t-21-8.342q-9-8.842-9-21T409-668l62-62H316q-8 32-31 55t-55 31v328q39 10 64.5 42t25.5 74q0 50-35 85t-85 35Zm560-800q50 0 85 35t35 85q0 50-35 85t-85 35q-50 0-85-35t-35-85q0-50 35-85t85-35ZM200-140q25.5 0 42.75-17.25T260-200q0-25.5-17.25-42.75T200-260q-25.5 0-42.75 17.25T140-200q0 25.5 17.25 42.75T200-140Zm0-560q25.5 0 42.75-17.25T260-760q0-25.5-17.25-42.75T200-820q-25.5 0-42.75 17.25T140-760q0 25.5 17.25 42.75T200-700Zm560 0q25.5 0 42.75-17.25T820-760q0-25.5-17.25-42.75T760-820q-25.5 0-42.75 17.25T700-760q0 25.5 17.25 42.75T760-700ZM200-200Zm0-560Zm560 0Z"
		/>
	</svg>
);
