import { t } from '@transifex/native';

import * as models from 'pkg/api/models';

import * as Input from 'components/form/inputs';
import Column from 'components/layout/column';

interface MatchCompetitionFormProps {
	competition?: models.matchCompetition.MatchCompetition;
}

export default function MatchCompetitionForm({
	competition,
}: MatchCompetitionFormProps) {
	return (
		<Column>
			<Input.Group label={t('Title')}>
				<Input.Field
					name="title"
					required
					defaultValue={competition?.title || ''}
				/>
			</Input.Group>
			<Input.Group label={t('Description')} optional>
				<Input.Area
					name="description"
					minrows={4}
					defaultValue={competition?.description || ''}
				/>
			</Input.Group>
		</Column>
	);
}
