// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
	@media (max-width: 768px) {

.QfzNEHXdVEdK9hZNdU3r.QfzNEHXdVEdK9hZNdU3r {
		grid-template-areas: 'user' 'actions';
		grid-template-columns: 1fr;
}
	}

@media (max-width: 768px) {

.MRzk9EAx6YS5CknpZFlQ.MRzk9EAx6YS5CknpZFlQ {
		padding-bottom: 0;
}
	}
`, "",{"version":3,"sources":["webpack://./routes/group/show/pending-requests/styles.css"],"names":[],"mappings":";CACC;;AADD;EAEE,qCAAqC;EACrC,0BAA0B;AAE5B;CADC;;AAIA;;AADD;EAEE,iBAAiB;AAEnB;CADC","sourcesContent":[".card.card {\n\t@media (--small-viewport) {\n\t\tgrid-template-areas: 'user' 'actions';\n\t\tgrid-template-columns: 1fr;\n\t}\n}\n\n.user.user {\n\t@media (--small-viewport) {\n\t\tpadding-bottom: 0;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var card = `QfzNEHXdVEdK9hZNdU3r`;
export var user = `MRzk9EAx6YS5CknpZFlQ`;
export default ___CSS_LOADER_EXPORT___;
