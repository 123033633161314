import { ReactNode } from 'react';

export const symbol_farsight_digital: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M413-638v120q0 6 4 10t10 4q6 0 10-4t4-10v-148q0-5-2.5-7.5T431-676h-7q-2 0-6 2l-37 27q-5 3-5.5 7.5t2.5 9.5q3 5 8 6t10-2l17-12Zm118-16q12 0 20 6.5t8 16.5q0 11-5 21t-14 19l-59 58q-1 1-3 7v9q0 5 4 9t9 4h87q5 0 8.5-4t3.5-9q0-5-3.5-8.5T578-529h-64v-1l44-44q16-16 22.5-29t6.5-28q0-20-16-34.5T532-680q-17 0-30 7.5T482-652q-2 5 0 9.5t7 6.5q5 2 10 .5t8-6.5q5-6 11-9t13-3ZM408-280q26 0 43-14.5t17-37.5q0-14-8-25.5T439-374v-2q11-5 17-15.5t6-22.5q0-20-15.5-33T407-460q-14 0-26.5 7T360-435q-3 5-.5 9.5t7.5 6.5q5 2 9.5 1t7.5-6q2-3 22-11 12 0 19.5 6t7.5 17q0 11-8.5 18t-20.5 7q-5 0-9 3.5t-4 8.5q0 5 3.5 9t8.5 4h3q14 0 23.5 8t9.5 20q0 11-9 19t-22 8q-8 0-15.5-4T380-323q-3-5-7.5-6.5t-9.5.5q-5 2-8 7.5t0 10.5q8 14 22 22.5t31 8.5Zm142 0q24 0 42-17.5t18-40.5q0-23-15.5-39.5T558-394q-5 0-18 4h-1l31-44q3-5 2.5-10.5T567-453q-5-3-10-2t-8 6l-36 53q-14 20-18.5 32t-4.5 25q0 25 17 42t43 17Zm0-26q-14 0-23-9t-9-23q0-8 4-15.5t12-12.5q3-2 16-4 13 0 22.5 9.5T582-337q0 13-9 22t-23 9ZM480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-82 31.5-155t86-127.5Q252-817 325-848.5T480-880q82 0 155 31.5t127.5 86Q817-708 848.5-635T880-480q0 82-31.5 155t-86 127.5Q708-143 635-111.5T480-80Zm0-60q140 0 240-100t100-240q0-140-100-240T480-820q-140 0-240 100T140-480q0 140 100 240t240 100Zm0-340Z"
		/>
	</svg>
);
