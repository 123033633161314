import { ReactNode } from 'react';

export const symbol_photo_camera_front: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-120q-24 0-42-18t-18-42v-513q0-23 18-41.5t42-18.5h147l55-66q8-11 20-16t26-5h184q14 0 26 5t20 16l55 66h147q23 0 41.5 18.5T880-693v513q0 24-18.5 42T820-120H140Zm0-60h680v-513H645l-73-87H388l-73 87H140v513Zm340-257ZM320-286h320v-19q0-42-42.5-65T480-393q-75 0-117.5 23T320-305v19Zm159.92-174q30.08 0 51.58-21.42 21.5-21.421 21.5-51.5 0-30.08-21.42-51.58-21.421-21.5-51.5-21.5-30.08 0-51.58 21.42-21.5 21.421-21.5 51.5 0 30.08 21.42 51.58 21.421 21.5 51.5 21.5Z"
		/>
	</svg>
);
