import { ReactNode } from 'react';

export const symbol_hdr_plus: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M596-315h-46q-9 0-15.5-7t-6.5-16q0-9 7-15.5t16-6.5h45v-46q0-9 7-15.5t16-6.5q9 0 15.5 6.5T641-406v46h46q9 0 15.5 6.5T709-338q0 9-6.5 16t-15.5 7h-46v46q0 9-6.5 15.5T619-247q-9 0-16-7t-7-16v-45ZM321-596v85q0 9-6.5 15.5T299-489q-9 0-16-6.5t-7-15.5v-196q0-9 7-15.5t16-6.5q9 0 15.5 6.5T321-707v66h105v-66q0-9 7-15.5t16-6.5q9 0 15.5 6.5T471-707v196q0 9-6.5 15.5T449-489q-9 0-16-7t-7-16v-84H321Zm212 84v-195q0-9 7-15.5t16-6.5h108q19 0 32 13t13 32v150q0 19-13 32t-32 13H556q-9 0-16-7t-7-16ZM419-315q5 0 8.5-3.5t3.5-8.5v-43q0-5-3.5-8.5T419-382h-92v67h92Zm-92 43v66q0 9-6 15t-15 6q-9 0-15.5-6.5T284-207v-196q0-9 7-15.5t16-6.5h124q18 0 30.5 12.5T474-382v67q0 17-11.5 29T434-272l23 60q4 10-2 18.5t-17 8.5q-6 0-11.5-3.5T419-198l-28-74h-64Zm251-262h74q5 0 8.5-3.5t3.5-8.5v-126q0-5-3.5-8.5T652-684h-74v150ZM480-80q-85 0-158-30.5T195-195q-54-54-84.5-127T80-480q0-84 30.5-157T195-764q54-54 127-85t158-31q84 0 157 31t127 85q54 54 85 127t31 157q0 85-31 158t-85 127q-54 54-127 84.5T480-80Zm0-60q145 0 242.5-97.5T820-480q0-145-97.5-242.5T480-820q-145 0-242.5 97.5T140-480q0 145 97.5 242.5T480-140Zm0-340Z"
		/>
	</svg>
);
