export default {
	name: 'contact-card',
	objects: [
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M6.72961 2.5104C7.08859 2.5104 7.37961 2.80141 7.37961 3.1604V4.9121H16.4869V3.1604C16.4869 2.80141 16.7779 2.5104 17.1369 2.5104C17.4959 2.5104 17.7869 2.80141 17.7869 3.1604V4.91253C19.2283 4.93822 20.3888 6.11464 20.3888 7.5621V18.8396C20.3888 20.3031 19.2023 21.4896 17.7388 21.4896H6.26121C4.79765 21.4896 3.61121 20.3031 3.61121 18.8396V7.5621C3.61121 6.15958 4.70076 5.01154 6.07961 4.91823V3.1604C6.07961 2.80141 6.37062 2.5104 6.72961 2.5104ZM6.26121 6.2121C5.51562 6.2121 4.91121 6.81652 4.91121 7.5621V18.8396C4.91121 19.5851 5.51562 20.1896 6.26121 20.1896H17.7388C18.4843 20.1896 19.0888 19.5851 19.0888 18.8396V7.5621C19.0888 6.81652 18.4843 6.2121 17.7388 6.2121H6.26121Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M12 12.4946C12.8762 12.4946 13.5866 11.7843 13.5866 10.908C13.5866 10.0317 12.8762 9.32141 12 9.32141C11.1237 9.32141 10.4134 10.0317 10.4134 10.908C10.4134 11.7843 11.1237 12.4946 12 12.4946ZM12 13.7946C13.5942 13.7946 14.8866 12.5022 14.8866 10.908C14.8866 9.31378 13.5942 8.02141 12 8.02141C10.4057 8.02141 9.11337 9.31378 9.11337 10.908C9.11337 12.5022 10.4057 13.7946 12 13.7946Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M6.90649 18.2355C6.90649 16.0718 8.66049 14.3178 10.8242 14.3178H13.1758C15.3394 14.3178 17.0935 16.0718 17.0935 18.2355H15.7935C15.7935 16.7898 14.6215 15.6178 13.1758 15.6178H10.8242C9.37846 15.6178 8.20649 16.7898 8.20649 18.2355H6.90649Z',
			},
		},
	],
};
