import { ReactNode } from 'react';

export const symbol_surgical: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M850-120H477q-15 0-22.5-9.5T447-150q0-11 7.5-20.5T477-180h373q13 0 21.5 8.5T880-150q0 13-8.5 21.5T850-120ZM517-367q-9 9-21 9t-21-9L367-475q-9-9-9-21t9-21l308-308q7-7 15-10t17-3q9 0 16.5 3t14.5 10l87 87q7 7 10 14.5t3 16.5q0 9-3 17t-10 15L517-367Zm-21-64 276-276-65-65-276 276 65 65ZM390-188q-32 32-74.5 50T227-120h-24q-35 0-67-10t-61-29q-8-5-12-13.5T59-190q0-8 3.5-16t9.5-14l212-211q9-9 21-9t21 9l83 83q14 14 22 32t8 38q0 20-8 38.5T409-207l-19 19Zm-187 8h24q34 0 65.5-13t55.5-37l20-20q12-12 10.5-28.5T365-307l-60-60-176 175q18 6 36.5 9t37.5 3Zm569-527-65-65 65 65ZM305-367Z"
		/>
	</svg>
);
