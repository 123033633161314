import { Fragment, useContext } from 'react';

import * as models from 'pkg/api/models';
import { useQueryState } from 'pkg/hooks/query-state';

import { TemplateContext } from 'routes/scheduling/templates/single';
import ResourceRow from 'routes/scheduling/templates/single/resources/ResourceRow';
import {
	defaultFilters,
	hasBeenFiltered,
} from 'routes/scheduling/templates/filters';
import {
	findResourcesByFilterByScheduleItems,
	getItemResources,
} from 'routes/scheduling/utils';

interface resourceRow {
	resource: models.resource.Resource;
	items: models.scheduleItem.ScheduleItem[];
}

export interface resourceGrid {
	[key: number]: resourceRow;
}

const ResourceGrid = () => {
	const qs = useQueryState();
	const search = qs.get('search')?.toString().toLowerCase();
	const qsFilters = qs.get('schedulingFilters');
	const filters =
		qsFilters !== 'undefined' && qsFilters
			? JSON.parse(qsFilters as string)
			: defaultFilters;
	const hasFiltered = hasBeenFiltered(filters);

	const templateContext = useContext(TemplateContext);
	const grid = new Map<string, resourceRow>();

	templateContext.resources.forEach((r) => {
		grid.set(r.id.toString(), {
			resource: r,
			items: [],
		});
	});

	templateContext.scheduleItems.forEach((item) => {
		getItemResources(item).forEach((r) => {
			const resourceId = r.id.toString();
			if (!grid.has(resourceId)) {
				if (!r) {
					return;
				}

				grid.set(resourceId, { resource: r, items: [] });
			}

			const gridItem = grid.get(resourceId);
			grid.set(resourceId, {
				...gridItem,
				items: [...gridItem.items, item],
			});
		});
	});

	if (search) {
		for (const k of grid.keys()) {
			if (!grid.get(k).resource.title.toLocaleLowerCase().includes(search)) {
				grid.delete(k);
			}
		}
	}

	const resourcesToList = findResourcesByFilterByScheduleItems(
		templateContext.resources,
		templateContext.scheduleItems,
		filters,
		templateContext.conflicts
	);

	const resourceIds = [...new Set(resourcesToList.map((r) => r.id))];

	if (hasFiltered) {
		for (const k of grid.keys()) {
			if (!resourceIds.includes(Number.parseInt(k, 10))) {
				grid.delete(k);
			}
		}
	}

	if (grid.size === 0) {
		return null;
	}

	const resourceRowItems: JSX.Element[] = [];

	grid.forEach((row) =>
		resourceRowItems.push(
			<ResourceRow
				key={row.resource.id}
				resource={row.resource}
				filters={filters}
				scheduleItems={row.items}
			/>
		)
	);

	return <Fragment>{resourceRowItems}</Fragment>;
};

export default ResourceGrid;
