import { ReactNode } from 'react';

export const symbol_view_comfy_alt: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M340-560H140q-24.75 0-42.375-17.625T80-620v-200q0-24.75 17.625-42.375T140-880h200q24.75 0 42.375 17.625T400-820v200q0 24.75-17.625 42.375T340-560Zm-200-60h200v-200H140v200ZM340-80H140q-24.75 0-42.375-17.625T80-140v-200q0-24.75 17.625-42.375T140-400h200q24.75 0 42.375 17.625T400-340v200q0 24.75-17.625 42.375T340-80Zm-200-60h200v-200H140v200Zm680-420H620q-24.75 0-42.375-17.625T560-620v-200q0-24.75 17.625-42.375T620-880h200q24.75 0 42.375 17.625T880-820v200q0 24.75-17.625 42.375T820-560Zm-200-60h200v-200H620v200ZM820-80H620q-24.75 0-42.375-17.625T560-140v-200q0-24.75 17.625-42.375T620-400h200q24.75 0 42.375 17.625T880-340v200q0 24.75-17.625 42.375T820-80Zm-200-60h200v-200H620v200ZM340-620Zm0 280Zm280-280Zm0 280Z"
		/>
	</svg>
);
