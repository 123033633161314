import * as flashActions from 'pkg/actions/flashes';

import { crash } from 'pkg/errors/errors';
import * as sdk from 'pkg/core/sdk';
import * as endpoints from 'pkg/api/endpoints/auto';

export async function setAsPrimary(
	groupId: number,
	id: number
): Promise<boolean> {
	const req = await sdk.post(endpoints.PaymentMethods.MakePrimary(groupId, id));
	const res = await req.json();

	if (!req.ok) {
		flashActions.show({
			title: crash().title,
			message: res.error,
		});

		return false;
	}

	return true;
}

export async function remove(groupId: number, id: number): Promise<boolean> {
	const req = await sdk.post(endpoints.PaymentMethods.Archive(groupId, id));
	const res = await req.json();

	if (!req.ok) {
		flashActions.show({
			title: crash().title,
			message: res.error,
		});

		return false;
	}

	return true;
}
