import { ReactNode } from 'react';

export const symbol_22mp: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M300-560v-53h80q14 0 27-13t13-27v-57q0-14-13-27t-27-13H275q-10.833 0-17.917 7.116-7.083 7.117-7.083 18Q250-714 257.083-707q7.084 7 17.917 7h95v54h-80q-14 0-27 13t-13 27v71q0 10.833 7.083 17.917Q264.167-510 275-510h120q10.833 0 17.917-7.116 7.083-7.117 7.083-18Q420-546 412.917-553q-7.084-7-17.917-7h-95Zm283 0v-53h80q14 0 27-13t13-27v-57q0-14-13-27t-27-13H558q-10.833 0-17.917 7.116-7.083 7.117-7.083 18Q533-714 540.083-707q7.084 7 17.917 7h95v54h-80q-14 0-27 13t-13 27v71q0 10.833 7.083 17.917Q547.167-510 558-510h120q10.833 0 17.917-7.116 7.083-7.117 7.083-18Q703-546 695.917-553q-7.084-7-17.917-7h-95ZM180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-60h600v-600H180v600Zm0-600v600-600Zm103 380h53v102q0 10.833 7.116 17.917 7.117 7.083 18 7.083Q372-273 379-280.083q7-7.084 7-17.917v-102h60v165q0 10.833 7.116 17.917 7.117 7.083 18 7.083Q482-210 489-217.083q7-7.084 7-17.917v-175q0-14-13-27t-27-13H273q-14 0-27 13t-13 27v175q0 10.833 7.116 17.917 7.117 7.083 18 7.083Q269-210 276-217.083q7-7.084 7-17.917v-165Zm323 127h80q14 0 27-13t13-27v-97q0-14-13-27t-27-13H581q-10.833 0-17.917 7.083Q556-435.833 556-425v190q0 10.833 7.116 17.917 7.117 7.083 18 7.083Q592-210 599-217.083q7-7.084 7-17.917v-38Zm0-50v-77h70v77h-70Z"
		/>
	</svg>
);
