import { ReactNode } from 'react';

export const symbol_inventory: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m620-245 218-218q9-9 21-8.5t21 9.5q9 9 9 21.5t-9 21.5L641-180q-9 9-21 9t-21-9L481-298q-9-9-8.5-21.5T482-341q9-9 21.5-9t21.5 9l95 96ZM180-120q-26 0-43-17t-17-43v-600q0-26 17-43t43-17h202q7-35 34.5-57.5T480-920q36 0 63.5 22.5T578-840h202q26 0 43 17t17 43v177q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T780-603v-177h-60v60q0 12.75-8.625 21.375T690-690H270q-12.75 0-21.375-8.625T240-720v-60h-60v600h221q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T401-120H180Zm300-660q17 0 28.5-11.5T520-820q0-17-11.5-28.5T480-860q-17 0-28.5 11.5T440-820q0 17 11.5 28.5T480-780Z"
		/>
	</svg>
);
