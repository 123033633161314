import { t } from '@transifex/native';
import React, { ChangeEvent, useContext, Fragment, useState } from 'react';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import { useCurrentGroup } from 'pkg/identity';

import {
	AddProductContext,
	emptyPriceOption,
} from 'routes/payments/products/create';
import * as productFormSharedStyles from 'routes/payments/products/form/shared.css';
import PriceOption from 'routes/payments/products/form/price_option';
import ProductMetadata from 'routes/payments/products/form/meta_data';

import AddCategoryModal from 'containers/products/AddCategoryModal';

import * as Input from 'components/form/inputs';
import Form from 'components/form/Form';
import Section from 'components/form/Section';
import Column from 'components/layout/column';

interface AddFormProps {
	productCategories: models.productCategory.ProductCategory[];
	refreshCategories: () => void;

	// Option to only allow a single price option
	singlePriceOption?: boolean;
}

const AddForm = ({
	productCategories,
	refreshCategories,
	singlePriceOption = false,
}: AddFormProps): JSX.Element => {
	const addProductContext = useContext(AddProductContext);

	const [showModal, setShowModal] = useState(false);
	const [selectValue, setSelectValue] = useState(
		addProductContext.formState.category
	);

	const hideCategoryModal = () => setShowModal(false);

	const group = useCurrentGroup();
	const groupId = group.id;

	const handleNewCategory = (id: string) => {
		setSelectValue(id);

		addProductContext.setFormState({
			category: id,
		});
	};

	const handleInputChange = (
		event: ChangeEvent<
			HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
		>
	) => {
		const { name, value } = event.target;

		if (value === 'newCategory') {
			setShowModal(true);
			return;
		}

		if (event.target.tagName.toLowerCase() === 'select') {
			setSelectValue(value);
		}

		addProductContext.setFormState({
			[name]: value,
		});
	};

	const addPriceOption = () => {
		addProductContext.setFormState({
			priceOptions: [
				...addProductContext.formState.priceOptions,
				emptyPriceOption(group.currency, addProductContext.providerSettings),
			],
		});
	};

	const removePriceOption = (index: number) => {
		addProductContext.setFormState({
			priceOptions: [
				...addProductContext.formState.priceOptions.filter(
					(po, i) => i !== index
				),
			],
		});
	};

	const handleToggle = () => {
		addProductContext.setFormState({
			isDefault: !addProductContext.formState.isDefault,
		});
	};

	const handleUpdateMetadata = (metadata: models.product.ProductMetadata) => {
		const currentData = addProductContext.formState.metadata;
		const item = currentData.findIndex((d) => metadata.id === d.id);

		if (item !== -1) {
			currentData[item] = metadata;

			addProductContext.setFormState({
				metadata: currentData,
			});
		} else {
			addProductContext.setFormState({
				metadata: [...currentData, metadata],
			});
		}
	};

	const handleRemoveMetadata = (id: number | string) => {
		const currentData = addProductContext.formState.metadata;

		addProductContext.setFormState({
			metadata: currentData.filter((d) => d.id !== id),
		});
	};

	return (
		<Fragment>
			<Form>
				<Section
					icon="info"
					title={t('Product information')}
					description={t('Fill in the basic information for the product')}>
					<Column>
						<div className={productFormSharedStyles.fieldWrapper}>
							<Column>
								<Input.Group
									label={t('Title')}
									hint={t('Example: Club membership')}>
									<Input.Field
										value={addProductContext.formState.productTitle}
										name="productTitle"
										onChange={handleInputChange}
									/>
								</Input.Group>
								<Input.Group optional label={t('Category')}>
									<Input.Select
										name="category"
										onChange={handleInputChange}
										value={selectValue}>
										<option value="">{t('No category')}</option>
										<option value="newCategory">{t('New category')}</option>
										{productCategories.map(
											(
												category: models.productCategory.ProductCategory,
												index: number
											) => (
												<option key={index} value={category.id}>
													{category.name}
												</option>
											)
										)}
									</Input.Select>
								</Input.Group>
							</Column>
						</div>
						<Input.Group optional label={t('Description')}>
							<Input.Area
								name="description"
								minRows={4}
								value={addProductContext.formState.description}
								onChange={handleInputChange}
							/>
						</Input.Group>

						<Input.Control
							checked={addProductContext.formState.isDefault}
							type="toggle"
							position="after"
							onChange={handleToggle}
							label={t('Show on contacts list')}
							description={t(
								'With this option turned on, this product will become a standard column on the contacts list. Recommended for memberships and subscriptions.'
							)}
						/>
					</Column>
				</Section>
				<Section
					icon="database"
					title={t('Metadata')}
					description={t(
						'Add custom meta data to your product, e.g accounting number'
					)}>
					<ProductMetadata
						metadataKeys={addProductContext.metadataKeys}
						metadata={addProductContext.formState.metadata}
						onUpdateMetadata={handleUpdateMetadata}
						handleRemoveMetadata={handleRemoveMetadata}
					/>
				</Section>
				<Section
					hideDivider
					icon="sell"
					title={t('Price options')}
					description={t('Add price option(s) to your product')}>
					<Column spacing={styles.spacing._5}>
						{addProductContext.formState.priceOptions.map(
							(item, index: number) =>
								React.createElement(PriceOption, {
									key: index,
									index: index,
									currency: group.currency,
									addPriceOption: addPriceOption,
									removePriceOption: removePriceOption,
									singlePriceOption,
								})
						)}
					</Column>
				</Section>
			</Form>
			{showModal && (
				<AddCategoryModal
					onClose={hideCategoryModal}
					groupId={groupId}
					handleNewCategory={handleNewCategory}
					refresh={refreshCategories}
				/>
			)}
		</Fragment>
	);
};

export default AddForm;
