// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JEBtvn1PpYCSYwUuyuAi {
	line-height: var(--font-line-height-xs);
	font-weight: var(--font-weight-semibold);
}

.JEBtvn1PpYCSYwUuyuAi.yjgyDKzwdJVz8qmzVmDN {
		color: var(--palette-green-500);
	}

.JEBtvn1PpYCSYwUuyuAi.Fo8hwjjCiz5PfqAykRXv {
		color: var(--palette-red-500);
	}

.JEBtvn1PpYCSYwUuyuAi.Q12OLiCTCrpMmZ67iR0e {
		color: var(--palette-orange-500);
	}

@media (max-width: 768px) {

.JEBtvn1PpYCSYwUuyuAi {
		font-size: var(--font-size-sm);
}
	}

.S74IAaqksV67Prfntc_1 {
	padding: var(--spacing-3) !important;
}

@media (max-width: 768px) {

.S74IAaqksV67Prfntc_1 {
		padding: var(--spacing-4) !important;
}
	}
`, "",{"version":3,"sources":["webpack://./components/event/user-card/styles.css"],"names":[],"mappings":"AAAA;CACC,uCAAuC;CACvC,wCAAwC;AAiBzC;;AAfC;EACC,+BAA+B;CAChC;;AAEA;EACC,6BAA6B;CAC9B;;AAEA;EACC,gCAAgC;CACjC;;AAEA;;AAhBD;EAiBE,8BAA8B;AAEhC;CADC;;AAGD;CACC,oCAAoC;AAKrC;;AAHC;;AAHD;EAIE,oCAAoC;AAEtC;CADC","sourcesContent":[".name {\n\tline-height: var(--font-line-height-xs);\n\tfont-weight: var(--font-weight-semibold);\n\n\t&.accepted {\n\t\tcolor: var(--palette-green-500);\n\t}\n\n\t&.declined {\n\t\tcolor: var(--palette-red-500);\n\t}\n\n\t&.unset {\n\t\tcolor: var(--palette-orange-500);\n\t}\n\n\t@media (--small-viewport) {\n\t\tfont-size: var(--font-size-sm);\n\t}\n}\n\n.cardBody {\n\tpadding: var(--spacing-3) !important;\n\n\t@media (--small-viewport) {\n\t\tpadding: var(--spacing-4) !important;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var name = `JEBtvn1PpYCSYwUuyuAi`;
export var accepted = `yjgyDKzwdJVz8qmzVmDN`;
export var declined = `Fo8hwjjCiz5PfqAykRXv`;
export var unset = `Q12OLiCTCrpMmZ67iR0e`;
export var cardBody = `S74IAaqksV67Prfntc_1`;
export default ___CSS_LOADER_EXPORT___;
