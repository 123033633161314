import { ReactNode } from 'react';

export const symbol_girl: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M479.899-680Q455-680 437.5-697.601t-17.5-42.5Q420-765 437.601-782.5t42.5-17.5Q505-800 522.5-782.399t17.5 42.5Q540-715 522.399-697.5t-42.5 17.5ZM420-190v-150h-29q-16 0-24.5-13t-3.5-27l71-208q5-14 17.627-23 12.626-9 28.5-9 15.873 0 28.373 9 12.5 9 17.5 23l71 208q5 14-3.5 27T569-340h-29v150q0 12.75-8.646 21.375T509.927-160h-60.146Q437-160 428.5-168.625 420-177.25 420-190Z"
		/>
	</svg>
);
