import { ReactNode } from 'react';

export const symbol_water_bottle_large: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M260-80q-24.75 0-42.375-17.625T200-140v-120q0-24.75 17.625-42.375T260-320h20v-200h-20q-24.75 0-42.375-17.625T200-580v-120q0-24.75 17.625-42.375T260-760h150v-60h-20q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T390-880h180q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T570-820h-20v60h150q24.75 0 42.375 17.625T760-700v120q0 24.75-17.625 42.375T700-520h-20v200h20q24.75 0 42.375 17.625T760-260v120q0 24.75-17.625 42.375T700-80H260Zm0-60h440v-120h-80v-260h-50q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T570-580h130v-120H260v120h80v260h50q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T390-260H260v120Zm220-280Z"
		/>
	</svg>
);
