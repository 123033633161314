import { ReactNode } from 'react';

export const symbol_gite: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-200q-24.75 0-42.375-17.625T80-260v-281q0-12.444 5-23.722T98-584l118-118q8-8 19.278-13 11.278-5 23.722-5h35v-50q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T354-770v50h347q12.444 0 23.722 5T744-702l118 118q8 8 13 19.278 5 11.278 5 23.722v281q0 24.75-17.625 42.375T820-200H140Zm500-60h180v-281l-90-90-90 90v281Zm-60 0v-240H140v240h440Z"
		/>
	</svg>
);
