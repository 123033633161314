import React, {
	Children,
	cloneElement,
	useState,
	useEffect,
	useRef,
	useMemo,
	ReactElement,
} from 'react';
import { t } from '@transifex/native';

import DateTime from 'pkg/datetime';
import { cssClasses } from 'pkg/css/utils';
import * as models from 'pkg/api/models';

import {
	MonthEventLargeScreenHeight,
	MonthEventSmallDesktopHeight,
	MonthEventSmallScreenHeight,
} from 'routes/group/calendar/config';
import { ViewOptions, useCalendarContext } from 'routes/group/calendar';
import { SharedEventProps } from 'routes/group/calendar/components/events/single';

import * as css from './styles.css';

// Interface that contains all functions that this component adds
export interface ClonedEventProps {
	hideInfo?: boolean;
	overlappingEvents?: {
		[key: string]: number[];
	};
}

interface DayProps {
	fixedHeight?: boolean;
	dateTime: DateTime;
	calculateHeight?: boolean;
	calculateOverlap?: boolean;

	children: ReactElement[];
}

const Day = ({
	children,
	fixedHeight = false,
	dateTime,
	calculateHeight = false,
	calculateOverlap = false,
}: DayProps) => {
	const calendarCtx = useCalendarContext();

	const eventHolderRef = useRef(null);
	const [clipContent, setClipContent] = useState(false);
	const amountOfChildren = Children.count(children);

	const fixedEventHeight = () => {
		if (window.innerWidth > 640 && window.innerHeight > 850) {
			return MonthEventLargeScreenHeight;
		} else if (window.innerWidth >= 769 && window.innerHeight <= 680) {
			return MonthEventSmallDesktopHeight;
		} else {
			return MonthEventSmallScreenHeight;
		}
	};

	useEffect(() => {
		if (fixedHeight) {
			if (
				amountOfChildren * fixedEventHeight() >
				eventHolderRef.current.getBoundingClientRect().height
			) {
				setClipContent(true);
			} else if (
				amountOfChildren * fixedEventHeight() <
					eventHolderRef.current.getBoundingClientRect().height &&
				clipContent === true
			) {
				setClipContent(false);
			}
		}
	}, [amountOfChildren, clipContent, children, fixedHeight]);

	let events = children;
	let hiddenEvents = 0;

	[events, hiddenEvents] = useMemo(() => {
		if (clipContent) {
			const amountToShow = Math.floor(
				eventHolderRef.current.getBoundingClientRect().height /
					fixedEventHeight() -
					1
			);

			const visibleEvents = [];
			for (const [i, child] of Children.toArray(events).entries()) {
				if (React.isValidElement(child)) {
					if (i < amountToShow) {
						visibleEvents.push(child);
					} else {
						break;
					}
				}
			}

			return [visibleEvents, amountOfChildren - amountToShow];
		}

		return [events, 0];
	}, [amountOfChildren, clipContent, events]);

	events = useMemo(() => {
		if (calculateHeight && calculateOverlap) {
			const overlappingEvents: { [key: string]: Set<number> } = {};
			Children.forEach(events, (child) => {
				if (React.isValidElement<SharedEventProps>(child)) {
					const event = child.props.event;
					const eventStartsAt =
						models.event.getStartsAtIncludingMeetBeforeMinutes(event);

					overlappingEvents[event.id] = new Set([]);

					const startOfDay = Math.ceil(dateTime.startOfDay / 1000);
					const endOfDay = Math.floor(dateTime.endOfDay / 1000);

					const firstStart =
						eventStartsAt < startOfDay ? startOfDay : eventStartsAt;
					const firstEnd =
						child.props.event.endsAt > endOfDay
							? endOfDay
							: child.props.event.endsAt;

					Children.forEach(events, (child2) => {
						if (React.isValidElement<SharedEventProps>(child)) {
							const innerEvent = child2.props.event;
							const innerEventStartsAt =
								models.event.getStartsAtIncludingMeetBeforeMinutes(innerEvent);

							if (!overlappingEvents[innerEvent.id]) {
								overlappingEvents[innerEvent.id] = new Set();
							}
							const secondStart =
								innerEventStartsAt < startOfDay
									? startOfDay
									: innerEventStartsAt;
							const secondEnd =
								innerEvent.endsAt > endOfDay ? endOfDay : innerEvent.endsAt;

							if (
								(firstStart > secondStart && firstStart < secondEnd) ||
								(firstEnd > secondStart && firstEnd < secondEnd) ||
								firstStart === secondStart ||
								firstEnd === secondEnd
							) {
								overlappingEvents[event.id].add(innerEvent.id);
								overlappingEvents[innerEvent.id].add(event.id);
							}
						}
					});
				}
			});

			const transformedOverlappingEvents: { [key: string]: number[] } = {};

			Object.entries(overlappingEvents).forEach(([id, list]) => {
				const arr = [...list];
				transformedOverlappingEvents[id] = arr;
			});

			Object.entries(overlappingEvents).forEach(([id, list]) => {
				let arr = [...list];
				list.forEach((id) => {
					if (overlappingEvents[id].size > arr.length) {
						arr = [...overlappingEvents[id]];
					}
				});
				transformedOverlappingEvents[id] = arr;
			});

			return Children.map(events, (child) => {
				const starts = child.props.event.startsAt;
				const ends = child.props.event.endsAt;
				const startOfDay = Math.ceil(dateTime.startOfDay / 1000);
				const endOfDay = Math.floor(dateTime.endOfDay / 1000);

				return cloneElement(
					child,
					{
						dayDateTime: dateTime,
						overlappingEvents: transformedOverlappingEvents,
						hideInfo:
							(starts < startOfDay && ends > endOfDay) ||
							(starts < startOfDay && ends < endOfDay),
					},
					null
				);
			});
		}
		return events;
	}, [
		events,
		calculateHeight,
		calculateOverlap,
		dateTime.startOfDay,
		dateTime.endOfDay,
	]);

	return (
		<div
			className={cssClasses(
				css.eventList,
				calendarCtx.currentTab === ViewOptions.Year ? css.yearView : ''
			)}
			ref={eventHolderRef}>
			{events}
			{hiddenEvents > 0 && (
				<span>
					{hiddenEvents} {t('More')}...
				</span>
			)}
		</div>
	);
};

export default Day;
