import { ReactNode } from 'react';

export const symbol_camera_outdoor: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M510-210q-14 0-24.5-10.5T475-245v-170q0-14 10.5-24.5T510-450h175q14 0 24.5 10.5T720-415v55l58-33q7.586-5 14.793-.5Q800-389 800-380v100q0 8.609-7 13.304-7 4.696-15-.304l-58-33v55q0 14-10.5 24.5T685-210H510Zm-350 30v-390q0-14.25 6.375-27T184-618l260-195q15.68-12 35.84-12Q500-825 516-813l272 204q12.235 9 12.618 21 .382 12-6.414 21-6.796 9-18.5 12T752-561L480-765 220-570v390h550q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T770-120H220q-24.75 0-42.375-17.625T160-180Zm320-292Z"
		/>
	</svg>
);
