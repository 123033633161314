import { ReactNode } from 'react';

export const symbol_expand: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M190-80q-13 0-21.5-8.5T160-110q0-13 8.5-21.5T190-140h580q13 0 21.5 8.5T800-110q0 13-8.5 21.5T770-80H190Zm292-112q-6 0-11-2t-10-7L351-311q-9-9-8.5-21.5T352-354q9-9 21.5-9t21.5 9l57 58v-368l-58 58q-9 9-21 9t-21-9q-9-9-9-21.5t9-21.5l109-110q5-5 10-7t11-2q6 0 11 2t10 7l110 110q9 9 9 21t-9 21q-9 9-21.5 9t-21.5-9l-58-57v368l58-58q9-9 21.5-8.5T613-353q9 9 9 21.5t-9 21.5L503-201q-5 5-10 7t-11 2ZM190-820q-13 0-21.5-8.5T160-850q0-13 8.5-21.5T190-880h580q13 0 21.5 8.5T800-850q0 13-8.5 21.5T770-820H190Z"
		/>
	</svg>
);
