import { ReactNode } from 'react';

export const symbol_kettle: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M250-260v-473l-74-99q-11-15-2.809-31.5Q181.383-880 200-880h453q27.854 0 47.427 19.573T720-813v63h100q24.75 0 42.375 17.625T880-690v220q0 24.75-17.625 42.375T820-410H720v150q0 24.75-17.625 42.375T660-200H310q-24.75 0-42.375-17.625T250-260Zm60 0h350v-560H260l50 65v495Zm410-210h100v-220H720v220ZM549.882-760Q529-760 514.5-745.417 500-730.833 500-710v340q0 20.833 14.618 35.417Q529.235-320 550.118-320 571-320 585.5-334.583 600-349.167 600-370v-340q0-20.833-14.618-35.417Q570.765-760 549.882-760ZM150-80q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-140h660q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q840-97 831.375-88.5T810-80H150Zm310-460Z"
		/>
	</svg>
);
