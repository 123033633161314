import { ReactNode } from 'react';

export const symbol_notifications_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M679-200H190q-13 0-21.5-8.5T160-230q0-13 8.5-21.5T190-260h50v-304q0-33 8-65t25-61l45 45q-9 19-13.5 39.5T300-564v304h319L96-784q-9-9-9-21t9-21q9-9 21-9t21 9l684 685q9 9 9 21t-9 21q-9 9-21 9t-21-9L679-200ZM540-798q81 17 130.5 83.5T720-564v163q0 15-9.5 22.5T690-371q-11 0-20.5-7.5T660-401v-163q0-75-52.5-127.5T480-744q-27 0-52.5 8T380-713q-12 8-26 7.5T331-717q-8-10-8-21.5t10-19.5q19-14 41-24.5t46-15.5v-22q0-25 17.5-42.5T480-880q25 0 42.5 17.5T540-820v22Zm-80 378Zm20 340q-31 0-54.5-18T402-145q0-7 4.5-11t11.5-4h124q7 0 11.5 4t4.5 11q0 29-23.5 47T480-80Zm28-461Z"
		/>
	</svg>
);
