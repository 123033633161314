import { ReactNode } from 'react';

export const symbol_keep_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M574-780H380v140L218-802q-4-4-5-7t-1-8q0-8.794 5.5-15.897T234-840h400q12.75 0 21.375 8.625T664-810q0 12-10.875 16.875T634-780v327q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T574-453v-327ZM450-70v-241H271q-17.273 0-27.636-12Q233-335 233-348.877q0-6.938 2.842-13.877Q238.684-369.692 245-376l75-72v-84L76-776q-9-9-9-21t9-21q9-9 21-9t21 9l707 708q9 9 9 21t-9 21.391Q816-59 804-59t-21-9L540-311h-30v241q0 12.75-8.675 21.375Q492.649-40 479.825-40 467-40 458.5-48.625T450-70ZM321-371h159L380-471v45l-59 55Zm156-172Zm-97 72Z"
		/>
	</svg>
);
