import Icon from 'components/icon';
import SectionTitle from 'components/SectionTitle';

import * as Table from 'components/form/Table';
import Column from 'components/layout/column';

const FormTable = () => {
	const data = [
		{
			name: 'Club membership',
			quantity: 1,
			price: '100€ / month',
			tax: '10%',
			total: '110(€)',
		},
		{
			name: 'Not club membership',
			quantity: 2,
			price: '200€ / month',
			tax: '20%',
			total: '220(€)',
		},
	];

	return (
		<Column>
			<div>
				<SectionTitle>Simple example</SectionTitle>
				<Table.Table>
					<tbody>
						<Table.TableRow>
							<Table.Cell>John doe - john@doe.com</Table.Cell>
							<Table.IconCell>
								<Icon name="edit" />
							</Table.IconCell>
							<Table.IconCell>
								<Icon name="close-circle" />
							</Table.IconCell>
						</Table.TableRow>
					</tbody>
				</Table.Table>
			</div>
			<div>
				<SectionTitle>Multiple rows example</SectionTitle>
				<Table.Table>
					<thead>
						<Table.TableHeadRow>
							<Table.HeadCell>Product description</Table.HeadCell>
							<Table.HeadCell>QTY</Table.HeadCell>
							<Table.HeadCell>Price</Table.HeadCell>
							<Table.HeadCell>Tax</Table.HeadCell>
							<Table.HeadCell>Total</Table.HeadCell>
						</Table.TableHeadRow>
					</thead>
					<tbody>
						{data.map((obj, index) => (
							<Table.TableRow key={index}>
								<Table.Cell>{obj.name}</Table.Cell>
								<Table.Cell>{obj.quantity}</Table.Cell>
								<Table.Cell>{obj.price}</Table.Cell>
								<Table.Cell>{obj.tax}</Table.Cell>
								<Table.Cell>{obj.total}</Table.Cell>
								<Table.IconCell>
									<Icon name="edit" />
								</Table.IconCell>
								<Table.IconCell>
									<Icon name="close-circle" />
								</Table.IconCell>
							</Table.TableRow>
						))}
					</tbody>
				</Table.Table>
			</div>
		</Column>
	);
};

export default FormTable;
