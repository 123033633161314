import { ReactNode } from 'react';
import styled from 'styled-components';

import * as columnStyles from 'components/layout/column/styles.css';

const Wrapper = styled.div`
	grid-area: content;
	overflow: auto;
	min-height: 100%;
	height: 100%;
	width: 100%;
	position: relative;
	z-index: 1;
	transition: transform 200ms ease-out;
	transform: translateY(var(--contentOffset, 0px));

	.${columnStyles.column} {
		width: 100%;
		max-width: 380px;
	}
`;

interface OnboardingWrapperProps {
	children?: ReactNode | ReactNode[];
}

export default function OnboardingWrapper({
	children,
}: OnboardingWrapperProps): JSX.Element {
	return <Wrapper>{children}</Wrapper>;
}
