import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';

import { ItemColumn } from 'routes/account/settings/child-relations/styles';

import Avatar from 'components/avatar';

import Row from 'components/layout/row';

import Button from 'design/button';
import * as ContextMenu from 'design/context_menu';

interface ParentItemProps {
	relation: models.accountRelation.AccountRelation;
	removeRelation: (id: number) => void;
}

export default function ParentItem({
	relation,
	removeRelation,
}: ParentItemProps): JSX.Element {
	const handleRemoveRelation = async () => {
		removeRelation(relation.id);
	};

	const targetAccountName = models.account.fullName(relation.targetAccount);

	const actions = [];

	actions.push(
		<ContextMenu.ConfirmItem
			caution
			closeOnClick
			onConfirm={handleRemoveRelation}
			confirmLabel={t('Remove connection')}
			message={t(
				'You are about to remove {name} as a connection, this cannot be undone.',
				{
					name: targetAccountName,
				}
			)}
			key="remove-connection">
			<ContextMenu.ItemIcon name="link_off" />
			{t('Remove connection')}
		</ContextMenu.ConfirmItem>
	);

	return (
		<Row columns="35px 1fr auto" align="center">
			<Avatar account={relation.targetAccount} />
			<ItemColumn spacing={styles.spacing._1}>
				<strong>{models.account.fullName(relation.targetAccount)}</strong>
				<span>{relation.targetAccount.email}</span>
			</ItemColumn>
			<ContextMenu.Menu
				toggleWith={<Button icon="more_horiz" small secondary />}>
				{actions}
			</ContextMenu.Menu>
		</Row>
	);
}
