import { ReactNode } from 'react';

export const symbol_universal_local: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-220h680v-520H140v520Zm0 60q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm0-60v-520 520Zm340-260q-18 0-31-13t-13-31q0-18 13-31t31-13q18 0 31 13t13 31q0 18-13 31t-31 13Zm.215-220Q411-700 357.5-651.5T304-520q0 69 54 135t102 108q5 4 9.815 5.5 4.815 1.5 10 1.5T490-271.5q5-1.5 10-5.5 48-42 102-108t54-135q0-83-53.285-131.5t-122.5-48.5Z"
		/>
	</svg>
);
