import { ReactNode } from 'react';

export const symbol_speed_1_7x: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M152.825-277Q140-277 131.5-285.625T123-307v-316H70q-12.75 0-21.375-8.675Q40-640.351 40-653.175 40-666 48.625-674.5T70-683h83q12.75 0 21.375 8.625T183-653v346q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM769-434l-77 139q-4.286 7.059-10.714 11.029Q674.857-280 667.143-280 650-280 642-294t0-28l91-164-85-152q-8-14-.5-28t24.643-14q7.714 0 14.143 3.971Q692.714-672.059 697-665l72 128 71-128q4.286-7.059 10.714-11.029Q857.143-680 864.857-680 882-680 890-666t0 28l-85 152 92 165q8 14 0 27.5T871.759-280Q864-280 858-283.706T848-294l-79-140ZM442.684-278q-14 0-22.842-11Q411-300 415-314l82-309H357q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T357-683h140q24 0 42 16t18 40q0 3-2 13l-84 315q-2 10-10 15.5t-18.316 5.5Zm-167.859 1Q262-277 253.5-285.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Z"
		/>
	</svg>
);
