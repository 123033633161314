import { ReactNode } from 'react';

export const symbol_arrow_range: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m194-449 93 92q9 9 9 21t-9 21q-9 9-21.5 9t-21.5-9L101-458q-5-5-7-10t-2-11q0-6 2-11t7-10l144-144q9-9 21-8.5t21 9.5q9 9 9 21t-9 21l-92 91h569l-92-91q-9-9-9-21t9-21q9-9 21.5-9t21.5 9l143 143q5 5 7 10t2 11q0 6-2 11t-7 10L714-314q-9 9-21 9t-21-9q-9-9-9-21.5t9-21.5l92-92H194Z"
		/>
	</svg>
);
