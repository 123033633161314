import { ReactNode } from 'react';

export const symbol_amp_stories: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M310-160q-13 0-21.5-8.5T280-190v-580q0-13 8.5-21.5T310-800h340q13 0 21.5 8.5T680-770v580q0 13-8.5 21.5T650-160H310ZM120-270v-421q0-12 9-20.5t21-8.5q13 0 21.5 8.5T180-690v421q0 12-9 20.5t-21 8.5q-13 0-21.5-8.5T120-270Zm660 0v-421q0-12 9-20.5t21-8.5q13 0 21.5 8.5T840-690v421q0 12-9 20.5t-21 8.5q-13 0-21.5-8.5T780-270Zm-440 50h280v-520H340v520Zm0 0v-520 520Z"
		/>
	</svg>
);
