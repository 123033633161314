import { useRef } from 'react';
import { t } from '@transifex/native';

import { PageWidths } from 'pkg/config/sizes';

import { UpdateIdentityProviderPayload } from 'pkg/actions/identity_providers';

import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useEndpoint } from 'pkg/api/use_endpoint';
import * as actions from 'pkg/actions';
import { pushState } from 'pkg/router/state';
import * as routes from 'pkg/router/routes';
import { useCurrentOrganization } from 'pkg/identity';

import {
	LargeScreen,
	SmallScreen,
	useSmallScreen,
} from 'components/MediaQuery';

import Form, { FormPayload, asString, submitForm } from 'components/form/Form';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import * as ActionBar from 'components/layout/ActionBar';
import { Spinner } from 'components/loaders/spinner';
import { getSelectedUserTraits } from 'components/identity-provider/role-select-group';
import EditIdentityProviderForm from 'components/identity-provider/edit-form';

import Button from 'design/button';

import { FormActionFooter } from 'styles/Form';

interface EditIdentityProviderProps {
	providerId: number;
}

export default function EditIdentityProvider({
	providerId,
}: EditIdentityProviderProps) {
	const formRef = useRef(null);
	const isSmallScreen = useSmallScreen();
	const org = useCurrentOrganization();

	const { record: provider, isLoading } =
		useEndpoint<models.identityProvider.IdentityProvider>(
			endpoints.IdentityProviders.Show(org.id, providerId)
		);

	const formSubmit = () => submitForm(formRef);

	const handleSubmit = async (data: FormPayload) => {
		const requiredForUserGroupRoles = getSelectedUserTraits(data);

		const payload: UpdateIdentityProviderPayload = {
			displayName: asString(data.displayName),
			requiredForIdentityUserTraits: requiredForUserGroupRoles,
			ssoEnabled: data.ssoEnabled as boolean,
		};

		const [ok] = await actions.identityProviders.update(
			org.id,
			providerId,
			payload
		);

		if (ok) {
			pushState(routes.Organization.Settings.Sso(org.id));
		}
	};

	const handleCancel = () =>
		pushState(routes.Organization.Settings.Sso(org.id));

	if (isLoading) {
		return <Spinner />;
	}

	return (
		<Form formRef={formRef} onSubmit={handleSubmit}>
			<ActionBar.SaveBar maxWidth={PageWidths.STANDARD}>
				<Button
					label={t('Cancel')}
					block={isSmallScreen}
					large={isSmallScreen}
					onClick={handleCancel}
				/>
				<Button
					label={t('Save')}
					primary
					onClick={formSubmit}
					block={isSmallScreen}
					large={isSmallScreen}
				/>
			</ActionBar.SaveBar>
			<LargeScreen>
				<LargeScreenContent.Inner maxWidth={PageWidths.STANDARD}>
					<EditIdentityProviderForm provider={provider} />
					<FormActionFooter>
						<Button label={t('Cancel')} onClick={handleCancel} />
						<Button label={t('Save')} primary onClick={formSubmit} />
					</FormActionFooter>
				</LargeScreenContent.Inner>
			</LargeScreen>
			<SmallScreen>
				<LargeScreenContent.Inner>
					<EditIdentityProviderForm provider={provider} />
				</LargeScreenContent.Inner>
			</SmallScreen>
		</Form>
	);
}
