import {
	AUTH_LOGIN_SUCCESS,
	AUTH_ALREADY_AUTHORIZED,
	AUTH_LOGOUT_SUCCESS,
} from 'pkg/actions/action-types';

const initialState = {
	authToken: null,
};

const auth = (state = initialState, action) => {
	switch (action.type) {
		case AUTH_LOGIN_SUCCESS:
			return {
				...state,
				authToken: action.payload,
			};

		case AUTH_ALREADY_AUTHORIZED:
			return {
				...state,
				authToken: action.payload,
			};

		case AUTH_LOGOUT_SUCCESS:
			return initialState;
	}

	return state;
};

export default auth;
