import { t } from '@transifex/native';

import { palette } from 'pkg/config/styles';

import { AttendanceDataTypes } from 'pkg/api/models/event_report';
import * as models from 'pkg/api/models';
import DateTime from 'pkg/datetime';
import { precise } from 'pkg/numbers';

import Row from 'components/layout/row';

import * as Chart from 'design/chart';
import * as DataCard from 'design/data_card';

interface DatePeriodMap {
	[key: string]: { [key in AttendanceDataTypes]: number };
}

interface AverageAttendanceWidgetProps {
	fromDateTime: DateTime;
	toDateTime: DateTime;
	localeOptions: Intl.DateTimeFormatOptions;

	dashboardData: models.eventReport.AttendanceDashboardData;
}

export default function AverageAttendanceWidget({
	dashboardData,
	fromDateTime,
	toDateTime,
	localeOptions,
}: AverageAttendanceWidgetProps) {
	let totalAttendingActivities = 0;
	let totalNotAttendingActivities = 0;
	let totalUnhandledActivities = 0;

	const datePeriods: DatePeriodMap = {};

	if (dashboardData.periodizedEventUserData?.length > 0) {
		dashboardData.periodizedEventUserData.forEach((d) => {
			switch (d.dataType) {
				case AttendanceDataTypes.Attending:
					totalAttendingActivities = d.periodizedData.total.currentPeriodTotal;
					break;
				case AttendanceDataTypes.NotAttending:
					totalNotAttendingActivities =
						d.periodizedData.total.currentPeriodTotal;
					break;
				default:
					totalUnhandledActivities = d.periodizedData.total.currentPeriodTotal;
			}

			Object.entries(d.periodizedData.datePeriods).forEach(([key, value]) => {
				if (key in datePeriods) {
					datePeriods[key][d.dataType] += value.currentPeriod;
				} else {
					datePeriods[key] = {
						attending: 0,
						not_attending: 0,
						unhandled: 0,
					};

					datePeriods[key][d.dataType] += value.currentPeriod;
				}
			});
		});
	}

	const linearChartItems: Chart.ChartItem[] = Object.entries(datePeriods).map(
		([key, value]) => {
			return {
				title: new DateTime(new Date(key)).toLocaleDateString(localeOptions),
				attendingValue: value.attending,
				notAttendingValue: value.not_attending,
				unhandledValue: value.unhandled,
			} as Chart.ChartItem;
		}
	);

	const attendancePercentage = `${precise(dashboardData.attendanceRate, 1)}%`;
	const didNotAttendPercentage = `${precise(
		dashboardData.didNotAttendRate,
		1
	)}%`;
	const unhandledPercentage = `${precise(dashboardData.unhandledRate, 1)}%`;

	const statusBreakdown: DataCard.BreakdownItem[] = [
		{
			title: t('Attended'),
			color: 'green',
			variant: 'label',
			value: t('{number} activities', {
				number: totalAttendingActivities,
			}),
			diff: attendancePercentage,
		},
		{
			title: t(`Didn't attend`),
			color: 'red',
			variant: 'label',
			value: t('{number} activities', {
				number: totalNotAttendingActivities,
			}),
			diff: didNotAttendPercentage,
		},
		{
			title: t('No response'),
			color: 'gray',
			variant: 'label',
			value: t('{number} activities', {
				number: totalUnhandledActivities,
			}),
			diff: unhandledPercentage,
		},
	];

	return (
		<DataCard.Base
			title={t('Avg. attendance')}
			titleIcon="avg_time"
			heading={attendancePercentage}
			headingSuffix={t('of {eventCount} events', {
				eventCount: dashboardData.eventCount,
			})}
			footer={
				<Row autoColumns="max-content" justifyContent="space-between">
					<span>
						{fromDateTime.toLocaleDateString({
							month: 'short',
							day: 'numeric',
							year: 'numeric',
						})}
					</span>
					<span>
						{toDateTime.toLocaleDateString({
							month: 'short',
							day: 'numeric',
							year: 'numeric',
						})}
					</span>
				</Row>
			}>
			<DataCard.Breakdown items={statusBreakdown} />
			<Chart.MultiLine
				items={linearChartItems}
				lineProps={[
					{
						value: 'attendingValue',
						fillColor: palette.green[400],
						tooltipTranslation: t('Attending'),
					},
					{
						value: 'notAttendingValue',
						fillColor: palette.red[400],
						tooltipTranslation: t(`Didn't attend`),
					},
					{
						value: 'unhandledValue',
						fillColor: palette.gray[500],
						tooltipTranslation: t('Unhandled'),
					},
				]}
			/>
		</DataCard.Base>
	);
}
