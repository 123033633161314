import { t } from '@transifex/native';

import DateTime, { Granularity } from 'pkg/datetime';
import { range } from 'pkg/utils';

export function isLeapYear(year: number): boolean {
	return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

export function getDaysInMonth(year: number, month: number): number {
	return [
		31,
		isLeapYear(year) ? 29 : 28,
		31,
		30,
		31,
		30,
		31,
		31,
		30,
		31,
		30,
		31,
	][month];
}

export const getWeekNumberForDay = (d: Date): number => {
	d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
	d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
	const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
	const weekNo = Math.ceil(((+d - +yearStart) / 86400000 + 1) / 7);
	return weekNo;
};

export function isSameDay(startDateTime: Date, endDate: DateTime): boolean {
	const startOfDayTime = startOfDay(+startDateTime).getTime();
	const endOfDayTime = endOfDay(+startDateTime).getTime();
	const endDateTime = endDate.toDate().getTime();

	const withinTheSameDay =
		endDateTime >= startOfDayTime && endDateTime <= endOfDayTime;

	return withinTheSameDay;
}

export function dayIsWithinRange(startsAt: number, endsAt: number, day: Date) {
	const dayDateTime = new DateTime(day);
	const startOfDay = Math.floor(dayDateTime.startOfDay / 1000);
	const endOfDay = Math.floor(dayDateTime.endOfDay / 1000);

	return (
		isSameDay(new Date(startsAt * 1000), dayDateTime) ||
		isSameDay(new Date(endsAt * 1000), dayDateTime) ||
		(startsAt < startOfDay && endsAt > endOfDay)
	);
}

export function isToday(dateTime: DateTime): boolean {
	return isSameDay(new Date(), dateTime);
}

export function startOfDay(timestamp: number): Date {
	const startOfDayDateTime = new Date(timestamp);
	startOfDayDateTime.setHours(0, 0, 0, 0);

	return startOfDayDateTime;
}

export function endOfDay(timestamp: number): Date {
	const endOfDayDateTime = new Date(timestamp);
	endOfDayDateTime.setHours(23, 59, 59, 999);

	return endOfDayDateTime;
}

export function incrementDateByNumberOfMonths(
	d: number,
	numMonths: number
): number {
	const dateObject = new Date(d);
	const n = dateObject.getDate();
	dateObject.setDate(1);
	dateObject.setMonth(dateObject.getMonth() + numMonths);
	dateObject.setDate(
		Math.min(n, getDaysInMonth(dateObject.getFullYear(), dateObject.getMonth()))
	);
	return +dateObject;
}

export function decrementDateByNumberOfMonths(
	d: number,
	numMonths: number
): number {
	const dateObject = new Date(d);
	const n = dateObject.getDate();
	dateObject.setDate(1);
	dateObject.setMonth(dateObject.getMonth() - numMonths);
	dateObject.setDate(
		Math.min(n, getDaysInMonth(dateObject.getFullYear(), dateObject.getMonth()))
	);
	return +dateObject;
}

export function decrementDateByNumberOfYears(
	d: number,
	numYears: number
): number {
	const dateObject = new Date(d);
	dateObject.setFullYear(dateObject.getFullYear() - numYears);
	return +dateObject;
}

export function incrementDateByNumberOfYears(
	d: number,
	numYears: number
): number {
	const dateObject = new Date(d);
	dateObject.setFullYear(dateObject.getFullYear() + numYears);
	return +dateObject;
}

export function incrementDateByNumberOfWeeks(
	d: number,
	numWeeks: number
): number {
	const dateObject = new Date(d);
	const weeks = 7 * numWeeks;

	return dateObject.getTime() + weeks * 24 * 60 * 60 * 1000;
}

export function decrementDateByNumberOfWeeks(
	d: number,
	numWeeks: number
): number {
	const dateObject = new Date(d);
	const weeks = 7 * numWeeks;

	return dateObject.getTime() - weeks * 24 * 60 * 60 * 1000;
}

export function incrementWeek(d: number): number {
	const dateObject = new Date(d);
	return dateObject.getTime() + 7 * 24 * 60 * 60 * 1000;
}

export function decrementWeek(d: number): number {
	const dateObject = new Date(d);
	return dateObject.getTime() - 7 * 24 * 60 * 60 * 1000;
}

export function incrementDateByNumberOfDays(
	d: number,
	numDays: number
): number {
	const dateObject = new Date(d);
	return +dateObject.setDate(dateObject.getDate() + numDays);
}

export function decrementDateByNumberOfDays(
	d: number,
	numDays: number
): number {
	const dateObject = new Date(d);
	return +dateObject.setDate(dateObject.getDate() - numDays);
}

export function getFirstDayOfWeek(d: Date): Date {
	const dateObject = new Date(d);
	const diff =
		dateObject.getDate() -
		dateObject.getDay() +
		(dateObject.getDay() === 0 ? -6 : 1);

	dateObject.setDate(diff);

	return dateObject;
}

export function getLastDayOfWeek(d: Date): Date {
	const dateObject = new Date(d);
	if (dateObject.getDay() !== 0) {
		const lastday = dateObject.getDate() - (dateObject.getDay() - 1) + 6;
		return new Date(dateObject.setDate(lastday));
	}
	return dateObject;
}

export const getStartOfYear = (timestamp: number): number => {
	const dateObj = new Date(timestamp * 1000);
	const year = dateObj.getFullYear();
	const week = getWeekNumberForDay(dateObj);
	const nextWeekYear = new Date(
		dateObj.getTime() + 7 * 24 * 60 * 60 * 1000
	).getFullYear();

	if (year !== nextWeekYear && week === 1) {
		return new DateTime(new Date(nextWeekYear, 0, 1)).startOfDay;
	}
	return new DateTime(new Date(year, 0, 1)).startOfDay;
};

export const getEndOfYear = (timestamp: number): number => {
	const dateObj = new Date(getStartOfYear(timestamp)).getFullYear();
	return new DateTime(new Date(dateObj, 11, 31)).endOfDay;
};

export const startOfMonth = (timestamp: number): number => {
	const dateObject = new DateTime(new Date(timestamp * 1000));
	return dateObject.startOfMonth;
};

export const endOfMonth = (timestamp: number): number => {
	const dateObject = new DateTime(new Date(timestamp * 1000));
	return dateObject.endOfMonth;
};

export const startOfWeekTimestamp = (timestamp: number): number => {
	const dateObject = new Date(timestamp * 1000);
	return new DateTime(getFirstDayOfWeek(dateObject)).startOfDay;
};

export const endOfWeekTimestamp = (timestamp: number): number => {
	const dateObject = new Date(timestamp * 1000);
	dateObject.setDate(
		dateObject.getDate() + ((0 + 7 - dateObject.getDay()) % 7)
	);
	return new DateTime(dateObject).endOfDay;
};

const DefaultDateTimeFormatOptions: Intl.DateTimeFormatOptions = {
	weekday: 'short',
	month: 'long',
	day: 'numeric',
};

export const getEventDate = (
	startsAt: number,
	endsAt: number,
	dateFormatOptions = DefaultDateTimeFormatOptions
): string => {
	const startsAtDT = new DateTime(new Date(startsAt * 1000));
	const endsAtDT = new DateTime(new Date(endsAt * 1000));
	const sameDay = endsAtDT.sameDay(startsAtDT.getTimestamp());

	const dateSegments = [
		startsAtDT.toLocaleDateString(dateFormatOptions),
		startsAtDT.toTimeString(),
		'–',
	];

	// Only add formatted date if end date isn't same as start date
	if (!sameDay) {
		dateSegments.push(endsAtDT.toLocaleDateString(dateFormatOptions));
	}

	dateSegments.push(endsAtDT.toTimeString());

	return dateSegments.join(' ');
};

export const isDateOlderThan = (timestamp: number, days: number): boolean => {
	const calculatedDate = new Date().getTime() - days * 24 * 60 * 60 * 1000;

	return timestamp * 1000 < calculatedDate;
};

export function yearsSince(d: Date): number {
	const ageDiffInMs = Date.now() - d.getTime();
	const ageDate = new Date(ageDiffInMs);
	const yearsSince = Math.abs(ageDate.getUTCFullYear() - 1970);

	return yearsSince;
}

export const getIncrementedUnixTimestamp = (
	interval: string,
	date: number,
	amount: number
) => {
	let granularity: Granularity = Granularity.month;
	switch (interval) {
		case 'year':
			granularity = Granularity.year;
			break;
		case 'week':
			granularity = Granularity.week;
			break;
		case 'day':
			granularity = Granularity.day;
			break;
	}

	return new DateTime(new Date(date))
		.next(granularity, amount)
		.getUnixTimestamp();
};

export const getIncrementedTimestamp = (
	interval: string,
	date: number,
	amount: number
): number => {
	switch (interval) {
		case 'year':
			return new DateTime(
				new Date(incrementDateByNumberOfYears(date, amount))
			).getTimestamp();
		case 'month':
			return new DateTime(
				new Date(incrementDateByNumberOfMonths(date, amount))
			).getTimestamp();
		case 'week':
			return new DateTime(
				new Date(incrementDateByNumberOfWeeks(date, amount))
			).getTimestamp();
		case 'day':
			return new DateTime(
				new Date(incrementDateByNumberOfDays(date, amount))
			).getTimestamp();
		default:
			return;
	}
};

export const getDecrementedTimeStamp = (
	interval: string,
	date: number,
	amount: number
): number => {
	switch (interval) {
		case 'month':
			return new DateTime(
				new Date(decrementDateByNumberOfMonths(date, amount))
			).getTimestamp();
		case 'week':
			return new DateTime(
				new Date(decrementDateByNumberOfWeeks(date, amount))
			).getTimestamp();
		case 'day':
			return new DateTime(
				new Date(decrementDateByNumberOfDays(date, amount))
			).getTimestamp();
		default:
			return;
	}
};

export const getWeekDates = (timestamp: number): Date[] => {
	const date = new DateTime(new Date(timestamp)).startOfDay;
	const firstDayOfWeek = getFirstDayOfWeek(new Date(date));
	const lastDayOfWeek = getLastDayOfWeek(new Date(date));

	const dates = [];
	let current = firstDayOfWeek;

	const addDays = (day: Date, num: number) => {
		const date = new Date(day);
		date.setDate(date.getDate() + num);
		return date;
	};

	while (current <= lastDayOfWeek) {
		dates.push(current);
		current = addDays(current, 1);
	}

	return dates;
};

export const getAmountOfDaysBetweenDates = (start: Date, end: Date) => {
	const difference = end.getTime() - start.getTime();
	const totalDays = Math.ceil(difference / (1000 * 3600 * 24));
	return totalDays;
};

export const months = (): [number, string][] => {
	const names = [
		t('January', {
			_context: 'dates',
			_comment: 'long month names',
		}),
		t('February', {
			_context: 'dates',
			_comment: 'long month names',
		}),
		t('March', {
			_context: 'dates',
			_comment: 'long month names',
		}),
		t('April', {
			_context: 'dates',
			_comment: 'long month names',
		}),
		t('May', {
			_context: 'dates',
			_comment: 'long month names',
		}),
		t('June', {
			_context: 'dates',
			_comment: 'long month names',
		}),
		t('July', {
			_context: 'dates',
			_comment: 'long month names',
		}),
		t('August', {
			_context: 'dates',
			_comment: 'long month names',
		}),
		t('September', {
			_context: 'dates',
			_comment: 'long month names',
		}),
		t('October', {
			_context: 'dates',
			_comment: 'long month names',
		}),
		t('November', {
			_context: 'dates',
			_comment: 'long month names',
		}),
		t('December', {
			_context: 'dates',
			_comment: 'long month names',
		}),
	];

	return range(1, 12).map((n: number) => [n, names[n - 1]]);
};

/**
 * Abbreviated month names
 */
export const shortMonths = (): [number, string][] => {
	const names = [
		t('Jan', {
			_context: 'dates',
			_comment: 'short month names',
		}),
		t('Feb', {
			_context: 'dates',
			_comment: 'short month names',
		}),
		t('Mar', {
			_context: 'dates',
			_comment: 'short month names',
		}),
		t('Apr', {
			_context: 'dates',
			_comment: 'short month names',
		}),
		t('May', {
			_context: 'dates',
			_comment: 'short month names',
		}),
		t('Jun', {
			_context: 'dates',
			_comment: 'short month names',
		}),
		t('Jul', {
			_context: 'dates',
			_comment: 'short month names',
		}),
		t('Aug', {
			_context: 'dates',
			_comment: 'short month names',
		}),
		t('Sep', {
			_context: 'dates',
			_comment: 'short month names',
		}),
		t('Oct', {
			_context: 'dates',
			_comment: 'short month names',
		}),
		t('Nov', {
			_context: 'dates',
			_comment: 'short month names',
		}),
		t('Dec', {
			_context: 'dates',
			_comment: 'short month names',
		}),
	];

	return range(1, 12).map((n: number) => [n, names[n - 1]]);
};

export const days = (): [number, string][] => {
	const names = [
		t('Monday', {
			_context: 'dates',
			_comment: 'long day names',
		}),
		t('Tuesday', {
			_context: 'dates',
			_comment: 'long day names',
		}),
		t('Wednsday', {
			_context: 'dates',
			_comment: 'long day names',
		}),
		t('Thursday', {
			_context: 'dates',
			_comment: 'long day names',
		}),
		t('Friday', {
			_context: 'dates',
			_comment: 'long day names',
		}),
		t('Saturday', {
			_context: 'dates',
			_comment: 'long day names',
		}),
		t('Sunday', {
			_context: 'dates',
			_comment: 'long day names',
		}),
	];
	return range(0, 6).map((n: number) => [n, names[n]]);
};

export const shortDays = (): [number, string][] => {
	const names = [
		t('Mon', { _context: 'dates', _comment: 'short day names' }),
		t('Tue', { _context: 'dates', _comment: 'short day names' }),
		t('Wed', { _context: 'dates', _comment: 'short day names' }),
		t('Thu', { _context: 'dates', _comment: 'short day names' }),
		t('Fri', { _context: 'dates', _comment: 'short day names' }),
		t('Sat', { _context: 'dates', _comment: 'short day names' }),
		t('Sun', { _context: 'dates', _comment: 'short day names' }),
	];
	return range(0, 6).map((n: number) => [n, names[n]]);
};

export const msToSeconds = (ms: number): number => Math.round(ms / 1000);

export const isValidBirthDate = (d: string) => {
	const currentYear = new Date().getFullYear();
	const year = Number.parseInt(d.slice(0, 4), 10);
	const month = Number.parseInt(d.slice(4, 6), 10);
	const day = Number.parseInt(d.slice(6, 8));

	if (year >= currentYear) {
		return false;
	}
	if (month < 1 || month > 12) {
		return false;
	}
	if (day < 1 || day > 31) {
		return false;
	}

	return true;
};

// Validates if timestamp is between the two start/end timestamps
export const isBetween = (
	timestamp: number,
	startTimestamp: number,
	endTimestamp: number
) => {
	if (timestamp >= startTimestamp && timestamp <= endTimestamp) {
		return true;
	} else {
		return false;
	}
};

export function calendarDateRangeString(from: DateTime, to: DateTime): string {
	const fromDateString = from.toLocaleDateString({
		day: 'numeric',
		month: 'short',
	});

	const toDateString = to.toLocaleDateString({
		day: 'numeric',
		month: 'short',
	});

	return `${fromDateString} - ${toDateString} ${to.getYear()}`;
}
