import { ReactNode } from 'react';

export const symbol_media_output: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M340-520ZM139-160q-25 0-42-17.625T80-220v-600q0-24.75 17-42.375T139-880h401q24.75 0 42.375 17.625T600-820v202.5q-16 2.5-30.915 5.974Q554.169-608.053 540-603v-217H139v600h201v60H139Zm201-120v-40q0-5.156.5-10.078T341-340q-33 0-57.5-23.5T259-420q0-33.333 23.333-56.667Q305.667-500 339-500q14 0 26 4.5t23 12.5q8-13 17-24.5t20-22.5q-18-14-39.5-22t-46.5-8q-58 0-99 41t-41 99q0 57.71 41.5 98.855T340-280Zm0-340q25 0 42.5-17.5T400-680q0-25-17.5-42.5T340-740q-25 0-42.5 17.5T280-680q0 25 17.5 42.5T340-620ZM510-80h-40q-28.875 0-49.438-20.562Q400-121.125 400-150v-170q0-100 70-170t170-70q100 0 170 70t70 170v170q0 28.875-20.562 49.438Q838.875-80 810-80h-40q-12.75 0-21.375-8.625T740-110v-120q0-12.75 8.625-21.375T770-260h70v-60q0-83.333-58.265-141.667Q723.471-520 640.235-520 557-520 498.5-461.667 440-403.333 440-320v60h70q12.75 0 21.375 8.625T540-230v120q0 12.75-8.625 21.375T510-80Z"
		/>
	</svg>
);
