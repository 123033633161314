import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import {
	useCurrentAccountUserIds,
	useCurrentAccountWards,
	useCurrentMembership,
	useCurrentOrganization,
} from 'pkg/identity';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useEndpoint } from 'pkg/api/use_endpoint';
import * as routes from 'pkg/router/routes';
import { useCollection } from 'pkg/api/use_collection';
import { EventTypes } from 'pkg/api/models/event';

import { DisableCreateEventDragConsumer } from 'routes/group/calendar/hooks/useCreateEventDrag';
import Heading from 'routes/event/single/event-heading/index';
import Description from 'routes/event/single/description/index';
import MatchStatistics from 'routes/event/single/match-statistics/index';
import EventUsersSummary from 'routes/event/single/event-users-summary/index';
import EventUserAttendance from 'routes/event/attendance';
import { EventContext } from 'routes/event/single';
import RSVPInfoBox from 'routes/event/components/rsvp/info-box';

import Column from 'components/layout/column';
import { Spinner } from 'components/loaders/spinner';

import Button from 'design/button';

import * as css from './styles.css';

interface EventPreviewProps {
	eventId: number;
}

export default function EventPreview({ eventId }: EventPreviewProps) {
	const currentMembership = useCurrentMembership();
	const org = useCurrentOrganization();

	const eventEndpoint = useEndpoint<models.event.Event>(
		endpoints.Events.Show(eventId)
	);
	const membershipsCollection = useCollection<models.membership.Membership>(
		endpoints.Groups.ShowUsers(currentMembership.groupId),
		{
			showAllResults: true,
			queryParams: new URLSearchParams({ includeLegalGuardians: '1' }),
		}
	);
	const event = eventEndpoint.record;
	const activeUserIds = useCurrentAccountUserIds();

	const wardAccountRelations = useCurrentAccountWards();
	const wardUserIds = models.accountRelation.findAllUserIds(
		wardAccountRelations.filter((ar) => models.accountRelation.isApproved(ar))
	);
	const allEventUsers = models.eventUser.findAllEventUsers(
		eventEndpoint.record.users,
		[...activeUserIds, ...wardUserIds]
	);
	const eventUsers = models.eventUser.sortEventUsers(
		allEventUsers,
		[],
		eventEndpoint.record
	);

	if (eventEndpoint.isLoading || membershipsCollection.isLoading) {
		return <Spinner />;
	}

	return (
		<EventContext.Provider value={{ eventEndpointResponse: eventEndpoint }}>
			<DisableCreateEventDragConsumer>
				<div className={css.previewCard}>
					<Column spacing={styles.spacing._5}>
						<Heading event={event} preview />

						{event.rsvpBefore && <RSVPInfoBox event={event} />}

						{eventUsers.length > 0 && (
							<Column spacing={styles.spacing._2}>
								{eventUsers.map((ev) => (
									<EventUserAttendance
										key={ev.id}
										eventUser={ev}
										event={event}
										eventEndpoint={eventEndpoint}
									/>
								))}
							</Column>
						)}

						<Description
							event={event}
							currentGroupMemberships={membershipsCollection.records}
						/>

						<Button
							label={t('View event')}
							large
							primary
							href={routes.Event.View(org.id, event.id, 'overview')}
						/>

						{event.type === EventTypes.Match &&
							models.sport.hasMatchesEnabled(event.group.sport) &&
							models.group.hasFeature(
								event.group,
								models.group.Features.Matches
							) && <MatchStatistics match={event.matches?.[0]} />}

						<EventUsersSummary event={event} preview />
					</Column>
				</div>
			</DisableCreateEventDragConsumer>
		</EventContext.Provider>
	);
}
