import { ReactNode } from 'react';

export const symbol_nights_stay: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M518-440Zm6 400H408l12-9.5Q432-59 446.5-70T473-90.5l12-9.5h41q97 0 180.5-58T838-302q-90-8-173-41.5T518.5-440Q455-503 422-585.5T381-757q-88 48-144.5 130.5T180-444v11.5q0 6.5 1 11.5l-9.039 3.721q-9.039 3.72-19.961 7.779-10.922 4.059-19.961 7.779L123-398q-2-13-2.5-24t-.5-22q0-136 80-241t209-143q8-2 15-1t12 6q5 5 8.5 11.375T447-797q-6 91 21.5 170.5T561-482q67 67 144 93t170 21q8.067-1 15.033 3Q897-361 902-356q5 5 5.5 12.5T905-328q-45 131-140.5 209.5T524-40Zm-284-60h180q33.333 0 56.667-23.265Q500-146.529 500-179.765 500-213 477.138-236.5T422-260h-39l-15-35q-17-39-51.688-62-34.687-23-76.312-23-57 0-98.5 41T100-240q0 58.333 40.833 99.167Q181.667-100 240-100Zm0 60q-83 0-141.5-58.5T40-240q0-83 58.5-141.5T240-440q60 0 109.5 32.5T423-320q57 2 97 42.5t40 97.5q0 58-41 99t-99 41H240Z"
		/>
	</svg>
);
