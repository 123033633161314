import { ReactNode } from 'react';

export const symbol_escalator: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M280-250h126l200-360h74q20.833 0 35.417-15Q730-640 730-660.5t-14.583-35Q700.833-710 680-710H554L354-350h-74q-20 0-35 14.583-15 14.584-15 35.417 0 20 15 35t35 15ZM180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-60h600v-600H180v600Zm0-600v600-600Z"
		/>
	</svg>
);
