import { ReactNode } from 'react';

export const symbol_person_edit: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M500-220Zm-340 30v-64q0-38 19-65t49-41q67-30 128.5-45T480-420q38.018 0 75.009 6T630-397l-48 47q-26-5-50.897-7.5Q506.207-360 480-360q-57 0-111 11.5T252-306q-14 7-23 21.5t-9 30.5v34h280v60H190q-12.75 0-21.375-8.625T160-190Zm400 40v-68q0-12.444 5-23.722T578-261l202.738-201.782Q790-472 801-476t22-4q12 0 23 4.5t20 13.5l37 37q9 9 13 20t4 22q0 11-4.5 22.5t-13.675 20.718L701-138q-8 8-19.278 13-11.278 5-23.722 5h-68q-12.75 0-21.375-8.625T560-150Zm300-233-37-37 37 37ZM620-180h38l121-122-18-19-19-18-122 121v38Zm141-141-19-18 37 37-18-19ZM480-481q-63 0-106.5-43.5T330-631q0-63 43.5-106.5T480-781q63 0 106.5 43.5T630-631q0 63-43.5 106.5T480-481Zm0-60q38 0 64-26t26-64q0-38-26-64t-64-26q-38 0-64 26t-26 64q0 38 26 64t64 26Zm0-90Z"
		/>
	</svg>
);
