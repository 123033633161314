import * as models from 'pkg/api/models';

export function getCachedChatMessage(chatId: number): string {
	const cacheKey = `chat:${chatId}:message`;

	return sessionStorage.getItem(cacheKey) ?? '';
}

export function cacheChatMessage(chatId: number, message?: string): string {
	const cacheKey = `chat:${chatId}:message`;

	if (message) {
		sessionStorage.setItem(cacheKey, message);
	} else {
		sessionStorage.removeItem(cacheKey);
	}

	return message ?? '';
}

export function getCachedChatAttachments(
	chatId: number
): models.attachment.Attachment[] {
	const cacheKey = `chat:${chatId}:attachments`;
	const cachedAttachments = sessionStorage.getItem(cacheKey) || '';

	if (cachedAttachments !== '') {
		return JSON.parse(cachedAttachments);
	}

	return [];
}

export function removeCachedChatAttachmentId(
	chatId: number,
	attachmentId: number
): models.attachment.Attachment[] {
	const cacheKey = `chat:${chatId}:attachments`;
	const attachments = getCachedChatAttachments(chatId);

	const filteredAttachments = attachments.filter(
		(a: models.attachment.Attachment) => a.id !== attachmentId
	);
	if (filteredAttachments.length > 0) {
		sessionStorage.setItem(cacheKey, JSON.stringify(filteredAttachments));
	} else {
		sessionStorage.removeItem(cacheKey);
	}

	return filteredAttachments;
}

export function cacheChatAttachments(
	chatId: number,
	newAttachments: models.attachment.Attachment[]
): models.attachment.Attachment[] {
	const cacheKey = `chat:${chatId}:attachments`;

	const attachments = getCachedChatAttachments(chatId);
	const cachedAttachmentIds = attachments.map((a) => a.id);

	for (const a of newAttachments) {
		if (!cachedAttachmentIds.includes(a.id)) {
			attachments.push(a);
		}
	}

	sessionStorage.setItem(cacheKey, JSON.stringify(attachments));

	return attachments;
}

export function clearChatCache(chatId: number) {
	sessionStorage.removeItem(`chat:${chatId}:message`);
	sessionStorage.removeItem(`chat:${chatId}:attachments`);
}
