import { t } from '@transifex/native';

import { Record } from 'pkg/api/models/record';

export enum PayoutType {
	Unknown = 0,
	Deposit,
	Fee,
	Chargeback,
	Void,
}

export interface Payout extends Record {
	groupId: number;
	paymentProviderId: string;
	amount: number;
	netAmount: number;
	currency: string;
	type: PayoutType;
	paidOutAt: number;
	accountNumber: string;
}

export function getPayoutTypeTranslation(type: PayoutType) {
	switch (type) {
		case PayoutType.Chargeback:
			return t('Chargeback');
		case PayoutType.Deposit:
			return t('Deposit');
		case PayoutType.Fee:
			return t('Fee');
		case PayoutType.Void:
			return t('Void');
		default:
			return t('Unknown');
	}
}
