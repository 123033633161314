import { ReactNode } from 'react';

export const symbol_mic_external_on: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M184-692q-14-15-19-34t-5-39q0-48 33-81.5t81-33.5q48 0 81 33.5t33 81.5q0 20-5 39t-19 34H184ZM396-80q-64 0-109.5-45T241-235h-7q-11 0-20-8t-10-19l-34-356q-2-14 7-23.5t23-9.5h148q14 0 23 9.5t7 23.5l-34 356q-1 11-10 19t-20 8h-13q0 39 28 67t67 28q39 0 66.5-28t27.5-67v-490q0-65 45-110t110-45q65 0 110 45t45 110v615q0 13-8.5 21.5T770-80q-13 0-21.5-8.5T740-110v-615q0-39-28-67t-67-28q-39 0-67 28t-28 67v490q0 65-45 110T396-80ZM261-295h26l28-296h-82l28 296Zm26-296h-54 82-28Z"
		/>
	</svg>
);
