import { t } from '@transifex/native';

import spacing from 'pkg/config/spacing';

import * as models from 'pkg/api/models';
import { RecurringInterval } from 'pkg/api/models/provider_settings';

import LineItemRow from 'routes/forms/registration/form/products/summary/LineItem';
import BillingSummary from 'routes/forms/registration/form/products/summary/BillingSummary';
import { useRegistrationFormContext } from 'routes/forms/registration/form';

import * as Card from 'components/Card';

import Column from 'components/layout/column';

interface Props {
	currency: string;
	serviceFee: number;
	discount: models.discount.Discount;
}

export function getIntervalText(
	recurringInterval: RecurringInterval,
	recurringIntervalCount: number
) {
	if (
		recurringInterval === RecurringInterval.Month &&
		recurringIntervalCount === 3
	) {
		return t('Quarterly');
	}
	if (
		recurringInterval === RecurringInterval.Month &&
		recurringIntervalCount === 6
	) {
		return t('Semi-Annually');
	}
	if (
		recurringInterval === RecurringInterval.Month &&
		recurringIntervalCount === 2
	) {
		return t('Bi-Monthly');
	}

	if (recurringIntervalCount === 1) {
		const intervalMapping: { [key in RecurringInterval]: string } = {
			[RecurringInterval.Day]: t('Daily'),
			[RecurringInterval.Week]: t('Weekly'),
			[RecurringInterval.Month]: t('Monthly'),
			[RecurringInterval.Year]: t('Yearly'),
		};

		return intervalMapping[recurringInterval];
	}

	const intervalMapping: { [key in RecurringInterval]: string } = {
		[RecurringInterval.Day]: t('Every {intervalCount} days', {
			intervalCount: recurringIntervalCount,
		}),
		[RecurringInterval.Week]: t('Every {intervalCount} weeks', {
			intervalCount: recurringIntervalCount,
		}),
		[RecurringInterval.Month]: t('Every {intervalCount} months', {
			intervalCount: recurringIntervalCount,
		}),
		[RecurringInterval.Year]: t('Every {intervalCount} years', {
			intervalCount: recurringIntervalCount,
		}),
	};

	return intervalMapping[recurringInterval];
}

const Summary = ({ currency, discount, serviceFee }: Props) => {
	const registrationCtx = useRegistrationFormContext();

	const preview = registrationCtx.state.preview;

	return (
		<Column>
			<Card.Base $dark>
				<Card.Body>
					<Column spacing={spacing._5}>
						<Column spacing={spacing._3}>
							{preview?.recurringPayment.lineItems &&
								preview?.recurringPayment.lineItems.map((recurringLineItem) => {
									const existsInInitialPayment =
										preview?.initialPayment.lineItems.some(
											(initialLineItems) =>
												initialLineItems?.productPrice?.id ===
												recurringLineItem?.productPrice?.id
										);
									if (!existsInInitialPayment) {
										return (
											<LineItemRow
												key={recurringLineItem?.productPrice?.id}
												lineItem={recurringLineItem}
												currency={currency}
												installmentCount={preview.installmentCount}
												subscriptionStartsAt={preview.subscriptionStartAt}
												discountedPayments={preview.discountedPayments}
											/>
										);
									}
								})}
							{preview?.initialPayment.lineItems.map((lineItem) => {
								return (
									<LineItemRow
										key={lineItem.productPrice?.id}
										lineItem={lineItem}
										currency={currency}
										installmentCount={preview.installmentCount}
										discountedPayments={preview.discountedPayments}
									/>
								);
							})}
						</Column>
					</Column>
				</Card.Body>
			</Card.Base>

			<Card.Base $dark>
				<Card.Body>
					<Column spacing={spacing._5}>
						<Column spacing={spacing._4}>
							<BillingSummary
								currency={currency}
								discount={discount}
								serviceFee={serviceFee}
								previewData={preview}
								recurringDiscountedPayment={preview?.recurringDiscountedPayment}
								recurringPayment={preview?.recurringPayment}
								recurringIntervalCount={preview?.recurringIntervalCount}
							/>
						</Column>
					</Column>
				</Card.Body>
			</Card.Base>
		</Column>
	);
};

export default Summary;
