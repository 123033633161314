import styled from 'styled-components';

import * as breakpoints from 'pkg/config/breakpoints';
import * as palette from 'pkg/config/palette';
import * as shadow from 'pkg/config/box-shadow';
import { size } from 'pkg/config/fonts';

import rgba from 'pkg/rgba';

const Card = styled.div`
	background-color: ${rgba(palette.card.backgroundColor)};
	position: relative;
	border-radius: var(--radius-6);
	scroll-snap-align: center;
	margin-right: var(--spacing-4);
	width: 90%;
	flex: 0 0 90%;
	min-width: 0;
	border: ${(props) =>
		props.noBorder ? 'none' : '1px solid var(--palette-gray-300)'};
	transition: box-shadow 120ms ease-out;

	@media (hover: hover) {
		scroll-snap-align: none;

		&:hover {
			box-shadow: ${shadow.cardHover};
		}
	}

	@media ${breakpoints.large}, ${breakpoints.nolimit} {
		width: 290px;
		flex: 0 0 290px;
	}

	&:last-child {
		margin-right: 0;
	}

	&:only-child {
		width: 100%;
		flex: 0 0 100%;

		@media ${breakpoints.large}, ${breakpoints.nolimit} {
			width: 100%;
			flex: 0 0 100%;
		}
	}
`;

export default Card;

export const Body = styled.div`
	padding: 20px 16px;
	line-height: 1.2;

	@media ${breakpoints.small} {
		padding: 16px;
	}
`;

export const Header = styled.header`
	padding: 16px 16px 0px;
	display: flex;
	flex-flow: row wrap;
	font-size: ${size.lg};
	flex: 0 0 auto;
	align-items: ${(props) => (props.centered ? 'center' : 'flex-start')};
	justify-content: ${(props) =>
		props.centered ? 'space-between' : 'flex-start'};

	border-radius: 3px 3px 0 0;

	@media ${breakpoints.small} {
		padding: 16px 16px 0 16px;
		border-radius: 0;
	}
`;

export const Divider = styled.hr`
	width: 100%;
	height: 0px;
	border: none;
	border-bottom: ${rgba(palette.card.innerDividerColor)} 1px solid;
	display: block;
`;
