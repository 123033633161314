import { ReactNode } from 'react';

export const symbol_font_download: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M376-364h208.916L623-260q3 8.8 10.5 14.4t17.625 5.6Q668-240 677-253.5t3-28.5L519-701q-3-9-10.742-14-7.742-5-17.032-5H468q-10 0-17.5 5.5T440-700L280-282q-6 15 3 28.5t25.538 13.5q10.75 0 18.606-6Q335-252 339-261l37-103Zm21-55 82-230h2l82 230H397ZM140-80q-24 0-42-18t-18-42v-680q0-24 18-42t42-18h680q24 0 42 18t18 42v680q0 24-18 42t-42 18H140Zm1-60h680v-680H141v680Zm0-680v680-680Z"
		/>
	</svg>
);
