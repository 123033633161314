import { ReactNode } from 'react';

export const symbol_volunteer_activism: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m562-110 248-78q0-20-15.5-34T758-235H549q-16 1-31.5-2t-30.5-8l-66-20q-12-4-18-16t-2-24q4-12 15.278-18T440-324l64 21q11 4 22.5 6t22.5 2h56q2 0 0 0 0-21-14-36.5T557-355l-218-83h-84v238l307 90Zm-13 57-294-84q-2 27-22.5 42T195-80h-95q-24.75 0-42.375-17.625T40-140v-298q0-24.75 17.625-42.375T100-498h238q5.333 0 10.667 1Q354-496 359-494l218 82q42 16 66.5 46t24.5 71h90q50.833 0 86.417 37Q880-221 880-170q0 11-5.5 20T859-138L583-53q-8.171 2-17.086 2Q557-51 549-53Zm-449-87h94v-298h-94v298Zm546-340q-11.25 0-22.125-4.25T604-497L482-616q-29.324-27.74-49.662-61.49Q412-711.24 412-751q0-53 35-91t87-38q34 0 62 17.5t50 43.5q22-26 50-43.5t62-17.5q52 0 87 38t35 91q0 39.664-20.5 73.332Q839-644 810-616L688-497q-9 8.5-19.875 12.75T646-480Zm0-59 120-119q20.118-19.854 37.059-42.32T820-751q0-28-17.5-48.5T758-820q-20 0-37 11t-30 27l-21.658 27.143Q660.38-744 646.19-744q-14.19 0-23.263-10.857L601-782q-13-16-30-27t-37-11q-27 0-44.5 20.5T472-751q0 28.214 16.941 50.68T526-658l120 119Zm0-188Z"
		/>
	</svg>
);
