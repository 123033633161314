const select_trigger = {
	name: 'select-trigger',
	objects: [
		{
			type: 'path',
			attributes: {
				d: 'M8.94 13.5344L12 16.3394L15.06 13.5344L16 14.4022L12 18.0689L8 14.4022L8.94 13.5344Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M15.06 10.5344L12 7.72944L8.94 10.5344L8 9.66667L12 6L16 9.66667L15.06 10.5344Z',
			},
		},
	],
};

export default select_trigger;
