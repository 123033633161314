import { ReactNode } from 'react';

export const symbol_exposure_plus_2: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M810-200H508q-9.931 0-16.966-7.083Q484-214.167 484-224.167V-260q0-4 7-17l200-204q34-35 49.5-64t15.5-60q0-42-25-66.5T662-696q-29 0-53 11.5T570-653q-8 11-20.5 14.5t-24.219-1.115Q512-645 506-656q-6-11 1-22 23-38 64.5-60t90.862-22Q733-760 777.5-717T822-605q0 41-19 79t-64 83L563-263l2.47 3H810q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-200ZM250-410H150q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-470h100v-100q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T310-570v100h100q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T410-410H310v100q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T250-310v-100Z"
		/>
	</svg>
);
