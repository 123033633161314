import { ReactNode } from 'react';

export const symbol_nest_true_radiant: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M232.765-120Q186-120 153-152.667 120-185.333 120-232v-378q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T180-610v378q0 22.1 15.5 37.05Q211-180 233.07-180t37-14.95Q285-209.9 285-232v-495q0-47.083 32.941-80.042 32.941-32.958 80-32.958t79.559 32.958Q510-774.083 510-727v495q0 22.1 15.5 37.05Q541-180 563.07-180t37-14.95Q615-209.9 615-232v-495q0-47.083 32.941-80.042 32.941-32.958 80-32.958t79.559 32.958Q840-774.083 840-727v377q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T780-350v-377q0-22-14.93-37.5t-37-15.5q-22.07 0-37.57 15.5T675-727v495q0 46.667-32.735 79.333Q609.529-120 562.765-120 516-120 483-152.667 450-185.333 450-232v-495q0-22-14.93-37.5t-37-15.5q-22.07 0-37.57 15.5T345-727v495q0 46.667-32.735 79.333Q279.529-120 232.765-120Z"
		/>
	</svg>
);
