import { ReactNode } from 'react';

export const symbol_water_lux: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M344-123q-45 0-70.5-27T210-177q-15 0-29 6t-55 31q-14 8-28 .5T84-164q0-8 4-13.5t10-9.5q54-33 72.5-41.5T210-237q45 0 70 27t64 27q39 0 65-27t71-27q45 0 70.5 27t64.5 27q39 0 64.5-27t70.5-27q21 0 40 8.5t73 41.5q6 4 9.5 9.5T876-164q0 17-14 24.5t-28-.5q-41-25-55-31t-29-6q-38 0-64 27t-71 27q-45 0-71-27t-64-27q-38 0-64.5 27T344-123ZM240-810q0 13-8.5 21.5T210-780h-60q-13 0-21.5-8.5T120-810q0-13 8.5-21.5T150-840h60q13 0 21.5 8.5T240-810Zm57 150q9 9 9 21.5t-9 21.5l-42 42q-9 9-21 9t-21-9q-9-9-9-21.5t9-21.5l42-42q9-9 21-9t21 9Zm183 0q-63 0-111.5-40T305-802q-3-13 3-24t18-14q12-3 23 3.5t14 18.5q10 42 42 70t75 28q43 0 75-28t42-70q3-12 14-18.5t23-3.5q12 3 18 14t3 24q-15 62-63.5 102T480-660Zm0-180Zm0 240q13 0 21.5 8.5T510-570v60q0 13-8.5 21.5T480-480q-13 0-21.5-8.5T450-510v-60q0-13 8.5-21.5T480-600Zm182-61q9-9 21-8.5t21 8.5l43 42q9 9 9.5 21t-8.5 21q-9 9-21.5 9t-21.5-9l-43-42q-9-9-9-21t9-21Zm178-149q0 13-8.5 21.5T810-780h-60q-13 0-21.5-8.5T720-810q0-13 8.5-21.5T750-840h60q13 0 21.5 8.5T840-810ZM344-283q-45 0-70.5-27T210-337q-15 0-29 6t-55 31q-14 8-28 .5T84-324q0-8 4-13.5t10-9.5q54-33 72.5-41.5T210-397q45 0 70 27t64 27q39 0 65-27t71-27q45 0 70.5 27t64.5 27q39 0 64.5-27t70.5-27q21 0 40 8.5t73 41.5q6 4 9.5 9.5T876-324q0 17-14 24.5t-28-.5q-41-25-55-31t-29-6q-38 0-64 27t-71 27q-45 0-71-27t-64-27q-38 0-64.5 27T344-283Z"
		/>
	</svg>
);
