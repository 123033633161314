import { SyntheticEvent } from 'react';

import Icon from 'components/icon';

import * as css from './styles.css';

const AddButton = ({
	onClick,
}: {
	onClick: (e: SyntheticEvent<HTMLDivElement>) => void;
}) => {
	return (
		<div onClick={onClick} className={css.button}>
			<Icon name="add" />
		</div>
	);
};

export default AddButton;
