import { ReactNode } from 'react';

export const symbol_weekend: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M152-692q0-45 31.5-76.5T260-800h440q45 0 76.5 31.5T808-692v83q48-5 80 21t32 75v245q0 45-31.5 76.5T812-160H148q-45 0-76.5-31.5T40-268v-245q0-49 32.5-75t79.5-21v-83Zm60 0v112q14 14 22 27.5t8 39.5v124h476v-124q0-26 8-39.5t22-27.5v-112q0-21-13.5-34.5T700-740H260q-21 0-34.5 13.5T212-692Zm566 363H182v-184q0-10-2.5-17.5t-8-13q-5.5-5.5-13-8t-17.1-2.5q-20.4 0-30.9 10.5T100-513v245.161Q100-247 113.5-233.5T148-220h664q21 0 34.5-13.5t13.5-34.339V-513q0-20-10.5-30.5T818.6-554q-9.6 0-17.1 2.5-7.5 2.5-12.677 8-5.176 5.5-8 13Q778-523 778-513v184Zm-298 0Zm0 109Zm0-169Z"
		/>
	</svg>
);
