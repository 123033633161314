import { t } from '@transifex/native';
import styled from 'styled-components';

import * as palette from 'pkg/config/palette';

import { FootballField, LineupContainer } from 'components/football-field';

import Button from 'design/button';

const OuterField = styled.div`
	position: relative;
	min-height: 0;
	width: 50vw;
	max-width: calc(100vh * 0.6);

	@media all and (orientation: portrait) {
		width: 100%;
		height: 100%;
	}
`;

const Outer = styled.div`
	grid-area: canvas;
	flex: 0 0 auto;
	background: ${palette.white};
	max-height: 100%;
	height: auto;
	min-height: 0;
	width: 100%;
	background: ${palette.green[500]};
	z-index: 3;
	position: relative;
	padding: 1rem;

	display: grid;
	align-items: center;
	align-content: center;
	grid-gap: 0.5rem;
	justify-items: center;

	@media all and (orientation: portrait) {
		padding: 0.5rem 1rem;

		${LineupContainer} {
			height: 100%;
		}
	}

	.formation-score {
		z-index: 2;
		display: flex;
		align-items: center;
		flex-direction: row;
		font-size: 24px;
		position: absolute;
		top: 1rem;

		@media all and (max-height: 900px) {
			position: static;
		}

		@media all and (min-width: 641px) and (max-width: 768px) and (orientation: landscape),
			all and (max-width: 640px) and (orientation: landscape) {
			font-size: 12px;

			.formation-score__optimize {
				display: none;
			}

			.formation-score__score {
				margin: 0;
			}
		}

		${Button} {
			color: ${palette.white};
			box-shadow: inset 0 0 0 1px ${palette.white};
		}
	}

	.formation-score__score {
		font-weight: var(--font-weight-semibold);
		font-size: 1.35rem;
		text-align: right;
		color: ${palette.white};
		text-shadow: 0 1px 0 #60a037;
		margin-right: 1rem;
	}
`;

const Canvas = ({ formation }) => {
	return (
		<Outer as="main" type={formation?.formationType}>
			<div className="formation-score">
				<div className="formation-score__score js-formation-score">0.00</div>
				<Button
					transparent
					small
					className="formation-score__optimize optimize-formation-score">
					{t(`Optimize`)}
				</Button>
			</div>

			<OuterField>
				<FootballField opacity={0.6} outline className="formation-canvas">
					{formation?.positions?.map((position, index) => {
						const props = {
							key: index,
							'data-id': position.positionId,
							'data-short-name': position.position.name,
							'data-slug': position.position.slug,
							className: 'field-position--placed field-position',
							style: {
								position: 'absolute',
								top: `${position.positionTop}%`,
								left: `${position.positionLeft}%`,
							},
						};

						if (position.user) {
							props['data-player-id'] = position.user.userId;
							props['data-is-locked'] = position.user.isLocked;
						}

						return (
							<div {...props}>
								<div className="field-position__title">
									{position.position.name}

									<span className="position-score"></span>
								</div>

								<div className="player-wrapper" />
							</div>
						);
					})}
				</FootballField>
			</OuterField>
		</Outer>
	);
};

export default Canvas;
