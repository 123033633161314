import { ReactNode } from 'react';

export const symbol_select_to_speak: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M780-481q0-93-52.5-168.5T590-759q-12-5-17-15.875T573-797q6-12 18.5-16.5t25.5.5q100 41 161.5 130.5T840-481q0 112-61.5 201.5T617-149q-13 5-25.5.5T573-165q-5-11.25 0-22.125T590-203q85-34 137.5-109.5T780-481ZM280-360H150q-12.75 0-21.375-8.625T120-390v-180q0-12.75 8.625-21.375T150-600h130l149-149q14-14 32.5-6.344T480-728v496q0 19.688-18.5 27.344Q443-197 429-211L280-360Zm380-119.94q0 45.94-21.5 85.44T580-331q-7 3-23.5 9T540-336v-289q0-20 17-14t24 9q37 24 58 63.5t21 86.56ZM420-311v-337L307-540H180v120h127l113 109ZM69.825-688Q57-688 48.5-696.625T40-718v-142q0-24.75 17.625-42.375T100-920h142q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T242-860H100v142q0 12.75-8.675 21.375Q82.649-688 69.825-688ZM718-40q-12.75 0-21.375-8.675Q688-57.351 688-70.175 688-83 696.625-91.5T718-100h142v-142q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T920-242v142q0 24.75-17.625 42.375T860-40H718ZM300-480Z"
		/>
	</svg>
);
