const icon = {
	name: 'camera',
	objects: [
		{
			type: 'path',
			attributes: {
				d: 'M19.3639 6.79482L19.3588 6.79482H17.2418C16.0449 6.79482 14.939 6.15626 14.3406 5.11969L13.8593 4.28611C13.7432 4.08498 13.5286 3.96108 13.2964 3.96108H10.6983C10.4661 3.96108 10.2515 4.08498 10.1354 4.28611L9.65415 5.11969C9.05575 6.15626 7.94978 6.79482 6.75288 6.79482H4.63588C4.62744 6.79482 4.61342 6.7964 4.59236 6.80646C4.57067 6.81682 4.53718 6.83852 4.49978 6.88165C4.42108 6.9724 4.35036 7.13197 4.35 7.33207V17.7223C4.35 17.9229 4.42073 18.083 4.4996 18.174C4.53704 18.2173 4.57059 18.239 4.5923 18.2494C4.61339 18.2595 4.62744 18.2611 4.63588 18.2611H19.3641C19.3724 18.2611 19.3865 18.2595 19.4077 18.2494C19.4297 18.2389 19.4632 18.217 19.5004 18.174C19.5379 18.1308 19.576 18.0694 19.6048 17.9893C19.6335 17.9091 19.65 17.818 19.65 17.7223V7.33357C19.65 7.23775 19.6334 7.14639 19.6046 7.06616C19.5758 6.98595 19.5375 6.92452 19.4999 6.88133C19.4627 6.83848 19.4292 6.81669 19.4073 6.80628C19.3862 6.79628 19.3722 6.79478 19.3639 6.79482ZM20.8755 18.4451C20.9577 18.216 21 17.9704 21 17.7223V7.33358C21 7.08503 20.9575 6.83892 20.875 6.60937C20.7924 6.37982 20.6715 6.17133 20.519 5.99586C20.3665 5.82039 20.1856 5.6814 19.9865 5.58684C19.7874 5.49228 19.5741 5.44402 19.3588 5.44482H17.2418C16.5273 5.44482 15.867 5.06359 15.5097 4.44474L15.0285 3.61117C14.6713 2.99232 14.011 2.61108 13.2964 2.61108H10.6983C9.98373 2.61108 9.32345 2.99232 8.9662 3.61117L8.48498 4.44474C8.12773 5.06359 7.46745 5.44482 6.75288 5.44482H4.63588C4.20239 5.44482 3.78661 5.64348 3.47989 5.99716C3.17316 6.35084 3.00056 6.83063 3 7.33113V17.7223C3 18.2233 3.17235 18.7037 3.47914 19.0579C3.78593 19.4121 4.20202 19.6111 4.63588 19.6111H19.3641C19.5789 19.6111 19.7917 19.5622 19.9901 19.4673C20.1886 19.3724 20.369 19.2333 20.5209 19.0579C20.6728 18.8825 20.7933 18.6743 20.8755 18.4451Z',
				clipRule: 'evenodd',
				fillRule: 'evenodd',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M12 9.42111C11.5402 9.42111 11.0907 9.55747 10.7083 9.81294C10.326 10.0684 10.028 10.4315 9.85199 10.8564C9.67602 11.2812 9.62997 11.7487 9.71968 12.1997C9.80939 12.6507 10.0308 13.065 10.356 13.3901C10.6811 13.7153 11.0954 13.9367 11.5464 14.0264C11.9974 14.1161 12.4649 14.0701 12.8897 13.8941C13.3146 13.7182 13.6777 13.4202 13.9332 13.0378C14.1886 12.6555 14.325 12.2059 14.325 11.7461C14.325 11.1295 14.0801 10.5381 13.644 10.1021C13.208 9.66606 12.6166 9.42111 12 9.42111ZM9.95829 8.69046C10.5626 8.28664 11.2732 8.07111 12 8.07111C12.9747 8.07111 13.9094 8.4583 14.5986 9.14749C15.2878 9.83669 15.675 10.7714 15.675 11.7461C15.675 12.473 15.4595 13.1835 15.0557 13.7878C14.6518 14.3922 14.0779 14.8632 13.4064 15.1414C12.7349 15.4195 11.9959 15.4923 11.2831 15.3505C10.5702 15.2087 9.91535 14.8587 9.40139 14.3447C8.88743 13.8308 8.53742 13.1759 8.39562 12.4631C8.25382 11.7502 8.3266 11.0113 8.60475 10.3397C8.8829 9.66823 9.35394 9.09427 9.95829 8.69046Z',
				clipRule: 'evenodd',
				fillRule: 'evenodd',
			},
		},
	],
};

export default icon;
