import { ReactNode } from 'react';

export const symbol_sync_lock: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M220-477q0 63.683 27.5 117.341Q275-306 337-266v-94q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T397-360v170q0 12.75-8.625 21.375T367-160H197q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T197-220h100q-64-48-100.5-108.5T160-477q0-89 46.5-167.5T340-765q11-5 21.5-.5t15 15.5q4.5 11 .5 22.5T362-711q-62 32-102 94.631Q220-553.737 220-477Zm549.474 18q-12.527 0-21-8.5Q740-476 739-489q-2-49-27.5-102T626-694v94q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T566-600v-170q0-12.75 8.625-21.375T596-800h170q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T766-740H665q73 66 103 129.5T800-490q0 13-9 22t-21.526 9ZM634-80q-17 0-28.5-11.385T594-119.6v-118.8q0-16.83 11.5-28.215Q617-278 634-278h1v-39q0-35 23.5-58.5T717-399q35 0 58.5 23.5T799-317v39h1q17 0 28.5 11.385T840-238.4v118.8q0 16.83-11.5 28.215Q817-80 800-80H634Zm41-198h84v-39q0-19-11.5-30.5T717-359q-19 0-30.5 11.5T675-317v39Z"
		/>
	</svg>
);
