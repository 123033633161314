import { ReactNode } from 'react';

export const symbol_summarize: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M309-621q12 0 21-9t9-21q0-12-9-21t-21-9q-12 0-21 9t-9 21q0 12 9 21t21 9Zm0 171q12 0 21-9t9-21q0-12-9-21t-21-9q-12 0-21 9t-9 21q0 12 9 21t21 9Zm0 171q12 0 21-9t9-21q0-12-9-21t-21-9q-12 0-21 9t-9 21q0 12 9 21t21 9ZM180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h437q12.444 0 23.722 5T660-822l162 162q8 8 13 19.278 5 11.278 5 23.722v437q0 24-18 42t-42 18H180Zm0-60h600v-429H639q-12.75 0-21.375-8.625T609-639v-141H180v600Zm0-600v171.429V-780v600-600Z"
		/>
	</svg>
);
