import * as models from 'pkg/api/models';

interface UserActivityUpdatePayload {
	ratingItemId?: number;
	exerciseId?: number;

	description?: string;
	privateComment?: string;
	physicalStrain: number;

	type: models.userActivity.UserActivityType;

	completedAt?: number;
}

export async function update(
	userActivity: models.userActivity.UserActivity,
	payload: UserActivityUpdatePayload
): Promise<[Response, models.userActivity.UserActivity, models.APIError?]> {
	return models.update<
		UserActivityUpdatePayload,
		models.userActivity.UserActivity
	>(userActivity, payload);
}
