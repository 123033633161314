import { ReactNode } from 'react';

export const symbol_local_gas_station: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M160-150v-630q0-24 18-42t42-18h269q24 0 42 18t18 42v288h65q20.625 0 35.312 14.688Q664-462.625 664-442v219q0 21.675 15.5 36.337Q695-172 717-172t37.5-14.663Q770-201.325 770-223v-295q-11 6-23 9t-24 3q-39.48 0-66.74-27.26Q629-560.52 629-600q0-31.614 18-56.807T695-690l-77-77q-8-7.636-8-17.818Q610-795 618-803q8-8 18-7.5t18 8.5l135 135q14 14 22.5 30.5T820-600v377q0 43.26-29.817 73.13-29.817 29.87-73 29.87T644-149.87q-30-29.87-30-73.13v-219h-65v292q0 12.75-8.625 21.375T519-120H190q-12.75 0-21.375-8.625T160-150Zm60-402h269v-228H220v228Zm503-4q18 0 31-13t13-31q0-18-13-31t-31-13q-18 0-31 13t-13 31q0 18 13 31t31 13ZM220-180h269v-312H220v312Zm269 0H220h269Z"
		/>
	</svg>
);
