import { ReactNode } from 'react';

export const symbol_car_rental: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M321-678q-51 0-86-35t-35-86q0-51 35-86t86-35q42 0 74 27t44 68h291q12.75 0 21.375 8.625T760-795q0 13-8.5 21.5T730-765h-10v57q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T660-708v-57H439q-11 39-44.17 63T321-678Zm.053-60Q347-738 364.5-755.553q17.5-17.552 17.5-43.5Q382-825 364.447-842.5q-17.552-17.5-43.5-17.5Q295-860 277.5-842.447q-17.5 17.552-17.5 43.5Q260-773 277.553-755.5q17.552 17.5 43.5 17.5Zm4.122 475q12.825 0 21.325-8.675 8.5-8.676 8.5-21.5 0-12.825-8.675-21.325-8.676-8.5-21.5-8.5-12.825 0-21.325 8.675-8.5 8.676-8.5 21.5 0 12.825 8.675 21.325 8.676 8.5 21.5 8.5Zm310 0q12.825 0 21.325-8.675 8.5-8.676 8.5-21.5 0-12.825-8.675-21.325-8.676-8.5-21.5-8.5-12.825 0-21.325 8.675-8.5 8.676-8.5 21.5 0 12.825 8.675 21.325 8.676 8.5 21.5 8.5ZM260-168v58q0 12.75-8.675 21.375Q242.649-80 229.825-80 217-80 208.5-88.625T200-110v-256q0-4.9.5-9.45.5-4.55 2.5-9.55l60-175q6-17.778 21.75-28.889T320-600h320q19.5 0 35.25 11.111T697-560l60 175q2 5 2.5 9.55.5 4.55.5 9.45v256q0 12.75-8.675 21.375Q742.649-80 729.825-80 717-80 708.5-88.625T700-110v-58H260Zm17-250h407l-41-122H317l-40 122Zm-17 60v130-130Zm0 130h440v-130H260v130Z"
		/>
	</svg>
);
