import { ReactNode } from 'react';

export const symbol_satellite_alt: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M560-32q-13 0-21.5-8.675-8.5-8.676-8.5-21.5Q530-75 538.625-83.5T560-92q125.357 0 212.679-87.321Q860-266.643 860-392q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T920-392q0 75-28.5 140.271-28.5 65.271-77 114.1Q766-88.801 700.5-60.4 635-32 560-32Zm0-120q-13 0-21.5-8.675-8.5-8.676-8.5-21.5 0-12.825 8.625-21.325T560-212q75 0 127.5-52.5T740-392q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T800-392q0 100-70 170t-170 70ZM222-49q-11.667 0-23.333-4.5Q187-58 179-66L9-236q-8-8-12.5-19.557Q-8-267.115-8-278.672-8-291-3.565-301.875T9-321l151-151q17-17 42.5-17t42.5 17l64 64 37-37-64-64q-17-17.315-17-42.157Q265-576 282-593l75-75q17.378-17 42.689-17T442-668l64 64 37-37-64-64q-17-17.378-17-42.689T479-790l151-151q8.857-9 19.929-13.5Q661-959 673-959q12 0 23 4t20 13l170 170q8.87 8.521 12.935 19.754Q903-741.014 903-728.62q0 11.62-5 23.12-5 11.5-13 19.5L734-535q-23 23-42.5 23T649-535l-64-64-37 37 64 64q17 17.378 16.5 42.689T611-413l-74 74q-17.378 17-42.689 17T452-339l-64-64-37 37 64 64q17 17 17 42.5T415-217L264-66q-8.25 8.13-19.125 12.565Q234-49 222-49Zm0-59 54-54-170-170-54 54 170 170Zm97-97 54-54-170-170-54 54 170 170Zm176-176 74-74-170-170-74 74 170 170Zm197-197 54-54-170-170-54 54 170 170Zm97-97 54-54-170-170-54 54 170 170ZM447-503Z"
		/>
	</svg>
);
