import { ReactNode } from 'react';

export const symbol_watch_wake: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M799.825-290Q787-290 778.5-298.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5ZM840-450h-40q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T800-510h40q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T840-450Zm40-160h-80q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5Q770-653 778.625-662T800-671h80q12.75 0 21.375 9.103 8.625 9.104 8.625 22 0 12.897-8.625 21.397Q892.75-610 880-610ZM309-80q-10.684 0-18.316-6.069Q283.053-92.138 280-102l-41-149q-55-35-87-95t-32-134q0-74 32-134.5t87-94.5l41-149q3.053-9.862 10.684-15.931Q298.316-880 309-880h182q10.684 0 18.316 6.069Q516.947-867.862 520-858l41 149q55 34 87 94.5T680-480q0 74-32 134t-87 95l-41 149q-3.053 9.862-10.684 15.931Q501.684-80 491-80H309Zm91-180q92 0 156-64t64-156q0-92-64-156t-156-64q-92 0-156 64t-64 156q0 92 64 156t156 64Zm-87-484q23-7 44.5-10.5T400-758q21 0 42.5 3.5T487-744l-18.353-76H331l-18 76Zm18.353 604H469l18-76q-23 6-44.5 9.5T400-203q-21 0-42.5-3.5T313-216l18.353 76ZM313-820h174-174Zm18.316 680H313h174-155.684Z"
		/>
	</svg>
);
