import { ReactNode } from 'react';

export const symbol_move_selection_right: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M460-240q-24.75 0-42.375-17.625T400-300v-360q0-24.75 17.625-42.375T460-720h360q24.75 0 42.375 17.625T880-660v360q0 24.75-17.625 42.375T820-240H460Zm0-420v360h360v-360H460Zm-190.175 0Q257-660 248.5-668.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Zm-160 0Q97-660 88.5-668.675q-8.5-8.676-8.5-21.5Q80-703 88.675-711.5q8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Zm0 210Q97-450 88.5-458.675q-8.5-8.676-8.5-21.5Q80-493 88.675-501.5q8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Zm0 210Q97-240 88.5-248.675q-8.5-8.676-8.5-21.5Q80-283 88.675-291.5q8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Zm160 0Q257-240 248.5-248.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5ZM640-480Z"
		/>
	</svg>
);
