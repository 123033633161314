import { ReactNode } from 'react';

export const symbol_vibration: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M30.175-595Q43-595 51.5-586.375T60-565v170q0 12.75-8.675 21.375Q42.649-365 29.825-365 17-365 8.5-373.625T0-395v-170q0-12.75 8.675-21.375Q17.351-595 30.175-595Zm120-88q12.825 0 21.325 8.625T180-653v346q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T120-307v-346q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625Zm780 88q12.825 0 21.325 8.625T960-565v170q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T900-395v-170q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625Zm-120-88q12.825 0 21.325 8.625T840-653v346q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T780-307v-346q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625ZM300-120q-24.75 0-42.375-17.625T240-180v-600q0-24.75 17.625-42.375T300-840h360q24.75 0 42.375 17.625T720-780v600q0 24.75-17.625 42.375T660-120H300Zm0-60h360v-600H300v600Zm0 0v-600 600Z"
		/>
	</svg>
);
