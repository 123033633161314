import { useState } from 'react';
import { useT } from '@transifex/react';

import { LoginErrorCodes, LoginErrorResponse } from 'pkg/actions/sso';

import * as actions from 'pkg/actions';
import * as models from 'pkg/api/models';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { LoginTypes, storeLoginInfo } from 'pkg/local_storage/login-info';
import { useQueryParams } from 'pkg/hooks/query-state';
import { parse } from 'pkg/json';

import { useOnboardingState } from 'routes/public/hooks';
import OnboardingSSOError from 'routes/public/steps/login/sso/redirect/error';
import { useOnboardingContext } from 'routes/public/onboarding';

import { Spinner } from 'components/loaders/spinner';

export default function LoginSSORedirect() {
	const t = useT();

	const [state, setState] = useState<LoginErrorResponse>(null);
	const query = useQueryParams();
	const parsedState = parse(query?.state as string);
	const onboardingCtx = useOnboardingContext();
	const onboardingState = useOnboardingState();
	const group: models.onboardingInfo.Group = onboardingState.get('group');

	const organizationId =
		group?.organizationId ?? (parsedState?.organizationId as number);

	useComponentDidMount(async () => {
		const [req, resp, err] = await actions.sso.login({
			code: query.code as string,
		});

		if (!req.ok) {
			setState(err);
			onboardingCtx.overrideBackSlug('start');

			return;
		}

		actions.auth.updateAuthToken(
			{
				accountToken: resp.accountToken,
				orgToken: resp.orgToken,
			},
			organizationId
		);

		storeLoginInfo(
			resp.account,
			LoginTypes.SSO,
			parsedState?.identityProvider as unknown as models.identityProvider.ScrubbedIdentityProvider,
			organizationId
		);

		window.history.pushState({}, '', '/');
		window.location.reload();
	});

	const handleStartClick = () => {
		onboardingCtx.goTo('start');
	};

	if (state) {
		if (state?.error_code === LoginErrorCodes.UserNotFound) {
			return (
				<OnboardingSSOError
					title={t('Sorry, you do not have a 360Player account yet')}
					description={t(
						'You need to create a 360Player account before continuing logging in.'
					)}
					group={group}
					handleStartClick={handleStartClick}
				/>
			);
		} else {
			return (
				<OnboardingSSOError
					title={t('Login failed')}
					description={t(
						'You where not able to authenticate with the identity provider.'
					)}
					group={group}
					handleStartClick={handleStartClick}
				/>
			);
		}
	}

	return <Spinner />;
}
