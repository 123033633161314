import { ReactNode } from 'react';

export const symbol_sports_mma: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M320-120q-18 0-29-11t-11-29v-95h400v95q0 18-11 29t-29 11H320Zm440-525v127q0 7-1 9l-31 148q-4 17-16 27t-29 10H277q-17 0-29-10t-16-27l-31-148q-1-2-1-9v-262q0-26 17-43t43-17h370q26 0 43 17t17 43v135q0-16 9.5-25.5T725-680q16 0 25.5 9.5T760-645ZM289-384h382l29-147v-37h-70v-212H260v249l29 147Zm191-198Zm-78 14h86q28 0 47.5-19.5T555-635q0-28-19.5-47.5T488-702h-86q-28 0-47.5 19.5T335-635q0 28 19.5 47.5T402-568Z"
		/>
	</svg>
);
