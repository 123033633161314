import * as styles from 'pkg/config/styles';

import * as endpoints from 'pkg/api/endpoints/auto';
import { useEndpoint } from 'pkg/api/use_endpoint';
import * as models from 'pkg/api/models';

import Post from 'routes/wall/post';

import Adslot, { AdslotBoundary } from 'components/adslot';

import Column from 'components/layout/column';

interface GroupPostProps {
	id: number;
}

const GroupPost = ({ id }: GroupPostProps) => {
	const { record: post } = useEndpoint<models.groupPost.GroupPost>(
		endpoints.GroupPosts.Show(id)
	);

	return (
		<Column spacing={styles.spacing._8}>
			<Post post={post} showFullPost />
			<AdslotBoundary>
				<Adslot dynamicHeight height={600} />
			</AdslotBoundary>
		</Column>
	);
};

export default GroupPost;
