import { ReactNode } from 'react';

export const symbol_flare: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M272-479.825q0 12.825-8.625 21.325T242-450H70q-12.75 0-21.375-8.675Q40-467.351 40-480.175 40-493 48.625-501.5T70-510h172q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5ZM332-628q-9 9-21 9t-21-9l-38-38q-9-9-9-21t9-21q9-9 21-9t21 9l38 38q9 9 9 21t-9 21Zm118-90v-172q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-890v172q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T450-718Zm178 90q-9-9-9-21t9-21l38-38q9-9 21-9t21 9q9 9 9 21t-9 21l-38 38q-9 9-21 9t-21-9Zm60 147.825q0-12.825 8.625-21.325T718-510h172q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T890-450H718q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5ZM480-378q-42.5 0-72.25-29.75T378-480q0-42.5 29.75-72.25T480-582q42.5 0 72.25 29.75T582-480q0 42.5-29.75 72.25T480-378Zm148 46q9-9 21-9t21 9l43 43q9 9 9 21t-9 21q-9 9-21 9t-21-9l-43-43q-9-9-9-21t9-21Zm-296 0q9 9 9 21t-9 21l-43 43q-9 9-21 9t-21-9q-9-9-9-21t9-21l43-43q9-9 21-9t21 9Zm148.175 60q12.825 0 21.325 8.625T510-242v172q0 12.75-8.675 21.375Q492.649-40 479.825-40 467-40 458.5-48.625T450-70v-172q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625Z"
		/>
	</svg>
);
