import { useContext } from 'react';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import { useQueryState } from 'pkg/hooks/query-state';
import { EventTypes, sortEventTypesByLabels } from 'pkg/api/models/event';

import {
	defaultFilters,
	FilterState,
} from 'routes/scheduling/templates/filters';
import { TemplateContext } from 'routes/scheduling/templates/single';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import Icon from 'components/icon';

import Row from 'components/layout/row';
import * as GroupGridItem from 'components/scheduling/grid/column-item';
import * as columnStyles from 'components/scheduling/grid/column-item/styles.css';

import * as Context from 'design/context_menu';

interface GroupColumnProps {
	group: models.group.Group;
}

const GroupColumn = ({ group }: GroupColumnProps) => {
	const templateContext = useContext(TemplateContext);
	const qs = useQueryState();
	const qsFilters = qs.get('schedulingFilters');
	const filters: FilterState =
		qsFilters !== 'undefined' && qsFilters
			? JSON.parse(qsFilters as string)
			: defaultFilters;

	const parentGroup = templateContext.flattenedGroups?.find(
		(g) => g.id === group.parentGroupId
	);

	const handleToggleVisibility = () => {
		qs.set(
			'schedulingFilters',
			JSON.stringify({
				...filters,
				groups:
					filters.groups.length > 0
						? filters.groups.filter((g) => g !== group.id)
						: templateContext.flattenedGroups
								.filter((g) => g.id !== group.id)
								.map((g) => g.id),
			})
		);
		qs.commit();
	};

	const handleShowOnlyRow = () => {
		qs.set(
			'schedulingFilters',
			JSON.stringify({
				...filters,
				groups: [group.id],
			})
		);
		qs.commit();
	};

	const handleRemoveScheduleItems = async () => {
		const itemsToRemove = templateContext.scheduleItems.filter(
			(item) => item.groupId === group.id
		);

		itemsToRemove.forEach(async (item) => {
			await models.scheduleItem.remove(item);
		});
	};

	const groupScheduleItems = templateContext.scheduleItems.filter(
		(item) => item.groupId === group.id
	);

	const mappedEventTypes: { [key: string]: number[] } = {};

	groupScheduleItems?.forEach((item) => {
		if (item.type in mappedEventTypes) {
			mappedEventTypes[item.type] = [...mappedEventTypes[item.type], item.id];
		} else {
			mappedEventTypes[item.type] = [item.id];
		}
	});

	const eventTypes =
		groupScheduleItems?.length > 0 ? (
			<div className={columnStyles.eventTypeWrapper}>
				{Object.keys(mappedEventTypes)
					.sort((a: EventTypes, b: EventTypes) => sortEventTypesByLabels(a, b))
					.map((k, i) => (
						<GroupGridItem.Type key={i} type={k as EventTypes}>
							{mappedEventTypes[k].length}
						</GroupGridItem.Type>
					))}
			</div>
		) : null;

	return (
		<div className={columnStyles.wrapper}>
			<div className={columnStyles.name}>
				<div className={columnStyles.parentName}>{parentGroup?.name}</div>
				<div className={columnStyles.nameRow}>
					<div>{group.name}</div>
					<Row autoColumns="auto" align="center">
						<SmallScreen>{eventTypes}</SmallScreen>
						<Context.Menu toggleWith={<Icon name="context-menu" />}>
							<Context.Item onClick={handleToggleVisibility}>
								<Context.ItemIcon name="visibility_off" />
								{t('Hide row')}
							</Context.Item>
							<Context.Item onClick={handleShowOnlyRow}>
								<Context.ItemIcon name="visibility" />
								{t('Show only this row')}
							</Context.Item>
							{!!templateContext.schedule && (
								<Context.ConfirmItem
									caution
									onConfirm={handleRemoveScheduleItems}
									message={t(
										'Are you sure you want to remove all bookings for this group?'
									)}>
									<Context.ItemIcon name="delete" />
									{t('Remove items')}
								</Context.ConfirmItem>
							)}
						</Context.Menu>
					</Row>
				</div>
			</div>
			<LargeScreen>{eventTypes}</LargeScreen>
		</div>
	);
};

export default GroupColumn;
