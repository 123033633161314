import { ReactNode } from 'react';

export const symbol_wb_auto: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M256.158-431H385l24 68q1.714 6.067 6.857 9.533Q421-350 427.5-350q11.143 0 17.321-8.867Q451-367.733 447-378L346-643q-2-5-6.826-8.5t-11.261-3.5h-14.478Q307-655 302-651.5q-5 3.5-7 8.5L193-377q-4 9.9 2.034 18.45 6.035 8.55 16.794 8.55 7.172 0 12.672-4 5.5-4 7.5-10l24.158-67ZM268-466l51-142h3l51 142H268Zm55 266q-117 0-198.5-81.5T43-480q0-117 81.5-198.5T323-760q86 0 156.5 47T581-586l-8-44q-2-10 3.5-17.5T592-655q7.273 0 12.727 3.971 5.455 3.97 7.273 11.029l49.739 217H664l61-221q2-5 5.716-8t8.446-3h8.108q4.73 0 8.23 3t5.5 8l61 221 50-217q1.818-7.059 7.273-11.029Q884.727-655 892-655q10 0 15.5 7.5T911-630l-62 264q-2 7-7.077 11.5t-12.461 4.5h-11.077q-7.385 0-12.956-4.219Q799.857-358.438 798-365l-55-199h-4l-55 199q-2 7-7.419 11-5.42 4-12.646 4H654q-7.6 0-13.3-4.5Q635-359 633-366l-29-123q2 119-80.5 204T323-200Zm0-60q92 0 156-64t64-156q0-92-64-156t-156-64q-92 0-156 64t-64 156q0 92 64 156t156 64Zm0-220Z"
		/>
	</svg>
);
