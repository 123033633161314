import styled, { DefaultTheme } from 'styled-components';

import * as styles from 'pkg/config/styles';

import { addCssVar } from 'pkg/cssvars';
import rgba from 'pkg/rgba';

const backgroundColor = addCssVar((theme: DefaultTheme) =>
	theme.darkMode ? styles.palette.gray[700] : styles.palette.white
);

const foregroundColor = addCssVar((theme: DefaultTheme) =>
	theme.darkMode ? styles.palette.white : styles.palette.black
);

const activeBackgroundColor = addCssVar((theme: DefaultTheme) =>
	theme.darkMode ? styles.palette.gray[800] : styles.palette.gray[200]
);

const borderColor = addCssVar(
	(theme: DefaultTheme) => styles.palette.gray[theme.darkMode ? 800 : 300]
);

export const AutocompleteWrapper = styled.div`
	position: relative;
	width: 100%;

	@media not ${styles.breakpoint.small} {
		max-width: 440px;
	}
`;

export const SuggestionsWrapper = styled.div`
	position: absolute;
	width: calc(100% - 4px);
	left: 2px;
	top: 40px;
`;

export const Suggestions = styled.ul`
	padding: var(--spacing-3) 1px;
	background-color: ${backgroundColor};
	border: 1px solid ${borderColor};
	color: ${foregroundColor};
	width: calc(100% + 8px);
	left: -4px;
	top: -6px;
	border-radius: var(--radius-4);
	max-height: 420px;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	display: block;
	position: relative;
	box-shadow:
		0px 4px 4px ${rgba(styles.palette.black, 0.1)},
		0px 10px 15px ${rgba(styles.palette.black, 0.1)};

	&[data-inline='false'] {
		position: absolute;
	}
`;

export const SuggestionGroup = styled.li`
	&:first-child span {
		padding-top: 0;
	}

	&:not(:first-child) {
		margin-top: var(--spacing-3);
		padding-top: var(--spacing-1);
		border-top: 1px solid ${borderColor};
	}
`;

export const SuggestionGroupLabel = styled.span`
	padding: var(--spacing-3);
	font-size: var(--font-size-xs);
	font-weight: var(--font-weight-bold);
	color: var(--palette-gray-500);
	display: block;
`;

export const Suggestion = styled.li`
	padding: var(--spacing-3);
	font-size: var(--font-size-xs);
	cursor: pointer;

	mark {
		margin-left: 2px;
		background-color: var(--palette-green-300);
		box-shadow: 0 0 0 2px var(--palette-green-300);
		border-radius: var(--radius-3);
	}

	&[data-active='true'] {
		background: ${activeBackgroundColor};
	}

	@media (hover: hover) {
		&:hover {
			background: ${activeBackgroundColor};
		}
	}
`;

export const DefaultItem = styled.div`
	padding: var(--spacing-3);
	pointer-events: none;
`;
