import { ReactNode } from 'react';

export const symbol_folder_shared: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-160q-24 0-42-18.5T80-220v-520q0-23 18-41.5t42-18.5h256q12.444 0 23.722 5t19.366 13.088L481-740h339q23 0 41.5 18.5T880-680v460q0 23-18.5 41.5T820-160H140Zm0-60h680v-460H456l-60-60H140v520Zm0 0v-520 520Zm299-70h320v-19q0-42-42.5-68.5T599-404q-75 0-117.5 26.5T439-309v19Zm159.92-174q30.08 0 51.58-21.42 21.5-21.421 21.5-51.5 0-30.08-21.42-51.58-21.421-21.5-51.5-21.5-30.08 0-51.58 21.42-21.5 21.421-21.5 51.5 0 30.08 21.42 51.58 21.421 21.5 51.5 21.5Z"
		/>
	</svg>
);
