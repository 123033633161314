// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IIPjoYt6GYtmO9wOyBQs {
	padding: var(--spacing-5);
}

.IIPjoYt6GYtmO9wOyBQs:not(:last-of-type) {
	border-bottom: 1px solid var(--palette-gray-300);
}

.ZVt6Ta3OjCKTfXrEyokt {
	color: var(--palette-gray-500);
}

.ZVt6Ta3OjCKTfXrEyokt,
	.ZVt6Ta3OjCKTfXrEyokt div,
	.ZVt6Ta3OjCKTfXrEyokt p {
		font-size: var(--font-size-xs);
	}
`, "",{"version":3,"sources":["webpack://./components/sidebar/panel/styles.css"],"names":[],"mappings":"AAAA;CACC,yBAAyB;AAC1B;;AAEA;CACC,gDAAgD;AACjD;;AAEA;CACC,8BAA8B;AAO/B;;AALC;;;EAGC,8BAA8B;CAC/B","sourcesContent":[".panel {\n\tpadding: var(--spacing-5);\n}\n\n.panel:not(:last-of-type) {\n\tborder-bottom: 1px solid var(--palette-gray-300);\n}\n\n.footer {\n\tcolor: var(--palette-gray-500);\n\n\t&,\n\tdiv,\n\tp {\n\t\tfont-size: var(--font-size-xs);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var panel = `IIPjoYt6GYtmO9wOyBQs`;
export var footer = `ZVt6Ta3OjCKTfXrEyokt`;
export default ___CSS_LOADER_EXPORT___;
