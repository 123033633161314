import styled from 'styled-components';

import { small } from 'pkg/config/breakpoints';

/* @components */
const Wrapper = styled.div`
	margin-top: 20px;
	width: 100%;
	display: grid;
	grid-gap: 20px;
	grid-auto-flow: row;

	@media ${small} {
		grid-gap: 0;
	}
`;

export default ({ children }) => <Wrapper>{children}</Wrapper>;
