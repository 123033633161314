import { ReactNode } from 'react';

export const symbol_connect_without_contact: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M690-80q-12.75 0-21.375-8.625T660-110v-57q-40.545-13.197-72.772-40.098Q555-234 537-272q-5-11 1.087-21.814t18.261-15.86Q567-314 577.5-309q10.5 5 15.5 16 16 34 47.5 53.5T710-220h120q20.833 0 35.417 14.583Q880-190.833 880-170v60q0 12.75-8.625 21.375T850-80H690Zm79.956-195Q739-275 717-297.044q-22-22.045-22-53Q695-381 717.044-403q22.045-22 53-22Q801-425 823-402.956q22 22.045 22 53Q845-319 822.956-297q-22.045 22-53 22ZM110-535q-12.75 0-21.375-8.625T80-565v-60q0-20.833 14.583-35.417Q109.167-675 130-675h120q37.079 0 68.539-19.5Q350-714 366-747q5-11 16.5-17t22.438-2.184q12.395 3.816 18.229 14.5Q429-741 424-730q-17.21 38.776-49.605 66.888Q342-635 300-622v57q0 12.75-8.625 21.375T270-535H110Zm79.956-195Q159-730 137-752.044q-22-22.045-22-53Q115-836 137.044-858q22.045-22 53-22Q221-880 243-857.956q22 22.045 22 53Q265-774 242.956-752q-22.045 22-53 22Zm229.869 310Q407-420 398.5-428.625T390-450q0-112 79-191t191-79q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T660-660q-87.5 0-148.75 61.25T450-450q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Zm120 0Q527-420 518.5-428.625T510-450q0-63 43.5-106.5T660-600q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T660-540q-38 0-64 26t-26 64q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Z"
		/>
	</svg>
);
