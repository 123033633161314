import { ReactNode } from 'react';

export const symbol_notifications_active: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M190-200q-13 0-21.5-8.5T160-230q0-13 8.5-21.5T190-260h50v-304q0-84 49.5-150.5T420-798v-22q0-25 17.5-42.5T480-880q25 0 42.5 17.5T540-820v22q81 17 130.5 83.5T720-564v304h50q13 0 21.5 8.5T800-230q0 13-8.5 21.5T770-200H190Zm290-302Zm0 422q-33 0-56.5-23.5T400-160h160q0 33-23.5 56.5T480-80ZM300-260h360v-304q0-75-52.5-127.5T480-744q-75 0-127.5 52.5T300-564v304ZM150-566q-13 0-21.5-9t-7.5-22q6-71 38-132t83-106q10-9 22.5-8.5T285-832q8 11 5.5 23.5T278-787q-40 36-65 84t-31 105q-2 14-10.5 23t-21.5 9Zm660 0q-13 0-21.5-9T778-598q-6-57-31-105t-65-84q-10-9-12.5-21.5T675-832q8-11 20.5-11.5T718-835q51 45 83 106t38 132q1 13-7.5 22t-21.5 9Z"
		/>
	</svg>
);
