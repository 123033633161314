import { ReactNode } from 'react';

export const symbol_thumb_up: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M855-632q24 0 42 18t18 42v81.839q0 7.161 1.5 14.661T915-461L789-171q-8.878 21.25-29.595 36.125Q738.689-120 716-120H272v-512l225-238q13.6-14 32.187-16.5Q547.773-889 565-879q17 10 25.5 27.5t4.2 36.5L556-632h299Zm-523 25v427h397l126-299v-93H482l53-249-203 214ZM139-120q-24.75 0-42.375-17.625T79-180v-392q0-24.75 17.625-42.375T139-632h133v60H139v392h133v60H139Zm193-60v-427 427Z"
		/>
	</svg>
);
