import { ControllerType } from 'components/video-analytics/Controller';

export default class StubController {
	public signature: string;

	public setSource(): void {
		return;
	}

	public async getCurrentTime(): Promise<number> {
		return Promise.resolve(0);
	}

	public async getDuration(): Promise<number> {
		return Promise.resolve(0);
	}

	public async play(): Promise<void> {
		return Promise.resolve();
	}

	public async pause(): Promise<void> {
		return Promise.resolve();
	}

	public isPlaying(): boolean {
		return false;
	}

	public async skip(): Promise<number> {
		return Promise.resolve(0);
	}

	public async seekTo(): Promise<number> {
		return Promise.resolve(0);
	}

	public async setVolume(): Promise<void> {
		return Promise.resolve();
	}

	public async getVolume(): Promise<number> {
		return Promise.resolve(0);
	}

	public async mute(): Promise<void> {
		return;
	}

	public async unmute(): Promise<void> {
		return;
	}

	public async getAvailablePlaybackRates(): Promise<readonly number[]> {
		return Promise.resolve([0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2]);
	}

	public async setPlaybackRate(): Promise<void> {
		return Promise.resolve();
	}

	public async getPlaybackRate(): Promise<number> {
		return Promise.resolve(1);
	}

	public typeOf(expectedType: ControllerType): boolean {
		return ControllerType.Video === expectedType;
	}

	public async getNode(): Promise<HTMLVideoElement | HTMLIFrameElement> {
		return Promise.resolve(document.createElement('video'));
	}

	public async requestFullscreen(): Promise<void> {
		return Promise.reject();
	}

	public async toggleFullscreen(): Promise<void> {
		return Promise.reject();
	}

	public async observeTimeChange(): Promise<void> {
		return Promise.resolve();
	}
}
