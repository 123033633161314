import { ReactNode } from 'react';

export const symbol_flights_and_hotels: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M275-304q0-32 21.5-53.5T350-379q32 0 53.5 21.5T425-304q0 32-21.5 53.5T350-229q-32 0-53.5-21.5T275-304ZM189.825-40Q177-40 168.5-48.625T160-70v-333q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T220-403v212h260v-154q0-12.75 8.625-21.375T510-375h230q24 0 42 18t18 42v245q0 12.75-8.675 21.375Q782.649-40 769.825-40 757-40 748.5-48.625T740-70v-61H220v61q0 12.75-8.675 21.375Q202.649-40 189.825-40ZM515-654 406-545l9 61.683q1 5.317-1 9.817t-6 8l-4 3.5q-7 7-16.5 5.308Q378-458.385 373-466l-42-70-75-40q-8-3.771-9-11.786-1-8.014 5-13.671l8-7.543q1-1 14-4l69 10 113-113-194-97q-11-6-13.5-18.5T256-853l7.225-7q5.161-5 10.837-6.5 5.677-1.5 11.87.5L545-801l107-107q13-13 32-14.5t33 12.5q13 13 12 30.5T715-849L608-742l63 250q2 8.105 0 15.053Q669-470 663.444-465L659-461q-11 10-24 7.5T615-468L515-654Zm225 463v-124H540v124h200Zm-200 0v-124 124Z"
		/>
	</svg>
);
