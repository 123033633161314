import { ReactNode } from 'react';

export const symbol_iframe: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M460-420h200v-80H460v80Zm-30 60q-13 0-21.5-8.5T400-390v-140q0-13 8.5-21.5T430-560h260q13 0 21.5 8.5T720-530v140q0 13-8.5 21.5T690-360H430ZM140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm0-60h680v-436H140v436Z"
		/>
	</svg>
);
