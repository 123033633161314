import styled, { css, DefaultTheme } from 'styled-components';

import * as palette from 'pkg/config/palette';
import * as styles from 'pkg/config/styles';
import * as zIndex from 'pkg/config/zindex';
import { SubNavHeight } from 'pkg/config/sizes';

import rgba from 'pkg/rgba';
import Link from 'pkg/router/Link';

import Icon from 'components/icon';

export const PageTitle = styled.h1<{
	theme?: DefaultTheme;
	compact?: boolean;
	context?: string;
}>`
	font-weight: var(--font-weight-semibold);
	margin: 0;
	user-select: none;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-weight: var(--font-weight-semibold);
	font-size: var(--font-size-lg);
	text-align: center;
	color: ${(props) => props.theme.accentColor || palette.white};
	grid-column-start: 2;
	min-width: 0;
	max-width: 100%;

	${(props) =>
		props.context?.length > 0 &&
		css`
			&::after {
				content: '${props.context}';
				display: block;
				color: ${(props) => props.theme.accentColor || palette.gray[500]};
				font-size: var(--font-size-2xs);
				font-weight: var(--font-weight-normal);
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		`};

	${({ theme }) =>
		theme.darkMode &&
		css`
			font-size: var(--font-size-sm);
			color: var(--palette-white);

			&::after {
				margin-top: var(--spacing-2);
				color: var(--palette-gray-500);
			}
		`}
`;

export const Back = styled(Icon)`
	color: ${(props) => props.theme.accentColor || palette.white};
	transform: rotateZ(180deg);
	height: 27px;
	width: 100%;
	cursor: pointer;

	${({ theme }) =>
		theme.darkMode &&
		css`
			color: var(--palette-white);
		`}
`;

export const NotificationsTrigger = styled(Icon)`
	font-size: var(--font-size-3xl);
	pointer-events: none;
	position: relative;
`;

export const NotificationsItem = styled.div<{
	theme?: DefaultTheme;
	hasNotifications?: boolean;
}>`
	position: relative;
	z-index: 98;
	cursor: pointer;
	transition: color 100ms ease-in-out;
	margin: 0 0 0 auto;
	flex: 0 0 auto;
	display: flex;
	align-content: center;
	justify-content: center;
	color: ${(props) => props.theme.accentColor || styles.palette.white};

	${(props) =>
		props.hasNotifications &&
		css`
			&::before {
				content: '';
				width: 8px;
				height: 8px;
				background: var(--palette-yellow-500);
				display: block;
				position: absolute;
				right: 6px;
				top: 6px;
				border-radius: var(--radius-5);
				box-shadow: ${rgba(palette.black, 0.4)} 0 1px 2px;
				z-index: 99;
			}
		`};
`;

export const HeaderWrapper = styled.div<{
	noBackground?: boolean;
	theme?: DefaultTheme;
}>`
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	height: 100%;
	padding-top: 60px;
	padding-top: calc(60px + env(safe-area-inset-top));
	background: ${(props) =>
		props.theme.colorProfileGradient
			? `linear-gradient(155deg, ${props.theme.colorProfileGradient})`
			: rgba(palette.navBar.backgroundColor)};
	background-size: 100% 600px;
	color: ${(props) => props.theme.accentColor || palette.white};

	${(props) =>
		props.theme?.darkMode &&
		css`
			background: var(--palette-gray-800);
			color: var(--palette-white);
		`}

	@media print {
		display: none;
	}
`;

export const FixedSubPageHeader = styled.div`
	width: 100%;
	z-index: ${zIndex.mobileHeader};
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	position: absolute;
	left: 0;
	top: 0;
`;

export const TitleWrapper = styled.div<{
	theme?: DefaultTheme;
	dashboard?: boolean;
}>`
	height: 60px;
	height: calc(60px + env(safe-area-inset-top));
	padding-top: 0;
	padding-top: env(safe-area-inset-top);
	padding-right: ${(props) => props.dashboard && styles.spacing._5};
	padding-left: ${(props) => props.dashboard && styles.spacing._5};
	width: 100%;
	display: grid;
	grid-template-columns: ${(props) =>
		props.dashboard ? '1fr 55px' : '55px 1fr 55px'};
	align-items: center;
	justify-items: center;
	background: ${(props) =>
		props.theme.colorProfileGradient
			? `linear-gradient(155deg, ${props.theme.colorProfileGradient})`
			: rgba(palette.navBar.backgroundColor)};
	background-size: 100% 600px;

	${(props) =>
		props.theme?.darkMode &&
		css`
			background: var(--palette-gray-800);
			color: var(--palette-white);
		`}
`;

export const Top = styled.div`
	overflow: hidden;
`;

export const SubNav = styled.div`
	display: flex;
	align-items: center;
	overflow: auto;
	overflow-y: hidden;
	width: 100%;
	padding: var(--spacing-1) var(--spacing-5) var(--spacing-5) var(--spacing-5);
	position: relative;
	z-index: 2;
	height: ${SubNavHeight.STANDARD}px;

	&:empty {
		padding: 0;
		height: 0;
	}
`;

export const SubNavItem = styled(Link)`
	font-weight: var(--font-weight-semibold);
	padding: var(--spacing-3) var(--spacing-4);
	border-radius: 200px;
	margin-right: var(--spacing-2);
	font-size: var(--font-size-sm);
	background-color: transparent;
	color: ${(props) => props.theme.accentColor || palette.white};
	white-space: nowrap;

	&:last-child {
		margin-right: 0;
	}

	@media (hover: hover) {
		&:hover {
			cursor: pointer;
			background-color: ${(props) =>
				props.theme.accentColorHover || palette.gray[900]};
			color: ${(props) => props.theme.accentColor || palette.white};
		}
	}

	&[data-current-page] {
		background-color: ${(props) =>
			props.theme.accentColorFaded || palette.gray[900]};
	}
`;

export const Header = styled.div<{ theme?: DefaultTheme; compact?: boolean }>`
	${({ compact, theme }) =>
		compact &&
		css`
			display: grid;
			grid-template-columns: 30px 1fr 30px;
			column-gap: var(--spacing-4);
			align-items: center;
			justify-content: center;
			height: 60px;
			height: calc(60px + env(safe-area-inset-top));
			padding: 0 var(--spacing-4);
			padding-top: 0;
			padding-top: env(safe-area-inset-top);
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			color: ${theme.darkMode ? styles.palette.white : styles.palette.black};
		`}
`;
