import { Fragment } from 'react';
import { connect } from 'react-redux';

import { getMatchEvents } from 'pkg/selectors/match_events';
import { getMatchResults } from 'pkg/selectors/matches.selector';

import { TableBodyCell, ItemResult } from 'components/group/statistics/Table';

const Statistics = (props) => (
	<Fragment>
		<TableBodyCell numerical>
			<ItemResult outcome={props.outcome}>
				{props.matchResults[0]} - {props.matchResults[1]}
			</ItemResult>
		</TableBodyCell>
		<TableBodyCell numerical>{props.shots}</TableBodyCell>
		<TableBodyCell numerical>{props.shotsOnGoal}</TableBodyCell>
		<TableBodyCell numerical>{props.corners}</TableBodyCell>
		<TableBodyCell numerical>{props.freeKicks}</TableBodyCell>
		<TableBodyCell numerical>{props.offsides}</TableBodyCell>
		<TableBodyCell numerical>{props.fouls}</TableBodyCell>
		<TableBodyCell numerical>{props.yellowCards}</TableBodyCell>
		<TableBodyCell numerical>{props.redCards}</TableBodyCell>
	</Fragment>
);

const mapStateToProps = (state, props) => {
	const matchId = props.match.get('id');

	let outcome = 0;

	if (props.match.goalCount > props.match.opponentGoalCount) {
		outcome = 1;
	} else if (props.match.goalCount < props.match.opponentGoalCount) {
		outcome = -1;
	}

	return {
		outcome,
		matchResults: getMatchResults(state, { matchId }),
		shots:
			getMatchEvents(state, {
				matchId,
				type: 'shot_on_goal',
				isOpponent: false,
			}).size +
			getMatchEvents(state, {
				matchId,
				type: 'shot_off_goal',
				isOpponent: false,
			}).size,
		shotsOnGoal: getMatchEvents(state, {
			matchId,
			type: 'shot_on_goal',
			isOpponent: false,
		}).size,
		corners: getMatchEvents(state, {
			matchId,
			type: 'corner',
			isOpponent: false,
		}).size,
		freeKicks: getMatchEvents(state, {
			matchId,
			type: 'free_kick',
			isOpponent: false,
		}).size,
		offsides: getMatchEvents(state, {
			matchId,
			type: 'offside',
			isOpponent: false,
		}).size,
		fouls: getMatchEvents(state, {
			matchId,
			type: 'free_kick',
			isOpponent: true,
		}).size,
		yellowCards: getMatchEvents(state, {
			matchId,
			type: 'card_yellow',
			isOpponent: false,
		}).size,
		redCards: getMatchEvents(state, {
			matchId,
			type: 'card_red',
			isOpponent: false,
		}).size,
	};
};

export default connect(mapStateToProps)(Statistics);
