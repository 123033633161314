import { FormEvent, ReactNode } from 'react';

import * as css from './styles.css';

interface FormProps {
	children?: ReactNode | ReactNode[];

	onSubmit?: (event: FormEvent<HTMLFormElement>) => void;
}

export default function Form({ children, onSubmit }: FormProps): JSX.Element {
	const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (onSubmit) {
			onSubmit(event);
		}
	};

	return (
		<form onSubmit={handleSubmit} className={css.wrapper}>
			{children}
		</form>
	);
}
