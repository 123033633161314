import { useT } from '@transifex/react';
import { useEffect } from 'react';

import { AuthOptions } from 'pkg/actions/auth';

import * as endpoints from 'pkg/api/endpoints/auto';
import { useEndpoint } from 'pkg/api/use_endpoint';
import * as models from 'pkg/api/models';
import * as actions from 'pkg/actions';
import { fromEntries } from 'pkg/utils';
import { useOrganizationIdentity } from 'pkg/identity/organization';
import { flashes } from 'pkg/actions';
import { LoginTypes, storeLoginInfo } from 'pkg/local_storage/login-info';
import { useQueryParams } from 'pkg/hooks/query-state';
import { parse } from 'pkg/json';

import MaterialSymbol from 'components/material-symbols';

import AuthGuardHeader from 'components/organization/auth_guard/header';
import { Spinner } from 'components/loaders/spinner';

import Button from 'design/button';

import * as css from './styles.css';

interface OrganizationAuthGuardProps {
	organization: models.group.Group;
}

export default function OrganizationAuthGuard({
	organization,
}: OrganizationAuthGuardProps) {
	const t = useT();
	const { refreshOrganizationIdentity } = useOrganizationIdentity();
	const orgHasSlug = !!organization?.slug;
	const query = useQueryParams();
	const parsedState = parse(query?.state as string);

	const { record: authOptions, isLoading } = useEndpoint<AuthOptions>(
		endpoints.Auth.Options(organization?.slug),
		{
			queryParams: new URLSearchParams({
				slug: organization?.slug?.toString(),
			}),
			delayFetch: !orgHasSlug,
		}
	);

	const search: { [key: string]: string } = fromEntries(
		new URLSearchParams(window.location.search)
	);

	useEffect(() => {
		const run = async () => {
			if (search?.code) {
				const [req, resp] = await actions.sso.login({ code: search.code });

				if (!req.ok) {
					flashes.show({
						title: t('Unable to authenticate'),
						message: t(
							'There was a problem when trying to authenticate you towards the identity provider, please try again or contact your IT admin to resolve the issue'
						),
					});
					return;
				}

				await actions.auth.updateAuthToken(
					{ accountToken: resp.accountToken, orgToken: resp.orgToken },
					organization.id
				);

				storeLoginInfo(
					resp.account,
					LoginTypes.SSO,
					parsedState?.identityProvider as unknown as models.identityProvider.ScrubbedIdentityProvider,
					organization.id
				);

				refreshOrganizationIdentity();
				window.history.pushState({}, '', '/');
			}
		};

		run();
	}, [search?.code]);

	const handleIdpClick = async (
		identityProvider: models.identityProvider.IdentityProvider
	) => {
		actions.sso.startLogin({ identityProvider });
	};

	if (isLoading) {
		return <Spinner center />;
	}

	return (
		<div className={css.wrapper}>
			<AuthGuardHeader />
			<div className={css.content}>
				<div className={css.card}>
					<span className={css.login}>
						<MaterialSymbol variant="key" />
						{t('Login with Single sign-on')}
					</span>
					<div>
						{t(
							'Your organization requires you to login using SSO in order to gain access to 360player.'
						)}
					</div>
					<div className={css.buttonWrapper}>
						{authOptions.identityProviders?.map((idp) => (
							<Button
								key={idp.id}
								label={t('Continue with {displayName}', {
									displayName: idp.displayName,
								})}
								primary
								large
								onClick={() => handleIdpClick(idp)}
							/>
						))}
					</div>
					<a
						className={css.link}
						href="https://help.360player.com/article/213-login-using-sso"
						target="_blank">
						{t('Need help?')}
					</a>
				</div>
			</div>
		</div>
	);
}
