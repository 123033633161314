import { ReactNode } from 'react';

export const symbol_nest_heat_link_e: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm-.221-60Q621-140 720.5-239.279q99.5-99.28 99.5-240.5Q820-621 720.721-720.5t-240.5-99.5Q339-820 239.5-720.721t-99.5 240.5Q140-339 239.279-239.5q99.28 99.5 240.5 99.5Zm.044-200Q422-340 381-381.177q-41-41.177-41-99T381.177-579q41.177-41 99-41T579-578.823q41 41.177 41 99T578.823-381q-41.177 41-99 41Z"
		/>
	</svg>
);
