import { ReactNode } from 'react';

export const symbol_exposure_zero: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-200q-100 0-160-79.5T260-480q0-121 60-200.5T480-760q100 0 160 79.5T700-480q0 121-60 200.5T480-200Zm0-62q76 0 114-65.5T632-480q0-87-38-152.5T480-698q-76 0-114 65.5T328-480q0 87 38 152.5T480-262Z"
		/>
	</svg>
);
