import { ReactNode } from 'react';

export const symbol_endocrinology: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M390-260q25 0 42.5-17.5T450-320q0-50-32.5-97T330-497v177q0 25 17.5 42.5T390-260Zm180 0q25 0 42.5-17.5T630-320v-177q-55 33-87.5 80T510-320q0 25 17.5 42.5T570-260Zm-410-66v-447q-15-17-28-36.5T108-849q-5-10 0-20.5t16-15.5q11-5 23-.5t18 15.5q48 87 131.5 136.5T480-684q100 0 182.5-48.5T793-866q7-12 19-18t24-1q11 5 15.5 17t-1.5 23q-11 20-23.5 37.5T800-773v447q0 38 18 70t49 52q10 7 13.5 18.5T880-163q-4 11-14 16t-20 0q-48-27-77-74.5T740-326v-389q-55 44-121.5 67.5T480-624q-72 0-138.5-23.5T220-715v389q0 57-29.5 104.5T113-147q-10 5-20 0t-14-16q-4-11 0-22.5T93-204q31-20 49-52t18-70Zm230 126q-50 0-85-35t-35-85v-222q0-11 8.5-18t19.5-4l26 6q51 13 91.5 44.5T480-437q24-45 64.5-76.5T636-558l26-6q11-3 19.5 4t8.5 18v222q0 50-35 85t-85 35q-27 0-50-11t-40-30q-17 19-40 30t-50 11Zm180-120Zm-180 0Z"
		/>
	</svg>
);
