import { ReactNode } from 'react';

export const symbol_scanner: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M751-460 175-689q-12.267-4.667-17.633-16.333Q152-717 157-729.5q5-12.5 16.5-18t24.5-.5l585 232q32 13 44.5 34t12.5 57v205q0 24-18 42t-42 18H180q-24 0-42-18t-18-42v-180q0-24 18-42t42-18h571Zm29 240v-180H180v180h600Zm-370-60h280q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T690-340H410q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T410-280Zm-139.825 0q12.825 0 21.325-8.675 8.5-8.676 8.5-21.5 0-12.825-8.675-21.325-8.676-8.5-21.5-8.5-12.825 0-21.325 8.675-8.5 8.676-8.5 21.5 0 12.825 8.675 21.325 8.676 8.5 21.5 8.5ZM180-220v-180 180Z"
		/>
	</svg>
);
