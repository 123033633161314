import { ReactNode } from 'react';

export const symbol_language_chinese_array: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M173-474q-9 9-18 17.5T137-440q-7 6-15.5 5.5T108-442q-6-7-4.5-16t9.5-15q29-26 54.5-54t48.5-58q5-7 13.5-9.5t16.5.5q7 2 10 9.5t-2 13.5q-10 14-20.5 27.5T211-516v237q0 8-5.5 13.5T192-260q-8 0-13.5-5.5T173-279v-195Zm217 214-19-1q-8-1-15.5-6t-9.5-13q-2-8 1.5-13t11.5-5h51q6 0 10.5-4.5T425-313v-198H284q-8 0-12.5-5t-4.5-13q0-8 4.5-12.5T284-546h233q8 0 13 5t5 13q0 8-5 13t-13 5h-54v203q0 10-2 20t-10 16q-14 8-29.5 9.5T390-260ZM232-676q-20 26-44 48t-50 42q-7 5-15 5t-13-7q-5-7-3-15t8-13q22-18 43-36.5t39-39.5q6-7 13.5-9t15.5 1q7 3 9 10.5t-3 13.5Zm272 38H304q-8 0-12.5-5t-4.5-13q0-8 4.5-12.5T304-673h201q8 0 13 5t4 13q-1 7-6 12t-12 5Zm200 318q32 0 63-15t57-43v-121q-29 4-51 8.5T731-480q-50 16-75 39.5T631-385q0 29 20 47t53 18Zm-14 51q-54 0-86.5-31T571-383q0-50 34-83.5T710-520q22-6 50.5-11.5T824-542q0-56-18.5-82.5T739-651q-22 0-44 6.5T644-622q-10 5-20 2.5T609-631q-5-10-2.5-20t12.5-15q31-17 64.5-27t67.5-10q69 0 101 42.5T884-540v237q0 11-7 18t-18 7h-3q-10 0-16.5-6.5T831-300l-3-27q-30 28-66.5 43T690-269Z"
		/>
	</svg>
);
