import { ReactNode } from 'react';

export const symbol_coffee_maker: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M220-80q-23 0-41.5-18T160-140v-680q0-23 18.5-41.5T220-880h550q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T770-820h-77v60q0 14-12.5 27T654-720H368q-14 0-26.5-13T329-760v-60H220v680h188q-38-26-58.5-63T329-293v-133q0-24.75 17.625-42.375T389-486h244q24.75 0 42.375 17.625T693-426v133q0 53-21 90t-59 63h157q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q800-97 791.375-88.5T770-80H220Zm290.5-86q51.5 0 87-37.042Q633-240.083 633-293v-133H389v133q0 52.917 35 89.958Q459-166 510.5-166Zm-.5-394q16.575 0 27.787-11.213Q549-582.425 549-599t-11.213-27.787Q526.575-638 510-638t-27.788 11.213Q471-615.575 471-599t11.212 27.787Q493.425-560 510-560Zm1 134Z"
		/>
	</svg>
);
