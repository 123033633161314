import { ReactNode } from 'react';

export const symbol_flight_class: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M550-440q-28.875 0-49.438-20.562Q480-481.125 480-510v-220q0-28.875 20.562-49.438Q521.125-800 550-800h100q28.875 0 49.438 20.562Q720-758.875 720-730v220q0 28.875-20.562 49.438Q678.875-440 650-440H550Zm-10-60h120v-240H540v240ZM372-240q-23 0-42-13.5T305-290L202.059-634.807Q200-640 200-643.5V-770q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T260-770v118l105 352h325q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T690-240H372Zm-22 120q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T350-180h340q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T690-120H350Zm190-620h120-120Z"
		/>
	</svg>
);
