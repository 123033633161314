import { ReactNode } from 'react';

import * as styles from 'pkg/config/styles';

import ColoredDot from 'components/ColoredDot';

interface Props {
	name: string;
	children: ReactNode;
	className?: string;
}

const Status: React.FC<React.PropsWithChildren<Props>> = ({
	name,
	children,
	className,
}) => {
	const colors: { [key: string]: string } = {
		paid: styles.palette.green[400],
		active: styles.palette.green[400],
		open: styles.palette.blue[400],
		uncollectible: styles.palette.yellow[400],
		expired: styles.palette.orange[400],
		past_due: styles.palette.red[400],
	};

	return (
		<ColoredDot className={className} color={colors[name]}>
			{children}
		</ColoredDot>
	);
};

export default Status;
