import { Fragment, useState } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import { useCurrentAccountWards, useCurrentGroup } from 'pkg/identity';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { useWardsInCurrentGroup } from 'pkg/hooks/useWardsInCurrentGroup';

import { Ward } from 'routes/dashboard/organization/children/Ward';

import SectionTitle from 'components/SectionTitle';

import Column from 'components/layout/column';
import Row from 'components/layout/row';
import JoinGroupModal from 'components/group/join-modal';
import InviteParentModal from 'components/account/invite-parent-modal';

import * as ContextMenu from 'design/context_menu';
import * as Card from 'design/card';
import Button from 'design/button';
import MissingEntities from 'design/placeholders/block';

export default function Children(): JSX.Element {
	const wardsInClub = useWardsInCurrentGroup();
	const currentGroup = useCurrentGroup();

	const [modalOpen, setModalOpen] = useState<'add-child' | 'invite-parent'>(
		null
	);

	const [selectedWardAccounts, setSelectedWardAccounts] = useState<
		models.account.Account[]
	>([]);

	const openChildModal = () => setModalOpen('add-child');
	const openParentModal = () => setModalOpen('invite-parent');

	const closeModal = () => setModalOpen(null);

	const hasWardsInClub = wardsInClub.length > 0;

	const wards = useCurrentAccountWards();
	const wardAccountRelationsInClub =
		models.accountRelation.findAllApproved(wards);

	const hasWards = wards.length > 0;

	useComponentDidMount(() => {
		if (wardAccountRelationsInClub) {
			const wardAccounts = wardAccountRelationsInClub.map(
				(accountRelation: models.accountRelation.AccountRelation) =>
					accountRelation.targetAccount
			);

			setSelectedWardAccounts(wardAccounts);
		}
	});

	if (!hasWards) {
		return null;
	}

	const hasMultipleWards = wards.length > 1;

	const toggleTargetAccount = (targetAccount: models.account.Account) => {
		const isSelected = !!selectedWardAccounts.filter(
			(a: models.account.Account) => a.id === targetAccount.id
		).length;

		if (isSelected) {
			// Deselect account
			const newSelectedWardAccounts = selectedWardAccounts.filter(
				(a: models.account.Account) => a.id !== targetAccount.id
			);

			setSelectedWardAccounts(newSelectedWardAccounts);
		} else {
			// Select account
			setSelectedWardAccounts([...selectedWardAccounts, targetAccount]);
		}
	};

	return (
		<Column>
			<SectionTitle>
				{t('Children in this club')}
				<Button
					inline
					noPadding
					icon="add"
					label={t('Add a child')}
					onClick={openChildModal}
				/>
			</SectionTitle>
			{hasWardsInClub && (
				<Card.Base $noBorder>
					{wardsInClub.map((user: models.user.User, index: number) => (
						<Fragment key={index}>
							{index !== 0 && <Card.Divider />}
							<Card.Body $narrowBody>
								<Ward user={user} />
							</Card.Body>
						</Fragment>
					))}
					<Card.Divider />
					<Card.Body $narrowBody>
						<Row
							columns="1fr"
							align="center"
							justifyContent="start"
							spacing={styles.spacing._1}>
							<div>
								{hasMultipleWards ? (
									<ContextMenu.Menu
										toggleWith={
											<Button
												block
												secondary
												icon="supervisor_account"
												label={t('Invite another parent')}
											/>
										}>
										<ContextMenu.Heading>
											{t('Select children')}
										</ContextMenu.Heading>
										{wardAccountRelationsInClub.map(({ targetAccount }) => {
											const isSelected = !!selectedWardAccounts.filter(
												(a: models.account.Account) => a.id === targetAccount.id
											).length;

											return (
												<ContextMenu.ControlItem
													type="checkbox"
													key={targetAccount.id}
													checked={isSelected}
													onClick={() => toggleTargetAccount(targetAccount)}>
													{models.account.fullName(targetAccount)}
												</ContextMenu.ControlItem>
											);
										})}
										<ContextMenu.Divider />
										<ContextMenu.Item noLink columns="1fr">
											<Button
												primary
												block
												label={t('Invite parent')}
												disabled={selectedWardAccounts.length === 0}
												onClick={openParentModal}
											/>
										</ContextMenu.Item>
									</ContextMenu.Menu>
								) : (
									<Button
										block
										secondary
										icon="supervisor_account"
										label={t('Invite another parent')}
										onClick={openParentModal}
									/>
								)}
							</div>
						</Row>
					</Card.Body>
				</Card.Base>
			)}

			{hasWards && !hasWardsInClub && (
				<MissingEntities
					noBorder
					title={t('No children in this club')}
					description={t('Get started by adding your child to this club.')}
					icon="user-add"
					actions={
						<Button
							secondary
							icon="add"
							label={t('Add child to club')}
							onClick={openChildModal}
						/>
					}
				/>
			)}

			{modalOpen === 'add-child' && (
				<JoinGroupModal
					allowAccountCreate
					filterWardsInCurrentGroup
					autoJoinWithGroupCode
					showCurrentAccount={false}
					group={currentGroup}
					onClose={closeModal}
				/>
			)}

			{modalOpen === 'invite-parent' && (
				<InviteParentModal
					targetAccounts={selectedWardAccounts}
					onClose={closeModal}
				/>
			)}
		</Column>
	);
}
