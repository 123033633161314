import { ReactNode } from 'react';

export const symbol_service_toolbox: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M303-729v-51q0-24.75 17.625-42.375T363-840h232q24.75 0 42.375 17.625T655-780v51h64q17.524 0 32.762 8.5T774-695l98 232q5 10 6.5 20.667Q880-431.667 880-421v201q0 24.75-17.625 42.375T820-160H140q-24.75 0-42.375-17.625T80-220v-201q0-10.667 1.5-21.333Q83-453 88-463l96-232q7-17 22.238-25.5T239-729h64Zm60 0h232v-51H363v51Zm-79 260v-17q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T344-486v17h272v-17q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T676-486v17h127l-83-200H240l-83 200h127Zm0 60H140v189h680v-189H676v17q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T616-392v-17H344v17q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T284-392v-17Zm196-30Zm0-30Zm0 60Z"
		/>
	</svg>
);
