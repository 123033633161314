import { t } from '@transifex/native';
import { createContext, useContext } from 'react';
import { useDispatch } from 'react-redux';

import * as api from 'pkg/api';
import * as actions from 'pkg/actions';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import useMixedState from 'pkg/hooks/useMixedState';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import {
	useCurrentAccount,
	useCurrentAccountWards,
	useCurrentOrganization,
	useFetchIdentity,
} from 'pkg/identity';
import { MembershipRole } from 'pkg/api/models/membership';
import { useCurrentRoute } from 'pkg/router/hooks';
import * as routes from 'pkg/router/routes';
import { replaceState } from 'pkg/router/state';
import { useWardsInCurrentGroup } from 'pkg/hooks/useWardsInCurrentGroup';
import { useAccountIdentity } from 'pkg/identity/account';
import { useOrganizationIdentity } from 'pkg/identity/organization';
import { CreateChildPayload, CreatePayload } from 'pkg/api/endpoints/accounts';

import * as StepModal from 'components/step-modal';

import GroupCode from 'components/group/join-modal/GroupCode';
import Connected from 'components/group/join-modal/Connected';
import AccountSelect from 'components/group/join-modal/AccountSelect';
import CreateAccountDetails from 'components/group/join-modal/create/Details';
import ConfirmManagedAccount from 'components/group/join-modal/create/ConfirmManagedAccount';
import Credentials from 'components/group/join-modal/create/Credentials';
import RoleSelect from 'components/group/join-modal/create/RoleSelect';

interface CreateMembershipResult {
	membership?: models.membership.Membership;
	statusCode: number;
}

type CreateMembershipCallback = (
	groupCode: string,
	role: models.membership.MembershipRole,
	targetAccountId?: number
) => Promise<CreateMembershipResult>;

export function useCreateMembership(): CreateMembershipCallback {
	const fetchIdentity = useFetchIdentity();

	return async (
		groupCode: string,
		role: models.membership.MembershipRole,
		targetAccountId?: number
	) => {
		let request: Response;
		let membership: models.membership.Membership;

		if (targetAccountId) {
			[request, membership] = await api
				.forAccount<models.membership.Membership>(targetAccountId)
				.post(endpoints.Groups.JoinFromInviteCode(), {
					code: groupCode,
					role,
				});
		} else {
			[request, membership] = await api.post<models.membership.Membership>(
				endpoints.Groups.JoinFromInviteCode(),
				{
					code: groupCode,
					role,
				}
			);
		}

		await fetchIdentity();

		return {
			membership,
			statusCode: request.status,
		};
	};
}

interface CreateChildMembership {
	membership?: models.membership.Membership;
	account?: models.account.Account;
	statusCode: number;
}

type CreateChildAccountCallback = (
	payload: CreateChildPayload
) => Promise<CreateChildMembership>;

export function useCreateChildAccount(
	parentAccount: models.account.Account
): CreateChildAccountCallback {
	return async (payload: CreateChildPayload) => {
		let statusCode: number = 200;

		const birthDate = [payload.year, payload.month, payload.day]
			.map((item: number) => item.toString())
			.map((item: string) => item.padStart(2, '0'))
			.join('-');

		const accountPayload: CreatePayload = {
			firstName: payload.firstName,
			lastName: payload.lastName,
			birthDate,
			countryId: parentAccount.countryId,
			languageCode: parentAccount.languageCode,
		};

		if (payload.email !== '' && payload.password !== '') {
			accountPayload.email = payload.email;
			accountPayload.password = payload.password;
		}

		try {
			const [accountRequest, account] = await api.post<
				models.account.Account,
				CreatePayload
			>(
				endpoints.Accounts.CreateChildAccountForAccount(parentAccount.id),
				accountPayload
			);

			if (!accountRequest.ok) {
				throw new Error(`${accountRequest.status}`);
			}

			statusCode = accountRequest.status;

			const [joinRequest, membership] = await api
				.forAccount<models.membership.Membership>(account.id)
				.post(endpoints.Groups.JoinFromInviteCode(), {
					code: payload.groupCode,
					role: models.membership.MembershipRole.User,
				});

			if (!joinRequest.ok) {
				throw new Error(`${joinRequest.status}`);
			}

			statusCode = joinRequest.status;

			return {
				account,
				membership,
				statusCode,
			};
		} catch (error: unknown) {
			const status = Number.parseInt((error as Error).message, 10);

			return {
				account: null,
				membership: null,
				statusCode: status,
			};
		}
	};
}

export function flashErrorMessage(statusCode: number) {
	let title: string = t('Something went wrong');

	let message: string = t(
		`If you think this error isn't supposed to happen, please contact 360Player support.`
	);

	switch (statusCode) {
		case 400:
			title = t('Could not create account');
			break;
		case 404:
			title = t('Not a valid group code');
			message = t('Did you enter the correct group code?');
			break;
		case 409:
			title = t('Email already in use');
			message = t('This email is already registered in 360Player.');
			break;
		case 429:
			title = t('Too many attempts');
			message = t(
				'You have attempted to join this group too many times, try again in a moment.'
			);
			break;
	}

	actions.flashes.show(
		{
			title,
			message,
		},
		statusCode
	);
}

type JoinModalStateSetter = (
	key: keyof JoinModalState,
	value: JoinModalState[keyof JoinModalState]
) => void;

interface JoinModalState {
	groupCode: string;
	groupRole: models.membership.MembershipRole;

	group: models.group.Group;
	membership: models.membership.Membership;
	targetAccount: models.account.Account;
	connectionInfo: models.onboardingInfo.Group;

	autoJoinWithGroupCode: boolean;
	isCreatingAccount: boolean;
	createPayload: Partial<CreateChildPayload>;

	setCreatePayload: (partial: Partial<CreateChildPayload>) => void;
	getCreatePayload: () => CreateChildPayload;

	set: JoinModalStateSetter;
	setAll: (partial: Partial<JoinModalState>) => void;
}

const DefaultJoinModalState: JoinModalState = {
	groupCode: '',
	groupRole: MembershipRole.User,

	group: null,
	membership: null,
	targetAccount: null,
	connectionInfo: null,

	autoJoinWithGroupCode: false,
	isCreatingAccount: false,
	createPayload: {},

	setCreatePayload: () => null,
	getCreatePayload: () => null,

	set: () => null,
	setAll: () => null,
};

const JoinModalContext = createContext<JoinModalState>(DefaultJoinModalState);

export function useJoinModalContext(): JoinModalState {
	return useContext(JoinModalContext);
}

export function useAddableAccounts(): models.accountRelation.AccountRelation[] {
	const wards = useCurrentAccountWards();

	const wardIdsInCurrentGroup = useWardsInCurrentGroup()?.map(
		(user: models.user.User) => user.accountId
	);

	const addableWards = wards?.filter(
		(rel: models.accountRelation.AccountRelation) =>
			!wardIdsInCurrentGroup.includes(rel.targetAccountId)
	);

	return addableWards;
}

export function useAlreadyMemberOf(groupCode?: string): boolean {
	const { users } = useOrganizationIdentity();
	const wards = useCurrentAccountWards();

	if (!groupCode) return false;

	const accountMemberships: string[] = [];
	const wardMemberships: string[] = [];

	// Current user's memberships
	if (users?.length > 0) {
		users.forEach((user: models.user.User) => {
			if (user.groups?.length > 0) {
				user.groups.forEach((membership: models.membership.Membership) => {
					if (
						membership.group &&
						!accountMemberships.includes(membership.group?.inviteCode)
					) {
						accountMemberships.push(membership.group.inviteCode);
					}
				});
			}
		});
	}

	// Current users' ward's memberships
	if (wards?.length > 0) {
		wards.forEach((rel: models.accountRelation.AccountRelation) => {
			if (rel.targetAccount?.users?.length > 0) {
				rel.targetAccount.users.forEach((user: models.user.User) => {
					if (user.groups?.length > 0) {
						user.groups.forEach((membership: models.membership.Membership) => {
							if (membership.group) {
								wardMemberships.push(membership.group.inviteCode);
							}
						});
					}
				});
			}
		});
	}

	if (accountMemberships.includes(groupCode)) {
		return true;
	}

	return false;
}

interface JoinModalProps {
	groupCode?: string;
	group?: models.group.Group;
	targetAccount?: models.account.Account;

	autoJoinWithGroupCode?: boolean;
	filterWardsInCurrentGroup?: boolean;
	showCurrentAccount?: boolean;
	allowAccountCreate?: boolean;
	onlyAccountCreate?: boolean;
	ignoreRedirect?: boolean;

	onClose: () => void;
}

export default function JoinModal({
	groupCode,
	group,
	targetAccount,

	autoJoinWithGroupCode = false,
	filterWardsInCurrentGroup = false,
	showCurrentAccount = true,
	allowAccountCreate = false,
	onlyAccountCreate = false,
	ignoreRedirect = false,

	onClose,
}: JoinModalProps): JSX.Element {
	const dispatch = useDispatch();
	const route = useCurrentRoute();
	const currentAccount = useCurrentAccount();
	const wards = useCurrentAccountWards();
	const addableAccounts = useAddableAccounts();
	const { refreshAccountIdentity } = useAccountIdentity();
	const org = useCurrentOrganization();

	const addable = filterWardsInCurrentGroup ? addableAccounts : wards;

	const [state, setState] = useMixedState<JoinModalState>(
		DefaultJoinModalState
	);

	useComponentDidMount(() => {
		if (!groupCode && group) {
			groupCode = group.inviteCode;
		}

		setState({
			groupCode,
			targetAccount,
			group,
			autoJoinWithGroupCode,
			isCreatingAccount: onlyAccountCreate,
		});
	});

	const handleClose = () => {
		if (route?.path.includes('join/')) {
			replaceState(routes.Organization.Home(org.id));
		}

		onClose();
	};

	const set = (
		key: keyof JoinModalState,
		value: JoinModalState[keyof JoinModalState]
	) => {
		setState({ [key]: value });
	};

	const setAll = (partial: Partial<JoinModalState>) => {
		setState(partial);
	};

	const setCreatePayload = (partial: Partial<CreateChildPayload>) => {
		set('createPayload', { ...state.createPayload, ...partial });
	};

	const getCreatePayload = (): CreateChildPayload => {
		return {
			...providedState.createPayload,
			groupCode: providedState.groupCode,
		} as CreateChildPayload;
	};

	const providedState: JoinModalState = {
		...state,

		setCreatePayload,
		getCreatePayload,
		set,
		setAll,
	};

	const handleDidConnect = async () => {
		if (!ignoreRedirect) {
			if (providedState.group) {
				if (providedState.connectionInfo?.organizationId) {
					replaceState(
						routes.Organization.Home(
							providedState.connectionInfo?.organizationId
						)
					);
				} else {
					replaceState(routes.Home());
				}

				dispatch(actions.users.setActiveGroup(providedState.group));
			}
		}

		refreshAccountIdentity();

		return true;
	};

	let defaultTitle = t('Join a new group');

	const createTitle = t('Create account for your child');

	const groupName = providedState.connectionInfo?.groupName || t('this group');

	if (providedState.group) {
		defaultTitle = t('Join {group}', { group: group.name });
	}

	const isSelf =
		!providedState.targetAccount ||
		currentAccount.id === providedState.targetAccount?.id;

	const showGroupCode = !providedState.group && !autoJoinWithGroupCode;

	let showAccountSelect =
		!onlyAccountCreate &&
		!targetAccount &&
		(addable?.length > 0 || allowAccountCreate);

	// Force account select when this is a join-route
	if (route?.groupCode) {
		showAccountSelect = true;
	}

	const showAccountCreate = providedState.isCreatingAccount;

	let showRoleSelect =
		isSelf &&
		!providedState.isCreatingAccount &&
		!autoJoinWithGroupCode &&
		!providedState.connectionInfo?.isOrganization;

	// Enforce role select when joining as self, to a non-organization if connection state is set
	if (isSelf && providedState.connectionInfo) {
		if (providedState.connectionInfo.isOrganization === false) {
			showRoleSelect = true;
		}
	}

	// Make sure role select isnt showing when creating child accounts
	if (onlyAccountCreate && showRoleSelect) {
		showRoleSelect = false;
	}

	if (!onlyAccountCreate) {
		showRoleSelect = true;
	}

	const accountStepCanGoNext =
		providedState.isCreatingAccount || !!providedState.targetAccount;

	let accountStepNextSlug = providedState.isCreatingAccount
		? 'create-account'
		: '';

	if (showGroupCode) {
		accountStepNextSlug = 'group-code';
	}

	const accountStepPrevSlug = showAccountSelect
		? 'account-select'
		: 'group-code';

	const closeOnPrev: boolean = route?.path.includes('registration/');

	return (
		<JoinModalContext.Provider value={providedState}>
			<StepModal.Base onClose={handleClose}>
				{showAccountSelect && (
					<StepModal.Step
						title={defaultTitle}
						slug="account-select"
						prevLabel={t('Close')}
						nextLabel={t('Continue')}
						canGoNext={accountStepCanGoNext}
						nextSlug={accountStepNextSlug}>
						<AccountSelect
							showCurrentAccount={showCurrentAccount}
							allowAccountCreate={allowAccountCreate}
							filterWardsInCurrentGroup={filterWardsInCurrentGroup}
						/>
					</StepModal.Step>
				)}

				{showGroupCode && (
					<StepModal.Step slug="group-code" title={t('Group code')}>
						<GroupCode />
					</StepModal.Step>
				)}

				{showRoleSelect && (
					<StepModal.Step
						slug="group-role"
						title={t(`What's your role in {group}?`, { group: groupName })}
						canGoNext={!!providedState.groupRole}>
						<RoleSelect />
					</StepModal.Step>
				)}

				{showAccountCreate && [
					<StepModal.Step
						slug="create-account"
						prevSlug={accountStepPrevSlug}
						title={createTitle}
						closeOnPrev={closeOnPrev}>
						<CreateAccountDetails />
					</StepModal.Step>,
					<StepModal.Step title={createTitle} hidePrev hideNext>
						<ConfirmManagedAccount />
					</StepModal.Step>,
					<StepModal.Step title={createTitle} nextSlug="connected">
						<Credentials />
					</StepModal.Step>,
				]}

				<StepModal.Step
					spaciousBody
					hidePrev
					closeOnNext
					canGoNext
					onNext={handleDidConnect}
					slug="connected"
					title={defaultTitle}
					nextLabel={t('Finish')}>
					<Connected />
				</StepModal.Step>
			</StepModal.Base>
		</JoinModalContext.Provider>
	);
}
