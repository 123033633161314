import { t } from '@transifex/native';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { BarChart, Bar, ResponsiveContainer, LabelList, YAxis } from 'recharts';

import * as palette from 'pkg/config/palette';

import { getGoalTimelineItems } from 'pkg/selectors/match_events';

import rgba from 'pkg/rgba';

import CardBase, * as Card from 'components/Card';
import SectionTitle from 'components/SectionTitle';

import Column from 'components/layout/column';

const Body = styled(Card.Body)`
	padding: 0 !important;
`;

const GoalsContainer = styled(ResponsiveContainer)`
	.recharts-wrapper::before {
		content: '';
		width: 2px;
		background: ${rgba(palette.gray[300], 0.8)};
		position: absolute;
		top: 30px;
		bottom: 10px;
		left: 50%;
		border-radius: var(--radius-3);
		transform: translateX(-50%);
	}
`;

const GoalsConcededContainer = styled(ResponsiveContainer)`
	.recharts-wrapper::before {
		content: '';
		width: 2px;
		background: ${rgba(palette.gray[300], 0.8)};
		position: absolute;
		top: 15px;
		bottom: 30px;
		left: 50%;
		border-radius: var(--radius-3);
		transform: translateX(-50%);
	}
`;

const Label = styled.text`
	font-weight: var(--font-weight-semibold);
	font-size: 0.75rem;
	color: ${palette.gray[800]};

	.bar-percentage {
		font-size: 0.7em;
		fill: ${palette.gray[500]};
		margin-left: 5px;
	}
`;

const CustomXAxis = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 0 1rem;
`;

const CustomXAxisText = styled.div`
	font-size: 0.75rem;
	width: 30%;
	text-align: center;
	font-weight: var(--font-weight-normal);

	&:first-child {
		text-align: left;
	}

	&:last-child {
		text-align: right;
	}
`;

const barLabel = (max, offset) => (props) => {
	const { x, y, width, value } = props;

	if (!value) {
		return '';
	}

	return (
		<g>
			<Label
				x={x + width / 2}
				y={y - offset}
				textAnchor="middle"
				dominantBaseline="middle"
				className="bar-label">
				<tspan>{value}</tspan>
				<tspan dx="2" className="bar-percentage">
					({Math.round((value / max) * 100)}%)
				</tspan>
			</Label>
		</g>
	);
};

const GoalTimeline = ({ eventItems, heading }) => {
	if (!eventItems.length) {
		return null;
	}

	const data = eventItems.map((item) => {
		return {
			[t(`Goals`)]: item.goals,
			[t(`Goals conceded`)]: item.goalsConceded,
		};
	});

	const totalGoals = eventItems.reduce(
		(total, current) =>
			Number.parseInt(total, 10) + Number.parseInt(current.goals, 10),
		0
	);

	const totalConcededGoals = eventItems.reduce(
		(total, current) =>
			Number.parseInt(total, 10) + Number.parseInt(current.goalsConceded, 10),
		0
	);

	const flatItems = [];
	eventItems.forEach((c) => {
		flatItems.push(c.goals, c.goalsConceded);
	});
	const max = Math.max(...flatItems);

	return (
		<Column>
			<SectionTitle icon="timer">{heading || t(`Goal timeline`)}</SectionTitle>
			<CardBase $noBorder>
				<Body>
					<GoalsContainer height={150} width="100%">
						<BarChart
							data={data}
							margin={{
								top: 30,
								right: 10,
								left: 10,
								bottom: 10,
							}}
							barGap={0}
							barCategoryGap={5}>
							<Bar
								isAnimationActive={false}
								dataKey={t(`Goals`)}
								type="monotone"
								fill={palette.green[400]}
								background={{ fill: palette.gray[200] }}
								radius={[6, 6, 0, 0]}>
								<LabelList position="top" content={barLabel(totalGoals, 7)} />
							</Bar>
							<YAxis mirror tick={false} axisLine={false} domain={[0, max]} />
						</BarChart>
					</GoalsContainer>

					<CustomXAxis>
						<CustomXAxisText>{t(`Start`)}</CustomXAxisText>
						<CustomXAxisText>{t(`Half-time`)}</CustomXAxisText>
						<CustomXAxisText>{t(`End`)}</CustomXAxisText>
					</CustomXAxis>

					<GoalsConcededContainer height={150} width="100%">
						<BarChart
							data={data}
							margin={{
								top: 15,
								right: 10,
								left: 10,
								bottom: 30,
							}}
							barGap={0}
							barCategoryGap={5}>
							<Bar
								isAnimationActive={false}
								dataKey={t(`Goals conceded`)}
								type="monotone"
								fill={palette.red[400]}
								background={{ fill: palette.gray[200] }}
								radius={[6, 6, 0, 0]}>
								<LabelList
									position="top"
									content={barLabel(totalConcededGoals, -12)}
								/>
							</Bar>
							<YAxis
								reversed
								mirror
								tick={false}
								axisLine={false}
								domain={[0, max]}
							/>
						</BarChart>
					</GoalsConcededContainer>
				</Body>
			</CardBase>
		</Column>
	);
};

const mapStateToProps = (state, props) => {
	const statProps = {
		...props.filters.toJS(),
		groupId: props.groupId,
		parts: props.parts || 7,
	};

	return {
		eventItems: getGoalTimelineItems(state, statProps),
	};
};

export default connect(mapStateToProps)(GoalTimeline);
