import { ReactNode, useState } from 'react';
import { useT } from '@transifex/react';

import * as styles from 'pkg/config/styles';

import { useCollection } from 'pkg/api/use_collection';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as ls from 'pkg/local_storage';
import { useCurrentOrganization } from 'pkg/identity';

import * as css from 'routes/user/registrations/categories/styles.css';
import FormCategoryWithItems, {
	FormItems,
} from 'routes/user/registrations/open/category-wrapper';

import SectionTitle from 'components/SectionTitle';
import BadgeWrapper from 'components/badge-wrapper';
import Badge from 'components/Badge';
import { ListLayout, useViewToggle } from 'components/view-toggle';

import Column from 'components/layout/column';
import SearchInput from 'components/form/SearchInput';
import { Spinner } from 'components/loaders/spinner';
import Row from 'components/layout/row';

import MissingEntities from 'design/placeholders/block';
import Divider from 'design/divider';

const filterSearch = (records: models.form.Form[], search: string) => {
	const searchBy = search.toLowerCase();

	return records
		.filter((form: models.form.Form) => {
			let searchable = `${form.title.toLowerCase()} ${form.description.toLowerCase()}`;

			if (form.submissions) {
				form.submissions
					.filter((submission) => !!submission.submittedForUser)
					.map((submission: models.formSubmission.FormSubmission) => {
						searchable += submission.submittedForUser.firstName.toLowerCase();
						searchable += submission.submittedForUser.lastName.toLowerCase();
					});
			}

			if (searchable.includes(searchBy)) {
				return true;
			}
		})
		.sort((a: models.form.Form, b: models.form.Form) =>
			a.title.localeCompare(b.title)
		);
};

export default function OpenRegistrations({}) {
	const t = useT();
	const viewToggle = useViewToggle({
		localStorageKey: ls.LocalStorageKeys.FormCategoriesView,
	});

	const [search, setSearch] = useState('');
	const org = useCurrentOrganization();

	const { records, isLoading } = useCollection<models.form.Form>(
		endpoints.Organizations.ListForms(org.id)
	);

	const handleChange = (value: string) => {
		setSearch(value);
	};

	let searchRegistrations: models.form.Form[] = [];

	if (search) {
		searchRegistrations = filterSearch(records, search);
	}

	const recordsToMap = search ? searchRegistrations : records;

	let content: ReactNode = (
		<FormCategoryWithItems
			forms={recordsToMap}
			group={org}
			view={viewToggle.view}
		/>
	);

	if (search) {
		content = (
			<FormItems forms={recordsToMap} group={org} view={viewToggle.view} />
		);
	}

	if (search && searchRegistrations.length === 0) {
		content = (
			<MissingEntities
				noBorder
				title={t('No results')}
				description={t('No search results.')}
			/>
		);
	}

	if (isLoading) {
		return <Spinner />;
	}

	return (
		<Column spacing={styles.spacing._6}>
			<Row autoColumns="max-content" justifyContent="space-between">
				<Row autoColumns="max-content" align="center">
					<BadgeWrapper>
						<Badge badgeUrl={org.profileImageUrl} />
					</BadgeWrapper>
					<span className={css.org}>{org.name}</span>
				</Row>
				{viewToggle.component}
			</Row>
			<Divider />

			<div>{t('Please select a registration below:')}</div>
			<SearchInput placeholder={t('Search')} onChange={handleChange} />
			{content}
		</Column>
	);
}

interface DashboardRegistrationsProps {
	showTitle?: boolean;
}

export function DashboardRegistrations({
	showTitle,
}: DashboardRegistrationsProps): JSX.Element {
	const t = useT();

	const [search, setSearch] = useState('');
	const org = useCurrentOrganization();

	const { records, isLoading } = useCollection<models.form.Form>(
		endpoints.Organizations.ListForms(org.id)
	);

	if (isLoading) {
		return <Spinner />;
	}

	let searchContent = null;

	if (search) {
		const filteredRecords = filterSearch(records, search);

		searchContent = <FormItems forms={filteredRecords} group={org} />;

		if (filteredRecords.length === 0) {
			searchContent = (
				<MissingEntities
					noBorder
					title={t('No results')}
					description={t('No search results.')}
				/>
			);
		}
	}

	const handleSearch = (value: string) => {
		setSearch(value);
	};

	const hasRegistrations = records.length > 0;

	return (
		<Column spacing={styles.spacing._5}>
			{showTitle && <SectionTitle>{t('Registrations')}</SectionTitle>}
			{hasRegistrations && (
				<SearchInput onChange={handleSearch} placeholder={t('Search')} />
			)}
			{hasRegistrations ? (
				<Column spacing={styles.spacing._7}>
					{search ? (
						<Column spacing={styles.spacing._3}>{searchContent}</Column>
					) : (
						<FormCategoryWithItems
							forms={records}
							group={org}
							view={ListLayout.List}
						/>
					)}
				</Column>
			) : (
				<MissingEntities
					noBorder
					title={t('No open registrations')}
					description={t(
						'There are no open registrations published by the club.'
					)}
					icon="registration"
				/>
			)}
		</Column>
	);
}
