import { ReactNode } from 'react';

export const symbol_detector: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-120q-88 0-168.5-33T169-249q-5-5-7-10.667-2-5.666-2-11.333 0-12.75 8.5-21.375T190-301q7 0 12 2t10 7q53.6 53.887 122.965 82.943Q404.329-180 480-180q76.252 0 145.62-28.543Q694.988-237.086 749-291q3.667-3.75 9.167-6.375T769.533-300Q782-300 791-291q9 9 9 21.467 0 5.866-2.625 11.366T791-249q-63 62-143 95.5T480-120Zm0-160q-56.354 0-107.677-21Q321-322 282-363q-4-4-6-9.25t-2-10.85q0-11.9 8.385-20.9 8.386-9 20.782-9 5.833 0 11.333 2t9.5 7q31 32 71.317 48 40.317 16 84.5 16t84.683-16q40.5-16 71.5-48 5-5 10.25-7t10.85-2q11.9 0 20.9 9 9 9 9 21.467 0 5.866-2.625 11.366T678-362q-39 40-90.323 61Q536.354-280 480-280ZM180-780v60h600v-60H180Zm121 120 18 60h322l18-60H301Zm18 120q-19.5 0-35.25-11.625T262-582l-25-78h-57q-24.75 0-42.375-17.625T120-720v-60q0-24.75 17.625-42.375T180-840h600q24.75 0 42.375 17.625T840-780v60q0 24.75-17.625 42.375T780-660h-57l-30 81q-6.932 17.25-22.338 28.125Q655.257-540 636-540H319ZM180-780v60-60Z"
		/>
	</svg>
);
