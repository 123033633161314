import { ReactNode } from 'react';

export const symbol_linked_services: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M766.61-614q-55.61 0-97.11-35T617-737H342q-9 45-41.5 77.5T223-618v275q53 11 88 52.404 35 41.405 35 97.596 0 64-44.944 108.5T193-40q-64 0-108.5-44.5T40-193.39Q40-249 75-290.5t88-52.5v-275q-53-11-88-52t-35-98q0-62.7 44.65-107.35Q129.3-920 192-920q57 0 98 35t52 88h275q11-53 52.404-88 41.405-35 97.596-35 64 0 108.5 44.5t44.5 108q0 63.5-44.5 108.5t-108.89 45ZM191.714-100q38.893 0 66.59-27Q286-154 286-191.714q0-38.893-27.696-66.59Q230.607-286 191.714-286 154-286 127-258.304q-27 27.697-27 66.59Q100-154 127-127t64.714 27Zm0-574q38.893 0 66.59-27.612Q286-729.225 286-768q0-37.95-27.696-64.975Q230.607-860 191.714-860 154-860 127-832.975T100-768q0 38.775 27 66.388Q154-674 191.714-674ZM768-40q-63.525 0-108.763-44.65Q614-129.3 614-192q0-63.525 45.128-108.763Q704.257-346 767.628-346 831-346 875.5-300.872q44.5 45.129 44.5 108.5Q920-129 875.35-84.5T768-40Zm0-60q37.95 0 64.975-27Q860-154 860-191.714q0-38.893-27.025-66.59Q805.95-286 768-286q-38.775 0-66.388 27.696Q674-230.607 674-191.714 674-154 701.612-127q27.613 27 66.388 27Zm0-574q37.95 0 64.975-27.612Q860-729.225 860-768q0-37.95-27.025-64.975Q805.95-860 768-860q-38.775 0-66.388 27.025Q674-805.95 674-768q0 38.775 27.612 66.388Q729.225-674 768-674ZM193-193Zm0-574Zm574 574Zm0-574Z"
		/>
	</svg>
);
