import { t } from '@transifex/native';

export function getAnnotationName(tool: string): string {
	const _context = 'video/annotations/tools';

	switch (tool) {
		case 'label':
			return t('Text', { _context });

		case 'circle':
			return t('Circle', { _context });

		case 'spotlight':
			return t('Spotlight', { _context });

		case 'line':
			return t('Line', { _context });

		case 'connection':
			return t('Connection', { _context });

		case 'arrow':
			return t('Arrow', { _context });

		case 'polygon':
			return t('Polygon', { _context });
	}

	return '';
}

export function getAnnotationPreferenceName(preference: string): string {
	const _context = 'video/annotations/preferences';

	switch (preference) {
		case 'circleSize':
			return t('Circle size', { _context });
		case 'color':
			return t('Color', { _context });
		case 'dashed':
			return t('Dashed', { _context });
		case 'doubleArrow':
			return t('Double arrow', { _context });
		case 'fontSize':
			return t('Font size', { _context });
		case 'opacity':
			return t('Opacity', { _context });
		case 'raised':
			return t('Raised', { _context });
		case 'rotating':
			return t('Rotating', { _context });
		case 'rotatingColor':
			return t('Rotating color', { _context });
		case 'squiggly':
			return t('Squiggly', { _context });
		case 'striped':
			return t('Striped', { _context });
		case 'strokeWidth':
			return t('Stroke width', { _context });
		case 'text':
			return t('Text', { _context });
	}

	return '';
}
