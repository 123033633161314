import styled from 'styled-components';

import * as palette from 'pkg/config/palette';
import { small } from 'pkg/config/breakpoints';

import rgba from 'pkg/rgba';

import Icon from 'components/icon';

import * as iconStyles from 'components/icon/styles.css';
import * as rowStyles from 'components/layout/row/styles.css';

const PlayerIcon = styled.div`
	height: 33px;
	width: 33px;
	background: ${palette.blue[500]};
	border-radius: 17px;

	display: flex;
	justify-content: center;
	align-items: center;

	.${iconStyles.icon} {
		font-size: 24px;
		color: ${palette.white};
	}
`;

const DiffWrapper = styled.div`
	background-color: ${(props) =>
		`hsla(${props.color[0]}, ${props.color[1]}%, ${props.color[2]}%, .1)`};
	padding: 5px 10px;
	border-radius: 30px;
	text-align: center;
	width: 100%;
	max-width: 50px;
`;

const DiffWrapperNoColor = styled.div`
	width: 100%;
	max-width: 50px;
	font-weight: var(--font-weight-normal);
	font-size: 12px;
	line-height: 15px;
	text-align: center;
	color: ${palette.gray[600]};
	display: flex;
	justify-content: center;
	align-items: center;
`;

const PlayerScore = styled.div`
	width: 100%;
	max-width: 100px;
	display: flex;
	align-items: center;

	font-weight: var(--font-weight-normal);
	font-size: 18px;
	line-height: 23px;

	p {
		width: 33px;
		text-align: center;
	}
`;

const PlayerScoreB = styled(PlayerScore)`
	justify-content: flex-end;
`;

const Diff = styled.div`
	color: ${(props) =>
		`hsl(${props.color[0]}, ${props.color[1]}%, ${props.color[2]}%)`};
	font-weight: var(--font-weight-normal);
	font-size: 10px;
`;

const Row = styled.div`
	padding: 0 15px;
	width: 100%;
	display: flex;
	justify-content: space-between;

	&:nth-of-type(1) {
		transition: opacity 300ms ease-in-out 200ms;
	}

	&:nth-of-type(2) {
		transition: opacity 300ms ease-in-out 250ms;
	}

	&:nth-of-type(3) {
		transition: opacity 300ms ease-in-out 300ms;
	}

	&:nth-of-type(4) {
		transition: opacity 300ms ease-in-out 350ms;
	}

	&:nth-of-type(5) {
		transition: opacity 300ms ease-in-out 400ms;
	}

	&:nth-of-type(5 + n) {
		transition: opacity 300ms ease-in-out 450ms;
	}
`;

const HR = styled.hr`
	height: 1px;
	background: ${rgba(palette.gray[600], 0.2)};
	border: none;
	outline: none;
	width: 100%;

	&:nth-of-type(1) {
		transition: opacity 300ms 300ms ease-in-out;
	}

	&:nth-of-type(2) {
		transition: opacity 300ms 400ms ease-in-out;
	}

	&:nth-of-type(3) {
		transition: opacity 300ms 500ms ease-in-out;
	}

	&:nth-of-type(4) {
		transition: opacity 300ms 600ms ease-in-out;
	}

	&:nth-of-type(5) {
		transition: opacity 300ms 700ms ease-in-out;
	}

	&:nth-of-type(5 + n) {
		transition: opacity 300ms 800ms ease-in-out;
	}
`;

const SlideImageGradient = styled.div`
	background: linear-gradient(${rgba(palette.white, 0.2)}, ${palette.white});

	height: 100%;
	position: absolute;
	top: 45px;
	right: 0;
	bottom: 0;
	left: 0;
`;

const Wrapper = styled.div`
	display: grid;
	grid-gap: 15px;
	width: 250px;
	align-content: center;
	justify-content: stretch;
	max-width: 300px;
	position: relative;
	margin: 0 auto;

	@media ${small} {
		margin-top: 30px;
		height: 250px;
	}

	${HR},
	.${rowStyles.row} {
		opacity: ${(props) => (props.active ? '1' : '0')};
	}
`;

const scores = [
	['7.2', '9.0'],
	['5.5', '5.6'],
	['6.7', '5.3'],
	['8.0', '8.2'],
];

const calcDiff = (a, b) => Math.round((a / b) * 100 - 100);

const calcColor = (b, a) => {
	let diff = parseFloat((b / a).toFixed(3));

	let hue = 120; // Green
	let saturation = 100 * diff;

	if (diff < 1) {
		hue = 360; // Green
		saturation = 100 - 100 * diff;
	}

	return [hue, saturation, Math.min(100 * (1 - Math.abs(diff - 0.5)), 85)];
};

const renderRow = ([a, b], index) => {
	const aFloat = parseFloat(a);
	const bFloat = parseFloat(b);
	const diff = calcDiff(bFloat, aFloat);
	const color = calcColor(bFloat, aFloat);

	return [
		<Row key={`row--${index}`}>
			<PlayerScore>
				<p>{a}</p>
			</PlayerScore>

			<DiffWrapper color={color}>
				<Diff color={color}>{diff}%</Diff>
			</DiffWrapper>

			<PlayerScoreB>
				<p>{b}</p>
			</PlayerScoreB>
		</Row>,
		<HR key={`break--${index}`} />,
	];
};

const Compare = (props) => {
	return (
		<Wrapper active={props.active}>
			<Row>
				<PlayerScore>
					<PlayerIcon>
						<Icon name="nav-profile" />
					</PlayerIcon>
				</PlayerScore>
				<DiffWrapperNoColor>vs</DiffWrapperNoColor>
				<PlayerScoreB>
					<PlayerIcon>
						<Icon name="nav-profile" />
					</PlayerIcon>
				</PlayerScoreB>
			</Row>
			{scores.map(renderRow)}

			<SlideImageGradient />
		</Wrapper>
	);
};

export default Compare;
