import { useT } from '@transifex/react';

import { QueryObject } from 'pkg/hooks/query-state';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { useCurrentOrganization } from 'pkg/identity';
import * as actions from 'pkg/actions';
import { useOrganizationIdentity } from 'pkg/identity/organization';
import { pushState } from 'pkg/router/state';
import * as routes from 'pkg/router/routes';
import getWorkOSErrors from 'pkg/sso';
import { LoginTypes, storeLoginInfo } from 'pkg/local_storage/login-info';
import * as models from 'pkg/api/models';
import { parse } from 'pkg/json';

interface SSORedirectProps {
	query: QueryObject;
}

// This route is just resposible for validating a sso login, probably adding error handling to this moving
// forward
export default function SSORedirect({ query }: SSORedirectProps): void {
	const t = useT();
	const { refreshOrganizationIdentity } = useOrganizationIdentity();
	const organization = useCurrentOrganization();
	const workOSErrors = getWorkOSErrors(query);
	const parsedState = parse(query?.state as string);

	const handleErrors = () => {
		actions.flashes.show({
			title: t('Unable to authenticate'),
			message: t(
				'There was a problem when trying to authenticate you towards the identity provider, please try again or contact your IT admin to resolve the issue'
			),
		});

		pushState(routes.Home());
	};

	useComponentDidMount(async () => {
		if (workOSErrors.hasError) {
			handleErrors();
			return;
		}

		if (query?.code) {
			const [req, resp] = await actions.sso.login({
				code: query.code as string,
			});

			if (!req.ok) {
				handleErrors();
				return;
			}

			await actions.auth.updateAuthToken(
				{ accountToken: resp.accountToken, orgToken: resp.orgToken },
				organization.id
			);

			refreshOrganizationIdentity();

			storeLoginInfo(
				resp.account,
				LoginTypes.SSO,
				parsedState?.identityProvider as unknown as models.identityProvider.ScrubbedIdentityProvider,
				organization.id
			);

			if (parsedState?.nextRoute) {
				pushState(parsedState?.nextRoute as string);
			} else {
				pushState(routes.Home());
			}
		}
	});

	return null;
}
