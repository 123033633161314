import { CSSProperties } from 'react';

import {
	Account,
	Group,
	OnboardingInfo,
	Invite,
} from 'pkg/api/models/onboarding_info';
import useMixedState from 'pkg/hooks/useMixedState';
import { AccountFlags } from 'pkg/api/models/account';
import { useCurrentRoute } from 'pkg/router/hooks';

import { useOnboardingContext } from 'routes/public/onboarding';

import { useAppState } from 'components/application/state';

interface OnboardingState extends OnboardingInfo {
	groupCode?: string;
	inviteKey?: string;
	parentalConsentEmail?: string;
	returnToForms?: boolean;
	/**
	 * The public ID of the form the user should be redirected to after onboarding
	 */
	formGUID?: string;
}

interface OnboardingStateHook {
	set: (partial: Partial<OnboardingState>) => void;
	setAccount: (account: Account) => void;
	setTargetAccount: (account: Account) => void;
	setGroup: (group: Group) => void;
	setInvite: (invite: Invite) => void;
	setAll: (state: OnboardingState) => void;
	get: <T>(key: keyof OnboardingState) => T;
	getAll: () => OnboardingState;
	flush: () => void;
}

export function useOnboardingState(): OnboardingStateHook {
	const { getStore, setStore } = useOnboardingContext();
	const storedState = getStore('state');

	let initialState: OnboardingState;

	if (storedState) {
		initialState = JSON.parse(storedState) as OnboardingState;
	}

	const [current, setCurrent, override] =
		useMixedState<OnboardingState>(initialState);

	const setStorage = (state: OnboardingState) => {
		setStore('state', JSON.stringify({ ...state }));
	};

	const setState = (state: OnboardingState) => {
		setCurrent(state);
		setStorage(state);
	};

	const set = (partial: Partial<OnboardingState>) => {
		const state: OnboardingState = { ...current, ...partial };

		setState(state);
	};

	const setAccount = (account: Partial<Account>) => {
		const state: OnboardingState = {
			...current,
			account: { ...current?.account, ...account },
		};

		setState(state);
	};

	const setTargetAccount = (account: Partial<Account>) => {
		const state: OnboardingState = {
			...current,
			targetAccount: { ...current?.targetAccount, ...account },
		};

		setState(state);
	};

	const setGroup = (group: Group) => {
		let state: OnboardingState;

		if (!group) {
			state = {
				...current,
				group: null,
			};
		} else {
			state = {
				...current,
				group: { ...current?.group, ...group },
			};
		}

		setState(state);
	};

	const setInvite = (invite: Invite) => {
		let state: OnboardingState;

		if (!invite) {
			state = {
				...current,
				invite: null,
			};
		} else {
			state = {
				...current,
				invite: { ...invite },
			};
		}

		setState(state);
	};

	const setAll = (state: OnboardingState) => {
		override(state);
		setStorage(state);
	};

	const get = <T>(key: keyof OnboardingState): T => {
		if (current && key in current) {
			return current[key] as T;
		}

		return null;
	};

	const getAll = () => current;

	const flush = () => {
		setStore('state', null);

		override({});
		setStorage({});
	};

	return {
		set,
		setAccount,
		setTargetAccount,
		setGroup,
		setInvite,
		setAll,
		get,
		getAll,
		flush,
	};
}

export interface OnboardingAccountPayload {
	firstName: string;
	lastName: string;
	email: string;
	birthDate: string;
	countryId: number;
	languageCode: string;

	password?: string;
	appleAuthCode?: string;
	ssoToken?: string;
	inviteKey?: string;
	profileImage?: string;

	flags: AccountFlags[];
}

type OnboardingAccountPayloadCallback = (
	isTargetAccount?: boolean
) => null | OnboardingAccountPayload;

export function useOnboardingAccountPayload(): OnboardingAccountPayloadCallback {
	const state = useOnboardingState();
	const route = useCurrentRoute();

	const data = state.getAll();
	const { language: languageCode } = useAppState();

	const callback: OnboardingAccountPayloadCallback = (
		isTargetAccount: boolean = false
	) => {
		const account = isTargetAccount ? data.targetAccount : data.account;
		const appleAuthCode = route.query?.code || account.appleAuthCode || '';

		const birthDate = [account.year, account.month, account.day]
			.map((item: number) => item.toString())
			.map((item: string) => item.padStart(2, '0'))
			.join('-');

		const payload: OnboardingAccountPayload = {
			firstName: account.firstName,
			lastName: account.lastName,
			email: account.email,
			countryId: account.countryId,
			birthDate,
			flags: [AccountFlags.CompletedFirstLogin],
			languageCode,
		};

		if (account.profileImage) {
			payload.profileImage = account.profileImage;
		}

		if (account.password !== '') {
			payload.password = account.password;
		}

		if (appleAuthCode !== '') {
			payload.appleAuthCode = appleAuthCode;
		}

		if (data.inviteKey) {
			payload.inviteKey = data.inviteKey;
		}

		if (account?.ssoToken) {
			payload.ssoToken = account.ssoToken;
		}

		return payload;
	};

	return callback;
}

function normalizedBrandedCSSVariables(
	hsl: string[] = ['0%', '0%', '0%']
): CSSProperties {
	const [h, s, l] = hsl;

	let hue: string = h || '247';
	let sat: string = '55%';

	// Gradient adjustments
	let lumStart: string = '22%';
	let lumStop: string = '13%';

	// Force increased saturation for *branded* color
	if (h) {
		sat = '95%';
	}

	// Force grayscale when pure white or black
	if (s === '0%' || l === '0%' || l === '100%') {
		hue = '0';
		sat = '0%';

		// Adjust grayscale to slightly darker to adjust for the lack of saturation
		lumStart = '18%';
		lumStop = '10%';
	}

	return {
		'--hue': hue,
		'--sat': sat,
		'--lum-start': lumStart,
		'--lum-stop': lumStop,
	} as CSSProperties;
}

export function useBrandedCSSVariables(brandHsl: string[]): CSSProperties {
	return normalizedBrandedCSSVariables(brandHsl);
}
