import { ReactNode } from 'react';

export const symbol_stacked_email: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M220-240q-24 0-42-18.5T160-300v-480q0-24 18-42t42-18h640q24 0 42 18t18 42v480q0 23-18 41.5T860-240H220Zm301-216L220-706v406h640v-406L559-456q-8 7-19 7t-19-7Zm19-63 315-261H225l315 261ZM100-120q-24 0-42-18.5T40-180v-529q0-12.75 8.675-21.375Q57.351-739 70.175-739 83-739 91.5-730.375T100-709v529h690q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T790-120H100Zm760-586v-74H220v74-74h640v74Z"
		/>
	</svg>
);
