import { ReactNode } from 'react';

export const symbol_unknown_5: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M190-488q-13 0-21.5-8.5T160-518q0-13 8.5-21.5T190-548h254q13 0 21.5 8.5T474-518q0 13-8.5 21.5T444-488H190Zm290-332q-13 0-21.5-8.5T450-850q0-13 8.5-21.5T480-880q85 0 158 30.5T765-765q54 54 84.5 127T880-480q0 85-30.5 158T765-195q-54 54-127 84.5T480-80q-80 0-155.5-29.5T195-196q-4-4-6-9.5t-2-11.5q0-12 8-20t20-8q6 0 11.5 2t9.5 6q52 48 112 72.5T480-140q149 0 244.5-95.5T820-480q0-149-95.5-244.5T480-820Z"
		/>
	</svg>
);
