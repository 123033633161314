import { ReactNode } from 'react';

export const symbol_incomplete_circle: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-84 31-156.5T197-763l283 283v-400q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Z"
		/>
	</svg>
);
