import { ReactNode } from 'react';

export const symbol_print_error: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M80-542q0-45.05 30.5-75.525Q141-648 186-648h664q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-588H186q-19.55 0-32.775 13.225Q140-561.55 140-542v186h102v-16q0-24.75 17.625-42.375T302-432h338q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T640-372H302v192h338q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T640-120H302q-24.75 0-42.375-17.625T242-180v-116H140q-24.75 0-42.375-17.625T80-356v-186Zm162-106v-132q0-24.75 17.625-42.375T302-840h356q24.75 0 42.375 17.625T718-780v132h-60v-132H302v132h-60Zm517.825 528Q747-120 738.5-129t-8.5-21.5q0-12.5 9-21t21.5-8.5q12.5 0 21 8.625T790-150q0 12-8.675 21-8.676 9-21.5 9ZM730-280v-218q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T790-498v218q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T730-280ZM140-588h590-590Z"
		/>
	</svg>
);
