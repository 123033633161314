import { useMemo } from 'react';

import * as zIndex from 'pkg/config/zindex';

let lastIndex = zIndex.startIndex;

export const useNewTopIndex = (): number => {
	return useMemo(() => {
		// We add 10 so we have some z-index for backdrops etc.
		lastIndex += 10;
		return lastIndex;
	}, []);
};
