import { ReactNode } from 'react';

export const symbol_family_history: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-60q-63 0-106.5-43.5T330-210q0-55 34.5-95.5T450-357v-93H270q-24.75 0-42.375-17.625T210-510v-90h-80q-12.75 0-21.375-8.625T100-630v-220q0-12.75 8.625-21.375T130-880h220q12.75 0 21.375 8.625T380-850v220q0 12.75-8.625 21.375T350-600h-80v90h420v-93q-51-11-85.5-51.5T570-750q0-63 43.5-106.5T720-900q63 0 106.5 43.5T870-750q0 55-34.5 95.5T750-603v93q0 24.75-17.625 42.375T690-450H510v93q51 11 85.5 51.5T630-210q0 63-43.5 106.5T480-60Zm239.798-600Q757-660 783.5-686.298t26.5-63.5Q810-787 783.702-813.5t-63.5-26.5Q683-840 656.5-813.702t-26.5 63.5Q630-713 656.298-686.5t63.5 26.5ZM160-660h160v-160H160v160Zm319.798 540Q517-120 543.5-146.298t26.5-63.5Q570-247 543.702-273.5t-63.5-26.5Q443-300 416.5-273.702t-26.5 63.5Q390-173 416.298-146.5t63.5 26.5ZM240-740Zm480-10ZM480-210Z"
		/>
	</svg>
);
