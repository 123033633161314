import { ReactNode } from 'react';

export const symbol_speed: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M407-325q23 23 66.5 21.5T537-336l179-280q7-11-1.5-19.5T695-637L418-456q-30 20-32 64t21 67Zm71-474q45 0 94.5 12t97.5 39q11 7 15 19.31 4 12.309-4 23.5-8 11.19-20.5 12.69T636-697q-41-22-83.5-32T478-739q-140.481 0-239.241 100.215Q140-538.569 140-396.017 140-351 152.5-305q12.5 46 35.5 85h579q22-36 35-84t13-94q0-35-9-75.5T776-551q-7-11-5.5-24t12.5-20q10.636-8 23.318-4Q819-595 825-583q23 45 35.5 89.5T875-404q2 60-12 113t-41 98q-12 23-25.5 28t-33.5 5H192q-17 0-33.5-8.5T134-193q-26-48-40-97.5T80-396q0-83 31.5-156.5t85.5-128Q251-735 323.68-767T478-799Zm-9 331Z"
		/>
	</svg>
);
