import { ReactNode } from 'react';

export const symbol_genetics: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M200-80q0-140 65-226t169-174q-104-88-169-174t-65-226v-20q0-13 8.5-21.5T230-930q13 0 21.5 8.5T260-900v20q0 11 .5 20.5T262-840h436q1-10 1.5-19.5t.5-20.5v-20q0-13 8.5-21.5T730-930q13 0 21.5 8.5T760-900v20q0 140-65 226T526-480q104 88 169 174t65 226v20q0 13-8.5 21.5T730-30q-13 0-21.5-8.5T700-60v-20q0-11-.5-20.5T698-120H262q-1 10-1.5 19.5T260-80v20q0 13-8.5 21.5T230-30q-13 0-21.5-8.5T200-60v-20Zm120-600h320q16-23 27.5-47.5T687-780H273q8 28 19.5 52.5T320-680Zm160 161q31-26 59-50.5t52-50.5H369q24 26 51.5 50.5T480-519ZM369-340h222q-24-26-52-50.5T480-441q-31 26-59 50.5T369-340Zm-96 160h414q-8-28-19.5-52.5T640-280H320q-16 23-27.5 47.5T273-180Z"
		/>
	</svg>
);
