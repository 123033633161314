import { ReactNode } from 'react';

export const symbol_stock_media: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-360q-24 0-42-18t-18-42v-400q0-24 18-42t42-18h400q24 0 42 18t18 42v400q0 24-18 42t-42 18H140Zm0-60h400v-400H140v400Zm181-80-51-63q-2.308-3-6.154-3T258-563l-51 71q-3 4-.75 8t6.75 4h253q4.5 0 6.75-4t-.75-8l-73-97q-2.308-3-6.154-3T387-589l-66 89ZM689.882-80Q644-80 612-112.118q-32-32.117-32-78Q580-236 612.083-268q32.084-32 77.917-32 14.3 0 26.65 3 12.35 3 23.35 9v-182q0-12.75 8.625-21.375T770-500h80q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-440h-50v250q0 45.833-32.118 77.917Q735.765-80 689.882-80ZM140-420v-400 400Z"
		/>
	</svg>
);
