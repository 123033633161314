import { ReactNode } from 'react';

export const symbol_equalizer: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M229.911-160Q201-160 180.5-180.562 160-201.125 160-230v-180q0-28.875 20.589-49.438Q201.177-480 230.089-480 259-480 279.5-459.438 300-438.875 300-410v180q0 28.875-20.589 49.438Q258.823-160 229.911-160Zm250 0Q451-160 430.5-180.562 410-201.125 410-230v-500q0-28.875 20.589-49.438Q451.177-800 480.089-800 509-800 529.5-779.438 550-758.875 550-730v500q0 28.875-20.589 49.438Q508.823-160 479.911-160Zm250 0Q701-160 680.5-180.562 660-201.125 660-230v-300q0-28.875 20.589-49.438Q701.177-600 730.089-600 759-600 779.5-579.438 800-558.875 800-530v300q0 28.875-20.589 49.438Q758.823-160 729.911-160Z"
		/>
	</svg>
);
