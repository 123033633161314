import { ReactNode } from 'react';

export const symbol_output: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v60q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T780-720v-60H180v600h600v-60q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T840-240v60q0 24-18 42t-42 18H180Zm585-330H390q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T390-510h375l-92-93q-8-9-8.5-21.1-.5-12.1 8.5-20.9 9-9 21-9t21 9l144 144q5 5 7 10.133 2 5.134 2 11Q868-474 866-469q-2 5-7 10L715-315q-9 9-21 9t-21-9q-9-9-9-21t9-21l92-93Z"
		/>
	</svg>
);
