// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.leO870PCEGV9MzqO8GI9 {
	color: var(--palette-white);
	opacity: 0.47;
}

.djrVBXWzVY7FEMA4Dnnm {
	color: var(--palette-white);
	text-align: center;
}

.leO870PCEGV9MzqO8GI9 {
	color: var(--palette-white);
	opacity: 0.47;
}

.djrVBXWzVY7FEMA4Dnnm {
	color: var(--palette-white);
	text-align: center;
}
`, "",{"version":3,"sources":["webpack://./routes/public/onboarding/styles.css"],"names":[],"mappings":";;AAEA;CACC,2BAA2B;CAC3B,aAAa;AACd;;AAEA;CACC,2BAA2B;CAC3B,kBAAkB;AACnB;;AARA;CACC,2BAA2B;CAC3B,aAAa;AACd;;AAEA;CACC,2BAA2B;CAC3B,kBAAkB;AACnB","sourcesContent":["@import 'styles.css';\n\n.faded {\n\tcolor: var(--palette-white);\n\topacity: 0.47;\n}\n\n.description {\n\tcolor: var(--palette-white);\n\ttext-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
export var faded = `leO870PCEGV9MzqO8GI9`;
export var description = `djrVBXWzVY7FEMA4Dnnm`;
export default ___CSS_LOADER_EXPORT___;
