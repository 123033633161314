import { ReactNode } from 'react';

export const symbol_west: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M194-450h656q13 0 21.5-8.5T880-480q0-13-8.5-21.5T850-510H194l187-187q9-9 9-21t-9-21q-9-9-21-9t-21 9L101-501q-5 5-7 10t-2 11q0 6 2 11t7 10l238 238q9 9 21 9t21-9q9-9 9-21t-9-21L194-450Z"
		/>
	</svg>
);
