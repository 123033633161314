import { ReactNode } from 'react';

export const symbol_severe_cold: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M810-570q-13 0-21.5-8.5T780-600q0-12 8.5-21t21.5-9q12 0 21 9t9 21q0 13-9 21.5t-21 8.5ZM400-238l-94 94q-8 8-20.5 8t-20.5-9q-9-8-9-20.5t9-21.5l135-135v-78h-78L186-264q-8 8-20.5 8t-20.5-9q-9-8-9-20.5t9-21.5l93-93H109q-12 0-20.5-8.5T80-430q0-12 8.5-21t21.5-9h128l-94-93q-8-8-8-20.5t9-21.5q8-8 20.5-8t21.5 8l135 135h78v-78L264-673q-8-8-8-20.5t9-21.5q8-8 20.5-8t21.5 8l93 93v-128q0-12 8.5-21t21.5-9q12 0 21 9t9 21v128l93-93q8-8 20.5-8t21.5 8q8 9 8 21.5t-8 20.5L460-538v78h290q12 0 21 9t9 21q0 13-9 21.5t-21 8.5H622l93 94q8 8 8 20.5t-8 20.5q-9 9-21.5 9t-20.5-9L538-400h-78v78l135 136q8 8 8 20.5t-8 20.5q-9 9-21.5 9t-20.5-9l-93-93v129q0 12-9 20.5T430-80q-13 0-21.5-8.5T400-110v-128Zm410-452q-13 0-21.5-8.5T780-720v-130q0-12 8.5-21t21.5-9q12 0 21 9t9 21v131q0 12-9 20.5t-21 8.5Z"
		/>
	</svg>
);
