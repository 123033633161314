import { Fragment } from 'react';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import * as routes from 'pkg/router/routes';
import { pushState } from 'pkg/router/state';
import { useCurrentOrganization } from 'pkg/identity';

import * as Card from 'components/Card';
import Icon from 'components/icon';

import Button from 'design/button';

import * as css from './styles.css';

interface EventUsersSummaryProps {
	event: models.event.Event;
	preview?: boolean;
}

export default function EventUsersSummary({
	event,
	preview,
}: EventUsersSummaryProps) {
	const eventUsers = event.users || [];
	const hasEnded = models.event.hasEnded(event);
	const org = useCurrentOrganization();

	const handleShowParticipants = () =>
		preview
			? pushState(routes.Event.View(org.id, event.id, 'overview'))
			: pushState(routes.Event.View(org.id, event.id, 'participants'));

	const usersAccepted = eventUsers.filter((eventUser) => {
		if (hasEnded) {
			return eventUser.attendanceStatus === 'attended';
		}

		return eventUser.status === 'accepted';
	});

	const usersDeclined = eventUsers.filter((eventUser) => {
		if (hasEnded) {
			return (
				eventUser.attendanceStatus !== 'attended' &&
				eventUser.attendanceStatus !== 'unset'
			);
		}

		return eventUser.status === 'declined';
	});

	const usersPending = eventUsers.filter((eventUser) => {
		if (hasEnded) {
			return eventUser.attendanceStatus === 'unset';
		}

		return eventUser.status === 'pending';
	});

	if (eventUsers.length === 0 && !models.event.canEdit(event)) {
		return null;
	}

	const acceptedLabel = hasEnded ? t('Attended') : t('Accepted');

	const declinedLabel = hasEnded ? t(`Didn't attend`) : t('Declined');

	const pendingLabel = hasEnded ? t('Unhandled') : t('No response');

	return (
		<Fragment>
			<Card.Base>
				<Card.Body>
					<div className={css.summaryLayout} onClick={handleShowParticipants}>
						<div className={css.summaryColumn}>
							<strong>{acceptedLabel}</strong>
							<div>
								<Icon name="check-filled" />
								<span>{usersAccepted.length}</span>
							</div>
						</div>
						<div className={css.summaryColumn}>
							<strong>{declinedLabel}</strong>
							<div>
								<Icon name="close-circle-filled" />
								<span>{usersDeclined.length}</span>
							</div>
						</div>
						<div className={css.summaryColumn}>
							<strong>{pendingLabel}</strong>
							<div>
								<Icon name="help-circle-filled" />
								<span>{usersPending.length}</span>
							</div>
						</div>
					</div>
				</Card.Body>
				{!preview && (
					<Fragment>
						<Card.Divider />
						<Card.Footer>
							<Button
								label={t('Participants')}
								icon="groups"
								iconPosition="left"
								secondary
								onClick={handleShowParticipants}
							/>
						</Card.Footer>
					</Fragment>
				)}
			</Card.Base>
		</Fragment>
	);
}
