import styled from 'styled-components';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import * as routes from 'pkg/router/routes';
import { useCurrentOrganization } from 'pkg/identity';

import GroupItem, {
	GroupItemAction,
	VisibilityLabel,
} from 'components/library/GroupItem';

const DurationLabel = styled(VisibilityLabel)`
	left: auto;
	right: var(--spacing-4);

	@media ${styles.breakpoint.small} {
		left: auto;
		right: var(--spacing-3);
	}
`;

interface VideoGroupItemProps {
	href?: string;
	embedded?: boolean;
	item: models.video.Video;
	actions?: GroupItemAction<models.video.Video>[];
	queryString?: string;
}

export default function VideoGroupItem({
	href,
	embedded,
	item,
	actions,
	queryString,
}: VideoGroupItemProps): JSX.Element {
	const org = useCurrentOrganization();
	const visibilityLabel = item.groupId
		? item.group?.name || t('Team')
		: t('Only me');
	const thumbnail = models.video.thumbnail(item);

	if (!href) {
		href = routes.Video.Show(org.id, item.id);
	}

	if (queryString) {
		href += queryString;
	}

	return (
		<GroupItem
			item={item}
			href={href}
			actions={actions}
			account={item.account}
			title={item.title}
			createdAt={item.createdAt}
			isNew={models.video.isNew(item)}
			visibilityLabel={visibilityLabel}
			embedded={embedded}>
			{thumbnail ? (
				<img src={thumbnail} loading="lazy" />
			) : (
				<img
					src={window.TS.assetUrl + '/img/library-placeholder.svg'}
					loading="lazy"
				/>
			)}
			<DurationLabel data-has-image={!!thumbnail}>
				{models.video.durationTimestamp(item)}
			</DurationLabel>
		</GroupItem>
	);
}
