import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import * as routes from 'pkg/router/routes';
import { CollectionResponse } from 'pkg/api/use_collection';
import { formatToCurrency } from 'pkg/i18n/formatters';
import DateTime from 'pkg/datetime';
import { useCurrentOrganization } from 'pkg/identity';

import * as Table from 'design/table';

interface RowProps {
	payoutRow: models.payoutRow.PayoutRow;
}

function Row({ payoutRow }: RowProps) {
	const org = useCurrentOrganization();

	const orderLink = routes.Order.Show(org.id, payoutRow.order?.id);
	const amount = formatToCurrency(payoutRow.amount / 100, payoutRow.currency);
	const fee = formatToCurrency(
		(payoutRow.amount - payoutRow.netAmount) / 100,
		payoutRow.currency
	);
	const netAmount = formatToCurrency(
		payoutRow.netAmount / 100,
		payoutRow.currency
	);

	return (
		<Table.Row>
			<Table.LinkCell href={orderLink} linkStyle>
				{payoutRow.order?.invoiceNumber}
			</Table.LinkCell>
			<Table.LinkCell href={orderLink}>{amount}</Table.LinkCell>
			<Table.LinkCell href={orderLink}>{fee}</Table.LinkCell>
			<Table.LinkCell href={orderLink}>{netAmount}</Table.LinkCell>
			<Table.LinkCell href={orderLink}>
				{models.payout.getPayoutTypeTranslation(payoutRow.type)}
			</Table.LinkCell>
			<Table.LinkCell href={orderLink}>
				{DateTime.fromTimestamp(payoutRow.createdAt).toLocaleDateString()}
			</Table.LinkCell>
		</Table.Row>
	);
}

interface PayoutRowTableProps {
	payoutRowsCollection: CollectionResponse<models.payoutRow.PayoutRow>;
	handleSortClick: (field: string, sortBy: 'asc' | 'desc') => void;
	sort: {
		by: string;
		order: 'desc' | 'asc';
	};
}

export default function PayoutRowTable({
	payoutRowsCollection,
	handleSortClick,
	sort,
}: PayoutRowTableProps) {
	const columns: Table.HeaderColumn[] = [
		{
			content: t('Invoice number'),
		},
		{ content: t('Amount paid'), sortKey: 'amount' },
		{ content: t('Transaction fee') },
		{ content: t('Net amount'), sortKey: 'net_amount' },
		{ content: t('Type'), sortKey: 'type' },
		{ content: t('Transaction date') },
	];

	return (
		<Table.Table
			columns={columns}
			onSort={handleSortClick}
			sortBy={sort.by}
			sortOrder={sort.order}>
			{payoutRowsCollection.records.map((pr) => (
				<Row key={pr.id} payoutRow={pr} />
			))}
		</Table.Table>
	);
}
