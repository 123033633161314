import { ReactNode } from 'react';

export const symbol_audio_video_receiver: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-340h680v-360H140v360Zm50 140q-12 0-21-9t-9-21v-50h-20q-24 0-42-18t-18-42v-360q0-24 18-42t42-18h680q24 0 42 18t18 42v360q0 24-18 42t-42 18h-20v50q0 12-8.625 21T770-200q-12 0-21-9t-9-21v-50H220v50q0 12-8.625 21T190-200Zm-50-140v-360 360Zm540-100q33 0 56.5-23.5T760-520q0-33-23.5-56.5T680-600q-33 0-56.5 23.5T600-520q0 33 23.5 56.5T680-440Zm-410 0h240q12.75 0 21.375-8.625T540-470v-100q0-12.75-8.625-21.375T510-600H270q-12.75 0-21.375 8.625T240-570v100q0 12.75 8.625 21.375T270-440Z"
		/>
	</svg>
);
