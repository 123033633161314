import { ReactNode } from 'react';

export const symbol_gastroenterology: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M120-320q0-45.833 32.083-77.917Q184.167-430 230-430h80q75 0 102.5-33t27.5-77q0-32-17.25-51T380-610q-24.75 0-42.375-17.625T320-670v-180q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T380-850v180q50 0 85 36.5t35 93.5q0 69-45 119.5T310-370h-80q-21.25 0-35.625 14.375T180-320v210q0 12.75-8.675 21.375Q162.649-80 149.825-80 137-80 128.5-88.625T120-110v-210Zm180 160q0-45.833 32.083-77.917Q364.167-270 410-270h160q88 0 149-61t61-149v-40q0-88-66-149t-154-61q-24.75 0-42.375-17.625T500-790v-60q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T560-850v60q112 0 196 79t84 191v40q0 112-79 191t-191 79H410q-21.25 0-35.625 14.375T360-160v50q0 12.75-8.675 21.375Q342.649-80 329.825-80 317-80 308.5-88.625T300-110v-50ZM180-80v-240q0-21.25 14.375-35.625T230-370h80q100 0 145-50.5T500-540q0-57-34.5-93.5T380-670v-210 210q51 0 85.5 36.5T500-540q0 69-45 119.5T310-370h-80q-21.25 0-35.625 14.375T180-320v240Z"
		/>
	</svg>
);
