import { t } from '@transifex/native';
import { ReactNode, useEffect, useState } from 'react';

import useInterval from 'pkg/hooks/useInterval';

import InfoScreen from 'components/InfoScreen';

import { useApiConfig } from 'components/application/apiconfig';

interface MaintenanceModeProps {
	children: ReactNode;
}

export default function MaintenanceMode({
	children,
}: MaintenanceModeProps): ReactNode {
	const apiConfig = useApiConfig();

	const [pingInterval, setPingInterval] = useState<number>(5000);

	useInterval(() => {
		apiConfig.refreshConfig();
	}, pingInterval);

	useEffect(() => {
		if (!apiConfig.maintenanceMode) {
			setPingInterval(0);
		}
	}, [apiConfig.maintenanceMode]);

	if (apiConfig.maintenanceMode) {
		return (
			<InfoScreen
				icon="info-circle"
				heading={t(`Maintenance`)}
				message={t(
					`360Player is down for maintenance. Please check back later.`
				)}
			/>
		);
	}

	return children;
}
