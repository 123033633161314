import { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import TextField from 'components/form/TextInput';

const TimeInput = styled(TextField)`
	margin-bottom: 0.5rem;
	width: auto;

	&:last-child {
		margin-bottom: 0;
	}
`;

const NOOP = () => {};

export default class NativeTimePicker extends Component {
	static propTypes = {
		selectedDate: PropTypes.instanceOf(Date),
		onChange: PropTypes.func.isRequired,
	};

	static defaultProps = {
		selectedDate: new Date(),
		onBlur: NOOP,
	};

	get componentProperties() {
		const d = this.props.selectedDate;
		const value = [d.getHours(), d.getMinutes()]
			.map((p) => p.toString().padStart(2, '0'))
			.join(':');

		return {
			type: 'time',
			value,
			onChange: this.onChange,
			onBlur: this.onBlur,
		};
	}

	onChange = (event) => {
		let nextDate = this.handleTimeChange(event);
		this.props.onChange(nextDate);
	};

	onBlur = (event) => {
		let nextDate = this.handleTimeChange(event);
		this.props.onBlur(nextDate);
	};

	handleTimeChange = (event) => {
		let nextDate = new Date(+this.props.selectedDate);

		if (event.target.valueAsDate) {
			const inputDate = event.target.valueAsDate;
			nextDate.setHours(inputDate.getUTCHours(), inputDate.getUTCMinutes(), 0);
		} else {
			const [h, m] = event.target.value.split(':').map((p) => parseInt(p));
			nextDate.setHours(h, m, 0);
		}

		if (!(nextDate instanceof Date && !Number.isNaN(nextDate))) {
			nextDate = this.props.selectedDate;
		}

		return nextDate;
	};

	render() {
		return <TimeInput {...this.componentProperties} />;
	}
}
