import { ReactNode } from 'react';

export const symbol_edit_calendar: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-80q-24 0-42-18t-18-42v-620q0-24 18-42t42-18h65v-28q0-13.6 9.2-22.8 9.2-9.2 22.8-9.2 14.025 0 23.513 9.2Q310-861.6 310-848v28h340v-28q0-13.6 9.2-22.8 9.2-9.2 22.8-9.2 14.025 0 23.513 9.2Q715-861.6 715-848v28h65q24 0 42 18t18 42v191q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.695t-8.5-21.549V-570H180v430h290q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q500-97 491.375-88.5T470-80H180Zm0-550h600v-130H180v130Zm0 0v-130 130Zm380 520v-81q0-5.565 2-10.783Q564-207 569-212l211.612-210.773q9.113-9.12 20.25-13.174Q812-440 823-440q12 0 23 4.5t20 13.5l37 37q9 9 13 20t4 22q0 11-4.5 22.5t-13.583 20.624L692-89q-5 5-10.217 7-5.218 2-10.783 2h-81q-12.75 0-21.375-8.625T560-110Zm300-233-37-37 37 37ZM620-140h38l121-122-18-19-19-18-122 121v38Zm141-141-19-18 37 37-18-19Z"
		/>
	</svg>
);
