import { ReactNode } from 'react';

export const symbol_balcony: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M359.825-500Q347-500 338.5-508.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Zm240 0Q587-500 578.5-508.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5ZM180-80q-24.75 0-42.375-17.625T120-140v-200q0-20 11-35t29-22v-163q0-66 25-124.5t68.5-102Q297-830 355.5-855T480-880q66 0 124.5 25t102 68.5Q750-743 775-684.5T800-560v163q18 7 29 22t11 35v200q0 24.75-17.625 42.375T780-80H180Zm0-260v200h105v-200H180Zm165 200h105v-200H345v200ZM220-400h230v-418q-99 12-164.5 85T220-560v160Zm290 0h230v-160q0-100-65.5-173T510-818v418Zm0 260h105v-200H510v200Zm165 0h105v-200H675v200Z"
		/>
	</svg>
);
