import { useState } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import { useCurrentGroupId, useCurrentMembership } from 'pkg/identity';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCollection } from 'pkg/api/use_collection';

import SelectedUsersPreview from 'containers/chat/SelectedUsersPreview';

import SearchInput from 'components/form/SearchInput';
import { Spinner } from 'components/loaders/spinner';
import UserSelectableList, {
	ChatSelectableListProps,
} from 'components/user/selectable_list';

const Wrapper = styled.div`
	overflow: auto;
`;

const SearchWrapper = styled.div`
	padding: 1rem;
`;

interface ContactsProps {
	selectedUsers?: models.user.User[];
	multipleChoice?: boolean;
	showPreview?: boolean;
	hiddenUserIds?: number[];

	userClick?: (users: models.user.User[]) => void;

	contacts: models.membership.Membership[];
}

export default function Contacts({
	contacts,
	selectedUsers = [],
	userClick = null,
	multipleChoice = false,
	showPreview = false,
	hiddenUserIds = [],
}: ContactsProps): JSX.Element {
	const [inputValue, setInputValue] = useState('');
	const activeGroupId = useCurrentGroupId();
	const activeMembership = useCurrentMembership();

	const collection = useCollection<models.user.User>(
		inputValue &&
			models.membership.isAdminOrStaff(activeMembership) &&
			endpoints.Groups.SearchForUsers(activeGroupId),
		{
			queryParams: new URLSearchParams({
				text: inputValue,
				search_type: 'name',
				has_account: 'true',
			}),
		}
	);

	if (hiddenUserIds.length > 0) {
		if (contacts.length > 0) {
			contacts = contacts.filter((c) => !hiddenUserIds.includes(c.user.id));
		}
	}

	const handleInput = async (searchPhrase: string) => {
		await setInputValue(searchPhrase);
	};

	const selectableListProps: ChatSelectableListProps = {
		selectedUsers,
		onChange: userClick,
		multipleChoice,
	};

	const sortUsers = (users: models.user.User[]) =>
		users.sort((a, b) =>
			models.user.fullName(a).localeCompare(models.user.fullName(b))
		);

	if (
		inputValue !== '' &&
		!models.membership.isAdminOrStaff(activeMembership)
	) {
		selectableListProps.users = sortUsers(
			contacts
				.filter((c) =>
					models.user.fullName(c.user).toLowerCase().includes(inputValue)
				)
				.map((m) => m.user)
		);
	} else if (
		inputValue !== '' &&
		models.membership.isAdminOrStaff(activeMembership)
	) {
		selectableListProps.users = sortUsers(collection.records);
	} else {
		selectableListProps.users = contacts
			.map((c) => c.user)
			.sort((a, b) =>
				models.user.fullName(a).localeCompare(models.user.fullName(b))
			);
		selectableListProps.groupBy = models.membership.groupByRole(contacts);
	}

	return (
		<Wrapper>
			<SearchWrapper>
				<SearchInput
					filled
					value={inputValue}
					placeholder={t('Search contacts...')}
					onChange={handleInput}
				/>
			</SearchWrapper>

			{showPreview && (
				<SelectedUsersPreview onChange={userClick} users={selectedUsers} />
			)}

			{collection.isLoading ? (
				<Spinner />
			) : (
				<UserSelectableList {...selectableListProps} />
			)}
		</Wrapper>
	);
}
