import { ReactNode } from 'react';

export const symbol_publish: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m450-531-99 99q-8.8 9-20.9 8.5-12.1-.5-21.491-9.5Q300-442 300-454.5t9-21.5l150-150q5-5 10.133-7 5.134-2 11-2Q486-635 491-633q5 2 10 7l151 151q9 9 9 21t-8.609 21q-9.391 9-21.891 9-12.5 0-21.5-9l-99-98v341q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T450-190v-341Zm-290-96v-113q0-24 18-42t42-18h520q24 0 42 18t18 42v113q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T740-627v-113H220v113q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T160-627Z"
		/>
	</svg>
);
