import { Component } from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import {
	getUserPrimaryRating,
	getSingleRating,
} from 'pkg/selectors/ratings.selector';
import { getPositions } from 'pkg/selectors/positions.selector';

import { togglePublishedToPlayer } from 'pkg/actions/ratings';

import rgba from 'pkg/rgba';
import { pushState } from 'pkg/router/state';
import Link from 'pkg/router/Link';
import * as routes from 'pkg/router/routes';
import { IdentityContext } from 'pkg/identity';
import * as models from 'pkg/api/models';

import Base from 'components/Card';
import Heatmap from 'components/HeatMap';
import RelativeTime from 'components/RelativeDateTime';
import Icon from 'components/icon';

import {
	HeatmapWrapper,
	ItemActions as IActions,
	ItemUser,
	ItemAvatar,
	UserLabel,
} from 'components/group/UserActionList';

import * as ContextMenu from 'design/context_menu';

const UserName = styled.div`
	font-weight: var(--font-weight-semibold);
	color: ${rgba(styles.palette.main.foregroundColor)};

	@media (hover: hover) {
		&:hover {
			color: ${rgba(styles.palette.main.foregroundColor, 0.7)};
		}
	}
`;

const ItemActions = styled(IActions)`
	@media ${styles.breakpoint.small} {
		justify-content: flex-end;
	}
`;

const ListItemContainer = styled(Base)`
	display: grid;
	grid-template-areas: 'avatar heat-map user actions';
	grid-template-columns: 45px 30px auto 250px;
	grid-column-gap: 10px;
	cursor: pointer;
	align-items: center;

	position: relative;
	padding: 15px 0.5rem 16px 16px;

	background: ${(props) =>
		props.currentPrimary ? `var(--palette-green-200)` : `var(--palette-white)`};

	@media ${styles.breakpoint.small} {
		grid-template-areas: 'avatar heat-map user actions';
		grid-template-columns: 30px 30px auto 100px;

		padding-top: 19px;
		padding-left: 16px;
		padding-bottom: 19px;
		padding-right: 16px;
	}
`;

const DraftItem = styled(ListItemContainer)`
	@media ${styles.breakpoint.small} {
		padding-top: 15px;
		padding-bottom: 14px;
	}
`;

const statusColors = {
	active: {
		background: styles.palette.green[500],
		color: styles.palette.white,
		border: rgba(styles.palette.white, 0),
	},
	draft: {
		background: rgba(styles.palette.orange[500], 0.15),
		color: styles.palette.orange[600],
		border: rgba(styles.palette.white, 0),
	},
	archive: {
		background: styles.palette.white,
		color: styles.palette.gray[500],
		border: styles.palette.gray[400],
	},
	player: {
		background: styles.palette.white,
		color: styles.palette.blue[500],
		border: rgba(styles.palette.blue[500], 0.7),
	},
};

const StatusLabel = styled.div`
	border: ${(props) => `1px solid ${statusColors[props.status].border}`};
	margin-right: 15px;
	border-radius: 55px;
	display: ${(props) => (props.status === 'archive' ? 'none' : 'block')};
	background: ${(props) => statusColors[props.status].background};
	color: ${(props) => statusColors[props.status].color};
	text-transform: lowercase;
	font-weight: var(--font-weight-normal);
	font-size: 0.7rem;
	line-height: 1;
	padding: 4px 7px 5px;
`;

const PlayerVisible = styled.span`
	margin-right: 16px;
	font-size: 12px;
	color: var(--palette-gray-500);

	display: ${(props) => (props.mobile ? 'none' : 'inline-block')};

	@media ${styles.breakpoint.small} {
		display: ${(props) => (!props.mobile ? 'none' : 'inline-block')};
		width: 100%;
		padding-top: 7px;
		font-size: 10px;
		margin-right: 0;
	}
`;

const MenuIcon = styled(Icon)`
	margin-right: 0.5rem;
`;

const PositionAvatar = styled.div`
	background-color: #fff;
	border-radius: 20px;
	height: 40px;
	width: 40px;
	display: flex;
	font-size: 0.8em;
	justify-content: center;
	align-items: center;
	border: 1px solid rgba(0, 0, 0, 0.1);

	@media ${styles.breakpoint.small} {
		height: 30px;
		width: 30px;
		font-size: 9px;
		font-weight: var(--font-weight-normal);
	}
`;

const MenuTrigger = styled.div`
	z-index: 8;
	cursor: pointer;
	color: ${rgba(styles.palette.card.contextMenuColor)};
	padding: 0.5rem;

	@media (hover: hover) {
		&:hover {
			color: ${rgba(styles.palette.card.contextMenuColor, 0.7)};
		}
	}

	${(props) =>
		props.visible &&
		css`
			color: ${rgba(styles.palette.main.actionableLinkColor)};

			@media (hover: hover) {
				&:hover {
					color: ${rgba(styles.palette.main.actionableLinkColor, 0.7)};
				}
			}
		`} svg {
		width: 18px;
	}
`;

const HeatMapLink = styled(Link)`
	width: 20px;
	height: 100%;
	max-height: 28px;
	display: inline-block;
	position: relative;
	align-self: center;
`;

class UserRatingListItem extends Component {
	static contextType = IdentityContext;

	get status() {
		const { rating } = this.props;
		if (!rating.isPrimaryRating) {
			if (rating.userId === rating.author.id) {
				return 'player';
			}
			return 'draft';
		}

		if (this.props.isActive) {
			return 'active';
		}

		return 'archive';
	}

	viewRating = () => {
		const { rating } = this.props;
		const { groupId, userId, id } = rating;

		pushState('user.profile.position-match', {
			rating: id,
			groupId,
			userId,
		});
	};

	editRating = () => {
		this.props.openEditModal(this.props.rating.id);
	};

	get itemProps() {
		const { rating } = this.props;

		const props = {
			key: rating.id,
			currentPrimary: this.props.isActive,
			showHeatmap: true,
			'data-type': 'active',
		};

		if (!rating.isPrimaryRating && rating.userId !== rating.author.id) {
			props.as = DraftItem;
		}

		return props;
	}

	showPlayerVisible = (mobile) => {
		const { rating } = this.props;
		if (!rating.isPrimaryRating && rating.userId !== rating.author.id) {
			return (
				<PlayerVisible mobile={mobile}>
					<Icon
						name="eye-hidden"
						style={{
							marginRight: '6px',
							opacity: '0.7',
						}}
					/>
					{t(`Not visible for player`)}
				</PlayerVisible>
			);
		}
		return null;
	};

	get ratingPosition() {
		return this.props.positions.find(
			(p) => p.id === this.props.rating.suggestedPositionId
		);
	}

	toggleRating = async () => {
		await this.props.togglePublishedToPlayer(this.props.rating.id);
	};

	get contextMenu() {
		const { rating } = this.props;
		const draftToggleKey = rating.isPrimaryRating
			? t(`Save as draft`)
			: t(`Publish to player`);
		let contextMenuItems = [];

		const { userId, id } = rating;

		contextMenuItems.push(
			<ContextMenu.LinkItem
				key="view-rating"
				href={routes.User.Profile.PositionMatch(
					rating.author?.organizationId,
					this.props.groupId,
					userId,
					id
				)}>
				<MenuIcon name="eye-visible" />
				{t(`View`)}
			</ContextMenu.LinkItem>
		);

		const canEdit =
			rating.userId === rating.author.id ||
			models.membership.isAdminOrStaff(this.context.membership);

		if (canEdit) {
			contextMenuItems.push(
				<ContextMenu.Item key="edit-rating" onClick={this.editRating}>
					<MenuIcon name="edit" />
					{t('Edit')}
				</ContextMenu.Item>
			);
		}

		if (
			models.membership.isAdminOrStaff(this.context.membership) &&
			rating.userId !== rating.author.id
		) {
			contextMenuItems.push(
				<ContextMenu.Item key="rating-status" onClick={this.toggleRating}>
					{draftToggleKey}
				</ContextMenu.Item>
			);
		}

		const toggle = (
			<MenuTrigger>
				<Icon name="context-menu" />
			</MenuTrigger>
		);

		return (
			<ContextMenu.Menu toggleWith={toggle}>
				{contextMenuItems}
			</ContextMenu.Menu>
		);
	}

	get statusLabel() {
		if (!this.context.membership) {
			return null;
		}

		let label = t('Active');
		switch (this.status) {
			case 'archive':
				label = t('Historic');
				break;
			case 'draft':
				label = t('Draft');
				break;
			case 'player':
				label = t('Player');
				break;
		}

		return (
			<StatusLabel active={this.props.isActive} status={this.status}>
				{label}
			</StatusLabel>
		);
	}

	render() {
		const { rating } = this.props;

		let authorFullName = '';

		if (rating.author?.firstName && rating.author?.lastName) {
			authorFullName = `${rating.author?.firstName} ${rating.author?.lastName}`;
		}

		const { id, userId } = rating;

		return (
			<ListItemContainer {...this.itemProps}>
				<ItemAvatar
					as={Link}
					href={routes.User.Profile.PositionMatch(
						rating.author?.organizationId,
						this.props.groupId,
						userId,
						id
					)}>
					<PositionAvatar>{this.ratingPosition?.name}</PositionAvatar>
				</ItemAvatar>
				<HeatmapWrapper>
					<HeatMapLink
						href={routes.User.Profile.PositionMatch(
							rating.author?.organizationId,
							this.props.groupId,
							userId,
							id
						)}>
						<Heatmap
							key={`HeatMap-${this.props.rating?.id}`}
							showScores={false}
							rating={this.props.rating?.positionScores}
							small={true}
						/>
					</HeatMapLink>
				</HeatmapWrapper>
				<ItemUser
					as={Link}
					href={routes.User.Profile.PositionMatch(
						rating.author?.organizationId,
						this.props.groupId,
						userId,
						id
					)}>
					<UserName>{authorFullName}</UserName>

					<UserLabel>
						<RelativeTime dateTime={rating.createdAt} />
						{this.showPlayerVisible(true)}
					</UserLabel>
				</ItemUser>
				<ItemActions>
					{this.showPlayerVisible(false)}

					{this.statusLabel}

					{this.contextMenu}
				</ItemActions>
			</ListItemContainer>
		);
	}
}

const mapStateToProps = () => {
	return (state, props) => {
		const rating = getSingleRating(state, { id: props.ratingId });

		const primaryRating = getUserPrimaryRating(state, {
			userId: rating.userId,
			groupId: props.groupId,
		});

		return {
			rating,
			primaryRating,
			positions: getPositions(state),
		};
	};
};

const actions = {
	togglePublishedToPlayer,
};

export default connect(mapStateToProps, actions)(UserRatingListItem);
