import { Set } from 'immutable';

import User from 'pkg/models/user';

import SelectableList from 'routes/video/shared/SelectableList';

import { useClipState } from 'components/video-analytics/ClipState';

interface TaggedUsers {
	[userId: number]: string;
}

interface GroupBy {
	[groupLabel: string]: number[];
}

interface UserTagsProps {
	availableUsers: Set<User>;
	groupBy: GroupBy;
}

export default function UserTags({
	availableUsers,
	groupBy,
}: UserTagsProps): JSX.Element {
	const clipState = useClipState();

	const selectedIds: number[] = Object.keys(clipState.taggedUsers).map(
		(userId: string) => Number.parseInt(userId, 10)
	);

	const handleChange = (userIds: number[]) => {
		const nextTaggedUsers = userIds.reduce(
			(next: TaggedUsers, userId: number) => {
				next[userId] = clipState.taggedUsers.hasOwnProperty(userId)
					? clipState.taggedUsers[userId]
					: '';

				return next;
			},
			{}
		);

		clipState.setTaggedUsers(nextTaggedUsers);
	};

	const handleCommentChange = (userId: number, comment: string) => {
		clipState.setTaggedUsers({
			...clipState.taggedUsers,
			[userId]: comment,
		});
	};

	return (
		<SelectableList
			selectable
			multipleChoice
			showControls
			showUngrouped={false}
			items={availableUsers}
			groupBy={groupBy}
			comments={clipState.taggedUsers}
			selectedIds={selectedIds}
			onChange={handleChange}
			onCommentChange={handleCommentChange}
		/>
	);
}
