import { ReactNode } from 'react';

export const symbol_ods: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M236-357h60q20 0 35-15t15-35v-146q0-20-15-35t-35-15h-60q-20 0-35 15t-15 35v146q0 20 15 35t35 15Zm0-50v-146h60v146h-60Zm179 50h95q20 0 35-15t15-35v-146q0-20-15-35t-35-15h-95q-6 0-10.5 4.5T400-588v216q0 6 4.5 10.5T415-357Zm35-50v-146h60v146h-60Zm290 50q14.025 0 23.513-9.487Q773-375.975 773-390v-76q0-14-9.487-23.5Q754.025-499 740-499h-77v-54h85q10.833 0 17.917-7.116 7.083-7.117 7.083-18Q773-589 765.917-596q-7.084-7-17.917-7H646q-14.025 0-23.513 9.487Q613-584.025 613-570v78q0 14 9.487 23.5Q631.975-459 646-459h77v52h-85q-10.833 0-17.917 7.116-7.083 7.117-7.083 18Q613-371 620.083-364q7.084 7 17.917 7h102ZM140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm0-60h680v-520H140v520Zm0 0v-520 520Z"
		/>
	</svg>
);
