import { ReactNode } from 'react';

export const symbol_warehouse: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-776 140-640v460h120v-260q0-24.75 17.625-42.375T320-500h320q24.75 0 42.375 17.625T700-440v260h120v-460L480-776ZM80-180v-459q0-18.846 10.5-33.923Q101-688 118-695l340-136q11-5 22-5t22 5l340 136q17 7 27.5 22.077Q880-657.846 880-639v459q0 24.75-17.625 42.375T820-120H640v-320H320v320H140q-24.75 0-42.375-17.625T80-180Zm290 60v-60h60v60h-60Zm80-120v-60h60v60h-60Zm80 120v-60h60v60h-60ZM320-500h320-320Z"
		/>
	</svg>
);
