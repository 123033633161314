import { t } from '@transifex/native';
import {
	ChangeEvent,
	SyntheticEvent,
	useContext,
	useEffect,
	useState,
} from 'react';

import * as styles from 'pkg/config/styles';

import { PaymentPreviewPayload, PaymentRow } from 'pkg/actions/payment_preview';

import { usePaymentProviderContext } from 'pkg/contexts/provider_settings';
import { cssClasses } from 'pkg/css/utils';
import useMixedState from 'pkg/hooks/useMixedState';
import * as models from 'pkg/api/models';
import { useCurrentGroup } from 'pkg/identity';
import uuid from 'pkg/uuid';
import DateTime, { Granularity } from 'pkg/datetime';
import * as actions from 'pkg/actions';

import {
	findPriceOptionType,
	FormEngineContext,
	PriceOption,
	PriceOptionType,
} from 'routes/forms/hooks/useFormEngine';
import ProductInfo from 'routes/forms/hooks/modals/price_option/product_info';
import SelectableOptions from 'routes/payments/orders/create/components/selectable_options';

import * as StepModal from 'components/step-modal';

import * as Input from 'components/form/inputs';
import Column from 'components/layout/column';
import InfoBox from 'components/form/info-box';
import Section from 'components/form/Section';
import DatePicker from 'components/form/date-picker';
import PaymentPreview from 'components/payment_platform/preview';

import * as css from './styles.css';

export interface PriceOptionState {
	installmentCount: number;
	bundleNumber: number;
	subscriptionStartAt: number;
	payFirstOrderNow: boolean;

	type: PriceOptionType;
	productPrices: models.productPrice.ProductPrice[];
}

enum StartOptions {
	Immediately = 'immediately',
	Custom = 'custom',
}

interface PriceOptionModalProps {
	priceOption?: PriceOption;
	hideModal: () => void;
}

export default function PriceOptionModal({
	priceOption,
	hideModal,
}: PriceOptionModalProps) {
	const formEngineContext = useContext(FormEngineContext);
	const group = useCurrentGroup();
	const groupId = group.id;
	const providerSettings = usePaymentProviderContext();
	const [preview, setPreview] =
		useState<models.paymentPreview.PaymentPreview>(null);

	const [priceOptionState, setPriceOptionState] =
		useMixedState<PriceOptionState>({
			type: priceOption ? findPriceOptionType(priceOption) : '',
			installmentCount: priceOption?.installmentCount || null,
			bundleNumber: priceOption ? priceOption.bundleNumber : uuid(),
			productPrices: priceOption?.productPrices || [],
			subscriptionStartAt: priceOption?.subscriptionStartAt || 0,
			payFirstOrderNow: priceOption?.payFirstOrderNow || false,
		});

	useEffect(() => {
		const fetchData = async () => {
			if (groupId) {
				const payload: PaymentPreviewPayload = {
					groupId,
					rows: priceOptionState.productPrices.map((pp) => {
						const formProductPrice =
							formEngineContext.formState.selectedProductsInfo.find(
								(spi) => spi.product.id === pp.productId
							);

						const row: PaymentRow = {
							productPriceId: pp.id,
							quantity: formProductPrice.minQuantity,
						};

						if (formProductPrice.taxRate) {
							row.taxRateId = formProductPrice.taxRateId;
						}

						return row;
					}),
				};

				if (priceOptionState.installmentCount) {
					payload.installmentCount = priceOptionState.installmentCount;
				}

				if (priceOptionState.subscriptionStartAt) {
					payload.subscriptionStartAt = priceOptionState.subscriptionStartAt;
					payload.delayedSubscriptionStart = true;
				}

				const [req, resp] = await actions.paymentPreview.getPreview(payload);

				if (req) {
					setPreview(resp);
				}
			}
		};

		fetchData();
	}, [groupId, JSON.stringify(priceOptionState)]);

	const reucrringProductPrices = priceOptionState.productPrices.filter(
		(item) => item.recurring === true
	);
	const oneTimePrices = priceOptionState.productPrices.filter(
		(i) => !i.recurring
	);
	const hasRecurringProductPrices = reucrringProductPrices.length > 0;
	const hasConflictingPriceCycles = reucrringProductPrices.some(
		(price) =>
			price.recurringInterval !== reucrringProductPrices[0].recurringInterval ||
			price.recurringIntervalCount !==
				reucrringProductPrices[0].recurringIntervalCount
	);
	const todayDate = new Date();
	const initialDateTime = new DateTime(todayDate).next(Granularity.day);
	const intialDate = new Date(initialDateTime.getUnixTimestamp() * 1000);
	const hasSubscriptionStartsAt = !!priceOptionState.subscriptionStartAt;

	const startsAtDate = new Date(priceOptionState.subscriptionStartAt * 1000);
	const startsAtDateTime = DateTime.fromTimestamp(
		priceOptionState.subscriptionStartAt
	);

	const dateStringOptions: Intl.DateTimeFormatOptions = {
		month: 'short',
		day: 'numeric',
		year: 'numeric',
	};
	const customDateString = hasSubscriptionStartsAt
		? startsAtDateTime.toLocaleDateString(dateStringOptions)
		: initialDateTime.toLocaleDateString(dateStringOptions);

	const handleChangeStartDate = (dates: Date[]) => {
		const subscriptionStartAt = new DateTime(
			new Date(dates[0])
		).getUnixTimestamp();

		setPriceOptionState({
			subscriptionStartAt,
		});
	};

	const StartsAtInputs = SelectableOptions<StartOptions>({
		selectableOptions: [
			{
				value: StartOptions.Immediately,
				translation: t('At registration'),
				onClick: () => handleSubStartChange(StartOptions.Immediately),
			},
			{
				value: StartOptions.Custom,
				translation: t('A custom date'),
				contextMenuTranslation: customDateString,
				onClick: () => handleSubStartChange(StartOptions.Custom),
				children: (
					<DatePicker
						name="subStartDate"
						handleChange={handleChangeStartDate}
						dates={hasSubscriptionStartsAt ? [startsAtDate] : [intialDate]}
						disableDatesBefore={intialDate}
					/>
				),
			},
		],
		defaultSelectedValue: hasSubscriptionStartsAt
			? StartOptions.Custom
			: StartOptions.Immediately,
	});

	const hasAllPricesSelected =
		priceOptionState.productPrices.length ===
		formEngineContext.formState.selectedProductsInfo.length;

	const showInstallmentInfoBox = priceOptionState.installmentCount === 1;
	const showScheduledStartInfo =
		hasSubscriptionStartsAt && oneTimePrices.length === 0;

	let hasPaymentType = false;

	if (priceOptionState.type !== '') {
		hasPaymentType = true;
	}

	let canSavePriceOption = false;
	let paymentTypeSectionTitle = '';
	let paymentTypeSectionDescription = '';

	switch (priceOptionState.type) {
		case 'direct': {
			paymentTypeSectionTitle = t('Upfront payment');
			paymentTypeSectionDescription = t(
				'Make a single payment at the time of registration.'
			);
			canSavePriceOption =
				!hasConflictingPriceCycles &&
				hasAllPricesSelected &&
				!hasRecurringProductPrices;
			break;
		}
		case 'installment': {
			paymentTypeSectionTitle = t('Installment plan');
			paymentTypeSectionDescription = t(
				'Select when the installment plan should begin, as well as for how many payments the plan will be. The schedule will only be applied to prices added with a recurring option.'
			);
			canSavePriceOption =
				!hasConflictingPriceCycles &&
				hasAllPricesSelected &&
				!showInstallmentInfoBox &&
				hasRecurringProductPrices &&
				priceOptionState.installmentCount > 1 &&
				!showScheduledStartInfo;
			break;
		}
		case 'subscription': {
			paymentTypeSectionTitle = t('Subscription plan');
			paymentTypeSectionDescription = t(
				'Enable recurring payments for continuous access until the plan is manually cancelled.'
			);
			canSavePriceOption =
				!hasConflictingPriceCycles &&
				hasAllPricesSelected &&
				hasRecurringProductPrices &&
				!showScheduledStartInfo;
			break;
		}
	}

	const handlePriceOptionChange = (value: PriceOptionType) => {
		setPriceOptionState({ type: value });
	};

	const handlePriceOptionSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
		const { value } = e.currentTarget;

		const defaultPriceOptionValues: PriceOptionState = {
			...priceOptionState,
			installmentCount: null,
			payFirstOrderNow: false,
			subscriptionStartAt: 0,
			type: value as PriceOptionType,
		};

		setPriceOptionState(defaultPriceOptionValues);
	};

	const handleChangeInstallmentCount = (
		event: SyntheticEvent<HTMLInputElement>
	) => {
		const value = Number.parseInt(event.currentTarget.value, 10);

		setPriceOptionState({
			installmentCount: value,
		});
	};

	const handleSubStartChange = (value: StartOptions) => {
		if (value === StartOptions.Immediately) {
			setPriceOptionState({
				subscriptionStartAt: 0,
			});
		} else {
			setPriceOptionState({
				subscriptionStartAt: initialDateTime.getUnixTimestamp(),
			});
		}
	};

	const handleTypeSelectNext = async () => {
		if (priceOptionState.type === 'direct' && hasRecurringProductPrices) {
			const productPrices = priceOptionState.productPrices.filter(
				(p) => !p.recurring
			);

			setPriceOptionState({
				productPrices,
			});
		}
	};

	const handlePriceSelectPrev = async () => {
		if (priceOptionState.type === 'installment') {
			setPriceOptionState({
				installmentCount: null,
			});
		}
	};

	const closeModal = async () => hideModal();

	const handleNext = async () => {
		const currentProductPrices = [...formEngineContext.formState.priceOptions];

		const bundleIndex = currentProductPrices.findIndex(
			(current) => current.bundleNumber === priceOptionState.bundleNumber
		);

		if (bundleIndex !== -1) {
			currentProductPrices[bundleIndex] = priceOptionState;
		} else {
			currentProductPrices.push(priceOptionState);
		}

		formEngineContext.setFormState({
			priceOptions: currentProductPrices,
		});
	};

	const showDelayedSubscriptionStart =
		providerSettings.settings.canHaveDelayedSubscriptionStart;

	return (
		<StepModal.Base wide onClose={hideModal} initialIndex={priceOption ? 1 : 0}>
			<StepModal.Step
				title={t('Add payment option')}
				onNext={handleTypeSelectNext}
				canGoNext={hasPaymentType}>
				<Section
					title={t('Payment options')}
					description={t(
						'Customize how customers pay for a smooth checkout experience. Choose between upfront payment or recurring intervals.'
					)}
					hideDivider>
					<Column>
						<Input.Control
							type="radio"
							label={t('Upfront payment')}
							description={t(
								'Make a single payment at the time of registration'
							)}
							value="direct"
							onChange={handlePriceOptionChange}
							checked={priceOptionState.type === 'direct'}
						/>
						<Input.Control
							type="radio"
							label={t('Installment plan')}
							description={t(
								'Split the cost into smaller payments with a set amount of installments'
							)}
							value="installment"
							onChange={handlePriceOptionChange}
							checked={priceOptionState.type === 'installment'}
						/>
						<Input.Control
							type="radio"
							label={t('Subscription plan')}
							description={t(
								'Enable recurring payments for continuous access until the plan is manually cancelled.'
							)}
							value="subscription"
							onChange={handlePriceOptionChange}
							checked={priceOptionState.type === 'subscription'}
						/>
					</Column>
				</Section>
			</StepModal.Step>
			<StepModal.Step
				title={t('Add payment option')}
				nextLabel={t('Save')}
				canGoNext={canSavePriceOption}
				onPrev={priceOption ? closeModal : handlePriceSelectPrev}
				onNext={handleNext}
				prevLabel={priceOption && t('Cancel')}>
				<Column spacing={styles.spacing._7}>
					{priceOption && (
						<Input.Group label={t('Payment type')}>
							<Input.Select
								value={priceOptionState.type}
								onChange={handlePriceOptionSelectChange}>
								<option value="direct">{t('Upfront payment')}</option>
								<option value="subscription">{t('Subscription plan')}</option>
								<option value="installment">{t('Installment plan')}</option>
							</Input.Select>
						</Input.Group>
					)}
					<Section
						title={paymentTypeSectionTitle}
						description={paymentTypeSectionDescription}
						hideDivider>
						{priceOptionState.type === 'subscription' &&
							showDelayedSubscriptionStart && (
								<div className={css.schedule_wrapper}>
									<Input.Group label={t('First payment')}>
										<div className={css.schedule_input_wrapper}>
											{StartsAtInputs.Content}
										</div>
									</Input.Group>
								</div>
							)}
						{priceOptionState.type === 'installment' && (
							<Column>
								<div
									className={cssClasses(
										css.schedule_wrapper,
										css.installments_schedule_wrapper
									)}>
									{showDelayedSubscriptionStart && (
										<Input.Group label={t('First installment')}>
											{StartsAtInputs.Content}
										</Input.Group>
									)}
									<Input.Group label={t('Number of installments')}>
										<Input.Field
											type="number"
											className={css.installments_amount_input}
											placeholder={t('Eg. 3')}
											onChange={handleChangeInstallmentCount}
											value={priceOptionState.installmentCount}
										/>
									</Input.Group>
								</div>
								{showInstallmentInfoBox && (
									<InfoBox
										color="red"
										text={t(
											'You need to enter at least 2 installments to use the installment plan option. If you only need your customer to do one payment, use a one-time price option instead.'
										)}
									/>
								)}
							</Column>
						)}
					</Section>
					{showScheduledStartInfo && (
						<InfoBox
							color="red"
							text={t(
								'When scheduling a start date of the recurring payment, at least one product due at registration must be added.'
							)}
						/>
					)}
					<Section title={t('Select prices')} hideDivider>
						<Column spacing={styles.spacing._4}>
							{formEngineContext.formState.selectedProductsInfo.map(
								(productInfo, index) => (
									<ProductInfo
										key={index}
										productInfo={productInfo}
										priceOptionState={priceOptionState}
										setPriceOptionState={setPriceOptionState}
										conflictingPriceCycles={hasConflictingPriceCycles}
									/>
								)
							)}
							{(priceOptionState.type === 'installment' ||
								priceOptionState.type === 'subscription') &&
								!hasRecurringProductPrices &&
								hasAllPricesSelected && (
									<InfoBox
										text={
											priceOptionState.type === 'installment'
												? t(
														'You need a recurring price selected in order to create a installment plan'
													)
												: t(
														'You need a recurring price selected in order to create a subscription plan'
													)
										}
										color="red"
									/>
								)}
							{hasConflictingPriceCycles && (
								<InfoBox
									text={t(
										'All prices within a payment option need to have the same recurring interval.'
									)}
									color="red"
								/>
							)}
							{priceOptionState.type === 'direct' &&
								hasRecurringProductPrices && (
									<InfoBox
										text={t(
											`You can't have a recurring price on a upfront payment.`
										)}
										color="red"
									/>
								)}
						</Column>
					</Section>
					<PaymentPreview
						preview={preview}
						registrationFlow
						productsWithTaxesExists={formEngineContext.formState.selectedProductsInfo.some(
							(spi) => spi.taxRate
						)}
					/>
				</Column>
			</StepModal.Step>
		</StepModal.Base>
	);
}
