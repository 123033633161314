import { ReactNode } from 'react';

export const symbol_ward: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M189.825-80Q177-80 168.5-88.625T160-110v-710h-50q-12 0-21-9t-9-21.5q0-12.5 9-21t21-8.5h80q12.75 0 21.375 8.625T220-850v740q0 12.75-8.675 21.375Q202.649-80 189.825-80ZM340-80q-24.75 0-42.375-17.625T280-140v-680q0-24.75 17.625-42.375T340-880h400q24.75 0 42.375 17.625T800-820v680q0 24.75-17.625 42.375T740-80H340Zm0-445q20-17 45.5-26t54.5-9h200q29 0 54.5 9t45.5 26v-295H340v295Zm199.911-85Q511-610 490.5-630.589q-20.5-20.588-20.5-49.5Q470-709 490.589-729.5q20.588-20.5 49.5-20.5Q569-750 589.5-729.411q20.5 20.588 20.5 49.5Q610-651 589.411-630.5q-20.588 20.5-49.5 20.5ZM340-140h400v-260q0-42-29-71t-71-29H440q-42 0-71 29t-29 71v260Zm170-150v50q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T570-240v-50h50q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T620-350h-50v-50q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T510-400v50h-50q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T460-290h50ZM340-140h400-400Z"
		/>
	</svg>
);
