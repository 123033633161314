import { ReactNode } from 'react';

export const symbol_screen_record: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-290q-79 0-134.5-55.5T290-480q0-79 55.5-134.5T480-670q79 0 134.5 55.5T670-480q0 79-55.5 134.5T480-290Zm340-190q0-48-13-92.5T771-655q-7-11-5.5-23.5T778-699q11-8 23-5.5t19 13.5q29 46 44.5 99.5T880-480q0 58-15.5 111.5T820-269q-7 11-19.5 14t-23.5-5q-11-8-12-20.5t6-23.5q23-38 36-83t13-93ZM480-820q-48 0-93 13t-83 36q-11 7-23.5 6T260-777q-8-11-5-23.5t14-19.5q46-29 99.5-44.5T480-880q59 0 112.5 15.5T692-820q11 7 14 19.5t-5 23.5q-8 11-21 12t-24-6q-38-23-82.5-36T480-820ZM140-480q0 48 13 93t36 83q7 11 6 23.5T183-260q-11 8-23.5 5T140-269q-29-46-44.5-99.5T80-480q0-58 15.5-111t44.5-99q7-11 19-14t23 5q11 8 12.5 20.5T189-655q-23 38-36 82.5T140-480Zm340 340q48 0 92.5-13t82.5-36q11-7 23.5-5.5T699-182q8 11 5 23t-14 19q-46 29-99 44.5T480-80q-58 0-111-15.5T270-140q-11-7-14-19t5-23q8-11 20.5-12.5T305-189q38 23 82.5 36t92.5 13Z"
		/>
	</svg>
);
