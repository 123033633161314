import { ReactNode } from 'react';

export const symbol_zone_person_urgent: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M80-758v-102q0-24 18-42t42-18h102q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T242-860H140v102q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Q97-728 88.5-736.625T80-758Zm60 638q-24 0-42-18t-18-42v-102q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-282v102h102q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T242-120H140Zm680-638v-102H718q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T718-920h102q24 0 42 18t18 42v102q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T820-758ZM540.08-634q-30.08 0-51.58-21.42-21.5-21.421-21.5-51.5 0-30.08 21.42-51.58 21.421-21.5 51.5-21.5 30.08 0 51.58 21.42 21.5 21.421 21.5 51.5 0 30.08-21.42 51.58-21.421 21.5-51.5 21.5ZM357-275l46-235-100 47v104q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T243-359v-125q0-9.333 4.875-16.722Q252.75-508.111 261-512l146-61q32-14 45.5-17.5t27.1-3.5q20.4 0 35.9 8.5T542-560l42 67q17 26 40.5 48.5T677-408l-30 51q-29-16-54.5-39.5T543-453l-43 178H357ZM610-80q-17 0-25.5-16t-.5-30l160-279q8-17 25.5-16t27.5 15l160 280q9 15-.75 30.5T930-80H610Zm160-40q6 0 10.5-4.5T785-135q0-6-4.5-10.5T770-150q-6 0-10.5 4.5T755-135q0 6 4.5 10.5T770-120Zm0-65q6 0 10.5-4.5T785-200v-135q0-6-4.5-10.5T770-350q-6 0-10.5 4.5T755-335v135q0 6 4.5 10.5T770-185Z"
		/>
	</svg>
);
