import { ReactNode } from 'react';

export const symbol_nest_sunblock: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M80-710v-60q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-770v60q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Q97-680 88.5-688.625T80-710Zm0 520v-60q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-250v60q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Q97-160 88.5-168.625T80-190Zm267-474-43 43q-9 9-21 8.5t-21-9.553q-9-9.052-9-21.5Q253-656 262-665l42-42q9.067-9 21.533-9Q338-716 347-706.947q9 9.052 9 21.5Q356-673 347-664Zm-43 410-43-42q-9-9-8.5-21t9.553-21q9.052-9 21.5-9Q296-347 305-338l42 42q9 9 9 21t-9.053 21q-9.052 9-21.5 9Q313-245 304-254Zm286-256-70-93q-8-10.348-6-22.174Q516-637 526-645t22-6q12 2 20 12l106 141q11 15 2.808 31.5Q668.617-450 650-450H350q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T350-510h240Zm240 310q-20 0-35-15t-15-35v-460q0-20.833 15-35.417Q810-760 830.5-760t35 14.583Q880-730.833 880-710v460q0 20-14.583 35-14.584 15-35.417 15ZM80-330v-60q38 0 64-26.438 26-26.437 26-63.562 0-38-26-64t-64-26v-60q63 0 106.5 43.5t43.5 106q0 62.5-43.5 106.5T80-330Z"
		/>
	</svg>
);
