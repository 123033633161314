import { useWatch } from 'react-hook-form';
import { Fragment } from 'react';
import { T, useT } from '@transifex/react';
import { useMediaQuery } from 'react-responsive';

import * as styles from 'pkg/config/styles';

import { MixedStateSetter } from 'pkg/hooks/useMixedState';
import { useCurrentOrganization } from 'pkg/identity';
import * as models from 'pkg/api/models';
import * as routes from 'pkg/router/routes';
import Link from 'pkg/router/Link';

import {
	DiscountFormState,
	DiscountType,
} from 'routes/payments/discounts/create';
import PreviewInfoBox from 'routes/payments/discounts/preview';
import RecurringSection, {
	RecurringConfigOptions,
} from 'routes/payments/discounts/create/form/recurring-section';

import Column from 'components/layout/column';
import * as Input from 'components/form/inputs';
import Section from 'components/form/Section';
import AvailabilitySection from 'components/discount/form/availability';
import InfoBox from 'components/form/info-box';

import * as css from './styles.css';

interface CreateDiscountFormProps {
	formState: DiscountFormState;
	setFormState: MixedStateSetter<DiscountFormState>;
	discount?: models.discount.Discount;
}

export default function CreateDiscountForm({
	formState,
	setFormState,
	discount,
}: CreateDiscountFormProps) {
	const t = useT();
	const organization = useCurrentOrganization();
	const hidePreview = useMediaQuery({ maxWidth: styles.breakpoint.fromLarge });

	let discountDefaultValue = '';

	if (discount?.percentOff) {
		discountDefaultValue = discount.percentOff.toString();
	} else if (discount?.amountOff) {
		discountDefaultValue = (discount.amountOff / 100).toString();
	}

	const code = useWatch({
		name: 'code',
		defaultValue: discount?.code || '',
	});
	const discountValue: string = useWatch({
		name: 'discountValue',
		defaultValue: discountDefaultValue,
	});

	let recurringConfigDefaultValue = RecurringConfigOptions.Initial;

	if (discount?.maxRecurringCount === 0) {
		recurringConfigDefaultValue = RecurringConfigOptions.Every;
	} else if (discount?.maxRecurringCount > 1) {
		recurringConfigDefaultValue = RecurringConfigOptions.SetAmount;
	}

	const recurringConfigValue = useWatch({
		name: 'recurringConfig',
		defaultValue: recurringConfigDefaultValue,
	});

	const maxUsageCount = useWatch({
		name: 'maxUsageCount',
		defaultValue: discount?.maxUsageCount || '1',
	});

	const handleTypeChange = (type: DiscountType) => {
		setFormState({
			type,
		});
	};

	const handleActiveChange = () => {
		setFormState({
			active: !formState.active,
		});
	};

	const onLimitUseChange = () =>
		setFormState({
			showUsageCount: !formState.showUsageCount,
		});

	const previewInfoBox = (
		<PreviewInfoBox
			formState={formState}
			code={code}
			discountValue={discountValue}
			recurringConfigValue={recurringConfigValue}
			maxUsageCount={maxUsageCount}
		/>
	);

	let shouldDisableInputs = false;

	if (discount) {
		shouldDisableInputs = true;
	}

	if (formState.showPreview) {
		return previewInfoBox;
	}

	return (
		<Fragment>
			<div className={css.wrapper}>
				<Section>
					<Column spacing={styles.spacing._8}>
						{discount && (
							<InfoBox>
								<div>
									<T
										_str={`Certain fields can't be edited after a discount has been created. These settings are locked to preserve the original configuration. To make changes to these fields, consider {link}.`}
										link={
											<Link href={routes.Discounts.Create(organization.id)}>
												{t('creating a new discount')}
											</Link>
										}
									/>
								</div>
							</InfoBox>
						)}
						<Input.Group label={t('Title')} required>
							<Input.Field
								name="title"
								placeholder={t('Eg. My coupon code')}
								required
								defaultValue={discount?.title || ''}
							/>
						</Input.Group>
						<Input.Group
							label={t('Description')}
							hint={t(
								'This will only be visible to other payment administrators'
							)}>
							<Input.Area
								name="description"
								minRows={3}
								defaultValue={discount?.description || ''}
							/>
						</Input.Group>
						<Input.Group
							label={t('Discount code')}
							required
							hint={t(
								'This will be the discount code the user fills in the checkout, it is case sensitive and use no blank spaces. Once created this cannot be changed and will be unique.'
							)}>
							<Input.Field
								name="code"
								placeholder={t('Eg. MYCOUPON50')}
								required
								defaultValue={code}
								disabled={shouldDisableInputs}
							/>
						</Input.Group>
					</Column>
				</Section>
				<Section title={t('Discount value')}>
					<Column spacing={styles.spacing._8}>
						<Column spacing={styles.spacing._3}>
							<Input.Control
								type="radio"
								checked={formState.type === DiscountType.Percentage}
								label={t('Percentage')}
								value="percentage"
								onChange={handleTypeChange}
								disabled={shouldDisableInputs}
							/>
							<Input.Control
								type="radio"
								checked={formState.type === DiscountType.FixedAmount}
								label={t('Fixed amount')}
								value="fixedAmount"
								onChange={handleTypeChange}
								disabled={shouldDisableInputs}
							/>
						</Column>
						<Input.Group
							label={
								formState.type === 'percentage'
									? t('Percentage off')
									: t('Amount off')
							}
							required>
							<Input.Field
								name="discountValue"
								required
								type="number"
								max={formState.type === 'percentage' ? '100' : 'false'}
								defaultValue={discountValue}
								disabled={shouldDisableInputs}>
								<Input.Prefix>
									{formState.type === 'percentage'
										? '%'
										: organization.currency.toLocaleUpperCase()}
								</Input.Prefix>
							</Input.Field>
						</Input.Group>
					</Column>
				</Section>
				<RecurringSection
					formState={formState}
					setFormState={setFormState}
					discountValue={discountValue}
					recurringConfigValue={recurringConfigValue}
					discount={discount}
				/>
				<Section title={t('Redemption limits')}>
					<Column spacing={styles.spacing._4} justify="start">
						<Input.Control
							type="checkbox"
							label={t('Limit the number of times this code can be redeemed')}
							description={t(
								'Redemption limits control how many times users can redeem the discount, not how many payments it applies to within a recurring plan.'
							)}
							checked={formState.showUsageCount}
							onChange={onLimitUseChange}
						/>
						{formState.showUsageCount && (
							<Input.Group label={t('Maximum redemptions')}>
								<Input.Field
									name="maxUsageCount"
									type="number"
									defaultValue={maxUsageCount}
									min="1"
									required
								/>
							</Input.Group>
						)}
					</Column>
				</Section>

				<AvailabilitySection
					active={formState.active}
					handleActiveChange={handleActiveChange}
				/>
			</div>
			{!hidePreview && <div>{previewInfoBox}</div>}
		</Fragment>
	);
}
