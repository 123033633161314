import { ReactNode } from 'react';

export const symbol_no_sim: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M424-880h316q24.75 0 42.375 18T800-820v523q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T740-297v-523H427l-83 83q-9 9-22 9t-22-8.609Q291-746 291-759q0-13 9-22l81-81q8-8 19.278-13 11.278-5 23.722-5ZM220-80q-24 0-42-18t-18-42v-470q0-12.444 4.5-23.722T178-653l19-18L60-808q-9-9-9-21t9-21.391Q69-859 81-859t21 9L867-85q9 9 9 21.158 0 12.158-8.609 21Q858-34 845.9-34T825-43L242-627l-22 22v465h520v-72l60 60v12q0 24-18 42t-42 18H220Zm315-422Zm-46 122Z"
		/>
	</svg>
);
