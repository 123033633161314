import { ReactNode } from 'react';

export const symbol_settings_account_box: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M576-40q-25 0-42.5-17.5T516-100v-280q0-25 17.5-42.5T576-440h280q25 0 42.5 17.5T916-380v280q0 25-17.5 42.5T856-40H576Zm140-200q25 0 42.5-17.5T776-300q0-25-17.5-42.5T716-360q-25 0-42.5 17.5T656-300q0 25 17.5 42.5T716-240Zm-280-8.553V-80h-15q-14 0-25-9t-13-23l-15-94q-19-7-40-19t-37-25l-86 40q-14 6-28 1.5T155-226L97-330q-8-13-4.546-26.942Q95.91-370.884 108-380l80-59q-2-9-2.5-20.5T185-480q0-9 .5-20.5T188-521l-80-59q-12.09-9.116-15.546-23.058Q89-617 97-630l58-104q8-13 22-17.5t28 1.5l86 40q16-13 37-25t40-18l15-95q2-14 13-23t25-9h118q14 0 25 9t13 23l15 94q19 7 40.5 18.5T669-710l86-40q14-6 27.5-1.5T804-734l59 104q8 13 4.5 27.5T852-580l-61 43q-10 7-21.5 6.5T750-540q-9-10.689-7-23.844Q745-577 756-584l44-32-40-72-106 46q-23-26-52-43.5T538-708l-14.413-112H436l-14 112q-34 7-63.5 24T306-642l-106-46-40 72 94 69q-4 17-6.5 33.5T245-480q0 17.391 2.5 33.696Q250-430 254-413l-94 69 40 72 106-46q27 27 59.5 45t70.5 24.447ZM306-318Zm174.463-292Q426-610 388-572q-38 38-38 92 0 23 7.5 44t21.5 38q9 12 22.5 14t24.5-6q10-8 11-20t-7-20q-10-9-15-22t-5-28q0-29.167 20.417-49.583Q450.833-550 480-550q14 0 26.5 5t22.5 15q8 8 19.625 6.289Q560.25-525.421 568-535q8-11 5.5-24T560-582q-16-14-36.5-21t-43.037-7ZM306-318Zm285 218h250q6 0 10.5-4.5T856-115v-8q0-4.571-1.5-8.381Q853-135.19 850-139q-25.379-27.915-59.386-44.458Q756.606-200 716-200q-40.597 0-74.597 16.525-34 16.524-59.373 44.409Q580-137 576-123v8q0 6 4.5 10.5T591-100Z"
		/>
	</svg>
);
