import { t } from '@transifex/native';

import { formatPriceIntervalToText } from 'pkg/utils';
import { formatToCurrency } from 'pkg/i18n/formatters';
import { PaymentPreview } from 'pkg/api/models/payment_preview';

import { getIntervalText } from 'routes/forms/registration/form/products/summary';

import SelectableCard from 'components/form/selectablecard';

interface Props {
	bundle: PaymentPreview;
	currency: string;
	isActive: boolean;
	onClick: (bundleNumber: number) => void;
	testid?: string;
}

const Bundle = ({ bundle, currency, isActive, onClick, testid }: Props) => {
	const title = formatPriceIntervalToText(
		bundle.recurringInterval,
		bundle.recurringIntervalCount,
		true
	);

	const intervalText = t('Then {interval} {recurringAmount}', {
		interval: getIntervalText(
			bundle.recurringInterval,
			bundle.recurringIntervalCount
		),
		recurringAmount: formatToCurrency(
			bundle.recurringPayment.salePrice / 100,
			currency
		),
	});

	const handleBundleClick = () => onClick(bundle.bundleNumber);

	const isSubscription =
		bundle.recurringIntervalCount != 0 && !bundle.totalInstallmentAmount;

	let totalAmount;
	if (isSubscription) {
		totalAmount = `${getIntervalText(
			bundle.recurringInterval,
			bundle.recurringIntervalCount
		)} ${formatToCurrency(bundle.recurringPayment.salePrice / 100, currency)}`;
	} else if (bundle.recurringIntervalCount) {
		totalAmount = formatToCurrency(
			bundle.totalInstallmentAmount / 100,
			currency
		);
	} else {
		totalAmount = formatToCurrency(
			bundle.initialPayment.salePrice / 100,
			currency
		);
	}

	const summaryTitle = isSubscription
		? totalAmount
		: t('Total {total}', { total: totalAmount });

	return (
		<SelectableCard
			key={bundle.bundleNumber}
			onClick={handleBundleClick}
			active={isActive}
			title={title}
			description={
				bundle.recurringInterval && bundle.installmentCount
					? t('{nr} Installments', {
							nr: bundle.installmentCount,
						})
					: ''
			}
			infoTitle={t('Due today {cost}', {
				cost: formatToCurrency(bundle.initialPayment.salePrice / 100, currency),
			})}
			infoDescription={
				!isSubscription && bundle.recurringIntervalCount != 0 && intervalText
			}
			summaryTitle={summaryTitle}
			testid={testid}
		/>
	);
};

export default Bundle;
