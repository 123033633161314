import { ReactNode } from 'react';

export const symbol_flight_takeoff: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M810-120H150q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-180h660q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-120ZM194-449l226-61-154-259q-7-11-2-22t17-15l12-4q7-2 13.48-.4T318-804l274 247 216-58q27-8 49 9.455 22 17.454 22 46.545 0 19.161-11.531 33.968Q855.938-510.226 838-505L206-334q-10 3-19.5-1T172-348L93-482q-5-8-1-15.5t13.176-9.167l10.589-2.5q4.235-.833 7.568.238 3.334 1.072 6.667 3.929l64 56Z"
		/>
	</svg>
);
