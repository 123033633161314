import { ReactNode } from 'react';

export const symbol_safety_check: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-288q79 0 136-57t57-136q0-79-57-136t-136-57q-79 0-136 57t-57 136q0 79 57 136t136 57Zm17-195 69 63q6 5 6.5 13t-5.5 14q-5 5-12.5 5.5T541-392l-76-70q-5-4.552-7.5-10.241-2.5-5.69-2.5-11.759v-102.03q0-8.97 6-14.97t15-6q9 0 15 6t6 14.96V-483ZM480-84q-5.32 0-9.88-1-4.56-1-9.12-3-139-47-220-168.5t-81-266.606V-719q0-19.257 10.875-34.662Q181.75-769.068 199-776l260-97q11-4 21-4t21 4l260 97q17.25 6.932 28.125 22.338Q800-738.257 800-719v195.894Q800-378 719-256.5T499-88q-4.56 2-9.12 3T480-84Zm0-59q115-38 187.5-143.5T740-523v-196l-260-98-260 98v196q0 131 72.5 236.5T480-143Zm0-337Z"
		/>
	</svg>
);
