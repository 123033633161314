import { ReactNode } from 'react';

export const symbol_videocam_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m720-525 134-134q7-7 16.5-3.458Q880-658.917 880-649v338q0 9.917-9.5 13.458Q861-294 854-301L720-435v45q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T660-390v-350H306q-15 0-22.5-9.318t-7.5-20.5q0-11.182 7.5-20.682Q291-800 306-800h354q24 0 42 18t18 42v215ZM827-48 60-815q-9-9-9-21t9-21q9-9 21-9t21 9L869-90q9 9 9 21t-9 21q-9 9-21 9t-21-9ZM484-560Zm-87 82ZM159-800l60 60h-79v520h520v-79l60 60v19q0 24-18 42t-42 18H140q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h19Z"
		/>
	</svg>
);
