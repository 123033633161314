import { createSelector } from 'reselect';

import * as multisort from 'pkg/multisort';

import * as sessionBlocks from './session_blocks';

const getSessionItemEntities = (state) => state.sessionItems.get('entities');

const getSessionItemModes = (state) => state.sessionItems.get('modes');

export const findAllByBlock = createSelector(
	getSessionItemEntities,
	(_, { sessionId }) => sessionId,
	(_, { blockId }) => blockId,
	(entities, sessionId, blockId) =>
		entities
			.filter((item) => item.get('trainingSessionBlockId') === blockId)
			.sort(multisort.asc('order'))
			.toList()
);

export const findAllBySession = createSelector(
	getSessionItemEntities,
	(state, props) =>
		sessionBlocks
			.findAll(state, props)
			.toList()
			.map((b) => b.id),
	(entities, blockIds) =>
		entities
			.filter((item) => blockIds.includes(item.get('trainingSessionBlockId')))
			.sort(multisort.asc('trainingSessionBlockId'))
			.toList()
);

export const hasChanges = createSelector(getSessionItemModes, (modes) =>
	modes
		.entrySeq()
		.find(([, mode]) => mode.get('hasChanges') && !mode.get('isNew'))
);
