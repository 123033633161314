import { useSelector } from 'react-redux';
import { t } from '@transifex/native';

import { Features } from 'pkg/models/group';

import * as selectors from 'pkg/selectors';
import { RootState } from 'pkg/reducers';
import { FilterGroups, useFilters } from 'pkg/filters/use_filters';
import useFieldsFilter from 'pkg/hooks/useUserFieldsFilter';
import * as routes from 'pkg/router/routes';
import { useCurrentGroup, useCurrentOrganization } from 'pkg/identity';
import * as models from 'pkg/api/models';

import ContactTableView from 'routes/organization/contacts/list/Table';
import {
	usersFilterConfig,
	getFilters,
} from 'routes/organization/contacts/utils';

import AssetImage from 'components/AssetImage';

import { MissingEntitiesWrapper } from 'components/missing-entities/Wrapper';

import Button from 'design/button';
import BlockPlaceholder from 'design/placeholders/block';

interface ContactsProps {
	organizationId: number;
}
const Contacts: React.FC<React.PropsWithChildren<ContactsProps>> = ({
	organizationId,
}) => {
	const org = useCurrentOrganization();
	const group = useCurrentGroup();
	const userFieldsFilter = useFieldsFilter();

	const filterGroups: FilterGroups = {
		...getFilters([
			usersFilterConfig().groups,
			usersFilterConfig().group_role,
			usersFilterConfig().sex,
			usersFilterConfig().inactivated,
			models.group.hasFeature(group, Features.Payments) &&
				usersFilterConfig().products,
			models.group.hasFeature(group, Features.Payments) &&
				usersFilterConfig().registrations,
			models.group.hasFeature(group, Features.Payments) &&
				usersFilterConfig().product_status,
			models.group.hasFeature(group, Features.Payments) &&
				usersFilterConfig().product_valid,
			models.group.hasFeature(group, Features.Payments) &&
				usersFilterConfig().product_created,
			usersFilterConfig().account,
			usersFilterConfig().user_created,
			usersFilterConfig().birth_date,
			usersFilterConfig().parent_connection,
			usersFilterConfig().child_connection,
			usersFilterConfig().archived,
			usersFilterConfig().required_information,
			models.group.isLOKActive(org) && usersFilterConfig().lok_data,
		]),
		...userFieldsFilter,
	};

	const filters = useFilters({
		groups: filterGroups,
		filterBarMobileTrigger: true,
	});

	const allContacts = useSelector((state: RootState) =>
		selectors.users.findAllByOrganizationId(state, organizationId)
	);

	if (allContacts.size === 0) {
		return (
			<MissingEntitiesWrapper>
				<BlockPlaceholder
					title={t('There are no contacts yet')}
					description={t('Create a new contact to get started')}
					actions={
						<Button
							secondary
							href={routes.Management.Contact.Create(org.id)}
							icon="add">
							{t('New contact')}
						</Button>
					}
					image={<AssetImage src="img/missing-entities/contacts.svg" />}
				/>
			</MissingEntitiesWrapper>
		);
	}

	return (
		<ContactTableView
			organizationId={organizationId}
			filters={filters}
			queryParams={filters.queryParam}
		/>
	);
};

export default Contacts;
