import { ReactNode } from 'react';

export const symbol_screenshot_monitor: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M720-360H620q-8 0-14 6t-6 14q0 8 6 14t14 6h110q12.75 0 21.375-8.625T760-350v-110q0-8-6-14t-14-6q-8 0-14 6t-6 14v100ZM240-680h100q8 0 14-6t6-14q0-8-6-14t-14-6H230q-12.75 0-21.375 8.625T200-690v110q0 8 6 14t14 6q8 0 14-6t6-14v-100ZM140-200q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H630v50q0 12.75-8.625 21.375T600-120H360q-12.75 0-21.375-8.625T330-150v-50H140Zm0-60h680v-520H140v520Zm0 0v-520 520Z"
		/>
	</svg>
);
