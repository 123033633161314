import { ReactNode } from 'react';

export const symbol_motion_sensor_alert: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-160q-24 0-42-18t-18-42v-142q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-362v142h142q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T282-160H140ZM80-758v-142q0-24 18-42t42-18h142q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T282-900H140v142q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Q97-728 88.5-736.625T80-758Zm399.567 268Q451-490 430.5-510.562 410-531.125 410-560q0-29 20.562-49.5Q451.125-630 480-630q29 0 49.5 20.5t20.5 49.933Q550-531 529.5-510.5T479.567-490ZM820-758v-142H678q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T678-960h142q24 0 42 18t18 42v142q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T820-758ZM234-526q-13.737 0-21.368 12.5Q205-501 209-487q19 75 73 128.5T411-286q14 4 26-3.5t12-21.894Q449-322 441.5-331q-7.5-9-18.5-12-58-15-100-57t-57-100q-3-11-11.841-18.5Q245.317-526 234-526Zm1-60q-13.737 0-21.368-12.5Q206-611 210-625q19-75 73-128.5T412-826q14-4 26 3.5t12 21.894Q450-790 442.5-781q-7.5 9-18.5 12-58 15-100 57t-57 100q-3 11-11.841 18.5Q246.317-586 235-586Zm491 0q13.737 0 21.368-12.5Q755-611 751-625q-19-75-73-128.5T549-826q-14-4-26 3.5t-12 21.894Q511-790 518.5-781q7.5 9 18.5 12 58 15 100 57t57 100q3 11 11.841 18.5Q714.683-586 726-586Zm3.5 466Q650-120 594-175.867 538-231.735 538-311q0-80 55.867-135.5Q649.735-502 729-502q80 0 135.5 55.5t55.5 135q0 79.5-55.5 135.5t-135 56Zm2-76q9.5 0 16-6t6.5-16q0-10-6.6-16t-15.4-6q-10 0-16 6t-6 16q0 10 6 16t15.5 6Zm.5-85q8 0 12.5-5t4.5-13v-108q0-8-5-12.5t-13-4.5q-8 0-12.5 5t-4.5 13v108q0 8 5 12.5t13 4.5Z"
		/>
	</svg>
);
