import { ReactNode } from 'react';

export const symbol_no_luggage: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m595-451-60-60v-59q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T595-570v119ZM300-80q-17 0-28.5-11.5T260-120q-24.75 0-42.375-17.625T200-180v-480q0-20 12.5-35t32.5-20l55 55h-40v480h440v-80l60 60v20q0 24.75-17.625 42.375T700-120q0 17-11.5 28.5T660-80q-17 0-28.5-11.5T620-120H340q0 17-11.5 28.5T300-80Zm65-472 60 60v222q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T365-270v-282Zm170 170 60 60v52q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T535-270v-112ZM854.947-63q-9.052 9-21.5 9Q821-54 812-63L47-827q-9-9.067-9-21.533Q38-861 47.053-870q9.052-9 21.5-9Q81-879 90-870l765 765q9 9 9 21t-9.053 21ZM477-397Zm118-323h105q24.75 0 42.375 17.625T760-660v312q0 15-9.318 22.5t-20.5 7.5Q719-318 709.5-325.683 700-333.366 700-348v-312H425q-24.75 0-42.375-17.625T365-720v-100q0-24.75 17.625-42.375T425-880h110q24.75 0 42.375 17.625T595-820v100Zm-170 0h110v-100H425v100Zm118 217Z"
		/>
	</svg>
);
