const icon = {
	name: 'drawing-rectangle-dashed',
	objects: [
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M4.78326 4.78326H6.08326V5.68536V6.08326H5.68536H4.78326V4.78326ZM4.78326 17.9168V19.2168H6.08326V18.3147V17.9168H5.68536H4.78326ZM17.9168 19.2168H19.2168V17.9168H18.3147H17.9168V18.3147V19.2168ZM19.2168 6.08326V4.78326H17.9168V5.68536V6.08326H18.3147H19.2168ZM7.48954 4.78326V6.08326H9.29373V4.78326H7.48954ZM11.0979 4.78326V6.08326H12.9021V4.78326H11.0979ZM14.7063 4.78326V6.08326H16.5105V4.78326H14.7063ZM19.2168 7.48954H17.9168V9.29373H19.2168V7.48954ZM19.2168 11.0979H17.9168V12.9021H19.2168V11.0979ZM19.2168 14.7063H17.9168V16.5105H19.2168V14.7063ZM16.5105 19.2168V17.9168H14.7063V19.2168H16.5105ZM12.9021 19.2168V17.9168H11.0979V19.2168H12.9021ZM9.29373 19.2168V17.9168H7.48955V19.2168H9.29373ZM4.78326 16.5105H6.08326V14.7063H4.78326V16.5105ZM4.78326 12.9021H6.08326V11.0979H4.78326V12.9021ZM4.78326 9.29373H6.08326V7.48955H4.78326V9.29373Z',
				fill: 'currentColor',
			},
		},
	],
};

export default icon;
