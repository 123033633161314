import { t } from '@transifex/native';
import { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { getCurrentSeason } from 'pkg/selectors/groups';
import {
	getAverageGoals,
	getLastMatch,
	getWinPercentage,
	getWins,
} from 'pkg/selectors/matches.selector';

import { fetchMatches } from 'pkg/actions/matches_deprecated';

import * as routes from 'pkg/router/routes';
import DateTime from 'pkg/datetime';
import { AccountIdentityContext } from 'pkg/identity/account';

import PhysicalStrain from 'routes/dashboard/components/PhysicalStrain';
import TeamStatsItem from 'routes/dashboard/components/TeamStatsItem';

import SectionTitle from 'components/SectionTitle';

import Column from 'components/layout/column';

const Wrapper = styled.div`
	display: grid;
	grid-gap: var(--spacing-4);
	padding: var(--spacing-5) 0;
	margin: calc(var(--spacing-5) * -1) 0;
	grid-template-columns: 1fr 1fr;
`;

class TeamStats extends Component {
	static contextType = AccountIdentityContext;

	componentDidMount() {
		if (this.props.groupId) {
			this.fetchMatches();
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.groupId !== this.props.groupId) {
			this.fetchMatches();
		}
	}

	fetchMatches = () => {
		this.props.fetchMatches({
			group_id: this.props.groupId,
			before: DateTime.now().getUnixTimestamp(),
			after: this.props.currentSeason.start,
		});
	};

	get matchStatisticsLink() {
		return routes.Statistics.Match(
			this.context.organization.id,
			this.props.groupId
		);
	}
	get scoredGoals() {
		const { averageGoals, averageGoalsTrend } = this.props.averageGoals;
		const { averageGoals: seasonAverageGoals } = this.props.seasonAverageGoals;

		let data = {};

		data.linkHref = this.matchStatisticsLink;
		data.title = t(`Scored goals`);
		data.timeSpan = t(`Last 3 games`);
		data.value = averageGoals;

		if (seasonAverageGoals) {
			data.description = t(`Season avg. {averageGoals}`, {
				averageGoals: seasonAverageGoals,
			});
		} else {
			data.description = t(`Register at least 3 games`);
		}

		if (averageGoalsTrend) {
			data.showArrow = true;

			if (averageGoals > averageGoalsTrend) {
				data.trend = 'up';
			} else if (averageGoals < averageGoalsTrend) {
				data.trend = 'down';
			} else {
				data.trend = 'flat';
			}
		} else {
			data.trend = 'none';
		}

		return <TeamStatsItem data={data} />;
	}

	get concededGoals() {
		const { averageGoals, averageGoalsTrend } = this.props.averageGoalsConceded;
		const { averageGoals: seasonAverageGoals } =
			this.props.seasonAverageGoalsConceded;

		let data = {};

		data.linkHref = this.matchStatisticsLink;
		data.title = t(`Conceded goals`);
		data.timeSpan = t(`Last 3 games`);
		data.value = averageGoals;
		data.reverseTrendArrow = true;

		if (seasonAverageGoals) {
			data.description = t(`Season avg. {averageGoals}`, {
				averageGoals: seasonAverageGoals,
			});
		} else {
			data.description = t(`Register at least 3 games`);
		}

		if (averageGoalsTrend) {
			data.showArrow = true;

			if (averageGoals > averageGoalsTrend) {
				data.trend = 'down';
			} else if (averageGoals < averageGoalsTrend) {
				data.trend = 'up';
			} else {
				data.trend = 'flat';
			}
		} else {
			data.trend = 'none';
		}

		return <TeamStatsItem data={data} />;
	}

	get matchWins() {
		const { winPercentage, wins, lastMatch } = this.props;
		let data = {};

		data.linkHref = this.matchStatisticsLink;
		data.title = t(`Match wins`);
		data.timeSpan = t(`Season`);
		data.value = winPercentage ? `${winPercentage}%` : '0';

		if (wins) {
			data.showArrow = true;
			data.description = t(`Total {wins} wins`, {
				wins: wins,
			});

			if (lastMatch?.goalCount > lastMatch?.opponentGoalCount) {
				data.trend = 'up';
			} else {
				data.trend = 'down';
			}
		} else {
			data.description = t(`Register at least one game`);

			data.trend = 'none';
		}

		return <TeamStatsItem data={data} />;
	}

	render = () => (
		<Column>
			<SectionTitle icon="bar_chart">{t(`Team stats`)}</SectionTitle>
			<Wrapper>
				{this.scoredGoals}
				{this.concededGoals}
				{this.matchWins}
				<PhysicalStrain groupId={this.props.groupId} />
			</Wrapper>
		</Column>
	);
}

const mapStateToProps = (state, props) => {
	const currentSeason = getCurrentSeason(state, { groupId: props.groupId });
	const dateNow = DateTime.now().getUnixTimestamp();

	const ownProps = {
		groupId: props.groupId,
		before: dateNow,
		after: currentSeason.start,
	};

	return {
		currentSeason,
		groupId: props.groupId,
		averageGoals: getAverageGoals(state, {
			goalType: 'goalCount',
			numberOfMatches: 3,
			...ownProps,
		}),
		averageGoalsConceded: getAverageGoals(state, {
			goalType: 'opponentGoalCount',
			numberOfMatches: 3,
			...ownProps,
		}),
		seasonAverageGoals: getAverageGoals(state, {
			goalType: 'goalCount',
			...ownProps,
		}),
		seasonAverageGoalsConceded: getAverageGoals(state, {
			goalType: 'opponentGoalCount',
			...ownProps,
		}),
		winPercentage: getWinPercentage(state, ownProps),
		lastMatch: getLastMatch(state, { withGoalData: true, ...ownProps }),
		wins: getWins(state, ownProps),
	};
};

const actions = {
	fetchMatches,
};

export default connect(mapStateToProps, actions)(TeamStats);
