const icon = {
	name: 'bus',
	objects: [
		{
			type: 'path',
			attributes: {
				d: 'M7.43883 16.3649C7.99018 16.3649 8.43715 15.9179 8.43715 15.3666C8.43715 14.8152 7.99018 14.3682 7.43883 14.3682C6.88747 14.3682 6.4405 14.8152 6.4405 15.3666C6.4405 15.9179 6.88747 16.3649 7.43883 16.3649ZM7.43883 17.6649C8.70815 17.6649 9.73715 16.6359 9.73715 15.3666C9.73715 14.0972 8.70815 13.0682 7.43883 13.0682C6.1695 13.0682 5.1405 14.0972 5.1405 15.3666C5.1405 16.6359 6.1695 17.6649 7.43883 17.6649Z',
				fillRule: 'evenodd',
				clipRule: 'evenodd',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M16.1395 16.3649C16.6909 16.3649 17.1378 15.9179 17.1378 15.3666C17.1378 14.8152 16.6909 14.3682 16.1395 14.3682C15.5882 14.3682 15.1412 14.8152 15.1412 15.3666C15.1412 15.9179 15.5882 16.3649 16.1395 16.3649ZM16.1395 17.6649C17.4088 17.6649 18.4378 16.6359 18.4378 15.3666C18.4378 14.0972 17.4088 13.0682 16.1395 13.0682C14.8702 13.0682 13.8412 14.0972 13.8412 15.3666C13.8412 16.6359 14.8702 17.6649 16.1395 17.6649Z',
				fillRule: 'evenodd',
				clipRule: 'evenodd',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M4.39949 14.6935V7.63514H19.6005V14.6935H17.9456V15.9935H19.6965C20.3615 15.9935 20.9005 15.4545 20.9005 14.7895V7.53913C20.9005 6.87419 20.3615 6.33514 19.6965 6.33514H4.30348C3.63853 6.33514 3.09949 6.87419 3.09949 7.53913V14.7895C3.09949 15.4545 3.63853 15.9935 4.30348 15.9935H5.30481V14.6935H4.39949',
				fillRule: 'evenodd',
				clipRule: 'evenodd',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M4.37 9.86432H16.9606V11.1643H4.37V9.86432Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M17.3043 9.86432L20.7347 12.2161L19.9996 13.2883L16.5692 10.9365L17.3043 9.86432Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M8.72644 14.7166H14.6622V16.0166H8.72644V14.7166Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M17.4393 6.86749V13.6952H16.1393V6.86749L17.4393 6.86749Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M13.0829 6.86749V11.1643H11.7829V6.86749L13.0829 6.86749Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M8.72644 6.86749V11.1643H7.42644V6.86749L8.72644 6.86749Z',
			},
		},
	],
};

export default icon;
