import { ReactNode } from 'react';

export const symbol_control_point_duplicate: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-170Q99-216 49.5-298.688 0-381.376 0-481q0-100 50-182t131-128q20-11 36-2.5t16 28.5q0 6.216-3.818 13.054T219-742q-73 38-116 108.101Q60-563.797 60-481q0 84 43 154t116 108q6.364 3.026 10.182 9.684Q233-202.658 233-196q0 20-16 29t-37-3Zm420 49q-75 0-140.5-28.5t-114-77q-48.5-48.5-77-114T240-481q0-75 28.5-140.5t77-114q48.5-48.5 114-77T600-841q75 0 140.5 28.5t114 77q48.5 48.5 77 114T960-481q0 75-28.5 140.5t-77 114q-48.5 48.5-114 77T600-121Zm0-360Zm.5 300q124.5 0 212-87.321Q900-355.643 900-481q0-124-87.321-212Q725.357-781 600-781q-124 0-212 88t-88 212q0 125.357 88 212.679Q476-181 600.5-181Zm.675-147q12.825 0 21.325-8.625T631-358v-94h93q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T724-512h-93v-93q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T571-605v93h-94q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T477-452h94v94q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625Z"
		/>
	</svg>
);
