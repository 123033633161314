import { memo } from 'react';
import styled, { css } from 'styled-components';

import * as styles from 'pkg/config/styles';

const borderRadius = 6;

const Options = styled.div<{ disabled: boolean }>`
	display: grid;
	gap: 1px;
	padding: 1px;
	grid-auto-flow: column;
	grid-auto-columns: 1fr;
	border-radius: ${borderRadius}px;
	background-color: var(--palette-gray-400);

	@media ${styles.breakpoint.small} {
		grid-auto-flow: row;
	}

	${({ disabled }) =>
		disabled &&
		css`
			opacity: 0.5;
		`}
`;

interface ItemProps {
	active?: boolean;
	disabled?: boolean;
}

const Item = styled.div<ItemProps>`
	background-color: var(--palette-white);
	padding: var(--spacing-4) var(--spacing-5);
	text-align: center;
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	transition: all 150ms ease-in-out;
	display: grid;
	align-items: center;
	align-content: center;

	@media ${styles.breakpoint.small} {
		text-align: left;
	}

	& > div {
		font-size: var(--font-size-base);
		font-weight: var(--font-weight-semibold);
		margin-bottom: var(--spacing-2);
	}

	& > p {
		color: var(--palette-gray-500);
		font-size: var(--font-size-sm);
		line-height: var(--font-line-height-sm);
	}

	&:first-child {
		border-radius: ${borderRadius}px 0 0 ${borderRadius}px;

		@media ${styles.breakpoint.small} {
			border-radius: ${borderRadius}px ${borderRadius}px 0 0;
		}
	}

	&:last-child {
		border-radius: 0 ${borderRadius}px ${borderRadius}px 0;

		@media ${styles.breakpoint.small} {
			border-radius: 0 0 ${borderRadius}px ${borderRadius}px;
		}
	}

	${({ active }) =>
		active &&
		css`
			background-color: var(--palette-blue-200);
			box-shadow: 0 0 0 1px var(--palette-blue-500);
		`};
`;

interface IOption {
	key: string | number;
	title: string;
	description?: string;
}

interface ISingleChoice {
	onChange?: (key: string | number) => void;
	value?: string | number;
	options?: IOption[];
	disabled?: boolean;
	testid?: string;
}

const SingleChoice = memo(
	({
		onChange,
		options,
		value,
		disabled,
		testid,
	}: ISingleChoice): JSX.Element => (
		<Options disabled={disabled} data-testid={testid}>
			{options.map((option) => (
				<Item
					disabled={disabled}
					onClick={() => onChange(option.key)}
					active={value === option.key}
					data-testid={`single_choice.${option.key}`}
					key={option.key}>
					<div>{option.title}</div>
					{option.description && <p>{option.description}</p>}
				</Item>
			))}
		</Options>
	)
);

export default SingleChoice;
