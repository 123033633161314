import { Fragment, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as styles from 'pkg/config/styles';

import * as actions from 'pkg/actions';

import * as Input from 'components/form/inputs';
import Row from 'components/layout/row';

import * as Table from 'design/table';

const TraverseRow = ({
	group,
	disabledGroups,
	selected,
	select,
	level = 0,
	singleSelect,
}) => {
	const dispatch = useDispatch();

	const expand = selected.includes(group.id);
	const [isExpanded, setIsExpanded] = useState(true);
	const [isFetched, setIsFetched] = useState(false);

	const isSelected = selected.includes(group.id);

	const handleTraverse = async () => {
		if (!isFetched) {
			await dispatch(
				actions.groups.fetchChildGroups(group.id, {
					include_sub_group_count: true,
				})
			);
			setIsFetched(true);
		}

		setIsExpanded((prev) => !prev);
	};

	useEffect(() => {
		if (expand) {
			setIsExpanded(true);
		}
	}, [expand]);

	useEffect(() => {
		if (level === 0) {
			dispatch(
				actions.groups.fetchChildGroups(group.id, {
					include_sub_group_count: true,
					recursive: true,
				})
			);

			setIsExpanded(true);
			setIsFetched(group.id);
		}
	}, [level, dispatch, group.id]);

	const handleSelect = (e) => {
		e.stopPropagation();
		e.preventDefault();

		if (disabledGroups.includes(group.id)) {
			return;
		}

		select(group);
	};

	const userAlreadyInGroup = disabledGroups.includes(group.id);

	let childGroups =
		group.children.size > 0 ? group.children.toList().toArray() : [];

	const hasUnsortedGroups = childGroups.some((group) => group.order === -1);

	if (hasUnsortedGroups) {
		childGroups = childGroups.sort((a, b) => a.name.localeCompare(b.name));
	} else {
		childGroups = childGroups.sort((a, b) => a.order - b.order);
	}

	const input = singleSelect ? (
		<Input.Control
			standalone
			type="radio"
			checked={isSelected}
			disabled={disabledGroups.includes(group.id)}
		/>
	) : (
		<Input.Control
			type="checkbox"
			checked={isSelected || userAlreadyInGroup}
			disabled={disabledGroups.includes(group.id)}
			id={`select-${group.name}`}
			standalone
		/>
	);

	return (
		<Fragment>
			<Table.ExpandableRow
				key={group.id}
				canExpand={group.canShowChildren || group.children.size > 0}
				isExpanded={isExpanded}
				level={level}
				onClick={handleTraverse}>
				<Table.Cell>
					<Row spacing={styles.spacing._2} autoColumns="auto" align="center">
						<div onClick={handleSelect}>{input}</div>
						<span onClick={handleTraverse}>{group.name}</span>
					</Row>
				</Table.Cell>
			</Table.ExpandableRow>
			{isExpanded && group.children.size > 0
				? childGroups.map((child) => (
						<TraverseRow
							key={child.id}
							group={child}
							disabledGroups={disabledGroups}
							level={level + 1}
							selected={selected}
							select={select}
							singleSelect={singleSelect}
						/>
					))
				: null}
		</Fragment>
	);
};

export default TraverseRow;
