export enum Slugs {
	LeftWing = 'lw',
	Striker = 'st',
	CenterForward = 'cf',
	RightWing = 'rw',
	LeftMidfielder = 'lm',
	CenterAttackingMidfielder = 'cam',
	CenterMidfielder = 'cm',
	RightMidfielder = 'rm',
	LeftBack = 'lb',
	CenterDefensiveMidfielder = 'cdm',
	CenterBack = 'cb',
	RightBack = 'rb',
	GoalKeeper = 'gk',
}

export interface Position {
	description: string;
	id: number;
	idealUserRatingId: number;
	longName: string;
	name: string;
	slug: Slugs;
}
