import { ReactNode } from 'react';

export const symbol_transportation: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M770-319q-72 0-122.5-44.5T583-478H443q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T443-538h140q3-27 15-53.5t31-47.5H347q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T347-699h317l-55-161H470q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T470-920h139q20.091 0 35.545 11.5Q660-897 666-879l61 180h33q87 0 143.5 52T960-509q0 78-56 134t-134 56Zm0-60q52 0 91-39t39-91q0-56-37-93t-92.882-37q-1.118 0-9.618.5t-9.5.5l30 82q5 11-.5 22T763-518q-11 4-22-1.5T725-536l-31-83q-27 23-40.5 49.5T640-509q0 54.167 37.917 92.083Q715.833-379 770-379ZM280-40q-45.417 0-77.208-31.792Q171-103.583 171-149v-11H30q-12.75 0-21.375-8.675Q0-177.351 0-190.175 0-203 8.625-211.5T30-220h167q13-15 34-26.5t49-11.5q25 0 45.5 10t37.5 28h127v-120H30q-12.75 0-21.375-8.675Q0-357.351 0-370.175 0-383 8.625-391.5T30-400h60v-120H30q-12.75 0-21.375-8.675Q0-537.351 0-550.175 0-563 8.625-571.5T30-580h235q8.171 0 15.229 3.947Q287.286-572.105 291-565l99 165h100q24.75 0 42.375 17.625T550-340v120q0 24.75-17.625 42.375T490-160H389v11q0 45.417-31.792 77.208Q325.417-40 280-40ZM150-400h170l-72-120h-98v120Zm130 300q20 0 34.5-14.5T329-149q0-20-14.5-34.5T280-198q-20 0-34.5 14.5T231-149q0 20 14.5 34.5T280-100Zm-35-180Z"
		/>
	</svg>
);
