import { t } from '@transifex/native';
import { Fragment } from 'react';

import { palette } from 'pkg/config/styles';

import {
	useLocalizedCurrencyFormatter,
	useOrderReport,
} from 'routes/payments/overview/hooks';
import { Widget } from 'routes/payments/overview/widgets';

import * as DataCard from 'design/data_card';
import * as Chart from 'design/chart';

export default function OrderStatusWidget(): JSX.Element {
	const { createdInvoicesGraph } = useOrderReport();
	const { paid, open, pastDue, uncollectible } = createdInvoicesGraph.statuses;
	const fmt = useLocalizedCurrencyFormatter();

	const chartData: Chart.ChartItem[] = [
		{
			title: t('Paid'),
			value: paid.count,
			fillColor: palette.green[200],
			textColor: palette.green[600],
			metadata: {
				amount: paid.amount,
			},
		},
		{
			title: t('Open'),
			value: open.count,
			fillColor: palette.blue[200],
			textColor: palette.blue[500],
			metadata: {
				amount: open.amount,
			},
		},
		{
			title: t('Past due'),
			value: pastDue.count,
			fillColor: palette.red[200],
			textColor: palette.red[600],
			metadata: {
				amount: pastDue.amount,
			},
		},
		{
			title: t('Uncollectible'),
			value: uncollectible.count,
			fillColor: palette.orange[100],
			textColor: palette.orange[600],
			metadata: {
				amount: uncollectible.amount,
			},
		},
	];

	const tooltipFormatter = (item: Chart.ChartItem, itemTotal: number) => {
		return (
			<Fragment>
				<strong>
					{t('{num} of {total} ({percent}%)', {
						num: item.value,
						total: itemTotal,
						percent: ((item.value / itemTotal) * 100).toFixed(1),
					})}
				</strong>
				<span>{fmt(item.metadata.amount)}</span>
			</Fragment>
		);
	};

	return (
		<Widget>
			<DataCard.Base titleIcon="check_circle" title={t('Invoice status')}>
				<Chart.Donut
					items={chartData}
					formatTooltip={tooltipFormatter}
					emptyStateLabel={t('No data for selected period')}
				/>
			</DataCard.Base>
		</Widget>
	);
}
