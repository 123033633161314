import { ReactNode } from 'react';

export const symbol_macro_auto: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-404q-40 0-66-26t-26-66v-9q-10 8-22 13.5t-28 5.5q-40 0-66-26t-26-66q0-27 15-50.5t39-33.5q-24-10-39-33.5T246-746q0-40 26-66t66-26q16 0 28 5t22 14v-9q0-40 26-66t66-26q40 0 66 26t26 66v9q9-8 20.5-13t26.5-6l-44 114q-15-23-39.5-36.5T480-774q-48 0-80 32t-32 80q0 48 32 80t80 32q11 0 21-2t19-5q5 15 18 25.5t31 10.5h126q-12 17-31 26t-42 9q-16 0-28-5.5T572-505v9q0 40-26 66t-66 26Zm205-282-34 87q-3 8-10 13t-16 5q-15 0-23-12.5t-3-26.5l121-313q3-8 9.5-12t14.5-4h20q8 0 14.5 4t9.5 12l123 313q5 14-3.5 26.5T884-581q-9 0-16-5t-10-13l-34-87H685Zm18-52h103l-47-131h-9l-47 131ZM555-155q80-26 133-79t79-133q-80 26-133 79.5T555-155Zm-75 75q-131 0-235.5-96T123-400q-2-17 9-28t28-9q128 17 224 121.5T480-80Zm-75-75q-26-79-79-132.5T193-367q26 80 79 133t133 79Zm150 0Zm-150 0Zm75 75q0-131 96-235.5T800-437q17-2 28 9t9 28q-17 128-121.5 224T480-80Z"
		/>
	</svg>
);
