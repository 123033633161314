import { t } from '@transifex/native';
import { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { T } from '@transifex/react';

import * as styles from 'pkg/config/styles';

import * as actions from 'pkg/actions';
import * as models from 'pkg/api/models';
import useConfirm from 'pkg/hooks/useConfirm';
import { useCurrentAccount } from 'pkg/identity';

import AccountConnection from 'routes/account/settings/child-relations/AccountConnection';

import * as Card from 'components/Card';
import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import Column from 'components/layout/column';
import Row from 'components/layout/row';

import Button from 'design/button';

interface IncomingRelationItemProps {
	account?: models.account.Account;
	relation: models.accountRelation.AccountRelation;
	refreshRelations: () => Promise<void>;
}

function IncomingRelationItem({
	account,
	relation,
	refreshRelations,
}: IncomingRelationItemProps): JSX.Element {
	const dispatch = useDispatch();
	const currentAccount = useCurrentAccount();

	const wardAccount = account ? account : relation.account;

	let guardianAccount = account ? relation.account : relation.targetAccount;

	if (!guardianAccount) {
		guardianAccount = currentAccount;
	}

	const userName = guardianAccount
		? models.account.fullName(guardianAccount)
		: '';

	const accept = async () => {
		// Await these so that refreshRelations update the view properly
		await dispatch(actions.accountRelations.approveRequest(relation.id));
		await refreshRelations();

		actions.flashes.show({
			title: t('Successfully saved!'),
			message: t('You and {userName} are now connected', {
				userName,
			}),
		});
	};

	const remove = useConfirm({
		message: t(
			'You are about to remove {userName} as a connection, this cannot be undone.',
			{ userName }
		),
		onConfirm: async () => {
			// Await these so that refreshRelations update the view properly
			await dispatch(actions.accountRelations.remove(relation.id));

			actions.flashes.show({
				title: t('Successfully saved!'),
				message: t('You are no longer connected to {userName}', {
					userName,
				}),
			});

			await refreshRelations();
		},
	});

	let label = (
		<T
			_str="{parent_name} wants to connect to you as a parent or legal guardian"
			parent_name={<strong>{models.account.fullName(guardianAccount)}</strong>}
		/>
	);

	if (account) {
		label = (
			<T
				_str="{parent_name} wants to connect to {child_name} as a parent or legal guardian"
				parent_name={
					<strong>{models.account.fullName(guardianAccount)}</strong>
				}
				child_name={<strong>{models.account.fullName(wardAccount)}</strong>}
			/>
		);
	}

	if (!account) return null;

	return (
		<Card.Base $noBorder>
			<Card.Body $narrowBody>
				<LargeScreen>
					<Row
						columns="auto 1fr auto"
						align="center"
						spacing={styles.spacing._7}>
						<AccountConnection a={guardianAccount} b={wardAccount} />
						<div>{label}</div>
						<Row>
							<Button caution onClick={remove}>
								{t('Remove')}
							</Button>
							<Button primary onClick={accept}>
								{t('Accept')}
							</Button>
						</Row>
					</Row>
				</LargeScreen>
				<SmallScreen>
					<Column spacing={styles.spacing._7}>
						<AccountConnection a={guardianAccount} b={wardAccount} />
						<div>{label}</div>
						<Row>
							<Button caution onClick={remove}>
								{t('Remove')}
							</Button>
							<Button primary onClick={accept}>
								{t('Accept')}
							</Button>
						</Row>
					</Column>
				</SmallScreen>
			</Card.Body>
		</Card.Base>
	);
}

interface IncomingRelationsProps {
	relations: models.accountRelation.AccountRelation[];
	refreshRelations: () => Promise<void>;
}

export default function IncomingRelations({
	relations,
	refreshRelations,
}: IncomingRelationsProps): JSX.Element {
	if (relations.length === 0) {
		return null;
	}

	return (
		<Fragment>
			{relations.map((relation: models.accountRelation.AccountRelation) => (
				<IncomingRelationItem
					key={relation.id}
					relation={relation}
					refreshRelations={refreshRelations}
				/>
			))}
		</Fragment>
	);
}
