import { ReactNode } from 'react';

export const symbol_emoji_symbols: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M403-820H150q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-880h253q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T403-820ZM247-718h-97q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-778h253q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T403-718h-97v154q0 12.325-8.272 20.662-8.272 8.338-20.5 8.338T256-544q-9-9-9-21v-153Zm321 601q-9 9-21.5 8.5T525-118q-9-9-9-21.5t9-21.5l271-270q9-9 21-9t21 9q9 9 9 21.5t-9 21.5L568-117Zm16-208q-23.4 0-38.7-15.3Q530-355.6 530-379q0-23.4 15.3-38.7Q560.6-433 584-433q23.4 0 38.7 15.3Q638-402.4 638-379q0 23.4-15.3 38.7Q607.4-325 584-325Zm202 207q-23.4 0-38.7-15.3Q732-148.6 732-172q0-23.4 15.3-38.7Q762.6-226 786-226q23.4 0 38.7 15.3Q840-195.4 840-172q0 23.4-15.3 38.7Q809.4-118 786-118ZM628-520q-38 0-64-27.5T538-610q0-38 26-64t64-26q13.161 0 23.581 3.5Q662-693 670-688v-163q0-12.325 8.338-20.662Q686.675-880 699-880h112q12.325 0 20.662 8.272 8.338 8.272 8.338 20.5T831.662-830q-8.337 9-20.662 9h-93v211q0 38-26 64t-64 26ZM211-80q-38 0-64.5-26.5T120-171q0-20 7-36.721 7-16.721 21-30.279l52-52-20-20q-14-14-21.5-31.5T151-376q0-38 26.5-64.5T242-467q38 0 64.5 26.5T333-376q0 17-5.5 34.5T308-310l-20 20 50 50 39-39q9-9 21-9t21.391 9Q428-270 428-258t-9 21l-42 42 33 33q8 7.822 8 19.911Q418-130 409.946-122q-8.053 8-20.5 8Q377-114 369-122l-39-38-51 51q-14 14-31 21.5T211-80Zm31-250 23.059-23.059Q270-358 271.5-362.8t1.5-12.8q0-14.4-8.5-22.9Q256-407 242-407t-22.5 8.525Q211-389.95 211-376q0 8 1.5 13t6.441 9.941L242-330Zm-31.182 190Q216-140 223-143t12-8l52-52-43-43-53 53q-5 4.636-7.5 10.818Q181-176 181-170q0 14 8 22t21.818 8Z"
		/>
	</svg>
);
