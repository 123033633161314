import { ReactNode } from 'react';

export const symbol_groups_2: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M393-480q-21 0-34.5-16t-9.5-36l15-90q8-43 40.5-70.5T480-720q44 0 76.5 27.5T597-622l15 90q4 20-9.5 36T568-480H393Zm18-60h139l-12-72q-4-21-20-34.5T480.5-660q-21.5 0-37.5 13.5T423-612l-12 72ZM139-408.903q-20 .903-34.85-7.897-14.85-8.8-19.35-27.28Q83-452 84.5-460t4.5-15.333q0 .916-1-3.667 0-1-8.8-21.059Q77-511 81-519.5q4-8.5 12-16.5l2-2q2-17 13.777-28 11.776-11 29.223-11 1 0 16.158 3l2.842-1q5-4 11.667-6.5 6.666-2.5 14.166-2.5Q192-584 200-580.5q8 3.5 12.302 9.625.861 0 1.291.437.43.438 1.291.438 12.046.875 21.081 7.438Q245-556 250-544.667q2 6.103 1.5 11.77-.5 5.666-2.5 10.897 0 2 1 3 6.067 6.151 9.533 13.621Q263-497.909 263-490q0 2-5 18.19-1 1.905 0 3.81 1 4 1 14 0 18-15.217 31.548-15.218 13.549-36.957 13.549H139ZM807.911-410Q779-410 758.5-430.69T738-480.435q0-10.565 3.5-20.065Q745-510 750-518l-25-22q-8-8-3-18t15.535-10h70.232q28.971 0 49.602 20.673Q878-526.654 878-497.624v17.594q0 29.03-20.589 49.53-20.588 20.5-49.5 20.5ZM0-270v-23q0-39.464 42-63.232T150.398-380q12.158 0 23.38.5T196-377.273q-8 17.273-12 34.842-4 17.57-4 37.431v65H30q-12.75 0-21.375-8.625T0-270Zm240 0v-35q0-65 66.5-105T480-450q108 0 174 40t66 105v35q0 12.75-8.625 21.375T690-240H270q-12.75 0-21.375-8.625T240-270Zm570-110q67.5 0 108.75 23.768T960-293v23q0 12.75-8.625 21.375T930-240H780v-65q0-19.861-3.5-37.431Q773-360 765-377.273q11-1.727 22.171-2.227 11.172-.5 22.829-.5Zm-330.2-10Q400-390 350-366q-50 24-50 61v5h360v-6q0-36-49.5-60t-130.7-24Zm.2 90Zm1-300Z"
		/>
	</svg>
);
