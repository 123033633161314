import { Map, Record } from 'immutable';

import ProductCategory from 'pkg/models/product_category';

import { ProductCategories } from 'pkg/actions/action-types';

interface IProductCategoryReducer {
	entities: Map<number, ProductCategory>;
}

class ProductCategoryReducer
	extends Record<IProductCategoryReducer>(
		{
			entities: Map<number, ProductCategory>(),
		},
		'productCategoriesReducer'
	)
	implements IProductCategoryReducer
{
	entities: Map<number, ProductCategory>;
}

const initialState = new ProductCategoryReducer({
	entities: Map<number, ProductCategory>(),
});

interface NormalizrPayload {
	result: number;
	entities: {
		productCategory: { [key: string]: ProductCategory };
	};
}

interface Action {
	type: string;
	payload?: NormalizrPayload;
	categoryId?: number;
}

const setItems = (
	state = initialState,
	action: Action
): ProductCategoryReducer => {
	const entities = Object.values(action.payload.entities.productCategory);

	entities.map((entity: ProductCategory) => {
		state = state.setIn(['entities', entity.id], new ProductCategory(entity));
	});

	return state;
};

const removeItem = (
	state = initialState,
	action: Action
): ProductCategoryReducer => {
	return state.updateIn(['entities'], (entities) => {
		return (entities as Map<number, ProductCategory>).filter(
			(item: ProductCategory) => item.get('id') !== action.categoryId
		);
	});
};

const productCategoriesReducer = (
	state = initialState,
	action: Action
): ProductCategoryReducer => {
	switch (action.type) {
		case ProductCategories.SET_ITEMS:
			return setItems(state, action);
		case ProductCategories.DELETE_ITEM:
			return removeItem(state, action);
		default:
			return state;
	}
};

export default productCategoriesReducer;
