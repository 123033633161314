import { ReactNode } from 'react';

export const symbol_4g_mobiledata_badge: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M100-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h760q24 0 42 18t18 42v600q0 24-18 42t-42 18H100Zm0-60h760v-600H100v600Zm0 0v-600 600Zm490-100h151q25 0 42-17.625T800-340v-142q0-12.75-8.625-21.375T770-512h-42q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T728-452h13v112H590v-280h210q-3-24-19.45-42T741-680H590q-24.75 0-42.375 17.625T530-620v280q0 24.75 17.625 42.375T590-280ZM324-410v100q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T384-310v-100h55q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T439-470h-55v-180q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T324-650v180H220v-180q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T160-650v210q0 12.75 8.625 21.375T190-410h134Z"
		/>
	</svg>
);
