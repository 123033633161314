import { ReactNode } from 'react';

export const symbol_phone_bluetooth_speaker: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M673-482v-145l-88 88q-5 5-13 5t-13-5q-5-5-5-13t5-13l111-111-111-105q-5-5-5-13t5-13q5-5 13-5t13 5l88 88v-145q0-10.5 9.5-14.25T699-874l89 92q9 9 9 21t-9 21l-66 64 67 72q8 9.273 8 21.636Q797-570 789-562l-90 90q-7 8-16.5 4.25T673-482Zm40-56 44-45-44-44v89Zm0-181 44-42-44-51v93Zm82 599q-116 0-236.5-56T335-335Q232-438 176-558.5T120-795q0-19.286 12.857-32.143T165-840h140q14 0 24 10t14 25l26.929 125.641Q372-665 369.5-653.5q-2.5 11.5-10.729 19.726L259-533q26 44 55 82t64 72q37 38 78 69.5t86 55.5l95-98q10-11 23.151-15T686-369l119 26q15 4 25 16.044T840-300v135q0 19.286-12.857 32.143T795-120ZM229-588l81-82-23-110H180q2 42 13.5 88.5T229-588Zm369 363q41 19 89 31t93 14v-107l-103-21-79 83ZM229-588Zm369 363Z"
		/>
	</svg>
);
