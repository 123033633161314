import { useContext } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import { EventTypes, sortEventTypesByLabels } from 'pkg/api/models/event';
import { useQueryState } from 'pkg/hooks/query-state';

import { TemplateContext } from 'routes/scheduling/templates/single';
import { FilterState } from 'routes/scheduling/templates/filters';

import { SmallScreen, LargeScreen } from 'components/MediaQuery';
import Icon from 'components/icon';

import Row from 'components/layout/row';
import * as columnStyles from 'components/scheduling/grid/column-item/styles.css';
import * as GridColumnItem from 'components/scheduling/grid/column-item';

import * as Context from 'design/context_menu';

interface ResourceColumnProps {
	filters: FilterState;
	resource: models.resource.Resource;
}

const ResourceColumn = ({ resource, filters }: ResourceColumnProps) => {
	const templateContext = useContext(TemplateContext);
	const qs = useQueryState();

	const parentResource = templateContext.resources.find(
		(item) => item.id === resource.parentResourceId
	);

	const handleToggleVisibility = () => {
		qs.set(
			'schedulingFilters',
			JSON.stringify({
				...filters,
				resources:
					filters.resources.length > 0
						? filters.resources.filter((r) => r !== resource.id)
						: templateContext.resources
								.filter((r) => r.id !== resource.id)
								.map((r) => r.id),
			})
		);
		qs.commit();
	};

	const handleShowOnlyRow = () => {
		qs.set(
			'schedulingFilters',
			JSON.stringify({
				...filters,
				resources: [resource.id],
			})
		);
		qs.commit();
	};

	const handleRemoveScheduleItems = async () => {
		const itemsToRemove = templateContext.scheduleItems.filter((item) =>
			item.resources?.some((i) => i.resourceId === resource.id)
		);

		templateContext?.onRemoveScheduleItems(itemsToRemove);
		itemsToRemove.forEach(async (item) => {
			await models.scheduleItem.remove(item);
		});
	};

	const resourceScheduleItems = templateContext.scheduleItems.filter((item) =>
		item.resources?.some((i) => i.resourceId === resource.id)
	);
	const mappedEventTypes: { [key: string]: number[] } = {};

	resourceScheduleItems?.forEach((item) => {
		if (item.type in mappedEventTypes) {
			mappedEventTypes[item.type] = [...mappedEventTypes[item.type], item.id];
		} else {
			mappedEventTypes[item.type] = [item.id];
		}
	});

	const eventTypes =
		Object.keys(mappedEventTypes).length > 0 ? (
			<div className={columnStyles.eventTypeWrapper}>
				{Object.keys(mappedEventTypes)
					.sort((a: EventTypes, b: EventTypes) => sortEventTypesByLabels(a, b))
					.map((k, i) => (
						<GridColumnItem.Type key={i} type={k as EventTypes}>
							{mappedEventTypes[k].length}
						</GridColumnItem.Type>
					))}
			</div>
		) : null;

	return (
		<div className={columnStyles.wrapper}>
			<div>
				<div className={columnStyles.name}>
					{resource.parentResourceId !== null && (
						<div className={columnStyles.parentName}>
							{parentResource?.title}
						</div>
					)}
					<Row spacing={styles.spacing._2} columns="1fr auto">
						<div>{resource.title}</div>
						<Row autoColumns="auto" align="center">
							<SmallScreen>{eventTypes}</SmallScreen>

							<Context.Menu toggleWith={<Icon name="context-menu" />}>
								<Context.Item onClick={handleToggleVisibility}>
									<Context.ItemIcon name="visibility_off" />
									{t('Hide row')}
								</Context.Item>
								<Context.Item onClick={handleShowOnlyRow}>
									<Context.ItemIcon name="visibility" />
									{t('Show only this row')}
								</Context.Item>
								{!!templateContext.schedule && (
									<Context.ConfirmItem
										caution
										onConfirm={handleRemoveScheduleItems}
										message={t(
											'Are you sure you want to remove all bookings for this group?'
										)}>
										<Context.ItemIcon name="delete" />
										{t('Remove items')}
									</Context.ConfirmItem>
								)}
							</Context.Menu>
						</Row>
					</Row>
				</div>
				<LargeScreen>{eventTypes}</LargeScreen>
			</div>
			{!!resource.location && (
				<div className={columnStyles.locationRow}>
					<Icon name="location" />
					<div className={columnStyles.location}>{resource.location.title}</div>
				</div>
			)}
		</div>
	);
};

export default ResourceColumn;
