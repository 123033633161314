import { ReactNode } from 'react';

export const symbol_air_purifier: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M120-180v-512q0-59 44.5-103.5T268-840h304q59 0 103.5 44.5T720-692v14q0 12.75-8.625 21.375T690-648q-13 0-21.5-8.5T660-678v-14q0-35-26.5-61.5T572-780H268q-35 0-61.5 26.5T180-691.813V-180h480v-67q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T720-247v67q0 24.75-17.625 42.375T660-120H180q-24.75 0-42.375-17.625T120-180Zm300-150q63 0 106.5-43.5T570-480q0-63-43.5-106.5T420-630q-63 0-106.5 43.5T270-480q0 63 43.5 106.5T420-330Zm0-60q-39 0-64.5-25.5T330-480q0-39 25.5-64.5T420-570q39 0 64.5 25.5T510-480q0 39-25.5 64.5T420-390Zm465-227.825q0 12.825-8.625 21.325T855-588q-14 0-25.5 7T811-561q-14 27-39.876 43.5Q745.247-501 714-501q-12.854 0-21.927-8.675-9.073-8.676-9.073-21.5 0-12.825 9.073-21.325T714-561q14.087 0 25.043-6.5Q750-574 757-589q12-26 39.5-42.5T855-648q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Zm0 150q0 12.825-9.073 21.325T854-438q-14.087 0-25.043 5.5Q818-427 811-413q-14 27-39.876 43.5Q745.247-353 714-353q-12.854 0-21.927-8.675-9.073-8.676-9.073-21.5 0-12.825 9.073-21.325T714-413q14.087 0 25.043-6.5Q750-426 757-441q12-27 39-42t58-15q12.854 0 21.927 8.675 9.073 8.676 9.073 21.5ZM660-180H180h480-17 17Z"
		/>
	</svg>
);
