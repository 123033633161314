import { t } from '@transifex/native';
import { useState, useRef, ChangeEvent } from 'react';

import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { OEmbedResponse } from 'pkg/hooks/oembed';

import OEmbed from 'routes/video/shared/OEmbed';

import * as StepModal from 'components/step-modal';

import {
	VideoPayload,
	VideoPayloadSetter,
} from 'components/video-library/modals/Video';
import * as Input from 'components/form/inputs';
import Column from 'components/layout/column';
import { isValidSource } from 'components/video-analytics/source/SourceUrl';

interface VideoProps {
	payload: VideoPayload;
	setPayload: VideoPayloadSetter;
}

export default function Video({
	payload,
	setPayload,
}: VideoProps): JSX.Element {
	const { setCanGoNext } = StepModal.useStepModalContext();

	const inputRef = useRef<HTMLInputElement>();
	const [link, setLink] = useState<string>('');

	useComponentDidMount(async () => {
		setCanGoNext(false);
	});

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const data = event.target.value;

		setCanGoNext(false);

		if (data && data.startsWith('http') && isValidSource(data)) {
			setLink(data);
		}
	};

	const handleEmbedSuccess = (embed: OEmbedResponse) => {
		setCanGoNext(true);

		setPayload({
			title: payload.title ? payload.title : embed.title,
			previewUrl: embed.thumbnail_url,
			url: link,
		});
	};

	return (
		<Column>
			<Input.Group
				label={t(`Paste link to video`)}
				hint={
					!link.startsWith('https') && link !== ''
						? t(`We only accept embeds over a secure (HTTPS) connection.`)
						: ''
				}>
				<Input.Field
					ref={inputRef}
					type="url"
					placeholder="https://"
					autoComplete="url"
					onChange={handleChange}
					changeDelay={350}
				/>
			</Input.Group>
			<OEmbed autoPlay controls url={link} onSuccess={handleEmbedSuccess} />
		</Column>
	);
}
