import { ReactNode } from 'react';

export const symbol_arrow_warm_up: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M479.825-460Q467-460 458.5-468.625T450-490v-276L263-579q-9 9-21 9t-21-9q-9-9-9-21t9-21l238-238q5-5 10.133-7 5.134-2 11-2Q486-868 491-866q5 2 10 7l238 238q9 9 9 21.158 0 12.158-8.609 21Q730-570 717.9-570t-20.9-9L510-766v276q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Zm0 200Q467-260 458.5-268.625T450-290v-80q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-370v80q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Zm0 180Q467-80 458.5-88.625T450-110v-60q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-170v60q0 12.75-8.675 21.375Q492.649-80 479.825-80Z"
		/>
	</svg>
);
