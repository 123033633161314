import { ReactNode } from 'react';

export const symbol_apk_install: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-80q-24 0-42-18t-18-42v-680q0-24 18-42t42-18h315q12.444 0 23.722 5T498-862l204 204q8 8 13 19.278 5 11.278 5 23.722v144q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T660-471v-129H440v-220H140v680h430q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q600-97 591.375-88.5T570-80H140Zm0-60v-680 680Zm60-60q4-49 30-90t68-65l-38-68q0-1 4-15 5-2 9.5-2t6.5 5l39 70q20-8 40-12.5t41-4.5q21 0 41 4.5t40 12.5l39-70 15-4q5 2 6 7t-1 9l-38 68q42 24 68 65t30 90H200Zm110-60q8 0 14-6t6-14q0-8-6-14t-14-6q-8 0-14 6t-6 14q0 8 6 14t14 6Zm180 0q8 0 14-6t6-14q0-8-6-14t-14-6q-8 0-14 6t-6 14q0 8 6 14t14 6Zm270 65v-151q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T820-346v151l53-53q9-9 21-8.5t21.391 9.5Q924-238 924-226t-9 21L811-101q-9 9-21 9t-21-9L665-205q-9-9-9-21t9.391-21q8.609-9 21.174-9T708-247l52 52Z"
		/>
	</svg>
);
