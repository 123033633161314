import { ReactNode } from 'react';

export const symbol_shuffle_on: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M100-40q-24 0-42-18t-18-42v-761q0-23 18-41.5t42-18.5h761q23 0 41.5 18.5T921-861v761q0 24-18.5 42T861-40H100Zm506-120h164q13 0 21.5-8.5T800-190v-163q0-13-8.5-21.5T770-383q-13 0-21.5 8.5T740-353v91L586-415q-9-9-21.5-8.5T543-414q-9 9-9 21t9 21l153 152h-90q-13 0-21.5 8.5T576-190q0 13 8.5 21.5T606-160Zm-436-10q9 9 21 9.5t21-8.5l528-528v90q0 13 8.5 21.5T770-577q13 0 21.5-8.5T800-607v-163q0-13-8.5-21.5T770-800H606q-13 0-21.5 8.5T576-770q0 13 8.5 21.5T606-740h92L170-212q-9 9-9 21t9 21Zm-1-579 204 205q9 9 21.5 9t21.5-9q9-9 9-21.5t-9-21.5L211-792q-9-9-21-9t-21 9q-9 9-9 21.5t9 21.5Z"
		/>
	</svg>
);
