import { ReactNode } from 'react';

export const symbol_wifi_channel: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M150-120q-12 0-20-9t-6-21q11-85 29.5-179.5T201-499q26-69 56.5-105t62.5-36q20 0 40.5 20.5T401-563q20 36 37 85t30 109q12-103 31-189t42-149q23-63 48-98t51-35q34 0 65.5 49.5T763-645q30 109 48 243t27 251q1 13-7.5 22t-21.5 9q-14 0-25-8.5T769-150q-12-38-27.5-74T709-290q-20-35-37.5-55.5T640-370q-12 4-27 23t-32 49q-17 30-32.5 67T521-153q-5 15-16 24t-25 9q-14 0-23.5-10T445-155q-10-76-24-144t-31-125q-17-57-34.5-97T320-580q-25 26-46 68t-38 99q-16 53-28.5 118T184-150q-2 13-11.5 21.5T150-120Zm372-187q26-59 56.5-91t61.5-32q32 0 65.5 33t57.5 90q-8-90-21-168.5T712-617q-17-63-35-104.5T640-778q-17 14-33.5 54T574-622q-16 62-29 141.5T522-307Z"
		/>
	</svg>
);
