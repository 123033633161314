import { ReactNode } from 'react';

export const symbol_money_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M444-752v-58q0-13 8.5-21.5T474-840q13 0 21.5 8.5T504-810v57q35 4 64 22.5t49 47.5q8 11 5.5 23.5T609-639q-11 8-23.5 5.5T565-647q-15-23-39-35.5T475-695q-20 0-40 5.5T398-672q-10 8-21.5 5.5T357-679q-8-10-6-21.5t12-19.5q18-13 38.5-21t42.5-11ZM785-78 614-248q-22 21-50.5 31T504-205v55q0 13-8.5 21.5T474-120q-13 0-21.5-8.5T444-150v-56q-49-7-84-36t-54-72q-5-12 .5-23.5T324-354q12-5 24 .5t17 17.5q17 37 45.5 55t69.5 18q26 0 50.5-6.5T570-292L78-784q-9-9-9-21.5t9-21.5q9-9 21.5-9t21.5 9l707 707q9 9 9 21t-9 21q-9 9-21.5 9T785-78Z"
		/>
	</svg>
);
