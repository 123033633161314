import { ReactNode } from 'react';

export const symbol_tv_options_input_settings: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-160q-24.75 0-42.375-17.625T80-220v-520q0-24.75 17.625-42.375T140-800h680q24.75 0 42.375 17.625T880-740v520q0 24.75-17.625 42.375T820-160H140Zm0-60h680v-520H140v520Zm232-158q16 14 34 25t38 17l3 18q2 11 10.1 18.5T476-292h8q11.2 0 19.6-7.5Q512-307 514-318l2-18q20-6 38.5-17t33.5-25l18 9q10 5 20 2.5t16-11.5l3-6q6-9 4.533-19.595Q648.067-414.189 640-421l-20-17q5-21 5-42t-5-42l20-17q8-7 10-17.385 2-10.384-4-19.615l-4-6q-6-9-16-11.5t-20 2.5l-18 9q-15-14-33.5-25T516-624l-3-18q-2-11-10.1-18.5T484-668h-8q-11.2 0-19.6 7.5Q448-653 446-642l-2 18q-20 6-38 17t-34 25l-18-9q-10-5-20-2.5T318-582l-3 6q-6 9-4.533 19.595Q311.933-545.811 320-539l20 17q-5 21-5 42t5 42l-20 17q-8 7-10 17.385-2 10.384 4 19.615l4 6q6 9 16 11.5t20-2.5l18-9Zm108-14q-37 0-62.5-25.5T392-480q0-37 25.5-62.5T480-568q37 0 62.5 25.5T568-480q0 37-25.5 62.5T480-392ZM140-220v-520 520Z"
		/>
	</svg>
);
