export const updateActivityComment = (id, comment) => (dispatch) =>
	dispatch({
		type: 'UPDATE_ACTIVITY_COMMENT',
		id,
		comment,
	});

export const addCommentsBatch = (id, collection) => (dispatch) =>
	dispatch({
		type: 'ADD_COMMENTS_BATCH',
		id,
		collection,
	});

export const addActivityComment = (id, comment) => (dispatch) =>
	dispatch({
		type: 'ADD_ACTIVITY_COMMENT',
		id,
		comment,
	});

export const addPostComment = (id, comment) => (dispatch) =>
	dispatch({
		type: 'ADD_POST_COMMENT',
		id,
		comment,
	});

export const removeActivityComment = (id, comment) => (dispatch) =>
	dispatch({
		type: 'REMOVE_ACTIVITY_COMMENT',
		id,
		comment,
	});

const addActivityLikesCollection = (id, collection) => (dispatch) =>
	dispatch({
		type: 'ADD_ACTIVITY_LIKES_COLLECTION',
		id,
		collection,
	});

export const addActivityLikes = (id, collection) => (dispatch) => {
	dispatch(addActivityLikesCollection(id, collection));
};
