import { ReactNode } from 'react';

export const symbol_arrows_more_up: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M509.825-200Q497-200 488.5-208.625T480-230v-330H150q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-620h360q12.75 0 21.375 8.625T540-590v360q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Zm220-220Q717-420 708.5-428.625T700-450v-330H370q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T370-840h360q12.75 0 21.375 8.625T760-810v360q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Z"
		/>
	</svg>
);
