import { ReactNode } from 'react';

export const symbol_emergency_share_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M481-890q-67 0-129 23t-113 67l-42-42q60-52 132.5-80T481-950q78 0 149.5 27T761-846q11 9 12 22t-8 22q-9 9-22 8t-24-9q-50-42-111-64.5T481-890Zm0 160q-35 0-68 11t-61 32l-42-42q36-30 79.5-45.5T481-790q47 0 90.5 15t78.5 44q10 8 10 20t-9 21q-9 9-21.5 9t-22.5-8q-27-20-59-30.5T481-730Zm236 409L442-597q9-2 18.5-2.5t19.5-.5q101 0 170.5 72T720-354q0 8-.5 16.5T717-321Zm-96 75L368-499q-31 26-49.5 63.5T300-354q0 50 47.5 122T480-80q47-44 82.5-86t58.5-80ZM480-10q-5 0-10-2t-10-6q-33-30-71.5-68T317-167.5Q284-211 262-258t-22-96q0-57 23-105.5t62-82.5L76-791q-9-9-9-21.5t9-21.5q9-9 21.5-9t21.5 9l713 713q9 9 8.5 21T831-79q-9 9-21 9t-21-9L667-200q-34 53-77 96.5T500-18q-5 4-10 6t-10 2Zm0-300q-21 0-35.5-14.5T430-360q0-21 14.5-35.5T480-410q11 0 20 4t15.5 10.5Q522-389 526-380t4 20q0 21-14.5 35.5T480-310Zm-19 20Z"
		/>
	</svg>
);
