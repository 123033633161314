import { cssClasses } from 'pkg/css/utils';

import * as css from './styles.css';

interface ParagraphRowProps {
	height: number;
	width?: number;
	percentageWidth?: number;
	inline?: boolean;
}

export function ParagraphRow({
	height,
	width,
	percentageWidth,
	inline,
}: ParagraphRowProps) {
	return (
		<div
			style={{
				height: `${height}px`,
				width: percentageWidth ? `${percentageWidth}%` : `${width}px`,
			}}
			className={cssClasses(css.paragraphRow, inline && css.inline)}
		/>
	);
}
