import { ReactNode } from 'react';

export const symbol_currency_rupee: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M555-129 299.199-393.828Q294-399 292-404.5q-2-5.5-2-12.167v-20q0-14.166 9.487-23.75Q308.975-470 323-470h97q57 0 100-37t50-103H270q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T270-670h295q-13-48-53.569-79T420-780H270q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T270-840h420q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T690-780H566q23 20 39 51t23 59h62q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T690-610h-60q-8 91-67.355 145.5Q503.289-410 420-410h-52l231 240q14 14 5.982 32t-27.873 18q-6.109 0-11.609-2t-10.5-7Z"
		/>
	</svg>
);
