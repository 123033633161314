import { ReactNode } from 'react';

export const symbol_read_more: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M377-450H110q-12.75 0-21.375-8.675Q80-467.351 80-480.175 80-493 88.625-501.5T110-510h267L267-621q-8-9-8.5-21.1-.5-12.1 8.5-20.9 9-9 21-9t21 9l162 162q9 9 9 21t-9 21L309-297q-9 9-21 9t-21-9q-9-9-9-21t9-21l110-111Zm193 140q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T570-370h280q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-310H570Zm0-280q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T570-650h280q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-590H570Zm120 140q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T690-510h160q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-450H690Z"
		/>
	</svg>
);
