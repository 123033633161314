import { useState, useContext } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import AddProductModal from 'routes/payments/products/modals/create';
import EditProductModal from 'routes/payments/products/modals/edit';
import ProductCalculations from 'routes/payments/orders/create/components/ProductCalculations';
import {
	AddOrderContext,
	AssignedProductProps,
} from 'routes/payments/orders/create';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import Column from 'components/layout/column';
import InfoBox from 'components/form/info-box';

import Button from 'design/button';

interface AssignProductsProps {
	subscriptionId?: number;
}

const AssignProducts: React.FC<
	React.PropsWithChildren<AssignProductsProps>
> = ({ subscriptionId }) => {
	const [modal, setModal] = useState({
		type: '',
		show: false,
	});

	const [editProduct, setEditProduct] = useState({
		productId: null,
		row: null,
	});

	const showModal = (type: string) =>
		setModal({
			type,
			show: true,
		});

	const hideModal = () =>
		setModal({
			type: '',
			show: false,
		});

	const OrderContext = useContext(AddOrderContext);

	const handleOpenEditModal = (productId: number, row: number) => {
		setEditProduct({ productId, row });
		showModal('editProduct');
	};

	const handleRemove = (row: number) =>
		OrderContext.setFormState({
			rows: OrderContext.formState.rows.filter((r, i) => i !== row),
		});

	const handleSave = (formState: AssignedProductProps) => {
		const rows = [...OrderContext.formState.rows];
		rows[editProduct.row] = formState;

		OrderContext.setFormState({
			rows,
		});
	};

	const filteredRows = OrderContext.formState.rows.filter(
		(item) => item.productPrice.recurring === true
	);
	const oneTimeRows = OrderContext.formState.rows.some(
		(item) => item.productPrice.recurring !== true
	);

	const noConflictingPriceCycles =
		filteredRows.length > 0
			? filteredRows.every(
					(obj) =>
						obj.productPrice.recurringInterval ===
							filteredRows[0].productPrice.recurringInterval &&
						obj.productPrice.recurringIntervalCount ===
							filteredRows[0].productPrice.recurringIntervalCount
				)
			: true;

	const showInfoBox = !!subscriptionId
		? !noConflictingPriceCycles || oneTimeRows
		: !noConflictingPriceCycles;

	return (
		<Column spacing={styles.spacing._5}>
			{OrderContext.formState.rows.length > 0 && (
				<ProductCalculations
					productRows={OrderContext.formState.rows}
					onEdit={handleOpenEditModal}
					onRemove={handleRemove}
				/>
			)}
			<span>
				<LargeScreen>
					<Button
						secondary
						icon="add"
						onClick={() => showModal('addProduct')}
						testid="order.form.add_product">
						{t('Add products')}
					</Button>
				</LargeScreen>
				<SmallScreen>
					<Button
						secondary
						block
						icon="add"
						onClick={() => showModal('addProduct')}
						testid="order.form.add_product">
						{t('Add products')}
					</Button>
				</SmallScreen>
			</span>
			{showInfoBox && (
				<InfoBox
					text={
						!!subscriptionId
							? t(
									'You can only add a recurring product with the same billing cycle as all other products when updating a subscription.'
								)
							: t(
									`A subscription can't have prices with different billing periods.`
								)
					}
					color="red"
				/>
			)}

			{modal.type === 'addProduct' && modal.show && (
				<AddProductModal hideAddModal={hideModal} />
			)}
			{modal.type === 'editProduct' && modal.show && (
				<EditProductModal
					data={OrderContext.formState.rows[editProduct.row]}
					productId={editProduct.productId}
					onSave={handleSave}
					onClose={hideModal}
				/>
			)}
		</Column>
	);
};

export default AssignProducts;
