import { ReactNode } from 'react';

export const symbol_apartment: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-120q-24.75 0-42.375-17.625T120-180v-435q0-24.75 17.625-42.375T180-675h105v-105q0-24.75 17.625-42.375T345-840h270q24.75 0 42.375 17.625T675-780v270h105q24.75 0 42.375 17.625T840-450v270q0 24.75-17.625 42.375T780-120H533v-165H427v165H180Zm0-60h105v-105H180v105Zm0-165h105v-105H180v105Zm0-165h105v-105H180v105Zm165 165h105v-105H345v105Zm0-165h105v-105H345v105Zm0-165h105v-105H345v105Zm165 330h105v-105H510v105Zm0-165h105v-105H510v105Zm0-165h105v-105H510v105Zm165 495h105v-105H675v105Zm0-165h105v-105H675v105Z"
		/>
	</svg>
);
