import { ReactNode } from 'react';

export const symbol_detector_offline: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m480-224-83 83q-9 9-21 9t-21-9q-9-9-9-21t9-21l83-83-83-83q-9-9-9-21t9-21q9-9 21-9t21 9l83 83 83-83q9-9 21-9t21 9q9 9 9 21t-9 21l-83 83 83 83q9 9 9 21t-9 21q-9 9-21 9t-21-9l-83-83ZM180-780v60h600v-60H180Zm121 120 18 60h322l18-60H301Zm18 120q-19.5 0-35.25-11.625T262-582l-25-78h-57q-24.75 0-42.375-17.625T120-720v-60q0-24.75 17.625-42.375T180-840h600q24.75 0 42.375 17.625T840-780v60q0 24.75-17.625 42.375T780-660h-57l-30 81q-6.932 17.25-22.338 28.125Q655.257-540 636-540H319ZM180-780v60-60Z"
		/>
	</svg>
);
