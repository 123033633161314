import { ReactNode } from 'react';

export const symbol_skateboarding: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M290 0q-10 0-17.5-7.5T265-25q0-10 7.5-17.5T290-50q10 0 17.5 7.5T315-25q0 10-7.5 17.5T290 0Zm340 0q-10 0-17.5-7.5T605-25q0-10 7.5-17.5T630-50q10 0 17.5 7.5T655-25q0 10-7.5 17.5T630 0ZM270-90q-42 0-82.5-19T124-163q-2-3.333-3-6t-1-6q0-8 5.263-14t14.474-6q5.263 0 9.763 2t6.5 6q13 22 38.5 35.5T246-132l132-160-60-170q-5-15-2.5-27t10.5-26l110-175H318l-58 93q-7 11-18 13.5t-22-4q-11-6.5-14-18.5t4-23l67-107q3.824-6.632 10.706-10.316Q294.588-750 303-750h205q23.318 0 37.568 12.907Q559.818-724.186 565-713l21 48q18 42 54 71.5t82 39.5q12 2 20 12t8 22q0 12-8.5 20.5T722-493q-57.242-8.676-104.121-42.838T539-621l-85 136 163 112q6 5 9.5 11.207Q630-355.586 630-348v218h30q31.57 0 58.785-15Q746-160 764-187q3.333-4 7-6t9-2q8 0 14 5.914 6 5.915 6 13.8 0 3.286-1 6.055-1 2.769-3 6.231-23 35-58.5 54T660-90H270Zm53-40h247v-190L399-439l45 141q2 8 1 15.5t-6 13.5L323-130Zm284.08-644q-30.08 0-51.58-21.42-21.5-21.421-21.5-51.5 0-30.08 21.42-51.58 21.421-21.5 51.5-21.5 30.08 0 51.58 21.42 21.5 21.421 21.5 51.5 0 30.08-21.42 51.58-21.421 21.5-51.5 21.5Z"
		/>
	</svg>
);
