import { t } from '@transifex/native';
import styled from 'styled-components';

import * as palette from 'pkg/config/palette';

import rgba from 'pkg/rgba';

import HeatMapWrapper from 'components/HeatMapWrapper';

const Outer = styled.div`
	grid-area: positions;
	justify-self: start;
	padding: 1.3rem 1.5rem;
	height: 100%;
	position: relative;
	width: 100%;
	justify-self: start;
	background: rgb(57, 144, 73);

	@media all and (orientation: portrait) {
		display: none;
	}

	${HeatMapWrapper} {
		width: 100%;
		height: 400px;
		grid-gap: 5px;

		.field-position {
			display: flex;
			transition: transform 150ms;
			user-select: none;
			overflow: visible;

			border: 1px dashed ${rgba(palette.white, 0.6)};
			background: transparent;

			cursor: grab;

			color: ${palette.white};
			font-size: var(--font-size-base);

			@media (hover: hover) {
				&:hover {
					background-color: ${rgba(palette.white, 0.1)};
				}
			}
		}
	}

	h3 {
		color: ${palette.white};
		font-weight: var(--font-weight-semibold);
		font-size: var(--font-size-base);
		margin-bottom: 1.2rem;
	}
`;

const Positions = ({ positions, className }) => (
	<Outer
		as="aside"
		className={`remove-position-drop-area ${className}`}
		data-drop-message={t(`Drop here to remove`)}>
		<h3>{t(`Choose positions`)}</h3>
		<HeatMapWrapper
			drag
			className="unused-positions heatmap heatmap--small heatmap--with-goalkeeper">
			{positions.map((position) => (
				<div
					key={position.id}
					className={`${position.slug} heatmap__spot field-position`}
					data-short-name={position.name}
					data-long-name={position.longName}
					data-id={position.id}
					data-slug={position.slug}>
					{position.name}
				</div>
			))}
		</HeatMapWrapper>
	</Outer>
);

export default Positions;
