// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._VIM8MRE0fvLiL0BQgRg {
	font-size: var(--font-size-sm);
}
`, "",{"version":3,"sources":["webpack://./design/placeholders/block/styles.css"],"names":[],"mappings":"AAAA;CACC,8BAA8B;AAC/B","sourcesContent":[".link {\n\tfont-size: var(--font-size-sm);\n}\n"],"sourceRoot":""}]);
// Exports
export var link = `_VIM8MRE0fvLiL0BQgRg`;
export default ___CSS_LOADER_EXPORT___;
