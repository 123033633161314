import { ReactNode } from 'react';

export const symbol_stay_primary_portrait: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480.018-286Q466-286 456.5-295.482q-9.5-9.483-9.5-23.5 0-14.018 9.482-23.518 9.483-9.5 23.5-9.5 14.018 0 23.518 9.482 9.5 9.483 9.5 23.5 0 14.018-9.482 23.518-9.483 9.5-23.5 9.5Zm.807-110Q468-396 459.5-404.625T451-426v-208q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T511-634v208q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM260-40q-24 0-42-18t-18-42v-760q0-24 18-42t42-18h440q24 0 42 18t18 42v760q0 24-18 42t-42 18H260Zm0-90v30h440v-30H260Zm0-60h440v-580H260v580Zm0-640h440v-30H260v30Zm0 0v-30 30Zm0 700v30-30Z"
		/>
	</svg>
);
