import Controller from 'components/video-analytics/Controller';
import { VideoUrl } from 'components/video-analytics/source/SourceUrl';

let __volume: number = 1;

export default class VideoController extends Controller {
	public signature: string;

	constructor(protected controllerInterface: any) {
		super(controllerInterface);
	}

	public setSource(source: VideoUrl): void {
		this.controllerInterface.src(source.getSourceUrl());
	}

	public async getCurrentTime(): Promise<number> {
		return Promise.resolve(this.controllerInterface.currentTime() || 0);
	}

	public async getDuration(): Promise<number> {
		return Promise.resolve(this.controllerInterface.duration());
	}

	public async play(): Promise<void> {
		if (!this.controllerInterface.paused()) return;

		this.controllerInterface.play();

		return Promise.resolve();
	}

	public async pause(): Promise<void> {
		if (this.controllerInterface.paused()) return;

		this.controllerInterface.pause();

		return Promise.resolve();
	}

	public isPlaying(): boolean {
		return !this.controllerInterface.paused();
	}

	public async skip(
		fraction: number,
		resumeAfterSkip: boolean = false
	): Promise<number> {
		const currentTime: number = await this.getCurrentTime();
		const nextFraction: number = currentTime + fraction;

		await this.seekTo(nextFraction, resumeAfterSkip);

		return Promise.resolve(nextFraction);
	}

	public async seekTo(
		fraction: number,
		resumeAfterSeek: boolean = false
	): Promise<number> {
		const duration: number = await this.getDuration();

		let nextFraction: number = fraction;

		if (nextFraction > duration) {
			nextFraction = duration;
		}

		if (!this.isPlaying() && resumeAfterSeek) {
			await this.play();
		} else if (this.isPlaying() && !resumeAfterSeek) {
			await this.pause();
		}

		await this.controllerInterface.currentTime(nextFraction);

		return Promise.resolve(nextFraction);
	}

	public async setVolume(fraction: number): Promise<void> {
		let nextFraction: number = fraction;

		if (nextFraction > 1) {
			nextFraction = 1;
		} else if (nextFraction < 0) {
			nextFraction = 0;
		}

		this.controllerInterface.volume(nextFraction);

		return Promise.resolve();
	}

	public async getVolume(): Promise<number> {
		const volume: number = this.controllerInterface.volume();

		return Promise.resolve(volume);
	}

	public async mute(): Promise<void> {
		__volume = this.controllerInterface.volume();

		await this.setVolume(0);
	}

	public async unmute(): Promise<void> {
		await this.setVolume(__volume);
	}

	public async getAvailablePlaybackRates(): Promise<readonly number[]> {
		return Promise.resolve([0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2]);
	}

	public async setPlaybackRate(playbackRate: number): Promise<void> {
		const availableRates = await this.getAvailablePlaybackRates();

		if (availableRates.includes(playbackRate)) {
			this.controllerInterface.playbackRate(playbackRate);
		}

		return Promise.resolve();
	}

	public async getPlaybackRate(): Promise<number> {
		return Promise.resolve(this.controllerInterface.playbackRate());
	}
}
