import { Fragment } from 'react/jsx-runtime';

import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCollection } from 'pkg/api/use_collection';

import { CustomFieldInput } from 'routes/payments/hooks/contact_form_sections/CustomFields';

import Section from 'components/form/Section';
import { Spinner } from 'components/loaders/spinner';
import Column from 'components/layout/column';

interface CustomFieldCategoriesProps {
	organizationId: number;
	prevData: models.user.Field[];
}

export interface FormattedPrevData {
	[key: string]: {
		value: string;
		attachments?: models.attachment.Attachment[];
	};
}

export default function CustomFieldCategories({
	organizationId,
	prevData = [],
}: CustomFieldCategoriesProps): JSX.Element {
	const { records: categories, isLoading } =
		useCollection<models.userFieldCategories.UserFieldCategory>(
			endpoints.UserFieldCategories.Index(organizationId)
		);

	const formattedPrevData: FormattedPrevData = {};

	for (const data of prevData) {
		formattedPrevData[data.key] = {
			value: data.values.join(','),
		};

		if (data.attachments) {
			formattedPrevData[data.key].attachments = [...data.attachments];
		}
	}

	const sortedCategories = [...categories].sort(
		(a, b) => a.sortOrder - b.sortOrder
	);

	if (isLoading) {
		return <Spinner />;
	}

	return (
		<Fragment>
			{sortedCategories.map((category) => (
				<Section title={category.title} hideDivider>
					<Column>
						{category.userFields.map((field) => (
							<CustomFieldInput
								key={field.key}
								field={field}
								defaultValue={formattedPrevData[field.key]?.value}
								defaultAttachments={formattedPrevData[field.key]?.attachments}
							/>
						))}
					</Column>
				</Section>
			))}
		</Fragment>
	);
}
