import { ReactNode } from 'react';

export const symbol_flowsheet: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M132-570v350-520 170Zm418.5 470q20.5 0 35-15t14.5-35.5q0-20.5-14.375-35T550-200q-20 0-35 14.375T500-150q0 20 15 35t35.5 15Zm260-420q20.5 0 35-15t14.5-35.5q0-20.5-14.375-35T810-620q-20 0-35 14.375T760-570q0 20 15 35t35.5 15ZM410-540q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T410-600H270q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T270-540h140Zm0 180q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T410-420H270q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T270-360h140ZM132-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h726q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T858-740H132v520h218q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T350-160H132ZM549.882-40Q504-40 472-72.083 440-104.167 440-150q0-38 22.5-67t57.5-39v-104q0-12.75 8.625-21.375T550-390h230v-74q-35-10-57.5-39T700-570q0-45.833 32.118-77.917 32.117-32.083 78-32.083Q856-680 888-647.917q32 32.084 32 77.917 0 38-22.5 67T840-464v104q0 12.75-8.625 21.375T810-330H580v74q35 10 57.5 39t22.5 67q0 45.833-32.118 77.917Q595.765-40 549.882-40Z"
		/>
	</svg>
);
