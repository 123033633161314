import { isDev } from 'pkg/flags';
import { isIOS } from 'pkg/platform';

let appleReady = false;

export const setup = () => {
	if (appleReady === true) {
		return Promise.resolve(window.AppleID.auth);
	}

	return new Promise((resolve) => {
		setTimeout(() => {
			if (!appleReady) {
				resolve(null);
			}
		}, 1000 * 10);

		(function (d, s, id) {
			function initApple() {
				window.AppleID?.auth.init({
					clientId: 'com.360player.signin',
					scope: 'name email',
					redirectURI: `${TS.apiUrl}/v1/auth/redirect`,
					state: location.href,
					usePopup: false,
				});

				appleReady === true;
				resolve(window.AppleID?.auth);
			}

			var js,
				ajs = d.getElementsByTagName(s)[0];

			if (d.getElementById(id)) {
				initApple();
				return;
			}

			js = d.createElement(s);
			js.id = id;
			js.onload = initApple;
			js.deferred = true;

			js.src =
				'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';

			if (ajs?.parentNode) {
				ajs.parentNode.insertBefore(js, ajs);
			}
		})(document, 'script', 'apple-cdn');
	});
};

const NOOP = (err) => {
	if (isDev) {
		console.error(err);
	}
};

export const connect = async (connection, onConnect, onError = NOOP) => {
	const appSignin = isIOS();

	if (!appSignin) {
		try {
			const response = await connection.signIn();

			onConnect(response.authorizationCode, response);
		} catch (error) {
			onError(error);
		}
	} else {
		window.cordova.plugins.SignInWithApple.signin(
			{ requestedScopes: [0, 1] },
			(response) => onConnect(response.authorizationCode, response),
			(error) => onError(error)
		);
	}
};

// @NOTE Just here for api concistency
export const disconnect = async (onDisconnect) => await onDisconnect();
