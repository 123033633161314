import { ReactNode } from 'react';

export const symbol_deployed_code_update: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-823 213-669l267 155 266-155-266-154ZM120-310v-340q0-16 7.875-29.787Q135.75-693.574 150-702l300-173q8-5 14.951-6.5 6.951-1.5 15-1.5t15.549 1.5Q503-880 510-875l300 173q14.25 8.426 22.125 22.213T840-650v180q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T780-470v-150L511-463q-14 8-30 8.5t-30-7.5L180-619v309l285 164q6.75 3.824 10.875 10.706T480-120q0 17-15 25.5t-30 .5L150-258q-14.25-8.426-22.125-22.213T120-310ZM730 0q-78 0-134-55.4-56-55.399-56-133.999Q540-268 596-324t134-56q78 0 134 55.867Q920-268.265 920-189q0 78.435-56 133.718Q808 0 730 0ZM480-478Zm245 345-67-67q-5-5-10-5t-10 5q-5 5-5 10.5t5 10.5l81 82q5 5 10.133 7 5.134 2 11 2Q746-88 751-90q5-2 10-7l82-82q5-5 5-10.5t-5-10.5q-5-5-10.5-5t-10.5 5l-67 67v-156q0-6-4.5-10.5T740-304q-6 0-10.5 4.5T725-289v156Z"
		/>
	</svg>
);
