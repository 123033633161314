import * as endpoints from 'pkg/api/endpoints/auto';
import * as sdk from 'pkg/core/sdk';

export const getEventUsers = (id) => {
	return sdk.get(endpoints.Events.ShowUsers(id), {}, {});
};

export const getEventSessions = (id) => {
	return sdk.get(endpoints.Events.ShowTrainingSessions(id), {}, {});
};

export const connectEventSession = (eventId, sessionId) => {
	return sdk.post(
		endpoints.Events.AddTrainingSession(eventId, sessionId),
		{},
		{}
	);
};

export const addUsersToEvent = (id, data) => {
	return sdk.post(endpoints.Events.AddUsers(id), {}, data);
};

export const deleteEvent = (eventId) => {
	return sdk.destroy(endpoints.Events.Delete(eventId));
};

export const addAttachment = (eventId, attachmentId) =>
	sdk.post(`/events/${eventId}/attachments/${attachmentId}`);
