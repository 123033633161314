import { ReactNode } from 'react';

export const symbol_subtitles_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-160q-24 0-42-17.625T80-220v-520q0-24 18-42t42-18h22l60 60h-82v520h519L527-350H270q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T270-410h198L62-816q-9-9-9-21t9-21q9-9 21-9t21 9l752 753q9 9 9 21t-9 21q-9 9-21 9t-21-9l-97-97H140Zm740-580v502q0 15-9.375 22.5T850-208q-14 0-22-8t-8-22v-502H320q-15 0-22.5-9.43t-7.5-20.745q0-12.825 8.625-21.325T320-800h500q23 0 41.5 18.5T880-740ZM690-470H590q-15 0-22.5-9.318t-7.5-20.5q0-11.182 7.5-20.682Q575-530 590-530h100q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T690-470Zm-126-14Zm-164 5Zm-130.175 9Q257-470 248.5-478.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Z"
		/>
	</svg>
);
