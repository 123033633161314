import { getParsedRoutes } from 'pkg/router/parser';
import * as json from 'pkg/json';

const ALIAS_SELF = '@self';
const ALIAS_ROOT = '@root';

const ROUTER_CONFIRM_NAVIGATION_KEY = '__router:confirmNavigation';

const isNamedRoute = (routePath) => {
	const parsedRoutes = getParsedRoutes();
	const exists = parsedRoutes.findIndex((n) => n.name === routePath);

	return exists > -1;
};

/**
 * @deprecated See {link}
 */
export const route = (location, props, hrefOnly = false) => {
	let to = '',
		href = '',
		replace = '';

	const params = {};

	if (isNamedRoute(location)) {
		to = location;
	} else {
		href = location;
	}

	const result = transformPath({ href, to, replace, params, props });

	if (hrefOnly) {
		return result.href;
	}

	return result;
};

/**
 *	Adds state as querystring to a path. If shouldStringify is true it converts state to json.
 *
 *	@param string location
 *	@param string|object state
 *
 *	@returns string
 */
export function link(location, state = {}, shouldSerialize = true) {
	if (typeof state === 'object') {
		if (Object.keys(state)?.length > 0) {
			if (shouldSerialize) {
				const params = new URLSearchParams();

				Object.entries(state).forEach(([key, value]) => {
					if (typeof value !== 'string') {
						value = json.stringify(value);
					}

					params.append(key, value.toString());
				});

				location += `?${params}`;
			} else {
				const qs = new URLSearchParams(state);
				location += `?${qs}`;
			}
		}
	} else if (!state.startsWith('?')) {
		location += `?${state}`;
	} else {
		location += state;
	}

	return location;
}

export function transform(path, params = {}) {
	Object.entries(params).forEach(([param, value]) => {
		path = path.replace(`:${param}`, value);
	});

	return path;
}

export const transformPath = (
	{ href, to, replace, params, props = {} },
	currentRoute = null
) => {
	const parsedRoutes = getParsedRoutes();

	if (href && !isLocalPathOrUrl(href)) {
		return {
			href,
			route: {},
		};
	}

	if (!href && !to && !replace) {
		return {
			href: '/',
			route: {},
		};
	}

	if (!params) {
		params = {};
	}

	if (to || replace) {
		let namedRoute = null;

		if (to) {
			namedRoute = parsedRoutes.find((n) => n.name === to);
		} else if (replace) {
			namedRoute = parsedRoutes.find((n) => n.name === replace);
		}

		if (to === ALIAS_ROOT || replace === ALIAS_ROOT) {
			namedRoute = parsedRoutes.find((n) => n.guid === ALIAS_ROOT);
		}

		if (to === ALIAS_SELF || replace === ALIAS_SELF) {
			if (currentRoute) {
				namedRoute = currentRoute;

				Object.entries(currentRoute).forEach(([prop, value]) => {
					if (namedRoute.pattern.includes(`:${prop}`)) {
						params[prop] = value;
					}
				});

				namedRoute.path = currentRoute.pattern;
			}
		}

		if (!namedRoute) {
			return {
				href: '/',
				route: {},
			};
		}

		let { path } = namedRoute;

		let query = namedRoute.query || {};

		const propBlacklist = ['className', 'forwardedRef', 'ref'];

		Object.entries(props).forEach(([prop, value]) => {
			if (path.includes(`:${prop}`)) {
				params[prop] = value;
			} else if (
				!propBlacklist.includes(prop) &&
				(typeof value === 'string' || value instanceof String)
			) {
				query[prop] = value;
			}
		});

		Object.entries(params).forEach(([param, value]) => {
			if (!path.includes(`:${param}`) && !props.hasOwnProperty(param)) {
				query[param] = value;
			}

			path = path.replace(`:${param}`, value);
		});

		let queryParams = new URLSearchParams(query).toString();

		if (queryParams.length > 0) {
			path = path + '?' + queryParams;
		}

		return {
			href: '/' + path.replace(/^\/+/, ''),
			route: namedRoute,
		};
	}

	return { href, route: {} };
};

export const isLocalPathOrUrl = (url) => {
	if (!url || url === undefined) return true;

	// Catch some none-URL options first, such as relative links or JS triggers.
	if (url.startsWith('/') && !url.startsWith('//')) {
		return true;
	} else if (url.startsWith('#')) {
		return true;
	} else if (url.startsWith('javascript')) {
		return true;
	}

	try {
		const u = new URL(url);

		// List of protocols that the app should handle
		if (['threesixty', 'capacitor'].some((p) => u.protocol === `${p}:`)) {
			return true;
		}

		// List of protocols that the app should let the OS handle
		if (['webcal', 'mailto', 'tel'].some((p) => u.protocol === `${p}:`)) {
			return false;
		}

		if (window.location.hostname === u.hostname) {
			return true;
		}

		if (
			!u.hostname ||
			u.hostname === 'localhost' ||
			u.hostname.endsWith('.local')
		) {
			return true;
		}

		return false;
	} catch {
		return false;
	}
};

export const shouldConfirmNavigation = () =>
	!!sessionStorage.getItem(ROUTER_CONFIRM_NAVIGATION_KEY);

export const setConfirmNavigation = (confirmNavigation) => {
	if (confirmNavigation) {
		sessionStorage.setItem(ROUTER_CONFIRM_NAVIGATION_KEY, confirmNavigation);
	} else {
		sessionStorage.removeItem(ROUTER_CONFIRM_NAVIGATION_KEY);
	}
};

export const confirmNavigation = (didNotConfirm = () => {}) => {
	const confirmNavigation = sessionStorage.getItem(
		ROUTER_CONFIRM_NAVIGATION_KEY
	);

	if (confirmNavigation) {
		const didConfirm = confirm(confirmNavigation);

		if (didConfirm) {
			setConfirmNavigation(null);
		} else {
			didNotConfirm();
		}

		return didConfirm;
	}

	return true;
};
