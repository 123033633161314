import { ReactNode } from 'react';

export const symbol_takeout_dining: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M304-220h352l23-310H281l23 310Zm-27-370h406l5-73-120-117H392L272-663l5 73Zm-57 54L100-662q-8-9-8-21t9-21q9-9 21.5-9t21.5 9l71 73-2-23q-2-14 2.5-26t14.5-22l118-120q8-8 19.5-13t23.5-5h178q12 0 23.5 5t19.5 13l118 120q10 10 14.5 22t2.5 26l-2 23 71-73q9-9 21.5-9t21.5 9q9 9 9 21t-8 21L740-536H220Zm436 376H304q-23 0-40.5-16.5T244-216l-24-320h520l-24 320q-2 23-19.5 39.5T656-160ZM480-590Zm0 60Z"
		/>
	</svg>
);
