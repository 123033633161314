import styled, { css, RuleSet } from 'styled-components';

import * as palette from 'pkg/config/palette';

import rgba from 'pkg/rgba';

import * as iconStyles from 'components/icon/styles.css';

const grids: { [type: string]: RuleSet } = {
	squad: css`
		grid-template-columns: minmax(0, 300px) 1fr minmax(0, 300px);
		grid-template-areas:
			'toolbar toolbar toolbar'
			'users canvas positions';
	`,
	formation: css`
		grid-template-columns: 1fr minmax(0, 300px);
		grid-template-areas:
			'toolbar toolbar'
			'canvas positions';
	`,
};

const FormationWrapper = styled.div<{ type: string }>`
	height: 100%;
	display: grid;
	grid-template-rows: auto 1fr;
	background: ${palette.green[500]};

	${({ type }) => grids[type]};

	@media all and (orientation: portrait) {
		grid-template-columns: 1fr;
		grid-template-areas:
			'toolbar'
			'canvas';
	}

	grid-gap: 0;
	justify-items: center;
	justify-content: center;
	align-items: stretch;
	width: 100%;

	aside.droppable::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background: rgba(0, 0, 0, 0.7);
		z-index: 2;
		border: 2px dashed ${palette.white};
		backdrop-filter: blur(2px);
	}

	aside.droppable::after {
		content: attr(data-drop-message);
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		padding: 50px 0;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 2;
		font-size: 18px;
		font-weight: var(--font-weight-semibold);
		color: ${palette.white};
	}

	&.hide-scores .formation-score,
	&.hide-scores .position-score,
	&.hide-scores .player__stat-score {
		display: none;
	}

	.dragging > :not(.droppable):not(main) {
		filter: blur(2px);
	}

	.field-position--placed,
	.field-position--dragging {
		background: transparent;
		border: 1px dashed ${rgba(palette.white, 1)};
		border-radius: var(--radius-3);
		padding: 0;
		text-align: center;
		z-index: 0;
		font-weight: var(--font-weight-semibold);
		position: relative;
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.field-position.has-player {
		flex-flow: column;

		.field-position__title {
			margin-bottom: 3px;
		}
	}

	.position-score:not(:empty) {
		margin-left: 5px;
	}

	@media all and (min-width: 641px) and (max-width: 768px) and (orientation: landscape),
		all and (max-width: 640px) and (orientation: landscape) {
		.field-position--placed,
		.field-position--dragging {
			padding: 0;
			border-width: 2px;
		}
	}

	.field-position--placed.has-player,
	.field-position--dragging.has-player {
		border: none;
	}

	.field-position--placed .player-wrapper,
	.field-position--dragging .player-wrapper {
		display: none;
	}

	.field-position--placed.has-player .player-wrapper,
	.field-position--dragging.has-player .player-wrapper {
		display: block;
	}

	.field-position--placed {
		width: 80px;
		height: 80px;
	}

	.field-position--dragging {
		z-index: 10;
		position: absolute;
		width: 50px;
		height: 50px;
		cursor: grabbing;
	}

	.field-position--dragging .remove {
		display: none;
	}

	.field-position.player-hover {
		border-style: solid;
		border-width: 2px;
		border-color: white;
		background-color: ${rgba(palette.white, 0.1)};
	}

	.field-position__title {
		font-weight: var(--font-weight-semibold);
		color: ${palette.white};
		font-size: var(--font-size-base);
		text-align: center;
		cursor: grab;
	}

	.ui-draggable {
		cursor: grab;

		&.ui-draggable-dragging {
			cursor: grabbing;
		}
	}

	.player {
		z-index: 100;
		position: relative;
		width: 100%;
		flex: 0 0 auto;
		overflow: visible;
		cursor: grab;
	}
	@media all and (min-width: 641px) and (max-width: 768px) and (orientation: landscape),
		all and (max-width: 640px) and (orientation: landscape) {
		.player {
			padding: 3px;
		}
	}

	.player.locked .${iconStyles.icon} {
		display: block;
	}

	.player.placed {
		width: 100%;
	}
	.player.ui-draggable-dragging {
		opacity: 0.9;
		width: 100px;
		height: 117.64705882px;
		transform: translate(-50%, -30%);
		cursor: grabbing;
	}
	@media all and (max-width: 640px) {
		.player.ui-draggable-dragging {
			width: 60px;
			height: 70.58823529px;
		}
	}
	@media all and (min-width: 641px) and (max-width: 768px) and (orientation: landscape),
		all and (max-width: 640px) and (orientation: landscape) {
		.player.ui-draggable-dragging {
			width: 35px;
			height: 41.17647059px;
		}
	}

	.player__content {
		z-index: 1;
		display: flex;
		flex-flow: column;
		align-items: center;
	}

	.player__names {
		text-align: center;
		color: black;
		flex: 0 0 auto;
		font-size: 12px;
		line-height: 1;
		white-space: nowrap;
		background: ${palette.white};
		padding: 6px 4px;
		overflow: hidden;
		border-radius: var(--radius-2);
		z-index: 1;
	}

	@media all and (min-width: 641px) and (max-width: 768px) and (orientation: landscape),
		all and (max-width: 640px) and (orientation: landscape) {
		.player__names {
			display: none;
		}
	}

	.player-top-position {
		color: ${palette.white};
		font-weight: var(--font-weight-normal);
		font-size: 11px;
		white-space: nowrap;
		margin-top: 2px;
	}

	@media all and (max-width: 640px) and (orientation: portrait),
		all and (max-width: 640px) and (orientation: landscape),
		all and (min-width: 641px) and (max-width: 768px) and (orientation: landscape),
		all and (min-width: 769px) and (max-width: 1100px) and (orientation: landscape),
		all and (min-width: 1101px) and (max-height: 730px) {
		.player-top-position {
			display: none;
		}
	}

	.player__stat-label {
		margin-right: 7px;
		color: ${palette.white};
	}

	.player__image {
		width: 44px;
		height: 44px;
		margin: 0 0 -5px 0;
		border: 2px solid ${palette.white};
		border-radius: 50%;
		position: relative;

		.${iconStyles.icon} {
			display: none;
			color: ${palette.black};
			background: ${palette.white};
			padding: 2px;
			border-radius: var(--radius-3);
			position: absolute;
			top: -4px;
			right: -4px;
			font-size: 18px;
			z-index: 2;
		}
	}

	.toggle-switch {
		user-select: none;
		position: relative;
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		cursor: pointer;
	}
	.toggle-switch__label {
		margin-bottom: 10px;
		font-size: 12px;
		cursor: pointer;
	}
	.toggle-switch input {
		display: none;
	}
	.toggle-switch__toggle {
		height: 30px;
		width: 80px;
		border-radius: 15px;
		background: #fff;
		box-shadow: inset 0 0 1px 1px #eee;
		position: relative;
		display: inline-block;
		transition: all 150ms;
		pointer-events: none;
	}
	.toggle-switch__toggle:active::before {
		width: 40px;
	}
	.toggle-switch__toggle::before {
		content: '';
		box-sizing: border-box;
		position: absolute;
		height: 30px;
		width: 30px;
		border-radius: 15px;
		border: 1px solid #eee;
		box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
		background: #fff;
		top: 0;
		left: 0;
		margin-left: 0;
		transition: all 150ms;
		z-index: 1;
	}
	.toggle-switch__toggle::after {
		content: attr(data-off-label);
		box-sizing: border-box;
		display: block;
		width: auto;
		line-height: 30px;
		vertical-align: middle;
		text-align: right;
		padding: 0 20px 0 40px;
		font-weight: var(--font-weight-semibold);
		text-transform: uppercase;
		font-size: 12px;
		transition: all 150ms;
		white-space: nowrap;
	}
	input:checked ~ .toggle-switch__toggle {
		box-shadow: inset 0 0 15px 15px #0b78e3;
		color: #fff;
	}
	input:checked ~ .toggle-switch__toggle:active::before {
		width: 40px;
		margin-left: -40px;
	}
	input:checked ~ .toggle-switch__toggle::before {
		left: 100%;
		margin-left: -30px;
	}
	input:checked ~ .toggle-switch__toggle::after {
		text-align: left;
		padding: 0 40px 0 20px;
		content: attr(data-on-label);
	}
`;

export default FormationWrapper;
