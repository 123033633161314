import { ReactNode } from 'react';

export const symbol_pet_supplies: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M368-80q-54 0-90-36t-36-89.794q0-8.206 1.5-21.706T243-243q-2-2-15.5-.5t-21.706 1.5Q152-242 116-278t-36-90q0-54 36-90t90-36q36 0 61.5 13t38.5 25l198-198q-11-13-24.5-38.5T466-754q0-54 36-90t90-36q54 0 90 36t36 89.794q0 8.206-1.5 21.706t.5 15.5q2 2 15.5.5t21.706-1.5Q808-718 844-682t36 90q0 54-36 90t-90 36q-36 0-61.5-13.5T654-504L456-306q12 13 25 38.5t13 61.5q0 54-36 90t-90 36Zm0-61q28.6 0 47.3-18 18.7-18 18.7-47 0-23-10-40t-18-26q-15-18-12.5-38.5T413-348l198-198q16.579-17.111 38.289-19.556Q671-568 688-554q9 8 26 18t40 10q28.6 0 47.3-18.7Q820-563.4 820-592q0-28.6-18.7-47.3Q782.6-658 754-658q-26 0-47-2t-33-14q-12-12-14-33t-2-47q0-28.6-18.7-47.3Q620.6-820 592-820q-28.6 0-47.3 18.7Q526-782.6 526-754q0 23 10 40t18 26q15 18 12.5 38.5T547-612L349-414q-16.579 17.111-38.289 19.556Q289-392 272-406q-9-8-26-18t-40-10q-29 0-47 18.7T141-368q0 28.6 18 47.3 18 18.7 47 18.7 26 0 47 2t33 14q12 12 14 33t2 47q0 29 18.7 47t47.3 18Zm113-340Z"
		/>
	</svg>
);
