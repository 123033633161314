import { Record, Map } from 'immutable';

const createActivity = Record({
	completedAt: null,
	createdAt: null,
	deletedAt: null,
	description: null,
	id: null,
	links: {},
	physicalStrain: null,
	ratingItem: {},
	ratingItemId: null,
	updatedAt: null,
	// @todo: put this user in users.users
	user: new Record({
		id: null,
		firstName: null,
		lastName: null,
		email: null,
		birthDate: null,
		meta: Map(),
		updatedAt: null,
		createdAt: null,
		language: null,
		countryId: null,
		activitySummary: null,
	}),
	userId: null,
	type: null,
	commentCount: null,
	likeCount: null,
	likedByCurrentUser: null,
	comments: null,
	likes: null,
	likeCount: null,
});

const makeActivity = (acticity) => createActivity(acticity);

export default {
	type: createActivity,
	new: makeActivity,
};
