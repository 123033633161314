import { useT } from '@transifex/react';

import * as models from 'pkg/api/models';

import { UnauthenticatedPublicShowForm } from 'routes/public/registration';

import Label from 'components/label';
import AssetImage from 'components/AssetImage';

interface Props {
	form: models.form.Form | UnauthenticatedPublicShowForm;
}

const AvailableSpots = ({ form }: Props): JSX.Element => {
	const t = useT();

	let availabilityText = t(
		'Available seats: {availableSpots} of {maxSubmissions} ',
		{
			availableSpots: form.spotsLeft,
			maxSubmissions: form.maxSubmissions,
		}
	);

	if (form.isFull) {
		availabilityText = t('No seats left');
	}

	let fireThreshold = 0.1;

	if (form.maxSubmissions <= 10) {
		fireThreshold = 0.3;
	}

	const showFireSvg =
		form.spotsLeft / form.maxSubmissions < fireThreshold &&
		form.spotsLeft !== 0;

	return (
		<Label color="orange">
			{showFireSvg && <AssetImage src="img/fire.svg" />}
			{availabilityText}
		</Label>
	);
};

export default AvailableSpots;
