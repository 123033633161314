export function Create(): string {
	return `/v1/performance-reviews`;
}

export function Delete(ID: number): string {
	return `/v1/performance-reviews/${ID}`;
}

export function Index(): string {
	return `/v1/performance-reviews`;
}

export function Update(ID: number): string {
	return `/v1/performance-reviews/${ID}`;
}
