import { t } from '@transifex/native';

import { FilterModalProps } from 'pkg/filters/types/Modal';
import * as models from 'pkg/api/models';
import { CollectionPagination } from 'pkg/api/use_collection';

import MaterialSymbol from 'components/material-symbols';
import Pagination from 'components/pagination';
import * as StepModal from 'components/step-modal';

import SearchInput from 'components/form/SearchInput';
import * as ActionBar from 'components/layout/ActionBar';
import * as Input from 'components/form/inputs';

import * as Table from 'design/table';

interface Props extends FilterModalProps {
	discounts: models.discount.Discount[];
	pagination: CollectionPagination;
	isLoading: boolean;
	handleSearch: (value: string) => void;
}

const SelectDiscountsModal = ({
	discounts,
	pagination,
	isLoading,
	handleSearch,

	selected = [],
	handleClose,
	handleNext,
	handleSelectItem,
	handleSelectAll,
}: Props) => {
	return (
		<StepModal.Base onClose={handleClose}>
			<StepModal.Step
				title={t('Select discounts')}
				nextLabel={t('Done')}
				onNext={handleNext}
				skipBody>
				<ActionBar.Bar>
					<ActionBar.PrimaryAction>
						<SearchInput
							onChange={handleSearch}
							placeholder={t('Search discounts...')}
						/>
					</ActionBar.PrimaryAction>
				</ActionBar.Bar>
				<Table.Table
					columns={[
						{
							content: (
								<Input.Control
									type="checkbox"
									standalone
									checked={
										pagination.totalCount > 0 &&
										pagination.totalCount === selected.length
									}
									onChange={() =>
										handleSelectAll(
											pagination.totalCount === selected.length
												? []
												: discounts.map((discount) => discount.id)
										)
									}
								/>
							),
							width: 'auto',
						},
						{ content: t('Title') },
						{ content: t('Code') },
					]}
					isLoading={isLoading}
					emptyState={{
						image: <MaterialSymbol variant="error" scale={1.6} />,
						title: t('No result'),
						content: t('No applied discounts found'),
					}}>
					{discounts.map((discount) => (
						<Table.Row>
							<Table.Cell onClick={() => handleSelectItem(discount.id)}>
								<Input.Control
									type="checkbox"
									value={discount.id}
									checked={selected.includes(discount.id)}
								/>
							</Table.Cell>
							<Table.Cell>{discount.title}</Table.Cell>
							<Table.Cell>{discount.code}</Table.Cell>
						</Table.Row>
					))}
				</Table.Table>
				<Pagination {...pagination} />
			</StepModal.Step>
		</StepModal.Base>
	);
};

export default SelectDiscountsModal;
