import { ReactNode } from 'react';

export const symbol_stockpot: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M210-160q-37.5 0-63.75-26.25T120-250v-378q0-13.175 8.625-22.088Q137.25-659 150-659h659q13.175 0 22.088 8.912Q840-641.175 840-628v378q0 37.5-26.25 63.75T750-160H210Zm-30-439v349q0 12.75 8.625 21.375T210-220h540q12.75 0 21.375-8.625T780-250v-349H180Zm190-161v-50q0-12.75 8.625-21.375T400-840h160q12.75 0 21.375 8.625T590-810v50h220q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q840-717 831.088-708q-8.913 9-22.088 9H150q-12.75 0-21.375-8.877t-8.625-22q0-13.123 8.913-21.623Q137.825-760 151-760h219Zm110 350Z"
		/>
	</svg>
);
