import { Fragment } from 'react';
import { t } from '@transifex/native';

import { formatPriceIntervalToText } from 'pkg/utils';
import { formatToCurrency } from 'pkg/i18n/formatters';
import { useCurrentGroup } from 'pkg/identity';

import {
	PriceOptionData,
	ProductMixedState,
} from 'routes/payments/products/create';

import SectionTitle from 'components/SectionTitle';

import * as css from './styles.css';

interface ProductInfoProps {
	product: ProductMixedState;
}

interface PriceOptionProps {
	item: PriceOptionData;
}

const PriceOption = ({ item }: PriceOptionProps): JSX.Element => {
	const group = useCurrentGroup();
	const taxRateItem = item.taxRate;

	const tax = taxRateItem.id ? (item.cost * taxRateItem.percentage) / 100 : 0;
	const fullPrice =
		taxRateItem.inclusive || !taxRateItem.id ? item.cost : item.cost + tax;
	const priceString = formatToCurrency(fullPrice, group.currency);
	const taxPrice = formatToCurrency(tax, group.currency);

	if (item.recurring === null) return null;

	return (
		<div>
			<div className={css.cost}>
				{priceString},{' '}
				{formatPriceIntervalToText(
					item.recurringInterval,
					item.recurringIntervalCount
				)}
			</div>
			{taxRateItem.id && (
				<div className={css.grayText}>
					{`${taxRateItem.percentage}% ${taxRateItem.display_name} (${
						taxRateItem.inclusive ? t('Inclusive') : t('Exclusive')
					}), ${taxPrice}`}
				</div>
			)}
		</div>
	);
};

const ProductInfo = ({ product }: ProductInfoProps): JSX.Element => {
	return (
		<Fragment>
			<div className={css.title}>{product.productTitle}</div>
			{product.description && <div>{product.description}</div>}
			<SectionTitle>{t('Pricing')}</SectionTitle>
			{product.priceOptions.map((item, index) => (
				<PriceOption item={item} key={index} />
			))}
		</Fragment>
	);
};

export default ProductInfo;
