import { ReactNode } from 'react';

export const symbol_first_page: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M270-240q-13 0-21.5-8.5T240-270v-420q0-13 8.5-21.5T270-720q13 0 21.5 8.5T300-690v420q0 13-8.5 21.5T270-240Zm269-237 170 170q9 9 8.5 21t-9.5 21q-9 9-21.5 9t-21.5-9L474-456q-5-5-7-10t-2-11q0-6 2-11t7-10l192-192q9-9 21.5-9t21.5 9q9 9 9 21.5t-9 21.5L539-477Z"
		/>
	</svg>
);
