import { ReactNode } from 'react';

export const symbol_scan_delete: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M220-820v186-186 680-11.5 11.5-680Zm0 740q-24 0-42-18t-18-42v-680q0-24 18-42t42-18h336q12.444 0 23.722 5T599-862l183 183q8 8 13 19.278 5 11.278 5 23.722v182q0 13-8.675 21-8.676 8-21.5 8-12.825 0-21.325-8.625T740-455v-179H611q-24.75 0-42.375-17.625T551-694v-126H220v680h321q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q571-97 562.375-88.5T541-80H220Zm500-117-63 62q-9 8-21.1 8.5-12.1.5-20.9-8.5-9-9-9-21t9-21l63-63-63-63q-9-9-9-21t9-21q9-9 21-9t21 9l63 63 63-63q9-9 21-9t21 9q9 9 8.5 21t-8.5 21l-62 63 62 63q8 9 8.5 21.1.5 12.1-8.5 20.9-9 9-21 8.5t-21-8.5l-63-62Z"
		/>
	</svg>
);
