import * as models from 'pkg/api/models';
import { formatToCurrency } from 'pkg/i18n/formatters';

import * as CardList from 'components/card-list';
import Icon from 'components/icon';

interface DiscountCardListItemProps {
	discount: models.discount.Discount;
}

export default function DiscountCardListItem({
	discount,
}: DiscountCardListItemProps) {
	return (
		<CardList.Base>
			<CardList.IconWrapper>
				<Icon name="discount" />
			</CardList.IconWrapper>
			<CardList.Title>
				{discount.title}
				<CardList.SubTitle>
					{discount.percentOff
						? `${discount.percentOff}%`
						: formatToCurrency(discount.amountOff, discount.currency)}
				</CardList.SubTitle>
			</CardList.Title>
		</CardList.Base>
	);
}
