import { Record } from 'immutable';

import { Countries } from 'pkg/models/schemas';

const CountryRecord = Record(
	{
		id: 0,
		name: '',
		code: '',
		createdAt: 0,
		updatedAt: 0,
		consentAgeLimit: 0,
	},
	'Country'
);

export default class Country extends CountryRecord {
	static normalizr() {
		return Countries;
	}
}
