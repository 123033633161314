import { ReactNode } from 'react';

export const symbol_home_and_garden: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-865q9.551 0 18.735 3 9.183 3 17.265 9l381 291q9.941 7.619 11.471 19.81Q910-530 902-520q-8 10-19.826 12T860-514L480-805 220-605.667V-220h202q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T422-160H220q-24.75 0-42.375-17.625T160-220v-340l-60 46q-9.609 8-21.804 6Q66-510 58-520.37q-8-9.63-6.056-21.82Q53.89-554.381 64-562l380-291q8.082-6 17.265-9 9.184-3 18.735-3Zm0 382ZM700-77q-44 30-90.5 27T528-88q-35-35-38-82t27-90q-30-44-27-90.5t38-81.5q35-35 82-38t90 27q44-30 90.5-27t81.5 38q35 35 38 82t-27 90q30 44 27 90.5T872-88q-35 35-82 38t-90-27Zm0-74 40 28q21 14 46.5 12.5T830-130q18-18 19.5-44T837-220l-28-40 28-40q14-21 12.5-46.5T830-390q-18-18-44-19.5T740-397l-40 28-40-28q-21-14-46.5-12.5T570-390q-18 18-19.5 44t12.5 46l28 40-28 40q-14 21-12.5 46.5T570-130q18 18 44 19.5t46-12.5l40-28Zm.141-69Q717-220 728.5-231.641t11.5-28.5Q740-277 728.359-288.5t-28.5-11.5Q683-300 671.5-288.359t-11.5 28.5Q660-243 671.641-231.5t28.5 11.5ZM700-260Z"
		/>
	</svg>
);
