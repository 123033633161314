import { css, DefaultTheme } from 'styled-components';

import uuid from 'pkg/uuid';

interface CSSVar {
	key: string;
	cb: (theme: DefaultTheme) => string;
}

const vars: CSSVar[] = [];

/**
 * addCssVar is a helper function to avoid building styled components with theme
 * dependencies. The theme dependency makes the component require a context, which can be
 * expensive. Updating a CSS variable in contrast is much faster.
 * @param cb Callback function to check current theme values. The callback should return
 * the expected CSS value.
 * @returns A CSS Variable
 */
export function addCssVar(cb: (theme: DefaultTheme) => string): string {
	const varName = `--${uuid()}`;

	vars.push({
		key: varName,
		cb: cb,
	});

	return `var(${varName})`;
}

export function getCssVars(theme: DefaultTheme): any {
	const varList = vars.map((cssVar) => `${cssVar.key}: ${cssVar.cb(theme)}`);

	return css`
		${varList.join(';\n')}
	`;
}
