import { t } from '@transifex/native';
import { createRef, Component } from 'react';
import styled from 'styled-components';

import { PageWidths } from 'pkg/config/sizes';
import { small } from 'pkg/config/breakpoints';

import CardWrapper, * as Card from 'components/Card';
import Icon from 'components/icon';

import Button from 'design/button';

const CardBase = styled(CardWrapper)`
	max-width: ${PageWidths.STANDARD}px;
	margin: 0 auto;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	width: 100%;
	position: relative;
	max-height: 100%;
	display: flex;
	flex-flow: column;
	justify-content: space-between;
	align-self: start;

	@media ${small} {
		align-self: auto;
	}

	${Card.Body} {
		padding: 0;
		height: auto;
		width: 100%;
		display: flex;
		flex: 1;
	}
`;

const CardScrollable = styled.div`
	display: flex;
	flex-flow: column;
	justify-content: flex-start;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	width: 100%;
	height: 100%;
`;

const PageHeading = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: column;
	margin-top: 2rem;
`;

const SectionHeading = styled.h1`
	font-size: var(--font-size-2xl);
	font-weight: var(--font-weight-semibold);
	text-align: center;
	margin-bottom: 1rem;

	@media ${small} {
		max-width: 60%;
	}
`;

const SectionDescription = styled.div`
	text-align: center;
	max-width: 50%;
	font-weight: var(--font-weight-normal);

	@media ${small} {
		max-width: 70%;
	}
`;

const CardFooter = styled(Card.Footer)`
	width: 100%;
	position: sticky;
	bottom: 0;
	z-index: 100;
`;

export default class SectionWrapper extends Component {
	constructor() {
		super();
		this.cardScroller = createRef();
	}

	scrollTop = () => {
		this.cardScroller.current.scrollTop = 0;
	};

	get backLink() {
		if (this.props.prev || this.props.prev === '') {
			let buttonText = t('Previous');
			if (this.props.prevTitle) {
				buttonText = this.props.prevTitle;
			}

			return (
				<Button large block onClick={this.props.goBack}>
					<Icon name="chevron" rotate="180deg" /> {buttonText}
				</Button>
			);
		}

		return null;
	}

	get nextLink() {
		if (this.props.next) {
			const buttonText = this.props.nextTitle
				? this.props.nextTitle
				: t('Next');

			return (
				<Button
					primary
					block
					large
					disabled={!this.props.canNavigate}
					onClick={this.props.goToNext}
					testid="step_modal.next">
					{buttonText}
				</Button>
			);
		}

		return null;
	}

	get cardFooter() {
		if (this.props.showNavigation) {
			return (
				<CardFooter>
					{this.backLink}
					{this.nextLink}
				</CardFooter>
			);
		}

		return null;
	}

	get stackDescription() {
		if (this.props.stackDescription) {
			return (
				<SectionDescription key="stack-description">
					<p>{this.props.stackDescription}</p>
				</SectionDescription>
			);
		}

		return null;
	}

	get cardHeader() {
		if (!(this.props.stackTitle || this.props.stackDescription)) {
			return null;
		}

		let headings = [];
		if (this.props.stackTitle) {
			headings.push(
				<SectionHeading key="section-heading">
					{this.props.stackTitle}
				</SectionHeading>
			);
		}

		if (this.props.stackDescription) {
			headings.push(this.stackDescription);
		}

		return <PageHeading>{headings}</PageHeading>;
	}

	render() {
		return (
			<CardBase
				hasHeader={this.props.stackTitle}
				className={this.props.className}>
				<CardScrollable ref={this.cardScroller}>
					{this.cardHeader}

					<Card.Body $flex>{this.props.children}</Card.Body>
				</CardScrollable>

				{this.cardFooter}
			</CardBase>
		);
	}
}
