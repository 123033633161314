import { ReactNode } from 'react';

export const symbol_battery_profile: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M340-140Zm-30 60q-12.75 0-21.375-8.625T280-110v-676q0-12.75 8.625-21.375T310-816h90v-34q0-12.75 8.625-21.375T430-880h100q12.75 0 21.375 8.625T560-850v34h90q12.75 0 21.375 8.625T680-786v220q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T620-566v-190H340v616h129q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q499-97 490.375-88.5T469-80H310Zm358.625 0Q663-80 659-83.5t-4.909-9.127L650-128q-20-6-41-17.5T575-171l-30 14.118q-5 1.882-10.5.882-5.5-1-8.875-5.755l-20.25-32.49Q502-199 503-204q1-5 6-9l27-22q-5-23-5-41.5t5-41.5l-27-22q-5-4-6-9t2.375-9.868l20.25-33.264Q529-397 534.5-398t10.5.882L575-383q13-12 34-24t41-18l4.091-36.235Q655-467 659-470.5q4-3.5 9.625-3.5h33.75q5.625 0 9.625 3.5t4.909 9.265L721-425q20 6 41 18t34 24l30-14.118q5-1.882 10.5-.882 5.5 1 8.875 5.868l20.25 33.264Q869-354 868-349t-6 9l-27 22q5 23 5 41.5t-5 41.5l27 22q5 4 6 9t-2.375 9.755l-20.25 32.49Q842-157 836.5-156t-10.4-1L796-171q-13 14-34 25.5T721-128l-4.091 35.373Q716-87 712-83.5q-4 3.5-9.625 3.5h-33.75ZM685-175q44 0 73-29t29-73q0-44-29-73t-73-29q-44 0-73 29t-29 73q0 44 29 73t73 29Z"
		/>
	</svg>
);
