import { ReactNode } from 'react';

export const symbol_spo2: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M486-166q-14 0-24-10t-10-24v-160q0-14 10-24t24-10h100q14 0 24 10t10 24v160q0 14-10 24t-24 10H486Zm14-48h72v-132h-72v132ZM836-80H714q-15 0-24.5-9.5T680-114v-76q0-15 9.5-24.5T714-224h98v-48H704q-10 0-17-7t-7-17q0-10 7-17t17-7h122q15 0 24.5 9.5T860-286v76q0 15-9.5 24.5T826-176h-98v48h108q10 0 17 7t7 17q0 10-7 17t-17 7ZM400-870q5 0 10 2t10 6q39 35 81 75.5t80.5 85q38.5 44.5 70 92.5t49.5 98q5 12-1.5 23T681-474q-12 3-22.5-3T643-495q-16-42-43.5-84t-61-81.5Q505-700 469-735.5T400-800Q273-688 206.5-587.5T140-408q0 97 56.5 171T350-145q14 2 23 12.5t5 22.5q-4 14-17.5 20T333-87q-116-23-184.5-114T80-408q0-66 30-130t75-122.5q45-58.5 97.5-110T380-862q5-4 10-6t10-2Zm-4 462Z"
		/>
	</svg>
);
