import { ReactNode } from 'react';

export const symbol_person_pin_circle: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-159q133-121 196.5-219.5T740-552q0-117.79-75.292-192.895Q589.417-820 480-820t-184.708 75.105Q220-669.79 220-552q0 75 65 173.5T480-159Zm-.25 59q-10.75 0-21.25-3.5T440-115q-42-38-91-87.5T258-309q-42-57-70-119t-28-124q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 62-28 124t-70 119q-42 57-91 106.5T520-115q-8 8-18.75 11.5t-21.5 3.5Zm.25-460Zm0 190q51.397 0 92.699-24Q614-418 639-458q-34-26-74.215-39t-85-13Q435-510 395-497t-74 39q25 40 66.301 64 41.302 24 92.699 24Zm.089-200Q509-570 529.5-590.589q20.5-20.588 20.5-49.5Q550-669 529.411-689.5q-20.588-20.5-49.5-20.5Q451-710 430.5-689.411q-20.5 20.588-20.5 49.5Q410-611 430.589-590.5q20.588 20.5 49.5 20.5Z"
		/>
	</svg>
);
