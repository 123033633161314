import { ReactNode } from 'react';

import { cssClasses } from 'pkg/css/utils';

import * as css from './styles.css';

interface PanelProps {
	footer?: boolean;
	children: ReactNode | ReactNode[];
}

export default function Panel({ footer, children }: PanelProps): JSX.Element {
	return (
		<section className={cssClasses(css.panel, footer ? css.footer : null)}>
			{children}
		</section>
	);
}
