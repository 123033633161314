import styled from 'styled-components';

import * as styles from 'pkg/config/styles';

export const ListItem = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const InfoWrapper = styled.div`
	display: grid;
	grid-auto-flow: column;
	justify-content: start;
	align-items: center;
	grid-gap: var(--spacing-4);
`;

export const Name = styled.div`
	font-size: var(--font-size-sm);
	font-weight: var(--font-weight-semibold);

	@media ${styles.breakpoint.small} {
		font-size: var(--font-size-base);
	}
`;

export const Role = styled.div`
	font-size: var(--font-size-xs);
	color: var(--palette-gray-500);

	@media ${styles.breakpoint.small} {
		font-size: var(--font-size-sm);
	}

	span {
		color: var(--palette-red-500);

		&:after {
			content: ' ';
		}
	}
`;

export const Comment = styled.div`
	font-size: var(--font-size-sm);
	color: var(--palette-gray-600);
`;
