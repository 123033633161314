import { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';

import * as styles from 'pkg/config/styles';

import Link from 'pkg/router/Link';
import * as models from 'pkg/api/models';

import Icon from 'components/icon';

import * as iconStyles from 'components/icon/styles.css';

const Wrapper = styled.figure<{
	align: 'left' | 'right';
	isFirstGroupItem: boolean;
	isLastGroupItem: boolean;
}>`
	display: grid;
	margin: 0;
	overflow: hidden;

	${({ align, isFirstGroupItem, isLastGroupItem }) =>
		align === 'left' &&
		css`
			border-radius: 0.5rem 1.5rem 1.5rem 0.5rem;

			${isFirstGroupItem &&
			css`
				border-top-left-radius: 1.5rem;
			`};

			${isLastGroupItem &&
			css`
				border-bottom-left-radius: 1.5rem;
			`};
		`};

	${({ align, isFirstGroupItem, isLastGroupItem }) =>
		align === 'right' &&
		css`
			border-radius: 1.5rem 0.5rem 0.5rem 1.5rem;

			${isFirstGroupItem &&
			css`
				border-top-right-radius: 1.5rem;
			`};

			${isLastGroupItem &&
			css`
				border-bottom-right-radius: 1.5rem;
			`};
		`};

	figcaption {
		display: grid;
		grid-template-areas:
			'icon title download'
			'icon meta download';
		grid-template-columns: auto 1fr auto;
		grid-gap: 0.3rem 0.7rem;
		min-width: 0;

		div {
			display: flex;
			align-items: center;
			color: var(--palette-blue-500);

			&:first-child {
				grid-area: icon;

				.${iconStyles.icon} {
					font-size: 1.3rem;
				}
			}

			&:last-child {
				grid-area: download;

				.${iconStyles.icon} {
					font-size: 1.5rem;
				}
			}
		}

		strong {
			grid-area: title;
			font-size: var(--font-size-sm);
			font-weight: var(--font-weight-semibold);
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			color: var(--palette-blue-500);
			line-height: 1.1;
			max-width: 10rem;
		}

		span {
			grid-area: meta;
			font-size: 0.65rem;
			font-weight: var(--font-weight-semibold);
			line-height: 1.1;
			color: var(--palette-gray-500);
			text-transform: uppercase;
		}
	}
`;

const ImageSource = styled.img<{ loaded: boolean }>`
	display: block;
	max-width: 240px;
	height: auto;
	max-height: 320px;
	transition: opacity 250ms ease-in-out;
	opacity: ${(props) => (props.loaded ? 1 : 0)};
	cursor: pointer;

	@media ${styles.breakpoint.small} {
		max-width: 100%;
	}
`;

interface ImageProps {
	src: string;

	onLoad: () => void;
}

function Image({ src, onLoad }: ImageProps) {
	const [isLoaded, setIsLoaded] = useState(false);
	const imgRef = useRef(null);

	const handleDidLoad = () => {
		setIsLoaded(true);
		onLoad();
	};

	useEffect(() => {
		if (imgRef.current && imgRef.current.complete) {
			handleDidLoad();
		}

		// exhaustive-deps wants onLoad as a dep, we don't
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<ImageSource
			src={src}
			ref={imgRef}
			onLoad={handleDidLoad}
			loaded={isLoaded}
			loading="lazy"
		/>
	);
}

interface AttachmentThreadItemProps {
	isFirstGroupItem: boolean;
	isLastGroupItem: boolean;
	align: 'left' | 'right';

	onLoad: () => void;
	onClick: (attachment: models.attachment.Attachment) => void;

	attachment: models.attachment.Attachment;
}

function AttachmentThreadItem({
	attachment,
	onClick,
	onLoad,
	align,
	isFirstGroupItem,
	isLastGroupItem,
}: AttachmentThreadItemProps) {
	if (
		attachment.previewUrl !== '' ||
		(attachment.type === 'image' && attachment.size < 5 * 1024 * 1024)
	) {
		const handleOnClick = () => {
			onClick(attachment);
		};

		return (
			<Wrapper
				onClick={handleOnClick}
				align={align}
				isFirstGroupItem={isFirstGroupItem}
				isLastGroupItem={isLastGroupItem}>
				<Image src={attachment.previewUrl || attachment.url} onLoad={onLoad} />
			</Wrapper>
		);
	}

	const extension = models.attachment.getExtension(attachment);
	const fileSize = models.attachment.getHumanFileSize(attachment);

	return (
		<Wrapper as={Link} href={attachment.url} target="_blank">
			<figcaption>
				<div>
					<Icon name="attachment" />
				</div>
				<strong>{attachment.title}</strong>
				<span>
					{extension} - {fileSize}
				</span>
				<div>
					<Icon name="download-simple" />
				</div>
			</figcaption>
		</Wrapper>
	);
}

export default AttachmentThreadItem;
