import * as models from 'pkg/api/models';
import * as ls from 'pkg/local_storage';

export enum LoginTypes {
	SSO = 'sso',
	Apple = 'apple',
	Email = 'email',
}

export interface UserLogin {
	firstName: string;
	lastName: string;
	email?: string;
	type: LoginTypes;
	meta: { 'profile-image-url'?: string };
	identityProvider?: models.identityProvider.ScrubbedIdentityProvider;
	organizationId?: number;
}

export function storeLoginInfo(
	account: Partial<models.account.Account>,
	type: LoginTypes,
	identityProvider?: models.identityProvider.ScrubbedIdentityProvider,
	organizationId = 0
): void {
	const { firstName, lastName, email } = account;

	const next: UserLogin = {
		firstName,
		lastName,
		email,
		type,
		organizationId,
		meta: {},
	};

	if (account?.profileImageUrl) {
		next.meta['profile-image-url'] = account.profileImageUrl;
	}

	if (identityProvider) {
		next.identityProvider = identityProvider;
	}

	if (organizationId) {
		ls.setItem(
			ls.LocalStorageKeys.ActiveOrganizationId,
			organizationId.toString()
		);
	}

	ls.setJsonItem(ls.LocalStorageKeys.UserLogin, next);
}
