export default {
	name: 'check-circle',
	viewBox: '0 0 25 25',
	objects: [
		{
			type: 'path',
			attributes: {
				d: 'M9.01611 11.8902L10.9543 13.6857L15.1938 9.64019L16.1338 10.508L10.9543 15.4151L8.07611 12.758L9.01611 11.8902Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M12.0488 19.3346C15.9406 19.3346 19.0955 16.1797 19.0955 12.288C19.0955 8.3962 15.9406 5.24131 12.0488 5.24131C8.15704 5.24131 5.00215 8.3962 5.00215 12.288C5.00215 16.1797 8.15704 19.3346 12.0488 19.3346ZM12.0488 20.6346C16.6585 20.6346 20.3955 16.8977 20.3955 12.288C20.3955 7.67823 16.6585 3.94131 12.0488 3.94131C7.43907 3.94131 3.70215 7.67823 3.70215 12.288C3.70215 16.8977 7.43907 20.6346 12.0488 20.6346Z',
			},
		},
	],
};
