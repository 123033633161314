const number = {
	name: 'number',
	paths: [
		'M6.6444 8.23936V16.3899H5.16792V9.67605H5.12016L3.21387 10.8938V9.54073L5.23955 8.23936H6.6444Z',
		'M8.53006 16.3899V15.3233L11.3597 12.5494C11.6303 12.2761 11.8558 12.0334 12.0362 11.8211C12.2166 11.6089 12.3519 11.4032 12.4421 11.2043C12.5323 11.0053 12.5774 10.793 12.5774 10.5675C12.5774 10.3101 12.5191 10.0899 12.4023 9.90687C12.2856 9.72115 12.1251 9.57788 11.9208 9.47706C11.7165 9.37624 11.4843 9.32583 11.2243 9.32583C10.9564 9.32583 10.7216 9.38154 10.5199 9.49298C10.3183 9.60176 10.1618 9.75697 10.0503 9.95861C9.94154 10.1602 9.88715 10.4004 9.88715 10.6789H8.48231C8.48231 10.1616 8.60037 9.71186 8.8365 9.32981C9.07263 8.94775 9.39764 8.65193 9.81154 8.44233C10.2281 8.23273 10.7056 8.12793 11.2442 8.12793C11.7908 8.12793 12.271 8.23008 12.6849 8.43437C13.0988 8.63866 13.4198 8.91857 13.648 9.27409C13.8788 9.62961 13.9942 10.0355 13.9942 10.4919C13.9942 10.797 13.9359 11.0968 13.8191 11.3913C13.7024 11.6858 13.4968 12.0121 13.2023 12.3703C12.9104 12.7285 12.5005 13.1623 11.9725 13.6717L10.5677 15.1004V15.1561H14.1176V16.3899H8.53006Z',
		'M18.2319 16.5013C17.6588 16.5013 17.1494 16.4031 16.7037 16.2068C16.2606 16.0105 15.9104 15.7372 15.6531 15.387C15.3957 15.0367 15.2591 14.6321 15.2431 14.1731H16.7395C16.7528 14.3934 16.8257 14.5857 16.9584 14.7502C17.0911 14.912 17.2675 15.0381 17.4877 15.1283C17.7079 15.2185 17.9547 15.2636 18.2279 15.2636C18.5198 15.2636 18.7785 15.2132 19.004 15.1124C19.2295 15.0089 19.4059 14.8656 19.5333 14.6825C19.6606 14.4995 19.723 14.2886 19.7203 14.0498C19.723 13.803 19.6593 13.5855 19.5293 13.3971C19.3993 13.2087 19.2109 13.0615 18.9642 12.9553C18.7201 12.8492 18.4256 12.7962 18.0807 12.7962H17.3604V11.658H18.0807C18.3646 11.658 18.6126 11.6089 18.8249 11.5107C19.0398 11.4125 19.2083 11.2746 19.3303 11.0968C19.4524 10.9164 19.5121 10.7081 19.5094 10.472C19.5121 10.2412 19.4603 10.0409 19.3542 9.87105C19.2507 9.6986 19.1035 9.56461 18.9125 9.4691C18.7241 9.37359 18.5025 9.32583 18.2478 9.32583C17.9984 9.32583 17.7676 9.37093 17.5554 9.46114C17.3431 9.55135 17.172 9.68002 17.042 9.84717C16.912 10.0117 16.843 10.208 16.835 10.4362H15.4143C15.4249 9.97983 15.5562 9.57921 15.8083 9.2343C16.063 8.88673 16.4026 8.61611 16.8271 8.42243C17.2516 8.2261 17.7278 8.12793 18.2558 8.12793C18.7997 8.12793 19.272 8.23008 19.6726 8.43437C20.0759 8.63601 20.3876 8.90796 20.6078 9.25021C20.828 9.59247 20.9381 9.97055 20.9381 10.3844C20.9408 10.8434 20.8055 11.2281 20.5322 11.5386C20.2616 11.849 19.9061 12.0519 19.4656 12.1475V12.2111C20.0387 12.2907 20.4778 12.503 20.7829 12.8479C21.0907 13.1901 21.2432 13.616 21.2406 14.1254C21.2406 14.5817 21.1106 14.9903 20.8506 15.3511C20.5932 15.7093 20.2377 15.9906 19.784 16.1948C19.333 16.3991 18.8156 16.5013 18.2319 16.5013Z',
	],
};

export default number;
