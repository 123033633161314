import { ReactNode } from 'react';

export const symbol_transition_push: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M420-160h-53q-12 0-20.5-9t-8.5-21q0-12 9-20.5t21-8.5h52v-521h-52q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T368-800h52q24 0 42 18t18 42v520q0 24-18 42t-42 18Zm200 0q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h240q24 0 42 18t18 42v520q0 24-18 42t-42 18H620Zm0-59h240v-521H620v521ZM245-450H70q-12.75 0-21.375-8.675Q40-467.351 40-480.175 40-493 48.625-501.5T70-510h175l-55-55q-9-9-9-21t8.842-21q8.842-9 21-9T232-607l107 106q5 5 7 10.133 2 5.134 2 11Q348-474 346-469q-2 5-7 10L233-351q-9 9-21.5 9t-21.5-9.391q-9-8.609-9-20.805 0-12.195 9-20.804l55-57Zm375 231v-521 521Z"
		/>
	</svg>
);
