import { t } from '@transifex/native';

import { months } from 'pkg/date';

import * as Inputs from 'components/form/inputs';

interface InputProps {
	name?: string;
	defaultValue?: string;
}

const currentDate = new Date();

export const Year: React.FC<React.PropsWithChildren<InputProps>> = ({
	name = 'year',
	defaultValue = currentDate.getFullYear(),
}) => (
	<Inputs.Field
		name={name}
		type="number"
		min={currentDate.getFullYear() - 100}
		max={currentDate.getFullYear()}
		placeholder={t(`YYYY`)}
		pattern="(19[0-8][0-9]|199[0-9]|20[0-2][0-9]|2030)"
		defaultValue={defaultValue}
	/>
);

export const Month: React.FC<React.PropsWithChildren<InputProps>> = ({
	name = 'month',
	defaultValue = currentDate.getMonth().toString(),
}) => (
	<Inputs.Select name={name} defaultValue={defaultValue}>
		{months().map((month) => (
			<option key={month.toString()} value={month[0]}>
				{month[1]}
			</option>
		))}
	</Inputs.Select>
);

export const Day: React.FC<React.PropsWithChildren<InputProps>> = ({
	name = 'day',
	defaultValue = currentDate.getDate(),
}) => (
	<Inputs.Field
		name={name}
		type="number"
		min={1}
		max={31}
		placeholder={t(`DD`)}
		pattern="([1-9]|[12][0-9]|3[01])"
		defaultValue={defaultValue}
	/>
);
