import { ReactNode } from 'react';

export const symbol_aod_tablet: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M340-510q-8 0-14-6t-6-14q0-8 6-14t14-6h280q8 0 14 6t6 14q0 8-6 14t-14 6H340Zm40 120q-8 0-14-6t-6-14q0-8 6-14t14-6h200q8 0 14 6t6 14q0 8-6 14t-14 6H380ZM100-160q-24.75 0-42.375-17.625T40-220v-520q0-24.75 17.625-42.375T100-800h760q24.75 0 42.375 17.625T920-740v520q0 24.75-17.625 42.375T860-160H100Zm30-580h-30v520h30v-520Zm60 520h580v-520H190v520Zm640-520v520h30v-520h-30Zm0 0h30-30Zm-700 0h-30 30Z"
		/>
	</svg>
);
