import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as sdk from 'pkg/core/sdk';

const formatNumber = (number: string | number, defaultValue: number = null) =>
	isNaN(+number) ? defaultValue : Number.parseInt(number as string, 10);

const formatMatchPayload = (match: models.match.Match) => ({
	title: match.title,
	description: match.description,
	type: match.type,
	location: match.location.trim(),
	report: match.report ? match.report : null,
	notes: match.notes ? match.notes : null,
	halfTimeAddition: match.halfTimeAddition,
	fullTimeAddition: match.fullTimeAddition,
	goalCount: formatNumber(match.goalCount),
	goalCountHalfTime: formatNumber(match.goalCountHalfTime),
	opponentGoalCount: formatNumber(match.opponentGoalCount),
	opponentGoalCountHalfTime: formatNumber(match.opponentGoalCountHalfTime),
	opponentName: match.opponentName,
	externalId: match.externalId,
	startsAt: formatNumber(match.startsAt),
	isHome: match.isHome,
	duration: formatNumber(match.duration, 90),
	overtime: formatNumber(match.overtime, 0),
	matchCompetitionId: formatNumber(match.matchCompetitionId),
});

export interface MatchPayload {
	opponentName: string;
	startsAt: number;
	startsAtLocal?: string;
	duration: number;
	location: string;
	matchCompetitionId: number;
	type: string;
	isHome: boolean;
}

enum MatchTypes {
	Default = 'default',
	Practice = 'practice',
}

interface UpdatePayload {
	title?: string;
	description?: string;
	location?: string;
	report?: string;
	startsAt?: number;
	matchCompetitionId?: number;
	goalCountHalfTime?: number;
	opponentGoalCountHalfTime?: number;
	goalCount?: number;
	opponentGoalCount?: number;
	duration?: number;
	halfTimeAddition?: number;
	fullTimeAddition?: number;
	overtime?: number;
	notes?: string;
	isHome?: boolean;
	externalId?: string;
	opponentName?: string;
	type?: MatchTypes;
}

export const update = async (
	match: models.match.Match,
	payload: UpdatePayload
) => {
	const formattedPayload = formatMatchPayload({
		...match,
		...payload,
	} as models.match.Match);

	const req = await sdk.patch(
		endpoints.Match.Update(match.id),
		{},
		formattedPayload
	);

	if (!req.ok) {
		return [false, null];
	}

	const resp = await req.json();

	return [true, resp];
};
