import { ReactNode } from 'react';

export const symbol_bookmarks: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M420-167 203-75q-30 13-56.5-5.5T120-131v-609q0-24 18-42t42-18h480q24 0 42.5 18t18.5 42v610q0 32.489-26.5 50.244Q668-62 638-75l-218-92Zm0-67 240 103v-609H180v609l240-103Zm389.825 104Q797-130 788.5-138.625T780-160v-700H263q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T263-920h517q24 0 42 18t18 42v700q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM420-740H180h480-240Z"
		/>
	</svg>
);
