export default {
	name: 'help-circle',
	viewBox: '0 0 25 25',
	objects: [
		{
			type: 'path',
			attributes: {
				d: 'M11.2414 14.5538H12.6241V14.4459C12.6324 13.5656 12.9189 13.1628 13.5957 12.7476C14.3598 12.2908 14.8373 11.668 14.8373 10.7171C14.8373 9.31364 13.7452 8.43335 12.159 8.43335C10.7057 8.43335 9.5265 9.23889 9.48498 10.8168H10.9632C11.0006 10.0361 11.5653 9.66243 12.1507 9.66243C12.786 9.66243 13.2968 10.086 13.2968 10.7379C13.2968 11.3192 12.9106 11.7178 12.4082 12.0334C11.6732 12.4901 11.2497 12.951 11.2414 14.4459V14.5538Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M11.968 17.1448C12.458 17.1448 12.8774 16.7379 12.8815 16.2313C12.8774 15.7331 12.458 15.3261 11.968 15.3261C11.4615 15.3261 11.0504 15.7331 11.0545 16.2313C11.0504 16.7379 11.4615 17.1448 11.968 17.1448Z',
			},
		},
		{
			type: 'path',
			attributes: {
				clipRule: 'evenodd',
				fillRule: 'evenodd',
				d: 'M12.2139 19.818C16.0501 19.818 19.1599 16.7082 19.1599 12.872C19.1599 9.03581 16.0501 5.92598 12.2139 5.92598C8.37773 5.92598 5.2679 9.03581 5.2679 12.872C5.2679 16.7082 8.37773 19.818 12.2139 19.818ZM12.2139 21.118C16.7681 21.118 20.4599 17.4261 20.4599 12.872C20.4599 8.31784 16.7681 4.62598 12.2139 4.62598C7.65976 4.62598 3.9679 8.31784 3.9679 12.872C3.9679 17.4261 7.65976 21.118 12.2139 21.118Z',
			},
		},
	],
};
