import { ReactNode } from 'react';

export const symbol_acute: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M600-160q-134 0-227-93t-93-227q0-134 93-227t227-93q134 0 227 93t93 227q0 134-93 227t-227 93Zm-.235-60Q708-220 784-295.765q76-75.764 76-184Q860-588 784.235-664q-75.764-76-184-76Q492-740 416-664.235q-76 75.764-76 184Q340-372 415.765-296q75.764 76 184 76ZM630-493v-117q0-12-8.625-21T600-640q-12.75 0-21.375 8.5T570-610v130q0 5.565 2 10.783Q574-464 579-459l102 101q8.8 9 20.9 9 12.1 0 21.1-8.842 9-8.842 9-21T723-400l-93-93ZM110-610q-12.75 0-21.375-8.675Q80-627.351 80-640.175 80-653 88.625-661.5T110-670h100q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T210-610H110ZM70-450q-12.75 0-21.375-8.675Q40-467.351 40-480.175 40-493 48.625-501.5T70-510h140q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T210-450H70Zm40 160q-12.75 0-21.375-8.675Q80-307.351 80-320.175 80-333 88.625-341.5T110-350h100q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T210-290H110Zm490-190Z"
		/>
	</svg>
);
