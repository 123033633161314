import { Fragment } from 'react';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import { App as CapApp } from '@capacitor/app';

import * as palette from 'pkg/config/palette';

import { withBrowserState } from 'pkg/router/Router';
import { isAndroid } from 'pkg/platform';
import { getCssVars } from 'pkg/cssvars';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { useRouterState } from 'pkg/router/hooks';

import PublicRoutes from 'routes/public';

import Snackbar from 'containers/Snackbar';

import { ScrollSpy } from 'components/ScrollSpy';

import { Spinner } from 'components/loaders/spinner';
import { useAppState } from 'components/application/state';

import 'css/global.css';

const CSSVariables = createGlobalStyle`
		:root {
			${({ vars }) => vars}
		}
	`;

const Scroll = styled(ScrollSpy)`
	width: 100%;
	height: 100%;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	position: relative;
	z-index: 1;
	grid-area: content;
`;

const PublicPage = () => {
	const { language } = useAppState();
	const { popState } = useRouterState();

	useComponentDidMount(async () => {
		if (isAndroid()) {
			CapApp.addListener('backButton', ({ canGoBack }) => {
				if (!canGoBack) {
					CapApp.exitApp();
				} else {
					popState();
				}
			});
		}
	});

	if (!language) {
		return (
			<Fragment>
				<CSSVariables key="public-css-vars" vars={getCssVars({})} />
				<Spinner center color={palette.brand} />
			</Fragment>
		);
	}
	return (
		<ThemeProvider theme={{}}>
			<CSSVariables key="public-css-vars" vars={getCssVars({})} />
			<Snackbar />
			<Scroll>
				<PublicRoutes />
			</Scroll>
		</ThemeProvider>
	);
};

const RouterConnectedPage = withBrowserState(PublicPage);

export default RouterConnectedPage;
