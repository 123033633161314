import { ReactNode } from 'react';

export const symbol_bottom_right_click: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M150-120q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-180h630v-630q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T840-810v630q0 24.75-17.625 42.375T780-120H150Zm449.911-170Q571-290 550.5-310.589q-20.5-20.588-20.5-49.5Q530-389 550.589-409.5q20.588-20.5 49.5-20.5Q629-430 649.5-409.411q20.5 20.588 20.5 49.5Q670-331 649.411-310.5q-20.588 20.5-49.5 20.5ZM250-480q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T250-540h128L140-778q-9-8.8-9-20.9 0-12.1 9.053-21.1 9.052-9 21.5-9Q174-829 183-820l237 238v-128q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T480-710v200q0 12.75-8.625 21.375T450-480H250Z"
		/>
	</svg>
);
