export function CancelFormSubmission(ID: number): string {
	return `/v1/form-submissions/${ID}/cancel`;
}

export function Create(guid: string): string {
	return `/v1/form-submissions/${guid}/create`;
}

export function Delete(ID: number): string {
	return `/v1/form-submissions/${ID}`;
}

export function Export(): string {
	return `/v1/form-submissions/export`;
}

export function GetPaymentStatus(ID: number): string {
	return `/v1/form-submissions/${ID}/payment-status`;
}

export function Index(): string {
	return `/v1/form-submissions`;
}

export function Show(ID: number): string {
	return `/v1/form-submissions/${ID}`;
}
