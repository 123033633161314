import { createElement } from 'react';
import styled from 'styled-components';

import * as palette from 'pkg/config/palette';

import { SelectablePlayerSections } from 'components/football-field';

const PositionName = styled.p`
	color: ${palette.white};
`;

const PositionShortName = styled.span`
	font-size: 1.13rem;
	margin: 0;
	font-weight: var(--font-weight-normal);
	display: block;
`;

const PositionLongName = styled.span`
	font-size: var(--font-size-sm);
	margin: 0;
	opacity: 0.7;
	display: block;
`;

const PositionSection = (props) => {
	const children = [
		<PositionName key="position-name">
			<PositionShortName>{props.position.name}</PositionShortName>
			<PositionLongName>{props.position.longName}</PositionLongName>
		</PositionName>,
	];

	return createElement(
		SelectablePlayerSections[props.position.slug],
		{
			key: props.position.slug,
			onClick: props.onClick.bind(null, props.position),
			selected: props.selected,
		},
		children
	);
};

export default PositionSection;
