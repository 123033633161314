import { t } from '@transifex/native';
import { Fragment, useState } from 'react';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import { useCollection } from 'pkg/api/use_collection';
import { FilterGroups, useFilters } from 'pkg/filters/use_filters';
import { FilterOperator, createFilterGroups } from 'pkg/filters';

import ProductList from 'containers/payment_platform/assign-product/ProductList';

import Pagination from 'components/pagination';

import ActionBar from 'components/products/ActionBar';
import { Spinner } from 'components/loaders/spinner';

interface SelectProductProps {
	organizationId: number;
	selectedProductId: number;

	showPrices?: boolean;

	onSelect: (product: models.product.Product) => void;
	setCreateNew: (show: boolean) => void;
}

const SelectProduct: React.FC<React.PropsWithChildren<SelectProductProps>> = ({
	organizationId,
	selectedProductId,
	showPrices = true,
	onSelect,
	setCreateNew,
}) => {
	const [sortBy, setSortBy] = useState('createdAt');
	const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
	const { records: productCategories } =
		useCollection<models.productCategory.ProductCategory>(
			endpoints.ProductCategory.List(),
			{
				queryParams: new URLSearchParams({
					group_id: organizationId.toString(),
				}),
			}
		);

	const productCategoryFilters: { [key: string]: number } = {};

	for (const category of productCategories) {
		productCategoryFilters[category.name] = category.id;
	}

	const filterGroups: FilterGroups = createFilterGroups({
		[t(`Title`)]: {
			hidden: true,
			filters: {
				Name: {
					type: 'text',
					operator: FilterOperator.Contains,
					property: 'name',
				},
			},
		},
		[t(`Created at`)]: {
			filters: {
				Created: {
					type: 'date',
					property: 'createdAt',
					operator: FilterOperator.GreaterThan,
				},
			},
		},
		[t(`Category`)]: {
			filters: {
				Category: {
					type: 'checkbox',
					operator: FilterOperator.Includes,
					property: 'productCategoryId',
					values: productCategoryFilters,
				},
				Uncategorized: {
					type: 'checkbox',
					operator: FilterOperator.NotSet,
					property: 'productCategoryId',
					values: { [t(`New product`)]: 'true' },
				},
			},
		},
	});

	const filters = useFilters({
		groups: filterGroups,
		hideFiltersInUrl: true,
	});

	filters.queryParam.set('group_id', organizationId.toString());
	filters.queryParam.set('sort_by', sortBy);
	filters.queryParam.set('sort_order', sortOrder);
	filters.queryParam.set('version', '2');

	const {
		records: products,
		pagination,
		isLoading,
	} = useCollection<models.product.Product>(endpoints.Products.Index(), {
		queryParams: filters.queryParam,
	});

	const handleSelect = (productId: number) =>
		onSelect(products.find((product) => product.id === productId));
	const handleSort = (value: string, sortOrder: 'asc' | 'desc') => {
		setSortBy(value);
		setSortOrder(sortOrder);
	};
	const handleGoToCreate = () => setCreateNew(true);

	return (
		<Fragment>
			<ActionBar
				onGoToCreate={handleGoToCreate}
				searchFilter={{
					type: 'text',
					operator: FilterOperator.Contains,
					property: 'name',
				}}
				filterSetter={filters.setFilter}
			/>
			{isLoading ? (
				<Spinner />
			) : (
				<ProductList
					products={products}
					onSelect={handleSelect}
					selectedProductId={selectedProductId}
					showPrices={showPrices}
					onSort={handleSort}
					sortBy={sortBy}
					sortOrder={sortOrder}
					productCategories={productCategories}
				/>
			)}
			<Pagination {...pagination} />
		</Fragment>
	);
};

export default SelectProduct;
