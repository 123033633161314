import { Component } from 'react';
import styled from 'styled-components';

import { small } from 'pkg/config/breakpoints';

import { translatedRatingItemCategory } from 'pkg/api/models/user_rating';

import CategoryItem from 'components/rating/CategoryItem';
import SliderItem from 'components/rating/form/SliderItem';

const RatingItems = styled.div`
	display: flex;
	margin-top: 15px;
	grid-gap: 15px;
	height: 100%;
	width: 100%;
	flex-flow: column;

	@media ${small} {
		padding-bottom: 0;
		margin-top: 0;
	}
`;

export default class Categories extends Component {
	get items() {
		const items = {};

		this.props.ratingItems.forEach((item) => {
			items[item.id] = 0;
		});

		if (this.props[this.props.label]) {
			for (let id in this.props[this.props.label]) {
				items[id] = this.props[this.props.label][id];
			}
		}

		return items;
	}

	get sortedItems() {
		const _sortedItems = {};
		this.props.ratingItems.forEach((item) => {
			if (!_sortedItems[item.categorySlug]) {
				_sortedItems[item.categorySlug] = [];
			}

			_sortedItems[item.categorySlug].push(item);
		});

		return _sortedItems;
	}

	get categoryItems() {
		const items = this.items;
		const _categories = {};
		const _sortedItems = this.sortedItems;

		if (!items) {
			return _categories;
		}

		for (let n in _sortedItems) {
			let categoryItems = [];
			let categoryTotal = 0;
			let itemIds = [];

			for (let item of _sortedItems[n]) {
				categoryItems.push(item);
				categoryTotal += parseFloat(items[item.id]);
				itemIds.push(item.id);
			}

			_categories[n] = {
				categoryItems,
				categoryTotal,
				itemIds,
			};
		}

		return _categories;
	}

	get ratingItems() {
		let categories = {};
		const _categories = this.categoryItems;

		for (let key in _categories) {
			const { categoryItems, itemIds } = _categories[key];

			let items = categoryItems.map((item) => (
				<SliderItem
					item={item}
					onInput={this.setItemValue}
					value={this.items[item.id]}
					key={`${key}-rating-item-${item.id}`}
				/>
			));

			const categoryTotal = categoryItems.reduce(
				(sum, item) => sum + this.items[item.id],
				0
			);

			const itemCategoryProps = {
				label: translatedRatingItemCategory(key),
				key: `item-category-${key}`,
				onInput: this.setItemsByAverage,
				average: categoryTotal / items.length,
				itemIds: itemIds,
				slug: key,
			};

			categories[key] = (
				<CategoryItem {...itemCategoryProps}>{items}</CategoryItem>
			);
		}

		let printCategories = [];

		for (let category in categories) {
			printCategories.push(categories[category]);
		}

		printCategories.sort(
			(a, b) => a.props.itemIds.length - b.props.itemIds.length
		);

		return <RatingItems>{printCategories}</RatingItems>;
	}

	setItemValues(inputItems) {
		this.props.onSelect(this.props.label, inputItems);
	}

	setItemValue = (itemId, value) => {
		let items = this.items;
		items[itemId] = value;
		this.setItemValues(items);
	};

	setItemsByAverage = (average, itemIds) => {
		let allItems = this.items;
		let items = {};
		let total = 0;

		for (let itemId in allItems) {
			itemId = parseInt(itemId, 10);
			if (itemIds.indexOf(itemId) === -1) {
				continue;
			}

			items[itemId] = parseFloat(allItems[itemId]);
			total += items[itemId];
		}

		let oldAvg = total / itemIds.length;
		let change = oldAvg === 0 ? 1 : average / oldAvg;
		let pot = 0;
		let unfullfilledIds = [];

		for (let n in items) {
			if (total === 0) {
				items[n] = average;
			} else if (average == 10 || (items[n] == 10 && change > 1)) {
				items[n] = parseFloat(10);
			} else {
				items[n] = Math.ceil(items[n] * change * 10) / 10;
			}

			// All values above 10 go into a common pot to be divided amongst fields that hasn't hit max.
			if (items[n] >= 10) {
				pot += parseFloat(items[n] - 10);
				items[n] = parseFloat(10);
			} else {
				unfullfilledIds.push(n);
			}

			items[n] = parseFloat(parseFloat(items[n]).toFixed(1));
		}

		let fillRemaining = (ids, pot) => {
			let newPot = 0;
			let newIds = [];

			ids.forEach((id) => {
				items[id] =
					Math.ceil(
						(parseFloat(items[id]) + parseFloat(pot / ids.length)) * 10
					) / 10;

				if (items[id] >= 10) {
					items[id] = parseFloat(10);
					newPot += parseFloat(items[id] - 10);
				} else {
					newIds.push(id);
				}

				items[id] = parseFloat(parseFloat(items[id]).toFixed(1));
			});

			if (newPot > 0 && newIds.length > 0) {
				fillRemaining(newIds, newPot);
			}
		};

		fillRemaining(unfullfilledIds, pot);

		for (let n in items) {
			allItems[n] = items[n];
		}

		this.setItemValues(allItems);
	};

	render() {
		return this.ratingItems;
	}
}
