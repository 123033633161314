import { ReactNode } from 'react';

export const symbol_health_and_beauty: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M681-80q-12 0-21-9t-9-21q0-12 9-21t21-9h145v-104H681q-12 0-21-9t-9-21q0-12 9-21t21-9h145v-104H681q-12 0-21-9t-9-21q0-12 9-21t21-9h145v-104H681q-12 0-21-9t-9-21q0-12 9-21t21-9h145v-104H681q-12 0-21-9t-9-21q0-12 9-21t21-9h166q31 0 52 21t21 52v570q0 31-21 52t-52 21H681Zm-440.805-60H376l125-345-147-86h-92l-147 86 125.195 345ZM308-356ZM183-120 57-465q-8-20-.66-40.348Q63.683-525.696 83-537l163-96v-216q0-12.75 8.625-21.375T276-879h63.505q13.495 0 21.995 8.625Q370-861.75 370-849v216l163 96q19.318 11.304 26.659 31.652Q567-485 559-465L433-120q-7 18-21.993 29Q396.015-80 377-80H239q-19.015 0-34.007-11Q190-102 183-120Z"
		/>
	</svg>
);
