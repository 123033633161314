import { List, Map } from 'immutable';

import VideoPlaylist from 'pkg/models/video_playlist';
import VideoPlaylistSequence from 'pkg/models/video_playlist_sequence';
import Account from 'pkg/models/account';
import VideoSequence from 'pkg/models/video_sequence';

import { localeIncludes } from 'pkg/strings';
import { RootState } from 'pkg/reducers';

import {
	FilterHighlights,
	FilterPrivate,
} from 'components/video-analytics/FilterState';

export const findAll = (state: RootState): Map<number, VideoPlaylist> =>
	state.videoPlaylists
		.get('entities')
		.map((playlist: VideoPlaylist) =>
			playlist.set(
				'account',
				state.accounts.getIn(['entities', playlist.accountId]) as Account
			)
		)
		.sortBy((playlist: VideoPlaylist) => -playlist.createdAt);

export const find = (state: RootState, playlistId: number): VideoPlaylist =>
	findAll(state).get(playlistId, new VideoPlaylist({}));

export const findAllSequences = (
	state: RootState,
	playlistId: number
): List<VideoPlaylistSequence> =>
	state.videoPlaylistSequences
		.get('entities')
		.filter(
			(item: VideoPlaylistSequence) => item.videoPlaylistId === playlistId
		)
		.sortBy((item: VideoPlaylistSequence) => item.order)
		.map((item: VideoPlaylistSequence) => {
			const sequence = state.videoSequences.getIn([
				'entities',
				item.videoSequenceId,
			]) as VideoSequence;

			const video = state.videos.getIn([
				'entities',
				sequence.videoId.toString(),
			]);

			item = item.set('video', video);
			item = item.set('videoSequence', sequence);

			return item;
		})
		.toList();

interface SequenceFilters {
	highlights?: FilterHighlights;
	private?: FilterPrivate;
	authorAccountIds?: number[];
	taggedUserIds?: number[];
	search?: string;
}

export const findAllFilteredSequences = (
	state: RootState,
	playlistId: number,
	filters: SequenceFilters
): List<VideoPlaylistSequence> => {
	let sequences = findAllSequences(state, playlistId);

	// Hide highlights
	if (sequences.size > 0 && filters.highlights === FilterHighlights.Hide) {
		sequences = sequences.filter(
			(sequence) => !sequence.videoSequence.reviewed
		);
	}

	// Hide non-highlights
	if (sequences.size > 0 && filters.highlights === FilterHighlights.Only) {
		sequences = sequences.filter((sequence) => sequence.videoSequence.reviewed);
	}

	// Hide private
	if (sequences.size > 0 && filters.private === FilterPrivate.Hide) {
		sequences = sequences.filter((sequence) => !sequence.videoSequence.private);
	}

	// Hide public
	if (sequences.size > 0 && filters.private === FilterPrivate.Only) {
		sequences = sequences.filter((sequence) => sequence.videoSequence.private);
	}

	// Filter sequence authors
	if (sequences.size > 0 && filters.authorAccountIds.length > 0) {
		sequences = sequences.filter((sequence) =>
			filters.authorAccountIds.includes(sequence.videoSequence.accountId)
		);
	}

	// Filter by search
	if (sequences.size > 0 && filters.search.length > 0) {
		sequences = sequences.filter((sequence) =>
			localeIncludes(
				[sequence.videoSequence.title, sequence.video.title].join(' '),
				filters.search
			)
		);
	}

	return sequences.sortBy((vps: VideoPlaylistSequence) => vps.order);
};
