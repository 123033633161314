import { t } from '@transifex/native';
import { useSelector } from 'react-redux';
import { List, Set, Map } from 'immutable';
import { useEffect } from 'react';

import Membership from 'pkg/models/membership';
import Group from 'pkg/models/group';
import Account from 'pkg/models/account';
import User from 'pkg/models/user';
import VideoSequence from 'pkg/models/video_sequence';
import Video from 'pkg/models/video';

import * as actions from 'pkg/actions';
import * as selectors from 'pkg/selectors';
import { RootState } from 'pkg/reducers';
import { useCurrentRoute } from 'pkg/router/hooks';
import { useCurrentGroup, useCurrentMembership } from 'pkg/identity';
import * as models from 'pkg/api/models';

import { CueType, useCueState } from 'components/video-analytics/CueState';
import { getCurrentFilterState } from 'components/video-analytics/FilterState';

/**
 * Finds a membership
 * @param groupId
 * @param userId
 */
export function useMemberships(
	groupId: number,
	userId: number
): Map<string, Membership> {
	return useSelector((state: RootState) =>
		selectors.groups
			.getMemberships(state)
			.filter((m: Membership) => m.userId === userId && m.groupId === groupId)
	);
}

/**
 * Finds a membership
 * @param groupId
 * @param userId
 */
export function useMembership(groupId: number, userId: number): Membership {
	return useSelector((state: RootState) =>
		selectors.groups
			.getMemberships(state)
			.find((m: Membership) => m.userId === userId && m.groupId === groupId)
	);
}

/**
 * Finds a user
 * @param userId
 */
export function useUser(userId: number): User {
	return useSelector<RootState, User>((state: RootState) =>
		selectors.users.find(state, userId)
	);
}

/**
 * Finds a group
 * @param groupId
 */
export function useGroup(groupId: number): Group {
	return useSelector<RootState, Group>((state: RootState) =>
		selectors.groups.find(state, groupId)
	);
}

/**
 * @param userId ID for the user to check against
 */
export function useCheckRelationToUser(userId: number): any {
	const activeMembership = useCurrentMembership();

	const isSelf = activeMembership?.userId === userId;
	const isParentToUser = activeMembership?.targetUserId === userId;
	const isSelfOrParentToUser = isSelf || isParentToUser;
	const isSelfOrParentToUserOrAdmin =
		isSelfOrParentToUser || models.membership.isAdminOrStaff(activeMembership);

	return {
		isSelf,
		isParentToUser,
		isSelfOrParentToUser,
		isSelfOrParentToUserOrAdmin,
	};
}

/**
 * Finds the current video based on route video ID
 * @returns Video
 */
export function useCurrentVideo(): Video {
	const { videoId } = useCurrentRoute();

	return useSelector<RootState, Video>((state: RootState) =>
		selectors.videos.find(state, videoId)
	);
}

/**
 * Returns all "visible" clips
 * @returns List<VideoSequence>
 */
export function useCurrentFilteredClips(): List<VideoSequence> {
	const { videoId } = useCurrentRoute();
	const { cuePoints } = useCueState();
	const filters = getCurrentFilterState();

	const activeCueIds: number[] = cuePoints.map(
		(cue: CueType) => cue.cueId as number
	);

	return useSelector((state: RootState) =>
		selectors.videoSequences.findAllFilteredSequences(state, videoId, filters)
	)
		.filter((sequence: VideoSequence) => activeCueIds.includes(sequence.id))
		.sortBy((sequence: VideoSequence) => sequence.getStartsAt());
}

interface GroupBy {
	[label: string]: number[];
}

interface GroupedUserSelector {
	users?: Set<User>;
	accounts?: Set<Account>;
	groupBy: GroupBy;
}

/**
 * Finds all taggable users within a group and groups them by role
 * @returns
 */
export function useGroupedTaggableUsers(): GroupedUserSelector {
	const video = useCurrentVideo();
	const membership = useCurrentMembership();

	const groupId = video.groupId || membership.groupId;

	useEffect(() => {
		if (video.groupId && video.groupId !== membership.groupId) {
			actions.groups.fetchSquad(video.groupId);
		}
	}, [video, membership]);

	const memberships = useSelector<RootState, Set<Membership>>(
		(state: RootState) =>
			selectors.groups.getMembershipsByGroupId(state, { groupId })
	)
		.toList()
		.sort((a: Membership, b: Membership) =>
			a.user.fullName.localeCompare(b.user.fullName)
		)
		.filter(
			(membership: Membership) => membership.valid() && !membership.isParent()
		);

	const users = memberships
		.map((membership: Membership) => membership.user)
		.toSet();

	const groupBy: GroupBy = {};

	const players = memberships
		.filter((m: Membership) => m.isPlayer())
		.sortBy((m: Membership) => m.user.firstName)
		.map((m: Membership) => m.userId)
		.filter((n) => n)
		.toArray();

	if (players.length > 0) {
		groupBy[t(`Players`)] = players;
	}

	const coaches = memberships
		.filter((m: Membership) => m.isAdminOrStaff())
		.sortBy((m: Membership) => m.user.firstName)
		.map((m: Membership) => m.userId)
		.filter((n) => n)
		.toArray();

	if (coaches.length > 0) {
		groupBy[t(`Coaches`)] = coaches;
	}

	const other = memberships
		.filter((m: Membership) => !m.isAdminOrStaff() && !m.isPlayer())
		.sortBy((m: Membership) => m.user.firstName)
		.map((m: Membership) => m.userId)
		.filter((n) => n)
		.toArray();

	if (other.length > 0) {
		groupBy[t(`Other`)] = other;
	}

	return { users, groupBy };
}

/**
 * Finds all the tagged users in a video and groups them by group role
 * @param videoId
 * @param clipId
 * @returns
 */
export function useGroupedTaggedUsers(
	videoId: number,
	clipId?: number
): GroupedUserSelector {
	const users = useSelector<RootState, Set<User>>((state: RootState) =>
		selectors.videoSequences.findAllTaggedUsers(state, videoId)
	);

	const group = useCurrentGroup();

	const memberships = useSelector<RootState, Set<Membership>>(
		selectors.groups.getMembershipsForGroup(group.id)
	)
		.toList()
		.filter((membership: Membership) => users.includes(membership.user))
		.sort((a: Membership, b: Membership) =>
			a.user.fullName.localeCompare(b.user.fullName)
		);

	const groupBy: GroupBy = {};

	const players = memberships
		.filter((m: Membership) => m.isPlayer())
		.sortBy((m: Membership) => m.user.firstName)
		.map((m: Membership) => m.userId)
		.filter((n) => n)
		.toArray();

	if (players.length > 0) {
		groupBy[t(`Players`)] = players;
	}

	const coaches = memberships
		.filter((m: Membership) => m.isAdmin())
		.sortBy((m: Membership) => m.user.firstName)
		.map((m: Membership) => m.userId)
		.filter((n) => n)
		.toArray();

	if (coaches.length > 0) {
		groupBy[t(`Coaches`)] = coaches;
	}

	const other = memberships
		.filter((m: Membership) => !m.isAdmin() && !m.isPlayer())
		.sortBy((m: Membership) => m.user.firstName)
		.map((m: Membership) => m.userId)
		.filter((n) => n)
		.toArray();

	if (other.length > 0) {
		groupBy[t(`Other`)] = other;
	}

	const userIdsNotInGroup = useSelector<RootState, number[]>(
		(state: RootState) => {
			if (clipId) {
				return selectors.videoSequenceUsers.findAllSequenceUsersOutsideOfActiveGroup(
					state,
					clipId,
					group.id
				);
			}

			return [];
		}
	);

	if (userIdsNotInGroup.length > 0) {
		groupBy[t(`Not in current group`)] = userIdsNotInGroup;
	}

	return { users, groupBy };
}

/**
 * Finds all clip authors of a video
 * @param videoId
 * @returns
 */
export function useGroupedClipAuthorAccounts(
	videoId: number
): GroupedUserSelector {
	const accounts = useSelector<RootState, Set<Account>>((state: RootState) =>
		selectors.videoSequences.findAllAuthors(state, videoId)
	);
	const group = useCurrentGroup();

	const memberships = useSelector<RootState, Set<Membership>>(
		selectors.groups.getMembershipsForGroup(group.id)
	)
		.toList()
		.sort((a: Membership, b: Membership) =>
			a.user.fullName.localeCompare(b.user.fullName)
		);

	const groupBy: GroupBy = {};

	const players = memberships
		.filter((m: Membership) => m.isPlayer())
		.sortBy((m: Membership) => m.user.firstName)
		.map(
			(m: Membership) =>
				accounts.find((account: Account) => account.users.includes(m.userId))
					?.id
		)
		.filter((n) => n)
		.toArray();

	if (players.length > 0) {
		groupBy[t(`Players`)] = players;
	}

	const coaches = memberships
		.filter((m: Membership) => m.isAdmin())
		.sortBy((m: Membership) => m.user.firstName)
		.map(
			(m: Membership) =>
				accounts.find((account: Account) => account.users.includes(m.userId))
					?.id
		)
		.filter((n) => n)
		.toArray();

	if (coaches.length > 0) {
		groupBy[t(`Coaches`)] = coaches;
	}

	return { accounts, groupBy };
}
