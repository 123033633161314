import { ReactNode } from 'react';

import { useCurrentOrganization } from 'pkg/identity';

import Badge from 'components/Badge';

import { LayoutPortalLocation } from 'components/layout/portal';
import * as SmallLayout from 'components/navigation/header/small_screen/Styles';
import { SubNavItemProps } from 'components/layout/page-templates';
import Back from 'components/navigation/Back';

import * as css from './styles.css';

interface BrandedHeaderLayoutProps {
	children: ReactNode | ReactNode[];
	pageSubNavItems: SubNavItemProps[];
}

export default function BrandedHeaderLayout({
	children,
	pageSubNavItems = [],
}: BrandedHeaderLayoutProps): JSX.Element {
	const org = useCurrentOrganization();

	return (
		<div className={css.brandedWrapper}>
			<div className={css.brandedHeader}>
				<div className={css.brandedHeaderBack}>
					<Back />
				</div>
				<Badge badgeUrl={org.profileImageUrl} className={css.brandedBadge} />
				<LayoutPortalLocation portalId="user-profile-header" />
				<SmallLayout.SubNav>
					{pageSubNavItems.map((item, n) => (
						<SmallLayout.SubNavItem key={n} {...item}>
							{item.title}
						</SmallLayout.SubNavItem>
					))}
				</SmallLayout.SubNav>
			</div>
			<div className={css.brandedInner}>
				<div className={css.brandedInnerWrapper}>{children}</div>
			</div>
		</div>
	);
}
