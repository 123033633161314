import { ReactNode } from 'react';

export const symbol_photo_camera_back: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-120q-24 0-42-18t-18-42v-513q0-23 18-41.5t42-18.5h147l55-66q8-11 20-16t26-5h184q14 0 26 5t20 16l55 66h147q23 0 41.5 18.5T880-693v513q0 24-18.5 42T820-120H140Zm0-60h680v-513H645l-73-87H388l-73 87H140v513Zm340-257ZM283-260h400q9 0 13.5-8t-1.5-16L583-431q-5-6-12-6t-12 6L445-280l-80-99q-5-6-12-6t-12 6l-70 95q-5 8-.75 16t12.75 8Z"
		/>
	</svg>
);
