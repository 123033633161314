import * as styles from 'pkg/config/styles';

import * as Input from 'components/form/inputs';
import Row from 'components/layout/row';

import * as Table from 'design/table';

const SearchRow = ({
	group,
	disabledGroups,
	isSelected,
	select,
	singleSelect,
}) => {
	const handleSelect = (e) => {
		e.stopPropagation();
		e.preventDefault();

		if (disabledGroups.includes(group.id)) {
			return;
		}

		select({ id: group.id });
	};

	const userAlreadyInGroup = disabledGroups.includes(group.id);

	const input = singleSelect ? (
		<Input.Control type="radio" checked={isSelected} />
	) : (
		<Input.Control
			type="checkbox"
			selected={isSelected || userAlreadyInGroup}
			disabled={userAlreadyInGroup}
			id={`select-${group.name}`}
			standalone
		/>
	);

	return (
		<Table.Row>
			<Table.Cell>
				<Row spacing={styles.spacing._2} autoColumns="auto" align="center">
					<div onClick={handleSelect}>{input}</div>
					<span>{group.name}</span>
				</Row>
			</Table.Cell>
		</Table.Row>
	);
};

export default SearchRow;
