import { ReactNode } from 'react';

export const symbol_exposure: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-60h600v-600L180-180Zm414-136h-63q-10.833 0-17.917-7.116-7.083-7.117-7.083-18Q506-352 513.083-359q7.084-7 17.917-7h63v-63q0-10.833 7.116-17.917 7.117-7.083 18-7.083Q630-454 637-446.917q7 7.084 7 17.917v63h63q10.833 0 17.917 7.116 7.083 7.117 7.083 18Q732-330 724.917-323q-7.084 7-17.917 7h-63v63q0 10.833-7.116 17.917-7.117 7.083-18 7.083Q608-228 601-235.083q-7-7.084-7-17.917v-63ZM405-638q10.833 0 17.917-7.116 7.083-7.117 7.083-18Q430-674 422.917-681q-7.084-7-17.917-7H253q-10.833 0-17.917 7.116-7.083 7.117-7.083 18Q228-652 235.083-645q7.084 7 17.917 7h152Z"
		/>
	</svg>
);
