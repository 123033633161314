import { ReactNode } from 'react';

export const symbol_view_column: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M120-260v-440q0-24.75 17.625-42.375T180-760h600q24.75 0 42.375 17.625T840-700v440q0 24.75-17.625 42.375T780-200H180q-24.75 0-42.375-17.625T120-260Zm60 0h160v-440H180v440Zm220 0h160v-440H400v440Zm220 0h160v-440H620v440Z"
		/>
	</svg>
);
