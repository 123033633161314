import { ReactNode } from 'react';

export const symbol_local_convenience_store: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M840-518.516V-180q0 24-18 42t-42 18H179q-24 0-42-18t-18-42v-339q-28-24-37-59t2-70l43-135q8-27 27.897-42T201-840h553q28.209 0 49.104 15.5Q824-809 832-783l44 135q11 35 1.5 70T840-518.516ZM570-550q29 0 49-19t16-46l-25-165H510v165q0 26.25 17 45.625T570-550Zm-187 0q28 0 47.5-19t19.5-46v-165H350l-25 165q-4 26 14 45.5t44 19.5Zm-182 0q23.6 0 41.3-16.5Q260-583 263-607l26-173H189l-46 146q-10 31 8 57.5t50 26.5Zm557 0q32 0 50.5-26t8.5-58l-46-146H671l26 173q3 24 20.4 40.5T758-550ZM179-180h601v-311q1 1-6.5 1H758q-25 0-47.5-10.5T666-533q-16 20-40 31.5T573.057-490Q543-490 521.5-498.5 500-507 480-527q-15 18-38 27.5t-52 9.5q-31 0-55-11t-41-32q-24 21-47 32t-46 11h-13.5q-6.5 0-8.5-1v311Zm601 0H179h601Zm-443-55h80q8 0 13.5-5.7T436-254q0-7.6-5.7-13.3-5.7-5.7-13.3-5.7h-61v-46h60.882q8.118 0 13.618-5.7T436-338v-80q0-8-5.7-13.5T417-437h-80q-8 0-13.5 5.7T318-418q0 7.6 5.7 13.3 5.7 5.7 13.3 5.7h61v42h-60.882q-8.118 0-13.618 5.7T318-338v83.636q0 8.364 5.7 13.864T337-235Zm267-84v65q0 7.6 5.7 13.3 5.7 5.7 13.3 5.7 7.6 0 13.3-5.7 5.7-5.7 5.7-13.3v-163.81q0-8.19-5.7-13.69T623-437q-7.6 0-13.3 5.7-5.7 5.7-5.7 13.3v61h-42v-60.882q0-8.118-5.7-13.618T543-437q-7.6 0-13.3 5.7-5.7 5.7-5.7 13.3v80q0 8 5.7 13.5T543-319h61Z"
		/>
	</svg>
);
