import { ReactNode } from 'react';

export const symbol_fastfood: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M575-364q-23-52-90.5-82.5T344-477q-73 0-140.5 30.5T112-364h463ZM344.5-537q49.5 0 97.5 11t88.5 35q40.5 24 69.5 61.5t40 89.5q3 14-5.93 25T611-304H77q-14.14 0-23.07-11T48-340q11-52 40-89.5t69.5-61.5q40.5-24 89-35t98-11ZM70-176q-12.75 0-21.375-8.675Q40-193.351 40-206.175 40-219 48.625-227.5T70-236h548q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T618-176H70ZM785-40h-47q-12.75 0-21.375-8.675Q708-57.351 708-70.175 708-83 716.625-91.5T738-100h56l57-580H481q-13 0-21-8.675-8-8.676-8-21.5 0-12.825 8.625-21.325T482-740h169v-150q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T711-890v150h176q13.5 0 22.75 9.75T917-707l-64 607q-3.091 25.862-22.667 42.931Q810.758-40 785-40Zm-77-60h86-86ZM70-40q-12.75 0-21.375-8.675Q40-57.351 40-70.175 40-83 48.625-91.5T70-100h548q12.75 0 21.375 8.675Q648-82.649 648-69.825 648-57 639.375-48.5T618-40H70Zm274-324Z"
		/>
	</svg>
);
