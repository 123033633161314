import { ReactNode } from 'react';

export const symbol_tibia_alt: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M365-391q-22-5-51-12.5T252-425q-27-11-54.5-25.5T142-484q-11-8-13-21t6-24q8-11 20.5-12.5T179-535q21 16 45 30t49 24.5q25 10.5 50.5 18T374-450q24 5 38.5 24t11.5 43q-4 32-5 66.5t2 69q3 34.5 10 69.5t17 68q4 11-4 19t-19 10q-11 2-23-4t-17-25q-12-43-18-84t-7-77.5q-1-36.5.5-67T365-391h-.5.5Zm357-243q18 29 28 62t10 69v393q0 13-8.5 21.5T730-80q-13 0-21.5-8.5T700-110v-393q0-54-28.5-98T595-668L357-773q-14-6-17.5-20.5T345-820q7-9 17-12t21 2l237 108q32 14 58 36.5t44 51.5ZM545-201q8 0 14.5-4t10.5-10q5 6 11 10t14 4q13 0 21.5-9t8.5-21q0-13-8.5-21.5T595-261h-5v-199h5q13 0 21.5-9t8.5-21q0-13-8.5-21.5T595-520q-8 0-14 3.5t-11 9.5q-4-6-10.5-9.5T545-520q-12 0-21 8.5t-9 21.5q0 12 9 21t21 9h5v199h-5q-12 0-21 8.5t-9 21.5q0 12 9 21t21 9ZM458-80q-14-36-23-74.5T422-232q-4-39-3.5-77t5.5-74q3-24-11.5-43T374-450q-29-5-58.5-14.5t-58-22.5Q229-500 203-517t-49-38q23 21 49 38t54.5 30q28.5 13 58 22.5T374-450q24 5 38.5 24t11.5 43q-5 36-5.5 74t3.5 77q4 39 13 77.5T458-80Z"
		/>
	</svg>
);
