import { ReactNode } from 'react';

export const symbol_south: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-92q-6 0-11-2t-10-7L221-339q-9-9-9-21t9-21q9-9 21-9t21 9l187 187v-656q0-13 8.5-21.5T480-880q13 0 21.5 8.5T510-850v656l187-187q9-9 21-9t21 9q9 9 9 21t-9 21L501-101q-5 5-10 7t-11 2Z"
		/>
	</svg>
);
