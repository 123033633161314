import React from 'react';
import { t } from '@transifex/native';

import {
	TableProps,
	User,
} from 'containers/group/statistics/training-dashboard';

import * as Card from 'components/Card';
import AssetImage from 'components/AssetImage';

import * as Table from 'design/table';

import * as css from './styles.css';

const IndividualTable = ({
	data,
	exerciseTagData,
}: {
	data: User[];
	exerciseTagData: TableProps[];
}) => {
	const tagMap: {
		[key: number]: {
			dataKey: number;
			name: string;
			color?: string;
		};
	} = {};

	const teamTagMap: {
		[key: number]: {
			dataKey: number;
			name: string;
			color?: string;
		};
	} = {};

	const teamData: { [key: string]: any }[] = [
		{
			first: 'Team',
			last: 'Data',
			id: 0,
		},
	];

	exerciseTagData.forEach((t) => {
		teamData[0][`tag_${t.id}`] = t;
	});

	exerciseTagData.forEach((value) => {
		teamTagMap[value.id] = {
			dataKey: value.id,
			name: value.name,
		};
	});

	data.forEach((value) => {
		const tags = Object.values<TableProps>(value.tags);
		tags.forEach((tag) => {
			tagMap[tag.id] = {
				dataKey: tag.id,
				name: tag.name,
			};
		});
	});

	const teamTagData = Object.values(teamTagMap).map((t) => ({
		...t,
	}));
	const tagColumns = [
		{ content: t('Name') },
		...Object.values(teamTagData).map((tag) => ({
			content: tag.name,
		})),
	];

	const emptyState = {
		title: t('No exercise data found!'),
		image: <AssetImage src="img/missing-entities/match-statistics.svg" />,
	};

	return (
		<Card.Base>
			<Table.Table stickyHeader columns={tagColumns} emptyState={emptyState}>
				<Table.Row className={css.stickyRow}>
					<Table.Cell>{t('Team Data')}</Table.Cell>
					{exerciseTagData.map((teamTag) => (
						<Table.Cell key={teamTag.id}>{teamTag.minutes}</Table.Cell>
					))}
				</Table.Row>
				{data.map((user) => (
					<Table.Row key={user.id}>
						<Table.LinkCell>
							{user.firstName} {user.lastName}
						</Table.LinkCell>
						{Object.values(teamTagData).map((tagColumn) => {
							return (
								<Table.Cell key={tagColumn.name}>
									{user.tags[tagColumn.dataKey]?.minutes || 0}
								</Table.Cell>
							);
						})}
					</Table.Row>
				))}
			</Table.Table>
		</Card.Base>
	);
};

export default IndividualTable;
