import { ReactNode } from 'react';

export const symbol_score: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm340-156 260-260v-244H180v510l152-152q9-9 21-9t21 9l146 146Zm-9.116-210Q500-486 493-493.083q-7-7.084-7-17.917v-190q0-10.833 7.116-17.917 7.117-7.083 18-7.083Q522-726 529-718.917q7 7.084 7 17.917v190q0 10.833-7.116 17.917-7.117 7.083-18 7.083ZM596-613l38 90q5 14-2.758 25.5Q623.485-486 607-486q-7.737 0-14.368-4Q586-494 583-502l-47-104 47-105q3-7 9.632-11 6.631-4 14.368-4 15 0 22.5 12t1.5 26l-35 75Zm-273 77h78q10.833 0 17.917 7.116 7.083 7.117 7.083 18Q426-500 418.917-493q-7.084 7-17.917 7H298q-10.833 0-17.917-7.083Q273-500.167 273-511v-99q0-10.833 7.083-17.917Q287.167-635 298-635h78v-41h-78q-10.833 0-17.917-7.116-7.083-7.117-7.083-18Q273-712 280.083-719q7.084-7 17.917-7h103q10.833 0 17.917 7.083Q426-711.833 426-701v91q0 10.833-7.083 17.917Q411.833-585 401-585h-78v49Zm30 178L175-180h605v-271L541-212q-9 9-21 9t-21-9L353-358ZM180-180v-600 600Z"
		/>
	</svg>
);
