import { t } from '@transifex/native';
import { useState } from 'react';

import * as models from 'pkg/api/models';

import StepModal, { Step } from 'components/step-modal';

import InviteLinkGeneratedStep from 'components/account/invite-parent-modal/InviteLinkGeneratedStep';
import EmailInviteSentStep from 'components/account/invite-parent-modal/EmailInviteSentStep';
import EmailInviteStep from 'components/account/invite-parent-modal/EmailInviteStep';

interface InviteParentModalProps {
	targetAccounts?: models.account.Account[];
	onClose: () => void;
}

export default function InviteParentModal({
	targetAccounts,
	onClose,
}: InviteParentModalProps): JSX.Element {
	const [email, setEmail] = useState('');
	const [inviteKey, setInviteKey] = useState('');

	const handleClose = async () => onClose();

	const multipleChildren = targetAccounts.length > 1;

	return (
		<StepModal onClose={onClose}>
			<Step title={t('Connect parent')} hideNext hidePrev>
				<EmailInviteStep
					targetAccounts={targetAccounts}
					email={email}
					setEmail={setEmail}
					setInviteKey={setInviteKey}
					multipleChildren={multipleChildren}
				/>
			</Step>

			<Step
				title={t('Connect parent')}
				slug="invite-link-generated"
				hidePrev
				nextLabel={t('Done')}
				onNext={handleClose}>
				<InviteLinkGeneratedStep
					inviteKey={inviteKey}
					multipleChildren={multipleChildren}
				/>
			</Step>

			<Step
				title={t('Connect parent')}
				hidePrev
				slug="email-invite-sent"
				nextLabel={t('Done')}
				onNext={handleClose}>
				<EmailInviteSentStep email={email} />
			</Step>
		</StepModal>
	);
}
