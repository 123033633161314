import { ReactNode } from 'react';

export const symbol_overview: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M753-237v-92q0-8-6-14t-14-6q-8 0-14 6t-6 14v96q0 5.818 2 10.909Q717-217 722-212l63.731 63.731Q792-142 800-142t14-6q6-6 6-14t-6-14l-61-61ZM180-120q-24.75 0-42.375-17.625T120-180v-600q0-24.75 17.625-42.375T180-840h600q24.75 0 42.375 17.625T840-780v275q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T780-505v-275H180v600h277q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T457-120H180Zm0-107v47-600 308-4 249Zm100-82.825q0 12.825 8.625 21.325T310-280h151q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T461-340H310q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5Zm0-170q0 12.825 8.625 21.325T310-450h320q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T630-510H310q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5Zm0-170q0 12.825 8.625 21.325T310-620h340q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T650-680H310q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5ZM732.5-41Q655-41 600-96.5T545-228q0-78.435 54.99-133.717Q654.98-417 733-417q77 0 132.5 55.283Q921-306.435 921-228q0 76-55.5 131.5T732.5-41Z"
		/>
	</svg>
);
