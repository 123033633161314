import { t } from '@transifex/native';

import * as palette from 'pkg/config/palette';

import rgba from 'pkg/rgba';
import * as models from 'pkg/api/models';
import { cssClasses } from 'pkg/css/utils';
import { MatchEventType } from 'pkg/api/models/match_event';

import Icon, { IconName } from 'components/icon';

import * as css from './styles.css';

const prefixableTypes = [
	MatchEventType.MatchEventTypeSubstitutionIn,
	MatchEventType.MatchEventTypeSubstitutionOut,
	MatchEventType.MatchEventTypeAssist,
];

const prefixColorMap: { [key in MatchEventType]?: string } = {
	substitution_in: palette.green[500],
	substitution_out: palette.red[400],
};

const iconMap: { [key: string]: { name: IconName; fill?: string } } = {
	goal: { name: 'football', fill: palette.green[500] },
	opponent_goal: { name: 'football', fill: palette.red[400] },
	card_red: { name: 'card', fill: palette.red[400] },
	card_yellow: { name: 'card', fill: palette.yellow[600] },
	substitution_in: { name: 'compare' },
	substitution_out: { name: 'compare' },
	position_swap: { name: 'sync' },
};

interface MatchEventProps {
	matchEvent: models.matchEvent.MatchEvent;
	subsequentEvent: models.matchEvent.MatchEvent;
}

export default function MatchEvent({
	matchEvent,
	subsequentEvent,
}: MatchEventProps) {
	const isSubstitutionEvent =
		matchEvent.type === MatchEventType.MatchEventTypeSubstitutionIn ||
		matchEvent.type === MatchEventType.MatchEventTypeSubstitutionOut;

	const icon = () => {
		let type = matchEvent.type as string;

		if (type === MatchEventType.MatchEventTypeGoal && matchEvent.isOpponent) {
			type = MatchEventType.MatchEventTypeOpponentGoal;
		}

		const name = iconMap[type]?.name || 'stopwatch';
		const fill = iconMap[type]?.fill || rgba(palette.black, 0.7);

		return (
			<div className={css.typeIcon}>
				<Icon name={name} fill={fill} />
			</div>
		);
	};

	const name = () => {
		let type = matchEvent.type;

		if (type === 'goal' && matchEvent.isOpponent) {
			type = MatchEventType.MatchEventTypeOpponentGoal;
		}

		return (
			<div className={css.typeName}>
				{models.matchEvent.getEventTypeTranslation(type)}
			</div>
		);
	};

	const userPrefix = (type: MatchEventType) => {
		if (!prefixableTypes.includes(type)) return null;

		let prefix = '';

		switch (type) {
			case MatchEventType.MatchEventTypeSubstitutionIn:
				prefix = t('In');
				break;
			case MatchEventType.MatchEventTypeSubstitutionOut:
				prefix = t('Out');
				break;
			case MatchEventType.MatchEventTypeAssist:
				prefix = t('Assist');
				break;
		}

		return (
			<span className={css.prefix} style={{ color: prefixColorMap[type] }}>
				{prefix}:
			</span>
		);
	};

	const associatedUser = () => {
		if (!subsequentEvent) return null;

		const { user, type } = this.props.subsequentEvent;

		if (!user) return null;

		return (
			<div className={css.associatedUser}>
				{userPrefix(type)}
				{user.firstName[0]}. {user.lastName}
			</div>
		);
	};

	const user = () => {
		const { user, type, isOpponent } = matchEvent;

		if (isOpponent) return <div className={css.meta}>{t('Opponent')}</div>;

		if (!user) return null;

		const userName = `${user.firstName.slice(0, 1)}. ${user.lastName}`;

		const eventUser = (
			<div
				className={cssClasses(
					css.user,
					!prefixableTypes.includes(type) ? css.black : ''
				)}>
				{userPrefix(type)}
				{userName}
			</div>
		);

		if (type === 'assist') {
			return <div className={css.meta}>{userName}</div>;
		}

		return (
			<div className={css.meta}>
				{user ? eventUser : null}
				{associatedUser()}
			</div>
		);
	};

	return (
		<div
			className={cssClasses(
				css.wrapper,
				isSubstitutionEvent ? css.reverseOrder : ''
			)}>
			<div className={css.minute}>
				{matchEvent.minute}
				{matchEvent.extraMinute == 0 && `´`}
				{matchEvent.extraMinute > 0 && <sup>+{matchEvent.extraMinute}</sup>}
			</div>
			{icon()}
			{name()}
			{user()}
		</div>
	);
}
