import { ReactNode } from 'react';

export const symbol_elevator: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M280-400v140q0 12.75 8.625 21.375T310-230h60q12.75 0 21.375-8.625T400-260v-140h10q13 0 21.5-8.5T440-430v-80q0-33-23.5-56.5T360-590h-40q-33 0-56.5 23.5T240-510v80q0 13 8.5 21.5T270-400h10Zm60-224q23 0 39.5-16.5T396-680q0-23-16.5-39.5T340-736q-23 0-39.5 16.5T284-680q0 23 16.5 39.5T340-624Zm202 94h146q9.391 0 13.696-7.5Q706-545 701-553l-73-117q-4.5-7-12.75-7T602-670l-73 117q-5 8-.696 15.5Q532.609-530 542-530Zm86 240 73-117q5-8 .696-15.5Q697.391-430 688-430H542q-9.391 0-13.696 7.5Q524-415 529-407l73 117q4.5 7 12.75 7t13.25-7ZM180-120q-24 0-42-18t-18-42v-600q0-23 18-41.5t42-18.5h600q23 0 41.5 18.5T840-780v600q0 24-18.5 42T780-120H180Zm0-60h600v-600H180v600Zm0 0v-600 600Z"
		/>
	</svg>
);
