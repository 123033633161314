import { ReactNode } from 'react';

export const symbol_planner_review: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m211-304 107-512q2-11 11-18.5t20-7.5h14q11 0 20 7.5t11 18.5l130 604 81-357q2-11 11.5-18.5T637-595h16q11 0 20 7t12 18l65 266h101q12 0 20.5 8.5T880-275q0 12-8.5 21t-20.5 9H727q-11 0-19-6.5T698-268l-52-208-87 373q-2 11-10 17t-19 6h-14q-11 0-19-6.5T487-104L357-706l-92 437q-2 11-10 17.5t-19 6.5H109q-12 0-20.5-9T80-275q0-12 9-20.5t21-8.5h101Z"
		/>
	</svg>
);
