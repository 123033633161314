import { normalize } from 'normalizr';

import MatchEvent from 'pkg/models/match_event';

import { addMatchEvents } from 'pkg/actions/match_events_deprecated';
import { MatchEvents } from 'pkg/actions/action-types';
import { addUserEntities } from 'pkg/actions/users';
import {
	createMatchEvents,
	deleteMatchEvent,
} from 'pkg/actions/services/match_events.service';

export const removeStartingMatchUser = (matchEventId) => async (dispatch) => {
	const request = await deleteMatchEvent(matchEventId);

	if (request.ok) {
		await dispatch({
			type: MatchEvents.REMOVE_SINGLE_EVENT,
			matchEventId,
		});
	}
};

export const addStartingMatchUser =
	(matchId, user, positionId) => async (dispatch) => {
		const events = [
			{
				type: 'starting_user',
				userId: user.id,
				positionId,
			},
		];

		const response = await createMatchEvents(matchId, { events });

		if (response.ok) {
			// Will always return an array with one object
			const result = await response.json();

			// since we dont get user in payload
			const eventsWithUser = result.events.map((event) => ({
				...event,
				user,
			}));

			const data = normalize(eventsWithUser, [MatchEvent.normalizr()]);

			if (data.entities.users) {
				dispatch(addUserEntities(data));
			}

			if (data.entities.matchEvents) {
				dispatch(addMatchEvents(data));
			}
		}
	};
