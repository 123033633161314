import { t } from '@transifex/native';
import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';

import * as styles from 'pkg/config/styles';

import * as routes from 'pkg/router/routes';
import * as actions from 'pkg/actions';
import { replaceState } from 'pkg/router/state';
import { link, route } from 'pkg/router/utils';
import * as models from 'pkg/api/models';
import { useCurrentOrganization } from 'pkg/identity';

import Session from 'containers/training/session/View';

import { SmallScreen, LargeScreen } from 'components/MediaQuery';
import CardAnatomy, * as Card from 'components/Card';

import SessionModal from 'components/training/add-modal/session';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import TabContainer from 'components/tab-view/Container';

import Button from 'design/button';

const SessionsContainer = styled(TabContainer)`
	grid-area: sessions;
`;

export default function EventSessions({ event, refresh }) {
	const org = useCurrentOrganization();
	const [modalOpen, setModalOpen] = useState(false);
	const [saving, setSaving] = useState(false);

	const sessions = event?.sessions || [];

	const eventSessionPath = route('event.sessions', { eventId: event.id }, true);

	const showAddModal = () => {
		setModalOpen(true);
	};

	const hideAddModal = () => {
		setModalOpen(false);
	};

	useEffect(() => setSaving(saving), [saving]);

	const handleSessionSelect = async (sessionId) => {
		if (sessionId && !saving) {
			setSaving(true);
			const ok = await actions.events.connectEventSession(event.id, sessionId);

			setSaving(false);

			if (ok) {
				refresh();
				hideAddModal();
			}
		}
	};

	const handleRefresh = () => {
		refresh();
	};

	let canViewSession = models.canEdit(event);

	if (!canViewSession) {
		canViewSession = event.sessions.some((s) => s.playerVisible);
	}

	if (!canViewSession || event?.type !== 'practice') {
		replaceState(routes.Event.View(event.id, 'overview'));
	}

	const content = (
		<SessionsContainer
			guid={eventSessionPath}
			path={eventSessionPath}
			name={t(`Sessions`)}>
			{/* No actions should be dislayed if you're not able to edit the event */}
			{!sessions.length > 0 && models.canEdit(event) && (
				<Fragment>
					<CardAnatomy>
						<Card.EmptyStateBody>
							<LargeScreen>
								<Button
									icon="add"
									large
									secondary
									href={link(routes.TrainingSession.Create(org.id), {
										eventId: event.id,
									})}>
									{t(`Create new`)}
								</Button>
								<Button icon="tactic" large secondary onClick={showAddModal}>
									{t(`Add from library`)}
								</Button>
							</LargeScreen>
							<SmallScreen>
								<Button
									icon="add"
									large
									block
									secondary
									href={link(routes.TrainingSession.Create(org.id), {
										eventId: event.id,
									})}>
									{t(`Create new`)}
								</Button>
								<Button
									icon="tactic"
									block
									large
									secondary
									onClick={showAddModal}>
									{t(`Add from library`)}
								</Button>
							</SmallScreen>
						</Card.EmptyStateBody>
					</CardAnatomy>
					{modalOpen && (
						<SessionModal
							onClose={hideAddModal}
							onSelect={handleSessionSelect}
						/>
					)}
				</Fragment>
			)}

			{sessions.length > 0 &&
				sessions.map(({ id }) => (
					<Session
						key={id}
						sessionId={id}
						allowCollections={false}
						afterDelete={handleRefresh}
						ignoreRedirect
						inEventView
						hasEventEditRights={models.canEdit(event)}
					/>
				))}
		</SessionsContainer>
	);

	return (
		<React.Fragment>
			<LargeScreen>
				<LargeScreenContent.Inner maxWidth={styles.size.PageWidths.STANDARD}>
					{content}
				</LargeScreenContent.Inner>
			</LargeScreen>
			<SmallScreen>
				<LargeScreenContent.Inner>{content}</LargeScreenContent.Inner>
			</SmallScreen>
		</React.Fragment>
	);
}
