import * as styles from 'pkg/config/styles';

import Icon, { IconName } from 'components/icon';

import * as css from './styles.css';

interface FullPageMessageProps {
	icon?: IconName;
	iconColor?: keyof typeof styles.palette.Colors;
	title: string;
	message?: string;
}

export default function FullPageMessage({
	icon = 'check-circle',
	iconColor = 'green',
	title,
	message = null,
}: FullPageMessageProps): JSX.Element {
	return (
		<div className={css.wrapper}>
			<Icon name={icon} fill={styles.palette[iconColor][400]} />
			<span>{title}</span>
			{message && <p>{message}</p>}
		</div>
	);
}
