import { useT } from '@transifex/react';
import { ChangeEvent, Fragment, useCallback } from 'react';

import spacing from 'pkg/config/spacing';

import {
	RSVPInterval,
	rsvpIntervalMappedToGranularity,
} from 'pkg/api/models/event';
import DateTime from 'pkg/datetime';

import * as Input from 'components/form/inputs';
import Column from 'components/layout/column';

import * as css from './styles.css';

export function validateRSVPDate(rsvpTimestamp: number) {
	const now = DateTime.now().getUnixTimestamp();

	if (rsvpTimestamp < now) {
		return false;
	}

	return true;
}

interface RSVPInputsProps {
	rsvpBefore: boolean;
	interval: RSVPInterval;
	intervalCount: number;
	starts: number;
	hasError?: boolean;

	handleRSVPChange: () => void;
	handleIntervalChange: (e: ChangeEvent<HTMLSelectElement>) => void;
	handleIntervalCountChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export default function RSVPInputs({
	rsvpBefore,
	interval,
	intervalCount,
	starts,
	hasError,
	handleRSVPChange,
	handleIntervalChange,
	handleIntervalCountChange,
}: RSVPInputsProps) {
	const t = useT();

	const rsvpTimestamp = DateTime.fromTimestamp(starts).prev(
		rsvpIntervalMappedToGranularity[interval],
		intervalCount
	);

	const validateWrap = useCallback(
		() => validateRSVPDate(rsvpTimestamp.getUnixTimestamp()),
		[rsvpTimestamp]
	);

	return (
		<Column spacing={spacing._3}>
			<Input.Control
				name="RSVPControl"
				label={t('RSVP Before')}
				type="checkbox"
				checked={rsvpBefore}
				onChange={handleRSVPChange}
			/>
			{rsvpBefore && (
				<Fragment>
					<Input.Group
						className={css.inputGroup}
						hasError={hasError}
						justify="start">
						<Input.Field
							type="number"
							name="rsvpIntervalCount"
							value={intervalCount}
							onChange={handleIntervalCountChange}
							min="1"
							required
							validate={validateWrap}
						/>
						<Input.Select
							name="rsvpInterval"
							value={interval}
							onChange={handleIntervalChange}>
							<option value={RSVPInterval.RSVPIntervalHour}>
								{t('hours before')}
							</option>
							<option value={RSVPInterval.RSVPIntervalDay}>
								{t('day before')}
							</option>
							<option value={RSVPInterval.RSVPIntervalWeek}>
								{t('weeks before')}
							</option>
						</Input.Select>
					</Input.Group>
					<span>
						{rsvpTimestamp.toLocaleDateString({
							day: 'numeric',
							month: 'short',
							year: 'numeric',
							hour: 'numeric',
							minute: 'numeric',
						})}
					</span>
				</Fragment>
			)}
		</Column>
	);
}
