import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import VideoSequence, {
	VideoSequencePayload,
	VideoSequenceResponse,
} from 'pkg/models/video_sequence';
import { VideoSequenceUserPayload } from 'pkg/models/video_sequence_user';

import * as json from 'pkg/json';
import { RootState } from 'pkg/reducers';
import * as selectors from 'pkg/selectors';
import * as actions from 'pkg/actions';
import { useQueryState } from 'pkg/hooks/query-state';
import { useCurrentRoute } from 'pkg/router/hooks';
import { shallowCompare, only } from 'pkg/objects';
import copyString from 'pkg/copyString';
import { cuePointsFromSequences, VideoSequenceRecordingId } from 'pkg/video';
import { useCurrentAccount } from 'pkg/identity';

import { usePlayerState } from 'components/video-analytics/PlayerState';
import { ClipState, useClipState } from 'components/video-analytics/ClipState';
import { CueGroupType, useCueState } from 'components/video-analytics/CueState';
import { getCurrentFilterState } from 'components/video-analytics/FilterState';

import { useTimelineOffset } from './timeline';

interface ClipActionsHook {
	copy: () => Promise<void>;
	link: () => Promise<void>;
	edit: (currentTime?: number) => Promise<void>;
	cancel: () => Promise<void>;
	destroy: () => Promise<void>;
	activate: () => Promise<void>;
}

interface TaggedUsers {
	[userId: number]: string;
}

export function useClipActions(clipId: number): ClipActionsHook {
	const cueState = useCueState();
	const clipState = useClipState();
	const timelineOffset = useTimelineOffset();

	const {
		setEditing,
		setTrimming,
		setRecording,
		setJustRecorded,
		controller,
		setShowingClipList,
	} = usePlayerState();

	const isLargeScreen = useMediaQuery({
		minWidth: styles.breakpoint.fromMedium,
	});

	const cue = useSelector((state: RootState) =>
		selectors.videoSequences.find(state, clipId)
	);

	const cancel = async () => {
		setEditing(false);
		setTrimming(false);
		setRecording(false);
		setShowingClipList(false);

		actions.videoSequences.cancelRecord();
		actions.videoSequenceUsers.flushTemporary();

		if (!controller.isPlaying()) {
			controller.play();
		}

		clipState.reset(cue?.toJS() as any);
	};

	const copy = async () => {
		const nextCue = {
			...only(
				cue.toJS(),
				'videoId',
				'title',
				'description',
				'startsAt',
				'startsAtMs',
				'endsAt',
				'endsAtMs',
				'private',
				'reviewed',
				'annotations'
			),
			taggedUsers: {},
			id: VideoSequenceRecordingId,
			title: t('Copy of {title}', {
				title: cue.title,
			}),
		};

		setShowingClipList(false);

		clipState.reset(nextCue as any);

		await controller.seekTo(
			((nextCue.endsAtMs as number) / 1000) as number,
			false
		);

		setEditing(true);
		setRecording(true);
		setJustRecorded(true);

		if (isLargeScreen) {
			setTrimming(true);
		}

		controller.pause();
	};

	const link = async () => {
		copyString(
			[
				document.location.origin,
				document.location.pathname,
				`?clip=${clipId}`,
			].join('')
		);

		actions.flashes.show({
			title: t('Link copied to clipboard'),
		});

		setShowingClipList(false);
	};

	const activate = async () => {
		cueState.setCueId(clipId);

		if (cue) {
			timelineOffset.set(cue.getStartsAt());
			await controller.seekTo(cue.getStartsAt(), true);

			if (cue.annotations) {
				const annotations = json.parse(cue.annotations);

				if (annotations) {
					clipState.setAnnotations(annotations);
				}
			}
		}

		setShowingClipList(false);
	};

	const edit = async (currentTime?: number) => {
		cueState.setCueId(clipId);

		if (cue) {
			let startsAt = cue.getStartsAt();

			if (
				currentTime &&
				currentTime >= startsAt &&
				currentTime <= cue.getEndsAt()
			) {
				startsAt = currentTime;
			}

			timelineOffset.set(startsAt);
			await controller.seekTo(startsAt, true);
		}

		const nextCue = {
			...only(
				cue.toJS(),
				'videoId',
				'title',
				'description',
				'startsAt',
				'startsAtMs',
				'endsAt',
				'endsAtMs',
				'private',
				'reviewed',
				'annotations'
			),
			taggedUsers: {},
		};

		setShowingClipList(false);

		clipState.reset(nextCue as any);

		setEditing(true);

		if (isLargeScreen) {
			setTrimming(true);
		}

		await controller.pause();
	};

	const destroy = async () => {
		cancel();

		actions.videoSequences.destroy(clipId);

		actions.flashes.show({
			title: t('Successfully deleted clip'),
		});
	};

	return {
		copy,
		link,
		edit,
		cancel,
		destroy,
		activate,
	};
}

interface ClipChangesHook {
	hasChanges: boolean;
	save: (additionalPayload?: Partial<VideoSequencePayload>) => Promise<void>;
}

export function useClipChanges(clipId: number): ClipChangesHook {
	const cueState = useCueState();
	const clipState = useClipState();
	const queryState = useQueryState();
	const { videoId } = useCurrentRoute();
	const filters = getCurrentFilterState();
	const timelineOffset = useTimelineOffset();

	const {
		setEditing,
		setTrimming,
		setRecording,
		isRecording,
		controller,
		setShowingClipList,
	} = usePlayerState();

	const accountId = useCurrentAccount().id;

	const cue = useSelector((state: RootState) =>
		selectors.videoSequences.find(state, clipId)
	);

	const comments = useSelector((state: RootState) =>
		selectors.videoSequenceUsers.findAllComments(state, clipId)
	);

	const sequenceUserIds = useSelector((state: RootState) =>
		selectors.videoSequenceUsers.findAllUserIds(state, clipId)
	);

	const filteredSequences = useSelector((state: RootState) =>
		selectors.videoSequences.findAllFilteredSequences(state, videoId, filters)
	).filter((sequence: VideoSequence) => sequence.accountId === accountId);

	const persisted = !isRecording;

	useEffect(() => {
		if (cue) {
			clipState.reset({ ...(cue.toJS() as any), taggedUsers: comments });
		}
	}, [clipId]);

	const cancel = async (resetCue?: ClipState) => {
		setEditing(false);
		setTrimming(false);
		setRecording(false);
		setShowingClipList(false);

		actions.videoSequences.cancelRecord();
		actions.videoSequenceUsers.flushTemporary();

		if (!controller.isPlaying()) {
			controller.play();
		}

		clipState.reset(resetCue || (cue.toJS() as any));
	};

	const createTaggedUsers = async (
		videoSequenceId: number,
		inserts: TaggedUsers
	) => {
		const keys = Object.keys(inserts).map((key: string) =>
			Number.parseInt(key, 10)
		);

		if (keys.length > 0) {
			const payloads: VideoSequenceUserPayload[] = keys.map(
				(userId: number) => ({
					videoSequenceId,
					userId,
					description: inserts[userId as number] ?? '',
				})
			);

			Promise.all(
				payloads.map(
					async (payload: VideoSequenceUserPayload) =>
						await actions.videoSequenceUsers.create(payload)
				)
			);
		}
	};

	const updateTaggedUsers = async (
		videoSequenceId: number,
		updates: TaggedUsers
	) => {
		const keys = Object.keys(updates).map((key: string) =>
			Number.parseInt(key, 10)
		);

		const sequenceKeys = Object.keys(sequenceUserIds).map((key: string) =>
			Number.parseInt(key, 10)
		);

		if (keys.length > 0) {
			interface Payloads {
				[sequenceId: number]: VideoSequenceUserPayload;
			}

			const payloads: Payloads = sequenceKeys.reduce(
				(p: Payloads, sequenceId: number) => {
					const userId = sequenceUserIds[sequenceId];

					if (updates[userId]) {
						p[sequenceId] = {
							videoSequenceId,
							userId,
							description: updates[userId],
						};
					}

					return p;
				},
				{}
			);

			if (Object.keys(payloads).length > 0) {
				Promise.all(
					Object.entries(payloads).map(
						async ([sequenceUserId, payload]) =>
							await actions.videoSequenceUsers.update(
								Number.parseInt(sequenceUserId, 10),
								payload
							)
					)
				);
			}
		}
	};

	const deleteTaggedUsers = async (deletes: TaggedUsers) => {
		const keys = Object.keys(deletes).map((key: string) =>
			Number.parseInt(key, 10)
		);

		if (keys.length > 0) {
			const deleteKeys: number[] = [];

			Object.entries(sequenceUserIds).forEach(([sequenceUserId, userId]) => {
				if (keys.includes(userId as number)) {
					deleteKeys.push(Number.parseInt(sequenceUserId, 10));
				}
			});

			if (deleteKeys.length > 0) {
				Promise.all(
					deleteKeys.map(async (sequenceUserId: number) =>
						actions.videoSequenceUsers.destroy(sequenceUserId)
					)
				);
			}
		}
	};

	const recalculateCuePoints = (nextCueId: number) => {
		cueState.setCuePoints(
			cuePointsFromSequences(filteredSequences),
			CueGroupType.Account,
			nextCueId
		);
	};

	const save = async (
		additionalPayload: Partial<VideoSequencePayload> = {}
	) => {
		const payload: VideoSequencePayload = {
			...(clipState.getPayload() as VideoSequencePayload),
			...additionalPayload,
		};

		if (!payload.title) {
			payload.title = t('Unnamed clip');
		}

		const diff = shallowCompare<TaggedUsers>(comments, clipState.taggedUsers);

		if (persisted) {
			const updatedClip = await actions.videoSequences.update(clipId, payload);

			createTaggedUsers(clipId, diff.inserts);

			const updateKeys = Object.keys(diff.updates).map((key: string) =>
				Number.parseInt(key, 10)
			);

			if (updateKeys.length > 0) {
				updateTaggedUsers(clipId, diff.updates);
			}

			const deleteKeys = Object.keys(diff.deletes).map((key: string) =>
				Number.parseInt(key, 10)
			);

			if (deleteKeys.length > 0) {
				deleteTaggedUsers(diff.deletes);
			}

			cueState.setCueId(clipId);

			if (updatedClip) {
				actions.flashes.show({
					title: t('Clip changes saved'),
				});
			}

			recalculateCuePoints(clipId);
			cancel(updatedClip);

			if (updatedClip.annotations) {
				const annotations = json.parse(updatedClip.annotations);

				if (annotations) {
					clipState.setAnnotations(annotations);
				}
			}
		} else {
			const newSequence: VideoSequenceResponse =
				await actions.videoSequences.create({ ...payload, videoId });

			if (newSequence.id) {
				createTaggedUsers(newSequence.id, diff.inserts);
			}

			queryState.set('new', newSequence.id);
			queryState.commit();

			timelineOffset.set(payload.endsAtMs / 1000);
			await controller.seekTo(payload.endsAtMs / 1000, true);

			actions.flashes.show({
				title: t('New clip created'),
			});

			cancel(newSequence);
		}
	};

	return {
		save,
		hasChanges: clipState.hasChanges,
	};
}
