import { ReactNode } from 'react';

export const symbol_widgets: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M658-487 487-658q-5-5-7-10.133-2-5.134-2-11Q478-685 480-690q2-5 7-10l171-171q5-5 10.133-7 5.134-2 11-2Q685-880 690-878q5 2 10 7l171 171q5 5 7 10.133 2 5.134 2 11Q880-673 878-668q-2 5-7 10L700-487q-5 5-10.133 7-5.134 2-11 2Q673-478 668-480q-5-2-10-7Zm-538-81v-241q0-12.75 8.625-21.375T150-839h241q12.75 0 21.375 8.625T421-809v241q0 12.75-8.625 21.375T391-538H150q-12.75 0-21.375-8.625T120-568Zm418 418v-241q0-12.75 8.625-21.375T568-421h241q12.75 0 21.375 8.625T839-391v241q0 12.75-8.625 21.375T809-120H568q-12.75 0-21.375-8.625T538-150Zm-418 0v-241q0-12.75 8.625-21.375T150-421h241q12.75 0 21.375 8.625T421-391v241q0 12.75-8.625 21.375T391-120H150q-12.75 0-21.375-8.625T120-150Zm60-448h181v-181H180v181Zm502 51 129-129-129-129-129 129 129 129Zm-84 367h181v-181H598v181Zm-418 0h181v-181H180v181Zm181-418Zm192-78ZM361-361Zm237 0Z"
		/>
	</svg>
);
