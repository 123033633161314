import { Fragment, useState, useContext } from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { useT } from '@transifex/react';

import * as breakpoints from 'pkg/config/breakpoints';

import Icon from 'components/icon';

import FabricCanvas from 'components/drawing/FabricCanvas';
import TopBar from 'components/drawing/edit/TopBar';
import Zoom from 'components/drawing/edit/Zoom';
import SettingsPane from 'components/drawing/edit/SettingsPane';
import PrefsPane from 'components/drawing/edit/PrefsPane';
import Tabs from 'components/drawing/edit/Tabs';
import Pane from 'components/drawing/edit/Pane';
import Tools from 'components/drawing/edit/Tools';
import { FabricContext } from 'components/drawing/FabricContext';
import Artboard from 'components/drawing/edit/Artboard';
import BackgroundModal from 'components/drawing/edit/BackgroundModal';
import { MAX_ZOOM, MIN_ZOOM } from 'components/drawing/config';
import StepsPane from 'components/drawing/edit/StepsPane';

const DrawingWrapper = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 0;
	font-size: var(--font-size-sm);
	background: var(--palette-gray-200);
	overflow: hidden;
	z-index: 100000;

	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 40px 1fr 40px 25vh;
	grid-template-areas:
		'topbar'
		'artboard'
		'tabs'
		'panes';

	.drawing-topbar {
		grid-area: topbar;
	}
	.drawing-artboard {
		grid-area: 2 / 1 / 5 / 2; // artboard area and continue behind overlapping tabs and panes
		overflow: auto;

		&.drawing-previewing {
			pointer-events: none;
		}
	}

	.drawing-tabs {
		grid-area: tabs;
	}
	.drawing-tabs,
	.drawing-tabs ~ div {
		z-index: 10; // place above artboard canvas
	}
	.drawing-tools,
	.drawing-steps,
	.drawing-settings {
		grid-area: panes;
	}
	.drawing-prefs {
		transition: transform 0.2s;
		grid-area: 3 / 1 / 5 / 2; // artboard area and continue behind overlapping tabs and panes
	}
	&.prefs-closed .drawing-prefs {
		transform: translateY(100%);
	}

	// animate bottom panes from bottom on mobile
	@media (max-width: ${breakpoints.toMedium}px) {
		.drawing-tabs,
		.drawing-tabs ~ div {
			transition: transform 0.2s;
		}
		&.mobile-closed {
			.drawing-tabs,
			.drawing-tabs ~ div:not(.drawing-prefs) {
				transform: translateY(25vh);
			}
		}
	}

	// desktop layout
	@media (min-width: ${breakpoints.fromMedium}px) {
		grid-template-columns: 250px 1fr 255px;
		grid-template-rows: 65px 40px 1fr;
		grid-template-areas:
			'topbar topbar topbar'
			'tools artboard tabs'
			'tools artboard panes';

		.drawing-artboard {
			grid-area: artboard;
		}

		// tool bar on the left
		.drawing-tools {
			grid-area: tools;
		}

		.drawing-tabs,
		.drawing-tools,
		.drawing-settings,
		.drawing-steps {
			transition: transform 0.2s;
		}

		// closed tools
		&.tools-closed {
			.drawing-artboard {
				grid-area: 2 / 1 / 4 / 3;
			}
			.drawing-tools {
				transform: translateX(-100%);
			}
		}
		&.settings-closed {
			.drawing-artboard {
				grid-area: 2 / 2 / 4 / 4;
			}
			.drawing-tabs,
			.drawing-settings,
			.drawing-steps {
				transform: translateX(100%);
			}
		}
		&.prefs-closed .drawing-prefs {
			transform: translateX(100%);
		}

		// both panes closed
		&.tools-closed.settings-closed {
			.drawing-artboard {
				grid-area: 2 / 1 / 4 / 4;
			}
		}

		// tool prefs cover tabs and panes
		.drawing-prefs {
			grid-area: 2 / 3 / 4 / 4;
		}
	}
`;

export default function EditWrapper({ saveAndClose }: any) {
	const { zoom, setZoom, selectedObject } = useContext(FabricContext);
	const [tab, setTab] = useState('tools');
	const [isPreviewing, setIsPreviewing] = useState(false);
	const t = useT();

	const isDesktop = useMediaQuery({ minWidth: breakpoints.fromMedium });

	// desktop side panes
	const [toolsExpanded, setToolsExpanded] = useState(true);
	const [settingsExpanded, setSettingsExpanded] = useState(true);

	// mobile bottom tabs and panes
	const [mobileExpanded, setMobileExpanded] = useState(true);

	const openTab = (newtab: string) => {
		setTab(newtab);
		setMobileExpanded(true);
	};

	const toggleMobile = () => {
		setMobileExpanded(!mobileExpanded);
	};

	return (
		<DrawingWrapper
			className={`
			tab-${tab}
			${toolsExpanded ? '' : 'tools-closed'}
			${settingsExpanded ? '' : 'settings-closed'}
			${mobileExpanded ? '' : 'mobile-closed'}
			${selectedObject ? '' : 'prefs-closed'}
			`}>
			<TopBar
				className="drawing-topbar"
				saveAndClose={saveAndClose}
				isPreviewing={isPreviewing}
				setIsPreviewing={setIsPreviewing}
			/>
			{!isPreviewing && (
				<Zoom
					step={0.25}
					min={MIN_ZOOM}
					max={MAX_ZOOM}
					current={zoom}
					onChange={setZoom}
				/>
			)}
			<Artboard
				className={
					'drawing-artboard' + (isPreviewing ? ' drawing-previewing' : '')
				}>
				<FabricCanvas isEditing />
			</Artboard>
			{!isPreviewing && (
				<Fragment>
					<Tabs
						className="drawing-tabs"
						tabs={{
							tools: !isDesktop && 'Tools',
							steps: t('Steps', {
								_context: 'training_library/drawing',
							}),
							settings: t('Settings', {
								_context: 'training_library/drawing',
							}),
						}}
						current={tab}
						openTab={openTab}
						toggleMobile={toggleMobile}
					/>
					<Pane
						className="drawing-tools"
						visible={isDesktop || tab === 'tools'} // always visible on desktop
						desktopLeft>
						<Tools />
					</Pane>
					<Pane
						className="drawing-settings"
						visible={tab === 'steps' || (isDesktop && tab === 'tools')} // visible as fallback on desktop
					>
						<StepsPane />
					</Pane>

					<Pane className="drawing-settings" visible={tab === 'settings'}>
						<SettingsPane />
					</Pane>
					<Pane className="drawing-prefs" visible={true}>
						{selectedObject && <PrefsPane />}
					</Pane>

					<PaneToggle
						className="left"
						onClick={() => {
							setToolsExpanded(!toolsExpanded);
						}}>
						<Icon
							name={toolsExpanded ? 'collapse' : 'collapse-reverse'}
							size={1.8}
						/>
					</PaneToggle>
					{!selectedObject && (
						<PaneToggle
							className="right"
							onClick={() => {
								setSettingsExpanded(!settingsExpanded);
							}}>
							<Icon
								name={settingsExpanded ? 'collapse' : 'collapse-reverse'}
								size={1.8}
							/>
						</PaneToggle>
					)}

					<BackgroundModal />
				</Fragment>
			)}
		</DrawingWrapper>
	);
}

const PaneToggle = styled.div<{ desktopLeft?: boolean }>`
	position: absolute;
	bottom: 10px;
	padding: 7px 8px;
	background: var(--palette-gray-300);
	color: var(--palette-gray-500);
	border-radius: var(--radius-2);
	transition: transform 0.2s;
	cursor: pointer;

	&.left {
		left: 260px;

		.tools-closed & {
			transform: translateX(-250px);
		}
	}

	&.right {
		right: 265px;
		transform: rotateY(180deg);

		.settings-closed & {
			transform: rotateY(180deg) translateX(-250px);
		}
	}

	@media (max-width: ${breakpoints.toMedium}px) {
		display: none;
	}
`;
