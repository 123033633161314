import { ReactNode } from 'react';

export const symbol_music_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M785-78 78-784q-9-9-9-21.5t9-21.5q9-9 21.5-9t21.5 9l707 707q9 9 9 21t-9 21q-9 9-21.5 9T785-78ZM546-705v146q0 15-9.5 22t-20.5 7q-11 0-20.5-7.5T486-559v-251q0-13 8.5-21.5T516-840h174q13 0 21.5 8.5T720-810v75q0 13-8.5 21.5T690-705H546ZM396-120q-63 0-106.5-43.5T246-270q0-63 43.5-106.5T396-420q28 0 50.5 8t39.5 22v-72l60 60v132q0 63-43.5 106.5T396-120Z"
		/>
	</svg>
);
