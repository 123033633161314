import { ReactNode } from 'react';

export const symbol_home_health: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M430-390v50q0 20.833 14.618 35.417Q459.235-290 480.118-290 501-290 515.5-304.583 530-319.167 530-340v-50h50q20.833 0 35.417-14.618Q630-419.235 630-440.118 630-461 615.417-475.5 600.833-490 580-490h-50v-50q0-20.833-14.618-35.417Q500.765-590 479.882-590 459-590 444.5-575.417 430-560.833 430-540v50h-50q-20.833 0-35.417 14.618Q330-460.765 330-439.882 330-419 344.583-404.5 359.167-390 380-390h50ZM220-120q-24.75 0-42.375-17.625T160-180v-390q0-14.25 6.375-27T184-618l260-195q15.68-12 35.84-12Q500-825 516-813l260 195q11.25 8.25 17.625 21T800-570v390q0 24.75-17.625 42.375T740-120H220Zm0-60h520v-394L480-763 220-574v394Zm260-292Z"
		/>
	</svg>
);
