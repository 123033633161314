import { ReactNode } from 'react';

export const symbol_bottom_navigation: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-220h600v-440H180v440Zm0 60v100h600v-100H180Zm0 0v100-100Z"
		/>
	</svg>
);
