import { T, useT } from '@transifex/react';

import spacing from 'pkg/config/spacing';

import * as routes from 'pkg/router/routes';
import * as models from 'pkg/api/models';
import { cssClasses } from 'pkg/css/utils';

import Badge from 'components/Badge';
import Label from 'components/label';
import * as Card from 'components/Card';

import Row from 'components/layout/row';
import Column from 'components/layout/column';
import ImageAttachment from 'components/attachment/ImageAttachment';
import AvatarStack from 'components/avatar/avatar-stack';
import AvailableSpots from 'components/forms/available-spots';

import Button from 'design/button';

import * as css from './styles.css';

interface FormsGridItemProps {
	form: models.form.Form;
	group: models.group.Group;
}

export default function FormsGridItem({ form, group }: FormsGridItemProps) {
	const t = useT();

	const submissionsString = form.submissions
		?.map((fs) => fs.submittedForUser.firstName)
		.join(', ');

	let statusColor = 'green';

	if (form.status === models.form.Statuses.Closed) {
		statusColor = 'gray';
	}

	let coverImageContent = (
		<Badge badgeUrl={group.profileImageUrl} size="85px" />
	);

	if (form.attachment) {
		coverImageContent = <ImageAttachment attachment={form.attachment} />;
	}

	return (
		<Card.Base className={css.cardBase}>
			<div className={cssClasses(css.image)}>{coverImageContent}</div>
			<Card.Body $flex>
				<Column className={css.bodyColumn} spacing={spacing._6}>
					<Column spacing={spacing._3}>
						<Row autoColumns="max-content" spacing={spacing._2}>
							<Label color={statusColor}>
								{models.form.getTranslatedStatus(form.status)}
							</Label>
							{!!form.maxSubmissions && <AvailableSpots form={form} />}
						</Row>
						<Column spacing={spacing._1}>
							<div className={css.title}>{form.title}</div>
							<div className={css.description}>{form.description}</div>
						</Column>
					</Column>
					<Column>
						{form.submissions && (
							<AvatarStack
								users={form.submissions.map((sb) => sb.submittedForUser)}
								renderRestWith={
									<span className={css.stackText}>
										<T
											_str="Registered by you: {submissions}"
											submissions={
												<div className={css.submissionNames}>
													{submissionsString}
												</div>
											}
										/>
									</span>
								}
								size={25}
							/>
						)}
						<Button
							label={t('Register now')}
							secondary
							href={routes.Registration.Index(form.group.slug, form.guid)}
						/>
					</Column>
				</Column>
			</Card.Body>
		</Card.Base>
	);
}
