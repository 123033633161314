import { ReactNode } from 'react';

export const symbol_design_services: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m357-513 90-90-75-75-27 27q-9 9-21 9t-21-9q-9-9-9-21t9-21l27-27-75-74-90 90 192 191Zm346 348 90-91-75-75-27 27q-9 9-21 9t-21-9q-9-9-9-21t9-21l27-27-74-74-90 90 191 192Zm8-615 70 70-70-70ZM150-120q-13 0-21.5-8.5T120-150v-114q0-6 2-11t7-10l185-185-202-202q-14-14-13.5-32t13.5-32l110-112q14-14 32-14t32 14l204 203 178-178q9-9 20-13t22-4q11 0 22 4t20 13l71 71q9 9 13 20t4 22q0 11-4 22t-13 20L645-490l203 203q14 14 14 32t-14 32L737-113q-14 14-32 14t-32-14L471-315 285-129q-5 5-10 7t-11 2H150Zm30-60h70l393-394-70-70-393 394v70Zm428-429-35-35 70 70-35-35Z"
		/>
	</svg>
);
