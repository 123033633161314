import { ReactNode } from 'react';

export const symbol_enterprise_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m824-43-77-77H140q-24 0-42-18t-18-42v-480q0-24 18-42t42-18h93l60 60H140v480h548L80-787q-9-9-9-21t9-21q9-9 21.5-9t21.5 9L867-85q9 9 8.5 21T866-43q-9 9-21 9t-21-9Zm56-617v405q0 15-9.318 22.5t-20.5 7.5Q839-225 829.5-232.683 820-240.366 820-255v-405H427q-12.444 0-23.722-5T384.3-677.7l-47.6-47.6q-7.7-7.7-12.2-18.978Q320-755.556 320-768v-52q0-23 18.5-41.5T380-880h200q24 0 42 18.5t18 41.5v100h180q23 0 41.5 18t18.5 42Zm-500-60h200v-100H380v100Zm231 268Zm-163 32Z"
		/>
	</svg>
);
