import { useState } from 'react';

import { pushState } from 'pkg/router/state';
import * as routes from 'pkg/router/routes';
import { useCurrentMembership, useCurrentOrganization } from 'pkg/identity';

import CreateExerciseForm from 'components/training/exercise/CreateExerciseForm';

const CreateExercise = (): JSX.Element => {
	const membership = useCurrentMembership();
	const org = useCurrentOrganization();
	const [activeCollectionModal, setActiveCollectionModal] = useState(0);

	const returnToLibrary = () => {
		pushState(routes.TrainingLibrary.Index(org.id, membership.groupId));
	};

	const onSave = (id: number) => {
		// trigger collection modal first time exercise is created
		setActiveCollectionModal(id);
	};

	const closeCollectionModal = () => {
		setActiveCollectionModal(0);
		returnToLibrary();
	};

	return (
		<CreateExerciseForm
			onSave={onSave}
			onCancel={returnToLibrary}
			collectionModalExerciseId={activeCollectionModal}
			toggleCollectionModal={closeCollectionModal}
		/>
	);
};

export default CreateExercise;
