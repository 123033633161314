import { Record } from 'immutable';
import { schema } from 'normalizr';

import { products } from 'pkg/models/schemas';

interface IProduct {
	id: number;
	groupId: number;
	userId: number;
	productCategoryId: number;
	name: string;
	description: string;
	isDefault: boolean;
	paymentProviderId: string;
	createdAt: number;
	updatedAt: number;
	archivedAt: number;
	prices: number[];
}

const productProps: IProduct = {
	id: 0,
	groupId: 0,
	userId: 0,
	productCategoryId: 0,
	name: '',
	description: '',
	isDefault: false,
	paymentProviderId: '',
	createdAt: 0,
	updatedAt: 0,
	archivedAt: null,
	prices: null,
};

class Product
	extends Record(productProps, 'ProductRecord')
	implements IProduct
{
	static normalizr(): schema.Entity {
		return products;
	}
}

export default Product;
