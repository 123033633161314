import { t } from '@transifex/native';
import styled from 'styled-components';
import { Fragment } from 'react';

import * as styles from 'pkg/config/styles';

import { useQueryState } from 'pkg/hooks/query-state';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import Icon from 'components/icon';

import Row from 'components/layout/row';

import * as Context from 'design/context_menu';
import Button from 'design/button';

const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: var(--palette-blue-500);
`;

const TextRow = styled(Row)`
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`;

const Text = styled.div`
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`;

export enum SchedulingViews {
	Resource = 'resource',
	Group = 'group',
}

const SelectView = () => {
	const qs = useQueryState();
	const view: SchedulingViews =
		(qs.get('view') as SchedulingViews) ||
		(sessionStorage.getItem('schedulingView') as SchedulingViews);
	const isGroupView = view === SchedulingViews.Group;

	const setGroupView = () => {
		qs.set('view', SchedulingViews.Group);
		qs.commit();
	};

	const setResourceView = () => {
		qs.set('view', SchedulingViews.Resource);
		qs.commit();
	};

	return (
		<Context.Menu
			toggleWith={
				<Fragment>
					<LargeScreen>
						<Wrapper>
							<TextRow
								autoColumns="auto"
								spacing={styles.spacing._2}
								align="center">
								<Icon name={isGroupView ? 'nav-members' : 'resource'} />
								<Text>{isGroupView ? t(`Groups`) : t(`Resources`)}</Text>
							</TextRow>
							<Icon name="chevron-down" size={1.3} />
						</Wrapper>
					</LargeScreen>
					<SmallScreen>
						<Button
							icon={isGroupView ? 'groups' : 'location_on'}
							label={isGroupView ? t(`View: Groups`) : t(`View: Resources`)}
						/>
					</SmallScreen>
				</Fragment>
			}>
			<Context.Item onClick={setGroupView}>
				<Context.ItemIcon name="groups" />
				{t(`Groups`)}
			</Context.Item>
			<Context.Item onClick={setResourceView}>
				<Context.ItemIcon name="home_pin" />
				{t(`Resources`)}
			</Context.Item>
		</Context.Menu>
	);
};

export default SelectView;
