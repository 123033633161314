import { t } from '@transifex/native';
import { Fragment, useState } from 'react';

import * as models from 'pkg/api/models';
import * as routes from 'pkg/router/routes';
import { formatPriceIntervalToText } from 'pkg/utils';
import DateTime from 'pkg/datetime';
import * as actions from 'pkg/actions';
import { formatToCurrency } from 'pkg/i18n/formatters';
import { OrderType } from 'pkg/api/models/order';
import { useCurrentGroup, useCurrentOrganization } from 'pkg/identity';

import EditModal from 'routes/payments/contacts/single/user_products/Edit';

import MissingEntities from 'components/missing-entities';
import CardAnatomy, * as Card from 'components/Card';
import SectionTitle from 'components/SectionTitle';
import Icon from 'components/icon';

import Column from 'components/layout/column';
import StatusLabel from 'components/payment_platform/status/label';

import * as Table from 'design/table';
import * as ContextMenu from 'design/context_menu';
import Button from 'design/button';

interface ProductItemProps {
	userProduct: models.userProduct.UserProduct;
	currency: string;
}

export const ProductItem: React.FC<
	React.PropsWithChildren<ProductItemProps>
> = ({ userProduct, currency }) => {
	const org = useCurrentOrganization();

	const [modal, setModal] = useState(false);

	const createdAt = new DateTime(new Date(userProduct.createdAt * 1000));
	const validUntil = new DateTime(new Date(userProduct.validUntil * 1000));

	let total = 0;
	if (userProduct.productPrice) {
		total = userProduct.productPrice.cost / 100;

		if (
			userProduct.productPrice.taxRate &&
			!userProduct.productPrice.taxRate.inclusive
		) {
			total =
				total + total * (userProduct.productPrice.taxRate.percentage / 100);
		}
	}

	const handleRemove = async () =>
		actions.userProducts.removeRelation(userProduct.id);

	const productShowUrl =
		userProduct.orderID && routes.Order.Show(org.id, userProduct.orderID);

	const handleEdit = () => setModal(true);
	const closeEdit = () => setModal(false);

	const productContext = (
		<ContextMenu.Menu
			toggleWith={
				<ContextMenu.TableTrigger>
					<Icon name="context-menu" />
				</ContextMenu.TableTrigger>
			}>
			{userProduct.orderID && (
				<ContextMenu.LinkItem
					href={routes.Order.Show(org.id, userProduct.orderID)}>
					<ContextMenu.ItemIcon name="arrow_forward" />
					{t('View invoice')}
				</ContextMenu.LinkItem>
			)}
			<ContextMenu.LinkItem
				href={routes.Product.Show(org.id, userProduct.product.id, 'overview')}>
				<ContextMenu.ItemIcon name="arrow_forward" />
				{t('View product')}
			</ContextMenu.LinkItem>
			<ContextMenu.Item onClick={handleEdit}>
				<ContextMenu.ItemIcon name="edit" />
				{t('Edit assignment')}
			</ContextMenu.Item>
			<ContextMenu.ConfirmItem
				caution
				message={t('Are you sure you want to remove the assigned product?')}
				onConfirm={handleRemove}>
				<ContextMenu.ItemIcon name="block" />
				{t('Delete assignment')}
			</ContextMenu.ConfirmItem>
		</ContextMenu.Menu>
	);

	return (
		<Fragment>
			<Table.Row>
				<Table.LinkCell href={productShowUrl}>
					{userProduct.product.name}
				</Table.LinkCell>
				<Table.LinkCell href={productShowUrl}>
					{!!userProduct.orderID && (
						<Fragment>
							{formatToCurrency(total, currency)}{' '}
							{formatPriceIntervalToText(
								userProduct.productPrice.recurringInterval,
								userProduct.productPrice.recurringIntervalCount
							)}
						</Fragment>
					)}
				</Table.LinkCell>
				<Table.LinkCell href={productShowUrl}>
					<StatusLabel status={{ name: userProduct.status }} />
				</Table.LinkCell>
				<Table.LinkCell href={productShowUrl}>
					{userProduct.validUntil > 0 ? validUntil.toLocaleDateString() : '-'}
				</Table.LinkCell>

				<Table.LinkCell href={productShowUrl}>
					{userProduct.order?.type !== OrderType.Payment && (
						<span>
							{createdAt.toLocaleDateString({
								year: 'numeric',
								month: 'numeric',
								day: 'numeric',
							})}
						</span>
					)}
				</Table.LinkCell>
				<Table.ActionCell>{productContext}</Table.ActionCell>
			</Table.Row>

			{modal && (
				<EditModal
					userProduct={userProduct}
					product={userProduct.product}
					handleClose={closeEdit}
				/>
			)}
		</Fragment>
	);
};

interface AssignedProductsProps {
	userProducts: models.userProduct.UserProduct[];
	contact: models.user.User;
	handleOpenModal: (name: string) => void;
}

const AssignedProducts: React.FC<
	React.PropsWithChildren<AssignedProductsProps>
> = ({ userProducts, contact, handleOpenModal }) => {
	const org = useCurrentOrganization();
	const group = useCurrentGroup();

	const handleAssignProduct = () => handleOpenModal('product');

	const tableColumns: Table.HeaderColumn[] = [
		{ content: t('Product'), width: '180px', align: 'left' },
		{ content: t('Price'), align: 'left' },
		{ content: t('Status'), width: 'max-content', align: 'left' },
		{ content: t('Valid until'), align: 'left' },
		{ content: t('Created'), align: 'left' },
		{ content: '', width: 'max-content' },
	];

	const content = !userProducts ? (
		<CardAnatomy $noBorder>
			<Card.Body>
				<MissingEntities centered>
					<Icon name="tag" />
					<p>{t('No assigned products')}</p>
				</MissingEntities>
			</Card.Body>
		</CardAnatomy>
	) : (
		<Fragment>
			<Card.Base>
				<Table.Table columns={tableColumns}>
					{userProducts.map((userProduct: models.userProduct.UserProduct) => (
						<ProductItem
							key={userProduct.id}
							userProduct={userProduct}
							currency={group.currency}
						/>
					))}
				</Table.Table>
			</Card.Base>
		</Fragment>
	);

	return (
		<div>
			<Column>
				<SectionTitle icon="sell">
					{t('Assigned products')}
					{!userProducts ? (
						<Button
							disabled={!!contact.deletedAt}
							inline
							noPadding
							icon="add"
							onClick={handleAssignProduct}>
							{t('Assign products')}
						</Button>
					) : (
						<Button
							icon="arrow_forward"
							iconPosition="right"
							inline
							noPadding
							href={routes.Management.Contact.Show(
								org.id,
								contact.id,
								'products'
							)}>
							{t('View all')}
						</Button>
					)}
				</SectionTitle>
				{content}
			</Column>
		</div>
	);
};

export default AssignedProducts;
