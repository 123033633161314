import { Fragment } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as flashActions from 'pkg/actions/flashes';

import { shareableUrl } from 'pkg/url';
import copyString from 'pkg/copyString';
import * as models from 'pkg/api/models';
import { useCurrentOrganization } from 'pkg/identity';
import * as routes from 'pkg/router/routes';

import StatusScreen from 'components/status-screen';
import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import * as ActionBar from 'components/layout/ActionBar';
import * as Input from 'components/form/inputs';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import Row from 'components/layout/row';

import Button from 'design/button';

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
`;
interface SuccessViewProps {
	groupId: number;
	form: models.form.Form;
	handlePublish: () => void;
	isEdit?: boolean;
	setIsSaved?: (value: boolean) => void;
}

export default function SuccessView(props: SuccessViewProps) {
	return (
		<Fragment>
			<LargeScreen>
				<LargeScreenContent.Wrapper>
					<LargeScreenContent.Inner maxWidth={styles.size.PageWidths.STANDARD}>
						<Content {...props} />
					</LargeScreenContent.Inner>
				</LargeScreenContent.Wrapper>
			</LargeScreen>
			<SmallScreen>
				<LargeScreenContent.Inner>
					<Content {...props} />
				</LargeScreenContent.Inner>
			</SmallScreen>
		</Fragment>
	);
}

const Content: React.FC<React.PropsWithChildren<SuccessViewProps>> = ({
	groupId,
	form,
	handlePublish,
	isEdit,
	setIsSaved,
}) => {
	const org = useCurrentOrganization();

	const copyURL = shareableUrl(
		routes.Registration.Index(form.group.slug, form.guid)
	);

	const handleCopyUrl = () => {
		copyString(copyURL);
		flashActions.show({ title: t('URL copied') });
	};

	const handleSetIsSaved = () => {
		setIsSaved(false);
	};

	const isDraft = form.status === models.form.Statuses.Draft;
	const isOpen = form.status === models.form.Statuses.Open;

	let title = '';
	if (isEdit) {
		title = t('Registration updated');
	} else {
		title = t('Registration created');
	}

	let description = '';
	switch (form.status) {
		case 'closed':
			description = t(
				`Your registration is saved, it's closed to submissions.`
			);
		case 'draft':
			description = t(
				`Your draft registration is saved, publish it to open make it available for submissions.`
			);
		case 'open':
			description = t(`Your registration is saved, it's open to submissions.`);
	}

	return (
		<Fragment>
			<SmallScreen>
				<ActionBar.SaveBar>
					<Button
						primary
						large
						block
						href={routes.Registrations.Index(org.id, groupId, 'active')}>
						{t('Finish')}
					</Button>
				</ActionBar.SaveBar>
			</SmallScreen>
			<Wrapper>
				<StatusScreen status="success" title={title} description={description}>
					<LargeScreen>
						<Button
							block
							large
							primary
							href={routes.Registrations.Index(org.id, groupId, 'active')}>
							{t('Finish')}
						</Button>
					</LargeScreen>
					{isDraft && (
						<Button block large onClick={handlePublish}>
							{t('Publish')}
						</Button>
					)}
					{setIsSaved ? (
						<Button block large onClick={handleSetIsSaved}>
							{t('Edit')}
						</Button>
					) : (
						<Button
							block
							large
							href={routes.Registrations.Edit(org.id, groupId, form.id)}>
							{t('Edit')}
						</Button>
					)}
					<Button
						href={routes.Registration.Index(form.group.slug, form.guid)}
						block
						large>
						{t('View')}
					</Button>

					{isOpen && (
						<Fragment>
							<Row autoColumns="1fr auto" align="center">
								<Input.Field name="url" type="text" value={copyURL} readonly />
								<Button block large onClick={handleCopyUrl}>
									{t('Copy URL')}
								</Button>
							</Row>
						</Fragment>
					)}
				</StatusScreen>
			</Wrapper>
		</Fragment>
	);
};
