import { Dispatch, SetStateAction } from 'react';

import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCollection } from 'pkg/api/use_collection';
import { buildGroupTree } from 'pkg/api/models/group';

import OrganizationTable, {
	DialogProps,
} from 'routes/group/settings/OrganizationTable';

interface BaseTreeProps {
	dialog: DialogProps;
	setDialog: Dispatch<SetStateAction<DialogProps>>;
	rootGroup: models.group.Group;
}
export default function BaseTree({
	dialog,
	setDialog,
	rootGroup,
}: BaseTreeProps): JSX.Element {
	const {
		records: childrenGroups,
		isLoading,
		refresh,
		removeRecord,
	} = useCollection<models.group.Group>(
		endpoints.Groups.ShowChildren(rootGroup.id),
		{
			queryParams: new URLSearchParams({
				include_sub_group_count: '1',
				include_player_count: '1',
				include_admin_count: '1',
				recursive: '1',
				include_pending_count: '1',
			}),
		}
	);

	const groups = buildGroupTree(rootGroup, childrenGroups);

	return (
		<OrganizationTable
			rootGroup={rootGroup}
			dialog={dialog}
			setDialog={setDialog}
			isLoading={isLoading}
			groups={groups}
			refresh={refresh}
			removeRecord={removeRecord}
		/>
	);
}
