import { ReactNode } from 'react';

export const symbol_full_coverage: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-120q-24 0-42-18t-18-42v-459q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-639v459h584q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T724-120H140Zm169-171q-24 0-42-18t-18-42v-459q0-12.75 8.625-21.375T279-840h611q12.75 0 21.375 8.625T920-810v459q0 24-18 42t-42 18H309Zm0-60h551v-429H309v429Zm116-131h108q12.75 0 21.375-8.625T563-512v-155q0-12.75-8.625-21.375T533-697H425q-12.75 0-21.375 8.625T395-667v155q0 12.75 8.625 21.375T425-482Zm225 0h80q18 0 31-13t13-31q0-18-13-31t-31-13h-80q-18 0-31 13t-13 31q0 18 13 31t31 13Zm0-127h80q18 0 31-13t13-31q0-18-13-31t-31-13h-80q-18 0-31 13t-13 31q0 18 13 31t31 13ZM309-351v-429 429Z"
		/>
	</svg>
);
