import { ReactElement } from 'react';

import Arrow from 'components/annotations/tools/Arrow';
import Circle from 'components/annotations/tools/Circle';
import Spotlight from 'components/annotations/tools/Spotlight';
import Label from 'components/annotations/tools/Label';
import Line from 'components/annotations/tools/Line';
import Polygon from 'components/annotations/tools/Polygon';
import Squiggly from 'components/annotations/tools/Squiggly';

interface ToolConfig {
	[key: string]: {
		move?: boolean; // support move?
		left?: number; // default position
		top?: number;
		resize?: boolean; // support resize?
		width?: number; // default size
		height?: number;
		multipoint?: boolean | number; // support multipoint spine? boolean = unlimited points, number limits points
		closedPath?: boolean; // should the path close automatically?
		preferences?: ToolPreferences; // preference pane config and defaults
		component?: ReactElement; // visual element to render
	};
}

export interface ToolPreference {
	hidden?: boolean;
	input?: string;
	defaultValue?: string | number | boolean;
	min?: number;
	max?: number;
	step?: number;
	condition?: string;
	conditionFalse?: string;
}

export interface ToolPreferences {
	[key: string]: ToolPreference;
}

export const toolConfig: ToolConfig = {
	circle: {
		left: 0.2,
		top: 0.2,
		width: 0.2,
		height: 0.15,
		resize: true,
		move: true,
		component: <Circle />,
		preferences: {
			color: {
				input: 'colorPicker',
				defaultValue: '#ffffff',
			},
			rotating: {
				input: 'toggle',
				defaultValue: true,
			},
			rotatingColor: {
				input: 'colorPicker',
				defaultValue: '#2d2fb1',
				condition: 'rotating',
			},
			strokeWidth: {
				input: 'range',
				defaultValue: 10,
				min: 1,
				max: 30,
				step: 0.1,
				conditionFalse: 'rotating',
			},
		},
	},
	spotlight: {
		left: 0.3,
		top: 0.3,
		width: 0.15,
		height: 0.1,
		resize: true,
		move: true,
		component: <Spotlight />,
		preferences: {
			color: {
				input: 'colorPicker',
				defaultValue: '#ffffff',
			},
		},
	},
	label: {
		left: 0.3,
		top: 0.3,
		move: true,
		resize: false,
		component: <Label />,
		preferences: {
			text: {
				input: 'text',
				defaultValue: 'Label',
			},
			fontSize: {
				input: 'range',
				defaultValue: 30,
				min: 26,
				max: 60,
				step: 1,
			},
		},
	},
	line: {
		move: true,
		resize: false,
		multipoint: true,
		component: <Line />,
		preferences: {
			strokeWidth: {
				input: 'range',
				defaultValue: 6,
				min: 2,
				max: 16,
				step: 0.1,
			},
			color: {
				input: 'colorPicker',
				defaultValue: '#72fd00',
			},
			dashed: {
				input: 'toggle',
				defaultValue: false,
			},
			squiggly: {
				input: 'toggle',
				defaultValue: false,
			},
		},
	},
	connection: {
		move: true,
		resize: false,
		multipoint: true,
		component: <Line />,
		preferences: {
			color: {
				input: 'colorPicker',
				defaultValue: '#ffffff',
			},
			rotating: {
				input: 'toggle',
				defaultValue: true,
			},
			rotatingColor: {
				input: 'colorPicker',
				defaultValue: '#2d2fb1',
				condition: 'rotating',
			},
			strokeWidth: {
				input: 'range',
				defaultValue: 6,
				min: 1,
				max: 12,
				step: 0.1,
				conditionFalse: 'rotating',
			},
			circleCorners: {
				hidden: true,
				defaultValue: true,
			},
			circleSize: {
				input: 'range',
				defaultValue: 5,
				min: 2,
				max: 12,
				step: 0.1,
			},
		},
	},
	arrow: {
		move: true,
		resize: false,
		multipoint: 2,
		component: <Arrow />,
		preferences: {
			strokeWidth: {
				input: 'range',
				defaultValue: 4,
				min: 1,
				max: 12,
				step: 0.1,
			},
			color: {
				input: 'colorPicker',
				defaultValue: '#fd00db',
			},
			raised: {
				input: 'toggle',
				defaultValue: false,
			},
			doubleArrow: {
				input: 'toggle',
				defaultValue: false,
			},
		},
	},
	squiggly: {
		move: true,
		resize: false,
		multipoint: true,
		component: <Squiggly />,
		preferences: {
			strokeWidth: {
				input: 'range',
				defaultValue: 4,
				min: 1,
				max: 12,
				step: 0.1,
			},
			color: {
				input: 'colorPicker',
				defaultValue: '#fd7a00',
			},
		},
	},
	polygon: {
		move: true,
		resize: false,
		multipoint: true,
		closedPath: true,
		component: <Polygon />,
		preferences: {
			color: {
				input: 'colorPicker',
				defaultValue: '#71f6ff',
			},
			opacity: {
				input: 'range',
				defaultValue: 50,
				min: 20,
				max: 255,
				step: 1,
			},
			striped: {
				input: 'toggle',
				defaultValue: false,
			},
		},
	},
};

export const getPreferenceValue = (annotation: any, pref: string) => {
	const config = toolConfig[annotation.tool];
	const value =
		config.preferences[pref] &&
		(annotation[pref] !== undefined
			? annotation[pref]
			: config.preferences[pref].defaultValue);

	return value;
};
