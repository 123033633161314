import { Fragment, useEffect, useState } from 'react';

import * as models from 'pkg/api/models';
import { useCurrentOrganization } from 'pkg/identity';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCollection } from 'pkg/api/use_collection';

import CategorySection from 'routes/user_fields/settings/category_fields/CategorySection';

import * as Draggable from 'components/Draggable';

import { Spinner } from 'components/loaders/spinner';

interface CategoryFieldsProps {
	isEditingSort: boolean;
	group: models.group.Group;
	onDrag: (userFields: models.userFields.UserField[]) => void;
	onReplace: (item: models.userFields.UserField) => void;
	onRemove: (itemId: number) => void;
	onCategoryEdit: (
		category: models.userFieldCategories.UserFieldCategory
	) => void;
	onCategoryDrag: (
		categories: models.userFieldCategories.UserFieldCategory[]
	) => void;

	fieldRefresh: () => void;
}

export default function CategoryFields({
	group,
	onDrag,
	onRemove,
	onReplace,
	onCategoryEdit,
	onCategoryDrag,
	isEditingSort,
	fieldRefresh,
}: CategoryFieldsProps): JSX.Element {
	const organization = useCurrentOrganization();

	const {
		records: categories,
		isLoading,
		refresh,
	} = useCollection<models.userFieldCategories.UserFieldCategory>(
		endpoints.UserFieldCategories.Index(organization.id)
	);

	const [fieldCategories, setFieldCategories] = useState<
		models.userFieldCategories.UserFieldCategory[]
	>([]);

	// i am not sure if the useEffect below is the best way to handle this,
	// but this is to defeat an infinte re-render
	useEffect(() => {
		const areArraysEqual =
			JSON.stringify(categories) === JSON.stringify(fieldCategories);
		if (categories && categories.length > 0 && !areArraysEqual) {
			setFieldCategories(categories);
		}
	}, [categories, fieldCategories]);

	if (isLoading) {
		return <Spinner />;
	}

	const handleCategoryReorder = (
		newCategoryOrder: models.userFieldCategories.UserFieldCategory[]
	) => {
		setFieldCategories(newCategoryOrder);
		onCategoryDrag(newCategoryOrder);
	};

	const sortedCategories = [...fieldCategories].sort(
		(a, b) => a.sortOrder - b.sortOrder
	);

	return (
		<Fragment>
			{sortedCategories &&
				sortedCategories.length > 0 &&
				!isEditingSort &&
				sortedCategories.map((category) => (
					<CategorySection
						category={category}
						group={group}
						refresh={refresh}
						onDrag={onDrag}
						onRemove={onRemove}
						onReplace={onReplace}
						onCategoryEdit={onCategoryEdit}
						isEditingSort={isEditingSort}
						fieldRefresh={fieldRefresh}
					/>
				))}
			{sortedCategories && sortedCategories.length > 0 && isEditingSort && (
				<Draggable.List
					items={sortedCategories}
					onReorder={handleCategoryReorder}>
					{sortedCategories.map((category) => (
						<Draggable.Item key={category.id} item={category}>
							<CategorySection
								category={category}
								group={group}
								refresh={refresh}
								onDrag={onDrag}
								onRemove={onRemove}
								onReplace={onReplace}
								onCategoryEdit={onCategoryEdit}
								isEditingSort={isEditingSort}
								fieldRefresh={fieldRefresh}
							/>
						</Draggable.Item>
					))}
				</Draggable.List>
			)}
		</Fragment>
	);
}
