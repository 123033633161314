import { Fragment, useContext, useState } from 'react';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import { dayIsWithinRange } from 'pkg/date';

import { BookingsContext } from 'routes/scheduling/bookings';
import GridItem from 'routes/scheduling/bookings/GridItem';
import CreateBookingModal from 'routes/scheduling/bookings/modals/CreateBooking';
import ResourceColumn from 'routes/scheduling/bookings/resources/Column';
import {
	getItemResourceIds,
	getTotalHeightOfDay,
	ScheduleGridItem,
} from 'routes/scheduling/utils';

import Column from 'components/layout/column';
import GridRow from 'components/scheduling/grid/row';
import * as dayStyles from 'components/scheduling/grid/day-item/styles.css';
import NewBooking from 'components/scheduling/grid/new-booking';

interface DayProps {
	isInView: boolean;
	day: Date;

	bookings: models.booking.Booking[];
	resource: models.resource.Resource;
}

const Day = ({ day, bookings, resource, isInView }: DayProps) => {
	const [modal, setModal] = useState('');

	const bookingsContext = useContext(BookingsContext);
	const dayBookings = bookings.filter((b) =>
		dayIsWithinRange(b.startsAt, b.endsAt, day)
	);

	const handleClick = () => setModal('createBooking');
	const hideModal = () => setModal('');

	if (!isInView) {
		const totalHeight = getTotalHeightOfDay(
			dayBookings as ScheduleGridItem[],
			bookingsContext.conflicts,
			bookingsContext.isSmallScreen,
			bookingsContext.view
		);

		return <div style={{ height: `${totalHeight}px` }} />;
	}

	return (
		<Fragment>
			<div className={dayStyles.itemWrapper}>
				<Column spacing={styles.spacing._1}>
					{dayBookings.map((b) => (
						<GridItem key={b.id} day={day} booking={b} />
					))}
				</Column>
				{!bookingsContext.calendarView && <NewBooking onClick={handleClick} />}
			</div>
			{modal === 'createBooking' && (
				<CreateBookingModal
					groupId={bookingsContext.groupId}
					hideModal={hideModal}
					date={day}
					resource={resource}
				/>
			)}
		</Fragment>
	);
};

interface ResourceRowProps {
	resource: models.resource.Resource;
}

const ResourceRow = ({ resource }: ResourceRowProps) => {
	const [isInView, setIsInView] = useState(false);
	const bookingContext = useContext(BookingsContext);

	const resourceBookings = bookingContext.bookings.filter((b) =>
		getItemResourceIds(b).includes(resource.id)
	);

	return (
		<GridRow cols={7} setIsInView={setIsInView}>
			{isInView && <ResourceColumn resource={resource} />}
			{bookingContext.weekDates.map((day, i) => (
				<div className={dayStyles.gridItem} key={i}>
					<Day
						day={day}
						bookings={resourceBookings}
						resource={resource}
						isInView={isInView}
					/>
				</div>
			))}
		</GridRow>
	);
};

export default ResourceRow;
