import { ReactNode } from 'react';

export const symbol_language_chinese_dayi: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M291-492q-21 72-59.5 130T130-268q-9 5-19 5t-17-8q-5-6-2-13.5t11-12.5q63-35 100-95t55-132H103q-8 0-13-5.5T85-543q0-8 5.5-13t13.5-5h162q5-29 8.5-58.5T279-679q0-9 5.5-16t14.5-7q9 0 14.5 7t5.5 16q-1 30-4 59.5t-8 58.5h183q8 0 13 5t5 13q0 8-5 13.5t-13 5.5H322q22 71 64 130.5T491-301q8 5 11.5 13t-1.5 15q-7 8-17.5 9t-19.5-4q-63-36-105.5-95T291-492Zm413 172q32 0 63-15t57-43v-121q-29 4-51 8.5T731-480q-50 16-75 39.5T631-385q0 29 20 47t53 18Zm-14 51q-54 0-86.5-31T571-383q0-50 34-83.5T710-520q22-6 50.5-11.5T824-542q0-56-18.5-82.5T739-651q-22 0-44 6.5T644-622q-10 5-20 2.5T609-631q-5-10-2.5-20t12.5-15q31-17 64.5-27t67.5-10q69 0 101 42.5T884-540v237q0 11-7 18t-18 7h-3q-10 0-16.5-6.5T831-300l-3-27q-30 28-66.5 43T690-269Z"
		/>
	</svg>
);
