export function setAppleMeta(url: string): void {
	const appleMetaTag = document.getElementById('ios-app-meta');

	if (url.startsWith('/')) {
		url = url.substring(1, url.length);
	}

	if (appleMetaTag) {
		appleMetaTag.setAttribute(
			'content',
			`app-id=1440982399, app-argument=threesixty://${url}`
		);
	}
}

export function setThemeColor(color: string): void {
	const themeTag = document.getElementById('theme-color-meta');

	if (!themeTag) {
		return;
	}
	themeTag.setAttribute('content', color);
}
