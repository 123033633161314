import {
	useEffect,
	useState,
	useRef,
	useMemo,
	useCallback,
	memo,
	ChangeEvent,
} from 'react';
import TextAreaAutosize from 'react-textarea-autosize';
import { t } from '@transifex/native';
import { Keyboard } from '@capacitor/keyboard';
import { useMediaQuery } from 'react-responsive';

import { toMedium } from 'pkg/config/breakpoints';
import * as styles from 'pkg/config/styles';
import { MAX_FILE_UPLOADS } from 'pkg/config/files';

import * as routes from 'pkg/router/routes';
import rgba from 'pkg/rgba';
import { isIOS, isApp } from 'pkg/platform';
import { resign, unload } from 'pkg/events';
import { replaceState } from 'pkg/router/state';
import { useClipboardAttachments } from 'pkg/hooks/attachments';
import * as numbers from 'pkg/numbers';
import * as actions from 'pkg/actions';
import * as models from 'pkg/api/models';
import {
	useCurrentAccountUserIds,
	useCurrentGroupId,
	useCurrentMembership,
	useCurrentOrganization,
} from 'pkg/identity';
import { cssClasses } from 'pkg/css/utils';

import { useChatContext } from 'routes/chat/view';

import {
	cacheChatAttachments,
	cacheChatMessage,
	clearChatCache,
	getCachedChatAttachments,
	getCachedChatMessage,
	removeCachedChatAttachmentId,
} from 'containers/chat/utils';
import QuotedMessageForm from 'containers/chat/QuotedMessageForm';

import Icon from 'components/icon';

import { useThreadContext } from 'components/chat/thread';
import File from 'components/form/File';
import FormAttachment from 'components/chat/attachment-form-item';
import { useAppState } from 'components/application/state';
import { useUploadAttachmentsState } from 'components/attachment/hooks/useAttachments';

import * as css from './styles.css';

let isTyping: boolean = false;
let typingTimeout: NodeJS.Timeout = null;
const typingIdleTimeout = 2000;

interface ReplyFormProps {
	autoFocus: boolean;
	handleMessageSent: () => void;

	chat: models.chat.Chat;
}

const ReplyForm = memo(
	({ chat, autoFocus = false, handleMessageSent }: ReplyFormProps) => {
		const threadContext = useThreadContext();
		const chatContext = useChatContext();
		const groupId = useCurrentGroupId();
		const currentMembership = useCurrentMembership();
		const currentAccountUserIds = useCurrentAccountUserIds();
		const org = useCurrentOrganization();

		const isSmallScreen = useMediaQuery({ maxWidth: toMedium });
		const { keyboardOpen } = useAppState();

		const inputRef = useRef<HTMLTextAreaElement>();
		const fakeInputRef = useRef<HTMLInputElement>();
		const messageSent = useRef(false);

		const [inputValue, setInputValue] = useState('');
		const {
			upload,
			attachments: newAttachments,
			reset: resetAttachments,
			set: setAttachment,
			deleteAttachment,
		} = useUploadAttachmentsState({
			onComplete: (a) => {
				cacheChatAttachments(chat.id, [a]);
			},
		});

		const quotedMessage: models.chatMessage.ChatMessage =
			threadContext.chatMessageCollection.records.find(
				(chatMessage) => chatMessage.id === chatContext.state.quotedMessageId
			);

		const sendStopTyping = useCallback(() => {
			if (!isTyping) {
				return;
			}

			isTyping = false;
			clearTimeout(typingTimeout);
			models.chat.sendEvent(chat, 'stopped-typing');
		}, [chat.id]);

		const reset = useCallback(() => {
			setInputValue('');
			resetAttachments();
			sendStopTyping();
		}, [sendStopTyping]);

		useEffect(() => {
			reset();
			isTyping = false;

			if (autoFocus) {
				inputRef.current.focus();
			}
		}, [chat.id, reset, autoFocus]);

		useEffect(() => {
			if (isIOS()) {
				Keyboard.setAccessoryBarVisible({ isVisible: false });
			}

			return () => {
				if (isIOS()) {
					Keyboard.setAccessoryBarVisible({ isVisible: true });
				}
			};
		}, []);

		useEffect(() => {
			resign.on(sendStopTyping);
			unload.on(sendStopTyping);

			return () => {
				resign.off(sendStopTyping);
				unload.off(sendStopTyping);
				sendStopTyping();
			};
		}, [sendStopTyping]);

		useEffect(() => {
			if (quotedMessage) {
				inputRef.current.focus();
			}
		}, [quotedMessage]);

		useClipboardAttachments(
			async (files) => {
				await handleNewAttachments(files);
			},
			[chat.id]
		);

		useEffect(() => {
			const retainedMessage = getCachedChatMessage(chat.id);
			const retainedAttachments = getCachedChatAttachments(chat.id);

			if (retainedMessage) {
				setInputValue(retainedMessage);
			}

			if (retainedAttachments.length > 0) {
				retainedAttachments.forEach((a) => {
					setAttachment(a);
				});
			}
		}, [chat.id]);

		const canSendReply = useMemo(() => {
			if (newAttachments.length > 0) {
				const unfinishedUploads = newAttachments.filter(
					(attachment) => attachment.attachment.status !== 'normal'
				);

				if (unfinishedUploads.length > 0) {
					return false;
				}

				return true;
			}

			if (inputValue.trim() === '') {
				return false;
			}

			return true;
		}, [inputValue, newAttachments, chat.id]);

		const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
			if (messageSent.current) {
				messageSent.current = false;

				clearChatCache(chat.id);
			} else {
				setInputValue(e.target.value);
				cacheChatMessage(chat.id, e.target.value);
			}
		};

		const sendStartTyping = () => {
			if (chat.isNew) {
				return;
			}

			if (isTyping) {
				clearTimeout(typingTimeout);
				typingTimeout = setTimeout(sendStopTyping, typingIdleTimeout);
				return;
			}

			isTyping = true;
			typingTimeout = setTimeout(sendStopTyping, typingIdleTimeout);
			models.chat.sendEvent(chat, 'started-typing');
		};

		const createChatMessage = useCallback(
			async (content: string, chatId?: number) => {
				if (!chatId) {
					chatId = chat.id;
				}

				const onCreatedTemporaryMessage = async (
					message: models.chatMessage.ChatMessage
				) => {
					threadContext.chatMessageCollection.addRecord(message);

					if (chatContext.state.quotedMessageId !== 0) {
						chatContext.setState({
							quotedMessageId: 0,
						});
					}
				};

				const onComplete = async (
					chatMessage: models.chatMessage.ChatMessage
				) => {
					if (chatMessage.failed) {
						threadContext.chatMessageCollection.replaceRecord(
							chatMessage,
							'clientMessageId'
						);
					}

					clearChatCache(chat.id);
					handleMessageSent();
				};

				models.chatMessage.createChatMessage({
					chatId,
					chat,
					chatUser: models.chat.findMyChatUser(chat, currentAccountUserIds),
					quotedMessage,
					onCreatedTemporaryMessage,
					content,
					onComplete,
					attachments: newAttachments.map((a) => a.attachment),
				});
			},
			[chat.id, newAttachments, quotedMessage, handleMessageSent]
		);

		const createChat = useCallback(
			async (content: string) => {
				const [req, resp] = await models.chat.create({
					title: chat.title || '',
					groupId,
					type: chat.type,
					users: chat.users
						.filter((cu) => cu.userId !== currentMembership.userId)
						.map((cu) => cu.userId),
				});

				if (req) {
					await createChatMessage(content, resp.id);

					replaceState(routes.Chat.Show(org.id, resp.id));
				}
			},
			[chat.id, createChatMessage, groupId]
		);

		const handleClickSubmit = () => {
			fakeInputRef.current.focus();

			setTimeout(() => {
				inputRef.current.focus();
			}, 20);

			handleSubmit();
		};

		const handleSubmit = useCallback(() => {
			const replyMessage = inputRef.current.value.trim();

			if (replyMessage.length === 0 && newAttachments.length === 0) {
				return;
			}

			if (chat.isNew) {
				createChat(replyMessage);
			} else {
				createChatMessage(replyMessage);
			}

			reset();
		}, [
			chat.isNew,
			createChat,
			createChatMessage,
			newAttachments.length,
			reset,
		]);

		const handleKeyPress = (e: any) => {
			sendStartTyping();

			if (e.key === 'Enter' && !e.shiftKey && canSendReply && !isApp()) {
				messageSent.current = true;
				// Note (liamoberg): is e needed here?
				handleSubmit();
			}
		};

		const numAttachments = newAttachments.length;

		const numAvailableSlots = numbers.clamp(
			MAX_FILE_UPLOADS - numAttachments,
			0,
			MAX_FILE_UPLOADS
		);

		const canUpload = numAttachments < MAX_FILE_UPLOADS;

		const handleNewAttachments = async (files: File[]) => {
			files = Array.from(files);

			const maxUploadableFiles = files.slice(0, numAvailableSlots).length;
			const numFilesOverflow = files.length - numAvailableSlots;

			for (let i = 0; i < maxUploadableFiles; i++) {
				const fileType = files[i].type.startsWith('image/')
					? models.attachment.AttachmentType.Image
					: models.attachment.AttachmentType.File;

				upload({
					type: fileType,
					visibility: models.attachment.AttachmentVisibility.Visible,
					originalFilename: files[i].name,
					title: files[i].name,
					file: files[i],
				});
			}

			if (numFilesOverflow > 0) {
				actions.flashes.show({
					title: t('Attachment limit exeeded'),
					message: t('Number of files not uploaded: {num}', {
						num: numFilesOverflow,
					}),
				});
			}
		};

		const handleDeleteNewFile = (attachment: models.attachment.Attachment) => {
			removeCachedChatAttachmentId(chat.id, attachment.id);
			deleteAttachment(attachment);
		};

		const hasAttachments = newAttachments.length > 0;

		const renderedAttachments = newAttachments.map((attachment) => (
			<FormAttachment
				attachment={attachment.attachment}
				onDelete={handleDeleteNewFile}
			/>
		));

		let replyInputRows = 15;

		if (isSmallScreen) {
			if (keyboardOpen) {
				replyInputRows = hasAttachments ? 6 : 11;
			} else {
				replyInputRows = hasAttachments ? 9 : 14;
			}
		}

		return (
			<div
				className={cssClasses(
					css.wrapper,
					!!quotedMessage && css.withQuotedMessage
				)}>
				{quotedMessage && <QuotedMessageForm item={quotedMessage} />}
				{hasAttachments && (
					<div className={css.attachmentsContainer}>{renderedAttachments}</div>
				)}
				<div className={css.attachmentIconContainer}>
					<File
						className={cssClasses(
							css.attachmentIcon,
							!canUpload && css.disabled
						)}
						multiple
						onChange={handleNewAttachments}
						disabled={!canUpload}>
						<Icon
							name="attachment"
							size={1.5}
							fill={rgba(styles.palette.icon.activeFillColor)}
						/>
					</File>
				</div>
				<div className={css.replyContainer}>
					<TextAreaAutosize
						className={css.replyInput}
						ref={inputRef}
						value={inputValue}
						onKeyDown={handleKeyPress}
						onChange={handleChange}
						placeholder={t('Say something...')}
						data-testid="chat.reply_input"
						data-keyboard-position="bottom"
						maxRows={replyInputRows}
					/>
					<input className={css.fakeInput} ref={fakeInputRef} />
				</div>
				<div
					className={cssClasses(css.replyIcon, !canSendReply && css.disabled)}
					onClick={handleClickSubmit}
					data-testid="chat.reply_button">
					<Icon
						name="plane"
						fill={rgba(
							canSendReply
								? styles.palette.icon.activeFillColor
								: styles.palette.icon.idleFillColor
						)}
					/>
				</div>
			</div>
		);
	}
);

export default ReplyForm;
