import { ReactNode } from 'react';

export const symbol_upload_2: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M190-140h580q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q800-97 791.375-88.5T770-80H190q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T190-140Zm200-100q-12.75 0-21.375-8.625T360-270v-250H260q-19 0-26.5-16.5T237-568l219-282q5-6 11.212-8.5Q473.423-861 480-861t12.788 2.5Q499-856 504-850l218 282q11 15 3.5 31.5T699-520h-99v250q0 12.75-8.625 21.375T570-240H390Zm30-60h120v-297h84L480-782 336-597h84v297Zm60-297Z"
		/>
	</svg>
);
