import { ReactNode } from 'react';

export const symbol_labs: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-80q-83 0-141.5-58.5T280-280v-360q-33 0-56.5-23.5T200-720v-80q0-33 23.5-56.5T280-880h400q33 0 56.5 23.5T760-800v80q0 33-23.5 56.5T680-640v360q0 83-58.5 141.5T480-80ZM260-700h440v-120H260v120Zm219.765 560Q538-140 579-180.833q41-40.834 41-99.167v-20H510q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T510-360h110v-100H510q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T510-520h110v-120H340v360q0 58.333 40.765 99.167 40.764 40.833 99 40.833ZM260-700v-120 120Z"
		/>
	</svg>
);
