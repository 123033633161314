import Arrow from './Arrow';
import Circle from './Circle';
import Line from './Line';
import Polygon from './Polygon';
import Spotlight from './Spotlight';
import Text from './Text';
import Connection from './Connection';

export enum ToolSymbol {
	Arrow = 'arrow',
	Circle = 'circle',
	Line = 'line',
	Polygon = 'polygon',
	Spotlight = 'spotlight',
	Text = 'label',
	Connection = 'connection',
}

export function getSymbol(symbol: ToolSymbol): JSX.Element {
	switch (symbol) {
		case ToolSymbol.Arrow:
			return <Arrow />;
		case ToolSymbol.Circle:
			return <Circle />;
		case ToolSymbol.Line:
			return <Line />;
		case ToolSymbol.Polygon:
			return <Polygon />;
		case ToolSymbol.Spotlight:
			return <Spotlight />;
		case ToolSymbol.Text:
			return <Text />;
		case ToolSymbol.Connection:
			return <Connection />;
		default:
			return <span />;
	}
}
