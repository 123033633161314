// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WZUqCx0ckFZ3wyucmBdF {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	width: 127px;
	height: 127px;
	border: 1px solid var(--palette-blue-500);
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
	border-radius: var(--radius-5);
	margin: 0 5px;
	position: relative;
	cursor: pointer;
}
@media (hover: hover) {
		.WZUqCx0ckFZ3wyucmBdF:hover {
			-webkit-box-shadow: var(--palette-blue-300) 0 0 0 4px !important;
			        box-shadow: var(--palette-blue-300) 0 0 0 4px !important;
		}
	}
.WZUqCx0ckFZ3wyucmBdF svg {
		font-size: 2rem;
	}
.WZUqCx0ckFZ3wyucmBdF.y_CC_b8go0jU7XpKO717 {
		background: var(--palette-blue-500);
		color: var(--palette-white);
		border: 1px solid var(--palette-blue-500);
	}
.WZUqCx0ckFZ3wyucmBdF .GKrviYOXzM41hiTW7T3f {
		font-weight: var(--font-weight-semibold);
		font-size: var(--font-size-sm);
		line-height: var(--font-line-height-sm);
	}
.WZUqCx0ckFZ3wyucmBdF.dboCRU5d8BOwG17MFPB4 .GKrviYOXzM41hiTW7T3f {
			position: absolute;
			bottom: 12px;
			right: 15px;
		}
.WZUqCx0ckFZ3wyucmBdF.h5EE60KKiX7qvr9FozAc .GKrviYOXzM41hiTW7T3f {
			position: absolute;
			bottom: 12px;
			left: 15px;
		}
.WZUqCx0ckFZ3wyucmBdF.h5EE60KKiX7qvr9FozAc svg {
			-webkit-transform: rotateY(180deg);
			        transform: rotateY(180deg);
		}
`, "",{"version":3,"sources":["webpack://./components/rating/create/stacks/big-select-button/styles.css"],"names":[],"mappings":"AAAA;CACC,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CACnB,wBAAuB;KAAvB,qBAAuB;SAAvB,uBAAuB;CACvB,4BAAsB;CAAtB,6BAAsB;KAAtB,0BAAsB;SAAtB,sBAAsB;CACtB,YAAY;CACZ,aAAa;CACb,yCAAyC;CACzC,8BAAsB;SAAtB,sBAAsB;CACtB,8BAA8B;CAC9B,aAAa;CACb,kBAAkB;CAClB,eAAe;AA2ChB;AAzCC;EACC;GACC,gEAAwD;WAAxD,wDAAwD;EACzD;CACD;AAEA;EACC,eAAe;CAChB;AAEA;EACC,mCAAmC;EACnC,2BAA2B;EAC3B,yCAAyC;CAC1C;AAEA;EACC,wCAAwC;EACxC,8BAA8B;EAC9B,uCAAuC;CACxC;AAGC;GACC,kBAAkB;GAClB,YAAY;GACZ,WAAW;EACZ;AAIA;GACC,kBAAkB;GAClB,YAAY;GACZ,UAAU;EACX;AAEA;GACC,kCAA0B;WAA1B,0BAA0B;EAC3B","sourcesContent":[".wrapper {\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\tflex-direction: column;\n\twidth: 127px;\n\theight: 127px;\n\tborder: 1px solid var(--palette-blue-500);\n\tbox-sizing: border-box;\n\tborder-radius: var(--radius-5);\n\tmargin: 0 5px;\n\tposition: relative;\n\tcursor: pointer;\n\n\t@media (hover: hover) {\n\t\t&:hover {\n\t\t\tbox-shadow: var(--palette-blue-300) 0 0 0 4px !important;\n\t\t}\n\t}\n\n\t& svg {\n\t\tfont-size: 2rem;\n\t}\n\n\t&.active {\n\t\tbackground: var(--palette-blue-500);\n\t\tcolor: var(--palette-white);\n\t\tborder: 1px solid var(--palette-blue-500);\n\t}\n\n\t& .text {\n\t\tfont-weight: var(--font-weight-semibold);\n\t\tfont-size: var(--font-size-sm);\n\t\tline-height: var(--font-line-height-sm);\n\t}\n\n\t&.left {\n\t\t& .text {\n\t\t\tposition: absolute;\n\t\t\tbottom: 12px;\n\t\t\tright: 15px;\n\t\t}\n\t}\n\n\t&.right {\n\t\t& .text {\n\t\t\tposition: absolute;\n\t\t\tbottom: 12px;\n\t\t\tleft: 15px;\n\t\t}\n\n\t\t& svg {\n\t\t\ttransform: rotateY(180deg);\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var wrapper = `WZUqCx0ckFZ3wyucmBdF`;
export var active = `y_CC_b8go0jU7XpKO717`;
export var text = `GKrviYOXzM41hiTW7T3f`;
export var left = `dboCRU5d8BOwG17MFPB4`;
export var right = `h5EE60KKiX7qvr9FozAc`;
export default ___CSS_LOADER_EXPORT___;
