import { ReactNode } from 'react';

export const symbol_imagesearch_roller: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M583-40H440q-14.45 0-24.225-9.775Q406-59.55 406-74v-250q0-14.45 9.775-24.225Q425.55-358 440-358h41v-133H140q-24.75 0-42.375-17.625T80-551v-193q0-24.75 17.625-42.375T140-804h83v-42q0-14.45 9.775-24.225Q242.55-880 257-880h509q14.45 0 24.225 9.775Q800-860.45 800-846v152q0 14.45-9.775 24.225Q780.45-660 766-660H257q-14.45 0-24.225-9.775Q223-679.55 223-694v-50h-83v193h341q24.75 0 42.375 17.625T541-491v133h42q14.45 0 24.225 9.775Q617-338.45 617-324v250q0 14.45-9.775 24.225Q597.45-40 583-40Zm-117-60h91v-198h-91v198ZM283-720h457v-100H283v100Zm183 620h91-91ZM283-720v-100 100Z"
		/>
	</svg>
);
