import { ReactNode } from 'react';

export const symbol_assignment_returned: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m450-440-56-56q-9-9-21.1-9-12.1 0-20.9 9-9 9-9 21.5t9 21.5l110 109q9 9 21 9t21-9l109-109q9-9 8.5-21.5T612-496q-9-9-21-9t-21 8l-60 57v-162q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T450-602v162ZM180-120q-24.75 0-42.375-17.625T120-180v-600q0-24.75 17.625-42.375T180-840h205q5-35 32-57.5t63-22.5q36 0 63 22.5t32 57.5h205q24.75 0 42.375 17.625T840-780v600q0 24.75-17.625 42.375T780-120H180Zm0-60h600v-600H180v600Zm300-617q14 0 24.5-10.5T515-832q0-14-10.5-24.5T480-867q-14 0-24.5 10.5T445-832q0 14 10.5 24.5T480-797ZM180-180v-600 600Z"
		/>
	</svg>
);
