// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.y9uVDkvypIZriSK2YS_O {
	position: relative;
}

.y9uVDkvypIZriSK2YS_O[aria-busy='true'] {
		-webkit-user-select: none;
		   -moz-user-select: none;
		            -ms-user-select: none;
		        user-select: none;
	}

.hEDAnuJF0EylEOIGBmyh {
	height: 100%;
}

.hEDAnuJF0EylEOIGBmyh > div:first-of-type {
		height: 100%;
	}

.PCv5Shy6pfHWzJARua_7 {
	width: 100%;
	height: 100%;
	background: hsla(0, 0%, 100%, 0.6);
	position: absolute;
	z-index: 1;
	display: grid;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	justify-items: center;
	place-items: center;
	border-radius: var(--radius-5);
}

.cNjC_4l0MrFqky20a5I0 {
	position: relative;
	padding: var(--spacing-3);
	padding-left: var(--spacing-5);
	padding-right: var(--spacing-5);
	border-radius: 100px;
	background: var(--palette-white);
	-webkit-box-shadow: var(--box-shadow-card-hover);
	        box-shadow: var(--box-shadow-card-hover);
	font-size: var(--font-size-xs);
}

.U6BCHgjusg6bRZBxZCgD {
	border-top: 1px solid var(--palette-gray-300);
}
`, "",{"version":3,"sources":["webpack://./routes/payments/overview/widgets/styles.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;AAKnB;;AAHC;EACC,yBAAiB;KAAjB,sBAAiB;cAAjB,qBAAiB;UAAjB,iBAAiB;CAClB;;AAGD;CACC,YAAY;AAKb;;AAHC;EACC,YAAY;CACb;;AAGD;CACC,WAAW;CACX,YAAY;CACZ,kCAAoC;CACpC,kBAAkB;CAClB,UAAU;CACV,aAAa;CACb,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CAAnB,qBAAmB;CAAnB,mBAAmB;CACnB,8BAA8B;AAC/B;;AAEA;CACC,kBAAkB;CAClB,yBAAyB;CACzB,8BAAgC;CAAhC,+BAAgC;CAChC,oBAAoB;CACpB,gCAAgC;CAChC,gDAAwC;SAAxC,wCAAwC;CACxC,8BAA8B;AAC/B;;AAEA;CACC,6CAA6C;AAC9C","sourcesContent":[".widget {\n\tposition: relative;\n\n\t&[aria-busy='true'] {\n\t\tuser-select: none;\n\t}\n}\n\n.widget__content {\n\theight: 100%;\n\n\t& > div:first-of-type {\n\t\theight: 100%;\n\t}\n}\n\n.loading {\n\twidth: 100%;\n\theight: 100%;\n\tbackground: hsla(0deg 0% 100% / 60%);\n\tposition: absolute;\n\tz-index: 1;\n\tdisplay: grid;\n\tplace-items: center;\n\tborder-radius: var(--radius-5);\n}\n\n.label {\n\tposition: relative;\n\tpadding: var(--spacing-3);\n\tpadding-inline: var(--spacing-5);\n\tborder-radius: 100px;\n\tbackground: var(--palette-white);\n\tbox-shadow: var(--box-shadow-card-hover);\n\tfont-size: var(--font-size-xs);\n}\n\n.table {\n\tborder-top: 1px solid var(--palette-gray-300);\n}\n"],"sourceRoot":""}]);
// Exports
export var widget = `y9uVDkvypIZriSK2YS_O`;
export var widget__content = `hEDAnuJF0EylEOIGBmyh`;
export var loading = `PCv5Shy6pfHWzJARua_7`;
export var label = `cNjC_4l0MrFqky20a5I0`;
export var table = `U6BCHgjusg6bRZBxZCgD`;
export default ___CSS_LOADER_EXPORT___;
