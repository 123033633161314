import { ReactNode } from 'react';

export const symbol_on_device_training: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M450-300v-20h60v20h-60Zm0-40v-37q-17-11-28.5-27T410-440q0-28.875 20.589-49.438Q451.177-510 480.089-510 509-510 529.5-489.438 550-468.875 550-440q0 20-11.5 36T510-377v37h-60Zm189 2-28-28q10-17 14.5-35.672Q630-420.345 630-440q0-19.655-4.5-38.328Q621-497 611-514l28-28q15 23 23 48.761 8 25.761 8 53.239 0 27.478-8 53.239Q654-361 639-338Zm-318 0q-15-23-23-48.761-8-25.761-8-53.239 0-81 59-135.5T490-630v-38l65 58-65 58v-38q-66 0-113 43t-47 107q0 19.655 4.5 38.328Q339-383 349-366l-28 28ZM220-40q-24.75 0-42.375-17.625T160-100v-760q0-24.75 17.625-42.375T220-920h520q24.75 0 42.375 17.625T800-860v760q0 24.75-17.625 42.375T740-40H220Zm0-150h520v-580H220v580Zm0 60v30h520v-30H220Zm0-700h520v-30H220v30Zm0-30v30-30Zm0 760v-30 30Z"
		/>
	</svg>
);
