import { ReactNode } from 'react';

export const symbol_psychiatry: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M479.825-130Q467-130 458.5-138.625T450-160v-279h-20q-64 0-120.5-24.5T209-533q-44-45-66.5-104T120-760v-50q0-12.75 8.625-21.375T150-840h48q62 0 119 24.5T419-746q33 34 54.5 76t30.5 89q7.647-11.9 16.824-22.95Q530-615 540-626q45-45 102-69.5T762-720h48q12.75 0 21.375 8.625T840-690v50q0 64-23.979 123Q792.043-458 748-413q-45 45-101.561 69Q589.878-320 528-320h-18v160q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM451-500q0-61-20-113.5t-55-89q-35-36.5-86-57T180-780q0 63 18.5 115.5T252-575q42 45 90.5 60T451-500Zm59 120q60 0 111-19.5t86-56q35-36.5 54-89T780-660q-60 0-111 20.5T583-583q-43 45-58 94t-15 109Zm0 0Zm-59-120Z"
		/>
	</svg>
);
