import { ReactNode } from 'react';

export const symbol_bookmark_remove: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M640-695q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T640-755h180q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T820-695H640ZM480-240l-196 84q-30 13-57-4.756-27-17.755-27-50.244v-574q0-24 18-42t42-18h260q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T520-785H260v574l220-93 220 93v-304q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T760-515v304q0 32.489-27 50.244Q706-143 676-156l-196-84Zm0-545H260h290-70Z"
		/>
	</svg>
);
