import { Record } from 'immutable';
import { schema } from 'normalizr';

import Account from './account';

interface IAccountRelation {
	id: number;
	accountId: number;
	account: Account;
	targetAccountId: number;
	targetAccount: Account;
	type: string;
	createdAt: number;
	approvedAt: number;
}

const AccountRelationProps: IAccountRelation = {
	id: 0,
	accountId: 0,
	account: null,
	targetAccountId: 0,
	targetAccount: null,
	type: '',
	createdAt: 0,
	approvedAt: null,
};

/* @NOTE If you make changes to this schema, you need to mirror those changes to the schema in data/models/user aswell. */
const AccountSchema = new schema.Entity('accounts', undefined, {
	idAttribute: (value) => value.id,
});

export const AccountRelationSchema = new schema.Entity('accountRelations', {
	targetAccount: AccountSchema,
	account: AccountSchema,
});

class AccountRelation
	extends Record(AccountRelationProps, 'AccountRelationRecord')
	implements IAccountRelation
{
	get approved(): boolean {
		return this.get('approvedAt') !== null;
	}

	static normalizr(): schema.Entity {
		return AccountRelationSchema;
	}
}

export default AccountRelation;
