import { useCurrentUser, useCurrentAccount } from 'pkg/identity';

import { Header } from 'components/navigation/slim-header/styles';
import User from 'components/navigation/slim-header/User';
import ContextSwitcher from 'components/navigation/menus/context_switch';

export default function SlimHeader(): JSX.Element {
	const account = useCurrentAccount();
	const user = useCurrentUser();

	return (
		<Header>
			<ContextSwitcher />
			<User account={account} user={user} />
		</Header>
	);
}
