import { ReactNode } from 'react';

export const symbol_folder_managed: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M721-177q44 0 73-29t29-73q0-44-29-73t-73-29q-44 0-73 29t-29 73q0 44 29 73t73 29Zm-3 95q-11 0-20-8t-10-18.667L686-130q-23-7-42-18.5T611-173l-17 8q-11 5-22 1.5T554-177l-4-6q-7-11-5-22.5t12-19.5l15-12q-5-19.2-5-41.6 0-22.4 5-41.4l-15-12q-10-8.062-12-20.156T550-375l4-6q7-11 18.5-14t22.5 2l16 8q14-12 33-23.5t42-18.5l2-22.256Q689-460 698-468t20-8h7q10.684 0 18.697 7.312Q751.711-461.375 754-450l3 23q23 7 42 18.5t33 23.5l16-8q11-5 22.5-2t18.5 14l4 6q7 10.531 5 22.816-2 12.286-12 20.184l-15 12q5 19.2 5 41.6 0 22.4-5 41.4l15 12q10 7.875 12 19.688 2 11.812-5 22.312l-4 7q-7 10-18 13t-22-2l-17-8q-14 13-33 24.5T757-130l-3.333 22.256Q752-97 743.9-89.5T725-82h-7ZM140-220v-520 220-20 320Zm0 60q-24 0-42-18.5T80-220v-520q0-23 18-41.5t42-18.5h256q12.444 0 23.722 5t19.366 13.088L481-740h339q23 0 41.5 18.5T880-680v158q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T820-522v-158H456l-60-60H140v520h335q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T475-160H140Z"
		/>
	</svg>
);
