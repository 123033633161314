import { ReactNode } from 'react';

export const symbol_pen_size_5: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M242-242.118q-32-32.117-32-78Q210-366 242-398l320-320q32.148-32 78.074-32Q686-750 718-717.882q32 32.117 32 78Q750-594 718-562L398-242q-32.148 32-78.074 32Q274-210 242-242.118Z"
		/>
	</svg>
);
