import { ReactNode } from 'react';
import { useMediaQuery } from 'react-responsive';

import { PageWidths } from 'pkg/config/sizes';
import * as styles from 'pkg/config/styles';

import { DiscountFormState } from 'routes/payments/discounts/create';

import * as LargeScreenContent from 'components/layout/LargeScreenContent';

interface DiscountLargeScreenContentInnerProps {
	formState: DiscountFormState;
	children: ReactNode;
}

export default function DiscountLargeScreenContentInner({
	children,
	formState,
}: DiscountLargeScreenContentInnerProps) {
	const fromLarge = useMediaQuery({ maxWidth: styles.breakpoint.fromLarge });

	let innerProps: Partial<LargeScreenContent.InnerProps> = {
		columns: 2,
		columnSpacing: styles.spacing._9,
		columnSizes: '1fr 0.6fr',
	};

	if (formState.showPreview || fromLarge) {
		innerProps = {};
	}

	return (
		<LargeScreenContent.Inner maxWidth={PageWidths.WIDE} {...innerProps}>
			{children}
		</LargeScreenContent.Inner>
	);
}
