import { Fragment } from 'react';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import { usePaymentProviderContext } from 'pkg/contexts/provider_settings';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as actions from 'pkg/actions';
import * as routes from 'pkg/router/routes';
import { link } from 'pkg/router/utils';
import { useCurrentOrganization } from 'pkg/identity';

import * as ContextMenu from 'design/context_menu';

const _context = 'payments';

interface ContextMenuActionsProps {
	order: models.order.Order;

	showRefundModal: () => void;
	replaceRecord: (order: models.order.Order) => void;
	removeRecord: (id?: number) => void;
}

export default function ContextMenuActions({
	order,
	showRefundModal,
	replaceRecord,
	removeRecord,
}: ContextMenuActionsProps) {
	const org = useCurrentOrganization();
	const providerSettings = usePaymentProviderContext();
	const orderActions = models.order.getActions(
		order,
		providerSettings.settings
	);

	const handleMarkAsPaid = async () => {
		const resp = await actions.order.markAs(
			endpoints.Orders.MarkPaid(),
			[order.id],
			t('Invoice successfully marked as paid.')
		);

		if (resp) {
			replaceRecord({
				...order,
				status: models.order.Statuses.Paid,
			});
		}
	};

	const handleMarkAsUncollectible = async () => {
		const resp = await actions.order.markAs(
			endpoints.Orders.MarkUncollectible(),
			[order.id],
			t('Invoice successfully marked as uncollectible.')
		);

		if (resp) {
			replaceRecord({
				...order,
				status: models.order.Statuses.Uncollectible,
			});
		}
	};

	const handleSendReceipt = () => actions.order.sendReceipt([order.id]);

	const handleSendReminder = () => actions.order.sendReminder([order.id]);

	const handleVoid = async () => {
		const resp = await actions.order.markAs(
			endpoints.Orders.MarkVoid(),
			[order.id],
			t('Invoice successfully voided.')
		);

		if (resp) {
			replaceRecord({
				...order,
				status: models.order.Statuses.Void,
			});
		}
	};

	const handleDelete = async () => {
		const resp = await actions.order.remove([order.id]);

		if (resp) {
			removeRecord(order.id);
		}
	};

	const handleRefund = async () => {
		if (order.flags.includes('auto_refundable')) {
			showRefundModal();
		} else {
			const resp = await actions.order.refund(order.id);

			if (resp) {
				replaceRecord({
					...order,
					status: models.order.Statuses.Refunded,
				});
			}
		}
	};

	const isProcessing = order.jobStatus === 'processing';
	const processingTooltip = t(
		`Currently processing in your payment provider. No further actions can be taken until it's completed.`,
		{ _context }
	);

	const refundButton = order.flags.includes('auto_refundable') ? (
		<ContextMenu.Item
			onClick={handleRefund}
			disabled={isProcessing}
			tooltip={isProcessing ? processingTooltip : ''}>
			<ContextMenu.ItemIcon name="redo" />
			<span>{t('Refund')}</span>
		</ContextMenu.Item>
	) : (
		<ContextMenu.ConfirmItem
			disabled={isProcessing}
			message={t(
				'Are you sure you want to mark this invoice as refunded? This action can not be undone.'
			)}
			confirmLabel={t('Confirm')}
			testid="order.single.refund"
			onConfirm={handleRefund}
			tooltip={isProcessing ? processingTooltip : ''}>
			<ContextMenu.ItemIcon name="redo" />
			<span>{t('Mark as refunded')}</span>
		</ContextMenu.ConfirmItem>
	);

	return (
		<Fragment>
			{orderActions.includes('paid') && (
				<ContextMenu.ConfirmItem
					disabled={isProcessing}
					onConfirm={handleMarkAsPaid}
					confirmLabel={t('Confirm')}
					testid="order.single.mark_as_paid"
					message={t('Are you sure you want to mark this invoice as paid?')}
					tooltip={isProcessing ? processingTooltip : ''}>
					<ContextMenu.ItemIcon name="check_circle" />
					<span>{t('Mark as paid')}</span>
				</ContextMenu.ConfirmItem>
			)}
			{orderActions.includes('uncollectible') && (
				<ContextMenu.ConfirmItem
					disabled={isProcessing}
					message={t(
						'Are you sure you want to mark this invoice as uncollectible?'
					)}
					confirmLabel={t('Confirm')}
					testid="order.single.mark_as_uncollectible"
					onConfirm={handleMarkAsUncollectible}
					tooltip={isProcessing ? processingTooltip : ''}>
					<ContextMenu.ItemIcon name="check_circle" />
					<span>{t('Mark as uncollectible')}</span>
				</ContextMenu.ConfirmItem>
			)}
			{orderActions.includes('reminder') && (
				<ContextMenu.Item
					disabled={isProcessing}
					onClick={handleSendReminder}
					testid="order.single.send_reminder"
					tooltip={isProcessing ? processingTooltip : ''}>
					<ContextMenu.ItemIcon name="draft" />
					<span>{t('Send reminder')}</span>
				</ContextMenu.Item>
			)}
			{orderActions.includes('receipt') && (
				<ContextMenu.Item
					disabled={isProcessing}
					onClick={handleSendReceipt}
					testid="order.single.receipt"
					tooltip={isProcessing ? processingTooltip : ''}>
					<ContextMenu.ItemIcon name="draft" />
					<span>{t('Send receipt')}</span>
				</ContextMenu.Item>
			)}
			{orderActions.includes('refundable') && refundButton}
			<ContextMenu.LinkItem
				href={link(routes.Invoice.New(org.id), {
					groupId: order.groupId,
					orderId: order.id,
				})}>
				<ContextMenu.ItemIcon name="content_copy" />
				{t('Duplicate')}
			</ContextMenu.LinkItem>
			{orderActions.includes('void') && (
				<ContextMenu.ConfirmItem
					disabled={isProcessing}
					message={t('Are you sure you want to void this invoice?')}
					confirmLabel={t('Void')}
					caution
					testid="order.single.void"
					onConfirm={handleVoid}
					tooltip={isProcessing ? processingTooltip : ''}>
					<ContextMenu.ItemIcon name="block" />
					<span>{t('Void')}</span>
				</ContextMenu.ConfirmItem>
			)}
			{orderActions.includes('delete') && (
				<ContextMenu.ConfirmItem
					disabled={isProcessing}
					message={t('Are you sure you want to delete this invoice?')}
					caution
					onConfirm={handleDelete}
					tooltip={isProcessing ? processingTooltip : ''}>
					<ContextMenu.ItemIcon name="block" />
					<span>{t('Delete')}</span>
				</ContextMenu.ConfirmItem>
			)}
		</Fragment>
	);
}
