// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.NwqYMdRjPEOJ2zMD454i {
	margin: 0 auto;
	display: grid;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	justify-items: center;
	place-items: center;
	position: relative;
	max-width: 1800px;
	width: 100%;
}

.NwqYMdRjPEOJ2zMD454i::before {
		width: 100%;
		height: 1px;
		background: var(--palette-gray-300);
		content: '';
		display: block;
		position: absolute;
		left: 0;
		top: 50%;
	}

.NwqYMdRjPEOJ2zMD454i {
	margin: 0 auto;
	display: grid;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	justify-items: center;
	place-items: center;
	position: relative;
	max-width: 1800px;
	width: 100%;
}

.NwqYMdRjPEOJ2zMD454i::before {
		width: 100%;
		height: 1px;
		background: var(--palette-gray-300);
		content: '';
		display: block;
		position: absolute;
		left: 0;
		top: 50%;
	}
`, "",{"version":3,"sources":["webpack://./routes/training/library/group/styles.css"],"names":[],"mappings":";;AAEA;CACC,cAAc;CACd,aAAa;CACb,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CAAnB,qBAAmB;CAAnB,mBAAmB;CACnB,kBAAkB;CAClB,iBAAiB;CACjB,WAAW;AAYZ;;AAVC;EACC,WAAW;EACX,WAAW;EACX,mCAAmC;EACnC,WAAW;EACX,cAAc;EACd,kBAAkB;EAClB,OAAO;EACP,QAAQ;CACT;;AAjBD;CACC,cAAc;CACd,aAAa;CACb,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CAAnB,qBAAmB;CAAnB,mBAAmB;CACnB,kBAAkB;CAClB,iBAAiB;CACjB,WAAW;AAYZ;;AAVC;EACC,WAAW;EACX,WAAW;EACX,mCAAmC;EACnC,WAAW;EACX,cAAc;EACd,kBAAkB;EAClB,OAAO;EACP,QAAQ;CACT","sourcesContent":["@import 'styles.css';\n\n.divider {\n\tmargin: 0 auto;\n\tdisplay: grid;\n\tplace-items: center;\n\tposition: relative;\n\tmax-width: 1800px;\n\twidth: 100%;\n\n\t&::before {\n\t\twidth: 100%;\n\t\theight: 1px;\n\t\tbackground: var(--palette-gray-300);\n\t\tcontent: '';\n\t\tdisplay: block;\n\t\tposition: absolute;\n\t\tleft: 0;\n\t\ttop: 50%;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var divider = `NwqYMdRjPEOJ2zMD454i`;
export default ___CSS_LOADER_EXPORT___;
