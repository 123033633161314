import { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import * as actions from 'pkg/actions';
import * as numbers from 'pkg/numbers';
import { useCurrentMembership, useCurrentUser } from 'pkg/identity';

import PhysicalStrain from 'components/physical-strain';
import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import * as StepModal from 'components/step-modal';

import Column from 'components/layout/column';
import Row from 'components/layout/row';

import * as Card from 'design/card';
import Button from 'design/button';

import * as css from './styles.css';

interface PhysicalStrainBreakdownProps {
	event: models.event.Event;
}

export default function PhysicalStrainBreakdown({
	event,
}: PhysicalStrainBreakdownProps): JSX.Element {
	const currentUser = useCurrentUser();
	const currentMembership = useCurrentMembership();

	const [modalOpen, setModalOpen] = useState<boolean>(false);

	const showModal = () => setModalOpen(true);
	const hideModal = () => setModalOpen(false);

	const [editedActivities, setEditedActivities] = useState<
		models.userActivity.UserActivity[]
	>(event?.activities || []);

	const [isEditing, setEditing] = useState<boolean>(false);
	const [isCollapsed, setCollapsed] = useState<boolean>(true);

	const canEditEvent = models.canEdit(event);

	useEffect(() => {
		setEditedActivities(event.activities);
	}, [event?.activities?.length]);

	const hasActivities = event?.activities?.length > 0;

	const handleChange = (activities: models.userActivity.UserActivity[]) => {
		setEditedActivities(activities);
	};

	const handleToggleCollapse = () => {
		setCollapsed(!isCollapsed);
	};

	const handleEdit = () => {
		setEditing(true);
		setCollapsed(false);
	};

	const handleSave = async () => {
		setEditing(false);

		let changedActivities = editedActivities;

		if (models.membership.isPlayer(currentMembership)) {
			const activity = event.activities.find(
				(ua: models.userActivity.UserActivity) => ua.userId === currentUser.id
			);

			if (!activity) {
				actions.flashes.show(
					{
						title: t('Something went wrong'),
						message: t('Could not update physical strain.'),
					},
					500,
					'nav-assess'
				);
			} else {
				changedActivities = [activity];
			}
		}

		const results = await Promise.all(
			changedActivities.map((activity: models.userActivity.UserActivity) =>
				actions.userActivities.update(activity, activity)
			)
		);

		const firstFail = results.find((result) => {
			const [request] = result;

			return !request.ok;
		})?.[0];

		if (firstFail) {
			actions.flashes.show(
				{
					title: t('Something went wrong'),
					message: t('Could not update physical strain.'),
				},
				firstFail.status,
				'nav-assess'
			);

			if (hasActivities) {
				setEditedActivities([...event.activities]);
			}
		} else {
			actions.flashes.show(
				{
					title: t('Physical strain updated'),
					message: t('Successfully updated physical strain.'),
				},
				200,
				'nav-assess'
			);
		}
	};

	const handleCancel = () => {
		setEditing(false);

		if (modalOpen) {
			hideModal();
		}

		if (event.activities?.length > 0) {
			setEditedActivities(event.activities);
		}
	};

	if (!hasActivities || !event) {
		return null;
	}

	let userActivityActions = (
		<Fragment>
			{!hasActivities ? <span /> : null}
			{canEditEvent ? (
				<Button small secondary onClick={handleEdit}>
					{t('Edit')}
				</Button>
			) : (
				<span />
			)}
			{hasActivities && (
				<Button small secondary onClick={handleToggleCollapse}>
					{isCollapsed ? t('Show all') : t('Collapse')}
				</Button>
			)}
		</Fragment>
	);

	if (isEditing) {
		userActivityActions = (
			<Fragment>
				<Button small onClick={handleCancel}>
					{t('Cancel')}
				</Button>
				<Button small primary onClick={handleSave}>
					{t('Save')}
				</Button>
			</Fragment>
		);
	}

	if (hasActivities && !models.membership.isAdminOrStaff(currentMembership)) {
		const activity = event.activities.find(
			(ua: models.userActivity.UserActivity) => ua.userId === currentUser.id
		);

		if (!isEditing) {
			userActivityActions = (
				<Fragment>
					<span />
					<Button small secondary onClick={handleEdit}>
						{t('Edit')}
					</Button>
				</Fragment>
			);
		}

		return (
			<Card.Base>
				<Card.Body>
					<Column>
						<Row
							align="center"
							columns="1fr auto auto"
							spacing={styles.spacing._3}>
							<Column spacing={styles.spacing._1}>
								<strong>{t('Physical strain')}</strong>
								<span className={css.strainDescription}>
									{t('Show or update physical strain')}
								</span>
							</Column>
							<LargeScreen>{userActivityActions}</LargeScreen>
							<SmallScreen>
								<Button small secondary onClick={showModal}>
									{t('Edit')}
								</Button>
								{modalOpen && (
									<StepModal.Base onClose={hideModal}>
										<StepModal.Step
											title={t('Team Physical Strain')}
											nextLabel={t('Save')}
											onNext={handleSave}
											footerContent={
												<Button onClick={handleCancel}>{t('Cancel')}</Button>
											}>
											<Column spacing={styles.spacing._4}>
												<PhysicalStrainForm
													event={event}
													showAverage={false}
													activities={[activity]}
													editable={true}
													collapsed={false}
													onChange={handleChange}
												/>
											</Column>
										</StepModal.Step>
									</StepModal.Base>
								)}
							</SmallScreen>
						</Row>
						<PhysicalStrainForm
							event={event}
							showAverage={false}
							activities={[activity]}
							editable={isEditing}
							collapsed={false}
							onChange={handleChange}
						/>
					</Column>
				</Card.Body>
			</Card.Base>
		);
	}

	return (
		<Card.Base>
			<Card.Body>
				<Column>
					<Row
						align="center"
						columns="1fr auto auto"
						spacing={styles.spacing._3}>
						<Column spacing={styles.spacing._1}>
							<strong>{t('Physical strain')}</strong>
							<span className={css.strainDescription}>
								{t('Show or update physical strain')}
							</span>
						</Column>

						<LargeScreen>{userActivityActions}</LargeScreen>
						<SmallScreen>
							<span />
							<Button
								small
								secondary
								icon="chevron_right"
								iconPosition="right"
								onClick={showModal}>
								{t('Show all')}
							</Button>
							{modalOpen && (
								<StepModal.Base onClose={hideModal}>
									<StepModal.Step
										title={t('Team Physical Strain')}
										nextLabel={t('Save')}
										onNext={handleSave}
										footerContent={
											<Button onClick={handleCancel}>{t('Cancel')}</Button>
										}>
										<Column spacing={styles.spacing._4}>
											<PhysicalStrainForm
												event={event}
												activities={editedActivities}
												editable={true}
												collapsed={false}
												onChange={handleChange}
											/>
										</Column>
									</StepModal.Step>
								</StepModal.Base>
							)}
						</SmallScreen>
					</Row>
					<PhysicalStrainForm
						event={event}
						activities={editedActivities}
						editable={isEditing}
						collapsed={isCollapsed}
						onChange={handleChange}
					/>
				</Column>
			</Card.Body>
		</Card.Base>
	);
}

interface PhysicalStrainFormProps {
	collapsed?: boolean;
	editable?: boolean;
	showAverage?: boolean;
	average?: number;
	event: models.event.Event;
	activities: models.userActivity.UserActivity[];
	onChange: (activities: models.userActivity.UserActivity[]) => void;
}

function PhysicalStrainForm({
	collapsed,
	editable,
	showAverage = true,
	event,
	activities = [],
	onChange,
}: PhysicalStrainFormProps): JSX.Element {
	activities = activities.filter((n) => n);

	const physicalStrainDefault = event.physicalStrainDefault;

	const average = models.userActivity.averagePhysicalStrain(
		activities,
		physicalStrainDefault
	);

	const [controlledAverage, setAverage] = useState<number>(average);

	const canEditAverage = event?.activities?.every(
		(activity: models.userActivity.UserActivity) =>
			activity.physicalStrain === event.physicalStrainDefault
	);

	useEffect(() => {
		setAverage(average);
	}, [average]);

	const calculateValue = (
		current: number,
		average: number,
		direction: number,
		min: number,
		max: number
	): number => {
		let next: number = numbers.clamp(current + direction, 0, 100);

		if (average >= max && next >= max) {
			next = average;
		}

		if (average <= min && next <= min) {
			next = average;
		}

		return next;
	};

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const id = Number.parseInt(event.currentTarget.id, 10);
		const value = Number.parseInt(event.currentTarget.value, 10);

		const next = activities.map(
			(activity: models.userActivity.UserActivity) => {
				if (activity.id === id) {
					activity.physicalStrain = value;
				}

				return activity;
			}
		);

		setAverage(
			models.userActivity.averagePhysicalStrain(next, physicalStrainDefault)
		);

		onChange(next);
	};

	const handleAverageChange = (event: ChangeEvent<HTMLInputElement>) => {
		const nextAverage = Number.parseInt(event.currentTarget.value, 10);

		let mod: number = 0;

		if (nextAverage > controlledAverage) {
			mod = 1;
		} else if (nextAverage < controlledAverage) {
			mod = -1;
		}

		const values = activities.map(
			(activity: models.userActivity.UserActivity) => activity.physicalStrain
		);

		const min = Math.min(...values);
		const max = Math.max(...values);

		const adjustedValues = values.map((current: number) => {
			return calculateValue(current, nextAverage, mod, min, max);
		});

		const next = activities.map(
			(activity: models.userActivity.UserActivity, index: number) => {
				activity.physicalStrain = adjustedValues[index];

				return activity;
			}
		);

		setAverage(nextAverage);
		onChange(next);
	};

	if (activities.length === 0) {
		return null;
	}

	return (
		<Fragment>
			{showAverage && (
				<PhysicalStrain
					id="average"
					value={controlledAverage}
					label={t('Team avg.')}
					editable={editable && canEditAverage}
					onChange={handleAverageChange}
				/>
			)}
			{!collapsed &&
				activities?.map((activity: models.userActivity.UserActivity) => (
					<PhysicalStrain
						thin
						key={activity.id}
						editable={editable}
						onChange={handleChange}
						id={activity.id.toString()}
						value={activity.physicalStrain}
						label={models.user.fullName(activity.user)}
					/>
				))}
		</Fragment>
	);
}
