import { ReactNode } from 'react';

export const symbol_ad_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-120q-24.75 0-42.375-17.625T120-180v-571l-33-33q-9-9-8.5-21.5T88-827q9-9 21.5-9t21.5 9l706 707q9 9 9 21t-9 21.391Q828-69 815.5-69T794-78l-43-42H180Zm0-60h511L180-692v512Zm630.182-55Q799-235 789.5-242.683 780-250.366 780-265v-418H384q-12.444 0-23.722-5T341-701l-88-88q-7-7-8-15.5t2-16.5q3-8 9.75-13.5T274-840h506q24.75 0 42.375 17.625T840-780v515q0 15-9.318 22.5t-20.5 7.5Z"
		/>
	</svg>
);
