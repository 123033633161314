import { ReactNode } from 'react';

export const symbol_media_link: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m425-272-88 55q-8 5-15.5.696Q314-220.609 314-230v-110q0-9.391 7.5-13.696Q329-358 337-353l88 55q7 4.5 7 12.75T425-272Zm95-313q-45.833 0-77.917-32.118-32.083-32.117-32.083-78Q410-741 442.083-773q32.084-32 77.917-32h20q8 0 14 6t6 14q0 8-6 14t-14 6h-20q-29.167 0-49.583 20.382Q450-724.235 450-695.118 450-666 470.417-645.5 490.833-625 520-625h20q8 0 14 6t6 14q0 8-6 14t-14 6h-20Zm160 0h-20q-8 0-14-6t-6-14q0-8 6-14t14-6h20q29.167 0 49.583-20.382Q750-665.765 750-694.882 750-724 729.583-744.5 709.167-765 680-765h-20q-8 0-14-6t-6-14q0-8 6-14t14-6h20q45.833 0 77.917 32.118 32.083 32.117 32.083 78Q790-649 757.917-617 725.833-585 680-585Zm-30-90H550q-8 0-14-6t-6-14q0-8 6-14t14-6h100q8 0 14 6t6 14q0 8-6 14t-14 6Zm3 265v-80h207v-370H340v370h-60v-370q0-24.75 17.625-42.375T340-920h520q24.75 0 42.375 17.625T920-860v390q0 24.75-17.625 42.375T860-410H653ZM100-40q-24.75 0-42.375-17.625T40-100v-370q0-24.75 17.625-42.375T100-530h520q24.75 0 42.375 17.625T680-470v370q0 24.75-17.625 42.375T620-40H100Zm0-60h520v-370H100v370Zm500-575ZM360-285Z"
		/>
	</svg>
);
