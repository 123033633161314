import { ReactNode } from 'react';

export const symbol_person_search: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M440.5-500Q378-500 334-544.062 290-588.125 290-650q0-63 44.062-106.5Q378.125-800 440-800q63 0 106.5 43.5t43.5 106q0 62.5-43.5 106.5t-106 44Zm-.5-60q38 0 64-26.438 26-26.437 26-63.562 0-38-26-64t-63.5-26q-37.5 0-64 26T350-650.5q0 37.5 26.438 64Q402.875-560 440-560ZM877-41 758-160q-23 17-47.5 23.5t-50.065 6.5q-71.015 0-120.725-49.618Q490-229.235 490-300.118 490-371 539.618-420.5q49.617-49.5 120.5-49.5Q731-470 780.5-420.29T830-299.565q0 25.565-6.5 50.065Q817-225 800-202L919-83q9 9 9 21t-9 21q-9 9-21 9t-21-9ZM660-190q47 0 78.5-31.5T770-300q0-47-31.5-78.5T660-410q-47 0-78.5 31.5T550-300q0 47 31.5 78.5T660-190Zm-540-30v-33.862Q120-291 137.5-317t50.5-43q42-21 106-39.5T421-420q13 0 21.5 9.073T451-389q0 11.738-8.5 20.369T421-359q-76 3-128 21t-81 32q-14 8-23 21.5t-9 30.5v34h248q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T428-160H180q-24.75 0-42.375-17.625T120-220Zm320-430Zm-2 430Z"
		/>
	</svg>
);
