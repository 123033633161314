import { ReactNode } from 'react';

export const symbol_ice_skating: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M110-80q-12.75 0-21.375-8.675Q80-97.351 80-110.175 80-123 88.625-131.5T110-140h130v-110h-60q-24.75 0-42.375-17.625T120-310v-470q0-24.75 17.625-42.375T180-840h240q24.75 0 42.375 17.625T480-780v120q0 30.423 13.5 55.211Q507-580 536-572l115 29q48 12 78.5 52.054T760-400v90q0 24.75-17.625 42.375T700-250h-60v110h90q54.167 0 92.083-37.917Q860-215.833 860-270q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T920-270q0 75-57.5 132.5T730-80H110Zm70-230h520v-90.467Q700-431 681.5-454t-47.08-31L519-515q-38-11-57.5-34.5T431-605h-95q-6 0-10.5-4.5T321-620q0-6 4.5-10.5T336-635h86q-2-13-2-24.5V-685h-84q-6 0-10.5-4.5T321-700q0-6 4.5-10.5T336-715h84v-65H180v470Zm120 170h280v-110H300v110ZM180-310Z"
		/>
	</svg>
);
