import { ReactNode } from 'react';

export const symbol_cadence: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480.175-675q12.825 0 21.325 8.625T510-645v495q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T450-150v-495q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625Zm-166 123q12.825 0 21.325 8.625T344-522v248q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T284-274v-248q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625Zm331 0q12.825 0 21.325 8.625T675-522v248q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T615-274v-248q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625ZM480-780q-50 0-92.5 24.5T319-688q-32 54-85.5 84T117-574h-7q-12.75 0-21.375-8.675Q80-591.351 80-604.175 80-617 88.625-625.5T110-634h7q47 0 87-22.5t63-62.5q34-57 90.576-89 56.577-32 122.5-32Q546-840 602.5-808t89.5 89q24 40 64 62.5t87 22.5h7q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-574h-7q-63 0-116.5-30T641-688q-26-43-68.66-67.5T480-780ZM149.825-368Q137-368 128.5-376.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Zm660 0Q797-368 788.5-376.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Z"
		/>
	</svg>
);
