import { ReactNode } from 'react';

export const symbol_variables: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M120-310v-340q0-12.75 8.625-21.375T150-680h660q12.75 0 21.375 8.625T840-650v340q0 12.75-8.625 21.375T810-280H150q-12.75 0-21.375-8.625T120-310Zm60-30h600v-280H180v280Zm0 0v-280 280Z"
		/>
	</svg>
);
