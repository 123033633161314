import { ReactNode } from 'react';

export const symbol_mark_as_unread: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-279q-23-5-41.5-21.156T80-339v-343q0-12 5-22.5t19-17.5l293-145q13-6 27-6t27 7l280 144q9 7 18 20.5t12 24.5h-74L424-813 140-672v393Zm90 159q-24 0-42-18.5T170-180v-407q0-23 18-41.5t42-18.5h590q23 0 41.5 18.5T880-587v407q0 23-18.5 41.5T820-120H230Zm269-271L230-523v343h590v-343L551-391q-13 6-26 6t-26-6Zm26-54 289-142H236l289 142Zm295-142H230h590Z"
		/>
	</svg>
);
