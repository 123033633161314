import { ReactNode } from 'react';

export const symbol_snowing: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M230-358q-17 0-28.5-11.5T190-398q0-17 11.5-28.5T230-438q17 0 28.5 11.5T270-398q0 17-11.5 28.5T230-358Zm0-332q-17 0-28.5-11.5T190-730q0-17 11.5-28.5T230-770q17 0 28.5 11.5T270-730q0 17-11.5 28.5T230-690Zm125 500q-17 0-28.5-11.5T315-230q0-17 11.5-28.5T355-270q17 0 28.5 11.5T395-230q0 17-11.5 28.5T355-190Zm0-332q-17 0-28.5-11.5T315-562q0-17 11.5-28.5T355-602q17 0 28.5 11.5T395-562q0 17-11.5 28.5T355-522Zm125 164q-17 0-28.5-11.5T440-398q0-17 11.5-28.5T480-438q17 0 28.5 11.5T520-398q0 17-11.5 28.5T480-358Zm0-332q-17 0-28.5-11.5T440-730q0-17 11.5-28.5T480-770q17 0 28.5 11.5T520-730q0 17-11.5 28.5T480-690Zm125 500q-17 0-28.5-11.5T565-230q0-17 11.5-28.5T605-270q17 0 28.5 11.5T645-230q0 17-11.5 28.5T605-190Zm0-332q-17 0-28.5-11.5T565-562q0-17 11.5-28.5T605-602q17 0 28.5 11.5T645-562q0 17-11.5 28.5T605-522Zm125 164q-17 0-28.5-11.5T690-398q0-17 11.5-28.5T730-438q17 0 28.5 11.5T770-398q0 17-11.5 28.5T730-358Zm0-332q-17 0-28.5-11.5T690-730q0-17 11.5-28.5T730-770q17 0 28.5 11.5T770-730q0 17-11.5 28.5T730-690Z"
		/>
	</svg>
);
