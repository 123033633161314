import { ReactNode } from 'react';

export const symbol_sync_problem: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-282q-12 0-21-9t-9-21q0-12 9-21t21-9q12 0 21 9t9 21q0 12-9 21t-21 9Zm1.825-148Q469-430 460.5-438.625T452-460v-189q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T512-649v189q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM188-477q0 63.683 27.5 117.341Q243-306 305-266v-94q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T365-360v170q0 12.75-8.625 21.375T335-160H165q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T165-220h100q-64-48-100.5-108.5T128-477q0-89 46.5-167.5T308-765q11-5 21.5-.5t15 15.5q4.5 11 .5 22.5T330-711q-62 32-102 94.631Q188-553.737 188-477Zm594-6q0-63.683-27.5-117.341Q727-654 665-694v94q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T605-600v-170q0-12.75 8.625-21.375T635-800h170q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T805-740H705q64 48 100.5 108.5T842-483q0 89-46.5 168T662-194q-11 5-21.5 0t-15-16q-4.5-11-.5-22.5t15-16.5q62-32 102-94.631Q782-406.263 782-483Z"
		/>
	</svg>
);
