import { ReactNode } from 'react';

export const symbol_sports_cricket: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M579-390 479-290q-10 10-25.5 10T428-290L90-628q-10-10.286-10-23.143Q80-664 90-674l100-100q10-10 25.5-13t25.5 7l338 338q10 10 10 26t-10 26Zm-125 41 66-66-302-302-66 66 302 302Zm273 243L573-260l44-44 154 154q9 8.556 9 21.778T771-106q-9 9-22.222 9-13.222 0-21.778-9Zm24-514q-55 0-92.5-37.5T621-750q0-55 37.5-92.5T751-880q55 0 92.5 37.5T881-750q0 55-37.5 92.5T751-620Zm-.118-60Q780-680 800.5-700.382q20.5-20.383 20.5-49.5Q821-779 800.618-799.5q-20.383-20.5-49.5-20.5Q722-820 701.5-799.618q-20.5 20.383-20.5 49.5Q681-721 701.382-700.5q20.383 20.5 49.5 20.5Zm.118-70ZM336-533Z"
		/>
	</svg>
);
