import {
	Children,
	memo,
	useRef,
	ReactNode,
	ReactElement,
	useEffect,
} from 'react';
import styled, { css } from 'styled-components';

import * as palette from 'pkg/config/palette';
import { ActionBarHeight, SubNavHeight } from 'pkg/config/sizes';

import { cssClasses } from 'pkg/css/utils';

import { ScrollSpy } from 'components/ScrollSpy';

import { Top, SubNav } from 'components/navigation/header/small_screen/Styles';
import { FAUX_MODAL_PARENT } from 'components/modals/FauxPageModal';
import { useAppState } from 'components/application/state';

export const Outer = styled(ScrollSpy)`
	position: relative;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	display: flex;
	flex-flow: column;
	justify-content: space-between;
	align-content: stretch;
	min-height: 0;
	min-width: 0;
	grid-area: content;

	${(props) =>
		props.noScroll &&
		css`
			overflow: hidden;
			-webkit-overflow-scrolling: auto;
		`};
`;

const Inner = styled.div`
	width: 100%;
	height: 100%;
`;

interface SmallScreenWrapperProps {
	noScroll: boolean;
	noPadding: boolean;
	formPage?: boolean;
	keyboardOpen?: boolean;
}

const SmallScreenWrapper = styled.div<SmallScreenWrapperProps>`
	padding-top: 61px;
	padding-top: calc(env(safe-area-inset-top) + 61px);
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	height: 100%;

	${({ formPage, keyboardOpen }) =>
		formPage &&
		!keyboardOpen &&
		css`
			/* Need the -1px so the background of Page shows */
			padding-bottom: calc(
				(${ActionBarHeight.STANDARD}px + env(safe-area-inset-bottom)) - 1px
			);
		`};

	${({ noScroll }) =>
		noScroll &&
		css`
			overflow: hidden;
			-webkit-overflow-scrolling: auto;
		`};

	${({ noPadding }) =>
		noPadding &&
		css`
			padding: 0;
		`};

	@media print {
		overflow: hidden;
		padding-top: 0;
	}
`;

interface ContentWrapperProps {
	noScroll?: boolean;
	noBorderRadius: boolean;
	noBackground?: boolean;
	tableGrid: boolean;
	hasNavAndNoScroll: boolean;
	backgroundColor?: string;
}

const ContentWrapper = styled.div<ContentWrapperProps>`
	position: relative;
	width: 100%;
	z-index: 2;
	${({ noScroll }) => !noScroll && 'min-'}height: ${({ hasNavAndNoScroll }) =>
		hasNavAndNoScroll ? `calc(100% - ${SubNavHeight.STANDARD}px)` : '100%'};
	overflow: hidden;

	${({ backgroundColor }) =>
		backgroundColor
			? css`
					background-color: ${backgroundColor};
				`
			: css`
					background: ${(props) =>
						props.theme.darkMode
							? palette.gray[800]
							: props.theme.primaryColor
								? props.theme.backgroundColor
								: palette.gray[200]};
				`}

	${({ noBackground }) =>
		noBackground &&
		css`
			background: none;
		`}

	${(props) =>
		!props.noBorderRadius &&
		css`
			border-radius: 20px 20px 0 0;
		`};

	${({ tableGrid }) =>
		tableGrid &&
		css`
			display: grid;
			grid-template-rows: max-content 1fr;
			grid-template-areas: 'action-bar' 'table';
		`};
`;

interface SmallScreenContentProps {
	children: ReactNode;
	noScroll?: boolean;
	noPadding?: boolean;
	noBorderRadius?: boolean;
	noBackground?: boolean;
	formPage?: boolean;
	tableGrid?: boolean;
	backgroundColor?: string;
}

const SmallScreenContent = memo(
	({
		children,
		noScroll,
		noBorderRadius = false,
		noBackground = false,
		noPadding = false,
		tableGrid = false,
		formPage = false,
		backgroundColor,
	}: SmallScreenContentProps): JSX.Element => {
		const outer = useRef(null);
		const wrapperRef = useRef(null);
		const { keyboardOpen, setScrollElement } = useAppState();

		const cArr = Children.toArray(children);

		const nav = cArr.filter(
			(c: ReactElement) => c.type === SubNav || c.type === Top
		);

		const content = cArr.filter(
			(c: ReactElement) => c.type !== SubNav && c.type !== Top
		);

		useEffect(() => {
			if (wrapperRef.current !== null && !noScroll) {
				setScrollElement(wrapperRef);
			}
		}, [wrapperRef.current, noScroll]);

		return (
			<Outer
				ref={outer}
				className={cssClasses('page-scrollable', FAUX_MODAL_PARENT)}
				noScroll={noScroll}>
				<Inner>
					<SmallScreenWrapper
						ref={wrapperRef}
						noScroll={noScroll}
						noPadding={noPadding}
						formPage={formPage}
						keyboardOpen={keyboardOpen}>
						{nav}
						<ContentWrapper
							noScroll={noScroll}
							noBorderRadius={noBorderRadius}
							noBackground={noBackground}
							backgroundColor={backgroundColor}
							tableGrid={tableGrid}
							hasNavAndNoScroll={noScroll && nav.length > 0}>
							{content}
						</ContentWrapper>
					</SmallScreenWrapper>
				</Inner>
			</Outer>
		);
	}
);

export default SmallScreenContent;
