import { ReactNode } from 'react';

export const symbol_autofps_select: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m434-601 43-117h6l44 117h-93Zm46 219q-97 0-163-66t-66-163q0-97 66-163t163-66q97 0 163 66t66 163q0 97-66 163t-163 66Zm-58.211-185H538l22 60q2 6 7.419 9.5 5.42 3.5 11.742 3.5 10.839 0 16.339-8.5Q601-511 597-521l-92-239q-2-5-6.8-8.5T487-772h-13.846Q467-772 462-768.5t-7 8.5l-93 239q-4 9.9 2.071 18.45Q370.143-494 381-494q6 0 11.5-3.467 5.5-3.466 7.5-9.533l21.789-60ZM149.825-80Q137-80 128.5-88.625T120-110v-128q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T180-238v128q0 12.75-8.675 21.375Q162.649-80 149.825-80Zm120 0Q257-80 248.5-88.625T240-110v-128q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T300-238v128q0 12.75-8.675 21.375Q282.649-80 269.825-80Zm120 0Q377-80 368.5-88.625T360-110v-128q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T420-238v128q0 12.75-8.675 21.375Q402.649-80 389.825-80ZM510-80q-12.75 0-21.375-8.625T480-110v-128q0-12.75 8.625-21.375T510-268h300q12.75 0 21.375 8.625T840-238v128q0 12.75-8.625 21.375T810-80H510Z"
		/>
	</svg>
);
