export function AddSequenceTag(ID: number, tagID: number): string {
	return `/v1/video-sequences/${ID}/tags/${tagID}`;
}

export function AddVideoSequenceUser(ID: number): string {
	return `/v1/video-sequences/${ID}/users`;
}

export function AggregatedIndex(): string {
	return `/v1/videos/all`;
}

export function Create(): string {
	return `/v1/videos`;
}

export function CreateSequence(ID: number): string {
	return `/v1/videos/${ID}/sequences`;
}

export function CreateVideoSequenceComment(ID: number): string {
	return `/v1/video-sequences/${ID}/comments`;
}

export function Delete(ID: number): string {
	return `/v1/videos/${ID}`;
}

export function DeleteSequence(ID: number): string {
	return `/v1/video-sequences/${ID}`;
}

export function DeleteVideoSequenceComment(ID: number): string {
	return `/v1/video-sequences/comments/${ID}`;
}

export function DeleteVideoSequenceUser(ID: number): string {
	return `/v1/video-sequence-users/${ID}`;
}

export function GenerateGenericOEmbed(): string {
	return `/v1/video-oembed`;
}

export function GenerateVeoOEmbed(): string {
	return `/v1/video-oembed/veo`;
}

export function Index(): string {
	return `/v1/videos`;
}

export function ListVideosFromWingfield(): string {
	return `/v1/video-sources/wingfield`;
}

export function RemoveSequenceTag(ID: number, tagID: number): string {
	return `/v1/video-sequences/${ID}/tags/${tagID}`;
}

export function Show(ID: number): string {
	return `/v1/videos/${ID}`;
}

export function Update(ID: number): string {
	return `/v1/videos/${ID}`;
}

export function UpdateSequence(ID: number): string {
	return `/v1/video-sequences/${ID}`;
}

export function UpdateVideoSequenceComment(ID: number): string {
	return `/v1/video-sequences/comments/${ID}`;
}

export function UpdateVideoSequenceUser(ID: number): string {
	return `/v1/video-sequence-users/${ID}`;
}
