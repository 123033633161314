import { t } from '@transifex/native';
import { ChangeEvent, useState } from 'react';

import * as actions from 'pkg/actions';
import { useCurrentUser } from 'pkg/identity';
import { cssClasses } from 'pkg/css/utils';
import * as models from 'pkg/api/models';

import Icon from 'components/icon';
import Avatar from 'components/avatar';

import * as Input from 'components/form/inputs';

import * as css from './styles.css';

interface CommentFormProps {
	id: number;
	onNewComment: (comment: models.groupPostComment.GroupPostComment) => void;
}

function CommentForm({ id, onNewComment }: CommentFormProps) {
	const [comment, setComment] = useState('');
	const user = useCurrentUser();

	const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
		setComment(e.target.value);
	};

	const handleSubmit = async () => {
		if (comment.trim() === '') {
			return;
		}

		const newComment = await actions.posts.addComment(user.id, id, comment);
		onNewComment(newComment);
		setComment('');
	};

	return (
		<form className={css.wrapper} onSubmit={handleSubmit}>
			<Avatar user={user} />
			<Input.Area
				id={`post-comment-form-${id}`}
				value={comment}
				placeholder={t(`Comment`)}
				onChange={handleChange}
			/>
			<Icon
				onClick={handleSubmit}
				name="plane"
				className={cssClasses(
					css.sendIcon,
					comment.length === 0 && css.disabled
				)}
			/>
		</form>
	);
}

export default CommentForm;
