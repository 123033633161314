import { t } from '@transifex/native';

import { replaceState } from 'pkg/router/state';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as routes from 'pkg/router/routes';
import { useCollection } from 'pkg/api/use_collection';
import {
	useCurrentAccountUserIds,
	useCurrentGroupId,
	useCurrentMembership,
	useCurrentOrganization,
} from 'pkg/identity';
import { newChatParams } from 'pkg/chat';

import Contacts from 'containers/chat/Contacts';

import StepModal, { Step } from 'components/step-modal';

import { Spinner } from 'components/loaders/spinner';

interface NewChatModalProps {
	onClose: () => void;
}

export default function NewChatModal({ onClose }: NewChatModalProps) {
	const activeGroupId = useCurrentGroupId();
	const activeUserIds = useCurrentAccountUserIds();
	const activeMembership = useCurrentMembership();
	const org = useCurrentOrganization();

	const { records, isLoading } = useCollection<models.membership.Membership>(
		endpoints.Groups.ShowUsers(activeGroupId),
		{
			queryParams: new URLSearchParams({ includeLegalGuardians: '1' }),
		}
	);

	const contacts = models.membership.filterActiveMembership(
		records,
		activeUserIds,
		activeMembership
	);

	const createChat = (users: models.user.User[]) => {
		onClose();

		replaceState(routes.Chat.New(org.id), newChatParams(users));
	};

	let content = <Contacts contacts={contacts} userClick={createChat} />;

	if (isLoading) {
		content = <Spinner />;
	}

	return (
		<StepModal onClose={onClose}>
			<Step
				title={t('New private chat')}
				skipBody
				canGoNext={false}
				hideNext
				prevLabel={t('Cancel')}>
				{content}
			</Step>
		</StepModal>
	);
}
