import { ReactNode } from 'react';

export const symbol_vpn_key_alert: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M280-240q-100 0-170-70T40-480q0-100 70-170t170-70q78 0 131.5 37.5T491-583h233q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T724-523H449.673Q442-566 396.5-613T280-660q-75 0-127.5 52.5T100-480q0 75 52.5 127.5T280-300q71 0 116.5-47t53.724-90H662q12.75 0 21.375 8.625T692-407v107h31.579q13.421 0 21.921 8.675 8.5 8.676 8.5 21.5 0 12.825-8.625 21.325T724-240h-66q-12.75 0-21.375-8.625T628-270v-107H491q-26 62-79.5 99.5T280-240Zm0-172q29 0 48.5-19.5T348-480q0-29-19.5-48.5T280-548q-29 0-48.5 19.5T212-480q0 29 19.5 48.5T280-412Zm0-68Zm599.825 240Q867-240 858.5-248.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Zm0-120Q867-360 858.5-368.625T850-390v-163q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T910-553v163q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Z"
		/>
	</svg>
);
