import { CSSProperties, Fragment, MouseEvent } from 'react';
import styled, { css } from 'styled-components';

import * as styles from 'pkg/config/styles';

import rgba from 'pkg/rgba';

import { useOnboardingContext } from 'routes/public/onboarding';

import Icon, { IconName } from 'components/icon';

import { Spinner } from 'components/loaders/spinner';

interface ButtonStyleProps {
	hasDescription?: boolean;
	primary?: boolean;
	secondary?: boolean;
	transparent?: boolean;
	disabled?: boolean;
	small?: boolean;
	apple?: boolean;
}

const ButtonStyles = styled.button<ButtonStyleProps>`
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: max-content;
	gap: var(--spacing-2);
	padding: 0 var(--spacing-5);
	font-family: var(--font-family-default);
	font-size: var(--font-size-lg);
	line-height: var(--font-line-height-base);
	font-weight: var(--font-weight-semibold);
	width: 100%;
	border: none;
	outline: none;
	cursor: pointer;
	text-decoration: none;
	appearance: none;
	user-select: none;
	border-radius: var(--radius-5);
	background: var(--palette-white);
	min-height: 52px;
	transition: box-shadow 120ms ease-in-out;
	background: var(--palette-white);
	color: var(--palette-black);

	@media ${styles.breakpoint.small} {
		font-size: var(--font-size-base);
	}

	&[disabled] {
		cursor: not-allowed;
		opacity: 0.7;
	}

	@media (hover: hover) {
		&:hover:not([disabled]) {
			box-shadow: ${rgba(styles.palette.white, 0.5)} 0 0 0 4px;
		}
	}

	&:active:not([disabled]) {
		opacity: 0.8;
		transform: scale(0.92);
	}

	${({ primary }) =>
		primary &&
		css`
			background: var(--brandedButtonColor, ${styles.palette.brand});
			color: var(--brandedButtonTextColor, var(--palette-gray-900));
		`}

	${({ secondary }) =>
		secondary &&
		css`
			background: var(--palette-blue-200);
			color: var(--palette-blue-500);
		`}

	${({ transparent }) =>
		transparent &&
		css`
			background: transparent;
			color: var(--palette-white);
			border: 2px solid rgba(255, 255, 255, 0.2);
		`}

	${({ small }) =>
		small &&
		css`
			min-height: 40px;
			font-size: var(--font-size-base);
			border-radius: var(--radius-3);

			@media ${styles.breakpoint.small} {
				font-size: var(--font-size-sm);
			}
		`}

	${({ apple }) =>
		apple &&
		css`
			grid-template-areas: 'icon label';
			grid-template-columns: auto auto;
			gap: var(--spacing-2);
			color: var(--palette-white);
			line-height: 1;

			svg {
				width: 20px;
				height: 20px;
			}
		`}

	${({ apple }) =>
		apple &&
		css`
			background: #000;
		`}

	${({ hasDescription }) =>
		hasDescription
			? css`
					padding: var(--spacing-5);
					grid-template-areas: 'icon label' 'icon description';
					grid-template-rows: auto auto;
					grid-template-columns: auto 1fr;
					gap: var(--spacing-2) var(--spacing-5);
					text-align: left;
					min-height: 86px;

					span:nth-child(1) {
						grid-area: icon;
						align-self: center;
						display: flex;
						align-items: center;
						justify-content: center;
						border-radius: var(--radius-4);
						width: 48px;
						height: 48px;

						svg {
							width: 60px;
							height: 60px;
						}
					}

					span:nth-child(2) {
						grid-area: label;
						justify-self: flex-start;
						align-self: flex-end;
					}

					span:nth-child(3) {
						grid-area: description;
						justify-self: flex-start;
						align-self: flex-start;
						color: var(--palette-gray-600);
						font-size: var(--font-size-sm);
						line-height: var(--font-line-height-sm);
						font-weight: var(--font-weight-normal);
						white-space: normal;

						@media ${styles.breakpoint.small} {
							font-size: var(--font-size-xs);
						}
					}
				`
			: css`
					align-items: center;
					justify-content: center;
					text-align: center;
				`}
`;

interface ButtonComponentProps extends ButtonStyleProps {
	label: string;
	description?: string;
	testid?: string;
	icon?: IconName;
	iconColor?: string;
	onClick?: (event?: MouseEvent | TouchEvent | PointerEvent) => void;
	type?: 'button' | 'submit' | 'reset';
	busy?: boolean;
}

function ButtonComponent({
	label,
	icon,
	description,
	testid,
	primary,
	secondary,
	transparent,
	small,
	iconColor,
	apple = false,
	onClick,
	type,
	disabled,
	busy,
}: ButtonComponentProps): JSX.Element {
	const { brandHsl } = useOnboardingContext();
	const [hue, sat, lum] = brandHsl;

	const brandable = hue && sat !== '0%' && lum !== '0%' && lum !== '100%';

	const brandedButtonColor = brandable
		? `hsl(${hue}, 55%, 45%)`
		: styles.palette.brand;

	let brandedButtonTextColor = brandable
		? `hsl(${hue}, 55%, 100%)`
		: styles.palette.gray[900];

	if (apple) {
		brandedButtonTextColor = styles.palette.white;
	}

	const hasDescription = !!description;

	if (!testid) {
		if (apple) testid = 'button-apple';
		else if (primary) testid = 'button-primary';
		else if (secondary) testid = 'button-secondary';
		else if (transparent) testid = 'button-transparent';
		else testid = 'button';
	}

	let buttonContents = (
		<Fragment>
			{icon && (
				<span>
					<Icon actualSize size={1.4} fill={iconColor} name={icon} />
				</span>
			)}
			{apple && (
				<span>
					<Icon fill={styles.palette.white} name="apple" />
				</span>
			)}
			<span>{label}</span>
			{description && <span>{description}</span>}
		</Fragment>
	);

	if (busy) {
		buttonContents = (
			<Spinner
				size="32px"
				color={brandedButtonTextColor || styles.palette.gray[400]}
			/>
		);
	}

	return (
		<ButtonStyles
			hasDescription={hasDescription}
			primary={primary}
			secondary={secondary}
			transparent={transparent}
			small={small}
			onClick={onClick}
			apple={apple}
			data-testid={testid}
			type={type || 'button'}
			disabled={disabled || busy}
			style={
				{
					'--brandedButtonColor': brandedButtonColor,
					'--brandedButtonTextColor': brandedButtonTextColor,
				} as CSSProperties
			}>
			{buttonContents}
		</ButtonStyles>
	);
}

const Button = styled(ButtonComponent)<
	ButtonComponentProps & { forwardedAs?: string | React.ComponentType<any> }
>``;

export default Button;
