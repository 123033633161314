import { ReactNode } from 'react';

export const symbol_traffic: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-234q24 0 41-17t17-41q0-24-17-41t-41-17q-24 0-41 17t-17 41q0 24 17 41t41 17Zm0-188q24 0 41-17t17-41q0-24-17-41t-41-17q-24 0-41 17t-17 41q0 24 17 41t41 17Zm0-188q24 0 41-17t17-41q0-24-17-41t-41-17q-24 0-41 17t-17 41q0 24 17 41t41 17ZM275-347v-80q-41-8-70-34t-40-59q-2-14 6.5-24t22.5-10h81v-77q-41-8-70-33.5T165-723q-2-14 6.5-24t22.5-10h81v-24q0-26 17-42.5t43-16.5h290q26 0 43 16.5t17 42.5v24h81q14 0 22.5 10t6.5 24q-11 33-40 58.5T685-631v77h81q14 0 22.5 10t6.5 24q-11 33-40 59t-70 34v80h80q14 0 23 10t7 24q-11 33-40 58.5T685-221v41q0 26-17 43t-43 17H335q-26 0-43-17t-17-43v-41q-41-8-70-33.5T165-313q-2-14 7-24t23-10h80Zm60 167h290v-600H335v600Zm0 0v-600 600Z"
		/>
	</svg>
);
