import { ReactNode } from 'react';

export const symbol_nest_clock_farsight_analog: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m510-469 103 103q9 9 9 21.158 0 12.158-8.609 21Q604-315 591.533-315q-12.466 0-21.533-9L459-435q-5-5-7-10.217-2-5.218-2-10.783v-154q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-610v141Zm-30.175-261Q467-730 458.5-738.625T450-760v-60h60v60q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM730-480.175q0-12.825 8.625-21.325T760-510h60v60h-60q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5ZM480.175-230q12.825 0 21.325 8.625T510-200v60h-60v-60q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625ZM230-479.825q0 12.825-8.625 21.325T200-450h-60v-60h60q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5ZM480.266-80q-82.734 0-155.5-31.5t-127.266-86q-54.5-54.5-86-127.341Q80-397.681 80-480.5q0-82.819 31.5-155.659Q143-709 197.5-763t127.341-85.5Q397.681-880 480.5-880q82.819 0 155.659 31.5Q709-817 763-763t85.5 127Q880-563 880-480.266q0 82.734-31.5 155.5T763-197.684q-54 54.316-127 86Q563-80 480.266-80ZM820-480.5Q820-622 721.188-721 622.375-820 480-820q-141 0-240.5 98.812Q140-622.375 140-480q0 141 99.5 240.5t241 99.5Q622-140 721-239.5t99-241Zm-340 .5Z"
		/>
	</svg>
);
