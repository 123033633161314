import { ReactNode } from 'react';

export const symbol_back_to_tab: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-697v147q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Q97-520 88.5-528.625T80-550v-220q0-12.75 8.625-21.375T110-800h220q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T330-740H182l179 179q9.231 9 9.615 21 .385 12-8.668 21-9.052 9-21.5 9Q328-510 319-519L140-697Zm0 537q-24 0-42-18t-18-42v-200q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-420v200h310q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T450-160H140Zm709.825-280Q837-440 828.5-448.625T820-470v-270H460q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T460-800h360q24 0 42 18t18 42v270q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM570-160q-12.75 0-21.375-8.625T540-190v-160q0-12.75 8.625-21.375T570-380h280q12.75 0 21.375 8.625T880-350v160q0 12.75-8.625 21.375T850-160H570Z"
		/>
	</svg>
);
