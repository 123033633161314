import { ReactNode } from 'react';

export const symbol_ev_shadow_add: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M464-80q-76 0-144.5-31.5T197-197q-57-57-87-130T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q158 0 272.5 106.5T879-512q1 13-8 22.5t-22 9.5q-13 0-22-9t-10-22q-3-33-12-65.5T780.886-638L416-273q20.119 24.93 42.56 45.965Q481-206 508-189q17 11 22.5 29.5t-1.5 38.092Q522-101 503.5-90.5T464-80Zm-81-245 365-365q-14-17-30-32t-33-29L351-417q5 25 13 47.5t19 44.5Zm-38-170 288-289q-12-6-24-11t-25-9q-101 31-167.5 115.5T345-495Zm-205 15.078Q140-340 237.5-241T475-140q-86-54-138-142.5T285-480q0-109 52.5-197.5T476-820q-140 2-238 100.5t-98 239.578ZM285-480ZM729.825-90Q717-90 708.5-98.625T700-120v-90h-90q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T610-270h90v-90q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T760-360v90h90q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-210h-90v90q0 12.75-8.675 21.375Q742.649-90 729.825-90Z"
		/>
	</svg>
);
