import { ReactNode } from 'react';

export const symbol_tamper_detection_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M720-435v111l-60-60v-356H304l-60-60h416q24.75 0 42.375 17.625T720-740v215l134-134q7-7 16.5-3.458Q880-658.917 880-649v338q0 9.917-9.5 13.458Q861-294 854-301L720-435ZM109.825-560Q97-560 88.5-568.625T80-590v-150q0-24 18-42t42-18h19l60 60h-79v150q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM470-160q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T470-220h190v-79l60 60v19q0 24-18 42t-42 18H470ZM827-48 60-815q-9-9-9-21t9-21q9-9 21-9t21 9L869-90q9 9 9 21t-9 21q-9 9-21 9t-21-9ZM420-540Zm62-22ZM153.238-110Q142-110 130.5-115T111-128L11-228q-2-2-1.5-3t2.5-3q4.8-5.333 11.4-8.667Q30-246 38.5-246t17.5 3q9 3 15 10l39 39v-246q0-8.667 6.07-14.333Q122.14-460 130.233-460q8.767 0 14.267 5.667Q150-448.667 150-440v120h30v-180q0-8.667 6.07-14.333Q192.14-520 200.233-520q8.767 0 14.267 5.667Q220-508.667 220-500v180h30v-140q0-8.667 6.07-14.333Q262.14-480 270.233-480q8.767 0 14.267 5.667Q290-468.667 290-460v140h30v-100q0-8.667 6.07-14.333Q332.14-440 340.233-440q8.767 0 14.267 5.667Q360-428.667 360-420v250q0 25-17.25 42.5T300-110H153.238Z"
		/>
	</svg>
);
