import { ReactNode } from 'react';

export const symbol_stylus_note: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m499-259 364-363-81-80-363 363 80 80Zm-399-90q0 31 23 50t71 26q12 2 19.5 11t6.5 21q-1 13-10 21t-21 6q-73-11-111-44.5T40-349q0-60 51-98t142-46q44-4 65.5-16t21.5-33q0-26-22.5-41T224-606q-13-2-20.5-11t-6.5-22q1-13 11-20.5t22-5.5q74 12 112 42.5t38 80.5q0 46-37.5 75T238-433q-69 5-103.5 26T100-349Zm418 156L353-358l398-397q14-14 31.5-13.5T814-755l102 101q14 14 14 32t-14 32L518-193Zm-159 33q-17 4-30-9t-9-30l33-159 165 165-159 33Z"
		/>
	</svg>
);
