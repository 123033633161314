import { ReactNode } from 'react';

export const symbol_quiet_time_active: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m791-222-45-45q28-24 50.5-53t41.5-62q-90-8-173-41.5T518-520q-63-63-96-145.5T381-837q-31.579 18.051-60.789 41.026Q291-773 267-746l-45-45q37-42 83.5-72t97.5-47q17-5 31 4.99T447-878q-7 88 23 169.5T563-564q63 63 145 93.5T878-446q17-2 27 13t4 32q-16 50-46 96t-72 83ZM777-66l-90-88q-38 17-78.711 25.5T524-120q-84 0-157.5-32t-128-86.5Q184-293 152-366.5T120-524q0-43.578 8.5-84.289T154-687l-87-87q-9-9.13-9-22.065T67.103-818q9.104-9 22-9Q102-827 111-818l708 710q9 9 9 21t-9 21q-9 9-21 8.5T777-66ZM524-180q30 0 58.814-5.5Q611.627-191 639-202L419.944-421.056 202-639q-11 27-16.5 56.085Q180-553.831 180-524q0 143.333 100.333 243.667Q380.667-180 524-180ZM420-421Zm86-86Z"
		/>
	</svg>
);
