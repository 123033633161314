import { ReactNode } from 'react';

export const symbol_fire_truck: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M280-120q-50 0-85-35t-35-85h-60q-24.75 0-42.375-17.625T40-300v-160q0-24.75 17.625-42.375T100-520h380v-180q0-24.75 17.625-42.375T540-760h110v-50q0-12.75 8.625-21.375T680-840h40q12.75 0 21.375 8.625T750-810v50h47q19.5 0 35.25 11.182T854-719l63 189q2 5 2.5 9.55.5 4.55.5 9.45v211q0 24.75-17.625 42.375T860-240h-60q0 50-35 85t-85 35q-50 0-85-35t-35-85H400q0 50-35 85t-85 35Zm0-60q25.5 0 42.75-17.25T340-240q0-25.5-17.25-42.75T280-300q-25.5 0-42.75 17.25T220-240q0 25.5 17.25 42.75T280-180Zm400 0q25.5 0 42.75-17.25T740-240q0-25.5-17.25-42.75T680-300q-25.5 0-42.75 17.25T620-240q0 25.5 17.25 42.75T680-180ZM100-460v160h76q16-27 43.5-43.5T280-360q33 0 60.5 16.5T384-300h96v-160H100Zm440 160h36q16-27 43.5-43.5T680-360q33 0 60.5 16.5T784-300h76v-160H540v160Zm0-220h317l-60-180H540v180ZM80-600v-120H60q-8 0-14-6t-6-14q0-8 6-14t14-6h360q8 0 14 6t6 14q0 8-6 14t-14 6h-20v120h20q8 0 14 6t6 14q0 8-6 14t-14 6H60q-8 0-14-6t-6-14q0-8 6-14t14-6h20Zm40 0h100v-120H120v120Zm140 0h100v-120H260v120ZM100-460h380-380Zm440 0h320-320Z"
		/>
	</svg>
);
