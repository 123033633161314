import { ReactNode } from 'react';

export const symbol_share_location: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M559-87q-14 2-24-6.5T525-117q0-11 7-19t18-10q32-7 62.5-19.5T671-197q9-6 20-5t18 9q10 11 9 24t-12 21q-34 23-70.5 38T559-87Zm210-167q-8-7-9-18t5-20q18-27 30.5-57t20.5-64q2-11 11-18t20-7q14 0 23 10t6 24q-8 41-24 78t-38 69q-8 11-21 12t-24-9Zm78-268q-11 0-20-7t-11-18q-8-33-20.5-63T765-668q-6-9-4.5-20t9.5-19q11-10 23.5-8.5T814-703q23 35 38 70.5t24 76.5q3 14-5.5 24T847-522ZM406-87q-140-28-231-137.5T84-480q0-146 91-255.5T406-873q14-3 24 6t10 23q0 11-7 19.5T415-814q-118 25-194.5 119T144-480q0 121 76.5 215T415-146q11 2 18 10.5t7 19.5q0 14-10 23t-24 6Zm266-675q-29-18-59-30.5T552-813q-11-2-18-11t-7-20q0-14 9.5-22.5T560-872q38 8 75 22.5t71 37.5q11 8 12.5 21t-8.5 24q-7 8-18 9t-20-4ZM483-284q-8 0-15.5-3t-13.5-8q-70-64-104.5-119T315-517q0-74 48-123t120-49q72 0 120 49t48 123q0 48-34.5 103T512-295q-6 5-13.5 8t-15.5 3Zm0-195q19 0 32-13.5t13-32.5q0-19-13-32t-32-13q-19 0-32 13t-13 32q0 19 13 32.5t32 13.5Z"
		/>
	</svg>
);
