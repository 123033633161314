import { Record } from 'immutable';
import { schema } from 'normalizr';

import Position from 'pkg/models/position';

export const positionScore = new schema.Entity('positionScore');

interface IPositionScore {
	maturityAdjustedValue: number;
	position: Position;
	positionId: number;
	value: number;
}

const positionScoreProps: IPositionScore = {
	maturityAdjustedValue: 0,
	position: new Position(),
	positionId: 0,
	value: 0,
};

export default class PositionScore
	extends Record(positionScoreProps, 'PositionScoreRecord')
	implements IPositionScore
{
	static normalizr() {
		return positionScore;
	}
}
