export function Create(): string {
	return `/v1/group-links`;
}

export function Delete(groupLinkID: number): string {
	return `/v1/group-links/${groupLinkID}`;
}

export function Show(groupLinkID: number): string {
	return `/v1/group-links/${groupLinkID}`;
}

export function Update(groupLinkID: number): string {
	return `/v1/group-links/${groupLinkID}`;
}
