import { ReactNode } from 'react';

export const symbol_arrow_left: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M534-306 376-464q-3-3-5-7.062-2-4.063-2-8.938 0-4.875 2-8.938 2-4.062 5-7.062l158-158q2-2 4.763-3.5t5.921-1.5q6.316 0 10.816 4.125Q560-650.75 560-644v328q0 6.75-4.636 10.875Q550.727-301 544.545-301 543-301 534-306Z"
		/>
	</svg>
);
