import { triggerError } from 'pkg/actions/app';
import { forAccountHeaders } from 'pkg/actions/utils';

import * as sdk from 'pkg/core/sdk';

export const setPositions = (positions: any) => {
	return {
		type: 'SET_POSITIONS',
		positions,
	};
};

export const deprecatedFetchPositions = (forAccount?: any) => {
	return async (dispatch: any) => {
		const headers = forAccountHeaders(forAccount);

		const req = await sdk.get('positions', {}, {}, headers);

		if (!req.ok) {
			return dispatch(triggerError(req));
		}

		const positions = await req.json();

		dispatch(setPositions(positions.records));
	};
};
