import { ReactNode } from 'react';

export const symbol_vpn_lock: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M481-80q-84 0-157-31t-127.5-85Q142-250 111-323T80-480q0-84 31-157t84.5-127q53.5-54 125-85T473-880q35 0 72.5 5.5T610-861q11 3 17.5 13t6.5 21v47q0 34-26.5 60.5T547-693H433v80q0 19-13.5 32.5T387-567h-80v87h253q20 0 33.5 13.5T607-433v127h40q29 0 51.5 16t30.5 42q45-51 68-109.5T820-476v-18q0-10-1-21-1-13 7.5-22.5T848-547q12 0 21 8t10 20q1 9 1 19v19q0 84-31 157.5T764-196q-54 54-126.5 85T481-80Zm-48-55v-91q-33 0-56.5-23.5T353-306v-40L147-552q-4 23-5.5 37.5T140-483q0 134 83 232t210 116Zm291-472q-13 0-21.5-8.5T694-637v-146q0-13 8.5-21.5T724-813h16v-40q0-33 22.5-57t54.5-24q32 0 54.5 24t22.5 57v40h17q13 0 21.5 8.5T941-783v146q0 13-8.5 21.5T911-607H724Zm50-206h86v-40q0-19-12-33t-31-14q-19 0-31 14t-12 33v40Z"
		/>
	</svg>
);
