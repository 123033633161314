import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import { useCurrentAccountUserIds } from 'pkg/identity';
import useConfirm from 'pkg/hooks/useConfirm';

import { useChatContext } from 'routes/chat/view';

import Icon from 'components/icon';
import StepModal, { Step } from 'components/step-modal';

import * as UserList from 'components/user/List';

import * as ContextMenu from 'design/context_menu';

interface UserListItemProps {
	isAdmin?: boolean;
	canEdit?: boolean;

	chat: models.chat.Chat;
	chatUser: models.chatUser.ChatUser;
	myChatUser: models.chatUser.ChatUser;
}

function UserListItem({
	chat,
	chatUser,
	myChatUser,
	isAdmin = false,
	canEdit = false,
}: UserListItemProps) {
	const chatContext = useChatContext();

	const isSelf = myChatUser?.userId === chatUser.user.id;
	const name = isSelf ? t('yourself') : models.user.fullName(chatUser.user);

	const handleRemoveChatUser = async () => {
		await models.chatUser.remove(chat, chatUser);
	};

	const removeUserConfirm = useConfirm({
		message: t('Do you want to remove {name} from this chat?', {
			name,
		}),
		onConfirm: handleRemoveChatUser,
	});

	const handleToggleAdmin = async () => {
		const [req, resp] = await models.chatUser.update(chat, chatUser, {
			isAdmin: !isAdmin,
		});

		if (req) {
			chatContext.chatCollection.replaceInRecord(chat.id, 'users', resp);
		}
	};

	const toggleAdminConfirm = useConfirm({
		message: t('Do you want to make {name} an admin for this chat?', {
			name,
		}),
		onConfirm: handleToggleAdmin,
	});

	return (
		<UserList.Item>
			<UserList.Avatar user={chatUser.user} />
			<UserList.Name>{models.user.fullName(chatUser.user)}</UserList.Name>
			{canEdit && !isSelf && (
				<UserList.Actions>
					<ContextMenu.Menu
						toggleWith={
							<ContextMenu.ButtonTrigger>
								<Icon name="context-menu" />
							</ContextMenu.ButtonTrigger>
						}>
						<ContextMenu.Item onClick={toggleAdminConfirm}>
							<ContextMenu.ItemIcon name="person" />
							{isAdmin ? t('Remove as admin') : t('Make admin')}
						</ContextMenu.Item>

						<ContextMenu.Item onClick={removeUserConfirm} caution>
							<ContextMenu.ItemIcon name="close" />
							{t('Remove from chat')}
						</ContextMenu.Item>
					</ContextMenu.Menu>
				</UserList.Actions>
			)}
		</UserList.Item>
	);
}

interface ChatUsersModalProps {
	chat: models.chat.Chat;

	onClose: () => void;
}

function ChatUsersModal({ chat, onClose }: ChatUsersModalProps) {
	const activeUserIds = useCurrentAccountUserIds();
	const myChatUser = models.chat.findMyChatUser(chat, activeUserIds);

	const getGroupedUsers = () => {
		let members: models.chatUser.ChatUser[] = [];
		let admins: models.chatUser.ChatUser[] = [];

		models.chatUser.filterDeleted(chat.users).map((chatUser) => {
			if (chatUser.isAdmin) {
				admins.push(chatUser);
			} else {
				members.push(chatUser);
			}
		});

		admins = admins.sort((a, b) =>
			models.user.fullName(a.user).localeCompare(models.user.fullName(b.user))
		);

		members = members.sort((a, b) =>
			models.user.fullName(a.user).localeCompare(models.user.fullName(b.user))
		);

		return { admins, members };
	};

	const groupedUsers = getGroupedUsers();
	const renderedUsers = [];
	const admins = groupedUsers.admins;
	const members = groupedUsers.members;

	if (models.chat.isGroup(chat)) {
		if (admins.length > 0) {
			renderedUsers.push(
				<UserList.Label key="admin-label">{t('Admins')}</UserList.Label>
			);

			admins.map((user) =>
				renderedUsers.push(
					<UserListItem
						key={user.id}
						myChatUser={myChatUser}
						chatUser={user}
						chat={chat}
						isAdmin
						canEdit={myChatUser?.isAdmin}
					/>
				)
			);
		}

		if (members.length > 0) {
			renderedUsers.push(
				<UserList.Label key="users-label">{t('Members')}</UserList.Label>
			);

			members.map((user) =>
				renderedUsers.push(
					<UserListItem
						key={user.id}
						myChatUser={myChatUser}
						chatUser={user}
						chat={chat}
						canEdit={myChatUser?.isAdmin}
					/>
				)
			);
		}
	} else {
		renderedUsers.push(
			<UserList.Label key="users-label">{t('Members')}</UserList.Label>
		);

		[...admins, ...members].map((user) =>
			renderedUsers.push(
				<UserListItem
					key={user.id}
					myChatUser={myChatUser}
					chatUser={user}
					chat={chat}
				/>
			)
		);
	}

	return (
		<StepModal onClose={onClose}>
			<Step
				title={t('Chat members')}
				skipBody
				canGoNext={false}
				hideNext
				prevLabel={t('Close')}>
				<UserList.Base>{renderedUsers}</UserList.Base>
			</Step>
		</StepModal>
	);
}

export default ChatUsersModal;
