import { ReactNode } from 'react';

export const symbol_install_desktop: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M330-150v-50H140q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h330q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T470-780H140v520h680v-110q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T880-370v110q0 24-18 42t-42 18H630v50q0 12.75-8.625 21.375T600-120H360q-12.75 0-21.375-8.625T330-150Zm320-378v-282q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T710-810v282l93-93q9-9 21-9t21 9q9 9 9 21t-9 21L701-435q-9 9-21 9t-21-9L515-579q-9-9-9-21t9-21q9-9 21-9t21 9l93 93Z"
		/>
	</svg>
);
