import { ReactNode } from 'react';

export const symbol_text_to_speech: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-80q-24.75 0-42.375-17.625T80-140v-680q0-24.75 17.625-42.375T140-880h270q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T410-820H140v680h480v-80q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T680-220v80q0 24.75-17.625 42.375T620-80H140Zm130-170q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T270-310h220q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T490-250H270Zm0-120q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T270-430h140q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T410-370H270Zm80-150q-12.5 0-21.25-8.75T320-550v-140q0-12.5 8.75-21.25T350-720h110l109-109q5-5 10.217-7 5.218-2 10.783-2 12.75 0 21.375 8.5T620-808v376q0 13-8.625 21.5T590-402q-6 0-11-2t-10-7L460-520H350Zm450-100q0 46-22 85.5T718-470q-13 7-25.5 2.217Q680-472.565 680-485v-278q0-13 12.5-18t25.5 3q40 26 61 68t21 90Zm60 0q0-76-41.5-139.5T708-856q-11-5-16-16.5t0-22.145Q697-906 709-911t23 0q86 40 137 118.503t51 172.5Q920-526 869-447.5 818-369 732-329q-11 5-23 0t-17-16.355Q687-356 692-367.5t16-16.5q69-33 110.5-96.5T860-620Z"
		/>
	</svg>
);
