import { ReactNode } from 'react';

export const symbol_sensor_door: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M230-90q-24.75 0-42.375-17.625T170-150v-660q0-24.75 17.625-42.375T230-870h500q24.75 0 42.375 17.625T790-810v660q0 24.75-17.625 42.375T730-90H230Zm0-60h500v-660H230v660Zm390.118-280Q641-430 655.5-444.618q14.5-14.617 14.5-35.5Q670-501 655.382-515.5q-14.617-14.5-35.5-14.5Q599-530 584.5-515.382q-14.5 14.617-14.5 35.5Q570-459 584.618-444.5q14.617 14.5 35.5 14.5ZM230-810v660-660Z"
		/>
	</svg>
);
