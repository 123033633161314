import { Fragment, memo } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import {
	getUrlByType,
	NotificationTargetType,
} from 'pkg/actions/notifications';

import * as actions from 'pkg/actions';
import Link from 'pkg/router/Link';
import * as models from 'pkg/api/models';
import { useCurrentOrganization } from 'pkg/identity';
import { useAccountIdentity } from 'pkg/identity/account';

import RelativeDateTime from 'components/RelativeDateTime';
import Avatar from 'components/avatar';

import * as ContextMenu from 'design/context_menu';

const ContextNotificationItem = styled(ContextMenu.LinkItem)`
	font-size: var(--font-size-base);
	display: grid;
	grid-template-areas:
		'author content'
		'author time';
	grid-template-columns: 35px 1fr;
	grid-column-gap: var(--spacing-4);
	grid-row-gap: var(--spacing-1);
	align-items: start;

	${Avatar} {
		grid-area: author;
	}
`;

const NotificationItem = styled(Link)`
	font-size: var(--font-size-base);
	display: grid;
	grid-template-areas:
		'author content'
		'author time';
	grid-template-columns: 35px 1fr;
	grid-column-gap: var(--spacing-4);
	grid-row-gap: var(--spacing-1);
	align-items: start;
	padding: var(--spacing-5) var(--spacing-5);
	color: var(--palette-black);
	border-bottom: 1px solid var(--palette-gray-300);

	${Avatar} {
		grid-area: author;
	}
`;

const NotificationTime = styled.span<{ isRead: boolean }>`
	display: block;
	grid-area: time;
	font-size: var(--font-size-sm);
	color: var(--palette-gray-500);
	padding-top: var(--spacing-2);
`;

const NotificationContent = styled.span`
	grid-area: content;

	strong {
		font-weight: var(--font-weight-semibold);
		overflow-wrap: break-word;
		word-break: break-word;
	}

	span {
		font-weight: var(--font-weight-normal);
		overflow-wrap: break-word;
		word-break: break-word;
	}
`;

type NotificationItemProps = {
	notification: models.accountNotification.AccountNotificaton;
	standAlone?: boolean;
};

const Item = memo(({ notification, standAlone }: NotificationItemProps) => {
	const dispatch = useDispatch();
	const { setOrganizationById } = useAccountIdentity();
	const org = useCurrentOrganization();
	const orgId = notification?.target?.organizationId;

	const handleClick = async () => {
		if (!notification.isRead) {
			await actions.notifications.markAsRead(notification.id);
		}

		if (orgId && orgId !== org.id) {
			setOrganizationById(orgId);
		}

		if (notification.type === 'account_relation_request') {
			dispatch(actions.accountRelations.fetchAccountRelations(true));

			dispatch(
				actions.accountRelations.setActiveRequestItem(notification.targetId)
			);
		}
	};

	const notificationTargetUrl = () => {
		const { targetId, targetType, target, triggerId } = notification;

		return getUrlByType(
			targetType as NotificationTargetType,
			targetId,
			target?.organizationId,
			triggerId
		);
	};

	const notificationContent = () => {
		return (
			<Fragment>
				{notification.title && <strong>{notification.title}</strong>}
				{notification.title && notification.message && ' '}
				{notification.message && <span>{notification.message}</span>}
			</Fragment>
		);
	};

	if (standAlone) {
		return (
			<NotificationItem onClick={handleClick} href={notificationTargetUrl()}>
				<Avatar account={notification.author} />
				<NotificationContent>{notificationContent()}</NotificationContent>
				<NotificationTime isRead={notification.isRead}>
					<RelativeDateTime dateTime={notification.createdAt} />
				</NotificationTime>
			</NotificationItem>
		);
	}

	return (
		<ContextNotificationItem
			onClick={handleClick}
			href={notificationTargetUrl()}>
			<Avatar account={notification.author} />
			<NotificationContent>{notificationContent()}</NotificationContent>
			<NotificationTime isRead={notification.isRead}>
				<RelativeDateTime dateTime={notification.createdAt} />
			</NotificationTime>
		</ContextNotificationItem>
	);
});

export default Item;
