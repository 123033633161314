import { ReactNode } from 'react';

export const symbol_align_horizontal_right: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M849.825-80Q837-80 828.5-88.625T820-110v-740q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T880-850v740q0 12.75-8.675 21.375Q862.649-80 849.825-80ZM370-290q-20.833 0-35.417-14.618Q320-319.235 320-340.118 320-361 334.583-375.5 349.167-390 370-390h300q20.833 0 35.417 14.618Q720-360.765 720-339.882 720-319 705.417-304.5 690.833-290 670-290H370ZM130-570q-20.833 0-35.417-14.618Q80-599.235 80-620.118 80-641 94.583-655.5 109.167-670 130-670h540q20.833 0 35.417 14.618Q720-640.765 720-619.882 720-599 705.417-584.5 690.833-570 670-570H130Z"
		/>
	</svg>
);
