import { ReactNode } from 'react';

export const symbol_animated_images: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m537-470 127-85q14-9.111 14-25.056Q678-596 664-605l-127-85q-15-10-31-1.25T490-665v170q0 17.5 16 26.25t31-1.25ZM214-81q-25 3-44.5-11.5T147-132L83-630q-3-25 12-45t40-23l32-3q14-2 23 7.143T199-671q0 12-7.583 19.92Q183.833-643.16 173-641l-31 3 65 498 569-69q12-2 22 6t12 20.39q2 12.39-5.5 21.5T784-150L214-81Zm105-177q-24 0-42-18t-18-42v-502q0-24 18-42t42-18h502q24 0 42 18t18 42v502q0 24-18 42t-42 18H319Zm0-60h502v-502H319v502ZM207-140Zm363-429Z"
		/>
	</svg>
);
