import { t } from '@transifex/native';
import { useRef, useState } from 'react';

import * as flashActions from 'pkg/actions/flashes';

import * as models from 'pkg/api/models';
import { ResourceCategoryCreatePayload } from 'pkg/api/models/resource_category';
import { crash } from 'pkg/errors/errors';

import CategoryForm from 'routes/scheduling/categories/CategoryForm';

import { IconName } from 'components/icon';
import * as StepModal from 'components/step-modal';

import Form, { FormPayload } from 'components/form/Form';

interface CreateCategoryProps {
	groupId: number;

	hideModal: () => void;

	onCreate: (category: models.resourceCategory.ResourceCategory) => void;
}

const CreateCategory = ({
	groupId,
	hideModal,
	onCreate,
}: CreateCategoryProps) => {
	const formRef = useRef(null);
	const [icon, setIcon] = useState<IconName | ''>('');

	const handleSubmit = async (data: FormPayload) => {
		const payload: ResourceCategoryCreatePayload = {
			groupId,
			title: data.title as string,
		};

		if (icon) {
			payload.icon = icon;
		}

		const [req, category] = await models.resourceCategory.create(payload);

		if (req.ok) {
			flashActions.show({
				title: t(`Successfully created category`),
			});
			hideModal();

			onCreate(category);
		} else {
			const err = crash();
			flashActions.show({
				title: err.title,
				message: err.description,
			});
		}
	};

	const handleNext = async () =>
		await formRef.current.dispatchEvent(
			new Event('submit', {
				cancelable: true,
				bubbles: true,
			})
		);

	return (
		<StepModal.Base onClose={hideModal}>
			<StepModal.Step
				title={t(`Create category`)}
				onNext={handleNext}
				nextLabel={t('Save')}>
				<Form formRef={formRef} onSubmit={handleSubmit}>
					<CategoryForm icon={icon} setIcon={setIcon} />
				</Form>
			</StepModal.Step>
		</StepModal.Base>
	);
};

export default CreateCategory;
