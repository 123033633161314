import { t } from '@transifex/native';

import { Features } from 'pkg/models/group';

import * as routes from 'pkg/router/routes';
import * as models from 'pkg/api/models';

export function getTabBarItems(
	organizationId: number,
	activeMembership: models.membership.Membership,
	group: models.group.Group
) {
	const isParent = activeMembership.targetUserId;
	const isAdminOrStaff =
		!isParent && models.membership.isAdminOrStaff(activeMembership);
	const isPlayer =
		(!isParent && models.membership.isPlayer(activeMembership)) ||
		!activeMembership.groupId;

	const hasGroups = !!activeMembership.groupId;

	const menuItems = [];

	menuItems.push({
		href: routes.Organization.Home(organizationId),
		name: t(`Home`),
		icon: 'home',
		slug: 'home',
	});

	// If we're in the club lobby
	if (activeMembership.isOrganizationMembership) {
		menuItems.push({
			href: routes.Calendar.Index(
				organizationId,
				group.id,
				sessionStorage.getItem('currentMode') || 'schedule'
			),
			name: t(`Calendar`),
			icon: 'calendar_today',
			slug: 'calendar',
			show: models.group.hasFeature(group, Features.Calendar),
		});
	}

	if (isAdminOrStaff) {
		menuItems.push(
			{
				href: routes.Calendar.Index(
					organizationId,
					group.id,
					sessionStorage.getItem('currentMode') || 'schedule'
				),
				name: t(`Calendar`),
				icon: 'calendar_today',
				slug: 'calendar',
				show: models.group.hasFeature(group, Features.Calendar),
			},
			{
				href: routes.Chat.Index(organizationId),
				name: t(`Chat`),
				icon: 'forum',
				slug: 'chat',
				show: models.group.hasFeature(group, Features.Chat),
			},
			{
				href: routes.Group.Show(organizationId, group.id),
				name: t(`Team`),
				icon: 'groups',
				slug: 'group',
			}
		);
	}

	if (isPlayer) {
		menuItems.push(
			{
				href: routes.Calendar.Index(
					organizationId,
					group.id,
					sessionStorage.getItem('currentMode') || 'schedule'
				),
				name: t(`Calendar`),
				icon: 'calendar_today',
				slug: 'calendar',
				show: models.group.hasFeature(group, Features.Calendar),
			},
			{
				href: routes.Chat.Index(organizationId),
				name: t(`Chat`),
				icon: 'forum',
				slug: 'chat',
				show: models.group.hasFeature(group, Features.Chat),
			},
			{
				href: routes.Group.Show(organizationId, group.id),
				name: t(`Team`),
				icon: 'groups',
				slug: 'team',
			}
		);
	}

	if (isParent && hasGroups) {
		menuItems.push(
			{
				href: routes.Calendar.Index(
					organizationId,
					group.id,
					sessionStorage.getItem('currentMode') || 'schedule'
				),
				name: t(`Calendar`),
				icon: 'calendar_today',
				slug: 'calendar',
				show: models.group.hasFeature(group, Features.Calendar),
			},
			{
				href: routes.Chat.Index(organizationId),
				name: t(`Chat`),
				icon: 'forum',
				slug: 'chat',
				show: models.group.hasFeature(group, Features.Chat),
			},
			{
				href: routes.Group.Show(organizationId, group.id),
				name: t(`Team`),
				icon: 'groups',
				slug: 'group',
			}
		);
	}

	return menuItems.map(({ href, name, icon, slug, show = true }, n) => {
		const properties = {
			key: `mainItem--${n}`,
			href,
			icon,
			slug,
			show,
		};

		return { name, properties };
	});
}
