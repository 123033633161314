import { ReactNode } from 'react';

export const symbol_stress_management: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-310q62 0 134-22t203-80q2-11 2.5-22t.5-23q0-22-17-34.5t-37-6.5q-14 5-32 11t-44 17q-67 27-114 38.5T480-420q-51 0-99-11.5T264-471q-27-11-41.5-16.5T194-498q-20-7-37 5.5T140-457q0 11 .5 22.5T143-412q108 51 191.5 76.5T480-310Zm0 170q111 0 195-54.5T796-337q-103 46-177 66.5T480-250q-64 0-143.5-22T163-337q36 89 119.5 143T480-140Zm0-420q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm0-60q42 0 71-29t29-71q0-42-29-71t-71-29q-42 0-71 29t-29 71q0 42 29 71t71 29Zm0 540q-177 0-288.5-105T80-457q0-52 41.5-83t90.5-15q16 5 33.5 11.5T287-527q64 26 106 36.5t87 10.5q43 0 85-10t103-36q29-11 46.5-18t34.5-12q49-14 90 17t41 82q0 79-31 147.5T764-190q-54 51-127 80.5T480-80Z"
		/>
	</svg>
);
