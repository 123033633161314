import { ReactNode } from 'react';

export const symbol_center_focus_strong: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-120q-24 0-42-18t-18-42v-142q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T180-322v142h142q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T322-120H180Zm600 0H638q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T638-180h142v-142q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T840-322v142q0 24-18 42t-42 18ZM120-638v-142q0-24 18-42t42-18h142q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T322-780H180v142q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T120-638Zm660 0v-142H638q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T638-840h142q24 0 42 18t18 42v142q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T780-638ZM480-293q-77.605 0-132.302-54.698Q293-402.395 293-480q0-77.605 54.698-132.302Q402.395-667 480-667q77.605 0 132.302 54.698Q667-557.605 667-480q0 77.605-54.698 132.302Q557.605-293 480-293Zm0-60q54 0 90.5-36.5T607-480q0-54-36.5-90.5T480-607q-54 0-90.5 36.5T353-480q0 54 36.5 90.5T480-353Zm0-127Z"
		/>
	</svg>
);
