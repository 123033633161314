import { t } from '@transifex/native';

import { PageWidths } from 'pkg/config/sizes';

import { useCurrentOrganization } from 'pkg/identity';
import * as routes from 'pkg/router/routes';
import { popState } from 'pkg/router/state';

import { useSmallScreen } from 'components/MediaQuery';

import * as ActionBar from 'components/layout/ActionBar';

import Button from 'design/button';

interface ComponentProps {
	isSaving: boolean;
	isSaved?: boolean;
}

export const ContactActionBar = ({
	isSaving,
	isSaved = false,
}: ComponentProps) => {
	const handleCancel = () => popState();

	const isSmallScreen = useSmallScreen();

	const org = useCurrentOrganization();

	if (isSaved && !isSmallScreen) {
		return null;
	}

	return (
		<ActionBar.SaveBar maxWidth={PageWidths.STANDARD}>
			{!isSaved && (
				<Button
					block={isSmallScreen}
					large={isSmallScreen}
					onClick={handleCancel}>
					{t('Cancel')}
				</Button>
			)}
			<Button
				block={isSmallScreen}
				large={isSmallScreen}
				type={!isSaved ? 'submit' : 'button'}
				isLoading={isSaving}
				primary
				testid="contacts.save_contact"
				href={isSaved ? routes.Management.Contacts.Home(org.id) : undefined}>
				{isSaved ? t('Finish') : t('Save')}
			</Button>
		</ActionBar.SaveBar>
	);
};
