import * as sdk from 'pkg/core/sdk';
import * as endpoints from 'pkg/api/endpoints/auto';

export interface OrderSubscriptionRow {
	priceId: number;
	quantity: number;
	taxRateId?: number;
	validTo?: number;
}

export interface OrderPayload {
	userIds: number[];
	daysUntilDue: number;
	description?: string;
	stripeCouponId?: string;
	billingUserId?: number;
	rows: OrderSubscriptionRow[];
	discountId?: number;
	finalizeNow?: boolean;
	finalizeFirstOrderNow?: boolean;
}
export const createBatch = (payload: OrderPayload): Promise<Response> =>
	sdk.post(endpoints.Orders.CreateBatch(), {}, payload);
