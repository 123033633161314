import { Fragment, useState } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import DateTime from 'pkg/datetime';
import uuid from 'pkg/uuid';

import PublishedPeriod from 'routes/scheduling/templates/modals/publish/published-period';

import Icon from 'components/icon';

import Column from 'components/layout/column';

import Button from 'design/button';

import * as css from './styles.css';

export interface StatePublishPeriod extends models.schedule.PublishedPeriod {
	editMode: boolean;
}

interface useDateRangeProps {
	groupId: number;
	showPublishedPeriods?: boolean;
	scheduleId?: number;
	publishedPeriods?: models.schedule.PublishedPeriod[];
	defaultStatePublishedPeriods?: StatePublishPeriod[];
}

const useManagePublishedPeriods = ({
	groupId,
	showPublishedPeriods = false,
	scheduleId,
	publishedPeriods = [],
	defaultStatePublishedPeriods,
}: useDateRangeProps) => {
	const [statePublishedPeriods, setStatePublishedPeriods] = useState<
		StatePublishPeriod[]
	>(() => {
		if (defaultStatePublishedPeriods) {
			return defaultStatePublishedPeriods;
		} else if (!!publishedPeriods.length) {
			return publishedPeriods?.map((p) => {
				return {
					...p,
					editMode: false,
					clientId: uuid(),
				};
			});
		}

		return [];
	});

	const publishPeriodIds = statePublishedPeriods.map((pp) => pp.id);

	const hasValidDates = !!statePublishedPeriods.filter(
		(publishedPeriod) =>
			publishedPeriod.startsAt !== undefined &&
			publishedPeriod.endsAt !== undefined
	).length;

	const addPublishedPeriod = () => {
		const d = new Date();
		d.setHours(12);
		const dt = new DateTime(d).getUnixTimestamp();

		const arr = [...statePublishedPeriods];
		arr.push({
			id: 0,
			groupId,
			scheduleId,
			startsAt: dt,
			endsAt: dt,
			editMode: true,
			clientId: uuid(),
		});
		setStatePublishedPeriods(arr);
	};

	let content;

	if (statePublishedPeriods.length === 0) {
		content = (
			<div className={css['wrapper-empty-state']}>
				<div className={css['content-empty-state']}>
					<Icon
						name="calendar-continue"
						actualSize
						size={3.4}
						fill={styles.palette.gray[500]}
					/>
					<Column justify="center">
						<div>
							<strong>{t('No date range added yet')}</strong>
							<div className={css['small-text']}>
								{t('Add your first date period')}
							</div>
						</div>
						<Button
							primary
							icon="add"
							label={t('Add date range')}
							onClick={addPublishedPeriod}
						/>
					</Column>
				</div>
			</div>
		);
	} else {
		content = (
			<Fragment>
				<Column spacing={styles.spacing._3}>
					{statePublishedPeriods.map((publishedPeriod, index) => (
						<PublishedPeriod
							key={index}
							index={index}
							publishedPeriod={publishedPeriod}
							statePublishedPeriods={statePublishedPeriods}
							setStatePublishedPeriods={setStatePublishedPeriods}
							showPublishedPeriods={showPublishedPeriods}
						/>
					))}
				</Column>
				<Button
					label={t('Add more dates')}
					secondary
					icon="add"
					onClick={addPublishedPeriod}
				/>
			</Fragment>
		);
	}

	return {
		content,
		statePublishedPeriods,
		hasValidDates,
		periodsToRemove: publishedPeriods.filter(
			(pp) => !publishPeriodIds.includes(pp.id)
		),
	};
};

export default useManagePublishedPeriods;
