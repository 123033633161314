import { ReactNode } from 'react';

export const symbol_tsv: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M270.116-357Q281-357 288-364.083q7-7.084 7-17.917v-171h33q10.833 0 17.917-7.116 7.083-7.117 7.083-18Q353-589 345.917-596q-7.084-7-17.917-7H211q-10.833 0-17.917 7.116-7.083 7.117-7.083 18Q186-567 193.083-560q7.084 7 17.917 7h34v171q0 10.833 7.116 17.917 7.117 7.083 18 7.083ZM510-357q14.025 0 23.513-9.487Q543-375.975 543-390v-78q0-13-9.487-22-9.488-9-23.513-9h-77v-54h85q10.833 0 17.917-7.116 7.083-7.117 7.083-18Q543-589 535.917-596q-7.084-7-17.917-7H416q-14.025 0-23.513 9.487Q383-584.025 383-570v78q0 14 9.487 23.5Q401.975-459 416-459h77v52h-85q-10.833 0-17.917 7.116-7.083 7.117-7.083 18Q383-371 390.083-364q7.084 7 17.917 7h102Zm160-63-42-165q-2-8-8.346-13t-14.808-5Q593-603 585.5-593t-4.5 21l54 195q2 9 10 14.5t17.444 5.5h15.112q9.444 0 16.944-5.5Q702-368 704-376l60-197q4-11.351-3.446-20.676Q753.108-603 741.378-603q-8.378 0-14.878 4.5T718-586l-48 166ZM140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm0-60h680v-520H140v520Zm0 0v-520 520Z"
		/>
	</svg>
);
