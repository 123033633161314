import { ReactNode } from 'react';

export const symbol_tire_repair: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M770-650q-17 0-28.5-11.5T730-690q0-8 3.5-15.5T742-718q12-12 55-27l43-15q-8 23-15 43-6 17-13.5 33T798-662q-5 5-12.5 8.5T770-650ZM140-120q-24.75 0-42.375-17.625T80-180v-600q0-24.75 17.625-42.375T140-840h270q24.75 0 42.375 17.625T470-780v361q14-5 27-8t23.076-3q45.802 0 77.863 32.083Q630-365.833 630-320v85q0 20 17.5 37.5t38 17.5q20.5 0 37.5-17.5t17-37.5v-225h-40v-54q-54-23-87-69.782t-33-105.975Q580-769 636-824.5T770.5-880q78.5 0 134 55.597Q960-768.805 960-689.924 960-631 927-584q-33 47-87 70v54h-40v225q0 45-34.5 80t-80 35q-45.5 0-80.5-35t-35-80v-85q0-21.25-14.375-35.625T520-370q-20 0-35 14.375T470-320v140q0 24.75-17.625 42.375T410-120H140Zm629.882-440Q824-560 862-597.882q38-37.883 38-92Q900-744 862.118-782q-37.883-38-92-38Q716-820 678-782.118q-38 37.883-38 92Q640-636 677.882-598q37.883 38 92 38ZM140-180h270v-124l-80 80v-85l80-80v-115l-80 80v-85l80-80v-125l-80 80v-85l61-61H159l61 61v85l-80-80v125l80 80v85l-80-80v115l80 80v85l-80-80v124Zm135-300Z"
		/>
	</svg>
);
