import { ReactNode } from 'react';

export const symbol_vrpano: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m432-411-63-82q-1-2-12-6-3 0-6.5 1.5T345-493l-96 122q-6 8-1.5 17.5T262-346q54-7 108.5-10.5T480-360q51 0 101.5 3.5T683-348q8 1 25.5-2t3.5-20L580-540q-1-2-12-6-3 0-6.5 1.5T556-540L456-411q-1 2-12 6-3 0-6.5-1.5T432-411ZM120-160q-17 0-28.5-11.5T80-200v-560q0-17 11.5-28.5T120-800q8 0 35.5 9.5T229-770q46 11 108.5 20.5T480-740q80 0 142.5-9.5T731-770q46-11 73.5-20.5T840-800q17 0 28.5 11.5T880-760v560q0 17-11.5 28.5T840-160q-8 0-35.5-9.5T731-190q-46-11-108.5-20.5T480-220q-80 0-142.5 9.5T229-190q-46 11-73.5 20.5T120-160Zm20-67q82-29 167.5-41T480-280q87 0 172.5 12T820-227v-505q-82 28-167.5 40T480-680q-87 0-172.5-12T140-732v505Zm340-253Z"
		/>
	</svg>
);
