import { Map, Record } from 'immutable';

import Price from 'pkg/models/price';

import { Prices } from 'pkg/actions/action-types';

interface IPriceReducer {
	entities: Map<number, Price>;
}

class PriceReducer
	extends Record<IPriceReducer>(
		{
			entities: Map<number, Price>(),
		},
		'priceReducer'
	)
	implements IPriceReducer
{
	entities: Map<number, Price>;
}

const initialState = new PriceReducer({
	entities: Map<number, Price>(),
});

interface Action {
	type: string;
	payload?: any;
}

const setItems = (state = initialState, action: Action): PriceReducer => {
	const { price } = action.payload.entities;

	Object.values(price).map((price: Price) => {
		state = state.setIn(['entities', price.id], new Price(price));
	});

	return state;
};

const priceReducer = (state = initialState, action: Action): PriceReducer => {
	switch (action.type) {
		case Prices.SET_ITEMS:
			return setItems(state, action);
		default:
			return state;
	}
};

export default priceReducer;
