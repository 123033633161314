import { ReactNode } from 'react';

export const symbol_draw_collage: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M605-141h60.253v-87.654L775-254q22-5 47-10.5t25-22.682Q847-297 838-304q-9-7-29-13-40-11-62-35.278T727-404q2-22 22-40.5t52-29.5q29-10 44.5-24t15.5-31q0-20-19.5-32t-39.5-4.364q-6 2.182-12.5 3.273Q783-561 776-561q-27 0-47-21.5T709-633q0-27 15-50.5t15-48.5q0-19.125-16-32.062Q707-777 685-777q-28 0-42.5 16T628-710q0 15 2 32t2 33q0 45-19 69.5T561-551q-11.943 0-24.971-3Q523-557 512-557q-8 0-11.5 2.75T497-546q0 11 13.5 21t26.5 21q25 21 38 44.5t13 45.375Q588-386 566.5-365.5T516.282-345Q505-345 493-347.5t-23.638-3.5q-29.623-2-45.492 5.44Q408-338.12 408-323q0 14 14.5 24t40.5 14l142 22.235V-141Zm-498.071 4.978q-11.072-5.021-15.5-16.5Q87-164 92-175l181-390q5-11 16.5-15t22.571 1.022q11.072 5.021 15.5 16.5Q332-551 327-540L146-150q-5 11-16.5 15t-22.571-1.022ZM575-81q-12.75 0-21.375-8.625T545-111v-101l-88-13q-50-7-79-33.5T349-322q0-40 31.5-65.5T460-411q11 0 20.5 1t17.5 2q10 0 15.5-5t5.5-13q-5-11-13.5-22.5T483-472q-19-19-32-37t-13-38q0-30.138 19-49.569T505-616q10 0 22 2t26 3q10 0 14.5-7t4.5-22q1-20-.5-39t-1.5-37.554Q570-775 600-806t84-31q49.16 0 82.08 28T799-739q0 27-12 50t-17 46q0 8 5 13.5t13 5.5q8-1 16-2.5t17-1.5q40.615 0 69.808 28.992Q920-570.017 920-528q0 38-26.5 67T826-420q-14 5-20.5 10.5T799-398q4 8 15.5 16t29.5 14q31 12 47 33t16 47q0 39-35 60.5T789-195l-64 15v69q0 12.75-8.625 21.375T695-81H575ZM152-568q-9 9-21.5 8T111-572q-15-24-23-51.669-8-27.668-8-56.325Q80-763 138.253-821q58.254-58 142.62-58 29.127 0 56.627 8 27.5 8 52.5 24 11 7 12.5 19t-7.603 21.12q-9.104 9.12-22 9.5Q360-797 348-803q-15.474-8-32.237-12t-33.375-4q-59.328 0-100.858 40.542Q140-737.917 140-680q0 17.5 4 33.75T156-615q6 12 5.5 25t-9.5 22Zm483 164Z"
		/>
	</svg>
);
