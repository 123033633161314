import { ReactNode } from 'react';

export const symbol_batch_prediction: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M280-80q-33 0-56.5-23.5T200-160v-400q0-33 23.5-56.5T280-640h400q33 0 56.5 23.5T760-560v400q0 33-23.5 56.5T680-80H280Zm170-118v10q0 13 8.5 21.5T480-158q13 0 21.5-8.5T510-188v-10h-60Zm0-30h60q0-29 15.5-52.5T560-327q19-23 34.5-47t15.5-54q0-54-37.5-92T480-558q-54 0-92 38t-38 92q0 30 15.5 54t34.5 47q19 23 34.5 46.5T450-228ZM240-710q0-23 13.5-36.5T290-760h380q23 0 36.5 13.5T720-710H240Zm40-120q0-23 13.5-36.5T330-880h300q23 0 36.5 13.5T680-830H280Z"
		/>
	</svg>
);
