import { ReactNode } from 'react';

export const symbol_wb_shade: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M792-160 540-412v-51q0-10 9.5-13.5T566-473l287 287q7 7 3.5 16.5T843-160h-51Zm-252-30v-147l177 177H570q-13 0-21.5-8.5T540-190Zm-400 0v-380h-24q-10 0-13.5-9.5T106-596l183-183q9-9 21-9t21 9l183 183q7 7 3.5 16.5T504-570h-24v380q0 13-8.5 21.5T450-160H170q-13 0-21.5-8.5T140-190Zm130-225h80v-155h-80v155Z"
		/>
	</svg>
);
