import { ReactNode } from 'react';

export const symbol_left_panel_close: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M663-380v-200q0-9.917-9.5-13.458Q644-597 637-590l-89 89q-9 9-9 21t9 21l89 89q7 7 16.5 3.458Q663-370.083 663-380ZM180-120q-24.75 0-42.375-17.625T120-180v-600q0-24.75 17.625-42.375T180-840h600q24.75 0 42.375 17.625T840-780v600q0 24.75-17.625 42.375T780-120H180Zm147-60v-600H180v600h147Zm60 0h393v-600H387v600Zm-60 0H180h147Z"
		/>
	</svg>
);
