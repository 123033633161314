import { ReactNode } from 'react';

export const symbol_expand_circle_right: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M524-480 415-371q-9 9-9 21t9 21q9 9 21.5 9t21.5-9l130-130q9-9 9-21t-9-21L457-632q-9-9-21-8.5t-21 9.5q-9 9-9 21t9 21l109 109ZM480-80q-83 0-156-31.5t-127-86Q143-252 111.5-325T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 155.5 31.5t127 85.5q54.5 54 86 127T880-480q0 82-31.5 155t-86 127.5q-54.5 54.5-127 86T480-80Zm0-60q141 0 240.5-99.5T820-480q0-142-99.5-241T480-820q-142 0-241 99t-99 241q0 141 99 240.5T480-140Zm0-340Z"
		/>
	</svg>
);
