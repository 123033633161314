import { t } from '@transifex/native';
import { ChangeEvent, useEffect } from 'react';

import * as styles from 'pkg/config/styles';

import DateTime from 'pkg/datetime';
import { CreateChildPayload } from 'pkg/api/endpoints/accounts';

import SectionTitle from 'components/SectionTitle';
import SectionDescription from 'components/SectionDescription';
import { useStepModalContext } from 'components/step-modal';

import { useJoinModalContext } from 'components/group/join-modal';
import Column from 'components/layout/column';
import * as Input from 'components/form/inputs';
import Row from 'components/layout/row';

export default function Details(): JSX.Element {
	const joinContext = useJoinModalContext();
	const modalContext = useStepModalContext();

	const get = (key: keyof CreateChildPayload): string => {
		let value = joinContext.createPayload[key] || '';

		if (typeof value !== 'string') {
			value = value.toString();
		}

		return value;
	};

	const set = (event: ChangeEvent<HTMLInputElement>) => {
		const name = event.currentTarget.name;
		let value: string | number = event.currentTarget.value;

		if (event.currentTarget.type === 'numeric') {
			value = Number.parseInt(value, 10);
		}

		joinContext.setCreatePayload({ [name]: value });
	};

	const validYear = Number.parseInt(get('year'), 10) >= 1900;
	const dob = [get('year'), get('month'), get('day')].join('-');
	const validDoB = DateTime.isValidISODateString(dob) && validYear;
	const displayDoBError = !validDoB && dob !== '--';

	const canGoNext =
		validDoB && get('firstName').length > 0 && get('lastName').length > 0;

	useEffect(() => {
		modalContext.setCanGoNext(canGoNext);
	}, [canGoNext]);

	return (
		<Column spacing={styles.spacing._7}>
			<Column spacing={styles.spacing._1}>
				<SectionTitle>{t(`Please provide your child's details`)}</SectionTitle>
				<SectionDescription>{t('All fields are required.')}</SectionDescription>
			</Column>

			<Column spacing={styles.spacing._7}>
				<Input.Group label={t(`Child's first name`)}>
					<Input.Field
						testid="registration.child.firstname"
						required
						name="firstName"
						onChange={set}
						value={get('firstName')}
					/>
				</Input.Group>
				<Input.Group label={t(`Child's last name`)}>
					<Input.Field
						testid="registration.child.lastname"
						required
						name="lastName"
						onChange={set}
						value={get('lastName')}
					/>
				</Input.Group>

				<Column spacing={styles.spacing._3}>
					<Input.Group
						label={t(`Child's date of birth`)}
						hasError={displayDoBError}>
						<Row spacing={styles.spacing._4}>
							<Input.Field
								testid="registration.child.day"
								required
								name="day"
								type="number"
								placeholder={t('DD')}
								min={1}
								max={31}
								pattern="^([1-9]|[12][0-9]|3[01])$"
								inputMode="numeric"
								onChange={set}
								value={get('day')}
							/>
							<Input.Field
								testid="registration.child.month"
								required
								name="month"
								type="number"
								placeholder={t('MM')}
								min={1}
								max={12}
								pattern="^([1-9]|1[0-2])$"
								inputMode="numeric"
								onChange={set}
								value={get('month')}
							/>
							<Input.Field
								testid="registration.child.year"
								required
								name="year"
								type="number"
								placeholder={t('YYYY')}
								min={1900}
								inputMode="numeric"
								max={new Date().getFullYear()}
								onChange={set}
								value={get('year')}
							/>
						</Row>
					</Input.Group>
				</Column>
			</Column>
		</Column>
	);
}
