import { ReactNode } from 'react';

export const symbol_brand_awareness: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M770-452h-85q-13 0-21.5-8.5T655-482q0-13 8.5-21.5T685-512h85q13 0 21.5 8.5T800-482q0 13-8.5 21.5T770-452ZM586-271q7-10 19-12.5t22 5.5l72 54q10 7 11.5 18.5T705-184q-7 10-19 12.5t-22-5.5l-72-54q-10-7-11.5-18.5T586-271Zm113-465-73 54q-10 8-21.5 5.5T586-689q-7-10-5.5-21.5T592-729l73-54q10-8 21.5-5.5T705-776q7 10 5.5 21.5T699-736ZM280-360H150q-13 0-21.5-8.5T120-390v-180q0-13 8.5-21.5T150-600h130l149-149q14-14 32.5-6.5T480-728v496q0 20-18.5 27.5T429-211L280-360Zm140-288L307-540H180v120h127l113 109v-337ZM298-480Z"
		/>
	</svg>
);
