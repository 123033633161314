const icon = {
	name: 'cone',
	objects: [
		{
			type: 'path',
			attributes: {
				d: 'M10.5917 3.56424C10.2815 3.56424 10.0138 3.78188 9.9505 4.0856L6.90542 18.6935H6C5.63824 18.6935 5.34497 18.9867 5.34497 19.3485C5.34497 19.7103 5.63824 20.0035 6 20.0035H17.2188C17.5805 20.0035 17.8738 19.7103 17.8738 19.3485C17.8738 18.9867 17.5805 18.6935 17.2188 18.6935H16.3131L13.2681 4.0856C13.2048 3.78188 12.9371 3.56424 12.6268 3.56424H10.5917ZM10.1975 9.32037L11.1243 4.8743H12.0942L13.0211 9.32037H10.1975ZM9.92442 10.6304L9.72782 11.5736H13.4907L13.2941 10.6304H9.92442ZM8.24365 18.6935L9.45473 12.8836H13.7638L14.9749 18.6935H8.24365Z',
				fillRule: 'evenodd',
				clipRule: 'evenodd',
			},
		},
	],
};

export default icon;
