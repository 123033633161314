import { ReactNode } from 'react';

export const symbol_2k: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m570-450 77 82q2 2 17 8 16 0 22.5-15t-4.5-26l-74-79 74-80q11-11 4.5-25.5T664-600q-4.789 0-8.895 2-4.105 2-8.105 6l-77 82v-65q0-10.833-7.116-17.917-7.117-7.083-18-7.083Q534-600 527-592.917q-7 7.084-7 17.917v190q0 10.833 7.116 17.917 7.117 7.083 18 7.083Q556-360 563-367.083q7-7.084 7-17.917v-65Zm-250 40v-53h86q14.45 0 24.225-9.487Q440-481.975 440-496v-70q0-14.45-9.775-24.225Q420.45-600 406-600H295q-10.833 0-17.917 7.116-7.083 7.117-7.083 18Q270-564 277.083-557q7.084 7 17.917 7h95v54h-87q-14.025 0-23.513 9.487Q270-477.025 270-463v78q0 10.833 7.083 17.917Q284.167-360 295-360h120q10.833 0 17.917-7.116 7.083-7.117 7.083-18Q440-396 432.917-403q-7.084-7-17.917-7h-95ZM180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-60h600v-600H180v600Zm0-600v600-600Z"
		/>
	</svg>
);
