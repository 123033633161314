import VideoSequenceUser, {
	VideoSequenceUserPayload,
} from 'pkg/models/video_sequence_user';

import * as services from 'pkg/actions/services';
import { normalizedDispatch } from 'pkg/actions/utils';
import * as actionTypes from 'pkg/actions/action-types';

import store from 'pkg/store/createStore';
import { VideoSequenceRecordingId } from 'pkg/video';

export const create = async (
	payload: VideoSequenceUserPayload
): Promise<VideoSequenceUser> => {
	const [request, response] = await services.video.createSequenceUser(
		payload.videoSequenceId,
		payload
	);

	if (request.ok) {
		normalizedDispatch(response, VideoSequenceUser.normalizr())(store.dispatch);
	}

	return response;
};

export const update = async (
	sequenceUserId: number,
	payload: VideoSequenceUserPayload
): Promise<VideoSequenceUser> => {
	const [request, response] = await services.video.updateSequenceUser(
		sequenceUserId,
		payload
	);

	if (request.ok) {
		normalizedDispatch(response, VideoSequenceUser.normalizr())(store.dispatch);
	}

	return response;
};

export const destroy = async (sequenceUserId: number): Promise<void> => {
	const request = await services.video.destroySequenceUser(sequenceUserId);

	if (request.ok) {
		store.dispatch({
			type: actionTypes.VideoSequenceUsers.DELETE_ITEM,
			sequenceUserId,
		});
	}
};

/**
 * Flushes temporary user tags from Redux state
 */
export const flushTemporary = async (): Promise<void> => {
	const tempIds = store
		.getState()
		.videoSequenceUsers.get('entities')
		.filter(
			(vsu: VideoSequenceUser) =>
				vsu.videoSequenceId === VideoSequenceRecordingId
		)
		.map((vsu: VideoSequenceUser) => vsu.id)
		.toList();

	if (tempIds.size > 0) {
		tempIds.forEach((sequenceUserId: number) => {
			store.dispatch({
				type: actionTypes.VideoSequenceUsers.DELETE_ITEM,
				sequenceUserId,
			});
		});
	}
};
