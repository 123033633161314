import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import { EventFlags, RSVPInterval } from 'pkg/api/models/event';
import { Linkable } from 'pkg/api/models/linkable';

export enum EventSeriesType {
	Camp = 'camp',
	Cup = 'cup',
	Match = 'match',
	Meeting = 'meeting',
	Practice = 'practice',
	Uncategorized = 'uncategorized',
}

export interface EventSeriesAttachments {
	attachment: models.attachment.Attachment;
	attachmentId: number;
	eventSeriesId: number;
	id: number;
}

export interface EventSeries extends Linkable {
	description: string;
	duration: number;
	events: number[];
	groupId: number;
	id: number;
	location: string;
	rRuleString: string;
	status: string;
	timezone: string;
	title: string;
	type: EventSeriesType;
	userId: number;
	isPrivate: boolean;
	isPublic: boolean;
	hideParticipants: boolean;
	meetBeforeMinutes: number;
	// rsvpIntervalCount is the number of RSVPInterval to require rsvp before. This is in relation to startsAt.
	rsvpIntervalCount: number;
	// rsvpInterval is the time unit of rsvpIntervalCount
	rsvpInterval: RSVPInterval;
	flags: EventFlags[];

	group: models.group.Group;
	seriesUsers: models.eventSeriesUser.EventSeriesUser[];
	eventSeriesAttachments: EventSeriesAttachments[];
}

const _context = 'eventSeriesTypes';

export function getTypeTranslation(eventSeries: EventSeries) {
	switch (eventSeries.type) {
		case EventSeriesType.Camp: {
			return t('Camp');
		}
		case EventSeriesType.Cup: {
			return t('Cup');
		}
		case EventSeriesType.Match: {
			return t('Match');
		}
		case EventSeriesType.Meeting: {
			return t('Meeting');
		}
		case EventSeriesType.Practice: {
			return t('Practice');
		}
		case EventSeriesType.Uncategorized: {
			return t('Other', { _context });
		}
	}
}
