import { ReactNode } from 'react';

export const symbol_cake_add: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M780-735h-60q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T720-795h60v-60q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T840-855v60h60q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T900-735h-60v60q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T780-675v-60ZM120-80q-17 0-28.5-11.5T80-120v-219q0-24.75 17.625-42.375T140-399h27v-182q0-24.75 17.625-42.375T227-641h183v-64q-20-14-30.5-30.534T369-775.411q0-14.589 5.5-28.089Q380-817 390-827l39-41q1-1 11.286-5Q442-873 451-868l39 41q10 10 16 23.5t6 28.089q0 23.343-11 39.877Q490-719 470-705v64h183q24.75 0 42.375 17.625T713-581v182h27q24.75 0 42.375 17.625T800-339v219q0 17-11.5 28.5T760-80H120Zm107-319h426v-182H227v182Zm-87 259h600v-199H140v199Zm87-259h426-426Zm-87 259h600-600Zm600-259H140h600Z"
		/>
	</svg>
);
