import { Component } from 'react';
import { t } from '@transifex/native';
import styled from 'styled-components';

import * as palette from 'pkg/config/palette';

import rgba from 'pkg/rgba';
import * as sdk from 'pkg/core/sdk';
import { pushState } from 'pkg/router/state';
import Link from 'pkg/router/Link';
import * as routes from 'pkg/router/routes';
import { AccountIdentityContext } from 'pkg/identity/account';
import { link } from 'pkg/router/utils';
import { ApiError } from 'pkg/errors/errors';

import RelativeTime from 'components/RelativeDateTime';
import CardBase, * as Card from 'components/Card';
import SectionTitle from 'components/SectionTitle';
import Icon from 'components/icon';

import { Spinner } from 'components/loaders/spinner';
import Column from 'components/layout/column';

import InlinePlaceholder from 'design/placeholders/inline';
import Button from 'design/button';

const squadTitle = () => t(`Squads`);

const List = styled.ul`
	list-style: none;
	padding: 0;
	margin: 0;
`;

const ItemAction = styled(Link)``;

const ListItem = styled.li`
	display: flex;

	a,
	span {
		flex: 1 1 auto;
		display: block;
		font-size: 1.1rem;
		padding: 0.8rem 0;
		border-bottom: ${rgba(palette.card.innerDividerColor)} 1px solid;

		time {
			display: block;
			font-size: 0.7em;
			color: rgb(180, 180, 180);
			margin-top: 5px;
		}
	}

	${ItemAction} {
		flex: 0 0 auto;
		font-size: var(--font-size-sm);
		display: flex;
		align-items: center;
	}

	&:last-child a,
	&:last-child span {
		border-bottom: none;
	}
`;

class Formations extends Component {
	static contextType = AccountIdentityContext;

	state = {
		squads: [],
		formations: [],
		defaultFormations: [],
		nextSquad: '',
		nextFormation: '',
		nextDefaultFormation: '',
		error: null,
		fetchingSquads: false,
		fetchingFormations: false,
		fetchingDefaultFormations: false,
	};

	componentDidMount() {
		const { groupId } = this.props;
		this.fetchSquads(`/group-formations?group=${groupId}&type=squad`);
		this.fetchFormations(`/group-formations?group=${groupId}&type=formation`);
		this.fetchDefaultFormations(`/group-formations`);
	}

	async fetchSquads(endpoint) {
		let loadingSquads = true;

		this.setState({ loadingSquads });
		const request = await sdk.get(endpoint);

		if (!request.ok) {
			this.setState({
				error: new ApiError(request.status),
			});

			return;
		}

		const collection = await request.json();

		const { squads } = this.state;
		squads.push(...collection.records);
		loadingSquads = false;

		const nextSquad = collection.links?.next ? collection.links.next : '';

		this.setState({ squads, loadingSquads, nextSquad });
	}

	async fetchFormations(endpoint) {
		let loadingFormations = true;

		this.setState({ loadingFormations });
		const request = await sdk.get(endpoint);

		if (!request.ok) {
			this.setState({
				error: new ApiError(request.status),
			});

			return;
		}

		const collection = await request.json();

		const { formations } = this.state;
		formations.push(...collection.records);
		loadingFormations = false;

		const nextFormation = collection.links?.next ? collection.links.next : '';

		this.setState({ formations, loadingFormations, nextFormation });
	}

	async fetchDefaultFormations(endpoint) {
		let loadingDefaultFormations = true;

		this.setState({ loadingDefaultFormations });
		const request = await sdk.get(endpoint);

		if (!request.ok) {
			this.setState({
				error: new ApiError(request.status),
			});

			return;
		}

		const collection = await request.json();

		const { defaultFormations } = this.state;
		defaultFormations.push(...collection.records);
		loadingDefaultFormations = false;

		const nextDefaultFormation = collection.links?.next
			? collection.links.next
			: '';

		this.setState({
			defaultFormations,
			loadingDefaultFormations,
			nextDefaultFormation,
		});
	}

	get squads() {
		const orgId = this.context.organization.id;
		const { loadingSquads, squads, nextSquad } = this.state;

		const items = [
			<List key="squad-list">
				{squads.map((item) => (
					<ListItem key={item.id}>
						<Link href={routes.Group.Formations.New(orgId)}>
							{item.name}
							<RelativeTime dateTime={item.updatedAt} />
						</Link>
					</ListItem>
				))}
			</List>,
		];

		if (loadingSquads) {
			items.push(<Spinner key="loading-squads" />);
		} else if (nextSquad) {
			items.push(
				<Button
					transparent
					block
					key="next-squads"
					onClick={this.fetchSquads.bind(this, nextSquad)}>
					{t('Load more')}
				</Button>
			);
		} else if (squads.length === 0) {
			items.push(
				<InlinePlaceholder
					key="no-squads"
					onClick={() =>
						pushState(
							link(routes.Group.Formations.New(orgId), {
								type: 'squad',
							})
						)
					}>
					<Icon name="nav-formation" />
					{t(`Create your first squad`)}
				</InlinePlaceholder>
			);
		}

		return items;
	}

	get formations() {
		const orgId = this.context.organization.id;
		const { loadingFormations, formations, nextFormation } = this.state;

		const items = [
			<List key="formation-list">
				{formations.map((item, n) => (
					<ListItem key={`${item.id}-${n}`}>
						<Link href={routes.Group.Formations.Show(orgId, item.id)}>
							{item.name}
							<RelativeTime dateTime={item.updatedAt} />
						</Link>

						<ItemAction
							href={link(routes.Group.Formations.New(orgId), {
								type: 'squad',
								baseId: item.id,
							})}>
							<Icon name="add" /> {t('Squads')}
						</ItemAction>
					</ListItem>
				))}
			</List>,
		];

		if (loadingFormations) {
			items.push(<Spinner key="loading-formations" />);
		} else if (nextFormation) {
			items.push(
				<Button
					transparent
					key="next-formations"
					block
					onClick={this.fetchSquads.bind(this, nextFormation)}>
					{t('Load more')}
				</Button>
			);
		} else if (formations.length === 0) {
			items.push(
				<InlinePlaceholder
					key="no-formation-card"
					onClick={() =>
						pushState(
							link(routes.Group.Formations.New(orgId), {
								type: 'formation',
							})
						)
					}>
					<Icon name="nav-formation" />
					{t(`Create your first formation`)}
				</InlinePlaceholder>
			);
		}

		return items;
	}

	get defaultFormations() {
		const orgId = this.context.organization.id;
		const { loadingFormations, defaultFormations, nextFormation } = this.state;

		const items = [
			<List key="formation-list">
				{defaultFormations.map((item) => (
					<ListItem key={item.id}>
						<span>{item.name}</span>

						<ItemAction
							href={link(routes.Group.Formations.New(orgId), {
								type: 'squad',
								baseId: item.id,
							})}>
							<Icon name="add" /> {t('Squads')}
						</ItemAction>
					</ListItem>
				))}
			</List>,
		];

		if (loadingFormations) {
			items.push(<Spinner key="loading-formations" />);
		} else if (nextFormation) {
			items.push(
				<Button
					transparent
					block
					key="next-formations"
					onClick={this.fetchSquads.bind(this, nextFormation)}>
					{t('Load more')}
				</Button>
			);
		} else if (defaultFormations.length === 0) {
			items.push(
				<InlinePlaceholder key="no-default-formations">
					<Icon name="nav-formation" />
					{t(`No template formations found`)}
				</InlinePlaceholder>
			);
		}

		return items;
	}

	render() {
		const orgId = this.context.organization.id;

		return (
			<Column>
				<Column>
					<SectionTitle>
						{t(`Squads`)}

						<Button
							inline
							noPadding
							href={link(routes.Group.Formations.New(orgId), {
								type: 'squad',
							})}
							data-testid="formations.new_squad_link">
							<Icon name="add" /> {t('Squads')}
						</Button>
					</SectionTitle>
					<CardBase $noBorder name={squadTitle()} key="squads">
						<Card.Body>{this.squads}</Card.Body>
					</CardBase>
				</Column>
				<Column>
					<SectionTitle>
						{t(`Formations`)}
						<Button
							inline
							noPadding
							href={link(routes.Group.Formations.New(orgId), {
								type: 'formation',
							})}
							data-testid="formations.new_formation_link">
							<Icon name="add" />
							{t(`Formations`)}
						</Button>
					</SectionTitle>
					<CardBase $noBorder>
						<Card.Body>{this.formations}</Card.Body>
					</CardBase>
				</Column>

				<Column>
					<SectionTitle>{t(`Templates`)}</SectionTitle>

					<CardBase $noBorder>
						<Card.Body>{this.defaultFormations}</Card.Body>
					</CardBase>
				</Column>
			</Column>
		);
	}
}

export default Formations;
