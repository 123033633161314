import { Fragment, ElementType } from 'react';
import styled, { css } from 'styled-components';

import * as styles from 'pkg/config/styles';
import * as zIndex from 'pkg/config/zindex';
import * as palette from 'pkg/config/palette';

import rgba from 'pkg/rgba';
import { formatTime } from 'pkg/timeline';
import { isYouTubeUrl, getYouTubeThumbnailUrl } from 'pkg/video';
import Link from 'pkg/router/Link';

import Icon from 'components/icon';

import * as iconStyles from 'components/icon/styles.css';

const Wrapper = styled.div`
	background: ${palette.white};
	position: relative;
	overflow: hidden;
	border-radius: var(--radius-5);
	display: block;

	&::before {
		padding-top: 56.25%;
		display: block;
		content: '';
	}
`;

const GradientIcon = styled(Icon)`
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	transform: rotate(-28deg) scale(2) translateX(-6%) translateY(-14%);
	mask-image: linear-gradient(217deg, #383f50 0%, rgba(56, 63, 80, 0) 65.73%);
`;

const Source = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	z-index: ${zIndex.videoThumbnail};
	border-radius: var(--radius-5);
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${palette.gray[600]};
	color: ${palette.gray[300]};

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.${iconStyles.icon} {
		color: ${palette.brand};
		font-size: 4rem;
		z-index: 1;

		@media ${`all and (max-width: ${styles.breakpoint.toSmall}px)`} {
			font-size: 2.5rem;
		}
	}

	${GradientIcon} {
		color: ${palette.gray[700]};
		z-index: 0;
	}
`;

const Overlay = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	z-index: ${zIndex.videoThumbnail + 2};
`;

const Duration = styled.time<{ $isVisible: boolean }>`
	padding: var(--spacing-2) var(--spacing-3);
	border-radius: var(--radius-2);
	position: absolute;
	display: inline-block;
	background: ${rgba(palette.black, 0.8)};
	color: ${palette.white};
	z-index: ${zIndex.videoThumbnail + 1};
	font-size: var(--font-size-sm);
	right: 5px;
	bottom: 5px;

	@supports (backdrop-filter: blur(15px)) {
		background: ${rgba(palette.black, 0.5)};
		backdrop-filter: blur(15px);
	}

	@media ${`all and (max-width: ${styles.breakpoint.toSmall}px)`} {
		padding: var(--spacing-1) var(--spacing-2);
		font-size: var(--font-size-xs);
	}

	${({ $isVisible }) =>
		!$isVisible &&
		css`
			opacity: 0;
		`}
`;

interface ThumbnailProps {
	sourceUrl: string;
	targetUrl?: string;
	duration?: number;
	className?: string;
}

interface WrapperProps {
	className?: string;
	href?: string;
	as?: ElementType;
}

const Thumbnail = ({
	sourceUrl,
	targetUrl,
	duration,
	className,
}: ThumbnailProps): JSX.Element => {
	let source = sourceUrl;
	let preview = (
		<Fragment>
			<Icon name="videos" />
			<GradientIcon name="logo" />
		</Fragment>
	);

	if (source !== null) {
		if (isYouTubeUrl(source)) {
			source = getYouTubeThumbnailUrl(sourceUrl);
		}

		preview = <img src={source} alt="" loading="lazy" />;
	}

	let props: WrapperProps = { className };

	if (targetUrl?.length > 0) {
		props = {
			href: targetUrl,
			as: Link,
		};
	}

	return (
		<Wrapper {...props}>
			<Source>{preview}</Source>
			<Overlay />
			<Duration $isVisible={duration > 0}>
				{formatTime(duration / 1000)}
			</Duration>
		</Wrapper>
	);
};

export default Thumbnail;
