import { ReactNode } from 'react';

export const symbol_adf_scanner: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M80-220v-158q0-45 27.5-72.5T180-478h62v-262q0-24.75 17.625-42.375T302-800h356q24.75 0 42.375 17.625T718-740v262h62q45 0 72.5 27.5T880-378v158q0 24.75-17.625 42.375T820-160H140q-24.75 0-42.375-17.625T80-220Zm222-258h356v-262H302v262ZM140-220h680v-158q0-20-10-30t-30-10H180q-20 0-30 10t-10 30v158Zm599.175-103q12.825 0 21.325-8.675 8.5-8.676 8.5-21.5 0-12.825-8.675-21.325-8.676-8.5-21.5-8.5-12.825 0-21.325 8.675-8.5 8.676-8.5 21.5 0 12.825 8.675 21.325 8.676 8.5 21.5 8.5ZM140-418h680-680Z"
		/>
	</svg>
);
