import { ReactNode } from 'react';

export const symbol_resume: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M240-270v-420q0-13 8.5-21.5T270-720q13 0 21.5 8.5T300-690v420q0 13-8.5 21.5T270-240q-13 0-21.5-8.5T240-270Zm220 1 298-186q7-5 10.5-11t3.5-14q0-8-3.5-14T758-505L460-691q-4-2-8-3.5t-8-1.5q-12 0-21 8t-9 22v372q0 14 9 22t21 8q4 0 8-1.5t8-3.5Zm14-86v-250l201 125-201 125Zm0-125Z"
		/>
	</svg>
);
