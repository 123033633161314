import { useT } from '@transifex/react';

import * as models from 'pkg/api/models';
import { PaymentProvider } from 'pkg/api/models/group';
import Link from 'pkg/router/Link';

import AdyenSettings from 'routes/settings/payments/adyen';

import SectionTitle from 'components/SectionTitle';
import * as Card from 'components/Card';

import Column from 'components/layout/column';
import {
	SettingDescription,
	SettingLabel,
	SettingRow,
} from 'components/settings/Settings';

import Button from 'design/button';

import * as css from './styles.css';

interface PaymentSettingsProps {
	organization: models.group.Group;
}

export default function PaymentSettings({
	organization,
}: PaymentSettingsProps) {
	const t = useT();

	const adyenConfig = organization.paymentConfigs?.find(
		(c) => c.paymentProvider === PaymentProvider.Adyen
	);

	let content = (
		<SettingRow>
			<SettingLabel>{t('Payment Provider')}</SettingLabel>
			<SettingDescription className={css.capitalize}>
				{organization.paymentProvider}
			</SettingDescription>
			{organization.paymentProvider === PaymentProvider.Stripe && (
				<Link href="https://dashboard.stripe.com/" target="_blank">
					<Button small icon="new_window">
						{t('Visit stripe dashboard')}
					</Button>
				</Link>
			)}
		</SettingRow>
	);

	if (organization.paymentProvider === PaymentProvider.Adyen && adyenConfig) {
		content = (
			<AdyenSettings organization={organization} paymentConfig={adyenConfig} />
		);
	}

	return (
		<Column>
			<SectionTitle>{t('Payments settings')}</SectionTitle>
			<Card.Base $noBorder>
				<Card.Body>{content}</Card.Body>
			</Card.Base>
		</Column>
	);
}
