import { ReactNode } from 'react';

export const symbol_carry_on_bag_inactive: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m613-425-60-60v-119H434l-60-60h179v-156h-54q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T499-880h84q12.75 0 21.375 8.625T613-850v425ZM778-90l-47-46q-8 37-40 66.5T613-40q-41 0-75-25t-42-70H272q-24 0-42-18t-18-42v-409q0-10 3.5-20.5T226-641L109-758q-9-9-9-21.5t9-21.5q9-9 21.5-9t21.5 9l669 669q9 9 9 21t-9 21q-9 9-21.5 9T778-90ZM338-195v-334l-66-66v400h66ZM612.767-90Q643-90 663-109.767q20-19.768 20-50Q683-190 663.233-210q-19.768-20-50-20Q583-230 563-210.233q-20 19.768-20 50Q543-130 562.767-110q19.768 20 50 20ZM398-195h99q6-20 22.5-40.5T553-265v-49L398-469v274Zm215 35ZM434-604Zm-66 272Zm-30 137v-334 334Zm60 0v-274 274Zm215 35Z"
		/>
	</svg>
);
