import { ReactNode } from 'react';

export const symbol_azm: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M451-91q-14 14-32.5 6.344T400-112v-288H112q-19.688 0-27.344-18.5Q77-437 91-451l331-331q8-8 19.278-13 11.278-5 23.722-5h275q24.75 0 42.375 17.625T800-740v275q0 12.444-5 23.722T782-422L451-91Zm179-509v246l110-110v-276H464L354-630h246q12.75 0 21.375 8.625T630-600ZM460-430v246l110-110v-276H294L184-460h246q12.75 0 21.375 8.625T460-430Z"
		/>
	</svg>
);
