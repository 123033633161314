import { ReactNode } from 'react';

export const symbol_savings: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M230-120q-21 0-39.49-13.964Q172.02-147.927 166-168q-25-86-41.542-148.458-16.543-62.458-26.365-109.682-9.822-47.224-13.957-83.785Q80-546.486 80-580q0-92 64-156t156-64h200q27-36 68.5-58t91.5-22q25 0 42.5 17.5T720-820q0 6-1.5 12t-3.5 11q-4 11-7.5 22t-5.5 24l91 91h57q12.75 0 21.375 8.625T880-630v227q0 10.242-5.5 18.121Q869-377 859-374l-91.926 30.297L713-163q-6.026 19.614-21.844 31.307Q675.338-120 655-120H540q-24.75 0-42.375-17.625T480-180v-20h-80v20q0 24.75-17.625 42.375T340-120H230Zm-5-60h115v-80h200v80h115l63-210 102-35v-175h-52L640-728q1-17 5.5-41.5T658-824q-43 11-76 33t-48 51H300q-66.286 0-113.143 46.857T140-580q0 42 22 146.5T225-180Zm415-340q17 0 28.5-11.5T680-560q0-17-11.5-28.5T640-600q-17 0-28.5 11.5T600-560q0 17 11.5 28.5T640-520ZM490-620q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T490-680H350q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T350-620h140Zm-10 118Z"
		/>
	</svg>
);
