import { ReactNode } from 'react';

export const symbol_location_home: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m516-813 260 195q11.25 8.25 17.625 21T800-570v390q0 24.75-17.625 42.375T740-120H220q-24.75 0-42.375-17.625T160-180v-390q0-14.25 6.375-27T184-618l260-195q15.68-12 35.84-12Q500-825 516-813Zm-35.882 443Q526-370 558-402.118q32-32.117 32-78Q590-526 557.882-558q-32.117-32-78-32Q434-590 402-557.882q-32 32.117-32 78Q370-434 402.118-402q32.117 32 78 32Zm.057-60Q459-430 444.5-444.325q-14.5-14.324-14.5-35.5Q430-501 444.325-515.5q14.324-14.5 35.5-14.5Q501-530 515.5-515.675q14.5 14.324 14.5 35.5Q530-459 515.675-444.5q-14.324 14.5-35.5 14.5ZM479-250q-55 0-106.5 18T278-180h403q-44-34-95.5-52T479-250ZM220-574v365q54-48 120.516-74.5T479-310q72.67 0 139.835 27T740-208v-366L480-763 220-574Zm260 94Z"
		/>
	</svg>
);
