import { t } from '@transifex/native';
import styled from 'styled-components';
import { Fragment, useState } from 'react';

import { pushState } from 'pkg/router/state';
import * as routes from 'pkg/router/routes';
import * as models from 'pkg/api/models';
import { useCurrentRoute } from 'pkg/router/hooks';
import { useCurrentMembership, useCurrentOrganization } from 'pkg/identity';

import AssetImage from 'components/AssetImage';

import Row from 'components/layout/row';
import VideoModal from 'components/video-library/modals/Video';
import PlaylistModal from 'components/video-library/modals/Playlist';
import CollectionModal from 'components/video-library/modals/add-to-collection';

import Button from 'design/button';
import * as ContextMenu from 'design/context_menu';
import PlaceholderBlock from 'design/placeholders/block';

const Wrapper = styled.div`
	width: 100%;
	max-width: 640px;
`;

export default function EmptyState(): JSX.Element {
	const { groupId } = useCurrentRoute();
	const membership = useCurrentMembership();
	const org = useCurrentOrganization();

	const [activeModal, setActiveModal] = useState<
		'' | 'video' | 'playlist' | 'collection'
	>('');

	const openVideoModal = () => setActiveModal('video');
	const openPlaylistModal = () => setActiveModal('playlist');
	const openCollectionModal = () => setActiveModal('collection');
	const closeModal = () => setActiveModal('');

	const handleAfterVideoSave = (video: models.video.Video) => {
		pushState(routes.Video.Show(org.id, video.id));
	};

	const handleAfterPlaylistSave = (
		playlist: models.videoPlaylist.VideoPlaylist
	) => {
		pushState(routes.VideoPlaylist.Show(org.id, playlist.id));
	};

	const handleAfterCollectionSave = (
		collection: models.videoCollection.VideoCollection
	) => {
		pushState(
			routes.VideoLibrary.Collection.Show(org.id, groupId, collection.id)
		);
	};

	const actions = models.membership.valid(membership) && (
		<ContextMenu.Menu
			toggleWith={
				<Button secondary icon="add">
					{t(`Add content`)}
				</Button>
			}>
			<ContextMenu.Item onClick={openVideoModal}>
				<ContextMenu.ItemIcon name="smart_display" />
				{t(`Video`)}
			</ContextMenu.Item>
			<ContextMenu.Item onClick={openPlaylistModal}>
				<ContextMenu.ItemIcon name="playlist_add" />
				{t(`Playlist`)}
			</ContextMenu.Item>
			<ContextMenu.Item onClick={openCollectionModal}>
				<ContextMenu.ItemIcon name="create_new_folder" />
				{t(`Collection`)}
			</ContextMenu.Item>
		</ContextMenu.Menu>
	);

	return (
		<Fragment>
			{activeModal === 'video' && (
				<VideoModal onClose={closeModal} afterSave={handleAfterVideoSave} />
			)}
			{activeModal === 'playlist' && (
				<PlaylistModal
					onClose={closeModal}
					afterSave={handleAfterPlaylistSave}
				/>
			)}
			{activeModal === 'collection' && (
				<CollectionModal
					createOnly
					onClose={closeModal}
					afterSave={handleAfterCollectionSave}
				/>
			)}
			<Row align="center" justify="center">
				<Wrapper>
					<PlaceholderBlock
						noBorder
						title={t(`Get started with Video Analytics!`)}
						description={t(
							`Video provides insights unlike anything else. Get started right away by adding a video, playlist or even a collection.`
						)}
						helpUrl="https://help.360player.com/article/18-how-to-add-a-video"
						image={
							<AssetImage src="img/missing-entities/video-analytics.svg" />
						}
						actions={actions}
					/>
				</Wrapper>
			</Row>
		</Fragment>
	);
}
