import { ReactNode } from 'react';

export const symbol_storm: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M778-80q-11 0-17.5-10.5T758-113q19-61 25.5-125T782-366q-33 95-115.5 151.5T483-158q-85 0-154-40.5T212-306q-48-67-74-154.5T112-641q0-53 6-104.5T140-848q5-14 16-23t26-9q11 0 17.5 10.5T202-847q-18 61-23.5 125t-.5 127q34-96 118-151.5T483-802q85 0 152.5 40.5t115 108Q798-586 823-499.5T848-320q0 54-6 105.5T820-112q-5 14-16 23t-26 9ZM483-220q109 0 184.5-75.5T743-480q0-109-75.5-184.5T483-740q-109 0-184.5 75.5T223-480q0 109 75.5 184.5T483-220Zm0-110q63 0 106.5-43.5T633-480q0-63-43.5-106.5T483-630q-63 0-106.5 43.5T333-480q0 63 43.5 106.5T483-330Zm0-60q-38 0-64-26t-26-64q0-38 26-64t64-26q38 0 64 26t26 64q0 38-26 64t-64 26Zm0-90Z"
		/>
	</svg>
);
