import { ReactNode } from 'react';

export const symbol_variable_insert: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M150-280q-12.75 0-21.375-8.625T120-310v-340q0-12.75 8.625-21.375T150-680h660q12.75 0 21.375 8.625T840-650v140q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T780-510v-110H180v280h390q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T570-280H150Zm30-60v-280 280Zm540 23v107q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T660-210v-180q0-12.75 8.625-21.375T690-420h180q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T870-360H762l133 133q9 8.8 9 20.9 0 12.1-9.053 21.1-9.052 9-21.5 9Q861-176 852-185L720-317Z"
		/>
	</svg>
);
