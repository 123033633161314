import { ReactNode } from 'react';

export const symbol_transit_enterexit: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M409-335h185q19.583 0 33.292 13.708Q641-307.583 641-288q0 20-13.708 34-13.709 14-33.292 14H270q-12.75 0-21.375-8.625T240-270v-324q0-19.583 14-33.292Q268-641 288-641t33.5 13.708Q335-613.583 335-594v185l275-275q15.171-15 37.086-15Q669-699 684-683.864q15 15.137 15 37Q699-625 684-610L409-335Z"
		/>
	</svg>
);
