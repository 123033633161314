const icon = {
	name: 'whistle',
	objects: [
		{
			type: 'path',
			attributes: {
				d: 'M10.7452 15.498C11.5727 15.498 12.2435 14.8272 12.2435 13.9997C12.2435 13.1722 11.5727 12.5014 10.7452 12.5014C9.91771 12.5014 9.24691 13.1722 9.24691 13.9997C9.24691 14.8272 9.91771 15.498 10.7452 15.498ZM10.7452 16.798C12.2906 16.798 13.5435 15.5451 13.5435 13.9997C13.5435 12.4542 12.2906 11.2014 10.7452 11.2014C9.19974 11.2014 7.94691 12.4542 7.94691 13.9997C7.94691 15.5451 9.19974 16.798 10.7452 16.798Z',
				fillRule: 'evenodd',
				clipRule: 'evenodd',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M14.5307 12.3512C14.733 12.0647 15.0424 11.8721 15.3888 11.817L20.0863 11.0708V10.3774H10.9642C10.9271 10.3795 10.8899 10.38 10.8527 10.3789C10.8171 10.3779 10.7812 10.3774 10.7452 10.3774C8.73997 10.3774 7.11439 12.003 7.11439 14.0082C7.11439 16.0135 8.73997 17.6391 10.7452 17.6391C12.7505 17.6391 14.376 16.0135 14.376 14.0082C14.376 13.7784 14.3549 13.5547 14.3147 13.3387C14.2505 12.9939 14.3284 12.6378 14.5307 12.3512ZM10.8903 9.07949C10.8421 9.0781 10.7937 9.0774 10.7452 9.0774C8.022 9.0774 5.81439 11.285 5.81439 14.0082C5.81439 16.7314 8.022 18.9391 10.7452 18.9391C13.4684 18.9391 15.676 16.7314 15.676 14.0082C15.676 13.7873 15.6615 13.5699 15.6334 13.3567C15.622 13.2707 15.6085 13.1854 15.5928 13.1009L20.5258 12.3172C21.0214 12.2385 21.3863 11.8111 21.3863 11.3093V10.098C21.3863 9.53432 20.9293 9.07739 20.3657 9.07739H10.9564C10.9342 9.07739 10.9121 9.07809 10.8903 9.07949Z',
				fillRule: 'evenodd',
				clipRule: 'evenodd',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M3.14525 6.71111C3.39909 6.45727 3.81065 6.45727 4.06449 6.71111L6.57731 9.22398C6.83115 9.47782 6.83114 9.88938 6.5773 10.1432C6.32346 10.3971 5.9119 10.3971 5.65806 10.1432L3.14524 7.63034C2.8914 7.3765 2.89141 6.96494 3.14525 6.71111Z',
				fillRule: 'evenodd',
				clipRule: 'evenodd',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M2.631 10.4622C2.71276 10.1126 3.06241 9.89553 3.41196 9.97729L5.38742 10.4394C5.73697 10.5211 5.95406 10.8708 5.8723 11.2203C5.79053 11.5699 5.44089 11.787 5.09134 11.7052L3.11587 11.2431C2.76632 11.1614 2.54924 10.8117 2.631 10.4622Z',
				fillRule: 'evenodd',
				clipRule: 'evenodd',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M6.66556 6.0347C7.01325 5.94538 7.36752 6.15483 7.45685 6.50252L7.94969 8.42095C8.03901 8.76864 7.82956 9.12291 7.48186 9.21224C7.13417 9.30156 6.7799 9.09211 6.69057 8.74441L6.19773 6.82599C6.10841 6.47829 6.31786 6.12402 6.66556 6.0347Z',
				fillRule: 'evenodd',
				clipRule: 'evenodd',
			},
		},
	],
};

export default icon;
