import { CollectionSelection } from 'pkg/api/use_collection';
import * as actions from 'pkg/actions';
import * as models from 'pkg/api/models';

import * as ActionBar from 'components/layout/ActionBar';
import { RemoveUserItem, useRemoveUserDialog } from 'components/user/remove';

interface MembershipBulkActionsProps {
	groupId: number;
	selection: CollectionSelection;
	selectedRecords: models.membership.Membership[];
	onRemove: () => void;
}

export default function MembershipBulkActions({
	groupId,
	selection,
	selectedRecords,
	onRemove,
}: MembershipBulkActionsProps) {
	const handleRemoveUsers = async (
		removeUsersRecursively: boolean,
		removeFromFutureEvents: boolean
	) => {
		const ok = await actions.memberships.removeUsersFromGroup(groupId, {
			userIds: selectedRecords.map((m) => m.userId),
			removeUsersRecursively,
			removeFromFutureEvents,
		});

		if (ok) {
			selection.deselectAll();
			onRemove();
		}
	};

	const dialog = useRemoveUserDialog({ handleRemoveUsers, bulk: true });

	return (
		<ActionBar.BulkActions
			numSelected={selection.selectedRecords.length}
			actions={[<RemoveUserItem bulk dialog={dialog} />]}
		/>
	);
}
