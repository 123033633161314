import { ChangeEvent } from 'react';
import { t } from '@transifex/native';

import TaxRate from 'pkg/models/tax_rates';

import * as models from 'pkg/api/models';
import { useCollection } from 'pkg/api/use_collection';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCurrentGroup } from 'pkg/identity';

import * as Input from 'components/form/inputs';

interface TaxSelectProps {
	name: string;
	value?: number;
	handleChange: (event: ChangeEvent<HTMLSelectElement>) => void;
}

const TaxSelect: React.FC<React.PropsWithChildren<TaxSelectProps>> = ({
	name,
	value,
	handleChange,
}) => {
	const group = useCurrentGroup();
	const { records: taxRates } = useCollection<models.taxRate.TaxRate>(
		endpoints.TaxRates.Index(group.id),
		{
			queryParams: new URLSearchParams({
				active: 'true',
			}),
		}
	);

	return (
		<Input.Select name={name} value={value || ''} onChange={handleChange}>
			<option value="">{t('No tax selected')}</option>
			{taxRates.map((item: TaxRate) => {
				return (
					<option key={item.id} value={item.id}>
						{item.display_name} {item.percentage}%{' '}
						{item.inclusive && t('incl.')}
					</option>
				);
			})}
		</Input.Select>
	);
};

export default TaxSelect;
