import { ReactNode, SelectHTMLAttributes, forwardRef } from 'react';
import styled from 'styled-components';

import Icon from 'components/icon';

import {
	SelectIconWrapper,
	SelectInput,
	Wrapper,
} from 'components/form/inputs/styles';

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
	name?: string;
	children: ReactNode;
	small?: boolean;
}

const Select = forwardRef<JSX.Element, SelectProps>(
	(
		{ name, children, className, small, ...rest }: SelectProps,
		ref: any
	): JSX.Element => {
		return (
			<Wrapper className={className} small={small}>
				<SelectIconWrapper>
					<Icon name="chevron" />
					<Icon name="chevron" />
				</SelectIconWrapper>
				<SelectInput name={name} ref={ref} small={small} {...rest}>
					{children}
				</SelectInput>
			</Wrapper>
		);
	}
);

/**
 * @deprecated
 * Use Inputs.Select in `components/forms/inputs`
 */
export default styled(Select)``;
