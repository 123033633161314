import { t } from '@transifex/native';
import { Fragment, Component } from 'react';
import styled from 'styled-components';

import * as breakpoints from 'pkg/config/breakpoints';

import CurrentPosition from 'containers/user/rating/CurrentPosition';

import * as Card from 'components/Card';
import { FormattedContent } from 'components/formatted-content';
import ToggleSwitch from 'components/ToggleSwitch';
import Icon from 'components/icon';
import Avatar from 'components/avatar';

import TrainingPriorityTable from 'components/user/rating/TrainingPriority';
import PositionMap from 'components/user/rating/PositionMap';

const FauxText = styled.div`
	width: 100%;
	background: var(--palette-gray-200);
	height: 1rem;
	border-radius: var(--radius-2);
`;

const InfoBody = styled(Card.Body)`
	display: grid;
	padding: 0 20px 20px 20px;
	grid-template-columns: 1fr;
	grid-row-gap: 25px;

	@media ${breakpoints.small} {
		padding: 0 20px 20px 20px;
	}
`;

const PositionInfo = styled.div`
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: flex-end;
	margin-top: 1.5rem;
`;

const PositionDescription = styled.div`
	font-size: var(--font-size-base);
	margin-top: 10px;
	font-weight: var(--font-weight-normal);

	h4 {
		font-weight: var(--font-weight-normal);
		font-size: var(--font-size-base);
		line-height: 1.1;
		margin: 0 0 0.75rem 0;
		padding: 0;
		color: var(--palette-gray-500);
	}
`;

const Position = styled.h4`
	margin-right: auto;
	font-weight: var(--font-weight-semibold);
	font-size: var(--font-size-2xl);
	margin: 0;
	padding: 0;
	color: var(--palette-gray-800);
	flex: 1;
`;

const Score = styled.div`
	text-align: right;
	margin-left: 10px;
	font-weight: var(--font-weight-semibold);
	font-size: var(--font-size-2xl);
	flex: 0;
	color: var(--palette-gray-800);
	position: relative;

	&::before {
		content: '${(props) => props.label}';
		display: block;
		position: absolute;
		max-width: 100%;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;

		margin: 0 0 5px 0;
		bottom: 100%;
		right: 0;

		text-align: right;
		line-height: 1;

		color: var(--palette-gray-600);
		font-size: var(--font-size-sm);
		font-weight: var(--font-weight-normal);
		text-align: right;
	}
`;

const TableWrapper = styled.div`
	${Card.Heading} {
		color: var(--palette-gray-800);
		margin-bottom: 1rem;
		font-weight: var(--font-weight-semibold);
		font-size: var(--font-size-lg);
	}
`;

const MaturityToggle = styled(ToggleSwitch)`
	margin-top: 0;
	padding: 0;
	justify-content: flex-start;
	flex-flow: column;
	align-items: flex-end;

	@media ${breakpoints.small} {
		padding: 0;
	}

	> label {
		color: var(--palette-gray-600);
		font-size: var(--font-size-sm);
		margin: 0 0 0.5rem 0;
		font-weight: var(--font-weight-normal);
		text-align: right;
	}
`;

const BestPositionIcon = styled(Icon)`
	z-index: 10;
	height: 20px;
	width: 20px;
	margin-right: 8px;

	color: var(--palette-white);
	background: var(--palette-blue-500);
	border-radius: 50%;
	padding: 3px;
	border: 1px solid var(--palette-gray-100);
`;

const ItemWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const Legend = styled.div`
	display: flex;
	flex-flow: ${(props) => (props.spread ? 'row' : 'column wrap')};
	justify-content: ${(props) =>
		props.spread ? 'space-between' : 'flex-start'};
	width: ${(props) => (props.spread ? '100%' : 'auto')};

	& > *:first-child {
		margin-bottom: ${(props) => (props.spread ? 0 : 0.5)}rem;
	}
`;

const AvatarWrapper = styled.div`
	height: 20px;
	width: 20px;
	font-size: 0.6rem;
	margin-right: 8px;
`;

const LegendLabel = styled.p`
	color: var(--palette-gray-600);
	font-size: var(--font-size-sm);
	font-weight: var(--font-weight-normal);
`;

const MapWrapper = styled.div`
	padding: var(--spacing-9) var(--spacing-6);

	${PositionMap} {
		max-width: 500px;
		margin: 0 auto;
	}

	@media ${breakpoints.small} {
		padding: var(--spacing-8) var(--spacing-3);
	}
`;

const LegendWrapper = styled.div`
	display: flex;
	position: relative;
	margin: 1rem 0;
	justify-content: ${(props) =>
		props.isChild ? 'space-between' : 'flex-start'};
	margin: 0 -20px;
	padding: 5px 20px 20px 20px;
`;

export default class PositionDetails extends Component {
	get hasComparison() {
		if (!this.props.comparison) {
			return false;
		}

		return !!this.props.comparison.id;
	}

	get showScores() {
		const { comparison, rating } = this.props;

		if (this.hasComparison && rating.id) {
			return !(comparison.hiddenScores || rating.hiddenScores);
		}

		if (!rating) {
			return true;
		}

		return !rating.hiddenScores;
	}

	get score() {
		if (!this.showScores) {
			return null;
		}

		let score = <FauxText />;

		const {
			maturityAdjusted,
			rating,
			comparison,
			currentPositionId,
			comparisonScores,
			positionScores,
		} = this.props;

		if (
			rating &&
			positionScores?.find((s) => s.positionId === currentPositionId)
		) {
			let p = positionScores.find((s) => s.positionId === currentPositionId);

			score = (maturityAdjusted ? p.maturityAdjustedValue : p.value).toFixed(2);
		}

		let compare = <FauxText />;

		if (
			this.hasComparison &&
			comparisonScores.find((s) => s.positionId === currentPositionId)
		) {
			let cp = comparisonScores.find((s) => s.positionId === currentPositionId);

			compare = (
				maturityAdjusted ? cp.maturityAdjustedValue : cp.value
			).toFixed(2);
		}

		if (this.hasComparison && comparison.author && rating.author) {
			return (
				<Fragment>
					<Score label={rating.author.firstName}>{score}</Score>
					<Score label={comparison.author.firstName}>{compare}</Score>
				</Fragment>
			);
		}

		return <Score data-testid="assessment.score">{score}</Score>;
	}

	get positionMapIconLegends() {
		if (this.props.rating) {
			return (
				<Legend spread={!this.props.isChild}>
					<ItemWrapper>
						<BestPositionIcon name="star" />
						<LegendLabel>{t(`Best position`)}</LegendLabel>
					</ItemWrapper>
					<ItemWrapper>
						<AvatarWrapper>
							<Avatar user={this.props.rating.user} />
						</AvatarWrapper>
						<LegendLabel>{t(`Chosen position`)}</LegendLabel>
					</ItemWrapper>
				</Legend>
			);
		}

		return null;
	}

	render() {
		return (
			<InfoBody key="information">
				<div>
					<MapWrapper>
						<CurrentPosition
							comparison={this.props.comparison}
							rating={this.props.rating}
						/>
					</MapWrapper>
					<LegendWrapper isChild={this.props.isChild}>
						{this.positionMapIconLegends}
						{this.props.isChild && (
							<MaturityToggle
								label={t(`Physical development`)}
								active={this.props.maturityAdjusted}
								onToggle={this.props.handleMaturityAdjustmentToggle}
							/>
						)}
					</LegendWrapper>
					<PositionInfo>
						<Position>{this.props.position?.name}</Position>

						{this.score}
					</PositionInfo>
					<PositionDescription>
						<h4>{this.props.position?.longName}</h4>
						{this.props.position?.description && (
							<FormattedContent
								raw={this.props.position.description}
								maxLength={80}
							/>
						)}
					</PositionDescription>
				</div>

				{!this.props.hideTrainingSuggestions && (
					<TableWrapper>
						<Card.Heading>{t(`Training priority`)}</Card.Heading>
						<TrainingPriorityTable
							maturityAdjusted={this.props.maturityAdjusted}
							ratingId={this.props.ratingId}
						/>
					</TableWrapper>
				)}
			</InfoBody>
		);
	}
}
