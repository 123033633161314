import { ReactNode } from 'react';

export const symbol_no_encryption: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M610-634v-95q0-54-38.849-92.5T476-860q-44 0-78.5 26.5T351-765q-4 13-13.5 20.5T317-739q-14-2-21.5-12.5T291-774q15-65 66.739-105.5Q409.478-920 476-920q80.915 0 137.457 55.867Q670-808.265 670-729v95h70q24.75 0 42.375 17.625T800-574v265q0 15-9.318 22.5t-20.5 7.5Q759-279 749.5-286.683 740-294.366 740-309v-265H474q-15 0-22.5-9.318t-7.5-20.5q0-11.182 7.5-20.682Q459-634 474-634h136ZM220-80q-24.75 0-42.375-17.625T160-140v-434q0-23 16-40.5t39-19.5L7-842q-9-9-8.5-21T8-884q9-9 21-9t21 9L889-45q9 9 8.5 21T888-3q-9 9-21 9t-21-9l-82-82q-8 3-13 4t-11 1H220Zm490-60L535-314q-11 11-24.239 16T482-293q-32 0-53.5-21T407-366.68q0-16.32 5.5-29.82T429-421L275-574h-55v434h490ZM493-357Zm113-84Z"
		/>
	</svg>
);
