import { ReactNode } from 'react';

export const symbol_developer_board: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M150-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v60.146h30q12.75 0 21.375 8.646T870-689.781q0 12.781-8.625 21.281Q852.75-660 840-660h-30v150h30.175q12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.625 21.325T840-450h-30v150h30.175q12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.625 21.422-8.625 8.597-21.375 8.597h-30V-180q0 24-18 42t-42 18H150Zm0-60h600v-600H150v600Zm90-60h193q12.75 0 21.375-8.625T463-270v-140q0-12.75-8.625-21.375T433-440H240q-12.75 0-21.375 8.625T210-410v140q0 12.75 8.625 21.375T240-240Zm283-336h137q12.75 0 21.375-8.625T690-606v-84q0-12.75-8.625-21.375T660-720H523q-12.75 0-21.375 8.625T493-690v84q0 12.75 8.625 21.375T523-576ZM240-470h193q12.75 0 21.375-8.625T463-500v-190q0-12.75-8.625-21.375T433-720H240q-12.75 0-21.375 8.625T210-690v190q0 12.75 8.625 21.375T240-470Zm283 230h137q12.75 0 21.375-8.625T690-270v-246q0-12.75-8.625-21.375T660-546H523q-12.75 0-21.375 8.625T493-516v246q0 12.75 8.625 21.375T523-240ZM150-780v600-600Z"
		/>
	</svg>
);
