import { Map, Record } from 'immutable';

import MatchEvent from 'pkg/models/match_event';

import * as actionTypes from 'pkg/actions/action-types';

const initialState = new Record(
	{
		entities: new Map(),
		eventTypes: new Map(),

		edit: {
			activeModal: false,
			eventType: '',
			eventId: null,
			changes: new Map({}),
		},

		guids: new Map({}),
		modes: new Map({}),
	},
	'match_events'
);

const matchEvents = (state = initialState(), action = {}) => {
	switch (action.type) {
		case actionTypes.MatchEvents.SET_GUIDS:
		case actionTypes.MatchEvents.ADD_GUIDS: {
			const guids = action.guids.reduce(
				(guids, item) => guids.set(item.id, new MatchEvent(item)),
				new Map({})
			);

			const modesToAdd = action.guids.reduce((obj, item) => {
				const persistedTypes = ['substitution_in', 'position_swap'];
				const persisted = persistedTypes.includes(item.type);

				return obj.set(
					item.id,
					new Map({
						persisted,
						handled: false,
						newEntity: true,
					})
				);
			}, new Map({}));

			return state
				.update('entities', (entities) => entities.merge(guids))
				.update('modes', (modes) => modes.merge(modesToAdd));
		}

		case actionTypes.MatchEvents.ADD_EVENTS: {
			if (!action.payload.entities.matchEvents) {
				return;
			}

			let modes = state.modes.withMutations((m) =>
				Object.values(action.payload.entities.matchEvents).forEach((event) =>
					m.set(
						event.id,
						Map({
							persisted: true,
							handled: false,
							newEntity: false,
						})
					)
				)
			);

			let entities = state.entities.withMutations((m) =>
				Object.values(action.payload.entities.matchEvents).forEach((event) =>
					m.set(event.id, new MatchEvent(event))
				)
			);

			return state.set('entities', entities).set('modes', modes);
		}

		case actionTypes.MatchEvents.UPDATE_USER_STARTING_POSITION: {
			const { matchEventId, positionId } = action;
			return state
				.setIn(['entities', matchEventId, 'positionId'], positionId)
				.setIn(['modes', matchEventId, 'handled'], true);
		}

		case actionTypes.MatchEvents.REMOVE_SINGLE_EVENT: {
			return state
				.deleteIn(['entities', action.matchEventId])
				.update('modes', (modes) => modes.remove(action.matchEventId));
		}

		case actionTypes.MatchEvents.ADD_LEADING_GUID: {
			const { matchEventId, leadingMatchEventId } = action;

			return state
				.setIn(['entities', matchEventId, 'leadingGuid'], leadingMatchEventId)
				.setIn(['modes', matchEventId, 'handled'], true);
		}

		case actionTypes.MatchEvents.REMOVE_GUIDS: {
			const leadingGuids = action.matchEventGuids
				.map((id) => state.getIn(['entities', id, 'leadingGuid']))
				.filter((guid) => guid);

			return state
				.update('entities', (entities) => {
					for (let guidIndex in leadingGuids) {
						entities = entities.delete(leadingGuids[guidIndex]);
					}

					for (let index in action.matchEventGuids) {
						entities = entities.delete(action.matchEventGuids[index]);
					}

					return entities;
				})
				.update('modes', (modes) => {
					for (let guidIndex in leadingGuids) {
						modes = modes.delete(leadingGuids[guidIndex]);
					}

					for (let index in action.matchEventGuids) {
						modes = modes.delete(action.matchEventGuids[index]);
					}
					return modes;
				});
		}

		case actionTypes.MatchEvents.UPDATE_MINUTE: {
			const { matchEventId, minute, extraMinute } = action;

			return state
				.setIn(['entities', matchEventId, 'minute'], minute)
				.setIn(['entities', matchEventId, 'extraMinute'], extraMinute)
				.setIn(['modes', matchEventId, 'handled'], true);
		}

		case actionTypes.MatchEvents.UPDATE_COMMENT: {
			const { matchEventId, comment } = action;

			return state
				.setIn(['entities', matchEventId, 'comment'], comment)
				.setIn(['modes', matchEventId, 'handled'], true);
		}

		case actionTypes.MatchEvents.UPDATE_USER: {
			const { matchEventId, userId } = action;

			return state.setIn(['edit', 'changes', matchEventId, 'userId'], userId);
		}

		case actionTypes.MatchEvents.UPDATE_POSITION: {
			const { matchEventId, positionId } = action;

			return state.setIn(
				['edit', 'changes', matchEventId, 'positionId'],
				positionId
			);
		}

		case actionTypes.MatchEvents.UPDATE_SUBSTITUTION_USER: {
			const { matchEventId, userId } = action;

			return state.setIn(
				['edit', 'changes', matchEventId, 'substitutionUserId'],
				userId
			);
		}

		case actionTypes.MatchEvents.EDIT_MODAL_OPEN: {
			const { eventType, eventId } = action;

			return state.set('edit', {
				activeModal: true,
				eventType,
				eventId,
				changes: new Map({}),
			});
		}

		case actionTypes.MatchEvents.EDIT_DONE: {
			const { changes } = state.edit;

			const updatedEntities = state.entities.withMutations((events) => {
				for (const [key, values] of changes.entries()) {
					for (const change of values.entries()) {
						events.setIn([key, change[0]], change[1]);
					}

					state = state
						.setIn(['modes', key, 'persisted'], false)
						.setIn(['modes', key, 'handled'], true);
				}
			});

			state = state.set('entities', updatedEntities);
			state = state.set('edit', initialState().get('edit'));

			return state;
		}

		case actionTypes.MatchEvents.EDIT_CANCEL: {
			return state.set('edit', initialState().get('edit'));
		}

		case actionTypes.MatchEvents.SET_COORDINATES: {
			const {
				matchEventId,
				coordinates: { x },
			} = action;

			return state.setIn(['entities', matchEventId, 'x'], x);
		}

		case actionTypes.REMOVE_MATCH: {
			return state.set(
				'entities',
				state
					.get('entities')
					.filter((entity) => entity.matchId !== action.matchId)
			);
		}

		default:
			return state;
	}
};

export default matchEvents;
