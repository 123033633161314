export default {
	name: 'area',
	viewBox: '0 0 24 25',
	objects: [
		{
			type: 'path',
			attributes: {
				d: 'M17.0238 10.3359C16.783 10.0876 16.3865 10.0815 16.1382 10.3224C15.8899 10.5632 15.8838 10.9597 16.1247 11.208L16.8562 11.9623H12.8184L12.8143 11.9623H12.6154L12.6154 7.72149L13.3697 8.45308C13.618 8.69389 14.0145 8.68782 14.2553 8.43953C14.4962 8.19123 14.4901 7.79473 14.2418 7.55391L12.4251 5.79201C12.1822 5.55639 11.796 5.55639 11.5531 5.79201L9.73643 7.55391C9.48813 7.79473 9.48206 8.19123 9.72288 8.43953C9.96369 8.68782 10.3602 8.69389 10.6085 8.45308L11.3628 7.72149L11.3628 11.7621L11.3628 11.7635L11.3628 11.9623H7.12201L7.8536 11.2079C8.09441 10.9597 8.08834 10.5631 7.84005 10.3223C7.59175 10.0815 7.19524 10.0876 6.95443 10.3359L5.19253 12.1525C4.95691 12.3955 4.95691 12.7817 5.19253 13.0246L6.95443 14.8413C7.19525 15.0896 7.59175 15.0956 7.84005 14.8548C8.08834 14.614 8.09441 14.2175 7.85359 13.9692L7.12201 13.2149H11.1598L11.164 13.2149H11.3628L11.3628 17.4557L10.6085 16.7241C10.3602 16.4833 9.96368 16.4894 9.72286 16.7377C9.48205 16.986 9.48812 17.3825 9.73642 17.6233L11.5531 19.3852C11.796 19.6208 12.1822 19.6208 12.4251 19.3852L14.2418 17.6233C14.4901 17.3825 14.4961 16.986 14.2553 16.7377C14.0145 16.4894 13.618 16.4833 13.3697 16.7241L12.6154 17.4557V13.4151L12.6154 13.4138V13.2149H16.8562L16.1247 13.9692C15.8838 14.2175 15.8899 14.614 16.1382 14.8548C16.3865 15.0956 16.783 15.0896 17.0238 14.8413L18.7857 13.0246C19.0213 12.7817 19.0213 12.3955 18.7857 12.1526L17.0238 10.3359Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M17.7967 5.461H6.20334C5.46831 5.461 4.87245 6.05686 4.87245 6.79188V18.3852C4.87245 19.1202 5.46831 19.7161 6.20334 19.7161H17.7967C18.5317 19.7161 19.1276 19.1202 19.1276 18.3852V6.79188C19.1276 6.05686 18.5317 5.461 17.7967 5.461ZM6.20334 4.44327C4.90623 4.44327 3.85472 5.49478 3.85472 6.79188V18.3852C3.85472 19.6823 4.90623 20.7338 6.20334 20.7338H17.7967C19.0938 20.7338 20.1453 19.6823 20.1453 18.3852V6.79188C20.1453 5.49478 19.0938 4.44327 17.7967 4.44327H6.20334Z',
			},
		},
	],
};
