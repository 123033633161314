import { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { t } from '@transifex/native';

import * as actions from 'pkg/actions';
import * as models from 'pkg/api/models';
import { useCurrentOrganization } from 'pkg/identity';
import * as routes from 'pkg/router/routes';

import PostForm from 'containers/group/post/Form';

import RelativeDateTime from 'components/RelativeDateTime';
import Badge from 'components/Badge';
import {
	FormattedContent,
	FormattedContentMaxLength,
} from 'components/formatted-content';
import Icon from 'components/icon';
import Label from 'components/label';
import Avatar from 'components/avatar';

import * as ContextMenu from 'design/context_menu';
import * as Table from 'design/table';

const PublishedName = styled.div`
	display: grid;
	grid-auto-flow: column;
	align-items: center;
	grid-gap: 10px;
	justify-content: start;

	${Badge} {
		width: 30px;
	}
`;
const CheckIcon = styled(Icon)`
	height: 20px;
	width: 20px;
	color: var(--palette-green-500);
`;
const CrossIcon = styled(Icon)`
	height: 20px;
	width: 20px;
	color: var(--palette-red-500);
`;
interface ListItemProps {
	post: models.groupPost.GroupPost;
	isScheduled: boolean;
	remove: (id: number) => void;
	refresh: () => void;
}

const ListItem: React.FC<React.PropsWithChildren<ListItemProps>> = ({
	post,
	remove,
	refresh,
}) => {
	const dispatch = useDispatch();
	const [editing, setEditing] = useState(false);
	const org = useCurrentOrganization();

	const handleDeleteClick = () => {
		if (
			window.confirm(
				t('Do you want to delete this?', { _context: 'confirm messages' })
			)
		) {
			dispatch(actions.posts.deleteGroupPost(post.id));
			remove(post.id);
		}
	};

	const openPostModal = () => {
		setEditing(true);
	};

	const closePostModal = async (anim: Promise<any>) => {
		await anim;
		refresh();
		setEditing(false);
	};

	if (!post.id) {
		return <span>-</span>;
	}

	return (
		<Fragment>
			<Table.Row>
				<Table.LinkCell
					linkStyle
					href={routes.Group.Post.Show(org.id, post.id)}>
					{post.title ? (
						<FormattedContent
							raw={post.title}
							maxLength={FormattedContentMaxLength.Standard}
						/>
					) : (
						<em>{t(`No title`)}</em>
					)}
				</Table.LinkCell>
				<Table.Cell>
					<PublishedName>
						<Avatar size={30} user={post.author} />
						{models.user.fullName(post.author)}
					</PublishedName>
				</Table.Cell>
				<Table.Cell>
					{post.authorGroupId ? (
						<PublishedName>
							<Badge badgeUrl={post.authorGroup.profileImageUrl} />
							{post.authorGroup.name}
						</PublishedName>
					) : (
						<PublishedName>
							<span>-</span>
						</PublishedName>
					)}
				</Table.Cell>
				<Table.Cell align="center">
					{models.groupPost.isPublished(post) ? (
						<Label color="green">{t(`Published`)}</Label>
					) : (
						<span>-</span>
					)}
				</Table.Cell>
				<Table.Cell>
					{post.createdAt != 0 ? (
						<RelativeDateTime dateTime={post.createdAt} />
					) : (
						<span>-</span>
					)}
				</Table.Cell>
				<Table.Cell>
					{post.publishedAt != 0 ? (
						<RelativeDateTime dateTime={post.publishedAt} />
					) : (
						<span>-</span>
					)}
				</Table.Cell>
				<Table.Cell>
					{post.isSticky ? (
						<RelativeDateTime dateTime={post.stickyUntil} />
					) : (
						<span>-</span>
					)}
				</Table.Cell>
				<Table.Cell align="center">
					{post.visibleToUsers ? (
						<CheckIcon name="check-circle" />
					) : (
						<CrossIcon name="close-circle" />
					)}
				</Table.Cell>
				<Table.Cell align="center">
					{post.visibleToLegalGuardians ? (
						<CheckIcon name="check-circle" />
					) : (
						<CrossIcon name="close-circle" />
					)}
				</Table.Cell>
				<Table.Cell align="center">
					{post.isPublic ? (
						<CheckIcon name="check-circle" />
					) : (
						<CrossIcon name="close-circle" />
					)}
				</Table.Cell>
				<Table.Cell align="right">
					{post.recipientCount > 0 ? (
						<Fragment>{post.recipientCount}</Fragment>
					) : (
						<span>0</span>
					)}
				</Table.Cell>
				{post.copyCount > 0 ? (
					<Table.LinkCell
						align="right"
						linkStyle
						href={routes.Group.Post.ListCopies(org.id, post.id)}>
						{post.copyCount}
					</Table.LinkCell>
				) : (
					<Table.Cell align="right">
						<span>-</span>
					</Table.Cell>
				)}
				<Table.Cell align="right">{post.viewCount}</Table.Cell>
				<Table.Cell>
					{post.commentsDisabled ? (
						<Label color="red">{t('Disabled')}</Label>
					) : (
						<Label color="green">{t('Enabled')}</Label>
					)}
				</Table.Cell>
				<Table.ActionCell>
					<ContextMenu.Menu toggleWith={<Icon name="context-menu" />}>
						{models.groupPost.hasCopies(post) && (
							<ContextMenu.LinkItem
								href={routes.Group.Post.ListCopies(org.id, post.id)}>
								<ContextMenu.ItemIcon name="visibility" />
								{t(`Show copies`)}
							</ContextMenu.LinkItem>
						)}

						<ContextMenu.LinkItem
							href={routes.Group.Post.Show(org.id, post.id)}>
							<ContextMenu.ItemIcon name="visibility" />
							{t('View')}
						</ContextMenu.LinkItem>
						{post.links?.edit && (
							<ContextMenu.Item onClick={openPostModal}>
								<ContextMenu.ItemIcon name="edit" /> {t('Edit')}
							</ContextMenu.Item>
						)}
						{post.links?.delete && (
							<ContextMenu.Item caution onClick={handleDeleteClick}>
								<ContextMenu.ItemIcon name="delete" /> {t('Delete')}
							</ContextMenu.Item>
						)}
					</ContextMenu.Menu>
				</Table.ActionCell>
			</Table.Row>
			{editing && (
				<PostForm
					isAdminOrStaffForGroup
					id={post.id}
					onClose={closePostModal}
				/>
			)}
		</Fragment>
	);
};

export default ListItem;
