import { ReactNode } from 'react';

export const symbol_webhook: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M270-120q-78 0-134-56T80-310q0-54 25.5-97t67.5-67q17-11 32-3t15 26q0 13-6.5 19.5T198-419q-26 17-42 46t-16 63q0 54 38 92t92 38q54 0 92-38t38-92q0-13 8.5-21.5T430-340h219q8-9 19-14.5t22-5.5q21 0 35.5 14.5T740-310q0 20-14.5 35T690-260q-11 0-22-5.5T649-280H458q-14 69-66.5 114.5T270-120Zm0-140q-20 0-35-15t-15-35q0-18 12.5-31.5T264-360l111-187q-30-29-47.5-65.5T310-690q0-79 56-134.5T500-880q66 0 117 40.5T684-737q4 16-5.5 26.5T655-700q-11 0-18.5-7T626-725q-11-42-46-68.5T500-820q-54 0-92 38t-38 92q0 34 16.5 63.5T430-580q11 8 14.5 18.5T442-541L316-329q2 6 3 10.5t1 8.5q0 20-14.5 35T270-260Zm420 140q-26 0-52-6.5T593-146q-19-13-13-33.5t27-20.5q8 0 14.5 2.5T634-193q17 7 30 10t26 3q54 0 92-38t38-92q0-54-38-92t-92-38q-11 0-20.5 1.5T651-434q-13 4-24.5 0T609-448L495-640q-20-4-32.5-17.5T450-690q0-21 15-35.5t35-14.5q21 0 35.5 14.5T550-690q0 4-.5 7.5T547-672l103 176q11-2 21.5-3t18.5-1q79 0 134.5 55.5T880-310q0 78-55.5 134T690-120Z"
		/>
	</svg>
);
