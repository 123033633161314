import { ReactNode } from 'react';

export const symbol_microwave: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M410-530q-18 0-34.5-7T344-554q-8-5-16.048-10.5Q319.905-570 310-570q-11 0-20.391 5.789Q280.217-558.421 272-550q-5 5-9.923 7T251-541q-13.175 0-22.087-8.625Q220-558.25 220-571q0-6 2.5-11.5T230-593q17-17 36.895-27 19.894-10 43.105-10 18 0 34.49 7.407Q360.981-615.185 377-605l16 10q8 5 17 5 14 0 38-20 5-5 10.385-7 5.384-2 11.538-2 13.077 0 21.577 8.625Q500-601.75 500-589q0 6-2.5 11t-6.5 10q-17 17-37.5 27.5T410-530Zm0 200q-18 0-34.5-7T344-354q-8-5-16.048-10.5Q319.905-370 310-370q-11 0-20.391 5.789Q280.217-358.421 272-350q-5 5-9.923 7T251-341q-13.175 0-22.087-8.625Q220-358.25 220-371q0-6 2.5-11.5T230-393q17-17 36.895-27 19.894-10 43.105-10 18 0 34.49 7.407Q360.981-415.185 377-405l16 10q8 5 17 5 17.2 0 30.1-14.5Q453-419 470.143-419q12.857 0 21.357 8.5T500-389q0 6-2.5 11t-6.5 10q0 .286-.273.554-.272.267-.727.446-17 17-36.895 27-19.894 10-43.105 10ZM140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm0-60h440v-520H140v520Zm500 0h180v-520H640v520Zm75-400h30q6 0 10.5-4.5T760-635v-30q0-6-4.5-10.5T745-680h-30q-6 0-10.5 4.5T700-665v30q0 6 4.5 10.5T715-620Zm15.5 170q12.5 0 21-9t8.5-21.5q0-12.5-8.625-21T730-510q-12 0-21 8.625T700-480q0 12 9 21t21.5 9Zm0 170q12.5 0 21-9t8.5-21.5q0-12.5-8.625-21T730-340q-12 0-21 8.625T700-310q0 12 9 21t21.5 9ZM640-220v-520 520Z"
		/>
	</svg>
);
