import { omit } from 'pkg/objects';
import { isApp } from 'pkg/platform';
import { fromEntries } from 'pkg/utils';
type ParserParamsType = { [key: string]: boolean | string };

function unserializeValue(val: string): boolean | string {
	if (val === 'true') return true;

	if (val === 'false') return false;

	return val;
}

function unserialize(unresolvedString: string): ParserParamsType {
	const unserializedObject: ParserParamsType = {};
	const urlSearchParams: URLSearchParams = new URLSearchParams(
		unresolvedString
	);

	for (const [key, value] of urlSearchParams.entries()) {
		const decodedKey: string = decodeURIComponent(key);
		const decodedValue: boolean | string = unserializeValue(
			decodeURIComponent(value)
		);

		unserializedObject[decodedKey] = decodedValue;
	}

	return unserializedObject;
}

function associate(
	keys: string[],
	values: (boolean | string)[]
): ParserParamsType {
	const targetObject: ParserParamsType = {};

	if (keys.length !== values.length) {
		throw new RangeError('Keys and value arrays must match in size.');
	}

	return keys.reduce((target, key, index) => {
		target[key] = values[index];
		return target;
	}, targetObject);
}

export function serialize(unresolvedObject: ParserParamsType): string {
	const parameterSegments: string[] = [];

	for (const [key, value] of Object.entries(unresolvedObject)) {
		const encodedKey: string = encodeURIComponent(key);

		if (value === null) {
			parameterSegments.push(encodedKey);
		} else {
			const encodedValue: string = encodeURIComponent(value);

			parameterSegments.push(`${encodedKey}=${encodedValue}`);
		}
	}

	return parameterSegments.join('&');
}

export function absoluteURL(path: string): string {
	const origin = window.location.origin;
	const correctedPath = path.startsWith('/') ? path : `/${path}`;
	const url = origin + correctedPath;
	return url;
}

export function shareableUrl(url: string): string {
	const urlParts = url.split(window.location.origin);
	// any "window.location.origin" will be an empty string after the split, remove them.
	const route = urlParts.filter((part) => part)[0];

	return isApp()
		? `https://app.360Player.com${route}`
		: `${window.location.origin}${route}`;
}

export const params = (): URLSearchParams =>
	new URLSearchParams(window.location.search);

export const query = (): Record<string, unknown> => fromEntries(params());

type ParserResultType = {
	match: boolean;
	path: string;
	pattern: string;
	params: ParserParamsType;
	query: ParserParamsType;
};

const REGEX_LOOKUP_PATTERN: RegExp = /(\:[^\/]+)/g;

const REGEX_REPLACE_PATTERN: RegExp = /\:([^\/]+)/;

const REGEX_LOOKUP_CAPTURE: string = '([^/]+)';

const REGEX_LOOKUP_QUERY: RegExp = /\S[^?]*(?:\?+|$)/g;

/**
 *	URL parser and matcher
 */
export class UrlParser {
	/**
	 *	Removes excessive forward slashes.
	 */
	clean(uriPath: string): string {
		return uriPath.replace(/\/+/g, '/').replace(/\/+$/, '');
	}

	/**
	 *	Converts URI path to RegExp.
	 */
	getPatternRegExp(uriPattern: string): RegExp {
		let pattern: string = this.clean(`/${uriPattern}`)
			.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
			.replace(REGEX_LOOKUP_PATTERN, () => {
				return REGEX_LOOKUP_CAPTURE;
			});

		if (pattern.length === 0) {
			pattern = '\\/';
		}

		return new RegExp(`^${pattern}$`, 'i');
	}

	/**
	 *	Transforms URI path and populates parameters in that path.
	 */
	transform(uriPattern: string, uriParams: ParserParamsType = {}): string {
		const omitKeys: string[] = [];

		const replaceMatch = (match: string, key: string): string => {
			if (uriParams.hasOwnProperty(key) === true) {
				omitKeys.push(key);

				if (uriParams[key] === null) {
					return '';
				}

				return uriParams[key]?.toString();
			}

			return '';
		};

		let queryString: string;
		let queryParams: ParserParamsType = {};
		const queryPosition: number = uriPattern.indexOf('?');
		const hasQueryInPattern: boolean = queryPosition > -1;

		if (hasQueryInPattern) {
			queryString = uriPattern.substring(queryPosition + 1);
			uriPattern = uriPattern.substring(0, queryPosition);

			queryParams = unserialize(queryString);
		}

		const transformedUrl: string = this.clean(uriPattern).replace(
			REGEX_REPLACE_PATTERN,
			replaceMatch
		);

		queryString = serialize(
			Object.assign({}, queryParams, omit(uriParams, ...omitKeys))
		);
		const urlSuffix: string = queryString.length > 0 ? `?${queryString}` : '';

		return this.clean(transformedUrl + urlSuffix);
	}

	/**
	 *	Parses URI using URI pattern.
	 */
	parse(uriPattern: string, uriPath: string): ParserResultType {
		uriPath = this.clean(uriPath);

		if (uriPath.length === 0) {
			uriPath += '/';
		}

		const uriPatternRegex: RegExp = this.getPatternRegExp(uriPattern);
		const parameterMatches: string[] = uriPattern.match(REGEX_LOOKUP_PATTERN);
		const uriMatches: string[] = uriPath.match(REGEX_LOOKUP_QUERY);

		const parserResult: ParserResultType = {
			match: false,
			path: uriPath,
			pattern: uriPattern,
			params: {},
			query: {},
		};

		if (uriMatches !== null && uriMatches !== undefined) {
			if (uriMatches.length <= 1) {
				uriMatches.push('', '');
			}

			const [parsedUriPath, parsedUriQuery] = uriMatches.map((match) =>
				match.replace('?', '')
			);
			const parsedUriMatches: string[] = parsedUriPath.match(uriPatternRegex);

			// @NOTE Update 'path' without eventual query string
			if (typeof parsedUriPath === 'string') {
				parserResult.path = parsedUriPath;
			}

			// @NOTE Unserialize query string if it exists
			if (typeof parsedUriQuery === 'string' && parsedUriQuery.length > 0) {
				const uriQueryObject: ParserParamsType = unserialize(parsedUriQuery);
				parserResult.query = uriQueryObject;
			}

			if (parsedUriMatches !== null && parsedUriMatches !== undefined) {
				parserResult.match = true;

				// @NOTE Filter out any non strings and remove full match from result
				const filteredMatches: string[] = parsedUriMatches
					.filter((match) => typeof match === 'string')
					.slice(1);

				const hasFilteredMatches: boolean =
					filteredMatches !== null && filteredMatches !== undefined;
				const hasParameterMatches: boolean =
					parameterMatches !== null && parameterMatches !== undefined;

				if (hasFilteredMatches === true && hasParameterMatches === true) {
					const keys: string[] = parameterMatches.map((match) =>
						match.replace(':', '')
					);
					const values: (boolean | string)[] =
						filteredMatches.map(unserializeValue);

					const matchParams: ParserParamsType = associate(keys, values);

					parserResult.params = matchParams;
				}
			}
		}

		return parserResult;
	}
}
