import { List } from 'immutable';

import TrainingCollection, {
	TrainingCollectionExercise,
	TrainingCollectionSession,
} from 'pkg/models/training_collection';

import { RootState } from 'pkg/reducers';
import { getOrganizationIdentityContextValues } from 'pkg/identity/organization';

const emptyCollection = new TrainingCollection();

const findAll = (state: RootState) => state.trainingCollections.entities;

const getTrainingCollectionExercises = (state: RootState) =>
	state.trainingCollections.exercises;

const getTrainingCollectionSessions = (state: RootState) =>
	state.trainingCollections.sessions;

export const findAllIdsByExercise = (
	state: RootState,
	itemInfo: { id: number; type: string }
): List<number> => {
	const items = getTrainingCollectionExercises(state);

	return items
		.filter((e: TrainingCollectionExercise) => e.exerciseId === itemInfo.id)
		.map((e: TrainingCollectionExercise) => e.trainingCollectionId)
		.toList();
};

export const findAllIdsBySession = (
	state: RootState,
	itemInfo: { id: number; type: string }
): List<number> => {
	const items = getTrainingCollectionSessions(state);

	return items
		.filter(
			(e: TrainingCollectionSession) => e.trainingSessionId === itemInfo.id
		)
		.map((e: TrainingCollectionSession) => e.trainingCollectionId)
		.toList();
};

export const findAllIdsByType = (
	state: RootState,
	itemInfo: { id: number; type: string }
): List<number> => {
	const collectionExerciseIds = findAllIdsByExercise(state, itemInfo);
	const collectionSessionIds = findAllIdsBySession(state, itemInfo);

	return itemInfo.type === 'exercises'
		? collectionExerciseIds
		: collectionSessionIds;
};

// finds one training collection
export const find = (
	state: RootState,
	{ collectionId }: { collectionId: number }
): TrainingCollection => {
	const entities = findAll(state);

	return entities.get(collectionId) || emptyCollection;
};

// finds all collections a user created
export const findAllUserCollections = (
	state: RootState
): List<TrainingCollection> => {
	const collections = findAll(state);
	const { user } = getOrganizationIdentityContextValues();
	const accountId = user.accountId;

	return collections
		.filter(
			(collection: TrainingCollection) =>
				!collection.groupId && collection.accountId === accountId
		)
		.toList();
};

export const findAllInGroup = (
	state: RootState,
	{
		groupId,
		includeInheriting = false,
	}: { groupId: number; includeInheriting?: boolean }
): List<TrainingCollection> => {
	const collections = findAll(state);

	return collections
		.filter(
			(collection: TrainingCollection) =>
				collection.groupId === groupId &&
				collection.inherit === includeInheriting
		)
		.toList();
};

export const findAllUnconnected = (
	state: RootState,
	collections: List<TrainingCollection>,
	itemInfo: { id: number; type: string }
): List<TrainingCollection> => {
	const excludeMap = findAllIdsByType(state, itemInfo);

	return collections.filter(
		(collection) => !excludeMap.includes(collection.id)
	);
};
