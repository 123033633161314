import { ReactNode } from 'react';

export const symbol_garage_home: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480.197-825q9.803 0 18.717 3 8.915 3 17.086 9l260 195q11.25 8.25 17.625 21T800-570v420q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T740-150v-420L480-765 220-570v420q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T160-150v-420q0-14.25 6.375-27T184-618l260-195q8.295-6 17.344-9 9.049-3 18.853-3ZM340-180h280v-110H340v110Zm0-170h280v-110H340v110Zm-30 230q-12.75 0-21.375-8.625T280-150v-340q0-12.75 8.625-21.375T310-520h340q12.75 0 21.375 8.625T680-490v340q0 12.75-8.625 21.375T650-120H310Z"
		/>
	</svg>
);
