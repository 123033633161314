import { Fragment } from 'react/jsx-runtime';
import { useT } from '@transifex/react';

import DateTime from 'pkg/datetime';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useEndpoint } from 'pkg/api/use_endpoint';

import * as ActionBar from 'components/layout/ActionBar';
import { Spinner } from 'components/loaders/spinner';

import * as Table from 'design/table';
import Button from 'design/button';

interface PeriodSum {
	periodSum: { [key: string]: number };
}

interface Entity extends PeriodSum {
	title: string;
	total: number;
}

interface RevenueDashboardResponse extends PeriodSum {
	entities: { [key: string]: Entity };
	showAs: string;
}

interface RevenueViewProps {
	organizationId: number;
}

export default function RevenueView({ organizationId }: RevenueViewProps) {
	const t = useT();
	// note: This will most likely be changed into a collection going forward
	const { record, isLoading } = useEndpoint<RevenueDashboardResponse>(
		endpoints.Organizations.RevenueDashboard(organizationId)
	);

	if (isLoading) {
		return <Spinner />;
	}

	const getDateColumns: Table.HeaderColumn[] = Object.keys(
		record.periodSum
	).map((p) => {
		return {
			content: new DateTime(new Date(p)).toLocaleDateString({
				year: 'numeric',
				month: 'short',
			}),
		};
	});

	return (
		<Fragment>
			<ActionBar.Bar>
				<ActionBar.PrimaryAction>
					<ActionBar.Search placeholder="Search" />
				</ActionBar.PrimaryAction>
				<Button label="Products" />
				<Button label="Filter" icon="filter" />
				<Button label="Export" />
			</ActionBar.Bar>
			<Table.Table
				columns={[
					{
						content: t('Product'),
					},
					{
						content: t('Total'),
					},
					...getDateColumns,
				]}>
				{Object.values(record.entities).map((value, i) => {
					return (
						<Table.Row key={i}>
							<Table.Cell>
								<span>{value.title}</span>
							</Table.Cell>
							<Table.Cell>{value.total}</Table.Cell>
							{Object.values(value.periodSum).map((v, i) => {
								return (
									<Table.Cell key={i}>
										<span>{v}</span>
									</Table.Cell>
								);
							})}
						</Table.Row>
					);
				})}
				<Table.Row>
					<Table.Cell>{t('Total')}</Table.Cell>
					<Table.Cell>TBI</Table.Cell>
					{Object.values(record.periodSum).map((v, i) => (
						<Table.Cell key={i}>{v}</Table.Cell>
					))}
				</Table.Row>
			</Table.Table>
		</Fragment>
	);
}
