import { ReactNode } from 'react';

export const symbol_window_sensor: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M889.825-620Q877-620 868.5-628.625T860-650v-160q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T920-810v160q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM120-180v-600q0-24.75 17.625-42.375T180-840h520q24.75 0 42.375 17.625T760-780v600q0 24.75-17.625 42.375T700-120H180q-24.75 0-42.375-17.625T120-180Zm60-330h220v-25q0-6 4.5-10.5T415-550h50q6 0 10.5 4.5T480-535v25h220v-270H180v270Zm0 330h520v-270H180v270Zm0 0h520-520Z"
		/>
	</svg>
);
