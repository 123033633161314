import { ReactNode } from 'react';

export const symbol_format_strikethrough: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M110-410q-12.75 0-21.375-8.675Q80-427.351 80-440.175 80-453 88.625-461.5T110-470h740q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-410H110Zm320-120v-170H250q-20.833 0-35.417-14.618Q200-729.235 200-750.118 200-771 214.583-785.5 229.167-800 250-800h460q20.833 0 35.417 14.618Q760-770.765 760-749.882 760-729 745.417-714.5 730.833-700 710-700H530v170H430Zm0 180h100v140q0 20.833-14.618 35.417Q500.765-160 479.882-160 459-160 444.5-174.583 430-189.167 430-210v-140Z"
		/>
	</svg>
);
