import { ReactNode } from 'react';

export const symbol_eco: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M213-175q-43.594-45-68.297-104Q120-338 120-400q0-73 25.5-133.5T222-645q62-62 180-90.5T700-759q20 1 36 8t29 20q13 13 20.5 29t8.5 36q3 90-2 164.5T772.5-367Q758-307 735-261t-55 78q-51 51-110 77T444-80q-69 0-126.5-23.5T213-175Zm103 0q25 17 58 26t69.923 9Q497-140 547-162t91-64q27-27 46-70.5t31-103Q727-459 731-534t0-165q-94-2-168.5 2.5T431-680q-57 12-98 30.5T266-604q-42 43-64 91t-22 98q0 48 20.5 100.5T251-230q53-98 127-176t157-123q-87 75-141 162.5T316-175Zm0 0Zm0 0Z"
		/>
	</svg>
);
