import { ReactNode } from 'react';

export const symbol_lowercase: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M334-240q-51 0-83.5-26T218-336q0-47 35.5-75.5T346-440q26 0 48.5 4.5T433-423v-18q0-38-20.5-58.5T353-520q-17 0-34 5t-31 16q-8 6-16 3.5t-13-9.5q-5-7-3.5-15.5T266-535q20-13 42-19t45-6q62 0 93.5 31t31.5 93v164q0 9-6.5 15.5T456-250q-9 0-15.5-6.5T434-272v-20h-4q-16 26-43.5 39T334-240Zm0-38q36 0 68-30t32-76q-16-8-38-13t-43-5q-42 0-65.5 17.5T264-336q0 26 20.5 42t49.5 16Zm340-76v-256q0-13 8.5-21.5T704-640q13 0 21.5 8.5T734-610v256l53-53q9-9 21-9t21 9q9 9 9 21t-9 21L725-261q-9 9-21 9t-21-9L579-365q-9-9-9-21t9-21q9-9 21-9t21 9l53 53Z"
		/>
	</svg>
);
