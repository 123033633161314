import { ReactNode } from 'react';

export const symbol_file_download_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m792-83-78-77H220q-24 0-42-18t-18-42v-113q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T220-333v113h434L521-353l-20 19q-5 5-9.5 7t-11.5 2q-6.957 0-11.478-2-4.522-2-9.757-7.235L308-485q-9-9-8-22t9-21q7-7 14.5-8t16.5 1L83-792q-9-9-9-21.526 0-12.527 9.053-21.369Q91.35-843 104.175-843T126-835l709 710q8 8.311 8 21.156Q843-91 834.702-82.87 825.649-74 812.825-74 800-74 792-83ZM652-529q9 9 9 22.222 0 13.222-9 21.778l-24 24q-9 9-22 9t-22-9q-9-9-8.5-22t9.5-22l24-24q9-9 21.5-9t21.5 9ZM480.175-800q12.825 0 21.325 8.625T510-770v162q0 14.872-9.318 21.936t-20.5 7.064Q469-579 459.5-586.25 450-593.5 450-608v-162q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625Z"
		/>
	</svg>
);
