import { ReactNode } from 'react';

export const symbol_tablet_android: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-40q-24.75 0-42.375-17.625T120-100v-760q0-24.75 17.625-42.375T180-920h600q24.75 0 42.375 17.625T840-860v760q0 24.75-17.625 42.375T780-40H180Zm0-150v90h600v-90H180Zm238.235 65h123.53Q550-125 556-131t6-14q0-8-6-14t-14.235-6h-123.53Q410-165 404-159t-6 14q0 8 6 14t14.235 6ZM180-250h600v-520H180v520Zm0-580h600v-30H180v30Zm0 0v-30 30Zm0 640v90-90Z"
		/>
	</svg>
);
