import { memo, Fragment, useState } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import * as actions from 'pkg/actions';
import * as routes from 'pkg/router/routes';
import { useCurrentOrganization } from 'pkg/identity';

import Badge from 'components/Badge';
import Icon from 'components/icon';

import * as ContextMenu from 'design/context_menu';
import * as Table from 'design/table';

interface ListItemProps {
	groupPost: models.groupPost.GroupPost;
	remove: (id: number) => void;
}
const PublishedName = styled.div`
	display: grid;
	grid-auto-flow: column;
	align-items: center;
	grid-gap: 10px;
	justify-content: start;

	${Badge} {
		width: 30px;
	}
`;

const ListItem = memo(({ groupPost, remove }: ListItemProps): JSX.Element => {
	const org = useCurrentOrganization();

	const handleDeleteClick = () => {
		if (
			window.confirm(
				t('Do you want to delete this?', { _context: 'confirm messages' })
			)
		) {
			actions.posts.deleteGroupPost(groupPost.id);
			remove(groupPost.id);
		}
	};
	const [editing, setEditing] = useState<boolean>(false);
	const togglePostModal = async (anim: any) => {
		await anim;
		setEditing(!editing);
	};

	const viewPercentage = (a: number, b: number) => {
		if (b > 0) {
			const percentage = Math.round((a / b) * 100);
			return Math.min(percentage, 100);
		}
		return 0;
	};

	const contextMenu = (
		<ContextMenu.Menu toggleWith={<Icon name="context-menu" />}>
			<ContextMenu.LinkItem href={routes.Group.Post.Show(org.id, groupPost.id)}>
				<ContextMenu.ItemIcon name="visibility" /> {t('View')}
			</ContextMenu.LinkItem>
			{groupPost.links?.edit && (
				<ContextMenu.Item onClick={togglePostModal}>
					<ContextMenu.ItemIcon name="edit" /> {t('Edit')}
				</ContextMenu.Item>
			)}
			{groupPost.links?.delete && (
				<ContextMenu.Item caution onClick={handleDeleteClick}>
					<ContextMenu.ItemIcon name="delete" /> {t('Delete')}
				</ContextMenu.Item>
			)}
		</ContextMenu.Menu>
	);
	const content = (
		<Fragment>
			<Table.Row>
				<Table.LinkCell
					href={routes.Group.Post.Show(org.id, groupPost.id)}
					linkStyle>
					<PublishedName>
						<Badge badgeUrl={groupPost.group.profileImageUrl} />
						{groupPost.group.name}
					</PublishedName>
				</Table.LinkCell>
				<Table.Cell align="right"> {groupPost.recipientCount}</Table.Cell>
				<Table.Cell align="right">{groupPost.viewCount}</Table.Cell>
				<Table.Cell align="right">{groupPost.likeCount}</Table.Cell>
				<Table.Cell align="right">{groupPost.commentCount}</Table.Cell>
				<Table.Cell align="right">
					{viewPercentage(groupPost.viewCount, groupPost.recipientCount)}%
				</Table.Cell>
				<Table.Cell> {contextMenu}</Table.Cell>
			</Table.Row>
		</Fragment>
	);

	if (!groupPost.id) {
		return null;
	}

	return <Fragment>{content}</Fragment>;
});

export default ListItem;
