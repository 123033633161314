import { List, Record } from 'immutable';

import * as actionTypes from 'pkg/actions/action-types';

interface ICurrencyReducer {
	items: List<string>;
	defaultCurrency: string;
}

class CurrencyReducer
	extends Record<ICurrencyReducer>(
		{
			items: List<string>(),
			defaultCurrency: '',
		},
		'currencyReducer'
	)
	implements ICurrencyReducer
{
	items: List<string>;
	defaultCurrency: '';
}

const initialState = new CurrencyReducer({
	items: List<string>(),
	defaultCurrency: '',
});

interface Action {
	type: string;
	payload: any;
}

const setItems = (state = initialState, action: Action): CurrencyReducer => {
	state = state.set('items', List<string>(action.payload.currencies));
	state = state.set('defaultCurrency', action.payload.defaultCurrency);

	return state;
};

const currenciesReducer = (
	state = initialState,
	action: Action
): CurrencyReducer => {
	switch (action.type) {
		case actionTypes.Currencies.SET_ITEMS: {
			return setItems(state, action);
		}

		default:
			return state;
	}
};

export default currenciesReducer;
