import { ReactNode } from 'react';

export const symbol_voice_selection: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M736.844-70Q724-70 715.5-78.625 707-87.25 707-100q0-6 2.182-12.095Q711.364-118.19 715-122q44-44 69.5-102.5T810-350q0-67.191-25.5-125.596Q759-534 715-578q-3.636-3.804-5.818-9.891Q707-593.978 707-600.065q0-12.935 8.625-21.435Q724.25-630 737-630q6 0 11.625 2.625T758-621q52 52 82 121.5t30 149.009Q870-270 840-200.5 810-131 758-79q-3.778 3.75-9.444 6.375Q742.889-70 736.844-70ZM623-184q-12.75 0-21.375-8.625T593-214q0-6 2.625-11.625T602-235q23-23 35.5-52.118 12.5-29.117 12.5-63Q650-384 637.5-413T602-465q-4-4-6.5-9.625T593-486.25q0-12.75 8.625-21.25T623-516q7 0 12.5 2t9.5 7q30 30 47.5 70.367 17.5 40.366 17.5 86.5Q710-304 692.5-263.5 675-223 645-193q-4 5-9.5 7t-12.5 2ZM310-365h-90v29q0 38 23.5 67.5T304-229l12 3q32.8 8.222 36.9 41.111Q357-152 327-135q-50.061 27.945-105.031 40.473Q167-82 110-80q-12 1-21-7.625T80-109q0-12.75 8.5-21.375T110-140q42-2 82.864-10.32Q233.728-158.64 274-174q-49-24-81.5-66T160-336v-59q0-12.75 8.625-21.375T190-425h130v-100q0-12.75 8.625-21.375T350-555h111L331-805q-6-11-1.5-23t15.5-17.5q11-5.5 22.5-2T385-833l130 251q16 30-1.44 58.5Q496.121-495 462-495h-82v60q0 28.875-20.562 49.438Q338.875-365 310-365Z"
		/>
	</svg>
);
