import { Map, Record } from 'immutable';

import Product from 'pkg/models/product';
import Price from 'pkg/models/price';

import * as actionTypes from 'pkg/actions/action-types';

interface IProductReducer {
	entities: Map<number, Product>;
}

class ProductReducer
	extends Record<IProductReducer>(
		{
			entities: Map<number, Product>(),
		},
		'productReducer'
	)
	implements IProductReducer
{
	entities: Map<number, Product>;
}

const initialState = new ProductReducer({
	entities: Map<number, Product>(),
});

interface NormalizrPayload {
	result: any;
	entities: {
		product: { [key: string]: Product };
		price: { [key: string]: Price };
	};
}

interface Action {
	type: string;
	payload?: NormalizrPayload;
	productId?: number;
}

const setItems = (state = initialState, action: Action): ProductReducer => {
	const entities = Object.values(action.payload.entities.product);

	entities.map((entity: Product) => {
		state = state.setIn(['entities', entity.id], new Product(entity));
	});

	return state;
};

const productReducer = (
	state = initialState,
	action: Action
): ProductReducer => {
	switch (action.type) {
		case actionTypes.Products.SET_ITEMS:
			return setItems(state, action);
		default:
			return state;
	}
};

export default productReducer;
