import { useEffect } from 'react';

interface EventChannel {
	on: (listener: EventListenerOrEventListenerObject) => void;
	off: (listener: EventListenerOrEventListenerObject) => void;
}

// The resume event behaves differently at different times on iOS (active/resume),
// and android simply has "resume".
export const resume: EventChannel = {
	on: (callback) => {
		document.addEventListener('resume', callback, false);
		document.addEventListener('active', callback, false);
	},

	off: (callback) => {
		document.removeEventListener('resume', callback);
		document.removeEventListener('active', callback);
	},
};

export function useResumeListener(
	listener: EventListenerOrEventListenerObject,
	deps?: any[]
): void {
	useEffect(() => {
		resume.on(listener);

		return () => {
			resume.off(listener);
		};
	}, deps ?? []);
}

export const resign: EventChannel = {
	on: (callback) => {
		document.addEventListener('resign', callback, false);
		document.addEventListener('pause', callback, false);
	},

	off: (callback) => {
		document.removeEventListener('resign', callback);
		document.removeEventListener('pause', callback);
	},
};

export function useResignListener(
	listener: EventListenerOrEventListenerObject,
	deps?: any[]
): void {
	useEffect(() => {
		resign.on(listener);

		return () => {
			resign.off(listener);
		};
	}, deps ?? []);
}

export const unload: EventChannel = {
	on: (callback) => {
		window.addEventListener('unload', callback);
	},

	off: (callback) => {
		window.removeEventListener('unload', callback);
	},
};

export function useUnloadListener(
	listener: EventListenerOrEventListenerObject,
	deps?: any[]
): void {
	useEffect(() => {
		unload.on(listener);

		return () => {
			unload.off(listener);
		};
	}, deps ?? []);
}
