import { t } from '@transifex/native';
import { Fragment, ReactElement, useContext, useState } from 'react';

import * as styles from 'pkg/config/styles';

import { eventTypeToTranslatedString } from 'pkg/models/event';

import * as models from 'pkg/api/models';
import DateTime from 'pkg/datetime';
import { isSameDay } from 'pkg/date';
import { cssClasses } from 'pkg/css/utils';
import useTooltip from 'pkg/hooks/useTooltip';

import { BookingsContext } from 'routes/scheduling/bookings';
import EditBookingModal from 'routes/scheduling/bookings/modals/EditBooking';
import { getItemResourceIds, getItemResources } from 'routes/scheduling/utils';

import Icon from 'components/icon';

import * as gridItemStyles from 'components/scheduling/grid/grid-item/styles.css';
import Row from 'components/layout/row';
import { SchedulingViews } from 'components/scheduling/SelectView';
import { getGridItemCssVars } from 'components/scheduling/grid/config';
import Column from 'components/layout/column';

interface BookingProps {
	day: Date;
	booking: models.booking.Booking;
}

const GridItem = ({ day, booking }: BookingProps) => {
	const [modal, setModal] = useState('');
	const { tooltip: overlapTooltip, onMouseEnter: overlapOnMouseEnter } =
		useTooltip(t(`Overlapping bookings`));
	const { tooltip: conflictTooltip, onMouseEnter: conflictOnMouseEnter } =
		useTooltip(t(`Resource has conflicts`));

	const startDate = new Date(booking.startsAt * 1000);
	const endDate = new Date(booking.endsAt * 1000);
	const start = new DateTime(startDate);
	const end = new DateTime(endDate);
	const dayDateTime = new DateTime(day);

	const multiDayBooking = !isSameDay(startDate, end);
	const isFirstDay = isSameDay(startDate, dayDateTime);
	const isLastDay = isSameDay(endDate, dayDateTime);
	const bookingsContext = useContext(BookingsContext);

	const startLocalDateString = start.toLocaleDateString({
		weekday: 'short',
		month: 'short',
		year: 'numeric',
	});
	const endLocalDateString = end.toLocaleDateString({
		weekday: 'short',
		month: 'short',
		year: 'numeric',
	});

	const startLocalTimeString = start.toLocaleTimeString({
		hour: '2-digit',
		minute: '2-digit',
	});
	const endLocalTimeString = end.toLocaleTimeString({
		hour: '2-digit',
		minute: '2-digit',
	});

	let startBookingString = startLocalTimeString;
	let endBookingString = endLocalTimeString;
	let bookingDates: ReactElement | string =
		`${startBookingString} - ${endBookingString}`;

	if (multiDayBooking) {
		startBookingString = `${startLocalDateString}, ${startLocalTimeString}`;
		endBookingString = `${endLocalDateString}, ${endLocalTimeString}`;

		if (isFirstDay) {
			startBookingString = startLocalTimeString;
		} else if (isLastDay) {
			endBookingString = endLocalTimeString;
		}

		bookingDates = (
			<Fragment>
				<span>{startBookingString}</span> <span>{endBookingString}</span>
			</Fragment>
		);
	}

	const showEditModal = () => setModal('edit');
	const hideModal = () => setModal('');

	const hasAnyResource = booking.resource || !!booking.resources?.length;
	const resources = getItemResources(booking);

	const resourceConflicts =
		bookingsContext.conflicts[booking.id]?.resourceConflicts;
	const groupConflicts = bookingsContext.conflicts[booking.id]?.groupConflicts;
	const hasResourceOrGroupConflicts =
		resourceConflicts?.length > 0 || groupConflicts?.length > 0;

	const group = booking.forGroupId
		? bookingsContext.flattenedGroups.find((g) => g.id === booking.forGroupId)
		: null;

	const bookingResourcesIds = getItemResourceIds(booking);

	if (
		(bookingsContext.filters?.conflicts &&
			(!resourceConflicts || resourceConflicts.length === 0)) ||
		(bookingsContext.filters?.types.length > 0 &&
			!bookingsContext.filters?.types.includes(booking.type)) ||
		(bookingsContext.filters?.resources.length > 0 &&
			!bookingsContext.filters?.resources.some((r) =>
				bookingResourcesIds.includes(r)
			))
	) {
		return null;
	}

	const scheduleItemBase = (
		<Fragment>
			<div
				data-booking={booking.id}
				className={gridItemStyles.base}
				style={getGridItemCssVars(booking.type)}
				onClick={!bookingsContext.calendarView ? showEditModal : undefined}>
				{hasResourceOrGroupConflicts && (
					<div className={gridItemStyles.errorInfo}>
						{resourceConflicts?.length > 0 && (
							<Row
								autoColumns="max-content"
								spacing={styles.spacing._1}
								align="center"
								onMouseEnter={conflictOnMouseEnter}>
								<Icon
									name="error"
									tooltip={t(`Resource has conflicts`)}
									fill={styles.palette.red[400]}
								/>
								{t(`Conflicts`)}
							</Row>
						)}
						{groupConflicts?.length > 0 && (
							<Row
								autoColumns="max-content"
								spacing={styles.spacing._1}
								align="center"
								onMouseEnter={overlapOnMouseEnter}>
								<Icon name="overlap" tooltip={t(`Overlapping bookings`)} />
								{t(`Overlaps`)}
							</Row>
						)}
					</div>
				)}
				<div className={cssClasses(gridItemStyles.infoBody)}>
					<Column spacing={styles.spacing._1}>
						<span className={gridItemStyles.type}>
							{eventTypeToTranslatedString(booking.type)}
						</span>
						<div
							className={cssClasses(
								gridItemStyles.time,
								multiDayBooking ? gridItemStyles.multiDay : ''
							)}>
							{bookingDates}
						</div>
					</Column>
					{bookingsContext.view === SchedulingViews.Resource &&
						group !== null && (
							<div className={gridItemStyles.name}>{group.name}</div>
						)}
					<div className={gridItemStyles.title}>{booking.title}</div>
				</div>
				{hasAnyResource && (
					<div
						className={cssClasses(
							gridItemStyles.infoBody,
							gridItemStyles.resourceBody
						)}>
						{bookingsContext.view === SchedulingViews.Group && (
							<Fragment>
								{!!booking.resource?.location && (
									<div className={gridItemStyles.locationRow}>
										<Icon
											name="location"
											actualSize
											size={1.4}
											className={gridItemStyles.locationIcon}
										/>
										<span className={gridItemStyles.location}>
											{booking.resource?.location.title}
										</span>
									</div>
								)}
							</Fragment>
						)}
						{resources.length > 0 && (
							<Column spacing={styles.spacing._1}>
								{resources.map((r) => (
									<div key={r.id} className={gridItemStyles.resource}>
										{r?.title}
									</div>
								))}
							</Column>
						)}
					</div>
				)}
			</div>
			{conflictTooltip}
			{overlapTooltip}
		</Fragment>
	);

	return (
		<Fragment>
			<div
				className={gridItemStyles.itemWrapper}
				style={{
					order: booking.startsAt,
				}}>
				{scheduleItemBase}
			</div>
			{modal === 'edit' && (
				<EditBookingModal booking={booking} hideModal={hideModal} />
			)}
		</Fragment>
	);
};

export default GridItem;
