import { ReactNode } from 'react';

export const symbol_macro_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M793-272 672-393q31-18 64-29t68-15q6.577-1 12.788 1.5Q823-433 828-428t7 11.212q2 6.211 1 12.788-4 36-14.5 68T793-272Zm-120 52-53-52q-21 25-37.5 54T555-155q35-11 64-27t54-38Zm43 43q-49 45-109.602 71Q545.796-80 480-80q0-65.796 26-126.398Q532-267 577-316l-68-68q-7 2-14 3t-15 1q-37 0-66-21.5T370.556-458q-5.556 0-12.056.5-6.5.5-12.5.5-48 0-82-34t-34-82q0-18.097 6-34.548Q242-624 253-639L106-786q-9-9-8.5-21.5t9.342-21.5q8.842-9 21-9T149-829l697 696q9 9.273 8.5 21.636Q854-99 845.158-90t-21 9Q812-81 803-90l-87-87ZM480-920q37 0 66 21.5t43 56.5q6 0 12.5-.5t12.5-.5q47.84 0 81.42 34.063Q729-774.873 729-727q0 21-8 40.5T699-650q14.444 17 22.222 36.5Q729-594 729-573q0 48-33.5 82T614-457h-6L447-618l-69-68q6-17 16.5-31.5T420-743l-42.378-29.815Q371-778 363-780.5t-17-2.5q-12.5 0-23.75 5.5T303-762l-43-42q20-23 49.989-31.5Q339.977-844 371-842q14-35 43-56.5t66-21.5Zm134.2 403q22.8 0 39.3-16.425Q670-549.85 670-572.967q0-17.033-9-29.533T637-623l-47-23q-2 14-6 26.5t-10.042 23.618q-6.041 11.117-14.5 21Q551-565 540-557l42.378 29.815Q589-522 596.8-519.5q7.8 2.5 17.4 2.5ZM590-654l47-23q15-8 23.5-21.241t8.5-28.889q0-22.87-15.889-39.37Q637.222-783 614-783q-9 0-16.667 2.5Q589.667-778 582-773l-44 30q11 8 20 17.6 9 9.6 15 20.4 7 11 11 23.5t6 27.5Zm-159-94q14-6 26.5-9t22.5-3q10 0 22.5 3t26.5 9l7-62q2-21-16-35.5T480.5-860q-21.5 0-40 14.773Q422-830.455 424-810l7 62Zm49.5 143q18.5 0 31.5-13.5t13-32q0-18.5-12.938-31.5-12.937-13-32.062-13-18 0-31.5 12.938Q435-669.125 435-650q0 18 13.5 31.5t32 13.5Zm93.5 9Zm-1-109Zm-93-55Zm0 680q0-140-93.5-233.5T164-432q-8.345-2-15.172 1Q142-428 137-423q-5.091 5.4-7.545 11.957Q127-404.486 129-396q26 129 117.5 222.5T480-80Zm-75-75q-80-26-133-79t-79-133q80 26 133 79.5T405-155Zm150 0Zm-150 0Z"
		/>
	</svg>
);
