import { FunctionComponent } from 'react';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import { useEndpoint } from 'pkg/api/use_endpoint';

import OrganizationSettings from 'routes/group/settings/OrganizationSettings';
import TeamSettings from 'routes/group/settings/TeamSettings';

import { Spinner } from 'components/loaders/spinner';

interface GroupSettingsProps {
	groupId: number;
	currentTab: string;
}

const GroupSettings: FunctionComponent<GroupSettingsProps> = ({
	groupId,
	currentTab,
}): JSX.Element => {
	const {
		record: group,
		isLoading,
		refresh,
		replaceRecord,
	} = useEndpoint<models.group.Group>(endpoints.Groups.Show(groupId));

	if (isLoading) {
		return <Spinner />;
	}

	return models.group.isOrganization(group) ? (
		<OrganizationSettings
			group={group}
			currentTab={currentTab}
			refresh={refresh}
			replaceRecord={replaceRecord}
		/>
	) : (
		<TeamSettings
			group={group}
			currentTab={currentTab}
			refresh={refresh}
			replaceRecord={replaceRecord}
		/>
	);
};

export default GroupSettings;
