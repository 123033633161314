import { useEventDispatcher } from 'pkg/hooks/events';
import useKeyBindings from 'pkg/hooks/useKeyBindings';

import { usePlayerState } from 'components/video-analytics/PlayerState';

export default function KeyBindings(): JSX.Element {
	const { controller } = usePlayerState();

	const handleArrowUp = useEventDispatcher('video-arrow-up');
	const handleArrowDown = useEventDispatcher('video-arrow-down');
	const handleArrowLeft = useEventDispatcher('video-arrow-left');
	const handleArrowRight = useEventDispatcher('video-arrow-right');

	const handleSpacebar = useEventDispatcher('video-spacebar');
	const handleEscape = useEventDispatcher('annotation-deselect');
	const handleDelete = useEventDispatcher('video-delete');

	const handleRecord = useEventDispatcher('video-record');
	const handleSave = useEventDispatcher('video-save');

	useKeyBindings(
		{
			onArrowUp: () => handleArrowUp,
			onArrowDown: () => handleArrowDown,
			onArrowLeft: () => handleArrowLeft,
			onArrowRight: () => handleArrowRight,

			onSpacebar: () => handleSpacebar,
			onEscape: () => handleEscape,
			onDelete: () => handleDelete,
			onBackspace: () => handleDelete,

			keyBindings: {
				r: () => handleRecord,
				'mod + s': () => handleSave,
			},
		},
		window,
		[controller]
	);

	return null;
}
