import { ReactNode } from 'react';

export const symbol_transition_dissolve: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M210-160q-21 0-35.5-15T160-210q0-22 15-36.5t35-14.5q22 0 36.5 14.5T261-210q0 20-14.5 35T210-160Zm180 0q-22 0-36.5-15T339-210q0-22 15-36.5t36-14.5q21 0 35.5 14.5T440-210q0 20-14.5 35T390-160Zm180 0q-20 0-35.5-15T519-210q0-22 15.5-36.5T570-261q21 0 36 14.5t15 36.5q0 20-15 35t-36 15Zm179 0q-21 0-36-15t-15-35q0-22 15.5-36.5T749-261q22 0 36.5 14.5T800-210q0 20-14.5 35T749-160ZM210-339q-20 0-35-15t-15-36q0-20 14.5-35t35.5-15q21 0 36 14.5t15 35.5q0 21-14.5 36T210-339Zm180 0q-21 0-36-15t-15-36q0-21 15-35.5t36-14.5q21 0 35.5 14.5T440-390q0 21-14.5 36T390-339Zm180 0q-20 0-35.5-15T519-390q0-21 15.5-35.5T570-440q21 0 36 14.5t15 35.5q0 21-15 36t-36 15Zm179 0q-20 0-35.5-15T698-390q0-21 15.5-35.5T749-440q22 0 36.5 14.5T800-390q0 21-14.5 36T749-339ZM210-519q-20 0-35-15.5T160-570q0-20 15-35.5t35-15.5q22 0 36.5 15t14.5 36q0 20-14.5 35.5T210-519Zm180 0q-21 0-36-15.5T339-570q0-21 15-36t36-15q21 0 35.5 15t14.5 36q0 20-14.5 35.5T390-519Zm180 0q-20 0-35.5-15.5T519-570q0-21 15.5-36t35.5-15q21 0 36 15t15 36q0 20-15 35.5T570-519Zm179 0q-20 0-35.5-15.5T698-570q0-21 15.5-36t35.5-15q22 0 36.5 15t14.5 36q0 20-14.5 35.5T749-519ZM210-698q-20 0-35-15.5T160-749q0-20 14.5-35.5T210-800q21 0 36 14.5t15 36.5q0 20-14.5 35.5T210-698Zm180 0q-21 0-36-15.5T339-749q0-22 15-36.5t36-14.5q21 0 35.5 14.5T440-749q0 20-14.5 35.5T390-698Zm180 0q-20 0-35.5-15.5T519-749q0-22 15.5-36.5T570-800q21 0 36 14.5t15 36.5q0 20-15 35.5T570-698Zm179 0q-20 0-35.5-15.5T698-749q0-22 15.5-36.5T749-800q22 0 36.5 14.5T800-749q0 20-14.5 35.5T749-698Z"
		/>
	</svg>
);
