import { ReactNode } from 'react';

export const symbol_water_heater: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M351-529q0 29 12 55t35 44q-2-3-1.5-6t.5-6q0-17 6.5-31.5T422-500l58-57 58 57q12 12 18.5 26.5T563-442q0 3 .5 6t-1.5 6q23-18 35-44t12-55q0-27-10.5-51T570-622q-11 7-22.5 10.5T524-608q-33 0-58-22t-29-55q-21 18-37 37t-27 39q-11 20-16.5 40t-5.5 40Zm129 28-31 30q-6 6-9 13.5t-3 15.5q0 17 13 29.5t30 12.5q17 0 30-12.5t13-29.5q0-8-3-16t-9-13l-31-30Zm-3-259v66q0 20 13.5 33t33.5 13q10 0 18-4t15-12l9-12q38 22 60.5 62t22.5 85q0 70-49.5 119.5T480-360q-70 0-119.5-49.5T311-529q0-67 44.5-128T477-760ZM220-80q-25 0-42.5-17.5T160-140v-580q0-66 47-113t113-47h320q66 0 113 47t47 113v580q0 25-17.5 42.5T740-80H220Zm0-170v110h520v-110q-30 0-53 20t-77 20q-54 0-75.5-20T480-250q-33 0-54.5 20T350-210q-54 0-75.5-20T220-250Zm130-20q33 0 54.5-20t75.5-20q54 0 77 20t53 20q30 0 53-20t77-20v-410q0-42-29-71t-71-29H320q-42 0-71 29t-29 71v410q54 0 75.5 20t54.5 20Z"
		/>
	</svg>
);
