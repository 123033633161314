import { ReactNode } from 'react';

export const symbol_filter_vintage: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M479-80q-56 0-97.5-38.5T331-220q-59 23-110.5 4T140-283q-29-48-19-105t68-92q-60-36-69-92.5T140-677q29-48 80.5-67t110.5 4q9-63 50.5-101.5T479-880q56 0 97.5 38.5T627-740q59-23 110.5-4t80.5 67q29 48 20 104.5T769-480q58 35 67.5 92.5T818-283q-29 48-80.5 67T627-220q-9 63-50.5 101.5T479-80Zm0-60q51 0 73.5-42.5T551-265l-32-53q-11 3-21 4t-19 1q-9 0-19-1t-21-4l-32 53q-24 40-1.5 82.5T479-140ZM192-313q26 44 69.5 47t74.5-40l32-45q-6-6-14.5-16T339-387q-6-10-11-22.5t-8-28.5h-61q-47 0-70 40.5t3 84.5Zm429 7q30 44 74.5 40.5T766-313q26-44 3-84.5T699-438h-61q-3 16-8 28.5T620-387q-5 10-12.5 19T590-351l31 45ZM481-480Zm-161-42 8-24q4-12 11-23t14-21.5q7-10.5 15-18.5l-31-44q-31-44-76-41.5T192-647q-27 45-3.5 85t70.5 40h61Zm379 0q47 0 69.5-41.5T766-647q-26-44-69.5-46.5T621-653l-31 44q8 6 15 17t14 23q7 12 11.5 22.5T638-522h61ZM439-642q11-2 21-3.5t19-1.5q9 0 19 1.5t21 3.5l32-53q24-41 1.5-83T479-820q-51 0-73.5 42t1.5 83l32 53Zm0 0q11-2 21-3.5t19-1.5q9 0 19 1.5t21 3.5q-11-2-21-3.5t-19-1.5q-9 0-19 1.5t-21 3.5ZM339-387q-6-10-11-22.5t-8-28.5q3 16 8 28.5t11 22.5q6 10 14.5 20t14.5 16q-6-6-14.5-16T339-387Zm-19-135 8-24q4-12 11-23t14-21.5q7-10.5 15-18.5-8 8-15 18.5T339-569q-7 11-11 23l-8 24Zm159 209q-9 0-19-1t-21-4q11 3 21 4t19 1q9 0 19-1t21-4q-11 3-21 4t-19 1Zm111-38q10-8 17.5-17t12.5-19q5-10 10-22.5t8-28.5q-3 16-8 28.5T620-387q-5 10-12.5 19T590-351Zm48-171q-3-14-7.5-24.5T619-569q-7-12-14-23t-15-17q8 6 15 17t14 23q7 12 11.5 22.5T638-522ZM480-373q44 0 75.5-31.5T587-480q0-44-31.5-75.5T480-587q-44 0-75.5 31.5T373-480q0 44 31.5 75.5T480-373Z"
		/>
	</svg>
);
