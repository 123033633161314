import { ReactNode } from 'react';

export const symbol_unlicense: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m715-343-42-42q35-39 51-83t16-92q0-109-75.5-184.5T480-820q-46 0-93 17t-82 50l-43-43q46-44 103-64t115-20q136 0 228 92t92 228q0 54-19 111t-66 106ZM566-492 412-646q14-11 30.5-17.5T480-670q45 0 77.5 32.5T590-560q0 23-7.5 39.5T566-492Zm-86 373L284-53q-14 5-27-4.5T244-82v-262q-45-46-64.5-102.5T160-560q0-38 8-74t22-63L78-809q-9-9-9-21.5t9-21.5q9-9 21.5-9t21.5 9l748 748q9 9 9 21t-9 21q-9 9-21.5 9T826-62L716-171v89q0 15-13 24.5T676-53l-196-66Zm0-181q20 0 44-3.5t47-12.5L236-651q-8 23-12 46.5t-4 44.5q0 109 75.5 184.5T480-300ZM304-124l176-55 176 55v-107l-40-40q-32 16-66.5 23.5T480-240q-42 0-89-13t-87-42v171Zm176-86Z"
		/>
	</svg>
);
