import { Map, Record } from 'immutable';

import PerformanceReview from 'pkg/models/performance_review';

import * as types from 'pkg/actions/action-types';

const initialState = Record(
	{
		entities: Map({}),
		links: {},
	},
	'performanceReviews'
);

const performanceReviews = (state = initialState(), action = {}) => {
	switch (action.type) {
		case types.PerformanceReviews.SET_ITEMS: {
			const entities = Object.values(action.payload.entities.performanceReview);

			entities.map((entity) => {
				state = state.setIn(
					['entities', entity.id],
					new PerformanceReview(entity)
				);
			});

			return state;
		}

		case 'ADD_PERFORMANCE_REVIEW': {
			return state.setIn(
				['entities', action.performanceReview.id],
				new PerformanceReview(action.performanceReview)
			);
		}

		case 'REMOVE_PERFORMANCE_REVIEW': {
			return state.deleteIn(['entities', action.performanceReviewId]);
		}

		case 'SET_LINKS': {
			return state.setIn(['links'], action.links);
		}

		case types.AUTH_LOGOUT_SUCCESS:
			return initialState();

		default:
			return state;
	}
};

export default performanceReviews;
