import { ReactNode } from 'react';

export const symbol_signature: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M546-463q85-58 133.5-128T728-732q0-42-14-65t-38-23q-57 0-97 85t-40 200q0 20 1.5 38t5.5 34ZM143-305q-9 9-21 9t-21-9q-9-9-9-21t9-21l43-43-43-43q-9-9-9-21t9-21q9-9 21-9t21 9l43 43 43-43q9-9 21-9t21 9q9 9 9 21t-9 21l-43 43 43 43q9 9 9 21t-9 21q-9 9-21 9t-21-9l-43-43-43 43Zm475-15q-32 0-57.5-14T517-375q-19 11-38.5 20T439-337q-12 5-23 .5T400-353q-5-12 .5-23.5T418-393q20-8 38.5-17t35.5-19q-7-23-10-49.5t-3-56.5q0-146 55.5-245.5T676-880q51 0 81.5 41T788-729q0 88-57.5 172T570-406q10 13 22 19.5t26 6.5q30 0 62.5-23t61.5-67q8-11 19.5-14.5T785-483q12 6 19 17.5t5 25.5q-2 15-1.5 30t2.5 32q9-5 18.5-11.5T848-404q10-8 22.5-9.5T893-407q10 8 11 21t-8 21q-22 21-45.5 33T806-320q-24 0-39-17t-18-49q-29 32-62.5 49T618-320ZM150-120q-13 0-21.5-8.5T120-150q0-13 8.5-21.5T150-180q13 0 21.5 8.5T180-150q0 13-8.5 21.5T150-120Zm165 0q-13 0-21.5-8.5T285-150q0-13 8.5-21.5T315-180q13 0 21.5 8.5T345-150q0 13-8.5 21.5T315-120Zm165 0q-13 0-21.5-8.5T450-150q0-13 8.5-21.5T480-180q13 0 21.5 8.5T510-150q0 13-8.5 21.5T480-120Zm165 0q-13 0-21.5-8.5T615-150q0-13 8.5-21.5T645-180q13 0 21.5 8.5T675-150q0 13-8.5 21.5T645-120Zm165 0q-13 0-21.5-8.5T780-150q0-13 8.5-21.5T810-180q13 0 21.5 8.5T840-150q0 13-8.5 21.5T810-120Z"
		/>
	</svg>
);
