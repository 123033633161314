// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.u4sBBm30Nuk9XwPlOC_p {
	padding: 0;
}

@media (max-width: 768px) {

.u4sBBm30Nuk9XwPlOC_p {
		padding: 0;
}
	}

.bPCN83u1TR0WZEbjbpWk {
	padding: 0 !important;
}

.RNOqCpaDMVWOVeg_Vj2b {
	margin: 0 auto !important;
	width: 80% !important;
	background: transparent !important;
}

.OlC362xBUFb9zRyhuNMx {
	border-color: transparent;
}
`, "",{"version":3,"sources":["webpack://./routes/event/single/tabs/lineup/styles.css"],"names":[],"mappings":"AAAA;CACC,UAAU;AAKX;;AAHC;;AAHD;EAIE,UAAU;AAEZ;CADC;;AAGD;CACC,qBAAqB;AACtB;;AAEA;CACC,yBAAyB;CACzB,qBAAqB;CACrB,kCAAkC;AACnC;;AAEA;CACC,yBAAyB;AAC1B","sourcesContent":[".cardBody {\n\tpadding: 0;\n\n\t@media (--small-viewport) {\n\t\tpadding: 0;\n\t}\n}\n\n.startingField {\n\tpadding: 0 !important;\n}\n\n.container {\n\tmargin: 0 auto !important;\n\twidth: 80% !important;\n\tbackground: transparent !important;\n}\n\n.sections {\n\tborder-color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
export var cardBody = `u4sBBm30Nuk9XwPlOC_p`;
export var startingField = `bPCN83u1TR0WZEbjbpWk`;
export var container = `RNOqCpaDMVWOVeg_Vj2b`;
export var sections = `OlC362xBUFb9zRyhuNMx`;
export default ___CSS_LOADER_EXPORT___;
