import { ReactNode } from 'react';

export const symbol_man_3: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M420-100v-250h-50q-12.75 0-21.375-8.625T340-380v-223q0-24.75 17.625-42.375T400-663h160q24.75 0 42.375 17.625T620-603v223q0 12.75-8.625 21.375T590-350h-50v250q0 12.75-8.646 21.375T509.927-70h-60.146Q437-70 428.5-78.625 420-87.25 420-100Zm39-635-41-41q-9-9-9-21t9-21l41-41q9-9 21-9t21 9l41 41q9 9 9 21t-9 21l-41 41q-9 9-21 9t-21-9Z"
		/>
	</svg>
);
