import { Record, List } from 'immutable';

import { views } from 'pkg/actions/action-types';

const initialState = new Record(
	{
		allSessions: new List(),
		allExercises: new List(),
	},
	'trainingLibraryReducer'
);

const setSessionResult = (state, { payload }) =>
	state.update('allSessions', (allSessions) =>
		allSessions.merge(
			new List(payload.filter((id) => !allSessions.includes(id)))
		)
	);

const setExerciseResult = (state, { payload }) =>
	state.update('allExercises', (allExercises) =>
		allExercises.merge(
			new List(payload.filter((id) => !allExercises.includes(id)))
		)
	);

const trainingLibraryReducer = (state = initialState(), action) => {
	switch (action.type) {
		case views.trainingLibrary.SET_SESSION_RESULT:
			return setSessionResult(state, action);
		case views.trainingLibrary.SET_EXERCISE_RESULT:
			return setExerciseResult(state, action);
		default:
			return state;
	}
};

export default trainingLibraryReducer;
