import { ReactNode } from 'react';

export const symbol_local_florist: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-80q0-123 85.5-225T770-432q14-3 27.5-.5T820-419q10 11 12 24.5t-1 27.5q-26 118-128 202.5T480-80Zm75-75q80-26 133-79t79-133q-80 26-133 79.5T555-155Zm-75 75q0-123-85.5-225T190-432q-14-3-27.5-.5T140-419q-10 11-12 25t1 28q26 118 128 202t223 84Zm-75-75q-80-26-133-79t-79-133q80 26 133 79.5T405-155Zm150 0Zm-150 0Zm75.5-445q20.5 0 35-15t14.5-35.5q0-20.5-14.375-35T480-700q-20 0-35 14.375T430-650q0 20 15 35t35.5 15Zm-.5 220q-36.324 0-65.662-21.5Q385-423 371-458q-6 0-12.5.5t-12.5.5q-47.873 0-81.937-34.063Q230-525.127 230-573q0-20 8-39.5t23-37.5q-14-18-22-37.5t-8-39.062Q231-775 264.58-809T346-843q6 0 12.5.5t12.5.5q14-35 43.338-56.5T480-920q36.324 0 65.662 21.5Q575-877 589-842q6 0 12.5-.5t12.5-.5q47.84 0 81.42 34.063Q729-774.873 729-727q0 20-7.5 39.5T699-650q14 18 22 37.5t8 39.062Q729-525 695.42-491T614-457q-6 0-12.5-.5T589-458q-14 35-43.338 56.5T480-380Zm134.2-137q22.8 0 39.3-16.425Q670-549.85 670-572.967q0-17.033-9-29.533T637-623l-47-23q-2 14-6 26.5t-10.042 23.618q-6.041 11.117-14.5 21Q551-565 540-557l42.378 29.815Q589-522 596.8-519.5q7.8 2.5 17.4 2.5ZM590-654l47-23q15-8 23.5-21.241t8.5-28.889q0-22.87-15.889-39.37Q637.222-783 614-783q-9 0-16.667 2.5Q589.667-778 582-773l-44 30q11 8 20 17.6 9 9.6 15 20.4 7 11 11 23.5t6 27.5Zm-159-94q14-6 26.5-9t22.5-3q10 0 22.5 3t26.5 9l7-62q2-21-16-35.5T480.5-860q-21.5 0-40 14.773Q422-830.455 424-810l7 62Zm49.5 308q21.5 0 39.5-14.5t16-35.5l-7-62q-14 6-26.5 9t-22.5 3q-10 0-22.5-3t-26.5-9l-7 62q-2 20.455 16.5 35.227Q459-440 480.5-440ZM370-654q2-15 6-27.5t10.042-23.353q6.041-10.853 14.5-20.5Q409-735 420-743l-42.378-29.815Q371-778 363.2-780.5q-7.8-2.5-17.4-2.5-22.8 0-39.3 16.475-16.5 16.474-16.5 39.661Q290-711 299-698t24 21l47 23Zm-24 136q9 0 16.5-2.5T378-528l44-29q-11-8-20-17.647-9-9.647-15-20.5t-10.4-23.978Q372.2-632.25 370-646l-47 23q-15 8-23.5 21.241T291-572.87Q292-550 307.341-534q15.341 16 38.659 16Zm228-78Zm-1-109Zm-93-55Zm0 220Zm-94-165Zm1 110Z"
		/>
	</svg>
);
