import { ReactNode } from 'react';

export const symbol_garage: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M141-80q-26 0-43-17t-17-43v-680q0-23 17-41.5t43-18.5h680q23 0 41.5 18.5T881-820v680q0 26-18.5 43T821-80H141Zm0-60h680v-680H141v680Zm184.825-253Q313-393 304.5-401.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Zm310 0Q623-393 614.5-401.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Zm-404.65 183q12.825 0 21.325-8.625T261-240v-58h440v58q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T761-240v-256q0-4.9-.5-9.45Q760-510 758-515l-60-175q-6-17.778-21.75-28.889T641-730H321q-19.5 0-35.25 11.111T264-690l-60 175q-2 5-2.5 9.55-.5 4.55-.5 9.45v256q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625ZM278-548l40-122h326l41 122H278ZM141-820v680-680Zm120 462v-130h440v130H261Z"
		/>
	</svg>
);
