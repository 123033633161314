import { ReactNode } from 'react';

export const symbol_point_of_sale: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M260-630q-25 0-42.5-17.5T200-690v-130q0-25 17.5-42.5T260-880h440q25 0 42.5 17.5T760-820v130q0 25-17.5 42.5T700-630H260Zm30-60h380q13 0 21.5-8.5T700-720v-70q0-13-8.5-21.5T670-820H290q-13 0-21.5 8.5T260-790v70q0 13 8.5 21.5T290-690ZM140-80q-25 0-42.5-17.5T80-140v-70h800v70q0 25-17.5 42.5T820-80H140ZM80-240l145-324q8-16 22.5-26t31.5-10h402q17 0 31.5 10t22.5 26l145 324H80Zm260-80h40q8 0 14-6t6-14q0-8-6-14t-14-6h-40q-8 0-14 6t-6 14q0 8 6 14t14 6Zm0-80h40q8 0 14-6t6-14q0-8-6-14t-14-6h-40q-8 0-14 6t-6 14q0 8 6 14t14 6Zm0-80h40q8 0 14-6t6-14q0-8-6-14t-14-6h-40q-8 0-14 6t-6 14q0 8 6 14t14 6Zm120 160h40q8 0 14-6t6-14q0-8-6-14t-14-6h-40q-8 0-14 6t-6 14q0 8 6 14t14 6Zm0-80h40q8 0 14-6t6-14q0-8-6-14t-14-6h-40q-8 0-14 6t-6 14q0 8 6 14t14 6Zm0-80h40q8 0 14-6t6-14q0-8-6-14t-14-6h-40q-8 0-14 6t-6 14q0 8 6 14t14 6Zm120 160h40q8 0 14-6t6-14q0-8-6-14t-14-6h-40q-8 0-14 6t-6 14q0 8 6 14t14 6Zm0-80h40q8 0 14-6t6-14q0-8-6-14t-14-6h-40q-8 0-14 6t-6 14q0 8 6 14t14 6Zm0-80h40q8 0 14-6t6-14q0-8-6-14t-14-6h-40q-8 0-14 6t-6 14q0 8 6 14t14 6Z"
		/>
	</svg>
);
