import { ReactNode } from 'react';

export const symbol_page_control: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M150-400q-33 0-56.5-23.5T70-480q0-33 23.5-56.5T150-560q33 0 56.5 23.5T230-480q0 33-23.5 56.5T150-400Zm0-46q14.385 0 24.192-9.808Q184-465.615 184-480t-9.808-24.192Q164.385-514 150-514t-24.192 9.808Q116-494.385 116-480t9.808 24.192Q135.615-446 150-446Zm220 46q-33 0-56.5-23.5T290-480q0-33 23.5-56.5T370-560q33 0 56.5 23.5T450-480q0 33-23.5 56.5T370-400Zm0-46q14.385 0 24.192-9.808Q404-465.615 404-480t-9.808-24.192Q384.385-514 370-514t-24.192 9.808Q336-494.385 336-480t9.808 24.192Q355.615-446 370-446Zm220 46q-33 0-56.5-23.5T510-480q0-33 23.5-56.5T590-560q33 0 56.5 23.5T670-480q0 33-23.5 56.5T590-400Zm0-46q14.385 0 24.192-9.808Q624-465.615 624-480t-9.808-24.192Q604.385-514 590-514t-24.192 9.808Q556-494.385 556-480t9.808 24.192Q575.615-446 590-446Zm220 46q-33 0-56.5-23.5T730-480q0-33 23.5-56.5T810-560q33 0 56.5 23.5T890-480q0 33-23.5 56.5T810-400Z"
		/>
	</svg>
);
