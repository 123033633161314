import { ReactNode } from 'react';

export const symbol_reset_image: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M320-580H150q-12.75 0-21.375-8.625T120-610v-170q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T180-780v101q48-73 127-117t173-44q113 0 202 60.5T812-628q5 11 .5 23T797-588q-11 5-23 .5T757-603q-34-75-108.5-126T480-780q-81 0-147.5 38.5T226-640h94q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T320-580Zm-54 343h429q8.5 0 12.75-8t-.75-16L590-417q-5-6-12-6t-12 6L446-262l-81-111q-5-6-12-6t-12 6l-86 112q-6 8-1.75 16t12.75 8ZM180-80q-24.75 0-42.375-17.625T120-140v-330q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T180-470v330h600v-330q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T840-470v330q0 24.75-17.625 42.375T780-80H180Z"
		/>
	</svg>
);
