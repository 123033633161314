import { t } from '@transifex/native';

import Link from 'pkg/router/Link';

import { useOnboardingContext } from 'routes/public/onboarding';
import FooterNote from 'routes/public/styles/FooterNote';

export default function ForgotPasswordLink(): JSX.Element {
	const { goTo } = useOnboardingContext();

	const handleClick = () => {
		window.history.pushState({}, '', 'reset-password-request');
		goTo('reset-password-request');
	};

	return (
		<FooterNote>
			<Link onClick={handleClick} testid="onboarding.reset_password_button">
				{t('Forgot your password?')}
			</Link>
		</FooterNote>
	);
}
