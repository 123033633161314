import { ReactNode } from 'react';

export const symbol_play_disabled: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m635-396-43-43 69-44-329-209v-33q0-18 15.5-26.5T378-750l354 242q14 9 14 25t-14 25l-97 62ZM772-90 532-330l-154 98q-15 10-30.5 1T332-258v-272L91-772q-9-9-9-21t9-21q9-9 21-9t21 9l682 682q9 9 8.5 21T814-90q-9 9-21 9t-21-9ZM392-465Zm0 153 93-59-93-94v153Zm-60-380Z"
		/>
	</svg>
);
