import { ReactNode } from 'react';

export const symbol_screen_lock_rotation: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M662-600q-15 0-27.5-12.5T622-640v-120q0-15 12.5-27.5T662-800h1v-42q0-33 22.5-55.5T741-920q33 0 55.5 22.5T819-842v42h2q15 0 27.5 12.5T861-760v120q0 15-12.5 27.5T821-600H662Zm39-200h80v-42.105Q781-860 770-871t-29-11q-18 0-29 11t-11 28.895V-800ZM488-98 379-207q-9-9-8.5-21t9.5-21q9-9 21.5-9t21.5 9L597-75q11 11 6 24.5T584.29-34q-11.29 2-24.461 2.5-13.17.5-26.829.5-98.639 0-185.819-37.5Q260-106 195-171.5 130-237 92.5-324.181 55-411.361 55-510q0-12.75 8.675-21.375Q72.351-540 85.175-540 98-540 106.5-531.375T115-510q0 80 29 151.5T223-232q50 55 118.5 90.5T488-98Zm129-106q-11.721 0-23.86-4.5Q581-213 571-223L246-548q-10-10-14.5-22.25t-4.5-24.078q0-12.672 4.5-24.172T246-640l166-165q10-10 22.25-15t24.078-5q12.672 0 24.172 5t21.5 15l27 27q9 9 9 21t-9 21q-9 9-21.5 9t-21.5-9l-30-30-172 172 331 332 172-172-35-35q-9-9-8.5-21.5T755-512q9-9 21.5-9t21.5 9l31 32q10 9 14.5 21.047Q848-446.907 848-434q0 13-4.5 24.5T829-388L663-223q-10 10-21.721 14.5T617-204Zm-77-310Z"
		/>
	</svg>
);
