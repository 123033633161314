import { t } from '@transifex/native';
import { useRef, useState } from 'react';

import * as flashActions from 'pkg/actions/flashes';

import { ResourceCategoryUpdatePayload } from 'pkg/api/models/resource_category';
import * as models from 'pkg/api/models';

import CategoryForm from 'routes/scheduling/categories/CategoryForm';

import { IconName } from 'components/icon';
import * as StepModal from 'components/step-modal';

import Form, { FormPayload } from 'components/form/Form';

interface EditCategoryProps {
	category: models.resourceCategory.ResourceCategory;

	onClose: () => void;
	refresh: () => Promise<void>;
}

const EditCategory = ({ category, onClose, refresh }: EditCategoryProps) => {
	const formRef = useRef(null);
	const [icon, setIcon] = useState<IconName | ''>(category.icon || '');

	const handleSubmit = async (data: FormPayload) => {
		const payload: ResourceCategoryUpdatePayload = {
			title: data.title as string,
			icon: icon || '',
		};

		const [ok] = await models.resourceCategory.update(category, payload);

		if (ok) {
			flashActions.show({
				title: t(`Successfully updated category`),
			});

			refresh();
			onClose();
		}
	};

	const handleNext = async () =>
		await formRef.current.dispatchEvent(
			new Event('submit', {
				cancelable: true,
				bubbles: true,
			})
		);

	return (
		<StepModal.Base onClose={onClose}>
			<StepModal.Step title={t(`Edit category`)} onNext={handleNext}>
				<Form formRef={formRef} onSubmit={handleSubmit}>
					<CategoryForm category={category} icon={icon} setIcon={setIcon} />
				</Form>
			</StepModal.Step>
		</StepModal.Base>
	);
};

export default EditCategory;
