import spacing from 'pkg/config/spacing';
import * as styles from 'pkg/config/styles';

import { eventTypeToTranslatedString } from 'pkg/models/event';

import { eventTypeLabels } from 'pkg/api/models/event';
import useMixedState from 'pkg/hooks/useMixedState';

import Icon from 'components/icon';

import * as Input from 'components/form/inputs';
import Column from 'components/layout/column';
import Row from 'components/layout/row';

const Inputs = () => {
	const [formState, setFormState] = useMixedState({
		radio: 1,
	});

	const handleRadioChange = (value: number) => {
		setFormState({ radio: value });
	};

	return (
		<div>
			<Column spacing={spacing._5}>
				<Input.Group label="Field" required>
					<Input.Field placeholder="Placeholder..." />
				</Input.Group>
				<Input.Group label="Field small" required>
					<Input.Field small placeholder="Placeholder..." />
				</Input.Group>
				<Input.Group label="Area" description="Description of text area">
					<Input.Area placeholder="Placeholder..." minRows={3}></Input.Area>
				</Input.Group>
				<Input.Group label="Select" hint="Input group with hint" optional>
					<Input.Select>
						<option>Select</option>
						{eventTypeLabels.map((label, i) => (
							<option key={i}>{eventTypeToTranslatedString(label)}</option>
						))}
					</Input.Select>
				</Input.Group>
				<Input.Group label="Prefix">
					<Input.Field placeholder="Camp Nou">
						<Input.Prefix>
							<Icon name="location" />
						</Input.Prefix>
					</Input.Field>
				</Input.Group>
				<Input.Group label="Suffixed Interactable">
					<Input.Field placeholder="Search something">
						<Input.Prefix>
							<Icon name="search" />
						</Input.Prefix>
						<Input.Suffix interactable>
							<Row
								spacing={styles.spacing._2}
								align="center"
								autoColumns="true">
								Clickable <Icon name="chevron-down" />
							</Row>
						</Input.Suffix>
					</Input.Field>
				</Input.Group>
				<Input.Group label="Suffix">
					<Input.Field defaultValue="0">
						<Input.Suffix>Minutes</Input.Suffix>
					</Input.Field>
				</Input.Group>
				<Input.Group label="Prefix small">
					<Input.Field small placeholder="Camp Nou">
						<Input.Prefix>
							<Icon name="location" />
						</Input.Prefix>
					</Input.Field>
				</Input.Group>
				<Input.Group label="Suffixed Interactable small">
					<Input.Field small placeholder="Search something">
						<Input.Prefix>
							<Icon name="search" />
						</Input.Prefix>
						<Input.Suffix interactable>
							<Row
								spacing={styles.spacing._2}
								align="center"
								autoColumns="true">
								Clickable <Icon name="chevron-down" />
							</Row>
						</Input.Suffix>
					</Input.Field>
				</Input.Group>
				<Input.Group label="Suffix small">
					<Input.Field small defaultValue="0">
						<Input.Suffix>Minutes</Input.Suffix>
					</Input.Field>
				</Input.Group>
				<Input.Group label="Radio buttons">
					<Input.Control
						type="radio"
						value={1}
						checked={formState.radio === 1}
						onChange={handleRadioChange}
						label="Choice 1"
						description="Description of why choice 1 is the best one"
					/>
					<Input.Control
						type="radio"
						value={2}
						checked={formState.radio === 2}
						onChange={handleRadioChange}
						label="Choice 2"
						description="Description of why choice 2 is the best one"
					/>
				</Input.Group>
				<Input.Group label="Checkbox">
					<Input.Control
						type="checkbox"
						label="Select 1"
						description="Description of checkbox"
					/>
					<Input.Control
						type="checkbox"
						label="Select 2"
						description="Description of checkbox"
					/>
				</Input.Group>
			</Column>
		</div>
	);
};

export default Inputs;
