import {
	FLASH_SET_VISIBILITY,
	FLASH_SET_MESSAGE,
} from 'pkg/actions/action-types';
import { logout } from 'pkg/actions/auth';
import { PushNotificationMetaData } from 'pkg/actions/notifications';

import store from 'pkg/store/createStore';
import { delay } from 'pkg/timings';

import { IconName } from 'components/icon';

export const FLASH_DECAY_TIME = 6000;

interface FlashMessage {
	title: string;
	message?: string;
	additionalData?: PushNotificationMetaData;
}

export const setVisibility = (visible: boolean): any => ({
	type: FLASH_SET_VISIBILITY,
	visible,
});

const setMessage = (
	message: FlashMessage,
	status?: number,
	icon?: IconName
): any => ({
	type: FLASH_SET_MESSAGE,
	message,
	status,
	icon,
});

export async function show(
	message: FlashMessage,
	status?: number,
	icon?: IconName
): Promise<void> {
	if (status === 401) {
		logout()(store.dispatch);
		return;
	}

	store.dispatch(setMessage(message, status, icon));
	store.dispatch(setVisibility(true));

	await delay(FLASH_DECAY_TIME);

	store.dispatch(setVisibility(false));
	store.dispatch(setMessage(null));
}
