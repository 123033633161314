import { t } from '@transifex/native';
import { Fragment } from 'react';

import spacing from 'pkg/config/spacing';
import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import * as actions from 'pkg/actions';
import { EventSeriesUserResponseStatus } from 'pkg/api/models/event_series_user';

import { useEventSeriesContext } from 'routes/event/series/single';

import Column from 'components/layout/column';
import EventSeriesUserCard from 'components/event-series/user-card';

import * as Context from 'design/context_menu';
import Button from 'design/button';

import * as css from './styles.css';

interface EventSeriesAttendanceProps {
	eventSeries: models.eventSeries.EventSeries;
	eventSeriesUsers: models.eventSeriesUser.EventSeriesUser[];
}

interface EventSeriesUserRowProps {
	eventSeries: models.eventSeries.EventSeries;
	eventSeriesUser: models.eventSeriesUser.EventSeriesUser;
}

function EventSeriesUserRow({
	eventSeries,
	eventSeriesUser,
}: EventSeriesUserRowProps) {
	const eventSeriesCtx = useEventSeriesContext();

	const handleDecline = async () => {
		const [req, eventSeriesUsers] = await actions.eventSeriesUsers.update(
			eventSeries.id,
			{
				status: EventSeriesUserResponseStatus.Declined,
				userIDs: [eventSeriesUser.userId],
			}
		);

		if (req) {
			eventSeriesUsers.forEach((user) =>
				eventSeriesCtx.eventSeriesEndpoint.replaceInRecord(
					eventSeries,
					'seriesUsers',
					user
				)
			);
		}
	};

	const handleAccept = async () => {
		const [req, eventSeriesUsers] = await actions.eventSeriesUsers.update(
			eventSeries.id,
			{
				status: EventSeriesUserResponseStatus.Accepted,
				userIDs: [eventSeriesUser.userId],
			}
		);

		if (req) {
			eventSeriesUsers.forEach((user) =>
				eventSeriesCtx.eventSeriesEndpoint.replaceInRecord(
					eventSeries,
					'seriesUsers',
					user
				)
			);
		}
	};

	let button = null;

	if (eventSeriesUser.status === EventSeriesUserResponseStatus.Pending) {
		button = (
			<Context.Menu
				toggleWith={<Button block label={t('No response')} icon="help" />}>
				<Context.Item onClick={handleAccept}>
					<Context.ItemIcon
						name="check_circle"
						fill={styles.palette.green[500]}
					/>
					{t('Accept series')}
				</Context.Item>
				<Context.Item onClick={handleDecline}>
					<Context.ItemIcon name="cancel" fill={styles.palette.red[500]} />
					{t('Decline series')}
				</Context.Item>
			</Context.Menu>
		);
	}

	if (eventSeriesUser.status !== EventSeriesUserResponseStatus.Pending) {
		return (
			<Context.Menu
				toggleWith={<EventSeriesUserCard eventSeriesUser={eventSeriesUser} />}>
				{eventSeriesUser.status === EventSeriesUserResponseStatus.Declined && (
					<Context.Item onClick={handleAccept}>
						<Context.ItemIcon
							name="check_circle"
							fill={styles.palette.green[500]}
						/>
						{t('Accept series')}
					</Context.Item>
				)}
				{eventSeriesUser.status === EventSeriesUserResponseStatus.Accepted && (
					<Context.Item onClick={handleDecline}>
						<Context.ItemIcon name="cancel" fill={styles.palette.red[500]} />
						{t('Decline series')}
					</Context.Item>
				)}
			</Context.Menu>
		);
	}

	return (
		<Fragment>
			<EventSeriesUserCard eventSeriesUser={eventSeriesUser}>
				{button}
			</EventSeriesUserCard>
		</Fragment>
	);
}

export default function EventSeriesAttendance({
	eventSeries,
	eventSeriesUsers,
}: EventSeriesAttendanceProps) {
	return (
		<Column>
			<Column spacing={spacing._1}>
				<div className={css.title}>{t('Respond to event series')}</div>
				<div className={css.description}>
					{t('The response will be set on all events in this series')}
				</div>
			</Column>
			{eventSeriesUsers.length && (
				<Column>
					{eventSeriesUsers.map((user) => (
						<EventSeriesUserRow
							key={user.userId}
							eventSeries={eventSeries}
							eventSeriesUser={user}
						/>
					))}
				</Column>
			)}
		</Column>
	);
}
