import { ReactNode } from 'react';

export const symbol_cool_to_dry: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M160-455q0-66 25-122.5T254-678l194-191q7-7 15.5-10t16.5-3q8 0 16.5 3t15.5 10l194 191q32 32 53.5 69.5T792-526q3 12-4.5 21T769-494q-11 2-21.5-3.5T732-520q-8-32-24.5-60T666-633L480-816 294-633q-36 36-55 80t-19 98q0 91 57.5 159.5T420-207q16 4 21.5 15t3.5 22q-2 11-10.5 18.5T412-147q-109-23-180.5-109T160-455Zm319-54Zm218 154q-18-8-37.5-14t-39.5-6q-16 0-31.5 4T558-361q-10 4-20 1t-14-13q-5-10-.5-19.5T538-407q20-8 40.5-13t41.5-5q23 0 44.5 5.5T707-406q18 8 37.5 14t39.5 6q16 0 31.5-4t30.5-10q10-4 20-1t14 13q5 10 .5 19.5T866-354q-20 8-40.5 13t-41.5 5q-23 0-44.5-5.5T697-355Zm0 130q-18-8-37.5-14t-39.5-6q-16 0-31.5 4T558-231q-10 4-20 1t-14-13q-5-10-.5-19.5T538-277q20-8 40.5-13t41.5-5q23 0 44.5 5.5T707-276q18 8 37.5 14t39.5 6q16 0 31.5-4t30.5-10q10-4 20-1t14 13q5 10 .5 19.5T866-224q-20 8-40.5 13t-41.5 5q-23 0-44.5-5.5T697-225Zm0 130q-18-8-37.5-14t-39.5-6q-16 0-31.5 4T558-101q-10 4-20 .5T524-113q-5-10-.5-19.5T538-147q20-8 40.5-13t41.5-5q23 0 44.5 5.5T707-146q18 8 37.5 14t39.5 6q16 0 31.5-4t30.5-10q10-4 20-1t14 13q5 10 .5 19.5T866-94q-20 8-40.5 13T784-76q-23 0-44.5-5.5T697-95Z"
		/>
	</svg>
);
