import { ReactNode } from 'react';

export const symbol_security_key: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M384-40q-24 0-42-18t-18-42v-145h-24q-24 0-42-18t-18-42v-555q0-24 18-42t42-18h360q24 0 42 18t18 42v555q0 24-18 42t-42 18h-24v145q0 24-18 42t-42 18H384Zm96-429q47.5 0 80.75-33.25T594-583q0-47.5-33.25-80.75T480-697q-47.5 0-80.75 33.25T366-583q0 47.5 33.25 80.75T480-469Zm-96 369h192v-145H384v145Zm-84-205h360v-555H300v555Zm179.965-224Q457-529 441.5-544.535q-15.5-15.535-15.5-38.5t15.535-38.465q15.535-15.5 38.5-15.5t38.465 15.535q15.5 15.535 15.5 38.5T518.465-544.5q-15.535 15.5-38.5 15.5ZM300-305h360-360Z"
		/>
	</svg>
);
