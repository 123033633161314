import { ReactNode } from 'react';

export const symbol_fluid_balance: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M132-220v-520 520ZM750-80q-24.75 0-42.375-17.625T690-140v-62q-73-11-121.5-66.5T520-400v-220q0-24.75 17.25-42.375T580-680h280q24.75 0 42.375 17.625T920-620v220q0 76-49 131.5T750-202v62h100q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q880-97 871.375-88.5T850-80H750Zm40-350h75v-195H575v115h55q30.489 0 57.745 14Q715-482 734-458q10 14 24.5 21t31.5 7Zm-70 175q53 0 92-32.5t50-82.5h-72q-30.848 0-58.424-13.5T686-422q-10.588-12.833-25.294-20.417Q646-450 630-450h-55v50q0 61 42 103t103 42Zm-34-203Zm-256-82q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T430-600H270q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T270-540h160Zm0 180q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T430-420H270q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T270-360h160ZM132-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h726q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T858-740H132v520h318q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T450-160H132Z"
		/>
	</svg>
);
