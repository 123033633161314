import { t } from '@transifex/native';
import { SyntheticEvent } from 'react';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';

import * as Input from 'components/form/inputs';
import Row from 'components/layout/row';

import * as Table from 'design/table';

interface FormsTableProps {
	selected: number[];

	forms: models.form.Form[];

	select: (id: number) => void;
}

interface FormRowProps {
	selected: number[];
	select: (id: number) => void;

	form: models.form.Form;
}

function FormRow({ form, selected, select }: FormRowProps) {
	const handleClick = (e: SyntheticEvent<HTMLDivElement>) => {
		e.stopPropagation();

		select(form.id);
	};

	return (
		<Table.Row>
			<Table.Cell onClick={handleClick}>
				<Row spacing={styles.spacing._2} autoColumns="auto" align="center">
					<div>
						<Input.Control
							type="checkbox"
							checked={selected.includes(form.id)}
						/>
					</div>
					<span>{form.title}</span>
				</Row>
			</Table.Cell>
		</Table.Row>
	);
}

export default function FormsTable({
	forms,
	selected = [],
	select,
}: FormsTableProps) {
	const columns = [
		{
			content: t('Name'),
		},
	];

	return (
		<Table.Table columns={columns}>
			{forms.map((form) => (
				<FormRow
					key={form.id}
					form={form}
					selected={selected}
					select={select}
				/>
			))}
		</Table.Table>
	);
}
