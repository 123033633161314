export default {
	name: 'nav-attendance',
	objects: [
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M12 20.0539C15.6887 20.0539 18.679 17.0636 18.679 13.3749C18.679 9.68617 15.6887 6.69587 12 6.69587C8.31125 6.69587 5.32095 9.68617 5.32095 13.3749C5.32095 17.0636 8.31125 20.0539 12 20.0539ZM12 21.4677C16.4695 21.4677 20.0928 17.8444 20.0928 13.3749C20.0928 8.90534 16.4695 5.28205 12 5.28205C7.53041 5.28205 3.90713 8.90534 3.90713 13.3749C3.90713 17.8444 7.53041 21.4677 12 21.4677Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M8.05602 3.6779C8.05602 3.28749 8.37252 2.97099 8.76293 2.97099H15.2372C15.6276 2.97099 15.9441 3.28749 15.9441 3.6779C15.9441 4.06832 15.6276 4.38481 15.2372 4.38481H12.707V5.8072C12.707 6.19762 12.3905 6.51412 12.0001 6.51412C11.6096 6.51412 11.2932 6.19762 11.2932 5.8072V4.38481H8.76293C8.37252 4.38481 8.05602 4.06832 8.05602 3.6779Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M17.4322 6.71641C17.8972 6.21422 18.6813 6.18405 19.1835 6.64903C19.6857 7.11401 19.7158 7.89806 19.2508 8.40026L18.3083 9.41825L16.4897 7.73441L17.4322 6.71641Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M15.2439 10.8654C15.52 11.1414 15.52 11.589 15.2439 11.8651L11.2246 15.8844C10.9485 16.1605 10.5009 16.1605 10.2249 15.8844L8.15431 13.8138C7.87824 13.5378 7.87824 13.0902 8.15431 12.8141C8.43037 12.538 8.87796 12.538 9.15403 12.8141L10.7247 14.3848L14.2442 10.8654C14.5202 10.5893 14.9678 10.5893 15.2439 10.8654Z',
			},
		},
	],
};
