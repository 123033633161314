export default {
	name: 'track-changes',
	viewBox: '0 0 24 24',
	objects: [
		{
			type: 'path',
			attributes: {
				d: 'M19.6968 11.2785C19.6968 12.7454 19.3138 14.1228 18.6423 15.3162H19.0065L21.7972 18.1069H19.0365L16.2551 15.3162H17.1034C17.9176 14.1784 18.3968 12.7844 18.3968 11.2785C18.3968 7.44237 15.287 4.33253 11.4508 4.33253C7.61461 4.33253 4.50477 7.44237 4.50477 11.2785C4.50477 15.1147 7.61461 18.2246 11.4508 18.2246C12.9402 18.2246 14.3202 17.7558 15.4511 16.9577V15.9891L18.2418 18.7892V21.5519L15.4511 18.7612V18.491C14.2664 19.1495 12.9024 19.5246 11.4508 19.5246C6.89664 19.5246 3.20477 15.8327 3.20477 11.2785C3.20477 6.7244 6.89664 3.03253 11.4508 3.03253C16.0049 3.03253 19.6968 6.7244 19.6968 11.2785Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M15.0371 15.5737C14.066 16.3854 12.8155 16.874 11.4508 16.874C8.36056 16.874 5.85541 14.3688 5.85541 11.2786C5.85541 8.18829 8.36056 5.68314 11.4508 5.68314C14.5411 5.68314 17.0462 8.18829 17.0462 11.2786C17.0462 12.6281 16.5685 13.866 15.7729 14.8324L14.8493 13.9058C15.4116 13.1795 15.7462 12.2681 15.7462 11.2786C15.7462 8.90626 13.8231 6.98314 11.4508 6.98314C9.07853 6.98314 7.15541 8.90626 7.15541 11.2786C7.15541 13.6508 9.07853 15.574 11.4508 15.574C12.4572 15.574 13.3827 15.2279 14.1148 14.6483L15.0371 15.5737Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M13.4733 12.5251C13.6971 12.1628 13.8263 11.7357 13.8263 11.2785C13.8263 9.96663 12.7627 8.90311 11.4508 8.90311C10.1389 8.90311 9.07538 9.96663 9.07538 11.2785C9.07538 12.5905 10.1389 13.654 11.4508 13.654C11.9274 13.654 12.3712 13.5136 12.7432 13.272L11.1791 11.7026C11.0855 11.6038 11.0341 11.4723 11.036 11.3362C11.0379 11.2001 11.0928 11.0701 11.1891 10.9739C11.2854 10.8777 11.4154 10.8229 11.5515 10.8212C11.6877 10.8194 11.8191 10.8709 11.9178 10.9647L11.9218 10.9684L13.4733 12.5251Z',
			},
		},
	],
};
