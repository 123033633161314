import { ReactNode } from 'react';

export const symbol_check_small: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m400-402 250-250q9-9 21-9t21 9q9 9 9 21t-9 21L421-339q-9 9-21 9t-21-9L268-450q-9-9-9-21t9-21q9-9 21-9t21 9l90 90Z"
		/>
	</svg>
);
