import User from 'pkg/models/user';

import { AUTH_LOGOUT_SUCCESS, Users } from 'pkg/actions/action-types';

const initialState = {
	entities: {},
	meta: {},
	collectionOrder: '',
	links: {},
	page: 0,
	pageCount: 0,
	recordCount: 0,
	recordOrder: '',
	recordsPerPage: 0,
};

const activityList = (state = initialState, action) => {
	switch (action.type) {
		case Users.SET_ACTIVE_MEMBERSHIP: {
			return initialState;
		}

		case 'SET_ACTIVITIES': {
			const { collection } = action;
			const entities = {};

			for (let i = 0; i < collection.records.length; i++) {
				const item = collection.records[i];
				item.user = new User(item.user);
				entities[item.id] = item;
			}

			return {
				...state,
				meta: collection.meta,
				collectionOrder: collection.collectionOrder,
				links: collection.links,
				page: collection.page,
				pageCount: collection.pageCount,
				recordCount: collection.recordCount,
				recordOrder: collection.recordOrder,
				recordsPerPage: collection.recordsPerPage,
				entities: {
					...state.entities,
					...entities,
				},
			};
		}

		case 'REMOVE_ACTIVITY': {
			return {
				...state,
				entities: state.entities.filter((e) => e.id !== action.id),
				recordCount: state.recordCount - 1,
			};
		}

		case AUTH_LOGOUT_SUCCESS:
			return initialState;

		default:
			return state;
	}
};

export default activityList;
