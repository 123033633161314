import { ReactNode } from 'react';

export const symbol_fire_hydrant: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M240-140v-130h-40q-33 0-56.5-23.5T120-350v-80q0-33 23.5-56.5T200-510h40v-130h-50q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T190-700h58q20-78 83.5-129T480-880q85 0 148.5 51T712-700h58q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T770-640h-50v130h40q33 0 56.5 23.5T840-430v80q0 33-23.5 56.5T760-270h-40v130h50q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q800-97 791.375-88.5T770-80H190q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T190-140h50Zm70-560h340q-19-53-65-86.5T480-820q-59 0-105 33.5T310-700Zm-10 560h360v-190h120v-120H660v-190H300v190H180v120h120v190Zm180-110q58 0 99-41t41-99q0-58-41-99t-99-41q-58 0-99 41t-41 99q0 58 41 99t99 41Zm.235-60Q447-310 423.5-333.265q-23.5-23.264-23.5-56.5Q400-423 423.265-446.5q23.264-23.5 56.5-23.5Q513-470 536.5-446.735q23.5 23.264 23.5 56.5Q560-357 536.735-333.5q-23.264 23.5-56.5 23.5ZM480-390Zm0-250Z"
		/>
	</svg>
);
