import { ReactNode } from 'react';

export const symbol_empty_dashboard: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M250-220h180q12 0 21-9t9-21v-120q0-12-9-21t-21-9H250q-12 0-21 9t-9 21v120q0 12 9 21t21 9Zm0-220h180q12 0 21-9t9-21v-240q0-12-9-21t-21-9H250q-12 0-21 9t-9 21v240q0 12 9 21t21 9Zm280 220h180q12 0 21-9t9-21v-240q0-12-9-21t-21-9H530q-12 0-21 9t-9 21v240q0 12 9 21t21 9Zm0-340h180q12 0 21-9t9-21v-120q0-12-9-21t-21-9H530q-12 0-21 9t-9 21v120q0 12 9 21t21 9ZM180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v60h30q13 0 21.5 8.5T900-690q0 13-8.5 21.5T870-660h-30v150h30q13 0 21.5 8.5T900-480q0 13-8.5 21.5T870-450h-30v150h30q13 0 21.5 8.5T900-270q0 13-8.5 21.5T870-240h-30v60q0 24-18 42t-42 18H180Zm0-60h600v-600H180v600Zm0-600v600-600Z"
		/>
	</svg>
);
