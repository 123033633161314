import { ReactNode } from 'react';

export const symbol_clean_hands: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m562-110 248-78q0-20-15.5-34T758-235H549q-16 1-31.5-2t-30.5-8l-66-20q-12-4-18-16t-2-24q4-12 15.278-18T440-324l64 21q11 4 22.5 6t22.5 2h56q2 0 0 0 0-21-14-36.5T557-355l-218-83h-84v238l307 90Zm-13 57-294-84q-2 27-22.5 42T195-80h-95q-24.75 0-42.375-17.625T40-140v-298q0-24.75 17.625-42.375T100-498h238q5.333 0 10.667 1Q354-496 359-494l218 82q42 16 66.5 46t24.5 71h90q50.833 0 86.417 37Q880-221 880-170q0 11-5.5 20T859-138L583-53q-8.171 2-17.086 2Q557-51 549-53Zm-449-87h94v-298h-94v298Zm490-331-60-23v-36q0-54-38.008-92-38.009-38-91.992-38-46 0-81 28.5T272.549-558H211q10-61 54-103.5T370-718v-102h-30q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T340-880h180q26 0 48.5 6.5T612-856q8.75 5 14.875 12.5T633-826q0 12.854-8.865 21.927Q615.271-795 602.167-795q-6.167 0-11.667-3t-10.5-6q-13-8-28-12t-32-4h-90v102q69 13.545 114.5 66.272Q590-599 590-530v59Zm-190-63Zm367.788-50Q738-584 717-605.15 696-626.3 696-656q0-38 20-69.5t43-62.5q1-2 9-4l9 4q23 31 43 62.5t20 69.5q0 29.7-21.212 50.85-21.213 21.15-51 21.15Z"
		/>
	</svg>
);
