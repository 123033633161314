import { ReactNode, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import { cssClasses } from 'pkg/css/utils';

import MaterialSymbol from 'components/material-symbols';

import Row from 'components/layout/row';

import * as css from './styles.css';

interface CollapsiblePanelProps {
	heading: string;
	defaultCollapsed?: boolean;

	// Rendered on right side of header
	additionalAction?: ReactNode;

	children: ReactNode | ReactNode[];
}

export default function CollapsiblePanel({
	heading,
	defaultCollapsed = false,

	additionalAction,
	children,
}: CollapsiblePanelProps): JSX.Element {
	const [isCollapsed, setCollapsed] = useState<boolean>(defaultCollapsed);

	const toggleCollapsed = () => setCollapsed(!isCollapsed);

	return (
		<section className={css.panel}>
			<AnimatePresence>
				<Row
					columns="auto 1fr auto"
					align="start"
					spacing="0px"
					className={css.header}>
					<div className={css.action} onClick={toggleCollapsed}>
						<MaterialSymbol
							variant="keyboard_arrow_down"
							scale={1.5}
							className={cssClasses(
								css.actionItem,
								isCollapsed ? css.collapsed : null
							)}
						/>
					</div>
					<span
						title={heading}
						className={css.heading}
						onClick={toggleCollapsed}>
						{heading}
					</span>
					<div className={css.action}>{additionalAction}</div>
				</Row>

				{!isCollapsed && (
					<motion.div
						key="collapsible-content"
						initial="collapsed"
						animate="open"
						exit="collapsed"
						variants={{
							open: { height: 'auto' },
							collapsed: { height: 0 },
						}}
						className={css.content}>
						<div className={css.innerContent}>{children}</div>
					</motion.div>
				)}
			</AnimatePresence>
		</section>
	);
}
