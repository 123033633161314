import { ReactNode } from 'react';

export const symbol_approval: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M220-80q-24.75 0-42.375-17.625T160-140v-200q0-24 18-42t42-18h520q24 0 42 18t18 42v200q0 24.75-17.625 42.375T740-80H220Zm50-160h420q21.25 0 35.625-14.325Q740-268.649 740-289.825 740-311 725.625-325.5 711.25-340 690-340H270q-21.25 0-35.625 14.325Q220-311.351 220-290.175 220-269 234.375-254.5 248.75-240 270-240Zm186-194L295-660q-7-10-9.5-21.213Q283-692.426 285-704q12-76 63-126t132-50q81 0 132 50t63 126q2 11.574-.5 22.787T665-660L504-434q-8.667 13-23.833 13Q465-421 456-434Zm24-44 139-202q0-58.333-40.559-99.167Q537.882-820 479.941-820T381.5-779.167Q341-738.333 341-680l139 202Zm0-171Z"
		/>
	</svg>
);
