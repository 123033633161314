import { ReactNode } from 'react';

export const symbol_add_to_drive: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M210-100q-12 0-25.5-7.5T165-126L55-318q-6-11-6-26t6-26l270-464q6-11 19.5-18.5T370-860h220q12 0 25.5 7.5T635-834l157 270q9 16-.5 31.5T763-519q-7-1-13-5t-10-10L585-800H375L109-344l105 184h324q7 0 12.5 3t10.5 8q12 15 4 32t-27 17H210Zm97-180q-8 0-15-4t-11-11l-12-21q-4-7-3.5-15t4.5-15l165-289q4-7 11-11t15-4h38q8 0 15 4t11 11l81 141q5 9 3.5 18.5T600-458q-11 11-24.5 9T554-464l-74-128-145 252h159q14 0 22.5 10t6.5 24q-2 11-10 18.5t-19 7.5H307Zm423 30h-90q-13 0-21.5-8.5T610-280q0-13 8.5-21.5T640-310h90v-90q0-13 8.5-21.5T760-430q13 0 21.5 8.5T790-400v90h90q13 0 21.5 8.5T910-280q0 13-8.5 21.5T880-250h-90v90q0 13-8.5 21.5T760-130q-13 0-21.5-8.5T730-160v-90Z"
		/>
	</svg>
);
