import { ReactNode } from 'react';

export const symbol_home_improvement_and_tools: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-92q-6 0-11-2t-10-7l-78-78q-8-8-13-19.5t-5-23.5v-480l-90-131q-11-15-2.5-31t26.5-16h366q18 0 26.5 16t-2.5 31l-90 131v480q0 12-5 23.5T579-179l-78 78q-5 5-10 7t-11 2Zm-57-459h114v-178l63-91H360l63 91v178Zm114 60H423v118h114v-118Zm0 261v-83H423v83l57 57 57-57Zm-57-321Zm0 178Zm0-178Zm0 60Zm0 178Z"
		/>
	</svg>
);
