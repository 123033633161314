// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SogyZ9gXuwViIZUlsVO2 {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	padding-bottom: var(--spacing-2);
}
`, "",{"version":3,"sources":["webpack://./routes/form_submissions/single/SideBar/styles.css"],"names":[],"mappings":"AAAA;CACC,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,wBAAuB;KAAvB,qBAAuB;SAAvB,uBAAuB;CACvB,gCAAgC;AACjC","sourcesContent":[".statusLabel {\n\tdisplay: flex;\n\tjustify-content: center;\n\tpadding-bottom: var(--spacing-2);\n}\n"],"sourceRoot":""}]);
// Exports
export var statusLabel = `SogyZ9gXuwViIZUlsVO2`;
export default ___CSS_LOADER_EXPORT___;
