import { Fragment } from 'react';

import { useCollection } from 'pkg/api/use_collection';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import { AccountRelationTypes } from 'pkg/api/models/account_relation';
import { useCurrentAccount, useCurrentMembership } from 'pkg/identity';

import PendingInvites from 'routes/account/settings/child-relations/PendingInvites';
import AccountsUnderDeletion from 'routes/account/settings/child-relations/AccountsUnderDeletion';
import ActiveRelations from 'routes/account/settings/child-relations/active-relations';
import ConnectedIncomingRelations from 'routes/account/settings/child-relations/ConnectedIncomingRelations';
import CreateRelations from 'routes/account/settings/child-relations/CreateRelations';
import IncomingRelations from 'routes/account/settings/child-relations/IncomingRelations';
import PendingRelations from 'routes/account/settings/child-relations/PendingRelations';
import { Group } from 'routes/account/settings/child-relations/styles';

export default function AccountRelations(): JSX.Element {
	const account = useCurrentAccount();
	const membership = useCurrentMembership();

	const relations = useCollection<models.accountRelation.AccountRelation>(
		endpoints.AccountRelations.Index()
	);

	const incoming = useCollection<models.accountRelation.AccountRelation>(
		endpoints.AccountRelations.Index(),
		{
			queryParams: new URLSearchParams({
				requests: '1',
			}),
		}
	);

	const invites = useCollection<models.accountInvite.AccountInvite>(
		endpoints.AccountInvites.Index()
	);

	const wardAccounts: models.account.Account[] = [];
	const wardAccountsUnderDeletion: models.account.Account[] = [];

	const active = relations.records.filter(
		(relation: models.accountRelation.AccountRelation) =>
			relation.type === AccountRelationTypes.LegalGuardian &&
			relation.approvedAt
	);

	const pending = relations.records.filter((r) =>
		models.accountRelation.isPending(r)
	);

	active.forEach((r) => {
		if (r.targetAccount.willBeDeletedAt) {
			wardAccountsUnderDeletion.push(r.targetAccount);
		} else {
			wardAccounts.push(r.targetAccount);
		}
	});

	const showEmptyState =
		incoming.records.length === 0 &&
		relations.records.length === 0 &&
		!membership.isLegalGuardian &&
		!models.account.isAnAdult(account);

	const refreshAll = async (): Promise<void> => {
		await relations.refresh();
		await incoming.refresh();
		await invites.refresh();
	};

	if (showEmptyState) {
		return null;
	}

	return (
		<Fragment>
			<IncomingRelations
				refreshRelations={refreshAll}
				relations={incoming.records}
			/>
			<PendingRelations refreshRelations={refreshAll} relations={pending} />
			<PendingInvites invites={invites} />
			{wardAccounts.length > 0 && (
				<Group data-no-border>
					{wardAccounts.map((account: models.account.Account) => (
						<ConnectedIncomingRelations
							key={account.id}
							account={account}
							refreshRelations={refreshAll}
						/>
					))}
				</Group>
			)}
			<ActiveRelations refreshRelations={refreshAll} relations={active} />
			{wardAccountsUnderDeletion.length > 0 && (
				<Group data-no-border>
					<AccountsUnderDeletion
						key={account.id}
						accounts={wardAccountsUnderDeletion}
						refreshRelations={refreshAll}
					/>
				</Group>
			)}
			<CreateRelations refreshRelations={refreshAll} />
		</Fragment>
	);
}
