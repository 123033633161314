import { ReactNode } from 'react';

export const symbol_auto_transmission: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M159.882-120Q114-120 82-152.083 50-184.167 50-230q0-38 22.5-67t57.5-39v-288q-35-10-57.5-39T50-730q0-45.833 32.118-77.917 32.117-32.083 78-32.083Q206-840 238-807.917q32 32.084 32 77.917 0 38-22.5 67T190-624v114h260v-114q-35-10-57.5-39T370-730q0-45.833 32.118-77.917 32.117-32.083 78-32.083Q526-840 558-807.917q32 32.084 32 77.917 0 38-22.5 67T510-624v114h210q21.25 0 35.625-15T770-560v-64q-35-10-57.5-39T690-730q0-45.833 32.118-77.917 32.117-32.083 78-32.083Q846-840 878-807.917q32 32.084 32 77.917 0 38-22.5 67T830-624v64q0 45-32.083 77.5Q765.833-450 720-450H510v114q35 10 57.5 39t22.5 67q0 45.833-32.118 77.917-32.117 32.083-78 32.083Q434-120 402-152.083 370-184.167 370-230q0-38 22.5-67t57.5-39v-114H190v114q35 10 57.5 39t22.5 67q0 45.833-32.118 77.917-32.117 32.083-78 32.083Zm.118-60q20 0 35-14.375T210-230q0-20-14.325-35-14.324-15-35.5-15Q139-280 124.5-265 110-250 110-229.5t14.375 35Q138.75-180 160-180Zm0-500q20 0 35-14.325 15-14.324 15-35.5Q210-751 195.675-765.5q-14.324-14.5-35.5-14.5Q139-780 124.5-765.625 110-751.25 110-730q0 20 14.375 35T160-680Zm320 500q20 0 35-14.375T530-230q0-20-15-35t-35-15q-20 0-35 15t-15 35.5q0 20.5 15 35t35 14.5Zm0-500q20 0 35-14.325 15-14.324 15-35.5Q530-751 515-765.5 500-780 480-780t-35 14.325q-15 14.324-15 35.5Q430-709 445-694.5q15 14.5 35 14.5Zm320.5 0q20.5 0 35-14.325 14.5-14.324 14.5-35.5Q850-751 835.625-765.5 821.25-780 800-780q-20 0-35 14.325-15 14.324-15 35.5Q750-709 765-694.5q15 14.5 35.5 14.5ZM160-230Zm0-500Zm320 500Zm0-500Zm320 0Z"
		/>
	</svg>
);
