import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCollection } from 'pkg/api/use_collection';

import * as StepModal from 'components/step-modal';

import * as Input from 'components/form/inputs';

interface ReplaceTemplateModalProps {
	groupId: number;

	hideModal: () => void;
}

const ReplaceTemplateModal = ({
	hideModal,
	groupId,
}: ReplaceTemplateModalProps) => {
	const { records: templates } = useCollection<models.schedule.Schedule>(
		endpoints.Schedule.Index(),
		{
			queryParams: new URLSearchParams({
				group_id: groupId.toString(),
			}),
		}
	);

	return (
		<StepModal.Base onClose={hideModal}>
			<StepModal.Step
				title="Replace with template"
				nextLabel="Confirm replacement">
				<Input.Group label="Template:">
					<Input.Select name="template">
						<option>Select template</option>
						{templates.map((t, i) => (
							<option key={i} value={t.id}>
								{t.title}
							</option>
						))}
					</Input.Select>
				</Input.Group>
			</StepModal.Step>
		</StepModal.Base>
	);
};

export default ReplaceTemplateModal;
