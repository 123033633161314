import { ReactNode } from 'react';

export const symbol_drag_click: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-240q-79 0-143-46.5T250-409q-3-11 3-21t18-13q12-3 23 3.5t16 19.5q19 54 65.5 87T480-300q75 0 127.5-52.5T660-480q0-57-33-104t-87-66q-14-5-20-16t-3-23q3-12 13-18t21-3q75 23 122 86.5T720-480q0 100-70 170t-170 70Zm0 160q-163 0-279-112.5T80-468q0-12 8.5-20.5T109-498q12-1 21.5 8t10.5 22q11 145 105.5 236.5T480-140q142 0 241-99t99-241q0-138-93.5-234.5T491-819q-13-1-21-10t-8-22q0-12 8.5-20t20.5-8q81 2 152 34.5T766.5-758q52.5 54 83 125.5T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm-97-420L251-631l-35 106q-4 11-15 10.5T187-526L89-853q-2-8 4.5-14.5T108-872l327 98q11 3 11.5 14T436-745l-106 35 132 132q17 17 17 39t-17 39q-17 17-39.5 17T383-500Z"
		/>
	</svg>
);
