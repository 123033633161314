import { ReactNode } from 'react';

export const symbol_quick_reference_all: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-820v293-3 390-680 186-186Zm129 400h146q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T455-480H309q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T309-420Zm0 170h90q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T399-310h-90q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T309-250ZM180-80q-24 0-42-18t-18-42v-680q0-24 18-42t42-18h336q12.444 0 23.722 5T559-862l183 183q8 8 13 19.278 5 11.278 5 23.722v99q0 13.077-8.675 21.538-8.676 8.462-21.5 8.462-12.825 0-21.325-8.625T700-537v-97H541q-12.75 0-21.375-8.625T511-664v-156H180v680h313q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q523-97 514.375-88.5T493-80H180Zm480-110q47 0 78.5-31.5T770-300q0-47-31.5-78.5T660-410q-47 0-78.5 31.5T550-300q0 47 31.5 78.5T660-190ZM885-75q-9 9-21 9t-21-9l-86-86q-21 14-45.5 22.5T660-130q-70.833 0-120.417-49.618Q490-229.235 490-300.118 490-371 539.618-420.5q49.617-49.5 120.5-49.5Q731-470 780.5-420.417 830-370.833 830-300q0 27-8.5 51.5T799-203l86 86q9 9 9 21t-9 21Z"
		/>
	</svg>
);
