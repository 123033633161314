import { ReactNode } from 'react';

export const symbol_routine: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M403-403q-35-35-64-74.5T287-562q-8 20-12 40.5t-4 41.5q0 87.171 60.915 148.085Q392.829-271 480-271q21 0 41.233-4 20.232-4 39.767-12-45-23-83.775-51.994Q438.451-367.988 403-403Zm78 193q-54.228 0-103.614-20.5T290-289q-38-38-58.5-87.386Q211-425.772 211-480q0-45 15-87.5t42-77.5q11-14 28-13t22 16q20 57 53 106t75 91q42 42 91.5 74.5T643-317q14 5 15.5 21.5T646-268q-34.809 28.567-77.405 43.284Q526-210 481-210Zm210-155q-11.2-3.611-16.1-14.806Q670-391 675-402q23-58 11-120t-57-107q-44-44-102.5-56.5T411-678q-12 5-24.083.333Q374.833-682.333 370-693q-6-11.478-1.5-22.239Q373-726 384-731q75-29 152-14t136.068 74.068Q730-613 746-536q16 77-13 152-5 13-16.808 18.5-11.809 5.5-25.192.5ZM479.825-840Q467-840 458.5-848.625T450-870v-60q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-930v60q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Zm0 840Q467 0 458.5-8.625T450-30v-60q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-90v60q0 12.75-8.675 21.375Q492.649 0 479.825 0ZM735-734.053q-9-9.052-9-21.5Q726-768 735-777l42-42q9-8 21-8.5t21 8.109q9 9.391 9 21.858 0 12.466-9 21.533l-42 42q-9 9-21 9t-21-9.053ZM141-140.609q-9-9.391-9-21.858 0-12.466 9-21.533l42-42q9-9 21-9t21 9.053q9 9.052 9 21.5Q234-192 225-183l-42 42q-9 8-21 8.5t-21-8.109ZM870-450q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T870-510h60q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T930-450h-60Zm-840 0q-12.75 0-21.375-8.675Q0-467.351 0-480.175 0-493 8.625-501.5T30-510h60q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T90-450H30Zm789.391 309q-9.391 9-21.858 9-12.466 0-21.533-9l-42-42q-9-9-9-21t9.053-21q9.052-9 21.5-9Q768-234 777-225l42 42q8 9 8.5 21t-8.109 21ZM225.947-735q-9.052 9-21.5 9Q192-726 183-735l-42-42q-8-9-8.5-21t8.109-21q9.391-9 21.858-9 12.466 0 21.533 9l42 42q9 9 9 21t-9.053 21ZM403-403Z"
		/>
	</svg>
);
