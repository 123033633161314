import { Fragment } from 'react';
import { t } from '@transifex/native';

import Videos from 'routes/video/library/group/Videos';
import Playlists from 'routes/video/library/group/Playlists';
import Collections from 'routes/video/library/group/Collections';
import { LibraryResponse } from 'routes/video/library/hooks';

import { useContentTypeFilter } from 'components/library/hooks';
import { Spinner } from 'components/loaders/spinner';
import EmptyState from 'components/library/VideoEmptyState';

interface LibraryProps {
	library: LibraryResponse;
}

export default function Library({ library }: LibraryProps): JSX.Element {
	const contentType = useContentTypeFilter();

	const videos = library.videos?.records;
	const hasVideos = videos?.length > 0;

	const playlists = library.playlists?.records;
	const hasPlaylists = playlists?.length > 0;

	const collections = library.collections?.records;
	const hasCollections = collections?.length > 0;

	const hasContent =
		!library.isLoading && (hasVideos || hasPlaylists || hasCollections);

	return (
		<Fragment>
			{!hasContent && !library.isLoading && <EmptyState />}

			{hasVideos && contentType.videos && (
				<Videos
					title={t('Videos')}
					items={videos}
					hasNext={library.videos.pagination.hasNext}
					fetchNext={library.videos.pagination.fetchNext}
					replaceRecord={library.videos.replaceRecord}
					removeRecord={library.videos.removeRecord}
				/>
			)}

			{library.numLoadingStates === 1 &&
				library.videos?.isLoading &&
				contentType.videos && <Spinner />}

			{hasPlaylists && contentType.playlists && (
				<Playlists
					title={t('Playlists')}
					items={playlists}
					hasNext={library.playlists.pagination.hasNext}
					fetchNext={library.playlists.pagination.fetchNext}
					replaceRecord={library.playlists.replaceRecord}
					removeRecord={library.playlists.removeRecord}
				/>
			)}

			{library.numLoadingStates === 1 &&
				library.playlists?.isLoading &&
				contentType.playlists && <Spinner />}

			{hasCollections && contentType.collections && (
				<Collections
					title={t('Collections')}
					items={collections}
					hasNext={library.collections.pagination.hasNext}
					fetchNext={library.collections.pagination.fetchNext}
					replaceRecord={library.collections.replaceRecord}
					removeRecord={library.collections.removeRecord}
				/>
			)}

			{library.numLoadingStates === 1 &&
				library.collections?.isLoading &&
				contentType.collections && <Spinner />}
		</Fragment>
	);
}
