import { Fragment, SyntheticEvent, useContext, useEffect } from 'react';
import { t } from '@transifex/native';

import { getIncrementedUnixTimestamp, isSameDay } from 'pkg/date';
import DateTime, { Granularity } from 'pkg/datetime';
import { AutoChargeOptions } from 'pkg/api/models/provider_settings';
import { usePaymentProviderContext } from 'pkg/contexts/provider_settings';

import {
	AddOrderContext,
	AssignedProductProps,
} from 'routes/payments/orders/create';
import SelectableOptions from 'routes/payments/orders/create/components/selectable_options';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import DatePicker from 'components/form/date-picker';
import * as Input from 'components/form/inputs';
import Row from 'components/layout/row';
import Column from 'components/layout/column';
import InfoBox from 'components/form/info-box';

enum SubscriptionStartOptionValues {
	Immediately = 'immediately',
	FirstOfMonth = 'firstOfMonth',
	Custom = 'custom',
}

export enum SubscriptionEndOptionValues {
	Subscription = 'subscription',
	Installment = 'installment',
}

enum ValidUntilOptionValues {
	CustomDate = 'customDate',
	InstallmentPeriod = 'installmentPeriod',
}

interface RecurringOptionsProps {
	assignedProducts: AssignedProductProps[];
}

function RecurringOptions({ assignedProducts }: RecurringOptionsProps) {
	const OrderContext = useContext(AddOrderContext);
	const paymentProviderContext = usePaymentProviderContext();

	const todayDate = new Date();
	const dateTime = new DateTime(todayDate);
	const now = DateTime.now().getUnixTimestamp();

	const orderStartAtIsToday = isSameDay(
		new Date(now * 1000),
		new DateTime(new Date(OrderContext.formState.startAt * 1000))
	);

	const startsAtDateTime = DateTime.fromTimestamp(
		OrderContext.formState.startAt
	);
	const endsAtDateTime = DateTime.fromTimestamp(OrderContext.formState.endAt);
	const validToDate = DateTime.fromTimestamp(OrderContext.formState.validTo);

	const startOfMonth = dateTime.startOfMonth;
	const startOfNextMonthDate = new DateTime(new Date(startOfMonth)).next(
		Granularity.month
	);

	const recurringInterval = assignedProducts[0].productPrice.recurringInterval;
	const recurringIntervalCount =
		assignedProducts[0].productPrice.recurringIntervalCount;

	// When incrementing the installmentCount we need to subtract one from the value because
	// we pay in advance
	const incrementInstallmentCountValue =
		OrderContext.formState.installmentCount - 1;

	useEffect(() => {
		// Ends at should change when starts at date is changed
		if (OrderContext.formState.endAt !== null) {
			// We update ends at from the starts at value
			if (
				EndsAtInputs.selected.value === SubscriptionEndOptionValues.Installment
			) {
				const newDateTimestamp = getIncrementedUnixTimestamp(
					recurringInterval,
					OrderContext.formState.startAt * 1000,
					recurringIntervalCount * incrementInstallmentCountValue
				);

				OrderContext.setFormState({
					endAt: newDateTimestamp,
				});
			}
			// If the new starts at date is after the set endsAt we just add one day
			// This can only happen when the custom date option is set on end date
			else if (OrderContext.formState.startAt >= OrderContext.formState.endAt) {
				OrderContext.setFormState({
					endAt: startsAtDateTime.next(Granularity.day, 1).getUnixTimestamp(),
				});
			}
		}
	}, [OrderContext.formState.startAt]);

	useEffect(() => {
		// Valid to input should change when last payment input changes
		if (OrderContext.formState.endAt) {
			if (
				ValidUntilInputs.selected.value ===
				ValidUntilOptionValues.InstallmentPeriod
			) {
				const newDateTimestamp = getIncrementedUnixTimestamp(
					recurringInterval,
					OrderContext.formState.endAt * 1000,
					recurringIntervalCount
				);

				OrderContext.setFormState({
					validTo: newDateTimestamp,
				});
			}
		}
	}, [OrderContext.formState.endAt]);

	// if the recurringInterval change and we're creating an installment we need to update endsAt
	useEffect(() => {
		if (
			EndsAtInputs.selected.value === SubscriptionEndOptionValues.Installment
		) {
			const newDateTimestamp = getIncrementedUnixTimestamp(
				recurringInterval,
				OrderContext.formState.startAt * 1000,
				recurringIntervalCount * incrementInstallmentCountValue
			);

			OrderContext.setFormState({
				endAt: newDateTimestamp,
			});
		}
	}, [recurringInterval, recurringIntervalCount]);

	const handleSubStartChange = (value: SubscriptionStartOptionValues) => {
		if (value === SubscriptionStartOptionValues.Immediately) {
			OrderContext.setFormState({
				startAt: dateTime.getUnixTimestamp(),
			});
		} else if (value === SubscriptionStartOptionValues.FirstOfMonth) {
			OrderContext.setFormState({
				startAt: startOfNextMonthDate.getUnixTimestamp(),
			});
		} else {
			OrderContext.setFormState({
				startAt: dateTime.getUnixTimestamp(),
			});
		}
	};

	const handleSubEndChange = (value: SubscriptionEndOptionValues) => {
		if (value === SubscriptionEndOptionValues.Subscription) {
			OrderContext.setFormState({
				endAt: null,
				installmentCount: null,
			});
		} else if (value === SubscriptionEndOptionValues.Installment) {
			if (assignedProducts.length > 0) {
				const newDateTimestamp = getIncrementedUnixTimestamp(
					recurringInterval,
					OrderContext.formState.startAt * 1000,
					recurringIntervalCount * (incrementInstallmentCountValue || 1)
				);

				OrderContext.setFormState({
					endAt: newDateTimestamp,
					installmentCount: 2,
				});
			}
		}
	};

	const handleValidUntilChange = (value: ValidUntilOptionValues) => {
		if (value === ValidUntilOptionValues.InstallmentPeriod) {
			const newDateTimestamp = getIncrementedUnixTimestamp(
				recurringInterval,
				OrderContext.formState.endAt * 1000,
				1
			);

			OrderContext.setFormState({
				validTo: newDateTimestamp,
			});
		}
	};

	const handleAutoChargeToggle = () => {
		OrderContext.setFormState({
			collectionMethod:
				OrderContext.formState.collectionMethod === 'manual'
					? 'automatic'
					: 'manual',
		});
	};

	const handleSubStartDateChange = (dates: Date[]) => {
		OrderContext.setFormState({
			startAt: new DateTime(new Date(dates[0])).getUnixTimestamp(),
		});
	};

	const handleDateChange = (dates: Date[]) => {
		OrderContext.setFormState({
			validTo: new DateTime(new Date(dates[0])).getUnixTimestamp(),
		});
	};

	const handleChangeInstallmentCount = (
		event: SyntheticEvent<HTMLInputElement>
	) => {
		const value = Number.parseInt(event.currentTarget.value, 10);
		const newDateTimestamp = getIncrementedUnixTimestamp(
			recurringInterval,
			OrderContext.formState.startAt * 1000,
			recurringIntervalCount * (value - 1)
		);

		OrderContext.setFormState({
			installmentCount: value,
			endAt: newDateTimestamp,
		});
	};

	const StartsAtInputs = SelectableOptions<SubscriptionStartOptionValues>({
		selectableOptions: [
			{
				value: SubscriptionStartOptionValues.Immediately,
				translation: t('Immediately'),
				onClick: () =>
					handleSubStartChange(SubscriptionStartOptionValues.Immediately),
			},
			{
				value: SubscriptionStartOptionValues.FirstOfMonth,
				translation: t('On the 1st of next month'),
				onClick: () =>
					handleSubStartChange(SubscriptionStartOptionValues.FirstOfMonth),
			},
			{
				value: SubscriptionStartOptionValues.Custom,
				contextMenuTranslation: startsAtDateTime.toLocaleDateString({
					month: 'short',
					day: 'numeric',
					year: 'numeric',
				}),
				translation: t('A custom date'),
				children: (
					<DatePicker
						name="subStartDate"
						handleChange={handleSubStartDateChange}
						dates={[new Date(OrderContext.formState.startAt * 1000)]}
						disableDatesBefore={todayDate}
					/>
				),
			},
		],
		// If we're duplicating the invoice and the startDate and now isn't the same we set
		// the default selected value to custom
		defaultSelectedValue:
			OrderContext.formState.hasOrderId &&
			!orderStartAtIsToday &&
			SubscriptionStartOptionValues.Custom,
	});

	const EndsAtInputs = SelectableOptions<SubscriptionEndOptionValues>({
		selectableOptions: [
			{
				value: SubscriptionEndOptionValues.Subscription,
				translation: t('Until cancelled'),
				onClick: () =>
					handleSubEndChange(SubscriptionEndOptionValues.Subscription),
			},
			{
				// After a specific number of installments
				value: SubscriptionEndOptionValues.Installment,
				contextMenuTranslation: t('After {amount} installments', {
					amount: OrderContext.formState.installmentCount,
				}),
				translation: t('After a specific number of installments'),
				onClick: () =>
					handleSubEndChange(SubscriptionEndOptionValues.Installment),
				children: (
					<Fragment>
						<Input.Field
							type="number"
							placeholder="Eg. 3"
							onChange={handleChangeInstallmentCount}
							value={OrderContext.formState.installmentCount}
						/>
					</Fragment>
				),
			},
		],
		defaultSelectedValue:
			OrderContext.formState.hasOrderId &&
			OrderContext.formState.installmentCount &&
			SubscriptionEndOptionValues.Installment,
	});

	const ValidUntilInputs = SelectableOptions<ValidUntilOptionValues>({
		selectableOptions: [
			{
				value: ValidUntilOptionValues.InstallmentPeriod,
				onClick: () =>
					handleValidUntilChange(ValidUntilOptionValues.InstallmentPeriod),
				translation: t('End of final installment period'),
			},
			{
				value: ValidUntilOptionValues.CustomDate,
				translation: t('A custom date'),
				contextMenuTranslation: validToDate.toLocaleDateString({
					month: 'short',
					day: 'numeric',
					year: 'numeric',
				}),
				children: (
					<DatePicker
						dates={[
							OrderContext.formState.validTo > 0
								? new Date(OrderContext.formState.validTo * 1000)
								: new Date(dateTime.endOfDay),
						]}
						disableDatesBefore={new Date()}
						handleChange={handleDateChange}
					/>
				),
			},
		],
		defaultSelectedValue:
			OrderContext.formState.validTo &&
			ValidUntilOptionValues.InstallmentPeriod,
	});

	const showInstallmentInfoBox = OrderContext.formState.installmentCount === 1;

	return (
		<div>
			<Column>
				<LargeScreen>
					<Row>
						<Input.Group
							label={t('First payment')}
							hint={
								StartsAtInputs.selected.value ===
									SubscriptionStartOptionValues.Immediately &&
								startsAtDateTime.toLocaleDateString({
									month: 'short',
									day: 'numeric',
									year: 'numeric',
								})
							}>
							{StartsAtInputs.Content}
						</Input.Group>

						<Input.Group
							label={t('Last payment')}
							hint={
								EndsAtInputs.selected.value ===
									SubscriptionEndOptionValues.Installment &&
								endsAtDateTime.toLocaleDateString({
									month: 'short',
									day: 'numeric',
									year: 'numeric',
								})
							}>
							{EndsAtInputs.Content}
						</Input.Group>
					</Row>

					{OrderContext.formState.endAt !== null && (
						<Row>
							<Input.Group
								label={t('Products valid until')}
								hint={
									ValidUntilInputs.selected.value !==
										ValidUntilOptionValues.CustomDate &&
									validToDate.toLocaleDateString({
										month: 'short',
										day: 'numeric',
										year: 'numeric',
									})
								}>
								{ValidUntilInputs.Content}
							</Input.Group>
						</Row>
					)}
				</LargeScreen>
				<SmallScreen>
					<Input.Group
						label={t('First payment')}
						hint={
							StartsAtInputs.selected.value ===
								SubscriptionStartOptionValues.Immediately &&
							startsAtDateTime.toLocaleDateString({
								month: 'short',
								day: 'numeric',
								year: 'numeric',
							})
						}>
						{StartsAtInputs.Content}
					</Input.Group>

					<Input.Group
						label={t('Last payment')}
						hint={
							EndsAtInputs.selected.value ===
								SubscriptionEndOptionValues.Installment &&
							endsAtDateTime.toLocaleDateString({
								month: 'short',
								day: 'numeric',
								year: 'numeric',
							})
						}>
						{EndsAtInputs.Content}
					</Input.Group>

					{OrderContext.formState.endAt !== null && (
						<Input.Group
							label={t('Products valid until')}
							hint={
								ValidUntilInputs.selected.value !==
									ValidUntilOptionValues.CustomDate &&
								validToDate.toLocaleDateString({
									month: 'short',
									day: 'numeric',
									year: 'numeric',
								})
							}>
							{ValidUntilInputs.Content}
						</Input.Group>
					)}
				</SmallScreen>

				{showInstallmentInfoBox && (
					<InfoBox
						color="red"
						text={t(
							'You need to enter at least 2 installments to use the installment plan option. If you only need your customer to do one payment, use a one-time price option instead.'
						)}
					/>
				)}

				{paymentProviderContext.settings.autoCharge ===
					AutoChargeOptions.Settable && (
					<Input.Control
						type="checkbox"
						label={t('Auto-charge customer')}
						description={t(
							`Charge your customer's existing payment method automatically each billing cycle. If they don't have one, they will be prompted to pay an invoice manually first.`
						)}
						checked={OrderContext.formState.collectionMethod !== 'manual'}
						onChange={handleAutoChargeToggle}
					/>
				)}
			</Column>
		</div>
	);
}

export default RecurringOptions;
