import { ReactNode } from 'react';

export const symbol_moved_location: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M732-371q-52 75-106 141T520-115q-8 8-18.5 11.5T480-100q-11 0-21.5-3.5T440-115q-42-38-91-87.5T258-309q-42-57-70-119t-28-124q0-136 93-232t227-96q38 0 75 9.5t72 25.5q9 5 15.5 12t6.5 17q0 13-9 21.5t-22 8.5q-8 0-20-7-27-15-57-21t-61-6q-109 0-184.5 78.5T220-552q0 83 66 179.5T480-159q58-53 107.5-114.5T682-404q5-8 12.5-13.5T711-423q13 0 21.5 8.5T741-393q0 6-2.5 11.5T732-371Zm53-218q-101-17-188.5 13T435-474q45-103 141.5-159.5T786-690l-73-73q-9-9-9-21t9-21q9-9 21-9t21 9l144 144q9 9 9 21t-9 21L755-475q-9 9-21 9t-21-9q-9-9-9-21t9-21l72-72Z"
		/>
	</svg>
);
