import { Map, Record } from 'immutable';

import MatchCompetition from 'pkg/models/match_competition';

import { MatchCompetitions as actionTypes } from 'pkg/actions/action-types';

const initialState = Record(
	{
		entities: new Map(),
	},
	'matchCompetitions'
);

const setItems = (state, { payload }) => {
	let { entities } = state;

	Object.values(payload).forEach((matchCompetition) => {
		entities = entities.set(
			matchCompetition.id,
			new MatchCompetition(matchCompetition)
		);
	});

	return state.set('entities', entities);
};

const deleteItem = (state, { id }) => state.removeIn(['entities', id]);

const matchCompetitions = (state = initialState(), action = {}) => {
	switch (action.type) {
		case actionTypes.SET_ITEMS: {
			return setItems(state, action);
		}

		case actionTypes.DELETE_ITEM: {
			return deleteItem(state, action);
		}

		default:
			return state;
	}
};

export default matchCompetitions;
