import { addFormatter } from 'components/formatted-content/FormattedContent';
import Embed from 'components/formatted-content/Embed';

addFormatter('youtube', {
	isEmbed: true,
	selector:
		/(https?:\/\/?(?:www.|m.)?(?:(?:youtube\.com|youtu.be))(?:\/(?:[\w\-]+\?v=|embed\/|v\/)?)(?:[\w\-]+)(?:\S+)?)$/gi,
	decorator: (guid: string, match: string): JSX.Element => {
		match.match(
			/((?:https?:)?\/{2})?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/gi
		);

		const embedUrl = `https://www.youtube.com/embed/${RegExp.$5}?playsinline=1`;

		return (
			<Embed key={guid}>
				<iframe
					src={embedUrl}
					width="100%"
					height="380"
					frameBorder="0"
					allowFullScreen
					allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				/>
			</Embed>
		);
	},
});
