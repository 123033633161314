import { ReactNode } from 'react';

export const symbol_display_external_input: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M765-210H590q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T590-270h175l-66-67q-8-8.5-8-21.25t8-20.75q9-9 21-9t21 9l118 118q9 9 9 21t-9 21L741-101q-9 9-21 9t-21-9q-9-9.067-9-21.533Q690-135 699-144l66-66Zm-585 90q-24 0-42-18t-18-42v-142q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T180-322v142h142q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T322-120H180Zm-60-518v-142q0-24 18-42t42-18h142q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T322-780H180v142q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T120-638Zm660 0v-142H638q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T638-840h142q24 0 42 18t18 42v142q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T780-638Z"
		/>
	</svg>
);
