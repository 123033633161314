import { ReactNode } from 'react';

export const symbol_4g_mobiledata: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M313.825-280Q301-280 292.5-288.625T284-310v-100H150q-12.75 0-21.375-8.625T120-440v-210q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T180-650v180h104v-180q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T344-650v180h55q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T399-410h-55v100q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM550-280q-24.75 0-42.375-17.625T490-340v-280q0-24.75 17.625-42.375T550-680h260q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-620H550v280h231v-112h-73q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T708-512h102q12.75 0 21.375 8.625T840-482v142q0 24.75-17 42.375T781-280H550Z"
		/>
	</svg>
);
