import { ReactNode } from 'react';

export const symbol_add_link: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M700-280h-91q-12 0-20.5-8.625T580-310q0-12 8.625-21T610-340h90v-90q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T760-430v90h90q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-280h-90v90q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T700-190v-90Zm-280 0H280q-83 0-141.5-58.5T80-480q0-83 58.5-141.5T280-680h140q12 0 21 9t9 21.5q0 12.5-9 21t-21 8.5H280q-58.333 0-99.167 40.765-40.833 40.764-40.833 99Q140-422 180.833-381q40.834 41 99.167 41h140q12 0 21 9t9 21.5q0 12.5-9 21t-21 8.5Zm-65-170q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T355-510h250q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T605-450H355Zm525-30h-60q0-58-40.809-99t-99.108-41H539q-12 0-20.5-8.625T510-650q0-12 8.625-21T540-680h140q83 0 141.5 58.5T880-480Z"
		/>
	</svg>
);
