import styled, { css } from 'styled-components';

import * as palette from 'pkg/config/palette';
import { small } from 'pkg/config/breakpoints';

import rgba from 'pkg/rgba';

import Icon from 'components/icon';

import RangeSlider from 'components/form/RangeSlider';

const Slider = styled(RangeSlider)`
	position: absolute;
	left: -5px;
	right: 100px;
	width: 85%;
	bottom: 50px;
`;

const ScoreWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: column wrap;
	position: absolute;
	bottom: 50px;
	right: 0;
`;

const MaxText = styled.p`
	font-weight: var(--font-weight-normal);
	font-size: 18px;
	line-height: 23px;
	color: ${palette.blue[500]};
`;

const SliderWrapper = styled.div`
	width: 90%;
	position: relative;

	@media ${small} {
		width: 80%;
	}
`;

const BestPositionIcon = styled(Icon)`
	z-index: 10;
	height: 24px;
	width: 24px;
	font-size: 14px;
	margin-bottom: 10px;

	background: ${palette.blue[500]};
	color: ${palette.white};
	border-radius: 50%;
	padding: 3px;
`;

const NumberWrapper = styled.div`
	background: #fff;
	height: 78px;
	width: 85px;
	border-radius: var(--radius-3);
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 0px 30px ${rgba(palette.black, 0.2)};
	font-weight: var(--font-weight-normal);
	font-size: 33px;
	line-height: 42px;
	position: absolute;
	bottom: 100px;
	left: auto;
	right: 245px;

	@media ${small} {
		bottom: 125px;
		right: 42%;
	}

	&::after {
		content: '';
		display: block;
		width: 0;
		height: 0;
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;

		border-top: 10px solid ${palette.white};

		position: absolute;
		bottom: -9px;
		left: 33px;
	}
`;

const Wrapper = styled.div`
	opacity: 0;
	transition: opacity 300ms ease-in-out 50ms;
	width: 100vw;
	height: 100%;
	display: flex;
	max-width: 400px;
	margin: 0 auto;

	@media ${small} {
		height: 250px;
	}

	${NumberWrapper},
	${Slider},
	${ScoreWrapper} {
		opacity: 0;
	}

	${NumberWrapper} {
		transition: opacity 300ms ease-in-out 100ms;
	}

	${Slider} {
		transition: opacity 300ms ease-in-out 200ms;
	}

	${ScoreWrapper} {
		transition: opacity 300ms ease-in-out 250ms;
	}

	${(props) =>
		props.active &&
		css`
			opacity: 1;

			${NumberWrapper},
			${Slider},
			${ScoreWrapper} {
				opacity: 1;
			}
		`}
`;

const Assess = (props) => {
	return (
		<Wrapper active={props.active}>
			<NumberWrapper>8.2</NumberWrapper>

			<SliderWrapper>
				<Slider
					thumbSize="large"
					value={72}
					thumbColor={palette.blue[500]}
					onChange={() => {}}
				/>

				<ScoreWrapper>
					<BestPositionIcon name="star" />
					<MaxText>10.0</MaxText>
				</ScoreWrapper>
			</SliderWrapper>
		</Wrapper>
	);
};

export default Assess;
