import { ReactNode } from 'react';

export const symbol_closed_caption_disabled: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m361-599 50 50H290v138h102q0-10 6.571-16.5Q405.143-434 415-434h4q9.857 0 16.429 6.571Q442-420.857 442-411v20q0 12.75-8.625 21.375T412-361H270q-12.75 0-21.375-8.625T240-391v-178q0-12.75 8.625-21.375T270-599h91Zm479-141v474q0 14.634-9.518 22.317t-20.5 7.683Q799-236 789.5-243.683T780-266v-474H306q-15 0-22.5-9.318t-7.5-20.5q0-11.182 7.5-20.682Q291-800 306-800h474q23 0 41.5 18.5T840-740ZM721-394q0 13-8.5 21.5T691-364h-9l-47-47h36q0-10 6.571-16.5Q684.143-434 694-434h4q9.857 0 16.429 6.571Q721-420.857 721-411v17Zm-30-205q12.75 0 21.375 8.625T721-569v20q0 9.857-6.571 16.429Q707.857-526 698-526h-4q-9.857 0-16.429-6.571Q671-539.143 671-549H569v72l-50-50v-42q0-12.75 8.625-21.375T549-599h142Zm-148 96Zm-126 46ZM180-160q-24 0-42-18t-18-42v-520q0-19.922 12.5-35.461Q145-791 163-797l57 57h-40v520h474L47-826q-9-9.067-9-21.533Q38-860 47.053-869q9.052-9 21.5-9Q81-878 90-869l764 764q9 9.067 9 21.533Q863-71 853.947-62q-9.052 9-21.5 9Q820-53 811-62l-97-98H180Z"
		/>
	</svg>
);
