import { t } from '@transifex/native';
import { Fragment } from 'react';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import DateTime from 'pkg/datetime';
import Link from 'pkg/router/Link';
import * as routes from 'pkg/router/routes';
import { useCurrentOrganization } from 'pkg/identity';

import { FormSummary } from 'routes/forms/single/overview';

import MissingEntities from 'components/missing-entities';
import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import Icon from 'components/icon';
import Avatar from 'components/avatar';

import Column from 'components/layout/column';
import Row from 'components/layout/row';
import StatusLabel from 'components/payment_platform/status/label';

import * as Card from 'design/card';

import * as css from './styles.css';

interface Props {
	groupId: number;
	formId: number;
	summary: FormSummary;
}

const LatestSubmissions = ({ groupId, formId, summary }: Props) => {
	const org = useCurrentOrganization();

	if (!summary.Settings.LatestSubmissions) {
		return (
			<Card.Base $noBorder>
				<Card.Body>
					<MissingEntities centered>
						<Icon name="list" />
						<p>{t(`No submissions yet`)}</p>
					</MissingEntities>
				</Card.Body>
			</Card.Base>
		);
	}
	return (
		<Card.Base $noBorder>
			<Card.Header $slim>
				<Card.Heading>{t(`Latest submissions`)}</Card.Heading>
				<Link
					href={routes.Registrations.Single(
						org.id,
						groupId,
						formId,
						'submissions'
					)}>
					{t(`View`)} <Icon name="chevron" size={1.4} />
				</Link>
			</Card.Header>
			<Card.Body>
				<div>
					<Column>
						{summary.LatestSubmissions.map(
							(formSubmission, i, allSubmissions) => {
								const createdDate = DateTime.fromTimestamp(
									formSubmission.updatedAt
								);

								return (
									<Fragment key={formSubmission.id}>
										{formSubmission.submittedForUser ? (
											<Link
												href={routes.Form.Submissions.Single(
													org.id,
													groupId,
													formId,
													formSubmission.id
												)}>
												<Row columns="30px 1fr auto auto" align="center">
													<Avatar user={formSubmission.submittedForUser} />
													<Column spacing={styles.spacing._1}>
														<div className={css.boldName}>
															{models.user.fullName(
																formSubmission.submittedForUser
															)}
														</div>
														<div className={css.emailStyle}>
															{formSubmission.submittedForUser.email}
														</div>
														<SmallScreen>
															<div className={css.grayed}>
																{createdDate.toLocaleDateString({
																	year: 'numeric',
																	month: 'numeric',
																	day: 'numeric',
																})}
															</div>
														</SmallScreen>
													</Column>
													{summary.Settings.TransactionSummary &&
														formSubmission.order && (
															<StatusLabel
																status={models.order.getStatus(
																	formSubmission.order
																)}
																jobStatus={formSubmission.order.jobStatus}
															/>
														)}
													<LargeScreen>
														<div className={css.grayed}>
															{createdDate.toLocaleDateString({
																year: 'numeric',
																month: 'numeric',
																day: 'numeric',
															})}
														</div>
													</LargeScreen>
												</Row>
											</Link>
										) : (
											<div>{t('New submission')}</div>
										)}
										{i + 1 !== allSubmissions.length && <Card.Divider />}
									</Fragment>
								);
							}
						)}
					</Column>
				</div>
			</Card.Body>
		</Card.Base>
	);
};

export default LatestSubmissions;
