import { ReactNode } from 'react';

export const symbol_attachment: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M326-243q-103 0-174.5-72.175t-71.5-175Q80-593 151.5-665.5 223-738 326-738h380q72.5 0 123.25 51.5T880-563q0 72-50.75 123.5T706-388H346q-42 0-72-30t-30-72.5q0-42.5 29.673-72.5 29.672-30 72.327-30h340q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T686-533H346q-17 0-29.5 12.5T304-489.86q0 18.139 12.5 30Q329-448 346-448h360q48 0 81-33.5t33-81.711q0-48.212-33.06-81.5Q753.88-678 706-678H326q-78 0-132 54.971T140-490q0 77.917 54 132.458Q248-303 326-303h360q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T686-243H326Z"
		/>
	</svg>
);
