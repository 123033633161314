import { ReactNode } from 'react';

export const symbol_mobiledata_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m378-236 58-58q9-9 21.5-9t21.5 9q9 9 9 21.5t-9 21.5L369-141q-5 5-10 7t-11 2q-6 0-11-2t-10-7L217-251q-9-9-8.5-21.5T218-294q9-9 21.5-9t21.5 9l57 58v-309L78-785q-9-9-9-21t9-21q9-9 21-9t21 9l708 707q9 9 9 21t-9 21q-9 9-21 9t-21-9L378-486v250Zm223-210q-12 0-21-9t-9-21v-248l-58 58q-9 9-21 9t-21-9q-9-9-9-21.5t9-21.5l109-110q5-5 10-7t11-2q6 0 11 2t10 7l110 110q9 9 9 21t-9 21q-9 9-21.5 9t-21.5-9l-58-57v248q0 14-9.5 22t-20.5 8Z"
		/>
	</svg>
);
