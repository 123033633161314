import { ReactNode } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import { useCueState } from 'components/video-analytics/CueState';
import { usePlayerState } from 'components/video-analytics/PlayerState';

import Button from 'design/button';

const Wrapper = styled.div`
	height: 100%;
	display: grid;
	grid-template-rows: 1fr auto;
	flex: 1;
`;

const Container = styled.div`
	padding: 0 var(--spacing-5);
	overflow: scroll;
	-webkit-overflow-scrolling: touch;
`;

const Actions = styled.div`
	padding: var(--spacing-5);

	@supports (env(safe-area-inset-bottom)) {
		@media ${styles.breakpoint.small} {
			border-top: 1px solid var(--palette-gray-600);
			padding-bottom: calc(var(--spacing-5) + env(safe-area-inset-bottom));
		}
	}
`;

interface RearrangeLayoutProps {
	children?: ReactNode;
}

export default function RearrangeLayout({
	children,
}: RearrangeLayoutProps): JSX.Element {
	const playerState = usePlayerState();
	const cueState = useCueState();

	const finish = () => {
		playerState.setRearranging(false);

		if (cueState.currentCue) {
			playerState.setSource(cueState.currentCue.sourceUrl, {
				startsAt: cueState.currentCue.startsAt,
			});

			playerState.controller.play();
		}
	};

	return (
		<Wrapper>
			<Container>{children}</Container>
			<Actions>
				<Button primary block onClick={finish}>
					{t('Save')}
				</Button>
			</Actions>
		</Wrapper>
	);
}
