import { ReactNode } from 'react';

export const symbol_assistant_device: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-665q-63 0-106.5 43.5T330-515q0 63 43.5 106.5T480-365q63 0 106.5-43.5T630-515q0-63-43.5-106.5T480-665Zm0 240q-39 0-64.5-25.5T390-515q0-39 25.5-64.5T480-605q39 0 64.5 25.5T570-515q0 39-25.5 64.5T480-425Zm170 170q16 0 28-12t12-28q0-16-12-28t-28-12q-16 0-28 12t-12 28q0 16 12 28t28 12ZM160-140v-550q0-78.85 55.575-134.425Q271.15-880 350-880h260q78.85 0 134.425 55.575Q800-768.85 800-690v550q0 24.75-17.625 42.375T740-80H220q-24.75 0-42.375-17.625T160-140Zm60-65h520v-484.898Q740-745 702.5-782.5 665-820 610-820H350q-55 0-92.5 37.5T220-689.898V-205Zm33 0h487-520 33Zm-33 0h520-520Z"
		/>
	</svg>
);
