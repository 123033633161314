export function AddSequences(ID: number): string {
	return `/v1/video-playlists/${ID}/sequences`;
}

export function Create(): string {
	return `/v1/video-playlists`;
}

export function Delete(ID: number): string {
	return `/v1/video-playlists/${ID}`;
}

export function Index(): string {
	return `/v1/video-playlists`;
}

export function RemoveSequences(ID: number): string {
	return `/v1/video-playlists/${ID}/sequences`;
}

export function Show(ID: number): string {
	return `/v1/video-playlists/${ID}`;
}

export function Update(ID: number): string {
	return `/v1/video-playlists/${ID}`;
}

export function UpdateSequenceOrder(ID: number): string {
	return `/v1/video-playlists/${ID}/sequences`;
}
