import { useEffect } from 'react';

import useMixedState from 'pkg/hooks/useMixedState';
import * as sdk from 'pkg/core/sdk';
import { tlog } from 'pkg/tlog';

import { useApiConfig } from 'components/application/apiconfig';

interface RequestHeaders {
	[key: string]: string;
}

interface EndpointOptions {
	queryParams?: URLSearchParams;
	/**
	 * use a POST request instead of GET
	 */
	postRequest?: boolean;
	body?: {
		[key: string]: unknown;
	};
	delayFetch?: boolean;
	requestHeaders?: RequestHeaders;
	defaultResponse?: any;
}

export interface EndpointResponse<T> {
	record: T;
	isLoading: boolean;
	isInitialLoad: boolean;
	hasError?: boolean;
	response?: Response;
	refresh: () => Promise<void>;
	replaceRecord: (record: T) => void;
	replaceInRecord: (record: T, key: keyof T, newRecord: any, id?: any) => T;
	removeInRecord: (record: T, key: keyof T, removeRecord: any) => void;
	addInRecord: (record: T, key: keyof T, newRecords: any) => void;
}

/**
 *
 * @param endpoint request this endpoint, complete with URL params
 * @returns
 */
export function useEndpoint<T>(
	endpoint: string,
	options: EndpointOptions = {},
	afterFetch?: (record: T, response: Response) => void
): EndpointResponse<T> {
	const apiConfig = useApiConfig();
	const {
		queryParams = options.queryParams || new URLSearchParams(),
		requestHeaders,
		delayFetch,
		defaultResponse = {},
		body = {},
		postRequest,
	} = options;

	const fetchData = async () => {
		if (!endpoint) {
			return;
		}

		if (!responseData.isLoading) {
			setState({ isLoading: true });
		}

		const options = queryParams
			? Object.fromEntries(queryParams?.entries())
			: {};

		let r: Response;
		let d: any;

		try {
			r = postRequest
				? await sdk.post(endpoint, options, body, requestHeaders || {})
				: await sdk.get(endpoint, options, {}, requestHeaders || {});
		} catch (e) {
			tlog.error('failed to make request', {
				error: e,
			});
			setState({
				hasError: true,
				isLoading: false,
			});
			throw new Error(e);
		}

		// client version is too low
		if (r?.status === 426) {
			apiConfig.refreshConfig();
			return;
		}

		if (r?.status >= 500 && r?.status < 600) {
			tlog.error('server error', {
				error: new Error(`server error ${r.statusText}`),
			});
			setState({
				hasError: true,
				isLoading: false,
			});
			return;
		}

		try {
			d = await r.json();
		} catch (e) {
			tlog.error('failed to parse json', {
				error: e,
			});
			setState({
				hasError: true,
				isLoading: false,
			});
			throw new Error(e);
		}

		const newState = {
			...responseData,
			response: r,
			isLoading: false,
			isInitialLoad: false,
		};

		if (r?.ok) {
			newState.record = d as T;
			newState.hasError = false;
		} else {
			newState.hasError = true;
		}

		setState(newState);
		afterFetch?.(newState.record, r);
	};

	const replaceRecord = (record: T) => {
		setState({
			record,
		});
	};

	// replaces a record at key with newRecord. If newRecord is a function and an id is also passed,
	// it is called with the current record and is expected to return an updated version of the record.
	const replaceInRecord = (
		record: T,
		key: keyof T,
		newRecord: any,
		id?: any
	) => {
		const innerRecords = (record[key] || []) as any[any];

		if (typeof newRecord === 'function' && typeof id !== 'undefined') {
			const index = innerRecords.findIndex((inner: any) => inner.id === id);
			innerRecords[index] = newRecord(innerRecords[index]);
		} else {
			const index = innerRecords.findIndex(
				(inner: any) => inner.id === newRecord.id
			);
			innerRecords[index] = newRecord;
		}
		record[key] = innerRecords;

		setState({
			record,
		});

		return record;
	};

	const removeInRecord = (record: T, key: keyof T, removeRecord: any) => {
		const innerRecords = (record[key] || []) as any[any];
		const index = innerRecords.findIndex(
			(inner: any) => inner.id === removeRecord.id
		);

		const newInnerRecords = innerRecords.filter(
			(r: any, i: number) => i !== index
		);

		record[key] = newInnerRecords;

		setState({
			record,
		});

		return;
	};

	const addInRecord = (record: T, key: keyof T, newRecords: any) => {
		const innerRecords = (record[key] || []) as any[any];
		const newInnerRecords: any = [...innerRecords, ...newRecords];

		record[key] = newInnerRecords;

		setState({
			record,
		});

		return;
	};

	let isLoading = true;

	if (!endpoint || delayFetch) {
		isLoading = false;
	}

	const [responseData, setState] = useMixedState<EndpointResponse<T>>({
		isLoading,
		isInitialLoad: true,
		refresh: fetchData,
		record: defaultResponse as T,
		replaceRecord,
		replaceInRecord,
		removeInRecord,
		addInRecord,
	});

	useEffect(() => {
		if (!delayFetch && !!endpoint) {
			fetchData();
		}
	}, [endpoint, delayFetch, queryParams?.toString(), JSON.stringify(body)]);

	return responseData;
}
