import { ReactNode } from 'react';

export const symbol_earthquake: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M360.5-80q-10.5 0-18.48-5.625T331-101L228-450H110q-12.75 0-21.375-8.675Q80-467.351 80-480.175 80-493 88.625-501.5T110-510h140q9.921 0 17.934 5.625Q275.947-498.75 279-489l76 256 136-623q2-11 10.25-17.5T520-880q10.5 0 18.75 6T549-857l94 423 68-225q3.059-9.75 11.088-15.375 8.03-5.625 17.971-5.625t17.441 5q7.5 5 10.5 14l54 151h28q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-450h-50q-9.849 0-17.424-5.5Q775-461 772-470l-30-83-74 252q-2.889 9.63-10.833 15.815Q649.222-279 639.111-280T621-287q-8-6-10-16l-91-402-131 602q-2.25 10.387-10.125 16.323Q371-80.742 360.5-80Z"
		/>
	</svg>
);
