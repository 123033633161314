import { Fragment, ReactNode } from 'react';

import spacing from 'pkg/config/spacing';

import * as models from 'pkg/api/models';
import { cssClasses } from 'pkg/css/utils';
import { useCurrentAccountUserIds } from 'pkg/identity';

import * as Card from 'components/Card';
import Icon from 'components/icon';
import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import Avatar from 'components/avatar';

import Row from 'components/layout/row';
import Column from 'components/layout/column';

import * as css from './styles.css';

interface EventUserCardProps {
	children?: ReactNode;

	eventUser: models.eventUser.EventUser;
	event: models.event.Event;
}

export default function EventUserCard({
	eventUser,
	children,
	event,
}: EventUserCardProps) {
	const userIds = useCurrentAccountUserIds();
	const eventUserIsCurrentUser = userIds.includes(eventUser.user.id);
	const hasEnded = models.event.hasEnded(event);

	const actionHasBeenTaken =
		eventUser.status === models.eventUser.EventUserStatus.Accepted ||
		eventUser.status === models.eventUser.EventUserStatus.Declined;

	const text = models.eventUser.getStatusText(
		eventUser,
		eventUserIsCurrentUser,
		hasEnded
	);

	let eventUserStatusClass = '';

	if (hasEnded) {
		switch (eventUser.attendanceStatus) {
			case models.eventUser.AttendanceStatuses.Attended:
				eventUserStatusClass = css.accepted;
				break;
			case models.eventUser.AttendanceStatuses.Sick:
			case models.eventUser.AttendanceStatuses.Injured:
			case models.eventUser.AttendanceStatuses.Other:
				eventUserStatusClass = css.declined;
				break;
			case models.eventUser.AttendanceStatuses.Unset:
				eventUserStatusClass = css.unset;
				break;
		}
	} else {
		if (eventUser.status === models.eventUser.EventUserStatus.Accepted) {
			eventUserStatusClass = css.accepted;
		} else if (eventUser.status === models.eventUser.EventUserStatus.Declined) {
			eventUserStatusClass = css.declined;
		}
	}

	const content = (
		<Fragment>
			<Row align="center" spacing={spacing._3} autoColumns="max-content">
				<Avatar size={22} user={eventUser.user} />
				{!actionHasBeenTaken && !hasEnded ? (
					eventUser.user.firstName
				) : (
					<span className={cssClasses(css.name, eventUserStatusClass)}>
						{text}
						{actionHasBeenTaken && !hasEnded && (
							<Icon size={1.2} name="chevron-down" />
						)}
					</span>
				)}
			</Row>

			{children}
		</Fragment>
	);

	return (
		<Card.Base>
			<Card.Body className={css.cardBody}>
				<LargeScreen>
					<Row
						autoColumns="max-content"
						justifyContent="space-between"
						align="center">
						{content}
					</Row>
				</LargeScreen>
				<SmallScreen>
					<Column>{content}</Column>
				</SmallScreen>
			</Card.Body>
		</Card.Base>
	);
}
