import { ReactNode } from 'react';

export const symbol_text_snippet: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-180h600v-375L555-780H180v600Zm0 60q-24.75 0-42.375-17.625T120-180v-600q0-24.75 17.625-42.375T180-840h375q12.444 0 23.722 5T598-822l224 224q8 8 13 19.278 5 11.278 5 23.722v375q0 24.75-17.625 42.375T780-120H180Zm129-171h342q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T651-351H309q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T309-291Zm0-159h342q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T651-510H309q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T309-450Zm0-159h216q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T525-669H309q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T309-609ZM180-180v-600 600Z"
		/>
	</svg>
);
