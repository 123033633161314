import { ReactNode } from 'react';

export const symbol_superscript: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M799-630q-12.75 0-21.375-8.625T769-660v-40q0-12.75 8.625-21.375T799-730h91v-40H784q-6 0-10.5-4.5T769-785q0-6 4.5-10.5T784-800h106q12.75 0 21.375 8.625T920-770v40q0 12.75-8.625 21.375T890-700h-91v40h106q6 0 10.5 4.5T920-645q0 6-4.5 10.5T905-630H799ZM300-160q-21.288 0-31.144-18.5Q259-197 270-215l150-236-136-213q-11-18-1.5-37t31.031-19q9.938 0 18.219 4.452Q340.031-711.095 345-703l124 196 124-196q4.892-8.095 13.454-12.548Q615.015-720 623.985-720 646-720 655.5-701t-1.5 37L518-451l150 236q11 18 .844 36.5T638.375-160q-9.375 0-17.837-4.452Q612.077-168.905 607-177L469-394 331-177q-4.895 8.095-13.461 12.548Q308.974-160 300-160Z"
		/>
	</svg>
);
