import { useT } from '@transifex/react';
import { useRef } from 'react';

import {
	CreateTaxRatePayload,
	UpdateTaxRatePayload,
} from 'pkg/actions/tax_rates';

import * as models from 'pkg/api/models';
import * as actions from 'pkg/actions';

import Modal from 'components/modal';
import * as Card from 'components/Card';

import Form, {
	asNumber,
	asString,
	FormPayload,
	submitForm,
} from 'components/form/Form';
import * as Input from 'components/form/inputs';
import Column from 'components/layout/column';

import Button from 'design/button';

interface TaxRateModalProps {
	groupId: number;
	taxRate?: models.taxRate.TaxRate;
	handleSave: () => void;
	onClose: () => void;
}

export default function TaxRateModal({
	groupId,
	taxRate,
	handleSave,
	onClose,
}: TaxRateModalProps) {
	const form = useRef(null);
	const t = useT();

	let title = t('Create tax');

	if (taxRate) {
		title = t('Edit tax');
	}

	const onSave = () => submitForm(form);

	const handleSubmit = async (data: FormPayload) => {
		if (taxRate) {
			const payload: UpdateTaxRatePayload = {
				display_name: asString(data.display_name),
				description: asString(data.description),
				jurisdiction: asString(data.jurisdiction),
				active: true,
			};

			const [req] = await actions.taxRates.update(
				groupId,
				taxRate.id as unknown as string,
				payload
			);

			if (req) {
				handleSave();
				onClose();
			}

			return;
		}

		const payload: CreateTaxRatePayload = {
			display_name: asString(data.display_name),
			inclusive: data.inclusive as boolean,
			percentage: asNumber(data.percentage),
			active: true,
		};

		if (data.description) {
			payload.description = asString(data.description);
		}

		if (data.jurisdiction) {
			payload.jurisdiction = asString(data.jurisdiction);
		}

		const [req] = await actions.taxRates.create(groupId, payload);

		if (req) {
			handleSave();
			onClose();
		}
	};

	const hasTaxRate = !!taxRate;

	return (
		<Modal onClose={onClose}>
			<Form formRef={form} onSubmit={handleSubmit}>
				<Card.Base>
					<Card.Header>
						<Card.Heading>{title}</Card.Heading>
					</Card.Header>
					<Card.Divider />
					<Card.Body>
						<Column>
							<Input.Group
								label={t('Name')}
								hint={t(
									'Name your tax option, ex. “Sweden”, percentage will be shown automatically'
								)}>
								<Input.Field
									name="display_name"
									defaultValue={taxRate?.display_name ?? ''}
									required
								/>
							</Input.Group>
							<Input.Group
								label={t('Percentage')}
								hint={t('The percentage is a number')}>
								<Input.Field
									name="percentage"
									defaultValue={taxRate?.percentage ?? 1}
									min={1}
									max={100}
									type="number"
									disabled={hasTaxRate}
								/>
							</Input.Group>
							<Input.Control
								name="inclusive"
								type="checkbox"
								label={t('Tax is included in the price')}
								description={t(
									'Select this if tax is already included in the price of your product'
								)}
								defaultChecked={taxRate?.inclusive}
								disabled={hasTaxRate}
							/>
							<Input.Group
								label={t('Description')}
								hint={t('A short description of ýour tax option')}>
								<Input.Field
									name="description"
									defaultValue={taxRate?.description ?? ''}
								/>
							</Input.Group>
							<Input.Group
								label={t('Jurisdiction')}
								hint={t(
									'If the tax applies to any specific jurisdiction you can describe it here'
								)}>
								<Input.Field
									name="jurisdiction"
									defaultValue={taxRate?.jurisdiction ?? ''}
								/>
							</Input.Group>
						</Column>
					</Card.Body>
					<Card.Divider />
					<Card.Footer>
						<Button label={t('Cancel')} onClick={onClose} />
						<Button label={t('Save')} primary onClick={onSave} />
					</Card.Footer>
				</Card.Base>
			</Form>
		</Modal>
	);
}
