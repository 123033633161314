import { t } from '@transifex/native';

import { Features } from 'pkg/models/group';

import { useCurrentGroup, useCurrentMembership } from 'pkg/identity';
import * as models from 'pkg/api/models';

import AssetImage from 'components/AssetImage';

import Button from 'design/button';
import MissingEntities from 'design/placeholders/block';

interface NoPostsProps {
	openPostModal: () => void;
}

export default function NoPosts({ openPostModal }: NoPostsProps): JSX.Element {
	const activeMembership = useCurrentMembership();
	const group = useCurrentGroup();

	const hideCreatePostButton =
		activeMembership.targetUserId ||
		activeMembership.isOrganizationMembership ||
		!models.group.hasFeature(group, Features.Posts);

	const missingEntitiesHelpUrl = !hideCreatePostButton
		? 'https://help.360player.com/article/79-writing-posts-to-your-team-or-organization'
		: undefined;

	const button = hideCreatePostButton ? null : (
		<Button
			icon="add"
			secondary
			label={t('Write a post!')}
			onClick={openPostModal}
		/>
	);

	const image = <AssetImage src="img/missing-entities/post.svg" />;

	let description = t(
		'Stay up to date with the team and use the wall for fast and easy communication.'
	);

	if (activeMembership.isOrganizationMembership) {
		description = t(
			'Stay up to date with the club and use the wall for fast and easy communication.'
		);
	}

	return (
		<MissingEntities
			noBorder
			key="missing-entity"
			title={t('No posts yet!')}
			helpUrl={missingEntitiesHelpUrl}
			description={description}
			actions={button}
			image={image}
		/>
	);
}
