import { ReactNode } from 'react';

export const symbol_tablet_mac: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480.175-115q12.825 0 21.325-8.675 8.5-8.676 8.5-21.5 0-12.825-8.675-21.325-8.676-8.5-21.5-8.5-12.825 0-21.325 8.675-8.5 8.676-8.5 21.5 0 12.825 8.675 21.325 8.676 8.5 21.5 8.5ZM180-40q-24.75 0-42.375-17.625T120-100v-760q0-24.75 17.625-42.375T180-920h600q24.75 0 42.375 17.625T840-860v760q0 24.75-17.625 42.375T780-40H180Zm0-150v90h600v-90H180Zm0-60h600v-520H180v520Zm0-580h600v-30H180v30Zm0 0v-30 30Zm0 640v90-90Z"
		/>
	</svg>
);
