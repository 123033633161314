import styled, { css } from 'styled-components';
import { MouseEvent, ReactNode } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import Account from 'pkg/models/account';

import { formatTime } from 'pkg/timeline';
import rgba from 'pkg/rgba';
import Link from 'pkg/router/Link';

import { FormattedContent } from 'components/formatted-content';
import { SmallScreen } from 'components/MediaQuery';
import Icon from 'components/icon';

interface ClipState {
	isActive?: boolean;
	isPrivate?: boolean;
	isHighlight?: boolean;
	isDraggable?: boolean;
	isRecording?: boolean;
	compact?: boolean;
	hasThumbnail?: boolean;
}

const Wrapper = styled.div<ClipState>`
	background: var(--palette-gray-800);
	border-radius: var(--radius-3);
	display: grid;
	grid-template-columns: auto 1fr 30px;
	column-gap: var(--spacing-4);
	align-items: center;
	user-select: none;
	cursor: pointer;
	color: var(--palette-white);
	box-shadow: var(--palette-gray-800) 0 0 0 2px;

	@media (hover: hover) {
		&:hover {
			background: var(--palette-gray-700);
			box-shadow: var(--palette-gray-700) 0 0 0 2px;
		}
	}

	${({ isDraggable }) =>
		isDraggable &&
		css`
			grid-template-columns: auto 1fr 40px;
		`}

	${({ hasThumbnail }) =>
		hasThumbnail &&
		css`
			height: 54px;
		`}

	${({ isActive, hasThumbnail }) =>
		isActive &&
		hasThumbnail &&
		css`
			height: auto;
			grid-template-rows: 54px auto;
		`}


	${({ isActive }) =>
		isActive &&
		css`
			background: ${rgba(styles.palette.gray[700])};
			box-shadow:
				var(--palette-gray-600) 0 0 0 1px,
				${rgba(styles.palette.gray[600], 0.25)} 0 0 4px 1px,
				inset ${rgba(styles.palette.black, 0.5)} 0 0 1px;

			@media (hover: hover) {
				&:hover {
					background: ${rgba(styles.palette.gray[700])};
					box-shadow:
						var(--palette-gray-500) 0 0 0 1px,
						${rgba(styles.palette.gray[500], 0.25)} 0 0 4px 1px,
						inset ${rgba(styles.palette.black, 0.5)} 0 0 1px;
				}
			}

			time {
				background-color: var(--palette-gray-800);
			}
		`}

	${({ isRecording }) =>
		isRecording &&
		css`
			background: ${rgba(styles.palette.red[900])};
			box-shadow:
				var(--palette-red-800) 0 0 0 1px,
				${rgba(styles.palette.red[800], 0.25)} 0 0 4px 1px,
				inset ${rgba(styles.palette.black, 0.5)} 0 0 1px;

			@media (hover: hover) {
				&:hover {
					background: ${rgba(styles.palette.red[900])};
					box-shadow:
						var(--palette-red-700) 0 0 0 1px,
						${rgba(styles.palette.red[700], 0.25)} 0 0 4px 1px,
						inset ${rgba(styles.palette.black, 0.5)} 0 0 1px;
				}
			}

			time {
				background-color: var(--palette-red-900);
			}
		`}

	${({ compact }) =>
		compact &&
		css`
			padding: var(--spacing-3) var(--spacing-4);
			height: auto;
			grid-template-columns: 1fr auto auto;

			time {
				padding: var(--spacing-2);
				padding-bottom: var(--spacing-1);
				font-size: var(--font-size-xs);
				border-radius: var(--radius-2);
			}
		`}
`;

const Thumbnail = styled.div<{ thumbnailUrl?: string }>`
	background: var(--palette-gray-900);
	width: 80px;
	height: 100%;
	border-radius: 4px 3px 3px 4px;
	box-shadow: inset var(--palette-gray-900) 0 0 0 1px;
	position: relative;

	${({ thumbnailUrl }) => css`
		background: var(--palette-gray-900) url(${thumbnailUrl}) no-repeat center
			center / cover;
	`}

	time {
		border-top-left-radius: 5px;
		border-bottom-left-radius: 3px;
		position: absolute;
		background: ${rgba(styles.palette.black, 0.8)};
		backdrop-filter: blur(5px);
		padding: var(--spacing-2) 6px;
		font-size: var(--font-size-xs);
		bottom: 0;
		right: 0;
	}
`;

const Content = styled.div`
	display: grid;
	grid-auto-flow: row;
	row-gap: var(--spacing-2);
`;

const Header = styled.header`
	font-weight: var(--font-weight-semibold);
	display: grid;
	grid-auto-flow: column;
	column-gap: var(--spacing-2);
	align-items: center;
	justify-content: start;
`;

const Title = styled.span<ClipState>`
	font-size: var(--font-size-sm);
	display: grid;
	grid-auto-flow: column;
	gap: var(--spacing-2);

	svg {
		transform: translateY(2px);
	}

	${({ isActive }) =>
		!isActive &&
		css`
			span {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				width: 100%;
				display: inline-block;
			}
		`}

	${({ isHighlight }) =>
		isHighlight &&
		css`
			color: var(--palette-yellow-500);
		`}

	${({ isPrivate }) =>
		isPrivate &&
		css`
			color: var(--palette-blue-400);
		`}

	${({ isHighlight, isPrivate }) =>
		isHighlight &&
		isPrivate &&
		css`
			color: var(--palette-orange-400);
		`}
`;

const SubTitle = styled.span`
	font-size: var(--font-size-sm);
	color: var(--palette-gray-500);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
	display: inline-block;
`;

const Contents = styled.div`
	grid-area: 2 / 1 / 3 / 4;
	font-size: var(--font-size-sm);
	display: grid;
	grid-auto-flow: row;
	gap: var(--spacing-3);
	margin-top: var(--spacing-3);
`;

const MetaData = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	place-items: start;
	color: var(--palette-gray-500);
	border-top: 1px solid var(--palette-gray-600);

	div {
		padding-top: var(--spacing-3);

		&:not(:only-child):last-of-type {
			width: 100%;
			text-align: right;
			align-self: end;
		}
	}
`;

interface ClipProps extends ClipState {
	title: string;
	subTitle?: string;
	startsAt: number;
	endsAt: number;
	thumbnailUrl?: string;
	description?: string;
	account?: Account;
	numComments?: number;
	canComment?: boolean;

	onClick?: () => void;
	onCommentClick?: () => void;

	compact?: boolean;
	renderActionWith?: ReactNode;
}

export default function Clip({
	isActive,
	isPrivate,
	isHighlight,
	isDraggable,
	isRecording,

	title,
	subTitle,
	startsAt,
	endsAt,
	thumbnailUrl,
	description,
	numComments,
	canComment = false,

	onClick,
	onCommentClick,

	compact,
	renderActionWith = null,
}: ClipProps): JSX.Element {
	const hideDiscussion: boolean = !canComment && numComments === 0;

	const handleCommentClick = (event: MouseEvent) => {
		event.stopPropagation();

		if (onCommentClick) {
			onCommentClick();
		}
	};

	if (compact) {
		return (
			<Wrapper
				compact
				isActive={isActive}
				isPrivate={isPrivate}
				isHighlight={isHighlight}
				isDraggable={isDraggable}
				isRecording={isRecording}
				hasThumbnail={!!thumbnailUrl}
				onClick={onClick}>
				<Content>
					<Header>
						<Title
							isActive={isActive}
							isPrivate={isPrivate}
							isHighlight={isHighlight}>
							{isHighlight && <Icon name="star" />}
							{isPrivate && <Icon name="eye-hidden" />}
							<span>{title}</span>
						</Title>
					</Header>
				</Content>
				<time>{formatTime(Math.ceil(endsAt - startsAt))}</time>
				{renderActionWith}
				{isActive && (
					<Contents>
						{description && (
							<FormattedContent
								raw={description}
								maxLength={140}
								allowedFormatters={['line-breaks', 'links']}
							/>
						)}
						<MetaData>
							<div>
								{formatTime(startsAt)} &mdash; {formatTime(endsAt)}
							</div>
							<SmallScreen>
								{!hideDiscussion && (
									<div>
										<Link onClick={handleCommentClick}>
											{t('{num} comments', { num: numComments })}
										</Link>
									</div>
								)}
							</SmallScreen>
						</MetaData>
					</Contents>
				)}
			</Wrapper>
		);
	}

	return (
		<Wrapper
			isActive={isActive}
			isPrivate={isPrivate}
			isHighlight={isHighlight}
			hasThumbnail={!!thumbnailUrl}
			isDraggable={isDraggable}
			onClick={onClick}>
			<Thumbnail thumbnailUrl={thumbnailUrl}>
				<time>{formatTime(Math.ceil(endsAt - startsAt))}</time>
			</Thumbnail>
			<Content>
				<Header>
					<Title isPrivate={isPrivate} isHighlight={isHighlight}>
						{isHighlight && <Icon name="star" />}
						{isPrivate && <Icon name="eye-hidden" />}
						<span>{title}</span>
					</Title>
				</Header>
				{subTitle && <SubTitle>{subTitle}</SubTitle>}
			</Content>
			{renderActionWith}
			{isActive && (
				<Contents>
					{description && (
						<FormattedContent
							raw={description}
							maxLength={140}
							allowedFormatters={['line-breaks', 'links']}
						/>
					)}
					<MetaData>
						<div>
							{formatTime(startsAt)} &mdash; {formatTime(endsAt)}
						</div>
						<SmallScreen>
							{!hideDiscussion && (
								<div>
									<Link onClick={handleCommentClick}>
										{t('{num} comments', { num: numComments })}
									</Link>
								</div>
							)}
						</SmallScreen>
					</MetaData>
				</Contents>
			)}
		</Wrapper>
	);
}
