import { ReactNode } from 'react';

export const symbol_language_japanese_kana: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M355-253q-11 3-21.5 1T318-262q-5-8-1.5-17t13.5-11q65-14 103-49.5t38-83.5q0-32-22.5-59T389-521q-24 58-56 104.5T262-336l8 26q2 8-2 16t-12 10q-8 2-16.5-2T229-298l-3-11q-28 16-52 23.5t-45 7.5q-28 0-47.5-21T62-350q0-55 37.5-103.5T204-534q1-21 3-42l4-42q-25 1-50.5.5T107-620q-10-1-17-7.5T83-643q0-8 6.5-13.5T105-661q20 2 46 3t64 1q2-11 3.5-23t2.5-23q1-11 7.5-18t16.5-7q9 0 14 8t3 17q-2 11-4 23t-4 23q51-2 95.5-7t82.5-13q8-2 15.5 3t7.5 13q0 10-7 16t-17 8q-45 7-90 11.5t-91 6.5q-2 17-3 35t-3 35q32-8 60.5-12t56.5-2l3-18q2-8 9.5-12.5T389-596q10 2 15 11t1 19l-3 11q51 14 82 51t31 82q0 59-43.5 104.5T355-253Zm335-16q-54 0-86.5-31T571-383q0-50 34-83.5T710-520q22-6 50.5-11.5T824-542q0-56-18.5-82.5T739-651q-22 0-44 6.5T644-622q-10 5-20 2.5T609-631q-5-10-2.5-20t12.5-15q31-17 64.5-27t67.5-10q69 0 101 42.5T884-540v237q0 11-7 18t-18 7h-3q-10 0-16.5-6.5T831-300l-3-27q-30 28-66.5 43T690-269Zm-552-53q18 0 37-8t41-23q-7-40-10-73.5t-3-65.5q-48 30-73 64t-25 70q0 15 9.5 25.5T138-322Zm114-60q32-32 56.5-68t40.5-77q-27 0-53.5 4.5T243-509q-2 28 .5 58.5T252-382Zm452 62q32 0 63-15t57-43v-121q-29 4-51 8.5T731-480q-50 16-75 39.5T631-385q0 29 20 47t53 18Z"
		/>
	</svg>
);
