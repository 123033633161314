import { ReactNode } from 'react';

export const symbol_arming_countdown: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-325q72.917 0 123.958-51.042Q655-427.083 655-500q0-72.083-51.5-122.542Q552-673 480-675v50q52 1 88.5 37.235 36.5 36.236 36.5 88Q605-448 568.542-411.5 532.083-375 480-375q-33.367 0-62.183-16Q389-407 372-436l-43 24q24 40 63.806 63.5T480-325ZM330.116-471Q341-471 348-478.116q7-7.117 7-18Q355-507 347.884-514q-7.117-7-18-7Q319-521 312-513.884q-7 7.117-7 18Q305-485 312.116-478q7.117 7 18 7Zm22-84Q363-555 370-562.116q7-7.117 7-18Q377-591 369.884-598q-7.117-7-18-7Q341-605 334-597.884q-7 7.117-7 18Q327-569 334.116-562q7.117 7 18 7Zm58-55Q421-610 428-617.116q7-7.117 7-18Q435-646 427.884-653q-7.117-7-18-7Q399-660 392-652.884q-7 7.117-7 18Q385-624 392.116-617q7.117 7 18 7ZM480-84q-5.32 0-9.88-1-4.56-1-9.12-3-139-47-220-168.5t-81-266.606V-719q0-19.257 10.875-34.662Q181.75-769.068 199-776l260-97q11-4 21-4t21 4l260 97q17.25 6.932 28.125 22.338Q800-738.257 800-719v195.894Q800-378 719-256.5T499-88q-4.56 2-9.12 3T480-84Zm0-59q115-38 187.5-143.5T740-523v-196l-260-98-260 98v196q0 131 72.5 236.5T480-143Zm0-337Z"
		/>
	</svg>
);
