import { ReactNode } from 'react';

export const symbol_deployed_code_history: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-823 213-669l267 155 266-155-266-154ZM120-310v-340q0-16 7.875-29.787Q135.75-693.574 150-702l300-173q8-5 14.951-6.5 6.951-1.5 15-1.5t15.549 1.5Q503-880 510-875l300 173q14.25 8.426 22.125 22.213T840-650v180q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T780-470v-150L511-463q-14 8-30 8.5t-30-7.5L180-619v309l285 164q6.75 3.824 10.875 10.706T480-120q0 17-15 25.5t-30 .5L150-258q-14.25-8.426-22.125-22.213T120-310ZM730 0q-78 0-134-55.4-56-55.399-56-133.999Q540-268 596-324t134-56q78 0 134 55.867Q920-268.265 920-189q0 78.435-56 133.718Q808 0 730 0ZM480-478Zm271 284v-103.04q0-8.96-6-14.96t-15-6q-9 0-15 6t-6 14.97V-195q0 6.069 2.5 11.759Q714-177.552 719-173l76 70q5 5 13 4.5t13-5.5q6-6 5.5-14t-6.5-13l-69-63Z"
		/>
	</svg>
);
