import * as styles from 'pkg/config/styles';

import { Wrapper, Text } from './styles';

interface DividerProps {
	text?: string;
	margin?: typeof styles.spacing;
	small?: boolean;
}

export default function Divider({
	text,
	margin,
	small,
}: DividerProps): JSX.Element {
	return (
		<Wrapper margin={margin} small={small}>
			{text && <Text>{text}</Text>}
		</Wrapper>
	);
}
