import { ReactNode } from 'react';

export const symbol_settings_input_component: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M123-70v-139.886Q89-223 64.5-252.5 40-282 40-317v-368q0-12.75 8.625-21.375T70-715h53v-175q0-14 8-22t22-8q14 0 22 8t8 22v175h53q12.75 0 21.375 8.625T266-685v368q0 35-24 64.5t-59 42.614V-70q0 12.75-8.675 21.375Q165.649-40 152.825-40 140-40 131.5-48.625T123-70Zm327 0v-139.886Q416-223 391.5-252.5 367-282 367-317v-368q0-12.75 8.625-21.375T397-715h53v-175q0-14 8-22t22-8q14 0 22 8t8 22v175h53q12.75 0 21.375 8.625T593-685v368q0 35-24 64.5t-59 42.614V-70q0 12.75-8.675 21.375Q492.649-40 479.825-40 467-40 458.5-48.625T450-70Zm327 0v-139.886Q743-223 718.5-252.5 694-282 694-317v-368q0-12.75 8.625-21.375T724-715h53v-175q0-14 8-22t22-8q14 0 22 8t8 22v175h53q12.75 0 21.375 8.625T920-685v368q0 35-24.5 64.5T837-209.886V-70q0 12.75-8.675 21.375Q819.649-40 806.825-40 794-40 785.5-48.625T777-70ZM100-655v184h106v-184H100Zm327 0v184h106v-184H427Zm327 0v184h106v-184H754ZM153-267q22 0 37.5-15t15.5-34.979V-411H100v94.021Q100-297 115.5-282t37.5 15Zm327 0q22 0 37.5-15t15.5-34.979V-411H427v94.021Q427-297 442.5-282t37.5 15Zm327 0q22 0 37.5-15t15.5-34.979V-411H754v94.021Q754-297 769.5-282t37.5 15ZM153-441Zm327 0Zm327 0Z"
		/>
	</svg>
);
