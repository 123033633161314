import { ReactNode } from 'react';

export const symbol_assignment_turned_in: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M423-415 323-515q-9-9-21-9t-21 9q-9 9-8.5 21t8.5 21l121 123q9 9 21 9t21-9l235-235q9-9 9-21.5t-9-21.5q-9-9-21.5-9t-21.5 9L423-415ZM180-120q-24.75 0-42.375-17.625T120-180v-600q0-24.75 17.625-42.375T180-840h205q5-35 32-57.5t63-22.5q36 0 63 22.5t32 57.5h205q24.75 0 42.375 17.625T840-780v600q0 24.75-17.625 42.375T780-120H180Zm0-60h600v-600H180v600Zm300-617q14 0 24.5-10.5T515-832q0-14-10.5-24.5T480-867q-14 0-24.5 10.5T445-832q0 14 10.5 24.5T480-797ZM180-180v-600 600Z"
		/>
	</svg>
);
