import { ReactNode } from 'react';

export const symbol_airline_seat_recline_extra: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M300-740q-29 0-49-20t-20-49q0-29 20-50t49-21q29 0 50 21t21 50q0 29-21 49t-50 20Zm227 560H276q-26 0-44.5-17T208-240l-86-429q-3-14 6.5-25t23.5-11q11 0 20 7t11 18l82 440h262q13 0 21.5 8.5T557-210q0 13-8.5 21.5T527-180Zm255 74L679-285H365q-26 0-46.5-15T293-340l-47-248q-9-46 23-81.5t80-35.5q35 0 60 22.5t32 56.5l46 240h166q20 0 35 12t25 29l122 209q6 11 2.5 23T823-95q-11 6-23 3t-18-14Z"
		/>
	</svg>
);
