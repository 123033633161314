import { ReactNode } from 'react';

export const symbol_mic_double: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M339.825-120Q327-120 318.5-128.625T310-150v-106q-97-11-165.5-76.5T63-492q-2-14 5.5-25.5T90-529q14 0 22.5 10.5T123-494q13 78 74.2 129T340-314h9.394q4.697 0 9.394-1 12.212 14 25.616 26.95Q397.809-275.1 413-264q-11.034 3-21.517 4.5Q381-258 370-256v106q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM620-423q-43 0-72-30.917-29-30.916-29-75.083v-251q0-41.667 29.441-70.833Q577.882-880 619.941-880t71.559 29.167Q721-821.667 721-780v251q0 44.167-29 75.083Q663-423 620-423Zm0-60q18 0 29.5-13.5T661-529v-251q0-17-11.787-28.5Q637.425-820 620-820t-29.213 11.5Q579-797 579-780v251q0 19 11.5 32.5T620-483Zm-326 48q-26-14-40.5-39.5T239-529v-251q0-41.667 29.441-70.833Q297.882-880 339.941-880t71.559 29.167Q441-821.667 441-780v227q-13-17-31.367-26.5Q391.267-589 370-589q-36.855 0-62.427 27Q282-535 282-496.652q0 15.652 3.529 30.985Q289.059-450.333 294-435Zm326 121q81.6 0 142.8-51Q824-416 837-494q2-14 10.5-24.5T870-529q14 0 21.5 11.5T897-492q-13 94-81.5 159.5T650-256v106q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T590-150v-106q-97-11-165.5-76.5T343-492q-2-14 5.5-25.5T370-529q14 0 22.5 10.5T403-494q13 78 74.2 129T620-314Zm0-338Z"
		/>
	</svg>
);
