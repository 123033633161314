import styled from 'styled-components';
import { Fragment } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import { clipOffset, clipWidth } from 'pkg/video';

import { useTimelineOffset } from 'routes/video/shared/hooks/timeline';

import Icon from 'components/icon';

import { usePlayerState } from 'components/video-analytics/PlayerState';
import { usePlaybackState } from 'components/video-analytics/PlaybackState';
import { useClipState } from 'components/video-analytics/ClipState';
import {
	Annotation as SingleAnnotation,
	Keyframe as SingleKeyframe,
} from 'components/annotations/AnnotationHooks';

interface TimelineProps {
	videoDuration: number;
	startsAt: number;
	endsAt: number;
}

const TimelineAttrs = ({ videoDuration, startsAt, endsAt }: TimelineProps) => ({
	style: {
		width: `${clipWidth(videoDuration, startsAt, endsAt) || 0}%`,
		left: `${clipOffset(0, videoDuration, startsAt)}%`,
	},
});

const Timeline = styled.div.attrs<TimelineProps>(TimelineAttrs)<TimelineProps>`
	background: var(--palette-blue-500);
	height: 2px;
	z-index: 100;
	position: absolute;
	transform: translateY(45px);
`;

interface KeyframeProps {
	videoDuration: number;
	placedAt: number;
}

const KeyframeAttrs = ({ videoDuration, placedAt }: KeyframeProps) => ({
	style: {
		left: `${clipOffset(0, videoDuration, placedAt)}%`,
	},
});

const Keyframe = styled.div.attrs<KeyframeProps>(KeyframeAttrs)<KeyframeProps>`
	width: 20px;
	height: 20px;
	transform: translate(-10px, calc((45px - 50%) + 1px));
	transform-origin: center center;
	z-index: 110;
	position: absolute;
	cursor: pointer;
	color: var(--palette-blue-500);

	&[data-active='true'] {
		color: var(--palette-blue-100);
		z-index: 120;
	}

	&[data-in-range='false'] {
		color: var(--palette-red-500);
	}

	&[data-hidden='true'] {
		opacity: 0.5;
	}

	@media (hover: hover) {
		&:hover div {
			color: var(--palette-blue-300);
		}
	}
`;

const Trigger = styled(motion.div)`
	width: 100%;
	height: 100%;
	background: currentColor;
	cursor: pointer;
`;

const Delete = styled(motion.div)`
	position: absolute;
	top: 90%;
	left: 90%;
	cursor: pointer;
	opacity: 0.8;

	&:hover {
		opacity: 1;
	}
`;

interface AnnotationProps {
	annotationId?: string;
}

export default function Annotation({
	annotationId,
}: AnnotationProps): JSX.Element {
	const { duration, controller } = usePlayerState();
	const clipState = useClipState();

	const timelineOffset = useTimelineOffset();
	const playbackState = usePlaybackState();

	const annotations = clipState.annotations
		? JSON.parse(clipState.annotations)
		: null;

	const annotation: SingleAnnotation =
		annotations && annotations.hasOwnProperty(annotationId)
			? annotations[annotationId]
			: null;

	if (!annotation) return null;

	const activateKeyframe = (keyframe: SingleKeyframe, index: number) => {
		const nextTimestamp = keyframe.time / 1000;

		playbackState.setCurrentTime(nextTimestamp);
		timelineOffset.set(nextTimestamp);
		controller.seekTo(nextTimestamp);

		clipState.setActiveKeyframe(index);
	};

	const deleteKeyframe = (index: number) => {
		const keyframes = [...annotation.keyframes];
		keyframes.splice(index, 1);
		clipState.setAnnotation(annotationId, { ...annotation, keyframes });
	};

	const withinClipBounds = (placedAt: number): boolean =>
		placedAt >= clipState.getStartsAt() && placedAt <= clipState.getEndsAt();

	const enterAnimation = {
		scale: 0.6,
		opacity: 1,
		rotate: '45deg',
	};

	const leaveAnimation = {
		scale: 0,
		opacity: 0,
		rotate: '45deg',
	};

	if (annotationId === 'playback') return null;

	return (
		<Fragment>
			<Timeline
				videoDuration={duration}
				startsAt={annotation.keyframes[0].time / 1000}
				endsAt={clipState.getEndsAt()}
			/>
			{annotation.keyframes.map((keyframe: SingleKeyframe, n: number) => (
				<Keyframe
					key={n}
					data-hidden={keyframe.hide}
					data-active={n === clipState.activeKeyframeIndex}
					data-in-range={withinClipBounds(keyframe.time / 1000)}
					videoDuration={duration}
					placedAt={keyframe.time / 1000}
					onClick={() => activateKeyframe(keyframe, n)}>
					<AnimatePresence>
						<Trigger
							key={`keyframe:${n}`}
							initial={leaveAnimation}
							animate={enterAnimation}
							exit={leaveAnimation}
						/>
						{n === clipState.activeKeyframeIndex &&
							(annotation.keyframes.length > 1 ||
								annotationId === 'playback') && (
								<Fragment>
									<Delete
										onClick={() => deleteKeyframe(n)}
										initial={{ scale: 0 }}
										exit={{ scale: 0 }}
										animate={{ scale: 1 }}
										transition={{ ease: 'easeOut', duration: 0.4 }}>
										<Icon name="delete" fill="white" size={1.2} />
									</Delete>
								</Fragment>
							)}
					</AnimatePresence>
				</Keyframe>
			))}
		</Fragment>
	);
}
