import { ReactNode } from 'react';

export const symbol_eject: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M230-260h500q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T730-200H230q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T230-260Zm10-139 215-323q4.545-7 10.985-10.5Q472.424-736 480-736q7.576 0 14.015 3.5Q500.455-729 505-722l215 323q10 15 1.25 31T695-352H265q-17.5 0-26.25-16t1.25-31Zm241-13Zm-162 0h322L480-650 319-412Z"
		/>
	</svg>
);
