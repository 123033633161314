import { ReactNode } from 'react';

export const symbol_adb: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M200-521v-40q0-72 33-131t87-97l-75-75 36-36 84.5 84q25.847-12 55.174-18.5Q450-841 480-841t59.5 6.5Q569-828 595-816l84-84 36 36-75 75q54 38 87 97.242 33 59.243 33 130.931V-521H200Zm400-80q17 0 28.5-11.5T640-641q0-17-11.5-28.5T600-681q-17 0-28.5 11.5T560-641q0 17 11.5 28.5T600-601Zm-240 0q17 0 28.5-11.5T400-641q0-17-11.5-28.5T360-681q-17 0-28.5 11.5T320-641q0 17 11.5 28.5T360-601ZM480-40q-117 0-198.5-81.5T200-320v-161h560v161q0 117-81.5 198.5T480-40Z"
		/>
	</svg>
);
