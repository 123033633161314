import { ReactNode } from 'react';

export const symbol_person_add_disabled: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m772-77-92-92v10H100q-24.75 0-42.375-17.625T40-219v-35q0-35 18-63.5t50-42.5q74-34 133-47t119-13q21 0 39 1t35 4l-65-65h-9q-66 0-108-42.5T210-631v-9L64-786q-9-9-9-21t8.842-21q8.842-9 21-9T106-828l708 709q9 9 9 21t-8.842 21q-8.842 9-21 9T772-77Zm-42-453H630q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T630-590h100v-100q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T790-690v100h100q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T890-530H790v100q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T730-430v-100Zm-249-8-44-44q7-12 10-23.5t3-25.5q0-39-25.5-64.5T360-721q-14 0-26.5 3.5T311-708l-44-44q17-14 42.094-21.5Q334.189-781 360-781q66 0 108 42t42 108q0 27-7.5 51.5T481-538ZM100-219h520L510-338q-35-12-70-17t-80-5q-56 0-106.5 10T132-306q-15 8-23.5 22t-8.5 30v35Zm337-363Zm-77 363Z"
		/>
	</svg>
);
