import { ReactNode } from 'react';

export const symbol_shape_line: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M240-520q-83 0-141.5-58.5T40-720q0-84 58.5-142T240-920q84 0 142 58t58 142q0 83-58 141.5T240-520Zm0-60q59.5 0 99.75-40.833Q380-661.667 380-720q0-59.5-40.25-99.75T240-860q-58.333 0-99.167 40.25Q100-779.5 100-720q0 58.333 40.833 99.167Q181.667-580 240-580ZM620-40q-24.75 0-42.375-17.625T560-100v-240q0-24.75 17.625-42.375T620-400h240q24.75 0 42.375 17.625T920-340v240q0 24.75-17.625 42.375T860-40H620Zm0-60h240v-240H620v240Zm120-120ZM240-720Zm475 48L288-246q7 12 10.5 25.65Q302-206.7 302-192q0 45-32 77.5T192.049-82Q147-82 114.5-114.5 82-147 82-192.049 82-238 114.5-270q32.5-32 77.5-32 14.7 0 28.35 3.5Q234-295 246-288l426-427q-7-12-10.5-25.5T658-768q0-46 32.5-78t77.549-32Q814-878 846-846q32 32 32 77.951Q878-723 846-690.5 814-658 768-658q-14 0-27.5-3.5T715-672Z"
		/>
	</svg>
);
