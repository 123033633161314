import { ReactNode } from 'react';

export const symbol_cast_warning: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M481-480Zm340 320H630q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T630-220h191v-236q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T881-456v236q0 24.75-17.625 42.375T821-160ZM110.825-680Q98-680 89.5-688.625T81-710v-30q0-24.75 17.625-42.375T141-800h308q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T449-740H141v30q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM730-540q-78 0-134-55.399-56-55.4-56-134Q540-808 596-864t134-56q78 0 134 55.867Q920-808.265 920-729q0 78.435-56 133.718Q808-540 730-540Zm-.5-76q9.5 0 16-6t6.5-16q0-10-6.6-16t-15.4-6q-10 0-16 6t-6 16q0 10 6 16t15.5 6Zm.5-85q8 0 12.5-5t4.5-13v-108q0-8-5-12.5t-13-4.5q-8 0-12.5 5t-4.5 13v108q0 8 5 12.5t13 4.5ZM130.765-160Q109-160 94-175.235q-15-15.236-15-37Q79-234 94.235-249q15.236-15 37-15Q153-264 168-248.765q15 15.236 15 37Q183-190 167.765-175q-15.236 15-37 15Zm179.768 0Q298.8-160 290-167.329q-8.8-7.328-11-19.671-9-69-56.5-117.5T107-362q-11-2-18.5-11.052-7.5-9.051-7.5-20.638Q81-406 88.5-415q7.5-9 18.5-8 93 9 158 75.5T340-188q2 11.789-7.5 19.895Q323-160 310.533-160ZM471-160q-12 0-21-8t-10-20q-11-134-103.5-229T111-523q-13-1-21.5-10.053-8.5-9.052-8.5-21.122Q81-567 91.5-575.5T116-583q154.76 12.781 263.38 123.391Q488-349 500-193q1 14-7.625 23.5T471-160Z"
		/>
	</svg>
);
