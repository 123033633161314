import styled, { css } from 'styled-components';

import * as palette from 'pkg/config/palette';

import rgba from 'pkg/rgba';

import AvatarImport from 'components/avatar';

const BaseStyle = styled.div`
	overflow: auto;
`;

export const Label = styled.h3`
	font-weight: var(--font-weight-bold);
	font-size: var(--font-size-sm);
	letter-spacing: 1px;
	color: ${palette.gray[400]};
	text-transform: uppercase;
	margin-top: 2rem;
	padding: 0 1rem;

	&:first-of-type {
		margin-top: 1rem;
	}
`;

export const ItemStyle = styled.div`
	display: grid;
	grid-template-columns: 2rem 1fr auto;
	grid-template-areas: 'avatar name actions';
	grid-gap: 1rem;
	padding: 1rem;
	align-items: center;
	position: relative;
	user-select: none;
	border-bottom: 1px solid ${rgba(palette.card.innerDividerColor)};

	&:last-child {
		border-bottom: 0;
	}

	@media (hover: hover) {
		&:hover {
			background: ${rgba(palette.blue[400], 0.05)};
		}
	}

	${(props) =>
		props.selected &&
		css`
			font-weight: var(--font-weight-semibold);
		`}
`;

export const Avatar = styled(AvatarImport)`
	grid-area: avatar;
	width: 2rem;
	height: 2rem;
`;

export const Name = styled.div`
	grid-area: name;
`;

export const Actions = styled.div`
	grid-area: actions;
`;

export const Item = ({ children }) => {
	const avatar = children.filter((child) => child.type === Avatar);
	const name = children.filter((child) => child.type === Name);
	const actions = children.filter((child) => child.type === Actions);

	return (
		<ItemStyle>
			{avatar}
			{name}
			<Actions>{actions}</Actions>
		</ItemStyle>
	);
};

export const Base = ({ children }) => <BaseStyle>{children}</BaseStyle>;
