import { ReactNode } from 'react';

export const symbol_pin_end: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v270q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T820-470v-270H140v520h430q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T570-160H140Zm322-420 124 124q9 9 9 21.158 0 12.158-8.609 21Q577-405 564.533-405q-12.466 0-21.533-9L420-537v73q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T360-464v-146q0-12.75 8.625-21.375T390-640h146q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T536-580h-74Zm308 420q-45 0-77.5-32.5t-32.5-78q0-45.5 32.5-77.5t78-32q45.5 0 77.5 32.083 32 32.084 32 77.917 0 45-32.083 77.5Q815.833-160 770-160Z"
		/>
	</svg>
);
