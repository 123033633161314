import { ReactNode } from 'react';

export const symbol_masked_transitions: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M360-80q-115 0-197.5-82.5T80-360q0-115 82.5-197.5T360-640q115 0 197.5 82.5T640-360q0 115-82.5 197.5T360-80Zm-.212-60Q451-140 515.5-204.288q64.5-64.288 64.5-155.5T515.712-515.5q-64.288-64.5-155.5-64.5T204.5-515.712q-64.5 64.288-64.5 155.5T204.288-204.5q64.288 64.5 155.5 64.5ZM668-328q1-7 1.5-15.5t.5-16.16Q670-487 578.5-578.5T359.66-670q-7.66 0-15.66.5t-15 1.5q22-90 98-151t172.545-61Q715-880 797.5-797.5 880-715 880-599.545 880-503 819.5-427.5 759-352 668-328Z"
		/>
	</svg>
);
