import { t } from '@transifex/native';
import { Fragment, useState, useRef, ChangeEvent } from 'react';
import styled from 'styled-components';

import * as styles from 'pkg/config/styles';

import { UserSex } from 'pkg/models/user';

import { UserPayload } from 'pkg/actions/services/users';

import * as actions from 'pkg/actions';
import store from 'pkg/store/createStore';
import { phoneRegexPattern } from 'pkg/strings';
import { months } from 'pkg/date';
import { useCurrentOrganization } from 'pkg/identity';
import { translatedSexString } from 'pkg/api/models/user';

import Icon from 'components/icon';
import CountrySelect from 'components/CountrySelect';
import StepModal, { Step } from 'components/step-modal';

import * as iconStyles from 'components/icon/styles.css';
import * as Inputs from 'components/form/inputs';
import Column from 'components/layout/column';
import Form, { FormPayload } from 'components/form/Form';
import Row from 'components/layout/row';
import Section from 'components/form/Section';

const currentDate = new Date();
const currentYear = currentDate.getFullYear();

const MoreDetailsTrigger = styled.div`
	display: flex;
	justify-content: left;
	align-items: center;
	cursor: pointer;

	span {
		font-size: var(--font-size-base);
		font-weight: var(--font-weight-semibold);
		margin-right: var(--spacing-2);
	}

	.${iconStyles.icon} {
		font-size: var(--font-size-2xl);
	}
`;

interface CreateUserModalProps {
	groupId: number;
	refreshTeamList: () => void;
	onClose: () => void;
}

export default function CreateUserModal({
	groupId,
	refreshTeamList,
	onClose,
}: CreateUserModalProps) {
	const [isLoading, setIsLoading] = useState(false);
	const [showMoreDetails, setShowMoreDetails] = useState(false);
	const [sex, setSex] = useState(0);

	const [country, setCountry] = useState<string>('');
	const [nationality, setNationality] = useState<string>('');

	const handleCountryChange = (event: ChangeEvent<HTMLOptionElement>) => {
		setCountry(event.target.value);
	};

	const handleNationalityChange = (event: ChangeEvent<HTMLOptionElement>) => {
		setNationality(event.target.value);
	};

	const formRef = useRef<HTMLFormElement>();

	const organization = useCurrentOrganization();

	const submitForm = async () =>
		await formRef.current.dispatchEvent(
			new Event('submit', {
				cancelable: true,
				bubbles: true,
			})
		);

	const createUser = async (data: FormPayload) => {
		setIsLoading(true);

		const year = data.birthDateYear?.toString();
		const month = data.birthDateMonth?.toString();
		const day = data.birthDateDay?.toString();
		let birthDate = null;

		if (year && month && day) {
			birthDate = `${year}-${month}-${day}`;
		}

		const payload: UserPayload = {
			organizationId: organization.id,
			groups: [{ id: groupId, role: 'user' }],
			firstName: data.firstName.toString(),
			lastName: data.lastName?.toString(),
			email: data.email?.toString(),
			phoneNumber: data.phoneNumber?.toString(),
			birthDate,
			sex,
			address: data.address?.toString(),
			postalCode: data.postalCode?.toString(),
			city: data.city?.toString(),
			region: data.region?.toString(),
			country: data.country?.toString(),
			nationality: data.nationality?.toString(),
		};

		const [response] = await actions.users.createUser(payload)(store.dispatch);

		if (response.ok) {
			actions.flashes.show({
				title: t(`User successfully created!`),
			});

			refreshTeamList();
		}

		setIsLoading(false);

		onClose();
	};

	const toggleMoreDetails = () => setShowMoreDetails(!showMoreDetails);

	const dayOptions = [];
	const monthOptions = [];
	const yearOptions = [];

	for (let i = 1; i <= 12; i++) {
		monthOptions.push({
			value: i.toString().padStart(2, '0'),
			label: months()[i - 1][1],
		});
	}

	for (let j = 1; j <= 31; j++) {
		dayOptions.push({
			value: j.toString().padStart(2, '0'),
			label: j.toString(),
		});
	}

	for (let y = currentYear; y > currentYear - 100; y--) {
		yearOptions.push({
			value: y.toString(),
			label: y.toString(),
		});
	}

	const handleChangeSex = (value: number) => setSex(value);

	return (
		<StepModal onClose={onClose}>
			<Step
				title={t(`New virtual user`)}
				onNext={submitForm}
				canGoNext={!isLoading}
				nextLabel={t('Save')}
				closeOnNext={false}>
				<Form onSubmit={createUser} formRef={formRef}>
					<Section
						title={t(`User details`)}
						description={t(
							`Fill in the personal information about the user below.`
						)}
						hideDivider>
						<Column spacing={styles.spacing._7}>
							<Row collapseOnSmallScreens collapsedSpacing={styles.spacing._7}>
								<Inputs.Group label={`* ${t(`First name`)}`}>
									<Inputs.Field name="firstName" required />
								</Inputs.Group>

								<Inputs.Group label={t(`Last name`)}>
									<Inputs.Field name="lastName" />
								</Inputs.Group>
							</Row>

							{!showMoreDetails && (
								<MoreDetailsTrigger onClick={toggleMoreDetails}>
									<span>{t(`More details`)}</span>
									<Icon name="chevron" rotate="0" />
								</MoreDetailsTrigger>
							)}

							{showMoreDetails && (
								<Fragment>
									<Row
										collapseOnSmallScreens
										collapsedSpacing={styles.spacing._7}>
										<Inputs.Group label={t(`Email`)}>
											<Inputs.Field name="email" type="email" />
										</Inputs.Group>

										<Inputs.Group label={t(`Cellphone number`)}>
											<Inputs.Field
												name="phoneNumber"
												type="tel"
												pattern={phoneRegexPattern}
											/>
										</Inputs.Group>
									</Row>

									<Row>
										<Inputs.Group label={t(`Birth date`)}>
											<Row>
												<Inputs.Select name="birthDateDay">
													<option value="">{t(`Day`)}</option>
													{dayOptions.map((item, index) => (
														<option key={index} value={item.value}>
															{item.label}
														</option>
													))}
												</Inputs.Select>

												<Inputs.Select name="birthDateMonth">
													<option value="">{t(`Month`)}</option>
													{monthOptions.map((item, index) => (
														<option key={index} value={item.value}>
															{item.label}
														</option>
													))}
												</Inputs.Select>

												<Inputs.Select name="birthDateYear">
													<option value="">{t(`Year`)}</option>
													{yearOptions.map((item, index) => (
														<option key={index} value={item.value}>
															{item.label}
														</option>
													))}
												</Inputs.Select>
											</Row>
										</Inputs.Group>
									</Row>

									<Inputs.Group label={t(`Gender`)}>
										<Column spacing={styles.spacing._3}>
											<Inputs.Control
												value={UserSex.Unknown}
												type="radio"
												label={translatedSexString(UserSex.Unknown)}
												checked={sex === UserSex.Unknown}
												onChange={handleChangeSex}
												name="genderNotSet"
												testid="genderNotSet"
											/>
											<Inputs.Control
												value={UserSex.Male}
												type="radio"
												label={translatedSexString(UserSex.Male)}
												checked={sex === UserSex.Male}
												onChange={handleChangeSex}
												name="genderMale"
												testid="genderMale"
											/>
											<Inputs.Control
												value={UserSex.Female}
												type="radio"
												label={translatedSexString(UserSex.Female)}
												checked={sex === UserSex.Female}
												onChange={handleChangeSex}
												name="genderFemale"
												testid="genderFemale"
											/>
											<Inputs.Control
												value={UserSex.NotApplicable}
												type="radio"
												label={translatedSexString(UserSex.NotApplicable)}
												checked={sex === UserSex.NotApplicable}
												onChange={handleChangeSex}
												name="genderNotApplicable"
												testid="genderNotApplicable"
											/>
										</Column>
									</Inputs.Group>

									<Inputs.Group label={t(`Street address`)}>
										<Inputs.Field name="address" />
									</Inputs.Group>

									<Row
										collapseOnSmallScreens
										collapsedSpacing={styles.spacing._7}>
										<Inputs.Group label={t(`ZIP code`)}>
											<Inputs.Field name="postalCode" />
										</Inputs.Group>

										<Inputs.Group label={t(`City`)}>
											<Inputs.Field name="city" />
										</Inputs.Group>

										<Inputs.Group label={t(`State or region`)}>
											<Inputs.Field name="region" />
										</Inputs.Group>
									</Row>

									<Row
										collapseOnSmallScreens
										collapsedSpacing={styles.spacing._7}>
										<Inputs.Group
											label={t(`Country`)}
											hint={t(`Country of residence`)}>
											<CountrySelect
												name="country"
												value={country}
												onChange={handleCountryChange}
											/>
										</Inputs.Group>

										<Inputs.Group label={t(`Nationality`)}>
											<CountrySelect
												role="nationality"
												name="nationality"
												value={nationality}
												suggestedCountry={country}
												onChange={handleNationalityChange}
											/>
										</Inputs.Group>
									</Row>
								</Fragment>
							)}
						</Column>
					</Section>
				</Form>
			</Step>
		</StepModal>
	);
}
