import { t } from '@transifex/native';
import { Fragment } from 'react';

import * as models from 'pkg/api/models';

import * as Input from 'components/form/inputs';
import Section from 'components/form/Section';
import UserTraitsInputGroup from 'components/identity-provider/role-select-group';

interface EditFormProps {
	provider: models.identityProvider.IdentityProvider;
}

export default function EditIdentityProviderForm({ provider }: EditFormProps) {
	const availableUserTraits = models.identityProvider.getAvailableUserTraits([
		provider,
	]);

	return (
		<Fragment>
			<Section title={t('Identity provider')} icon="passkey">
				<Input.Group
					label={t('Display name')}
					required
					description={t(
						'This is the name of the login method that will be shown to the user'
					)}>
					<Input.Field
						name="displayName"
						defaultValue={provider.displayName}
						required
					/>
				</Input.Group>
			</Section>
			<Section title={t('Status')} icon="encrypted">
				<Input.Control
					name="ssoEnabled"
					label={t('Enable SSO')}
					type="checkbox"
					description={t('Enable SSO for the organization')}
					defaultChecked={provider.ssoEnabled}
				/>
			</Section>
			<Section
				title={t('Roles')}
				icon="group"
				description={t(
					'Should this login method only be applied to specific roles in your organization?'
				)}
				hideDivider>
				<UserTraitsInputGroup
					preSelectedUserTraits={provider.requiredForIdentityUserTraits}
					availableUserTraits={availableUserTraits}
				/>
			</Section>
		</Fragment>
	);
}
