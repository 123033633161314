// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QP15y6G4alVI0FJRYYnp {
	width: 100%;
}

.YKlTcGBwEk2di7ditqU2 {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
	display: inline-block;
}
`, "",{"version":3,"sources":["webpack://./routes/video/shared/style.css"],"names":[],"mappings":"AAAA;CACC,WAAW;AACZ;;AAEA;CACC,mBAAmB;CACnB,gBAAgB;CAChB,uBAAuB;CACvB,WAAW;CACX,qBAAqB;AACtB","sourcesContent":[".filterTrigger {\n\twidth: 100%;\n}\n\n.filterTriggerLabel {\n\twhite-space: nowrap;\n\toverflow: hidden;\n\ttext-overflow: ellipsis;\n\twidth: 100%;\n\tdisplay: inline-block;\n}\n"],"sourceRoot":""}]);
// Exports
export var filterTrigger = `QP15y6G4alVI0FJRYYnp`;
export var filterTriggerLabel = `YKlTcGBwEk2di7ditqU2`;
export default ___CSS_LOADER_EXPORT___;
