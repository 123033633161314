import { ReactNode } from 'react';

export const symbol_4g_plus_mobiledata: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M815-450h-55q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T760-510h55v-55q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T875-565v55h55q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T930-450h-55v55q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T815-395v-55ZM427-280q-24.75 0-42.375-17.625T367-340v-280q0-24.75 17.625-42.375T427-680h219q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T646-620H427v280h189v-110h-50q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T566-510h80q12.75 0 21.375 8.625T676-480v140q0 24.75-17.625 42.375T616-280H427ZM100-650v185h100v-185q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T260-650v185h18q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T278-405h-18v95q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T200-310v-95H70q-12.75 0-21.375-8.625T40-435v-215q0-12.75 8.675-21.375Q57.351-680 70.175-680 83-680 91.5-671.375T100-650Z"
		/>
	</svg>
);
