import { ReactNode } from 'react';

export const symbol_hdr_auto: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M390-404h180l38 98q3 8 10 13t16 5q15 0 23-12t3-26L518-697q-3-8-10.5-13.5T491-716h-22q-9 0-16.5 5.5T442-697L300-326q-5 14 3.5 26t23.5 12q9 0 16-5t10-13l37-98Zm18-49 71-191h2l72 191H408Zm72 373q-85 0-158-30.5T195-195q-54-54-84.5-127T80-480q0-84 30.5-157T195-764q54-54 127-85t158-31q84 0 157 31t127 85q54 54 85 127t31 157q0 85-31 158t-85 127q-54 54-127 84.5T480-80Zm0-400Zm0 340q145 0 242.5-97.5T820-480q0-145-97.5-242.5T480-820q-145 0-242.5 97.5T140-480q0 145 97.5 242.5T480-140Z"
		/>
	</svg>
);
