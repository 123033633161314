import { ReactNode } from 'react';

export const symbol_turn_right: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M280-190v-338q0-24.75 17.625-42.375T340-588h386l-69-69q-9-9-9-21t9-21q9-9 21-9t21 9l120 120q9 9 9 21t-9 21L699-417q-9 9-21 9t-21-9q-9-9-9-21t9-21l69-69H340v338q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T280-190Z"
		/>
	</svg>
);
