import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
	width: 100%;
	background-color: var(--palette-white);
	box-shadow: var(--palette-gray-300) 0 0 0 1px;
	border-radius: var(--radius-5);
	display: block;
`;

export const Header = styled.div<{ noclip?: boolean }>`
	padding: var(--spacing-4);
	display: grid;
	grid-template-columns: 1fr auto;
	grid-template-rows: auto auto;
	font-size: var(--font-size-xs);
	gap: var(--spacing-2);
	color: var(--palette-gray-500);

	${({ noclip }) =>
		!noclip &&
		css`
			span {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		`}

	span:first-child {
		grid-area: 1 / 1 / 2 / 3;
		font-size: var(--font-size-base);
		font-weight: var(--font-weight-semibold);
		color: var(--palette-black);
	}
`;
