import { ReactNode } from 'react';

export const symbol_u_turn_right: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M281.825-120Q269-120 260.5-128.625T252-150v-456q0-97.5 68.25-165.75T486-840q97.5 0 165.75 68.25T720-606v214l69-69q9-9 21-9t21 9q9 9 9 21t-9 21L711-299q-5 5-10.133 7-5.134 2-11 2Q684-290 679-292q-5-2-10-7L549-419q-9-9-9-21t9-21q9-9 21-9t21 9l69 69v-214q0-71.775-51.159-122.888-51.16-51.112-123-51.112Q414-780 363-728.888 312-677.775 312-606v456q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Z"
		/>
	</svg>
);
