import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import { useEndpoint } from 'pkg/api/use_endpoint';
import * as endpoints from 'pkg/api/endpoints/auto';
import { UserProductStatuses } from 'pkg/api/models/user_product';
import DateTime from 'pkg/datetime';

import Icon from 'components/icon';
import Label from 'components/label';
import * as CardList from 'components/card-list';
import * as StepModal from 'components/step-modal';

import Column from 'components/layout/column';

import * as css from './styles.css';

interface OverdueUserProductsModalProps {
	membership: models.membership.Membership;

	onClose: () => void;
}

interface OverdueUserProductProps {
	userProduct: models.userProduct.UserProduct;
}

function OverdueUserProduct({ userProduct }: OverdueUserProductProps) {
	const invoiced = DateTime.fromTimestamp(userProduct.createdAt);
	const validUntil = userProduct.validUntil
		? DateTime.fromTimestamp(userProduct.validUntil)
		: null;

	return (
		<CardList.Base>
			<CardList.IconWrapper>
				<Icon name="tag" />
			</CardList.IconWrapper>
			<CardList.Title>
				<div>{userProduct.product.name}</div>
				<CardList.SubTitle>{invoiced.toLocaleDateString()}</CardList.SubTitle>
			</CardList.Title>
			<CardList.Actions>
				<Column spacing={styles.spacing._1} justify="center">
					<Label color="red">{t('Past due')}</Label>
					{validUntil && (
						<span className={css.validUntil}>
							{t('Valid until {date}', {
								date: validUntil.toLocaleDateString({}),
							})}
						</span>
					)}
				</Column>
			</CardList.Actions>
		</CardList.Base>
	);
}

export default function OverdueUserProductsModal({
	membership,
	onClose,
}: OverdueUserProductsModalProps) {
	const userId = membership.userId;
	const { record: user, isLoading } = useEndpoint<models.user.User>(
		endpoints.Users.Show(userId),
		{
			queryParams: new URLSearchParams({
				include_user_products: 'true',
			}),
		}
	);

	const pastDueUserProducts = user.products?.filter(
		(p) => p.status === UserProductStatuses.PastDue
	);

	if (isLoading) {
		return null;
	}

	return (
		<StepModal.Base onClose={onClose} wide>
			<StepModal.Step
				title={t('Overdue products for {name}', {
					name: models.user.fullName(user),
				})}>
				<Column spacing={styles.spacing._5}>
					<div className={css.overdueProductsTitle}>
						{t('Overdue products')}
					</div>
					<Column spacing={styles.spacing._3}>
						{pastDueUserProducts?.map((userProduct) => (
							<OverdueUserProduct userProduct={userProduct} />
						))}
					</Column>
				</Column>
			</StepModal.Step>
		</StepModal.Base>
	);
}
