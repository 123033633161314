import { ReactNode } from 'react';

export const symbol_remove_road: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M740-469v-301q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T800-770v301q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T740-469ZM160-190v-580q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T220-770v580q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T160-190Zm290-493v-87q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-770v87q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T450-683Zm0 246v-87q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-524v87q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T450-437Zm0 247v-88q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-278v88q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T450-190Zm321 22-64 64q-9 9-21 8.5t-21.391-9.5Q656-114 656-126t9-21l63-63-64-64q-9-8.8-9-20.9 0-12.1 9.053-21.1 9.052-9 21.5-9Q698-325 707-316l63 63 64-64q9-9 21.5-9t21.891 9Q886-308 886-295.5t-9 21.5l-63 63 63 64q9 9 9 21t-9 21q-9 9-21.5 9t-21.5-9l-63-63Z"
		/>
	</svg>
);
