import * as models from 'pkg/api/models';

import Badge from 'components/Badge';

import * as GroupCard from 'components/group/GroupCard';

interface GroupTargetProps {
	group: models.group.Group;
}

export default function GroupTarget({ group }: GroupTargetProps): JSX.Element {
	return (
		<GroupCard.Base>
			<GroupCard.Group>
				<Badge badgeUrl={group.profileImageUrl} size="44px" />
				<div>
					<strong>{group.name}</strong>
				</div>
			</GroupCard.Group>
		</GroupCard.Base>
	);
}
