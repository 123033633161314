import { memo } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';

import LastReadAvatars from 'containers/chat/LastReadAvatars';

const Wrapper = styled.div`
	margin-bottom: var(--spacing-5);
	position: relative;

	> div:first-child {
		font-size: var(--font-size-sm);
		color: var(--palette-gray-500);
		font-style: italic;
		text-align: center;
		padding: 0 var(--spacing-8);
	}
`;

interface SystemReplyProps {
	lastReadUsers: models.user.User[];

	items: models.chatMessage.ChatMessage[];
}

function findTranslation(items: models.chatMessage.ChatMessage[]) {
	const type = items[0].content;

	const users = items.map((cm) => cm.user);

	const names = users
		.filter((n) => n)
		.map(({ firstName }) => firstName)
		.join(', ');

	if (type === 'joined') {
		return t('{names} joined', { names });
	} else {
		return t('{names} left', { names });
	}
}

const SystemReply = memo(({ items, lastReadUsers }: SystemReplyProps) => {
	const reply = findTranslation(items);

	return (
		<Wrapper>
			<div>{reply}</div>

			{lastReadUsers && <LastReadAvatars users={lastReadUsers} isSystemReply />}
		</Wrapper>
	);
});

export default SystemReply;
