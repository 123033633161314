import { ReactNode } from 'react';

export const symbol_hide_image: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M840-780v485q0 15-9.318 22.5t-20.5 7.5Q799-265 789.5-272.683 780-280.366 780-295v-485H294q-15 0-22.5-9.318t-7.5-20.5q0-11.182 7.5-20.682Q279-840 294-840h486q24 0 42 18t18 42ZM180-120q-24 0-42-18t-18-42v-562l-42-42q-9-9-9-21.5t9-21.5q9-9 21.5-9t21.5 9l707 707q9 9 9 21t-9 21q-9 9-21.5 9T785-78l-43-42H180Zm406-157H266q-8.5 0-12.75-8t1.75-16l86-112q5-6 12-6t12 6l81 111 50-65-316-316v503h503l-97-97Zm-49-260ZM432-432Z"
		/>
	</svg>
);
