import { ReactNode } from 'react';

export const symbol_shield_lock: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M390-320h180q12.75 0 21.375-8.625T600-350v-140q0-12.75-8.625-21.375T570-520h-10v-40q0-33-23.5-56.5T480-640q-33 0-56.5 23.5T400-560v40h-10q-12.75 0-21.375 8.625T360-490v140q0 12.75 8.625 21.375T390-320Zm40-200v-40q0-20 15-33.5t35-13.5q20 0 35 13.513 15 13.512 15 33.487v40H430Zm50 436q-5.32 0-9.88-1-4.56-1-9.12-3-139-47-220-168.5t-81-266.606V-719q0-19.257 10.875-34.662Q181.75-769.068 199-776l260-97q11-4 21-4t21 4l260 97q17.25 6.932 28.125 22.338Q800-738.257 800-719v195.894Q800-378 719-256.5T499-88q-4.56 2-9.12 3T480-84Zm0-59q115-38 187.5-143.5T740-523v-196l-260-98-260 98v196q0 131 72.5 236.5T480-143Zm0-337Z"
		/>
	</svg>
);
