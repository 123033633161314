import { Fragment, SyntheticEvent } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';

import * as Input from 'components/form/inputs';
import Row from 'components/layout/row';

import * as Table from 'design/table';

type SelectableOptions = 'multi' | 'single';

interface CategoryRowProps {
	category: models.resourceCategory.ResourceCategory;
	selectedCategories: number[];

	selectable: SelectableOptions;

	select: (categoryId: number) => void;
}

const CategoryRow = ({
	category,
	selectable,
	select,
	selectedCategories,
}: CategoryRowProps) => {
	const handleClick = (e: SyntheticEvent<HTMLDivElement>) => {
		e.stopPropagation();

		select(category.id);
	};

	return (
		<Fragment>
			<Table.Row>
				{selectable ? (
					<Table.Cell onClick={handleClick}>
						<Row spacing={styles.spacing._2} autoColumns="auto" align="center">
							<div>
								<Input.Control
									type={selectable === 'single' ? 'radio' : 'checkbox'}
									standalone
									checked={selectedCategories.includes(category.id)}
								/>
							</div>
							<span>{category.title}</span>
						</Row>
					</Table.Cell>
				) : (
					<Table.Cell>{category.title}</Table.Cell>
				)}
			</Table.Row>
		</Fragment>
	);
};

interface ResourceCategoriesTableProps {
	categories: models.resourceCategory.ResourceCategory[];

	selectedCategories?: number[];
	search?: string;

	select?: (categoryId: number) => void;
	selectAll?: () => void;

	selectable?: SelectableOptions;
}

const ResourceCategoriesTable = ({
	categories,
	search,
	selectable,
	selectedCategories,
	select,
	selectAll,
}: ResourceCategoriesTableProps) => {
	const titleContent =
		selectable === 'multi' ? (
			<Row align="center" spacing={styles.spacing._1}>
				<Input.Control
					checked={selectedCategories.length === categories.length}
					type="checkbox"
					standalone
					onChange={selectAll}
				/>
				{t('Title')}
			</Row>
		) : (
			t('Title')
		);

	const columns: Table.HeaderColumn[] = [
		{
			content: titleContent,
		},
	];

	return (
		<Table.Table columns={columns}>
			{search ? (
				categories
					.filter((c) =>
						c.title.toLocaleLowerCase().includes(search.toLocaleLowerCase())
					)
					.sort((a, b) => a.title.localeCompare(b.title))
					.map((category) => (
						<CategoryRow
							key={category.id}
							category={category}
							selectedCategories={selectedCategories}
							select={select}
							selectable={selectable}
						/>
					))
			) : (
				<Fragment>
					{categories
						.sort((a, b) => a.title.localeCompare(b.title))
						.map((category) => (
							<CategoryRow
								key={category.id}
								category={category}
								selectedCategories={selectedCategories}
								select={select}
								selectable={selectable}
							/>
						))}
				</Fragment>
			)}
		</Table.Table>
	);
};

export default ResourceCategoriesTable;
