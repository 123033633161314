import { t } from '@transifex/native';
import {
	ChangeEvent,
	Dispatch,
	Fragment,
	ReactNode,
	SetStateAction,
	useRef,
	useState,
} from 'react';

import spacing from 'pkg/config/spacing';

import * as models from 'pkg/api/models';
import { useCurrentMembership, useCurrentOrganization } from 'pkg/identity';
import { localeIncludes } from 'pkg/strings';
import { useOrganizationIdentity } from 'pkg/identity/organization';
import { useUser } from 'pkg/hooks/selectors';
import * as routes from 'pkg/router/routes';
import { pushState } from 'pkg/router/state';
import { link } from 'pkg/router/utils';

import MaterialSymbol from 'components/material-symbols';
import Badge from 'components/Badge';
import Avatar from 'components/avatar';

import * as Input from 'components/form/inputs';
import GroupSwitcherItem from 'components/navigation/menus/group_switcher/item';
import { useGroupSwitcher } from 'components/navigation/menus/group_switcher/Provider';
import Row from 'components/layout/row';
import { Spinner } from 'components/loaders/spinner';
import { useContextSwitchFooter } from 'components/navigation/menus/context_switch';
import Column from 'components/layout/column';
import { useAppState } from 'components/application/state';

import * as Context from 'design/context_menu';
import Button from 'design/button';

import * as css from './styles.css';

function GroupSwitcherTrigger(): JSX.Element {
	const membership = useCurrentMembership();
	const { organization } = useOrganizationIdentity();

	const collapsedDrawer = useAppState().collapsedDrawer;

	let subHeading: string =
		models.membership.translatedRoleStringFromMembership(membership);

	if (membership.isOrganizationMembership) {
		subHeading = t('Viewing club lobby');
	}
	const isParent = models.membership.isParent(membership);

	const targetUser = useUser(membership.targetUserId);
	const targetUserFirstName = targetUser.firstName;

	const badgeUrl = collapsedDrawer
		? organization.profileImageUrl
		: membership.group?.profileImageUrl;

	return (
		<Fragment>
			<Row
				columns="1fr auto"
				align="center"
				justifyContent="center"
				className={css.trigger}>
				<Column spacing={spacing._1}>
					<strong className={css.triggerName}>{membership.group.name}</strong>
					<span className={css.triggerRole}>
						{!isParent && subHeading}
						{isParent && targetUserFirstName}
					</span>
				</Column>
				<MaterialSymbol actualSize scale={1.7} variant="expand_more" />
			</Row>
			<div className={css.smallTrigger}>
				<div className={css.badgeWrapper}>
					<Badge badgeUrl={badgeUrl} />
				</div>
			</div>
		</Fragment>
	);
}

interface WithKeywordsProps {
	keywords?: string;
	setKeywords: Dispatch<SetStateAction<string>>;
}

function GroupSwitcherHeader({
	keywords,
	setKeywords,
}: WithKeywordsProps): JSX.Element {
	const searchRef = useRef<HTMLInputElement>();
	const { nodeTree, setSearch, ascend } = useGroupSwitcher();

	const canGoBack = nodeTree.length > 0;

	const handleGoBack = () => {
		setKeywords('');
		ascend();
	};

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		setKeywords(event.target.value);
		setSearch(event.target.value);
	};

	const resetSearch = () => {
		setKeywords('');
		setSearch('');

		searchRef.current.focus();
	};

	return (
		<Fragment>
			<Context.Pane>
				<Column>
					<Row align="center" columns={canGoBack ? 'auto 1fr' : '1fr'}>
						{canGoBack && (
							<Button onClick={handleGoBack} className={css.backButton}>
								<MaterialSymbol variant="navigate_before" scale={2.2} />
							</Button>
						)}
						<Input.Field
							ref={searchRef}
							type="search"
							placeholder={t('Search groups…')}
							value={keywords}
							onChange={handleChange}>
							<Input.Prefix inline>
								<MaterialSymbol variant="search" scale={1.3} />
							</Input.Prefix>
							{keywords?.length > 0 && (
								<Input.Suffix inline onClick={resetSearch}>
									<MaterialSymbol variant="close" scale={1.3} />
								</Input.Suffix>
							)}
						</Input.Field>
					</Row>
				</Column>
			</Context.Pane>
		</Fragment>
	);
}

function GroupSwitcherWardGroups(): JSX.Element {
	const { search } = useGroupSwitcher();
	const { wards } = useOrganizationIdentity();

	const segments: ReactNode[] = [];

	if (wards.length === 0) {
		return null;
	}

	wards.forEach((user: models.user.User) => {
		const memberships = user.groups ?? [];

		if (memberships.length === 0) {
			return;
		}

		const filteredMemberships = memberships.filter(
			(membership: models.membership.Membership) =>
				search.length > 0 ? localeIncludes(membership.group.name, search) : true
		);

		if (filteredMemberships.length > 0) {
			segments.push(
				<Fragment key={user.id}>
					<Context.SegmentHeading>
						<Avatar size={16} user={user} />
						<span>{models.user.fullName(user)}</span>
					</Context.SegmentHeading>
					<div className={css.groupsWrapper}>
						{memberships.map((membership: models.membership.Membership) => (
							<GroupSwitcherItem
								key={membership.id}
								userId={membership.userId}
								group={membership.group}
								subHeading={models.membership.roleToTranslatedString(
									membership.role as models.membership.MembershipRole
								)}
							/>
						))}
					</div>
				</Fragment>
			);
		}
	});

	if (segments.length === 0) {
		return null;
	}

	return <Fragment>{segments}</Fragment>;
}

function GroupSwitcherGroups({ setKeywords }: WithKeywordsProps): JSX.Element {
	const {
		isLoading,
		isTraversing,
		search,
		groups,
		memberships,
		parentGroup,
		currentNodeId,
		descend,
	} = useGroupSwitcher();

	const organization = useCurrentOrganization();

	const handleTraverse = (parentGroupId: number) => {
		descend(parentGroupId);
	};

	const resetSearch = () => {
		setKeywords('');
	};

	const getMembershipRoleByGroupId = (groupId: number) => {
		if (isTraversing || search.length > 0) {
			return '';
		}

		const membership = memberships.find(
			(membership: models.membership.Membership) =>
				membership.groupId === groupId
		);

		if (membership) {
			return models.membership.translatedRoleStringFromMembership(membership);
		}

		return '';
	};

	let heading: string = '';
	let showClubLobby: boolean = true;

	if (currentNodeId !== 0) {
		heading = parentGroup.name;
		showClubLobby = false;
	} else if (search.length > 0) {
		heading = t('Search results');
		showClubLobby = false;
	} else {
		heading = t('My groups');
	}

	if (search.length > 0 && groups.length === 0) {
		return (
			<Context.Pane padding="var(--spacing-4) var(--spacing-4) var(--spacing-7) var(--spacing-4)">
				<Column justify="center" spacing={spacing._6}>
					<MaterialSymbol actualSize variant="manage_search" scale={6.5} />
					<Column justify="center" spacing={spacing._2}>
						<p>{t('No matches searching for')}</p>
						<mark className={css.keyword}>{search}</mark>
					</Column>
					<Button secondary onClick={resetSearch}>
						{t('Reset search')}
					</Button>
				</Column>
			</Context.Pane>
		);
	}

	const activateClubLobby = () => {
		pushState(link(routes.Group.Change(), { organizationId: organization.id }));
	};

	return (
		<Fragment>
			{isLoading && <Spinner />}
			<Context.SegmentHeading>{heading}</Context.SegmentHeading>
			<div className={css.groupsWrapper}>
				{showClubLobby && (
					<Fragment>
						<Context.Item tight onClick={activateClubLobby}>
							<Row
								align="center"
								justify="start"
								columns="40px 1fr"
								spacing={spacing._4}>
								<div className={css.clubBadge}>
									<Badge badgeUrl={organization.profileImageUrl} />
								</div>
								<Column spacing={spacing._1}>
									<strong>{t('Club lobby')}</strong>
									<span className={css.itemSubHeading}>
										{t('Registrations and club info')}
									</span>
								</Column>
							</Row>
						</Context.Item>
					</Fragment>
				)}

				{groups?.map((group: models.group.Group) => {
					const closestParentName =
						search.length > 0 && group.parents?.length > 0
							? group.parents.at(-1).name
							: '';

					const membershipRole = getMembershipRoleByGroupId(group.id);

					return (
						<Fragment key={group.id}>
							<GroupSwitcherItem
								group={group}
								supHeading={closestParentName}
								subHeading={membershipRole}
								onTraverse={handleTraverse}
							/>
						</Fragment>
					);
				})}
			</div>
		</Fragment>
	);
}

export default function GroupSwitcher(): JSX.Element {
	const { Footer, JoinModal } = useContextSwitchFooter();
	const { reset, currentNodeId } = useGroupSwitcher();

	const [keywords, setKeywords] = useState<string>('');

	return (
		<Fragment>
			<Context.Menu toggleWith={<GroupSwitcherTrigger />} onClose={reset}>
				<GroupSwitcherHeader keywords={keywords} setKeywords={setKeywords} />
				<GroupSwitcherGroups keywords={keywords} setKeywords={setKeywords} />
				{!currentNodeId && <GroupSwitcherWardGroups />}
				{keywords.length === 0 && !currentNodeId && Footer}
			</Context.Menu>
			{JoinModal}
		</Fragment>
	);
}
