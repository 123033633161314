import { ReactNode } from 'react';

export const symbol_medication_liquid: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M150-840h420q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T570-780H150q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-840Zm160 470v50q0 20.833 14.618 35.417Q339.235-270 360.118-270 381-270 395.5-284.583 410-299.167 410-320v-50h50q20.833 0 35.417-14.618Q510-399.235 510-420.118 510-441 495.417-455.5 480.833-470 460-470h-50v-50q0-20.833-14.618-35.417Q380.765-570 359.882-570 339-570 324.5-555.417 310-540.833 310-520v50h-50q-20.833 0-35.417 14.618Q210-440.765 210-419.882 210-399 224.583-384.5 239.167-370 260-370h50ZM140-120q-24 0-42-18t-18-42v-480q0-24 18-42t42-18h440q24 0 42 18t18 42v480q0 24-18 42t-42 18H140Zm0-60h440v-480H140v480Zm640-246q-35-17-57.5-55T700-570q0-63 32-106.5t78-43.5q46 0 78 43.5T920-570q0 51-22.5 89T840-426v276q0 12-8.625 21T810-120q-12 0-21-9t-9-21v-276Zm30-54q15 0 32.5-24.188Q860-528.375 860-570t-17.5-65.812Q825-660 810-660t-32.5 24.188Q760-611.625 760-570t17.5 65.812Q795-480 810-480Zm-450 60Zm450-150Z"
		/>
	</svg>
);
