import { ReactNode } from 'react';

export const symbol_electric_rickshaw: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M227.914-280q-42.087 0-75-26Q120-332 110-374h-10q-24.75 0-42.375-17.625T40-434v-346q0-24.75 17.625-42.375T100-840h513.812Q633-840 651-834q18 6 29 20l152 197q8 11 11 24.5t3 27.5v51q34 15 54 45.265 20 30.264 20 67.735 0 51-35 86t-86 35q-42 0-74.34-26.438Q692.32-332.875 680-374H346q-12 41-44 67.5T227.914-280ZM100-638h189v-142H100v142Zm249 204h236v-346H349v142h111q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T460-578H349v144Zm296-157h127L645-755v164ZM228.053-340Q254-340 271.5-357.553q17.5-17.552 17.5-43.5Q289-427 271.447-444.5q-17.552-17.5-43.5-17.5Q202-462 184.5-444.447q-17.5 17.552-17.5 43.5Q167-375 184.553-357.5q17.552 17.5 43.5 17.5Zm571 0Q825-340 842.5-357.553q17.5-17.552 17.5-43.5Q860-427 842.447-444.5q-17.552-17.5-43.5-17.5Q773-462 755.5-444.447q-17.5 17.552-17.5 43.5Q738-375 755.553-357.5q17.552 17.5 43.5 17.5ZM495-51l-185-96q-5-3-3.909-8.5 1.091-5.5 6.909-5.5h130v-50q0-8 7-13t15-1l184 90q5 2 3.5 8t-7.5 6H517v56q0 8-7 13t-15 1ZM100-578v144h12q11-38 43.5-63t72.5-25q16.775 0 32.025 5T289-502v-76H100Zm545 144h37q11-36 39-60.5t65-24.5v-12H645v97ZM100-578h189-189Zm545 47h141-141Z"
		/>
	</svg>
);
