import { ReactNode } from 'react';

export const symbol_detection_and_zone: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M80-718v-102q0-24 18-42t42-18h102q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T242-820H140v102q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Q97-688 88.5-696.625T80-718Zm60 638q-24 0-42-18t-18-42v-102q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-242v102h102q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q272-97 263.375-88.5T242-80H140Zm680 0H718q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T718-140h102v-102q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T880-242v102q0 24-18 42t-42 18Zm0-638v-102H718q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T718-880h102q24 0 42 18t18 42v102q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T820-718ZM540.08-594q-30.08 0-51.58-21.42-21.5-21.421-21.5-51.5 0-30.08 21.42-51.58 21.421-21.5 51.5-21.5 30.08 0 51.58 21.42 21.5 21.421 21.5 51.5 0 30.08-21.42 51.58-21.421 21.5-51.5 21.5ZM500-235H357l46-235-100 47v104q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T243-319v-125q0-9.333 4.875-16.722Q252.75-468.111 261-472l146-61q32-14 45.5-17.5t27.1-3.5q20.4 0 35.9 8.5T542-520l42 67q23 37 60 65.5t86 36.5q12.75 2.164 21.375 10.82Q760-331.525 760-319.262 760-307 751.449-298q-8.552 9-20.449 7-57-6-102.5-36.5T543-413l-43 178Z"
		/>
	</svg>
);
