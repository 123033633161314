import { EventTypes } from 'pkg/models/event';

import { Dateable } from 'pkg/api/models/dateable';
import { Record } from 'pkg/api/models/record';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as sdk from 'pkg/core/sdk';

import {
	ConflictingItems,
	conflictingTimes,
	ConflictMap,
	getItemResourceIds,
	timesAsNumbers,
} from 'routes/scheduling/utils';

import { Group } from './group';
import { Resource } from './resource';

// A scheduleItem can have multiple resources connected to it, we still have the
// single resourceId number to determine which of the resources are the primary one.
// The primary resource is used to get location/category.
export interface ScheduleItem extends Record, Dateable {
	scheduleId: number;
	resourceId?: number;
	groupId: number;
	day: number;
	title: string;
	description: string;
	publishedAt?: number;
	startsAt: string;
	endsAt: string;
	clientId: string;

	type: EventTypes;
	group?: Group;
	resource?: Resource;
	resources?: ScheduleItemResource[];
}

export interface ScheduleItemResource extends Record {
	resourceId: number;
	scheduleItemId: number;

	resource: Resource;
}

export function getConflictingItems(
	scheduleItems: ScheduleItem[],
	conflictMap: ConflictMap
): ConflictingItems {
	const conflicts: ConflictingItems = {};
	scheduleItems.forEach((scheduleItem) => {
		const itemTimes = timesAsNumbers([
			scheduleItem.startsAt,
			scheduleItem.endsAt,
		]);
		const itemResourceIds = getItemResourceIds(scheduleItem);

		// check for conflicts
		scheduleItems.forEach((innerScheduleItem) => {
			const innerItemResourceIds = getItemResourceIds(innerScheduleItem);
			let hasSameGroup = false;
			let hasRelatedResource = false;
			if (innerScheduleItem.groupId === scheduleItem.groupId) {
				hasSameGroup = true;
			}

			if (itemResourceIds.length > 0 && innerItemResourceIds.length > 0) {
				itemResourceIds.forEach((r) => {
					innerItemResourceIds.forEach((cir) => {
						if (conflictMap[r]?.has(cir)) {
							hasRelatedResource = true;
						}
					});
				});
			}

			if (!hasSameGroup && !hasRelatedResource) {
				return;
			}

			// items on different days can't collide
			if (innerScheduleItem.day !== scheduleItem.day) {
				return;
			}

			// can't collide with self
			if (innerScheduleItem.id === scheduleItem.id) {
				return;
			}

			const cit = timesAsNumbers([
				innerScheduleItem.startsAt,
				innerScheduleItem.endsAt,
			]);
			const hasConflict = conflictingTimes(cit, itemTimes);

			if (!hasConflict) {
				return;
			}

			if (!conflicts[scheduleItem.id]) {
				conflicts[scheduleItem.id] = {
					groupConflicts: [],
					resourceConflicts: [],
					scheduleItems: [],
				};
			}

			if (innerScheduleItem.groupId === scheduleItem.groupId) {
				conflicts[scheduleItem.id].groupConflicts = [
					...new Set([
						...conflicts[scheduleItem.id].groupConflicts,
						innerScheduleItem.id,
					]),
				];

				conflicts[scheduleItem.id].scheduleItems = [
					...new Set([
						...conflicts[scheduleItem.id].scheduleItems,
						innerScheduleItem.id,
					]),
				];
			}

			if (itemResourceIds.length > 0 && innerItemResourceIds.length > 0) {
				itemResourceIds.forEach((r) => {
					innerItemResourceIds.forEach((cir) => {
						if (conflictMap[r]?.has(cir)) {
							conflicts[scheduleItem.id].resourceConflicts = [
								...new Set([
									...conflicts[scheduleItem.id].resourceConflicts,
									cir,
								]),
							];

							conflicts[scheduleItem.id].scheduleItems = [
								...new Set([
									...conflicts[scheduleItem.id].scheduleItems,
									innerScheduleItem.id,
								]),
							];
						}
					});
				});
			}
		});
	});

	return conflicts;
}

export interface ScheduleItemCreatePayload {
	title: string;
	groupId: number;
	scheduleId: number;
	resourceId?: number;
	resourceIds?: number[];
	description: string;
	day: number;
	startsAt: string;
	endsAt: string;
	clientId: string;
	type: EventTypes;
}

export async function create(
	payload: ScheduleItemCreatePayload
): Promise<[Response, ScheduleItem, models.APIError?]> {
	return models.create(endpoints.ScheduleItem.Create(), payload);
}

export interface ScheduleItemUpdatePayload {
	title: string;
	groupId: number;
	resourceId?: number;
	// To clear out resources send in an empty array
	resourceIds?: number[];
	description: string;
	day: number;
	startsAt?: string;
	endsAt?: string;
	type?: EventTypes;
}

export async function update(
	scheduleItem: ScheduleItem,
	payload: ScheduleItemUpdatePayload
): Promise<[Response, ScheduleItem]> {
	const req = await sdk.patch(
		endpoints.ScheduleItem.Update(scheduleItem.id),
		{},
		payload
	);

	const response: ScheduleItem = await req.json();

	return [req, response];
}

export async function remove(scheduleItem: ScheduleItem): Promise<boolean> {
	const req = await sdk.destroy(endpoints.ScheduleItem.Delete(scheduleItem.id));

	if (req.ok) {
		return true;
	}

	return false;
}
