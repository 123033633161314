import { ReactNode } from 'react';

export const symbol_climate_mini_split: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M820-480H140q-24.75 0-42.375-17.625T80-540v-280q0-24.75 17.625-42.375T140-880h680q24.75 0 42.375 17.625T880-820v280q0 24.75-17.625 42.375T820-480ZM450-190v-160q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-350v160q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T450-190Zm370-350H140h680Zm-570 0v-110q0-24.75 17.625-42.375T310-710h340q24.75 0 42.375 17.625T710-650v110h-60v-110H310v110h-60Zm-110 0h680v-280H140v280Zm30 339q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T170-261q24 0 46-9t39-26q11-11 19.5-25t12.5-30q3-12 11.786-20.5 8.785-8.5 21.232-8.5 10.982 0 19.482 7t8.5 18q0 24.556-17 54.778-17 30.222-33.826 47.048Q271-227 238.376-214T170-201Zm619 0q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T789-261q-24 0-46-9t-39-26q-11-11-19.5-25T672-351q-3-12-11.786-20.5-8.785-8.5-21.232-8.5-10.982 0-19.482 7t-8.5 18q0 24.556 17 54.778 17 30.222 33.826 47.048Q688-227 720.624-214T789-201Z"
		/>
	</svg>
);
