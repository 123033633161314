import { ReactNode } from 'react';

export const symbol_local_library: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M120-258v-308q0-26.105 20-44.053Q160-628 186-626q86 8 158.5 52.5T480-469q63-60 135.5-104.5T774-626q26-2 46 15.947 20 17.948 20 44.053v308q0 23-16 40t-39 19q-73 6-142 35T517-90q-8 7-17.295 10-9.295 3-19.705 3-10.41 0-19.705-3Q451-83 443-90q-57-45-126-74t-142-35q-23-2-39-19t-16-40Zm360 121q71-51 146.5-81.5T780-257v-310q-68 11-143.5 54.5T480-392q-88-81-160-123t-140-52v310q78 8 153.5 38.5T480-137Zm3-475q-65 0-109.5-44.5T329-766q0-65 44.5-109.5T483-920q65 0 109.5 44.5T637-766q0 65-44.5 109.5T483-612Zm.159-60Q522-672 549.5-699.659q27.5-27.66 27.5-66.5Q577-805 549.341-832.5q-27.66-27.5-66.5-27.5Q444-860 416.5-832.341q-27.5 27.66-27.5 66.5Q389-727 416.659-699.5q27.66 27.5 66.5 27.5ZM483-766Zm-3 374Z"
		/>
	</svg>
);
