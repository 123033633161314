import { ReactNode } from 'react';

export const symbol_vpn_key: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M280-240q-100 0-170-70T40-480q0-100 70-170t170-70q78 0 131.5 37.5T491-583h369q24.75 0 42.375 17.625T920-523v86q0 24.75-17.625 42.375T860-377h-46v77q0 24.75-17.625 42.375T754-240h-66q-24.75 0-42.375-17.625T628-300v-77H491q-26 62-79.5 99.5T280-240Zm0-60q71 0 116.5-47t53.374-90H692v137h62v-137h106v-86H450q-8-43-53.5-90T280-660q-75 0-127.5 52.5T100-480q0 75 52.5 127.5T280-300Zm0-112q29 0 48.5-19.5T348-480q0-29-19.5-48.5T280-548q-29 0-48.5 19.5T212-480q0 29 19.5 48.5T280-412Zm0-68Z"
		/>
	</svg>
);
