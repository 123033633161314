import { ReactNode } from 'react';

export const symbol_outpatient: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M70-120q-12.75 0-21.375-8.625T40-150v-660q0-12.75 8.625-21.375T70-840h500q12.75 0 21.375 8.625T600-810v660q0 12.75-8.625 21.375T570-120H371v-170H270v170H70Zm30-60h110v-170h221v170h109v-600H100v600Zm110-270h60v-60h-60v60Zm0-160h60v-60h-60v60Zm160 160h60v-60h-60v60Zm0-160h60v-60h-60v60ZM210-180v-170h221v170-170H210v170Zm430-300.175q0-12.825 8.625-21.325T670-510h135l-32-33q-8-9-8.5-21t8.5-21q9-9 21-9t21 9l84 84q9 9 9 21t-9 21l-84 84q-9 9-21 9t-21-9q-9-9-8.5-21t8.5-21l32-33H670q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5Z"
		/>
	</svg>
);
