import { ReactNode } from 'react';

export const symbol_rewarded_ads: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-517q37 0 62.5-25.5T568-605q0-37-25.5-62.5T480-693q-37 0-62.5 25.5T392-605q0 37 25.5 62.5T480-517Zm-30 337v-148q-54-11-96-46.5T296-463q-74-8-125-60t-51-125v-44q0-24.75 17.625-42.375T180-752h104v-28q0-24.75 17.625-42.375T344-840h272q24.75 0 42.375 17.625T676-780v28h104q24.75 0 42.375 17.625T840-692v44q0 73-51 125t-125 60q-16 53-58 88.5T510-328v148h122q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T632-120H328q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T328-180h122ZM284-526v-166H180v44q0 45 29.5 78.5T284-526Zm196.235 141Q537-385 576.5-424.958 616-464.917 616-522v-258H344v258q0 57.083 39.735 97.042Q423.471-385 480.235-385ZM676-526q45-10 74.5-43.5T780-648v-44H676v166Zm-196-57Z"
		/>
	</svg>
);
