import { ReactNode } from 'react';

export const symbol_broadcast_on_personal: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M400-513ZM140-160q-24.75 0-42.375-17.625T80-220v-390q0-14.25 6.375-27T104-658l260-195q8.295-6 17.344-9 9.049-3 18.853-3 9.803 0 18.717 3 8.915 3 17.086 9l189 142q12.235 9.209 12.618 21.105Q638-678 631-669t-19 12q-12 3-24-6L400-805 140-610v390h257q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T397-160H140Zm550.106-410Q603-570 541.5-509.1T480-360q0 38.609 14 74.804Q508-249 534-221q6 6 6 14.5t-5.5 14.5q-5.5 6-13.5 6.5t-14-5.5q-32-34-49.5-77.74Q440-312.48 440-360q0-104.808 73.117-177.404Q586.233-610 690.135-610 795-610 867.5-537.404 940-464.808 940-360q0 47.168-17.5 90.584Q905-226 873-192q-6 6-14 5.5t-13-6.5q-5-6-5-14.5t6-14.5q26-28 39.5-64t13.5-74q0-88.2-60.869-149.1-60.87-60.9-149.025-60.9ZM690-470q-45 0-77.5 31.9T580-360q0 19.412 7 37.206Q594-305 606-291q5 6.368 5 14.184Q611-269 605.5-263q-5.5 6-13.5 6t-13-6q-18-20-28.5-44.5T540-360q0-62.812 44.101-106.406Q628.203-510 690.132-510 753-510 796.5-466.406 840-422.812 840-360q0 27-9 52.5T804-262q-5 6-14 6t-14-7q-5-7-4.5-15t5.5-15q11-14 17-31.5t6-35.5q0-46.2-31.9-78.1Q736.2-470 690-470Zm0 350q-8 0-14-6t-6-14v-185q-9-6-14.5-15t-5.5-20q0-16.8 11.5-28.4Q673-400 689.585-400 707-400 718.5-388.4T730-360q0 11-5 20t-15 15v185q0 8-6 14t-14 6Z"
		/>
	</svg>
);
