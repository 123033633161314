import { ReactNode } from 'react';

export const symbol_nightlight: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M593-80q-88 0-166.5-31.5t-137.868-86.563q-59.369-55.063-94-129.5Q160-402 160-488q0-86 34.5-161t94-130q59.5-55 138-86.5T593-897q29.667 0 59.333 5.5Q682-886 711-876q10 3 15.5 11.203T732-848q0 6.12-2.5 11.56T722-826q-74 66-119 152.5T558-488q0 97.92 44 184.96T721-151q5 5 7.5 10.558Q731-134.885 731-129q0 8.938-5 17.469T711-100q-29 10-58.524 15T593-80Zm0-60h26.419Q632-140 640-141q-66-74-104-162t-38-185q0-97 38-185t104-163q-8-1-20.581-1H593q-153 0-263 101.962-110 101.963-110 247Q220-343 330-241.5T593-140Zm-95-349Z"
		/>
	</svg>
);
