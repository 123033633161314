export default {
	name: 'folder-list',
	objects: [
		{
			type: 'path',
			attributes: {
				d: 'M8.40392 10.5004C8.11066 10.5004 7.8679 10.7382 7.8679 11.0314C7.8679 11.3247 8.11066 11.5624 8.40392 11.5624C8.69718 11.5624 8.93994 11.3247 8.93994 11.0314C8.93994 10.7382 8.69718 10.5004 8.40392 10.5004Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M10.1346 10.5004C9.84131 10.5004 9.60358 10.7382 9.60358 11.0314C9.60358 11.3247 9.84131 11.5624 10.1346 11.5624H15.6011C15.8944 11.5624 16.1321 11.3247 16.1321 11.0314C16.1321 10.7382 15.8944 10.5004 15.6011 10.5004H10.1346Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M10.1346 12.4872C9.84131 12.4872 9.60358 12.725 9.60358 13.0182C9.60358 13.3115 9.84131 13.5492 10.1346 13.5492H15.6011C15.8944 13.5492 16.1321 13.3115 16.1321 13.0182C16.1321 12.725 15.8944 12.4872 15.6011 12.4872H10.1346Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M9.60358 15.005C9.60358 14.7117 9.84131 14.474 10.1346 14.474H15.6011C15.8944 14.474 16.1321 14.7117 16.1321 15.005C16.1321 15.2982 15.8944 15.536 15.6011 15.536H10.1346C9.84131 15.536 9.60358 15.2982 9.60358 15.005Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M7.8679 13.0182C7.8679 12.725 8.11066 12.4872 8.40392 12.4872C8.69717 12.4872 8.93994 12.725 8.93994 13.0182C8.93994 13.3115 8.69717 13.5492 8.40392 13.5492C8.11066 13.5492 7.8679 13.3115 7.8679 13.0182Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M8.40392 14.474C8.11066 14.474 7.8679 14.7117 7.8679 15.005C7.8679 15.2982 8.11066 15.536 8.40392 15.536C8.69717 15.536 8.93994 15.2982 8.93994 15.005C8.93994 14.7117 8.69717 14.474 8.40392 14.474Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M11.3896 5.09937L13.6419 7.21979H18C19.1046 7.21979 20 8.11522 20 9.21979V16.9006C20 18.0052 19.1046 18.9006 18 18.9006H6C4.89543 18.9006 4 18.0052 4 16.9006L4.0001 7.09937C4.0001 5.9948 4.89553 5.09937 6.0001 5.09937H11.3896ZM18.7 16.9006V9.21979C18.7 8.83319 18.3866 8.51979 18 8.51979L5.3 8.51979L5.3 16.9006C5.3 17.2872 5.6134 17.6006 6 17.6006H18C18.3866 17.6006 18.7 17.2872 18.7 16.9006Z',
			},
		},
	],
};
