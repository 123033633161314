import styled from 'styled-components';
import { Fragment } from 'react';
import { t } from '@transifex/native';

import DateTime from 'pkg/datetime';
import * as endpoints from 'pkg/api/endpoints/auto';
import { PublishedPeriod } from 'pkg/api/models/schedule';
import { useCollection } from 'pkg/api/use_collection';
import * as models from 'pkg/api/models';
import { cssClasses } from 'pkg/css/utils';

import { StatePublishPeriod } from 'routes/scheduling/templates/hooks/useManagePublishedPeriods';

import Icon from 'components/icon';
import Label from 'components/label';
import * as CardList from 'components/card-list';

import Row from 'components/layout/row';
import InfoBox from 'components/form/info-box';
import Column from 'components/layout/column';

import * as css from './styles.css';

const CardBase = styled(CardList.Base)`
	display: grid !important;
	padding: var(--spacing-5) !important;
`;

interface PublishedPeriodsProps {
	groupId: number;
	scheduleId?: number;
	startDateTime: DateTime;
	endDateTime: DateTime;
}

const PublishedPeriods = ({
	groupId,
	scheduleId,
	startDateTime,
	endDateTime,
}: PublishedPeriodsProps) => {
	const { records: publishedPeriods } = useCollection<PublishedPeriod>(
		endpoints.Schedule.PublishedPeriods(),
		{
			queryParams: new URLSearchParams({
				group_id: groupId.toString(),
				from: startDateTime.getUnixTimestamp().toString(),
				to: endDateTime.getUnixTimestamp().toString(),
			}),
		}
	);

	const filteredPeriods = publishedPeriods.filter(
		(pp) => pp.scheduleId !== scheduleId
	);

	const publishedSchedulesString = filteredPeriods
		.map(
			(p) =>
				`${p.schedule?.title} (${DateTime.fromTimestamp(
					p.startsAt
				).toLocaleDateString({
					day: 'numeric',
					month: 'short',
					year: 'numeric',
				})}–${DateTime.fromTimestamp(p.endsAt).toLocaleDateString({
					day: 'numeric',
					month: 'short',
					year: 'numeric',
				})})`
		)
		.join(', ');

	if (filteredPeriods.length === 0) {
		return null;
	}

	return (
		<InfoBox
			color="yellow"
			title={t(
				`Warning - there's published schedules that will be affected by this selected period: `
			)}>
			<p>{publishedSchedulesString}</p>
		</InfoBox>
	);
};

interface PeriodDataLabelsProps {
	data: models.schedule.PublishedPeriodInfo;
}

const PeriodDataLabels = ({ data }: PeriodDataLabelsProps) => {
	return (
		<Fragment>
			{!!data.createdBookingsInPeriod && (
				<Label color="green">
					{t('{amount} new bookings', {
						amount: data.createdBookingsInPeriod,
					})}
				</Label>
			)}
			{!!data.createdEventsInPeriod && (
				<Label color="green">
					{t('{amount} new events', {
						amount: data.createdEventsInPeriod,
					})}
				</Label>
			)}
			{!!data.updatedBookingsInPeriod && (
				<Label color="orange">
					{t('{amount} changed bookings', {
						amount: data.updatedBookingsInPeriod,
					})}
				</Label>
			)}
			{!!data.updatedEventsInPeriod && (
				<Label color="orange">
					{t('{amount} changed events', {
						amount: data.updatedEventsInPeriod,
					})}
				</Label>
			)}
			{!!data.deletedBookingsInPeriod && (
				<Label color="red">
					{t('{amount} removed bookings', {
						amount: data.deletedBookingsInPeriod,
					})}
				</Label>
			)}
			{!!data.deletedEventsInPeriod && (
				<Label color="red">
					{t('{amount} removed events', {
						amount: data.deletedEventsInPeriod,
					})}
				</Label>
			)}
		</Fragment>
	);
};

interface PublishedPeriodCardListItemProps {
	showPublishedPeriods?: boolean;
	willBeRemoved?: boolean;

	actions?: JSX.Element;
	periodData?: models.schedule.PublishedPeriodInfo;

	publishedPeriod: StatePublishPeriod;
}

const PublishedPeriodCardListItem = ({
	showPublishedPeriods = false,
	willBeRemoved = false,
	actions,
	periodData,
	publishedPeriod,
}: PublishedPeriodCardListItemProps) => {
	const startDateTime = DateTime.fromTimestamp(publishedPeriod.startsAt);
	const endDateTime = DateTime.fromTimestamp(publishedPeriod.endsAt);

	const amountOfDays = startDateTime.getDaysBetween(endDateTime);

	const options: Intl.DateTimeFormatOptions = {
		weekday: 'short',
		day: 'numeric',
		month: 'short',
		year: 'numeric',
	};

	return (
		<Column>
			<CardBase>
				<CardList.IconWrapper>
					<Icon name="calendar-continue" />
				</CardList.IconWrapper>
				<CardList.Title>
					<Row autoColumns="max-content">
						<div>
							<div className={css.label}>{t('Start date')}</div>
							<div
								className={cssClasses(
									css.date,
									willBeRemoved ? css.removed : ''
								)}>
								{startDateTime.toLocaleDateString(options)}
							</div>
						</div>
						<Icon
							className={css.icon}
							name="arrow-right"
							actualSize
							size={1.6}
						/>
						<div>
							<div className={css.label}>{t('End date')}</div>
							<div
								className={cssClasses(
									css.date,
									willBeRemoved ? css.removed : ''
								)}>
								{endDateTime.toLocaleDateString(options)}
							</div>
						</div>
					</Row>
				</CardList.Title>
				<CardList.Actions>
					<div className={css.labelRow}>
						<Label color="gray">
							{t('{amountOfDays} days', {
								amountOfDays,
							})}
						</Label>
						{periodData && <PeriodDataLabels data={periodData} />}
						{publishedPeriod.id === 0 && (
							<Label color="yellow">{t('New')}</Label>
						)}
						{actions}
					</div>
				</CardList.Actions>
			</CardBase>
			{showPublishedPeriods && (
				<PublishedPeriods
					groupId={publishedPeriod.groupId}
					startDateTime={startDateTime}
					endDateTime={endDateTime}
					scheduleId={publishedPeriod.scheduleId}
				/>
			)}
		</Column>
	);
};

export default PublishedPeriodCardListItem;
