import { t } from '@transifex/native';

import { CollectionSort } from 'pkg/api/use_collection';
import * as models from 'pkg/api/models';
import { useCurrentOrganization } from 'pkg/identity';
import * as routes from 'pkg/router/routes';

import AssetImage from 'components/AssetImage';

import OrderRow from 'components/orders/table/row';
import * as Input from 'components/form/inputs';

import Button from 'design/button';
import * as Table from 'design/table';

type OrderFields =
	| 'amount_due'
	| 'assigned_contacts'
	| 'created_at'
	| 'due_date'
	| 'email'
	| 'missing_entities'
	| 'name'
	| 'number'
	| 'sent_at'
	| 'status'
	| 'terms'
	| 'type'
	| 'paid_at'
	| 'product_name';

function getTranslatedField(field: OrderFields) {
	switch (field) {
		case 'amount_due':
			return t('Amount due');
		case 'assigned_contacts':
			return t('Assigned contacts');
		case 'created_at':
			return t('Created at');
		case 'due_date':
			return t('Due date');
		case 'email':
			return t('Email');
		case 'missing_entities':
			return t('No invoices found');
		case 'name':
			return t('Name');
		case 'number':
			return t('Invoice number');
		case 'sent_at':
			return t('Sent at');
		case 'status':
			return t('Status');
		case 'terms':
			return t('Terms');
		case 'type':
			return t('Type');
		case 'paid_at':
			return t('Paid at');
		case 'product_name':
			return t('Product');
	}
}

const sortableFields = [
	'name',
	'email',
	'amount_due',
	'number',
	'terms',
	'created_at',
	'sent_at',
	'paid_at',
	'due_date',
];

interface OrderTableProps {
	isAllSelected?: boolean;
	isLoading?: boolean;
	skipCreateButton?: boolean;

	sort: CollectionSort;
	orders: models.order.Order[];

	// Function when all items in table should be selected
	handleSelectAll: () => void;
	// Function to check if passed order id is selected
	isSelected: (orderId: number) => boolean;
	// Function to run when selecting a order
	select: (orderId: number) => void;
	replaceRecord: (
		record: models.order.Order,
		key?: keyof models.order.Order
	) => void;
	removeRecord: (recordId: number) => void;
}

export default function OrderTable({
	sort,
	orders = [],
	isLoading = false,
	isAllSelected = false,
	handleSelectAll,
	isSelected,
	select,
	replaceRecord,
	removeRecord,
	skipCreateButton = false,
}: OrderTableProps) {
	const org = useCurrentOrganization();

	const getColumn = (field: OrderFields): Table.HeaderColumn => {
		const translatedField = getTranslatedField(field);

		const ColumnInfo: Table.HeaderColumn = {
			content: translatedField,
		};

		if (sortableFields.includes(field)) {
			ColumnInfo.sortKey = field;
		}

		return ColumnInfo;
	};

	const emptyState = {
		title: t('There are no invoices yet'),
		content: !skipCreateButton && t('Create a new invoice to get started.'),
		image: <AssetImage src="img/missing-entities/Invoice.svg" />,
		button: !skipCreateButton && (
			<Button primary href={routes.Invoice.New(org.id)} icon="add">
				{t('New order')}
			</Button>
		),
	};

	return (
		<Table.Table
			emptyState={emptyState}
			sortBy={sort.column}
			sortOrder={sort.order}
			onSort={sort.setSort}
			stickyHeader
			stickyFooter
			columns={[
				{
					content: (
						<Input.Control
							type="checkbox"
							standalone
							checked={isAllSelected}
							onChange={handleSelectAll}
						/>
					),
					width: '50px',
				},

				getColumn('name'),
				getColumn('email'),
				getColumn('status'),
				getColumn('amount_due'),
				getColumn('number'),
				getColumn('assigned_contacts'),
				getColumn('product_name'),
				getColumn('terms'),
				getColumn('created_at'),
				getColumn('sent_at'),
				getColumn('due_date'),
				getColumn('paid_at'),
				getColumn('type'),
				{
					content: '',
					width: 'max-content',
				},
			]}
			isLoading={isLoading}>
			{orders.map((order) => {
				return (
					<OrderRow
						key={order.id}
						order={order}
						isChecked={isSelected(order.id)}
						handleSelect={() => select(order.id)}
						replaceRecord={replaceRecord}
						removeRecord={removeRecord}
					/>
				);
			})}
		</Table.Table>
	);
}
