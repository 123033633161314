import { ReactNode } from 'react';

export const symbol_sports: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M440-200q-101 0-170.5-69.5T200-440q0-11 1-23t3-21q-5 2-12 3t-12 1q-44 0-72-28t-28-72q0-44 28-72t72-28q31 0 57 18.5t37 46.5q31-28 74.5-46.5T441-680h409q13 0 21.5 8.5T880-650v60q0 13-8.5 21.5T850-560H680v120q0 101-69.5 170.5T440-200ZM180-540q18 0 29-11t11-29q0-18-11-29t-29-11q-18 0-29 11t-11 29q0 18 11 29t29 11Zm260 240q60 0 100-40t40-100q0-60-40-100t-100-40q-60 0-100 40t-40 100q0 60 40 100t100 40Zm0-60q33 0 56.5-23.5T520-440q0-33-23.5-56.5T440-520q-33 0-56.5 23.5T360-440q0 33 23.5 56.5T440-360Zm0-80Z"
		/>
	</svg>
);
