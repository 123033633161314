import { ReactNode } from 'react';

export const symbol_ballot: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M651-565q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T651-625H483q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T483-565h168Zm0 230q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T651-395H483q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T483-335h168ZM348-543q21.45 0 36.725-15.275Q400-573.55 400-595q0-21.45-15.275-36.725Q369.45-647 348-647q-21.45 0-36.725 15.275Q296-616.45 296-595q0 21.45 15.275 36.725Q326.55-543 348-543Zm0 230q21.45 0 36.725-15.275Q400-343.55 400-365q0-21.45-15.275-36.725Q369.45-417 348-417q-21.45 0-36.725 15.275Q296-386.45 296-365q0 21.45 15.275 36.725Q326.55-313 348-313ZM180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-60h600v-600H180v600Zm0-600v600-600Z"
		/>
	</svg>
);
