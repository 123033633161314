import { ReactNode } from 'react';

export const symbol_nest_thermostat_sensor: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-140q24.726 0 48.863-3.5T576-154q-27-30-41.5-67T520-299q0-16.185 2-31.093Q524-345 528-359q-26-15-39-46.818-13-31.819-13-74.244Q476-537 500-574.5t73.337-37.5Q615-612 639-583t30 74q17-5 34.345-8 17.346-3 35.655-3 20.455 0 40.227 3.5Q799-513 819-506q-5-66-33.5-123.5t-74-100Q666-772 606.523-796 547.047-820 480-820q-69.967 0-131.984 26.5Q286-767 239.5-720.5q-46.5 46.5-73 108t-26.5 132q0 70.5 26.562 132.685 26.563 62.185 72.782 108.425 46.218 46.24 108.375 72.815Q409.875-140 480-140Zm59-249q16-35 41.5-62.5T640-496q-3-39-20-62.5T572.586-582Q540-582 523-554t-17 74q0 35 9 57.5t24 33.5ZM740-80q-29 0-57-7.5T630-110q-36 15-74 22.5T480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q84 0 158 32.5t128.5 88Q821-704 851.5-629T880-470q38 32 59 76t21 94q0 92-64 156T740-80Zm-.202-60Q806-140 853-186.798t47-113Q900-366 853.202-413t-113-47Q674-460 627-413.202t-47 113Q580-234 626.798-187t113 47ZM416-572l-2-3H293q-5.6 0-9.8-4-4.2-4-4.2-10t4.643-10.5Q288.286-604 294-604h141q6 0 10.5 4.5T450-589v16q0 3-2 7L334-362q-3 6-9.5 7.5t-12.5-1.167q-6-3.333-7.5-9.833T306-378l110-194Z"
		/>
	</svg>
);
