import { ReactNode } from 'react';

export const symbol_zoom_in_map: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M290-247 164-121q-9 9-21 8.5t-21-9.5q-9-9-9-21.5t9-21.5l125-125h-97q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-350h170q12.75 0 21.375 8.625T350-320v170q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T290-150v-97Zm380 0v97q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T610-150v-170q0-12.75 8.625-21.375T640-350h170q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-290h-97l126 126q9 9 9 21t-9 21q-9 9-21.5 9t-21.5-9L670-247ZM247-670 121-796q-9-9-8.5-21.5T122-839q9-9 21.5-9t21.5 9l125 126v-97q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T350-810v170q0 12.75-8.625 21.375T320-610H150q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-670h97Zm466 0h97q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-610H640q-12.75 0-21.375-8.625T610-640v-170q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T670-810v97l127-127q9-9 21.5-9t21.5 9q9 9 9 21.5t-9 21.5L713-670Z"
		/>
	</svg>
);
