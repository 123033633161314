const icon = {
	name: 'dressingroom',
	objects: [
		{
			type: 'path',
			attributes: {
				d: 'M10.8046 6.51455C11.4581 5.86545 12.5046 5.82553 13.2063 6.42293L13.3764 6.56776C14.1999 7.26888 14.1999 8.5343 13.3764 9.23542L12.8326 9.69838C12.7565 9.76314 12.7128 9.85768 12.7128 9.95719V10.5799L19.9216 13.6268C21.6937 14.3758 21.1549 17 19.229 17H4.77095C2.84505 17 2.30628 14.3758 4.07841 13.6268L11.2872 10.5799V9.95719C11.2872 9.44435 11.5129 8.95711 11.9049 8.62336L12.4487 8.16039C12.6085 8.02436 12.6085 7.77882 12.4487 7.64279L12.2786 7.49796C12.1424 7.38204 11.9394 7.38979 11.8126 7.51573L10.9201 8.40218L9.91209 7.401L10.8046 6.51455ZM12 11.8174L4.63658 14.9296C4.53072 14.9743 4.48273 15.0335 4.45748 15.0861C4.42856 15.1464 4.41721 15.2262 4.43469 15.3114C4.45217 15.3965 4.49409 15.4655 4.54446 15.5098C4.58843 15.5484 4.65591 15.5841 4.77095 15.5841H19.229C19.3441 15.5841 19.4116 15.5484 19.4555 15.5098C19.5059 15.4655 19.5478 15.3965 19.5653 15.3114C19.5828 15.2262 19.5714 15.1464 19.5425 15.0861C19.5173 15.0335 19.4693 14.9743 19.3634 14.9296L12 11.8174Z',
				clipRule: 'evenodd',
				fillRule: 'evenodd',
			},
		},
	],
};

export default icon;
