import { ReactNode } from 'react';

export const symbol_toys: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M276-160q-45 0-79.5-33.5T159-275q-37-23-57.5-58.432T81-411q0-52 30.5-93.5T192-557l-81-85-19 20q-9 10-21.5 10T49-621.5q-9-9.5-9-21.5t9-22l81-84q9-9 21.5-9t21.5 9.5q9 9.5 9 21.5t-9 21l-19 21 72 77 39-117q11-33 38.485-54 27.486-21 61.515-21h232q34.412 0 62.206 21Q687-758 697-725l50 166q57 5 95 46.5T880-411q0 42.136-20.5 77.568Q839-298 802-275q-3 48-37.5 81.5T685-160q-42 0-73.5-29T572-260H389q-8 42-39.5 71T276-160Zm-2-401h175v-179h-84q-14.576 0-25.788 9.5T323-707l-49 146Zm235 0h175l-44.898-145.655Q634-721 622.788-730.5 611.576-740 597-740h-88v179ZM389-320h183q11-36 43-58t70-22q32 0 59 17t44 44q17-13 24.5-32.427T820-411q0-38-26-64t-64-26H231q-38 0-64 26t-26 64q0 20.146 8 39.573T173-339q16-29 43.585-45 27.586-16 59.415-16 38 0 70 22t43 58ZM276-220q25.5 0 42.75-17.25T336-280q0-25.5-17.25-42.75T276-340q-25.5 0-42.75 17.25T216-280q0 25.5 17.25 42.75T276-220Zm409 0q25.5 0 42.75-17.25T745-280q0-25.5-17.25-42.75T685-340q-25.5 0-42.75 17.25T625-280q0 25.5 17.25 42.75T685-220ZM481-411Z"
		/>
	</svg>
);
