export const tags = [
	'1v1',
	'attacking',
	'defending',
	'finishing',
	'goalkeeping',
	'passing',
	'physical',
	'possession',
	'rondos',
	'set_pieces',
	'small_sided_games',
	'tactical',
	'technical',
	'warm-up',
];

export const time = {
	10: {
		min: null,
		max: 10,
	},
	20: {
		min: null,
		max: 20,
	},
	30: {
		min: null,
		max: 30,
	},
	45: {
		min: 45,
		max: null,
	},
};

export const participants = {
	1: {
		min: 1,
		max: 1,
	},
	2: {
		min: 2,
		max: 2,
	},
	3: {
		min: 3,
		max: 3,
	},
	4: {
		min: 4,
		max: 4,
	},
	5: {
		min: 5,
		max: 5,
	},
	6: {
		min: 6,
		max: 6,
	},
	7: {
		min: 7,
		max: 7,
	},
	8: {
		min: 8,
		max: 8,
	},
	9: {
		min: 9,
		max: 9,
	},
	10: {
		min: 10,
		max: 10,
	},
	11: {
		min: 11,
		max: null,
	},
};

export const age = {
	7: {
		min: null,
		max: 7,
	},
	12: {
		min: 8,
		max: 12,
	},
	15: {
		min: 13,
		max: 15,
	},
	16: {
		min: 16,
		max: null,
	},
};
