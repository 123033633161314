import { Dispatch } from 'redux';
import { t } from '@transifex/native';

import Exercise from 'pkg/models/exercise';

import * as exerciseService from 'pkg/actions/services/exercises';
import { triggerError } from 'pkg/actions/app';
import * as actionTypes from 'pkg/actions/action-types';
import { normalizedDispatch } from 'pkg/actions/utils';

import { flashes } from 'pkg/actions';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as sdk from 'pkg/core/sdk';
import store from 'pkg/store/createStore';

const removeExercise = (exerciseId: number) => ({
	type: actionTypes.Exercises.DELETE_ITEM,
	exerciseId,
});

export async function getSingleExercise(
	id: number,
	update?: boolean
): Promise<void> {
	if (!update && store.getState().exercises.hasIn(['entities', id])) {
		return;
	}

	const request = await exerciseService.getSingleExercise(id);

	if (!request.ok) {
		triggerError(request)(store.dispatch);
		return;
	}

	const response = await request.json();
	normalizedDispatch(response, Exercise.normalizr())(store.dispatch);
}

export const deleteExercise =
	(id: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		const request = await exerciseService.deleteExercise(id);

		if (!request.ok) {
			triggerError(request)(dispatch);
			return;
		}

		dispatch(removeExercise(id));
	};

export const duplicateExercise = async (id: number): Promise<Exercise> => {
	const response = await sdk.post(endpoints.Exercises.Duplicate(id));

	if (!response.ok) {
		flashes.show({
			title: t('Something went wrong!'),
			message: t('Could not duplicate exercise.'),
		});
		return;
	}

	return await response.json();
};
