import { ReactNode } from 'react';

export const symbol_chip_extraction: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-481q0 115.636 77.5 201.818Q335-193 451-182q12 2 20.5 10.7T480-151q0 14-12.032 22T441-123q-138-15-229.5-117T120-481q0-139 91.5-241T441-839q15.438-2 27.219 6.17T480-811.076q0 12.076-8.5 20.576T451-780q-116 11-193.5 97.182T180-481Zm544 31H390q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T390-510h334l-92-93q-9-8.8-9-20.9 0-12.1 9-21.1 9-9 21-9t21 9l144 144q9 9 9 21t-9 21L674-315q-9 9-21 8.5t-21-9.5q-9-9-9-21t9-21l92-92Z"
		/>
	</svg>
);
