import { Map, Record } from 'immutable';

import VideoSequenceComment from 'pkg/models/video_sequence_comment';

import * as actionTypes from 'pkg/actions/action-types';

interface ISequenceCommentsReducer {
	entities: Map<number, VideoSequenceComment>;
}

class SequenceCommentReducer
	extends Record<ISequenceCommentsReducer>(
		{
			entities: Map<number, VideoSequenceComment>(),
		},
		'videoSequenceComments'
	)
	implements ISequenceCommentsReducer
{
	entities: Map<number, VideoSequenceComment>;
}

const initialState = new SequenceCommentReducer({
	entities: Map<number, VideoSequenceComment>(),
});

interface NormalizrPayload {
	result: number[];
	entities: {
		videoSequenceComments: { [key: string]: VideoSequenceComment };
	};
}

interface SequenceCommentAction {
	type: string;
	payload?: NormalizrPayload;
	commentId?: number;
}

const setItems = (
	state = initialState,
	action: SequenceCommentAction
): SequenceCommentReducer => {
	const { videoSequenceComments } = action.payload.entities;

	Object.values(videoSequenceComments).forEach(
		(comment: VideoSequenceComment) => {
			state = state.updateIn(
				['entities', comment.id],
				(existing: VideoSequenceComment) => {
					if (!existing) {
						existing = new VideoSequenceComment(comment);
					}

					const links = comment.links || existing.get('links');

					existing = existing.merge(comment);

					// Only override if there are any links
					if (Object.keys(links).length > 0) {
						existing.set('links', links);
					}

					return existing;
				}
			);
		}
	);

	return state;
};

const deleteItem = (
	state = initialState,
	action: SequenceCommentAction
): SequenceCommentReducer => {
	if (action.commentId) {
		state = state.deleteIn(['entities', action.commentId]);
	}

	return state;
};

const videoCollectionsReducer = (
	state = initialState,
	action: SequenceCommentAction
): SequenceCommentReducer => {
	switch (action.type) {
		case actionTypes.VideoSequenceComments.SET_ITEMS:
		case actionTypes.VideoSequenceComments.UPDATE_ITEM:
			return setItems(state, action);
		case actionTypes.VideoSequenceComments.DELETE_ITEM:
			return deleteItem(state, action);
		default:
			return state;
	}
};

export default videoCollectionsReducer;
