import { ReactNode } from 'react';

export const symbol_assured_workload: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M212-271v-279q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T272-550v279q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T212-271Zm242 0v-279q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T514-550v279q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T454-271Zm400-369H103q-9.583 0-16.292-6.708Q80-653.417 80-663v-17q0-6 3.5-11.5T92-700l358-204q14.167-8 30-8t30 8l357 203q6 4 9.5 10t3.5 13.5v10.227q0 11.591-7.475 19.432Q865.05-640 854-640Zm-640-60h532L480-852 214-700ZM110-121q-12.75 0-21.375-8.675Q80-138.351 80-151.175 80-164 88.625-172.5T110-181h440q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T550-121H110Zm607.825-306Q705-427 696.5-435.625T688-457v-93q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T748-550v93q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM640-218v-83q0-8.161 4.5-15.581Q649-324 657-328l130-65q5.714-3 12.857-3Q807-396 813-393l130 65q8 4 12.5 11.419 4.5 7.42 4.5 15.581v83q0 78-39.5 131T812-5q-4 2-12 2l-12-2q-69-29-108.5-82T640-218Zm139 42-45-45q-6-6-14-6t-14 6q-6 6-6 14t6 14l52 52q9 9 21 9t21-9l107-107q6-6 6-14t-6-14q-6-6-14-6t-14 6L779-176ZM214-700h532-532Z"
		/>
	</svg>
);
