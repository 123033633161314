import { ReactNode } from 'react';

export const symbol_key_vertical: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M432-680q0-28 20-48t48-20q28 0 48 20t20 48q0 28-20 48t-48 20q-28 0-48-20t-20-48Zm-172 0q0-100 70-170t170-70q100 0 170 70t70 170q0 72-34 126t-103 85v344q0 6-2 11t-7 10l-82 82q-5 5-10.5 7T490-13q-6 0-11-2.5T469-23L354-148q-4-4-6-9t-2-10q0-5 1-9.5t4-9.5l50-69-51-70q-3-4-4-8.5t-1-9.5q0-5 1.5-9.5t4.5-8.5l46-57v-51q-60-25-98.5-78.5T260-680Zm60 1q0 58 38.5 107t98.5 63v114l-45 54q0-1 0 0v.5-.5l63 88-62 82v-.5.5l79 85-.5.5.5-.5v.5-.5l51-51v-372q56-12 96.5-60T680-679q0-75-52.5-127.5T500-859q-75 0-127.5 52.5T320-679Z"
		/>
	</svg>
);
