import { ReactNode } from 'react';

export const symbol_voice_chat: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M270-530q8 0 14-6t6-14v-20q0-8-6-14t-14-6q-8 0-14 6t-6 14v20q0 8 6 14t14 6Zm100 80q8 0 14-6t6-14v-180q0-8-6-14t-14-6q-8 0-14 6t-6 14v180q0 8 6 14t14 6Zm110 80q8 0 14-6t6-14v-340q0-8-6-14t-14-6q-8 0-14 6t-6 14v340q0 8 6 14t14 6Zm110-80q8 0 14-6t6-14v-180q0-8-6-14t-14-6q-8 0-14 6t-6 14v180q0 8 6 14t14 6Zm100-80q8 0 14-6t6-14v-20q0-8-6-14t-14-6q-8 0-14 6t-6 14v20q0 8 6 14t14 6ZM240-240 131-131q-14 14-32.5 6.5T80-152v-668q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H240Zm-26-60h606v-520H140v600l74-80Zm-74 0v-520 520Z"
		/>
	</svg>
);
