import { t } from '@transifex/native';
import { useState } from 'react';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import Link from 'pkg/router/Link';
import { UserFieldType } from 'pkg/api/models/user_fields';

import LoginMethods from 'containers/user/LoginMethods';

import Icon, { IconName } from 'components/icon';
import { FormattedContent } from 'components/formatted-content';

import Column from 'components/layout/column';
import { GalleryModal } from 'components/attachment/gallery';

import * as css from './styles.css';

function FieldBlock({ field }: { field: models.user.Field }): JSX.Element {
	const [galleryOpenOnId, setGalleryOpenOnId] = useState(0);

	const handleCloseModal = () => {
		setGalleryOpenOnId(0);
	};

	switch (field.type) {
		case UserFieldType.Attachment:
			return (
				<div key={field.key}>
					{galleryOpenOnId > 0 && (
						<GalleryModal
							activeId={galleryOpenOnId}
							attachments={field.attachments}
							onClose={handleCloseModal}
						/>
					)}
					<p>{field.label}</p>
					<Column>
						{field.attachments?.map((a) => {
							let icon: IconName = 'file-generic';
							switch (a.type) {
								case models.attachment.AttachmentType.Image:
									icon = 'file-image';
							}

							return (
								<Link
									key={a.id}
									onClick={() => {
										setGalleryOpenOnId(a.id);
									}}>
									<Icon name={icon} size={1.4} /> {a.title}
								</Link>
							);
						})}
					</Column>
				</div>
			);
		case UserFieldType.Email:
			return (
				<div key={field.key}>
					<p>{field.label}</p>
					<p>
						{field.values.map((val, i) => (
							<Link key={i} href={`mailto:${val}`}>
								{val}
							</Link>
						))}
					</p>
				</div>
			);
		case UserFieldType.Phone:
			return (
				<div key={field.key}>
					<p>{field.label}</p>
					<p>
						{field.values.map((val, i) => (
							<Link key={i} href={`tel:${val}`}>
								{val}
							</Link>
						))}
					</p>
				</div>
			);
		case UserFieldType.LongText:
			return (
				<div key={field.key}>
					<p>{field.label}</p>
					<p>
						{field.values.map((val, i) => (
							<FormattedContent key={i} raw={val} />
						))}
					</p>
				</div>
			);
		default:
			return (
				<div key={field.key}>
					<p>{field.label}</p>
					<p>{field.values.join(', ')}</p>
				</div>
			);
	}
}

export default function ContactDetails({
	contact,
}: {
	contact: models.user.User;
}): JSX.Element {
	const countryString = models.user.getCountryString(contact) || '-';

	return (
		<Column spacing={styles.spacing._3} className={css.list}>
			<div>
				<p>{t('First name')}</p>
				<p>{contact.firstName || '-'}</p>
			</div>
			<div>
				<p>{t('Last name')}</p>
				<p>{contact.lastName || '-'}</p>
			</div>
			<div>
				<p>{t('Gender')}</p>
				<p>{models.user.translatedSexString(contact.sex)}</p>
			</div>
			<div>
				<p>{t('Birth date')}</p>
				<p>{contact.birthDate || '-'}</p>
			</div>
			{contact.lokSwedishPersonalId && (
				<div>
					<p>{t('Personal ID number')}</p>
					<p>{contact.lokSwedishPersonalId}</p>
				</div>
			)}
			<div>
				<p>{t('Email')}</p>
				{contact.email ? (
					<a href={`mailto:${contact.email}`}>{contact.email || '-'}</a>
				) : (
					<p>-</p>
				)}
			</div>
			<div>
				<p>{t('Login methods')}</p>
				<LoginMethods userId={contact.id} accountId={contact?.accountId} />
			</div>
			<div>
				<p>{t('Phone number')}</p>
				{contact.phoneNumber ? (
					<a href={`tel:${contact.phoneNumber}`}>
						{contact.phoneNumber || '-'}
					</a>
				) : (
					<p>-</p>
				)}
			</div>
			<div>
				<p>{t('Address')}</p>
				<p>{contact.address || '-'}</p>
			</div>
			<div>
				<p>{t('City')}</p>
				<p>{contact.city || '-'}</p>
			</div>
			<div>
				<p>{t('Country')}</p>
				<p>{countryString}</p>
			</div>
			<div>
				<p>{t('Postal code')}</p>
				<p>{contact.postalCode || '-'}</p>
			</div>

			{contact?.fields
				?.filter(
					(f) =>
						f.visibility.includes(models.userFields.Visibility.ContactCard) &&
						f.type !== models.userFields.UserFieldType.Reserved
				)
				?.map((field: models.user.Field) => (
					<FieldBlock key={field.key} field={field} />
				))}
		</Column>
	);
}
