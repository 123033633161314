import { ReactNode } from 'react';

export const symbol_waves: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M275-226q-53 0-83.5 31T110-151q-12 3-21-4.5T80-176q0-13 8.5-23t21.5-15q39-16 70.5-44t94.5-28q37 0 63 12.5t47.5 27.5q21.5 15 43.5 27.5t51 12.5q29 0 51-12.5t43.5-27.5q21.5-15 47.5-27.5t63-12.5q63 0 93.5 28t70.5 44q13 5 22 14.5t9 23.5q0 13-9.5 20.5T849-151q-51-13-81-44t-83-31q-30 0-52.5 12.5t-44 27.5q-21.5 15-47 27.5T480-146q-36 0-61.5-12.5t-47-27.5q-21.5-15-44-27.5T275-226Zm0-176q-53 0-83.5 31T110-327q-12 3-21-4.5T80-352q0-13 8.5-23t21.5-15q39-16 70.5-44t94.5-28q37 0 63 12.5t47.5 27.5q21.5 15 43.5 27.5t51 12.5q29 0 51-12.5t43.5-27.5q21.5-15 47.5-27.5t63-12.5q63 0 93.5 28t70.5 44q13 5 22 14.5t9 23.5q0 12-10 19.5t-21 4.5q-51-15-81-44.5T685-402q-30 0-52 12.5T589-362q-22 15-47.5 27.5T480-322q-36 0-61.5-12.5t-47-27.5q-21.5-15-44-27.5T275-402Zm0-176q-53 0-83.5 31T110-503q-12 3-21-4.5T80-528q0-13 9-22t21-14q39-14 70.5-44t94.5-30q37 0 63 12.5t47.5 27.5q21.5 15 43.5 27.5t51 12.5q29 0 51-12.5t43.5-27.5q21.5-15 47.5-27.5t63-12.5q63 0 93.5 30t70.5 44q13 5 22 14t9 22q0 13-9.5 20.5T849-503q-51-13-81-44t-83-31q-30 0-52.5 12.5t-44 27.5q-21.5 15-47 27.5T480-498q-36 0-61.5-12.5t-47-27.5q-21.5-15-44-27.5T275-578Zm0-176q-53 0-83.5 31T110-679q-12 3-21-4.5T80-704q0-13 8.5-23t21.5-15q39-16 70.5-44t94.5-28q37 0 63 12.5t47.5 27.5q21.5 15 43.5 27.5t51 12.5q29 0 51-12.5t43.5-27.5q21.5-15 47.5-27.5t63-12.5q63 0 93.5 28t70.5 44q13 5 22 14.5t9 23.5q0 13-9.5 20.5T849-679q-51-13-81-44t-83-31q-30 0-52.5 12.5t-44 27.5q-21.5 15-47 27.5T480-674q-36 0-61.5-12.5t-47-27.5q-21.5-15-44-27.5T275-754Z"
		/>
	</svg>
);
