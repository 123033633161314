import { ReactNode } from 'react';

export const symbol_female: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M450-200h-50q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T400-260h50v-102q-82-11-136-73.706T260-581q0-91.417 64.5-155.209Q389-800 480-800t155.5 63.791Q700-672.417 700-581q0 82.588-54 145.294Q592-373 510-362v102h50q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T560-200h-50v50q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T450-150v-50Zm29.798-220Q546-420 593-466.798t47-113Q640-646 593.202-693t-113-47Q414-740 367-693.202t-47 113Q320-514 366.798-467t113 47Z"
		/>
	</svg>
);
