import { ReactNode } from 'react';

export const symbol_forward_media: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-80q-75 0-140.5-28.5t-114-77q-48.5-48.5-77-114T120-440q0-75 28.5-140.5t77-114q48.5-48.5 114-77T480-800h23l-58-58q-8-8-7.5-20.5T446-899q8-8 20.5-8t20.5 8l105 106q9 9 9 21t-9 21L487-646q-9 9-21.5 9t-20.5-9q-9-9-8.5-21t9.5-21l52-52h-23q-124 0-209.5 87.5T180-440q0 125 87.5 212.5T480-140q116 0 202-77.5T779-410q2-13 10.5-21.5T810-440q13 0 21.5 9t7.5 21q-11 141-114.5 235.5T480-80Z"
		/>
	</svg>
);
