import { t } from '@transifex/native';
import { Fragment, useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import * as palette from 'pkg/config/palette';
import { small } from 'pkg/config/breakpoints';

import Rating from 'pkg/models/rating';

import {
	getCurrentRating,
	getPositionScores,
	getSingleRating,
} from 'pkg/selectors/ratings.selector';

import { fetchSingleRating, changePosition } from 'pkg/actions/ratings';

import rgba from 'pkg/rgba';
import * as selectors from 'pkg/selectors';
import { useCurrentGroup } from 'pkg/identity';

import CurrentPosition from 'containers/user/rating/CurrentPosition';

import CardAnatomy, * as Card from 'components/Card';
import Icon from 'components/icon';
import UserAvatar from 'components/avatar';

import { Spinner } from 'components/loaders/spinner';
import TrainingPriorityTable from 'components/user/rating/TrainingPriority';
import TextBlock from 'components/modals/TextBlock';

import Button from 'design/button';

const FauxText = styled.div`
	width: 100%;
	background: ${palette.gray[200]};
	height: 1rem;
	border-radius: var(--radius-2);
`;

const ContentWrapper = styled.div`
	width: 100%;
`;

const Top = styled.div`
	width: 100%;
	background-color: ${palette.white};
	padding: var(--spacing-8) 0;
	position: relative;

	&::before {
		content: '';
		position: absolute;
		top: 100%;
		left: 50%;
		border: 10px solid transparent;
		border-top-color: ${palette.white};
		transform: translateX(-50%);
	}
`;

const WidthContainer = styled.div`
	width: 450px;
	max-width: 90%;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`;

const Legend = styled.div`
	display: flex;
	flex-flow: ${(props) => (props.spread ? 'row' : 'column wrap')};
	justify-content: ${(props) =>
		props.spread ? 'space-between' : 'flex-start'};
	width: ${(props) => (props.spread ? '100%' : 'auto')};
	margin-bottom: var(--spacing-7);
`;

const ItemWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const BestPositionIcon = styled(Icon)`
	z-index: 10;
	height: 20px;
	width: 20px;
	margin-right: var(--spacing-3);
	color: ${palette.blue[500]};
	background: ${palette.white};
	border-radius: 50%;
	padding: var(--spacing-1);
	border: 1px solid ${rgba(palette.black, 0.1)};
`;

const LegendLabel = styled.p`
	color: ${palette.gray[600]};
	font-size: var(--font-size-sm);
	font-weight: var(--font-weight-normal);
`;

const SmallAvatarWrapper = styled.div`
	height: 20px;
	width: 20px;
	font-size: var(--font-size-2xs);
	margin-right: var(--spacing-3);
`;

const TableWrapper = styled.div`
	width: 100%;
	padding-bottom: var(--spacing-8);

	${Card.Heading} {
		color: ${palette.gray[800]};
		margin: var(--spacing-4) 0;
		font-weight: var(--font-weight-semibold);
		font-size: var(--font-size-sm);
	}

	@media ${small} {
		padding-bottom: var(--spacing-4);
	}
`;

const Body = styled(Card.Body)`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0;
	background-color: ${palette.blue[100]};

	@media ${small} {
		padding: 0;
	}
`;

const Footer = styled(Card.Footer)`
	justify-content: center;
	background-color: ${palette.white};
`;

const AvatarWrapper = styled.div`
	position: relative;
	z-index: 1;
	width: 105px;
	height: 105px;
	margin-bottom: var(--spacing-5);
`;

const ActivePos = keyframes`
	0% {
	box-shadow: inset 0 0 0 2px white, 0 0 0 0 ${
		palette.white
	}, 0 3px 5px 2px ${rgba(palette.black, 0.4)};
	}

	50% {
		box-shadow: inset 0 0 0 2px white, 0 0 0 15px ${rgba(
			palette.white,
			0
		)}, 0 3px 5px 2px ${rgba(palette.black, 0.4)};
	}

	100% {
		box-shadow: inset 0 0 0 2px white, 0 0 0 15px ${rgba(
			palette.white,
			0
		)}, 0 3px 5px 2px ${rgba(palette.black, 0.4)};
	}
`;

const Position = styled.div`
	position: absolute;
	right: 0;
	top: 0;
	height: 40px;
	width: 40px;
	background-color: ${palette.blue[500]};
	color: ${palette.white};
	font-weight: var(--font-weight-semibold);
	font-size: var(--font-size-sm);
	border-radius: 100%;
	overflow: hidden;
	z-index: 2;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow:
		inset 0 0 0 2px white,
		0 2px 5px ${rgba(palette.black, 0.2)};
	animation: ${ActivePos} 1200ms ease-out infinite;
	transform: translate3d(25%, -25%, 0);
`;

const TextBox = styled(TextBlock)`
	color: ${palette.black};
	margin-bottom: 0;

	div {
		&:nth-child(1) {
			font-size: var(--font-size-2xl);
		}
	}
`;

const MapWrapper = styled.div`
	width: 100%;
	margin-bottom: var(--spacing-7);
	padding-top: var(--spacing-8);
`;

const PositionWrapper = styled.div`
	width: 100%;
	margin-bottom: var(--spacing-8);
`;

const PositionInfo = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	font-size: var(--font-size-2xl);
	color: ${palette.gray[800]};
	margin-bottom: var(--spacing-4);
	font-weight: var(--font-weight-semibold);
	letter-spacing: -0.5px;

	@media ${small} {
		font-size: var(--font-size-xl);
	}
`;

const Description = styled.div``;

const AssessmentPreview = (props) => {
	const [bestPosId, setBestPosId] = useState(null);
	const [loading, setLoading] = useState(true);

	const dispatch = useDispatch();

	const groupId = useCurrentGroup().id;
	const currentRating = useSelector((state) => getCurrentRating(state, props));
	const user = useSelector((state) =>
		selectors.users.find(state, currentRating.userId)
	);
	const maturityAdjusted = useSelector(
		(state) => state.ratings.maturityAdjustment
	);
	const currentPositionId = useSelector(
		(state) => state.ratings.currentPosition
	);
	const currentPosition = useSelector((state) =>
		state.positions.entities.find(({ id }) => id === currentPositionId)
	);

	const bestPosition = useSelector((state) =>
		state.positions.entities.find(({ id }) => id === bestPosId)
	);

	const rating = useSelector((state) =>
		getSingleRating(state, { id: props.ratingId })
	);

	const positionScores = useSelector((state) =>
		getPositionScores(state, { ratingId: props.ratingId })
	);

	useEffect(() => {
		if (bestPosition !== undefined) {
			setLoading(false);
		}
	}, [bestPosition]);

	useEffect(() => {
		(async () => {
			await dispatch(fetchSingleRating(props.ratingId));
		})();
	}, [dispatch, groupId, props.ratingId]);

	useEffect(() => {
		const getBestPos = () => {
			let bestPosition = {};

			const positionValue = maturityAdjusted
				? 'maturityAdjustedValue'
				: 'value';

			positionScores.forEach((item) => {
				if (!bestPosition[positionValue]) {
					bestPosition = item;
				}

				if (bestPosition[positionValue] < item[positionValue]) {
					bestPosition = item;
				}
			});

			return {
				positionId: bestPosition.positionId,
			};
		};

		(async () => {
			const pos = getBestPos();

			if (!pos.positionId) {
				return;
			}

			setBestPosId(pos.positionId);
			await dispatch(changePosition(pos.positionId, props.ratingId));
		})();
	}, [dispatch, maturityAdjusted, positionScores, props.ratingId]);

	const GetScore = () => {
		let score = <FauxText />;

		if (
			currentRating &&
			positionScores?.find((s) => s.positionId === currentPositionId)
		) {
			let p = positionScores.find((s) => s.positionId === currentPositionId);

			score = (maturityAdjusted ? p.maturityAdjustedValue : p.value).toFixed(2);
		}

		return <div>{score}</div>;
	};

	return (
		<CardAnatomy>
			{!loading ? (
				<Fragment>
					<Body>
						<ContentWrapper>
							<Top>
								<WidthContainer>
									<AvatarWrapper>
										<UserAvatar user={user} />
										<Position>{bestPosition?.name}</Position>
									</AvatarWrapper>
									<TextBox wide>
										<h3>
											{rating.author.id === rating.user.id
												? t(`Your matched position is {position}!`, {
														position: bestPosition?.longName,
													})
												: t(`{name} matched position is {position}!`, {
														name: rating.user.firstName,
														position: bestPosition?.longName,
													})}
										</h3>
										<p>
											{rating.author.id === rating.user.id
												? t(`Your best position is {position}`, {
														position: bestPosition?.longName,
													})
												: t(`The best position for {name} is {position}`, {
														name: rating.user.firstName,
														position: bestPosition?.longName,
													})}
										</p>
									</TextBox>
								</WidthContainer>
							</Top>
							<WidthContainer>
								<MapWrapper>
									<CurrentPosition rating={rating} comparison={new Rating()} />
								</MapWrapper>

								<Legend spread={user.isAnAdult}>
									<ItemWrapper>
										<BestPositionIcon name="star" />
										<LegendLabel>{t(`Best position`)}</LegendLabel>
									</ItemWrapper>
									<ItemWrapper>
										<SmallAvatarWrapper>
											<UserAvatar user={user} />
										</SmallAvatarWrapper>
										<LegendLabel>{t(`Chosen position`)}</LegendLabel>
									</ItemWrapper>
								</Legend>
								<PositionWrapper>
									<PositionInfo>
										<div>
											{t(`Training priority as {position}`, {
												position: currentPosition?.name,
											})}
										</div>

										<GetScore />
									</PositionInfo>
									<Description>{currentPosition?.description}</Description>
								</PositionWrapper>
								<TableWrapper>
									<Card.Heading>{t(`Training priority`)}</Card.Heading>

									<TrainingPriorityTable
										assessmentPreview
										maturityAdjusted={maturityAdjusted}
										ratingId={props.ratingId}
									/>
								</TableWrapper>
							</WidthContainer>
						</ContentWrapper>
					</Body>
					<Footer>
						<Button
							onClick={props.complete}
							label={
								props.isOnboarding
									? t(`Ok, let's start using 360Player!`)
									: t(`Continue`)
							}
							large
							testid="step_modal.next"
						/>
					</Footer>
				</Fragment>
			) : (
				<Spinner />
			)}
		</CardAnatomy>
	);
};

export default AssessmentPreview;
