export function AddAttachment(ID: number, attachmentID: number): string {
	return `/v1/exercises/${ID}/attachments/${attachmentID}`;
}

export function AddAttachment1(
	groupID: number,
	ID: number,
	attachmentID: number
): string {
	return `/v1/organizations/${groupID}/exercises/${ID}/attachments/${attachmentID}`;
}

export function AddTag(ID: number, tagID: number): string {
	return `/v1/exercises/${ID}/tags/${tagID}`;
}

export function AddTag1(groupID: number, ID: number, tagID: number): string {
	return `/v1/organizations/${groupID}/exercises/${ID}/tags/${tagID}`;
}

export function Create(): string {
	return `/v1/exercises`;
}

export function Create1(groupID: number): string {
	return `/v1/organizations/${groupID}/exercises`;
}

export function CreateDrawing(): string {
	return `/v1/exercises/drawings`;
}

export function CreateDrawing1(groupID: number): string {
	return `/v1/organizations/${groupID}/exercises/drawings`;
}

export function Delete(ID: number): string {
	return `/v1/exercises/${ID}`;
}

export function Delete1(groupID: number, ID: number): string {
	return `/v1/organizations/${groupID}/exercises/${ID}`;
}

export function DeleteDrawing(ID: number): string {
	return `/v1/exercises/drawings/${ID}`;
}

export function DeleteDrawing1(groupID: number, ID: number): string {
	return `/v1/organizations/${groupID}/exercises/drawings/${ID}`;
}

export function Duplicate(ID: number): string {
	return `/v1/exercises/${ID}/duplicate`;
}

export function Duplicate1(groupID: number, ID: number): string {
	return `/v1/organizations/${groupID}/exercises/${ID}/duplicate`;
}

export function Index(): string {
	return `/v1/exercises`;
}

export function Index1(groupID: number): string {
	return `/v1/organizations/${groupID}/exercises`;
}

export function RemoveAttachment(ID: number, attachmentID: number): string {
	return `/v1/exercises/${ID}/attachments/${attachmentID}`;
}

export function RemoveAttachment1(
	groupID: number,
	ID: number,
	attachmentID: number
): string {
	return `/v1/organizations/${groupID}/exercises/${ID}/attachments/${attachmentID}`;
}

export function RemoveTag(ID: number, tagID: number): string {
	return `/v1/exercises/${ID}/tags/${tagID}`;
}

export function RemoveTag1(groupID: number, ID: number, tagID: number): string {
	return `/v1/organizations/${groupID}/exercises/${ID}/tags/${tagID}`;
}

export function Show(ID: number): string {
	return `/v1/exercises/${ID}`;
}

export function Show1(groupID: number, ID: number): string {
	return `/v1/organizations/${groupID}/exercises/${ID}`;
}

export function Update(ID: number): string {
	return `/v1/exercises/${ID}`;
}

export function Update1(groupID: number, ID: number): string {
	return `/v1/organizations/${groupID}/exercises/${ID}`;
}

export function UpdateDrawing(ID: number): string {
	return `/v1/exercises/drawings/${ID}`;
}

export function UpdateDrawing1(groupID: number, ID: number): string {
	return `/v1/organizations/${groupID}/exercises/drawings/${ID}`;
}
