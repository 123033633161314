import { ReactNode } from 'react';

export const symbol_account_tree: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M604-180v-65h-94q-24.75 0-42.375-17.625T450-305v-350h-93v70q0 24.75-17.625 42.375T297-525H140q-24.75 0-42.375-17.625T80-585v-195q0-24.75 17.625-42.375T140-840h157q24.75 0 42.375 17.625T357-780v65h247v-65q0-24.75 17.625-42.375T664-840h156q24.75 0 42.375 17.625T880-780v195q0 24.75-17.625 42.375T820-525H664q-24.75 0-42.375-17.625T604-585v-70h-94v350h94v-70q0-24.75 17.625-42.375T664-435h156q24.75 0 42.375 17.625T880-375v195q0 24.75-17.625 42.375T820-120H664q-24.75 0-42.375-17.625T604-180ZM140-780v195-195Zm524 405v195-195Zm0-405v195-195Zm0 195h156v-195H664v195Zm0 405h156v-195H664v195ZM140-585h157v-195H140v195Z"
		/>
	</svg>
);
