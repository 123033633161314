import { ReactNode } from 'react';

export const symbol_headset_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M810.182-240Q799-240 789.5-247.683 780-255.366 780-270v-125H654q-15 0-22.5-9.318t-7.5-20.5q0-11.182 7.5-20.682Q639-455 654-455h126v-68q0-124-87-213.5T482-826q-51 0-100.5 18T292-756l-44-44q45-38 105-62t129-24q75 0 140.5 28.5t113.552 77.822q48.051 49.323 76 115.254Q840-598.493 840-523v253q0 15-9.318 22.5t-20.5 7.5ZM512-45q-12.75 0-21.375-8.675Q482-62.351 482-75.175 482-88 490.625-96.5T512-105h260l-55-55h-25q-24.75 0-42.375-17.625T632-220v-25L216-661q-17 28-26.5 64.89Q180-559.219 180-523v68h88q24.75 0 42.375 17.625T328-395v175q0 24.75-17.625 42.375T268-160h-88q-24 0-42-18t-18-42v-303q0-53 13.5-100.5T170-707L61-816q-9-9-9-21t9-21.391Q70-867 82.5-867t21.5 9L880-83q11 11 5.214 24.5Q879.429-45 864-45H512ZM180-395v175h88v-175h-88Zm0 0h88-88Zm474 0h126-126Z"
		/>
	</svg>
);
