import { ReactNode } from 'react';

export const symbol_swipe_up_alt: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-160q-83 0-141.5-58.5T280-360q0-82 50.5-133.5T450-559v-207l-68 68q-9 9-21.1 9-12.1 0-20.9-9-9-9-9-21t9-21l119-119q5-5 10.133-7 5.134-2 11-2Q486-868 491-866q5 2 10 7l119 119q9 9 9 21.158 0 12.158-8.609 21Q611-689 598.9-689t-20.9-9l-68-68v207q69 14 119.5 65.5T680-360q0 83-58.5 141.5T480-160Zm-.235-60Q538-220 579-260.765q41-40.764 41-99Q620-418 579.235-459q-40.764-41-99-41Q422-500 381-459.235q-41 40.764-41 99Q340-302 380.765-261q40.764 41 99 41ZM480-360Z"
		/>
	</svg>
);
