const trophy = {
	name: 'trophy',
	objects: [
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M9.18379 6.26445V10.9232C9.18379 12.512 10.4717 13.7999 12.0605 13.7999C13.6492 13.7999 14.9372 12.512 14.9372 10.9232V6.26445H9.18379ZM8.41724 4.96445C8.12262 4.96445 7.88379 5.20328 7.88379 5.49789V6.58401C6.17679 6.58401 4.79297 7.96781 4.79297 9.67481C4.79297 11.3818 6.17676 12.7656 7.88376 12.7656C8.02411 12.7656 8.16227 12.7562 8.29765 12.7381C8.86631 13.915 9.97078 14.7844 11.2943 15.0298V17.7355H8.32446V19.0355H15.6755V17.7355H12.5943V15.0661C14.0153 14.8848 15.2132 13.9895 15.8169 12.7513C15.9154 12.7608 16.0153 12.7656 16.1162 12.7656C17.8232 12.7656 19.207 11.3818 19.207 9.67481C19.207 8.00831 17.8881 6.64986 16.2372 6.58634V5.49789C16.2372 5.20328 15.9984 4.96445 15.7037 4.96445H8.41724ZM16.2372 7.88803V10.9232C16.2372 11.1063 16.2254 11.2867 16.2026 11.4636C17.1515 11.4185 17.907 10.6349 17.907 9.67481C17.907 8.72643 17.1698 7.95023 16.2372 7.88803ZM7.88379 10.9232V7.88401C6.89476 7.88401 6.09297 8.68578 6.09297 9.67481C6.09297 10.6638 6.89473 11.4656 7.88376 11.4656C7.89542 11.4656 7.90704 11.4655 7.91864 11.4653C7.89565 11.2878 7.88379 11.1069 7.88379 10.9232Z',
			},
		},
	],
};

export default trophy;
