enum spacing {
	'_1' = '2px',
	'_2' = '4px',
	'_3' = '8px',
	'_4' = '12px',
	'_5' = '16px',
	'_6' = '20px',
	'_7' = '24px',
	'_8' = '32px',
	'_9' = '64px',
	'_10' = '128px',
}

export default spacing;
