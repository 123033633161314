import { ReactNode } from 'react';

export const symbol_roofing: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M427-160q-24.75 0-42.375-17.625T367-220v-106q0-24.75 17.625-42.375T427-386h106q24.75 0 42.375 17.625T593-326v106q0 24.75-17.625 42.375T533-160H427Zm0-60h106v-106H427v106ZM100-474q-10.348 8-22.174 6Q66-470 58-480t-6.056-22.19Q53.89-514.381 64-522l380-291q8.082-6 17.265-9 9.184-3 18.735-3t18.735 3q9.183 3 17.265 9l142 108v-45q0-20.833 14.618-35.417Q687.235-800 708.118-800 729-800 743.5-785.417 758-770.833 758-750v122l139 106q9.941 7.619 11.471 19.81Q910-490 902-480q-8 10-19.826 12T860-474L480-765 100-474Zm380 201Z"
		/>
	</svg>
);
