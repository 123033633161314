import styled from 'styled-components';

import * as styles from 'pkg/config/styles';

import { TableRow, TableBodyCell } from 'components/group/statistics/Table';

const TextWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	@media ${styles.breakpoint.small} {
		justify-content: flex-start;
	}
`;

const NoDataTableRow = ({ text, span }) => {
	return (
		<TableRow>
			<TableBodyCell colSpan={span || 10}>
				<TextWrapper>{text}</TextWrapper>
			</TableBodyCell>
		</TableRow>
	);
};

export default NoDataTableRow;
