import * as styles from 'routes/kitchen_sink/formatting.css';

import { FormattedContent } from 'components/formatted-content/FormattedContent';

interface MarkdownProps {
	raw: string;
}

export default function Markdown({ raw }: MarkdownProps): JSX.Element {
	const implodeCodeBlocks = (blocks: string[]): string[] => {
		let didOpenSpecialMarkdown = false;
		let specialMarkdownCodeBlocks: string[] = [];

		return blocks
			.map((block: string) => {
				const isCodeBlockToken = block.trim().startsWith('```');

				if (isCodeBlockToken && !didOpenSpecialMarkdown) {
					didOpenSpecialMarkdown = true;
					specialMarkdownCodeBlocks = [];
				}

				if (
					isCodeBlockToken &&
					didOpenSpecialMarkdown &&
					specialMarkdownCodeBlocks.length
				) {
					didOpenSpecialMarkdown = false;

					specialMarkdownCodeBlocks.push('```');

					return specialMarkdownCodeBlocks.filter((n: string) => n).join('\n');
				}

				if (didOpenSpecialMarkdown) {
					specialMarkdownCodeBlocks.push(`${block}\n `);

					return '';
				}

				return block;
			})
			.filter((n: string) => n);
	};

	return (
		<article className={styles.wrapper}>
			<FormattedContent
				raw={raw}
				rawFormatter={implodeCodeBlocks}
				allowedFormatters={[
					'md:lists',
					'md:blockquote',
					'md:headings',
					'md:formatting',
					'md:anchors',
					'md:spacer',
					'md:divider',
					'md:code-block',
					'md:code-inline',
					'md:includes',
					'youtube',
				]}
			/>
		</article>
	);
}

/**
 * A special template string function used for inline markdown.
 * It has a few limitations such as not being able to handle code blocks, and inline code needs to be escaped.
 * First and last line must be empty to prevent Prettier from formatting.
 *
 * 	@example
 * 	{md`
 * 		# Hello
 *
 * 		World!
 *	`}
 *
 * 	@param strings TemplateStringsArray
 *
 * 	@returns JSX.Element
 */
export function md(strings: TemplateStringsArray): JSX.Element {
	let fragments = strings.join('').split('\n');

	// Fetch the initial ident
	const indent = (fragments[1].match(/\t/gy) || '').length;

	// Remove initial indent
	fragments = fragments.map((fragment: string) => {
		return fragment.replace(new RegExp(`^\\t{${indent}}`, 'gim'), '');
	});

	const raw = fragments.join('\n').replace(/^\s+|\s+$/g, '');

	return <Markdown raw={raw} />;
}
