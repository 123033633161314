import { ReactNode } from 'react';

export const symbol_air: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M461-160q-38 0-69-22t-41-59q-3-13 6.5-23t23.5-10q10.636 0 18.818 7.5Q408-259 413-248q6 14 19.731 21 13.731 7 28.269 7 22.95 0 38.475-15.535Q515-251.07 515-274.035T499.475-312.5Q483.95-328 461-328H110q-12.75 0-21.375-8.675Q80-345.351 80-358.175 80-371 88.625-379.5T110-388h351q47.5 0 80.75 33.25T575-274q0 47.5-33.25 80.75T461-160ZM110-568q-12.75 0-21.375-8.675Q80-585.351 80-598.175 80-611 88.625-619.5T110-628h513q31.667 0 53.833-22.235 22.167-22.236 22.167-54Q699-736 676.833-758 654.667-780 623-780q-23 0-42 12t-26 33q-4 11-12.5 19t-19.5 8q-13.778 0-22.389-9.5Q492-727 495-740q11-45 46.5-72.5T623-840q57 0 96.5 39.5T759-704q0 57-39.5 96.5T623-568H110Zm670 321q-13 2-22.5-6.5T748-276q0-11 8-19.5t19-12.5q22-7 33.5-26t11.5-42q0-30-21-51t-51-21H110q-12.75 0-21.375-8.675Q80-465.351 80-478.175 80-491 88.625-499.5T110-508h638q54.686 0 93.343 38.657Q880-430.686 880-376q0 47-27.5 83T780-247Z"
		/>
	</svg>
);
