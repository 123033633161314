import { ReactNode } from 'react';

export const symbol_app_blocking: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M720-320q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm0-50q15.771 0 30.886-4Q766-378 778-387L627-538q-8 13-12.5 27.457T610-481q0 46 31.9 78.5T720-370Zm94-52q8-13 12-27.786 4-14.785 4-30.214 0-46.2-31.9-78.1Q766.2-590 720-590q-15.771 0-30.886 4Q674-582 662-574l152 152ZM260-40q-24.75 0-42.375-17.625T200-100v-760q0-24.75 17.625-42.375T260-920h440q24.75 0 42.375 17.625T760-860v116q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T700-744v-26H260v580h440v-26q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T760-216v116q0 24.75-17.625 42.375T700-40H260Zm0-90v30h440v-30H260Zm0-700h440v-30H260v30Zm0 0v-30 30Zm0 700v30-30Z"
		/>
	</svg>
);
