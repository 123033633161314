import { t } from '@transifex/native';
import { useState, Fragment } from 'react';
import { T } from '@transifex/react';

import * as styles from 'pkg/config/styles';

import { AccountRole } from 'pkg/api/models/onboarding_info';
import * as models from 'pkg/api/models';
import { getRequiredIdpForAccountRole } from 'pkg/api/models/identity_provider';

import { useOnboardingContext } from 'routes/public/onboarding';
import { useOnboardingState } from 'routes/public/hooks';
import Title from 'routes/public/styles/Title';
import Button from 'routes/public/styles/Button';
import CancelButton from 'routes/public/styles/CancelButton';
import * as publicStyles from 'routes/public/styles/styles.css';
import RoleSelectorCard from 'routes/public/styles/RoleSelectorCard';

import Column from 'components/layout/column';
import InfoBox from 'components/form/info-box';

export default function RoleSelector(): JSX.Element {
	const { goTo } = useOnboardingContext();
	const onboardingState = useOnboardingState();
	const [selectedRole, setSelectedRole] = useState<AccountRole>(null);
	const identityProviders =
		onboardingState.get<models.identityProvider.ScrubbedIdentityProvider[]>(
			'identityProviders'
		);

	const handleSelectRole = (role: AccountRole) => {
		setSelectedRole(role);
	};

	const handleSave = () => {
		onboardingState.setAccount({ role: selectedRole });

		if (getRequiredIdpForAccountRole(selectedRole, identityProviders)) {
			goTo('sso-select');
		} else {
			goTo('user-information');
		}
	};

	return (
		<Fragment>
			<Column spacing={styles.spacing._8} className={publicStyles.main}>
				<Title title={t('Select your role')} />

				<Column spacing={styles.spacing._6}>
					<Column>
						<RoleSelectorCard
							label={t(`I'm a parent to a player`)}
							description={t(
								'Set up an account for yourself and for your child.'
							)}
							userRole="parent"
							onClick={() => handleSelectRole('parent')}
							selected={selectedRole === 'parent'}
							testid="onboarding.signup.parent"
						/>
						<RoleSelectorCard
							label={t(`I'm a coach or staff`)}
							description={t(
								'You are a coach or staff member in the team or club.'
							)}
							userRole="staff"
							onClick={() => handleSelectRole('staff')}
							selected={selectedRole === 'staff'}
							testid="onboarding.signup.staff"
						/>
						<RoleSelectorCard
							label={t(`I'm a player`)}
							description={t('You want to create a player account only.')}
							userRole="player"
							onClick={() => handleSelectRole('player')}
							selected={selectedRole === 'player'}
							testid="onboarding.signup.player"
						/>
					</Column>

					{selectedRole === 'player' && (
						<InfoBox color="orange">
							<p>
								<T
									_str="If you are a parent signing up with your child, choose the {text} option instead."
									text={<strong>"{t(`I'm a parent to a player`)}"</strong>}
								/>
							</p>
						</InfoBox>
					)}

					<Button
						primary
						label={t('Continue')}
						disabled={!selectedRole}
						onClick={handleSave}
					/>
				</Column>
			</Column>

			<div className={publicStyles.footer}>
				<CancelButton />
			</div>
		</Fragment>
	);
}
