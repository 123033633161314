const icon = {
	name: 'video-camera',
	objects: [
		{
			type: 'path',
			attributes: {
				d: 'M5.16144 5.87629C4.80647 6.23126 4.58516 6.8772 4.58516 8.15833V15.175C4.58516 15.9582 4.78129 16.6687 5.2011 17.1723C5.60352 17.6551 6.28247 18.0333 7.44349 18.0333H12.7102C13.9865 18.0333 14.6332 17.8123 14.9895 17.4566C15.3456 17.1012 15.5685 16.4549 15.5685 15.175V14.2948L15.5685 14.2917V9.03334L15.5685 9.03019V8.15833C15.5685 6.8772 15.3472 6.23126 14.9922 5.87629C14.6372 5.52131 13.9913 5.3 12.7102 5.3H7.44349C6.16236 5.3 5.51642 5.52131 5.16144 5.87629ZM15.9079 18.3767C16.5824 17.7035 16.8244 16.7267 16.8628 15.5376L18.1603 16.4477L18.1612 16.4483C18.8229 16.9151 19.6097 17.1329 20.2931 16.7779C20.9764 16.4229 21.2518 15.6535 21.2518 14.8417V8.49168C21.2518 7.67931 20.9762 6.91114 20.2944 6.55509C19.6124 6.19898 18.8257 6.41252 18.1629 6.87547L16.8626 7.78755C16.8237 6.60321 16.5834 5.62902 15.9114 4.95705C15.1706 4.21619 14.0624 4 12.7102 4H7.44349C6.09129 4 4.98306 4.21619 4.2422 4.95705C3.50134 5.69791 3.28516 6.80613 3.28516 8.15833V15.175C3.28516 16.1502 3.52652 17.1938 4.20254 18.0047C4.89596 18.8366 5.97117 19.3333 7.44349 19.3333H12.7102C14.0588 19.3333 15.1663 19.1169 15.9079 18.3767ZM18.9084 15.3845L16.8685 13.9537V9.37137L18.9074 7.94121C19.3776 7.61292 19.6245 7.67189 19.6926 7.70742C19.7608 7.74305 19.9518 7.91238 19.9518 8.49168V14.8417C19.9518 15.4216 19.7605 15.5896 19.6939 15.6242C19.6276 15.6587 19.3815 15.7184 18.91 15.3856L18.9084 15.3845ZM11.2518 9.58332C11.2518 9.25195 11.5204 8.98332 11.8518 8.98332C12.1832 8.98332 12.4518 9.25195 12.4518 9.58332C12.4518 9.91469 12.1832 10.1833 11.8518 10.1833C11.5204 10.1833 11.2518 9.91469 11.2518 9.58332ZM11.8518 7.68332C10.8024 7.68332 9.95178 8.53398 9.95178 9.58332C9.95178 10.6327 10.8024 11.4833 11.8518 11.4833C12.9011 11.4833 13.7518 10.6327 13.7518 9.58332C13.7518 8.53398 12.9011 7.68332 11.8518 7.68332Z',
				clipRule: 'evenodd',
				fillRule: 'evenodd',
			},
		},
	],
};

export default icon;
