import { ReactNode } from 'react';

export const symbol_counter_7: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m535-623-82 309q-4 14 5 25t23 11q10 0 18-5.5t10-15.5l84-315q1-3 1.5-6.5t.5-6.5q0-24-18-40t-42-16H395q-13 0-21.5 8.5T365-653q0 13 8.5 21.5T395-623h140ZM480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm0-60q142 0 241-99.5T820-480q0-142-99-241t-241-99q-141 0-240.5 99T140-480q0 141 99.5 240.5T480-140Zm0-340Z"
		/>
	</svg>
);
