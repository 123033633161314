import { ReactNode } from 'react';

export const symbol_swipe_right: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M470-80q-21.882 0-41.941-8T392-112L202-302q-8-8-9-19t5-20l1-1q11-16 28.5-22.5T264-366l96 26v-340q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T420-680v381q0 15-12 24t-26 5l-86-23 139.178 139.178Q442-147 451.13-143.5q9.131 3.5 18.87 3.5h170q42 0 71-29t29-71v-180q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T800-420v180q0 66-47 113T640-80H470Zm47.175-490q12.825 0 21.325 8.625T547-540v140q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T487-400v-140q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625Zm126 40q12.825 0 21.325 8.625T673-500v100q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T613-400v-100q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625ZM833-740q-79-69-171.016-104.5Q569.968-880 470-880q-107.161 0-192.08 39.5Q193-801 140-724q-7 10-16.146 17-9.147 7-20.854 7-8 0-11.5-8t1.5-16q53-94 152.26-145 99.26-51 224.74-51 103 0 196 34.5T840-787v-73q0-8 6-14t14-6q8 0 14 6t6 14v130q0 12.75-8.625 21.375T850-700H720q-8 0-14-6t-6-14q0-8 6-14t14-6h113ZM578-265Z"
		/>
	</svg>
);
