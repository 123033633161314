import { ReactNode } from 'react';

export const symbol_keyboard_double_arrow_down: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m480-284 177-177q9-9 21-9t21 9q9 9 9 21t-9 21L501-221q-5 5-10 7t-11 2q-6 0-11-2t-10-7L261-419q-9-9-9-21t9-21q9-9 21-9t21 9l177 177Zm0-253 177-177q9-9 21-9t21 9q9 9 9 21t-9 21L501-474q-5 5-10 7t-11 2q-6 0-11-2t-10-7L261-672q-9-9-9-21t9-21q9-9 21-9t21 9l177 177Z"
		/>
	</svg>
);
