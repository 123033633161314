import { Fragment } from 'react/jsx-runtime';
import { useState } from 'react';
import { useT } from '@transifex/react';

import { isToday } from 'pkg/date';
import DateTime from 'pkg/datetime';

import SubscriptionDialog from 'routes/group/calendar/components/CalendarSubscriptionDialog';
import { useCalendarContext } from 'routes/group/calendar';

import { SmallScreen } from 'components/MediaQuery';

import * as ActionBar from 'components/layout/ActionBar';
import ScheduleDateSelect from 'components/calendar/schedule-date-select';

import Button from 'design/button';

interface UserProfileCalendarActionBarProps {
	userId: number;
}

export default function UserProfileCalendarActionBar({
	userId,
}: UserProfileCalendarActionBarProps) {
	const t = useT();
	const calendarCtx = useCalendarContext();
	const [showModal, setShowModal] = useState(false);

	const selectedDateIsToday = isToday(
		new DateTime(calendarCtx.calendarState.scheduleDate)
	);

	const handleShowModal = () => setShowModal(true);

	const closeModal = () => setShowModal(false);

	const onTodayClick = () => {
		calendarCtx.scrollItemRef.current.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	return (
		<Fragment>
			<ActionBar.FilterBar>
				<ActionBar.PrimaryAction>
					<ScheduleDateSelect />
				</ActionBar.PrimaryAction>
				<Fragment>
					{calendarCtx.calendarState.showToday && selectedDateIsToday && (
						<SmallScreen>
							<Button
								label={t('Today')}
								inline
								onClick={onTodayClick}
								noPadding
							/>
						</SmallScreen>
					)}
					<Button
						label={t('Subscribe')}
						icon="ios_share"
						iconPosition="left"
						onClick={handleShowModal}
					/>
				</Fragment>
			</ActionBar.FilterBar>
			{showModal && (
				<SubscriptionDialog userIds={[userId]} onClose={closeModal} />
			)}
		</Fragment>
	);
}
