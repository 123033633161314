import { ReactNode } from 'react';

export const symbol_kitchen: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M338.825-650Q326-650 317.5-658.625T309-680v-58q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T369-738v58q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Zm0 361Q326-289 317.5-297.625T309-319v-136q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T369-455v136q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM220-80q-24.75 0-42.375-17.625T160-140v-680q0-24.75 17.625-42.375T220-880h520q24.75 0 42.375 17.625T800-820v680q0 24.75-17.625 42.375T740-80H220Zm0-60h520v-398H220v398Zm0-458h520v-222H220v222Z"
		/>
	</svg>
);
