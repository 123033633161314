import { ReactNode } from 'react';

export const symbol_expand_circle_up: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m480-524 109 109q9 9 21 9t21-9q9-9 9-21.5t-9-21.5L501-588q-9-9-21-9t-21 9L328-457q-9 9-8.5 21t9.5 21q9 9 21 9t21-9l109-109Zm0 444q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-155.5t85.5-127q54-54.5 127-86T480-880q83 0 155.5 31.5t127 86q54.5 54.5 86 127T880-480q0 83-31.5 156t-86 127q-54.5 54-127 85.5T480-80Zm0-60q141 0 240.5-99T820-480q0-141-99.5-240.5T480-820q-142 0-241 99.5T140-480q0 142 99 241t241 99Zm0-340Z"
		/>
	</svg>
);
