import { useT } from '@transifex/react';

import spacing from 'pkg/config/spacing';

import * as models from 'pkg/api/models';
import Link from 'pkg/router/Link';

import FooterNote from 'routes/public/styles/FooterNote';
import Title from 'routes/public/styles/Title';
import Button from 'routes/public/styles/Button';
import { useOnboardingContext } from 'routes/public/onboarding';
import { useOnboardingState } from 'routes/public/hooks';

import Badge from 'components/Badge';

import Column from 'components/layout/column';

export function GenericUnableToAuthError() {
	const t = useT();
	const onboardingCtx = useOnboardingContext();
	const onboardingState = useOnboardingState();
	const group = onboardingState.get<models.onboardingInfo.Group>('group');

	const handleStartClick = () => onboardingCtx.goTo('start');

	return (
		<OnboardingSSOError
			title={t('Unable to authenticate')}
			description={t(
				'There was a problem when trying to authenticate you towards the identity provider, please try again or contact your IT admin to resolve the issue'
			)}
			group={group}
			handleStartClick={handleStartClick}
		/>
	);
}

interface ContentProps {
	title: string;
	description: string;
	group: models.onboardingInfo.Group;
	handleStartClick: () => void;
}

export default function OnboardingSSOError({
	title,
	description,
	group,
	handleStartClick,
}: ContentProps) {
	const t = useT();

	return (
		<Column spacing={spacing._8}>
			<Column justify="center">
				<Badge badgeUrl={group?.profileImageUrl} size="90px" />
				<Title title={title} description={description} center />
			</Column>

			<Column spacing={spacing._5}>
				<Button label={t('Back to start')} primary onClick={handleStartClick} />
			</Column>

			<FooterNote>
				<Link href="https://help.360player.com/article/213-login-using-sso">
					{t('Need help?')}
				</Link>
			</FooterNote>
		</Column>
	);
}
