import { schema } from 'normalizr';

export const videosSchema = new schema.Entity('videos');

export const matchVideosSchema = new schema.Entity(
	'matchVideos',
	{
		video: videosSchema,
	},
	{
		idAttribute: (value) => {
			return `m${value.matchId}v${value.videoId}`;
		},
	}
);

export const userVideosSchema = new schema.Entity('userVideos');
