import { useT } from '@transifex/react';

import spacing from 'pkg/config/spacing';

import * as models from 'pkg/api/models';

import FormInputField from 'routes/forms/registration/form/FormInputField';

import * as CardList from 'components/card-list';
import Avatar from 'components/avatar';

import Section from 'components/form/Section';
import Column from 'components/layout/column';

interface Props {
	account: models.account.Account;
	fields: models.form.Field[];
	form: models.form.Form;

	user?: models.user.User;
}

const FormFields = ({ fields, user, account, form }: Props) => {
	const t = useT();

	return (
		<Section title={t('Registration for')}>
			<Column spacing={spacing._7}>
				<Column>
					<CardList.Base>
						<CardList.Image>
							{account ? <Avatar account={account} /> : <Avatar user={user} />}
						</CardList.Image>
						<CardList.Title>
							{account
								? models.account.fullName(account)
								: models.user.fullName(user)}
							<CardList.SubTitle>
								{user?.organization?.name || form?.group?.name}
							</CardList.SubTitle>
						</CardList.Title>
					</CardList.Base>
				</Column>
				<Column spacing={spacing._6}>
					{fields
						.sort((a, b) => a.sortOrder - b.sortOrder)
						.map((field) => (
							<FormInputField
								key={field.key}
								field={field}
								user={user}
								account={account}
							/>
						))}
				</Column>
			</Column>
		</Section>
	);
};

export default FormFields;
