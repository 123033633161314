import { t } from '@transifex/native';

import { palette } from 'pkg/config/styles';

import { eventTypeToTranslatedString } from 'pkg/models/event';

import * as models from 'pkg/api/models';
import { EventTypes } from 'pkg/api/models/event';
import { precise } from 'pkg/numbers';

import * as Chart from 'design/chart';
import * as DataCard from 'design/data_card';

interface ActivitiesTypeWidget {
	dashboardData: models.eventReport.AttendanceDashboardData;
}

export default function ActivitiesTypeWidget({
	dashboardData,
}: ActivitiesTypeWidget) {
	const breakdownItems: DataCard.BreakdownItem[] = [
		{
			title: eventTypeToTranslatedString(EventTypes.Match),
			color: 'green',
			variant: 'label',
			value: dashboardData.matchEventsCount.toString(),
			diff: `${precise(dashboardData.matchEventsRate, 1).toString()}%`,
		},
		{
			title: eventTypeToTranslatedString(EventTypes.Practice),
			color: 'orange',
			variant: 'label',
			value: dashboardData.practiceEventsCount.toString(),
			diff: `${precise(dashboardData.practiceEventsRate, 1).toString()}%`,
		},
		{
			title: eventTypeToTranslatedString(EventTypes.Meeting),
			color: 'blue',
			variant: 'label',
			value: dashboardData.meetingEventsCount.toString(),
			diff: `${precise(dashboardData.meetingEventsRate, 1).toString()}%`,
		},
		{
			title: eventTypeToTranslatedString(EventTypes.Camp),
			color: 'purple',
			variant: 'label',
			value: dashboardData.campEventsCount.toString(),
			diff: `${precise(dashboardData.campEventsRate, 1).toString()}%`,
		},
		{
			title: eventTypeToTranslatedString(EventTypes.Cup),
			color: 'red',
			variant: 'label',
			value: dashboardData.cupEventsCount.toString(),
			diff: `${precise(dashboardData.cupEventsRate, 1).toString()}%`,
		},
		{
			title: eventTypeToTranslatedString(EventTypes.Uncategorized),
			color: 'gray',
			variant: 'label',
			value: dashboardData.uncategorizedEventsCount.toString(),
			diff: `${precise(dashboardData.uncategorizedEventsRate, 1).toString()}%`,
		},
	];

	const chartItems: Chart.ChartItem[] = [
		{
			title: eventTypeToTranslatedString(EventTypes.Match),
			value: dashboardData.matchEventsCount,
			fillColor: palette.green[200],
			textColor: palette.green[600],
		},
		{
			title: eventTypeToTranslatedString(EventTypes.Practice),
			value: dashboardData.practiceEventsCount,
			fillColor: palette.orange[200],
			textColor: palette.orange[600],
		},
		{
			title: eventTypeToTranslatedString(EventTypes.Meeting),
			value: dashboardData.meetingEventsCount,
			fillColor: palette.blue[200],
			textColor: palette.blue[600],
		},
		{
			title: eventTypeToTranslatedString(EventTypes.Camp),
			value: dashboardData.campEventsCount,
			fillColor: palette.purple[200],
			textColor: palette.purple[600],
		},
		{
			title: eventTypeToTranslatedString(EventTypes.Cup),
			value: dashboardData.cupEventsCount,
			fillColor: palette.red[200],
			textColor: palette.red[600],
		},
		{
			title: eventTypeToTranslatedString(EventTypes.Uncategorized),
			value: dashboardData.uncategorizedEventsCount,
			fillColor: palette.gray[200],
			textColor: palette.gray[600],
		},
	];

	return (
		<DataCard.Base
			title={t('Number of activities per type')}
			titleIcon="calendar_today"
			heading={dashboardData.eventCount?.toString()}
			headingSuffix={t('total events')}>
			<DataCard.Breakdown items={breakdownItems} />
			<Chart.Donut items={chartItems} hideLegend />
		</DataCard.Base>
	);
}
