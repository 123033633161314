import { ReactNode } from 'react';

export const symbol_bluetooth_connected: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M200-432q-20 0-34.5-14T151-480q0-20 14.5-35t34.5-15q20 0 34.5 15t14.5 35q0 20-14.5 34T200-432Zm560 0q-20 0-34.5-14T711-480q0-20 14.5-35t34.5-15q20 0 34.5 15t14.5 35q0 20-14.5 34T760-432ZM450-136v-272L277-235q-9 9-21 9t-21-9q-9-9-9-21t9-21l203-203-203-203q-9-9-9-21t9-21q9-9 21-9t21 9l173 173v-272q0-14 9.5-22t20.5-8q5 0 10.5 2t10.5 7l172 172q5 5 7 10t2 11q0 6-2 11t-7 10L522-480l151 151q5 5 7 10t2 11q0 6-2 11t-7 10L501-115q-5 5-10.5 7t-10.5 2q-11 0-20.5-8t-9.5-22Zm60-416 100-100-100-98v198Zm0 342 100-98-100-100v198Z"
		/>
	</svg>
);
