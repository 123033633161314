import { ReactNode } from 'react';

export const symbol_text_rotation_angledown: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M498-180 113-565q-9-9-9-21t9-21q9-9 21-9t21 9l385 385v-48q0-13 8.5-21.5T570-300q13 0 21.5 8.5T600-270v120q0 13-8.5 21.5T570-120H450q-13 0-21.5-8.5T420-150q0-13 8.5-21.5T450-180h48Zm-12-467-99 47q-8 4-15.5 2t-13.5-7q-10-10-7.5-23.5T366-648l377-170q8-4 16-2.5t14 7.5l14 14q6 6 7.5 14t-2.5 16L621-391q-5 12-18.5 14.5T579-384q-6-6-7.5-13.5T574-413l46-98-134-136Zm44-22 112 114 99-210-2-3-209 99Z"
		/>
	</svg>
);
