import { ReactNode } from 'react';

export const symbol_fax: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M200-120q-50 0-85-35t-35-85v-280q0-50 35-85t85-35q27 0 49.5 11t39.5 29h31v-140q0-24.75 17.625-42.375T380-800h280q24.75 0 42.375 17.625T720-740v140h60q41.667 0 70.833 29.167Q880-541.667 880-500v280q0 24.75-17.625 42.375T820-160H289q-17 18-39.5 29T200-120Zm0-60q25.5 0 42.75-17.25T260-240v-280q0-25.5-17.25-42.75T200-580q-25.5 0-42.75 17.25T140-520v280q0 25.5 17.25 42.75T200-180Zm180-420h280v-140H380v140Zm-60 380h500v-280q0-17-11.5-28.5T780-540H320v320Zm280-180q17 0 28.5-11.5T640-440q0-17-11.5-28.5T600-480q-17 0-28.5 11.5T560-440q0 17 11.5 28.5T600-400Zm120 0q17 0 28.5-11.5T760-440q0-17-11.5-28.5T720-480q-17 0-28.5 11.5T680-440q0 17 11.5 28.5T720-400ZM600-280q17 0 28.5-11.5T640-320q0-17-11.5-28.5T600-360q-17 0-28.5 11.5T560-320q0 17 11.5 28.5T600-280Zm120 0q17 0 28.5-11.5T760-320q0-17-11.5-28.5T720-360q-17 0-28.5 11.5T680-320q0 17 11.5 28.5T720-280Zm-310 0h80q12.75 0 21.375-8.625T520-310v-140q0-12.75-8.625-21.375T490-480h-80q-12.75 0-21.375 8.625T380-450v140q0 12.75 8.625 21.375T410-280Zm-90 60v-320 320Z"
		/>
	</svg>
);
