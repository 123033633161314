import { createSelector } from 'reselect';

import { getMatchById } from 'pkg/selectors/matches.selector';
import { matchEventsListSelector } from 'pkg/selectors/match_events';
import { getEventUsersEntities } from 'pkg/selectors/event_users';
import { findAll } from 'pkg/selectors/users';

const matchEventUsers = createSelector(
	getEventUsersEntities,
	getMatchById,
	(eventUsers, match) =>
		eventUsers.filter((eu) => eu.eventId === match.eventId).toList()
);

export const getUsersForMatch = createSelector(
	matchEventUsers,
	findAll,
	(eventUsers, users) =>
		eventUsers
			.map((eventUser) => eventUser.set('user', users.get(eventUser.userId)))
			.sort((a, b) =>
				`${a.user.firstName} ${a.user.lastName}`.localeCompare(
					`${b.user.firstName} ${b.user.lastName}`
				)
			)
);

const getStartingEvents = createSelector(
	(_, props) => props.matchId,
	matchEventsListSelector,
	(matchId, matchEvents) =>
		matchEvents.filter(
			(event) =>
				event.get('type') === 'starting_user' &&
				event.get('matchId') === matchId
		)
);

const getStartingUsersMatchEvent = createSelector(
	getStartingEvents,
	(matchEvents) => {
		const eventsByUserId = matchEvents.groupBy((event) => event.get('userId'));
		const userIds = [];

		eventsByUserId.mapKeys((key) => {
			userIds.push(key);
		});

		return userIds;
	}
);

const getUserIdByPosition = createSelector(
	getStartingEvents,
	(_, props) => props.position,
	(entities, position) =>
		entities
			.filter((event) => event.get('positionId') === position.id)
			.map((event) => event.get('userId'))
);

export const makeGetUsersWithoutStartingEvent = () =>
	createSelector(
		getUsersForMatch,
		getStartingUsersMatchEvent,
		(eventUsers, placedUsers) => {
			return eventUsers.filter(
				(eventUser) => !placedUsers.includes(eventUser.get('userId'))
			);
		}
	);

export const getMatchUsersByPosition = createSelector(
	getUsersForMatch,
	getUserIdByPosition,
	(eventUsers, startingUsers) => {
		return eventUsers.filter((eventUser) =>
			startingUsers.includes(eventUser.get('userId'))
		);
	}
);
