import { ReactNode } from 'react';

export const symbol_smart_display: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m415-328 218-141q7-4.5 7-12.75T633-495L415-636q-8-5-15.5-.5T392-623v282q0 9 7.5 13.5t15.5-.5ZM140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm0-60h680v-520H140v520Zm0 0v-520 520Z"
		/>
	</svg>
);
