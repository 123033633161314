import { t } from '@transifex/native';
import { useSelector } from 'react-redux';
import { Fragment } from 'react';

import * as styles from 'pkg/config/styles';

import User from 'pkg/models/user';

import * as selectors from 'pkg/selectors';
import { RootState } from 'pkg/reducers';

import { FormStateData, Selected } from 'routes/payments/hooks/useContactForm';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import Icon from 'components/icon';

import { FormPayload } from 'components/form/Form';
import Section from 'components/form/Section';
import Column from 'components/layout/column';

import Button, { ButtonGroup } from 'design/button';

interface BillingUserProps {
	contactData: FormStateData;
	billingData: FormPayload | User;
	onOpenModal: (modal: string) => void;
	onRemoveBilling: () => void;
}

const BillingUserSection: React.FC<
	React.PropsWithChildren<BillingUserProps>
> = ({ contactData, billingData, onOpenModal, onRemoveBilling }) => {
	const selectedBillingUser = useSelector((state: RootState) =>
		selectors.users.find(state, contactData.billingUserId)
	);

	const handleOpenBillingSelect = () => onOpenModal('billingSelect');

	return (
		<Section
			icon="person_add"
			title={t(`Billing contact`)}
			description={t(
				`Add a dedicated contact if someone else should recieve all invoices related to this contact`
			)}>
			<Column spacing={styles.spacing._5}>
				{selectedBillingUser.id !== 0 && (
					<Selected>
						<p>{t(`Selected billing contact`)}</p>
						<div>
							<span>{selectedBillingUser.fullName}</span>
							<Icon name="close-circle" onClick={onRemoveBilling} />
						</div>
					</Selected>
				)}

				{billingData.firstName && (
					<Selected>
						<p>{t(`Selected billing contact`)}</p>
						<div>
							<span>
								{billingData.firstName as string}{' '}
								{billingData.lastName as string}
							</span>
							<Icon name="close-circle" onClick={onRemoveBilling} />
						</div>
					</Selected>
				)}

				{contactData.billingUserId === null && !billingData.firstName && (
					<Fragment>
						<LargeScreen>
							<ButtonGroup>
								<Button secondary onClick={handleOpenBillingSelect}>
									<Icon name="add" />
									{t(`Add billing contact`)}
								</Button>
							</ButtonGroup>
						</LargeScreen>
						<SmallScreen>
							<Button secondary block onClick={handleOpenBillingSelect}>
								<Icon name="add" />
								{t(`Add billing contact`)}
							</Button>
						</SmallScreen>
					</Fragment>
				)}
			</Column>
		</Section>
	);
};

export default BillingUserSection;
