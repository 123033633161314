import { ReactNode } from 'react';

export const symbol_relax: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-120q-18 0-34.5-6.5T416-146L166-396q-8-9-7.5-22t9.5-21q9-8 21.375-7.333Q201.75-445.667 210-437l249 248q5 5 9.8 7 4.8 2 11.2 2 6.4 0 11.2-2 4.8-2 9.8-7l267-268q26-26 39-60.5t13-72.5q0-77-49.946-133.5T645.189-780Q610-780 577.5-765.5 545-751 521-725l-27 29q-3 3-6 5t-8 2q-5 0-8.636-1.885-3.637-1.884-6.364-5.115l-27-29q-24.273-25.816-56.636-40.408Q349-780 314-780q-70 0-118 50t-55 120q-2 12-11.5 20.5T108-583q-12-2-20-11.5T82-616q9-91 73-157.5T314-840q48 0 90.5 19t75.5 53q32-34 74.5-53t90.5-19q100 0 167.5 74T880-590q0 49-17 94t-51 80L543-146q-13 13-29 19.5t-34 6.5Zm-5-370H111q-12.75 0-21.375-8.675Q81-507.351 81-520.175 81-533 89.625-541.5T111-550h524q21.25 0 35.625-14.325Q685-578.649 685-599.825 685-621 670.625-635.5 656.25-650 635-650q-7.35 0-14.175 1.5Q614-647 608-643q-10 8-22.19 6.056Q573.619-638.889 566-649q-8-9.609-6-21.804Q562-683 572-691q14-9 29.904-14 15.903-5 33.096-5 45.833 0 77.917 32.118 32.083 32.117 32.083 78Q745-554 712.917-522 680.833-490 635-490h-62q6 11 9 23.35 3 12.35 3 26.65 0 45.833-32.083 77.917Q520.833-330 475-330q-17.193 0-33.096-5Q426-340 412-349q-10-8-12-20.089-2-12.089 6-21.911 7.619-10.111 19.81-12.056Q438-405 448-397q6 4 12.825 5.5Q467.65-390 475-390q21.25 0 35.625-14.325Q525-418.649 525-439.825 525-461 510.625-475.5 496.25-490 475-490Zm5 10Z"
		/>
	</svg>
);
