import { useEventListener } from 'pkg/hooks/events';

type PostMessagePayload = string | JSONObject;

type PostMessageSender = (data: PostMessagePayload) => void;

type PostMessageReciever = (
	data: PostMessagePayload,
	event: MessageEvent
) => void;

export function usePostMessage(
	onMessage?: PostMessageReciever,
	target?: HTMLIFrameElement
): PostMessageSender {
	const actualTarget = target?.contentWindow || window.top;

	useEventListener('message', (event: MessageEvent) => {
		if (onMessage) {
			onMessage(event.data, event);
		}
	});

	const messageSender = (data: PostMessagePayload) => {
		actualTarget.postMessage(data, '*');
	};

	return messageSender;
}
