import { ReactNode } from 'react';

export const symbol_not_started: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M360.175-330q12.825 0 21.325-8.625T390-360v-240q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T330-600v240q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625ZM525-362l149-99q10-6.805 10-18.902Q684-492 674-499l-149-99q-11-8-23-1.443T490-579v198q0 13.886 12 20.443Q514-354 525-362ZM480.266-80q-82.734 0-155.5-31.5t-127.266-86q-54.5-54.5-86-127.341Q80-397.681 80-480.5q0-82.819 31.5-155.659Q143-709 197.5-763t127.341-85.5Q397.681-880 480.5-880q82.819 0 155.659 31.5Q709-817 763-763t85.5 127Q880-563 880-480.266q0 82.734-31.5 155.5T763-197.684q-54 54.316-127 86Q563-80 480.266-80Zm.234-60Q622-140 721-239.5t99-241Q820-622 721.188-721 622.375-820 480-820q-141 0-240.5 98.812Q140-622.375 140-480q0 141 99.5 240.5t241 99.5Zm-.5-340Z"
		/>
	</svg>
);
