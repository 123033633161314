import { ReactNode } from 'react';

export const symbol_private_connectivity: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-200q-109 0-188-72t-90-178h-92q-12.75 0-21.375-8.675Q80-467.351 80-480.175 80-493 88.625-501.5T110-510h92q11-106 90-178t188-72q109 0 188 72t90 178h92q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-450h-92q-11 106-90 178t-188 72Zm.495-60Q572-260 636-324.495q64-64.494 64-156Q700-572 636-636t-155.505-64q-91.506 0-156 64Q260-572 260-480.495q0 91.506 64.495 156Q388.989-260 480.495-260Zm-77.154-85h153.364Q573-345 584-355.915q11-10.916 11-27.051v-113.898Q595-513 584.075-524T557-535h-1v-41q0-32-21.982-55.5Q512.035-655 479.54-655 448-655 426-631.5T404-576v41h-1q-16.15 0-27.075 10.915Q365-513.169 365-497.034v113.898Q365-367 376.023-356t27.318 11ZM480-410q-13 0-21.5-8.5T450-440q0-13 8.5-21.5T480-470q13 0 21.5 8.5T510-440q0 13-8.5 21.5T480-410Zm-46-125v-41q0-20 13.225-34.5T480-625q19.55 0 32.775 14.5Q526-596 526-576v41h-92Zm46 95Z"
		/>
	</svg>
);
