import { only } from 'pkg/objects';

export default function notifyRouterChanges(
	parsedRoutes: any
): Promise<Response> {
	const isLoggedIn = !!window.localStorage.getItem('authToken');

	if (!isLoggedIn || window.Cypress) return;

	return fetch('/router-tools/routes', {
		method: 'post',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			routes: parsedRoutes.map((route: any) => only(route, 'name', 'path')),
		}),
	});
}
