import { ReactNode } from 'react';

export const symbol_directions_alt: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M479.949-77Q468-77 456.5-81 445-85 437-93L93-437q-8-8-12-19.551-4-11.551-4-23.5t4-23.449Q85-515 93-523l344-344q8-8 19.551-12 11.551-4 23.5-4t23.449 4q11.5 4 19.5 12l344 344q8 8 12 19.551 4 11.551 4 23.5t-4 23.449q-4 11.5-12 19.5L523-93q-8 8-19.551 12-11.551 4-23.5 4ZM308-308l172 172 344-344-344-344-344 344 172 172Zm172-172Zm81 30L458-347q-9 9-8.5 21t9.5 21q9 9 21.5 9t21.5-9l154-154q9-9 9-21t-9-21L501-656q-9-9-21-9t-21 9q-9 9-9 21.5t9 21.5l102 103H313q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T313-450h248Z"
		/>
	</svg>
);
