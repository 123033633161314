import { t } from '@transifex/native';
import { Fragment, MouseEvent, ReactNode, useState } from 'react';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import { useCollection } from 'pkg/api/use_collection';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import DateTime, { Granularity } from 'pkg/datetime';
import * as endpoints from 'pkg/api/endpoints/auto';

import RelativeDateTime from 'components/RelativeDateTime';
import * as Card from 'components/Card';
import Label from 'components/label';
import { useStepModalContext } from 'components/step-modal';

import {
	VideoPayload,
	VideoPayloadSetter,
} from 'components/video-library/modals/Video';
import * as Input from 'components/form/inputs';
import Column from 'components/layout/column';

import * as Table from 'design/table';
import Button from 'design/button';
import Outline from 'design/placeholders/outline';

interface MatchProps {
	videoId?: number;
	videoGroupId?: number;
	selectable?: boolean;

	payload?: VideoPayload;
	setPayload?: VideoPayloadSetter;
}

const dateTime = DateTime.now();

interface MatchTableProps {
	records: models.match.Match[];

	selectable?: boolean;
	selectedIds?: number[];

	onSelect?: (record: models.match.Match) => void;
	onRemove?: (record: models.match.Match) => void;
}

export function MatchTable({
	records,
	selectable,
	selectedIds,
	onSelect,
	onRemove,
}: MatchTableProps): JSX.Element {
	const getResult = (match: models.match.Match): ReactNode => {
		const { goalCount, opponentGoalCount } = match;
		const [goals, opponentGoals] = [goalCount || 0, opponentGoalCount || 0];

		if (!Number.isInteger(goalCount) && !Number.isInteger(opponentGoalCount)) {
			return <Fragment>&mdash;</Fragment>;
		}

		return (
			<Fragment>
				{match.isHome
					? `${goals} - ${opponentGoals}`
					: `${opponentGoals} - ${goals}`}
			</Fragment>
		);
	};

	const getOutcome = (match: models.match.Match): ReactNode => {
		const { goalCount, opponentGoalCount } = match;
		const [goals, opponentGoals] = [goalCount || 0, opponentGoalCount || 0];

		if (!Number.isInteger(goalCount) && !Number.isInteger(opponentGoalCount)) {
			return <Fragment>&mdash;</Fragment>;
		}

		if (goals === opponentGoals) {
			return <Label color="gray">{t(`Tie`)}</Label>;
		} else if (goals > opponentGoals) {
			return <Label color="green">{t(`Win`)}</Label>;
		} else {
			return <Label color="red">{t(`Loss`)}</Label>;
		}
	};

	const getMatch = (matchId: number): models.match.Match => {
		return records.find((match: models.match.Match) => match.id === matchId);
	};

	const handleSelect = (event: MouseEvent<HTMLTableRowElement>) => {
		event.preventDefault();
		event.stopPropagation();

		if (onSelect) {
			const match = getMatch(Number.parseInt(event.currentTarget.id, 10));

			onSelect(match);
		}
	};

	const handleRemove = (event: MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		event.stopPropagation();

		if (onRemove) {
			const parentNode = event.currentTarget.parentNode as HTMLDivElement;
			const match = getMatch(Number.parseInt(parentNode.dataset.matchId, 10));

			onRemove(match);
		}
	};

	const offsetColumn = {
		content: <span />,
		width: 'max-content',
	};

	const columns = [
		selectable ? offsetColumn : null,
		{
			content: t(`Match`),
			width: 'minmax(max-content, 1fr)',
		},
		{
			content: t(`Result`),
			align: 'center',
			width: 'max-content',
		},
		{
			content: t(`Outcome`),
			align: 'center',
			width: 'max-content',
		},
		{
			content: t(`Date`),
			align: 'right',
			width: 'max-content',
		},
		selectable ? null : offsetColumn,
	].filter((n) => n);

	if (records.length === 0) {
		return null;
	}

	return (
		<Table.Table columns={columns}>
			{records.map((match: models.match.Match) => (
				<Table.Row
					key={match.id}
					id={match.id.toString()}
					onClick={selectable ? handleSelect : null}>
					{selectable && (
						<Table.Cell>
							<Input.Control
								standalone
								type="radio"
								value={match.id}
								checked={selectedIds.includes(match.id)}
							/>
						</Table.Cell>
					)}
					<Table.Cell>
						<Column spacing={styles.spacing._2}>
							<strong>{match.title}</strong>
							<span>
								{match.location} &bull; {match.isHome ? t(`Home`) : t(`Away`)}
							</span>
						</Column>
					</Table.Cell>
					<Table.Cell align="center">{getResult(match)}</Table.Cell>
					<Table.Cell align="center">{getOutcome(match)}</Table.Cell>
					<Table.Cell align="right">
						<RelativeDateTime hideTime dateTime={match.startsAt} />
					</Table.Cell>
					{!selectable && (
						<Table.Cell align="right">
							<div data-match-id={match.id}>
								<Button small caution icon="delete" onClick={handleRemove} />
							</div>
						</Table.Cell>
					)}
				</Table.Row>
			))}
		</Table.Table>
	);
}

export default function Match({
	videoGroupId,
	selectable = true,

	payload,
	setPayload,
}: MatchProps): JSX.Element {
	const before = dateTime.next(Granularity.month).getUnixTimestamp().toString();
	const after = dateTime.prev(Granularity.year).getUnixTimestamp().toString();

	let groupId: string;

	if (payload.groupId) {
		groupId = payload.groupId.toString();
	}

	if (videoGroupId) {
		groupId = videoGroupId.toString();
	}

	const matches = useCollection<models.match.Match>(endpoints.Match.Index(), {
		showAllResults: true,
		queryParams: new URLSearchParams({
			group_id: groupId,
			before,
			after,
		}),
	});

	const [selectedId, setSelectedId] = useState<number>(0);
	const { withNext, setCanGoNext } = useStepModalContext();

	useComponentDidMount(() => {
		if (selectable) {
			setCanGoNext(false);
		}
	});

	withNext(async () => {
		setPayload({ matchId: selectedId });

		return true;
	});

	const handleMatchSelect = (match: models.match.Match) => {
		setSelectedId(match.id);

		setPayload({ match });
		setCanGoNext(true);
	};

	if (matches.records.length === 0) {
		return (
			<Card.Body>
				<Outline icon="nav-events" title={t(`No matches found`)} />
			</Card.Body>
		);
	}

	return (
		<MatchTable
			records={matches.records}
			selectable
			selectedIds={[selectedId]}
			onSelect={handleMatchSelect}
		/>
	);
}
