import { ReactNode } from 'react';

export const symbol_line_end_diamond: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m600-285 195-195-195-195-195 195 195 195Zm-21 64L350-450H110q-13 0-21.5-8.5T80-480q0-13 8.5-21.5T110-510h240l229-229q9-9 21-9t21 9l238 238q9 9 9 21t-9 21L621-221q-9 9-21 9t-21-9Zm21-259Z"
		/>
	</svg>
);
