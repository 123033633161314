import { ReactNode } from 'react';

export const symbol_beach_access: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M771-151 554-368q-10-10-10-23t10-23q10-10 23-10t23 10l217 217q10 10 10 23t-10 23q-10 10-23 10t-23-10Zm-572-54q-40-54-59.5-116T120-446q0-78 29-152t88-133q59-59 133.5-88T523-848q63 0 124.5 19T763-770q13 10 14 26t-11 28L252-202q-12 12-27.5 11T199-205Zm28-58 69-70q-16-22-32-47.5t-29.5-54Q221-463 212-493.5T200-556q-27 74-18.5 151T227-263Zm117-115 246-248q-46-36-93.5-56.5t-90.5-27q-43-6.5-76.5 0T278-685q-18 18-23 51.5t2.5 75q7.5 41.5 29 88T344-378Zm363-363q-66-42-143.5-49T412-768q30 3 59.5 12t58 21.5q28.5 12.5 55 28.5t50.5 34l72-69Z"
		/>
	</svg>
);
