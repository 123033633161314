import { t } from '@transifex/native';

import { ContactsWithoutGroupsData } from 'pkg/api/dashboards/management';

import * as DataCard from 'design/data_card';

interface ContactsWithoutGroupsWidgetProps {
	data: ContactsWithoutGroupsData;
}

export default function ContactsWithoutGroupsWidget({
	data,
}: ContactsWithoutGroupsWidgetProps) {
	const breakdownItems: DataCard.BreakdownItem[] = [
		{
			title: t('Inactivated'),
			value: data.inactivatedCount.toString(),
		},
	];

	return (
		<DataCard.Base
			title={t('Contacts without groups')}
			titleIcon="linked_services"
			heading={data.totalCount.toString()}
			headingSuffix={t('Total amount')}>
			<DataCard.Breakdown items={breakdownItems} />
		</DataCard.Base>
	);
}
