import { ReactNode } from 'react';

export const symbol_railway_alert: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-724h299-299Zm34 644q-7.906 0-13.297-4.6-5.391-4.6-7.547-11.5Q151-103 153-109.5t9-11.5l58-39q-58 0-99-41t-41-99v-398q0-71 70.5-101.5T407-835q13.175 0 22.087 8Q438-819 438-805.877q0 13.123-8.289 22Q421.421-775 408-775q-136 2-195 13.5T140-724h255q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T395-664H140v173h431q13.077 0 21.538 8.675 8.462 8.676 8.462 21.5 0 12.825-8.625 21.325T571-431H140v131q0 33.333 23.333 56.667Q186.667-220 220-220h360q33.333 0 56.667-23.333Q660-266.667 660-300v-93q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T720-393v93q0 58-41 99t-99 41l58 39q7 5 9 11.5t-.156 13.4q-2.156 6.9-7.547 11.5T626-80H174Zm515.187-418Q610-498 554-554.011q-56-56.01-56-135.478Q498-768 554-824t135-56q80 0 135.5 55.5T880-689q0 80-55.813 135.5-55.812 55.5-135 55.5ZM692-659q8 0 12.5-5t4.5-13v-108q0-8-5-12.5t-13-4.5q-8 0-12.5 5t-4.5 13v108q0 8 5 12.5t13 4.5Zm-.5 85q9.5 0 16-6t6.5-16q0-10-6.6-16t-15.4-6q-11 1-16.5 7t-5.5 15.5q0 9.5 6 15.5t15.5 6ZM400.059-272Q423-272 439-288.059q16-16.059 16-39T438.941-366q-16.059-16-39-16T361-365.941q-16 16.059-16 39T361.059-288q16.059 16 39 16ZM660-431H140h520Z"
		/>
	</svg>
);
