import { Record } from 'immutable';
import { schema } from 'normalizr';

import Price from 'pkg/models/price';
import Product from 'pkg/models/product';
import { forms } from 'pkg/models/schemas';
import TaxRate from 'pkg/models/tax_rates';

import * as models from 'pkg/api/models';

export enum Visibilities {
	Unlisted = 'unlisted',
	Public = 'public',
	Organization = 'organization',
}

export enum Statuses {
	Draft = 'draft',
	Open = 'open',
	Closed = 'closed',
}

export interface Field {
	key: string;
	type: string;
	label: string;
	values?: { value: string; label: string }[];
	sortOrder?: number;
	required?: boolean;
}

export interface FormProductPrice {
	productPriceId?: number;
	taxRateId?: number;

	productPrice: Price;
	taxRate?: TaxRate;
	product: Product;

	minQuantity?: number;
	maxQuantity?: number;
	required?: boolean;
	validTo?: number;

	installmentCount?: number;
	bundleNumber?: number;
}
export interface IForm {
	id: number;
	groupId: number;
	formCategoryId: number;
	title: string;
	description: string;
	visibility: Visibilities;
	status: Statuses;
	maxSubmissions: number;
	fields: Field[];
	productPrices: FormProductPrice[];
	publicUrl: string;
	attachmentId: number;
	attachment?: models.attachment.Attachment;
	submissionEmailEnabled: boolean;
	submissionEmailSubject?: string;
	submissionEmailContent?: string;
}

const formProps: IForm = {
	id: 0,
	groupId: 0,
	formCategoryId: 0,
	title: '',
	description: '',
	publicUrl: '',
	visibility: Visibilities.Unlisted,
	status: Statuses.Draft,
	maxSubmissions: 0,
	attachmentId: 0,
	fields: [],
	productPrices: [],
	submissionEmailEnabled: true,
	submissionEmailSubject: '',
	submissionEmailContent: '',
};

class Form extends Record(formProps, 'FormRecord') implements IForm {
	static normalizr(): schema.Entity {
		return forms;
	}
}

export default Form;
