import { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import { findAllUserPerformanceReviews } from 'pkg/selectors/performance_reviews';

import { fetchGroup } from 'pkg/actions/groups';
import {
	fetchPerformanceReviews,
	deletePerformanceReview,
	updatePerformanceReview,
	savePerformanceReview,
} from 'pkg/actions/performance_review';

import { IdentityContext } from 'pkg/identity';
import * as models from 'pkg/api/models';
import { tlog } from 'pkg/tlog';

import CardBase, * as Card from 'components/Card';
import RelativeTime from 'components/RelativeDateTime';
import { FormattedContent } from 'components/formatted-content';
import Icon from 'components/icon';
import Modal from 'components/modal';

import { PlaceholderType, Skeleton } from 'components/loaders/skeleton';
import TextArea from 'components/form/TextArea';
import Label from 'components/form/Label';
import { ButtonTrigger } from 'components/navigation/header/small_screen/page_actions/ButtonTrigger';

import * as ContextMenu from 'design/context_menu';
import NoDialogue from 'design/placeholders/inline';
import Button from 'design/button';

const DialougeGrid = styled.div`
	display: grid;
	grid-row-gap: var(--spacing-5);
`;

const DialogueBody = styled.div`
	padding: var(--spacing-5) 0 0;
	margin-bottom: var(--spacing-5);

	@media ${styles.breakpoint.small} {
		padding: 0;
		border-radius: var(--radius-2);
	}
`;

const Dialogues = styled.div`
	@media ${styles.breakpoint.small} {
		background: transparent;
		padding: 0;
	}
`;

const DialoguesHeader = styled.div`
	display: flex;
	justify-content: space-between;

	@media ${styles.breakpoint.small} {
		display: none;
	}
`;

const ReviewUpdated = styled.h4`
	font-size: var(--font-size-sm);
	font-weight: var(--font-weight-normal);
	color: rgba(179, 184, 189, 1);
`;

const EditModal = ({
	editingReview,
	isSaving,
	error,
	onSave,
	onClose,
	onInputChange,
}) => (
	<Modal open onClose={onClose}>
		<CardBase>
			<Card.Header>
				<Card.Heading>
					{editingReview.id
						? t(`Edit player dialogue`)
						: t(`Create new player dialogue`)}
				</Card.Heading>
			</Card.Header>

			<Card.Divider />

			<Card.Body $flex>
				<Label>{t(`Player dialogue`)}</Label>
				<TextArea
					value={editingReview.performanceReview}
					onChange={onInputChange}
					minRows={6}
					data-testid="performance_review.textarea"
				/>
			</Card.Body>

			{error}

			<Card.Footer>
				<Button
					large
					primary
					disabled={isSaving}
					onClick={onSave}
					testid="step_modal.next">
					{t('Save')}
				</Button>
			</Card.Footer>
		</CardBase>
	</Modal>
);

const SingleDialogue = ({ dialogue, deleteDialogue, setActiveDialogue }) => (
	<CardBase>
		<Card.Header centered>
			<Card.Heading>
				<RelativeTime dateTime={dialogue.createdAt} />
			</Card.Heading>
			<ReviewUpdated>
				<Icon name="edit" /> <RelativeTime dateTime={dialogue.updatedAt} />
			</ReviewUpdated>
		</Card.Header>

		<Card.Divider />

		<Card.Body>
			<FormattedContent raw={dialogue.performanceReview} />
		</Card.Body>

		<Card.Footer>
			{dialogue.links?.delete ? (
				<Button
					icon="delete"
					caution
					onClick={(e) => deleteDialogue(dialogue, e)}
					testid="performance_review.delete_button">
					{t('Delete')}
				</Button>
			) : null}

			<Button
				icon="edit"
				onClick={(e) => setActiveDialogue(dialogue.toJS(), e)}>
				{t('Edit')}
			</Button>
		</Card.Footer>
	</CardBase>
);

class Dialogue extends Component {
	static contextType = IdentityContext;

	_mounted = false;

	state = {
		editingReview: {},
		isSaving: '',
		saveError: false,
		showFormModal: false,
		loaded: false,
		isLoading: false,
	};

	async componentDidMount() {
		this._mounted = true;

		this.props.setPageActions(this.getPageActions(true));

		this._scrollContainer = document.querySelector('.page-scrollable');
		this._scrollContainer.addEventListener('scroll', this.handleScroll, {
			passive: true,
			capture: false,
		});

		const { groupId } = this.props;
		await this.fetchData(groupId);

		if (this._mounted) {
			this.setState({ loaded: true });
		}
	}

	componentWillUnmount() {
		this._mounted = false;
		this._scrollContainer.removeEventListener('scroll', this.handleScroll);
	}

	handleScroll = async () => {
		const scrollBarrier =
			this._scrollContainer.scrollHeight > 1000
				? this._scrollContainer.scrollHeight - 350
				: this._scrollContainer.scrollHeight - 100;

		const scrollTop =
			this._scrollContainer.getBoundingClientRect().height +
			this._scrollContainer.scrollTop;

		if (
			scrollTop >= scrollBarrier &&
			!this.state.isLoading &&
			this.props.links.next
		) {
			this.setState({ isLoading: true });
			await this.fetchMoreDialogues();
			this.setState({ isLoading: false });
		}
	};

	async fetchData(groupId) {
		await fetchGroup(groupId);
		await this.props.fetchPerformanceReviews(
			this.props.userId,
			groupId,
			false,
			this.context.membership.targetUser?.accountId
		);
	}

	fetchMoreDialogues = async () => {
		await this.props.fetchPerformanceReviews(
			this.props.userId,
			this.props.groupId,
			true,
			this.context.membership.targetUser?.accountId
		);
	};

	async componentDidUpdate(prevProps) {
		if (prevProps.groupId !== this.props.groupId) {
			await this.fetchData(this.props.groupId);
		}

		if (
			prevProps.groupId !== this.props.groupId ||
			prevProps.userId !== this.props.userId
		) {
			this.props.setPageActions(this.getPageActions(true));
		}
	}

	deleteDialogue = async (dialogue, event) => {
		event?.preventDefault();

		if (
			!confirm(
				t('Do you want to delete this?', { _context: 'confirm messages' })
			)
		) {
			return;
		}
		this.props.deletePerformanceReview(
			dialogue.id,
			this.context.membership.targetUser?.accountId
		);
	};

	setActiveDialogue = (editingReview, event) => {
		if (event?.preventDefault) {
			event?.preventDefault();
		}

		this.setState({ editingReview });
	};

	get reviews() {
		return this.props.performanceReviews.map((dialogue) => (
			<SingleDialogue
				key={`dialogue-${dialogue.id}`}
				dialogue={dialogue}
				deleteDialogue={this.deleteDialogue}
				setActiveDialogue={this.setActiveDialogue}
			/>
		));
	}

	get template() {
		return models.group.getPerformanceReviewTemplate(
			this.context.membership.group
		);
	}

	async save(payload, event) {
		event.preventDefault();
		let saved = false;
		this.setState({ isSaving: true });

		try {
			if (payload.id) {
				await this.props.updatePerformanceReview(
					payload,
					this.context.membership.targetUser?.accountId
				);
				saved = true;
			} else {
				payload.groupId = Number.parseInt(this.props.groupId, 10);
				saved = await this.props.savePerformanceReview(
					payload,
					this.context.membership.targetUser?.accountId
				);
			}
		} catch (e) {
			tlog.error(e);
			this.setState({ isSaving: false, saveError: true });
			return;
		}

		const newState = { isSaving: false };

		if (saved) {
			newState.editingReview = {};
		}

		this.setState(newState);
	}

	handleInput = (e) => {
		let { editingReview } = this.state;

		editingReview = { ...editingReview, performanceReview: e.target.value };

		this.setState({ editingReview });
	};

	get formModal() {
		if (Object.keys(this.state.editingReview).length > 1) {
			return (
				<EditModal
					key="form-modal"
					editingReview={this.state.editingReview}
					isSaving={this.state.isSaving}
					error={this.state.saveError}
					setActiveReview={this.setActiveDialogue}
					onSave={this.save.bind(this, this.state.editingReview)}
					onClose={(e) => this.setActiveDialogue({}, e)}
					onInputChange={this.handleInput}
				/>
			);
		}

		return null;
	}

	get dialogues() {
		return this.props.performanceReviews.filter(
			(dialogue) => +dialogue.groupId === +this.props.groupId
		);
	}

	get dialoguesBody() {
		if (this.props.performanceReviews?.size > 0 && this.state.loaded) {
			return [
				<DialogueBody key="dialogue-body">
					<DialougeGrid>{this.reviews}</DialougeGrid>
				</DialogueBody>,
				this.props.links.next && (
					<Button key="load-more-logs" onClick={this.fetchMoreDialogues}>
						{t('Load more')}
					</Button>
				),
			];
		} else if (this.state.loaded) {
			return (
				<div>
					<NoDialogue>
						<Icon name="nav-dialogue" />
						<p>{t(`No Dialogues`)}</p>
					</NoDialogue>
				</div>
			);
		}

		return <Skeleton placeholderType={PlaceholderType.Card} numItems={3} />;
	}

	newDialogue = (e) => {
		this.setActiveDialogue(
			{
				userId: this.props.userId,
				groupId: this.props.groupId,
			},
			e
		);
	};

	newDialogueFromTemplate = (e) => {
		this.setActiveDialogue(
			{
				userId: this.props.userId,
				groupId: this.props.groupId,
				performanceReview: this.template,
			},
			e
		);
	};

	getPageActions(asContextMenu) {
		if (asContextMenu) {
			const items = [];

			if (this.template.length > 0) {
				items.push(
					<ContextMenu.LinkItem
						key="new-from-template"
						onClick={this.newDialogueFromTemplate}>
						<ContextMenu.ItemIcon name="add" />
						{t('Use template')}
					</ContextMenu.LinkItem>
				);
			}

			items.push(
				<ContextMenu.Item key="new-from-scratch" onClick={this.newDialogue}>
					<ContextMenu.ItemIcon name="add" />
					{t('New blank dialogue')}
				</ContextMenu.Item>
			);

			return (
				<ContextMenu.Menu toggleWith={<ButtonTrigger icon="add" />}>
					{items}
				</ContextMenu.Menu>
			);
		}

		return [
			<Button
				icon="add"
				key="new-from-scratch"
				onClick={this.newDialogue}
				testid="performance_review.new_blank_button">
				{t('New blank dialogue')}
			</Button>,
			<Button
				icon="add"
				disabled={this.template.length === 0}
				key="new-from-template"
				onClick={this.newDialogueFromTemplate}
				testid="performance_review.use_template_button">
				{t('Use template')}
			</Button>,
		];
	}

	render() {
		return [
			<Dialogues>
				<DialoguesHeader centered>{this.getPageActions(false)}</DialoguesHeader>
				{this.dialoguesBody}
			</Dialogues>,
			this.formModal,
		];
	}
}

const mapStateToProps = () => {
	return (state, props) => ({
		performanceReviews: findAllUserPerformanceReviews(state, {
			userId: props.userId,
		}).toList(),
		links: state.performanceReviews.links,
	});
};

const actions = {
	fetchPerformanceReviews,
	deletePerformanceReview,
	updatePerformanceReview,
	savePerformanceReview,
};

export default connect(mapStateToProps, actions)(Dialogue);
