import { ReactNode } from 'react';

export const symbol_humidity_mid: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-100q-133 0-226.5-91.709T160-415q0-63.142 24.5-120.771Q209-593.401 254-637.5L480-860l226 222.5q45 44.099 69.5 101.729Q800-478.142 800-415q0 131.582-93.5 223.291T480-100ZM220-415h520q0-51-19.5-97T666-593L480-776 294-593q-35 35-54.5 81.042Q220-465.917 220-415Z"
		/>
	</svg>
);
