import { ReactNode } from 'react';

export const symbol_align_flex_center: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M489.825-80Q477-80 468.5-88.625T460-110v-320H140q-12.75 0-21.375-8.625T110-460v-40q0-12.75 8.625-21.375T140-530h320v-320q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T520-850v320h300q12.75 0 21.375 8.625T850-500v40q0 12.75-8.625 21.375T820-430H520v320q0 12.75-8.675 21.375Q502.649-80 489.825-80Z"
		/>
	</svg>
);
