import { ReactNode } from 'react';

export const symbol_match_word: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M100-199q-25 0-42.5-17.5T40-259v-100q0-13 8.5-21.5T70-389q13 0 21.5 8.5T100-359v100h760v-100q0-13 8.5-21.5T890-389q13 0 21.5 8.5T920-359v100q0 25-17.5 42.5T860-199H100Zm195-158q-43 0-69-23.5T200-443q0-43 30.5-69.5T312-539q20 0 39.5 4t33.5 12v-20q0-35-19-54t-54-19q-17 0-32 4.5T252-598q-7 5-15 5t-14-5q-6-5-6-13t6-13q19-15 40.5-22t48.5-7q56 0 85 28.5t29 85.5v158q0 8-6 14t-14 6q-8 0-14-6t-6-14v-23h-4q-14 23-36 35t-51 12Zm23-147q-35 0-55.5 16T242-444q0 24 15 38t41 14q38 0 63-27.5t25-68.5q-12-8-30.5-12t-37.5-4Zm319 147q-29 0-53.5-12T545-401h-2v20q0 8-6 14t-14 6q-8 0-14-6t-6-14v-360q0-8 6-14t14-6q8 0 14 6t6 14v113l-3 40h2q13-20 39-33t56-13q51 0 87.5 39t36.5 99q0 60-36 99.5T637-357Zm-8-241q-38 0-63.5 29.5T540-495q0 44 25.5 72.5T629-394q38 0 64-29t26-73q0-44-26-73t-64-29Z"
		/>
	</svg>
);
