import { ReactNode } from 'react';

export const symbol_contrast_square: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-60h600v-600L180-180Zm120-430v51q0 10.833 7.116 17.917 7.117 7.083 18 7.083Q336-534 343-541.083q7-7.084 7-17.917v-51h51q10.833 0 17.917-7.116 7.083-7.117 7.083-18Q426-646 418.917-653q-7.084-7-17.917-7h-51v-51q0-10.833-7.116-17.917-7.117-7.083-18-7.083Q314-736 307-728.917q-7 7.084-7 17.917v51h-51q-10.833 0-17.917 7.116-7.083 7.117-7.083 18Q224-624 231.083-617q7.084 7 17.917 7h51Zm395 300H519q-10.833 0-17.917-7.116-7.083-7.117-7.083-18Q494-346 501.083-353q7.084-7 17.917-7h176q10.833 0 17.917 7.116 7.083 7.117 7.083 18Q720-324 712.917-317q-7.084 7-17.917 7Z"
		/>
	</svg>
);
