import * as React from 'react';

import useTooltip from 'pkg/hooks/useTooltip';
import { cssClasses } from 'pkg/css/utils';

import library, { IconObject } from 'assets/icons';

import * as css from './styles.css';

const Paths = ({ paths }: { paths: string[] }): JSX.Element => (
	<React.Fragment>
		{paths.map((definition: string, index: number) => (
			<path fill="currentColor" d={definition} key={index} />
		))}
	</React.Fragment>
);

const ObjectPaths = ({ objects }: { objects: IconObject[] }): JSX.Element => (
	<React.Fragment>
		{objects.map((object: IconObject, index: number) => (
			<object.type fill="currentColor" key={index} {...object.attributes} />
		))}
	</React.Fragment>
);

// Names of all icons. When adding or removing an icon, update this type.
// The complete type can be copied from the developer console in a web browser.
export type IconName =
	| 'add'
	| 'add-conversation'
	| 'add-folder'
	| 'add-post'
	| 'add-reaction'
	| 'age'
	| 'apple'
	| 'archive'
	| 'area'
	| 'arrow-down'
	| 'arrow-right'
	| 'arrow-up'
	| 'at'
	| 'attachment'
	| 'avatar-upload'
	| 'av-jump-next'
	| 'av-jump-prev'
	| 'av-pause'
	| 'av-play'
	| 'av-playback-speed'
	| 'av-play-clips'
	| 'av-record'
	| 'av-repeat'
	| 'av-replay'
	| 'av-skip-backwards'
	| 'av-skip-forwards'
	| 'av-step-next'
	| 'av-step-prev'
	| 'av-stop'
	| 'av-theatre-mode'
	| 'nav-badge'
	| 'block'
	| 'bus'
	| 'calendar-clean'
	| 'calendar-continue'
	| 'camera'
	| 'card'
	| 'cards'
	| 'caret-down'
	| 'caret-up'
	| 'check'
	| 'checkbox-checked'
	| 'checkbox-checked-outlined'
	| 'checkbox-unchecked'
	| 'check-circle'
	| 'check-filled'
	| 'chevron'
	| 'chevron-down'
	| 'clapperboard'
	| 'clip-annotations'
	| 'clip-author'
	| 'clip-user-tag'
	| 'clock'
	| 'close'
	| 'close-circle'
	| 'close-circle-filled'
	| 'coach'
	| 'collapse'
	| 'collapse-reverse'
	| 'collection'
	| 'color-picker'
	| 'comment'
	| 'compare'
	| 'cone'
	| 'connect'
	| 'contact-card'
	| 'context-menu'
	| 'conversations'
	| 'create'
	| 'cube'
	| 'customize-content'
	| 'database'
	| 'data-connection'
	| 'delete'
	| 'download'
	| 'download-simple'
	| 'drag-handle'
	| 'drawing-arrow'
	| 'drawing-arrow-dashed'
	| 'drawing-circle'
	| 'drawing-circle-dashed'
	| 'drawing-freehand'
	| 'drawing-line'
	| 'drawing-line-dashed'
	| 'drawing-polygon'
	| 'drawing-polygon-dashed'
	| 'drawing-rectangle'
	| 'drawing-rectangle-dashed'
	| 'drawing-squiggly-arrow'
	| 'drawing-squiggly-line'
	| 'drawing-text'
	| 'dressingroom'
	| 'duplicate'
	| 'duration'
	| 'discount'
	| 'edit'
	| 'error'
	| 'eye-hidden'
	| 'eye-visible'
	| 'facebook'
	| 'field'
	| 'fields'
	| 'file-csv'
	| 'file-generic'
	| 'file-image'
	| 'file-pdf'
	| 'filter'
	| 'flash'
	| 'folder-list'
	| 'football'
	| 'fresh'
	| 'fullscreen'
	| 'fullscreen-close'
	| 'google-maps'
	| 'group-move'
	| 'help-circle'
	| 'help-circle-filled'
	| 'history'
	| 'hourglass'
	| 'image-rotate'
	| 'info-circle'
	| 'intercom-help'
	| 'key'
	| 'language'
	| 'leave'
	| 'library'
	| 'link'
	| 'list-clear'
	| 'list'
	| 'location'
	| 'lock'
	| 'lock-open'
	| 'logo'
	| 'long-text'
	| 'magnify-minus'
	| 'magnify-plus'
	| 'mail'
	| 'mark-all-complete'
	| 'menu'
	| 'merge'
	| 'mute'
	| 'nav-account-settings'
	| 'nav-activity'
	| 'nav-assess'
	| 'nav-attendance'
	| 'nav-back'
	| 'nav-dialogue'
	| 'nav-events'
	| 'nav-formation'
	| 'nav-group'
	| 'nav-home'
	| 'nav-logout'
	| 'nav-members'
	| 'nav-menu'
	| 'nav-privacy-settings'
	| 'nav-profile'
	| 'nav-profile-settings'
	| 'nav-settings'
	| 'new-window'
	| 'note'
	| 'notification'
	| 'notification-outline'
	| 'number'
	| 'order'
	| 'nav-organisation'
	| 'overlap'
	| 'parent'
	| 'nav-payments'
	| 'person'
	| 'pin'
	| 'plane'
	| 'player'
	| 'playlist-add'
	| 'print'
	| 'radio-checked'
	| 'radio-unchecked'
	| 'redo'
	| 'registration'
	| 'reload'
	| 'remove'
	| 'reply'
	| 'resource'
	| 'rotate-left'
	| 'rotate-right'
	| 'scissors'
	| 'search'
	| 'select-trigger'
	| 'share'
	| 'shirt'
	| 'shoe'
	| 'short-text'
	| 'single-choice'
	| 'sort'
	| 'star'
	| 'star-hollow'
	| 'statistics'
	| 'stopwatch'
	| 'stripe'
	| 'supervisor'
	| 'sync'
	| 'tag'
	| 'task-complete'
	| 'task-incomplete'
	| 'thumbs-up'
	| 'timer'
	| 'timer-add'
	| 'track-changes'
	| 'training'
	| 'tree-view'
	| 'trending'
	| 'trending-down'
	| 'trending-flat'
	| 'trophy'
	| 'unfold'
	| 'unlink'
	| 'unmark-all-complete'
	| 'upload'
	| 'user'
	| 'user-add'
	| 'user-checked'
	| 'user-edit'
	| 'video-analyze'
	| 'video-camera'
	| 'video-placeholder'
	| 'video-playlist'
	| 'videos'
	| 'volume-high'
	| 'volume-low'
	| 'volume-off'
	| 'wall'
	| 'warning-circle'
	| 'whistle';

export interface IconProps {
	name: IconName;
	className?: string;
	onClick?: () => void;
	disabled?: boolean;
	style?: React.CSSProperties;
	fill?: string;
	rotate?: string;
	size?: number;
	// Set this property to scale with em instead of transform, meaning scaling will
	// change the actual bounding box.
	actualSize?: boolean;
	tooltip?: string;
}

const Icon = (props: IconProps) => {
	const { name, className, onClick, style, disabled } = props;
	const icon = library.find((i) => i.name === name);

	const { onMouseEnter, tooltip } = useTooltip(props.tooltip);

	if (!icon) {
		if (icon !== undefined) {
			console.warn(`Missing icon definition icon ${name}.`);
		}

		return null;
	}

	const viewBox = icon.viewBox ? icon.viewBox : '0 0 24 24';

	const svgProps: Record<string, unknown> = {
		className,
		viewBox,
		style,
		'aria-hidden': true,
		role: 'img',
		xmlns: 'https://www.w3.org/2000/svg',
	};

	if (!disabled) {
		svgProps.onClick = onClick;
	}

	Object.entries(props).forEach(([attr, value]) => {
		if (attr.startsWith('data-')) {
			svgProps[attr] = value;
		}
	});

	return (
		<React.Fragment>
			{tooltip}
			<svg
				{...svgProps}
				onMouseEnter={onMouseEnter}
				className={cssClasses(
					css.icon,
					props.className,
					props.hasOwnProperty('onClick') ? css['has-click'] : '',
					props.actualSize ? css['actual-size'] : ''
				)}
				style={
					{
						'--fill-color': props.fill,
						'--rotate': props.rotate,
						'--size': props.actualSize ? `${props.size}em` : props.size,
					} as React.CSSProperties
				}>
				{icon.paths && <Paths paths={icon.paths} />}
				{icon.objects && <ObjectPaths objects={icon.objects} />}
			</svg>
		</React.Fragment>
	);
};

export default Icon;
