import { ReactNode } from 'react';

export const symbol_hearing_disabled: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m732-314-43-43q45-53 68-117.5T780-608q0-69-21-130.5T697-850q-8-10-7.5-22.5T699-893q10-9 23-8t21 12q47 57 72 129.5T840-608q0 81-27.5 156T732-314ZM622-424l-46-46q17-29 25.5-61.5T610-604q0-90-61-151.5T398-817q-37 0-69.5 10T268-778l-43-43q36-27 80-41.5t93-14.5q120 0 196 76.5T670-604q0 56-11.5 99.5T622-424ZM482-564 357-689q10-5 20-7.5t21-2.5q39 0 66 28t27 67q0 11-2 21t-7 19Zm-86 53q-39 0-66-27t-27-66q0-11 2.5-22t7.5-21l126 126q-10 5-21 7.5t-22 2.5ZM812-63 537-337q-17 14-33.5 29.5T473-274q-14 18-23 38.5T433-194q-20 53-63 83t-98 30q-60 0-103.5-41T121-224q0-13 8.5-21.5T151-254q13 0 21.5 8.5T181-224q4 36 30.5 59.5T272-141q36 0 63.5-22.5T382-231q10-23 21.5-45t26.5-42q14-18 29.5-33.5T493-381L193-681q-5 15-8.5 31.5T180-616q0 13-8.5 21.5T150-586q-13 0-21.5-8.5T120-616q2-30 8.5-58t18.5-53L47-827q-9-9-9-21.5t9-21.5q9-9 21.5-9t21.5 9l765 765q9 9 9 21t-9 21q-9 9-21.5 9T812-63Z"
		/>
	</svg>
);
