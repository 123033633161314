import { ReactNode } from 'react';

export const symbol_perm_device_information: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M479.825-298Q467-298 458.5-306.625T450-328v-196q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-524v196q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Zm.193-300Q466-598 456.5-607.482q-9.5-9.483-9.5-23.5 0-14.018 9.2-23.518T479-664q14.45 0 24.225 9.482 9.775 9.483 9.775 23.5 0 14.018-9.482 23.518-9.483 9.5-23.5 9.5ZM260-40q-24 0-42-18t-18-42v-760q0-24 18-42t42-18h440q24 0 42 18t18 42v760q0 24-18 42t-42 18H260Zm0-90v30h440v-30H260Zm0-60h440v-580H260v580Zm0-640h440v-30H260v30Zm0 0v-30 30Zm0 700v30-30Z"
		/>
	</svg>
);
