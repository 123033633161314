import { Fragment, MouseEvent, createContext, useContext } from 'react';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import { CollectionResponse, useCollection } from 'pkg/api/use_collection';
import * as endpoints from 'pkg/api/endpoints/auto';

import { useEventContext } from 'routes/event/single';
import EventCommentForm from 'routes/event/single/comments/form';
import EventComment from 'routes/event/single/comments/single';

import { CommentLink } from 'components/Links';
import FeedItem, { Comments as CommentList } from 'components/FeedItem';

export interface CommentContextProps {
	commentsCollectionResponse: CollectionResponse<models.eventComment.EventComment>;
}

const CommentCtx = createContext<CommentContextProps>(null);

export function useCommentsContext() {
	return useContext(CommentCtx);
}

interface CommentsProps {
	user: models.user.User;
	event: models.event.Event;
}

export default function Comments({ event, user }: CommentsProps) {
	const commentsCollectionResponse =
		useCollection<models.eventComment.EventComment>(
			endpoints.Events.ShowComments(event.id),
			{ showAllResults: true }
		);

	const eventCtx = useEventContext();
	const comments = commentsCollectionResponse.records.sort(
		(a, b) => b.createdAt - a.createdAt
	);

	const hasNextPage = commentsCollectionResponse.pagination.hasNext;

	const onDeleteComment = (comment: models.eventComment.EventComment) => {
		commentsCollectionResponse.removeRecord(comment.id);
	};

	const getNextCommentPage = async (e: MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();

		commentsCollectionResponse.pagination.fetchNext();
	};

	const renderPager = () => {
		if (!hasNextPage) {
			return null;
		}

		return (
			<FeedItem>
				<CommentLink onClick={getNextCommentPage}>
					{t('View previous comments')}
				</CommentLink>
			</FeedItem>
		);
	};

	if (commentsCollectionResponse.isLoading) {
		return null;
	}

	return (
		<CommentCtx.Provider value={{ commentsCollectionResponse }}>
			<div>
				<EventCommentForm eventId={event.id} user={user} />
				{comments.length > 0 && (
					<Fragment>
						<CommentList reverseMargin moreLink={hasNextPage}>
							{comments.map((comment) => {
								return (
									<EventComment
										key={comment.id}
										comment={comment}
										groupMemberships={eventCtx.membershipsCollection.records}
										onDeleteComment={onDeleteComment}
									/>
								);
							})}
						</CommentList>
						{renderPager()}
					</Fragment>
				)}
			</div>
		</CommentCtx.Provider>
	);
}
