import { ReactNode } from 'react';

export const symbol_ad_group_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M880-820v589q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T820-231v-529H297q-12.444 0-23.722-5T254-778l-53-53q4-21 20.5-35t38.5-14h560q24.75 0 42.375 17.625T880-820ZM260-200q-24.75 0-42.375-17.625T200-260v-403L49.97-813.031Q41-822 41-834q0-12 9-21t21-9q12 0 21 9L855-92q9 9 9 21t-9 21q-9 9-21 9t-20.969-8.97L663-200H260Zm0-60h343L260-603v343ZM140-80q-24.75 0-42.375-17.625T80-140v-590q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-730v590h590q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q760-97 751.375-88.5T730-80H140Zm293-349Zm107-87Z"
		/>
	</svg>
);
