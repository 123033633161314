import { ReactNode } from 'react';

export const symbol_comment_bank: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M240-240 131-131q-14 14-32.5 6.344T80-152v-668q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H240Zm580-60v-520H716v248q0 8-7 13t-15 1l-64-34q-5.455-2-10.227-2-4.773 0-9.773 2l-65 34q-8 4-15-1t-7-13v-248H140v600l74-80h606Zm-680 0v-520 520Z"
		/>
	</svg>
);
