import { ReactNode } from 'react';

export const symbol_emergency_recording: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M370-428v108q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T430-320v-108l101 58q11 6 22.667 2.889 11.666-3.111 17.5-14Q577-392 574-404t-14-18l-100-58 100-58q11-6 14-18t-2.833-22.889q-5.834-10.889-17.5-14Q542-596 531-590l-101 58v-108q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T370-640v108l-101-58q-11-6-22.667-2.889-11.666 3.111-17.5 14Q223-568 226-556t14 18l100 58-100 58q-11 6-14 18t2.833 22.889q5.834 10.889 17.5 14Q258-364 269-370l101-58ZM140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h520q24 0 42 18t18 42v215l134-134q7-7 16.5-3.458Q880-658.917 880-649v338q0 9.917-9.5 13.458Q861-294 854-301L720-435v215q0 24-18 42t-42 18H140Zm0-60h520v-520H140v520Zm0 0v-520 520Z"
		/>
	</svg>
);
