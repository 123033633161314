import { ReactNode } from 'react';

export const symbol_question_mark: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M593-646.625Q593-695 561.76-723.5 530.519-752 479-752q-31.806 0-57.581 14.031Q395.645-723.939 377-697q-11 16-29.5 20t-35.5-5q-19-11-23-27t8-35q30-46 77.62-71 47.621-25 104.38-25 94 0 151 52.236T687-651q0 46-20 85t-66 84q-37 35-50 55.719T534-379q-3 21-18 35t-34.797 14q-19.798 0-34-13.5Q433-357 433-375q0-34 18-66t55.363-66.222Q554-550 573.5-582q19.5-32 19.5-64.625ZM478.911-80Q450-80 429.5-100.589q-20.5-20.588-20.5-49.5Q409-179 429.589-199.5q20.588-20.5 49.5-20.5Q508-220 528.5-199.411q20.5 20.588 20.5 49.5Q549-121 528.411-100.5 507.823-80 478.911-80Z"
		/>
	</svg>
);
