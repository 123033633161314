import { ReactNode } from 'react';

export const symbol_vaccines: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M250-290h-60q-24 0-42-18t-18-42v-320h-10q-12.75 0-21.375-8.675Q90-687.351 90-700.175 90-713 98.625-721.5T120-730h130v-90h-30q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T220-880h120q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T340-820h-30v90h130q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T440-670h-10v320q0 24-18 42t-42 18h-60v200q0 12.75-8.675 21.375Q292.649-60 279.825-60 267-60 258.5-68.625T250-90v-200Zm-60-60h180v-80h-80q-8 0-14-6t-6-14q0-8 6-14t14-6h80v-80h-80q-8 0-14-6t-6-14q0-8 6-14t14-6h80v-80H190v320ZM600-80q-24 0-42-18t-18-42v-266q0-32 8-48.5t21-30.5q19-20 25-31t6-24v-30h-10q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 9-21.325t21-8.5h200q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T790-570h-10v30q0 12 7.5 25t26.5 33q13 14 19.5 29t6.5 47v266q0 24-18 42t-42 18H600Zm0-300h180v-26q0-18-10-32t-22-29q-15-19-21.5-35t-6.5-38v-30h-60v30q0 21-6 37t-21 35q-12 15-22.5 29.5T600-406v26Zm0 120h180v-80H600v80Zm0 120h180v-80H600v80Zm0-120h180-180Z"
		/>
	</svg>
);
