import styled, { css } from 'styled-components';

import * as models from 'pkg/api/models';

import Icon from 'components/icon';
import Avatar from 'components/avatar';

import * as iconStyles from 'components/icon/styles.css';

const Item = styled.li<{ selected: boolean }>`
	display: grid;
	grid-gap: 1rem;
	grid-template-columns: 2rem auto 1.4rem;
	padding: var(--spacing-3) var(--spacing-4);
	align-items: center;
	position: relative;
	user-select: none;
	cursor: pointer;
	border-bottom: 1px solid var(--palette-gray-300);

	&:last-of-type {
		border: none;
	}

	@media (hover: hover) {
		&:hover {
			background: var(--palette-gray-100);
		}
	}

	${({ theme }) =>
		theme.darkMode &&
		css`
			@media (hover: hover) {
				&:hover {
					background: var(--palette-gray-700);
				}
			}
		`}

	${(props) =>
		props.selected &&
		css`
			font-weight: var(--font-weight-semibold);
		`}
`;

const ListAvatar = styled(Avatar)`
	width: 2rem;
	height: 2rem;
`;

const ListItemStatus = styled.div<{ selected: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: inset 0 0 0 2px var(--palette-gray-400);
	border-radius: 100rem;
	transition: all 250ms ease-in-out;

	.${iconStyles.icon} {
		width: 100%;
		height: 100%;
		color: var(--palette-blue-500);
		opacity: 0;
		transition: opacity 250ms ease-in-out;
	}

	${(props) =>
		props.selected &&
		css`
			background: var(--palette-white);
			color: var(--palette-blue-500);
			box-shadow: inset 0 0 0 2px var(--palette-blue-500);

			.${iconStyles.icon} {
				opacity: 1;
			}
		`};
`;

const NOOP = () => {
	return;
};

interface ListItemProps {
	selectable: boolean;

	isSelected: (user: models.user.User) => boolean;
	selectItem: (user: models.user.User) => void;

	user: models.user.User;
}

export default function ListItem({
	user,
	selectable,
	selectItem,
	isSelected,
}: ListItemProps) {
	const handleClick = () => selectItem(user);

	return (
		<Item
			key={`selectableList-item-${user.id}`}
			data-user-id={user.id}
			onClick={selectable ? handleClick : NOOP}
			selected={selectable && isSelected(user)}
			data-testid="selectable_user_list.item">
			<ListAvatar user={user} />
			<div>{models.user.fullName(user)}</div>
			{selectable && (
				<ListItemStatus selected={isSelected(user)}>
					<Icon name="check-filled" />
				</ListItemStatus>
			)}
		</Item>
	);
}
