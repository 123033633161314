import { ReactNode } from 'react';

export const symbol_crop_rotate: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M481 0Q303.884 0 170.543-112.204 37.203-224.409 5-395q-2-14 4.5-26t20-14q13.5-2 22.914 6.013Q61.828-420.974 64-408q23 142 137 241.5T459-59l-55-55q-9-9-8.5-21.5T405-157q9-9 21.5-9t21.5 9L575-29q5 5 3.5 12T570-8q-22 5-45 6.5T481 0ZM356-294q-26 0-43-17t-17-43v-251h-55q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T241-665h55v-55q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T356-720v366h366q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T722-294h-55v55q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T607-239v-55H356Zm251-120v-191H416v-60h191q26 0 43 17t17 43v191h-60ZM481-960q176.798 0 309.899 112.5T955-564q2 14-4 25.5T931-525q-12 2-22-6.5T897-552q-23-142-137-241.5T502-901l55 55q9 9 9 21.5t-9 21.5q-9 9-21.5 9t-21.5-9L386-931q-5-5-3.036-12.2 1.965-7.2 9.036-8.8 22-5 44.5-6.5T481-960Z"
		/>
	</svg>
);
