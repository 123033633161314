import { ReactNode } from 'react';

export const symbol_analytics: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M313.825-482Q301-482 292.5-473.375T284-452v145q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T344-307v-145q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625Zm332-215Q633-697 624.5-688.375T616-667v360q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T676-307v-360q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625Zm-166 302Q467-395 458.5-386.375T450-365v58q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T510-307v-58q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625ZM180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-60h600v-600H180v600Zm0-600v600-600Zm300.175 298q12.825 0 21.325-8.675 8.5-8.676 8.5-21.5 0-12.825-8.675-21.325-8.676-8.5-21.5-8.5-12.825 0-21.325 8.675-8.5 8.676-8.5 21.5 0 12.825 8.675 21.325 8.676 8.5 21.5 8.5Z"
		/>
	</svg>
);
