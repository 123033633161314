export default {
	name: 'nav-members',
	objects: [
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M16.8813 4.25C16.0348 4.25 15.3563 4.92851 15.3563 5.775C15.3563 6.58994 15.983 7.24672 16.7765 7.29818C16.843 7.29228 16.9112 7.29228 16.9777 7.29818C17.7698 7.24677 18.3993 6.59084 18.4063 5.77278C18.4051 4.93406 17.7254 4.25 16.8813 4.25ZM14.1063 5.775C14.1063 4.23816 15.3444 3 16.8813 3C18.4197 3 19.6563 4.24807 19.6563 5.775V5.77947C19.6456 7.27857 18.4655 8.49573 16.9789 8.54959C16.9449 8.55083 16.9108 8.54928 16.8771 8.54497C16.8434 8.54928 16.8093 8.55083 16.7753 8.54959C15.2895 8.49576 14.1063 7.27836 14.1063 5.775Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M15.9651 9.33439C17.232 9.11244 18.6766 9.32404 19.7268 10.0209L19.7279 10.0217C20.4458 10.5002 20.8875 11.1936 20.8875 11.9667C20.8875 12.7397 20.4458 13.4332 19.7279 13.9117C18.6855 14.6094 17.2582 14.8202 15.9944 14.6081C15.654 14.5509 15.4244 14.2286 15.4815 13.8882C15.5387 13.5478 15.861 13.3182 16.2014 13.3753C17.2205 13.5464 18.3093 13.3574 19.0334 12.8724L19.0346 12.8717C19.4917 12.5669 19.6375 12.227 19.6375 11.9667C19.6375 11.7065 19.4919 11.3668 19.0351 11.0621C18.302 10.5759 17.1969 10.3876 16.1808 10.5656C15.8408 10.6252 15.5169 10.3979 15.4573 10.0579C15.3977 9.71786 15.6251 9.39395 15.9651 9.33439Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M4.23126 5.77501C4.23126 4.24808 5.46784 3.00001 7.00626 3.00001C8.5431 3.00001 9.78126 4.23817 9.78126 5.77501C9.78126 7.27837 8.59805 8.49577 7.11222 8.5496C7.07821 8.55084 7.04416 8.54929 7.01042 8.54498C6.97669 8.54929 6.94264 8.55084 6.90863 8.5496C5.42202 8.49574 4.24198 7.27858 4.23127 5.77948L4.23126 5.77501ZM5.48126 5.77278C5.48824 6.59084 6.11774 7.24678 6.9098 7.29819C6.97635 7.29229 7.04451 7.29229 7.11106 7.29819C7.90448 7.24673 8.53126 6.58995 8.53126 5.77501C8.53126 4.92852 7.85275 4.25001 7.00626 4.25001C6.16209 4.25001 5.48246 4.93407 5.48126 5.77278Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M4.85239 11.0621C5.58552 10.5759 6.6906 10.3876 7.70673 10.5656C8.04673 10.6252 8.37064 10.3979 8.43021 10.0579C8.48977 9.71786 8.26243 9.39395 7.92243 9.33439C6.6555 9.11244 5.21089 9.32404 4.16069 10.0209L4.15956 10.0217C3.44174 10.5002 3 11.1936 3 11.9667C3 12.7395 3.44151 13.4328 4.159 13.9113C5.20147 14.6093 6.62909 14.8203 7.89306 14.6081C8.23347 14.5509 8.46311 14.2286 8.40596 13.8882C8.34881 13.5478 8.02652 13.3182 7.6861 13.3753C6.66697 13.5464 5.57821 13.3574 4.85407 12.8724L4.85294 12.8717C4.39576 12.5669 4.25 12.227 4.25 11.9667C4.25 11.7065 4.39564 11.3668 4.85239 11.0621Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M11.8813 10.475C11.0348 10.475 10.3563 11.1535 10.3563 12C10.3563 12.8149 10.983 13.4717 11.7765 13.5232C11.844 13.5172 11.9131 13.5173 11.9805 13.5234C12.7662 13.4784 13.3993 12.8203 13.4063 11.9978C13.4051 11.1591 12.7254 10.475 11.8813 10.475ZM9.10627 12C9.10627 10.4632 10.3444 9.22501 11.8813 9.22501C13.4197 9.22501 14.6563 10.4731 14.6563 12V12.0045C14.6456 13.4995 13.4685 14.7314 11.9744 14.7747C11.9419 14.7757 11.9093 14.7741 11.8771 14.77C11.8434 14.7743 11.8093 14.7758 11.7753 14.7746C10.2895 14.7208 9.10627 13.5034 9.10627 12Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M11.9594 15.4875C12.9331 15.4875 13.9405 15.73 14.7279 16.255C15.4458 16.7335 15.8875 17.4269 15.8875 18.2C15.8875 18.9729 15.4459 19.6662 14.7283 20.1448C13.9375 20.6735 12.9305 20.9187 11.9563 20.9187C10.9821 20.9187 9.97507 20.6735 9.18427 20.1448C8.46662 19.6663 8.025 18.9729 8.025 18.2C8.025 17.4269 8.46674 16.7335 9.18457 16.255L9.18607 16.254C9.97688 15.73 10.9854 15.4875 11.9594 15.4875ZM9.87721 17.2955C9.42061 17.6002 9.275 17.9399 9.275 18.2C9.275 18.4603 9.42076 18.8002 9.87794 19.105C10.4204 19.4677 11.1723 19.6687 11.9563 19.6687C12.7402 19.6687 13.4914 19.4682 14.0338 19.1055C14.491 18.8007 14.6375 18.4603 14.6375 18.2C14.6375 17.9397 14.4917 17.5998 14.0346 17.295C13.497 16.9367 12.746 16.7375 11.9594 16.7375C11.1733 16.7375 10.4197 16.9364 9.87721 17.2955Z',
			},
		},
	],
};
