import { ReactNode } from 'react';

export const symbol_smart_card_reader: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M80-110v-90q0-24.75 17.625-42.375T140-260h680q24.75 0 42.375 17.625T880-200v90q0 12.75-8.625 21.375T850-80H110q-12.75 0-21.375-8.625T80-110Zm60-30h680v-60H140v60Zm63-230v-450q0-24 18-42t42-18h434q24 0 42 18t18 42v450q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T697-370v-450H263v450q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T203-370Zm232-192.233q0-28.767-5-57.267-5-28.5-19-54.5-11-18-27.806-29.5Q366.389-715 345-715q-9.429 0-15.714 6.677Q323-701.645 323-692v259q0 9.645 6.517 16.323Q336.033-410 346-410q20 0 37-12t28-29q14.222-24.932 19.111-53.699Q435-533.466 435-562.233ZM565.796-491Q595-491 616-511.796q21-20.797 21-50Q637-591 616.204-612q-20.797-21-50-21Q537-633 516-612.204q-21 20.797-21 50Q495-533 515.796-512q20.797 21 50 21ZM480-140Zm0-440Z"
		/>
	</svg>
);
