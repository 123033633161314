import { ReactNode } from 'react';

export const symbol_comments_disabled: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-240q-23 0-41.5-18.5T80-300v-494l-33-33q-9-9.067-9-21.533Q38-861 47.053-870q9.052-9 21.5-9Q81-879 90-870l765 765q9 9 9 21t-9.053 21q-9.052 9-21.5 9Q821-54 812-63L634-240H140Zm740-580v618q0 9.917-9.5 13.458Q861-185 854-192L746-300h74v-520H226q-15 0-22.5-9.318t-7.5-20.5q0-11.182 7.5-20.682Q211-880 226-880h594q24 0 42 18.5t18 41.5ZM140-300h434L474-400H270q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T270-460h144l-70-70h-74q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T270-590h42v28L140-734v434Zm580-129.825q0 12.825-8.625 21.325T690-400h-31q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T659-460h31q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5ZM690-530H528q-15 0-22.5-9.318t-7.5-20.5q0-11.182 7.5-20.682Q513-590 528-590h162q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T690-530Zm0-130H398q-15 0-22.5-9.318t-7.5-20.5q0-11.182 7.5-20.682Q383-720 398-720h292q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T690-660ZM357-517Zm129-43Z"
		/>
	</svg>
);
