import { ReactNode } from 'react';

export const symbol_language_korean_latin: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M215.955-455Q244-455 264-467.75T284-506q0-26-19.955-39t-48-13Q188-558 168-544.8t-20 38.5q0 25.3 19.955 38.3 19.955 13 48 13ZM96-616q-6.546 0-11.273-5.032-4.727-5.033-4.727-12Q80-640 85.023-644.5T97-649h97v-36q0-8.885 6.081-14.942 6.081-6.058 15-6.058T230-699.942q6 6.057 6 14.942v36h99q6.545 0 11.273 4.727Q351-639.545 351-633q0 6.955-4.727 11.977Q341.545-616 335-616H96Zm119.526 195Q171-421 139-443.194 107-465.389 107-506q0-41.556 32.031-63.278Q171.061-591 216-591q45 0 77.5 21.722T326-506q0 41.556-32.5 63.278T215.526-421ZM178-260q-12.75 0-21.375-8.625T148-290v-79q0-8.885 6.526-14.942 6.527-6.058 15.5-6.058Q179-390 185-383.942q6 6.057 6 14.942v75h247q6.955 0 11.977 5.032 5.023 5.033 5.023 12Q455-270 449.977-265q-5.022 5-11.977 5H178Zm235.919-105Q405-365 399-371.058q-6-6.057-6-14.942v-300q0-8 6.5-14t14.5-6q8 0 14 6t6 14v130h52q6.955 0 11.977 5.032 5.023 5.033 5.023 12Q503-532 497.977-527q-5.022 5-11.977 5h-51v136q0 8.885-6.081 14.942-6.081 6.058-15 6.058ZM704-320q32.494 0 63.247-15T824-377.508V-499q-29 4-51 8.5T731-480q-50 15.782-75 39.455-25 23.672-25 55.236Q631-356 650.909-338q19.909 18 53.091 18Zm-14 51q-54 0-86.5-31T571-383q0-50 34-83.5T709.719-520Q732-526 760.5-531.5T824-542q0-56-18.5-82.5T739-651q-22 0-44 6.5T644-622q-10 5-19.849 2.667Q614.303-621.667 609-631q-5-10-2.5-20t12.5-15q31-17 64.5-27t67.5-10q69 0 101 42.5T884-540v237q0 10.606-7.078 17.803-7.079 7.197-17.51 7.197h-3.726q-9.686 0-16.186-6.5T831-300l-3-27q-30 28-66.5 43T690-269Z"
		/>
	</svg>
);
