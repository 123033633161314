import { ReactNode } from 'react';

export const symbol_1x_mobiledata_badge: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M100-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h760q24 0 42 18t18 42v600q0 24-18 42t-42 18H100Zm0-60h760v-600H100v600Zm0 0v-600 600Zm182-440v310q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T342-310v-340q0-12.75-8.625-21.375T312-680h-82q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T230-620h52Zm328 186 79 140q4 7 10.154 10.5T713-280q16.5 0 24.75-14t.25-28l-92-164 85-152q8-14 0-28t-24.522-14q-8.261 0-14.869 3.5Q685-673 681-666l-71 129-71-128q-4.032-6.667-10.484-10.833Q522.065-680 514-680q-16.5 0-24.75 14t-.25 28l85 152-91 164q-8 14 0 28t24.522 14q8.261 0 14.869-4Q529-288 533-295l77-139Z"
		/>
	</svg>
);
