import { ReactNode } from 'react';

export const symbol_lightbulb_circle: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-200q18 0 32-11.5t17-28.5h-98q4 17 17.5 28.5T480-200ZM380-310h200q8 0 14-6t6-14q0-8-6-14t-14-6H380q-8 0-14 6t-6 14q0 8 6 14t14 6Zm-19-90h238q37-27 59-68.5t22-91.5q0-83-58.5-141.5T480-760q-83 0-141.5 58.5T280-560q0 50 22 91.5t59 68.5Zm14-40q-26-25-40.5-56.5T320-560q0-66 47-113t113-47q66 0 113 47t47 113q0 32-14.5 63.5T585-440H375ZM480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm0-60q142 0 241-99.5T820-480q0-142-99-241t-241-99q-141 0-240.5 99T140-480q0 141 99.5 240.5T480-140Zm0-340Z"
		/>
	</svg>
);
