import { Component } from 'react';

import Select from 'components/form/Select';
import Label from 'components/form/Label';
import Row from 'components/form/Row';
import Hint from 'components/form/Hint';

export class DropdownList extends Component {
	static defaultProps = {
		initialOption: 0,
		options: [],
		isValidFormComponent: true,
	};

	state = {
		currentOption: null,
	};

	constructor(props) {
		super(props);

		this.state = {
			currentOption: props.initialOption,
		};
	}

	componentDidMount() {
		// @NOTE Cast value to string for concistency
		if (typeof this.props.formChange === 'function') {
			this.props.formChange.call(
				this,
				this.props.name,
				`${this.state.currentOption}`
			);
		}

		if (typeof this.props.onChange === 'function') {
			this.props.onChange.call(
				this,
				this.props.name,
				`${this.state.currentOption}`
			);
		}

		this.setState({
			currentOption: this.props.initialOption,
		});
	}

	componentDidUpdate(prevProps) {
		if (this.props.initialOption !== prevProps.initialOption) {
			this.setState({
				currentOption: this.props.initialOption,
			});
		}
	}

	handleOptionChange = (event) => {
		if (event.target) {
			const value = event.target.value;

			if (value !== this.state.currentOption) {
				this.setState({ currentOption: value });

				if (typeof this.props.formChange === 'function') {
					this.props.formChange.call(this, this.props.name, event.target.value);
				}

				if (typeof this.props.onChange === 'function') {
					this.props.onChange.call(this, this.props.name, event.target.value);
				}
			}
		}
	};

	get value() {
		return this.state.currentOption;
	}

	get helpText() {
		if (this.props.helpText) {
			return <Hint>{this.props.helpText}</Hint>;
		}

		return '';
	}

	get selectProperties() {
		let props = {
			onChange: this.handleOptionChange,
			value: this.state.currentOption,
		};

		if (this.props.disabled) {
			props.disabled = true;
		}

		if (this.props.name) {
			props.name = this.props.name;
		}

		return props;
	}

	get options() {
		return this.props.options.map((option, n) => {
			let [value, label] = option;

			return (
				<option
					ref={(refNode) => (this.refNode = refNode)}
					key={n}
					value={value}>
					{label}
				</option>
			);
		});
	}

	get label() {
		return this.props.label && <Label>{this.props.label}</Label>;
	}

	get select() {
		return <Select {...this.selectProperties}>{this.options}</Select>;
	}

	render() {
		if (this.props.options.length === 0) return null;

		return (
			<Row className={this.props.className}>
				{this.label}
				{this.select}
				{this.helpText}
			</Row>
		);
	}
}
