import { ReactNode } from 'react';

export const symbol_deployed_code_account: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M80-224v-33.969Q80-293 97.5-321t50.5-43q60-27 122.5-43.5T400-424q12.718 0 21.859 8.5Q431-407 431-394q0 12.632-9.141 21.316T400-364q-58 0-113.5 12.5T172-310q-16 8-24 22.5t-8 29.5v34h261q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T401-164H140q-24.75 0-42.375-17.625T80-224Zm599.895 107q-5.895 0-11.808-1.333Q662.174-119.667 657-123l-135-79q-10.621-6.118-16.31-16.441Q500-228.765 500-241v-157q0-12.235 5.69-22.559Q511.379-430.882 522-437l135-79q5.205-3.333 11.154-4.667Q674.103-522 680.051-522q5.949 0 11.404 1.667Q696.909-518.667 702-516l135 79q10.733 6.118 16.867 16.441Q860-410.235 860-398v157q0 12.235-6.133 22.559Q847.733-208.118 837-202l-135 79q-5.158 2.667-10.684 4.333Q685.789-117 679.895-117ZM400-485q-66 0-108-42t-42-108q0-66 42-108t108-42q66 0 108 42t42 108q0 66-42 108t-108 42Zm0-60q39 0 64.5-25.5T490-635q0-39-25.5-64.5T400-725q-39 0-64.5 25.5T310-635q0 39 25.5 64.5T400-545Zm0-90Zm1 411Zm177-188 102 60 102-60-102-59-102 59Zm127 228 105-59.704V-370l-105 62v124Zm-155-60 105 62v-125.148L550-368v124Z"
		/>
	</svg>
);
