import { Component } from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';

import * as palette from 'pkg/config/palette';
import * as breakpoints from 'pkg/config/breakpoints';

import {
	getRatingScores,
	getCurrentRating,
	makeGetComparisonRating,
} from 'pkg/selectors/ratings.selector';

import {
	Table as T,
	TableBody,
	TableCell,
	TableHeadCell,
	TableRow as TR,
} from 'components/legacy/Table';

const FauxText = styled.div`
	width: 100%;
	background: ${palette.gray[200]};
	height: 1rem;
	border-radius: var(--radius-2);
`;

const Table = styled(T)`
	background-color: transparent;
	tr > td {
		&:first-child {
			width: 22px;
		}

		width: calc(50% - 20px);

		@media ${breakpoints.small} {
			width: auto;
		}
	}
`;

const AuthorName = styled.span`
	font-size: 11px;
	color: ${palette.gray[600]};
	width: 100%;
	text-align: center;
	margin-bottom: 0.5rem;
	font-weight: var(--font-weight-normal);
	letter-spacing: 1px;
	text-transform: uppercase;
`;

const TH = styled(TableHeadCell)`
	background: transparent;
	font-size: var(--font-size-base);
	padding: 10px;
	text-align: left;
	opacity: 1;

	@media ${breakpoints.small} {
		padding: 10px;
	}
`;

const TD = styled(TableCell)`
	padding: 10px;
	font-size: var(--font-size-base);
	font-weight: ${(props) => (props.number ? 600 : 500)};
	opacity: 1;
	color: ${palette.blue[400]};
	position: relative;
	z-index: 1;

	${(props) =>
		props.number &&
		css`
			border-bottom: none;
			font-size: 10px;

			&::before {
				content: '';
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				height: 22px;
				width: 22px;
				border-radius: var(--radius-2);
				background-color: ${palette.blue[200]};
				z-index: -1;
			}
		`};

	text-align: ${(props) => (props.number ? 'center' : 'left')};

	@media ${breakpoints.small} {
		padding: 10px;
	}
`;

const TBody = styled(TableBody)`
	${TR}:first-of-type {
		border-top: none;

		${TH} {
			border-top: none;
			padding-top: 20px;

			@media ${breakpoints.small} {
				padding-top: 20px;
			}
		}

		${TD}:first-child {
			border-radius: 3px 3px 0 0;
		}
	}

	${TR}:last-of-type {
		${TD}:first-child {
			border-radius: 0 0 3px 3px;
		}
	}

	${TR} {
		@media (hover: hover) {
			&:hover ${TH}, &:hover ${TD} {
				background: transparent;

				a {
					color: #0b78e3;
				}
			}
		}
	}

	${TH}, ${TD} {
		padding-left: 15px;
		padding-right: 5px;

		&:first-child {
			padding-left: 5px;
		}

		&:last-child {
			padding-right: 0;
		}
	}

	${TD} {
		padding-right: 0;

		&:first-child {
			padding-left: 0;
		}
	}
`;

const Trait = styled.span`
	color: ${palette.gray[800]};
	font-weight: var(--font-weight-normal);
`;

class TrainingPriorityTable extends Component {
	static defaultProps = {
		currentRatingAuthor: '',
		comparisonRatingAuthor: '',
		comparisonScores: [],
		ratingScores: [],
		hasComparison: false,
		maturityAdjusted: false,
		assessmentPreview: false,
	};

	get tableRows() {
		const comparisonScores = this.props.comparisonScores || [];
		let comparisonTraits = this.props.hasComparison ? comparisonScores : [];

		let actualTraits = !this.props.ratingScores ? [] : this.props.ratingScores;

		let rows = [];

		const traitSorting = (a, b) =>
			(
				this.props.maturityAdjusted
					? b.maturityAdjustedDifference > a.maturityAdjustedDifference
					: b.difference > a.difference
			)
				? -1
				: 1;

		comparisonTraits.sort(traitSorting);
		actualTraits.sort(traitSorting);

		if (this.props.hasComparison) {
			rows.push(
				<TR key="training-priority-heading">
					<TH colSpan={2} textAlign="right" highlight>
						<AuthorName>{this.props.currentRatingAuthor?.firstName}</AuthorName>
					</TH>
					<TH textAlign="right" highlight>
						<AuthorName>
							{this.props.comparisonRatingAuthor?.firstName}
						</AuthorName>
					</TH>
				</TR>
			);
		}

		for (let i = 0; i < 10; i++) {
			let content = [];
			let ratingItem = { id: '', category: 'missing' };

			if (actualTraits[i]) {
				ratingItem = actualTraits[i].ratingItem;
			}

			content.push(
				<TD
					key={`trait-index-${i}-${ratingItem.category}`}
					width="40px"
					number
					textAlign="left">
					{i + 1}
				</TD>
			);

			if (ratingItem?.id) {
				content.push(
					<TD key={`trait-item-${i}-${ratingItem.category}`} textAlign="left">
						<Trait>{ratingItem.name}</Trait>
					</TD>
				);
			} else {
				content.push(
					<TD key={`trait-item-${i}-${ratingItem.category}`} textAlign="left">
						<FauxText />
					</TD>
				);
			}

			if (comparisonTraits && comparisonTraits.length > 0) {
				if (comparisonTraits[i]) {
					content.push(
						<TD
							key={`trait-comparison-${i}-${comparisonTraits[i].ratingItem.id}`}
							textAlign="left">
							<Trait>{comparisonTraits[i].ratingItem.name}</Trait>
						</TD>
					);
				} else {
					content.push(
						<TD
							key={`trait-comparison-${i}-${comparisonTraits[i].ratingItem.id}`}
							textAlign="left">
							<FauxText />
						</TD>
					);
					content.push(
						<TD
							key={`trait-comparison-${i}-${comparisonTraits[i].ratingItem.id}`}
							textAlign="left">
							<FauxText />
						</TD>
					);
				}
			} else if (this.props.hasComparison && comparisonTraits.length === 0) {
				content.push(
					<TD key={`trait-comparison-${i}`} textAlign="left">
						<FauxText />
					</TD>
				);
			}

			rows.push(<TR key={i}>{content}</TR>);
		}

		return rows;
	}

	render() {
		return (
			<Table>
				<TBody assessmentPreview={this.props.assessmentPreview}>
					{this.tableRows}
				</TBody>
			</Table>
		);
	}
}

const mapStateToProps = () => {
	const getComparisonRating = makeGetComparisonRating();

	return (state, props) => {
		const ratingScores = getRatingScores(
			state,
			props.ratingId,
			state.ratings.currentPosition
		);

		const comparisonScores = getRatingScores(
			state,
			state.ratings.comparisonRatingId,
			state.ratings.currentPosition
		);

		const currentRating = getCurrentRating(state, props);

		return {
			currentRatingAuthor: currentRating?.author,
			comparisonRatingAuthor: getComparisonRating(state, props)?.author,
			comparisonScores,
			ratingScores,
			hasComparison: !!state.ratings.comparisonRatingId,
			maturityAdjusted: state.ratings.maturityAdjustment,
		};
	};
};

const actions = {};

export default connect(mapStateToProps, actions)(TrainingPriorityTable);
