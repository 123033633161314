import { createContext, ReactNode, useContext } from 'react';

import * as models from 'pkg/api/models';
import useMixedState from 'pkg/hooks/useMixedState';
import { useAccountIdentity } from 'pkg/identity/account';
import { useOrganizationIdentity } from 'pkg/identity/organization';

const NoOp = (): void => null;

type SetMembership = (membership: models.membership.Membership) => void;

interface Identity {
	membership: Nullable<models.membership.Membership>;
	setMembership: SetMembership;
}

const DefaultIdentity: Identity = {
	membership: null,
	setMembership: NoOp,
};

export const IdentityContext = createContext<Identity>(DefaultIdentity);

let __currentIdentityContextValues: Readonly<Partial<Identity>>;

/**
 *	@warn This is is an "unsafe" way of getting current context values, and is *only* used in router middlewares.
 */
export function getCurrentIdentityContextValues(): Readonly<Partial<Identity>> {
	return __currentIdentityContextValues;
}

function useIdentityContext(): Identity {
	return useContext(IdentityContext);
}

interface ProviderProps {
	children: ReactNode | ReactNode[];
}

// todo(jbfm): this should be merged into OrganizationIdentityProvider
export function IdentityProvider({ children }: ProviderProps): JSX.Element {
	const [state, setState] = useMixedState<Identity>(DefaultIdentity);
	const user = useCurrentUser();
	const { wards } = useOrganizationIdentity();

	const setMembership = (membership: models.membership.Membership) => {
		membership.user = user;
		if (membership.targetUserId) {
			membership.targetUser = wards.find(
				(u) => u.id === membership.targetUserId
			);
		}

		const nextState: Partial<Identity> = {
			membership,
		};

		setState(nextState);
	};

	const providedValue: Identity = {
		...state,

		setMembership,
	};

	__currentIdentityContextValues = Object.freeze(providedValue);

	return (
		<IdentityContext.Provider value={providedValue}>
			{children}
		</IdentityContext.Provider>
	);
}

/* "Selector" helpers */

export function useCurrentUser(): models.user.User {
	const { user } = useOrganizationIdentity();

	return user;
}

export function useCurrentAccount(): models.account.Account {
	const { account } = useAccountIdentity();

	// Ensure users is always an array
	if (account?.users === null) {
		account.users = [];
	}

	return account;
}

export function useCurrentAccountUserIds(): number[] {
	const { users } = useOrganizationIdentity();

	return users.map((u) => u.id);
}

export function useCurrentMembership(): models.membership.Membership {
	const user = useCurrentUser();
	const identity = useIdentityContext();

	const membership = identity.membership || user?.groups?.[0];
	if (!membership) {
		return {} as models.membership.Membership;
	}

	return membership;
}

export function useCurrentMemberships(): models.membership.Membership[] {
	return useCurrentUser()?.groups || [];
}

export function useCurrentGroup(): models.group.Group {
	return useCurrentMembership()?.group;
}

/**
 * Finds the sport for the currently active group.
 * If the group has no sport, an empty sport is returned.
 */
export function useCurrentSport(): models.sport.Sport {
	const groupSport = useCurrentGroup().sport;

	return (
		groupSport || {
			type: models.sport.SportType.Unknown,
			defaultMatchDuration: 0,
			defaultMatchPeriods: 1,
			hasMatchModel: false,
			hasRatingModel: false,
		}
	);
}

export function useCurrentOrganization(): models.group.Group {
	const { organization } = useAccountIdentity();

	return organization;
}

export function useCurrentAccountWards(): models.accountRelation.AccountRelation[] {
	const { wardsAccountRelation } = useOrganizationIdentity();

	return wardsAccountRelation;
}

export function useCurrentAccountPendingWards(): models.accountRelation.AccountRelation[] {
	const { wardsAccountRelation } = useOrganizationIdentity();

	return wardsAccountRelation.filter(
		(ward: models.accountRelation.AccountRelation) => !ward.approvedAt
	);
}

export function useCurrentGroupId(): number {
	return useCurrentMembership()?.groupId || null;
}

export function useFetchIdentity(): () => Promise<void> {
	const { refreshAccountIdentity } = useAccountIdentity();
	const { refreshOrganizationIdentity } = useOrganizationIdentity();

	const refreshIdentityProviders = async () => {
		await refreshAccountIdentity();
		await refreshOrganizationIdentity();
	};

	return refreshIdentityProviders;
}
