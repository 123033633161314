import { ReactNode } from 'react';

export const symbol_general_device: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M350-210q-12.75 0-21.375-8.625T320-240v-540q0-24.75 17.625-42.375T380-840h200q24.75 0 42.375 17.625T640-780v540q0 12.75-8.625 21.375T610-210H350Zm30-60h200v-510H380v510Zm-30 90h260q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T610-120H350q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T350-180Zm30-90h200-200Z"
		/>
	</svg>
);
