import { motion, MotionProps } from 'framer-motion';
import { ReactNode, useEffect, useRef } from 'react';
import styled from 'styled-components';

import * as styles from 'pkg/config/styles';

import { formatTime } from 'pkg/timeline';
import {
	timelineDragOffset,
	timelineOffsetToTime,
	timelineWidth,
} from 'pkg/video';
import { useThrottle } from 'pkg/timings';

import * as config from 'routes/video/analyze/timeline/config';
import TimeSegments from 'routes/video/analyze/timeline/TimeSegments';
import { TimelineOffsetHook } from 'routes/video/shared/hooks/timeline';
import PlaybackKeyframeButton from 'routes/video/analyze/timeline/PlaybackKeyframeButton';
import KeyframeControls from 'routes/video/analyze/timeline/KeyframeControls';

import { usePlayerState } from 'components/video-analytics/PlayerState';
import { usePlaybackState } from 'components/video-analytics/PlaybackState';
import { useCueState } from 'components/video-analytics/CueState';
import {
	IS_DISPLAYING,
	IS_EDITING,
	IS_OUTSIDE_CLIP,
} from 'components/annotations/constants';

const Wrapper = styled.div`
	height: 100%;
	overflow: hidden;
	position: relative;
`;

const Pointer = styled.div`
	position: absolute;
	background: var(--palette-white);
	width: 1px;
	height: 100%;
	left: 50%;
	z-index: ${styles.zIndex.videoAnalytics + 10};
	pointer-events: none;
`;

const Timestamp = styled.div`
	padding: var(--spacing-2) var(--spacing-3);
	position: absolute;
	background: var(--palette-gray-900);
	box-shadow:
		0 0 0 1px var(--palette-gray-600),
		0 0 5px var(--palette-gray-700);
	border-radius: var(--radius-2);
	color: var(--palette-white);
	font-size: var(--font-size-xs);
	left: 50%;
	top: 10px;
	transform: translateX(-50%);
	z-index: ${styles.zIndex.videoAnalytics + 15};
	pointer-events: none;
	transition: transform 150ms ease-in-out;

	.${IS_EDITING} & {
		transform: translateX(-50%) translateY(110%);
	}
`;

const Backdrop = styled.div`
	background: var(--palette-gray-700);
	width: 100%;
	height: 40px;
	position: absolute;
	left: 0;
	top: 0;
	z-index: ${styles.zIndex.videoAnalytics};
	box-shadow: var(--palette-gray-900) 0 0 20px;
`;

interface DraggableProps {
	containerWidth: number;
}

const DraggableAttrs = ({ containerWidth }: DraggableProps) => ({
	style: {
		width: `${containerWidth}px`,
	},
});

const Draggable = styled(motion.div).attrs<DraggableProps>(
	DraggableAttrs
)<DraggableProps>`
	margin: 0 50%;
	height: 100%;
	cursor: grab;
	position: relative;
	color: var(--palette-gray-500);
	z-index: ${styles.zIndex.videoAnalytics + 5};

	&:active {
		cursor: grabbing;
	}

	&::before,
	&::after {
		display: block;
		position: absolute;
		overflow: visible;
		top: 13px;
		font-size: var(--font-size-sm);
		color: var(--palette-gray-600);
	}

	&::before {
		content: attr(data-starts-at);
		transform: translateX(calc(-100% - 10px));
		left: 0;
	}

	&::after {
		content: attr(data-ends-at);
		transform: translateX(calc(100% + 10px));
		right: 0;
	}
`;

const Container = styled.div`
	padding: var(--spacing-4) 0;
	height: calc(100% - 40px); // Size of timestamps header

	&[data-num-intersects='0'] {
		padding: var(--spacing-9) 0;
	}

	@media ${styles.breakpoint.small} {
		&[data-num-intersects='0'] {
			padding: var(--spacing-8) 0;
		}
	}
`;

const Workspace = styled.div`
	--num-intersects: 1;

	height: 100%;
	display: grid;
	grid-template-rows: repeat(calc(var(--num-intersects) + 1), 1fr);

	& > div {
		grid-column-start: 1;
	}

	// One row
	&[data-num-intersects='0'] > div {
		grid-row-start: 1;
	}

	// Two Rows
	&[data-num-intersects='1'] > div {
		&:nth-child(odd) {
			grid-row-start: 1;
		}

		&:nth-child(even) {
			grid-row-start: 2;
		}
	}

	// Three Rows
	&[data-num-intersects='2'] > div {
		&:nth-child(3n + 1) {
			grid-row-start: 1;
		}

		&:nth-child(3n + 2) {
			grid-row-start: 2;
		}

		&:nth-child(3n) {
			grid-row-start: 3;
		}
	}

	// Four Rows
	&[data-num-intersects='3'] > div {
		&:nth-child(4n + 1) {
			grid-row-start: 1;
		}

		&:nth-child(4n + 2) {
			grid-row-start: 2;
		}

		&:nth-child(4n + 3) {
			grid-row-start: 3;
		}

		&:nth-child(4n) {
			grid-row-start: 4;
		}
	}

	// Five Rows
	&[data-num-intersects='4'] > div {
		&:nth-child(5n + 1) {
			grid-row-start: 1;
		}

		&:nth-child(5n + 2) {
			grid-row-start: 2;
		}

		&:nth-child(5n + 3) {
			grid-row-start: 3;
		}

		&:nth-child(5n + 4) {
			grid-row-start: 4;
		}

		&:nth-child(5n) {
			grid-row-start: 5;
		}
	}
`;

interface TimelineProps {
	precision?: config.Precision;
	zoom?: number;
	duration: number;
	timelineOffset: TimelineOffsetHook;
	maxIntersections: number;
	disabled?: boolean;
	children?: ReactNode;
}

export default function Timeline({
	precision = config.Precision.ThirtySeconds,
	zoom,
	duration,
	timelineOffset,
	maxIntersections,
	disabled,
	children,
}: TimelineProps): JSX.Element {
	const draggableRef = useRef<HTMLDivElement>();
	const wrapperRef = useRef<HTMLDivElement>();

	const { currentTime, setCurrentTime } = usePlaybackState();
	const { currentCue } = useCueState();
	const {
		controller,
		isPlaying,
		setScrubbing,
		isScrubbing,
		setJustScrubbed,
		isTrimming,
		isRecording,
		isEditing,
	} = usePlayerState();

	const formattedCurrentTime = formatTime(currentTime);
	const formattedDuration = formatTime(duration);

	const containerWidth = timelineWidth(
		config.getSegmentWidth(zoom),
		duration,
		config.multiplier[precision]
	);

	useEffect(() => {
		timelineOffset.set(currentTime);
	}, [precision]);

	useEffect(() => {
		if (isPlaying && !isScrubbing) {
			timelineOffset.set(currentTime);
		}
	}, [currentTime]);

	const nextTimestamp = (): number => {
		const offset: number = timelineDragOffset(draggableRef.current);
		const timeOffset: number = timelineOffsetToTime(offset * 100, 0, duration);

		return timeOffset;
	};

	const handleTimelineDragStart = () => {
		setScrubbing(true);

		if (isRecording && isPlaying) {
			controller.pause();
		}
	};

	const handleTimelineDragEnd = () => {
		setCurrentTime(nextTimestamp());
	};

	const handleTimelineDragComplete = async () => {
		setCurrentTime(nextTimestamp());

		await controller.seekTo(nextTimestamp(), isPlaying);

		setScrubbing(false);
		setJustScrubbed(true);

		if (isRecording && !isTrimming && !isPlaying) {
			controller.play();
		}
	};

	const handleTimelineDrag = useThrottle(() => {
		setCurrentTime(nextTimestamp());
	}, 500);

	const draggableProps: MotionProps & DraggableProps = {
		containerWidth,

		style: { x: timelineOffset.get() },
		drag: disabled ? false : 'x',
		dragElastic: 0.1,
		dragConstraints: {
			left: -containerWidth,
			right: 0,
		},
		dragTransition: {
			// Controls "momentum flick" inertia
			power: 0.3,
			// Controls momentum slowdown threshold
			timeConstant: 150,
		},
		onDrag: handleTimelineDrag,
		onDragStart: handleTimelineDragStart,
		onDragEnd: handleTimelineDragEnd,
		onDragTransitionEnd: handleTimelineDragComplete,
	};

	if (duration === 0) {
		return null;
	}

	const startsAtMs = currentCue
		? currentCue.startsAtMs || currentCue.startsAt * 1000
		: 0;

	const endsAtMs = currentCue
		? currentCue.endsAtMs || currentCue.endsAt * 1000
		: 0;

	const withinClip =
		currentTime * 1000 >= startsAtMs && currentTime * 1000 <= endsAtMs;

	return (
		<Wrapper
			ref={wrapperRef}
			className={`
				${isEditing ? IS_EDITING : IS_DISPLAYING}
				${withinClip ? '' : IS_OUTSIDE_CLIP}
			`}>
			<Timestamp>
				{formattedCurrentTime} / {formattedDuration}
			</Timestamp>
			<PlaybackKeyframeButton disabled={!withinClip} />
			<KeyframeControls />
			<Pointer />
			<Backdrop />
			<Draggable
				ref={draggableRef}
				{...draggableProps}
				data-testid="video.analyze.workspace"
				data-starts-at="00:00"
				data-ends-at={formattedDuration}>
				<TimeSegments duration={duration} precision={precision} zoom={zoom} />
				<Container
					data-num-intersects={maxIntersections}
					data-is-trimming={isTrimming}>
					<Workspace
						data-num-intersects={maxIntersections}
						style={{ '--num-intersects': maxIntersections } as any}>
						{children}
					</Workspace>
				</Container>
			</Draggable>
		</Wrapper>
	);
}
