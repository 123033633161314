import { ReactNode } from 'react';

export const symbol_mode_dual: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M225-128q-9 9-22 8t-21-10q-35-37-55.5-83T106-310q0-50 14-97.5t33-93.5q16-38 29-76.5t13-80.5q0-38-18.5-70.5T130-787q-9-8-9-21t8-22q8-9 20.5-9t21.5 8q38 35 61 79t23 95q0 48-13.5 92T210-477q-17 40-30.5 81.5T166-310q0 40 17 75t44 64q8 9 7.5 21.5T225-128Zm160 0q-9 8-22 7.5t-21-9.5q-35-37-55.5-83T266-310q0-50 14-97.5t33-93.5q16-38 29-76.5t13-80.5q0-38-18.5-71T290-788q-9-8-9-20.5t8-21.5q8-9 20.5-9.5T331-832q38 35 61 79.5t23 95.5q0 47-13.5 92T370-477q-17 41-30.5 82T326-310q0 40 17 75t44 64q8 9 7.5 21.5T385-128Zm105 18v-740q0-13 8.5-21.5T520-880q13 0 21.5 8.5T550-850v164l129-129q9-9 21-8.5t21 9.5q9 9 9 21t-9 21L550-601v91h91l168-168q9-9 21-9t21 9q9 9 9 21.5t-9 21.5L726-510h164q13 0 21.5 8.5T920-480q0 13-8.5 21.5T890-450H726l128 129q8 8 8 20t-8 21q-9 9-21.5 9t-21.5-9L641-450h-91v90l172 173q8 8 8 20t-8 21q-9 9-21.5 9t-21.5-9L550-275v165q0 13-8.5 21.5T520-80q-13 0-21.5-8.5T490-110Z"
		/>
	</svg>
);
