import { ReactNode } from 'react';

export const symbol_arrow_downward_alt: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M450-357v-370q0-13 8.5-21.5T480-757q13 0 21.5 8.5T510-727v372l147-147q9-9 21-9t21 9q9 9 9 21t-9 21L500-261q-9 9-21 9t-21-9L259-460q-9-9-9-21t9-21q9-9 21.5-9.5T302-503l148 146Z"
		/>
	</svg>
);
