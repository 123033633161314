import { ReactNode } from 'react';

export const symbol_cabin: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M220-180h520v-100H220v100Zm0-160h520v-100H220v100Zm0-160h520v-67l-43-33H264l-44 33v67Zm123-160h275L480-765 343-660ZM160-180v-341l-60 46q-10 8-22 6t-20-12q-8-10-6-22t12-20l96-73v-94q0-13 8.5-21.5T190-720q13 0 21.5 8.5T220-690v48l224-170q16-12 36-12t36 12l381 290q10 8 11.5 20t-6.5 22q-8 10-19.5 11.5T861-475l-61-46v341q0 25-17.5 42.5T740-120H220q-25 0-42.5-17.5T160-180Zm35-580q-15 0-24.5-11t-4.5-24q11-34 40-54.5t64-20.5q14 0 26-7t18-20q5-9 12.5-16t18.5-7q15 0 24.5 11t4.5 24q-12 34-40.5 54.5T270-810q-14 0-25.5 7.5T226-783q-5 9-12.5 16t-18.5 7Z"
		/>
	</svg>
);
