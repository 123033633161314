import { ReactNode } from 'react';

export const symbol_wind_power: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M430-40q0-25 17.625-42.5T490-100v-225q-15-4-28-10.5T438-353l-88 55q-14 9-30.5 11t-33.5-3l-180-51q-29-8-47.5-32.5T40-429q0-38 26.5-64.5T131-520h301q10-11 22-19t26-13v-137q0-17 6.5-32t18.5-26l137-128q23-22 53.5-25.5T752-887q32 19.948 41.5 56.354T783-762.322L623-500q8 13 11.5 27t4.5 29l108 26q17 4 29.618 14 12.617 10 20.382 24l91 164q15 27 11 57t-26 52q-27 27-64.5 27T744-107L550-301v201q25 0 42.5 17.625T610-40H430ZM190-770q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T190-830h180q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T370-770H190Zm350 72v141q11 2 21.5 6t19.5 9l151-252q7-11 3.5-24T720-836.5q-9-5.5-19.5-4T683-832L540-698ZM70-610q-12.75 0-21.375-8.675Q40-627.351 40-640.175 40-653 48.625-661.5T70-670h140q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T210-610H70Zm450 230q25.5 0 42.75-17.25T580-440q0-25.5-17.25-42.75T520-500q-25.5 0-42.75 17.25T460-440q0 25.5 17.25 42.75T520-380Zm-208 36 95-58q-5-14-5.5-29t1.5-29H131q-13 0-22 9t-9 22.333Q100-418 106-410q6 8 16 11l190 55Zm429-15-115-26q-6 12-15 22.5T591-344l196 195q9 9 22 9t22-9q8.25-8.4 9.625-18.2Q842-177 837-186l-96-173ZM150-130q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-190h140q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T290-130H150Zm370-310Zm61-102Zm-178 82Zm188 116Z"
		/>
	</svg>
);
