import { t } from '@transifex/native';
import * as React from 'react';

import User from 'pkg/models/user';

import { IconName } from 'components/icon';

import Row from 'components/layout/row';

import * as DataCard from 'design/data_card';

type StatProps = {
	title: string;
	icon: IconName;
	value: number;
	average: number;
};

const Stat: React.FC<React.PropsWithChildren<StatProps>> = ({
	title,
	icon,
	value = 0,
	average = 0,
}) => (
	<DataCard.WithLargeIcon
		title={title}
		icon={icon}
		titleSuffix={t(`Season`)}
		heading={value.toString()}
		headingSuffix={t(`Team avg {value}`, {
			value: average,
		})}></DataCard.WithLargeIcon>
);

type StatisticsProps = {
	user: User;
	averageStats: Map<string, number>;
	isSelfOrParentToUserOrAdmin: boolean;
};

const Statistics: React.FC<React.PropsWithChildren<StatisticsProps>> = ({
	user,
	averageStats,
	isSelfOrParentToUserOrAdmin,
}) => {
	const { meta } = user;

	return (
		<Row collapseOnSmallScreens>
			<Stat
				icon="training"
				title={t(`Trainings`)}
				value={meta.get('season-activity-count')}
				average={averageStats.get('season-activity-count')}
			/>
			<Stat
				icon="nav-formation"
				title={t(`Matches`)}
				value={meta.get('season-matches-played')}
				average={averageStats.get('season-matches-played')}
			/>
			{isSelfOrParentToUserOrAdmin && (
				<Stat
					icon="nav-attendance"
					title={t(`Started`)}
					value={meta.get('season-started-matches')}
					average={averageStats.get('season-started-matches')}
				/>
			)}
		</Row>
	);
};

export default Statistics;
