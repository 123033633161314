import { Component } from 'react';

import HeatMapWrapper from 'components/HeatMapWrapper';

class Spot extends Component {
	getCssClasses() {
		let classes = ['heatmap__spot', this.props.position.slug];

		if (this.props.position.is_chosen_position === true) {
			classes.push('heatmap__spot--chosen');
		}

		if (this.props.active) {
			classes.push('active');
		}

		if (!this.props.heatMap.shouldShowScores()) {
			classes.push('heatmap__spot--border-less ');
		}

		return classes.join(' ');
	}

	get positionName() {
		if (this.props.heatMap.shouldShowScores()) {
			return <div className="position">{this.props.position.name}</div>;
		}

		return null;
	}

	get positionScore() {
		if (this.props.heatMap.shouldShowScores()) {
			return (
				<div className="score">
					{this.props.heatMap.isMaturityAdjusted()
						? this.props.position.maturityAdjustedValue.toFixed(2)
						: this.props.position.value.toFixed(2)}
				</div>
			);
		}
		return null;
	}

	render() {
		return (
			<div
				className={this.getCssClasses()}
				style={{
					backgroundColor: `hsl(${this.props.color[0]}, ${this.props.color[1]}%, ${this.props.color[2]}%)`,
				}}
				onClick={this.props.heatMap.clickPosition.bind(
					this.props.heatMap,
					this.props.position
				)}>
				<div className="info">
					{this.positionName}
					{this.positionScore}
				</div>
			</div>
		);
	}
}

/**
 * Possible props:
 * @param {Object} rating
 * @param {function} onPositionClick
 * @param {bool} interactive
 * @param {bool} maturityAdjusted
 */
export default class HeatMap extends Component {
	constructor(props) {
		super(props);

		this.onPositionClick = () => {};
		this.hasGoalKeeper = false;

		if (typeof this.props.onPositionClick == 'function') {
			this.onPositionClick = this.props.onPositionClick;
		}

		// Green hsl(120, 100%, 50%)
		// Red hsl(360, 100%, 50%)
	}

	isMaturityAdjusted() {
		return this.props.maturityAdjusted ? this.props.maturityAdjusted : false;
	}

	shouldShowScores() {
		return this.props.showScores != undefined ? this.props.showScores : true;
	}

	clickPosition(position) {
		this.onPositionClick.call(this, position);
	}

	getSpots() {
		let positions = [];
		let spots = [];

		for (let i = 0; i < this.props.rating?.length; i++) {
			positions.push(this.props.rating[i]);
		}

		positions.sort((a, b) =>
			this.isMaturityAdjusted()
				? a.maturityAdjustedValue - b.maturityAdjustedValue
				: a.value - b.value
		);

		let positionAverages = positions
			.map((item) =>
				this.isMaturityAdjusted() ? item.maturityAdjustedValue : item.value
			)
			.sort();
		let max = Math.max(...positionAverages);
		let min = Math.min(...positionAverages);
		let median = positionAverages[Math.round(positionAverages.length / 2)];

		let medianDiff = 1 - (median - min) / (max - min);

		for (let i = 0; i < positions.length; i++) {
			let avg = this.isMaturityAdjusted()
				? positions[i].maturityAdjustedValue
				: positions[i].value;

			let diff = 1 - (avg - min) / (max - min);

			let hue = 360; // Red
			let saturation = 100 * diff;

			if (diff < medianDiff) {
				hue = 120; // Green
				saturation = 100 - 100 * diff;
			}

			spots.push(
				<Spot
					color={[
						hue,
						saturation,
						Math.min(100 * (1 - Math.abs(diff - 0.5)), 85),
					]}
					key={`${positions[i].id}-${i}`}
					heatMap={this}
					position={
						positions[i]?.position ? positions[i].position : positions[i]
					}
					active={this.props.currentPosition == parseInt(positions[i].id, 10)}
					showScores={this.props.showScores}
				/>
			);

			if (positions[i].position.slug === 'gk') {
				this.hasGoalKeeper = true;
			}
		}

		return spots;
	}

	render() {
		let spots = this.getSpots();
		let classes = ['heatmap'];

		if (this.props.interactive) {
			classes.push('heatmap--interactive');
		}

		if (this.props.small) {
			classes.push('heatmap--small');
		}

		if (this.hasGoalKeeper) {
			classes.push('heatmap--with-goalkeeper');
		}

		return (
			<HeatMapWrapper className={classes.join(' ')}>{spots}</HeatMapWrapper>
		);
	}
}
