import { StartLoginStateFlows } from 'pkg/actions/sso';

import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { useCurrentRoute } from 'pkg/router/hooks';
import { parse } from 'pkg/json';
import getWorkOSErrors from 'pkg/sso';

import LoginSSORedirect from 'routes/public/steps/login/sso/redirect/login';
import RegistrationSSORedirect from 'routes/public/steps/login/sso/redirect/registration';
import { GenericUnableToAuthError } from 'routes/public/steps/login/sso/redirect/error';
import { useOnboardingContext } from 'routes/public/onboarding';

// This component is only responsible for calling the login action and handling errors
export default function SSORedirect() {
	const onboardingCtx = useOnboardingContext();

	const { query } = useCurrentRoute();

	const parsedQueryState = parse(query?.state);
	const workOSErrors = getWorkOSErrors(query);

	useComponentDidMount(async () => {
		if (workOSErrors.hasError) {
			onboardingCtx.overrideBackSlug('start');
		}
	});

	if (workOSErrors.hasError) {
		return <GenericUnableToAuthError />;
	}

	const flow = parsedQueryState?.flow as StartLoginStateFlows;

	if (flow === StartLoginStateFlows.Login) {
		return <LoginSSORedirect />;
	} else if (flow === StartLoginStateFlows.Registration) {
		return <RegistrationSSORedirect />;
	}

	return null;
}
