import { ReactNode } from 'react';

export const symbol_note_stack: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M280-140v-480q0-25.063 17.5-42.532Q315-680 340-680h480q24.75 0 42.375 17.625T880-620v340L680-80H340q-24.75 0-42.375-17.625T280-140ZM81-725q-5-25 9.5-44.5T130-794l473-84q25-5 44.5 9t24.5 39l16 90h-60l-14-79-473 83 79 450v83q-20-2-35-15t-19-34L81-725Zm259 105v480h308v-142q0-12.75 8.625-21.375T678-312h142v-308H340Zm240 240Z"
		/>
	</svg>
);
