import { FC, PropsWithChildren, Fragment, useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

import { fromMedium, toMedium } from 'pkg/config/breakpoints';

type PropsWithChildrenOnly = PropsWithChildren<unknown>;

export const LargeScreen: FC<PropsWithChildrenOnly> = ({
	children,
}): JSX.Element => {
	const isLargeScreen = useMediaQuery({ minWidth: fromMedium });
	const isPrinting = useMediaQuery({ print: true });

	return <Fragment>{isLargeScreen || isPrinting ? children : null}</Fragment>;
};

export const SmallScreen: FC<PropsWithChildrenOnly> = ({
	children,
}): JSX.Element => {
	const isSmallScreen = useMediaQuery({ maxWidth: toMedium });
	const isPrinting = useMediaQuery({ print: true });

	return <Fragment>{isSmallScreen && !isPrinting ? children : null}</Fragment>;
};

export function useLargeScreen() {
	const isLargeScreen = useMediaQuery({ minWidth: fromMedium });
	return isLargeScreen;
}

export function useSmallScreen() {
	const isSmallScreen = useMediaQuery({ maxWidth: toMedium });
	return isSmallScreen;
}

export const RenderPrint: FC<PropsWithChildrenOnly> = ({
	children,
}): JSX.Element => {
	const [isPrinting, setIsPrinting] = useState(false);

	const beforePrint = () => {
		setIsPrinting(true);
	};

	const afterPrint = () => {
		setIsPrinting(false);
	};

	useEffect(() => {
		window.addEventListener('beforeprint', beforePrint);
		window.addEventListener('afterprint', afterPrint);

		return () => {
			window.removeEventListener('beforeprint', beforePrint);
			window.removeEventListener('afterprint', afterPrint);
		};
	}, []);
	return <Fragment>{isPrinting ? children : null}</Fragment>;
};

export const TouchDevice: FC<PropsWithChildrenOnly> = ({
	children,
}): JSX.Element => {
	const query = '(hover: none) and (pointer: coarse)';
	const isTouchDevice = useMediaQuery({ query });

	return <Fragment>{isTouchDevice ? children : null}</Fragment>;
};

export const FinePointerDevice: FC<PropsWithChildrenOnly> = ({
	children,
}): JSX.Element => {
	const query = '(hover: hover) and (pointer: fine)';
	const isTouchDevice = useMediaQuery({ query });

	return <Fragment>{isTouchDevice ? children : null}</Fragment>;
};
