import { ReactNode } from 'react';

export const symbol_file_save: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m700-238-53-53q-9-9-21-9t-21 9q-9 9-9 21t9 21l104 104q9 9 21 9t21-9l104-104q9-9 9-21t-9-21q-9-9-21-9t-21 9l-53 53v-151q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T700-389v151ZM610-60h240q12.75 0 21.375 8.675Q880-42.649 880-29.825 880-17 871.375-8.5T850 0H610q-12.75 0-21.375-8.675Q580-17.351 580-30.175 580-43 588.625-51.5T610-60ZM220-160q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h281q12.444 0 23.722 5T544-862l198 198q8 8 13 19.278 5 11.278 5 23.722v112q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T700-509v-111H545q-18.75 0-31.875-13.125T500-665v-155H220v600h270q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T490-160H220Zm0-60v-600 600Z"
		/>
	</svg>
);
