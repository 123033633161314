import { Fragment } from 'react';
import { useT } from '@transifex/react';

import { MixedStateSetter } from 'pkg/hooks/useMixedState';
import * as routes from 'pkg/router/routes';
import { replaceState } from 'pkg/router/state';
import { useCurrentOrganization } from 'pkg/identity';

import { DiscountFormState } from 'routes/payments/discounts/create';

import { useSmallScreen } from 'components/MediaQuery';

import Button from 'design/button';

interface ButtonActionsProps {
	edit?: boolean;
	formState: DiscountFormState;
	setFormState: MixedStateSetter<DiscountFormState>;

	handleNextAction: () => void;
	onCancel?: () => void;
	onPreview?: () => void;
}

/**
 * This component handles rendering of the action/footer bar buttons
 */
export default function ButtonActions({
	edit = false,
	formState,
	setFormState,
	handleNextAction,
	onCancel,
	onPreview,
}: ButtonActionsProps) {
	const t = useT();
	const isSmallScreen = useSmallScreen();
	const org = useCurrentOrganization();

	const handleHidePreview = () => setFormState({ showPreview: false });

	const handleCancel = () => {
		if (onCancel) {
			onCancel();
			return;
		}

		replaceState(routes.Discounts.Index(org.id));
	};

	if (formState.showPreview) {
		return (
			<Fragment>
				<Button
					block={isSmallScreen}
					large={isSmallScreen}
					label={t('Back')}
					onClick={handleHidePreview}
				/>
				<Button
					block={isSmallScreen}
					large={isSmallScreen}
					label={edit ? t('Save') : t('Create discount')}
					primary
					busy={formState.saving}
					onClick={handleNextAction}
				/>
			</Fragment>
		);
	}

	return (
		<Fragment>
			<Button
				block={isSmallScreen}
				large={isSmallScreen}
				label={t('Cancel')}
				onClick={handleCancel}
			/>
			<Button
				block={isSmallScreen}
				large={isSmallScreen}
				label={t('Preview')}
				type="submit"
				primary
				onClick={onPreview || undefined}
			/>
		</Fragment>
	);
}
