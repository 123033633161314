import { ReactNode } from 'react';

export const symbol_search_insights: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M400.059-300Q508-300 584.5-376.125T661-561q0-107.917-76.5-183.458Q508-820 400.059-820t-183.5 75.542Q141-668.917 141-561q0 108.75 75.559 184.875T400.059-300Zm.766-148Q388-448 379.5-456.625T371-478v-207q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T431-685v207q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Zm-145 0Q243-448 234.5-456.625T226-478v-124q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T286-602v124q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Zm290 0Q533-448 524.5-456.625T516-478v-83q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T576-561v83q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM400-240q-133 0-226-93.5T81-560.705q0-133.705 92.795-226.5Q266.59-880 400.295-880 534-880 627.5-787T721-561q0 58.513-19.5 110.756Q682-398 647-356l212 212q9 9 8.5 21t-8.75 21q-8.25 9-21 9T816-102L605-314q-42 35-94.244 54.5Q458.513-240 400-240Z"
		/>
	</svg>
);
