import { ReactNode } from 'react';

export const symbol_battery_share: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M429.5-290q-12.5 0-21-8.625T400-320v-90q0-24.75 17.25-42.375T460-470h220l-52-52q-8-9.067-8-21.533 0-12.467 8.25-20.597 9-8.87 21.375-8.87T671-564l103 103q9 9.067 9 21.533Q783-427 774-418L671-315q-9.067 9-21.533 9Q637-306 628-315q-9-9-9-21.5t9-21.5l53-52H460v91q0 12-9 20.5t-21.5 8.5ZM310-80q-12.75 0-21.375-8.625T280-110v-676q0-12.75 8.625-21.375T310-816h90v-34q0-12.75 8.625-21.375T430-880h100q12.75 0 21.375 8.625T560-850v34h90q12.75 0 21.375 8.625T680-786v110q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T620-676v-80H340v616h280v-64q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T680-204v94q0 12.75-8.625 21.375T650-80H310Z"
		/>
	</svg>
);
