import { t } from '@transifex/native';

import { Order } from 'pkg/api/models/order';
import { Form } from 'pkg/api/models/form';
import { User } from 'pkg/api/models/user';
import { Record } from 'pkg/api/models/record';
import { Linkable } from 'pkg/api/models/linkable';
import { Dateable } from 'pkg/api/models/dateable';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';

export const getSubmissionsFieldsTranslations = () => {
	const translations: { [key: string]: string } = {
		user_firstName: t(`First name`),
		user_lastName: t(`Last name`),
		user_email: t(`Email`),
		user_birthDate: t(`Date of birth`),
		user_phoneNumber: t(`Phone number`),
		user_companyName: t(`Company name`),
		user_sex: t(`Gender`),
		user_address: t(`Address`),
		user_region: t(`Region`),
		user_city: t(`City`),
		user_postalCode: t(`Zip code`),
		user_country: t(`Country`),
		user_nationality: t(`Nationality`),
	};

	return translations;
};

export enum Visibilities {
	Unlisted = 'unlisted',
	Public = 'public',
}

export enum FormSubmissionStatuses {
	Draft = 'draft',
	Submitted = 'submitted',
}

export interface FormSubmission extends Linkable, Record, Dateable {
	formId: number;
	userId: number;
	orderId: number;
	subscriptionId: number;
	data: { [key: string]: string };
	status: FormSubmissionStatuses;
	submittedForUserId?: number;
	submittedByUserId?: number;

	form?: Form;
	submittedForUser?: User;
	order?: Order;
	submittedByUser?: User;
}

interface ExportSubmissionsPayload {
	form_id: number;
	filters: string;
}

export async function exportSubmissions(
	payload: ExportSubmissionsPayload,
	formFields: string[]
): Promise<[boolean, string]> {
	return models.createExport(
		endpoints.FormSubmissions.Export(),
		{ formFields },
		payload
	);
}
