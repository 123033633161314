import { useEventListener } from 'pkg/hooks/events';
import { useViewportFetchLimit } from 'pkg/hooks/library';
import { useCurrentAccount } from 'pkg/identity';

import { useAccountLibrary } from 'routes/video/library/hooks';
import Library from 'routes/video/library/Library';

export default function Account(): JSX.Element {
	const account = useCurrentAccount();

	const fetchLimit = useViewportFetchLimit();
	const library = useAccountLibrary(account.id, fetchLimit);

	useEventListener('video-collection-saved', () => {
		library.refresh();
	});

	return <Library library={library} />;
}
