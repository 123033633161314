import { ReactNode } from 'react';

export const symbol_delete_sweep: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M185-200q-24 0-42-18t-18-42v-415q-13 0-21.5-8.675-8.5-8.676-8.5-21.5Q95-718 103.625-726.5T125-735h125v-15.135q0-12.865 8.625-21.365Q267.25-780 280-780h75q12.75 0 21.375 8.625T385-750v15h125q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T510-675v415q0 24-18 42t-42 18H185Zm445-30q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T630-290h85q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T715-230h-85Zm0-184q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T630-474h175q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T805-414H630Zm0-184q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T630-658h220q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-598H630Zm-445-77v415h265v-415H185Z"
		/>
	</svg>
);
