import styled from 'styled-components';

import * as palette from 'pkg/config/palette';

import Icon from 'components/icon';

import InlinePlaceholder from 'design/placeholders/inline';

const Missing = styled(InlinePlaceholder)`
	border: 2px dashed ${palette.gray[400]};
	border-radius: var(--radius-5);
`;

const Deleted = () => {
	return (
		<Missing>
			<Icon name="library" />
			This exercise has been deleted
		</Missing>
	);
};

export default Deleted;
