import { ReactNode } from 'react';

export const symbol_other_houses: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M160-180v-341l-60 46q-10.348 8-22.174 6Q66-471 58-481t-6.056-22.19Q53.89-515.381 64-523l380-289q8-6 17.211-9.5 9.21-3.5 18.789-3.5t18.789 3.5Q508-818 516-812l381 290q9.941 7.619 11.471 19.81Q910-490 902-479.63q-8 9.63-19.5 11.13T861-475l-61-46v341q0 24.75-17.625 42.375T740-120H220q-24.75 0-42.375-17.625T160-180Zm60 0h520v-387L480-765 220-567v387Zm100-180q17 0 28.5-11.5T360-400q0-17-11.5-28.5T320-440q-17 0-28.5 11.5T280-400q0 17 11.5 28.5T320-360Zm160 0q17 0 28.5-11.5T520-400q0-17-11.5-28.5T480-440q-17 0-28.5 11.5T440-400q0 17 11.5 28.5T480-360Zm160 0q17 0 28.5-11.5T680-400q0-17-11.5-28.5T640-440q-17 0-28.5 11.5T600-400q0 17 11.5 28.5T640-360ZM220-180h520-520Z"
		/>
	</svg>
);
