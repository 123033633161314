import * as organizationServices from 'pkg/actions/services/organization';

import * as sdk from 'pkg/core/sdk';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';

import {
	deleteSplashScreenState,
	storeSplashScreenState,
} from 'components/splash-screen';

interface Invoiced {
	amount: number;
	count: number;
}

export interface InvoicedStatuses {
	open: Invoiced;
	paid: Invoiced;
	pastDue: Invoiced;
	uncollectible: Invoiced;
}

export interface InvoicedDateItems {
	dates: { [key: string]: { currentPeriod: number; lastYear: number } };
	totals: { currentPeriodTotal: number; lastYearTotal: number };
	showAs: 'days' | 'weeks' | 'months';
}

export interface SplashScreenPayload {
	enabled?: boolean;
	image?: string;
}

export const fetchPayouts = async (
	groupId: number
): Promise<organizationServices.IPayout[]> => {
	const request = await organizationServices.fetchPayouts(groupId);
	const response: organizationServices.IPayout[] = await request.json();

	if (!request.ok) {
		return;
	}

	return response;
};

export async function updateSplashScreen(
	organizationId: number,
	payload: SplashScreenPayload
): Promise<[boolean, SplashScreenPayload]> {
	const request = await sdk.patch(
		endpoints.Organizations.UpdateSplashScreen(organizationId),
		{},
		payload
	);

	if (request.ok) {
		const response = await request.json();

		return [true, response];
	}

	return [false, null];
}

export async function deleteSplashScreen(
	organizationId: number
): Promise<boolean> {
	const request = await sdk.destroy(
		endpoints.Organizations.DeleteSplashScreenImage(organizationId)
	);

	return request.ok;
}

export function hydrateSplashScreenStateFromGroup(group: models.group.Group) {
	const splash = models.group.getSplashScreenSettings(group);

	const badgeUrl = group.profileImageUrl ?? '';
	const imageUrl = splash?.imageUrl ?? '';
	const primaryColor = models.group.getPrimaryColorValue(group);
	const hasSplashScreenFeature = models.group.hasFeature(
		group,
		models.group.Features.SplashScreen
	);

	if (!hasSplashScreenFeature || !splash?.enabled) {
		deleteSplashScreenState();

		return;
	}

	storeSplashScreenState({
		primaryColor,
		badgeUrl,
		imageUrl,
	});
}

interface LokSettings {
	// LOK integration fields
	lokOrganizationNumber: string;
	lokSportId: number;
	lokFromTime: number;
}

export async function updateLOKSettings(
	organizationID: number,
	payload: LokSettings
): Promise<boolean> {
	const res = await sdk.patch(
		endpoints.Organizations.UpdateLOKSettings(organizationID),
		{},
		payload
	);
	return res.ok;
}
