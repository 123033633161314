import { ReactNode } from 'react';

export const symbol_speaker_notes_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M273-403q-17 0-28.5-11.5T233-443q0-17 11.5-28.5T273-483q17 0 28.5 11.5T313-443q0 17-11.5 28.5T273-403Zm607-417v551q0 12.75-8.573 21.375Q862.855-239 850.182-239 839-239 829.5-246.75 820-254.5 820-270v-550H250q-15 0-22.5-9.318t-7.5-20.5q0-11.182 7.5-20.682Q235-880 250-880h570q23 0 41.5 18.5T880-820ZM696-533H537q-15 0-22.5-9.318t-7.5-20.5q0-11.182 7.5-20.682Q522-593 537-593h159q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T696-533ZM240-240 131-131q-14 14-32.5 6.344T80-152v-657l-3-3q-9-7.822-9-19.911Q68-844 76.5-853q8.5-9 21-9.5T119-854L882-91q9 9 8.5 21T881-49q-9 9-21 9t-21-9L648-240H240Zm289-290Zm-256 7q-17 0-28.5-11.5T233-563q0-17 11.5-28.5T273-603q17 0 28.5 11.5T313-563q0 17-11.5 28.5T273-523Zm423-130H411q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T411-713h285q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T696-653ZM364-525ZM140-749v529l74-80h374L140-749Z"
		/>
	</svg>
);
