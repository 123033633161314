import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { t } from '@transifex/native';

import { Features } from 'pkg/models/group';

import * as flashActions from 'pkg/actions/flashes';

import { RootState } from 'pkg/reducers';
import * as actions from 'pkg/actions';
import * as selectors from 'pkg/selectors';
import store from 'pkg/store/createStore';
import { useGroup } from 'pkg/hooks/selectors';
import useFields from 'pkg/hooks/useTableFields';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCollection } from 'pkg/api/use_collection';
import * as models from 'pkg/api/models';
import { crash } from 'pkg/errors/errors';
import { Filters, toFilterQuery } from 'pkg/filters/use_filters';
import {
	FilterOperator,
	createFilter,
	createQueryFilter,
	QueryFilterObject,
} from 'pkg/filters';
import * as routes from 'pkg/router/routes';
import { link } from 'pkg/router/utils';
import { useCurrentMembership } from 'pkg/identity';

import ContactRow from 'routes/payments/contacts/TableRow';
import BillingConfirmModal from 'routes/organization/contacts/BillingConfirmModal';
import ArchiveConfirmModal from 'routes/organization/contacts/archive-confirm-modal';

import MergeContacts from 'containers/payment_platform/contacts/MergeContacts';
import AssignProductModal from 'containers/payment_platform/contacts/AssignProductModal';
import AddToSingleGroupModal from 'containers/payment_platform/contacts/AddToSingleGroupModal';

import AssetImage from 'components/AssetImage';
import { useExport } from 'components/export';
import { LargeScreen } from 'components/MediaQuery';
import Pagination from 'components/pagination';

import QuickFilter from 'components/layout/QuickFilter';
import * as Inputs from 'components/form/inputs';
import * as ActionBar from 'components/layout/ActionBar';
import Status from 'components/payment_platform/status';
import { RemoveUserItem, useRemoveUserDialog } from 'components/user/remove';

import * as ContextMenu from 'design/context_menu';
import * as Table from 'design/table';
import Button from 'design/button';

interface ContactTableViewProps {
	organizationId: number;
	filters: Filters;
	queryParams: URLSearchParams;
	extraFields?: { field: string; title: string }[];
	subGroupId?: number;
	defaultExportFilters?: { [key: string]: unknown };
	refreshUsers?: boolean;
	refreshCallBack?: () => void;
}

interface ItemProps {
	field: string;
	title: string;
}

const ContactTableView: React.FC<
	React.PropsWithChildren<ContactTableViewProps>
> = ({
	organizationId,
	filters,
	queryParams,
	extraFields = [],
	subGroupId,
	defaultExportFilters,
	refreshUsers,
	refreshCallBack = null,
}) => {
	const [modal, setModal] = useState<{
		show: boolean;
		name: string;
		userId?: number;
	}>({
		show: false,
		name: '',
		userId: 0,
	});
	const [showOnlySelected, setShowOnlySelected] = useState(false);
	const organization = useGroup(organizationId);
	const membership = useCurrentMembership();

	const activeProductFilters = filters.currentFilters.find(
		(filter) =>
			filter.property === 'product_ids' &&
			filter.operator === FilterOperator.Includes
	);

	const productFilters: QueryFilterObject = {};

	if (activeProductFilters?.values) {
		productFilters.id = createQueryFilter({
			operator: FilterOperator.Includes,
			values: activeProductFilters?.values,
		});
	} else {
		productFilters.isDefault = createQueryFilter({
			operator: FilterOperator.Equals,
			values: [true],
		});
	}

	const { records: products } = useCollection<models.product.Product>(
		endpoints.Products.Index(),
		{
			queryParams: new URLSearchParams({
				group_id: organizationId.toString(),
				filters: toFilterQuery(productFilters),
			}),
		}
	);

	if (subGroupId) {
		queryParams.set('group_id', [subGroupId].toString());
	}

	const displayCountPerPage = 50;

	const {
		records: users,
		isLoading,
		pagination,
		selection,
		refresh,
		sort,
		selectedRecords: selectedUserRecords,
	} = useCollection<models.user.User>(
		endpoints.Organizations.ListUsers(organizationId),
		{
			queryParams,
			count: displayCountPerPage,
			useLocationPagination: true,
			defaultSortBy: 'id',
			defaultSortByOrder: 'desc',
			showOnlySelected,
		}
	);
	const { records: userFields, isLoading: isLoadingUserFields } =
		useCollection<models.userFields.UserField>(endpoints.UserField.Index(), {
			queryParams: new URLSearchParams({
				group_id: organizationId.toString(),
			}),
		});

	useEffect(() => {
		if (refreshUsers) {
			refresh();

			if (refreshCallBack) {
				refreshCallBack();
			}
		}
	}, [refreshUsers]);

	const allSelectedContacts = users.filter((user: models.user.User) =>
		selection.selectedRecords.includes(user.id)
	);

	const selectedContactsWithAccounts = selectedUserRecords.filter(
		(u) => u.accountId
	);

	const disableArchive = allSelectedContacts.some(
		(contact) => contact.deletedAt > 0
	);

	const defaultFields: ItemProps[] = [
		{ field: 'name', title: t('Name') },
		{ field: 'email', title: t('Email') },
		{ field: 'billing_email', title: t('Billing email') },
		{ field: 'id', title: t('Created') },
		{ field: 'date_of_birth', title: t('Date of birth') },
	];

	if (organization.isLOKActive()) {
		defaultFields.push({ field: 'lok', title: t('LOK') });
	}

	const displayRole = extraFields?.findIndex((ef) => ef.field === 'role') > -1;
	const sortableFields: string[] = [
		'name',
		'email',
		'billing_email',
		'id',
		'date_of_birth',
	];

	const displayFields = useFields(products.map((product) => product.name));

	const getCheckedQuickFilters = () => {
		const checkedItems = filters.currentFilters.find(
			(filter) => filter.property === 'product_status'
		);

		if (!checkedItems) {
			return [];
		}

		if ('values' in checkedItems) {
			return checkedItems.values;
		}

		return [];
	};

	const handleOpenMerge = (): void => setModal({ show: true, name: 'merge' });

	const handleMergeFinished = (): void => {
		refresh();
		selection.deselectAll();
	};

	const handleAssignProductSingle = (userId: number): void =>
		setModal({ show: true, name: 'product', userId });

	const handleAssignProductMultiple = (): void =>
		setModal({ show: true, name: 'product', userId: 0 });

	const handleAddUsersToGroup = (): void =>
		setModal({ show: true, name: 'group' });

	const handleAddUserToGroup = (userId: number): void =>
		setModal({ show: true, name: 'group', userId });

	const handleMultipleBillingContacts = () =>
		setModal({ show: true, name: 'billingConfirm' });

	const handleShowArchiveModal = () =>
		setModal({ show: true, name: 'archiveConfirm' });

	const handleCloseModal = (): void =>
		setModal({ show: false, name: '', userId: 0 });

	const handleArchiveSingle = async (contactId: number) => {
		await actions.users.archiveUsers([contactId])(store.dispatch);
		refresh();
		return;
	};

	const handleRestoreSingle = async (contactId: number): Promise<void> => {
		await actions.users.restoreUser(contactId);
		refresh();
		return;
	};

	const handleRemoveUserFromGroup = async (
		removeUserRecursively: boolean,
		removeFromFutureEvents: boolean,
		contactId: number
	) => {
		const req = await actions.memberships.removeUserFromGroup(
			subGroupId,
			contactId,
			{ removeUserRecursively, removeFromFutureEvents }
		);

		if (req) {
			selection.deselectAll();
			refresh();
			return;
		}
	};

	const handleArchiveUsers = async (ids: number[]) => {
		await actions.users.archiveUsers(ids)(store.dispatch);
		selection.deselectAll();
		refresh();
		return;
	};

	const handleArchiveMultiple = async (): Promise<void> => {
		if (selectedContactsWithAccounts.length > 0) {
			handleShowArchiveModal();
		} else {
			handleArchiveUsers(selection.selectedRecords as number[]);
		}
	};

	const handleArchiveConfirm = async (users: models.user.User[]) => {
		const userIds = users.map((u) => u.id);
		const recordIdsToRemove = selection.selectedRecords.filter(
			(id) => !userIds.includes(id as number)
		);

		handleArchiveUsers(recordIdsToRemove as number[]);
	};

	const getColumn = (
		field: string,
		title: string,
		extraOptions?: Table.HeaderColumn
	): Table.HeaderColumn => {
		const content = title;

		let columnOptions: Table.HeaderColumn = {
			content,
			hide: displayFields.disabledFields.includes(content),
		};

		if (extraOptions) {
			columnOptions = {
				...columnOptions,
				...extraOptions,
			};
		}

		if (sortableFields.includes(field)) {
			columnOptions.sortKey = field;
		}

		return columnOptions;
	};

	const handleSelectAll = () => {
		selection.selectAll();

		if (showOnlySelected) {
			setShowOnlySelected(false);
		}
	};

	const selectAllCheckBox = (
		<Inputs.Control
			testid="check-all"
			type="checkbox"
			standalone
			checked={selection.isAllSelected}
			disabled={users.length === 0}
			onChange={handleSelectAll}
		/>
	);

	const selectedContactsEmails = allSelectedContacts
		.filter((user: models.user.User) => user.email)
		.map((user: models.user.User) => user.email)
		.join(',');

	const selectedContactsBillingEmails = allSelectedContacts
		.map((user: models.user.User) => models.user.getBillingEmail(user))
		.filter((email) => email)
		.join(',');

	const country = useSelector((state: RootState) =>
		selectors.groups.findGroupCountry(state, { groupId: organization.id })
	);

	const m = useCurrentMembership();

	const fields = models.organization.getExportUsersFields({
		country,
		customFields: userFields,
		membership: m,
	});

	const createExport = async (fields: string[]) => {
		const payload: { [key: string]: boolean | string[] } = {};

		fields.forEach((s) => {
			if (s.includes('custom.')) {
				const [, key] = s.split('.');
				// 'fields' will contain all the custom user fields
				payload['fields'] = [...((payload['fields'] as string[]) || []), key];
			} else {
				payload[s] = true;
			}
		});

		fields.forEach((s) => (payload[s] = true));

		let params = new URLSearchParams('');

		if (filters.queryParam) {
			params = filters.queryParam;
		} else if (!!defaultExportFilters) {
			params = new URLSearchParams(defaultExportFilters.toString());
		}

		const [ok, csvData] = await models.organization.exportUsers(
			organization.id,
			params,
			payload
		);

		if (ok) {
			const fileName = `${organization.name}.csv`;

			const url = URL.createObjectURL(new Blob([csvData]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', fileName);
			document.body.appendChild(link);
			link.click();
			link.remove();
		} else {
			const err = crash();
			flashActions.show({
				title: err.title,
				message: err.description,
			});
		}
	};

	const exportAction = useExport(fields, createExport);

	const emptyState = {
		title: t('No contacts found'),
		content: t('No contacts were found here'),
		image: <AssetImage src="img/missing-entities/post.svg" />,
		button: (
			<Button
				primary
				href={routes.Management.Contact.Create(organization.id)}
				icon="add">
				{t('Add contact')}
			</Button>
		),
	};

	const handleQuickFilter = (name: string) => {
		filters.setFilter(
			createFilter({
				type: 'checkbox',
				property: 'product_status',
				operator: FilterOperator.Includes,
			}),
			[name],
			true,
			true
		);
	};

	const handleAddToGroup = async (groupId: number, role: number) => {
		const [request] = await models.create(
			endpoints.UserGroups.AddUsersToGroup(groupId),
			modal.userId
				? [{ id: modal.userId, role }]
				: selection.selectedRecords.map((userId) => ({
						id: userId,
						role,
					}))
		);

		if (request.ok) {
			actions.flashes.show({
				title: t('Added users to group'),
			});
		} else {
			actions.flashes.show({
				title: t('Unable to add users to group'),
			});
		}
	};

	const handleRemoveUsers = async (
		removeUsersRecursively: boolean,
		removeFromFutureEvents: boolean
	) => {
		const ok = await actions.memberships.removeUsersFromGroup(subGroupId, {
			userIds: selection.selectedRecords as number[],
			removeUsersRecursively,
			removeFromFutureEvents,
		});

		if (ok) {
			selection.deselectAll();
			refresh();
		}
	};

	const removeUsersDialog = useRemoveUserDialog({ handleRemoveUsers });

	return (
		<Fragment>
			<ActionBar.IntegratedFilterBar
				pageActionIcon="more_horiz"
				searchFilter={{
					type: 'text',
					operator: FilterOperator.Contains,
					property: 'name',
				}}
				filters={filters}
				actions={[
					models.membership.isAdmin(membership) && {
						label: t('Export'),
						icon: 'download',
						onClick: exportAction.open,
					},
					products.length > 0 && {
						label: t('Fields'),
						icon: 'view_week',
						contextMenuItems: displayFields.contextMenuItems,
					},
					{
						label: t('New contact'),
						href: routes.Management.Contact.Create(organizationId),
						type: 'primary',
						icon: 'person_add',
						testid: 'contacts.new',
					},
				]}
			/>
			{products.length > 0 && (
				<LargeScreen>
					<QuickFilter
						onCheck={handleQuickFilter}
						selectedFilters={getCheckedQuickFilters() as string[]}
						filters={[
							{
								name: 'active',
								label: <Status name="active">{t('Active')}</Status>,
							},
							{
								name: 'past_due',
								label: <Status name="past_due">{t('Past Due')}</Status>,
							},
							{
								name: 'expired',
								label: <Status name="expired">{t('Expired')}</Status>,
							},
							{
								name: 'open',
								label: <Status name="open">{t('Open')}</Status>,
							},
						]}
					/>
				</LargeScreen>
			)}
			<ActionBar.BulkActions
				numSelected={selection.selectedRecords.length}
				showSelected={showOnlySelected}
				setShowSelected={setShowOnlySelected}
				actions={[
					organization.hasFeature(Features.Payments) && (
						<Fragment>
							<ContextMenu.LinkItem
								href={link(routes.Invoice.New(organizationId), {
									groupId: organizationId,
									userIds: selection.selectedRecords,
								})}>
								<ContextMenu.ItemIcon name="request_quote" />
								{t('New order')}
							</ContextMenu.LinkItem>
							<ContextMenu.Item onClick={handleAssignProductMultiple}>
								<ContextMenu.ItemIcon name="tag" />
								{t('Assign Product')}
							</ContextMenu.Item>
						</Fragment>
					),
					<ContextMenu.Item onClick={handleMultipleBillingContacts}>
						<ContextMenu.ItemIcon name="person_add" />
						{t('Assign billing contacts')}
					</ContextMenu.Item>,
					selection.selectedRecords.length === 2 && (
						<ContextMenu.Item onClick={handleOpenMerge}>
							<ContextMenu.ItemIcon name="merge" />
							{t('Merge')}
						</ContextMenu.Item>
					),
					selection.selectedRecords.length > 0 && (
						<ContextMenu.LinkItem
							href={`mailto:?bcc=${selectedContactsEmails}`}>
							<ContextMenu.ItemIcon name="mail" />
							{t('Send email')}
						</ContextMenu.LinkItem>
					),
					selection.selectedRecords.length > 0 && (
						<ContextMenu.LinkItem
							href={`mailto:?bcc=${selectedContactsBillingEmails}`}>
							<ContextMenu.ItemIcon name="mail" />
							{t('Send email to billing address')}
						</ContextMenu.LinkItem>
					),
					<ContextMenu.Item onClick={handleAddUsersToGroup}>
						<ContextMenu.ItemIcon name="groups" />
						{t('Add users to group')}
					</ContextMenu.Item>,
					<ContextMenu.ConfirmItem
						onConfirm={handleArchiveMultiple}
						caution
						message={t('Are you sure you want to archive this contact?')}
						disabled={disableArchive}
						tooltip={
							disableArchive && t("Can't archive already archived contacts")
						}>
						<ContextMenu.ItemIcon name="archive" />
						{t('Archive contacts')}
					</ContextMenu.ConfirmItem>,
					subGroupId && <RemoveUserItem bulk dialog={removeUsersDialog} />,
				]}
			/>
			<Table.Table
				emptyState={emptyState}
				stickyHeader
				stickyFooter
				sortBy={sort.column}
				sortOrder={sort.order}
				onSort={sort.setSort}
				columns={[
					{
						content: selectAllCheckBox,
						width: '50px',
					},
					getColumn('name', t('Name')),
					{ content: '', width: 'max-content' },
					getColumn('email', t('Email')),
					getColumn('billing_email', t('Billing email')),
					getColumn('date_of_birth', t('Date of birth')),
					getColumn('id', t('Created')),
					organization.isLOKActive() ? getColumn('lok', t('LOK')) : null,
					displayRole && getColumn('role', t('Role')),
					...products.map((product) => getColumn(product.name, product.name)),
					{ content: '', width: 'max-content' },
				].filter((n) => n)}
				isLoading={isLoading || isLoadingUserFields}>
				{users.map((user) => (
					<ContactRow
						key={user.id}
						organization={organization}
						contact={user}
						isSelected={selection.isSelected(user.id)}
						products={products}
						onSelect={selection.selectSingle}
						onAssignProduct={handleAssignProductSingle}
						onArchiveContact={handleArchiveSingle}
						onRestoreContact={handleRestoreSingle}
						onAddUserToGroup={handleAddUserToGroup}
						onRemoveUserFromGroup={handleRemoveUserFromGroup}
						displayRole={displayRole}
						subGroupId={subGroupId}
					/>
				))}
			</Table.Table>
			{!showOnlySelected && <Pagination {...pagination} />}
			{modal.show && modal.name === 'merge' && (
				<MergeContacts
					users={selection.selectedRecords as number[]}
					onMergeFinished={handleMergeFinished}
					onClose={handleCloseModal}
				/>
			)}
			{modal.show && modal.name === 'product' && (
				<AssignProductModal
					organizationId={organizationId}
					userIds={
						modal.userId
							? [modal.userId]
							: (selection.selectedRecords as number[])
					}
					onClose={handleCloseModal}
				/>
			)}
			{modal.show && modal.name === 'group' && (
				<AddToSingleGroupModal
					groupId={organizationId}
					onConfirm={handleAddToGroup}
					onClose={handleCloseModal}
				/>
			)}
			{modal.show && modal.name === 'billingConfirm' && (
				<BillingConfirmModal
					userIds={selection.selectedRecords as number[]}
					onClose={handleCloseModal}
				/>
			)}
			{modal.show && modal.name === 'archiveConfirm' && (
				<ArchiveConfirmModal
					users={selectedContactsWithAccounts}
					handleArchiveConfirm={handleArchiveConfirm}
					closeModal={handleCloseModal}
				/>
			)}
			{exportAction.modal}
		</Fragment>
	);
};

export default ContactTableView;
