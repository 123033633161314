import { Fragment } from 'react';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import useTooltip from 'pkg/hooks/useTooltip';
import DateTime from 'pkg/datetime';
import Link from 'pkg/router/Link';

import { useSmallScreen } from 'components/MediaQuery';
import Label from 'components/label';
import Icon from 'components/icon';
import * as CardList from 'components/card-list';

import * as css from './styles.css';

interface LocationProps {
	location: models.resourceLocation.ResourceLocation;
}

function Location({ location }: LocationProps) {
	if (!location) {
		return null;
	}

	const locationQuery = encodeURIComponent(location.title);

	return (
		<Link
			className={css.location}
			href={`https://www.google.com/maps/search/?api=1&query=${locationQuery}`}>
			{location.title}
		</Link>
	);
}

interface ResourceCardItemProps {
	resource: models.resource.Resource;
	actions?: JSX.Element;

	isPrimary?: boolean;
}

const ResourceCardItem = ({
	resource,
	actions = null,
	isPrimary = false,
}: ResourceCardItemProps) => {
	const { onMouseEnter, tooltip } = useTooltip(
		t(
			'Setting the main resource determines which location to use for the booking'
		)
	);
	const isSmallScreen = useSmallScreen();

	const removedAt = DateTime.fromTimestamp(resource?.deletedAt);

	return (
		<Fragment>
			<CardList.Base className={css.cardBase}>
				<CardList.IconWrapper>
					<Icon name={resource?.category?.icon || 'resource'} />
				</CardList.IconWrapper>
				<CardList.Title className={css.titleWrapper}>
					<div>{resource?.title}</div>
					<Location location={resource?.location} />
					<div className={css.tags}>
						{resource?.deletedAt && (
							<Label small={isSmallScreen} color="gray">
								{t('Deleted {date}', {
									date: removedAt.toLocaleDateString(),
								})}
							</Label>
						)}
						{isPrimary && (
							<Label
								small={isSmallScreen}
								color="green"
								onMouseEnter={onMouseEnter}>
								{t('Main resource')}
							</Label>
						)}
						{resource?.category && (
							<Label small={isSmallScreen} color="blue">
								{resource.category?.title}
							</Label>
						)}
					</div>
				</CardList.Title>
				<CardList.Actions>
					<div className={css.actionsContent}>{actions}</div>
				</CardList.Actions>
			</CardList.Base>
			{tooltip}
		</Fragment>
	);
};

export default ResourceCardItem;
