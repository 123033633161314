import { ReactNode } from 'react';

export const symbol_sensor_occupied: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-520q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35Zm0-60q25 0 42.5-17.5T540-640q0-25-17.5-42.5T480-700q-25 0-42.5 17.5T420-640q0 25 17.5 42.5T480-580ZM240-340v-16q0-26 14-45t46-36q42-22 87-32.5t93-10.5q48 0 93 10.5t87 32.5q32 17 46 36t14 45v16q0 25-17.5 42.5T660-280H300q-25 0-42.5-17.5T240-340Zm240-80q-48 0-93 13t-87 39v28h360v-28q-42-26-87-39t-93-13Zm191-434q-12-6-17-18t0-24q5-12 17.5-15.5T696-909q69 35 123.5 89.5T909-696q6 12 2 24.5T895-654q-12 5-24 0t-18-17q-30-59-77-106t-105-77Zm-384 1q-60 30-105 75.5T107-672q-6 12-18 17.5t-24 .5q-12-5-16-17.5t2-24.5q35-69 89.5-123.5T264-909q12-6 25-2t18 16q5 12-1.5 24T287-853ZM107-289q30 60 76 105.5T289-107q12 6 17.5 18t.5 24q-5 12-18 16t-25-2q-69-35-123.5-89.5T51-264q-6-12-2-25t16-18q12-5 24 .5t18 17.5Zm746 1q6-12 18-18t24-1q12 5 16 18t-2 25q-35 69-89.5 123.5T696-51q-12 6-24.5 2T654-65q-5-12 0-24t17-18q59-30 105.5-76T853-288ZM480-640Zm0 300h180-360 180Z"
		/>
	</svg>
);
