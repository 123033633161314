import { useT } from '@transifex/react';

import spacing from 'pkg/config/spacing';

import * as models from 'pkg/api/models';
import Link from 'pkg/router/Link';
import * as routes from 'pkg/router/routes';
import { link } from 'pkg/router/utils';

import Subscriptions from 'routes/organization/user-profile/overview/payments/Subscriptions';
import Invoices from 'routes/organization/user-profile/overview/payments/Invoices';
import Products from 'routes/organization/user-profile/overview/payments/Products';

import MaterialSymbol from 'components/material-symbols';

import Column from 'components/layout/column';
import { Spinner } from 'components/loaders/spinner';
import Row from 'components/layout/row';

import * as Card from 'design/card';

import * as css from './styles.css';

interface OrganizationUserProfilePaymentsProps {
	organizationId: number;
	user: models.user.User;
	isLoading: boolean;
}

export default function OrganizationUserProfilePayments({
	organizationId,
	user,
	isLoading,
}: OrganizationUserProfilePaymentsProps): JSX.Element {
	const t = useT();

	const showInvoices: boolean = models.hasAllowedAction(
		user,
		models.Action.UserListOrders
	);

	const showSubscriptions: boolean = models.hasAllowedAction(
		user,
		models.Action.UserListSubscriptions
	);

	const showProducts: boolean = models.hasAllowedAction(
		user,
		models.Action.UserListProducts
	);

	const canCreateInvoice = models.hasAllowedAction(
		user,
		models.Action.UserCreateInvoice
	);

	const invoiceLink = link(routes.Invoice.New(organizationId), {
		userIds: [user.id],
		returnUrl: routes.Organization.User.Profile.Payments(
			organizationId,
			user.id
		),
	});

	if (isLoading) {
		return <Spinner />;
	}

	return (
		<Column spacing={spacing._8}>
			{/* Subscriptions */}
			{showSubscriptions && (
				<Column spacing={spacing._4}>
					<Row
						autoColumns="max-content"
						align="center"
						justifyContent="space-between">
						<Row spacing={spacing._3} align="center" autoColumns="max-content">
							<MaterialSymbol scale={1.3} actualSize variant="sync" />
							<div className={css.tableTitle}>{t('Subscriptions')}</div>
						</Row>
						{canCreateInvoice && (
							<Link href={invoiceLink} className={css.newAction}>
								+ {t('New')}
							</Link>
						)}
					</Row>
					<Card.Base $noBorder>
						<Card.Body $noSpacing>
							<Subscriptions
								user={user}
								showViewAll
								organizationId={organizationId}
							/>
						</Card.Body>
					</Card.Base>
				</Column>
			)}

			{/* Invoices */}
			{showInvoices && (
				<Column spacing={spacing._4}>
					<Row
						autoColumns="max-content"
						align="center"
						justifyContent="space-between">
						<Row spacing={spacing._3} align="center" autoColumns="max-content">
							<MaterialSymbol scale={1.3} actualSize variant="paid" />
							<div className={css.tableTitle}>{t('Invoices')}</div>
						</Row>
						{canCreateInvoice && (
							<Link href={invoiceLink} className={css.newAction}>
								+ {t('New')}
							</Link>
						)}
					</Row>
					<Card.Base $noBorder>
						<Card.Body $noSpacing>
							<Invoices
								user={user}
								showViewAll
								organizationId={organizationId}
							/>
						</Card.Body>
					</Card.Base>
				</Column>
			)}

			{/* Products */}
			{showProducts && (
				<Column spacing={spacing._4}>
					<Row
						autoColumns="max-content"
						align="center"
						justifyContent="space-between">
						<Row spacing={spacing._3} align="center" autoColumns="max-content">
							<MaterialSymbol scale={1.3} actualSize variant="sell" />
							<div className={css.tableTitle}>{t('Products')}</div>
						</Row>
						<Link
							href={link(
								routes.Organization.User.Profile.Products(
									organizationId,
									user.id
								),
								{ openProductModal: 'true' }
							)}
							className={css.newAction}>
							+ {t('Assign')}
						</Link>
					</Row>
					<Card.Base $noBorder>
						<Card.Body $noSpacing>
							<Products
								user={user}
								showViewAll
								organizationId={organizationId}
							/>
						</Card.Body>
					</Card.Base>
				</Column>
			)}
		</Column>
	);
}
