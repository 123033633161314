import { t } from '@transifex/native';
import { useRef } from 'react';

import useContactForm, {
	FormStateData,
} from 'routes/payments/hooks/useContactForm';

import Dialog from 'components/Dialog';

import Form, { FormPayload } from 'components/form/Form';

import Button from 'design/button';

interface CreateProps {
	groupId: number;
	onConfirm: (data: FormPayload, contactData: FormStateData) => void;
	onClose: () => void;
}

const CreateModal: React.FC<React.PropsWithChildren<CreateProps>> = ({
	groupId,
	onConfirm,
	onClose,
}) => {
	const formRef = useRef(null);
	const { contactData, DetailsSection } = useContactForm({
		groupId,
		emailRequired: true,
	});

	const handleConfirm = (data: FormPayload) => {
		onConfirm(data, contactData);
		onClose();
	};

	const submitForm = async () =>
		await formRef.current.dispatchEvent(
			new Event('submit', {
				cancelable: true,
				bubbles: true,
			})
		);

	return (
		<Form onSubmit={handleConfirm} formRef={formRef}>
			<Dialog
				heading={t(`Create billing contact`)}
				confirmButton={
					<Button primary icon="add" testid="contacts.billing_contact_save">
						{t(`Create and select`)}
					</Button>
				}
				cancelButton={<Button transparent>{t('Cancel')}</Button>}
				onConfirm={submitForm}
				onClose={onClose}>
				{DetailsSection}
			</Dialog>
		</Form>
	);
};

export default CreateModal;
