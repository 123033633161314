import { ReactNode } from 'react';

export const symbol_lock_open: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M220-80q-24.75 0-42.375-17.625T160-140v-434q0-24.75 17.625-42.375T220-634h390v-96q0-54.167-37.917-92.083Q534.167-860 480-860q-47.6 0-83.3 30-35.7 30-44.7 75-2 11-11 18t-20.719 7Q307-730 299-740q-8-10-6-23 11-67 63.5-112T480-920q78.85 0 134.425 55.575Q670-808.85 670-730v96h70q24.75 0 42.375 17.625T800-574v434q0 24.75-17.625 42.375T740-80H220Zm0-60h520v-434H220v434Zm260.168-140Q512-280 534.5-302.031T557-355q0-30-22.668-54.5t-54.5-24.5Q448-434 425.5-409.5t-22.5 55q0 30.5 22.668 52.5t54.5 22ZM220-140v-434 434Z"
		/>
	</svg>
);
