import { ReactNode } from 'react';

export const symbol_priority: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M351-120q-97 0-164-67t-67-164v-258q0-97 67-164t164-67h258q97 0 164 67t67 164v258q0 97-67 164t-164 67H351Zm88-291-76-76q-9-9-21.5-8.5T320-486q-9 9-9 21.5t9 21.5l98 97q9 9 21 9t21-9l198-198q9-9 9-21.5t-9-21.5q-9-9-21.5-9t-21.5 9L439-411Zm-88 231h258q71 0 121-50t50-121v-258q0-71-50-121t-121-50H351q-71 0-121 50t-50 121v258q0 71 50 121t121 50Zm129-300Z"
		/>
	</svg>
);
