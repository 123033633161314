import { ReactNode } from 'react';

export const symbol_sensors: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-480q0 63 21 117.5t58 99.5q8 10 8.5 22.5T219-219q-9 9-22.5 8.5T174-222q-45-52-69.5-117.5T80-480q0-75 24.5-140.5T174-738q9-11 22.5-11.5T219-741q9 9 8.5 21.5T219-697q-37 45-58 99.5T140-480Zm160 0q0 29 8.5 55.5T333-376q8 11 7.5 23.5T331-331q-9 9-21 8.5T290-333q-23-30-36.5-67.5T240-480q0-42 13.5-79.5T290-627q8-10 20.5-10.5T332-629q9 9 9.5 21.5T334-584q-16 22-25 48.5t-9 55.5Zm180 80q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm180-80q0-29-8.5-55.5T627-584q-8-11-7.5-23.5T629-629q9-9 21-8.5t20 10.5q23 30 36.5 67.5T720-480q0 42-13.5 78.5T670-334q-8 10-20.5 10.5T628-332q-9-9-9.5-21t7.5-23q16-22 25-48.5t9-55.5Zm160 0q0-63-21-117.5T741-697q-8-10-8.5-23t8.5-22q9-9 23-8t22 12q45 53 69.5 118.5T880-480q0 74-24.5 139.5T786-222q-8 11-22 11.5t-23-8.5q-9-9-8.5-21.5T741-263q37-45 58-99.5T820-480Z"
		/>
	</svg>
);
