import { ReactNode } from 'react';

export const symbol_flash_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m664-820-64 220h74q26.873 0 39.937 24Q727-552 711-529l-59 85q-8.426 11.053-22.213 12.526Q616-430 606-440q-8-8-8.5-18t5.5-19l43-63h-86q-15.455 0-24.727-12Q526-564 531-578l70-242H310q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T310-880h308q23 0 38 18.5t8 41.5ZM792-83 561-313 441-139q-5 7-11.5 8.5t-13.4-.656q-6.9-2.156-11.5-7.547T400-152v-248h-60q-24.75 0-42.375-17.625T280-460v-134L82-792q-9-9.067-9-21.533Q73-826 82.053-835q9.052-9 21.5-9Q116-844 125-835l710 710q9 9 9 21t-9.053 21q-9.052 9-21.5 9Q801-74 792-83ZM463-639Z"
		/>
	</svg>
);
