import { ReactNode } from 'react';

export const symbol_upgrade: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M310-160q-13 0-21.5-8.5T280-190q0-13 8.5-21.5T310-220h340q13 0 21.5 8.5T680-190q0 13-8.5 21.5T650-160H310Zm170-170q-13 0-21.5-8.5T450-360v-326L350-586q-9 9-21 9t-21-9q-9-9-9-21t9-21l151-151q5-5 10-7t11-2q6 0 11 2t10 7l151 151q9 9 9 21t-9 21q-9 9-21 9t-21-9L510-686v326q0 13-8.5 21.5T480-330Z"
		/>
	</svg>
);
