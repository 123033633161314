import * as sdk from 'pkg/core/sdk';
import * as endpoints from 'pkg/api/endpoints/auto';
import { PaymentPreview } from 'pkg/api/models/payment_preview';

export interface PaymentRow {
	productPriceId: number;
	quantity: number;
	taxRateId?: number;
}

export interface PaymentPreviewPayload {
	groupId: number;
	discountId?: number;
	installmentCount?: number;
	subscriptionStartAt?: number;
	delayedSubscriptionStart?: boolean;
	bundleNumber?: number;
	rows: PaymentRow[];
}

export async function getPreview(
	payload: PaymentPreviewPayload
): Promise<[boolean, PaymentPreview]> {
	const resp = await sdk.post(endpoints.Payments.Preview(), {}, payload);
	const json = await resp.json();

	if (!resp.ok) {
		return [false, null];
	}

	return [true, json];
}

export async function getBundlesPreviews(payload: PaymentPreviewPayload[]) {
	const resp = await sdk.post(endpoints.Payments.BundlesPreview(), {}, payload);
	const json = await resp.json();

	if (!resp.ok) {
		return [false, null];
	}

	return [true, json];
}
