import { ReactNode } from 'react';

export const symbol_battery_unknown: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M680-354q-12 0-21.5 5.5T642-334q-5 8-14.257 11t-17.486-1Q601-328 597-337t1-17q12.843-21 34.578-33.5Q654.313-400 680-400q37 0 64.5 24t27.5 60q0 11-3 20.5t-9 17.5q-11 16-26 28.5T710-220q0 1-5 14-2 10-8.5 17t-16.5 7q-11 0-18-7.5t-6-17.5q1-8 2.5-14.5T664-235q10-17 25.5-29t26.5-28q3-4 7-23 0-17-13-28t-30-11Zm0 274q-14 0-24-9.5T646-113q0-14 10-24t24-10q14 0 23.5 10t9.5 24q0 14-9.5 23.5T680-80Zm-340-60Zm-30 60q-12.75 0-21.375-8.625T280-110v-676q0-12.75 8.625-21.375T310-816h90v-34q0-12.75 8.625-21.375T430-880h100q12.75 0 21.375 8.625T560-850v34h90q12.75 0 21.375 8.625T680-786v298q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T620-488v-268H340v616h169q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q539-97 530.375-88.5T509-80H310Z"
		/>
	</svg>
);
