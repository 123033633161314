import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t } from '@transifex/native';

import Rating from 'pkg/models/rating';
import Position from 'pkg/models/position';
import PositionScore from 'pkg/models/position_score';

import * as actions from 'pkg/actions';
import * as selectors from 'pkg/selectors';
import { RootState } from 'pkg/reducers';
import { useCheckRelationToUser, useUser } from 'pkg/hooks/selectors';
import * as routes from 'pkg/router/routes';
import { useCurrentMembership, useCurrentOrganization } from 'pkg/identity';

import PositionDetails from 'containers/user/rating/PositionDetails';

import CardBase from 'components/Card';
import AssetImage from 'components/AssetImage';
import SectionTitle from 'components/SectionTitle';

import Column from 'components/layout/column';
import AssessmentCreate from 'components/assessment/Create';

import MissingEntities from 'design/placeholders/block';
import Button from 'design/button';

type Props = {
	userId: number;
	groupId: number;
};

const PositionMatch = ({ userId, groupId }: Props): JSX.Element => {
	const dispatch = useDispatch();
	const org = useCurrentOrganization();

	const [showAssessmentForm, setShowAssessmentForm] = useState(false);

	const maturityAdjusted: number = useSelector(
		(state: RootState) => state.ratings.maturityAdjustment
	);
	const activeMembership = useCurrentMembership();
	const user = useUser(userId);

	const rating: Rating = useSelector((state: RootState) =>
		selectors.ratings.getUserPrimaryRating(state, { groupId, userId })
	);

	const positionScores: PositionScore[] = useSelector((state: RootState) =>
		selectors.ratings.getPositionScores(state, {
			ratingId: rating?.id,
		})
	);

	const position: Position = useSelector((state: RootState) =>
		selectors.positions.getCurrentPositionId(state)
	);

	const { isParentToUser } = useCheckRelationToUser(userId);
	const forAccount = isParentToUser ? user.accountId : null;

	useEffect(() => {
		dispatch(actions.positions.deprecatedFetchPositions(forAccount));
	}, [dispatch]);

	useEffect(() => {
		dispatch(
			actions.ratings.fetchUsersPrimaryRating(userId, groupId, forAccount)
		);
	}, [dispatch, groupId, userId]);

	const showAssessment = (): void => setShowAssessmentForm(true);
	const hideAssessment = (): void => setShowAssessmentForm(false);

	const handleMaturityAdjustment = (): void => {
		dispatch(actions.ratings.toggleMaturityAdjustment(!maturityAdjusted));
	};

	return (
		<Column>
			<SectionTitle>
				{t('Assessments')}
				{rating?.id ? (
					<Button
						inline
						noPadding
						href={routes.User.Profile.PositionMatch(
							org.id,
							groupId,
							userId,
							rating.id
						)}
						label={t('View details')}
					/>
				) : null}
			</SectionTitle>
			{rating?.id ? (
				<CardBase $noBorder>
					<PositionDetails
						rating={rating}
						ratingId={rating.id}
						comparison={new Rating()}
						maturityAdjusted={maturityAdjusted}
						handleMaturityAdjustmentToggle={handleMaturityAdjustment}
						positionScores={positionScores}
						isChild={!user.isAnAdult}
						position={position}
					/>
				</CardBase>
			) : (
				<MissingEntities
					noBorder
					title={
						activeMembership.role === 2
							? t(`Analyze your players!`)
							: t(`Get to know your best positions!`)
					}
					description={
						activeMembership.role === 2
							? t(
									`Create an assessment to analyze your players and see their best position, while also generating individual training.`
								)
							: t(
									`Create an assessment to analyze your best positions and to get indiviudal training recommendations.`
								)
					}
					helpUrl="https://help.360player.com/article/43-how-to-create-an-assessment"
					actions={
						<Button
							icon="add"
							secondary
							label={t(`Create an assessment`)}
							onClick={showAssessment}
						/>
					}
					image={
						<AssetImage
							className="temporary"
							src="img/missing-entities/position-match.svg"
						/>
					}
				/>
			)}
			{showAssessmentForm && (
				<AssessmentCreate
					userId={userId}
					groupId={groupId}
					userRole={activeMembership ? activeMembership.role : 1}
					onClose={hideAssessment}
					forAccount={forAccount}
				/>
			)}
		</Column>
	);
};

export default PositionMatch;
