import styled from 'styled-components';

import { PageWidths } from 'pkg/config/sizes';
import { small } from 'pkg/config/breakpoints';

import CardWrapper, * as Card from 'components/Card';
import Placeholder, { CardHeader } from 'components/Placeholder';

import PM from 'components/user/rating/PositionMap';

const PositionMap = styled(PM)`
	margin-top: 2rem;
	height: auto;
	width: 70%;
	margin-left: auto;
	margin-right: auto;

	@media ${small} {
		width: 100%;
	}
`;

const Wrapper = styled(CardWrapper)`
	max-width: ${PageWidths.STANDARD}px;
	margin: 0 auto;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	width: 100%;
	position: relative;
	margin-top: 1rem;

	${Card.Body} {
		@media ${small} {
			padding-bottom: 2rem;
		}
	}

	@media not ${small} {
		align-self: start;
	}

	@media ${small} {
		display: grid;
		margin-top: 0;

		grid-template-rows: ${(props) =>
			props.hasHeader ? '40px 1fr 60px' : '1fr 60px'};
		grid-template-rows: ${(props) =>
			props.hasHeader
				? '40px 1fr calc(env(safe-area-inset-bottom) + 60px)'
				: '1fr calc(env(safe-area-inset-bottom) + 60px)'};
	}
`;

export default class AssessmentPlaceholder extends Placeholder {
	getPlaceholderItem(key) {
		return (
			<Wrapper key={key}>
				<Card.Header>
					<CardHeader />
				</Card.Header>
				<Card.Divider />
				<Card.Body>
					<PositionMap />
				</Card.Body>
			</Wrapper>
		);
	}
}
