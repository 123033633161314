import {
	createContext,
	ReactNode,
	useContext,
	useEffect,
	useMemo,
} from 'react';

import useMixedState from 'pkg/hooks/useMixedState';
import * as object from 'pkg/objects';

import { MaterialSymbolVariant } from 'components/material-symbols/symbols';

export interface PageAction {
	href?: string;
	onClick?: () => void;
	icon?: MaterialSymbolVariant;
	label: string;
	// If contextMenuItems is set the item will trigger a context menu.
	contextMenuItems?: JSX.Element[];
	testid?: string;
}

interface PageActionsContextValues {
	icon?: MaterialSymbolVariant;
	actions?: PageAction[];
}

interface PageActionsContextActions {
	setActions?: (actions: PageAction[], icon: MaterialSymbolVariant) => void;
}

interface PageActionsContext
	extends PageActionsContextActions,
		PageActionsContextValues {}

const PageActionsContext = createContext<PageActionsContext>({});

// usePageActions sets page actions for the current view
export function usePageActions(
	actions: PageAction[],
	icon: MaterialSymbolVariant = 'add'
) {
	const ctx = useContext(PageActionsContext);
	const memoizedActions = useMemo(() => actions, [actions]);

	if (!actions) {
		actions = [];
	}

	useEffect(() => {
		// Deep comparison logic to check for changes in actions
		const actionsChanged = memoizedActions?.some((action, index) => {
			const oldAction = ctx.actions[index];
			return !object.deepCompareObjects(action, oldAction);
		});

		// Only update if actions have changed
		if (actionsChanged || icon !== ctx.icon) {
			ctx.setActions(memoizedActions, icon);
		}
	}, [memoizedActions, icon]);
}

export function usePageActionsContext(): PageActionsContext {
	const ctx = useContext(PageActionsContext);
	return ctx;
}

export function PageActionsContextProvider({
	children,
}: {
	children: ReactNode;
}): JSX.Element {
	const [values, setValues] = useMixedState<PageActionsContextValues>({});
	const handleSetValues = (
		actions: PageAction[],
		icon: MaterialSymbolVariant = 'add'
	) => {
		setValues({
			actions,
			icon,
		});
	};

	useEffect(() => {
		setValues({
			actions: [],
		});
	}, [window.location.pathname]);

	return (
		<PageActionsContext.Provider
			value={{
				actions: values.actions,
				icon: values.icon,
				setActions: handleSetValues,
			}}>
			{children}
		</PageActionsContext.Provider>
	);
}
