import { ReactNode } from 'react';

export const symbol_brightness_alert: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M479.982-280q14.018 0 23.518-9.482 9.5-9.483 9.5-23.5 0-14.018-9.482-23.518-9.483-9.5-23.5-9.5-14.018 0-23.518 9.482-9.5 9.483-9.5 23.5 0 14.018 9.482 23.518 9.483 9.5 23.5 9.5Zm3.193-153q12.825 0 21.325-8.625T513-463v-193q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T453-656v193q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625ZM346.163-160H220q-24.75 0-42.375-17.625T160-220v-125.587L68-438q-9-9-13-19.814-4-10.813-4-22Q51-491 55-502t13-20l92-92.413V-740q0-24.75 17.625-42.375T220-800h125.587L438-892q9-9 20.5-13t22.697-4q11.197 0 22.021 4.696Q514.042-899.609 523-891l91 91h126q24.75 0 42.375 17.625T800-740v125.587L892-522q9 9 13 19.814 4 10.813 4 22Q909-469 905-458t-13 20l-92 92.413V-220q0 24.75-17.625 42.375T740-160H614l-91 90q-8.958 8.13-19.782 12.565Q492.394-53 481.197-53q-11.197 0-22.16-4.435Q448.073-61.87 439-70l-92.837-90ZM370-220l111 107 107.917-107H740v-151l109-109-109-109v-151H589L481-849 371-740H220v151L111-480l109 109v151h150Zm111-261Z"
		/>
	</svg>
);
