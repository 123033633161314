import { ReactNode } from 'react';

export const symbol_plumbing: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M781-597 650-728 545-622 444-723l127-127q11-11 23-15.5t26-4.5q14 0 26.5 4.5T670-850l104 104q15 15 23 34t9 39q1 20-5 39.5T781-597ZM238-416q-20-20-20-51.5t20-51.5l91-92 103 103-92 92q-22 22-52 21t-50-21Zm-64 307q-10-10-15-23t-5-27q0-14 5-27t15-23l300-299-144-145q-15-15-15-35t15-35q15-15 35.5-15t35.5 15l144 144 57-57 105 107q9 9 9 21t-9 21q-13 13-25.5 8.5T658-494L273-109q-10 10-22.5 15T224-89q-14 0-27-5t-23-15Z"
		/>
	</svg>
);
