import { ReactNode } from 'react';

export const symbol_markdown_paste: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M529.825-120Q517-120 508.5-128.625T500-150v-250q0-24.75 17.625-42.375T560-460h260q24.75 0 42.375 17.625T880-400v250q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T820-150v-250H720v170q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T660-230v-170H560v250q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM180-120q-26 0-43-17t-17-43v-600q0-26 17-43t43-17h202q7-35 34.5-57.5T480-920q36 0 63.5 22.5T578-840h202q26 0 43 17t17 43v230q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T780-550v-230h-60v60q0 12.75-8.625 21.375T690-690H270q-12.75 0-21.375-8.625T240-720v-60h-60v600h230q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T410-120H180Zm300-660q17 0 28.5-11.5T520-820q0-17-11.5-28.5T480-860q-17 0-28.5 11.5T440-820q0 17 11.5 28.5T480-780Z"
		/>
	</svg>
);
