export default {
	name: 'videos',
	objects: [
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M20.115 16.9591V7.53906C20.115 7.15246 19.8016 6.83906 19.415 6.83906L4.58496 6.83906C4.19836 6.83906 3.88496 7.15246 3.88496 7.53906L3.88496 16.9591C3.88496 17.3457 4.19836 17.6591 4.58496 17.6591L19.415 17.6591C19.8016 17.6591 20.115 17.3457 20.115 16.9591ZM21.415 7.53906C21.415 6.43449 20.5195 5.53906 19.415 5.53906L4.58496 5.53906C3.48039 5.53906 2.58496 6.43449 2.58496 7.53906L2.58496 16.9591C2.58496 18.0636 3.48039 18.9591 4.58496 18.9591L19.415 18.9591C20.5195 18.9591 21.415 18.0636 21.415 16.9591V7.53906Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M13.4142 12.249L10.6499 13.8393V10.6588L13.4142 12.249ZM10.34 15.5173L15.0257 12.8217C15.4674 12.5676 15.4674 11.9304 15.0257 11.6764L10.34 8.9807C9.89953 8.72732 9.34987 9.04524 9.34987 9.55336V14.9447C9.34987 15.4528 9.89953 15.7707 10.34 15.5173Z',
			},
		},
	],
};
