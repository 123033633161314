import { ReactNode } from 'react';

export const symbol_mark_email_read: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m144-740 334 213 334-213H144Zm-4 568q-24.75 0-42.375-17.625T80-232v-508q0-24.75 17.625-42.375T140-800h677q24.75 0 42.375 17.625T877-740v270q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T817-470v-214L494.238-477.212Q491-475 478-473q-5 0-8.5-1t-7.5-3L140-684v452h270q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T410-172H140Zm339-256Zm-1-99Zm1 56Zm154 305 223-223q9-9 21-8.5t21 9.5q9 9 9 21.5t-9 21.5L654-101q-9 9-21 9t-21-9L493-220q-9-9-8.5-21.5T494-263q9-9 21.5-9t21.5 9l96 97Z"
		/>
	</svg>
);
