import { t } from '@transifex/native';
import { Fragment, useContext, useState } from 'react';

import * as styles from 'pkg/config/styles';

import { usePaymentProviderContext } from 'pkg/contexts/provider_settings';

import PriceOptionModal from 'routes/forms/hooks/modals/price_option';
import { FormEngineContext } from 'routes/forms/hooks/useFormEngine';
import Option from 'routes/forms/hooks/sections/price_options/single';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import Icon from 'components/icon';

import Section from 'components/form/Section';
import Column from 'components/layout/column';
import InfoBox from 'components/form/info-box';

import MissingEntities from 'design/placeholders/block';
import Button from 'design/button';

export type Modals = '' | 'priceOption';

export default function PriceOptions() {
	const formEngineContext = useContext(FormEngineContext);
	const [modal, setModal] = useState<Modals>('');
	const showPriceOptionModal = () => setModal('priceOption');
	const hideModal = () => setModal('');
	const paymentProviderContext = usePaymentProviderContext();

	const hasOldPriceOptions = formEngineContext.form.productPrices?.some(
		(productPrice) => productPrice.bundleNumber === 0
	);

	const displayEmptyState =
		formEngineContext.formState.priceOptions.length === 0;

	let mainContent = (
		<MissingEntities
			title={t('No payment options')}
			description={t(
				`Add one or more payment options for your customers to choose between when submitting the form`
			)}
			image={
				<Icon
					name="order"
					actualSize
					size={4}
					fill={styles.palette.gray[500]}
				/>
			}
			actions={
				<Button
					label={t('Add payment plan')}
					icon="add"
					onClick={showPriceOptionModal}
					primary
				/>
			}
		/>
	);

	if (!displayEmptyState) {
		mainContent = (
			<Fragment>
				{formEngineContext.formState.priceOptions.map((priceOption, index) => (
					<Option
						key={index}
						priceOption={priceOption}
						index={index}
						providerSettings={paymentProviderContext.settings}
					/>
				))}
				<div>
					<LargeScreen>
						<Button
							label={t('Add payment plan')}
							icon="add"
							onClick={showPriceOptionModal}
							secondary
						/>
					</LargeScreen>
					<SmallScreen>
						<Button
							label={t('Add payment plan')}
							icon="add"
							block
							onClick={showPriceOptionModal}
							secondary
						/>
					</SmallScreen>
				</div>
			</Fragment>
		);
	}

	return (
		<Fragment>
			<Section
				title={t('Payment plan')}
				description={t(
					'Add one or more price options for your customers to choose between when submitting the form.'
				)}>
				<Column>
					{hasOldPriceOptions && (
						<InfoBox
							color="orange"
							title={t(
								`We've updated how price options work to give you greater control in your registrations!`
							)}
							text={t(
								'If you want to make any changes to this registration you need to add new price options before saving. The current registration link will keep working until you do.'
							)}
						/>
					)}
					{mainContent}
				</Column>
			</Section>
			{modal === 'priceOption' && <PriceOptionModal hideModal={hideModal} />}
		</Fragment>
	);
}
