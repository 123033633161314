import { QueryState } from 'pkg/hooks/query-state';
import * as arrays from 'pkg/arrays';

export enum FilterHighlights {
	Show = 'showHighlights',
	Hide = 'hideHighlights',
	Only = 'onlyHighlights',
}

export enum FilterPrivate {
	Show = 'showPrivate',
	Hide = 'hidePrivate',
	Only = 'onlyPrivate',
}

export interface FilterState {
	highlights?: FilterHighlights;
	private?: FilterPrivate;
	authorAccountIds?: number[];
	taggedUserIds?: number[];
	tagIds?: number[];
	search?: string;
}

export interface FilterHookResponse extends FilterState {
	isFiltered: boolean;
	numAppliedFilters: number;

	showHighlights: boolean;
	hideHighlights: boolean;
	onlyHighlights: boolean;
	highlightsVisibility: string;

	showPrivate: boolean;
	hidePrivate: boolean;
	onlyPrivate: boolean;
	privateVisibility: string;

	reset: () => void;
	refresh: () => void;

	setHighlights: (filterHighlights: FilterHighlights) => void;
	setPrivate: (filterPrivate: FilterPrivate) => void;
	setAuthors: (authorIds: number[]) => void;
	setTaggedUsers: (taggedUserIds: number[]) => void;
	setTags: (tags: number[]) => void;
	setSearch: (search: string) => void;
}

const normalizeQueryArray = (a: any[]) =>
	arrays.unique<number>(
		a.map((n) => Number.parseInt(n, 10)).filter((n) => !Number.isNaN(n))
	);

export function getCurrentFilterState(): FilterState {
	const queryState = new QueryState(
		new URLSearchParams(document.location.search)
	);

	const filters: FilterState = {
		highlights: FilterHighlights.Show,
		private: FilterPrivate.Show,
		taggedUserIds: [],
		authorAccountIds: [],
		tagIds: [],
		search: queryState.get('search', '') as string,
	};

	if (queryState.has('highlights')) {
		filters.highlights = queryState.is('highlights', '1')
			? FilterHighlights.Only
			: FilterHighlights.Hide;
	}

	if (queryState.has('private')) {
		filters.private = queryState.is('private', '1')
			? FilterPrivate.Only
			: FilterPrivate.Hide;
	}

	if (queryState.has('authors')) {
		const authorAccountIds = normalizeQueryArray(
			queryState.getArray('authors')
		);

		filters.authorAccountIds = authorAccountIds;
	}

	if (queryState.has('tagged')) {
		const taggedUserIds = normalizeQueryArray(queryState.getArray('tagged'));

		filters.taggedUserIds = taggedUserIds;
	}

	if (queryState.has('tags')) {
		const tagIds = normalizeQueryArray(queryState.getArray('tags'));

		filters.tagIds = tagIds;
	}

	return filters;
}

export const useFilterState = (): FilterHookResponse => {
	const queryState = new QueryState(
		new URLSearchParams(document.location.search)
	);

	const state = getCurrentFilterState();

	const numAppliedFilters = useNumAppliedFilters();
	const isFiltered = numAppliedFilters > 0;

	const showHighlights = !queryState.has('highlights');
	const hideHighlights = queryState.is('highlights', '0');
	const onlyHighlights = queryState.is('highlights', '1');

	const showPrivate = !queryState.has('private');
	const hidePrivate = queryState.is('private', '0');
	const onlyPrivate = queryState.is('private', '1');

	let highlightsVisibility = 'show',
		privateVisibility = 'show';

	if (hideHighlights) {
		highlightsVisibility = 'hide';
	} else if (onlyHighlights) {
		highlightsVisibility = 'only';
	}

	if (hidePrivate) {
		privateVisibility = 'hide';
	} else if (onlyPrivate) {
		privateVisibility = 'only';
	}

	const setHighlights = (
		filterHighlights: FilterHighlights,
		commitChange = true
	) => {
		if (filterHighlights === FilterHighlights.Show) {
			queryState.remove('highlights');
		} else if (filterHighlights === FilterHighlights.Only) {
			queryState.set('highlights', '1');
		} else if (filterHighlights === FilterHighlights.Hide) {
			queryState.set('highlights', '0');
		}

		if (commitChange) {
			queryState.commit();
		}
	};

	const setPrivate = (filterPrivate: FilterPrivate, commitChange = true) => {
		if (filterPrivate === FilterPrivate.Show) {
			queryState.remove('private');
		} else if (filterPrivate === FilterPrivate.Only) {
			queryState.set('private', '1');
		} else if (filterPrivate === FilterPrivate.Hide) {
			queryState.set('private', '0');
		}

		if (commitChange) {
			queryState.commit();
		}
	};

	const setAuthors = (authorIds: number[], commitChange = true) => {
		if (authorIds.length > 0) {
			queryState.set('authors', authorIds);
		} else {
			queryState.remove('authors');
		}

		if (commitChange) {
			queryState.commit();
		}
	};

	const setTaggedUsers = (taggedUserIds: number[], commitChange = true) => {
		if (taggedUserIds.length > 0) {
			queryState.set('tagged', taggedUserIds);
		} else {
			queryState.remove('tagged');
		}

		if (commitChange) {
			queryState.commit();
		}
	};

	const setTags = (tags: number[], commitChange = true) => {
		if (tags.length > 0) {
			queryState.set('tags', tags);
		} else {
			queryState.remove('tags');
		}

		if (commitChange) {
			queryState.commit();
		}
	};

	const setSearch = (search: string, commitChange = true) => {
		if (search.length > 0) {
			queryState.set('search', search);
		} else {
			queryState.remove('search');
		}

		if (commitChange) {
			queryState.commit();
		}
	};

	const reset = (): void => queryState.flush();

	const refresh = (): void => {
		const current = getCurrentFilterState();

		setHighlights(current.highlights, false);
		setPrivate(current.private, false);
		setAuthors(current.authorAccountIds, false);
		setTaggedUsers(current.taggedUserIds, false);
		setTags(current.tagIds, false);
		setSearch(current.search, false);

		queryState.commit();
	};

	const filters = {
		...state,

		isFiltered,
		numAppliedFilters,

		showHighlights,
		hideHighlights,
		onlyHighlights,
		highlightsVisibility,

		showPrivate,
		hidePrivate,
		onlyPrivate,
		privateVisibility,

		reset,
		refresh,

		setHighlights,
		setPrivate,
		setAuthors,
		setTaggedUsers,
		setTags,
		setSearch,
	};

	if (queryState.has('search')) {
		filters.search = queryState.get('search') as string;
	}

	if (queryState.has('authors')) {
		const authorAccountIds = normalizeQueryArray(
			queryState.getArray('authors')
		);

		filters.authorAccountIds = authorAccountIds;
	}

	if (queryState.has('tagged')) {
		const taggedUserIds = normalizeQueryArray(queryState.getArray('tagged'));

		filters.taggedUserIds = taggedUserIds;
	}

	if (queryState.has('tags')) {
		const tagIds = normalizeQueryArray(queryState.getArray('tags'));

		filters.tagIds = tagIds;
	}

	return filters;
};

export const useNumAppliedFilters = (): number => {
	const queryState = new QueryState(
		new URLSearchParams(document.location.search)
	);

	const filters: boolean[] = [
		queryState.has('highlights'),
		queryState.has('private'),
		queryState.has('authors'),
		queryState.has('tagged'),
		queryState.has('search'),
		queryState.has('tags'),
	];

	return filters.filter((isApplied: boolean) => isApplied).length;
};
