import { ReactNode } from 'react';

export const symbol_align_vertical_center: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M339.882-120Q319-120 304.5-134.583 290-149.167 290-170v-280H110q-12.75 0-21.375-8.675Q80-467.351 80-480.175 80-493 88.625-501.5T110-510h180v-280q0-20.833 14.618-35.417Q319.235-840 340.118-840 361-840 375.5-825.417 390-810.833 390-790v280h180v-160q0-20.833 14.618-35.417Q599.235-720 620.118-720 641-720 655.5-705.417 670-690.833 670-670v160h180q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-450H670v160q0 20.833-14.618 35.417Q640.765-240 619.882-240 599-240 584.5-254.583 570-269.167 570-290v-160H390v280q0 20.833-14.618 35.417Q360.765-120 339.882-120Z"
		/>
	</svg>
);
