import { ReactNode } from 'react';

export const symbol_local_see: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-397ZM140-80q-24 0-42-18t-18-42v-513q0-23 18-41.5t42-18.5h147l68-80q5-5 10.5-8t12.5-3q19.5 0 27.75 17T401-755l-68 81q-8 11-20 16t-26 5H140v513h680v-185q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T880-325v185q0 24-18.5 42T820-80H140Zm340-147q42.365 0 79.183-18.5Q596-264 619-298q8-13 7.5-27.5T615-349q-11-8-23-3.5T572-336q-15 23-39.5 36T480-287q-46.2 0-78.1-31.9Q370-350.8 370-397q0-20 7-38t19-32q12-14 28.5-23.5T461-504q12-2 19-13t5-23.5q-2-12.5-12.5-19T449-564q-30 5-55.5 19.5T349-507q-19 23-29 51t-10 59q0 70.833 49.583 120.417Q409.167-227 480-227Zm239.925-733Q634-960 568.5-900 503-840 503-736.787q0 89.511 68.5 167.149T700-437q4.737 4 9.868 6Q715-429 720-429t10.526-2q5.527-2 9.474-6 60-55 128.5-132.638T937-736.787Q937-840 871.425-900q-65.574-60-151.5-60ZM720-496q-102.05-95.619-129.525-154.162Q563-708.704 563-737q0-79.536 50-121.268T720-900q57 0 107 41.628t50 120.967q0 28.405-27.475 87.029Q822.05-591.752 720-496Zm0-202Zm-59 49 22-73-60-49h73l24-72 24 72h73l-60 49 23 73-60-45-59 45Z"
		/>
	</svg>
);
