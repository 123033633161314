import * as React from 'react';
import styled from 'styled-components';

import DateTime from 'pkg/datetime';

const NUMBER_OF_INDICATORS: number = 55;
const BAR_HEIGHT: number = 300;
const BAR_ROW_HEIGHT: number = 2;
const INDICATOR_HEIGHT: number = 30;
const INDICATOR_POINTER_HEIGHT: number = 3;

const Wrapper = styled.div`
	position: relative;
	height: ${BAR_HEIGHT}px;
	width: 10px;
	margin: 0 auto;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	span {
		display: block;
		width: 10px;
		height: ${BAR_ROW_HEIGHT}px;
		background-color: var(--palette-gray-300);
		border-radius: var(--radius-6);

		&:nth-child(5n) {
			width: 20px;
			margin-left: -5px;
			background-color: var(--palette-gray-500);
			border-radius: var(--radius-4);
		}
	}
`;

const Indicator = styled.div<{ topPos: number }>`
	position: absolute;
	display: flex;
	align-items: center;
	min-width: 150px;
	color: var(--palette-white);
	font-size: var(--font-size-sm);

	div {
		display: flex;
		align-items: center;
		padding: 0 var(--spacing-4);
		height: ${INDICATOR_HEIGHT}px;
		border-radius: 32px;
	}
`;

const Current = styled(Indicator)`
	top: ${(props) => props.topPos - BAR_ROW_HEIGHT / 2}px;
	left: -10px;
	white-space: nowrap;

	div {
		background-color: var(--palette-blue-500);
	}

	&:before {
		content: '';
		background-color: var(--palette-blue-500);
		width: 70px;
		height: ${INDICATOR_POINTER_HEIGHT}px;
	}
`;

const Estimated = styled(Indicator)`
	top: ${(props) => props.topPos - BAR_ROW_HEIGHT}px;
	right: -20px;
	white-space: nowrap;

	div {
		background-color: var(--palette-gray-600);
	}

	&:after {
		content: '';
		background-color: var(--palette-gray-600);
		width: 70px;
		height: ${INDICATOR_POINTER_HEIGHT}px;
	}
`;

type HeightProps = { currentValue: number; estimatedValue: number };

const Height: React.FC<React.PropsWithChildren<HeightProps>> = ({
	currentValue,
	estimatedValue,
}) => {
	// get nearest fifth in estimated height and set the starting point to be 5 rows below that value.
	const maxStartPoint = Math.ceil(estimatedValue / 5) * 5 + 5;

	const lowestBarPoint = 285;

	const barRowHeight = BAR_HEIGHT / NUMBER_OF_INDICATORS;

	const difference: number = estimatedValue - currentValue;

	const estimatedPos: number =
		barRowHeight * (maxStartPoint - estimatedValue) -
		INDICATOR_HEIGHT / 2 -
		INDICATOR_POINTER_HEIGHT / 2;

	let currentPos: number = estimatedPos + barRowHeight * difference;

	if (currentPos > lowestBarPoint) {
		currentPos = lowestBarPoint;
	}

	const formatter = new Intl.NumberFormat(DateTime.getLocale(), {
		style: 'unit',
		unit: 'centimeter',
	});

	return (
		<Wrapper>
			{Array.from(Array(NUMBER_OF_INDICATORS)).map((_, index: number) => (
				<span key={index} />
			))}
			<Current topPos={currentPos}>
				<div>{formatter.format(currentValue)}</div>
			</Current>
			<Estimated topPos={estimatedPos}>
				<div>{formatter.format(estimatedValue)}</div>
			</Estimated>
		</Wrapper>
	);
};

export default Height;
