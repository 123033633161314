import { ReactNode } from 'react';

export const symbol_water_pump: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M479.911-376Q451-376 430.5-396.562 410-417.125 410-446q0-23 7-39t31-52l20-29q5-7 12-7t12 7l20 29q24 36 31 52t7 39q0 28.875-20.589 49.438Q508.823-376 479.911-376ZM100-260h207q-29-23-52-53.5T218-380H100v120Zm379.788 0Q571-260 635.5-324.288q64.5-64.288 64.5-155.5T635.712-635.5q-64.288-64.5-155.5-64.5T324.5-635.712q-64.5 64.288-64.5 155.5T324.288-324.5q64.288 64.5 155.5 64.5ZM742-580h118v-120H653q29 23 52 53.5t37 66.5ZM100-200v10q0 12.75-8.675 21.375Q82.649-160 69.825-160 57-160 48.5-168.625T40-190v-260q0-12.75 8.675-21.375Q57.351-480 70.175-480 83-480 91.5-471.375T100-450v10h103q-2-10-2.5-19.5T200-480q0-117 81.5-198.5T480-760h380v-10q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T920-770v260q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T860-510v-10H757q2 10 2.5 19.5t.5 20.5q0 117-81.5 198.5T480-200H100Zm0-60v-120 120Zm760-320v-120 120ZM480-480Z"
		/>
	</svg>
);
