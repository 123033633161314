import styled from 'styled-components';
import { List } from 'immutable';
import { t } from '@transifex/native';

import * as palette from 'pkg/config/palette';

import * as routes from 'pkg/router/routes';

import { FormattedContent } from 'components/formatted-content';
import Tag from 'components/Tag';
import Icon from 'components/icon';

import * as iconStyles from 'components/icon/styles.css';

const Wrapper = styled.div`
	display: grid;
	grid-auto-flow: row;
	grid-gap: 15px;
	text-align: center;
	position: relative;

	@media print {
		text-align: left;
	}
`;

const TagsWrapper = styled.div`
	margin-top: var(--spacing-3);
	display: flex;
	flex-flow: wrap;
	gap: var(--spacing-2);
	align-items: center;
	justify-content: center;
`;

const Divider = styled.hr`
	display: block;
	height: 0;
	width: 25%;
	margin: 5px auto;
	border: none;
	border-bottom: 1px solid ${palette.gray[400]};
`;

const Duration = styled.h3`
	color: ${palette.gray[500]};

	.${iconStyles.icon} {
		margin-right: 5px;
		transform: scale(1.1);
	}
`;

const Title = styled.h1``;

export default ({ item, tags = List([]), duration, activeGroup }) => (
	<Wrapper>
		<Duration>
			<Icon name="timer" />

			{t('{num} min', {
				num: duration,
				_context: 'training_library/session_builder',
			})}
		</Duration>
		<Title>{item.title}</Title>
		{tags.size > 0 && (
			<TagsWrapper>
				{tags.map((tag) => (
					<Tag
						type="session"
						small
						key={tag.name}
						name={tag.name}
						href={`${routes.TrainingLibrary.Index(activeGroup)}?tags=${
							tag.name
						}`}
					/>
				))}
			</TagsWrapper>
		)}
		{item.description && <FormattedContent raw={item.description} />}
		<Divider />
	</Wrapper>
);
