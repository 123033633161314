import { ReactNode } from 'react';

export const symbol_network_wifi_locked: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-740q-108 0-207.5 40T88-594l42 42q75-60 164-94t186-34q97 0 186 34t164 94l42-42q-85-66-184.5-106T480-740Zm10 600q-14 0-27.5-5.5T438-162L47-553q-8.25-8.298-13.125-19.614T29-596q0-14 5.5-25.5T51-642q91-76 201-117t228-41q118 0 228 41t201 117q11 9 16.5 20.5T931-596q0 12.07-4.875 23.386Q921.25-561.298 913-553l-58 58q-5.115 5.091-12.058 6.545Q836-487 829-489q-16-5-31.5-7.5T765-499q-91.25 0-155.125 64.167Q546-370.667 546-278.083q0 10.083 1.667 19.115Q549.333-249.935 551-240q2 10 3 19.355T555-201q0 28.372-19.5 44.686T490-140Zm192 20q-17 0-28.5-11.385T642-159.6v-118.8q0-16.83 11.5-28.215Q665-318 682-318h1v-39q0-33.825 24.124-57.913 24.124-24.087 58-24.087Q799-439 823-414.913q24 24.088 24 57.913v39h1q17 0 28.5 11.385T888-278.4v118.8q0 16.83-11.5 28.215Q865-120 848-120H682Zm41-198h84v-39.298Q807-374 794.5-386.5T765-399q-17 0-29.5 12.5T723-357.298V-318Z"
		/>
	</svg>
);
