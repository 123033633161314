import { schema } from 'normalizr';

export const Sports = new schema.Entity('sports');

export const GroupSchema = new schema.Entity('groups');
export const Countries = new schema.Entity('countries');

export const attachments = new schema.Entity('attachments', undefined, {
	idAttribute: (value) => value.id,
});

export const subscriptions = new schema.Entity('subscription', undefined, {
	idAttribute: (value) => value.id,
});

export const orders = new schema.Entity('order', undefined, {
	idAttribute: (value) => value.id,
});

export const prices = new schema.Entity('price', undefined, {
	idAttribute: (value) => value.id,
});

export const products = new schema.Entity('product', undefined, {
	idAttribute: (value) => value.id,
});

export const taxRates = new schema.Entity('taxRate');
export const users = new schema.Entity('users', undefined, {
	idAttribute: (value) => value.id,
});

export const forms = new schema.Entity(
	'forms',
	{ attachment: attachments },
	{
		idAttribute: (value) => value.id,
	}
);

export const formCategories = new schema.Entity('formCategories', undefined, {
	idAttribute: (value) => value.id,
});

export const event = new schema.Entity('events', undefined, {
	idAttribute: (value) => value.id,
});

export const match = new schema.Entity('matches', undefined, {
	idAttribute: (value) => value.id,
});

export const eventSeriesUsers = new schema.Entity('eventSeriesUsers', {
	user: users,
});

export const eventUsers = new schema.Entity('eventUsers', {
	user: users,
	attendanceReporter: users,
});

export const matchEventsSchema = new schema.Entity('matchEvents', {
	user: users,
});

export const eventSeries = new schema.Entity('eventSeries', {
	idAttribute: (value) => value.id,
});

export const matchCompetition = new schema.Entity('matchCompetitions');
export const matchOpponent = new schema.Entity('matchOpponents');

export const userProducts = new schema.Entity('userProduct', {
	user: users,
	product: products,
	productPrice: prices,
	order: orders,
});

products.define({
	prices: [prices],
});

prices.define({
	product: products,
});

orders.define({
	rows: [
		{
			productPrice: prices,
			taxRate: taxRates,
		},
	],
	userProducts: [userProducts],
});

subscriptions.define({
	customerUser: users,
	rows: [
		{
			productPrice: prices,
			ProductPrice: prices, // todo(jbfm): remove this when api sends camelCase
			taxRate: taxRates,
		},
	],
});

GroupSchema.define({
	sport: Sports,
	country: Countries,
	organization: GroupSchema,
	children: [GroupSchema],
});

const MembershipSchema = new schema.Entity('memberships', undefined, {
	idAttribute: ({ id, targetUserId, userId, groupId }): string => {
		if (id && !targetUserId) {
			return id.toString();
		}

		return `u${userId}g${groupId}`;
	},
});

MembershipSchema.define({
	group: GroupSchema,
});

users.define({
	country: Countries,
	groups: [MembershipSchema],
	organization: GroupSchema,
	products: [userProducts],
	billingUser: users,
});

match.define({
	events: [matchEventsSchema],
	competition: matchCompetition,
	opponent: matchOpponent,
});

event.define({
	attachments: [attachments],
	users: [eventUsers],
	matches: [match],
	user: users,
});

eventSeries.define({
	eventSeriesAttachments: [
		{
			attachment: attachments,
		},
	],
	events: [event],
	seriesUsers: [eventSeriesUsers],
});
