import { ReactNode } from 'react';

export const symbol_fire_extinguisher: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M360-80q-33 0-56.5-23.5T280-160v-40h400v40q0 33-23.5 56.5T600-80H360Zm-80-150v-220h400v220H280Zm4-250q10-46 42-86t81-60q-10-8-18-17.5T375-664l-146-34q-9-2-13-8t-4-14q0-8 4-14t13-8l146-34q15-29 42.5-46.5T480-840q21 0 40 7t34 19l90-19q14-3 25 6.5t11 23.5v166q0 14-11 23.5t-25 6.5l-90-19h-1q47 19 79.5 57.5T676-480H284Zm196-180q25 0 42.5-17.5T540-720q0-26-17.5-43T480-780q-25 0-42.5 17.5T420-720q0 25 17.5 42.5T480-660Z"
		/>
	</svg>
);
