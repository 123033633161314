import { ReactNode } from 'react';

export const symbol_event_seat: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M189.825-120Q177-120 168.5-128.625T160-150v-130q0-24.75 17.625-42.375T220-340h520q24.75 0 42.375 17.625T800-280v130q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T740-150v-130H220v130q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Zm-19.943-290Q149-410 134.5-424.618q-14.5-14.617-14.5-35.5Q120-481 134.618-495.5q14.617-14.5 35.5-14.5Q191-510 205.5-495.382q14.5 14.617 14.5 35.5Q220-439 205.382-424.5q-14.617 14.5-35.5 14.5ZM280-400v-380q0-24.75 17.625-42.375T340-840h280q24.75 0 42.375 17.625T680-780v380H280Zm509.882-10Q769-410 754.5-424.618q-14.5-14.617-14.5-35.5Q740-481 754.618-495.5q14.617-14.5 35.5-14.5Q811-510 825.5-495.382q14.5 14.617 14.5 35.5Q840-439 825.382-424.5q-14.617 14.5-35.5 14.5ZM340-460h280v-320H340v320Zm0 0h280-280Z"
		/>
	</svg>
);
