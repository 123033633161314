import { ReactNode } from 'react';

export const symbol_rocket_launch: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M292-506q18-36 38.5-71t43.5-67l-79-16-109 109 106 45Zm515-312q-102 5-184 43.5T463-658q-31 31-64 81t-59 107l133 133q57-26 107-59t81-64q78-78 116.5-159.5T821-803q0-3-1-6t-3-5q-2-2-4.5-3t-5.5-1ZM570-566q-20-20-20-49.5t20-49.5q20-20 49.5-20t49.5 20q20 20 20 49.5T669-566q-20 20-49.5 20T570-566Zm-61 278 45 105 109-109-15-79q-32 23-67.5 44T509-288Zm373-559q3 125-40.5 230.5T704-417q-1 1-2 1.5t-2 1.5l22 110q3 15-1 29t-15 25L565-109q-11 11-26.5 8T517-119l-68-157-169-170-158-67q-15-6-17.5-22t8.5-27l140-140q11-11 25-15.5t29-1.5l110 22q1-1 2-1.5t2-1.5q94-94 199-137.5T850-878q6 0 11.5 2.5T872-868q5 5 7.5 10t2.5 11ZM149-325q35-35 85.5-35.5T320-326q35 35 34.5 85.5T319-155q-47 47-112.5 56.5T75-80l18-132q9-66 56-113Zm42 43q-24 26-31 60t-13 69q35-6 69-13t60-31q20-17 20-42.5T278-284q-19-18-44.5-18T191-282Z"
		/>
	</svg>
);
