import { ReactNode } from 'react';

export const symbol_checkbook: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M267-363h293q15 0 22.5-9.318t7.5-20.5q0-11.182-7.5-20.682Q575-423 560-423H267q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T267-363Zm0-178h171q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T438-601H267q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T267-541ZM140-200q-24.75 0-42.375-17.625T80-260v-440q0-24.75 17.625-42.375T140-760h680q24.75 0 42.375 17.625T880-700v99q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T820-601v-99H140v439h300q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q470-218 461.375-209T440-200H140Zm775-280q5 5 5 12.5t-5.122 12.622L878-418l-50-50 39-39q4.773-5 10.5-5t10.5 5l27 27Zm-63 88L589-129q-5 5-10.223 7-5.223 2-10.795 2H545q-6 0-10.5-4.5T530-135v-22.982q0-5.572 2-10.795T539-179l263-263 50 50ZM140-700v439-439Z"
		/>
	</svg>
);
