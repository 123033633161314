import { Fragment } from 'react';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import useMixedState from 'pkg/hooks/useMixedState';
import * as actions from 'pkg/actions';

import EventCommentForm from 'routes/event/single/comments/form';

import { FormattedContent } from 'components/formatted-content';
import RelativeDateTime from 'components/RelativeDateTime';
import Avatar from 'components/avatar';

import * as Comment from 'components/legacy/Comment';
import UserLink from 'components/user/link';

import { useDialog } from 'design/dialog';

interface EventCommentProps {
	comment: models.eventComment.EventComment;
	groupMemberships: models.membership.Membership[];

	onDeleteComment: (comment: models.eventComment.EventComment) => void;
}

export default function EventComment({
	comment,
	groupMemberships,
	onDeleteComment,
}: EventCommentProps) {
	const [state, setState] = useMixedState({
		editing: false,
		commentText: '',
	});

	const user = comment.user;

	const toggleEdit = () => {
		setState({
			editing: !state.editing,
			commentText: comment.comment,
		});
	};

	const deleteCallback = async () => {
		const ok = await actions.eventComments.remove(comment.id);

		if (ok) {
			onDeleteComment(comment);
		}
	};

	const updateCallback = () => {
		setState({
			editing: false,
		});
	};

	const deleteConfirm = useDialog({
		message: t('Do you want to delete this?', { _context: 'confirm messages' }),
		onConfirm: deleteCallback,
	});

	let content = (
		<Comment.Body>
			<FormattedContent
				raw={comment.comment}
				prefixWith={
					<Fragment>
						<UserLink user={comment.user} memberships={groupMemberships} />{' '}
					</Fragment>
				}
			/>
		</Comment.Body>
	);

	if (state.editing) {
		content = (
			<EventCommentForm
				eventId={comment.eventId}
				user={comment.user}
				comment={comment}
				onComment={updateCallback}
			/>
		);
	}

	return (
		<Comment.Wrapper isEditing={state.editing}>
			{!state.editing && (
				<Comment.AuthorAvatar>
					<Avatar user={user} />
				</Comment.AuthorAvatar>
			)}
			<Comment.Container>
				{content}
				<Comment.Actions>
					<Comment.Timestamp>
						{comment.createdAt && (
							<RelativeDateTime dateTime={comment.createdAt} />
						)}
					</Comment.Timestamp>
					{models.canEdit(comment) && (
						<Comment.Action key="edit-comment" onClick={toggleEdit}>
							{state.editing ? t('Abort') : t('Edit')}
						</Comment.Action>
					)}
					{models.canDelete(comment) && (
						<Comment.Action key="delete-comment" onClick={deleteConfirm}>
							{t('Delete')}
						</Comment.Action>
					)}
				</Comment.Actions>
			</Comment.Container>
		</Comment.Wrapper>
	);
}
