import { ReactNode } from 'react';

export const symbol_energy_program_time_used: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M410-450ZM110-90q-24.75 0-42.375-17.625T50-150v-600q0-24.75 17.625-42.375T110-810h340q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T450-750H110v600h600v-260q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T770-410v260q0 24.75-17.625 42.375T710-90H110Zm133.825-432Q231-522 222.5-513.375T214-492v215q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T274-277v-215q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625Zm166-131Q397-653 388.5-644.375T380-623v346q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T440-277v-346q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625Zm166 258Q563-395 554.5-386.375T546-365v88q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T606-277v-88q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625ZM730-500q-31 0-59.5-10.5T616-538l-21 20q-9 8-21 8.5t-21-8.5q-9-9-9-21t9-21l22-22q-16-24-25.5-51.325Q540-660.651 540-690q0-78.85 55.575-134.425Q651.15-880 730-880h160q12.75 0 21.375 8.625T920-850v160q0 78.85-55.575 134.425Q808.85-500 730-500Zm0-60q54.167 0 92.083-37.917Q860-635.833 860-690v-130H730q-54.167 0-92.083 37.917Q600-744.167 600-690q0 17 4.5 33.5T616-623l115-115q9-9 21-9t21 9q9 9.067 9 21.533Q782-704 773-695L659-581q17 8 34.5 14.5T730-560Zm-15-120Z"
		/>
	</svg>
);
