import { ReactNode } from 'react';

export const symbol_ar_on_you: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-792q130 0 221 91t91 221q0 130-91 221t-221 91q-130 0-221-91t-91-221q0-130 91-221t221-91Zm0 564q104 0 178-74t74-178q0-104-74-178t-178-74q-104 0-178 74t-74 178q0 104 74 178t178 74ZM338-564q0 16.575 11.212 27.787Q360.425-525 377-525q16.575 0 27.788-11.213Q416-547.425 416-564t-11.212-27.787Q393.575-603 377-603q-16.575 0-27.788 11.213Q338-580.575 338-564Zm206 0q0 16.575 11.213 27.787Q566.425-525 583-525t27.787-11.213Q622-547.425 622-564t-11.213-27.787Q599.575-603 583-603t-27.787 11.213Q544-580.575 544-564ZM40-761v-99q0-24.75 17.625-42.375T100-920h99q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T199-860h-99v99q0 12.75-8.675 21.375Q82.649-731 69.825-731 57-731 48.5-739.625T40-761ZM199-40h-99q-24.75 0-42.375-17.625T40-100v-99q0-12.75 8.675-21.375Q57.351-229 70.175-229 83-229 91.5-220.375T100-199v99h99q12.75 0 21.375 8.675Q229-82.649 229-69.825 229-57 220.375-48.5T199-40Zm661-4h-99q-12.75 0-21.375-8.675Q731-61.351 731-74.175 731-87 739.625-95.5T761-104h99v-100q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T920-204v100q0 24.75-17.625 42.375T860-44Zm0-717v-99h-99q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T761-920h99q24.75 0 42.375 17.625T920-860v99q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T860-761ZM480-480Zm0 137q37.631 0 69.816-17.5Q582-378 604-407q6-10.667 0-21.333Q598-439 584.776-439H375.224Q362-439 356-428.333q-6 10.666 0 21.333 22 29 54.184 46.5Q442.369-343 480-343Z"
		/>
	</svg>
);
