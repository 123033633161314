import { ReactNode } from 'react';

export const symbol_multiline_chart: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M842-296q0 13-8.5 21.5T812-266q-13 0-21.5-8.5T782-296q-6-53-23.5-103T713-497L586-355q-17 20-43 20.5T499-352L376-475 143-242q-9 9-21 8.5t-21-9.5q-8-9-8.5-21t8.5-21l232-232q17-17 42.5-17.5T418-517l123 123 139-156q-60-69-135.5-108.5T378-698q-72 0-137.5 26T122-599q-5 4-10 6.5t-11 2.5q-13 0-21.5-9T71-621q0-6 2-11.5t7-9.5q61-55 137.5-85.5T378-758q100 0 187.5 43.5T719-594l98-111q9-10 22.5-10.5T862-706q8 8 8 19.5t-8 20.5L756-545q27 42 56.5 121T842-296Z"
		/>
	</svg>
);
