import { ReactNode } from 'react';

export const symbol_language_chinese_quick: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M85-271.412Q81-278 83-285.5q2-7.5 9-12.5 15-10 28.325-21.484Q133.65-330.969 148-343v-122H98q-6.954 0-11.977-5.032-5.023-5.033-5.023-12Q81-489 86.023-494T98-499h71q6 0 10.5 4.5T184-484v138q23 26 54.5 40t66.5 14q51.01 0 101-1t101-3q8-1 11 4t1 13q-2 8-9 14t-15 6q-49 2-96.978.5Q350.044-260 301-260q-38 0-74-14t-62-42q-13.263 12.667-27.632 25.333Q123-278 109-266q-6 5-13 3.5t-11-8.912ZM478-335q-28-17-51.5-40.5T381-424v97q0 8-4.5 12.5T364-310q-8 0-13-5t-5-13v-98q-20 26-44.62 48.982Q276.761-354.036 250-334q-6 5-13.5 3.5T224-338q-5-6-4.5-11.5t7.5-9.5q29.842-16.636 55.421-38.818T329-447h-74q-6 0-10.5-4.5T240-462v-95q0-6 4.5-10.5T255-572h92v-45H235q-7 0-11-4.5t-4-11.5q0-7 4.5-11t11.5-4h111v-36q0-6.955 5.032-11.977 5.033-5.023 12-5.023Q371-701 376-695.977q5 5.022 5 11.977v36h121q7 0 11 4t4 11q0 7-4 11.5t-11 4.5H381v45h98q6 0 10.5 4.5T494-557v94q0 6-4.5 10.5T479-448h-82q23 26 48.5 47.5T501-362q7.364 4.167 8.182 9.583Q510-347 505.333-341 500-335 492-333t-14-2ZM271-476h75v-67h-75v67Zm109 0h80v-67h-80v67ZM157-607q-12-15.294-26-28.039-14-12.745-30-23.961-6-4-6-9.5t4.667-10.5q5.333-5 12.74-5 7.408 0 12.593 4 15.714 12.324 29.857 25.162Q169-642 183-628q5 5.115 5 12.058 0 6.942-6.118 11.742-6.117 5.2-13 4.2Q162-601 157-607Zm547 287q32.494 0 63.247-15T824-377.508V-499q-29 4-51 8.5T731-480q-50 15.782-75 39.455-25 23.672-25 55.236Q631-356 650.909-338q19.909 18 53.091 18Zm-14 51q-54 0-86.5-31T571-383q0-50 34-83.5T709.719-520Q732-526 760.5-531.5T824-542q0-56-18.5-82.5T739-651q-22 0-44 6.5T644-622q-10 5-19.849 2.667Q614.303-621.667 609-631q-5-10-2.5-20t12.5-15q31-17 64.5-27t67.5-10q69 0 101 42.5T884-540v237q0 10.606-7.078 17.803-7.079 7.197-17.51 7.197h-3.726q-9.686 0-16.186-6.5T831-300l-3-27q-30 28-66.5 43T690-269Z"
		/>
	</svg>
);
