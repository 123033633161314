import styled from 'styled-components';

import * as styles from 'pkg/config/styles';
import * as breakpoints from 'pkg/config/breakpoints';

import rgba from 'pkg/rgba';

export const Header = styled.div`
	display: grid;
	grid-template-columns: 360px max-content;
	align-items: center;
	justify-content: space-between;
	background: ${({ theme }) =>
		theme.primaryColor || rgba(styles.palette.navBar.backgroundColor)};
	width: 100%;
	gap: var(--spacing-1);
	position: relative;
	padding: calc(var(--sat) + var(--spacing-3)) var(--spacing-6) var(--spacing-3)
		var(--spacing-3);

	@media ${breakpoints.small} {
		grid-template-columns: 1fr auto;
		padding: calc(var(--sat) + var(--spacing-2)) var(--spacing-5)
			var(--spacing-2) 0;
	}
`;
