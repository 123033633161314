import styled, { css } from 'styled-components';

import * as palette from 'pkg/config/palette';

import rgba from 'pkg/rgba';

export const Dots = styled.div`
	display: flex;
	max-width: 180px;
	width: 100%;
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
`;

const InnerDot = styled.div`
	height: 8px;
	width: 8px;
	border-radius: var(--radius-3);
	background: ${(props) =>
		props.active
			? `${rgba(palette.blue[500], 1)}`
			: `${rgba(palette.black, 0.2)}`};

	${(props) =>
		props.clickable &&
		css`
			cursor: pointer;
		`}
`;

const OuterDot = styled.div`
	padding: 5px;
`;

export const Dot = ({ active, onClick }) => (
	<OuterDot onClick={onClick} data-testid="swiper.dot">
		<InnerDot active={active} clickable={onClick && true} />
	</OuterDot>
);
