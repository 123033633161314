export default {
	name: 'task-incomplete',
	objects: [
		{
			type: 'path',
			attributes: {
				d: 'M12 20.5654C16.5542 20.5654 20.246 16.8735 20.246 12.3193C20.246 7.7652 16.5542 4.07333 12 4.07333C7.44587 4.07333 3.75401 7.7652 3.75401 12.3193C3.75401 16.8735 7.44587 20.5654 12 20.5654ZM13.1 8.66683C13.1 8.05932 12.6075 7.56683 12 7.56683C11.3925 7.56683 10.9 8.05932 10.9 8.66683V12.346C10.9 12.9535 11.3925 13.446 12 13.446C12.6075 13.446 13.1 12.9535 13.1 12.346V8.66683ZM10.9 15.5242C10.9 16.1317 11.3925 16.6242 12 16.6242C12.6075 16.6242 13.1 16.1317 13.1 15.5242V15.488C13.1 14.8805 12.6075 14.388 12 14.3881C11.3925 14.3881 10.9 14.8805 10.9 15.488V15.5242Z',
				fillRule: 'evenodd',
				clipRule: 'evenodd',
			},
		},
	],
};
