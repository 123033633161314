import { ReactNode } from 'react';

export const symbol_stadium: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M158-723v-96q0-8 7-12.5t15-.5l95 48q8 5 8 13t-8 13l-95 48q-8 4-15-.5t-7-12.5Zm540 0v-96q0-8 7-12.5t15-.5l95 48q8 5 8 13t-8 13l-95 48q-8 4-15-.5t-7-12.5Zm-252-36v-96q0-8 7-12.5t15-.5l95 48q8 5 8 13t-8 13l-95 48q-8 4-15-.5t-7-12.5ZM398-82q-139-10-228.5-42T80-200v-360q0-25 31.5-46.5t85.5-38q54-16.5 127-26t156-9.5q83 0 156 9.5t127 26q54 16.5 85.5 38T880-560v360q0 45-88.5 76.5T563-82q-14 1-23.5-7.5T530-112v-138H430v138q0 13-9.5 22T398-82Zm82-418q97 0 172.5-14.5T800-558q-23-24-107.5-43T480-620q-128 0-212.5 19T160-558q72 29 147.5 43.5T480-500ZM370-145v-105q0-25 17.5-42.5T430-310h100q25 0 42.5 17.5T590-250v105q80-5 138.5-21.5T820-205v-297q-57 26-149 44t-191 18q-99 0-191-18t-149-44v297q33 22 91.5 38.5T370-145Zm110-179Z"
		/>
	</svg>
);
