import { ReactNode } from 'react';

export const symbol_g_translate: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m481-80-41-120H160q-35 0-57.5-22.5T80-280v-520q0-35 22.5-57.5T160-880h240l35 119.4h365q35 0 57.5 22.387Q880-715.825 880-681v521q0 35-22.444 57.5T800.2-80H481ZM286.701-376Q356-376 400-420.778T444-537v-15.5q0-6.5-2.013-11.5H283v61h90q-8 28.949-30.5 44.975Q320-442 288-442q-39 0-67-28.289-28-28.288-28-69.711t28-69.711Q249-638 287.742-638q17.881 0 33.775 6.5 15.894 6.5 28.807 19.5L399-658q-20-22-50-34t-62-12q-68 0-115.5 48T124-540q0 68 47.706 116 47.705 48 114.995 48Zm267.704 19L577-378q-14-17-26-32.5T528-444l26.405 87Zm49.79-51q28.363-33 43.052-63 14.688-30 19.753-47H508l11 43h40q8 14.7 19 31.85Q589-426 604.195-408ZM521-120h279q17 0 28.5-11.521 11.5-11.52 11.5-28.55V-681q0-17-11.5-28.5T800-721H448l46 163h79v-43h41v43h147v40h-52q-9 38-29 74.151-20 36.15-47 67.28l109 107.448L713-240 604-348l-36 36 33 112-80 80Z"
		/>
	</svg>
);
