import { normalize } from 'normalizr';
import { batch } from 'react-redux';

import SessionBlock from 'pkg/models/session_block';

import { SessionBlocks } from 'pkg/actions/action-types';
import { actionSetSessionMode } from 'pkg/actions/sessions';

const { SET_ITEMS, DELETE_ITEM, DELETE_ITEMS, SET_MODE, UNSET_MODE } =
	SessionBlocks;

const actionSetBlocks = (payload) => ({
	type: SET_ITEMS,
	payload,
});

const actionDeleteBlock = (blockId) => ({
	type: DELETE_ITEM,
	blockId,
});

const actionDeleteBlocksBySession = (sessionId) => ({
	type: DELETE_ITEMS,
	sessionId,
});

export const actionSetSessionBlockMode = (blockId, payload) => ({
	type: SET_MODE,
	blockId,
	payload,
});

export const actionUnsetSessionBlockMode = (blockId) => ({
	type: UNSET_MODE,
	blockId,
});

/* Actions */

export const createSessionBlock =
	(organizationId, sessionId, payload) => async (dispatch) => {
		payload.trainingSessionId = sessionId;

		let isNew = true;

		const data = normalize(payload, SessionBlock.normalizr());

		batch(() => {
			dispatch(actionSetBlocks(data));
			dispatch(
				actionSetSessionBlockMode(payload.id, {
					isNew,
					hasChanges: false,
				})
			);
		});

		return payload;
	};

export const updateSessionBlock =
	(organizationId, sessionBlock, payload) => async (dispatch) => {
		sessionBlock = sessionBlock.merge(payload);

		let result = sessionBlock.toJS();

		const data = normalize(result, SessionBlock.normalizr());

		const hasChanges = true;

		dispatch(actionSetBlocks(data));
		dispatch(actionSetSessionBlockMode(sessionBlock.id, { hasChanges }));
		dispatch(
			actionSetSessionMode(sessionBlock.trainingSessionId, { hasChanges })
		);

		await dispatch(actionSetBlocks(data));

		return data;
	};

export const deleteSessionBlock =
	(organizationId, blockId) => async (dispatch, getState) => {
		const state = getState().sessionBlocks;
		const block = state.getIn(['entities', blockId]);

		if (block) {
			const sessionId = block.get('trainingSessionId');
			dispatch(actionSetSessionMode(sessionId, { hasChanges: true }));
		}

		dispatch(actionDeleteBlock(blockId));
	};

export const deleteSessionBlocksBySessionId = (sessionId) => async (dispatch) =>
	dispatch(actionDeleteBlocksBySession(sessionId));

export const swapSessionBlockOrder = (source, target) => (dispatch) => {
	const sessionId = source.get('trainingSessionId');

	const sourceOrder = source.get('order');
	const targetOrder = target.get('order');

	source = source.set('order', targetOrder).toJS();
	target = target.set('order', sourceOrder).toJS();

	const data = normalize([source, target], [SessionBlock.normalizr()]);

	batch(() => {
		const hasChanges = true;

		dispatch(actionSetBlocks(data));
		dispatch(actionSetSessionBlockMode(source.id, { hasChanges }));
		dispatch(actionSetSessionBlockMode(target.id, { hasChanges }));
		dispatch(actionSetSessionMode(sessionId, { hasChanges }));
	});
};
