import { t } from '@transifex/native';

import FormRow from 'components/form/Row';
import Toggle from 'components/form/Toggle';

const Notification = ({ dispatch, data }) => {
	const handleNotifyChange = () => {
		dispatch({
			type: 'set_value',
			payload: { key: 'notify', value: !data.notify },
		});
	};

	return (
		<FormRow>
			<Toggle
				label={t(`Notify recipients`)}
				onChange={handleNotifyChange}
				isActive={data.notify}
				description={t(
					`A notification will be sent to each recipient, depending on their notification settings.`
				)}
			/>
		</FormRow>
	);
};

export default Notification;
