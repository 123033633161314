import { t } from '@transifex/native';
import { Fragment } from 'react';
import { T } from '@transifex/react';

import * as models from 'pkg/api/models';
import { useEndpoint } from 'pkg/api/use_endpoint';
import * as endpoints from 'pkg/api/endpoints/auto';
import { cssClasses } from 'pkg/css/utils';

import * as Card from 'components/Card';

import { Spinner } from 'components/loaders/spinner';
import Column from 'components/layout/column';

import * as css from './styles.css';

interface FogisImportModalProps {
	group: models.group.Group;
	inputValue: string;
}

interface CompetitionProps {
	competitionName: string;
}

interface FogisProps {
	teamName: string;
	clubName: string;
	competitions: CompetitionProps[];
}

export default function FogisImportModal({
	group,
	inputValue,
}: FogisImportModalProps): JSX.Element {
	const arr = inputValue.split('/');

	const fogisTeamCode = Number(arr.filter((n) => n).pop());

	const { record: fogisTeam, isLoading } = useEndpoint<FogisProps>(
		endpoints.Groups.GetFogisTeamNameAndCompetitions(group.id),
		{
			queryParams: new URLSearchParams({
				fogis_team_id: fogisTeamCode.toString(),
			}),
		}
	);

	if (isLoading) {
		return <Spinner />;
	}

	const competitions = fogisTeam.competitions.map((competition, index) => (
		<Fragment key={index}>
			<div className={css.wrapper}>{competition.competitionName}</div>
			{fogisTeam.competitions.length !== index + 1 && <Card.Divider />}
		</Fragment>
	));

	return (
		<Column>
			<div className={css.confirmTitle}>
				<T
					_str="Confirm import of matches for {team} "
					team={fogisTeam.teamName}
				/>
			</div>
			<div className={css.confirmDescription}>
				<div>
					{t(
						'By confirming this you will start importing matches from SvFF Fogis.'
					)}
				</div>
				<div>
					{t('Proceed only if this is your team - you cannot undo this.')}
				</div>
			</div>
			<Card.Base>
				<div className={cssClasses(css.wrapper, css.header)}>
					{t('Competitions that will be imported')}
				</div>
				<Card.Divider />
				{competitions}
			</Card.Base>
		</Column>
	);
}
