import { ReactNode } from 'react';

export const symbol_local_dining: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M477-432 186-141q-9 9-21 9t-21-9q-9-9-9-21t9-21l399-399q-22-48-10-99.5t57-96.5q45-44 109-56.5T804-806q42 42 28.5 105T772-590q-41 42-91.5 55t-94.5-6l-67 67 291 291q9 9 9 21t-9 21q-9 9-21 9t-21-9L477-432Zm-187-39L171-590q-43-43-51.5-103T144-804q8-12 21.5-14t24.5 9l219 219-119 119Z"
		/>
	</svg>
);
