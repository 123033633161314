import { ReactNode } from 'react';

export const symbol_universal_currency_alt: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-200q-24 0-42-18t-18-42v-440q0-24 18-42t42-18h680q24 0 42 18t18 42v440q0 24-18 42t-42 18H140Zm0-60h680v-440H140v440Zm580-100h-85q-10.833 0-17.917 7.116-7.083 7.117-7.083 18Q610-324 617.083-317q7.084 7 17.917 7h105q12.75 0 21.375-8.625T770-340v-105q0-10.833-7.116-17.917-7.117-7.083-18-7.083Q734-470 727-462.917q-7 7.084-7 17.917v85Zm-239.941-5Q528-365 561.5-398.559t33.5-81.5Q595-528 561.441-561.5t-81.5-33.5Q432-595 398.5-561.441t-33.5 81.5Q365-432 398.559-398.5t81.5 33.5ZM240-595h85q10.833 0 17.917-7.116 7.083-7.117 7.083-18Q350-631 342.917-638q-7.084-7-17.917-7H220q-12.75 0-21.375 8.625T190-615v105q0 10.833 7.116 17.917 7.117 7.083 18 7.083Q226-485 233-492.083q7-7.084 7-17.917v-85ZM140-260v-440 440Z"
		/>
	</svg>
);
