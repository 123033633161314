import { ReactNode } from 'react';

export const symbol_directions_car: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M200-204v44q0 16.667-11.735 28.333Q176.529-120 159.765-120 143-120 131.5-131.667 120-143.333 120-160v-304q0-4.667.5-9.333Q121-478 123-483l78-236q6-19 21.75-30T258-760h444q19.5 0 35.25 11T759-719l78 236q2 5 2.5 9.667.5 4.666.5 9.333v304q0 16.667-11.735 28.333Q816.529-120 799.765-120 783-120 771.5-131.667 760-143.333 760-160v-44H200Zm3-330h554l-55-166H258l-55 166Zm-23 60v210-210Zm105.765 160Q309-314 324.5-329.75T340-368q0-23.333-15.75-39.667Q308.5-424 286-424q-23.333 0-39.667 16.265Q230-391.471 230-368.235 230-345 246.265-329.5q16.264 15.5 39.5 15.5ZM675-314q23.333 0 39.667-15.75Q731-345.5 731-368q0-23.333-16.265-39.667Q698.471-424 675.235-424 652-424 636.5-407.735q-15.5 16.264-15.5 39.5Q621-345 636.75-329.5T675-314Zm-495 50h600v-210H180v210Z"
		/>
	</svg>
);
