import { ReactNode } from 'react';

export const symbol_bluetooth_searching: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M362-408 189-235q-9 9-21 9t-21-9q-9-9-9-21t9-21l203-203-203-203q-9-9-9-21t9-21q9-9 21-9t21 9l173 173v-272q0-14 9.5-22t20.5-8q5 0 10.5 2t10.5 7l172 172q5 5 7 10t2 11q0 6-2 11t-7 10L434-480l151 151q5 5 7 10t2 11q0 6-2 11t-7 10L413-115q-5 5-10.5 7t-10.5 2q-11 0-20.5-8t-9.5-22v-272Zm60-144 100-100-100-98v198Zm0 342 100-98-100-100v198Zm215-206-53-53q-5-5-5-10.5t5-10.5l52-52q10-10 20-7.5t13 15.5q4 14 5.5 27t1.5 27q0 14-2 28t-6 28q-3 13-12.5 15.5T637-416Zm108 107q-6-6-6-14.5t4-16.5q15-33 23-68t8-72q0-36-8-71t-23-67q-5-10-4-19t9-17q10-10 26.5-7.5T802-637q15 33 23.5 73.5T834-480q0 42-9 82t-26 78q-8 18-24.5 20.5T745-309Z"
		/>
	</svg>
);
