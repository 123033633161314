import { Fragment, useMemo } from 'react';

import * as models from 'pkg/api/models';
import { useCurrentAccountUserIds, useCurrentMembership } from 'pkg/identity';

import Reply from 'containers/chat/Reply';
import SystemReply from 'containers/chat/SystemReply';

import TimestampReply from 'components/chat/TimestampReply';

interface MessagesProps {
	handleContentReflow?: any;

	goToMessage: (messageId: number) => Promise<void>;
	handleToggleGallery: (attachment: models.attachment.Attachment) => void;

	chat: models.chat.Chat;
	thread: models.chatMessage.ChatMessage[];
}

export function Messages({
	handleToggleGallery,
	thread,
	goToMessage,
	chat,
	handleContentReflow,
}: MessagesProps) {
	const activeMembership = useCurrentMembership();
	const chatUsers = chat.users;
	const activeUserIds = useCurrentAccountUserIds();
	const selfChatUser = models.chat.findMyChatUser(chat, activeUserIds);

	const isMonitoredByLegalGuardian = !selfChatUser;

	const lastReadUsers = useMemo(() => {
		const ids: Array<Array<models.user.User>> = [];

		chatUsers.forEach((chatUser) => {
			if (activeUserIds.includes(chatUser.userId)) {
				return;
			}

			if (chatUser.lastReadMessageId) {
				if (!ids[chatUser.lastReadMessageId]) {
					ids[chatUser.lastReadMessageId] = [];
				}

				ids[chatUser.lastReadMessageId].push(chatUser.user);
			}
		});

		return ids;
	}, [activeUserIds, chatUsers]);

	const isFirstInGroup = (
		item: models.chatMessage.ChatMessage,
		prevItem: models.chatMessage.ChatMessage
	) => {
		if (
			item.userId !== prevItem?.userId ||
			item.createdAt - prevItem?.createdAt > 1800 ||
			prevItem?.type !== 'message' ||
			item.quotedMessageId ||
			prevItem?.quotedMessageId
		) {
			return true;
		}

		return false;
	};

	const isLastInGroup = (
		item: models.chatMessage.ChatMessage,
		nextItem: models.chatMessage.ChatMessage
	) => {
		if (
			item.userId !== nextItem?.userId ||
			nextItem?.createdAt - item.createdAt > 1800 ||
			nextItem?.type !== 'message' ||
			item.quotedMessageId ||
			nextItem?.quotedMessageId
		) {
			return true;
		}

		return false;
	};

	const isLastMessage = (
		item: models.chatMessage.ChatMessage,
		nextItem: models.chatMessage.ChatMessage
	) => {
		if (!nextItem) {
			return true;
		}

		return false;
	};

	const renderedThread = [];
	let groupedSystemReplies = [];

	for (let index = 0; index < thread.length; index++) {
		const item = thread[index];
		const prevItem = index === 0 ? undefined : thread[index - 1];
		const nextItem = thread[index + 1];

		if (
			(item.createdAt - prevItem?.createdAt >= 1800 &&
				groupedSystemReplies.length === 0) ||
			!prevItem
		) {
			renderedThread.push(
				<TimestampReply
					key={`${item.id}-${item.createdAt}`}
					time={item.createdAt}
				/>
			);
		}

		if (item.type === 'notice' && models.chat.isGroup(chat)) {
			groupedSystemReplies.push(item);

			if (nextItem?.type !== 'notice' || item.content !== nextItem?.content) {
				const thisLastReadUsers: models.user.User[] = [];

				groupedSystemReplies.map(
					({ id }) =>
						lastReadUsers[id] && thisLastReadUsers.push(...lastReadUsers[id])
				);

				renderedThread.push(
					<SystemReply
						key={item.id}
						items={groupedSystemReplies}
						lastReadUsers={
							thisLastReadUsers.length > 0 ? thisLastReadUsers : undefined
						}
					/>
				);

				groupedSystemReplies = [];
			}

			continue;
		}

		const isFirstGroupItem = isFirstInGroup(item, prevItem);

		if (item.type === 'message') {
			renderedThread.push(
				<Reply
					key={item.id}
					item={item}
					activeUserId={selfChatUser?.userId}
					showAuthor={
						(models.chat.isGroup(chat) || isMonitoredByLegalGuardian) &&
						isFirstGroupItem
					}
					activeMembership={activeMembership}
					isMonitoredByLegalGuardian={isMonitoredByLegalGuardian}
					isFirstGroupItem={isFirstGroupItem}
					isLastGroupItem={isLastInGroup(item, nextItem)}
					isLastMessage={isLastMessage(item, nextItem)}
					lastReadUsers={lastReadUsers[item.id] || undefined}
					attachmentOnClick={handleToggleGallery}
					onContentReflow={handleContentReflow}
					goToMessage={goToMessage}
					chat={chat}
				/>
			);
		}
	}

	renderedThread.reverse();
	return <Fragment>{renderedThread}</Fragment>;
}
