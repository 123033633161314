import { ReactNode } from 'react';

export const symbol_model_training: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M442-210q0-21-15-43t-32.5-45Q377-321 362-346.5T347-400q0-55 39-94t94-39q55 0 94 39t39 94q0 28-15 53.5T565.5-298Q548-275 533-253t-15 43h-76Zm38 90q-17 0-28.5-11.5T440-160v-10h80v10q0 17-11.5 28.5T480-120Zm249-111q-7-7-8-17.5t5-18.5q24-35 39-78.5t15-94.5q0-58-21.5-112T693-650q-9-9-9.5-21t8.5-21q9-9 22-9.5t22 8.5q48 49 76 113.5T840-440q0 57-16.5 109.5T776-234q-8 12-22 13t-25-10Zm-545-4q-30-44-47-96t-17-109q0-150 105-254.5T480-799l39 2-60-60q-9-9-9-21t9-21q9-9 21-9t21 9l118 118q5 5 7 10t2 11q0 6-2 11t-7 10L501-621q-10 10-21.5 9.5T459-621q-9-9-9-21.5t9-21.5l70-71-49-4q-125 0-212.5 87T180-440q0 45 13.5 90.5T234-267q6 8 5.5 18t-7.5 17q-11 11-25.5 10T184-235Z"
		/>
	</svg>
);
