import { Fragment, useState } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';

import Icon from 'components/icon';

import ListSelectControls from 'components/user/selectable_list/controls_selector';
import ListSearch from 'components/user/selectable_list/search';
import ListPreview from 'components/user/selectable_list/preview';
import ListItem from 'components/user/selectable_list/list_item';

import InlinePlaceholder from 'design/placeholders/inline';

const SelectableListContainer = styled.div`
	line-height: 1.2;
	overflow: auto;
`;

const GroupLabel = styled.h3`
	font-weight: var(--font-weight-bold);
	font-size: var(--font-size-xs);
	letter-spacing: 0.5px;
	color: var(--palette-gray-700);
	text-transform: uppercase;
	padding: var(--spacing-3) var(--spacing-5);
	background-color: var(--palette-gray-100);
	border-bottom: 1px solid var(--palette-gray-200);
	border-top: 1px solid var(--palette-gray-200);
`;

const ListWrapper = styled.ul`
	padding: 0;
`;

function labelTranslation(label: string) {
	switch (label) {
		case 'adminOrStaff': {
			return t('Coaches');
		}
		case 'players': {
			return t('Players');
		}
		case 'parents': {
			return t('Parents');
		}
	}
}

const NOOP = () => {
	return;
};

export interface ChatSelectableListProps {
	selectedUsers: models.user.User[];
	multipleChoice?: boolean;
	showControls?: boolean;
	showPreview?: boolean;
	showFilter?: boolean;
	ungroupedLabel?: string;
	selectable?: boolean;
	ungroupedPosition?: 'above' | 'below';
	missingEntitiesText?: string;

	onChange: (users: models.user.User[]) => void;

	users?: models.user.User[];
	groupBy?: models.membership.MembershipGroups;
}

export default function SelectableList({
	users = [],
	selectedUsers = [],
	multipleChoice = false,
	showControls = false,
	showPreview = false,
	showFilter = false,
	groupBy = null,
	ungroupedLabel = t('Ungrouped users'),
	ungroupedPosition = 'below',
	onChange = NOOP,
	selectable = true,
	missingEntitiesText = t('No users available'),
}: ChatSelectableListProps) {
	const [filterValue, setFilterValue] = useState('');

	const hasUsers = users.length > 0;
	const numSelectedUsers = selectedUsers.length;
	const selectedUserIds = selectedUsers.map((u) => u.id);

	const isSelected = (user: models.user.User) => {
		return selectedUserIds.includes(user.id);
	};

	const select = (user: models.user.User) => {
		if (!isSelected(user)) {
			selectedUsers.push(user);

			onChange(selectedUsers);
		}
	};

	const selectAll = () => {
		onChange(users);
	};

	const deselect = (user: models.user.User) => {
		onChange(selectedUsers.filter((u) => u.id !== user.id));
	};

	const deselectAll = () => {
		onChange([]);
	};

	const selectItem = (user: models.user.User) => {
		if (!multipleChoice) {
			onChange([user]);
		} else if (isSelected(user)) {
			deselect(user);
		} else {
			select(user);
		}
	};

	const handleSelectAll = () => selectAll();

	const handleDeselectAll = () => deselectAll();

	const handleFilterInput = (value: string) => {
		setFilterValue(value.toLocaleLowerCase().trim());
	};

	const groupedUsers = () => {
		const groupedIds: number[] = [];
		const groups = [];

		if (groupBy !== null) {
			for (const [label, memberships] of Object.entries(groupBy)) {
				const userIds = memberships.map(
					(m: models.membership.Membership) => m.user.id
				);
				groupedIds.push(...userIds);

				const groupUsers = users
					.filter((user) => userIds.includes(user.id))
					.filter((n) => n)
					.sort((a, b) =>
						models.user.fullName(a).localeCompare(models.user.fullName(b))
					);

				if (groupUsers.length > 0) {
					groups.push(
						<Fragment key={`group:${label}`}>
							<GroupLabel>{labelTranslation(label)}</GroupLabel>
							<ListWrapper>
								{groupUsers.map((user, index) => (
									<ListItem
										key={index}
										user={user}
										selectItem={selectItem}
										selectable={selectable}
										isSelected={isSelected}
									/>
								))}
							</ListWrapper>
						</Fragment>
					);
				}
			}
		}

		const remainingUsers = users.filter((n) => !groupedIds.includes(n.id));

		if (remainingUsers.length > 0) {
			const remainingGroup = (
				<Fragment key="group:uncategorized">
					<GroupLabel>{ungroupedLabel}</GroupLabel>
					<ListWrapper>
						{remainingUsers.map((user, index) => (
							<ListItem
								key={index}
								user={user}
								selectItem={selectItem}
								selectable={selectable}
								isSelected={isSelected}
							/>
						))}
					</ListWrapper>
				</Fragment>
			);

			if (ungroupedPosition === 'above') {
				groups.unshift(remainingGroup);
			} else {
				groups.push(remainingGroup);
			}
		}

		return <Fragment>{groups}</Fragment>;
	};

	const searchResults = users.filter((user) => {
		const searchableFields = models.user.fullName(user);

		return searchableFields.includes(filterValue);
	});

	const filteredUsers = searchResults.map((user, index) => (
		<ListItem
			key={index}
			user={user}
			selectItem={selectItem}
			selectable={selectable}
			isSelected={isSelected}
		/>
	));

	let listItems = (
		<ul>
			{users.map((u, index) => (
				<ListItem
					key={index}
					user={u}
					selectItem={selectItem}
					selectable={selectable}
					isSelected={isSelected}
				/>
			))}
		</ul>
	);

	if (!hasUsers) {
		listItems = (
			<ul>
				<li>
					<InlinePlaceholder>
						<Icon name="nav-members" />
						{missingEntitiesText}
					</InlinePlaceholder>
				</li>
			</ul>
		);
	}

	if (filterValue !== '') {
		listItems = <ul>{filteredUsers}</ul>;
	}

	if (groupBy !== null) {
		listItems = groupedUsers();
	}

	return (
		<SelectableListContainer>
			<ListSelectControls
				showControls={showControls}
				multipleChoice={multipleChoice}
				numSelectedUsers={numSelectedUsers}
				handleDeselectAll={handleDeselectAll}
				handleSelectAll={handleSelectAll}
			/>
			<ListSearch
				showFilter={showFilter}
				filterValue={filterValue}
				handleFilterInput={handleFilterInput}
			/>
			<ListPreview
				showPreview={showPreview}
				numSelectedUsers={numSelectedUsers}
				deselect={deselect}
				selectedUsers={selectedUsers}
			/>
			{listItems}
		</SelectableListContainer>
	);
}
