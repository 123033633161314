import { ReactNode } from 'react';

export const symbol_forklift: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M150-130q-45.833 0-77.917-32.083Q40-194.167 40-240q0-24.575 10.5-46.788Q61-309 80-325v-165q0-12.75 8.625-21.375T110-520h50v-210q0-12.75 8.625-21.375T190-760h238q18.375 0 33.688 9.5Q477-741 484-724l170 400q7 15 11.5 30.953Q670-277.093 670-260q0 53.857-38.071 91.929Q593.857-130 540-130q-38.764 0-71.382-22T420-210H256q-10 35-39.5 57.5T150-130Zm740-40H770q-12.75 0-21.375-8.625T740-200v-570q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T800-770v540h90q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T890-170Zm-740.175-20Q171-190 185.5-204.325q14.5-14.324 14.5-35.5Q200-261 185.675-275.5q-14.324-14.5-35.5-14.5Q129-290 114.5-275.675q-14.5 14.324-14.5 35.5Q100-219 114.325-204.5q14.324 14.5 35.5 14.5Zm390.057 0Q569-190 589.5-210.382q20.5-20.383 20.5-49.5Q610-289 589.618-309.5q-20.383-20.5-49.5-20.5Q511-330 490.5-309.618q-20.5 20.383-20.5 49.5Q470-231 490.382-210.5q20.383 20.5 49.5 20.5ZM256-270h153.6q2.4-16 7.4-31t13-29H316L193-460h-53v110h10q37 0 66.5 22.5T256-270Zm87-121h217L429-700H220v180l123 129Zm-27 61-19-20.5Q278-371 254.5-395T212-439.5L193-460h-53 53l123 130h114-114Z"
		/>
	</svg>
);
