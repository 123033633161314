import { ReactNode } from 'react';

export const symbol_sheets_rtl: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M249-101 145-205q-9-9-9-21t9-21l104-104q9-9 21-9t20.87 8.842q9.13 8.842 8.63 21T291-309l-52 53h531q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T770-196H239l52 53q8.25 8.8 8.625 20.9Q300-110 291-101q-9 9-21 9t-21-9Zm-44-339q-18.75 0-31.875-13.125T160-485v-390q0-18.75 13.125-31.875T205-920h550q18.75 0 31.875 13.125T800-875v390q0 18.75-13.125 31.875T755-440H205Zm15-270h230v-150H220v150Zm290 0h230v-150H510v150Zm-60 210v-150H220v150h230Zm60 0h230v-150H510v150Z"
		/>
	</svg>
);
