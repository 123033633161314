import { ReactNode } from 'react';

export const symbol_online_prediction: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M130-480q0 64 21.5 123T214-252q8 8 8.5 18.5T215-215q-8 8-18.5 7t-18.5-9q-46-53-72-120.5T80-480q0-75 26-142.5T178-743q8-8 18.5-9t18.5 7q8 8 7.5 18.5T214-708q-41 46-62.5 105T130-480Zm110 0q0 39 14 79.5t40 70.5q7 8 8 18t-7 18q-8 8-18.5 7t-17.5-9q-31-38-50-85.5T190-480q0-51 19-98.5t50-85.5q7-8 17-8.5t18 7.5q8 8 7.5 17.5T294-630q-26 30-40 70.5T240-480Zm202 180q0-21-15-43t-32.5-45Q377-411 362-436.5T347-490q0-55 39-94t94-39q55 0 94 39t39 94q0 28-15 53.5T565.5-388Q548-365 533-343t-15 43h-76Zm-2 50v-10h80v10q0 17-11.5 28.5T480-210q-17 0-28.5-11.5T440-250Zm280-230q0-39-14-79.5T666-630q-7-8-8-18t7-18q8-8 18.5-7t17.5 9q31 38 50 85.5t19 98.5q0 51-19 98.5T701-296q-7 8-17 8.5t-18-7.5q-8-8-7.5-17.5T666-330q26-30 40-70.5t14-79.5Zm110 0q0-64-21.5-123T746-708q-8-8-8.5-18.5T745-745q8-8 18.5-7t18.5 9q46 53 72 120.5T880-480q0 75-26 142.5T782-217q-8 8-18.5 9t-18.5-7q-8-8-7.5-18.5T746-252q41-46 62.5-105T830-480Z"
		/>
	</svg>
);
