import { ReactNode } from 'react';

export const symbol_content_paste_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M810.182-238Q799-238 789.5-245.683 780-253.366 780-268v-512h-60v60q0 12.75-8.625 21.375T690-690H380q-12.444 0-23.722-5t-19.436-13.158L256-789q-5-5-7-10.5t-2-10.833q0-10.667 8-20.167 8-9.5 22-9.5h105q7-35 34.5-57.5T480-920q36 0 63.5 22.5T578-840h202q26 0 43 17t17 43v512q0 15-9.318 22.5t-20.5 7.5ZM480-780q17 0 28.5-11.5T520-820q0-17-11.5-28.5T480-860q-17 0-28.5 11.5T440-820q0 17 11.5 28.5T480-780Zm214 600L180-694v514h514Zm-514 60q-26 0-43-17t-17-43v-574l-38-38q-9-9.067-9-21.533Q73-826 82.053-835q9.052-9 21.5-9Q116-844 125-835l710 710q9 9 9 21t-9.053 21q-9.052 9-21.5 9Q801-74 792-83l-38-37H180Z"
		/>
	</svg>
);
