import { ReactNode } from 'react';

export const symbol_cookie: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-82 31.5-155T197-763q54-55 126-87t153-32q32 0 51.5 15t24.5 47q8 58 50 98.5t99 48.5q21 3 33 16t17 37q5 31 26 55.5t50 38.5q31 15 43 36t8 53q-7 74-41 139t-87.5 113.5Q696-136 627.103-108 558.207-80 480-80Zm0-60q142 0 236-93.5T821-464q-54-20-87.5-59.5T692-614q-81-11-136.5-70T492-821q-74-3-138.5 24t-112 74Q194-676 167-612.5T140-480q0 142 99 241t241 99Zm1-345Zm-60.882-73Q446-558 464-576.118q18-18.117 18-44Q482-646 463.882-664q-18.117-18-44-18Q394-682 376-663.882q-18 18.117-18 44Q358-594 376.118-576q18.117 18 44 18Zm-80 200Q366-358 384-376.118q18-18.117 18-44Q402-446 383.882-464q-18.117-18-44-18Q314-482 296-463.882q-18 18.117-18 44Q278-394 296.118-376q18.117 18 44 18ZM600-320q17 0 28.5-11.5T640-360q0-17-11.5-28.5T600-400q-17 0-28.5 11.5T560-360q0 17 11.5 28.5T600-320Z"
		/>
	</svg>
);
