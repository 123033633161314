import { ReactNode } from 'react';

export const symbol_bottom_panel_open: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M380-527h200q9.917 0 13.458-9.5Q597-546 590-553l-89-89q-9-9-21-9t-21 9l-89 89q-7 7-3.458 16.5Q370.083-527 380-527ZM180-120q-24.75 0-42.375-17.625T120-180v-600q0-24.75 17.625-42.375T180-840h600q24.75 0 42.375 17.625T840-780v600q0 24.75-17.625 42.375T780-120H180Zm0-207v147h600v-147H180Zm0-60h600v-393H180v393Zm0 60v147-147Z"
		/>
	</svg>
);
