import { useContext } from 'react';
import { t } from '@transifex/native';

import spacing from 'pkg/config/spacing';

import * as models from 'pkg/api/models';

import { TemplateContext } from 'routes/scheduling/templates/single';
import ScheduleCardListItem from 'routes/scheduling/templates/schedule_items/card_list_item';

import SectionTitle from 'components/SectionTitle';
import * as StepModal from 'components/step-modal';

import Column from 'components/layout/column';

const _context = 'scheduling/bookings';

interface ScheduleItemConflictsModalProps {
	scheduleItem: models.scheduleItem.ScheduleItem;

	hideModal: () => void;
}

const ScheduleItemConflictsModal = ({
	scheduleItem,
	hideModal,
}: ScheduleItemConflictsModalProps) => {
	const templateContext = useContext(TemplateContext);

	const scheduleItemConflicts = templateContext.conflicts[scheduleItem.id];
	const scheduleItems = templateContext.scheduleItems.filter(
		(contextScheduleItems) =>
			scheduleItemConflicts.scheduleItems.includes(contextScheduleItems.id)
	);

	return (
		<StepModal.Base wide onClose={hideModal}>
			<StepModal.Step
				title={t('Showing conflicts for: {title}', {
					_context,
					title: scheduleItem.title,
				})}
				prevLabel={t('Close')}
				nextLabel={t('Done')}>
				<Column>
					<ScheduleCardListItem scheduleItem={scheduleItem} />
					<SectionTitle>
						{t('Conflicting with:', {
							_context,
						})}
					</SectionTitle>
					<Column spacing={spacing._3}>
						{scheduleItems.map((item) => (
							<ScheduleCardListItem
								key={item.id}
								scheduleItem={item}
								conflicts={scheduleItemConflicts}
							/>
						))}
					</Column>
				</Column>
			</StepModal.Step>
		</StepModal.Base>
	);
};

export default ScheduleItemConflictsModal;
