import { ReactNode } from 'react';

export const symbol_source_environment: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M80-150v-624q0-7.692 3.5-13.846T93-799l165-110q7.73-5 16.865-5Q284-914 292-909l165 110q6 5 9.5 11.154T470-774v99h380q12.75 0 21.375 8.625T880-645v495q0 12.75-8.625 21.375T850-120H110q-12.75 0-21.375-8.625T80-150Zm60-30h105v-105H140v105Zm0-165h105v-105H140v105Zm0-165h105v-105H140v105Zm0-165h105v-105H140v105Zm165 0h105v-105H305v105Zm0 495h515v-435H305v435Zm275-330h105q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T685-450H580q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T580-510Zm0 165h105q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T685-285H580q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T580-345ZM470-479.825q0 12.825-8.675 21.325-8.676 8.5-21.5 8.5-12.825 0-21.325-8.675-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5ZM439.825-285Q427-285 418.5-293.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Z"
		/>
	</svg>
);
