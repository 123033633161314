import { ReactNode } from 'react';

export const symbol_notifications_unread: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-80q-33 0-56.5-23.5T400-160h160q0 33-23.5 56.5T480-80Zm0-422ZM190-200q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T190-260h50v-304q0-84 49.5-150.5T420-798v-22q0-25 17.5-42.5T480-880q22.917 0 38.958 14.5Q535-851 539-830q-12 20-19 42.5t-9 46.5q-8-2-15.283-2.5-7.284-.5-15.717-.5-75 0-127.5 52.5T300-564v304h360v-284q15 3 30 4t30-1v281h50q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T770-200H190Zm510.882-420Q655-620 623-652.118q-32-32.117-32-78Q591-776 623.118-808q32.117-32 78-32Q747-840 779-807.882q32 32.117 32 78Q811-684 778.882-652q-32.117 32-78 32Z"
		/>
	</svg>
);
