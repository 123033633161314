import { ReactNode } from 'react';

export const symbol_bathroom: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M353-244q16 0 27.5-11.5T392-283q0-16-11.5-27.5T353-322q-16 0-27.5 11.5T314-283q0 16 11.5 27.5T353-244Zm127 0q16 0 27.5-11.5T519-283q0-16-11.5-27.5T480-322q-16 0-27.5 11.5T441-283q0 16 11.5 27.5T480-244Zm127 0q16 0 27.5-11.5T646-283q0-16-11.5-27.5T607-322q-16 0-27.5 11.5T568-283q0 16 11.5 27.5T607-244ZM353-370q16 0 27.5-11.5T392-409q0-16-11.5-27.5T353-448q-16 0-27.5 11.5T314-409q0 16 11.5 27.5T353-370Zm127 0q16 0 27.5-11.5T519-409q0-16-11.5-27.5T480-448q-16 0-27.5 11.5T441-409q0 16 11.5 27.5T480-370Zm127 0q16 0 27.5-11.5T646-409q0-16-11.5-27.5T607-448q-16 0-27.5 11.5T568-409q0 16 11.5 27.5T607-370ZM480-722q-83 0-141.5 58.5T280-522q0 11 7.475 18.5T306-496h348q11.05 0 18.525-7.475Q680-510.95 680-522q0-83-58.5-141.5T480-722Zm0 50q55 0 98.5 34.5T630-546H330q8-57 51.5-91.5T480-672ZM140-80q-24 0-42-18t-18-42v-680q0-24 18-42t42-18h680q24 0 42 18t18 42v680q0 24-18 42t-42 18H140Zm0-60h680v-680H140v680Zm0 0v-680 680Z"
		/>
	</svg>
);
