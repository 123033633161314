import { ReactNode } from 'react';

export const symbol_calculate: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M314-316v63q0 10.833 7.116 17.917 7.117 7.083 18 7.083Q350-228 357-235.083q7-7.084 7-17.917v-63h63q10.833 0 17.917-7.116 7.083-7.117 7.083-18Q452-352 444.917-359q-7.084-7-17.917-7h-63v-63q0-10.833-7.116-17.917-7.117-7.083-18-7.083Q328-454 321-446.917q-7 7.084-7 17.917v63h-63q-10.833 0-17.917 7.116-7.083 7.117-7.083 18Q226-330 233.083-323q7.084 7 17.917 7h63Zm240 53h152q10.4 0 17.2-7.116 6.8-7.117 6.8-18 0-10.884-6.5-17.384T706-312H553q-10.4 0-17.2 6.5-6.8 6.5-6.8 17.384 0 10.883 7.083 18Q543.167-263 554-263Zm0-107h151q10.833 0 17.917-7.116 7.083-7.117 7.083-18Q730-406 722.917-413q-7.084-7-17.917-7H554q-10.833 0-17.917 7.116-7.083 7.117-7.083 18Q529-384 536.083-377q7.084 7 17.917 7Zm73-224 43 43q7.636 8 17.818 8Q698-543 706-551q8-8 8-18t-8-18l-43-43 43-43q8-7.636 8-17.818Q714-701 706-709q-8-8-18.182-8-10.182 0-17.818 8l-43 43-43-43q-7.636-8-17.818-8Q556-717 548-709q-8 8-8 18.182 0 10.182 8 17.818l43 43-43 43q-8 7.714-8 18t8 18q7.636 8 17.818 8Q576-543 584-551l43-43Zm-361-11h146q10.833 0 17.917-7.116 7.083-7.117 7.083-18Q437-641 429.917-648q-7.084-7-17.917-7H266q-10.833 0-17.917 7.116-7.083 7.117-7.083 18Q241-619 248.083-612q7.084 7 17.917 7Zm-86 485q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-60h600v-600H180v600Zm0-600v600-600Z"
		/>
	</svg>
);
