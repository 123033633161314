import { ReactNode } from 'react';

export const symbol_bath_outdoor: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M220-492v74q-20 14-30 28.5T180-359q0 5 1.5 11t3.5 11l55 17h79q5 0 9 2.5t6 7.5l2 7 39-15 11-2h147q4 0 7 1.5t5 4.5l24 32 107-16 40-56q2-3 5-4.5t7-1.5h52q-1-17-11-31.5T740-418v-74q49 28 74.5 61t25.5 71l36 54q2 3 2.5 6.5t-.5 6.5l-35 103q-2 5-5.5 7.5T829-180H716l2 8q1 5-2 10t-8 7l-83 33-11 2H350q-5 0-8.5-2.5T336-130l-6-16-103 24q-3 1-6.5.5T214-124L86-216q-3-2-4.5-5t-1.5-7v-83q0-5 2-8t6-5l34-17q-1-5-1.5-9t-.5-9q0-38 25.5-71.5T220-492Zm-58 255 37-37-39-19-20 10v31l22 15Zm73 52 75-18-22-57h-18l-57 57 22 18Zm147 5h121l27-54-20-26H392l-34 14 24 66Zm190 0h36l42-17-7-35-45 7-26 45Zm138-60h87l17-51-6-9h-57l-41 60ZM241-598q-22-31-35.5-66T192-737v-16.5q0-8.5 2-16.5 2-12 10-21t20-9q13 0 22 9t7 21q-1 8-1.5 14.5T251-741q0 34 14.5 64t34.5 57q21 29 35 62.5t14 69.5q0 5-.5 9t-.5 9q-1 12-9 21t-20 9q-13 0-22.5-9t-7.5-21q1-5 1-10v-10q0-31-15.5-57T241-598Zm160 0q-22-31-35.5-66T352-737v-16.5q0-8.5 2-16.5 2-12 10-21t20-9q13 0 22 9t7 21q-1 8-1.5 14.5T411-741q0 34 14.5 64t34.5 57q21 29 35 62.5t14 69.5q0 5-.5 9t-.5 9q-1 12-9 21t-20 9q-13 0-22.5-9t-7.5-21q1-5 1-10v-10q0-31-15.5-57T401-598Zm160 0q-22-31-35.5-66T512-737v-16.5q0-8.5 2-16.5 2-12 10-21t20-9q13 0 22 9t7 21q-1 8-1.5 14.5T571-741q0 34 14.5 64t34.5 57q21 29 35 62.5t14 69.5q0 5-.5 9t-.5 9q-1 12-9 21t-20 9q-13 0-22.5-9t-7.5-21q1-5 1-10v-10q0-31-15.5-57T561-598Z"
		/>
	</svg>
);
