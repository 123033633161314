import { ReactNode } from 'react';

export const symbol_physical_therapy: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M483-275ZM598-75l-216-85H150q-12.75 0-21.375-8.625T120-190v-130q0-83 58.5-141.5T320-520h429q38 0 64.5 26t26.5 64q0 31-19 55.5T773-342l-93 27v184q0 15.909-7.057 28.788T654-81q-12.148 8.25-26.574 10.125Q613-69 598-75Zm-99.956-225H375q-14 0-23 9t-11 20q-2 11 3.682 22.455Q350.364-237.091 364-232l256 101v-169H498.044ZM180-220h113q-6-10-9.5-21.5T280-265q0-39 28-67t67-28h238l145-40q14-4 19-13t3-20q-2-11-10-19t-21-8H320q-58.333 0-99.167 40.833Q180-378.333 180-320v100Zm303-55Zm-83-285q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm0-60q42 0 71-29t29-71q0-42-29-71t-71-29q-42 0-71 29t-29 71q0 42 29 71t71 29Zm0-100Z"
		/>
	</svg>
);
