import { ReactNode } from 'react';

export const symbol_touch_app: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M445-80q-29 0-56-12t-45-35L143-383q-7-9-7-20t8-19l4-4q14-15 34.5-18.5T221-438l99 53v-365q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T380-750v415q0 17-14.5 25.5t-29.5.5l-100-53 156 198q10 12 23.76 18 13.76 6 29.24 6h205q38 0 64-26t26-64v-170q0-25.5-17.25-42.75T680-460H490q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T490-520h190q50 0 85 35t35 85v170q0 63-43.5 106.5T650-80H445Zm43-250Zm-16.696-320Q458-650 449.5-658.625 441-667.25 441-680q0-1.5 4-15 7-12 11-26t4-29.478Q460-796 427.882-828q-32.117-32-78-32Q304-860 272-827.917 240-795.833 240-750q0 15 4 29t11 26q2 3 3 6.5t1 8.5q0 12.75-8.58 21.375T228.58-650q-8.58 0-15.58-4t-11.167-11.839Q191-685 185.5-706.25q-5.5-21.25-5.5-44.2 0-70.55 49.725-120.05T350-920q70.55 0 120.275 49.5Q520-821 520-750.313q0 22.994-5.704 44.286Q508.593-684.736 498-666q-4 8-11.043 12-7.044 4-15.653 4Z"
		/>
	</svg>
);
