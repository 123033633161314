import { t } from '@transifex/native';

import spacing from 'pkg/config/spacing';

import * as routes from 'pkg/router/routes';
import { useCurrentAccount, useCurrentMembership } from 'pkg/identity';
import * as models from 'pkg/api/models';

import Column from 'components/layout/column';

import * as Context from 'design/context_menu';
import Button from 'design/button';

import * as css from './styles.css';

interface FooterProps {
	openJoinModal: () => void;
}

export default function Footer({ openJoinModal }: FooterProps): JSX.Element {
	const contextMenu = Context.useContextMenu();
	const account = useCurrentAccount();
	const membership = useCurrentMembership();

	const open = () => {
		contextMenu.close();
		openJoinModal();
	};

	const showManageChildrenButton =
		membership.isLegalGuardian || models.account.isAnAdult(account);

	return (
		<div className={css.footer}>
			<Context.Pane>
				<Column spacing={spacing._3}>
					<Button secondary icon="add" onClick={open}>
						{t('Join a new group or organization')}
					</Button>
					{showManageChildrenButton && (
						<Button
							secondary
							icon="person_add"
							href={routes.Account.Settings.Show('children')}
							onClick={contextMenu.close}>
							{t('Add or manage children')}
						</Button>
					)}
				</Column>
			</Context.Pane>
		</div>
	);
}
