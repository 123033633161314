import styled from 'styled-components';

import Column from 'components/layout/column';

export const Title = styled.div`
	font-size: var(--font-size-lg);

	font-weight: var(--font-weight-semibold);
`;

export const CenterColumn = styled(Column)`
	text-align: center;
`;
