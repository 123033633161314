import { ReactNode } from 'react';

export const symbol_filter_tilt_shift: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M148-409q8 32 19 60t26 52q8 12 6.5 25.5T188-249q-10 9-22.5 9T145-251q-22-32-37-70.5T85-407q-2-13 5.5-23t20.5-10q14 0 24 8.5t13 22.5Zm44-253q-15 24-25.5 52T148-551q-3 14-13 22.5t-24 8.5q-12 0-19.5-10T86-552q9-46 24-85t36-71q7-11 20-12t22 8q10 9 11 23t-7 27Zm105 468q26 16 54 27.5t57 18.5q13 3 21.5 13t8.5 23q0 12-9.5 19.5T407-87q-44-8-83-22.5T252-145q-11-8-12.5-20.5T247-188q10-10 23.5-12t26.5 6Zm115-619q-30 7-58 18.5T300-767q-13 8-27.5 6.5T248-772q-10-10-8.5-22.5T252-815q34-21 74-36t85-23q12-2 21 6t9 20q0 13-8 22.5T412-813Zm69 433q-42 0-71-29t-29-71q0-42 29-71t71-29q42 0 71 29t29 71q0 42-29 71t-71 29Zm180 186q13-8 28-6.5t25 11.5q10 10 8.5 23T710-146q-34 22-74.5 37T548-86q-12 2-21-6t-9-20q0-13 8-23t22-13q29-7 57-18t56-28ZM550-812q-13-3-21-13t-8-23q0-12 9-20t21-6q43 8 82.5 22.5T708-815q11 8 13 21t-7 23q-10 10-23.5 11.5T664-766q-27-17-56-28t-58-18Zm263 403q3-14 13-22.5t24-8.5q12 0 19.5 9.5T875-408q-10 47-24.5 85.5T815-252q-7 11-19.5 12t-21.5-8q-10-10-11.5-23.5T769-297q15-24 25.5-52t18.5-60Zm-44-254q-8-12-6.5-25.5T774-712q9-9 21.5-8t20.5 12q22 32 37 70.5t23 85.5q2 13-5.5 22.5T850-520q-14 0-24-9t-13-23q-8-32-18.5-59.5T769-663Z"
		/>
	</svg>
);
