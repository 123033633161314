import { ReactNode } from 'react';

export const symbol_fiber_pin: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M243-446h100q23 0 36.5-14.375T393-496v-57q0-21.25-13.5-35.625T343-603H208q-6 0-10.5 4.5T193-588v206q0 10.833 7.116 17.917 7.117 7.083 18 7.083Q229-357 236-364.083q7-7.084 7-17.917v-64Zm226.884-157Q459-603 452-595.917q-7 7.084-7 17.917v196q0 10.833 7.116 17.917 7.117 7.083 18 7.083Q481-357 488-364.083q7-7.084 7-17.917v-196q0-10.833-7.116-17.917-7.117-7.083-18-7.083ZM598-535l112 167q4 5 9 8t11 3h12q11 0 17.5-6.5T766-381v-200q0-9-6.5-15.5T744-603q-9 0-16 6.5t-7 15.5v149L605-594q-3-5-8-7t-10.172-2h-10.345Q567-603 560-596.5t-7 15.5v202q0 9 7 15.5t16 6.5q9 0 15.5-7t6.5-16v-155Zm-355 39v-57h100v57H243ZM140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm0-60h680v-520H140v520Zm0 0v-520 520Z"
		/>
	</svg>
);
