import { ReactNode } from 'react';

export const symbol_flashlight_on: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M330-140v-383l-80-117q-5.385-7.727-7.692-16.227Q240-664.727 240-674v-146q0-24.75 17.625-42.375T300-880h360q24.75 0 42.375 17.625T720-820v146q0 9.273-2.308 17.773-2.307 8.5-7.692 16.227l-80 117v383q0 24.75-17.625 42.375T570-80H390q-24.75 0-42.375-17.625T330-140Zm150.176-186Q459-326 444-340.824q-15-14.823-15-36Q429-398 443.824-413q14.823-15 36-15Q501-428 516-413.176q15 14.823 15 36Q531-356 516.176-341q-14.823 15-36 15ZM300-764h360v-56H300v56Zm360 60H300v35l90 131v398h180v-398l90-131v-35ZM480-480Z"
		/>
	</svg>
);
