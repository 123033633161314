import { Record } from 'immutable';
import { schema } from 'normalizr';

import User from 'pkg/models/user';

interface INotification {
	id: number;
	userId: number;
	accountId: number;
	authorId: number;
	author: User;
	createdAt: number;
	isRead: boolean;
	message: string;
	target: any;
	targetId: number;
	triggerId: number;
	targetType: string;
	title: string;
	type: string;
}

const NotificationProps: INotification = {
	id: 0,
	userId: 0,
	accountId: 0,
	authorId: 0,
	author: null,
	createdAt: Math.round(Date.now() / 1000),
	isRead: false,
	message: '',
	target: null,
	targetId: 0,
	triggerId: 0,
	targetType: '',
	title: '',
	type: '',
};

const NotificationSchema = new schema.Entity('notifications', {
	author: User.normalizr(),
});

class Notification
	extends Record(NotificationProps, 'NotificationRecord')
	implements INotification
{
	static normalizr(): schema.Entity {
		return NotificationSchema;
	}
}

export default Notification;
