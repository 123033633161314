const redo = {
	name: 'redo',
	objects: [
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				d: 'M9.49648 15.0531L5.50133 11.0579L9.35507 7.2042L10.2743 8.12344L7.98981 10.4079L15.5052 10.4079C17.458 10.4079 19.0411 11.991 19.0411 13.9439C19.0411 15.8968 17.458 17.4799 15.5052 17.4799L13.7322 17.4799L13.7322 16.1799L15.5052 16.1799C16.7401 16.1799 17.7411 15.1788 17.7411 13.9439C17.7411 12.709 16.7401 11.7079 15.5052 11.7079L7.98981 11.7079L10.4157 14.1338L9.49648 15.0531Z',
			},
		},
	],
};
export default redo;
