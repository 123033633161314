import { createRef, Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { t } from '@transifex/native';

import { setTabBarHeight } from 'pkg/actions/app';

import * as routes from 'pkg/router/routes';
import store from 'pkg/store/createStore';
import { RootState } from 'pkg/reducers';
import * as selectors from 'pkg/selectors';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { useCurrentRouterState } from 'pkg/router/hooks';
import { useAccountIdentity } from 'pkg/identity/account';
import { getUnreadChatsForOtherOrganizations } from 'pkg/api/models/group';
import { useCurrentGroup, useCurrentMembership } from 'pkg/identity';

import UnreadChatMessagesBadge from 'containers/chat/UnreadChatMessagesBadge';

import BottomMenu from 'components/navigation/menus/small_screen/BottomMenu';
import BottomMenuItem from 'components/navigation/menus/small_screen/BottomMenuItem';
import * as MoreMenu from 'components/navigation/menus/small_screen/styles';
import { getTabBarItems } from 'components/navigation/menus/small_screen/config';
import UserNavigation from 'components/navigation/menus/small_screen/UserNavigation';
import NavigationItems from 'components/navigation/menus/NavigationItems';
import ContextSwitcher from 'components/navigation/menus/context_switch';

import * as Drawer from 'design/navigation/drawer';

export default function NavBarContainer(): JSX.Element {
	const [showMoreMenu, setShowMoreMenu] = useState(false);
	const { organizations, organization } = useAccountIdentity();
	const unreadChats = getUnreadChatsForOtherOrganizations(
		organization,
		organizations
	);

	const activeMembership = useCurrentMembership();
	const group = useCurrentGroup();
	const [currentState] = useCurrentRouterState('/');

	const tabBarHeight = useSelector(
		(state: RootState) => state.app.tabBarHeight
	);

	const unreadNotifications = useSelector(selectors.notifications.hasUnread);
	const hasUnreadNotifications = unreadNotifications || unreadChats > 0;

	const tabNavRef = createRef<any>();

	useComponentDidMount(() => {
		store.dispatch(setTabBarHeight(tabNavRef.current.offsetHeight));
	});

	useEffect(() => {
		let bottomNavigationHeight = 0;

		if (tabNavRef.current) {
			bottomNavigationHeight = tabNavRef.current.offsetHeight;
		}

		if (bottomNavigationHeight !== tabBarHeight) {
			store.dispatch(setTabBarHeight(bottomNavigationHeight));
		}
	}, [tabBarHeight]);

	useEffect(() => {
		if (showMoreMenu) {
			setShowMoreMenu(false);
		}
	}, [currentState.location]);

	// The path from window.location is available sooner than the new URL in the state, so we use this to check active states
	const currentUrl = () => {
		const { pathname } = window.location;
		return pathname;
	};

	const toggleMoreMenu = () => {
		setShowMoreMenu(!showMoreMenu);
	};

	const moreMenu = (
		<MoreMenu.Wrapper visible={showMoreMenu}>
			<MoreMenu.Navigation>
				<MoreMenu.Header>
					<ContextSwitcher />
				</MoreMenu.Header>

				<Drawer.Items>
					<NavigationItems />
					<UserNavigation />
				</Drawer.Items>
			</MoreMenu.Navigation>
		</MoreMenu.Wrapper>
	);

	if (!organization?.id) {
		return (
			<Fragment>
				<BottomMenu ref={tabNavRef}>
					<BottomMenuItem
						name={t('Home')}
						icon="home"
						href={routes.Home()}
						slug="home"
						active={currentUrl() === routes.Home() && !showMoreMenu}
						testid="navbar.menu_item.home"
					/>

					<BottomMenuItem
						name={t('Notifications')}
						icon="notifications"
						unread={hasUnreadNotifications}
						href="/notifications"
						slug="notifications"
						active={currentUrl() === routes.Notifications() && !showMoreMenu}
						testid="navbar.menu_item.notifications"
					/>
					<BottomMenuItem
						name={t('More')}
						icon="menu"
						activeIcon="close"
						as="div"
						onClick={toggleMoreMenu}
						active={showMoreMenu}
						testid="navbar.menu_item.more"
					/>
				</BottomMenu>

				{moreMenu}
			</Fragment>
		);
	}

	const menuItems = getTabBarItems(
		organization.id,
		activeMembership,
		group
	).map((item, n) => {
		if (!item.properties.show) {
			return null;
		}

		return (
			<BottomMenuItem
				key={`tab-menu-item-${n}`}
				name={item.name}
				onClick={showMoreMenu ? toggleMoreMenu : undefined}
				active={currentUrl() === item.properties.href && !showMoreMenu}
				testid={`navbar.menu_item.${item.properties.slug}`}
				{...item.properties}>
				{item.properties.slug === 'chat' && (
					<UnreadChatMessagesBadge inTabBar />
				)}
			</BottomMenuItem>
		);
	});

	return (
		<Fragment>
			<BottomMenu ref={tabNavRef}>
				{menuItems}

				<BottomMenuItem
					name={t('Notifications')}
					icon="notifications"
					unread={hasUnreadNotifications}
					href="/notifications"
					slug="notifications"
					active={currentUrl() === '/notifications' && !showMoreMenu}
					testid="navbar.menu_item.notifications"
				/>

				<BottomMenuItem
					name={t('More')}
					icon="menu"
					activeIcon="close"
					as="div"
					onClick={toggleMoreMenu}
					active={showMoreMenu}
					testid="navbar.menu_item.more"
				/>
			</BottomMenu>

			{moreMenu}
		</Fragment>
	);
}
