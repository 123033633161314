import {
	memo,
	useRef,
	useEffect,
	useContext,
	createContext,
	useMemo,
	useCallback,
	MutableRefObject,
	ReactNode,
	RefObject,
	CSSProperties,
} from 'react';

import uuid from 'pkg/uuid';
import withForwardedRef from 'pkg/withForwardedRef';

interface SpyContextProps {
	observe: (
		ref: MutableRefObject<unknown>,
		id: string,
		onTrigger: (
			e: IntersectionObserverEntry,
			ref?: MutableRefObject<unknown>
		) => void
	) => void;
	unobserve: (ref: MutableRefObject<unknown>, id: string) => void;
}

export const SpyContext = createContext<SpyContextProps>({
	observe: (): void => {
		console.debug('noop');
	},
	unobserve: (): void => {
		console.debug('noop');
	},
});

interface TriggerProps {
	offsetY?: number;
	onTrigger: (
		e: IntersectionObserverEntry,
		ref?: MutableRefObject<unknown>
	) => void;
}

export const Trigger = memo<TriggerProps>(({ offsetY = 0, onTrigger }) => {
	const ref = useRef(null);

	const spy = useContext(SpyContext);
	const id = useMemo(() => uuid(), []);

	useEffect(() => {
		spy.observe(ref.current, id, onTrigger);
	}, [id, onTrigger, spy]);

	useEffect(() => {
		const r = ref.current;
		return () => {
			spy.unobserve(r, id);
		};
	}, [id, spy]);

	return (
		<div
			style={{
				height: '1px',
				transform: `translateY(${offsetY}px)`,
			}}
			data-id={id}
			ref={ref}
		/>
	);
});

interface ScrollSpyProps {
	threshold: number;

	rootMargin?: string;
	className?: string;
	styles?: CSSProperties;

	children: ReactNode;
	forwardedRef: RefObject<HTMLDivElement>;
}

export const ScrollSpy = withForwardedRef(
	memo(
		({
			children,
			className,
			threshold = 0,
			rootMargin = '0px',
			styles,
			forwardedRef,
		}: ScrollSpyProps) => {
			const observeQueue = useRef([]);
			const ref = useRef();
			const observer = useRef(null);
			const cbMap = useRef<{
				[key: string]: (
					e: IntersectionObserverEntry,
					ref?: MutableRefObject<unknown>
				) => void;
			}>({});

			const observe = useCallback((target: any, id: any, triggerFunc: any) => {
				const setCallbacks = () => {
					if (!observer.current || cbMap.current[id]) {
						return;
					}

					cbMap.current[id] = triggerFunc;
					observer.current.observe(target);
				};

				if (!observer.current) {
					observeQueue.current.push(setCallbacks);
					return;
				}

				setCallbacks();
			}, []);

			const unobserve = useCallback((target: any, id: any) => {
				if (!cbMap.current[id]) {
					return;
				}

				delete cbMap.current[id];
				observer.current.unobserve(target);
			}, []);

			useEffect(() => {
				const ioCallback = (entries: IntersectionObserverEntry[]) => {
					entries.forEach((entry) =>
						cbMap.current[(entry.target as HTMLElement).dataset.id]?.call(
							null,
							entry,
							ref
						)
					);
				};

				observer.current = new IntersectionObserver(ioCallback, {
					root: forwardedRef ? forwardedRef.current : ref.current,
					threshold,
					rootMargin,
				});

				observeQueue.current.forEach((cb) => cb());
				observeQueue.current = [];
			}, [forwardedRef, rootMargin, threshold]);

			return (
				<SpyContext.Provider value={{ observe, unobserve }}>
					<div ref={forwardedRef || ref} className={className} style={styles}>
						{children}
					</div>
				</SpyContext.Provider>
			);
		}
	)
);
