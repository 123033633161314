import { ReactNode } from 'react';

export const symbol_weight: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M217-180h526l-63-440H280l-63 440Zm263-500q25.5 0 42.75-17.25T540-740q0-25.5-17.25-42.75T480-800q-25.5 0-42.75 17.25T420-740q0 25.5 17.25 42.75T480-680Zm104 0h96q23.108 0 40.054 14.5T740-628l62 440q4 27-13.625 47.5T743-120H217q-27.75 0-45.375-20.5T158-188l62-440q3-23 19.946-37.5T280-680h96q-8-14-12-28.567-4-14.566-4-31.433 0-50 35-85t85-35q50 0 85 35t35 85q0 17-4 31.5T584-680ZM217-180h526-526Z"
		/>
	</svg>
);
