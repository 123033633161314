import { Record } from 'immutable';
import { schema } from 'normalizr';

import Group from 'pkg/models/group';
import Country from 'pkg/models/country';
import { membershipId } from 'pkg/models/membership';
import * as schemas from 'pkg/models/schemas';
import Scrubbable from 'pkg/models/scrubbable';

import { yearsSince } from 'pkg/date';

export enum AccountNotificationSettings {
	Push = 'push',
	Email = 'email',
}

export enum AccountClearNotificationSettings {
	Push = '!push',
	Email = '!email',
}

export enum AccountFlags {
	CompletedFirstLogin = 'completed-first-login',
	OptOutAnalytics = 'optout-ga',
}

export enum AccountClearFlags {
	OptOutAnalytics = '!optout-ga',
}

export enum AccountHourFormat {
	'24h' = 0,
	'12h' = 1,
}

interface IAccount extends Scrubbable {
	id: number;
	firstName: string;
	lastName: string;
	email: string;
	birthDate: string;
	profileImageUrl: string;
	createdAt: number;
	languageCode: string;
	hourFormat: AccountHourFormat;
	countryId: number;
	activitySummary: any;
	needsParentalConsent: boolean;
	parentalConsentEmail: string;
	parentalConsentGranted?: number;
	parentalConsentRevoked?: number;
	hasAppleAuth?: boolean;
	hasEmail?: boolean;

	trackingHash: any;
	helpScoutSignature: any;

	wards: number[];
	flags: AccountFlags[] | AccountClearFlags;
	users: number[];
	willBeDeletedAt: number;
	notificationSettings: AccountNotificationSettings[];

	links: { [key: string]: string };
}

const AccountProps: IAccount = {
	id: null,
	firstName: '',
	lastName: '',
	email: null,
	birthDate: '',
	profileImageUrl: '',
	createdAt: null,
	languageCode: null,
	hourFormat: AccountHourFormat['24h'],
	countryId: null,
	activitySummary: null,
	needsParentalConsent: false,
	parentalConsentEmail: '',
	parentalConsentGranted: null,
	parentalConsentRevoked: null,
	hasAppleAuth: false,
	hasEmail: false,
	willBeDeletedAt: null,

	trackingHash: null,
	helpScoutSignature: null,

	wards: [],
	flags: [],
	users: [],
	notificationSettings: [],

	links: {},

	_s: undefined,
};

export const AccountSchema = new schema.Entity('accounts', undefined, {
	idAttribute: (value) => value.id,
});

const MembershipSchema = new schema.Entity('memberships', undefined, {
	idAttribute: membershipId,
});

MembershipSchema.define({
	group: Group.normalizr(),
});

/* @NOTE If you make changes to this schema, you need to mirror those changes to the schema in data/models/account_relation aswell. */
const AccountRelationSchema = new schema.Entity(
	'accountRelations',
	{
		targetAccount: AccountSchema,
		account: AccountSchema,
	},
	{
		idAttribute: (value) => value.id,
	}
);

AccountSchema.define({
	wards: [AccountRelationSchema],
	country: Country.normalizr(),
	users: [schemas.users],
});

class Account
	extends Record(AccountProps, 'AccountRecord')
	implements IAccount
{
	get fullName(): string {
		return `${this.get('firstName')} ${this.get('lastName')}`;
	}

	get initials(): string {
		return [
			this.get('firstName').substring(0, 1),
			this.get('lastName').substring(0, 1),
		].join('');
	}

	getProfileImageUrl(): string {
		return this.get('profileImageUrl');
	}

	get age(): number {
		return yearsSince(new Date(this.get('birthDate')));
	}

	get isAnAdult(): boolean {
		return this.age >= 18;
	}

	get nickname(): string {
		return this.getIn(['meta', 'nickname']) as string;
	}

	get gender(): string {
		return this.getIn(['meta', 'gender']) as string;
	}

	hasFlag(flag: AccountFlags): boolean {
		return this.flags.includes(flag);
	}

	hasNotificationSetting(notification: AccountNotificationSettings): boolean {
		return this.notificationSettings.includes(notification);
	}

	static normalizr(): schema.Entity {
		return AccountSchema;
	}
}

export default Account;
