import { ReactNode } from 'react';

export const symbol_strikethrough_s: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M504-160q-67 0-125-31t-93-88q-7-12-2.5-25t17.5-20q14-8 29.5-3.5T356-309q26 35 65 54t83 19q46 0 80-28t34-72q0-12-3-24t-7-24q-5-15 2-28.5t22-18.5q14-5 27.5 2.5T679-407q7 17 11 35t4 36q0 75-56.5 125.5T504-160ZM110-490q-13 0-21.5-8.5T80-520q0-13 8.5-21.5T110-550h740q13 0 21.5 8.5T880-520q0 13-8.5 21.5T850-490H110Zm218-120q-15 0-26-11t-11-26q0-70 55-114.5T474-806q48 0 92 18.5t74 55.5q9 11 6.5 25.5T632-684q-13 10-29 8t-27-14q-20-20-46.5-30T474-730q-42 0-74.5 22.5T365-647q0 15-11 26t-26 11Z"
		/>
	</svg>
);
