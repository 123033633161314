import { createSelector } from 'reselect';

import Country from 'pkg/models/country';
import Group from 'pkg/models/group';

import { activeAccountUserIds } from 'pkg/selectors/app';
import { findAllByGroup } from 'pkg/selectors/users';

import DateTime, { Granularity } from 'pkg/datetime';
import { primaryColor } from 'pkg/colorconverter';

const emptyGroup = new Group({});
const emptyCountry = new Country({});

export const getGroupEntities = (state) => state.groups.get('entities');

export const getMembershipEntities = (state) => state.memberships.get('items');

export const getUsersByRole = (state, { groupId, role }) => {
	const memberships = getMembershipEntities(state);
	const users = state.users.entities;

	return memberships
		.filter((m) => m.role === role && m.groupId === groupId)
		.map((member) => member.set('user', users.get(member.userId)))
		.filter((m) => m.user !== null);
};

export const getGroup = ({ groups }, { groupId }) =>
	groups.entities.get(groupId) || emptyGroup;

export const find = ({ groups }, groupId) =>
	groups.entities.get(groupId) || emptyGroup;

export const findAllByIds = (state, groupIds) => {
	const groups = getGroupEntities(state);

	return groups.filter((group) => groupIds.includes(group.id));
};

export const getCurrentSeason = ({ groups }, { groupId }) => {
	const group = groups.entities.get(groupId);

	if (!group) {
		return { start: 0, end: 0 };
	}

	let startMonth = group.seasonStartMonth;
	const now = new Date();

	let startYear = now.getUTCFullYear();
	if (now.getUTCMonth() + 1 < startMonth) {
		startYear -= 1;
	}

	const season = {
		start: Math.round(
			Date.UTC(startYear, startMonth - 1, 1, 0, 0, 0, 0) / 1000
		),
		end: Math.round(
			Date.UTC(startYear + 1, startMonth - 1, 1, 0, 0, 0, -1) / 1000
		),
	};

	return season;
};

export const getLastSeason = ({ groups }, { groupId }) => {
	const group = groups.entities.get(groupId);

	if (!group) {
		return { start: 0, end: 0 };
	}

	const d = new Date(getCurrentSeason({ groups }, { groupId }).start * 1000);
	const lastSeasonStart = new DateTime(d)
		.prev(Granularity.year, 1)
		.getUnixTimestamp();
	const lastSeasonEnd = new DateTime(d)
		.prev(Granularity.day, 1)
		.getUnixTimestamp();

	const dates = {
		start: lastSeasonStart,
		end: lastSeasonEnd,
	};

	return dates;
};

export const findGroupCountry = ({ groups, countries }, { groupId }) => {
	const group = groups.entities.get(groupId) || emptyGroup;

	if (group.countryId) {
		return countries.get('items').get(group.countryId);
	}

	return emptyCountry;
};

export const getMembershipsForUser = (state, props) => {
	const memberships = state.memberships.get('items');
	const users = state.users.entities;
	const groups = state.groups.get('entities');
	const userIds = props?.userId ? [props.userId] : activeAccountUserIds(state);

	return memberships
		.filter((membership) => userIds.includes(membership.userId))
		?.toList()
		.map((membership) => {
			const user = users.get(membership.userId);
			const group = groups.get(membership.groupId);

			membership = membership.set('user', user).set('group', group);

			return membership;
		});
};

export const getMemberships = createSelector(
	getMembershipEntities,
	(state) => state.users.entities,
	(state) => state.groups.entities,
	(memberships, users, groups) =>
		memberships.map((membership) =>
			membership
				.set('user', users.get(membership.userId))
				.set('group', groups.get(membership.groupId))
		)
);

export const getMembershipsForGroup = (groupId) =>
	createSelector(getMemberships, (memberships) =>
		memberships.filter((membership) => membership.groupId === groupId)
	);

export const getMembershipsByGroupId = (state, { groupId }) => {
	const memberships = getMemberships(state);

	return memberships.filter((membership) => membership.groupId === groupId);
};

export const findAllMembershipsByGroupId = (state, { groupId }) => {
	const memberships = getMembershipEntities(state)?.toList();
	const groups = state.groups.entities;
	const users = state.users.entities;

	return memberships
		.filter((m) => m.groupId === groupId && m.status === 1 && !m.isInherited)
		.map((m) => {
			const group = groups.get(m.groupId);

			m = m.set('group', group).set('user', users.get(m.userId));

			return m;
		})
		.sort((a, b) =>
			`${a.user?.firstName} ${a.user?.lastName}`.localeCompare(
				`${b.user?.firstName} ${b.user?.lastName}`
			)
		);
};

export const getColorProfileGradient = createSelector(
	({ groups }, { groupId }) => groups.entities.get(groupId),
	(group) => {
		let color = group?.getPrimaryColor();

		if (!color) {
			return null;
		}

		color = primaryColor(color).replace('hsl(', '').replace(')', '');

		let gradientColors = [];
		const hue = color.split(',')[0];
		const saturation = color.split(',')[1];
		const lum = parseInt(color.split(',')[2].replace('%', ''));

		if (lum > 15 && lum < 90) {
			gradientColors.push(`hsl(${hue}, ${saturation}, ${lum + 10}%)`);
			gradientColors.push(`hsl(${hue}, ${saturation}, ${lum}%)`);
			gradientColors.push(`hsl(${hue}, ${saturation}, ${lum - 15}%)`);
		} else if (lum < 15) {
			gradientColors.push(
				`hsl(${hue}, ${saturation}, ${lum + (15 - lum) + 10}%)`
			);
			gradientColors.push(`hsl(${hue}, ${saturation}, ${lum}%)`);
		} else {
			gradientColors.push(`hsl(${hue}, ${saturation}, ${lum}%)`);
			gradientColors.push(
				`hsl(${hue}, ${saturation}, ${lum - (100 - lum) - 15}%)`
			);
		}

		return gradientColors.join(', ');
	}
);

export const getSquadAverageStats = (state, props) => {
	const { stats, groupId } = props;
	const squad = findAllByGroup(state, groupId);

	let averageStats = new Map();

	stats.forEach((stat) => {
		const total = squad
			.toList()
			.reduce(
				(sum, user) => sum + Number.parseInt(user.meta.get(stat) || 0, 10),
				0
			);

		averageStats = averageStats.set(
			stat,
			Number.parseFloat((total / squad.size).toFixed(1))
		);
	});

	return averageStats;
};

const getRoot = (items, groupId) =>
	items.filter((item) => item.get('id') === groupId);

const getBranch = (items, parentId) =>
	items.filter((item) => item.get('parentGroupId') === parentId);

const traverseBranch = (items, branches) => {
	return branches
		.map((branch) => {
			const hasParent = branch.get('parentGroupId') !== null;
			const hasChildren =
				items.find((item) => item.parentGroupId === branch.id) ||
				branch.hasIn(['links', 'show:children']);

			if (hasParent && hasChildren) {
				branch = branch.update('children', {}, () =>
					traverseBranch(items, getBranch(items, branch.get('id')))
				);
			}

			return branch;
		})
		.sortBy((branch) => branch.get('name'));
};

const traverseTree = (items, rootId) => {
	let tree = getRoot(items, rootId);

	const directBranches = getBranch(items, rootId);
	const branches = traverseBranch(items, directBranches);

	tree = tree.setIn([rootId, 'children'], branches);

	return tree;
};

export const getOrganizationTree = (state, { groupId }) => {
	const groupEntities = getGroupEntities(state);

	return traverseTree(groupEntities, groupId);
};
