import { ReactNode } from 'react';

export const symbol_pacemaker: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M249.899-80Q196-80 158-118.071 120-156.143 120-210v-341q-24-23-52-49.5T40-664q0-22.909 16.545-39.455Q73.091-720 96-720q17 0 30 9t24 21q11-12 24.5-21t29.5-9q22.909 0 39.455 16.545Q260-686.909 260-664q0 37-28 63.5T180-551v341q0 29.167 20.382 49.583Q220.765-140 249.882-140 279-140 299.5-160.417 320-180.833 320-210v-475q0-80.925 57.053-137.963Q434.106-880 515.053-880T653-822.963Q710-765.925 710-685v7q72 11 121 66.997Q880-555.005 880-480v200q0 83-58.5 141.5T680-80q-83 0-141.5-58.5T480-280v-200q0-75.005 49-131.003Q578-667 650-678v-7q0-57-39-96t-96-39q-57 0-96 39t-39 96v475q0 53.857-38.101 91.929Q303.798-80 249.899-80Zm429.866-60Q738-140 779-180.833q41-40.834 41-99.167v-200q0-58.333-40.765-99.167-40.764-40.833-99-40.833Q622-620 581-579.167 540-538.333 540-480v200q0 58.333 40.765 99.167 40.764 40.833 99 40.833ZM680-360q-33 0-56.5 23.5T600-280q0 33 23.5 56.5T680-200q33 0 56.5-23.5T760-280q0-33-23.5-56.5T680-360Zm0 80Z"
		/>
	</svg>
);
