import { ReactNode } from 'react';

export const symbol_festival: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M139-80q-20 0-32-16t-7-36q17-66 25-132t10-133q-45-14-70-52.5T40-536v-26q0-16 8.5-28.5T72-608q106-42 198.5-104.5T448-850q7-6 15-9t17-3q9 0 17 3t15 9q85 75 177.5 137.5T888-608q15 5 23.5 17.5T920-562v26q0 47-24.5 86T826-397q2 67 9.5 133.5T860-132q5 20-6.5 36T822-80H139Zm51-516h580q-80-40-151.5-91.5T480-798q-66 60-138.5 110.5T190-596Zm393 145q32 0 52-25.5t20-59.5H510q0 34 20.5 59.5T583-451Zm-205 0q32 0 52-25.5t20-59.5H305q0 34 20.5 59.5T378-451Zm-205 0q32 0 52-25.5t20-59.5H100q0 34 20.5 59.5T173-451Zm-8 311h133q11-65 16-129.5t9-130.5q-14-6-26-16.5T278-441q-17 19-38 31.5T195-392q-3 64-9.5 126.5T165-140Zm195 0h241q-9-63-14-126t-8-127q-31-2-58.5-15.5T477-449q-14 26-40 40t-55 16q-4 64-8.5 127T360-140Zm302 0h134q-14-63-21-125.5T766-392q-23-5-43-17.5T688-441q-10 14-22 24.5T638-400q4 66 10 130.5T662-140Zm126-311q32 0 52-25.5t20-59.5H715q0 34 20.5 59.5T788-451Z"
		/>
	</svg>
);
