import { ReactNode } from 'react';

export const symbol_picture_in_picture_alt: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm0-60h680v-520H140v520Zm0 0v-520 520Zm336-45h275q12.75 0 21.375-8.625T781-295v-197q0-12.75-8.625-21.375T751-522H476q-12.75 0-21.375 8.625T446-492v197q0 12.75 8.625 21.375T476-265Zm30-60v-137h215v137H506Z"
		/>
	</svg>
);
