import { t } from '@transifex/native';
import { useDispatch } from 'react-redux';

import { updateMatchEventUser } from 'pkg/actions/match_events_deprecated';

import StepModal, { Step } from 'components/step-modal';

import SelectableList from 'components/user/SelectableList';

const SelectModal = ({ event, changes, users, onNext, onClose }) => {
	const dispatch = useDispatch();

	const handleSelectPlayer = (userId) =>
		dispatch(updateMatchEventUser(event.id, userId[0]));

	return (
		<StepModal onClose={onClose}>
			<Step
				title={t(`Select player`)}
				prevLabel={t('Cancel')}
				nextLabel={t('Done')}
				canGoNext={!!changes.get('userId')}
				skipBody
				onPrev={onClose}
				onNext={onNext}>
				<SelectableList
					users={users}
					selectedUserIds={[changes.get('userId') || event.userId]}
					onChange={handleSelectPlayer}
				/>
			</Step>
		</StepModal>
	);
};

export default SelectModal;
