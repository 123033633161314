import { ReactNode } from 'react';

export const symbol_slide_library: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M150-160q-28.875 0-49.438-20.562Q80-201.125 80-230v-420q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-650v430h550q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T690-160H150Zm160-160q-28.875 0-49.438-20.562Q240-361.125 240-390v-340q0-28.875 20.562-49.438Q281.125-800 310-800h288q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T598-740H300v360h520v-298q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T880-678v288q0 28.875-20.562 49.438Q838.875-320 810-320H310Zm229.697-169Q523-489 508-494.75T481-512q-4-5-4-9t5-6q11-3 17-12t6-20q0-21.667 15.441-36.833Q535.882-611 557.941-611 580-611 595-595.758q15 15.242 15 37.016Q610-530 589.349-509.5 568.697-489 539.697-489ZM638-624l-15-15q-8-8-8-21t8-21l144-142q8-8 21-8t21 8l14 14q9 9 9 21t-9 21L680-624q-8 8-21 8t-21-8Z"
		/>
	</svg>
);
