import { t } from '@transifex/native';
import { useState } from 'react';
import styled from 'styled-components';

import * as palette from 'pkg/config/palette';

import rgba from 'pkg/rgba';

import Icon from 'components/icon';
import Avatar from 'components/avatar';

import * as iconStyles from 'components/icon/styles.css';

const Outer = styled.div`
	grid-area: users;
	width: 100%;
	min-width: 0;
	height: auto;
	min-height: 0;
	position: relative;
	background: rgb(57, 144, 73);
	padding: 1.3rem 1.5rem;
	-webkit-overflow-scrolling: auto;
	overflow: auto;

	@media all and (orientation: portrait) {
		display: none;
	}

	h3 {
		color: ${palette.white};
		font-weight: var(--font-weight-semibold);
		font-size: var(--font-size-base);
		margin-bottom: 1.2rem;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.${iconStyles.icon} {
			cursor: pointer;
			font-size: 0.75rem;
			transform: scale(2);
		}
	}

	ul {
		display: grid;
		grid-auto-flow: row;
		grid-gap: 8px;
	}
`;

const Name = styled.div`
	font-weight: var(--font-weight-semibold);
	font-size: var(--font-size-sm);
	grid-area: name;
`;

const Position = styled.div`
	font-weight: var(--font-weight-normal);
	font-size: 0.7rem;
	grid-area: position;
	opacity: 0.65;
`;

const ListItem = styled.li`
	display: grid;
	grid-template-areas:
		'avatar name icon'
		'avatar position icon';
	grid-template-columns: 30px 1fr auto;
	align-items: center;
	align-content: center;
	grid-column-gap: 10px;
	z-index: 1;
	user-select: none;
	border-radius: var(--radius-5);
	box-shadow: inset 0 0 0 1px ${rgba(palette.white, 0.3)};
	padding: 0.75rem 0.8rem;
	color: ${palette.white};

	&[data-scores='null'] {
		grid-template-areas:
			'avatar name icon'
			'avatar name icon';

		${Position} {
			display: none;
		}
	}

	@media (hover: hover) {
		&:hover {
			background-color: ${rgba(palette.white, 0.1)};
			box-shadow: inset 0 0 0 2px ${rgba(palette.white, 0.3)};
		}
	}

	.${iconStyles.icon} {
		font-size: 20px;
		color: ${palette.white};
	}

	&:not(.locked) {
		cursor: grab;
	}

	.list-item__content {
		font-size: var(--font-size-base);
	}

	${Avatar} {
		grid-area: avatar;
	}

	.${iconStyles.icon} {
		grid-area: icon;
	}

	&.locked {
		opacity: 0.6;
	}
`;

const userProfileUrl = (groupId, userId) =>
	`group/${groupId}/user/profile/${userId}`;

const Users = ({ users, className }) => {
	const [lockedUsers, setLockedUsers] = useState([]);

	const toggleUserLock = (e) => {
		const userId = Number.parseInt(e.currentTarget.dataset.id, 10);

		let users = [...lockedUsers];

		if (users.includes(userId)) {
			users.splice(users.indexOf(userId), 1);
		} else {
			users.push(userId);
		}

		setLockedUsers(users);
	};

	const toggleAll = () => {
		if (lockedUsers.length === users.length) {
			setLockedUsers([]);
		} else {
			setLockedUsers(users.map((u) => u.userId));
		}
	};

	return (
		<Outer
			as="aside"
			className={`remove-player-drop-area ${className}`}
			data-drop-message={t(`Drop here to remove`)}>
			<h3>
				{t(`Pick a player`)}{' '}
				<Icon
					className="toggle-lock-benched-players"
					onClick={toggleAll}
					name={`${lockedUsers.length === users.length ? 'lock' : 'lock-open'}`}
				/>
			</h3>

			<ul className="user-list" id="formation-user-list">
				{users.map((groupUser) => (
					<ListItem
						key={groupUser.id}
						className={`list-item--align-center list-player ${
							lockedUsers.includes(groupUser.userId) && 'locked'
						}`}
						data-id={groupUser.user.id}
						data-scores={JSON.stringify(
							groupUser.lastRating.positionScores?.map((position) => ({
								id: position.positionId,
								slug: position.position.slug,
								name: position.position.name,
								long_name: position.position.longName,
								average: position.value,
								ma_average: position.maturityAdjustedValue,
								is_chosen_position:
									position.positionId ===
									groupUser.lastRating.suggestedPositionId,
							})) || null
						)}
						data-image={groupUser.user.getProfileImageUrl()}
						data-first-name={groupUser.user.firstName}
						data-last-name={groupUser.user.lastName}
						data-profile-url={userProfileUrl(
							groupUser.groupId,
							groupUser.user.id
						)}>
						<Avatar user={groupUser.user} />

						<Name>
							{`${groupUser.user.firstName.substring(0, 1)}. ${
								groupUser.user.lastName
							}`}
						</Name>

						<Position>
							{
								groupUser.lastRating?.positionScores?.filter(
									(p) =>
										p.positionId === groupUser.lastRating.suggestedPositionId
								)[0].position.longName
							}
						</Position>

						<Icon
							name={
								lockedUsers.includes(groupUser.userId) ? 'lock' : 'lock-open'
							}
							onClick={toggleUserLock}
							data-id={groupUser.userId}
						/>
					</ListItem>
				))}
			</ul>
		</Outer>
	);
};

export default Users;
