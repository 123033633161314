import { ReactNode } from 'react';

export const symbol_signal_cellular_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M224-140h510L479-395 224-140Zm-72 60q-20 0-27.5-18.5T131-131l306-306-307-307q-9-9-9-21.5t9-21.5q9-9 21.5-9t21.5 9L867-93q9 9 9 21t-9 21q-9 9-21.5 9T824-51l-30-29H152Zm728-728v570q0 15-9.5 22.5T850-208q-11 0-20.5-7.5T820-238v-498L586-502q-9 9-21 9t-21-9q-9-9-9-21t9-21l285-285q14-14 32.5-6.5T880-808ZM693-354Zm-86 86Z"
		/>
	</svg>
);
