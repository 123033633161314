import { t } from '@transifex/native';
import { Fragment } from 'react';

import * as endpoints from 'pkg/api/endpoints/auto';
import { useCollection } from 'pkg/api/use_collection';
import * as models from 'pkg/api/models';
import * as routes from 'pkg/router/routes';
import { useCurrentOrganization } from 'pkg/identity';

import SubscriptionItem from 'routes/organization/contacts/single/list/subscription-item';

import MissingEntities from 'components/missing-entities';
import CardAnatomy, * as Card from 'components/Card';
import SectionTitle from 'components/SectionTitle';
import Icon from 'components/icon';

import Column from 'components/layout/column';

import * as Table from 'design/table';
import Button from 'design/button';

interface SubscriptionProps {
	user: models.user.User;
}

function Subscriptions({ user }: SubscriptionProps): JSX.Element {
	const org = useCurrentOrganization();

	const { records: subscriptions } =
		useCollection<models.subscription.Subscription>(
			endpoints.Subscriptions.Index(),
			{
				queryParams: new URLSearchParams({
					group_id: org.id.toString(),
					associated_user_id: user.id.toString(),
				}),
			}
		);

	const tableColumns: Table.HeaderColumn[] = [
		{ content: t('Title'), width: '180px', align: 'left' },
		{ content: t('Amount'), align: 'left' },
		{ content: t('Status'), width: 'max-content', align: 'left' },
		{ content: t('Billed to'), align: 'left' },
		{ content: t('Products for'), align: 'left' },
		{ content: t('Created'), align: 'left' },
		{ content: '', width: 'max-content' },
	];

	const content = !subscriptions.length ? (
		<CardAnatomy $noBorder>
			<Card.Body>
				<MissingEntities centered>
					<Icon name="sync" />
					<p>{t('No subscriptions')}</p>
				</MissingEntities>
			</Card.Body>
		</CardAnatomy>
	) : (
		<Fragment>
			<Card.Base>
				<Table.Table columns={tableColumns}>
					{subscriptions.map((subscription) => (
						<SubscriptionItem
							key={subscription.id}
							subscription={subscription}
							userId={user.id}
						/>
					))}
				</Table.Table>
			</Card.Base>
		</Fragment>
	);

	return (
		<Column>
			<SectionTitle icon="sync">
				{t('Subscriptions')}
				{subscriptions.length > 0 && (
					<Button
						icon="arrow_forward"
						iconPosition="right"
						inline
						noPadding
						href={routes.Management.Contact.Show(
							org.id,
							user.id,
							'subscriptions'
						)}>
						{t('View all')}
					</Button>
				)}
			</SectionTitle>
			{content}
		</Column>
	);
}

export default Subscriptions;
