import { ReactNode } from 'react';

export const symbol_language_us_dvorak: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M220-340h150v-280H220v280Zm-30 60q-12.75 0-21.375-8.625T160-310v-340q0-12.75 8.625-21.375T190-680h180q24.75 0 42.375 17.625T430-620v280q0 24.75-17.625 42.375T370-280H190Zm466.105 0q-10.105 0-18.022-6.058Q630.167-292.115 627-301L507-643q-5-14 3.352-25.5t23.537-11.5q9.111 0 16.611 5.481T561-661l107 300 106-300q3-8.038 10.5-13.519T801-680q14 0 23 12t4 26L707-301q-3.222 8.885-11.278 14.942Q687.667-280 678-280h-21.895Z"
		/>
	</svg>
);
