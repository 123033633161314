import { ReactNode } from 'react';

export const symbol_usb_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-80q-32 0-52-20t-20-52q0-22 11-40t31-29v-111H302q-24 0-42-18t-18-42v-109q-20-9-31-26.695T200-568q0-23 11-40.5t29-25.5L82-792q-8-8.25-8-20.625T83.053-834q9.052-9 21.5-9Q117-843 126-834l709 709q8.25 8.25 8.625 20.625T834.947-83q-9.052 9-21.5 9Q801-74 792-83L542-332h-32v111q19.95 10.652 30.975 29.826Q552-172 552-152q0 32-20 52t-52 20Zm280-545v114q0 6-4.5 10.5T745-496h-27v96q0 15-9.318 22.5t-20.5 7.5Q677-370 667.5-377.683 658-385.366 658-400v-96h-27q-6 0-10.5-4.5T616-511v-114q0-6 4.5-10.5T631-640h114q6 0 10.5 4.5T760-625ZM302-392h148v-32L338-536q-5 11-14 20.5T302-501v109Zm148-340h-50q-8.5 0-12.75-8t.75-16l80-109q2.276-2.4 12.138-6 3.793 0 6.828 1.5Q490-868 492-865l80 109q6 8 2 16t-14 8h-50v124q0 15-9.318 22.5t-20.5 7.5Q469-578 459.5-585.683 450-593.366 450-608v-124Z"
		/>
	</svg>
);
