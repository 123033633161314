import { useDispatch, useSelector, batch } from 'react-redux';
import { t } from '@transifex/native';

import {
	updateMatchEventUser,
	updateMatchEventSubstitutionUser,
} from 'pkg/actions/match_events_deprecated';

import * as selectors from 'pkg/selectors';

import PosSelection from 'containers/match/edit/event/modals/PosSelection';

import StepModal, { Step } from 'components/step-modal';

import SelectableList from 'components/user/SelectableList';

const SubModal = ({
	event,
	changes,
	users,
	mode,
	onNext,
	onClose,
	onCancel,
}) => {
	const dispatch = useDispatch();

	const leadingEvent = useSelector((state) =>
		selectors.matchEvents.find(state, event.leadingMatchEventId)
	);

	const handleSelectSubInPlayer = (userId) => {
		mode.get('newEntity')
			? dispatch(updateMatchEventSubstitutionUser(event.id, userId[0]))
			: dispatch(updateMatchEventUser(event.id, userId[0]));
	};

	const handleSelectSubOutPlayer = (userId) => {
		mode.get('newEntity')
			? dispatch(updateMatchEventUser(event.id, userId[0]))
			: batch(() => {
					dispatch(updateMatchEventSubstitutionUser(event.id, userId[0]));
					dispatch(updateMatchEventUser(leadingEvent.id, userId[0]));
				});
	};

	const getPlayerOutId = () => {
		if (mode.get('newEntity')) {
			return changes.get('userId') || event.userId;
		}

		return changes.get('substitutionUserId') || leadingEvent.userId;
	};

	const getPlayerInId = () => {
		if (mode.get('newEntity')) {
			return changes.get('substitutionUserId') || event.substitutionUserId;
		}

		return changes.get('userId') || event.userId;
	};

	return (
		<StepModal onClose={onClose}>
			<Step
				title={t(`Player out`)}
				prevLabel={t('Cancel')}
				nextLabel={t('Next')}
				canGoNext={!!getPlayerOutId()}
				skipBody
				onPrev={onCancel}>
				<SelectableList
					users={users}
					selectedUserIds={[getPlayerOutId()]}
					onChange={handleSelectSubOutPlayer}
				/>
			</Step>

			<Step
				title={t(`Player in`)}
				prevLabel={t('Cancel')}
				nextLabel={t('Next')}
				canGoNext={!!getPlayerInId()}
				skipBody
				onPrev={onCancel}>
				<SelectableList
					users={users.filter((user) => user.id !== getPlayerOutId())}
					selectedUserIds={[getPlayerInId()]}
					onChange={handleSelectSubInPlayer}
				/>
			</Step>

			<Step
				title={t(`Select position`)}
				prevLabel={t('Cancel')}
				nextLabel={t('Done')}
				canGoNext={!!changes.get('positionId') || !!event.positionId}
				skipBody
				onNext={onNext}
				onPrev={onCancel}>
				<PosSelection
					event={event}
					changes={changes}
					eventUser={users.find((user) => user.id === getPlayerInId())}
				/>
			</Step>
		</StepModal>
	);
};

export default SubModal;
