import { ReactNode } from 'react';

export const symbol_person_4: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-440q-66 0-113-47t-47-113v-140q0-25 17.5-42.5T380-800q15 0 28.5 7t21.5 20q8-13 21.5-20t28.5-7q15 0 28.5 7t21.5 20q8-13 21.5-20t28.5-7q25 0 42.5 17.5T640-740v140q0 66-47 113t-113 47Zm0-60q42 0 71-29t29-71v-120H380v120q0 42 29 71t71 29ZM160-180v-34q0-38 19-65t49-41q67-30 128.5-45T480-380q62 0 123 15.5t127.921 44.694q31.301 14.126 50.19 40.966Q800-252 800-214v34q0 24.75-17.625 42.375T740-120H220q-24.75 0-42.375-17.625T160-180Zm60 0h520v-34q0-16-9.5-30.5T707-266q-64-31-117-42.5T480-320q-57 0-111 11.5T252-266q-14 7-23 21.5t-9 30.5v34Zm260 0Zm0-540Z"
		/>
	</svg>
);
