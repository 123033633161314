import { ReactNode } from 'react';

export const symbol_trending_down: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M780-300 539-541 414-416q-16.934 17-41.967 17Q347-399 330-416L102-644q-9-9-9-21.5t9-21.5q9-9 21.5-9t21.5 9l226 227 125-125q16.934-17 41.967-17Q563-602 580-585l241 241v-94q0-12.325 9-20.663Q839-467 851.228-467q12.228 0 20.5 8.337Q880-450.325 880-438v168q0 12.75-8.625 21.375T850-240H683q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T683-300h97Z"
		/>
	</svg>
);
