import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import * as sdk from 'pkg/core/sdk';

import GroupLinkFolder from 'routes/group/settings/tabs/group_links/GroupLinkFolder';

import * as Sortable from 'components/dnd/sortable';

import * as css from './styles.css';

interface PopulatedIndexProps {
	groupId: number;
	folders: models.groupLinkFolder.GroupLinkFolder[];
	refresh: () => void;
}

export default function PopulatedIndex({
	groupId,
	folders,
	refresh,
}: PopulatedIndexProps): JSX.Element {
	const theme: Sortable.Theme = {
		container: css.container,
		activeContainer: css.active,
		item: css.item,
		activeItem: css.active,
		siblingItem: css.sibling,
		overlayItem: css.overlay,
	};

	const handleChange = async (
		folders: models.groupLinkFolder.GroupLinkFolder[]
	) => {
		await Promise.all(
			folders.map((folder: models.groupLinkFolder.GroupLinkFolder, i) => {
				folder.sortOrder = i + 1;
				return sdk.patch(
					endpoints.GroupLinksFolder.UpdateFolder(folder.id),
					{},
					folder
				);
			})
		);
	};

	const renderFolder = (folder: models.groupLinkFolder.GroupLinkFolder) => (
		<GroupLinkFolder groupId={groupId} refresh={refresh} folder={folder} />
	);

	return (
		<Sortable.Container
			items={folders}
			renderWith={renderFolder}
			theme={theme}
			onChange={handleChange}
		/>
	);
}
