// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wmutaYip_3w2zYtGWCJH {
	padding: var(--spacing-5);
	border-radius: var(--radius-5);
	position: relative;
	overflow: hidden;
	color: var(--accentColor);
	background: linear-gradient(135deg, var(--colorProfileGradient));
	-webkit-box-shadow: var(--box-shadow-card-hover), inset hsla(0, 0%, 0%, 0.13) 0 0 0 1px;
	        box-shadow: var(--box-shadow-card-hover), inset hsla(0, 0%, 0%, 0.13) 0 0 0 1px;
}

.wmutaYip_3w2zYtGWCJH[data-is-branded='false'] {
		background: linear-gradient(135deg, var(--palette-white), var(--palette-blue-300));
	}

.kG_eezXCrHdahZ3jTVp_ {
	position: absolute;
	width: 210px;
	height: 210px;
	right: -35px;
	top: -35px;
	-webkit-transform: rotate(10deg);
	        transform: rotate(10deg);
	-webkit-filter: grayscale(1);
	        filter: grayscale(1);
	opacity: 0.07;
	-webkit-user-select: none;
	   -moz-user-select: none;
	            -ms-user-select: none;
	        user-select: none;
	pointer-events: none;
}

.kG_eezXCrHdahZ3jTVp_ img {
		width: 100%;
		height: 100%;
		opacity: 0.5;
	}

.sMG3H8p47gMOjBMtUVF_ {
	font-size: var(--font-size-sm);
}
`, "",{"version":3,"sources":["webpack://./components/group/join-widget/styles.css"],"names":[],"mappings":"AAAA;CACC,yBAAyB;CACzB,8BAA8B;CAC9B,kBAAkB;CAClB,gBAAgB;CAChB,yBAAyB;CACzB,gEAAgE;CAChE,uFAAgF;SAAhF,+EAAgF;AAKjF;;AAHC;EACC,kFAAkF;CACnF;;AAGD;CACC,kBAAkB;CAClB,YAAY;CACZ,aAAa;CACb,YAAY;CACZ,UAAU;CACV,gCAAwB;SAAxB,wBAAwB;CACxB,4BAAoB;SAApB,oBAAoB;CACpB,aAAa;CACb,yBAAiB;IAAjB,sBAAiB;aAAjB,qBAAiB;SAAjB,iBAAiB;CACjB,oBAAoB;AAOrB;;AALC;EACC,WAAW;EACX,YAAY;EACZ,YAAY;CACb;;AAGD;CACC,8BAA8B;AAC/B","sourcesContent":[".wrapper {\n\tpadding: var(--spacing-5);\n\tborder-radius: var(--radius-5);\n\tposition: relative;\n\toverflow: hidden;\n\tcolor: var(--accentColor);\n\tbackground: linear-gradient(135deg, var(--colorProfileGradient));\n\tbox-shadow: var(--box-shadow-card-hover), inset hsla(0deg 0% 0% / 13%) 0 0 0 1px;\n\n\t&[data-is-branded='false'] {\n\t\tbackground: linear-gradient(135deg, var(--palette-white), var(--palette-blue-300));\n\t}\n}\n\n.backdrop {\n\tposition: absolute;\n\twidth: 210px;\n\theight: 210px;\n\tright: -35px;\n\ttop: -35px;\n\ttransform: rotate(10deg);\n\tfilter: grayscale(1);\n\topacity: 0.07;\n\tuser-select: none;\n\tpointer-events: none;\n\n\t& img {\n\t\twidth: 100%;\n\t\theight: 100%;\n\t\topacity: 0.5;\n\t}\n}\n\n.heading {\n\tfont-size: var(--font-size-sm);\n}\n"],"sourceRoot":""}]);
// Exports
export var wrapper = `wmutaYip_3w2zYtGWCJH`;
export var backdrop = `kG_eezXCrHdahZ3jTVp_`;
export var heading = `sMG3H8p47gMOjBMtUVF_`;
export default ___CSS_LOADER_EXPORT___;
