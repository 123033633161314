import * as sdk from 'pkg/core/sdk';
import * as endpoints from 'pkg/api/endpoints/auto';

export interface IPayout {
	amount: number;
	currency: string;
	status: string;
	statusTranslated: string;
	destination: string;
	createdAt: number;
}

export const fetchPayouts = (groupId: number): Promise<Response> =>
	sdk.get(endpoints.Organizations.ListPayouts(groupId));
