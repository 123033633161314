import { t } from '@transifex/native';
import { ChangeEvent, useState } from 'react';

import * as models from 'pkg/api/models';
import * as actions from 'pkg/actions';

import { useEventContext } from 'routes/event/single';

import * as StepModal from 'components/step-modal';

import * as Input from 'components/form/inputs';

interface EventUserCommentModalProps {
	eventUser: models.eventUser.EventUser;

	closeModal: () => void;
}

export default function EventUserCommentModal({
	eventUser,
	closeModal,
}: EventUserCommentModalProps) {
	const eventCtx = useEventContext();
	const [comment, setComment] = useState(eventUser.comment);

	let stepModalTitle = t('Add comment');
	const nextLabel = t('Save');

	if (eventUser.comment) {
		stepModalTitle = t('Edit comment');
	}

	const handleCommentChange = (e: ChangeEvent<HTMLInputElement>) =>
		setComment(e.currentTarget.value);

	const handleUpdateComment = async () => {
		const req = await actions.eventUsers.patchEventUsers(eventUser.eventId, {
			users: [
				{
					userId: eventUser.userId,
					comment,
					attendanceStatus: eventUser.attendanceStatus,
					status: eventUser.status,
				},
			],
		});

		if (req) {
			const updatedEventUser = {
				...eventUser,
				comment,
			} as models.eventUser.EventUser;

			if (eventCtx) {
				eventCtx.eventEndpointResponse.replaceInRecord(
					eventCtx.eventEndpointResponse.record,
					'users',
					updatedEventUser
				);
			}
		}
	};

	return (
		<StepModal.Base thin onClose={closeModal}>
			<StepModal.Step
				title={stepModalTitle}
				prevLabel={t('Cancel')}
				nextLabel={nextLabel}
				onNext={handleUpdateComment}>
				<Input.Group>
					<Input.Field
						defaultValue={comment}
						onChange={handleCommentChange}
						placeholder={t('Comment (optional)')}
					/>
				</Input.Group>
			</StepModal.Step>
		</StepModal.Base>
	);
}
