import { ReactNode } from 'react';

export const symbol_hangout_video_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M400-639h138q12.75 0 21.375 8.625T568-609v138L400-639Zm145 145 143-113q11-9 23.5-2.5T724-589v274L545-494Zm320 317q9-8 16-20t7-32v-507q0-24-18-42t-42-18H243l60 60h525v522l37 37ZM161-796l60 60h-69v507h491L543-329H287q-12.75 0-21.375-8.625T257-359v-256L60-812q-9-9-9.5-21.467Q50-845.933 59-855q9-9 21.5-9t21.5 9L865-92q9 9 9 21t-9 21q-9 9-21.5 9T822-50L702-169H152q-24 0-42-18t-18-42v-507q0-24 18-42t42-18h9Zm406 324Zm-168-1Z"
		/>
	</svg>
);
