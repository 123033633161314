import { ReactNode } from 'react';

export const symbol_sledding: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M765-40q-18 0-32-3t-30-8L59-260q-8-2-11.5-9.5T46-285q2-8 9.5-11.5T71-298l151 49 27-85-150-49q-8-2-11.5-9.5T86-408q2-8 9.5-11.5T111-421l59 19v-151q0-9 5-16.5t13-11.5l195-84q7-3 13.5-4t14.5-1q32 0 48.5 26t6.5 55l-47 145 103-16q23-4 44 7.5t31 32.5l85 184 57 19q8 2 11.5 9.5T752-192q-2 8-9.5 11.5T727-179l-118-38-28 84 135 44q13 5 25 7t24 2q44 0 79.5-35.5T880-195q0-34-15.5-62T818-303q-7-4-10-11.5t0-15.5q3-8 10-12t14 0q42 20 65 58.5t23 88.5q0 62-46.5 108.5T765-40ZM543-145l28-85-285-92-27 85 284 92Zm61-116-60-130-203 44 263 86Zm-305-99q-11-14-15-33t2-37l47-148-103 44v151l69 23Zm181-354q-30 0-51.5-21.5T407-787q0-30 21.5-51.5T480-860q30 0 51.5 21.5T553-787q0 30-21.5 51.5T480-714Z"
		/>
	</svg>
);
