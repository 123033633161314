import { ReactNode } from 'react';

export const symbol_immunology: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M521-80q-60 0-150-16.5T227-154q-30-23-63-72t-60.5-107Q76-391 58-449t-18-99q0-85 58-138.5T222-783q60-39 133-68t151-29q78 0 141.5 30T774-777q15 10 39 30t47.5 50q23.5 30 41 70.5T920-534q2 74-30.5 154.5t-88 147Q746-166 673-123T521-80Zm-1-60q66 0 127.5-38T757-274q48-58 76.5-127T860-532q-2-75-44.5-123.5T740-727q-54-36-110.5-64.5T506-820q-69 0-136 27t-121 63q-43 29-96.5 75T99-552q0 34 16.5 86t41 104.5q24.5 52.5 53 97T262-202q38 29 116.5 45.5T520-140ZM414-250q49 0 81.5-35t32.5-79q0-20-8.5-39.5T493-440q-23-21-38-46t-22-56q-9-40-40-64t-69-24q-45 0-79.5 34.5T210-516q0 37 15.5 83t43 86.5q27.5 40.5 65 68.5t80.5 28Zm0-60q-28 0-54-23.5t-46-56Q294-422 282-457t-12-59q0-20 17-37t37-17q15 0 31 10.5t21 31.5q10 41 27.5 72.5T453-395q8 7 11.5 15.5T468-362q0 20-15 36t-39 16Zm236-140q13 0 21.5-9t8.5-21v-26l24 12q11 5 22.5 2.5T745-505q7-11 4.5-23.5T736-546l-28-14 28-14q11-5 13-17.5t-4-23.5q-6-11-18-13t-23 3l-24 12v-27q0-13-8.5-21.5T650-670q-12 0-21 8.5t-9 21.5v28l-24-13q-11-6-22.5-3T555-614q-6 11-3.5 23.5T565-573l26 11-24 16q-11 7-14.5 18.5T555-505q7 11 18.5 14t22.5-3l24-13v27q0 12 9 21t21 9Zm-168-30Z"
		/>
	</svg>
);
