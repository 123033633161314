import { ReactNode } from 'react';

export const symbol_settings_brightness: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m426-338 43 44q4.636 5 10.818 5Q486-289 491-294l44-44h79q6 0 10.5-4.5T629-353v-81l38.923-38.923Q673-478 673-484.182T668-495l-39-38v-80q0-6-4.5-10.5T614-628h-80l-43-43q-4.636-5-10.818-5Q474-676 469-671l-43 43h-77q-6 0-10.5 4.5T334-613v80l-38.923 38.923Q290-489 290-482.818T295-472l39 39v80q0 6 4.5 10.5T349-338h77Zm54-25v-238q49 0 83.5 34.708Q598-531.583 598-482t-34.417 84.292Q529.167-363 480-363ZM140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm0-60h680v-520H140v520Zm0 0v-520 520Z"
		/>
	</svg>
);
