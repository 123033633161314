import { t } from '@transifex/native';
import { ChangeEvent, FocusEvent, useState } from 'react';

import * as styles from 'pkg/config/styles';

import Link from 'pkg/router/Link';
import * as api from 'pkg/api';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as actions from 'pkg/actions';
import * as ls from 'pkg/local_storage';
import { OnboardingInfoResponse } from 'pkg/api/endpoints/onboarding_info';

import { useOnboardingContext } from 'routes/public/onboarding';
import { useOnboardingState } from 'routes/public/hooks';
import Button from 'routes/public/styles/Button';
import FooterNote from 'routes/public/styles/FooterNote';
import ThreeSixtyLogo from 'routes/public/styles/logo';
import Title from 'routes/public/styles/Title';
import Field from 'routes/public/styles/inputs/field';
import Form from 'routes/public/styles/forms';

import Column from 'components/layout/column';

export default function GroupCode(): JSX.Element {
	const { goTo } = useOnboardingContext();
	const onboardingState = useOnboardingState();

	const [isSaving, setIsSaving] = useState(false);
	const [error, setError] = useState<string>('');
	const [groupCode, setGroupCode] = useState<string>(
		onboardingState.get<string>('groupCode') || ''
	);

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		setError('');
		setGroupCode(event.target.value.toUpperCase());
	};

	const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
		if (event.target.value === '') {
			setError(t('Group code is required to continue'));
		}
	};

	const handleSave = async () => {
		setIsSaving(true);

		const [request, response] = await api.get<OnboardingInfoResponse>(
			endpoints.Accounts.OnboardingInfo() + `?invite_code=${groupCode}`
		);

		if (!request.ok) {
			actions.flashes.show({
				title: t('Invalid group code'),
				message: t('The group code used is not valid.'),
			});

			setError(t('Invalid group code'));

			setIsSaving(false);
		} else {
			onboardingState.set({
				groupCode,
				group: {
					country: response.country,
					groupName: response.groupName,
					organizationId: response.organizationId,
					organizationName: response.organizationName,
					primaryColor: response.primaryColor,
					profileImageUrl: response.profileImageUrl,
				},
				identityProviders: response.identityProviders,
			});

			ls.setItem(
				ls.LocalStorageKeys.ActiveOrganizationId,
				response.organizationId.toString()
			);

			goTo('group-confirmation');
		}
	};

	return (
		<Column spacing={styles.spacing._8}>
			<ThreeSixtyLogo />

			<Form onSubmit={handleSave}>
				<Column spacing={styles.spacing._8}>
					<Title
						title={t('Enter a group code to continue')}
						description={t(
							'Join a group with a group code that you get from your club.'
						)}
						thinDescription
						center
					/>

					<Column>
						<Field
							name="group-code"
							variant="group-code"
							value={groupCode}
							onChange={handleChange}
							onBlur={handleBlur}
							placeholder={t('Group code')}
							errorMessage={error}
							persistentErrorMessage={!!error}
							validateOnChange
						/>
						<Button
							primary
							label={t('Continue')}
							type="submit"
							busy={isSaving}
						/>
					</Column>

					<FooterNote>
						<Link href="https://help.360player.com/article/149-i-dont-have-a-group-code">
							{t(`I don't have a group code`)}
						</Link>
					</FooterNote>
				</Column>
			</Form>
		</Column>
	);
}
