import { ReactNode } from 'react';

export const symbol_emergency_heat_2: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M243-128q-5 4-10 5.5t-10 1.5q-6 0-11.5-2t-10.5-7q-41-43-61-88.5T120-314q0-36 11-76.5T170-500q26-60 35-92.5t9-59.5q0-38-16.5-70.5T146-788q-5-5-7-10.5t-2-11.5q0-5 1.5-10.5T144-831q5-5 10.5-7.5T166-841q5 0 10.5 2t10.5 7q44 41 65.5 85t21.5 94q0 34-10.5 72.5T226-477q-25 60-35 95t-10 66q0 40 16 76t48 70q4 5 5.5 10t1.5 10q0 6-2 11.5t-7 10.5Zm203-1q-5 4-10.5 6t-10.5 2q-6 0-11-2t-10-7q-41-43-61-89t-20-96q0-36 11-76.5T373-501q26-60 35-92.5t9-59.5q0-38-16.5-71T349-789q-5-5-7-10.5t-2-11.5q0-5 1.5-10t5.5-10q5-5 10.5-7.5T369-841q5 0 10.5 2t10.5 7q44 41 65.5 85t21.5 94q0 34-10 71.5T429-478q-26 61-36 96.5T383-316q0 39 16 74.5t49 70.5q4 5 5.5 10t1.5 10q0 6-2 11.5t-7 10.5Zm203 1q-5 4-10 5.5t-10 1.5q-6 0-11.5-2t-10.5-7q-41-43-61-88.5T526-314q0-36 11-76.5T576-500q26-60 35-92.5t9-59.5q0-38-16.5-70.5T552-788q-5-5-6.5-10t-1.5-11q0-5 1.5-10.5T551-830q5-5 10.5-7t11.5-2q5 0 10 1.5t10 5.5q44 41 65.5 85t21.5 94q0 34-10 71t-38 105q-26 60-36 95.5T586-316q0 39 16 75.5t49 70.5q4 5 5.5 10t1.5 10q0 6-2 11.5t-7 10.5Zm161-401q-12 0-21-9t-9-21q0-13 9-21.5t21-8.5q13 0 21.5 8.5T840-559q0 12-8.5 21t-21.5 9Zm0-120q-13 0-21.5-8.5T780-679v-130q0-13 8.5-21.5T810-839q13 0 21.5 8.5T840-809v130q0 13-8.5 21.5T810-649Z"
		/>
	</svg>
);
