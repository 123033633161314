const objects = [
	'M10.9123 4.14138L5.51531 7.2578C4.8428 7.65398 4.42969 8.37432 4.42969 9.14506V15.3626C4.42969 16.1454 4.84519 16.8629 5.51283 17.2484L10.9096 20.3647L10.911 20.3655C11.5911 20.7535 12.4203 20.7515 13.0877 20.3663L18.4826 17.2511L18.4847 17.2498C19.1572 16.8536 19.5703 16.1333 19.5703 15.3626V9.14506C19.5703 8.36645 19.1582 7.65459 18.4847 7.25781L13.0904 4.1429L13.0891 4.1422C12.409 3.7541 11.5797 3.75611 10.9123 4.14138ZM10.2244 2.94983C11.32 2.31729 12.6718 2.31904 13.773 2.94831L13.7757 2.94985L19.1797 6.07033C20.2683 6.71005 20.9462 7.87015 20.9462 9.14506V15.3626C20.9462 16.6272 20.2693 17.7969 19.1796 18.4373L19.1751 18.4399L13.7757 21.5578C12.68 22.1903 11.3282 22.1886 10.227 21.5593L10.2243 21.5578L4.82493 18.4399C3.72912 17.8073 3.05382 16.6341 3.05382 15.3626V9.14506C3.05382 7.88044 3.73064 6.71069 4.82035 6.07035L4.82486 6.0677L10.2244 2.94983Z',
	'M12 10.9925C12.837 10.9925 13.5155 10.314 13.5155 9.47704C13.5155 8.64005 12.837 7.96153 12 7.96153C11.163 7.96153 10.4845 8.64005 10.4845 9.47704C10.4845 10.314 11.163 10.9925 12 10.9925ZM12 12.2343C13.5228 12.2343 14.7573 10.9998 14.7573 9.47704C14.7573 7.95425 13.5228 6.71978 12 6.71978C10.4772 6.71978 9.24274 7.95425 9.24274 9.47704C9.24274 10.9998 10.4772 12.2343 12 12.2343Z',
	'M7.13474 16.4762C7.13474 14.4095 8.81016 12.7341 10.8769 12.7341H13.1231C15.1899 12.7341 16.8653 14.4095 16.8653 16.4762H15.6235C15.6235 15.0953 14.5041 13.9758 13.1231 13.9758H10.8769C9.49596 13.9758 8.37649 15.0953 8.37649 16.4762H7.13474Z',
].map((d: string) => ({
	type: 'path',
	attributes: {
		fillRule: 'evenodd',
		clipRule: 'evenodd',
		d,
	},
}));

const icon = {
	name: 'clip-author',
	viewbox: '0 0 25 25',
	objects,
};

export default icon;
