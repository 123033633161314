import { Dateable } from 'pkg/api/models/dateable';
import { Record } from 'pkg/api/models/record';
import * as models from 'pkg/api/models';

export enum OrderRowTypes {
	Product = 'product',
	Fee = 'fee',
	Discount = 'discount',
}

export interface OrderRow extends Record, Dateable {
	id: number;
	orderId: number;
	productPriceId: number;
	description?: string;
	quantity: number;
	taxRateId: number;
	validTo: number;
	amount: number;
	taxPercentage: number;
	taxInclusive: boolean;
	discountId?: number;
	type: OrderRowTypes;

	productPrice?: models.productPrice.ProductPrice;
	taxRate?: models.taxRate.TaxRate;
	discount?: models.discount.Discount;
}

export function getTotalOneTimePayments(orderRows: OrderRow[]) {
	let amount = 0;

	orderRows?.forEach((o) => (amount += o.amount));

	return amount;
}

// Used this to sort rows in a order based on type
export const orderRowTypesMap: { [key in OrderRowTypes]: number } = {
	product: 1,
	fee: 2,
	discount: 3,
};

export function findDiscount(orderRows: OrderRow[]) {
	return orderRows?.find((row) => row.type === 'discount')?.discount;
}

export function findDiscountRow(orderRows: OrderRow[]) {
	return orderRows?.find((row) => row.type === 'discount');
}

export function findFee(orderRows: OrderRow[]) {
	return orderRows?.find((row) => row.type === 'fee');
}

export function findProductRows(orderRows: OrderRow[]) {
	return orderRows?.filter((row) => row.type === 'product');
}
