import { t } from '@transifex/native';
import styled, { css } from 'styled-components';

import * as styles from 'pkg/config/styles';

import CardBase, * as Card from 'components/Card';
import Badge from 'components/Badge';
import SectionTitle from 'components/SectionTitle';
import Icon from 'components/icon';

import FieldStyles from 'components/form/FieldStyles';
import Column from 'components/layout/column';

import Button from 'design/button';

const PlaceholderIcon = styled(Icon)`
	width: 100%;
	height: 100%;
	color: var(--palette-gray-400);
`;

const Wrapper = styled.div`
	display: grid;
	grid-template-areas: ${(props) =>
		props.isHome ? css` 'self score opponent'` : css`'opponent score self'`};
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: auto 1fr;
	justify-items: center;
`;

const ScoreWrapper = styled.div`
	grid-area: score;
	width: 100%;
`;

const Team = styled.div`
	grid-area: ${({ opponent }) => (opponent ? 'opponent' : 'self')};
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const TeamName = styled.div`
	max-width: 150px;
	word-break: break-word;
	margin: 0 auto;
	text-align: center;
	font-size: var(--font-size-base);
	line-height: var(--font-line-height-lg);

	@media ${styles.breakpoint.small} {
		max-width: 100px;
		font-size: var(--font-size-sm);
		line-height: var(--font-line-height-sm);
	}
`;

const Score = styled.div`
	display: flex;
	flex-direction: ${(props) => (props.isHome ? 'row' : 'row-reverse')};
	justify-content: space-between;
	align-items: center;

	&:first-child {
		margin-bottom: var(--spacing-5);
	}
`;

const ScoreInput = styled.input`
	${FieldStyles};

	width: 55px;
	height: 55px;
	padding: 0;
	text-align: center;
	font-size: var(--font-size-2xl);
	font-weight: var(--font-weight-bold);
	flex: none;

	-moz-appearance: textfield;
	::-webkit-outer-spin-button,
	::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	@media ${styles.breakpoint.small} {
		width: 43px;
		height: 43px;
		font-size: var(--font-size-xl);
	}
`;

const HalfTimeScore = styled(Score)`
	${ScoreInput} {
		width: 35px;
		height: 35px;
		margin: 0 var(--spacing-6);
		color: var(--palette-gray-500);
		font-size: var(--font-size-base);

		@media ${styles.breakpoint.small} {
			width: 35px;
			height: 35px;
			font-size: var(--font-size-sm);
			margin: 0 var(--spacing-3);
		}
	}
`;

const ScoreDivider = styled.div`
	width: 15px;
	height: 3px;
	background-color: var(--palette-gray-800);
`;

const SmallSubText = styled.span`
	color: var(--palette-gray-500);
	font-size: var(--font-size-sm);
`;

const GroupBadge = styled.div`
	margin-bottom: var(--spacing-3);
	width: 68px;
	height: 68px;

	@media ${styles.breakpoint.small} {
		width: 48px;
		height: 48px;
	}
`;

const EditResult = ({
	match,
	matchOpponent,
	updateMatchProperty,
	createMatchEvents,
	removeEvents,
	updateGoalCountHalfTime,
}) => {
	const handleOnBlur = ({ target }) => {
		const prevValue = match.get(target.name);
		let isAutoSave = false;

		if (!target.value) {
			target.value = 0;
		}

		if (prevValue === Number.parseInt(target.value, 10)) {
			return;
		}

		if (target.name.includes('HalfTime')) {
			const goalsCount = target.name.includes('opponent')
				? match.get('opponentGoalCount')
				: match.get('goalCount');

			if (target.value > goalsCount) {
				target.value = prevValue;
				return;
			}
		} else {
			if (prevValue > target.value) {
				const deletedAmount = removeEvents(
					prevValue - target.value,
					target.name.includes('opponent')
				);

				target.value = prevValue - deletedAmount;

				if (deletedAmount === 0) {
					return;
				}

				isAutoSave = true;
			} else if (target.value > prevValue) {
				createMatchEvents(
					target.value - prevValue,
					target.name === 'opponentGoalCount'
				);
			}

			const goalsCountHalfTime = target.name.includes('opponent')
				? match.get('opponentGoalCountHalfTime')
				: match.get('goalCountHalfTime');

			if (goalsCountHalfTime > target.value) {
				updateGoalCountHalfTime(target.value, target.name.includes('opponent'));
			}
		}

		updateMatchProperty(target.name, target.value, false, isAutoSave);
	};

	const handleOnFocus = ({ target }) => {
		if (Number.parseInt(target.value, 10) === 0) {
			target.value = null;
		}
	};

	const changeSide = () => updateMatchProperty('isHome', !match.isHome);

	return (
		<Column>
			<SectionTitle>
				{t(`Result`)}
				<Button
					inline
					noPadding
					secondary
					onClick={changeSide}
					icon="swap_horiz">
					{t(`Home/Away`)}
				</Button>
			</SectionTitle>
			<CardBase>
				<Card.Body>
					<Wrapper isHome={match.isHome}>
						<Team>
							<GroupBadge>
								<Badge badgeUrl={match.group.profileImageUrl} />
							</GroupBadge>
							<TeamName>{match.group.name}</TeamName>
							<SmallSubText>
								{match.isHome ? t(`Home`) : t(`Away`)}
							</SmallSubText>
						</Team>

						<ScoreWrapper key={`${match.goalCount}-${match.opponentGoalCount}`}>
							<Score isHome={match.isHome}>
								<ScoreInput
									type="number"
									name="goalCount"
									defaultValue={match.goalCount || 0}
									onFocus={handleOnFocus}
									onBlur={handleOnBlur}
								/>
								<ScoreDivider />
								<ScoreInput
									type="number"
									name="opponentGoalCount"
									defaultValue={match.opponentGoalCount || 0}
									onFocus={handleOnFocus}
									onBlur={handleOnBlur}
								/>
							</Score>

							<HalfTimeScore isHome={match.isHome}>
								<ScoreInput
									type="number"
									name="goalCountHalfTime"
									defaultValue={match.goalCountHalfTime || 0}
									onFocus={handleOnFocus}
									onBlur={handleOnBlur}
								/>
								<SmallSubText>{t(`Halftime`)}</SmallSubText>
								<ScoreInput
									type="number"
									name="opponentGoalCountHalfTime"
									defaultValue={match.opponentGoalCountHalfTime || 0}
									onFocus={handleOnFocus}
									onBlur={handleOnBlur}
								/>
							</HalfTimeScore>
						</ScoreWrapper>

						<Team opponent>
							<GroupBadge>
								{matchOpponent.get('imageUrl') ? (
									<Badge badgeUrl={matchOpponent.get('imageUrl')} />
								) : (
									<PlaceholderIcon name="nav-group" />
								)}{' '}
							</GroupBadge>
							<TeamName>{match.opponentName || t(`Opponent`)}</TeamName>
							<SmallSubText>
								{!match.isHome ? t(`Home`) : t(`Away`)}
							</SmallSubText>
						</Team>
					</Wrapper>
				</Card.Body>
			</CardBase>
		</Column>
	);
};

export default EditResult;
