import { ReactNode } from 'react';

export const symbol_devices_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M810-740H308q-15 0-22.5-9.318t-7.5-20.5q0-11.182 7.5-20.682Q293-800 308-800h502q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-740Zm70 90v404q0 15-9.318 22.5t-20.5 7.5Q839-216 829.5-223.683 820-231.366 820-246v-374H580v92q0 15-9.318 22.5t-20.5 7.5Q539-498 529.5-505.683 520-513.366 520-528v-122q0-12.75 8.625-21.375T550-680h300q12.75 0 21.375 8.625T880-650ZM550-160q-12.75 0-21.375-8.625T520-190v-165L220-654v404h195q18.75 0 31.875 13.176 13.125 13.177 13.125 32Q460-186 446.875-173T415-160H125q-18.75 0-31.875-13.176Q80-186.353 80-205.176 80-224 93.125-237T125-250h35v-464l-70-70q-9-9-8.5-21.5T91-827q9-9 21-9t21 9l708 707q9 9 8.5 21.5T840-77q-9 9-21 9t-21-9l-83-83H550Zm150-198Z"
		/>
	</svg>
);
