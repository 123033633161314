import { Record, Map } from 'immutable';
import { schema } from 'normalizr';

import Country from 'pkg/models/country';
import Membership from 'pkg/models/membership';
import { GroupSchema } from 'pkg/models/schemas';

import { sport } from 'pkg/api/models';

export enum Flags {
	ShowOnboarding = 'showOnboarding',
}

export enum Features {
	Advertisements = 'advertisements',
	AggregatedStatistics = 'aggregated-statistics',
	Assessments = 'assessments',
	Calendar = 'calendar',
	Chat = 'chat',
	Forms = 'forms',
	Imports = 'imports',
	IndividualDevelopment = 'individual-development',
	Matches = 'matches',
	Payments = 'payments',
	Posts = 'posts',
	ResourceManagement = 'resource-management',
	Scheduling = 'scheduling',
	TrainingLibrary = 'training-library',
	Video = 'video',
	Websites = 'websites',
}

export enum GroupStatusTypes {
	Unknown = 'unknown',
	Pending = 'pending',
	Active = 'active',
	Banned = 'banned',
	Inactive = 'inactive',
}

interface IGroup {
	id: number;
	parentGroupId: number;
	organizationId: number;
	countryId: number;
	sportType?: sport.SportType;
	externalId: string | number;
	allowInvites: boolean;
	createdAt: number;
	updatedAt: number;
	name: string;
	slug: string;
	inviteCode: string;
	meta: any;
	flags: Flags[];
	features: Features[];
	videoSources?: string[];
	paymentProviderActive: boolean;
	paymentProvider?: string;
	currency: string;
	seasonStartMonth: number;
	profileImageUrl: string;
	primaryColor: string;
	showRatingResults: boolean;
	order: number;
	serviceFeePercent: number;

	// LOK integration fields
	lokOrganizationNumber?: string;
	lokSportId?: number;
	lokFromTime?: number;

	sport: any;
	resolvedUserGroup: { [key: string]: Membership };
	activitySummary: string;
	links?: Map<string, string>;
	children: Map<number, Group>;
	totalChildGroupCount: number;
	totalPlayerCount: number;
	totalAdminCount: number;
	totalLegalGuardianCount: number;
	totalPendingMembersCount: number;
	country?: Country;
	userFields: any[];
	allowedActions: string[];
}

const GroupProps: IGroup = {
	id: null,
	parentGroupId: null,
	organizationId: null,
	externalId: '',
	allowInvites: true,
	countryId: null,
	sportType: null,
	createdAt: null,
	updatedAt: null,
	name: '',
	slug: null,
	flags: [],
	features: [],
	videoSources: [],
	paymentProvider: '',
	paymentProviderActive: false,
	currency: '',
	meta: Map({}),
	inviteCode: '',
	seasonStartMonth: 1,
	profileImageUrl: '',
	primaryColor: '',
	showRatingResults: false,
	order: 0,
	userFields: [],
	serviceFeePercent: 0,
	country: new Country(),

	activitySummary: null,
	resolvedUserGroup: null,
	totalChildGroupCount: 0,
	totalPlayerCount: 0,
	totalAdminCount: 0,
	totalLegalGuardianCount: 0,
	totalPendingMembersCount: 0,

	lokOrganizationNumber: null,
	lokSportId: null,
	lokFromTime: null,

	sport: null,
	links: Map({}),
	children: Map(),
	allowedActions: [],
};

class Group extends Record(GroupProps, 'GroupRecord') implements IGroup {
	constructor(args: any) {
		if (!args) args = {};

		super(args);
	}

	get canShowChildren(): boolean {
		return this.hasIn(['links', 'show:children']);
	}

	get hasChildren(): boolean {
		return this.get('children')?.size > 0;
	}

	isOrganization(): boolean {
		return this.get('organizationId') === null;
	}

	get totalUserCount(): number {
		return this.totalPlayerCount + this.totalAdminCount;
	}

	getOrganizationID(): number {
		return this.isOrganization() ? this.id : this.organizationId;
	}

	isLOKActive(): boolean {
		if (!this.isOrganization()) {
			return false;
		}

		return !!this.lokOrganizationNumber;
	}

	getPrimaryColor(): string {
		const color = this.primaryColor;

		if (color && color.indexOf(':') !== -1) {
			return color.split(':').pop();
		}

		return null;
	}

	hasFlag(flag: Flags): boolean {
		return this.flags.includes(flag);
	}

	hasFeature(feature: Features): boolean {
		return this.features.includes(feature);
	}

	hasFeatures(...features: Features[]): boolean {
		return (
			features.length > 0 &&
			features.filter((feature: Features) => this.features.includes(feature))
				.length === features.length
		);
	}

	hasAnyFeature(...features: Features[]): boolean {
		return !!features.filter((feature: Features) =>
			this.features.includes(feature)
		).length;
	}

	hasVideoSource(videoSource: string): boolean {
		return (
			this.hasFeature(Features.Video) && this.videoSources.includes(videoSource)
		);
	}

	static normalizr(): schema.Entity {
		return GroupSchema;
	}
}

export default Group;
