import { ReactNode } from 'react';

export const symbol_box_add: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-674h390-22 21-389Zm460 0h140-140Zm-443-60h565l-36.409-46H233l-36 46Zm183 305 100-50 100 50v-245H380v245ZM180-120q-24.75 0-42.375-17.625T120-180v-529q0-9.882 3-19.059 3-9.176 9-16.941l52-71q8-11 20.941-17.5Q217.882-840 232-840h495q14.118 0 27.059 6.5T775-816l53 71q6 7.765 9 16.941 3 9.177 3 19.059v205q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T780-504v-170H640v224q-23 16-40.5 37T570-367l-90-45-117 58q-15 8-29-.75T320-381v-293H180v494h374q12 0 21 9t9 21.364Q584-138 576-129t-22 9H180Zm581.825-3Q749-123 740.5-131.625T732-153v-90h-90q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T642-303h90v-90q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T792-393v90h90q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T882-243h-90v90q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM180-674h390-22 21-389Z"
		/>
	</svg>
);
