import styled from 'styled-components';

import * as breakpoints from 'pkg/config/breakpoints';
import * as palette from 'pkg/config/palette';

import Card from 'routes/dashboard/components/Card';

import Placeholder from 'components/Placeholder';

import Column from 'components/layout/column';
import { ParagraphRow } from 'components/loaders/skeleton/placeholders/ParagraphRow';

const Wrapper = styled.div`
	margin-right: 1rem;
	flex: 0 0 90%;
	width: 90%;
	min-width: 0;

	@media (hover: hover) {
		scroll-snap-align: none;
	}

	@media ${breakpoints.large}, ${breakpoints.nolimit} {
		flex: 0 0 320px;
		width: 320px;
	}

	&:last-child {
		margin-right: 0;
	}
`;

const PlacerholderCard = styled(Card)`
	background-color: ${palette.white};
	padding: 1.2rem 1rem;
	flex: 0 0 100%;
	width: 100%;
`;

export default class DashboardPlaceholder extends Placeholder {
	getPlaceholderItem(key) {
		return (
			<Wrapper key={key}>
				<PlacerholderCard>
					<Column>
						<ParagraphRow height={15} percentageWidth={70} />
						<ParagraphRow height={10} percentageWidth={40} />
						<ParagraphRow height={10} percentageWidth={55} />
					</Column>
				</PlacerholderCard>
			</Wrapper>
		);
	}
}
