import { useEffect, Fragment } from 'react';
import { t } from '@transifex/native';
import { T } from '@transifex/react';

import spacing from 'pkg/config/spacing';

import * as routes from 'pkg/router/routes';
import { useEndpoint } from 'pkg/api/use_endpoint';
import { useCollection } from 'pkg/api/use_collection';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import Badge from 'components/Badge';
import Label from 'components/label';
import { FormattedContent } from 'components/formatted-content';

import Row from 'components/layout/row';
import AvailableSpots from 'components/forms/available-spots';
import { Spinner } from 'components/loaders/spinner';
import AvatarStack from 'components/avatar/avatar-stack';
import { useTemplateContext } from 'components/layout/page-templates/context';

import Button from 'design/button';

import * as css from './styles.css';

interface Props {
	guid: string;
}

export interface ShowFormResponse {
	form: models.form.Form;
	paymentProviderSettings: models.providerSettings.ProviderSettings;
}

const PreviewForm = ({ guid }: Props) => {
	const { setPageTitle } = useTemplateContext();
	const { record: response, isLoading: isFormLoading } =
		useEndpoint<ShowFormResponse>(endpoints.Forms.PublicShow(guid));

	const { records: submissions } =
		useCollection<models.formSubmission.FormSubmission>(
			endpoints.Forms.UserSubmissions(guid)
		);

	const submissionsString = submissions
		?.map((fs) => fs.submittedForUser.firstName)
		.join(', ');

	useEffect(() => {
		if (response?.form?.title) {
			setPageTitle(response.form.title);
		}
	}, [isFormLoading]);

	if (isFormLoading) {
		return <Spinner />;
	}

	const groupPrimaryColor = models.group.getPrimaryColorStylesheetString(
		response.form.group
	);

	return (
		<div className={css.wrapper}>
			<div
				className={css.cover}
				style={{
					backgroundColor: groupPrimaryColor,
				}}>
				<div className={css.coverContentWrapper}>
					<div className={css.coverContent}>
						<div className={css.badgeWrapper}>
							<Badge badgeUrl={response.form.group.profileImageUrl} />
						</div>
					</div>
				</div>
			</div>

			<div className={css.contentWrapper}>
				<div className={css.content}>
					<div className={css.info}>
						<LargeScreen>
							<div className={css.title}>{response.form.title}</div>
						</LargeScreen>
						{response.form.attachmentId && (
							<div className={css.image}>
								<img src={response.form.attachment.url} />
							</div>
						)}
						{response.form.description && (
							<Fragment>
								<div className={css.sectionHeading}>{t('Description')}</div>

								<div className={css.registrationDescription}>
									<FormattedContent raw={response.form.description} />
								</div>
							</Fragment>
						)}
					</div>

					<div className={css.sidebar}>
						<Row autoColumns="max-content" spacing={spacing._2}>
							<Label
								color={models.form.isOpen(response.form) ? 'green' : 'gray'}>
								{models.form.getTranslatedStatus(response.form.status)}
							</Label>
							{!!response.form.maxSubmissions && (
								<AvailableSpots form={response.form} />
							)}
						</Row>
						<SmallScreen>
							<div className={css.title}>{response.form.title}</div>
						</SmallScreen>
						<div className={css.buttonWrapper}>
							<Button
								testid="registration.register"
								block
								large
								primary
								href={routes.Registration.Form(
									response.form.group.slug,
									response.form.guid
								)}
								label={t('Register')}
								disabled={!models.form.isOpen(response.form)}
							/>
						</div>
						{submissions && (
							<div>
								<AvatarStack
									users={submissions.map((sb) => sb.submittedForUser)}
									renderRestWith={
										<span className={css.stackText}>
											<T
												_str="Registered by you: {submissions}"
												submissions={
													<div className={css.submissionNames}>
														{submissionsString}
													</div>
												}
											/>
										</span>
									}
									size={25}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default PreviewForm;
