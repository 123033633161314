import { ReactNode } from 'react';

export const symbol_sort_by_alpha: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m181-379-31 81q-3 7.615-10.5 12.808Q132-280 123-280q-16 0-24.5-13T96-320l139-342q3-8 10.273-13 7.272-5 16.727-5h23q9.455 0 16.727 5Q309-670 312-662l138 342q6 14-2.5 27t-24.125 13q-9.375 0-16.875-5.481T396-299l-31-80H181Zm20-52h144l-70-185h-4l-70 185Zm406 99h207q10.833 0 18.417 7.618 7.583 7.617 7.583 18.5 0 10.882-7.583 18.382Q824.833-280 814-280H567q-7.917 0-13.458-5.542Q548-291.083 548-299v-32q0-5.25 2-10.125t5-8.875l226-278H592q-10.833 0-18.417-7.618-7.583-7.617-7.583-18.5 0-10.882 7.583-18.382Q581.167-680 592-680h226q8.333 0 14.167 5.871Q838-668.258 838-659.871V-628q0 5.25-2 10.125T831-609L607-332ZM402-784q-5.25 0-7.125-4.5t2.175-8.55l72.09-72.09Q474-874 480.182-874q6.182 0 10.818 5l71.957 71.957Q567-793 565.125-788.5 563.25-784 558-784H402Zm67 693-71.957-71.957Q393-167 394.875-171.5 396.75-176 402-176h156q5.25 0 7.125 4.5t-2.175 8.55l-72.09 72.09Q486-86 479.818-86q-6.182 0-10.818-5Z"
		/>
	</svg>
);
