import { ReactNode } from 'react';

export const symbol_go_to_line: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M479.882-700Q459-700 444.5-714.618q-14.5-14.617-14.5-35.5Q430-771 444.618-785.5q14.617-14.5 35.5-14.5Q501-800 515.5-785.382q14.5 14.617 14.5 35.5Q530-729 515.382-714.5q-14.617 14.5-35.5 14.5Zm0 540Q459-160 444.5-174.618q-14.5-14.617-14.5-35.5Q430-231 444.618-245.5q14.617-14.5 35.5-14.5Q501-260 515.5-245.382q14.5 14.617 14.5 35.5Q530-189 515.382-174.5q-14.617 14.5-35.5 14.5Z"
		/>
	</svg>
);
