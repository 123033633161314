import { ReactNode } from 'react';

export const symbol_horizontal_split: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M150-200q-12.75 0-21.375-8.625T120-230v-260q0-12.75 8.625-21.375T150-520h660q12.75 0 21.375 8.625T840-490v260q0 12.75-8.625 21.375T810-200H150Zm0-380q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-640h660q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-580H150Zm0-120q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-760h660q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-700H150Z"
		/>
	</svg>
);
