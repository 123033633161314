import * as colorconv from 'pkg/colorconverter';
import { useCurrentGroup } from 'pkg/identity';

interface GroupPalette {
	primaryColor?: string;

	accentColor?: string;
	accentColorFaded?: string;
	accentColorHover?: string;
	accentColorLight?: string;
	accentColorTextHover?: string;

	colorProfileGradient?: string;
	backgroundColor?: string;

	scrollbarColor?: string;
}

const DefaultGroupPalette: GroupPalette = {
	primaryColor: null,

	accentColor: null,
	accentColorFaded: null,
	accentColorHover: null,
	accentColorLight: null,
	accentColorTextHover: null,

	colorProfileGradient: null,
	backgroundColor: null,

	scrollbarColor: null,
};

export default function useGroupPalette(): GroupPalette {
	const group = useCurrentGroup();

	if (group && group.primaryColor) {
		return {
			primaryColor: colorconv.primaryColor(group.primaryColor),

			accentColor: colorconv.accentColor(group.primaryColor),
			accentColorFaded: colorconv.primaryColor(group.primaryColor, 0.17),
			accentColorHover: colorconv.primaryColor(group.primaryColor, 0.1),
			accentColorLight: colorconv.primaryColor(group.primaryColor, 0.06),
			accentColorTextHover: colorconv.primaryColor(group.primaryColor, 0.7),

			backgroundColor: colorconv.backgroundColor(group.primaryColor),

			scrollbarColor: colorconv.scrollbarColor(group.primaryColor),
		} as GroupPalette;
	}

	return DefaultGroupPalette;
}
