// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.a4oREtHzdDDJC1s5Arj8 {
	font-weight: var(--font-weight-bold);
}

.fbhcUTPNhzp_o8ok2TSH {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	gap: var(--spacing-2);
	color: var(--palette-gray-600);
	margin: var(--spacing-1) 0 var(--spacing-2) 0;
	font-size: var(--font-size-sm);
}
`, "",{"version":3,"sources":["webpack://./routes/form_submissions/single/styles.css"],"names":[],"mappings":"AAAA;CACC,oCAAoC;AACrC;;AAEA;CACC,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CACnB,qBAAqB;CACrB,8BAA8B;CAC9B,6CAA6C;CAC7C,8BAA8B;AAC/B","sourcesContent":[".boldCode {\n\tfont-weight: var(--font-weight-bold);\n}\n\n.fieldDescription {\n\tdisplay: flex;\n\talign-items: center;\n\tgap: var(--spacing-2);\n\tcolor: var(--palette-gray-600);\n\tmargin: var(--spacing-1) 0 var(--spacing-2) 0;\n\tfont-size: var(--font-size-sm);\n}\n"],"sourceRoot":""}]);
// Exports
export var boldCode = `a4oREtHzdDDJC1s5Arj8`;
export var fieldDescription = `fbhcUTPNhzp_o8ok2TSH`;
export default ___CSS_LOADER_EXPORT___;
