import { Record } from 'immutable';
import { schema } from 'normalizr';

import { eventSeriesUsers } from 'pkg/models/schemas';
import User from 'pkg/models/user';

export enum EventSeriesUserStatus {
	Accepted = 'accepted',
	Declined = 'declined',
	Pending = 'pending',
}

interface IEventSeriesUser {
	eventSeriesId: number;
	flags: [];
	id: number;
	status: string;
	user: User;
	userId: number;
}

const EventSeriesUserProps: IEventSeriesUser = {
	eventSeriesId: null,
	flags: [],
	id: null,
	status: null,
	user: new User({}),
	userId: null,
};

class EventSeriesUser
	extends Record(EventSeriesUserProps, 'EventSeriesUserRecord')
	implements IEventSeriesUser
{
	static normalizr(): schema.Entity {
		return eventSeriesUsers;
	}
}

export default EventSeriesUser;
