import { ReactNode } from 'react';

export const symbol_blur_medium: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M280-280q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T280-340h177q-23.032-22.862-37.016-50.431Q406-418 402-450H210q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T210-510h191.788Q407-542 421-569.5t36-50.5H190q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T190-680h410q83 0 141.5 58.5T800-480q0 83-58.5 141.5T600-280H280Zm319.765-60Q658-340 699-380.765q41-40.764 41-99Q740-538 699.235-579q-40.764-41-99-41Q542-620 501-579.235q-41 40.764-41 99Q460-422 500.765-381q40.764 41 99 41Zm-409.94 60Q177-280 168.5-288.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Z"
		/>
	</svg>
);
