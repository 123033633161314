// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
	.diiY4UmMvG23EU5ADRZz:invalid [type='submit'] {
		opacity: 0.7;
		cursor: not-allowed;
	}

.diiY4UmMvG23EU5ADRZz:invalid [type='submit']:active {
			pointer-events: none;
		}

.F2HejlHQkZHkybPay3rl {
	font-size: var(--font-size-lg);
	font-weight: var(--font-weight-semibold);
	color: var(--palette-white);
}

@media (max-width: 768px) {

.F2HejlHQkZHkybPay3rl {
		font-size: var(--font-size-base);
}
	}
`, "",{"version":3,"sources":["webpack://./routes/public/styles/forms/styles.css"],"names":[],"mappings":";CACC;EACC,YAAY;EACZ,mBAAmB;CAKpB;;AAHC;GACC,oBAAoB;EACrB;;AAIF;CACC,8BAA8B;CAC9B,wCAAwC;CACxC,2BAA2B;AAK5B;;AAHC;;AALD;EAME,gCAAgC;AAElC;CADC","sourcesContent":[".wrapper {\n\t&:invalid [type='submit'] {\n\t\topacity: 0.7;\n\t\tcursor: not-allowed;\n\n\t\t&:active {\n\t\t\tpointer-events: none;\n\t\t}\n\t}\n}\n\n.label {\n\tfont-size: var(--font-size-lg);\n\tfont-weight: var(--font-weight-semibold);\n\tcolor: var(--palette-white);\n\n\t@media (--small-viewport) {\n\t\tfont-size: var(--font-size-base);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var wrapper = `diiY4UmMvG23EU5ADRZz`;
export var label = `F2HejlHQkZHkybPay3rl`;
export default ___CSS_LOADER_EXPORT___;
