// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hlsvttp5EKgVfdXuxyUu {
	color: var(--palette-gray-500);
	font-size: var(--font-size-xs);
	font-weight: var(--font-weight-normal);
}
`, "",{"version":3,"sources":["webpack://./routes/forms/hooks/sections/products/styles.css"],"names":[],"mappings":"AAAA;CACC,8BAA8B;CAC9B,8BAA8B;CAC9B,sCAAsC;AACvC","sourcesContent":[".validUntil {\n\tcolor: var(--palette-gray-500);\n\tfont-size: var(--font-size-xs);\n\tfont-weight: var(--font-weight-normal);\n}\n"],"sourceRoot":""}]);
// Exports
export var validUntil = `hlsvttp5EKgVfdXuxyUu`;
export default ___CSS_LOADER_EXPORT___;
