import { ReactNode } from 'react';

export const symbol_turn_slight_left: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M569.825-160Q557-160 548.5-168.625T540-190v-268L300-698v97q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T240-601v-169q0-12.75 8.625-21.375T270-800h169q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T439-740h-97l240 240q8 8 13 18.956 5 10.955 5 23.044v268q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Z"
		/>
	</svg>
);
