import { ReactNode } from 'react';

export const symbol_line_end: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M790-390q-29.086 0-52.543-17T705-450H110q-12.75 0-21.375-8.675Q80-467.351 80-480.175 80-493 88.625-501.5T110-510h595q9-26 32.457-43T790-570q37.8 0 63.9 26.141t26.1 64Q880-442 853.9-416T790-390Z"
		/>
	</svg>
);
