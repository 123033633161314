import { t } from '@transifex/native';
import { T } from '@transifex/react';
import { useState } from 'react';

import * as styles from 'pkg/config/styles';

import Link from 'pkg/router/Link';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import * as api from 'pkg/api';
import { useCurrentAccount, useFetchIdentity } from 'pkg/identity';

import SectionTitle from 'components/SectionTitle';
import SectionDescription from 'components/SectionDescription';
import { useStepModalContext } from 'components/step-modal';

import Column from 'components/layout/column';
import {
	flashErrorMessage,
	useCreateChildAccount,
	useJoinModalContext,
} from 'components/group/join-modal';
import { Spinner } from 'components/loaders/spinner';

import Button from 'design/button';

export default function ConfirmManagedAccount(): JSX.Element {
	const joinContext = useJoinModalContext();
	const modalContext = useStepModalContext();
	const currentAccount = useCurrentAccount();
	const fetchIdentity = useFetchIdentity();

	const createAccount = useCreateChildAccount(currentAccount);

	const [isBusy, setBusy] = useState<boolean>(false);

	const handleCreateCredentials = () => modalContext.next();

	const handleManagedAccount = async () => {
		setBusy(true);

		const [request, info] = await api.get<models.onboardingInfo.Group>(
			endpoints.Accounts.OnboardingInfo() +
				`?invite_code=${joinContext.groupCode}`
		);

		if (request.ok) {
			joinContext.set('connectionInfo', info);
		} else {
			flashErrorMessage(request.status);

			return Promise.resolve(false);
		}

		const { statusCode, membership, account } = await createAccount(
			joinContext.getCreatePayload()
		);

		if (statusCode >= 400) {
			flashErrorMessage(statusCode);

			setBusy(false);
		}

		// @NOTE Joining an org does not return a valid membership
		if (!membership?.hasOwnProperty('message')) {
			joinContext.set('membership', membership);
		}

		joinContext.set('targetAccount', account);

		await fetchIdentity();

		modalContext.goTo('connected');
	};

	return (
		<Column spacing={styles.spacing._7}>
			<Column spacing={styles.spacing._1}>
				<SectionTitle>
					{t('Should your child be able to access their own account?')}
				</SectionTitle>
				<SectionDescription>
					{t(
						`If so, we will need to collect a unique email address for your child. If you don't create a login for your child, you will still be able to manage team related activities on their behalf.`
					)}
				</SectionDescription>
			</Column>

			<Column spacing={styles.spacing._4}>
				<Button secondary disabled={isBusy} onClick={handleCreateCredentials}>
					{t('Yes, create an account for {name}', {
						name: joinContext.createPayload?.firstName,
					})}
				</Button>
				<Button
					secondary
					busy={isBusy}
					onClick={handleManagedAccount}
					testid="registration.child.manage">
					{isBusy ? (
						<Spinner color="inherit" size="40px" />
					) : (
						t('No, I manage their account')
					)}
				</Button>
			</Column>

			<Column spacing={styles.spacing._7}>
				<div>
					<T
						_str="By submitting this you agree to our {terms_and_conditions_link}."
						terms_and_conditions_link={
							<Link href="https://www.360player.com/terms-of-service">
								{t('terms and conditions')}
							</Link>
						}
					/>
				</div>
			</Column>
		</Column>
	);
}
