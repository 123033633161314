import { ReactNode } from 'react';

export const symbol_sd_card: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M413.175-528q12.825 0 21.325-8.625T443-558v-97q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T383-655v97q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625Zm119 0q12.825 0 21.325-8.625T562-558v-97q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T502-655v97q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625Zm119 0q12.825 0 21.325-8.625T681-558v-97q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T621-655v97q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625ZM220-80q-24 0-42-18t-18-42v-476q0-12.444 5-23.722T178-659l203-203q8-8 19.278-13 11.278-5 23.722-5h316q24 0 42 18t18 42v680q0 24-18 42t-42 18H220Zm0-60h520v-680H426L220-613.875V-140Zm0 0h520-520Z"
		/>
	</svg>
);
