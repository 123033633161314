const userChecked = {
	name: 'user-checked',
	objects: [
		{
			type: 'path',
			attributes: {
				d: 'M15.1047 7.11705C15.1047 9.39084 13.2614 11.2341 10.9876 11.2341C8.71382 11.2341 6.87055 9.39084 6.87055 7.11705C6.87055 4.84327 8.71382 3 10.9876 3C13.2614 3 15.1047 4.84327 15.1047 7.11705ZM13.8047 7.11705C13.8047 8.67287 12.5434 9.9341 10.9876 9.9341C9.43179 9.9341 8.17055 8.67287 8.17055 7.11705C8.17055 5.56124 9.43179 4.3 10.9876 4.3C12.5434 4.3 13.8047 5.56124 13.8047 7.11705Z',
				fillRule: 'evenodd',
				clipRule: 'evenodd',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M9.31058 12.2574C6.37763 12.2574 4 14.635 4 17.568H5.3C5.3 15.353 7.0956 13.5574 9.31058 13.5574H12.6646C13.0033 13.5574 13.3323 13.5994 13.6465 13.6785L14.326 12.5225C13.8033 12.3505 13.2448 12.2574 12.6646 12.2574H9.31058Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M20.2232 13.9747C20.48 13.6858 20.454 13.2433 20.1651 12.9865C19.8761 12.7296 19.4337 12.7557 19.1768 13.0446L14.3667 18.456L12.2232 16.0446C11.9664 15.7557 11.5239 15.7296 11.235 15.9865C10.946 16.2433 10.92 16.6858 11.1768 16.9747L13.8435 19.9747C13.9763 20.1242 14.1667 20.2097 14.3667 20.2097C14.5666 20.2097 14.757 20.1242 14.8899 19.9747L20.2232 13.9747Z',
			},
		},
	],
};

export default userChecked;
