export function AddVideo(matchID: number): string {
	return `/v1/matches/${matchID}/videos`;
}

export function DeleteVideo(matchID: number, videoID: number): string {
	return `/v1/matches/${matchID}/videos/${videoID}`;
}

export function DeleteVideoByID(ID: number): string {
	return `/v1/match-videos/${ID}`;
}

export function Destroy(matchID: number): string {
	return `/v1/matches/${matchID}`;
}

export function EventIndex(eventID: number): string {
	return `/v1/events/${eventID}/matches`;
}

export function Index(): string {
	return `/v1/matches`;
}

export function Show(matchID: number): string {
	return `/v1/matches/${matchID}`;
}

export function ShowLatestByUser(userID: number): string {
	return `/v1/users/${userID}/latest-match`;
}

export function ShowVideos(matchID: number): string {
	return `/v1/matches/${matchID}/videos`;
}

export function Update(matchID: number): string {
	return `/v1/matches/${matchID}`;
}
