import { ReactNode } from 'react';

export const symbol_step_over: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M479.882-200Q434-200 402-232.118q-32-32.117-32-78Q370-356 402.118-388q32.117-32 78-32Q526-420 558-387.882q32 32.117 32 78Q590-264 557.882-232q-32.117 32-78 32ZM716-550q-32-67-95.17-108.5Q557.659-700 480-700q-85 0-151 48.5T236-525q-5 13.837-15.417 24.419Q210.167-490 196-490q-13 0-19.5-12.5T174-529q29-102 113-166.5T479-760q81 0 149 37t112 99v-106q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T800-730v210q0 12.75-8.625 21.375T770-490H560q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T560-550h156Z"
		/>
	</svg>
);
