import { Fragment, useState, memo } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import { useEndpoint } from 'pkg/api/use_endpoint';
import { useCurrentAccountUserIds } from 'pkg/identity';

import ChatUsersModal from 'containers/chat/modals/ChatUsersModal';

import AvatarStack from 'components/avatar/avatar-stack';

const Wrapper = styled.div`
	display: grid;
	padding: 0 1rem;
	grid-area: header;
	grid-auto-columns: 1fr auto;
	grid-auto-flow: column;
	grid-gap: 0.8rem;
	align-items: center;
	background: var(--palette-white);
	border-bottom: var(--palette-gray-300) 1px solid;
	font-weight: var(--font-weight-semibold);
	transition: transform 200ms ease-out;
	z-index: 1;

	& > span {
		margin-bottom: 0.1rem;
		color: var(--palette-gray-800);
	}
`;
const ParentText = styled.div`
	font-size: var(--font-size-xs);
	color: var(--palette-gray-600);
	font-weight: var(--font-weight-thin);
	margin-top: var(--spacing-1);
`;

const Title = styled.div`
	display: inline-block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	min-width: 0;
`;
interface RelativeViewProps {
	userId: number;
}
interface ListRelatives {
	parents: models.user.User[];
	children: models.user.User[];
}

function UserChildren({ userId }: RelativeViewProps): JSX.Element {
	const { record: summary } = useEndpoint<ListRelatives>(
		endpoints.Users.ListRelatives(userId)
	);
	if (!summary.children) {
		return null;
	}

	return (
		<ParentText>
			{`${t('Parent of:')} `}
			{summary.children
				?.map((user) => user.firstName + ' ' + user.lastName)
				.join(', ')}
		</ParentText>
	);
}

interface HeaderProps {
	children?: JSX.Element;

	chat: models.chat.Chat;
}

const Header = ({ children, chat }: HeaderProps) => {
	const [showGroupMembersModal, setShowGroupMembersModal] = useState(false);
	const activeUserIds = useCurrentAccountUserIds();

	const myChatUser = models.chat.findMyChatUser(chat, activeUserIds);
	const chatTitle = models.chat.getChatTitle(chat, activeUserIds);

	const users = models.chatUser
		.filterDeleted(chat.users)
		.filter((cu) => cu.userId !== myChatUser?.userId)
		.map((cu) => cu.user);

	const toggleGroupMembersModal = () =>
		setShowGroupMembersModal(!showGroupMembersModal);

	return (
		<Fragment>
			<Wrapper>
				<AvatarStack
					users={users}
					showRest={false}
					onClick={toggleGroupMembersModal}
					renderEmptyWith={<span>{chatTitle}</span>}>
					<Title>
						<span>{chatTitle}</span>
						{users.length === 1 && <UserChildren userId={users[0].id} />}
					</Title>
				</AvatarStack>
				{children}
			</Wrapper>

			{showGroupMembersModal && (
				<ChatUsersModal chat={chat} onClose={toggleGroupMembersModal} />
			)}
		</Fragment>
	);
};

export default memo(Header);
