import { ReactNode } from 'react';

export const symbol_femur_alt: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M537-448q13 0 21.5-9t8.5-21q0-8-2.5-13.5T557-502q12 0 22-8.5t10-20.5q0-13-8.5-21.5T559-561q-11 0-19.5 8t-9.5 19l-163-76q2-4 3.5-8t1.5-9q0-13-8.5-21.5T342-657q-12 0-21 8.5t-9 21.5q0 6 2.5 11.5T322-605q-12 0-22 8.5T290-575q0 12 9 21t21 9q12 0 20.5-8.5T350-573l164 76q-1 1-7 19 0 12 9 21t21 9Zm-172 57q-21-5-48.5-12T258-422q-28-11-57.5-26.5T142-484q-11-8-13-21t6-24q8-11 20.5-12.5T179-535q21 16 45 30t49 24.5q25 10.5 50.5 18T374-450q24 5 38.5 24t11.5 43q-4 32-5 66.5t2 69q3 34.5 10 69.5t17 68q4 11-4 19t-19 10q-11 2-23-4t-17-25q-12-43-18-84t-7-77.5q-1-36.5.5-67T365-391h-.5.5Zm357-243q18 29 28 62t10 69v393q0 13-8.5 21.5T730-80q-13 0-21.5-8.5T700-110v-393q0-54-28.5-98T595-668L357-773q-14-6-17.5-20.5T345-820q7-9 17-12t21 2l237 108q32 14 58 36.5t44 51.5ZM458-80q-14-36-23-74.5T422-232q-4-39-3.5-77t5.5-74q3-24-11.5-43T374-450q-29-5-58.5-14.5t-58-22.5Q229-500 203-517t-49-38q23 21 49 38t54.5 30q28.5 13 58 22.5T374-450q24 5 38.5 24t11.5 43q-5 36-5.5 74t3.5 77q4 39 13 77.5T458-80Z"
		/>
	</svg>
);
