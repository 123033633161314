import { ReactNode } from 'react';

export const symbol_glass_cup: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M262-80q-23 0-40.344-14.93Q204.312-109.859 202-133l-75-680q-3-27 14.837-47 17.838-20 45.163-20h586q27.325 0 45.163 20Q836-840 833-813l-75 680q-2.312 23.141-19.656 38.07Q721-80 698-80H262Zm-12-170 12 110h436l12-110H250Zm-7-61h474l56-509H187l56 509Zm7 171h460-460Z"
		/>
	</svg>
);
