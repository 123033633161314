import { CollectionResponse, useCollection } from 'pkg/api/use_collection';
import * as endpoints from 'pkg/api/endpoints/auto';
import { Video } from 'pkg/api/models/video';
import { VideoPlaylist } from 'pkg/api/models/video_playlist';
import { VideoCollection } from 'pkg/api/models/video_collection';
import {
	useCurrentAccount,
	useCurrentGroup,
	useCurrentMembership,
} from 'pkg/identity';

/**
 *	Behaves like a CollectionPagination without page data.
 *
 *	@prop boolean hasPrev
 *	@prop boolean hasNext
 *	@prop callable fetchNext
 *	@prop callable fetchPrev
 */
export interface AggregatedCollectionPagination {
	hasPrev: boolean;
	hasNext: boolean;
	fetchPrev: () => void;
	fetchNext: () => void;
}

/**
 *	Behaves like a CollectionResponse without all the callbacks, used for aggregated results.
 *
 *	@prop boolean isLoading
 *	@prop T[] records
 *	@prop AggregatedCollectionPagination pagination
 *	@prop callable removeRecord
 *	@prop callable replaceRecord
 */
export interface AggregatedCollectionResponse<T> {
	isLoading: boolean;
	records: T[];
	pagination: AggregatedCollectionPagination;
	refresh?: () => Promise<void>;
	removeRecord?: (recordId: number) => void;
	replaceRecord?: (record: T) => void;
}

/**
 *	Fetches videos for account.
 *
 *	@param number accountId
 *	@param number limit
 *
 *	@returns CollectionResponse<Video>
 */
export function useAccountVideos(
	accountId: number,
	limit: number = 10
): CollectionResponse<Video> {
	return useCollection<Video>(endpoints.Video.Index(), {
		showAllResults: true,
		queryParams: new URLSearchParams({
			account_id: accountId.toString(),
			limit: limit.toString(),
		}),
	});
}

/**
 *	Fetches video playlists for account.
 *
 *	@param number accountId
 *	@param number limit
 *
 *	@returns CollectionResponse<VideoPlaylist>
 */
export function useAccountPlaylists(
	accountId: number,
	limit: number = 10
): CollectionResponse<VideoPlaylist> {
	return useCollection<VideoPlaylist>(endpoints.VideoPlaylist.Index(), {
		showAllResults: true,
		queryParams: new URLSearchParams({
			account_id: accountId.toString(),
			count: limit.toString(),
		}),
	});
}

/**
 *	Fetches video collections for account.
 *
 *	@param number limit
 *
 *	@returns CollectionResponse<VideoCollection>
 */
export function useAccountCollections(
	limit: number = 10
): CollectionResponse<VideoCollection> {
	return useCollection<VideoCollection>(endpoints.VideoCollections.Index(), {
		showAllResults: true,
		queryParams: new URLSearchParams({
			video_limit: limit.toString(),
		}),
	});
}

/**
 *	Fetches videos current user is tagged in.
 *
 *	@param number accountId
 *	@param number limit
 *
 *	@returns CollectionResponse<Video>
 */
export function useCurrentUserTaggedVideos(
	limit: number = 10
): CollectionResponse<Video> {
	const { groupId, userId } = useCurrentMembership();

	return useCollection<Video>(endpoints.Video.Index(), {
		showAllResults: true,
		queryParams: new URLSearchParams({
			group_id: groupId.toString(),
			tagged_user_id: userId.toString(),
			limit: limit.toString(),
		}),
	});
}

/**
 *	Fetches videos for group.
 *
 *	@param number groupId
 *	@param number limit
 *
 *	@returns CollectionResponse<Video>
 */
export function useGroupVideos(
	groupId: number,
	limit: number = 10
): CollectionResponse<Video> {
	return useCollection<Video>(endpoints.Video.Index(), {
		showAllResults: true,
		queryParams: new URLSearchParams({
			group_id: groupId.toString(),
			limit: limit.toString(),
		}),
	});
}

/**
 *	Fetches video playlists for group.
 *
 *	@param number groupId
 *	@param number limit
 *
 *	@returns CollectionResponse<VideoPlaylist>
 */
export function useGroupPlaylists(
	groupId: number,
	limit: number = 10
): CollectionResponse<VideoPlaylist> {
	return useCollection<VideoPlaylist>(endpoints.VideoPlaylist.Index(), {
		showAllResults: true,
		queryParams: new URLSearchParams({
			group_id: groupId.toString(),
			count: limit.toString(),
		}),
	});
}

/**
 *	Fetches video collections for group.
 *
 *	@param number groupId
 *	@param number limit
 *
 *	@returns CollectionResponse<VideoCollection>
 */
export function useGroupCollections(
	groupId: number,
	limit: number = 10
): CollectionResponse<VideoCollection> {
	return useCollection<VideoCollection>(endpoints.VideoCollections.Index(), {
		showAllResults: true,
		queryParams: new URLSearchParams({
			group_id: groupId.toString(),
			video_limit: limit.toString(),
		}),
	});
}

/**
 *	Fetches video collections for club.
 *
 *	@param number groupId
 *	@param number limit
 *
 *	@returns CollectionResponse<Video>
 */
export function useClubCollections(
	groupId: number,
	limit: number = 10
): CollectionResponse<VideoCollection> {
	return useCollection<VideoCollection>(endpoints.VideoCollections.Index(), {
		showAllResults: true,
		queryParams: new URLSearchParams({
			group_id: groupId.toString(),
			video_limit: limit.toString(),
			inherited: '1',
		}),
	});
}

/**
 *	@prop boolean isLoading
 *	@prop boolean hasRecords
 *	@prop CollectionResponse<Video> videos
 *	@prop CollectionResponse<VideoPlaylist> playlists
 *	@prop CollectionResponse<VideoCollection> collections
 */
export interface LibraryResponse {
	refresh: () => void;
	numLoadingStates: number;
	isLoading: boolean;
	hasRecords: boolean;
	videos: CollectionResponse<Video>;
	playlists: CollectionResponse<VideoPlaylist>;
	collections: CollectionResponse<VideoCollection>;
}

/**
 *	Fetches video library for accout.
 *
 *	@param number accountId
 *	@param number limit
 *
 *	@returns LibraryResponse
 */
export function useAccountLibrary(
	accountId: number,
	limit: number = 15
): LibraryResponse {
	const videos = useAccountVideos(accountId, limit);
	const playlists = useAccountPlaylists(accountId, limit);
	const collections = useAccountCollections(limit);

	const numLoadingStates = [
		videos.isLoading,
		playlists.isLoading,
		collections.isLoading,
	].filter(Boolean).length;

	const isLoading = numLoadingStates > 0;

	const hasRecords =
		videos.records.length +
			playlists.records.length +
			collections.records.length >
		0;

	const refresh = () => {
		videos.refresh();
		playlists.refresh();
		collections.refresh();
	};

	return {
		refresh,
		numLoadingStates,
		isLoading,
		hasRecords,
		videos,
		playlists,
		collections,
	};
}

/**
 *	Fetches video library for group.
 *
 *	@param number groupId
 *	@param number limit
 *
 *	@returns LibraryResponse
 */
export function useGroupLibrary(
	groupId: number,
	limit: number = 15
): LibraryResponse {
	const videos = useGroupVideos(groupId, limit);
	const playlists = useGroupPlaylists(groupId, limit);
	const collections = useGroupCollections(groupId, limit);

	const numLoadingStates = [
		videos.isLoading,
		playlists.isLoading,
		collections.isLoading,
	].filter(Boolean).length;

	const isLoading = numLoadingStates > 0;

	const hasRecords =
		videos.records.length +
			playlists.records.length +
			collections.records.length >
		0;

	const refresh = () => {
		videos.refresh();
		playlists.refresh();
		collections.refresh();
	};

	return {
		refresh,
		numLoadingStates,
		isLoading,
		hasRecords,
		videos,
		playlists,
		collections,
	};
}

/**
 *	Fetches video library for clubs.
 *
 *	@param number groupId
 *	@param number limit
 *
 *	@returns LibraryResponse
 */
export function useClubLibrary(
	groupId: number,
	limit: number = 15
): LibraryResponse {
	const collections = useClubCollections(groupId, limit);

	const isLoading = collections.isLoading;
	const numLoadingStates = isLoading ? 1 : 0;

	const hasRecords = collections.records.length > 0;

	const refresh = () => {
		collections.refresh();
	};

	return {
		refresh,
		numLoadingStates,
		isLoading,
		hasRecords,
		videos: null,
		playlists: null,
		collections,
	};
}

/**
 *	Fetches all the videos the current account owns in a specific group.
 *
 *	@param number limit
 *
 *	@returns AggregatedCollectionResponse<Video>
 */
export function useAggregatedVideos(
	limit: number = 10
): CollectionResponse<Video> {
	const group = useCurrentGroup();
	return useCollection<Video>(endpoints.Video.AggregatedIndex(), {
		showAllResults: true,
		queryParams: new URLSearchParams({
			group_id: group.id.toString(),
			limit: limit.toString(),
		}),
	});
}

/**
 *	Merges account and group playlists into a single result.
 *
 *	@param number limit
 *
 *	@returns AggregatedCollectionResponse<VideoPlaylist>
 */
export function useAggregatedPlaylists(
	limit: number = 10
): AggregatedCollectionResponse<VideoPlaylist> {
	const group = useCurrentGroup();
	const account = useCurrentAccount();

	const playlists = useAccountPlaylists(account.id, limit);
	const groupPlaylists = useGroupPlaylists(group.id, limit);

	const isLoading = playlists.isLoading || groupPlaylists.isLoading;

	const records: VideoPlaylist[] = [
		...playlists.records,
		...groupPlaylists.records,
	]
		.filter(
			(playlist: VideoPlaylist, index: number, self: VideoPlaylist[]) =>
				index ===
				self.findIndex((item: VideoPlaylist) => item.id === playlist.id)
		)
		.sort((a: VideoPlaylist, b: VideoPlaylist) => b.id - a.id);

	const refresh = async () => {
		playlists.refresh();
		groupPlaylists.refresh();
	};

	const removeRecord = (recordId: number) => {
		playlists.removeRecord(recordId);
		groupPlaylists.removeRecord(recordId);
	};

	const replaceRecord = (record: VideoPlaylist) => {
		playlists.replaceRecord(record);
		groupPlaylists.replaceRecord(record);
	};

	const pagination: AggregatedCollectionPagination = {
		hasPrev: playlists.pagination.hasPrev || groupPlaylists.pagination.hasPrev,
		hasNext: playlists.pagination.hasNext || groupPlaylists.pagination.hasNext,
		fetchPrev: () => {
			if (playlists.pagination.hasPrev) {
				playlists.pagination.fetchPrev();
			}

			if (groupPlaylists.pagination.hasPrev) {
				groupPlaylists.pagination.fetchPrev();
			}
		},
		fetchNext: () => {
			if (playlists.pagination.hasNext) {
				playlists.pagination.fetchNext();
			}

			if (groupPlaylists.pagination.hasNext) {
				groupPlaylists.pagination.fetchNext();
			}
		},
	};

	return {
		isLoading,
		records,
		pagination,
		refresh,
		removeRecord,
		replaceRecord,
	};
}

/**
 *	Merges account, group and club collections into a single result.
 *
 *	@param number limit
 *
 *	@returns AggregatedCollectionResponse<VideoCollection>
 */
export function useAggregatedCollections(): AggregatedCollectionResponse<VideoCollection> {
	const group = useCurrentGroup();

	const collections = useAccountCollections(1);
	const groupCollections = useGroupCollections(group.id, 1);
	const clubCollections = useClubCollections(group.id, 1);

	const isLoading =
		collections.isLoading ||
		groupCollections.isLoading ||
		clubCollections.isLoading;

	const records: VideoCollection[] = [
		...collections.records,
		...groupCollections.records,
		...clubCollections.records,
	]
		.filter(
			(collection: VideoCollection, index: number, self: VideoCollection[]) =>
				index ===
				self.findIndex((item: VideoCollection) => item.id === collection.id)
		)
		.sort((a: VideoCollection, b: VideoCollection) => b.id - a.id);

	const refresh = async () => {
		collections.refresh();
		groupCollections.refresh();
		clubCollections.refresh();
	};

	const removeRecord = (recordId: number) => {
		collections.removeRecord(recordId);
		groupCollections.removeRecord(recordId);
		clubCollections.removeRecord(recordId);
	};

	const replaceRecord = (record: VideoCollection) => {
		collections.replaceRecord(record);
		groupCollections.replaceRecord(record);
		clubCollections.replaceRecord(record);
	};

	const pagination: AggregatedCollectionPagination = {
		hasPrev:
			collections.pagination.hasPrev ||
			groupCollections.pagination.hasPrev ||
			clubCollections.pagination.hasPrev,
		hasNext:
			collections.pagination.hasNext ||
			groupCollections.pagination.hasNext ||
			clubCollections.pagination.hasNext,
		fetchPrev: () => {
			if (collections.pagination.hasPrev) {
				collections.pagination.fetchPrev();
			}

			if (groupCollections.pagination.hasPrev) {
				groupCollections.pagination.fetchPrev();
			}

			if (clubCollections.pagination.hasPrev) {
				clubCollections.pagination.fetchPrev();
			}
		},
		fetchNext: () => {
			if (collections.pagination.hasNext) {
				collections.pagination.fetchNext();
			}

			if (groupCollections.pagination.hasNext) {
				groupCollections.pagination.fetchNext();
			}

			if (clubCollections.pagination.hasNext) {
				clubCollections.pagination.fetchNext();
			}
		},
	};

	return {
		isLoading,
		records,
		pagination,
		refresh,
		removeRecord,
		replaceRecord,
	};
}

type ContentGroup = 'team' | 'club' | 'your';

/**
 *	Searches for videos, either in group library, unless "Your content" tab is active.
 *
 *	@param string keyword
 *	@param ContentGroup contentGroup
 *	@param number limit
 *
 *	@returns CollectionResponse<Video>
 */
export function useVideoSearch(
	keyword: string,
	contentGroup: ContentGroup,
	limit: number = 25
): CollectionResponse<Video> {
	const group = useCurrentGroup();
	const { id } = useCurrentAccount();

	const params: { [key: string]: string } = {
		name: keyword,
		limit: limit.toString(),
	};

	if (contentGroup === 'your') {
		params['account_id'] = id.toString();
	} else {
		params['group_id'] = group.id.toString();
	}

	return useCollection<Video>(endpoints.Video.Index(), {
		showAllResults: true,
		queryParams: new URLSearchParams(params),
	});
}

/**
 *	Searches for videos in team and account library.
 *
 *	@param string keyword
 *	@param number limit
 *
 *	@returns AggregatedCollectionResponse<Video>
 */
export function useAggregatedVideoSearch(
	keyword: string,
	limit: number = 25
): AggregatedCollectionResponse<Video> {
	const videos = useVideoSearch(keyword, 'your', limit);
	const groupVideos = useVideoSearch(keyword, 'team', limit);

	const isLoading = videos.isLoading || groupVideos.isLoading;

	const records: Video[] = [...videos.records, ...groupVideos.records]
		.filter(
			(video: Video, index: number, self: Video[]) =>
				index === self.findIndex((item: Video) => item.id === video.id)
		)
		.sort((a: Video, b: Video) => b.id - a.id)
		.slice(0, limit);

	const pagination: AggregatedCollectionPagination = {
		hasPrev: videos.pagination.hasPrev || groupVideos.pagination.hasPrev,
		hasNext: videos.pagination.hasNext || groupVideos.pagination.hasNext,
		fetchPrev: () => {
			if (videos.pagination.hasPrev) {
				videos.pagination.fetchPrev();
			}

			if (groupVideos.pagination.hasPrev) {
				groupVideos.pagination.fetchPrev();
			}
		},
		fetchNext: () => {
			if (videos.pagination.hasNext) {
				videos.pagination.fetchNext();
			}

			if (groupVideos.pagination.hasNext) {
				groupVideos.pagination.fetchNext();
			}
		},
	};

	return {
		isLoading,
		records,
		pagination,
	};
}

/**
 *	Searches for playlists, either in group library, or your content if content group is properly set.
 *
 *	@param string keyword
 *	@param ContentGroup contentGroup
 *	@param number limit
 *
 *	@returns CollectionResponse<Video>
 */
export function usePlaylistSearch(
	keyword: string,
	contentGroup: ContentGroup,
	limit: number = 25
): CollectionResponse<VideoPlaylist> {
	const group = useCurrentGroup();
	const { id } = useCurrentAccount();

	const params: { [key: string]: string } = {
		title: keyword,
		count: limit.toString(),
	};

	if (contentGroup === 'your') {
		params['account_id'] = id.toString();
	} else {
		params['group_id'] = group.id.toString();
	}

	return useCollection<VideoPlaylist>(endpoints.VideoPlaylist.Index(), {
		showAllResults: true,
		queryParams: new URLSearchParams(params),
	});
}

/**
 *	Searches for video playlists in team and account library.
 *
 *	@param string keyword
 *	@param number limit
 *
 *	@returns AggregatedCollectionResponse<VideoPlaylist>
 */
export function useAggregatedPlaylistSearch(
	keyword: string,
	limit: number = 25
): AggregatedCollectionResponse<VideoPlaylist> {
	const playlists = usePlaylistSearch(keyword, 'your', limit);
	const groupPlaylists = usePlaylistSearch(keyword, 'team', limit);

	const isLoading = playlists.isLoading || groupPlaylists.isLoading;

	const records: VideoPlaylist[] = [
		...playlists.records,
		...groupPlaylists.records,
	]
		.filter(
			(playlist: VideoPlaylist, index: number, self: VideoPlaylist[]) =>
				index ===
				self.findIndex((item: VideoPlaylist) => item.id === playlist.id)
		)
		.sort((a: VideoPlaylist, b: VideoPlaylist) => b.id - a.id)
		.slice(0, limit);

	const pagination: AggregatedCollectionPagination = {
		hasPrev: playlists.pagination.hasPrev || groupPlaylists.pagination.hasPrev,
		hasNext: playlists.pagination.hasNext || groupPlaylists.pagination.hasNext,
		fetchPrev: () => {
			if (playlists.pagination.hasPrev) {
				playlists.pagination.fetchPrev();
			}

			if (groupPlaylists.pagination.hasPrev) {
				groupPlaylists.pagination.fetchPrev();
			}
		},
		fetchNext: () => {
			if (playlists.pagination.hasPrev) {
				playlists.pagination.fetchNext();
			}

			if (groupPlaylists.pagination.hasPrev) {
				groupPlaylists.pagination.fetchNext();
			}
		},
	};

	return {
		isLoading,
		records,
		pagination,
	};
}
