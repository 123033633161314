import { ReactNode } from 'react';

export const symbol_heart_broken: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M439-134Q325-247 254.5-321.5t-109-127q-38.5-52.5-52-91T80-620q0-92 64-156t156-64q45 0 87 16.5t75 47.5l-51 178q-5 14 4.5 26t24.5 12h81l-35 290.568Q485-261 492.5-260t9.5-6l89-295q5-14-4.273-26.5Q577.455-600 562-600h-82l71-212q25-14 52.5-21t56.5-7q92 0 156 64t64 156q0 42-13.5 81t-52 91.5Q776-395 706-321T523-134q-8 8-19 13t-23 5q-12 0-23-5t-19-13ZM140-620q0 33 11.5 63.5t41.5 71q30 40.5 82.5 97T410-248q4 4 8.5 2.5t5.5-6.5l29-248h-15q-42 0-72-29t-13-88l40-137q-22-11-45-18.5t-48-7.5q-66.286 0-113.143 46.857T140-620Zm680 0q0-66.286-46.857-113.143T660-780q-15.333 0-30.667 3Q614-774 600-769l-36 109h5q41 0 66.5 32.5T645-541l-72 248q-2 5 3.5 8.5T587-286q70-68 115-116.5t71.5-86.5q26.5-38 36.5-69t10-62Zm-175 79Zm-292-76Z"
		/>
	</svg>
);
