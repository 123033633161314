import { Record } from 'immutable';

import { matchOpponent } from 'pkg/models/schemas';

const MatchOpponentRecord = Record(
	{
		id: 0,
		groupId: null,
		externalId: null,
		name: '',
		clubExternalId: null,
		clubName: '',
		imageUrl: '',
		createdAt: 0,
		updatedAt: 0,
	},
	'matchOpponent'
);

export default class extends MatchOpponentRecord {
	static normalizr() {
		return matchOpponent;
	}
}
