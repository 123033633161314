import { ReactNode } from 'react';

export const symbol_oral_disease: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M230-80q-12.75 0-21.375-8.625T200-110v-300q0-12.75 8.625-21.375T230-440h50v-198l-90-90q-17-16.934-17-41.967Q173-795 190-812l83-83q8.8-9 20.9-9 12.1 0 21.1 9.053 9 9.052 9 21.5Q324-861 315-852l-83 82 90 90q8 8 13 19.413T340-637v197h50q12.75 0 21.375 8.625T420-410v300q0 12.75-8.625 21.375T390-80H230Zm340 0q-12.75 0-21.375-8.625T540-110v-300q0-12.75 8.625-21.375T570-440h50v-133q-52-11-86-53.5t-34-98.11q0-64.39 43.5-109.89T650-880q63 0 106.5 45.531T800-725q0 56-34 98.5T680-573v133h50q12.75 0 21.375 8.625T760-410v300q0 12.75-8.625 21.375T730-80H570Zm80-550q38 0 64-27.867t26-67Q740-764 714-792q-26-28-64-28t-64 27.867q-26 27.867-26 67Q560-686 586-658q26 28 64 28ZM260-140h100v-240H260v240Zm340 0h100v-240H600v240Zm-340 0h100-100Zm340 0h100-100Z"
		/>
	</svg>
);
