import { ReactNode } from 'react';

export const symbol_join_right: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M920-480q0 117-81.5 198.5T640-200q-36 0-69.5-8.5T508-233q58-45 90-109.5T630-480q0-73-32-137.5T508-727q29-16 62.5-24.5T640-760q117 0 198.5 81.5T920-480ZM480-259q-5 0-9.5-1.5T461-266q-48-41-74.5-95.5T360-480q0-64 26.5-118.5T461-694q5-4 9.5-5.5t9.5-1.5q5 0 9.5 1.5t9.5 5.5q48 41 74.5 95.5T600-480q0 64-26.5 118.5T499-266q-5 4-9.5 5.5T480-259ZM40-480q0-56 21-107t61-91q54-54 127.5-73t147.5 2q12 4 18.5 14.5T418-712q-4 12-14.5 18t-22.5 2q-58-17-115.5-2T165-636q-31 31-48 71t-17 85q0 44 17 84t48 71q43 43 100.5 58.5T381-268q12-4 22.5 2t14.5 18q4 12-2 22.5T398-211q-74 22-148 2.5T122-282q-40-40-61-91T40-480Z"
		/>
	</svg>
);
