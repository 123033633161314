import { Fragment } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import rgba from 'pkg/rgba';
import { useCurrentAccountUserIds } from 'pkg/identity';
import * as models from 'pkg/api/models';

import RelativeTime from 'components/RelativeDateTime';
import Icon from 'components/icon';

import * as iconStyles from 'components/icon/styles.css';

const Timestamp = styled(RelativeTime)`
	grid-area: timestamp;
	text-align: right;
	align-self: end;
	font-size: var(--font-size-sm);
	line-height: var(--font-line-height-base);
	color: var(--palette-gray-500);

	@media ${styles.breakpoint.small} {
		font-size: var(--font-size-xs);
	}
`;

const Reply = styled.div`
	grid-area: message;
	align-self: start;
	color: ${rgba(styles.palette.card.timestampColor)};
	font-size: 0.86rem;
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	position: relative;
	padding-right: 1rem;
	height: 2.4em;

	.${iconStyles.icon} {
		position: absolute;
		top: 1px;
		right: 0;
	}
`;

const UnreadLabel = styled.span`
	color: ${rgba(styles.palette.main.actionableLinkColor)};

	&::after {
		margin: 0 0.3rem;
		content: '•';
		color: ${rgba(styles.palette.icon.idleFillColor)};
		display: inline-block;
	}
`;

const NewChatMessagePlaceholder = styled.div`
	grid-area: message;
	align-self: flex-end;
	color: ${rgba(styles.palette.card.timestampColor)};
	font-size: 0.86rem;
	align-self: start;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-style: italic;
`;

function getLastMessage(messageContent: string, name: string) {
	switch (messageContent) {
		case 'joined':
			return t('{name} joined', {
				name,
			});
		case 'left':
			return t('{name} left', {
				name,
			});
	}
}

interface MostRecentReplyProps {
	unread: boolean;

	chat: models.chat.Chat;
}

function MostRecentReply({ chat, unread }: MostRecentReplyProps) {
	const activeUserIds = useCurrentAccountUserIds();
	const message = chat.lastMessage;
	const lastMessageUser = message?.user;

	const chatUser = models.chat.findMyChatUser(chat, activeUserIds);

	if (!message?.id) {
		return (
			<NewChatMessagePlaceholder>{t('New chat')}</NewChatMessagePlaceholder>
		);
	}

	if (!message || !lastMessageUser) {
		return null;
	}

	const lastMessageAuthorName = activeUserIds.includes(lastMessageUser.id)
		? t('You')
		: lastMessageUser.firstName;

	let lastMessageText = '';

	if (message.archivedAt) {
		lastMessageText = models.chatMessage.showMessageContent(chatUser, message);
	} else if (message.type === 'notice') {
		lastMessageText = getLastMessage(message.content, lastMessageAuthorName);
	} else if (message.attachmentId > 0) {
		lastMessageText = t('{name} sent an attachment', {
			name: lastMessageAuthorName,
		});
	} else {
		lastMessageText = `${lastMessageAuthorName}: ${message.content}`;
	}

	const isArchived = chat.isArchived || chatUser?.hasArchived;

	return (
		<Fragment>
			<Timestamp dateTime={message.createdAt} />
			<Reply>
				{unread && <UnreadLabel>{t('Unread')}</UnreadLabel>}
				<span>{lastMessageText}</span>
				{!isArchived && chatUser?.hasMuted && <Icon name="mute" />}
				{isArchived && <Icon name="archive" />}
			</Reply>
		</Fragment>
	);
}

export default MostRecentReply;
