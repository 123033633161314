import * as sdk from 'pkg/core/sdk';
import { CreateSubscriptionPayload } from 'pkg/api/endpoints/subscriptions';
import * as endpoints from 'pkg/api/endpoints/auto';

export interface IFetchAllFilters {
	created_from?: string;
	created_to?: string;
	customer_user_id?: string;
	associated_user_id?: string;
	product_id?: string;
	status?: string;
	next_invoice_due_date_from?: string;
	next_invoice_due_date_to?: string;
	recurring_interval?: string;
	recurring_interval_count?: string;
	end_from?: string;
	end_to?: string;
}

export interface IFetchAllQueryParams extends IFetchAllFilters {
	group_id: string;
	count?: string;
	page?: string;
	search?: string;
	sort_by?: string;
	sort_by_order?: string;
}

export const createBatch = (
	payload: CreateSubscriptionPayload
): Promise<Response> =>
	sdk.post(endpoints.Subscriptions.CreateBatch(), {}, payload);
