import React, {
	FC,
	useMemo,
	useReducer,
	useCallback,
	createContext,
	useContext,
	ReactNode,
} from 'react';

type CueIdType = number | string;

export enum CueGroupType {
	All,
	Highlight,
	Account,
}

export interface CueType {
	cueId: CueIdType;
	videoId?: number;
	sourceUrl?: string;
	title?: string;
	description?: string;
	annotations?: string;
	startsAt: number;
	startsAtMs: number;
	endsAt: number;
	endsAtMs: number;
	isHighlight?: boolean;
	isPrivate?: boolean;
}

export interface CueChangesType {
	title?: string;
	description?: string;
	startsAt?: number;
	startsAtMs?: number;
	endsAt?: number;
	endsAtMs?: number;
	isHighlight?: boolean;
	isPrivate?: boolean;
}

interface CueState {
	cuePoints?: CueType[];
	cueGroupType?: CueGroupType;
	cueIndex?: number;
	cueActive?: boolean;
	cueContextId?: number;
}

type CuePayload = CueType[] | CueIdType | string | boolean | null;

export enum CueAction {
	Reset = 'reset',
	CuePoints = 'cuePoints',
	CueGroupType = 'cueGroupType',
	CueIndex = 'cueIndex',
	CueActive = 'cueActive',
	CueContextId = 'cueContextId',
}

export type Action =
	| { type: CueAction.Reset; payload: CueState }
	| { type: CueAction.CuePoints; payload: CueState }
	| { type: CueAction.CueGroupType; payload: CueState }
	| { type: CueAction.CueIndex; payload: CueState }
	| { type: CueAction.CueActive; payload: CueState }
	| { type: CueAction.CueContextId; payload: CueState };

interface CueStateDispatch {
	state: CueState;
	dispatch?: React.Dispatch<Action>;
	emit?: (type: CueAction, payload: CuePayload) => void;
}

const initialState: CueState = {
	cuePoints: [],
	cueGroupType: null,
	cueIndex: -1,
	cueActive: false,
	cueContextId: 0,
};

const CueContext = createContext<CueStateDispatch>({
	state: initialState,
});

export interface CueHookResponse extends CueState {
	cueGroupType?: CueGroupType;
	currentCue?: CueType;
	prevCue?: CueType;
	nextCue?: CueType;

	reset: () => void;
	setCueId: (cueId: number) => void;
	setCuePoints: (
		cuePoints: CueType[],
		cueGroupType?: CueGroupType,
		cueId?: number
	) => void;
	setCueIndex: (cueIndex: number) => void;
	setCueActive: (isActive: boolean) => void;
	setCueContextId: (cueContextId: number) => void;
	updateCue: (cueId: CueIdType, nextCue: CueChangesType) => void;

	prev: () => void;
	next: () => void;
}

export const useCue = (cueId: number): CueType => {
	const { state } = useContext(CueContext);

	return state.cuePoints.find((cueType: CueType) => cueType.cueId === cueId);
};

export const useCueState = (): CueHookResponse => {
	const { state, emit } = useContext(CueContext);

	let currentCue = null,
		nextCue = null,
		prevCue = null;

	if (state.cuePoints.length > 0) {
		currentCue = state.cuePoints[state.cueIndex] ?? undefined;

		if (state.cueIndex > 0) {
			prevCue = state.cuePoints[state.cueIndex - 1];
		}

		if (state.cueIndex + 1 <= state.cuePoints.length) {
			nextCue = state.cuePoints[state.cueIndex + 1];
		}
	}

	const reset = (): void => emit(CueAction.Reset, null);

	const setCuePoints = (
		cuePoints: CueType[],
		cueGroupType: CueGroupType = CueGroupType.All,
		cueId?: number
	): void => {
		emit(CueAction.CuePoints, cuePoints);
		emit(CueAction.CueGroupType, cueGroupType);

		const cueIndex = cuePoints.findIndex(
			(cueType: CueType) => cueType.cueId === cueId
		);

		if (cueId > 0 && cueIndex >= 0) {
			emit(CueAction.CueIndex, cueIndex);
		}
	};

	const setCueIndex = (cueIndex: number): void =>
		emit(CueAction.CueIndex, cueIndex);

	const setCueId = (cueId: number): void => {
		const cueIndex = state.cuePoints.findIndex(
			(cueType: CueType) => cueType.cueId === cueId
		);

		if (cueIndex >= 0) {
			emit(CueAction.CueIndex, cueIndex);
		}
	};

	const setCueContextId = (cueIndex: number): void =>
		emit(CueAction.CueContextId, cueIndex);

	const prev = (): void => {
		if (!!state.cuePoints.length && state.cueIndex > 0) {
			emit(CueAction.CueIndex, state.cueIndex - 1);
		}
	};

	const next = (): void => {
		if (
			!!state.cuePoints.length &&
			state.cueIndex + 1 <= state.cuePoints.length
		) {
			emit(CueAction.CueIndex, state.cueIndex + 1);
		}
	};

	const setCueActive = (isActive: boolean): void =>
		emit(CueAction.CueActive, isActive);

	const updateCue = (cueId: CueIdType, nextCue: CueChangesType): void => {
		const cueIndex = state.cuePoints.findIndex(
			(cueType: CueType) => cueType.cueId === cueId
		);

		if (cueIndex >= 0) {
			const cuePoints = state.cuePoints;
			const prevCue = cuePoints[cueIndex];

			cuePoints[state.cueIndex] = {
				...prevCue,
				...nextCue,
			} as CueType;

			emit(CueAction.CuePoints, cuePoints);
			emit(CueAction.CueIndex, cueIndex);
		}
	};

	return {
		...state,
		currentCue,
		reset,

		setCueId,
		setCuePoints,
		setCueIndex,
		setCueActive,
		setCueContextId,
		updateCue,

		prevCue,
		prev,
		nextCue,
		next,
	} as CueHookResponse;
};

const reducer = (state: CueState, action: Action) => {
	const mutateState = (state: CueState, nextState: CueState): CueState => ({
		...state,
		...nextState,
	});

	const actionType = action.type;

	switch (actionType) {
		case CueAction.Reset:
			return { ...initialState };
		case CueAction.CuePoints:
			return mutateState(state, {
				...action.payload,
				cueIndex: 0,
			});
		case CueAction.CueIndex:
		case CueAction.CueActive:
		case CueAction.CueContextId:
		case CueAction.CueGroupType:
			return mutateState(state, action.payload);
		default:
			throw new Error(`Invalid action '${actionType}'.`);
	}
};

interface StateProviderProps {
	children: ReactNode;
}

const CueStateProvider: FC<StateProviderProps> = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState);

	const emit = useCallback(
		(type: CueAction, payload: CuePayload) => {
			dispatch({
				type,
				payload: {
					[type]: payload,
				},
			});
		},
		[dispatch]
	);

	const store: CueStateDispatch = useMemo(
		() => ({
			state,
			dispatch,
			emit,
		}),
		[state, dispatch, emit]
	);

	return <CueContext.Provider value={store}>{children}</CueContext.Provider>;
};

export default CueStateProvider;
