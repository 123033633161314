import { ReactNode } from 'react';

export const symbol_file_download_done: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m380-422 357-357q9-9 21-8.5t21 9.342q9 8.842 9 21T779-736L401-358q-9 9-21 9t-21-9L181-536q-9-8.8-8.5-20.9.5-12.1 9.342-21.1 8.842-9 21-9T224-578l156 156ZM230-160q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T230-220h500q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T730-160H230Z"
		/>
	</svg>
);
