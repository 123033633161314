import { ReactNode } from 'react';

export const symbol_contactless: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M285-480q0 12-2 24t-6 23q-4 12 .5 23t15.5 15q13 5 24.5-1t15.5-18q5-16 8.5-32.5T345-480q0-17-2.5-34t-8.5-33q-5-12-17-17.5t-24-.5q-11 4-15 15.5t0 22.5q4 11 5.5 23t1.5 24Zm143 0q0 26-3.5 51.5T413-378q-4 12 0 24t16 16q13 5 24.5-1.5T470-359q10-29 14-59.5t4-61.5q0-31-4-61t-14-59q-5-13-16-19.5t-24-1.5q-12 4-16.5 16t-.5 24q8 24 11.5 49.5T428-480Zm143 0q0 40-6 79t-20 77q-5 12-.5 24t16.5 17q12 5 23.5-1t16.5-18q16-43 23-88t7-90q0-45-7-89.5T601-657q-5-12-16.5-18t-23.5-1q-12 5-16.5 17t.5 24q14 37 20 76t6 79ZM480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm0-60q142 0 241-99.5T820-480q0-142-99-241t-241-99q-141 0-240.5 99T140-480q0 141 99.5 240.5T480-140Zm0-340Z"
		/>
	</svg>
);
