import { Dateable } from 'pkg/api/models/dateable';

export interface TaxRate extends Dateable {
	// This id comes as a string from the backend but we use it as a number in the frontend
	// there's a function called convertIdsToNumber in this file that does this.
	id: number;
	active: boolean;
	createdAt: number;
	description: string;
	display_name: string;
	inclusive: boolean;
	country: string;
	jurisdiction: string;
	state: string;
	percentage: number;
}

export function getAmountWithTaxRate(startAmount: number, taxRate: TaxRate) {
	return taxRate?.inclusive
		? (startAmount * taxRate.percentage) / (taxRate.percentage + 100)
		: startAmount * (taxRate.percentage / 100);
}

// Converts taxRates id to numbers, they come as strings from the backend.
// This was done in the class constructor in the old models.
export function convertIdsToNumber(taxRates: TaxRate[]) {
	return taxRates.map((taxRate) => {
		return {
			...taxRate,
			id: Number.parseInt(taxRate.id as unknown as string, 10),
		};
	});
}
