import { t } from '@transifex/native';

import TraverseRow from 'containers/group/settings/user/tree/TraverseRow';

import SearchRow from 'components/group/settings/user/tree/SearchRow';

import InlinePlaceholder from 'design/placeholders/inline';
import * as Table from 'design/table';

const TreeBase = ({
	groups,
	disabledGroups,
	isSearch,
	selected,
	onSelect,
	singleSelect = false,
}) => {
	if (isSearch && groups.size === 0) {
		return <InlinePlaceholder>{t(`No groups found.`)}</InlinePlaceholder>;
	}

	const tableColumns = [
		{
			content: t(`Name`),
		},
	];

	if (!isSearch) {
		tableColumns.push({
			content: '',
			width: 'max-content',
		});
	}

	return (
		<Table.Table columns={tableColumns}>
			{groups
				.toList()
				.map((group) =>
					isSearch ? (
						<SearchRow
							key={group.id}
							group={group}
							disabledGroups={disabledGroups}
							isSelected={selected.includes(group.id)}
							select={onSelect}
							singleSelect={singleSelect}
						/>
					) : (
						<TraverseRow
							key={group.id}
							group={group}
							disabledGroups={disabledGroups}
							selected={selected}
							select={onSelect}
							singleSelect={singleSelect}
						/>
					)
				)}
		</Table.Table>
	);
};

export default TreeBase;
