import { ReactNode } from 'react';

export const symbol_speed_0_7x: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m689-434-77 139q-4.286 7.059-10.714 11.029Q594.857-280 587.143-280 570-280 562-294t0-28l91-164-85-152q-8-14-.5-28t24.643-14q7.714 0 14.143 3.971Q612.714-672.059 617-665l72 128 71-128q4.286-7.059 10.714-11.029Q777.143-680 784.857-680 802-680 810-666t0 28l-85 152 92 165q8 14 0 27.5T791.759-280Q784-280 778-283.706T768-294l-79-140ZM340.684-278q-14 0-22.842-11Q309-300 313-314l82-309H255q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T255-683h140q24 0 42 16t18 40q0 3-2 13l-84 315q-2 10-10 15.5t-18.316 5.5Zm-150.859 1Q177-277 168.5-285.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Z"
		/>
	</svg>
);
