const userEdit = {
	name: 'user-edit',
	objects: [
		{
			type: 'path',
			attributes: {
				d: 'M9.9876 11.4244C12.2614 11.4244 14.1047 9.58118 14.1047 7.3074C14.1047 5.03361 12.2614 3.19035 9.9876 3.19035C7.71382 3.19035 5.87055 5.03361 5.87055 7.3074C5.87055 9.58118 7.71382 11.4244 9.9876 11.4244ZM9.9876 10.1244C11.5434 10.1244 12.8047 8.86321 12.8047 7.3074C12.8047 5.75158 11.5434 4.49035 9.9876 4.49035C8.43179 4.49035 7.17055 5.75158 7.17055 7.3074C7.17055 8.86321 8.43179 10.1244 9.9876 10.1244Z',
				fillRule: 'evenodd',
				clipRule: 'evenodd',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M3 17.7583C3 14.8254 5.37763 12.4478 8.31058 12.4478H11.6646C12.2448 12.4478 12.8033 12.5408 13.326 12.7128L12.6465 13.8688C12.3323 13.7898 12.0033 13.7478 11.6646 13.7478H8.31058C6.0956 13.7478 4.3 15.5434 4.3 17.7583H3Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M17.9432 12.3341C18.5766 12.269 19.1658 12.5521 19.6995 13.0857C20.2364 13.6227 20.523 14.2133 20.4602 14.8483C20.4008 15.4475 20.0428 15.917 19.6995 16.2603L19.2872 16.6726C19.2358 16.7512 19.1691 16.8171 19.0924 16.8674L16.1665 19.7934C16.0279 19.9388 15.8555 20.046 15.7115 20.118C15.5605 20.1935 15.3785 20.2612 15.1975 20.2898L15.1863 20.2915L13.8377 20.4812C13.4158 20.5412 12.9846 20.43 12.6749 20.1203C12.3651 19.8105 12.2538 19.3802 12.3139 18.9583L12.5054 17.5977C12.5344 17.4139 12.6034 17.2332 12.6786 17.0846C12.7538 16.9359 12.8598 16.7709 12.9949 16.6358L16.0163 13.6143C16.0222 13.6082 16.0283 13.6021 16.0344 13.5962L16.5349 13.0957C16.8783 12.7523 17.3468 12.3953 17.9432 12.3341ZM18.7449 15.3058L18.5326 15.518C17.9758 15.2647 17.5311 14.8175 17.281 14.2588L17.4895 14.0503C17.7561 13.7837 17.9476 13.6907 18.0812 13.677C18.1778 13.6671 18.3885 13.684 18.7449 14.0403C19.108 14.4034 19.1264 14.6177 19.1167 14.7153C19.1035 14.8486 19.0115 15.0391 18.7449 15.3058ZM13.9495 17.5904L16.2841 15.2557C16.5984 15.7667 17.0275 16.1974 17.5371 16.5135L15.2049 18.8457L15.1919 18.8591C15.19 18.8607 15.1852 18.8646 15.1768 18.8704C15.1599 18.8821 15.1363 18.8963 15.1079 18.9105C15.0795 18.9247 15.0514 18.9364 15.0267 18.9449C15.0147 18.949 15.0047 18.9519 14.9972 18.9539L14.9885 18.9559L13.651 19.1442L13.8392 17.8069C13.84 17.8031 13.8428 17.7916 13.8493 17.7728C13.8574 17.7496 13.8689 17.7224 13.8833 17.694C13.8976 17.6656 13.9127 17.6403 13.9266 17.6201C13.9403 17.6002 13.9495 17.5904 13.9495 17.5904Z',
				fillRule: 'evenodd',
				clipRule: 'evenodd',
			},
		},
	],
};

export default userEdit;
