import styled from 'styled-components';

import * as palette from 'pkg/config/palette';
import { small } from 'pkg/config/breakpoints';

import rgba from 'pkg/rgba';

export const PlayerItem = styled.div`
	display: flex;
	flex-flow: column wrap;
	align-items: center;
	justify-content: center;
`;

export const PlayerNameWrapper = styled.p`
	color: ${palette.white};
	margin: 0;
	line-height: 1;
	font-size: 0.6rem;
	text-transform: uppercase;
	letter-spacing: 0.1px;
	max-width: 80px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-weight: var(--font-weight-normal);

	@media ${small} {
		max-width: 50px;
	}
`;

export const PlayerPositionName = styled.span`
	color: ${rgba(palette.white, 0.9)};
	font-size: 0.58rem;
	margin: 0 0 1px;
	line-height: 1;
	letter-spacing: 0.4px;
	display: block;
	font-weight: var(--font-weight-normal);
	width: 100%;
`;
