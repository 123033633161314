import { ReactNode } from 'react';

export const symbol_keyboard_return: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m236-449 145 145q9 9 8.5 21t-9.5 21q-9 9-21.5 9t-21.5-9L141-458q-5-5-7-10.133-2-5.134-2-11Q132-485 134-490q2-5 7-10l197-197q9-9 21.5-9t21.5 9q9 9 9 21.5t-9 21.5L235-508h545v-142q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T840-650v141q0 24.75-17.625 42.375T780-449H236Z"
		/>
	</svg>
);
