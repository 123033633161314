import * as React from 'react';

import Default, { DefaultProps } from 'components/missing-entities/Default';
import WithImage from 'components/missing-entities/Image';

type CombinedProps = DefaultProps & {
	imgSrc?: string;
};

const Component: React.FC<React.PropsWithChildren<CombinedProps>> = ({
	centered = false,
	imgSrc = '',
	children,
}) => {
	if (imgSrc) {
		return (
			<WithImage centered={centered} imgSrc={imgSrc}>
				{children}
			</WithImage>
		);
	}

	return <Default centered={centered}>{children}</Default>;
};

export default Component;
