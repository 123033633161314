// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.d3qJQ6WtQLLDMhqz41i3 {
	display: grid;
	grid-template-columns: 1fr auto;
	grid-gap: var(--spacing-3);
}

.d3qJQ6WtQLLDMhqz41i3 button {
		height: 100%;
	}

@-webkit-keyframes uBIDGzDzqwZ3y098L5SP {
	0% {
		-webkit-transform: translateX(0);
		        transform: translateX(0);
	}

	15% {
		-webkit-transform: translateX(-5px);
		        transform: translateX(-5px);
	}

	30% {
		-webkit-transform: translateX(5px);
		        transform: translateX(5px);
	}

	45% {
		-webkit-transform: translateX(-5px);
		        transform: translateX(-5px);
	}

	60% {
		-webkit-transform: translateX(5px);
		        transform: translateX(5px);
	}

	75% {
		-webkit-transform: translateX(-3px);
		        transform: translateX(-3px);
	}

	100% {
		-webkit-transform: translateX(0);
		        transform: translateX(0);
	}
}

@keyframes uBIDGzDzqwZ3y098L5SP {
	0% {
		-webkit-transform: translateX(0);
		        transform: translateX(0);
	}

	15% {
		-webkit-transform: translateX(-5px);
		        transform: translateX(-5px);
	}

	30% {
		-webkit-transform: translateX(5px);
		        transform: translateX(5px);
	}

	45% {
		-webkit-transform: translateX(-5px);
		        transform: translateX(-5px);
	}

	60% {
		-webkit-transform: translateX(5px);
		        transform: translateX(5px);
	}

	75% {
		-webkit-transform: translateX(-3px);
		        transform: translateX(-3px);
	}

	100% {
		-webkit-transform: translateX(0);
		        transform: translateX(0);
	}
}

.uBIDGzDzqwZ3y098L5SP {
	-webkit-animation: uBIDGzDzqwZ3y098L5SP 0.4s ease-in-out;
	        animation: uBIDGzDzqwZ3y098L5SP 0.4s ease-in-out;
	-webkit-animation-iteration-count: 1;
	        animation-iteration-count: 1;
}

.scS8jGQ7JIsJaZBOUtSQ {
	padding-bottom: var(--spacing-3);
}

.cx9KdhhtfiysRDAtIm7e {
	margin-bottom: var(--spacing-4);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	gap: var(--spacing-4);
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
}

.TMC6xF5gBTLSW5B__GOA {
	padding-bottom: var(--spacing-1);
}
`, "",{"version":3,"sources":["webpack://./routes/forms/registration/form/products/discount/styles.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,+BAA+B;CAC/B,0BAA0B;AAK3B;;AAHC;EACC,YAAY;CACb;;AAGD;CACC;EACC,gCAAwB;UAAxB,wBAAwB;CACzB;;CAEA;EACC,mCAA2B;UAA3B,2BAA2B;CAC5B;;CAEA;EACC,kCAA0B;UAA1B,0BAA0B;CAC3B;;CAEA;EACC,mCAA2B;UAA3B,2BAA2B;CAC5B;;CAEA;EACC,kCAA0B;UAA1B,0BAA0B;CAC3B;;CAEA;EACC,mCAA2B;UAA3B,2BAA2B;CAC5B;;CAEA;EACC,gCAAwB;UAAxB,wBAAwB;CACzB;AACD;;AA5BA;CACC;EACC,gCAAwB;UAAxB,wBAAwB;CACzB;;CAEA;EACC,mCAA2B;UAA3B,2BAA2B;CAC5B;;CAEA;EACC,kCAA0B;UAA1B,0BAA0B;CAC3B;;CAEA;EACC,mCAA2B;UAA3B,2BAA2B;CAC5B;;CAEA;EACC,kCAA0B;UAA1B,0BAA0B;CAC3B;;CAEA;EACC,mCAA2B;UAA3B,2BAA2B;CAC5B;;CAEA;EACC,gCAAwB;UAAxB,wBAAwB;CACzB;AACD;;AAEA;CACC,wDAAkC;SAAlC,gDAAkC;CAClC,oCAA4B;SAA5B,4BAA4B;AAC7B;;AAEA;CACC,gCAAgC;AACjC;;AAEA;CACC,+BAA+B;CAC/B,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,qBAAqB;CACrB,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;AACpB;;AAEA;CACC,gCAAgC;AACjC","sourcesContent":[".field_wrapper {\n\tdisplay: grid;\n\tgrid-template-columns: 1fr auto;\n\tgrid-gap: var(--spacing-3);\n\n\tbutton {\n\t\theight: 100%;\n\t}\n}\n\n@keyframes wiggle {\n\t0% {\n\t\ttransform: translateX(0);\n\t}\n\n\t15% {\n\t\ttransform: translateX(-5px);\n\t}\n\n\t30% {\n\t\ttransform: translateX(5px);\n\t}\n\n\t45% {\n\t\ttransform: translateX(-5px);\n\t}\n\n\t60% {\n\t\ttransform: translateX(5px);\n\t}\n\n\t75% {\n\t\ttransform: translateX(-3px);\n\t}\n\n\t100% {\n\t\ttransform: translateX(0);\n\t}\n}\n\n.wiggle {\n\tanimation: wiggle 0.4s ease-in-out;\n\tanimation-iteration-count: 1;\n}\n\n.incorrectInfoBox {\n\tpadding-bottom: var(--spacing-3);\n}\n\n.discount_title_wrapper {\n\tmargin-bottom: var(--spacing-4);\n\tdisplay: flex;\n\tgap: var(--spacing-4);\n\talign-items: center;\n}\n\n.discount_title {\n\tpadding-bottom: var(--spacing-1);\n}\n"],"sourceRoot":""}]);
// Exports
export var field_wrapper = `d3qJQ6WtQLLDMhqz41i3`;
export var wiggle = `uBIDGzDzqwZ3y098L5SP`;
export var incorrectInfoBox = `scS8jGQ7JIsJaZBOUtSQ`;
export var discount_title_wrapper = `cx9KdhhtfiysRDAtIm7e`;
export var discount_title = `TMC6xF5gBTLSW5B__GOA`;
export default ___CSS_LOADER_EXPORT___;
