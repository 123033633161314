import { ReactNode } from 'react';

export const symbol_spatial_speaker: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M311-80q-112 0-191.5-78.5T40-349q0-13 8.675-21.5 8.676-8.5 21.5-8.5Q83-379 91.5-370.375T100-349q0 88 61.717 148.5Q223.435-140 311-140q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q341-97 332.5-88.5T311-80Zm338 0q-13 0-21.5-8.675-8.5-8.676-8.5-21.5 0-12.825 8.625-21.325T649-140q87.565 0 149.282-60.5Q860-261 860-349q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T920-349q0 112-79.5 190.5T649-80ZM311-220q-54 0-92.5-37.5T180-349q0-13 8.675-21.5 8.676-8.5 21.5-8.5 12.825 0 21.325 8.625T240-349q0 13.647 5.365 26.157 5.366 12.51 15.5 22.176Q271-291 283.5-285.5 296-280 311-280q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.5 21.325T311-220Zm338 0q-13 0-21.5-8.675-8.5-8.676-8.5-21.5 0-12.825 8.625-21.325T649-280q15 0 27.5-5.5t22.635-15.167q10.134-9.666 15.5-22.176Q720-335.353 720-349q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T780-349q0 54-38.5 91.5T649-220ZM384-359q-24.75 0-42.375-17.625T324-419v-361q0-24.75 17.625-42.375T384-840h193q24.75 0 42.375 17.625T637-780v361q0 24.75-17.625 42.375T577-359H384Zm0-60h193v-361H384v361Zm0 0h193-193Z"
		/>
	</svg>
);
