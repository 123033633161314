import styled, { css } from 'styled-components';
import { t } from '@transifex/native';

import { palette } from 'pkg/config/styles';

import Button from 'design/button';

const ListControlsSelector = styled.div`
	display: grid;
	grid-auto-flow: column;
	grid-gap: 5px;
	align-items: center;
	justify-content: end;
`;

const ListControls = styled.div`
	padding: 10px;
	background: ${palette.gray[100]};
	border-bottom: 1px solid ${palette.gray[300]};
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	justify-content: center;
	position: sticky;
	top: 0;
	z-index: 3;

	${({ theme }) =>
		theme.darkMode &&
		css`
			background: ${palette.gray[800]};
			border-bottom-color: ${palette.gray[600]};
		`}
`;

const ListMeta = styled.div`
	padding-left: 10px;
	font-weight: var(--font-weight-normal);
	font-size: var(--font-size-sm);
	color: ${palette.gray[600]};
`;

interface ListControlsProps {
	showControls: boolean;
	multipleChoice: boolean;
	numSelectedUsers: number;

	handleDeselectAll: () => void;
	handleSelectAll: () => void;
}

export default function ListSelectControls({
	showControls,
	multipleChoice,
	numSelectedUsers,
	handleDeselectAll,
	handleSelectAll,
}: ListControlsProps) {
	if (!showControls || !multipleChoice) {
		return null;
	}

	return (
		<ListControls>
			<ListMeta>
				{t('{num} selected users', {
					num: numSelectedUsers,
				})}
			</ListMeta>
			<ListControlsSelector>
				{numSelectedUsers > 0 && (
					<Button
						transparent
						caution
						icon="check_box_outline_blank"
						onClick={handleDeselectAll}>
						{t('Clear selection')}
					</Button>
				)}
				<Button transparent icon="check_box" onClick={handleSelectAll}>
					{t('Select all')}
				</Button>
			</ListControlsSelector>
		</ListControls>
	);
}
