import { ReactNode } from 'react';

export const symbol_directions_walk: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M419-378 345-63q-2 11-10.328 17-8.327 6-19.189 6Q301-40 291.5-51T285-76l109-550-101 43v103q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Q250-450 241-458.913q-9-8.912-9-22.087v-122q0-9.333 4.875-16.722Q241.75-627.111 250-631l174.292-73.219Q438-710 453.5-711.5 469-713 484-710q17 3 29.5 10.875T534-679l42 66q16 24 35.5 43t42.5 33q17 10 34.5 16t36.5 9q12.108 2.25 20.054 10.875Q753-492.5 753-479.75t-8.781 21.25Q735.439-450 723-452q-59-7-105.5-35.5T533-568l-38 152 82 74q5 4.552 7.5 10.241 2.5 5.69 2.5 11.759v250q0 12.75-8.675 21.375Q569.649-40 556.825-40 544-40 535.5-48.625T527-70v-210l-108-98Zm121.08-376q-30.08 0-51.58-21.42-21.5-21.421-21.5-51.5 0-30.08 21.42-51.58 21.421-21.5 51.5-21.5 30.08 0 51.58 21.42 21.5 21.421 21.5 51.5 0 30.08-21.42 51.58-21.421 21.5-51.5 21.5Z"
		/>
	</svg>
);
