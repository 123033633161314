import { ReactNode } from 'react';

export const symbol_shift_lock: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M320-250v-180H182q-19.362 0-27.181-17Q147-464 159-479l298-372q8.88-11 22.94-11T503-851l298 372q12 15 4.181 32T778-430H640v180q0 12.75-8.625 21.375T610-220H350q-12.75 0-21.375-8.625T320-250Zm60-30h200v-210h133L480-786 247-490h133v210Zm100-253ZM170-80q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T170-140h620q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q820-97 811.375-88.5T790-80H170Z"
		/>
	</svg>
);
