import { ReactNode } from 'react';

export const symbol_book_2: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M220-320q15-10 32.5-15t37.5-5h30v-480h-30q-29.167 0-49.583 20.417Q220-779.167 220-750v430Zm160-20h360v-480H380v480Zm-160 20v-500 500Zm70 240q-53.857 0-91.929-38.071Q160-156.143 160-210v-540q0-53.857 38.071-91.929Q236.143-880 290-880h450q24.75 0 42.375 17.625T800-820v515q0 9.143-5 16.571Q790-281 781-277q-19 8-30 25.767-11 17.766-11 41 0 23.233 10.933 40.824Q761.867-151.818 781-144q9 4 14 13t5 21q0 12.439-8.625 21.22Q782.75-80 770-80H290Zm-.459-60H699q-9-15-14-33t-5-37q0-20 5-37.5t15-32.5H289.607Q261-280 240.5-259.583 220-239.167 220-210q0 29 20.5 49.5t49.041 20.5Z"
		/>
	</svg>
);
