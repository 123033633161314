import { ReactNode } from 'react';

export const symbol_self_care: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M660-40q-8.5 0-14.25-5.75T640-60q0-8.5 5.75-14.25T660-80h140v-60H660q-8.5 0-14.25-5.75T640-160q0-8.5 5.75-14.25T660-180h140v-60H660q-8.5 0-14.25-5.75T640-260q0-8.5 5.75-14.25T660-280h140v-60H660q-8.5 0-14.25-5.75T640-360q0-8.5 5.75-14.25T660-380h140v-60H660q-8.5 0-14.25-5.75T640-460q0-8.5 5.75-14.25T660-480h140v-60H660q-8.5 0-14.25-5.75T640-560q0-8.5 5.75-14.25T660-580h160q24.75 0 42.375 17.625T880-520v420q0 24.75-17.625 42.375T820-40H660ZM319.823-340Q394-340 447-410.871t53-169Q500-678 447.177-749q-52.823-71-127-71Q246-820 193-749.129t-53 169Q140-482 192.823-411q52.823 71 127 71ZM320-40q-39 0-63.5-29T237-136l17-155q-76-27-125-106.5T80-580q0-125 70-212.5T320-880q100 0 170 87.5T560-580q0 103-49.5 182.5T385-291l18 155q5 38-19.5 67T320-40Z"
		/>
	</svg>
);
