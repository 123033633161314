import { ReactNode } from 'react';

export const symbol_hevc: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M120-384v-192q0-10.4 6.8-17.2 6.8-6.8 17.2-6.8 10.4 0 17.2 6.8 6.8 6.8 6.8 17.2v63h55v-63q0-10.4 6.8-17.2 6.8-6.8 17.2-6.8 10.4 0 17.2 6.8 6.8 6.8 6.8 17.2v192q0 10.4-6.8 17.2-6.8 6.8-17.2 6.8-10.4 0-17.2-6.8-6.8-6.8-6.8-17.2v-81h-55v81q0 10.4-6.8 17.2-6.8 6.8-17.2 6.8-10.4 0-17.2-6.8-6.8-6.8-6.8-17.2Zm204 1v-195q0-9 7-15.5t16-6.5h81q10.4 0 17.2 6.8 6.8 6.8 6.8 17.2 0 10.4-6.8 17.2-6.8 6.8-17.2 6.8h-56v39h56q10.4 0 17.2 6.8 6.8 6.8 6.8 17.2 0 10.4-6.8 17.2-6.8 6.8-17.2 6.8h-56v57h56q10.4 0 17.2 6.8 6.8 6.8 6.8 17.2 0 10.4-6.8 17.2-6.8 6.8-17.2 6.8h-81q-9 0-16-7t-7-16Zm201-9-34-180q-2-11 5.5-19.5t18.768-8.5q8.854 0 15.293 6T539-580l32 171 31-171q2-8 8.439-14t15.293-6q11.268 0 18.768 9t5.5 20l-34 179q-2.327 14.049-13.184 23.024Q591.959-360 578-360h-15q-13.959 0-24.816-8.976Q527.327-377.951 525-392Zm198 32q-15 0-24.5-9.5T689-394v-172q0-15 9.5-24.5T723-600h83q15 0 24.5 9.5t9.5 24.263V-548q0 10.4-6.8 17.2-6.8 6.8-17.2 6.8-10.4 0-17.2-6.8-6.8-6.8-6.8-17.2v-4h-55v144h55v-1q0-10.4 6.8-17.2 6.8-6.8 17.2-6.8 10.4 0 17.2 6.8 6.8 6.8 6.8 17.2v15q0 15-9.5 24.5T806-360h-83Z"
		/>
	</svg>
);
