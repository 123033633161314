import { Record } from 'immutable';
import { schema } from 'normalizr';

import Sequence from 'pkg/models/video_sequence';
import Video from 'pkg/models/video';

export const playlistSequenceId = (
	playlistSequence: PlaylistSequence
): string =>
	`p${playlistSequence.videoPlaylistId}s${playlistSequence.videoSequenceId}`;

export const videoPlaylistSequence = new schema.Entity(
	'videoPlaylistSequences',
	{
		videoSequence: Sequence.normalizr(),
	},
	{
		idAttribute: playlistSequenceId,
	}
);

interface PlaylistSequence {
	order: number;
	videoPlaylistId: number;
	videoSequenceId: number;
	videoSequence: Sequence;
	video?: Video;
	createdAt?: number;
}

export interface VideoPlaylistSequenceResponse {
	order: number;
	videoPlaylistId: number;
	videoSequenceId: number;
	videoSequence: any;
	createdAt?: number;
}

export interface VideoPlaylistSequencePayload {
	order: number;
}

const videoPlaylistSequenceProps: PlaylistSequence = {
	order: 0,
	videoPlaylistId: 0,
	videoSequenceId: 0,
	videoSequence: null,
	video: null,
};

class VideoPlaylistSequence
	extends Record(videoPlaylistSequenceProps, 'VideoPlaylistSequenceRecord')
	implements PlaylistSequence
{
	static normalizr(): schema.Entity {
		return videoPlaylistSequence;
	}

	get id(): string {
		return playlistSequenceId(this);
	}

	valid(): boolean {
		return !!this.get('videoPlaylistId') && !!this.get('videoSequenceId');
	}
}

export default VideoPlaylistSequence;
