import { ReactNode } from 'react';

export const symbol_file_copy: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M780-160H260q-24 0-42-18t-18-42v-640q0-24 18-42t42-18h323q12.444 0 23.722 5T626-902l196 196q8 8 13 19.278 5 11.278 5 23.722v443q0 24-18 42t-42 18Zm0-502H623q-18.75 0-31.875-13.125T578-707v-153H260v640h520v-442ZM140-40q-24 0-42-18t-18-42v-589q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-689v589h468q12.75 0 21.375 8.675Q638-82.649 638-69.825 638-57 629.375-48.5T608-40H140Zm120-820v198-198 640-640Z"
		/>
	</svg>
);
