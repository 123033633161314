import { ReactNode } from 'react';

export const symbol_frame_person_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M812-62.914 794-80H678q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T678-140h56L634-240H270q-12.75 0-21.375-8.625T240-270v-46q0-20 10-36.5t27-29.5q33-26 80-39.5t79-16.5L140-734v56q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Q97-648 88.5-656.625T80-678v-116l-33-33q-9-9.067-8.5-21.533Q39-861 48.053-870q9.052-9 21.5-9Q82-879 91-870l764 765q9 9 9 21t-9.053 21q-9.052 9-21.5 9Q821-54 812-62.914ZM574-300l-80-80q-58 0-105 13t-89 39v28h274Zm26-300q0 32-15 58t-39 42l-44-44q17-7 27.5-21.5T540-600q0-25.5-17.25-42.75T480-660q-20 0-34.5 10.5T424-622l-44-44q16-24 42-39t58-15q50 0 85 35t35 85Zm280 318v116l-60-60v-56q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T880-282ZM140-80q-24 0-42-18t-18-42v-142q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-282v142h142q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q312-97 303.375-88.5T282-80H140Zm680-598v-142H678q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T678-880h142q24 0 42 18t18 42v142q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T820-678Zm-357 95Zm31 283ZM166-880h116q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T282-820h-56l-60-60Z"
		/>
	</svg>
);
