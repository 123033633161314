import { ReactNode } from 'react';

export const symbol_accessibility_new: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480.08-734q-30.08 0-51.58-21.42-21.5-21.421-21.5-51.5 0-30.08 21.42-51.58 21.421-21.5 51.5-21.5 30.08 0 51.58 21.42 21.5 21.421 21.5 51.5 0 30.08-21.42 51.58-21.421 21.5-51.5 21.5ZM373-110v-503q-58-5-113.5-11.5T153-642q-14-3-21-14t-4-24q3-13 15-19t26-3q76 16 152 22.5t159 6.5q83 0 159-6.5T791-702q14-3 26 3t15 19q3 13-4 24t-21 14q-51 11-106.5 17.5T587-613v503q0 12.75-8.675 21.375Q569.649-80 556.825-80 544-80 535.5-88.625T527-110v-230h-94v230q0 12.75-8.675 21.375Q415.649-80 402.825-80 390-80 381.5-88.625T373-110Z"
		/>
	</svg>
);
