import * as models from 'pkg/api/models';

interface OrganizationUserProfileNotesProps {
	user: models.user.User;
}

export default function OrganizationUserProfileNotes({
	user,
}: OrganizationUserProfileNotesProps): JSX.Element {
	return <p>Showing Notes for {models.user.fullName(user)}</p>;
}
