import styled from 'styled-components';

import * as palette from 'pkg/config/palette';

import rgba from 'pkg/rgba';

import Icon from 'components/icon';

import * as iconStyles from 'components/icon/styles.css';

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	margin: 5px 0 9px -1px;
	color: ${rgba(palette.gray[600], 0.85)};
	font-size: 13px;
	font-weight: var(--font-weight-normal);

	span {
		display: flex;
		align-items: center;
		margin-right: 10px;

		&:last-of-type {
			margin-right: 0;
		}

		.${iconStyles.icon} {
			font-size: 20px;
			margin-right: 2px;
		}
	}
`;

const InfoItem = ({ icon, min, max, type }) => {
	let value = '0';

	if (!max) {
		value = min;
	}

	if (min && max) {
		value = `${min}-${max}`;
	}

	return (
		<span>
			<Icon name={icon} />
			{value}
			{type ? ` ${type}` : ''}
		</span>
	);
};

export default ({ item, onClick }) => {
	return (
		<Wrapper onClick={onClick}>
			{item.minTime > 0 && (
				<InfoItem icon="duration" min={item.get('minTime')} type="min" />
			)}
			{item.minParticipants > 0 && (
				<InfoItem icon="nav-members" min={item.get('minParticipants')} />
			)}
			{item.minAge > 0 && (
				<InfoItem
					icon="age"
					min={item.get('minAge')}
					max={item.get('maxAge')}
				/>
			)}
			{item.areaWidth > 0 && (
				<InfoItem
					icon="area"
					min={item.get('areaWidth')}
					max={item.get('areaLength')}
					type="m"
				/>
			)}
		</Wrapper>
	);
};
