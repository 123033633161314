import { createElement, memo } from 'react';

import * as models from 'pkg/api/models';
import { useOrganizationIdentity } from 'pkg/identity/organization';

import Avatar from 'components/avatar';

interface AvatarsProps {
	item: any;

	chat: models.chat.Chat;
	myChatUser: models.chatUser.ChatUser;
}

interface UserContainerProps {
	item: any;

	user: models.user.User;
}

const UserContainer = memo(({ user, item }: UserContainerProps) => {
	if (!user) {
		return null;
	}

	return createElement(item, { user, item });
});

const Avatars = memo(({ chat, item, myChatUser }: AvatarsProps) => {
	const { users } = useOrganizationIdentity();
	const activeUserIds = users.map((u) => u.id);

	const chatUsers = models.chatUser
		.filterDeleted(chat.users)
		.filter(({ userId }) => !activeUserIds.includes(userId));

	if (chatUsers.length === 0 && myChatUser) {
		const avatars = <UserContainer item={Avatar} user={myChatUser.user} />;

		return createElement(item, { numberOfAvatars: 1 }, [avatars]);
	}

	const avatars = chatUsers
		.slice(0, 3)
		.map((chatUser) => (
			<UserContainer
				key={chatUser.user.id}
				item={Avatar}
				user={chatUser.user}
			/>
		));

	const numberOfAvatars = avatars.length;

	return createElement(item, { numberOfAvatars }, [avatars]);
});

export default Avatars;
