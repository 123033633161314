import { ReactNode } from 'react';

export const symbol_find_in_page: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M220-80q-24 0-42-18t-18-42v-680q0-24 18-42t42-18h345q13.279 0 25.082 5.431T610-859l175 201q8 8 11.5 18.075T800-619v471q0 20.106-18.5 27.553Q763-113 749-127L562-314q-20 12-39.683 17-19.682 5-42.317 5-62 0-104.5-42.5T333-439q0-62 42.5-104.5T480-586q62 0 104.5 42.5T627-439q0 23-6.5 44T601-358l139 139v-403L568-820H220v680h420q14.5 0 21.75 9.318t7.25 20.5Q669-99 661.75-89.5 654.5-80 640-80H220Zm260-272q38 0 62.5-29.5T567-450q0-33-26-54.5T480-526q-35 0-61 21.5T393-450q0 39 24.5 68.5T480-352Zm0-98Zm0 0Z"
		/>
	</svg>
);
