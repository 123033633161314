import { ReactNode } from 'react';

export const symbol_sentiment_stressed: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m480-338 49 49q5 5 11 5t11-5l49-49 18 18q9 9 21 9t21-9q9-9 9-21t-9-21l-49-49q-5-5-11-5t-11 5l-49 49-49-49q-5-5-11-5t-11 5l-49 49-49-49q-5-5-11-5t-11 5l-49 49q-9 9-9 21t9 21q9 9 21 9t21-9l18-18 49 49q5 5 11 5t11-5l49-49ZM339-560l-52 38q-10 8-12.5 20t5.5 22q8 10 20 12.5t22-5.5l102-75q6-5 6-12t-6-12l-102-75q-10-8-22-5.5T280-640q-8 10-6 22t13 20l52 38Zm282 0 52-38q10-8 12.5-20t-5.5-22q-8-10-20-12.5t-22 5.5l-102 75q-6 5-6 12t6 12l102 75q10 8 22 5.5t20-12.5q8-10 6-22t-13-20l-52-38ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-400Zm0 340q142 0 241-99t99-241q0-142-99-241t-241-99q-142 0-241 99t-99 241q0 142 99 241t241 99Z"
		/>
	</svg>
);
