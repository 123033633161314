import { ReactNode } from 'react';

export const symbol_arrow_or_edge: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M270-235v-285H100q-24.75 0-42.375-17.625T40-580v-230q0-12.75 8.675-21.375Q57.351-840 70.175-840 83-840 91.5-831.375T100-810v230h170q24.75 0 42.375 17.625T330-520v285l53-52q9-8 21-8.5t21 8.109q9 9.391 9 21.491t-9 20.9L321-141q-9 9-21 9t-21-9L175-245q-9-9-9-21t9-21q9-9 21.5-9t21.5 9l52 52Zm419 0 53-52q9-8 21-8.5t21 8.109q9 9.391 9 21.491t-9 20.9L680-141q-9 9-21 9t-21-9L534-245q-9-9-9-21t9-21q9-9 21.5-9t21.5 9l52 52v-285q0-24.75 17.625-42.375T689-580h171v-230q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T920-810v230q0 24.75-17.625 42.375T860-520H689v285Z"
		/>
	</svg>
);
