import { ReactNode } from 'react';

export const symbol_airline_stops: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M450-260q-8-144-102-249T116-628q-14-2-25-10t-11-22q0-13 9.5-21.5T112-689q125 10 224 86.5T480-407q35-96 102.5-170T740-700H610q-13 0-21.5-8.5T580-730q0-13 8.5-21.5T610-760h200q13 0 21.5 8.5T840-730v200q0 13-8.5 21.5T810-500q-13 0-21.5-8.5T780-530v-123q-114 59-188 162t-82 231h50q13 0 21.5 8.5T590-230q0 13-8.5 21.5T560-200H400q-13 0-21.5-8.5T370-230q0-13 8.5-21.5T400-260h50Z"
		/>
	</svg>
);
