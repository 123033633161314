import { useFormContext } from 'react-hook-form';
import { t } from '@transifex/native';

import { Statuses } from 'pkg/models/form';

import * as models from 'pkg/api/models';

import * as Input from 'components/form/inputs';
import Section from 'components/form/Section';
import Column from 'components/layout/column';

interface AvailabilityProps {
	data: { status: Statuses; maxSubmissions: number; forceAuth: boolean };
	statuses: models.form.Statuses[];
}

const Availability: React.FC<React.PropsWithChildren<AvailabilityProps>> = ({
	data,
	statuses,
}) => {
	const methods = useFormContext();
	const showMaxSubmissions = methods.watch(
		'limitResponses',
		data.maxSubmissions > 0 ? true : false
	);

	const getTranslatedStatusDescription = (
		status: models.form.Statuses
	): string => {
		switch (status) {
			case Statuses.Open:
				return t('The registration is open to submissions');
			case Statuses.Draft:
				return t('A draft registration is only visible to preview');
			case Statuses.Closed:
				return t('The registration is closed to new submissions');
			default:
				return status;
		}
	};

	return (
		<Section
			hideDivider
			icon="key"
			title={`6. ${t('Availability')}`}
			description={t('Who is this registration available to?')}>
			<Column>
				<Column>
					<Input.Control
						type="checkbox"
						name="limitResponses"
						value=""
						label={t('Limit responses')}
						description={t(
							'Limit the number of responses before the registration closes'
						)}
						defaultChecked={!!data.maxSubmissions}
					/>
					{showMaxSubmissions && (
						<Input.Group label={t('Response limit')}>
							<Input.Field
								type="number"
								name="maxSubmissions"
								placeholder="20"
								required
								defaultValue={data.maxSubmissions}
							/>
						</Input.Group>
					)}

					<Input.Control
						name="forceAuth"
						type="checkbox"
						defaultChecked={data.forceAuth}
						label={t('Require a 360Player account')}
						description={t(
							'Only people with an existing 360Player account will be able to submit this registration. Recommended if the people submitting the form will use 360Player within the club'
						)}
						optional
					/>
				</Column>
				<Input.Group label={t('Status')}>
					<Column>
						{statuses.map((status, key) => (
							<Input.Control
								name="status"
								key={key}
								type="radio"
								label={models.form.getTranslatedStatus(status)}
								description={getTranslatedStatusDescription(status)}
								value={status}
								defaultChecked={data.status === status}
							/>
						))}
					</Column>
				</Input.Group>
			</Column>
		</Section>
	);
};

export default Availability;
