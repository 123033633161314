import { createSelector } from 'reselect';
import { Map } from 'immutable';

import MatchEvent from 'pkg/models/match_event';

import { getMembershipsForGroup } from 'pkg/selectors/groups';
import { findAll } from 'pkg/selectors/users';
import { getMatches } from 'pkg/selectors/matches.selector';

const emtpyMatchEvent = new MatchEvent();

const ignoredEventTypes = ['starting_user', 'substitution_out', 'assist'];

const eventsSelector = (state) => state.matchEvents.entities;

export const find = (state, id) =>
	state.matchEvents.entities.get(id) || emtpyMatchEvent;

export const getMatchEventModes = (state) => state.matchEvents.get('modes');

export const matchEventsListSelector = (state) =>
	state.matchEvents.get('entities').toList();

export const getMatchEventTypes = (state) => state.matchTypes.get('entities');

export const getMatchEventEdit = (state) => state.matchEvents.get('edit');

export const getCurrentEditChanges = (state) => {
	const edit = getMatchEventEdit(state);

	return edit.changes.get(edit.eventId) || new Map();
};

export const getFilteredMatchEventTypes = createSelector(
	getMatchEventTypes,
	(eventTypes) =>
		eventTypes.filter((eventType) => !ignoredEventTypes.includes(eventType))
);

export const getMatchEvents = createSelector(
	(_, { matchId }) => matchId,
	eventsSelector,
	(_, { type }) => type,
	(_, { isOpponent }) => isOpponent,
	findAll,
	(matchId, matchEvents, type, isOpponent, users) =>
		matchEvents
			.toList()
			.filter((event) => {
				if (
					event?.get('matchId') !== matchId ||
					(type !== undefined && event?.get('type') !== type) ||
					(isOpponent !== undefined && event?.get('isOpponent') !== isOpponent)
				) {
					return false;
				}

				return event;
			})
			.map((event) => {
				if (event.user) {
					return event.set('user', users.get(event.user));
				}

				return event;
			})
);

export const getAllEventsForMatch = (state, matchId) => {
	const matchEvents = eventsSelector(state);
	const users = findAll(state);

	return matchEvents
		.filter((event) => event?.get('matchId') === matchId)
		.map((event) => {
			if (event.user) {
				return event.set('user', users.get(event.user));
			}

			return event;
		});
};

export const makeGetCurrentStartingEvent = () =>
	createSelector(
		(_, props) => props.matchId,
		(_, props) => props.userId,
		matchEventsListSelector,
		(matchId, activeUserId, matchEvents) => {
			const events = matchEvents.filter((matchEvent) => {
				return (
					matchEvent.get('matchId') === matchId &&
					matchEvent.get('userId') === activeUserId &&
					matchEvent.get('type') === 'starting_user'
				);
			});

			if (events.size === 0) {
				return null;
			}

			return events.last().toJS();
		}
	);

export const getStartingMatchEvents = createSelector(
	matchEventsListSelector,
	(_, props) => props.matchId,
	(matchEvents, matchId) => {
		return matchEvents
			.filter(
				(matchEvent) =>
					matchEvent.get('type') === 'starting_user' &&
					matchEvent.get('matchId') === matchId
			)
			.groupBy((event) => event.get('positionId'));
	}
);

export const getScoringLeagueItems = createSelector(
	getMatches,
	eventsSelector,
	findAll,
	(matches, matchEvents, users) =>
		matchEvents
			.filter(
				(event) =>
					event.get('userId') &&
					(event.get('type') === 'goal' || event.get('type') === 'assist') &&
					matches.filter((match) => {
						return match.get('id') === event.get('matchId');
					}).size > 0
			)
			.groupBy((event) => event.get('userId'))
			.map((usersMatchEvents, userId) => {
				if (!users.get(userId)) return;

				let item = new Map({})
					.set('userId', userId)
					.set('firstName', users.get(userId).firstName)
					.set(
						'goals',
						usersMatchEvents.filter((event) => event.get('type') === 'goal')
							.size
					)
					.set(
						'assists',
						usersMatchEvents.filter((event) => event.get('type') === 'assist')
							.size
					)
					.set('points', usersMatchEvents.size);

				return item;
			})
			.filter((match) => match)
			.sortBy((item) => item.get('points'))
			.reverse()
);

export const getCardsItems = createSelector(
	getMatches,
	eventsSelector,
	findAll,
	(matches, matchEvents, users) =>
		matchEvents
			.filter(
				(event) =>
					event.get('userId') &&
					(event.get('type') === 'card_red' ||
						event.get('type') === 'card_yellow') &&
					matches.filter((match) => {
						return match.get('id') === event.get('matchId');
					}).size > 0
			)
			.groupBy((event) => event.get('userId'))
			.map((usersMatchEvents, userId) => {
				if (!users.get(userId)) return;

				let item = new Map({})
					.set('userId', userId)
					.set('firstName', users.get(userId).firstName)
					.set(
						'card_red',
						usersMatchEvents.filter((event) => event.get('type') === 'card_red')
							.size
					)
					.set(
						'card_yellow',
						usersMatchEvents.filter(
							(event) => event.get('type') === 'card_yellow'
						).size
					)
					.set(
						'total',
						usersMatchEvents.filter(
							(event) =>
								event.get('type') === 'card_red' ||
								event.get('type') === 'card_yellow'
						).size
					);

				return item;
			})
			.filter((match) => match)
			.sortBy((item) => item.get('total'))
			.reverse()
);

export const getGoalTimelineItems = createSelector(
	getMatches,
	eventsSelector,
	(_, props) => props.parts,
	(matches, matchEvents, parts) => {
		let itemsArray = [];

		for (let i = 0; i < parts; i++) {
			itemsArray[i] = { goals: 0, goalsConceded: 0 };
		}

		matchEvents
			.filter(
				(event) =>
					event.get('type') === 'goal' &&
					event.get('minute') &&
					matches.filter(
						(match) =>
							match.get('id') === event.get('matchId') && match.get('duration')
					).size > 0
			)
			.groupBy((event) => event.get('matchId'))
			.map((events, matchId) => {
				const match = matches.get(matchId);
				const eachPartLength = match.duration / parts;

				let partsArray = [];
				partsArray[0] = { start: 0, end: eachPartLength };

				for (let i = 1; i < parts; i++) {
					partsArray[i] = {
						start: eachPartLength * i + 1,
						end: eachPartLength * (i + 1),
					};
				}

				partsArray.map((part, i) => {
					itemsArray[i].goals += events.filter(
						(event) =>
							!event.isOpponent &&
							event.minute >= part.start &&
							event.minute <= part.end
					).size;

					itemsArray[i].goalsConceded += events.filter(
						(event) =>
							event.isOpponent &&
							event.minute >= part.start &&
							event.minute <= part.end
					).size;
				});
			});

		return itemsArray;
	}
);

export const getPlayTimeItems = (groupId) =>
	createSelector(
		getMatches,
		eventsSelector,
		findAll,
		getMembershipsForGroup(groupId),
		(matches, matchEvents, users, activeGroupMemberships) => {
			const results = {};

			activeGroupMemberships.map((membership) => {
				if (membership.role !== 1) {
					return;
				}

				const userId = membership.userId;

				results[userId] = {
					user: users.get(userId),
					firstName: users.get(userId).firstName,
					matches: [],
					start: 0,
					minutes: 0,
					average: 0,
				};
			});

			matchEvents
				.filter(
					(event) =>
						event.get('userId') &&
						(((event.get('minute') ||
							(event.get('minute') === 0 &&
								event.get('type') === 'starting_user')) &&
							event.get('type') === 'starting_user') ||
							event.get('type') === 'card_red' ||
							event.get('type') === 'substitution_in' ||
							event.get('type') === 'substitution_out') &&
						matches.filter(
							(match) =>
								match.get('id') === event.get('matchId') &&
								match.get('duration')
						).size > 0
				)
				.groupBy((event) => event.get('matchId'))
				.map((events, matchId) => {
					const match = matches.get(matchId);
					const matchLength = match.duration + match.overtime;

					events
						.sortBy((event) => event.minute)
						.map((event) => {
							if (!users.get(event.userId)) {
								return;
							}

							if (!results[event.userId]) {
								results[event.userId] = {
									user: users.get(event.userId),
									firstName: users.get(event.userId).firstName,
									matches: [],
									start: 0,
									minutes: 0,
									average: 0,
								};
							}

							if (event.type === 'starting_user') {
								results[event.userId].start++;
							}

							if (
								event.type === 'starting_user' ||
								event.type === 'substitution_in'
							) {
								let minutes = matchLength - event.minute;

								results[event.userId].matches.push(event.matchId);
								results[event.userId].minutes += minutes;
							}

							if (
								event.type === 'card_red' ||
								event.type === 'substitution_out'
							) {
								results[event.userId].minutes -= matchLength - event.minute;
							}

							results[event.userId].minutes = Math.max(
								results[event.userId].minutes,
								0
							);
						});
				});

			for (let [id] of Object.entries(results)) {
				results[id].matches = [...new Set(results[id].matches)].length;

				if (results[id].minutes && results[id].matches) {
					results[id].average = Math.ceil(
						results[id].minutes / results[id].matches
					);
				}
			}

			return new Map(results);
		}
	);
