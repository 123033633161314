const helpCircleFilled = {
	name: 'help-circle-filled',
	objects: [
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M20.2461 12C20.2461 16.5541 16.5542 20.246 12 20.246C7.44589 20.246 3.75403 16.5541 3.75403 12C3.75403 7.44583 7.44589 3.75397 12 3.75397C16.5542 3.75397 20.2461 7.44583 20.2461 12ZM12.3589 13.5545H11.1298V13.4586C11.1371 12.1298 11.5136 11.7201 12.1669 11.3141C12.6135 11.0336 12.9568 10.6792 12.9568 10.1625C12.9568 9.583 12.5028 9.20652 11.9381 9.20652C11.4177 9.20652 10.9157 9.53871 10.8825 10.2326H9.56845C9.60536 8.83004 10.6536 8.11398 11.9455 8.11398C13.3554 8.11398 14.3262 8.89647 14.3262 10.144C14.3262 10.9893 13.9017 11.5429 13.2226 11.9489C12.6209 12.318 12.3662 12.6761 12.3589 13.4586V13.5545ZM12.5877 15.0457C12.584 15.496 12.2112 15.8577 11.7757 15.8577C11.3254 15.8577 10.96 15.496 10.9637 15.0457C10.96 14.6028 11.3254 14.2411 11.7757 14.2411C12.2112 14.2411 12.584 14.6028 12.5877 15.0457Z',
			},
		},
	],
};

export default helpCircleFilled;
