import { ReactNode } from 'react';

export const symbol_featured_seasonal_and_gifts: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M160-140v-385h-20q-24.75 0-42.375-17.625T80-585v-110q0-24.75 17.625-42.375T140-755h160q-8-12-12-25.5t-4-27.5q0-46.667 32.667-79.333Q349.333-920 396-920q24 0 46.5 10t37.5 29q15-19 37.5-29t46.5-10q46.667 0 79.333 32.667Q676-854.667 676-808q0 14-4 27.5T660-755h160q24.75 0 42.375 17.625T880-695v110q0 24.75-17.625 42.375T820-525h-20v385q0 24.75-17.625 42.375T740-80H220q-24.75 0-42.375-17.625T160-140Zm404.07-720Q542-860 527-845.07t-15 37Q512-786 526.93-771t37 15Q586-756 601-770.93t15-37Q616-830 601.07-845t-37-15ZM344-808.07Q344-786 358.93-771t37 15Q418-756 433-770.93t15-37Q448-830 433.07-845t-37-15Q374-860 359-845.07t-15 37ZM140-695v110h310v-110H140Zm310 555v-385H214v385h236Zm60 0h236v-385H510v385Zm310-445v-110H510v110h310Z"
		/>
	</svg>
);
