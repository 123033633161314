import { ReactNode } from 'react';

export const symbol_face_6: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm-.049-740Q358-820 266-744.5T148-554q41 0 67.5-19.5T271-650q13.75-28.182 40.333-44.091Q337.917-710 370-710h220q32.083 0 58.667 15.909Q675.25-678.182 689-650q29 60 58 78t65 18q-26-115-117.5-190.5T479.951-820Zm-.215 680Q622-140 721-239.5T820-480v-12q-76 0-117.5-39.5T635-622q-7-14-18.717-21-11.716-7-25.283-7H370q-14.71 0-26.355 7T325-622q-26 52-69 91.5T140-491v11q0 141 99.267 240.5Q338.533-140 479.736-140ZM353.775-383Q331-383 315.5-398.725q-15.5-15.726-15.5-38.5Q300-460 315.725-475.5q15.726-15.5 38.5-15.5Q377-491 392.5-475.275q15.5 15.726 15.5 38.5Q408-414 392.275-398.5q-15.726 15.5-38.5 15.5Zm253 0Q584-383 568.5-398.725q-15.5-15.726-15.5-38.5Q553-460 568.725-475.5q15.726-15.5 38.5-15.5Q630-491 645.5-475.275q15.5 15.726 15.5 38.5Q661-414 645.275-398.5q-15.726 15.5-38.5 15.5ZM480-710Z"
		/>
	</svg>
);
