import { ReactNode } from 'react';

export const symbol_edgesensor_low: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M109.825-400Q97-400 88.5-408.625T80-430v-220q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-650v220q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Zm740 119Q837-281 828.5-289.625T820-311v-219q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T880-530v219q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM301-80q-24 0-42-18t-18-42v-680q0-24 18-42t42-18h359q24 0 42 18t18 42v680q0 24-18 42t-42 18H301Zm359-90H301v30h359v-30ZM301-790h359v-30H301v30Zm0 0v-30 30Zm0 620v30-30Zm0-60h359v-500H301v500Z"
		/>
	</svg>
);
