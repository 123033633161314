import { ReactNode } from 'react';

export const symbol_urology: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M430-120v50q0 12.75-8.675 21.375Q412.649-40 399.825-40 387-40 378.5-48.625T370-70v-50h-14q-22 0-38.5-14T297-170l-20-120q-5-28 13.353-49T337-360h14l19-52q-11 6-24 9t-26 3q-99 0-169.5-66.5T80-630v-60q0-97 70.5-163.5T320-920q45.833 0 77.917 32.118 32.083 32.117 32.083 78Q430-764 397.917-732 365.833-700 320-700h-40q-12 0-21-8.675-9-8.676-9-21.5 0-12.825 8.625-21.325T280-760h40q21.25 0 35.625-14.325Q370-788.649 370-809.825 370-831 355.625-845.5 341.25-860 320-860q-74.25 0-127.125 49T140-690v60q0 72 52.875 121T320-460q21.25 0 35.625-14.325Q370-488.649 370-509.825 370-531 355.625-545.5 341.25-560 320-560h-40q-12 0-21-8.675-9-8.676-9-21.5 0-12.825 8.625-21.325T280-620h40q45.833 0 77.917 32.083Q430-555.833 430-510v150h100v-150q0-45.833 32.083-77.917Q594.167-620 640-620h40q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T680-560h-40q-21.25 0-35.625 14.325Q590-531.351 590-510.175 590-489 604.375-474.5 618.75-460 640-460q74.25 0 127.125-49T820-630v-60q0-72-52.875-121T640-860q-21.25 0-35.625 14.325Q590-831.351 590-810.175 590-789 604.375-774.5 618.75-760 640-760h40q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T680-700h-40q-45.833 0-77.917-32.118-32.083-32.117-32.083-78Q530-856 562.083-888q32.084-32 77.917-32 99 0 169.5 66.5T880-690v60q0 97-70.5 163.5T640-400q-13 0-25.5-3t-24.5-9v52h33q28.294 0 46.647 21Q688-318 683-290l-20 120q-4 22-20.5 36T604-120h-14v50q0 12.75-8.675 21.375Q572.649-40 559.825-40 547-40 538.5-48.625T530-70v-50H430Zm-94-180 20 120h248l20-120H336Zm0 0 20 120-20-120Z"
		/>
	</svg>
);
