import { ReactNode } from 'react';

export const symbol_merge_type: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M703-182q-9 9-21.5 9.5T660-181L463-378q-8-8-12.5-19.5T446-421v-303L345-623q-9 9-21.5 9t-21.5-9q-9-9-9-21.5t9-21.5l152-153q5-5 10.5-7t11.5-2q6 0 11 2t10 7l152 152q9 9 9 21.5t-9 21.5q-9 9-21.5 9.5T606-623L506-724v303l197 197q9 9 9 21t-9 21Zm-452 0q-9-9-9-21t9-21l119-120q9-9 21.5-9t21.5 9q9 9 9 21.5t-9 21.5L294-182q-9 9-21.5 9t-21.5-9Z"
		/>
	</svg>
);
