import { ReactNode } from 'react';

export const symbol_candlestick_chart: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M280-190v-60h-50q-12.75 0-21.375-8.625T200-280v-400q0-12.75 8.625-21.375T230-710h50v-60q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T340-770v60h50q12.75 0 21.375 8.625T420-680v400q0 12.75-8.625 21.375T390-250h-50v60q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T280-190Zm-20-120h100v-340H260v340Zm360 120v-180h-50q-12.75 0-21.375-8.625T540-400v-200q0-12.75 8.625-21.375T570-630h50v-140q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T680-770v140h50q12.75 0 21.375 8.625T760-600v200q0 12.75-8.625 21.375T730-370h-50v180q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T620-190Zm-20-240h100v-140H600v140Zm-290-50Zm340-20Z"
		/>
	</svg>
);
