import { t } from '@transifex/native';
import { Fragment, useState } from 'react';

import { FilterGroups, useFilters } from 'pkg/filters/use_filters';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useEndpoint } from 'pkg/api/use_endpoint';
import { FilterOperator, createFilterGroups } from 'pkg/filters';
import Link from 'pkg/router/Link';
import { UserFieldType } from 'pkg/api/models/user_fields';
import * as routes from 'pkg/router/routes';
import { useCurrentGroup } from 'pkg/identity';
import { useCollection } from 'pkg/api/use_collection';

import { FormattedContent } from 'components/formatted-content';
import Icon, { IconName } from 'components/icon';

import * as ActionBar from 'components/layout/ActionBar';
import { GalleryModal } from 'components/attachment/gallery';
import Column from 'components/layout/column';
import { useTemplateContext } from 'components/layout/page-templates/context';

import * as Table from 'design/table';

interface UserFieldsGrossListProps {
	userId: number;
	organizationId: number;
}

interface customFieldRow {
	key: string;
	values: string[];
	attachments?: models.attachment.Attachment[];
	label: string;
	type: UserFieldType;
}

function FieldRow({ field }: { field: customFieldRow }): JSX.Element {
	const [galleryOpenOnId, setGalleryOpenOnId] = useState(0);

	const handleCloseModal = () => {
		setGalleryOpenOnId(0);
	};

	let content = <Table.Cell>{field.values?.join(', ') || '-'}</Table.Cell>;

	switch (field.type) {
		case UserFieldType.Attachment:
			content = (
				<Table.Cell>
					<Column>
						{field.attachments?.map((a) => {
							let icon: IconName = 'file-generic';
							switch (a.type) {
								case models.attachment.AttachmentType.Image:
									icon = 'file-image';
							}

							return (
								<Fragment key={a.id}>
									<Link
										onClick={() => {
											setGalleryOpenOnId(a.id);
										}}>
										<Icon name={icon} /> {a.title}
									</Link>
								</Fragment>
							);
						})}
					</Column>
				</Table.Cell>
			);
			break;
		case UserFieldType.Email:
			content = (
				<Table.Cell>
					{field.values?.map((val, i) => (
						<Link href={`mailto: ${val}`} key={i} raw={val}>
							{val}
						</Link>
					)) || '-'}
				</Table.Cell>
			);
			break;
		case UserFieldType.Phone:
			content = (
				<Table.Cell>
					{field.values?.map((val, i) => (
						<Link href={`tel: ${val}`} key={i} raw={val}>
							{val}
						</Link>
					)) || '-'}
				</Table.Cell>
			);
			break;
		case UserFieldType.LongText:
			content = (
				<Table.Cell>
					{field.values?.map((val, i) => (
						<FormattedContent key={i} raw={val} />
					)) || '-'}
				</Table.Cell>
			);
			break;
	}

	return (
		<Table.Row>
			<Table.Cell multiline>{field.label}</Table.Cell>
			{content}
			{galleryOpenOnId > 0 && (
				<GalleryModal
					activeId={galleryOpenOnId}
					attachments={field.attachments}
					onClose={handleCloseModal}
				/>
			)}
		</Table.Row>
	);
}

/**
 * UserFieldsGrossList is a table list of all values on a user in an organization,
 * It will display all custom fields set up for the organization, with values filled in
 * for the fields that the user has values in.
 *
 * Here all fields are visible, regardless of their visibility setting on "UserField".
 */
const UserFieldsGrossList = ({
	userId,
	organizationId,
}: UserFieldsGrossListProps): JSX.Element => {
	const { setPageTitle } = useTemplateContext();
	const group = useCurrentGroup();

	const filterGroups: FilterGroups = createFilterGroups({
		[t('Label')]: {
			hidden: true,
			filters: {
				Label: {
					type: 'text',
					operator: FilterOperator.Contains,
					property: 'label',
				},
			},
		},
	});

	const filters = useFilters({
		groups: filterGroups,
	});

	const { record: user, isLoading: isUserLoading } =
		useEndpoint<models.user.User>(endpoints.Users.Show(userId), {}, (user) => {
			setPageTitle(models.user.fullName(user));
		});

	const { records: organizationUserFields, isLoading: isLoadingUserFields } =
		useCollection<models.userFields.UserField>(endpoints.UserField.Index(), {
			queryParams: new URLSearchParams({
				group_id: organizationId.toString(),
			}),
		});

	const searchValue = filters.currentFilters.find(
		(filter) => filter.property === 'label'
	)?.values[0];

	const reservedFields = [
		// customField > userField. A edited user field becomes a customField - use that.
		...models.userFields
			.getReservedFieldsToDisplay(group)
			.filter((key) =>
				(organizationUserFields || []).find(
					(customField) => customField.key !== key
				)
			)
			.map((key) => ({
				key,
				value: user[key as keyof models.user.User],
				label: models.userFields.getReservedTranslation(
					key as models.userFields.ReservedUserField
				),
			})),
	];

	const customFields: customFieldRow[] = (organizationUserFields || [])
		.filter((f) => f.type !== models.userFields.UserFieldType.Reserved)
		.filter((field) => {
			if (!searchValue) {
				return true;
			}

			return field.label
				.toLowerCase()
				.includes(searchValue.toString().toLowerCase().trim());
		})
		.sort((a, b) => a.sortOrder - b.sortOrder)
		.map((field) => {
			// todo: filter out reserved fields

			const uf = user.fields?.find((f) => f.key === field.key);
			const res: customFieldRow = {
				key: field.key,
				values: uf?.values,
				attachments: uf?.attachments,
				label: field.label,
				type: field.type,
			};
			if (!uf) {
				return res;
			}

			return res;
		});

	return (
		<Fragment>
			<ActionBar.IntegratedFilterBar
				pageActionIcon="more_horiz"
				filters={filters}
				searchFilter={{
					type: 'text',
					operator: FilterOperator.Contains,
					property: 'label',
				}}
				actions={[
					{
						label: t('Manage properties'),
						href: routes.Settings.Index(
							organizationId,
							user.organizationId,
							'user-fields'
						),
					},
					{
						label: t('Edit contact'),
						href: routes.Management.Contact.Edit(organizationId, user.id),
					},
				]}
			/>

			<Table.Table
				columns={[
					{
						content: t('Property'),
						width: '150px',
					},
					{
						content: t('Value'),
					},
				]}
				isLoading={isUserLoading || isLoadingUserFields}
				emptyState={{
					title: t('Try searching for something else'),
					content: t('No records found'),
				}}>
				{reservedFields
					.filter((field) => {
						if (!searchValue) {
							return true;
						}

						return field.label
							.toLowerCase()
							.includes(searchValue.toString().toLowerCase().trim());
					})
					.map((field) => (
						<Table.Row key={field.label}>
							<Table.Cell multiline>{field.label}</Table.Cell>
							<Table.Cell>{field.value || '-'}</Table.Cell>
						</Table.Row>
					))}
				{customFields.map((cf) => (
					<FieldRow key={cf.key} field={cf} />
				))}
			</Table.Table>
		</Fragment>
	);
};

export default UserFieldsGrossList;
