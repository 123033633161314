import { ReactNode } from 'react';

export const symbol_bubble: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-120q-34 0-69-4.5T344-142q-11-5-16-17t0-23q5-11 16.5-16t22.5 0q24 10 54 14t59 4q127 0 213.5-86.5T780-480q0-127-86.5-213.5T480-780q-127 0-213.5 86.5T180-480q0 30 4 56.5t14 52.5q4 12-1.5 23.5T179-332q-12 4-23.5-2T140-352q-9-28-14.5-60.5T120-480q0-75 28-140.5T225-735q49-49 114.5-77T480-840q75 0 140.5 28T735-735q49 49 77 114.5T840-480q0 75-28 140.5T735-225q-49 49-114.5 77T480-120Zm-338-22q-9-9-9-21.5t9-21.5l350-350H351q-13 0-21.5-8.5T321-565q0-13 8.5-21.5T351-595h214q13 0 21.5 8.5T595-565v214q0 13-8.5 21.5T565-321q-13 0-21.5-8.5T535-351v-141L184-141q-9 9-21 8.5t-21-9.5Z"
		/>
	</svg>
);
