import { ReactNode } from 'react';

export const symbol_unfold_more_double: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m481-85 106-106q9-9 21-9t21 9q9 9 9 21t-9 21L501-21q-5 5-10 7t-11 2q-6 0-11-2t-10-7L333-148q-9-9-9-21t9-21q9-9 21-9t21 9L481-85Zm0-200 106-106q9-9 21-9t21 9q9 9 9 21t-9 21L501-221q-5 5-10 7t-11 2q-6 0-11-2t-10-7L333-348q-9-9-9-21t9-21q9-9 21-9t21 9l106 105Zm-1-389L375-569q-9 9-21 9t-21-9q-9-9-9-21.5t9-21.5l127-127q5-5 10-7t11-2q6 0 11 2t10 7l127 127q9 9 8.5 21.5T628-569q-9 9-21 9t-21-9L480-674Zm0-200L375-769q-9 9-21 9t-21-9q-9-9-9-21.5t9-21.5l127-127q5-5 10-7t11-2q6 0 11 2t10 7l127 127q9 9 8.5 21.5T628-769q-9 9-21 9t-21-9L480-874Z"
		/>
	</svg>
);
