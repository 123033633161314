import { ReactNode } from 'react';

export const symbol_e_mobiledata: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M350-280q-12.75 0-21.375-8.625T320-310v-340q0-12.75 8.625-21.375T350-680h260q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T610-620H380v110h230q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T610-450H380v110h230q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T610-280H350Z"
		/>
	</svg>
);
