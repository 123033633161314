import { ReactNode } from 'react';

export const symbol_sports_and_outdoors: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m409-147 16-58q5-14 14-24t23-11l133-10q15-1 26.5 6t16.5 20l13 39q42-26 75.5-59.5T781-321l-10-8q-13-8-18-20t-3-25l30-132q2-14 12.5-21.5T816-536q-5-26-13.5-52T781-640q-8 6-18 6t-20-7l-112-66q-11-7-16-19.5t-3-26.5l9-36q-33-16-68.5-23.5T480-820q-16 0-31 .5t-31 2.5l34 72q6 13 2 27t-16 24l-99 88q-12 7-26 8.5t-27-6.5l-95-56q-26 41-38.5 86.5T140-480q0 18 4 54l94-9q14-2 26.5 5.5T283-408l48 123q6 13 3.5 25T321-238l-38 36q28 20 59.5 33.5T409-147Zm79-183q-15 1-26.5-6T444-356l-60-133q-5-13-.5-26t16.5-21l108-95q10-10 24-9.5t27 7.5l118 71q12 7 17 19.5t2 26.5l-30 140q-4 12-14.5 21T630-345l-142 15Zm-8 250q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z"
		/>
	</svg>
);
