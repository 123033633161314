import { ReactNode } from 'react';

export const symbol_google_wifi: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M164-540h632l-11.738-200H176l-12 200Zm-19 290h670l-15-230H160l-15 230Zm53 90-11-30h-43q-25.812 0-43.406-18.824Q83-227.647 85-254l31-490q2-24 19.103-40 17.102-16 40.897-16h608q23.795 0 40.897 16Q842-768 844-744l31 490q2 26.353-15.594 45.176Q841.812-190 816-190h-43l-11 30H198Z"
		/>
	</svg>
);
