import { Fragment, useEffect, useRef } from 'react';

import { useEndpoint } from 'pkg/api/use_endpoint';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';

import { Spinner } from 'components/loaders/spinner';
import { MaterialSymbolVariant } from 'components/material-symbols/symbols';
import { useTemplateContext } from 'components/layout/page-templates/context';
interface CustomLinksEmbeddedProps {
	linkId: number;
}

export default function CustomLinksEmbedded({
	linkId,
}: CustomLinksEmbeddedProps) {
	const { setPageTitle, setPageIcon } = useTemplateContext();
	const { record: link, isLoading } = useEndpoint<models.groupLink.GroupLink>(
		endpoints.GroupLinks.Show(linkId)
	);

	useEffect(() => {
		if (!isLoading) {
			setPageTitle(link.title);
			setPageIcon(link.groupLinkFolder.iconName as MaterialSymbolVariant);
		}
	}, [isLoading]);

	const frameRef = useRef<HTMLIFrameElement>();
	const content = (
		<iframe
			ref={frameRef}
			src={link.url}
			style={{ width: '100%', height: '100%', border: 'none' }}
			sandbox="allow-scripts allow-forms allow-modals allow-same-origin allow-popups allow-downloads allow-popups-to-escape-sandbox"
			referrerPolicy="strict-origin-when-cross-origin"
		/>
	);

	if (isLoading) {
		return <Spinner />;
	}

	return <Fragment>{content}</Fragment>;
}
