import { ReactNode } from 'react';

export const symbol_youtube_searched_for: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M435-329q-51 0-94.5-17.5T262-395q-10-9-10.5-22.5T260-440q9-9 21.5-8t21.5 9q26 23 59.5 36.5T434-389q81 0 138-57.5T629-585q0-81-57-138.5T434-781q-82 0-139.5 57.5T237-585l42-43q9-9 21-9.5t21 8.5q9 9 9 21.5t-9 21.5l-89 90q-9 9-21 9t-21-9l-90-90q-9-9-8.5-21.5T101-629q9-9 21.5-9t21.5 9l34 35q4-103 77-175t179-72q106 0 180.5 75T689-585q0 43-14 83t-42 75l220 218q9 9 9 22t-9 22q-9 9-22 9t-22-9L590-384q-30 26-70 40.5T435-329Z"
		/>
	</svg>
);
