import * as styles from 'pkg/config/styles';

import { pushState } from 'pkg/router/state';
import DateTime from 'pkg/datetime';
import * as routes from 'pkg/router/routes';
import { useCurrentOrganization } from 'pkg/identity';
import * as models from 'pkg/api/models';

import { ReportRow } from 'routes/group/report/activities';

import Icon from 'components/icon';
import Label from 'components/label';
import RelativeDateTime from 'components/RelativeDateTime';

import * as Table from 'design/table';

interface ActivityReportRow {
	report: ReportRow;
	event: models.event.Event;
	showLOK: boolean;
}

export default function ActivityReportRow({
	report,
	event,
	showLOK,
}: ActivityReportRow): JSX.Element {
	const org = useCurrentOrganization();

	const date = new DateTime(
		new Date(event.startsAt * 1000)
	).toLocaleDateString();

	const startTime = new DateTime(
		new Date(event.startsAt * 1000)
	).toLocaleTimeString({
		timeStyle: 'short',
	});

	const endTime = new DateTime(
		new Date(event.endsAt * 1000)
	).toLocaleTimeString({
		timeStyle: 'short',
	});

	const invited =
		report.inviteAccepted + report.inviteDeclined + report.invitePending;

	const absent =
		report.attendanceInjured + report.attendanceOther + report.attendanceSick;

	const handleRowClick = () =>
		pushState(routes.Event.View(org.id, event.id, 'overview'));

	return (
		<Table.Row onClick={handleRowClick}>
			<Table.Cell>{event.title}</Table.Cell>
			<Table.Cell align="right">{date}</Table.Cell>
			<Table.Cell align="right">{`${startTime}-${endTime}`}</Table.Cell>
			<Table.Cell>{event.location}</Table.Cell>
			<Table.Cell align="right">
				<Label color="gray">{invited}</Label>
			</Table.Cell>
			<Table.Cell align="right">
				<Label color="green">{report.attendanceAttended}</Label>
			</Table.Cell>
			<Table.Cell align="right">
				<Label color="red">{absent}</Label>
			</Table.Cell>
			<Table.Cell align="right">
				<Label color="orange">{report.attendanceUnhandled}</Label>
			</Table.Cell>
			{showLOK && (
				<Table.Cell align="center">
					<Icon
						size={1.8}
						fill={
							report.validForLok
								? styles.palette.green[500]
								: styles.palette.red[500]
						}
						name={report.validForLok ? 'check-circle' : 'close-circle'}
					/>
				</Table.Cell>
			)}
			{showLOK && (
				<Table.Cell align="right">
					<RelativeDateTime dateTime={event.updatedAt} />
				</Table.Cell>
			)}
			{showLOK && (
				<Table.Cell align="right">
					{event.lokLastReportedDate ? (
						<RelativeDateTime dateTime={event.lokLastReportedDate} />
					) : (
						''
					)}
				</Table.Cell>
			)}
		</Table.Row>
	);
}
