import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { StatusBar } from '@capacitor/status-bar';
import { Keyboard } from '@capacitor/keyboard';
import { SplashScreen } from '@capacitor/splash-screen';
import { ErrorBoundary } from '@sentry/react';

import { pushState } from 'pkg/router/state';
import { isAndroid, isApp, isIOS } from 'pkg/platform';
import { setAppleMeta } from 'pkg/meta_tags';
import store from 'pkg/store/createStore';
import { isDev } from 'pkg/flags';
import { tlog } from 'pkg/tlog';

import Application from 'components/application';

import { FullPageCrash } from 'components/crashes/FullPageCrash';

import 'pkg/core/sdk';
import 'pkg/dev/generateRouteDocs';

window.handleOpenURL = (url: string) => {
	//incoming url includes custom-url-scheme.
	const [protocol, location] = url.split('://');

	// handleOpenURL also receives all calls to window.open, leading to external links being pushed to the state.
	if (!protocol.startsWith('threesixty')) {
		return;
	}

	pushState(location);
};

const renderApp = () => {
	const container = document.querySelector('#app');
	const root = createRoot(container);

	const resetAfterCrash = () => {
		window.history.pushState({}, '', '/');
		window.location.reload();
	};

	root.render(
		<ErrorBoundary fallback={<FullPageCrash reset={resetAfterCrash} />}>
			<Provider store={store}>
				<StrictMode>
					<Application />
				</StrictMode>
			</Provider>
		</ErrorBoundary>
	);
};

const init = () => {
	if (isDev() && !isApp()) {
		import('pkg/dev/consoleActions');
	}

	if (isApp()) {
		SplashScreen.hide();
	}

	if (isIOS()) {
		Keyboard.setAccessoryBarVisible({ isVisible: true });
	}

	if (isAndroid()) {
		StatusBar.setBackgroundColor({ color: '#252831' });
	}

	tlog.init();

	setAppleMeta(location.pathname + location.search + location.hash);

	window.addEventListener('statusTap', function () {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	});

	renderApp();
};

document.addEventListener(isApp() ? 'deviceready' : 'DOMContentLoaded', init);
