import { ReactNode } from 'react';

export const symbol_extension_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-120q-24.75 0-42.375-17.625T120-180v-176q44-5 75.5-34.5T227-463q0-43-31.5-72.5T120-570v-176q0-12.565 5-23.283Q130-780 138-788l42 42v130q48.15 17.817 77.575 59.686Q287-514.445 287-462.777 287-412 257.5-370T180-310v130h130q18-48 60.029-77.5t93-29.5Q514-287 556-257.5q42 29.5 60 77.5h130l42 42q-8 8-18.717 13-10.718 5-23.283 5H570q-5-44-34.5-75.5T463-227q-43 0-72.5 31.5T356-120H180Zm626-120-60-60v-115h45q19 0 32.5-13.5T837-461q0-19-13.5-32.5T791-507h-45v-239H511v-48q0-19-13.5-32.5T465-840q-19 0-32.5 13.5T419-794v48H300l-60-60h117q11-40 39.5-67t68.5-27q40 0 68.5 27t39.5 67h173q24 0 42 18t18 42v173q40 11 65.5 41.5T897-461q0 40-25.5 67T806-356v116ZM792-83 82-792q-9-9.067-8.5-21.533Q74-826 83.053-835q9.052-9 21.5-9Q117-844 126-835l709 710q9 9 9 21t-9.053 21q-9.052 9-21.5 9Q801-74 792-83ZM523-523Zm-60 60Z"
		/>
	</svg>
);
