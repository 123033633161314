import { t } from '@transifex/native';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import * as breakpoints from 'pkg/config/breakpoints';

import * as selectors from 'pkg/selectors';
import * as routes from 'pkg/router/routes';

import Badge from 'components/Badge';
import Icon from 'components/icon';

import Button from 'design/button';

const Score = styled.div`
	font-size: var(--font-size-2xl);
	line-height: 68px;
	display: inline-block;
	vertical-align: top;
	font-weight: var(--font-weight-semibold);
	white-space: nowrap;
	flex: 1;
	justify-content: center;

	${(props) =>
		props.$center &&
		css`
			margin: 0 8px;
		`};

	@media ${breakpoints.small} {
		font-size: var(--font-size-2xl);
		line-height: 48px;
	}
`;

const Fulltime = styled.div`
	text-align: center;
	display: flex;
	justify-content: space-around;
	grid-area: 'fulltime';

	flex-direction: ${(props) => (props.$isHome ? 'row' : 'row-reverse')};
`;

const Halftime = styled(Fulltime)`
	padding: 5px 16px 0;
	color: var(--palette-gray-500);
	grid-area: 'halftime';
	position: relative;

	${Score} {
		font-size: var(--font-size-sm);
		line-height: 1.4rem;
		display: flex;
		vertical-align: center;
		font-weight: var(--font-weight-semibold);

		@media ${breakpoints.small} {
			font-size: var(--font-size-sm);
			line-height: 1.2;
		}
	}
`;

const Team = styled.div`
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;

	grid-area: ${(props) => (props.$opponent ? 'opponent' : 'self')};
`;

const PlaceholderIcon = styled(Icon)`
	width: 100%;
	height: 100%;
	color: var(--palette-gray-400);
`;

const GroupBadge = styled.div`
	margin-bottom: 5px;
	width: 68px;
	height: 68px;

	@media ${breakpoints.small} {
		width: 48px;
		height: 48px;
	}
`;

const Name = styled.div`
	max-width: 150px;
	word-break: break-word;
	margin: 0 auto;
	text-align: center;
	font-size: var(--font-size-base);
	line-height: 1.4;
	font-weight: var(--font-weight-semibold);

	@media ${breakpoints.small} {
		max-width: 100px;
		font-size: var(--font-size-sm);
		line-height: 1.2;
	}
`;

const Outer = styled.div`
	padding: 1.3rem 1rem;
	color: var(--palette-gray-900);
	min-width: 0;

	display: grid;
	grid-template-areas: ${(props) =>
		props.$isHome
			? css` 'self fulltime opponent' 'self halftime opponent'`
			: css`'opponent fulltime self' 'opponent halftime self'`};

	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: auto 1fr;
	align-items: start;
	justify-content: center;

	${(props) =>
		props.$slim &&
		css`
			${Score} {
				font-size: var(--font-size-2xl);
				line-height: 44px;
			}

			${Halftime} {
				${Score} {
					font-size: var(--font-size-base);
					line-height: 1.1;
				}
			}

			${GroupBadge} {
				width: 44px;
				height: 44px;
			}

			${Team} {
				min-width: 0;
				overflow: hidden;
			}

			${Name} {
				max-width: 100%;
				font-size: 0.7rem;
				line-height: 1.1rem;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			${ButtonWrapper} {
				height: 44px;
			}
		`}

	${(props) =>
		props.$fadeContent &&
		css`
			${Team} {
				opacity: 0.4;
			}
		`}


	@media ${breakpoints.small} {
		grid-template-columns: 1fr 0.5fr 1fr;
	}
`;

const ButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100px;
`;

const NoStatsButton = ({ matchId, eventId, canEdit, noLink = false }) => (
	<ButtonWrapper>
		<Button
			secondary
			icon={canEdit ? 'add' : null}
			href={
				!noLink &&
				(canEdit
					? routes.Match.Edit(eventId, eventId, matchId, 'result')
					: routes.Match.View(eventId, eventId, matchId, 'summary'))
			}
			label={canEdit ? t(`Add statistics`) : t(`View match`)}
			testid="add_statistics_button"
		/>
	</ButtonWrapper>
);

const ResultScore = ({ match }) => (
	<Fragment>
		<Fulltime $isHome={match.isHome}>
			<Score>{match.goalCount === null ? '?' : match.goalCount}</Score>
			<Score $center>-</Score>
			<Score>
				{match.opponentGoalCount === null ? '?' : match.opponentGoalCount}
			</Score>
		</Fulltime>

		<Halftime $isHome={match.isHome}>
			<Score>
				{match.goalCountHalfTime === null ? '?' : match.goalCountHalfTime}
			</Score>
			<Score $center>-</Score>
			<Score>
				{match.opponentGoalCountHalfTime === null
					? '?'
					: match.opponentGoalCountHalfTime}
			</Score>
		</Halftime>
	</Fragment>
);

/**
 * @deprecated has been moved to components/match/result/index and uses new models
 */

export default ({ matchId, slim = false, noLink = false }) => {
	const match = useSelector((state) =>
		selectors.matches.getMatchById(state, { matchId })
	);

	const matchOpponent = useSelector((state) =>
		selectors.matchOpponents.find(state, match.matchOpponentId)
	);

	const showStats = !(
		match.goalCount === null && match.opponentGoalCount === null
	);

	return (
		<Fragment>
			<Outer $isHome={match.isHome} $fadeContent={!showStats} $slim={slim}>
				<Team>
					<GroupBadge>
						<Badge group={match.group} badgeUrl={match.group.profileImageUrl} />
					</GroupBadge>

					<Name>{match.group.name}</Name>
				</Team>

				{showStats ? (
					<ResultScore match={match} />
				) : (
					<NoStatsButton
						matchId={matchId}
						eventId={match.eventId}
						canEdit={match.hasIn(['links', 'edit'])}
						slim={slim}
						noLink={noLink}
					/>
				)}

				<Team $opponent>
					<GroupBadge>
						{matchOpponent.get('imageUrl') ? (
							<Badge badgeUrl={matchOpponent.get('imageUrl')} />
						) : (
							<PlaceholderIcon name="nav-group" />
						)}{' '}
					</GroupBadge>
					<Name>{match.opponentName || t(`Opponent`)}</Name>
				</Team>
			</Outer>
		</Fragment>
	);
};
