import { ReactNode } from 'react';

export const symbol_surfing: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M120-40h-10q-13 0-21.5-8.5T80-70q0-13 8.5-21.5T110-100h10q26 0 51-7t49-25q8-7 20-7t20 7q24 18 49 25t51 7q25 0 50.5-7.5T460-132q9-6 20.5-6t19.5 6q24 18 49 25t51 7q25 0 50.5-7.5T700-132q8-6 20-6t20 6q24 17 49.5 24.5T840-100h10q13 0 21.5 8.5T880-70q0 13-8.5 21.5T850-40h-10q-29 0-59.5-8.5T720-80q-30 23-60.5 31.5T600-40q-29 0-59.5-8.5T480-80q-30 23-60.5 31.5T360-40q-29 0-59.5-8.5T240-80q-30 23-60.5 31.5T120-40Zm350-528 160 126v161q17 12 32 23.5t31 24.5q-20 16-43.5 26.5T600-196q-34 0-65-18t-55-47q-24 29-55 47t-65 18h-4.5q-2.5 0-4.5-1-103-72-162-131.5T130-429q0-25 21-33t43-8q29 0 69.5 10t91.5 28l-22-137q-4-23 2-42t23-30l106-71q-7-1-22-4t-32-6q-17-3-32-6.5t-22-4.5l-84 57q-10 7-22 5.5T231-683q-7-11-5-23t13-19l93-64q5-3 10.5-4.5t11.5-.5l206 38q11 2 22.5 9.5T602-725l35 62q23 40 60.5 67.5T782-560q12 2 20 11.5t8 21.5q0 12-8.5 20.5T782-500q-63-10-116-47.5T580-642l-110 74Zm-66 51 17 114q38 18 75.5 38.5T570-322v-80L404-517Zm276-237q-30 0-51.5-21.5T607-827q0-30 21.5-51.5T680-900q30 0 51.5 21.5T753-827q0 30-21.5 51.5T680-754Z"
		/>
	</svg>
);
