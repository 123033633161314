import * as endpoints from 'pkg/api/endpoints/auto';
import * as sdk from 'pkg/core/sdk';

export const getSingleExercise = (id: number): Promise<Response> => {
	return sdk.get(endpoints.Exercises.Show(id));
};

export const deleteExercise = (id: number): Promise<Response> => {
	return sdk.destroy(endpoints.Exercises.Delete(id));
};

export const createExerciseAttachment = (
	id: number,
	attachmentId: number
): Promise<Response> =>
	sdk.post(endpoints.Exercises.AddAttachment(id, attachmentId));

export const deleteExerciseAttachment = (
	id: number,
	attachmentId: number
): Promise<Response> =>
	sdk.destroy(endpoints.Exercises.RemoveAttachment(id, attachmentId));
