import { Fragment, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import { useCurrentGroup, useCurrentMembership } from 'pkg/identity';
import * as models from 'pkg/api/models';
import { RootState } from 'pkg/reducers';
import ColorConverter, {
	accentColor,
	backgroundColor,
	primaryColor,
} from 'pkg/colorconverter';
import * as selectors from 'pkg/selectors';
import { toggleCssClass } from 'pkg/css/utils';
import { applyGroupPalette } from 'pkg/css/group-palette';

import { VideoStyles } from 'components/video/VideoStyles';
import { useAppState } from 'components/application/state';

import Scrollbars from 'styles/Scrollbars';

interface ThemeWrapperProps {
	children: ReactNode;
}
export function ThemeWrapper({ children }: ThemeWrapperProps) {
	const activeMembership = useCurrentMembership();
	const group = useCurrentGroup();
	const groupId = activeMembership.groupId;

	const groupPrimaryColor = models.group.getPrimaryColor(group);

	const collapsedDrawer = useAppState().collapsedDrawer;
	const navBarHeight = useSelector<RootState, number>(
		(state) => state.app.navBarHeight
	);
	const tabBarHeight = useSelector<RootState, number>(
		(state) => state.app.tabBarHeight
	);
	const tabBarVisible = useSelector<RootState, boolean>(
		(state) => state.app.tabBarVisible
	);

	const darkMode = useSelector<RootState, boolean>(
		(state) => state.app.darkMode
	);
	const colorProfileGradient = useSelector<RootState, string>((state) =>
		selectors.groups.getColorProfileGradient(state, { groupId })
	);

	const primaryColorDark = ColorConverter.from(groupPrimaryColor)
		.setLightness(42)
		.toStylesheetString();

	const primaryColorLight = ColorConverter.from(groupPrimaryColor)
		.setLightness(95)
		.desaturate(30)
		.toStylesheetString();

	const theme = {
		darkMode,
		tabBarHeight,
		navBarHeight,
		tabBarVisible,
		primaryColor: groupPrimaryColor && primaryColor(groupPrimaryColor),
		primaryColorDark: groupPrimaryColor && primaryColorDark,
		primaryColorLight: groupPrimaryColor && primaryColorLight,

		accentColor: groupPrimaryColor && accentColor(groupPrimaryColor),
		accentColorTextHover:
			groupPrimaryColor && accentColor(groupPrimaryColor, 0.7),
		accentColorFaded: groupPrimaryColor && accentColor(groupPrimaryColor, 0.17),
		accentColorHover: groupPrimaryColor && accentColor(groupPrimaryColor, 0.1),
		accentColorLight: groupPrimaryColor && accentColor(groupPrimaryColor, 0.06),
		colorProfileGradient: groupPrimaryColor && colorProfileGradient,
		backgroundColor: groupPrimaryColor && backgroundColor(groupPrimaryColor),
	};

	const app = document.getElementById('app');

	toggleCssClass(!tabBarVisible, app, 'hide-tab-bar');
	toggleCssClass(darkMode, document.body, 'dark-mode');
	toggleCssClass(collapsedDrawer, document.body, 'collapsed-drawer');

	if (groupPrimaryColor) {
		applyGroupPalette(groupPrimaryColor);
	} else {
		applyGroupPalette('#000');
	}

	return (
		<Fragment>
			<VideoStyles />
			<Scrollbars />
			<ThemeProvider theme={theme}>
				<Fragment>{children}</Fragment>
			</ThemeProvider>
		</Fragment>
	);
}
