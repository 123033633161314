import { Fragment, useState } from 'react';
import { t } from '@transifex/native';

import { useCollection } from 'pkg/api/use_collection';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useQueryState } from 'pkg/hooks/query-state';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';

import CreateLocationModal from 'routes/scheduling/locations/modals/Create';

import { LargeScreen } from 'components/MediaQuery';

import * as ActionBar from 'components/layout/ActionBar';
import LocationsTable from 'components/resources/locations/table';
import { ButtonTrigger } from 'components/navigation/header/small_screen/page_actions/ButtonTrigger';
import { useTemplateContext } from 'components/layout/page-templates/context';

import Button from 'design/button';

interface LocationsProps {
	groupId: number;
}

const LocationsView = ({ groupId }: LocationsProps) => {
	const qs = useQueryState();
	const search = qs.get('search');
	const { setPageActions } = useTemplateContext();

	const { records, isLoading, refresh } =
		useCollection<models.resourceLocation.ResourceLocation>(
			endpoints.ResourceLocation.Index(),
			{
				queryParams: new URLSearchParams({
					group_id: groupId.toString(),
				}),
			}
		);
	const [showCreateModal, setShowCreateModal] = useState(false);

	const handleCreateModal = () => {
		setShowCreateModal(true);
	};
	const closeModal = () => setShowCreateModal(false);

	const handleCreate = async () => {
		refresh();
	};

	useComponentDidMount(() => {
		setPageActions(<ButtonTrigger icon="add" onClick={handleCreateModal} />);
	});

	return (
		<Fragment>
			<ActionBar.FilterBar>
				<ActionBar.PrimaryAction>
					<ActionBar.Search
						value={qs.get('search') as string}
						placeholder={t('Search')}
					/>
				</ActionBar.PrimaryAction>
				<LargeScreen>
					<Button
						label={t('Create new')}
						icon="add"
						primary
						onClick={handleCreateModal}
					/>
				</LargeScreen>
			</ActionBar.FilterBar>

			<LocationsTable
				locations={records}
				refresh={refresh}
				search={search?.toString()}
				handleOpenModal={handleCreateModal}
				isLoading={isLoading}
			/>
			{showCreateModal && (
				<CreateLocationModal
					groupId={groupId}
					onClose={closeModal}
					onCreate={handleCreate}
				/>
			)}
		</Fragment>
	);
};

export default LocationsView;
