import { ReactNode } from 'react';

export const symbol_multiple_stop: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m234-292 69 69q9 9 9 21t-9 21q-9 9-21 9t-21-9L141-301q-5-5-7-10t-2-11q0-6 2-11t7-10l120-120q9-9 21-9t21 9q9 9 9 21t-9 21l-69 69h256q13 0 21.5 8.5T520-322q0 13-8.5 21.5T490-292H234Zm376 0q-12 0-21-9t-9-21q0-13 9-21.5t21-8.5q13 0 21.5 8.5T640-322q0 12-8.5 21t-21.5 9Zm120 0q-12 0-21-9t-9-21q0-13 9-21.5t21-8.5q13 0 21.5 8.5T760-322q0 12-8.5 21t-21.5 9Zm-4-316H470q-13 0-21.5-8.5T440-638q0-13 8.5-21.5T470-668h256l-69-69q-9-9-9-21t9-21q9-9 21-9t21 9l120 120q5 5 7 10t2 11q0 6-2 11t-7 10L699-497q-9 9-21 9t-21-9q-9-9-9-21t9-21l69-69Zm-496 0q-12 0-21-9t-9-21q0-13 9-21.5t21-8.5q13 0 21.5 8.5T260-638q0 12-8.5 21t-21.5 9Zm120 0q-12 0-21-9t-9-21q0-13 9-21.5t21-8.5q13 0 21.5 8.5T380-638q0 12-8.5 21t-21.5 9Z"
		/>
	</svg>
);
