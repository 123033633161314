import { PointerEvent, MouseEvent, ReactNode, useState } from 'react';

import { clamp } from 'pkg/numbers';

import Tooltip from 'components/tooltip';

interface TooltipSetup<T> {
	showTooltip: (event: PointerEvent<T>) => void;
	hideTooltip: () => void;
	tooltip?: JSX.Element;

	onMouseEnter: (event: MouseEvent<T>) => void;
}

interface TooltipOptions {
	persist?: boolean;
	align?: 'left' | 'center' | 'right';
	className?: string;
	skipHorizontalAlign?: boolean;
	skipVerticalAlign?: boolean;
	hideOnMouseLeave?: boolean;
	offset?: {
		x?: number;
		y?: number;
	};

	onShow?: () => void;
	onHide?: () => void;
	onTooltipFocus?: () => void;
	onTooltipBlur?: () => void;
}

export default function useTooltip<T extends HTMLElement | SVGSVGElement>(
	component: string | ReactNode,
	{
		persist = false,
		align = 'center',
		className,

		skipHorizontalAlign,
		skipVerticalAlign,
		hideOnMouseLeave = true,
		offset = {},

		onShow,
		onHide,
		onTooltipFocus,
		onTooltipBlur,
	}: TooltipOptions = {}
): TooltipSetup<T> {
	const [position, setPosition] = useState(null);

	const showTooltip = (event: PointerEvent<T> | MouseEvent<T>) => {
		const boundingRect = event.currentTarget.getBoundingClientRect();
		let x = clamp(
			boundingRect.x + boundingRect.width / 2 + (offset?.x || 0),
			0,
			window.innerWidth - boundingRect.width
		);

		if (align === 'left') {
			x -= boundingRect.width / 2;
		}

		if (align === 'right') {
			x -= window.innerWidth - boundingRect.width * 2;
		}

		const y = boundingRect.y + (offset?.y || 0);

		setPosition({ x, y });

		if (onShow) {
			onShow();
		}

		if (!persist && hideOnMouseLeave) {
			event.currentTarget.addEventListener('mouseleave', hideTooltip, {
				once: true,
			});
		}
	};

	const hideTooltip = () => {
		setPosition(null);

		if (onHide) {
			onHide();
		}
	};

	const handleTooltipBlur = () => {
		if (onTooltipBlur) {
			onTooltipBlur();
		}
	};

	const tooltip = (
		<Tooltip
			align={align}
			persist={persist}
			component={component}
			className={className}
			position={position}
			skipHorizontalAlign={skipHorizontalAlign}
			skipVerticalAlign={skipVerticalAlign}
			onOutsideInteract={hideTooltip}
			onMouseEnter={onTooltipFocus}
			onMouseLeave={handleTooltipBlur}
		/>
	);

	if (!component) {
		return {
			onMouseEnter: showTooltip,
			showTooltip,
			hideTooltip,
			tooltip: null,
		};
	}

	return {
		onMouseEnter: showTooltip,
		showTooltip,
		hideTooltip,
		tooltip: !!position && tooltip,
	};
}
