import { ReactNode } from 'react';

export const symbol_vertical_split: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M143-367q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T143-427h269q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T412-367H143Zm0 167q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T143-260h269q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T412-200H143Zm0-333q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T143-593h269q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T412-533H143Zm0-167q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T143-760h269q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T412-700H143Zm466 500q-24.75 0-42.375-17.625T549-260v-440q0-24.75 17.625-42.375T609-760h179q24.75 0 42.375 17.625T848-700v440q0 24.75-17.625 42.375T788-200H609Zm0-60h179v-440H609v440Zm90-220Z"
		/>
	</svg>
);
