import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import useMixedState from 'pkg/hooks/useMixedState';
import { UserSex, translatedSexString } from 'pkg/api/models/user';

import * as Input from 'components/form/inputs';
import Row from 'components/layout/row';
import Column from 'components/layout/column';
import {
	SettingControl,
	SettingDescription,
	SettingLabel,
	SettingRow,
} from 'components/settings/Settings';

import * as ContextMenu from 'design/context_menu';
import Button from 'design/button';

interface ConfirmGenderProps {
	handleConfirmGender: () => void;
}
export function ConfirmGenderButton({
	handleConfirmGender,
}: ConfirmGenderProps): JSX.Element {
	const contextMenu = ContextMenu.useContextMenu();
	const handleConfirm = async () => {
		handleConfirmGender();
		contextMenu.close();
	};
	return (
		<Button secondary label={t('Confirm gender')} onClick={handleConfirm} />
	);
}
interface GenderSettingProps {
	group: models.group.Group;
	refresh?: () => void;
}

export default function GenderSettings({
	group,
	refresh,
}: GenderSettingProps): JSX.Element {
	const [formState, setFormState] = useMixedState({
		radio: 1,
	});

	const handleRadioChange = (value: number) => {
		setFormState({ radio: value });
	};

	const handleConfirmGender = async () => {
		const [request] = await models.update(group, {
			sex: formState.radio,
		});
		if (request.ok) {
			refresh();
		}
	};

	return (
		<SettingRow>
			<SettingLabel>{t('Gender')}</SettingLabel>
			<SettingDescription>
				{translatedSexString(group.sex, t('Select gender'))}
			</SettingDescription>
			<SettingControl>
				<div>
					<ContextMenu.Menu
						toggleWith={
							<Button small icon="edit">
								{t('Change')}
							</Button>
						}>
						<ContextMenu.Heading>
							<div>{t('Set group gender')}</div>
						</ContextMenu.Heading>
						<ContextMenu.Pane>
							<Column>
								<Row>
									<Input.Group>
										<Input.Control
											type="radio"
											value={UserSex.Male}
											checked={formState.radio === UserSex.Male}
											onChange={handleRadioChange}
											label={translatedSexString(models.user.UserSex.Male)}
										/>
										<Input.Control
											type="radio"
											value={UserSex.Female}
											checked={formState.radio === UserSex.Female}
											onChange={handleRadioChange}
											label={translatedSexString(models.user.UserSex.Female)}
										/>
										<Input.Control
											type="radio"
											value={UserSex.NotApplicable}
											checked={formState.radio === UserSex.NotApplicable}
											onChange={handleRadioChange}
											label={translatedSexString(
												models.user.UserSex.NotApplicable
											)}
										/>
									</Input.Group>
								</Row>
								<ConfirmGenderButton
									handleConfirmGender={handleConfirmGender}
								/>
							</Column>
						</ContextMenu.Pane>
					</ContextMenu.Menu>
				</div>
			</SettingControl>
		</SettingRow>
	);
}
