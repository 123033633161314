import { useRef } from 'react';

import { useCollection } from 'pkg/api/use_collection';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import useMixedState from 'pkg/hooks/useMixedState';
import DateTime from 'pkg/datetime';

import { CalendarContext, CalendarStateProps } from 'routes/group/calendar';
import ScheduleView from 'routes/group/calendar/views/schedule';
import UserProfileCalendarActionBar from 'routes/user/profile/calendar/action-bar';
import * as calendarStyles from 'routes/group/calendar/styles.css';

interface UserProfileCalendarProps {
	userId: number;
}

export default function UserProfileCalendar({
	userId,
}: UserProfileCalendarProps) {
	const scrollItemRef = useRef(null);
	const [calendarState, setCalendarState] = useMixedState<CalendarStateProps>({
		showToday: false,
		scheduleDate: new Date(),
	});

	const eventsCollection = useCollection<models.event.Event>(
		endpoints.Events.Index(),
		{
			queryParams: new URLSearchParams({
				user_ids: [userId].toString(),
				from: Math.ceil(
					new DateTime(calendarState.scheduleDate).startOfDay / 1000
				).toString(),
			}),
			showAllResults: true,
		}
	);

	return (
		<CalendarContext.Provider
			value={{
				eventsCollection,
				calendarState,
				setCalendarState,
				scrollItemRef,
			}}>
			<UserProfileCalendarActionBar userId={userId} />
			<div ref={scrollItemRef} className={calendarStyles.wrapper}>
				<div className={calendarStyles['page-wrap']}>
					<ScheduleView />
				</div>
			</div>
		</CalendarContext.Provider>
	);
}
