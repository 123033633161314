import { ReactNode } from 'react';

export const symbol_custom_typography: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M512-160v50q0 12.75-8.675 21.375Q494.649-80 481.825-80 469-80 460.5-88.625T452-110v-160q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T512-270v50h298q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-160H512Zm-362 0q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-220h213q13 0 21 8.675 8 8.676 8 21.5 0 12.825-8.625 21.325T362-160H150Zm226-364h209l38 104q3 8.8 10.5 14.4t17.625 5.6Q668-400 677-413.5t3-28.5L519-861q-3-9-10.604-14-7.604-5-17.49-5h-22.812q-9.886 0-17.49 5.5Q443-869 440-860L280-442q-6 15 3.241 28.5t25.313 13.5Q319-400 327-406q8-6 12-15l37-103Zm21-55 82-230h2l82 230H397Z"
		/>
	</svg>
);
