import { t } from '@transifex/native';
import { ChangeEvent } from 'react';

import spacing from 'pkg/config/spacing';

import AssetImage from 'components/AssetImage';
import Connection from 'components/connection';

import Column from 'components/layout/column';
import * as Input from 'components/form/inputs';
import InfoBox from 'components/form/info-box';

interface CreacteConnectionProps {
	onCanCreateRequest: (email: string) => void;
}

export default function CreateConnection({
	onCanCreateRequest,
}: CreacteConnectionProps): JSX.Element {
	const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.currentTarget.checkValidity()) {
			onCanCreateRequest(event.currentTarget.value);
		}
	};

	return (
		<Column
			spacing={spacing._8}
			blockSpacing={spacing._9}
			inlineSpacing={spacing._8}>
			<Connection
				leftComponent={<AssetImage src="img/video-options/wingfield.svg" />}
				rightComponent={
					<AssetImage src="img/video-options/hosted-by-360.svg" />
				}
			/>

			<Column spacing={spacing._7}>
				<InfoBox>
					<strong>
						{t("You haven't connected to Wingfield yet", {
							_context: 'wingfield video modal',
						})}
					</strong>
					{t(
						'Link your 360Player and Wingfield accounts by first providing the email for your Wingfield account, and then confirm the connection request.',
						{ _context: 'wingfield video modal' }
					)}
				</InfoBox>
				<Input.Group
					label={t('Your Wingfield account email', {
						_context: 'wingfield video modal',
					})}
					hint={t('Wingfield will send a connection request to this email.', {
						_context: 'wingfield video modal',
					})}>
					<Input.Field
						name="email"
						type="email"
						placeholder="john@example.com"
						onChange={handleEmailChange}
					/>
				</Input.Group>
			</Column>
		</Column>
	);
}
