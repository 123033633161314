import { ReactNode } from 'react';

export const symbol_asterisk: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M479.825-120Q467-120 458.5-128.625T450-150v-258L268-226q-8.8 9-20.9 9-12.1 0-21.1-8.842-9-8.842-9-21T226-268l182-182H150q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-510h259L226-693q-9-8.8-9-20.9 0-12.1 8.842-21.1 8.842-9 21-9T268-735l182 182v-257q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-810v258l183-183q8.8-9 20.9-9 12.1 0 21.1 8.842 9 8.842 9 21T735-693L552-510h258q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-450H553l182 182q9 8.8 9 20.9 0 12.1-8.842 21.1-8.842 9-21 9T693-226L510-409v259q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Z"
		/>
	</svg>
);
