import { ReactNode } from 'react';

export const symbol_emoji_nature: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m723-611-38 34q-11 10-28 11.5t-31-8.5q-14-10-16.5-27t1.5-30l17-58-43-24q-14-8-18-22.5t1-30.5q5-16 17-24t29-8h49l15-45q5-15 16.5-26.5T723-881q17 0 28.5 11.5T768-843l15 45h49q15 0 28 8.5t18 23.5q5 14 1 29.5T860-713l-43 24 17 58q5 14 2.5 30.5T820-574q-14 9-31 8t-28-11l-38-34Zm0-60q17 0 28.5-11.5T763-711q0-17-11.5-28.5T723-751q-17 0-28.5 11.5T683-711q0 17 11.5 28.5T723-671ZM529-231q22 54-15.5 102.5T415-80q-32 0-58-15.5T316-143q-67 7-122-47.5T143-312q-30-16-46.5-48.5T80-422q0-67 47-99.5t113-3.5l60 24q17-37 49.5-57.5T421-581v-62q0-10 7-17t18-7q11 0 17.5 6.5T470-643v66q41 8 72 43t38 67h65q11 0 18 7t7 18q0 11-7 18t-18 7h-61q-3 39-23 71.5T504-296l25 65Zm-231 29q-3-27 2-55.5t15-55.5q-27 14-55.5 17t-57.5-1q2 33 33 64t63 31Zm-74-153q40 0 84.5-21.5T368-408l-142-58q-25-11-55.5 2.5T140-424q0 26 33.5 47.5T224-355Zm191 215q31 0 49.5-20.5T472-209l-60-155q-18 29-36 61.5T358-220q0 20 14.5 50t42.5 30Zm65-212q26-15 35-36t9-45q0-35-25.5-60.5T438-519q-24 0-46.5 11T358-472l87 34 35 86Zm-165 39Z"
		/>
	</svg>
);
