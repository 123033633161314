const overlap = {
	name: 'overlap',
	objects: [
		{
			type: 'path',
			attributes: {
				d: 'M4.3 6.3H14.7V8H8V14.7H4.3V6.3ZM8 16H3V5H16V8H21V19H8V16ZM16 9.3H19.7V17.7H9.3V16H16V9.3ZM14.7 9.3V14.7H9.3V9.3H14.7Z',
				fillRule: 'evenodd',
				clipRule: 'evenodd',
			},
		},
	],
};

export default overlap;
