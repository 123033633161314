import { ReactNode } from 'react';

export const symbol_emoji_language: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M624-40q-97 0-175.5-57T341-247q-2-7-1-13.5t6-12.5q5-6 10.5-9t13.5-3h312.49q1.255-14.173 1.882-27.702.628-13.529.628-27.702V-364.5q0-12.5-2.5-24.5H635q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T635-449h197q-20-35-49.5-62T716-554q-13-5-18.5-16.5t-1.5-23q4-11.5 13.5-17.5t20.5-2q86 33 138 108.5T920-337q0 123.75-86.333 210.375Q747.333-40 624-40Zm-79-75q-11-27-19-54t-13-56h-97q21 38 54 66.5t75 43.5Zm79 14q18-29 30-60.5t19-63.5h-99q3 13 18.5 54t31.5 70Zm79-14q42-15 75-43.5t54-66.5h-97q-5 29-13 56t-19 54Zm40-170h111.467q2.533-12 4.033-25 1.5-13 1.5-27.235 0-14.236-1.5-26.53T854-389H743q2 12 2.5 24t.5 25q0 14.094-.5 27.547Q745-299 743-285Zm-405.824-42q-123.823 0-210.5-86.676Q40-500.353 40-624.176 40-748 126.676-834q86.677-86 210.5-86Q461-920 547-834q86 86 86 209.824 0 123.823-86 210.5Q461-327 337.176-327Zm-.199-60Q435-387 504-456.477q69-69.478 69-167.5Q573-722 503.809-791T337-860q-98.032 0-167.516 69.191T100-624q0 98.032 69.477 167.516Q238.955-387 336.977-387ZM234.5-655q12.5 0 21-9t8.5-21.5q0-12.5-8.625-21T234-715q-12 0-21 8.625T204-685q0 12 9 21t21.5 9ZM337-475q45.6 0 81.225-25.594Q453.85-526.188 470-566H204q16.15 39.812 51.775 65.406Q291.4-475 337-475Zm102.5-180q12.5 0 21-9t8.5-21.5q0-12.5-8.625-21T439-715q-12 0-21 8.625T409-685q0 12 9 21t21.5 9ZM337-624Z"
		/>
	</svg>
);
