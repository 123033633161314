import { Fragment, useState, useEffect } from 'react';
import { t } from '@transifex/native';
import { useMediaQuery } from 'react-responsive';
import { T } from '@transifex/react';

import * as styles from 'pkg/config/styles';
import { spacing } from 'pkg/config/styles';

import * as routes from 'pkg/router/routes';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as sdk from 'pkg/core/sdk';
import * as models from 'pkg/api/models';
import copyString from 'pkg/copyString';
import Link from 'pkg/router/Link';
import { useCurrentOrganization } from 'pkg/identity';

import * as Card from 'components/Card';
import SectionTitle from 'components/SectionTitle';
import Icon from 'components/icon';

import * as Input from 'components/form/inputs';
import Toggle from 'components/form/Toggle';
import Column from 'components/layout/column';
import InfoBox from 'components/form/info-box';
import Row from 'components/layout/row';

import Button from 'design/button';

import * as css from './styles.css';

interface InviteCodeSettingsProps {
	group: models.group.Group;
	refresh: () => void;
}
export default function InviteCodeSettings({
	group,
	refresh,
}: InviteCodeSettingsProps): JSX.Element {
	const org = useCurrentOrganization();

	const generateTeamCode = async (groupId: number) => {
		await sdk.post(endpoints.Groups.GenerateInviteCode(groupId), {});
		refresh();
	};

	const [didCopyGroupCode, setDidCopyGroupCode] = useState(false);
	const [didCopyInviteLink, setDidCopyInviteLink] = useState(false);

	const [allowInvites, setAllowInvites] = useState(group.allowInvites);
	const handleAllowInvitesChange = async () => {
		setAllowInvites(!allowInvites);

		await models.update(group, {
			allowInvites: !allowInvites,
		});
	};

	const handleRefreshCode = async () => {
		const confirmMessage = t(
			'Are you sure you want to create a new invite code? The previous code will stop working immediately, and any users not connected to the team will have to use the new one instead.'
		);

		if (group?.inviteCode && !confirm(confirmMessage)) {
			return false;
		}

		await generateTeamCode(group?.id);

		setDidCopyGroupCode(false);
	};
	const inviteLink = 'https://app.360Player.com/join/' + group.inviteCode;

	const handleCopyCode = () => {
		setDidCopyGroupCode(true);
		setDidCopyInviteLink(false);
	};

	const handleCopyInviteLink = () => {
		setDidCopyInviteLink(true);
		setDidCopyGroupCode(false);
	};
	useEffect(() => {
		if (didCopyGroupCode) {
			copyString(group.inviteCode);
		}
	}, [didCopyGroupCode]);

	useEffect(() => {
		if (didCopyInviteLink) {
			copyString(inviteLink);
		}
	}, [didCopyInviteLink]);

	const isSmallScreen: boolean = useMediaQuery({
		maxWidth: styles.breakpoint.toMedium,
	});

	const createCode = (
		<Button primary large icon="person_add" onClick={handleRefreshCode}>
			{t('Create an invite code')}
		</Button>
	);

	const link = (
		<Link href={routes.Group.Show(org.id, group.id)}>{t('team')}</Link>
	);

	const displayCode = (
		<Fragment>
			<Input.Group>
				<Input.Field value={group.inviteCode} readOnly>
					<Input.Suffix onClick={handleCopyCode}>{t('Copy')}</Input.Suffix>
				</Input.Field>
			</Input.Group>
			{didCopyGroupCode && (
				<span className={css.groupCodeCopyTrue}>
					<Fragment>
						<Icon name="check" /> {t('Invite code copied!')}
					</Fragment>
				</span>
			)}
		</Fragment>
	);

	const displayLink = (
		<Fragment>
			<Input.Group>
				<Input.Field value={inviteLink} readOnly>
					<Input.Suffix onClick={handleCopyInviteLink}>
						{t('Copy')}
					</Input.Suffix>
				</Input.Field>
			</Input.Group>
			{didCopyInviteLink && (
				<span className={css.groupCodeCopyTrue}>
					<Fragment>
						<Icon name="check" /> {t('Invite link copied!')}
					</Fragment>
				</span>
			)}
		</Fragment>
	);
	let groupCodeCopy = (
		<Row columns="25% 25% auto">
			<div className={css.settingLabel}>
				{models.group.isOrganization(group) ? t('Club code') : t('Group code')}
			</div>
			<Column>{group.inviteCode ? displayCode : createCode}</Column>
			<div className={css.clickable}>
				<Icon name="reload" />
				<div onClick={handleRefreshCode}>{t('Generate new code')}</div>
			</div>
		</Row>
	);
	let inviteLinkCopy = (
		<Row columns="25% auto">
			<div className={css.settingLabel}>
				{models.group.isOrganization(group)
					? t('Club invite link')
					: t('Invite link')}
			</div>
			<Column>{displayLink}</Column>
		</Row>
	);
	if (isSmallScreen) {
		groupCodeCopy = (
			<Column>
				<div className={css.settingLabel}>{t('Group code')}</div>
				<div>
					<Column>{group.inviteCode ? displayCode : createCode}</Column>
					<div className={css.clickable}>
						<Icon name="reload" />
						<div onClick={handleRefreshCode}>{t('Generate new code')}</div>
					</div>
				</div>
			</Column>
		);
		inviteLinkCopy = (
			<Column>
				<div className={css.settingLabel}>{t('Invite link')}</div>
				<Column>{displayLink}</Column>
			</Column>
		);
	}

	return (
		<Fragment>
			<Column>
				<SectionTitle>{t('Invite Settings')}</SectionTitle>
				<Card.Base $noBorder>
					<Card.Body>
						<Column spacing={spacing._5}>
							<InfoBox color="blue">
								{models.group.isOrganization(group) ? (
									<div>
										<T
											_str="Invite people to join your club by sharing the code or the link below! If you want to add an administrator to manage the club, go to {team} and invite them there."
											team={link}
										/>
									</div>
								) : (
									t(
										"It's easy to get everyone onboard, simply share the group code or invite link with your team and they can connect to the team when creating their account. Administrators joining this way will require approval before joining."
									)
								)}
							</InfoBox>
							<Row columns="25% auto auto">
								<div className={css.settingLabel}>
									{models.group.isOrganization(group)
										? t('People can join this club')
										: t('People can join this group')}
								</div>
								<div className={css.joinDescription}>
									{t(
										'If this feature is ON, new members can be invited and join the team.'
									)}
								</div>
								<Toggle
									standAlone
									isActive={allowInvites}
									onChange={handleAllowInvitesChange}
								/>
							</Row>
							<Card.Divider />
							<div className={!allowInvites && css.disabled}>
								<Column spacing={spacing._5}>
									{groupCodeCopy}

									{group.inviteCode !== null && (
										<Fragment>
											<Card.Divider /> {inviteLinkCopy}
										</Fragment>
									)}
								</Column>
							</div>
						</Column>
					</Card.Body>
				</Card.Base>
			</Column>
		</Fragment>
	);
}
