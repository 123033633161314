import { ReactNode } from 'react';

export const symbol_currency_yuan: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M450-150v-242H270q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T270-452h177L229-794q-10-15-1.191-30.5Q236.617-840 255-840q8.088 0 14.706 3.684T280-826l200 315 200-315q3.707-6.632 10.379-10.316Q697.052-840 705.207-840 723-840 732-824.5t-1 30.5L513-452h177q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T690-392H510v242q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T450-150Z"
		/>
	</svg>
);
