import { ReactNode } from 'react';

export const symbol_trackpad_input: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M553-80q-21.882 0-41.941-8T475-112L299-289q-9-9-9-21t8-21l5.7-5.7Q317-350 334-354t34 1l82 23v-320q0-12.75 9-21.375T480.5-680q12.5 0 21 8.625T510-650v360q0 15.349-12 24.174Q486-257 472-261l-85-24 131 130q7 8 15.5 11.5T553-140h187q38 0 64-26.438 26-26.437 26-63.562v-170q0-12.75 9-21.375T860.5-430q12.5 0 21 8.625T890-400v170q0 63-43.5 106.5T740-80H553Zm53.825-290Q594-370 585.5-378.625T577-400v-130q0-12.75 9-21.375T607.5-560q12.5 0 21 8.625T637-530v130q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Zm126 0Q720-370 711.5-378.625T703-400v-80q0-12.75 9-21.375T733.5-510q12.5 0 21 8.625T763-480v80q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM740-140H518h222Zm-600-60q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h640q24 0 42 18t18 42v150q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T780-630v-150H140v520h103q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T243-200H140Z"
		/>
	</svg>
);
