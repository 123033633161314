import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import { AttendanceStatuses } from 'pkg/api/models/event_user';

import EventUserStatus from 'routes/event/single/event-users/StatusPicker';
import * as UserRowStyles from 'routes/event/components/UserRowStyles';

import Avatar from 'components/avatar';

import Column from 'components/layout/column';

import * as css from './styles.css';

interface UserRowProps {
	event: models.event.Event;
	eventUser: models.eventUser.EventUser;
}

export default function UserRow({ eventUser }: UserRowProps): JSX.Element {
	const canEditUser = models.canEdit(eventUser);

	const showAttendanceStatus = [
		AttendanceStatuses.Injured,
		AttendanceStatuses.Sick,
		AttendanceStatuses.Other,
	].includes(eventUser.attendanceStatus);

	return (
		<Column spacing={styles.spacing._2}>
			<UserRowStyles.ListItem>
				<UserRowStyles.InfoWrapper>
					<Avatar user={eventUser.user} size={30} />
					<span>
						<UserRowStyles.Name>
							{models.user.fullName(eventUser.user)}
						</UserRowStyles.Name>
						<UserRowStyles.Role>
							{showAttendanceStatus && (
								<span className={css.capitalized}>
									{models.eventUser.getDeclineReasonTranslation(
										eventUser.attendanceStatus
									)}
								</span>
							)}
						</UserRowStyles.Role>
					</span>
				</UserRowStyles.InfoWrapper>

				{canEditUser && <EventUserStatus eventUser={eventUser} />}
			</UserRowStyles.ListItem>

			{canEditUser && eventUser.comment && (
				<UserRowStyles.Comment>{eventUser.comment}</UserRowStyles.Comment>
			)}
		</Column>
	);
}
