import { ReactNode } from 'react';

export const symbol_screenshot_tablet: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M100-160q-24.75 0-42.375-17.625T40-220v-520q0-24.75 17.625-42.375T100-800h760q24.75 0 42.375 17.625T920-740v520q0 24.75-17.625 42.375T860-160H100Zm30-580h-30v520h30v-520Zm60 520h580v-520H190v520Zm640-520v520h30v-520h-30Zm0 0h30-30Zm-700 0h-30 30Zm540 420H570q-8 0-14 6t-6 14q0 8 6 14t14 6h118q9 0 15.5-7t6.5-16v-117q0-8-6-14t-14-6q-8 0-14 6t-6 14v100ZM290-640h100q8 0 14-6t6-14q0-8-6-14t-14-6H273q-9 0-16 6.5t-7 15.5v118q0 8 6 14t14 6q8 0 14-6t6-14v-100Z"
		/>
	</svg>
);
