import { ReactNode } from 'react';

export const symbol_shop_two: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M100-80q-24 0-42-18t-18-42v-429q0-12.75 8.675-21.375Q57.351-599 70.175-599 83-599 91.5-590.375T100-569v429h579q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q709-97 700.375-88.5T679-80H100Zm120-120q-24 0-42-18t-18-42v-488q0-12.75 8.625-21.375T190-778h212v-82q0-24 18-42t42-18h156q24 0 42 18t18 42v82h212q12.75 0 21.375 8.625T920-748v488q0 24-18 42t-42 18H220Zm0-60h640v-458H220v458Zm242-518h156v-82H462v82ZM220-260v-458 458Zm230-124q0 9 7.5 13.5t15.5-.5l157-101q7-4.5 7-12.75T630-498L473-599q-8-5-15.5-.5T450-586v202Z"
		/>
	</svg>
);
