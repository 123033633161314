import { ReactNode } from 'react';

export const symbol_anchor: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-80q-66 0-130.5-24T234-167q-51-39-82.5-88T120-355v-70q0-9 8-13t16 1l83 62q11 8 12.5 21.5T231-330l-34 34q31 58 106 103.5T450-142v-388H350q-13 0-21.5-8.5T320-560q0-13 8.5-21.5T350-590h100v-74q-38-14-59-42t-21-64q0-46 32.5-78t77.5-32q46 0 78 32t32 78q0 36-21 64t-59 42v74h100q13 0 21.5 8.5T640-560q0 13-8.5 21.5T610-530H510v388q72-5 147-50.5T763-296l-34-34q-10-10-8.5-23.5T733-375l83-62q8-5 16-1t8 13v70q0 51-31.5 100T726-167q-51 39-115.5 63T480-80Zm0-640q21 0 35.5-15t14.5-35q0-21-14.5-35.5T480-820q-20 0-35 14.5T430-770q0 20 15 35t35 15Z"
		/>
	</svg>
);
