import { Fragment, useState } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import { MembershipRole } from 'pkg/models/membership';

import { Account } from 'pkg/api/models/onboarding_info';
import * as actions from 'pkg/actions';
import * as api from 'pkg/api';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';

import {
	useOnboardingAccountPayload,
	OnboardingAccountPayload,
	useOnboardingState,
} from 'routes/public/hooks';
import {
	fetchCurrentAccount,
	useOnboardingContext,
} from 'routes/public/onboarding';
import Button from 'routes/public/styles/Button';
import LogoutButton from 'routes/public/styles/LogoutButton';
import * as publicStyles from 'routes/public/styles/styles.css';
import Title from 'routes/public/styles/Title';

import Column from 'components/layout/column';

export default function WardOwnAccount(): JSX.Element {
	const { goTo, resetStore } = useOnboardingContext();

	const onboardingState = useOnboardingState();

	const targetAccountFromState = onboardingState.get<Account>('targetAccount');

	const [isSaving, setIsSaving] = useState(false);

	const getPayload = useOnboardingAccountPayload();

	const handleCreateWardAccount = async () => {
		setIsSaving(true);

		const [ok, parentAccount] = await fetchCurrentAccount();

		if (!ok) {
			actions.flashes.show({
				title: t(`Couldn't auth user`),
			});

			resetStore();
			goTo('start');
			return;
		}

		const wardAccount: Account = {
			...targetAccountFromState,
			countryId: parentAccount.countryId,
		};

		await onboardingState.setTargetAccount(wardAccount);

		let canContinue: boolean;

		try {
			const [request, wardAccount] = await api.post<
				models.account.Account,
				OnboardingAccountPayload
			>(endpoints.Accounts.CreateChildAccountForAccount(parentAccount.id), {
				...getPayload(true),
			});

			if (!request.ok) {
				setIsSaving(false);

				throw new Error(`${request.status}`);
			}

			const groupCode = onboardingState.get<string>('groupCode');

			const [joinRequest] = await api
				.forAccount(wardAccount.id)
				.post(endpoints.Groups.JoinFromInviteCode(), {
					code: groupCode,
					role: MembershipRole.User,
				});

			if (!joinRequest.ok) {
				setIsSaving(false);

				throw new Error(`${joinRequest.status}`);
			}

			canContinue = true;
		} catch (error: unknown) {
			canContinue = false;

			const status = Number.parseInt((error as Error).message, 10);

			let title = t('Something went wrong');
			let message = t(
				`If you think this error isn't supposed to happen, please contact 360Player support.`
			);

			switch (status) {
				case 400:
					title = t('Could not create account');
					break;
				case 409:
					title = t('Email already in use');
					message = t('This email is already registered in 360Player');
					break;
			}

			actions.flashes.show({
				title: title,
				message: message,
			});
		} finally {
			if (!canContinue) {
				setIsSaving(false);
				return;
			}

			goTo('ward-finished');
		}
	};

	return (
		<Fragment>
			<Column spacing={styles.spacing._8} className={publicStyles.main}>
				<Title
					title={t('Should your child be able to access their own account?')}
					description={t(
						`If so, we will need to collect a unique email address for your child. If you don't create a login for your child, you will still be able to manage team related activities on their behalf.`
					)}
				/>

				<Column>
					<Button
						label={t('Yes, create a login')}
						onClick={() => goTo('ward-account-details')}
						testid="onboarding.child_own_account.yes_button"
						busy={isSaving}
					/>
					<Button
						label={t('No, continue without')}
						onClick={handleCreateWardAccount}
						testid="onboarding.child_own_account.no_button"
						busy={isSaving}
					/>
				</Column>
			</Column>

			<div className={publicStyles.footer}>
				<LogoutButton />
			</div>
		</Fragment>
	);
}
