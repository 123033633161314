import { t } from '@transifex/native';
import { Component } from 'react';
import { connect } from 'react-redux';

import { getGroupVideos } from 'pkg/selectors/group_videos.selector';

import { fetchGroupVideos } from 'pkg/actions/group_videos';

import * as routes from 'pkg/router/routes';
import { pushState } from 'pkg/router/state';
import { AccountIdentityContext } from 'pkg/identity/account';

import Video from 'routes/dashboard/components/VideoItem';

import RelativeDateTime from 'components/RelativeDateTime';
import AssetImage from 'components/AssetImage';
import { ScrollableWrapper } from 'components/scrollable-wrapper';
import SectionTitle from 'components/SectionTitle';

import { PlaceholderType, Skeleton } from 'components/loaders/skeleton';
import VideoModal from 'components/video-library/modals/Video';
import Column from 'components/layout/column';

import MissingEntities from 'design/placeholders/block';
import Button from 'design/button';

class GroupVideos extends Component {
	static contextType = AccountIdentityContext;

	state = {
		fetchingVideos: true,
		showVideoModal: false,
	};

	async componentDidMount() {
		await this.props.fetchGroupVideos(this.props.groupId);

		this.setState({ fetchingVideos: false });
	}

	componentDidUpdate(prevProps) {
		if (this.props.groupId !== prevProps.groupId) {
			this.props.fetchGroupVideos(this.props.groupId);
		}
	}

	showVideoModal = () => {
		this.setState({
			showVideoModal: true,
		});
	};

	closeVideoModal = () => {
		this.setState({
			showVideoModal: false,
		});
	};

	handleVideoSave = (video) => {
		pushState(routes.Video.Show(this.context.organization.id, video.id));
	};

	get videos() {
		return this.props.videos.map((video) => (
			<Video
				href={routes.Video.Show(this.context.organization.id, video.id)}
				title={video.title}
				sourceUrl={video.thumbnailUrl}
				caption={<RelativeDateTime dateTime={video.createdAt} />}
				key={video.id}
			/>
		));
	}

	get hasVideos() {
		return this.props.videos.size > 0;
	}

	get renderVideos() {
		if (this.state.fetchingVideos) {
			return (
				<ScrollableWrapper chevrons>
					<Skeleton
						placeholderType={PlaceholderType.DashboardVideos}
						numItems={5}
					/>
				</ScrollableWrapper>
			);
		}

		const button = (
			<Button
				secondary
				label={t(`Add video!`)}
				onClick={this.showVideoModal}
				icon="add"
			/>
		);

		if (!this.hasVideos) {
			const missingEntitiesTitle = this.props.isAdminOrStaff
				? t(`Analyze your videos`)
				: t(`No videos added yet!`);

			const missingEntitiesDescription = this.props.isAdminOrStaff
				? t(
						`Use our powerful video analytics tool to get insights for your team.`
					)
				: t(
						`Here you can see your team’s videos to analyze and create highlights!`
					);

			const missingEntitiesHelpUrl = !this.props.isParent
				? 'https://help.360player.com/article/18-how-to-add-a-video'
				: undefined;

			const image = (
				<AssetImage src="img/missing-entities/video-analytics.svg" />
			);

			return (
				<MissingEntities
					noBorder
					dashboard
					title={missingEntitiesTitle}
					description={missingEntitiesDescription}
					helpUrl={missingEntitiesHelpUrl}
					image={image}
					actions={!this.props.isParent && button}
				/>
			);
		}

		return (
			<ScrollableWrapper chevrons videoItems>
				{this.videos}
			</ScrollableWrapper>
		);
	}

	render() {
		const goToVideoLibrary = () => {
			pushState(
				routes.VideoLibrary.Index(
					this.context.organization.id,
					this.props.groupId
				)
			);
		};

		return (
			<Column>
				<SectionTitle icon="smart_display">
					{t(`Team videos`)}
					{this.hasVideos && (
						<Button
							iconPosition="right"
							icon="chevron_right"
							noPadding
							inline
							onClick={goToVideoLibrary}
							label={t(`View all`)}
						/>
					)}
				</SectionTitle>

				{this.renderVideos}

				{this.state.showVideoModal && (
					<VideoModal
						onClose={this.closeVideoModal}
						afterSave={this.handleVideoSave}
					/>
				)}
			</Column>
		);
	}
}

const mapStateToProps = (state, props) => {
	return {
		videos: getGroupVideos(state, { groupId: props.groupId }).take(5),
	};
};

const actions = { fetchGroupVideos };

export default connect(mapStateToProps, actions)(GroupVideos);
