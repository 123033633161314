import { ReactNode } from 'react';

export const symbol_line_start: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M170-390q-37.8 0-63.9-26.141t-26.1-64Q80-518 106.1-544t63.9-26q29.086 0 52.543 17T255-510h595q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-450H255q-9 26-32.457 43T170-390Z"
		/>
	</svg>
);
