import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RRule, rrulestr } from 'rrule';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import {
	getTimeZoneFromString,
	getGmtString,
	getTimeZoneFromSessionStorage,
} from 'pkg/timezone';
import { RootState } from 'pkg/reducers';
import * as selectors from 'pkg/selectors';
import DateTime, { Granularity } from 'pkg/datetime';
import * as models from 'pkg/api/models';

import { DescriptionRow } from 'routes/event/components/DescriptionRow';
import { RRuleToText } from 'routes/event/rRuleConfig';
import EventLocation from 'routes/event/components/location';

import UserLink from 'containers/user/Link';

import { FormattedContent } from 'components/formatted-content';
import Icon from 'components/icon';
import Avatar from 'components/avatar';

import Column from 'components/layout/column';
import GalleryPreview from 'components/attachment/gallery/preview';

interface DescriptionProps {
	eventSeries: models.eventSeries.EventSeries;
}

export default function Description({
	eventSeries,
}: DescriptionProps): JSX.Element {
	const rule = eventSeries.rRuleString
		? RRule.fromString(eventSeries?.rRuleString)
		: new RRule();
	const createdByUser = useSelector((state: RootState) =>
		selectors.users.find(state, eventSeries.userId)
	);

	const rRuleString = useMemo(() => {
		if (eventSeries.rRuleString) {
			return rrulestr(eventSeries.rRuleString);
		}

		return null;
	}, [eventSeries.rRuleString]);

	const startsAt = new DateTime(
		new Date(
			rRuleString?.options.dtstart.getUTCFullYear(),
			rRuleString?.options.dtstart.getUTCMonth(),
			rRuleString?.options.dtstart.getUTCDate(),
			rRuleString?.options.dtstart.getUTCHours(),
			rRuleString?.options.dtstart.getUTCMinutes()
		)
	);

	const durationEnd = startsAt.next(Granularity.second, eventSeries.duration);
	const durationString = `${startsAt.toLocaleTimeString({
		hour: '2-digit',
		minute: '2-digit',
	})} - ${durationEnd.toLocaleTimeString({
		hour: '2-digit',
		minute: '2-digit',
	})}`;

	const timeZone = useMemo(() => {
		const currentTimeZone = getTimeZoneFromSessionStorage();

		if (
			!eventSeries.timezone ||
			currentTimeZone?.name === eventSeries.timezone
		) {
			return null;
		}

		const timeZoneString = getGmtString(
			getTimeZoneFromString(eventSeries.timezone) || currentTimeZone
		);

		return timeZoneString;
	}, [eventSeries.timezone]);

	const createdBy = () => {
		if (!models.canEdit(eventSeries) || !createdByUser?.id) {
			return null;
		}

		return (
			<DescriptionRow>
				<Avatar user={createdByUser} size={24} />
				<div>
					{t('Created by')} <UserLink userId={createdByUser.id} />
				</div>
			</DescriptionRow>
		);
	};

	return (
		<Column spacing={styles.spacing._5}>
			{eventSeries.isPrivate && (
				<DescriptionRow>
					<Icon name="eye-hidden" />
					<div>{t('This event series is only visible to invited users')}</div>
				</DescriptionRow>
			)}
			<DescriptionRow>
				<Icon name="reload" />
				{eventSeries.rRuleString && RRuleToText(rule)}
			</DescriptionRow>
			<DescriptionRow>
				<Icon name="nav-events" />
				<div>
					{durationString} {timeZone}
				</div>
			</DescriptionRow>

			<EventLocation item={eventSeries} />

			{eventSeries.description && (
				<DescriptionRow iconTop>
					<Icon name="note" />
					<FormattedContent raw={eventSeries.description} />
				</DescriptionRow>
			)}

			{createdBy()}

			{eventSeries.eventSeriesAttachments?.length > 0 && (
				<GalleryPreview
					attachments={eventSeries.eventSeriesAttachments.map(
						(esa) => esa.attachment
					)}
				/>
			)}
		</Column>
	);
}
