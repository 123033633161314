import { ReactNode } from 'react';

export const symbol_humidity_indoor: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M481-300q66 0 112.5-46T640-458q0-32-12-60.5T593-569l-92-90q-9-8-21-8t-21 8l-92 90q-23 22-35 50.5T320-458q0 66 47.5 112T481-300ZM380-460q0-19 7.565-35.514Q395.13-512.027 409-526l71-70 71.25 70.209Q565-512 572.5-495.512T580-460H380ZM220-160q-24.75 0-42.375-17.625T160-220v-390q0-14.25 6.375-27T184-658l260-195q15.68-12 35.84-12Q500-865 516-853l260 195q11.25 8.25 17.625 21T800-610v390q0 24.75-17.625 42.375T740-160H220Zm0-60h520v-394L480-803 220-614v394Zm260-292Z"
		/>
	</svg>
);
