import { ReactNode } from 'react';

export const symbol_water_voc: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M160-408q0-65 30-129.5t74.921-123.032q44.922-58.532 97.5-110Q415-822 460-862q4.5-4 9.75-6t9.917-2q5.333 0 10.75 2 5.416 2 9.583 6 42 37 86.138 79.671Q630.276-739.658 670-692q32 37 58 77t44 82q5 11-.5 22T754-496q-12 4-23.5-1T714-513q-29-65-87.5-137T480-800Q353-688 286.5-587.5T220-408q0 115 74 190.5T479-141q15 0 22.5 10t7.5 21q0 11-7.5 21T479-79q-136 1-227.5-95.5T160-408Zm313-62ZM615-82q-8 3-15.5.5T589-92l-5-11q-8-17-11-34.179-3-17.178-3-34.821 0-21.662 5.5-42.831Q581-236 591-256q8-17 13.5-34.824Q610-308.648 610-328q0-16.395-3.5-31.698Q603-375 596-389l-1-3q-4-8-.806-15.412 3.193-7.412 11-10.5Q613-421 620-418.5q7 2.5 11 10.5l4 10q8 17 11.5 34.362Q650-346.276 650-328q0 20.969-5 40.984Q640-267 631-248q-8.4 17.988-14.7 37.035-6.3 19.046-6.3 39.151 0 14.814 3.5 29.314t9.5 28.222l2 5.278q4 8 .85 15.862T615-82Zm120 0q-8 3-15.5.5T709-92l-5-11q-8-17-11-34.179-3-17.178-3-34.821 0-21.662 5.5-42.831Q701-236 711-256q8-17 13.5-34.824Q730-308.648 730-328q0-16.395-3.5-31.698Q723-375 716-389l-1-3q-4-8-.806-15.412 3.193-7.412 11-10.5Q733-421 740-418.5q7 2.5 11 10.5l4.231 8.667Q762-382 766-364t4 36q0 20.969-5 40.984Q760-267 751-248q-8.4 17.988-14.7 37.035-6.3 19.046-6.3 39.151 0 14.814 3.5 29.314t9.5 28.222l2 5.278q4 8 .85 15.862T735-82Zm110.194-335.912Q853-421 860-418.5q7 2.5 11 10.5l5 9q8 17 11 34.812 3 17.813 3 36.563Q890-307 885-287t-14 39q-8.4 18.2-14.7 36.935-6.3 18.736-6.3 39.077 0 14.988 3.5 29.488t9.5 28.222l2 5.278q1 2-5 8.5T849-88q-5 6-5.5 8.5T855-82q-8 3-15.5.5T829-92l-5-11q-8-17-11-34.179-3-17.178-3-34.821 0-21.662 5.5-42.831Q821-236 831-256q8-17 13.5-34.824Q850-308.648 850-328q0-16.395-3.5-31.698Q843-375 836-389l-1-3q-4-8-.806-15.412 3.193-7.412 11-10.5Z"
		/>
	</svg>
);
