import { ReactNode } from 'react';

export const symbol_supervised_user_circle: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M422-144q48-89 122.5-121.5T674-298q23 0 42.5 3.5T757-285q26-37 44.5-88.118Q820-424.235 820-480q0-142.375-98.812-241.188Q622.375-820 480-820t-241.188 98.812Q140-622.375 140-480q0 51 15 98.5t41 85.5q41-22 85.5-33t91.5-11q29 0 56.5 4t50.5 14q-15 10-30 21.5T423-278q-14-2-26.5-2h-23.037Q336-280 300-272q-36 8-69 25 38 40 87.5 67.5T422-144Zm58.468 64Q398-80 325-111.5q-73-31.5-127.5-86t-86-127.266Q80-397.532 80-480.266T111.5-635.5q31.5-72.5 86-127t127.266-86q72.766-31.5 155.5-31.5T635.5-848.5q72.5 31.5 127 86t86 127.032q31.5 72.532 31.5 155T848.5-325q-31.5 73-86 127.5t-127.032 86q-72.532 31.5-155 31.5ZM373.121-420Q316-420 275.5-460.379q-40.5-40.379-40.5-97.5t40.379-97.621q40.379-40.5 97.5-40.5t97.621 40.379q40.5 40.379 40.5 97.5T470.621-460.5q-40.379 40.5-97.5 40.5ZM373-480q32.5 0 55.25-22.75T451-558q0-32.5-22.75-55.25T373-636q-32.5 0-55.25 22.75T295-558q0 32.5 22.75 55.25T373-480Zm301 106q-44 0-75-31t-31-75q0-44 31-75t75-31q44 0 75 31t31 75q0 44-31 75t-75 31ZM480-480Z"
		/>
	</svg>
);
