import { ReactNode } from 'react';

export const symbol_stat_minus_1: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-369q-6 0-11-2t-10-7L261-576q-9-9-9-21t9-21q9-9 21-9t21 9l177 177 177-177q9-9 21-9t21 9q9 9 9 21t-9 21L501-378q-5 5-10 7t-11 2Z"
		/>
	</svg>
);
