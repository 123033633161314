import { ReactNode } from 'react';

export const symbol_plagiarism: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M448-294q20 0 40.5-5.5T528-317l90 90q7.822 8 19.911 8Q650-219 658-226.857q8-7.857 8-20T658-267l-91-91q11-17 15.5-36.151Q587-413.302 587-433q0-59-40-99t-99-40q-59 0-99 40t-40 99q0 59 40 99t99 40Zm0-60q-34 0-56.5-22.5T369-433q0-34 22.5-56.5T448-512q34 0 56.5 22.5T527-433q0 34-22.5 56.5T448-354ZM220-80q-24 0-42-18t-18-42v-680q0-24 18-42t42-18h336q12.444 0 23.722 5T599-862l183 183q8 8 13 19.278 5 11.278 5 23.722v496q0 24-18 42t-42 18H220Zm331-584v-156H220v680h520v-494H581q-12.75 0-21.375-8.625T551-664ZM220-820v186-186 680-680Z"
		/>
	</svg>
);
