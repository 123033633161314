import { ReactNode } from 'react';

export const symbol_nest_wifi_pro_2: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M360-80q-75 0-121.5-41T166-223q-27-60-36.5-129.5T120-479q0-100 16-175t57-125q42-51 111-76t176-25q107 0 176 25t111 76q41 50 57 125t16 175q0 57-9.5 126.5T794-223q-26 61-72.5 102T600-80H360Zm0-60h240q58 0 92.5-36.5T747-267q20-54 26.5-112t6.5-100q0-87-11.5-151T723-737q-33-42-91.5-62.5T480-820q-93 0-151.5 20.5T237-737q-34 43-45.5 107T180-479q0 42 6.5 100T213-267q20 54 54.5 90.5T360-140Zm301-309q12 0 21-8.5t9-21.5q0-109-58.5-180T480-730q-13 0-21.5 9t-8.5 21q0 13 8.5 21.5T480-670q69 0 110 53t41 138q0 13 8.5 21.5T661-449Zm-181-31Z"
		/>
	</svg>
);
