import { ReactNode } from 'react';

export const symbol_offline_pin: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M350-280h260q13 0 21.5-8.5T640-310q0-13-8.5-21.5T610-340H350q-13 0-21.5 8.5T320-310q0 13 8.5 21.5T350-280Zm87-216-72-72q-9-9-22.5-8.5T320-567q-9 9-9 22.5t9 22.5l96 95q9 9 21 9t21-9l181-181q9-9 9-22.5t-9-22.5q-9-9-22.5-9t-22.5 9L437-496Zm43 416q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm0-60q142 0 241-99.5T820-480q0-142-99-241t-241-99q-141 0-240.5 99T140-480q0 141 99.5 240.5T480-140Zm0-340Z"
		/>
	</svg>
);
