export function AccountIndex(accountID: number): string {
	return `/v1/accounts/${accountID}/tokens`;
}

export function CreateCalendarSubscription(accountID: number): string {
	return `/v1/accounts/${accountID}/tokens/calendar-subscription`;
}

export function CreateOneTimeLogin(accountID: number): string {
	return `/v1/accounts/${accountID}/tokens/login`;
}

export function CreateParentalConsentToken(): string {
	return `/v1/me/parental-consent-request`;
}

export function CreatePasswordResetToken(): string {
	return `/v1/request-password-reset`;
}

export function CreatePushChannelTokens(accountID: number): string {
	return `/v1/accounts/${accountID}/tokens/push`;
}

export function Expire(tokenID: number): string {
	return `/v1/account-tokens/${tokenID}/expire`;
}
