import { normalize } from 'normalizr';

import Video from 'pkg/models/video';

import * as service from 'pkg/actions/services/group_videos';
import { ADD_VIDEOS } from 'pkg/actions/action-types';

import * as sdk from 'pkg/core/sdk';

/* Dispatches */

const EmptyLinks = {
	prev: null,
	next: null,
};

const actionAddVideos = (groupId, payload, links = EmptyLinks) => ({
	type: ADD_VIDEOS,
	groupId,
	payload,
	links,
});

/* Actions */

export const fetchGroupVideos =
	(groupId, limit = 10, linkOverride = '') =>
	async (dispatch) => {
		let request;
		if (!linkOverride) {
			request = await service.getGroupVideos(groupId, limit);
		} else {
			request = await sdk.get(linkOverride);
		}

		if (request.ok) {
			const response = await request.json();
			const data = normalize(response.records, [Video.normalizr()]);

			dispatch(actionAddVideos(groupId, data, response.links));

			return [request, response];
		}
	};
