import { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { t } from '@transifex/native';

import { getPositions } from 'pkg/selectors/positions.selector';
import {
	makeGetUserRatings,
	getUserPrimaryRating,
} from 'pkg/selectors/ratings.selector';

import {
	fetchSingleRating,
	fetchUsersPrimaryRating,
	fetchUsersRatings,
} from 'pkg/actions/ratings';
import { deprecatedFetchPositions } from 'pkg/actions/positions';

import Column from 'components/layout/column';
import UserRatingListItem from 'components/user/rating/ListItem';

import Button from 'design/button';

const MoreRatings = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 1rem;
`;

const NoRatingsWrapper = styled.div`
	display: flex;
	padding: 15px 0 0;
`;

class UserRatings extends Component {
	state = {
		showRatingForm: false,
		primaryRatingId: null,
	};

	static defaultProps = {
		borderTop: true,
	};

	async componentDidMount() {
		if (!this.props.primaryRating?.id) {
			await this.props.fetchUsersPrimaryRating(
				this.props.userId,
				this.props.groupId
			);
		}

		if (this.props.ratings.length === 0) {
			await this.props.fetchUsersRatings(this.props.userId, this.props.groupId);
		}

		if (this.props.positions.length === 0) {
			await this.props.fetchPositions();
		}
	}

	handleLoadMore = async () => {
		await this.props.fetchUsersRatings(
			this.props.userId,
			this.props.groupId,
			true
		);
	};

	get loadMore() {
		if (!this.props.collectionLinks?.next) {
			return null;
		}

		return (
			<MoreRatings key="load-more">
				<Button onClick={this.handleLoadMore}>{t('Load more')}</Button>
			</MoreRatings>
		);
	}

	renderRating = (rating) => {
		return (
			<UserRatingListItem
				key={rating.id}
				isActive={rating.id === this.props.activeRatingId}
				groupId={this.props.groupId}
				ratingId={rating.id}
				openEditModal={this.props.openEditModal}
			/>
		);
	};

	render() {
		if (!this.props.ratings || !this.props.positions.length) {
			return null;
		}

		if (this.props.ratings?.length === 0) {
			return (
				<NoRatingsWrapper>
					<h3>{t(`Dialogue missing`)}</h3>
				</NoRatingsWrapper>
			);
		}

		return (
			<Fragment>
				<Column>{this.props.ratings.map(this.renderRating)}</Column>
				{this.loadMore}
			</Fragment>
		);
	}
}

const mapStateToProps = () => {
	const getUserRatings = makeGetUserRatings();

	return (state, props) => {
		let ratings = getUserRatings(state, props);

		if (props.groupId) {
			ratings = ratings.filter(
				(r) => +r.groupId === +props.groupId || r.groupId === null
			);
		}

		return {
			ratings,
			primaryRating: getUserPrimaryRating(state, props),
			collectionLinks:
				state.ratings.collectionLinks[props.groupId] &&
				state.ratings.collectionLinks[props.groupId][props.userId],
			positions: getPositions(state),
		};
	};
};

const actions = {
	fetchSingleRating,
	fetchUsersPrimaryRating,
	fetchUsersRatings,
	fetchPositions: deprecatedFetchPositions,
};

export default connect(mapStateToProps, actions)(UserRatings);
