import { Record } from 'immutable';
import { schema } from 'normalizr';

import { eventUsers } from 'pkg/models/schemas';

export enum EventUserStatus {
	Pending = 'pending',
	Accepted = 'accepted',
	Declined = 'declined',
}

export default class EventUser extends Record({
	id: null,
	eventId: null,
	attendanceStatus: null,
	comment: '',
	status: '',
	userId: null,
	user: null,
	attendanceReporterId: null,
	attendanceReporter: null,
	attendanceLastUpdated: null,
	flags: [],
}) {
	get lokLeader(): boolean {
		return this.get('flags').includes('lok_leader');
	}

	get lokHasHandicap(): boolean {
		return this.get('flags').includes('lok_has_handicap');
	}

	static normalizr(): schema.Entity<any> {
		return eventUsers;
	}
}
