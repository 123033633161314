import { t } from '@transifex/native';
import styled from 'styled-components';
import { ChangeEvent, useState } from 'react';

import * as models from 'pkg/api/models';
import DateTime from 'pkg/datetime';
import * as actions from 'pkg/actions';

import Heading from 'components/Heading';
import StepModal, { Step } from 'components/step-modal';

import DatePicker from 'components/form/date-picker';
import Select from 'components/form/Select';
import Column from 'components/layout/column';

const ValidUntilHeading = styled.p`
	font-weight: var(--font-weight-semibold);
	margin-bottom: var(--spacing-2);
`;

const DateWrapper = styled.div`
	margin-top: var(--spacing-5);
`;

interface EditProps {
	userProduct: models.userProduct.UserProduct;
	product: models.product.Product;
	handleClose: () => void;
}

const Edit: React.FC<React.PropsWithChildren<EditProps>> = ({
	userProduct,
	product,
	handleClose,
}) => {
	const [validData, setValidData] = useState<{
		validUntil: number;
		validUntilSelect: string;
	}>({
		validUntil: userProduct.validUntil > 0 ? userProduct.validUntil : -1,
		validUntilSelect: userProduct.validUntil > 0 ? 'custom' : '',
	});

	const handleChangeValidUntil = (e: ChangeEvent<HTMLSelectElement>): void => {
		if (e.target.value === '') {
			setValidData({ validUntil: -1, validUntilSelect: '' });
		} else {
			setValidData({
				validUntil: new DateTime(new Date()).getUnixTimestamp(),
				validUntilSelect: 'custom',
			});
		}
	};

	const handleChangeDate = (dates: Date[]) => {
		const newDate = new DateTime(new Date(dates[0]));

		setValidData({
			...validData,
			validUntil: newDate.getUnixTimestamp(),
		});
	};

	const handleSave = async () => {
		await actions.userProducts.updateRelation(
			userProduct.id,
			validData.validUntil
		);

		return true;
	};

	return (
		<StepModal onClose={handleClose}>
			<Step
				title={t(`Edit assigned products`)}
				nextLabel={t('Save')}
				onNext={handleSave}>
				<Column>
					<Heading>{product.name}</Heading>
					<div>
						<ValidUntilHeading>{t(`Assignment valid until`)}</ValidUntilHeading>
						<Select
							value={validData.validUntilSelect}
							onChange={handleChangeValidUntil}>
							<option value="">{t(`Forever`)}</option>
							<option value="custom">{t(`Custom date`)}</option>
						</Select>
						{validData.validUntil > 0 && (
							<DateWrapper>
								<DatePicker
									dates={[new Date(validData.validUntil * 1000)]}
									disableDatesBefore={new Date()}
									handleChange={handleChangeDate}
								/>
							</DateWrapper>
						)}
					</div>
				</Column>
			</Step>
		</StepModal>
	);
};

export default Edit;
