import { ReactNode } from 'react';

export const symbol_print_disabled: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M792-83 658-216h60v36q0 24.75-17.625 42.375T658-120H302q-24.75 0-42.375-17.625T242-180v-116H140q-24.75 0-42.375-17.625T80-356v-186q0-45.05 30.5-75.525Q141-648 186-648h40L82-792q-9-9.067-9-21.533Q73-826 82.053-835q9.052-9 21.5-9Q116-844 125-835l710 710q9 9 9 21t-9.053 21q-9.052 9-21.5 9Q801-74 792-83Zm-490-97h356v-36L502-372H302v192Zm448-116-60-60h130v-186.215Q820-562 806.775-575 793.55-588 774-588H458l-60-60h376q45.05 0 75.525 30.475Q880-587.05 880-542v186q0 24.75-17.625 42.375T820-296h-70ZM140-542v186h102v-16q0-24.75 17.625-42.375T302-432h140L286-588H185.806Q166-588 153-574.775 140-561.55 140-542Zm518-106v-132H266l-60-60h452q24.75 0 42.375 17.625T718-780v132h-60Zm81 155q12 0 21-9t9-21q0-12-9-21t-21-9q-12 0-21 9t-9 21q0 12 9 21t21 9Zm35-95h46-362 316Zm-634 0h146-146Z"
		/>
	</svg>
);
