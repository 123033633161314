import { ReactNode } from 'react';

export const symbol_chalet: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M710-670h-43l-46 46q-5 5-10 4.5t-10-5.5q-5-5-5-10.5t5-10.5l24-24h-40q-6 0-10.5-4.5T570-685q0-6 4.5-10.5T585-700h40l-25-25q-5-5-5-10.5t5-10.5q5-5 11-5t11 5l45 45h43v-42l-46-46q-5-5-4.5-10.5T665-810q5-5 10.5-5t10.5 5l24 25v-40q0-6 4.5-10.5T725-840q6 0 10.5 4.5T740-825v40l25-25q5-5 10.5-5t10.5 5q5 5 5 10.5t-5 10.5l-46 46v43h43l46-46q5-5 10.5-5t10.5 5q5 5 5 10.5t-5 10.5l-25 25h40q6 0 10.5 4.5T880-685q0 6-4.5 10.5T865-670h-40l25 25q5 5 5 10t-5 10q-5 5-10.5 5t-10.5-5l-46-45h-43v43l46 46q5 5 5 10t-5 10q-5 5-10.5 5t-10.5-5l-25-24v40q0 6-4.5 10.5T725-530q-6 0-10.5-4.5T710-545v-40l-25 25q-5 5-10 4.5t-10-5.5q-5-5-5-10.5t5-10.5l45-45v-43Zm-74 345-36-36v171q0 13-8.5 21.5T570-160H230q-13 0-21.5-8.5T200-190v-172l-37 37q-9 9-21 9t-21-9q-9-9-9-21t9-21l237-237q17-17 42-17t42 17l237 237q9 9 9 21t-9 21q-9 9-21.5 9t-21.5-9ZM260-220h110v-90q0-13 8.5-21.5T400-340q13 0 21.5 8.5T430-310v90h110v-201L400-561 260-422v202Zm0 0h280-280Z"
		/>
	</svg>
);
