import { useContext, useRef, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import Icon from 'components/icon';
import * as Draggable from 'components/Draggable';

import { fabric, FabricContext } from 'components/drawing/FabricContext';
import { Section } from 'components/drawing/edit/Pane';
import ToolName from 'components/drawing/edit/ToolName';

import Button from 'design/button';

export default function StepsPane({ className }: any) {
	const { slides, setSlide, setSlides } = useContext(FabricContext);

	const addSlide = () => {
		const newSlides = structuredClone(slides);
		const last = slides.length - 1;
		newSlides.splice(last, 0, structuredClone(slides[last]));
		setSlides(newSlides);
		requestAnimationFrame(() => {
			setSlide(slides.length);
		});
	};

	return (
		<div className={className}>
			<Section>
				{slides.map((slideObject, i) => (
					<Slide key={i} i={i} />
				))}

				<Button block onClick={addSlide}>
					<Icon name="add" />
					{t('Add step', {
						_context: 'training_library/drawing',
					})}
				</Button>
			</Section>
		</div>
	);
}

const Slide = ({ i }: any) => {
	const { canvas, slides, setSlides, slide, setSlide } =
		useContext(FabricContext);

	const objects = canvas.current
		.getObjects()
		?.filter((o: any) => o.id && !['background', 'grid'].includes(o.id));

	const setCurrent = () => {
		setSlide(i);
	};

	const deleteSlide = () => {
		const newSlides = structuredClone(slides);
		newSlides.splice(i, 1);
		setSlides(newSlides);

		requestAnimationFrame(() => {
			setSlide(slide >= newSlides.length ? slide - 1 : slide);
		});
	};

	const duplicateSlide = () => {
		const newSlides = structuredClone(slides);
		newSlides.splice(i, 0, structuredClone(slides[i]));
		setSlides(newSlides);
		requestAnimationFrame(() => {
			setSlide(i + 1);
		});
	};

	const [showLayers, setShowLayers] = useState(!i);
	const toggleLayers = () => {
		setShowLayers(!showLayers);
	};

	const orderItems = (objects: any) => {
		const newSlides: any = structuredClone(slides);
		objects.forEach((o: any, index: number) => {
			if (!newSlides[slide][o.id]) newSlides[slide][o.id] = {};
			newSlides[slide][o.id].z = objects.length - index;
		});

		setSlides(newSlides);
	};

	return (
		<SlideWrapper current={slide === i} onClick={setCurrent}>
			<SlideOverview>
				<div className="num">{i + 1}</div>
				<div className="thumb">
					<Thumbnail slide={i} />
				</div>
				<div className="actions">
					<Icon size={1.2} name="duplicate" onClick={duplicateSlide} />
					{slides.length > 1 && (
						<Icon
							size={1.2}
							name="delete"
							onClick={deleteSlide}
							disabled={slides.length === 1}
						/>
					)}
				</div>
			</SlideOverview>
			{slide === i && (
				<div>
					<LayerToggle onClick={toggleLayers}>
						<Icon size={1.2} name={showLayers ? 'chevron-down' : 'chevron'} />{' '}
						{objects.length === 1
							? t('1 object', {
									_context: 'training_library/drawing',
									nof: objects.length,
								})
							: t('{nof} objects', {
									_context: 'training_library/drawing',
									nof: objects.length,
								})}
					</LayerToggle>
					{showLayers && (
						<Draggable.List
							items={objects}
							onReorder={orderItems}
							spacing={styles.spacing._2}>
							{objects
								.sort((a: any, b: any) =>
									slides[slide][a.id]?.z < slides[slide][b.id]?.z ? 1 : -1
								)
								.map((object: any) => (
									<Draggable.Item key={object.id} item={object}>
										<Layer object={object} />
									</Draggable.Item>
								))}
						</Draggable.List>
					)}
				</div>
			)}
		</SlideWrapper>
	);
};

export const Thumbnail = ({ slide }: any) => {
	const thumbEl = useRef();
	const { canvas, slides } = useContext(FabricContext);

	const getMostRecentSlide = (id: any) => {
		let checkSlide = slide;

		// check slides array backwards
		while (checkSlide >= 0) {
			const slideArray: any = slides[checkSlide];
			if (slideArray[id]) return slideArray[id];
			else checkSlide--;
		}
	};

	useEffect(() => {
		if (!canvas.current) return;

		const thumbInstance = new fabric.StaticCanvas(thumbEl.current, {
			backgroundColor: '#44A055',
			width: canvas.current.backgroundWidth || canvas.current.width,
			height: canvas.current.backgroundHeight || canvas.current.height,
		});

		// load existing canvas data
		thumbInstance.loadFromJSON(canvas.current.toJSON(['id', 'tool']), () => {
			thumbInstance.renderAll();

			// set current slide positions
			const objects = thumbInstance.getObjects();

			// skip grid
			const grid = objects.find((obj: any) => obj.id === 'grid');
			if (grid) thumbInstance.remove(grid);

			objects.forEach((target: any) => {
				const slideObject = getMostRecentSlide(target.id);

				if (slideObject) {
					const visible = slideObject.visible === false ? false : true;
					const values = structuredClone(slideObject);
					delete values.visible;
					delete values.z;

					target.set('visible', visible);
					target?.animate(values, {
						onChange: thumbInstance.renderAll.bind(thumbInstance),
						duration: 1,
					});
				}
			});
		});
	}, [canvas.current, slides]);

	return (
		<ThumbnailWrapper>
			<canvas
				ref={thumbEl}
				width="1000"
				height="1000"
				style={{ width: 100, height: 100 }}
			/>
		</ThumbnailWrapper>
	);
};

const ThumbnailWrapper = styled.div`
	& canvas {
		width: 100% !important;
		height: 100% !important;
		border-radius: var(--radius-3);
	}
`;

const SlideWrapper = styled.div<{
	current?: boolean;
}>`
	padding: 8px;
	margin-bottom: 5px;
	border: 1px solid var(--palette-gray-300);
	border-radius: var(--radius-3);
	font-size: var(--font-size-xs);
	font-weight: var(--font-weight-semibold);

	${(props: any) =>
		props.current &&
		css`
			border-color: var(--palette-blue-500);
			background: var(--palette-blue-100);

			.num {
				color: var(--palette-blue-500);
			}
		`}
`;

const SlideOverview = styled.div<{
	current?: boolean;
}>`
	display: flex;
	justify-content: space-between;

	.num {
		padding-right: 5px;
	}

	.thumb {
		width: 70%;

		.lower-canvas {
			max-width: 100px;
		}
	}

	.actions {
		align-self: flex-start;
		svg {
			margin-left: 4px;
		}
	}
`;

const Layer = ({ dragElement, object }: any) => {
	const { canvas, slides, setSlides, slide } = useContext(FabricContext);

	const current = slides[slide][object.id] || {};

	const toggle = () => {
		const newSlides: any = structuredClone(slides);
		const slideObject = newSlides[slide][object.id] || {};
		slideObject.visible = slideObject.visible !== false ? false : true;
		newSlides[slide][object.id] = slideObject;
		setSlides(newSlides);
	};

	const edit = () => {
		canvas.current.setActiveObject(object);
		canvas.current.renderAll();
	};

	return (
		<LayerWrapper>
			{dragElement}

			<ToolName tool={object.tool} />

			<LayerActions>
				<span onClick={toggle}>
					<Icon
						size={current.visible === false ? 1 : 1.5}
						name={current.visible === false ? 'eye-hidden' : 'eye-visible'}
					/>
				</span>
				<span onClick={edit}>
					<Icon size={1.2} name="edit" />
				</span>
			</LayerActions>
		</LayerWrapper>
	);
};

const LayerToggle = styled.div`
	cursor: pointer;
	margin-top: var(--spacing-3);

	& + ul {
		margin-top: var(--spacing-3);
	}
`;

const LayerWrapper = styled.div`
	display: flex;
	align-items: center;
	border: 1px solid var(--palette-gray-400);
	padding: 5px;
	gap: 5px;
	border-radius: var(--radius-3);
	background: var(--palette-white);

	svg {
		cursor: pointer;
		width: 16px;
		height: 16px;
	}
	div:first-child svg {
		cursor: move;
	}
`;

const LayerActions = styled.div`
	display: flex;
	gap: var(--spacing-3);
	font-size: var(--font-size-lg);
	margin-left: auto;
	color: var(--palette-blue-500);
`;
