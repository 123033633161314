import { ReactNode } from 'react';

export const symbol_ramp_right: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M478-120q-13 0-21.5-8.5T448-150v-237q-29 40-72.5 78.5T278-238q-13 8-27 7t-24-11q-9-9-4.5-21.5T239-284q107-68 158-134.5T448-580v-146l-69 69q-9 9-21 9t-21-9q-9-9-9-21t9-21l120-120q5-5 10-7t11-2q6 0 11 2t10 7l120 120q9 9 9 21t-9 21q-9 9-21 9t-21-9l-69-69v576q0 13-8.5 21.5T478-120Z"
		/>
	</svg>
);
