const icon = {
	name: 'drawing-line',
	objects: [
		{
			type: 'path',

			attributes: {
				d: 'M6.71484 18L18.7148 6',
				stroke: 'currentColor',
				strokeWidth: 1.3,
			},
		},
	],
};

export default icon;
