import { ReactNode } from 'react';

export const symbol_partner_reports: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M481-280q-12 0-21-8.625T451-310q0-12 9-21t21-9q12 0 21 9t9 21.5q0 12.5-9 21t-21 8.5Zm-.175-160Q468-440 459.5-448.625T451-470v-340q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T511-810v340q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM180-120q-24 0-42-18t-18-42v-113q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T180-293v113h600v-113q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T840-293v113q0 24-18 42t-42 18H180Z"
		/>
	</svg>
);
