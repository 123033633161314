import { ReactNode } from 'react';

export const symbol_workspaces: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M230-120q-63 0-106.5-43.5T80-270q0-63 43.5-106.5T230-420q63 0 106.5 43.5T380-270q0 63-43.5 106.5T230-120Zm501 0q-63 0-106.5-43.5T581-270q0-63 43.5-106.5T731-420q63 0 106.5 43.5T881-270q0 63-43.5 106.5T731-120Zm-501.089-60Q267-180 293.5-206.411q26.5-26.412 26.5-63.5Q320-307 293.589-333.5q-26.412-26.5-63.5-26.5Q193-360 166.5-333.589q-26.5 26.412-26.5 63.5Q140-233 166.411-206.5q26.412 26.5 63.5 26.5Zm501 0Q768-180 794.5-206.411q26.5-26.412 26.5-63.5Q821-307 794.589-333.5q-26.412-26.5-63.5-26.5Q694-360 667.5-333.589q-26.5 26.412-26.5 63.5Q641-233 667.411-206.5q26.412 26.5 63.5 26.5ZM481-541q-63 0-106.5-43.5T331-691q0-63 43.5-106.5T481-841q63 0 106.5 43.5T631-691q0 63-43.5 106.5T481-541Zm-.089-60Q518-601 544.5-627.411q26.5-26.412 26.5-63.5Q571-728 544.589-754.5q-26.412-26.5-63.5-26.5Q444-781 417.5-754.589q-26.5 26.412-26.5 63.5Q391-654 417.411-627.5q26.412 26.5 63.5 26.5Zm.089-90Zm250 421Zm-501 0Z"
		/>
	</svg>
);
