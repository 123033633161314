import { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { t } from '@transifex/native';

import { PageWidths } from 'pkg/config/sizes';

import User from 'pkg/models/user';

import { UserPayload } from 'pkg/actions/services/users';

import { popState } from 'pkg/router/state';
import * as models from 'pkg/api/models';
import store from 'pkg/store/createStore';
import * as selectors from 'pkg/selectors';
import * as actions from 'pkg/actions';
import { RootState } from 'pkg/reducers';
import { UserAddressTypes } from 'pkg/api/models/user_address';
import { useCurrentOrganization } from 'pkg/identity';

import useContactForm, {
	FormFields,
	FormStateData,
	getBirthDate,
} from 'routes/payments/hooks/useContactForm';
import { ContactActionBar } from 'routes/payments/contacts/shared/ContactFormActions';

import AssignProductModal from 'containers/payment_platform/contacts/AssignProductModal';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import SavedContact from 'components/payment_platform/contacts/create/SavedContact';
import Form, { FormPayload } from 'components/form/Form';

import Button, { ButtonGroup } from 'design/button';

import { FormActionFooter } from 'styles/Form';

interface CreateContactProps {
	organizationId: number;
	query: {
		groupIds?: string;
	};
}

export const generatePayload = (
	formFieldsData: FormPayload,
	contactData: FormStateData,
	orgId: number
): UserPayload => {
	const mappedFormData = formFieldsData as typeof FormFields;
	const skipKeys = [
		'birthDay',
		'birthMonth',
		'birthYear',
		'firstName',
		'groups',
	];

	const changes: { [key: string]: any } = {
		organizationId: orgId,
		firstName: mappedFormData.firstName,
	};

	for (const [key, value] of Object.entries(mappedFormData)) {
		if (!!value && !skipKeys.includes(key)) {
			changes[key] =
				key === FormFields.sex ? Number.parseInt(value, 10) : value;
		}
	}

	if (
		mappedFormData.birthYear &&
		mappedFormData.birthMonth &&
		mappedFormData.birthDay
	) {
		changes['birthDate'] = getBirthDate(mappedFormData);
	}

	for (const [key, value] of Object.entries(contactData)) {
		if (!!value && !skipKeys.includes(key)) {
			changes[key] = value;
		}

		if (key === 'groups' && value.length > 0) {
			changes[key] = value;
		}
	}

	const userData: UserPayload = {
		organizationId: orgId,
		firstName: mappedFormData.firstName,
		...changes,
	};

	if (formFieldsData.fields) {
		userData.fields = models.user.getFieldValuesAsPayload(
			formFieldsData.fields
		);
	}

	return userData;
};

const CreateContact: React.FC<React.PropsWithChildren<CreateContactProps>> = ({
	organizationId,
	query,
}) => {
	const [assignProductModal, setAssignProductModal] = useState<boolean>(false);
	const [createdUserId, setCreatedUserId] = useState<number>(0);
	const [isSaving, setIsSaving] = useState<boolean>(false);
	const org = useCurrentOrganization();

	const isSaved: boolean = !!createdUserId;

	const {
		contactData,
		billingData,
		billingContactData,
		DetailsSection,
		UserGroupsSection,
		BillingUserSection,
		BillingAddressSection,
		Modals,
	} = useContactForm({
		groupId: organizationId,
		reset: isSaved,
		preSelectedGroups:
			query.groupIds?.length > 0
				? query.groupIds?.split(',').map((id) => Number.parseInt(id, 10))
				: [],
	});

	const createdUser = useSelector((state: RootState) =>
		selectors.users.find(state, createdUserId)
	);
	const billingUser: User = useSelector((state: RootState) =>
		selectors.users.find(state, contactData.billingUserId)
	);

	const handleSubmit = async (formData: FormPayload) => {
		setIsSaving(true);

		const addBillingUser = !!billingData?.firstName;
		let billingUserId: number = null;

		if (addBillingUser) {
			const [, billingResult] = await actions.users.createUser(
				generatePayload(
					billingData as FormPayload,
					billingContactData,
					organizationId
				)
			)(store.dispatch);

			if (billingResult) {
				billingUserId = billingResult.id;
			}
		}

		const data = generatePayload(formData, contactData, organizationId);

		if (billingUserId) {
			data.billingUserId = billingUserId;
		}

		const [, result] = await actions.users.createUser(data)(store.dispatch);

		const billingAddressData = formData.billingAddress as FormPayload;

		if (result) {
			setCreatedUserId(result.id);

			if (billingAddressData?.email) {
				await models.userAddress.create({
					...billingAddressData,
					userId: result.id,
					type: UserAddressTypes.Billing,
				});
			}
		}

		setIsSaving(false);
	};

	const handleCreateAnother = (): void => {
		setCreatedUserId(0);
	};

	const handleOpenAssignProductModal = () => setAssignProductModal(true);

	const handleCloseModal = () => setAssignProductModal(false);

	const getEmail = (): string => {
		if (billingData.email) {
			return billingData.email as string;
		}

		if (billingUser.email) {
			return billingUser.email;
		}

		return createdUser.email as string;
	};

	const handleCancel = () => popState();

	return (
		<Fragment>
			<Form onSubmit={handleSubmit} resetOnSubmit>
				<ContactActionBar isSaving={isSaving} isSaved={isSaved} />
				<LargeScreen>
					<LargeScreenContent.Inner maxWidth={PageWidths.STANDARD} spacious>
						{isSaved ? (
							<SavedContact
								userId={createdUserId}
								email={getEmail()}
								onAssignProduct={handleOpenAssignProductModal}
								handleCreateAnother={handleCreateAnother}
							/>
						) : (
							<Fragment>
								{DetailsSection}
								{UserGroupsSection}
								{BillingAddressSection}
								{BillingUserSection}
								<FormActionFooter>
									<ButtonGroup>
										<Button label={t('Cancel')} onClick={handleCancel} />
										<Button
											type="submit"
											isLoading={isSaving}
											primary
											testid="contacts.save_contact"
											onClick={handleCreateAnother}>
											{t('Save')}
										</Button>
									</ButtonGroup>
								</FormActionFooter>
							</Fragment>
						)}
					</LargeScreenContent.Inner>
				</LargeScreen>
				<SmallScreen>
					<LargeScreenContent.Inner>
						{isSaved ? (
							<SavedContact
								userId={createdUserId}
								email={getEmail()}
								onAssignProduct={handleOpenAssignProductModal}
								handleCreateAnother={handleCreateAnother}
							/>
						) : (
							<Fragment>
								{DetailsSection}
								{UserGroupsSection}
								{BillingAddressSection}
								{BillingUserSection}
							</Fragment>
						)}
					</LargeScreenContent.Inner>
				</SmallScreen>
			</Form>

			{Modals}

			{assignProductModal && (
				<AssignProductModal
					organizationId={org.id}
					userIds={[createdUserId]}
					onClose={handleCloseModal}
				/>
			)}
		</Fragment>
	);
};

export default CreateContact;
