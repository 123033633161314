import { ReactNode } from 'react';

export const symbol_nightlife: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M261-220v-204L71-714q-10-15-1.25-30.5T96-760h390q17.5 0 26.25 15.5T511-714L321-424v204h54q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T375-160H207q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T207-220h54Zm-61-394h182l55-86H145l55 86Zm435 454q-50 0-82.5-32.5T520-275q0-50 32.5-82.5T635-390q14.903 0 28.452 3Q677-384 690-379v-351q0-12.75 8.625-21.375T720-760h111q20.417 0 34.708 14.292Q880-731.417 880-711q0 20-14.292 35-14.291 15-34.708 15h-81v386q0 50-32.5 82.5T635-160Z"
		/>
	</svg>
);
