import { ReactNode } from 'react';

export const symbol_person_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m792-83-78-77H220q-25 0-42.5-17.5T160-220v-34q0-38 19-65t49-41q60-27 115.5-42T455-419L82-792q-9-9-8.5-21.5T83-835q9-9 21.5-9t21.5 9l709 710q9 9 9 21t-9 21q-9 9-21.5 9T792-83ZM220-220h434L515-359q-8-1-17-1h-18q-57 0-111 11.5T252-306q-14 7-23 21.5t-9 30.5v34Zm511-140q31 14 50 41t19 65v8L657-389q18 6 36.5 13.5T731-360ZM550-496l-48-48q30-7 49-31t19-56q0-38-26-64t-64-26q-32 0-56 19t-31 49l-48-48q19-38 55.5-59t79.5-21q63 0 106.5 43.5T630-631q0 43-21 79.5T550-496Zm104 276H220h434ZM448-599Z"
		/>
	</svg>
);
