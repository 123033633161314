import { ReactNode } from 'react';

export const symbol_noise_control_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-80q-56 0-103-13t-89-36q-12-7-16-20t3-24q7-11 19.5-12.5T319-181q36 20 76.5 30.5T480-140q44 0 84.5-10.5T641-181q12-6 25-4t20 13q7 11 2.5 23.5T672-129q-42 23-89 36T480-80ZM140-480q0 44 10.5 85t30.5 77q7 12 5 24.5T173-274q-11 7-24 2.5T129-288q-23-42-36-89T80-480q0-56 13-103t36-89q7-12 19.5-16.5T172-686q11 7 13 20t-4 25q-20 36-30.5 76.5T140-480Zm680 0q0-45-10.5-85.5T779-642q-6-12-4-24.5t13-19.5q11-7 23.5-2.5T831-672q23 42 36 89t13 103q0 56-13 103t-36 89q-7 12-19.5 16.5T788-274q-11-7-13-20t4-25q20-36 30.5-76.5T820-480ZM480-820q-44 0-84.5 10.5T319-779q-12 6-25 4t-20-13q-7-11-2.5-23.5T288-831q42-23 89-36t103-13q56 0 103 13t89 36q12 7 16.5 19.5T686-788q-7 11-19.5 13t-24.5-4q-36-20-76.5-30.5T480-820Zm39 570q-36 0-64.5-22.5T413-330q-3-9-7-17t-11-15l-50-51q-25-25-40-57.5T290-538q0-72 49.5-122T460-710q49 0 90.5 25.5T613-614q8 15-1 29.5T586-570q-9 0-16-5t-11-13q-14-29-40.5-45.5T460-650q-46 0-78 32t-32 78q0 23 8.5 44t24.5 37l55 54q13 13 21 28.5t14 32.5q5 15 18 24.5t29 9.5q14 0 25.5-7.5T564-337q3-7 10-10t15-3q17 0 26 14.5t1 28.5q-14 27-40.5 42T519-250Zm21-160q21 0 35.5-14.5T590-460q0-21-14.5-35.5T540-510q-21 0-35.5 14.5T490-460q0 21 14.5 35.5T540-410Z"
		/>
	</svg>
);
