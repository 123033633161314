import { ReactNode } from 'react';

export const symbol_move_item: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M824-450H353q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T353-510h471l-68-68q-9-9-9-21t8.609-21q9.391-9 21.891-9 12.5 0 21.5 9l120 119q9 9 9 21t-9 21L799-339q-8.8 9-20.9 8.5-12.1-.5-21.491-9.5Q748-349 748-361.5t9-21.5l67-67ZM580-600v-180H180v600h400v-180q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T640-360v180q0 24.75-17.625 42.375T580-120H180q-24.75 0-42.375-17.625T120-180v-600q0-24.75 17.625-42.375T180-840h400q24.75 0 42.375 17.625T640-780v180q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T580-600Z"
		/>
	</svg>
);
