import { Fragment, useState } from 'react';

import * as models from 'pkg/api/models';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { QueryObject } from 'pkg/hooks/query-state';
import { ApiError } from 'pkg/errors/errors';

import ProductList from 'routes/organization/contacts/single/list/Product';
import { UserProfileLayoutCrash } from 'routes/organization/user-profile/layout';

import AssignProductModal from 'containers/payment_platform/contacts/AssignProductModal';

import { Spinner } from 'components/loaders/spinner';

interface OrganizationUserProfileUserProductsProps {
	user: models.user.User;
	organizationId: number;
	isLoading: boolean;
	query?: QueryObject;
}

export default function OrganizationUserProfileUserProducts({
	organizationId,
	user,
	isLoading,
	query,
}: OrganizationUserProfileUserProductsProps) {
	const [showModal, setShowModal] = useState(false);

	const handleOpenModal = () => setShowModal(true);

	const handleCloseModal = () => setShowModal(false);

	useComponentDidMount(() => {
		if (query?.hasOwnProperty('openProductModal')) {
			handleOpenModal();
		}
	});

	if (isLoading) {
		return <Spinner />;
	}

	if (!models.hasAllowedAction(user, models.Action.UserListProducts)) {
		return <UserProfileLayoutCrash error={new ApiError(403)} />;
	}

	return (
		<Fragment>
			<ProductList contact={user} handleOpenModal={handleOpenModal} />
			{showModal && (
				<AssignProductModal
					organizationId={organizationId}
					userIds={[user.id]}
					onClose={handleCloseModal}
				/>
			)}
		</Fragment>
	);
}
