import DateTime from 'pkg/datetime';
import { getGmtString, getTimeZoneFromString } from 'pkg/timezone';

// This is only visible if the local timezone differs from the events timezone
interface TimezoneTimeProps {
	timezone: string;
	useAbbreviation?: boolean;

	starts: DateTime;
	ends?: DateTime;
}

const TimezoneTime = ({
	timezone,
	starts,
	ends,
	useAbbreviation = false,
}: TimezoneTimeProps) => {
	const tz = getTimeZoneFromString(timezone);
	const timezoneString = getGmtString(tz);

	const eventTimezoneStart = starts.toLocaleTimeString({
		hour: 'numeric',
		minute: 'numeric',
		timeZone: timezone,
	});
	const eventTimezoneEnd = ends?.toLocaleTimeString({
		hour: 'numeric',
		minute: 'numeric',
		timeZone: timezone,
	});

	if (!ends) {
		return (
			<small>{`${eventTimezoneStart} ${
				useAbbreviation ? tz.abbreviation : timezoneString
			}`}</small>
		);
	}

	return (
		<small>{`${eventTimezoneStart} – ${eventTimezoneEnd} ${
			useAbbreviation ? tz.abbreviation : timezoneString
		}`}</small>
	);
};

export default TimezoneTime;
