import { ReactNode } from 'react';

export const symbol_medication: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M440-380v72q0 17 11.735 28 11.736 11 28.5 11Q497-269 508-280.667q11-11.666 11-28.333v-71h74q16.667 0 28.333-11.735Q633-403.471 633-420.235 633-437 621.333-448.5 609.667-460 593-460h-74v-72q0-17-11-28t-27.765-11q-16.764 0-28.5 11Q440-549 440-532v72h-73q-16.667 0-28.333 11.735Q327-436.529 327-419.765 327-403 338.667-391.5 350.333-380 367-380h73ZM260-120q-24.75 0-42.375-17.625T200-180v-479q0-24.75 17.625-42.375T260-719h440q24.75 0 42.375 17.625T760-659v479q0 24.75-17.625 42.375T700-120H260Zm0-60h440v-479H260v479Zm10-600q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T270-840h421q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T691-780H270Zm-10 121v479-479Z"
		/>
	</svg>
);
