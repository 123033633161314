import { ReactNode } from 'react';

export const symbol_tab_close_right: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m480-438 93 93q9 9 21 9t21-9q9-9 9-21t-9-21l-93-93 93-93q9-9 9-21t-9-21q-9-9-21-9t-21 9l-93 93-93-93q-9-9-21-9t-21 9q-9 9-9 21t9 21l93 93-93 93q-9 9-9 21t9 21q9 9 21 9t21-9l93-93Zm325 282H654q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T654-216h151l-52-53q-8-9-8.5-21t8.5-21q9-9 21-9t21 9l104 104q9 9 9 21t-9 21L795-61q-9 9-21 9t-21-9q-9-9-8.5-21t8.5-21l52-53Zm-625-24v-600 600-7 7Zm0 60q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v356q0 13-8.675 21-8.676 8-21.5 8-12.825 0-21.325-8.625T780-425v-355H180v600h356q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T536-120H180Z"
		/>
	</svg>
);
