import { ReactNode } from 'react';

export const symbol_subdirectory_arrow_left: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m275-325 141 141q9 9 9 21t-9.053 21q-9.052 9-21.5 9Q382-133 373-142L181-336q-5-5-7-10.133-2-5.134-2-11Q172-363 174-368q2-5 7-10l191-191q9-9 21.5-9t21.891 9Q424-560 424-547.5t-9 21.5L274-385h426v-385q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T760-770v385q0 24.75-17.625 42.375T700-325H275Z"
		/>
	</svg>
);
