import { ReactNode } from 'react';

export const symbol_cell_merge: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M159.825-610Q147-610 138.5-618.625T130-640v-130q0-24.75 17.625-42.375T190-830h130q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T320-770H190v130q0 12-8.675 21-8.676 9-21.5 9Zm640 0Q787-610 778.5-618.625T770-640v-130H640q-12 0-21-8.675-9-8.676-9-21.5 0-12.825 8.625-21.325T640-830h130q24.75 0 42.375 17.625T830-770v130q0 12-8.675 21-8.676 9-21.5 9ZM652-362l-97-97q-9-9-9-21t9-21l97-97q8.8-9 20.9-9 12.1 0 21.1 9 9 9 9.5 21.5T695-555l-46 45h191q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T840-450H649l46 46q9 9 8.5 21t-9.342 21q-8.842 9-21 9T652-362Zm-386.12-.053q-9.12-9.052-9.5-21.5Q256-396 265-405l46-45H120q-12 0-21-8.675-9-8.676-9-21.5Q90-493 98.625-501.5T120-510h191l-46-46q-9-8.8-8.5-20.9.5-12.1 9.5-21.1 9-9 21-9t21 9l97 97q9 9 9 21t-9 21l-97 97q-9 9-21 9t-21.12-9.053ZM190-130q-24.75 0-42.375-17.625T130-190v-130q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T190-320v130h130q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T320-130H190Zm450 0q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T640-190h130v-130q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T830-320v130q0 24.75-17.625 42.375T770-130H640Z"
		/>
	</svg>
);
