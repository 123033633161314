import { ReactNode } from 'react';

export const symbol_screen_search_desktop: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M458-420q22.086 0 41.543-7.5T538-446l69 69q7 7 16 7t16-6.588Q646-384 646-393t-7-16l-72-72q11-17 18.5-35.356T593-555q0-57-39-96t-96-39q-57 0-96 39.075-39 39.076-39 96Q323-498 362-459t96 39Zm.044-50Q423-470 398-494.956q-25-24.955-25-60Q373-590 397.969-615T458-640q35 0 60 24.956 25 24.955 25 60Q543-520 518.044-495q-24.955 25-60 25ZM70-120q-12.75 0-21.375-8.675Q40-137.351 40-150.175 40-163 48.625-171.5T70-180h820q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T890-120H70Zm70-120q-24 0-42-18t-18-42v-480q0-24 18-42t42-18h680q24 0 42 18t18 42v480q0 24-18 42t-42 18H140Zm0-60h680v-480H140v480Zm0 0v-480 480Z"
		/>
	</svg>
);
