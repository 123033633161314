import { ReactNode } from 'react';

export const symbol_contact_phone: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M690-479q0-26 3.5-47t12.5-47h54q7.692 0 13.846-3.5T785-586l33-47q6-9 5.308-19.976Q822.615-663.951 815-672l-38-37q-9-9-21-8.5t-21 8.5q-47 46-71 105t-24 125q0 66 24 125t71 105q9 8 21 8.5t21-8.5l38-37q7.615-8.049 8.308-19.024Q824-316 818-325l-33-47q-5-6-11.154-9.5T760-385h-54q-9-25-12.5-46.5T690-479ZM60-120q-24 0-42-18T0-180v-600q0-24 18-42t42-18h840q24 0 42 18t18 42v600q0 24-18 42t-42 18H60Zm531-60h309v-600H60v600h7q44-69 112.5-109T329-329q81 0 149.5 40T591-180ZM329-400q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35ZM143-180h372q-35.606-42.275-84.303-65.637Q382-269 329-269t-101.5 23.5Q179-222 143-180Zm186-280q-25.5 0-42.75-17.25T269-520q0-25.5 17.25-42.75T329-580q25.5 0 42.75 17.25T389-520q0 25.5-17.25 42.75T329-460Zm151-20Z"
		/>
	</svg>
);
