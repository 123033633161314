import { ReactNode } from 'react';

export const symbol_donut_small: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M521-842q0-15 11.5-24.5T558-873q121 23 206.5 110T873-555q2 14-6.5 24T843-521H600q-3 0-5-1.5t-3-4.5q-8-22-25.5-38T527-589q-3-1-4.5-3t-1.5-5v-245Zm60 40v174q14 9 27 20.5t21 26.5h173q-25-80-83-138.5T581-802ZM399-88q-138-27-228-136T81-477q0-145 89.5-256T399-872q16-3 28 7t12 26v243l-4 7q-33 14-53.5 45T361-477q0 35 20.5 63t52.5 42q1 0 5 7v244q0 16-12 26t-28 7Zm-20-714q-109 30-173.5 121T141-477q0 111 66 199.5T379-157v-174q-37-25-57.5-63T301-477q0-45 20-85t58-66v-174ZM555-87q-14 2-24-6.5T521-116v-247q0-3 2-5.5t5-3.5q23-8 39.5-23.5T592-433l8-6h243q14 0 23.5 11.5T873-402q-23 121-110 206.5T555-87Zm74-292q-10 14-21.5 27T581-331v173q80-23 138-82t83-139H629ZM301-479Zm328-102Zm0 202Z"
		/>
	</svg>
);
