import { ReactNode } from 'react';

export const symbol_no_photography: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M880-693v466q0 15-9.318 22.5t-20.5 7.5Q839-197 829.5-204.683 820-212.366 820-227v-466H673q-14 0-26-5t-20-16l-55-66H388l-35 42q-8 10-21 10.5t-23-9.5q-8-8-9-19.5t7-20.5l35-42q8-11 20-16t26-5h184q14 0 26 5t20 16l55 66h147q23 0 41.5 18.5T880-693ZM140-120q-24 0-42-18t-18-42v-513q0-23 18-41.5t42-18.5h67l60 60H140v513h640l60 60H140Zm480-220q-23 33-59 53t-80.859 20Q407-267 358.5-316T310-437q0-44.859 19.5-81.43Q349-555 382-578l44 44q-26 14-41 39.556-15 25.555-15 57.777Q370-390 401-358.5t79 31.5q32.093 0 57.547-15Q563-357 577-383l43 43Zm-19-218q23 23 36 53.5t13 67.5q0 10-1 19t-3 18L443-603q9-2 18-3t19-1q35.808 0 66.904 13Q578-581 601-558ZM812-63 47-827q-9-9.067-9-21.533Q38-861 47.053-870q9.052-9 21.5-9Q81-879 90-870l765 765q9 9 9 21t-9.053 21q-9.052 9-21.5 9Q821-54 812-63ZM438-437Zm138-33Z"
		/>
	</svg>
);
