// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nMXARzqKzATBxGzyR62x {
	display: block;
	width: 100%;
	font-size: 11px;
	min-height: 0;
	max-height: 100%;
}
.nMXARzqKzATBxGzyR62x.BWBmZZS6XUeShMZYT7_Z {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		    -ms-flex-pack: center;
		        justify-content: center;
	}
@media all and (min-width: 640px) and (max-height: 650px) {
	.nMXARzqKzATBxGzyR62x {
		font-size: 8px;
		padding-right: 0;
}
	}
@media (max-width: 768px) {
	.nMXARzqKzATBxGzyR62x {
		font-size: 8px;
		padding-right: 0;
}
	}
`, "",{"version":3,"sources":["webpack://./routes/group/calendar/components/day-item/styles.css"],"names":[],"mappings":"AAAA;CACC,cAAc;CACd,WAAW;CACX,eAAe;CACf,aAAa;CACb,gBAAgB;AAgBjB;AAdC;EACC,oBAAa;EAAb,oBAAa;EAAb,aAAa;EACb,wBAAuB;MAAvB,qBAAuB;UAAvB,uBAAuB;CACxB;AAEA;CAZD;EAaE,cAAc;EACd,gBAAgB;AAOlB;CANC;AAEA;CAjBD;EAkBE,cAAc;EACd,gBAAgB;AAElB;CADC","sourcesContent":[".eventList {\n\tdisplay: block;\n\twidth: 100%;\n\tfont-size: 11px;\n\tmin-height: 0;\n\tmax-height: 100%;\n\n\t&.yearView {\n\t\tdisplay: flex;\n\t\tjustify-content: center;\n\t}\n\n\t@media all and (min-width: 640px) and (max-height: 650px) {\n\t\tfont-size: 8px;\n\t\tpadding-right: 0;\n\t}\n\n\t@media (--small-viewport) {\n\t\tfont-size: 8px;\n\t\tpadding-right: 0;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var eventList = `nMXARzqKzATBxGzyR62x`;
export var yearView = `BWBmZZS6XUeShMZYT7_Z`;
export default ___CSS_LOADER_EXPORT___;
