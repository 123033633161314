import { ReactNode } from 'react';

export const symbol_file_upload_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M510-684v148l-60-60v-88l-44 44-43-43 96-96q5-5 10.133-7 5.134-2 11-2Q486-788 491-786q5 2 10 7l151 151q9 9 9 21t-8.609 21q-9.391 9-21.891 9-12.5 0-21.5-9l-99-98Zm-60 174 60 60v107q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T450-343v-167Zm264 350H220q-24 0-42-18t-18-42v-113q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T220-333v113h434L82-792q-9-9.067-9-21.533Q73-826 82.053-835q9.052-9 21.5-9Q116-844 125-835l710 710q9 9 9 21t-9.053 21q-9.052 9-21.5 9Q801-74 792-83l-78-77Zm86-82-60-60v-31q0-13 8.654-21.5T770-363q12.75 0 21.375 8.625T800-333v91Z"
		/>
	</svg>
);
