export default {
	name: 'nav-home',
	objects: [
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M12.412 4.265l.745-1.066 8.256 5.769-.744 1.065-8.257-5.768zm6.661 7.432h1.481c1.467 0 2.062-1.889.86-2.73l-.745 1.066a.2.2 0 01-.115.364h-2.78v7.911h-2.919v-5.577a1 1 0 00-1-1h-3.114a1 1 0 00-1 1v5.577H6.82v-7.91h-2.78a.2.2 0 01-.114-.365l8.256-5.768a.2.2 0 01.23 0l.744-1.066a1.5 1.5 0 00-1.718 0L3.183 8.968c-1.203.84-.608 2.73.859 2.73h1.48v6.61a1.3 1.3 0 001.3 1.3H9.74a1.3 1.3 0 001.3-1.3v-5.277h2.514v5.277a1.3 1.3 0 001.3 1.3h2.918a1.3 1.3 0 001.3-1.3v-6.61z',
			},
		},
	],
};
