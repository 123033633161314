import { ReactNode } from 'react';

export const symbol_fertile: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M520-835q32 38 61.5 76t56.5 80l194-145q7-5 15-6t16 3.25q8 3.75 12.5 11.169 4.5 7.42 4.5 15.581v320q0 83-31.5 156T763-197q-54 54-127 85.5T480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480v-320q0-8.161 4.5-15.581Q89-823 97-826.75q8-4.25 16-3.25t15 6l193 145q27-42 57-80t62-76q7.547-9.12 18.113-14.06Q468.679-854 480-854t21.887 4.94Q512.453-844.12 520-835Zm-40 695q142.375 0 241.188-98.812Q820-337.625 820-480v-260L622-592q-39-63-69.5-105.5T480-791q-42 51-73.5 94.5T338-592L140-740v260q0 142.375 98.812 241.188Q337.625-140 480-140Zm0-326Z"
		/>
	</svg>
);
