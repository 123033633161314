import { ReactNode } from 'react';

export const symbol_stethoscope_check: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m673-142-99-99q-9-8.8-9-20.9 0-12.1 9-21.1 9-9 21-9t21 9l78 77 163-162q9-9 21-8.5t21 9.342q9 8.842 9 21T899-325L715-142q-8.8 9-20.9 9-12.1 0-21.1-9ZM540-81q-109 0-184.5-78T280-347v-35q-85-11-142.5-75.711T80-610v-200q0-12.75 8.625-21.375T110-840h90v-10q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T260-850v80q0 12-8.675 21-8.676 9-21.5 9-12.825 0-21.325-8.625T200-770v-10h-60v170q0 71 49.5 120.5T310-440q71 0 120.5-49.5T480-610v-170h-60v10q0 12-8.675 21-8.676 9-21.5 9-12.825 0-21.325-8.625T360-770v-80q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T420-850v10h90q12.75 0 21.375 8.625T540-810v200q0 87.578-57.5 152.289Q425-393 340-382v35q0 85 57.5 145.5T540-141q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q570-98 561.375-89.5T540-81Z"
		/>
	</svg>
);
