import { ReactNode } from 'react';

export const symbol_femur: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M396-76q-44.373 0-77.653-27.778Q285.068-131.556 277-176q-3-17-13.244-28.5T234-216q-4 0-6.953.458-2.953.459-4.922.459Q167-205 123-241t-44-93.326q0-42.24 27.5-76.434Q134-444.954 179-453l118-20 134-192q1 0 .5.5l-.5.5q-14-15-22.5-33.5T400-737q0-41.576 28-69.788Q456-835 497-835q26 0 44 9.12T567-811q9.562 7 21.781 10T614-798q8-24 25.5-43t41.5-28q44-17 85 3t56.81 61.817l50.392 140.384Q890-618 868-578q-22 40-60 55-34 13-67 2.5T687-556h-38L494-334.344 515-218q10 56-26.219 99T396-76Zm0-80q19 0 31.5-14.5T436-204l-24-133q-2-8 0-15.5t7-14.5l164-235q11-16 28.5-25t37.5-9l-29-80q-30 1-55-7t-45-23q-7-5-12.333-7-5.334-2-10.667-2-8 0-12.5 5.739t-4.5 12.435q0 3.826 1.5 7.826 1.5 4 4.5 7 13 13.186 19 27.893t6 29.921Q511-651 507-640t-11 22L353-413q-5 7-11.5 10.5T327-397l-134 23q-15 3-24.5 14.5T159-334q0 19 15 31.5t33.72 8.9Q215-296 221.5-296H234q23 0 43.5 7.43t36.5 21.299q16 13.869 27 33.187 11 19.318 15 44.084 3 15 14.5 24.5T396-156Zm384-442q11-5 17-16t1-22l-50-140q-5-12-16.171-17.5Q720.657-799 709-794q-11 5-17 16t-1 22l51 140q5 11 16 17t22 1ZM462-485Zm283-211Z"
		/>
	</svg>
);
