import { ReactNode } from 'react';

export const symbol_sailing: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m161-467 228-331q5-7 11.5-9t13.5 0q7 2 11.5 7.5T430-786v336q0 13-8.5 21.5T400-420H186q-9 0-16-4.5T159-436q-4-7-3.5-15t5.5-16Zm83-13h126v-187L244-480Zm309 60q-33 0-29-27.5t9-44.5q9-42 13-86t4-92q0-46-4-90t-13-87q-2-10 1-18.5t10-13.5q7-5 16-6t17 4q41 24 84.5 75t69.5 91q41 63 64.5 125T827-453q2 14-7 23.5t-23 9.5H553Zm36-60h175q-17-87-62.5-168T602-782q5 30 6.5 59t1.5 53q0 46-6.5 99T589-480Zm-219 0Zm219 0ZM360-196q-29 0-53.5-15T259-245q-5-4-9.5-5.5T240-252q-5 0-9.5 1.5T221-245q-12 10-25 18.5T169-213q-20-13-39.5-34.5T98-295q-7-15-.5-30t26.5-15h712q16 0 24.5 12.5T864-300q-8 23-30.5 48.5T791-213q-15-5-27.5-13.5T739-245q-5-4-9.5-5.5T720-252q-5 0-9.5 1.5T701-245q-23 19-47.5 34T600-196q-29 0-53.5-15T499-245q-5-4-9.5-5.5T480-252q-5 0-9.5 1.5T461-245q-23 19-47.5 34T360-196ZM480-80q-30 23-60.5 31.5T360-40q-29 0-59.5-8.5T240-80q-31 24-61.5 32T110-40q-13 0-21.5-8.5T80-70q0-19 12.5-24.5T120-100q29 0 55-11t49-27q4-2 7.5-3.5t8.5-1.5q5 0 8.5 1.5t7.5 3.5q24 16 49.5 27t54.5 11q29 0 55-11t49-27q4-2 7.5-3.5t8.5-1.5q5 0 8.5 1.5t7.5 3.5q24 16 49.5 27t54.5 11q29 0 55-11t49-27q2-1 16-5 4 0 16 5 23 16 49 27t55 11q15 0 27.5 5.5T880-70q0 19-12.5 24.5T840-40q-29 0-59.5-8.5T720-80q-30 23-60.5 31.5T600-40q-29 0-59.5-8.5T480-80Z"
		/>
	</svg>
);
