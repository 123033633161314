import { ReactNode } from 'react';

export const symbol_settings_bluetooth: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M451-488 278-315q-9 9-21 9t-21-9q-9-9-9-21t9-21l203-203-203-203q-9-9-9-21t9-21q9-9 21-9t21 9l173 173v-272q0-14 9.5-22t20.167-8q5.333 0 10.833 2t10.5 7l172 172q5 5 7 10.133 2 5.134 2 11Q683-726 681-721q-2 5-7 10L523-560l151 151q5 5 7 10.133 2 5.134 2 11Q683-382 681-377q-2 5-7 10L502-195q-5 5-10.5 7t-10.833 2Q470-186 460.5-194q-9.5-8-9.5-22v-272Zm60 198 100-98-100-100v198Zm0-342 100-100-100-98v198ZM305.825 0Q293 0 284.5-8.675q-8.5-8.676-8.5-21.5Q276-43 284.675-51.5q8.676-8.5 21.5-8.5Q319-60 327.5-51.325q8.5 8.676 8.5 21.5Q336-17 327.325-8.5q-8.676 8.5-21.5 8.5Zm174 0Q467 0 458.5-8.675q-8.5-8.676-8.5-21.5Q450-43 458.675-51.5q8.676-8.5 21.5-8.5Q493-60 501.5-51.325q8.5 8.676 8.5 21.5Q510-17 501.325-8.5q-8.676 8.5-21.5 8.5Zm170 0Q637 0 628.5-8.675q-8.5-8.676-8.5-21.5Q620-43 628.675-51.5q8.676-8.5 21.5-8.5Q663-60 671.5-51.325q8.5 8.676 8.5 21.5Q680-17 671.325-8.5q-8.676 8.5-21.5 8.5Z"
		/>
	</svg>
);
