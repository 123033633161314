import styled, { css } from 'styled-components';

import * as styles from 'pkg/config/styles';

import Link from 'pkg/router/Link';

import { DeleteComment } from 'components/Links';

import * as iconStyles from 'components/icon/styles.css';

/* @component FeedItem */
const FeedItem = styled.div`
	font-size: var(--font-size-base);
	position: relative;
	font-weight: var(--font-weight-normal);

	${DeleteComment} {
		opacity: 0;
		transition: opacity 250ms ease-in-out;
	}

	@media (hover: hover) {
		&:hover ${DeleteComment} {
			opacity: 1;
		}
	}
`;

export default FeedItem;

export const Parent = styled(FeedItem)``;

export const Content = styled.div`
	display: flex;
	flex-flow: column;
	flex: 1 1 auto;
	margin-right: 0;
	line-height: 1.3;
	position: relative;
`;

export const Title = styled.div`
	font-weight: var(--font-weight-semibold);
	margin: ${(props) => (props.firstChild ? '0 0 5px 0' : '5px 0 5px')};

	@media all and (max-width: 767px) {
		padding-right: 1.5rem;
	}
`;

Title.defaultProps = {
	firstChild: false,
};

export const DateTime = styled.time`
	color: var(--palette-gray-400);
	font-size: var(--font-size-sm);
	padding-top: ${({ paddingTop }) => (paddingTop ? '5px' : '0px')};
	display: flex;
	align-items: center;

	@media all and (min-width: 768px) {
		margin-left: auto;
	}
`;

DateTime.defaultProps = {
	padding: false,
};

/**
 * @type {React.Element<any>}
 */
export const MetaAction = styled.span`
	padding: var(--spacing-3);
	text-decoration: none;
	cursor: pointer;
	color: ${(props) =>
		props.$active ? styles.palette.blue[500] : styles.palette.black};
	font-size: var(--font-size-base);
	font-weight: var(--font-weight-semibold);
	position: relative;
	display: flex;
	align-items: center;

	.${iconStyles.icon} {
		margin-right: var(--spacing-2);
		font-size: var(--font-size-2xl);
	}

	&:first-child {
		padding-left: var(--spacing-5);
	}

	@media (hover: hover) {
		&:hover {
			color: ${(props) =>
				props.active ? styles.palette.blue[400] : styles.palette.gray[500]};
		}
	}
`;

export const MetaWrapper = styled.div`
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	padding: var(--spacing-1) 0 var(--spacing-2) 0;
`;

/**
 * @type {React.Element<any>}
 */
export const Comments = styled.div`
	margin-bottom: 30px;

	${({ reverseMargin }) =>
		reverseMargin &&
		css`
			margin-bottom: 0;
			margin-top: 30px;
		`}

	padding-bottom: ${({ moreLink }) => (moreLink ? '1rem' : '0')};

	&:empty {
		margin: 0;
	}

	${DateTime} {
		margin-left: 0;
	}

	${FeedItem} {
		margin-bottom: 0.5rem;
		padding-bottom: 0.5rem;
		margin-top: 1rem;
	}

	${FeedItem}:first-child {
		margin-top: 0;
	}

	& ${FeedItem}:last-of-type {
		margin-bottom: 0;
		padding-bottom: 0;
	}
`;

export const AuthorLink = styled(Link)`
	flex: 0 0 auto;
	left: 0;
`;
