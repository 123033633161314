import { ReactNode } from 'react';

export const symbol_contacts: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M174-40q-12.75 0-21.375-8.675Q144-57.351 144-70.175 144-83 152.625-91.5T174-100h612q12.75 0 21.375 8.675Q816-82.649 816-69.825 816-57 807.375-48.5T786-40H174Zm0-820q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T174-920h612q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T786-860H174Zm306 416q50 0 84-34t34-84q0-50-34-84t-84-34q-50 0-84 34t-34 84q0 50 34 84t84 34ZM132-160q-24 0-42-18t-18-42v-520q0-26 18-43t42-17h696q24 0 42 18t18 42v520q0 24-18 42t-42 18H132Zm88-60q51-63 121-94.5T479.5-346q68.5 0 140 31.5T740-220h88v-520H132v520h88Zm94 0h334q-31-30-72.5-48T480-286q-54 0-94.5 18T314-220Zm166.158-284Q456-504 439.5-521T423-562q0-24 16.342-41t40.5-17Q504-620 520.5-603t16.5 41q0 24-16.342 41t-40.5 17ZM480-480Z"
		/>
	</svg>
);
