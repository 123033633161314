export const isWeb = (): boolean => !window.TS?.isApp;

export const isApp = (): boolean => !isWeb();

export const isAndroid = (): boolean => isApp() && window.TS.isAndroid;

export const isIOS = (): boolean => isApp() && window.TS.isIOS;

export const usesHardwareVolumeControls = (): boolean => {
	const audio = new Audio();

	// Will fail on iOS devices
	audio.volume = 0.5;

	return audio.volume === 1;
};

export const isTouchDevice = (): boolean =>
	window.matchMedia('(pointer: coarse)').matches;
