import { ReactNode } from 'react';

export const symbol_mobile_friendly: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M220-130v30h440v-30H220Zm378-275 218-218q9-9 21-9t21 9q9 9 9 21.5t-9 21.5L619-341q-9 9-21 9t-21-9L449-469q-9-9-8.5-21t9.5-21q9-9 21-9t21 9l106 106ZM220-830h440v-30H220v30Zm0 0v-30 30Zm0 700v30-30Zm0 90q-24.75 0-42.375-17.625T160-100v-760q0-24.75 17.625-42.375T220-920h440q24.75 0 42.375 17.625T720-860v116q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T660-744v-26H220v580h440v-26q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T720-216v116q0 24.75-17.625 42.375T660-40H220Z"
		/>
	</svg>
);
