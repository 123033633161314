import { MouseEvent } from 'react';

import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useEndpoint } from 'pkg/api/use_endpoint';

import Icon from 'components/icon';

import { useAppState } from 'components/application/state';

import * as Context from 'design/context_menu';

import * as css from './styles.css';

interface LanguageSelectorProps {
	onSelect?: (language: string) => void;
}

export default function LanguageSelector({
	onSelect,
}: LanguageSelectorProps): JSX.Element {
	const defaultLocaleLabel: string = 'English';

	const { language: currentLanguageCode, setLanguage } = useAppState();

	const { record: languages, isLoading } = useEndpoint<
		models.language.Language[]
	>(endpoints.Language.Index());

	const currentLanguageLocale = (): string => {
		if (languages) {
			return (
				languages.find(
					(language: models.language.Language) =>
						language.code === currentLanguageCode
				)?.localLabel || defaultLocaleLabel
			);
		}

		return defaultLocaleLabel;
	};

	const isCurrentLanguage = (language: models.language.Language): boolean => {
		return language.code === currentLanguageCode;
	};

	const selectLanguage = async (event: MouseEvent<HTMLDivElement>) => {
		const language = event.currentTarget.dataset.languageCode;
		setLanguage(language);
		window.location.reload();
		if (onSelect) {
			onSelect(language);
		}
	};

	if (isLoading) {
		return null;
	}

	const toggle = (
		<span className={css.toggle}>
			{currentLanguageLocale()}
			<Icon name="chevron-down" size={2} />
		</span>
	);

	return (
		<div className={css.wrapper}>
			<Context.Menu toggleWith={toggle}>
				{languages.map((language: models.language.Language) => (
					<Context.Item
						key={language.code}
						data-language-code={language.code}
						icon={isCurrentLanguage(language) ? 'check' : null}
						onClick={selectLanguage}>
						<span>{language.localLabel}</span>
					</Context.Item>
				))}
			</Context.Menu>
		</div>
	);
}
