import { t } from '@transifex/native';

export enum AutoChargeOptions {
	Always = 'always',
	Settable = 'settable',
}

export enum RecurringInterval {
	Day = 'day',
	Week = 'week',
	Month = 'month',
	Year = 'year',
}

export type RecurringOptionStrings =
	| 'annually'
	| 'semiAnnually'
	| 'daily'
	| 'weekly'
	| 'bimonthly'
	| 'quarterly'
	| 'monthly'
	| 'custom';

export interface AvailableRecurringOptions {
	recurringInterval: RecurringInterval;
	recurringIntervalCount: number;
}

export interface ProviderSettings {
	allowProration: boolean;
	allowTrialPeriod: boolean;
	allowSubscriptionReactivation: boolean;
	autoCharge: AutoChargeOptions;
	canResetBillingCycle: boolean;
	canScheduleCancelSubscription: boolean;
	orderDescriptionMaxLength: number;
	subscriptionDescriptionMaxLength: number;
	canHaveServiceFee: boolean;
	availableRecurringOptions: {
		[key in RecurringOptionStrings]?: AvailableRecurringOptions;
	};
	allowCustomRecurringCombinations: boolean;
	allowReminderActionOnAutoChargedOrders: boolean;
	canHaveDiscountCode: boolean;
	canHaveDelayedSubscriptionStart: boolean;
}

export const DefaultProviderSettings: ProviderSettings = {
	allowProration: false,
	allowTrialPeriod: false,
	allowSubscriptionReactivation: false,
	autoCharge: AutoChargeOptions.Settable,
	canResetBillingCycle: false,
	canScheduleCancelSubscription: false,
	orderDescriptionMaxLength: 0,
	subscriptionDescriptionMaxLength: 0,
	canHaveServiceFee: false,
	allowCustomRecurringCombinations: false,
	allowReminderActionOnAutoChargedOrders: false,
	canHaveDiscountCode: false,
	canHaveDelayedSubscriptionStart: false,
	availableRecurringOptions: {},
};

export function getRecuringOptionTranslation(
	recurringOption: RecurringOptionStrings
): string {
	switch (recurringOption) {
		case 'annually': {
			return t('Annually');
		}
		case 'semiAnnually': {
			return t('Semi-annually');
		}
		case 'quarterly': {
			return t('Quarterly');
		}
		case 'bimonthly': {
			return t('Bi-monthly');
		}
		case 'monthly': {
			return t('Monthly');
		}
		case 'weekly': {
			return t('Weekly');
		}
		case 'daily': {
			return t('Daily');
		}
	}
}

export function getCustomIntervalTranslation(interval: RecurringInterval) {
	switch (interval) {
		case RecurringInterval.Year: {
			return t('year');
		}
		case RecurringInterval.Month: {
			return t('months');
		}
		case RecurringInterval.Week: {
			return t('weeks');
		}
		case RecurringInterval.Day: {
			return t('days');
		}
	}
}

// Returns all the unique recurring intervals that is supported by the provider
export function getIntervals(providerSettings: ProviderSettings) {
	return providerSettings?.availableRecurringOptions
		? [
				...new Set(
					Object.values(providerSettings.availableRecurringOptions).map(
						(s) => s.recurringInterval
					)
				),
			]
		: [];
}

export function getFirstRecurringOption(providerSettings: ProviderSettings) {
	if (!Object.keys(providerSettings).length) {
		return null;
	}

	const recurringOptionString = Object.keys(
		providerSettings.availableRecurringOptions
	)[0] as RecurringOptionStrings;

	return {
		recurringOptionString,
		firstRecurringOption:
			providerSettings.availableRecurringOptions[recurringOptionString],
	};
}
