// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tuBCZKrwVPbGwZMb8Mqe {
	font-size: var(--font-size-2xl);
	pointer-events: none;
	position: relative;
}

.NZUsLQABzm1vGx1Hg_x_ {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	position: relative;
	z-index: 98;
	cursor: pointer;
	width: 40px;
	height: 40px;
	border-radius: 20px;
	background: var(--palette-gray-300);
	color: var(--palette-black);
	-webkit-transition: -webkit-box-shadow 150ms ease-in-out;
	transition: -webkit-box-shadow 150ms ease-in-out;
	transition: box-shadow 150ms ease-in-out;
	transition: box-shadow 150ms ease-in-out, -webkit-box-shadow 150ms ease-in-out;
}

@media (hover: hover) {
		.NZUsLQABzm1vGx1Hg_x_:hover {
			color: var(--palette-blue-500);
			-webkit-box-shadow: var(--palette-gray-200) 0 0 0 4px;
			        box-shadow: var(--palette-gray-200) 0 0 0 4px;
		}
	}

.NZUsLQABzm1vGx1Hg_x_.EX3M5kxoNPhb0qX8go_A::before {
			content: '';
			width: 12px;
			height: 12px;
			background: var(--palette-red-500);
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			border-radius: var(--radius-5);
			-webkit-box-shadow: rgba(0, 0, 0, 0.4) 0 1px 2px;
			        box-shadow: rgba(0, 0, 0, 0.4) 0 1px 2px;
			z-index: 99;
		}
`, "",{"version":3,"sources":["webpack://./components/navigation/header/large_screen/notifications-item/styles.css"],"names":[],"mappings":"AAAA;CACC,+BAA+B;CAC/B,oBAAoB;CACpB,kBAAkB;AACnB;;AAEA;CACC,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CACnB,wBAAuB;KAAvB,qBAAuB;SAAvB,uBAAuB;CACvB,kBAAkB;CAClB,WAAW;CACX,eAAe;CACf,WAAW;CACX,YAAY;CACZ,mBAAmB;CACnB,mCAAmC;CACnC,2BAA2B;CAC3B,wDAAwC;CAAxC,gDAAwC;CAAxC,wCAAwC;CAAxC,8EAAwC;AAwBzC;;AAtBC;EACC;GACC,8BAA8B;GAC9B,qDAA6C;WAA7C,6CAA6C;EAC9C;CACD;;AAGC;GACC,WAAW;GACX,WAAW;GACX,YAAY;GACZ,kCAAkC;GAClC,cAAc;GACd,kBAAkB;GAClB,MAAM;GACN,QAAQ;GACR,8BAA8B;GAC9B,gDAAwC;WAAxC,wCAAwC;GACxC,WAAW;EACZ","sourcesContent":[".triggerIcon {\n\tfont-size: var(--font-size-2xl);\n\tpointer-events: none;\n\tposition: relative;\n}\n\n.wrapper {\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\tposition: relative;\n\tz-index: 98;\n\tcursor: pointer;\n\twidth: 40px;\n\theight: 40px;\n\tborder-radius: 20px;\n\tbackground: var(--palette-gray-300);\n\tcolor: var(--palette-black);\n\ttransition: box-shadow 150ms ease-in-out;\n\n\t@media (hover: hover) {\n\t\t&:hover {\n\t\t\tcolor: var(--palette-blue-500);\n\t\t\tbox-shadow: var(--palette-gray-200) 0 0 0 4px;\n\t\t}\n\t}\n\n\t&.hasNotifications {\n\t\t&::before {\n\t\t\tcontent: '';\n\t\t\twidth: 12px;\n\t\t\theight: 12px;\n\t\t\tbackground: var(--palette-red-500);\n\t\t\tdisplay: block;\n\t\t\tposition: absolute;\n\t\t\ttop: 0;\n\t\t\tright: 0;\n\t\t\tborder-radius: var(--radius-5);\n\t\t\tbox-shadow: rgba(0, 0, 0, 0.4) 0 1px 2px;\n\t\t\tz-index: 99;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var triggerIcon = `tuBCZKrwVPbGwZMb8Mqe`;
export var wrapper = `NZUsLQABzm1vGx1Hg_x_`;
export var hasNotifications = `EX3M5kxoNPhb0qX8go_A`;
export default ___CSS_LOADER_EXPORT___;
