import { Fragment } from 'react';

import DateTime from 'pkg/datetime';
import * as routes from 'pkg/router/routes';
import * as models from 'pkg/api/models';
import { useCurrentOrganization } from 'pkg/identity';
import { pushState } from 'pkg/router/state';

import Avatar from 'components/avatar';

import Row from 'components/layout/row';

import * as Table from 'design/table';

interface ListRowProps {
	submission: models.formSubmission.FormSubmission;
}

export function ListRow({ submission }: ListRowProps): JSX.Element {
	const org = useCurrentOrganization();
	const createdAtAsDateTime = DateTime.fromTimestamp(submission.createdAt);

	const goToSubmission = () => {
		pushState(routes.User.Submission(org.id, submission.id));
	};

	return (
		<Table.Row onClick={goToSubmission}>
			<Table.Cell>{submission.form.title}</Table.Cell>

			<Table.Cell key="createdAt" align="left">
				{createdAtAsDateTime.toLocaleDateString()}
			</Table.Cell>

			<Table.Cell>
				<Row align="center" autoColumns="max-content">
					{submission.submittedForUser && (
						<Fragment>
							<Avatar user={submission.submittedForUser} size={25} />
							{models.user.fullName(submission.submittedForUser)}
						</Fragment>
					)}
				</Row>
			</Table.Cell>

			<Table.Cell>
				<Row align="center" autoColumns="max-content">
					{submission.submittedByUser && (
						<Fragment>
							<Avatar user={submission.submittedByUser} size={25} />
							{models.user.fullName(submission.submittedByUser)}
						</Fragment>
					)}
				</Row>
			</Table.Cell>
		</Table.Row>
	);
}
