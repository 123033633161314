import { t } from '@transifex/native';
import { useSelector } from 'react-redux';
import { List } from 'immutable';

import * as styles from 'pkg/config/styles';

import Group from 'pkg/models/group';
import { GroupsProps } from 'pkg/models/user';

import * as selectors from 'pkg/selectors';
import { RootState } from 'pkg/reducers';

import { FormStateData, Selected } from 'routes/payments/hooks/useContactForm';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import Icon from 'components/icon';

import Section from 'components/form/Section';
import Column from 'components/layout/column';

import Button, { ButtonGroup } from 'design/button';

interface UserGroupsProps {
	contactData: FormStateData;
	onRemoveGroup: (groupId: number) => void;
	onOpenModal: (modal: string) => void;
}

const UserGroupsSection: React.FC<React.PropsWithChildren<UserGroupsProps>> = ({
	contactData,
	onRemoveGroup,
	onOpenModal,
}) => {
	const userGroups: List<Group> = useSelector((state: RootState) =>
		selectors.groups.findAllByIds(
			state,
			contactData.groups.map((group: GroupsProps) => group.id)
		)
	).toList();

	const handleOpenGroupsModal = () => onOpenModal('group');

	return (
		<Section
			icon="groups"
			title={t(`Add contact to group`)}
			description={t(`Select the group/teams that the contact is linked to`)}>
			<Column spacing={styles.spacing._5}>
				{userGroups.size !== 0 && (
					<Selected>
						<p>{t(`Group name`)}</p>
						{userGroups.map((group: Group) => (
							<div key={group.id}>
								<span>{group.name}</span>
								<Icon
									name="close-circle"
									onClick={() => onRemoveGroup(group.id)}
								/>
							</div>
						))}
					</Selected>
				)}
				<LargeScreen>
					<ButtonGroup>
						<Button
							secondary
							icon="add"
							onClick={handleOpenGroupsModal}
							testid="contacts.single.add_to_group">
							{t(`Add group`)}
						</Button>
					</ButtonGroup>
				</LargeScreen>
				<SmallScreen>
					<Button
						secondary
						icon="add"
						block
						onClick={handleOpenGroupsModal}
						testid="contacts.single.add_to_group">
						{t(`Add group`)}
					</Button>
				</SmallScreen>
			</Column>
		</Section>
	);
};

export default UserGroupsSection;
