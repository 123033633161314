import { ReactNode } from 'react';

export const symbol_add_to_home_screen: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M300-40q-24.75 0-42.375-17.625T240-100v-116q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T300-216v26h440v-580H300v26q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T240-744v-116q0-24.75 17.625-42.375T300-920h440q24.75 0 42.375 17.625T800-860v760q0 24.75-17.625 42.375T740-40H300Zm0-90v30h440v-30H300Zm109-408L184-314q-9 9-21 9t-21-9q-9-9-9-21.5t9-21.5l223-223H230q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T230-640h209q12.75 0 21.375 8.625T469-610v209q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T409-401v-137ZM300-830h440v-30H300v30Zm0 0v-30 30Zm0 700v30-30Z"
		/>
	</svg>
);
