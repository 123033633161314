import { ReactNode } from 'react';

export const symbol_mail_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M397-477Zm166-6Zm202 373-51-50H140q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h20l60 60h-80v520h514L76-798q-9-9.067-9-21.533Q67-832 76.053-841q9.052-9 21.5-9Q110-850 119-841l689 689q9 9 9 21t-9.053 21q-9.052 9-21.5 9Q774-101 765-110Zm115-630v502q0 15-9.318 22.5t-20.5 7.5Q839-208 829.5-215.683 820-223.366 820-238v-447L560-514q-11 7-20.425 3.661-9.425-3.338-14.5-11.5Q520-530 521-539.5q1-9.5 12-16.5l283-184H318q-15 0-22.5-9.318t-7.5-20.5q0-11.182 7.5-20.682Q303-800 318-800h502q24 0 42 18t18 42Z"
		/>
	</svg>
);
