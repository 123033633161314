import { t } from '@transifex/native';
import { useRef, useState } from 'react';

import * as models from 'pkg/api/models';

import * as Card from 'components/Card';
import Modal from 'components/modal';

import MatchCompetitionForm from 'components/match-competition/form';
import Form, { FormPayload } from 'components/form/Form';

import Button from 'design/button';

interface MatchCompetitionModalProps {
	type?: 'create' | 'edit';

	competition?: models.matchCompetition.MatchCompetition;

	handleSave: (data: FormPayload) => void;
	onClose: () => void;
}

export default function MatchCompetitionModal({
	type = 'create',
	competition,
	handleSave,
	onClose,
}: MatchCompetitionModalProps) {
	const formRef = useRef(null);
	const [saving, setSaving] = useState(false);

	const handleConfirm = async (data: FormPayload) => {
		setSaving(true);

		await handleSave(data);

		setSaving(false);
	};

	const submitForm = async () =>
		await formRef.current.dispatchEvent(
			new Event('submit', {
				cancelable: true,
				bubbles: true,
			})
		);

	return (
		<Form onSubmit={handleConfirm} formRef={formRef}>
			<Modal onClose={onClose}>
				<Card.Base>
					<Card.Header>
						<Card.Heading>
							{type === 'create'
								? t('Create competition')
								: t('Edit competition')}
						</Card.Heading>
					</Card.Header>
					<Card.Divider />
					<Card.Body>
						<MatchCompetitionForm competition={competition} />
					</Card.Body>
					<Card.Footer>
						<Button large onClick={onClose}>
							{t('Cancel')}
						</Button>
						<Button primary large onClick={submitForm} isLoading={saving}>
							{t('Save')}
						</Button>
					</Card.Footer>
				</Card.Base>
			</Modal>
		</Form>
	);
}
