import { ReactNode } from 'react';

export const symbol_deblur: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M240.105-364Q224-364 213-374.895q-11-10.894-11-27Q202-418 212.895-429q10.894-11 27-11Q256-440 267-429.105q11 10.894 11 27Q278-386 267.105-375q-10.894 11-27 11Zm0 162Q224-202 213-212.895q-11-10.894-11-27Q202-256 212.895-267q10.894-11 27-11Q256-278 267-267.105q11 10.894 11 27Q278-224 267.105-213q-10.894 11-27 11Zm0-318Q224-520 213-530.895q-11-10.894-11-27Q202-574 212.895-585q10.894-11 27-11Q256-596 267-585.105q11 10.894 11 27Q278-542 267.105-531q-10.894 11-27 11ZM121-537q-9 0-15-6t-6-15q0-9 6-15t15-6q9 0 15 6t6 15q0 9-6 15t-15 6Zm119.105-145Q224-682 213-692.895q-11-10.894-11-27Q202-736 212.895-747q10.894-11 27-11Q256-758 267-747.105q11 10.894 11 27Q278-704 267.105-693q-10.894 11-27 11ZM121-381q-9 0-15-6t-6-15q0-9 6-15t15-6q9 0 15 6t6 15q0 9-6 15t-15 6Zm281 281q-9 0-15-6t-6-15q0-9 6-15t15-6q9 0 15 6t6 15q0 9-6 15t-15 6Zm0-718q-9 0-15-6t-6-15q0-9 6-15t15-6q9 0 15 6t6 15q0 9-6 15t-15 6Zm.105 136Q386-682 375-692.895q-11-10.894-11-27Q364-736 374.895-747q10.894-11 27-11Q418-758 429-747.105q11 10.894 11 27Q440-704 429.105-693q-10.894 11-27 11ZM402-348q-22.5 0-38.25-15.75T348-402q0-22.5 15.75-38.25T402-456q22.5 0 38.25 15.75T456-402q0 22.5-15.75 38.25T402-348Zm0-156q-22.5 0-38.25-15.75T348-558q0-22.5 15.75-38.25T402-612q22.5 0 38.25 15.75T456-558q0 22.5-15.75 38.25T402-504Zm.105 302Q386-202 375-212.895q-11-10.894-11-27Q364-256 374.895-267q10.894-11 27-11Q418-278 429-267.105q11 10.894 11 27Q440-224 429.105-213q-10.894 11-27 11ZM770-479.967Q770-590 698.5-671 627-752 521-767q-13.175-1.533-22.087-10.733-8.913-9.2-8.913-22.234 0-13.033 9.5-21.533Q509-830 521-828q131 15 220 113.757 89 98.756 89 234Q830-345 741-246T521-132q-12 2-21.5-6.675-9.5-8.676-9.5-21.5 0-12.825 8.913-22.056Q507.825-191.462 521-193q106-15 177.5-95.967 71.5-80.968 71.5-191ZM490-480Z"
		/>
	</svg>
);
