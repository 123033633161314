import { ReactNode } from 'react';

export const symbol_running_with_errors: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M846.825-227Q834-227 825.5-235.625T817-257v-267q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T877-524v267q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Zm3.193 140Q836-87 826.5-96.483q-9.5-9.482-9.5-23.499 0-14.018 9.482-23.518 9.483-9.5 23.5-9.5 14.018 0 23.518 9.482 9.5 9.483 9.5 23.5Q883-106 873.518-96.5q-9.483 9.5-23.5 9.5ZM478-80q-83 0-156-31.5T195-197q-54-54-85.5-127T78-480q0-83 31.5-156T195-763q54-54 127-85.5T478-880q80 0 152 30t129 87q9 9 9 21t-9 21L529-491q-14 14-32.5 6.344T478-512v-308q-142 0-241 98.812Q138-622.375 138-480t98.812 241.188Q335.625-140 478-140q67 0 128-25t111-70q9-8 21-8t20 9q8 9 8 21t-9 20q-57 54-129.238 83.5T478-80Z"
		/>
	</svg>
);
