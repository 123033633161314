import { ReactNode } from 'react';

export const symbol_unarchive: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480.175-279q12.825 0 21.325-8.625T510-309v-171l66 66q7.822 8 19.911 8Q608-406 616-413.857q8-7.857 8-20T616-454L501-569q-9-9-21-9t-21 9L344-454q-8 7.822-8 19.911Q336-422 343.857-414q7.857 8 20 8T384-414l66-66v171q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625ZM180-674v494h600v-494H180Zm0 554q-24.75 0-42.375-17.625T120-180v-529q0-9.882 3-19.059 3-9.176 9-16.941l52-71q8-11 20.941-17.5Q217.882-840 232-840h495q14.118 0 27.059 6.5T775-816l53 71q6 7.765 9 16.941 3 9.177 3 19.059v529q0 24.75-17.625 42.375T780-120H180Zm17-614h565l-36.409-46H233l-36 46Zm283 307Z"
		/>
	</svg>
);
