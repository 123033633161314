import { ReactNode } from 'react';

export const symbol_family_home: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-259q5 0 10.132-2 5.131-2 9.868-6 18-16 43.5-41t49-54q23.5-29 40.5-59.5t17-56.5q0-38.824-25.5-64.412Q599-568 560-568q-23 0-44 11.5T480-529q-15-16-36.081-27.5-21.081-11.5-43.568-11.5Q361-568 335.5-542.412T310-478q0 26 17 56.5t40.5 59.5q23.5 29 49 54t43.5 41q4.737 4 9.868 6Q475-259 480-259ZM220-120q-24.75 0-42.375-17.625T160-180v-341l-60 46q-10.348 8-22.174 6Q66-471 58-481t-6.056-22.19Q53.89-515.381 64-523l380-289q8-6 17.211-9.5 9.21-3.5 18.789-3.5t18.789 3.5Q508-818 516-812l381 290q9.941 7.619 11.471 19.81Q910-490 902-479.63q-8 9.63-19.5 11.13T861-475l-61-46v341q0 24.75-17.625 42.375T740-120H220Zm0-60h520v-387L480-765 220-567v387Zm0 0h520-520Z"
		/>
	</svg>
);
