import { ReactNode } from 'react';

export const symbol_rebase: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M471-170H316q-10 39-42 64.5T200-80q-50 0-85-35t-35-85q0-42 25.5-74t64.5-42v-328q-39-10-64.5-42T80-760q0-50 35-85t85-35q42 0 74 25.5t42 64.5h155l-62-62q-9-9-9-21.158 0-12.158 8.609-21Q418-903 430.1-903t20.9 9l114 113q9 9 9 21t-9 21L451-626q-4 4-9.5 6.5T430-617q-6 0-11-2.5t-9.941-6.833Q400-635 400-647q0-12 9-21l62-62H316q-8 32-31 55t-55 31v328q32 8 55 31t31 55h155l-62-62q-9-9-9-21.158 0-12.158 8.609-21Q418-343 430.1-343t20.9 9l114 113q9 9 9 21t-9 21L451-66q-4 4-9.5 6.5T430-57q-6 0-11-2.5t-9.941-6.833Q400-75 400-87q0-12 9-21l62-62Zm289 90q-50 0-85-35t-35-85q0-41.98 25.5-73.99T730-316v-328q-39-10-64.5-42.01T640-760q0-50 35-85t85-35q50 0 85 35t35 85q0 42-25.5 74T790-644v328q39 10 64.5 42t25.5 74q0 50-35 85t-85 35Zm-560-60q25.5 0 42.75-17.25T260-200q0-25.5-17.25-42.75T200-260q-25.5 0-42.75 17.25T140-200q0 25.5 17.25 42.75T200-140Zm560 0q25.5 0 42.75-17.25T820-200q0-25.5-17.25-42.75T760-260q-25.5 0-42.75 17.25T700-200q0 25.5 17.25 42.75T760-140ZM200-700q25.5 0 42.75-17.25T260-760q0-25.5-17.25-42.75T200-820q-25.5 0-42.75 17.25T140-760q0 25.5 17.25 42.75T200-700Zm560 0q25.5 0 42.75-17.25T820-760q0-25.5-17.25-42.75T760-820q-25.5 0-42.75 17.25T700-760q0 25.5 17.25 42.75T760-700ZM200-200Zm560 0ZM200-760Zm560 0Z"
		/>
	</svg>
);
