import { t } from '@transifex/native';
import {
	ChangeEvent,
	Fragment,
	FunctionComponent,
	useEffect,
	useState,
} from 'react';
import { useSelector } from 'react-redux';

import Group from 'pkg/models/group';

import * as actions from 'pkg/actions';
import * as selectors from 'pkg/selectors';
import { RootState } from 'pkg/reducers';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import * as sdk from 'pkg/core/sdk';
import DateTime, { Granularity } from 'pkg/datetime';

import * as Card from 'components/Card';
import SectionTitle from 'components/SectionTitle';

import Column from 'components/layout/column';
import * as Inputs from 'components/form/inputs';
import Select from 'components/form/Select';

import Button from 'design/button';

interface Props {
	groupId: number;
}

interface LokSport {
	SportId: number;
	Name: string;
	SubSports?: LokSport[];
}

// This component is untranslated due to no users outside of Sweden accessing it.
// In addition, everything from IdrottOnline will always be in swedish.
const LOK: FunctionComponent<Props> = ({ groupId }) => {
	const group = useSelector<RootState, Group>((state) =>
		selectors.groups.find(state, groupId)
	);

	const [orgNr, setOrgNr] = useState(group.lokOrganizationNumber || '');
	const [lokSportId, setLokSportId] = useState(group.lokSportId || 0);
	const [fromTime, setFromTime] = useState(
		group.lokFromTime
			? new DateTime(new Date(group.lokFromTime * 1000))
			: new DateTime().prev(Granularity.year)
	);

	const [canSave, setCanSave] = useState(false);
	const [lokSports, setLokSports] = useState<LokSport[]>([]);

	useComponentDidMount(async () => {
		const req = await sdk.get('/v1/external/lok/sports');
		if (!req.ok) {
			return;
		}

		const sports = await req.json();
		setLokSports(sports);
	});

	const handleOrgNrChange = (e: ChangeEvent<HTMLInputElement>): void => {
		setOrgNr(e.target.value);
	};

	useEffect(() => {
		// orgNr must match XXXXXX-XXXX
		const regex = /^(\d{6}-\d{4})$/;
		if (regex.test(orgNr)) {
			setCanSave(true);
		} else {
			setCanSave(false);
		}
	}, [orgNr]);

	const handleLokSportChange = (e: ChangeEvent<HTMLSelectElement>): void => {
		setLokSportId(Number.parseInt(e.target.value, 10));
		setCanSave(true);
	};

	const handleDateChange = (date: Date) => {
		const fromTime = new DateTime(date);
		setFromTime(fromTime);
	};

	const save = async () => {
		setCanSave(false);
		await actions.organizations.updateLOKSettings(groupId, {
			lokOrganizationNumber: orgNr,
			lokSportId: lokSportId,
			lokFromTime: fromTime.getUnixTimestamp(),
		});

		actions.flashes.show({ title: 'LOK-inställningar sparade' }, 200);
	};

	return (
		<Column>
			<SectionTitle>{t(`LOK integration`)}</SectionTitle>
			<Card.Base $noBorder>
				<Card.Body>
					<Column>
						<Inputs.Group
							label="Organisationsnummer"
							hint="Samma organisationsnummer som i IdrottOnline för er klubb.">
							<Inputs.Field
								pattern="[\d]{6}-[\d]{4}"
								placeholder="XXXXXX-XXXX"
								value={orgNr}
								onChange={handleOrgNrChange}
							/>
						</Inputs.Group>

						<Inputs.Group
							label="Sport i IdrottOnline"
							hint="Denna sport gäller för hela klubben.">
							<Select value={lokSportId} onChange={handleLokSportChange}>
								<option value="">Välj sport</option>
								{lokSports.map((sport) =>
									sport.SubSports?.length > 0 ? (
										<Fragment key={sport.SportId}>
											<option value={sport.SportId}>{sport.Name}</option>
											<optgroup label={`Undersporter till ${sport.Name}`}>
												{sport.SubSports.map((subSport) => (
													<option
														key={subSport.SportId}
														value={subSport.SportId}>
														{subSport.Name}
													</option>
												))}
											</optgroup>
										</Fragment>
									) : (
										<option key={sport.SportId} value={sport.SportId}>
											{sport.Name}
										</option>
									)
								)}
							</Select>
						</Inputs.Group>
						<Inputs.Group
							label="Startdatum"
							hint="Endast händelser efter detta datum kommer skickas. Händelser som är äldre än 1 år kommer aldrig skickas.">
							<Inputs.DateTimePicker
								hideTimeInput
								onDateChange={handleDateChange}
								date={fromTime.toDate()}
							/>
						</Inputs.Group>
					</Column>
				</Card.Body>
				<Card.Footer>
					<Button primary disabled={!canSave} onClick={save}>
						Spara
					</Button>
				</Card.Footer>
			</Card.Base>
		</Column>
	);
};

export default LOK;
