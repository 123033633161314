import { ReactNode } from 'react';

export const symbol_psychology_alt: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M240-252q-57-52-88.5-121.5T120-520q0-150 105-255t255-105q125 0 221.5 73.5T827-615l55 218q4 14-5 25.5T853-360h-93v140q0 24.75-17.625 42.375T700-160H600v50q0 12.75-8.675 21.375Q582.649-80 569.825-80 557-80 548.5-88.625T540-110v-80q0-12.75 8.625-21.375T570-220h130v-170q0-12.75 8.625-21.375T730-420h84l-45-180q-24-97-105-158.5T480-820q-125 0-212.5 86.5T180-522.46q0 64.417 26.324 122.392Q232.649-342.092 281-297l19 18v169q0 12.75-8.675 21.375Q282.649-80 269.825-80 257-80 248.5-88.625T240-110v-142Zm257-198Zm-17 130q17 0 28.5-11.5T520-360q0-17-11.5-28.5T480-400q-17 0-28.5 11.5T440-360q0 17 11.5 28.5T480-320Zm3-343q22 0 36.5 12t14.5 31q0 16-8.5 30.5T492-549q-19 20-27.5 35.5T453-478q-2 12 6.5 21t21.5 9q11 0 20.5-7.5T514-476q3-12 9.5-23.5T544-526q32-35 42-53t10-41q0-42-32.5-71T483-720q-30.562 0-57.781 14Q398-692 381-667q-8 11-4 24t15.5 18q11.5 5 22 1.5T433-638q10-12 23-18.5t27-6.5Z"
		/>
	</svg>
);
