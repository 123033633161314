import { ReactNode } from 'react';

export const symbol_piano_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m792-83-38-37H180q-24.75 0-42.375-17.625T120-180v-574l-38-38q-9-9.067-9-21.533Q73-826 82.053-835q9.052-9 21.5-9Q116-844 125-835l710 710q9 9 9 21t-9.053 21q-9.052 9-21.5 9Q801-74 792-83Zm48-123-60-60v-514H673v360q0 9-5 17t-14 11L533-513v-267H427v161L206-840h574q24.75 0 42.375 17.625T840-780v574Zm-660 26h157v-210h-20q-12.75 0-21.375-8.625T287-420v-167L180-694v514Zm197 0h206v-111L427-447v27q0 13-8.5 21.5T397-390h-20v210Zm246 0h71l-71-71v71Z"
		/>
	</svg>
);
