import { ReactNode } from 'react';

export const symbol_bath_private: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M776-580q-26 0-45-18.9t-19-45q0-26.1 19-45.1t45-19q26 0 45 19t19 45.1q0 26.1-19 45T776-580Zm-592 0q-26 0-45-18.9t-19-45q0-26.1 19-45.1t45-19q26 0 45 19t19 45.1q0 26.1-19 45T184-580Zm182.2-60q-8.4 0-13.3-7t-3.9-15q3-27-4-47.5T317-758q-27-35-37-66.5t-6-74.5q1-9 7.333-15.5Q287.667-921 297-921q8 0 14 6.828T316-898q-3 39 3.5 60.5T347-787q24.894 32.409 34.947 62.205Q392-695 390-663q-.609 10.062-7.304 16.531Q376-640 366.2-640Zm120.108 0Q478-640 473-646.742T469-662q3-27-4.5-48.5T437-758q-26-35-36-66t-6-75q1-9 7.176-15.5Q408.353-921 417-921q8 0 13 7t4 15q-3 35 4.5 59.5T468-787q24.894 32.409 34.947 62.205Q513-695 510-662q-1 9-7.5 15.5t-16.192 6.5Zm120 0Q598-640 593-646.742T589-662q3-27-4.5-48.5T557-758q-26-35-36-66t-6-75q1-9 7.177-15.5Q528.353-921 537-921q7.846 0 12.923 6.364Q555-908.273 554-900q-3 34 5 59.5t29 53.5q24.894 32.409 34.947 62.205Q633-695 630-662q-1 9-7.5 15.5t-16.192 6.5ZM140-80q-24.75 0-42.375-17.625T80-140v-270q0-12.75 8.625-21.375T110-440v-23q0-30 25.5-50t54.5-20q15.644 0 29.822 6Q234-521 245-510l43 49q5 6 11 11t13.388 10H648q7-5 13-10t11-11l43-49q11-11 25.178-17T770-533q29 0 50.5 20.5T850-463v23q13 0 21.5 8.625T880-410v270q0 24.75-17.625 42.375T820-80H140Zm0-190h680v-110H140v110Zm0 130h680v-70H140v70Zm340-130Zm0 60Zm0 0v-60 60Z"
		/>
	</svg>
);
