import { ReactNode } from 'react';

export const symbol_skeleton: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-80q-45 0-78.5-9.5T326-125l-109-70q-29-18-43-46.5T160-301q0-69 48-104t104-35q29 0 56.5 9.5T418-402q5 5 11.5 8t20.5 6v-72q-55-3-101-12t-79-24q-33-15-51.5-35.5T200-579q0-20 14-37.5t39-32.5q-6-9-9.5-19.5T240-691q0-22 17-40.5t46-33.5q-2-5-2.5-9.5t-.5-9.5q0-23 22.5-41.5T382-855q12-4 23 1.5t15 17.5q4 12-2 23t-18 15q-14 5-23.5 9.5T362-780q5 5 24 11.5t64 9.5v-91q0-13 8.5-21.5T480-880q13 0 21.5 8.5T510-850v91q45-3 64-9.5t24-11.5q-5-4-14.5-8.5T560-798q-12-4-18-15t-2-23q4-12 15-17.5t23-1.5q23 8 52.5 25t29.5 46q0 4-3 19 29 15 46 33.5t17 40.5q0 12-3.5 23t-9.5 20q25 14 39 31.5t14 37.5q0 27-18.5 47.5T690-496q-33 15-79 24t-101 12v72q14-3 20.5-6t11.5-8q22-19 49.5-28.5T648-440q56 0 104 35.5T800-300q0 31-14 59.5T743-194l-109 69q-42 26-75.5 35.5T480-80Zm0-60q35 0 62-7.5t60-28.5l109-69q14-9 21.5-23.5T740-300q0-39-29-59.5T648-380q-18 0-35.5 6T581-357q-22 19-47 29t-54 10q-29 0-54-10t-47-29q-14-11-31.5-17t-35.5-6q-34 0-63 20t-29 59q0 17 7.5 32t21.5 24l110 70q33 21 59.5 28t61.5 7Zm-30-380v-60q-48-2-83.5-9T305-608q-23 10-33 18.5T260-577q2 11 44.5 31.5T450-520ZM330-260q12 0 21-9t9-21q0-12-9-21t-21-9q-12 0-21 9t-9 21q0 12 9 21t21 9Zm120-380v-59q-35-2-60.5-7T346-720q-23 9-33.5 17.5T300-690q1 14 37 29.5T450-640Zm60 120q103-5 145.5-25.5T700-577q-2-3-12-11.5T655-608q-26 12-61.5 19t-83.5 9v60Zm0-120q77-5 113-20.5t37-29.5q-2-4-12.5-12.5T614-720q-18 9-43.5 14t-60.5 7v59Zm120 380q12 0 21-9t9-21q0-12-9-21t-21-9q-12 0-21 9t-9 21q0 12 9 21t21 9Zm-150 0Z"
		/>
	</svg>
);
