import { ReactNode } from 'react';

export const symbol_houseboat: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-240q-37 0-61.5 19.5T344-201q-50 0-74.5-19.5T207-240q-26 0-47.5 13.5T110-205q-11 3-20.5-5T80-231q0-13 8.5-23t20.5-15q21-9 41-20t56-11q51 0 76 19.5t61 19.5q37 0 63-19.5t74-19.5q51 0 75.5 19.5T618-261q35 0 60-19.5t76-19.5q37 0 57 11t40 20q12 5 20.5 15t8.5 23q0 13-9.5 21t-20.5 5q-27-8-49-21.5T753-240q-37 0-62 19.5T617-201q-50 0-74.5-19.5T480-240ZM230-360q-22 0-42-8t-36-24l-27-27q-9-9-9-21t9-21q9-9 21-8.5t21 8.5l27 26q8 8 17 11.5t19 3.5h60v-186l-37 27q-10 8-22 5.5T211-586q-8-10-5.5-22t12.5-20l226-166q8-6 17.5-9t18.5-3q9 0 18.5 3t17.5 9l226 166q10 8 12.5 20t-5.5 22q-8 11-20 13t-23-6l-36-27v186h60q11 0 19.5-3.5T766-435l27-27q9-8 21-8t21 9q9 9 9 21t-9 21l-27 27q-16 16-36 24t-42 8H230Zm120-60h100v-50q0-13 8.5-21.5T480-500q13 0 21.5 8.5T510-470v50h100v-230l-130-95-130 95v230Zm0 0h260-260Z"
		/>
	</svg>
);
