import { ReactNode } from 'react';

export const symbol_print: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M302-120q-24.75 0-42.375-17.625T242-180v-116H140q-24.75 0-42.375-17.625T80-356v-186q0-45.05 30.5-75.525Q141-648 186-648h588q45.05 0 75.525 30.475Q880-587.05 880-542v186q0 24.75-17.625 42.375T820-296H718v116q0 24.75-17.625 42.375T658-120H302ZM140-356h102v-16q0-24.75 17.625-42.375T302-432h356q24.75 0 42.375 17.625T718-372v16h102v-186.215Q820-562 806.775-575 793.55-588 774-588H186q-19.55 0-32.775 13.225Q140-561.55 140-542v186Zm518-292v-132H302v132h-60v-132q0-24.75 17.625-42.375T302-840h356q24.75 0 42.375 17.625T718-780v132h-60Zm81 155q12 0 21-9t9-21q0-12-9-21t-21-9q-12 0-21 9t-9 21q0 12 9 21t21 9Zm-81 313v-192H302v192h356ZM140-588h680-680Z"
		/>
	</svg>
);
