const objects = [
	'M6.95271 3.89099C5.85048 3.89099 4.97224 4.77179 4.97224 5.84513V15.4113C4.97224 16.4847 5.85048 17.3655 6.95271 17.3655H7.61033C8.46999 17.3655 9.31129 17.7019 9.92741 18.3173L9.92882 18.3187L11.4252 19.7976C11.856 20.2226 12.5626 20.2227 12.9933 19.7976L14.4897 18.3187L14.4911 18.3173C15.1065 17.7027 15.9386 17.3655 16.8082 17.3655H17.4746C18.5754 17.3655 19.4551 16.4773 19.4551 15.4113V5.84513C19.4551 4.77179 18.5768 3.89099 17.4746 3.89099H6.95271ZM3.67224 5.84513C3.67224 4.04248 5.1439 2.59099 6.95271 2.59099H17.4746C19.2834 2.59099 20.7551 4.04249 20.7551 5.84513V15.4113C20.7551 17.2038 19.2849 18.6655 17.4746 18.6655H16.8082C16.2755 18.6655 15.7756 18.8713 15.409 19.2379L15.4063 19.2406L13.9069 20.7225C12.9698 21.6475 11.449 21.6478 10.5119 20.7227L9.01229 19.2406L9.00958 19.2379C8.64379 18.8721 8.13541 18.6655 7.61033 18.6655H6.95271C5.1439 18.6655 3.67224 17.214 3.67224 15.4113V5.84513Z',
	'M12.2137 9.78853C13.0899 9.78853 13.8003 9.07818 13.8003 8.20193C13.8003 7.32568 13.0899 6.61533 12.2137 6.61533C11.3374 6.61533 10.6271 7.32568 10.6271 8.20193C10.6271 9.07818 11.3374 9.78853 12.2137 9.78853ZM12.2137 11.0885C13.8079 11.0885 15.1003 9.79615 15.1003 8.20193C15.1003 6.60771 13.8079 5.31533 12.2137 5.31533C10.6194 5.31533 9.32707 6.60771 9.32707 8.20193C9.32707 9.79615 10.6194 11.0885 12.2137 11.0885Z',
	'M7.12019 15.5294C7.12019 13.3657 8.87419 11.6117 11.0379 11.6117H13.3895C15.5531 11.6117 17.3072 13.3657 17.3072 15.5294H16.0072C16.0072 14.0837 14.8352 12.9117 13.3895 12.9117H11.0379C9.59216 12.9117 8.42019 14.0837 8.42019 15.5294H7.12019Z',
].map((d: string) => ({
	type: 'path',
	attributes: {
		fillRule: 'evenodd',
		clipRule: 'evenodd',
		d,
	},
}));

const icon = {
	name: 'clip-user-tag',
	viewbox: '0 0 25 25',
	objects,
};

export default icon;
