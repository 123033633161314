import { ReactNode } from 'react';

export const symbol_for_you: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-479q66 0 135-8.5T744-513q21-8 38.5 5t17.5 35v287q0 18.667-10.596 33.973Q778.808-136.72 762-130q-63 25-139 37.5T480-80q-67 0-143-12.5T198-130q-16.808-6.72-27.404-22.027Q160-167.333 160-186v-287q0-22 17.5-35t38.5-5q60 17 129 25.5t135 8.5Zm260 289v-263q-53 14-124.287 24t-135.5 10Q416-419 344.5-429T220-453v263q49 20 123.5 35T480-140q62 0 136.5-15T740-190ZM480-880q65 0 109.5 44.5T634-726q0 65-44.5 109.5T480-572q-65 0-109.5-44.5T326-726q0-65 44.5-109.5T480-880Zm.159 248Q519-632 546.5-659.659q27.5-27.66 27.5-66.5Q574-765 546.341-792.5q-27.66-27.5-66.5-27.5Q441-820 413.5-792.341q-27.5 27.66-27.5 66.5Q386-687 413.659-659.5q27.66 27.5 66.5 27.5ZM480-742Zm0 445Z"
		/>
	</svg>
);
