import { ReactNode } from 'react';

export const symbol_local_post_office: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M113-80q-14.025 0-23.513-9.487Q80-98.975 80-113v-298q0-14.025 9.487-23.513Q98.975-444 113-444h133v-206q0-97 68.25-163.5T480-880h173q94.583 0 160.792 67.5Q880-745 880-650v540q0 12.75-8.675 21.375Q862.649-80 849.825-80 837-80 828.5-88.625T820-110v-85H626v82q0 15-12 24t-28 9H113Zm513-175h194v-395q0-70.125-48.5-120.062Q723-820 653-820H479.5q-71.569 0-122.534 49Q306-722 306-650v206h280q16 0 28 9t12 24v156Zm-273-7 213-122H140l213 122ZM140-140h426v-189L383-224q-6.706 3.636-14.157 5.818Q361.392-216 353.196-216q-8.196 0-15.782-2.182T323-224L140-329v189Zm0-244v20-.5 42.5-7 189-189 7-42.5.5-20Zm290-191q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T430-635h266q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T696-575H430Z"
		/>
	</svg>
);
