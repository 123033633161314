import { ReactNode } from 'react';

export const symbol_hls: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M120-384v-192q0-10.4 6.8-17.2 6.8-6.8 17.2-6.8 10.4 0 17.2 6.8 6.8 6.8 6.8 17.2v65h98v-65q0-10.4 6.8-17.2 6.8-6.8 17.2-6.8 10.4 0 17.2 6.8 6.8 6.8 6.8 17.2v192q0 10.4-6.8 17.2-6.8 6.8-17.2 6.8-10.4 0-17.2-6.8-6.8-6.8-6.8-17.2v-79h-98v79q0 10.4-6.8 17.2-6.8 6.8-17.2 6.8-10.4 0-17.2-6.8-6.8-6.8-6.8-17.2Zm276 1v-193q0-10.4 6.8-17.2 6.8-6.8 17.2-6.8 10.4 0 17.2 6.8 6.8 6.8 6.8 17.2v168h96q10.4 0 17.2 6.8 6.8 6.8 6.8 17.2 0 10.4-6.8 17.2-6.8 6.8-17.2 6.8H419q-9 0-16-7t-7-16Zm258 23q-15 0-24.5-9.5T620-394v-22q0-10 9-14t15-10q9.6 0 16.8 7.2 7.2 7.2 7.2 16.8v8h104v-53H654q-14.45 0-24.225-9.775Q620-480.55 620-495v-71q0-15 9.5-24.5T654-600h132q15 0 24.5 9.5T820-566v22q0 10-9 14t-15 10q-9.6 0-16.8-7.2-7.2-7.2-7.2-16.8v-8H668v53h118q14.45 0 24.225 9.775Q820-479.45 820-465v71q0 15-9.5 24.5T786-360H654Z"
		/>
	</svg>
);
