import { ReactNode } from 'react';

export const symbol_indeterminate_question_box: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-120q-24 0-42-18t-18-42v-142q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T180-322v142h142q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T322-120H180Zm600 0H638q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T638-180h142v-142q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T840-322v142q0 24-18 42t-42 18ZM120-780q0-24 18-42t42-18h142q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T322-780H180v142q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T120-638v-142Zm720 0v142q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T780-638v-142H638q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T638-840h142q24 0 42 18t18 42ZM477.028-245Q493-245 504-256.028q11-11.028 11-27T503.972-310q-11.028-11-27-11T450-309.972q-11 11.028-11 27T450.028-256q11.028 11 27 11ZM479-658q27.6 0 48.3 16.842Q548-624.316 548-598q0 25.487-15.13 45.432Q517.739-532.622 500-516q-27 26-39.5 46.5T445-420q-2 11 6.627 19 8.628 8 20.393 8 10.98 0 19.98-8t11-19q4-18 13.5-33t29.5-35q32-32 45.5-57.5t13.5-54.071Q605-651 569.391-683 533.783-715 479-715q-41.774 0-74.887 17T351-649q-6 9-3 19.5t12 15.5q11 6 24 4t21-12q14.281-17.379 33.105-26.69Q456.93-658 479-658Z"
		/>
	</svg>
);
