import styled from 'styled-components';

import * as styles from 'pkg/config/styles';

const Wrapper = styled.div`
	display: grid;
	grid-template-rows: 1fr;
	row-gap: var(--spacing-3);
	align-items: start;
	align-content: start;
	cursor: pointer;
	transition: transform 135ms ease-out;

	@media (hover: hover) {
		&:hover {
			transform: scale(1.022);
		}
	}
`;

const Container = styled.div`
	border-radius: var(--radius-5);
	background-color: var(--palette-blue-300);
	font-size: var(--font-size-lg);
	font-weight: var(--font-weight-semibold);
	color: var(--palette-blue-500);
	display: flex;
	place-items: center;
	place-content: center;
	width: 100%;
	height: calc(100% - 38px);
	min-height: 38px;

	@media ${styles.breakpoint.small} {
		aspect-ratio: 0;
		border-radius: var(--radius-3);
		font-size: var(--font-size-sm);
		font-weight: var(--font-weight-semibold);
		height: 35px;
	}
`;

interface GroupItemButtonProps {
	label: string;
	aspectRatio?: string;
	onClick: () => void;
}

export default function GroupItemButton({
	label,
	onClick,
}: GroupItemButtonProps): JSX.Element {
	return (
		<Wrapper onClick={onClick}>
			<Container>{label}</Container>
		</Wrapper>
	);
}
