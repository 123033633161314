import { ReactNode } from 'react';

export const symbol_tools_power_drill: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M240-180h260v-70H240v70Zm20-390h200q8 0 14-6t6-14q0-8-6-14t-14-6H260q-8 0-14 6t-6 14q0 8 6 14t14 6Zm0-100h200q8 0 14-6t6-14q0-8-6-14t-14-6H260q-8 0-14 6t-6 14q0 8 6 14t14 6Zm380 150v-60h100v-120H640v-60h100q24.75 0 42.375 17.625T800-700v30h90q12.75 0 21.375 8.625T920-640q0 12-8.625 21T890-610h-90v30q0 24.75-17.625 42.375T740-520H640ZM480-310h-60v-190h160v-280H240q-42 0-71 29t-29 71v80q0 42 29 71t71 29h80v190h-60v-130h-20q-66 0-113-47T80-600v-80q0-66 47-113t113-47h340q24.75 0 42.375 17.625T640-780v280q0 24.75-17.625 42.375T580-440H480v130ZM225-120q-18.75 0-31.875-13.125T180-165v-100q0-18.75 13.125-31.875T225-310h290q18.75 0 31.875 13.125T560-265v100q0 18.75-13.125 31.875T515-120H225Zm135-520Zm140 460H240h260Z"
		/>
	</svg>
);
