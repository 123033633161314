import { ReactNode } from 'react';

export const symbol_invert_colors_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M706-658q46 45 70 106.5T800-434q0 28-5 56.5T780-323q-4 11-12.5 16.5T748-301q-8 0-15-3t-13-9L498-535q-8-8-13-19.5t-5-23.5v-218L379-696q-9 9-21 9t-21-9q-9-9-9-21.5t9-21.5l111-110q7-7 15.5-10t16.5-3q8 0 16.5 3t15.5 10l194 191ZM480-120q-132 0-226-91.5T160-435q0-51 16-100t54-97L78-784q-9-9-9-21.5t9-21.5q9-9 21.5-9t21.5 9l707 707q9 9 9 21t-9 21q-9 9-21.5 9T785-78L676-186q-45 34-94.5 50T480-120Zm0-60v-203L273-590q-30 37-41.5 77T220-435q0 107 76.5 181T480-180Z"
		/>
	</svg>
);
