import { t } from '@transifex/native';

import DateTime from 'pkg/datetime';
import * as routes from 'pkg/router/routes';
import * as models from 'pkg/api/models';
import { MatchEventType } from 'pkg/api/models/match_event';
import { useCurrentOrganization } from 'pkg/identity';

import { LargeScreen } from 'components/MediaQuery';
import * as Card from 'components/Card';
import SectionTitle from 'components/SectionTitle';
import Icon from 'components/icon';

import {
	GoalList,
	GoalItem,
	ResultCardBase,
	BottomCardBody,
	BottomCardSection,
	GoalWrapper,
	HomeScore,
	AwayScore,
} from 'components/match/ResultStyles';
import Column from 'components/layout/column';
import Result from 'components/match/result';

import Button from 'design/button';

interface ResultSummaryProps {
	event: models.event.Event;
	match: models.match.Match;
	matchEvents: models.matchEvent.MatchEvent[];
}

export default function ResultSummary({
	event,
	match,
	matchEvents,
}: ResultSummaryProps) {
	const org = useCurrentOrganization();

	const goalEvents = matchEvents.filter(
		(event) => event.type === MatchEventType.MatchEventTypeGoal && event.minute
	);

	const activeGroupGoals = (isHome: boolean) => {
		if (!goalEvents.length) {
			return null;
		}

		return (
			<GoalList>
				{goalEvents
					.sort((a, b) => a.minute - b.minute)
					.filter((event) => event.isOpponent !== isHome)
					.map((event) => (
						<GoalItem key={event.id}>
							{models.user.fullName(event?.user)}
							{` ${event.minute}'${
								event.extraMinute ? `+${event.extraMinute}` : ''
							}`}
						</GoalItem>
					))}
			</GoalList>
		);
	};

	return (
		<Column>
			<SectionTitle>
				{t('Result')}
				{models.canEdit(match) && (
					<LargeScreen>
						<Button
							inline
							noPadding
							icon="edit"
							href={routes.Match.Edit(org.id, event.id, match.id, 'result')}>
							{t('Edit')}
						</Button>
					</LargeScreen>
				)}
			</SectionTitle>
			<ResultCardBase>
				<Result match={match} />

				{goalEvents.length !== 0 && (
					<GoalWrapper key="GoalWrapper">
						<HomeScore>{activeGroupGoals(match.isHome)}</HomeScore>
						<AwayScore>{activeGroupGoals(!match.isHome)}</AwayScore>
					</GoalWrapper>
				)}

				<Card.Divider />
				<BottomCardBody>
					<BottomCardSection>
						<Icon name="clock" />
						{new DateTime(new Date(match.startsAt * 1000)).toLocaleDateString({
							weekday: 'short',
							month: 'short',
							day: 'numeric',
						})}
					</BottomCardSection>
					<BottomCardSection>
						<Icon name="location" />
						{match.location}
					</BottomCardSection>
				</BottomCardBody>
			</ResultCardBase>
		</Column>
	);
}
