import { Map, Record } from 'immutable';

import Notification from 'pkg/models/notification';

import * as actionTypes from 'pkg/actions/action-types';

interface NotificationsState {
	hasUnread: boolean;
	entities: Map<number, Notification>;
}

class NotificationsReducer
	extends Record<NotificationsState>(
		{
			hasUnread: false,
			entities: Map<number, Notification>(),
		},
		'notifications'
	)
	implements NotificationsState
{
	entities: Map<number, Notification>;
}

const initialState = new NotificationsReducer();

interface NormalizrPayload {
	result: number;
	entities: {
		notifications: { [key: number]: Notification };
	};
}

interface Action {
	type: string;
	payload?: NormalizrPayload;
	hasUnread?: boolean;
}

const addNotifications = (state = initialState, action: Action) => {
	let entities = state.entities;

	Object.values(action.payload.entities.notifications).forEach(
		(notification: Notification) =>
			(entities = entities.set(notification.id, new Notification(notification)))
	);

	const hasUnread = entities.find((notification) => !notification.isRead)
		? true
		: false;

	return state.set('entities', entities).set('hasUnread', hasUnread);
};

const notifications = (
	state = initialState,
	action: Action
): NotificationsReducer => {
	switch (action.type) {
		case actionTypes.Notifications.SET_ITEMS: {
			return addNotifications(state, action);
		}

		case actionTypes.Notifications.SET_HAS_UNREAD: {
			return state.set('hasUnread', action.hasUnread);
		}

		case actionTypes.AUTH_LOGOUT_SUCCESS:
			return initialState;

		default:
			return state;
	}
};

export default notifications;
