// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.twbSwXo1D3IoKXnTU00a {
	display: grid;
	grid-template-columns: auto 1fr auto;
}
`, "",{"version":3,"sources":["webpack://./routes/organization/user-profile/overview/memberships/styles.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,oCAAoC;AACrC","sourcesContent":[".contextItemWithExtraIcon {\n\tdisplay: grid;\n\tgrid-template-columns: auto 1fr auto;\n}\n"],"sourceRoot":""}]);
// Exports
export var contextItemWithExtraIcon = `twbSwXo1D3IoKXnTU00a`;
export default ___CSS_LOADER_EXPORT___;
