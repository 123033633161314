// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YT6N_EJQ1i8FusE05XoJ {
	grid-area: thread;
	background: var(--palette-white);
	min-height: 0;
	position: relative;
}

.TfQmSyO13LAwlSbXd4tJ {
	padding: var(--spacing-5) 0 0 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: reverse;
	    -ms-flex-flow: column-reverse;
	        flex-flow: column-reverse;
	overflow-y: scroll;
}

.TfQmSyO13LAwlSbXd4tJ * {
		will-change: opacity; /* Opacity won't change, but this fixes some scroll issues in iOS */
	}

.UCZ2rl2PL9ZS8WV6lC0k {
	-webkit-box-flex: 0;
	    -ms-flex: 0 0 42px;
	        flex: 0 0 42px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;

	-webkit-box-pack: center;

	    -ms-flex-pack: center;

	        justify-content: center;
}

.qGOdu32RYw7h9mOv3M2U {
	width: 60px;
}
`, "",{"version":3,"sources":["webpack://./components/chat/thread/styles.css"],"names":[],"mappings":"AAAA;CACC,iBAAiB;CACjB,gCAAgC;CAChC,aAAa;CACb,kBAAkB;AACnB;;AAEA;CACC,+BAA+B;CAC/B,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,YAAY;CACZ,4BAAyB;CAAzB,8BAAyB;KAAzB,6BAAyB;SAAzB,yBAAyB;CACzB,kBAAkB;AAKnB;;AAHC;EACC,oBAAoB,EAAE,mEAAmE;CAC1F;;AAGD;CACC,mBAAc;KAAd,kBAAc;SAAd,cAAc;CACd,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;;CAEnB,wBAAuB;;KAAvB,qBAAuB;;SAAvB,uBAAuB;AACxB;;AAEA;CACC,WAAW;AACZ","sourcesContent":[".wrapper {\n\tgrid-area: thread;\n\tbackground: var(--palette-white);\n\tmin-height: 0;\n\tposition: relative;\n}\n\n.inner {\n\tpadding: var(--spacing-5) 0 0 0;\n\tdisplay: flex;\n\theight: 100%;\n\tflex-flow: column-reverse;\n\toverflow-y: scroll;\n\n\t* {\n\t\twill-change: opacity; /* Opacity won't change, but this fixes some scroll issues in iOS */\n\t}\n}\n\n.loadingSpinner {\n\tflex: 0 0 42px;\n\tdisplay: flex;\n\talign-items: center;\n\n\tjustify-content: center;\n}\n\n.spinner {\n\twidth: 60px;\n}\n"],"sourceRoot":""}]);
// Exports
export var wrapper = `YT6N_EJQ1i8FusE05XoJ`;
export var inner = `TfQmSyO13LAwlSbXd4tJ`;
export var loadingSpinner = `UCZ2rl2PL9ZS8WV6lC0k`;
export var spinner = `qGOdu32RYw7h9mOv3M2U`;
export default ___CSS_LOADER_EXPORT___;
