import ProductTable from 'routes/payments/products/list/ProductTable';

interface ProductsProps {
	organizationId: number;
}

const Products: React.FC<React.PropsWithChildren<ProductsProps>> = ({
	organizationId,
}) => <ProductTable organizationId={organizationId} />;

export default Products;
