import { Component, Fragment, createRef, cloneElement } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import * as styles from 'pkg/config/styles';

import CardAnatomy, * as Card from 'components/Card';
import Modal from 'components/modal';

const NOOP = () => {};

const Actions = styled.div`
	display: grid;
	grid-gap: 1rem;
	grid-auto-flow: column;
	justify-content: end;

	@media ${styles.breakpoint.small} {
		justify-content: stretch;
	}
`;

export default class extends Component {
	static propTypes = {
		heading: PropTypes.string,

		didConfirm: PropTypes.bool,
		confirmButton: PropTypes.element.isRequired,
		cancelButton: PropTypes.element.isRequired,

		onConfirm: PropTypes.func,
		onCancel: PropTypes.func,
		onClose: PropTypes.func,
	};

	static defaultProps = {
		didConfirm: false,
		onConfirm: NOOP,
		onCancel: NOOP,
		onClose: NOOP,
	};

	state = {
		isOpen: true,
		willClose: false,
		keyboardOpen: false,
	};

	modalRef = createRef();

	static getDerivedStateFromProps(nextProps) {
		if (nextProps.didConfirm === true) {
			return { willClose: true };
		}

		return null;
	}

	componentDidUpdate(prevProps, prevState) {
		const { willClose } = this.state;

		if (willClose === true && willClose !== prevState.willClose) {
			this.modalRef.current?.close();
		}
	}

	handleClose = () => {
		this.setState(
			{
				isOpen: false,
				didConfirm: false,
			},
			this.props.onClose
		);
	};

	handleCancel = () => {
		this.setState(
			{
				willClose: true,
				didConfirm: false,
				isOpen: false,
			},
			this.props.onCancel
		);
	};

	handleConfirm = () => {
		if (this.state.didConfirm) return;

		this.props.onConfirm();
	};

	render() {
		const { confirmButton, cancelButton, heading, children, noSpacing } =
			this.props;

		return (
			<Modal
				forwardedRef={this.modalRef}
				open={this.state.isOpen}
				onClose={this.handleClose}>
				<CardAnatomy>
					{heading && (
						<Fragment>
							<Card.Header>
								<Card.Heading>{heading}</Card.Heading>
							</Card.Header>
							<Card.Divider />
						</Fragment>
					)}
					<Card.Body $flex $noSpacing={noSpacing}>
						{children}
					</Card.Body>
					{!this.state.keyboardOpen && (
						<Card.Footer>
							<Actions>
								{cloneElement(cancelButton, {
									onClick: this.handleCancel,
									large: true,
								})}
								{cloneElement(confirmButton, {
									onClick: this.handleConfirm,
									large: true,
								})}
							</Actions>
						</Card.Footer>
					)}
				</CardAnatomy>
			</Modal>
		);
	}
}
