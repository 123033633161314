import { ReactNode } from 'react';

export const symbol_format_paragraph: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M419.825-160Q407-160 398.5-168.625T390-190v-230h-40q-78.85 0-134.425-55.606Q160-531.212 160-610.106T215.575-744.5Q271.15-800 350-800h340q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T690-740h-80v550q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T550-190v-550H450v550q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Z"
		/>
	</svg>
);
