import { t } from '@transifex/native';
import { ReactNode } from 'react';

import DateTime, { Weekday } from 'pkg/datetime';
import { calendarDateRangeString } from 'pkg/date';
import { useQueryState } from 'pkg/hooks/query-state';
import * as models from 'pkg/api/models';
import { useCurrentGroupId, useCurrentOrganization } from 'pkg/identity';
import { range } from 'pkg/utils';
import { popState } from 'pkg/router/state';
import * as arrays from 'pkg/arrays';

import { FilterState } from 'routes/scheduling/templates/filters';
import {
	ConflictingItems,
	findGroupsByFilterForBookings,
	findResourcesByFilterByBookings,
} from 'routes/scheduling/utils';
import Booking from 'routes/scheduling/bookings/print/Booking';
import {
	BookingsProvider,
	useBookingsContext,
} from 'routes/scheduling/bookings';

import Badge from 'components/Badge';

import * as Printable from 'components/layout/printable';
import InfoBox from 'components/form/info-box';
import Column from 'components/layout/column';
import Row from 'components/layout/row';

import * as css from './styles.css';

interface GroupedGridItems {
	[Weekday.MONDAY]: ReactNode[];
	[Weekday.TUESDAY]: ReactNode[];
	[Weekday.WEDNESDAY]: ReactNode[];
	[Weekday.THURSDAY]: ReactNode[];
	[Weekday.FRIDAY]: ReactNode[];
	[Weekday.SATURDAY]: ReactNode[];
	[Weekday.SUNDAY]: ReactNode[];
}

interface GridItem<T> {
	source: T;
	items: GroupedGridItems | null;
}

const getGroupedGridItems = (
	bookings: models.booking.Booking[],
	filters: FilterState,
	conflicts: ConflictingItems,
	group?: models.group.Group
): GroupedGridItems => {
	const grouped: GroupedGridItems = {
		[Weekday.MONDAY]: [],
		[Weekday.TUESDAY]: [],
		[Weekday.WEDNESDAY]: [],
		[Weekday.THURSDAY]: [],
		[Weekday.FRIDAY]: [],
		[Weekday.SATURDAY]: [],
		[Weekday.SUNDAY]: [],
	};

	if (bookings.length === 0) {
		return;
	}

	if (filters.types?.length > 0) {
		bookings = bookings.filter((item: models.booking.Booking) =>
			filters.types.includes(item.type)
		);
	}

	bookings.forEach((booking: models.booking.Booking) => {
		const hasConflicts: boolean =
			conflicts[booking.id]?.groupConflicts.length > 0 ||
			conflicts[booking.id]?.resourceConflicts.length > 0;

		if (filters.conflicts && !hasConflicts) {
			return;
		}

		const start = DateTime.fromTimestamp(booking.startsAt).getWeekday();
		const end = DateTime.fromTimestamp(booking.endsAt).getWeekday();

		range(start, end).forEach((weekday: Weekday, n: number) => {
			grouped[weekday].push(
				<Booking
					key={`${booking.id}:${n}`}
					group={group}
					booking={booking}
					hasConflicts={hasConflicts}
				/>
			);
		});
	});

	return grouped;
};

function useGridGroupedByGroup(): Map<number, GridItem<models.group.Group>> {
	const { flattenedGroups, bookings, search, filters, conflicts } =
		useBookingsContext();

	let groups: models.group.Group[] = findGroupsByFilterForBookings(
		flattenedGroups,
		bookings,
		filters,
		conflicts
	);

	if (search) {
		groups = groups.filter((group: models.group.Group) =>
			group.name.toLocaleLowerCase().includes(search)
		);
	}

	const getBookingsForGroup = (group: models.group.Group): GroupedGridItems => {
		const groupBookings = bookings.filter(
			(booking: models.booking.Booking) => booking.forGroupId === group.id
		);

		return getGroupedGridItems(groupBookings, filters, conflicts);
	};

	const grid = new Map<number, GridItem<models.group.Group>>();

	groups.forEach((group: models.group.Group) => {
		const items = getBookingsForGroup(group);

		if (items !== undefined) {
			grid.set(group.id, { source: group, items });
		}
	});

	return grid;
}

function useGridGroupedByResource(): Map<
	number,
	GridItem<models.resource.Resource>
> {
	const {
		flattenedGroups,
		resources: flattenedResources,
		bookings,
		search,
		filters,
		conflicts,
	} = useBookingsContext();

	let resources: models.resource.Resource[] = findResourcesByFilterByBookings(
		flattenedResources,
		bookings,
		filters,
		conflicts
	);

	if (search) {
		resources = resources.filter((resource: models.resource.Resource) =>
			resource.title.toLocaleLowerCase().includes(search)
		);
	}

	const grid = new Map<number, GridItem<models.resource.Resource>>();

	const getBookingsForResource = (
		resource: models.resource.Resource
	): GroupedGridItems => {
		const resourceGroup = flattenedGroups.find(
			(g: models.group.Group) => g.id === resource.groupId
		);

		const resourceBookings = bookings.filter(
			(booking: models.booking.Booking) => {
				const resourceIds = arrays.unique<number>(
					[
						booking.resourceId,
						booking.resources?.map(
							(r: models.booking.BookingResource) => r.resourceId
						),
					].flat()
				);

				return resourceIds.includes(resource.id);
			}
		);

		return getGroupedGridItems(
			resourceBookings,
			filters,
			conflicts,
			resourceGroup
		);
	};

	resources.forEach((resource: models.resource.Resource) => {
		const items = getBookingsForResource(resource);

		if (items !== undefined) {
			grid.set(resource.id, { source: resource, items });
		}
	});

	return grid;
}

export default function BookingsPrint(): JSX.Element {
	const qs = useQueryState();
	const groupId = useCurrentGroupId();

	return (
		<BookingsProvider groupId={groupId}>
			{qs.is('view', 'group') ? <GroupCalendar /> : <ResourceCalendar />}
		</BookingsProvider>
	);
}

function GroupCalendar(): JSX.Element {
	const org = useCurrentOrganization();
	const grid = useGridGroupedByGroup();
	const { startDate, endDate } = useBookingsContext();

	const calendarData: Printable.CalendarGroup[] = [];

	const goBack = (): string => {
		popState();
		return;
	};

	Array.from(grid.values()).forEach((item: GridItem<models.group.Group>) => {
		calendarData.push({
			title: item.source.name,
			items: item.items,
		});
	});

	const calendarDateRange = calendarDateRangeString(startDate, endDate);

	const title = t('{org} Bookings {date}', {
		org: org.name,
		date: calendarDateRange,
		_context: 'bookings printout',
	});

	const caption = (
		<Row columns="1fr auto" justify="start" align="center">
			<Row columns="50px auto" align="center">
				<Badge badgeUrl={org.profileImageUrl} />
				<span className={css.calendarCaption}>{org.name}</span>
			</Row>
			<span>{calendarDateRange}</span>
		</Row>
	);

	if (calendarData.length === 0) {
		return (
			<Printable.Sheet canPrint={false} backAction={goBack}>
				<InfoBox color="orange">
					<Column>
						<strong>{t('Nothing to print')}</strong>
						<div>{t('Data filtering returned nothing to print.')}</div>
					</Column>
				</InfoBox>
			</Printable.Sheet>
		);
	}

	return (
		<Printable.Sheet title={title} backAction={goBack} orientation="landscape">
			<Printable.Calendar data={calendarData} caption={caption} />
		</Printable.Sheet>
	);
}

function ResourceCalendar(): JSX.Element {
	const org = useCurrentOrganization();
	const grid = useGridGroupedByResource();
	const { startDate, endDate } = useBookingsContext();

	const calendarData: Printable.CalendarGroup[] = [];

	const goBack = (): string => {
		popState();
		return;
	};

	Array.from(grid.values()).forEach(
		(item: GridItem<models.resource.Resource>) => {
			calendarData.push({
				title: item.source.title,
				items: item.items,
			});
		}
	);

	const calendarDateRange = calendarDateRangeString(startDate, endDate);

	const title = t('{org} Bookings {date}', {
		org: org.name,
		date: calendarDateRange,
		_context: 'bookings printout',
	});

	const caption = (
		<Row columns="1fr auto" justify="start" align="center">
			<Row columns="50px auto" align="center">
				<Badge badgeUrl={org.profileImageUrl} />
				<span className={css.calendarCaption}>{org.name}</span>
			</Row>
			<span>{calendarDateRange}</span>
		</Row>
	);

	if (calendarData.length === 0) {
		return (
			<Printable.Sheet canPrint={false} backAction={goBack}>
				<InfoBox color="orange">
					<Column>
						<strong>{t('Nothing to print')}</strong>
						<div>{t('Data filtering returned nothing to print.')}</div>
					</Column>
				</InfoBox>
			</Printable.Sheet>
		);
	}

	return (
		<Printable.Sheet title={title} backAction={goBack} orientation="landscape">
			<Printable.Calendar data={calendarData} caption={caption} />
		</Printable.Sheet>
	);
}
