const icon = {
	name: 'video-placeholder',
	viewBox: '0 0 38 35',
	objects: [
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M27,31.1c-0.2-0.1-0.4-0.2-0.5-0.4c-0.4-0.3-0.9-0.6-1.3-0.9c-0.1-0.1-0.2-0.1-0.4-0.2l0,0  c-0.8-0.5-0.1-1.7,0.7-1.2l2.4,1.4c0.3,0.2,0.7-0.2,0.5-0.5l-3.9-6.7c-0.5-0.9,0.7-1.6,1.2-0.7l1.9,3.4c0.1,0.1,0.2,0.2,0.3,0.2  c0.2,0,0.4-0.1,0.4-0.2c0,0,0,0,0-0.1c0-0.1,0-0.2-0.1-0.2c-0.2-0.3-0.1-0.7,0.1-0.9c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.3-0.1,0.5,0  c0.1,0,0.3,0.1,0.4,0.3c0,0,0,0,0,0.1l0.5,0.9c0.2,0.3,0.6,0.2,0.7-0.1c0,0,0,0,0-0.1c0-0.1,0-0.2-0.1-0.2c-0.2-0.3-0.1-0.7,0.1-0.9  c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.3-0.1,0.5,0c0.1,0,0.3,0.1,0.4,0.3c0,0,0,0,0,0.1l0.5,0.9c0.2,0.3,0.6,0.2,0.7-0.1  c0,0,0,0,0-0.1c0-0.1,0-0.2-0.1-0.2c-0.2-0.3-0.1-0.7,0.1-0.9c0.1-0.1,0.1-0.1,0.2-0.2c0.3-0.1,0.7-0.1,0.9,0.4  c0.1,0.1,0.1,0.2,0.2,0.4c0.1,0.1,0.1,0.2,0.1,0.3c1.6,3.2,2,6.8-2.3,8.1C30.4,33.2,28.7,32.3,27,31.1z M32.3,22.3  c0,0,0.1-0.1,0.1-0.1c0.5-0.3,1.1-0.5,1.7-0.3c0.7,0.2,1.2,0.6,1.5,1.1c1,1.8,1.9,4,1.7,6c-0.1,1.1-0.5,2.1-1.3,3  c-0.8,0.9-1.8,1.5-3.2,2c-1.9,0.6-3.7,0.1-5.1-0.7c-0.7-0.4-1.4-0.8-2-1.2c-0.1,0-0.1-0.1-0.2-0.1c-0.6-0.4-1.1-0.7-1.6-1  c-0.6-0.3-1-0.9-1.1-1.5c-0.1-0.6,0-1.2,0.3-1.6c0.3-0.4,0.7-0.8,1.2-1c0.2-0.1,0.5-0.1,0.7-0.1l-2-3.4c-0.3-0.6-0.4-1.2-0.2-1.8  c0.2-0.6,0.6-1.1,1-1.3s1.1-0.4,1.7-0.2c0.6,0.2,1.1,0.6,1.5,1.1l0.8,1.4c0.4-0.2,1-0.3,1.5-0.2c0.2,0,0.4,0.1,0.6,0.2  c0,0,0.1-0.1,0.1-0.1c0.5-0.3,1.1-0.5,1.7-0.3C32,22.1,32.2,22.2,32.3,22.3z M27.5,20.1c0-0.7-0.1-1.3-0.5-2c-1-1.7-3.2-2.3-4.9-1.3  c-1.7,1-2.3,3.2-1.3,4.9c0.3,0.6,0.8,1,1.3,1.3l1,1.8c-1.4-0.2-2.8-1.1-3.6-2.4c-1.4-2.4-0.6-5.4,1.8-6.8c2.4-1.4,5.4-0.6,6.8,1.8  c0.8,1.4,0.9,3,0.3,4.4L27.5,20.1z M31,19.2V4.4c0-0.7-0.5-1.2-1.2-1.2H4.2C3.5,3.2,3,3.7,3,4.4v16.2c0,0.7,0.5,1.2,1.2,1.2h13.5  v2.2H4.2c-1.9,0-3.4-1.5-3.4-3.4V4.4c0-1.9,1.5-3.4,3.4-3.4h25.6c1.9,0,3.4,1.5,3.4,3.4v14.8H31z M22.2,13.5l-8.1,4.6  c-0.8,0.4-1.7-0.1-1.7-1V7.9c0-0.9,0.9-1.4,1.7-1l8.1,4.6C23,12,23,13.1,22.2,13.5z M14.7,15.3l4.8-2.7l-4.8-2.7V15.3z',
			},
		},
	],
};

export default icon;
