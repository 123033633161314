import { ReactNode } from 'react';

export const symbol_remove_done: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M835-110 581-364l-93 93q-9 9-21 9t-21-9L264-453q-9-9-8.5-21.5T265-496q9-9 21.5-9t21.5 9l159 160 71-71-409-408q-9-9-8.5-21.5T130-858q9-9 21.5-9t21.5 9l705 706q9 9 9 21t-9 21q-9 9-21.5 9t-21.5-9ZM276-271 94-453q-9-9-8.5-21.5T95-496q9-9 21.5-9t21.5 9l159 160 43 43-22 22q-9 9-21 9t-21-9Zm390-178-43-43 207-207q9-9 21.5-9t21.5 9q9 9 9 21.5t-9 21.5L666-449Zm-85-85-43-43 122-122q9-9 21.5-9t21.5 9q9 9 9 21.5t-9 21.5L581-534Z"
		/>
	</svg>
);
