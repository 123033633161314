import { ReactNode } from 'react';

export const symbol_movie: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m140-800 58 119q7.729 15.4 22.083 24.2Q234.438-648 251-648q32.5 0 49.25-27.462Q317-702.923 303-732l-33-68h89l58 119q7.729 15.4 22.083 24.2Q453.438-648 470-648q32.5 0 49.25-27.462Q536-702.923 522-732l-33-68h89l58 119q7.729 15.4 22.083 24.2Q672.438-648 689-648q32.5 0 49.25-27.462Q755-702.923 741-732l-33-68h112q24 0 42 18t18 42v520q0 24-18 42t-42 18H140q-24 0-42-18t-18-42v-520q0-24 18-42t42-18Zm0 212v368h680v-368H140Zm0 0v368-368Z"
		/>
	</svg>
);
