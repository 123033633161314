import { ReactNode } from 'react';

export const symbol_podcasts: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M450-110v-296q-22-9-36-29t-14-45q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 25-14 45t-36 29v296q0 13-8.5 21.5T480-80q-13 0-21.5-8.5T450-110ZM226-212q-9 9-22.5 9T182-213q-48-54-75-121.5T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 78-27 145.5T778-213q-8 10-21.5 10.5T734-211q-9-9-8-22t10-23q40-45 62-102t22-122q0-142-99-241t-241-99q-142 0-241 99t-99 241q0 65 22 122t62 102q9 10 10 22.5t-8 21.5Zm113-113q-9 9-22 9t-21-10q-26-32-41-70.5T240-480q0-100 70-170t170-70q100 0 170 70t70 170q0 45-15 83.5T664-326q-8 10-20.5 10.5T622-324q-9-9-9-22t8-23q18-23 28.5-51t10.5-60q0-75-52.5-127.5T480-660q-75 0-127.5 52.5T300-480q0 32 10.5 60t28.5 51q8 10 8.5 22.5T339-325Z"
		/>
	</svg>
);
