import { ReactNode } from 'react';

export const symbol_how_to_vote: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-80q-24 0-42-18t-18-42v-175q0-10.566 4-21.132T136-355l98-109q9-10 21.5-10t21.37 8.87Q285-457 285.5-444.5 286-432 278-423l-98 108h600l-92-105q-8-9-7.28-20.927.72-11.928 8.64-20.128Q698-470 711-470t22 10l92 105q8 8 11.5 18.667Q840-325.667 840-315v175q0 24-18 42t-42 18H180Zm0-60h600v-115H180v115Zm262-245L283-544q-19-19-17-42.5t20-41.5l212-212q16.934-16.56 41.967-17.28Q565-858 583-840l159 159q17 17 17.5 40.5T740-597L528-385q-17 17-42 18t-44-18Zm249-257L541-792 333-584l150 150 208-208ZM180-140v-115 115Z"
		/>
	</svg>
);
