import { Dispatch } from 'react';
import { t } from '@transifex/native';

import FormRow from 'components/form/Row';
import Toggle from 'components/form/Toggle';

interface CommentsProps {
	data: any;
	dispatch: Dispatch<any>;
	isNewPost: boolean;
}

export default function Comments({
	data,
	dispatch,
	isNewPost,
}: CommentsProps): JSX.Element {
	const handleChange = () => {
		dispatch({
			type: 'set_value',
			payload: { key: 'commentsDisabled', value: !data.commentsDisabled },
		});
	};

	return (
		<FormRow>
			<Toggle
				label={t('Disable comments')}
				onChange={handleChange}
				isActive={data.commentsDisabled}
				description={
					!isNewPost &&
					t(
						'Existing comments will still be displayed, but will not be editable.'
					)
				}
			/>
		</FormRow>
	);
}
