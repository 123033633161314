import { ReactNode } from 'react';

export const symbol_preview_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-285q-80 0-143-43t-92-112q19-46 54.5-80.5T382-575l39 39q-38 11-69.5 36T300-440q27 49 75 77t105 28q30 0 58.5-8t52.5-23l35 35q-31 22-68 34t-78 12Zm191-86-35-35q7-8 13-16.5t11-17.5q-25-45-67.5-72T498-544l-49-49q85-11 159 31.5T715-440q-8 20-19 37t-25 32ZM180-120q-25 0-42.5-17.5T120-180v-571l-33-33q-9-9-8.5-21.5T88-827q9-9 21.5-9t21.5 9l706 707q9 9 9 21t-9 21q-9 9-21.5 9T794-78l-43-42H180Zm0-60h511L180-692v512Zm660-22-60-60v-421H359L202-840h578q25 0 42.5 17.5T840-780v578Z"
		/>
	</svg>
);
