const fileCsv = {
	name: 'file-csv',
	objects: [
		{
			type: 'path',
			attributes: {
				d: 'M12.5525 5.25769L15.178 7.84419L18.0446 10.7498H12.5525V5.25769Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M12.5525 5.25769H5.82031V20.3166H18.0446L18.0446 10.7498H16.7446V19.0166H7.12031V6.55769H12.5525V5.25769Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M10.8143 14.5908C10.7535 14.0869 10.3721 13.7971 9.86717 13.7971C9.29082 13.7971 8.85083 14.2041 8.85083 14.9179C8.85083 15.6295 9.28336 16.0386 9.86717 16.0386C10.4265 16.0386 10.7642 15.6668 10.8143 15.2652L10.3476 15.263C10.304 15.4963 10.1207 15.6306 9.87462 15.6306C9.5433 15.6306 9.31851 15.3845 9.31851 14.9179C9.31851 14.464 9.54011 14.2051 9.87782 14.2051C10.1303 14.2051 10.3125 14.3511 10.3476 14.5908H10.8143Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M12.3286 14.4544H12.7708C12.7644 14.0656 12.4448 13.7971 11.959 13.7971C11.4806 13.7971 11.1301 14.0613 11.1323 14.4576C11.1312 14.7794 11.3581 14.9637 11.7267 15.0521L11.9643 15.1117C12.2019 15.1693 12.334 15.2375 12.335 15.3845C12.334 15.5443 12.1827 15.6529 11.9483 15.6529C11.7086 15.6529 11.536 15.5421 11.5211 15.3238H11.0747C11.0864 15.7957 11.4242 16.0397 11.9536 16.0397C12.4863 16.0397 12.7995 15.785 12.8006 15.3855C12.7995 15.0223 12.5257 14.8294 12.1465 14.7442L11.9504 14.6973C11.7608 14.6537 11.6021 14.5833 11.6053 14.4267C11.6053 14.2861 11.7299 14.1828 11.9558 14.1828C12.1763 14.1828 12.3116 14.2829 12.3286 14.4544Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M13.505 13.8269H12.9926L13.7458 16.0088H14.3403L15.0924 13.8269H14.581L14.0526 15.4846H14.0324L13.505 13.8269Z',
			},
		},
	],
};

export default fileCsv;
