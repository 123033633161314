import { ReactNode } from 'react';

export const symbol_night_sight_auto: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M440-190q59 0 110.5-27.5T641-290q-129-8-220-95.5T330-600q0-17 2.5-34.5T338-669q-65 36-106.5 96T190-440q0 104.167 72.917 177.083Q335.833-190 440-190Zm0 60q-129 0-219.5-90.5T130-440q0-54.798 19-104.399T201-634q33-40 79-68t100-40q18-4 29.5 12t1.5 34q-12 22-16.5 46.485Q390-625.029 390-600q0 104.167 72.917 177.083Q535.833-350 640-350q11 0 21-.5t21.273-3.773Q702-360 716-344.5q14 15.5 5 34.5-38 82-114 131t-167 49Zm218-474-20 56.836Q634-536 625.31-530q-8.689 6-19.31 6-17 0-27.5-14t-4.554-31l101.09-287q3.964-11 13.652-17.5Q698.375-880 710-880h26q12 0 21.583 6.72Q767.167-866.56 771-856l101.056 287q5.944 17-4.556 31T840-524q-10.667 0-19.394-6.229Q811.879-536.458 808-547l-20.27-57H658Zm16-50h98l-49-155-49 155ZM421-387Z"
		/>
	</svg>
);
