import { ReactNode } from 'react';

export const symbol_desktop_mac: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M420-240H140q-24 0-42-18t-18-42v-480q0-24 18-42t42-18h680q24 0 42 18t18 42v480q0 24-18 42t-42 18H540l79 80q1 1 5 12.25v11.25q0 7.5-4.5 12T607-120H348.425q-5.425 0-8.925-3.529-3.5-3.53-3.5-8.824V-150q0-1 4-9l80-81ZM140-396h680v-384H140v384Zm0 0v-384 384Z"
		/>
	</svg>
);
