import { Map, Record } from 'immutable';

import AccountRelation from 'pkg/models/account_relation';

import {
	AccountRelations,
	AUTH_LOGOUT_SUCCESS,
} from 'pkg/actions/action-types';

interface IAccountRelationState {
	entities: Map<number, AccountRelation>;
	activeRequestItemId: number;
}

class AccountRelationsState
	extends Record<IAccountRelationState>(
		{
			entities: Map<number, AccountRelation>(),
			activeRequestItemId: 0,
		},
		'accountRelationsReducer'
	)
	implements IAccountRelationState {}

const setAccountRelationsReducer = (
	state: AccountRelationsState,
	action: any
) => {
	Object.values(action.payload.entities.accountRelations).forEach(
		(entity: any) => {
			state = state.setIn(['entities', entity.id], new AccountRelation(entity));
		}
	);

	return state;
};

const deleteAccountRelationReducer = (
	state: AccountRelationsState,
	action: any
) => state.deleteIn(['entities', action.accountRelationId]);

const accountRelationsReducer = (
	state: AccountRelationsState = new AccountRelationsState(),
	action: any
): AccountRelationsState => {
	switch (action.type) {
		case AccountRelations.SET_ITEMS:
			return setAccountRelationsReducer(state, action);
		case AccountRelations.DELETE_ITEM:
			return deleteAccountRelationReducer(state, action);
		case AccountRelations.SET_ACTIVE_REQUEST_ITEM:
			return state.set('activeRequestItemId', action.payload.itemId || 0);
		case AUTH_LOGOUT_SUCCESS:
			return new AccountRelationsState();
		default:
			return state;
	}
};

export default accountRelationsReducer;
