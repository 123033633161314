import { ReactNode } from 'react';

export const symbol_nest_wifi_router: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M360-120q-100 0-170-70t-70-170v-240q0-100 70-170t170-70h240q100 0 170 70t70 170v240q0 100-70 170t-170 70H360ZM180-360h600v-240q0-75-52.5-127.5T600-780H360q-75 0-127.5 52.5T180-600v240Zm11 60q19 57 68 88.5T360-180h240q53 0 101.5-31.5T769-300H191Zm289-60Z"
		/>
	</svg>
);
