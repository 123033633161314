import { t } from '@transifex/native';
import { Fragment, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { breakpoint, palette } from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import Link from 'pkg/router/Link';

import FogisLinkModal from 'containers/group/settings/Fogis/FogisLinkModal';

import * as Card from 'components/Card';
import Icon from 'components/icon';

import Row from 'components/layout/row';
import InfoBox from 'components/form/info-box';
import Column from 'components/layout/column';

import MissingEntities from 'design/placeholders/block';
import Button from 'design/button';

import * as css from './styles.css';

interface FogisSettingsProps {
	group: models.group.Group;
	refresh: () => void;
}
export default function FogisSettings({
	group,
	refresh,
}: FogisSettingsProps): JSX.Element {
	const [fogisModal, setFogisModal] = useState(false);
	const [fogisLoading, setFogisLoading] = useState(false);

	const handleOpenFogisModal = () => setFogisModal(true);
	const handleClose = () => setFogisModal(false);

	const onSuccess = () => setFogisLoading(true);
	const isSmallScreen = useMediaQuery({ maxWidth: breakpoint.toMedium });

	let connectedContent = (
		<div className={css.emptyState}>
			<MissingEntities
				title={t('No connections yet')}
				description={t('Get started by connecting to SvFF')}
				inCard={false}
				actions={
					<Button primary onClick={handleOpenFogisModal}>
						{t('Connect to SvFF')}
					</Button>
				}
				image={
					<Icon name="link" actualSize size={5} fill={palette.gray[500]} />
				}></MissingEntities>
		</div>
	);

	if (group.externalId) {
		const fogisId = group.externalId.toString().split('fogis:').pop();
		const teamUrl = 'https://www.svenskfotboll.se/lagsida/' + fogisId;

		const teamLinkUrl = <Link href={teamUrl.toString()}>{teamUrl}</Link>;
		connectedContent = !isSmallScreen ? (
			<Row className={css.connectedContent} columns="15% 1fr 20%">
				<div className={css.connectedTitle}>{t('Fogis link')}</div>
				<div className={css.connectedUrl}>{teamLinkUrl}</div>
				<Button onClick={handleOpenFogisModal} secondary icon="link" small>
					{t('Edit link')}
				</Button>
			</Row>
		) : (
			<Fragment>
				<Row className={css.connectedContent} columns="25% 1fr">
					<div className={css.connectedTitle}>{t('Fogis link')}</div>
					<div className={css.connectedUrl}>{teamLinkUrl}</div>
				</Row>
				<Row className={css.connectedContent} columns="1fr">
					<Button onClick={handleOpenFogisModal} secondary icon="link" small>
						{t('Edit link')}
					</Button>
				</Row>
			</Fragment>
		);
	}
	return (
		<Card.Base>
			<Card.Header>
				<Card.Heading>{t('Import matches from SvFF Fogis')}</Card.Heading>
			</Card.Header>
			<Card.Divider />
			<Card.Body>
				<Column>
					{fogisLoading && (
						<InfoBox
							color="blue"
							text={t(
								'We are in the process of fetching all your matches from Fogis, they will show up in your calendar in a few minutes.'
							)}
						/>
					)}
					{connectedContent}
				</Column>

				{fogisModal && (
					<FogisLinkModal
						group={group}
						handleClose={handleClose}
						onSuccess={onSuccess}
						refresh={refresh}
					/>
				)}
			</Card.Body>
		</Card.Base>
	);
}
