import { ReactNode } from 'react';

export const symbol_lock_person: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M753-249q27.917 0 47.458-19.559Q820-288.118 820-316.059T800.458-363Q780.917-382 753-382q-27.5 0-46.75 19.353t-19.25 47Q687-288 706.25-268.5T753-249Zm-.214 133Q786-116 815-131.5t47-42.5q-26-14-53-22.5t-56-8.5q-29 0-56 8.5T644-174q18 27 46.786 42.5 28.785 15.5 62 15.5ZM350-634h260v-93q0-57-37.5-95T480-860q-55 0-92.5 38T350-727v93ZM220-80q-23 0-41.5-18.5T160-140v-434q0-23 18.5-41.5T220-634h70v-93q0-81 55-137t135-56q80 0 135 56t55 137v93h70q23 0 41.5 18.5T800-574v49q0 14.5-9.318 21.75t-20.5 7.25Q759-496 749.5-503.5 740-511 740-526v-48H220v434h298q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q548-97 539.375-88.5T518-80H220Zm533 34q-79 0-136-57t-57-136q0-79 57-136t136-57q79 0 136 57t57 136q0 79-57 136T753-46ZM220-574v434-434Z"
		/>
	</svg>
);
