import { Fragment, useContext, useState } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import { MixedStateSetter } from 'pkg/hooks/useMixedState';
import { useCurrentGroup } from 'pkg/identity';
import { formatToCurrency } from 'pkg/i18n/formatters';
import { formatPriceIntervalToText } from 'pkg/utils';
import * as models from 'pkg/api/models';
import { cssClasses } from 'pkg/css/utils';

import {
	FormEngineContext,
	SelectedProductInfo,
} from 'routes/forms/hooks/useFormEngine';
import { PriceOptionState } from 'routes/forms/hooks/modals/price_option';
import CreatePriceModal from 'routes/payments/prices/modals/new';

import Icon from 'components/icon';
import Label from 'components/label';
import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import * as CardList from 'components/card-list';

import Row from 'components/layout/row';
import Column from 'components/layout/column';

import * as Context from 'design/context_menu';
import Button from 'design/button';

import * as css from './styles.css';

type Modals = 'createPriceModal' | '';

interface ProductInfoProps {
	conflictingPriceCycles: boolean;
	showActions?: boolean;

	productInfo: SelectedProductInfo;
	priceOptionState: PriceOptionState;
	setPriceOptionState: MixedStateSetter<PriceOptionState>;
}

export default function ProductInfo({
	showActions = true,
	conflictingPriceCycles = false,
	productInfo,
	priceOptionState,
	setPriceOptionState,
}: ProductInfoProps) {
	const [modal, setModal] = useState<Modals>('');
	const group = useCurrentGroup();

	const formEngineContext = useContext(FormEngineContext);

	const showPriceModal = () => setModal('createPriceModal');
	const hideModal = () => setModal('');

	const selectedInterval = priceOptionState.productPrices.find(
		(p) => p.recurring
	)?.recurringInterval;

	const selectedProductPrice = priceOptionState.productPrices.find(
		(pp) => pp.productId === productInfo.product?.id
	);

	const cost = formatToCurrency(
		selectedProductPrice?.cost / 100,
		group.currency
	);
	let selectedProductPriceCostText = cost;

	const intervalToText = formatPriceIntervalToText(
		selectedProductPrice?.recurringInterval,
		selectedProductPrice?.recurringIntervalCount
	);

	if (selectedProductPrice?.recurring) {
		selectedProductPriceCostText = `${cost} / ${intervalToText}`;
	}

	const recurringPrices = models.product.getActiveRecurringPrices(
		productInfo.product
	);
	const oneTimePrices = models.product.getActiveOneTimePrices(
		productInfo.product
	);

	const handlePriceSelect = (
		productPrice: models.productPrice.ProductPrice
	) => {
		const selectedProductPrices = [...priceOptionState.productPrices];
		const productPriceIndex = selectedProductPrices.findIndex(
			(price) => price.productId === productPrice.productId
		);

		if (productPriceIndex !== -1) {
			selectedProductPrices[productPriceIndex] = productPrice;
		} else {
			selectedProductPrices.push(productPrice);
		}

		setPriceOptionState({
			productPrices: selectedProductPrices,
		});
	};

	const handleRemoveSelectedPrice = () => {
		const selectedProductPrices = [...priceOptionState.productPrices];
		const productPriceIndex = selectedProductPrices.findIndex(
			(price) => price.productId === selectedProductPrice.productId
		);

		selectedProductPrices.splice(productPriceIndex, 1);
		setPriceOptionState({
			productPrices: selectedProductPrices,
		});
	};

	const updateProductPrice = (
		productPrice: models.productPrice.ProductPrice
	) => {
		productInfo.product.prices.push(productPrice);

		handlePriceSelect(productPrice);
	};

	let showOneTimePrices = false;

	if (
		(priceOptionState.type === 'direct' && oneTimePrices?.length > 0) ||
		(priceOptionState.type !== 'direct' &&
			oneTimePrices?.length > 0 &&
			formEngineContext.formState.selectedProductsInfo.length > 1)
	) {
		showOneTimePrices = true;
	}

	let content = (
		<Context.Menu
			width="500px"
			toggleWith={<Button secondary label={t('Select price')} icon="add" />}>
			<Context.Item tight onClick={showPriceModal}>
				<div className={css.newPrice}>
					<Icon name="add" />
					{t('Add a new price')}
				</div>
			</Context.Item>
			{showOneTimePrices && (
				<Fragment>
					<Context.Label>{t('One-time prices')}</Context.Label>
					{oneTimePrices?.map((productPrice, index) => {
						const cost = formatToCurrency(
							productPrice.cost / 100,
							group.currency
						);

						return (
							<Context.Item
								key={index}
								justifyContent="stretch"
								onClick={() => handlePriceSelect(productPrice)}>
								<div className={css.priceRow}>
									<div className={css.cost}>{cost}</div>
									<div className={css.priceTitle}>{productPrice.title}</div>
								</div>
							</Context.Item>
						);
					})}
				</Fragment>
			)}

			{recurringPrices?.length > 0 && priceOptionState.type !== 'direct' && (
				<Fragment>
					<Context.Label>
						<span>
							<Icon name="sync" rotate="90deg" /> {t('Recurring prices')}
						</span>
					</Context.Label>
					{recurringPrices?.map((productPrice, index) => {
						const cost = formatToCurrency(
							productPrice.cost / 100,
							group.currency
						);

						return (
							<Context.Item
								key={index}
								justifyContent="stretch"
								onClick={() => handlePriceSelect(productPrice)}>
								<div className={css.priceRow}>
									<div className={css.cost}>
										{`${cost} / ${formatPriceIntervalToText(
											productPrice.recurringInterval,
											productPrice.recurringIntervalCount
										)}`}
										{selectedInterval &&
											productPrice.recurring &&
											selectedInterval !== productPrice?.recurringInterval && (
												<Icon name="error" fill={styles.palette.red[500]} />
											)}
									</div>
									<div className={css.priceTitle}>{productPrice.title}</div>
								</div>
							</Context.Item>
						);
					})}
				</Fragment>
			)}
		</Context.Menu>
	);

	if (selectedProductPrice) {
		content = (
			<Row autoColumns="max-content" align="center">
				<div className={css.selectedPriceWrapper}>
					<LargeScreen>
						<span className={css.selectedPriceTitle}>
							{selectedProductPrice.title}
						</span>
					</LargeScreen>
					<div
						className={cssClasses(
							css.selectedPriceCost,
							conflictingPriceCycles ? css.hasConflict : ''
						)}>
						{conflictingPriceCycles && (
							<Icon name="error" fill={styles.palette.red[500]} />
						)}
						<LargeScreen>
							{selectedProductPrice?.recurring && (
								<Icon name="sync" rotate="90deg" />
							)}
							{selectedProductPriceCostText}
						</LargeScreen>
						<SmallScreen>
							<div className={css.intervalWrapper}>
								<span>
									{selectedProductPrice?.recurring && (
										<Icon name="sync" rotate="90deg" />
									)}{' '}
									{cost}
								</span>
								<span className={css.interval}>{`/${intervalToText}`}</span>
							</div>
						</SmallScreen>
					</div>
				</div>
				{showActions && (
					<Button icon="close" secondary onClick={handleRemoveSelectedPrice} />
				)}
			</Row>
		);
	}

	return (
		<Column>
			<Column>
				<CardList.Base>
					<CardList.IconWrapper>
						<Icon name="tag" />
					</CardList.IconWrapper>
					<CardList.Title>
						<Row autoColumns="auto" align="center">
							{productInfo.product.name}
							{selectedProductPrice && !selectedProductPrice?.recurring && (
								<Label color="green">{t('One-time')}</Label>
							)}
						</Row>
					</CardList.Title>
					<CardList.Actions>{content}</CardList.Actions>
				</CardList.Base>
			</Column>

			{modal === 'createPriceModal' && (
				<CreatePriceModal
					productId={productInfo.product.id}
					hideModal={hideModal}
					setPrice={updateProductPrice}
					forceOneTimePrice={priceOptionState.type === 'direct'}
				/>
			)}
		</Column>
	);
}
