import { ReactNode } from 'react';

export const symbol_book_5: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M270-80q-45 0-77.5-29.637T160-183v-564q0-36.566 22.118-65.434Q204.235-841.302 240-849l308-64q28-6 50 12.14 22 18.139 22 46.86v497q0 21.162-13 37.581Q594-303 573-298l-313 67q-17.143 3.692-28.571 17.538Q220-199.615 220-183q0 19 15 31t35 12h470v-630q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T800-770v630q0 24.75-17.625 42.375T740-80H270Zm70-228 220-47v-499l-220 45v501Zm-60 12.809V-796l-20 4q-17 4-28.5 15.827T220-747v471q9.062-5.2 19.031-9.1Q249-289 260-291l20-4.191ZM220-792v516-516Z"
		/>
	</svg>
);
