import { ReactNode } from 'react';

export const symbol_strategy: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M205-529 95-591q-6.75-3.714-10.875-10.771Q80-608.829 80-617v-126q0-8.171 4.125-15.229Q88.25-765.286 95-769l110-62q6.677-4 14.839-4Q228-835 235-831l110 62q6.75 3.714 10.875 10.771Q360-751.171 360-743v126q0 8.171-4.125 15.229Q351.75-594.714 345-591l-110 62q-6.677 4-14.839 4Q212-525 205-529Zm15-60 80-46v-90l-80-46-80 46v90l80 46Zm430 71v-69l120 70q14.25 8.13 22.125 21.804Q800-481.522 800-466v212q0 15.522-7.875 29.196Q784.25-211.13 770-203L590-98q-14.328 8-30.164 8Q544-90 530-98L350-203q-14.25-8.13-22.125-21.804Q320-238.478 320-254v-212q0-15.522 7.875-29.196Q335.75-508.87 350-517l120-70v69l-90 53v210.747L560-150l180-104.253V-465l-90-53Zm-60-142v210q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T530-450v-400q0-12.75 8.625-21.375T560-880h265q17.5 0 26.25 16T850-833l-31 46q-5 7.647-5 16.823Q814-761 819-753l31 46q10 15 1.25 31T825-660H590Zm-30 326ZM220-680Z"
		/>
	</svg>
);
