import { t } from '@transifex/native';
import { CSSProperties, Fragment, useState, MouseEvent, useRef } from 'react';
import styled from 'styled-components';

import * as styles from 'pkg/config/styles';

import {
	ImageEditorFormat,
	SaveableImage,
	useImageEditor,
} from 'pkg/hooks/image-editor';
import rgba from 'pkg/rgba';

import { useOnboardingContext } from 'routes/public/onboarding';

import Icon from 'components/icon';
import StepModal, { Step } from 'components/step-modal';
import Avatar from 'components/avatar';

import Row from 'components/layout/row';
import FileInput from 'components/form/File';
import RangeSlider from 'components/form/RangeSlider';
import Column from 'components/layout/column';

const AvatarEditorWrapper = styled.div`
	display: flex;
	justify-content: center;

	svg[role='img'] {
		color: var(--palette-gray-400);
		height: 20px;
		width: 20px;
	}
`;

const ThumbnailSelector = styled(FileInput)`
	cursor: pointer;
`;

const AvatarWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin-bottom: var(--spacing-3);
`;

const AvatarImageWrapper = styled.div`
	position: relative;
	height: 80px;
	width: 80px;
	border-radius: 40px;
	transition: box-shadow 120ms ease-in-out;

	@media (hover: hover) {
		&:hover {
			box-shadow: ${rgba(styles.palette.white, 0.5)} 0 0 0 4px;
		}
	}

	span {
		position: absolute;
		top: -5px;
		right: -5px;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 30px;
		height: 30px;
		border-radius: 15px;
		background: var(--brandedButtonColor, ${styles.palette.brand});
		color: var(--brandedButtonTextColor, var(--palette-gray-900));

		svg {
			width: 20px;
			height: 20px;
		}
	}
`;

export const AvatarIconWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background: var(--brandedButtonColor, ${styles.palette.brand});
	color: var(--brandedButtonTextColor, var(--palette-gray-900));
	height: 80px;
	width: 80px;
	border-radius: 40px;
	cursor: pointer;
	transition: box-shadow 120ms ease-in-out;

	@media (hover: hover) {
		&:hover {
			box-shadow: ${rgba(styles.palette.white, 0.5)} 0 0 0 4px;
		}
	}

	svg {
		width: 40px;
		height: 40px;
	}
`;

interface AvatarEditorProps {
	avatar?: string;
	onSave: (url: string) => void;
}

export default function AvatarEditor({
	avatar,
	onSave,
}: AvatarEditorProps): JSX.Element {
	const { brandHsl } = useOnboardingContext();

	const [scale, setScale] = useState<number>(1);
	const [showModal, setShowModal] = useState<boolean>(false);

	const fileInputRef = useRef<HTMLInputElement>(null);

	const closeModal = () => {
		setShowModal(false);
	};

	const avatarEditor = useImageEditor({
		width: 320,
		height: 320,
		saveFormat: ImageEditorFormat.Base64,
		gutterRoundness: 160,
		onSave: async (image: SaveableImage) => {
			onSave(image as string);
		},
	});

	const handleImageSelect = async (files: File[]) => {
		await avatarEditor.load(files[0]);

		setShowModal(true);
	};

	const handleScaleChange = (scale: number) => {
		setScale(scale);

		avatarEditor.scale(scale);
	};

	const handleRemoveThumbnail = async (event: MouseEvent<HTMLSpanElement>) => {
		event.preventDefault();

		await onSave(null);

		fileInputRef.current.value = null;
	};

	const handleSaveAvatar = async () => {
		await avatarEditor.save();
	};

	const [hue, sat, lum] = brandHsl;

	const brandable = hue && sat !== '0%' && lum !== '0%' && lum !== '100%';

	const brandedButtonColor = brandable
		? `hsl(${hue}, 55%, 45%)`
		: styles.palette.brand;

	const brandedButtonTextColor = brandable
		? `hsl(${hue}, 55%, 100%)`
		: styles.palette.gray[900];

	const brandStyles = {
		'--brandedButtonColor': brandedButtonColor,
		'--brandedButtonTextColor': brandedButtonTextColor,
	} as CSSProperties;

	return (
		<Fragment>
			<ThumbnailSelector
				accept="image/*"
				allowDrop
				onChange={handleImageSelect}
				inputRef={fileInputRef}>
				<AvatarWrapper>
					{avatar ? (
						<AvatarImageWrapper style={brandStyles}>
							<Avatar customImageUrl={avatar} size={80} isEditable />
							<span onClick={handleRemoveThumbnail}>
								<Icon name="close" />
							</span>
						</AvatarImageWrapper>
					) : (
						<AvatarIconWrapper style={brandStyles}>
							<Icon name="avatar-upload" />
						</AvatarIconWrapper>
					)}
				</AvatarWrapper>
			</ThumbnailSelector>
			{showModal && (
				<StepModal thin onClose={closeModal}>
					<Step
						title={t('Profile picture')}
						hidePrev
						spaciousBody
						onNext={handleSaveAvatar}
						nextLabel={t('Save')}>
						<AvatarEditorWrapper>
							<Column>
								{avatarEditor.canvas}
								<Row
									columns="auto 1fr auto"
									spacing={styles.spacing._6}
									align="center"
									style={{ width: '100%', maxWidth: '320px' }}>
									<Icon name="magnify-minus" />
									<RangeSlider
										min={0.1}
										max={5}
										step={0.01}
										value={scale}
										onChange={handleScaleChange}
									/>
									<Icon name="magnify-plus" />
								</Row>
							</Column>
						</AvatarEditorWrapper>
					</Step>
				</StepModal>
			)}
		</Fragment>
	);
}
