import { ReactNode } from 'react';

export const symbol_sports_bar: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M310-180h300v-430h-90q-28 0-47 15.5T430-552q-26 29-57 51t-63 30v291Zm-60 0v-292q-52-14-91-53t-39-95.178q0-53.168 30.5-94.298t78.5-57.181q23-48.153 68.5-78.248Q343-880 400-880q35 0 65.5 12t55.5 33q10-2 19-3.5t20-1.5q66 0 113 47t47 113q0 22-9 44t-23 36h92q24.75 0 42.375 17.625T840-540v280q0 24.75-17.625 42.375T780-200H670v20q0 24.75-17.625 42.375T610-120H310q-24.75 0-42.375-17.625T250-180Zm-70-440q0 38 31 64t69 26q32 0 58-17.5t53-50.5q23-28 55-50t74-22h140q0-45-26.5-77.5T560-780q-18 0-35.5 5.5L507-769l-19-16q-18-16-41-25.5t-47-9.5q-35 0-67 18t-47 50l-14 30-32 11q-26 9-43 36t-17 55Zm490 360h110v-280H670v280Zm-360 80h300-300Z"
		/>
	</svg>
);
