import { t } from '@transifex/native';
import { useRef, useState } from 'react';

import { CreateIndentityProviderPayload } from 'pkg/actions/identity_providers';

import * as actions from 'pkg/actions';
import { IdentityUserTrait } from 'pkg/api/models/identity_provider';

import * as Card from 'components/Card';
import Modal from 'components/modal';

import Form, { FormPayload, asString, submitForm } from 'components/form/Form';
import { getSelectedUserTraits } from 'components/identity-provider/role-select-group';
import CreateIdentityProviderForm from 'components/identity-provider/create-form';

import Button, { ButtonGroup } from 'design/button';

interface CreateIdentityProviderModalProps {
	organizationId: number;
	availableUserTraits: IdentityUserTrait[];
	refresh: () => void;
	hideModal: () => void;
}

export default function CreateIdentityProviderModal({
	organizationId,
	availableUserTraits,
	hideModal,
	refresh,
}: CreateIdentityProviderModalProps) {
	const [saving, setSaving] = useState(false);
	const formRef = useRef(null);

	const handleSubmit = async (data: FormPayload) => {
		setSaving(true);
		const requiredForIdentityUserTraits = getSelectedUserTraits(data);

		const payload: CreateIndentityProviderPayload = {
			displayName: asString(data.displayName),
			requiredForIdentityUserTraits,
		};

		const [ok] = await actions.identityProviders.create(
			organizationId,
			payload
		);

		if (ok) {
			refresh();
			hideModal();
		}

		setSaving(false);
		return false;
	};

	const handleSubmitClick = () => submitForm(formRef);

	return (
		<Form formRef={formRef} onSubmit={handleSubmit}>
			<Modal onClose={hideModal}>
				<Card.Base>
					<Card.Header>
						<Card.Heading>{t('New identity provider')}</Card.Heading>
					</Card.Header>
					<Card.Divider />
					<Card.Body>
						<CreateIdentityProviderForm
							availableUserTraits={availableUserTraits}
						/>
					</Card.Body>
					<Card.Divider />
					<Card.Footer>
						<ButtonGroup>
							<Button
								label={t('Cancel')}
								onClick={hideModal}
								disabled={saving}
							/>
							<Button
								label={t('Save')}
								primary
								onClick={handleSubmitClick}
								busy={saving}
							/>
						</ButtonGroup>
					</Card.Footer>
				</Card.Base>
			</Modal>
		</Form>
	);
}
