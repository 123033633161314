export const baseSegmentWidth: number = 120;

export const getSegmentWidth = (zoom: number = 1): number =>
	baseSegmentWidth * zoom;

export enum Precision {
	TenMinutes,
	FiveMinutes,
	OneMinute,
	ThirtySeconds,
	FortyFiveSeconds,
	TenSeconds,
	FiveSeconds,
}

export const PrecisionMinIndex: number = 0;
export const PrecisionMaxIndex: number = 6;

export const multiplier: { [key in Precision]: number } = {
	[Precision.TenMinutes]: 0.1,
	[Precision.FiveMinutes]: 0.2,
	[Precision.OneMinute]: 1,
	[Precision.ThirtySeconds]: 2,
	[Precision.FortyFiveSeconds]: 4,
	[Precision.TenSeconds]: 6,
	[Precision.FiveSeconds]: 12,
};

export const granularity: { [key in Precision]: number } = {
	[Precision.TenMinutes]: 60 * 10,
	[Precision.FiveMinutes]: 60 * 5,
	[Precision.OneMinute]: 60,
	[Precision.ThirtySeconds]: 30,
	[Precision.FortyFiveSeconds]: 15,
	[Precision.TenSeconds]: 10,
	[Precision.FiveSeconds]: 5,
};
