import React, { useEffect, useState } from 'react';
import { Map, List } from 'immutable';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import User from 'pkg/models/user';
import Rating from 'pkg/models/rating';
import Video from 'pkg/models/video';
import Match from 'pkg/models/match';
import { MembershipRole } from 'pkg/models/membership';
import { Features } from 'pkg/models/group';

import * as actions from 'pkg/actions';
import * as selectors from 'pkg/selectors';
import { RootState } from 'pkg/reducers';
import * as routes from 'pkg/router/routes';
import { useCheckRelationToUser, useGroup } from 'pkg/hooks/selectors';
import {
	useCurrentMembership,
	useCurrentOrganization,
	useCurrentSport,
} from 'pkg/identity';
import * as models from 'pkg/api/models';

import SeasonStatistics from 'containers/user/profile/statistics/Season';
import LatestMatch from 'containers/user/profile/statistics/LatestMatch';

import SectionTitle from 'components/SectionTitle';

import Column from 'components/layout/column';
import { Spinner } from 'components/loaders/spinner';
import About from 'components/user/profile/About';
import Videos from 'components/user/profile/Videos';
import PhysicalStrain from 'components/user/profile/statistics/PhysicalStrain';
import PositionMatch from 'components/user/profile/development/PositionMatch';
import PositionProfile from 'components/user/profile/PositionProfile';
import EstimatedHeight from 'components/user/profile/EstimatedHeight';

import Button from 'design/button';

const Cols = styled.div<{ columns: number }>`
	display: grid;
	grid-template-columns: repeat(${(props) => props.columns}, 1fr);
	grid-gap: var(--spacing-8);

	@media ${styles.breakpoint.small} {
		grid-template-rows: 1fr 1fr;
		grid-template-columns: 1fr;
		grid-gap: var(--spacing-5);
	}
`;
type PlayerOverviewProps = {
	groupId: number;
	user: User;
};

const PlayerOverview: React.FC<
	React.PropsWithChildren<PlayerOverviewProps>
> = ({ groupId, user }) => {
	const dispatch = useDispatch();
	const org = useCurrentOrganization();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [latestMatchId, setLatestMatchId] = useState<number | Promise<number>>(
		0
	);

	const userId = user.id;
	const group = useGroup(groupId);

	const accountUserIds = useSelector((state: RootState) =>
		selectors.app.activeAccountUserIds(state)
	);
	const activeMembership = useCurrentMembership();

	const groupUsers: Map<number, User> = useSelector((state: RootState) =>
		selectors.users.findAllByGroup(state, groupId, MembershipRole.User)
	);

	const rating: Rating = useSelector((state: RootState) =>
		selectors.ratings.getUserPrimaryRating(state, { groupId, userId })
	);

	const videos: List<Video> = useSelector((state: RootState) =>
		selectors.userVideos.findTaggedVideos(state)
	);

	const latestMatch: Match = useSelector((state: RootState) =>
		selectors.matches.getMatchById(state, { matchId: latestMatchId })
	);

	const groupSport = useCurrentSport();

	const { isParentToUser, isSelfOrParentToUserOrAdmin } =
		useCheckRelationToUser(userId);
	const forAccount = isParentToUser ? user.accountId : null;

	const canViewTaggedVideos =
		accountUserIds.includes(userId) ||
		!models.membership.isPlayer(activeMembership);

	useEffect((): void => {
		const fetchData = async (): Promise<void> => {
			setIsLoading(true);

			const forAccount = isParentToUser ? user.accountId : null;

			if (canViewTaggedVideos) {
				dispatch(
					actions.userVideos.fetchTaggedVideos(
						userId,
						groupId,
						null,
						forAccount
					)
				);
			}

			const [, matchId] = await Promise.all([
				dispatch(
					actions.ratings.fetchUsersPrimaryRating(userId, groupId, forAccount)
				),
				dispatch(
					actions.deprecatedMatchActions.fetchUserLatestMatch(userId, groupId)
				),
			]);

			setLatestMatchId(matchId);
			setIsLoading(false);
		};

		fetchData();
	}, [groupId, userId, dispatch, canViewTaggedVideos]);

	if (isLoading) {
		return <Spinner />;
	}

	return (
		<Column spacing={styles.spacing._7}>
			<Column>
				<SectionTitle>{t('About')}</SectionTitle>
				<About user={user} rating={rating} />
			</Column>

			{isSelfOrParentToUserOrAdmin && group.hasFeature(Features.Video) && (
				<Column>
					<SectionTitle>
						{t('Videos tagged in')}
						<Button
							inline
							noPadding
							icon="chevron_right"
							iconPosition="right"
							href={routes.User.Profile.Show(
								org.id,
								groupId,
								userId,
								'videos'
							)}>
							{t('View all')}
						</Button>
					</SectionTitle>
					<Videos videos={List(videos)} />
				</Column>
			)}

			<Column>
				<SectionTitle>
					{t('Statistics')}
					{isSelfOrParentToUserOrAdmin && (
						<Button
							inline
							noPadding
							icon="chevron_right"
							iconPosition="right"
							href={routes.User.Profile.Show(org.id, groupId, userId, 'stats')}>
							{t('View all')}
						</Button>
					)}
				</SectionTitle>
				<SeasonStatistics
					user={user}
					groupId={groupId}
					isSelfOrParentToUserOrAdmin={isSelfOrParentToUserOrAdmin}
				/>
			</Column>
			<Column>
				<PhysicalStrain
					user={user}
					users={groupUsers.toList()}
					groupId={groupId}
					isSelfOrParentToUserOrAdmin={isSelfOrParentToUserOrAdmin}
				/>
			</Column>
			{models.sport.hasMatchesEnabled(groupSport) &&
				group.hasFeature(Features.Matches) && (
					<Column>
						<SectionTitle>
							{t('Last match played')}
							{isSelfOrParentToUserOrAdmin && !!latestMatch.id && (
								<Button
									inline
									noPadding
									icon="chevron_right"
									iconPosition="right"
									href={routes.Match.View(
										org.id,
										latestMatch.eventId,
										latestMatch.id,
										'summary'
									)}>
									{t('View match')}
								</Button>
							)}
						</SectionTitle>
						<LatestMatch match={latestMatch} />
					</Column>
				)}
			{models.sport.hasRatingsEnabled(groupSport) &&
				group.hasFeature(Features.Assessments) && (
					<Column>
						<SectionTitle>
							{t('Position')}

							{isSelfOrParentToUserOrAdmin && !!rating?.id && (
								<Button
									inline
									noPadding
									icon="chevron_right"
									iconPosition="right"
									href={routes.User.Profile.PositionMatch(
										org.id,
										groupId,
										userId,
										rating.id
									)}>
									{t('View details')}
								</Button>
							)}
						</SectionTitle>
						<PositionMatch
							userId={user.id}
							rating={rating}
							isSelfOrParentToUserOrAdmin={isSelfOrParentToUserOrAdmin}
							forAccount={forAccount}
						/>
					</Column>
				)}
			<Cols columns={isSelfOrParentToUserOrAdmin ? 2 : 1}>
				{models.sport.hasRatingsEnabled(groupSport) &&
					group.hasFeature(Features.Assessments) && (
						<Column>
							<SectionTitle>{t('Profile position')}</SectionTitle>
							<PositionProfile rating={rating} />
						</Column>
					)}

				{isSelfOrParentToUserOrAdmin && (
					<Column>
						<SectionTitle>{t('Estimated height')}</SectionTitle>
						<EstimatedHeight
							user={user}
							isSelf={activeMembership.userId === user.id}
						/>
					</Column>
				)}
			</Cols>
		</Column>
	);
};

export default PlayerOverview;
