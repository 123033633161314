// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Rqs_9XaYxIZ0iunVd81P {
	color: var(--palette-gray-600);
	width: 5em;
	height: 4em;
}

.wooE7FwNJrAlqFKOdSNA {
	margin: var(--spacing-6) 0;
}

.oZ_uaXSqFFuge3Eg4TOz {
	font-size: var(--font-size-xl);
	font-weight: var(--font-weight-semibold);
	text-align: center;
}

._LfTAU9OyAKU6jTz6WVw {
	font-size: var(--font-size-l);
	font-weight: var(--font-weight-bold);
}

.ioLBrMtaDc31TX6Im3yH {
	font-size: var(--font-size-base);
	line-height: var(--font-line-height-base);
	text-align: center;
	margin-bottom: var(--spacing-4);
}

._so_n_KHMPzaU8dcHYR3 {
	color: var(--palette-red-600);
	font-weight: var(--font-weight-thin);
	font-size: var(--font-size-sm);
}

.TVO7Bu52WC6JrfeGICN5 {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
}

._80rSknijdVqFX5uJZba {
	font-weight: var(--font-weight-semibold);
	font-size: var(--font-size-sm);
}

.R9NzCToYyQa54IwkuIpw {
	font-size: var(--font-size-sm);
}

.M2PZInLis5a99SuGUrTq {
	padding: var(--spacing-4);
	font-size: var(--font-size-l);
}

.M2PZInLis5a99SuGUrTq.VGKxpZ9X3sKFZ4gaUjTZ {
	font-size: var(--font-size-l);
	background-color: var(--palette-gray-100);
	font-weight: var(--font-weight-semibold);
}
`, "",{"version":3,"sources":["webpack://./containers/group/settings/Fogis/styles.css"],"names":[],"mappings":"AAAA;CACC,8BAA8B;CAC9B,UAAU;CACV,WAAW;AACZ;;AAEA;CACC,0BAA0B;AAC3B;;AAEA;CACC,8BAA8B;CAC9B,wCAAwC;CACxC,kBAAkB;AACnB;;AAEA;CACC,6BAA6B;CAC7B,oCAAoC;AACrC;;AAEA;CACC,gCAAgC;CAChC,yCAAyC;CACzC,kBAAkB;CAClB,+BAA+B;AAChC;;AAEA;CACC,6BAA6B;CAC7B,oCAAoC;CACpC,8BAA8B;AAC/B;;AAEA;CACC,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;AACpB;;AAEA;CACC,wCAAwC;CACxC,8BAA8B;AAC/B;;AAEA;CACC,8BAA8B;AAC/B;;AAEA;CACC,yBAAyB;CACzB,6BAA6B;AAC9B;;AAEA;CACC,6BAA6B;CAC7B,yCAAyC;CACzC,wCAAwC;AACzC","sourcesContent":[".gray {\n\tcolor: var(--palette-gray-600);\n\twidth: 5em;\n\theight: 4em;\n}\n\n.emptyState {\n\tmargin: var(--spacing-6) 0;\n}\n\n.confirmTitle {\n\tfont-size: var(--font-size-xl);\n\tfont-weight: var(--font-weight-semibold);\n\ttext-align: center;\n}\n\n.competitionTitle {\n\tfont-size: var(--font-size-l);\n\tfont-weight: var(--font-weight-bold);\n}\n\n.confirmDescription {\n\tfont-size: var(--font-size-base);\n\tline-height: var(--font-line-height-base);\n\ttext-align: center;\n\tmargin-bottom: var(--spacing-4);\n}\n\n.invalid {\n\tcolor: var(--palette-red-600);\n\tfont-weight: var(--font-weight-thin);\n\tfont-size: var(--font-size-sm);\n}\n\n.connectedContent {\n\talign-items: center;\n}\n\n.connectedTitle {\n\tfont-weight: var(--font-weight-semibold);\n\tfont-size: var(--font-size-sm);\n}\n\n.connectedUrl {\n\tfont-size: var(--font-size-sm);\n}\n\n.wrapper {\n\tpadding: var(--spacing-4);\n\tfont-size: var(--font-size-l);\n}\n\n.wrapper.header {\n\tfont-size: var(--font-size-l);\n\tbackground-color: var(--palette-gray-100);\n\tfont-weight: var(--font-weight-semibold);\n}\n"],"sourceRoot":""}]);
// Exports
export var gray = `Rqs_9XaYxIZ0iunVd81P`;
export var emptyState = `wooE7FwNJrAlqFKOdSNA`;
export var confirmTitle = `oZ_uaXSqFFuge3Eg4TOz`;
export var competitionTitle = `_LfTAU9OyAKU6jTz6WVw`;
export var confirmDescription = `ioLBrMtaDc31TX6Im3yH`;
export var invalid = `_so_n_KHMPzaU8dcHYR3`;
export var connectedContent = `TVO7Bu52WC6JrfeGICN5`;
export var connectedTitle = `_80rSknijdVqFX5uJZba`;
export var connectedUrl = `R9NzCToYyQa54IwkuIpw`;
export var wrapper = `M2PZInLis5a99SuGUrTq`;
export var header = `VGKxpZ9X3sKFZ4gaUjTZ`;
export default ___CSS_LOADER_EXPORT___;
