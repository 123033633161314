import { ReactNode } from 'react';

export const symbol_person_2: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M369-480q-27.429 0-45.714-20.5Q305-521 309-548l16-120q8-57 52-94.5T480-800q59 0 103 37.5t52 94.5l16 120q4 27-14.286 47.5Q618.429-480 591-480H369Zm-1-60h224l-16.059-119.226Q571-694 543.5-717 516-740 480-740t-63.447 22.781Q389.106-694.438 384-659l-16 119Zm112 0ZM160-220v-34q0-38 19-65t49-41q67-30 128.5-45T480-420q62 0 123 15.5t127.921 44.694q31.301 14.126 50.19 40.966Q800-292 800-254v34q0 24.75-17.625 42.375T740-160H220q-24.75 0-42.375-17.625T160-220Zm60 0h520v-34q0-16-9.5-30.5T707-306q-64-31-117-42.5T480-360q-57 0-111 11.5T252-306q-14 7-23 21.5t-9 30.5v34Zm260 0Z"
		/>
	</svg>
);
