import { ReactNode } from 'react';

export const symbol_eyeglasses: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M268-340q35 0 63.5-21.5T373-418l15-44q21-59-6-108.5T302-620H139l21 183q5 42 35.5 69.5T268-340Zm424 0q42 0 72.5-28t35.5-70l21-182H659q-53 0-80 49.5T572-461l15 43q12 35 41 56.5t64 21.5Zm-423 60q-65 0-112.5-43T101-432L78-620h-8q-13 0-21.5-8.5T40-650q0-13 8.5-21.5T70-680h229q40 0 76.5 22.5T438-594h85q26-41 62.5-63.5T662-680h228q13 0 21.5 8.5T920-650q0 13-8.5 21.5T890-620h-8l-23 188q-8 66-55.5 109T691-280q-53 0-97-32t-64-86l-15-41q-3-9-5.5-20t-4.5-25h-50q-2 13-4.5 23.5T445-441l-15 42q-20 54-64 86.5T269-280Z"
		/>
	</svg>
);
