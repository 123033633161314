import { forAccountHeaders } from 'pkg/actions/utils';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as sdk from 'pkg/core/sdk';

export const fetchRatingItem = (forAccount) => {
	const headers = forAccountHeaders(forAccount);

	return sdk.get(endpoints.RatingItems.Index(), {}, {}, headers);
};
