import { t } from '@transifex/native';
import { useContext } from 'react';

import { AddProductContext } from 'routes/payments/products/create';
import ProductInfo from 'routes/payments/products/form/preview';

import StepModal, { Step } from 'components/step-modal';

interface ProductPreviewProps {
	onClose: () => void;
}

const ProductPreview = ({ onClose }: ProductPreviewProps): JSX.Element => {
	const addProductContext = useContext(AddProductContext);

	return (
		<StepModal onClose={onClose}>
			<Step title={t(`Product preview`)} prevLabel={t('Close')} hideNext>
				<ProductInfo product={addProductContext.formState} />
			</Step>
		</StepModal>
	);
};

export default ProductPreview;
