import { ReactNode } from 'react';

export const symbol_align_start: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M110-820q-12.75 0-21.375-8.675Q80-837.351 80-850.175 80-863 88.625-871.5T110-880h740q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-820H110Zm210 250q-12.75 0-21.375-8.625T290-600v-40q0-12.75 8.625-21.375T320-670h320q12.75 0 21.375 8.625T670-640v40q0 12.75-8.625 21.375T640-570H320Zm0 240q-12.75 0-21.375-8.625T290-360v-40q0-12.75 8.625-21.375T320-430h320q12.75 0 21.375 8.625T670-400v40q0 12.75-8.625 21.375T640-330H320Z"
		/>
	</svg>
);
