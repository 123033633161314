import { ReactNode } from 'react';

export const symbol_view_comfy: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M80-220v-520q0-24.75 17.625-42.375T140-800h680q24.75 0 42.375 17.625T880-740v520q0 24.75-17.625 42.375T820-160H140q-24.75 0-42.375-17.625T80-220Zm740-290v-230H140v230h680ZM383-220h437v-230H383v230Zm-243 0h183v-230H140v230Z"
		/>
	</svg>
);
