import { Fragment, memo, useState } from 'react';
import styled, { css } from 'styled-components';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';

import UserContainer from 'containers/chat/UserContainer';
import LastReadUsersModal from 'containers/chat/modals/LastReadUsersModal';

import Avatar from 'components/avatar';

export const ReplyAvatars = styled.div<{
	singleAvatar: boolean;
	isSystemReply: boolean;
}>`
	position: relative;
	display: flex;
	justify-content: flex-end;
	position: relative;
	margin: var(--spacing-2) 0 var(--spacing-3);

	${Avatar} {
		width: 16px;
		height: 16px;
		font-size: var(--font-size-xs);
		box-shadow: 0 0 0 1px var(--palette-white);
	}

	${({ singleAvatar }) =>
		!singleAvatar &&
		css`
			${Avatar} {
				margin-left: 0;
				margin-right: -0.1rem;

				&:last-child {
					margin-right: 0;
				}
			}
		`};

	${({ isSystemReply }) =>
		isSystemReply &&
		css`
			padding: 0 25px;
		`};
`;

const ShowMore = styled.div`
	display: flex;
	align-items: center;
	margin-left: 6px;
	font-size: var(--font-size-xs);
	color: var(--palette-gray-500);
	font-weight: var(--font-weight-semibold);
`;

interface LastReadAvatarsProps {
	users: models.user.User[];
	isSystemReply?: boolean;
}

const LastReadAvatars = memo(
	({ users, isSystemReply }: LastReadAvatarsProps) => {
		const [showLastReadUsersModal, setShowLastReadUsersModal] = useState(false);

		const toggleLastReadUsersModal = () => {
			setShowLastReadUsersModal(!showLastReadUsersModal);
		};

		let renderedShowMore;

		const isSingleAvatar = users.length === 1;
		const truncatedUserIds = users.slice(0, 3);
		const showMore = users.length > 3;

		if (showMore) {
			const num = users.length - truncatedUserIds.length;

			renderedShowMore = <ShowMore>{t('+{num} more', { num })}</ShowMore>;
		}

		return (
			<Fragment>
				<ReplyAvatars
					singleAvatar={isSingleAvatar}
					onClick={toggleLastReadUsersModal}
					isSystemReply={isSystemReply}>
					{truncatedUserIds.map((user) => (
						<UserContainer key={user.id} item={Avatar} user={user} />
					))}
					{showMore && renderedShowMore}
				</ReplyAvatars>
				{showLastReadUsersModal && (
					<LastReadUsersModal
						users={users}
						onClose={toggleLastReadUsersModal}
					/>
				)}
			</Fragment>
		);
	}
);

export default LastReadAvatars;
