import { Fragment, useContext, useMemo, useRef, useState } from 'react';
import { t } from '@transifex/native';

import { EventTypes } from 'pkg/models/event';

import * as models from 'pkg/api/models';
import { ScheduleItemUpdatePayload } from 'pkg/api/models/schedule_item';
import { useCollection } from 'pkg/api/use_collection';
import * as endpoints from 'pkg/api/endpoints/auto';
import useMixedState from 'pkg/hooks/useMixedState';
import { useCurrentOrganization } from 'pkg/identity';

import ScheduleItemForm from 'routes/scheduling/templates/schedule_items/form';
import { ScheduleItemFormState } from 'routes/scheduling/templates/modals/CreateScheduleItem';
import {
	findScheduleItemConflicts,
	resourceConflictMap,
	timesAsNumbers,
} from 'routes/scheduling/utils';
import { TemplateContext } from 'routes/scheduling/templates/single';

import * as StepModal from 'components/step-modal';

import Form, { FormPayload } from 'components/form/Form';
import useSelectGroups from 'components/group/select_modal';
import useSelectResource, {
	ConflictItem,
	SelectableOptions,
} from 'components/resources/select_modal';
import { Spinner } from 'components/loaders/spinner';

import Button from 'design/button';

interface EditScheduleItemProps {
	hideModal: () => void;

	scheduleItem: models.scheduleItem.ScheduleItem;
	schedule: models.schedule.Schedule;
	scheduleItems: models.scheduleItem.ScheduleItem[];
}

const EditScheduleItem = ({
	scheduleItem,
	schedule,
	hideModal,
	scheduleItems,
}: EditScheduleItemProps) => {
	const [modal, setModal] = useState('');
	const templateContext = useContext(TemplateContext);

	const [formState, setFormState] = useMixedState<ScheduleItemFormState>({
		days: [scheduleItem.day],
		startsAt: scheduleItem.startsAt,
		endsAt: scheduleItem.endsAt,
	});

	const showSelectGroupsModal = () => setModal('selectGroup');
	const showSelectResourceModal = () => setModal('selectResource');
	const hideSelectmodal = () => setModal('');

	const organization = useCurrentOrganization();
	const { records: resources, isLoading: isLoadingResources } =
		useCollection<models.resource.Resource>(endpoints.Resource.Index(), {
			queryParams: new URLSearchParams({
				group_id: schedule.groupId.toString(),
			}),
		});

	const conflictMap = useMemo(() => {
		return resourceConflictMap(resources);
	}, [resources.length]);

	const cit =
		formState && timesAsNumbers([formState.startsAt, formState.endsAt]);

	const bookedResources = findScheduleItemConflicts(
		conflictMap,
		scheduleItems,
		cit,
		formState.days
	);

	const preSelectedResources = new Set<number>();

	if (scheduleItem.resource) {
		preSelectedResources.add(scheduleItem.resource.id);
	}

	if (scheduleItem.resources?.length > 0) {
		scheduleItem.resources.forEach((r) =>
			preSelectedResources.add(r.resourceId)
		);
	}

	const SelectGroups = useSelectGroups({
		singleSelect: true,
		preSelectedGroups: [scheduleItem.groupId],
		hideModal: hideSelectmodal,
		groups: templateContext.groups,
	});
	const SelectResource = useSelectResource({
		groupId: organization.id,
		selectable: SelectableOptions.multi,
		resources,
		hideModal: hideSelectmodal,
		preSelectedResources: [...preSelectedResources],
		primaryResourceId: scheduleItem.resourceId || null,
		setPrimaryResource: true,
		extraColumns: [
			{
				content: t(`Availability`),
				component: <ConflictItem conflicts={bookedResources} />,
			},
		],
	});

	const ref = useRef<HTMLFormElement>(null);

	const handleSave = async (data: FormPayload) => {
		const payload: ScheduleItemUpdatePayload = {
			title: data.title as string,
			groupId: SelectGroups.selectedGroups[0],
			description: data.description as string,
			day: formState.days[0],
			startsAt: formState.startsAt,
			endsAt: formState.endsAt,
			type: data.eventType as EventTypes,
			resourceId: null,
		};

		if (SelectResource.primaryResource) {
			payload.resourceId = SelectResource.primaryResource;
		}

		const selectedResources = SelectResource.selectedResources.filter(
			(r) => r !== SelectResource.primaryResource
		);

		if (scheduleItem.resources?.length > 0 && selectedResources.length === 0) {
			payload.resourceIds = [];
		}

		if (selectedResources.length > 0) {
			payload.resourceIds = selectedResources;
		}

		const [ok, item] = await models.scheduleItem.update(scheduleItem, payload);

		if (ok) {
			templateContext?.onBeforeScheduleItemUpdate(item);
			hideModal();
		}
	};

	const handleRemove = async () => {
		const confirm = window.confirm(
			t(`Are you sure you want to delete this booking?`)
		);

		if (confirm) {
			const ok = await models.scheduleItem.remove(scheduleItem);

			if (ok) {
				templateContext.onRemoveScheduleItems([scheduleItem]);
				hideModal();
			}
		}
	};

	const handleNext = async () =>
		ref.current.dispatchEvent(
			new Event('submit', {
				cancelable: true,
				bubbles: true,
			})
		);

	const content = isLoadingResources ? (
		<Spinner />
	) : (
		<Fragment>
			<Form formRef={ref} onSubmit={handleSave}>
				<ScheduleItemForm
					groups={templateContext.flattenedGroups}
					scheduleItem={scheduleItem}
					schedule={schedule}
					showGroupSelectModal={showSelectGroupsModal}
					showSelectResourceModal={showSelectResourceModal}
					selectedGroup={SelectGroups.selectedGroups}
					selectedResource={SelectResource.selectedResources}
					formState={formState}
					setFormState={setFormState}
					isEditForm
					selectResource={SelectResource.select}
					primaryResource={SelectResource.primaryResource}
					selectPrimaryResource={SelectResource.selectPrimaryResource}
				/>
			</Form>
		</Fragment>
	);

	return (
		<Fragment>
			<StepModal.Base onClose={hideModal}>
				<StepModal.Step
					title={t(`Edit booking`)}
					onNext={handleNext}
					nextLabel={t('Save')}
					footerContent={
						<div>
							<Button
								label={t(`Remove booking`)}
								large
								caution
								onClick={handleRemove}
							/>
						</div>
					}>
					{content}
				</StepModal.Step>
			</StepModal.Base>
			{modal === 'selectGroup' && SelectGroups.Modal}
			{modal === 'selectResource' && SelectResource.Modal}
		</Fragment>
	);
};

export default EditScheduleItem;
