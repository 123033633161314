import { ReactNode } from 'react';

export const symbol_amend: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M310-340h340q13 0 21.5-8.5T680-370q0-13-8.5-21.5T650-400H310q-13 0-21.5 8.5T280-370q0 13 8.5 21.5T310-340Zm73-200q20-19 45-29.5t52-10.5q40 0 73 22t50 58q5 11 16.5 15t22.5-1q12-5 17-16t-1-22q-26-52-73.5-84T480-640q-39 0-75.5 15T340-582v-28q0-13-8.5-21.5T310-640q-13 0-21.5 8.5T280-610v107q0 9 7 16t16 7h107q13 0 21.5-8.5T440-510q0-13-8.5-21.5T410-540h-27Zm97 460q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm0-60q142 0 241-99.5T820-480q0-142-99-241t-241-99q-141 0-240.5 99T140-480q0 141 99.5 240.5T480-140Zm0-340Z"
		/>
	</svg>
);
