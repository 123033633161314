import { t } from '@transifex/native';

import * as flashActions from 'pkg/actions/flashes';

import * as endpoints from 'pkg/api/endpoints/auto';
import {
	AttendanceStatuses,
	EventUser,
	EventUserFlags,
	EventUserRole,
	EventUserStatus,
} from 'pkg/api/models/event_user';
import * as sdk from 'pkg/core/sdk';
import { crash } from 'pkg/errors/errors';

interface InviteUsersPayload {
	sendInvites: boolean;
	users: number[];
	organizer?: {
		[key: number]: boolean;
	};
}

export const inviteEventUsers = async (
	eventId: number,
	payload: InviteUsersPayload
) => {
	const req = await sdk.post(endpoints.Events.AddUsers(eventId), {}, payload);

	if (!req.ok) {
		return;
	}

	const resp: EventUser[] = await req.json();

	if (payload.sendInvites) {
		flashActions.show({
			title: t('Invites sent'),
			message: t('Added {num} users to the event', {
				num: payload.users.length,
			}),
		});
	} else {
		flashActions.show({
			title: t('Added users'),
			message: t('Added {num} users to the event', {
				num: payload.users.length,
			}),
		});
	}

	return resp || [];
};

export const deleteEventUsers = async (eventId: number, userIds: number[]) => {
	const req = await sdk.destroy(
		endpoints.Events.RemoveUsers(eventId),
		{},
		{ users: userIds }
	);

	if (req.ok) {
		return true;
	}

	return false;
};

interface PatcheventUsersPayload {
	users: {
		userId: number;
		status?: EventUserStatus;
		comment?: string;
		attendanceStatus?: AttendanceStatuses;
		role?: EventUserRole;
		flags?: EventUserFlags[];
	}[];
	physicalStrain?: string;
}

export const patchEventUsers = async (
	eventId: number,
	payload: PatcheventUsersPayload
) => {
	const req = await sdk.patch(
		endpoints.Events.UpdateUsers(eventId),
		{},
		payload
	);

	if (req.ok) {
		return true;
	}

	const error = crash();

	flashActions.show({
		title: error.title,
		message: error.description,
	});

	return false;
};
