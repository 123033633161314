import { ChangeEvent, useContext } from 'react';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';

import { AddOrderContext } from 'routes/payments/orders/create';

import * as Input from 'components/form/inputs';
import Column from 'components/layout/column';
import InfoBox from 'components/form/info-box';

interface InstallmentCountProps {
	subscription: models.subscription.Subscription;
}

export default function InstallmentCount({
	subscription,
}: InstallmentCountProps) {
	const OrderContext = useContext(AddOrderContext);

	const sentOrders = subscription.orders.filter((order) => order.sentAt).length;

	const handleInstallmentCountChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.currentTarget;

		OrderContext.setFormState({
			installmentCount: Number.parseInt(value, 10),
		});
	};

	const validateInstallmentCount = () => {
		if (OrderContext.formState.installmentCount <= sentOrders) {
			return false;
		}

		return true;
	};

	return (
		<Column>
			<Input.Group label={t('Number of installments')}>
				<Input.Field
					name="installmentCount"
					type="number"
					value={OrderContext.formState.installmentCount}
					onChange={handleInstallmentCountChange}
					validate={validateInstallmentCount}
				/>
			</Input.Group>
			<span>
				{t(
					'Current amount of installments sent: {sentOrders}/{currentInstallmentCount}',
					{
						sentOrders,
						currentInstallmentCount: subscription.installmentCount,
					}
				)}
			</span>
			{OrderContext.formState.installmentCount <= sentOrders && (
				<InfoBox color="red">
					{t(
						'The amount of total installments must be higher than the ones already sent'
					)}
				</InfoBox>
			)}
		</Column>
	);
}
