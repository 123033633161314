import { ReactNode } from 'react';

export const symbol_text_rotation_down: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m256-234 31-31q9-9 21-9t21 9q9 9 9 21t-9 21l-82 82q-9 9-21 9t-21-9l-83-81q-9-9-8.5-21.5T123-265q9-8 21-8.5t21 8.5l31 31v-536q0-13 8.5-21.5T226-800q13 0 21.5 8.5T256-770v536Zm252-143v-206l-91-33q-8-3-12.5-9.5T400-640q0-14 11-21.5t24-2.5l385 144q9 3 14.5 10.5T840-492v24q0 10-5.5 17.5T820-439L434-296q-13 5-23.5-2.5T400-320q0-8 4.5-14.5T417-344l91-33Zm47-17 233-84v-4l-233-84v172Z"
		/>
	</svg>
);
