import { ReactNode } from 'react';

export const symbol_space_bar: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M220-360q-24.75 0-42.375-17.625T160-420v-150q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T220-570v150h520v-150q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T800-570v150q0 24.75-17.625 42.375T740-360H220Z"
		/>
	</svg>
);
