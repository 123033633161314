import { ReactNode } from 'react';

export const symbol_network_cell: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M152-80q-14 0-22-9.5t-8-20.5q0-5 2-10.5t7-10.5l698-698q5-5 10.5-7t10.5-2q11 0 20.5 8t9.5 22v683q0 19-13 32t-32 13H152Zm534-60h134v-596L686-602v462Z"
		/>
	</svg>
);
