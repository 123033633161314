import { ReactNode } from 'react';

export const symbol_ios: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M189.825-280Q177-280 168.5-288.625T160-310v-215q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T220-525v215q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM347-280q-24.75 0-42.375-17.625T287-340v-280q0-24.75 17.625-42.375T347-680h105q24.75 0 42.375 17.625T512-620v280q0 24.75-17.625 42.375T452-280H347Zm0-60h105v-280H347v280Zm258 60q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T605-340h135v-114H635q-24.75 0-42.375-17.625T575-514v-106q0-24.75 17.625-42.375T635-680h135q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T770-620H635v106h105q24.75 0 42.375 17.625T800-454v114q0 24.75-17.625 42.375T740-280H605ZM189.825-615Q177-615 168.5-623.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Z"
		/>
	</svg>
);
