import { ReactNode } from 'react';

export const symbol_settings_remote: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M358-40q-16.15 0-27.075-10.925T320-78v-484q0-16.15 10.925-27.075T358-600h244q16.15 0 27.075 10.925T640-562v484q0 16.15-10.925 27.075T602-40H358Zm121.746-358q17.747 0 30-12.254 12.254-12.253 12.254-30Q522-458 509.82-471T480-484q-18.48 0-31.24 12.76Q436-458.48 436-440q0 17.64 13 29.82T479.746-398Zm.047-342q-36.207 0-70 11.5T347-695q-10.208 8-22.604 7.5Q312-688 303-697q-9-9.273-8.5-21.636Q295-731 305-739q38-30 82.691-45.5t92.5-15.5Q528-800 572.5-784.5T655-739q10.214 7.857 10.607 20.429Q666-706 657-697q-9 9-21.5 9.5T613-695q-29-22-63-33.5T479.793-740ZM480-900q-67.989 0-130.494 23Q287-854 235-811q-10.792 8.25-23.896 8.625Q198-802 189-811t-8.5-21.5Q181-845 191-853q61-51 134.765-79Q399.529-960 480-960q79.757 0 153.378 27.5Q707-905 767-852q10.214 8.311 10.607 21.156Q778-818 769-809q-9 9-22 8t-24-10q-51-44-113.5-66.5T480-900ZM380-100h200v-440H380v440Zm0 0h200-200Z"
		/>
	</svg>
);
