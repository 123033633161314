import { ReactNode } from 'react';

export const symbol_install_mobile: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M260-40q-24 0-42-18t-18-42v-760q0-24 18-42t42-18h290q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T550-860H260v30h290q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T550-770H260v580h440v-100q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T760-290v190q0 24-18 42t-42 18H260Zm0-90v30h440v-30H260Zm444-399v-271q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T764-800v271l93-92q9-8 21-8.5t21 8.5q9 9 9 21t-9 21L755-435q-9 9-21 9t-21-9L569-579q-9-9-9-21t9-21q9-9 21-9t21 9l93 92ZM260-830v-30 30Zm0 700v30-30Z"
		/>
	</svg>
);
