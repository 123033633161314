import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';
import { spacing } from 'pkg/config/styles';

import DateTime from 'pkg/datetime';

import Label from 'components/label';
import Icon from 'components/icon';

import Row from 'components/layout/row';

function getStatusTranslation(status: string) {
	switch (status) {
		case 'partially_refunded': {
			return t('Partially refunded');
		}
		case 'paid':
			return t('Paid');
		case 'active':
			return t('Active');
		case 'open':
			return t('Open');
		case 'uncollectible':
			return t('Uncollectible');
		case 'expired':
			return t('Expired');
		case 'void':
			return t('Void');
		case 'past_due':
			return t('Past due');
		case 'scheduled':
			return t('Scheduled');
		case 'draft':
			return t('Draft');
		case 'canceled':
			return t('Canceled');
		case 'processing':
			return t('Processing');
		case 'creating':
			return t('Creating');
		case 'failed':
			return t('Failed');
		case 'completed':
			return t('Completed');
		case 'refunded':
			return t('Refunded');
		case 'pending':
			return t('Pending');
		default:
			return `${status} (missing translation)`;
	}
}

export interface IStatusInfo {
	name: string;
	trialEndAt?: number;
	endAt?: number;
	archivedAt?: number;
}

interface StatusLabelProps {
	status: IStatusInfo;
	jobStatus?: string;
	className?: string;
}

function StatusLabel({ status, jobStatus = '', className }: StatusLabelProps) {
	if (status.name === '') {
		return null;
	}

	let statusTranslation = getStatusTranslation(status.name);
	let color = 'gray';
	let date;

	switch (status.name) {
		case 'partially_refunded':
			{
				color = 'green';
			}
			break;
		case 'completed':
			{
				color = 'purple';
			}
			break;
		case 'paid':
		case 'active':
			{
				color = 'green';
			}
			break;
		case 'open':
			{
				color = 'blue';
			}
			break;
		case 'uncollectible':
		case 'expired':
		case 'void':
			{
				color = 'orange';
			}
			break;
		case 'past_due':
			{
				color = 'red';
			}
			break;
		case 'scheduled':
			{
				color = 'blue';
			}
			break;
		case 'refunded':
		case 'draft':
		case 'canceled':
			{
				color = 'orange';
			}
			break;
		case 'processing':
		case 'creating':
		case 'failed':
			{
				color = 'gray';
			}
			break;
		case 'trialing':
			{
				date = new DateTime(
					new Date(status.trialEndAt * 1000)
				).toLocaleDateString({
					year: 'numeric',
					month: 'numeric',
					day: 'numeric',
				});
				statusTranslation = t('Trial until {date}', {
					date,
				});
				color = 'blue';
			}
			break;
		case 'cancels_at':
			{
				date = new DateTime(new Date(status.endAt * 1000)).toLocaleDateString({
					year: 'numeric',
					month: 'numeric',
					day: 'numeric',
				});
				statusTranslation = t('Cancels at {date}', { date });
				color = 'gray';
			}
			break;
		case 'archived': {
			date = new DateTime(
				new Date(status.archivedAt * 1000)
			).toLocaleDateString();
			statusTranslation = t('Archived {date}', { date });
			color = 'gray';
		}
	}

	let tooltipText = '';

	if (status.name === 'draft') {
		tooltipText = t(
			'This invoice will be sent automatically one hour from creation.'
		);
	}

	return (
		<Row
			className={className}
			align="center"
			autoColumns="max-content"
			spacing={spacing._3}>
			<Label
				color={color}
				data-testid={`order.status.${status.name}`}
				tooltipText={tooltipText}>
				{statusTranslation}

				{status.name === 'draft' && <Icon actualSize size={1} name="clock" />}
			</Label>
			{jobStatus === 'failed' && (
				<Icon
					actualSize
					size={1.4}
					fill={styles.palette.red[400]}
					name="task-incomplete"
					tooltip={t('Failed when processing')}
				/>
			)}
			{jobStatus === 'processing' && (
				<Icon
					actualSize
					size={1}
					fill={styles.palette.blue[400]}
					name="hourglass"
					tooltip={t(
						`Currently processing in your payment provider. No further actions can be taken until it's completed.`
					)}
				/>
			)}
		</Row>
	);
}

export default StatusLabel;
