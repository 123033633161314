import { t } from '@transifex/native';
import { Fragment, useState } from 'react';

import * as models from 'pkg/api/models';
import * as routes from 'pkg/router/routes';
import {
	useCurrentGroup,
	useCurrentMembership,
	useCurrentOrganization,
} from 'pkg/identity';
import { useCollection } from 'pkg/api/use_collection';
import * as endpoints from 'pkg/api/endpoints/auto';
import { pushState } from 'pkg/router/state';

import GroupPost from 'routes/wall/post';
import NoPosts from 'routes/wall/no-posts';

import PostForm from 'containers/group/post/Form';

import SectionTitle from 'components/SectionTitle';

import { PlaceholderType, Skeleton } from 'components/loaders/skeleton';
import Column from 'components/layout/column';

import Button from 'design/button';

export default function Wall(): JSX.Element {
	const [postModalActive, setPostModalActive] = useState(false);

	const activeMembership = useCurrentMembership();
	const org = useCurrentOrganization();
	const group = useCurrentGroup();

	const isAdminOrStaff = models.membership.isAdminOrStaff(activeMembership);

	const { records: posts, isLoading } =
		useCollection<models.groupPost.GroupPost>(
			activeMembership.isOrganizationMembership
				? endpoints.Organizations.ListGroupPosts(org.id)
				: endpoints.Groups.ShowFeed(group.id)
		);

	const post = posts?.[0];

	const hasPost = post?.id;

	const openPostModal = () => {
		setPostModalActive(true);
	};

	const closePostModal = () => {
		setPostModalActive(false);
	};

	const renderPost = () => {
		if (isLoading && !hasPost) {
			return <Skeleton placeholderType={PlaceholderType.FeedItem} />;
		}

		if (!hasPost) {
			return <NoPosts openPostModal={openPostModal} />;
		}

		return <GroupPost post={post} hideComments />;
	};

	const handleNewPost = (post: models.groupPost.GroupPost) => {
		pushState(routes.Group.Post.Show(org.id, post.id));
	};

	return (
		<Fragment>
			<Column>
				<SectionTitle icon="feed">
					{activeMembership.isOrganizationMembership
						? t('Club wall')
						: t('Wall')}
					<Button
						icon="chevron_right"
						iconPosition="right"
						noPadding
						inline
						label={t('View all')}
						href={routes.Group.Wall(org.id, group.id)}
					/>
				</SectionTitle>
				{renderPost()}
			</Column>

			{postModalActive && (
				<PostForm
					groupId={group.id}
					isAdminOrStaffForGroup={isAdminOrStaff}
					onClose={closePostModal}
					onSave={handleNewPost}
				/>
			)}
		</Fragment>
	);
}
