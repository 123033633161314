import styled from 'styled-components';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import Link from 'pkg/router/Link';
import * as models from 'pkg/api/models';

import Icon from 'components/icon';

const FileInfoContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: calc(100% - 60px);
	padding-right: var(--spacing-7);

	@media ${styles.breakpoint.small} {
		padding-right: 0;
	}
`;

const Divider = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 65%;
	color: var(--palette-white);
	height: 100%;

	@media ${styles.breakpoint.small} {
		max-width: 80%;
	}
`;

const FileName = styled.div`
	font-size: var(--font-size-sm);
	font-weight: var(--font-weight-semibold);
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

const FileMeta = styled.div`
	text-transform: uppercase;
	font-size: var(--font-size-xs);
	font-weight: var(--font-weight-semibold);
	color: var(--palette-gray-500);
`;

const Download = styled(Link)`
	display: flex;
	color: var(--palette-white);
	align-items: center;
`;

const DownloadIcon = styled(Icon)`
	grid-area: 'download';
	align-self: center;
	justify-self: center;
	font-size: var(--font-size-2xl);
`;

const DownloadText = styled.span`
	display: block;
	margin-left: var(--spacing-4);

	@media ${styles.breakpoint.small} {
		display: none;
	}
`;

interface FileInfoProps {
	attachment: models.attachment.Attachment;
}

const FileInfo = ({ attachment }: FileInfoProps) => {
	const ext = models.attachment.getExtension(attachment);
	const fileSize = models.attachment.getHumanFileSize(attachment);

	return (
		<FileInfoContainer>
			<Divider>
				<FileName>{attachment.title}</FileName>
				<FileMeta>
					{ext} - {fileSize}
				</FileMeta>
			</Divider>
			<Divider>
				<Download as={Link} href={attachment.url} target="_blank">
					<DownloadIcon name="download-simple" />
					<DownloadText>{t('Download')}</DownloadText>
				</Download>
			</Divider>
		</FileInfoContainer>
	);
};

export default FileInfo;
