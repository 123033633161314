import { ReactNode } from 'react';

export const symbol_ink_marker: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m241-97-40-40-42 42q-14 14-33.5 14.5T92-94q-14-14-12.5-34T94-161l42-42-40-40 587-587q11-11 27.5-11t27.5 11l91 91q11 11 11 27.5T829-684L241-97Zm194-400L181-243l60 60 254-254-60-60Z"
		/>
	</svg>
);
