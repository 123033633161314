import { ReactNode } from 'react';

export const symbol_auto_delete: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M261-750v570-570Zm0 630q-24 0-42-18t-18-42v-570h-.722Q188-750 179.5-758.625 171-767.25 171-780q0-12 8.625-21T201-810h146q0-13 8.625-21.5T377-840h188q12.75 0 21.375 8.625T595-810h146q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T741-750v178q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T681-572v-178H261v570h139q13 0 21 9t8 21.364Q429-138 420.375-129T399-120H261Zm128.825-545Q377-665 368.5-656.375T360-635v269q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T420-366v-269q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625Zm163 0Q540-665 531.5-656.375T523-635v83q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T583-552v-83q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625ZM667.986-79Q580-79 518.5-141.014q-61.5-62.014-61.5-150Q457-379 518.718-440.5 580.435-502 668-502q43.518 0 82.259 16.5t67.741 45q29 28.5 45.5 67.223 16.5 38.724 16.5 82.221Q880-203 817.986-141q-62.014 62-150 62ZM687-298v-92q0-8-6-14t-14-6q-8 0-14 6t-6 14v96q0 5.565 2 10.783Q651-278 656-273l62.727 62.727Q725-204 733-204t14-6q6-6 6-14t-6-14l-60-60Z"
		/>
	</svg>
);
