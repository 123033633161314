import React from 'react';

import { useCurrentOrganization } from 'pkg/identity';
import * as routes from 'pkg/router/routes';
import { useEndpoint } from 'pkg/api/use_endpoint';
import * as endpoints from 'pkg/api/endpoints/auto';
import { pushState } from 'pkg/router/state';
import { useCurrentRoute } from 'pkg/router/hooks';

import { Spinner } from 'components/loaders/spinner';

interface PaymentStatusResponse {
	organizationId: number;
	groupId: number;
	guid: string;

	cancelled?: boolean;
	success?: boolean;
}
interface FetchPaymentStatusProps {
	formSubmissionId: string;
	afterFetch: (resp: PaymentStatusResponse) => void;
	guid: string;
}
function FetchPaymentStatus({
	formSubmissionId,
	afterFetch,
	guid,
}: FetchPaymentStatusProps): JSX.Element {
	const org = useCurrentOrganization();
	const { hasError } = useEndpoint<PaymentStatusResponse>(
		endpoints.FormSubmissions.GetPaymentStatus(
			Number.parseInt(formSubmissionId, 10)
		),
		{},
		(resp) => {
			afterFetch(resp);
		}
	);

	if (hasError) {
		pushState(routes.Registration.Cancel(org.slug, guid, null));
	}

	return <Spinner />;
}

const PaymentStatus = () => {
	const route = useCurrentRoute();
	const org = useCurrentOrganization();
	const formSubmissionId = route?.query?.formSubmissionId;
	const guid = route.guid;

	const afterFetch = (resp: PaymentStatusResponse) => {
		if (resp.success) {
			pushState(
				routes.Registration.Success(
					org.slug,
					resp.guid,
					Number.parseInt(formSubmissionId, 10)
				)
			);
		} else if (resp.cancelled && formSubmissionId) {
			pushState(
				routes.Registration.Cancel(
					org.slug,
					resp.guid,
					Number.parseInt(formSubmissionId, 10)
				)
			);
		}
	};

	return (
		<FetchPaymentStatus
			formSubmissionId={formSubmissionId}
			afterFetch={afterFetch}
			guid={guid}
		/>
	);
};

export default PaymentStatus;
