import { useEffect, useState } from 'react';
import { t } from '@transifex/native';

import * as api from 'pkg/api';
import * as models from 'pkg/api/models';
import * as actions from 'pkg/actions';
import * as endpoints from 'pkg/api/endpoints/auto';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import {
	ConnectionStatus,
	ExternalServiceProvider,
	useExternalServiceConnections,
} from 'pkg/api/models/external_service';
import { useCurrentMembership } from 'pkg/identity';

import * as StepModal from 'components/step-modal';

import {
	VideoPayload,
	VideoPayloadSetter,
} from 'components/video-library/modals/Video';
import ListVideosForUser from 'components/video-library/modals/steps/wingfield/ListVideosForUser';
import PendingConnection from 'components/video-library/modals/steps/wingfield/PendingConnection';
import ListUsers from 'components/video-library/modals/steps/wingfield/ListUsers';
import CreateConnection from 'components/video-library/modals/steps/wingfield/CreateConnection';
import { Spinner } from 'components/loaders/spinner';

type ConnectionStatusType = 'loading' | 'pending' | 'active' | 'not-connected';

type ConnectionStatusSetter = (connectionStatus: ConnectionStatusType) => void;

function useConnectionStatusFor(
	membership: models.membership.Membership
): [ConnectionStatusType, ConnectionStatusSetter] {
	const isAdminOrStaff = models.membership.isAdminOrStaff(membership);

	const [didLoadConnections, setDidLoadConnections] =
		useState<boolean>(isAdminOrStaff);

	const [connectionStatus, setConnectionStatus] =
		useState<ConnectionStatusType>(isAdminOrStaff ? 'active' : 'loading');

	// Only fetch if current membership isn't an admin or staff
	const { isLoading, records } = useExternalServiceConnections({
		userId: isAdminOrStaff ? null : membership.userId,
		serviceProvider: ExternalServiceProvider.Wingfield,
	});

	useEffect(() => {
		if (!didLoadConnections && !isLoading) {
			setDidLoadConnections(true);
		}
	}, [isLoading]);

	useEffect(() => {
		if (didLoadConnections) return;

		const hasActiveConnections =
			records.filter(
				(record: models.externalService.ExternalService) =>
					record.connectionStatus === ConnectionStatus.Active
			)?.length > 0;

		const hasPendingConnections =
			records.filter(
				(record: models.externalService.ExternalService) =>
					record.connectionStatus === ConnectionStatus.Pending
			)?.length > 0;

		if (!isLoading && hasPendingConnections && !hasActiveConnections) {
			setConnectionStatus('pending');
			return;
		}

		if (!isLoading && !hasActiveConnections && !hasPendingConnections) {
			setConnectionStatus('not-connected');
			return;
		}

		if (!isLoading && hasActiveConnections) {
			setConnectionStatus('active');
			return;
		}
	}, [records]);

	return [connectionStatus, setConnectionStatus];
}

export interface WingfieldProps {
	payload: VideoPayload;
	setPayload: VideoPayloadSetter;
}

export default function Wingfield({
	payload,
	setPayload,
}: WingfieldProps): JSX.Element {
	const modalContext = StepModal.useStepModalContext();

	const currentMembership = useCurrentMembership();

	const isAdminOrStaff = models.membership.isAdminOrStaff(currentMembership);

	const currentUserId = currentMembership.userId;

	const [selectedUserId, setSelectedUserId] = useState<number>(
		!isAdminOrStaff ? currentUserId : 0
	);

	const [wingfieldEmail, setWingfieldEmail] = useState<string>();

	const [connectionStatus, setConnectionStatus] =
		useConnectionStatusFor(currentMembership);

	const handleCanCreateRequest = (email: string) => {
		modalContext.setCanGoNext(true);
		setWingfieldEmail(email);
	};

	useComponentDidMount(() => {
		modalContext.setCanGoNext(false);
	});

	modalContext.withPrev(async () => {
		if (selectedUserId !== 0) {
			if (!isAdminOrStaff) {
				modalContext.goTo('start');
			} else {
				setSelectedUserId(0);
				setConnectionStatus('active');
			}

			return Promise.resolve(false);
		}

		return Promise.resolve(true);
	});

	modalContext.withNext(async () => {
		if (wingfieldEmail) {
			const [req] = await api.post(
				endpoints.Users.ConnectWithExternalAccount(currentUserId),
				{
					externalService: 'wingfield',
					externalId: wingfieldEmail,
				}
			);

			if (!req.ok) {
				actions.flashes.show(
					{
						title: t('Could not create Wingfield connection', {
							_context: 'wingfield video modal',
						}),
					},
					req.status
				);

				return Promise.resolve(false);
			}

			modalContext.setCanGoPrev(false);
			modalContext.setCanGoNext(false);

			actions.flashes.show(
				{
					title: t('Connection request sent', {
						_context: 'wingfield video modal',
					}),
					message: t('Check your inbox for a Wingfield email connection.', {
						_context: 'wingfield video modal',
					}),
				},
				200
			);

			setWingfieldEmail(null);
			setConnectionStatus('pending');

			modalContext.setCanGoNext(false);

			return Promise.resolve(false);
		}

		return Promise.resolve(true);
	});

	if (connectionStatus === 'loading') {
		return <Spinner />;
	}

	if (connectionStatus === 'pending') {
		return (
			<PendingConnection
				currentUserId={currentUserId}
				setSelectedUserId={setSelectedUserId}
				setConnectionStatus={setConnectionStatus}
			/>
		);
	}

	if (connectionStatus === 'not-connected') {
		return <CreateConnection onCanCreateRequest={handleCanCreateRequest} />;
	}

	if (isAdminOrStaff && !selectedUserId) {
		return (
			<ListUsers
				currentUserId={currentUserId}
				setSelectedUserId={setSelectedUserId}
				setConnectionStatus={setConnectionStatus}
			/>
		);
	}

	if (selectedUserId !== 0) {
		return (
			<ListVideosForUser
				payload={payload}
				setPayload={setPayload}
				currentUserId={currentUserId}
				selectedUserId={selectedUserId}
			/>
		);
	}

	return null;
}
