import { DefaultTheme, css } from 'styled-components';

import * as styles from 'pkg/config/styles';

import rgba from 'pkg/rgba';

import * as iconStyles from 'components/icon/styles.css';
import Hint from 'components/form/Hint';

interface FieldStylesProps {
	small?: boolean;
	hasError?: boolean;
	highlighted?: boolean;
	weighted?: boolean;
	theme?: DefaultTheme;
}

const FieldStyles = css<FieldStylesProps>`
	background-color: var(--palette-white);
	color: var(--palette-black);
	border-radius: var(--radius-3);
	border: none;
	height: 40px;
	border: 1px solid var(--palette-gray-400);

	${({ theme }) =>
		theme.darkMode &&
		css`
			background-color: var(--palette-gray-700);
			color: var(--palette-white);
			border: 1px solid var(--palette-gray-600);

			box-shadow: 0 2px 3px ${rgba(styles.palette.black, 0.2)};

			&:hover {
				border: 1px solid var(--palette-gray-600);
				box-shadow: var(--palette-gray-700) 0 0 0 4px;
			}

			&:focus {
				border: 1px solid var(--palette-blue-500);
				box-shadow: var(--palette-blue-800) 0 0 0 4px;
			}

			&:active {
				border: 1px solid var(--palette-blue-500);
			}
		`}

	margin: 0;
	padding: var(--spacing-3) var(--spacing-4);
	box-sizing: border-box;
	width: 100%;
	flex: 1;
	line-height: 1;
	text-indent: none;
	font-weight: var(--font-weight-normal);
	font-size: var(--font-size-base);

	appearance: none;
	transition:
		box-shadow 150ms ease-in-out,
		background-color 150ms ease-in-out;
	resize: none;

	&::-webkit-search-cancel-button,
	&::-webkit-search-decoration {
		appearance: none;
	}

	&[type='number'] {
		padding: var(--spacing-3) var(--spacing-2);
	}

	::placeholder {
		color: var(--palette-gray-500);
	}

	&:hover {
		border: 1px solid var(--palette-gray-400);
		box-shadow: var(--palette-gray-300) 0 0 0 4px;
	}

	&:focus {
		outline: none;
		border: 1px solid var(--palette-blue-500);
		box-shadow: var(--palette-blue-300) 0 0 0 4px;
	}

	&:active {
		border: 1px solid var(--palette-blue-500);
	}

	&:disabled {
		cursor: not-allowed;
	}

	&[type='email']:invalid,
	&[pattern]:not(:placeholder-shown):invalid,
	&[type='url']:not(:placeholder-shown):invalid {
		box-shadow:
			var(--palette-red-500) 0 0 0 1px,
			var(--palette-red-300) 0 0 0 4px;
		padding: var(--spacing-3) 40px var(--spacing-3) var(--spacing-4);

		& + ${Hint} {
			color: var(--palette-red-400);
		}

		& + .${iconStyles.icon} {
			z-index: 0;
		}
	}

	${({ hasError }) =>
		hasError &&
		css`
			padding: var(--spacing-3) 40px var(--spacing-3) var(--spacing-4);

			&,
			&:focus,
			&:hover {
				box-shadow:
					var(--palette-red-500) 0 0 0 1px,
					var(--palette-red-300) 0 0 0 4px;
			}

			& + .${iconStyles.icon} {
				z-index: 0;
			}
		`}

	${({ highlighted }) =>
		highlighted &&
		css`
			box-shadow:
				inset 0 0 0 1px var(--palette-gray-300),
				${rgba(styles.palette.blue[300], 0)} 0 0 0 2px;
		`}

	${({ weighted }) =>
		weighted &&
		css`
			font-size: var(--font-size-lg);
			font-weight: var(--font-weight-bold);
		`}

	${({ small }) =>
		small &&
		css`
			height: 35px;
			font-size: var(--font-size-sm);
		`};
`;

export default FieldStyles;
