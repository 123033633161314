import { Fragment } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import DateTime from 'pkg/datetime';

import { ItemColumn } from 'routes/account/settings/child-relations/styles';

import * as Card from 'components/Card';
import Avatar from 'components/avatar';

import Column from 'components/layout/column';
import Row from 'components/layout/row';

import Button from 'design/button';

interface AccountUnderDeletionProps {
	account: models.account.Account;
	refreshRelations: () => Promise<void>;
}

function AccountUnderDeletion({
	account,
	refreshRelations,
}: AccountUnderDeletionProps): JSX.Element {
	const cancelDeletion = async () => {
		const confirm = window.confirm(t('Are you sure?'));

		if (!confirm) {
			return;
		}

		await models.account.cancelDelete(account);

		refreshRelations();
	};

	const willBeDeletedAt = new DateTime(
		new Date(account.willBeDeletedAt * 1000)
	).toLocaleDateString({
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
	});

	return (
		<Column>
			<p>
				{t(
					'This account has been scheduled for deletion, and will be deleted on {date}, you can cancel the deletion at any time before that date.',
					{
						date: willBeDeletedAt,
					}
				)}
			</p>
			<Card.Base $noBorder key={account.id}>
				<Card.Body $narrowBody>
					<Row columns="35px 1fr auto auto" align="center">
						<Avatar account={account} />
						<ItemColumn spacing={styles.spacing._1}>
							<strong>{models.account.fullName(account)}</strong>
							<span>{account.email}</span>
						</ItemColumn>
						<Button
							caution
							label={t('Cancel user delete request')}
							onClick={cancelDeletion}
						/>
					</Row>
				</Card.Body>
			</Card.Base>
		</Column>
	);
}

interface AccountsUnderDeletionProps {
	accounts: models.account.Account[];
	refreshRelations: () => Promise<void>;
}

export default function AccountsUnderDeletion({
	accounts,
	refreshRelations,
}: AccountsUnderDeletionProps): JSX.Element {
	if (accounts.length === 0) {
		return;
	}

	return (
		<Fragment>
			{accounts.map((account: models.account.Account) => (
				<AccountUnderDeletion
					key={account.id}
					account={account}
					refreshRelations={refreshRelations}
				/>
			))}
		</Fragment>
	);
}
