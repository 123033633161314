import { t } from '@transifex/native';
import { ChangeEvent } from 'react';

import DateTime, { Granularity } from 'pkg/datetime';

import * as Input from 'components/form/inputs';

interface MeetingTimeProps {
	meetBeforeMinutes: string;

	starts: DateTime;

	handleFormChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const MeetingTime = ({
	starts,
	meetBeforeMinutes,
	handleFormChange,
}: MeetingTimeProps) => {
	const meetingMinutes = Number.parseInt(meetBeforeMinutes, 10);
	const startsAtMeetingTime = starts.prev(Granularity.minute, meetingMinutes);

	return (
		<Input.Group label={t(`Meet time before event`)} justify="stretch">
			<Input.Field
				name="meetBeforeMinutes"
				type="number"
				defaultValue={meetBeforeMinutes}
				onChange={handleFormChange}>
				<Input.Suffix>{t(`Minutes`)}</Input.Suffix>
			</Input.Field>
			{meetingMinutes > 0 && (
				<span>
					{t(`Meet time {time}`, {
						time: startsAtMeetingTime.toTimeString(),
					})}
				</span>
			)}
		</Input.Group>
	);
};

export default MeetingTime;
