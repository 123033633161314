import { useState, useEffect, useRef, ReactNode } from 'react';
import styled from 'styled-components';
import Panzoom from '@panzoom/panzoom';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';

import Icon from 'components/icon';

import * as iconStyles from 'components/icon/styles.css';

const FileName = styled.span`
	font-size: var(--font-size-3xl);
	color: var(--palette-white);
	font-weight: var(--font-weight-bold);
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	width: 100%;

	@media ${styles.breakpoint.small} {
		font-size: var(--font-size-lg);
	}
`;

const Video = styled.video`
	max-width: 65%;
	display: inline-block;
	vertical-align: middle;
`;

const Audio = styled.audio`
	max-width: 70%;
	display: inline-block;
	vertical-align: middle;
`;

const Helper = styled.div`
	display: inline-block;
	height: 100%;
	vertical-align: middle;
`;

const FileWrap = styled.div`
	display: inline-flex;
	vertical-align: middle;
	flex-direction: column;
	align-items: center;
	max-width: 80%;
	overflow: hidden;

	.${iconStyles.icon} {
		font-size: 200px;

		@media ${styles.breakpoint.small} {
			font-size: 100px;
		}

		path {
			fill: var(--palette-white);
		}
	}
`;

const AttachmentWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	padding-top: var(--spacing-9);
	overflow-x: hidden;
`;

interface ImageProps {
	loaded?: boolean;
}

const Image = styled.img<ImageProps>`
	display: block;
	max-width: 80%;
	max-height: calc(100% - var(--spacing-9));
	transition: opacity 250ms ease-in-out;
	opacity: ${({ loaded }) => (loaded ? 1 : 0)};
	z-index: 1;

	@media ${styles.breakpoint.small} {
		max-width: 100%;
		max-height: calc(100% - var(--spacing-9));
	}
`;

interface ZoomableWrapperProps {
	children: ReactNode;
}

function ZoomableWrapper({ children }: ZoomableWrapperProps): JSX.Element {
	const panzoomRef = useRef(null);

	useEffect(() => {
		if (!panzoomRef.current) {
			return;
		}

		const refCopy = panzoomRef.current;

		const panzoom = Panzoom(refCopy, {
			maxScale: 5,
			minScale: 1,
			contain: 'outside',
		});

		// Resets zoom and pan when attachment.id changes
		panzoom.reset();

		refCopy.addEventListener('wheel', panzoom.zoomWithWheel);

		return () => {
			refCopy.removeEventListener('wheel', panzoom.zoomWithWheel);
			panzoom.destroy();
		};
	}, []);

	return <AttachmentWrapper ref={panzoomRef}>{children}</AttachmentWrapper>;
}

interface GalleryItemProps {
	attachment: models.attachment.Attachment;
}

export default function GalleryItem({
	attachment,
}: GalleryItemProps): JSX.Element {
	const [isLoaded, setIsLoaded] = useState(false);

	const handleDidLoad = () => setIsLoaded(true);

	let content: JSX.Element;

	switch (attachment.type) {
		case 'image':
			content = (
				<Image
					src={attachment.url}
					onLoad={handleDidLoad}
					loaded={isLoaded}
					loading="lazy"
				/>
			);
			break;
		case 'video':
			content = (
				<Video controls>
					<source src={attachment.url} type="video/mp4" />
				</Video>
			);
			break;
		case 'audio':
			content = (
				<Audio controls>
					<source src={attachment.url} />
				</Audio>
			);
			break;
		default:
			content = (
				<FileWrap>
					<Icon name="file-generic" />
					<FileName>{attachment.title}</FileName>
				</FileWrap>
			);
	}

	if (attachment.type === 'image') {
		return (
			<ZoomableWrapper key={attachment.id}>
				<Helper />
				{content}
			</ZoomableWrapper>
		);
	}

	return (
		<AttachmentWrapper>
			<Helper />
			{content}
		</AttachmentWrapper>
	);
}
