const icon = {
	name: 'drawing-polygon',
	objects: [
		{
			type: 'path',

			attributes: {
				d: 'M5.86093 18.7339L10.5149 9.99201L19.183 5.5539V15.3234L5.86093 18.7339Z',
				stroke: 'currentColor',
				fill: 'none',
				strokeWidth: 1.3,
			},
		},
	],
};

export default icon;
