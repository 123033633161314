import { ReactNode } from 'react';

export const symbol_difference: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M490-610v54q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T550-556v-54h54q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T604-670h-54v-54q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T490-724v54h-54q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T436-610h54Zm-54 240h168q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T604-430H436q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T436-370ZM260-160q-24 0-42-18t-18-42v-640q0-24 18-42t42-18h323q12.444 0 23.722 5T626-902l196 196q8 8 13 19.278 5 11.278 5 23.722v443q0 24-18 42t-42 18H260Zm0-60h520v-442L578-860H260v640ZM140-40q-24 0-42-18t-18-42v-589q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-689v589h468q12.75 0 21.375 8.675Q638-82.649 638-69.825 638-57 629.375-48.5T608-40H140Zm120-180v-640 640Z"
		/>
	</svg>
);
