import { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t } from '@transifex/native';
import { App as CapApp } from '@capacitor/app';

import * as styles from 'pkg/config/styles';
import { PageWidths } from 'pkg/config/sizes';

import { Features } from 'pkg/models/group';

import { setUrl } from 'pkg/actions/app';

import { isDev } from 'pkg/flags';
import Router, { IndexRoute, Route } from 'pkg/router/Router';
import { useCurrentRouterState, useRouterState } from 'pkg/router/hooks';
import * as mw from 'pkg/router/middlewares';
import { QueryStateProvider } from 'pkg/hooks/query-state';
import { isAndroid } from 'pkg/platform';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import * as routes from 'pkg/router/routes';
import { useCurrentOrganization } from 'pkg/identity';
import * as flags from 'pkg/flags';
import * as models from 'pkg/api/models';

import NoMembershipDashboard from 'routes/dashboard/no-membership';
import RegistrationError from 'routes/forms/registration/cancel';
import PaymentStatus from 'routes/forms/registration/payment-status';
import LoginRedirect from 'routes/login/redirect';
import ScheduleTemplatePrint from 'routes/scheduling/templates/print';
import AttendanceRoute from 'routes/statistics/attendance';
import Support from 'routes/support';
import ListFormSubmissions from 'routes/forms/single/Submissions';
import Products from 'routes/payments/products/list';
import OrganizationContactList from 'routes/organization/contacts/list';
import EditContact from 'routes/payments/contacts/Edit';
import SubgroupSettings from 'routes/group/SubgroupSettings';
import ViewMonitoredChat from 'routes/chat/ViewMonitored';
import EditMatch from 'routes/match/Edit';
import UserProfile from 'routes/user/profile/View';
import UserGoals from 'routes/user/profile/development/Goals';
import PerformanceReviews from 'routes/user/profile/development/PerformanceReviews';
import PositionMatch from 'routes/user/profile/development/position-match/View';
import AccountSettings from 'routes/account/settings';
import UserSettings from 'routes/user/settings';
import ViewFormation from 'routes/group/formation/View';
import GroupPost from 'routes/group/GroupPost';
import AssessGroup from 'routes/group/profile/Assess';
import DialogueGroup from 'routes/group/profile/Dialogue';
import GroupFormations from 'routes/group/profile/GroupFormations';
import UserReport from 'routes/group/report/user';
import SingleActivity from 'routes/user/activity/View';
import SingleExercise from 'routes/exercise/single';
import CreateExercise from 'routes/exercise/create';
import EditExercise from 'routes/exercise/edit';
import VideoPlayMode from 'routes/video/play';
import VideoPlaylist from 'routes/video/playlist';
import VideoAnalyzeMode from 'routes/video/analyze';
import Dashboard from 'routes/dashboard';
import ViewChat from 'routes/chat/view';
import Calendar from 'routes/group/calendar';
import SessionEdit from 'routes/training/session/edit';
import SessionView from 'routes/training/session/View';
import SessionPrint from 'routes/training/session/print';
import PostIndex from 'routes/post/list';
import PostCopyIndex from 'routes/post/list_copies';
import OrganizationList from 'routes/organization/list';
import SingleOrganization from 'routes/organization/single';
import CreateContact from 'routes/payments/contacts/Create';
import SingleContact from 'routes/organization/contacts/single';
import ContactUserFields from 'routes/user_fields/gross';
import NewProduct from 'routes/payments/products/create';
import EditProduct from 'routes/payments/products/edit';
import SingleProduct from 'routes/payments/products/single';
import SingleSubscription from 'routes/payments/subscriptions/single';
import NewOrder from 'routes/payments/orders/create';
import SingleOrder from 'routes/payments/orders/single';
import Taxes from 'routes/payments/tax_rates';
import EditSubscription from 'routes/payments/subscriptions/edit';
import OrderList from 'routes/payments/orders/list';
import SubscriptionList from 'routes/payments/subscriptions/list';
import PayoutsList from 'routes/payments/payouts';
import EventSingle from 'routes/event/single';
import EventCreate from 'routes/event/create';
import EventEdit from 'routes/event/edit';
import EventSeries from 'routes/event/series/single';
import EventSeriesEdit from 'routes/event/series/edit';
import ListForms from 'routes/forms/list';
import CreateForm from 'routes/forms/create';
import EditForm from 'routes/forms/edit';
import DuplicateForm from 'routes/forms/duplicate';
import SingleForm from 'routes/forms/single';
import SingleFormSubmission from 'routes/form_submissions/single';
import PreviewRegistration from 'routes/forms/registration/preview';
import Registration from 'routes/forms/registration';
import RegistrationSuccess from 'routes/forms/registration/success';
import VideoLibrary from 'routes/video/library';
import TrainingLibrary from 'routes/training/library';
import ShowGroup from 'routes/group/show';
import Notifications from 'routes/notifications';
import Resources from 'routes/scheduling/resources';
import ScheduleTemplates from 'routes/scheduling/templates/list';
import SingleSchedule from 'routes/scheduling/templates/single';
import Bookings from 'routes/scheduling/bookings';
import KitchenSink from 'routes/kitchen_sink';
import Archived from 'routes/payments/products/list/Archived';
import UserBilling from 'routes/user/billing';
import PaymentsOverview from 'routes/payments/overview';
import Invoice from 'routes/user/billing/single_invoice';
import Wall from 'routes/wall';
import GroupChange from 'routes/group_change';
import CreateDiscount from 'routes/payments/discounts/create';
import Discounts from 'routes/payments/discounts';
import EditDiscount from 'routes/payments/discounts/edit';
import NewChat from 'routes/chat/new';
import GroupSettings from 'routes/group/settings';
import { RefundsIndex } from 'routes/payments/refunds';
import UserGroupList from 'routes/group/settings/user_management/Single';
import UserManagement from 'routes/group/settings/user_management';
import SinglePayout from 'routes/payments/payouts/single';
import CompetitionsView from 'routes/settings/competitions';
import ManagementOverview from 'routes/management/overview';
import CustomLinksEmbedded from 'routes/custom_links';
import BookingsPrint from 'routes/scheduling/bookings/print';
import { Profile } from 'routes/profile';
import SingleSignOnSettings from 'routes/group/settings/single-sign-on';
import EditIdentityProvider from 'routes/group/settings/single-sign-on/identity-provider/edit';
import OrganizationRedirect from 'routes/organization/redirect';
import SSORedirect from 'routes/single_sign_on';
import AttributeMapping from 'routes/group/settings/single-sign-on/attribute-mapping';
import Registrations from 'routes/user/registrations';
import SingleCategoryForms from 'routes/user/registrations/categories';
import {
	UserProfileFullScreenLayout,
	UserProfileLayout,
} from 'routes/organization/user-profile/layout';
import OrganizationUserProfileOverview from 'routes/organization/user-profile/overview';
import OrganizationUserProfileCalendar from 'routes/organization/user-profile/calendar';
import OrganizationUserProfileNotes from 'routes/organization/user-profile/notes';
import OrganizationUserProfilePayments from 'routes/organization/user-profile/payments';
import OrganizationUserProfileRegistrations from 'routes/organization/user-profile/registrations';
import OrganizationUserProfileAttendance from 'routes/organization/user-profile/attendance';
import OrganizationUserProfileVideos from 'routes/organization/user-profile/videos';
import OrganizationUserProfileDevelopment from 'routes/organization/user-profile/development';
import Failed from 'routes/forms/registration/failed';
import RevenueView from 'routes/payments/revenue';
import OrganizationUserProfileInvoices from 'routes/organization/user-profile/payments/invoices';
import OrganizationUserProfileSubscriptions from 'routes/organization/user-profile/payments/subscriptions';
import OrganizationUserProfileUserProducts from 'routes/organization/user-profile/payments/user-products';
import PaymentsSettings from 'routes/settings/payments';

import MatchStatistics from 'containers/group/statistics/Match';
import Training from 'containers/group/statistics/training-dashboard';

import { ErrorPage } from 'components/Error';

import {
	Page,
	SimplePage,
	FullScreenPage,
	FullScreenTablePage,
	FormPage,
	FullScreenPageWithoutBorderRadius,
	PrintablePage,
	WidePage,
} from 'components/layout/page-templates';

function RouteContextProviders({ children }) {
	const error = useSelector((state) => state.app.apiError);

	if (error !== null) {
		return (
			<FullScreenPage>
				<ErrorPage error={error} />
			</FullScreenPage>
		);
	}

	return <QueryStateProvider>{children}</QueryStateProvider>;
}

/**
 * @type {React.Element<any>}
 */
const Routes = memo((props) => {
	const dispatch = useDispatch();
	const { popState, replaceState } = useRouterState();
	const org = useCurrentOrganization();

	const [currentState] = useCurrentRouterState(props.currentUrl || '/');

	useComponentDidMount(() => {
		if (isAndroid()) {
			CapApp.addListener('backButton', ({ canGoBack }) => {
				if (!canGoBack) {
					CapApp.exitApp();
				} else {
					popState();
				}
			});
		}
	});

	useEffect(() => {
		dispatch(setUrl(currentState.location));
	}, [currentState.location, dispatch]);

	const redirectToHome = (notFoundPath) => {
		if (org?.id) {
			replaceState(routes.Organization.Home(org.id));
		} else {
			replaceState(routes.Home());
		}

		if (isDev()) {
			console.warn({ notFoundPath });
		}
	};

	const productNavItems = [
		{
			to: 'products.index',
			replaceParams: ['organizationId'],
			title: t('All products'),
		},
		{
			to: 'taxes.index',
			replaceParams: ['organizationId'],
			title: t('Taxes'),
		},
		{
			to: 'products.archived',
			replaceParams: ['organizationId'],
			title: t('Archived'),
		},
	];

	const invoicesNavItems = [
		{
			to: 'payments.orders',
			replaceParams: ['groupId', 'organizationId'],
			title: t('Invoices'),
		},
		{
			to: 'refunds.index',
			replaceParams: ['groupId', 'organizationId'],
			title: t('Refunds'),
		},
	];

	const aggreagatedUserProfileNavItems = [
		{
			to: 'organization.user.profile.show',
			replaceParams: ['organizationId', 'userId'],
			title: t('Overview'),
		},
		{
			to: 'organization.user.profile.calendar',
			replaceParams: ['organizationId', 'userId'],
			title: t('Calendar'),
		},
		{
			to: 'organization.user.profile.notes',
			replaceParams: ['organizationId', 'userId'],
			title: t('Notes'),
		},
		{
			to: 'organization.user.profile.payments',
			replaceParams: ['organizationId', 'userId'],
			title: t('Payments'),
			allowedToViewItem: [
				models.Action.UserListProducts,
				models.Action.UserListSubscriptions,
				models.Action.UserListOrders,
			],
		},
		{
			to: 'organization.user.profile.registrations',
			replaceParams: ['organizationId', 'userId'],
			title: t('Registrations'),
			allowedToViewItem: [models.Action.UserListFormSubmissions],
		},
		{
			to: 'organization.user.profile.attendance',
			replaceParams: ['organizationId', 'userId'],
			title: t('Attendance'),
		},
		{
			to: 'organization.user.profile.video',
			replaceParams: ['organizationId', 'userId'],
			title: t('Video'),
			allowedToViewItem: [models.Action.UserListVideos],
		},
		{
			to: 'organization.user.profile.development',
			replaceParams: ['organizationId', 'userId'],
			title: t('Development'),
		},
	];

	return (
		<Router
			proxiedChildren={props.children}
			currentPath={currentState.location}
			onNotFound={redirectToHome}
			wrapAllWith={RouteContextProviders}
			beforeEach={[
				mw.app.resetErrors,
				mw.app.logMalformedLinks,
				mw.layout.unsetDarkMode,
				mw.layout.showTabBar,
				mw.layout.exitFullscreen,
				mw.layout.expandDrawer,
			]}>
			{org ? (
				<IndexRoute
					component={OrganizationRedirect}
					name="home"
					title={t('Home')}
					icon="home"
				/>
			) : (
				<IndexRoute
					component={NoMembershipDashboard}
					name="home"
					title={t('Home')}
					icon="home"
					wrapWith={SimplePage}
				/>
			)}

			{/* Invite & Join */}

			<Route
				path="join/:groupCode"
				name="join"
				title={t('Home')}
				icon="home"
				component={Dashboard}
			/>
			<Route
				path="claim/:inviteKey"
				name="claim"
				title={t('Home')}
				icon="home"
				component={Dashboard}
			/>
			<Route
				path="invite/:inviteId"
				name="invite.show"
				title={t('Home')}
				icon="home"
				component={Dashboard}
			/>

			{/* Debug Routes */}

			<Route path="debug">
				<Route
					path="kitchen-sink"
					icon="history"
					title="Kitchen sink"
					component={KitchenSink}
					before={[mw.layout.hideDrawer]}
					backgroundColor={styles.palette.white}
				/>
			</Route>

			{/* Group & Organization */}

			<Route
				name="group.change"
				path="group-change"
				component={GroupChange}
				userInformationNotRequired
			/>

			{/* Support */}

			<Route
				name="support"
				path="support"
				component={Support}
				title={t('Support')}
				icon="help-circle"
				wrapWith={SimplePage}
			/>

			{/* Notifications */}

			<Route
				name="notifications"
				path="notifications"
				component={Notifications}
				title={t(`Notifications`)}
				icon="notifications"
				wrapWith={Page}
				backgroundColor={styles.palette.white}
				subNavItems={[
					{
						to: 'notifications',
						title: t('New', {
							_context: 'notifications',
							_comment: 'Navigation item for unread notifications',
						}),
					},
					{
						to: 'notifications.history',
						params: {
							currentTab: 'history',
						},
						title: t('History', {
							_context: 'notifications',
							_comment: 'Navigation item for old notifications',
						}),
					},
				]}
			/>
			<Route
				name="notifications.history"
				path="notifications/:currentTab"
				currentTab="history"
				component={Notifications}
				title={t(`Notifications`)}
				icon="notifications"
				wrapWith={Page}
				backgroundColor={styles.palette.white}
				subNavItems={[
					{
						to: 'notifications',
						title: t('New', {
							_context: 'notifications',
							_comment: 'Navigation item for unread notifications',
						}),
					},
					{
						to: 'notifications.history',
						params: {
							currentTab: 'history',
						},
						title: t('History', {
							_context: 'notifications',
							_comment: 'Navigation item for old notifications',
						}),
					},
				]}
			/>

			{/* Account */}

			<Route path="account" userInformationNotRequired>
				<Route
					path="settings/:currentTab"
					component={AccountSettings}
					icon="manage_accounts"
					title={t('Account settings')}
					name="account.settings.show"
					wrapWith={SimplePage}
					userInformationNotRequired
					subNavItems={[
						{
							to: 'account.settings.show',
							params: { currentTab: 'general' },
							title: t(`Account`),
							testid: 'subnav.account_settings.account',
						},
						{
							to: 'account.settings.show',
							params: { currentTab: 'account' },
							title: t(`Account`),
							testid: 'subnav.account_settings.account',
						},
						{
							to: 'account.settings.show',
							params: { currentTab: 'privacy' },
							title: t(`Privacy`),
							testid: 'subnav.account_settings.privacy',
						},
					]}
				/>
			</Route>

			{/* Organization Routes */}

			<Route
				path="organization/:organizationId"
				before={[mw.app.checkRequiredUserInformation, mw.app.hasUser]}>
				<IndexRoute
					component={Dashboard}
					name="organization.home"
					title={t('Home')}
					icon="home"
				/>

				{/* Profile */}

				{flags.aggregatedUserProfile() && (
					<Route
						path="user/:userId"
						name="organization.user.profile.show"
						icon="person"
						title={t('User Profile')}
						component={OrganizationUserProfileOverview}
						wrapWith={UserProfileLayout}
						subNavItems={aggreagatedUserProfileNavItems}>
						<Route
							path="calendar"
							name="organization.user.profile.calendar"
							component={OrganizationUserProfileCalendar}
							wrapWith={UserProfileFullScreenLayout}
						/>
						<Route
							path="notes"
							name="organization.user.profile.notes"
							component={OrganizationUserProfileNotes}
							wrapWith={UserProfileLayout}
						/>
						<Route
							path="payments"
							name="organization.user.profile.payments"
							component={OrganizationUserProfilePayments}
							wrapWith={UserProfileLayout}
						/>
						<Route
							path="registrations"
							name="organization.user.profile.registrations"
							component={OrganizationUserProfileRegistrations}
							wrapWith={UserProfileLayout}
						/>
						<Route
							path="attendance"
							name="organization.user.profile.attendance"
							component={OrganizationUserProfileAttendance}
							wrapWith={UserProfileFullScreenLayout}
						/>
						<Route
							path="video"
							name="organization.user.profile.video"
							component={OrganizationUserProfileVideos}
							wrapWith={UserProfileLayout}
						/>
						<Route
							path="development"
							name="organization.user.profile.development"
							component={OrganizationUserProfileDevelopment}
							wrapWith={UserProfileLayout}
						/>
						<Route
							path="invoices"
							name="organization.user.profile.invoices"
							component={OrganizationUserProfileInvoices}
							wrapWith={UserProfileFullScreenLayout}
						/>
						<Route
							path="subscriptions"
							name="organization.user.profile.subscriptions"
							component={OrganizationUserProfileSubscriptions}
							wrapWith={UserProfileFullScreenLayout}
						/>
						<Route
							path="products"
							name="organization.user.profile.products"
							component={OrganizationUserProfileUserProducts}
							wrapWith={UserProfileLayout}
						/>
					</Route>
				)}

				<Route
					path="profile/:userId"
					name="profile.show"
					icon="person"
					title={t('Profile')}
					component={Profile}
				/>

				{/* Payments */}

				<Route
					path="payments"
					before={[
						mw.app.checkMembershipForPaymentCap,
						mw.app.organizationHasPaymentProvider,
						mw.app.groupHasFeature,
					]}
					feature={Features.Payments}>
					<Route
						name="payments.overview"
						path="overview"
						wrapWith={FullScreenPageWithoutBorderRadius}
						component={PaymentsOverview}
						title={t('Overview')}
						icon="paid"
					/>
					{flags.revenueTableFlag() && (
						<Route
							name="payments.revenue"
							path="revenue"
							wrapWith={FullScreenTablePage}
							component={RevenueView}
							title={t('Revenue')}
							icon="card"
						/>
					)}
					<Route
						name="payments.orders"
						path="orders"
						component={OrderList}
						wrapWith={FullScreenTablePage}
						title={t('Invoices')}
						icon="request_quote"
						subNavItems={invoicesNavItems}
					/>
					<Route
						name="payments.subscriptions"
						path="subscriptions"
						component={SubscriptionList}
						wrapWith={FullScreenTablePage}
						title={t('Subscriptions')}
						icon="currency_exchange"
					/>
					<Route
						name="payments.payouts"
						path="payouts"
						component={PayoutsList}
						wrapWith={FullScreenTablePage}
						title={t('Payouts')}
						icon="account_balance">
						<Route
							name="payout.index"
							path="/:id"
							component={SinglePayout}
							icon="paid"
						/>
					</Route>
					<Route
						path="tax-rates"
						name="taxes.index"
						component={Taxes}
						wrapWith={Page}
						icon="sell"
						title={t(`Taxes`)}
						subNavItems={productNavItems}
					/>

					{/* Discounts */}

					<Route
						path="discounts"
						name="discounts.index"
						component={Discounts}
						wrapWith={FullScreenTablePage}
						icon="sell"
						title={t('Discounts')}>
						<Route
							path="create"
							name="discounts.create"
							component={CreateDiscount}
							wrapWith={FormPage}
							icon="sell"
							title={t('Create discount code')}
							before={[mw.layout.hideTabBar]}
						/>
						<Route
							path=":id/edit"
							name="discounts.edit"
							component={EditDiscount}
							wrapWith={FormPage}
							icon="sell"
							title={t('Edit discount')}
							before={[mw.layout.hideTabBar]}
						/>
					</Route>

					{/* Refunds */}

					<Route
						path="refunds"
						name="refunds.index"
						component={RefundsIndex}
						wrapWith={FullScreenTablePage}
						icon="sell"
						title={t('Refunds')}
						subNavItems={invoicesNavItems}
					/>

					<Route path="products">
						<IndexRoute
							name="products.index"
							component={Products}
							wrapWith={FullScreenTablePage}
							icon="sell"
							title={t(`Products`)}
							subNavItems={productNavItems}
						/>
						<Route
							path="archived"
							name="products.archived"
							component={Archived}
							wrapWith={FullScreenTablePage}
							icon="sell"
							title={t(`Archived`)}
							subNavItems={productNavItems}
						/>
						<Route
							path="new-product"
							name="product.create"
							component={NewProduct}
							wrapWith={FormPage}
							icon="sell"
							title={t(`New product`)}
							before={[mw.layout.hideTabBar]}
						/>
						<Route
							path="edit/:productId"
							name="product.edit"
							component={EditProduct}
							wrapWith={FormPage}
							title={t(`Product`)}
							icon="sell"
							before={[mw.layout.hideTabBar]}
						/>
						<Route
							path=":productId/:currentTab"
							name="product.show"
							component={SingleProduct}
							wrapWith={Page}
							icon="sell"
							title={t(`Product`)}
							subNavItems={[
								{
									title: t(`Overview`),
									to: 'product.show',
									replaceParams: ['productId', 'organizationId'],
									params: {
										currentTab: 'overview',
									},
								},
								{
									title: t(`Assigned contacts`),
									to: 'product.show',
									replaceParams: ['productId', 'organizationId'],
									params: {
										currentTab: 'assigned-contacts',
									},
								},
								{
									title: t(`Purchase history`),
									to: 'product.show',
									replaceParams: ['productId', 'organizationId'],
									params: {
										currentTab: 'purchase-history',
									},
								},
								{
									title: t(`Subscriptions`),
									to: 'product.show',
									replaceParams: ['productId', 'organizationId'],
									params: {
										currentTab: 'subscriptions',
									},
								},
							]}
						/>
					</Route>
					<Route
						name="invoice.new"
						path="new-order"
						component={NewOrder}
						before={[mw.layout.hideTabBar]}
					/>
					<Route
						path="subscription/:id/edit"
						name="subscription.edit"
						component={EditSubscription}
						before={[mw.layout.hideTabBar]}
					/>
					<Route
						name="subscription.show"
						path="subscription/:id/:currentTab"
						title={t(`Subscriptions`)}
						icon="sync"
						wrapWith={Page}
						component={SingleSubscription}
						subNavItems={[
							{
								title: t(`Overview`),
								to: 'subscription.show',
								replaceParams: ['id', 'organizationId'],
								params: {
									currentTab: 'overview',
								},
							},
							{
								title: t(`Invoices`),
								to: 'subscription.show',
								replaceParams: ['id', 'organizationId'],
								params: {
									currentTab: 'invoices',
								},
							},
						]}
					/>
					<Route
						path="order/:id"
						name="order.show"
						component={SingleOrder}
						title={t(`Invoice`)}
						icon="request_quote"
						wrapWith={Page}
					/>
				</Route>

				{/* Chat */}

				<Route
					path="chat"
					before={[mw.app.groupHasFeature]}
					feature={Features.Chat}>
					<IndexRoute
						component={ViewChat}
						name="chat.index"
						title={t(`Chat`)}
						wrapWith={FullScreenPage}
						icon="forum"
					/>
					<Route
						path="monitored/:targetAccountId"
						component={ViewMonitoredChat}
						name="chat.monitored.index"
						title={t(`Monitored chats`)}
						wrapWith={FullScreenPage}
						icon="forum">
						<Route
							path=":targetChatId"
							component={ViewMonitoredChat}
							name="chat.monitored.show"
							title={t(`Monitored chats`)}
							wrapWith={FullScreenPage}
							icon="forum"
						/>
					</Route>
					<Route
						path="new"
						component={NewChat}
						wrapWith={FullScreenPage}
						name="chat.new"
						title={t(`Chat`)}
						icon="forum"
					/>
					<Route
						path=":targetChatId"
						name="chat.show"
						component={ViewChat}
						wrapWith={FullScreenPage}
						title={t(`Chat`)}
						icon="forum"
					/>
				</Route>

				{/* Group */}

				<Route path="group">
					<Route
						path="formations"
						before={[mw.app.groupHasFeature]}
						feature={Features.Assessments}>
						<Route
							path="new"
							component={ViewFormation}
							name="group.formations.new"
							title={t(`Formation`)}
							icon="groups"
							wrapWith={FullScreenPage}
						/>
						<Route
							path=":formationId"
							component={ViewFormation}
							name="group.formations.show"
							title={t(`Formation`)}
							icon="groups"
							wrapWith={FullScreenPage}
						/>
					</Route>

					<Route path="post/:id">
						<IndexRoute
							component={GroupPost}
							name="group.post.show"
							title={t(`Post`)}
							wrapWith={SimplePage}
							icon="sticky_note_2"
						/>

						<Route
							path="/copies"
							name="group.post.list_copies"
							icon="dynamic_feed"
							title={t(`View Recipients for: {title}`)}
							component={PostCopyIndex}
							wrapWith={FullScreenTablePage}
						/>
					</Route>

					<Route path=":groupId">
						<IndexRoute
							component={ShowGroup}
							name="group.show"
							title={t('Team')}
							icon="groups"
							wrapWith={Page}
						/>
						<Route
							path="wall"
							name="group.wall"
							component={Wall}
							title={t('Wall')}
							icon="feed"
							wrapWith={SimplePage}
							feature={Features.Posts}
						/>
						<Route
							path="calendar/:currentTab"
							component={Calendar}
							title={t(`Calendar`)}
							icon="calendar_today"
							name="calendar.index"
							before={[mw.app.groupHasFeature]}
							feature={Features.Calendar}
						/>

						<Route
							path="subgroup-settings"
							name="organization.sub-group.settings"
							component={SubgroupSettings}
							wrapWith={SimplePage}
						/>

						<Route path="user/:userId">
							<Route path="profile">
								<Route
									path=":currentTab"
									component={UserProfile}
									name="user.profile.show"
									icon="person"
									title={t(`Profile`)}
								/>
								<Route path="development" wrapWith={SimplePage}>
									<Route
										path="goals"
										component={UserGoals}
										name="user.profile.goals"
										title={t(`Goals`, {
											_context: 'user profile development',
										})}
									/>
									<Route
										path="position-match/:ratingId"
										component={PositionMatch}
										name="user.profile.position-match"
										title={t(`PositionMatch`)}
									/>
									<Route
										path="position-match/:ratingId/compare/:compareId"
										component={PositionMatch}
										name="user.profile.position-match-compare"
										title={t(`PositionMatch`)}
									/>
									<Route
										path="performance-reviews"
										component={PerformanceReviews}
										name="user.profile.performance-reviews"
										title={t(`Reviews`)}
									/>
								</Route>
							</Route>
						</Route>
					</Route>
				</Route>

				<Route
					wrapWith={WidePage}
					component={SingleCategoryForms}
					path="registrations/category/:categoryId"
					name="registrations.category"
					title={t('Registrations')}
				/>

				<Route path="registrations/:groupId">
					<Route
						path="edit/:formId"
						component={EditForm}
						wrapWith={FormPage}
						name="registrations.edit"
						title={t('Registrations')}
						icon="contact_page"
						before={[mw.layout.hideTabBar]}
					/>
					<Route
						path="duplicate/:formId"
						component={DuplicateForm}
						wrapWith={FormPage}
						name="registrations.duplicate"
						title={t('Duplicate registration')}
						icon="contact_page"
						before={[mw.layout.hideTabBar]}
					/>
					<Route
						component={SingleForm}
						wrapWith={Page}
						name="registrations.single"
						title={t('Registrations')}
						icon="contact_page"
						path=":formId/:currentTab"
					/>

					<Route
						path=":formId/submissions/:submissionId"
						component={SingleFormSubmission}
						name="form.submissions.single"
					/>
					<Route
						path=":formId/submissions/:submissionId"
						component={ListFormSubmissions}
						name="form.submissions.list"
					/>
					<Route
						path="create"
						component={CreateForm}
						wrapWith={FormPage}
						name="registrations.create"
						title={t('Registrations')}
						icon="contact_page"
						before={[mw.layout.hideTabBar]}
					/>
					<Route
						component={ListForms}
						wrapWith={FullScreenTablePage}
						name="registrations.index"
						title={t('Registrations')}
						icon="contact_page"
						path="/:currentTab"
						subNavItems={[
							{
								to: 'registrations.index',
								params: { currentTab: 'active' },
								replaceParams: ['groupId', 'organizationId'],
								title: t(`Registrations`),
							},
							{
								to: 'registrations.index',
								params: { currentTab: 'archived' },
								replaceParams: ['groupId', 'organizationId'],
								title: t(`Archived`),
							},
						]}
					/>
				</Route>

				{/* Management */}

				<Route path="management">
					<Route
						path="organization"
						name="management.organization"
						component={OrganizationList}
						title={t(`Organization`)}
						icon="lan"
						wrapWith={FullScreenTablePage}
					/>
					<Route
						path="overview"
						name="management.overview"
						component={ManagementOverview}
						title={t('Overview')}
						icon="person_book"
						wrapWith={WidePage}
						maxWidth={PageWidths.EXTRA_WIDE}
					/>
					<Route
						path="group/:singleGroupId"
						name="management.single-organization"
						component={SingleOrganization}
						icon="groups"
						wrapWith={Page}
					/>
					<Route path="contacts">
						<IndexRoute
							component={OrganizationContactList}
							title={t(`Contacts`)}
							icon="group"
							name="management.contacts.home"
							wrapWith={FullScreenTablePage}
						/>
						<Route
							path="new-contact"
							name="management.contact.create"
							component={CreateContact}
							wrapWith={FormPage}
							before={[mw.layout.hideTabBar]}
							icon="person_add"
							title={t(`New contact`)}
						/>
						<Route
							path="edit/:userId"
							name="management.contact.edit"
							component={EditContact}
							wrapWith={FormPage}
							before={[mw.layout.hideTabBar]}
							title={t(`Contact`)}
							icon="person_edit"
						/>
						<Route
							path=":userId/:currentTab"
							name="management.contact.show"
							component={SingleContact}
							wrapWith={Page}
							icon="person"
						/>
					</Route>
				</Route>

				{/* Org Specific Settings */}
				<Route path="settings">
					<Route
						component={SingleSignOnSettings}
						title={t('Single sign-on')}
						name="organization.settings.sso"
						path="sso"
						wrapWith={FullScreenTablePage}>
						<Route
							component={EditIdentityProvider}
							name="sso.edit"
							path=":providerId/edit"
							wrapWith={FormPage}
							before={[mw.layout.hideTabBar]}
						/>
						<Route
							name="sso.attribute-mapping"
							path=":providerId/attribute-mapping"
							component={AttributeMapping}
							wrapWith={FormPage}
							before={[mw.layout.hideTabBar]}
						/>
					</Route>
					<Route
						component={PaymentsSettings}
						wrapWith={SimplePage}
						icon="settings"
						title={t('Settings')}
						name="organization.settings.payments"
						path="payments"
						before={[mw.app.checkMembershipForPaymentCap]}
					/>
				</Route>

				{/* Settings */}

				<Route path="settings/:groupId">
					<Route
						component={UserManagement}
						wrapWith={FullScreenTablePage}
						name="settings.users"
						title={t('Settings')}
						icon="settings"
						path="user-management/:currentTab"
						subNavItems={[
							{
								to: 'settings.users',
								params: { currentTab: 'all' },
								replaceParams: ['groupId', 'organizationId'],
								title: t('All'),
							},
							{
								to: 'settings.users',
								params: { currentTab: 'banned' },
								replaceParams: ['groupId', 'organizationId'],
								title: t('Banned'),
							},
						]}
					/>
					<Route
						path="user-management/user/:userId"
						name="settings.user.show"
						component={UserGroupList}
						wrapWith={FullScreenTablePage}
						title={t(`User`)}
					/>
					<Route
						path="organization"
						name="settings.organization"
						component={OrganizationList}
						title={t(`Organization`)}
						icon="lan"
						wrapWith={FullScreenTablePage}
					/>
					<Route
						component={CompetitionsView}
						icon="settings"
						title={t('Competitions')}
						name="settings.competitions"
						path="competitions"
						wrapWith={FullScreenTablePage}
					/>

					<Route
						component={GroupSettings}
						icon="settings"
						title={t(`Settings`)}
						name="settings.index"
						path=":currentTab"
						wrapWith={FullScreenPageWithoutBorderRadius}
					/>
				</Route>

				{/* Statistics */}

				<Route path="statistics/:groupId">
					<Route
						name="statistics.attendance-report.show"
						path="attendance/:currentTab"
						title={t('Attendance')}
						component={AttendanceRoute}
						icon="timer"
					/>
					<Route
						path="/user/:userId"
						component={UserReport}
						title={t(`Attendance`)}
						icon="timer"
						name="statistics.attendance-report.user"
						wrapWith={FullScreenTablePage}
					/>
					<Route
						path="/match"
						component={MatchStatistics}
						name="statistics.match"
						icon="bar_chart"
						title={t('Match')}
						wrapWith={FullScreenPageWithoutBorderRadius}
						before={[mw.app.groupHasFeature]}
						feature={Features.AggregatedStatistics}
					/>
					<Route
						path="/training"
						component={Training}
						name="statistics.training"
						icon="bar_chart"
						title={t('Training')}
						wrapWith={FullScreenPageWithoutBorderRadius}
						before={[mw.app.groupHasFeature]}
						feature={Features.AggregatedStatistics}
					/>
				</Route>

				{/* Scheduling */}

				<Route path="scheduling/:groupId">
					<Route
						path="bookings"
						name="bookings.index"
						title={t('Bookings')}
						component={Bookings}
					/>
					<Route
						path="bookings/print"
						component={BookingsPrint}
						name="bookings.print"
						wrapWith={PrintablePage}
						before={[mw.layout.hideTabBar, mw.layout.hideDrawer]}
					/>
					<Route
						path="schedule-templates"
						name="templates.index"
						title={t(`Schedule templates`)}
						wrapWith={FullScreenTablePage}
						component={ScheduleTemplates}
						icon="table_chart">
						<Route
							wrapWith={null}
							name="templates.show"
							path=":id"
							component={SingleSchedule}
						/>
						<Route
							name="templates.print"
							path=":id/print"
							component={ScheduleTemplatePrint}
							wrapWith={PrintablePage}
							before={[mw.layout.hideTabBar, mw.layout.hideDrawer]}
						/>
					</Route>
					<Route
						path="resources"
						title={t(`Resources`)}
						wrapWith={FullScreenTablePage}
						subNavItems={[
							{
								to: 'resources.index',
								params: { currentTab: 'overview' },
								replaceParams: ['groupId', 'organizationId'],
								title: t(`Resources`),
							},
							{
								to: 'resources.index',
								params: { currentTab: 'locations' },
								replaceParams: ['groupId', 'organizationId'],
								title: t(`Locations`),
							},
						]}
						icon="location_on">
						<Route
							name="resources.index"
							path=":currentTab"
							component={Resources}
						/>
					</Route>
				</Route>

				{/* Development */}

				<Route path="development/:groupId">
					<Route
						path="/training-library/collections/:collectionId"
						name="training-library.collection.show"
						icon="tactic"
						component={TrainingLibrary}
						title={t(`Training Library`)}
						wrapWith={FullScreenPageWithoutBorderRadius}
					/>
					<Route
						path="/training-library"
						icon="tactic"
						title={t(`Training Library`)}
						before={[mw.app.groupHasFeature]}
						feature={Features.TrainingLibrary}
						subNavItems={[
							{
								title: t(`Browse`),
								to: 'training-library.index',
								replaceParams: ['groupId', 'organizationId'],
							},
							{
								title: t(`Team`),
								to: 'training-library.show',
								replaceParams: ['groupId', 'organizationId'],
								params: {
									contentGroup: 'team',
								},
							},
							{
								title: t(`Club`),
								to: 'training-library.show',
								replaceParams: ['groupId', 'organizationId'],
								params: {
									contentGroup: 'club',
								},
							},
							{
								title: t(`Curated content`),
								to: 'training-library.show',
								replaceParams: ['groupId', 'organizationId'],
								params: {
									contentGroup: 'curated',
								},
							},
							{
								title: t(`Your content`),
								to: 'training-library.show',
								replaceParams: ['groupId', 'organizationId'],
								params: {
									contentGroup: 'your',
								},
							},
						]}
						wrapWith={Page}>
						<IndexRoute
							name="training-library.index"
							component={TrainingLibrary}
						/>
						<Route
							path=":contentGroup"
							name="training-library.show"
							component={TrainingLibrary}
						/>
					</Route>
					<Route
						path="/video-library/collections/:collectionId"
						name="video-library.collection.show"
						component={VideoLibrary}
						title={t(`Video Library`)}
						icon="smart_display"
						wrapWith={FullScreenPageWithoutBorderRadius}
					/>
					<Route
						path="/video-library"
						icon="smart_display"
						title={t(`Video Library`)}
						subNavItems={[
							{
								title: t(`Browse`),
								to: 'video-library.index',
								replaceParams: ['groupId', 'organizationId'],
							},
							{
								title: t(`Team`),
								to: 'video-library.show',
								replaceParams: ['groupId', 'organizationId'],
								params: {
									contentGroup: 'team',
								},
							},
							{
								title: t(`Club`),
								to: 'video-library.show',
								replaceParams: ['groupId', 'organizationId'],
								params: {
									contentGroup: 'club',
								},
							},
							{
								title: t(`Your content`),
								to: 'video-library.show',
								replaceParams: ['groupId', 'organizationId'],
								params: {
									contentGroup: 'your',
								},
							},
						]}
						wrapWith={Page}>
						<IndexRoute name="video-library.index" component={VideoLibrary} />
						<Route
							path=":contentGroup"
							name="video-library.show"
							component={VideoLibrary}
						/>
					</Route>
					<Route
						path="/assessments"
						component={AssessGroup}
						title={t(`Assessment`)}
						icon="tune"
						wrapWith={SimplePage}
						before={[mw.app.groupHasFeature]}
						feature={Features.Assessments}
					/>
					<Route
						path="/performance-reviews"
						component={DialogueGroup}
						title={t('Performance Reviews')}
						icon="forum"
						wrapWith={SimplePage}
					/>
					<Route
						path="formations"
						component={GroupFormations}
						name="development.formations.index"
						title={t('Formations')}
						icon="groups"
						wrapWith={SimplePage}
					/>
				</Route>

				{/* Events */}

				<Route path="events">
					<Route
						path="create"
						title={t(`Create event`)}
						icon="calendar_add_on"
						component={EventCreate}
						name="event.create"
						wrapWith={FormPage}
						before={[mw.layout.hideTabBar]}
					/>
					<Route
						path=":eventId"
						title={t(`Event`)}
						icon="calendar_add_on"
						component={EventSingle}
						backgroundColor={styles.palette.white}>
						<Route
							icon="edit_calendar"
							component={EventEdit}
							name="event.edit"
							path="edit"
							wrapWith={FormPage}
							before={[mw.layout.hideTabBar]}
						/>
						<Route
							title={t(`Event`)}
							path=":currentTab"
							icon="calendar_today"
							component={EventSingle}
							name="event.view"
							backgroundColor={styles.palette.white}
						/>

						{/* Single Match */}
						<Route
							path="matches/:matchId"
							icon="nav-events"
							backgroundColor={styles.palette.white}>
							<Route
								path=":currentTab"
								name="match.view"
								icon="calendar_today"
								component={EventSingle}
								title={t(`Summary`)}
							/>
							<Route
								path="edit/:currentTab"
								name="match.edit"
								component={EditMatch}
								matchCurrentTab="(result|lineup)"
								before={[mw.layout.hideTabBar]}
								wrapWith={Page}
								subNavItems={[
									{
										to: 'match.edit',
										params: { currentTab: 'result' },
										replaceParams: ['matchId', 'eventId', 'organizationId'],
										title: t(`Result`),
									},
									{
										to: 'match.edit',
										params: { currentTab: 'lineup' },
										replaceParams: ['matchId', 'eventId', 'organizationId'],
										title: t(`Lineup`),
									},
								]}
							/>
						</Route>
					</Route>
				</Route>

				{/* Event series */}

				<Route path="event-series">
					<Route path=":eventSeriesId">
						<Route
							path="edit"
							title={t('Edit series')}
							icon="edit_calendar"
							component={EventSeriesEdit}
							name="event-series.edit"
							backgroundColor={styles.palette.white}
							wrapWith={FormPage}
							before={[mw.layout.hideTabBar]}
						/>
						<IndexRoute
							title={t('Event series')}
							icon="event_repeat"
							component={EventSeries}
							name="event-series.view"
							wrapWith={Page}
							backgroundColor={styles.palette.white}
						/>
					</Route>
				</Route>

				{/* Communication */}

				<Route path="communication/:groupId">
					<Route
						path="posts/:currentTab"
						wrapWith={FullScreenTablePage}
						component={PostIndex}
						name="group.post.index"
						title={t(`Posts`)}
						icon="dynamic_feed"
						subNavItems={[
							{
								title: t(`Published`),
								to: 'group.post.index',
								replaceParams: ['groupId', 'organizationId'],
								params: { currentTab: 'published' },
							},
							{
								title: t(`Draft`),
								to: 'group.post.index',
								replaceParams: ['groupId', 'organizationId'],
								params: { currentTab: 'draft' },
							},
							{
								title: t(`Scheduled`),
								to: 'group.post.index',
								replaceParams: ['groupId', 'organizationId'],
								params: { currentTab: 'scheduled' },
							},
						]}
					/>
				</Route>

				{/* Custom Links */}

				<Route
					path="custom-links-:title/:linkId/"
					title={t('Custom Link')}
					icon="link"
					component={CustomLinksEmbedded}
					name="custom-links.show"
					backgroundColor={styles.palette.white}
					wrapWith={FullScreenPage}
					before={[mw.layout.hideTabBar]}
				/>

				{/* Training & Exercises */}

				<Route
					path="exercises"
					before={[mw.app.groupHasFeature]}
					feature={Features.Exercises}>
					<Route
						before={[mw.layout.hideTabBar]}
						icon="tactic"
						path="new"
						component={CreateExercise}
						title={t(`Create exercise`)}
						name="exercise.create"
						wrapWith={FormPage}
					/>
					<Route
						before={[mw.layout.hideTabBar]}
						icon="tactic"
						path=":exerciseId/edit"
						component={EditExercise}
						title={t(`Edit exercise`)}
						name="exercise.edit"
						wrapWith={FormPage}
					/>
					<Route
						icon="tactic"
						path=":exerciseId"
						component={SingleExercise}
						name="exercise.show"
						wrapWith={Page}
						backgroundColor={styles.palette.white}
					/>
				</Route>

				<Route
					path="training-sessions"
					before={[mw.app.groupHasFeature]}
					feature={Features.TrainingLibrary}>
					<Route
						path="create"
						component={SessionEdit}
						name="training-session.create"
						title={t(`Create session`)}
						icon="tactic"
						wrapWith={Page}
						before={[mw.layout.hideTabBar]}
					/>
					<Route
						path=":sessionId"
						component={SessionView}
						name="training-session.show"
						title={t(`Session`)}
						icon="tactic"
					/>
					<Route
						path=":sessionId/edit"
						component={SessionEdit}
						name="training-session.edit"
						title={t(`Edit session`)}
						icon="tactic"
						wrapWith={Page}
						before={[mw.layout.hideTabBar]}
					/>
					<Route
						path=":sessionId/print"
						component={SessionPrint}
						name="training-session.print"
						wrapWith={PrintablePage}
						before={[mw.layout.hideTabBar, mw.layout.hideDrawer]}
					/>
				</Route>

				{/* Video */}

				<Route
					path="videos/:videoId"
					component={VideoPlayMode}
					name="video.show"
					before={[
						mw.layout.useDarkMode,
						mw.layout.hideTabBar,
						mw.layout.collapseDrawer,
					]}
					title={t(`Video`)}
					icon="smart_display"
					compactHeader
				/>
				<Route
					path="videos/:videoId/edit"
					redirectTo="videos/:videoId/analyze"
				/>
				<Route
					path="videos/:videoId/analyze"
					component={VideoAnalyzeMode}
					name="video.analyze"
					before={[
						mw.layout.useDarkMode,
						mw.layout.hideTabBar,
						mw.layout.hideDrawer,
					]}
					title={t(`Video`)}
					icon="smart_display"
					compactHeader
				/>
				<Route
					path="video-playlists/:playlistId"
					name="video-playlist.show"
					before={[
						mw.layout.useDarkMode,
						mw.layout.hideTabBar,
						mw.layout.collapseDrawer,
					]}
					component={VideoPlaylist}
				/>

				{/* User */}

				<Route path="user">
					<Route
						path="registrations/:currentTab"
						name="user.registrations"
						component={Registrations}
						title={t('Registrations')}
						icon="contact_page"
						subNavItems={[
							{
								to: 'user.registrations',
								params: { currentTab: 'open' },
								replaceParams: ['organizationId'],
								title: t('Open registrations'),
							},
							{
								to: 'user.registrations',
								params: { currentTab: 'submissions' },
								replaceParams: ['organizationId'],
								title: t('Submissions'),
							},
						]}
					/>
					<Route
						path="submissions/:submissionId"
						component={SingleFormSubmission}
						name="user.submission"
					/>

					<Route path=":userId">
						<Route
							path="properties"
							name="contact.fields"
							component={ContactUserFields}
							wrapWith={FullScreenTablePage}
						/>
						<Route
							path="settings"
							component={UserSettings}
							icon="manage_accounts"
							title={t('Edit profile')}
							name="user.settings"
							wrapWith={FormPage}
							before={[mw.layout.hideTabBar]}
							backgroundColor={styles.palette.white}
							userInformationNotRequired
						/>
					</Route>
				</Route>
				<Route
					path="user-activity/:activityId"
					component={SingleActivity}
					name="user.activity.show"
					title={t(`Activity`)}
					wrapWith={SimplePage}
				/>

				<Route
					path="self/billing"
					name="self.billing"
					component={UserBilling}
					icon="paid"
					title={t('Billing')}
					wrapWith={Page}>
					<Route
						path="invoice/:orderId"
						component={Invoice}
						icon="paid"
						title={t('Invoice')}
						name="self.invoice"
						wrapWith={Page}
					/>
				</Route>
			</Route>

			<Route path="registration/:orgSlug/:guid">
				<IndexRoute
					component={PreviewRegistration}
					wrapWith={Page}
					name="registration.index"
					icon="edit_document"
				/>
				<Route
					path="register"
					component={Registration}
					wrapWith={WidePage}
					name="registration.form"
					icon="edit_document"
					before={[mw.layout.hideTabBar]}
				/>
				<Route
					path="payment-status"
					component={PaymentStatus}
					wrapWith={Page}
					name="registration.payment-status"
					icon="edit_document"
					before={[mw.layout.hideTabBar]}
					title={t(`Determining payment status`)}
				/>
				<Route
					path="success/:formSubmissionId"
					component={RegistrationSuccess}
					wrapWith={Page}
					name="registration.success"
					icon="edit_document"
					before={[mw.layout.hideTabBar]}
				/>
				<Route
					path="cancel/:formSubmissionId"
					component={RegistrationError}
					wrapWith={Page}
					name="registration.cancel"
					icon="edit_document"
					before={[mw.layout.hideTabBar]}
				/>
				<Route
					path="failed"
					component={Failed}
					wrapWith={Page}
					name="registration.failed"
					icon="edit_document"
					before={[mw.layout.hideTabBar]}
				/>
			</Route>

			<Route
				path="login"
				name="login-redirector.index"
				component={LoginRedirect}
				userInformationNotRequired
			/>

			<Route
				path="sso/redirect"
				name="sso-redirector.index"
				component={SSORedirect}
			/>
		</Router>
	);
});

export default Routes;
