import styled from 'styled-components';

import * as styles from 'pkg/config/styles';

import * as iconStyles from 'components/icon/styles.css';

export const Link = styled.button<{ reverse?: boolean }>`
	display: flex;
	justify-content: center;
	align-items: center;
	appearance: none;
	border: none;
	cursor: pointer;
	font-size: 1.6rem;
	outline: none;
	width: 34px;
	height: 34px;
	padding: 0;
	background-color: var(--palette-blue-200);
	border-radius: var(--radius-3);

	@media ${styles.breakpoint.small} {
		width: 40px;
		height: 40px;
	}

	@media (hover: hover) {
		&:hover {
			background-color: var(--palette-blue-300);
		}
	}

	&:active {
		background-color: var(--palette-blue-200);
	}

	.${iconStyles.icon} {
		transform: scaleX(${({ reverse }) => (reverse ? -1.1 : 1.1)}) scaleY(1.1);
		color: var(--palette-blue-500);
	}
`;

export const Date = styled.div`
	font-size: var(--font-size-lg);
	font-weight: var(--font-weight-semibold);

	@media ${styles.breakpoint.small} {
		font-size: var(--font-size-base);
	}
`;

export const DateWrapper = styled.span`
	font-size: var(--font-size-base);
	display: flex;
	align-items: center;
	white-space: nowrap;
`;

export const WeekNumber = styled.div`
	color: var(--palette-gray-500);
	font-weight: var(--font-weight-normal);
	font-size: var(--font-size-xs);
`;
