const navOrganization = {
	name: 'nav-organisation',
	objects: [
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				d: 'M8.91548 4.04981V8.18291H15.5122V4.04981H8.91548ZM8.81947 2.74981C8.15452 2.74981 7.61548 3.28885 7.61548 3.9538V8.27892C7.61548 8.94386 8.15452 9.48291 8.81947 9.48291H11.5638V11.7208H5.86309V14.7369H3.11878C2.45384 14.7369 1.91479 15.276 1.91479 15.9409V20.266C1.91479 20.931 2.45384 21.47 3.11879 21.47H9.90753C10.5725 21.47 11.1115 20.931 11.1115 20.266V15.9409C11.1115 15.276 10.5725 14.7369 9.90753 14.7369H7.16309V13.0208H17.2647V14.7369H14.5202C13.8552 14.7369 13.3162 15.276 13.3162 15.9409V20.266C13.3162 20.931 13.8552 21.47 14.5202 21.47H21.3089C21.9738 21.47 22.5129 20.931 22.5129 20.266V15.9409C22.5129 15.276 21.9738 14.7369 21.3089 14.7369H18.5647V11.7208H12.8638V9.48291H15.6082C16.2732 9.48291 16.8122 8.94386 16.8122 8.27892V3.9538C16.8122 3.28885 16.2732 2.74981 15.6082 2.74981H8.81947ZM14.6162 16.0369V20.17H21.2129V16.0369H14.6162ZM3.21479 20.17V16.0369H9.81152V20.17H3.21479Z',
			},
		},
	],
};
export default navOrganization;
