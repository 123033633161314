import { HTMLProps, ReactNode } from 'react';

import * as styles from 'pkg/config/styles';

import { cssClasses, cssVarList } from 'pkg/css/utils';

import * as css from './styles.css';

interface ColumnProps extends HTMLProps<HTMLDivElement> {
	children: ReactNode;
	spacing?: styles.spacing;
	inlineSpacing?: styles.spacing;
	blockSpacing?: styles.spacing;
	justify?: 'start' | 'center' | 'end' | 'stretch';
	className?: string;
	padding?: styles.spacing;
}

// Default wrapper for a column of items with spacing between each item.
//
// Example layout:
// [ item ]
// [ item ]
// [ item ]
const Column = ({
	spacing = styles.spacing._4,
	inlineSpacing,
	blockSpacing,
	padding,
	justify = 'stretch',
	className,
	children,
	...props
}: ColumnProps) => {
	return (
		<div
			{...props}
			style={{
				...cssVarList({
					spacing,
					justify,
					inlineSpacing,
					blockSpacing,
					padding,
				}),
				...props.style,
			}}
			className={cssClasses(css.column, className)}>
			{children}
		</div>
	);
};

export default Column;
