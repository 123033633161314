import { ReactNode } from 'react';

export const symbol_full_stacked_bar_chart: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M190-160q-12.75 0-21.375-8.625T160-190v-80h140v80q0 13-8.625 21.5T270-160h-80Zm-30-170v-140h140v140H160Zm0-200v-240q0-13 8.625-21.5T190-800h80q12.75 0 21.375 8.625T300-770v240H160Zm280 370q-12.75 0-21.375-8.625T410-190v-240h140v240q0 13-8.625 21.5T520-160h-80Zm-30-330v-140h140v140H410Zm0-200v-80q0-13 8.625-21.5T440-800h80q12.75 0 21.375 8.625T550-770v80H410Zm280 530q-12.75 0-21.375-8.625T660-190v-30h140v30.175q0 12.825-8.625 21.325T770-160h-80Zm-30-130v-140h140v140H660Zm0-200v-280q0-13 8.625-21.5T690-800h80q12.75 0 21.375 8.625T800-770v280H660Z"
		/>
	</svg>
);
