import { ReactNode } from 'react';

export const symbol_sanitizer: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M680-650q-20 0-35-15t-15-35q0-16.522 14.5-38.261Q659-760 669-772q5-5 11-5t11 5q10.4 12.031 24.7 33.516Q730-717 730-700q0 20-14.583 35-14.584 15-35.417 15Zm110 280q-37 0-63.5-26.5T700-460q0-32 26-75t46-68q7.054-8 18.027-8T808-603q20 25 46 68t26 75q0 37-26.1 63.5T790-370Zm-430 40v50q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T420-280v-50h50q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T470-390h-50v-50q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T360-440v50h-50q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T310-330h50ZM220-140h340v-350q0-71-49.5-120.5t-120-49.5Q320-660 270-610.5T220-490v350Zm0 0h340-340Zm0 60q-24 0-42-18t-18-42v-350q0-86 57-150.192Q274-704.384 360-718v-102h-60q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T300-880h210q24 0 45.5 5t41.5 16q8 4 13 11.551t5 16.612q0 12.837-9.073 21.337T584-801q-3 0-16-4-14-7-28-11t-30-4h-90v102q86 13.616 143 77.808Q620-576 620-490v350q0 24-18 42t-42 18H220Z"
		/>
	</svg>
);
