import { t } from '@transifex/native';

import DateTime from 'pkg/datetime';

import { useOrderReport } from 'routes/payments/overview/hooks';
import { Widget } from 'routes/payments/overview/widgets';

import * as DataCard from 'design/data_card';

export default function AveragePaymentsOverWidget(): JSX.Element {
	const { averageOverdueTime, startOfRange } = useOrderReport();
	const { average, lastMonth, lastQuarter, lastYear } = averageOverdueTime;

	return (
		<Widget>
			<DataCard.Base
				titleIcon="warning"
				title={t('Average payments overdue')}
				heading={t('{average} days', { average: average.toFixed(1) })}
				noFooterDivider
				footer={t('Since {date}', {
					date: DateTime.fromTimestamp(startOfRange).toDateString(),
				})}>
				<DataCard.Breakdown
					items={[
						{
							title: t('Previous 30 days'),
							value: lastMonth.toFixed(1),
						},
						{
							title: t('Previous 90 days'),
							value: lastQuarter.toFixed(1),
						},
						{
							title: t('Previous year'),
							value: lastYear.toFixed(1),
						},
					]}
				/>
			</DataCard.Base>
		</Widget>
	);
}
