import { ReactNode } from 'react';

export const symbol_abc: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M395-360q-6 0-10.5-4.5T380-375v-210q0-6 4.5-10.5T395-600h158q14.45 0 24.225 9.775Q587-580.45 587-566v52q0 14.45-9.775 24.225Q567.45-480 553-480q14.45 0 24.225 9.775Q587-460.45 587-446v52q0 14.45-9.775 24.225Q567.45-360 553-360H395Zm33-144h111v-48H428v48Zm0 96h111v-48H428v48Zm-284 48q-10.4 0-17.2-6.8-6.8-6.8-6.8-17.2v-182q0-14.45 9.775-24.225Q139.55-600 154-600h139q14.45 0 24.225 9.775Q327-580.45 327-566v182q0 10.4-6.8 17.2-6.8 6.8-17.2 6.8-10.4 0-17.2-6.8-6.8-6.8-6.8-17.2v-53H168v53q0 10.4-6.8 17.2-6.8 6.8-17.2 6.8Zm24-125h111v-67H168v67Zm499 125q-14.45 0-24.225-9.775Q633-379.55 633-394v-172q0-14.45 9.775-24.225Q652.55-600 667-600h139q14.45 0 24.225 9.775Q840-580.45 840-566v22q0 10.4-6.8 17.2-6.8 6.8-17.2 6.8-10.4 0-17.2-6.8-6.8-6.8-6.8-17.2v-8H681v144h111v-8q0-10.4 6.8-17.2 6.8-6.8 17.2-6.8 10.4 0 17.2 6.8 6.8 6.8 6.8 17.2v22q0 14.45-9.775 24.225Q820.45-360 806-360H667Z"
		/>
	</svg>
);
