import { ReactNode } from 'react';

export const symbol_legend_toggle: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M190-260h580q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T770-200H190q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T190-260Zm0-170h580q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T770-370H190q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T190-430Zm-15-194 191-116q15-9 33.5-8.5T432-737l168 119 152-108q15-11 31.5-2.75t16.5 27.893Q800-693 796.5-687q-3.5 6-9.5 11L635-568q-16 11-35 10.5T565-569L397-688 206-572q-15 9-30.5.325-15.5-8.676-15.5-26.027 0-8.298 4-14.798t11-11.5Z"
		/>
	</svg>
);
