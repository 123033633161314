import { batch } from 'react-redux';

import { triggerError } from 'pkg/actions/app';
import * as ratingsService from 'pkg/actions/services/ratings.service';
import * as actionTypes from 'pkg/actions/action-types';
import { forAccountHeaders } from 'pkg/actions/utils';

import * as sdk from 'pkg/core/sdk';

const setRatings = (ratings, userId, groupId) => {
	return {
		type: actionTypes.Ratings.SetItems,
		ratings,
		userId,
		groupId,
	};
};

export const addRating = (rating) => {
	return {
		type: 'ADD_RATING',
		rating,
	};
};

export const addRatings = (ratings) => {
	return {
		type: actionTypes.Ratings.AddItems,
		ratings,
	};
};

export const addSingleUserRating = (rating, userId) => {
	return {
		type: actionTypes.RATING_ADD_SINGLE_USER_RATING,
		rating,
		userId,
	};
};

export const setCurrentPosition = (id) => ({
	type: 'SET_CURRENT_POSITION',
	id,
});

export const setComparisonRatingId = (ratingId) => ({
	type: 'SET_COMPARISON_RATING_ID',
	ratingId,
});

export const toggleMaturityAdjustment = (maturityAdjustment) => ({
	type: 'TOGGLE_MATURITY_ADJUSTMENT',
	maturityAdjustment,
});

export const setPositionScores = ({ rating }) => ({
	type: 'SET_POSITION_SCORES',
	ratingId: rating.id,
	positionScores: rating.positionScores,
});

export const setUserRatingItems = ({ rating }) => ({
	type: 'SET_USER_RATING_ITEMS',
	ratingId: rating.id,
	ratingItems: rating.ratingItems,
});

export const setRatingScores = ({ rating, positionId }) => ({
	type: 'SET_RATING_SCORES',
	ratingId: rating.id,
	positionId,
	ratingScores: rating.ratingScores,
});

export const setRatingsError = (ratingId, status, statusText) => ({
	type: 'SET_RATING_ERROR',
	ratingId,
	status,
	statusText,
});

export const setPositionFetchingStatus = (status) => ({
	type: 'SET_POSITION_FETCHING_STATUS',
	status,
});

export const togglePublishedToPlayer = (id) => async (dispatch) => {
	const request = await ratingsService.togglePublishedToPlayer(id);
	const rating = await request.json();
	await dispatch(addSingleUserRating(rating, rating.userId));
};

export const fetchUsersPrimaryRating =
	(userId, groupId = 0, forAccount = null) =>
	async (dispatch) => {
		const params = {
			'with-input': 1,
		};

		if (groupId > 0) {
			params.group_id = groupId;
		}

		const headers = forAccountHeaders(forAccount);

		const ratingResponse = await sdk.get(
			`/users/${userId}/primary-rating`,
			params,
			{},
			headers
		);

		if (!ratingResponse.ok) {
			return;
		}

		const rating = await ratingResponse.json();

		const positionResponse = await sdk.get(
			`user-ratings/${rating.id}?position=${rating.suggestedPositionId}`,
			{},
			{},
			headers
		);

		if (!positionResponse.ok) {
			return;
		}

		const position = await positionResponse.json();

		batch(() => {
			rating.ratingScores = position.ratingScores;
			dispatch(addSingleUserRating(rating, userId));

			const initItems = {
				rating,
				positionId: rating.suggestedPositionId,
			};

			dispatch(setPositionScores(initItems));
			dispatch(setRatingScores(initItems));
			dispatch(setCurrentPosition(rating.suggestedPositionId));
		});
	};

export const fetchUsersRatings =
	(userId, groupId, fetchNextPage = false, forAccount = null) =>
	async (dispatch, getState) => {
		const headers = forAccountHeaders(forAccount);

		let url = `users/${userId}/ratings`;

		if (groupId) {
			url = `${url}?group_id=${groupId}`;
		}

		if (fetchNextPage) {
			url = getState().ratings.collectionLinks[groupId][userId].next;
		}

		const response = await sdk.get(url, {}, {}, headers);

		if (!response.ok) {
			return;
		}

		const ratings = await response.json();

		dispatch(setRatings(ratings, userId, groupId));
	};

export const fetchRatingPositionScores =
	(ratingId, positionId, forAccount = null) =>
	async (dispatch) => {
		const headers = forAccountHeaders(forAccount);

		const positionResponse = await sdk.get(
			`user-ratings/${ratingId}?position=${positionId}`,
			{},
			{},
			headers
		);

		if (!positionResponse.ok) {
			throw positionResponse;
		}

		const rating = await positionResponse.json();

		dispatch(setRatingScores({ rating, positionId }));
		dispatch(setPositionScores({ rating }));
		return rating;
	};

export const fetchSingleRating =
	(ratingId, forAccount = null) =>
	async (dispatch) => {
		if (!ratingId) {
			throw 'Must have a rating id!';
		}

		const headers = forAccountHeaders(forAccount);

		const ratingResponse = await sdk.get(
			`user-ratings/${ratingId}?with-input=1`,
			{},
			{},
			headers
		);
		const rating = await ratingResponse.json();

		if (!ratingResponse.ok) {
			dispatch(triggerError(ratingResponse, rating));
		}

		const { userId, suggestedPositionId } = rating;

		const position = await fetchRatingPositionScores(
			ratingId,
			suggestedPositionId,
			forAccount
		)(dispatch);

		rating.ratingScores = position.ratingScores;

		batch(() => {
			dispatch(addSingleUserRating(rating, userId));

			const initItems = {
				rating,
				positionId: suggestedPositionId,
			};

			dispatch(setUserRatingItems({ rating }));
			dispatch(setPositionScores({ rating }));
			dispatch(setRatingScores(initItems));

			dispatch(setCurrentPosition(suggestedPositionId));
		});
	};

export const changePosition =
	(positionId, ratingId) => async (dispatch, getState) => {
		const newEntities = [];
		const {
			comparisonRatingId: id,
			currentPosition,
			entities,
			ratingScores,
			error,
		} = getState().ratings;
		if (positionId === currentPosition) {
			return;
		}

		dispatch(setPositionFetchingStatus(true));
		dispatch(setCurrentPosition(positionId));

		let newPosition = null;

		if (!ratingScores[ratingId]?.length) {
			const positionResponse = await sdk.get(
				`user-ratings/${ratingId}?position=${positionId}`
			);

			newPosition = await positionResponse.json();

			if (!newPosition.ratingScores) {
				newPosition.ratingScores = [];
			}

			const newItems = {
				rating: {
					...entities[ratingId],
					...newPosition,
				},
				positionId,
			};

			dispatch(setRatingScores(newItems));
		}

		if (!id && newPosition) {
			dispatch(setCurrentPosition(positionId));
			dispatch(setPositionFetchingStatus(false));
			dispatch(addRating(newPosition));
			return;
		}

		if (!id && !newPosition) {
			dispatch(setCurrentPosition(positionId));
			dispatch(setPositionFetchingStatus(false));
			return;
		}

		if (newPosition) {
			newEntities.push(newPosition);
		}

		if (error.id === id) {
			dispatch(setCurrentPosition(positionId));
			dispatch(setPositionFetchingStatus(false));
			return;
		}

		if (!ratingScores[id][positionId]?.length) {
			const comparisonResponse = await sdk.get(
				`user-ratings/${id}?position=${positionId}`
			);

			if (!comparisonResponse.ok) {
				return dispatch(setRatingsError(id, comparisonResponse));
			}

			const comparisonRating = await comparisonResponse.json();

			if (!comparisonRating.ratingScores) {
				comparisonRating.ratingScores = [];
			}

			const comparisonItems = {
				rating: {
					...entities[id],
					...comparisonRating,
				},
				positionId,
			};

			dispatch(setRatingScores(comparisonItems));

			newEntities.push(comparisonRating);
		}

		if (newEntities.length) {
			dispatch(addRatings(newEntities));
		}

		dispatch(setCurrentPosition(positionId));
		dispatch(setPositionFetchingStatus(false));
	};

export const fetchComparisonRating =
	(id, forAccount) => async (dispatch, getState) => {
		const headers = forAccountHeaders(forAccount);

		const { currentPosition: positionId } = getState().ratings;
		const ratingResponse = await sdk.get(
			`user-ratings/${id}?with-input=1`,
			{},
			{},
			headers
		);

		if (!ratingResponse.ok) {
			throw ratingResponse;
		}
		const rating = await ratingResponse.json();

		const positionResponse = await sdk.get(
			`user-ratings/${id}?position=${positionId}`,
			{},
			{},
			headers
		);

		if (!positionResponse.ok) {
			throw positionResponse;
		}

		const position = await positionResponse.json();

		rating.ratingScores = position.ratingScores;

		if (!position.ratingScores?.length) {
			rating.ratingScores = [];
		}

		dispatch(addRating(rating));

		const item = { rating, positionId };
		dispatch(setUserRatingItems({ rating }));
		dispatch(setPositionScores({ rating }));
		dispatch(setRatingScores(item));

		dispatch(setComparisonRatingId(id));
	};

export const saveNewUserRating =
	(userId, requestObject, forAccount) => async (dispatch) => {
		const response = await ratingsService.saveRating(
			userId,
			requestObject,
			forAccount
		);

		const result = await response.json();

		dispatch(addSingleUserRating(result, result.userId));

		return result;
	};
