import { ReactNode } from 'react';

export const symbol_view_in_ar: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M448-167 228-296q-13.775-8.426-21.387-22.213Q199-332 199-348v-257q0-16 7.613-29.787Q214.225-648.574 228-657l221-131q14-8 31-8t31 8l221 131q13.775 8.426 21.388 22.213Q761-621 761-605v257q0 16-7.875 29.787Q745.25-304.426 731-296L508-167q-14.328 8-30.164 8Q462-159 448-167Zm2-69v-224L260-569v219l190 114Zm60 0 191-114v-219L510-460v224ZM109.825-661Q97-661 88.5-669.625T80-691v-129q0-24.75 17.625-42.375T140-880h129q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T269-820H140v129q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM140-80q-24.75 0-42.375-17.625T80-140v-129q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-269v129h129q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q299-97 290.375-88.5T269-80H140Zm680 0H691q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T691-140h129v-129q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T880-269v129q0 24.75-17.625 42.375T820-80Zm0-611v-129H691q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T691-880h129q24.75 0 42.375 17.625T880-820v129q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T820-691ZM480-514l190-110-190-109-190 109 190 110Zm0 25Zm0-25Zm30 54Zm-60 0Z"
		/>
	</svg>
);
