import { ReactNode } from 'react';

export const symbol_engineering: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M42-150v-62q0-34 16-56.5t45-36.5q54-26 115.5-43T358-365q78 0 139.5 17T613-305q29 14 45 36.5t16 56.5v62q0 13-8.5 21.5T644-120H72q-13 0-21.5-8.5T42-150Zm60-30h512v-32q0-15-8.5-24.5T585-251q-43-19-96-36.5T358-305q-78 0-131 17.5T131-251q-12 5-20.5 14.5T102-212v32Zm256-245q-66 0-108-43t-42-107h-10q-8 0-14-6t-6-14q0-8 6-14t14-6h10q0-40 20-72t52-52v39q0 6 4.5 10.5T295-685q7 0 11-4.5t4-10.5v-52q8-2 22-3.5t27-1.5q13 0 27 1.5t22 3.5v52q0 6 4 10.5t11 4.5q6 0 10.5-4.5T438-700v-39q32 20 51 52t19 72h10q8 0 14 6t6 14q0 8-6 14t-14 6h-10q0 64-42 107t-108 43Zm0-60q42 0 66-25t24-65H268q0 40 24 65t66 25Zm301 105-1-10q-7-4-14.5-9T630-409l-11 6q-7 4-13.5 2t-11.5-8l-1-2q-5-7-3.5-14.5T597-438l11-8q-2-4-2-7.5v-15q0-3.5 2-7.5l-11-8q-6-5-7.5-12t3.5-14l1-3q5-6 11.5-8t13.5 2l11 6 14-10q7-5 14-9l1-11q1-8 6.5-13t13.5-5h2q8 0 13.5 5.5T701-542l1 10q7 4 14 9l14 10 11-6q7-4 13.5-2t11.5 8l1 2q5 7 3.5 14.5T763-484l-11 8q2 4 2 7.5v15q0 3.5-2 7.5l11 8q6 5 7.5 12t-3.5 14l-1 3q-5 6-11.5 8t-13.5-2l-11-6q-6 5-13.5 10t-14.5 9l-1 11q-1 8-6.5 13t-13.5 5h-2q-8 0-13.5-5.5T659-380Zm21-43q16 0 27-11t11-27q0-16-11-27t-27-11q-16 0-27 11t-11 27q0 16 11 27t27 11Zm84-169-5-21q-10-4-20.5-11T721-639l-28 10q-7 2-13.5 0t-10.5-8l-4-6q-4-7-2.5-14t7.5-12l22-17q-2-5-3.5-11t-1.5-12q0-6 1.5-12t3.5-11l-22-17q-6-5-7.5-12.5T665-775l4-5q4-6 10.5-8.5t13.5-.5l28 10q7-8 17.5-15.5T759-805l5-21q2-6 7-10t11-4h10q6 0 11 4t7 10l5 21q10 3 20.5 10.5T853-779l28-10q7-2 13.5 0t10.5 8l4 6q4 7 2.5 14t-7.5 12l-22 17q2 5 3.5 11t1.5 12q0 6-1.5 12t-3.5 11l22 17q6 5 7.5 12.5T909-643l-4 5q-4 6-10.5 8.5t-13.5.5l-28-10q-7 8-17.5 15T815-613l-5 21q-2 6-7 10t-11 4h-10q-6 0-11-4t-7-10Zm23-59q25 0 41.5-16.5T845-709q0-25-16.5-41.5T787-767q-25 0-41.5 16.5T729-709q0 25 16.5 41.5T787-651ZM358-180Z"
		/>
	</svg>
);
