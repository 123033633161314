import { ReactNode } from 'react';

export const symbol_sd_card_alert: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M220-80q-24 0-42-18t-18-42v-476q0-12.444 5-23.722T178-659l203-203q8-8 19.278-13 11.278-5 23.722-5h316q24 0 42 18t18 42v680q0 24-18 42t-42 18H220Zm0-60h520v-680H426L220-613.875V-140Zm260-149q14 0 24.5-10.5T515-324q0-14-10.5-24.5T480-359q-14 0-24.5 10.5T445-324q0 14 10.5 24.5T480-289Zm2.175-143q12.825 0 21.325-8.625T512-462v-145q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T452-607v145q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625ZM220-140h520-520Z"
		/>
	</svg>
);
