import { ReactNode } from 'react';

export const symbol_texture_minus: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M129-131q-8-8-9.5-20t9.5-23l659-656q11-11 22.852-9.538 11.852 1.461 20 9.5Q839-822 840.5-810t-9.5 23L172-131q-10.889 11-22.944 9.5Q137-123 129-131Zm481-49q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T610-240h240q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-180H610ZM129-450q-8-8-9.5-20t9.5-23l338-338q10.889-11 22.944-9.5Q502-839 510-831q8 8 9.5 20.056Q521-798.889 510-788L172-450q-10.889 11-22.944 9.5Q137-442 129-450Zm544 141q-10.889 11-22.944 9.5Q638-301 630-309q-8-8-9.5-20.056Q619-341.111 630-352l158-158q11-11 23-9.5t20 9.5q8 8 9.5 20.056Q842-477.889 831-467L673-309ZM146-744q-7 7-16.5 3.458Q120-744.083 120-754v-55.82q0-13.18 8.625-21.68T150-840h56q9.917 0 13.458 9.5Q223-821 216-814l-70 70Zm325.825 624Q459-120 450-128.675q-9-8.676-9-21.5Q441-163 450-172t21.825-9q12.824 0 21.5 9Q502-163 502-150.175q0 12.824-8.675 21.5-8.676 8.675-21.5 8.675Z"
		/>
	</svg>
);
