const icon = {
	name: 'drawing-circle',
	objects: [
		{
			type: 'circle',

			attributes: {
				cx: 12.675,
				cy: 12.1099,
				r: 6.96905,
				stroke: 'currentColor',
				fill: 'none',
				strokeWidth: 1.3,
			},
		},
	],
};

export default icon;
