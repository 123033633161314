import { ReactNode } from 'react';

export const symbol_add_ad: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M725-295H620q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T620-355h105v-105q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T785-460v105h105q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T890-295H785v105q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T725-190v-105ZM100-120q-24 0-42-18t-18-42v-615q0-24 18-42t42-18h615q24 0 42 18t18 42v215q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T715-580v-75H100v475h535q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T635-120H100Zm0-595h615v-80H100v80Zm0 0v-80 80Z"
		/>
	</svg>
);
