import { t } from '@transifex/native';
import { Fragment, useEffect } from 'react';

import { useCollection } from 'pkg/api/use_collection';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useEndpoint } from 'pkg/api/use_endpoint';

import ListItemCopy from 'routes/post/list_copies/ListItemCopy';

import Pagination from 'components/pagination';

import { useTemplateContext } from 'components/layout/page-templates/context';

import * as Table from 'design/table';

interface IndexProps {
	postId: number;
}
interface PageProps {
	id: number;
}

const CopiesTable = ({ postId }: IndexProps): JSX.Element => {
	const { setPageTitle } = useTemplateContext();
	const { records, isLoading, pagination, removeRecord, sort } =
		useCollection<models.groupPost.GroupPost>(
			endpoints.GroupPosts.ListCopies(postId),
			{
				defaultSortBy: 'groupName',
				defaultSortByOrder: 'asc',
			}
		);
	const { record: post } = useEndpoint<models.groupPost.GroupPost>(
		endpoints.GroupPosts.Show(postId)
	);

	useEffect(() => {
		setPageTitle(t(`View Recipients for: {title}`, { title: post.title }));
	}, [post?.title]);

	const tableColumns = [
		{
			content: t(`Group Name`),
			sortKey: 'groupName',
		},
		{
			content: t(`Recipient Count`),
			width: 'min-content',
			sortKey: 'recipientCount',
			align: 'right',
		},
		{
			content: t(`Views`),
			width: 'min-content',
			sortKey: 'viewCount',
			align: 'right',
		},
		{
			content: t(`Likes`),
			width: 'min-content',
			sortKey: 'likeCount',
			align: 'right',
		},
		{
			content: t(`Comments`),
			width: 'min-content',
			sortKey: 'commentCount',
			align: 'right',
		},
		{
			content: t(`Percentage Seen`),
			width: 'min-content',
			sortKey: 'percentageSeen',
			align: 'right',
		},
		{
			content: '',
			width: 'min-content',
		},
	];

	return (
		<Fragment>
			<Table.Table
				sortBy={sort.column}
				sortOrder={sort.order}
				onSort={sort.setSort}
				isLoading={isLoading}
				columns={tableColumns}>
				{records.map((groupPost: models.groupPost.GroupPost) => (
					<ListItemCopy
						groupPost={groupPost}
						key={groupPost.id}
						remove={removeRecord}
					/>
				))}
			</Table.Table>
			<Pagination {...pagination} />
		</Fragment>
	);
};

const Page = ({ id }: PageProps): JSX.Element => {
	return (
		<Fragment>
			<CopiesTable postId={id} />
		</Fragment>
	);
};

export default Page;
