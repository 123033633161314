import { ReactNode } from 'react';

export const symbol_grid_goldenratio: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M377-377H110q-12.75 0-21.375-8.675Q80-394.351 80-407.175 80-420 88.625-428.5T110-437h267v-87H110q-12.75 0-21.375-8.675Q80-541.351 80-554.175 80-567 88.625-575.5T110-584h267v-266q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T437-850v266h87v-266q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T584-850v266h266q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-524H584v87h266q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-377H584v267q0 12.75-8.675 21.375Q566.649-80 553.825-80 541-80 532.5-88.625T524-110v-267h-87v267q0 12.75-8.675 21.375Q419.649-80 406.825-80 394-80 385.5-88.625T377-110v-267Zm60-60h87v-87h-87v87Z"
		/>
	</svg>
);
