import { ReactNode } from 'react';

export const symbol_double_arrow: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M452-480 277-713q-11-14-3-30.5t27-16.5q7 0 13 3.5t11 8.5l201 268-201 268q-5 5-11 8.5t-13 3.5q-18 0-26-16t3-31l174-233Zm252 0L529-713q-11-14-3-30.5t27-16.5q7 0 13 3.5t11 8.5l201 268-201 268q-5 5-11 8.5t-13 3.5q-18 0-26-16t3-31l174-233Z"
		/>
	</svg>
);
