import { useState } from 'react';

type ToggleCallback = () => void;

export default function useToggleState(
	defaultToggleState: boolean = false
): [boolean, ToggleCallback, ToggleCallback] {
	const [state, setState] = useState<boolean>(defaultToggleState);

	const on = () => setState(true);
	const off = () => setState(false);

	return [state, on, off];
}
