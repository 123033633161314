import { ReactNode } from 'react';

export const symbol_bathtub: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M273.08-599q-30.08 0-51.58-21.42-21.5-21.421-21.5-51.5 0-30.08 21.42-51.58 21.421-21.5 51.5-21.5 30.08 0 51.58 21.42 21.5 21.421 21.5 51.5 0 30.08-21.42 51.58-21.421 21.5-51.5 21.5ZM185.825-80Q168-80 154-91.5T140-120q-24.75 0-42.375-17.625T80-180v-230q0-12.75 8.625-21.375T110-440h90v-27.794Q200-503 225-528t60.379-25Q304-553 321-546q17 7 30 21l52 59q7 8 14.5 14.5T433-440h307v-334q0-18.941-12.5-32.471Q715-820 696-820q-9.882 0-18.941 2T661-809l-52 52q5 17 1.5 34.5T598-689l-102-97q16.435-10.929 35.217-13.964Q550-803 568-796l52-51q15-15 34.539-24T696-880q43.789 0 73.895 31Q800-818 800-774v334h50q12.75 0 21.375 8.625T880-410v230q0 24.75-17.625 42.375T820-120q0 17-14 28.5T773-80H185.825ZM140-180h680v-200H140v200Zm0 0h680-680Z"
		/>
	</svg>
);
