import { ReactNode } from 'react';

export const symbol_sensors_krx: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-480q0 45 11 86t31 77q6 11 4 23.5T173-274q-11 8-23.5 4.5T131-284q-25-44-38-93T80-480q0-54 13-102.5t38-92.5q7-11 19-15t23 4q11 7 13 19.5t-4 23.5q-20 36-31 77t-11 86Zm340 240q-100 0-170-70t-70-170q0-100 70-170t170-70q100 0 170 70t70 170q0 100-70 170t-170 70Zm0-60q75 0 127.5-52.5T660-480q0-75-52.5-127.5T480-660q-75 0-127.5 52.5T300-480q0 75 52.5 127.5T480-300Zm339-180q0-45-11-86t-31-77q-6-11-4-23.5t13-19.5q11-8 23-4t19 15q25 44 38 92.5T879-480q0 54-13 103t-38 93q-7 11-19 14.5t-23-4.5q-11-7-13-19.5t4-23.5q20-36 31-77t11-86Zm-339 0Z"
		/>
	</svg>
);
