import styled from 'styled-components';

import * as palette from 'pkg/config/palette';

const Logo = (props) => (
	<svg {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M20.8104 37.5369L22.5527 29.4595H26.1677C27.436 29.4595 28.3725 30.4766 28.3725 31.6998C28.3725 32.8985 27.5783 34.6545 25.1011 34.6545H23.1216L22.4935 37.5369H20.8104V37.5369ZM25.2078 33.1411C26.0494 33.1411 26.6183 32.596 26.6183 31.8817C26.6183 31.3487 26.2152 30.9732 25.6344 30.9732H23.916L23.4418 33.1411H25.2078V33.1411Z"
			fill={palette.white}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M27.2943 37.5376L29.0366 29.4602H30.5418L28.7996 37.5376H27.2943"
			fill={palette.white}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M34.4298 32.0932L34.584 31.3181H36.1011L34.8329 37.1674H33.3157L33.4817 36.4408C32.9958 37.022 32.4504 37.3127 31.7631 37.3127C30.4711 37.3127 29.5823 36.4408 29.5823 34.8906C29.5823 33.1224 30.6607 31.1727 32.6047 31.1727C33.3751 31.1729 34.0504 31.512 34.4298 32.0932V32.0932ZM31.1581 34.576C31.1581 35.4118 31.7035 35.9446 32.4266 35.9446C32.9598 35.9446 33.4339 35.6418 33.7422 35.2422L34.1809 33.2196C33.9319 32.8201 33.4459 32.5415 32.8414 32.5415C31.8577 32.5415 31.1581 33.4982 31.1581 34.576V34.576Z"
			fill={palette.white}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M35.5781 38.0632C35.7086 38.1238 35.9456 38.1479 36.1587 38.1479C36.4672 38.1479 36.6686 38.051 36.8701 37.7483L37.2257 37.1911L36.2063 31.3177H37.7707L38.3754 35.3869L40.7695 31.3177H42.4289L38.2568 38.1238C37.6287 39.1652 36.9885 39.5407 36.0403 39.5407C35.7086 39.5407 35.353 39.4923 35.0803 39.3953L35.5781 38.0632"
			fill={palette.white}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M41.1719 34.6848C41.1719 32.7836 42.5704 31.1727 44.4787 31.1727C45.9482 31.1727 47.0506 32.1415 47.0506 33.7765C47.0506 34.1276 46.9795 34.5637 46.9321 34.7453H42.6772C42.6772 34.7818 42.6772 34.8543 42.6772 34.8907C42.6772 35.3872 43.1867 36.0532 44.2533 36.0532C44.7632 36.0532 45.344 35.9078 45.6994 35.6294L46.1615 36.7072C45.6045 37.0947 44.8459 37.3128 44.1349 37.3128C42.3334 37.3128 41.1719 36.3196 41.1719 34.6848V34.6848ZM45.6996 33.6794C45.7116 33.6553 45.7116 33.5825 45.7116 33.5584C45.7116 32.965 45.2847 32.432 44.3841 32.432C43.5661 32.432 42.9617 33.0377 42.8429 33.6794H45.6996V33.6794Z"
			fill={palette.white}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M46.5958 37.1668L47.8643 31.3175H49.3693L49.2036 32.0804C49.7013 31.5234 50.3175 31.1721 51.2302 31.1721L50.8984 32.6981C50.8036 32.6739 50.59 32.6254 50.3531 32.6254C49.796 32.6254 49.2625 32.9404 48.9307 33.3278L48.101 37.1667H46.5958V37.1668"
			fill={palette.white}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.7142 24.8132L13.9981 22.7007C15.0077 24.322 17.0271 25.1324 18.7099 25.1324C20.585 25.1324 21.5467 24.1499 21.5467 22.8726C21.5467 21.9145 20.8255 21.1532 18.6138 21.1532C17.9406 21.1532 16.931 21.1778 16.6905 21.2023L17.3636 18.0583C17.6282 18.0829 18.9985 18.0829 19.6234 18.0829C21.4023 18.0829 22.7486 17.567 22.7486 16.1914C22.7486 15.1105 21.5708 14.3984 19.5753 14.3984C18.205 14.3984 16.8107 14.816 15.6808 15.7248L14.4547 13.3422C15.777 12.114 17.7482 11.3036 19.9118 11.3036C23.7342 11.3036 26.2586 12.8756 26.2586 15.5775C26.2586 17.7882 24.0228 19.3602 21.9313 19.6303C23.59 20.0478 24.9363 21.1287 24.9363 23.2166C24.9363 26.1149 22.1716 28.2275 18.734 28.2275C15.801 28.2274 12.9163 26.9993 11.7142 24.8132"
			fill={palette.white}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M25.3923 21.8908C25.3923 16.5852 28.4695 11.3041 34.0227 11.3041C36.3788 11.3041 38.1578 12.1638 39.3839 13.5148L37.2683 15.9956C36.523 14.964 35.2248 14.399 33.8066 14.399C31.4505 14.399 29.9359 16.4132 29.311 18.4275C29.2149 18.6485 29.1426 18.8941 29.0947 19.0661C29.9603 18.1573 31.571 17.3468 33.2536 17.3468C36.1144 17.3468 38.3504 19.0661 38.3504 21.8664C38.3504 25.3542 35.3453 28.2282 31.691 28.2282C27.7483 28.2278 25.3923 25.8945 25.3923 21.8908V21.8908ZM34.7684 22.3081C34.7684 20.9816 33.398 20.1465 31.6911 20.1465C30.5613 20.1465 29.5516 20.6378 28.7581 21.3747C28.7343 21.5712 28.7343 21.9886 28.7343 22.2344C28.7343 24.0029 29.9844 25.1327 31.6911 25.1327C33.446 25.1327 34.7684 23.8555 34.7684 22.3081V22.3081Z"
			fill={palette.white}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M38.6537 21.6199C38.6537 16.3144 41.8991 11.3035 46.6351 11.3035C50.1207 11.3035 52.4047 13.6615 52.4047 17.8864C52.4047 23.1919 49.1833 28.2274 44.4475 28.2274C40.9614 28.2274 38.6537 25.8448 38.6537 21.6199V21.6199ZM48.9429 17.7143C48.9429 15.7738 48.1737 14.3982 46.2985 14.3982C43.6301 14.3982 42.1156 18.6231 42.1156 21.7917C42.1156 23.7323 42.8849 25.1322 44.7599 25.1322C47.4285 25.1322 48.9429 20.8829 48.9429 17.7143V17.7143Z"
			fill={palette.white}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M53.2502 31.7693C52.2724 31.7693 51.4788 30.9584 51.4788 29.9595C51.4788 28.9523 52.2724 28.1497 53.2502 28.1497C54.236 28.1497 55.0213 28.9523 55.0213 29.9595C55.0213 30.9582 54.2361 31.7693 53.2502 31.7693V31.7693ZM53.2506 28.3974C52.4065 28.3974 51.7216 29.0803 51.7216 29.9595C51.7216 30.8132 52.4067 31.5216 53.2506 31.5216C54.0944 31.5216 54.7795 30.8132 54.7795 29.9595C54.7796 29.0805 54.0944 28.3974 53.2506 28.3974V28.3974ZM53.7345 31.0008L53.2081 30.1813H52.8406V31.0008H52.5732V28.9263H53.4001C53.7426 28.9263 54.0436 29.1738 54.0436 29.5581C54.0436 30.019 53.6425 30.1727 53.5255 30.1727L54.0684 31.0008H53.7345V31.0008ZM53.4006 29.1657H52.8409V29.934H53.4006C53.5763 29.934 53.7684 29.7719 53.7684 29.5583C53.7683 29.3279 53.5762 29.1657 53.4006 29.1657V29.1657Z"
			fill={palette.white}
		/>
		<path
			d="M32.7099 6.98899C30.8084 4.69365 28.3797 2.89265 25.631 1.73983C22.8824 0.587012 19.8955 0.116628 16.9256 0.368859C13.9557 0.621089 11.0909 1.58844 8.57603 3.18828C6.06116 4.78812 3.97089 6.97291 2.48377 9.55604C0.99665 12.1392 0.156866 15.0439 0.0361499 18.022C-0.084566 21.0002 0.517373 23.9633 1.79055 26.6584C3.06373 29.3534 4.97032 31.7002 7.34746 33.4983C9.7246 35.2964 12.5017 36.4925 15.4414 36.9842L15.9481 33.9551C13.4973 33.5451 11.1821 32.548 9.20025 31.0489C7.21844 29.5498 5.62893 27.5933 4.56749 25.3465C3.50604 23.0997 3.00421 20.6293 3.10485 18.1464C3.20549 15.6635 3.90561 13.2419 5.14542 11.0884C6.38523 8.93482 8.12787 7.11337 10.2245 5.7796C12.3211 4.44582 14.7095 3.63935 17.1855 3.42906C19.6615 3.21878 22.1516 3.61094 24.4431 4.57203C26.7347 5.53313 28.7595 7.03461 30.3448 8.94823L32.7099 6.98899Z"
			fill={palette.brand}
		/>
	</svg>
);

Logo.defaultProps = {
	xmlns: 'http://www.w3.org/2000/svg',
	width: '56',
	height: '40',
	viewBox: '0 0 56 40',
};

export default styled(Logo)``;
