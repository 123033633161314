import { t } from '@transifex/native';
import { Share } from '@capacitor/share';

import * as models from 'pkg/api/models';
import { useShare } from 'pkg/share';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as api from 'pkg/api';

import Button from 'routes/public/styles/Button';

interface InviteParentProps {
	account: models.account.Account;
	wardAccount: models.account.Account;

	handleGoToEmailInvite: () => void;
}

export default function InviteParent({
	account,
	wardAccount,
	handleGoToEmailInvite,
}: InviteParentProps) {
	const share = useShare({});

	const handleClick = async () => {
		// First we check if we have native share
		if (!share.canShare) {
			// If we can't go to email invite modal
			handleGoToEmailInvite();
			return;
		}

		// Now we create the invite link
		const [request, response] =
			await api.post<models.accountInvite.AccountInvite>(
				endpoints.AccountInvites.Create(),
				{
					targets: [
						{
							type: 'child',
							targetId: wardAccount.id,
						},
					],
				}
			);

		if (!request.ok) {
			// Add error handling
			return;
		}

		// When we have the inviteLink ready we set it to the context
		const inviteKey = `https://app.360Player.com/claim/${response.key}`;

		await Share.share({
			title: t('You have been invited to 360Player.'),
			text: t(
				'{parentName} has invited you to connect to {wardName} in 360Player! Use the link to create your account and connect!',
				{
					parentName: account ? models.account.fullName(account) : '',
					wardName: wardAccount ? models.account.fullName(wardAccount) : '',
				}
			),
			url: inviteKey,
			dialogTitle: t('You have been invited to 360Player.'),
		});
	};

	return (
		<Button
			transparent
			icon="user-add"
			label={t('Invite another parent')}
			onClick={handleClick}
		/>
	);
}
