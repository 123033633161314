import { ReactNode } from 'react';

export const symbol_nest_protect: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M315-120q-81 0-138-57t-57-138v-330q0-81.9 57-138.45Q234-840 315-840h330q81.9 0 138.45 56.55Q840-726.9 840-645v330q0 81-56.55 138T645-120H315Zm0-60h330q57.375 0 96.188-38.812Q780-257.625 780-315v-330q0-57.375-38.812-96.188Q702.375-780 645-780H315q-57.375 0-96.188 38.812Q180-702.375 180-645v330q0 57.375 38.812 96.188Q257.625-180 315-180Zm165-105q-81 0-138-57t-57-138.5q0-81.5 57-138T480.5-675q81.5 0 138 56.55T675-480q0 81-56.55 138T480-285Zm0-60q57.375 0 96.188-38.812Q615-422.625 615-480t-38.812-96.188Q537.375-615 480-615t-96.188 38.812Q345-537.375 345-480t38.812 96.188Q422.625-345 480-345Z"
		/>
	</svg>
);
