import { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import * as breakpoints from 'pkg/config/breakpoints';

import { range } from 'pkg/utils';

import NativeTimePicker from 'components/NativeTimePicker';

import Select from 'components/form/Select';

/* TimePicker Styled Components */

const TimePickerWrapper = styled.div`
	margin-bottom: 20px;
	width: ${(props) => (props.fullwidth ? '100%' : '60%')};
	display: grid;
	align-items: start;
	justify-content: center;
	grid-column-gap: 5px;
	grid-template-columns: ${(props) =>
		props.suffix ? '3fr 3fr 2fr' : '1fr 1fr'};
	grid-template-rows: auto;

	${Select} {
		min-width: 65px;
	}

	@media ${breakpoints.small} {
		width: 100%;
	}
`;

/* TimePicker Component */

const NOOP = () => {};

class TimeValuePicker extends Component {
	static propTypes = {
		range: PropTypes.array.isRequired,
		value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		onChange: PropTypes.func,
		transform: PropTypes.func,
		fullwidth: PropTypes.bool,
	};

	static defaultProps = {
		onChange: NOOP,
		onBlur: NOOP,
		transform: (n) => n,
		fullwidth: false,
	};

	get componentProperties() {
		const { value } = this.props;
		const onChange = (event) => this.props.onChange(event.currentTarget.value);

		return {
			value,
			onChange,
			className: 'input',
		};
	}

	get options() {
		return this.props.range.map((n) => {
			return (
				<option key={`time-picker-value-${n}`} value={n}>
					{this.props.transform(n)}
				</option>
			);
		});
	}

	render() {
		return <Select {...this.componentProperties}>{this.options}</Select>;
	}
}

export default class extends Component {
	canRenderNativePicker = false;

	static propTypes = {
		locale: PropTypes.string,
		localeAM: PropTypes.string,
		localePM: PropTypes.string,
		hourFormat: PropTypes.oneOf(['12h', '24h']),
		selectedDate: PropTypes.instanceOf(Date),
		onDateChange: PropTypes.func,
	};

	static defaultProps = {
		locale: 'en-US',
		localeAM: 'AM',
		localePM: 'PM',
		hourFormat: '24h',
		selectedDate: new Date(),
		onDateChange: NOOP,
	};

	constructor(newProps) {
		super(newProps);

		const testNode = document.createElement('input');
		testNode.type = 'time';

		// @NOTE Type will default to "text" on unsupported browsers
		if (testNode.type === 'time') {
			this.canRenderNativePicker = true;
		}
	}

	emitDateChange(selectedDate) {
		this.props.onDateChange(selectedDate);
	}

	get hourComponentProperties() {
		const prefer12h = this.props.hourFormat === '12h';
		const min = !prefer12h ? 0 : 1;
		const max = !prefer12h ? 23 : 12;
		const hourRange = range(min, max);

		let transform;
		let selectedHour = this.props.selectedDate.getHours();

		if (prefer12h && selectedHour > 12) {
			selectedHour -= 12;
		}

		if (selectedHour === 0 && prefer12h) {
			selectedHour = 12;
		}

		const onChange = (nextHour) => {
			nextHour = parseInt(nextHour, 10);
			this.onDateTimeHourChange(nextHour);
		};

		if (prefer12h) {
			transform = (n) => n;
		} else {
			transform = (n) => n.toString().padStart(2, '0');
		}

		return {
			range: hourRange,
			value: selectedHour,
			transform,
			onChange,
		};
	}

	onDateTimeHourChange(nextHour) {
		const { selectedDate } = this.props;
		const actualHour = selectedDate.getHours();
		const prefer12h = this.props.hourFormat === '12h';

		if (prefer12h && actualHour > 12) {
			nextHour += 12;
		}

		if (nextHour === 24) nextHour = 0;

		let nextDate = new Date(+this.props.selectedDate);
		nextDate.setHours(nextHour);

		this.emitDateChange(nextDate);
	}

	get minuteComponentProperties() {
		const minuteRange = range(0, 59).filter((n) => !(n % 5));

		// @NOTE Round down to neares 5 minutes
		const selectedMinute =
			Math.floor(this.props.selectedDate.getMinutes() / 5) * 5;

		const onChange = (nextMinute) => {
			nextMinute = parseInt(nextMinute, 10);
			this.onDateTimeMinuteChange(nextMinute);
		};

		const transform = (n) => n.toString().padStart(2, '0');

		return {
			range: minuteRange,
			value: selectedMinute,
			transform,
			onChange,
		};
	}

	onDateTimeMinuteChange(nextMinute) {
		const { selectedDate } = this.props;

		let nextDate = new Date(+selectedDate);
		nextDate.setHours(nextDate.getHours(), nextMinute, 0);

		this.emitDateChange(nextDate);
	}

	get meridiemComponentProperties() {
		const range = ['am', 'pm'];
		const selectedMeridiem =
			this.props.selectedDate.getHours() < 12 ? 'am' : 'pm';
		const prefer12h = this.props.hourFormat === '12h';

		const onChange = () => {
			let nextHour = this.props.selectedDate.getHours();

			if (prefer12h && nextHour >= 12) {
				nextHour -= 12;
			} else if (prefer12h && nextHour < 12) {
				nextHour += 12;
			}

			let nextDate = new Date(+this.props.selectedDate);
			nextDate.setHours(nextHour);

			this.emitDateChange(nextDate);
		};

		const transform = (meridiem) => {
			if (meridiem === 'am') {
				return this.props.localeAM;
			}

			return this.props.localePM;
		};

		return {
			range,
			onChange,
			transform,
			value: selectedMeridiem,
		};
	}

	get nativeComponentProperties() {
		return {
			selectedDate: this.props.selectedDate,
			onChange: (nextDateTime) => {
				let nextDate;
				if (!Number.isNaN(+this.props.selectedDate)) {
					nextDate = new Date(+this.props.selectedDate);
				} else {
					nextDate = new Date();
				}

				if (Number.isNaN(nextDateTime.getTime())) {
					nextDateTime = new Date();
				}

				if (Number.isNaN(nextDate.getTime())) {
					nextDate = new Date();
				}

				nextDate.setHours(
					nextDateTime.getHours(),
					nextDateTime.getMinutes(),
					0
				);

				this.emitDateChange(nextDate);
			},
			onBlur: this.props.onBlur,
		};
	}

	render() {
		if (this.canRenderNativePicker) {
			return <NativeTimePicker {...this.nativeComponentProperties} />;
		}

		return (
			<TimePickerWrapper
				suffix={this.props.hourFormat === '12h'}
				fullwidth={this.props.fullwidth}>
				<TimeValuePicker {...this.hourComponentProperties} />
				<TimeValuePicker {...this.minuteComponentProperties} />
				{this.props.hourFormat === '12h' ? (
					<TimeValuePicker {...this.meridiemComponentProperties} />
				) : null}
			</TimePickerWrapper>
		);
	}
}
