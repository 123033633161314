import { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { t } from '@transifex/native';

import * as breakpoints from 'pkg/config/breakpoints';
import * as palette from 'pkg/config/palette';

import { newActivity, updateActivity } from 'pkg/actions/activities';

import * as models from 'pkg/api/models';
import DateTime from 'pkg/datetime';
import rgba from 'pkg/rgba';
import * as sdk from 'pkg/core/sdk';
import { NOOP } from 'pkg/utils';
import { debounce } from 'pkg/timings';
import { useCurrentSport } from 'pkg/identity';
import { translatedRatingItemCategory } from 'pkg/api/models/user_rating';
import { physicalStrainLabel } from 'pkg/api/models/user_activity';

import CardBase, * as Card from 'components/Card';
import Icon from 'components/icon';
import ModalBase from 'components/modal';

import * as iconStyles from 'components/icon/styles.css';
import { Spinner } from 'components/loaders/spinner';
import DateTimePicker from 'components/form/DateTimePicker';
import TextArea from 'components/form/TextArea';
import Select from 'components/form/Select';

import Button from 'design/button';

const CardBody = styled(Card.Body)`
	margin: 0 auto;
`;

const FormRow = styled.div`
	position: relative;
	margin-bottom: 20px;

	label {
		margin-bottom: 10px;
		color: ${palette.black};
		display: block;
		font-weight: var(--font-weight-semibold);
		font-size: var(--font-size-base);
	}
`;

const DateTimePickerWrapper = styled.div`
	background: ${palette.gray[200]};
	border-radius: var(--radius-3);
	padding: 12px 16px 13px;
	margin: 0 auto;
	text-align: center;

	.${iconStyles.icon} {
		margin-right: 5px;
		margin-top: 1px;
	}
`;

const TypeButton = styled(Button)`
	margin-bottom: 0.5rem;
`;

const HintBlock = styled.div`
	color: #777;
	font-size: var(--font-size-sm);
	margin: 5px 0;
`;

const Thumb = css`
	width: 25px;
	height: 25px;
	margin-top: -8px;
	background: transparent;
	position: relative;
	z-index: 4;

	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.4);

	border-radius: 50%;
	box-sizing: border-box;
	cursor: pointer;
	appearance: none;

	background: radial-gradient(transparent 24%, #fff 24%);
	border: 1px solid rgba(0, 0, 0, 0.2);
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

	@media ${breakpoints.small} {
		width: 30px;
		height: 30px;
		margin-top: -10px;
	}
`;

export const RangeInput = styled.input`
	appearance: none;
	width: 100%;
	outline: none;
	background: transparent;
	border-style: none;
	padding: 0;
	height: auto;
	outline: none;
	border: 0;
	margin-left: 0;
	margin-right: 0;
	margin-bottom: 1rem;

	&::-moz-focus-outer {
		border: 0;
	}

	&::-webkit-slider-thumb {
		${Thumb};
	}

	&::-moz-range-thumb {
		${Thumb};
	}

	&::-ms-thumb {
		${Thumb} margin-top: -6px;
	}

	&::-webkit-slider-runnable-track {
		width: 100%;
		height: 10px;
		background: ${palette.gray[200]};
		box-shadow: inset 0 2px 4px ${rgba(palette.black, 0.05)};
		border-radius: var(--radius-3);
	}

	&::-moz-range-track {
		width: 100%;
		height: 10px;
		background: ${palette.gray[200]};
		box-shadow: inset 0 2px 4px ${rgba(palette.black, 0.05)};
		border-radius: var(--radius-3);
	}

	&::-ms-track {
		width: 100%;
		height: 10px;
		background: ${palette.gray[200]};
		box-shadow: inset 0 2px 4px ${rgba(palette.black, 0.05)};
		border-radius: var(--radius-3);
	}

	&::-ms-fill-lower,
	&::-ms-fill-upper {
		background: transparent;
	}

	&::-webkit-slider-runnable-track {
		background: linear-gradient(
			90deg,
			#0b78e3 10%,
			#05cf95 38%,
			#ffe800 64%,
			#ff8220 80%,
			#ff004a 88%
		);
		border-radius: var(--radius-3);
	}

	&::-moz-range-track {
		background: linear-gradient(
			90deg,
			#0b78e3 10%,
			#05cf95 38%,
			#ffe800 64%,
			#ff8220 80%,
			#ff004a 88%
		);
		border-radius: var(--radius-3);
	}

	&::-ms-track {
		background: linear-gradient(
			90deg,
			#0b78e3 10%,
			#05cf95 38%,
			#ffe800 64%,
			#ff8220 80%,
			#ff004a 88%
		);
		border-radius: var(--radius-3);
	}
`;

const ActivityTooltipText = styled.span`
	font-size: 11px;
	font-weight: var(--font-weight-normal);
	letter-spacing: 0.6px;
	color: #847676;
	line-height: 1;
	text-transform: uppercase;
`;

const ActivityTooltipWrapper = styled.div`
	position: absolute;
	left: ${(props) => (props.offset ? `${props.offset}%` : '0')};
	top: ${(props) => (props.show ? '-24px' : 0)};
	bottom: 0;
	background: ${palette.white};
	padding: 10px;
	height: 30px;
	max-width: 150px;
	min-width: 70px;
	text-align: center;
	transform: ${(props) => `translateX(-${props.horizontalOffset}%)`};
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3236);
	border-radius: 18px;
	overflow: hidden;
	opacity: ${(props) => (props.show ? '1' : 0)};
	z-index: ${(props) => (props.show ? '3' : 0)};
	pointer-events: none;
	transition:
		opacity 125ms ease-in-out,
		top 250ms ease-in-out,
		transform 50ms linear;
	user-select: none;
	white-space: nowrap;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const ActivityTooltip = ({ offset, show, horizontalOffset }) => (
	<ActivityTooltipWrapper
		offset={offset}
		show={show}
		horizontalOffset={horizontalOffset}>
		<ActivityTooltipText>{physicalStrainLabel(offset)}</ActivityTooltipText>
	</ActivityTooltipWrapper>
);

export const ActivityTooltipSlider = (props) => {
	return (
		<Fragment>
			<ActivityTooltip
				offset={props.offset}
				show={props.show}
				horizontalOffset={props.horizontalOffset}
			/>
			<RangeInput
				type="range"
				min="0"
				max="100"
				step="1"
				data-min-label={t('Low')}
				onChange={props.onChange}
				data-max-label={t('High')}
				value={props.value}
			/>
		</Fragment>
	);
};

// Form component to register a user activity.
class Form extends Component {
	static ACTIVITY_TYPE_INDIVIDUAL = 'individual';
	static ACTIVITY_TYPE_GROUP = 'group';
	static ACTIVITY_TYPE_MATCH = 'match';
	static ACTIVITY_TYPE_OTHER = 'other';

	typeOptions = [
		[Form.ACTIVITY_TYPE_GROUP, t(`Team training`)],
		[Form.ACTIVITY_TYPE_INDIVIDUAL, t(`Individual training`)],
		[Form.ACTIVITY_TYPE_MATCH, t(`Match`)],
		[Form.ACTIVITY_TYPE_OTHER, t(`Other activity`)],
	];
	_mounted = false;

	static propTypes = {
		userActivityId: PropTypes.number,
		asModal: PropTypes.bool,
		handleSuccess: PropTypes.func,
	};

	static defaultProps = {
		userActivityId: null,
		asModal: false,
		type: Form.ACTIVITY_TYPE_INDIVIDUAL,
		// Initial values
		description: '',
		physicalStrain: 50,
		completedAt: undefined,
		ratingItem: {
			id: undefined,
		},
		onClose: NOOP,
	};

	state = {
		isSaving: false,
		ratingItemOptions: [],
		description: '',
		type: Form.ACTIVITY_TYPE_INDIVIDUAL,
		physicalStrain: 50,
		completedAt: undefined,
		ratingItem: {
			id: undefined,
		},
		showTooltip: false,
	};

	constructor(props) {
		super(props);

		this.debounceTooltip = debounce(this.hideTooltip, 1000);
	}

	hideTooltip = () => {
		this.setState({ showTooltip: false });
	};

	componentDidMount() {
		this._mounted = true;
		this.fetchRatingItems();

		this.checkInitialValues(this.props);
	}

	componendDidUpdate() {
		this.checkInitialValues(this.props);
	}
	componentWillUnmount() {
		this._mounted = false;
	}

	fetchRatingItems = async () => {
		const response = await sdk.request('/rating-items', 'GET');
		const json = await response.json();

		if (!response.ok) {
			throw new Error('Could not fetch rating items.');
		}

		let ratingItem = { id: this.state.ratingItem.id };

		if (this.state.ratingItem.id === undefined) {
			ratingItem.id = json.records[0].id;
		}

		if (this._mounted) {
			this.setState({
				ratingItemOptions: json.records,
				ratingItem,
			});
		}
	};

	save = async (e) => {
		e.preventDefault();
		e.stopPropagation();

		this.setState({ isSaving: true });

		let ratingItemId = null;

		if (
			this.state.type === Form.ACTIVITY_TYPE_INDIVIDUAL &&
			models.sport.hasRatingsEnabled(this.props.groupSport)
		) {
			ratingItemId = this.state.ratingItem.id;
		}

		const data = {
			completedAt: this.state.completedAt,
			description: this.state.description,
			type: this.state.type,
			physicalStrain: this.state.physicalStrain,
			ratingItemId,
		};

		if (this.props.userActivityId !== null) {
			this.props.updateActivity(this.props.userActivityId, data);
		} else {
			this.props.newActivity(this.props.userId, data);
		}

		if (typeof this.props.handleSuccess === 'function') {
			this.props.handleSuccess();
		}

		if (this._mounted) {
			this.setState({
				isSaving: false,
				description: '',
				ratingItem: {
					id: undefined,
				},
			});
		}

		this.props.onClose();
	};

	checkInitialValues(props) {
		let state = this.state;
		state.ratingItem.id =
			props.ratingItem?.id == null ? undefined : props.ratingItem.id;
		state.description = props.description;
		state.type = props.type;
		state.physicalStrain = props.physicalStrain;

		if (props.completedAt) {
			state.completedAt = props.completedAt;
			state.completedAtDate = new Date(props.completedAt * 1000);
		} else {
			state.completedAt = Math.ceil(Date.now() / 1000);
			state.completedAtDate = new Date();
		}

		this.setState(state);
	}

	setDescription = (event) => {
		let state = this.state;
		state.description = event.target.value;
		this.setState(state);
	};

	setRatingItem = (event) => {
		let state = this.state;
		state.ratingItem.id = parseInt(event.target.value, 10);
		this.setState(state);
	};

	setPhysicalStrain = (event) => {
		let state = this.state;
		state.physicalStrain = parseInt(event.target.value, 10);
		state.showTooltip = true;
		this.setState(state, this.debounceTooltip);
	};

	get ratingItemSelector() {
		let groups = {};
		let items = this.state.ratingItemOptions;

		for (let i in items) {
			let item = items[i];
			let cat = translatedRatingItemCategory(item.categorySlug);

			if (!groups[cat]) {
				groups[cat] = [];
			}

			groups[cat].push(item);
		}

		let options = [];

		for (let g in groups) {
			options.push(
				<optgroup key={g} length={groups[g].length} label={g}>
					{groups[g].map((item, index) => {
						return (
							<option key={`item-option-${index}`} value={item.id}>
								{item.name}
							</option>
						);
					})}
				</optgroup>
			);
		}

		options.sort((a, b) => a.props.length - b.props.length);

		return (
			<Select
				onChange={this.setRatingItem}
				className="input"
				defaultValue={this.state.ratingItem.id}>
				{options}
			</Select>
		);
	}

	get isGroupActivity() {
		return this.state.type !== Form.ACTIVITY_TYPE_INDIVIDUAL;
	}

	handleDateChange = (date) => {
		this.setState({
			completedAt: Math.ceil(+date / 1000),
			completedAtDate: new Date(+date),
		});
	};

	get publishDateTimePicker() {
		const dateTimePickerProperties = {
			selectedDate: this.state.completedAtDate,
			onDateChange: this.handleDateChange,
		};

		let dateTime = new DateTime(this.state.completedAtDate);

		const toggleDateTimePicker = (e) => {
			e.preventDefault();

			let { dateTimePickerVisible } = this.state;
			dateTimePickerVisible = !dateTimePickerVisible;
			this.setState({ dateTimePickerVisible });
		};

		const dateTimePickerDefault = (
			<time key="dateTimePickerDefault" dateTime={dateTime.toString()}>
				{dateTime.toDateString()} {dateTime.toTimeString()}
			</time>
		);

		if (this.state.dateTimePickerVisible) {
			return (
				<FormRow>
					<DateTimePicker {...dateTimePickerProperties} />
				</FormRow>
			);
		}

		return (
			<DateTimePickerWrapper onClick={toggleDateTimePicker.bind(this)}>
				<Icon name="nav-events" />
				{dateTimePickerDefault}
			</DateTimePickerWrapper>
		);
	}

	get tooltip() {
		let horizontalOffset = 50;

		if (this.state.physicalStrain <= 5) {
			horizontalOffset = this.state.physicalStrain;
		} else if (this.state.physicalStrain >= 95) {
			horizontalOffset = 50 + this.state.physicalStrain / 2;
		}

		return (
			<ActivityTooltip
				offset={this.state.physicalStrain}
				show={this.state.showTooltip}
				horizontalOffset={horizontalOffset}
			/>
		);
	}

	get formContent() {
		let content = [];

		if (this.state.ratingItemOptions.length === 0 || this.state.isSaving) {
			return <Spinner />;
		}

		let descriptionHint = '';

		switch (this.state.type) {
			case Form.ACTIVITY_TYPE_INDIVIDUAL:
				descriptionHint = t(`E.g. ran intervals for 10 minutes`);
				break;
			case Form.ACTIVITY_TYPE_GROUP:
				descriptionHint = t(`E.g. team training`);
				break;
			case Form.ACTIVITY_TYPE_MATCH:
				descriptionHint = t(`E.g. match against Farham FC`);
				break;
			case Form.ACTIVITY_TYPE_OTHER:
				descriptionHint = t(
					`E.g. physical activity in school or other context`
				);
				break;
		}

		let ratingItemRow = (
			<FormRow>
				<label>{t(`What did you practice?`)}</label>
				{this.ratingItemSelector}
			</FormRow>
		);

		const showRatingItemRow =
			this.state.type === Form.ACTIVITY_TYPE_INDIVIDUAL &&
			models.sport.hasRatingsEnabled(this.props.groupSport);

		content.push(
			<CardBody $flex key="form-body">
				<FormRow>
					{this.typeOptions.map(([type, label]) => {
						const handleOnClick = () => this.setState({ type });
						return (
							<TypeButton
								block
								key={type}
								active={this.state.type === type}
								onClick={handleOnClick}
								testid={`activity.${type}`}>
								{label}
							</TypeButton>
						);
					})}
				</FormRow>

				{showRatingItemRow ? ratingItemRow : null}

				<FormRow>
					<label>{t(`What did you do?`)}</label>
					<TextArea
						onChange={this.setDescription}
						value={this.state.description}
						data-testid="activity.description_input"
					/>
					<HintBlock>{descriptionHint}</HintBlock>
				</FormRow>

				<FormRow>
					<label>{t('Physical strain')}</label>

					{this.tooltip}

					<RangeInput
						type="range"
						min="0"
						max="100"
						step="1"
						data-min-label={t('Low')}
						onChange={this.setPhysicalStrain}
						data-max-label={t('High')}
						value={this.state.physicalStrain}
					/>

					<HintBlock>
						{t(
							`High physical strain is for example high pulse and intense breathing or high lactic acid in muscles.`
						)}
					</HintBlock>
				</FormRow>

				<FormRow>
					<label>{t(`When did you do it?`)}</label>
					{this.publishDateTimePicker}
				</FormRow>
			</CardBody>
		);

		content.push(
			<Card.Footer key="form-footer">
				<Button primary large onClick={this.save} testid="step_modal.next">
					{!this.props.userActivityId ? t(`Register training`) : t('Save')}
				</Button>
			</Card.Footer>
		);

		return content;
	}

	onClose = async (p) => {
		await p;
		this.props.onClose();
	};

	get content() {
		let title = t(`Register individual training`);

		if (this.isGroupActivity === true) {
			title = t(`Team training`);
		}

		let content = (
			<ModalBase
				key="modal"
				onOpen={this.fetchRatingItems}
				onClose={this.onClose}>
				<CardBase key="card-as-modal">
					<Card.Header inverted>
						<Card.Heading>{title}</Card.Heading>
					</Card.Header>

					<Card.Divider />

					{this.formContent}
				</CardBase>
			</ModalBase>
		);

		return content;
	}

	render() {
		if (this.props.contentOnly) {
			return this.formContent;
		}

		return this.content;
	}
}

const actions = { newActivity, updateActivity };

function withSport(Component) {
	return function SportComponent(props) {
		const sport = useCurrentSport();
		return <Component {...props} groupSport={sport} />;
	};
}

export default connect(() => {}, actions)(withSport(Form));
