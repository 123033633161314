import { Fragment } from 'react';

import { PageWidths } from 'pkg/config/sizes';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import { useEndpoint } from 'pkg/api/use_endpoint';
import { useCurrentGroup } from 'pkg/identity';

import Content from 'routes/payments/orders/single/content';
import SideBar from 'routes/payments/orders/single/side_bar';

import { Spinner } from 'components/loaders/spinner';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import * as SideBarLayout from 'components/layout/side-bar-page';

interface SingleOrderProps {
	id: string;
	groupId: string;
}

const SingleOrder: React.FC<React.PropsWithChildren<SingleOrderProps>> = ({
	id,
}) => {
	const orderEndpoint = useEndpoint<models.order.Order>(
		endpoints.Orders.Show(Number.parseInt(id, 10))
	);

	const group = useCurrentGroup();

	if (orderEndpoint.isLoading) {
		return <Spinner />;
	}

	return (
		<Fragment>
			<SideBarLayout.Layout>
				<SideBar
					order={orderEndpoint.record}
					group={group}
					orderEndpoint={orderEndpoint}
				/>
				<SideBarLayout.Content>
					<LargeScreenContent.Inner maxWidth={PageWidths.WIDE}>
						<Content order={orderEndpoint.record} />
					</LargeScreenContent.Inner>
				</SideBarLayout.Content>
			</SideBarLayout.Layout>
		</Fragment>
	);
};

export default SingleOrder;
