import { Linkable } from 'pkg/api/models/linkable';
import { Dateable } from 'pkg/api/models/dateable';
import { Attachment } from 'pkg/api/models/attachment';
import { GroupPostComment } from 'pkg/api/models/group_post_comment';
import { User } from 'pkg/api/models/user';
import { Group } from 'pkg/api/models/group';
import { Record } from 'pkg/api/models/record';
import { Collection } from 'pkg/api/models/collection';

export interface GroupPost extends Linkable, Record, Dateable {
	groupPostParentId: number;

	content: string;
	title: string;
	status: string;
	isSticky: boolean;
	stickyUntil: number;
	commentsDisabled: boolean;
	notify: boolean;
	publishedAt: number;

	visibleToLegalGuardians: boolean;
	visibleToUsers: boolean;
	visibleToOrganization: boolean;
	isPublic: boolean;

	authorId: number;
	authorGroupId?: number;
	groupId: number;

	commentCount: number;
	likedByCurrentUser: boolean;
	recipientCount: number;
	viewCount: number;
	likeCount: number;

	viewedByCurrentUser: boolean;
	comments: Collection<GroupPostComment>;
	copyCount: number;

	likes: User[];
	usersViewed: User[];
	author: User;
	attachments: Attachment[];
	authorGroup: Group;
	group: Group;
}

export function isDraft(post: GroupPost): boolean {
	return post.status === 'draft';
}

export function isPublished(post: GroupPost): boolean {
	return post.status === 'published';
}

export function hasCopies(post: GroupPost): boolean {
	return post.groupPostParentId === post.id;
}
