import { ReactNode } from 'react';

export const symbol_language_us: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M220-280q-24.75 0-42.375-17.625T160-340v-310q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T220-650v310h160v-310q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T440-650v310q0 24.75-17.625 42.375T380-280H220Zm360 0q-24.75 0-42.375-17.625T520-340v-10q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T580-350v10h160v-110H580q-24.75 0-42.375-17.625T520-510v-110q0-24.75 17.625-42.375T580-680h160q24.75 0 42.375 17.625T800-620v10q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T740-610v-10H580v110h160q24.75 0 42.375 17.625T800-450v110q0 24.75-17.625 42.375T740-280H580Z"
		/>
	</svg>
);
