import { ReactNode } from 'react';

export const symbol_send_money: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M60-480q0 76 39.5 138.5T202-247q11 5 15.5 16t-.5 22q-5 11-16 16t-22 0Q94-235 47-313T0-480q0-88 46-165.5T175-765q12-6 24-2t17 16q5 11-.5 22T199-712q-63 32-101 94.5T60-480Zm500 320q-131 0-225.5-94.5T240-480q0-131 94.5-225.5T560-800q46 0 93.5 14.5T742-741q10 8 10.5 20t-7.5 20q-8 9-20 9.5t-23-6.5q-32-21-69.5-31.5T560-740q-107 0-183.5 76.5T300-480q0 107 76.5 183.5T560-220q35 0 72.5-10.5T702-261q11-7 23-6t20 9q8 8 7.5 20T742-219q-41 30-88.5 44.5T560-160Zm287-295H550q-11 0-19-8t-8-19q0-11 8-19t19-8h297l-62-62q-9-9-9-21t9-21q9-9 21.5-9t21.5 9l111 110q9 9 9 21t-9 21L828-350q-9 9-21 8.5t-21-9.5q-9-9-9-21.5t9-21.5l61-61Z"
		/>
	</svg>
);
