import { ReactNode } from 'react';

export const symbol_nest_cam_iq: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M340-80q-24.75 0-42.375-17.625T280-140h170v-181q-107-14-178.5-93T200-600q0-117 81.5-198.5T480-880q117 0 198.5 81.5T760-600q0 107-71.5 186T510-321v181h170q0 24.75-17.625 42.375T620-80H340Zm140-300q92 0 156-64t64-156q0-92-64-156t-156-64q-92 0-156 64t-64 156q0 92 64 156t156 64Zm0-120q-42 0-71-29t-29-71q0-42 29-71t71-29q42 0 71 29t29 71q0 42-29 71t-71 29Z"
		/>
	</svg>
);
