import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { useCurrentRoute } from 'pkg/router/hooks';

import { usePlayerState } from 'components/video-analytics/PlayerState';
import {
	FilterHighlights,
	FilterPrivate,
	useFilterState,
} from 'components/video-analytics/FilterState';

export default function Bootstrap(): JSX.Element {
	const { query } = useCurrentRoute();
	const { setGaplessPlayback } = usePlayerState();
	const filters = useFilterState();

	useComponentDidMount(() => {
		setGaplessPlayback(true);

		if (query.hasOwnProperty('search')) {
			filters.setSearch(query.search);
		}

		if (query.hasOwnProperty('authors')) {
			const authorIds = query.authors
				.split(',')
				.map((n: string) => Number.parseInt(n, 10));

			filters.setAuthors(authorIds);
		}

		if (query.hasOwnProperty('tagged')) {
			const taggedUserIds = query.tagged
				.split(',')
				.map((n: string) => Number.parseInt(n, 10));

			filters.setTaggedUsers(taggedUserIds);
		}

		if (query.hasOwnProperty('highlights')) {
			let highlightClips = FilterHighlights.Show;

			if (query.highlights === '1') {
				highlightClips = FilterHighlights.Only;
			} else if (query.highlights === '0') {
				highlightClips = FilterHighlights.Hide;
			}

			filters.setHighlights(highlightClips);
		}

		if (query.hasOwnProperty('private')) {
			let privateClips = FilterPrivate.Show;

			if (query.private === '1') {
				privateClips = FilterPrivate.Only;
			} else if (query.private === '0') {
				privateClips = FilterPrivate.Hide;
			}

			filters.setPrivate(privateClips);
		}
	});

	return null;
}
