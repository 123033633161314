import { ReactNode } from 'react';

export const symbol_temple_buddhist: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M160-140v-314q-50-12-83.5-48.5T40-589q0-13.175 8.877-22.088 8.877-8.912 22-8.912t21.623 8.912Q101-602.175 101-589q3 33 28.285 56T189-510h51v-104q-50-12-83.5-48.5T120-749q0-13.175 8.877-22.088 8.877-8.912 22-8.912t21.623 8.912Q181-762.175 181-749q3 33 28.285 56T269-670h24l139-186q9-12 21.75-18t26.25-6q13.5 0 26.25 6T528-856l139 186h24q34.43 0 59.715-23Q776-716 779-749q0-13.175 8.877-22.088 8.877-8.912 22-8.912t21.623 8.912Q840-762.175 840-749q-3 50-36 86.5T720-614v104h51q34.43 0 59.715-23Q856-556 859-589q0-13.175 8.877-22.088 8.877-8.912 22-8.912t21.623 8.912Q920-602.175 920-589q-3 50-36 86.5T800-454v314q0 24.75-17.625 42.375T740-80H560q-12.75 0-21.375-8.625T530-110v-130q0-21.25-14.325-35.625Q501.351-290 480.175-290 459-290 444.5-275.625 430-261.25 430-240v130q0 12.75-8.625 21.375T400-80H220q-24.75 0-42.375-17.625T160-140Zm208-530h224L480-819 368-670Zm-68 160h360v-100H300v100Zm-80 370h150v-100q0-45.833 32.118-77.917 32.117-32.083 78-32.083Q526-350 558-317.917q32 32.084 32 77.917v100h150v-310H220v310Zm260-310Zm0-220Zm0 160Z"
		/>
	</svg>
);
