import { ReactNode } from 'react';

export const symbol_compass_calibration: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-79q-79 0-135.5-56.5T288-271q0-79 56.5-135.5T480-463q79 0 135.5 56.5T672-271q0 79-56.5 135.5T480-79Zm0-60q55 0 93.5-38.5T612-271q0-55-38.5-93.5T480-403q-55 0-93.5 38.5T348-271q0 55 38.5 93.5T480-139Zm-.032-441Q426-580 376-561t-92 53q-8.93 8-20.465 7T244-510L104-650q-10-10-9.5-23.5T106-695q81-65 175.5-105T480-840q104 0 198.5 40T854-695q11 8 11.5 21.5T856-650L716-510q-8.119 8.462-19.56 9.231Q685-500 676-508q-42-34-92.032-53-50.033-19-104-19ZM266-573q51-32 104.122-49.5t110-17.5Q537-640 591-624t103 51l97-97q-66-51-146.5-80.5T480-780q-84 0-164 29.5T169-670l97 97Zm214-67Zm0 369Z"
		/>
	</svg>
);
