import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import SectionTitle from 'components/SectionTitle';

import Row from 'components/layout/row';
import Column from 'components/layout/column';

import * as DataCard from 'design/data_card';

const MatchSummary = ({ statistics }) => {
	if (!statistics) {
		return null;
	}

	return (
		<Column>
			<SectionTitle icon="star">{t('Summary')}</SectionTitle>
			<Column spacing={styles.spacing._8}>
				<Row spacing={styles.spacing._8} collapseOnSmallScreens>
					<Column>
						<DataCard.WithLargeIcon
							title={statistics.matchesPlayed.label}
							icon="field"
							heading={statistics.matchesPlayed.number}>
							<DataCard.Breakdown
								items={[
									{
										title: statistics.wins.label,
										value: statistics.wins.number,
										color: 'green',
										variant: 'label',
									},
									{
										title: statistics.losses.label,
										value: statistics.losses.number,
										color: 'red',
										variant: 'label',
									},
									{
										title: statistics.draws.label,
										value: statistics.draws.number,
										color: 'orange',
										variant: 'label',
									},
								]}
							/>
						</DataCard.WithLargeIcon>
					</Column>
					<Column>
						<DataCard.WithLargeIcon
							title={statistics.goals.label}
							icon="football"
							heading={statistics.goals.number}>
							<DataCard.Breakdown
								items={[
									{
										title: statistics.goalsConceded.label,
										value: statistics.goalsConceded.number,
									},
									{
										title: statistics.goalsDifference.label,
										value: statistics.goalsDifference.number,
									},
									{
										title: statistics.cleanSheets.label,
										value: statistics.cleanSheets.number,
									},
								]}
							/>
						</DataCard.WithLargeIcon>
					</Column>
				</Row>
			</Column>
		</Column>
	);
};

export default MatchSummary;
