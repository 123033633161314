import { List, Record } from 'immutable';

import { MatchEvents } from 'pkg/actions/action-types';

const initialState = new Record(
	{
		entities: new List([]),
	},
	'match_types'
);

const setEventTypes = (state, { payload }) =>
	state.setIn(['entities'], new List(payload));

const matchTypes = (state = initialState(), action = {}) => {
	switch (action.type) {
		case MatchEvents.ADD_EVENT_TYPES:
			return setEventTypes(state, action);

		default:
			return state;
	}
};

export default matchTypes;
