import { ReactNode } from 'react';

export const symbol_conversion_path_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M812-63 47-827q-9-9-9-21.5t9-21.5q9-9 21.5-9t21.5 9l765 765q9 9 9 21t-9 21q-9 9-21.5 9T812-63ZM443-204q-66 0-109.5-43.5T290-357q0-66 43.5-109.5T443-510h7l60 60h-67q-41 0-67 26t-26 67q0 41 26 67t67 26h253l128 128q-13 8-27.5 12t-30.5 4q-41 0-71.5-24.5T656-204H443Zm404-111q23 23 30.5 56t-4.5 64q-2 5-7.5 7t-10.5-3l-69-69-62-62q-5-5-3.5-11t6.5-8q31-11 64-4t56 30ZM194-612q-48 0-81-33t-33-81q0-32 15.5-57.5T136-824l156 156q-14 24-40 40t-58 16Zm394 142q-13 0-21.5-8.5T558-500q0-8 19-27 18-11 27-31t9-45q0-41-26-67t-67-26H365q-15 0-22.5-9.5T335-726q0-11 7.5-20.5T365-756h155q66 0 109.5 43.5T673-603q0 42-18.5 75T605-475q-4 2-8 3.5t-9 1.5Z"
		/>
	</svg>
);
