import { ReactNode } from 'react';

export const symbol_brightness_3: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M476-161q102-37 163-124.5T700-481q0-109-62-196.5T474-802q54 69 79 151t25 170q0 87-24 169t-78 151Zm169-602q56 57 85.5 129.5T760-481q0 80-29.5 152.5T645-199q-54 56-123.5 86.5T366-82q-10 0-16.5-7t-6.5-17q0-7 3.5-13t9.5-9q83-61 122.5-155.5T518-481q0-103-39.5-197.5T356-834q-6-3-9.5-9t-3.5-13q0-10 6.5-17t16.5-7q86 0 155.5 30.5T645-763Zm-67 282Z"
		/>
	</svg>
);
