import { ReactNode } from 'react';

export const symbol_auto_read_play: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m442-435 158-106q10-6.805 10-18.902Q610-572 600-579L442-685q-11-8-23-1.443T407-666v213q0 14 12 20t23-2ZM240-240 131-131q-14 14-32.5 6.344T80-152v-668q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H240Zm-26-60h606v-520H140v600l74-80Zm-74 0v-520 520Z"
		/>
	</svg>
);
