import { t } from '@transifex/native';

import {
	IdentityUserTrait,
	userTraitToTranslatedString,
} from 'pkg/api/models/identity_provider';

import * as Input from 'components/form/inputs';
import { FormPayload } from 'components/form/Form';

const selectableRoles: IdentityUserTrait[] = [
	IdentityUserTrait.Admin,
	IdentityUserTrait.Staff,
	IdentityUserTrait.User,
	IdentityUserTrait.LegalGuardian,
];

interface UserTraitsInputGroupProps {
	availableUserTraits: IdentityUserTrait[];
	preSelectedUserTraits?: IdentityUserTrait[];
}

// Takes a whole form payload and return all the selectedRoles that has been checked
export const getSelectedUserTraits = (
	data: FormPayload
): IdentityUserTrait[] => {
	const userTraits = data.userTraits as unknown as { [key: string]: boolean };

	return Object.entries(userTraits).reduce((acc, [key, value]) => {
		if (value) {
			acc.push(key);
		}

		return acc;
	}, []);
};

export default function UserTraitsInputGroup({
	availableUserTraits = [],
	preSelectedUserTraits = [],
}: UserTraitsInputGroupProps) {
	return (
		<Input.Group
			label={t('Required for roles')}
			description={t(
				'Should this login method only be applied to specific roles in your organization?'
			)}>
			{selectableRoles.map((r) => (
				<Input.Control
					key={r}
					type="checkbox"
					name={`userTraits.${r}`}
					label={userTraitToTranslatedString(r)}
					defaultChecked={preSelectedUserTraits.includes(r)}
					disabled={
						!availableUserTraits.includes(r) &&
						!preSelectedUserTraits.includes(r)
					}
				/>
			))}
		</Input.Group>
	);
}
