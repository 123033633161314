import { ReactNode } from 'react';

export const symbol_kayaking: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M120-40h-10q-13 0-21.5-8.5T80-70q0-13 8.5-21.5T110-100h10q26 0 51-7t49-25q8-7 20-7t20 7q24 18 49 25t51 7q25 0 50.5-7.5T460-132q9-6 20.5-6t19.5 6q24 18 49 25t51 7q25 0 50.5-7.5T700-132q8-6 20-6t20 6q24 17 49.5 24.5T840-100h10q13 0 21.5 8.5T880-70q0 13-8.5 21.5T850-40h-10q-29 0-59.5-8.5T720-80q-30 23-60.5 31.5T600-40q-29 0-59.5-8.5T480-80q-30 23-60.5 31.5T360-40q-29 0-59.5-8.5T240-80q-30 23-60.5 31.5T120-40Zm360-554q-30 0-51.5-21.5T407-667q0-30 21.5-51.5T480-740q30 0 51.5 21.5T553-667q0 30-21.5 51.5T480-594ZM360-196q-24 0-47.5-9.5T269-232q-14-11-30-11.5t-29 9.5q-9 7-18 13.5T174-209q-14-3-29.5-6.5T113-224q-41-11-41-36t41-36q45-12 92-22t88-17l56-179q8-28 34-39t51 2l106 56 121-64 70-162-16-41q-2-6-1.5-12t2.5-11l31-67q5-11 16.5-15.5t22.5.5l55 25q11 5 15.5 16.5T856-803l-30 67q-2 5-7 9t-10 6l-41 16-156 363q57 7 118.5 19.5T847-296q39 11 39 36t-39 36q-16 5-31.5 8.5T786-209q-9-5-18-11.5T750-234q-13-10-29-9.5T691-232q-20 17-43.5 26.5T600-196q-24 0-47.5-9.5T509-232q-13-11-29-11t-29 11q-20 17-43.5 26.5T360-196Zm209-151 52-124-81 42-76-38-36 117h52q23 0 44.5.5T569-347Z"
		/>
	</svg>
);
