import { ReactNode } from 'react';

export const symbol_door_sliding: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M369-449q-14 0-24.5-10.5T334-484q0-14 10.5-24.5T369-519q14 0 24.5 10.5T404-484q0 14-10.5 24.5T369-449Zm223 0q-14 0-24.5-10.5T557-484q0-14 10.5-24.5T592-519q14 0 24.5 10.5T627-484q0 14-10.5 24.5T592-449ZM150-120q-12.75 0-21.375-8.625T120-150q0-13 8.5-21.5T150-180h16v-600q0-24 18-42t42-18h508q24 0 42 18t18 42v600h16q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-120H150Zm76-60h224v-600H226v600Zm284 0h224v-600H510v600Zm-30-332Z"
		/>
	</svg>
);
