import { Fragment, ReactNode, useState } from 'react';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCollection } from 'pkg/api/use_collection';
import { cssVarList } from 'pkg/css/utils';

import Icon from 'components/icon';
import Badge from 'components/Badge';

import * as Sortable from 'components/dnd/sortable';
import * as ActionBar from 'components/layout/ActionBar';
import { Spinner } from 'components/loaders/spinner';
import Row from 'components/layout/row';

import Button from 'design/button';

import * as css from './style.css';

interface SortOrderRowProps {
	item: models.group.Group;
	isDragging: boolean;
	renderBranch: (items: models.group.Group[]) => ReactNode;
}

function SortOrderRow({
	item,
	isDragging,
	renderBranch,
}: SortOrderRowProps): JSX.Element {
	const [expanded, setExpanded] = useState<boolean>(true);

	const toggle = () => setExpanded(!expanded);

	const hasChildren = item?.children?.length > 0;

	return (
		<Sortable.Item id={item.id}>
			<Row
				columns="1fr auto"
				align="center"
				className={css.row}
				data-depth={item.depth}
				style={cssVarList({ depth: item.depth })}>
				<Row columns="30px 1fr" align="center">
					<Badge badgeUrl={item.profileImageUrl} />
					{item.name}
				</Row>
				<Row align="center">
					{hasChildren && (
						<div onClick={toggle} className={css.toggle}>
							<Icon
								size={1.4}
								name="chevron"
								rotate={expanded ? '90deg' : '0deg'}
							/>
						</div>
					)}
					<Icon name="drag-handle" size={1.4} />
				</Row>
			</Row>
			{expanded && hasChildren && !isDragging && renderBranch(item.children)}
		</Sortable.Item>
	);
}

interface Payload {
	[key: number]: number[];
}

interface SortOrderProps {
	rootGroup: models.group.Group;
	onProcessing: (payload: Payload) => Promise<void>;
	onFinish: () => void;
}

export default function SortOrder({
	rootGroup,
	onProcessing,
	onFinish,
}: SortOrderProps): JSX.Element {
	const [isSaving, setSaving] = useState<boolean>(false);

	const { isLoading, records: childGroups } = useCollection<models.group.Group>(
		endpoints.Groups.ShowChildren(rootGroup.id),
		{
			queryParams: new URLSearchParams({
				recursive: 'true',
			}),
		}
	);

	const tree = models.group.buildGroupTree(rootGroup, childGroups);

	const theme: Sortable.Theme = {
		container: css.container,
		activeContainer: css.active,
		item: css.item,
		activeItem: css.active,
		siblingItem: css.sibling,
		overlayItem: css.overlay,
	};

	const payload: Payload = {};

	const handleChange = (items: models.group.Group[]) => {
		const parentId = items[0].parentGroupId || 0;
		const order = items.map((group: models.group.Group) => group.id);

		payload[parentId] = order;
	};

	const handleFinish = async () => {
		setSaving(true);

		await onProcessing(payload);
		onFinish();
	};

	const renderLeaf = (item: models.group.Group, isDragging: boolean) => {
		return (
			<SortOrderRow
				item={item}
				isDragging={isDragging}
				renderBranch={renderBranch}
			/>
		);
	};

	const renderBranch = (items: models.group.Group[]) => {
		return (
			<Sortable.Container
				items={items}
				renderWith={renderLeaf}
				theme={theme}
				onChange={handleChange}
			/>
		);
	};

	return (
		<Fragment>
			<ActionBar.SaveBar>
				<Button onClick={onFinish}>{t('Cancel')}</Button>
				<Button primary isLoading={isSaving} onClick={handleFinish}>
					{t('Finish')}
				</Button>
			</ActionBar.SaveBar>
			{isLoading ? (
				<Spinner />
			) : (
				<div className={css.wrapper}>{renderBranch(tree)}</div>
			)}
		</Fragment>
	);
}
