import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import * as actions from 'pkg/actions';
import * as routes from 'pkg/router/routes';
import { pushState } from 'pkg/router/state';
import { useCurrentOrganization } from 'pkg/identity';

import * as Card from 'components/Card';
import Modal from 'components/modal';

import * as Input from 'components/form/inputs';
import Form, { FormPayload, asString } from 'components/form/Form';

import Button, { ButtonGroup } from 'design/button';

interface DuplicateSessionModalProps {
	session: models.session.Session;

	onFail?: () => void;
	onClose: () => void;
}

export default function DuplicateSessionModal({
	session,
	onFail = null,
	onClose,
}: DuplicateSessionModalProps) {
	const org = useCurrentOrganization();

	const handleSubmit = async (data: FormPayload) => {
		const title = asString(data.title);

		const [ok, duplicatedSession] = await actions.sessions.duplicate(
			org.id,
			session.id,
			{
				title,
			}
		);

		if (!ok) {
			if (onFail) {
				onFail();
			}

			return;
		}

		pushState(routes.TrainingSession.Edit(org.id, duplicatedSession.id));
	};

	return (
		<Modal onClose={onClose}>
			<Form onSubmit={handleSubmit}>
				<Card.Base>
					<Card.Header>
						<Card.Heading>
							{t('Duplicate {name}', { name: session.title })}
						</Card.Heading>
					</Card.Header>
					<Card.Divider />
					<Card.Body>
						<Input.Group label={t('Session title')}>
							<Input.Field
								name="title"
								defaultValue={t('{sessionTitle} COPY', {
									sessionTitle: session.title,
								})}
							/>
						</Input.Group>
					</Card.Body>
					<Card.Divider />
					<Card.Footer>
						<ButtonGroup>
							<Button label={t('Cancel')} onClick={onClose} />
							<Button label={t('Confirm')} primary type="submit" />
						</ButtonGroup>
					</Card.Footer>
				</Card.Base>
			</Form>
		</Modal>
	);
}
