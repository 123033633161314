import { ReactNode } from 'react';

export const symbol_network_check: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M32-569q-14-14-13-32.5T34-633q91-80 206-123.5T480-800q24 0 54 1.5t62 5.5l-42 86q-18-2-36-2.5t-38-.5q-107 0-204 37.5T98-568q-15 12-33.5 12.5T32-569Zm123 118q-14-14-13-32t15-29q66-53 154.5-79.5T508-611l-39 81q-66-2-132.5 20.5T219-448q-15 11-33 10.5T155-451Zm289 287q-27-11-39.5-41t1.5-59l237-490q3-7 10.5-10t15.5-1q8 2 13 9t3 15L547-206q-8 32-41 42.5t-62-.5Zm280-492 22-90q54 23 97.5 51t82.5 63q14 13 15.5 31T929-569q-14 14-33.5 13.5T861-569q-33-27-68.5-50T724-656Zm113 237q-13 13-31.5 13T774-419q-16-17-44.5-37T681-485l21-86q35 12 73 38.5t62 50.5q13 13 13 31.5T837-419Z"
		/>
	</svg>
);
