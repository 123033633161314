import { ReactNode } from 'react';

export const symbol_video_camera_back: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M235-333h340q9.208 0 13.104-8Q592-349 587-357l-99-135q-2.286-3-5.333-4.5-3.048-1.5-6.857-1.5-3.81 0-6.719 1.5-2.909 1.5-5.091 4.5l-82 109q-2 3-5.5 4t-6.5 1q-3 0-6.5-1t-5.5-4l-47-60q-2.455-2.857-5.727-3.929Q302-448 299-448q-3 0-6.5 1.5T287-442l-64 85q-5 8-1.104 16T235-333Zm-95 173q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h520q24 0 42 18t18 42v215l134-134q7-7 16.5-3.458Q880-658.917 880-649v338q0 9.917-9.5 13.458Q861-294 854-301L720-435v215q0 24-18 42t-42 18H140Zm0-60h520v-520H140v520Zm0 0v-520 520Z"
		/>
	</svg>
);
