import styled, { css } from 'styled-components';

import * as styles from 'pkg/config/styles';

import Icon from 'components/icon';

export const Wrapper = styled.div`
	overflow: auto;
	-webkit-overflow-scrolling: touch;
`;

export const Header = styled.div`
	display: grid;
	grid-auto-flow: row;
	background: var(--palette-white);
	border-bottom: 1px solid var(--palette-gray-300);
	z-index: ${styles.zIndex.dashboardScroll};
	position: sticky;
	top: 0;

	${({ theme }) =>
		theme.darkMode &&
		css`
			background: var(--palette-gray-800);
			border-bottom-color: var(--palette-gray-700);
		`}
`;

export const Container = styled.div``;

export const Heading = styled.div`
	padding: var(--spacing-3) var(--spacing-5);
	background: var(--palette-gray-200);
	border-top: 1px solid var(--palette-gray-300);
	color: var(--palette-gray-500);
	text-transform: uppercase;
	font-size: var(--font-size-xs);
	font-weight: var(--font-weight-semibold);
	line-height: 1;

	${({ theme }) =>
		theme.darkMode &&
		css`
			background: var(--palette-gray-900);
			border-top-color: var(--palette-gray-700);
		`}
`;

export const Item = styled.div`
	padding: var(--spacing-4) var(--spacing-5);
	background: var(--palette-white);
	border-top: 1px solid var(--palette-gray-300);
	display: grid;
	grid-template-columns: 30px auto 30px;
	align-items: center;
	gap: var(--spacing-4);

	&:active {
		background: var(--palette-gray-200);
	}

	${({ theme }) =>
		theme.darkMode &&
		css`
			background: var(--palette-gray-800);
			border-top-color: var(--palette-gray-700);

			&:active {
				background: var(--palette-gray-900);
			}
		`}
`;

export const PreviewWrapper = styled.div`
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
`;

export const PreviewContainer = styled.div`
	padding: 0 var(--spacing-5) var(--spacing-3) var(--spacing-5);
	display: inline-grid;
	grid-auto-flow: column;
	grid-auto-columns: 60px;
	gap: var(--spacing-6);
`;

export const PreviewItem = styled.div`
	display: grid;
	grid-auto-flow: row;
	grid-auto-rows: 60px auto;
	gap: var(--spacing-3);
	align-items: center;
	justify-content: center;
	font-size: var(--font-size-xs);
	position: relative;

	span {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		display: block;
	}
`;

export const PreviewCloseIcon = styled(Icon)`
	padding: var(--spacing-1);
	width: 20px;
	height: 20px;
	border-radius: 1000px;
	background: var(--palette-blue-500);
	color: var(--palette-white);
	box-shadow: var(--palette-white) 0 0 0 2px;
	z-index: ${styles.zIndex.dashboardScroll + 1};
	position: absolute;
	right: 0;
	top: 0;

	${({ theme }) =>
		theme.darkMode &&
		css`
			box-shadow: var(--palette-gray-800) 0 0 0 3px;
		`}
`;

export const SearchWrapper = styled.div`
	padding: var(--spacing-5);
`;

export const ControlsWrapper = styled.div`
	padding: var(--spacing-2) var(--spacing-3) var(--spacing-2) var(--spacing-5);
	display: grid;
	grid-template-columns: 1fr auto auto;
	font-size: var(--font-size-sm);
	align-items: center;
	color: var(--palette-gray-500);
`;

export const EmptyState = styled.div`
	padding: var(--spacing-8);
	display: grid;
	align-items: center;
	justify-content: center;
	justify-items: center;
	gap: var(--spacing-5);
`;
