import { ReactNode } from 'react';

export const symbol_switch_access_shortcut: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M538-783q-71 54-110.5 132.5T388-483q0 99 47.455 183.877Q482.909-214.246 568-163q11 7 16.5 19.5T583-120q-7 11-20 14t-25-4q-98-60-154-158.5T328-482q0-98.882 42-187.441T489-820H352q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T352-880h216q12.75 0 21.375 8.625T598-850v216q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T538-634v-149Z"
		/>
	</svg>
);
