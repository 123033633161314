import styled from 'styled-components';
import { CSSProperties, useEffect, useRef, useState } from 'react';
import videojs from 'video.js';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import { OEmbedResponse, useOEmbed } from 'pkg/hooks/oembed';

import Icon from 'components/icon';

import { Spinner } from 'components/loaders/spinner';
import Row from 'components/layout/row';
import StubController from 'components/video-analytics/StubController';

const Wrapper = styled.div`
	background-color: var(--backdrop, var(--palette-gray-800));
	color: var(--palette-white);
	border-radius: var(--roundness, 10px);
	aspect-ratio: 16 / 9;
	position: relative;
	overflow: hidden;

	&[data-no-embed] {
		display: grid;
		place-items: center;
		place-content: center;
	}

	iframe,
	video,
	.video-js {
		width: 100%;
		height: 100%;
		border-radius: var(--roundness, 10px);
		outline: none;
		object-fit: cover;
	}

	@supports not (aspect-ratio: 16 / 9) {
		padding-top: 56.25%;

		&[data-no-embed] > div {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}

		iframe,
		video,
		.video-js {
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
		}
	}
`;

type OEmbedEventListener = () => void;

type OEmbedTimeUpdateEventListener = (currentTime: number) => void;

interface OEmbedProps {
	url: string;

	canvasRoundness?: number;
	canvasBackdrop?: string;

	autoPlay?: boolean;
	controls?: boolean;
	startsAt?: number;

	onControllerChange?: (controller: any) => void;
	onHasDuration?: (duration: number) => void;
	onSuccess?: (embed: OEmbedResponse) => void;

	onTimeUpdate?: OEmbedTimeUpdateEventListener;
	onCanPlay?: OEmbedEventListener;
	onPlay?: OEmbedEventListener;
	onPause?: OEmbedEventListener;
	onWaiting?: OEmbedEventListener;
	onResume?: OEmbedEventListener;
	onError?: OEmbedEventListener;
}

export default function OEmbed({
	url,

	canvasRoundness = 10,
	canvasBackdrop = '',

	autoPlay = false,
	controls = false,
	startsAt = 0,

	onControllerChange,
	onHasDuration,
	onSuccess,

	onTimeUpdate,
	onCanPlay,
	onPlay,
	onPause,
	onWaiting,
	onResume,
	onError,
}: OEmbedProps): JSX.Element {
	const [isLoading, embed, source] = useOEmbed(url);

	const [videoReady, setVideoReady] = useState<boolean>(false);
	const [didSendError, setDidSendError] = useState<boolean>(false);

	const playerRef = useRef<any>();

	const bindListener = (event: string, listener?: OEmbedEventListener) => {
		if (listener) {
			playerRef.current.on(event.toLocaleLowerCase(), listener);
		}
	};

	const validControllerRef = () => {
		return (
			(playerRef.current !== null || playerRef.current !== undefined) &&
			!(playerRef.current instanceof StubController)
		);
	};

	const handleTimeUpdate = () => {
		const currentTime = playerRef.current?.currentTime();

		if (onTimeUpdate) {
			onTimeUpdate(currentTime);
		}
	};

	useEffect(() => {
		if (!isLoading && embed && onSuccess) {
			onSuccess(embed);
		}

		if (!isLoading && !embed && onError && !didSendError) {
			onError();
			setDidSendError(true);
		}
	}, [url, isLoading, embed]);

	// Update didSendError if url changes
	useEffect(() => {
		if (didSendError) {
			setDidSendError(false);
		}
	}, [url]);

	useEffect(() => {
		if (source === 'hosted' || source === 'veo') {
			const node = document.querySelector('.video-js');

			if (node) {
				playerRef.current = videojs(node, {
					muted: autoPlay,
					autoplay: autoPlay,
					controls: controls,
					controlBar: {
						fullscreenToggle: false,
						pictureInPictureToggle: false,
					},
					suppressNotSupportedError: true,
				});

				bindListener('timeUpdate', handleTimeUpdate);
				bindListener('canPlay', onCanPlay);
				bindListener('play', onPlay);
				bindListener('pause', onPause);
				bindListener('waiting', onWaiting);
				bindListener('resume', onResume);

				playerRef.current.ready(() => setVideoReady(true));

				playerRef.current.on('loadedmetadata', () => {
					if (onHasDuration && playerRef.current !== null) {
						const duration = playerRef.current.duration();

						onHasDuration(duration);
					}
				});

				if (onControllerChange && validControllerRef()) {
					onControllerChange(playerRef.current);
				}
			}
		}
	}, [source]);

	useEffect(() => {
		if (onControllerChange && validControllerRef()) {
			onControllerChange(playerRef.current);

			// Properly set time when source changes
			if (startsAt && playerRef.current) {
				playerRef.current.currentTime(startsAt);
			}
		}
	}, [playerRef.current]);

	if (!embed) {
		const embedInformation = (
			<Row columns="auto 1fr" align="center" spacing={styles.spacing._2}>
				<Icon name="error" />
				<span>{t('Paste a link in the field above')}</span>
			</Row>
		);

		return (
			<Wrapper
				data-no-embed
				style={
					{
						'--roundness': `${canvasRoundness}px`,
						'--backdrop': canvasBackdrop,
					} as CSSProperties
				}>
				{isLoading || !videoReady ? <Spinner /> : embedInformation}
			</Wrapper>
		);
	}

	return (
		<Wrapper
			dangerouslySetInnerHTML={{ __html: embed.html }}
			style={
				{
					'--roundness': `${canvasRoundness}px`,
					'--backdrop': canvasBackdrop,
				} as CSSProperties
			}
		/>
	);
}
