import { ReactNode } from 'react';

export const symbol_shield_with_house: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-592 236-402q22 72 67.5 130.5T410-176v-144q0-12.75 8.625-21.375T440-350h80q12.75 0 21.375 8.625T550-320v144q61-37 106.5-95.5T724-402L480-592ZM220-719v196q0 12.647 1 27.824Q222-480 224-467l256-200 256 200q2-13 3-28.176 1-15.177 1-27.824v-196l-260-98-260 98ZM480-84q-5.32 0-9.88-1-4.56-1-9.12-3-139-47-220-168.5t-81-266.606V-719q0-19.257 10.875-34.662Q181.75-769.068 199-776l260-97q11-4 21-4t21 4l260 97q17.25 6.932 28.125 22.338Q800-738.257 800-719v195.894Q800-378 719-256.5T499-88q-4.56 2-9.12 3T480-84Z"
		/>
	</svg>
);
