import { ReactNode } from 'react';

export const symbol_export_notes: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M778-253v84q0 6 4.5 10.5T793-154q6 0 10.5-4.5T808-169v-120q0-6-4.5-10.5T793-304H673q-6 0-10.5 4.5T658-289q0 6 4.5 10.5T673-274h84L651-168q-5 5-5 10.5t5 10.5q5 5 10.5 5t10.5-5l106-106ZM180-120q-24.75 0-42.375-17.625T120-180v-600q0-24.75 17.625-42.375T180-840h600q24.75 0 42.375 17.625T840-780v275q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T780-505v-275H180v600h277q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T457-120H180Zm0-107v47-600 308-4 249Zm100-82.825q0 12.825 8.625 21.325T310-280h151q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T461-340H310q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5Zm0-170q0 12.825 8.625 21.325T310-450h320q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T630-510H310q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5Zm0-170q0 12.825 8.625 21.325T310-620h340q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T650-680H310q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5ZM732.5-41Q655-41 600-96.5T545-228q0-78.435 54.99-133.717Q654.98-417 733-417q77 0 132.5 55.283Q921-306.435 921-228q0 76-55.5 131.5T732.5-41Z"
		/>
	</svg>
);
