import { ReactNode } from 'react';

export const symbol_detector_alarm: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M479.825-120Q467-120 458.5-128.625T450-150v-140q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-290v140q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM798-256q-9 9-21 9t-21-9l-99-99q-9-9-8.5-21t9-21q8.5-9 20.967-9 12.466 0 21.533 9l98 99q8 9 8.5 21.1.5 12.1-8.5 20.9Zm-636 0q-9-9-9-21t9-21l99-100q9-9 21-9t21 9.053q9 9.052 9 21.5Q312-364 303-355l-99 99q-9 8-21.1 8.5-12.1.5-20.9-8.5Zm18-524v60h600v-60H180Zm121 120 18 60h322l18-60H301Zm18 120q-19.5 0-35.25-11.625T262-582l-25-78h-57q-24.75 0-42.375-17.625T120-720v-60q0-24.75 17.625-42.375T180-840h600q24.75 0 42.375 17.625T840-780v60q0 24.75-17.625 42.375T780-660h-57l-30 81q-6.932 17.25-22.338 28.125Q655.257-540 636-540H319ZM180-780v60-60Z"
		/>
	</svg>
);
