import { ReactNode } from 'react';

export const symbol_domain_add: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-120q-24.75 0-42.375-17.625T80-180v-600q0-24.75 17.625-42.375T140-840h270q24.75 0 42.375 17.625T470-780v105h350q24.75 0 42.375 17.625T880-615v250q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T820-365v-250H470v105h80v60h-80v105h80v60h-80v105h165q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T635-120H140Zm0-60h105v-105H140v105Zm0-165h105v-105H140v105Zm0-165h105v-105H140v105Zm0-165h105v-105H140v105Zm165 495h105v-105H305v105Zm0-165h105v-105H305v105Zm0-165h105v-105H305v105Zm0-165h105v-105H305v105ZM842.825-40Q830-40 821.5-48.625T813-70v-57h-58q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T755-187h58v-58q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T873-245v58h57q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T930-127h-57v57q0 12.75-8.675 21.375Q855.649-40 842.825-40ZM655-450v-60h60v60h-60Zm0 165v-60h60v60h-60Z"
		/>
	</svg>
);
