import { ReactNode } from 'react';

export const symbol_timer_5_shutter: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M591-200H370q-16.667 0-28.333-11.735Q330-223.471 330-240.235 330-257 341.667-268.5 353.333-280 370-280h221v-160H370q-16.667 0-28.333-11.667Q330-463.333 330-480v-240q0-16.667 11.667-28.333Q353.333-760 370-760h261q16.667 0 28.333 11.735Q671-736.529 671-719.765 671-703 659.333-691.5 647.667-680 631-680H410v160h181q33.333 0 56.667 23.333Q671-473.333 671-440v160q0 33.333-23.333 56.667Q624.333-200 591-200Z"
		/>
	</svg>
);
