import { ReactNode } from 'react';

export const symbol_signal_disconnected: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M748-560q0 46-15.5 89T690-391q-6 8-16.5 8.5T655-390q-8-8-7-18t7-19q21-29 32-63t11-70q0-37-11.5-71.5T652-695q-7-8-7.5-18.5T652-732q8-8 18-7.5t17 8.5q29 36 45 80t16 91Zm-268-91q38 0 64.5 26.5T571-560q0 11-3 22t-8 21q-5 8-13.5 9t-15.5-6l-97-97q-7-7-6-16t9-14q10-5 21-7.5t22-2.5Zm400 91q0 72-26 139t-72 123q-7 8-17 8.5t-18-7.5q-8-8-7.5-18.5T747-334q39-48 61-105.5T830-560q0-63-21.5-121T746-787q-8-8-8.5-19t7.5-19q8-8 18-6t18 10q48 54 73.5 121.5T880-560ZM792-83 510-364v214q0 13-8.5 21.5T480-120q-13 0-21.5-8.5T450-150v-274L268-606q-4 11-5 22.5t-1 23.5q0 37 11.5 72t34.5 64q7 8 7.5 18t-7.5 18q-8 8-18 7.5t-17-8.5q-29-36-45-80t-16-91q0-23 3.5-44.5T227-647l-62-62q-17 35-26 72.5t-9 76.5q0 63 21.5 121T214-333q8 8 8.5 19t-7.5 19q-8 8-18 6t-18-10q-48-54-73.5-121.5T80-560q0-49 12-96t35-91l-45-45q-9-9-9-21.5t9-21.5q9-9 21.5-9t21.5 9l710 710q9 9 9 21t-9 21q-9 9-21.5 9T792-83Z"
		/>
	</svg>
);
