import { Fragment } from 'react';
import { t } from '@transifex/native';

import * as routes from 'pkg/router/routes';
import * as models from 'pkg/api/models';
import { useCollection } from 'pkg/api/use_collection';
import * as endpoints from 'pkg/api/endpoints/auto';
import { FilterOperator } from 'pkg/filters';
import { useCurrentOrganization } from 'pkg/identity';

import { useDiscountFilters } from 'routes/payments/discounts/filters';
import DiscountsTable from 'routes/payments/discounts/table';

import Pagination from 'components/pagination';

import * as ActionBar from 'components/layout/ActionBar';

export default function Discounts() {
	const org = useCurrentOrganization();
	const filters = useDiscountFilters({});

	filters.queryParam.set('group_id', org.id.toString());

	const discountsCollection = useCollection<models.discount.Discount>(
		endpoints.Discounts.Index(),
		{
			queryParams: filters.queryParam,
		}
	);

	return (
		<Fragment>
			<ActionBar.IntegratedFilterBar
				searchFilter={{
					type: 'text',
					operator: FilterOperator.Contains,
					property: 'search',
				}}
				filters={filters}
				actions={[
					{
						label: t('Create new'),
						href: routes.Discounts.Create(org.id),
						type: 'primary',
						icon: 'add',
					},
				]}
			/>
			<DiscountsTable
				sort={discountsCollection.sort}
				discountsCollection={discountsCollection}
			/>
			<Pagination {...discountsCollection.pagination} />
		</Fragment>
	);
}
