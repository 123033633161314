import { ReactNode } from 'react';

export const symbol_highlighter_size_3: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M310-170 169-311q-9-9-9-21t9-21l437-438q9-9 21.5-9t21.5 9l141 142q9 9 9 21t-9 21L353-170q-9 9-21.5 9t-21.5-9Z"
		/>
	</svg>
);
