import { ReactNode } from 'react';

export const symbol_lte_plus_mobiledata_badge: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M100-180v-600 600Zm0 60q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h760q24 0 42 18t18 42v110q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T860-670v-110H100v600h760v-110q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T920-290v110q0 24-18 42t-42 18H100Zm183-200q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T283-380h-63v-230q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T160-610v260q0 12.75 8.625 21.375T190-320h93Zm95-260v230q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T438-350v-230h35q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T473-640H343q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T343-580h35Zm312 260q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T690-380h-63v-70h43q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T670-510h-43v-70h63q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T690-640h-93q-12.75 0-21.375 8.625T567-610v260q0 12.75 8.625 21.375T597-320h93Zm120-130v20q0 13 8.5 21.5T840-400q13 0 21.5-8.5T870-430v-20h20q13 0 21.5-8.5T920-480q0-13-8.5-21.5T890-510h-20v-20q0-13-8.5-21.5T840-560q-13 0-21.5 8.5T810-530v20h-20q-13 0-21.5 8.5T760-480q0 13 8.5 21.5T790-450h20Z"
		/>
	</svg>
);
