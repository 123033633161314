import { Fragment } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import DateTime from 'pkg/datetime';
import * as models from 'pkg/api/models';
import { formatToCurrency } from 'pkg/i18n/formatters';
import Link from 'pkg/router/Link';
import * as routes from 'pkg/router/routes';
import { cssClasses } from 'pkg/css/utils';
import { useCurrentOrganization } from 'pkg/identity';

import Avatar from 'components/avatar';
import * as Card from 'components/Card';
import Icon from 'components/icon';

import StatusLabel from 'components/payment_platform/status/label';
import Row from 'components/layout/row';
import Column from 'components/layout/column';

import * as css from './styles.css';

interface ItemProps {
	order: models.order.Order;
	currency: string;
	// The current user we're viewing, to determine paid by/paid on behalf of logic
	userId: number;
}

const InvoiceItem: React.FC<React.PropsWithChildren<ItemProps>> = ({
	userId,
	order,
	currency,
}) => {
	const orgId = useCurrentOrganization().id;
	const dueDate = new DateTime(new Date(order.dueDate * 1000));

	const users = order.userProducts?.map((up) => up.user) ?? [];

	const hideOnBehalf = users.length === 1 && users[0].id === userId;

	return (
		<Card.Base $noBorder>
			<Card.Body $narrowBody>
				<Row columns="1fr auto">
					<Row columns="auto auto 1fr" align="center">
						<div className={css.iconwrapper}>
							<Icon name="file-generic" />
						</div>
						<div className={css.title}>
							<Link href={routes.Self.Invoice(orgId, order.id)}>
								{formatToCurrency(order.amountDue / 100, currency)}
							</Link>
						</div>
						{order.invoiceNumber && <span>{order.invoiceNumber}</span>}
					</Row>

					<Column justify="center" spacing={styles.spacing._2}>
						<StatusLabel status={models.order.getStatus(order)} />
						{!!order.dueDate && (
							<span className={cssClasses(css.extraSmallText, css.sub)}>
								{t('Due')} {dueDate.toLocaleDateString()}
							</span>
						)}
					</Column>
				</Row>
			</Card.Body>

			{!hideOnBehalf && (
				<Fragment>
					<Card.Divider />
					<Card.Body>
						<Column spacing={styles.spacing._2}>
							<span className={cssClasses(css.smallText, css.sub)}>
								{t('On behalf of')}
							</span>
							{users.map((user) => (
								<Row
									key={user.id}
									columns="20px 1fr"
									spacing={styles.spacing._3}
									align="center">
									<Avatar user={user} size={20} />
									<span className={css.smallText}>
										{models.user.fullName(user)}
									</span>
								</Row>
							))}
						</Column>
					</Card.Body>
				</Fragment>
			)}
		</Card.Base>
	);
};

export default InvoiceItem;
