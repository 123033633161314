import * as styles from 'pkg/config/styles';

import * as actions from 'pkg/actions';

import { md } from 'routes/kitchen_sink/components/Markdown';

import Flash from 'components/flash';

import Column from 'components/layout/column';
import Row from 'components/layout/row';

import Button from 'design/button';

export default function Flashes(): JSX.Element {
	const showSnack = () => {
		actions.flashes.show({
			title: 'Hello World! 👋🏻',
		});
	};

	const showFlash = () => {
		actions.flashes.show({
			title: 'A longer flash',
			message: 'Lorem ipsum dolor and all that jazz!',
		});
	};

	const showError = () => {
		actions.flashes.show(
			{
				title: 'Something went wrong',
				message: '☠️ ☠️ ☠️',
			},
			500
		);
	};

	const showSuccess = () => {
		actions.flashes.show(
			{
				title: 'You did the thing!',
			},
			200
		);
	};

	return (
		<Column spacing={styles.spacing._7}>
			{md`
				# Flashes

				Flashes are used to present information, errors and successes for various actions.

				---

				## Common variants
			`}

			<Flash title={`A simple "snack" message`} />

			<Flash title="Post created" message="Your post is now on the wall." />

			<Flash variant="success" title="Successfully did the thing! 😁" />

			<Flash
				variant="error"
				title="Something went wrong"
				message="If the issue presist, please contact us."
			/>

			{md`
				---

				## Special variants

				Special variants are defined through \`additionalData\`.
			`}

			<Flash
				title="Playson liked your post"
				additionalData={{ 'resource-type': 'groupPost' }}
			/>

			<Flash
				title="Coach sent you a message"
				message="Hey buddy! What's up? Could you give me a call, I want to discuss the next practice!"
				additionalData={{ 'resource-type': 'chat' }}
			/>

			<Flash
				title="A new booking is available"
				additionalData={{ 'resource-type': 'booking' }}
			/>

			{md`
				---

				## Try it out
			`}

			<Row autoColumns="auto">
				<Button label="Show a snack flash" onClick={showSnack} />
				<Button label="Show verbose flash" onClick={showFlash} />
				<Button label="Show success" onClick={showSuccess} />
				<Button label="Show error" onClick={showError} />
			</Row>
		</Column>
	);
}
