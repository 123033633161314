import { Fragment, useEffect, useState } from 'react';

import * as models from 'pkg/api/models';
import { useCurrentGroup, useCurrentMembership } from 'pkg/identity';
import { useCurrentRoute } from 'pkg/router/hooks';

import ClubNavigation from 'components/navigation/menus/ClubNavigation';
import OrganizationNavigation from 'components/navigation/menus/OrganizationNavigation';
import TeamNavigation from 'components/navigation/menus/TeamNavigation';
import NoOrganizationsNavigation from 'components/navigation/menus/NoOrganizationsNavigation';

export interface NavigationItemsProps {
	displayChildren: string;
	handleDisplayChildren: (slug: string) => void;
}

export default function NavigationItems() {
	const activeMembership = useCurrentMembership();
	const group = useCurrentGroup();
	const route = useCurrentRoute();

	let path = route.path.slice(1);
	let slugIndex = 0;

	if (path.startsWith('organization')) {
		slugIndex = 2;
	}

	path = path.split('/')?.[slugIndex] ?? '';

	const [displayChildren, setDisplayChildren] = useState(path);

	const handleDisplayChildren = (slug: string) => {
		setDisplayChildren(slug);
	};

	useEffect(() => {
		handleDisplayChildren(path);
	}, [route.path]);

	if (!group?.id) {
		return <NoOrganizationsNavigation />;
	}

	return (
		<Fragment>
			{activeMembership.isOrganizationMembership ? (
				<ClubNavigation
					handleDisplayChildren={handleDisplayChildren}
					displayChildren={displayChildren}
				/>
			) : models.group.isOrganization(group) &&
			  models.membership.isAdminOrStaff(activeMembership) ? (
				<OrganizationNavigation
					handleDisplayChildren={handleDisplayChildren}
					displayChildren={displayChildren}
				/>
			) : (
				<TeamNavigation
					handleDisplayChildren={handleDisplayChildren}
					displayChildren={displayChildren}
				/>
			)}
		</Fragment>
	);
}
