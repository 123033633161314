import { ReactNode } from 'react';

export const symbol_pallet: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M160-120v20q0 12.75-8.625 21.375T130-70h-20q-12.75 0-21.375-8.625T80-100v-30q0-12.75 8.625-21.375T110-160h740q12.75 0 21.375 8.625T880-130v30q0 12.75-8.625 21.375T850-70h-20q-12.75 0-21.375-8.625T800-100v-20H520v20q0 12.75-8.625 21.375T490-70h-20q-12.75 0-21.375-8.625T440-100v-20H160Zm70-200q-12.75 0-21.375-8.625T200-350v-500q0-12.75 8.625-21.375T230-880h500q12.75 0 21.375 8.625T760-850v510q0 8.5-5.75 14.25T740-320H230Zm10-40h480v-480H240v480Zm340-300q8.5 0 14.25-5.75T600-680q0-8.5-5.75-14.25T580-700H380q-8.5 0-14.25 5.75T360-680q0 8.5 5.75 14.25T380-660h200ZM240-360v-480 480Z"
		/>
	</svg>
);
