import { ReactNode } from 'react';

export const symbol_find_replace: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M440-740q-70 0-127 39t-80 103q-4 11-15 16.5t-22 2.5q-12-3-18.5-13.5T175-615q29-83 101.5-134T440-800q65 0 121.5 30t98.5 81v-81q0-13 8.5-21.5T690-800q13 0 21.5 8.5T720-770v168q0 13-8.5 21.5T690-572H522q-13 0-21.5-8.5T492-602q0-13 8.5-21.5T522-632h104q-32-48-80.5-78T440-740Zm0 499q-65 0-122.5-27T220-346v76q0 13-8.5 21.5T190-240q-13 0-21.5-8.5T160-270v-168q0-13 8.5-21.5T190-468h168q13 0 21.5 8.5T388-438q0 13-8.5 21.5T358-408H249q33 48 83 77.5T440-301q67 0 121-37.5t80-98.5q5-12 15.5-19.5T680-461q13 3 19.5 14t1.5 23q-8 21-19.5 40.5T656-347l163 163q9 9 9 21t-9 21q-9 9-21.5 9t-21.5-9L613-304q-37 31-81 47t-92 16Z"
		/>
	</svg>
);
