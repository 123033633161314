import { ReactNode } from 'react';

export const symbol_local_laundry_service: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M220-80q-24.75 0-42.375-17.625T160-140v-680q0-24.75 17.625-42.375T220-880h520q24.75 0 42.375 17.625T800-820v680q0 24.75-17.625 42.375T740-80H220Zm0-60h520v-680H220v680Zm260-59q80.51 0 137.255-56.745Q674-312.49 674-393q0-80.51-56.745-137.255Q560.51-587 480-587q-80.51 0-137.255 56.745Q286-473.51 286-393q0 80.51 56.745 137.255Q399.49-199 480-199Zm0-84q-22.681 0-44.053-8-21.373-8-37.947-24l159-159q17 15 25 36.56 8 21.56 8 44.44 0 45.833-32.083 77.917Q525.833-283 480-283ZM304.175-708q12.825 0 21.325-8.675 8.5-8.676 8.5-21.5 0-12.825-8.675-21.325-8.676-8.5-21.5-8.5-12.825 0-21.325 8.675-8.5 8.676-8.5 21.5 0 12.825 8.675 21.325 8.676 8.5 21.5 8.5Zm134 0q12.825 0 21.325-8.675 8.5-8.676 8.5-21.5 0-12.825-8.675-21.325-8.676-8.5-21.5-8.5-12.825 0-21.325 8.675-8.5 8.676-8.5 21.5 0 12.825 8.675 21.325 8.676 8.5 21.5 8.5ZM220-140v-680 680Z"
		/>
	</svg>
);
