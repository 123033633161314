export default {
	name: 'tag',
	objects: [
		{
			type: 'path',
			attributes: {
				d: 'M14.1818 4.97494L5.54274 13.614C5.26938 13.8874 5.26938 14.3306 5.54275 14.604L10.2209 19.2821C10.4942 19.5554 10.9374 19.5554 11.2108 19.2821L19.8499 10.643C19.9798 10.5131 20.0534 10.3373 20.0549 10.1536L20.092 5.43827C20.0951 5.04736 19.7775 4.72969 19.3866 4.73277L14.6713 4.76994C14.4875 4.77139 14.3117 4.84502 14.1818 4.97494ZM4.62351 12.6948C3.84246 13.4758 3.84246 14.7422 4.62351 15.5232L9.30162 20.2013C10.0827 20.9824 11.349 20.9824 12.13 20.2013L20.7691 11.5622C21.1403 11.191 21.3507 10.6888 21.3548 10.1638L21.392 5.44851C21.4008 4.33165 20.4932 3.42401 19.3763 3.43281L14.661 3.46998C14.1361 3.47412 13.6338 3.68449 13.2626 4.0557L4.62351 12.6948Z',
				fillRule: 'evenodd',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M16.8481 9.7203C17.3296 9.23879 17.3296 8.45811 16.8481 7.9766C16.3666 7.49509 15.5859 7.49509 15.1044 7.9766C14.6229 8.45811 14.6229 9.23879 15.1044 9.7203C15.5859 10.2018 16.3666 10.2018 16.8481 9.7203ZM17.7674 10.6395C18.7566 9.65034 18.7566 8.04655 17.7674 7.05736C16.7782 6.06817 15.1744 6.06817 14.1852 7.05736C13.196 8.04655 13.196 9.65034 14.1852 10.6395C15.1744 11.6287 16.7782 11.6287 17.7674 10.6395Z',
				fillRule: 'evenodd',
			},
		},
	],
};
