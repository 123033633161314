import { ReactNode } from 'react';

export const symbol_speaker_notes: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M281-399q17 0 28.5-11.5T321-439q0-17-11.5-28.5T281-479q-17 0-28.5 11.5T241-439q0 17 11.5 28.5T281-399Zm0-120q17 0 28.5-11.5T321-559q0-17-11.5-28.5T281-599q-17 0-28.5 11.5T241-559q0 17 11.5 28.5T281-519Zm0-120q17 0 28.5-11.5T321-679q0-17-11.5-28.5T281-719q-17 0-28.5 11.5T241-679q0 17 11.5 28.5T281-639Zm149 230h140q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T570-469H430q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T430-409Zm0-120h261q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T691-589H430q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T430-529Zm0-120h261q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T691-709H430q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T430-649ZM240-240 131-131q-14 14-32.5 6.344T80-152v-668q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H240Zm-26-60h606v-520H140v600l74-80Zm-74 0v-520 520Z"
		/>
	</svg>
);
