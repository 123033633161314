import { ReactNode } from 'react';

export const symbol_price_check: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m558-206 219-219q9-9 21-9t21 9q9 9 9 21t-9 21L579-143q-9 9-21 9t-21-9L409-271q-9-9-9-21t9-21q9-9 21-9t21 9l107 107ZM270-392v-15h-80q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T190-467h190v-105H191q-13.175 0-22.087-8.912Q160-589.825 160-603v-164q0-12.75 8.625-21.375T190-797h80v-15q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T330-812v15h80q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T410-737H220v105h189q13.175 0 22.087 8.912Q440-614.175 440-601v164q0 12.75-8.625 21.375T410-407h-80v15q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T270-392Z"
		/>
	</svg>
);
