import { ReactNode } from 'react';

export const symbol_airplanemode_active: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M350-80v-42l80-60v-251L80-330v-58l350-206v-236q0-21 14.5-35.5T480-880q21 0 35.5 14.5T530-830v236l350 206v58L530-433v251l80 60v42l-130-37-130 37Z"
		/>
	</svg>
);
