import { ReactNode } from 'react';

export const symbol_cast_connected: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M741-630v300q0 12.75-8.625 21.375T711-300h-99q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T612-360h69v-240H394q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T394-660h317q12.75 0 21.375 8.625T741-630ZM480-480Zm401-260v520q0 24.75-17.625 42.375T821-160H630q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T630-220h191v-520H141v30q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Q98-680 89.5-688.625T81-710v-30q0-24.75 17.625-42.375T141-800h680q24.75 0 42.375 17.625T881-740ZM310.533-160Q298.8-160 290-167.329q-8.8-7.328-11-19.671-9-69-56.5-117.5T107-362q-11-2-18.5-11.052-7.5-9.051-7.5-20.638Q81-406 88.5-415q7.5-9 18.5-8 93 9 158 75.5T340-188q2 11.789-7.5 19.895Q323-160 310.533-160ZM471-160q-12 0-21-8t-10-20q-11-134-103.5-229T111-523q-13-1-21.5-10.053-8.5-9.052-8.5-21.122Q81-567 91.5-575.5T116-583q154.76 12.835 263.38 123.418Q488-349 500-193q1 14-7.625 23.5T471-160Zm-352.141 0Q102-160 90.5-171.641t-11.5-28.5Q79-217 90.64-228.5q11.642-11.5 28.501-11.5t28.359 11.641q11.5 11.641 11.5 28.5T147.359-171.5q-11.641 11.5-28.5 11.5Z"
		/>
	</svg>
);
