import { ReactNode } from 'react';

export const symbol_wrong_location: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-880q16 0 32 2t32 5v61q-16-4-31.5-6t-32.5-2q-109 0-184.5 75T220-552q0 75 65 173.5T480-159q133-121 196.5-219.5T740-552q0-8-.5-16t-1.5-16h60q1 8 1.5 16t.5 16q0 94-69.5 202.5T520-115q-8 8-18.5 11.5T480-100q-11 0-21.5-3.5T440-115q-42-38-91-87.5T258-309q-42-57-70-119t-28-124q0-150 96.5-239T480-880Zm0 320Zm279-176 63 63q9 9 21 9t21-9q9-9 9-21t-9-21l-63-63 63-63q9-9 9-21t-9-21q-9-9-21-9t-21 9l-63 63-63-63q-9-9-21-9t-21 9q-9 9-9 21t9 21l63 63-63 63q-9 9-9 21t9 21q9 9 21 9t21-9l63-63ZM480-490q29 0 49.5-20.5T550-560q0-29-20.5-49.5T480-630q-29 0-49.5 20.5T410-560q0 29 20.5 49.5T480-490Z"
		/>
	</svg>
);
