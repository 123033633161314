import { ReactNode } from 'react';

export const symbol_car_repair: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M479.825-80Q467-80 458.5-88.625T450-110v-90H190q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T190-260h580q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T770-200H510v90q0 12.75-8.675 21.375Q492.649-80 479.825-80Zm-154.65-423q12.825 0 21.325-8.675 8.5-8.676 8.5-21.5 0-12.825-8.675-21.325-8.676-8.5-21.5-8.5-12.825 0-21.325 8.675-8.5 8.676-8.5 21.5 0 12.825 8.675 21.325 8.676 8.5 21.5 8.5Zm310 0q12.825 0 21.325-8.675 8.5-8.676 8.5-21.5 0-12.825-8.675-21.325-8.676-8.5-21.5-8.5-12.825 0-21.325 8.675-8.5 8.676-8.5 21.5 0 12.825 8.675 21.325 8.676 8.5 21.5 8.5Zm-405.35 183Q217-320 208.5-328.625T200-350v-256q0-4.9.5-9.45.5-4.55 2.5-9.55l60-175q6-17.778 21.75-28.889T320-840h320q19.5 0 35.25 11.111T697-800l60 175q2 5 2.5 9.55.5 4.55.5 9.45v256q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T700-350v-58H260v58q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM277-658h407l-41-122H317l-40 122Zm-17 60v130-130Zm0 130h440v-130H260v130Z"
		/>
	</svg>
);
