import { ReactNode } from 'react';

export const symbol_admin_panel_settings: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M690.882-270q25.883 0 44-19Q753-308 753-333.882q0-25.883-18.118-44-18.117-18.118-44-18.118Q665-396 646-377.882q-19 18.117-19 44Q627-308 646-289q19 19 44.882 19ZM689.5-145q33.5 0 60.5-14t46-40q-26-14-51.962-21-25.961-7-54-7-28.038 0-54.538 7-26.5 7-51.5 21 19 26 45.5 40t60 14ZM480-877q5 0 10.5 1t10.5 3l260 97q17.25 6.932 28.125 22.338Q800-738.257 800-719v187q0 12.325-9 20.663Q782-503 770-503t-21-9q-9-9-9-21v-186l-260-96-260 96v197q0 70 21 129.5T295-286q33 47 74.5 80.5T451-154q11 5 16 16.5t.385 22.629Q462-103 449.5-98 437-93 425-98q-125-51-195-170.741Q160-388.483 160-522v-197q0-19.257 10.875-34.662Q181.75-769.068 199-776l260-97q5.25-2.4 10.5-3.2 5.25-.8 10.5-.8ZM692.5-80Q615-80 560-135.5T505-267q0-78.435 54.99-133.717Q614.98-456 693-456q77 0 132.5 55.283Q881-345.435 881-267q0 76-55.5 131.5T692.5-80ZM480-479Z"
		/>
	</svg>
);
