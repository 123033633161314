import { ReactNode } from 'react';

export const symbol_noise_control_on: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480.266-80q-82.734 0-155.5-31.5t-127.266-86q-54.5-54.5-86-127.341Q80-397.681 80-480.5q0-82.819 31.5-155.659Q143-709 197.5-763t127.341-85.5Q397.681-880 480.5-880q82.819 0 155.659 31.5Q709-817 763-763t85.5 127Q880-563 880-480.266q0 82.734-31.5 155.5T763-197.684q-54 54.316-127 86Q563-80 480.266-80Zm.234-60Q622-140 721-239.5t99-241Q820-622 721.188-721 622.375-820 480-820q-141 0-240.5 98.812Q140-622.375 140-480q0 141 99.5 240.5t241 99.5Zm38.477-110q-35.977 0-64.53-22.326Q425.895-294.651 413-330q-3.375-8.828-7.312-17.103Q401.75-355.379 395-362l-50-51q-24.655-24.894-39.828-57.447Q290-503 290-538q0-72 49.558-122t120.356-50Q509-710 550.5-684.5T613-614q8 15-.75 29.5T586-570q-9 0-16.125-4.875T559-588q-14.28-28.786-40.644-45.393Q491.992-650 460.136-650 414-650 382-618.124q-32 31.876-32 78.041Q350-517 358.5-496t24.5 37l55 54q13.243 13.138 21.284 28.623Q467.324-360.892 473-344q5 15 18 24.5t29.023 9.5q13.558 0 25.268-7.5Q557-325 564-337q3-7 9.75-10t15-3Q606-350 615-335.5t1 28.5q-14 27-40.291 42-26.29 15-56.732 15Zm21.141-160Q561-410 575.5-424.618q14.5-14.617 14.5-35.5Q590-481 575.382-495.5q-14.617-14.5-35.5-14.5Q519-510 504.5-495.382q-14.5 14.617-14.5 35.5Q490-439 504.618-424.5q14.617 14.5 35.5 14.5Z"
		/>
	</svg>
);
