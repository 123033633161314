import { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import { useCurrentUser } from 'pkg/identity';

import Icon from 'components/icon';
import Avatar from 'components/avatar';

import TextArea from 'components/form/TextArea';
import * as iconStyles from 'components/icon/styles.css';

import Button from 'design/button';

const Wrapper = styled.div`
	padding: var(--spacing-2) 0;
	display: grid;
	grid-template-columns: 30px 1fr 30px;
	gap: var(--spacing-2);
	align-items: start;
	justify-content: center;

	textarea {
		font-size: var(--font-size-sm);
	}
`;

const EditWrapper = styled(Wrapper)`
	grid-template-columns: 1fr;
	grid-template-rows: auto auto;
	justify-items: end;
`;

const AvatarWrapper = styled.div`
	padding: var(--spacing-2);
`;

const SendButton = styled.div<{ canSend: boolean }>`
	width: 30px;
	height: 30px;
	display: grid;
	align-items: center;
	justify-content: center;
	align-self: end;
	margin-bottom: var(--spacing-2);
	color: ${({ canSend }) =>
		canSend ? styles.palette.blue[500] : styles.palette.gray[700]};

	.${iconStyles.icon} {
		width: 18px;
		height: 18px;
	}
`;

interface ReplyFormProps {
	content?: string;
	autoFocus?: boolean;
	placeholder?: string;
	isEditing?: boolean;

	onSend?: (content: string) => void;
}

export default function ReplyForm({
	content = '',
	autoFocus = true,
	placeholder = t(`Leave a reply`),
	isEditing = false,

	onSend,
}: ReplyFormProps): JSX.Element {
	const [commentContent, setContent] = useState<string>(content);
	const canSend = commentContent.length > 0 && !!onSend;
	const user = useCurrentUser();

	const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
		setContent(event.target.value);
	};

	const handleSend = () => {
		onSend(commentContent);
		setContent('');
	};

	if (isEditing) {
		return (
			<EditWrapper>
				<TextArea
					minRows={1}
					maxRows={8}
					onChange={handleChange}
					autoFocus={autoFocus}
					placeholder={placeholder}
					value={commentContent}
				/>
				<Button small primary onClick={handleSend}>
					{t('Save')}
				</Button>
			</EditWrapper>
		);
	}

	return (
		<Wrapper>
			<AvatarWrapper>
				<Avatar user={user} />
			</AvatarWrapper>
			<TextArea
				minRows={1}
				maxRows={4}
				onChange={handleChange}
				autoFocus={autoFocus}
				placeholder={placeholder}
				value={commentContent}
			/>
			<SendButton canSend={canSend} onClick={canSend ? handleSend : null}>
				<Icon name="plane" />
			</SendButton>
		</Wrapper>
	);
}
