import { Component, Fragment } from 'react';
import styled from 'styled-components';

import * as palette from 'pkg/config/palette';
import { small } from 'pkg/config/breakpoints';

import rgba from 'pkg/rgba';

import RangedSlider from 'components/form/RangeSlider';

const Slider = styled(RangedSlider)``;

const AverageWrapper = styled.div`
	display: flex;
	width: 100%;
	max-width: 60%;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding-bottom: 1rem;
	margin: 0 auto;
	flex: 0 0 auto;

	@media ${small} {
		max-width: 65%;
	}

	${Slider} {
		margin-top: 1rem;
	}
`;

const Label = styled.div`
	font-weight: var(--font-weight-semibold);
	font-size: var(--font-size-2xl);
	margin: 0;
	color: ${palette.black};
	padding: 2rem 0 1.5rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-flow: row wrap;

	span {
		display: inline-flex;
		width: 100%;
	}
`;

const Average = styled.input`
	height: 100px;
	width: 100px;
	line-height: 100px;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	border-radius: var(--radius-5);
	outline: none;
	background: ${palette.white};
	color: ${palette.black};
	font-size: 30px;
	border: 2px solid ${palette.gray[300]};
	margin-bottom: 0.5rem;

	-webkit-appearance: none;
	-moz-appearance: textfield;

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
`;

const ChildrenWrapper = styled.div`
	border-top: ${rgba(palette.card.innerDividerColor)} 1px solid;
	margin-top: 1rem;
	padding: 1rem 0 2rem;
	background: ${rgba(palette.gray[200], 0.4)};
	margin-top: 0;
	width: 100%;
	flex-flow: column;
	flex: 1 1 100%;
`;

export default class ItemCategory extends Component {
	shouldComponentUpdate(nextProps) {
		for (let i = 0; i < nextProps.children.length; i++) {
			const newItem = nextProps.children[i];
			const currentItem = this.props.children.find(
				(child) => child.props.item.id === newItem.props.item.id
			);

			if (currentItem.props.value !== newItem.props.value) {
				return true;
			}
		}

		return false;
	}

	setAverage = (value) => {
		this.props.onInput(
			Number.parseFloat(value),
			this.props.itemIds,
			this.props.parentId
		);
	};

	setTextAverage = (e) => {
		let value = Number.parseFloat(e.target.value);

		if (Number.isNaN(value)) {
			value = 0;
		}

		if (value <= 0.1) {
			value = Number.parseInt(value * 100);
		}

		const valueDiff = value - this.props.average;
		if (valueDiff < 0.1 && valueDiff !== 0 && value > this.props.average) {
			value = Math.floor(value) + valueDiff * 10;
		}

		this.props.onInput(value, this.props.itemIds, this.props.parentId);
	};

	get average() {
		return Number.parseFloat(this.props.average).toFixed(1);
	}

	render() {
		return (
			<Fragment>
				<AverageWrapper>
					<Label borderless={this.props.borderless}>
						<span>{this.props.label}</span>
					</Label>

					<Average
						type="number"
						pattern="^\d*(\.\d{0,1})?$"
						value={this.average}
						onChange={this.setTextAverage}
					/>

					<Slider
						value={this.props.average}
						step={0.1}
						thumbSize="large"
						min={0}
						max={10}
						onChange={this.setAverage}
					/>
				</AverageWrapper>

				<ChildrenWrapper>{this.props.children}</ChildrenWrapper>
			</Fragment>
		);
	}
}

ItemCategory.defaultProps = {
	parentId: 0,
};
