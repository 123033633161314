import { ReactNode } from 'react';

export const symbol_pediatrics: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M320-700q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T320-760h130v-90q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-850v90h130q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T640-700H320Zm20 620q-24.75 0-42.375-17.625T280-140v-390q0-50 35-85t85-35h160q50 0 85 35t35 85v390q0 24.75-17.625 42.375T620-80H340Zm0-60h280v-390q0-25.5-17.25-42.75T560-590H400q-25.5 0-42.75 17.25T340-530v50h100q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T440-420H340v110h100q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T440-250H340v110Zm0 0v-450 450Z"
		/>
	</svg>
);
