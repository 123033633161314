import { t } from '@transifex/native';
import { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import * as breakpoints from 'pkg/config/breakpoints';

import {
	makeGetUserRatings,
	getUserPrimaryRating,
} from 'pkg/selectors/ratings.selector';

import {
	fetchUsersRatings,
	fetchUsersPrimaryRating,
} from 'pkg/actions/ratings';

import { IdentityContext } from 'pkg/identity';

import Icon from 'components/icon';

import { PlaceholderType, Skeleton } from 'components/loaders/skeleton';
import RatingsCollection from 'components/user/rating/Collection';
import RatingFormModal from 'components/rating/RatingFormModal';
import { Spinner } from 'components/loaders/spinner';
import AssessmentCreate from 'components/assessment/Create';

import NoAssessments from 'design/placeholders/inline';

const AssessBody = styled.div`
	padding-bottom: 20px;

	@media ${breakpoints.small} {
		padding: 10px 10px 0;
	}
`;

class Assessment extends Component {
	static contextType = IdentityContext;

	_mounted = false;

	state = {
		showRatingForm: false,
		loaded: false,
		ratingId: 0,
		isFetching: false,
	};

	async componentDidMount() {
		this._mounted = true;

		if (this._mounted) {
			const state = { loaded: true };
			this.setState(state);
		}

		this.props.setDefinedPageActions();
		this.setState({ isFetching: true });
		await this.props.fetchUsersRatings(
			this.props.userId,
			this.context.membership.groupId,
			false,
			this.context.membership.targetUser?.accountId
		);
		this.setState({ isFetching: false });
	}

	componentWillUnmount() {
		this._mounted = false;
	}

	handleSaved = async () => {
		this.props.fetchUsersRatings(
			this.props.userId,
			this.context.membership.groupId,
			false,
			this.context.membership.targetUser?.accountId
		);
		this.props.fetchUsersPrimaryRating(
			this.props.userId,
			this.context.membership.groupId,
			this.context.membership.targetUser?.accountId
		);

		this.setState({ showRatingForm: false });
	};

	get ratingFormModal() {
		if (this.state.showRatingForm) {
			const modalProps = {
				key: 'rating-form-modal',
				ratingId: this.state.ratingId,
				openModal: this.openModal,
				closeModal: this.closeModal,
				userId: this.props.userId,
				groupId: this.context.membership.groupId,
				handleSaved: this.handleSaved,
			};

			return <RatingFormModal {...modalProps} />;
		}

		return null;
	}

	handleOpenEditModal = (ratingId) => {
		this.setState({
			ratingId,
			showRatingForm: true,
		});
	};

	openModal = () => {
		this.setState({ showRatingForm: true });
	};

	closeModal = async (p) => {
		await p;
		this.setState({ showRatingForm: false, ratingId: 0 });
	};

	closeAssessmentModal = async (p) => {
		await p;
		this.props.onCloseModal();
	};

	get hideClose() {
		return (
			!this.props.hasRatings &&
			(this.context.membership ? this.context.membership.role : 1) === 1
		);
	}

	get createAssessmentModal() {
		if (this.props.showCreateModal) {
			return (
				<AssessmentCreate
					hideClose={this.hideClose}
					userId={this.props.userId}
					groupId={this.context.membership.groupId}
					onClose={this.closeAssessmentModal}
					userRole={this.context.membership ? this.context.membership.role : 1}
				/>
			);
		}
	}

	render() {
		if (this.state.isFetching) {
			return <Spinner />;
		}

		const items = [];

		if (!this.props.hasRatings) {
			items.push(
				<AssessBody key="no-assess-body">
					<NoAssessments key="NoAssessments" onClick={this.openAssessmentModal}>
						<Icon name="nav-assess" />
						{t(`No Assessments`)}
					</NoAssessments>
				</AssessBody>
			);
		}

		if (this.props.hasRatings) {
			items.push(
				<RatingsCollection
					key="RatingsCollection"
					activeRatingId={this.props.activeRatingId}
					userId={this.props.userId}
					groupId={this.context.membership.groupId}
					openEditModal={this.handleOpenEditModal}
					borderTop={false}
				/>
			);
		}

		if (this.state.loaded) {
			return [items, this.ratingFormModal, this.createAssessmentModal];
		}

		return <Skeleton placeholderType={PlaceholderType.List} numItems={6} />;
	}
}

const mapStateToProps = () => {
	const getRatings = makeGetUserRatings();

	return (state, props) => {
		return {
			primaryRating: getUserPrimaryRating(state, props),
			search: state.app.search,
			hasRatings: getRatings(state, { userId: props.userId }).length > 0,
		};
	};
};

const actions = {
	fetchUsersRatings,
	fetchUsersPrimaryRating,
};

export default connect(mapStateToProps, actions)(Assessment);
