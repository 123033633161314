import { useQueryState } from 'pkg/hooks/query-state';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';

export type ContentTypeFilterKey =
	| 'videos'
	| 'playlists'
	| 'collections'
	| 'sessions'
	| 'exercises';

interface ContentTypeFilter {
	videos: boolean;
	playlists: boolean;
	collections: boolean;
	sessions: boolean;
	exercises: boolean;

	toggle: (contentType: ContentTypeFilterKey) => void;
}

export function useContentTypeFilter(): ContentTypeFilter {
	const qs = useQueryState();

	const videos = !qs.has('videos');
	const playlists = !qs.has('playlists');
	const collections = !qs.has('collections');
	const sessions = !qs.has('sessions');
	const exercises = !qs.has('exercises');

	useComponentDidMount(() => {
		qs.flush();
	});

	const toggle = (contentType: ContentTypeFilterKey) => {
		if (qs.has(contentType)) {
			qs.remove(contentType);
		} else {
			qs.set(contentType, '0');
		}

		qs.commit();
	};

	return {
		videos,
		playlists,
		collections,
		sessions,
		exercises,

		toggle,
	};
}
