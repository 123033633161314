export const EVENT_SET_USERS_SUCCESS = 'event/SET_USERS_SUCCESS';
export const ACCEPTED_EVENT_INVITE = 'event/ACCEPTED_INVITE';
export const SET_PENDING_EVENTS = 'event/SET_PENDING_EVENTS';
export const REMOVE_PENDING_EVENT = 'event/REMOVE_PENDING_EVENT';

export const ACTIVITY_POST_SUCCESS = 'activity/POST_SUCCESS';
export const ACTIVITY_UPDATE_SUCCESS = 'activity/UPDATE_SUCCESS';
export const ACTIVITY_REMOVE_SUCCESS = 'activity/REMOVE_SUCCESS';

export const AUTH_GET_ME_SUCCESS = 'auth/GET_ME_SUCCESS';
export const AUTH_ALREADY_AUTHORIZED = 'auth/ALREADY_AUTHORIZED';
export const AUTH_LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
export const AUTH_LOGOUT_SUCCESS = 'auth/LOGOUT_SUCCESS';

export const APP_SET_COLLAPSED_DRAWER = 'app/SET_COLLAPSED_DRAWER';
export const APP_SET_DRAWER_VISIBILITY = 'app/SET_DRAWER_VISIBILITY';
export const APP_API_ERROR = 'app/API_ERROR';
export const APP_RESET_API_ERROR = 'app/RESET_API_ERROR';
export const APP_SET_NAVBAR_HEIGHT = 'app/SET_NAVBAR_HEIGHT';
export const APP_SET_TABBAR_HEIGHT = 'app/SET_TABBAR_HEIGHT';
export const APP_SET_URL = 'app/SET_APP_URL';
export const APP_SET_TABBAR_VISIBILITY = 'app/SET_TABBAR_VISIBILITY';
export const APP_SET_DARK_MODE = 'app/APP_SET_DARK_MODE';
export const APP_SET_PARENTAL_CONSENT = 'app/SET_PARENTAL_CONSENT';
export const APP_SET_FORCE_NAVIGATE_BACK_LINK =
	'app/SET_FORCE_NAVIGATE_BACK_LINK';

export const FLASH_SET_VISIBILITY = 'flash/SET_VISIBILITY';
export const FLASH_SET_MESSAGE = 'flash/SET_MESSAGE';

export const ADD_MATCHES = 'match/ADD_MATCHES';
export const REMOVE_MATCH = 'match/REMOVE_MATCH';
export const MATCH_UPDATE_PROPERTY = 'match/UPDATE_PROPERTY';

export const RATING_ADD_SINGLE_USER_RATING = 'rating/ADD_SINGLE_USER_RATING';

export const ADD_VIDEO = 'videos/ADD_VIDEO';
export const ADD_VIDEOS = 'videos/ADD_VIDEOS';
export const UPDATE_VIDEO = 'videos/UPDATE_VIDEO';
export const REMOVE_VIDEO = 'videos/REMOVE_VIDEO';

export const ADD_MATCH_VIDEOS = 'videos/ADD_MATCH_VIDEOS';
export const REMOVE_MATCH_VIDEO = 'videos/REMOVE_MATCH_VIDEO';

export const ADD_GROUP_VIDEOS = 'groupVideos/ADD_GROUP_VIDEOS';
export const EVENT_REMOVE_EVENT_USER = 'matchEvent/REMOVE_EVENT_USER';

// Action enums, place any new actions here.
// The top level constants above this comment should be refactored into enums.

export enum Users {
	ADD_USERS = 'user/ADD_USERS',
	REMOVE_USERS = 'users/REMOVE_USERS',
	SET_ACTIVE_MEMBERSHIP = 'user/SET_ACTIVE_MEMBERSHIP',
	USER_PATCH_ERROR = 'user/PATCH_ERROR',
	USER_LOG_REMOVE_SUCCESS = 'user/LOG_REMOVE_SUCCESS',
	USER_PERSONAL_DATA = 'user/USER_PERSONAL_DATA',
}

export enum MatchEvents {
	ADD_EVENTS = 'matchEvents/ADD_ITEMS',
	ADD_EVENT_TYPES = 'matchEvents/ADD_EVENT_TYPES',
	ADD_GUIDS = 'matchEvents/ADD_GUIDS',
	ADD_LEADING_GUID = 'matchEvents/ADD_LEADING_GUID',
	REMOVE_SINGLE_EVENT = 'matchEvents/REMOVE_SINGLE_EVENT',
	REMOVE_GUIDS = 'matchEvents/REMOVE_GUIDS',
	UPDATE_USER = 'matchEvents/UPDATE_USER',
	UPDATE_MINUTE = 'matchEvents/UPDATE_MINUTE',
	UPDATE_COMMENT = 'matchEvents/UPDATE_COMMENT',
	UPDATE_POSITION = 'matchEvents/UPDATE_POSITION',
	UPDATE_SUBSTITUTION_USER = 'matchEvents/UPDATE_SUBSTITUTION_USER',
	EDIT_MODAL_OPEN = 'matchEvents/EDIT_MODAL_OPEN',
	EDIT_DONE = 'matchEvents/EDIT_DONE',
	EDIT_CANCEL = 'matchEvents/EDIT_CANCEL',
	SET_COORDINATES = 'matchEvents/SET_COORDINATES',
	SET_GUIDS = 'matchEvents/SET_GUIDS',
}

export enum MatchOpponents {
	SET_ITEMS = 'matchOpponents/SET_ITEMS',
}

export enum MatchCompetitions {
	SET_ITEMS = 'matchCompetition/ADD_ITEMS',
	DELETE_ITEM = 'matchCompetition/DELETE_ITEM',
}

export enum Ratings {
	SetItems = 'ratings/SET_ITEMS',
	AddItems = 'ratings/ADD_ITEMS',
}

export enum RatingItems {
	SET_ITEMS = 'ratingItems/SET_ITEMS',
}

export enum Exercises {
	SET_ITEMS = 'exercises/SET_ITEMS',
	DELETE_ITEM = 'exercises/DELETE_ITEM',
}

export enum EventSeries {
	SET_ITEMS = 'eventSeries/SET_ITEMS',
}

export enum EventSeriesUsers {
	SET_ITEMS = 'eventSeriesUsers/SET_ITEMS',
}

export enum EventComments {
	SET_ITEMS = 'eventComments/SET_ITEMS',
}

export enum PerformanceReviews {
	SET_ITEMS = 'performanceReview/SET_ITEMS',
}

export enum Countries {
	SET_ITEMS = 'countries/SET_ITEMS',
}

export enum Memberships {
	SET_ITEMS = 'memberships/SET_ITEMS',
	DELETE_ITEM = 'memberships/DELETE_ITEM',
}

export enum UserGoals {
	ADD_ITEMS = 'userGoals/ADD_ITEMS',
	REMOVE_ITEM = 'userGoals/REMOVE_ITEM',
}

export enum Groups {
	ADD_ITEM = 'group/ADD_ITEM',
	ADD_ITEMS = 'group/ADD_ITEMS',
	DELETE_ITEM = 'group/DELETE_ITEM',
	SET_INVITES = 'group/SET_INVITES',

	CREATE_IMPORT_CONNECTION = 'group/CREATE_IMPORT_CONNECTION',
	DELETE_IMPORT_CONNECTION = 'group/DELETE_IMPORT_CONNECTION',

	UPDATE_GROUP_KEY_VALUE = 'group/UPDATE_PRIMARY_COLOR',

	SET_BANNED_MEMBERS = 'group/SET_BANNED_MEMBERS',
	SET_PENDING_MEMBERS = 'group/SET_PENDING_MEMBERS',
}

export enum Sessions {
	SET_ITEMS = 'sessions/SET_ITEMS',
	DELETE_ITEM = 'sessions/DELETE_ITEM',
	SET_MODE = 'sessions/SET_MODE',
	UNSET_MODE = 'sessions/UNSET_MODE',
}

export enum SessionBlocks {
	SET_ITEMS = 'sessionBlocks/SET_ITEMS',
	DELETE_ITEM = 'sessionBlocks/DELETE_ITEM',
	DELETE_ITEMS = 'sessionBlocks/DELETE_ITEMS',

	SET_MODE = 'sessionBlocks/SET_MODE',
	UNSET_MODE = 'sessionBlocks/UNSET_MODE',
}

export enum SessionItems {
	SET_ITEMS = 'sessionItems/SET_ITEMS',
	DELETE_ITEM = 'sessionItems/DELETE_ITEM',

	SET_MODE = 'sessionItems/SET_MODE',
	UNSET_MODE = 'sessionItems/UNSET_MODE',
}

export enum Sports {
	SET_ITEMS = 'sport/SET_ITEMS',
}

export enum TrainingCollections {
	SET_ITEMS = 'trainingCollections/SET_ITEMS',
	SET_INHERITED_ITEMS = 'trainingCollections/SET_INHERITED_ITEMS',
	DELETE_ITEM = 'trainingCollections/DELETE_ITEM',
	ADD_EXERCISE = 'trainingCollections/ADD_EXERCISE',
	ADD_SESSION = 'trainingCollections/ADD_SESSION',
	REMOVE_EXERCISE = 'trainingCollections/REMOVE_EXERCISE',
	REMOVE_SESSION = 'trainingCollections/REMOVE_SESSION',
}

export enum Tags {
	SET_ITEMS = 'tags/SET_ITEMS',
	DELETE_ITEM = 'tags/DELETE_ITEM',
}

export enum UserVideos {
	ADD_ITEMS = 'userVideos/ADD_ITEMS',
	RESET_ITEMS = 'userVideos/RESET_ITEMS',
}

export enum AccountRelations {
	SET_ITEMS = 'accountRelations/SET_ITEMS',
	DELETE_ITEM = 'accountRelations/DELETE_ITEM',
	SET_ACTIVE_REQUEST_ITEM = 'accountRelations/SET_ACTIVE_REQUEST_ITEM',
}

export enum Notifications {
	SET_ITEMS = 'notifications/SET_ITEMS',
	SET_HAS_UNREAD = 'notifications/SET_HAS_UNREAD',
}

export enum VideoSequenceComments {
	SET_ITEMS = 'videoSequenceComments/SET_ITEMS',
	UPDATE_ITEM = 'videoSequenceComments/UPDATE_ITEM',
	DELETE_ITEM = 'videoSequenceComments/DELETE_ITEM',
}

export enum VideoPlaylists {
	SET_ITEMS = 'videoPlaylists/SET_ITEMS',
	UPDATE_ITEM = 'videoPlaylists/UPDATE_ITEM',
	DELETE_ITEM = 'videoPlaylists/DELETE_ITEM',
}

export enum VideoPlaylistSequences {
	SET_ITEMS = 'videoPlaylistSequences/SET_ITEMS',
	UPDATE_ITEM = 'videoPlaylistSequences/UPDATE_ITEM',
	DELETE_ITEM = 'videoPlaylistSequences/DELETE_ITEM',
}

export enum VideoSequences {
	SET_ITEMS = 'videoSequences/SET_ITEMS',
	UPDATE_ITEM = 'videoSequences/UPDATE_ITEM',
	DELETE_ITEM = 'videoSequences/DELETE_ITEM',
}

export enum VideoSequenceUsers {
	SET_ITEMS = 'videoSequenceUsers/SET_ITEMS',
	UPDATE_ITEM = 'videoSequenceUsers/UPDATE_ITEM',
	DELETE_ITEM = 'videoSequenceUsers/DELETE_ITEM',
}

export enum Accounts {
	SET_ITEMS = 'accounts/SET_ITEMS',
}

export enum ProductCategories {
	SET_ITEMS = 'productCategories/SET_ITEMS',
	DELETE_ITEM = 'productCategories/DELETE_ITEM',
}

export enum Products {
	SET_ITEMS = 'products/SET_ITEMS',
}

export enum Prices {
	SET_ITEMS = 'prices/SET_ITEMS',
}

export enum TaxRates {
	SET_ITEMS = 'taxRates/SET_ITEMS',
	DELETE_ITEM = 'taxRates/DELETE_ITEM',
}

export enum Currencies {
	SET_ITEMS = 'currencies/SET_ITEMS',
}

export enum Orders {
	SET_ITEMS = 'orders/SET_ITEMS',
	SET_LINKS = 'orders/SET_LINKS',
	SET_STATUS = 'orders/SET_STATUS',
	DELETE_ITEMS = 'orders/DELETE_ITEMS',
}

export enum UserProducts {
	SET_ITEMS = 'userProduct/SET_ITEMS',
	DELETE_ITEM = 'userProduct/DELETE_ITEM',
}

export enum Forms {
	SET_ITEMS = 'forms/SET_ITEMS',
}

export enum FormSubmissions {
	SET_ITEMS = 'formSubmissions/SET_ITEMS',
}

export enum FormCategories {
	SET_ITEMS = 'formCategory/SET_ITEMS',
}

// View specific actions

export const views = {
	trainingLibrary: {
		SET_EXERCISE_RESULT: 'views/SET_EXERCISE_RESULT',
		SET_SESSION_RESULT: 'views/SET_SESSION_RESULT',
	},
};
