import { ReactNode } from 'react';

export const symbol_airline_seat_flat: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M390-400q-12.75 0-21.375-8.625T360-430v-190q0-24.75 17.625-42.375T420-680h308q63 0 107 44t44 107v99q0 12.75-8.625 21.375T849-400H390Zm30-220v160-160Zm430 340H110q-12.75 0-21.375-8.675Q80-297.351 80-310.175 80-323 88.625-331.5T110-340h740q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-280ZM200-400q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35Zm0-60q25.5 0 42.75-17.25T260-520q0-25.5-17.25-42.75T200-580q-25.5 0-42.75 17.25T140-520q0 25.5 17.25 42.75T200-460Zm220 0h399v-69q0-37.537-26.731-64.269Q765.537-620 728-620H420v160Zm-220-60Z"
		/>
	</svg>
);
