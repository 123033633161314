import { ReactNode } from 'react';

export const symbol_prompt_suggestion: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M725-406H295q-73 0-124-52t-51-125q0-74 51-126t124-52q13 0 21.5 8.5T325-731q0 13-8.5 21.5T295-701q-48 0-81.5 34.5T180-583q0 48 33.5 82.5T295-466h430L585-608q-9-9-8.5-21t9.5-21q9-9 21-9t21 9l191 193q9 9 9 21t-9 21L628-222q-9 9-21.5 9t-21.5-9q-9-9-9-21t9-21l140-142Z"
		/>
	</svg>
);
