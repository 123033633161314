import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Fragment } from 'react';
import { t } from '@transifex/native';

import User from 'pkg/models/user';
import Tag from 'pkg/models/tag';

import { formatTime } from 'pkg/timeline';
import * as selectors from 'pkg/selectors';
import { RootState } from 'pkg/reducers';

import ClipDiscussion from 'routes/video/shared/ClipDiscussion';
import Clip from 'routes/video/shared/clips/CurrentClip';
import ClipComment from 'routes/video/shared/ClipComment';

import Avatar from 'components/avatar';

import { useCueState } from 'components/video-analytics/CueState';

const Divider = styled.div`
	height: 1px;
	background-color: var(--palette-gray-700);
`;

const TagItem = styled.span`
	padding: var(--spacing-2) var(--spacing-3);
	margin: 0 var(--spacing-2) var(--spacing-2) 0;
	background: var(--palette-gray-800);
	font-size: var(--font-size-xs);
	height: 24px;
	border-radius: var(--radius-2);
	border: 1px solid var(--palette-gray-600);
	display: inline-grid;
	grid-auto-flow: column;
	gap: var(--spacing-3);
	place-items: center;
	position: relative;

	&[data-tag-type='user'] {
		padding-left: var(--spacing-2);

		${Avatar} {
			border-radius: var(--radius-3);
		}
	}

	&[data-tag-type='tag'] {
		top: -3px;
	}
`;

export default function CurrentClip(): JSX.Element {
	const { currentCue } = useCueState();

	const clipId = currentCue?.cueId as number;

	const clip = useSelector((state: RootState) =>
		selectors.videoSequences.find(state, clipId)
	);

	const author = useSelector((state: RootState) =>
		selectors.accounts.find(state, clip?.accountId)
	);

	const users = useSelector((state: RootState) =>
		selectors.videoSequenceUsers.findSequenceUsers(state, clipId)
	);

	const tags = useSelector((state: RootState) =>
		selectors.videoSequences.findAllTags(state, clipId)
	);

	if (!clip?.valid()) return null;

	return (
		<Clip
			title={clip.title}
			subTitle={t('{starts_at} ‒ {ends_at} by {author_name}', {
				starts_at: formatTime(clip.getStartsAt()),
				ends_at: formatTime(clip.getEndsAt()),
				author_name: author.fullName,
			})}
			isHighlight={clip.reviewed}
			isPrivate={clip.private}
			description={clip.description}>
			<ClipComment clipId={clipId} />
			{(users.size > 0 || tags.length > 0) && (
				<Fragment>
					<Divider />
					<div>
						{users.map((user: User) => (
							<TagItem key={user.id} data-tag-type="user">
								<Avatar user={user} size={14} />
								<span>{user.fullName}</span>
							</TagItem>
						))}
						{tags.map((tag: Tag) => (
							<TagItem key={tag.id} data-tag-type="tag">
								{tag.name.replace(/\_/g, ' ')}
							</TagItem>
						))}
					</div>
				</Fragment>
			)}
			<Divider />
			<ClipDiscussion />
		</Clip>
	);
}
