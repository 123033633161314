import { ReactNode } from 'react';

export const symbol_vertical_distribute: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M110-80q-12.75 0-21.375-8.675Q80-97.351 80-110.175 80-123 88.625-131.5T110-140h740q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q880-97 871.375-88.5T850-80H110Zm220-350q-20.833 0-35.417-14.618Q280-459.235 280-480.118 280-501 294.583-515.5 309.167-530 330-530h300q20.833 0 35.417 14.618Q680-500.765 680-479.882 680-459 665.417-444.5 650.833-430 630-430H330ZM110-820q-12.75 0-21.375-8.675Q80-837.351 80-850.175 80-863 88.625-871.5T110-880h740q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-820H110Z"
		/>
	</svg>
);
