import { ReactNode } from 'react';

export const symbol_flag_circle: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M375-440h105l32 63q4 8 11.5 12.5T539-360h151q13 0 21.5-8.5T720-390v-180q0-13-8.5-21.5T690-600h-90l-32-63q-4-8-11.5-12.5T541-680H350q-13 0-21.5 8.5T320-650v383q0 11 8.5 19t19.5 8q11 0 19-8.5t8-19.5v-172Zm182 25-40-80H375v-130h148l40 80h102v130H557ZM480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm0-60q142 0 241-99.5T820-480q0-142-99-241t-241-99q-141 0-240.5 99T140-480q0 141 99.5 240.5T480-140Zm0-340Z"
		/>
	</svg>
);
