import { Fragment, useState } from 'react';
import { t } from '@transifex/native';
import { useDispatch } from 'react-redux';

import { APP_SET_TABBAR_VISIBILITY } from 'pkg/actions/action-types';

import * as sdk from 'pkg/core/sdk';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import * as actions from 'pkg/actions';
import { FilterGroups, useFilters } from 'pkg/filters/use_filters';
import { FilterOperator, createFilterGroups } from 'pkg/filters';
import { UserSex, translatedSexString } from 'pkg/api/models/user';
import { useEndpoint } from 'pkg/api/use_endpoint';
import { useCurrentMembership } from 'pkg/identity';

import { DialogProps } from 'routes/group/settings/OrganizationTable';
import SearchedTree from 'routes/organization/list/SearchedTree';
import BaseTree from 'routes/organization/list/BaseTree';
import SortOrder from 'routes/organization/list/sort-order';

import * as ActionBar from 'components/layout/ActionBar';

interface Props {
	organizationId: number;
	groupId?: number;
}

const Organization: React.FC<React.PropsWithChildren<Props>> = ({
	organizationId,
	groupId,
}) => {
	const dispatch = useDispatch();
	const membership = useCurrentMembership();

	const { record: rootGroup } = useEndpoint<models.group.Group>(
		endpoints.Groups.Show(groupId ?? organizationId),
		{
			queryParams: new URLSearchParams({
				include_sub_group_count: '1',
				include_player_count: '1',
				include_admin_count: '1',
				include_pending_count: '1',
			}),
		}
	);

	const initialCreateGroup: DialogProps = {
		show: false,
		group: {} as models.group.Group,
		defaultSelected: rootGroup,
		type: 'create',
	};

	const [dialog, setDialog] = useState<DialogProps>(initialCreateGroup);
	const [isSorting, setSorting] = useState<boolean>(false);

	const startSorting = () => {
		setSorting(true);

		dispatch({
			type: APP_SET_TABBAR_VISIBILITY,
			tabBarVisible: false,
		});
	};

	const cancelSorting = () => {
		setSorting(false);

		dispatch({
			type: APP_SET_TABBAR_VISIBILITY,
			tabBarVisible: true,
		});
	};

	const handleCreateGroup = (): void => {
		setDialog({ ...initialCreateGroup, show: true });
	};

	const handleUpdateOrder = async (payload: any) => {
		const request = await sdk.patch(
			endpoints.Groups.UpdateGroupOrder(organizationId),
			{},
			payload
		);

		if (!request.ok) {
			actions.flashes.show({
				title: t('Could not update group order'),
			});
		}
	};

	const filterGroups: FilterGroups = createFilterGroups({
		[t('Name')]: {
			hidden: true,
			filters: {
				Name: {
					type: 'text',
					operator: FilterOperator.Contains,
					property: 'name',
				},
			},
		},
		[t('Gender')]: {
			filters: {
				Category: {
					type: 'checkbox',
					operator: FilterOperator.Includes,
					property: 'sex',
					values: {
						[translatedSexString(UserSex.Male)]: UserSex.Male,
						[translatedSexString(UserSex.Female)]: UserSex.Female,
						[translatedSexString(UserSex.NotApplicable)]: UserSex.NotApplicable,
					},
				},
			},
		},
		[t('Birth Year From')]: {
			filters: {
				Name: {
					type: 'number',
					property: 'birthYearFrom',
				},
			},
		},
		[t('Birth Year To')]: {
			filters: {
				Name: {
					type: 'number',
					property: 'birthYearTo',
				},
			},
		},
	});

	const filters = useFilters({
		groups: filterGroups,
		filterBarMobileTrigger: true,
	});

	filters.queryParam.set('include_sub_group_count', '1');
	filters.queryParam.set('include_player_count', '1');
	filters.queryParam.set('include_admin_count', '1');
	filters.queryParam.set('recursive', '1');
	filters.queryParam.set('include_pending_count', '1');

	const isSearch = filters.queryParam.has('filters');

	if (isSorting) {
		return (
			<SortOrder
				rootGroup={rootGroup}
				onFinish={cancelSorting}
				onProcessing={handleUpdateOrder}
			/>
		);
	}

	return (
		<Fragment>
			<ActionBar.IntegratedFilterBar
				searchFilter={{
					type: 'text',
					operator: FilterOperator.Contains,
					property: 'name',
				}}
				filters={filters}
				pageActionIcon="more_horiz"
				actions={
					models.membership.isAdmin(membership)
						? [
								{
									label: t('Sort groups'),
									onClick: startSorting,
								},
								{
									label: t('Create new group'),
									onClick: handleCreateGroup,
									type: 'primary',
									icon: 'add',
								},
							]
						: []
				}
			/>

			{isSearch ? (
				<SearchedTree
					rootGroup={rootGroup}
					filters={filters}
					dialog={dialog}
					setDialog={setDialog}
				/>
			) : (
				<BaseTree dialog={dialog} setDialog={setDialog} rootGroup={rootGroup} />
			)}
		</Fragment>
	);
};

export default Organization;
