import { ReactNode } from 'react';

export const symbol_atm: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M409-552h-56q-10.4 0-17.2-6.8-6.8-6.8-6.8-17.2 0-10.4 6.8-17.2 6.8-6.8 17.2-6.8h160q10.4 0 17.2 6.8 6.8 6.8 6.8 17.2 0 10.4-6.8 17.2-6.8 6.8-17.2 6.8h-56v168q0 10.4-6.8 17.2-6.8 6.8-17.2 6.8-10.4 0-17.2-6.8-6.8-6.8-6.8-17.2v-168ZM128-437v53q0 10.4-6.8 17.2-6.8 6.8-17.2 6.8-10.4 0-17.2-6.8Q80-373.6 80-384v-182q0-15 9.5-24.5T114-600h139q15 0 24.5 9.5T287-566v182q0 10.4-6.8 17.2-6.8 6.8-17.2 6.8-10.4 0-17.2-6.8-6.8-6.8-6.8-17.2v-53H128Zm0-48h111v-67H128v67Zm506-67v168q0 10.4-6.8 17.2-6.8 6.8-17.2 6.8-10.4 0-17.2-6.8-6.8-6.8-6.8-17.2v-182q0-15 9.5-24.5T620-600h226q15 0 24.5 9.5T880-566v182q0 10.4-6.8 17.2-6.8 6.8-17.2 6.8-10.4 0-17.2-6.8-6.8-6.8-6.8-17.2v-168h-75v126q0 10.4-6.8 17.2-6.8 6.8-17.2 6.8-10.4 0-17.2-6.8-6.8-6.8-6.8-17.2v-126h-75Z"
		/>
	</svg>
);
