import { ReactNode } from 'react';

export const symbol_arrow_right: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M415.316-301q-6.316 0-10.816-4.125Q400-309.25 400-316v-328q0-6.75 4.636-10.875Q409.273-659 415.455-659 417-659 426-654l158 158q3 3 5 7.062 2 4.063 2 8.938 0 4.875-2 8.938-2 4.062-5 7.062L426-306q-2 2-4.763 3.5t-5.921 1.5Z"
		/>
	</svg>
);
