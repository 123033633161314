import { ReactNode } from 'react';

export const symbol_exit_to_app: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-120q-24 0-42-18t-18-42v-180q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T180-360v180h600v-600H180v180q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T120-600v-180q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm306-330H150q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-510h336l-96-96q-9-9-8.5-22t9.109-22q9.391-9 22.891-9 13.5 0 22.5 9l149 149q5 5 7 10.133 2 5.134 2 11Q594-474 592-469q-2 5-7 10L435-309q-9.333 9-22.167 8.5Q400-301 390.609-310 382-319 382-332.5t9-22.5l95-95Z"
		/>
	</svg>
);
