import { ReactNode } from 'react';

export const symbol_web_stories: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M700-140v-680q28 0 44 14.5t16 45.5v560q0 31-16 45.5T700-140ZM140-80q-24.75 0-42.375-17.625T80-140v-680q0-24.75 17.625-42.375T140-880h400q24.75 0 42.375 17.625T600-820v680q0 24.75-17.625 42.375T540-80H140Zm720-121v-557q20 0 30 8.5t10 31.5v477q0 23-10 31.5t-30 8.5Zm-720 61h400v-680H140v680Zm0-680v680-680Z"
		/>
	</svg>
);
