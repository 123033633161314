import { t } from '@transifex/native';
import { ChangeEvent } from 'react';

import * as styles from 'pkg/config/styles';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as api from 'pkg/api';
import * as models from 'pkg/api/models';

import { useSmallScreen } from 'components/MediaQuery';
import SectionTitle from 'components/SectionTitle';
import SectionDescription from 'components/SectionDescription';
import { useStepModalContext } from 'components/step-modal';

import * as Inputs from 'components/form/inputs';
import Column from 'components/layout/column';
import Row from 'components/layout/row';

import Button from 'design/button';
import Divider from 'design/divider';

interface EmailStepProps {
	targetAccounts?: models.account.Account[];
	email?: string;
	setEmail: (email: string) => void;
	setInviteKey: (link: string) => void;
	multipleChildren: boolean;
}

export default function EmailInviteStep({
	targetAccounts,
	email,
	setEmail,
	setInviteKey,
	multipleChildren,
}: EmailStepProps): JSX.Element {
	const { goTo } = useStepModalContext();

	const targets = targetAccounts.map((targetAccount) => {
		return { type: 'child', targetId: targetAccount.id };
	});

	const handleSetEmail = (event: ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value);
	};

	const handleSendInvite = async () => {
		const [request] = await api.post(endpoints.AccountInvites.Index(), {
			email,
			targets,
		});

		if (request.ok) {
			goTo('email-invite-sent');
		}
	};

	const generateInviteLink = async () => {
		const [request, response] =
			await api.post<models.accountInvite.AccountInvite>(
				endpoints.AccountInvites.Index(),
				{
					targets,
				}
			);

		if (request.ok) {
			setInviteKey(response.key);
			goTo('invite-link-generated');
		}
	};

	const isSmallScreen = useSmallScreen();
	const validEmail = !!email && email.includes('@');

	let title: string;
	let description: string;

	if (multipleChildren) {
		title = t('Connect a parent to your children!');

		description = t(
			'Add the email address of the parent that you want to connect to your children! If they already have a 360Player account, make sure to use that email.'
		);
	} else {
		const targetAccount = targetAccounts[0];

		title = t('Connect a parent to {child_name}!', {
			child_name: models.account.fullName(targetAccount),
		});

		description = t(
			'Add the email address of the parent that you want to connect to {child_first_name}! If they already have a 360Player account, make sure to use that email.',
			{ child_first_name: targetAccount.firstName }
		);
	}

	return (
		<Column spacing={styles.spacing._8}>
			<Column spacing={styles.spacing._2}>
				<SectionTitle>{title}</SectionTitle>
				<SectionDescription>{description}</SectionDescription>
			</Column>

			<Column>
				<Inputs.Group label={t('Email')}>
					<Row collapseOnSmallScreens align="center" columns="1fr auto">
						<Inputs.Field
							onChange={handleSetEmail}
							value={email}
							type="email"
						/>
						<Button
							primary
							large
							block={isSmallScreen}
							label={t('Send invite')}
							disabled={!validEmail}
							onClick={handleSendInvite}
						/>
					</Row>
				</Inputs.Group>
			</Column>

			<Divider text={t('or')} small />

			<Button
				secondary
				large
				label={t('Generate invite link')}
				icon="ios_share"
				onClick={generateInviteLink}
			/>
		</Column>
	);
}
