const single_choice = {
	name: 'single-choice',
	objects: [
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M12.2139 20.0337C16.1056 20.0337 19.2605 16.8788 19.2605 12.9871C19.2605 9.09531 16.1056 5.94041 12.2139 5.94041C8.32211 5.94041 5.16721 9.09531 5.16721 12.9871C5.16721 16.8788 8.32211 20.0337 12.2139 20.0337ZM12.2139 21.3337C16.8236 21.3337 20.5605 17.5968 20.5605 12.9871C20.5605 8.37734 16.8236 4.64041 12.2139 4.64041C7.60414 4.64041 3.86721 8.37734 3.86721 12.9871C3.86721 17.5968 7.60414 21.3337 12.2139 21.3337Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M17.2743 12.9871C17.2743 15.7819 15.0087 18.0475 12.2139 18.0475C9.41905 18.0475 7.15341 15.7819 7.15341 12.9871C7.15341 10.1923 9.41905 7.92661 12.2139 7.92661C15.0087 7.92661 17.2743 10.1923 17.2743 12.9871Z',
			},
		},
	],
};

export default single_choice;
