import { ReactNode } from 'react';

export const symbol_mitre: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M540-430v-100q0-24.75 17.625-42.375T600-590h40v-110h-40q-24.75 0-42.375-17.625T540-760v-100q0-24.75 17.625-42.375T600-920h140q24.75 0 42.375 17.625T800-860v100q0 24.75-17.625 42.375T740-700h-40v110h40q24.75 0 42.375 17.625T800-530v100q0 24.75-17.625 42.375T740-370H600q-24.75 0-42.375-17.625T540-430Zm60 0h140v-100H600v100Zm0-330h140v-100H600v100ZM160-100v-100q0-24.75 17.625-42.375T220-260h40v-110h-40q-24.75 0-42.375-17.625T160-430v-100q0-24.75 17.625-42.375T220-590h40v-110h-40q-24.75 0-42.375-17.625T160-760v-100q0-24.75 17.625-42.375T220-920h140q24.75 0 42.375 17.625T420-860v100q0 24.75-17.625 42.375T360-700h-40v110h40q24.75 0 42.375 17.625T420-530v100q0 24.75-17.625 42.375T360-370h-40v110h40q24.75 0 42.375 17.625T420-200v100q0 24.75-17.625 42.375T360-40H220q-24.75 0-42.375-17.625T160-100Zm60 0h140v-100H220v100Zm0-330h140v-100H220v100Zm0-330h140v-100H220v100Zm450 280Zm0-330ZM290-150Zm0-330Zm0-330Z"
		/>
	</svg>
);
