import { useContext, useState, Fragment, useRef } from 'react';
import { t } from '@transifex/native';

import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import useConfirm from 'pkg/hooks/useConfirm';

import Icon from 'components/icon';

import { getPrefs } from 'components/drawing/config';
import { FabricContext } from 'components/drawing/FabricContext';
import { Section } from 'components/drawing/edit/Pane';
import ColorPicker from 'components/drawing/edit/ColorPicker';
import { Flex, Label } from 'components/drawing/edit/Tools';
import TextInput from 'components/form/TextInput';
import RangeSlider from 'components/form/RangeSlider';
import ToggleSwitch from 'components/form/Toggle';

import Button from 'design/button';

const _context = 'training_library/drawing';

export default function PrefsPane({ className }: any) {
	const { slide, slides, setSlides, canvas, selectedObject } =
		useContext(FabricContext);

	// get preferences
	const prefs = getPrefs(selectedObject);

	const deleteObject = () => {
		canvas.current.getActiveObjects().forEach((target: any) => {
			// remove from all slides
			const newSlides: any = structuredClone(slides);
			newSlides.forEach((slide: any) => {
				delete slide[target.id];
			});
			setSlides(newSlides);

			// remove from canvas
			canvas.current.remove(target);
		});
		canvas.current.discardActiveObject();
		canvas.current.requestRenderAll();
	};

	const confirmDeletion = useConfirm({
		message: t(
			'Are you sure you want to delete this object from all steps? \n\nTo hide from certain steps, use the visibility toggle in the steps pane.',
			{
				_context,
			}
		),
		onConfirm: deleteObject,
	});

	const deleteHandler = () => {
		if (slides.length > 1) {
			confirmDeletion();
		} else {
			deleteObject();
		}
	};

	const duplicateObject = () => {
		canvas.current.getActiveObjects().forEach((target: any) => {
			target.clone((cloned: any) => {
				cloned.tool = target.tool;
				cloned.left += 30;
				cloned.top += 30;
				canvas.current.add(cloned);
				canvas.current.setActiveObject(cloned);
			});
		});
	};

	const move = (front: boolean) => {
		const newSlides: any = structuredClone(slides);

		canvas.current.getActiveObjects().forEach((target: any) => {
			const objects = Object.entries(slides[slide]);

			const currentZ = slides[slide][target.id].z;
			const destinationZ = front ? objects.length : 1;

			const [swap] = objects.find(([, val]: any) => val.z === destinationZ);

			newSlides[slide][swap].z = currentZ;
			newSlides[slide][target.id].z = destinationZ;
		});

		setSlides(newSlides);
		canvas.current.requestRenderAll();
	};

	const toFront = () => {
		move(true);
	};
	const toBack = () => {
		move(false);
	};

	const close = () => {
		canvas.current.discardActiveObject();
		canvas.current.renderAll();
	};

	const selected = canvas.current.getActiveObjects().length;

	return (
		<div className={className}>
			<Section>
				<Flex>
					<Label>
						{t('Preferences', {
							_context,
						})}
					</Label>
					<Icon name="close" onClick={close} size={1.5} />
				</Flex>
			</Section>

			{prefs?.map((pref: any, i: number) => (
				<Fragment key={selectedObject?.id + i}>
					{pref.type === 'color' && <Color pref={pref} />}
					{pref.type === 'range' && <Range pref={pref} />}
					{pref.type === 'toggle' && <Toggle pref={pref} />}
					{pref.type === 'text' && <Text pref={pref} />}
				</Fragment>
			))}
			<Section spaced>
				<Label>{t('Edit', { _context })}</Label>
				<Button block icon="content_copy" onClick={duplicateObject}>
					{t('Duplicate', { _context })}
				</Button>
				<Button block icon="delete" onClick={deleteHandler}>
					{t('Delete', { _context })}
				</Button>
			</Section>
			{selected === 1 && (
				<Section spaced>
					<Label>{t('Arrange', { _context })}</Label>
					<Button block icon="arrow_upward" onClick={toFront}>
						{t('Move to front', { _context })}
					</Button>
					<Button block icon="arrow_downward" onClick={toBack}>
						{t('Move to back', { _context })}
					</Button>
				</Section>
			)}
		</div>
	);
}

const Color = ({ pref }: any) => {
	const { canvas, selectedObject } = useContext(FabricContext);
	const [value, setValue] = useState(null);

	useComponentDidMount(() => {
		setValue(pref.get({ obj: selectedObject }));
	});

	const set = (value: any) => {
		pref.set({ value, obj: selectedObject, canvas });
		setValue(value);
	};

	return pref.inline ? (
		<Section spaced>
			<Label>{pref.label()}</Label>
			<ColorPicker
				inline
				opacity={pref.opacity}
				value={value || ''}
				onChange={set}
			/>
		</Section>
	) : (
		<Section>
			<Flex>
				<Label>{pref.label()}</Label>
				<ColorPicker
					opacity={pref.opacity}
					value={value || ''}
					onChange={set}
				/>
			</Flex>
		</Section>
	);
};

const Range = ({ pref }: any) => {
	const { canvas, selectedObject } = useContext(FabricContext);
	const [value, setValue] = useState(null);

	useComponentDidMount(() => {
		setValue(pref.get({ obj: selectedObject }));
	});

	const set = (value: any) => {
		pref.set({ value, obj: selectedObject, canvas });
		setValue(value);
	};

	return (
		<Section tight>
			<Flex>
				<Label>{pref.label()}</Label>
				<RangeSlider
					type="range"
					value={value || 0}
					min={pref.min || 0}
					max={pref.max || 10}
					onChange={set}
				/>
			</Flex>
		</Section>
	);
};

const Toggle = ({ pref }: any) => {
	const { canvas, selectedObject } = useContext(FabricContext);
	const [value, setValue] = useState(null);

	useComponentDidMount(() => {
		setValue(pref.get({ obj: selectedObject }));
	});

	const set = (value: any) => {
		pref.set({ value, obj: selectedObject, canvas });
		setValue(value);
	};

	return (
		<Section tight>
			<ToggleSwitch
				label={pref.label()}
				standAlone
				isActive={!!value}
				onChange={set}
			/>
		</Section>
	);
};

const Text = ({ pref }: any) => {
	const { canvas, selectedObject } = useContext(FabricContext);
	const [value, setValue] = useState(null);
	const inpEl = useRef<any>();

	useComponentDidMount(() => {
		setValue(pref.get({ obj: selectedObject }));

		if (pref.autofocus) {
			// focus on input after timeout to avoid stuttering sidebar animation caused by autofocus attribute or instanst call to focus()

			setTimeout(() => {
				inpEl?.current?.focus();
			}, 300);
		}
	});

	const set = (e: any) => {
		let value = e.target.value;
		if (pref.uppercase) value = value.toUpperCase();

		pref.set({ value, obj: selectedObject, canvas });
		setValue(value);
	};

	const placeholder = pref.placeholder();

	return (
		<Section spaced>
			<Label>{pref.label()}</Label>
			<TextInput
				ref={inpEl}
				small
				value={(value !== placeholder && value) || ''}
				onChange={set}
				maxLength={pref.max}
				placeholder={placeholder}
			/>
		</Section>
	);
};
