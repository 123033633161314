const navBadge = {
	name: 'nav-badge',
	objects: [
		{
			type: 'path',
			attributes: {
				d: 'M18.7299 6.49465C15.5519 7.56297 13.8479 4.9489 12.7989 4.00165C11.7499 4.9489 10.0459 7.56297 6.86789 6.49465C6.97243 7.94613 7.02135 9.97259 6.18686 13.4618C4.88163 18.9194 10.2725 19.5194 12.7989 20.9515C15.3253 19.5194 20.7162 18.9194 19.4109 13.4618C18.5765 9.97259 18.6254 7.94613 18.7299 6.49465Z',
				strokeWidth: '1.3',
				strokeLinejoin: 'bevel',
				stroke: 'currentColor',
				fill: 'none',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M13.5177 4.41394V9.54667H18.6048V10.8467H12.8677H7.03879V9.54667H12.2177V4.41394H13.5177ZM10.0892 15.1511C10.0394 14.9425 10.013 14.7249 10.013 14.5011C10.013 14.2773 10.0394 14.0596 10.0892 13.8511H6.30444V15.1511H10.0892ZM19.2932 15.1511H15.5084C15.5583 14.9425 15.5847 14.7249 15.5847 14.5011C15.5847 14.2773 15.5583 14.0596 15.5084 13.8511H19.2932V15.1511Z',
				fillRule: 'evenodd',
				clipRule: 'evenodd',
			},
		},
		{
			type: 'circle',
			attributes: {
				cx: '12.7989',
				cy: '14.4289',
				r: '2.13585',
				stroke: 'currentColor',
				fill: 'none',
				strokeWidth: '1.3',
			},
		},
	],
};
export default navBadge;
