import { ReactNode } from 'react';

export const symbol_forward_to_inbox: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M820-740H140v520h360q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T500-160H140q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v280q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T820-460v-280Zm-680 0v520-210 4-314Zm340 218 340-218v55L496-473q-7.5 5-15.75 5T464-473L140-678v-62l340 218Zm325 362H620q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T620-220h185l-53-53q-9-9-8.5-21t9.5-21.391Q762-324 774-324t21 9l104 104q5 5 7 10.091 2 5.091 2 10.909 0 5-2 10.5t-7 10.5L795-65q-9 9-21 9t-21-9q-9-9-8.5-21t8.5-21l52-53Z"
		/>
	</svg>
);
