import { ReactNode } from 'react';

export const symbol_mode_cool_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M510-275v165q0 13-8.5 21.5T480-80q-13 0-21.5-8.5T450-110v-165L322-147q-9 9-21 8.5t-21-8.5q-9-9-9-21.5t9-21.5l157-157-90-90-155 155q-8 8-20 8t-21-8q-9-9-9.5-21.5T150-325l125-125H110q-13 0-21.5-8.5T80-480q0-13 8.5-21.5T110-510h165L62-723q-9-9-8.5-21.5T63-766q9-9 21.5-9t21.5 9L807-64q9 9 9 21t-9 21q-9 9-21.5 9T764-22L510-275Zm91-175h-91l-60-60v-91L283-768q-9-9-9-21t8-21q9-9 22-9.5t22 8.5l124 125v-164q0-13 8.5-21.5T480-880q13 0 21.5 8.5T510-850v164l129-129q9-9 21-8.5t21 9.5q9 9 9 21t-9 21L510-601v91h91l168-168q9-9 21-8.5t21 8.5q9 9 9 21.5t-9 21.5L686-510h164q13 0 21.5 8.5T880-480q0 13-8.5 21.5T850-450H686l128 129q4 4 6 9.5t2 11q0 5.5-2 10.5t-6 10q-9 9-21.5 9t-21.5-9L601-450Z"
		/>
	</svg>
);
