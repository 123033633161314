import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Fragment } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as selectors from 'pkg/selectors';
import { RootState } from 'pkg/reducers';
import { useCurrentMembership } from 'pkg/identity';

import Comment from 'containers/comment/Comment';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import Icon from 'components/icon';

const Divider = styled.div`
	height: 1px;
	background-color: var(--palette-gray-700);

	@media ${styles.breakpoint.small} {
		margin-top: var(--spacing-3);
		margin-bottom: var(--spacing-4);
	}
`;

const Heading = styled.div`
	font-size: var(--font-size-base);
	color: var(--palette-white);

	svg {
		margin-right: var(--spacing-2);
	}
`;

interface ClipCommentProps {
	clipId: number;
}

export default function ClipComment({ clipId }: ClipCommentProps): JSX.Element {
	const membership = useCurrentMembership();
	const activeSequenceUser = useSelector((state: RootState) =>
		selectors.videoSequenceUsers.findActiveSequenceUser(
			state,
			membership.targetUserId || membership.userId,
			clipId
		)
	);

	const clip = useSelector((state: RootState) =>
		selectors.videoSequences.find(state, clipId)
	);

	if (!activeSequenceUser || !activeSequenceUser.description.length)
		return null;

	return (
		<Fragment>
			<LargeScreen>
				<Divider />
			</LargeScreen>
			<Heading>
				<Icon name="eye-hidden" />
				{t('Private comment from clip author')}
			</Heading>
			<Comment
				accountId={clip.accountId}
				content={activeSequenceUser.description}
				footerLabel={t('Private comment')}
			/>
			<SmallScreen>
				<Divider />
			</SmallScreen>
		</Fragment>
	);
}
