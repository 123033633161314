import { ReactNode } from 'react';

export const symbol_movie_info: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-120q-24 0-42-18t-18-42v-599q0-24 18-42.5t42-18.5h681q24.338 0 41.669 18.5Q880-803 880-779v599q0 24-17.331 42T821-120H140Zm340.175-174q12.825 0 21.325-8.625T510-324v-173q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T450-497v173q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625Zm0-319q12.825 0 21.325-8.675 8.5-8.676 8.5-21.5 0-12.825-8.675-21.325-8.676-8.5-21.5-8.5-12.825 0-21.325 8.675-8.5 8.676-8.5 21.5 0 12.825 8.675 21.325 8.676 8.5 21.5 8.5ZM305-180h352v-599H305v599Zm0-599h352-352ZM140-180h105v-105H140v105Zm576 0h105v-105H716v105ZM140-344h105v-105H140v105Zm576 0h105v-105H716v105ZM140-509h105v-105H140v105Zm576 0h105v-105H716v105ZM140-674h105v-105H140v105Zm576 0h105v-105H716v105Z"
		/>
	</svg>
);
