export enum PageWidths {
	STANDARD = 720,
	WIDE = 1024,
	EXTRA_WIDE = 1280,
	ULTRA_WIDE = 2560,
}

export enum DrawerSizes {
	EXPANDED = 250,
	COLLAPSED = 65,
}

export enum VideoPlayMode {
	CLIP_LIST = 320,
}

export enum ActionBarHeight {
	STANDARD = 68,
	MEDIUM = 72,
}

export enum SubNavHeight {
	STANDARD = 49,
}
