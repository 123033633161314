import { ReactNode } from 'react';

export const symbol_keyboard_double_arrow_up: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-423 303-246q-9 9-21 9t-21-9q-9-9-9-21t9-21l198-198q5-5 10-7t11-2q6 0 11 2t10 7l198 198q9 9 9 21t-9 21q-9 9-21 9t-21-9L480-423Zm0-253L303-499q-9 9-21 9t-21-9q-9-9-9-21t9-21l198-198q5-5 10-7t11-2q6 0 11 2t10 7l198 198q9 9 9 21t-9 21q-9 9-21 9t-21-9L480-676Z"
		/>
	</svg>
);
