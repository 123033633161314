import { t } from '@transifex/native';
import { useRef, useState } from 'react';

import * as flashActions from 'pkg/actions/flashes';

import * as models from 'pkg/api/models';
import { useEndpoint } from 'pkg/api/use_endpoint';
import * as endpoints from 'pkg/api/endpoints/auto';
import useMixedState from 'pkg/hooks/useMixedState';
import { useCurrentGroup } from 'pkg/identity';

import CreatePriceForm from 'routes/payments/prices/forms/create';

import * as Card from 'components/Card';
import Modal from 'components/modal';

import { Spinner } from 'components/loaders/spinner';
import Form, { asString, submitForm, FormPayload } from 'components/form/Form';

import Button, { ButtonGroup } from 'design/button';

export interface PriceMixedState {
	recurring: boolean;
	recurringIntervalCount: number;

	recurringOption?: models.providerSettings.RecurringOptionStrings;
	recurringInterval: models.providerSettings.RecurringInterval;
}

interface NewPriceModalProps {
	productId: number;
	forceOneTimePrice?: boolean;

	setPrice: (price: models.productPrice.ProductPrice) => void;
	refreshProduct?: () => void;
	hideModal: () => void;
}

export default function CreatePriceModal({
	productId,
	forceOneTimePrice = false,
	setPrice,
	refreshProduct,
	hideModal,
}: NewPriceModalProps) {
	const formRef = useRef(null);
	const [saving, setSaving] = useState(false);
	const group = useCurrentGroup();

	const [formState, setFormState] = useMixedState<PriceMixedState>({
		recurring: forceOneTimePrice ? false : true,
		recurringIntervalCount: 0,
		recurringInterval: null,
	});

	const { record: providerSettings, isLoading } =
		useEndpoint<models.providerSettings.ProviderSettings>(
			endpoints.Groups.PaymentProviderSettings(group.id)
		);

	const handleSubmit = async (data: FormPayload) => {
		setSaving(true);

		const payload: models.productPrice.CreateProductPricePayload = {
			productId,
			cost: Number.parseFloat(asString(data.cost)),
			title: asString(data.title),
			currency: group.currency.toLowerCase(),
			recurring: formState.recurring,
			recurringInterval: formState.recurringInterval || 'year',
			recurringIntervalCount: formState.recurringIntervalCount,
			description: '',
		};

		const [req, resp] = await models.productPrice.create(
			payload,
			formState.recurringOption,
			providerSettings
		);

		if (req.ok) {
			if (refreshProduct) {
				refreshProduct();
			}
			setPrice(resp);
			hideModal();
		} else {
			flashActions.show({
				title: t('Something went wrong!'),
			});

			setSaving(false);
		}
	};

	const onSubmitClick = async () => {
		submitForm(formRef);
	};

	if (isLoading) {
		return <Spinner />;
	}

	return (
		<Modal wide onClose={hideModal}>
			<Card.Base>
				<Card.Header>
					<Card.Heading>{t('New price')}</Card.Heading>
				</Card.Header>
				<Card.Divider />
				<Card.Body>
					<Form onSubmit={handleSubmit} formRef={formRef} stopPropagation>
						<CreatePriceForm
							group={group}
							formState={formState}
							setFormState={setFormState}
							providerSettings={providerSettings}
							disableRecurringChange={forceOneTimePrice}
						/>
					</Form>
				</Card.Body>
				<Card.Divider />
				<Card.Footer>
					<ButtonGroup>
						<Button label={t('Cancel')} busy={saving} onClick={hideModal} />
						<Button
							label={t('Save')}
							primary
							onClick={onSubmitClick}
							isLoading={saving}
						/>
					</ButtonGroup>
				</Card.Footer>
			</Card.Base>
		</Modal>
	);
}
