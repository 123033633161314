import { ReactNode } from 'react';

export const symbol_garage_door: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M230.825-120Q218-120 209.5-128.625T201-150v-402L99-475q-10.043 8-21.522 6.5Q66-470 58-480q-8-10-6-22t12-20l380-290q8-6 17.211-9.5 9.21-3.5 18.789-3.5t18.789 3.5Q508-818 516-812l380 290q10 8 11.5 20t-6.5 22q-8 10-19.478 11.5Q870.043-467 860-475l-102-78v403q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T698-150v-399H261v399q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM360-160q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T360-220h240q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T600-160H360Zm0-130q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T360-350h240q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T600-290H360Zm0-129q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T360-479h240q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T600-419H360Zm-85-190h409L480-765 275-609Z"
		/>
	</svg>
);
