import { ReactNode } from 'react';

export const symbol_camera_video: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M358-180h244l-25-100H383l-25 100Zm2-160h240q91.3 0 155.65-64.288Q820-468.576 820-559.788T755.65-715.5Q691.3-780 600-780H360q-91.3 0-155.65 64.288Q140-651.424 140-560.212T204.35-404.5Q268.7-340 360-340Zm120-120q-42 0-71-29t-29-71q0-42 29-71t71-29q42 0 71 29t29 71q0 42-29 71t-71 29ZM257-613q12 0 21-9t9-21.5q0-12.5-9-21t-21.5-8.5q-12.5 0-21 8.625T227-643q0 12 8.625 21T257-613Zm-67 493q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T190-180h107l26-102q-103-14-173-92.5T80-560q0-117 81.5-198.5T360-840h240q117 0 198.5 81.5T880-560q0 107-70 185.5T637-282l26 102h107q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T770-120H190Zm290-280q66 0 113-47t47-113q0-66-47-113t-113-47q-66 0-113 47t-47 113q0 66 47 113t113 47Zm0-160ZM358-180h244-244Z"
		/>
	</svg>
);
