import { ReactNode } from 'react';

export const symbol_hardware: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M394-120q-16 0-25-13.5t-9-30.5v-476H196q-14 0-23-11t-6-25q14-71 67.5-117.5T360-840h196q18 0 31 13t13 31v98l127-129q6-7 14.5-10t17.5-3h11q13 0 21.5 8.5T800-810v284q0 13-8.5 21.5T770-496h-11q-9 0-17.5-3T727-509L600-638v474q0 18-13 31t-31 13H394Zm26-60h120v-237H420v237Zm0-297h120v-303H360q-39 0-75 22t-51 58h186v223Zm60-3Z"
		/>
	</svg>
);
