import styled, { css } from 'styled-components';

import * as palette from 'pkg/config/palette';
import { small } from 'pkg/config/breakpoints';

import { Body } from 'components/Card';

export const SettingsWrapper = styled.div`
	display: grid;
	grid-auto-flow: row;
	gap: var(--spacing-5);

	${Body} {
		padding-left: 26px;
		padding-right: 26px;
	}
`;

export const SettingRow = styled.div<{
	isEditing?: boolean;
	centered?: boolean;
}>`
	padding: 1rem 0;
	display: grid;
	grid-template-areas: 'label description control';
	grid-template-columns: 26% 2fr auto;
	width: 100%;
	align-items: start;

	${(props) =>
		props.isEditing &&
		css`
			grid-template-areas: 'label description description' 'label control control';
			grid-template-columns: 26% 1fr 1fr;
			gap: 1rem;
		`};

	${({ centered, isEditing }) =>
		!centered &&
		css`
			@media ${small} {
				padding: 0;
				grid-template-columns: 1fr auto;
				grid-template-areas: 'label control' 'description description';

				${isEditing &&
				css`
					grid-template-areas: 'label' 'description' 'control';
					grid-template-columns: 1fr;
				`};
			}
		`}

	${(props) =>
		props.centered &&
		css`
			padding-top: 0;
			gap: 0.2rem;
			grid-template-columns: 1fr;
			grid-template-areas: 'label' 'description' 'control';
			align-items: center;
			justify-items: center;
		`}
`;

export const MultipleItemsSettingRow = styled.div`
	padding: 1rem 0;
	display: grid;

	gap: 1rem;
	grid-template-areas: 'label .';
	grid-template-columns: 26% 1fr;
	width: 100%;
	align-items: start;

	@media ${small} {
		grid-template-areas: 'label' '.';
		grid-template-columns: 1fr;
		grid-auto-flow: row;
	}
`;

export const SettingItems = styled.div`
	display: grid;
	grid-auto-flow: row;
	gap: 1rem;
`;

export const FullWidthSettingRow = styled.div`
	padding: 1rem 0;

	@media ${small} {
		padding: 0;
	}
`;

export const SettingLabel = styled.span`
	grid-area: label;
	font-weight: var(--font-weight-semibold);
	font-size: var(--font-size-sm);
	word-break: break-all;
`;

export const SettingDescription = styled.span`
	grid-area: description;
	font-size: var(--font-size-sm);
	color: ${palette.gray[700]};
	max-width: 100%;

	> span {
		display: block;
		overflow-wrap: break-word;
		word-break: break-word;
		padding-right: var(--spacing-3);
	}
`;

export const SettingControl = styled.span`
	grid-area: control;
	display: grid;
	grid-auto-flow: column;
	gap: 0.5rem;
	justify-content: end;
	align-items: start;
`;

export const SettingRowDivider = styled.div`
	background: ${palette.gray[300]};
	height: 1px;

	@media ${small} {
		margin: 1rem 0;
	}
`;

export const SettingDoubleColumn = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 1rem;

	@media ${small} {
		grid-template-columns: 1fr;
		grid-template-rows: repeat(2, auto);
	}
`;
