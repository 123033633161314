import { Fragment } from 'react';

import { useCollection } from 'pkg/api/use_collection';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { emailRegexPattern } from 'pkg/strings';

import useAttachments from 'components/attachment/hooks/useAttachments';
import * as Inputs from 'components/form/inputs';
import Column from 'components/layout/column';
import { Spinner } from 'components/loaders/spinner';

interface CustomFieldsProps {
	organizationId: number;
	prevData?: models.user.Field[];
	ignoreRequired?: boolean;
}

function fieldTypeToInputType(f: models.userFields.UserField): string {
	switch (f.type) {
		case models.userFields.UserFieldType.Email:
			return 'email';
		case models.userFields.UserFieldType.Phone:
			return 'tel';
		case models.userFields.UserFieldType.Number:
			return 'number';
		case models.userFields.UserFieldType.Date:
			return 'date';
	}

	return 'text';
}

const CustomFields = ({
	organizationId,
	prevData = [],
	ignoreRequired = false,
}: CustomFieldsProps): JSX.Element => {
	const formattedPrevData: {
		[key: string]: {
			value: string;
			attachments?: models.attachment.Attachment[];
		};
	} = {};

	for (const data of prevData) {
		formattedPrevData[data.key] = {
			value: data.values.join(','),
		};

		if (data.attachments) {
			formattedPrevData[data.key].attachments = [...data.attachments];
		}
	}

	const { records: fields, isLoading } =
		useCollection<models.userFields.UserField>(endpoints.UserField.Index(), {
			queryParams: new URLSearchParams({ group_id: organizationId.toString() }),
		});

	if (isLoading) {
		return <Spinner />;
	}

	return (
		<Column>
			{fields
				.filter(
					(field) => field.type !== models.userFields.UserFieldType.Reserved
				)
				.map((field) => (
					<CustomFieldInput
						key={field.key}
						field={field}
						defaultValue={formattedPrevData[field.key]?.value}
						defaultAttachments={formattedPrevData[field.key]?.attachments}
						ignoreRequired={ignoreRequired}
					/>
				))}
		</Column>
	);
};

interface CustomFieldInputProps {
	field: models.userFields.UserField;
	defaultValue?: string;
	defaultAttachments?: models.attachment.Attachment[];
	ignoreRequired?: boolean;
}

export const CustomFieldInput = ({
	field,
	defaultValue,
	defaultAttachments,
	ignoreRequired,
}: CustomFieldInputProps) => {
	let content = null;

	let name = `fields.${field.type}:${field.key}`;

	if (field.type === models.userFields.UserFieldType.Reserved) {
		const [, reservedKey] = field.key.split('.');
		name = reservedKey;
	}

	// "fields.*type*.*key*" is to store under fields: [] and to determine what type the field is of
	// "fields.short_text.name" for instance
	const fieldDefaultProps = {
		name,
		type: fieldTypeToInputType(field),
		required: !ignoreRequired && field.required,
		defaultValue,
	};

	if (ignoreRequired) {
		field.required = false;
	}

	switch (field.type) {
		case models.userFields.UserFieldType.Choice:
		case models.userFields.UserFieldType.MultiChoice:
			content = field.values.map((value) => (
				<Inputs.Control
					type={
						field.type === models.userFields.UserFieldType.Choice
							? 'radio'
							: 'checkbox'
					}
					name={`fields.${field.type}:${field.key}`}
					label={value.label}
					key={value.label}
					value={value.label}
					defaultChecked={defaultValue && defaultValue.includes(value.label)}
				/>
			));
			break;
		case models.userFields.UserFieldType.Email:
			content = (
				<Inputs.Field {...fieldDefaultProps} pattern={emailRegexPattern} />
			);
			break;
		case models.userFields.UserFieldType.Number:
			content = <Inputs.Field {...fieldDefaultProps} pattern="[0-9]+" />;
			break;
		case models.userFields.UserFieldType.LongText:
			content = <Inputs.Area {...fieldDefaultProps} minRows={3} maxRows={10} />;
			break;
		case models.userFields.UserFieldType.Attachment:
			content = (
				<AttachmentField
					field={field}
					defaultAttachments={defaultAttachments}
				/>
			);
			break;
		default:
			content = <Inputs.Field {...fieldDefaultProps} />;
	}

	return (
		<Inputs.Group
			label={field.label}
			description={field.description}
			required={field.required}>
			{content}
		</Inputs.Group>
	);
};

interface AttachmentFieldProps {
	field: models.userFields.UserField;
	defaultAttachments: models.attachment.Attachment[];
}

const AttachmentField = ({
	field,
	defaultAttachments = [],
}: AttachmentFieldProps): JSX.Element => {
	let type = undefined;
	switch (field.attachmentType) {
		case 'image':
			type = models.attachment.AttachmentType.Image;
			break;
		case 'video':
			type = models.attachment.AttachmentType.Video;
			break;
		case 'audio':
			type = models.attachment.AttachmentType.Audio;
			break;
	}

	const { FileUploader, AttachmentItems, attachments } = useAttachments({
		attachments: defaultAttachments,
		type,
	});

	return (
		<Fragment>
			<div>{FileUploader}</div>
			{AttachmentItems}
			{attachments.length > 0 &&
				// we need to reverse the list to get correct index for each attachment
				attachments
					.reverse()
					.map((attachment) => (
						<Inputs.Field
							key={attachment.id}
							name={`fields.${field.type}:${field.key}:${attachment.id}`}
							value={attachment.id}
							hidden
						/>
					))}
		</Fragment>
	);
};

export default CustomFields;
