import * as routes from 'pkg/router/routes';
import { pushState } from 'pkg/router/state';
import { useCurrentMembership, useCurrentOrganization } from 'pkg/identity';

import EditExerciseForm from 'components/training/exercise/EditExerciseForm';

interface EditExerciseProps {
	exerciseId: number;
}

const EditExercise = ({ exerciseId }: EditExerciseProps): JSX.Element => {
	const membership = useCurrentMembership();
	const org = useCurrentOrganization();

	const returnToLibrary = () => {
		pushState(routes.TrainingLibrary.Index(org.id, membership.groupId));
	};

	return (
		<EditExerciseForm
			exerciseId={exerciseId}
			onSave={returnToLibrary}
			onCancel={returnToLibrary}
		/>
	);
};

export default EditExercise;
