import { ReactNode } from 'react';

export const symbol_expand_less: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-543 304-367q-9 9-21.5 9.5T261-366q-9-9-9-21.5t9-21.5l198-198q5-5 10-7t11-2q6 0 11 2t10 7l197 197q9 9 9.5 21t-8.5 21q-9 9-21.5 9t-21.5-9L480-543Z"
		/>
	</svg>
);
