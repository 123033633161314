import { ReactNode } from 'react';

export const symbol_auto_towing: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M40-298.073v-60.146Q40-371 48.625-379.5 57.25-388 70-388h410L100-682v117q0 12.75-8.675 21.375Q82.649-535 69.825-535 57-535 48.5-543.625T40-565v-166q0-17.5 14.5-26.25T84-758l470 259v-271q0-12.75 8.625-21.375T584-800h108q13.574 0 25.639 5.69Q729.705-788.621 738-778l168 201q6.632 8.324 10.316 18.162Q920-549 920-538v240q0 12.75-8.625 21.375T890-268h-67q0 45-31.5 76.5T715-160q-45 0-76.5-31.5T607-268H349q0 45-31.5 76.5T241-160q-45 0-76.5-31.5T133-268H70q-12.75 0-21.375-8.646T40-298.073ZM241-203q27 0 46-19t19-46q0-27-19-46t-46-19q-27 0-46 19t-19 46q0 27 19 46t46 19Zm474 0q27 0 46-19t19-46q0-27-19-46t-46-19q-27 0-46 19t-19 46q0 27 19 46t46 19ZM614-560h228L692-740h-78v180Z"
		/>
	</svg>
);
