import { Dispatch } from 'redux';
import { t } from '@transifex/native';

import { GroupsProps } from 'pkg/models/user';
import Membership from 'pkg/models/membership';

import * as actionTypes from 'pkg/actions/action-types';
import * as flashActions from 'pkg/actions/flashes';
import * as membershipService from 'pkg/actions/services/membership';

import * as localStorageHelpers from 'pkg/local_storage';
import { OrganizationsLocalStorageData } from 'pkg/identity/account';
import * as models from 'pkg/api/models';
import * as sdk from 'pkg/core/sdk';
import * as endpoints from 'pkg/api/endpoints/auto';

interface setMembershipAction {
	type: typeof actionTypes.Users.SET_ACTIVE_MEMBERSHIP;
	membership: Membership;
	targetUserId: number;
}

export const setMembership = (
	membership: Membership,
	targetUserId: number
): setMembershipAction => {
	return {
		type: actionTypes.Users.SET_ACTIVE_MEMBERSHIP,
		membership,
		targetUserId,
	};
};

export const setActiveMembership =
	(membership: Membership, targetUserId: number = 0) =>
	async (dispatch: Dispatch): Promise<void> => {
		const parsedLocalStorageOrgData =
			localStorageHelpers.getJsonItem<OrganizationsLocalStorageData>(
				localStorageHelpers.LocalStorageKeys.Organizations
			);

		if (parsedLocalStorageOrgData) {
			const membershipOrgId = membership.group.getOrganizationID();

			parsedLocalStorageOrgData[membershipOrgId] = {
				...parsedLocalStorageOrgData[membershipOrgId],
				activeMembership: membership.toJS() as models.membership.Membership,
			};

			localStorageHelpers.setJsonItem(
				localStorageHelpers.LocalStorageKeys.Organizations,
				parsedLocalStorageOrgData
			);
		}

		dispatch(setMembership(membership, targetUserId));
	};

export const addUserToGroups = async (
	userId: number,
	groups: GroupsProps[]
): Promise<boolean> => {
	const request = await membershipService.addUserToGroups(userId, groups);

	if (!request.ok) {
		flashActions.show(
			{
				title: t('Something went wrong'),
				message: t(
					"If you think this error isn't supposed to happen, please contact 360Player support."
				),
			},
			request.status
		);

		return false;
	}

	flashActions.show(
		{
			title: t(`User added`),
			message: t(`User was added successfully`),
		},
		request.status
	);

	return true;
};

interface RemoveUserFromGroupPayload {
	removeUserRecursively: boolean;
	removeFromFutureEvents: boolean;
}

export async function removeUserFromGroup(
	groupId: number,
	userId: number,
	payload: RemoveUserFromGroupPayload
): Promise<boolean> {
	const req = await sdk.destroy(
		endpoints.UserGroups.RemoveUser(groupId, userId),
		{},
		payload
	);

	if (!req.ok) {
		flashActions.show({
			title: t('Something went wrong'),
			message: t('Could not remove user'),
		});

		return false;
	}

	flashActions.show({
		title: t('Successfully removed user'),
	});

	return true;
}

interface RemoveUsersFromGroupPayload {
	userIds: number[];
	removeUsersRecursively?: boolean;
	removeFromFutureEvents?: boolean;
}

export async function removeUsersFromGroup(
	groupId: number,
	payload: RemoveUsersFromGroupPayload
): Promise<boolean> {
	const req = await sdk.destroy(
		endpoints.UserGroups.RemoveUsers(groupId),
		{},
		payload
	);

	if (!req.ok) {
		flashActions.show({
			title: t('Something went wrong'),
			message: t('Could not remove users'),
		});

		return false;
	}

	flashActions.show({
		title: t('Successfully removed users'),
	});

	return true;
}
