import { ReactNode } from 'react';

export const symbol_gallery_thumbnail: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M100-200q-24.75 0-42.375-17.625T40-260v-440q0-24.75 17.625-42.375T100-760h440q24.75 0 42.375 17.625T600-700v440q0 24.75-17.625 42.375T540-200H100Zm620-320q-17 0-28.5-11.5T680-560v-160q0-17 11.5-28.5T720-760h160q17 0 28.5 11.5T920-720v160q0 17-11.5 28.5T880-520H720Zm20-60h120v-120H740v120ZM100-260h440v-440H100v440Zm90-100h260q8.5 0 12.75-8t-.75-16l-75-100q-5-6-12-6t-12 6l-63 84-43-57q-5-6-12-6t-12 6l-55 73q-5 8-.75 16t12.75 8Zm530 160q-17 0-28.5-11.5T680-240v-160q0-17 11.5-28.5T720-440h160q17 0 28.5 11.5T920-400v160q0 17-11.5 28.5T880-200H720Zm20-60h120v-120H740v120Zm-640 0v-440 440Zm640-320v-120 120Zm0 320v-120 120Z"
		/>
	</svg>
);
