import { Fragment, useState } from 'react';
import styled from 'styled-components';

import { useEndpoint } from 'pkg/api/use_endpoint';
import { useCurrentRoute } from 'pkg/router/hooks';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';

import { FormattedContent } from 'components/formatted-content';
import Icon from 'components/icon';
import * as StepModal from 'components/step-modal';

const InteractableIcon = styled(Icon)`
	margin-left: var(--spacing-3);
	color: var(--palette-gray-500);

	@media (hover: hover) {
		&:hover {
			color: var(--palette-gray-300);
		}
	}
`;

export default function VideoDescription() {
	const { videoId } = useCurrentRoute();
	const [modalOpen, setModalOpen] = useState<boolean>(false);

	const { record: video } = useEndpoint<models.video.Video>(
		endpoints.Video.Show(videoId)
	);

	const openModal = () => setModalOpen(true);

	const closeModal = () => setModalOpen(false);

	if (!video.description) {
		return null;
	}

	return (
		<Fragment>
			<InteractableIcon name="info-circle" onClick={openModal} />
			{modalOpen && (
				<StepModal.Base onClose={closeModal}>
					<StepModal.Step title={video.title} hideNext hidePrev>
						<FormattedContent raw={video.description} />
					</StepModal.Step>
				</StepModal.Base>
			)}
		</Fragment>
	);
}
