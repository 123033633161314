import { ReactNode } from 'react';

export const symbol_interpreter_mode: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M824.941-305Q802-305 786-321.042 770-337.083 770-360v-100q0-22.917 16.059-38.958 16.059-16.042 39-16.042T864-498.958q16 16.041 16 38.958v100q0 22.917-16.059 38.958-16.059 16.042-39 16.042ZM825-160q-6 0-10.5-4.5T810-175v-51q-45-7-78-37t-40-75q-1-7.158 3-12.079T705.518-355q6.519 0 11.001 3.5Q721-348 722-342q5 38 34.519 62.5 29.52 24.5 68.528 24.5Q863-255 892-279.5t36-62.5q1-6 5.8-9.5T945-355q5.895 0 10.447 4.053Q960-346.895 959-341q-5 46-39 77t-80 38v51q0 6-4.5 10.5T825-160ZM249-649.917Q249-682 262-710.5t36-49.5q23-21 52.5-31t61.5-8q10 1 13.5 9.5T423-773q-17 25-25.5 56.5T389-650q0 35 8.5 66.5T423-527q5 8 2 16.5T413-500q-32 2-62-7.5T298-539q-23-22-36-50.417-13-28.416-13-60.5ZM40-220v-33.862Q40-291 57.5-317t51.5-45q31-17 74-30.5t97-22.5q10-2 13.5 8t-5.5 16q-35 29-49.5 63.759Q224-292.483 224-254v34q0 8.333 1.5 16.667 1.5 8.333 3.643 16.253Q232-177 226.375-168.5 220.75-160 211-160H100q-24.75 0-42.375-17.625T40-220Zm559-280q-63 0-106.5-43.5T449-650q0-63 43.5-106.5T599-800q63 0 106.5 43.5T749-650q0 63-43.5 106.5T599-500Zm0-60q38 0 64-26.438 26-26.437 26-63.562 0-38-26-64t-63.5-26q-37.5 0-64 26T509-650.5q0 37.5 26.438 64Q561.875-560 599-560ZM344-160q-24.75 0-42.375-17.625T284-220v-34q0-35 18.5-63.5T353-360q47-21 108.5-40.5T599-420h11.636Q623-420 632-411t9 21q0 12-9 21t-21 9h-12q-72 0-124 16.5T377-306q-16 8-24.5 21.5T344-254v34h292q15 0 22.5 9.318t7.5 20.5q0 11.182-7.5 20.682Q651-160 636-160H344Zm255-490Zm0 430Z"
		/>
	</svg>
);
