import { t } from '@transifex/native';
import styled from 'styled-components';
import { Fragment, ChangeEvent, useState } from 'react';

import * as styles from 'pkg/config/styles';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import { FilterOperator, createFilterGroups } from 'pkg/filters';
import DateTime from 'pkg/datetime';
import * as actions from 'pkg/actions';
import store from 'pkg/store/createStore';
import { useCollection } from 'pkg/api/use_collection';
import { FilterGroups, useFilters } from 'pkg/filters/use_filters';

import ProductList from 'containers/payment_platform/assign-product/ProductList';

import Pagination from 'components/pagination';
import StepModal, { Step } from 'components/step-modal';

import DatePicker from 'components/form/date-picker';
import ActionBar from 'components/products/ActionBar';
import Column from 'components/layout/column';
import FormRow from 'components/form/Row';
import Select from 'components/form/Select';
import { Spinner } from 'components/loaders/spinner';
import useNewProduct from 'components/products/hooks/useNewProduct';

const ValidUntilHeading = styled.p`
	font-weight: var(--font-weight-semibold);
	margin-bottom: var(--spacing-2);
`;

const InnerContainer = styled.div`
	padding: var(--spacing-6);
`;

const DateWrapper = styled.div`
	margin-top: var(--spacing-5);
`;

interface Props {
	organizationId: number;
	userIds: number[];
	onClose: () => void;
}

const AssignProductModal: React.FC<React.PropsWithChildren<Props>> = ({
	organizationId,
	userIds,
	onClose,
}) => {
	const [selectedProductId, setSelectedProductId] = useState<number>(0);

	const [validData, setValidData] = useState<{
		validUntil: number;
		validUntilSelect: string;
	}>({
		validUntil: -1,
		validUntilSelect: '',
	});
	const [sortBy, setSortBy] = useState('createdAt');
	const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
	const { records: productCategories } =
		useCollection<models.productCategory.ProductCategory>(
			endpoints.ProductCategory.List(),
			{
				queryParams: new URLSearchParams({
					group_id: organizationId.toString(),
				}),
			}
		);

	const productCategoryFilters: { [key: string]: number } = {};

	for (const category of productCategories) {
		productCategoryFilters[category.name] = category.id;
	}

	const filterGroups: FilterGroups = createFilterGroups({
		[t(`Title`)]: {
			hidden: true,
			filters: {
				Name: {
					type: 'text',
					operator: FilterOperator.Contains,
					property: 'name',
				},
			},
		},
		[t(`Created at`)]: {
			filters: {
				Created: {
					type: 'date',
					property: 'createdAt',
					operator: FilterOperator.GreaterThan,
				},
			},
		},
		[t(`Category`)]: {
			filters: {
				Category: {
					type: 'checkbox',
					operator: FilterOperator.Includes,
					property: 'productCategoryId',
					values: productCategoryFilters,
				},
				Uncategorized: {
					type: 'checkbox',
					operator: FilterOperator.NotSet,
					property: 'productCategoryId',
					values: { [t(`New product`)]: 'true' },
				},
			},
		},
	});

	const filters = useFilters({
		groups: filterGroups,
		hideFiltersInUrl: true,
	});

	filters.queryParam.set('group_id', organizationId.toString());
	filters.queryParam.set('sort_by', sortBy);
	filters.queryParam.set('sort_order', sortOrder);
	filters.queryParam.set('version', '2');

	const {
		records: products,
		pagination,
		isLoading,
	} = useCollection<models.product.Product>(endpoints.Products.Index(), {
		queryParams: filters.queryParam,
	});
	const [createNew, setCreateNew] = useState<boolean>(false);
	const handleSelect = (productId: number) => setSelectedProductId(productId);
	const handleSort = (value: string, sortOrder: 'asc' | 'desc') => {
		setSortBy(value);
		setSortOrder(sortOrder);
	};
	const handleGoToCreate = () => setCreateNew(true);
	const { canSaveCreateProductForm, onCreateNewProduct, NewProductForm } =
		useNewProduct(organizationId);

	const handleChangeValidUntil = (e: ChangeEvent<HTMLSelectElement>): void => {
		if (e.target.value === '') {
			setValidData({ validUntil: -1, validUntilSelect: '' });
		} else {
			setValidData({
				validUntil: new DateTime(new Date()).getUnixTimestamp(),
				validUntilSelect: 'custom',
			});
		}
	};

	const handleChangeDate = (dates: Date[]) => {
		const newDate = new DateTime(new Date(dates[0]));

		setValidData({
			...validData,
			validUntil: newDate.getUnixTimestamp(),
		});
	};
	const handleNext = async () => {
		await actions.userProducts.createRelation({
			userIds,
			productId: selectedProductId,
			validUntil: validData.validUntil,
		})(store.dispatch);

		return true;
	};

	const handleCreateNew = async () => {
		onCreateNewProduct();
	};

	return (
		<StepModal onClose={onClose}>
			{createNew ? (
				<Step
					title={t('Create new product')}
					onNext={handleCreateNew}
					canGoNext={canSaveCreateProductForm}>
					{NewProductForm}
				</Step>
			) : (
				<Step
					skipBody
					canGoNext={selectedProductId > 0}
					title={t('Assign product')}
					nextLabel={t('Confirm')}
					onNext={handleNext}>
					<Fragment>
						<InnerContainer>
							<FormRow>
								<Column spacing={styles.spacing._3}>
									<ValidUntilHeading>
										{t(`Assignment valid until`)}
									</ValidUntilHeading>
									<Select
										value={validData.validUntilSelect}
										onChange={handleChangeValidUntil}>
										<option value="">{t(`Forever`)}</option>
										<option value="custom">{t(`Custom date`)}</option>
									</Select>
									{validData.validUntil > 0 && (
										<DateWrapper>
											<DatePicker
												dates={
													validData.validUntil > 0
														? [new Date(validData.validUntil * 1000)]
														: [new Date()]
												}
												disableDatesBefore={new Date()}
												handleChange={handleChangeDate}
											/>
										</DateWrapper>
									)}
								</Column>
							</FormRow>
						</InnerContainer>
						<ActionBar
							onGoToCreate={handleGoToCreate}
							searchFilter={{
								type: 'text',
								operator: FilterOperator.Contains,
								property: 'name',
							}}
							filterSetter={filters.setFilter}
						/>

						{isLoading ? (
							<Spinner />
						) : (
							<ProductList
								products={products}
								onSelect={handleSelect}
								selectedProductId={selectedProductId}
								onSort={handleSort}
								sortBy={sortBy}
								sortOrder={sortOrder}
								productCategories={productCategories}
							/>
						)}
						<Pagination {...pagination} />
					</Fragment>
				</Step>
			)}
		</StepModal>
	);
};

export default AssignProductModal;
