import { ReactNode } from 'react';

export const symbol_devices_wearables: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-790h360v-30H140v30Zm0 710q-24 0-42-18t-18-42v-680q0-24 18-42t42-18h360q24 0 42 18t18 42v165q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T500-655v-75H140v500h285q12 0 21 9t9 21.364Q455-188 447-179t-22 9H140v30h385q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q555-97 546.375-88.5T525-80H140Zm559.798-140Q766-220 813-266.798t47-113Q860-446 813.202-493t-113-47Q634-540 587-493.202t-47 113Q540-314 586.798-267t113 47ZM650-80q-12.75 0-21.375-8.625T620-110v-65q-62-24-101-79t-39-126q0-71 39-126t101-79v-65q0-12.75 8.625-21.375T650-680h100q12.75 0 21.375 8.625T780-650v65q62 24 101 79t39 126q0 71-39 126t-101 79v65q0 12.75-8.625 21.375T750-80H650Zm-510-90v30-30Zm0-620v-30 30Z"
		/>
	</svg>
);
