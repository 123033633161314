import { getMembershipsForGroup } from './groups';

export const findAll = (state, sequenceId) =>
	state.videoSequenceUsers
		.get('entities')
		.filter((n) => n.get('videoSequenceId') === sequenceId)
		.toList();

export const findAllComments = (state, sequenceId) =>
	findAll(state, sequenceId).reduce(
		(comments, item) => ({
			...comments,
			[item.userId]: item.description,
		}),
		{}
	);

export const findAllUserIds = (state, sequenceId) =>
	findAll(state, sequenceId).reduce(
		(userIds, item) => ({
			...userIds,
			[item.id]: item.userId,
		}),
		{}
	);

export const findSequenceUsers = (state, sequenceId) => {
	return findAll(state, sequenceId)
		.map((su) => state.users.entities.get(su?.userId) || null)
		.toList()
		.filter((n) => n);
};

export const findAllSequenceUsersOutsideOfActiveGroup = (
	state,
	sequenceId,
	groupId
) => {
	const memberships = getMembershipsForGroup(groupId)(state)
		.filter((m) => !m.isLegalGuardian)
		.toList()
		.map((m) => m.userId)
		.toArray();

	return findSequenceUsers(state, sequenceId)
		.filter((user) => !memberships.includes(user.id))
		.sort((a, b) => a.fullName.localeCompare(b.fullName))
		.map((user) => user.id)
		.toArray();
};

export const findActiveSequenceUser = (state, userId, sequenceId) => {
	return state.videoSequenceUsers
		.get('entities')
		.filter(
			(n) =>
				n.get('videoSequenceId') === sequenceId && n.get('userId') === userId
		)
		.first();
};
