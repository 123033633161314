import { ReactNode } from 'react';

export const symbol_add_reaction: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-480Zm.266 400q-82.734 0-155.5-31.5t-127.266-86q-54.5-54.5-86-127.266T80-480.266Q80-563 111.599-636q31.6-73 85.77-127 54.17-54 127.401-85.5Q398-880 480-880q39 0 75.426 7 36.425 7 70.574 21 17 7 21 18.5t0 22.5q-4 11-13.5 16.5t-21.5.5q-31-13-64-19.5t-68-6.5q-141.312 0-240.656 99T140-480.486q0 141.515 99.486 241Q338.971-140 480.486-140 622-140 721-239.344T820-480q0-23.667-3-47.333Q814-551 807-574q-4-13 3-22t18.186-12q11.187-3 22 2.5Q861-600 866-584q7 25 10.5 50.922Q880-507.156 880-480q0 82-31.5 155.23Q817-251.539 763-197.369t-127 85.77Q563-80 480.266-80ZM810-770h-60q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T750-830h60v-60q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T870-890v60h60q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T930-770h-60v60q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T810-710v-60ZM626-533q22.5 0 38.25-15.75T680-587q0-22.5-15.75-38.25T626-641q-22.5 0-38.25 15.75T572-587q0 22.5 15.75 38.25T626-533Zm-292 0q22.5 0 38.25-15.75T388-587q0-22.5-15.75-38.25T334-641q-22.5 0-38.25 15.75T280-587q0 22.5 15.75 38.25T334-533Zm145.504 272Q537-261 585.5-287q48.5-26 78.5-72.4 6-11.6-.75-22.6-6.75-11-20.25-11H316.918Q303-393 296.5-382t-.5 22.6q30 46.4 78.5 72.4 48.5 26 105.004 26Z"
		/>
	</svg>
);
