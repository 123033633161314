import { ReactNode } from 'react';

export const symbol_crossword: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M386-326v186h187v-186H386Zm-60-60v-187H140v187h186Zm60 0h187v-187H386v187Zm247 0h187v-187H633v187Zm0-247h187v-187H633v187ZM326-326H140q-24.75 0-42.375-17.625T80-386v-187q0-24.75 17.625-42.375T140-633h433v-187q0-24.75 17.625-42.375T633-880h187q24.75 0 42.375 17.625T880-820v434q0 24.75-17.625 42.375T820-326H633v186q0 24.75-17.625 42.375T573-80H386q-24.75 0-42.375-17.625T326-140v-186Z"
		/>
	</svg>
);
