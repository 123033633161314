import { ReactNode } from 'react';

export const symbol_hearing: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-608q0 66 21 127.5T263-368q8 11 8.5 24t-9.5 22q-10 9-23.5 7T217-328q-47-60-72-131t-25-149q0-77 24-149t72-131q8-11 22-12.5t24 7.5q10 8 9 21t-9 23q-42 51-62 113t-20 128Zm382 97q-39 0-66-27t-27-66q0-39 27-67t66-28q39 0 67 28t28 67q0 39-28 66t-67 27Zm217 287q0-13 8.5-21.5T809-254q13 0 21.5 8.5T839-224q-4 60-47.5 101.5T688-81q-57 0-99-31t-62-82q-20-51-39.5-79.5T414-345q-66-53-95-113t-29-146q0-120 76-196.5T562-877q118 0 196 73.5T840-616q0 13-8.5 21.5T810-586q-13 0-21.5-8.5T780-616q-4-88-65-144.5T562-817q-90 0-151 61.5T350-604q0 72 28 124.5T471-378q39 29 62.5 63t44.5 84q17 42 44.5 66t65.5 24q35 0 61-24t30-59Z"
		/>
	</svg>
);
