const comp =
	(order, ...props) =>
	(a, b) => {
		for (const p of props) {
			if (a.get(p) > b.get(p)) {
				return order * 1;
			}
			if (a.get(p) < b.get(p)) {
				return order * -1;
			}
		}
		return 0;
	};

export const asc = (...props) => comp(1, ...props);

export const desc = (...props) => comp(-1, ...props);
