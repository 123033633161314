import { ReactNode } from 'react';

export const symbol_hdr_on: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M636-384v-194q0-9 7-15.5t16-6.5h132q23 0 36 13t13 36v61q0 17-11 29t-28 14l23 56q5 11-2 21t-19 10q-7.273 0-13.636-4Q783-368 781-375l-25-66h-72v57q0 10.4-6.8 17.2-6.8 6.8-17.2 6.8-10.4 0-17.2-6.8-6.8-6.8-6.8-17.2Zm48-105h108v-63H684v63Zm-516 26v79q0 10.4-6.8 17.2-6.8 6.8-17.2 6.8-10.4 0-17.2-6.8-6.8-6.8-6.8-17.2v-192q0-10.4 6.8-17.2 6.8-6.8 17.2-6.8 10.4 0 17.2 6.8 6.8 6.8 6.8 17.2v65h108v-65q0-10.4 6.8-17.2 6.8-6.8 17.2-6.8 10.4 0 17.2 6.8 6.8 6.8 6.8 17.2v192q0 10.4-6.8 17.2-6.8 6.8-17.2 6.8-10.4 0-17.2-6.8-6.8-6.8-6.8-17.2v-79H168Zm365 103H401q-9 0-16-7t-7-16v-195q0-9 7-15.5t16-6.5h132q23 0 36 13t13 36v142q0 23-13 36t-36 13Zm-107-48h108v-144H426v144Z"
		/>
	</svg>
);
