import { ReactNode } from 'react';

export const symbol_manage_search: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M110-200q-12.75 0-21.375-8.675Q80-217.351 80-230.175 80-243 88.625-251.5T110-260h340q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T450-200H110Zm0-210q-12.75 0-21.375-8.675Q80-427.351 80-440.175 80-453 88.625-461.5T110-470h140q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T250-410H110Zm0-210q-12.75 0-21.375-8.675Q80-637.351 80-650.175 80-663 88.625-671.5T110-680h140q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T250-620H110Zm450 300q-83 0-141.5-58.5T360-520q0-83 58.5-141.5T560-720q83 0 141.5 58.5T760-520q0 32-10 62t-30 56l139 139q9 9 9 21t-9 21q-9 9-21 9t-21-9L678-360q-26 20-56 30t-62 10Zm-.235-60Q618-380 659-420.765q41-40.764 41-99Q700-578 659.235-619q-40.764-41-99-41Q502-660 461-619.235q-41 40.764-41 99Q420-462 460.765-421q40.764 41 99 41Z"
		/>
	</svg>
);
