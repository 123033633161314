import { ReactNode } from 'react';

export const symbol_data_info_alert: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M150-160q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-220h420q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T570-160H150Zm489.894-290Q561-450 505.5-505.606t-55.5-134.5Q450-719 505.606-774.5t134.5-55.5Q719-830 774.5-774.394t55.5 134.5Q830-561 774.394-505.5t-134.5 55.5ZM150-500q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-560h213q12.75 0 21.375 9T393-529.636Q393-518 384.375-509T363-500H150Zm0 170q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-390h371q12.75 0 21.375 9T551-359.636Q551-348 542.75-339T521-330H150Zm490-190q8 0 14-6t6-14v-120q0-8-6-14t-14-6q-8 0-14 6t-6 14v120q0 8 6 14t14 6Zm0-200q8 0 14-6t6-14q0-8-6-14t-14-6q-8 0-14 6t-6 14q0 8 6 14t14 6Z"
		/>
	</svg>
);
