import { ReactNode } from 'react';

export const symbol_home_work: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M920-780v600q0 24.75-17.625 42.375T860-120H671q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T671-180h189v-600H465v70q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T405-710v-70q0-24.615 17.692-42.308Q440.385-840 465-840h395q24.75 0 42.375 17.625T920-780ZM40-479q0-14 6.5-27.5T65-528l211-151q8.345-5.867 17.069-8.433Q301.793-690 310.897-690q9.103 0 17.897 2.567Q337.588-684.867 346-679l210 151q12 8 18.5 21.5T581-479v299q0 24.75-17.625 42.375T521-120h-97q-24.75 0-42.375-17.625T364-180v-141H258v141q0 24.75-17.625 42.375T198-120h-98q-24.75 0-42.375-17.625T40-180v-299Zm60 .422V-180h98v-141q0-24.75 17.625-42.375T258-381h106q24.75 0 42.375 17.625T424-321v141h97v-298.578L311-630 100-478.578ZM615-563ZM424-180v-201H198v201-201h226v201Zm288-443h30q6 0 10.5-4.5T757-638v-30q0-6-4.5-10.5T742-683h-30q-6 0-10.5 4.5T697-668v30q0 6 4.5 10.5T712-623Zm0 171h30q6 0 10.5-4.5T757-467v-30q0-6-4.5-10.5T742-512h-30q-6 0-10.5 4.5T697-497v30q0 6 4.5 10.5T712-452Zm0 170h30q6 0 10.5-4.5T757-297v-30q0-6-4.5-10.5T742-342h-30q-6 0-10.5 4.5T697-327v30q0 6 4.5 10.5T712-282Z"
		/>
	</svg>
);
