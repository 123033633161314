import { ReactNode } from 'react';

export const symbol_restore_from_trash: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M261-120q-24.75 0-42.375-17.625T201-180v-570h-11q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T190-810h158q0-13 8.625-21.5T378-840h204q12.75 0 21.375 8.625T612-810h158q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T770-750h-11v570q0 24.75-17.625 42.375T699-120H261Zm438-630H261v570h438v-570Zm-438 0v570-570Zm189 215v166q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T510-369v-166l59 63q8.5 9 21.25 9t21.75-9q9-9 9-21t-9-21L501-626q-9-9-21-9t-21 9L348-514q-8 9-8.5 21.1-.5 12.1 8.5 20.9 9 9 21.5 8.5T391-473l59-62Z"
		/>
	</svg>
);
