import { ReactNode } from 'react';

export const symbol_synagogue: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M40-180v-500q0-45.833 32.118-77.917 32.117-32.083 78-32.083Q196-790 228-757.917q32 32.084 32 77.917v31l181-157q8.25-7.895 18.375-11.447Q469.5-821 480-821t20.625 3.553Q510.75-813.895 519-806l181 157v-31q0-45.833 32.118-77.917 32.117-32.083 78-32.083Q856-790 888-757.917q32 32.084 32 77.917v500q0 24.75-17.625 42.375T860-120H545q-6 0-10.5-4.5T530-135v-185q0-21.25-14.325-35.625Q501.351-370 480.175-370 459-370 444.5-355.625 430-341.25 430-320v185q0 6-4.5 10.5T415-120H100q-24.75 0-42.375-17.625T40-180Zm720-450h100v-49.825Q860-701 845.675-715.5q-14.324-14.5-35.5-14.5Q789-730 774.5-715.625 760-701.25 760-680v50Zm-660 0h100v-49.825Q200-701 185.675-715.5q-14.324-14.5-35.5-14.5Q129-730 114.5-715.625 100-701.25 100-680v50Zm0 450h100v-390H100v390Zm160 0h110v-140q0-45.833 32.118-77.917 32.117-32.083 78-32.083Q526-430 558-397.917q32 32.084 32 77.917v140h110v-390L480-761 260-570v390Zm500 0h100v-390H760v390ZM479.941-505Q457-505 441-521.059q-16-16.059-16-39T441.059-599q16.059-16 39-16T519-598.941q16 16.059 16 39T518.941-521q-16.059 16-39 16Z"
		/>
	</svg>
);
