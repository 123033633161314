import { t } from '@transifex/native';
import { Fragment, useMemo, useRef, useState } from 'react';

import { EventTypes } from 'pkg/models/event';

import * as models from 'pkg/api/models';
import { ScheduleItemCreatePayload } from 'pkg/api/models/schedule_item';
import uuid from 'pkg/uuid';
import useMixedState from 'pkg/hooks/useMixedState';
import { useCurrentOrganization } from 'pkg/identity';

import ScheduleItemForm from 'routes/scheduling/templates/schedule_items/form';
import {
	findScheduleItemConflicts,
	resourceConflictMap,
	timesAsNumbers,
} from 'routes/scheduling/utils';

import * as StepModal from 'components/step-modal';

import Form, { FormPayload } from 'components/form/Form';
import useSelectGroups from 'components/group/select_modal';
import useSelectResource, {
	ConflictItem,
	SelectableOptions,
} from 'components/resources/select_modal';

export interface ScheduleItemFormState {
	days: number[];
	startsAt: string;
	endsAt: string;
}

interface CreateScheduleItemProps {
	day?: number;
	groupId?: number;
	resourceId?: number;
	startsAt?: string;
	endsAt?: string;

	hideModal: () => void;
	onBeforeSave: (scheduleItems: models.scheduleItem.ScheduleItem[]) => void;
	onSuccess: () => void;
	onFail: (scheduleItem: models.scheduleItem.ScheduleItem) => void;

	schedule: models.schedule.Schedule;

	groups: models.group.Group[];
	resources: models.resource.Resource[];
	scheduleItems: models.scheduleItem.ScheduleItem[];
}

const CreateScheduleItem = ({
	groups,
	resources,
	schedule,
	day,
	groupId,
	startsAt = '12:00',
	endsAt = '13:00',
	resourceId,
	onBeforeSave,
	onSuccess,
	onFail,
	hideModal,
	scheduleItems,
}: CreateScheduleItemProps) => {
	const organizationId = useCurrentOrganization().id;
	const [modal, setModal] = useState('');
	const [formState, setFormState] = useMixedState<ScheduleItemFormState>({
		days: day ? [day] : [],
		startsAt,
		endsAt,
	});

	const hideSelectModal = () => setModal('');

	const showGroupSelectModal = () => setModal('selectGroups');
	const showSelectResourceModal = () => setModal('selectResource');

	const conflictMap = useMemo(() => {
		return resourceConflictMap(resources);
	}, [resources.length]);

	const cit =
		formState && timesAsNumbers([formState.startsAt, formState.endsAt]);

	const bookedResources = findScheduleItemConflicts(
		conflictMap,
		scheduleItems,
		cit,
		formState.days
	);

	const SelectGroups = useSelectGroups({
		singleSelect: true,
		hideModal: hideSelectModal,
		groups,
		preSelectedGroups: groupId ? [groupId] : [],
	});

	const SelectResource = useSelectResource({
		groupId: organizationId,
		resources,
		selectable: SelectableOptions.multi,
		hideModal: hideSelectModal,
		preSelectedResources: resourceId ? [resourceId] : [],
		setPrimaryResource: true,
		extraColumns: [
			{
				content: t(`Availability`),
				component: <ConflictItem conflicts={bookedResources} />,
			},
		],
	});

	const ref = useRef<HTMLFormElement>(null);

	const defaultScheduleItem: Partial<models.scheduleItem.ScheduleItem> = {
		groupId,
		type: EventTypes.Practice,
	};

	const handleNext = async () =>
		ref.current.dispatchEvent(
			new Event('submit', {
				cancelable: true,
				bubbles: true,
			})
		);

	const handleSave = async (data: FormPayload) => {
		const scheduleItemsToSave: {
			[key: string]: models.scheduleItem.ScheduleItem;
		} = {};

		const unsavedItem = async (day: number) => {
			const clientId: string = uuid();

			const item: models.scheduleItem.ScheduleItem = {
				title: data.title as string,
				groupId: SelectGroups.selectedGroups[0],
				group: groups.find((g) => g.id === SelectGroups.selectedGroups[0]),
				scheduleId: schedule.id,
				description: data.description as string,
				day,
				startsAt: formState.startsAt,
				endsAt: formState.endsAt,
				type: data.eventType as EventTypes,
				clientId,
			};

			if (SelectResource.primaryResource) {
				item.resourceId = SelectResource.primaryResource;
				item.resource = resources.find(
					(r) => r.id === SelectResource.primaryResource
				);
			}

			const selectedResources = SelectResource.selectedResources.filter(
				(id) => id !== SelectResource.primaryResource
			);

			if (selectedResources.length > 0) {
				const filteredResources = resources.filter((r) =>
					selectedResources.includes(r.id)
				);
				item.resources = filteredResources.map((r) => {
					return {
						resourceId: r.id,
						scheduleItemId: null,
						resource: r,
					} as models.scheduleItem.ScheduleItemResource;
				});
			}

			scheduleItemsToSave[clientId] = item;
		};

		const handleCreateScheduleItem = async (
			scheduleItem: models.scheduleItem.ScheduleItem
		): Promise<[boolean, models.scheduleItem.ScheduleItem]> => {
			const payload: ScheduleItemCreatePayload = {
				title: data.title as string,
				groupId: SelectGroups.selectedGroups[0],
				scheduleId: schedule.id,
				description: data.description as string,
				day: scheduleItem.day,
				startsAt: formState.startsAt,
				endsAt: formState.endsAt,
				type: data.eventType as EventTypes,
				clientId: scheduleItem.clientId,
			};

			if (SelectResource.primaryResource) {
				payload.resourceId = SelectResource.primaryResource;
			}

			const selectedResources = SelectResource.selectedResources.filter(
				(resource) => resource !== SelectResource.primaryResource
			);

			if (SelectResource.selectedResources.length > 0) {
				payload.resourceIds = selectedResources;
			}

			const [req, item] = await models.scheduleItem.create(payload);

			if (req.ok) {
				return [true, item];
			}

			return [false, scheduleItem];
		};

		formState.days.forEach(async (d) => {
			unsavedItem(d);

			if (Object.keys(scheduleItemsToSave).length === formState.days.length) {
				onBeforeSave?.(Object.values(scheduleItemsToSave).map((v) => v));

				Object.values(scheduleItemsToSave).forEach(async (s) => {
					const [ok, item] = await handleCreateScheduleItem(s);

					if (ok) {
						onSuccess?.();
					} else {
						onFail?.(item);
						return;
					}
				});
			}
		});

		hideModal();
	};

	const canGoNext =
		formState.days.length > 0 &&
		(SelectResource.selectedResources.length > 0 ||
			SelectGroups.selectedGroups.length > 0);

	return (
		<Fragment>
			<StepModal.Base onClose={hideModal}>
				<StepModal.Step
					title={t(`New booking`)}
					nextLabel={t(`Confirm booking`)}
					onNext={handleNext}
					canGoNext={canGoNext}>
					<Form formRef={ref} onSubmit={handleSave}>
						<ScheduleItemForm
							groups={groups}
							schedule={schedule}
							scheduleItem={defaultScheduleItem}
							showGroupSelectModal={showGroupSelectModal}
							showSelectResourceModal={showSelectResourceModal}
							selectedGroup={SelectGroups.selectedGroups}
							selectResource={SelectResource.select}
							primaryResource={SelectResource.primaryResource}
							selectPrimaryResource={SelectResource.selectPrimaryResource}
							selectedResource={SelectResource.selectedResources}
							formState={formState}
							setFormState={setFormState}
						/>
					</Form>
					{modal === 'selectGroups' && SelectGroups.Modal}
					{modal === 'selectResource' && SelectResource.Modal}
				</StepModal.Step>
			</StepModal.Base>
		</Fragment>
	);
};

export default CreateScheduleItem;
