import { Component } from 'react';
import PropTypes from 'prop-types';
import { t } from '@transifex/native';

import { NOOP } from 'pkg/utils';
import * as routes from 'pkg/router/routes';
import { link } from 'pkg/router/utils';
import { AccountIdentityContext } from 'pkg/identity/account';

import CardBase, * as Card from 'components/Card';
import RelativeTime from 'components/RelativeDateTime';
import AddButton from 'components/add-button';
import Modal from 'components/modal';

import RatingForm from 'components/rating/Form';
import { UserActionListItem } from 'components/group/UserActionList';
import AssessmentCreate from 'components/assessment/Create';

import * as ContextMenu from 'design/context_menu';

export default class ListItem extends Component {
	static contextType = AccountIdentityContext;

	static propTypes = {
		groupId: PropTypes.number.isRequired,
		organizationId: PropTypes.number.isRequired,
		nowTimestamp: PropTypes.number.isRequired,
		onSave: PropTypes.func,
	};

	static defaultProps = {
		primaryRating: {
			id: '',
			createdAt: 0,
		},
		onSave: NOOP,
	};

	state = {
		showForm: false,
		baseOnRating: null,
		showAssessmentModal: false,
	};

	get isOutdated() {
		const { primaryRating } = this.props;
		const monthMS = 60 * 60 * 24 * 60;
		const nowTS = Math.floor(Date.now() / 1000);
		let createdAt = 0;

		if (primaryRating.createdAt) {
			createdAt = primaryRating.createdAt;
		}

		return createdAt + monthMS < nowTS;
	}

	get hasPrimaryRating() {
		return !!this.props.primaryRating;
	}

	closeCreateRatingModal = async (p) => {
		await p;

		this.setState({
			showForm: false,
		});
	};

	handleUpdateClick = (e) => {
		if (e?.preventDefault) {
			e.preventDefault();
		}

		this.setState({
			showForm: true,
			baseOnRating: this.props.primaryRating.id,
		});
	};

	openAssessmentModal = () => this.setState({ showAssessmentModal: true });

	onSave = () => {
		this.setState({
			showForm: false,
		});

		this.props.onSave();
	};

	get messages() {
		const { primaryRating } = this.props;
		let saveMessage = '';

		let cardMessage = t(`Assessment missing`);
		if (primaryRating.id && primaryRating.isPrimaryRating) {
			cardMessage = (
				<RelativeTime dateTime={this.props.primaryRating.createdAt} />
			);

			saveMessage = (
				<RelativeTime dateTime={this.props.primaryRating.createdAt} />
			);
		}
		if (primaryRating.id && !primaryRating.isPrimaryRating) {
			cardMessage = t(`Draft`);
		}

		return {
			cardMessage,
			saveMessage,
		};
	}

	get ratingForm() {
		const { groupId, organizationId, membership, isAdminOrStaff } = this.props;

		return (
			<RatingForm
				onSave={this.onSave}
				ratingId={this.state.baseOnRating}
				userId={membership.userId}
				groupId={groupId}
				organizationId={organizationId}
				isAdminOrStaff={isAdminOrStaff}
			/>
		);
	}

	get createRatingModal() {
		const { showForm } = this.state;

		if (!showForm) {
			return null;
		}

		const { firstName, lastName } = this.props.membership?.user;

		return (
			<Modal open onClose={this.closeCreateRatingModal} key="CreateRatingModal">
				<CardBase>
					<Card.Header centered>
						<Card.Heading>
							{firstName} {lastName}
						</Card.Heading>
					</Card.Header>

					<Card.Divider />

					{this.ratingForm}
				</CardBase>
			</Modal>
		);
	}

	get itemActions() {
		const items = [];
		const { membership, primaryRating, lastSelfRating, groupId } = this.props;
		const orgId = this.context.organization.id;
		const { user } = membership;

		const ratingId = primaryRating.id || lastSelfRating.id;

		items.push(
			<ContextMenu.LinkItem
				key={`user-rating-action--new-${membership.userId}`}
				onClick={this.openAssessmentModal}>
				<ContextMenu.ItemIcon name="add" />
				{t('New')}
			</ContextMenu.LinkItem>
		);

		if (this.props.primaryRating.id) {
			items.push(
				<ContextMenu.LinkItem
					key={`user-rating-action--update-${membership.userId}`}
					onClick={this.handleUpdateClick}>
					<ContextMenu.ItemIcon name="edit" />
					{t('Update')}
				</ContextMenu.LinkItem>
			);
		}

		if (ratingId) {
			items.push(
				<ContextMenu.LinkItem
					key={`user-rating-action--view-${membership.userId}`}
					href={link(
						routes.User.Profile.PositionMatch(
							orgId,
							groupId,
							user.id,
							ratingId
						),
						{ tab: 'history' }
					)}>
					<ContextMenu.ItemIcon name="history" />
					{t(`History`)}
				</ContextMenu.LinkItem>
			);
		}

		return items;
	}

	closeAssessmentModal = async (p) => {
		await p;

		this.setState({
			showAssessmentModal: false,
		});
	};

	get createAssessmentModal() {
		if (this.state.showAssessmentModal) {
			const { membership, isAdminOrStaff } = this.props;

			return (
				<AssessmentCreate
					userId={membership.userId}
					groupId={membership.groupId}
					onClose={this.closeAssessmentModal}
					isAdminOrStaff={isAdminOrStaff}
				/>
			);
		}

		return null;
	}

	render() {
		const { membership } = this.props;

		if (!membership) {
			return null;
		}

		const { user } = membership;
		if (!user) {
			return null;
		}

		const ratingId =
			this.props.primaryRating.id || this.props.lastSelfRating.id;

		return [
			<UserActionListItem
				key={membership.userId}
				userLabel={this.messages.cardMessage}
				missing={!this.hasPrimaryRating || this.isOutdated}
				user={user}
				groupUser={membership}
				primaryRating={this.props.primaryRating}
				lastSelfRating={this.props.lastSelfRating}
				showHeatmap={this.props.showHeatmap}
				to={ratingId ? 'user.profile.position-match' : ''}
				params={{
					organizationId: this.context.organization.id,
					ratingId,
					groupId: this.props.groupId,
					userId: user.id,
				}}
				userId={user.id}>
				<ContextMenu.Menu
					title={`${user.firstName} ${user.lastName}`}
					toggleWith={<AddButton />}>
					{this.itemActions}
				</ContextMenu.Menu>
				{this.createRatingModal}
			</UserActionListItem>,
			this.createAssessmentModal,
		];
	}
}
