import { t } from '@transifex/native';
import { Fragment } from 'react';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';

import { useOnboardingContext } from 'routes/public/onboarding';
import Button from 'routes/public/styles/Button';
import * as formStyles from 'routes/public/styles/forms/styles.css';
import ThreeSixtyLogo from 'routes/public/styles/logo';
import Title from 'routes/public/styles/Title';
import AccountCard from 'routes/public/styles/AccountCard';
import { useOnboardingState } from 'routes/public/hooks';
import GroupCard from 'routes/public/styles/GroupCard';

import Column from 'components/layout/column';

export default function AccountInviteFinished(): JSX.Element {
	const { resetStore } = useOnboardingContext();
	const onboardingState = useOnboardingState();

	const invite = onboardingState.get<models.onboardingInfo.Invite>('invite');
	const account = onboardingState.get<models.account.Account>('account');

	const save = async () => {
		resetStore();
		window.history.pushState({}, '', '/');
		window.location.reload();
	};

	return (
		<Column spacing={styles.spacing._8}>
			<ThreeSixtyLogo />

			<Title title={t('Success!')} />

			<Column spacing={styles.spacing._4}>
				<span className={formStyles.label}>{t('Your account')}</span>
				<AccountCard account={account} />
			</Column>

			<Column spacing={styles.spacing._4}>
				<span className={formStyles.label}>{t('Connected to')}</span>

				{invite?.targetChildren && (
					<Fragment>
						{invite.targetChildren.map((account: models.account.Account) => (
							<AccountCard account={account} key={account.id} hideEmail />
						))}
					</Fragment>
				)}

				{invite?.targetGroups && (
					<Fragment>
						{invite.targetGroups.map((group: models.group.Group) => (
							<GroupCard group={group} key={group.name} />
						))}
					</Fragment>
				)}
			</Column>

			<Button primary label={t('Finish')} onClick={save} />
		</Column>
	);
}
