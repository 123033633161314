import {
	ChangeEvent,
	MutableRefObject,
	ReactNode,
	useRef,
	useEffect,
} from 'react';
import styled from 'styled-components';

import Icon from 'components/icon';

import * as Input from 'components/form/inputs';

export interface SearchInputProps {
	forwardedRef?: MutableRefObject<HTMLInputElement>;
	value?: string;
	placeholder?: string;
	onChange?: (value: string) => void;
	onReset?: () => void;
	changeDelay?: number;
	queryName?: string;
	small?: boolean;
	rounded?: boolean;
	filled?: boolean;
	className?: string;
	testid?: string;
	children?: ReactNode | ReactNode[];
}

const SearchInput: React.FC<React.PropsWithChildren<SearchInputProps>> = ({
	forwardedRef,
	placeholder,
	value,
	onChange,
	onReset,
	changeDelay = 500,
	small,
	rounded,
	filled,
	className,
	testid,
	children,
}) => {
	const ref = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (value && ref.current) {
			ref.current.value = value;
		}
	}, [value]);

	const clear = (): void => {
		const inputRef = forwardedRef?.current || ref.current;
		if (inputRef) {
			inputRef.focus();
			inputRef.value = '';
			if (onChange) {
				onChange('');
			}
		}

		if (onReset) {
			onReset();
		}
	};

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		if (onChange) {
			onChange(event.target.value);
		}
	};

	const showResetButton = ref.current && ref.current.value;

	return (
		<Input.Field
			ref={forwardedRef || ref}
			defaultValue={value}
			className={className}
			small={small}
			rounded={rounded}
			filled={filled}
			changeDelay={changeDelay}
			onChange={handleChange}
			testid={testid}
			placeholder={placeholder}>
			<Input.Prefix inline>
				<Icon name="search" />
			</Input.Prefix>
			{showResetButton && (
				<Input.Suffix inline onClick={clear}>
					<Icon name="close-circle-filled" size={1.35} />
				</Input.Suffix>
			)}
			{children}
		</Input.Field>
	);
};

export default styled(SearchInput)``;
