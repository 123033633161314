import { ReactNode } from 'react';

export const symbol_signal_wifi_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M723-363q-8 8-20 7.5t-20-8.5q-8-8-8-20.5t8-20.5l189-189q-85-66-184.5-106T480-740q-39 0-77.5 5T326-719q-12 4-22.5-2.5T289-740q-4-12 2.5-23t18.5-15q42-12 84-17t86-5q127 0 242.5 48.5T935-621q5 4 7.5 10t2.5 12q0 6-1.5 11.5T937-577L723-363ZM480-202l96-96-372-372q-30 16-59 35t-57 41l392 392ZM816-59 617-257 501-141q-5 5-10 7t-11 2q-5 0-10.5-2t-10.5-7L23-577q-5-5-6.5-10T15-598q0-6 2.5-12t7.5-11q32-29 64.5-52t68.5-43L58-816q-9-9-9-21.5t9-21.5q9-9 21.5-9t21.5 9l758 758q9 9 9 21t-9 21q-9 9-21.5 9T816-59ZM494-552Zm-104 68Z"
		/>
	</svg>
);
