import { Fragment, lazy, Suspense, useMemo } from 'react';

import * as styles from 'routes/kitchen_sink/formatting.css';

import {
	addFormatter,
	FormattedBlock,
	FormattedContent,
} from 'components/formatted-content/FormattedContent';

const allowedInlineFormatters = [
	'md:formatting',
	'md:anchors',
	'md:code-inline',
];

addFormatter('md:lists', {
	defaultIgnored: true,
	selector: /(^\t{0,4}(-|\*|\d+\.)\s.*)/gim,
	ignoreMatchSplit: true,
	decorator: (guid: string, match: string): JSX.Element => {
		const isOrdered = match.includes('1. '); // Might be a bit naïve but...
		const rows = match.split('\n');

		const items: any = {};

		let indent: number = 0;
		let parent: string = null;

		const trimRow = (row: string) =>
			`${row}`
				.trim()
				.replace(/^(-|\*|\d+\.)/, '')
				.trim();

		rows.forEach((row: string, index: number) => {
			const nextIndent = row.split(/\t/)?.length - 1 || 0;

			const item = trimRow(row);

			if (nextIndent !== indent) {
				parent = trimRow(rows[index - 1]);
				indent = nextIndent;

				if (nextIndent === 0) {
					parent = null;
				}
			}

			if (!parent) {
				items[item] = [];
			} else {
				const next = items[parent] ?? [];

				next.push(item);
				items[parent] = next;
			}
		});

		if (isOrdered) {
			return (
				<ol key={guid} className={styles.ol}>
					{Object.entries(items).map(([key, subItems]) => (
						<li>
							<FormattedBlock
								raw={key}
								allowedFormatters={allowedInlineFormatters}
							/>
							{(subItems as string[]).length > 0 && (
								<ol className={styles.ol}>
									{(subItems as string[]).map((item: string) => (
										<li>
											<FormattedBlock
												raw={item}
												allowedFormatters={allowedInlineFormatters}
											/>
										</li>
									))}
								</ol>
							)}
						</li>
					))}
				</ol>
			);
		}

		return (
			<ul key={guid} className={styles.ul}>
				{Object.entries(items).map(([key, subItems]) => (
					<li>
						<FormattedBlock
							raw={key}
							allowedFormatters={allowedInlineFormatters}
						/>
						{(subItems as string[]).length > 0 && (
							<ul className={styles.ul}>
								{(subItems as string[]).map((item: string) => (
									<li>
										<FormattedBlock
											raw={item}
											allowedFormatters={allowedInlineFormatters}
										/>
									</li>
								))}
							</ul>
						)}
					</li>
				))}
			</ul>
		);
	},
});

addFormatter('md:blockquote', {
	defaultIgnored: true,
	selector: /(^>\s.*)/gim,
	ignoreMatchSplit: true,
	decorator: (guid: string, match: string): JSX.Element => {
		const rows = match
			.split('\n')
			.map((row: string) => row.replace('>', '').trim())
			.join('\n');

		return (
			<blockquote key={guid} className={styles.bq}>
				<FormattedContent
					raw={rows}
					allowedFormatters={allowedInlineFormatters}
				/>
			</blockquote>
		);
	},
});

addFormatter('md:headings', {
	defaultIgnored: true,
	selector: /^(#{1,4} .*)$/gi,
	decorator: (guid: string, match: string): JSX.Element => {
		match.match(/^(#{1,4}) (.*)$/gi);

		const headingSize: number = RegExp.$1.toString().length;

		switch (headingSize) {
			case 1:
				return (
					<h1 key={guid} className={styles.h1}>
						{RegExp.$2}
					</h1>
				);
			case 2:
				return (
					<h2 key={guid} className={styles.h2}>
						{RegExp.$2}
					</h2>
				);
			case 3:
				return (
					<h3 key={guid} className={styles.h3}>
						{RegExp.$2}
					</h3>
				);
			case 4:
				return (
					<h4 key={guid} className={styles.h4}>
						{RegExp.$2}
					</h4>
				);
			default:
				return <Fragment key={guid}>{RegExp.$2}</Fragment>;
		}
	},
});

addFormatter('md:formatting', {
	defaultIgnored: true,
	selector: /([_|*|~|+]{2}.+?[_|*|~|+]{2})/gim,
	decorator: (guid: string, match: string): JSX.Element => {
		const string = match.slice(2, -2);

		let type = '';

		// @NOTE Hacky validation, but it works...

		if (match.startsWith('__') && match.endsWith('__')) {
			type = 'em';
		} else if (match.startsWith('**') && match.endsWith('**')) {
			type = 'strong';
		} else if (match.startsWith('~~') && match.endsWith('~~')) {
			type = 'del';
		} else if (match.startsWith('++') && match.endsWith('++')) {
			type = 'ins';
		}

		if (!type) {
			return <FormattedBlock key={guid} raw={match} />;
		}

		switch (type) {
			case 'em':
				return <em key={guid}>{string}</em>;
			case 'strong':
				return <strong key={guid}>{string}</strong>;
			case 'ins':
				return <ins key={guid}>{string}</ins>;
			case 'del':
				return <del key={guid}>{string}</del>;
			default:
				return <FormattedBlock key={guid} raw={match} />;
		}
	},
});

addFormatter('md:anchors', {
	defaultIgnored: true,
	selector: /(\[.*?\]\(.*?\))/gim,
	decorator: (guid: string, match: string): JSX.Element => {
		match.match(/\[(.*?)\]\((.*?)\)/gim);

		return (
			<a key={guid} href={RegExp.$2} target="_blank" rel="noreferrer">
				{RegExp.$1}
			</a>
		);
	},
});

addFormatter('md:spacer', {
	selector: /(={3,})/g,
	decorator: (): JSX.Element => <div className={styles.spacer} />,
});

addFormatter('md:divider', {
	selector: /(-{3,})/g,
	decorator: (): JSX.Element => <hr className={styles.divider} />,
});

addFormatter('md:code-block', {
	defaultIgnored: true,
	selector: /^[`]{3}(?:\w+)?\n([\s\S]*?)[`]{3}$/gim,
	decorator: (guid: string, match: string): JSX.Element => {
		return (
			<pre data-code-block className={styles.syntax}>
				{match.replace(/^\s+|\s+$/g, '')}
			</pre>
		);
	},
});

addFormatter('md:code-inline', {
	defaultIgnored: true,
	selector: /[`]{1}(.+?)[`]{1}/gim,
	decorator: (guid: string, match: string): JSX.Element => {
		return <code className={styles.code}>{match}</code>;
	},
});

interface IncludeProps {
	path: string;
}

function Include({ path }: IncludeProps): JSX.Element {
	const LazyComponent = useMemo(
		() => lazy(() => import(`routes/kitchen_sink/views/${path}`)),
		[]
	);

	return (
		<Suspense>
			<LazyComponent />
		</Suspense>
	);
}

addFormatter('md:includes', {
	defaultIgnored: true,
	selector: /(\{\{.*?\}\})/gim,
	decorator: (guid: string, match: string): JSX.Element => {
		const componmentPath = match.slice(2, -2);

		return <Include path={componmentPath} />;
	},
});
