import styled from 'styled-components';
import { Fragment, useMemo } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import { useQueryState } from 'pkg/hooks/query-state';
import DateTime, { Granularity } from 'pkg/datetime';
import * as models from 'pkg/api/models';
import {
	getFirstDayOfWeek,
	getLastDayOfWeek,
	getWeekNumberForDay,
} from 'pkg/date';
import * as routes from 'pkg/router/routes';
import { useCurrentGroup, useCurrentOrganization } from 'pkg/identity';
import { pushState } from 'pkg/router/state';

import ScheduleFilters from 'routes/scheduling/templates/filters';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import Icon from 'components/icon';

import * as ActionBar from 'components/layout/ActionBar';
import Row from 'components/layout/row';
import * as DateNavigator from 'components/actionbar/DateNavigator';
import Column from 'components/layout/column';
import SelectView from 'components/scheduling/SelectView';

import Button from 'design/button';

const ActionBarRow = styled(Row)`
	> * {
		margin-right: 0 !important;
	}
`;

const PrintTitle = styled.div`
	padding-top: var(--spacing-5);
	width: 100%;
	display: none;
	padding-bottom: 1cm;

	span {
		font-weight: var(--font-weight-semibold);
		font-size: var(--font-size-3xl);
		margin-bottom: var(--spacing-2);
	}

	@media print {
		display: block;
		background: var(--palette-white);
	}
`;
interface BookingsActionBarProps {
	timestampDate: Date;

	groups: models.group.Group[];
	resources?: models.resource.Resource[];
	bookings: models.booking.Booking[];

	handleShowBookingModal?: () => void;
}

const BookingsActionBar = ({
	handleShowBookingModal,
	timestampDate,
	groups,
	resources,
	bookings,
}: BookingsActionBarProps) => {
	const group = useCurrentGroup();
	const organizationId = useCurrentOrganization().id;
	const qs = useQueryState();

	const timestampDateTime = new DateTime(timestampDate);
	const startDate = new DateTime(getFirstDayOfWeek(timestampDate));
	const endDate = new DateTime(getLastDayOfWeek(timestampDate));
	const weekNumber = getWeekNumberForDay(timestampDate);

	const todayDate = useMemo(() => {
		return new DateTime(new Date());
	}, []);

	const hideToday = useMemo(() => {
		const dateObj = todayDate;

		return dateObj.isBetween(
			startDate.getUnixTimestamp() * 1000,
			endDate.getUnixTimestamp() * 1000
		);
	}, [timestampDate, todayDate]);

	const handleNavigatePrev = () => {
		const dateTime = timestampDateTime.prev(Granularity.week, 1);

		qs.set('timestamp', dateTime.getUnixTimestamp());
		qs.commit();
	};

	const handleNavigateNext = () => {
		const dateTime = timestampDateTime.next(Granularity.week, 1);

		qs.set('timestamp', dateTime.getUnixTimestamp());
		qs.commit();
	};

	const toggleToday = () => {
		qs.set('timestamp', todayDate.getUnixTimestamp());
		qs.commit();
	};

	const todayButton = !hideToday ? (
		<Button label={t('Today')} onClick={toggleToday} />
	) : null;

	const goToPrintableUrl = (): string => {
		const href = routes.Bookings.Print(organizationId, group.id);

		pushState(href, qs.toQueryString());

		return;
	};

	const navActions = (
		<Fragment>
			<ActionBar.Search placeholder={t('Search')} />
			<ScheduleFilters
				groups={groups}
				resources={resources}
				bookings={bookings}
				groupId={organizationId}
			/>
			<SmallScreen>
				<SelectView />
			</SmallScreen>
			<SmallScreen>{todayButton}</SmallScreen>
			<LargeScreen>
				<Button icon="print" onClick={goToPrintableUrl}>
					{t('Print')}
				</Button>
			</LargeScreen>
			{handleShowBookingModal && (
				<Button
					icon="add"
					label={t(`New booking`)}
					primary
					onClick={handleShowBookingModal}
				/>
			)}
		</Fragment>
	);

	return (
		<Fragment>
			<PrintTitle>
				<DateNavigator.Date>
					<Column spacing={styles.spacing._1}>
						<DateNavigator.DateWrapper>
							<span>
								{new DateTime(
									getFirstDayOfWeek(timestampDate)
								).toLocaleDateString({
									day: 'numeric',
									month: 'short',
								})}
							</span>
							–
							<span>
								{new DateTime(
									getLastDayOfWeek(timestampDate)
								).toLocaleDateString({
									day: 'numeric',
									month: 'short',
								})}
							</span>
						</DateNavigator.DateWrapper>
						<DateNavigator.WeekNumber color="gray">
							{t('Week')} {weekNumber}
						</DateNavigator.WeekNumber>
					</Column>
				</DateNavigator.Date>
			</PrintTitle>
			<ActionBar.FilterBar>
				<ActionBar.PrimaryAction>
					<Row autoColumns="max-content">
						<Row spacing={styles.spacing._2} autoColumns="max-content">
							<DateNavigator.Link reverse onClick={handleNavigatePrev}>
								<Icon name="chevron" />
							</DateNavigator.Link>

							<DateNavigator.Link onClick={handleNavigateNext}>
								<Icon name="chevron" />
							</DateNavigator.Link>
						</Row>
						<DateNavigator.Date>
							<Column spacing={styles.spacing._1}>
								<DateNavigator.DateWrapper>
									<span>
										{startDate.toLocaleDateString({
											day: 'numeric',
											month: 'short',
										})}
									</span>
									–
									<span>
										{endDate.toLocaleDateString({
											day: 'numeric',
											month: 'short',
										})}{' '}
									</span>
								</DateNavigator.DateWrapper>
								<DateNavigator.WeekNumber color="gray">
									{t('Week')} {weekNumber}
								</DateNavigator.WeekNumber>
							</Column>
						</DateNavigator.Date>
						<LargeScreen>{todayButton}</LargeScreen>
					</Row>
				</ActionBar.PrimaryAction>

				<SmallScreen>{navActions}</SmallScreen>
				<LargeScreen>
					<ActionBarRow
						autoColumns="max-content"
						align="center"
						spacing={styles.spacing._3}>
						{navActions}
					</ActionBarRow>
				</LargeScreen>
			</ActionBar.FilterBar>
		</Fragment>
	);
};

export default BookingsActionBar;
