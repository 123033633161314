import { ReactNode } from 'react';

export const symbol_business_center: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-120q-24 0-42-18t-18-42v-480q0-24 18-42t42-18h180v-100q0-24 18-42t42-18h200q24 0 42 18t18 42v100h180q24 0 42 18t18 42v480q0 24-18 42t-42 18H140Zm240-600h200v-100H380v100Zm440 349H571v30.175q0 12.825-8.638 21.325t-21.408 8.5H420.77q-12.77 0-21.27-8.625T391-341v-30H140v191h680v-191Zm-369 0h60v-60h-60v60Zm-311-60h251v-30.175q0-12.825 8.638-21.325t21.408-8.5H541.23q12.77 0 21.27 8.625T571-461v30h249v-229H140v229Zm340 30Z"
		/>
	</svg>
);
