import { Fragment } from 'react';
import { t } from '@transifex/native';

import useTooltip from 'pkg/hooks/useTooltip';
import * as models from 'pkg/api/models';
import { cssClasses } from 'pkg/css/utils';
import { useCurrentAccountUserIds, useCurrentAccountWards } from 'pkg/identity';

import { getCalendarEventColorVars } from 'routes/group/calendar/config';
import EventIcons from 'routes/group/calendar/components/events/event-icons';
import { SharedEventProps } from 'routes/group/calendar/components/events/single';

import * as eventStyles from 'components/event/calendar-styles/styles.css';

import * as css from './styles.css';

export default function MonthStyle({
	event,
	previewContextMenu,
	handleEventClick,
}: SharedEventProps) {
	const { tooltip, onMouseEnter } = useTooltip(
		t('Group: {group_name}', { group_name: event.group.name })
	);

	const calendarEventStyles = getCalendarEventColorVars(event.type);

	const wardAccountRelations = useCurrentAccountWards();
	const activeUserIds = useCurrentAccountUserIds();
	const wardUserIds =
		models.accountRelation.findAllUserIds(wardAccountRelations);

	const eventUsers = models.eventUser.findAllEventUsers(event.users, [
		...activeUserIds,
		...wardUserIds,
	]);

	const isCanceled = models.event.isCanceled(event);
	const unhandled = eventUsers.some((ev) => models.eventUser.isUnhandled(ev));
	const hasEnded = models.event.hasEnded(event);

	return (
		<Fragment>
			<div
				className={cssClasses(
					css.monthWrapper,
					eventStyles['event-color-styles'],
					unhandled && !models.event.hasEnded(event)
						? eventStyles.unhandled
						: '',
					hasEnded ? eventStyles['has-finished'] : ''
				)}
				style={calendarEventStyles}
				onClick={handleEventClick}
				data-event-type={event.type}
				onMouseEnter={onMouseEnter}>
				<EventIcons event={event} />
				{isCanceled ? (
					<div className={css.monthText}>
						<div className={css.allCaps}>{t('Canceled')}</div>
						<div className={css.strikeThrough}>{event.title}</div>
					</div>
				) : (
					<div className={css.monthText}>{event.title}</div>
				)}
			</div>
			{previewContextMenu}
			{tooltip}
		</Fragment>
	);
}
