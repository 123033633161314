import { ReactNode } from 'react';

export const symbol_regular_expression: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M80-481q0-72 25-137.5T176-739q8-10 20.5-11t21.5 8q9 9 8.5 21.5T218-697q-38 46-58 101t-20 115q0 59 21 113t58 101q8 11 8.5 24t-8.5 22q-9 9-22 8.5T176-223q-45-55-70.5-121T80-481Zm300 231q-21 0-35.5-14.5T330-300q0-21 14.5-35.5T380-350q21 0 35.5 14.5T430-300q0 21-14.5 35.5T380-250Zm179-200q-13 0-21.5-8.5T529-480v-49l-41 25q-11 6-23 3t-18-14q-6-11-3-23t14-18l41-24-42-24q-11-6-13.5-18t3.5-23q6-11 18-14t23 4l41 24v-49q0-13 8.5-21.5T559-710q13 0 21.5 8.5T589-680v49l41-25q11-6 23-3t18 14q6 11 3 23t-14 18l-41 24 42 24q11 6 13.5 18t-3.5 23q-6 11-18 14t-23-4l-41-24v49q0 13-8.5 21.5T559-450Zm321-31q0 72-25 137.5T784-223q-8 10-20.5 10.5T742-221q-9-9-9-21.5t8-23.5q37-46 58-100.5T820-481q0-60-21-115t-58-101q-8-11-8-23.5t9-21.5q9-9 21.5-8t20.5 11q45 55 70.5 120.5T880-481Z"
		/>
	</svg>
);
