import { useContext, useMemo, useRef, useState } from 'react';
import { t } from '@transifex/native';

import * as flashActions from 'pkg/actions/flashes';

import useMixedState from 'pkg/hooks/useMixedState';
import {
	getGmtString,
	getTimeZoneByGmtString,
	getTimeZoneFromSessionStorage,
} from 'pkg/timezone';
import DateTime, { Granularity } from 'pkg/datetime';
import * as models from 'pkg/api/models';
import { BookingCreatePayload } from 'pkg/api/models/booking';
import { EventFlags, EventTypes } from 'pkg/api/models/event';
import { useCurrentUser } from 'pkg/identity';

import BookingForm from 'routes/scheduling/bookings/booking_form';
import { BookingsContext } from 'routes/scheduling/bookings';
import {
	findBookingConflicts,
	resourceConflictMap,
} from 'routes/scheduling/utils';

import * as StepModal from 'components/step-modal';

import Form, { FormPayload, submitForm } from 'components/form/Form';
import useSelectGroups from 'components/group/select_modal';
import useSelectResource, {
	ConflictItem,
	SelectableOptions,
} from 'components/resources/select_modal';

interface CreateBookingModalProps {
	groupId: number;
	date?: Date;

	hideModal: () => void;

	group?: models.group.Group;
	resource?: models.resource.Resource;
}

export interface BookingFormState {
	timeZone: string;
	start: number;
	end: number;
}

const CreateBookingModal = ({
	groupId,
	hideModal,
	group,
	date = new Date(),
	resource,
}: CreateBookingModalProps) => {
	const currentDate = new Date();
	const dateTime = new DateTime(date).setTime(currentDate.getHours(), 0, 0, 0);
	const timeZone =
		useMemo(() => getGmtString(getTimeZoneFromSessionStorage()), []) || '';

	const [formState, setFormState] = useMixedState<BookingFormState>({
		timeZone,
		start: dateTime.getUnixTimestamp(),
		end: dateTime.next(Granularity.hour, 2).getUnixTimestamp(),
	});

	const [modal, setModal] = useState('');

	const bookingsContext = useContext(BookingsContext);
	const currentUser = useCurrentUser();
	const hideSelectModal = () => setModal('');
	const showGroupSelectModal = () => setModal('selectGroups');
	const showSelectResourceModal = () => setModal('selectResource');

	const conflictMap = useMemo(() => {
		return resourceConflictMap(bookingsContext.resources);
	}, [bookingsContext.resources.length]);

	const bookedResources = findBookingConflicts(
		conflictMap,
		bookingsContext.bookings,
		[formState.start, formState.end]
	);

	const SelectGroups = useSelectGroups({
		singleSelect: true,
		hideModal: hideSelectModal,
		groups: bookingsContext.groups,
		preSelectedGroups: group ? [group.id] : [],
	});
	const SelectResource = useSelectResource({
		groupId,
		resources: bookingsContext.resources,
		selectable: SelectableOptions.multi,
		hideModal: hideSelectModal,
		preSelectedResources: resource ? [resource.id] : [],
		setPrimaryResource: true,
		extraColumns: [
			{
				content: t('Availability'),
				component: <ConflictItem conflicts={bookedResources} />,
			},
		],
	});

	const ref = useRef(null);

	const handleNext = async () => submitForm(ref);

	const handleSave = async (data: FormPayload) => {
		const payload: BookingCreatePayload = {
			userId: currentUser.id,
			forGroupId: SelectGroups.selectedGroups[0],
			byGroupId: groupId,
			resourceId: null,
			timezone: getTimeZoneByGmtString(formState.timeZone).name,
			startsAt: formState.start,
			endsAt: formState.end,
			startsAtLocal: DateTime.fromTimestamp(formState.start).toDateTimeString(),
			endsAtLocal: DateTime.fromTimestamp(formState.end).toDateTimeString(),
			type: data.eventType as EventTypes,
			title: data.title as string,
			description: data.description as string,
		};

		if (SelectResource.primaryResource) {
			payload.resourceId = SelectResource.primaryResource;
		}

		if (data.inviteUsers) {
			payload.inviteUsersToEvent = true;
			payload.inviteAdminAndStaffToEvent = true;
		}

		if (data.autoInviteNewUsers) {
			payload.eventFlags = [
				EventFlags.EventFlagsAutoInviteAdminAndStaff,
				EventFlags.EventFlagsAutoInviteUsers,
			];
		}

		const selectedResources = SelectResource.selectedResources.filter(
			(r) => r !== SelectResource.primaryResource
		);

		if (selectedResources.length > 0) {
			payload.resourceIds = selectedResources;
		}

		const [request, booking] = await models.booking.create(
			payload,
			data.createEvents as boolean
		);

		if (request.ok) {
			flashActions.show({
				title: t('The booking {bookingTitle} has been created', {
					bookingTitle: booking.title,
				}),
				message: booking.eventId && t('And a connected event has been created'),
			});
			await bookingsContext.refreshBookings();

			hideModal();
		} else {
			flashActions.show({
				title: t('Something went wrong'),
			});
		}
	};

	return (
		<StepModal.Base onClose={hideModal}>
			<StepModal.Step
				title={t('New booking')}
				nextLabel={t('Confirm booking')}
				onNext={handleNext}
				canGoNext={SelectGroups.selectedGroups.length > 0}>
				<Form formRef={ref} onSubmit={handleSave}>
					<BookingForm
						resources={bookingsContext.resources}
						groups={bookingsContext.flattenedGroups}
						formState={formState}
						setFormState={setFormState}
						showGroupSelectModal={showGroupSelectModal}
						showSelectResourceModal={showSelectResourceModal}
						selectedGroup={SelectGroups.selectedGroups}
						selectedResource={SelectResource.selectedResources}
						selectResource={SelectResource.select}
						selectPrimaryResource={SelectResource.selectPrimaryResource}
						primaryResource={SelectResource.primaryResource}
					/>
				</Form>
				{modal === 'selectGroups' && SelectGroups.Modal}
				{modal === 'selectResource' && SelectResource.Modal}
			</StepModal.Step>
		</StepModal.Base>
	);
};

export default CreateBookingModal;
