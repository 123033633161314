import { ReactNode } from 'react';

export const symbol_signal_wifi_bad: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m760-223-63 62q-9 8-21.1 8.5-12.1.5-20.9-8.5-9-9-9-21t9-21l63-63-63-63q-9-9-9-21t9-21q9-9 21-9t21 9l63 63 63-63q9-9 21.158-9 12.158 0 21 8.609Q874-362 874-349.533q0 12.466-9 21.533l-62 62 62 63q8 9 8.5 21.1.5 12.1-8.5 20.9-9 9-21 8.5t-21-8.5l-63-62ZM480-800q120 0 228.5 40.5T909-645q11 9 16.5 21t5.5 26q0 12.49-4.5 24.245T912-552L806-446q-8 8-20.5 8t-20.5-8q-8-8-8-20.5t8-20.5l107-107q-87-68-184.5-107T480-740q-110 0-207.5 39T88-594l392 392 59-59q8-8 20.5-8t20.5 8q8 8 8 20.5t-8 20.5l-58 58q-9 9-20 13t-22 4q-11 0-22-4t-20-13L48-552q-9-9-14-21t-5-24q0-14 5.31-26.467Q39.62-635.933 51-645q92-74 200.5-114.5T480-800Zm0 329Z"
		/>
	</svg>
);
