import { Fragment, useContext, useMemo, useRef } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import { eventTypeLabels } from 'pkg/models/event';

import DateTime from 'pkg/datetime';
import { getWeekDates, isSameDay, isToday } from 'pkg/date';
import useLongTouchPress from 'pkg/hooks/useLongTouchPress';
import * as models from 'pkg/api/models';
import { useCurrentMembership } from 'pkg/identity';
import { cssClasses, cssVarList } from 'pkg/css/utils';
import rgba from 'pkg/rgba';

import useCreateEventDrag, {
	CreateEventDragContext,
	getPositionFromDate,
} from 'routes/group/calendar/hooks/useCreateEventDrag';
import { ViewProps, useCalendarContext } from 'routes/group/calendar';
import { hourHeight, getHours } from 'routes/group/calendar/config';
import EventWrapper from 'routes/group/calendar/components/events/single';
import Day from 'routes/group/calendar/components/day-item';
import { getEventsForDate, groupByDate } from 'routes/group/calendar/utils';

import { SmallScreen } from 'components/MediaQuery';

import EventTypeContextMenuItem from 'components/event/TypeContextMenuItem';
import Row from 'components/layout/row';

import * as ContextMenu from 'design/context_menu';

import * as css from './styles.css';

interface WeekProps {
	day: Date;
	currentDate: number;

	changeView: (timestamp: number, viw: string) => void;

	events: models.event.Event[];
}

function Week({ day, currentDate, changeView, events }: WeekProps) {
	const dateObj = new DateTime(day);

	const isSelected = (date: Date) => {
		const current = new Date(currentDate);

		return (
			current.getDate() == date.getDate() &&
			current.getMonth() == date.getMonth() &&
			current.getFullYear() == date.getFullYear()
		);
	};

	const isCurrentMonth = (date: Date) => {
		const current = new Date(currentDate);

		return (
			current.getMonth() == date.getMonth() &&
			current.getFullYear() == date.getFullYear()
		);
	};

	const selected = isSelected(day);
	const today = isToday(dateObj);
	const month = isCurrentMonth(day);

	const sendCurrentDay = () => changeView(dateObj.startOfDay, 'day');

	return (
		<div
			className={cssClasses(
				css.weekItem,
				today ? css.isToday : '',
				selected ? css.isCurrent : '',
				month ? css.isCurrentMonth : ''
			)}
			onClick={sendCurrentDay}>
			{today !== false && <span className={css.today}>{t('Today')}</span>}
			<span className={css.weekItemName}>
				{dateObj.toLocaleDateString({
					weekday: 'short',
				})}
			</span>
			<span className={css.weekItemNumber}>
				{dateObj.toLocaleDateString({
					day: 'numeric',
				})}
			</span>
			<Row spacing={styles.spacing._1} className={css.dotWrapper}>
				{events.slice(0, 5).map((e) => (
					<div
						className={cssClasses(css.weekEventDot)}
						style={cssVarList({
							color: rgba(styles.palette.events[e.type].foregroundColor),
						})}
					/>
				))}
			</Row>
		</div>
	);
}

export default function DayView({
	currentDate,
	changeView,
	scrollItemRef,
	currentAccount,
}: ViewProps) {
	const calendarCtx = useCalendarContext();

	const gridRef = useRef<HTMLDivElement>(null);
	const currentDateObj = new Date(currentDate);
	const dateObj = new DateTime(currentDateObj);
	const now = new DateTime(new Date());
	const today = isToday(dateObj);
	const membership = useCurrentMembership();

	const createEventDragContext = useContext(CreateEventDragContext);

	const createEventDrag = useCreateEventDrag(gridRef, scrollItemRef);

	const groupedEvents = groupByDate(
		calendarCtx.eventsCollection.records,
		false
	);
	const currentDayEvents = getEventsForDate(groupedEvents, dateObj);

	const weekDates = useMemo(() => getWeekDates(currentDate), [currentDate]);

	const handleMouseUp = (e: any) =>
		createEventDrag.handleMouseUp(e, currentDateObj);

	const handleMove = (e: any) => createEventDrag.handleMove(e, currentDateObj);

	const handleMouseDown = (e: any) =>
		createEventDrag.handleMouseDown(e, currentDateObj);

	const handleLongPress = (e: any) =>
		createEventDrag.handleLongPress(e, currentDateObj);

	const handleTouchMove = (e: any) =>
		createEventDrag.handleTouchMove(e, currentDateObj);

	const handleTouchEnd = () => createEventDrag.handleTouchEnd();

	const startDateTime = new DateTime(
		new Date(
			createEventDrag.eventData.start < createEventDrag.eventData.end
				? createEventDrag.eventData.start * 1000
				: createEventDrag.eventData.end * 1000
		)
	);
	const endDateTime = new DateTime(
		new Date(
			createEventDrag.eventData.end > createEventDrag.eventData.start
				? createEventDrag.eventData.end * 1000
				: createEventDrag.eventData.start * 1000
		)
	);

	const startTimeStamp = startDateTime.getUnixTimestamp();
	const endTimeStamp = endDateTime.getUnixTimestamp();

	const nowPosition = getPositionFromDate(now);
	const startPosition = getPositionFromDate(startDateTime);
	const endPosition = getPositionFromDate(endDateTime);

	let longPressEvents = useLongTouchPress({ onLongPress: handleLongPress });
	const isAdminOrStaff = models.membership.isAdminOrStaff(membership);

	if (!isAdminOrStaff) {
		longPressEvents = {};
	}

	const createDragEvents =
		isAdminOrStaff && !createEventDragContext.disabled
			? {
					onMouseDown: handleMouseDown,
					onMouseUp: handleMouseUp,
					onMouseMove: handleMove,
					onTouchMove: handleTouchMove,
					onTouchEndCapture: handleTouchEnd,
				}
			: {};

	const showContextMenuAtStart =
		createEventDrag.eventData.end < createEventDrag.eventData.start;

	return (
		<Fragment>
			<div>
				<SmallScreen>
					<div className={css.weekMenu}>
						{weekDates.map((day, index) => {
							const dateObjDay = new DateTime(new Date(day));
							const events = calendarCtx.eventsCollection.records.filter((e) =>
								isSameDay(new Date(e.startsAt * 1000), dateObjDay)
							);

							return (
								<Week
									day={day}
									key={index}
									currentDate={currentDate}
									events={events}
									changeView={changeView}
								/>
							);
						})}
					</div>
				</SmallScreen>

				<div className={css.gridContainer}>
					<div className={css.hoursGrid}>
						{getHours(currentAccount.hourFormat).map((h, index) => (
							<div
								style={{ height: hourHeight }}
								className={cssClasses(
									css.hourRow,
									index === 0 ? css.first : ''
								)}
								key={index}>
								<span className={css.hour}>{h}</span>
							</div>
						))}
					</div>
					<div
						className={css.dayGrid}
						ref={gridRef}
						{...createDragEvents}
						{...longPressEvents}>
						{createEventDrag.eventData.start > 0 &&
							createEventDrag.mousePosition.start !==
								createEventDrag.mousePosition.current && (
								<Fragment>
									<div
										className={css.newEvent}
										style={{
											top: startPosition,
											height: endPosition - startPosition,
										}}>
										<span>
											{startDateTime.toLocaleTimeString({
												hour: 'numeric',
												minute: 'numeric',
											})}{' '}
											-{' '}
											{endDateTime.toLocaleTimeString({
												hour: 'numeric',
												minute: 'numeric',
											})}
										</span>
										{createEventDrag.eventData.showContextMenu &&
											!createEventDrag.mousePosition.onTouchDevice && (
												<ContextMenu.Menu
													defaultOpen={true}
													toggleWith={<span></span>}
													togglePosition={{
														top: !showContextMenuAtStart && '100%',
														left: `${createEventDrag.mousePosition.x}px`,
													}}
													onClose={createEventDrag.closeModal}>
													{eventTypeLabels.map((eventType) => (
														<EventTypeContextMenuItem
															eventType={eventType}
															startsAt={startTimeStamp.toString()}
															endsAt={endTimeStamp.toString()}
															key={eventType}
														/>
													))}
												</ContextMenu.Menu>
											)}
									</div>
								</Fragment>
							)}
						{today && (
							<span style={{ top: nowPosition }} className={css.currentTime} />
						)}
						<div className={cssClasses(css.eventsContainer)}>
							<Day calculateHeight calculateOverlap dateTime={dateObj}>
								{currentDayEvents.map((e) => (
									<EventWrapper key={e.id} event={e} />
								))}
							</Day>
						</div>
						{getHours(currentAccount.hourFormat).map((h, index) => (
							<div className={css.row} key={index}></div>
						))}
					</div>
				</div>
			</div>

			{createEventDrag.eventData.showContextMenu &&
				createEventDrag.mousePosition.onTouchDevice && (
					<ContextMenu.Menu
						defaultOpen={true}
						toggleWith={<span></span>}
						onClose={createEventDrag.closeModal}>
						{eventTypeLabels.map((eventType) => (
							<EventTypeContextMenuItem
								eventType={eventType}
								startsAt={startTimeStamp.toString()}
								endsAt={endTimeStamp.toString()}
								key={eventType}
							/>
						))}
					</ContextMenu.Menu>
				)}
		</Fragment>
	);
}
