import { ReactNode } from 'react';

export const symbol_pattern: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M226-160q-27.225 0-46.613-19.341Q160-198.681 160-225.841 160-253 179.387-272.5 198.775-292 226-292q5 0 9.5.5t9.5 2.5l172-172q-6-17-1.305-34.901 4.695-17.902 17.5-31Q446-540 464-544.5t35 1.5l171-171q-1-5-1.5-10t-.5-10q0-27.225 19.341-46.612Q706.681-800 733.841-800 761-800 780.5-780.659q19.5 19.34 19.5 46.5Q800-707 780.612-687.5 761.225-668 734-668q-7 0-21-2L543-500q5 18 1 36t-17.065 31.065Q513-419 495-415.5q-18 3.5-37-1.5L297-256h124q8-17 23.949-26.5 15.95-9.5 34.557-9.5Q499-292 515-282.5t24 26.5h136q9.271-17.1 24.864-26.55T734-292q27.225 0 46.612 19.341Q800-253.319 800-226.159 800-199 780.612-179.5 761.225-160 734-160q-19.017 0-35.008-9.5Q683-179 675-196H539q-8 17-23.992 26.5Q499.017-160 480-160q-18.789 0-34.895-9.5Q429-179 421-196H285q-8 17-24.105 26.5Q244.789-160 226-160Zm.159-254Q199-414 179.5-433.341q-19.5-19.34-19.5-46.5Q160-507 179.341-526.5q19.34-19.5 46.5-19.5Q253-546 272.5-526.659q19.5 19.34 19.5 46.5Q292-453 272.659-433.5q-19.34 19.5-46.5 19.5Zm0-254Q199-668 179.5-687.341q-19.5-19.34-19.5-46.5Q160-761 179.341-780.5q19.34-19.5 46.5-19.5Q253-800 272.5-780.659q19.5 19.34 19.5 46.5Q292-707 272.659-687.5q-19.34 19.5-46.5 19.5Zm254 0Q453-668 433.5-687.341q-19.5-19.34-19.5-46.5Q414-761 433.341-780.5q19.34-19.5 46.5-19.5Q507-800 526.5-780.659q19.5 19.34 19.5 46.5Q546-707 526.659-687.5q-19.34 19.5-46.5 19.5Zm254 254Q707-414 687.5-433.341q-19.5-19.34-19.5-46.5Q668-507 687.341-526.5q19.34-19.5 46.5-19.5Q761-546 780.5-526.659q19.5 19.34 19.5 46.5Q800-453 780.659-433.5q-19.34 19.5-46.5 19.5Z"
		/>
	</svg>
);
