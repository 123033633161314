import { ReactNode } from 'react';

export const symbol_upcoming: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-120q-24 0-42-18t-18-42v-215q0-24 18-42t42-18h185q11.351 0 19.676 7Q353-441 355-430q7 45 42 73.5t83 28.5q48 0 83-28.5t42-73.5q2-11 10.324-18 8.325-7 19.676-7h185q24 0 42 18t18 42v215q0 24-18 42t-42 18H140Zm0-60h680v-215H656q-18 57-65.5 92T480-268q-63 0-112.5-35T304-395H140v215Zm552-377q-9-9-9-21.5t9-21.5l92-92q9-9 21-8.5t21 9.5q9 9 9 21.5t-9 21.5l-92 92q-9 9-21 8.5t-21-9.5Zm-423 0q-9 9-21.5 9t-21.5-9l-93-92q-9-9-8.5-21t9.5-21q9-9 21.5-9t21.5 9l92 92q9 9 9 21t-9 21Zm210.825-96Q467-653 458.5-661.625T450-683v-127q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-810v127q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM140-180h680-680Z"
		/>
	</svg>
);
