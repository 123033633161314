import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import * as palette from 'pkg/config/palette';

import Icon from 'components/icon';

import * as iconStyles from 'components/icon/styles.css';

const Status = styled.div`
	width: 1.8rem;
	height: 1.8rem;

	display: flex;
	align-items: center;
	justify-content: center;

	color: ${(props) => props.color};
	box-shadow: inset 0 0 0 3px ${palette.gray[300]};
	transition: all 250ms ease-in-out;
	cursor: pointer;

	${({ square }) =>
		square
			? css`
					border-radius: var(--radius-3);
				`
			: css`
					border-radius: 100rem;
				`}

	.${iconStyles.icon} {
		width: 100%;
		height: 100%;
		color: ${(props) => props.color};
		opacity: 0;
		transition: opacity 250ms ease-in-out;
	}

	${(props) =>
		props.selected &&
		css`
			box-shadow: inset 0 0 0 3px ${(props) => props.color};

			.${iconStyles.icon} {
				opacity: 1;
			}
		`};
`;

const SelectedStatus = ({
	isSelected,
	color = palette.blue[400],
	onClick,
	square,
}) => (
	<Status onClick={onClick} color={color} selected={isSelected} square={square}>
		{square ? <Icon name="check" /> : <Icon name="check-filled" />}
	</Status>
);

const NOOP = () => {};

SelectedStatus.propTypes = {
	isSelected: PropTypes.bool,
	color: PropTypes.string,
	onClick: PropTypes.func,
	square: PropTypes.bool,
};

SelectedStatus.defaultProps = {
	isSelected: false,
	color: palette.blue[500],
	onOpen: NOOP,
	square: false,
};

export default SelectedStatus;
