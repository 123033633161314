import { t } from '@transifex/native';
import { ReactNode, Fragment } from 'react';

import spacing from 'pkg/config/spacing';

import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useEndpoint } from 'pkg/api/use_endpoint';
import { useCurrentGroupId } from 'pkg/identity';
import { findCountryByCode } from 'pkg/countries';
import Link from 'pkg/router/Link';

import Column from 'components/layout/column';

import * as Card from 'design/card';

interface ContactInformationProps {
	heading?: string;
	align?: 'start' | 'center' | 'end';
	skipCard?: boolean;
	skipHeader?: boolean;
	groupId?: number;
}

export default function ClubContactInformation({
	heading = t('Contact Information'),
	align = 'start',
	skipCard,
	skipHeader,
	groupId,
}: ContactInformationProps): JSX.Element {
	const currentGroupId = useCurrentGroupId();

	if (!groupId) {
		groupId = currentGroupId;
	}

	const { isLoading, record: contactInfoItems } =
		useEndpoint<models.groupContactInformation.GroupContactInformation>(
			endpoints.GroupInfo.ShowByGroupID(groupId),
			{
				defaultResponse: [],
			}
		);

	if (isLoading || contactInfoItems.length === 0) {
		return null;
	}

	const renderAddressRow = (
		fieldName: models.groupContactInformation.ContactFieldName
	): ReactNode => {
		const item = contactInfoItems.find(
			(item: models.groupContactInformation.ContactItem) =>
				item.fieldName === fieldName
		);

		if (!item || item.fieldValue.length === 0) return null;

		switch (fieldName) {
			case 'clubLegalName':
				return <strong>{item.fieldValue}</strong>;
			case 'addressCountry':
				return (
					<span>{findCountryByCode(item.fieldValue)?.country || null}</span>
				);
			case 'contactPhone': {
				return <Link href={`tel:${item.fieldValue}`}>{item.fieldValue}</Link>;
			}
			case 'contactEmail': {
				return (
					<Link href={`mailto:${item.fieldValue}`}>{item.fieldValue}</Link>
				);
			}
			case 'website':
				return <Link href={item.fieldValue}>{item.fieldValue}</Link>;
			default:
				return <span>{item.fieldValue}</span>;
		}
	};

	const information = (
		<Column justify={align}>
			{skipHeader && !skipCard && <span>{heading}</span>}
			{renderAddressRow('clubLegalName')}
			{renderAddressRow('taxID')}
			<Column spacing={spacing._1} justify={align}>
				{renderAddressRow('contactPhone')}
				{renderAddressRow('contactEmail')}
				{renderAddressRow('website')}
			</Column>
			<Column spacing={spacing._1} justify={align}>
				{renderAddressRow('addressStreetAddress')}
				<span>
					{renderAddressRow('addressPostalCode')}
					{renderAddressRow('addressRegion')}
				</span>
				{renderAddressRow('addressCountry')}
			</Column>
		</Column>
	);

	if (skipCard) {
		return information;
	}

	return (
		<Card.Base>
			{!skipHeader && (
				<Fragment>
					<Card.Header>
						<Card.Heading>{heading}</Card.Heading>
					</Card.Header>
					<Card.Divider />
				</Fragment>
			)}
			<Card.Body>{information}</Card.Body>
		</Card.Base>
	);
}
