import { ReactNode } from 'react';

export const symbol_ring_volume: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M479.825-692Q467-692 458.5-700.625T450-722v-128q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-850v128q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Zm207.784 90q-8.609-9-8.109-22t9.5-22l89-87q9-9 22-9t21 10q8 9 7.5 21t-8.5 20l-88 89q-9 9-22 9t-22.391-9ZM274-603q-8 10-20.5 10t-21.5-9l-90-88q-9-9-9.5-21.5t8.109-21.5Q150-742 163-742q13 0 22 9l89 90q8 8 8 19.5t-8 20.5ZM136-146l-94-94q-9-9-8.5-21t8.5-22q82-96 197-146.5T480-480q126 0 241 50.5T918-283q8 10 8.5 22t-8.5 21l-94 94q-8 8-23 9t-24-6l-114-85q-6-4.5-9-10.5t-3-13.5v-139q-42-16-85.5-22.5T480-420q-42 0-85.5 6.5T309-391v139q0 7.5-3 13.5t-9 10.5l-114.188 85.278Q171-134 158.312-134.923 145.625-135.846 136-146Zm108-224q-38 19-73 45.5T104-268l57 59 83-61v-100Zm467-4v98l87 66 58-58q-32-33-68.5-58.5T711-374Zm-467 4Zm467-4Z"
		/>
	</svg>
);
