import { t } from '@transifex/native';
import { Fragment } from 'react';
import { useMediaQuery } from 'react-responsive';

import { FontWeights } from 'pkg/config/fonts';
import * as styles from 'pkg/config/styles';

import MaterialSymbol from 'components/material-symbols';

import UnreadChatList from 'components/notifications/unread-chats/list';

import * as Context from 'design/context_menu';

import * as css from './styles.css';

interface UnreadChatsProps {
	amount: number;
	showDivider?: boolean;
	showUnreadChats?: () => void;
}

export default function UnreadChats({
	amount,
	showDivider = true,
	showUnreadChats,
}: UnreadChatsProps) {
	const isSmallScreen = useMediaQuery({
		maxWidth: styles.breakpoint.toMedium,
	});

	const content = (
		<Fragment>
			<Context.Item
				skipFirstItemTopSpacing
				fontWeight={FontWeights.SemiBold}
				closeOnClick={false}
				onClick={isSmallScreen ? undefined : showUnreadChats}>
				<div className={css.iconWrapper}>
					<MaterialSymbol variant="chat" scale={1.4} />
				</div>
				{t('{amount} unread chats in other clubs', { amount })}
			</Context.Item>
			{showDivider && <Context.Divider tight />}
		</Fragment>
	);

	if (isSmallScreen) {
		return (
			<Context.Menu toggleWith={content}>
				<UnreadChatList />
			</Context.Menu>
		);
	}

	return content;
}
