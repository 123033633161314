import { t } from '@transifex/native';
import { useDispatch } from 'react-redux';

import { updateMatchEventUser } from 'pkg/actions/match_events_deprecated';

import PosSelection from 'containers/match/edit/event/modals/PosSelection';

import StepModal, { Step } from 'components/step-modal';

import SelectableList from 'components/user/SelectableList';

const PosSwapModal = ({ event, changes, users, onNext, onClose, onCancel }) => {
	const dispatch = useDispatch();

	const handleSelectPlayer = (userId) =>
		dispatch(updateMatchEventUser(event.id, userId[0]));

	return (
		<StepModal onClose={onClose}>
			<Step
				title={t(`Change position`)}
				prevLabel={t('Cancel')}
				nextLabel={t('Next')}
				canGoNext={!!changes.get('userId') || !!event.userId}
				skipBody
				onPrev={onCancel}>
				<SelectableList
					users={users}
					selectedUserIds={[changes.get('userId') || event.userId]}
					onChange={handleSelectPlayer}
				/>
			</Step>

			<Step
				title={t(`Select position`)}
				prevLabel={t('Cancel')}
				nextLabel={t('Done')}
				canGoNext={!!changes.get('positionId') || !!event.positionId}
				skipBody
				onNext={onNext}
				onPrev={onCancel}>
				<PosSelection
					event={event}
					changes={changes}
					eventUser={users.find(
						(user) => user.id == (changes.get('userId') || event.userId)
					)}
				/>
			</Step>
		</StepModal>
	);
};

export default PosSwapModal;
