import { Fragment } from 'react';
import { t } from '@transifex/native';

import spacing from 'pkg/config/spacing';

import DateTime from 'pkg/datetime';
import * as models from 'pkg/api/models';
import { MatchEventType } from 'pkg/api/models/match_event';

import MatchEvent from 'routes/event/single/tabs/summary/single-match-event';
import * as matchEventStyling from 'routes/event/single/tabs/summary/single-match-event/styles.css';

import SectionTitle from 'components/SectionTitle';
import CardContainer, * as Card from 'components/Card';
import Icon from 'components/icon';

import Column from 'components/layout/column';
import Row from 'components/layout/row';

import * as css from './styles.css';

function groupMatchEvents(matchEvents: models.matchEvent.MatchEvent[]) {
	return matchEvents
		.map((event) => [event])
		.sort((a, b) => a[0].minute - b[0].minute);
}

interface MatchEventsProps {
	match: models.match.Match;
	matchEvents: models.matchEvent.MatchEvent[];
}

export default function MatchEventsList({
	match,
	matchEvents,
}: MatchEventsProps) {
	const groupedEvents = groupMatchEvents(matchEvents)?.filter(
		(me) =>
			me[0].type !== MatchEventType.MatchEventTypeStartingUser && me[0].minute
	);

	const content = () => {
		if (!match || !groupedEvents) return null;

		const halfTimeMark = match.duration / 2;

		const firstHalfTime: models.matchEvent.MatchEvent[][] = [];
		const secondHalfTime: models.matchEvent.MatchEvent[][] = [];
		const overtime: models.matchEvent.MatchEvent[][] = [];

		groupedEvents.forEach((group) => {
			const [event] = group;

			if (event.minute <= halfTimeMark) {
				firstHalfTime.push(group);
			} else if (event.minute <= match.duration) {
				secondHalfTime.push(group);
			} else {
				overtime.push(group);
			}
		});

		const startsAt = new DateTime()
			.setUnixTimestamp(match.startsAt)
			.toTimeString();

		const bodies = (
			<Fragment>
				<Card.Body key="match-events">
					<div className={css.label}>
						<Row autoColumns="max-content" spacing={spacing._3}>
							<Icon name="timer" />
							{t('Starts at {time}', {
								time: startsAt,
							})}
						</Row>
					</div>

					<div className={matchEventStyling.matchEvents}>
						{firstHalfTime.map(([event, subsequentEvent], n) => (
							<MatchEvent
								key={`firstHalfTime:${event.id}-${n}`}
								matchEvent={event}
								subsequentEvent={subsequentEvent}
							/>
						))}
						{firstHalfTime.length === 0 && (
							<p className={css.noEventsLabel}>
								{t('No match events registered')}
							</p>
						)}
					</div>

					{secondHalfTime.length !== 0 && (
						<Fragment>
							<div className={css.label}>
								<Row autoColumns="max-content" spacing={spacing._3}>
									<Icon name="timer" />
									{t('Half time {time}', {
										time: `${halfTimeMark}+${match.halfTimeAddition}`,
									})}
								</Row>
							</div>

							<div className={matchEventStyling.matchEvents}>
								{secondHalfTime.map(([event, subsequentEvent], n) => (
									<MatchEvent
										key={`secondHalfTime:${event.id}-${n}`}
										matchEvent={event}
										subsequentEvent={subsequentEvent}
									/>
								))}
								{secondHalfTime.length === 0 && (
									<p className={css.noEventsLabel}>
										{t('No match events registered')}
									</p>
								)}
							</div>
							<div className={css.label}>
								<Row autoColumns="max-content" spacing={spacing._3}>
									<Icon name="timer" />
									{t('Match end {time}', {
										time: `${match.duration}+${match.fullTimeAddition}`,
									})}
								</Row>
							</div>
						</Fragment>
					)}
				</Card.Body>
				{overtime.length !== 0 && (
					<Fragment>
						<Card.Divider />
						<Card.Body>
							<div className={css.label}>
								<Row autoColumns="max-content" spacing={spacing._3}>
									<Icon name="timer-add" />
									{t('Overtime')}
								</Row>
							</div>

							<div className={matchEventStyling.matchEvents}>
								{overtime.map(([event, subsequentEvent], n) => (
									<MatchEvent
										key={`overtime:${event.id}-${n}`}
										matchEvent={event}
										subsequentEvent={subsequentEvent}
									/>
								))}
							</div>
						</Card.Body>
					</Fragment>
				)}
			</Fragment>
		);

		return bodies;
	};

	return (
		<Column>
			<SectionTitle>{t('Match events')}</SectionTitle>
			<CardContainer>{content()}</CardContainer>
		</Column>
	);
}
