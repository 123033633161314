import { ReactNode } from 'react';

export const symbol_note_alt: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-120q-24.75 0-42.375-17.625T120-180v-600q0-24.75 17.625-42.375T180-840h205q5-35 32-57.5t63-22.5q36 0 63 22.5t32 57.5h205q24.75 0 42.375 17.625T840-780v600q0 24.75-17.625 42.375T780-120H180Zm0-60h600v-600H180v600Zm300-617q14 0 24.5-10.5T515-832q0-14-10.5-24.5T480-867q-14 0-24.5 10.5T445-832q0 14 10.5 24.5T480-797ZM180-180v-600 600Zm121-109h33.127q5.521 0 10.697-2Q350-293 355-298l246-246-59-61-247 247q-5 5-7 10.156-2 5.156-2 10.656v33.688q0 5.5 4.5 10T301-289Zm318-273 39-39q7-7 7.5-15.5T658-633l-29-28q-8-8-16-7.5t-15 7.5l-38 38 59 61Z"
		/>
	</svg>
);
