import { ReactNode } from 'react';

export const symbol_theater_comedy: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M773-676q13.6 0 22.8-9.2 9.2-9.2 9.2-22.8 0-13.6-9.2-22.8-9.2-9.2-22.8-9.2-13.6 0-22.8 9.2-9.2 9.2-9.2 22.8 0 13.6 9.2 22.8 9.2 9.2 22.8 9.2Zm-172 0q13.6 0 22.8-9.2 9.2-9.2 9.2-22.8 0-13.6-9.2-22.8-9.2-9.2-22.8-9.2-13.6 0-22.8 9.2-9.2 9.2-9.2 22.8 0 13.6 9.2 22.8 9.2 9.2 22.8 9.2Zm85 76q-29 0-55.5 9T588-559q-6 10 1 19.5t19.857 9.5h154.286Q776-530 783-539.5t1-19.5q-16-23-42.5-32t-55.5-9ZM274-81q-97.5 0-165.75-68.25T40-315v-207q0-24.75 17.625-42.375T100-582h348q24.75 0 42.375 17.625T508-522v207q0 97.5-68.25 165.75T274-81Zm.159-60Q346-141 397-192.113 448-243.225 448-315v-207H100v207q0 71.775 51.159 122.887 51.16 51.113 123 51.113ZM920-820v216q0 95.625-69.5 160.312Q781-379 687-379q-23 0-47-4.5T594-396q-12-5-17-16.087T577-435q5-11 16.087-16.5T616-452q17 6 35.5 9.5T689-439q69 0 120-47.5T860-604v-216H509v159q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T449-661v-159q0-24.75 17.625-42.375T509-880h351q24.75 0 42.375 17.625T920-820ZM189-386q13.6 0 22.8-9.2 9.2-9.2 9.2-22.8 0-13.6-9.2-22.8-9.2-9.2-22.8-9.2-13.6 0-22.8 9.2-9.2 9.2-9.2 22.8 0 13.6 9.2 22.8 9.2 9.2 22.8 9.2Zm173 0q13.6 0 22.8-9.2 9.2-9.2 9.2-22.8 0-13.6-9.2-22.8-9.2-9.2-22.8-9.2-13.6 0-22.8 9.2-9.2 9.2-9.2 22.8 0 13.6 9.2 22.8 9.2 9.2 22.8 9.2Zm-85 155q29.436 0 55.718-9 26.282-9 42.493-31.629Q381-282 373.957-291.5 366.913-301 354-301H200q-12.913 0-19.957 9.5-7.043 9.5-1.254 19.871Q195-249 221.282-240q26.282 9 55.718 9Zm-3-101Zm406-298Z"
		/>
	</svg>
);
