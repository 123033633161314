import { ReactNode } from 'react';

export const symbol_60fps_select: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M228-400q-24 0-42-18t-18-42v-280q0-24 18-42t42-18h173q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T401-740H228v110h143q24 0 42 18t18 42v110q0 24-18 42t-42 18H228Zm0-60h143v-110H228v110Zm363 60q-24 0-42-18t-18-42v-280q0-24 18-42t42-18h143q24 0 42 18t18 42v280q0 24-18 42t-42 18H591Zm0-60h143v-280H591v280ZM149.825-80Q137-80 128.5-88.625T120-110v-128q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T180-238v128q0 12.75-8.675 21.375Q162.649-80 149.825-80Zm120 0Q257-80 248.5-88.625T240-110v-128q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T300-238v128q0 12.75-8.675 21.375Q282.649-80 269.825-80Zm120 0Q377-80 368.5-88.625T360-110v-128q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T420-238v128q0 12.75-8.675 21.375Q402.649-80 389.825-80ZM510-80q-12.75 0-21.375-8.625T480-110v-128q0-12.75 8.625-21.375T510-268h300q12.75 0 21.375 8.625T840-238v128q0 12.75-8.625 21.375T810-80H510Z"
		/>
	</svg>
);
