import { ReactNode } from 'react';

export const symbol_globe_uk: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M479.933-141Q499-141 517-143t35-6.263L501-226H353v-42.333q0-34.925 24.822-59.796Q402.644-353 437.5-353H522v-127h-84q-17 0-30-13t-13-30v-85h-18.818Q349-608 329.5-626.5 310-645 310-672.486q0-9.514 3-19.014t8-17.5l66-97q-107 30-176.5 119.439T141-480h42v-43q0-17 12.5-29.5T225-565h85q17 0 30 12.5t13 29.5v43q0 17-13 29.5T310-438v42.74q0 35.26-24.906 59.76t-59.88 24.5H186q45 77 122.328 123.5T479.933-141ZM802-376q8-25 12.5-50.941 4.5-25.941 4.5-53.471 0-118.588-71.967-209.27Q675.067-780.364 565-809v116.518q35 0 59.924 24.893 24.925 24.893 24.925 59.848V-523q20.151 0 36.651 5.5Q703-512 717-498l85 122ZM480.266-80q-82.734 0-155.5-31.5t-127.266-86q-54.5-54.5-86-127.341Q80-397.681 80-480.5q0-82.819 31.5-155.659Q143-709 197.5-763t127.341-85.5Q397.681-880 480.5-880q82.819 0 155.659 31.5Q709-817 763-763t85.5 127Q880-563 880-480.266q0 82.734-31.5 155.5T763-197.684q-54 54.316-127 86Q563-80 480.266-80Z"
		/>
	</svg>
);
