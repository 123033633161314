import { t } from '@transifex/native';

import { triggerError } from 'pkg/actions/app';
import { show } from 'pkg/actions/flashes';

import { replaceState } from 'pkg/router/state';
import * as sdk from 'pkg/core/sdk';
import store from 'pkg/store/createStore';

export const SET_EXISTING_FORMATION = 'SET_EXISTING_FORMATION';
export const SET_NEW_FORMATION = 'SET_NEW_FORMATION';
export const SET_CURRENT_FORMATION = 'SET_CURRENT_FORMATION';
export const SET_SAVING_STATUS = 'SET_SAVING_STATUS';

const setExistingFormation = (formation) => ({
	type: SET_EXISTING_FORMATION,
	formation,
});

const setNewFormation = (formation) => ({
	type: SET_NEW_FORMATION,
	formation,
});

const setCurrentFormation = (currentFormation) => ({
	type: SET_CURRENT_FORMATION,
	currentFormation,
});

const setSavingStatus = (saving) => ({
	type: SET_SAVING_STATUS,
	saving,
});

const defaultFormation = {
	id: 'new',
	name: '',
	formationType: 'squad',
	maturityAdjusted: false,
	showScores: true,
	positions: [],
};

export const setFormation = (id, groupId, search) => async (dispatch) => {
	if (id === 'new') {
		let positions = [];

		if (search?.type === 'squad' && search?.baseId) {
			const req = await sdk.get(`/group-formations/${search.baseId}`);
			const body = await req.json();

			if (req.ok) {
				positions = body.positions;
			}
		}

		dispatch(
			setNewFormation({
				...defaultFormation,
				formationType: search?.type === 'formation' ? 'formation' : 'squad',
				groupId,
				positions,
			})
		);

		dispatch(setCurrentFormation('new'));

		return;
	}

	const request = await sdk.get(`/group-formations/${id}`);
	const body = await request.json();

	if (!request.ok) {
		return dispatch(triggerError(request, body));
	}

	dispatch(setExistingFormation(body));
	dispatch(setCurrentFormation(body.id));
	return;
};

// Tries to save the given formation.
// If a new formation the formation will recieve an ID and the current formation will be updated to the newly created formation.
export const persistFormation = (formation) => async (dispatch) => {
	let request;

	dispatch(setSavingStatus(true));

	formation.id = store.getState().formations.currentFormation;

	if (formation.id !== 'new') {
		request = await sdk.patch(
			`/group-formations/${formation.id}`,
			{},
			formation
		);
	} else {
		request = await sdk.post('/group-formations', {}, formation);
	}

	const body = await request.json();
	dispatch(setSavingStatus(false));

	// @todo This should be an inline error and not a page error
	if (!request.ok) {
		return dispatch(triggerError(request, body));
	}

	dispatch(setExistingFormation(body));

	if (formation.id === 'new') {
		replaceState('group.formations.show', {
			formationId: body.id,
		});
	}

	dispatch(setCurrentFormation(body.id));

	show(
		{
			title: t(`Formation saved`),
		},
		200
	);
};

export const deleteFormation = () => async () => {
	const formationId = store.getState().formations.currentFormation;

	if (formationId === 'new') {
		replaceState('/');
		return;
	}

	if (
		!confirm(t('Do you want to delete this?', { _context: 'confirm messages' }))
	) {
		return;
	}

	const req = await sdk.destroy('/group-formations/:id', { id: formationId });

	if (req.ok) {
		replaceState('/');
	}
};
