import { ReactNode } from 'react';

export const symbol_speed_1_5x: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M123-623H70q-12.75 0-21.375-8.675Q40-640.351 40-653.175 40-666 48.625-674.5T70-683h83q12.75 0 21.375 8.625T183-653v346q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T123-307v-316Zm666 189-77 139q-4.286 7.059-10.714 11.029Q694.857-280 687.143-280 670-280 662-294t0-28l91-164-85-152q-8-14-.5-28t24.643-14q7.714 0 14.143 3.971Q712.714-672.059 717-665l72 128 71-128q4.286-7.059 10.714-11.029Q877.143-680 884.857-680 902-680 910-666t0 28l-85 152 92 165q8 14 0 27.5T891.759-280Q884-280 878-283.706T868-294l-79-140ZM517-277H397q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T397-337h120v-115H397q-12.75 0-21.375-8.625T367-482v-171q0-12.75 8.625-21.375T397-683h150q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T547-623H427v111h90q24 0 42 18t18 42v115q0 24-18 42t-42 18Zm-242.175 0Q262-277 253.5-285.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Z"
		/>
	</svg>
);
