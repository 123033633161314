import styled from 'styled-components';

import * as breakpoints from 'pkg/config/breakpoints';
import * as palette from 'pkg/config/palette';

import CardWrapper, * as Card from 'components/Card';
import Placeholder from 'components/Placeholder';

import { ParagraphRow } from 'components/loaders/skeleton/placeholders/ParagraphRow';

const ListItem = styled(CardWrapper)`
	position: relative;
	margin-bottom: 10px;
`;

const Item = styled(Card.Body)`
	grid-template-columns: 40px 1fr;
	display: grid;
	grid-column-gap: 13px;
	justify-items: start;
	align-items: center;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-right: 10px;

	@media ${breakpoints.small} {
		border-radius: 0;
		grid-row-gap: 15px;
		padding: 16px;
	}
`;

const ItemAvatar = styled.div`
	width: 36px;
	height: 36px;
	border-radius: 50%;
	background-color: ${palette.gray[300]};
	z-index: 1;
`;

const Paragraphs = styled.div``;

export default class ListPlaceholder extends Placeholder {
	getPlaceholderItem(key) {
		return (
			<ListItem key={key}>
				<Item>
					<ItemAvatar />
					<Paragraphs>
						<ParagraphRow height={8} width={180} />
						<ParagraphRow height={8} width={80} />
					</Paragraphs>
				</Item>
			</ListItem>
		);
	}
}
