import { ReactNode } from 'react';

export const symbol_vaping_rooms: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M315-200v-100h515q20.833 0 35.417 14.618Q880-270.765 880-249.882 880-229 865.417-214.5 850.833-200 830-200H315Zm539.884-145Q844-345 837-352.083q-7-7.084-7-17.917v-75q0-75-50-123t-125-48q-10.833 0-17.917-7.116-7.083-7.117-7.083-18Q630-652 637.083-659q7.084-7 17.917-7 34 0 55.5-24t21.5-58q0-34-21.5-58T655-830q-10.833 0-17.917-7.116-7.083-7.117-7.083-18Q630-866 637.083-873q7.084-7 17.917-7 54 0 90.5 39t36.5 93q0 33-12.5 57T737-650q58 20 100.5 75T880-445v75q0 10.833-7.116 17.917-7.117 7.083-18 7.083Zm-95 0Q749-345 742-352.083q-7-7.084-7-17.917v-31q0-42-24.5-66T651-491h-71q-54 0-90.5-41.5T453-628q0-54 36.5-90.5T580-755q10.833 0 17.917 7.116 7.083 7.117 7.083 18Q605-719 597.917-712q-7.084 7-17.917 7-35 0-56 21t-21 56q0 35 21 61t55.565 26H651q54.377 0 94.188 37Q785-467 785-413v43q0 10.833-7.116 17.917-7.117 7.083-18 7.083ZM285-203q-37-8-74.822-12.5Q172.355-220 135-220h-25q-12.75 0-21.375-8.625T80-250q0-13 8.5-21.5T110-280h25q37.355 0 75.178-4.5Q248-289 285-297v94Zm135-27q8 0 14-6t6-14q0-8-6-14t-14-6q-8 0-14 6t-6 14q0 8 6 14t14 6Z"
		/>
	</svg>
);
