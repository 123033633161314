import { ReactNode } from 'react';

export const symbol_lock_open_right: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M220-140h520v-434H220v434Zm260.168-140Q512-280 534.5-302.031T557-355q0-30-22.668-54.5t-54.5-24.5Q448-434 425.5-409.5t-22.5 55q0 30.5 22.668 52.5t54.5 22ZM220-140v-434 434Zm0 60q-24.75 0-42.375-17.625T160-140v-434q0-24.75 17.625-42.375T220-634h330v-96q0-78.85 55.575-134.425Q661.15-920 740-920q71 0 123 44.5T927-765q2 12-6.625 23.5T899.75-730q-12.75 0-21.25-6T868-755q-9-45-44.7-75T740-860q-54.167 0-92.083 37.917Q610-784.167 610-730v96h130q24.75 0 42.375 17.625T800-574v434q0 24.75-17.625 42.375T740-80H220Z"
		/>
	</svg>
);
