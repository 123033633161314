import { ReactNode } from 'react';

export const symbol_swipe_left: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M127-740h113q8 0 14 6t6 14q0 8-6 14t-14 6H110q-12.75 0-21.375-8.625T80-730v-130q0-8 6-14t14-6q8 0 14 6t6 14v73q81-64 174-98.5T490-920q125.48 0 224.74 51T867-724q5 8 2 16t-11.444 8q-10.556 0-20.026-5.517Q828.061-711.034 822-720q-52-78-137.5-119T490-880q-99.968 0-191.984 35.5T127-740ZM470-80q-21.882 0-41.941-8T392-112L202-302q-8-8-9-19t5-20l1-1q11-16 28.5-22.5T264-366l96 26v-340q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T420-680v381q0 15-12 24t-26 5l-86-23 139.178 139.178Q442-147 451.13-143.5q9.131 3.5 18.87 3.5h170q42 0 71-29t29-71v-180q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T800-420v180q0 66-47 113T640-80H470Zm47.175-490q12.825 0 21.325 8.625T547-540v140q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T487-400v-140q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625Zm126 40q12.825 0 21.325 8.625T673-500v100q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T613-400v-100q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625ZM578-265Z"
		/>
	</svg>
);
