import { ReactNode } from 'react';

export const symbol_cardiology: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M290-840q57 0 105.5 27t84.5 78q42-54 89-79.5T670-840q89 0 149.5 60.5T880-629q0 15-1.5 29.5T873-570h-62q5-15 7-29.5t2-29.5q0-66-42-108.5T670-780q-51 0-95 31.5T504-660h-49q-26-56-70-88t-95-32q-66 0-108 42.5T140-629q0 15 2 29.5t7 29.5H87q-4-15-5.5-29.5T80-629q0-90 60.5-150.5T290-840Zm190 712q-11 0-21.5-3.5T440-143q-42-39-78.5-73t-67-64.5q-30.5-30.5-56-58T192-390h78q38 42 89.5 91.5T480-186q69-63 120-112.5t89-91.5h79q-21 24-46.5 51.5t-56 58Q635-250 598.5-216T520-143q-8 8-18.5 11.5T480-128Zm-38-202q9 0 16-5.5t10-14.5l61-181 46 68q5 6 11 9.5t14 3.5h280q13 0 21.5-8.5T910-480q0-13-8.5-21.5T880-510H617l-72-106q-5-7-12-10t-15-3q-9 0-16.5 5.5T491-609l-60 181-47-69q-5-6-10.5-9.5T360-510H80q-13 0-21.5 8.5T50-480q0 13 8.5 21.5T80-450h262l72 107q5 7 12.5 10t15.5 3Zm38-153Z"
		/>
	</svg>
);
