import { ReactNode } from 'react';

export const symbol_checked_bag: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M110-120q-12.75 0-21.375-8.675Q80-137.351 80-150.175 80-163 88.625-171.5T110-180h740q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-120H110Zm108-120q-24 0-42-18t-18-42v-360q0-24 18-42t42-18h139q0-54 36-87t86-33q50 0 86 33t36 87h139q24 0 42 18t18 42v360q0 24-18 42t-42 18H218Zm466-60h56v-360h-56v360ZM401-720h156q0-34-23.5-54.5T479-795q-31 0-54.5 20.5T401-720ZM274-300v-360h-56v360h56Zm45-360v360h320v-360H319Zm-45 360h45-45Zm410 0h-45 45Zm-410 0h-56 56Zm45 0h320-320Zm365 0h56-56Z"
		/>
	</svg>
);
