import { ReactNode } from 'react';

export const symbol_travel: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m272-271-98-55q-8-4-9-12.5t5-14.5l8-8q3-3 6.5-4.5t7.5-.5l91 13 177-177-291-159q-11-6-12.5-18t7.5-21l7-8q5-5 11-6.5t13 .5l379 98 118-119q15-15 35.5-15t35.5 15q15 15 15 35.5T763-692L645-573l98 378q2 8 0 14.5t-7 11.5l-4 4q-10 10-23.5 7.5T688-172L531-459 354-282l12 89q1 5-.5 9.5T361-176l-4 4q-7 7-17 5.5T325-177l-53-94Z"
		/>
	</svg>
);
