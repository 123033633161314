import { t } from '@transifex/native';
import { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';

import * as styles from 'pkg/config/styles';

import User from 'pkg/models/user';

import * as actions from 'pkg/actions';
import * as selectors from 'pkg/selectors';
import { RootState } from 'pkg/reducers';

import StepModal, { Step } from 'components/step-modal';

import InfoBox from 'components/form/info-box';
import Column from 'components/layout/column';

import * as Table from 'design/table';

const Row: React.FC<React.PropsWithChildren<{ user: User }>> = ({ user }) => {
	const billingUser = useSelector((state: RootState) =>
		selectors.users.find(state, user.billingUserId)
	);

	return (
		<Fragment>
			<Table.Row>
				<Table.Cell>
					{user.firstName} {user.lastName}
				</Table.Cell>
				<Table.Cell>{billingUser.fullName}</Table.Cell>
			</Table.Row>
		</Fragment>
	);
};

interface BillingConfirmModalProps {
	userIds: number[];
	onClose: () => void;
}

const BillingConfirmModal: React.FC<
	React.PropsWithChildren<BillingConfirmModalProps>
> = ({ userIds, onClose }) => {
	const [users, setUsers] = useState<User[]>([]);

	const handleSetBillingContacts = async () => {
		const [resp, req] =
			await actions.users.setLegalGuardianAsBillingContact(userIds);

		if (req.ok) {
			setUsers(resp);
			return true;
		}

		return false;
	};

	return (
		<StepModal onClose={onClose}>
			<Step
				title={t(`Add parent as billing contact`)}
				nextLabel={t('Continue')}
				onNext={handleSetBillingContacts}>
				<InfoBox
					text={t(`You are about to set parents as the billing contacts for the selected contacts. This will overwrite any existing billing contact.

 Do you want to continue?`)}
					color="orange"
				/>
			</Step>
			<Step
				title={t(`Assign billing contacts`)}
				hidePrev
				nextLabel={t('Finish')}>
				<Column spacing={styles.spacing._8}>
					<InfoBox
						text={t(`The following contacts were assigned a billing contact:`)}
					/>
					<Table.Table
						columns={[
							{ content: t(`Contact`) },
							{ content: t(`Billing contact`) },
						]}>
						{users.map((user) => (
							<Row key={user.id} user={user} />
						))}
					</Table.Table>
				</Column>
			</Step>
		</StepModal>
	);
};

export default BillingConfirmModal;
