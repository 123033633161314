import { t } from '@transifex/native';
import { useContext, useMemo, useRef, useState } from 'react';

import * as models from 'pkg/api/models';
import useMixedState from 'pkg/hooks/useMixedState';
import {
	getGmtString,
	getTimeZoneByGmtString,
	getTimeZoneFromSessionStorage,
} from 'pkg/timezone';
import { BookingUpdatePayload } from 'pkg/api/models/booking';
import { useCurrentOrganization } from 'pkg/identity';
import DateTime from 'pkg/datetime';
import * as actions from 'pkg/actions';
import { EventFlags } from 'pkg/api/models/event';

import BookingForm from 'routes/scheduling/bookings/booking_form';
import { BookingsContext } from 'routes/scheduling/bookings';
import {
	findBookingConflicts,
	getItemResourceIds,
	resourceConflictMap,
} from 'routes/scheduling/utils';

import * as StepModal from 'components/step-modal';

import useSelectGroups from 'components/group/select_modal';
import useSelectResource, {
	ConflictItem,
	SelectableOptions,
} from 'components/resources/select_modal';
import Form, { FormPayload } from 'components/form/Form';

import Button from 'design/button';

interface EditBookingModalProps {
	booking: models.booking.Booking;

	hideModal: () => void;
}

const EditBookingModal = ({ booking, hideModal }: EditBookingModalProps) => {
	const organizationId = useCurrentOrganization().id;
	const [modal, setModal] = useState('');
	const timeZone = useMemo(
		() => getGmtString(getTimeZoneFromSessionStorage()),
		[]
	);

	const [formState, setFormState] = useMixedState({
		timeZone,
		start: booking.startsAtLocal
			? new DateTime(new Date(booking.startsAtLocal)).getUnixTimestamp()
			: booking.startsAt,
		end: booking.endsAtLocal
			? new DateTime(new Date(booking.endsAtLocal)).getUnixTimestamp()
			: booking.endsAt,
	});
	const ref = useRef(null);

	const handleNext = async () =>
		ref.current.dispatchEvent(
			new Event('submit', {
				cancelable: true,
				bubbles: true,
			})
		);

	const bookingsContext = useContext(BookingsContext);

	const hideSelectModal = () => setModal('');
	const showGroupSelectModal = () => setModal('selectGroups');
	const showSelectResourceModal = () => setModal('selectResource');

	const conflictMap = useMemo(() => {
		return resourceConflictMap(bookingsContext.resources);
	}, [bookingsContext.resources.length]);

	const bookedResources = findBookingConflicts(
		conflictMap,
		bookingsContext.bookings,
		[formState.start, formState.end]
	);

	const preSelectedResources = getItemResourceIds(booking, true);

	const SelectGroups = useSelectGroups({
		singleSelect: true,
		hideModal: hideSelectModal,
		groups: bookingsContext.groups,
		preSelectedGroups: [booking.forGroupId],
	});
	const SelectResource = useSelectResource({
		groupId: organizationId,
		resources: bookingsContext.resources,
		selectable: SelectableOptions.multi,
		hideModal: hideSelectModal,
		preSelectedResources: [...preSelectedResources],
		setPrimaryResource: true,
		primaryResourceId: booking.resourceId || null,
		extraColumns: [
			{
				content: t('Availability'),
				component: <ConflictItem conflicts={bookedResources} />,
			},
		],
	});

	const handleSave = async (data: FormPayload) => {
		const payload: BookingUpdatePayload = {
			forGroupId: SelectGroups.selectedGroups[0],
			title: data.title as string,
			description: data.description as string,
			timezone: getTimeZoneByGmtString(formState.timeZone).name,
			startsAt: formState.start,
			endsAt: formState.end,
			startsAtLocal: DateTime.fromTimestamp(formState.start).toDateTimeString(),
			endsAtLocal: DateTime.fromTimestamp(formState.end).toDateTimeString(),
			resourceId: null,
		};

		if (SelectResource.primaryResource) {
			payload.resourceId = SelectResource.primaryResource;
		}

		if (data.autoInviteNewUsers) {
			payload.eventFlags = [
				EventFlags.EventFlagsAutoInviteUsers,
				EventFlags.EventFlagsAutoInviteAdminAndStaff,
			];
		}

		const selectedResources = SelectResource.selectedResources.filter(
			(r) => r !== SelectResource.primaryResource
		);

		if (booking.resources?.length > 0 && selectedResources.length === 0) {
			payload.resourceIds = [];
		}

		if (selectedResources.length > 0) {
			payload.resourceIds = selectedResources;
		}

		const [ok] = await models.booking.update(
			booking,
			payload,
			data.createEvents as boolean
		);

		if (ok) {
			await bookingsContext.refreshBookings();
			hideModal();
		}
	};

	const bookingHasEvent = !!booking.eventId;

	let deleteButtonText = t('Delete booking');
	let deleteConfirmText = t('Are you sure you want to delete this booking?');

	if (bookingHasEvent) {
		deleteButtonText = t('Delete booking and event');
		deleteConfirmText = t(
			'Are you sure you want to delete the booking and connected event?'
		);
	}

	const handleRemove = async () => {
		const confirm = window.confirm(deleteConfirmText);

		if (confirm) {
			const ok = await actions.bookings.remove(booking.id);

			if (ok) {
				bookingsContext.refreshBookings();
			}
		}
	};

	return (
		<StepModal.Base onClose={hideModal}>
			<StepModal.Step
				title={t('Edit booking')}
				nextLabel={t('Save')}
				onNext={handleNext}
				canGoNext={SelectGroups.selectedGroups.length > 0}
				footerContent={
					<div>
						<Button
							label={deleteButtonText}
							large
							caution
							onClick={handleRemove}
						/>
					</div>
				}>
				<Form formRef={ref} onSubmit={handleSave}>
					<BookingForm
						groups={bookingsContext.flattenedGroups}
						formState={formState}
						setFormState={setFormState}
						showGroupSelectModal={showGroupSelectModal}
						showSelectResourceModal={showSelectResourceModal}
						selectedGroup={SelectGroups.selectedGroups}
						selectedResource={SelectResource.selectedResources}
						booking={booking}
						resources={bookingsContext.resources}
						selectResource={SelectResource.select}
						primaryResource={SelectResource.primaryResource}
						selectPrimaryResource={SelectResource.selectPrimaryResource}
					/>
				</Form>
				{modal === 'selectGroups' && SelectGroups.Modal}
				{modal === 'selectResource' && SelectResource.Modal}
			</StepModal.Step>
		</StepModal.Base>
	);
};

export default EditBookingModal;
