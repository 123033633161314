import { ReactNode } from 'react';

export const symbol_battery_error: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M310-80q-12.75 0-21.375-8.625T280-110v-676q0-12.75 8.625-21.375T310-816h90v-34q0-12.75 8.625-21.375T430-880h100q12.75 0 21.375 8.625T560-850v34h90q12.75 0 21.375 8.625T680-786v381q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T620-405v-351H340v616h212q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q582-97 573.375-88.5T552-80H310Zm30-60Zm414-23-63 62q-9 8-21.1 8.5-12.1.5-20.9-8.5-9-9-9-21t9-21l63-63-63-63q-9-9-9-21t9-21q9-9 21-9t21 9l63 63 63-63q9-9 21-9t21 9q9 9 8.5 21t-8.5 21l-62 63 62 63q8 9 8.5 21.1.5 12.1-8.5 20.9-9 9-21 8.5t-21-8.5l-63-62Z"
		/>
	</svg>
);
