import { ReactNode } from 'react';

export const symbol_volume_mute: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M440-360H310q-13 0-21.5-8.5T280-390v-180q0-13 8.5-21.5T310-600h130l149-149q14-14 32.5-6.5T640-728v496q0 20-18.5 27.5T589-211L440-360Zm-100-60h127l113 109v-337L467-540H340v120Zm119-60Z"
		/>
	</svg>
);
