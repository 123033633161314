import { t } from '@transifex/native';
import React, { Fragment, useState } from 'react';
import styled from 'styled-components';

import * as styles from 'pkg/config/styles';

import Rating from 'pkg/models/rating';
import RatingScore from 'pkg/models/rating_score';
import PositionScore from 'pkg/models/position_score';

import AssetImage from 'components/AssetImage';
import CardAnatomy, * as Card from 'components/Card';
import Heading from 'components/Heading';

import AssessmentCreate from 'components/assessment/Create';
import FootballField from 'components/fields/Football';

import MissingEntities from 'design/placeholders/block';
import Button from 'design/button';

const Wrapper = styled(Card.Body)`
	padding-left: var(--spacing-8);
	padding-right: var(--spacing-8);

	@media ${styles.breakpoint.small} {
		padding-left: var(--spacing-5);
		padding-right: var(--spacing-5);
	}
`;

const Cols = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: var(--spacing-10);

	@media ${styles.breakpoint.small} {
		display: block;
	}
`;

const DescriptionWrapper = styled.div`
	${Heading} {
		display: flex;
		justify-content: space-between;
		margin-bottom: var(--spacing-5);

		&:first-child {
			margin-bottom: var(--spacing-2);
			font-size: var(--font-size-sm);
			font-weight: var(--font-weight-normal);
			color: var(--palette-gray-500);
		}
	}

	@media ${styles.breakpoint.small} {
		margin-bottom: var(--spacing-7);
	}
`;

const RatingsWrapper = styled.div`
	${Heading} {
		margin-bottom: var(--spacing-5);
	}

	${Card.Divider} {
		margin: var(--spacing-6) 0;
	}
`;

const RatingItemRow = styled.div<{ color: keyof typeof styles.palette.Colors }>`
	display: flex;
	align-items: center;
	margin-bottom: var(--spacing-3);
	gap: var(--spacing-2);

	&:last-child {
		margin-bottom: 0;
	}

	div {
		:first-child {
			display: flex;
			justify-content: center;
			align-items: center;

			width: 35px;
			height: 35px;
			margin-right: var(--spacing-4);

			background-color: ${(props) => styles.palette[props.color][100]};
			color: ${(props) => styles.palette[props.color][500]};
			border-radius: var(--radius-3);

			font-weight: var(--font-weight-semibold);
		}

		:last-child {
			font-weight: var(--font-weight-normal);
		}
	}
`;

type PositionProps = {
	userId: number;
	rating: Rating;
	isSelfOrParentToUserOrAdmin: boolean;
	forAccount?: number;
};

// This component is only used in profile/overview/View.
// It will replace development/position-match/Preview later.
const Position: React.FC<React.PropsWithChildren<PositionProps>> = ({
	userId,
	rating,
	isSelfOrParentToUserOrAdmin,
	forAccount,
}) => {
	const [showModal, setShowModal] = useState(false);

	let suggestedPosition: PositionScore = new PositionScore();

	if (rating?.positionScores) {
		suggestedPosition = rating.positionScores.find(
			(positionScore: PositionScore) =>
				positionScore.positionId === rating.suggestedPositionId
		);
	}

	const sortedRatingScores: RatingScore[] = rating?.ratingScores
		? rating.ratingScores.sort(
				(a: RatingScore, b: RatingScore) =>
					b.maturityAdjustedDifference - a.maturityAdjustedDifference
			)
		: [];

	const trainingPriorities: RatingScore[] = sortedRatingScores
		.slice(-3)
		.reverse();

	const topStrengths: RatingScore[] = sortedRatingScores.slice(0, 3);

	const positionScore: number = Math.round(
		suggestedPosition?.maturityAdjustedValue
	);

	const handleShowModal = () => setShowModal(true);

	const handleCloseModal = () => setShowModal(false);

	if (suggestedPosition?.value === 0) {
		return (
			<Fragment>
				<MissingEntities
					noBorder
					title={t(`No position match done`)}
					description={t(
						`A PositionMatch is needed in terms to see the position statistics of the player`
					)}
					image={<AssetImage src="img/missing-entities/position-match.svg" />}
					helpUrl="https://help.360player.com/article/43-how-to-create-an-assessment"
					actions={
						isSelfOrParentToUserOrAdmin && (
							<Button secondary onClick={handleShowModal} icon="add">
								{t(`Create PositionMatch`)}
							</Button>
						)
					}
				/>

				{showModal && (
					<AssessmentCreate
						userId={userId}
						userRole={1}
						onClose={handleCloseModal}
						forAccount={forAccount}
					/>
				)}
			</Fragment>
		);
	}

	return (
		<CardAnatomy $noBorder>
			<Wrapper>
				<FootballField
					position={suggestedPosition?.position}
					score={positionScore}
					rating={rating}
				/>
				<Cols>
					<DescriptionWrapper>
						<Heading>
							<span>{t(`Choosen position`)}</span>
							{!rating.hiddenScores && <span>{t(`Score`)}</span>}
						</Heading>
						<Heading>
							<span>{suggestedPosition?.position.longName}</span>
							{!rating.hiddenScores && <span>{positionScore}</span>}
						</Heading>
						<p>{suggestedPosition?.position.description}</p>
					</DescriptionWrapper>
					<RatingsWrapper>
						<div>
							<Heading>
								{t(`Top training priority as {position}`, {
									position: suggestedPosition?.position.name,
								})}
							</Heading>
							<div>
								{trainingPriorities.map((item, index) => (
									<RatingItemRow key={item.ratingItem.id} color="orange">
										<div>{index + 1}.</div>
										{item.ratingItem.name}
									</RatingItemRow>
								))}
							</div>
						</div>
						<Card.Divider />
						<div>
							<Heading>
								{t(`Top strengths as {position}`, {
									position: suggestedPosition?.position.name,
								})}
							</Heading>
							{topStrengths.map((item, index) => (
								<RatingItemRow key={item.ratingItem.id} color="green">
									<div>{index + 1}.</div>
									{item.ratingItem.name}
								</RatingItemRow>
							))}
						</div>
					</RatingsWrapper>
				</Cols>
			</Wrapper>
		</CardAnatomy>
	);
};

export default Position;
