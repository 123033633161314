import { Fragment, useContext, useState } from 'react';
import { t } from '@transifex/native';

import { formatPriceIntervalToText } from 'pkg/utils';
import * as models from 'pkg/api/models';
import { formatToCurrency } from 'pkg/i18n/formatters';
import { useCurrentGroup } from 'pkg/identity';
import { paymentPreviewTotal } from 'pkg/api/models/payment_preview';
import DateTime from 'pkg/datetime';

import PriceOptionModal from 'routes/forms/hooks/modals/price_option';
import {
	findPriceOptionType,
	FormEngineContext,
	PriceOption,
} from 'routes/forms/hooks/useFormEngine';

import Icon from 'components/icon';
import * as CardList from 'components/card-list';

import Row from 'components/layout/row';

import * as Context from 'design/context_menu';

interface PriceOptionProps {
	index: number;

	priceOption: PriceOption;
	providerSettings: models.providerSettings.ProviderSettings;
}

export default function Option({
	index,
	priceOption,
	providerSettings,
}: PriceOptionProps) {
	const group = useCurrentGroup();
	const formEngineContext = useContext(FormEngineContext);
	const [modal, setModal] = useState<'priceOption' | ''>('');

	const preview = formEngineContext.previews.find(
		(pr) => pr.bundleNumber === index + 1
	);

	const priceOptionType = priceOption?.type || findPriceOptionType(priceOption);
	const recurringProductPrice = priceOption.productPrices.find(
		(productPrice) => productPrice?.recurring
	);
	const interval = formatPriceIntervalToText(
		recurringProductPrice?.recurringInterval,
		recurringProductPrice?.recurringIntervalCount,
		true
	);

	let total = t('Total {amount}', {
		amount: paymentPreviewTotal(preview, group.currency),
	});
	let optionTitle = '';
	let subTitle = '';
	let costSubTitle = '';

	if (
		preview?.recurringPayment.salePrice > 0 &&
		priceOptionType !== 'subscription'
	) {
		costSubTitle = t('Then {interval} {recurringAmount}', {
			interval,
			recurringAmount: formatToCurrency(
				preview.recurringPayment.salePrice / 100,
				group.currency
			),
		});
	}

	if (priceOptionType === 'direct') {
		optionTitle = t('Upfront');
	} else {
		optionTitle = interval;
	}

	switch (priceOptionType) {
		case 'installment': {
			subTitle = t('{amount} Installments', {
				amount: priceOption.installmentCount,
			});
			break;
		}
		case 'subscription': {
			subTitle = t('Subscription');
			total = `${formatToCurrency(
				preview?.recurringPayment.salePrice / 100,
				group.currency
			)}/${interval}`;

			break;
		}
	}

	const priceDetailsRows = [];

	priceOption.productPrices.forEach((pp) => {
		const productInfo = formEngineContext.formState.selectedProductsInfo.find(
			(productInfo) => productInfo.product.id === pp.product.id
		);

		if (!productInfo) {
			return;
		}

		priceDetailsRows.push({
			productPrice: pp,
			quantity: productInfo.minQuantity,
			taxRate: productInfo.taxRate,
		});
	});

	const initialPayment = formatToCurrency(
		preview?.initialPayment.salePrice / 100,
		group.currency
	);

	const hideModal = () => setModal('');
	const showModal = () => setModal('priceOption');

	const handleRemove = () => {
		formEngineContext.setFormState({
			priceOptions: formEngineContext.formState.priceOptions.filter(
				(p, i) => i !== index
			),
		});
	};

	const missingPriceForProducts =
		formEngineContext.formState.selectedProductsInfo.length -
		priceOption.productPrices.length;

	const showMissingOneTimePriceWarning =
		providerSettings.canHaveDelayedSubscriptionStart &&
		priceOption.subscriptionStartAt &&
		priceOption.productPrices.filter((p) => !p.recurring).length === 0;

	const showMissingPriceWarning =
		!!missingPriceForProducts && missingPriceForProducts > 0;

	// Check if we've got a type selected that needs a recurring price
	// This can happen if you've got a bunch of price options and remove
	// a product
	const noRecurringPrices =
		priceOptionType !== 'direct' &&
		priceOption.productPrices.every((po) => !po.recurring);

	let priceOptionLabel = t('Option {index}', { index: index + 1 });

	if (showMissingPriceWarning) {
		priceOptionLabel = t(
			'Option {index} - Missing price for {amount} products',
			{
				index: index + 1,
				amount: missingPriceForProducts,
			}
		);
	} else if (showMissingOneTimePriceWarning) {
		priceOptionLabel = t(
			'This price option has a scheduled start and needs to have a one time product due at registration'
		);
	} else if (
		!showMissingPriceWarning &&
		!showMissingOneTimePriceWarning &&
		preview?.subscriptionStartAt
	) {
		priceOptionLabel = t('Option {index} - Subscription starting {startsAt}', {
			index: index + 1,
			startsAt: DateTime.fromTimestamp(
				preview.subscriptionStartAt
			).toLocaleDateString(),
		});
	}

	return (
		<Fragment>
			<CardList.Base
				label={{
					text: priceOptionLabel,
					icon: 'tag',
				}}
				columns="1fr 1fr 1fr"
				cautious={
					(!!missingPriceForProducts && missingPriceForProducts > 0) ||
					noRecurringPrices ||
					showMissingOneTimePriceWarning
				}>
				<CardList.Title>
					<div>
						{recurringProductPrice && (
							<Fragment>
								<Icon name="sync" rotate="90deg" />{' '}
							</Fragment>
						)}
						{optionTitle}
					</div>
					{subTitle && <CardList.SubTitle>{subTitle}</CardList.SubTitle>}
				</CardList.Title>

				<CardList.Col>
					<CardList.TextContent>
						<div>
							{t('Due at registration {amount}', { amount: initialPayment })}
						</div>

						{costSubTitle && (
							<CardList.SmallText>{costSubTitle}</CardList.SmallText>
						)}
					</CardList.TextContent>
				</CardList.Col>

				<CardList.Actions justify="end">
					<Row autoColumns="max-content" align="center">
						<CardList.TextContent>{total}</CardList.TextContent>
						<Context.Menu
							toggleWith={<Context.ButtonTrigger icon="more_horiz" />}>
							<Context.Item icon="edit" onClick={showModal}>
								{t('Edit')}
							</Context.Item>
							<Context.Item icon="cancel" onClick={handleRemove}>
								{t('Remove')}
							</Context.Item>
						</Context.Menu>
					</Row>
				</CardList.Actions>
			</CardList.Base>
			{modal === 'priceOption' && (
				<PriceOptionModal priceOption={priceOption} hideModal={hideModal} />
			)}
		</Fragment>
	);
}
