import { palette } from 'pkg/config/styles';

import Icon, { IconName } from 'components/icon';

import * as css from './styles.css';

interface StatusPickerTriggerProps {
	icon: IconName;
	color: string;
}

export default function StatusPickerTrigger({
	icon,
	color,
}: StatusPickerTriggerProps) {
	return (
		<div className={css.wrapper}>
			<Icon className={css.icon} name={icon} fill={color} />
			<Icon
				className={css.icon}
				name="chevron"
				rotate="90deg"
				fill={palette.gray[400]}
			/>
		</div>
	);
}
