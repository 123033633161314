import { ReactNode } from 'react';

export const symbol_golf_course: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M791-123q-20.417 0-34.708-14.292Q742-151.583 742-172q0-20.417 14.292-34.708Q770.583-221 791-221q20.417 0 34.708 14.292Q840-192.417 840-172q0 20.417-14.292 34.708Q811.417-123 791-123ZM396-80q-97 0-166.5-19T160-148.643Q160-170 200-188.5t94-27.5v40q0 15.3 10.289 25.65 10.29 10.35 25.5 10.35Q345-140 355.5-150.35 366-160.7 366-176v-656q0-17.5 14-26.25t29-.75l196 95q17 8 17.5 27T606-710l-180 94v395.537Q516-215 574-195t58 46q0 31-69.5 50T396-80Z"
		/>
	</svg>
);
