import {
	useRef,
	useEffect,
	RefObject,
	SyntheticEvent,
	useCallback,
} from 'react';

type EventCallback = (event: Event | SyntheticEvent) => void;

/**
 *	Adds an event listener
 *
 *	@param type string Event type
 *	@param callback (event: Event | SyntheticEvent) => void
 *	@param callbackDeps any[] Array with callback dependencies, useful when callback changes
 *	@param targetNodeRef RefObject<T> Target node to listen for events, defaults to window
 */
export function useEventListener<T extends HTMLElement = HTMLDivElement>(
	type: string,
	callback: EventCallback,
	callbackDeps?: any[],
	targetNodeRef?: RefObject<T> | Document
): void {
	const cachedCallback = useRef<EventCallback>();

	if (!callbackDeps) {
		callbackDeps = [];
	}

	useEffect(() => {
		let target: T | Document | Window = window;

		if (target.hasOwnProperty('current')) {
			target = (targetNodeRef as RefObject<T>).current;
		} else if (targetNodeRef instanceof Document) {
			target = targetNodeRef;
		}

		if (!(target && target.addEventListener)) return;

		if (cachedCallback.current !== callback) {
			cachedCallback.current = callback;
		}

		const eventListener: EventCallback = (event: Event) => {
			if (!!cachedCallback?.current) {
				cachedCallback.current(event);
			}
		};

		target.addEventListener(type, eventListener);

		return () => target.removeEventListener(type, eventListener);
	}, [type, targetNodeRef, ...callbackDeps]);
}

type EventDispatcher<T> = (detail?: T) => void;

/**
 *	Creates and return a Custom Event dispatcher.
 *
 *	@param type string Event type e.g "app-greeting"
 *	@param target EventTarget
 *	@returns EventDispatcher
 */
export function useEventDispatcher<T = any>(
	type: string,
	target: EventTarget = window
): EventDispatcher<T> {
	const dispatcher = useCallback(
		(detail?: T) => {
			target.dispatchEvent(new CustomEvent(type, { detail }));
		},
		[type, target]
	);

	return dispatcher;
}

/**
 *	Behaves like useEventDispatcher, but immediately dispatches event instead of returning a dispatcher.
 */
export function dispatchCustomEvent<T = any>(
	type: string,
	detail: T,
	target: EventTarget = window
): boolean {
	return target.dispatchEvent(new CustomEvent(type, { detail }));
}
