import { ReactNode } from 'react';

export const symbol_trolley: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M810-260H220q-24.75 0-42.375-17.625T160-320v-460h-50q-12.75 0-21.375-8.675Q80-797.351 80-810.175 80-823 88.625-831.5T110-840h50q24.75 0 42.375 17.625T220-780v460h590q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-260ZM219.115-80Q194-80 177-97.677q-17-17.677-17-42.5t17-42.323q17-17.5 42.115-17.5 25.115 0 43 17.677t17.885 42.5Q280-115 262.115-97.5t-43 17.5ZM320-390q-12.75 0-21.375-8.625T290-420v-160q0-12.75 8.625-21.375T320-610h160q12.75 0 21.375 8.625T510-580v160q0 12.75-8.625 21.375T480-390H320Zm30-60h100v-100H350v100Zm250 60q-12.75 0-21.375-8.625T570-420v-160q0-12.75 8.625-21.375T600-610h160q12.75 0 21.375 8.625T790-580v160q0 12.75-8.625 21.375T760-390H600Zm30-60h100v-100H630v100ZM779.823-80Q755-80 737.5-97.677t-17.5-42.5Q720-165 737.677-182.5t42.5-17.5Q805-200 822.5-182.323t17.5 42.5Q840-115 822.323-97.5t-42.5 17.5ZM350-450h100-100Zm280 0h100-100Z"
		/>
	</svg>
);
