import { List, Map, Set, Collection } from 'immutable';

import VideoSequence from 'pkg/models/video_sequence';
import Account from 'pkg/models/account';
import User from 'pkg/models/user';
import VideoSequenceUser from 'pkg/models/video_sequence_user';
import Tag from 'pkg/models/tag';

import { localeIncludes } from 'pkg/strings';
import { RootState } from 'pkg/reducers';
import { VideoSequenceRecordingId } from 'pkg/video';

import {
	FilterHighlights,
	FilterPrivate,
} from 'components/video-analytics/FilterState';

export const findAll = (state: RootState): Map<number, VideoSequence> =>
	state.videoSequences.get('entities');

export const find = (state: RootState, sequenceId: number): VideoSequence =>
	findAll(state).get(sequenceId);

export const findAllTags = (state: RootState, sequenceId: number): Tag[] => {
	const clip = find(state, sequenceId);

	if (!clip || !clip.tags) return [];

	return clip.tags.map((tagId: number) =>
		state.tags.getIn(['entities', tagId])
	);
};

export const findAllByVideoId = (
	state: RootState,
	videoId: number
): List<VideoSequence> =>
	findAll(state)
		.filter((sequence: VideoSequence) => sequence.videoId === videoId)
		.toList();

interface SequenceFilters {
	highlights?: FilterHighlights;
	private?: FilterPrivate;
	authorAccountIds?: number[];
	taggedUserIds?: number[];
	tagIds?: number[];
	search?: string;
}

export const findAllFilteredSequences = (
	state: RootState,
	videoId: number,
	filters: SequenceFilters
): List<VideoSequence> => {
	let sequences = findAllByVideoId(state, videoId);

	// Hide highlights
	if (sequences.size > 0 && filters.highlights === FilterHighlights.Hide) {
		sequences = sequences.filter(
			(sequence: VideoSequence) => !sequence.reviewed
		);
	}

	// Hide non-highlights
	if (sequences.size > 0 && filters.highlights === FilterHighlights.Only) {
		sequences = sequences.filter(
			(sequence: VideoSequence) => sequence.reviewed
		);
	}

	// Hide private
	if (sequences.size > 0 && filters.private === FilterPrivate.Hide) {
		sequences = sequences.filter(
			(sequence: VideoSequence) => !sequence.private
		);
	}

	// Hide public
	if (sequences.size > 0 && filters.private === FilterPrivate.Only) {
		sequences = sequences.filter((sequence: VideoSequence) => sequence.private);
	}

	// Filter sequence authors
	if (sequences.size > 0 && filters.authorAccountIds.length > 0) {
		sequences = sequences.filter((sequence: VideoSequence) =>
			filters.authorAccountIds.includes(sequence.accountId)
		);
	}

	// Filter by tagged users
	if (sequences.size > 0 && filters.taggedUserIds.length > 0) {
		const sequenceIds = List(
			filters.taggedUserIds.map((userId: number) =>
				state.videoSequenceUsers
					.get('entities')
					.filter((su: VideoSequenceUser) => su.userId === userId)
					.map((su: VideoSequenceUser) => su.videoSequenceId)
					.toList()
					.flatten()
			)
		)
			.flatten()
			.toList();

		sequences = List(
			sequenceIds.map((sequenceId: number) =>
				sequences.find((sequence: VideoSequence) => sequence.id === sequenceId)
			)
		).filter((n) => n);
	}

	// Filter by tags
	if (sequences.size > 0 && filters.tagIds.length > 0) {
		const sequenceIds = List(
			filters.tagIds.map((tagId: number) =>
				state.videoSequences
					.get('entities')
					.filter((sequence: VideoSequence) => sequence.tags.includes(tagId))
					.map((sequence: VideoSequence) => sequence.id)
					.toList()
					.flatten()
			)
		)
			.flatten()
			.toList();

		sequences = List(
			sequenceIds.map((sequenceId: number) =>
				sequences.find((sequence: VideoSequence) => sequence.id === sequenceId)
			)
		).filter((n) => n);
	}

	// Filter by search
	if (sequences.size > 0 && filters.search.length > 0) {
		sequences = sequences.filter((sequence: VideoSequence) =>
			localeIncludes(sequence.title, filters.search)
		);
	}

	// Remove duplicate sequences
	if (sequences.size > 0) {
		sequences = sequences
			.groupBy((sequence: VideoSequence) => sequence.id)
			.map((item: Collection<number, VideoSequence>) => item.first())
			.toList() as List<VideoSequence>;
	}

	return sequences
		.sortBy(
			(sequence: VideoSequence) =>
				sequence.startsAtMs || sequence.startsAt * 1000
		)
		.filter(
			(sequence: VideoSequence) => sequence.id !== VideoSequenceRecordingId
		);
};

export const findAllAuthors = (
	state: RootState,
	videoId: number
): Set<Account> =>
	findAllByVideoId(state, videoId)
		.map((sequence: VideoSequence) => sequence.accountId)
		.map(
			(accountId: number) =>
				new Account(state.accounts.getIn(['entities', accountId]))
		)
		.toSet()
		.sortBy((account: Account) => account.fullName);

/**
 *	Returns all sequence authors, and includes current account regardless of it having authored sequences
 */
export const findAllAvailableAuthors = (
	state: RootState,
	videoId: number
): Set<Account> => {
	const authors = findAllAuthors(state, videoId);

	return authors.sortBy((account: Account) => account.fullName);
};

export const findAllTaggedSequenceUsers = (
	state: RootState,
	videoId: number
): Set<VideoSequenceUser> =>
	findAllByVideoId(state, videoId)
		.map((sequence: VideoSequence) => Set(sequence.users))
		.filter((n) => n)
		.flatten(true)
		.toSet()
		.map((sequenceUserId: number) =>
			state.videoSequenceUsers.getIn(['entities', sequenceUserId])
		) as Set<VideoSequenceUser>;

export const findAllTaggedUsers = (
	state: RootState,
	videoId: number
): Set<User> =>
	findAllTaggedSequenceUsers(state, videoId)
		.map((sequenceUser: VideoSequenceUser) => sequenceUser?.userId)
		.map((userId: number) => state.users.getIn(['entities', userId]))
		.filter((n) => n) as Set<User>;

export const findAllHighlights = (
	state: RootState,
	videoId: number
): List<VideoSequence> =>
	findAllByVideoId(state, videoId)
		.filter((sequence: VideoSequence) => sequence.reviewed)
		.sortBy((sequence: VideoSequence) => sequence.getStartsAt());

export const findAllAvailableTags = (
	state: RootState,
	videoId: number
): Tag[] =>
	findAllByVideoId(state, videoId)
		.map((item: VideoSequence) => item.tags)
		.toArray()
		.flat()
		.filter((n) => n)
		.map((tagId: number) => state.tags.getIn(['entities', tagId]));
