import { List } from 'immutable';
import { FC, PropsWithChildren, memo, ReactElement } from 'react';
import styled, { css } from 'styled-components';
import { t } from '@transifex/native';

import User from 'pkg/models/user';
import Account from 'pkg/models/account';

import Avatar from 'components/avatar';

const Wrapper = styled.div`
	display: inline-grid;
	grid-auto-flow: column;
	align-items: center;
	justify-content: start;
	grid-column-gap: 10px;

	${({ onClick }) =>
		!!onClick &&
		css`
			cursor: pointer;
		`}
`;

const Trigger = styled.div<{ size: number }>`
	background: var(--palette-blue-500);
	color: var(--palette-white);
	width: ${(props) => props.size}px;
	height: ${(props) => props.size}px;
	box-shadow: 0 0 0 3px var(--palette-white);
	border-radius: ${(props) => props.size}px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
`;

const StackedAvatar = styled(Avatar)<{ backdropColor?: string }>`
	box-shadow: 0 0 0 2px var(--palette-white);

	${({ theme }) =>
		theme.darkMode &&
		css`
			box-shadow: 0 0 0 2px var(--palette-gray-800);
		`}

	${({ backdropColor }) =>
		backdropColor &&
		css`
			box-shadow: 0 0 0 2px ${backdropColor};
		`}
`;

const StackedAvatars = styled.div<{ size: number }>`
	padding: 4px 0;
	width: ${({ size }) => size}px;
	display: inline-grid;
	grid-auto-flow: column;

	div:nth-child(2) {
		transform: translateX(-30%);
	}

	div:nth-child(3) {
		transform: translateX(-60%);
	}
`;

const Rest = styled.div`
	display: inline-block;
`;

interface StackProps {
	users?: List<User>;
	accounts?: List<Account>;
	size?: number;
	showRest?: boolean;
	onClick?: () => void;
	prefixWith?: ReactElement<any, any>;
	renderTriggerWith?: ReactElement<any, any>;
	renderEmptyWith?: ReactElement<any, any>;
	renderRestWith?: string | JSX.Element;
	backdropColor?: string;
}

/**
 * @deprecated
 * Use components/avatar/avatar-stack instead
 */
const Stack: FC<PropsWithChildren<StackProps>> = (props) => {
	const {
		users,
		accounts,
		size = 30,
		showRest = true,
		onClick = null,
		prefixWith = null,
		renderTriggerWith = null,
		renderEmptyWith = null,
		renderRestWith = null,
		backdropColor,
		children,
	} = props;

	if ((!accounts || accounts.size === 0) && (!users || users.size === 0)) {
		return renderEmptyWith;
	}

	const numAccounts = accounts?.size;
	const numUsers = users?.size;

	let truncatedAccounts: List<Account>;
	let truncatedUsers: List<User>;

	let multiplier: number;
	let num: number;

	if (numAccounts && numAccounts > 0) {
		truncatedAccounts = accounts.take(renderTriggerWith ? 2 : 3);
		multiplier = truncatedAccounts.size + (renderTriggerWith ? 1 : 0);
		num = truncatedAccounts.size;
	} else if (numUsers && numUsers > 0) {
		truncatedUsers = users.take(renderTriggerWith ? 2 : 3);
		multiplier = truncatedUsers.size + (renderTriggerWith ? 1 : 0);
		num = truncatedUsers.size;
	}

	let wrapperSize = size * multiplier;

	if (num === 1) {
		wrapperSize = size;
	} else if (num === 2) {
		wrapperSize = wrapperSize - size * 0.3;
	} else {
		wrapperSize = wrapperSize - size * 0.3 * 2;
	}

	return (
		<Wrapper onClick={onClick}>
			{prefixWith}
			<StackedAvatars size={wrapperSize}>
				{truncatedAccounts?.map((account: Account, n: number) => (
					<StackedAvatar
						key={`stacked-account-${n}`}
						size={size}
						account={account}
						backdropColor={backdropColor}
					/>
				))}
				{truncatedUsers?.map((user: User, n: number) => (
					<StackedAvatar
						key={`stacked-user-${n}`}
						size={size}
						user={user}
						backdropColor={backdropColor}
					/>
				))}
				{renderTriggerWith && (
					<Trigger size={size}>{renderTriggerWith}</Trigger>
				)}
			</StackedAvatars>
			{showRest && !renderRestWith && num > 0 && (
				<Rest>{t('+{num} more', { num })}</Rest>
			)}
			{renderRestWith && <Rest>{renderRestWith}</Rest>}
			{children}
		</Wrapper>
	);
};

export default memo(Stack);
