import { t } from '@transifex/native';

import { formatPriceIntervalToText } from 'pkg/utils';
import DateTime from 'pkg/datetime';
import { formatToCurrency } from 'pkg/i18n/formatters';
import { useCurrentGroup } from 'pkg/identity';

import { AssignedProductProps } from 'routes/payments/orders/create';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import Icon from 'components/icon';

import * as Table from 'components/form/Table';

import * as ContextMenu from 'design/context_menu';

interface ProductCalculationProps {
	productRows: AssignedProductProps[];
	onEdit: (id: number, index: number) => void;
	onRemove: (index: number) => void;
}

interface ProductCalculationRowProps {
	productRow: AssignedProductProps;
	index: number;
	onEdit: (id: number, index: number) => void;
	onRemove: (index: number) => void;
}

const ProductCalculations: React.FC<
	React.PropsWithChildren<ProductCalculationProps>
> = ({ productRows, onEdit, onRemove }) => {
	const group = useCurrentGroup();

	const currencySymbol = new Intl.NumberFormat(DateTime.getLocale(), {
		style: 'currency',
		currency: group.currency,
	})
		.formatToParts(1000)
		.find((i) => i.type === 'currency');

	return (
		<Table.Table>
			<thead>
				<LargeScreen>
					<Table.TableHeadRow>
						<Table.HeadCell>{t(`Product description`)}</Table.HeadCell>
						<Table.HeadCell>{t(`Quantity`)}</Table.HeadCell>
						<Table.HeadCell>
							{t(`Price ({currency})`, {
								currency: currencySymbol.value,
							})}
						</Table.HeadCell>
						<Table.HeadCell>{t(`TAX`)}</Table.HeadCell>
						<Table.HeadCell>
							{t(`Total ({currency})`, {
								currency: currencySymbol.value,
							})}
						</Table.HeadCell>
					</Table.TableHeadRow>
				</LargeScreen>
				<SmallScreen>
					<Table.TableHeadRow>
						<Table.HeadCell>{t(`Product description`)}</Table.HeadCell>
						<Table.HeadCell>
							{t(`Price ({currency})`, {
								currency: currencySymbol.value,
							})}
						</Table.HeadCell>
						<Table.HeadCell>
							{t(`Total ({currency})`, {
								currency: currencySymbol.value,
							})}
						</Table.HeadCell>
					</Table.TableHeadRow>
				</SmallScreen>
			</thead>
			<tbody>
				{productRows.map((row, index) => (
					<ProductCalculationsRow
						key={index}
						productRow={row}
						index={index}
						onEdit={onEdit}
						onRemove={onRemove}
					/>
				))}
			</tbody>
		</Table.Table>
	);
};

const ProductCalculationsRow: React.FC<
	React.PropsWithChildren<ProductCalculationRowProps>
> = ({ productRow, index, onEdit, onRemove }) => {
	const taxRate = productRow.taxRate;

	let total = productRow.productPrice.cost / 100;
	let hasTaxRate = false;

	if (taxRate && !!Object.keys(taxRate).length) {
		hasTaxRate = true;
	}

	if (hasTaxRate && !taxRate.inclusive) {
		total = total + total * (taxRate.percentage / 100);
	}

	total = total * productRow.quantity;

	const validTo = new DateTime(new Date(productRow.validTo * 1000));

	return (
		<Table.TableRow>
			<LargeScreen>
				<Table.Cell>
					{productRow.product.name}
					{productRow.validTo > 0 && (
						<Table.CellInnerText>
							{`${t(`Valid until`)} ${validTo.toLocaleDateString({
								year: 'numeric',
								month: 'numeric',
								day: 'numeric',
							})}
							`}
						</Table.CellInnerText>
					)}
				</Table.Cell>
				<Table.Cell>{productRow.quantity}</Table.Cell>
				<Table.Cell>
					{formatToCurrency(
						productRow.productPrice.cost / 100,
						productRow.productPrice.currency
					)}
					<Table.CellInnerText>
						{formatPriceIntervalToText(
							productRow.productPrice.recurringInterval,
							productRow.productPrice.recurringIntervalCount
						)}
					</Table.CellInnerText>
				</Table.Cell>

				<Table.Cell>
					{hasTaxRate &&
						`${taxRate.percentage}% ${
							taxRate.inclusive === true ? t('incl.') : ''
						}`}
				</Table.Cell>
				<Table.Cell>
					{formatToCurrency(total, productRow.productPrice.currency)}
				</Table.Cell>
				<Table.IconCell
					onClick={() => onEdit(productRow.productPrice.productId, index)}>
					<Icon name="edit" />
				</Table.IconCell>
				<Table.IconCell onClick={() => onRemove(index)}>
					<Icon name="close-circle" />
				</Table.IconCell>
			</LargeScreen>

			<SmallScreen>
				<Table.Cell>
					{productRow.product.name}
					<Table.CellInnerText>
						{t('Qty.')} {productRow.quantity}
					</Table.CellInnerText>
					{productRow.validTo > 0 && (
						<Table.CellInnerText>
							{`${t(`Valid until`)} ${validTo.toLocaleDateString({
								year: 'numeric',
								month: 'numeric',
								day: 'numeric',
							})}
							`}
						</Table.CellInnerText>
					)}
				</Table.Cell>
				<Table.Cell>
					{formatToCurrency(
						productRow.productPrice.cost / 100,
						productRow.productPrice.currency
					)}
					<Table.CellInnerText>
						{formatPriceIntervalToText(
							productRow.productPrice.recurringInterval,
							productRow.productPrice.recurringIntervalCount
						)}
					</Table.CellInnerText>
				</Table.Cell>
				<Table.Cell>
					{formatToCurrency(total, productRow.productPrice.currency)}
					<Table.CellInnerText>
						{hasTaxRate &&
							` ${t('Tax.')} ${taxRate.percentage}% ${
								taxRate.inclusive === true ? t('incl.') : ''
							}`}
					</Table.CellInnerText>
				</Table.Cell>
				<Table.IconCell>
					<ContextMenu.Menu toggleWith={<Icon name="context-menu" />}>
						<ContextMenu.Item
							onClick={() => onEdit(productRow.productPrice.productId, index)}>
							<ContextMenu.ItemIcon name="edit" />
							{t('Edit')}
						</ContextMenu.Item>
						<ContextMenu.Item onClick={() => onRemove(index)}>
							<ContextMenu.ItemIcon name="cancel" />
							{t('Remove')}
						</ContextMenu.Item>
					</ContextMenu.Menu>
				</Table.IconCell>
			</SmallScreen>
		</Table.TableRow>
	);
};

export default ProductCalculations;
