import { ReactNode } from 'react';

export const symbol_collapse_all: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-275 311-106q-9 9-21 9t-21-9q-9-9-9-21t9-21l169-169q17-17 42-17t42 17l169 169q9 9 9 21t-9 21q-9 9-21 9t-21-9L480-275Zm0-411 169-169q9-9 21-9t21 9q9 9 9 21t-9 21L522-644q-17 17-42 17t-42-17L269-813q-9-9-9-21t9-21q9-9 21-9t21 9l169 169Z"
		/>
	</svg>
);
