import { ReactNode } from 'react';

export const symbol_jump_to_element: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M500-500h50q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T550-440h-80q-12.75 0-21.375-8.625T440-470v-80q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T500-550v50Zm320 0v-50q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T880-550v80q0 12.75-8.625 21.375T850-440h-80q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T770-500h50ZM500-820v50q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T440-770v-80q0-12.75 8.625-21.375T470-880h80q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T550-820h-50Zm320 0h-50q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T770-880h80q12.75 0 21.375 8.625T880-850v80q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T820-770v-50ZM380-338 143-101q-9 9-21 9t-21-9q-9-9-9-21t9-21l237-237H150q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-440h260q12.75 0 21.375 8.625T440-410v260q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T380-150v-188Z"
		/>
	</svg>
);
