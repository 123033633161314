import { ReactNode } from 'react';

export const symbol_rsvp: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M40-384v-194q0-9 7-15.5t16-6.5h123q21 0 34.5 13.5T234-552v58q0 17-11 29.5T196-447l22 54q5 12-2.438 22.5Q208.125-360 195-360q-8 0-13.5-4t-8.5-11l-28-71H88v62q0 10.4-6.8 17.2Q74.4-360 64-360q-10.4 0-17.2-6.8Q40-373.6 40-384Zm366-24v-48h-90q-12.75 0-21.375-8.625T286-486v-84q0-12.75 8.625-21.375T316-600h114q10.4 0 17.2 6.8 6.8 6.8 6.8 17.2 0 10.4-6.8 17.2-6.8 6.8-17.2 6.8h-96v48h90q12.75 0 21.375 8.625T454-474v84q0 12.75-8.625 21.375T424-360H310q-10.4 0-17.2-6.8-6.8-6.8-6.8-17.2 0-10.4 6.8-17.2 6.8-6.8 17.2-6.8h96Zm186-28 39-146q2-8 9-13t15.293-5q11.609 0 18.658 9.5Q681-581 678-569l-51 189q-2 9-10 14.5t-17.167 5.5h-14.666Q576-360 568-365.5q-8-5.5-10-14.5l-52-189q-3-11 4.049-21t18.658-10Q537-600 544-595t9 13l39 146Zm134 52v-194q0-9 7-15.5t16-6.5h123q21 0 34.5 13.5T920-552v58q0 21-13.5 34.5T872-446h-98v62q0 10.4-6.8 17.2-6.8 6.8-17.2 6.8-10.4 0-17.2-6.8-6.8-6.8-6.8-17.2ZM88-494h98v-58H88v58Zm686 0h98v-58h-98v58Z"
		/>
	</svg>
);
