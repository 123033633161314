import { ReactNode } from 'react';

export const symbol_pen_size_1: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M199-199q-6-6-6-14t6-14l534-534q6-6 14-6t14 6q6 6 6 14t-6 14L227-199q-6 6-14 6t-14-6Z"
		/>
	</svg>
);
