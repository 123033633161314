import { ReactNode } from 'react';

export const symbol_personal_bag_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M479.824-880q45.576 0 75.376 33.5Q585-813 585-769.685V-751q76 30 125.5 101.5T760-490v138q0 15-9.318 22.5t-20.5 7.5Q719-322 709.5-329.683 700-337.366 700-352v-138q0-91.3-64.35-155.65Q571.3-710 480-710h-50q-24.75 0-42.375-17.625T370-770q0-44 33-77t76.824-33Zm.176 60q-20 0-32.5 15T435-770v2q6-1 21.5-1.5t24-.5q8.5 0 24 .5T525-768v-2q0-20-12.5-35T480-820ZM799-81 460-420H320q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T320-480h80L287-593q-14 22-20.5 49.5T260-490v350h440v-125l60 60v65q0 24-18 42t-42 18H260q-24 0-42-18t-18-42v-350q0-38 10.5-76.5T242-637L96-783q-9-9-9-21t9-21q9-9 21.5-9t21.5 9l703 702q9 9 9 21t-9 21q-9 9-21.5 9T799-81ZM535-530Zm-75 110Zm20 53Z"
		/>
	</svg>
);
