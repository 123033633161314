import { ReactNode } from 'react';

export const symbol_magnification_small: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm0-60h680v-520H140v520Zm0 0v-520 520Zm135-308q32.587 0 55.794-23.206Q354-574.412 354-607q0-32.588-23.206-55.794Q307.587-686 275-686q-32.587 0-55.794 23.206Q196-639.588 196-607q0 32.588 23.206 55.794Q242.413-528 275-528Z"
		/>
	</svg>
);
