import { t } from '@transifex/native';

import DateTime from 'pkg/datetime';
import { formatToCurrency } from 'pkg/i18n/formatters';
import * as models from 'pkg/api/models';
import { useCurrentGroup } from 'pkg/identity';

import Icon from 'components/icon';
import * as CardList from 'components/card-list';

interface UpcomingOrderRowProps {
	subscription: models.subscription.Subscription;
}

const UpcomingOrderRow: React.FC<
	React.PropsWithChildren<UpcomingOrderRowProps>
> = ({ subscription }) => {
	const group = useCurrentGroup();
	const costDetails = models.subscription.getSubscriptionRowsTotalCost(
		subscription.rows
	);
	const date = new DateTime(
		new Date((subscription.currentPeriodEnd ?? subscription.startAt) * 1000)
	);
	const totalAmount = formatToCurrency(costDetails, group.currency);

	return (
		<CardList.Base noBorder>
			<CardList.IconWrapper>
				<Icon name="order" />
			</CardList.IconWrapper>
			<CardList.Title>
				{totalAmount}
				<CardList.SubTitle>{t('Amount')}</CardList.SubTitle>
			</CardList.Title>
			<CardList.Col>
				<CardList.TextContent>
					{date.toLocaleDateString({
						year: 'numeric',
						month: 'numeric',
						day: 'numeric',
					})}
					<CardList.SmallText>{t('Send date')}</CardList.SmallText>
				</CardList.TextContent>
			</CardList.Col>
		</CardList.Base>
	);
};

export default UpcomingOrderRow;
