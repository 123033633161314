import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as actions from 'pkg/actions';
import { Group } from 'pkg/api/models/onboarding_info';
import * as routes from 'pkg/router/routes';

import {
	fetchCurrentAccount,
	useOnboardingContext,
} from 'routes/public/onboarding';
import { useOnboardingState } from 'routes/public/hooks';
import Button from 'routes/public/styles/Button';
import TeamBadge from 'routes/public/styles/TeamBadge';
import Title from 'routes/public/styles/Title';

import Column from 'components/layout/column';

export default function OrganizationFinished(): JSX.Element {
	const { goTo, resetStore } = useOnboardingContext();
	const onboardingState = useOnboardingState();
	const group = onboardingState.get<Group>('group');
	const returnToForms = onboardingState.get<string>('returnToForms');

	const save = async () => {
		const [, account] = await fetchCurrentAccount();

		if (account.id) {
			const hasReturnToForms = returnToForms;

			resetStore();

			if (hasReturnToForms) {
				window.history.pushState(
					{},
					'',
					routes.Registration.Form(
						group.organizationSlug,
						onboardingState.get<string>('formGUID')
					)
				);
				window.location.reload();
			} else {
				window.history.pushState({}, '', '/');
				window.location.reload();
			}
		} else {
			goTo('start');

			actions.flashes.show({
				title: t('Something went wrong'),
				message: t(
					"If you think this error isn't supposed to happen, please contact 360Player support."
				),
			});
		}
	};

	return (
		<Column spacing={styles.spacing._8}>
			<TeamBadge group={group} />

			<Title
				title={t('Welcome!')}
				description={t(
					'You have now created your account and are now a member of {organization_name}. By continuing you will have access to open registrations within the club, communication they share, and other ways for you to mange your billing and more.',
					{ organization_name: group.groupName }
				)}
			/>

			<Button primary label={t('Continue')} onClick={save} />
		</Column>
	);
}
