import { ReactNode } from 'react';

export const symbol_keyboard_double_arrow_right: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M411-481 234-658q-9-9-9-21t9-21q9-9 21-9t21 9l198 198q5 5 7 10t2 11q0 6-2 11t-7 10L276-262q-9 9-21 9t-21-9q-9-9-9-21t9-21l177-177Zm253 0L487-658q-9-9-9-21t9-21q9-9 21-9t21 9l198 198q5 5 7 10t2 11q0 6-2 11t-7 10L529-262q-9 9-21 9t-21-9q-9-9-9-21t9-21l177-177Z"
		/>
	</svg>
);
