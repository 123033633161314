import { ReactNode } from 'react';

export const symbol_add_diamond: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M450-449v96q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T510-353v-96h97q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T607-509h-97v-97q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T450-606v97h-96q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T354-449h96Zm30 369q-11 0-22-4.5T438-97L98-437q-8-9-12.5-20T81-479q0-11 4.5-22.5T98-521l340-341q9-9 20-13t22.5-4q11.5 0 22.5 4t19 13l341 341q9 8 13 19.5t4 22.5q0 11-4 22t-13 20L522-97q-8.105 8.13-19.158 12.565Q491.789-80 480-80Zm0-60 340-339-340-340-339 340 339 339Zm1-340Z"
		/>
	</svg>
);
