const cube = {
	name: 'cube',
	objects: [
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				d: 'M12.2582 2.7168L20.3678 7.24469V17.4313L12.2462 21.4921L4.05994 17.4328V6.91757L12.2582 2.7168ZM5.35994 8.37829L11.595 11.5731V19.7181L5.35994 16.6263V8.37829ZM12.895 19.7142L19.0678 16.6278V8.65458L12.895 11.5867V19.7142ZM12.2559 10.451L18.3009 7.57961L12.2319 4.19101L6.13532 7.31487L12.2559 10.451Z',
			},
		},
	],
};

export default cube;
