import { ReactNode } from 'react';

export const symbol_instant_mix: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M231.825-160Q219-160 210.5-168.625T202-190v-264h-52q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-514h164q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T314-454h-52v264q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Zm0-414Q219-574 210.5-582.625T202-604v-166q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T262-770v166q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM398-615q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T398-675h52v-95q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-770v95h52q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T562-615H398Zm81.825 455Q467-160 458.5-168.625T450-190v-335q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-525v335q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Zm248 0Q715-160 706.5-168.625T698-190v-97h-52q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T646-347h164q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-287h-52v97q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Zm0-247Q715-407 706.5-415.625T698-437v-333q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T758-770v333q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Z"
		/>
	</svg>
);
