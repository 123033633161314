import { ReactNode } from 'react';

export const symbol_format_italic: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M264-199q-16.8 0-28.4-11.641-11.6-11.641-11.6-28.5t11.6-28.359Q247.2-279 264-279h94l139-409H378q-16.8 0-28.4-11.641-11.6-11.641-11.6-28.5t11.6-28.359Q361.2-768 378-768h300q16.8 0 28.4 11.641 11.6 11.641 11.6 28.5T706.4-699.5Q694.8-688 678-688h-94L445-279h119q16.8 0 28.4 11.641 11.6 11.641 11.6 28.5T592.4-210.5Q580.8-199 564-199H264Z"
		/>
	</svg>
);
