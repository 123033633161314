import { ReactNode } from 'react';

export const symbol_tamper_detection_on: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M470-211q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T470-271h190v-520H140v150q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Q97-611 88.5-619.625T80-641v-150q0-24 18-42t42-18h520q24 0 42 18t18 42v215l134-134q7-7 16.5-3.458Q880-709.917 880-700v338q0 10.5-9.5 14.25T854-351L720-486v215q0 24-18 42t-42 18H470Zm-316.762 50Q142-161 130.5-166T111-179L11-279q-2-2-1.5-3t2.5-3q5-5 11.5-8.5T38-297q10 0 18 2.5T71-284l39 39v-246q0-8.667 6.07-14.333Q122.14-511 130.233-511q8.767 0 14.267 5.667Q150-499.667 150-491v120h30v-180q0-8.667 6.07-14.333Q192.14-571 200.233-571q8.767 0 14.267 5.667Q220-559.667 220-551v180h30v-140q0-8.667 6.07-14.333Q262.14-531 270.233-531q8.767 0 14.267 5.667Q290-519.667 290-511v140h30v-100q0-8.667 6.07-14.333Q332.14-491 340.233-491q8.767 0 14.267 5.667Q360-479.667 360-471v250q0 25-17.25 42.5T300-161H153.238ZM660-791v520-520Z"
		/>
	</svg>
);
