import { ReactNode } from 'react';

export const symbol_format_textdirection_vertical: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M274.825-239Q262-239 253.5-247.625T245-269v-179q-68 0-116.5-45T80-604q0-66 49-110.5T245-759h270q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T515-699h-50v430q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T405-269v-430H305v430q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Zm455.042 66Q724-173 719-175q-5-2-10-7L602-290q-8-9-8.5-21.1-.5-12.1 8.5-20.9 9-9 21-9t21 9l56 57v-454q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T760-729v454l56-57q9-9 21-9t21.391 9q8.609 9 9.109 21.364.5 12.363-8.5 21.636L751-181q-5 5-10.133 6.5-5.134 1.5-11 1.5ZM245-508v-191q-42 0-73.5 27T140-603.5q0 41.5 31.5 68.5t73.5 27Zm0-96Z"
		/>
	</svg>
);
