import { ReactNode } from 'react';

export const symbol_battery_charging_50: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M310-80q-13 0-21.5-8.5T280-110v-676q0-13 8.5-21.5T310-816h90v-34q0-13 8.5-21.5T430-880h100q13 0 21.5 8.5T560-850v34h90q13 0 21.5 8.5T680-786v298q0 13-8.5 21.5T650-458q-13 0-21.5-8.5T620-488v-268H340v383h132q14 0 20 12t-1 24q-11 23-17.5 47.5T467-239q0 35 11 66.5t30 57.5q8 11 2.5 23T492-80H310Zm350-120h-53q-9 0-13.5-7.5t.5-15.5l92-147q3-5 8.5-3.5t5.5 7.5v86h53q9 0 13.5 7.5T766-257l-92 148q-3 5-8.5 3.5T660-113v-87Z"
		/>
	</svg>
);
