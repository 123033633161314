import { ReactNode } from 'react';

export const symbol_monitor_heart: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M80-740q0-24 18-42t42-18h680q24 0 42 18t18 42v140q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T820-600v-140H140v140q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Q97-570 88.5-578.625T80-600v-140Zm60 580q-24 0-42-18t-18-42v-140q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-360v140h680v-140q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T880-360v140q0 24-18 42t-42 18H140Zm259.809-130q8.191 0 15.791-4t11.4-12l133-266 53 106q3.75 8 11.25 12t15.75 4h210q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T850-510H659l-72-143q-3.723-8.25-11.17-11.625T560.191-668q-8.191 0-15.791 3.375T533-653L400-388l-53-106q-3.75-8-11.25-12T320-510H110q-12.75 0-21.375 8.675Q80-492.649 80-479.825 80-467 88.625-458.5T110-450h191l72 144q3.723 8 11.17 12t15.639 4ZM480-480Z"
		/>
	</svg>
);
