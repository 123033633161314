import { memo } from 'react';
import styled, { css } from 'styled-components';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import { useCurrentAccountUserIds } from 'pkg/identity';

import Icon from 'components/icon';

const Wrapper = styled.div<{ align: string }>`
	display: flex;
	flex-wrap: wrap;
	margin-bottom: -1.5rem;
	position: relative;

	${({ align }) =>
		align === 'right' &&
		css`
			justify-content: flex-end;
		`}
`;

const RepliedTo = styled.div<{ align: string }>`
	display: flex;
	align-items: center;
	flex: 0 0 100%;
	font-size: var(--font-size-xs);
	font-weight: var(--font-weight-semibold);
	color: var(--palette-gray-500);
	margin-bottom: 5px;

	${({ align }) =>
		align === 'right' &&
		css`
			justify-content: flex-end;
		`}

	span {
		margin-left: 2px;
	}
`;

const Message = styled.div<{ align: string; isThumbnail: boolean }>`
	max-width: 80%;
	word-break: break-all;
	line-height: 1.5;
	background: var(--palette-gray-200);
	color: var(--palette-gray-500);
	border-radius: 1.5rem;
	padding: 0.7rem 1rem 2rem 0.9rem;

	@media ${styles.breakpoint.small} {
		max-width: 90%;
	}

	p {
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
		white-space: pre-line;
		line-height: var(--font-line-height-lg);
	}

	${({ align }) =>
		align === 'right' &&
		css`
			padding: 0.7rem 0.9rem 2rem 1rem;
		`}

	${({ isThumbnail }) =>
		isThumbnail &&
		css`
			border-radius: 1.5rem;
			padding: 0;
			overflow: hidden;
			border: 1px solid var(--palette-gray-300);

			img {
				display: block;
				max-width: 180px;
				height: auto;
				max-height: 240px;
			}
		`}
`;

const Attachment = ({
	attachment,
}: {
	attachment: models.attachment.Attachment;
}) => {
	if (models.attachment.hasThumbnail(attachment)) {
		return <img src={attachment.previewUrl || attachment.url} />;
	}

	return <p>{t('Attachment: {filename}', { filename: attachment.title })}</p>;
};

interface QuotedMessageProps {
	align: string;
	activeUserId: number;

	onClick: (messageId: number) => void;

	chat: models.chat.Chat;
	message: models.chatMessage.ChatMessage;
}

const QuotedMessage = memo(
	({ align, activeUserId, onClick, chat, message }: QuotedMessageProps) => {
		const item = message.quotedMessage;
		const isAttachment = item?.attachmentId > 0;
		const quotedMessageUser = item?.user;
		const replyingUser = message.user;
		const attachment = item.attachment;

		const activeUserIds = useCurrentAccountUserIds();
		const selfChatUser = models.chat.findMyChatUser(chat, activeUserIds);

		if (!item || !quotedMessageUser || !replyingUser) {
			return null;
		}

		const handleOnClick = () => onClick(item.id);

		let repliedLabel = '';

		if (
			quotedMessageUser.id === activeUserId &&
			replyingUser.id === activeUserId
		) {
			// You replied to yourself
			repliedLabel = t('You replied to yourself');
		} else if (quotedMessageUser.id === activeUserId) {
			// {Name} replied to you
			repliedLabel = t('{replying_name} replied to you', {
				replying_name: replyingUser.firstName,
			});
		} else {
			// {Name} replied to {name}
			const replyingName =
				replyingUser.id === activeUserId ? t('You') : replyingUser.firstName;

			repliedLabel = t('{replying_name} replied to {quoted_name}', {
				replying_name: replyingName,
				quoted_name: quotedMessageUser.firstName,
			});
		}

		return (
			<Wrapper align={align} onClick={handleOnClick}>
				<RepliedTo align={align}>
					<Icon name="reply" fill={styles.palette.gray[500]} />
					<span>{repliedLabel}</span>
				</RepliedTo>

				<Message
					isThumbnail={models.attachment.hasThumbnail(attachment)}
					align={align}>
					{isAttachment ? (
						attachment && <Attachment attachment={attachment} />
					) : (
						<p>{models.chatMessage.showMessageContent(selfChatUser, item)}</p>
					)}
				</Message>
			</Wrapper>
		);
	}
);

export default QuotedMessage;
