import * as palette from 'pkg/config/palette';

import rgba from 'pkg/rgba';

// Questionable naming conventions here, a standard needs to be set

export const small = `0 0 9px ${rgba(palette.black, 0.15)}`;
export const medium = `0 3px 15px ${rgba(palette.black, 0.15)}`;
export const large = `0 0 30px ${rgba(palette.black, 0.2)}`;

export const input = `0 2px 3px ${rgba(palette.gray[200], 0.8)}`;

export const button = `0 1px 3px ${rgba(palette.black, 0.1)}`;

export const flat = `0 0 0 1px ${rgba(palette.black, 0.05)}, 0 1px 3px 0 ${rgba(
	palette.black,
	0.15
)}`;

export const card = `0px 1px 3px ${rgba(
	palette.gray[800],
	0.1
)}, 0px 2px 1px ${rgba(palette.gray[800], 0.06)}`;

export const cardHover = `0 4px 8px 3px ${rgba(palette.black, 0.1)}`;
