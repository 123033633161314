import { ReactNode } from 'react';

export const symbol_high_res: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M240-300h24l29 64q3 8 10 12t15 4q15 0 23.5-12t2.5-26l-23-54q17-6 28-18t11-30v-55q0-18.75-13.125-31.875T315-460h-95q-17 0-28.5 11.5T180-420v170q0 13 8.5 21.5T210-220q13 0 21.5-8.5T240-250v-50Zm290 80q13 0 21.5-8.5T560-250q0-13-8.5-21.5T530-280h-70v-30h50q13 0 21.5-8.5T540-340q0-13-8.5-21.5T510-370h-50v-30h70q13 0 21.5-8.5T560-430q0-13-8.5-21.5T530-460h-90q-17 0-28.5 11.5T400-420v160q0 17 11.5 28.5T440-220h90Zm190-60h-90q-13 0-21.5 8.5T600-250q0 13 8.5 21.5T630-220h110q17 0 28.5-11.5T780-260v-60q0-17-11.5-28.5T740-360h-80v-40h90q13 0 21.5-8.5T780-430q0-13-8.5-21.5T750-460H640q-17 0-28.5 11.5T600-420v60q0 17 11.5 28.5T640-320h80v40Zm-480-80v-40h60v40h-60Zm120-230h60v60q0 13 8.5 21.5T450-500q13 0 21.5-8.5T480-530v-180q0-13-8.5-21.5T450-740q-13 0-21.5 8.5T420-710v60h-60v-60q0-13-8.5-21.5T330-740q-13 0-21.5 8.5T300-710v180q0 13 8.5 21.5T330-500q13 0 21.5-8.5T360-530v-60Zm220-120v180q0 13 8.5 21.5T610-500q13 0 21.5-8.5T640-530v-180q0-13-8.5-21.5T610-740q-13 0-21.5 8.5T580-710ZM100-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h760q24 0 42 18t18 42v600q0 24-18 42t-42 18H100Zm0-60h760v-600H100v600Zm0 0v-600 600Z"
		/>
	</svg>
);
