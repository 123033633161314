import { t } from '@transifex/native';
import { MouseEvent } from 'react';

import spacing from 'pkg/config/spacing';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import { useCollection } from 'pkg/api/use_collection';
import {
	ConnectionStatus,
	ExternalServiceProvider,
	useExternalServiceConnections,
} from 'pkg/api/models/external_service';
import useConfirm from 'pkg/hooks/useConfirm';
import * as actions from 'pkg/actions';
import * as api from 'pkg/api';
import { useCurrentUser } from 'pkg/identity';

import FeatureSelect from 'components/feature_select';
import RelativeDateTime from 'components/RelativeDateTime';
import Icon from 'components/icon';
import * as StepModal from 'components/step-modal';

import Thumbnail from 'components/video/Thumbnail';
import { WingfieldProps } from 'components/video-library/modals/steps/wingfield';
import { Spinner } from 'components/loaders/spinner';
import Column from 'components/layout/column';
import Row from 'components/layout/row';

import Button from 'design/button';

function RemoveConnectionAction(): JSX.Element {
	const modalContext = StepModal.useStepModalContext();

	const self = useCurrentUser();

	const { isLoading, records } = useExternalServiceConnections({
		userId: self.id,
		serviceProvider: ExternalServiceProvider.Wingfield,
		connectionStatus: ConnectionStatus.Active,
	});

	const currentConnectionId = records[0]?.id;

	const handleRemoveConnection = useConfirm({
		message: t('Are you sure you want to remove this connection?', {
			_context: 'wingfield video modal',
		}),
		onConfirm: async () => {
			if (currentConnectionId) {
				await api.delete(
					endpoints.Users.DeleteExternalConnection(currentConnectionId)
				);

				actions.flashes.show(
					{
						title: t('Successfully removed Wingfield connection', {
							_context: 'wingfield video modal',
						}),
					},
					200
				);
			}

			modalContext.goTo('start');
		},
	});

	if (!isLoading && records.length > 0) {
		return (
			<Button small caution onClick={handleRemoveConnection}>
				<Icon name="delete" />
				<span>
					{t('Remove connection', { _context: 'wingfield video modal' })}
				</span>
			</Button>
		);
	}

	return null;
}

interface ListVideosForUserProps extends WingfieldProps {
	currentUserId: number;
	selectedUserId: number;
}

export default function ListVideosForUser({
	payload,
	setPayload,

	currentUserId,
	selectedUserId,
}: ListVideosForUserProps): JSX.Element {
	const modalContext = StepModal.useStepModalContext();

	const { isLoading, records } =
		useCollection<models.video.WingfieldVideoSource>(
			endpoints.Video.ListVideosFromWingfield(),
			{
				queryParams: new URLSearchParams({
					userId: selectedUserId.toString(),
				}),
			}
		);

	const videoTitle = (source: models.video.WingfieldVideoSource) => {
		if (source.videoType === models.video.WingfieldVideoType.Session) {
			switch (source.sessionType) {
				case models.video.WingfieldSessionType.Match:
					return t('Match', { _context: 'wingfield video title' });
				case models.video.WingfieldSessionType.Freeplay:
					return t('Freeplay', { _context: 'wingfield session type' });
				case models.video.WingfieldSessionType.Drill:
					return t('Drill', { _context: 'wingfield video title' });
			}
		}

		if (source.videoType === models.video.WingfieldVideoType.Highlight) {
			switch (source.sessionType) {
				case models.video.WingfieldSessionType.Match:
					return t('Match highlights', { _context: 'wingfield video title' });
				case models.video.WingfieldSessionType.Freeplay:
					return t('Freeplay highlights', {
						_context: 'wingfield session type',
					});
				case models.video.WingfieldSessionType.Drill:
					return t('Drill highlights', { _context: 'wingfield video title' });
			}
		}
	};

	const handleItemSelect = (item: MouseEvent<HTMLDivElement>) => {
		const { title, userId, externalId } = item.currentTarget.dataset;

		setPayload({
			title,
			userId: Number.parseInt(userId, 10),
			externalId,
		});

		modalContext.setCanGoNext(true);
	};

	if (isLoading) {
		return <Spinner />;
	}

	if (records.length === 0) {
		return (
			<Column blockSpacing={spacing._7} spacing={spacing._7} justify="center">
				<Icon name="video-placeholder" size={3} />
				<span>
					{t('There are no videos for this user', {
						_context: 'wingfield video modal',
					})}
				</span>
				{currentUserId === selectedUserId && <RemoveConnectionAction />}
			</Column>
		);
	}

	return (
		<Column>
			{records.map((source: models.video.WingfieldVideoSource) => (
				<FeatureSelect
					key={source.sessionId}
					data-user-id={selectedUserId}
					data-external-id={source.sessionId}
					data-preview-url={source.thumbnailUrl}
					data-title={videoTitle(source)}
					active={payload?.externalId === source.sessionId}
					onClick={handleItemSelect}>
					<Row columns="160px 1fr">
						<Thumbnail
							sourceUrl={source.thumbnailUrl}
							duration={source.durationSeconds * 1000}
						/>
						<Column>
							<strong>{videoTitle(source)}</strong>
							<RelativeDateTime dateTime={source.date} />
						</Column>
					</Row>
				</FeatureSelect>
			))}
			{currentUserId === selectedUserId && <RemoveConnectionAction />}
		</Column>
	);
}
