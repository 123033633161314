import { Fragment, useContext, useState } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import { eventTypeToTranslatedString } from 'pkg/models/event';

import * as flashActions from 'pkg/actions/flashes';

import * as models from 'pkg/api/models';
import DateTime from 'pkg/datetime';
import useTooltip from 'pkg/hooks/useTooltip';
import rgba from 'pkg/rgba';
import { cssClasses } from 'pkg/css/utils';

import EditScheduleItem from 'routes/scheduling/templates/modals/EditScheduleItem';
import {
	getItemResourceIds,
	getItemResources,
	timesAsNumbers,
} from 'routes/scheduling/utils';
import { FilterState } from 'routes/scheduling/templates/filters';
import { TemplateContext } from 'routes/scheduling/templates/single';
import ScheduleItemConflictsModal from 'routes/scheduling/templates/schedule_items/conflicts_modal';

import Icon from 'components/icon';

import * as gridItemStyles from 'components/scheduling/grid/grid-item/styles.css';
import { Spinner } from 'components/loaders/spinner';
import { SchedulingViews } from 'components/scheduling/SelectView';
import Row from 'components/layout/row';
import Column from 'components/layout/column';
import { getGridItemCssVars } from 'components/scheduling/grid/config';

import * as Context from 'design/context_menu';

const _context = 'scheduling/bookings';

interface TemplateScheduleItemProps {
	scheduleItem: models.scheduleItem.ScheduleItem;
	schedule: models.schedule.Schedule;
	filters: FilterState;
}

const TemplateScheduleItem = ({
	scheduleItem,
	schedule,
	filters,
}: TemplateScheduleItemProps) => {
	const templateContext = useContext(TemplateContext);
	const [modal, setModal] = useState('');
	const { tooltip: overlapTooltip, onMouseEnter: overlapOnMouseEnter } =
		useTooltip(t('Overlapping bookings'));
	const { tooltip: conflictTooltip, onMouseEnter: conflictOnMouseEnter } =
		useTooltip(t('Resource has conflicts'));
	const { tooltip, onMouseEnter } = useTooltip(t('Creating booking'));
	const hasId = !!scheduleItem.id;

	const showEditModal = () => setModal('edit');
	const hideModal = () => setModal('');
	const showConflictsModal = () => setModal('conflicts');

	// I use this random dates just to figure out at what time of the day
	// the scheduleItem was created
	const startsAt = new DateTime(
		new Date('1970-01-01T' + scheduleItem.startsAt + 'Z')
	).toLocaleTimeString({
		timeZone: 'UTC',
		hour: 'numeric',
		minute: 'numeric',
	});
	const endsAt = new DateTime(
		new Date('1970-01-01T' + scheduleItem.endsAt + 'Z')
	).toLocaleTimeString({
		timeZone: 'UTC',
		hour: 'numeric',
		minute: 'numeric',
	});

	const hasAnyResource =
		scheduleItem.resource || !!scheduleItem.resources?.length;

	const resources = getItemResources(scheduleItem);

	const resourceConflicts =
		templateContext.conflicts[scheduleItem.id]?.resourceConflicts;
	const groupConflicts =
		templateContext.conflicts[scheduleItem.id]?.groupConflicts;
	const hasResourceOrGroupConflicts =
		resourceConflicts?.length > 0 || groupConflicts?.length > 0;

	const handleRemove = async () => {
		const ok = await models.scheduleItem.remove(scheduleItem);

		if (ok) {
			flashActions.show({
				title: t('Successfully removed booking', {
					_context,
				}),
			});
		}
	};

	if (
		(filters?.conflicts &&
			(!resourceConflicts || resourceConflicts.length === 0)) ||
		(filters?.types.length > 0 &&
			!filters?.types.includes(scheduleItem.type)) ||
		(filters?.resources.length > 0 &&
			!filters?.resources.some((r) =>
				getItemResourceIds(scheduleItem).includes(r)
			)) ||
		(templateContext.view === SchedulingViews.Resource &&
			filters?.groups.length > 0 &&
			!filters?.groups.includes(scheduleItem.groupId))
	) {
		return null;
	}

	const scheduleItemBase = (
		<Fragment>
			<div
				className={cssClasses(
					gridItemStyles.base,
					!hasId ? gridItemStyles.inactive : ''
				)}
				style={getGridItemCssVars(scheduleItem.type)}
				onMouseEnter={!hasId ? onMouseEnter : undefined}>
				{hasResourceOrGroupConflicts && (
					<div className={gridItemStyles.errorInfo}>
						{resourceConflicts?.length > 0 && (
							<Row
								spacing={styles.spacing._1}
								align="center"
								onMouseEnter={conflictOnMouseEnter}
								autoColumns="max-content">
								<Icon name="error" fill={styles.palette.red[400]} />
								{t('Conflicts')}
							</Row>
						)}
						{groupConflicts?.length > 0 && (
							<Row
								autoColumns="max-content"
								spacing={styles.spacing._1}
								align="center"
								onMouseEnter={overlapOnMouseEnter}>
								<Icon name="overlap" />
								{t('Overlaps')}
							</Row>
						)}
					</div>
				)}
				<div className={cssClasses(gridItemStyles.infoBody)}>
					<Column spacing={styles.spacing._1}>
						{!hasId ? (
							<Spinner
								size="20px"
								color={rgba(
									styles.palette.events[scheduleItem.type].foregroundColor
								)}
							/>
						) : (
							<span className={gridItemStyles.type}>
								{eventTypeToTranslatedString(scheduleItem.type)}
							</span>
						)}
						<div
							className={gridItemStyles.time}>{`${startsAt} – ${endsAt}`}</div>
					</Column>
					{templateContext.view === SchedulingViews.Resource && (
						<div className={gridItemStyles.name}>
							{scheduleItem.group?.name}
						</div>
					)}
					<div className={gridItemStyles.title}>{scheduleItem.title}</div>
				</div>
				{hasAnyResource && (
					<div
						className={cssClasses(
							gridItemStyles.infoBody,
							gridItemStyles.resourceBody
						)}>
						{templateContext.view === SchedulingViews.Group && (
							<Fragment>
								{!!scheduleItem.resource?.location && (
									<div className={gridItemStyles.locationRow}>
										<Icon
											name="location"
											actualSize
											size={1.4}
											className={gridItemStyles.locationIcon}
										/>
										<span className={gridItemStyles.location}>
											{scheduleItem.resource?.location.title}
										</span>
									</div>
								)}
							</Fragment>
						)}
						{resources.length > 0 && (
							<Column spacing={styles.spacing._1}>
								{resources.map((r) => (
									<div key={r.id} className={gridItemStyles.resource}>
										{r?.title}
									</div>
								))}
							</Column>
						)}
					</div>
				)}
			</div>
			{!hasId && tooltip}
			{conflictTooltip}
			{overlapTooltip}
		</Fragment>
	);

	return (
		<div
			className={gridItemStyles.itemWrapper}
			style={{
				order: timesAsNumbers([scheduleItem.startsAt, scheduleItem.endsAt])[0],
			}}
			onClick={hasResourceOrGroupConflicts ? undefined : showEditModal}>
			{hasResourceOrGroupConflicts ? (
				<Context.Menu toggleWith={scheduleItemBase}>
					{templateContext.conflicts[scheduleItem.id] && (
						<Context.Item onClick={showConflictsModal}>
							<Context.ItemIcon name="arrow_forward" />
							{t('Show conflicts', {
								_context,
							})}
						</Context.Item>
					)}
					<Context.Item onClick={showEditModal}>
						<Context.ItemIcon name="edit" />
						{t('Edit')}
					</Context.Item>
					<Context.ConfirmItem
						caution
						onConfirm={handleRemove}
						message={t('Are you sure you want to delete this booking?')}>
						<Context.ItemIcon name="delete" />
						{t('Remove')}
					</Context.ConfirmItem>
				</Context.Menu>
			) : (
				<Fragment>{scheduleItemBase}</Fragment>
			)}
			{modal === 'conflicts' && (
				<ScheduleItemConflictsModal
					scheduleItem={scheduleItem}
					hideModal={hideModal}
				/>
			)}
			{modal === 'edit' && (
				<EditScheduleItem
					scheduleItem={scheduleItem}
					scheduleItems={templateContext.scheduleItems}
					schedule={schedule}
					hideModal={hideModal}
				/>
			)}
		</div>
	);
};

export default TemplateScheduleItem;
