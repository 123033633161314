import { ReactNode } from 'react';

export const symbol_library_add_check: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m491-466-76-76q-9-9-21.1-9-12.1 0-20.9 9-9 9-9 21t9 21l96 96q9 9 21 9t21-9l186-186q9-9 9-21.5t-8.609-20.5q-9.391-8-21.891-7.5-12.5.5-20.5 8.5L491-466ZM260-200q-24 0-42-18t-18-42v-560q0-24 18-42t42-18h560q24 0 42 18t18 42v560q0 24-18 42t-42 18H260Zm0-60h560v-560H260v560ZM140-80q-24 0-42-18t-18-42v-590q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-730v590h590q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q760-97 751.375-88.5T730-80H140Zm120-740v560-560Z"
		/>
	</svg>
);
