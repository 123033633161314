import { t } from '@transifex/native';
import { useSelector } from 'react-redux';
import { List } from 'immutable';
import { useMemo, useState } from 'react';

import Group from 'pkg/models/group';

import * as selectors from 'pkg/selectors';
import { RootState } from 'pkg/reducers';
import * as actions from 'pkg/actions';
import { debounce } from 'pkg/timings';
import store from 'pkg/store/createStore';
import { useCurrentGroup, useCurrentOrganization } from 'pkg/identity';

import TreeBase from 'containers/group/settings/user/tree/Base';

import StepModal, { Step } from 'components/step-modal';

import * as ActionBar from 'components/layout/ActionBar';
import SearchInput from 'components/form/SearchInput';

interface SelectGroupsProps {
	data: {
		name: string;
		values: (string | number)[];
	};
	onSelect: (groupIds: number[]) => void;
	onCloseModal: () => void;
	showOrganization?: boolean;
	canGoNextIfEmpty?: boolean;
	disabledGroups?: number[];
	selectAllChildren?: boolean;
	singleSelect?: boolean;
}

const SelectGroups: React.FC<React.PropsWithChildren<SelectGroupsProps>> = ({
	data,
	onCloseModal,
	onSelect,
	showOrganization = false,
	canGoNextIfEmpty = false,
	disabledGroups,
	selectAllChildren,
	singleSelect = false,
}) => {
	const [isSearch, setIsSearch] = useState<boolean>(false);
	const [selectedGroups, setSelectedGroups] = useState<number[]>(
		data.values as number[]
	);

	const [searchResultIds, setSearchResultIds] = useState<List<number>>(
		List([])
	);

	const group = useCurrentGroup();
	const organization = useCurrentOrganization();

	const membershipTree = useSelector((state: RootState) =>
		selectors.groups.getOrganizationTree(state, {
			groupId: showOrganization ? organization.id : group.id,
		})
	);

	const searchedGroups = useSelector((state: RootState) =>
		selectors.groups.findAllByIds(state, searchResultIds)
	).toList();

	const findAllChildIds = (group: Group): number[] => {
		const ids = [group.id];

		group.children?.forEach((group) => {
			ids.push(...findAllChildIds(group));
		});

		return ids;
	};

	const handleSelect = (group: Group) => {
		if (selectAllChildren) {
			const ids = findAllChildIds(group);

			setSelectedGroups((prev) => {
				return prev.includes(group.id)
					? prev.filter((groupId) => !ids.includes(groupId))
					: [...prev, ...ids];
			});

			return;
		}

		setSelectedGroups((prev) => {
			return prev.includes(group.id)
				? prev.filter((groupId) => groupId !== group.id)
				: [...prev, group.id];
		});
	};

	const handleSingleSelect = (group: Group) => setSelectedGroups([group.id]);

	const handleSearch = useMemo(
		() =>
			debounce(async (value: string) => {
				setIsSearch(true);

				if (value.trim().length === 0) {
					setIsSearch(false);
					setSearchResultIds(List([]));
				} else {
					const groupIds = await actions.groups.searchChildren(
						group.id,
						value
					)(store.dispatch);

					setSearchResultIds(List(groupIds));
				}
			}, 500),
		[group.id]
	);

	const handleNext = async () => {
		onSelect(selectedGroups);
		return true;
	};

	return (
		<StepModal onClose={onCloseModal} wide>
			<Step
				title={t(`Select groups`)}
				nextLabel={t('Done')}
				skipBody
				canGoNext={canGoNextIfEmpty ? true : selectedGroups.length > 0}
				onNext={handleNext}>
				<ActionBar.Bar>
					<ActionBar.PrimaryAction>
						<SearchInput
							placeholder={t(`Search group (name)`)}
							onChange={handleSearch}
						/>
					</ActionBar.PrimaryAction>
				</ActionBar.Bar>
				<TreeBase
					groups={isSearch ? searchedGroups : membershipTree}
					disabledGroups={disabledGroups || []}
					isSearch={isSearch}
					selected={List(selectedGroups)}
					onSelect={singleSelect ? handleSingleSelect : handleSelect}
					singleSelect={singleSelect}
				/>
			</Step>
		</StepModal>
	);
};

export default SelectGroups;
