import { ReactNode } from 'react';

export const symbol_phone_android: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M418.235-125h123.53Q550-125 556-131t6-14q0-8-6-14t-14.235-6h-123.53Q410-165 404-159t-6 14q0 8 6 14t14.235 6ZM260-40q-24 0-42-18t-18-42v-760q0-24 18-42t42-18h440q24 0 42 18t18 42v760q0 24-18 42t-42 18H260Zm0-150v90h440v-90H260Zm0-60h440v-520H260v520Zm0-580h440v-30H260v30Zm0 640v90-90Zm0-640v-30 30Z"
		/>
	</svg>
);
