import { ReactNode } from 'react';

export const symbol_history_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M477-780q-37 0-72 10t-67 27q-11 6-23 3.5T296-753q-7-11-5-23t13-18q39-23 83-34.5t90-11.5q75 0 141 28t115.5 76.5Q783-687 811.5-622T840-482q0 51-13.5 98T788-295q-6 10-17.5 10t-20.5-8q-9-9-11.5-21t3.5-23q18-33 28-70t10-78q0-124-89-209.5T477-780Zm4 97q13 0 21.5 8.5T511-653v50q0 13-8.5 21.5T481-573q-13 0-21.5-8.5T451-603v-50q0-13 8.5-21.5T481-683Zm-4 563q-75 0-139.5-28.5T224-226q-44-45-71-102t-32-123q-1-13 7.5-21.5T150-481q13 0 21 8.5t10 21.5q5 54 28 100.5t58 82.5q41 41 94.5 64.5T477-180q46 0 87.5-13t76.5-36L263-607h-91q-13 0-21.5-8.5T142-637v-91l-58-58q-9-9-9-21.5t9-21.5q9-9 21.5-9t21.5 9l709 709q9 9 8.5 21T835-78q-9 9-21.5 9T792-78L685-185q-45 31-97.5 48T477-120Z"
		/>
	</svg>
);
