import { ReactNode } from 'react';

export const symbol_change_history: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M134-160q-18 0-26.5-15.5T109-206l347-553q9-14 25-14t25 14l345 553q9 15 1 30.5T826-160H134Zm53-60h586L481-685 187-220Zm293-233Z"
		/>
	</svg>
);
