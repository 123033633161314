import { ReactNode } from 'react';

export const symbol_monitoring: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M149.825-120Q137-120 128.5-128.625T120-150v-46q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T180-196v46q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Zm165 0Q302-120 293.5-128.625T285-150v-206q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T345-356v206q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Zm165 0Q467-120 458.5-128.625T450-150v-146q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-296v146q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Zm165 0Q632-120 623.5-128.625T615-150v-246q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T675-396v246q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Zm165 0Q797-120 788.5-128.625T780-150v-366q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T840-516v366q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM559.5-499q-11.5 0-22.463-4.696Q526.073-508.391 517-517L400-634 172-407q-9.067 9-21.533 8.5Q138-399 129.13-408q-8.13-9-8.63-21t8.5-21l229-227q9.073-8.87 20.037-12.935Q389-694 400-694q11 0 22.341 4.065Q433.683-685.87 442-677l118 118 229-229q9-9 21-9t20.87 9q8.13 9 8.63 21t-8.5 21L602-517q-8 9-19.5 13.5t-23 4.5Z"
		/>
	</svg>
);
