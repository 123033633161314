import { ReactNode } from 'react';

export const symbol_guardian: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-40q-186 0-313-68T40-275q0-36 16-67.5t48-58.5q31-26 74-45t97-29q11-2 20.5 5.5T307-450q2 12-6.5 21.5T280-417q-41 7-74 19.5T149-366q-24 20-36.5 42.5T100-275q0 69 114 122t266 53q152 0 266-53t114-122q0-26-12.5-49T811-366q-25-19-57.5-32T680-418q-12-2-20.5-11.5T653-450q1-12 10.5-19.5T685-475q45 9 86 24.5t71 37.5q39 27 58.5 61.5T920-275q0 100-127 167.5T480-40Zm-80-164q-13 0-21.5-8.5T370-234v-424H190q-12 0-21-8.5t-9-21.5q0-12 9-21t21-9h580q12 0 21 9t9 21q0 13-9 21.5t-21 8.5H590v424q0 13-8.5 21.5T560-204q-12 0-21-8.5t-9-21.5v-175H430v175q0 13-9 21.5t-21 8.5Zm80-574q-29 0-50-21t-21-50q0-29 21-50t50-21q29 0 50 21t21 50q0 29-21 50t-50 21Z"
		/>
	</svg>
);
