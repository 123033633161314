import { ReactNode } from 'react';

export const symbol_water_orp: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M80-408q0-65.005 30-129.503Q140-602 184.921-660.532q44.922-58.532 97.5-110Q335-822 380-862q4.737-4 9.868-6Q395-870 400-870t10.132 2q5.131 2 9.868 6 46 42 98 92.5T615-660q41 53 72.5 116.5T720-411q0 13-8.912 22-8.913 9-22.088 9-11.9 0-19.95-9-8.05-9-9.05-21-2-48-21.5-93T593-588q-33-50-81.5-103T400-800Q273-688 206.5-587.5T140-408q0 54 17.5 100.5T206-226v16.059q0 10.323-5 16.632Q196-187 188-184q-8 3-15.5 2t-14.5-8q-37-43-57.5-98.5T80-408Zm320-105ZM300-86q-14.45 0-24.225-9.775Q266-105.55 266-120v-160q0-14.45 9.775-24.225Q285.55-314 300-314h100q14.45 0 24.225 9.775Q434-294.45 434-280v160q0 14.45-9.775 24.225Q414.45-86 400-86H300Zm14-48h72v-132h-72v132Zm214-32v62q0 10.4-6.8 17.2Q514.4-80 504-80q-10.4 0-17.2-6.8-6.8-6.8-6.8-17.2v-194q0-9 7-15.5t16-6.5h123q20 0 34 14t14 34v58q0 17-10.5 29.5T636-167l22 54q5 12-2.438 22.5Q648.125-80 635-80q-8 0-13.5-4T613-95l-28-71h-57Zm246 0v62q0 10.4-6.8 17.2Q760.4-80 750-80q-10.4 0-17.2-6.8-6.8-6.8-6.8-17.2v-194q0-9 7-15.5t16-6.5h123q20 0 34 14t14 34v58q0 20-14 34t-34 14h-98Zm-246-48h98v-58h-98v58Zm246 0h98v-58h-98v58Z"
		/>
	</svg>
);
