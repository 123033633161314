import { ReactNode } from 'react';

export const symbol_wifi_proxy: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M700-180h50v-50h-50v50Zm0-110h50v-50h-50v50Zm110 110h50v-50h-50v50Zm-125 60q-18.75 0-31.875-13.125T640-165v-190q0-18.75 13.125-31.875T685-400h80q18.75 0 31.875 13.125T810-355v65h65q18.75 0 31.875 13.125T920-245v80q0 18.75-13.125 31.875T875-120H685ZM480-800q120 0 228.5 41T909-644q10.733 8.203 16.483 19.763Q931.233-612.678 932-600q1 12.58-4 24.79Q923-563 913-553l-52 53q-8 8-20.5 8t-20.5-8q-8-8-8-20.5t8-20.5l52-53q-87-68-184.5-107T480-740q-110 0-207.5 39T88-594l392 392 39-39q8-8 20.5-8t20.5 8q8 8 8 20.5t-8 20.5l-38 38q-9 9-20 13t-22 4q-11 0-22-4t-20-13L48-552q-9.88-9.905-14.94-22.476Q28-587.048 29-600q.759-12.75 6.069-24.375T51-645q92-74 200.5-114.5T480-800Zm0 329Z"
		/>
	</svg>
);
