import { useContext } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import { EventTypes, sortEventTypesByLabels } from 'pkg/api/models/event';
import { useQueryState } from 'pkg/hooks/query-state';

import { BookingsContext } from 'routes/scheduling/bookings';

import { SmallScreen, LargeScreen } from 'components/MediaQuery';
import Icon from 'components/icon';

import Row from 'components/layout/row';
import * as columnStyles from 'components/scheduling/grid/column-item/styles.css';
import * as GridColumnItem from 'components/scheduling/grid/column-item';

import * as Context from 'design/context_menu';

interface ResourceColumnProps {
	resource: models.resource.Resource;
}

const ResourceColumn = ({ resource }: ResourceColumnProps) => {
	const bookingsContext = useContext(BookingsContext);
	const qs = useQueryState();

	const parentResource = bookingsContext.resources.find(
		(item) => item.id === resource.parentResourceId
	);
	const resourceBookings = bookingsContext.bookings.filter(
		(item) =>
			item.resources?.some((i) => i.resourceId === resource.id) ||
			item.resourceId === resource.id
	);

	const handleToggleVisibility = () => {
		qs.set(
			'schedulingFilters',
			JSON.stringify({
				...bookingsContext.filters,
				resources:
					bookingsContext.filters.resources.length > 0
						? bookingsContext.filters.resources.filter((r) => r !== resource.id)
						: bookingsContext.resources
								.filter((r) => r.id !== resource.id)
								.map((r) => r.id),
			})
		);
		qs.commit();
	};

	const handleShowOnlyRow = () => {
		qs.set(
			'schedulingFilters',
			JSON.stringify({
				...bookingsContext.filters,
				resources: [resource.id],
			})
		);
		qs.commit();
	};

	const handleRemoveBookings = async () => {
		for (const item of resourceBookings) {
			await models.booking.remove(item);
		}
		bookingsContext?.refreshBookings();
	};

	const mappedEventTypes: { [key: string]: number[] } = {};

	resourceBookings?.forEach((item) => {
		if (item.type in mappedEventTypes) {
			mappedEventTypes[item.type] = [...mappedEventTypes[item.type], item.id];
		} else {
			mappedEventTypes[item.type] = [item.id];
		}
	});

	const eventTypes =
		Object.keys(mappedEventTypes).length > 0 ? (
			<div className={columnStyles.eventTypeWrapper}>
				{Object.keys(mappedEventTypes)
					.sort((a: EventTypes, b: EventTypes) => sortEventTypesByLabels(a, b))
					.map((k, i) => (
						<GridColumnItem.Type key={i} type={k as EventTypes}>
							{mappedEventTypes[k].length}
						</GridColumnItem.Type>
					))}
			</div>
		) : null;

	return (
		<div className={columnStyles.wrapper}>
			<div>
				<div className={columnStyles.name}>
					{resource.parentResourceId !== null && (
						<div className={columnStyles.parentName}>
							{parentResource?.title}
						</div>
					)}
					<Row spacing={styles.spacing._2} columns="1fr auto">
						<div>{resource.title}</div>
						<Row autoColumns="auto" align="center">
							<SmallScreen>{eventTypes}</SmallScreen>

							<Context.Menu toggleWith={<Icon name="context-menu" />}>
								<Context.Item onClick={handleToggleVisibility}>
									<Context.ItemIcon name="visibility_off" />
									{t('Hide row')}
								</Context.Item>
								<Context.Item onClick={handleShowOnlyRow}>
									<Context.ItemIcon name="visibility" />
									{t('Show only this row')}
								</Context.Item>
								{resourceBookings.length > 0 && (
									<Context.ConfirmItem
										caution
										onConfirm={handleRemoveBookings}
										message={t(
											'Are you sure you want to remove all bookings for this group?'
										)}>
										<Context.ItemIcon name="delete" />
										{t('Remove items')}
									</Context.ConfirmItem>
								)}
							</Context.Menu>
						</Row>
					</Row>
				</div>
				<LargeScreen>{eventTypes}</LargeScreen>
			</div>
			{!!resource.location && (
				<div className={columnStyles.locationRow}>
					<Icon name="location" />
					<div className={columnStyles.location}>{resource.location.title}</div>
				</div>
			)}
		</div>
	);
};

export default ResourceColumn;
