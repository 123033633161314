import { t } from '@transifex/native';
import { useRef, useState } from 'react';

import * as flashActions from 'pkg/actions/flashes';

import * as models from 'pkg/api/models';
import useMixedState from 'pkg/hooks/useMixedState';
import { ResourceUpdatePayload } from 'pkg/api/models/resource';
import { crash } from 'pkg/errors/errors';

import ResourceForm from 'routes/scheduling/resources/forms/ResourceForm';
import CreateCategory from 'routes/scheduling/categories/modals/Create';
import CreateLocationModal from 'routes/scheduling/locations/modals/Create';

import * as StepModal from 'components/step-modal';

import Form, { asString, FormPayload } from 'components/form/Form';
import useSelectResource, {
	SelectableOptions,
} from 'components/resources/select_modal';

type Modals = '' | 'category' | 'location' | 'selectResource';

interface EditResourceModalProps {
	resource: models.resource.Resource;
	resources: models.resource.Resource[];

	refreshResources: () => Promise<void>;
	refreshCategories: () => Promise<void>;
	hideModal: () => void;
}

const EditResourceModal = ({
	resource,
	resources,
	refreshResources,
	refreshCategories,
	hideModal,
}: EditResourceModalProps) => {
	const [modal, setModal] = useState<Modals>('');
	const [resourceIds, setResourceIds] = useMixedState({
		resourceLocationId: resource.resourceLocationId,
		resourceCategoryId: resource.resourceCategoryId,
	});
	const formRef = useRef(null);

	const hideInnerModal = () => setModal('');
	const showCategoryModal = () => setModal('category');
	const showLocationModal = () => setModal('location');
	const showSelectResourceModal = () => setModal('selectResource');

	const SelectResource = useSelectResource({
		groupId: resource.groupId,
		selectable: SelectableOptions.single,
		hideModal: hideInnerModal,
		resources: resources.filter((r) => r.id !== resource.id),
		preSelectedResources:
			resource.parentResourceId !== null ? [resource.parentResourceId] : [],
	});

	const handleCreateCategory = (
		category: models.resourceCategory.ResourceCategory
	) =>
		setResourceIds({
			resourceCategoryId: category.id,
		});

	const handleCreateLocation = (
		location: models.resourceLocation.ResourceLocation
	) =>
		setResourceIds({
			resourceLocationId: location.id,
		});

	const handleSubmit = async (data: FormPayload) => {
		const payload: ResourceUpdatePayload = {
			title: asString(data.title),
			description: asString(data.description),
			resourceCategoryId:
				data.category === ''
					? null
					: Number.parseInt(asString(data.category), 10),
			resourceLocationId:
				data.location === ''
					? null
					: Number.parseInt(asString(data.location), 10),
			parentResourceId:
				SelectResource.selectedResources.length > 0
					? SelectResource.selectedResources[0]
					: null,
		};

		const [ok] = await models.resource.update(resource, payload);

		if (ok) {
			flashActions.show({
				title: t(`Successfully edited {name}`, {
					name: asString(data.title),
				}),
			});
			refreshResources();
			refreshCategories();
		} else {
			const err = crash();
			flashActions.show({
				title: err.title,
				message: err.description,
			});
		}
	};

	const handleNext = async () =>
		await formRef.current.dispatchEvent(
			new Event('submit', {
				cancelable: true,
				bubbles: true,
			})
		);

	return (
		<StepModal.Base onClose={hideModal}>
			<StepModal.Step
				title={t(`Edit {name}`, {
					name: resource.title,
				})}
				nextLabel={t('Save')}
				onNext={handleNext}>
				<Form formRef={formRef} onSubmit={handleSubmit}>
					<ResourceForm
						groupId={resource.groupId}
						resource={{ ...resource, ...resourceIds }}
						showCategoryModal={showCategoryModal}
						showLocationModal={showLocationModal}
						selectedResources={SelectResource.selectedResources}
						selectResource={SelectResource.select}
						resources={resources}
						showSelectResourceModal={showSelectResourceModal}
					/>
				</Form>
				{modal === 'category' && (
					<CreateCategory
						groupId={resource.groupId}
						hideModal={hideInnerModal}
						onCreate={handleCreateCategory}
					/>
				)}
				{modal === 'location' && (
					<CreateLocationModal
						groupId={resource.groupId}
						onClose={hideInnerModal}
						onCreate={handleCreateLocation}
					/>
				)}
				{modal === 'selectResource' && SelectResource.Modal}
			</StepModal.Step>
		</StepModal.Base>
	);
};

export default EditResourceModal;
