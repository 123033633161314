import { ReactNode } from 'react';

export const symbol_note_stack_add: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M280-140v-480q0-25.063 17.5-42.532Q315-680 340-680h480q24.75 0 42.375 17.625T880-620v315q0 12.444-5 23.722T862-262L698-98q-8 8-19.278 13-11.278 5-23.722 5H340q-24.75 0-42.375-17.625T280-140ZM81-725q-5-25 9.5-44.5T130-794l473-84q25-5 44.5 9t24.5 39l16 90h-60l-14-79-473 83 79 450v83q-20-2-35-15t-19-34L81-725Zm259 105v480h308l172-172v-308H340Zm240 240Zm-30 30v90q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T610-260v-90h90q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T700-410h-90v-90q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T550-500v90h-90q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T460-350h90Z"
		/>
	</svg>
);
