import { ReactNode } from 'react';

export const symbol_settings_phone: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M459-525q-17 0-28.5-11.5T419-565q0-17 11.5-28.5T459-605q17 0 28.5 11.5T499-565q0 17-11.5 28.5T459-525Zm173 0q-16.575 0-27.787-11.5Q593-548 593-565t11.213-28.5Q615.425-605 632-605q17.425 0 29.213 11.5Q673-582 673-565t-11.787 28.5Q649.425-525 632-525Zm177 0q-17.425 0-29.213-11.5Q768-548 768-565t11.787-28.5Q791.575-605 809-605q16.575 0 27.787 11.5Q848-582 848-565t-11.213 28.5Q825.575-525 809-525Zm-14 405q-116 0-236.5-56T335-335Q232-438 176-558.5T120-795q0-19.286 12.857-32.143T165-840h140q14 0 24 10t14 25l26.929 125.641Q372-665 369.5-653.5q-2.5 11.5-10.729 19.726L259-533q26 44 55 82t64 72q37 38 78 69.5t86 55.5l95-98q10-11 23.151-15T686-369l119 26q15 4 25 16.044T840-300v135q0 19.286-12.857 32.143T795-120ZM229-588l81-82-23-110H180q2 42 13.5 88.5T229-588Zm369 363q41 19 89 31t93 14v-107l-103-21-79 83ZM229-588Zm369 363Z"
		/>
	</svg>
);
