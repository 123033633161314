import { ReactNode } from 'react';

export const symbol_stream_apps: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M620-420h240v-160H620v160Zm20 60-54 54q-7 7-16.5 3.458Q560-306.083 560-316v-294q0-12.75 8.625-21.375T590-640h300q12.75 0 21.375 8.625T920-610v220q0 12.75-8.625 21.375T890-360H640ZM260-130v30h440v-30H260Zm0-700h440v-30H260v30Zm0 0v-30 30Zm0 700v30-30Zm360-290v-160 160ZM260-40q-24.75 0-42.375-17.625T200-100v-760q0-24.75 17.625-42.375T260-920h440q24.75 0 42.375 17.625T760-860v116q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T700-744v-26H260v580h440v-26q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T760-216v116q0 24.75-17.625 42.375T700-40H260Z"
		/>
	</svg>
);
