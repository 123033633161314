import { Fragment, SyntheticEvent, useState } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as flashActions from 'pkg/actions/flashes';

import * as models from 'pkg/api/models';
import { ResourceLocationUpdatePayload } from 'pkg/api/models/resource_location';
import { crash } from 'pkg/errors/errors';

import EditLocationModal from 'routes/scheduling/locations/modals/Edit';

import Icon from 'components/icon';

import Row from 'components/layout/row';
import * as Input from 'components/form/inputs';

import * as Table from 'design/table';
import * as Context from 'design/context_menu';
import Button from 'design/button';

type Modals = '' | 'edit';
type Selectable = 'single' | 'multi';

interface LocationsRowProps {
	selectedLocations: number[];
	selectable: Selectable;

	select: (locationId: number) => void;

	location: models.resourceLocation.ResourceLocation;

	refresh: () => void;
}

const LocationsRow = ({
	selectedLocations,
	location,
	selectable,
	select,
	refresh,
}: LocationsRowProps) => {
	const [modal, setModal] = useState<Modals>('');

	const hideModal = () => setModal('');

	const showEditModal = () => setModal('edit');

	const handleClick = (e: SyntheticEvent<HTMLDivElement>) => {
		e.stopPropagation();

		select(location.id);
	};

	const handleEditLocation = async (payload: ResourceLocationUpdatePayload) => {
		const [ok] = await models.resourceLocation.update(location, payload);

		if (ok) {
			flashActions.show({
				title: t(`Successfully edited location`),
			});

			refresh();
			hideModal();
			return;
		}

		const err = crash();
		flashActions.show({
			title: err.title,
			message: err.description,
		});
	};

	const onDelete = async () => {
		const ok = await models.resourceLocation.remove(location);

		if (ok) {
			flashActions.show({
				title: t(`Succesfully removed location`),
			});
			refresh();
			return;
		}

		const err = crash();
		flashActions.show({
			title: err.title,
			message: err.description,
		});
	};

	return (
		<Fragment>
			<Table.Row>
				{selectable ? (
					<Table.Cell onClick={handleClick}>
						<Row spacing={styles.spacing._2} autoColumns="auto" align="center">
							<div>
								<Input.Control
									type={selectable === 'single' ? 'radio' : 'checkbox'}
									standalone
									checked={selectedLocations.includes(location.id)}
								/>
							</div>
							<span>{location.title}</span>
						</Row>
					</Table.Cell>
				) : (
					<Table.Cell>{location.title}</Table.Cell>
				)}
				<Table.Cell>
					<Context.Menu
						toggleWith={
							<Context.TableTrigger>
								<Icon name="context-menu" />
							</Context.TableTrigger>
						}>
						<Context.Item onClick={showEditModal}>
							<Context.ItemIcon name="edit" />
							{t('Edit')}
						</Context.Item>
						<Context.ConfirmItem
							onConfirm={onDelete}
							caution
							message={t(`Are you sure you want to remove this location?`)}>
							<Context.ItemIcon name="delete" />
							{t('Delete')}
						</Context.ConfirmItem>
					</Context.Menu>
				</Table.Cell>
			</Table.Row>
			{modal === 'edit' && (
				<EditLocationModal
					location={location}
					onClose={hideModal}
					handleEditLocation={handleEditLocation}
				/>
			)}
		</Fragment>
	);
};

interface LocationsTableProps {
	// Locations to list
	locations: models.resourceLocation.ResourceLocation[];

	// Selected locations ids
	selectedLocations?: number[];

	// Func that returns location id on selected item
	select?: (locationId: number) => void;

	// Func that selects all of the locations
	selectAll?: () => void;

	// Check if table should be selectable and what type of select to display
	selectable?: Selectable;

	// Function to refresh the fetched data
	refresh?: () => Promise<void>;

	// Open empty state modal
	handleOpenModal?: () => void;

	// loading data
	isLoading: boolean;

	// Option to hide the context menu
	hideContextColumn?: boolean;

	// Search query
	search?: string;
}

const LocationsTable = ({
	locations,
	selectable,
	select,
	selectedLocations,
	hideContextColumn = false,
	isLoading = true,
	search,
	handleOpenModal = null,
	refresh,
	selectAll,
}: LocationsTableProps) => {
	const titleContent =
		selectable === 'multi' ? (
			<Row align="center" spacing={styles.spacing._1}>
				<Input.Control
					checked={selectedLocations.length === locations.length}
					type="checkbox"
					standalone
					onChange={selectAll}
				/>
				{t('Title')}
			</Row>
		) : (
			t('Title')
		);

	const columns: Table.HeaderColumn[] = [
		{
			content: titleContent,
		},
		{
			content: '',
			width: 'max-content',
			hide: hideContextColumn,
		},
	];

	return (
		<Table.Table
			columns={columns}
			isLoading={isLoading}
			emptyState={{
				title: t('No locations'),
				content: t('Get started by creating a new location'),
				button: handleOpenModal ? (
					<Button secondary icon="add" onClick={handleOpenModal}>
						{t(`New location`)}
					</Button>
				) : null,
				image: (
					<Icon
						name="location"
						actualSize
						size={4}
						fill={styles.palette.gray[500]}
					/>
				),
			}}>
			{search
				? locations
						.filter((l) =>
							l.title.toLocaleLowerCase().includes(search.toLocaleLowerCase())
						)
						.sort((a, b) => a.title.localeCompare(b.title))
						.map((l) => (
							<LocationsRow
								key={l.id}
								location={l}
								selectable={selectable}
								selectedLocations={selectedLocations}
								select={select}
								refresh={refresh}
							/>
						))
				: locations
						.sort((a, b) => a.title.localeCompare(b.title))
						.map((location) => (
							<LocationsRow
								key={location.id}
								location={location}
								selectable={selectable}
								selectedLocations={selectedLocations}
								select={select}
								refresh={refresh}
							/>
						))}
		</Table.Table>
	);
};

export default LocationsTable;
