import { ReactNode } from 'react';

export const symbol_folder_limited: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-160q-24 0-42-18.5T80-220v-520q0-23 18-41.5t42-18.5h256q12.444 0 23.722 5t19.366 13.088L481-740h339q23 0 41.5 18.5T880-680v460q0 23-18.5 41.5T820-160H140Zm0-60h680v-460H456l-60-60H140v520Zm0 0v-520 520Zm340-70q66 0 113-47t47-113q0-66-47-113t-113-47q-66 0-113 47t-47 113q0 66 47 113t113 47Zm-58-253q13-8 27.5-12.5T480-560q46.2 0 78.1 31.9Q590-496.2 590-450q0 16-4.5 30.5T573-392L422-543Zm58 203q-45 0-77.5-32.5T370-450q0-16 4.5-30.5T387-508l151 151q-13 8-27.5 12.5T480-340Z"
		/>
	</svg>
);
