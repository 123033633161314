import { ReactNode } from 'react';

export const symbol_pivot_table_chart: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M380-640v-200h400q24 0 42 18t18 42v140H380ZM180-120q-24 0-42-18t-18-42v-400h200v460H180Zm-60-520v-140q0-24 18-42t42-18h140v200H120Zm357 436 60 60q9 9 9 21t-9 21q-9 9-21.5 9t-21.5-9L382-213q-9-9-9-21t9-21l112-112q9-9 21.5-9t21.5 9q9 9 9 21.5t-9 21.5l-60 60h122q42 0 69.5-27.5T696-361v-122l-60 60q-9 9-21 9t-21-9q-9-9-9-21.5t9-21.5l111-112q9-9 21-9t21 9l112 112q9 9 9 21t-9 21q-9 9-21.5 9t-21.5-9l-60-59v122q0 66-46 111.5T599-204H477Z"
		/>
	</svg>
);
