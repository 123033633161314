enum radius {
	'_1' = '2px',
	'_2' = '4px',
	'_3' = '6px',
	'_4' = '8px',
	'_5' = '10px',
	'_6' = '14px',
	'_7' = '20px',
	'_8' = '24px',
}

export default radius;
