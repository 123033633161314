import { Fragment, useMemo } from 'react';
import { t } from '@transifex/native';

import { Visibilities, Statuses } from 'pkg/models/form';

import * as flashActions from 'pkg/actions/flashes';

import * as models from 'pkg/api/models';
import * as routes from 'pkg/router/routes';
import DateTime from 'pkg/datetime';
import { useCurrentOrganization } from 'pkg/identity';
import { getTranslatedStatus } from 'pkg/api/models/form';
import copyString from 'pkg/copyString';
import { cssClasses } from 'pkg/css/utils';
import { shareableUrl } from 'pkg/url';

import Icon from 'components/icon';
import Label from 'components/label';

import * as Input from 'components/form/inputs';

import * as Table from 'design/table';
import * as ContextMenu from 'design/context_menu';

import * as css from './styles.css';

interface ActiveFormRowProps {
	groupId: number;
	form: models.form.Form;
	replace: (newRecord: models.form.Form) => void;
	remove: (id: number) => void;
}

const ActiveFormRow: React.FC<React.PropsWithChildren<ActiveFormRowProps>> = ({
	groupId,
	form,
	replace,
	remove,
}) => {
	const org = useCurrentOrganization();

	const createdAt = useMemo(() => {
		return new DateTime(new Date(form.createdAt * 1000));
	}, [form.createdAt]);

	const handleOpenForm = async () => {
		const response = await models.update(form, {
			status: Statuses.Open,
		});
		replace(response[1] as models.form.Form);
	};

	const handleCloseForm = async () => {
		const response = await models.update(form, {
			status: Statuses.Closed,
		});
		replace(response[1] as models.form.Form);
	};

	const handleDraftForm = async () => {
		const response = await models.update(form, {
			status: Statuses.Draft,
		});
		replace(response[1] as models.form.Form);
	};

	const handlePublicForm = async () => {
		const response = await models.update(form, {
			visibility: Visibilities.Public,
		});
		replace(response[1] as models.form.Form);
	};

	const handleOrganizationForm = async () => {
		const response = await models.update(form, {
			visibility: Visibilities.Organization,
		});
		replace(response[1] as models.form.Form);
	};

	const handleUnlistedForm = async () => {
		const response = await models.update(form, {
			visibility: Visibilities.Unlisted,
		});
		replace(response[1] as models.form.Form);
	};

	const handleArchiveForm = async () => {
		await models.destroy(form);
		remove(form.id);
	};

	const handleCopyUrl = () => {
		copyString(
			shareableUrl(routes.Registration.Index(form.group.slug, form.guid))
		);
		flashActions.show({ title: t('URL copied') });
	};

	const handleCopyExternalCategoryUrl = async () => {
		copyString(form.publicCategoryUrl);
		flashActions.show({ title: t('URL copied') });
	};

	const canOpenForm =
		form.maxSubmissions === 0 || form.submissionCount < form.maxSubmissions;

	const contextMenu = (
		<Table.ActionCell>
			<ContextMenu.Menu
				toggleWith={
					<ContextMenu.TableTrigger>
						<Icon name="context-menu" />
					</ContextMenu.TableTrigger>
				}>
				<ContextMenu.LinkItem
					icon="edit"
					href={routes.Registrations.Edit(org.id, groupId, form.id)}>
					{t('Edit')}
				</ContextMenu.LinkItem>
				<ContextMenu.LinkItem
					icon="content_copy"
					href={routes.Registrations.Duplicate(org.id, groupId, form.id)}>
					{t('Duplicate')}
				</ContextMenu.LinkItem>

				{form.submissionCount > 0 && (
					<Fragment>
						<ContextMenu.Divider />
						<ContextMenu.LinkItem
							icon="group"
							href={routes.Registrations.Single(
								org.id,
								groupId,
								form.id,
								'overview'
							)}>
							{t('View submissions')}
						</ContextMenu.LinkItem>
					</Fragment>
				)}
				<ContextMenu.Divider />

				<ContextMenu.LinkItem
					icon="arrow_forward"
					href={routes.Registration.Index(form.group.slug, form.guid)}>
					{t('Visit registration')}
				</ContextMenu.LinkItem>

				<ContextMenu.Divider />

				<ContextMenu.Label>{t('Share')}</ContextMenu.Label>
				<ContextMenu.Item
					icon="link"
					onClick={handleCopyUrl}
					closeOnClick={false}>
					{t('Copy registration URL')}
				</ContextMenu.Item>
				{form.formCategoryId && (
					<Fragment>
						<ContextMenu.Item
							icon="link"
							onClick={handleCopyExternalCategoryUrl}
							closeOnClick={false}>
							{t('Copy category URL')}
						</ContextMenu.Item>
					</Fragment>
				)}
				<ContextMenu.Divider />
				<ContextMenu.Pane>
					<Input.Group label={t('Status')}>
						<Input.Select
							small
							value={form.status}
							onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
								const newStatus = e.target.value;
								if (newStatus === Statuses.Open && canOpenForm) {
									handleOpenForm();
								} else if (newStatus === Statuses.Closed) {
									handleCloseForm();
								} else if (newStatus === Statuses.Draft) {
									handleDraftForm();
								}
							}}>
							<option value={Statuses.Open} disabled={!canOpenForm}>
								{t('Open')}
							</option>
							<option value={Statuses.Closed}>{t('Closed')}</option>
							<option value={Statuses.Draft}>{t('Draft')}</option>
						</Input.Select>
					</Input.Group>
				</ContextMenu.Pane>

				<ContextMenu.Pane padding="0 var(--spacing-5) var(--spacing-5) var(--spacing-5)">
					<Input.Group label={t('Visibility')}>
						<Input.Select
							small
							value={form.visibility}
							onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
								const newVisibility = e.target.value;
								if (newVisibility === Visibilities.Public) {
									handlePublicForm();
								} else if (newVisibility === Visibilities.Organization) {
									handleOrganizationForm();
								} else if (newVisibility === Visibilities.Unlisted) {
									handleUnlistedForm();
								}
							}}>
							<option value={Visibilities.Public}>{t('Public')}</option>
							<option value={Visibilities.Organization}>
								{t('Club lobby')}
							</option>
							<option value={Visibilities.Unlisted}>{t('Unlisted')}</option>
						</Input.Select>
					</Input.Group>
				</ContextMenu.Pane>
				<ContextMenu.Divider />
				<ContextMenu.ConfirmItem
					icon="archive"
					onConfirm={handleArchiveForm}
					confirmLabel={t('Archive')}
					caution
					message={t('Are you sure you want to archive the form?')}>
					{t('Archive')}
				</ContextMenu.ConfirmItem>
			</ContextMenu.Menu>
		</Table.ActionCell>
	);

	const getStatusColor = () => {
		if (form.status === models.form.Statuses.Draft) {
			return 'gray';
		}

		if (form.status === models.form.Statuses.Closed) {
			return 'red';
		}

		return 'green';
	};

	const visibilityLabels = {
		public: t('Public'),
		organization: t('Club lobby'),
		unlisted: t('Unlisted'),
	};

	const shouldLinkCategory = form.formCategory;

	let categoryCellLink = routes.Registrations.Single(
		org.id,
		groupId,
		form.id,
		'overview'
	);

	if (shouldLinkCategory) {
		categoryCellLink = routes.Registrations.Category(
			org.id,
			form.formCategoryId
		);
	}

	return (
		<Table.Row
			href={routes.Registrations.Single(org.id, groupId, form.id, 'overview')}>
			<Table.LinkCell>{form.title}</Table.LinkCell>
			<Table.LinkCell href={categoryCellLink}>
				<span className={cssClasses(shouldLinkCategory ? css.link : '')}>
					{form.formCategory ? form.formCategory.title : t(`Uncategorized`)}
				</span>
			</Table.LinkCell>
			<Table.LinkCell>{visibilityLabels[form.visibility]}</Table.LinkCell>
			<Table.LinkCell>
				<Label color={getStatusColor()}>
					{getTranslatedStatus(form.status)}
				</Label>
			</Table.LinkCell>
			<Table.LinkCell>
				{form.submissionCount}
				{`${form.maxSubmissions ? '/' + form.maxSubmissions : ''}`}
			</Table.LinkCell>
			<Table.LinkCell>{createdAt.toLocaleDateString()}</Table.LinkCell>
			{contextMenu}
		</Table.Row>
	);
};

export default ActiveFormRow;
