import * as ls from 'pkg/local_storage';

import { ApplicationState } from 'components/application/state';

const defaultDrawerState: boolean =
	window.matchMedia('(max-width: 950px)').matches;

export function getDefaultDrawerState(): boolean {
	const localStorageItem = ls.getItem(ls.LocalStorageKeys.CollapsedDrawer);

	if (!localStorageItem) {
		return defaultDrawerState;
	}

	return (localStorageItem ?? 'false') === 'true';
}

export function toggleDrawer(appState: ApplicationState) {
	if (appState.collapsedDrawer) {
		expandDrawer(appState, true);
	} else {
		collapseDrawer(appState, true);
	}
}

export function collapseDrawer(
	appState: Partial<ApplicationState>,
	manually: boolean
) {
	const collapsed = appState.collapsedDrawer;

	if (collapsed) {
		return;
	}

	if (manually) {
		ls.setItem(ls.LocalStorageKeys.CollapsedDrawer, 'true');
	}

	appState.setCollapsedDrawer(true);

	return;
}

export function expandDrawer(
	appState: Partial<ApplicationState>,
	manually: boolean
) {
	const collapsed = appState.collapsedDrawer;
	const collapsedDrawer = ls.getItem(ls.LocalStorageKeys.CollapsedDrawer);

	if (!collapsed || (collapsedDrawer === 'true' && !manually)) {
		return;
	}

	if (manually) {
		ls.setItem(ls.LocalStorageKeys.CollapsedDrawer, 'false');
	}

	appState.setCollapsedDrawer(false);

	return;
}
