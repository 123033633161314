import { normalize } from 'normalizr';
import { batch } from 'react-redux';

import SessionItem from 'pkg/models/session_item';

import { triggerError } from 'pkg/actions/app';
import * as service from 'pkg/actions/services/sessions';
import { SessionItems } from 'pkg/actions/action-types';
import { actionSetSessionMode } from 'pkg/actions/sessions';
import { actionSetSessionBlockMode } from 'pkg/actions/session_blocks';

import * as selectors from 'pkg/selectors';
import store from 'pkg/store/createStore';

const { SET_ITEMS, DELETE_ITEM, SET_MODE, UNSET_MODE } = SessionItems;

const actionSetItems = (payload) => ({
	type: SET_ITEMS,
	payload,
});

const actionDeleteItem = (itemId) => ({
	type: DELETE_ITEM,
	itemId,
});

export const actionSetSessionItemMode = (itemId, payload) => ({
	type: SET_MODE,
	itemId,
	payload,
});

export const actionUnsetSessionItemMode = (itemId) => ({
	type: UNSET_MODE,
	itemId,
});

/* Actions */

export const createSessionItem =
	(organizationId, sessionId, blockId, payload) => async (dispatch) => {
		payload = Object.assign({}, payload, {
			trainingSessionBlockId: blockId,
		});

		let isNew = true;
		let result = payload;

		const data = normalize(result, SessionItem.normalizr());

		batch(() => {
			const hasChanges = true;

			dispatch(actionSetItems(data));
			dispatch(actionSetSessionItemMode(result.id, { isNew }));
			dispatch(actionSetSessionMode(sessionId, { hasChanges }));
			dispatch(actionSetSessionBlockMode(blockId, { hasChanges }));
		});

		return result;
	};

export const deleteSessionItem =
	(organizationId, itemId) => async (dispatch, getState) => {
		const state = getState().sessionItems;
		const item = state.getIn(['entities', itemId]);

		batch(() => {
			if (item) {
				const sessionId = selectors.sessionBlocks
					.find(getState(), { blockId: item.trainingSessionBlockId })
					.get('trainingSessionId');
				dispatch(actionSetSessionMode(sessionId, { hasChanges: true }));
			}

			dispatch(
				actionSetSessionItemMode(itemId, {
					isDiscarded: true,
				})
			);
			dispatch(actionDeleteItem(itemId));
		});
	};

export const deleteSessionItemsBySessionId = (sessionId) => (dispatch) => {
	const items = selectors.sessionItems.findAllBySession(store.getState(), {
		sessionId,
	});

	batch(() => {
		items.forEach((item) => {
			dispatch(actionDeleteItem(item.id));
		});
	});
};

export const swapSessionItemOrder = (source, target) => (dispatch) => {
	const sessionId = source.get('trainingSessionId');

	const sourceOrder = source.get('order');
	const targetOrder = target.get('order');

	source = source.set('order', targetOrder).toJS();
	target = target.set('order', sourceOrder).toJS();

	const data = normalize([source, target], [SessionItem.normalizr()]);

	batch(() => {
		const hasChanges = true;

		dispatch(actionSetItems(data));
		dispatch(actionSetSessionMode(sessionId, { hasChanges }));
		dispatch(actionSetSessionItemMode(source.id, { hasChanges }));
		dispatch(actionSetSessionItemMode(target.id, { hasChanges }));
	});
};

export const setSessionItemUsers =
	(organizationId, itemId, users) => async (dispatch) => {
		const request = await service.setSessionItemUsers(organizationId, itemId, {
			users,
		});

		if (!request.ok) {
			dispatch(triggerError(request));
			return;
		}
	};
