import styled from 'styled-components';

import * as breakpoints from 'pkg/config/breakpoints';

import Icon from 'components/icon';

const Wrapper = styled.div`
	display: flex;
	background: var(--palette-white);
	border: 1px solid var(--palette-gray-300);

	@media (max-width: ${breakpoints.toMedium}px) {
		border-width: 1px 0 0;
	}

	@media (min-width: ${breakpoints.fromMedium}px) {
		border-width: 0 0 1px 1px;
	}

	.close {
		align-self: center;
		margin: 0 10px 0 auto;

		.mobile-closed & {
			transform: rotate(180deg) scale(2);
		}

		@media (min-width: ${breakpoints.fromMedium}px) {
			display: none;
		}
	}
`;

const Tab = styled.div`
	margin: 5px 0 0 5px;
	padding: 5px;
	border-bottom: 2px solid transparent;
	cursor: pointer;
	font-weight: var(--font-weight-semibold);
	color: var(--palette-gray-500);

	&.current {
		color: var(--palette-black);
		border-color: var(--palette-black);
	}
`;

export default function Tabs({
	className,
	tabs,
	current,
	openTab,
	toggleMobile,
}: any) {
	const tabArray = Object.entries(tabs).filter(([, label]) => label);

	if (!tabs[current]) current = tabArray[0][0];

	return (
		<Wrapper className={className}>
			{tabArray.map(([id, label]: any) => (
				<Tab
					key={id}
					className={current === id ? 'current' : ''}
					onClick={() => {
						openTab(id);
					}}>
					{label}
				</Tab>
			))}
			<Icon
				className="close"
				name="chevron-down"
				size={2}
				onClick={toggleMobile}
			/>
		</Wrapper>
	);
}
