import { t } from '@transifex/native';
import { useContext } from 'react';

import * as Context from 'design/context_menu';
import Button from 'design/button';

interface ApplyProps {
	disable: boolean;

	onApply: () => void;
}

export function Apply({ onApply, disable }: ApplyProps): JSX.Element {
	const contextMenuContext = useContext(Context.MenuContext);

	const handleClick = () => {
		onApply();
		contextMenuContext.close();
	};

	return (
		<Button
			primary
			onClick={handleClick}
			testid="filters.apply"
			disabled={disable}>
			{t('Apply')}
		</Button>
	);
}
