import styled, { css } from 'styled-components';

import Button from 'design/button';

const RecordButton = styled(Button)`
	background: var(--palette-red-500);
	color: var(--palette-white);
	transition: box-shadow 150ms ease-in-out;

	@media (hover: hover) {
		&:hover:not([disabled]) {
			box-shadow: var(--palette-red-200) 0 0 0 4px;
		}
	}

	&:active {
		background: var(--palette-red-400);
		box-shadow: none;
	}

	${({ theme }) =>
		theme.darkMode &&
		css`
			background: var(--palette-red-400);
			transition: box-shadow 150ms ease-in-out;

			@media (hover: hover) {
				&:hover:not([disabled]) {
					box-shadow: var(--palette-gray-600) 0 0 0 4px;
				}
			}
		`}
`;

export default RecordButton;
