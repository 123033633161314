import { isAndroid } from 'pkg/platform';

export default function copyString(string: string): void {
	const copyString = () => {
		const textArea = document.createElement('textarea');

		textArea.value = string;

		textArea.style.fontSize = '12pt'; /* Prevent zooming on iOS */
		textArea.style.position = 'fixed';
		textArea.style.top = '0';
		textArea.style.left = '0';
		textArea.setAttribute('readonly', '');

		document.body.appendChild(textArea);

		textArea.focus();
		textArea.select();
		textArea.setSelectionRange(0, string.length); /* For some mobile devices */

		document.execCommand('copy');

		document.body.removeChild(textArea);
	};

	if (!isAndroid() && navigator.clipboard) {
		navigator.clipboard.writeText(string);
	} else {
		copyString();
	}
}
