import { ReactNode } from 'react';

export const symbol_transfer_within_a_station: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m132-76 107-550-98 43v104q0 13-8.5 21.5T111-449q-13 0-21.5-8.5T81-479v-124q0-9 5-16.5T99-631l170-73q27-12 58.5-6.5T375-679l42 66q24 38 60.5 66t83.5 36q12 2 20 11t8 21q0 13-9.5 21.5T558-451q-51-6-98-34.5T377-568l-39 153 79 74q5 5 7.5 10.5T427-319v249q0 13-8.5 21.5T397-40q-13 0-21.5-8.5T367-70v-209l-104-99-71 314q-2 11-10.5 17.5T162-40q-14 0-23.5-11T132-76Zm243-678q-30 0-51.5-21.5T302-827q0-30 21.5-51.5T375-900q30 0 51.5 21.5T448-827q0 30-21.5 51.5T375-754Zm410 643H585q-11 0-18-7t-7-18q0-11 7-18t18-7h200l-18-18q-8-8-8-17.5t8-17.5q8-8 17.5-8t17.5 8l57 57q9 9 9 21t-9 21l-57 57q-8 8-17 7.5T768-59q-8-8-8-17.5t8-17.5l17-17ZM655-287l18 18q8 8 8 17t-8 17q-8 8-17.5 8t-17.5-8l-57-56q-9-9-9-21t9-21l57-57q8-8 17.5-7.5T673-389q8 8 8 17t-8 17l-18 18h200q11 0 18 7t7 18q0 11-7 18t-18 7H655Z"
		/>
	</svg>
);
