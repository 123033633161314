import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCollection } from 'pkg/api/use_collection';
import { UserAddress } from 'pkg/api/models/user_address';

import DataRow from 'containers/user/profile/info-contact/DataRow';

import SectionTitle from 'components/SectionTitle';
import * as Card from 'components/Card';

import Column from 'components/layout/column';
import UserAddressDataList from 'components/user-address/DataList';

interface ContactInformationProps {
	user: models.user.User;
}

const BillingAddressInformation = ({ user }: ContactInformationProps) => {
	const { records } = useCollection(endpoints.UserAddress.Index(), {
		queryParams: new URLSearchParams({ user_id: user.id.toString() }),
	});

	if (records.length === 0) return null;

	return (
		<Column>
			<SectionTitle icon="credit_card">{t('Billing address')}</SectionTitle>
			{records.map((ua: UserAddress) => (
				<Card.Base $noBorder key={ua.id}>
					<Card.Body>
						<UserAddressDataList userAddress={ua} item={DataRow} />
					</Card.Body>
				</Card.Base>
			))}
		</Column>
	);
};

export default BillingAddressInformation;
