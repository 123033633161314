import { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { t } from '@transifex/native';

import * as breakpoints from 'pkg/config/breakpoints';

import { fetchSingleRating, addSingleUserRating } from 'pkg/actions/ratings';
import { fetchRatingItems } from 'pkg/actions/rating_items';
import { deprecatedFetchPositions } from 'pkg/actions/positions';
import { forAccountHeaders } from 'pkg/actions/utils';

import * as sdk from 'pkg/core/sdk';
import { replaceState } from 'pkg/router/state';
import * as routes from 'pkg/router/routes';
import { translatedRatingItemCategory } from 'pkg/api/models/user_rating';

import * as Card from 'components/Card';

import { Spinner } from 'components/loaders/spinner';

import Button from 'design/button';

import Item from './form/Item.jsx';
import Options from './form/Options.jsx';
import ItemCategory from './form/ItemCategory.jsx';

const StyledSpinner = styled(Spinner)``;

const FormActions = styled(Card.Footer)`
	${StyledSpinner} {
		margin: 0 0 0 auto;
	}

	button {
		margin-left: 10px;
	}
`;

const RatingItems = styled.div`
	display: grid;
	margin-top: 15px;
	grid-gap: 15px;
	grid-template-columns: 1fr;
	align-items: start;
	align-content: start;

	@media ${breakpoints.small} {
		margin-top: 0;
		display: block;
	}
`;

class Form extends Component {
	_mounted = false;

	state = {
		items: [],
		showErrors: false,
		isLoading: false,
	};

	constructor(props) {
		super(props);

		this.state = {
			showErrors: false,
			isLoading: false,
		};
	}

	async componentDidMount() {
		this._mounted = true;
		this.setState({ isLoading: true });

		if (!this.props.ratingItems.length) {
			await this.props.fetchRatingItems(this.props.forAccount);
		}

		if (!this.props.positions.length) {
			await this.props.fetchPositions(this.props.forAccount);
		}

		if (!this.props.rating?.ratingItems && this.props.ratingId) {
			await this.props.fetchSingleRating(
				this.props.ratingId,
				this.props.forAccount
			);
		}

		if (this._mounted) {
			this.setState({
				isLoading: false,
				position: this.props.rating?.suggestedPositionId
					? this.props.rating.suggestedPositionId
					: this.props.positions[0].id,
				foot: this.props.rating?.primaryFoot
					? this.props.rating.primaryFoot
					: 'right',
				maturityAdjustment: this.props.rating?.maturityAdjustment
					? this.props.rating.maturityAdjustment
					: 0,
				items: this.items,
			});
		}
	}

	componentWillUnmount() {
		this._mounted = false;
	}

	get items() {
		const items = {};

		this.props.ratingItems.forEach((item) => {
			items[item.id] = 0;
		});

		if (this.props.rating && this.props.rating.ratingItems) {
			this.props.rating.ratingItems.forEach((item) => {
				items[item.ratingItemId] = item.value / 10;
			});
		}

		return items;
	}

	get sortedItems() {
		const _sortedItems = {};
		this.props.ratingItems.forEach((item) => {
			if (!_sortedItems[item.categorySlug]) {
				_sortedItems[item.categorySlug] = [];
			}

			_sortedItems[item.categorySlug].push(item);
		});

		return _sortedItems;
	}

	get categoryItems() {
		const items = this.state.items;
		const _categories = {};
		const _sortedItems = this.sortedItems;

		if (!items) {
			return _categories;
		}

		for (let n in _sortedItems) {
			let categoryItems = [];
			let categoryTotal = 0;
			let itemIds = [];

			for (let item of _sortedItems[n]) {
				categoryItems.push(item);
				categoryTotal += parseFloat(items[item.id]);
				itemIds.push(item.id);
			}

			_categories[n] = {
				categoryItems,
				categoryTotal,
				itemIds,
			};
		}

		return _categories;
	}

	get ratingItems() {
		let categories = {};
		const _categories = this.categoryItems;
		for (let key in _categories) {
			const { categoryItems, itemIds } = _categories[key];
			const items = categoryItems.map((item) => (
				<Item
					item={item}
					onInput={this.setItemValue}
					showErrors={this.state.showErrors}
					value={this.state.items[item.id]}
					key={`${key}-rating-item-${item.id}`}
				/>
			));

			const categoryTotal = categoryItems.reduce(
				(sum, item) => sum + this.state.items[item.id],
				0
			);

			const itemCategoryProps = {
				label: translatedRatingItemCategory(key),
				key: `item-category-${key}`,
				onInput: this.setItemsByAverage,
				average: categoryTotal / items.length,
				itemIds: itemIds,
				slug: key,
				borderless:
					this.position?.slug === 'gk'
						? key === 'goalkeeping'
						: key === 'heading-skills',
			};

			categories[key] = (
				<ItemCategory {...itemCategoryProps}>{items}</ItemCategory>
			);
		}

		let printCategories = [];

		for (let category in categories) {
			if (category === 'goalkeeping') continue;

			printCategories.push(categories[category]);
		}

		printCategories.sort(
			(a, b) => a.props.itemIds.length - b.props.itemIds.length
		);

		if (this.position?.slug === 'gk') {
			printCategories.unshift(categories['goalkeeping']);
		}

		return <RatingItems>{printCategories}</RatingItems>;
	}

	save = async (e, isDraft = true) => {
		e.preventDefault();
		let requestObject = {};
		requestObject.suggestedPositionId = this.state.position;
		requestObject.maturityAdjustment = this.state.maturityAdjustment;
		requestObject.primaryFoot = this.state.foot;
		requestObject.isPrimaryRating = isDraft ? false : true;

		if (parseInt(this.props.groupId) !== 0) {
			requestObject.groupId = parseInt(this.props.groupId);
		}

		requestObject.ratingItems = [];
		for (let n in this.state.items) {
			if (!this.itemIsVisible(n)) {
				continue;
			}

			let itemValue = parseInt(Math.round(this.state.items[n] * 10), 10);

			if (itemValue === 0) {
				this.setState({
					showErrors: true,
				});

				return;
			}

			requestObject.ratingItems.push({
				ratingItemId: parseInt(n, 10),
				value: itemValue,
			});
		}

		this.setState({ isLoading: true });

		const headers = forAccountHeaders(this.props.forAccount);
		const response = await sdk.post(
			'users/:userId/ratings',
			{ userId: this.props.userId },
			requestObject,
			headers
		);
		const result = await response.json();

		this.setState({ isLoading: false });

		if (typeof this.props.onSave === 'function') {
			this.props.onSave();
		}

		this.props.addSingleUserRating(result, result.userId);

		replaceState(
			routes.User.Profile.PositionMatch(
				this.props.organizationId,
				this.props.groupId,
				result.userId,
				this.props.rating.id
			)
		);
	};

	setItemValues(inputItems) {
		this.setState({
			items: inputItems,
		});
	}

	setItemValue = (itemId, value) => {
		let items = this.state.items;
		items[itemId] = value;
		this.setItemValues(items);
	};

	setItemsByAverage = (average, itemIds) => {
		let allItems = this.state.items;
		let items = {};
		let total = 0;

		for (let itemId in allItems) {
			itemId = parseInt(itemId, 10);
			if (itemIds.indexOf(itemId) === -1) {
				continue;
			}

			items[itemId] = parseFloat(allItems[itemId]);
			total += items[itemId];
		}

		let oldAvg = total / itemIds.length;
		let change = oldAvg === 0 ? 1 : average / oldAvg;
		let pot = 0;
		let unfullfilledIds = [];

		for (let n in items) {
			if (total === 0) {
				items[n] = average;
			} else if (average == 10 || (items[n] == 10 && change > 1)) {
				items[n] = parseFloat(10);
			} else {
				items[n] = Math.ceil(items[n] * change * 10) / 10;
			}

			// All values above 10 go into a common pot to be divided amongst fields that hasn't hit max.
			if (items[n] >= 10) {
				pot += parseFloat(items[n] - 10);
				items[n] = parseFloat(10);
			} else {
				unfullfilledIds.push(n);
			}

			items[n] = parseFloat(parseFloat(items[n]).toFixed(1));
		}

		let fillRemaining = (ids, pot) => {
			let newPot = 0;
			let newIds = [];

			ids.forEach((id) => {
				items[id] =
					Math.ceil(
						(parseFloat(items[id]) + parseFloat(pot / ids.length)) * 10
					) / 10;

				if (items[id] >= 10) {
					items[id] = parseFloat(10);
					newPot += parseFloat(items[id] - 10);
				} else {
					newIds.push(id);
				}

				items[id] = parseFloat(parseFloat(items[id]).toFixed(1));
			});

			if (newPot > 0 && newIds.length > 0) {
				fillRemaining(newIds, newPot);
			}
		};

		fillRemaining(unfullfilledIds, pot);

		for (let n in items) {
			allItems[n] = items[n];
		}

		this.setItemValues(allItems);
	};

	getItem(id) {
		let item = this.props.ratingItems.toList().find((i) => {
			return i.id === parseInt(id, 10);
		});

		return item;
	}

	itemIsVisible(id) {
		if (
			this.getItem(id)?.categorySlug === 'goalkeeping' &&
			this.position?.slug !== 'gk'
		) {
			return false;
		}

		return true;
	}

	get position() {
		let pos = this.props.positions
			.filter((p) => {
				return p.id === parseInt(this.state.position);
			})
			.shift();

		return pos;
	}

	setOption = (key, value) => {
		let validKeys = ['position', 'foot', 'maturityAdjustment'];

		if (validKeys.indexOf(key) === -1) {
			return;
		}

		let stateObj = {};
		stateObj[key] = value;
		this.setState(stateObj);
	};

	render() {
		let ratingItems = this.props.ratingItems;

		if (ratingItems.length === 0) {
			// @todo Render an error
			return null;
		}

		let actions = [
			<Button primary key="action-0" onClick={this.save}>
				{t('Save')}
			</Button>,
		];

		if (this.props.groupId !== null && this.props.isAdminOrStaff) {
			actions = [
				<Button large key="action-0" onClick={this.save}>
					{t('Save as draft')}
				</Button>,
				<Button
					primary
					large
					key="action-1"
					onClick={(e) => {
						this.save(e, false);
					}}
					testid="step_modal.next">
					{t('Save')}
				</Button>,
			];
		}

		if (this.state.isLoading) {
			actions = [<StyledSpinner key="saving" size="50px" />];
		}

		const items = [
			<Card.Body $flex key="rating-items">
				<Options
					onOptionChange={this.setOption}
					position={this.state.position}
					positionOptions={this.props.positions}
				/>

				{this.ratingItems}
			</Card.Body>,

			<Card.Divider key="divider" />,

			<FormActions key="form-actions">{actions}</FormActions>,
		];

		return items;
	}
}

const mapStateToProps = () => {
	return (state, props) => {
		return {
			rating: state.ratings.entities[props.ratingId],
			positions: state.positions.entities,
			ratingItems: state.ratingItems.entities,
		};
	};
};

const actions = {
	fetchSingleRating,
	fetchPositions: deprecatedFetchPositions,
	fetchRatingItems,
	addSingleUserRating,
};

export default connect(mapStateToProps, actions)(Form);
