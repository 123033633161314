import { ReactNode } from 'react';

export const symbol_cleaning: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M418-785v-35h-28q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T390-880h215q16 0 25.5 9.5T640-845v33q0 4.167-.5 7.083Q639-802 637-799l-37 74H493v-45l-79.934 84.868Q412-684 397-680q-8.571 0-13.286-7-4.714-7-.714-15l35-83ZM380-80q-24.75 0-42.375-17.625T320-140v-218q0-7.632 1.778-14.882T328-387l148-267q8.056-14.419 21.606-22.709Q511.155-685 528-685h82q12.75 0 21.375 8.625T640-655v515q0 24.75-17.625 42.375T580-80H380Zm0-60h200v-485h-52L380-358v218Zm0 0h200-200Z"
		/>
	</svg>
);
