import { ReactNode } from 'react';

export const symbol_liquor: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M198-140v-141q-35-10-56.5-38.5T120-386v-304q0-13 8.5-21.5T150-720h156q13 0 21.5 8.5T336-690v304q0 35-21.5 65T258-281v141h48q13 0 21.5 8.5T336-110q0 13-8.5 21.5T306-80H150q-13 0-21.5-8.5T120-110q0-13 8.5-21.5T150-140h48Zm-18-359h96v-161h-96v161Zm48 161q19 0 33.5-14.5T276-386v-53h-96v53q0 19 14.5 33.5T228-338ZM520-80q-24 0-42-18t-18-42v-408q0-20 11-32t31-21l38-17q24-11 35-27t11-39v-161q0-16 9.5-25.5T621-880h94q16 0 25.5 9.5T750-845v161q0 23 13.5 39t37.5 27l38 17q19 8 30 20.5t11 32.5v408q0 24-18 42t-42 18H520Zm126-696h44v-44h-44v44ZM520-465h300v-83l-42-16q-40-15-64-49t-24-75v-28h-44v28q0 41-22.5 72T563-568l-43 20v83Zm0 325h300v-103H520v103Zm0-163h300v-102H520v102ZM228-439Zm292 136v-102 102Z"
		/>
	</svg>
);
