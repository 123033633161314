export default {
	name: 'nav-formation',
	objects: [
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M3.73353 9.47481V14.8058H4.4553C4.67075 14.8058 4.87028 14.7377 5.03353 14.6218C5.28876 14.4406 5.4553 14.1427 5.4553 13.8058V10.4748C5.4553 10.138 5.28876 9.84003 5.03353 9.65883C4.87028 9.54294 4.67075 9.47481 4.4553 9.47481H3.73353ZM3.88551 16.1058H4.4553C5.72556 16.1058 6.7553 15.0761 6.7553 13.8058V10.4748C6.7553 9.20455 5.72556 8.17481 4.4553 8.17481H3.88551V7.53955C3.88551 7.15295 4.19891 6.83955 4.58551 6.83955H19.4145C19.8011 6.83955 20.1145 7.15295 20.1145 7.53955V8.17476H19.6118C18.3416 8.17476 17.3118 9.20451 17.3118 10.4748V13.8058C17.3118 15.076 18.3416 16.1058 19.6118 16.1058H20.1145V16.9586C20.1145 17.3452 19.8011 17.6586 19.4145 17.6586H4.58551C4.19891 17.6586 3.88551 17.3452 3.88551 16.9586V16.1058ZM2.58551 7.53955C2.58551 6.43498 3.48094 5.53955 4.58551 5.53955H19.4145C20.5191 5.53955 21.4145 6.43498 21.4145 7.53955V16.9586C21.4145 18.0631 20.5191 18.9586 19.4145 18.9586H4.58551C3.48094 18.9586 2.58551 18.0631 2.58551 16.9586V7.53955ZM20.3336 14.8058V9.47476H19.6118C19.3964 9.47476 19.1969 9.5429 19.0336 9.65879C18.7784 9.83998 18.6118 10.1379 18.6118 10.4748V13.8058C18.6118 14.1426 18.7784 14.4406 19.0336 14.6218C19.1969 14.7376 19.3964 14.8058 19.6118 14.8058H20.3336Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M11.35 18.37V6.12811H12.65V18.37H11.35Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M12.0001 14.1269C13.0372 14.1269 13.878 13.2861 13.878 12.2489C13.878 11.2118 13.0372 10.371 12.0001 10.371C10.9629 10.371 10.1221 11.2118 10.1221 12.2489C10.1221 13.2861 10.9629 14.1269 12.0001 14.1269ZM12.0001 15.4269C13.7552 15.4269 15.178 14.004 15.178 12.2489C15.178 10.4938 13.7552 9.07098 12.0001 9.07098C10.2449 9.07098 8.82214 10.4938 8.82214 12.2489C8.82214 14.004 10.2449 15.4269 12.0001 15.4269Z',
			},
		},
	],
};
