import { ReactNode } from 'react';

export const symbol_artist: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M692-160q-47 0-76-29t-29-76q0-47 29-76t76-29q17 0 31 4t25 12v-176q0-12.75 8.625-21.375T778-560h72q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-500h-53v235q0 47-29 76t-76 29Zm-582 0q-12.75 0-21.375-8.625T80-190v-64q0-32 17-61.5t51-44.5q75-33 133.392-46.5Q339.784-420 400-420q35 0 68.5 4.5T537-402q16 5 21 15.379 5 10.38 3 21.5Q559-354 550-347q-9 7-22 3-29-8-61.214-12-32.215-4-66.786-4-58 0-109 11t-119 43q-16 8-24 22.5t-8 29.5v34h372q15 0 22.5 9.318t7.5 20.5q0 11.182-7.75 20.682Q526.5-160 511-160H110Zm290-321q-66 0-108-42t-42-108q0-66 42-108t108-42q66 0 108 42t42 108q0 66-42 108t-108 42Zm0-60q39 0 64.5-25.5T490-631q0-39-25.5-64.5T400-721q-39 0-64.5 25.5T310-631q0 39 25.5 64.5T400-541Zm0-90Zm0 411Z"
		/>
	</svg>
);
