import { ReactNode } from 'react';

export const symbol_arrow_cool_down: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M479.867-83Q474-83 469-85q-5-2-10-7L221-330q-9-9-9-21.196 0-12.195 9-20.804 8.8-9 20.9-9 12.1 0 21.1 9l187 187v-276q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-461v276l187-187q9-9 21.1-9 12.1 0 20.9 9 9 9 9 21t-9 21L501-92q-5 5-10.133 7-5.134 2-11 2Zm-.042-468Q467-551 458.5-559.625T450-581v-80q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-661v81q0 13-8.675 21-8.676 8-21.5 8Zm0-200Q467-751 458.5-759.625T450-781v-60q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-841v61q0 13-8.675 21-8.676 8-21.5 8Z"
		/>
	</svg>
);
