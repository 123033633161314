import styled from 'styled-components';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import * as routes from 'pkg/router/routes';
import { useCurrentOrganization } from 'pkg/identity';

import Avatar from 'components/avatar';

import Row from 'components/layout/row';

import * as ContextMenu from 'design/context_menu';
import Button from 'design/button';

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	padding: var(--spacing-7) var(--spacing-5) 0;
`;

interface ShowMonitoredChatsButtonProps {
	wards: models.accountRelation.AccountRelation[];
}

export const ShowMonitoredChatsButton = ({
	wards,
}: ShowMonitoredChatsButtonProps) => {
	const org = useCurrentOrganization();

	if (!wards.length) {
		return null;
	}

	// One child: button links to monitored chats
	if (wards.length === 1) {
		const ward = wards[0];

		return (
			<ButtonWrapper>
				<Button
					block
					transparent
					icon="visibility"
					label={t('Show monitored chats')}
					href={routes.Chat.Monitored.Index(org.id, ward.targetAccountId)}
				/>
			</ButtonWrapper>
		);
	}

	// Multiple children: button triggers context menu with all children
	const actions = wards.map((ward: models.accountRelation.AccountRelation) => (
		<ContextMenu.LinkItem
			href={routes.Chat.Monitored.Index(org.id, ward.targetAccountId)}
			key={ward.targetAccountId}>
			<Row align="center" columns="auto 1fr">
				<Avatar account={ward.targetAccount} size={30} />
				<span>{models.account.fullName(ward.targetAccount)}</span>
			</Row>
		</ContextMenu.LinkItem>
	));

	return (
		<ButtonWrapper>
			<ContextMenu.Menu
				fullWidth
				toggleWith={
					<Button
						block
						transparent
						icon="visibility"
						label={t('Show monitored chats')}
					/>
				}>
				<ContextMenu.Label>{t('Select user')}</ContextMenu.Label>
				{actions}
			</ContextMenu.Menu>
		</ButtonWrapper>
	);
};
