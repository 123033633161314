import { useEffect, useRef, useState } from 'react';

interface IntersectionObserverProps extends IntersectionObserverInit {
	callback?: (entry: [IntersectionObserverEntry]) => void;
}

export default function useIntersectionObserver({
	callback,
	threshold = 0,
	root = null,
	rootMargin = '0px',
}: IntersectionObserverProps = {}): [
	(targets: Element[]) => void,
	IntersectionObserver,
] {
	const [targets, setTargets] = useState<Element[]>([]);

	const observer = useRef<IntersectionObserver>(null);

	useEffect(() => {
		if (targets.length > 0) {
			if (!observer.current) {
				observer.current = new IntersectionObserver(callback, {
					threshold,
					root,
					rootMargin,
				});
			}

			targets.forEach((target: Element) => {
				if (target) {
					observer.current.observe(target);
				}
			});

			return () => observer.current.disconnect();
		}
	}, [targets]);

	return [setTargets, observer.current];
}
