import { MutableRefObject, ReactNode } from 'react';
import { useTheme } from 'styled-components';

import { cssClasses } from 'pkg/css/utils';

import { ScrollSpy } from 'components/ScrollSpy';

import * as css from './styles.css';

export interface MenuWrapperProps {
	className?: string;
	maxWidth: string;
	maxHeight: string;
	index?: number;
	forwardedRef?: MutableRefObject<HTMLDivElement>;
	scrollSpy?: boolean;
	children?: ReactNode;
	id?: string;
	position?: menuPositionOrigin;
	offsetHorizontal?: number;
	offsetVertical?: number;
}

enum menuPositionOrigin {
	TopRight,
	TopLeft,
	BottomRight,
	BottomLeft,
}

export function MenuWrapper({
	index,
	className,
	forwardedRef,
	maxWidth,
	maxHeight,
	children,
	id,
	position,
	offsetHorizontal,
	offsetVertical,
	scrollSpy,
}: MenuWrapperProps) {
	const theme = useTheme();
	const styles: React.CSSProperties = { zIndex: index + 1 };

	if (maxWidth) {
		styles.maxWidth = maxWidth;
	}
	if (maxHeight) {
		styles.maxHeight = maxHeight;
	}

	const classes: string[] = [css.menuWrapper, className];
	if (theme.animationDirection === 'in') {
		classes.push(css.animatingIn);
	} else {
		classes.push(css.animatingOut);
	}

	switch (position) {
		case menuPositionOrigin.TopLeft:
			styles.transformOrigin = 'top left';
			styles.marginLeft = offsetHorizontal;
			styles.marginTop = offsetVertical;
			break;
		case menuPositionOrigin.TopRight:
			styles.transformOrigin = 'top right';
			styles.marginRight = offsetHorizontal;
			styles.marginTop = offsetVertical;
			break;
		case menuPositionOrigin.BottomLeft:
			styles.transformOrigin = 'bottom left';
			styles.marginLeft = offsetHorizontal;
			styles.marginBottom = offsetVertical;
			break;
		case menuPositionOrigin.BottomRight:
			styles.transformOrigin = 'bottom right';
			styles.marginRight = offsetHorizontal;
			styles.marginBottom = offsetVertical;
			break;
	}

	if (scrollSpy) {
		return (
			<ScrollSpy
				id={id}
				data-testid="context_menu"
				ref={forwardedRef}
				className={cssClasses(...classes)}
				styles={styles}>
				{children}
			</ScrollSpy>
		);
	}

	return (
		<div
			id={id}
			data-testid="context_menu"
			ref={forwardedRef}
			className={cssClasses(...classes)}
			style={styles}>
			{children}
		</div>
	);
}

export function MenuWrapperTopRight(props: MenuWrapperProps) {
	return <MenuWrapper {...props} position={menuPositionOrigin.TopRight} />;
}
export function MenuWrapperTopLeft(props: MenuWrapperProps) {
	return <MenuWrapper {...props} position={menuPositionOrigin.TopLeft} />;
}
export function MenuWrapperBottomRight(props: MenuWrapperProps) {
	return <MenuWrapper {...props} position={menuPositionOrigin.BottomRight} />;
}
export function MenuWrapperBottomLeft(props: MenuWrapperProps) {
	return <MenuWrapper {...props} position={menuPositionOrigin.BottomLeft} />;
}
