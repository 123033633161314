import { Record } from 'immutable';
import { schema } from 'normalizr';

import Account from 'pkg/models/account';
import Group from 'pkg/models/group';
import PlaylistSequence from 'pkg/models/video_playlist_sequence';

export enum PlaylistSequencePermissions {
	GroupMember = 'groupMember',
	GroupAdmin = 'groupAdmin',
}

export const videoPlaylists = new schema.Entity('videoPlaylists', {
	account: Account.normalizr(),
	group: Group.normalizr(),
	sequences: [PlaylistSequence.normalizr()],
});

interface Playlist {
	id: number;
	accountId: number;
	account: Account;
	groupId: number;
	group: Group;
	title: string;
	isPrivate: boolean;
	sequences?: number[];
	sequenceCount?: number;
	sequencePermissions?: PlaylistSequencePermissions[];
	createdAt?: number;
	updatedAt?: number;
	links?: {
		delete?: string;
		edit?: string;
	};
}

const videoPlaylistProps: Playlist = {
	id: 0,
	accountId: 0,
	account: null,
	groupId: null,
	group: null,
	title: '',
	isPrivate: false,
	sequences: [],
	sequenceCount: 0,
	sequencePermissions: [],
	createdAt: 0,
	updatedAt: 0,
	links: {},
};

class VideoPlaylist
	extends Record(videoPlaylistProps, 'VideoPlaylistRecord')
	implements Playlist
{
	static normalizr(): schema.Entity {
		return videoPlaylists;
	}

	valid(): boolean {
		return !!this.get('id');
	}

	numSequences(): number {
		let count = this.sequenceCount;

		if (!count && this.sequences.length) {
			count = this.sequences.length;
		}

		return count;
	}
}

export default VideoPlaylist;
