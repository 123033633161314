export function Index(accountID: number): string {
	return `/v1/accounts/${accountID}/notifications`;
}

export function MarkAllAsRead(accountID: number): string {
	return `/v1/accounts/${accountID}/notifications`;
}

export function Update(notificationID: number): string {
	return `/v1/account-notifications/${notificationID}`;
}
