import { ReactNode } from 'react';

export const symbol_supervisor_account: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M684-381q-39.48 0-66.74-27.26Q590-435.52 590-475q0-39.48 27.26-66.74Q644.52-569 684-569q39.48 0 66.74 27.26Q778-514.48 778-475q0 39.48-27.26 66.74Q723.48-381 684-381ZM488-190v-21q0-22.892 11-42.446T530-284q36-18.5 74.5-27.75T684-321q41 0 80 9t74 28q21 10 31.5 29.869T880-211v21q0 12.75-8.625 21.375T850-160H518q-12.75 0-21.375-8.625T488-190Zm-88-291q-66 0-108-42t-42-108q0-66 42-108t108-42q66 0 108 42t42 108q0 66-42 108t-108 42Zm0-150ZM80-254q0-34 17-62.5t50.667-43.5Q215-390 276.5-405t123.245-15Q432-420 457-417t54 9l-25.5 25.5L460-357q-13-2-28-2.5t-32-.5q-56.627 0-110.814 11.5Q235-337 172-306q-14 7-23 22t-9 30v34h288v27.75q0 9.25 4 17.75t10 14.5H140q-24.75 0-42.375-17.625T80-220v-34Zm348 34Zm-28-321q39 0 64.5-25.5T490-631q0-39-25.5-64.5T400-721q-39 0-64.5 25.5T310-631q0 39 25.5 64.5T400-541Z"
		/>
	</svg>
);
