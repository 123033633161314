import { ReactNode } from 'react';

import * as css from './styles.css';

interface BadgeWrapperProps {
	children: ReactNode;
}

export default function BadgeWrapper({ children }: BadgeWrapperProps) {
	return <div className={css.wrapper}>{children}</div>;
}
