import styled from 'styled-components';

const Wrapper = styled.div`
	display: flex;
	align-items: stretch;
	position: absolute;
	top: 14px;
	left: 50%;
	transform: translateX(-50%);
	background: var(--palette-white);
	border: 1px solid var(--palette-gray-300);
	border-radius: var(--radius-3);
	text-align: center;
	z-index: 1000;

	div {
		padding: 7px 10px;
		cursor: pointer;
		font-size: var(--font-size-base);

		&:hover {
			background: var(--palette-gray-200);
		}
	}

	.current {
		display: grid;
		place-items: center;
		border: 1px solid var(--palette-gray-300);
		border-width: 0 1px;
		min-width: 60px;
		font-size: var(--font-size-sm);
	}

	.disabled {
		opacity: 0.5;
		cursor: default;
	}
`;

export default function Zoom({ current, step, min, max, onChange }: any) {
	const reset = () => {
		onChange(1);
	};
	const zoomIn = () => {
		let newValue = current + step;
		if (newValue > max) newValue = max;
		onChange(newValue);
	};
	const zoomOut = () => {
		let newValue = current - step;
		if (newValue < min) newValue = min;
		onChange(newValue);
	};
	return (
		<Wrapper>
			<div className={current === min ? 'disabled' : ''} onClick={zoomOut}>
				-
			</div>
			<div className="current" onClick={reset}>
				{Math.round(current * 100)}%
			</div>
			<div className={current === max ? 'disabled' : ''} onClick={zoomIn}>
				+
			</div>
		</Wrapper>
	);
}
