import { ReactNode } from 'react';

export const symbol_developer_mode: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m246-480 119 119q9 9 8.5 21t-8.75 21.12q-9 9.12-21.375 9.5Q331-309 322-318L181-459q-9-9-9-21t9-21l141-141q9.067-9 21.533-8.5Q356-650 365-641q8.25 9 8.625 21T365-599L246-480Zm14 350v30h440v-30H260Zm0-700h440v-30H260v30Zm454 350L595-599q-9-9-8.5-21t8.75-21.12q9-9.12 21.375-9.5Q629-651 638-642l141 141q9 9 9 21t-9 21L638-318q-9.067 9-21.533 8.5Q604-310 595-319q-8.25-9-8.625-21T595-361l119-119ZM260-830v-30 30Zm0 700v30-30Zm0 90q-24 0-42-18t-18-42v-124q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T260-224v34h440v-34q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T760-224v124q0 24-18 42t-42 18H260Zm0-880h440q24 0 42 18t18 42v124q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T700-736v-34H260v34q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T200-736v-124q0-24 18-42t42-18Z"
		/>
	</svg>
);
