import { ReactNode } from 'react';

export const symbol_sentiment_content: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M390-340h180q13 0 21.5-8.5T600-370q0-13-8.5-21.5T570-400H390q-13 0-21.5 8.5T360-370q0 13 8.5 21.5T390-340Zm90 260q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-400Zm0 340q142 0 241-99t99-241q0-142-99-241t-241-99q-142 0-241 99t-99 241q0 142 99 241t241 99ZM340-620q-25 0-47 11t-39 29q-8 9-6.5 21t12.5 19q10 7 22 5t22-10q8-7 17-10.5t19-3.5q10 0 19 4t17 10q10 8 22 9.5t22-5.5q11-7 12.5-19t-7.5-20q-17-18-38.5-29T340-620Zm280 0q-25 0-47.5 11T533-580q-8 9-6 21t13 19q10 7 22 5.5t22-9.5q8-6 17-10t19-4q11 0 20 4t17 10q10 8 21.5 9.5T700-540q11-7 12.5-19t-7.5-21q-17-18-38.5-29T620-620Z"
		/>
	</svg>
);
