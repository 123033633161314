// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eA08ZM7Z5bTPwXUxEU_K {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	gap: var(--spacing-2) var(--spacing-4);
	-ms-flex-flow: wrap;
	    flex-flow: wrap;
}
`, "",{"version":3,"sources":["webpack://./routes/event/components/location/styles.css"],"names":[],"mappings":"AAAA;CACC,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CACnB,sCAAsC;CACtC,mBAAe;KAAf,eAAe;AAChB","sourcesContent":[".wrapper {\n\tdisplay: flex;\n\talign-items: center;\n\tgap: var(--spacing-2) var(--spacing-4);\n\tflex-flow: wrap;\n}\n"],"sourceRoot":""}]);
// Exports
export var wrapper = `eA08ZM7Z5bTPwXUxEU_K`;
export default ___CSS_LOADER_EXPORT___;
