export function Claim(inviteID: number): string {
	return `/v1/account-invites/${inviteID}/claim`;
}

export function Create(): string {
	return `/v1/account-invites`;
}

export function Destroy(inviteID: number): string {
	return `/v1/account-invites/${inviteID}`;
}

export function FromGroup(groupID: number): string {
	return `/v1/groups/${groupID}/invites`;
}

export function Index(): string {
	return `/v1/account-invites`;
}

export function Show(inviteID: number): string {
	return `/v1/account-invites/${inviteID}`;
}
