import { ReactNode } from 'react';

export const symbol_mode_off_on: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M479.825-478Q467-478 458.5-486.625T450-508v-346q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-854v346q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM480-118q-75 0-140.5-28.5t-114-77q-48.5-48.5-77-114T120-478q0-64 22.5-123.5T207-709q9-10 22-10.5t22.75 9.25Q260-702 258.5-689.5T249-667q-34 39-51.5 88.237Q180-529.527 180-478q0 125.357 87.321 212.679Q354.643-178 480-178t212.679-87.321Q780-352.643 780-478q0-53-17.5-101.5T711-668q-8.333-10.045-9.167-22.023Q701-702 709-710q10-10 24-9t23 12q42 48 63 107t21 122q0 75-28.5 140.5t-77 114q-48.5 48.5-114 77T480-118Z"
		/>
	</svg>
);
