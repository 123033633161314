import Button from 'design/button';

import * as css from './styles.css';

interface MultiDaySelectProps {
	selectedDays: number[];
	scheduleDays: string[];

	handleSelectDay: (index: number) => void;
}

const MultiDaySelect = ({
	selectedDays,
	scheduleDays,
	handleSelectDay,
}: MultiDaySelectProps) => {
	return (
		<div className={css.row}>
			{scheduleDays.map((d, i) => (
				<Button
					key={i}
					label={d}
					onClick={() => handleSelectDay(i + 1)}
					active={selectedDays.includes(i + 1)}
					secondary={selectedDays.includes(i + 1)}
				/>
			))}
		</div>
	);
};

export default MultiDaySelect;
