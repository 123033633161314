import { ReactNode } from 'react';

export const symbol_view_sidebar: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-160q-24.75 0-42.375-17.625T80-220v-520q0-24.75 17.625-42.375T140-800h680q24.75 0 42.375 17.625T880-740v520q0 24.75-17.625 42.375T820-160H140Zm543-443h137v-137H683v137Zm0 186h137v-126H683v126ZM140-220h483v-520H140v520Zm543 0h137v-137H683v137Z"
		/>
	</svg>
);
