import { ReactNode } from 'react';

export const symbol_elevation: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M780-120H141q-18 0-27-16t2-31l206-288q8.311-12.121 21.156-18.561Q356-480 370-480h172l245-286q14-16 33.5-8.926Q840-767.851 840-747v567q0 24.75-17.625 42.375T780-120ZM289-580 161-401q-8 10-19.478 12.5Q130.043-386 120-394q-10-8-12.5-19.826T113-436l128-179q8-12 21.5-18.5T290-640h170l165-192q8.186-10.4 20.093-11.2Q657-844 667-836t11 20.444q1 12.445-7 22.556L506-601q-8 10-20 15.5t-26 5.5H289Zm-90 400h581v-486L587-441q-8 10-19.5 15.5T542-420H370L199-180Zm581 0Z"
		/>
	</svg>
);
