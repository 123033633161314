import { ReactNode } from 'react';

export const symbol_hvac: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480.235-260Q572-260 636-324.235q64-64.236 64-156Q700-572 635.765-636q-64.236-64-156-64Q388-700 324-635.765q-64 64.236-64 156Q260-388 324.235-324q64.236 64 156 64ZM480-320q-29 0-56-10.5T375-360h210q-22 19-49 29.5T480-320Zm-138-80q-8-14-13-29t-7-31h316q-2 16-7 31t-13 29H342Zm-20-100q2-16 7-31t13-29h276q8 14 13 29t7 31H322Zm53-100q22-19 49-29.5t56-10.5q29 0 56 10.5t49 29.5H375ZM180-120q-24.75 0-42.375-17.625T120-180v-600q0-24.75 17.625-42.375T180-840h600q24.75 0 42.375 17.625T840-780v600q0 24.75-17.625 42.375T780-120H180Zm0-60h600v-600H180v600Zm0-600v600-600Z"
		/>
	</svg>
);
