import { ReactNode } from 'react';

export const symbol_lists: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M399-160q-36 0-61-25.58-25-25.579-25-61.5Q313-283 338-308t61-25h395q36 0 61 25t25 60.92q0 35.921-25 61.5Q830-160 794-160H399Zm1-233q-35.888 0-61.444-25.58Q313-444.159 313-480.08q0-35.92 25.556-61.42T400-567h393q35.888 0 61.444 25.58Q880-515.841 880-479.92q0 35.92-25.556 61.42T793-393H400Zm-1-234q-36 0-61-25.58-25-25.579-25-61.5Q313-750 338-775t61-25h395q36 0 61 25t25 60.92q0 35.921-25 61.5Q830-627 794-627H399Zm-231.92 0q-35.921 0-61.5-25.58Q80-678.159 80-714.08 80-750 105.58-775q25.579-25 61.5-25Q203-800 228-775t25 60.92q0 35.921-25 61.5Q203-627 167.08-627Zm-.16 234q-35.92 0-61.42-25.58Q80-444.159 80-480.08q0-35.92 25.58-61.42 25.579-25.5 61.5-25.5 35.92 0 61.42 25.58 25.5 25.579 25.5 61.5 0 35.92-25.58 61.42-25.579 25.5-61.5 25.5Zm.16 233q-35.921 0-61.5-25.58Q80-211.159 80-247.08 80-283 105.58-308q25.579-25 61.5-25Q203-333 228-308t25 60.92q0 35.921-25 61.5Q203-160 167.08-160Z"
		/>
	</svg>
);
