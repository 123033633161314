import * as actionTypes from 'pkg/actions/action-types';

const initialState = {
	entities: {},
	collectionLinks: {},
	currentPosition: 0,
	maturityAdjustment: true,
	comparisonRatingId: 0,
	positionScores: {},
	ratingItems: {},
	ratingScores: {},
	error: {},
	positionFetchingStatus: false,
	primaryRatingIds: {},
};

const ratings = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_PRIMARY_RATING_ID': {
			const newPrimaryRating = { ...state.primaryRatingIds };
			newPrimaryRating[action.groupId] = action.ratingId;

			return {
				...state,
				primaryRatingIds: newPrimaryRating,
			};
		}

		case 'RESET_PRIMARY_RATING_ID': {
			return {
				...state,
				primaryRatingIds: {},
			};
		}

		case 'SET_RATING_ERROR': {
			return {
				...state,
				comparisonRatingId: action.ratingId,
				error: {
					id: action.ratingId,
					status: action.status,
					statusText: action.statusText,
				},
			};
		}

		case 'SET_POSITION_FETCHING_STATUS': {
			return {
				...state,
				positionFetchingStatus: action.status,
			};
		}

		case actionTypes.Ratings.AddItems: {
			const newEntities = { ...state.entities };

			for (let i = 0; i < action.ratings.length; i++) {
				const rating = action.ratings[i];
				if (!newEntities[rating.id]) {
					ratings[rating.id] = {};
				}

				newEntities[rating.id] = {
					...newEntities[rating.id],
					...rating,
				};
			}

			return {
				...state,
				entities: { ...newEntities },
			};
		}

		case 'ADD_RATING': {
			const newEntities = { ...state.entities };
			newEntities[action.rating.id] = {
				...newEntities[action.rating.id],
				...action.rating,
			};

			return {
				...state,
				entities: { ...newEntities },
			};
		}

		case actionTypes.Ratings.SetItems: {
			let { ratings } = action;

			if (ratings?.records) {
				ratings = ratings.records;
			}

			const newEntities = { ...state.entities };
			for (let i = 0; i < ratings.length; i++) {
				const rating = ratings[i];
				if (newEntities[rating.id]) {
					newEntities[rating.id] = {
						...newEntities[rating.id],
						...rating,
					};
				} else {
					newEntities[rating.id] = rating;
				}
			}
			if (!state.collectionLinks[action.groupId]) {
				state.collectionLinks[action.groupId] = {};
			}

			if (action.ratings.links) {
				state.collectionLinks[action.groupId][action.userId] =
					action.ratings.links;
			} else {
				state.collectionLinks[action.groupId][action.userId] = {};
			}

			return {
				...state,
				entities: {
					...state.entities,
					...newEntities,
				},
				collectionLinks: { ...state.collectionLinks },
			};
		}

		case 'UPDATE_USER_RATING': {
			const newEntities = { ...state.entities };

			newEntities[action.rating.id] = {
				...newEntities[action.rating.id],
				...action.rating,
			};

			return {
				...state,
				entities: { ...newEntities },
			};
		}

		case actionTypes.RATING_ADD_SINGLE_USER_RATING: {
			const newEntities = { ...state.entities };

			if (newEntities[action.rating.id]) {
				newEntities[action.rating.id] = {
					...newEntities[action.rating.id],
					...action.rating,
				};
			} else {
				newEntities[action.rating.id] = {
					...action.rating,
				};
			}

			return {
				...state,
				entities: { ...newEntities },
			};
		}

		case 'SET_CURRENT_POSITION': {
			return {
				...state,
				currentPosition: action.id,
			};
		}

		case 'SET_COMPARISON_RATING_ID': {
			return {
				...state,
				comparisonRatingId: action.ratingId,
			};
		}

		case 'TOGGLE_MATURITY_ADJUSTMENT': {
			return {
				...state,
				maturityAdjustment: action.maturityAdjustment,
			};
		}

		case 'SET_POSITION_SCORES': {
			if (!action.positionScores) {
				return state;
			}

			if (!state.positionScores[action.ratingId]) {
				state.positionScores[action.ratingId] = {};
			}

			state.positionScores[action.ratingId] = action.positionScores;

			return {
				...state,
				positionScores: {
					...state.positionScores,
				},
			};
		}

		case 'SET_USER_RATING_ITEMS': {
			if (!action.ratingItems) {
				return state;
			}

			if (!state.ratingItems[action.ratingId]) {
				state.ratingItems[action.ratingId] = {};
			}

			state.ratingItems[action.ratingId] = action.ratingItems;

			return {
				...state,
				ratingItems: {
					...state.ratingItems,
				},
			};
		}

		case 'SET_RATING_SCORES': {
			if (!action.ratingScores) {
				return state;
			}

			if (!state.ratingScores[action.ratingId]) {
				state.ratingScores[action.ratingId] = {};
			}

			if (!state.ratingScores[action.ratingId][action.positionId]) {
				state.ratingScores[action.ratingId][action.positionId] = [];
			}

			state.ratingScores[action.ratingId][action.positionId] =
				action.ratingScores;

			return {
				...state,
				ratingScores: { ...state.ratingScores },
			};
		}

		case actionTypes.AUTH_LOGOUT_SUCCESS:
			return initialState;

		default: {
			return { ...state };
		}
	}
};

export default ratings;
