import {
	ReactNode,
	FormEvent,
	Ref,
	useEffect,
	useRef,
	MutableRefObject,
} from 'react';
import styled from 'styled-components';
import {
	FieldValues,
	FormProvider,
	SubmitErrorHandler,
	SubmitHandler,
	useForm,
} from 'react-hook-form';

import { Label } from 'components/form/inputs/styles';

export const asString = (value: any) => value as string;
export const asNumber = (value: any) => Number.parseInt(value as string, 10);

export function submitForm(ref: MutableRefObject<HTMLFormElement>) {
	return ref.current.dispatchEvent(
		new Event('submit', {
			cancelable: true,
			bubbles: true,
		})
	);
}

export type FormDataValue =
	| FormDataEntryValue
	| { [key: string]: FormDataEntryValue }
	| boolean;

export interface FormPayload {
	[key: string]: FormDataValue;
}

interface FormProps {
	children: ReactNode;
	className?: string;
	action?: string;
	method?: string;
	testid?: string;
	onSubmit?: SubmitHandler<FieldValues>;
	onErrors?: SubmitErrorHandler<FieldValues>;
	formRef?: Ref<HTMLFormElement>;
	stopPropagation?: boolean;
	resetOnSubmit?: boolean;
}

const StyledForm = styled.form`
	${Label} {
		font-weight: var(--font-weight-semibold);
	}
`;

const Form = ({
	children,
	className,
	action,
	method,
	testid,
	onSubmit,
	onErrors,
	formRef,
	stopPropagation = false,
	resetOnSubmit = false,
}: FormProps): JSX.Element => {
	const ref = useRef<HTMLFormElement>();
	const methods = useForm();

	useEffect(() => {
		if (resetOnSubmit && methods.formState.isSubmitSuccessful) {
			methods.reset();
		}
	}, [methods.formState, methods.reset]);

	if (!formRef) {
		formRef = ref;
	}

	const handleSubmit = stopPropagation
		? (e: FormEvent<HTMLFormElement>) => {
				e.stopPropagation();

				return methods.handleSubmit(onSubmit, onErrors)(e);
			}
		: methods.handleSubmit(onSubmit, onErrors);

	return (
		<FormProvider {...methods}>
			<StyledForm
				ref={formRef}
				action={action}
				method={method}
				noValidate
				className={className}
				data-testid={testid}
				onSubmit={handleSubmit}>
				{children}
			</StyledForm>
		</FormProvider>
	);
};

export default Form;
