import { ReactNode } from 'react';

export const symbol_nest_cam_outdoor: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M576-189q-22 26-54.5 29.5T463-178L294-319q-26-21-45-48t-31-58q-11 12-26 18.5t-32 6.5H80v-320h80q29 0 51.5 18t27.5 47q38-58 98-91.5T466-780q45 0 85.5 16t74.5 45l170 141q26 21 28.5 53.5T806-466L576-189ZM260-518q0 45 19 84t53 68l185 154 255-307-184-154q-26.761-22.927-59.097-34.963Q496.566-720 462-720q-84 0-142.449 58.682Q261.103-602.637 260-518Zm256 52Z"
		/>
	</svg>
);
