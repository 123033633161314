import { ReactNode } from 'react';

export const symbol_vacuum: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M154-80q-47.5 0-80.75-33.25T40-194q0-47.5 33.25-80.75T154-308q47.5 0 80.75 33.25T268-194q0 47.5-33.25 80.75T154-80Zm0-60q24 0 39-15t15-39q0-24-15-39t-39-15q-24 0-39 15t-15 39q0 24 15 39t39 15Zm340-300v300q0 24.75-17.625 42.375T434-80h-98q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T336-140h98v-300q0-37.538-26.731-64.269Q380.538-531 343-531H142v133q0 13-8.675 21-8.676 8-21.5 8Q99-369 90.5-377.625T82-399v-132q0-24.75 17.625-42.375T142-591h65v-153q0-72 52-124t124-52q56 0 100 29t65 80l278 671h64q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q920-97 911.375-88.5T890-80H684q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T684-140h80L489-786q-15-35-41.5-54.5T383-860q-48 0-82 34t-34 82v153h76.164Q406-591 450-547t44 107ZM288-336Z"
		/>
	</svg>
);
