const Training = {
	name: 'training',
	objects: [
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				d: 'M8.66665 4.58362C7.25658 4.58362 5.90426 5.14377 4.90719 6.14083C3.91012 7.1379 3.34998 8.49022 3.34998 9.90029C3.34998 10.3824 3.41545 10.8577 3.54147 11.3144H2V12.6144H4.09492C4.3192 12.9922 4.59126 13.3438 4.90719 13.6597L11.5404 20.2929C11.7942 20.5467 12.2058 20.5467 12.4596 20.2929L19.0928 13.6597C19.4087 13.3438 19.6808 12.9922 19.9051 12.6144H22V11.3144H20.4585C20.5845 10.8577 20.65 10.3824 20.65 9.90029C20.65 8.49022 20.0898 7.1379 19.0928 6.14083C18.0957 5.14377 16.7434 4.58362 15.3333 4.58362C14.1152 4.58362 12.9401 5.00166 12 5.75831C11.0598 5.00166 9.8848 4.58362 8.66665 4.58362ZM19.0928 11.3144C19.2613 10.8666 19.35 10.388 19.35 9.90029C19.35 8.835 18.9268 7.81334 18.1735 7.06007C17.4203 6.3068 16.3986 5.88362 15.3333 5.88362C14.2728 5.88362 13.2554 6.30306 12.5031 7.05008L12.4943 7.06017C12.4872 7.06823 12.4769 7.07956 12.4643 7.09241C12.342 7.21719 12.1747 7.28751 12 7.28752C11.8253 7.28754 11.658 7.21724 11.5357 7.09248C11.5231 7.0796 11.5128 7.06826 11.5057 7.06019L11.4968 7.05007C10.7445 6.30306 9.72721 5.88362 8.66665 5.88362C7.60136 5.88362 6.5797 6.3068 5.82643 7.06007C5.07316 7.81334 4.64998 8.835 4.64998 9.90029C4.64998 10.388 4.73867 10.8666 4.90714 11.3144H8.98546L10.6667 8.7926L13.3333 12.7926L14.3188 11.3144H19.0928ZM5.7057 12.6144C5.74494 12.6572 5.78519 12.6993 5.82643 12.7405L12 18.914L18.1735 12.7405C18.2148 12.6993 18.255 12.6572 18.2943 12.6144H15.0145L13.3333 15.1362L10.6667 11.1362L9.6812 12.6144H5.7057Z',
			},
		},
	],
};

export default Training;
