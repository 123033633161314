import { ReactNode } from 'react';

export const symbol_tty: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-480q-17 0-28.5-11.5T440-520q0-17 11.5-28.5T480-560q17 0 28.5 11.5T520-520q0 17-11.5 28.5T480-480Zm0-240q-17 0-28.5-11.5T440-760q0-17 11.5-28.5T480-800q17 0 28.5 11.5T520-760q0 17-11.5 28.5T480-720Zm80 120q-17 0-28.5-11.5T520-640q0-17 11.5-28.5T560-680q17 0 28.5 11.5T600-640q0 17-11.5 28.5T560-600Zm40 120q-17 0-28.5-11.5T560-520q0-17 11.5-28.5T600-560q17 0 28.5 11.5T640-520q0 17-11.5 28.5T600-480Zm0-240q-17 0-28.5-11.5T560-760q0-17 11.5-28.5T600-800q17 0 28.5 11.5T640-760q0 17-11.5 28.5T600-720Zm80 120q-17 0-28.5-11.5T640-640q0-17 11.5-28.5T680-680q17 0 28.5 11.5T720-640q0 17-11.5 28.5T680-600Zm40 120q-17 0-28.5-11.5T680-520q0-17 11.5-28.5T720-560q17 0 28.5 11.5T760-520q0 17-11.5 28.5T720-480Zm0-240q-17 0-28.5-11.5T680-760q0-17 11.5-28.5T720-800q17 0 28.5 11.5T760-760q0 17-11.5 28.5T720-720Zm80 120q-17 0-28.5-11.5T760-640q0-17 11.5-28.5T800-680q17 0 28.5 11.5T840-640q0 17-11.5 28.5T800-600Zm40 120q-17 0-28.5-11.5T800-520q0-17 11.5-28.5T840-560q17 0 28.5 11.5T880-520q0 17-11.5 28.5T840-480Zm0-240q-17 0-28.5-11.5T800-760q0-17 11.5-28.5T840-800q17 0 28.5 11.5T880-760q0 17-11.5 28.5T840-720Zm-45 600q-116 0-236.5-56T335-335Q232-438 176-558.5T120-795q0-19.286 12.857-32.143T165-840h140q14 0 24 10t14 25l26.929 125.641Q372-665 369.5-653.5q-2.5 11.5-10.729 19.726L259-533q26 44 55 82t64 72q37 38 78 69.5t86 55.5l95-98q10-11 23.151-15T686-369l119 26q15 4 25 16.044T840-300v135q0 19.286-12.857 32.143T795-120ZM229-588l81-82-23-110H180q2 42 13.5 88.5T229-588Zm369 363q41 19 89 31t93 14v-107l-103-21-79 83ZM229-588Zm369 363Z"
		/>
	</svg>
);
