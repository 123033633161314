import { ReactNode } from 'react';

export const symbol_docs_apps_script: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M520-130q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T520-190h145l-118-83q-10-7-12.5-19t4.5-22q7-10 18.826-12.5T580-322l119 82-50-136q-5-11 1-22t18-16q12-5 23 .5t16 17.5l48 136 39-139q3-12 13-18t22-3q12 3 18.5 13t3.5 22l-62 232q-3 10-10.5 16.5T760-130H520ZM150-240q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-300h261q-2 17-1 29t4 31H150Zm0-166q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-466h353q-17.25 12.286-31.125 27.643Q458-423 447-406H150Zm0-167q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-633h540q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T690-573H150Zm0-167q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-800h540q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T690-740H150Z"
		/>
	</svg>
);
