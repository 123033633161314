import { ReactNode } from 'react';

export const symbol_video_chat: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M313-409h247q14.025 0 23.513-9.775Q593-428.55 593-443v-93l74 74q4 4 8.5 2.125T680-467v-191q0-5.25-4.5-7.125T667-663l-74 74v-94q0-14.025-9.487-23.513Q574.025-716 560-716H313q-14.025 0-23.513 9.487Q280-697.025 280-683v240q0 14.45 9.487 24.225Q298.975-409 313-409Zm-73 169L131-131q-14 14-32.5 6.344T80-152v-668q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H240Zm-26-60h606v-520H140v600l74-80Zm-74 0v-520 520Z"
		/>
	</svg>
);
