import { ReactNode } from 'react';

export const symbol_contract: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M225-80q-43.75 0-74.375-30.625T120-185v-75q0-24.75 17.625-42.375T180-320h60v-500q0-24.75 17.625-42.375T300-880h480q24.75 0 42.375 17.625T840-820v635q0 43.75-30.625 74.375T735-80H225Zm509.912-60Q754-140 767-152.938q13-12.937 13-32.062v-635H300v500h330q24.75 0 42.375 17.625T690-260v75q0 19.125 12.912 32.062 12.913 12.938 32 12.938ZM390-640q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T390-700h300q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T690-640H390Zm0 120q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T390-580h300q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T690-520H390ZM224-140h406v-120H180v75q0 19.125 13 32.062Q206-140 224-140Zm0 0h-44 450-406Z"
		/>
	</svg>
);
