import { ReactNode } from 'react';

export const symbol_qr_code_2_add: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M200-400v-80h80v80h-80Zm-80-80v-80h80v80h-80Zm360-280v-80h80v80h-80ZM170-650h140v-140H170v140Zm0 480h140v-140H170v140Zm480-480h140v-140H650v140ZM360-400v-80h-80v-80h160v160h-80Zm40-200v-160h80v80h80v80H400Zm-190-90v-60h60v60h-60Zm0 480v-60h60v60h-60Zm480-480v-60h60v60h-60Zm-40 440H550q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T550-310h100v-100q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T710-410v100h100q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-250H710v100q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T650-150v-100ZM120-630v-180q0-12.75 8.625-21.375T150-840h180q12.75 0 21.375 8.625T360-810v180q0 12.75-8.625 21.375T330-600H150q-12.75 0-21.375-8.625T120-630Zm0 480v-180q0-12.75 8.625-21.375T150-360h180q12.75 0 21.375 8.625T360-330v180q0 12.75-8.625 21.375T330-120H150q-12.75 0-21.375-8.625T120-150Zm480-480v-180q0-12.75 8.625-21.375T630-840h180q12.75 0 21.375 8.625T840-810v180q0 12.75-8.625 21.375T810-600H630q-12.75 0-21.375-8.625T600-630Z"
		/>
	</svg>
);
