import { ReactNode } from 'react';

export const symbol_door_sensor: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M340-360v-420 600-180Zm-60 30h60v150h280v-600H340v390h-60v-390q0-24.75 17.625-42.375T340-840h280q24.75 0 42.375 17.625T680-780v600q0 24.75-17.625 42.375T620-120H340q-24.75 0-42.375-17.625T280-180v-150Zm200.5-240q12.5 0 21-9t8.5-21.5q0-12.5-8.625-21T480-630q-12 0-21 8.625T450-600q0 12 9 21t21.5 9ZM810-610q-12 0-21-9t-9-21v-160q0-12.75 9-21.375T810.5-830q12.5 0 21 8.625T840-800v160q0 12-8.625 21T810-610ZM190-330q-12 0-21-9t-9-21.5q0-12.5 9-21t21-8.5h290q12.75 0 21.375 8.625T510-360q0 12-8.625 21T480-330H190Z"
		/>
	</svg>
);
