import { Record } from 'immutable';
import { schema } from 'normalizr';

import { orders } from 'pkg/models/schemas';

import DateTime from 'pkg/datetime';

export enum OrderType {
	Unknown = 'unknown',
	Invoice = 'invoice',
	Payment = 'payment',
}

export enum Statuses {
	Draft = 'draft',
	Open = 'open',
	Paid = 'paid',
	PastDue = 'past_due',
	Void = 'void',
	Uncollectible = 'uncollectible',
}

export interface IOrderRow {
	createdAt: number;
	id: number;
	orderId: number;
	productPrice: number;
	productPriceId: number;
	description?: string;
	quantity: number;
	taxRateId: number;
	updatedAt: number;
	validTo: number;
	amount: number;
}

interface IOrder {
	accountId: number;
	address: string;
	amountDue: number;
	amountRemaining: number;
	city: string;
	companyName: string;
	country: string;
	createdAt: number;
	currency: string;
	customerUserId: number;
	description: string;
	dueDate: number;
	email: string;
	firstName: string;
	flags: string[];
	groupId: number;
	id: number;
	lastName: string;
	phoneNumber: string;
	postalCode: string;
	region: string;
	rows: IOrderRow[];
	sentAt: number;
	jobStatus: string;
	stripeCouponId: number;
	paymentProviderId: string;
	status: string;
	subscription: any;
	subscriptionId: number;
	updatedAt: number;
	userProducts: number[];
	invoiceNumber: number;
	type: OrderType;
}

const orderProps: IOrder = {
	accountId: 0,
	address: '',
	amountDue: 0,
	amountRemaining: 0,
	city: '',
	companyName: '',
	country: '',
	createdAt: 0,
	currency: '',
	customerUserId: 0,
	description: '',
	dueDate: 0,
	email: '',
	firstName: '',
	flags: [],
	groupId: 0,
	id: 0,
	lastName: '',
	phoneNumber: '',
	postalCode: '',
	region: '',
	rows: [],
	sentAt: null,
	jobStatus: 'unknown',
	stripeCouponId: null,
	paymentProviderId: '',
	status: '',
	subscription: null,
	subscriptionId: null,
	updatedAt: 0,
	userProducts: [],
	invoiceNumber: null,
	type: OrderType.Unknown,
};

class Order extends Record(orderProps, 'OrderRecord') implements IOrder {
	constructor(args: any) {
		if (!args) args = {};

		if (args.rows == null) {
			args.rows = [];
		}

		const r: any[] = [];
		args.rows.forEach((row: any) => {
			r.push({
				...row,
				taxRateId:
					typeof row.taxRateId === 'string'
						? Number.parseInt(row.taxRateId, 10)
						: row.taxRateId,
			});
		});
		args.rows = r;

		super(args);
	}

	static normalizr(): schema.Entity {
		return orders;
	}

	getStatus(): { name: string } {
		const now = new DateTime(new Date()).getUnixTimestamp();

		if (this.dueDate < now && this.status === 'open') {
			return { name: 'past_due' };
		}

		return { name: this.status === 'unknown' ? '' : this.status };
	}
}

export default Order;
