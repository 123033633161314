import { ReactNode } from 'react';

export const symbol_password: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M111-261h739q13 0 21.5 8.5T880-231q0 13-9 22t-22 9H110q-13 0-21.5-9T80-231q0-13 9-21.5t22-8.5Zm45-260-27 48q-5 8-13.5 10.5T99-465q-8-5-11-14t2-17l28-48H62q-9 0-15.5-7T40-567q0-9 7-15.5t16-6.5h55l-28-48q-5-8-2.5-17T98-668q8-5 17-2t14 11l27 47 27-48q5-8 13.5-10.5T213-668q8 5 11 14t-2 17l-28 48h56q9 0 15.5 6.5T272-567q0 9-6.5 16t-15.5 7h-56l28 48q5 8 2.5 17T214-465q-8 5-17 2t-14-11l-27-47Zm324 0-26 47q-5 8-14.5 10.5T422-466q-8-5-10.5-14.5T414-498l28-48h-56q-9 0-15.5-7t-6.5-16q0-9 7-15.5t16-6.5h55l-28-48q-5-8-2.5-17t10.5-14q8-5 17-2t14 11l27 47 27-48q5-8 13.5-10.5T537-670q8 5 11 14t-2 17l-28 48h56q9 0 15.5 6.5T596-569q0 9-6.5 16t-15.5 7h-56l29 48q5 8 2 17.5T538-466q-8 5-17.5 2T506-475l-26-46Zm324 0-26 47q-5 8-14.5 10.5T746-466q-8-5-10.5-14.5T738-498l28-48h-56q-9 0-15.5-7t-6.5-16q0-9 7-15.5t16-6.5h55l-28-48q-5-8-2.5-17t10.5-14q8-5 17-2t14 11l27 47 27-48q5-8 13.5-10.5T861-670q8 5 11 14t-2 17l-28 48h56q9 0 15.5 6.5T920-569q0 9-6.5 16t-15.5 7h-56l29 48q5 8 2 17.5T862-466q-8 5-17.5 2T830-475l-26-46Z"
		/>
	</svg>
);
