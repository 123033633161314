import { ReactNode } from 'react';

export const symbol_shield_locked: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M160-525v-194q0-19 11-34.5t28-22.5l260-97q11-4 21-4t21 4l260 97q17 7 28 22.5t11 34.5v196q0 15-9.5 22.5T770-493q-13 0-21.5-8t-8.5-22v-196l-260-98-260 98v196q0 131 72.5 236.5T480-143q17-6 36.5-15.5T551-177q11-6 23.5-2.5T593-165q6 11 2.5 23T581-124q-16 9-36 18t-37 16q-5 2-10 3t-10 1q-5 0-9.5-1t-9.5-3q-139-47-221-168.5T160-525ZM675-80q-15 0-28.5-13.5T633-122v-122q0-15 13.5-28.5T675-286v-40q0-36 23.5-61t58.5-25q35 0 58 25t23 61v40h1q14 0 27.5 13.5T880-244v122q0 15-13.5 28.5T839-80H675Zm40-206h84v-40q0-20-11.5-33T757-372q-20 0-31 13t-11 33v40ZM480-480Z"
		/>
	</svg>
);
