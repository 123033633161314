import styled from 'styled-components';

import Toggle from 'components/Toggle';

import FormLabel from 'components/form/Label';

const Label = styled(FormLabel)`
	margin-right: 10px;
	margin-bottom: 0;
	padding: 0;
`;

const NOOP = () => {};

const ToggleSwitch = ({ active, label, onToggle, className }) => {
	return (
		<div className={className} onClick={onToggle}>
			{label && <Label>{label}</Label>}
			<Toggle active={active} />
		</div>
	);
};

ToggleSwitch.defaultProps = {
	active: false,
	isValidFormComponent: true,
	label: '',
	onToggle: NOOP,
};

const toggleAlign = (props) => {
	switch (props.align) {
		case 'start':
			return 'flext-start';
		case 'end':
			return 'flex-end';
		default:
			return 'space-between';
	}
};

export default styled(ToggleSwitch).attrs((props) => ({
	align: toggleAlign(props),
}))`
	user-select: none;
	position: relative;
	display: flex;
	align-items: center;
	flex-direction: row;
	cursor: pointer;
	justify-content: ${(props) => props.align};
	font-size: 1em;
`;
