import { ReactNode } from 'react';

export const symbol_mark_chat_read: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m701-245 156-156q9-9 21-8.5t21.391 9.5Q908-391 908-379t-9 21L722-181q-9 9-21 9t-21-9l-92-92q-9-9-9-21t9-21q9-9 21-8.5t21 8.5l71 70Zm-461 5L131-131q-14 14-32.5 6.344T80-152v-668q0-24 18-42t42-18h680q24 0 42 18t18 42v280q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T820-540v-280H140v600l74-80h246q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T460-240H240Zm-100-60v-520 520Z"
		/>
	</svg>
);
