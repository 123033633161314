import { t } from '@transifex/native';
import { useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import { formatToCurrency } from 'pkg/i18n/formatters';
import * as actions from 'pkg/actions';

import * as Card from 'components/Card';
import Modal from 'components/modal';

import * as Input from 'components/form/inputs';
import InfoBox from 'components/form/info-box';
import Column from 'components/layout/column';
import Form, { FormPayload, submitForm } from 'components/form/Form';
import Section from 'components/form/Section';

import Button, { ButtonGroup } from 'design/button';

interface RefundModalProps {
	currency: string;

	hideModal: () => void;

	order: models.order.Order;
}

export default function RefundModal(props: RefundModalProps) {
	const formRef = useRef(null);
	const [saving, setSaving] = useState(false);

	const handleSubmit = async (data: FormPayload) => {
		setSaving(true);

		const payload: actions.order.RefundPayload = {};
		if (data.refundAmount) {
			payload.amount = Math.round(
				Number.parseFloat(data.refundAmount as string) * 100
			);
		}

		const ok = await actions.order.createRefund(props.order, payload);

		if (ok) {
			props.hideModal();
		} else {
			setSaving(false);
		}
	};

	const onSubmitClick = async () => {
		submitForm(formRef);
	};

	return (
		<Modal onClose={props.hideModal}>
			<Card.Base>
				<Card.Header>
					<Card.Heading>{t('Refund payment')}</Card.Heading>
				</Card.Header>
				<Card.Divider />
				<Card.Body>
					<Form formRef={formRef} onSubmit={handleSubmit}>
						<RefundForm {...props} />
					</Form>
				</Card.Body>
				<Card.Divider />
				<Card.Footer>
					<ButtonGroup>
						<Button
							label={t('Cancel')}
							onClick={props.hideModal}
							busy={saving}
						/>
						<Button
							label={t('Refund')}
							primary
							onClick={onSubmitClick}
							isLoading={saving}
						/>
					</ButtonGroup>
				</Card.Footer>
			</Card.Base>
		</Modal>
	);
}

function RefundForm({ order, currency }: RefundModalProps) {
	const amountDue = (order.amountDue - order.amountRefunded) / 100;
	const amount = formatToCurrency(amountDue, currency);
	const formContext = useFormContext();

	const refundOption = formContext.watch('refundOption');
	const refundAmountInput = formContext.watch('refundAmount');

	let refundAmount = amount;

	if (refundAmountInput) {
		refundAmount = formatToCurrency(
			Number.parseFloat(refundAmountInput),
			currency
		);
	}

	const validateRefundAmount = () => {
		if (Number.parseFloat(refundAmountInput) > amountDue) {
			return false;
		}

		return true;
	};

	return (
		<Section
			title={t('Refund for invoice {invoiceNumber}', {
				invoiceNumber: order.invoiceNumber,
			})}
			description={t('Available amount: {amount}', {
				amount,
			})}
			hideDivider>
			<Column spacing={styles.spacing._6}>
				<Input.Select name="refundOption" defaultValue="full">
					<option value="full">{t('Full refund')}</option>
					<option value="amount">{t('Select amount')}</option>
				</Input.Select>

				{refundOption === 'amount' && (
					<Input.Group label={t('Type amount to refund')}>
						<Input.Field
							name="refundAmount"
							defaultValue={amountDue}
							validate={validateRefundAmount}>
							<Input.Prefix>
								<span>{currency.toUpperCase()}</span>
							</Input.Prefix>
						</Input.Field>
					</Input.Group>
				)}

				<InfoBox color="orange">
					<span>
						{t(
							'A refund of {amount} will be issued, are you sure you want to continue? This action can not be undone.',
							{
								amount: refundAmount,
							}
						)}
					</span>
					<span>
						{t(
							'The refund may take up to ten days to appear on your customer’s statement.'
						)}
					</span>
				</InfoBox>
			</Column>
		</Section>
	);
}
