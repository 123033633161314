import { ReactNode } from 'react';

export const symbol_grouped_bar_chart: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M190-160q-12.75 0-21.375-8.625T160-190v-420q0-12.75 8.625-21.375T190-640h80q12.75 0 21.375 8.625T300-610v420q0 12.75-8.625 21.375T270-160h-80Zm210 0q-12.75 0-21.375-8.625T370-190v-220q0-12.75 8.625-21.375T400-440h80q12.75 0 21.375 8.625T510-410v220q0 12.75-8.625 21.375T480-160h-80Zm290 0q-12.75 0-21.375-8.625T660-190v-580q0-12.75 8.625-21.375T690-800h80q12.75 0 21.375 8.625T800-770v580q0 12.75-8.625 21.375T770-160h-80Z"
		/>
	</svg>
);
