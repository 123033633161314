import { t } from '@transifex/native';
import styled from 'styled-components';
import { Fragment, MouseEvent } from 'react';

import { tags } from 'pkg/config/training-filters';
import { getAlias } from 'pkg/config/tags';

import { useQueryState } from 'pkg/hooks/query-state';

import { useTrainingLibraryFilters } from 'routes/training/library/hooks';

import Icon, { IconName } from 'components/icon';

import Row from 'components/layout/row';

const Wrapper = styled.div`
	margin: 0 auto;
	width: 100%;
	max-width: 1800px;
	display: flex;
	flex-wrap: wrap;
	gap: var(--spacing-3);
`;

const Term = styled(Row)`
	padding: var(--spacing-3) var(--spacing-4);
	padding-left: var(--spacing-4);
	background-color: var(--palette-gray-500);
	color: var(--palette-white);
	font-size: var(--font-size-sm);
	border-radius: var(--radius-3);
	cursor: pointer;
	gap: 6px;

	&[data-type='tag'] {
		background-color: var(--palette-green-500);
	}

	&[data-type='filter'] {
		background-color: var(--palette-blue-500);
	}

	&[data-type='reset'] {
		background-color: var(--palette-gray-200);
		color: var(--palette-gray-600);
		box-shadow: inset 0 0 0 1px var(--palette-gray-400);
	}

	@media (hover: hover) {
		&:hover {
			opacity: 0.7;
		}
	}
`;

interface ItemProps {
	type?: 'reset' | 'search' | 'tag' | 'filter';
	icon?: IconName;
	name?: string;
	label: string;
	onClick?: (event?: MouseEvent<HTMLDivElement>) => void;
}

function Item({ type, icon, name, label, onClick }: ItemProps): JSX.Element {
	return (
		<Term
			autoColumns="auto"
			align="center"
			justify="start"
			onClick={onClick}
			data-type={type}
			data-name={name}>
			{icon && <Icon name={icon} size={1.15} />}
			{label}
			<Icon name="close" />
		</Term>
	);
}

export default function CurrentFilter(): JSX.Element {
	const qs = useQueryState();
	const filters = useTrainingLibraryFilters();

	if (!filters.isFiltered) {
		return null;
	}

	const combineMinMax = (min?: number, max?: number): string => {
		if (min && !max) {
			return `${min}+`;
		} else if (!min && max) {
			return `0-${max}`;
		} else {
			return `${min}-${max}`;
		}
	};

	const removeTitle = () => {
		qs.remove('title');
		qs.commit();
	};

	const removeTag = (event: MouseEvent<HTMLDivElement>) => {
		filters.removeTag(event.currentTarget.dataset.name);
	};

	const removeDuration = () => {
		qs.remove('minTime');
		qs.remove('maxTime');
		qs.commit();
	};

	const removeAge = () => {
		qs.remove('minAge');
		qs.remove('maxAge');
		qs.commit();
	};

	const removeParticipants = () => {
		qs.remove('minParticipants');
		qs.remove('maxParticipants');
		qs.commit();
	};

	return (
		<Wrapper>
			{filters.hasTitleFilter && (
				<Item
					type="search"
					icon="search"
					label={filters.title}
					onClick={removeTitle}
				/>
			)}
			{filters.hasTagsFilter && (
				<Fragment>
					{filters.getTags().map((tag: string) => {
						let label = tag;

						if (tags.includes(tag)) {
							label = getAlias(tag);
						}

						return (
							<Item
								type="tag"
								icon="tag"
								name={tag}
								label={label}
								onClick={removeTag}
							/>
						);
					})}
				</Fragment>
			)}
			{filters.hasTimeFilter && (
				<Item
					type="filter"
					icon="timer"
					label={combineMinMax(filters.minTime, filters.maxTime)}
					onClick={removeDuration}
				/>
			)}
			{filters.hasAgeFilter && (
				<Item
					type="filter"
					icon="age"
					label={combineMinMax(filters.minAge, filters.maxAge)}
					onClick={removeAge}
				/>
			)}
			{filters.hasParticipantsFilter && (
				<Item
					type="filter"
					icon="nav-members"
					label={combineMinMax(
						filters.minParticipants,
						filters.maxParticipants
					)}
					onClick={removeParticipants}
				/>
			)}
			<Item type="reset" label={t('Clear all')} onClick={filters.reset} />
		</Wrapper>
	);
}
