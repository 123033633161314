import { ReactNode } from 'react';

export const symbol_add_home: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M718-290v94q0 7 5.143 11.5t12 4.5q6.857 0 11.357-5.1 4.5-5.1 4.5-11.9v-93h94q7 0 11.5-5.143t4.5-12q0-6.857-4.5-11.357Q852-323 845-323h-94v-94q0-7-4.5-11.5t-11.357-4.5q-6.857 0-12 4.5T718-417v94h-94q-7 0-11.5 4.5t-4.5 11.357q0 6.857 5.1 12T625-290h93Zm14.5 170Q655-120 600-175.5T545-307q0-78.435 54.99-133.717Q654.98-496 733-496q77 0 132.5 55.283Q921-385.435 921-307q0 76-55.5 131.5t-133 55.5ZM160-260v-390q0-14.25 6.375-27T184-698l260-195q8.295-6 17.344-9 9.049-3 18.853-3 9.803 0 18.717 3 8.915 3 17.086 9l263 198q5 4 7 9.44 2 5.44 2 11.56 0 12.75-8.564 21.375T758.213-644q-5.213 0-9.801-1.5T740-650L480-845 220-650v390h235q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T455-200H220q-24.75 0-42.375-17.625T160-260Zm320-293Z"
		/>
	</svg>
);
