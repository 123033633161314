import { ReactNode } from 'react';

export const symbol_laptop_windows: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M30-160q-12.75 0-21.375-8.675Q0-177.351 0-190.175 0-203 8.625-211.5T30-220h111v-42q-24 0-42-18t-18-42v-458q0-24 18-42t42-18h678q24 0 42 18t18 42v458q0 24-18 42t-42 18v42h111q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T930-160H30Zm111-162h678v-458H141v458Zm0 0v-458 458Z"
		/>
	</svg>
);
