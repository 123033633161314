import { t } from '@transifex/native';
import { Component } from 'react';
import styled from 'styled-components';

import * as sdk from 'pkg/core/sdk';
import Link from 'pkg/router/Link';
import * as routes from 'pkg/router/routes';

import CardBase, * as Card from 'components/Card';
import SectionTitle from 'components/SectionTitle';
import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import { Strain } from 'components/user/activity/Indicator';
import PhysicalStrainIndicator from 'components/legacy/PhysicalStrainIndicator';
import Explanation from 'components/help/FeatureDescription';
import Column from 'components/layout/column';

import Button from 'design/button';

const PhysicalStrainCardBase = styled(CardBase)`
	margin-bottom: var(--spacing-5);
`;

const AverageWrapper = styled.div`
	padding-bottom: var(--spacing-5);
	position: relative;
`;

const AverageBorder = styled.div`
	position: absolute;
	bottom: 0;
	left: ${(props) => `calc( ${props.offset}% - 4px )`};
	right: 0;
	height: calc(100% - 16px);
	width: 2px;
	border-left: var(--palette-gray-200);
	z-index: 5;
`;

const CardBody = styled(Card.Body)`
	overflow: hidden;
	padding-top: var(--spacing-6);
`;

const ListItem = styled.li`
	flex-direction: row;
	flex-wrap: wrap;
	flex: 1;
	width: 100%;
	margin: 0;
	padding: var(--spacing-2) 0;
	border-radius: var(--radius-2);
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	background: transparent;
`;

const PhysicalStrainList = styled.ul`
	padding-top: 0;
	margin-bottom: var(--spacing-3);
	overflow: hidden;
	position: relative;
	list-style: none;
`;

export default class GroupPhysicalStrain extends Component {
	_mounted = false;

	static defaultProps = {
		onlyStrain: false,
		showMaxStrains: false,
	};

	state = {
		results: null,
		isLoading: true,
		isHelpVisible: false,
		showList: false,
	};

	async fetchUsers(cp, results) {
		const response = await sdk.get('/groups/:groupId/users', {
			groupId: this.props.groupId,
			preloadactivities: 1,
			page: cp,
		});

		const result = await response.json();

		results.push(...result.records);

		if (result.pageCount > 1 && result.page !== result.pageCount) {
			this.fetchUsers(cp + 1, results);
		}

		if (!this._mounted) {
			return;
		}

		if (result.page === result.pageCount) {
			this.setState({
				results,
				isLoading: false,
			});
		}

		if (results.length === 0) {
			this.setState({ isLoading: false });
		}
	}

	componentDidMount() {
		if (this.props.groupId) {
			this.fetchUsers(1, []);
		}

		this._mounted = true;

		if (!this.props.onlyStrain) {
			this.setState({ showList: true });
		}
	}

	componentWillUnmount() {
		this._mounted = false;
	}

	get items() {
		let items = this.state.results;

		if (!items) return [];

		items = items.filter(({ role }) => role === 1);

		items.sort(
			(a, b) =>
				Number(
					b.user.meta.find((m) => m.key === 'current-physical-strain')?.value ||
						0
				) -
				Number(
					a.user.meta.find((m) => m.key === 'current-physical-strain')?.value ||
						0
				)
		);

		return items;
	}

	renderUserStrain = (membership, n) => {
		let { user } = membership;

		const userPhysicalStrain = user.meta.find(
			(m) => m.key === 'current-physical-strain'
		)?.value;

		return (
			<ListItem key={`item_${n}`}>
				<Strain>
					<PhysicalStrainIndicator
						narrow
						label={
							<Link
								href={routes.User.Profile.Show(
									user.organizationId,
									this.props.groupId,
									user.id,
									'stats'
								)}>
								{user.firstName} {user.lastName}
							</Link>
						}
						strainLevel={userPhysicalStrain}
					/>
				</Strain>
			</ListItem>
		);
	};

	get usersPhysicalStrain() {
		if (!this.state.results) return null;

		return this.items.map(this.renderUserStrain);
	}

	get usersWithMaxPhysicalStrain() {
		const maxUsers = this.items.filter(({ user }) => {
			const currentPhysicalStrain = user.meta.find(
				(m) => m.key === 'current-physical-strain'
			)?.value;

			if (currentPhysicalStrain > 80) {
				return true;
			}

			return false;
		});

		return maxUsers.map(this.renderUserStrain);
	}

	get calcAverageStrain() {
		if (this.items.length === 0) {
			return 0;
		}

		const averageSum = this.items.reduce(
			(sum, { user }) =>
				sum +
					Number(
						user.meta.find((m) => m.key === 'current-physical-strain')?.value
					) || 0,
			0
		);

		return Number(averageSum / this.items.length).toFixed(2);
	}

	get averagePhysicalStrain() {
		return (
			<AverageWrapper>
				<PhysicalStrainIndicator
					strainLevel={this.calcAverageStrain}
					thick
					label={t(`Team average physical strain`)}
				/>
			</AverageWrapper>
		);
	}

	get userList() {
		if (!this.props.hideUser)
			if (this.state.showList) {
				return (
					<PhysicalStrainList>
						<AverageBorder offset={this.calcAverageStrain} />

						{this.usersPhysicalStrain}
					</PhysicalStrainList>
				);
			}

		if (this.props.showMaxStrains) {
			return (
				<PhysicalStrainList>
					{this.usersWithMaxPhysicalStrain}
				</PhysicalStrainList>
			);
		}

		return null;
	}

	toggleShowList = () => {
		this.setState({ showList: !this.state.showList });
	};

	get actions() {
		if (this.props.onlyStrain && this.props.showAllPlayersButton) {
			return (
				<Button block onClick={this.toggleShowList}>
					{this.state.showList ? t(`Hide`) : t(`Show all players`)}
				</Button>
			);
		}

		return null;
	}

	get headerViewTrainingStatisticsButton() {
		if (!this.props.showTrainingStatisticsButton) {
			return null;
		}

		return (
			<LargeScreen>
				<Button
					icon="chevron"
					iconPosition="right"
					href={routes.Statistics.Training(
						this.props.organizationId,
						this.props.groupId
					)}>
					{t(`View training statistics`)}
				</Button>
			</LargeScreen>
		);
	}

	get bodyViewTrainingStatisticsButton() {
		if (!this.props.showTrainingStatisticsButton) {
			return null;
		}

		return (
			<SmallScreen>
				<Button
					block
					href={routes.Statistics.Training(
						this.props.organizationId,
						this.props.groupId
					)}>
					{t(`View training statistics`)}
				</Button>
			</SmallScreen>
		);
	}

	render() {
		return (
			<Column>
				<SectionTitle>
					{t(`Estimated physical strain`)}
					<Explanation
						title={t(`What is estimated physical strain?`)}
						body={t(
							`Estimated physical strain is estimated from the strain that the players have assigned to their acitivities during the last four days.`
						)}
					/>
					{this.headerViewTrainingStatisticsButton}
				</SectionTitle>
				<PhysicalStrainCardBase key="card">
					<CardBody key="card__body">
						{this.averagePhysicalStrain}

						{!this.state.isLoading && this.userList}

						{this.actions}

						{this.bodyViewTrainingStatisticsButton}
					</CardBody>
				</PhysicalStrainCardBase>
			</Column>
		);
	}
}
