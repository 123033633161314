import { Fragment, MouseEvent, PointerEvent } from 'react';
import { t } from '@transifex/native';

import { cssClasses } from 'pkg/css/utils';
import * as models from 'pkg/api/models';
import { isTouchDevice } from 'pkg/platform';
import copyString from 'pkg/copyString';
import { useCurrentAccountUserIds, useCurrentUser } from 'pkg/identity';
import * as actions from 'pkg/actions';
import * as emoji from 'pkg/emoji';
import useTooltip from 'pkg/hooks/useTooltip';

import { useChatContext } from 'routes/chat/view';

import Icon from 'components/icon';
import { LargeScreen } from 'components/MediaQuery';

import { useThreadContext } from 'components/chat/thread';
import Reactions, {
	findBySlugAndUserId,
	ReactionPayload,
} from 'components/emoji/reactions';
import EmojiPicker from 'components/emoji/picker';

import { useDialog } from 'design/dialog';
import ActionSheet, {
	ActionSheetAction,
	useActionSheet,
} from 'design/actionsheet';

import * as css from './styles.css';

interface ChatMessageActionsProps {
	align: string;
	showContextMenu: boolean;

	handleCloseContextMenu: () => void;

	chat: models.chat.Chat;
	chatMessage: models.chatMessage.ChatMessage;
}

export default function ChatMessageActions({
	align,
	showContextMenu = false,
	handleCloseContextMenu,
	chatMessage,
	chat,
}: ChatMessageActionsProps) {
	const chatContext = useChatContext();
	const threadContext = useThreadContext();
	const activeUserId = useCurrentUser()?.id;
	const activeUserIds = useCurrentAccountUserIds();
	const actionSheet = useActionSheet();

	const iconSize = 1.2;
	const selfChatUser = models.chat.findMyChatUser(chat, activeUserIds);
	const isMyMessage = selfChatUser.userId === chatMessage.userId;

	const handleQuoteMessage = (event: MouseEvent<HTMLDivElement>) => {
		event.stopPropagation();

		chatContext.setState({
			quotedMessageId: chatMessage.id,
		});
	};

	const handleArchiveMessage = async () => {
		const [ok, message] = await models.chatMessage.update({
			chatMessage: chatMessage,
			archive: true,
		});

		if (ok) {
			threadContext.chatMessageCollection.replaceRecord(message);
		}
	};

	const archiveMessage = t('Are you sure you want to archive this message?');
	const confirmArchive = useDialog({
		message: archiveMessage,
		onConfirm: handleArchiveMessage,
	});

	const canBeCopied: boolean =
		chatMessage.attachmentId !== 0 &&
		chatMessage.type === 'message' &&
		chatMessage.content !== '';

	const handleSelect = (raw: string) => {
		handleReaction({
			slug: emoji.toSlug(raw),
			data: raw,
		});

		if (hideTooltip) {
			hideTooltip();
		}
	};

	const { showTooltip, hideTooltip, tooltip } = useTooltip(
		<div className={css.reactionPicker} data-align={align}>
			<EmojiPicker onSelect={handleSelect} />
		</div>,
		{
			persist: true,
			offset: {
				y: 10,
			},
		}
	);

	if (chatMessage.archivedAt) {
		return null;
	}

	const handleAddReaction = (event: PointerEvent<HTMLDivElement>) => {
		event.stopPropagation();
		showTooltip(event);
	};

	const handleReaction = async (reaction: ReactionPayload) => {
		const chatReaction = findBySlugAndUserId(
			chatMessage.reactions,
			reaction.slug,
			activeUserId
		);

		if (!chatReaction) {
			const tempId = Number.MAX_SAFE_INTEGER;

			const reactionPayload = {
				emojiSlug: reaction.slug,
				chatId: chat.id,
				chatMessageId: chatMessage.id,
				userId: activeUserId,
			};

			const message = threadContext.chatMessageCollection.records.find(
				(chatMessage: models.chatMessage.ChatMessage) =>
					chatMessage?.id === reactionPayload.chatMessageId
			);

			const tempReaction: models.chatReaction.ChatReaction = {
				id: tempId,
				...reactionPayload,
				user: selfChatUser.user,
			};

			// @NOTE Add a temporary reaction
			message.reactions = [...(message.reactions || []), tempReaction];

			threadContext.chatMessageCollection.replaceRecord(message);

			const newReaction = await actions.chatReactions.create(reactionPayload);

			// Filter out matching reaction without links and temp reaction
			const nextReactions = [...message.reactions].filter(
				(reaction: models.chatReaction.ChatReaction) =>
					reaction.id !== newReaction.id && reaction.id !== tempId
			);

			message.reactions = [...nextReactions, newReaction];

			threadContext.chatMessageCollection.replaceRecord(message);
		} else {
			actions.chatReactions.remove(chatReaction);
		}

		hideTooltip();
		actionSheet.hide();
	};

	if (isTouchDevice()) {
		const actionSheetActions: ActionSheetAction[] = [
			{
				label: t('Reply to message'),
				icon: 'reply',
				onInteract: () => {
					chatContext.setState({
						quotedMessageId: chatMessage.id,
					});
				},
			},
		];

		if (canBeCopied) {
			actionSheetActions.push({
				label: t('Copy message'),
				icon: 'collection',
				onInteract: () => {
					copyString(chatMessage.content);
				},
			});
		}

		if (isMyMessage) {
			actionSheetActions.push({
				label: t('Archive message'),
				icon: 'delete',
				onInteract: handleArchiveMessage,
				cautious: true,
			});
		}

		return (
			<Fragment>
				{showContextMenu && (
					<ActionSheet
						actions={actionSheetActions}
						onHide={handleCloseContextMenu}>
						<Reactions pickerOnly onReaction={handleReaction} />
					</ActionSheet>
				)}
			</Fragment>
		);
	}

	const addReactionAction = (
		<div
			className={cssClasses(css.iconWrapper)}
			onClick={tooltip ? hideTooltip : handleAddReaction}>
			<Icon name="add-reaction" size={iconSize} />
			{tooltip}
		</div>
	);

	return (
		<LargeScreen>
			<div
				className={cssClasses(
					css.wrapper,
					align === 'right' ? css.rightAligned : ''
				)}>
				{isMyMessage && (
					<div
						className={cssClasses(css.iconWrapper, css.delete)}
						onClick={confirmArchive}>
						<Icon name="delete" size={iconSize} />
					</div>
				)}
				{align === 'right' && addReactionAction}
				<div
					className={cssClasses(css.iconWrapper)}
					onClick={handleQuoteMessage}>
					<Icon name="reply" size={iconSize} />
				</div>
				{align !== 'right' && addReactionAction}
			</div>
		</LargeScreen>
	);
}
