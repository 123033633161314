import { ReactNode } from 'react';

export const symbol_photo_auto_merge: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M278-218q-24 0-42-18t-18-42v-502q0-24 18-42t42-18h296q-2 15-3.5 30t.5 30H278v496q46-58 111-92t140.106-34Q603-410 668.5-375.5T780-284q0 1-.5.5t.5-.5v-296q15 0 30-1t30-5v308q0 24-18 42t-42 18H278Zm251-403ZM173-41q-25 3-44.5-12T106-93L42-591q-3-25 12.68-44.5T95-658l63-7v61l-57 6 65 498 477-58h123q0 21-15.5 35.5T713-106L173-41Zm-7-59Zm588-644-10.154 31q-1.846 6-7.031 9.5t-11.408 3.5Q714-700 708-708.308q-6-8.307-2-18.692l57-159.036q2-5.964 7.5-9.964t12.5-4h14q7 0 12.5 4t7.5 10.034l57 159.909Q878-716 872-708q-6 8-16.645 8-6.774 0-12.097-3.714-5.323-3.715-7.258-9.286l-11-31h-71Zm10-30h51l-25-81-26 81ZM529-350q-48 0-92 18.5T361-278h336q-32-35-76-53.5T529-350Zm0-383q46 0 79 33t33 79q0 46-33 78.5T529-510q-46 0-79-32.291T417-621q0-46 33-79t79-33Zm0 60q-22 0-37 15t-15 37q0 22 15 37t37 15q22 0 37-15t15-37q0-22-15-37t-37-15Z"
		/>
	</svg>
);
