import DateTime from 'pkg/datetime';

export function clamp(value: number, min: number, max: number): number {
	if (value < min) return min;
	if (value > max) return max;

	return value;
}

export function precise(value: number, fractionDigits: number = 1): number {
	return Number.parseFloat(value.toFixed(fractionDigits));
}

export function fillCount(
	count: number,
	startAtZero: boolean = false
): number[] {
	const index: number = startAtZero ? 0 : 1;

	return Array.from(Array(count + index).keys()).slice(index);
}

export function average(values: number[]): number {
	if (!values.length) return 0;

	return values.reduce((a: number, b: number) => a + b) / values.length;
}

export function compact(value: number): string {
	const fmt = Intl.NumberFormat(DateTime.getLocale(), { notation: 'compact' });

	return fmt.format(value);
}
