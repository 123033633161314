import { ReactNode } from 'react';

export const symbol_theaters: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M220-180v30.175q0 12.825-8.675 21.325-8.676 8.5-21.5 8.5-12.825 0-21.325-8.625T160-150v-660q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T220-810v30h120v-30.175q0-12.825 8.625-21.325T370-840h220q12.75 0 21.375 8.625T620-810v30h120v-30.175q0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.625T800-810v660q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T740-150v-30H620v30.175q0 12.825-8.625 21.325T590-120H370q-12.75 0-21.375-8.625T340-150v-30H220Zm0-60h120v-120H220v120Zm0-180h120v-120H220v120Zm0-180h120v-120H220v120Zm400 360h120v-120H620v120Zm0-180h120v-120H620v120Zm0-180h120v-120H620v120ZM400-180h160v-600H400v600Zm0-600h160-160Z"
		/>
	</svg>
);
