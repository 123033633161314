import { ReactNode } from 'react';

export const symbol_north_west: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M260-658v264q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T200-394v-336q0-12.75 8.625-21.375T230-760h336q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T566-700H302l477 477q9 9 9 21t-9 21q-9 9-21 9t-21-9L260-658Z"
		/>
	</svg>
);
