import { ReactNode } from 'react';

export const symbol_sweep: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M441-240q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T441-300h162q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T603-240H441Zm-200-85 374-374q9-9 21-8.5t21 9.342q9 8.842 9 21T657-656L262-261q-9 9-21 9t-21-9L36-445q-9-8.8-8.5-20.9.5-12.1 9.342-21.1 8.842-9 21-9T79-487l162 162Zm362-90q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T603-475h165q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T768-415H603Zm165-175q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T768-650h162q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T930-590H768Z"
		/>
	</svg>
);
