import * as sdk from 'pkg/core/sdk';
import * as endpoints from 'pkg/api/endpoints/auto';

export interface RelationProps {
	userIds: number[];
	productId: number;
	validUntil: number;
}

export const createRelation = (payload: RelationProps): Promise<Response> =>
	sdk.post(endpoints.UserProduct.Create(), {}, payload);

export const removeRelation = (id: number): Promise<Response> =>
	sdk.destroy(endpoints.UserProduct.Delete(id));

export const updateRelation = (
	id: number,
	validUntil: number
): Promise<Response> =>
	sdk.patch(endpoints.UserProduct.Update(id), {}, { validUntil });
