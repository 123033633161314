const icon = {
	name: 'cards',
	objects: [
		{
			type: 'path',
			attributes: {
				d: 'M11.1776 4.7231C10.9785 4.7231 10.7793 4.76906 10.5878 4.838L4.95016 7.17426C4.16885 7.49598 3.79351 8.39219 4.11523 9.16584L7.91453 18.3194C8.15199 18.9092 8.71116 19.2616 9.30097 19.2769C9.50013 19.2769 9.70695 19.2539 9.90611 19.162L15.5514 16.8257C16.1259 16.5883 16.4783 16.0214 16.4936 15.4469C16.5013 15.2555 16.463 15.0333 16.394 14.8342L12.5641 5.68059C12.342 5.09077 11.7751 4.73076 11.1776 4.7231ZM13.8433 4.7231L16.486 11.1191V6.25508C16.486 5.84877 16.3245 5.45911 16.0372 5.1718C15.7499 4.8845 15.3603 4.7231 14.954 4.7231H13.8433ZM18.0256 5.90272V12.8196L19.8869 8.33091C20.201 7.5496 19.841 6.66105 19.052 6.33934L18.0256 5.90272ZM11.1776 6.2321L14.9923 15.4393L9.33161 17.7832L5.51699 8.58368',
			},
		},
	],
};

export default icon;
