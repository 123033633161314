import { Fragment, ChangeEvent, ReactNode } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import { Field } from 'pkg/api/models/form';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import * as Card from 'components/Card';
import Icon, { IconName } from 'components/icon';
import Label from 'components/label';

import * as Sortable from 'components/dnd/sortable';
import InfoBox from 'components/form/info-box';
import Row from 'components/layout/row';
import FormRow from 'components/form/Row';
import Section from 'components/form/Section';
import { Spinner } from 'components/loaders/spinner';

import * as ContextMenu from 'design/context_menu';
import Button from 'design/button';

import * as css from './style.css';

interface FieldsProps {
	data: { fields: Field[] };
	isLoading: boolean;
	onAddField: () => void;
	onDrag: (fields: Field[]) => void;
	handleOpenFieldsModal: () => void;
	handleOpenCustomFieldModal: (field?: Field, isNewSection?: boolean) => void;
	handleOpenDefaultFieldModal: (field?: Field) => void;
	removeSingleField: (field: Field) => void;
	handleToggleRequired: (field: Field) => void;
	onChange?: (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
}

const fieldIcons: { [key: string]: IconName } = {
	string: 'short-text',
	long_text: 'long-text',
	number: 'number',
	email: 'mail',
	phone: 'long-text',
	choice: 'single-choice',
	multi_choice: 'checkbox-checked',
	date: 'nav-events',
	country: 'location',
	section: 'info-circle',
};

const Fields: React.FC<React.PropsWithChildren<FieldsProps>> = ({
	data,
	isLoading,
	onAddField,
	onDrag,
	handleOpenCustomFieldModal,
	handleOpenDefaultFieldModal,
	removeSingleField,
	handleToggleRequired,
}) => {
	const theme: Sortable.Theme = {
		item: css.item,
		activeItem: css.active,
		overlayItem: css.overlay,
	};

	const selectedDefaultFields = getContactFields().filter((field) =>
		data.fields.find((prevField) => prevField.key === field.key)
	);

	const hasUniqueLabels =
		[...new Set(data.fields.map((field) => field.label.toLowerCase()))]
			.length === data.fields.length;

	if (isLoading) {
		return <Spinner />;
	}

	const renderItem = (item: Field): ReactNode => {
		const isCustomField = !item.userFieldId && !item.key.startsWith('user_');

		const handleEdit = () =>
			isCustomField
				? handleOpenCustomFieldModal(item)
				: handleOpenDefaultFieldModal(item);

		return (
			<Sortable.Item id={item.key}>
				<SingleField
					item={item}
					isCustomField={isCustomField}
					dragElement={
						<div className={css.handle}>
							<Icon name="drag-handle" size={1.5} />
						</div>
					}
					onEdit={handleEdit}
					onRemove={removeSingleField}
					onToggleRequired={handleToggleRequired}
				/>
			</Sortable.Item>
		);
	};

	return (
		<Section
			icon="edit"
			title={`2. ${t('Add form fields')}`}
			description={t('Select the data that you want to collect on this form.')}>
			{data.fields && data.fields.length > 0 && (
				<FormRow>
					<Sortable.Container
						items={data.fields}
						idProp="key"
						renderWith={renderItem}
						onChange={onDrag}
						theme={theme}
					/>
				</FormRow>
			)}
			{!hasUniqueLabels && (
				<InfoBox color="red">{t('The field labels has to be unique')}</InfoBox>
			)}
			<Row collapseOnSmallScreens autoColumns="max-content">
				<ContextMenu.Menu
					toggleWith={
						<Button secondary icon="add">
							{t('Add question')}
						</Button>
					}>
					<ContextMenu.Item
						icon="add"
						onClick={onAddField}
						disabled={
							selectedDefaultFields.length === getContactFields().length
						}
						testid="form.create.add_fields">
						{t('Default field')}
					</ContextMenu.Item>
					<ContextMenu.Item
						icon="add"
						onClick={() => handleOpenCustomFieldModal()}
						testid="form.create.add_custom_field">
						{t('Custom question')}
					</ContextMenu.Item>
				</ContextMenu.Menu>

				<Button
					secondary
					icon="add"
					onClick={() => handleOpenCustomFieldModal(null, true)}
					testid="form.create.add_section">
					{t('Add section')}
				</Button>
			</Row>
		</Section>
	);
};

const SingleField: React.FC<
	React.PropsWithChildren<{
		item: Field;
		dragElement?: ReactNode;
		isCustomField: boolean;
		onEdit: () => void;
		onRemove: (field: Field) => void;
		onToggleRequired: (field: Field) => void;
	}>
> = ({
	item,
	dragElement,
	isCustomField,
	onEdit,
	onToggleRequired,
	onRemove,
}) => {
	const handleEdit = () => {
		onEdit();
	};

	const handleRemove = () => {
		onRemove(item);
	};

	const handleToggleRequired = () => {
		onToggleRequired(item);
	};

	return (
		<Card.Base>
			<div className={css.wrapper}>
				<div>
					<div className={css.left}>
						{dragElement}
						<div className={css.iconWrapper}>
							<Icon
								name={fieldIcons[item.type]}
								size={1.5}
								fill={styles.palette.blue[500]}
							/>
						</div>
						<div className={css.wordBreak}>
							<div className={css.heading}>{item.label}</div>
							{item.description && <span>{item.description}</span>}
							<SmallScreen>
								<div className={css.labels}>
									{!isCustomField && (
										<Label small className={css.label} color="blue">
											{t('Default')}
										</Label>
									)}
									{item.required && (
										<Label small className={css.label} color="gray">
											{t('Required')}
										</Label>
									)}
								</div>
							</SmallScreen>
						</div>
					</div>
				</div>
				<div className={css.right}>
					<LargeScreen>
						{!isCustomField && (
							<Label className={css.label} color="blue">
								{t('Default')}
							</Label>
						)}
						{item.required && (
							<Label className={css.label} color="gray">
								{t('Required')}
							</Label>
						)}
					</LargeScreen>
					<ContextMenu.Menu
						toggleWith={<ContextMenu.ButtonTrigger icon="more_horiz" small />}>
						{item.type !== models.form.FieldTypes.Section && (
							<Fragment>
								<ContextMenu.Item onClick={handleToggleRequired}>
									{item.required ? t('Remove required') : t('Set required')}
								</ContextMenu.Item>
								<ContextMenu.Divider />
							</Fragment>
						)}
						<ContextMenu.Item onClick={handleEdit}>
							{t('Edit')}
						</ContextMenu.Item>
						<ContextMenu.Item caution onClick={handleRemove}>
							{t('Remove')}
						</ContextMenu.Item>
					</ContextMenu.Menu>
				</div>
			</div>
		</Card.Base>
	);
};

export const preSelectedFields = () => [
	{
		key: 'user_firstName',
		type: models.form.FieldTypes.ShortText,
		label: t('First name'),
		sortOrder: 0,
	},
	{
		key: 'user_lastName',
		type: models.form.FieldTypes.ShortText,
		label: t('Last name'),
		sortOrder: 1,
	},
	{
		key: 'user_email',
		type: models.form.FieldTypes.Email,
		label: t('Email'),
		sortOrder: 2,
	},
];

export const getContactFields = (isSwedishGroup?: boolean): Field[] => {
	const fields = [
		...preSelectedFields(),
		{
			key: 'user_birthDate',
			type: models.form.FieldTypes.Date,
			label: t('Date of birth'),
		},
		{
			key: 'user_phoneNumber',
			type: models.form.FieldTypes.Phone,
			label: t('Phone number'),
		},
		{
			key: 'user_companyName',
			type: models.form.FieldTypes.ShortText,
			label: t('Company name'),
		},
		{
			key: 'user_sex',
			type: models.form.FieldTypes.Choice,
			values: [
				{ value: '0', label: 'unknown' },
				{ value: '1', label: 'male' },
				{ value: '2', label: 'female' },
				{ value: '9', label: 'not_applicable' },
			],
			label: t('Gender'),
		},
		{
			key: 'user_address',
			type: models.form.FieldTypes.ShortText,
			label: t('Address'),
		},
		{
			key: 'user_region',
			type: models.form.FieldTypes.ShortText,
			label: t('Region'),
		},
		{
			key: 'user_city',
			type: models.form.FieldTypes.ShortText,
			label: t('City'),
		},
		{
			key: 'user_postalCode',
			type: models.form.FieldTypes.ShortText,
			label: t('Zip code'),
		},
		{
			key: 'user_country',
			type: models.form.FieldTypes.Country,
			label: t('Country'),
		},
		{
			key: 'user_nationality',
			type: models.form.FieldTypes.Country,
			label: t('Nationality'),
		},
	];

	if (isSwedishGroup) {
		fields.push({
			key: 'user_lokSwedishPersonalId',
			type: models.form.FieldTypes.ShortText,
			label: t('Personal ID Number'),
		});
	}

	return fields;
};

export default Fields;
