import { ReactNode } from 'react';

export const symbol_bring_your_own_ip: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-80q-83 0-156-31.5t-127-85.82q-54-54.321-85.5-127.754T80-482q0-82.754 31.5-155.537T197-764.16Q251-818 324-849t156-31q15 0 30.5 1.5T541-875q13 2 21 11.429 8 9.428 8 23.571 0 11-9 19t-20 6q-15-2-30.333-3.5Q495.333-820 480-820q-42 35-62 86t-34 104h156q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T540-570H373q-3 22-4.5 44t-1.5 44q0 22.75 1.5 45.5T373-391h214.576q3.424-23 4.924-45.667Q594-459.333 594-482q0-14-.5-29t-2.5-29q-1-12 7-21t20.351-9q13.123 0 22.386 9T651-539q1 14 2 28.5t1 28.5q0 22.667-2 45.333Q650-414 648-391h160.219q5.891-22.75 8.836-45.5Q820-459.25 820-482q0-14-1.5-28.5T815-539q-2-12 5.5-21.5t19.735-9.5Q854-570 864-561.5t12 22.5q2 14 3 28.5t1 28.5q0 83.493-31.5 156.926Q817-251.641 763-197.32 709-143 636-111.5 563-80 480-80ZM151.712-391H311q-2-22.75-3-45.5t-1-45.5q0-22 1.25-44t3.75-44H151.781q-5.891 21.667-8.836 43.333Q140-505 140-482.2q0 22.8 2.928 45.6t8.784 45.6ZM172-630h151q10-48 27.5-93t43.5-86q-73 18-131 65t-91 114Zm533-30q-18.75 0-31.875-13.125T660-705v-130q0-18.75 13.125-31.875T705-880h130q18.75 0 31.875 13.125T880-835v130q0 18.75-13.125 31.875T835-660H705Zm70-100h70q6 0 10.5-4.5T860-775v-70q0-6-4.5-10.5T845-860h-70q-6 0-10.5 4.5T760-845v70q0 6 4.5 10.5T775-760ZM395-150q-27-42-44.5-87.5T321.591-331H172q35 66 93.5 111.5T395-150Zm85 12q38-39 61-89t36-104H384q12 54 35 103.5t61 89.5Zm86-13q71-22 129-68t93-112H639.339Q628-283 610.5-237.5T566-151Z"
		/>
	</svg>
);
