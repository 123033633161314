import { useState, memo } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import { useOrganizationIdentity } from 'pkg/identity/organization';

import Search from 'containers/chat/Search';
import ChatListItem from 'containers/chat/ChatListItem';

import Icon from 'components/icon';

import InlinePlaceholder from 'design/placeholders/inline';

const Wrapper = styled.div`
	display: flex;
	flex-flow: column;
	grid-area: chatlist;
	position: relative;
	min-height: 100%;
	min-width: 0;
	border-right: var(--palette-gray-300) 1px solid;
	-webkit-overflow-scrolling: touch;
	overflow: scroll;

	@media ${styles.breakpoint.small} {
		overflow: visible;
	}

	${InlinePlaceholder} {
		margin: auto 0;
	}
`;

interface ChatListProps {
	currentChatId: number;
	monitoredAccountId: number;

	replaceState?: boolean;

	chats: models.chat.Chat[];
}

function ChatList({
	currentChatId,
	monitoredAccountId,
	replaceState = false,
	chats,
}: ChatListProps) {
	const { users } = useOrganizationIdentity();
	const [filterValue, setFilterValue] = useState('');

	const sortedChats = chats.sort((a, b) => b.lastMessageId - a.lastMessageId);
	const filteredChats = models.chat.findAllBySearch(chats, filterValue, users);

	const renderedChats = filterValue.length > 0 ? filteredChats : sortedChats;
	const isSearching = filterValue.length > 0;
	const hasRenderedChats = renderedChats.length > 0;
	const renderSearch = hasRenderedChats || isSearching;

	return (
		<Wrapper>
			{renderSearch && <Search onChange={setFilterValue} />}

			{renderedChats.map((chat) => (
				<ChatListItem
					replaceState={replaceState}
					chat={chat}
					key={chat.id}
					currentChatId={currentChatId}
					monitoredAccountId={monitoredAccountId}
				/>
			))}

			{!isSearching && !hasRenderedChats && (
				<InlinePlaceholder>
					<Icon name="add-conversation" />
					{t('You have no conversations')}
				</InlinePlaceholder>
			)}

			{isSearching && !hasRenderedChats && (
				<InlinePlaceholder>
					<Icon name="search" />
					{t('No conversations found')}
				</InlinePlaceholder>
			)}
		</Wrapper>
	);
}

export default memo(ChatList);
