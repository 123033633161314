import { ReactNode } from 'react';

export const symbol_hr_resting: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M150-120q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-180h660q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-120H150Zm67-384q-29-27-42.5-64T161-644q0-81 55.5-138.5T352-840q36 0 69 13t59 38q26-25 59-38t69-13q81 0 136.5 58T800-643q0 39-14.5 74.5T744-505L535-302q-11 11-25 16.5t-30 5.5q-16 0-30-5.5T425-302L217-504Zm42-42 221 214 223-215q20-19 29-44.083 9-25.084 9-51.917 0-56-38.321-96t-93.739-40q-25.94 0-50.101 10.045Q534.679-758.909 517-740l-22 22q-3.081 3-6.932 4.5-3.852 1.5-7.96 1.5-4.108 0-7.608-1.5T466-718l-22-22q-19.2-18.824-42.6-29.412Q378-780 352-780q-54.973 0-92.987 40Q221-700 220-644q0 27.119 9.5 53.06Q239-565 259-546Zm221-10Z"
		/>
	</svg>
);
