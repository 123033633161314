import { ReactNode } from 'react';

export const symbol_universal_currency: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M310-440h-85q-10.833 0-17.917 7.116-7.083 7.117-7.083 18Q200-404 207.083-397q7.084 7 17.917 7h95q17 0 28.5-11.5T360-430v-75q0-14.875-12-24.938Q336-540 320-540h-70v-50h85q10.833 0 17.917-7.116 7.083-7.117 7.083-18Q360-626 352.917-633q-7.084-7-17.917-7H225q-10.833 0-17.917 7.083Q200-625.833 200-615v100q0 10.833 7.083 17.917Q214.167-490 225-490h85v50Zm290 7q-77 0-122.5 26.77Q432-379.459 432-334v7q0 6 4.5 10.5T447-312h306q6 0 10.5-4.5T768-327v-7q0-45.459-45.5-72.23Q677-433 600-433Zm0-55q33 0 56.5-23.5T680-568q0-33-23.5-56.5T600-648q-33 0-56.5 23.5T520-568q0 33 23.5 56.5T600-488ZM140-200q-24 0-42-18t-18-42v-440q0-24 18-42t42-18h680q24 0 42 18t18 42v440q0 24-18 42t-42 18H140Zm0-60h680v-440H140v440Zm0 0v-440 440Z"
		/>
	</svg>
);
