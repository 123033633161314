import { ReactNode } from 'react';

export const symbol_explore_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M689-687q3 3 4 7.5t-1 9.5L592-482q-4 7-11.5 8t-12.5-4l-90-90q-5-5-4.5-12.5T481-592l191-98q5-2 9.5-1t7.5 4ZM480-140q51.944 0 101.472-15Q631-170 675-199L500-374 289-265q-10 5-17.5-2.5T269-285l113-207-183-183q-29 44-44 93.528Q140-531.944 140-480q0 142.375 98.812 241.188Q337.625-140 480-140Zm0 60q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-64.175 19-125.088Q118-666 156-718l-74-74q-9-9.067-9-21.533Q73-826 82.053-835q9.052-9 21.5-9Q116-844 125-835l710 710q9 9 9 21t-9 21q-9.067 9-21.533 9Q801-74 792-83l-74-73q-52 38-112.912 57Q544.175-80 480-80Zm73-473ZM408-408ZM275-824q47-28 99-42t106-14q80 0 153 30t130 87q57 57 87 130t30 153q0 54-13.5 105T827-277q-6 11-18 13.5t-23-4.5q-11-7-13-19.5t4-23.5q21-39 32-81.5t11-87.5q0-142.375-98.812-241.188Q622.375-820 480-820q-45 0-88 11t-82 33q-11 6-22.5 4T268-784q-8-10-6-22t13-18Z"
		/>
	</svg>
);
