import { cssClasses } from 'pkg/css/utils';

import * as css from './styles.css';

interface SpinnerProps {
	size?: string;
	color?: string;
	duration?: string;
	center?: boolean;
	className?: string;
}

export const EllipsisIcon = (props: SpinnerProps) => (
	<svg viewBox="0 0 100 60" className={props.className}>
		<g transform="translate(25 30)">
			<circle
				cx="0"
				cy="0"
				r="6"
				fill="currentColor"
				transform="scale(0.0621662 0.0621662)">
				<animateTransform
					attributeName="transform"
					type="scale"
					begin="-0.2333333333333333s"
					calcMode="spline"
					keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
					values="0;1;0"
					keyTimes="0;0.5;1"
					dur={props.duration || '0.7s'}
					repeatCount="indefinite"
				/>
			</circle>
		</g>
		<g transform="translate(50 30)">
			<circle
				cx="0"
				cy="0"
				r="6"
				fill="currentColor"
				transform="scale(0.464313 0.464313)">
				<animateTransform
					attributeName="transform"
					type="scale"
					begin="-0.11666666666666665s"
					calcMode="spline"
					keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
					values="0;1;0"
					keyTimes="0;0.5;1"
					dur={props.duration || '0.7s'}
					repeatCount="indefinite"
				/>
			</circle>
		</g>
		<g transform="translate(75 30)">
			<circle
				cx="0"
				cy="0"
				r="6"
				fill="currentColor"
				transform="scale(0.89591 0.89591)">
				<animateTransform
					attributeName="transform"
					type="scale"
					begin="0s"
					calcMode="spline"
					keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
					values="0;1;0"
					keyTimes="0;0.5;1"
					dur={props.duration || '0.7s'}
					repeatCount="indefinite"
				/>
			</circle>
		</g>
	</svg>
);

export const Spinner = (props: SpinnerProps) => (
	<div
		className={cssClasses(css.spinner, props.center && css.center)}
		style={{
			width: props.size ? props.size : undefined,
			color: props.color ? props.color : undefined,
		}}>
		<EllipsisIcon {...props} />
	</div>
);
