import { t } from '@transifex/native';
import { Fragment, useState } from 'react';

import { createUserField, updateUserField } from 'pkg/actions/user_fields';

import * as models from 'pkg/api/models';

import Field from 'routes/group/settings/user-fields/list/Field';
import FieldModal from 'routes/group/settings/user-fields/modal';

import * as Draggable from 'components/Draggable';
import Heading from 'components/Heading';
import MaterialSymbol from 'components/material-symbols';

import FormComponent from 'components/form/Form';
import Column from 'components/layout/column';
import Row from 'components/layout/row';

import Button from 'design/button';

import * as css from './styles.css';

interface CategorySectionProps {
	category: models.userFieldCategories.UserFieldCategory;
	group: models.group.Group;
	refresh: () => void;
	onDrag: (userFields: models.userFields.UserField[]) => void;
	onReplace: (item: models.userFields.UserField) => void;
	onRemove: (itemId: number) => void;
	onCategoryEdit: (
		category: models.userFieldCategories.UserFieldCategory
	) => void;
	isEditingSort: boolean;

	fieldRefresh: () => void;
}

export default function CategorySection({
	category,
	group,
	onDrag,
	onRemove,
	onReplace,
	onCategoryEdit,
	isEditingSort,
	fieldRefresh,
}: CategorySectionProps): JSX.Element {
	const [showModal, setShowModal] = useState<boolean>(false);
	const [showList, setShowList] = useState<boolean>(true);
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [fieldInEdit, setFieldInEdit] =
		useState<models.userFields.UserField>(null);
	const [userFields, setUserFields] = useState<models.userFields.UserField[]>(
		category.userFields
	);

	const handleShowModal = () => {
		setShowModal(true);
	};

	const handleCloseModal = () => {
		setShowModal(false);
	};

	const onToggle = () => {
		setShowList((prevShowList) => !prevShowList);
	};

	const handleSaveField = async (data: models.userFields.UserField) => {
		const payload: Partial<models.userFields.UserField> = {
			groupId: group.id,
			label: data.label,
			description: data.description,
			required: !!data.required,
			visibility: data.visibility,
			type: data.type,
			userFieldCategoryId: category.id,
		};

		if (payload.type === models.userFields.UserFieldType.Reserved) {
			payload.key = data.key;
		}

		if (data.attachmentType) {
			payload.attachmentType = data.attachmentType;
		}

		if (data.values?.length > 0) {
			payload.values = data.values;
		}

		if (data.sortOrder) {
			payload.sortOrder = data.sortOrder;
		}

		if (!isEdit) {
			const [ok] = await createUserField(payload);

			if (ok) {
				fieldRefresh();
				return ok;
			}
		} else {
			const [ok] = await updateUserField(payload, fieldInEdit.id);

			if (ok) {
				fieldRefresh();
				return true;
			}
		}
	};

	const handleEditCategory = () => {
		onCategoryEdit(category);
	};

	const handleEditField = (field: models.userFields.UserField) => {
		setFieldInEdit(field);
		setIsEdit(true);
		setShowModal(true);
	};

	const handleReorder = (newOrder: models.userFields.UserField[]) => {
		setUserFields(newOrder);
		onDrag(newOrder);
	};

	// Sort userFields by sortOrder before rendering
	const sortedUserFields = [...userFields].sort(
		(a, b) => a.sortOrder - b.sortOrder
	);

	return (
		<Fragment>
			<Column>
				<Heading>
					{isEditingSort ? (
						<Row columns="10px 1fr">
							<MaterialSymbol variant="drag_indicator" className={css.drag} />
							<div onClick={handleEditCategory} className={css.headingTitle}>
								{category.title}
							</div>
						</Row>
					) : (
						<div onClick={handleEditCategory} className={css.headingTitle}>
							{category.title}
						</div>
					)}

					<Button
						onClick={onToggle}
						icon="expand_more"
						iconRotate={showList ? '180' : '0'}
						iconPosition="right"
						small
						iconSize={2}
						transparent>
						{showList ? t('Collapse') : t('Expand')}
					</Button>
				</Heading>

				{showList && sortedUserFields?.length > 0 && isEditingSort && (
					<Draggable.List items={sortedUserFields} onReorder={handleReorder}>
						{sortedUserFields.map((field) => (
							<Draggable.Item key={field.id} item={field}>
								<Field
									key={field.id}
									item={field}
									custom
									isReordering={isEditingSort}
									onEdit={handleEditField}
									onReplace={onReplace}
									onRemove={onRemove}
									onRefresh={fieldRefresh}
								/>
							</Draggable.Item>
						))}
					</Draggable.List>
				)}
				{!isEditingSort &&
					showList &&
					sortedUserFields?.length > 0 &&
					sortedUserFields.map((field) => (
						<Field
							key={field.id}
							item={field}
							custom
							isReordering={isEditingSort}
							onEdit={handleEditField}
							onReplace={onReplace}
							onRemove={onRemove}
							onRefresh={fieldRefresh}
						/>
					))}
				{showList && (
					<div>
						<Button icon="add" inline noPadding onClick={handleShowModal}>
							{t('New field')}
						</Button>
					</div>
				)}

				<FormComponent>
					{showModal && (
						<FieldModal
							existingFields={userFields ?? []}
							prevData={fieldInEdit}
							group={group}
							onDone={handleSaveField}
							onClose={handleCloseModal}
						/>
					)}
				</FormComponent>
			</Column>
		</Fragment>
	);
}
