import { ReactNode } from 'react';

export const symbol_communication: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M40-330v-13q0-54 54.5-70.5T190-430q41 0 95.5 16.5T340-343v13H40Zm149.956-155Q159-485 137-507.044q-22-22.045-22-53Q115-591 137.044-613q22.045-22 53-22Q221-635 243-612.956q22 22.045 22 53Q265-529 242.956-507q-22.045 22-53 22ZM399-426q-11 0-18-7t-7-17.545q0-5.273 7-18.455 8-11 12.5-23t4.5-25.021q0-13.022-4.5-25.5Q389-555 381-565q-3.111-4.143-5.056-8.7-1.944-4.557-1.944-9.529Q374-594 381.083-601q7.084-7 17.917-7 5 0 10 1.5t8 5.5q17 18 25 39.733 8 21.732 8 44.5Q450-494 441.5-472q-8.5 22-24.5 39-4 4-8.333 5.5-4.334 1.5-9.667 1.5Zm72.323 72Q460-354 453-361.083q-7-7.084-7-17.917 0-5 1.944-9.469 1.945-4.469 5.056-8.531 24-29 35.5-59.058t11.5-61Q500-548 488.5-578.5T453-637q-3.111-4.265-5.056-8.529-1.944-4.265-1.944-9.383Q446-666 453.122-673q7.121-7 18.013-7 5.865 0 10.806 2.727 4.941 2.728 9.059 7.273 30 36 45 74.378t15 78.5Q551-477 536-438.5T491-364q-4 5-8.79 7.5-4.791 2.5-10.887 2.5ZM620-330v-13q0-54 54.5-70.5T770-430q41 0 95.5 16.5T920-343v13H620Zm149.956-155Q739-485 717-507.044q-22-22.045-22-53Q695-591 717.044-613q22.045-22 53-22Q801-635 823-612.956q22 22.045 22 53Q845-529 822.956-507q-22.045 22-53 22Z"
		/>
	</svg>
);
