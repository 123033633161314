import { ReactNode } from 'react';

export const symbol_queue_play_next: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M868-230 768-330q-10-9.957-10-22.979Q758-366 767.967-376q9.966-10 23-10Q804-386 814-376l126 125q9 9 9 21t-9 21L814-83q-9.957 10-22.979 10Q778-73 768-83.183q-10-10.184-10-23.5Q758-120 768-130l100-100ZM360-120q-12.75 0-21.375-8.625T330-150v-50H140q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v267q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T820-513v-267H140v520h516q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T656-200h-26v50q0 12.75-8.625 21.375T600-120H360Zm89-379v100q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T509-399v-100h100q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T609-559H509v-100q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T449-659v100H349q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T349-499h100ZM140-260v-520 520Z"
		/>
	</svg>
);
