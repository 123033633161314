import { ReactNode } from 'react';

export const symbol_home_storage: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M251-120q-21.658 0-38.462-14.179Q195.734-148.358 192-170l-66-395q-2-14 6.488-24.5T155-600h650q14.024 0 22.512 10.5Q836-579 834-565l-66 395q-3.734 21.642-20.538 35.821Q730.658-120 709-120H251Zm-58-420 57 360h460l57-360H193Zm207 160h160q12.75 0 21.375-9T590-410.5q0-12.5-8.625-21T560-440H400q-12 0-21 8.625T370-410q0 12 9 21t21 9ZM240-660q-12 0-21-9t-9-21.5q0-12.5 9-21t21-8.5h480q12.75 0 21.375 8.625T750-690q0 12-8.625 21T720-660H240Zm80-120q-12 0-21-9t-9-21.5q0-12.5 9-21t21-8.5h320q12.75 0 21.375 8.625T670-810q0 12-8.625 21T640-780H320Zm-70 600h460-460Z"
		/>
	</svg>
);
