import { t } from '@transifex/native';

import FullPageMessage from 'components/layout/full-page-message';

interface LoginCreatedStepProps {
	email: string;
}

export default function LoginCreatedStep({
	email,
}: LoginCreatedStepProps): JSX.Element {
	return (
		<FullPageMessage
			title={t('Account created successfully!')}
			message={t(
				'A verification email has been sent to the new account "{child_email}"',
				{
					child_email: email,
				}
			)}
		/>
	);
}
