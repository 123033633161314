import { ReactNode } from 'react';

export const symbol_crib: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480.326-80Q409-80 343-104t-120-69q-11.375-9.044-12.188-23.022Q210-210 219-219q9-9 21.5-8.5T264-219q15.086 12.25 32.057 22.75Q313.029-185.75 330-177v-143H220q-24 0-42-18t-18-42v-260q0-66 47-113t113-47h130q12.75 0 21.375 8.625T480-770v170h260q24 0 42 18t18 42v160q0 24-18 42t-42 18H630v143q18-9 35-20t32-24q10-8 22.5-8t21.5 9q9 9.333 8.5 22.167Q749-185 739-177q-54 46-120.674 71.5t-138 25.5Zm-.076-60q22.75 0 45.25-3t44.5-10v-167H390v167q22 7 44.75 10t45.5 3ZM220-380h520v-160H420v-200H320q-42 0-71 29t-29 71v260Zm200-160Z"
		/>
	</svg>
);
