import { MouseEvent } from 'react';
import styled, { css, DefaultTheme } from 'styled-components';

import * as styles from 'pkg/config/styles';

import { addCssVar } from 'pkg/cssvars';

const backgroundColor = addCssVar(
	(theme: DefaultTheme) => styles.palette.gray[theme.darkMode ? 900 : 200]
);

const hoverColor = addCssVar(
	(theme: DefaultTheme) => styles.palette.gray[theme.darkMode ? 800 : 200]
);

const activeColor = addCssVar((theme: DefaultTheme) =>
	theme.darkMode ? styles.palette.gray[500] : styles.palette.white
);

const shadowColor = addCssVar(
	(theme: DefaultTheme) => styles.palette.gray[theme.darkMode ? 900 : 300]
);

export const Switcher = styled.div<{ dark?: boolean; wide?: boolean }>`
	display: inline-block;
	margin: 0 auto;
	border-radius: var(--radius-4);
	padding: 3px;
	background-color: ${backgroundColor};

	${(props) =>
		props.dark &&
		css`
			background-color: var(--palette-gray-300);
		`}

	${(props) =>
		props.wide &&
		css`
			width: 100%;
			display: flex;
		`}
`;

export const TabButton = styled.div<{ active: boolean; wide?: boolean }>`
	display: inline-block;
	padding: var(--spacing-3) var(--spacing-5);
	font-size: var(--font-size-sm);
	line-height: var(--font-line-height-xs);
	color: var(--palette-gray-600);
	background-color: transparent;
	box-shadow: inset 0 0 0 1px transparent;
	margin: 0;
	border-radius: var(--radius-3);
	cursor: pointer;
	text-align: center;

	@media (hover: hover) {
		&:hover:not([disabled]) {
			background-color: ${hoverColor};
			box-shadow: inset 0 0 0 1px ${shadowColor};
		}
	}

	${(props) =>
		props.wide &&
		css`
			flex: 1;
			display: flex;
			justify-content: center;
			align-items: center;
		`}

	${(props) =>
		props.active &&
		css`
			background-color: ${activeColor};
			box-shadow: inset 0 0 0 1px ${shadowColor};
			color: var(--palette-black);
			font-weight: var(--font-weight-semibold);

			@media (hover: hover) {
				&:hover:not([disabled]) {
					background-color: ${activeColor};
				}
			}
		`}
`;

interface TabSwitcherTabs {
	[key: string]: string;
}

interface TabSwitcherProps {
	tabs: TabSwitcherTabs;
	active: string;
	onChange: (tab: string) => void;
	className?: string;
	theme?: DefaultTheme;
	dark?: boolean;
	wide?: boolean;
}

export const TabSwitcher = ({
	tabs,
	active,
	onChange,
	className,
	dark,
	wide,
}: TabSwitcherProps): JSX.Element => {
	const handleChange = (event: MouseEvent) => {
		const key = (event.currentTarget as HTMLDivElement).dataset.key;

		if (key === active) return;

		onChange(key);
	};

	return (
		<Switcher className={className} dark={dark} wide={wide}>
			{Object.entries(tabs).map(([key, label]) => (
				<TabButton
					key={key}
					data-key={key}
					wide={wide}
					onClick={handleChange}
					active={key === active}
					data-testid={`test_${key}`}>
					{label}
				</TabButton>
			))}
		</Switcher>
	);
};

export default styled(TabSwitcher)``;
