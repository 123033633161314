import { ReactNode } from 'react';

export const symbol_bubble_chart: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M584.882-120Q539-120 507-152.118q-32-32.117-32-78Q475-276 507.118-308q32.117-32 78-32Q631-340 663-307.882q32 32.117 32 78Q695-184 662.882-152q-32.117 32-78 32Zm-.057-60Q606-180 620.5-194.325q14.5-14.324 14.5-35.5Q635-251 620.675-265.5q-14.324-14.5-35.5-14.5Q564-280 549.5-265.675q-14.5 14.324-14.5 35.5Q535-209 549.325-194.5q14.324 14.5 35.5 14.5Zm80.111-230Q575-410 512.5-472.564t-62.5-152.5Q450-715 512.564-777.5t152.5-62.5Q755-840 817.5-777.436t62.5 152.5Q880-535 817.436-472.5t-152.5 62.5Zm-.162-60Q730-470 775-514.774t45-110Q820-690 775.226-735t-110-45Q600-780 555-735.226t-45 110Q510-560 554.774-515t110 45ZM275-237q-65 0-110-45t-45-110q0-65 45-110t110-45q65 0 110 45t45 110q0 65-45 110t-110 45Zm-.133-60Q314-297 342-324.867q28-27.868 28-67Q370-431 342.133-459q-27.868-28-67-28Q236-487 208-459.133q-28 27.868-28 67Q180-353 207.867-325q27.868 28 67 28ZM585-230Zm80-395ZM275-392Z"
		/>
	</svg>
);
