// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vneGsDLgYMdTfwdCkvyM {
	display: inline-block;
	font-size: inherit;
	height: 1em;
	width: 1em;
	overflow: visible;
	vertical-align: -0.125em;
	color: inherit;
	color: var(--fill-color, inherit);
	-webkit-transform-origin: 50% 50%;
	        transform-origin: 50% 50%;
	-webkit-transform: scale(1) rotateZ(0deg);
	        transform: scale(1) rotateZ(0deg);
	-webkit-transform: scale(var(--size, 1)) rotateZ(var(--rotate, 0deg));
	        transform: scale(var(--size, 1)) rotateZ(var(--rotate, 0deg));
}

.vneGsDLgYMdTfwdCkvyM.MR8CzIWoCwrk8U6Cg3Tz {
		cursor: pointer;
	}

.vneGsDLgYMdTfwdCkvyM.ctQWOy7TfTFD4EzLOeF2 {
		height: var(--size);
		width: var(--size);
		-webkit-transform: rotateZ(0deg);
		        transform: rotateZ(0deg);
		-webkit-transform: rotateZ(var(--rotate, 0deg));
		        transform: rotateZ(var(--rotate, 0deg));
	}

.EfADVEgKh2QYxkmfjh2B {
	--size: 25px;
	--bg: var(--palette-gray-200);
	--fg: var(--palette-gray-500);

	background: var(--bg);
	color: var(--fg);
	width: var(--size);
	height: var(--size);
	display: grid;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	justify-items: center;
	place-items: center;
	border-radius: 100000px;
}

.EfADVEgKh2QYxkmfjh2B svg {
		width: 60%;
		height: 60%;
	}
`, "",{"version":3,"sources":["webpack://./components/icon/styles.css"],"names":[],"mappings":"AAAA;CACC,qBAAqB;CACrB,kBAAkB;CAClB,WAAW;CACX,UAAU;CACV,iBAAiB;CACjB,wBAAwB;CACxB,cAAiC;CAAjC,iCAAiC;CACjC,iCAAyB;SAAzB,yBAAyB;CACzB,yCAA6D;SAA7D,iCAA6D;CAA7D,qEAA6D;SAA7D,6DAA6D;AAW9D;;AATC;EACC,eAAe;CAChB;;AAEA;EACC,mBAAmB;EACnB,kBAAkB;EAClB,gCAAuC;UAAvC,wBAAuC;EAAvC,+CAAuC;UAAvC,uCAAuC;CACxC;;AAGD;CACC,YAAY;CACZ,6BAA6B;CAC7B,6BAA6B;;CAE7B,qBAAqB;CACrB,gBAAgB;CAChB,kBAAkB;CAClB,mBAAmB;CACnB,aAAa;CACb,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CAAnB,qBAAmB;CAAnB,mBAAmB;CACnB,uBAAuB;AAMxB;;AAJC;EACC,UAAU;EACV,WAAW;CACZ","sourcesContent":[".icon {\n\tdisplay: inline-block;\n\tfont-size: inherit;\n\theight: 1em;\n\twidth: 1em;\n\toverflow: visible;\n\tvertical-align: -0.125em;\n\tcolor: var(--fill-color, inherit);\n\ttransform-origin: 50% 50%;\n\ttransform: scale(var(--size, 1)) rotateZ(var(--rotate, 0deg));\n\n\t&.has-click {\n\t\tcursor: pointer;\n\t}\n\n\t&.actual-size {\n\t\theight: var(--size);\n\t\twidth: var(--size);\n\t\ttransform: rotateZ(var(--rotate, 0deg));\n\t}\n}\n\n.decorated {\n\t--size: 25px;\n\t--bg: var(--palette-gray-200);\n\t--fg: var(--palette-gray-500);\n\n\tbackground: var(--bg);\n\tcolor: var(--fg);\n\twidth: var(--size);\n\theight: var(--size);\n\tdisplay: grid;\n\tplace-items: center;\n\tborder-radius: 100000px;\n\n\t& svg {\n\t\twidth: 60%;\n\t\theight: 60%;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var icon = `vneGsDLgYMdTfwdCkvyM`;
var _1 = `MR8CzIWoCwrk8U6Cg3Tz`;
export { _1 as "has-click" };
var _2 = `ctQWOy7TfTFD4EzLOeF2`;
export { _2 as "actual-size" };
export var decorated = `EfADVEgKh2QYxkmfjh2B`;
export default ___CSS_LOADER_EXPORT___;
