import { ReactNode } from 'react';

export const symbol_all_out: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M265-120h-85q-24.75 0-42.375-17.625T120-180v-85q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T180-265v85h85q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T265-120Zm515 0h-85q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T695-180h85v-85q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T840-265v85q0 24.75-17.625 42.375T780-120Zm-300-60q-125 0-212.5-87.5T180-480q0-125 87.5-212.5T480-780q125 0 212.5 87.5T780-480q0 125-87.5 212.5T480-180Zm0-60q99.6 0 169.8-70.2Q720-380.4 720-480q0-99.6-70.2-169.8Q579.6-720 480-720q-99.6 0-169.8 70.2Q240-579.6 240-480q0 99.6 70.2 169.8Q380.4-240 480-240ZM120-780q0-24.75 17.625-42.375T180-840h85q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T265-780h-85v85q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T120-695v-85Zm689.825 115Q797-665 788.5-673.625T780-695v-85h-85q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T695-840h85q24.75 0 42.375 17.625T840-780v85q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM480-480Z"
		/>
	</svg>
);
