import styled, { css } from 'styled-components';

import * as palette from 'pkg/config/palette';
import { small } from 'pkg/config/breakpoints';

import rgba from 'pkg/rgba';

import Icon from 'components/icon';

import * as iconStyles from 'components/icon/styles.css';
import RangeSlider from 'components/form/RangeSlider';

const Slider = styled(RangeSlider)`
	opacity: 0.3;

	&:first-of-type {
		opacity: 1;
	}
`;

const FauxText = styled.div`
	width: 100%;
	background: ${palette.gray[200]};
	height: 12px;
	border-radius: 12px;
`;

const BackCard = {};
const FrontCard = {};

BackCard.Body = styled.div`
	background: ${palette.white};
	height: 105px;
	width: calc(100% - 40px);
	border-radius: 13px;
	box-shadow: 0px 0px 30px 7px ${rgba(palette.black, 0.3)};
	display: flex;
	align-items: center;
	padding: 30px 20px;
	position: relative;
	z-index: 1;
	justify-content: space-between;
`;

BackCard.Avatar = styled.div`
	background: ${palette.blue[500]};
	height: 50px;
	width: 50px;
	border-radius: 25px;
	display: flex;
	justify-content: center;
	align-items: center;

	.${iconStyles.icon} {
		font-size: 3rem;
		color: ${palette.white};
	}
`;

BackCard.TextWrapper = styled.div`
	width: 75%;
	height: 100%;
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
`;

FrontCard.Body = styled.div`
	background: ${palette.white};
	height: 170px;
	width: calc(100% - 40px);
	border-radius: 13px;
	box-shadow: 0px 0px 30px 7px ${rgba(palette.black, 0.3)};
	display: flex;
	align-items: center;
	padding: 20px;
	position: absolute;
	z-index: 2;
	top: 100px;
	right: 0;
	left: auto;
	display: flex;
	flex-flow: row wrap;

	@media ${small} {
		top: 80px;
	}
`;

FrontCard.Number = styled.p`
	font-size: 18px;
	font-weight: var(--font-weight-normal);
`;

FrontCard.NumberWrapper = styled.div`
	position: absolute;
	height: 55px;
	width: 55px;
	background: ${palette.white};
	border-radius: var(--radius-3);
	z-index: 4;
	top: -35px;
	right: 65px;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 0px 20px 10px ${rgba(palette.black, 0.1)};

	@media ${small} {
		right: 56px;
	}

	&::after {
		content: '';
		display: block;
		width: 0;
		height: 0;
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;

		border-top: 10px solid ${palette.white};

		position: absolute;
		bottom: -9px;
	}
`;

const Wrapper = styled.div`
	height: 300px;
	width: 350px;
	padding-top: 40px;
	position: relative;

	@media ${small} {
		padding-top: 20px;
		width: 280px;
	}

	${FrontCard.NumberWrapper} {
		opacity: 0;
		transition: opacity 300ms ease-in-out 600ms;
	}

	${FrontCard.Body} {
		opacity: 0;
		transition: opacity 300ms ease-in-out 400ms;
	}

	${BackCard.Body} {
		opacity: 0;
		transition: opacity 300ms ease-in-out 200ms;
	}

	${(props) =>
		props.active &&
		css`
			${BackCard.Body},
			${FrontCard.NumberWrapper},
		${FrontCard.Body} {
				opacity: 1;
			}
		`}
`;

const NOOP = () => {};

const AssessmentTop = (props) => (
	<Wrapper active={props.active}>
		<BackCard.Body>
			<BackCard.Avatar>
				<Icon name="nav-profile" />
			</BackCard.Avatar>
			<BackCard.TextWrapper>
				<FauxText />
				<FauxText />
				<FauxText />
			</BackCard.TextWrapper>
		</BackCard.Body>

		<FrontCard.Body>
			{[72, 50, 28].map((value) => (
				<Slider
					thumbSize="small"
					key={`slider--${value}`}
					value={value}
					thumbColor={palette.blue[500]}
					onChange={NOOP}
				/>
			))}
			<FrontCard.NumberWrapper>
				<FrontCard.Number>7.2</FrontCard.Number>
			</FrontCard.NumberWrapper>
		</FrontCard.Body>
	</Wrapper>
);

export default AssessmentTop;
