import { useEffect, useRef } from 'react';

type Callback = () => void;

export default function useInterval(
	callback: Callback,
	interval?: number,
	deps?: any[]
): void {
	const cachedCallback = useRef<Callback>();

	// Lock polling at 50ms, anything below it will clear the interval
	const intervalThreshold = 50;

	if (!deps) {
		deps = [];
	}

	useEffect(() => {
		cachedCallback.current = callback;
	}, [callback, ...deps]);

	useEffect(() => {
		function tick() {
			if (cachedCallback.current) {
				cachedCallback.current();
			}
		}

		if (Number.isNaN(interval) || interval < intervalThreshold) {
			return;
		}

		const id = setInterval(tick, interval);

		return () => clearInterval(id);
	}, [interval]);
}
