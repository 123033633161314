import {
	Component,
	createRef,
	useEffect,
	createElement,
	useContext,
} from 'react';
import PropTypes from 'prop-types';
import { Map, Record } from 'immutable';
import styled, { css } from 'styled-components';

import * as palette from 'pkg/config/palette';
import { cardHover } from 'pkg/config/box-shadow';
import * as zIndex from 'pkg/config/zindex';

import rgba from 'pkg/rgba';
import { debounce } from 'pkg/timings';

import KeyboardObserver from 'components/KeyboardObserver';
import Icon from 'components/icon';

import * as Input from 'components/form/inputs';

import * as ContextMenu from 'design/context_menu';

const Wrapper = styled.div`
	position: relative;

	${(props) =>
		props.inline &&
		css`
			display: grid;
			grid-template-areas: 'field' 'results';
			grid-template-rows: auto 1fr;
		`}
`;

const Results = styled.div`
	background: ${palette.white};
	width: 100%;
	overflow: scroll;
	-webkit-overflow-scrolling: touch;

	${(props) =>
		!props.inline &&
		css`
			position: absolute;
			top: calc(100%);
			left: 0;
			max-height: 300px;
			border-radius: var(--radius-2);
			box-shadow:
				0 0 0 1px ${rgba(palette.black, 0.1)},
				${cardHover};
			z-index: ${zIndex.startIndex + 100};
		`}
`;

const Inner = styled.div`
	border-bottom: 1px solid ${palette.gray[200]};

	&:first-child {
		padding-top: 0.5rem;
	}

	&:last-child {
		padding-bottom: 0.5rem;
		border: none;
	}
`;

const Item = styled.div`
	padding: 0.5rem 1rem;
	cursor: pointer;

	${(props) =>
		props.isActive &&
		css`
			background-color: ${rgba(palette.blue[400], 0.05)};
		`}

	@media (hover: hover) {
		&:hover {
			background-color: ${rgba(palette.blue[400], 0.05)};
		}
	}
`;

const NOOP = () => {};

let shouldBlurInput = true;
let userDidClick = false;

const Result = ({ index, indexKey = '', isActive, onClick, children }) => {
	const ref = createRef();

	const context = useContext(ContextMenu.MenuContext);

	useEffect(() => {
		const node = ref.current;

		if (node && isActive && !userDidClick) {
			node.scrollIntoView({
				behaviour: 'smooth',
			});
		}
	});

	const disableInputBlur = () => {
		shouldBlurInput = false;
		userDidClick = true;
	};

	const enableInputBlur = () => {
		shouldBlurInput = true;
		userDidClick = false;
	};

	const handleClick = () => {
		if (context?.close) {
			context.close();
		}
	};

	return (
		<Inner
			ref={ref}
			isActive={isActive}
			data-index={index}
			data-index-key={indexKey}
			onClick={onClick}
			onMouseDown={disableInputBlur}
			onMouseUp={enableInputBlur}>
			<Item isActive={isActive} onClick={handleClick}>
				{children}
			</Item>
		</Inner>
	);
};

export default class extends Component {
	inputRef = createRef();

	static propTypes = {
		items: PropTypes.instanceOf(Map),
		name: PropTypes.string.isRequired,
		icon: PropTypes.string,
		placeholder: PropTypes.string,
		value: PropTypes.string,
		hasError: PropTypes.bool,

		closeOnSelect: PropTypes.bool,
		clearInputOnSelect: PropTypes.bool,

		emptyItem: PropTypes.oneOfType([
			PropTypes.instanceOf(Record),
			PropTypes.instanceOf(Map),
		]),

		resultItem: PropTypes.elementType.isRequired,
		renderEmptyWith: PropTypes.func,
		renderCreateWith: PropTypes.func,

		onActive: PropTypes.func,
		onInactive: PropTypes.func,
		onTyping: PropTypes.func,
		onChange: PropTypes.func,
		onSelect: PropTypes.func,
		onCreate: PropTypes.func,
	};

	static defaultProps = {
		items: new Map({}),
		icon: 'search',
		placeholder: '',
		emptyItem: null,
		hasError: false,

		closeOnSelect: true,
		clearInputOnSelect: true,

		isBusy: false,

		onActive: NOOP,
		onInactive: NOOP,
		onTyping: NOOP,
		onChange: NOOP,
		onSelect: NOOP,
		onCreate: NOOP,
	};

	state = {
		selectedIndex: -1,
		currentValue: '',

		isFetching: false,
		isActive: false,
		isBusy: false,
	};

	componentDidMount() {
		if (this.inputRef.current) {
			// Force a re-render when we have refs.
			this.forceUpdate();
		}
	}

	static getDerivedStateFromProps(nextProps) {
		const { isFetching } = nextProps;

		if (isFetching === true || isFetching === false) {
			return { isFetching };
		}

		return null;
	}

	handleFocus = () => {
		this.setState({ isActive: true }, () => {
			this.props.onActive(this.state.currentValue);
		});
	};

	handleBlur = (event) => {
		if (!shouldBlurInput) {
			event.preventDefault();

			event.currentTarget.focus();
		} else {
			this.setState({ isActive: false }, this.props.onInactive);
		}
	};

	handleTyping = (event) => {
		const { isActive, isBusy } = this.state;

		if (!isActive) {
			this.setState({ isActive: true });
		}

		if (!isBusy) {
			this.setState({ isBusy: true });
		}

		const value = event.currentTarget.value;

		this.setState({ currentValue: value });

		this.props.onTyping(value);

		this.handleDidType(value);
	};

	handleDidType = debounce((value) => {
		this.props.onChange(value);
		this.setState({ isBusy: false });
	}, 200);

	close = () => {
		this.setState({ isActive: false });
	};

	prev = (event) => {
		event.preventDefault();

		if (!this.state.isActive) {
			this.setState({ isActive: true });
		}

		let { selectedIndex } = this.state;

		selectedIndex -= 1;

		if (selectedIndex < 0) {
			selectedIndex = 0;
		}

		this.setState({ selectedIndex });
	};

	next = (event) => {
		event.preventDefault();

		if (!this.state.isActive) {
			this.setState({ isActive: true });
		}

		let { selectedIndex } = this.state;
		let maxLength = this.props.items.size - 1;

		if (this.props.renderCreateWith) {
			maxLength += 1;
		}

		selectedIndex += 1;

		if (selectedIndex > maxLength) {
			selectedIndex = maxLength;
		}

		this.setState({ selectedIndex });
	};

	select = (event) => {
		let { selectedIndex, isActive } = this.state;
		const { items, onSelect, onCreate, closeOnSelect, clearInputOnSelect } =
			this.props;
		const value = this.inputRef.current.value;

		if (!isActive) return;

		let selectedKey = '';

		if (event && selectedIndex === -1) {
			selectedIndex = Number.parseInt(event.currentTarget.dataset.index, 10);
			selectedKey = event.currentTarget.dataset.indexKey;
		}

		if (items.size !== 0 && selectedIndex !== -1 && items.has(selectedKey)) {
			onSelect(items.get(selectedKey));
		} else if (this.props.renderCreateWith && value.length > 0) {
			onCreate(value);
		}

		const nextState = {
			selectedIndex: -1,
			currentValue: '',
		};

		if (closeOnSelect) {
			nextState.isActive = false;
		}

		if (clearInputOnSelect) {
			this.setState(nextState, () => {
				this.inputRef.current.value = '';
			});
		} else {
			this.setState(nextState);
		}
	};

	get keyboardObserver() {
		const ref = this.inputRef.current;

		if (!ref) return null;

		return (
			<KeyboardObserver
				target={ref}
				onEnter={this.select}
				onArrowUp={this.prev}
				onArrowDown={this.next}
				onEscape={this.close}
			/>
		);
	}

	get isBusy() {
		return this.state.isFetching || this.state.isBusy;
	}

	get results() {
		const { isActive, currentValue, selectedIndex } = this.state;

		if (!isActive) return null;

		const {
			inline,
			items,
			emptyItem,
			resultItem,
			renderEmptyWith,
			renderCreateWith,
		} = this.props;

		const zeroResults = items.size === 0;
		const canRenderEmpty = renderEmptyWith && emptyItem;
		const hasCurrentValue = currentValue.length > 0;

		if (zeroResults && !canRenderEmpty && !renderCreateWith) return null;
		if (zeroResults && !hasCurrentValue) return null;

		return (
			<Results inline={inline}>
				{canRenderEmpty && (
					<Result index={-1}>{renderEmptyWith(emptyItem)}</Result>
				)}
				{items.keySeq().map((key, n) => (
					<Result
						index={n}
						indexKey={key}
						key={`result-item-${n}`}
						onClick={this.select}
						isActive={n === selectedIndex}>
						{createElement(resultItem, {
							key,
							value: items.get(key),
							isActive: n === selectedIndex,
						})}
					</Result>
				))}
				{typeof renderCreateWith === 'function' && (
					<Result
						index={items.size + 1}
						key="result-item-create"
						onClick={this.select}
						isActive={items.size === selectedIndex}>
						{renderCreateWith(currentValue, items.size === selectedIndex)}
					</Result>
				)}
			</Results>
		);
	}

	render() {
		const { inline, name, icon, autoFocus, placeholder, value, hasError } =
			this.props;

		return (
			<Wrapper inline={inline}>
				<Input.Field
					ref={this.inputRef}
					name={name}
					busy={this.isBusy}
					autoComplete="off"
					autoFocus={autoFocus}
					placeholder={placeholder}
					onFocus={this.handleFocus}
					onBlur={this.handleBlur}
					onChange={this.handleTyping}
					hasError={hasError}
					value={value}>
					<Input.Prefix>
						<Icon name={icon} />
					</Input.Prefix>
				</Input.Field>
				{this.keyboardObserver}
				{this.results}
			</Wrapper>
		);
	}
}
