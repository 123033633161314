const icon = {
	name: 'stripe',
	viewBox: '0 0 10 14',
	objects: [
		{
			type: 'path',
			attributes: {
				fill: '#6772E5',
				d: 'M4.04008 4.54667C4.04008 4.02667 4.46675 3.82667 5.17342 3.82667C6.18675 3.82667 7.46675 4.13334 8.48008 4.68V1.54667C7.37342 1.10667 6.28008 0.933334 5.17342 0.933334C2.46675 0.933334 0.666748 2.34667 0.666748 4.70667C0.666748 8.38667 5.73342 7.8 5.73342 9.38667C5.73342 10 5.20008 10.2 4.45342 10.2C3.34675 10.2 1.93341 9.74667 0.813415 9.13334V12.3067C2.05341 12.84 3.30675 13.0667 4.45342 13.0667C7.22675 13.0667 9.13342 11.6933 9.13342 9.30667C9.12008 5.33334 4.04008 6.04 4.04008 4.54667Z',
			},
		},
	],
};

export default icon;
