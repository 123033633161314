import { css } from 'styled-components';

import * as styles from 'pkg/config/styles';

import rgba from 'pkg/rgba';

import * as iconStyles from 'components/icon/styles.css';

const Vertical = {};

Vertical.Item = css`
	font-size: var(--font-size-base);
	border-radius: var(--radius-2);
	font-weight: var(--font-weight-semibold);
	background: rgba(255, 255, 255, 0);
	color: ${rgba(styles.palette.gray[600], 0.8)};
	padding: 10px 10px 10px 9px;
	margin-bottom: 5px;
	border-left: 3px solid transparent;
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
	text-align: left;
	width: 100%;

	flex: 1 1 0;
	user-select: none;
	position: relative;
	z-index: 1;
	transition: all 100ms;

	.${iconStyles.icon} {
		display: inline-block;
		color: ${rgba(styles.palette.gray[600], 0.8)};
		height: 1.4rem;
		margin-right: 0.8rem;
	}

	@media (hover: hover) {
		&:hover {
			cursor: pointer;
			background: rgba(255, 255, 255, 0.4);
			box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.0412138);
			color: ${rgba(styles.palette.gray[600], 1)};

			.${iconStyles.icon} {
				color: ${rgba(styles.palette.gray[600], 1)};
			}
		}
	}

	${(props) =>
		props.hidden.includes('vertical') &&
		css`
			display: none;
		`};
`;

Vertical.ActiveItem = css`
	background: rgba(255, 255, 255, 0.4);
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.0412138);
	color: var(--palette-black);
	z-index: 2;

	@media (hover: hover) {
		&:hover {
			color: var(--palette-black);

			.${iconStyles.icon} {
				color: var(--palette-black);
			}
		}
	}

	.${iconStyles.icon} {
		color: var(--palette-black);
	}

	${(props) =>
		props.hidden.includes('vertical') &&
		css`
			display: none;
		`};
`;

export default Vertical;
