const icon = {
	name: 'drawing-rectangle',
	objects: [
		{
			type: 'rect',
			attributes: {
				x: 5.64713,
				y: 5.54317,
				width: 13.1335,
				height: 13.1335,
				stroke: 'currentColor',
				fill: 'none',
				strokeWidth: 1.3,
			},
		},
	],
};

export default icon;
