import { Ref, SetStateAction, Dispatch } from 'react';
import { t } from '@transifex/native';
import { useFormContext } from 'react-hook-form';

import * as styles from 'pkg/config/styles';

import * as actions from 'pkg/actions';
import * as api from 'pkg/api';
import * as endpoints from 'pkg/api/endpoints/auto';
import { Account } from 'pkg/api/models/account';
import { emailRegexPattern } from 'pkg/strings';

import AccountCard from 'routes/account/settings/privacy/AccountCard';

import { useStepModalContext } from 'components/step-modal';

import Form, { asString, FormPayload } from 'components/form/Form';
import InfoBox from 'components/form/info-box';
import * as Input from 'components/form/inputs';
import Column from 'components/layout/column';

function FormFields(): JSX.Element {
	const { watch } = useFormContext();

	const validate = (compareWith: string, value: string) => {
		const compareValue = watch(compareWith);
		return value === compareValue;
	};

	return (
		<Column>
			<Input.Group label={t(`Child's email`)}>
				<Input.Field
					required
					type="email"
					name="email"
					pattern={emailRegexPattern}
				/>
			</Input.Group>
			<Input.Group label={t('Confirm email')}>
				<Input.Field
					required
					type="email"
					name="emailConfirmation"
					validate={(value: string) => validate('email', value)}
				/>
			</Input.Group>
			<Input.Group label={t('Select a password')}>
				<Input.Field required name="password" type="password" minLength={8} />
			</Input.Group>
			<Input.Group label={t('Confirm password')}>
				<Input.Field
					required
					name="passwordConfirmation"
					type="password"
					minLength={8}
					validate={(value: string) => validate('password', value)}
				/>
			</Input.Group>
		</Column>
	);
}

interface CreateChildLoginModalProps {
	account: Account;
	formRef: Ref<HTMLFormElement>;
	setEmail: Dispatch<SetStateAction<string>>;
	setIsLoading?: Dispatch<SetStateAction<boolean>>;
}

export default function CreateChildLoginStep({
	account,
	formRef,
	setEmail,
	setIsLoading,
}: CreateChildLoginModalProps): JSX.Element {
	const stepModalContext = useStepModalContext();

	const handleCreateLogin = async (formData: FormPayload) => {
		try {
			setIsLoading(true);

			const email = asString(formData.email);
			const password = asString(formData.password);

			const [request] = await api
				.forAccount(account.id)
				.patch(endpoints.Accounts.Update(account.id), {
					email,
					password,
				});

			if (!request.ok) {
				throw new Error(`${request.status}`);
			}

			setIsLoading(false);

			await setEmail(email);

			stepModalContext.goTo('account-created');
		} catch (error: unknown) {
			const status = Number.parseInt((error as Error).message, 10);

			let title = t('Something went wrong');
			let message = t(
				`If you think this error isn't supposed to happen, please contact 360Player support.`
			);

			switch (status) {
				case 409:
					title = t('Email already in use');
					message = t('This email is already registered in 360Player');
					break;
			}

			actions.flashes.show({
				title: title,
				message: message,
			});

			return false;
		}
	};

	return (
		<Column spacing={styles.spacing._7}>
			<InfoBox color="blue">
				{t(
					'In order to create a login for your child, they will need to to have their own email address.'
				)}
			</InfoBox>
			<AccountCard account={account} />
			<Form formRef={formRef} onSubmit={handleCreateLogin}>
				<FormFields />
			</Form>
		</Column>
	);
}
