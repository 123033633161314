export default {
	name: 'comment',
	objects: [
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M6.36811 15.4766C4.85627 12.8526 5.37784 9.4855 7.81107 7.40726C10.6353 4.9951 14.9652 5.23372 17.4822 7.94023C19.9993 10.6468 19.7503 14.7963 16.9261 17.2084C15.5488 18.3847 13.8107 18.9309 12.1035 18.8674L6.07673 18.8461C5.56027 18.8442 5.09392 18.5496 4.88863 18.0954C4.68335 17.6413 4.77806 17.1137 5.12994 16.7514L6.36811 15.4766ZM8.01627 15.6082C6.20307 13.4163 6.46922 10.2222 8.67602 8.33732C10.9642 6.38295 14.4724 6.57628 16.5117 8.76914C18.5511 10.962 18.3493 14.324 16.0611 16.2784C14.9392 17.2367 13.5239 17.6786 12.1323 17.6216L6.08152 17.6002L8.01627 15.6082Z',
			},
		},
	],
};
