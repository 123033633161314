import { ReactNode } from 'react';

export const symbol_directions_boat: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M156-200 82-457q-4-12 1.5-23.5T101-496l59-20v-224q0-25 17.5-42t42.5-18h150v-90q0-12.75 8.625-21.375T400-920h160q12.75 0 21.375 8.625T590-890v90h150q24.75 0 42.375 17.625T800-740v225l60 19q11 4 16.5 15t1.5 23l-74 258q-39-4-76-22t-66-44q-8.854-8-20.927-8T621-265q-27 28-64 46.5T480.417-200Q470-200 461-201.1q-9-1.1-18-3.3-29-6.6-55-23.1T339-265q-8-9-20.5-9t-21.5 8q-29 26-65.5 44T156-200ZM480-40q-39 0-80-10.5T320-81q-39 20-79 30.5T160-40h-50q-12.75 0-21.375-8.675Q80-57.351 80-70.175 80-83 88.625-91.5T110-100h50q39 0 75.5-10.5T305-141q7.188-5 15.094-5T335-141q26 15 54.5 24t57.5 13q7.692 1 16.346 1.5t16.93.5q37.241 0 74.483-9.5Q592-121 625-141q7.188-4 15.094-4T655-141q33 20 69.5 30.5T800-100h50q12.75 0 21.375 8.675Q880-82.649 880-69.825 880-57 871.375-48.5T850-40h-50q-41 0-81-10.5T640-81q-39 20-80 30.5T480-40ZM220-535l241-78q9.158-3 19.079-3Q490-616 499-613l241 79v-206H220v205Zm260.25 275q33.75 0 62.25-19t51.5-44q19-20 47-20.5t48 19.5q17 16 35 30t38 25l51-179-333-108-334 108 52 177q20.065-11.243 37.043-23.892Q252.022-307.541 269-323q21-20 49.026-20.5Q346.053-344 365-324q17 17 35 32t41 24q10 4 19.562 6 9.563 2 19.688 2ZM480-408Z"
		/>
	</svg>
);
