import { ReactNode } from 'react';

export const symbol_productivity: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M78-320v-94q0-35 18-63.5t50-42.5q60-26 123-43t129-17q51.429 0 101.714 10.5Q550-559 598-541q-11 11-21.051 22.74Q566.897-506.519 559-493q-39-14-79.22-20.5T398-520q-60 0-117 15t-111 39q-15 7-23.5 20.966Q138-431.069 138-414v34h383q-1 15 0 30t3 30H78Zm320-60Zm0-261q-63 0-106.5-43.5T248-791q0-63 43.5-106.5T398-941q63 0 106.5 43.5T548-791q0 63-43.5 106.5T398-641Zm0-60q38 0 64-26t26-64q0-38-26-64t-64-26q-38 0-64 26t-26 64q0 38 26 64t64 26Zm0-90Zm360 0q0 63-43.5 106.5T608-641q-13 0-25-1t-24-6q27-29 38-66.5t11-76.5q0-39-12-76t-37-67q12-4.375 24-5.688Q595-941 608.3-941q62.7 0 106.2 43.5T758-791Zm11.527 621Q691-170 635.5-225.575 580-281.15 580-360q0-79 55.575-134.5T770-550q79 0 134.5 55.5T960-359.527Q960-281 904.5-225.5 849-170 769.527-170ZM732-278l134-134-21-21-113 113-56-57-21 21 77 78Z"
		/>
	</svg>
);
