import { t } from '@transifex/native';

import { palette } from 'pkg/config/styles';

import { PlayerGenderDistributionData } from 'pkg/api/dashboards/management';

import * as Chart from 'design/chart';
import * as DataCard from 'design/data_card';

interface PlayerGenderDistributionProps {
	data: PlayerGenderDistributionData;
}

export default function PlayerGenderDistribution({
	data,
}: PlayerGenderDistributionProps) {
	const chartItems: Chart.ChartItem[] = [
		{
			title: t('Male'),
			value: data.male,
			fillColor: palette.green[200],
		},
		{
			title: t('Female'),
			value: data.female,
			fillColor: palette.blue[300],
		},
		{
			title: t('Unspecified'),
			value: data.unspecified,
			fillColor: palette.red[200],
		},
	];

	return (
		<DataCard.Base
			title={t('Player gender distribution')}
			titleIcon="transgender">
			<Chart.Donut items={chartItems} />
		</DataCard.Base>
	);
}
