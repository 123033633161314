import { Children, useRef, useState, ReactNode } from 'react';

import { cssClasses } from 'pkg/css/utils';

import { LargeScreen } from 'components/MediaQuery';
import Icon from 'components/icon';

import * as css from './styles.css';

interface ScrollableWrapperProps {
	children: ReactNode;
	chevrons: boolean;
}
export function ScrollableWrapper({
	children,
	chevrons,
}: ScrollableWrapperProps) {
	const scrollableWrapperRef = useRef<HTMLDivElement>();
	const [scrollPos, setScrollPos] = useState(0);

	const scrollWidth = () => {
		if (!scrollableWrapperRef.current) {
			return 0;
		}

		const { clientWidth, scrollWidth } = scrollableWrapperRef.current;

		return scrollWidth - clientWidth;
	};

	const handleScroll = (event: any) => {
		const position = event.target.scrollLeft;
		setScrollPos(position);
	};

	const scrollChevronClick = (direction: 'left' | 'right') => {
		const element = scrollableWrapperRef.current;

		if (!element) {
			return;
		}

		let scrollDistance = element.scrollWidth / Children.count(children);

		if (
			element.scrollLeft === 0 ||
			element.scrollLeft === element.scrollWidth - element.clientWidth
		) {
			scrollDistance /= 2;
		}

		if (direction === 'right') {
			const newX = element.scrollLeft + scrollDistance;

			element.scrollTo({
				top: 0,
				left: newX,
				behavior: 'smooth',
			});
		} else {
			const newX = element.scrollLeft - scrollDistance;

			element.scrollTo({
				top: 0,
				left: newX,
				behavior: 'smooth',
			});
		}
	};

	const handleLeftChevronClick = () => {
		scrollChevronClick('left');
	};

	const handleRightChevronClick = () => {
		scrollChevronClick('right');
	};

	return (
		<div className={css.wrapper}>
			<div
				className={css.inner}
				ref={scrollableWrapperRef}
				onScroll={handleScroll}>
				<LargeScreen>
					{scrollPos >= 1 && (
						<div className={cssClasses(css.gradient, css.left)}>
							{chevrons && (
								<div
									className={cssClasses(css.chevron, css.left)}
									onClick={handleLeftChevronClick}>
									<Icon name="chevron" rotate="180deg" />
								</div>
							)}
						</div>
					)}
					{scrollPos <= scrollWidth() - -1 && (
						<div className={cssClasses(css.gradient, css.right)}>
							{chevrons && (
								<div
									className={cssClasses(css.chevron, css.right)}
									onClick={handleRightChevronClick}>
									<Icon name="chevron" />
								</div>
							)}
						</div>
					)}
				</LargeScreen>

				{children}
			</div>
		</div>
	);
}
