import { ReactNode } from 'react';

export const symbol_money: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M602-319h131q12.75 0 21.375-8.625T763-349v-262q0-12.75-8.625-21.375T733-641H602q-12.75 0-21.375 8.625T572-611v262q0 12.75 8.625 21.375T602-319Zm30-60v-202h71v202h-71Zm-278 60h126q12.75 0 21.375-8.625T510-349v-262q0-12.75-8.625-21.375T480-641H354q-12.75 0-21.375 8.625T324-611v262q0 12.75 8.625 21.375T354-319Zm30-60v-202h66v202h-66Zm-127 30v-262q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T197-611v262q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T257-349ZM80-220v-520q0-24.75 17.625-42.375T140-800h680q24.75 0 42.375 17.625T880-740v520q0 24.75-17.625 42.375T820-160H140q-24.75 0-42.375-17.625T80-220Zm60 0h680v-520H140v520Zm0 0v-520 520Z"
		/>
	</svg>
);
