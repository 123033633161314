import mixpanel, { Mixpanel } from 'mixpanel-browser';
import { Fragment, ReactNode, useEffect, useState } from 'react';

import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import {
	useCurrentAccount,
	useCurrentMembership,
	useCurrentOrganization,
} from 'pkg/identity';
import { isDev, isDevServer } from 'pkg/flags';
import { useCurrentRoute } from 'pkg/router/hooks';
import * as models from 'pkg/api/models';
import { isAndroid, isIOS } from 'pkg/platform';
import { AccountFlags, hasFlag } from 'pkg/api/models/account';

let globalClient: Mixpanel;
let clientRegistered = false;
let currentPath = '';

/**
 * customEventQueue is used before the client has registered, in case any events are triggered
 * before we're ready to send them.
 */
let customEventQueue: {
	event: CustomEvent;
	data?: { [key: string]: any };
}[] = [];

function Identify({
	client,
	children,
}: {
	client: Mixpanel;
	children: ReactNode;
}): JSX.Element {
	const acc = useCurrentAccount();
	useEffect(() => {
		if (!acc.trackingHash) {
			return;
		}
		client.identify(acc.trackingHash);
	}, [acc.trackingHash]);

	return <Fragment>{children}</Fragment>;
}

function MembershipTracker({ client }: { client: Mixpanel }): JSX.Element {
	const membership = useCurrentMembership();
	const org = useCurrentOrganization();

	useEffect(() => {
		if (org?.id) {
			client.register({
				role: models.membership.roleString(membership),
				organization: org.id,
			});
		}
	}, [membership.id, org?.id]);

	return null;
}

function PageView({ client }: { client: Mixpanel }): JSX.Element {
	const route = useCurrentRoute();
	useEffect(() => {
		const whitelistedParams = [':currentTab'];
		const patternParts = `/${route.pattern}`.split('/');
		const path = route.path
			.split('/')
			.map((p: string, i: number) => {
				// part isn't a param, safe to set
				if (!patternParts[i].startsWith(':')) {
					return p;
				}

				if (whitelistedParams.includes(patternParts[i])) {
					return p;
				}

				return '_';
			})
			.join('/');

		currentPath = path;

		client.track('$mp_web_page_view', {
			$current_url: '',
			current_url_search: '',
			current_url_path: path,
			current_domain: document.location.hostname,
			current_url_protocol: document.location.protocol,
		});
	}, [route.path]);
	return null;
}

export function Analytics(): JSX.Element {
	const acc = useCurrentAccount();
	const [client, setClient] = useState<Mixpanel>(null);

	useComponentDidMount(async () => {
		if (hasFlag(acc, AccountFlags.OptOutAnalytics)) {
			return;
		}

		const client = (globalClient = mixpanel.init(
			'85da8ff7f9c1ed9c342386eb553f8f4b',
			{
				debug: isDevServer(),
				ignore_dnt: isDevServer(),
				persistence: 'cookie',
			},
			'webtracker'
		));

		let platform = 'web';
		if (isIOS()) {
			platform = 'ios';
		} else if (isAndroid()) {
			platform = 'android';
		}

		const iosVersionReg = navigator.userAgent.match(
			/(iPhone|iPad);.+?Version\/([\d\.]+)/
		);

		let osVersion: string = undefined;

		if (navigator.userAgentData) {
			const ua = await navigator.userAgentData.getHighEntropyValues([
				'platformVersion',
			]);

			if (ua.platformVersion) {
				osVersion = ua.platformVersion;
			}
		} else if (iosVersionReg?.length > 0) {
			osVersion = iosVersionReg[2];
		}

		// disable current_url and current_url_search to avoid sending PII
		client.register({
			$current_url: '',
			current_url_search: '',
			environment: isDev() ? 'development' : 'production',
			app_version: window.TS.version,
			os_version: osVersion,
			platform,
		});

		clientRegistered = true;
		customEventQueue.forEach((ev) => {
			trackEvent(ev.event, ev.data || {});
		});
		customEventQueue = [];

		setClient(client);
	});

	useEffect(() => {
		if (hasFlag(acc, AccountFlags.OptOutAnalytics) && client) {
			client.reset();
			client.unregister('85da8ff7f9c1ed9c342386eb553f8f4b');
			setClient(null);
		}
	}, [...acc.flags, client]);

	if (hasFlag(acc, AccountFlags.OptOutAnalytics)) {
		return null;
	}

	if (!client) {
		return null;
	}

	return (
		<Identify client={client}>
			<MembershipTracker client={client} />
			<PageView client={client} />
		</Identify>
	);
}

export function unregister() {
	globalClient?.reset();
}

export enum CustomEvent {
	AdView = 'adview',
}

export function trackEvent(
	event: CustomEvent,
	data: { [key: string]: any } = {}
) {
	if (!clientRegistered) {
		customEventQueue.push({ event, data });
		return;
	}

	globalClient.track(event, {
		$current_url: '',
		current_url_search: '',
		current_url_path: currentPath,
		current_domain: document.location.hostname,
		current_url_protocol: document.location.protocol,
		...data,
	});
}
