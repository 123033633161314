import { Linkable } from 'pkg/api/models/linkable';
import { Dateable } from 'pkg/api/models/dateable';
import * as models from 'pkg/api/models';
import { getYouTubeThumbnailUrl } from 'pkg/video';
import { formatTime } from 'pkg/timeline';
import { Record } from 'pkg/api/models/record';
import * as endpoints from 'pkg/api/endpoints/auto';

import {
	isValidVeoSource,
	isValidYouTubeSource,
} from 'components/video-analytics/source/SourceUrl';

export enum VideoType {
	Raw = 'raw',
	YouTube = 'youtube',
	Wingfield = 'wingfield',
}

export enum VideoSource {
	Link = 'link',
	Veo = 'veo',
	Wingfield = 'wingfield',
}

export interface Video extends Linkable, Record, Dateable {
	private: boolean;
	userId: number;
	accountId: number;
	groupId: number;
	thumbnailAttachmentId: number;
	uri: string;
	duration: number;
	title: string;
	description: string;
	type: VideoType;

	account?: models.account.Account;
	thumbnail?: models.attachment.Attachment;
	sequences: models.videoSequence.VideoSequence[];
	group?: models.group.Group;
}

export function thumbnail(video: Video): string {
	if (video.thumbnail) {
		return video.thumbnail?.url;
	} else if (video.type === VideoType.YouTube) {
		return getYouTubeThumbnailUrl(video.uri);
	} else {
		return '';
	}
}

export function durationTimestamp(video: Video): string {
	return formatTime(video.duration / 1000);
}

export function isNew(video: Video): boolean {
	return video.createdAt + 3600 * 24 > Math.floor(Date.now() / 1000);
}

export interface VideoCreatePayload {
	userId?: number;
	groupId?: number;
	externalId?: string;
	thumbnailAttachmentId?: number;

	title: string;
	description?: string;
	duration?: number;
	type?: VideoType;
	private?: boolean;

	uri?: string;
	veoUri?: string;
}

export async function create(
	payload: VideoCreatePayload
): Promise<[Response, Video, models.APIError?]> {
	if (payload.uri && payload.veoUri) {
		return Promise.resolve([new Response(null, { status: 400 }), null]);
	}

	if (payload.uri) {
		if (!payload.type) {
			if (isValidYouTubeSource(payload.uri)) {
				payload.type = VideoType.YouTube;
			} else {
				payload.type = VideoType.Raw;
			}
		}

		if (isValidVeoSource(payload.uri)) {
			payload.veoUri = payload.uri;
			payload.uri = null;
		}
	}

	return models.create(endpoints.Video.Create(), payload);
}

export interface VideoUpdatePayload {
	groupId?: number;
	thumbnailAttachmentId?: number;

	title?: string;
	description?: string;
	duration?: number;
}

export async function update(
	video: Video,
	payload: VideoCreatePayload
): Promise<[Response, Video, models.APIError?]> {
	if (Object.keys(payload).length === 0) {
		return Promise.resolve([new Response(null, { status: 400 }), null]);
	}

	return models.update(video, payload);
}

export enum WingfieldVideoType {
	Unknown = 'unknown',
	Session = 'session',
	Highlight = 'hightlights',
}

export enum WingfieldSessionType {
	Unknown = 'unknown',
	Match = 'match',
	Freeplay = 'freeplay',
	Drill = 'drill',
}

export interface WingfieldVideoSource {
	id?: string | number; // useCollection expects an ID
	sessionId: string;
	date: number;
	durationSeconds: number;
	exipiresAt: number;
	sessionType: WingfieldSessionType;
	videoType: WingfieldVideoType;
	thumbnailUrl: string;
	videoUrl: string;
}
