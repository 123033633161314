import { useT } from '@transifex/react';
import { Fragment, ReactNode } from 'react';

import * as models from 'pkg/api/models';
import { formatToCurrency } from 'pkg/i18n/formatters';
import { formatPriceIntervalToText } from 'pkg/utils';
import { useCurrentOrganization } from 'pkg/identity';
import { useCollection } from 'pkg/api/use_collection';
import DateTime from 'pkg/datetime';
import * as routes from 'pkg/router/routes';
import { link } from 'pkg/router/utils';
import Link from 'pkg/router/Link';
import * as endpoints from 'pkg/api/endpoints/auto';

import MaterialSymbol from 'components/material-symbols';

import StatusLabel from 'components/payment_platform/status/label';

import * as ContextMenu from 'design/context_menu';
import * as Table from 'design/table';

import * as css from './styles.css';

interface ProductsProps {
	count?: number;
	showViewAll?: boolean;
	organizationId: number;
	user: models.user.User;
}

export default function Products({
	organizationId,
	user,
	count = 5,
	showViewAll = false,
}: ProductsProps): JSX.Element {
	const t = useT();

	const {
		records: userProducts,
		isLoading,
		pagination,
	} = useCollection<models.userProduct.UserProduct>(
		endpoints.Users.ListUserProducts(user.id),
		{
			showAllResults: true,
			queryParams: new URLSearchParams({
				count: count.toString(),
			}),
		}
	);

	const columns: Table.HeaderColumn[] = [
		{ content: t('Product'), align: 'left' },
		{ content: t('Price'), width: 'max-content' },
		{ content: t('Status'), width: 'max-content', align: 'center' },
		{ content: t('Valid until'), align: 'right', width: 'max-content' },
		{ content: t('Created at'), align: 'right', width: 'max-content' },
		{ content: '', width: 'max-content' },
	];

	const emptyState = {
		title: t('No assigned products'),
		content: t('There are no products assigned to {name}.', {
			name: models.user.fullName(user),
		}),
		image: (
			<MaterialSymbol
				actualSize
				scale={3}
				variant="box_add"
				className={css.emptyStateIcon}
			/>
		),
		className: css.emptyState,
	};

	return (
		<Fragment>
			<Table.Table
				columns={columns}
				emptyState={emptyState}
				isLoading={isLoading}>
				{userProducts.map((userProduct) => (
					<Product key={userProduct.id} user={user} userProduct={userProduct} />
				))}
			</Table.Table>
			{showViewAll && pagination?.hasNext && (
				<Link
					href={routes.Organization.User.Profile.Products(
						organizationId,
						user.id
					)}
					className={css.viewAll}>
					{t('View all products')}
					<MaterialSymbol variant="chevron_right" />
				</Link>
			)}
		</Fragment>
	);
}

interface ProductProps {
	user: models.user.User;
	userProduct: models.userProduct.UserProduct;
}

function Product({ user, userProduct }: ProductProps): JSX.Element {
	const t = useT();
	const org = useCurrentOrganization();

	let priceTotal: number = 0;
	let priceInterval: string = '';
	let validUntil: string = '—';

	const createdAt = DateTime.fromTimestamp(
		userProduct.createdAt
	).toLocaleDateString({
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
	});

	if (userProduct?.productPrice) {
		const { cost, taxRate, recurringInterval, recurringIntervalCount } =
			userProduct.productPrice;

		priceTotal = cost / 100;

		if (taxRate && taxRate.inclusive) {
			priceTotal = priceTotal * 2 * (taxRate.percentage / 100);
		}

		priceInterval = formatPriceIntervalToText(
			recurringInterval,
			recurringIntervalCount
		);
	}

	const price: string = [
		formatToCurrency(priceTotal, org.currency),
		priceInterval,
	].join(' ');

	if (userProduct?.validUntil > 0) {
		validUntil = DateTime.fromTimestamp(
			userProduct.validUntil
		).toLocaleDateString();
	}

	const productUrl = link(
		routes.Product.Show(org.id, userProduct.productId, 'overview'),
		{
			returnUrl: routes.Organization.User.Profile.Show(org.id, user.id),
		}
	);

	const actions: ReactNode[] = [];

	if (models.hasAllowedAction(userProduct, models.Action.UserProductView)) {
		actions.push(
			<ContextMenu.LinkItem
				key="product-url"
				href={productUrl}
				className={css.contextItemWithExtraIcon}>
				<ContextMenu.ItemIcon name="box" />
				<span>{t('View product')}</span>
				<ContextMenu.ItemIcon name="arrow_forward" />
			</ContextMenu.LinkItem>
		);
	}

	return (
		<Table.Row>
			<Table.Cell>{userProduct.product.name}</Table.Cell>
			<Table.Cell align="right">{price}</Table.Cell>
			<Table.Cell align="center">
				<StatusLabel status={{ name: userProduct.status }} />
			</Table.Cell>
			<Table.Cell align="right">{validUntil}</Table.Cell>
			<Table.Cell align="right">{createdAt}</Table.Cell>
			<Table.ActionCell>
				{actions.length > 0 && (
					<ContextMenu.Menu
						toggleWith={
							<ContextMenu.TableTrigger>
								<MaterialSymbol actualSize variant="more_horiz" scale={1.3} />
							</ContextMenu.TableTrigger>
						}>
						{actions}
					</ContextMenu.Menu>
				)}
			</Table.ActionCell>
		</Table.Row>
	);
}
