import { ReactNode } from 'react';

export const symbol_eye_tracking: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M100-40q-24 0-42-18t-18-42v-93q0-12.75 8.675-21.375Q57.351-223 70.175-223 83-223 91.5-214.375T100-193v93h93q12.75 0 21.375 8.675Q223-82.649 223-69.825 223-57 214.375-48.5T193-40h-93Zm760 0h-93q-12.75 0-21.375-8.675Q737-57.351 737-70.175 737-83 745.625-91.5T767-100h93v-93q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T920-193v93q0 24-18 42t-42 18ZM479-225q-109 0-199-57.5T137-441q-5-9-7-18.868-2-9.869-2-20.132 0-10.5 2.25-20.25T137-519q53-102 143-159.5T479-736q109 0 199.5 57.5T822-519q5 9 7 19t2 20q0 10-2 20t-7 19q-53 101-143.5 158.5T479-225Zm0-60q93 0 173-52.5T774-480q-42-90-122-143t-173-53q-93 0-172.5 53T185-480q42 90 121.5 142.5T479-285Zm1.222-63Q535-348 574-386.722q39-38.722 39-93.5T574.071-574q-38.93-39-94-39Q425-613 386.5-574.071q-38.5 38.93-38.5 94Q348-425 386.722-386.5t93.5 38.5ZM480-408q-30 0-51-21t-21-51q0-30 21-51.5t51-21.5q30 0 51.5 21.292Q553-510.417 553-480q0 30-21.292 51-21.291 21-51.708 21ZM193-860h-93v123H40v-123q0-24 18-42t42-18h93q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T193-860Zm727 0v93q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T860-767v-93h-93q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T767-920h93q24 0 42 18t18 42ZM480-481Z"
		/>
	</svg>
);
