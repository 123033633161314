import { ReactNode } from 'react';

export const symbol_coffee: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M442-242q-116 0-199-80.045Q160-402.089 160-517v-263q0-24.75 17.625-42.375T220-840h529q54.271 0 92.636 37Q880-766 880-712q0 60-37 106t-94 46h-25v43q0 114.911-83 194.955Q558-242 442-242ZM220-620h444v-160H220v160Zm222 318q91 0 156.5-62T664-517v-43H220v43q0 91 65.5 153T442-302Zm282-318h25q33 0 52-28.5t19-63.5q0-29-21-48.5T749-780h-25v160ZM190-120q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T190-180h580q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T770-120H190Zm252-440Z"
		/>
	</svg>
);
