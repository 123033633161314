import { ReactNode } from 'react';

export const symbol_qr_code_2: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M520-120v-80h80v80h-80Zm-80-80v-200h80v200h-80Zm320-120v-160h80v160h-80Zm-80-160v-80h80v80h-80Zm-480 80v-80h80v80h-80Zm-80-80v-80h80v80h-80Zm360-280v-80h80v80h-80ZM170-650h140v-140H170v140Zm-50 20v-180q0-12.75 8.625-21.375T150-840h180q12.75 0 21.375 8.625T360-810v180q0 12.75-8.625 21.375T330-600H150q-12.75 0-21.375-8.625T120-630Zm50 460h140v-140H170v140Zm-50 20v-180q0-12.75 8.625-21.375T150-360h180q12.75 0 21.375 8.625T360-330v180q0 12.75-8.625 21.375T330-120H150q-12.75 0-21.375-8.625T120-150Zm530-500h140v-140H650v140Zm-50 20v-180q0-12.75 8.625-21.375T630-840h180q12.75 0 21.375 8.625T840-810v180q0 12.75-8.625 21.375T810-600H630q-12.75 0-21.375-8.625T600-630Zm80 510v-120h-80v-80h160v120h80v80H680ZM520-400v-80h160v80H520Zm-160 0v-80h-80v-80h240v80h-80v80h-80Zm40-200v-160h80v80h80v80H400Zm-190-90v-60h60v60h-60Zm0 480v-60h60v60h-60Zm480-480v-60h60v60h-60Z"
		/>
	</svg>
);
