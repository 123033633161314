import { ReactNode } from 'react';

export const symbol_wifi_lock: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M682-120q-17 0-28.5-11.385T642-159.6v-118.8q0-16.83 11.5-28.215Q665-318 682-318h1v-39q0-33.825 24.124-57.913 24.124-24.087 58-24.087Q799-439 823-414.913q24 24.088 24 57.913v39h1q17 0 28.5 11.385T888-278.4v118.8q0 16.83-11.5 28.215Q865-120 848-120H682Zm41-198h84v-39q0-19-11.5-30.5T765-399q-19 0-30.5 11.5T723-357v39ZM480-800q120 0 228.5 41T909-644q10.733 8.203 16.483 19.763Q931.233-612.678 932-600q1 12.58-4 24.79Q923-563 913-553l-52 53q-8 8-20.5 8t-20.5-8q-8-8-8-20.5t8-20.5l52-53q-87-68-184.5-107T480-740q-110 0-207.5 39T88-594l392 392 39-39q8-8 20.5-8t20.5 8q8 8 8 20.5t-8 20.5l-38 38q-9 9-20 13t-22 4q-11 0-22-4t-20-13L48-552q-9.88-9.905-14.94-22.476Q28-587.048 29-600q.759-12.75 6.069-24.375T51-645q92-74 200.5-114.5T480-800Zm0 329Z"
		/>
	</svg>
);
