import { ReactNode } from 'react';

export const symbol_nest_eco_leaf: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-164q-58.529 0-110.265-19.5Q318-203 276-238l-62 61q-9 9-20.5 9t-20.5-9q-9-9-9-20.5t9-20.5l61-62q-35.343-42.163-54.672-93.582Q160-425 160-484q0-134 93-227t227-93h260q24.75 0 42.375 17.625T800-744v260q0 134-93 227t-227 93Zm0-60q108.333 0 184.167-75.833Q740-375.667 740-484v-260H480q-108.333 0-184.167 75.833Q220-592.333 220-484q0 45.736 15 87.368T276-322l217-216q9-9 20.5-9t20.5 9q9 8.8 9 20.9 0 12.1-9 21.1L318-280q33 26 74.632 41 41.632 15 87.368 15Zm0-260Z"
		/>
	</svg>
);
