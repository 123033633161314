import { Fragment } from 'react';
import { t } from '@transifex/native';

import * as actions from 'pkg/actions';
import store from 'pkg/store/createStore';
import * as routes from 'pkg/router/routes';
import {
	useCurrentGroup,
	useCurrentMembership,
	useCurrentOrganization,
	useCurrentUser,
} from 'pkg/identity';

import * as ContextMenu from 'design/context_menu';

const UserActions = (): JSX.Element => {
	const membership = useCurrentMembership();
	const group = useCurrentGroup();
	const user = useCurrentUser();
	const org = useCurrentOrganization();

	const groupId = group.id;
	const userId = user?.id;

	const showBilling = userId && !!user.billingUserId;
	const showProfileActions =
		userId && membership.id && user.id === membership.userId;

	const onLogout = () => {
		actions.auth.logout()(store.dispatch);
	};

	return (
		<Fragment>
			{showProfileActions && (
				<Fragment>
					<ContextMenu.LinkItem
						href={routes.User.Profile.Show(org.id, groupId, userId, 'overview')}
						testid="user_dropdown.profile">
						<ContextMenu.ItemIcon name="person" />
						{t('My profile')}
					</ContextMenu.LinkItem>

					<ContextMenu.LinkItem
						href={routes.User.Settings(org.id, userId)}
						testid="user_dropdown.edit_profile">
						<ContextMenu.ItemIcon name="edit" />
						{t('Edit profile')}
					</ContextMenu.LinkItem>

					<ContextMenu.Divider />
				</Fragment>
			)}

			<ContextMenu.LinkItem
				href={routes.Account.Settings.Show('general')}
				testid="user_dropdown.settings">
				<ContextMenu.ItemIcon name="settings" />
				{t('My settings')}
			</ContextMenu.LinkItem>

			{showBilling && (
				<ContextMenu.LinkItem
					href={routes.Self.Billing(org.id)}
					testid="user_dropdown.billing">
					<ContextMenu.ItemIcon name="credit_card" />
					{t('Billing')}
				</ContextMenu.LinkItem>
			)}

			<ContextMenu.Divider />

			<ContextMenu.LinkItem
				href={routes.Support()}
				testid="user_dropdown.support">
				<ContextMenu.ItemIcon name="help" />
				{t('Support')}
			</ContextMenu.LinkItem>

			<ContextMenu.LinkItem
				href="/"
				onClick={onLogout}
				testid="user_dropdown.logout">
				<ContextMenu.ItemIcon name="logout" />
				{t('Log out')}
			</ContextMenu.LinkItem>
		</Fragment>
	);
};

export default UserActions;
