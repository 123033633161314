import { Users, AUTH_LOGOUT_SUCCESS } from 'pkg/actions/action-types';

const initialState = {
	entities: {},
};

const userLogs = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_USER_LOGS': {
			if (!state.entities[action.userId]) {
				state.entities[action.userId] = {
					links: {},
					records: [],
				};
			}

			let { records } = state.entities[action.userId];

			if (records.length === 0) {
				records = [...action.logs.records];
			} else {
				const recordIds = records.map((r) => r.id);
				const actionIds = action.logs.records.map((r) => r.id);
				for (let i = 0; i < actionIds.length; i++) {
					if (!recordIds.includes(actionIds[i])) {
						const log = action.logs.records.find((l) => l.id === actionIds[i]);
						records.push(log);
					}
				}
			}

			state.entities[action.userId] = {
				...state.entities[action.userId],
				...action.logs,
				records: [...records],
			};

			return {
				...state,
				entities: { ...state.entities },
			};
		}

		case 'PREPEND_USER_LOG': {
			let prevLogs = state.entities[action.userId].records;

			if (prevLogs.length >= 5) {
				prevLogs = prevLogs.splice(0, prevLogs.length - 1);
			}

			prevLogs.unshift(action.log);
			state.entities[action.userId].records = [...prevLogs];

			return {
				...state,
				entities: {
					...state.entities,
				},
			};
		}

		case Users.USER_LOG_REMOVE_SUCCESS: {
			const { records } = state.entities[action.userId];
			const filteredRecord = records.filter(
				(userLog) => userLog.id !== action.logId
			);

			state.entities[action.userId].records = filteredRecord;

			return {
				...state,
				entities: {
					...state.entities,
				},
			};
		}

		case AUTH_LOGOUT_SUCCESS:
			return initialState;

		default:
			return state;
	}
};

export default userLogs;
