export default {
	name: 'note',
	viewBox: '0 0 24 24',
	objects: [
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M6.72961 2.51041C7.08859 2.51041 7.37961 2.80142 7.37961 3.16041V4.91211H16.4869V3.16041C16.4869 2.80142 16.7779 2.51041 17.1369 2.51041C17.4959 2.51041 17.7869 2.80142 17.7869 3.16041V4.91254C19.2283 4.93823 20.3888 6.11464 20.3888 7.56211V18.8396C20.3888 20.3031 19.2023 21.4896 17.7388 21.4896H6.26121C4.79765 21.4896 3.61121 20.3031 3.61121 18.8396V7.56211C3.61121 6.15959 4.70076 5.01155 6.07961 4.91823V3.16041C6.07961 2.80142 6.37062 2.51041 6.72961 2.51041ZM6.26121 6.21211C5.51562 6.21211 4.91121 6.81652 4.91121 7.56211V9.11506H19.0888V7.56211C19.0888 6.81652 18.4843 6.21211 17.7388 6.21211H6.26121ZM19.0888 10.4151H4.91121V18.8396C4.91121 19.5852 5.51562 20.1896 6.26121 20.1896H17.7388C18.4843 20.1896 19.0888 19.5851 19.0888 18.8396V10.4151Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M6.80557 13.1328C6.80557 12.7738 7.09658 12.4828 7.45557 12.4828H16.5444C16.9034 12.4828 17.1944 12.7738 17.1944 13.1328C17.1944 13.4918 16.9034 13.7828 16.5444 13.7828H7.45557C7.09658 13.7828 6.80557 13.4918 6.80557 13.1328Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M6.80557 16.1928C6.80557 15.8338 7.09658 15.5428 7.45557 15.5428H14.4674C14.8264 15.5428 15.1174 15.8338 15.1174 16.1928C15.1174 16.5518 14.8264 16.8428 14.4674 16.8428H7.45557C7.09658 16.8428 6.80557 16.5518 6.80557 16.1928Z',
			},
		},
	],
};
