import { useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useT } from '@transifex/react';

import spacing from 'pkg/config/spacing';

import * as actions from 'pkg/actions';
import * as models from 'pkg/api/models';
import { usePaymentProviderContext } from 'pkg/contexts/provider_settings';
import { AutoChargeOptions } from 'pkg/api/models/provider_settings';
import { getAmountOfDaysBetweenDates } from 'pkg/date';

import { AddOrderContext } from 'routes/payments/orders/create';

import Icon from 'components/icon';

import Section from 'components/form/Section';
import * as Input from 'components/form/inputs';
import Column from 'components/layout/column';

interface ToggleOptionsProps {
	user: models.user.User;
	subscription: models.subscription.Subscription;
}

export default function AdvancedSettings({
	user,
	subscription,
}: ToggleOptionsProps) {
	const t = useT();

	const OrderContext = useContext(AddOrderContext);
	const formContext = useFormContext();
	const [hasPaymentMethod, setHasPaymentMethod] = useState(false);

	const paymentProviderContext = usePaymentProviderContext();
	const hideAdvancedOptions =
		!paymentProviderContext.settings.allowProration &&
		!paymentProviderContext.settings.canResetBillingCycle &&
		paymentProviderContext.settings.autoCharge === AutoChargeOptions.Always &&
		subscription.collectionMethod !== 'manual';

	useEffect(() => {
		const fetchData = async () => {
			const data = await actions.users.fetchPaymentSource(user.id);

			setHasPaymentMethod(data);
		};

		if (user.id) {
			fetchData();
		}
	}, [user.id]);

	useEffect(() => {
		if (hasPaymentMethod) {
			OrderContext.setFormState({
				collectionMethod: subscription.collectionMethod,
			});
		}
	}, [subscription.collectionMethod, hasPaymentMethod]);

	const handleChargeToggle = () =>
		OrderContext.setFormState({
			collectionMethod:
				OrderContext.formState.collectionMethod === 'automatic'
					? 'manual'
					: 'automatic',
		});

	let daysUntilDue = 30;
	const lastOrder = subscription.orders[subscription.orders.length - 1];

	if (lastOrder?.id) {
		const created = new Date(lastOrder.createdAt * 1000);
		const due = new Date(lastOrder.dueDate * 1000);

		daysUntilDue = getAmountOfDaysBetweenDates(created, due);
	}

	if (hideAdvancedOptions) {
		return null;
	}

	return (
		<Section
			title={t('{step}. Advanced options', { step: 3 })}
			description={t('Additional settings for this subscription')}
			hideDivider>
			<Column spacing={spacing._7}>
				{subscription.collectionMethod === 'manual' && (
					<Input.Group
						hint={t(
							'All invoices generated by the subscription will have this due setting.'
						)}
						label={t('Days until due')}
						errorMessage={
							formContext.formState.errors?.daysUntilDue &&
							t('Please enter a value between 1 and 365 for days until due.')
						}>
						<Input.Field
							name="daysUntilDue"
							type="number"
							defaultValue={daysUntilDue}
							min={1}
							max={365}
							required>
							<Input.Prefix>
								<Icon name="nav-events" />
							</Input.Prefix>
						</Input.Field>
					</Input.Group>
				)}
				{paymentProviderContext.settings.autoCharge ===
					AutoChargeOptions.Settable && (
					<Input.Control
						label={t('Auto-charge customer')}
						description={t(
							`Charge your customer's existing payment method automatically each billing cycle.`
						)}
						type="toggle"
						position="after"
						disabled={!hasPaymentMethod}
						onChange={handleChargeToggle}
						checked={OrderContext.formState.collectionMethod === 'automatic'}
					/>
				)}
			</Column>
		</Section>
	);
}
