import { ReactNode } from 'react';

export const symbol_3p: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M320-400h320v-19q0-42-42.5-65T480-507q-75 0-117.5 23T320-419v19Zm160-173q30.525 0 52.263-21.737Q554-616.475 554-647t-21.737-52.263Q510.525-721 480-721t-52.263 21.737Q406-677.525 406-647t21.737 52.263Q449.475-573 480-573ZM240-240 131-131q-14 14-32.5 6.344T80-152v-668q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H240Zm-26-60h606v-520H140v600l74-80Zm-74 0v-520 520Z"
		/>
	</svg>
);
