import { ReactNode } from 'react';

export const symbol_power_input: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M110-390q-12.75 0-21.375-8.675Q80-407.351 80-420.175 80-433 88.625-441.5T110-450h154q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T264-390H110Zm273 0q-13 0-21-8.675-8-8.676-8-21.5 0-12.825 8.625-21.325T384-450h153q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T537-390H383Zm273 0q-13 0-21-8.675-8-8.676-8-21.5 0-12.825 8.625-21.325T657-450h153q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-390H656ZM110-510q-12.75 0-21.375-8.675Q80-527.351 80-540.175 80-553 88.625-561.5T110-570h700q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-510H110Z"
		/>
	</svg>
);
