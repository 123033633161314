import { t } from '@transifex/native';
import { Share, ShareOptions } from '@capacitor/share';
import { useState } from 'react';

import copyString from 'pkg/copyString';
import * as actions from 'pkg/actions';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { tlog } from 'pkg/tlog';

export function useShare(options: ShareOptions): {
	canShare: boolean;
	handleShare: () => void;
} {
	const [share, setShare] = useState(false);

	useComponentDidMount(async () => {
		const s = await Share.canShare();

		setShare(s.value);
	});

	const handleShare = async () => {
		if (share) {
			await Share.share(options);
		}
	};

	return { canShare: share, handleShare };
}

export function hasShareApi(): boolean {
	return 'share' in navigator && typeof navigator.share === 'function';
}

export async function url(url: string): Promise<void> {
	try {
		await navigator.share({
			url,
		});
	} catch (e) {
		tlog.error(e);
	}
}

export async function currentUrl(): Promise<void> {
	await url(document.location.href);
}

export function copyCurrentUrl(): void {
	copyString(document.location.href);

	actions.flashes.show({
		title: t(`Link copied to clipboard`),
	});
}
