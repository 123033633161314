import { ReactNode } from 'react';

export const symbol_3g_mobiledata: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M150-280q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-340h177v-110H152q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T152-510h175v-110H150q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-680h177q24.75 0 42.375 17.625T387-620v84q0 23-13.5 40T338-479q21 0 35 16.333 14 16.334 14 39.667v83q0 24.75-17.625 42.375T327-280H150Zm400 0q-24.75 0-42.375-17.625T490-340v-280q0-24.75 17.625-42.375T550-680h260q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-620H550v280h231v-112h-73q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T708-512h102q12.75 0 21.375 8.625T840-482v142q0 24.75-17 42.375T781-280H550Z"
		/>
	</svg>
);
