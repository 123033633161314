import { Fragment, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { t } from '@transifex/native';

import { toMedium } from 'pkg/config/breakpoints';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import { useCollection } from 'pkg/api/use_collection';
import { useQueryState } from 'pkg/hooks/query-state';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';

import CreateResourceModal from 'routes/scheduling/resources/modals/Create';
import CreateCategory from 'routes/scheduling/categories/modals/Create';

import { LargeScreen } from 'components/MediaQuery';

import ResourcesTable from 'components/resources/table';
import * as ActionBar from 'components/layout/ActionBar';
import { ButtonTrigger } from 'components/navigation/header/small_screen/page_actions/ButtonTrigger';
import { useTemplateContext } from 'components/layout/page-templates/context';

import * as Context from 'design/context_menu';
import Button from 'design/button';
interface ResourcesViewProps {
	groupId: number;
}

const ResourcesView = ({ groupId }: ResourcesViewProps) => {
	const qs = useQueryState();
	const search = qs.get('search');
	const { setPageActions } = useTemplateContext();

	const [modal, setModal] = useState('');

	const isSmallScreen = useMediaQuery({ maxWidth: toMedium });

	const {
		records: resources,
		refresh: refreshResources,
		isLoading,
	} = useCollection<models.resource.Resource>(endpoints.Resource.Index(), {
		queryParams: new URLSearchParams({
			group_id: groupId.toString(),
		}),
	});

	const { records: categories, refresh: refreshCategories } =
		useCollection<models.resourceCategory.ResourceCategory>(
			endpoints.ResourceCategory.Index(),
			{
				queryParams: new URLSearchParams({
					group_id: groupId.toString(),
				}),
			}
		);

	const sortedResources = models.resource.buildResourceTree(
		resources,
		categories,
		true
	);

	useComponentDidMount(() => setPageActions(contextMenu));

	const showResourceModal = () => setModal('resource');
	const showCategoryModal = () => setModal('category');
	const hideModal = () => setModal('');

	const handleCreateCategory = () => {
		refreshCategories();
	};

	const contextMenuTrigger = isSmallScreen ? (
		<ButtonTrigger icon="add" />
	) : (
		<Button label={t('Create new')} icon="add" primary />
	);

	const contextMenu = (
		<Context.Menu toggleWith={contextMenuTrigger}>
			<Context.Item onClick={showResourceModal}>
				<Context.ItemIcon name="distance" />
				{t('New resource')}
			</Context.Item>
			<Context.Item onClick={showCategoryModal}>
				<Context.ItemIcon name="create_new_folder" />
				{t(`Category`)}
			</Context.Item>
		</Context.Menu>
	);

	return (
		<Fragment>
			<ActionBar.FilterBar>
				<ActionBar.PrimaryAction>
					<ActionBar.Search
						value={qs.get('search') as string}
						placeholder={t('Search')}
					/>
				</ActionBar.PrimaryAction>
				<LargeScreen>{contextMenu}</LargeScreen>
			</ActionBar.FilterBar>
			<ResourcesTable
				resources={sortedResources}
				refresh={refreshResources}
				search={search?.toString()}
				categories={categories}
				refreshCategories={refreshCategories}
				handleOpenModal={showResourceModal}
				isLoading={isLoading}
			/>
			{modal === 'resource' && (
				<CreateResourceModal
					groupId={groupId}
					refresh={refreshResources}
					refreshCategories={refreshCategories}
					hideModal={hideModal}
					resources={sortedResources}
				/>
			)}
			{modal === 'category' && (
				<CreateCategory
					groupId={groupId}
					hideModal={hideModal}
					onCreate={handleCreateCategory}
				/>
			)}
		</Fragment>
	);
};

export default ResourcesView;
