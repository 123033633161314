import { ReactNode } from 'react';

export const symbol_menstrual_health: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480v-320q0-19 16.5-27t31.5 3l197 147q28-42 60-86.5t69-90.5q10-12 26-12t26 12q37 46 69 90t60 87l197-147q15-11 31.5-3t16.5 27v320q0 83-31.5 156T763-197q-54 54-127 85.5T480-80ZM200-360q0-56 24-122.5T293-625L140-740v260q0 62 20.5 116t56.5 99q-8-23-12.5-46.5T200-360Zm280 220q91 0 155.5-64.5T700-360q0-91-60-201.5T480-791Q380-670 320-560.5T260-360q0 91 64.5 155.5T480-140Zm280-220q0 25-4.5 48.5T743-265q36-45 56.5-99T820-480v-260L667-625q45 76 69 143t24 122Z"
		/>
	</svg>
);
