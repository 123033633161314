import { useSelector } from 'react-redux';
import { Fragment } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import * as selectors from 'pkg/selectors';
import { RootState } from 'pkg/reducers';
import { useCurrentRoute } from 'pkg/router/hooks';
import { useCurrentMembership } from 'pkg/identity';
import * as models from 'pkg/api/models';

import Icon from 'components/icon';

import * as ContextMenu from 'design/context_menu';

const Wrapper = styled.div`
	position: absolute;
	top: var(--spacing-2);
	right: var(--spacing-2);
`;

interface ListItemContextMenuProps {
	clipId: number;

	edit: () => Promise<void>;
	copy: () => Promise<void>;
	link: () => Promise<void>;
	destroy: () => Promise<void>;
	addToPlaylist: () => void;

	onClose: () => void;
}
export default function ListItemContextMenu({
	clipId,

	edit,
	copy,
	link,
	destroy,
	addToPlaylist,

	onClose,
}: ListItemContextMenuProps): JSX.Element {
	const { videoId } = useCurrentRoute();

	const clip = useSelector((state: RootState) =>
		selectors.videoSequences.find(state, clipId)
	);

	const video = useSelector((state: RootState) =>
		selectors.videos.find(state, videoId)
	);

	const activeMembership = useCurrentMembership();

	const canEdit = edit && clip.hasIn(['links', 'edit']);
	const canCopy = copy && video.hasIn(['links', 'create:video_sequence']);
	const canDelete = destroy && clip.hasIn(['links', 'delete']);
	const canAddToPlaylist = !models.membership.isParent(activeMembership);

	return (
		<Fragment>
			<Wrapper>
				<ContextMenu.Menu defaultOpen toggleWith={<span />} onClose={onClose}>
					{canEdit && (
						<ContextMenu.Item onClick={edit}>
							<Icon name="edit" />
							<span>{t('Edit clip')}</span>
						</ContextMenu.Item>
					)}

					{canCopy && (
						<ContextMenu.Item onClick={copy}>
							<Icon name="duplicate" />
							<span>{t('Copy clip')}</span>
						</ContextMenu.Item>
					)}

					{(canEdit || canCopy) && <ContextMenu.Divider />}

					{canAddToPlaylist && (
						<ContextMenu.Item onClick={addToPlaylist}>
							<Icon name="playlist-add" size={1.4} />
							<span>{t('Add to playlist')}</span>
						</ContextMenu.Item>
					)}

					<ContextMenu.Item onClick={link}>
						<Icon name="link" rotate="45deg" />
						<span>{t('Copy link to clip')}</span>
					</ContextMenu.Item>

					{canDelete && destroy && (
						<Fragment>
							<ContextMenu.Divider />
							<ContextMenu.ConfirmItem
								caution
								message={t('Are you sure? This action cannot be undone.')}
								confirmLabel={t('Delete')}
								onConfirm={destroy}>
								<Icon name="block" />
								<span>{t('Delete clip')}</span>
							</ContextMenu.ConfirmItem>
						</Fragment>
					)}
				</ContextMenu.Menu>
			</Wrapper>
		</Fragment>
	);
}
