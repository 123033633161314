import { AllowedActionable } from 'pkg/api/models/allowed_actionable';
import { getAmountOfDaysBetweenDates } from 'pkg/date';
import * as models from 'pkg/api/models';

export enum CardBrand {
	AmericanExpress = 'american-express',
	CartesBancaires = 'cartes-bancaires',
	Cirrus = 'cirrus',
	Discover = 'discover',
	DinersClub = 'diners-club',
	Elo = 'elo',
	Hipercard = 'hipercard',
	Jcb = 'jcb',
	Maestro = 'maestro',
	Mastercard = 'mastercard',
	Mir = 'mir',
	UnionPay = 'union-pay',
	Visa = 'visa',
}

export enum PaymentMethodType {
	Unknown = 'unknown',
	Card = 'card',
}

interface PaymentMethodData {
	lastFourDigits: string;
	expirationMonth: number;
	expirationYear: number;
	cardBrand: CardBrand;
}

export interface PaymentMethod extends AllowedActionable {
	id: number;
	userId: number;
	groupPaymentConfigId: number;
	paymentProviderId: string;
	primary: boolean;
	type: PaymentMethodType;
	paymentMethodData: PaymentMethodData;
	createdAt?: number;
	disabledAt?: number;
	expiredAt?: number;
	archivedAt?: number;
}

export const getCardBrand = (paymentMethod: PaymentMethod): string => {
	switch (paymentMethod.paymentMethodData.cardBrand) {
		case models.paymentMethod.CardBrand.AmericanExpress:
			return 'American Express';
		case models.paymentMethod.CardBrand.CartesBancaires:
			return 'Cartes Bancaires';
		case models.paymentMethod.CardBrand.Cirrus:
			return 'Cirrus';
		case models.paymentMethod.CardBrand.Discover:
			return 'Discover';
		case models.paymentMethod.CardBrand.DinersClub:
			return 'Diner Club';
		case models.paymentMethod.CardBrand.Elo:
			return 'Elo';
		case models.paymentMethod.CardBrand.Hipercard:
			return 'Hipercard';
		case models.paymentMethod.CardBrand.Jcb:
			return 'JCB';
		case models.paymentMethod.CardBrand.Maestro:
			return 'Maestro';
		case models.paymentMethod.CardBrand.Mastercard:
			return 'Mastercard';
		case models.paymentMethod.CardBrand.Mir:
			return 'Mir (МИР)';
		case models.paymentMethod.CardBrand.UnionPay:
			return 'Union Pay';
		case models.paymentMethod.CardBrand.Visa:
			return 'Visa';
		default:
			return '';
	}
};

export const getCardImgUrl = (paymentMethod: PaymentMethod): string => {
	const getUrl = (brandImgFileName: string) =>
		`img/card-brands/${brandImgFileName}.svg`;

	switch (paymentMethod.paymentMethodData.cardBrand) {
		case models.paymentMethod.CardBrand.AmericanExpress:
			return getUrl('american_express');
		case models.paymentMethod.CardBrand.CartesBancaires:
			return getUrl('cartes_bancaires');
		case models.paymentMethod.CardBrand.Cirrus:
			return getUrl('cirrus');
		case models.paymentMethod.CardBrand.Discover:
			return getUrl('discover');
		case models.paymentMethod.CardBrand.DinersClub:
			return getUrl('diners_club');
		case models.paymentMethod.CardBrand.Elo:
			return getUrl('elo');
		case models.paymentMethod.CardBrand.Hipercard:
			return getUrl('hipercard');
		case models.paymentMethod.CardBrand.Jcb:
			return getUrl('jcb');
		case models.paymentMethod.CardBrand.Maestro:
			return getUrl('maestro');
		case models.paymentMethod.CardBrand.Mastercard:
			return getUrl('mastercard');
		case models.paymentMethod.CardBrand.Mir:
			return getUrl('mir');
		case models.paymentMethod.CardBrand.UnionPay:
			return getUrl('union_pay');
		case models.paymentMethod.CardBrand.Visa:
			return getUrl('visa');
		default:
			return '';
	}
};

export const getCardBrandWithDigits = (
	paymentMethod: PaymentMethod
): string => {
	return `${getCardBrand(paymentMethod)}${' '} **** ${paymentMethod.paymentMethodData.lastFourDigits}`;
};

export const getFormattedExpirationDate = (paymentMethod: PaymentMethod) => {
	const { expirationYear, expirationMonth } = paymentMethod.paymentMethodData;

	const formattedYear = expirationYear?.toString()?.substring(2);
	const formattedMonth =
		expirationMonth >= 10 ? expirationMonth?.toString() : `0${expirationMonth}`;

	return `${formattedMonth}/${formattedYear}`;
};

export const expiresSoon = (paymentMethod: PaymentMethod): boolean => {
	const expirationYear = paymentMethod?.paymentMethodData.expirationYear;
	const expirationMonth = paymentMethod?.paymentMethodData.expirationMonth;

	const expirationDate = new Date(expirationYear, expirationMonth);
	const todayDate = new Date();

	// we consider a payment method to expire soon from 30days or less before it expires
	return 30 >= getAmountOfDaysBetweenDates(todayDate, expirationDate);
};
