// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dZNuY3NiuHvuJqcqANjN {
	font-weight: var(--font-weight-semibold);
	color: var(--palette-gray-900);
}

.dZNuY3NiuHvuJqcqANjN.xWwJa7hYEocSFJqfMSr2 {
		text-decoration: line-through;
	}

._UBZNH3nE5hcpI2ujm6K {
	font-size: var(--font-size-sm);
	line-height: var(--font-line-height-sm);
	color: var(--palette-gray-500);
	font-weight: var(--font-weight-normal);
}

.RXEdo9bnT7aTfuyev09c {
	-ms-flex-item-align: center;
	    align-self: center;
}

.OD62Yia_27bfzg58Ntgm {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	    -ms-flex-direction: row;
	        flex-direction: row;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	gap: var(--spacing-2);
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
}
`, "",{"version":3,"sources":["webpack://./routes/scheduling/templates/modals/publish/published-period-card-list/styles.css"],"names":[],"mappings":"AAAA;CACC,wCAAwC;CACxC,8BAA8B;AAK/B;;AAHC;EACC,6BAA6B;CAC9B;;AAGD;CACC,8BAA8B;CAC9B,uCAAuC;CACvC,8BAA8B;CAC9B,sCAAsC;AACvC;;AAEA;CACC,2BAAkB;KAAlB,kBAAkB;AACnB;;AAEA;CACC,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,8BAAmB;CAAnB,6BAAmB;KAAnB,uBAAmB;SAAnB,mBAAmB;CACnB,mBAAe;KAAf,eAAe;CACf,qBAAqB;CACrB,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;AACpB","sourcesContent":[".date {\n\tfont-weight: var(--font-weight-semibold);\n\tcolor: var(--palette-gray-900);\n\n\t&.removed {\n\t\ttext-decoration: line-through;\n\t}\n}\n\n.label {\n\tfont-size: var(--font-size-sm);\n\tline-height: var(--font-line-height-sm);\n\tcolor: var(--palette-gray-500);\n\tfont-weight: var(--font-weight-normal);\n}\n\n.icon {\n\talign-self: center;\n}\n\n.labelRow {\n\tdisplay: flex;\n\tflex-direction: row;\n\tflex-wrap: wrap;\n\tgap: var(--spacing-2);\n\talign-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
export var date = `dZNuY3NiuHvuJqcqANjN`;
export var removed = `xWwJa7hYEocSFJqfMSr2`;
export var label = `_UBZNH3nE5hcpI2ujm6K`;
export var icon = `RXEdo9bnT7aTfuyev09c`;
export var labelRow = `OD62Yia_27bfzg58Ntgm`;
export default ___CSS_LOADER_EXPORT___;
