import { ReactNode } from 'react';

export const symbol_wb_twilight: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M792-670q9 9 9 21t-9 21l-43 43q-9.067 9-21.533 9-12.467 0-21.337-9.053-9.13-9.052-8.63-21.5Q698-619 707-628l43-42q9-8 21-8.5t21 8.5ZM110-170q-12.75 0-21.375-8.675Q80-187.351 80-200.175 80-213 88.625-221.5T110-230h740q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-170H110Zm370.175-630q12.825 0 21.325 8.625T510-770v60q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T450-710v-60q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625ZM169-672q9-9 21-9t21 9l44 44q9 9.067 9 21.533 0 12.467-9 21.858-9 8.609-21.5 8.109T212-586l-43-44q-8-9-8.5-21t8.5-21Zm99 292h424q-19-71-77-115.5T480-540q-77 0-135 44.5T268-380Zm-68 60q0-117 81.5-198.5T480-600q117 0 198.5 81.5T760-320H200Zm280-60Z"
		/>
	</svg>
);
