import { forwardRef, Fragment } from 'react';
import styled, { css } from 'styled-components';

import * as palette from 'pkg/config/palette';

import rgba from 'pkg/rgba';
import Link from 'pkg/router/Link';

import MaterialSymbol from 'components/material-symbols';
import Icon from 'components/icon';

const ItemName = styled.span`
	font-weight: var(--font-weight-normal);
	font-size: var(--font-size-3xs);
`;

const IconWrapper = styled.div`
	position: relative;
	font-size: 24px;
	padding-bottom: var(--spacing-1);
`;

const ItemIcon = styled(Icon)`
	transition: transform 150ms ease-in-out;
	margin-bottom: 0;
	display: block;
`;

const ActiveTab = css`
	position: relative;
	color: ${(props) => props.theme.primaryColorDark || palette.blue[500]};
	box-shadow: inset 0px 3px 0px 0px
		${(props) => props.theme.primaryColorDark || palette.blue[500]};

	@media (hover: hover) {
		&:hover {
			color: ${palette.gray[600]};
		}
	}

	${ItemName} {
		font-weight: var(--font-weight-bold);
	}

	${ItemIcon} {
		stroke: currentColor;
		stroke-width: 0.3px;
	}
`;

const HighLightTab = css`
	color: ${(props) => props.theme.accentColor || palette.black};
	position: relative;
	padding: 0;

	@media (hover: hover) {
		&:hover {
			color: ${palette.black};
		}
	}

	${IconWrapper} {
		background-color: ${(props) => props.theme.primaryColor || palette.brand};
		border-radius: 100px;
		padding: 5px;
		width: 35px;
		height: 35px;
		display: flex;
		align-items: center;
		justify-content: center;
		box-shadow: inset 0 0 0 1px ${rgba(palette.black, 0.05)};
	}

	${ItemIcon} {
		font-size: 25px;
		padding: 2px;
		margin: 0;
	}
`;

const WrapperStyling = css`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: var(--spacing-4) var(--spacing-1) var(--spacing-3) var(--spacing-1);
	width: 100%;
	height: 100%;

	color: ${palette.gray[600]};

	@media (hover: hover) {
		&:hover {
			cursor: pointer;
			color: ${palette.gray[600]};
		}
	}

	${(props) => props.active && !props.noActiveState && ActiveTab};

	${(props) => props.highlighted && HighLightTab};
`;

const ActiveWrapper = styled.div`
	${WrapperStyling};
`;

const Wrapper = styled(Link)`
	${WrapperStyling};
`;

const UnreadLabel = styled.span`
	position: absolute;
	top: 0px;
	right: 0px;
	z-index: 1;
	display: inline-block;

	&::before {
		content: '';
		width: 10px;
		height: 10px;
		background: var(--palette-red-500);
		display: block;
		border-radius: var(--radius-5);

		${(props) =>
			props.active &&
			css`
				box-shadow:
					${palette.white} 0 0 0 2px,
					${rgba(palette.white, 0)} 1px 2px 3px;
			`};

		${(props) =>
			!props.active &&
			css`
				box-shadow: ${palette.white} 0 0 0 2px;
			`};
	}
`;

/**
 * @type {React.Element<any>}
 */
const TabMenuItem = forwardRef(
	(
		{
			name,
			icon,
			activeIcon,
			href,
			onClick,
			active,
			highlighted,
			noActiveState,
			hideLabel,
			columns,
			size,
			slug,
			visible,
			unread,
			children,
			to,
			as,
			testid,
		},
		ref
	) => {
		const itemContent = (
			<Fragment>
				<IconWrapper>
					{unread && <UnreadLabel active={active} />}
					<MaterialSymbol
						variant={activeIcon && active ? activeIcon : icon}
						labelHidden={hideLabel}
						size={1.25}
						slug={slug}
						active={active}
					/>
					{children}
				</IconWrapper>
				{!hideLabel && <ItemName size={size}>{name}</ItemName>}
			</Fragment>
		);

		const wrapperProps = {
			onClick,
			as,
			href,
			active,
			highlighted,
			noActiveState,
			columns,
			ref,
			visible,
			to,
			'data-testid': testid || 'navbar.menu_item',
		};

		if (active) {
			return (
				<ActiveWrapper resetHistory {...wrapperProps}>
					{itemContent}
				</ActiveWrapper>
			);
		} else {
			return (
				<Wrapper resetHistory {...wrapperProps}>
					{itemContent}
				</Wrapper>
			);
		}
	}
);

const NOOP = () => {};

TabMenuItem.defaultProps = {
	onClick: NOOP,
};

export default TabMenuItem;
