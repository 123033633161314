import styled, { css } from 'styled-components';

import * as styles from 'pkg/config/styles';

import rgba from 'pkg/rgba';

export const Wrapper = styled.div<{ visible?: boolean }>`
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: ${({ theme }) => theme.tabBarHeight}px;
	width: 100%;
	height: calc(100% - ${({ theme }) => theme.tabBarHeight}px);
	transition: all 250ms ease-in-out;
	transform: translateY(100%);
	z-index: ${styles.zIndex.navigation - 1};
	visibility: hidden;

	${({ visible }) =>
		visible &&
		css`
			transform: translateY(0);
			visibility: visible;
		`};
`;

export const Navigation = styled.nav`
	display: grid;
	grid-template-rows: auto 1fr;
	background: ${({ theme }) =>
		theme.primaryColor || rgba(styles.palette.navBar.backgroundColor)};
	transition: width 200ms;
	z-index: ${styles.zIndex.drawer};
	overflow: hidden;
	height: 100%;
	width: 100%;
`;

export const Header = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	align-items: center;
	width: 100%;
	padding-top: var(--sat);
`;
