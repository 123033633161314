import { t } from '@transifex/native';
import { useState, useCallback, ChangeEvent } from 'react';
import { List } from 'immutable';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import spacing from 'pkg/config/spacing';

import Group from 'pkg/models/group';
import { MembershipRole } from 'pkg/models/membership';
import { GroupsProps } from 'pkg/models/user';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import { debounce } from 'pkg/timings';
import store from 'pkg/store/createStore';
import * as selectors from 'pkg/selectors';
import * as actions from 'pkg/actions';
import { useCollection } from 'pkg/api/use_collection';

import TreeBase from 'containers/group/settings/user/tree/Base';

import Icon from 'components/icon';
import StepModal, { Step } from 'components/step-modal';

import * as Input from 'components/form/inputs';
import SingleChoice from 'components/form/SingleChoice';

const Top = styled.div`
	display: grid;
	grid-gap: ${spacing._5};
	padding: ${spacing._6};
`;

interface Props {
	groupId: number;
	userId?: number;
	preSelectedGroups?: number[];
	onConfirm: (data: GroupsProps[]) => void;
	onClose: () => void;
}

const AddToGroupsModal: React.FC<React.PropsWithChildren<Props>> = ({
	groupId,
	userId,
	preSelectedGroups,
	onConfirm,
	onClose,
}) => {
	const { records: memberships } = useCollection<models.membership.Membership>(
		userId ? endpoints.UserGroups.GroupMemberships(groupId, userId) : null
	);

	const disabledGroups = memberships?.map(
		(membership: models.membership.Membership) => membership.groupId
	);

	const [selectedGroups, setSelectedGroups] = useState<number[]>(
		preSelectedGroups || []
	);
	const [searchQuery, setSearchQuery] = useState<string>('');
	const [searchedGroupIds, setSearchedGroupIds] = useState<number[]>([]);
	const [role, setRole] = useState<number>(1);

	const membershipTree = useSelector((state) =>
		selectors.groups.getOrganizationTree(state, {
			groupId,
		})
	);

	const searchedGroups = useSelector((state) =>
		selectors.groups.findAllByIds(state, searchedGroupIds)
	).toList();

	const debouncedSearch = useCallback(
		(value: string) => {
			debounce(async () => {
				if (value.trim().length === 0) {
					return;
				}

				const groups = await actions.groups.searchChildren(
					groupId,
					value
				)(store.dispatch);

				setSearchedGroupIds(groups);
			}, 500)();
		},
		[groupId]
	);

	const handleSearch = async ({ target }: ChangeEvent<HTMLInputElement>) => {
		setSearchQuery(target.value);

		if (!target.value) {
			setSearchedGroupIds([]);
			return;
		}

		debouncedSearch(target.value);
	};

	const handleSelect = (group: Group) => {
		setSelectedGroups((prev) => {
			return prev.includes(group.id)
				? prev.filter((groupId) => groupId !== group.id)
				: [...prev, group.id];
		});
	};

	const handleRoleChange = (role: number) => setRole(role);

	const handleDone = async (): Promise<boolean> => {
		await onConfirm(selectedGroups.map((id) => ({ role, id })));

		return true;
	};

	const isSearch = !!searchQuery;

	const userOption = {
		key: MembershipRole.User,
		title: t(`Player`),
		description: t(`Player in the team`),
	};

	const staffOption = {
		key: MembershipRole.Staff,
		title: t(`Staff`),
		description: t(`Coach or Team manager`),
	};

	const adminOption = {
		key: MembershipRole.Admin,
		title: t(`Admin`),
		description: t(`Can change settings`),
	};

	const roleSelectorOptions = [userOption, staffOption, adminOption].filter(
		(n) => n
	);

	return (
		<StepModal onClose={onClose}>
			<Step
				title={t(`Add user to group`)}
				skipBody
				canGoNext={selectedGroups.length > 0}
				nextLabel={t('Done')}
				onNext={handleDone}>
				<Top>
					<Input.Field
						rounded="true"
						placeholder={t(`Search group (name)`)}
						testid="contacts.single.search_group"
						onChange={handleSearch}>
						<Input.Prefix inline>
							<Icon name="search" size={1.2} />
						</Input.Prefix>
					</Input.Field>

					<SingleChoice
						onChange={handleRoleChange}
						value={role}
						options={roleSelectorOptions}
					/>
				</Top>
				<TreeBase
					groups={isSearch ? List(searchedGroups) : membershipTree}
					disabledGroups={disabledGroups}
					isSearch={isSearch}
					selected={List(selectedGroups)}
					onSelect={handleSelect}
				/>
			</Step>
		</StepModal>
	);
};

export default AddToGroupsModal;
