import { Fragment, useRef, useState } from 'react';
import { useT } from '@transifex/react';

import * as models from 'pkg/api/models';
import {
	ImageEditorFormat,
	SaveableImage,
	useImageEditor,
} from 'pkg/hooks/image-editor';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';

import * as StepModal from 'components/step-modal';
import MaterialSymbol from 'components/material-symbols';

import FileInput from 'components/form/File';
import RangeSlider from 'components/form/RangeSlider';
import Row from 'components/layout/row';

import Button from 'design/button';

import * as css from './styles.css';

interface BadgeEditorProps {
	group: models.group.Group;
}

export default function BadgeEditor({ group }: BadgeEditorProps): JSX.Element {
	const t = useT();

	const fileInputRef = useRef<HTMLInputElement>(null);
	const [open, setOpen] = useState<boolean>(false);
	const [scale, setScale] = useState<number>(1);
	const [loading, setLoading] = useState<boolean>(false);

	const openModal = () => setOpen(true);
	const closeModal = () => setOpen(false);

	const canvasSize: number = 320;
	const groupBadgeUrl = group?.profileImageUrl;

	const editor = useImageEditor({
		width: canvasSize,
		height: canvasSize,
		imageWidth: canvasSize * 3,
		backgroundColor: models.group.getPrimaryColorStylesheetString(group),
		gutterRoundness: 20,
		maskOpacity: 1,

		saveFormat: ImageEditorFormat.Base64,
		onSuccess: () => {
			setScale(1);
		},
		onSave: async (image: SaveableImage) => {
			await models.update<{ badge: string }, models.group.Group>(group, {
				badge: image as string,
			});

			location.reload();
		},
	});

	const handleImageSelect = async (files: File[]) => {
		await editor.load(files[0]);
	};

	const handleImageScaleChange = (scale: number) => {
		setScale(scale);
		editor.scale(scale);
	};

	useComponentDidMount(
		() => {
			if (editor && groupBadgeUrl) {
				editor.load(groupBadgeUrl);
			}
		},
		() => {
			setScale(1);
			setLoading(false);
		}
	);

	return (
		<Fragment>
			<Button small icon="image" onClick={openModal}>
				{groupBadgeUrl ? t('Change') : t('Upload')}
			</Button>
			{open && (
				<StepModal.Base onClose={closeModal}>
					<StepModal.Step
						title={t('Group Badge')}
						nextLabel={t('Save')}
						canGoPrev={!loading}
						canGoNext={editor.hasImage || !loading}
						onNext={editor.save}>
						<div className={css.badgeEditor}>
							<div className={css.editor}>
								{editor.canvas}
								<FileInput
									accept="image/*"
									allowDrop
									onChange={handleImageSelect}
									inputRef={fileInputRef}
									className={
										editor.hasImage ? css.fileTrigger : css.uploadTrigger
									}>
									{editor.hasImage ? (
										<MaterialSymbol variant="edit" />
									) : (
										<Button icon="image" className={css.uploadButton}>
											{t('Upload image')}
										</Button>
									)}
								</FileInput>
							</div>
							<Row
								columns="20px 1fr 20px"
								align="center"
								justify="center"
								className={css.scale}>
								<MaterialSymbol variant="do_not_disturb_on" />
								<RangeSlider
									min={0.25}
									max={3}
									step={0.01}
									value={scale}
									onChange={handleImageScaleChange}
								/>
								<MaterialSymbol variant="add_circle" />
							</Row>
						</div>
					</StepModal.Step>
				</StepModal.Base>
			)}
		</Fragment>
	);
}
