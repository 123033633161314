import { ReactNode } from 'react';

export const symbol_phonelink_ring: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M260-40q-24.75 0-42.375-17.625T200-100v-760q0-24.75 17.625-42.375T260-920h440q24.75 0 42.375 17.625T760-860v116q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T700-744v-26H260v580h440v-26q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T760-216v116q0 24.75-17.625 42.375T700-40H260Zm0-90v30h440v-30H260Zm0-700h440v-30H260v30Zm0 0v-30 30Zm0 700v30-30Zm660-350.554Q920-429 902.5-381T852-295q-8 10-20 10t-20.87-9.391Q803-303 802.5-315.042 802-327.083 810-337q24.359-31.075 37.179-67.906Q860-441.736 860-480.868 860-520 847-556q-13-36-39-65-9-10-10-23.5t8.087-22.587Q816-677 829.5-677t22.5 11q33 38 50.5 85.946 17.5 47.945 17.5 99.5ZM800-483q0 35.368-14.096 67.974Q771.809-382.421 747-357q-3.587 3.75-9.326 6.375Q731.935-348 726.196-348 714-348 705-356.816q-9-8.817-9-21.306 0-5.878 2.5-11.378T706-400q17-17 25.5-38t8.5-44q0-23-8.5-45T706-566q-5-4-7-9.659-2-5.658-2-11.317 0-12.024 8.816-20.524 8.817-8.5 21.306-8.5 5.878 0 11.378 2t9.5 7q25 25 38.5 57.093Q800-517.813 800-483Z"
		/>
	</svg>
);
