import { Fragment, memo } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import { setForceNavBarBackLink } from 'pkg/actions/app';

import * as models from 'pkg/api/models';
import { route } from 'pkg/router/utils';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCollection } from 'pkg/api/use_collection';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';

import MonitoredChatList from 'containers/chat/MonitoredChatList';
import Header from 'containers/chat/Header';
import MonitoringUser from 'containers/chat/MonitoringUser';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import Icon from 'components/icon';

import Thread from 'components/chat/thread';
import { Spinner } from 'components/loaders/spinner';

import InlinePlaceholder from 'design/placeholders/inline';

const LargeLayout = styled.div`
	height: 100%;
	display: grid;
	grid-template-columns: 400px 1fr;
	grid-template-rows: 4rem 1fr auto;
	grid-template-areas:
		'initiate header'
		'chatlist thread'
		'chatlist thread'
		'chatlist footer';

	${InlinePlaceholder} {
		grid-area: thread;
		align-self: center;
	}
`;

const SmallLayoutInChat = styled.div`
	height: 100%;
	min-height: 0;
	display: grid;
	grid-template-areas: 'header' 'thread' 'footer';
	grid-template-rows: 3.4rem 1fr auto;

	${InlinePlaceholder} {
		grid-area: thread;
		align-self: center;
	}
`;

const SmallLayoutInList = styled.div`
	height: 100%;
	min-height: 0;
	display: grid;
	grid-template-areas: 'chatlist';
	grid-template-rows: 1fr;
	overflow: scroll;
	-webkit-overflow-scrolling: touch;
`;

interface CurrentChatProps {
	targetAccountId?: number;
	forceBack?: boolean;

	chat: models.chat.Chat;
}

const CurrentChat = memo(
	({ targetAccountId, forceBack = false, chat }: CurrentChatProps) => {
		useComponentDidMount(
			() => {
				if (forceBack) {
					setForceNavBarBackLink(
						route('chat.monitored', { targetAccountId }, true)
					);
				}
			},
			() => {
				if (forceBack) {
					setForceNavBarBackLink('');
				}
			}
		);

		if (!chat) {
			return (
				<InlinePlaceholder>
					<Icon name="add-conversation" />
					{t('Pick a conversation')}
				</InlinePlaceholder>
			);
		}

		return (
			<Fragment>
				<Header chat={chat} />
				<Thread chat={chat} />
			</Fragment>
		);
	}
);

interface ViewProps {
	targetAccountId: number;
	targetChatId: number;
}

export default function View({ targetAccountId, targetChatId }: ViewProps) {
	const { records: chats, isLoading } = useCollection<models.chat.Chat>(
		endpoints.Chat.ListMonitored(targetAccountId)
	);

	const targetChat = chats.find((c) => c.id === targetChatId);

	if (isLoading) {
		return <Spinner />;
	}

	return (
		<Fragment>
			<LargeScreen>
				<LargeLayout>
					<MonitoringUser accountId={targetAccountId} />
					<MonitoredChatList
						replaceState
						currentChatId={targetChatId}
						monitoredAccountId={targetAccountId}
						chats={chats}
					/>
					<CurrentChat chat={targetChat} />
				</LargeLayout>
			</LargeScreen>

			<SmallScreen>
				{!targetChatId && (
					<SmallLayoutInList>
						<MonitoredChatList
							currentChatId={targetChatId}
							monitoredAccountId={targetAccountId}
							chats={chats}
						/>
					</SmallLayoutInList>
				)}

				{targetChatId && (
					<SmallLayoutInChat>
						<CurrentChat
							forceBack
							targetAccountId={targetAccountId}
							chat={targetChat}
						/>
					</SmallLayoutInChat>
				)}
			</SmallScreen>
		</Fragment>
	);
}
