import * as flashActions from 'pkg/actions/flashes';

import { crash } from 'pkg/errors/errors';
import * as endpoints from 'pkg/api/endpoints/auto';
import { Field, FormProductPrice, Statuses } from 'pkg/api/models/form';
import * as sdk from 'pkg/core/sdk';

export interface UpdateFormPayload {
	title: string;
	formCategoryId?: number | string;
	description?: string;
	maxSubmissions?: number;
	visibility?: string;
	status: Statuses;
	fields: Field[];
	productPrices?: FormProductPrice[];
	attachmentId?: number;
	submissionEmailEnabled?: boolean;
	submissionEmailSubject?: string;
	submissionEmailContent?: string;
}

export const update = async (
	formId: number,
	payload: UpdateFormPayload
): Promise<Response> => {
	const request = await sdk.patch(endpoints.Forms.Update(formId), {}, payload);
	const response = await request.json();

	if (!request.ok) {
		flashActions.show({
			title: crash().title,
			message: response.error,
		});

		return;
	}

	return request;
};
