import styled from 'styled-components';

import { toMedium } from 'pkg/config/breakpoints';
import { ActionBarHeight } from 'pkg/config/sizes';

export const Container = styled.div`
	overflow: auto;
	background: var(--palette-white);

	@media all and (max-width: ${toMedium}px) {
		height: calc(100% - ${ActionBarHeight.MEDIUM}px);
	}
`;

export const Wrapper = styled.div`
	width: 100%;
	border-bottom: 1px solid var(--palette-gray-300);

	@media print {
		border-right: 1px solid var(--palette-black);
		border-left: 1px solid var(--palette-black);
		border-bottom: 1px solid var(--palette-black);
		border-top: 2px solid var(--palette-black);
		gap: 0px;
	}
`;

export const ContentWrapper = styled.div<{ templateRows?: string }>`
	height: 100%;
	width: 100%;
	display: grid;
	grid-template-rows: ${({ templateRows }) =>
		templateRows ? templateRows : 'auto 1fr'};

	@media print {
		size: A4 landscape;
	}
`;
