import { ReactNode } from 'react';

export const symbol_power_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M412-120q-12.75 0-21.375-8.625T382-150v-88L256-377q-7.652-7.857-11.826-18.214T240-417v-192.318Q240-634 257-651.5q17-17.5 41-17.5l60 60h-58v191l142 155.701V-180h76v-82l49-54L88-795q-9-9-9-21t9-21q9-9 21-9t21 9l708 708q9 9 9 21t-9 21q-9 9-21 9t-21-9L610-273l-32 35v88q0 12.75-8.625 21.375T548-120H412Zm280-240-32-32v-217H443L342-710v-100q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T402-810v141h156v-141q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T618-810v171l-30-30h72q24.75 0 42.375 17.625T720-609v194q0 11.378-3.5 21.689T705-375l-13 15ZM553-499Zm-114 55Z"
		/>
	</svg>
);
