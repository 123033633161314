import { t } from '@transifex/native';
import { createRef, createElement, Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { small } from 'pkg/config/breakpoints';
import * as palette from 'pkg/config/palette';

import { getPositions } from 'pkg/selectors/positions.selector';
import { getRatingItemsByCategory } from 'pkg/selectors/rating_items';
import { makeGetUserRatings } from 'pkg/selectors/ratings.selector';
import { getMembershipsForUser } from 'pkg/selectors/groups';

import { deprecatedFetchPositions } from 'pkg/actions/positions';
import { fetchRatingItems } from 'pkg/actions/rating_items';
import { saveNewUserRating } from 'pkg/actions/ratings';

import { IdentityContext } from 'pkg/identity';
import * as models from 'pkg/api/models';
import { isDev } from 'pkg/flags';

import AssessmentPreview from 'containers/user/assessment/AssessmentPreview';

import CardBase from 'components/Card';

import { PlaceholderType, Skeleton } from 'components/loaders/skeleton';
import Categories from 'components/rating/create/stacks/Categories';
import Positions from 'components/rating/create/stacks/Positions';
import FootComponent from 'components/rating/create/stacks/FootComponent';
import CompleteComponent from 'components/rating/create/stacks/Complete';
import PhysicalMaturity from 'components/rating/create/stacks/PhysicalMaturity';
import SectionWrapper from 'components/rating/create/stacks/SectionWrapper';
import AnalyzingComponent from 'components/rating/create/stacks/AnalyzingComponent';

const CreateWrapper = styled(CardBase)`
	display: grid;
	max-height: 100%;
	height: 100%;
	grid-row-gap: 1rem;
	background: ${palette.white};

	@media ${small} {
		height: 100%;
		grid-row-gap: 0;
		-webkit-overflow-scrolling: touch;
	}
`;

class CreateAssessment extends Component {
	static contextType = IdentityContext;

	static defaultProps = {
		onComplete: () => {},
		ratingItems: {},
		positions: [],
		userMembership: {},
	};

	sectionWrapper = createRef();

	state = {
		position: {},
		primaryFoot: '',
		maturityAdjustment: 0,
		canNavigate: false,
		loaded: false,
		isPrimaryRating: null,
		section: 'index',
		preview: false,
		ratingId: '',
	};

	ASSESSMENT_STACK = {
		index: {
			component: Positions,
			showNavigation: true,
			stackTitle: t(`Choose position`),
			stackDescription: t(`Select the location for this assessment`),
			isValid: (position) => position?.id,
			getRelevantState: ({ position }) => ({ position }),
			getStateValue: ({ position }) => position,
			prev: null,
			next: 'primaryFoot',
			props: {},
		},

		primaryFoot: {
			component: FootComponent,
			stackTitle: t(`Primary Foot`),
			stackDescription: t(`Select the players primary foot.`),
			showNavigation: true,
			isValid: (primaryFoot) => ['left', 'right'].includes(primaryFoot),
			getRelevantState: ({ primaryFoot }) => ({ primaryFoot }),
			getStateValue: ({ primaryFoot }) => primaryFoot,
			prev: true,
			next: 'development',
			props: {},
		},
	};

	parseAssesmentStack = () => {
		if (
			this.props.ratingItems.length === 0 ||
			this.props.positions.length === 0
		) {
			return;
		}

		const state = {};

		let ratingCategories = [...Object.keys(this.props.ratingItems)];
		let ratingItems = { ...this.props.ratingItems };

		const gk = this.props.positions.find((p) => p.slug === 'gk');

		if (this.state.position && this.state.position.id !== gk.id) {
			delete this.ASSESSMENT_STACK.goalkeeping;
			delete ratingItems.goalkeeping;

			ratingCategories = ratingCategories.filter(
				(key) => key !== 'goalkeeping'
			);
		}

		this.ASSESSMENT_STACK.index.props.positions = this.props.positions;

		if (this.isUserChild && !this.isSelfRating) {
			this.ASSESSMENT_STACK.primaryFoot.next = 'development';

			this.ASSESSMENT_STACK.development = {
				component: PhysicalMaturity,
				stackTitle: t(`Physical Development`),
				stackDescription: t(
					`Select the level of the players physical development`
				),
				getRelevantState: ({ maturityAdjustment }) => ({ maturityAdjustment }),
				getStateValue: ({ maturityAdjustment }) => maturityAdjustment,
				showNavigation: true,
				isValid: (maturityAdjustment) =>
					[-2, -1, 0, 1, 2].includes(Number.parseInt(maturityAdjustment)),
				prev: true,
				next: ratingCategories[0],
				props: {},
			};
		} else {
			this.ASSESSMENT_STACK.primaryFoot.next = ratingCategories[0];
		}

		for (let key in ratingItems) {
			const keyIndex = ratingCategories.findIndex((k) => k === key);
			const nextStep = ratingCategories[keyIndex + 1];
			const prevStep = ratingCategories[keyIndex - 1];
			const fallBackPrevStep = this.isUserChild ? 'development' : 'primaryFoot';

			state[key] = {};

			this.props.ratingItems[key].forEach((item) => {
				state[key][item.id] = isDev() ? 5 : 0;
			});

			this.ASSESSMENT_STACK[key] = {
				component: Categories,
				showNavigation: true,
				getRelevantState: (state) => ({ [key]: state[key] }),
				getStateValue: (state) => state[key],
				isValid: (data) => Object.keys(data).every((key) => data[key] > 0),
				next: nextStep !== undefined ? nextStep : 'complete',
				prev: prevStep !== undefined ? prevStep : fallBackPrevStep,
				props: {
					label: key,
					ratingItems: this.props.ratingItems[key],
				},
			};
		}

		if (this.state.position.slug !== 'gk') {
			this.ASSESSMENT_STACK['goalkeeping'] = {
				component: null,
				getRelevantState: () => ({}),
				getStateValue: () => null,
				props: {},
			};
		}

		this.ASSESSMENT_STACK['complete'] = {
			component: CompleteComponent,
			stackTitle: models.membership.isAdminOrStaff(this.context.membership)
				? t(`Set assessment accessibility`)
				: '',

			isValid: (isPrimaryRating) => isPrimaryRating,
			showNavigation: true,
			getRelevantState: ({ isPrimaryRating }) => ({ isPrimaryRating }),
			getStateValue: ({ isPrimaryRating }) => isPrimaryRating,
			prev: ratingCategories[ratingCategories.length - 1],
			next: this.save,
			nextTitle: t('Save'),
			prev: false,
			props: {
				onSave: this.save,
				isAdmin: models.membership.isAdminOrStaff(this.context.membership),
				setPrimaryRating: this.handlePrimaryRating,
			},
		};

		this.ASSESSMENT_STACK['analyzing'] = {
			component: AnalyzingComponent,
			isValid: () => true,
			showNavigation: false,
			getRelevantState: () => ({}),
			getStateValue: () => null,
			prev: 'complete',
			next: null,
			props: {
				userId: this.props.userId,
				onComplete: this.props.onComplete,
				setPreview: this.setPreview,
			},
		};

		this.setState(state);
	};

	handlePrimaryRating = (isPrimaryRating) => {
		this.setState({ isPrimaryRating, canNavigate: true });
	};

	async componentDidMount() {
		await this.props.fetchRatingItems(this.props.forAccount);
		await this.props.fetchPositions(this.props.forAccount);

		this.parseAssesmentStack();

		this.setState({ loaded: true });
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.section && this.state.section !== prevState.section) {
			const sectionState = this.currentSection.getStateValue(this.state);
			const canNavigate = this.currentSection?.isValid(sectionState);

			this.setState({ canNavigate });
		}

		if (this.state.position) {
			const posIndex = this.props.positions
				.map((p) => p.id)
				.findIndex((pos) => pos === this.state.position.id);

			if (posIndex >= 0) {
				if (prevState.position.id !== this.state.position.id) {
					this.parseAssesmentStack();
				}
			}
		}
	}

	save = async (e) => {
		if (e) {
			e.preventDefault();
		}

		let requestObject = {};

		requestObject.raterUserId = this.context.membership.userId;
		requestObject.suggestedPositionId = this.state.position.id;
		requestObject.maturityAdjustment = Number.parseInt(
			this.state.maturityAdjustment
		);

		requestObject.primaryFoot = this.state.primaryFoot;
		requestObject.isPrimaryRating = this.state.isPrimaryRating;

		if (this.context.membership.groupId) {
			requestObject.groupId = this.context.membership.groupId;
		}

		requestObject.ratingItems = [];

		const ratingKeys = Object.keys(this.props.ratingItems).filter((key) => {
			if (this.state.position.slug !== 'gk' && key === 'goalkeeping') {
				return false;
			}

			return true;
		});

		const ratingCategories = [...ratingKeys];
		let items = {};
		for (let key in ratingCategories) {
			const _categoryItems = this.state[ratingCategories[key]];

			items = {
				...items,
				..._categoryItems,
			};
		}

		for (let n in items) {
			let itemValue = parseInt(Math.round(items[n] * 10), 10);

			if (itemValue === 0) {
				this.setState({ showErrors: true });

				return;
			}

			requestObject.ratingItems.push({
				ratingItemId: parseInt(n, 10),
				value: itemValue,
			});
		}

		const ratingRequest = this.props.saveNewUserRating(
			this.props.userId,
			requestObject,
			this.props.forAccount
		);

		this.ASSESSMENT_STACK.analyzing.props.ratingRequest = ratingRequest;

		this.setState({ section: 'analyzing' });
	};

	get currentSection() {
		if (
			!this.ASSESSMENT_STACK[this.state.section] ||
			this.state.section === undefined
		) {
			return this.ASSESSMENT_STACK.index;
		}

		return this.ASSESSMENT_STACK[this.state.section];
	}

	onSelect = (section, value) => {
		this.setState({
			[section]: value,
			canNavigate: this.currentSection.isValid(value),
		});
	};

	get pages() {
		if (this.currentSection?.component) {
			return createElement(this.currentSection.component, {
				userId: this.props.userId,
				onSelect: this.onSelect,
				positions: this.props.positions,
				...this.currentSection.props,
				...this.currentSection.getRelevantState(this.state),
			});
		}

		return null;
	}

	get isSelfRating() {
		const { userId } = this.props;

		// User is creating a self rating
		if (this.context.membership.userId === userId) {
			return true;
		}

		return false;
	}

	get isUserChild() {
		if (this.props.userMembership?.user?.birthDate) {
			return !this.props.userMembership.user.isAnAdult;
		}

		return false;
	}

	scrollTop = () => {
		this.sectionWrapper.current.scrollTop();
	};

	goBack = () => {
		this.setState({
			section: this.currentSection.prev,
		});

		this.scrollTop();
	};

	goToNext = () => {
		if (typeof this.currentSection.next === 'function') {
			this.currentSection.next();
		} else {
			this.setState({
				section: this.currentSection.next,
			});
		}

		this.scrollTop();
	};

	setPreview = (rating) => {
		this.setState({
			preview: true,
			ratingId: rating,
		});
	};

	render() {
		if (this.state.preview) {
			return (
				<AssessmentPreview
					complete={this.props.onComplete}
					ratingId={this.state.ratingId}
					isOnboarding={this.props.isOnboarding}
				/>
			);
		}
		if (!this.state.loaded) {
			return (
				<CreateWrapper key="CreateWrapper">
					<Skeleton placeholderType={PlaceholderType.Assessment} />
				</CreateWrapper>
			);
		}

		return (
			<CreateWrapper>
				<SectionWrapper
					ref={this.sectionWrapper}
					prev={this.currentSection.prev}
					next={this.currentSection.next}
					stackTitle={this.currentSection.stackTitle}
					stackDescription={this.currentSection.stackDescription}
					showNavigation={this.currentSection.showNavigation}
					nextTitle={this.currentSection.nextTitle}
					prevTitle={t('Previous')}
					goBack={this.goBack}
					goToNext={this.goToNext}
					canNavigate={this.state.canNavigate}>
					{this.pages}
				</SectionWrapper>
			</CreateWrapper>
		);
	}
}

const mapStateToProps = () => {
	const getUserRatings = makeGetUserRatings();

	return (state, props) => ({
		positions: getPositions(state),
		ratingItems: getRatingItemsByCategory(state),
		userMembership: getMembershipsForUser(state, props).first(),
		userRatings: getUserRatings(state, props),
	});
};

const actions = {
	fetchPositions: deprecatedFetchPositions,
	fetchRatingItems,
	saveNewUserRating,
};

export default connect(mapStateToProps, actions)(CreateAssessment);
