import { ReactNode } from 'react';

export const symbol_child_friendly: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M361-80q-23 0-39.5-16.5T305-136q0-23 16.5-39.5T361-192q23 0 39.5 16.5T417-136q0 23-16.5 39.5T361-80Zm423 0q-23 0-39.5-16.5T728-136q0-23 16.5-39.5T784-192q23 0 39.5 16.5T840-136q0 23-16.5 39.5T784-80ZM536-647 382-832q37-19 79-28.5t79-9.5q41 0 80 8.5t74 25.5q13 8 14.5 22t-9.5 24L536-647Zm-38 347h168L366-661v229q0 57 37.5 94.5T498-300Zm18-181ZM110-714q-13 0-21.5-8.5T80-744q0-56 36-96t87-40q29 0 54.5 12.5T320-810l423 506q15 18 4.5 41T712-240H498q-81 0-136.5-55.5T306-432v-302l-29-33q-28-32-43.5-42.5T203-820q-31 0-47 24t-16 52q0 13-8.5 21.5T110-714Z"
		/>
	</svg>
);
