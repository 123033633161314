import { ReactNode } from 'react';

export const symbol_confirmation_number: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-160q-24.75 0-42.375-17.625T80-220v-132q0-10.185 5.5-17.593Q91-377 100-380q31-11 48.5-39.5t17.5-60q0-31.5-17.5-60.5T100-580q-9-3-14.5-10.407Q80-597.815 80-608v-132q0-24.75 17.625-42.375T140-800h680q24.75 0 42.375 17.625T880-740v132q0 10.185-5.5 17.593Q869-583 860-580q-31 11-48.5 40T794-479.5q0 31.5 17.5 60T860-380q9 3 14.5 10.407Q880-362.185 880-352v132q0 24.75-17.625 42.375T820-160H140Zm0-60h680v-109q-38-26-62-65t-24-86q0-47 24-86t62-65v-109H140v109q39 26 62.5 65t23.5 86q0 47-23.5 86T140-329v109Zm340-63q12 0 21-9t9-21q0-12-9-21t-21-9q-12 0-21 9t-9 21q0 12 9 21t21 9Zm0-167q12 0 21-9t9-21q0-12-9-21t-21-9q-12 0-21 9t-9 21q0 12 9 21t21 9Zm0-167q12 0 21-9t9-21q0-12-9-21t-21-9q-12 0-21 9t-9 21q0 12 9 21t21 9Zm0 137Z"
		/>
	</svg>
);
