import { t } from '@transifex/native';

import { Record } from 'pkg/api/models/record';
import { Dateable } from 'pkg/api/models/dateable';
import { User } from 'pkg/api/models/user';
import { Linkable } from 'pkg/api/models/linkable';
import * as numbers from 'pkg/numbers';

export enum UserActivityType {
	Individual = 'individual',
	Group = 'group',
	Match = 'match',
	Other = 'other',
}

export interface UserActivity extends Record, Linkable, Dateable {
	ratingItemId?: number;
	exerciseId?: number;
	userId?: number;
	user: User;

	description?: string;
	privateComment?: string;

	physicalStrain: number;
	type: UserActivityType;
}

export function averagePhysicalStrain(
	activities: UserActivity[],
	physicalStrainDefault: number = 50
): number {
	if (!activities || activities?.length === 0) {
		return physicalStrainDefault;
	}

	return numbers.average(
		activities?.map((activity: UserActivity) => activity?.physicalStrain)
	);
}

export enum PhysicalStrainThreshold {
	Max = 100,
	VeryHigh = 90,
	High = 70,
	Moderate = 40,
	Low = 20,
}

export function physicalStrainLabel(value: number): string {
	if (value < 0 || value > 100) {
		return t('Invalid');
	}

	if (value === PhysicalStrainThreshold.Max) {
		return t('Max');
	} else if (value >= PhysicalStrainThreshold.VeryHigh) {
		return t('Very high');
	} else if (value >= PhysicalStrainThreshold.High) {
		return t('High');
	} else if (value >= PhysicalStrainThreshold.Moderate) {
		return t('Moderate');
	} else if (value >= PhysicalStrainThreshold.Low) {
		return t('Low');
	} else {
		return t('Very low');
	}
}
