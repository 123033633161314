import { ReactNode } from 'react';

export const symbol_airline_seat_legroom_normal: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M660-410H320q-28.875 0-49.438-20.562Q250-451.125 250-480v-330q0-12.75 8.625-21.375T280-840h160q12.75 0 21.375 8.625T470-810v220h220q28.875 0 49.438 20.562Q760-548.875 760-520v300h70q21 0 35.5 14.5T880-170q0 21-14.5 35.5T830-120H690q-12.75 0-21.375-8.625T660-150v-260ZM530-280H180q-24.75 0-42.375-17.625T120-340v-470q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T180-810v470h350q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T530-280Z"
		/>
	</svg>
);
