import { ReactNode } from 'react';

export const symbol_body_system: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m437-453-47 190q-1 5-4 9t-6.5 7q-3.5 3-8.5 5t-11 2q-14 0-23-11t-6-25l73-350-101 43v63q0 13-8.5 21.5T273-490q-13 0-22-9t-9-22v-102l192-81q14-6 29.5-7.5T494-710q17 3 29.5 11t20.5 20l42 66q20 32 51 59t59 38q11 5 19 14.5t8 21.5q0 12-8 20.5t-19 5.5q-38-10-83-40t-81-80l-27 98 92 83v123q0 13-8.5 21.5T567-240q-13 0-21.5-8.5T537-270v-92l-100-91Zm113-301q-30 0-51.5-21.5T477-827q0-30 21.5-51.5T550-900q30 0 51.5 21.5T623-827q0 30-21.5 51.5T550-754ZM480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-110 53.5-200T275-823q11-7 23-3t17 15q5 11 2 22.5T303-770q-73 45-118 121t-45 169q0 141 99.5 240.5T480-140q135 0 231.5-90.5T819-452q1-12 10-20t21-8q13 0 21.5 10t7.5 23q-13 155-127 261T480-80Z"
		/>
	</svg>
);
