import { ReactNode } from 'react';

export const symbol_blinds_closed: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M160-180v-600q0-24.75 17.625-42.375T220-840h520q24.75 0 42.375 17.625T800-780v600h50q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-120H665q0 25-17.601 42.5t-42.5 17.5Q580-60 562.5-77.571 545-95.143 545-120H110q-12.75 0-21.375-8.675Q80-137.351 80-150.175 80-163 88.625-171.5T110-180h50Zm60-495h355v-105H220v105Zm415 0h105v-105H635v105ZM220-510h355v-105H220v105Zm415 0h105v-105H635v105ZM220-345h355v-105H220v105Zm415 0h105v-105H635v105ZM220-180h355v-105H220v105Zm415 0h105v-105H635v105Z"
		/>
	</svg>
);
