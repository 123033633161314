import { ReactNode } from 'react';

export const symbol_remove_shopping_cart: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M411-656q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T411-716h167q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T578-656H411ZM289.788-80Q260-80 239-101.212q-21-21.213-21-51Q218-182 239.212-203q21.213-21 51-21Q320-224 341-202.788q21 21.213 21 51Q362-122 340.788-101q-21.213 21-51 21Zm404 0Q664-80 643-101.212q-21-21.213-21-51Q622-182 643.212-203q21.213-21 51-21Q724-224 745-202.788q21 21.213 21 51Q766-122 744.788-101q-21.213 21-51 21ZM140-820H92q-12.75 0-21.375-8.675Q62-837.351 62-850.175 62-863 70.625-871.5T92-880h67q9 0 16.2 4.435Q182.4-871.13 186-863l162 347h288l152-265q4-7 10.75-11t15-4q17.25 0 25.75 14t.5 29L701-493q-11 19-28.559 30.5Q654.881-451 634-451H331l-56 104h461q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T736-287H284q-37.663 0-57.332-30Q207-347 224-378l64-118-148-324Z"
		/>
	</svg>
);
