import { ReactNode } from 'react';

export const symbol_drive_file_move: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-160q-24 0-42-18.5T80-220v-520q0-23 18-41.5t42-18.5h256q12.444 0 23.722 5t19.366 13.088L481-740h339q23 0 41.5 18.5T880-680v460q0 23-18.5 41.5T820-160H140Zm0-60h680v-460H456l-60-60H140v520Zm0 0v-520 520Zm386-190-54 54q-9 9-9 21t9 21q9 9 21 9t21-9l105-105q9-9 9-21t-9-21L514-566q-9-9-21-9t-21 9q-9 9-9 21t9 21l54 54H350q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T350-410h176Z"
		/>
	</svg>
);
