import { t } from '@transifex/native';
import { Fragment, useMemo } from 'react';

import DateTimePicker from 'components/form/DateTimePicker';
import FormRow from 'components/form/Row';
import Label from 'components/form/Label';
import SingleChoice from 'components/form/SingleChoice';

const Schedule = ({ dispatch, data, post }) => {
	const handlePublishedAtChange = (value) => {
		dispatch({
			type: 'set_value',
			payload: {
				key: 'publishedAt',
				value: Math.round(+value / 1000),
			},
		});
	};

	const handleStatusChange = (value) => {
		if (value !== 'scheduled' && data.publishedAt === 0) {
			dispatch({
				type: 'set_value',
				payload: {
					key: 'publishedAt',
					value: 0,
				},
			});
		}

		if (value === 'published' && data.publishedAt > post.publishedAt) {
			dispatch({
				type: 'set_value',
				payload: {
					key: 'publishedAt',
					value: post.publishedAt,
				},
			});
		}

		dispatch({
			type: 'set_value',
			payload: {
				key: 'status',
				value,
			},
		});
	};

	const publishDate = useMemo(() => {
		if (data.publishedAt === 0) {
			return new Date();
		}

		return new Date(data.publishedAt * 1000);
	}, [data.publishedAt]);

	return (
		<Fragment>
			<FormRow>
				<Label>{t(`Schedule`)}</Label>

				<SingleChoice
					onChange={handleStatusChange}
					value={data.status}
					testid="new_post_form.scheduled_options_wrapper"
					options={[
						{
							key: 'published',
							title: t(`Published`),
							description: t(`The post will be visible instantly.`),
						},
						{
							key: 'draft',
							title: t(`Draft`),
							description: t(
								`Not visible on the wall. You can publish it later.`
							),
						},
						{
							key: 'scheduled',
							title: t(`Scheduled`),
							description: t(
								`The post will be automatically published on the selected date.`
							),
						},
					]}
				/>
			</FormRow>

			{data.status === 'scheduled' && (
				<FormRow>
					<Label>{t(`Publish at`)}</Label>

					<DateTimePicker
						onDateChange={handlePublishedAtChange}
						disableDatesBefore={new Date()}
						selectedDate={publishDate}
					/>
				</FormRow>
			)}
		</Fragment>
	);
};

export default Schedule;
