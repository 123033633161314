import { t } from '@transifex/native';
import { Fragment } from 'react';

import { toolConfig } from 'components/drawing/config';
const _context = 'training_library/drawing';

interface ToolNameProp {
	tool: string;
}

export default function ToolName({ tool }: ToolNameProp) {
	let name = '';

	const [toolType, toolVariant] = tool?.split('_');
	switch (toolType) {
		case 'icon':
			name = toolConfig.icons[toolVariant].label;
			break;
		case 'circle':
			name = t('Circle', {
				_context,
			});
			break;
		case 'rectangle':
			name = t('Rectangle', {
				_context,
			});
			break;
		case 'polygon':
			name = t('Polygon', {
				_context,
			});
			break;
		case 'line':
			name = t('Line', {
				_context,
			});
			break;
		case 'arrow':
			name = t('Arrow', {
				_context,
			});
			break;
		case 'freehand':
			name = t('Freehand drawing', {
				_context,
			});
			break;
		case 'text':
			name = t('Text', {
				_context,
			});
			break;
		case 'player':
			name = t('Player', {
				_context,
			});
			break;
		case 'jersey':
			name = t('Jersey', {
				_context,
			});
			break;
	}

	return <Fragment>{name}</Fragment>;
}
