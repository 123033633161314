import { ReactNode } from 'react';

export const symbol_person_3: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M400-400q-50 0-85-35t-35-85q0-22 7-41.5t21-35.5q-4-10-6-21t-2-22q0-38 20.5-67.5T374-751q20-23 47-36t59-13q32 0 59 13t47 36q33 14 53.5 43.5T660-640q0 11-2 22t-6 21q14 16 21 35.5t7 41.5q0 50-35 85t-85 35H400Zm0-60h160q25.5 0 42.75-18.5T620-519.957q0-10.043-3-19.543T606-558q-8-10-11-19.135-3-9.134-3-17.173 0-11.692 4-23.192t4-22.553Q600-659 590-673.5 580-688 563-696q-7-3-12-7t-10-9q-6.774-8-22.355-18-15.58-10-38.613-10-23.032 0-38.639 10.545Q425.786-718.909 419-711q-5 5-10 9.5t-12 6.5q-17 7-27 21.5t-10 33.447q0 11.053 4 22.553t4 23.192q0 8.039-3 17.173Q362-568 354-558q-8 9-11 18.5t-3 19.543Q340-497 357.25-478.5T400-460ZM160-140v-34q0-38 19-65t49-41q67-30 128.5-45T480-340q62 0 123 15.5t127.921 44.694q31.301 14.126 50.19 40.966Q800-212 800-174v34q0 24.75-17.625 42.375T740-80H220q-24.75 0-42.375-17.625T160-140Zm60 0h520v-34q0-16-9.5-30.5T707-226q-64-31-117-42.5T480-280q-57 0-111 11.5T252-226q-14 7-23 21.5t-9 30.5v34Zm260 0Zm0-320Z"
		/>
	</svg>
);
