import { Fragment } from 'react';
import { T, useT } from '@transifex/react';

import { PageWidths } from 'pkg/config/sizes';

import * as flashActions from 'pkg/actions/flashes';

import useMixedState, { MixedStateSetter } from 'pkg/hooks/useMixedState';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as routes from 'pkg/router/routes';
import { pushState } from 'pkg/router/state';
import { useCurrentOrganization } from 'pkg/identity';
import Link from 'pkg/router/Link';

import CreateDiscountForm from 'routes/payments/discounts/create/form';
import ButtonActions from 'routes/payments/discounts/create/form/button-actions';
import { RecurringConfigOptions } from 'routes/payments/discounts/create/form/recurring-section';
import DiscountLargeScreenContentInner from 'routes/payments/discounts/create/form/inner-layout';
import ContentHeading from 'routes/payments/discounts/create/form/content-heading';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import * as ActionBar from 'components/layout/ActionBar';
import Form, { asNumber, asString, FormPayload } from 'components/form/Form';

import { ButtonGroup } from 'design/button';

import { FormActionFooter } from 'styles/Form';

export enum DiscountType {
	Percentage = 'percentage',
	FixedAmount = 'fixedAmount',
}

export enum RecurringOptions {
	Forever = 'forever',
	AmountOfPayments = 'amountOfPayments',
}

export interface DiscountFormState {
	active: boolean;
	saving: boolean;
	splitAmount: boolean;
	type: DiscountType;
	maxRecurringCount: number;
	showUsageCount: boolean;
	showPreview: boolean;
	payload: models.discount.CreateDiscountPayload;
	showSuccess: boolean;
}

export interface DiscountFormStateInterface {
	formState: DiscountFormState;
	setFormState: MixedStateSetter<DiscountFormState>;
}

export const defaultDiscountState: DiscountFormState = {
	type: DiscountType.Percentage,
	splitAmount: false,
	active: true,
	saving: false,
	maxRecurringCount: 1,
	showUsageCount: false,
	showPreview: false,
	payload: null,
	showSuccess: false,
};

export function makeDiscountPayload(
	groupId: number,
	formState: DiscountFormState,
	formData: FormPayload
): models.discount.CreateDiscountPayload {
	const payload: models.discount.CreateDiscountPayload = {
		groupId,
		title: asString(formData.title),
		description: asString(formData.description),
		code: asString(formData.code),
	};

	if (formState.type === DiscountType.Percentage) {
		payload.percentOff = asNumber(formData.discountValue);
	} else {
		payload.amountOff = asNumber(formData.discountValue) * 100;
	}

	if (formState.active) {
		payload.isActive = true;
	}

	if (formState.type === DiscountType.FixedAmount) {
		payload.splitAmount = formState.splitAmount;
	}

	if (formState.showUsageCount) {
		payload.maxUsageCount = asNumber(formData.maxUsageCount);
	}

	switch (formData.recurringConfig) {
		case RecurringConfigOptions.Initial:
			payload.maxRecurringCount = 1;
			break;
		case RecurringConfigOptions.Every:
			payload.maxRecurringCount = 0;
			break;
		case RecurringConfigOptions.SetAmount:
			payload.maxRecurringCount = asNumber(formState.maxRecurringCount);
			break;
	}

	return payload;
}

export default function CreateDiscount() {
	const org = useCurrentOrganization();
	const [formState, setFormState] =
		useMixedState<DiscountFormState>(defaultDiscountState);

	const handleSubmit = async (data: FormPayload) => {
		const payload = makeDiscountPayload(org.id, formState, data);

		setFormState({ showPreview: true, payload });
	};

	return (
		<Form onSubmit={handleSubmit}>
			<View formState={formState} setFormState={setFormState} />
		</Form>
	);
}

interface ViewProps {
	formState: DiscountFormState;
	setFormState: MixedStateSetter<DiscountFormState>;
}

function View({ formState, setFormState }: ViewProps) {
	const t = useT();
	const org = useCurrentOrganization();

	const handleCreateDiscount = async () => {
		setFormState({ saving: true });

		const [req] = await models.create<
			models.discount.CreateDiscountPayload,
			models.discount.Discount
		>(endpoints.Discounts.Create(), formState.payload);
		if (req.ok) {
			flashActions.show({
				title: t('Successfully created discount'),
			});
			pushState(routes.Discounts.Index(org.id));
		}

		setFormState({ saving: false });
	};

	const content = (
		<CreateDiscountForm formState={formState} setFormState={setFormState} />
	);

	const buttonActions = (
		<ButtonActions
			formState={formState}
			setFormState={setFormState}
			handleNextAction={handleCreateDiscount}
		/>
	);

	const contentHeading = (
		<ContentHeading
			title={
				formState.showPreview
					? t('Verify your discount')
					: t('Create discount code')
			}
			description={
				!formState.showPreview && (
					<T
						_str="Discounts can be used either in a registration or when creating invoices. {learn}"
						learn={
							<Link href="https://help.360player.com/article/164-how-to-work-with-discount-codes">
								{t('Learn more.')}
							</Link>
						}
					/>
				)
			}
		/>
	);

	return (
		<Fragment>
			<LargeScreen>
				{!formState.showPreview && (
					<ActionBar.SaveBar maxWidth={PageWidths.WIDE}>
						{buttonActions}
					</ActionBar.SaveBar>
				)}
				{contentHeading}
				<DiscountLargeScreenContentInner formState={formState}>
					{content}
					<FormActionFooter>
						<ButtonGroup>{buttonActions}</ButtonGroup>
					</FormActionFooter>
				</DiscountLargeScreenContentInner>
			</LargeScreen>
			<SmallScreen>
				<ActionBar.SaveBar maxWidth={PageWidths.WIDE}>
					{buttonActions}
				</ActionBar.SaveBar>
				<LargeScreenContent.Inner>
					{contentHeading}
					{content}
				</LargeScreenContent.Inner>
			</SmallScreen>
		</Fragment>
	);
}
