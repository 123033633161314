import { ReactNode } from 'react';

export const symbol_sos: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M400-280q-24.75 0-42.375-17.625T340-340v-280q0-24.75 17.625-42.375T400-680h160q24.75 0 42.375 17.625T620-620v280q0 24.75-17.625 42.375T560-280H400Zm-180 0H70q-12.75 0-21.375-8.675Q40-297.351 40-310.175 40-323 48.625-331.5T70-340h150v-110H100q-24.75 0-42.375-17.625T40-510v-110q0-24.75 17.625-42.375T100-680h150q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T250-620H100v110h120q24.75 0 42.375 17.625T280-450v110q0 24.75-17.625 42.375T220-280Zm640 0H710q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T710-340h150v-110H740q-24.75 0-42.375-17.625T680-510v-110q0-24.75 17.625-42.375T740-680h150q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T890-620H740v110h120q24.75 0 42.375 17.625T920-450v110q0 24.75-17.625 42.375T860-280Zm-460-60h160v-280H400v280Z"
		/>
	</svg>
);
