import styled from 'styled-components';
import { Fragment } from 'react';

import * as styles from 'pkg/config/styles';

import Video from 'pkg/models/video';

import Link from 'pkg/router/Link';
import { useCurrentRoute } from 'pkg/router/hooks';
import { useCurrentOrganization } from 'pkg/identity';
import * as routes from 'pkg/router/routes';
import { link } from 'pkg/router/utils';
import * as models from 'pkg/api/models';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import {
	FormattedContent,
	FormattedContentMaxLength,
} from 'components/formatted-content';
import RelativeDateTime from 'components/RelativeDateTime';
import * as Card from 'components/Card';
import Avatar from 'components/avatar';

import Thumbnail from 'components/video/Thumbnail';

import * as contextCss from 'design/context_menu/styles.css';

const Item = styled(Card.Base)`
	display: flex;
	padding: var(--spacing-6) 40px var(--spacing-6) var(--spacing-6);

	> .${contextCss.defaultToggle} {
		top: 8px;
		right: 8px;
	}

	@media ${`all and (max-width: ${styles.breakpoint.toSmall}px)`} {
		background-color: transparent;
		border: none;
		padding: 0;
	}
`;

const ThumbnailBox = styled(Link)`
	width: 255px;
	margin-right: var(--spacing-5);
	flex-shrink: 0;

	@media ${`all and (max-width: ${styles.breakpoint.toLarge}px)`} {
		width: 200px;
	}

	@media ${`all and (max-width: ${styles.breakpoint.toSmall}px)`} {
		width: 140px;
	}
`;

const TextBox = styled.div`
	padding-right: 44px;

	@media ${`all and (max-width: ${styles.breakpoint.toSmall}px)`} {
		padding-right: 0;
	}
`;

const Title = styled(Link)`
	display: block;
	font-weight: var(--font-weight-semibold);
	margin-bottom: var(--spacing-2);
	word-break: break-word;
	color: var(--palette-black);

	@media (hover: hover) {
		&:hover {
			color: var(--palette-black);
		}
	}

	@media ${`all and (max-width: ${styles.breakpoint.toSmall}px)`} {
		font-size: var(--font-size-sm);
	}
`;

const Description = styled.div`
	font-size: var(--font-size-xs);
`;

const VideoItemDate = styled(RelativeDateTime)`
	display: block;
	font-size: var(--font-size-sm);
	color: var(--palette-gray-500);
	margin-bottom: var(--spacing-3);

	@media ${`all and (max-width: ${styles.breakpoint.toSmall}px)`} {
		font-size: var(--font-size-xs);
	}
`;

const Author = styled.div`
	display: flex;
	align-items: center;
	color: var(--palette-gray-800);
	font-weight: var(--font-weight-semibold);
	font-size: var(--font-size-sm);
	margin-bottom: var(--spacing-3);

	${Avatar} {
		margin-right: var(--spacing-2);
	}

	@media ${`all and (max-width: ${styles.breakpoint.toSmall}px)`} {
		display: none;
	}
`;
interface VideoListItemProps {
	linkToTaggedUser?: boolean;
	video: Video;
	user?: models.user.User;
	account?: models.account.Account;
	handleDeleteVideo: (videoId: number) => void;
}

const VideoListItem = ({
	linkToTaggedUser,
	video,
	user,
	account,
}: VideoListItemProps): JSX.Element => {
	const route = useCurrentRoute();
	const org = useCurrentOrganization();

	const params: { [key: string]: number } = {};
	if (linkToTaggedUser) {
		params.tagged = route.userId;
	}

	return (
		<Item data-testid="video_library.list.item">
			<ThumbnailBox href={link(routes.Video.Show(org.id, video.id), params)}>
				<Thumbnail sourceUrl={video.thumbnailUrl} duration={video.duration} />
			</ThumbnailBox>
			<TextBox>
				<Title href={link(routes.Video.Show(org.id, video.id), params)}>
					{video.title}
				</Title>
				<VideoItemDate dateTime={video.createdAt} />
				<Author>
					{account?.id ? (
						<Fragment>
							<Avatar account={account} size={16} />
							<span>{models.account.fullName(account)}</span>
						</Fragment>
					) : (
						<Fragment>
							<Avatar user={user} size={16} />
							<span>{models.user.fullName(user)}</span>
						</Fragment>
					)}
				</Author>
				{video.description !== '' && (
					<Description>
						<LargeScreen>
							<FormattedContent
								raw={video.description}
								maxLength={FormattedContentMaxLength.Standard}
							/>
						</LargeScreen>
						<SmallScreen>
							<FormattedContent raw={video.description} maxLength={70} />
						</SmallScreen>
					</Description>
				)}
			</TextBox>
		</Item>
	);
};

export default VideoListItem;
