export default {
	name: 'duration',
	objects: [
		{
			type: 'path',
			attributes: {
				d: 'M16.0457 7.5561C16.4329 7.13797 17.0857 7.11285 17.5038 7.49999C17.9219 7.88714 17.9471 8.53995 17.5599 8.95808L16.7751 9.80567L15.2609 8.40369L16.0457 7.5561Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M12 18.9933C15.0034 18.9933 17.4382 16.5585 17.4382 13.5551C17.4382 10.5517 15.0034 8.11695 12 8.11695C8.99659 8.11695 6.56184 10.5517 6.56184 13.5551C6.56184 16.5585 8.99659 18.9933 12 18.9933ZM12 20.2933C15.7214 20.2933 18.7382 17.2765 18.7382 13.5551C18.7382 9.83372 15.7214 6.81695 12 6.81695C8.27862 6.81695 5.26184 9.83372 5.26184 13.5551C5.26184 17.2765 8.27862 20.2933 12 20.2933Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M9.54458 17.5551C9.75426 17.6838 9.97461 17.7964 10.2039 17.8913C11.0615 18.2466 12.0052 18.3395 12.9156 18.1584C13.4634 18.0494 13.9843 17.8442 14.4554 17.5551C14.7673 17.3636 15.0573 17.1354 15.3188 16.8739C15.5803 16.6124 15.8085 16.3224 16 16.0105C16.2891 15.5394 16.4943 15.0185 16.6033 14.4707C16.7844 13.5603 16.6915 12.6166 16.3362 11.759C16.2413 11.5297 16.1287 11.3094 16 11.0997C15.6472 10.525 15.1731 10.0305 14.6076 9.65258C13.8357 9.13686 12.9283 8.86159 12 8.86159L12 13.5551H7.30648C7.30648 14.4834 7.58175 15.3908 8.09748 16.1627C8.47535 16.7282 8.96987 17.2023 9.54458 17.5551Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M8.65481 5.48138C8.65481 5.12239 8.94582 4.83138 9.30481 4.83138H14.6953C15.0543 4.83138 15.3453 5.12239 15.3453 5.48138C15.3453 5.84036 15.0543 6.13138 14.6953 6.13138H12.6501V7.25425C12.6501 7.61323 12.3591 7.90425 12.0001 7.90425C11.6411 7.90425 11.3501 7.61323 11.3501 7.25425V6.13138H9.30481C8.94582 6.13138 8.65481 5.84036 8.65481 5.48138Z',
			},
		},
	],
};
