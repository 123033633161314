import { ReactNode } from 'react';

export const symbol_image_search: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h183q13 0 21.5 8.5T393-810q0 13-8.5 21.5T363-780H180v600h600v-211q0-13 8.5-21.5T810-421q13 0 21.5 8.5T840-391v211q0 24-18 42t-42 18H180Zm267-182 118-154q5-6 12-6t12 6l117 155q5 8 1 16t-13 8H268q-9 0-13.5-8t1.5-16l86-111q5-6 12-6t12 6l81 110Zm205-246q-70 0-118-48t-48-118q0-70 48-118t118-48q70 0 118 48t48 118q0 28-8 53t-22 46l109 109q9 9 9 21t-9 21q-9 9-21.5 9t-21.5-9L742-575q-21 14-41.5 20.5T652-548Zm0-60q46 0 76-30t30-76q0-46-30-76t-76-30q-46 0-76 30t-30 76q0 46 30 76t76 30Z"
		/>
	</svg>
);
