import { ReactNode } from 'react';

export const symbol_background_replace: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M124-520v-85l235-235h85L124-520Zm-4-166v-85l69-69h85L120-686Zm527-17q-10-11-21-21.5T602-743l97-97h85L647-703ZM220-361l77-77q7 11 14.5 20t16.5 17l-19 5q-23 7-45 16t-44 19Zm478-222q-2-18-6-35t-12-33l160-160v86L698-583ZM469-780l60-60h85l-70 70q-16-5-31.5-7.5T480-780h-11ZM120-345v-85l140-140v10q0 17 2 33t8 31L120-345Zm670 11q-12-11-26-18t-28-13l104-104v85l-50 50Zm-116-54q-11-3-21.5-6.5T631-401q20-17 33.5-39t22.5-47l153-153v86L674-388Zm-194-12q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm0-60q42 0 71-29t29-71q0-42-29-71t-71-29q-42 0-71 29t-29 71q0 42 29 71t71 29ZM160-150v-41q0-34 17-63t47-44q60-30 124.5-46T480-360q67 0 131.5 16T736-298q30 15 47 44t17 63v41q0 13-8.5 21.5T770-120H190q-13 0-21.5-8.5T160-150Zm60-30h520q0-20-7.5-37.5T708-244q-54-26-111-41t-117-15q-60 0-117 15t-111 41q-17 9-24.5 26.5T220-180Zm260 0Zm0-380Z"
		/>
	</svg>
);
