import { RefObject, useEffect, useRef, useState } from 'react';

import { clamp } from 'pkg/numbers';

type DimensionsRect = DOMRectReadOnly | null;

type Dimensions = number[];

export function useDimensions<T extends HTMLElement>(
	targetNodeRef?: RefObject<T>
): DimensionsRect {
	const [dimensions, setDismensions] = useState<DimensionsRect>(null);
	const observerRef = useRef<ResizeObserver>();

	useEffect(() => {
		observerRef.current = new ResizeObserver(([entry]) =>
			entry ? setDismensions(entry.contentRect) : null
		);
	}, [observerRef.current]);

	useEffect(() => {
		if (targetNodeRef.current) {
			observerRef.current.observe(targetNodeRef.current);
		}

		return () => {
			if (targetNodeRef.current) {
				observerRef.current.unobserve(targetNodeRef.current);
			}
		};
	}, [targetNodeRef.current]);

	return dimensions;
}

export function useVideoAspectRatio<T extends HTMLElement>(
	targetNodeRef: RefObject<T>,
	maxDimensions?: Dimensions
): Dimensions {
	const targetDimensions = useDimensions<T>(targetNodeRef);

	if (!targetDimensions) {
		return [0, 0];
	}

	let targetMaxWidth = targetDimensions.width;
	let targetMaxHeight = targetDimensions.height;

	if (maxDimensions) {
		const [maxWidth, maxHeight] = maxDimensions;

		if (maxWidth) {
			targetMaxWidth = clamp(targetMaxWidth, 0, maxWidth);
		}

		if (maxHeight) {
			targetMaxHeight = clamp(targetMaxHeight, 0, maxHeight);
		}
	}

	const aspectWidth = targetMaxHeight * (16 / 9);
	const aspectHeight = targetMaxWidth * (9 / 16);

	if (aspectWidth > targetMaxWidth) {
		return [targetMaxWidth, aspectHeight];
	} else if (aspectHeight > targetMaxHeight) {
		return [aspectWidth, targetMaxHeight];
	}

	return [targetMaxWidth, targetMaxHeight];
}
