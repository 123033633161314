import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import {
	MembershipStatus,
	translatedRoleStringFromMembership,
} from 'pkg/api/models/membership';
import { useCollection } from 'pkg/api/use_collection';
import * as actions from 'pkg/actions';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import store from 'pkg/store/createStore';

import SectionTitle from 'components/SectionTitle';
import Avatar from 'components/avatar';

import * as UserCard from 'components/user/UserCard';
import Column from 'components/layout/column';

import Button from 'design/button';

import * as css from './styles.css';

interface UserItemProps {
	membership: models.membership.Membership;
	refreshPendingRequests: () => void;
	refreshTeamList: () => void;
}

function UserItem({
	membership,
	refreshPendingRequests,
	refreshTeamList,
}: UserItemProps): JSX.Element {
	const updateMembership = async (status: MembershipStatus) => {
		await actions.groups.deprecatedUpdateGroupMember(
			membership.groupId,
			membership.userId,
			{
				status,
			}
		)(store.dispatch, store.getState);
	};

	const handleDecline = async () => {
		const didConfirm = confirm(t(`Decline request?`));

		if (!didConfirm) return;

		await updateMembership(MembershipStatus.Inactive);

		refreshPendingRequests();
	};

	const handleAccept = async () => {
		const didConfirm = confirm(t('Allow this user to join?'));
		if (!didConfirm) return;

		await updateMembership(MembershipStatus.Active);

		refreshPendingRequests();
		refreshTeamList();
	};

	return (
		<UserCard.Base className={css.card}>
			<UserCard.User className={css.user}>
				<Avatar user={membership.user} size={40} />

				<div>
					<strong>{models.user.fullName(membership.user)}</strong>
					<span>{translatedRoleStringFromMembership(membership)}</span>
				</div>
			</UserCard.User>

			<UserCard.Actions>
				<Button caution label={t('Decline')} onClick={handleDecline} />
				<Button secondary label={t('Accept')} onClick={handleAccept} />
			</UserCard.Actions>
		</UserCard.Base>
	);
}

interface PendingRequestsProps {
	groupId: number;
	refreshTeamList: () => void;
}
export default function PendingRequests({
	groupId,
	refreshTeamList,
}: PendingRequestsProps): JSX.Element {
	const {
		records,
		isLoading,
		refresh: refreshPendingRequests,
	} = useCollection<models.membership.Membership>(
		endpoints.Groups.ShowUsers(groupId),
		{
			queryParams: new URLSearchParams({
				status: 'pending',
			}),
			showAllResults: true,
		}
	);

	if (isLoading || records.length === 0) {
		return null;
	}

	const pendingMemberships = records.map((membership) => (
		<UserItem
			key={membership.user.id}
			membership={membership}
			refreshPendingRequests={refreshPendingRequests}
			refreshTeamList={refreshTeamList}
		/>
	));

	return (
		<Column spacing={styles.spacing._4}>
			<SectionTitle>{t(`Pending requests`)}</SectionTitle>
			<Column spacing={styles.spacing._3}>{pendingMemberships}</Column>
		</Column>
	);
}
