import { ReactNode } from 'react';

export const symbol_nest_cam_wired_stand: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-300q-58.333 0-99.167 40.25Q340-219.5 340-160v60h280v-60q0-59.5-40.25-99.75T480-300ZM300-650q0 74.715 50 129.358Q400-466 475-458l186 20v-425l-184.589 18.783Q401-836 350.5-780.5T300-650Zm20 610q-17 0-28.5-11.5T280-80v-80q0-84 58.5-142T480-360q16.677 0 31.839 2Q527-356 542-351l24-37-97-9q-98-10-163.5-82.649T240-650.499q0-99.203 65.5-171.852T469-904l187-19q26-2 45.5 15.5T721-863v424q0 27-19.5 44.5T656-379l-22-2-38 57q38 27 61 70t23 94v80q0 17-11.5 28.5T640-40H320Zm341-611ZM480-100Z"
		/>
	</svg>
);
