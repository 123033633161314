import { ReactNode } from 'react';

export const symbol_mail_lock: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M143-160q-25 0-44-18.5T80-220v-520q0-23 18-41.5t42-18.5h680q23 0 41.5 18.5T880-740v180q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T820-560v-120L496-473q-4 2-7.5 3t-8.5 1q-5 0-8.5-1t-7.5-3L140-680v460h500q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T640-160H143Zm337-367 340-213H140l340 213ZM140-220v-460 2-40.469V-717v-23 23-1.28V-677v-3 460Zm625 60q-14 0-24.5-10.5T730-195v-120q0-14 11.5-24.5T770-350v-45q0-30.938 22.044-52.969 22.045-22.031 53-22.031Q876-470 898-447.969q22 22.031 22 52.969v45q17 0 28.5 10.5T960-315v120q0 14-10.5 24.5T925-160H765Zm35-190h90v-44.912Q890-414 877.088-427q-12.913-13-32-13Q826-440 813-427.062 800-414.125 800-395v45Z"
		/>
	</svg>
);
