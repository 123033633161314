import { createContext, useState, useEffect, useRef } from 'react';
import { fabric } from 'fabric';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import uuid from 'pkg/uuid';
import { tlog } from 'pkg/tlog';
import { usePrevious } from 'pkg/hooks/component-lifecycle';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';

import {
	DEFAULT_ZOOM_OFFSET,
	getConfig,
	getPrefs,
	toolConfig,
} from 'components/drawing/config';
import { SportConfig } from 'components/drawing/config/sport';

export { fabric } from 'fabric';

const defaultSettings = {
	snapping: 10,
	snappingVisible: false,
	backgroundModalOpen: false,
};

export const FabricContext = createContext({
	drawing: null,
	setDrawing: null,
	canvas: null,
	initCanvas: null,
	settings: defaultSettings,
	setSetting: null,
	config: null,
	setBackground: null,
	slides: [],
	slide: 0,
	setSlide: null,
	setSlides: null,
	duration: 1800,
	setDuration: null,
	freehand: false,
	setFreehand: null,
	color: styles.palette.black + '55',
	setColor: null,
	updateDrawing: null,
	zoom: 1,
	setZoom: null,
	selectedObject: null,
	spacebarDown: null,
	triggeredTool: null,
	setTriggeredTool: null,
});

export const FabricContextProvider = ({
	drawing: drawingData,
	updateDrawing,
	children,
}: any) => {
	const initialDrawingData = structuredClone(drawingData);

	const [drawing, setDrawing] = useState(initialDrawingData);
	const canvas: any = useRef();
	const [config, setConfig] = useState<SportConfig>();
	const [settings, setSettings] = useState(defaultSettings);
	const [slides, setSlides] = useState<any>([{}]);
	const [slide, setSlide] = useState(0);
	const [duration, setDuration] = useState(1800);
	const [freehand, setFreehand] = useState(false);
	const [color, setColor] = useState(styles.palette.black + '55');
	const isMouseDown = useRef(false);

	const setSetting = (setting: string, value: any) => {
		setSettings({ ...settings, [setting]: value });
	};

	useComponentDidMount(async () => {
		const config = await import(
			`components/drawing/config/sport/${drawing.sport}.ts`
		);

		setConfig(config);
	});

	const initCanvas = (el: HTMLCanvasElement, isEditing: boolean): any => {
		// initialize canvas (interactive canvas only if editing)
		const Canvas = isEditing ? fabric.Canvas : fabric.StaticCanvas;
		const canvasInstance = new Canvas(el, {
			width: 1000,
			height: 1000,
			preserveObjectStacking: true,
		});

		fabric.Object.prototype.objectCaching = false;
		if (isEditing) {
			// customize rotation control cursor
			fabric.Object.prototype.controls.mtr.cursorStyleHandler = (
				fabricObject: any
			) => {
				if (fabricObject.lockRotation) {
					return 'not-allowed';
				} else {
					return `url(${
						window.TS.assetUrl + 'img/drawing/rotation-cursor.svg'
					}) 8 8, auto`;
				}
			};
		}

		// store canvas in context
		canvas.current = canvasInstance;

		// load existing slides
		if (initialDrawingData.slides) {
			setSlide(0);
			setSlides(initialDrawingData.slides);
		}

		// load existing canvas data
		if (initialDrawingData.objects) {
			Object.entries(initialDrawingData.objects).forEach(
				async ([id, object]: [id: string, object: any]) => {
					const obj = { ...object };
					const { create, prefs: prefNames } = getConfig(obj);
					const prefs = getPrefs(obj);
					if (create) {
						// create object
						obj.id = id;
						const createdObject = await create(obj);

						// set position from first slide
						const slides = initialDrawingData.slides;
						const firstSlide = slides[0][id];
						createdObject.set('top', firstSlide.top);
						createdObject.set('left', firstSlide.left);
						createdObject.set('scaleX', firstSlide.scaleX);
						createdObject.set('scaleY', firstSlide.scaleY);
						createdObject.set('angle', firstSlide.angle);
						rotateTexts(createdObject);

						// set all preferences
						prefs?.forEach((pref: any, i: number) => {
							pref.set({
								value: obj.prefs[prefNames[i]],
								obj: createdObject,
								canvas,
							});
						});

						// add to canvas
						canvasInstance.add(createdObject);
					}
				}
			);
		}
	};

	const setBackground = async (position: any) => {
		const bg = canvas.current
			.getObjects()
			.find((o: any) => o.id === 'background');
		if (bg) canvas.current.remove(bg);

		const set = config.backgrounds[position];

		// set canvas background
		fabric.Image.fromURL(
			window.TS.assetUrl + '/' + set.url + '.png',
			(img: any) => {
				img.id = 'background';
				canvas.current.add(img);
			},
			{ crossOrigin: 'Anonymous' }
		);
	};

	const setCanvasToBackgroundSize = (canvas: any) => {
		const bg = canvas.current
			.getObjects()
			.find((o: { id: string }) => o.id === 'background');

		if (!bg) return;

		// scale canvas to background
		const dimensions = [
			bg.width || bg.viewBoxWidth,
			bg.height || bg.viewBoxHeight,
		];

		let scale;
		const isWide = dimensions[0] > dimensions[1];
		if (isWide) {
			scale = 1000 / dimensions[0];
		} else {
			scale = 1000 / dimensions[1];
		}
		canvas.current.setWidth(dimensions[0] * scale);
		canvas.current.setHeight(dimensions[1] * scale);
		canvas.current.backgroundWidth = dimensions[0] * scale;
		canvas.current.backgroundHeight = dimensions[1] * scale;

		const zoomFactor =
			zoom > 1 + DEFAULT_ZOOM_OFFSET ? zoom - DEFAULT_ZOOM_OFFSET : 1;
		bg.scaleToWidth(dimensions[0] * scale * zoomFactor);

		// don't allow selection
		bg.selectable = false;
		bg.hoverCursor = 'default';
		bg.sendToBack();
	};

	useEffect(() => {
		if (!config || !canvas.current || !drawing?.background) return;
		setBackground(drawing.background);
	}, [config, canvas.current, drawing.background]);

	// grid and snapping
	useEffect(() => {
		if (!canvas.current || !canvas.current.contextCache) return;
		const size = settings.snapping;

		// remove existing grid
		const grid = canvas.current
			.getObjects()
			.find((obj: any) => obj.id === 'grid');
		canvas.current.remove(grid);

		// draw lines
		if (!size || !settings.snappingVisible) return;

		// offset to center of canvas
		let x = ((canvas.current.backgroundWidth / 2) % size) - size;
		let y = ((canvas.current.backgroundHeight / 2) % size) - size;
		const lines = [];

		const lineOption = {
			stroke: 'rgba(0, 0, 0, 0.2)',
			strokeWidth: 1,
			selectable: false,
		};

		while (x < canvas.current.backgroundWidth) {
			x += size;
			lines.push(
				new fabric.Line([x, 0, x, canvas.current.backgroundHeight], lineOption)
			);
		}
		while (y < canvas.current.backgroundHeight) {
			y += size;
			lines.push(
				new fabric.Line([0, y, canvas.current.backgroundWidth, y], lineOption)
			);
		}

		// add to group and add to canvas
		const group: any = new fabric.Group(lines);
		group.id = 'grid';
		canvas.current.add(group);
		group.sendToBack();
		group.bringForward();
		group.selectable = false;
	}, [
		settings.snapping,
		settings.snappingVisible,
		canvas.current?.backgroundWidth,
		canvas.current?.backgroundHeight,
	]);

	const onRotate = ({ target }: any) => {
		// snap to 15 degrees while shift is down
		if (shiftDown.current && !target.snapAngle) {
			target.snapAngle = 15;
		} else if (!shiftDown.current && target.snapAngle) {
			target.snapAngle = 0;
		}

		// rotate texts
		rotateTexts(target);
	};

	const onMove = ({ target }: any) => {
		// cancel selection on single object while moving
		if (canvas.current.getActiveObjects().length === 1)
			canvas.current.discardActiveObject?.();

		if (settings.snapping) {
			const size = settings.snapping;

			// center object to grid intersections
			const x = ((canvas.current.backgroundWidth / 2) % size) - size;
			const y = ((canvas.current.backgroundHeight / 2) % size) - size;
			const centerX = (target.getScaledWidth() / 2) % size;
			const centerY = (target.getScaledHeight() / 2) % size;

			target.set({
				left: Math.round(target.left / size) * size + x + size - centerX,
				top: Math.round(target.top / size) * size + y + size - centerY,
			});
		}
	};

	// new object
	const onObjectAdded = ({ target }: any) => {
		const [tool] = triggeredTool?.split('_') || [];
		if (tool === 'freehand') {
			// freehand lines will be grouped when done
			setFreehandPaths([...freehandPaths, target]);
			return;
		}

		// set random id on objects
		if (!target.id) target.id = uuid();

		// store tool used to create object
		if (triggeredTool && !target.tool) target.tool = triggeredTool;

		// set background size
		if (target.id === 'background') {
			setCanvasToBackgroundSize(canvas);
		}
	};

	const onObjectUpdate = ({ target }: any) => {
		// create slide
		const newSlides: any = structuredClone(slides);
		if (!newSlides[slide]) newSlides[slide] = {};

		// store props for each object
		const offsetLeft = !target.id ? target.left + target.width / 2 : 0;
		const offsetTop = !target.id ? target.top + target.height / 2 : 0;

		// if there is no id, it is a group selection
		const objects = target.id ? [target] : target._objects;
		objects?.forEach((obj: any) => {
			if (!obj.id) {
				tlog.message('missing', obj);
			} else if (obj.id !== 'background' && obj.id !== 'grid') {
				const values = {
					left: obj.left + offsetLeft,
					top: obj.top + offsetTop,
					scaleX: obj.scaleX,
					scaleY: obj.scaleY,
					angle: obj.angle,
				};

				if (!newSlides[0][obj.id] && !obj.tempMulti) {
					// create on each slide and set z and visibilty values
					newSlides.forEach((s: any, i: number) => {
						s[obj.id] = { ...values };
						s[obj.id].z = Object.keys(s).length;
						s[obj.id].visible = i >= slide;
					});
				} else {
					// set updated values on current slide
					newSlides[slide][obj.id] = {
						...newSlides[slide][obj.id],
						...values,
					};
				}
			}
		});

		setSlides(newSlides);
	};

	const [copiedObject, setCopiedObject] = useState(null);
	const [spacebarDown, setSpacebarDown] = useState(null);
	const shiftDown = useRef(false);
	const onKeyDown = (e: KeyboardEvent) => {
		// ignore text input
		const target = e.target as HTMLElement;
		if (target.tagName === 'INPUT' && !e.metaKey) {
			if (e.code === 'Enter') e.preventDefault();

			return;
		}

		const obj = canvas.current?.getActiveObject?.();

		// ignore key commands while editing text objects or in static mode
		if (obj?.isEditing || !canvas.current?.getActiveObject) return;

		// global actions
		if (e.code === 'KeyV' && e.metaKey && copiedObject) {
			// paste
			const clones: any = [];
			copiedObject.forEach((target: any) => {
				target.clone((cloned: any) => {
					cloned.tool = target.tool;
					cloned.left += 30;
					cloned.top += 15;
					canvas.current.add(cloned);
					canvas.current.setActiveObject(cloned);
					clones.push(cloned);
				});
			});

			setCopiedObject(clones);
		} else if (e.code === 'Space' && !spacebarDown) {
			if (zoom > 1 + DEFAULT_ZOOM_OFFSET) setSpacebarDown('pannable');
			mouseDown({ e });
		} else if (e.key === 'Shift' && !shiftDown.current) {
			shiftDown.current = true;
			if (multipoint) drawMulti();
		} else if (e.code === 'Escape' || e.code === 'Enter') {
			setTriggeredTool(null);
		} else if (e.code === 'ArrowLeft' || e.code === 'ArrowUp') {
			if (slide) setSlide(slide - 1);
		} else if (e.code === 'ArrowRight' || e.code === 'ArrowDown') {
			if (slide + 1 < slides.length) setSlide(slide + 1);
		} else if (!e.metaKey) {
			// drawing tool keyboard shortcuts
			const key = e.key.toUpperCase();
			const tool = Object.entries(toolConfig.shapes).find(
				([, config]: any) => config.key === key
			);
			if (tool) setTriggeredTool(tool[0]);
		}

		// active object actions
		if (!obj) return;

		if (e.key == 'Delete' || e.code == 'Delete' || e.key == 'Backspace') {
			canvas.current.remove(obj);
			canvas.current.getActiveObjects().forEach((target: any) => {
				canvas.current.remove(target);
			});
			canvas.current.discardActiveObject();
		} else if (e.code === 'KeyC' && e.metaKey) {
			// copy active objects
			const copy: any = [];
			canvas.current.getActiveObjects().forEach((target: any) => {
				copy.push(target);
			});
			setCopiedObject(copy);
		} else if (e.code === 'KeyD' && e.metaKey) {
			e.preventDefault();

			// duplicate active objects
			canvas.current.getActiveObjects().forEach((target: any) => {
				target.clone((cloned: any) => {
					cloned.tool = target.tool;
					cloned.left += 30;
					cloned.top += 30;
					canvas.current.add(cloned);
					canvas.current.setActiveObject(cloned);
				});
			});
		} else if (e.code === 'Escape') {
			canvas.current.discardActiveObject();
			canvas.current.renderAll();
		}
	};

	const onKeyUp = (e: KeyboardEvent) => {
		const obj = canvas.current?.getActiveObject?.();

		// ignore key commands while editing text objects
		if (obj?.isEditing) return;

		if (e.code === 'Space' && spacebarDown) {
			setSpacebarDown(null);

			mouseUp({ e });
		} else if (e.key === 'Shift' && shiftDown.current) {
			shiftDown.current = false;
			if (multipoint) drawMulti();
		}
	};

	const [zoom, setZoom] = useState(1);
	// const [zoomPoint, setZoomPoint] = useState([0, 0]);
	useEffect(() => {
		if (!canvas.current) return;

		// zoom to mouse pointer or center of canvas (subtract 5% for artboard padding)
		if (zoom > 1 + DEFAULT_ZOOM_OFFSET) {
			// canvas.current.zoomToPoint(
			// 	{
			// 		x: zoomPoint[0] || canvas.current.height / 2,
			// 		y: zoomPoint[1] || canvas.current.width / 2,
			// 	},
			// 	zoom - 0.08
			// );
			canvas.current.setZoom(zoom - DEFAULT_ZOOM_OFFSET);
		}

		// center on 100%
		if (zoom <= 1) {
			const vpt = canvas.current.viewportTransform;
			vpt[4] = 0;
			vpt[5] = 0;
			canvas.current.setZoom(1);
			canvas.current.setWidth(canvas.current.backgroundWidth || 1000);
			canvas.current.setHeight(canvas.current.backgroundHeight || 1000);
			canvas.current.requestRenderAll();
		}
	}, [canvas.current, zoom]);

	const startPan = () => {
		setSpacebarDown('panning');
		canvas.current.isPanning = true;
		canvas.current.selection = false;
	};

	const whilePan = (e: any) => {
		const vpt = canvas.current.viewportTransform;
		vpt[4] += e.clientX - canvas.current.lastPosX;
		vpt[5] += e.clientY - canvas.current.lastPosY;

		// limit top and left pan
		if (vpt[4] > 0) vpt[4] = 0;
		if (vpt[5] > 0) vpt[5] = 0;

		// limit right and bottom pan
		const zoomFactor =
			zoom > 1 + DEFAULT_ZOOM_OFFSET ? zoom - DEFAULT_ZOOM_OFFSET : 1;
		const xLimit =
			canvas.current.width - zoomFactor * canvas.current.backgroundWidth;
		if (vpt[4] < xLimit) vpt[4] = xLimit;
		const yLimit =
			canvas.current.height - zoomFactor * canvas.current.backgroundHeight;
		if (vpt[5] < yLimit) vpt[5] = yLimit;

		canvas.current.requestRenderAll();
	};
	const stopPan = () => {
		setSpacebarDown(null);
		canvas.current.setViewportTransform(canvas.current.viewportTransform);
		canvas.current.isPanning = false;
		canvas.current.selection = true;
	};

	const [triggeredTool, setTriggeredTool] = useState(null);
	const [freehandPaths, setFreehandPaths] = useState([]);

	useEffect(() => {
		if (!canvas.current) return;

		// make canvas and existing objects active/passive
		canvas.current.selection = !triggeredTool;
		const objects = canvas.current.getObjects();
		objects
			.filter((obj: any) => obj.id !== 'background' && obj.id !== 'grid')
			.forEach((obj: any) => {
				obj.selectable = !triggeredTool;
			});

		if (triggeredTool) {
			setSelectedObject(null);
			canvas.current?.discardActiveObject();
		}
		canvas.current.renderAll();

		const isDrawing = triggeredTool === 'freehand';
		canvas.current.isDrawingMode = isDrawing;
		if (isDrawing) {
			const brush = new fabric.PencilBrush(canvas.current);
			brush.color = color;
			brush.width = 3;
			canvas.current.freeDrawingBrush = brush;
		}

		if (triggeredTool !== 'freehand' && freehandPaths.length) {
			const group: any = new fabric.Group(freehandPaths);
			group.tool = 'freehand';
			group.id = uuid();
			freehandPaths.forEach((o) => {
				canvas.current.remove(o);
			});
			canvas.current.add(group);
			onObjectUpdate({ target: group });
			setFreehandPaths([]);
		}

		// reset
		if (!triggeredTool) {
			setMultipoint(null);
			setTempMulti(null);
		}
	}, [triggeredTool, color]);

	const getPosition = (e: MouseEvent) => {
		const pointer = canvas.current.getPointer(e);

		return [pointer.x, pointer.y];
	};

	const newObject = useRef(null);
	const [multipoint, setMultipoint] = useState(null);

	const startObject = async (e: any) => {
		if (!triggeredTool || triggeredTool === 'freehand') return;

		canvas.current.selection = false;

		let obj;
		const [tool, variant] = triggeredTool?.split('_') || [];
		const { create, multipoint: multipointTool } = getConfig({
			tool: triggeredTool,
		});
		let [left, top] = getPosition(e);

		if (multipoint && shiftDown.current) {
			// draw straight line while shift is down
			const last = multipoint.at(-1);
			if (Math.abs(last.x - left) > Math.abs(last.y - top)) {
				top = last.y;
			} else {
				left = last.x;
			}
		}

		if (create && !multipointTool) {
			obj = await create({
				tool,
				variant,
				color,
				left,
				top,
				text: t('Text, select to edit…', {
					_context: 'training_library/drawing',
				}),
			});
		} else if (multipointTool) {
			const newMultipoint = multipoint || [];

			const closeThreshold = 5;
			const sameAsExistingPoint = newMultipoint.find(
				(p: any) =>
					Math.abs(left - p.x) < closeThreshold &&
					Math.abs(top - p.y) < closeThreshold
			);

			if (!sameAsExistingPoint || (sameAsExistingPoint && tool === 'line')) {
				// add point
				newMultipoint.push({ x: left, y: top });
			} else if (tool === 'polygon') {
				// clone first point to close path
				newMultipoint.push({ ...newMultipoint[0] });
			}
			setMultipoint(newMultipoint);

			if (
				sameAsExistingPoint ||
				(tool !== 'polygon' &&
					!(
						tool === 'line' &&
						variant !== 'squiggly' &&
						e.type === 'mousedown'
					) &&
					newMultipoint.length === 2)
			) {
				setTempMulti(null);
				stopMulti();
				canvas.current.discardActiveObject();
			} else {
				await drawMulti();
			}
		}

		if (obj) {
			newObject.current = obj;
			canvas.current.add(obj);

			// did the mouse already get released? run stopObject instantly
			if (!isMouseDown.current) stopObject();
		}
	};

	const drawMulti = async () => {
		if (!multipoint) return;

		// get temporary object while adding points
		const obj = canvas.current.getObjects().find((o: any) => o.tempMulti);

		// set new id if there is no existing object
		const id = obj?.id || uuid();

		// delete existing and draw new
		if (obj) canvas.current.remove(obj);

		// merge in current mouse position while drawing
		const points = [...multipoint];
		if (tempMulti) {
			let { x, y } = tempMulti;
			if (shiftDown.current) {
				// create straight line when finishing line while shift is down
				const last = points.at(-1);
				if (Math.abs(last.x - x) > Math.abs(last.y - y)) {
					y = last.y;
				} else {
					x = last.x;
				}
			}
			points.push({ x, y });
		}

		// draw to canvas
		const [tool, variant] = triggeredTool?.split('_') || [];
		const { create } = getConfig({
			tool: triggeredTool,
		});

		let group: any;
		if (create) {
			group = await create({
				tool,
				variant,
				color,
				points,
			});
		}
		if (!group) return;

		group.tempMulti = !!tempMulti;
		group.id = id;
		group.tool = triggeredTool;
		canvas.current.add(group);
		canvas.current.renderAll();
	};

	const [tempMulti, setTempMulti] = useState(null);
	const whileMulti = ({ e }: any) => {
		const [x, y] = getPosition(e);
		setTempMulti({ x, y });
		drawMulti();
	};

	const stopMulti = () => {
		const obj = canvas.current.getObjects().find((o: any) => o.tempMulti);
		if (obj) {
			if (triggeredTool === 'polygon') {
				// update polygon to close path
				obj.item(0).set('points', multipoint);
			}
			obj.selectable = false;
			obj.tempMulti = false;

			// create multipoint on each slide and set z and visibilty values
			const newSlides: any = structuredClone(slides);
			newSlides.forEach((s: any, i: number) => {
				s[obj.id] = { ...slides[slide][obj.id] };
				s[obj.id].z = Object.keys(s).length;
				s[obj.id].visible = i >= slide;
			});
			setSlides(newSlides);
		}
		setMultipoint(null);
	};

	useEffect(() => {
		if (!multipoint?.length) return;
		canvas.current.on('mouse:move', whileMulti);

		return () => {
			if (!multipoint) setTempMulti(null);
			canvas.current.off('mouse:move', whileMulti);
		};
	}, [tempMulti, multipoint]);

	const whileObject = (e: any) => {
		if (!triggeredTool || triggeredTool === 'freehand') return;

		const [tool] = triggeredTool?.split('_') || [];
		const obj = newObject.current;
		if (obj) {
			const [left, top] = getPosition(e);
			const x = left - canvas.current.startPosX;
			const y = top - canvas.current.startPosY;

			if (tool === 'circle') {
				const rx = Math.abs(x) / 2;
				const ry = shiftDown.current ? rx : Math.abs(y) / 2;
				obj.set('rx', rx);
				obj.set('ry', ry);
				if (x < 0) obj.set('left', left);
				if (y < 0) obj.set('top', top);
			} else if (tool === 'rectangle') {
				const width = Math.abs(x);
				const height = shiftDown.current ? width : Math.abs(y);
				obj.set('width', width);
				obj.set('height', height);
				if (x < 0) obj.set('left', left);
				if (y < 0) obj.set('top', top);
			} else {
				obj.set('left', left - obj.getScaledWidth() / 2);
				obj.set('top', top - obj.getScaledHeight() / 2);
			}
			canvas.current.renderAll();
		}
	};

	const stopObject = () => {
		if (!triggeredTool || triggeredTool === 'freehand' || !newObject.current)
			return;
		const { autoDone } = getConfig({
			tool: triggeredTool,
		});

		const [tool] = triggeredTool?.split('_') || [];

		if (newObject.current) {
			const o = newObject.current;
			if (tool === 'circle') {
				const size = 105;
				if (o.rx < 3) {
					o.set('rx', size);
					o.set('left', o.left - size);
				}
				if (o.ry < 3) {
					o.set('ry', size);
					o.set('top', o.top - size);
				}
			} else if (tool === 'rectangle') {
				const size = 175;
				if (o.width < 3) {
					o.set('width', size);
					o.set('left', o.left - size / 2);
				}
				if (o.height < 3) {
					o.set('height', size);
					o.set('top', o.top - size / 2);
				}
			}

			o.setCoords();
			o.selectable = false;
			canvas.current.renderAll();
		}

		if (autoDone) {
			setTriggeredTool(null);
			canvas.current.setActiveObject(newObject.current);
		}
		newObject.current = null;
	};

	const mouseDown = ({ e }: any) => {
		isMouseDown.current = true;

		e.stopPropagation();
		e.preventDefault();
		canvas.current.lastPosX = e.clientX;
		canvas.current.lastPosY = e.clientY;

		const [x, y] = getPosition(e);

		canvas.current.startPosX = x;
		canvas.current.startPosY = y;

		if (!triggeredTool && (e?.altKey || e?.shiftKey || spacebarDown)) {
			startPan();
		} else if (triggeredTool) {
			startObject(e);
			e.stopPropagation();
		}
	};

	const mouseMove = ({ e }: any) => {
		e.stopPropagation();
		e.preventDefault();
		if (canvas.current.isPanning) {
			whilePan(e);
		} else if (triggeredTool) {
			whileObject(e);
		}

		canvas.current.lastPosX = e.clientX;
		canvas.current.lastPosY = e.clientY;
	};

	const mouseUp = ({ e }: any) => {
		isMouseDown.current = false;
		e.stopPropagation();
		e.preventDefault();

		const [tool] = triggeredTool?.split('_') || [];
		if (canvas.current.isPanning) {
			stopPan();
		} else if (tool !== 'polygon' && multipoint?.length === 1) {
			// finish shape on first mouse up if mouse moved while clicking
			const [left, top] = getPosition(e);

			const distance =
				Math.abs(multipoint[0].x - left) + Math.abs(multipoint[0].y - top);

			if (distance > 5) {
				startObject(e);
			}
		} else if (triggeredTool) {
			stopObject();
		}

		canvas.current.renderAll();
	};

	const rotateTexts = (target: any) => {
		const texts = target.getObjects?.().filter((o: any) => o.type === 'text');
		texts?.forEach((text: any) => {
			text.set('angle', -target.angle);
		});
	};

	const [selectedObject, setSelectedObject] = useState(null);
	const onSelect = () => {
		setSelectedObject(canvas.current.getActiveObject());
	};

	const onClearSelect = () => {
		setSelectedObject(null);
	};

	// add event listeners
	useEffect(() => {
		if (!canvas.current) return;

		// update drawing on canvas change
		canvas.current.on('object:added', onObjectAdded);
		canvas.current.on('object:added', onObjectUpdate);
		canvas.current.on('object:modified', onObjectUpdate);
		canvas.current.on('object:removed', onObjectUpdate);
		canvas.current.on('object:rotating', onRotate);

		// snap to grid?
		canvas.current.on('object:moving', onMove);

		// pan
		canvas.current.on('mouse:down', mouseDown);
		canvas.current.on('mouse:move', mouseMove);
		canvas.current.on('mouse:up', mouseUp);

		// active object
		canvas.current.on('selection:created', onSelect);
		canvas.current.on('selection:updated', onSelect);
		canvas.current.on('selection:cleared', onClearSelect);

		// keyboard shortcuts
		document.addEventListener('keydown', onKeyDown);
		document.addEventListener('keyup', onKeyUp);

		return () => {
			canvas.current.off('object:added', onObjectAdded);
			canvas.current.off('object:added', onObjectUpdate);
			canvas.current.off('object:modified', onObjectUpdate);
			canvas.current.off('object:removed', onObjectUpdate);
			canvas.current.off('object:rotating', onRotate);
			canvas.current.off('object:moving', onMove);
			canvas.current.off('mouse:down', mouseDown);
			canvas.current.off('mouse:move', mouseMove);
			canvas.current.off('mouse:up', mouseUp);
			canvas.current.off('selection:created', onSelect);
			canvas.current.off('selection:updated', onSelect);
			canvas.current.off('selection:cleared', onClearSelect);
			document.removeEventListener('keydown', onKeyDown);
			document.removeEventListener('keyup', onKeyUp);
		};
	}, [
		canvas.current,
		settings,
		slide,
		slides,
		zoom,
		copiedObject,
		spacebarDown,
		shiftDown.current,
		triggeredTool,
		color,
		multipoint,
	]);

	// animate slide objects
	const getMostRecentSlide = (id: any) => {
		let checkSlide = slide;

		// check slides array backwards
		while (checkSlide >= 0) {
			const slideArray: any = slides[checkSlide];
			if (slideArray?.[id]) return slideArray[id];
			else checkSlide--;
		}
	};

	const prevSlide = usePrevious(slide);
	useEffect(() => {
		if (!canvas.current) return;
		canvas.current.renderAll();

		const objects = canvas.current.getObjects();

		objects?.forEach((target: any, index: number) => {
			const slideObject = getMostRecentSlide(target.id);

			if (slideObject) {
				const renderer = canvas.current.renderAll.bind(canvas.current);

				// avoid rotating more than 180 degrees (go other direction)
				if (Math.abs(target.angle - slideObject.angle) > 180) {
					const loop = target.angle > slideObject.angle ? 360 : -360;
					slideObject.angle = slideObject.angle + loop;
				}

				// trigger animation
				const visible = slideObject.visible === false ? false : true;
				const values = structuredClone(slideObject);
				delete values.visible;
				delete values.z;

				target.set('visible', visible);
				const z = slideObject.z !== undefined ? slideObject.z : index;
				target.moveTo(z + (settings.snappingVisible ? 1 : 0)); // +1 to place above background and grid

				if (slide !== prevSlide) {
					target?.animate(values, {
						onChange: () => {
							rotateTexts(target);
							renderer();
						},
						duration: duration / 2, // half of slide playback duration
					});
				}
			}
		});
	}, [slide, slides, duration]);

	return (
		<FabricContext.Provider
			value={{
				drawing,
				setDrawing,
				canvas,
				initCanvas,
				settings,
				setSetting,
				config,
				setBackground,
				slides,
				slide,
				setSlide,
				setSlides,
				duration,
				setDuration,
				freehand,
				setFreehand,
				color,
				setColor,
				updateDrawing,
				zoom,
				setZoom,
				selectedObject,
				spacebarDown,
				triggeredTool,
				setTriggeredTool,
			}}>
			{children}
		</FabricContext.Provider>
	);
};
