import { ReactNode } from 'react';

export const symbol_saved_search: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m376-533-54 40q-5 3-9 0t-2-8l21-64-55-43q-5-3-3-8t7-5h65l23-66q2-5 7-5t7 5l23 66h64q5 0 7 5t-3 8l-55 43 21 64q2 5-2 8t-9 0l-53-40Zm2 204q-108 0-183-75t-75-181q0-106 75-181t182-75q106 0 180.5 75T632-585q0 43-14 83t-42 75l242 240q9 9 9 22t-9 22q-9 9-22 9t-22-9L533-384q-30 26-70 40.5T378-329Zm-1-60q81 0 138-57.5T572-585q0-81-57-138.5T377-781q-82 0-139.5 57.5T180-585q0 81 57.5 138.5T377-389Z"
		/>
	</svg>
);
