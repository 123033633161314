import { ReactNode } from 'react';

export const symbol_table_restaurant: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M146-580h667l-45-160H193l-47 160Zm334-80Zm212 140H269l-14 100h450l-13-100ZM165-194l44-326h-89q-20 0-31.5-16T82-571l57-200q4-13 14-21t24-8h606q14 0 24 8t14 21l57 200q5 19-6.5 35T840-520h-88l44 326q2 14-7 24t-23 10q-11 0-19-7.5T737-186l-24-174H247l-24 174q-2 11-10 18.5t-19 7.5q-14 0-22.5-10t-6.5-24Z"
		/>
	</svg>
);
