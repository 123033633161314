import { ReactNode } from 'react';

export const symbol_key_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M792-83 497-377h-6q-31 73-88 105t-123 32q-100 0-170-70T40-480q0-66 35-125t102-91l-95-96q-9-9-9-21.5t9-21.5q9-9 22-9t22 9l709 710q9 9 9 21t-9 21q-9 9-21.5 9T792-83Zm155-387q0 6-2.5 11t-7.5 10L812-334q-5 4-9.5 6t-9.5 2q-5 0-9.5-1t-9.5-4l-69-50-23 17-43-43 67-48 83 61 84-78-51-51H522l-60-60h373q6 0 11 2t10 7l82 82q5 5 7 10.5t2 11.5ZM280-300q51 0 99.5-33.5T447-427l-59.5-59.5L335-539l-52.5-52.5L223-651q-64 22-93.5 71T100-480q0 75 52.5 127.5T280-300Zm0-112q-28 0-48-20t-20-48q0-28 20-48t48-20q29 0 48.5 20t19.5 48q0 28-20 48t-48 20Z"
		/>
	</svg>
);
