import { List, Record } from 'immutable';
import { schema } from 'normalizr';

import { prices } from 'pkg/models/schemas';

export const hasDuplicatePriceCycles = (prices: List<Price>) => {
	const selectedPriceIntervals = prices.map((item) => {
		return `${item.recurringInterval}${item.recurringIntervalCount}`;
	});
	const uniquePriceIntervals = new Set(selectedPriceIntervals);

	return selectedPriceIntervals.size > uniquePriceIntervals.size ? true : false;
};

export interface IPrice {
	id: number;
	productId: number;
	paymentProviderId: string;
	taxRateId?: number;
	title: string;
	description: string;
	currency: string;
	cost: number;
	recurring: boolean;
	recurringInterval: string;
	recurringIntervalCount: number;
	createdAt: number;
	updatedAt: number;
	archivedAt: number;
}

const priceProps: IPrice = {
	id: 0,
	productId: 0,
	paymentProviderId: '',
	taxRateId: null,
	title: '',
	description: '',
	currency: '',
	cost: 0,
	recurring: false,
	recurringInterval: '',
	recurringIntervalCount: 0,
	createdAt: 0,
	updatedAt: 0,
	archivedAt: null,
};

class Price extends Record(priceProps, 'PriceRecord') implements IPrice {
	static normalizr(): schema.Entity {
		return prices;
	}
}

export default Price;
