export function AppleSignInSave(): string {
	return `/v1/auth/asi-save`;
}

export function Login(): string {
	return `/v1/auth`;
}

export function Options(slug: string): string {
	return `/v1/auth/options/${slug}`;
}

export function Redirect(): string {
	return `/v1/auth/redirect`;
}

export function SSOLogin(): string {
	return `/v1/auth/sso/login`;
}

export function SSOStart(): string {
	return `/v1/auth/sso/start`;
}

export function SSOValidate(): string {
	return `/v1/auth/sso/validate`;
}
