import { ReactNode } from 'react';

export const symbol_architecture: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M440-506 318-170l-3 5-25 24q-7 7-15 3.5T265-150l-5-32q0 1 1-7l126-347q11 11 24.5 18t28.5 12Zm40-14q-50 0-85-35t-35-85q0-42 24.5-74.5T450-756v-54q0-13 8.5-21.5T480-840q13 0 21.5 8.5T510-810v54q41 9 65.5 41.5T600-640q0 50-35 85t-85 35Zm0-60q25 0 42.5-17.5T540-640q0-25-17.5-42.5T480-700q-25 0-42.5 17.5T420-640q0 25 17.5 42.5T480-580Zm38 75q15-5 29-12t25-18l127 346q2 4 0 7l-4 32q-2 9-10 12.5t-15-3.5l-25-24q-3-3-3-5L518-505Z"
		/>
	</svg>
);
