import { ReactNode } from 'react';

export const symbol_login: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M511-120q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T511-180h269v-600H511q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T511-840h269q24 0 42 18t18 42v600q0 24-18 42t-42 18H511Zm-26-330H150q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-510h333l-81-81q-9-9-8.5-21t9.5-21q9-9 21.5-9t21.5 9l133 133q9 9 9 21t-9 21L447-326q-8.8 9-20.9 8.5-12.1-.5-21.491-9.5Q396-336 396-348.5t9-21.5l80-80Z"
		/>
	</svg>
);
