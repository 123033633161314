import { ReactNode } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import useComponentDidMount from 'pkg/hooks/useComponentDidMount';

const FauxModalWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	min-height: 100%;
	z-index: 1000;
	background-color: var(--palette-white);
`;

interface FauxPageModalProps {
	children: ReactNode;
}

export const FAUX_MODAL_PARENT = 'faux-page-modal-container';
export const FAUX_MODAL_CHILD = 'faux-page-modal-child';

export default function FauxPageModal({ children }: FauxPageModalProps) {
	useComponentDidMount(() => {
		document.querySelector('.' + FAUX_MODAL_PARENT)?.scrollTo(0, 0);
	});

	return createPortal(
		<FauxModalWrapper className={FAUX_MODAL_CHILD}>
			{children}
		</FauxModalWrapper>,
		document.querySelector('.' + FAUX_MODAL_PARENT)
	);
}
