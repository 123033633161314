import { ReactNode } from 'react';

export const symbol_measuring_tape: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M540-160H260q-24.75 0-42.375-17.625T200-220v-280q0-142 99-241t241-99q142 0 241 99t99 241q0 142-99 241t-241 99Zm0-60q116.308 0 198.154-81.783Q820-383.565 820-499.783 820-616 738.217-698q-81.782-82-198-82Q424-780 342-698.154 260-616.308 260-500v280h280Zm0-140q58 0 99-41t41-99q0-58-41-99t-99-41q-58 0-99 41t-41 99q0 58 41 99t99 41Zm.235-60Q507-420 483.5-443.265q-23.5-23.264-23.5-56.5Q460-533 483.265-556.5q23.264-23.5 56.5-23.5Q573-580 596.5-556.735q23.5 23.264 23.5 56.5Q620-467 596.735-443.5q-23.264 23.5-56.5 23.5Zm-430.41 260Q97-160 88.5-168.625T80-190v-140q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-330v140q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM540-500Z"
		/>
	</svg>
);
