import { ReactNode } from 'react';

export const symbol_backspace: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M360-200q-22 0-40-11.5T289-241L144-445q-11-15.972-11-34.986Q133-499 144-515l145-204q13-18 31-29.5t40-11.5h420q24.75 0 42.375 17.625T840-700v440q0 24.75-17.625 42.375T780-200H360Zm420-60v-440 440Zm-431 0h431v-440H349L195-480l154 220Zm211-178 91 91q9 9 21 9t21-9q9-9 9-21.5t-9-21.5l-91-90 90-90q9-9 9-21t-9-21q-9-9-21.5-9t-21.5 9l-89 90-91-91q-9-9-21-9t-21 9q-9 9-9 21.5t9 21.5l91 90-91 90q-9 9-9 21t9 21q9 9 21.5 9t21.5-9l90-90Z"
		/>
	</svg>
);
