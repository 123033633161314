import { useEffect, useRef } from 'react';

import { useEffectOnce } from 'pkg/hooks/component-lifecycle';

type Effect = () => void;

type AsyncEffect = () => Promise<void>;

export default function useComponentDidMount(
	onMount: Effect | AsyncEffect,
	onUnmount?: Effect
): void {
	useEffectOnce(() => {
		onMount();

		if (onUnmount) {
			return onUnmount;
		}
	});
}

export function useComponentWillUnmount(onUnmount?: Effect): void {
	useEffectOnce(() => {
		return onUnmount();
	});
}

export function useComponentWillMount(onWillMount: Effect | AsyncEffect): void {
	const mounted = useRef<boolean>(false);

	if (!mounted.current) {
		onWillMount();
	}

	useEffect(() => {
		mounted.current = true;
	}, []);
}
