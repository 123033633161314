import { ReactNode } from 'react';

export const symbol_water_bottle: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M623-530H377q-5 14-13.458 25.5Q355.083-493 343-485l32 345h250l32-345q-12-8-20.5-19.5T623-530ZM366-700l-17 69q9 8 16 18.5t11 22.5h248.576q4.647-11.429 11.035-21.714Q642-622 651-630l-17-70H366Zm9 620q-23.351 0-40.675-16Q317-112 315-135l-33-365q-1-8.889 3.5-16.444Q290-524 298-529l1-1q8-5 14-12t6-17q0-9-4-17t-12-12l-2-1q-9-5-13-14.5t-2-19.5l28-114q2-10 10.25-16.5T343-760h127v-60h-30q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T440-880h120q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T560-820h-30v60h127q10.8 0 18.9 6.5Q684-747 686-737l27 112q2 10-2 19.5T698-591l-2 1q-8 4-12 12t-4 16.743q0 10.686 5.5 17.971Q691-536 699.679-531.37q8.678 4.629 13.5 12.5Q718-511 717-501l-32 366q-2.25 23.356-19.5 39.178Q648.25-80 625-80H375Zm125-450Zm0-60Z"
		/>
	</svg>
);
