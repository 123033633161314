import { ReactNode } from 'react';

export const symbol_queue_music: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M642.941-160Q595-160 561.5-193.559t-33.5-81.5Q528-323 560.667-356.5 593.333-390 640-390q15.968 0 30.484 3Q685-384 698-377v-308q0-14.875 10.35-24.938Q718.7-720 734-720h111q14.875 0 24.938 10.289Q880-699.421 880-684.211 880-669 869.938-659 859.875-649 845-649h-87v375q0 47.5-33.559 80.75t-81.5 33.25ZM150-320q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-380h246q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T396-320H150Zm0-170q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-550h413q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T563-490H150Zm0-170q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-720h413q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T563-660H150Z"
		/>
	</svg>
);
