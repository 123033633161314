import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';

import useManagePublishedPeriods, {
	StatePublishPeriod,
} from 'routes/scheduling/templates/hooks/useManagePublishedPeriods';
import PublishedPeriodCardListItem from 'routes/scheduling/templates/modals/publish/published-period-card-list';

import SectionTitle from 'components/SectionTitle';
import * as StepModal from 'components/step-modal';

import Column from 'components/layout/column';
import InfoBox from 'components/form/info-box';

interface RemoveBookingModalProps {
	schedule: models.schedule.Schedule;

	hideModal: () => void;
	refreshBookings: () => Promise<void>;
	refreshPublishedPeriods: () => Promise<void>;
}

const RemoveBookingModal = ({
	schedule,
	hideModal,
	refreshBookings,
	refreshPublishedPeriods,
}: RemoveBookingModalProps) => {
	const { periodsToRemove } = useManagePublishedPeriods({
		groupId: schedule.groupId,
		publishedPeriods: schedule.schedulePublishedPeriods,
		scheduleId: schedule.id,
		defaultStatePublishedPeriods: [],
	});

	const handleSave = async () => {
		const confirm = window.confirm(
			t(
				`Are you sure you want to remove {scheduleTitle} and all it's associated bookings/events?`,
				{ scheduleTitle: schedule.title }
			)
		);

		if (confirm) {
			const [ok] = await models.schedule.publish(schedule.id, {
				publishedPeriods: [],
				dryRun: false,
			});

			if (ok) {
				refreshBookings();
				refreshPublishedPeriods();
				return true;
			} else {
				return false;
			}
		}
	};

	return (
		<StepModal.Base onClose={hideModal}>
			<StepModal.Step
				title={t('Remove {scheduleTitle}', { scheduleTitle: schedule?.title })}
				nextLabel={t('Remove')}
				onNext={handleSave}>
				{!!periodsToRemove.length && (
					<Column spacing={styles.spacing._3}>
						<SectionTitle>
							{t('Schedule will be unpublished from')}
						</SectionTitle>
						{periodsToRemove.map((publishedPeriod) => (
							<PublishedPeriodCardListItem
								key={publishedPeriod.id}
								publishedPeriod={
									{
										...publishedPeriod,
										editMode: false,
									} as StatePublishPeriod
								}
								willBeRemoved={true}
							/>
						))}
						<InfoBox color="yellow">
							{periodsToRemove.length > 1
								? t(
										'Warning: Schedule will be removed for these periods and bookings/events removed'
									)
								: t(
										'Warning: Schedule will be removed for this period and bookings and events removed'
									)}
						</InfoBox>
					</Column>
				)}
			</StepModal.Step>
		</StepModal.Base>
	);
};

export default RemoveBookingModal;
