import { t } from '@transifex/native';
import { Fragment } from 'react';

import { PageWidths } from 'pkg/config/sizes';

import { Statuses } from 'pkg/models/order';

import { useCurrentRoute } from 'pkg/router/hooks';
import { useEndpoint } from 'pkg/api/use_endpoint';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { formatToCurrency } from 'pkg/i18n/formatters';
import DateTime from 'pkg/datetime';
import { useCurrentOrganization } from 'pkg/identity';
import { PaymentProvider } from 'pkg/api/models/group';

import OrderSummary from 'routes/payments/orders/single/summary';

import * as Card from 'components/Card';
import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import StatusLabel from 'components/payment_platform/status/label';
import { Inner } from 'components/layout/LargeScreenContent';
import { Spinner } from 'components/loaders/spinner';
import Row from 'components/layout/row';
import Column from 'components/layout/column';
import InfoBox from 'components/form/info-box';
import ClubContactInformation from 'components/group/club-contact-information';

import Button from 'design/button';

import * as css from './styles.css';

const Invoice = () => {
	const { orderId } = useCurrentRoute();
	const organization = useCurrentOrganization();

	const { record: order, isLoading } = useEndpoint<models.order.Order>(
		endpoints.Orders.Show(orderId)
	);

	if (isLoading) {
		return <Spinner />;
	}

	const amountDue = formatToCurrency(order.amountDue / 100, order.currency);
	const dueDate = DateTime.fromTimestamp(order.dueDate);
	const sentAt = DateTime.fromTimestamp(order.sentAt);
	const paidAt = DateTime.fromTimestamp(order.paidAt);
	const fullName = order.firstName + ' ' + order.lastName;

	const dateStringOptions: Intl.DateTimeFormatOptions = {
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
	};

	const users: {
		[key: string]: {
			fullName: string;
			id: number;
		};
	} = {};

	order.userProducts?.forEach((userProduct) => {
		if (!users[userProduct.userId]) {
			users[userProduct.userId] = {
				fullName: models.user.fullName(userProduct.user),
				id: userProduct.userId,
			};
		}
	});

	const userList = Object.values(users).map((user) => {
		return (
			<div key={user.id} className={css.userlist}>
				{user.fullName}
			</div>
		);
	});

	const itemList = models.orderRow.findProductRows(order.rows).map((row) => {
		const itemCost = formatToCurrency(
			(row.amount * row.quantity) / 100,
			order.currency
		);

		return (
			<Column key={row.id} className={css.spacing}>
				<Row className={css.amounts}>
					<div>{row?.description}</div>
					<div>{itemCost}</div>
				</Row>
				{row.type === 'product' && (
					<Row>
						<div className={css.qty}>
							{t('Qty')} {row.quantity}
						</div>
					</Row>
				)}
			</Column>
		);
	});

	let totalRefunds = 0;

	order.refunds?.forEach((r) => (totalRefunds += r.amount));

	const hasBeenPaid = order.status === models.order.Statuses.Paid;
	const hasPaymentUrl = !!order.paymentUrl;
	const isFirstOrder = models.order.isFirstOrderOfSubscription(order);

	let showPayButton = hasPaymentUrl;

	if (
		order?.subscription &&
		!isFirstOrder &&
		organization.paymentProvider === PaymentProvider.Purspot &&
		!hasBeenPaid
	) {
		showPayButton = false;
	}

	const showUpdatePaymentMethodButton =
		order?.subscription &&
		!showPayButton &&
		models.hasLink(order.subscription, 'show:payment_method');

	let showUpdatePaymentMethodInfo = false;

	if (
		order?.subscription &&
		organization.paymentProvider === PaymentProvider.Purspot &&
		(order.status === models.order.Statuses.PastDue ||
			order.status === models.order.Statuses.Uncollectible) &&
		models.hasLink(order.subscription, 'show:payment_method')
	) {
		showUpdatePaymentMethodInfo = true;
	}

	return (
		<Inner topSpacing={16} maxWidth={PageWidths.STANDARD}>
			<Column>
				<Card.Base $noBorder>
					<Card.Body $noSpacing>
						<div className={css.cardpadding}>
							<Column>
								<LargeScreen>
									<Row className={css.header}>
										<div className={css.due}>{amountDue}</div>
										<StatusLabel status={models.order.getStatus(order)} />
										{showUpdatePaymentMethodButton && (
											<Button
												primary
												large
												href={
													order?.subscription?.links?.['show:payment_method']
												}
												label={t('Update payment method')}
											/>
										)}
										{showPayButton && (
											<Button primary large href={order.paymentUrl}>
												{hasBeenPaid ? t('View receipt') : t('Pay now')}
											</Button>
										)}
									</Row>
								</LargeScreen>
								<SmallScreen>
									<Row className={css.header}>
										<div className={css.due}>{amountDue}</div>
										<StatusLabel status={models.order.getStatus(order)} />
									</Row>
									{showPayButton && (
										<Row className={css.pay}>
											<Button primary large href={order.paymentUrl}>
												{t('Pay now')}
											</Button>
										</Row>
									)}
									{showUpdatePaymentMethodButton && (
										<Row className={css.pay}>
											<Button
												primary
												large
												href={
													order?.subscription?.links?.['show:payment_method']
												}
												label={t('Update payment method')}
											/>
										</Row>
									)}
								</SmallScreen>
								{showUpdatePaymentMethodInfo && (
									<InfoBox
										color="red"
										text={t(
											'Last payment failed, we will retry within 24 hours. Please update your payment method.'
										)}
									/>
								)}
								<Row className={css.row}>
									<div className={css.gray}>{t('Due date')}</div>
									<div>
										{order.dueDate
											? dueDate.toLocaleDateString(dateStringOptions)
											: sentAt.toLocaleDateString(dateStringOptions)}
									</div>
								</Row>
								<Card.Divider />
								<Row className={css.row}>
									<div className={css.gray}> {t('Billed to')}</div>
									<Column className={css.spacing}>
										<div>{fullName}</div>
										<div className={css.tax}>{order.email}</div>
									</Column>
								</Row>
								<Card.Divider />
								{order.status === Statuses.Paid && (
									<Fragment>
										<Row className={css.row}>
											<div className={css.gray}> {t('Paid at')}</div>
											<div>{paidAt.toLocaleDateString(dateStringOptions)}</div>
										</Row>
										<Card.Divider />
									</Fragment>
								)}
								{userList.length > 0 && (
									<Fragment>
										<Row className={css.row}>
											<div className={css.gray}> {t('Products for')}</div>
											<div>{userList}</div>
										</Row>
										<Card.Divider />
									</Fragment>
								)}
								<Row className={css.row}>
									<div className={css.gray}>{t('From')}</div>
									<div>{order.group.name}</div>
								</Row>
								<Card.Divider />
								{order.invoiceNumber && (
									<Fragment>
										<Row className={css.row}>
											<div className={css.gray}>{t('Invoice no.')}</div>
											{order.invoiceNumber}
										</Row>
										<Card.Divider />
									</Fragment>
								)}
								<Row className={css.row}>
									<div className={css.memo}>{t('Memo')}</div>
									{order.description.length > 0 ? (
										order.description
									) : (
										<div>{t('No description')}</div>
									)}
								</Row>
							</Column>
						</div>
					</Card.Body>
				</Card.Base>

				<Card.Base $noBorder>
					<Card.Header>
						<Card.Heading>{t('Invoice details')}</Card.Heading>
					</Card.Header>

					<Card.Divider />
					<Card.Body $noSpacing>
						<div className={css.cardpadding}>
							<Column>
								<Column>{itemList}</Column>
								<OrderSummary order={order} />
							</Column>
						</div>
					</Card.Body>
				</Card.Base>
				<ClubContactInformation
					skipCard
					align="center"
					heading={t('Questions about billing?')}
				/>
			</Column>
		</Inner>
	);
};

export default Invoice;
