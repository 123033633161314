import { ReactNode } from 'react';

export const symbol_roller_skating: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M160-390v-470q0-24.75 17.625-42.375T220-920h240q24.75 0 42.375 17.625T520-860v120q0 30.423 13.5 55.211Q547-660 576-652l115 29q48 12 78.5 52.054T800-480v90q0 24.75-17.625 42.375T740-330H220q-24.75 0-42.375-17.625T160-390Zm60 0h520v-90.467Q740-511 721.5-534t-47.08-31L559-595q-38-11-57.5-34.5T471-685h-95q-6 0-10.5-4.5T361-700q0-6 4.5-10.5T376-715h86q-2-13-2-24.5V-765h-84q-6 0-10.5-4.5T361-780q0-6 4.5-10.5T376-795h84v-65H220v470Zm0 0ZM190-40q-45 0-77.5-32.5t-32.5-78q0-45.5 32.5-77.5t78-32q45.5 0 77.5 32.083 32 32.084 32 77.917 0 45-32.083 77.5Q235.833-40 190-40Zm.5-60q20.5 0 35-15t14.5-35.5q0-20.5-14.375-35T190-200q-20 0-35 14.375T140-150q0 20 15 35t35.5 15ZM770-40q-45 0-77.5-32.5t-32.5-78q0-45.5 32.5-77.5t78-32q45.5 0 77.5 32.083 32 32.084 32 77.917 0 45-32.083 77.5Q815.833-40 770-40Zm.5-60q20.5 0 35-15t14.5-35.5q0-20.5-14.375-35T770-200q-20 0-35 14.375T720-150q0 20 15 35t35.5 15ZM480-40q-45 0-77.5-32.5t-32.5-78q0-45.5 32.5-77.5t78-32q45.5 0 77.5 32.083 32 32.084 32 77.917 0 45-32.083 77.5Q525.833-40 480-40Zm.5-60q20.5 0 35-15t14.5-35.5q0-20.5-14.375-35T480-200q-20 0-35 14.375T430-150q0 20 15 35t35.5 15ZM190-150Zm290 0Zm290 0Z"
		/>
	</svg>
);
