import styled from 'styled-components';

import * as breakpoints from 'pkg/config/breakpoints';
import * as palette from 'pkg/config/palette';

import CardWrapper, * as Card from 'routes/dashboard/components/Card';

import Placeholder from 'components/Placeholder';

import { ParagraphRow } from 'components/loaders/skeleton/placeholders/ParagraphRow';

const Wrapper = styled.div`
	display: block;
	flex: 0 0 90%;
	width: 90%;
	margin-right: 1rem;
	scroll-snap-align: center;
	min-width: 0;

	@media (hover: hover) {
		scroll-snap-align: none;
	}

	@media ${breakpoints.large}, ${breakpoints.nolimit} {
		flex: 0 0 320px;
		width: 320px;
	}

	&:last-child {
		margin-right: 0;
	}

	${CardWrapper} {
		flex: 0 0 100%;
		width: 100%;
	}

	${Card.Header} {
		justify-content: center;
	}

	${Card.Body} {
		display: grid;
		grid-template-columns: 30% 40% 30%;
		padding: 1.5rem 1rem 1rem;

		> div {
			display: grid;
			grid-template-rows: 44px auto;

			> div:first-child {
				display: grid;
				grid-template-columns: 1fr 1fr 1fr;
				align-items: center;
				justify-items: center;
			}

			> div:nth-child(2) {
				display: grid;
				grid-template-columns: 1fr 1fr 1fr;
				align-items: center;
				justify-items: center;
				place-self: center;
				width: 80%;
			}
		}
	}
`;

const ResultTeam = styled.div`
	text-align: center;
`;

const ResultLogo = styled.div`
	margin: 0 auto;
	width: 44px;
	height: 44px;
	border-radius: 50%;
	background-color: ${palette.gray[300]};
	margin-bottom: 0.8rem;
`;

export default class DashboardPlaceholder extends Placeholder {
	getPlaceholderItem(key) {
		return (
			<Wrapper key={key}>
				<CardWrapper>
					<Card.Header>
						<ParagraphRow height={14} percentageWidth={60} inline />
					</Card.Header>
					<Card.Divider />
					<Card.Body>
						<ResultTeam>
							<ResultLogo />
							<ParagraphRow height={10} percentageWidth={45} inline />
						</ResultTeam>
						<div>
							<div>
								<ParagraphRow height={28} width={22} inline />
								<ParagraphRow height={3} width={10} inline />
								<ParagraphRow height={28} width={22} inline />
							</div>

							<div>
								<ParagraphRow height={18} width={12} inline />
								<ParagraphRow height={2} width={6} inline />
								<ParagraphRow height={18} width={12} inline />
							</div>
						</div>
						<ResultTeam>
							<ResultLogo />
							<ParagraphRow height={10} percentageWidth={65} inline />
						</ResultTeam>
					</Card.Body>
				</CardWrapper>
			</Wrapper>
		);
	}
}
