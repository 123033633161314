import { ReactNode } from 'react';

export const symbol_picture_in_picture_medium: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M335-320q-12.75 0-21.375-8.625T305-350v-277q0-12.75 8.625-21.375T335-657h355q12.75 0 21.375 8.625T720-627v277q0 12.75-8.625 21.375T690-320H335ZM110-160q-12.75 0-21.375-8.675Q80-177.351 80-190.175 80-203 88.625-211.5T110-220h710v-550q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T880-770v550q0 24-18 42t-42 18H110Z"
		/>
	</svg>
);
