import styled, { css } from 'styled-components';

import * as models from 'pkg/api/models';

import Icon from 'components/icon';
import Avatar from 'components/avatar';

import * as iconStyles from 'components/icon/styles.css';

const SelectedContainer = styled.div`
	margin: 0;
	padding: 0.5rem 0;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
`;

const SelectedList = styled.ol`
	display: grid;
	grid-gap: 1rem;
	grid-auto-flow: column;
	padding: 1rem;
	justify-content: start;
`;

const SelectedListItem = styled.li<{ isClickable: boolean }>`
	display: grid;
	grid-gap: 0.5rem;
	grid-auto-flow: row;
	grid-auto-rows: 4rem auto;
	position: relative;
	width: 4rem;

	${({ isClickable }) =>
		isClickable &&
		css`
			cursor: pointer;
		`}

	&:last-child {
		width: 5rem;
		border-right: 1rem solid transparent;
	}

	${Avatar} {
		position: relative;
		z-index: 1;
	}

	.${iconStyles.icon} {
		position: absolute;
		right: 0;
		top: 0;
		transition: all 150ms ease-in-out;
		padding: 5px;
		font-size: 1.5rem;
		background: var(--palette-red-400);
		color: var(--palette-white);
		border-radius: 10rem;
		transform: translate3d(0, 0, 0);
		border: 2px solid var(--palette-white);
		z-index: 2;
	}

	@media (hover: hover) {
		&:hover {
			.${iconStyles.icon} {
				background: var(--palette-black);
			}
		}
	}
`;

const SelectedListItemLabel = styled.span`
	text-align: center;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

interface SelectedUsersPreviewProps {
	onChange: (users: models.user.User[]) => void;

	users: models.user.User[];
}

export default function SelectedUsersPreview({
	users = [],
	onChange = null,
}: SelectedUsersPreviewProps) {
	const deselectUser = (user: models.user.User) => {
		if (!onChange) {
			return;
		}

		const newUsers = users.filter((u) => u.id !== user.id);

		onChange(newUsers);
	};

	if (users.length === 0) {
		return null;
	}

	return (
		<SelectedContainer>
			<SelectedList>
				{users.map((user) => (
					<SelectedListItem
						key={`selected-list-user-${user.id}`}
						onClick={() => deselectUser(user)}
						isClickable={!!onChange}>
						<Avatar user={user} />
						<SelectedListItemLabel>{user.firstName}</SelectedListItemLabel>
						{onChange && <Icon name="close" />}
					</SelectedListItem>
				))}
			</SelectedList>
		</SelectedContainer>
	);
}
