import { Record } from 'immutable';
import { schema } from 'normalizr';

import User from 'pkg/models/user';
import Group from 'pkg/models/group';
import { GroupSchema } from 'pkg/models/schemas';

export enum MembershipRole {
	Unknown = 0,
	User = 1,
	Admin = 2,
	Bot = 4,
	Staff = 5,
}

export enum Capabilities {
	Sport = 'sport',
	Chat = 'chat',
	PaymentAdmin = 'payment-admin',
	Forms = 'forms',
	Scheduler = 'scheduler',
}

interface IMembership {
	createdAt: number;
	group?: Group;
	groupId: number;
	id: number;
	role: string | number;
	status: number;
	title: string;
	updatedAt: number;
	user: User;
	userId: number;
	lastRating: any;
	lastSelfRating: any;
	lastPerformanceReviewAt: number;
	isInherited: boolean;
	isLegalGuardian: boolean;
	isOrganizationMembership: boolean;
	targetUser: User;
	targetUserId: number;
	capabilities: Capabilities[];
}

const MembershipProps: IMembership = {
	createdAt: null,
	group: null,
	groupId: null,
	id: null,
	role: null,
	status: null,
	title: '',
	updatedAt: null,
	user: null,
	userId: null,
	lastRating: {},
	lastSelfRating: {},
	lastPerformanceReviewAt: null,
	isInherited: false,
	isLegalGuardian: false,
	isOrganizationMembership: false,
	targetUser: null,
	targetUserId: null,
	capabilities: [],
};

export function membershipId(membership: IMembership): string {
	if (membership.id && !membership.targetUserId) {
		return membership.id.toString();
	}

	const { userId, groupId } = membership;

	return `u${userId}g${groupId}`;
}

export function roleToString(role: string | number): string {
	if (typeof role === 'string') {
		return role;
	}

	switch (role) {
		case MembershipRole.Admin:
			return 'admin';
		case MembershipRole.Staff:
			return 'staff';
		default:
			return 'user';
	}
}

/* @NOTE If you make changes to this schema, you need to mirror those changes to the schema in data/models/user aswell. */
const UserSchema = new schema.Entity('users', undefined, {
	idAttribute: (value) => value.id,
});

export const MembershipSchema = new schema.Entity('memberships', undefined, {
	idAttribute: membershipId,
});

MembershipSchema.define({
	user: UserSchema,
	group: GroupSchema,
});

class Membership
	extends Record(MembershipProps, 'MembershipRecord')
	implements IMembership
{
	isPlayer(): boolean {
		return (
			this.get('role') === MembershipRole.User ||
			this.get('role') === 'user' ||
			this.get('role') === MembershipRole.Bot ||
			this.get('role') === 'bot'
		);
	}

	isAdmin(): boolean {
		return (
			this.get('role') === MembershipRole.Admin || this.get('role') === 'admin'
		);
	}

	isStaff(): boolean {
		return (
			this.get('role') === MembershipRole.Staff || this.get('role') === 'staff'
		);
	}

	isAdminOrStaff(): boolean {
		return (
			this.get('role') === MembershipRole.Admin ||
			this.get('role') === 'admin' ||
			this.get('role') === MembershipRole.Staff ||
			this.get('role') === 'staff'
		);
	}

	isParent(): boolean {
		return !!this.get('targetUserId') || this.isLegalGuardian;
	}

	isActive(): boolean {
		return this.get('status') === 1;
	}

	isPending(): boolean {
		return this.get('status') === 0;
	}

	valid(): boolean {
		return !!this.get('id') && !this.get('targetUserId') && !this.isParent();
	}

	roleString(): string {
		if (this.isParent()) {
			return 'parent';
		}

		return roleToString(this.role);
	}

	static normalizr(): schema.Entity {
		return MembershipSchema;
	}

	hasCapability(flag: Capabilities): boolean {
		return this.capabilities.includes(flag);
	}
}

export default Membership;
