import { Fragment, useState, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as flashActions from 'pkg/actions/flashes';

import * as routes from 'pkg/router/routes';
import * as sdk from 'pkg/core/sdk';
import { replaceState } from 'pkg/router/state';
import { useRouterState } from 'pkg/router/hooks';
import { useEndpoint } from 'pkg/api/use_endpoint';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import {
	useCurrentAccount,
	useCurrentGroup,
	useCurrentMembership,
	useCurrentOrganization,
	useCurrentUser,
} from 'pkg/identity';
import { useOrganizationIdentity } from 'pkg/identity/organization';

import UserSettingsForm from 'routes/user/settings/form';
import { getAlteredValues } from 'routes/payments/hooks/useContactForm';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import Form, { FormPayload } from 'components/form/Form';
import Column from 'components/layout/column';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import * as ActionBar from 'components/layout/ActionBar';
import { Spinner } from 'components/loaders/spinner';

import Button from 'design/button';

import * as css from './style.css';

interface UserSettingsProps {
	userId: number;
}

export default function UserSettings({
	userId,
}: UserSettingsProps): JSX.Element {
	const formRef = useRef(null);

	const org = useCurrentOrganization();
	const { popState, store: routerStore } = useRouterState();
	const activeMembership = useCurrentMembership();
	const currentUser = useCurrentUser();
	const account = useCurrentAccount();
	const group = useCurrentGroup();

	const { wardsAccountRelation } = useOrganizationIdentity();
	const accountRelationIds = models.accountRelation
		.findAllApproved(wardsAccountRelation)
		.map((r) => r.targetAccountId);

	const [avatar, setAvatar] = useState<string>('');
	const [isSaving, setIsSaving] = useState<boolean>(false);

	const { record: user, isLoading } = useEndpoint<models.user.User>(
		endpoints.Users.Show(userId)
	);

	const isSmallScreen = useMediaQuery({
		maxWidth: styles.breakpoint.toMedium,
	});

	const handleSubmit = async (data: FormPayload) => {
		setIsSaving(true);

		const alteredDetails = getAlteredValues(user, data);
		const fields = models.user.getFieldValuesAsPayload(alteredDetails.fields);

		const birthDate = `${data.birthDateYear.toString()}-${data.birthDateMonth.toString()}-${data.birthDateDay.toString()}`;

		const metaFields = [
			models.user.MetaField.Height,
			models.user.MetaField.MotherHeight,
			models.user.MetaField.FatherHeight,
		];

		const meta = metaFields.map((field) => ({
			key: field,
			value: data[field] ? data[field].toString() : null,
		}));

		const accountPayload: { [key: string]: string | number } = {
			firstName: data.firstName as string,
			lastName: data.lastName as string,
			birthDate,
			sex: Number.parseInt(data.sex as string, 10),
			nationality: data.nationality as string,
		};

		// TODO: update user model to use correct field interface
		const currentUserPayload: {
			[key: string]: any;
		} = {
			firstName: data.firstName,
			lastName: data.lastName,
			birthDate,
			sex: Number.parseInt(data.sex as string, 10),
			nationality: data.nationality,
			flags: [data.handicap ? data.handicap : '!has_handicap'],
			email: data.email,
			phoneNumber: data.phoneNumber.toString(),
			address: data.address,
			city: data.city,
			postalCode: data.postalCode.toString(),
			region: data.region,
			country: data.country,
			fields,
			lokSwedishPersonalId: data.lokSwedishPersonalId?.toString() || '',
			meta,
		};

		if (avatar) {
			accountPayload.profileImage = avatar;
			currentUserPayload.profileImage = avatar;
		}

		if (
			user.accountId === account.id ||
			accountRelationIds.includes(user.accountId)
		) {
			sdk.patch(endpoints.Accounts.Update(user.accountId), {}, accountPayload);
		}

		const request = await sdk.patch(
			endpoints.Users.Show(user.id),
			{},
			currentUserPayload
		);

		setIsSaving(false);

		flashMessage(request);
	};

	const handleSave = () => {
		formRef.current.dispatchEvent(
			new Event('submit', {
				cancelable: true,
				bubbles: true,
			})
		);
	};

	const handleCancel = () => {
		// Go back 1 step if possible
		if (routerStore.size > 1) {
			popState();
			return;
		}

		if (
			activeMembership.isOrganizationMembership &&
			currentUser.id === user.id
		) {
			// Editing your own club lobby profile, send back to profile
			replaceState(routes.Profile.Show(org.id, user.id));
		} else if (currentUser.id !== user.id) {
			// Editing child profile, send back to my children view
			replaceState(routes.Account.Settings.Show('children'));
		} else {
			// Editing your own profile, send back to profile
			replaceState(
				routes.User.Profile.Show(org.id, group.id, user.id, 'overview')
			);
		}
	};

	const flashMessage = async (request: any) => {
		const response = await request.json();

		if (!request.ok) {
			if (request.status === 409) {
				if (response.error === 'duplicate lok swedish personal id') {
					flashActions.show(
						{
							title: t('The Personal ID already exists in this organization'),
							message: t(
								'Contact an organization administrator to resolve this, then return to complete this form.'
							),
						},
						request.status
					);
					return false;
				}
			}

			flashActions.show(
				{
					title: t('An error occured'),
				},
				request.status
			);
		} else {
			flashActions.show(
				{
					title: t('Successfully saved!'),
				},
				request.status
			);
		}
	};

	if (isLoading) {
		return <Spinner />;
	}

	return (
		<Fragment>
			<ActionBar.SaveBar maxWidth={styles.size.PageWidths.STANDARD}>
				<Button
					label={t('Cancel')}
					disabled={isSaving}
					onClick={handleCancel}
					block={isSmallScreen}
				/>
				<Button
					primary
					type="submit"
					onClick={handleSave}
					disabled={isSaving}
					label={t('Save')}
					block={isSmallScreen}
				/>
			</ActionBar.SaveBar>
			<LargeScreen>
				<LargeScreenContent.Inner
					maxWidth={styles.size.PageWidths.STANDARD}
					spacious>
					<Column>
						<Form formRef={formRef} onSubmit={handleSubmit}>
							<UserSettingsForm
								group={group}
								user={user}
								avatar={avatar}
								onChangeAvatar={setAvatar}
							/>
						</Form>
						<div className={css.formActions}>
							<Button
								label={t('Cancel')}
								disabled={isSaving}
								onClick={handleCancel}
							/>
							<Button
								primary
								type="submit"
								onClick={handleSave}
								label={t('Save')}
								disabled={isSaving}
								testid="user_settings.save_button"
							/>
						</div>
					</Column>
				</LargeScreenContent.Inner>
			</LargeScreen>
			<SmallScreen>
				<LargeScreenContent.Inner>
					<Column>
						<Form formRef={formRef} onSubmit={handleSubmit}>
							<UserSettingsForm
								group={group}
								user={user}
								avatar={avatar}
								onChangeAvatar={setAvatar}
							/>
						</Form>
					</Column>
				</LargeScreenContent.Inner>
			</SmallScreen>
		</Fragment>
	);
}
