import { ReactNode } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import { breakpoint } from 'pkg/config/styles';

import {
	navigatePushNotification,
	PushNotificationMetaData,
} from 'pkg/actions/notifications';

import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { useHapticFeedback, HapticFeedbackImpact } from 'pkg/haptics';
import { useNewTopIndex } from 'pkg/hooks/useTopIndex';
import { RootState } from 'pkg/reducers';
import { cssVarList } from 'pkg/css/utils';

import Icon, { IconName } from 'components/icon';

import * as css from './styles.css';

export type FlashVariant = 'info' | 'success' | 'error';

interface FlashMeta extends PushNotificationMetaData {
	url?: string;
}

export interface FlashProps {
	title: string;
	message?: string;
	icon?: IconName;
	variant?: FlashVariant;
	additionalData?: FlashMeta;
	setVisibility?: (isVisible: boolean) => void;
	testid?: string;
}

export default function Flash({
	icon = 'info-circle',
	title,
	message,
	variant = 'info',
	additionalData,
	setVisibility,
	testid,
}: FlashProps): JSX.Element {
	const emitHapticFeedback = useHapticFeedback();

	const resourceType = additionalData?.['resource-type'];

	const resourceId = Number.parseInt(
		additionalData?.['resource-id'] || '0',
		10
	);

	const hasInteractionAction = resourceId !== 0 && resourceType;

	const normalizeIcon = (
		variant: FlashVariant,
		additionalData: FlashMeta
	): IconName => {
		switch (variant) {
			case 'success':
				icon = 'task-complete';
				break;
			case 'error':
				icon = 'error';
				break;
		}

		if (additionalData?.hasOwnProperty('resource-type')) {
			switch (additionalData['resource-type']) {
				case 'event':
				case 'booking':
					icon = 'nav-events';
					break;
				case 'chat':
				case 'chatMessage':
					icon = 'add-conversation';
					break;
				case 'userActivity':
					icon = 'thumbs-up';
					break;
				case 'accountInvite':
					icon = 'nav-profile';
					break;
			}
		}

		return icon;
	};

	icon = normalizeIcon(variant, additionalData);

	const handleTap = () => {
		if (!hasInteractionAction && setVisibility) {
			setVisibility(false);
		}
	};

	const handleClick = () => {
		if (setVisibility) {
			setVisibility(false);
		}

		if (hasInteractionAction) {
			navigatePushNotification(additionalData);
		}
	};

	useComponentDidMount(() => {
		if (variant === 'error') {
			emitHapticFeedback(HapticFeedbackImpact.Heavy);
		} else if (variant === 'success') {
			emitHapticFeedback(HapticFeedbackImpact.Medium);
		}
	});

	return (
		<div
			data-testid={testid}
			className={css.wrapper}
			onTouchStart={handleTap}
			onClick={handleClick}>
			<div className={css.icon} data-variant={variant}>
				<Icon name={icon} size={1.25} />
			</div>
			<div
				className={css.body}
				data-variant={variant}
				data-has-message={!!message}>
				<strong>{title}</strong>
				{message && <span className={css.message}>{message}</span>}
			</div>
			<div className={css.close}>
				<Icon name="close" />
			</div>
		</div>
	);
}

interface AnimateOption {
	[key: string]: string | number;
}

interface FlashAnimationWrapperProps {
	children: ReactNode | ReactNode[];
	isVisible: boolean;
}

export function FlashAnimationWrapper({
	children,
	isVisible,
}: FlashAnimationWrapperProps): JSX.Element {
	const zIndex = useNewTopIndex();
	const isSmallScreen = useMediaQuery({ maxWidth: breakpoint.toMedium });

	const navBarHeight = useSelector(
		(state: RootState) => state.app.navBarHeight
	);

	const animateFrom: AnimateOption = {
		opacity: 0,
		x: 100,
	};

	const animateTo: AnimateOption = {
		opacity: 1,
		x: 0,
	};

	if (isSmallScreen) {
		delete animateFrom.x;
		delete animateTo.x;

		animateFrom.y = -100;
		animateTo.y = 0;
	}

	return (
		<div
			className={css.position}
			style={cssVarList({
				'z-index': zIndex,
				'nav-bar-height': `${navBarHeight || 0}px`,
			})}>
			<AnimatePresence mode="wait">
				{isVisible && (
					<motion.div
						initial={animateFrom}
						animate={animateTo}
						exit={animateFrom}>
						{children}
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
}
