import { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import useConfirm from 'pkg/hooks/useConfirm';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import Icon from 'components/icon';

import { duplicationConfirmString } from 'components/training/exercise/utils';

import * as ContextMenu from 'design/context_menu';
import Button from 'design/button';

const ContextTrigger = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	padding: 10px 1rem 1rem;
	color: var(--palette-gray-500);
`;

const InnerContextTrigger = styled(Button)`
	position: absolute;
	top: 10px;
	right: 10px;
`;

const Wrapper = styled.div`
	display: grid;
	grid-gap: 20px;
	grid-template-columns: 1fr 50px;
	align-items: start;
	width: 100%;
	position: relative;

	@media ${styles.breakpoint.small} {
		grid-template-columns: 100%;
	}
`;

const Container = styled.div`
	width: 100%;
`;

const Actions = styled.div`
	display: grid;
	min-width: 50px;
	grid-auto-flow: row;
	grid-gap: 10px;
	position: sticky;
	top: 1rem;
`;

const DirectionIcon = styled(Icon)`
	width: 1.6rem;
	height: 1.6rem;

	${({ up }) =>
		up &&
		css`
			transform: scale(1.2) rotate(-90deg);
		`}

	${({ down }) =>
		down &&
		css`
			transform: scale(1.2) rotate(90deg);
		`}
`;

DirectionIcon.defaultProps = {
	name: 'chevron',
};

const RemoveIcon = styled(Icon)`
	width: 1.2rem;
	height: 1.2rem;
	transform: translateY(1px);
`;

RemoveIcon.defaultProps = {
	name: 'close',
};

const ActionButton = styled(Button)`
	border: none;
`;

const NOOP = () => {};

class MovableBlock extends Component {
	static propTypes = {
		inner: PropTypes.bool,
		canAscend: PropTypes.bool,
		canDescend: PropTypes.bool,

		onRemove: PropTypes.func,
		onAscend: PropTypes.func,
		onDescend: PropTypes.func,
		exerciseActions: PropTypes.element,
	};

	static defaultProps = {
		inner: false,
		canAscend: true,
		canDescend: true,

		onRemove: null,
		onAscend: NOOP,
		onDescend: NOOP,
		exerciseActions: null,
	};

	render() {
		const {
			inner,
			canAscend,
			canDescend,
			context,
			contextId,
			children,
			hasActions,
			onAscend,
			onDescend,
			onRemove,
			onDuplicate,
			exerciseActions,
			handleDuplicateExercise,
		} = this.props;

		const removeExercise = () => onRemove(contextId);

		return (
			<Wrapper context={context}>
				<Container>{children}</Container>
				{hasActions && (
					<Fragment>
						<SmallScreen>
							<ContextMenu.Menu
								toggleWith={
									inner ? (
										<InnerContextTrigger small icon="more_horiz" />
									) : (
										<ContextTrigger>
											<Icon name="context-menu" />
										</ContextTrigger>
									)
								}>
								{canAscend && (
									<ContextMenu.Item onClick={onAscend}>
										<ContextMenu.ItemIcon
											name="chevron_right"
											rotate="-90deg"
										/>{' '}
										{t('Move up', {
											_context: 'training_library/session_builder',
										})}
									</ContextMenu.Item>
								)}
								{canDescend && (
									<ContextMenu.Item onClick={onDescend}>
										<ContextMenu.ItemIcon name="chevron_right" rotate="90deg" />{' '}
										{t('Move down', {
											_context: 'training_library/session_builder',
										})}
									</ContextMenu.Item>
								)}

								{exerciseActions}

								{onDuplicate && (
									<ContextMenu.Item onClick={handleDuplicateExercise}>
										<ContextMenu.ItemIcon name="file_copy" /> {t('Duplicate')}
									</ContextMenu.Item>
								)}

								{onRemove && (
									<ContextMenu.Item caution onClick={removeExercise}>
										<ContextMenu.ItemIcon name="close" /> {t('Remove')}
									</ContextMenu.Item>
								)}
							</ContextMenu.Menu>
						</SmallScreen>
						<LargeScreen>
							<Actions>
								{onRemove && (
									<ActionButton
										icon="delete"
										small
										iconSize={1.2}
										onClick={removeExercise}
									/>
								)}

								{onDuplicate && (
									<ActionButton
										icon="file_copy"
										iconSize={1.2}
										small
										onClick={handleDuplicateExercise}
									/>
								)}

								<Button
									icon="arrow_upward"
									iconRotate="180deg"
									iconSize={1.3}
									small
									disabled={!canAscend}
									onClick={onAscend}
								/>
								<Button
									icon="arrow_downward"
									iconSize={1.3}
									small
									disabled={!canDescend}
									onClick={onDescend}
								/>
							</Actions>
						</LargeScreen>
					</Fragment>
				)}
			</Wrapper>
		);
	}
}

export default withConfirmDuplication(MovableBlock);

function withConfirmDuplication(Component) {
	return function WrappedComponent(props) {
		const { contextId, onDuplicate } = props;
		const confirmDuplication = useConfirm({
			message: duplicationConfirmString,
			onConfirm: () => {
				onDuplicate(contextId);
			},
		});
		return (
			<Component {...props} handleDuplicateExercise={confirmDuplication} />
		);
	};
}
