import { ReactNode } from 'react';

export const symbol_cell_wifi: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M312-80q-20 0-27.5-18.5T291-131l538-538q14-14 32.5-6.5T880-648v523q0 19-13 32t-32 13H312Zm415-60h93v-435l-93 93v342ZM442-486q-25 0-41-16.5T385-543q0-25 16-41t41-16q24 0 40.5 16t16.5 41q0 24-16.5 40.5T442-486Zm0-174q-38 0-69.5 14T315-609q-9 8-21.5 8t-20.5-8q-9-9-9-22t10-22q33-31 76.5-49t91.5-18q48 0 91.5 18t76.5 49q10 9 10 22t-9 22q-8 8-20.5 8t-21.5-8q-26-23-57.5-37T442-660Zm0-120q-59 0-113.5 21.5T229-698q-10 8-22.5 9t-21.5-8q-9-9-8-22.5t11-22.5q51-47 118-72.5T442-840q69 0 136 25.5T696-742q10 9 11 22.5t-8 22.5q-9 9-21.5 8t-22.5-9q-45-39-99.5-60.5T442-780Z"
		/>
	</svg>
);
