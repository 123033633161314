import { Component } from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { t } from '@transifex/native';

import * as breakpoints from 'pkg/config/breakpoints';

import { getUsersByRole } from 'pkg/selectors/groups';

import * as routes from 'pkg/router/routes';
import Link from 'pkg/router/Link';
import { AccountIdentityContext } from 'pkg/identity/account';

import CardWrapper from 'routes/dashboard/components/Card';

const Card = styled(CardWrapper)`
	display: grid;
	grid-template-columns: 1fr 1fr;
	width: 100%;
	flex: 0 0 100%;
	margin: 0;
	padding: var(--spacing-5);
	line-height: 1;

	@media ${breakpoints.small} {
		grid-template-columns: 1fr;
		grid-row-gap: var(--spacing-8);
	}

	@media ${breakpoints.large}, ${breakpoints.nolimit} {
		width: 100%;
		flex: 0 0 100%;
	}
`;

const CardColumn = styled.div`
	&:last-child {
		text-align: right;
	}
`;

const CardTitle = styled.div`
	font-size: var(--font-size-base);
	font-weight: var(--font-weight-semibold);
	margin-bottom: 0.3rem;
	color: var(--palette-gray-900);
`;

const CardTimeSpan = styled.div`
	font-size: var(--font-size-sm);
	font-weight: var(--font-weight-normal);
	color: var(--palette-gray-500);
`;

const CardValue = styled.div`
	font-size: 1.4rem;
	line-height: 1.4rem;
	font-weight: var(--font-weight-semibold);
	margin-bottom: 0.35rem;

	${(props) =>
		props.$strain &&
		props.$strain === 'very_high' &&
		css`
			color: var(--palette-red-400);
		`}

	${(props) =>
		props.$strain &&
		props.$strain === 'high' &&
		css`
			color: var(--palette-orange-500);
		`}

	${(props) =>
		props.$strain &&
		props.$strain === 'moderate' &&
		css`
			color: var(--palette-green-500);
		`}

	${(props) =>
		props.$strain &&
		props.$strain === 'low' &&
		css`
			color: var(--palette-blue-500);
		`}

	${(props) =>
		props.$strain &&
		props.$strain === 'very_low' &&
		css`
			color: var(--palette-gray-400);
		`}
`;

const CardDescription = styled.div`
	font-size: 0.7rem;
	font-weight: var(--font-weight-normal);
	color: var(--palette-gray-500);
`;

class GroupPhysicalStrain extends Component {
	static contextType = AccountIdentityContext;

	get items() {
		return this.props.memberships;
	}

	get usersWithMaxPhysicalStrainString() {
		const maxUsers = this.items.filter(({ user }) => {
			const currentPhysicalStrain = user.meta.get('current-physical-strain');

			if (currentPhysicalStrain > 80) {
				return true;
			}

			return false;
		});

		if (maxUsers.size > 0) {
			return t(`{usersWithMaxPhysicalStrain} players on high or more`, {
				usersWithMaxPhysicalStrain: maxUsers.size,
			});
		}

		return t(`No players on high or more`);
	}

	get calcAverageStrain() {
		if (this.items.size === 0) {
			return 0;
		}

		const averageSum = this.items.reduce(
			(sum, { user }) =>
				sum + Number(user.meta.get('current-physical-strain')) || 0,
			0
		);

		return Number(averageSum / this.items.size).toFixed(2);
	}

	get averagePhysicalStrain() {
		const strainLevel = this.calcAverageStrain;

		if (strainLevel === 100) {
			return t('Max');
		}

		if (strainLevel > 90) {
			return t('Very high');
		}

		if (strainLevel > 70) {
			return t('High');
		}

		if (strainLevel > 40) {
			return t('Moderate');
		}

		if (strainLevel > 20) {
			return t('Low');
		}

		return t('Very low');
	}

	get trainingStatisticsLink() {
		return routes.Statistics.Training(
			this.context.organization.id,
			this.props.groupId
		);
	}

	get renderCard() {
		let data = {};
		const strainLevel = this.calcAverageStrain;

		data.linkHref = this.trainingStatisticsLink;
		data.title = t(`Physical strain`);
		data.timeSpan = t(`Current`);
		data.value = this.averagePhysicalStrain;

		if (strainLevel === 0) {
			data.description = t(`Need more activities`);
		} else {
			data.description = this.usersWithMaxPhysicalStrainString;
		}

		if (strainLevel > 90) {
			data.strain = 'very_high';
		} else if (strainLevel > 70) {
			data.strain = 'high';
		} else if (strainLevel > 40) {
			data.strain = 'moderate';
		} else if (strainLevel > 0) {
			data.strain = 'low';
		} else {
			data.strain = 'very_low';
		}

		return (
			<Card as={Link} href={data.linkHref} noBorder>
				<CardColumn>
					<CardTitle>{data.title}</CardTitle>
					<CardTimeSpan>{data.timeSpan}</CardTimeSpan>
				</CardColumn>
				<CardColumn>
					<CardValue $strain={data.strain}>{data.value}</CardValue>
					<CardDescription>{data.description}</CardDescription>
				</CardColumn>
			</Card>
		);
	}

	render = () => this.renderCard;
}

const mapStateToProps = (state, props) => ({
	memberships: getUsersByRole(state, { groupId: props.groupId, role: 1 }),
});

export default connect(mapStateToProps)(GroupPhysicalStrain);
