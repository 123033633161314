const icon = {
	name: 'drawing-text',
	objects: [
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M7.01904 5.09933H18.4107V8.52099H17.1107V6.39933H13.3649V17.8205H15.8607V19.1205H9.66805V17.8205H12.0649V6.39933H8.31904V8.52099H7.01904V5.09933Z',
			},
		},
	],
};

export default icon;
