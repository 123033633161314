import { ReactNode } from 'react';

export const symbol_screenshot: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M620-270v-76q0-10.833 7.116-17.917 7.117-7.083 18-7.083Q656-371 663-363.917q7 7.084 7 17.917v96q0 12.75-8.625 21.375T640-220H538q-10.833 0-17.917-7.116-7.083-7.117-7.083-18Q513-256 520.083-263q7.084-7 17.917-7h82ZM340-690v86q0 10.833-7.116 17.917-7.117 7.083-18 7.083Q304-579 297-586.083q-7-7.084-7-17.917v-106q0-12.75 8.625-21.375T320-740h105q10.833 0 17.917 7.116 7.083 7.117 7.083 18Q450-704 442.917-697q-7.084 7-17.917 7h-85ZM260-40q-24 0-42-18t-18-42v-760q0-24 18-42t42-18h440q24 0 42 18t18 42v760q0 24-18 42t-42 18H260Zm0-90v30h440v-30H260Zm0-60h440v-580H260v580Zm0-640h440v-30H260v30Zm0 0v-30 30Zm0 700v30-30Z"
		/>
	</svg>
);
