// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.q2_Bs1tZvUNO83uPSwBT {
	min-width: 0;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	padding: var(--spacing-7) var(--spacing-5) var(--spacing-8) var(--spacing-5);
}
`, "",{"version":3,"sources":["webpack://./routes/dashboard/group/styles.css"],"names":[],"mappings":"AAAA;CACC,YAAY;CACZ,cAAc;CACd,iCAAiC;CACjC,4EAA4E;AAC7E","sourcesContent":[".wrapper {\n\tmin-width: 0;\n\toverflow: auto;\n\t-webkit-overflow-scrolling: touch;\n\tpadding: var(--spacing-7) var(--spacing-5) var(--spacing-8) var(--spacing-5);\n}\n"],"sourceRoot":""}]);
// Exports
export var wrapper = `q2_Bs1tZvUNO83uPSwBT`;
export default ___CSS_LOADER_EXPORT___;
