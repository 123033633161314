import { ReactNode } from 'react';

export const symbol_password_2_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M151.765-454Q105-454 72.5-486.735 40-519.471 40-566.235 40-613 72.735-645.5q32.736-32.5 79.5-32.5Q199-678 231.5-645.265q32.5 32.736 32.5 79.5Q264-519 231.265-486.5q-32.736 32.5-79.5 32.5Zm656-1Q761-455 728.5-487.375T696-566q0-47.083 32.735-80.042Q761.471-679 808.235-679 855-679 887.5-646.042 920-613.083 920-566q0 46.25-32.735 78.625Q854.529-455 807.765-455ZM592-566q0 17-5 34t-16 31q-5 8-14 8t-16-7L414-627q-7-7-6.5-16t7.5-15q14-11 30.5-16t34.5-5q46.667 0 79.333 32.958Q592-613.083 592-566ZM110-200q-12.75 0-21.375-8.675Q80-217.351 80-230.175 80-243 88.625-251.5T110-260h500L84-786q-9-9-9-21.5t9-21.5q9-9 21.5-9t21.5 9l709 709q9 9 9 21t-9 21q-9 9-21.5 9T793-78L669.975-200H110Z"
		/>
	</svg>
);
