import { Fragment } from 'react';
import { t } from '@transifex/native';

import DateTime from 'pkg/datetime';

import TimePicker from 'components/TimePicker';

import Calendar from 'components/form/calendar';

interface DateTimePickerProps {
	hideDatePicker?: boolean;
	disableDatesBefore?: Date;
	disableDatesAfter?: Date;
	highlightedDates?: Date[][];

	selectedDate?: Date;
	onDateChange: (date: Date) => void;
}

const DateTimePicker = ({
	hideDatePicker = false,
	selectedDate = new Date(),
	disableDatesAfter,
	disableDatesBefore,
	highlightedDates,
	onDateChange,
}: DateTimePickerProps) => {
	const timePickerDateChange = (date: Date) => {
		const nextDate = new DateTime(new Date(+selectedDate));
		nextDate.mergeTime(date);

		onDateChange(nextDate.toDate());
	};

	const calendarDateChange = (date: Date[]) => {
		const nextDate = new DateTime(date[0]);
		nextDate.mergeTime(new Date(+selectedDate));
		onDateChange(nextDate.toDate());
	};

	return (
		<Fragment>
			<TimePicker
				selectedDate={selectedDate}
				onDateChange={timePickerDateChange}
				hourFormat={DateTime.getHourFormat()}
				localeAM={t('AM')}
				localePM={t('PM')}
			/>

			{!hideDatePicker && (
				<Calendar
					selectedDates={[selectedDate]}
					onDateChange={calendarDateChange}
					disableDatesBefore={disableDatesBefore}
					disableDatesAfter={disableDatesAfter}
					highlightedDates={highlightedDates}
				/>
			)}
		</Fragment>
	);
};

export default DateTimePicker;
