import { ReactNode } from 'react';

export const symbol_keep: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M634-780v332l77 68q5 5 7 10.595 2 5.595 2 11.564v17.158q0 12.683-8.625 21.183T690-311H510v229q0 5.565-2 10.783Q506-66 501-61l-10 10q-4.636 5-10.818 5Q474-46 469-51l-10-10q-5-5-7-10.217-2-5.218-2-10.783v-229H270q-12.75 0-21.375-8.585Q240-328.169 240-340.859v-17.169q0-5.972 2-11.472 2-5.5 7-10.5l71-68v-332h-20q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T300-840h354q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T654-780h-20ZM321-371h312l-59-55v-354H380v354l-59 55Zm156 0Z"
		/>
	</svg>
);
