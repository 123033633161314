import { ReactNode } from 'react';

export const symbol_safety_divider: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M479.825-200Q467-200 458.5-208.625T450-230v-500q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-730v500q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM40-330v-13q0-21.081 11.408-38.649Q62.816-399.216 83-408q25.078-10.522 51.603-16.261Q161.128-430 190.064-430t55.417 5.739Q271.963-418.522 297-408q20.184 8.784 31.592 26.351Q340-364.081 340-343v13H40Zm580 0v-13q0-21.081 11.408-38.649Q642.816-399.216 663-408q25.078-10.522 51.603-16.261Q741.128-430 770.064-430t55.417 5.739Q851.963-418.522 877-408q20.184 8.784 31.592 26.351Q920-364.081 920-343v13H620ZM189.956-485Q159-485 137-507.044q-22-22.045-22-53Q115-591 137.044-613q22.045-22 53-22Q221-635 243-612.956q22 22.045 22 53Q265-529 242.956-507q-22.045 22-53 22Zm580 0Q739-485 717-507.044q-22-22.045-22-53Q695-591 717.044-613q22.045-22 53-22Q801-635 823-612.956q22 22.045 22 53Q845-529 822.956-507q-22.045 22-53 22Z"
		/>
	</svg>
);
