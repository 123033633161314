import { ReactNode } from 'react';

export const symbol_phone_enabled: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M165-120q-19.286 0-32.143-12.857T120-165v-135q0-14.912 10-26.956Q140-339 155-343l119-26q12.698-2 25.849 2T323-352l95 98q45-24 86-55.5t78-69.5q35-34 64-72t55-82l-99.771-100.774Q593-642 590.5-653.5q-2.5-11.5-.429-25.859L617-805q4-15 14-25t24-10h140q19.286 0 32.143 12.857T840-795q0 116-56 236.5T625-335Q522-232 401.5-176T165-120Zm566-468q25-60 37-106.5t12-85.5H673l-23 110 81 82ZM362-225l-79-83-103 21v107q45-2 93-14t89-31Zm369-363ZM362-225Z"
		/>
	</svg>
);
