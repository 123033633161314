import { ReactNode } from 'react';

export const symbol_power: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M442-180h76v-82l142-156v-191H300v191l142 155.701V-180Zm-60 30v-88L256-377q-7.652-7.857-11.826-18.214T240-417v-192q0-24.75 17.625-42.375T300-669h72l-30 30v-171q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T402-810v141h156v-141q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T618-810v171l-30-30h72q24.75 0 42.375 17.625T720-609v192q0 11.429-4.174 21.786Q711.652-384.857 704-377L578-238v88q0 12.75-8.625 21.375T548-120H412q-12.75 0-21.375-8.625T382-150Zm98-245Z"
		/>
	</svg>
);
