import { ReactNode } from 'react';

export const symbol_settings_backup_restore: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-120q-143 0-245.5-95.5T121-451q-1-12 7.5-21t21.379-9q12.121 0 20.621 8.5Q179-464 181-451q11 115 96 193t203 78q125.357 0 212.679-87.321Q780-354.643 780-480t-87.321-212.679Q605.357-780 480-780q-69 0-129 30.5T246-667h74q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T320-607H172q-12.75 0-21.375-8.625T142-637v-148q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T202-785v76q53-62 125.216-96.5T480-840q75 0 140.5 28.5t114 77q48.5 48.5 77 114T840-480q0 75-28.5 140.5t-77 114q-48.5 48.5-114 77T480-120Zm-.044-285Q449-405 427-427.044q-22-22.045-22-53Q405-511 427.044-533q22.045-22 53-22Q511-555 533-532.956q22 22.045 22 53Q555-449 532.956-427q-22.045 22-53 22Z"
		/>
	</svg>
);
