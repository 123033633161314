import { ReactNode } from 'react';

export const symbol_help_center: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M477.028-246Q493-246 504-257.028q11-11.028 11-27T503.972-311q-11.028-11-27-11T450-310.972q-11 11.028-11 27T450.028-257q11.028 11 27 11ZM605-601q0-52.627-34-83.814Q537-716 479-716q-42 0-75.725 17.472Q369.551-681.056 350-648q-6 9-.944 19.562Q354.111-617.875 365-613q11 5 21.5 1.5T405-624q14-17 32.5-26t41.5-9q32 0 50.5 16t18.5 44q0 20-11 38.5T500-517q-28 26-40 47t-15 48q-1 11 7.5 19.5t20.575 8.5q10.567 0 19.246-8Q501-410 503-422q4-17 13.5-32t29.5-35q32-32 45.5-57.5T605-601ZM180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-60h600v-600H180v600Zm0-600v600-600Z"
		/>
	</svg>
);
