import { ReactNode } from 'react';

export const symbol_chromecast_2: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M390-120q-112 0-191-79t-79-191q0-105 69-181t171-87v-84q0-57 40.5-97.5T498-880q32 0 60 13.5t48 38.5l212 268q10-8 22-7t20 11l61 77q8 10 6.5 22T916-437l-47 37q-10 8-22 7t-20-11l-60-77q-8-10-7-22t11-20L559-791q-11-14-27-21.5t-34-7.5q-33 0-55.5 22.5T420-742v84q102 11 171 87t69 181q0 112-79 191t-191 79Zm0-60q87 0 148.5-61T600-390q0-87-61.5-148.5T390-600q-88 0-149 61.5T180-390q0 88 61 149t149 61Z"
		/>
	</svg>
);
