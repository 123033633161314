import { ReactNode } from 'react';

export const symbol_download_2: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M190-80q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T190-140h580q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q800-97 791.375-88.5T770-80H190Zm289-179q-6.577 0-12.788-2.5Q460-264 455-270L237-552q-11-15-3.5-31.5T260-600h99v-250q0-12.75 8.625-21.375T389-880h180q12.75 0 21.375 8.625T599-850v250h100q19 0 26.5 16.5T722-552L503-270q-5 6-11.212 8.5Q485.577-259 479-259Zm0-79 144-185h-84v-297H419v297h-84l144 185Zm0-185Z"
		/>
	</svg>
);
