import { t } from '@transifex/native';
import { useState } from 'react';

import spacing from 'pkg/config/spacing';

import DateTime, { Granularity } from 'pkg/datetime';
import { cssClasses } from 'pkg/css/utils';

import Icon from 'components/icon';

import Calendar from 'components/form/calendar';
import Row from 'components/layout/row';

import * as ContextMenu from 'design/context_menu';

import * as css from './styles.css';

export interface Dates {
	startOfRange: number;
	endOfRange: number;
}

interface DatePreset {
	name: string;
	default?: boolean;
	// Unix timestamps
	startOfRangeDate: number;
	endOfRangeDate?: number;
}

export interface DateQueryFilters {
	startOfRange?: string | number;
	endOfRange?: string | number;
	dateLabel?: string;
}

interface Props {
	//sets size to same as small button
	small?: boolean;
	//custom dates to filter with from the beginning
	filters?: DateQueryFilters;
	//filter-options/presets beyond the default ones.  a preset to filter
	//from the beginning of the season, for instance.
	customDatePresets?: DatePreset[];
	//a custom function to run before changing the dates.
	beforeDateChange?: (dates: {
		startOfRange: number;
		endOfRange: number;
	}) => void;
	//a custom function to add the filter to the URL.
	setUrl?: (params: DateQueryFilters) => void;
}

const useDateFilter = ({
	filters = {},
	customDatePresets = [],
	beforeDateChange = null,
	setUrl = null,
	small,
}: Props): {
	dates: Dates;
	DateFilter: JSX.Element;
} => {
	const dateTimeNow = DateTime.now();
	const defaultPreset = customDatePresets.find((preset) => preset.default);

	if (!filters.startOfRange) {
		// set default as start of year
		filters.startOfRange = defaultPreset
			? defaultPreset.startOfRangeDate
			: Math.round(
					new DateTime(new Date(Date.UTC(new Date().getUTCFullYear(), 0, 1)))
						.startOfDay / 1000
				);
	}

	if (!filters.endOfRange) {
		filters.endOfRange = Math.floor(dateTimeNow.endOfDay / 1000);
	}

	const [showDatePicker, setShowDatePicker] = useState(false);
	const [dropdownLabel, setDropdownLabel] = useState(
		defaultPreset ? defaultPreset.name : t('This year')
	);
	const [dates, setDates] = useState<Dates>({
		startOfRange: filters.startOfRange as number,
		endOfRange: filters.endOfRange as number,
	});

	const beforeDate = new Date((dates.startOfRange as number) * 1000);
	const afterDate = new Date((dates.endOfRange as number) * 1000);

	const beforeDateTime = new DateTime(beforeDate);
	const afterDateTime = new DateTime(afterDate);

	const beforeDateSpan = beforeDateTime.toLocaleDateString({
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
	});

	const afterDateSpan = afterDateTime.sameDay(dateTimeNow.getTimestamp())
		? t('Today')
		: afterDateTime.toLocaleDateString({
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
			});
	const dateSpanLabel = `${
		filters.startOfRange ? beforeDateSpan : ''
	} – ${afterDateSpan}`;

	const innerDateSpanLabel = `${
		filters.startOfRange
			? beforeDateTime.toLocaleDateString({
					year: 'numeric',
					month: 'numeric',
					day: 'numeric',
				})
			: ''
	} - ${afterDateSpan}`;

	const datePresets: DatePreset[] = [
		{
			name: t('Last 7 days'),
			startOfRangeDate: dateTimeNow.prev(Granularity.day, 7).getUnixTimestamp(),
			endOfRangeDate: DateTime.now().getUnixTimestamp(),
		},
		{
			name: t('Last 4 weeks'),
			startOfRangeDate: dateTimeNow
				.prev(Granularity.week, 4)
				.getUnixTimestamp(),
			endOfRangeDate: DateTime.now().getUnixTimestamp(),
		},
		...customDatePresets,
	];

	const onDateChange = (
		dates: Dates,
		dateLabel: string,
		showDatePicker = false
	) => {
		// Convert dates to become start/end of day
		const newDates: Dates = {
			startOfRange: Math.round(
				DateTime.fromTimestamp(dates.startOfRange).startOfDay / 1000
			),
			endOfRange: Math.floor(
				DateTime.fromTimestamp(dates.endOfRange).endOfDay / 1000
			),
		};

		if (beforeDateChange) beforeDateChange(newDates);

		setDates(newDates);
		setDropdownLabel(dateLabel);
		setShowDatePicker(showDatePicker);

		if (setUrl) {
			setUrl({
				startOfRange: newDates.startOfRange.toString(),
				endOfRange: newDates.endOfRange.toString(),
				dateLabel,
			});
		}
	};

	const handleCalendarChange = (selectedDates: Date[]) => {
		const beforeDate = selectedDates[0]
			? new DateTime(selectedDates[0])
			: undefined;
		const afterDate = new DateTime(selectedDates[1]);

		onDateChange(
			{
				startOfRange: beforeDate ? beforeDate.getUnixTimestamp() : undefined,
				endOfRange: afterDate.getUnixTimestamp(),
			},
			t('Custom'),
			true
		);
	};

	const handleDatePresetChange = (preset: DatePreset) => {
		const dates = {
			startOfRange: preset.startOfRangeDate,
			endOfRange: preset.endOfRangeDate || dateTimeNow.getUnixTimestamp(),
		};

		onDateChange(dates, preset.name);
	};

	const toggleDatePicker = () => {
		setShowDatePicker(!showDatePicker);
	};

	const closeDatePicker = () => setShowDatePicker(false);

	const disableDateAfter = new Date();

	const isActive = (itemTitle: string): boolean => {
		return filters.dateLabel === itemTitle || dropdownLabel === itemTitle;
	};

	const customLabel = t('Custom');

	return {
		dates,
		DateFilter: (
			<ContextMenu.Menu
				onClose={closeDatePicker}
				toggleWith={
					<div
						className={cssClasses(
							css.dateFilterButton,
							small ? css.small : ''
						)}>
						<div className={css.left}>
							<Icon name="nav-events" />
							<span>{t('Date')}</span>
						</div>
						<div className={css.dateFilterLabel}>{dateSpanLabel}</div>
					</div>
				}>
				<ContextMenu.Heading>
					{filters.dateLabel || dropdownLabel}
					<div className={css.dateSpanPreview}>{innerDateSpanLabel}</div>
				</ContextMenu.Heading>

				{datePresets.map((preset: DatePreset) => (
					<ContextMenu.Item
						tight
						key={preset.name}
						onClick={() => handleDatePresetChange(preset)}>
						<Row
							align="center"
							justify="start"
							columns="16px 1fr"
							spacing={spacing._3}>
							{isActive(preset.name) ? (
								<Icon name="check" size={1.2} />
							) : (
								<span />
							)}
							{preset.name}
						</Row>
					</ContextMenu.Item>
				))}

				<ContextMenu.Divider />

				<ContextMenu.Item tight closeOnClick={false} onClick={toggleDatePicker}>
					<Row
						align="center"
						justify="start"
						columns="16px 1fr"
						spacing={spacing._3}>
						{isActive(customLabel) ? (
							<Icon name="check" size={1.2} />
						) : (
							<Icon name="nav-events" size={1.2} />
						)}
						{customLabel}
					</Row>
				</ContextMenu.Item>

				{showDatePicker && (
					<ContextMenu.Pane>
						<div className={css.calendarWrapper}>
							<Calendar
								selectedDates={[beforeDate, afterDate]}
								range
								disableDatesAfter={disableDateAfter}
								onDateChange={handleCalendarChange}
							/>
						</div>
					</ContextMenu.Pane>
				)}
			</ContextMenu.Menu>
		),
	};
};

export default useDateFilter;
