import { ReactNode } from 'react';

export const symbol_reset_tv: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M360-120q-12.75 0-21.375-8.625T330-150v-50H140q-24.75 0-42.375-17.625T80-260v-520q0-24.75 17.625-42.375T140-840h680q24.75 0 42.375 17.625T880-780v137q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T820-643v-137H140v520h680v-233H487l69 68q9 9 9 21t-9 21q-9 9-21 9t-21-9L394-502q-9-9-9-21t9-21l120-120q9-9 21-9t21 9q9 9 9 21t-9 21l-69 69h333q23 0 41.5 18.5T880-493v233q0 24.75-17.625 42.375T820-200H630v50q0 12.75-8.625 21.375T600-120H360Zm150-403Z"
		/>
	</svg>
);
