import { Map, Record } from 'immutable';

import Country from 'pkg/models/country';

import * as actionTypes from 'pkg/actions/action-types';

const initialState = Record(
	{
		items: new Map(),
	},
	'countryReducer'
);

const countries = (state = initialState(), action) => {
	switch (action.type) {
		case actionTypes.Countries.SET_ITEMS:
			let items = state.items;

			Object.values(action.countries || []).forEach(
				(c) => (items = items.set(c.id, new Country(c)))
			);

			return state.set('items', items);
	}

	return state;
};

export default countries;
