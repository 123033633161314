import {
	ChangeEvent,
	CSSProperties,
	MouseEvent,
	useEffect,
	useRef,
} from 'react';
import styled, { DefaultTheme } from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { t } from '@transifex/native';
import { T } from '@transifex/react';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import { useCollection } from 'pkg/api/use_collection';
import * as endpoints from 'pkg/api/endpoints/auto';
import { addCssVar } from 'pkg/cssvars';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { useCurrentGroup, useCurrentMembership } from 'pkg/identity';

import ShareOptions from 'routes/video/shared/ShareOptions';

import Icon from 'components/icon';
import { useStepModalContext } from 'components/step-modal';

import { MatchTable } from 'components/video-library/modals/steps/Match';
import {
	VideoPayload,
	VideoPayloadSetter,
} from 'components/video-library/modals/Video';
import FileInput from 'components/form/File';
import * as Input from 'components/form/inputs';
import Column from 'components/layout/column';

import Button from 'design/button';

const ThumbnailWrapper = styled(Input.Group)`
	position: relative;
`;

const removeButtonBorder = addCssVar((theme: DefaultTheme) =>
	theme.darkMode ? styles.palette.gray[800] : styles.palette.white
);

const thumbnailPreview = addCssVar((theme: DefaultTheme) =>
	theme.darkMode ? styles.palette.gray[700] : styles.palette.gray[500]
);

const thumbnailBoxShadow = addCssVar((theme: DefaultTheme) =>
	theme.darkMode ? styles.palette.gray[600] : styles.palette.gray[300]
);

const thumbnailBoxShadowHover = addCssVar((theme: DefaultTheme) =>
	theme.darkMode ? styles.palette.gray[300] : styles.palette.gray[500]
);

const ThumbnailSelector = styled(FileInput)`
	padding: var(--spacing-5);
	box-shadow: 0 0 0 1px ${thumbnailBoxShadow};
	border-radius: var(--radius-3);
	display: grid;
	grid-template-columns: auto 1fr;
	place-items: center;
	gap: var(--spacing-5);
	transition: box-shadow 350ms ease-in-out;
	cursor: pointer;

	&[data-will-drop='true'] {
		box-shadow:
			0 0 0 1px ${thumbnailBoxShadowHover},
			${styles.boxShadow.cardHover};
	}

	> div {
		pointer-events: none;
		font-weight: var(--font-weight-normal);
	}

	@media ${styles.breakpoint.small} {
		font-size: var(--font-size-sm);
	}
`;

const ThumbnailPreview = styled.div`
	background: ${thumbnailPreview};
	background-size: cover;
	background-position: center center;
	border-radius: var(--radius-2);
	height: 80px;
	aspect-ratio: 16 / 9;
	overflow: hidden;

	/* @TODO Remove when we can drop iOS 14 */
	@supports not (aspect-ratio: 16 / 9) {
		@media ${styles.breakpoint.small} {
			width: 160px;
			height: 90px;
		}
	}
`;

const RemoveThumbnailButton = styled.span`
	display: grid;
	place-items: center;
	background-color: var(--palette-red-500);
	color: var(--palette-white);
	box-shadow: 0 0 0 2px ${removeButtonBorder};
	width: 20px;
	height: 20px;
	border-radius: 100px;
	position: absolute;
	left: 148px;
	top: 32px;

	@media (hover: hover) {
		&:hover {
			background-color: var(--palette-red-800);
		}
	}
`;

interface EditProps {
	videoId?: number;
	videoGroupId?: number;

	payload: VideoPayload;
	setPayload: VideoPayloadSetter;
}

export default function Edit({
	videoId,
	videoGroupId,

	payload,
	setPayload,
}: EditProps): JSX.Element {
	const group = useCurrentGroup();
	const membership = useCurrentMembership();
	const { setCanGoNext, goTo, busy, withPrev } = useStepModalContext();

	const titleRef = useRef<HTMLInputElement>();
	const descriptionRef = useRef<HTMLTextAreaElement>();

	const connectedMatches = useCollection<models.match.Match>(
		endpoints.Match.Index(),
		{
			showAllResults: true,
			queryParams: new URLSearchParams({
				group_id: videoGroupId?.toString(),
				video_id: videoId?.toString(),
			}),
		}
	);

	useComponentDidMount(() => setCanGoNext(true));

	withPrev(() => {
		if (payload.externalId) {
			setPayload({ externalId: null });

			if (!videoId) {
				goTo('start');
			}
		}

		return Promise.resolve(true);
	});

	useEffect(() => {
		if (payload.title) {
			titleRef.current.value = payload.title;
		}

		if (payload.description) {
			descriptionRef.current.value = payload.description;
		}
	}, [payload.title, payload.description]);

	const handleTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
		setPayload({ title: event.target.value });
	};

	const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
		setPayload({ description: event.target.value });
	};

	const handleShareChange = (selectedId: string) => {
		setPayload({
			groupId: selectedId === 'team' ? group.id : null,
		});
	};

	const handleImageSelect = (files: File[]) => {
		setPayload({ file: files[0] });

		goTo('thumbnail');
	};

	const handleRemoveThumbnail = (event: MouseEvent<HTMLSpanElement>) => {
		event.preventDefault();

		setPayload({ previewUrl: null, attachment: null });
	};

	const handleRemoveMatchConnection = async (match: models.match.Match) => {
		connectedMatches.removeRecord(match.id);

		if (videoId && connectedMatches.includesRecord(match.id)) {
			await models.matchVideo.unlink(match.id, videoId);
		}

		if (match.id === payload.match?.id) {
			setPayload({ match: null, matchId: null });
		}
	};

	const isSmallScreen = useMediaQuery({
		maxWidth: styles.breakpoint.toMedium,
	});

	const style = busy
		? {
				pointerEvents: 'none',
				opacity: 0.5,
				cursor: 'busy',
			}
		: {};

	const matches = [payload.match, ...connectedMatches?.records].filter(
		(n) => n
	);

	return (
		<Column style={style as CSSProperties}>
			<Input.Group label={t('Video title')}>
				<Input.Field
					ref={titleRef}
					defaultValue={payload.title}
					onChange={handleTitleChange}
					changeDelay={350}
				/>
			</Input.Group>

			<Input.Group optional label={t('Description')}>
				<Input.Area
					ref={descriptionRef}
					minRows={2}
					defaultValue={payload.description}
					onChange={handleDescriptionChange}
					changeDelay={350}
				/>
			</Input.Group>

			<Input.Group label={t('Sharing options')}>
				<ShareOptions
					disabled={!!videoGroupId}
					selectedId={payload.groupId ? 'team' : 'only_me'}
					onClick={handleShareChange}
					options={[
						{
							id: 'only_me',
							icon: 'person',
							label: t('Only you'),
							description: t(
								'A private video will always be available to you, you may be able to share it with your team, but it cannot be made private once it has been shared.'
							),
						},
						{
							id: 'team',
							icon: 'groups',
							label: t('Shared with team"'),
							description: (
								<T
									_str="A team shared video will be available to members of {team_name}, and cannot be made private once shared."
									team_name={<strong>{group.name}</strong>}
								/>
							),
						},
					]}
				/>
			</Input.Group>

			{payload.groupId && models.membership.isAdminOrStaff(membership) && (
				<Input.Group label={t('Connected match')}>
					<MatchTable
						records={matches}
						onRemove={handleRemoveMatchConnection}
					/>

					{matches.length === 0 && (
						<div>
							<Button onClick={() => goTo('matchConnect')} icon="scoreboard">
								{t('Connect a match to this video')}
							</Button>
						</div>
					)}
				</Input.Group>
			)}

			<ThumbnailWrapper label={t('Video thumbnail')}>
				<ThumbnailSelector
					accept="image/*"
					allowDrop
					onChange={handleImageSelect}>
					{payload.previewUrl && (
						<RemoveThumbnailButton onClick={handleRemoveThumbnail}>
							<Icon name="close" />
						</RemoveThumbnailButton>
					)}
					<ThumbnailPreview
						style={{ backgroundImage: `url(${payload.previewUrl})` }}
					/>
					<div>
						{isSmallScreen
							? t('Tap to choose a new thumbnail image')
							: t('Click to choose, or drag a new thumbnail image here')}
					</div>
				</ThumbnailSelector>
			</ThumbnailWrapper>
		</Column>
	);
}
