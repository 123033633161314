import { ReactNode } from 'react';

export const symbol_turn_left: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m234-528 69 69q9 9 9 21t-9 21q-9 9-21 9t-21-9L141-537q-9-9-9-21t9-21l120-120q9-9 21-9t21 9q9 9 9 21t-9 21l-69 69h386q24.75 0 42.375 17.625T680-528v338q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T620-190v-338H234Z"
		/>
	</svg>
);
