import { ReactNode } from 'react';

export const symbol_video_camera_front: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M232-318h336v-21q0-46-45-72.5T400-438q-78 0-123 26.5T232-339v21Zm168-175q35 0 57.5-22.5T480-573q0-35-22.5-57.5T400-653q-35 0-57.5 22.5T320-573q0 35 22.5 57.5T400-493ZM140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h520q24 0 42 18t18 42v215l134-134q7-7 16.5-3.458Q880-658.917 880-649v338q0 9.917-9.5 13.458Q861-294 854-301L720-435v215q0 24-18 42t-42 18H140Zm0-60h520v-520H140v520Zm0 0v-520 520Z"
		/>
	</svg>
);
