import { ReactNode } from 'react';

export const symbol_select_check_box: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-120q-24.75 0-42.375-17.625T120-180v-600q0-24.75 17.625-42.375T180-840h571q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T751-780H180v600h600v-337q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T840-517v337q0 24.75-17.625 42.375T780-120H180Zm281-252 361-361q9-9 21-9t21 9q9 9 9 21.196 0 12.195-9 20.804L482-309q-9 9-21 9t-21-9L260-489q-9-9-9-21t9-21q9-9 21-9t21 9l159 159Z"
		/>
	</svg>
);
