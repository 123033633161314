import { t } from '@transifex/native';
import { Fragment } from 'react';

import * as styles from 'pkg/config/styles';

import { Group } from 'pkg/api/models/onboarding_info';

import { useOnboardingContext } from 'routes/public/onboarding';
import { useOnboardingState } from 'routes/public/hooks';
import Button from 'routes/public/styles/Button';
import TeamBadge from 'routes/public/styles/TeamBadge';
import Title from 'routes/public/styles/Title';
import CancelButton from 'routes/public/styles/CancelButton';
import * as publicStyles from 'routes/public/styles/styles.css';

import Column from 'components/layout/column';

export default function GroupConfirmation(): JSX.Element {
	const { goTo } = useOnboardingContext();
	const onboardingState = useOnboardingState();

	const group = onboardingState.get<Group>('group');

	const handleSave = () => {
		goTo('role-selector');
	};

	return (
		<Fragment>
			<Column spacing={styles.spacing._8} className={publicStyles.main}>
				<Title
					title={t('Welcome to 360Player!')}
					description={t('You are about to join:')}
				/>

				<TeamBadge group={group} />

				<Column>
					<Button primary label={t('Continue')} onClick={handleSave} />
				</Column>
			</Column>

			<div className={publicStyles.footer}>
				<CancelButton />
			</div>
		</Fragment>
	);
}
