import { ReactNode } from 'react';

export const symbol_dialpad: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M479.911-40Q451-40 430.5-60.589 410-81.177 410-110.088 410-139 430.589-159.5q20.588-20.5 49.5-20.5Q509-180 529.5-159.411q20.5 20.588 20.5 49.499Q550-81 529.411-60.5 508.823-40 479.911-40Zm-250-740Q201-780 180.5-800.589q-20.5-20.588-20.5-49.5Q160-879 180.589-899.5q20.588-20.5 49.5-20.5Q259-920 279.5-899.411q20.5 20.588 20.5 49.5Q300-821 279.411-800.5q-20.588 20.5-49.5 20.5Zm0 246Q201-534 180.5-554.589q-20.5-20.588-20.5-49.5Q160-633 180.589-653.5q20.588-20.5 49.5-20.5Q259-674 279.5-653.411q20.5 20.588 20.5 49.5Q300-575 279.411-554.5q-20.588 20.5-49.5 20.5Zm0 247Q201-287 180.5-307.589q-20.5-20.588-20.5-49.5Q160-386 180.589-406.5q20.588-20.5 49.5-20.5Q259-427 279.5-406.411q20.5 20.588 20.5 49.5Q300-328 279.411-307.5q-20.588 20.5-49.5 20.5Zm500-493Q701-780 680.5-800.589q-20.5-20.588-20.5-49.5Q660-879 680.589-899.5q20.588-20.5 49.5-20.5Q759-920 779.5-899.411q20.5 20.588 20.5 49.5Q800-821 779.411-800.5q-20.588 20.5-49.5 20.5Zm-250 493Q451-287 430.5-307.589q-20.5-20.588-20.5-49.5Q410-386 430.589-406.5q20.588-20.5 49.5-20.5Q509-427 529.5-406.411q20.5 20.588 20.5 49.5Q550-328 529.411-307.5q-20.588 20.5-49.5 20.5Zm250 0Q701-287 680.5-307.589q-20.5-20.588-20.5-49.5Q660-386 680.589-406.5q20.588-20.5 49.5-20.5Q759-427 779.5-406.411q20.5 20.588 20.5 49.5Q800-328 779.411-307.5q-20.588 20.5-49.5 20.5Zm0-247Q701-534 680.5-554.589q-20.5-20.588-20.5-49.5Q660-633 680.589-653.5q20.588-20.5 49.5-20.5Q759-674 779.5-653.411q20.5 20.588 20.5 49.5Q800-575 779.411-554.5q-20.588 20.5-49.5 20.5Zm-250 0Q451-534 430.5-554.589q-20.5-20.588-20.5-49.5Q410-633 430.589-653.5q20.588-20.5 49.5-20.5Q509-674 529.5-653.411q20.5 20.588 20.5 49.5Q550-575 529.411-554.5q-20.588 20.5-49.5 20.5Zm0-246Q451-780 430.5-800.589q-20.5-20.588-20.5-49.5Q410-879 430.589-899.5q20.588-20.5 49.5-20.5Q509-920 529.5-899.411q20.5 20.588 20.5 49.5Q550-821 529.411-800.5q-20.588 20.5-49.5 20.5Z"
		/>
	</svg>
);
