import { ReactNode } from 'react';

export const symbol_local_police: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m480-431 83 63q5 3 9.5 0t2.5-8l-35-103 87-62q5-3 3-8.5t-7-5.5H518l-31-101q-2-5-7-5t-7 5l-31 101H337q-5 0-7 5.5t3 8.5l85 62-36 103q-2 5 2.5 8.5t9.5.5l86-64Zm0 347q-5 0-9.5-1t-9.5-3q-139-47-220-168.5T160-523v-196q0-19 11-34.5t28-22.5l260-97q11-4 21-4t21 4l260 97q17 7 28 22.5t11 34.5v196q0 145-81 266.5T499-88q-5 2-9.5 3t-9.5 1Zm0-59q115-38 187.5-143.5T740-523v-196l-260-98-260 98v196q0 131 72.5 236.5T480-143Zm0-337Z"
		/>
	</svg>
);
