export default {
	name: 'add-conversation',
	objects: [
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M6.906 13.55L4.97 15.629l4.04.015c.028.439.103.875.224 1.3l-4.269-.015a1.3 1.3 0 01-.947-2.186l1.239-1.33a6.852 6.852 0 0111.114-7.864 6.822 6.822 0 011.73 4.279 6.124 6.124 0 00-1.354-.538 5.55 5.55 0 10-9.842 4.26z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M18.18 17.503a3.63 3.63 0 10-2.694 1.375l3.96-.015-1.266-1.36zm1.621-.166a4.933 4.933 0 00-8.15-5.368 4.931 4.931 0 003.863 8.208l3.937-.014a1.3 1.3 0 00.946-2.186l-.596-.64z',
			},
		},
	],
};
