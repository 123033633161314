import { useSelector } from 'react-redux';

import * as selectors from 'pkg/selectors';
import { useAccountIdentity } from 'pkg/identity/account';
import { getUnreadChatsForOtherOrganizations } from 'pkg/api/models/group';
import { cssClasses } from 'pkg/css/utils';

import Icon from 'components/icon';

import NotificationList from 'components/notifications/List';

import * as ContextMenu from 'design/context_menu';

import * as css from './styles.css';

export const NotificationsItem = (): JSX.Element => {
	const hasUnreadNotifications = useSelector(selectors.notifications.hasUnread);
	const { organizations, organization } = useAccountIdentity();
	const unreadChats = getUnreadChatsForOtherOrganizations(
		organization,
		organizations
	);

	const hasNotifications = hasUnreadNotifications || unreadChats > 0;

	return (
		<div
			className={cssClasses(
				css.wrapper,
				hasNotifications && css.hasNotifications
			)}>
			<ContextMenu.Menu
				appearFrom={ContextMenu.AppearFrom.TopRight}
				offsetHorizontal={-6}
				offsetVertical={42}
				toggleWith={
					<Icon className={css.triggerIcon} name="notification-outline" />
				}
				scrollSpy>
				<NotificationList />
			</ContextMenu.Menu>
		</div>
	);
};
