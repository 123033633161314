import { ReactNode } from 'react';

export const symbol_recent_patient: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M220-160q-24.75 0-42.375-17.625T160-220v-34q0-38 19-65t49-41q67-30 128.5-45T480-420q21 0 42 1.5t42 5.5q11.243 1.6 18.622 10.8Q590-393 590-380.879q0 12.879-9.5 20.379Q571-353 558-355q-20-3-39-4t-39-1q-57 0-110.5 12T252-306q-14 7-23 21.5t-9 30.5v34h340q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T560-160H220Zm0-60h370-370Zm260-261q-66 0-108-42t-42-108q0-66 42-108t108-42q66 0 108 42t42 108q0 66-42 108t-108 42Zm0-60q39 0 64.5-25.5T570-631q0-39-25.5-64.5T480-721q-39 0-64.5 25.5T390-631q0 39 25.5 64.5T480-541Zm0-90Zm250 421h-50q-12.75 0-21.375-8.625T650-240v-160q0-12.75 8.625-21.375T680-430h147q12.629 0 19.314 11Q853-408 848-397l-64 127h40q13.5 0 20.25 11.5T844-236L744-62q-3 5-8.5 3.5T730-66v-144Z"
		/>
	</svg>
);
