import { ReactNode } from 'react';

export const symbol_energy: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M373-80v-27q0-33 23.5-55.5T453-186v-300q-26-6-46-23t-31-41l-90 22q-31 8-59.5-3.5T188-572l178-44q6-43 37.5-71.5T478-718l28-98q9-31 33-51.5t53-13.5l-50 179q29 14 44 42t15 60q0 14-3.5 27.5T588-547l62 67q21 23 26.5 54T663-371L542-499q-5 6-13 9t-16 4v300q33 1 57 23.5t24 55.5v27H373Zm110-452q29 0 48.5-19.5T551-600q0-29-19.5-48.5T483-668q-29 0-48.5 19.5T415-600q0 29 19.5 48.5T483-532Z"
		/>
	</svg>
);
