import { t } from '@transifex/native';
import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import * as styles from 'pkg/config/styles';

import Rating from 'pkg/models/rating';
import Position from 'pkg/models/position';
import PositionScore from 'pkg/models/position_score';
import User from 'pkg/models/user';

import * as selectors from 'pkg/selectors';
import * as actions from 'pkg/actions';
import { RootState } from 'pkg/reducers';

import PositionDetails from 'containers/user/rating/PositionDetails';

import RelativeDateTime from 'components/RelativeDateTime';
import Heading from 'components/Heading';
import CardBase, * as Card from 'components/Card';

import PropertyTable from 'components/user/rating/PropertyTable';
import { Spinner } from 'components/loaders/spinner';

const ComparisonHeader = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: var(--spacing-9);

	@media ${styles.breakpoint.small} {
		grid-template-columns: none;
		grid-template-rows: 1fr 1fr;
		grid-gap: var(--spacing-7);
	}
`;

const ComparisonInfo = styled.div`
	div {
		display: flex;
		justify-content: space-between;
		margin-bottom: var(--spacing-3);

		&:last-child {
			margin-bottom: 0;
		}

		span:first-child {
			color: var(--palette-gray-500);
		}

		span:last-child {
			font-weight: var(--font-weight-semibold);
		}
	}
`;

const Values = styled.div`
	margin-top: var(--spacing-8);

	${Heading} {
		margin-bottom: var(--spacing-3);
	}
`;

type SinglePositionMatchProps = {
	ratingId: number;
	compareId: number;
	userId: number;
	setDefinedPageActions: () => void;
	forAccount?: number;
};

const SinglePositionMatch: React.FC<
	React.PropsWithChildren<SinglePositionMatchProps>
> = ({ ratingId, compareId, userId, setDefinedPageActions, forAccount }) => {
	const dispatch = useDispatch();

	const [isFetching, setIsFetching] = useState({
		positions: false,
		ratings: false,
	});

	const maturityAdjusted: number = useSelector(
		(state: RootState) => state.ratings.maturityAdjustment
	);

	const rating: Rating = useSelector((state: RootState) =>
		selectors.ratings.getSingleRating(state, { id: ratingId })
	);

	const comparison: Rating = useSelector((state: RootState) =>
		selectors.ratings.getSingleRating(state, { id: compareId })
	);

	const comparisonScores: PositionScore[] = useSelector((state: RootState) =>
		selectors.ratings.getPositionScores(state, { ratingId: comparison.id })
	);

	const user: User = useSelector((state: RootState) =>
		selectors.users.find(state, userId)
	);

	const position: Position = useSelector((state: RootState) =>
		selectors.positions.getCurrentPositionId(state)
	);

	const positionScores: PositionScore[] = useSelector((state: RootState) =>
		selectors.ratings.getPositionScores(state, {
			ratingId,
		})
	);

	const handleMaturityAdjustment = (): void => {
		dispatch(actions.ratings.toggleMaturityAdjustment(!maturityAdjusted));
	};

	useEffect((): void => {
		setIsFetching((prev) => ({ ...prev, positions: true }));
		const fetchData = async (): Promise<void> => {
			await dispatch(actions.positions.deprecatedFetchPositions(forAccount));
			setIsFetching((prev) => ({ ...prev, positions: false }));
		};

		fetchData();
	}, [dispatch]);

	useEffect((): void => {
		setIsFetching((prev) => ({ ...prev, ratings: true }));
		const fetchData = async (): Promise<void> => {
			await dispatch(actions.ratings.fetchSingleRating(ratingId, forAccount));
			if (compareId) {
				await dispatch(
					actions.ratings.fetchComparisonRating(compareId, forAccount)
				);
			}
			setIsFetching((prev) => ({ ...prev, ratings: false }));
		};

		fetchData();
	}, [dispatch, ratingId, compareId]);

	useEffect(() => {
		setDefinedPageActions();
	}, [setDefinedPageActions]);

	if (isFetching.ratings || isFetching.positions) {
		return <Spinner />;
	}

	return (
		<Fragment>
			<CardBase>
				<PositionDetails
					rating={rating}
					ratingId={rating.id}
					position={position}
					positionScores={positionScores}
					comparison={comparison}
					comparisonScores={comparisonScores}
					isChild={!user.isAnAdult}
					maturityAdjusted={maturityAdjusted}
					handleMaturityAdjustmentToggle={handleMaturityAdjustment}
					hideTrainingSuggestions={false}
					currentPositionId={position.id}
				/>
			</CardBase>
			<Values>
				{!compareId && <Heading>{t(`Based on rating`)}:</Heading>}
				<CardBase>
					<Card.Body>
						{compareId ? (
							<ComparisonHeader>
								{[rating, comparison].map(
									(data: Rating, index: number): JSX.Element => (
										<ComparisonInfo key={`${data.id}${index}`}>
											<div>
												<span>{t(`Based on rating`)}</span>
												<span>
													{data.author?.firstName} {data.author?.lastName}
												</span>
											</div>
											<div>
												<span>{t(`Created`)}</span>
												<span>
													<RelativeDateTime dateTime={data.createdAt} />
												</span>
											</div>
											<div>
												<span>{t(`Best foot`)}</span>
												<span>
													{data.primaryFoot === 'left'
														? t('Left foot')
														: t('Right foot')}
												</span>
											</div>
										</ComparisonInfo>
									)
								)}
							</ComparisonHeader>
						) : null}
						<PropertyTable
							ratingId={rating.id}
							comparison={comparison}
							comparisonScores={comparisonScores}
							user={user}
						/>
					</Card.Body>
				</CardBase>
			</Values>
		</Fragment>
	);
};

export default SinglePositionMatch;
