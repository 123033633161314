import { useState } from 'react';

import useDateFilter from 'pkg/hooks/use-date-filter';

import DatePicker from 'components/form/date-picker';
import DateTimePicker from 'components/form/DateTimePicker';

export const DatePickerStory = () => {
	const [dates, setDates] = useState([new Date(), new Date()]);

	const handleChange = (dates: Date[]) => {
		setDates(dates);
	};

	return (
		<DatePicker
			dates={dates}
			handleChange={handleChange}
			multipleDates
			disableDatesBefore={new Date('5 November 2022')}
			highlightedDates={[
				[new Date('9 November 2022'), new Date('18 November 2022, 20:00')],
				[new Date('5 November 2022'), new Date('8 November 2022, 20:00')],
				[new Date('19 November 2022'), new Date('20 November 2022, 20:00')],
			]}
		/>
	);
};

export const DateTimePickerStory = () => {
	const [date, setDate] = useState(new Date());

	const onDateChange = (date: Date) => {
		setDate(date);
	};

	return <DateTimePicker selectedDate={date} onDateChange={onDateChange} />;
};

export const DateFilterHookStory = () => {
	const udf = useDateFilter({});

	return udf.DateFilter;
};
