import { ReactNode } from 'react';

export const symbol_cookie_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M749-632q-2 42 27.5 73t71.225 41.714Q862-513 871-501.719q9 11.281 9 26.719 0 57-13.5 106T824-274q-7 11-18.826 12.5T783-268q-11-8-13-21t5-24q19-34 31-71.5t15-79.5q-54-20-87.5-59.5T692-614q-81-11-136.5-70T492-821q-51-2-96.5 9.5T311-777q-11.17 7-23.085 5Q276-774 268-784.5t-5.455-22.662Q265.091-819.324 276-825q51-30 109-43t118-13q19 0 33 11.5t15 29.5q3 68 49 117t115 51q15 1 25 12.607T749-632ZM339.882-358Q314-358 296-376.118q-18-18.117-18-44Q278-446 296.118-464q18.117-18 44-18Q366-482 384-463.882q18 18.117 18 44Q402-394 383.882-376q-18.117 18-44 18ZM480-80q-85 0-158-30.5T195-195q-54-54-84.5-127T80-480q0-68 20-128t56-110L47-827q-9-9.067-9-21.533Q38-861 47.053-870q9.052-9 21.5-9Q81-879 90-870l765 765q9 9 9 21t-9.053 21q-9.052 9-21.5 9Q821-54 812-63l-94-93q-50 36-110 56T480-80Zm0-60q54.643 0 103.821-15.5Q633-171 675-199L199-675q-28 42-43.5 91.179Q140-534.643 140-480q0 145 97.5 242.5T480-140Zm-43-297Zm117-119Z"
		/>
	</svg>
);
