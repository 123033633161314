import { ReactNode } from 'react';

export const symbol_house_siding: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M261-240v90q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T201-150v-402L99-475q-10.043 8-21.522 6.5Q66-470 58-480q-8-10-6-22t12-20l380-290q8-6 17.211-9.5 9.21-3.5 18.789-3.5t18.789 3.5Q508-818 516-812l380 290q10 8 11.5 20t-6.5 22q-8 10-19.478 11.5Q870.043-467 860-475l-102-78v403q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T698-150v-90H261Zm0-220h437v-100H261v100Zm0 160h437v-100H261v100Zm29-320h380L480-765 290-620Z"
		/>
	</svg>
);
