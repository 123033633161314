import { ReactNode } from 'react';

export const symbol_shift: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M320-150v-280H182q-19.362 0-27.181-17Q147-464 159-479l298-371q8.88-11 22.94-11T503-850l298 371q12 15 4.181 32T778-430H640v280q0 12.75-8.625 21.375T610-120H350q-12.75 0-21.375-8.625T320-150Zm60-30h200v-310h133L480-786 247-490h133v310Zm100-310Z"
		/>
	</svg>
);
