import { ReactNode } from 'react';

export const symbol_line_start_diamond: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m360-285 195-195-195-195-195 195 195 195Zm-21 64L101-459q-9-9-9-21t9-21l238-238q9-9 21-9t21 9l229 229h240q13 0 21.5 8.5T880-480q0 13-8.5 21.5T850-450H610L381-221q-9 9-21 9t-21-9Zm21-259Z"
		/>
	</svg>
);
