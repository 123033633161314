import { ReactNode } from 'react';

export const symbol_humidity_percentage: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M580.118-250Q601-250 615.5-264.618q14.5-14.617 14.5-35.5Q630-321 615.382-335.5q-14.617-14.5-35.5-14.5Q559-350 544.5-335.382q-14.5 14.617-14.5 35.5Q530-279 544.618-264.5q14.617 14.5 35.5 14.5ZM357-277q9 9 21 9t21-9l204-204q9-9 9-21t-9.053-21q-9.052-9-21.5-9Q569-532 560-523L357-319q-9 8.8-9 20.9 0 12.1 9 21.1Zm23.118-173Q401-450 415.5-464.618q14.5-14.617 14.5-35.5Q430-521 415.382-535.5q-14.617-14.5-35.5-14.5Q359-550 344.5-535.382q-14.5 14.617-14.5 35.5Q330-479 344.618-464.5q14.617 14.5 35.5 14.5ZM480-80q-137 0-228.5-94T160-408q0-64 29-127.042t72.5-121Q305-714 355.5-764.5T450-854q7-6 14.57-8.5t15.5-2.5q7.93 0 15.43 2.5T510-854q44 39 94.615 89.701 50.616 50.701 94 108.5Q742-598 771-535q29 63 29 127 0 140-91.5 234T480-80Zm0-60q112 0 186-76.5t74-191.822Q740-487 673.5-587.5T480-800Q353-688 286.5-587.5T220-408.322Q220-293 294-216.5T480-140Zm0-340Z"
		/>
	</svg>
);
