import { ReactNode } from 'react';

export const symbol_cached: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M481-158q-131 0-225.5-94.5T161-478v-45l-61 61q-8 8-19 8t-19-8q-8-8-8-19.5t8-19.5l108-109q9-9 21-9t21 9l109 109q8 8 8 19t-8 19q-8 8-19.5 8t-19.5-8l-61-60v45q0 107 76.5 183.5T481-218q20 0 39-2.5t36-7.5q12-3 23.5 1.5T596-211q5 11 0 22t-17 15q-24 8-48.5 12t-49.5 4Zm-1-580q-20 0-39 2.5t-36 7.5q-12 3-24-1.5T364-745q-5-11 0-22.5t16-15.5q25-8 49.5-11.5T480-798q131 0 225.5 94.5T800-478v43l61-61q8-8 19-8t19 8q8 8 8 19.5t-8 19.5L791-348q-9 9-21 9t-21-9L641-456q-8-8-8-20t8-20q8-8 20-8t20 8l59 59v-41q0-107-76.5-183.5T480-738Z"
		/>
	</svg>
);
