import { useState, useEffect } from 'react';

import DisplayWrapper from 'components/drawing/display/DisplayWrapper';
import EditWrapper from 'components/drawing/edit/EditWrapper';
import { FabricContextProvider } from 'components/drawing/FabricContext';

export default function Drawing({
	drawingId,
	drawing,
	drawings,
	setDrawings,
	updateDrawing,
	isEditable,
	deleteExistingDrawing,
	isCurrentThumbnail,
	setThumbnail,
}: any) {
	const [isEditing, setIsEditing] = useState(false);

	useEffect(() => {
		// if no canvas data is set the drawing must be new, start editing right away
		if (!drawing.objects && isEditable) setIsEditing(true);
	}, [drawing]);

	const saveAndClose = (updates?: any) => {
		if (updates) {
			// update drawing in state
			updateDrawing(drawingId, updates);
		} else if (drawingId.startsWith('tempId-') && !drawing.objects) {
			// cancelled out from new and empty drawing, delete drawing from state
			const newDrawings = { ...drawings };
			delete newDrawings[drawingId];
			setDrawings(newDrawings);
		}
		setIsEditing(false);
	};

	// set sport to football for legacy drawings missing specified sport
	if (!drawing.sport) drawing.sport = 'football';

	return (
		<FabricContextProvider drawingId={drawingId} drawing={drawing}>
			{isEditing ? (
				<EditWrapper saveAndClose={saveAndClose} setIsEditing={setIsEditing} />
			) : (
				<DisplayWrapper
					setIsEditing={isEditable && setIsEditing}
					drawingId={drawingId}
					drawing={drawing}
					drawings={drawings}
					setDrawings={setDrawings}
					deleteExistingDrawing={deleteExistingDrawing}
					isCurrentThumbnail={isCurrentThumbnail}
					setThumbnail={setThumbnail}
				/>
			)}
		</FabricContextProvider>
	);
}
