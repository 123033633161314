import { Map, Record } from 'immutable';

import VideoPlaylist from 'pkg/models/video_playlist';

import * as actionTypes from 'pkg/actions/action-types';

interface IPlaylistsReducer {
	entities: Map<number, VideoPlaylist>;
}

class PlaylistReducer
	extends Record<IPlaylistsReducer>(
		{
			entities: Map<number, VideoPlaylist>(),
		},
		'videoPlaylists'
	)
	implements IPlaylistsReducer
{
	entities: Map<number, VideoPlaylist>;
}

const initialState = new PlaylistReducer({
	entities: Map<number, VideoPlaylist>(),
});

interface NormalizrPayload {
	result: number[];
	entities: {
		videoPlaylists: { [key: string]: VideoPlaylist };
	};
}

interface PlaylistAction {
	type: string;
	payload?: NormalizrPayload;
	playlistId?: number;
}

const setItems = (
	state = initialState,
	action: PlaylistAction
): PlaylistReducer => {
	const videoPlaylists = action.payload;

	Object.values(videoPlaylists).forEach((playlist: VideoPlaylist) => {
		state = state.updateIn(
			['entities', playlist.id],
			(existing: VideoPlaylist) => {
				if (!existing) {
					existing = new VideoPlaylist(playlist);
				}

				existing = existing.merge(playlist);

				return existing;
			}
		);
	});

	return state;
};

const deleteItem = (
	state = initialState,
	action: PlaylistAction
): PlaylistReducer => {
	if (action.playlistId) {
		state = state.deleteIn(['entities', action.playlistId]);
	}

	return state;
};

const videoCollectionsReducer = (
	state = initialState,
	action: PlaylistAction
): PlaylistReducer => {
	switch (action.type) {
		case actionTypes.VideoPlaylists.SET_ITEMS:
		case actionTypes.VideoPlaylists.UPDATE_ITEM:
			return setItems(state, action);
		case actionTypes.VideoPlaylists.DELETE_ITEM:
			return deleteItem(state, action);
		default:
			return state;
	}
};

export default videoCollectionsReducer;
