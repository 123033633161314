import styled, { css, DefaultTheme } from 'styled-components';

import * as styles from 'pkg/config/styles';

import { addCssVar } from 'pkg/cssvars';

import * as iconStyles from 'components/icon/styles.css';

import HeadCell from './HeadCell';

interface HeadCellSortProps {
	active: boolean;
}

interface SortIconProps {
	active: boolean;
	sortOrder: 'asc' | 'desc';
}

export const SortIcons = styled.div<SortIconProps>`
	margin-left: var(--spacing-2);
	margin-right: calc(var(--spacing-2) * -1);
	display: flex;

	.${iconStyles.icon} {
		opacity: 0.3;
	}

	.${iconStyles.icon}:first-child {
		margin-right: -6px;
	}

	${({ active, sortOrder }) =>
		active &&
		sortOrder === 'asc' &&
		css`
			.${iconStyles.icon}:first-child {
				opacity: 1;
			}
		`}
	${({ active, sortOrder }) =>
		active &&
		sortOrder === 'desc' &&
		css`
			.${iconStyles.icon}:last-child {
				opacity: 1;
			}
		`}
`;

export default styled(HeadCell)<HeadCellSortProps>`
	cursor: pointer;
	display: flex;
	justify-content: space-between;

	&:hover {
		background-color: ${addCssVar((theme: DefaultTheme) =>
			theme.darkMode ? styles.palette.black : styles.palette.gray[200]
		)};
	}

	${(props) =>
		props.active &&
		css`
			color: var(--palette-blue-500) !important;
			background-color: var(--palette-blue-200) !important;
		`}

	.${iconStyles.icon} {
		font-size: var(--font-size-lg);
	}
`;
