import { useDispatch, useSelector } from 'react-redux';

import {
	submitEventEdit,
	cancelEventEdit,
	removeMatchEventGuids,
	removeMatchEvent,
} from 'pkg/actions/match_events_deprecated';

import * as selectors from 'pkg/selectors';

import SelectModal from './SelectModal';
import SubModal from './SubModal';
import PosSwapModal from './PosSwapModal';

export default ({ matchId }) => {
	const dispatch = useDispatch();

	const matchUsers = useSelector((state) =>
		selectors.matchUsers.getUsersForMatch(state, { matchId })
	).map((matchUser) => matchUser.user);
	const matchEventEdit = useSelector((state) =>
		selectors.matchEvents.getMatchEventEdit(state)
	);
	const editEvent = useSelector((state) =>
		selectors.matchEvents.find(state, matchEventEdit.eventId)
	);
	const eventChanges = useSelector((state) =>
		selectors.matchEvents.getCurrentEditChanges(state)
	);

	const modes = useSelector((state) =>
		selectors.matchEvents.getMatchEventModes(state)
	);

	const eventModes = modes.get(editEvent.id);

	const handleNext = () => dispatch(submitEventEdit());

	const handleClose = () => dispatch(cancelEventEdit());

	const handleCancel = () => {
		!eventModes.get('handled') && removeEvent();
		handleClose();
	};

	const removeEvent = () => {
		dispatch(removeMatchEvent(editEvent.id));
		dispatch(removeMatchEventGuids([editEvent.id]));
	};

	const modalProps = {
		event: editEvent,
		changes: eventChanges,
		users: matchUsers,
		onNext: handleNext,
		onClose: handleClose,
	};

	let ActiveModal = <SelectModal {...modalProps} />;

	if (
		editEvent.type === 'substitution_in' ||
		editEvent.type === 'substitution_out'
	) {
		ActiveModal = (
			<SubModal {...modalProps} mode={eventModes} onCancel={handleCancel} />
		);
	}

	if (editEvent.type === 'position_swap') {
		ActiveModal = <PosSwapModal {...modalProps} onCancel={handleCancel} />;
	}

	return ActiveModal;
};
