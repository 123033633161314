import { t } from '@transifex/native';
import { Fragment } from 'react';

import * as styles from 'pkg/config/styles';

import * as api from 'pkg/api';
import * as models from 'pkg/api/models';
import { useCurrentRoute } from 'pkg/router/hooks';
import * as actions from 'pkg/actions';
import * as endpoints from 'pkg/api/endpoints/auto';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import getRequiredIdpForMembershipRole from 'pkg/api/models/identity_provider';
import { OnboardingInfoResponse } from 'pkg/api/endpoints/onboarding_info';

import { useOnboardingContext } from 'routes/public/onboarding';
import Button from 'routes/public/styles/Button';
import CancelButton from 'routes/public/styles/CancelButton';
import * as formStyles from 'routes/public/styles/forms/styles.css';
import * as publicStyles from 'routes/public/styles/styles.css';
import ThreeSixtyLogo from 'routes/public/styles/logo';
import Title from 'routes/public/styles/Title';
import AccountCard from 'routes/public/styles/AccountCard';
import GroupCard from 'routes/public/styles/GroupCard';
import { useOnboardingState } from 'routes/public/hooks';

import { Spinner } from 'components/loaders/spinner';
import Column from 'components/layout/column';

export default function AccountInviteConfirmation(): JSX.Element {
	const { goTo, reset } = useOnboardingContext();
	const onboardingState = useOnboardingState();
	const identityProviders =
		onboardingState.get<models.identityProvider.ScrubbedIdentityProvider[]>(
			'identityProviders'
		);
	const invite = onboardingState.get<models.onboardingInfo.Invite>('invite');

	let { inviteKey } = useCurrentRoute();
	const { query } = useCurrentRoute();

	if (!inviteKey) {
		inviteKey = (query?.invite_key as string) || null;
	}

	useComponentDidMount(async () => {
		const [request, response] = await api.get<OnboardingInfoResponse>(
			endpoints.Accounts.OnboardingInfo() + `?account_invite_key=${inviteKey}`
		);

		if (!request.ok) {
			actions.flashes.show(
				{
					title: t('Something went wrong'),
					message: t('This invite code is not in use.'),
				},
				404
			);

			reset();
		} else {
			onboardingState.set({
				group: {
					country: response.country,
					groupName: response.groupName,
					organizationId: response.organizationId,
					organizationName: response.organizationName,
					primaryColor: response.primaryColor,
					profileImageUrl: response.profileImageUrl,
				},
				inviteKey,
				invite: response,
				identityProviders: response.identityProviders,
			});
		}
	});

	if (!invite) {
		return <Spinner />;
	}

	const save = () => {
		if (
			getRequiredIdpForMembershipRole(
				invite.targetGroupRoles[0],
				identityProviders
			)
		) {
			goTo('account-invite-sso-select');
			return;
		}

		goTo('account-invite-user-information');
	};

	const login = () => {
		goTo('login');
	};

	return (
		<Fragment>
			<Column spacing={styles.spacing._8} className={publicStyles.main}>
				<ThreeSixtyLogo />

				<Title title={t('Welcome!')} />

				<Column spacing={styles.spacing._4}>
					<span className={formStyles.label}>
						{t('You have been invited by')}
					</span>
					<AccountCard account={invite.author} hideEmail />
				</Column>

				<Column spacing={styles.spacing._4}>
					<span className={formStyles.label}>{t('To connect to')}</span>

					{invite.targetChildren && (
						<Fragment>
							{invite.targetChildren.map((account: models.account.Account) => (
								<AccountCard account={account} key={account.id} hideEmail />
							))}
						</Fragment>
					)}

					{invite.targetGroups && (
						<Fragment>
							{invite.targetGroups.map((group: models.group.Group) => (
								<GroupCard group={group} key={group.name} />
							))}
						</Fragment>
					)}
				</Column>

				<Column>
					<Button primary label={t('Create an account')} onClick={save} />
					<Button label={t('Login')} onClick={login} />
				</Column>
			</Column>

			<div className={publicStyles.footer}>
				<CancelButton />
			</div>
		</Fragment>
	);
}
