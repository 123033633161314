import { Fragment, useContext } from 'react';

import * as models from 'pkg/api/models';
import { useQueryState } from 'pkg/hooks/query-state';

import { BookingsContext } from 'routes/scheduling/bookings';
import ResourceRow from 'routes/scheduling/bookings/resources/Row';
import { findResourcesByFilterByBookings } from 'routes/scheduling/utils';
import {
	defaultFilters,
	hasBeenFiltered,
} from 'routes/scheduling/templates/filters';

interface resourceRow {
	resource: models.resource.Resource;
	items: models.booking.Booking[];
}

const ResourceGrid = () => {
	const qs = useQueryState();
	const search = qs.get('search')?.toString().toLowerCase();
	const qsFilters = qs.get('schedulingFilters');
	const filters =
		qsFilters !== 'undefined' && qsFilters
			? JSON.parse(qsFilters as string)
			: defaultFilters;
	const hasFiltered = hasBeenFiltered(filters);

	const bookingsContext = useContext(BookingsContext);

	const grid = new Map<string, resourceRow>();

	bookingsContext.resources.forEach((r) =>
		grid.set(r.id.toString(), {
			resource: r,
			items: [],
		})
	);

	if (search) {
		for (const k of grid.keys()) {
			if (!grid.get(k).resource.title.toLocaleLowerCase().includes(search)) {
				grid.delete(k);
			}
		}
	}

	const resourcesToList = findResourcesByFilterByBookings(
		bookingsContext.resources,
		bookingsContext.bookings,
		filters,
		bookingsContext.conflicts
	);

	const resourceIds = [...new Set(resourcesToList.map((r) => r.id))];

	if (hasFiltered) {
		for (const k of grid.keys()) {
			if (!resourceIds.includes(Number.parseInt(k, 10))) {
				grid.delete(k);
			}
		}
	}

	if (grid.size === 0) {
		return null;
	}

	const resourceRowItems: JSX.Element[] = [];

	grid.forEach((row) =>
		resourceRowItems.push(
			<ResourceRow key={row.resource.id} resource={row.resource} />
		)
	);

	return <Fragment>{resourceRowItems}</Fragment>;
};

export default ResourceGrid;
