import { ReactNode } from 'react';

export const symbol_auto_awesome_motion: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M380-80q-24 0-42-18t-18-42v-440q0-24 18-42t42-18h440q24 0 42 18t18 42v440q0 24-18 42t-42 18H380Zm0-60h440v-440H380v440Zm-180-76v-484q0-24 18-42t42-18h484q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T744-700H260v484q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T200-216ZM80-336v-484q0-24 18-42t42-18h484q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T624-820H140v484q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Q97-306 88.5-314.625T80-336Zm300 196v-440 440Z"
		/>
	</svg>
);
