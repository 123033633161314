import { ReactNode } from 'react';

export const symbol_cell_tower: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M130-560q0 57 21.5 118T214-333q8 8 9 18.5t-7 18.5q-8 8-18.5 7.5T180-297q-51-57-75.5-126T80-560q0-68 24.5-137T180-823q7-8 17-9t18 7q8 8 7.5 19t-8.5 19q-41 48-62.5 109T130-560Zm132 0q0 30 13 67t34 68q6 9 6.5 19t-7.5 18q-8 8-18.5 7.5T272-389q-31-37-45.5-83T212-560q0-42 14.5-88t45.5-83q7-8 17-8.5t18 7.5q8 8 8 18t-6 18q-22 29-34.5 66T262-560Zm121 390-24 71q-3 8-10 13.5T333-80q-14 0-23-11.5t-4-25.5l121-368q-17-12-27.5-31T389-560q0-38 26.5-64.5T480-651q38 0 64.5 26.5T571-560q0 25-10.5 44T533-485l121 368q5 14-4 25.5T627-80q-9 0-16-5t-10-14l-23-71H383Zm19-60h156l-78-238-78 238Zm296-330q0-30-13-67t-34-68q-6-9-6-19t8-18q8-8 18-7.5t17 8.5q30 37 45 83t15 88q0 42-15 88t-45 83q-7 8-17 8.5t-18-7.5q-8-8-8-18t6-18q22-29 34.5-66t12.5-70Zm132 0q0-57-21.5-118T746-787q-8-8-9-18.5t7-18.5q8-8 18.5-7.5T780-823q51 57 75.5 126T880-560q0 69-23.5 137.5T781-297q-8 8-18 8.5t-18-7.5q-8-8-7.5-18.5T746-333q41-48 62.5-109T830-560Z"
		/>
	</svg>
);
