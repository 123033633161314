import { ReactNode } from 'react';

export const symbol_things_to_do: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M242-180v-192h-60q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T182-432h61q5-84 61-139t146-65v-174q0-12.75 8.625-21.375T480-840h208q12.75 0 21.375 8.625T718-810v86q0 12.75-8.625 21.375T688-694H510v58q90 10 146 65t61 139h61q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T778-372h-60v192h132q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-120H110q-12.75 0-21.375-8.675Q80-137.351 80-150.175 80-163 88.625-171.5T110-180h132Zm60 0h148v-192H302v192Zm208 0h148v-192H510v192ZM305-432h350q-5-64-54-105t-121-41q-72 0-121 41t-54 105Zm175 0Z"
		/>
	</svg>
);
