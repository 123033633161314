import { Fragment, ChangeEvent, useContext } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import DateTime from 'pkg/datetime';

import {
	FormEngineContext,
	SelectedProductInfo,
} from 'routes/forms/hooks/useFormEngine';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import Icon from 'components/icon';

import { Label } from 'components/form/inputs/styles';
import Section from 'components/form/Section';
import * as Table from 'components/form/Table';
import Column from 'components/layout/column';
import Row from 'components/layout/row';

import Button from 'design/button';

import * as css from './styles.css';

interface ProductProps {
	data: { selectedProducts: SelectedProductInfo[] };
	onAddProduct: () => void;
	onEditProduct: (priceId: number) => void;
	onChange?: (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
}

const Products: React.FC<React.PropsWithChildren<ProductProps>> = ({
	data,
	onAddProduct,
	onEditProduct,
}) => {
	const hasProducts = data.selectedProducts.length > 0;

	return (
		<Section
			icon="sell"
			title={t('{step}. Add products and price options', { step: 3 })}
			description={t(
				'Do you want to add a product which can be purchased upon submission of this form?'
			)}
			hideDivider={hasProducts}>
			<Column>
				{hasProducts && (
					<Column spacing={styles.spacing._5}>
						<Table.Table>
							<thead>
								<Table.TableHeadRow>
									<Table.HeadCell>{t('Product description')}</Table.HeadCell>
									<LargeScreen>
										<Table.HeadCell>{t('Required')}</Table.HeadCell>
										<Table.HeadCell>{t('Quantity')}</Table.HeadCell>
										<Table.HeadCell>{t('TAX')}</Table.HeadCell>
									</LargeScreen>
									<Table.HeadCell />
									<Table.HeadCell />
								</Table.TableHeadRow>
							</thead>
							<tbody>
								{data.selectedProducts.map((productData) => (
									<ProductRow
										key={productData.product.id}
										item={productData}
										onEdit={onEditProduct}
									/>
								))}
							</tbody>
						</Table.Table>
					</Column>
				)}
				<LargeScreen>
					<Row align="center" columns="auto 1fr">
						<Button
							secondary
							icon="add"
							onClick={onAddProduct}
							testid="form.create.add_product">
							{t('Add product')}
						</Button>
						<Label>
							<span>{t('Optional')}</span>
						</Label>
					</Row>
				</LargeScreen>
				<SmallScreen>
					<Button
						block
						secondary
						icon="add"
						onClick={onAddProduct}
						testid="form.create.add_product">
						{t('Add product')}
					</Button>
				</SmallScreen>
			</Column>
		</Section>
	);
};

interface ProductRowProps {
	item: SelectedProductInfo;
	onEdit: (productId: number) => void;
}

const ProductRow: React.FC<React.PropsWithChildren<ProductRowProps>> = ({
	item,
	onEdit,
}) => {
	const formEngineContext = useContext(FormEngineContext);

	const taxRate = item.taxRate;
	let hasTaxRate = false;

	if (taxRate && !!Object.keys(taxRate).length) {
		hasTaxRate = true;
	}

	const handleEdit = () => onEdit(item.product.id);

	const handleRemove = () =>
		formEngineContext.setFormState({
			selectedProductsInfo:
				formEngineContext.formState.selectedProductsInfo.filter(
					(p) => p.product.id !== item.product.id
				),
		});

	const validTo = new DateTime(new Date(item.validTo * 1000));

	return (
		<Fragment>
			<Table.TableRow>
				<Table.Cell>
					<Column spacing={styles.spacing._1}>
						{item.product.name}
						{item.validTo > 0 && (
							<span className={css.validUntil}>
								{t('Valid until {date}', {
									date: validTo.toLocaleDateString({
										year: 'numeric',
										month: 'numeric',
										day: 'numeric',
									}),
								})}
							</span>
						)}
					</Column>
				</Table.Cell>
				<LargeScreen>
					<Table.Cell>{item.required ? t('Yes') : t('No')}</Table.Cell>
					<Table.Cell>
						{item.minQuantity}
						{item.maxQuantity > item.minQuantity && ` - ${item.maxQuantity}`}
					</Table.Cell>
					<Table.Cell>
						{hasTaxRate &&
							`${taxRate.percentage}% ${
								taxRate.inclusive === true ? t('incl.') : ''
							}`}
					</Table.Cell>
				</LargeScreen>
				<Table.IconCell onClick={handleEdit}>
					<Icon name="edit" />
				</Table.IconCell>
				<Table.IconCell onClick={handleRemove}>
					<Icon name="close-circle" />
				</Table.IconCell>
			</Table.TableRow>
		</Fragment>
	);
};

export default Products;
