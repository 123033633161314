import { ReactNode } from 'react';

export const symbol_arrow_split: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M190-448q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T190-508h276l232-232h-93q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T605-800h165q12.75 0 21.375 8.625T800-770v165q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T740-605v-92L509-466q-8 8-19.278 13-11.278 5-23.722 5H190Zm415 288q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T605-220h93L545-374q-9-8.8-9-20.9 0-12.1 9-21.1 9-9 21.196-9 12.195 0 20.804 9l153 153v-92q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T800-355v165q0 12.75-8.625 21.375T770-160H605Z"
		/>
	</svg>
);
