import { ReactNode } from 'react';

export const symbol_ev_mobiledata_badge: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M100-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h760q24 0 42 18t18 42v600q0 24-18 42t-42 18H100Zm0-60h760v-600H100v600Zm0 0v-600 600Zm310-100q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T410-340H260v-110h110q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T370-510H260v-110h150q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T410-680H230q-12.75 0-21.375 8.625T200-650v340q0 12.75 8.625 21.375T230-280h180Zm210-97-72-281q-2.357-9.862-10.214-15.931Q529.929-680 518.929-680 504-680 495-668q-9 12-5 26l88 337q2.87 11.207 12.435 18.103Q600-280 611-280h18q11 0 20.565-6.897Q659.13-293.793 662-305l88-338q4-14-5.209-25.5Q735.581-680 721-680q-10.973 0-18.811 6.345Q694.351-667.31 692-657l-72 280Z"
		/>
	</svg>
);
