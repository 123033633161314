import { ReactNode } from 'react';

export const symbol_flashlight_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M784-109 630-263v123q0 24.75-17.625 42.375T570-80H390q-24.75 0-42.375-17.625T330-140v-423L77-816q-9-9-9-21t9-21q9-9 21.5-9t21.5 9l707 707q9 9 9 21t-9 21q-9 9-21.5 9t-21.5-9Zm-394-31h180v-183L390-503v363Zm240-293-60-60v-45l90-131v-35H360l-60-60h360v-56H300v56l-60-60q2-23 19-39.5t41-16.5h360q24.75 0 42.375 17.625T720-820v146q0 9.273-2.308 17.773-2.307 8.5-7.692 16.227l-80 117v90ZM510-553Zm-30 140Z"
		/>
	</svg>
);
