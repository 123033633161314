import styled from 'styled-components';

import * as breakpoints from 'pkg/config/breakpoints';

import Placeholder from 'components/Placeholder';

import { ParagraphRow } from 'components/loaders/skeleton/placeholders/ParagraphRow';

const Wrapper = styled.div`
	display: block;
	flex: 0 0 65%;
	width: 65%;
	margin-right: 1rem;
	scroll-snap-align: center;
	min-width: 0;

	@media (hover: hover) {
		scroll-snap-align: none;
	}

	@media ${breakpoints.large}, ${breakpoints.nolimit} {
		flex: 0 0 210px;
		width: 210px;
	}

	&:last-child {
		margin-right: 0;
	}
`;

const VideoThumb = styled.div`
	height: 120px;
	width: 100%;
	background-color: var(--palette-gray-300);
	margin-bottom: 8px;
`;

export default class DashboardPlaceholder extends Placeholder {
	getPlaceholderItem(key) {
		return (
			<Wrapper key={key}>
				<VideoThumb />
				<ParagraphRow height={13} percentageWidth={75} />
				<ParagraphRow height={11} percentageWidth={55} />
			</Wrapper>
		);
	}
}
