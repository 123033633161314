import { ReactNode } from 'react';

export const symbol_keyboard_command_key: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M249.899-120Q196-120 158-158.101t-38-92Q120-304 158.071-342q38.072-38 91.929-38h70v-200h-70q-53.857 0-91.929-38.101-38.071-38.101-38.071-92T158.101-802q38.101-38 92-38T342-801.929q38 38.072 38 91.929v70h200v-70q0-53.857 38.101-91.929 38.101-38.071 92-38.071T802-801.899q38 38.101 38 92T801.929-618Q763.857-580 710-580h-70v200h70q53.857 0 91.929 38.101 38.071 38.101 38.071 92T801.899-158q-38.101 38-92 38T618-158.071Q580-196.143 580-250v-70H380v70q0 53.857-38.101 91.929-38.101 38.071-92 38.071Zm-.017-60Q279-180 299.5-200.417 320-220.833 320-250v-70h-70q-29.167 0-49.583 20.382Q180-279.235 180-250.118 180-221 200.382-200.5q20.383 20.5 49.5 20.5Zm460 0Q739-180 759.5-200.382q20.5-20.383 20.5-49.5Q780-279 759.583-299.5 739.167-320 710-320h-70v70q0 29.167 20.382 49.583Q680.765-180 709.882-180ZM380-380h200v-200H380v200ZM250-640h70v-70q0-29.167-20.382-49.583Q279.235-780 250.118-780 221-780 200.5-759.618q-20.5 20.383-20.5 49.5Q180-681 200.417-660.5 220.833-640 250-640Zm390 0h70q29.167 0 49.583-20.382Q780-680.765 780-709.882 780-739 759.618-759.5q-20.383-20.5-49.5-20.5Q681-780 660.5-759.583 640-739.167 640-710v70Z"
		/>
	</svg>
);
