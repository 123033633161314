import { ReactNode } from 'react';

export const symbol_bloodtype: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-80q-137 0-228.5-94T160-408q0-64 29-127.042t72.5-121Q305-714 355.5-764.5T450-854q7-6 14.57-8.5t15.5-2.5q7.93 0 15.43 2.5T510-854q44 39 94.615 89.701 50.616 50.701 94 108.5Q742-598 771-535q29 63 29 127 0 140-91.5 234T480-80Zm0-60q112 0 186-76.5t74-191.822Q740-487 673.5-587.5T480-800Q353-688 286.5-587.5T220-408.322Q220-293 294-216.5T480-140Zm0-340Zm-90 220h180q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T570-320H390q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T390-260Zm60-190v60q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T510-390v-60h60q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T570-510h-60v-60q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T450-570v60h-60q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T390-450h60Z"
		/>
	</svg>
);
