import { getTimeZones, TimeZone } from '@vvo/tzdb';

import DateTime from 'pkg/datetime';

/**
 *
 * @returns Array of all tzdb time zones
 */
export const getTimeZoneList = (): TimeZone[] => getTimeZones();

export const getGmtString = (tz: TimeZone): string | undefined => {
	if (tz?.currentTimeFormat && tz?.mainCities) {
		return `(GMT ${tz?.currentTimeFormat.substr(0, 6)}) ${tz?.mainCities.join(
			', '
		)}`;
	}

	return undefined;
};

/**
 * @returns Array of GMT strings from each unique time zone (e.g. (GMT +02:00) European Central Time)
 */
export const getGmtStrings = (): string[] =>
	getTimeZoneList()
		.sort((a, b) => a.currentTimeFormat.localeCompare(b.currentTimeFormat))
		.map((tz) => getGmtString(tz));

/**
 * @returns The tz object from the tz string in the session storage
 */

/**
 * @param tzString
 * @returns The tz object from a tz string
 */
export const getTimeZoneFromString = (
	tzString: string
): TimeZone | undefined => {
	let tz = getTimeZoneList().find((tz) => tzString === tz.name);
	if (!tz) {
		tz = getTimeZoneList().find((tz) => tz.group.includes(tzString));
	}

	return tz;
};

export const getTimeZoneFromSessionStorage = (): TimeZone =>
	getTimeZoneFromString(DateTime.getTimeZone());

/**
 *
 * @param tzString
 * @returns if tz string exists
 */
export const validateTimeZone = (tzString: string): boolean =>
	getGmtStrings().find((string) => string === tzString) ? true : false;

/**
 *
 * @param gmtString
 * @returns time zone from a gmt string
 */
export const getTimeZoneByGmtString = (gmtString: string): TimeZone => {
	const cities = gmtString
		.substr(gmtString.indexOf(')') + 2)
		.split(', ')
		.toString();
	return getTimeZoneList().find((tz) => tz.mainCities.toString() === cities);
};

/**
 *
 * @param tzName
 * @returns gmt string from tz name
 */
export const getGmtStringByTimeZoneName = (tzName: string): string =>
	getGmtString(getTimeZoneList().find((tz) => tz.name === tzName));
