import { ReactNode } from 'react';

export const symbol_fireplace: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M414-269q14 11 29 16t31 5q30.908 0 55.954-19.5T560-323q5-49-31-73.5T473-459q-5 16-5.5 27t3.5 27q3 20 8 34.5t1 33.5q-5 21-22.5 40.5T414-269ZM80-140v-680q0-24.75 17.625-42.375T140-880h680q24.75 0 42.375 17.625T880-820v680q0 24.75-17.625 42.375T820-80H140q-24.75 0-42.375-17.625T80-140Zm400.059-60Q533-200 569.5-236.75T606-326q0-23-10.5-42T569-398q-42-26-66.5-65.5T467-533q-54 35-84 87t-30 117q0 71 38.118 100 38.117 29 88.941 29ZM140-140h100v-30.175q0-12.825 8.625-21.325T270-200h66q-22-26-32.5-58T293-329q0-94 48-162t126-115q6-4 12.842-4 6.842 0 13 2T504-601q5 5 7 12 14 49 33 78t58 61q34 28 49 57t15 67q0 38.351-13 70.676Q640-223 616-200h74q12.75 0 21.375 8.625T720-170v30h100v-680H140v680Zm340-60Z"
		/>
	</svg>
);
