import { ReactNode } from 'react';

export const symbol_nest_wake_on_approach: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M829.882-481Q809-481 794.5-495.583 780-510.167 780-531v-259q0-20.833 14.618-35.417Q809.235-840 830.118-840 851-840 865.5-825.417 880-810.833 880-790v259q0 20.833-14.618 35.417Q850.765-481 829.882-481ZM400-481q-63 0-106.5-43.5T250-631q0-63 43.5-106.5T400-781q63 0 106.5 43.5T550-631q0 63-43.5 106.5T400-481ZM80-160v-94q0-35 18-63.5t50-42.5q60-27 123.184-43.5Q334.367-420 400-420q66 0 128.5 17T651-360q32 14 50.5 42.5T720-254v94H80Zm60-60h520v-34q0-16.69-9-30.345Q642-298 627-306q-54-26-110.5-40t-116.289-14q-59.788 0-117 14Q226-332 172-306q-15 8-23.5 21.655Q140-270.69 140-254v34Zm260-321q38 0 64-26t26-64q0-38-26-64t-64-26q-38 0-64 26t-26 64q0 38 26 64t64 26Zm0-90Zm0 411Z"
		/>
	</svg>
);
