import { ReactNode } from 'react';

export const symbol_rv_hookup: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M356-121q-39 0-71.5-23.5T242-207h-42q-53 0-86.5-33.5T80-327v-176q0-24.75 17.625-42.375T140-563h181v-137H110q-12.75 0-21.375-8.675Q80-717.351 80-730.175 80-743 88.625-751.5T110-760h490q24 0 42 18t18 42v433h144l-42-42q-9-9-9-21t8.609-21q9.391-9 21.891-9 12.5 0 21.5 9l94 93q9 9 9 21t-9 21l-94 94q-9 9-21 8.5t-21-9.5q-9-9-9-21.5t9-21.5l41-41H470q-10 39-42.5 62.5T356-121Zm25-442h219v-137H381v137Zm-24.754 382Q381-181 397.5-197.746q16.5-16.745 16.5-41.5Q414-264 397.254-280.5q-16.745-16.5-41.5-16.5Q331-297 314.5-280.254q-16.5 16.745-16.5 41.5Q298-214 314.746-197.5q16.745 16.5 41.5 16.5ZM242-267q10-39 42.5-64.5T356-357q39 0 71.5 25.5T470-267h130v-236H140v176q0 27 16.5 43.5T200-267h42Zm0-236H140h460-358Z"
		/>
	</svg>
);
