import { ReactNode } from 'react';

export const symbol_smoking_rooms: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M130-200q-20.833 0-35.417-14.618Q80-229.235 80-250.118 80-271 94.583-285.5 109.167-300 130-300h535q10.833 0 17.917 7.097Q690-285.806 690-274.951v50.097Q690-214 682.917-207q-7.084 7-17.917 7H130Zm629.884 0Q749-200 742-207.097t-7-17.952v-50.097Q735-286 742.116-293q7.117-7 18-7Q771-300 778-292.903t7 17.952v50.097Q785-214 777.884-207q-7.117 7-18 7Zm95 0Q844-200 837-207.097t-7-17.952v-50.097Q830-286 837.116-293q7.117-7 18-7Q866-300 873-292.903t7 17.952v50.097Q880-214 872.884-207q-7.117 7-18 7Zm-95-145Q749-345 742-352.083q-7-7.084-7-17.917v-31q0-42-24.5-66T651-491h-71q-54 0-90.5-41.5T453-628q0-54 36.5-90.5T580-755q10.833 0 17.917 7.116 7.083 7.117 7.083 18Q605-719 597.917-712q-7.084 7-17.917 7-35 0-56 21t-21 56q0 35 21 61t55.565 26H651q54.377 0 94.188 37Q785-467 785-413v43q0 10.833-7.116 17.917-7.117 7.083-18 7.083Zm95 0Q844-345 837-352.083q-7-7.084-7-17.917v-75q0-75-50-123t-125-48q-10.833 0-17.917-7.116-7.083-7.117-7.083-18Q630-652 637.083-659q7.084-7 17.917-7 34 0 55.5-24t21.5-58q0-34-21.5-58T655-830q-10.833 0-17.917-7.116-7.083-7.117-7.083-18Q630-866 637.083-873q7.084-7 17.917-7 54 0 90.5 39t36.5 93q0 33-12.5 57T737-650q58 20 100.5 75T880-445v75q0 10.833-7.116 17.917-7.117 7.083-18 7.083Z"
		/>
	</svg>
);
