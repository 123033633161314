import { Fragment, ReactNode } from 'react';
import { t } from '@transifex/native';

import spacing from 'pkg/config/spacing';

import * as models from 'pkg/api/models';
import { cssClasses } from 'pkg/css/utils';
import { useCurrentAccountUserIds } from 'pkg/identity';
import { EventSeriesUserResponseStatus } from 'pkg/api/models/event_series_user';

import * as Card from 'components/Card';
import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import Icon from 'components/icon';
import Avatar from 'components/avatar';

import Row from 'components/layout/row';
import Column from 'components/layout/column';
import * as css from 'components/event/user-card/styles.css';

interface EventSeriesUserCardProps {
	eventSeriesUser: models.eventSeriesUser.EventSeriesUser;
	children?: ReactNode;
}

export default function EventSeriesUserCard({
	eventSeriesUser,
	children,
}: EventSeriesUserCardProps) {
	const userIds = useCurrentAccountUserIds();
	const eventUserIsCurrentUser = userIds.includes(eventSeriesUser.user.id);
	const actionHasBeenTaken =
		eventSeriesUser.status !== EventSeriesUserResponseStatus.Pending;

	let eventUserStatusClass = '';
	let text = '';
	const name = `${
		eventSeriesUser.user.firstName
	} ${eventSeriesUser.user.lastName.substring(0, 1)}.`;

	if (eventUserIsCurrentUser) {
		switch (eventSeriesUser.status) {
			case EventSeriesUserResponseStatus.Accepted:
				text = t('You accepted');
				eventUserStatusClass = css.accepted;
				break;
			case EventSeriesUserResponseStatus.Declined:
				text = t('You declined');
				eventUserStatusClass = css.declined;
				break;
			case EventSeriesUserResponseStatus.Pending:
				text = t('No response');
				break;
		}
	} else {
		switch (eventSeriesUser.status) {
			case EventSeriesUserResponseStatus.Accepted:
				text = t('{name} accepted', { name });
				eventUserStatusClass = css.accepted;
				break;
			case EventSeriesUserResponseStatus.Declined:
				text = t('{name} declined', { name });
				eventUserStatusClass = css.declined;
				break;
			case EventSeriesUserResponseStatus.Pending:
				text = t(`{name} hasn't responded`, { name });
				break;
		}
	}

	const content = (
		<Fragment>
			<Row align="center" spacing={spacing._3} autoColumns="max-content">
				<Avatar size={22} user={eventSeriesUser.user} />
				{!actionHasBeenTaken ? (
					eventSeriesUser.user.firstName
				) : (
					<span className={cssClasses(css.name, eventUserStatusClass)}>
						{text}
						{actionHasBeenTaken && <Icon size={1.2} name="chevron-down" />}
					</span>
				)}
			</Row>

			{children}
		</Fragment>
	);

	return (
		<Card.Base>
			<Card.Body className={css.cardBody}>
				<LargeScreen>
					<Row
						autoColumns="max-content"
						justifyContent="space-between"
						align="center">
						{content}
					</Row>
				</LargeScreen>
				<SmallScreen>
					<Column>{content}</Column>
				</SmallScreen>
			</Card.Body>
		</Card.Base>
	);
}
