import { ReactNode } from 'react';

export const symbol_sign_language: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M303-838q-11-8-13-19.5t6-22.5q8-11 19.5-12.5T338-886l201 149q10 8 12 19.826T545-695q-8 11-20.174 13T502-688L303-838Zm-40 120q-11-8-12.5-19.5T257-760q8-11 19.5-12.5T299-766l129 96q-19-3-37 7t-26 21l-102-76Zm380 137ZM438-887q-11-8-12.5-19.5T432-929q8-11 19.5-12.5T474-935l226 168 12-98q1.971-15.31 12.486-26.155Q735-902 750-904l16-2q11-2 20.5 4t12.5 17l84 282q8 26 3.37 51.165Q881.741-526.671 866-506L760-364q-1-20-6-38t-15-35l78-105q8.25-10.154 9.625-21.577Q828-575 825-586l-61-208-11 85q-2 17-18 24.5t-30-3.5L438-887Zm-97 377-86-64q-11-8-12.5-19.5T249-616q8-11 19.5-12.5T291-622l49 37q0 19 6 38t16 37h-21ZM70-290q-12.75 0-21.375-8.675Q40-307.351 40-320.175 40-333 48.625-341.5T70-350h250q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T320-290H70Zm40 120q-12.75 0-21.375-8.675Q80-187.351 80-200.175 80-213 88.625-221.5T110-230h210q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T320-170H110Zm80 120q-12.75 0-21.375-8.675Q160-67.351 160-80.175 160-93 168.625-101.5T190-110h420q21.25 0 35.625-14.375T660-160v-200q0-12.5-5-22.5T640-400L466-530l42 75q8 15-.5 30T482-410H150q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-470h281l-49-86q-8-13-6-28t13-26l12-11q8-8 19-9t20 6l236 176q21.083 15.731 32.542 38.866Q720-386 720-360v200q0 45.833-32.083 77.917Q655.833-50 610-50H190Zm315-210Z"
		/>
	</svg>
);
