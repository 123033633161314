import { ReactNode } from 'react';

export const symbol_swap_horiz: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m194-323 100 100q9 9 9 21.158 0 12.158-8.609 21Q285-172 272.9-172t-20.9-9L101-332q-5-5-7-10.133-2-5.134-2-11Q92-359 94-364q2-5 7-10l151-151q9-9 21-9t21.391 9Q303-516 303-504t-9 21L194-383h286q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T480-323H194Zm572-254H480q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T480-637h286L666-737q-9-9-9-21.158 0-12.158 8.609-21Q675-788 687.1-788t20.9 9l151 151q5 5 7 10.133 2 5.134 2 11Q868-601 866-596q-2 5-7 10L708-435q-9 9-21 9t-21.391-9Q657-444 657-456t9-21l100-100Z"
		/>
	</svg>
);
