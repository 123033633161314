import styled from 'styled-components';
import { t } from '@transifex/native';

import Icon from 'components/icon';

import * as iconStyles from 'components/icon/styles.css';

import Button from 'design/button';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 250px;
	border: 1px dashed var(--palette-gray-500);
	border-radius: var(--radius-5);
	padding: var(--spacing-5);
`;

const CollectionWrapper = styled(Wrapper)`
	margin-top: 20px;
`;

const Content = styled.div`
	color: var(--palette-gray-500);
	text-align: center;

	.${iconStyles.icon} {
		font-size: 50px;
	}
`;

const Text = styled.div`
	margin-bottom: 15px;
	line-height: 20px;
`;

const Title = styled.div`
	font-weight: var(--font-weight-semibold);
`;

/**
 * @type {React.Element<any>}
 */

export const CollectionPlaceholder = ({ onActionClick }) => {
	const _context = 'training_library';

	const handleActionClick = () => onActionClick('collection');

	return onActionClick ? (
		<CollectionWrapper>
			<Content>
				<Icon name="folder-list" />
				<Text>
					<Title>{t('Nothing here yet', { _context })}</Title>
					{t('Start of by creating your first collection.', { _context })}
				</Text>
				<Button transparent onClick={handleActionClick}>
					{t('Create a collection', { _context })}
				</Button>
			</Content>
		</CollectionWrapper>
	) : (
		<Content>
			<Icon name="folder-list" />
			<Text>
				<Title>{t('Nothing here yet', { _context })}</Title>
				{t('Start of by creating your first collection.', { _context })}
			</Text>
		</Content>
	);
};
