import { Component } from 'react';
import PropTypes from 'prop-types';

import { omit } from 'pkg/objects';
import { debounce } from 'pkg/timings';
import withForwardedRef from 'pkg/withForwardedRef';

import Icon from 'components/icon';

import * as Input from 'components/form/inputs';

const NOOP = () => {};

/* @NOTE Currently this component cannot handle external updates */
/* @deprecated */

class DelayedTextInput extends Component {
	static propTypes = {
		onTyping: PropTypes.func,
		onChange: PropTypes.func,

		minRows: PropTypes.number,
		maxRows: PropTypes.number,

		bounceDelay: PropTypes.number,
		small: PropTypes.bool,
	};

	static defaultProps = {
		onTyping: NOOP,
		onChange: NOOP,

		bounceDelay: 500,
	};

	handleTyping = (event) => {
		const target = event.currentTarget;
		const { value } = target;

		this.props.onTyping(value, target);
		this.handleOnChange(value, target);
	};

	handleOnChange = debounce((value, target) => {
		this.props.onChange(value, target);
	}, this.props.bounceDelay);

	get componentProperties() {
		const props = omit(
			this.props,
			'value',
			'onTyping',
			'onChange',
			'onInput',
			'bounceDelay',
			'small',
			'forwardedRef'
		);

		props.small = this.props.small;
		props.ref = this.props.forwardedRef;
		props.changeDelay = this.props.bounceDelay;

		props.defaultValue = this.props.value;
		props.onTyping = (event) =>
			this.props.onTyping(event.target.value, event.target);
		props.onChange = (event) =>
			this.props.onChange(event.target.value, event.target);

		return props;
	}

	render() {
		if (this.props.minRows || this.props.maxRows) {
			return <Input.Area {...this.componentProperties} />;
		}

		if (this.props.icon) {
			return (
				<Input.Field {...this.componentProperties}>
					<Input.Prefix>
						<Icon name={this.props.icon} />
					</Input.Prefix>
				</Input.Field>
			);
		}

		return <Input.Field {...this.componentProperties} />;
	}
}

export default withForwardedRef(DelayedTextInput);
