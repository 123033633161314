import { t } from '@transifex/native';
import styled, { css, DefaultTheme } from 'styled-components';

import * as styles from 'pkg/config/styles';
import * as palette from 'pkg/config/palette';
import { weight } from 'pkg/config/fonts';

import { CollectionPagination } from 'pkg/api/use_collection';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';

import Icon from 'components/icon';
import { useModalContext } from 'components/modal';

import NumberSteps, { Step } from 'components/pagination/NumberSteps';
import { useFullScreenTableContext } from 'components/layout/page-templates';
import { useAppState } from 'components/application/state';

const Wrapper = styled.div<{
	theme?: DefaultTheme;
	fullPageTable?: boolean;
}>`
	display: flex;
	justify-content: space-between;
	align-items: start;
	position: sticky;
	bottom: 0;
	box-shadow: ${styles.boxShadow.medium};
	padding: var(--spacing-4);
	background-color: var(--palette-white);

	${({ fullPageTable }) =>
		fullPageTable &&
		css`
			box-shadow: none;
			padding: var(--spacing-4) 0;
		`};

	${({ theme }) =>
		theme.darkMode &&
		css`
			background-color: var(--palette-gray-800);
		`}
`;

const Count = styled.div`
	display: grid;
	grid-auto-flow: column;
	grid-gap: var(--spacing-5);
	align-items: center;
	align-self: center;
	@media ${styles.breakpoint.small} {
		font-size: var(--font-size-sm);
	}
`;

const CountAmount = styled.div`
	span {
		font-weight: ${weight.semibold};
	}
`;

const TotalCount = styled.p`
	color: var(--palette-gray-500);
	font-size: var(--font-size-sm);
	line-height: var(--font-line-height-sm);
	@media ${styles.breakpoint.small} {
		font-size: var(--font-size-xs);
	}
`;

const Paginate = styled.div<{ theme?: DefaultTheme }>`
	display: flex;
	border-radius: var(--radius-2);
	border: 1px solid
		${({ theme }) => (theme.darkMode ? palette.gray[600] : palette.gray[300])};
`;

const DirectionStep = styled(Step)``;

const Pagination: React.FC<React.PropsWithChildren<CollectionPagination>> = ({
	currentPage = 1,
	count = 30,
	totalCount,
	hasNext,
	hasPrev,
	fetchNext,
	fetchPrev,
	fetchPage,
}) => {
	const { fullPageTable } = useFullScreenTableContext();
	const { inModal } = useModalContext();
	const { setHasPagination } = useAppState();
	const currentCount = currentPage * count;

	const toAmount =
		totalCount > 0 && currentCount > totalCount ? totalCount : currentCount;

	const fromAmount = currentCount - count + 1;

	const totalPages: number = Math.ceil(totalCount / count);

	const handlePaginateByNumber = (page: number) => fetchPage(page);

	useComponentDidMount(
		() => {
			if (!inModal) {
				setHasPagination(true);
			}
		},
		() => {
			if (!inModal) {
				setHasPagination(false);
			}
		}
	);

	return (
		<Wrapper fullPageTable={fullPageTable && !inModal}>
			<Count>
				<div>
					{totalCount > 0 ? (
						<CountAmount>
							{t(`Showing`)} <span>{fromAmount}</span> {t(`To`).toLowerCase()}{' '}
							<span>{toAmount}</span>
						</CountAmount>
					) : (
						<CountAmount>
							<span>0</span> {t(`Results`).toLocaleLowerCase()}
						</CountAmount>
					)}
					{totalCount > 0 && (
						<TotalCount>
							{' '}
							{t(`Of`).toLowerCase()} {totalCount} {t(`Results`).toLowerCase()}
						</TotalCount>
					)}
				</div>
			</Count>
			<Paginate>
				{hasPrev && (
					<DirectionStep onClick={fetchPrev}>
						<Icon name="chevron" rotate="180deg" />
					</DirectionStep>
				)}
				{totalPages > 1 && (
					<NumberSteps
						totalPages={totalPages}
						currentPage={currentPage}
						onStep={handlePaginateByNumber}
					/>
				)}
				{hasNext && (
					<DirectionStep onClick={fetchNext}>
						<Icon name="chevron" />
					</DirectionStep>
				)}
			</Paginate>
		</Wrapper>
	);
};

export default Pagination;
