const icon = {
	name: 'wall',
	viewBox: '0 0 25 24',
	objects: [
		{
			type: 'path',
			attributes: {
				d: 'M8.5 11C9.88071 11 11 9.88071 11 8.5C11 7.11929 9.88071 6 8.5 6C7.11929 6 6 7.11929 6 8.5C6 9.88071 7.11929 11 8.5 11ZM8.5 9.7C9.16274 9.7 9.7 9.16274 9.7 8.5C9.7 7.83726 9.16274 7.3 8.5 7.3C7.83726 7.3 7.3 7.83726 7.3 8.5C7.3 9.16274 7.83726 9.7 8.5 9.7Z',
				fillRule: 'evenodd',
				clipRule: 'evenodd',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M11 15.5C11 16.8807 9.88071 18 8.5 18C7.11929 18 6 16.8807 6 15.5C6 14.1193 7.11929 13 8.5 13C9.88071 13 11 14.1193 11 15.5ZM9.7 15.5C9.7 16.1627 9.16274 16.7 8.5 16.7C7.83726 16.7 7.3 16.1627 7.3 15.5C7.3 14.8373 7.83726 14.3 8.5 14.3C9.16274 14.3 9.7 14.8373 9.7 15.5Z',
				fillRule: 'evenodd',
				clipRule: 'evenodd',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M12.3501 7.00001C12.3501 6.64102 12.6411 6.35001 13.0001 6.35001L17.0001 6.35001C17.3591 6.35001 17.6501 6.64102 17.6501 7.00001C17.6501 7.35899 17.3591 7.65001 17.0001 7.65001H13.0001C12.6411 7.65001 12.3501 7.35899 12.3501 7.00001Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M13.0001 13.35C12.6411 13.35 12.3501 13.641 12.3501 14C12.3501 14.359 12.6411 14.65 13.0001 14.65H17.0001C17.3591 14.65 17.6501 14.359 17.6501 14C17.6501 13.641 17.3591 13.35 17.0001 13.35H13.0001Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M12.3501 9.00001C12.3501 8.64102 12.6411 8.35001 13.0001 8.35001H15.0001C15.3591 8.35001 15.6501 8.64102 15.6501 9.00001C15.6501 9.35899 15.3591 9.65001 15.0001 9.65001H13.0001C12.6411 9.65001 12.3501 9.35899 12.3501 9.00001Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M13.0001 15.35C12.6411 15.35 12.3501 15.641 12.3501 16C12.3501 16.359 12.6411 16.65 13.0001 16.65H15.0001C15.3591 16.65 15.6501 16.359 15.6501 16C15.6501 15.641 15.3591 15.35 15.0001 15.35H13.0001Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M6 3C4.34315 3 3 4.34315 3 6V18C3 19.6569 4.34315 21 6 21H18C19.6569 21 21 19.6569 21 18V6C21 4.34315 19.6569 3 18 3H6ZM18 4.3H6C5.06112 4.3 4.3 5.06112 4.3 6V18C4.3 18.9389 5.06112 19.7 6 19.7H18C18.9389 19.7 19.7 18.9389 19.7 18V6C19.7 5.06112 18.9389 4.3 18 4.3Z',
				fillRule: 'evenodd',
				clipRule: 'evenodd',
			},
		},
	],
};

export default icon;
