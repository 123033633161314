import { ReactNode } from 'react';

export const symbol_panorama: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm0-60h680v-520H140v520Zm306-125-80-112q-5-6-12-6.5t-12 5.5l-87 114q-6 8-1.5 16t13.5 8h427q8.5 0 12.75-8t-.75-16L589-503q-5-6-12-6t-12 6L446-345ZM140-220v-520 520Z"
		/>
	</svg>
);
