import { useT } from '@transifex/react';

import spacing from 'pkg/config/spacing';

import { EventFlags } from 'pkg/api/models/event';

import Column from 'components/layout/column';
import * as Input from 'components/form/inputs';

import * as css from './styles.css';

interface AutoInviteProps {
	flags: EventFlags[];

	handleChange: (name: string) => void;
}

export default function AutoInvite({ flags, handleChange }: AutoInviteProps) {
	const t = useT();

	const onChange = (
		value: string | number | readonly string[],
		target?: HTMLInputElement
	) => {
		handleChange(target.name);
	};

	return (
		<Input.Group label={t('Auto-add new group members')}>
			<Column spacing={spacing._2}>
				<Input.Control
					name={EventFlags.EventFlagsAutoInviteAdminAndStaff}
					type="checkbox"
					label={t('Add new admins/staff as organizers to this event')}
					onChange={onChange}
					defaultChecked={flags.includes(
						EventFlags.EventFlagsAutoInviteAdminAndStaff
					)}
					className={css.control}
				/>
				<Input.Control
					name={EventFlags.EventFlagsAutoInviteUsers}
					type="checkbox"
					label={t('Add new players as participants to this event')}
					onChange={onChange}
					defaultChecked={flags.includes(EventFlags.EventFlagsAutoInviteUsers)}
					className={css.control}
				/>
			</Column>
		</Input.Group>
	);
}
