import { ReactNode } from 'react';

export const symbol_mic: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-423q-43 0-72-30.917-29-30.916-29-75.083v-251q0-41.667 29.441-70.833Q437.882-880 479.941-880t71.559 29.167Q581-821.667 581-780v251q0 44.167-29 75.083Q523-423 480-423Zm0-228Zm-30 501v-106q-96-11-166.5-77.5T202-498q-2-12.854 6.593-21.927 8.592-9.073 21.5-9.073 12.907 0 21.407 9t10.5 22q12 81 74.711 132.5 62.71 51.5 142.935 51.5Q561-314 623.5-365.5 686-417 698-498q2-13 10.675-22 8.676-9 21.5-9 12.825 0 21.325 9.073T758-498q-11 97-81 164t-167 78v106q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T450-150Zm30-333q18 0 29.5-13.5T521-529v-251q0-17-11.788-28.5Q497.425-820 480-820q-17.425 0-29.212 11.5Q439-797 439-780v251q0 19 11.5 32.5T480-483Z"
		/>
	</svg>
);
