import { ReactNode } from 'react';

export const symbol_finance: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-120q-24 0-42-18t-18-42v-630q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T180-810v630h630q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-120H180Zm104.657-135Q272-255 263.5-263.625T255-285v-274q0-12.75 8.562-21.375T284.781-589h59.562q12.657 0 21.157 8.625T374-559v274q0 12.75-8.562 21.375T344.219-255h-59.562Zm198 0Q470-255 461.5-263.625T453-285v-480q0-12.75 8.562-21.375T482.781-795h59.562q12.657 0 21.157 8.625T572-765v480q0 12.75-8.562 21.375T542.219-255h-59.562Zm194 0Q664-255 655.5-263.625T647-285v-110q0-12.75 8.562-21.375T676.781-425h59.562q12.657 0 21.157 8.625T766-395v110q0 12.75-8.562 21.375T736.219-255h-59.562Z"
		/>
	</svg>
);
