import { InputHTMLAttributes, memo } from 'react';
import styled from 'styled-components';

import * as Input from 'components/form/inputs';

interface RadioProps
	extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
	description?: string;
	value?: string | number | readonly string[];
	label?: string;
	readOnly?: boolean;
	testid?: string;
	onChange?: (value: string | number | readonly string[]) => void;
}

const RadioButton = memo((props: RadioProps) => (
	<Input.Control
		type="radio"
		value={props.value}
		label={props.label}
		description={props.description}
		standalone={!props.label && !props.description}
		checked={props.checked}
		testid={props.testid}
		onChange={props.onChange}
	/>
));

export default styled(RadioButton)``;
