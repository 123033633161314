import { ReactNode } from 'react';

export const symbol_person_check: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m702-579 149-149q8.8-9 20.9-9 12.1 0 21.1 9.053 9 9.052 9 21.5Q902-694 893-685L723-515q-9 9-21 9t-21-9l-85-85q-9-9.067-9-21.533Q587-634 596-643q9-9 21-9t21 9l64 64Zm-342 98q-66 0-108-42t-42-108q0-66 42-108t108-42q66 0 108 42t42 108q0 66-42 108t-108 42ZM40-220v-34q0-35 17.5-63.5T108-360q75-33 133.338-46.5 58.339-13.5 118.5-13.5Q420-420 478-406.5 536-393 611-360q33 15 51 43t18 63v34q0 24.75-17.625 42.375T620-160H100q-24.75 0-42.375-17.625T40-220Zm60 0h520v-34q0-16-9-30.5T587-306q-71-33-120-43.5T360-360q-58 0-107.5 10.5T132-306q-15 7-23.5 21.5T100-254v34Zm260-321q39 0 64.5-25.5T450-631q0-39-25.5-64.5T360-721q-39 0-64.5 25.5T270-631q0 39 25.5 64.5T360-541Zm0 251Zm0-341Z"
		/>
	</svg>
);
