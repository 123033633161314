import { FC, Fragment } from 'react';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';

import EventUsers from 'routes/event/single/EventUsers';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import * as LargeScreenContent from 'components/layout/LargeScreenContent';

interface EventParticipantsProps {
	event: models.event.Event;
}

const EventParticipants: FC<EventParticipantsProps> = ({ event }) => {
	return (
		<Fragment>
			<LargeScreen>
				<LargeScreenContent.Inner maxWidth={styles.size.PageWidths.STANDARD}>
					<EventUsers event={event} />
				</LargeScreenContent.Inner>
			</LargeScreen>

			<SmallScreen>
				<LargeScreenContent.Inner>
					<EventUsers event={event} />
				</LargeScreenContent.Inner>
			</SmallScreen>
		</Fragment>
	);
};

export default EventParticipants;
