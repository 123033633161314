import { ReactNode } from 'react';

export const symbol_highlighter_size_4: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M353-127 127-353q-9-9-9-21.5t9-21.5l437-437q9-9 21-9t21 9l227 226q9 9 9 21.5t-9 21.5L396-127q-9 9-21.5 9t-21.5-9Z"
		/>
	</svg>
);
