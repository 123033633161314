import { ReactNode } from 'react';

export const symbol_leak_remove: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M785-76 617-245q-13 23-20.5 47.5T586-150q-2 13-11 21.5t-21 8.5q-13 0-22-9t-7-21q3-35 15.5-72.5T573-289l-43-43q-26 38-43.5 83T465-150q-1 13-9.5 21.5T434-120q-13 0-21.5-9t-7.5-21q5-64 26-122t56-103L375-487q-48 36-104.5 56.5T150-405q-12 1-21-7.5t-9-21.5q0-12 8.5-21t21.5-10q52-4 98-21.5t84-43.5l-43-43q-26 19-63.5 31.5T150-525q-12 1-21-7.5t-9-21.5q0-12 8.5-21t21.5-11q28-3 51.5-11.5T245-617L78-784q-9-9-9-21t9-21q9-9 21.5-9t21.5 9l707 706q9 9 9 22t-9 22q-9 9-21.5 9T785-76ZM406-840q13 0 22 9t7 21q-5 47-21 87.5T377-656l-43-43q15-25 25.5-52t14.5-59q2-13 11-21.5t21-8.5Zm120 0q13 0 21.5 9t7.5 21q-5 73-30.5 132.5T463-569l-44-44q31-38 51-89t25-108q1-13 9.5-21.5T526-840Zm314 314q0 12-8.5 21.5T810-494q-60 5-107 24t-90 50l-43-43q49-38 107.5-62.5T810-555q12-1 21 7.5t9 21.5ZM700-333l-44-44q32-23 70.5-37.5T810-434q12-2 21 6.5t9 21.5q0 12-8.5 21T810-374q-33 4-61 15t-49 26Z"
		/>
	</svg>
);
