import { ReactNode } from 'react';

export const symbol_fit_screen: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M820-640v-100H720q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T720-800h100q24 0 42 18t18 42v100q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T820-640Zm-740 0v-100q0-24 18-42t42-18h100q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T240-740H140v100q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Q97-610 88.5-618.625T80-640Zm740 480H720q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T720-220h100v-100q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T880-320v100q0 24-18 42t-42 18Zm-680 0q-24 0-42-18t-18-42v-100q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-320v100h100q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T240-160H140Zm60-180v-280q0-24.75 17.625-42.375T260-680h440q24.75 0 42.375 17.625T760-620v280q0 24.75-17.625 42.375T700-280H260q-24.75 0-42.375-17.625T200-340Zm60 0h440v-280H260v280Zm0 0v-280 280Z"
		/>
	</svg>
);
