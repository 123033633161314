import Session from 'pkg/models/session';

import * as sdk from 'pkg/core/sdk';
import * as endpoints from 'pkg/api/endpoints/auto';

export interface UpdateSession {
	description?: string;
	eventId?: number;
	playerVisible?: boolean;
	tags?: string[];
	title?: string;
	userId?: number;
}

export const fetchSession = (
	organizationId: number,
	sessionId: number
): Promise<Response> =>
	sdk.get(endpoints.TrainingSessions.Show(organizationId, sessionId));

export const createSession = (
	organizationId: number,
	payload: Session
): Promise<Response> =>
	sdk.post(endpoints.TrainingSessions.Create(organizationId), {}, payload);

export const updateSession = (
	organizationId: number,
	sessionId: number,
	payload: UpdateSession
): Promise<Response> =>
	sdk.patch(
		endpoints.TrainingSessions.Update(organizationId, sessionId),
		{},
		payload
	);

export const deleteSession = (
	organizationId: number,
	sessionId: number
): Promise<Response> =>
	sdk.destroy(endpoints.TrainingSessions.Delete(organizationId, sessionId));

export const deleteSessionTag = (
	organizationId: number,
	sessionId: number,
	tagId: number
): Promise<Response> =>
	sdk.destroy(
		endpoints.TrainingSessions.RemoveTag(organizationId, sessionId, tagId)
	);

interface SessionItemUsers {
	users: number[];
}

export const setSessionItemUsers = (
	organizationId: number,
	sessionItemId: number,
	payload: SessionItemUsers
): Promise<Response> =>
	sdk.put(
		endpoints.TrainingSessions.SetItemUsers(organizationId, sessionItemId),
		{},
		payload
	);
