import { Fragment, useState } from 'react';
import { t } from '@transifex/native';

import { useCurrentMembership, useCurrentOrganization } from 'pkg/identity';
import { useCurrentRoute } from 'pkg/router/hooks';
import { useEndpoint } from 'pkg/api/use_endpoint';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import useMixedState from 'pkg/hooks/useMixedState';
import { pushState } from 'pkg/router/state';
import * as routes from 'pkg/router/routes';
import useConfirm from 'pkg/hooks/useConfirm';
import * as actions from 'pkg/actions';

import { useSmallScreen } from 'components/MediaQuery';

import AddToCollectionModal from 'components/training-library/modals/AddToCollection';
import Group, { GroupWrapperProperties } from 'components/library/Group';
import ExerciseGroupItem from 'components/library/group-items/Exercise';
import { GroupItemAction } from 'components/library/GroupItem';
import { duplicationConfirmString } from 'components/training/exercise/utils';
import ExerciseDuplicationModal from 'components/training/exercise/ExerciseDuplicationModal';

import Button from 'design/button';

import * as css from './styles.css';

export default function Exercises({
	title,
	items,
	isLoading,
	hasNext,
	fetchNext,
	removeRecord,
}: GroupWrapperProperties<models.exercise.Exercise>): JSX.Element {
	const route = useCurrentRoute();
	const membership = useCurrentMembership();
	const org = useCurrentOrganization();

	const [modals, setModal] = useMixedState<{
		exercise: number;
		collection: number;
	}>({
		exercise: 0,
		collection: 0,
	});

	const handleAddToCollection = (exercise: models.exercise.Exercise) => {
		setModal({ collection: exercise.id });
	};

	const handleRemoveFromCollection = (exercise: models.exercise.Exercise) => {
		if (route?.collectionId && exercise.id) {
			models.trainingCollection.removeExercise(
				org.id,
				route.collectionId,
				exercise.id
			);

			removeRecord(exercise.id);
		}
	};

	const { record: collection } =
		useEndpoint<models.trainingCollection.TrainingCollection>(
			route?.collectionId
				? endpoints.TrainingCollections.Show(org.id, route?.collectionId)
				: ''
		);

	const closeModal = () => setModal({ exercise: 0, collection: 0 });

	const [isDuplicating, setIsDuplicating] = useState<boolean>(false);

	const isSmallScreen = useSmallScreen();

	if (items.length === 0) {
		return null;
	}

	return (
		<Fragment>
			{isDuplicating && <ExerciseDuplicationModal />}

			<Group title={title} icon="tactic" context="training-library">
				{items.map((item: models.exercise.Exercise, n: number) => (
					<ExerciseItem
						key={`${item.id}:${n}`}
						item={item}
						setIsDuplicating={setIsDuplicating}
						membership={membership}
						handleAddToCollection={handleAddToCollection}
						collection={collection}
						handleRemoveFromCollection={handleRemoveFromCollection}
						removeRecord={removeRecord}
					/>
				))}
			</Group>
			{hasNext && fetchNext && (
				<div className={css.divider}>
					<Button
						block={isSmallScreen}
						label={t('Load more')}
						busy={isLoading}
						onClick={fetchNext}
					/>
				</div>
			)}
			{modals.collection !== 0 && (
				<AddToCollectionModal
					exerciseId={modals.collection}
					onClose={closeModal}
				/>
			)}
		</Fragment>
	);
}

interface ExerciseItemProps {
	item: models.exercise.Exercise;
	setIsDuplicating: (value: boolean) => void;
	membership: models.membership.Membership;
	handleAddToCollection: (exercise: models.exercise.Exercise) => void;
	collection: models.trainingCollection.TrainingCollection;
	handleRemoveFromCollection: (exercise: models.exercise.Exercise) => void;
	removeRecord: (recordId: number) => void;
}

const ExerciseItem = ({
	item,
	setIsDuplicating,
	membership,
	handleAddToCollection,
	collection,
	handleRemoveFromCollection,
	removeRecord,
}: ExerciseItemProps) => {
	const org = useCurrentOrganization();
	const exerciseActions: GroupItemAction<models.exercise.Exercise>[] = [];

	const handleEdit = () => {
		pushState(routes.Exercise.Edit(org.id, item.id));
	};

	const handleDuplicate = useConfirm({
		hideOnBusy: true,
		message: duplicationConfirmString,
		onConfirm: async () => {
			setIsDuplicating(true);

			const newExercise = await actions.exercises.duplicateExercise(item.id);

			if (newExercise) {
				pushState(routes.Exercise.Edit(org.id, newExercise.id));
			}

			setIsDuplicating(false);
			return true;
		},
	});

	if (models.canEdit(item)) {
		exerciseActions.push({
			icon: 'edit',
			label: t('Edit exercise', { _context: 'training-library' }),
			onClick: handleEdit,
		});
	}

	exerciseActions.push({
		icon: 'file_copy',
		label: t('Duplicate'),
		onClick: handleDuplicate,
	});

	if (!models.membership.isParent(membership)) {
		exerciseActions.push({
			icon: 'create_new_folder',
			label: t('Add to collection', { _context: 'training-library' }),
			onClick: handleAddToCollection,
		});
	}

	if (collection?.id) {
		exerciseActions.push({
			icon: 'cancel',
			label: t('Remove from collection', {
				_context: 'training-library',
			}),
			onClick: handleRemoveFromCollection,
		});
	}

	if (models.canDelete(item)) {
		exerciseActions.push({
			icon: 'delete',
			label: t('Delete', {
				_context: 'training-library',
			}),
			confirm: t(
				'Do you want to delete {name}? This action cannot be undone.',
				{
					name: item.title,
				}
			),
			onClick: async () => {
				const success = await models.destroy(item);

				if (success) {
					removeRecord(item.id);
				}
			},
		});
	}

	return <ExerciseGroupItem item={item} actions={exerciseActions} />;
};
