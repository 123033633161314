import { ReactNode } from 'react';

export const symbol_youtube_activity: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M168-257q-26-7-45-26t-26-45q-6-23-9.5-56T82-447q-2-36-2-73t2-73q2-30 5.5-63t9.5-56q7-26 26-45t45-26q23-6 74.5-9.5T347-798q62-2 133-2t133 2q53 2 104.5 5.5T792-783q26 7 45 26t26 45q10 37 13 81.5t4 86.5q0 15-12 23.5t-26 4.5q-11-2-21-3t-21-1q-83 0-141.5 58.5T600-320q0 11 1.5 21.5T605-277q3 14-4.5 25T579-241q-21 0-39 .5t-31 .5h-29q-71 0-133-2-53-2-104.5-5.5T168-257Zm254-156 163-94q8-5 8-13t-8-13l-163-94q-8-5-15-.5t-7 13.5v188q0 9 7 13.5t15-.5Zm338 133h-40q-17 0-28.5-11.5T680-320q0-17 11.5-28.5T720-360h40v-40q0-17 11.5-28.5T800-440q17 0 28.5 11.5T840-400v40h40q17 0 28.5 11.5T920-320q0 17-11.5 28.5T880-280h-40v40q0 17-11.5 28.5T800-200q-17 0-28.5-11.5T760-240v-40Z"
		/>
	</svg>
);
