import { ReactNode } from 'react';

export const symbol_format_h1: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M229.825-280Q217-280 208.5-288.625T200-310v-340q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T260-650v140h180v-140q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T500-650v340q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T440-310v-140H260v140q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Zm500 0Q717-280 708.5-288.625T700-310v-310h-50q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T650-680h80q12.75 0 21.375 8.625T760-650v340q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Z"
		/>
	</svg>
);
