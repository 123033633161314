import { useQueryState } from 'pkg/hooks/query-state';

export function useReturnUrlOrFallback(fallbackUrl: string): string {
	const qs = useQueryState();

	if (qs.has('returnUrl')) {
		return qs.get('returnUrl');
	}

	return fallbackUrl;
}
