import { ReactNode } from 'react';

export const symbol_select_window_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M812-63 407-467H140v327h506v-174l60 60v114q0 24.75-17.625 42.375T646-80H140q-24.75 0-42.375-17.625T80-140v-387q0-24.75 17.625-42.375T140-587h115v-32L47-827q-9-9.067-9-21.533Q38-861 47.053-870q9.052-9 21.5-9Q81-879 90-870l765 765q9 9 9 21t-9.053 21q-9.052 9-21.5 9Q821-54 812-63Zm68-757v387q0 24.75-17.625 42.375T820-373H698q-12.444 0-23.722-5T655-391L510-536q-7-7-8-15.5t2-16.5q3-8 9.75-13.5T531-587h101q32 0 53 21t21 53v80h114v-327H311q-12.444 0-23.722-5t-19.345-13.067l-50.6-50.6Q210-836 209-844.5t2-16.5q3-8 9.75-13.5T238-880h582q24.75 0 42.375 17.625T880-820Z"
		/>
	</svg>
);
