import { t } from '@transifex/native';
import styled from 'styled-components';
import { MouseEvent, ReactNode, useState } from 'react';

import * as styles from 'pkg/config/styles';

import rgba from 'pkg/rgba';

import Icon from 'components/icon';

import Row from 'components/layout/row';

import Button from 'design/button';
import * as Context from 'design/context_menu';

import * as css from './styles.css';

const Label = styled.div`
	font-weight: var(--font-weight-semibold);
`;
interface TitleRowProps {
	active?: boolean;
}

const TitleRow = styled(Context.Pane)<TitleRowProps>`
	cursor: pointer;
	position: relative;
	color: ${({ active }) =>
		active
			? rgba(styles.palette.main.actionableLinkColor)
			: styles.palette.black};
`;

interface FilterGroupProps {
	label: string;
	isActive: boolean;
	onClearFilter?: (e: MouseEvent<HTMLElement>) => void;
	children: ReactNode;
}

const FilterGroup = ({
	label,
	isActive = false,
	onClearFilter,
	children,
}: FilterGroupProps) => {
	const [isOpen, setIsOpen] = useState(isActive);

	const toggleOpen = () => {
		setIsOpen(!isOpen);
	};

	const handleClear = (event: MouseEvent<HTMLDivElement>) => {
		event.preventDefault();
		event.stopPropagation();

		onClearFilter(event);
		setIsOpen(false);
	};

	return (
		<div className={css.filterGroup}>
			<TitleRow active={isActive} onClick={toggleOpen}>
				<Row
					autoColumns="auto auto"
					align="center"
					justifyContent="space-between">
					<Label>{label}</Label>
					<Row autoColumns="auto auto" align="center">
						{isActive && (
							<Button
								className={css.clearButton}
								label={t('Clear')}
								onClick={handleClear}
								inline
								small
							/>
						)}
						<Icon name={isOpen ? 'chevron-down' : 'chevron'} size={2} />
					</Row>
				</Row>
			</TitleRow>

			{isOpen && (
				<Context.Pane padding="0 var(--spacing-5) var(--spacing-3) var(--spacing-5)">
					{children}
				</Context.Pane>
			)}
		</div>
	);
};

export default FilterGroup;
