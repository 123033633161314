import { ReactNode } from 'react';

export const symbol_man_2: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M428-110v-250h-58q-12.75 0-21.375-8.625T340-390v-223q0-24.75 17.625-42.375T400-673h160q24.75 0 42.375 17.625T620-613v223q0 12.75-8.625 21.375T590-360h-58v250q0 12.75-8.625 21.375T502-80h-44q-12.75 0-21.375-8.625T428-110Zm52.08-624q-30.08 0-51.58-21.42-21.5-21.421-21.5-51.5 0-30.08 21.42-51.58 21.421-21.5 51.5-21.5 30.08 0 51.58 21.42 21.5 21.421 21.5 51.5 0 30.08-21.42 51.58-21.421 21.5-51.5 21.5Z"
		/>
	</svg>
);
