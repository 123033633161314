import { useDispatch } from 'react-redux';
import { Fragment } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import { StartLoginStateFlows } from 'pkg/actions/sso';
import { CreateAccountResponse } from 'pkg/actions/accounts';

import useConfirm from 'pkg/hooks/useConfirm';
import * as actions from 'pkg/actions';
import {
	Account,
	AccountLoginMethod,
	Group,
} from 'pkg/api/models/onboarding_info';
import { useCurrentRoute } from 'pkg/router/hooks';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { MembershipRole } from 'pkg/api/models/membership';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as language from 'pkg/i18n/language';
import * as api from 'pkg/api';
import * as models from 'pkg/api/models';
import Link from 'pkg/router/Link';

import { useOnboardingContext } from 'routes/public/onboarding';
import { ConnectResponse, useConnectWithApple } from 'routes/public/connect';
import Button from 'routes/public/styles/Button';
import Title from 'routes/public/styles/Title';
import FooterNote from 'routes/public/styles/FooterNote';
import CancelButton from 'routes/public/styles/CancelButton';
import {
	useOnboardingAccountPayload,
	useOnboardingState,
} from 'routes/public/hooks';
import * as publicStyles from 'routes/public/styles/styles.css';

import Column from 'components/layout/column';

type CreateAccountAction = (appleAuthCode: string) => Promise<void>;

function useCreateAccountAction(): CreateAccountAction {
	const dispatch = useDispatch();
	const onboardingState = useOnboardingState();
	const groupFromState = onboardingState.get<Group>('group');
	const { goTo } = useOnboardingContext();
	const getPayload = useOnboardingAccountPayload();

	return async (appleAuthCode: string) => {
		const accountFromState = onboardingState.get<Account>('account');

		const accountPayload: Account = {
			...onboardingState.get('account'),
			appleAuthCode,
		};

		await onboardingState.setAccount(accountPayload);

		const accountRole = onboardingState.get<Account>('account')?.role;
		const parentalConsentEmail = onboardingState.get<string>(
			'parentalConsentEmail'
		);

		let canContinue: boolean;

		try {
			const [request, response] = await api
				.acceptLanguage<CreateAccountResponse, any>(language.get())
				.post(endpoints.Accounts.Create(), {
					...getPayload(),
					appleAuthCode,
				});

			if (!request.ok) {
				throw new Error(`${request.status}`);
			}

			await actions.auth.updateAuthToken(
				{
					accountToken: response.authToken,
					orgToken: response.orgToken,
				},
				groupFromState.organizationId
			);

			if (parentalConsentEmail) {
				const req = await dispatch(
					actions.accounts.requestParentalConsent(parentalConsentEmail)
				);

				if (!req) {
					canContinue = false;

					throw new Error('500');
				}
			}

			if (
				accountRole !== 'parent' &&
				accountRole !== undefined &&
				!parentalConsentEmail
			) {
				const groupCode = onboardingState.get<string>('groupCode');

				const role =
					accountRole === 'staff' ? MembershipRole.Staff : MembershipRole.User;

				const [joinRequest] = await api.post(
					endpoints.Groups.JoinFromInviteCode(),
					{
						code: groupCode,
						role,
					}
				);

				if (!joinRequest.ok) {
					throw new Error(`${joinRequest.status}`);
				}
			}

			canContinue = true;
		} catch (error: unknown) {
			canContinue = false;

			const status = Number.parseInt((error as Error).message, 10);

			let title = t('Something went wrong');
			let message = t(
				"If you think this error isn't supposed to happen, please contact 360Player support."
			);

			switch (status) {
				case 400:
					title = t('Could not create account');
					message = t(
						'You can try again, if the problem still persists please contact our customer support'
					);
					break;
				case 409:
					title = t('Email already in use');
					message = t('This email is already registered in 360Player');
					break;
			}

			actions.flashes.show({
				title,
				message,
			});

			return;
		} finally {
			if (!canContinue) return;

			if (parentalConsentEmail) {
				goTo('gdpr-request-pending');
				return;
			}

			if (accountFromState.role === 'player') {
				goTo('player-finished');
				return;
			}

			if (accountFromState.role === 'staff') {
				goTo('staff-finished');
				return;
			}

			if (accountFromState.role === 'parent') {
				goTo('create-ward-account');
				return;
			}
		}
	};
}

export default function CreateAccount(): JSX.Element {
	const { goTo, overrideBackSlug } = useOnboardingContext();
	const onboardingState = useOnboardingState();
	const createAction = useCreateAccountAction();

	const route = useCurrentRoute();
	const appleToken = route.query?.code || '';
	const inviteKey = onboardingState.get<string>('inviteKey');

	const signingUpAsParent =
		onboardingState.get<Account>('account').role === 'parent';
	const identityProviders =
		onboardingState.get<models.identityProvider.ScrubbedIdentityProvider[]>(
			'identityProviders'
		);

	const nextStep = signingUpAsParent
		? 'create-ward-account'
		: 'account-details';

	const normalizeOnboardingState = (
		response: ConnectResponse
	): Partial<Account> => {
		const data: Partial<Account> = {};

		if (response.firstName) {
			data.firstName = response.firstName;
		}

		if (response.lastName) {
			data.lastName = response.lastName;
		}

		if (response.email) {
			data.email = response.email;
		}

		return data;
	};

	const apple = useConnectWithApple({
		success: (token: string, response: ConnectResponse) => {
			onboardingState.setAccount({
				...normalizeOnboardingState(response),
				appleAuthCode: token,
				loginType: AccountLoginMethod.Apple,
			});

			createAction(token);
		},
		failure: () => {
			actions.flashes.show({
				title: t('Could not create account'),
				message: t('Could not connect using Apple'),
			});
		},
	});

	useComponentDidMount(() => {
		if (inviteKey) {
			overrideBackSlug('user-information');
		}

		if (appleToken) {
			try {
				apple.emitSuccess(appleToken, {});

				goTo(nextStep);
			} catch {
				apple.emitFailure();
			}
		}
	});

	const continueWithEmail = () => {
		onboardingState.setAccount({ loginType: AccountLoginMethod.Email });
		goTo('account-details');
	};

	const continueWithApple = () => apple.signup();

	const handleLogin = useConfirm({
		message: t(
			'You are about to exit the onboarding and all progress will be lost. Are you sure?'
		),
		confirmLabel: t("Yes, I'm sure"),
		onConfirm: () => {
			goTo('login');
		},
	});

	const handleIdpClick = (
		identityProvider: models.identityProvider.ScrubbedIdentityProvider
	) => {
		actions.sso.startLogin({
			identityProvider,
			state: {
				nextSlug: 'account-details',
				flow: StartLoginStateFlows.Registration,
			},
		});
	};

	return (
		<Fragment>
			<Column spacing={styles.spacing._8} className={publicStyles.main}>
				<Title title={t('How do you want to create your account?')} />

				<Column>
					<Button
						label={t('Sign up with email')}
						onClick={continueWithEmail}
						testid="onboarding.signup.email_button"
					/>
					{identityProviders?.map((idp) => (
						<Button
							key={idp.id}
							label={t('Continue with {displayName}', {
								displayName: idp.displayName,
							})}
							onClick={() => handleIdpClick(idp)}
						/>
					))}
					<Button
						apple
						label={t('Sign up with Apple')}
						busy={apple.isLoading}
						onClick={continueWithApple}
					/>
				</Column>

				<FooterNote>
					<Link onClick={handleLogin}>{t('Already have an account?')}</Link>
				</FooterNote>
			</Column>

			<div className={publicStyles.footer}>
				<CancelButton />
			</div>
		</Fragment>
	);
}
