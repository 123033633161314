import { ReactNode } from 'react';

export const symbol_flash_on: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m460-272 186-268H520l81-280H340v360h120v188Zm251-257L441-139q-5 7-11.5 8.5t-13.4-.656q-6.9-2.156-11.5-7.547T400-152v-248h-60q-24.75 0-42.375-17.625T280-460v-360q0-24.75 17.625-42.375T340-880h278q23 0 38 18.5t8 41.5l-64 220h74q26.873 0 39.937 24Q727-552 711-529Zm-251 69H340h120Z"
		/>
	</svg>
);
