import { ReactNode } from 'react';

export const symbol_reopen_window: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v270q0 13-8.5 21.5T850-440q-13 0-21.5-8.5T820-470v-186H140v436h310q13 0 21.5 8.5T480-190q0 13-8.5 21.5T450-160H140ZM760 0q-57 0-104-29t-73-78q-7-13-3-25.5t17-17.5q11-4 22.5 0t16.5 15q18 34 51 54.5T760-60q58 0 99-41t41-99q0-58-41-99t-99-41q-28 0-53 10t-45 30h28q13 0 21.5 8.5T720-270q0 13-8.5 21.5T690-240H590q-13 0-21.5-8.5T560-270v-100q0-13 8.5-21.5T590-400q13 0 21.5 8.5T620-370v27q29-27 65-42t75-15q83 0 141.5 58.5T960-200q0 83-58.5 141.5T760 0Z"
		/>
	</svg>
);
