export function CreateBatch(): string {
	return `/v1/orders/batch`;
}

export function Delete(): string {
	return `/v1/orders`;
}

export function EventLog(ID: number): string {
	return `/v1/orders/${ID}/event-log`;
}

export function ExportItemizedOrders(): string {
	return `/v1/orders/export-itemized`;
}

export function ExportOrders(): string {
	return `/v1/orders/export`;
}

export function ExportTransactions(): string {
	return `/v1/orders/export-transactions`;
}

export function Index(): string {
	return `/v1/orders`;
}

export function ListAccountAndWardsOrders(): string {
	return `/v1/accounts/self/orders`;
}

export function ListUserAndWardsOrders(): string {
	return `/v1/users/self/orders`;
}

export function MarkPaid(): string {
	return `/v1/orders/mark-paid`;
}

export function MarkUncollectible(): string {
	return `/v1/orders/mark-uncollectible`;
}

export function MarkVoid(): string {
	return `/v1/orders/void`;
}

export function Refund(orderID: number): string {
	return `/v1/orders/${orderID}/refund`;
}

export function Send(orderID: number): string {
	return `/v1/orders/${orderID}/send`;
}

export function SendInvoice(): string {
	return `/v1/orders/send`;
}

export function SendReceipt(): string {
	return `/v1/orders/send-receipt`;
}

export function SendReminder(): string {
	return `/v1/orders/send-reminder`;
}

export function Show(ID: number): string {
	return `/v1/orders/${ID}`;
}
