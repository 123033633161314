import { ReactNode } from 'react';

export const symbol_electric_car: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M200-364v44q0 16.667-11.735 28.333Q176.529-280 159.765-280 143-280 131.5-291.667 120-303.333 120-320v-304q0-4.667.5-9.333Q121-638 123-643l78-236q6-19 21.75-30T258-920h444q19.5 0 35.25 11T759-879l78 236q2 5 2.5 9.667.5 4.666.5 9.333v304q0 16.667-11.735 28.333Q816.529-280 799.765-280 783-280 771-291.958 759-303.917 759-321v-43H200Zm3-330h554l-55-166H258l-55 166Zm-23 60v210-210Zm105.765 160Q309-474 324.5-489.75T340-528q0-23.333-15.75-39.667Q308.5-584 286-584q-23.333 0-39.667 16.265Q230-551.471 230-528.235 230-505 246.265-489.5q16.264 15.5 39.5 15.5ZM675-474q23.333 0 39.667-15.75Q731-505.5 731-528q0-23.333-16.265-39.667Q698.471-584 675.235-584 652-584 636.5-567.735q-15.5 16.264-15.5 39.5Q621-505 636.75-489.5T675-474ZM516-120v55q0 8.13-7 12.565Q502-48 494-52l-175-92q-5-3-3.909-8.5 1.091-5.5 6.909-5.5h118v-52q0-8.13 7-12.565 7-4.435 15-.435l178 89q5 2.947 3.5 8.474Q642-120 636-120H516ZM180-424h600v-210H180v210Z"
		/>
	</svg>
);
