import { ReactNode } from 'react';

export const symbol_shoppingmode: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M446.75-80q-11.25 0-22.5-4.25T404-97L98-404q-9-8-13.5-19.5T80-446.25q0-11.25 4.304-22.5Q88.61-480 98-489l373-373q8.298-8.25 19.614-13.125T514-880h307q24.75 0 42.375 17.625T881-820v306q0 12.089-5 23.044Q871-480 863-472L489-97q-9 8-20 12.5T446.75-80ZM445-138l376-378v-304H514L139-445l306 307Zm271-526q21 0 36.5-15.5T768-716q0-21-15.5-36.5T716-768q-21 0-36.5 15.5T664-716q0 21 15.5 36.5T716-664ZM480-479Z"
		/>
	</svg>
);
