import { ReactNode } from 'react';

export const symbol_timer_10_alt_1: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M395.175-281q12.825 0 21.325-8.625T425-311v-260q0-12.75-8.625-21.375T395-601h-50q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T345-541h20v230q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625ZM525-281h60q26 0 43-17t17-43v-200q0-26-17-43t-43-17h-60q-26 0-43 17t-17 43v200q0 26 17 43t43 17Zm0-60v-200h60v200h-60Zm-45 200q125 0 212.5-87.5T780-441q0-125-87.5-212.5T480-741q-125 0-212.5 87.5T180-441q0 125 87.5 212.5T480-141Zm0-299Zm-90-420q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T390-920h180q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T570-860H390Zm90 779q-74 0-139.5-28.5T226-187q-49-49-77.5-114.5T120-441q0-74 28.5-139.5T226-695q49-49 114.5-77.5T480-801q67 0 126 22.5T711-716l30-30q9-9 21-9t21 9q9 9 9 21t-9 21l-30 30q36 40 61.5 97T840-441q0 74-28.5 139.5T734-187q-49 49-114.5 77.5T480-81Z"
		/>
	</svg>
);
