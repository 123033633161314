import { ReactNode } from 'react';

export const symbol_history_toggle_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M216.877-713q-13.123 0-22-8.5T186-742.825q0-12.824 8.877-21.5 8.877-8.675 22-8.675t21.623 8.675q8.5 8.676 8.5 21.5 0 12.825-8.5 21.325t-21.623 8.5ZM384-808q-12 0-21-8.781-9-8.78-9-21.219 0-12 8.78-21 8.781-9 21.22-9 12 0 21 9t9 21q0 12-9 21t-21 9Zm192.175 0q-12.824 0-21.5-8.5Q546-825 546-837.825q0-12.824 8.675-21.5 8.676-8.675 21.5-8.675 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.5 21.325t-21.325 8.5Zm166.65 95Q730-713 721.5-721.5T713-743q0-13 8.5-21.5t21.325-8.5q12.824 0 21.5 8.5Q773-756 773-743t-8.675 21.5q-8.676 8.5-21.5 8.5Zm95 167Q825-546 816.5-554.675q-8.5-8.676-8.5-21.5 0-12.825 8.5-21.325t21.325-8.5q12.824 0 21.5 8.675 8.675 8.676 8.675 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5ZM838-354q-12 0-21-8.78-9-8.781-9-21.22 0-12 8.781-21 8.78-9 21.219-9 12 0 21 9t9 21q0 12-9 21t-21 9Zm-94.877 168Q730-186 721.5-194.877q-8.5-8.877-8.5-22t8.5-21.623q8.5-8.5 21.623-8.5 13.123 0 22 8.5T774-216.877q0 13.123-8.877 22t-22 8.877ZM576-92q-12 0-21-9t-9-21q0-12 9-21t21-9q12 0 21 8.78 9 8.781 9 21.22 0 12-8.781 21-8.78 9-21.219 9Zm-192 0q-12 0-21-9t-9-21q0-12 8.78-21 8.781-9 21.22-9 12 0 21 8.78 9 8.781 9 21.22 0 12-9 21t-21 9Zm-167.877-95Q203-187 194.5-195.675q-8.5-8.676-8.5-21.5Q186-230 194.675-239q8.676-9 21.5-9Q229-248 238-239.123t9 22q0 13.123-8.877 21.623-8.877 8.5-22 8.5Zm-93.948-168q-12.824 0-21.5-8.5Q92-372 92-384.825q0-12.824 8.675-21.5 8.676-8.675 21.5-8.675 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.5 21.325t-21.325 8.5ZM122-546q-12 0-21-9t-9-21q0-12 9-21t21-9q12 0 21 8.781 9 8.78 9 21.219 0 12-8.78 21-8.781 9-21.22 9Zm388 53.818L633-369q9 9 9 21t-9 21q-9 9-21 9t-21-9L459-459q-5-5-7-10.217-2-5.218-2-10.783v-160q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-640v147.818Z"
		/>
	</svg>
);
