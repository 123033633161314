import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { t } from '@transifex/native';

import * as palette from 'pkg/config/palette';

import * as commentActions from 'pkg/actions/comments';

import * as selectors from 'pkg/selectors';
import rgba from 'pkg/rgba';
import * as sdk from 'pkg/core/sdk';

import UserLink from 'containers/user/Link';

import { FormattedContent } from 'components/formatted-content';
import RelativeDateTime from 'components/RelativeDateTime';
import Icon from 'components/icon';
import Avatar from 'components/avatar';

import * as Comment from 'components/legacy/Comment';
import TextArea from 'components/form/TextArea';

const CommentFormWrapper = styled.form`
	display: flex;
	align-items: flex-end;
`;

/**
 * @type {React.Element<any>}
 */
class SingleComment extends Component {
	static propTypes = {
		comment: PropTypes.object.isRequired,
		type: PropTypes.string,
		stateKey: PropTypes.string,
		resourceId: PropTypes.number,
		commentColumnName: PropTypes.oneOf(['comment', 'content']),
	};

	static defaultProps = {
		commentColumnName: 'comment',
	};

	state = {
		editing: false,
		active: false,
		commentText: '',
	};

	constructor() {
		super();

		this.handleInputOnChange = this.handleInputOnChange.bind(this);
	}

	toggleEdit = (e) => {
		e.preventDefault();
		const { editing } = this.state;
		const commentText = this.props.comment.comment;
		this.setState({ editing: !editing, commentText });
	};

	updateComment = async () => {
		const { commentText } = this.state;

		if (this.props.updateEventComment) {
			await this.props.updateEventComment(this.props.comment.id, commentText);

			this.setState({ editing: false });
			return;
		}

		let payload = {};
		payload[this.props.commentColumnName] = commentText;

		const response = await sdk.patch(
			this.props.comment.links?.edit,
			{},
			payload
		);

		if (!response.ok) {
			this.setState({ editing: false });
			return;
		}

		const commentResponse = await response.json();

		const comment = {
			...this.props.comment,
			comment: commentResponse[this.props.commentColumnName],
		};

		await this.props.updateComment(this.props.resourceId, {
			...comment,
		});

		this.setState({ editing: false });
	};

	handleInputOnChange(e) {
		this.setState({ commentText: e.target.value });
	}

	get contentInputProps() {
		return {
			type: 'text',
			onChange: this.handleInputOnChange,
			defaultValue: this.state.commentText,
			multilineoptions: { minRows: 1 },
		};
	}

	get iconName() {
		if (!this.state.editing) {
			return 'edit';
		}
		return 'remove';
	}

	get readableCreatedAt() {
		if (!this.props.comment.createdAt) {
			return null;
		}

		return <RelativeDateTime dateTime={this.props.comment.createdAt} />;
	}

	get editForm() {
		return (
			<CommentFormWrapper {...this.formProps}>
				<TextArea {...this.contentInputProps} />
				<Icon
					onClick={this.updateComment}
					name="plane"
					style={{
						transition: 'color 250ms ease-in-out',
						color: rgba(
							this.disabled
								? palette.icon.idleFillColor
								: palette.icon.activeFillColor
						),
						fontSize: '1.4rem',
						marginLeft: '10px',
						transform: 'translateY(-8px)',
					}}
				/>
			</CommentFormWrapper>
		);
	}

	get editActions() {
		const actions = [];
		const { comment } = this.props;

		if (comment.links?.delete) {
			actions.push(
				<Comment.Action key="delete-comment" onClick={this.deleteCallback}>
					{t('Delete')}
				</Comment.Action>
			);
		}

		if (comment.links?.edit) {
			actions.push(
				<Comment.Action key="edit-comment" onClick={this.toggleEdit}>
					{this.state.editing ? t('Abort') : t('Edit')}
				</Comment.Action>
			);
		}

		return actions;
	}

	deleteCallback = async (event) => {
		event.preventDefault();

		const { comment } = this.props;
		const commitDeleteAction = window.confirm(
			t('Do you want to delete this?', { _context: 'confirm messages' })
		);

		if (this.props.removeEventComment && commitDeleteAction) {
			await this.props.removeEventComment(this.props.comment.id);
			return;
		}

		if (commitDeleteAction) {
			await sdk.destroy(comment.links.delete);
			this.props.removeComment(this.props.resourceId, comment);
		}
	};

	get content() {
		if (this.state.editing) {
			return this.editForm;
		}

		const { comment } = this.props;

		return (
			<FormattedContent
				raw={comment.comment}
				prefixWith={
					<Fragment>
						<UserLink userId={comment.userId} />{' '}
					</Fragment>
				}
			/>
		);
	}

	render() {
		const { comment, user } = this.props;

		return (
			<Comment.Wrapper>
				<Comment.AuthorAvatar>
					<Avatar user={user.id ? user : comment.user} />
				</Comment.AuthorAvatar>
				<Comment.Container>
					<Comment.Body>{this.content}</Comment.Body>
					<Comment.Actions>
						<Comment.Timestamp>{this.readableCreatedAt}</Comment.Timestamp>
						{this.editActions}
					</Comment.Actions>
				</Comment.Container>
			</Comment.Wrapper>
		);
	}
}

const mapsStateToProps = (state, props) => {
	return {
		user: selectors.users.find(state, props.comment.userId),
	};
};

const mapDispatchToProps = (dispatch, props) => {
	if (!props.stateKey) {
		return {};
	}

	let updateComment = () => {};
	let removeComment = () => {};

	if (props.stateKey === 'activities') {
		updateComment = commentActions.updateActivityComment;
		removeComment = commentActions.removeActivityComment;
	}

	return bindActionCreators(
		{
			updateComment,
			removeComment,
			addCommentsBatch: commentActions.addCommentsBatch,
		},
		dispatch
	);
};

export default connect(mapsStateToProps, mapDispatchToProps)(SingleComment);
