import { ReactNode } from 'react';

export const symbol_u_turn_left: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M282-290q-6 0-11-2t-10-7L141-419q-9-9-9-21t9-21q9-9 21-9t21 9l69 69v-214q0-97 68.5-165.5T486-840q97 0 165.5 68.5T720-606v456q0 13-8.5 21.5T690-120q-13 0-21.5-8.5T660-150v-456q0-72-51-123t-123-51q-72 0-123 51t-51 123v214l69-69q9-9 21-9t21 9q9 9 9 21t-9 21L303-299q-5 5-10 7t-11 2Z"
		/>
	</svg>
);
