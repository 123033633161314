import { Fragment, useContext, useState } from 'react';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import { dayIsWithinRange } from 'pkg/date';

import GridItem from 'routes/scheduling/bookings/GridItem';
import CreateBookingModal from 'routes/scheduling/bookings/modals/CreateBooking';
import GroupColumn from 'routes/scheduling/bookings/groups/Column';
import { BookingsContext } from 'routes/scheduling/bookings';
import { getTotalHeightOfDay, ScheduleGridItem } from 'routes/scheduling/utils';

import Column from 'components/layout/column';
import GridRow from 'components/scheduling/grid/row';
import * as dayStyles from 'components/scheduling/grid/day-item/styles.css';
import NewBooking from 'components/scheduling/grid/new-booking';

interface DayProps {
	isInView: boolean;
	day: Date;

	group: models.group.Group;
	bookings: models.booking.Booking[];
}

const Day = ({ day, isInView, bookings, group }: DayProps) => {
	const [modal, setModal] = useState('');

	const bookingsContext = useContext(BookingsContext);

	const handleClick = () => setModal('createBooking');
	const hideModal = () => setModal('');

	const dayBookings = bookings.filter((b) =>
		dayIsWithinRange(b.startsAt, b.endsAt, day)
	);

	if (!isInView) {
		const totalHeight = getTotalHeightOfDay(
			dayBookings as ScheduleGridItem[],
			bookingsContext.conflicts,
			bookingsContext.isSmallScreen,
			bookingsContext.view
		);

		return <div style={{ height: `${totalHeight}px` }} />;
	}

	return (
		<Fragment>
			<div className={dayStyles.itemWrapper}>
				<Column spacing={styles.spacing._2}>
					{dayBookings.map((b) => (
						<GridItem key={b.id} day={day} booking={b} />
					))}
				</Column>
				{!bookingsContext.calendarView && <NewBooking onClick={handleClick} />}
			</div>
			{modal === 'createBooking' && (
				<CreateBookingModal
					group={group}
					groupId={bookingsContext.groupId}
					hideModal={hideModal}
					date={day}
				/>
			)}
		</Fragment>
	);
};

interface GroupRowProps {
	group: models.group.Group;

	bookings: models.booking.Booking[];
}

const GroupRow = ({ group, bookings }: GroupRowProps) => {
	const [isInView, setIsInView] = useState(false);
	const bookingsContext = useContext(BookingsContext);

	return (
		<GridRow setIsInView={setIsInView} cols={7}>
			{isInView && <GroupColumn group={group} bookings={bookings} />}
			{bookingsContext.weekDates.map((day, i) => (
				<div className={dayStyles.gridItem} key={i}>
					<Day
						day={day}
						group={group}
						isInView={isInView}
						bookings={bookings}
					/>
				</div>
			))}
		</GridRow>
	);
};

export default GroupRow;
