import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { t } from '@transifex/native';

import { toMedium } from 'pkg/config/breakpoints';

import * as models from 'pkg/api/models';

import TreeRow from 'containers/group/settings/organization/tree/Row';

import * as Table from 'design/table';
interface BaseProps {
	showActions?: boolean;
	groups: models.group.Group[];
	rootGroupId: number;
	selectable?: boolean;
	excludeGroupTree?: boolean;
	selectedGroup?: models.group.Group;
	onShowDialog: (type: string, group: models.group.Group) => void;
	onCreateSubGroup: (selectedGroup: models.group.Group) => void;
	onSelectGroup?: (group: models.group.Group) => void;
	isLoading?: boolean;
	emptyState: Table.EmptyStateProps;
	refresh: () => void;
	removeRecord: (recordId: number) => void;
}

const TreeBase: React.FC<React.PropsWithChildren<BaseProps>> = ({
	showActions,
	groups,
	rootGroupId,
	selectable = false,
	excludeGroupTree = 0,
	selectedGroup,
	onShowDialog,
	onCreateSubGroup,
	onSelectGroup,
	isLoading,
	emptyState,
	refresh,
	removeRecord,
}) => {
	const isMobile = useMediaQuery({ maxWidth: toMedium });

	const [isAllExpanded, setIsAllExpanded] = useState<boolean>(true);

	const handleExpanded = () => setIsAllExpanded((prev) => !prev);

	return (
		<Table.Table
			emptyState={emptyState}
			columns={[
				{
					content: t('Name'),
				},
				{
					content: t('Group code'),
					hide: selectable || isMobile,
				},
				{
					content: t('Groups'),
					hide: isMobile,
				},
				{
					content: t('Users'),
					hide: selectable || isMobile,
				},
				{
					content: t('Players'),
					hide: selectable || isMobile,
				},
				{
					content: t('Admins/Staff'),
					hide: selectable || isMobile,
				},
				{
					content: t('Gender'),
					hide: selectable || isMobile,
				},
				{
					content: t('Birth year'),
					hide: selectable || isMobile,
				},
				{
					content: (
						<Table.ToggleExpand
							isExpanded={isAllExpanded}
							onClick={handleExpanded}
						/>
					),
					width: 'max-content',
				},
			]}
			isLoading={isLoading}>
			{groups.map((group: models.group.Group) => {
				return (
					<TreeRow
						showActions={showActions}
						key={`${rootGroupId}${group.id}`}
						group={group}
						rootGroupId={rootGroupId}
						excludeGroupTree={excludeGroupTree}
						selectable={selectable}
						selectedGroup={selectedGroup}
						expandAll={isAllExpanded}
						onShowDialog={onShowDialog}
						onCreateSubGroup={onCreateSubGroup}
						onSelectGroup={onSelectGroup}
						refresh={refresh}
						removeRecord={removeRecord}
					/>
				);
			})}
		</Table.Table>
	);
};

export default TreeBase;
