import { ReactNode } from 'react';

export const symbol_label: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M816-445 671-241q-13 18-31 29.5T600-200H180q-24.75 0-42.375-17.625T120-260v-440q0-24.75 17.625-42.375T180-760h420q22 0 40 11.5t31 29.5l145 204q11 15.972 11 34.986Q827-461 816-445ZM611-260l154-220-154-220H180v440h431ZM180-700v440-440Z"
		/>
	</svg>
);
