import { ReactNode } from 'react';

export const symbol_signal_wifi_statusbar_not_connected: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M438-162 45-554q-9-9-13.5-21T28-599q1-12 6-23.5T49-642q91-77 201-117.5T480-800q130 0 236.5 43T922-632q8 6 11.5 15t4.5 18q1 9-2.5 18T925-565q-25-26-58-39.5T795-618q-78 0-133 55t-55 133q0 39 14 70.5t41 57.5L522-162q-9 9-20 13t-22 4q-11 0-22-4t-20-13Zm357 12q-15 0-25.5-10.5T759-186q0-15 10.5-25.5T795-222q15 0 25.5 10.5T831-186q0 15-10.5 25.5T795-150Zm118-299q0 31-12.5 53T862-353q-23 19-32 32.5T819-287q-1 10-7.5 16.5T795-264q-10 0-16.5-6.5T773-287q2-31 11-52t35-40q26-19 35-33.5t9-33.5q0-26-17.5-42.5T800-505q-23 0-39.5 9T733-471q-6 8-15 10.5t-17-2.5q-9-5-12-15.5t2-18.5q17-29 45-45t64-16q48 0 80.5 31t32.5 78Z"
		/>
	</svg>
);
