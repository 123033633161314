import { ReactNode } from 'react';

export const symbol_edit_attributes: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M279-280q-83 0-141.5-58.5T79-480q0-83 58.5-141.5T279-680h400q83 0 141.5 58.5T879-480q0 83-58.5 141.5T679-280H279Zm0-60h400q58.333 0 99.167-40.765 40.833-40.764 40.833-99Q819-538 778.167-579 737.333-620 679-620H279q-58.333 0-99.167 40.765-40.833 40.764-40.833 99Q139-422 179.833-381q40.834 41 99.167 41Zm64-69 103-103q8-7.909 8-18.455Q454-541 446-549q-8-8-18.182-8-10.182 0-17.818 8l-88 87-27-27q-7.636-8-17.818-8Q267-497 259-489.071q-8 7.928-8 18.5Q251-460 259-452l42 43q9 9 21 9t21-9Zm137-71Z"
		/>
	</svg>
);
