export function AvailableTypes(): string {
	return `/v1/match-events/available-types`;
}

export function Create(matchID: number): string {
	return `/v1/matches/${matchID}/events`;
}

export function Destroy(matchEventID: number): string {
	return `/v1/match-events/${matchEventID}`;
}

export function Index(matchID: number): string {
	return `/v1/matches/${matchID}/events`;
}

export function Update(matchEventID: number): string {
	return `/v1/match-events/${matchEventID}`;
}
