import { MouseEvent, ReactNode } from 'react';
import styled from 'styled-components';

import MaterialSymbol from 'components/material-symbols';

import InfoBox from 'components/form/info-box';
import { MaterialSymbolVariant } from 'components/material-symbols/symbols';

import * as ContextMenu from 'design/context_menu';
import Button from 'design/button';

const ShareWrapper = styled.div`
	display: grid;
	grid-template-rows: repeat(2, auto);
	place-items: start;
	place-content: start;
	gap: var(--spacing-2);
	row-gap: var(--spacing-5);
`;

const Description = styled.div`
	font-size: var(--font-size-sm);
`;

const Options = styled.div`
	display: inline-grid;
	gap: var(--spacing-3);
	grid-auto-flow: column;
`;

const Option = styled.div`
	position: relative;
`;

const ButtonLabel = styled.div`
	display: grid;
	grid-template-columns: auto 1fr;
	gap: var(--spacing-3);
	align-items: center;
	justify-content: center;
`;

interface ShareOption {
	id: string;
	icon: MaterialSymbolVariant;
	label: string;
	description: string | ReactNode;
}

interface ShareOptionsProps {
	selectedId: string;
	disabled?: boolean;
	options: ShareOption[];
	onClick: (id: string) => void;
}

export default function ShareOptions({
	selectedId,
	disabled,
	options,
	onClick,
}: ShareOptionsProps): JSX.Element {
	const currentOption = options.find(
		(option: ShareOption) => option.id === selectedId
	);

	const { icon, label, description } = currentOption;

	const handleClick = (event: MouseEvent<HTMLDivElement>) => {
		onClick(event.currentTarget.dataset.id);
	};

	return (
		<InfoBox>
			<ShareWrapper>
				<Description>{description}</Description>

				<Options>
					<Option>
						<ContextMenu.Menu
							toggleWith={
								<Button
									small
									icon="expand_more"
									iconPosition="right"
									disabled={disabled}>
									<ButtonLabel>
										<MaterialSymbol variant={icon} />
										<span>{label}</span>
									</ButtonLabel>
								</Button>
							}>
							{options.map((option: ShareOption) => (
								<ContextMenu.Item
									key={option.id}
									data-id={option.id}
									onClick={handleClick}>
									<ContextMenu.ItemIcon name={option.icon} />
									{option.label}
								</ContextMenu.Item>
							))}
						</ContextMenu.Menu>
					</Option>
				</Options>
			</ShareWrapper>
		</InfoBox>
	);
}
