import { ReactNode } from 'react';

export const symbol_keyboard_tab: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M849.825-240Q837-240 828.5-248.625T820-270v-420q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T880-690v420q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM606-450H110q-12.75 0-21.375-8.675Q80-467.351 80-480.175 80-493 88.625-501.5T110-510h496L459-658q-9-9-9-21t9-21q9-9 21-9t21 9l199 199q5 5 7 10.133 2 5.134 2 11Q709-474 707-469q-2 5-7 10L501-260q-9 9-21.158 9-12.158 0-21-8.609Q450-269 450-281.467q0-12.466 9-21.533l147-147Z"
		/>
	</svg>
);
