import { ReactNode, useEffect } from 'react';
import { t } from '@transifex/native';

import useMixedState from 'pkg/hooks/useMixedState';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import {
	AttachmentType,
	AttachmentVisibility,
} from 'pkg/api/models/attachment';
import { useEndpoint } from 'pkg/api/use_endpoint';
import { VideoType } from 'pkg/api/models/video';
import { useCurrentGroup } from 'pkg/identity';
import * as actions from 'pkg/actions';

import * as StepModal from 'components/step-modal';

import SourceStep from 'components/video-library/modals/steps/Source';
import VideoStep from 'components/video-library/modals/steps/Video';
import EditStep from 'components/video-library/modals/steps/Edit';
import MatchStep from 'components/video-library/modals/steps/Match';
import ThumbnailStep from 'components/video-library/modals/steps/Thumbnail';
import WingfieldStep from 'components/video-library/modals/steps/wingfield';

export interface VideoPayload {
	userId?: number;
	videoId?: number;
	matchId?: number;
	groupId?: number;
	attachmentId?: number;
	externalId?: string;

	source: string;
	match?: models.match.Match;

	url?: string;
	title?: string;
	description?: string;

	type?: VideoType;
	file?: File;
	attachment?: File;
	previewUrl?: string;
}

export type VideoPayloadSetter = (payload: Partial<VideoPayload>) => void;

interface VideoProps {
	videoId?: number;
	matchId?: number;

	match?: models.match.Match;

	onClose?: () => void;
	afterSave?: (video: models.video.Video) => void;
}

export default function Video({
	videoId,
	matchId,

	match,

	onClose,
	afterSave,
}: VideoProps): JSX.Element {
	const group = useCurrentGroup();

	const [payload, setPayload] = useMixedState<VideoPayload>({
		videoId,
		matchId,
		match,
		groupId: group.id,
		source: null,
	});

	const { record: video } = useEndpoint<models.video.Video>(
		endpoints.Video.Show(videoId)
	);

	useEffect(() => {
		if (video) {
			setPayload({
				title: video.title,
				description: video.description,
				groupId: video.groupId,
				previewUrl: video.thumbnail?.url,
			});
		}
	}, [video]);

	useEffect(() => {
		setPayload({ groupId: group.id });
	}, [group.id]);

	const createAttachment = async (
		file: File
	): Promise<models.attachment.Attachment> => {
		const attachment = await actions.attachments.upload({
			type: AttachmentType.Image,
			visibility: AttachmentVisibility.Hidden,
			originalFilename: 'video-thumbnail.png',
			file,
		});

		return attachment;
	};

	const create = async (payload: VideoPayload): Promise<models.video.Video> => {
		let thumbnailAttachmentId: number;

		if (payload.attachment) {
			const attachment = await createAttachment(payload.attachment);

			if (attachment.id) {
				thumbnailAttachmentId = attachment.id;
			}
		}

		const [, v] = await models.video.create({
			externalId: payload.externalId,
			userId: payload.userId,
			groupId: payload.groupId,
			thumbnailAttachmentId,
			title: payload.title,
			description: payload.description,
			uri: payload.url,
			type: payload.type,
		});

		if (payload.matchId && v.id) {
			await models.matchVideo.link(payload.matchId, v.id);
		}

		return Promise.resolve(v);
	};

	const update = async (payload: VideoPayload): Promise<models.video.Video> => {
		if (payload.title.length === 0) {
			return Promise.reject(false);
		}

		let thumbnailAttachmentId: number;

		if (payload.attachment) {
			const attachment = await createAttachment(payload.attachment);

			if (attachment.id) {
				thumbnailAttachmentId = attachment.id;
			}
		}

		// Remove attachment reference, not the attachment itself
		if (
			video.thumbnailAttachmentId &&
			!payload.attachment &&
			!payload.previewUrl
		) {
			thumbnailAttachmentId = null;
		}

		const [, v] = await models.video.update(video, {
			groupId: payload.groupId,
			thumbnailAttachmentId,
			title: payload.title,
			description: payload.description,
		});

		if (payload.matchId && v.id) {
			await models.matchVideo.link(payload.matchId, v.id);
		}

		return Promise.resolve(v);
	};

	const save = async () => {
		let v: models.video.Video;

		if (payload.videoId) {
			v = await update(payload);
		} else {
			v = await create(payload);
		}

		if (afterSave && v?.id) {
			afterSave(v);
		}
	};

	let initialStep: ReactNode = null,
		sourceStep: ReactNode = null;

	if (!videoId) {
		initialStep = (
			<StepModal.Step
				slug="start"
				title={t('Select video source', { _context: 'video modal' })}
				nextLabel={t('Continue')}>
				<SourceStep payload={payload} setPayload={setPayload} />
			</StepModal.Step>
		);

		if (payload.source !== 'wingfield') {
			sourceStep = (
				<StepModal.Step
					title={t('Add new video', { _context: 'video modal' })}
					prevLabel={t('Back')}
					nextLabel={t('Continue')}>
					<VideoStep payload={payload} setPayload={setPayload} />
				</StepModal.Step>
			);
		} else {
			sourceStep = (
				<StepModal.Step
					title={t('Add Wingfield video', { _context: 'video modal' })}
					prevLabel={t('Back')}
					nextLabel={t('Continue')}>
					<WingfieldStep payload={payload} setPayload={setPayload} />
				</StepModal.Step>
			);
		}
	}

	return (
		<StepModal.Base onClose={onClose}>
			{initialStep}
			{sourceStep}
			<StepModal.Step
				slug="edit"
				title={
					videoId
						? t('Edit video', { _context: 'video modal' })
						: t('Add new video', { _context: 'video modal' })
				}
				prevLabel={videoId ? t('Cancel') : t('Back')}
				nextLabel={t('Save')}
				closeOnNext
				onNext={save}>
				<EditStep
					videoId={videoId}
					videoGroupId={video?.groupId}
					payload={payload}
					setPayload={setPayload}
				/>
			</StepModal.Step>
			<StepModal.Step
				skipBody
				slug="matchConnect"
				title={t('Connect video to a match', { _context: 'video modal' })}
				prevLabel={t('Cancel')}
				nextSlug="edit"
				nextLabel={t('Connect')}>
				<MatchStep payload={payload} setPayload={setPayload} />
			</StepModal.Step>
			<StepModal.Step
				slug="thumbnail"
				title={t('Upload video thumbnail', { _context: 'video modal' })}
				prevSlug="edit"
				prevLabel={t('Cancel')}
				nextSlug="edit"
				nextLabel={t('Upload')}>
				<ThumbnailStep payload={payload} setPayload={setPayload} />
			</StepModal.Step>
		</StepModal.Base>
	);
}
