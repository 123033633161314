import { t } from '@transifex/native';
import styled from 'styled-components';
import { Fragment } from 'react';

import AssetImage from 'components/AssetImage';

import Row from 'components/layout/row';

import PlaceholderBlock from 'design/placeholders/block';

const Wrapper = styled.div`
	width: 100%;
	max-width: 640px;
`;

export default function EmptyState(): JSX.Element {
	return (
		<Fragment>
			<Row align="center" justify="center">
				<Wrapper>
					<PlaceholderBlock
						noBorder
						title={t(`Add content to your training library!`)}
						description={t(
							`Create and curate drills and sessions to build the ultimate coaching library for you or your club.`
						)}
						helpUrl="https://help.360player.com/category/25-training-library"
						image={<AssetImage src="img/missing-entities/player-cards.svg" />}
					/>
				</Wrapper>
			</Row>
		</Fragment>
	);
}
