import { ReactNode } from 'react';

export const symbol_door_front: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M150-120q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-180h50v-600q0-24 18-42t42-18h440q24 0 42 18t18 42v600h50q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-120H150Zm110-60h440v-600H260v600Zm307-269q14.45 0 24.225-9.775Q601-468.55 601-483q0-14.45-9.775-24.225Q581.45-517 567-517q-14.45 0-24.225 9.775Q533-497.45 533-483q0 14.45 9.775 24.225Q552.55-449 567-449ZM260-780v600-600Z"
		/>
	</svg>
);
