import { Fragment } from 'react';

import { FontWeights } from 'pkg/config/fonts';

import * as models from 'pkg/api/models';
import { cssClasses, cssVarList } from 'pkg/css/utils';
import * as routes from 'pkg/router/routes';

import Badge from 'components/Badge';

import NotificationBadge from 'components/navigation/notification_badge';
import Row from 'components/layout/row';

import * as Context from 'design/context_menu';

import * as css from './styles.css';

interface OrganizationListItemProps {
	active: boolean;
	hideContent?: boolean;
	lastItem: boolean;
	organizationData: models.group.OrganizationData;
}

export default function OrganizationListItem({
	active,
	hideContent = false,
	organizationData,
}: OrganizationListItemProps) {
	const primaryColor = models.group.getPrimaryColorStylesheetString(
		organizationData as models.group.Group
	);

	return (
		<Fragment>
			<Context.LinkItem
				href={routes.Organization.Home(organizationData.id)}
				tight
				className={cssClasses(
					hideContent ? css.hideContent : '',
					css.contextMenuItem
				)}
				fontWeight={FontWeights.SemiBold}>
				<Row columns="40px 1fr" align="center">
					<div
						className={cssClasses(css.badgeWrapper, active && css.active)}
						style={cssVarList({
							primaryColor,
						})}>
						{organizationData.unreadChatCount > 0 && (
							<NotificationBadge amount={organizationData.unreadChatCount} />
						)}
						<div
							className={cssClasses(
								css.badgeInnerWrapper,
								active && css.active
							)}>
							<Badge
								badgeUrl={organizationData.profileImageUrl}
								className={css.badge}
							/>
						</div>
					</div>
					<p>{organizationData.name}</p>
				</Row>
			</Context.LinkItem>
		</Fragment>
	);
}
