import { ReactNode } from 'react';

export const symbol_countertops: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M220-620h110v-120H220v120Zm0 400h230v-280H220v280Zm290 0h230v-280H510v280Zm-290 0h520v-280H220v280Zm330.825-440Q538-660 529-668.625T520-690q0-45 32.5-77.5t78-32.5q45.5 0 77.5 32.083 32 32.084 32 77.917v130h110q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-500h-50v280q0 24.75-17.625 42.375T740-160H220q-24.75 0-42.375-17.625T160-220v-280h-50q-12.75 0-21.375-8.675Q80-517.351 80-530.175 80-543 88.625-551.5T110-560h120q-28.875 0-49.438-20.562Q160-601.125 160-630v-140q0-12.75 8.625-21.375T190-800h170q12.75 0 21.375 8.625T390-770v140q0 28.875-20.562 49.438Q348.875-560 320-560h360v-130q0-21.25-14.325-35.625Q651.351-740 630.175-740 609-740 595-725.5 581-711 581-691q0 13.175-8.675 22.088-8.676 8.912-21.5 8.912ZM220-620h110-110Z"
		/>
	</svg>
);
