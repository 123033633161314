import { App, URLOpenListenerEvent } from '@capacitor/app';

import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { replaceState } from 'pkg/router/state';

export default function DeepLinkObserver(): JSX.Element {
	useComponentDidMount(() => {
		App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
			const targetUrl = new URL(event.url);

			replaceState(`${targetUrl.pathname}${targetUrl.search}`);
			window.history.replaceState(
				{},
				'',
				`${targetUrl.pathname}${targetUrl.search}`
			);

			return;
		});
	});

	return null;
}
