import { ReactNode } from 'react';

export const symbol_elderly: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M538.08-754q-30.08 0-51.58-21.42-21.5-21.421-21.5-51.5 0-30.08 21.42-51.58 21.421-21.5 51.5-21.5 30.08 0 51.58 21.42 21.5 21.421 21.5 51.5 0 30.08-21.42 51.58-21.421 21.5-51.5 21.5ZM695-405q-6 0-10.5-4.5T680-420v-33q-42-11-87.5-42T521-568q-12 34-18.5 75t-4.5 73l91 129q3.429 3.913 4.714 8.609Q595-277.696 595-273v203q0 12.75-8.675 21.375Q577.649-40 564.825-40 552-40 543.5-48.625T535-70v-189l-85-95-9 145q0 2.909-6 16L338-64q-8 10-19.826 12T296-58q-10-8-12-19.826T290-100l86-115-8-191q-2-52 5.5-109.5T397-617l-97 56v80.571q0 13.429-8.675 21.929-8.676 8.5-21.5 8.5-12.825 0-21.325-8.625T240-480v-99q0-8.067 4-15.033Q248-601 255-605l152-87q17-10 32.708-15 15.707-5 29.292-5 26.515 0 48.258 21Q539-670 559-625q20 46 59 75.5t94 46.5q7-4 13.5-5.5T739-510q23 0 42 18.5t19 41.5v395q0 6-4.5 10.5T785-40q-6 0-10.5-4.5T770-55v-395q0-12-9-21t-21-9q-12 0-21 9t-9 21v30q0 6-4.5 10.5T695-405Z"
		/>
	</svg>
);
