// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.h1rLPcxDjY79_wcbVkrg {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./routes/payments/subscriptions/single/sidebar/styles.css"],"names":[],"mappings":"AAAA;CACC,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,wBAAuB;KAAvB,qBAAuB;SAAvB,uBAAuB;AACxB","sourcesContent":[".labelWrapper {\n\tdisplay: flex;\n\tjustify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
export var labelWrapper = `h1rLPcxDjY79_wcbVkrg`;
export default ___CSS_LOADER_EXPORT___;
