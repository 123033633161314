import styled, { css } from 'styled-components';

import * as palette from 'pkg/config/palette';

import Horizontal from 'components/tab-view/variants/Horizontal';
import Vertical from 'components/tab-view/variants/Vertical';

export const Tab = styled.div`
	color: ${palette.gray[600]};

	@media (hover: hover) {
		&:hover {
			color: ${palette.black};
		}
	}

	${(props) => !props.theme.vertical && Horizontal.Item};

	${(props) =>
		props.theme.vertical &&
		css`
			@media not ${props.theme.breakpoint} {
				${Vertical.Item};
			}

			@media ${props.theme.breakpoint} {
				${Horizontal.Item(props)};
			}
		`};
`;

Tab.defaultProps = {
	hidden: [],
};

export const ActiveTab = styled(Tab)`
	${(props) => !props.theme.vertical && Horizontal.ActiveItem};

	${(props) =>
		props.theme.vertical &&
		css`
			@media not ${props.theme.breakpoint} {
				${Vertical.ActiveItem};
			}

			@media ${props.theme.breakpoint} {
				${Horizontal.ActiveItem(props)};
			}
		`};
`;

export const TabNav = styled.nav`
	position: relative;
	max-width: ${(props) => props.theme.maxWidth}px;
	margin: 0 auto;
	width: 100%;
`;

export const TabWrapper = styled.div`
	display: block;
	list-style: none;
	padding: 0;
	border-top: none;

	${(props) => !props.theme.vertical && Horizontal.Outer(props)};

	${(props) =>
		props.theme.vertical &&
		css`
			@media not ${props.theme.breakpoint} {
				position: sticky;
				transition: top 500ms ease-in-out;
				top: ${props.visibleNavBar ? props.navBarHeight + 20 : 20}px;
			}

			@media ${props.theme.breakpoint} {
				${Horizontal.Outer(props)};
			}
		`};
`;
