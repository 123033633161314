import { ReactNode } from 'react';

export const symbol_draw: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M190-120q-13 0-21.5-8.5T160-150v-73q0-12 5-23.5t13-19.5l553-553q11-11 22.5-16t24.5-5q12 0 23.5 5t22.5 16l35 35q11 10 16 21.5t5 24.5q0 12-5 24t-16 23L306-138q-8 8-19.5 13t-23.5 5h-73Zm30-60h44l446-446-22-22-22-22-446 446v44Zm601-557-44-44 44 44Zm-133 89-22-22 44 44-22-22ZM560-120q78 0 139-36.5T760-260q0-36-24-66t-74-52q-11-5-23.5-4T619-370q-7 11-4.5 24t13.5 18q33 15 52.5 32.5T700-260q0 32-40.5 56T560-180q-12 0-21 8.5t-9 21.5q0 12 9 21t21 9ZM370-719q0 20-19 35t-85 39q-94 34-120 62t-26 63q0 38 27 61t60 35q12 5 23-.5t15-17.5q4-12-1-22.5T227-480q-23-9-35-19t-12-21q0-16 21.5-30.5T290-590q83-31 111.5-59.5T430-719q0-56-41-88.5T280-840q-42 0-76 14.5T153-789q-8 9-7.5 21t11.5 20q10 8 22.5 6.5T200-751q15-15 34-22t46-7q46 0 68 18t22 43Z"
		/>
	</svg>
);
