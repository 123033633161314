import { Fragment, useState } from 'react';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCollection } from 'pkg/api/use_collection';
import { FilterGroups, useFilters } from 'pkg/filters/use_filters';
import { FilterOperator, createFilterGroups } from 'pkg/filters';
import * as routes from 'pkg/router/routes';
import { useCurrentOrganization } from 'pkg/identity';

import CategoryModal from 'routes/payments/products/list/CategoryModal';

import ProductListItem from 'containers/products/ListItem';

import Pagination from 'components/pagination';
import AssetImage from 'components/AssetImage';

import * as ActionBar from 'components/layout/ActionBar';

import Button from 'design/button';
import * as Table from 'design/table';

interface ProductsProps {
	organizationId: number;
	archived?: boolean;
}

interface IStateCategories {
	[key: number]: boolean;
}

export interface IFilters {
	categories: IStateCategories;
	subscriptions: boolean;
	oneTime: boolean;
	uncategorized: boolean;
}

const ProductTable: React.FC<React.PropsWithChildren<ProductsProps>> = ({
	organizationId,
	archived,
}) => {
	const org = useCurrentOrganization();
	const productCategoryParams = new URLSearchParams({});

	productCategoryParams.set('group_id', organizationId.toString());

	const { records: productCategories } =
		useCollection<models.productCategory.ProductCategory>(
			endpoints.ProductCategory.List(),
			{
				queryParams: productCategoryParams,
			}
		);

	const productCategoryFilters: { [key: string]: number } = {};

	for (const category of productCategories) {
		productCategoryFilters[category.name] = category.id;
	}

	const filterGroups: FilterGroups = createFilterGroups({
		[t('Title')]: {
			hidden: true,
			filters: {
				Name: {
					type: 'text',
					operator: FilterOperator.Contains,
					property: 'name',
				},
			},
		},
		[t('Created')]: {
			filters: {
				Created: {
					type: 'date',
					property: 'createdAt',
					operator: FilterOperator.GreaterThan,
				},
			},
		},
		[t('Category')]: {
			filters: {
				Category: {
					type: 'checkbox',
					operator: FilterOperator.Includes,
					property: 'productCategoryId',
					values: productCategoryFilters,
				},
				Uncategorized: {
					type: 'checkbox',
					operator: FilterOperator.NotSet,
					property: 'productCategoryId',
					values: { [t(`New product`)]: 'true' },
				},
			},
		},
	});

	const [sortBy, setSortBy] = useState('createdAt');
	const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');

	const filters = useFilters({
		filterBarMobileTrigger: true,
		groups: filterGroups,
	});

	filters.queryParam.set('group_id', organizationId.toString());
	filters.queryParam.set('sort_by', sortBy);
	filters.queryParam.set('sort_order', sortOrder);
	filters.queryParam.set('version', '2');

	if (archived) {
		filters.queryParam.set('archived', 'true');
	}

	const {
		records: products,
		pagination,
		refresh,
		isLoading,
	} = useCollection<models.product.Product>(endpoints.Products.Index(), {
		queryParams: filters.queryParam,
	});

	const handleSort = (value: string, sortOrder: 'asc' | 'desc') => {
		setSortBy(value);
		setSortOrder(sortOrder);
	};

	const [showCategory, setShowCategory] = useState(false);

	const handleCloseCategories = () => setShowCategory(false);
	const handleShowCategories = () => setShowCategory(true);

	const tableColumns = [
		{
			content: t(`Product title`),
			sortKey: 'name',
		},
		{
			content: t(`Category`),
			sortKey: 'category',
		},
		{
			content: t(`Price`),
		},
		{
			content: t(`Created at`),
			sortKey: 'createdAt',
		},
		{
			content: '',
			width: 'max-content',
		},
	];

	const emptyState = {
		title: t(`There are no products yet`),
		content: t(`Create a new product to get started.`),
		image: <AssetImage src="img/missing-entities/Product.svg" />,
		button: (
			<Button secondary icon="add" href={routes.Product.Create(org.id)}>
				{t(`New product`)}
			</Button>
		),
	};

	const content = (
		<Fragment>
			<Table.Table
				columns={tableColumns}
				sortBy={sortBy}
				sortOrder={sortOrder}
				onSort={handleSort}
				emptyState={emptyState}
				isLoading={isLoading}>
				{products.map((product: models.product.Product) => {
					return (
						<ProductListItem
							key={product.id}
							product={product}
							refresh={refresh}
						/>
					);
				})}
			</Table.Table>
			<Pagination {...pagination} />
		</Fragment>
	);

	return (
		<Fragment>
			<ActionBar.IntegratedFilterBar
				filters={filters}
				searchFilter={{
					type: 'text',
					operator: FilterOperator.Contains,
					property: 'name',
				}}
				actions={[
					{
						label: t('Categories'),
						onClick: handleShowCategories,
					},
					{
						label: t('Create'),
						icon: 'add',
						href: routes.Product.Create(org.id),
						type: 'primary',
					},
				]}
			/>
			{content}
			{showCategory && (
				<CategoryModal
					groupId={organizationId}
					onClose={handleCloseCategories}
				/>
			)}
		</Fragment>
	);
};

export default ProductTable;
