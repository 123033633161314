import { Fragment, ReactNode } from 'react';

import * as Card from 'components/Card';

import * as css from './styles.css';

interface CardRowProps {
	label: string;
	children: ReactNode;
	hideDivider?: boolean;
}

export default function CardRow({
	label,
	children,
	hideDivider,
}: CardRowProps) {
	return (
		<Fragment>
			<div className={css.container}>
				<div className={css.label}>{label}</div>
				{children}
			</div>
			{!hideDivider && <Card.Divider />}
		</Fragment>
	);
}
