const long_text = {
	name: 'long-text',
	objects: [
		{
			type: 'path',
			attributes: {
				d: 'M5.09874 13.8868H19.329V15.1868H5.09874V13.8868Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M5.09874 10.7868H19.329V12.0868H5.09874V10.7868Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M5.09874 7.68784H19.329V8.98784H5.09874V7.68784Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M5.09874 16.9863H14.1313V18.2863H5.09874V16.9863Z',
			},
		},
	],
};

export default long_text;
