import { ReactNode } from 'react';

export const symbol_avg_pace: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M609.859-770Q593-770 581.5-781.641t-11.5-28.5Q570-827 581.641-838.5t28.5-11.5Q627-850 638.5-838.359t11.5 28.5Q650-793 638.359-781.5t-28.5 11.5Zm0 660Q593-110 581.5-121.641t-11.5-28.5Q570-167 581.641-178.5t28.5-11.5Q627-190 638.5-178.359t11.5 28.5Q650-133 638.359-121.5t-28.5 11.5Zm160-520Q753-630 741.5-641.641t-11.5-28.5Q730-687 741.641-698.5t28.5-11.5Q787-710 798.5-698.359t11.5 28.5Q810-653 798.359-641.5t-28.5 11.5Zm0 380Q753-250 741.5-261.641t-11.5-28.5Q730-307 741.641-318.5t28.5-11.5Q787-330 798.5-318.359t11.5 28.5Q810-273 798.359-261.5t-28.5 11.5Zm60-190Q813-440 801.5-451.641t-11.5-28.5Q790-497 801.641-508.5t28.5-11.5Q847-520 858.5-508.359t11.5 28.5Q870-463 858.359-451.5t-28.5 11.5ZM80-479.837Q80-638 186-752t261-127q13-1 23 7.625T480-850q0 12-7.946 20.769Q464.108-820.462 452-819q-132 11-222 108.133t-90 230.96Q140-345 230-248.5T452-141q12.108 1.476 20.054 10.333Q480-121.81 480-110q0 12.75-10 21.375T447-81Q292-94 186-207.837t-106-272ZM479.911-410Q451-410 430.5-430.562 410-451.125 410-480q0-5.938 1-12.469t3-11.864L355-563q-9-9-9-21t9-21q9-9 21-8.5t21 8.5l60 59q5-2 23-4 28.875 0 49.438 20.589Q550-508.823 550-479.911 550-451 529.411-430.5q-20.588 20.5-49.5 20.5Z"
		/>
	</svg>
);
