import { ReactNode } from 'react';

export const symbol_background_dot_large: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M340.118-290Q361-290 375.5-304.618q14.5-14.617 14.5-35.5Q390-361 375.382-375.5q-14.617-14.5-35.5-14.5Q319-390 304.5-375.382q-14.5 14.617-14.5 35.5Q290-319 304.618-304.5q14.617 14.5 35.5 14.5Zm0-280Q361-570 375.5-584.618q14.5-14.617 14.5-35.5Q390-641 375.382-655.5q-14.617-14.5-35.5-14.5Q319-670 304.5-655.382q-14.5 14.617-14.5 35.5Q290-599 304.618-584.5q14.617 14.5 35.5 14.5Zm280 280Q641-290 655.5-304.618q14.5-14.617 14.5-35.5Q670-361 655.382-375.5q-14.617-14.5-35.5-14.5Q599-390 584.5-375.382q-14.5 14.617-14.5 35.5Q570-319 584.618-304.5q14.617 14.5 35.5 14.5Zm0-280Q641-570 655.5-584.618q14.5-14.617 14.5-35.5Q670-641 655.382-655.5q-14.617-14.5-35.5-14.5Q599-670 584.5-655.382q-14.5 14.617-14.5 35.5Q570-599 584.618-584.5q14.617 14.5 35.5 14.5ZM180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-60h600v-600H180v600Zm0-600v600-600Z"
		/>
	</svg>
);
