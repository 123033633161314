import { List, Record, Set, Map } from 'immutable';

import User from 'pkg/models/user';

import * as actionTypes from 'pkg/actions/action-types';

interface IUserReducer {
	pendingEvents: Set<any>;
	upcomingEvents: List<any>;
	entities: Map<number, User>;
	userTaggedVideos: any;
	personalData: any;
}

class UserReducer
	extends Record<IUserReducer>(
		{
			pendingEvents: Set<any>(),
			upcomingEvents: List<any>(),
			entities: Map<number, User>(),
			userTaggedVideos: {},
			personalData: undefined,
		},
		'users'
	)
	implements IUserReducer
{
	pendingEvents: Set<any>;
	upcomingEvents: List<any>;
	entities: Map<number, User>;
	userTaggedVideos: any;
	personalData: any;
}

const initialState = new UserReducer({
	pendingEvents: Set([]),
	upcomingEvents: List([]),
	entities: Map<number, User>(),
	userTaggedVideos: {},
	personalData: undefined,
});

const removeUsers = (
	state = initialState,
	action: { type: string; userIds: number[] }
) =>
	state.updateIn(['entities'], (entities) =>
		(entities as Map<number, User>).filter(
			(item: User) => !action.userIds.includes(item.id)
		)
	);

const users = (state = initialState, action: any): UserReducer => {
	switch (action.type) {
		case actionTypes.Users.ADD_USERS: {
			const users = action.payload.entities.users;

			for (const idString in users) {
				const id = Number.parseInt(idString, 10);
				let existingUser = state.entities.get(id);
				let payloadUser = new User(users[id]);

				if (
					existingUser &&
					((existingUser._s && !payloadUser._s) ||
						(existingUser._s && payloadUser._s) ||
						(!existingUser._s && !payloadUser._s))
				) {
					if (existingUser.meta.size) {
						payloadUser = payloadUser.mergeDeep({ meta: existingUser.meta });
					}

					state = state.updateIn(['entities', id], () => payloadUser);
				} else if (!existingUser) {
					state = state.setIn(['entities', id], payloadUser);
				} else if (payloadUser.meta.size) {
					existingUser = existingUser.mergeDeep({ meta: payloadUser.meta });
					state = state.updateIn(['entities', id], () => existingUser);
				}
			}

			return state;
		}

		case actionTypes.Users.REMOVE_USERS:
			return removeUsers(state, action);

		case actionTypes.ACCEPTED_EVENT_INVITE: {
			const upcomingEvents = state.upcomingEvents.update((list: any) =>
				list.findIndex((i: number) => i === action.eventId) === -1
					? list.push(action.eventId)
					: list
			);
			return state.set('upcomingEvents', upcomingEvents);
		}

		case actionTypes.REMOVE_PENDING_EVENT: {
			const pendingEvents = state.pendingEvents.update((events: any) =>
				events.filter((eventId: number) => eventId !== action.id)
			);

			return state.set('pendingEvents', pendingEvents);
		}

		case actionTypes.SET_PENDING_EVENTS: {
			const pendingEvents = Set(action.payload.result);

			return state.set('pendingEvents', pendingEvents);
		}

		case actionTypes.Users.USER_PERSONAL_DATA: {
			return state.set('personalData', action.payload);
		}

		case actionTypes.AUTH_LOGOUT_SUCCESS:
			return initialState;

		default:
			return state;
	}
};

export default users;
