import { ReactNode } from 'react';

export const symbol_snippet_folder: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-160q-24 0-42-18.5T80-220v-520q0-23 18-41.5t42-18.5h256q12.444 0 23.722 5t19.366 13.088L481-740h339q23 0 41.5 18.5T880-680v460q0 23-18.5 41.5T820-160H140Zm0-60h680v-460H456l-60-60H140v520Zm0 0v-520 520Zm420-120v-220h75l65 65v155H560Zm-30 60h200q12.75 0 21.375-8.625T760-310v-198q0-5.565-2-10.783Q756-524 751-529l-82-82q-5-5-10.217-7-5.218-2-10.783-2H530q-12.75 0-21.375 8.625T500-590v280q0 12.75 8.625 21.375T530-280Z"
		/>
	</svg>
);
