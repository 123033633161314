import { ReactNode } from 'react';

export const symbol_laptop_mac: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M57-160q-23.513 0-40.256-17.625Q0-195.25 0-220h141q-24 0-42-18t-18-42v-500q0-24 18-42t42-18h678q24 0 42 18t18 42v500q0 24-18 42t-42 18h141q0 25-17.625 42.5T900-160H57Zm423-22q14.45 0 24.225-9.775Q514-201.55 514-216q0-14.45-9.775-24.225Q494.45-250 480-250q-14.45 0-24.225 9.775Q446-230.45 446-216q0 14.45 9.775 24.225Q465.55-182 480-182Zm-339-98h678v-500H141v500Zm0 0v-500 500Z"
		/>
	</svg>
);
