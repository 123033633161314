import { ReactNode } from 'react';

export const symbol_highlighter_size_1: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m268-212-56-56q-9-9-9-21.5t9-21.5l437-437q9-9 21.5-9t21.5 9l56 56q9 9 9 21.5t-9 21.5L310-212q-9 9-21 9t-21-9Z"
		/>
	</svg>
);
