import styled from 'styled-components';

export const MissingEntitiesWrapper = styled.div`
	width: 610px;
	max-width: 100%;
	align-self: center;
	margin: 0 auto;
	margin-top: var(--spacing-8);
	margin-bottom: var(--spacing-8);
`;
