import {
	Fragment,
	ReactNode,
	createContext,
	useContext,
	PropsWithChildren,
} from 'react';
import styled, { css } from 'styled-components';
import { ErrorBoundary } from '@sentry/react';

import * as styles from 'pkg/config/styles';

import { useCurrentOrganization } from 'pkg/identity';
import { Action } from 'pkg/api/models';

import {
	LargeScreen,
	SmallScreen,
	useLargeScreen,
} from 'components/MediaQuery';

import { MaterialSymbolVariant } from 'components/material-symbols/symbols';
import SmallScreenHeader from 'components/navigation/header/small_screen';
import LargeScreenHeader from 'components/navigation/header/large_screen';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import SmallScreenContent from 'components/layout/SmallScreenContent';
import * as LargeLayout from 'components/navigation/header/large_screen/Styles';
import * as SmallLayout from 'components/navigation/header/small_screen/Styles';
import { ViewCrash } from 'components/crashes/view-crash';
import { useAppState } from 'components/application/state';
import {
	useTemplateContext,
	TemplateContextWrapper,
} from 'components/layout/page-templates/context';

export interface SubNavItemProps {
	title: string;
	replace?: boolean;
	href?: string;
	to?: string;
	params?: any;
	query?: any;
	onClick?: any;
	testid?: string;
	allowedToViewItem?: Action[];
}

export interface PageProps {
	title?: string;
	icon?: MaterialSymbolVariant;
	subNavItems?: SubNavItemProps[];
	backgroundColor?: string;
	children?: ReactNode;
	currentTab?: string;
	maxWidth?: styles.size.PageWidths;
	compactHeader?: boolean;
	subTitle?: string;
}

interface NavItemsProps {
	items: SubNavItemProps[];
}

export const LargeSubNavItems = ({ items }: NavItemsProps) => {
	const org = useCurrentOrganization();

	if (items?.length <= 1) {
		return null;
	}

	return (
		<Fragment>
			{items.map((item: SubNavItemProps, i) => {
				const orgParam = org?.id ? { organizationId: org.id } : null;
				const params = { ...item.params, ...orgParam };

				return (
					<LargeLayout.SubNavItem
						key={`${item.title}-${i}`}
						replace={item.replace}
						href={item.href}
						to={item.to}
						params={params}
						query={item.query}
						onClick={item.onClick}
						data-testid={item.testid}>
						{item.title}
					</LargeLayout.SubNavItem>
				);
			})}
		</Fragment>
	);
};

export const SmallSubNavItems = ({ items }: NavItemsProps) => {
	const org = useCurrentOrganization();

	if (items?.length <= 1) {
		return null;
	}

	return (
		<Fragment>
			{items.map((item: SubNavItemProps, i) => {
				const orgParam = org?.id ? { organizationId: org.id } : null;
				const params = { ...item.params, ...orgParam };

				return (
					<SmallLayout.SubNavItem
						key={`${item.title}-${i}`}
						replace={item.replace}
						href={item.href}
						to={item.to}
						query={item.query}
						onClick={item.onClick}
						params={params}
						data-testid={item.testid}>
						{item.title}
					</SmallLayout.SubNavItem>
				);
			})}
		</Fragment>
	);
};

export const FormPage = ({ title, icon, children }: PageProps) => {
	return (
		<TemplateContextWrapper title={title} icon={icon}>
			<FormPageContent>{children}</FormPageContent>
		</TemplateContextWrapper>
	);
};

function FormPageContent({ children }: PropsWithChildren) {
	const { pageActions, pageTitle, pageIcon } = useTemplateContext();

	return (
		<Fragment>
			<LargeScreen>
				<LargeScreenHeader
					title={pageTitle}
					icon={pageIcon}></LargeScreenHeader>

				<LargeScreenContent.Wrapper backgroundColor={styles.palette.white}>
					<ErrorBoundary fallback={<ViewCrash />}>{children}</ErrorBoundary>
				</LargeScreenContent.Wrapper>
			</LargeScreen>

			<SmallScreen>
				<SmallScreenHeader title={pageTitle} actionTrigger={pageActions} />

				<SmallScreenContent formPage backgroundColor={styles.palette.white}>
					<ErrorBoundary fallback={<ViewCrash />}>{children}</ErrorBoundary>
				</SmallScreenContent>
			</SmallScreen>
		</Fragment>
	);
}

export const Page = ({
	title,
	icon,
	subNavItems = [],
	backgroundColor,
	children,
	currentTab,
	subTitle,
}: PageProps) => {
	return (
		<TemplateContextWrapper
			title={title}
			icon={icon}
			subNavItems={subNavItems}
			backgroundColor={backgroundColor}
			subTitle={subTitle}
			currentTab={currentTab}>
			<PageContent>{children}</PageContent>
		</TemplateContextWrapper>
	);
};

function PageContent({ children }: PropsWithChildren) {
	const {
		pageTitle,
		pageIcon,
		pageActions,
		pageSubNavItems,
		pageBackgroundColor,
		pageSubTitle,
		noScroll,
		currentTab,
	} = useTemplateContext();

	const hasSubNav = pageSubNavItems.length > 1;

	return (
		<Fragment>
			<LargeScreen>
				<LargeScreenHeader title={pageTitle} icon={pageIcon}>
					{hasSubNav && <LargeSubNavItems items={pageSubNavItems} />}
				</LargeScreenHeader>

				<LargeScreenContent.Wrapper
					key={currentTab}
					backgroundColor={pageBackgroundColor}>
					<ErrorBoundary fallback={<ViewCrash />}>{children}</ErrorBoundary>
				</LargeScreenContent.Wrapper>
			</LargeScreen>

			<SmallScreen>
				<SmallScreenHeader
					subTitle={pageSubTitle}
					title={pageTitle}
					actionTrigger={pageActions}
				/>

				<SmallScreenContent
					backgroundColor={pageBackgroundColor}
					noScroll={noScroll}>
					{hasSubNav && (
						<SmallLayout.SubNav>
							<SmallSubNavItems items={pageSubNavItems} />
						</SmallLayout.SubNav>
					)}
					<ErrorBoundary fallback={<ViewCrash />}>{children}</ErrorBoundary>
				</SmallScreenContent>
			</SmallScreen>
		</Fragment>
	);
}

export const SimplePage = ({
	title,
	icon,
	subNavItems = [],
	children,
	backgroundColor,

	currentTab,
}: PageProps) => {
	return (
		<TemplateContextWrapper
			title={title}
			icon={icon}
			subNavItems={subNavItems}
			backgroundColor={backgroundColor}
			currentTab={currentTab}>
			<SimplePageProps>{children}</SimplePageProps>
		</TemplateContextWrapper>
	);
};

function SimplePageProps({ children }: PropsWithChildren) {
	const {
		pageBackgroundColor,
		currentTab,
		pageTitle,
		pageIcon,
		pageSubNavItems,
		pageActions,
	} = useTemplateContext();

	const hasSubNav = pageSubNavItems.length > 0;

	return (
		<Fragment>
			<LargeScreen>
				<LargeScreenHeader title={pageTitle} icon={pageIcon}>
					{hasSubNav && <LargeSubNavItems items={pageSubNavItems} />}
				</LargeScreenHeader>

				<LargeScreenContent.Wrapper
					key={currentTab}
					backgroundColor={pageBackgroundColor}>
					<LargeScreenContent.Inner maxWidth={styles.size.PageWidths.STANDARD}>
						<ErrorBoundary fallback={<ViewCrash />}>{children}</ErrorBoundary>
					</LargeScreenContent.Inner>
				</LargeScreenContent.Wrapper>
			</LargeScreen>

			<SmallScreen>
				<SmallScreenHeader title={pageTitle} actionTrigger={pageActions} />

				<SmallScreenContent backgroundColor={pageBackgroundColor}>
					{hasSubNav && (
						<SmallLayout.SubNav>
							<SmallSubNavItems items={pageSubNavItems} />
						</SmallLayout.SubNav>
					)}
					<LargeScreenContent.Inner>
						<ErrorBoundary fallback={<ViewCrash />}>{children}</ErrorBoundary>
					</LargeScreenContent.Inner>
				</SmallScreenContent>
			</SmallScreen>
		</Fragment>
	);
}

export const WidePage = ({
	title,
	icon,
	subNavItems = [],
	maxWidth = styles.size.PageWidths.WIDE,
	children,
	currentTab,
}: PageProps) => {
	return (
		<TemplateContextWrapper
			title={title}
			icon={icon}
			subNavItems={subNavItems}
			currentTab={currentTab}
			maxWidth={maxWidth}>
			<WidePageContent>{children}</WidePageContent>
		</TemplateContextWrapper>
	);
};

function WidePageContent({ children }: PropsWithChildren) {
	const {
		pageTitle,
		pageIcon,
		pageSubNavItems,
		currentTab,
		maxWidth,
		pageActions,
	} = useTemplateContext();

	const hasSubNav = pageSubNavItems.length > 0;

	return (
		<Fragment>
			<LargeScreen>
				<LargeScreenHeader title={pageTitle} icon={pageIcon}>
					{hasSubNav && <LargeSubNavItems items={pageSubNavItems} />}
				</LargeScreenHeader>

				<LargeScreenContent.Wrapper key={currentTab}>
					<LargeScreenContent.Inner maxWidth={maxWidth}>
						<ErrorBoundary fallback={<ViewCrash />}>{children}</ErrorBoundary>
					</LargeScreenContent.Inner>
				</LargeScreenContent.Wrapper>
			</LargeScreen>

			<SmallScreen>
				<SmallScreenHeader title={pageTitle} actionTrigger={pageActions} />

				<SmallScreenContent>
					{hasSubNav && (
						<SmallLayout.SubNav>
							<SmallSubNavItems items={pageSubNavItems} />
						</SmallLayout.SubNav>
					)}
					<LargeScreenContent.Inner>
						<ErrorBoundary fallback={<ViewCrash />}>{children}</ErrorBoundary>
					</LargeScreenContent.Inner>
				</SmallScreenContent>
			</SmallScreen>
		</Fragment>
	);
}

// FullScreenPage locks scrolling, and removes content padding in mobile.
export const FullScreenPage = ({
	title,
	icon,
	subNavItems = [],
	children,
	currentTab,
}: PageProps) => {
	return (
		<TemplateContextWrapper
			title={title}
			icon={icon}
			subNavItems={subNavItems}
			currentTab={currentTab}>
			<FullScreenPageContent>{children}</FullScreenPageContent>
		</TemplateContextWrapper>
	);
};

function FullScreenPageContent({ children }: PropsWithChildren) {
	const { pageTitle, pageIcon, pageSubNavItems, pageActions, currentTab } =
		useTemplateContext();

	const hasSubNav = pageSubNavItems.length > 0;

	return (
		<Fragment>
			<LargeScreen>
				<LargeScreenHeader title={pageTitle} icon={pageIcon}>
					{hasSubNav && <LargeSubNavItems items={pageSubNavItems} />}
				</LargeScreenHeader>

				<LargeScreenContent.Wrapper key={currentTab} disableScroll>
					<ErrorBoundary fallback={<ViewCrash />}>{children}</ErrorBoundary>
				</LargeScreenContent.Wrapper>
			</LargeScreen>

			<SmallScreen>
				<SmallScreenHeader title={pageTitle} actionTrigger={pageActions} />

				<SmallScreenContent noScroll>
					{hasSubNav && (
						<SmallLayout.SubNav>
							<SmallSubNavItems items={pageSubNavItems} />
						</SmallLayout.SubNav>
					)}
					<ErrorBoundary fallback={<ViewCrash />}>{children}</ErrorBoundary>
				</SmallScreenContent>
			</SmallScreen>
		</Fragment>
	);
}

// FullScreenPageWithoutBorderRadius removes content padding in mobile.
export const FullScreenPageWithoutBorderRadius = ({
	title,
	icon,
	compactHeader,
	subNavItems = [],
	children,
	currentTab,
}: PageProps) => {
	return (
		<TemplateContextWrapper
			title={title}
			icon={icon}
			subNavItems={subNavItems}
			currentTab={currentTab}>
			<FullScreenPageWithoutBorderRadiusContent compactHeader={compactHeader}>
				{children}
			</FullScreenPageWithoutBorderRadiusContent>
		</TemplateContextWrapper>
	);
};

interface FullScreenPageWithoutBorderRadiusContentProps
	extends PropsWithChildren {
	compactHeader: boolean;
}

function FullScreenPageWithoutBorderRadiusContent({
	children,
	compactHeader,
}: FullScreenPageWithoutBorderRadiusContentProps) {
	const { pageTitle, pageIcon, pageSubNavItems, currentTab, pageActions } =
		useTemplateContext();

	const hasSubNav = pageSubNavItems.length > 0;

	return (
		<Fragment>
			<LargeScreen>
				<LargeScreenHeader
					title={pageTitle}
					icon={pageIcon}
					compact={!!compactHeader}>
					{hasSubNav && <LargeSubNavItems items={pageSubNavItems} />}
				</LargeScreenHeader>

				<LargeScreenContent.Wrapper key={currentTab}>
					<ErrorBoundary fallback={<ViewCrash />}>{children}</ErrorBoundary>
				</LargeScreenContent.Wrapper>
			</LargeScreen>

			<SmallScreen>
				<SmallScreenHeader title={pageTitle} actionTrigger={pageActions} />

				<SmallScreenContent noBorderRadius>
					{hasSubNav && (
						<SmallLayout.SubNav>
							<SmallSubNavItems items={pageSubNavItems} />
						</SmallLayout.SubNav>
					)}
					<ErrorBoundary fallback={<ViewCrash />}>{children}</ErrorBoundary>
				</SmallScreenContent>
			</SmallScreen>
		</Fragment>
	);
}

export const FullScreenPageWithoutHeader = ({ children }: PageProps) => {
	return (
		<Fragment>
			<LargeScreen>
				<LargeScreenContent.Wrapper backgroundColor={styles.palette.white}>
					<ErrorBoundary fallback={<ViewCrash />}>{children}</ErrorBoundary>
				</LargeScreenContent.Wrapper>
			</LargeScreen>

			<SmallScreen>
				<SmallScreenContent noBorderRadius noPadding>
					<ErrorBoundary fallback={<ViewCrash />}>{children}</ErrorBoundary>
				</SmallScreenContent>
			</SmallScreen>
		</Fragment>
	);
};

export const TableContentWrapper = styled.div<{
	fullPageTable?: boolean;
	actionBarElement?: boolean;
	hasPagination?: boolean;
}>`
	display: flex;
	flex-flow: column nowrap;
	max-height: 100%;
	min-height: 100%;

	${({ fullPageTable, actionBarElement, hasPagination }) =>
		fullPageTable &&
		css`
			padding: 0 var(--spacing-5);

			${!hasPagination &&
			css`
				padding-bottom: var(--spacing-5);
			`};

			${!actionBarElement &&
			css`
				padding: var(--spacing-5);
			`};
		`};
`;

export function useFullScreenTableContext() {
	return useContext(FullScreenTableContext);
}

export const FullScreenTableContext = createContext({
	fullPageTable: false,
});

export function FullScreenTableContextProvider({
	children,
}: {
	children: ReactNode;
}) {
	const isLargeScreen = useLargeScreen();
	const fullPageTable = isLargeScreen;

	return (
		<FullScreenTableContext.Provider value={{ fullPageTable }}>
			{children}
		</FullScreenTableContext.Provider>
	);
}

// Used when full screen tables need sticky header and footer
export const FullScreenTablePage = ({
	title,
	icon,
	subNavItems = [],
	children,
	currentTab,
}: PageProps) => {
	return (
		<TemplateContextWrapper
			title={title}
			icon={icon}
			subNavItems={subNavItems}
			currentTab={currentTab}>
			<FullScreenTablePageContent>{children}</FullScreenTablePageContent>
		</TemplateContextWrapper>
	);
};

function FullScreenTablePageContent({ children }: PropsWithChildren) {
	const isLargeScreen = useLargeScreen();
	const { hasActionBar, hasPagination } = useAppState();

	const { pageTitle, pageIcon, pageSubNavItems, currentTab, pageActions } =
		useTemplateContext();

	const hasSubNav = pageSubNavItems.length > 0;

	return (
		<FullScreenTableContextProvider>
			<LargeScreen>
				<LargeScreenHeader title={pageTitle} icon={pageIcon}>
					{hasSubNav && <LargeSubNavItems items={pageSubNavItems} />}
				</LargeScreenHeader>

				<LargeScreenContent.Wrapper
					key={currentTab}
					disableScroll
					backgroundColor={styles.palette.white}>
					<TableContentWrapper
						fullPageTable={isLargeScreen}
						actionBarElement={hasActionBar}
						hasPagination={hasPagination}>
						<ErrorBoundary fallback={<ViewCrash />}>{children}</ErrorBoundary>
					</TableContentWrapper>
				</LargeScreenContent.Wrapper>
			</LargeScreen>

			<SmallScreen>
				<SmallScreenHeader title={pageTitle} actionTrigger={pageActions} />

				<SmallScreenContent noScroll>
					{hasSubNav && (
						<SmallLayout.SubNav>
							<SmallSubNavItems items={pageSubNavItems} />
						</SmallLayout.SubNav>
					)}
					<TableContentWrapper>
						<ErrorBoundary fallback={<ViewCrash />}>{children}</ErrorBoundary>
					</TableContentWrapper>
				</SmallScreenContent>
			</SmallScreen>
		</FullScreenTableContextProvider>
	);
}

export const PrintablePage = ({ children }: PageProps) => {
	return (
		<Fragment>
			<ErrorBoundary fallback={<ViewCrash />}>{children}</ErrorBoundary>
		</Fragment>
	);
};
