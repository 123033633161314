import { ReactNode } from 'react';

export const symbol_water_loss: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M444-330q-52 0-102 14.5T247-275l15 135h436l17-150h-18q-37 0-68-5.5T544-316q-25-7-49.5-10.5T444-330Zm-204-10q47-24 98.965-37T444-390q30 0 59.5 4t56.5 12q51.128 14 78.226 19 27.097 5 57.774 5h25l52-470H187l53 480Zm22 260q-23 0-40.344-14.93Q204.312-109.859 202-133l-74-673q-3-30 17.106-52 20.107-22 49.894-22h570q29.787 0 49.894 22Q835-836 832-806l-74 673q-2.312 23.141-19.656 38.07Q721-80 698-80H262Zm182-60h254-436 182Z"
		/>
	</svg>
);
