import { useState } from 'react';
import { useDispatch } from 'react-redux';

import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import * as actions from 'pkg/actions';
import * as Apple from 'pkg/apple';
import * as language from 'pkg/i18n/language';
import * as platform from 'pkg/platform';

export interface ConnectResponse {
	firstName?: string;
	lastName?: string;
	email?: string;
}

type ConnectInitiator = () => Promise<void>;

type ConnectSuccessCallback = (
	token: string,
	response: ConnectResponse
) => void;

type ConnectFailureCallback = () => void;

type LoginInitiator = () => Promise<void>;

interface ConnectHook {
	isLoading: boolean;

	signup: ConnectInitiator;
	login: LoginInitiator;

	emitSuccess: ConnectSuccessCallback;
	emitFailure: ConnectFailureCallback;
}

interface ConnectHookArguments {
	success?: ConnectSuccessCallback;
	failure?: ConnectFailureCallback;
}

interface AppleSDKOptions {
	renderButton?: boolean;
}

export function useAppleSDK(options?: AppleSDKOptions) {
	const [instance, set] = useState<any>();

	useComponentDidMount(async () => {
		const sdk = await Apple.setup();

		if (sdk) {
			set(sdk);

			if (options?.renderButton === true) {
				sdk.renderButton();
			}
		}
	});

	return instance;
}

export function useConnectWithApple({
	success,
	failure,
}: ConnectHookArguments): ConnectHook {
	const sdk = useAppleSDK({
		renderButton: !platform.isIOS(),
	});

	if (!success) {
		success = async () => Promise.resolve();
	}

	if (!failure) {
		failure = async () => Promise.reject();
	}

	const dispatch = useDispatch();

	const [isLoading, setLoading] = useState<boolean>(false);

	let authenticateOnSuccess: boolean = false;

	const handleConnectSuccess = async (token: string, response: any) => {
		if (response.email.length > 0) {
			const payload = {
				token: response.identityToken,
				firstname: response.fullName.givenName,
				lastname: response.fullName.familyName,
				email: response.email,
			};

			const request = await actions.auth.saveAppleSignInCredentials(payload);

			if (!request) {
				failure();
				return;
			}

			success(token, {
				firstName: response.fullName.givenName,
				lastName: response.fullName.familyName,
				email: response.email,
			});

			return;
		}

		if (authenticateOnSuccess && token) {
			const res = await dispatch(
				actions.auth.authWithApple(token, language.get())
			);

			if (!res) {
				failure();
				return;
			} else {
				success(token, {});
			}

			return;
		} else {
			// @NOTE When we get to here we're not authenticating, and it isn't a first-time SIWA connection
			success(token, {});
		}
	};

	const handleConnectFailure = async (error: any) => {
		if ([1000, 1002].includes(error.code)) {
			failure();
		}

		setLoading(false);
	};

	const signup: ConnectInitiator = async () => {
		setLoading(true);

		Apple.connect(sdk, handleConnectSuccess, handleConnectFailure);
	};

	const login: LoginInitiator = async (): Promise<void> => {
		setLoading(true);

		if (platform.isIOS()) {
			authenticateOnSuccess = true;
		}

		Apple.connect(sdk, handleConnectSuccess, handleConnectFailure);

		return Promise.resolve();
	};

	return {
		isLoading,
		signup,
		login,

		emitSuccess: success,
		emitFailure: failure,
	};
}
