import { ReactNode } from 'react';

export const symbol_format_textdirection_l_to_r: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M450-820v430q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T390-390v-190q-66 0-113-43t-47-106.5q0-63.5 43.5-107T380-880h250q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T630-820h-60v430q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T510-390v-430h-60Zm-60 180v-180q-42 0-71 24t-29 66q0 42 29 66t71 24Zm0-90Zm336 534H150q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-256h576l-53-53q-9-9-9-21t9-21q9-9 21-9t21 9l104 104q9 9 9 21t-9 21L715-101q-9 9-21 9t-21-9q-9-9-9-21t9-21l53-53Z"
		/>
	</svg>
);
