import { ReactNode } from 'react';

export const symbol_feed: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h437q12.444 0 23.722 5T660-822l162 162q8 8 13 19.278 5 11.278 5 23.722v437q0 24-18 42t-42 18H180Zm0-60h600v-429H639q-12.75 0-21.375-8.625T609-639v-141H180v600Zm471-111q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T651-351H309q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T309-291h342ZM450-609q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T450-669H309q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T309-609h141Zm201 159q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T651-510H309q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T309-450h342ZM180-780v171.429V-780v600-600Z"
		/>
	</svg>
);
