import { ReactNode } from 'react';

export const symbol_clinical_notes: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M680-330q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35Zm0-60q25.5 0 42.75-17.25T740-450q0-25.5-17.25-42.75T680-510q-25.5 0-42.75 17.25T620-450q0 25.5 17.25 42.75T680-390ZM470-50q-12.75 0-21.375-8.625T440-80v-86q0-21 10-39.5t28-29.5q28-17 47.5-25t51.5-16q9.667-2 19.333-.5Q606-275 612-267l68 88 68-88q6.25-7.857 15.625-9.429Q773-278 783-276q32 8 51 16t47 25q18 11 28.5 29.5T920-166v86q0 12.75-8.625 21.375T890-50H470Zm30-60h157l-82-106q-20 8-39 17.5T500-178v68Zm203 0h157v-68q-17-11-35.5-20.5T786-216l-83 106Zm-46 0Zm46 0Zm-23-340ZM180-180v-600 180-30 450Zm0 60q-24.75 0-42.375-17.625T120-180v-600q0-24.75 17.625-42.375T180-840h600q24.75 0 42.375 17.625T840-780v247q-11-20-26-37t-34-30v-180H180v600h200v60H180Zm130-500h311q14-5 28.84-7.5Q664.681-630 680-630v-50H310q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T310-620Zm0 170h190q0-15 2.5-30.5T510-510H310q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T310-450Zm0 170h128q17-13 36-21.5t39-16.5q-4-11-11-16.5t-21-5.5H310q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T310-280Z"
		/>
	</svg>
);
