import { ReactNode } from 'react';

export const symbol_attribution: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-617q-51 0-77 11.5T377-570v168q0 6 4.5 10.5T392-387h44v136q0 18.48 12.76 31.24Q461.52-207 480-207q18.48 0 31.24-12.76Q524-232.52 524-251v-136h44q6 0 10.5-4.5T583-402v-168q0-24-26-35.5T480-617Zm0 537q-84 0-157-31t-127-85q-54-54-85-127T80-480q0-83.728 31-156.864Q142-710 196-764t127-85q73-31 157-31 83.728 0 156.864 31Q710-818 764-764t85 127.136Q880-563.728 880-480q0 84-31 157t-85 127q-54 54-127.136 85T480-80Zm0-60q145 0 242.5-97.5T820-480q0-145-97.5-242.5T480-820q-145 0-242.5 97.5T140-480q0 145 97.5 242.5T480-140Zm0-506q23.4 0 38.7-15.3Q534-676.6 534-700q0-23.4-15.3-38.7Q503.4-754 480-754q-23.4 0-38.7 15.3Q426-723.4 426-700q0 23.4 15.3 38.7Q456.6-646 480-646Zm0 166Z"
		/>
	</svg>
);
