import { ReactNode } from 'react';

export const symbol_playlist_add_check_circle: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m562-324-35-35q-9.067-9.231-21.533-9.615Q493-369 484-359.947q-9 9.052-9 21.5Q475-326 484-317l57 57q9 9 21 9t21-9l142-143q9-9 9-21t-9-21.391Q716-454 704-454t-21 9L562-324Zm-237-36h30q14 0 24.5-10.5T390-395q0-14-10.5-24.5T355-430h-30q-14 0-24.5 10.5T290-395q0 14 10.5 24.5T325-360Zm-5-130h200q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T520-550H320q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T320-490Zm0-120h200q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T520-670H320q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T320-610ZM480.266-80q-82.734 0-155.5-31.5t-127.266-86q-54.5-54.5-86-127.341Q80-397.681 80-480.5q0-82.819 31.5-155.659Q143-709 197.5-763t127.341-85.5Q397.681-880 480.5-880q82.819 0 155.659 31.5Q709-817 763-763t85.5 127Q880-563 880-480.266q0 82.734-31.5 155.5T763-197.684q-54 54.316-127 86Q563-80 480.266-80Zm.234-60Q622-140 721-239.5t99-241Q820-622 721.188-721 622.375-820 480-820q-141 0-240.5 98.812Q140-622.375 140-480q0 141 99.5 240.5t241 99.5Zm-.5-340Z"
		/>
	</svg>
);
