import { ReactNode } from 'react';

export const symbol_contrast_rtl_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m812-63-94-93q-50 36-110 56T480-80q-85 0-158-30.5T195-195q-54-54-84.5-127T80-480q0-68 20-128t56-110L47-827q-9-9-8.5-21.5T48-870q9-9 21.5-9t21.5 9l764 765q9 9 9 21t-9 21q-9 9-21.5 9T812-63Zm-332-77q55 0 104-15.5t91-43.5L480-394v254Zm400-340q0 57-14 108t-40 96q-6 11-17.5 13t-22.5-5q-11-7-13.5-19t3.5-23q21-38 32.5-80.5T820-480q0-145-97.5-242.5T480-820v218q0 10-9.5 13.5T454-592L277-769q-14-14-12-33t19-29q44-24 93-36.5T480-880q84 0 157 31t127 85q54 54 85 127t31 157Z"
		/>
	</svg>
);
