import { ReactNode } from 'react';

export const symbol_sim_card_download: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M512-394v-164q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T452-558v164l-62-62q-9-9-21.1-9-12.1 0-20.9 9-9 9-9 21.5t9 21.5l113 114q9 9 21 9t21-9l114-114q9-9 9-21.5t-9-21.5q-9-9-21.5-9t-21.5 9l-62 62ZM220-80q-24 0-42-18t-18-42v-476q0-12.444 5-23.722T178-659l203-203q8-8 19.278-13 11.278-5 23.722-5h316q24 0 42 18t18 42v680q0 24-18 42t-42 18H220Zm0-60h520v-680H426L220-613.875V-140Zm0 0h520-520Z"
		/>
	</svg>
);
