import { t } from '@transifex/native';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import { RootState } from 'pkg/reducers';
import { formatToCurrency } from 'pkg/i18n/formatters';
import { formatPriceIntervalToText } from 'pkg/utils';
import DateTime from 'pkg/datetime';
import * as selectors from 'pkg/selectors';

import { LargeScreen } from 'components/MediaQuery';

import Column from 'components/layout/column';
import * as Table from 'components/form/Table';

import * as css from './styles.css';

const TotalCostWrapper = styled.div`
	display: flex;
	align-items: flex-end;
	flex-direction: column;
`;

const Calculation = styled.div`
	p {
		color: var(--palette-gray-500);
		font-size: var(--font-size-xs);
	}

	text-align: right;
`;

const Subtotal = styled.div`
	font-size: var(--font-size-lg);
	font-weight: var(--font-weight-semibold);
`;

const Total = styled.div`
	font-size: var(--font-size-2xl);
	font-weight: var(--font-weight-semibold);
`;

const Divider = styled.hr`
	width: 200px;
	border: none;
	border-top: 1px solid var(--palette-gray-300);
	margin: var(--spacing-5) 0;
`;

interface ProductCalculationProps {
	order: models.order.Order;
	groupId: number;
}

interface ProductCalculationRowProps {
	productRow: models.orderRow.OrderRow;
	currency: string;
}

interface TotalCostProps {
	order: models.order.Order;
	currency: string;
}

const ProductCalculations: React.FC<
	React.PropsWithChildren<ProductCalculationProps>
> = ({ groupId, order }) => {
	const group = useSelector((state: RootState) =>
		selectors.groups.find(state, groupId)
	);

	const currencySymbol = new Intl.NumberFormat(DateTime.getLocale(), {
		style: 'currency',
		currency: group.currency,
	})
		.formatToParts(1000)
		.find((i) => i.type === 'currency');

	return (
		<Column spacing={styles.spacing._7}>
			<Table.Table>
				<thead>
					<Table.TableHeadRow>
						<Table.HeadCell>{t(`Product description`)}</Table.HeadCell>
						<Table.HeadCell>{t(`Quantity`)}</Table.HeadCell>
						<Table.HeadCell>
							{t(`Price {currency}`, {
								currency: currencySymbol.value,
							})}
						</Table.HeadCell>
						<Table.HeadCell>{t(`Tax`)}</Table.HeadCell>
						<Table.HeadCell>
							{t(`Total {currency}`, {
								currency: currencySymbol.value,
							})}
						</Table.HeadCell>
					</Table.TableHeadRow>
				</thead>
				<tbody>
					{order.rows.map((row) => (
						<ProductCalculationsRow
							key={row.id}
							productRow={row}
							currency={group.currency}
						/>
					))}
				</tbody>
			</Table.Table>
			<TotalCost order={order} currency={group.currency} />
		</Column>
	);
};

const ProductCalculationsRow: React.FC<
	React.PropsWithChildren<ProductCalculationRowProps>
> = ({ productRow, currency }) => {
	const price = productRow.productPrice;

	let total = productRow.amount / 100;

	if (!productRow.taxInclusive) {
		total += total * (productRow.taxPercentage / 100);
	}

	total = total * productRow.quantity;

	let discountDescription;

	if (productRow.discount) {
		const code = <div className={css.boldCode}>{productRow.discount.code}</div>;
		discountDescription = (
			<div>
				{productRow.discount.title} ({code})
			</div>
		);
	}

	return (
		<Table.TableRow>
			<Table.Cell>
				{productRow.discount ? discountDescription : productRow.description}
			</Table.Cell>
			<LargeScreen>
				<Table.Cell>{productRow.quantity}</Table.Cell>
				<Table.Cell>
					{price &&
						`${formatToCurrency(
							productRow.amount / 100,
							currency
						)} / ${formatPriceIntervalToText(
							price.recurringInterval,
							price.recurringIntervalCount
						)}`}
				</Table.Cell>

				<Table.Cell>
					{productRow.taxRateId &&
						`${productRow.taxPercentage}% ${
							productRow.taxInclusive === true ? t(`incl.`) : ''
						}`}
				</Table.Cell>
			</LargeScreen>
			<Table.Cell>{formatToCurrency(total, currency)}</Table.Cell>
		</Table.TableRow>
	);
};

const TotalCost: React.FC<React.PropsWithChildren<TotalCostProps>> = ({
	order,
	currency,
}) => {
	const { subtotal, taxes } = getCosts(order.rows);
	const totalAmount = order.amountDue / 100;

	return (
		<TotalCostWrapper>
			<Calculation>
				<p>{t(`Subtotal`)}</p>
				<Subtotal>{formatToCurrency(subtotal, currency)}</Subtotal>
			</Calculation>
			<Divider />
			<Calculation>
				<p>{t(`Total amount`)}</p>
				<Total>{formatToCurrency(totalAmount, currency)}</Total>
				{Object.entries(taxes).map(([key, tax]) => (
					<p key={key}>
						{t(`Tax {amount} {percentage}%`, {
							amount: formatToCurrency(tax.amount, currency),
							percentage: key || 0,
						})}
						{tax.isInclusive && ` ${t(`incl.`)}`}
					</p>
				))}
			</Calculation>
		</TotalCostWrapper>
	);
};

interface CostReturnProps {
	taxes: { [key: number]: { isInclusive: boolean; amount: number } };
	subtotal: number;
}

const getCosts = (productRows: models.orderRow.OrderRow[]): CostReturnProps => {
	let subtotal = 0;
	const taxes: { [key: number]: { isInclusive: boolean; amount: number } } = {};

	for (const productRow of productRows) {
		const { taxPercentage, taxInclusive, amount, quantity } = productRow;
		const cost = amount / 100;

		const taxAmount = taxInclusive
			? ((amount * (taxPercentage / 100)) / 100) * quantity
			: cost * (taxPercentage / 100) * quantity;

		subtotal += cost * quantity;

		if (taxes[taxPercentage]) {
			taxes[taxPercentage] = {
				...taxes[taxPercentage],
				amount: taxes[taxPercentage].amount + taxAmount,
			};
		} else {
			taxes[taxPercentage] = {
				isInclusive: productRow.taxInclusive,
				amount: taxAmount,
			};
		}
	}

	return {
		taxes,
		subtotal,
	};
};

export default ProductCalculations;
