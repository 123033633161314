import { useRef } from 'react';

import * as models from 'pkg/api/models';
import { ResourceLocationUpdatePayload } from 'pkg/api/models/resource_location';

import LocationForm from 'routes/scheduling/locations/LocationForm';

import * as StepModal from 'components/step-modal';

import Form, { FormPayload } from 'components/form/Form';

interface EditLocationModalProps {
	location: models.resourceLocation.ResourceLocation;

	onClose: () => void;
	handleEditLocation: (payload: ResourceLocationUpdatePayload) => void;
}

const EditLocationModal = ({
	location,
	onClose,
	handleEditLocation,
}: EditLocationModalProps) => {
	const formRef = useRef(null);

	const handleSubmit = async (data: FormPayload) => {
		const payload = {
			title: data.title as string,
		};

		handleEditLocation(payload);
	};

	const handleNext = async () =>
		await formRef.current.dispatchEvent(
			new Event('submit', {
				cancelable: true,
				bubbles: true,
			})
		);

	return (
		<StepModal.Base onClose={onClose}>
			<StepModal.Step onNext={handleNext}>
				<Form formRef={formRef} onSubmit={handleSubmit}>
					<LocationForm location={location} />
				</Form>
			</StepModal.Step>
		</StepModal.Base>
	);
};

export default EditLocationModal;
