import { ReactNode } from 'react';

export const symbol_supervised_user_circle_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-66 20.5-126T158-716l-76-76q-9-9-8.5-21.5T83-835q9-9 21.5-9t21.5 9l709 710q9 9 9 21t-9 21q-9 9-21.5 9T792-83l-76-75q-50 37-110 57.5T480-80Zm-58-64q32-59 77-92.5t91-47.5L200-674q-29 42-44.5 91T140-480q0 51 15 98.5t41 85.5q41-22 85.5-33t91.5-11q29 0 56.5 4t50.5 14q-15 10-30 21.5T423-278q-14-2-26.5-2H373q-37 0-73 8t-69 25q38 40 87.5 67.5T422-144Zm87-393L352-694q70-11 119 38.5T509-537ZM373-420q-57 0-97.5-40.5T235-558q0-34 15-63t41-48l193 193q-19 26-48 41t-63 15Zm299 46L568-478q0-44 31-76t75-32q44 0 75 31t31 75q0 44-32 75t-76 31Zm-235-63Zm116-116ZM275-824q47-28 99-42t106-14q80 0 153 30t130 87q57 57 87 130t30 153q0 54-13.5 105T827-277q-6 11-18 13.5t-23-4.5q-11-7-13-19.5t4-23.5q21-39 32-81.5t11-87.5q0-142-99-241t-241-99q-45 0-88 11t-82 33q-11 6-22.5 4T268-784q-8-10-6-22t13-18Z"
		/>
	</svg>
);
