import {
	ReactNode,
	createContext,
	useContext,
	useState,
	PointerEvent,
	Fragment,
	useEffect,
} from 'react';

import { cssClasses } from 'pkg/css/utils';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';

import * as css from './style.css';

type TabIdentifier = string;

export interface Tab {
	id: TabIdentifier;
	label: string;
	className?: string;
	onClick?: (event: PointerEvent) => void;
}

interface TabBarState {
	tabs: Tab[];
	activeTab?: Tab;
	activate: (tabId: TabIdentifier) => void;
}

const TabBarContext = createContext<TabBarState>({
	tabs: [],
	activate: () => null,
});

export function useTabBar(): TabBarState {
	return useContext(TabBarContext);
}

interface TabBarProps {
	tabs: Tab[];
	defaultActiveTabId?: string;
	className?: string;
	itemClassName?: string;
	onChange?: (tab: Tab) => void;
	children?: ReactNode | ReactNode[];
}

export function TabBar({
	tabs,
	defaultActiveTabId = '',
	className,
	itemClassName,
	onChange,
	children,
}: TabBarProps): JSX.Element {
	const [activeTabId, setActiveTabId] = useState<string>(defaultActiveTabId);

	const activeTab = tabs.find((tab: Tab) => tab.id === activeTabId);

	const activate = (tabId: TabIdentifier) => {
		setActiveTabId(tabId);
	};

	useComponentDidMount(() => {
		if (!defaultActiveTabId && tabs.length > 0) {
			setActiveTabId(tabs[0].id);
		}
	});

	useEffect(() => {
		if (onChange) {
			onChange(activeTab);
		}
	}, [activeTabId]);

	return (
		<TabBarContext.Provider value={{ tabs, activeTab, activate }}>
			<ul className={cssClasses(css.bar, className)}>
				{tabs.map((tab: Tab) => (
					<TabBarItem key={tab.id} {...tab} className={itemClassName} />
				))}
			</ul>
			{children}
		</TabBarContext.Provider>
	);
}

function TabBarItem({ id, label, onClick, className }: Tab): JSX.Element {
	const ctx = useTabBar();

	const handleActivate = (event: PointerEvent<HTMLLIElement>) => {
		ctx.activate(id);

		if (onClick) {
			onClick(event);
		}
	};

	return (
		<li
			id={id}
			className={cssClasses(
				css.tab,
				className,
				ctx.activeTab?.id === id ? css.active : undefined
			)}
			onClick={handleActivate}>
			{label}
		</li>
	);
}

interface TabViewProps {
	tabId: TabIdentifier;
	className?: string;
	children?: ReactNode | ReactNode[];
}

export function TabView({
	tabId,
	className,
	children,
}: TabViewProps): JSX.Element {
	const ctx = useTabBar();

	if (ctx.activeTab?.id !== tabId) {
		return null;
	}

	if (className) {
		return <div className={className}>{children}</div>;
	}

	return <Fragment>{children}</Fragment>;
}
