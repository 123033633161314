import { ReactNode, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useAccountIdentity } from 'pkg/identity/account';
import { useEndpoint } from 'pkg/api/use_endpoint';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as actions from 'pkg/actions';
import * as models from 'pkg/api/models';

import { Spinner } from 'components/loaders/spinner';

export function IdentityObserver({ children }: { children: ReactNode }) {
	const { organization } = useAccountIdentity();
	const [checkedInitialMembership, setCheckedInitialMembership] =
		useState(false);
	const dispatch = useDispatch();

	useEndpoint(
		organization?.id
			? endpoints.Organizations.Show(organization.id)
			: undefined,
		{},
		(group: models.group.Group) => {
			actions.organizations.hydrateSplashScreenStateFromGroup(group);
		}
	);

	useEffect(() => {
		if (!checkedInitialMembership) {
			(async () => {
				if (organization?.id) {
					await actions.app.setInitialMembership(organization.id)(dispatch);
				}
				setCheckedInitialMembership(true);
			})();
		}
	}, [organization?.id]);

	if (!checkedInitialMembership) {
		return <Spinner center />;
	}

	return children;
}
