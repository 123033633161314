import { ReactNode } from 'react';

export const symbol_done_all: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M91-445q-9-9-8.5-21.5T92-488q9-9 21.5-9t21.5 9l159 160 11 11 11 11q9 9 9 21t-9 21l-1 1q-9 9-21 9t-21-9L91-445Zm373 117 363-363q9-9 21.5-9t21.5 9q9 9 9 21.5t-9 21.5L485-263q-9 9-21 9t-21-9L261-445q-9-9-8.5-21.5T262-488q9-9 21.5-9t21.5 9l159 160Zm236-320L485-433q-9 9-21 8.5t-21-9.5q-9-9-9-21.5t9-21.5l214-214q9-9 21.5-9t21.5 9q9 9 9 21.5t-9 21.5Z"
		/>
	</svg>
);
