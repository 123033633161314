import { ReactNode } from 'react';

export const symbol_format_text_wrap: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M189.825-161Q177-161 168.5-169.625T160-191v-579q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T220-770v579q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Zm580 0Q757-161 748.5-169.625T740-191v-579q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T800-770v579q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM363-284l-86-85q-9-9-9-21t9-21l86-85q9-8 21.1-8.5 12.1-.5 20.9 8.5 9 9 9 21t-9 21l-34 34h179q42 0 71-29t29-71q0-42-29-71t-71-29H280q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T280-680h270q66 0 113 47t47 113q0 66-47 113t-113 47H371l34 34q9 9 9 21.158 0 12.158-8.609 21Q396-275 383.9-275t-20.9-9Z"
		/>
	</svg>
);
