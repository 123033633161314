import { ReactNode } from 'react';

export const symbol_expand_all: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m480-168 174-174q9-9 22-9t22 9q9 9 9 22t-9 22L522-122q-17 17-42 17t-42-17L262-298q-9-9-9-22t9-22q9-9 22-9t22 9l174 174Zm0-624L306-618q-9 9-22 9t-22-9q-9-9-9-22t9-22l176-176q17-17 42-17t42 17l176 176q9 9 9 22t-9 22q-9 9-22 9t-22-9L480-792Z"
		/>
	</svg>
);
