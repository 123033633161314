import { ReactNode } from 'react';

export const symbol_nest_thermostat_sensor_eu: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-140q25 0 49-3.5t47-10.5q-27-30-41.5-67T520-299q0-16 2-31t6-29q-26-15-39-47t-13-74q0-57 24-94.5t73-37.5q42 0 66 29t30 74q17-5 34.5-8t35.5-3q20 0 40 3.5t40 10.5q-5-66-33.5-123.5t-74-100Q666-772 606.5-796T480-820q-70 0-132 26.5t-108.5 73q-46.5 46.5-73 108T140-480q0 70 26.5 132t73 108.5Q286-193 348-166.5T480-140Zm59-249q16-35 41.5-62.5T640-496q-3-39-20-62.5T573-582q-33 0-50 28t-17 74q0 35 9 57.5t24 33.5ZM740-80q-29 0-57-7.5T630-110q-36 15-74 22.5T480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q84 0 158 32.5t128.5 88Q821-704 851.5-629T880-470q38 32 59 76t21 94q0 92-64 156T740-80Zm0-60q66 0 113-47t47-113q0-66-47-113t-113-47q-66 0-113 47t-47 113q0 66 47 113t113 47ZM425-354H288q-6 0-10.5-4.5T273-369v-12q0-2 5-11l17-15q17-14 31.5-25.5T357-458q29-26 40-42.5t11-37.5q0-20-13.5-32T358-582q-21 0-36.5 10T298-543q-2 5-5.5 7.5T284-533q-6 0-10-4.5t-4-10.5l1-6q11-28 34-43t54-15q37 0 58 23t21 51q0 26-11.5 47.5T387-445q-9 8-28.5 23.5T318-387l1 3h106q6 0 10.5 4.5T440-369q0 6-4.5 10.5T425-354Z"
		/>
	</svg>
);
