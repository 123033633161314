import { Fragment, useEffect } from 'react';
import { t } from '@transifex/native';

import { VideoSequenceRecordingId } from 'pkg/video';

import useClipRecording from 'routes/video/shared/hooks/use-clip-recording';

import { usePlayerState } from 'components/video-analytics/PlayerState';
import { useCueState } from 'components/video-analytics/CueState';
import { usePlaybackState } from 'components/video-analytics/PlaybackState';
import { useClipState } from 'components/video-analytics/ClipState';

import Button from 'design/button';

import * as css from './styles.css';

interface RecordProps {
	save: () => Promise<void>;
	cancel: () => Promise<void>;
}

export default function Record({ save, cancel }: RecordProps): JSX.Element {
	const { isRecording, isTrimming, setTrimming, setEditing } = usePlayerState();
	const { currentCue, setCueId } = useCueState();
	const { currentTime } = usePlaybackState();
	const recording = useClipRecording();
	const clipState = useClipState();

	useEffect(() => {
		if (isRecording && !isTrimming) {
			clipState.setEndsAt(currentTime);
		}
	}, [currentTime]);

	if (isRecording && isTrimming) {
		const done = () => {
			setCueId(VideoSequenceRecordingId);

			setTrimming(false);
			setEditing(true);
		};

		return (
			<div className={css.wrapper}>
				<Button block primary onClick={done}>
					{t('Done')}
				</Button>
			</div>
		);
	}

	if (currentCue && !isRecording && isTrimming) {
		return (
			<div className={css.wrapper}>
				<Button block transparent onClick={cancel}>
					{t('Cancel')}
				</Button>
				<Button block primary onClick={() => save()}>
					{t('Save')}
				</Button>
			</div>
		);
	}

	return (
		<div className={css.wrapper}>
			{!isRecording ? (
				<Button block className={css.recordButton} onClick={recording.start}>
					{t('Start recording')}
				</Button>
			) : (
				<Fragment>
					<Button block transparent onClick={recording.cancel}>
						{t('Cancel')}
					</Button>
					<Button className={css.recordButton} block onClick={recording.stop}>
						{t('Stop recording')}
					</Button>
				</Fragment>
			)}
		</div>
	);
}
