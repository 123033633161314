import styled from 'styled-components';

import * as palette from 'pkg/config/palette';
import * as breakpoints from 'pkg/config/breakpoints';

import CardBase, { Body, Header } from 'components/Card';

import * as iconStyles from 'components/icon/styles.css';

export const GoalList = styled.ul``;

export const GoalItem = styled.li`
	color: ${palette.gray[600]};
	line-height: 1.5;
`;

export const ResultCardBase = styled(CardBase)`
	margin-bottom: 10px;
`;

export const BottomCardBody = styled(Body)`
	text-align: center;
	display: flex;
	color: ${palette.gray[500]};
	justify-content: center;
`;

export const BottomCardSection = styled.div`
	padding: 0 5px;

	.${iconStyles.icon} {
		margin-right: 4px;
		vertical-align: -2px;
	}
`;

export const GoalWrapper = styled.div`
	display: grid;
	grid-template-areas: 'home away';
	grid-template-columns: repeat(2, 1fr);
	padding: 15px 50px;
	border-top: 1px solid ${palette.gray[200]};

	@media ${breakpoints.small} {
		padding: 15px 20px;
	}
`;

export const HomeScore = styled.div`
	grid-area: home;
	text-align: left;
`;

export const AwayScore = styled.div`
	grid-area: away;
	text-align: right;
`;

export const MatchHeader = styled(Header)`
	@media ${breakpoints.small} {
		display: none;
	}
`;
