import { ReactNode } from 'react';

export const symbol_carry_on_bag_question: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M358-195v-409h-66v409h66Zm130 60H292q-24 0-42-18t-18-42v-409q0-24 18-42t42-18h281v-156h-54q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T519-880h114v403q-14 5-30.5 14T573-443v-161H418v409h54q1 15 5 30.5t11 29.5Zm237-294q80.51 0 137.255 56.745Q919-315.51 919-235q0 80.51-56.745 137.255Q805.51-41 725-41q-80.51 0-137.255-56.745Q531-154.49 531-235q0-80.51 56.745-137.255Q644.49-429 725-429Zm1.108 318Q738-111 746-119.108t8-20Q754-151 745.892-159t-20-8Q714-167 706-158.892t-8 20Q698-127 706.108-119t20 8ZM388-400Zm-30 205v-409 409Zm60 0v-409 409Zm384-107q0-31.719-21.5-50.859Q759-372 725.703-372q-21.088 0-38.846 10.043Q669.099-351.914 658-335q-5 7-1.526 14.184 3.473 7.184 11.579 10.5Q675-307 681.5-310q6.5-3 11.5-8.833Q698-327 706.75-331t19.25-4q16.875 0 26.438 8.25Q762-318.5 762-302q0 11-7 19.5T740-266q-8 7-15.5 14T712-237q-4 7-5 13.3-1 6.3-1 14.7 0 8 6 14t14 6q8 0 14-6t6-14q0-8 6.5-17.5t15.227-17.9Q783-257 792.5-269.5 802-282 802-302Z"
		/>
	</svg>
);
