import { ReactNode } from 'react';

export const symbol_align_vertical_top: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M339.882-80Q319-80 304.5-94.583 290-109.167 290-130v-540q0-20.833 14.618-35.417Q319.235-720 340.118-720 361-720 375.5-705.417 390-690.833 390-670v540q0 20.833-14.618 35.417Q360.765-80 339.882-80Zm280-240Q599-320 584.5-334.583 570-349.167 570-370v-300q0-20.833 14.618-35.417Q599.235-720 620.118-720 641-720 655.5-705.417 670-690.833 670-670v300q0 20.833-14.618 35.417Q640.765-320 619.882-320ZM110-820q-12.75 0-21.375-8.675Q80-837.351 80-850.175 80-863 88.625-871.5T110-880h740q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-820H110Z"
		/>
	</svg>
);
