export default function Connection(): JSX.Element {
	return (
		<svg
			width="25"
			height="25"
			viewBox="0 0 25 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.9108 7.36662L16.1832 7.72258C16.5599 8.21477 17.1501 8.52915 17.8142 8.52915C18.9475 8.52915 19.8662 7.61045 19.8662 6.47717C19.8662 5.3439 18.9475 4.4252 17.8142 4.4252C16.6809 4.4252 15.7622 5.3439 15.7622 6.47717C15.7622 6.63462 15.7798 6.78676 15.8127 6.93219L15.9108 7.36662ZM10.9033 10.4233L10.7707 9.9977L10.4702 9.66359C10.0932 9.24429 9.54967 8.98294 8.94375 8.98294C7.81048 8.98294 6.89178 9.90164 6.89178 11.0349C6.89178 11.8012 7.31141 12.4709 7.93819 12.8242L8.32671 13.0433L8.77232 13.0799C8.82865 13.0845 8.88582 13.0869 8.94375 13.0869C10.077 13.0869 10.9957 12.1682 10.9957 11.0349C10.9957 10.8203 10.9631 10.6152 10.9033 10.4233ZM7.11081 17.4517L6.66194 17.4414C6.64582 17.441 6.62965 17.4408 6.61343 17.4408C5.48016 17.4408 4.56146 18.3595 4.56146 19.4928C4.56146 20.6261 5.48016 21.5448 6.61343 21.5448C7.74671 21.5448 8.66541 20.6261 8.66541 19.4928C8.66541 18.683 8.19648 17.9809 7.51087 17.6467L7.11081 17.4517ZM15.3891 8.33034C15.9468 9.05911 16.8256 9.52915 17.8142 9.52915C19.4998 9.52915 20.8662 8.16273 20.8662 6.47717C20.8662 4.79162 19.4998 3.4252 17.8142 3.4252C16.1287 3.4252 14.7622 4.79162 14.7622 6.47717C14.7622 6.70926 14.7882 6.93529 14.8372 7.15254L11.2138 8.99494C10.6551 8.37365 9.84508 7.98294 8.94375 7.98294C7.2582 7.98294 5.89178 9.34935 5.89178 11.0349C5.89178 12.1766 6.51871 13.1719 7.44709 13.6953L6.6849 16.4416C6.66114 16.4411 6.63732 16.4408 6.61343 16.4408C4.92788 16.4408 3.56146 17.8072 3.56146 19.4928C3.56146 21.1783 4.92788 22.5448 6.61343 22.5448C8.29899 22.5448 9.66541 21.1783 9.66541 19.4928C9.66541 18.2862 8.96525 17.2432 7.94906 16.7478L8.69043 14.0765C8.77396 14.0834 8.85845 14.0869 8.94375 14.0869C10.6293 14.0869 11.9957 12.7205 11.9957 11.0349C11.9957 10.7183 11.9475 10.4129 11.858 10.1258L15.3891 8.33034Z"
				fill="white"
			/>
		</svg>
	);
}
