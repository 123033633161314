import { ReactNode } from 'react';

export const symbol_hide: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M376-334 183-141q-9 9-21 9t-21-8.842q-9-8.842-9-21T141-183l193-193h-88q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T246-436h160q12.75 0 21.375 8.625T436-406v160q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T376-246v-88Zm251-251h88q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T715-525H555q-12.75 0-21.375-8.625T525-555v-160q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T585-715v88l192-192q9-9 21-9t21 8.842q9 8.842 9 21T819-777L627-585Z"
		/>
	</svg>
);
