import { List } from 'immutable';

import VideoSequence from 'pkg/models/video_sequence';
import VideoPlaylistSequence from 'pkg/models/video_playlist_sequence';

import { formatTime } from 'pkg/timeline';
import { msToSeconds } from 'pkg/date';

import { CueType } from 'components/video-analytics/CueState';

export const VideoSequenceRecordingId = Number.MAX_SAFE_INTEGER;

export function isYouTubeUrl(targetUrl: string): boolean {
	const regex = new RegExp(
		'^(https?:/{2})(((player.|www.|m.)?(youtu(be.com|.be|be.googleapis.com))/(?:p/watch?v=|v/)?(?:[A-Za-z0-9._%-]*)(?:(?:/?|&)S+)?))'
	);

	return regex.test(targetUrl);
}

export function getYouTubeThumbnailUrl(targetUrl: string): string {
	const regexVideoId: RegExp =
		/^.*(?:youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*/;
	const videoId: string | undefined = targetUrl.match(regexVideoId)?.pop();

	if (!videoId) return null;

	return `https://img.youtube.com/vi/${videoId}/0.jpg`;
}

export function cuePointsFromSequences(
	sequences: List<VideoSequence>
): CueType[] {
	return sequences
		.sortBy(
			(sequence: VideoSequence) =>
				sequence.startsAtMs || sequence.startsAt * 1000
		)
		.toArray()
		.map(
			(item: VideoSequence) =>
				({
					cueId: item.id,
					title: item.title,
					description: item.description,
					annotations: item.annotations,
					videoId: item.videoId,
					startsAt: item.startsAt,
					startsAtMs: item.startsAtMs,
					endsAt: item.endsAt,
					endsAtMs: item.endsAtMs,
					isHighlight: item.reviewed,
					isPrivate: item.private,
				}) as CueType
		);
}

export function cuePointsFromPlaylistSequences(
	sequences: List<VideoPlaylistSequence> | VideoPlaylistSequence[]
): CueType[] {
	if (sequences instanceof List) {
		sequences = (sequences as List<VideoPlaylistSequence>)
			.sortBy((item: VideoPlaylistSequence) => item.order)
			.toArray();
	}

	return (sequences as VideoPlaylistSequence[]).map(
		(item: VideoPlaylistSequence) =>
			({
				cueId: item.videoSequenceId,
				videoId: item.video.id,
				title: item.videoSequence.title,
				description: item.videoSequence.description,
				annotations: item.videoSequence.annotations,
				startsAt: item.videoSequence.startsAt,
				startsAtMs: item.videoSequence.startsAtMs,
				endsAt: item.videoSequence.endsAt,
				endsAtMs: item.videoSequence.endsAtMs,
				sourceUrl: item.video.uri,
				isHighlight: item.videoSequence.reviewed,
				isPrivate: item.videoSequence.private,
			}) as CueType
	);
}

export function clipOffset(
	videoStartsAt: number,
	videoEndsAt: number,
	pointedAt: number
): number {
	return ((pointedAt - videoStartsAt) * 100) / (videoEndsAt - videoStartsAt);
}

export function clipWidth(
	videoEndsAt: number,
	clipStartsAt: number,
	clipEndsAt: number
): number {
	return (
		100 -
		((videoEndsAt - clipEndsAt) / videoEndsAt) * 100 -
		(clipStartsAt / videoEndsAt) * 100
	);
}

export function timelineOffsetToTime(
	offset: number,
	videoStartsAt: number,
	videoEndsAt: number
): number {
	return Number.parseFloat(
		(
			(videoEndsAt - videoStartsAt) * (offset / 100) +
			videoStartsAt
		).toPrecision(3)
	);
}

export function timelineOffsetToTimestamp(
	offset: number,
	videoStartsAt: number,
	videoEndsAt: number
): string {
	return formatTime(timelineOffsetToTime(offset, videoStartsAt, videoEndsAt));
}

export function segmentMultiplier(duration: number): number {
	let multiplier = 1;

	if (duration / 60 > 1) {
		multiplier = duration / 60;
	}

	return multiplier;
}

export function segmentCount(duration: number, timesBy: number): number {
	const multiplier: number = segmentMultiplier(duration);

	if (duration > 0 && duration < 60) {
		return (multiplier * duration) / 60;
	}

	return multiplier * timesBy;
}

export function timelineWidth(
	segmentWidth: number,
	duration: number,
	timesBy: number
): number {
	const numSegments: number = segmentCount(duration, timesBy);

	return segmentWidth * numSegments;
}

export function timelineDragOffset(node: HTMLDivElement): number {
	const offset = Number.parseFloat(
		node?.style.transform.match(/(-[0-9.]+)/gi)?.pop() ?? '0.0'
	);

	return Math.abs(offset / Number.parseFloat(node.style.width));
}

export function formatTimestamp(time: number): string {
	const h = Math.floor(time / 3600);
	const m = Math.floor((time % 3600) / 60);
	const s = Math.floor(time % 60);

	const parts: string[] = [m, s].map((t) => t.toString().padStart(2, '0'));

	if (h > 0) {
		parts.unshift(h.toString());
	}

	return parts.join(':');
}

type TimeRange = [number, number];

export function findMaxTimeIntersections(ranges: TimeRange[]): number {
	const numRanges: number = ranges.length;
	const tmp: { [key: number]: number } = {};

	for (let n = 0; n < numRanges; n++) {
		const a = ranges[n];

		tmp[n] = 0;

		for (let j = n + 1; j < numRanges; j++) {
			const b = ranges[j];

			if ((a[1] < b[0] || b[1] < a[0]) === false) {
				tmp[n]++;
			}
		}
	}

	return Math.max(...Object.values(tmp));
}

export function getStartsAt(item: CueType | VideoSequence): number {
	if (!item) return 0;

	return item?.startsAtMs ? msToSeconds(item.startsAtMs) : item.startsAt;
}

export function getEndsAt(item: CueType | VideoSequence): number {
	if (!item) return 0;

	return item?.endsAtMs ? msToSeconds(item.endsAtMs) : item.endsAt;
}
