export function AddExercise(
	groupID: number,
	trainingCollectionID: number,
	exerciseID: number
): string {
	return `/v1/organizations/${groupID}/training-collections/${trainingCollectionID}/exercises/${exerciseID}`;
}

export function AddSession(
	groupID: number,
	trainingCollectionID: number,
	sessionID: number
): string {
	return `/v1/organizations/${groupID}/training-collections/${trainingCollectionID}/sessions/${sessionID}`;
}

export function Create(groupID: number): string {
	return `/v1/organizations/${groupID}/training-collections`;
}

export function Delete(groupID: number, trainingCollectionID: number): string {
	return `/v1/organizations/${groupID}/training-collections/${trainingCollectionID}`;
}

export function Index(groupID: number): string {
	return `/v1/organizations/${groupID}/training-collections`;
}

export function RemoveExercise(
	groupID: number,
	trainingCollectionID: number,
	exerciseID: number
): string {
	return `/v1/organizations/${groupID}/training-collections/${trainingCollectionID}/exercises/${exerciseID}`;
}

export function RemoveSession(
	groupID: number,
	trainingCollectionID: number,
	sessionID: number
): string {
	return `/v1/organizations/${groupID}/training-collections/${trainingCollectionID}/sessions/${sessionID}`;
}

export function ShareIndex(
	groupID: number,
	trainingCollectionID: number
): string {
	return `/v1/organizations/${groupID}/training-collections/${trainingCollectionID}/share`;
}

export function Show(groupID: number, trainingCollectionID: number): string {
	return `/v1/organizations/${groupID}/training-collections/${trainingCollectionID}`;
}

export function ShowExercises(
	groupID: number,
	trainingCollectionID: number
): string {
	return `/v1/organizations/${groupID}/training-collections/${trainingCollectionID}/exercises`;
}

export function ShowSessions(
	groupID: number,
	trainingCollectionID: number
): string {
	return `/v1/organizations/${groupID}/training-collections/${trainingCollectionID}/sessions`;
}

export function Update(groupID: number, trainingCollectionID: number): string {
	return `/v1/organizations/${groupID}/training-collections/${trainingCollectionID}`;
}

export function UpdateShare(
	groupID: number,
	trainingCollectionID: number
): string {
	return `/v1/organizations/${groupID}/training-collections/${trainingCollectionID}/share`;
}
