import { Fragment, useState } from 'react';
import { useT } from '@transifex/react';

import * as models from 'pkg/api/models';
import { useCurrentUser } from 'pkg/identity';

import Icon from 'components/icon';
import MaterialSymbol from 'components/material-symbols';
import Avatar from 'components/avatar';

import * as Context from 'design/context_menu';
import Button from 'design/button';

import * as css from './styles.css';

interface UserItemProps {
	user: models.user.User;
	currentUser: models.user.User;
	eventUserId: number;
	isEditing?: boolean;

	onRemove: (userId: number) => void;
	onMakeOrganizer?: (userId: number) => void;
	onMakeParticipant?: (userId: number) => void;
}

const UserItem = ({
	user,
	isEditing,

	onRemove,
	onMakeOrganizer,
	onMakeParticipant,
}: UserItemProps) => {
	const t = useT();

	const handleRemove = () => {
		onRemove(user.id);
	};

	const handleMakeOrganizer = () => {
		onMakeOrganizer(user.id);
	};

	const handleMakeParticipant = () => {
		onMakeParticipant(user.id);
	};

	return (
		<div className={css.listItem}>
			<div className={css.infoWrapper}>
				<Avatar user={user.firstName && user.lastName && user} size={30} />

				<span className={css.name}>{models.user.fullName(user)}</span>
			</div>
			{isEditing ? (
				<Button
					inline
					small
					icon="cancel"
					onClick={handleRemove}
					className={css.remove}
				/>
			) : (
				<Context.Menu
					toggleWith={<MaterialSymbol variant="stylus" scale={1.3} />}>
					{onMakeOrganizer && (
						<Context.Item onClick={handleMakeOrganizer}>
							<Context.ItemIcon name="person_edit" />
							<span>{t('Make organizer')}</span>
						</Context.Item>
					)}
					{onMakeParticipant && (
						<Context.Item onClick={handleMakeParticipant}>
							<Context.ItemIcon name="person_remove" />
							<span>{t('Remove as organizer')}</span>
						</Context.Item>
					)}
					<Context.Divider />
					<Context.Item caution onClick={handleRemove}>
						<Context.ItemIcon name="person_off" />
						<span>{t('Uninvite')}</span>
					</Context.Item>
				</Context.Menu>
			)}
		</div>
	);
};

interface UserListProps {
	selectedUsers: models.user.User[];
	eventUserId?: number;
	isEditing?: boolean;

	onRemove?: (userId: number) => void;
	onMakeOrganizer?: (userId: number) => void;
	onMakeParticipant?: (userId: number) => void;
}

const UserList = ({
	selectedUsers,
	eventUserId = null,
	isEditing,

	onRemove,
	onMakeOrganizer,
	onMakeParticipant,
}: UserListProps): JSX.Element => {
	const t = useT();

	const [showAll, setShowAll] = useState(false);
	const currentUser = useCurrentUser();

	const users = selectedUsers
		.sort((a, b) =>
			models.user.fullName(a).localeCompare(models.user.fullName(b))
		)
		.sort((a, b) =>
			a.id === currentUser.id ? -1 : b.id === currentUser.id ? 1 : 0
		);

	const showingUsers = showAll ? users : users.slice(0, 10);

	const loadAll = () => setShowAll(true);

	return (
		<Fragment>
			{showingUsers.map((user) => (
				<UserItem
					key={user.id}
					user={user}
					currentUser={currentUser}
					eventUserId={eventUserId}
					isEditing={isEditing}
					onRemove={onRemove}
					onMakeOrganizer={onMakeOrganizer}
					onMakeParticipant={onMakeParticipant}
				/>
			))}
			{users.length > 10 && !showAll && (
				<p onClick={loadAll} className={css.loadMore}>
					<Icon name="add" />
					{t(`{num} more`, { num: users.length - 10 })}
				</p>
			)}
		</Fragment>
	);
};

export default UserList;
