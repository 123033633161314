import { ReactNode } from 'react';

export const symbol_add_chart: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h365q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T545-780H180v600h600v-365q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T840-545v365q0 24-18 42t-42 18H180Zm133.825-432Q301-552 292.5-543.375T284-522v215q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T344-307v-215q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625Zm166-131Q467-683 458.5-674.375T450-653v346q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T510-307v-346q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625Zm166 258Q633-425 624.5-416.375T616-395v88q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T676-307v-88q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625ZM693-692h-58q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T635-752h58v-58q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T753-810v58h57q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-692h-57v57q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T693-635v-57ZM480-480Z"
		/>
	</svg>
);
