import { ReactNode } from 'react';

export const symbol_signal_cellular_alt: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M249.882-160Q229-160 214.5-174.583 200-189.167 200-210v-140q0-20.833 14.618-35.417Q229.235-400 250.118-400 271-400 285.5-385.417 300-370.833 300-350v140q0 20.833-14.618 35.417Q270.765-160 249.882-160Zm250 0Q479-160 464.5-174.583 450-189.167 450-210v-340q0-20.833 14.618-35.417Q479.235-600 500.118-600 521-600 535.5-585.417 550-570.833 550-550v340q0 20.833-14.618 35.417Q520.765-160 499.882-160Zm250 0Q729-160 714.5-174.583 700-189.167 700-210v-540q0-20.833 14.618-35.417Q729.235-800 750.118-800 771-800 785.5-785.417 800-770.833 800-750v540q0 20.833-14.618 35.417Q770.765-160 749.882-160Z"
		/>
	</svg>
);
