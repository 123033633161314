import { ReactNode } from 'react';

export const symbol_8k: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m570-450 77 82q2 2 17 8 16 0 22.5-15t-4.5-26l-74-79 74-80q11-11 4.5-25.5T664-600q-4.789 0-8.895 2-4.105 2-8.105 6l-77 82v-65q0-10.833-7.116-17.917-7.117-7.083-18-7.083Q534-600 527-592.917q-7 7.084-7 17.917v190q0 10.833 7.116 17.917 7.117 7.083 18 7.083Q556-360 563-367.083q7-7.084 7-17.917v-65Zm-280 90h103q14.025 0 23.513-9.487Q426-378.975 426-393v-173q0-14.45-9.487-24.225Q407.025-600 393-600H290q-14.45 0-24.225 9.775Q256-580.45 256-566v173q0 14.025 9.775 23.513Q275.55-360 290-360Zm16-136v-71h70v71h-70Zm0 103v-70h70v70h-70ZM180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-60h600v-600H180v600Zm0-600v600-600Z"
		/>
	</svg>
);
