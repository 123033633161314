import { Map, Record } from 'immutable';

import Tag from 'pkg/models/tag';

import { Tags } from 'pkg/actions/action-types';

const initialState = new Record(
	{
		entities: new Map({}),
	},
	'tagsReducer'
);

const setTagsReducer = (state, action) => {
	const data = action.payload.entities.tags;
	const entities = Object.values(data);

	entities.forEach((entity) => {
		state = state.setIn(['entities', entity.id], new Tag(entity));
	});

	return state;
};

const deleteTagReducer = (state, action) =>
	state.deleteIn(['entities', action.sessionId]);

const sessionsReducer = (state = initialState(), action = {}) => {
	switch (action.type) {
		case Tags.SET_ITEMS:
			return setTagsReducer(state, action);
		case Tags.DELETE_ITEM:
			return deleteTagReducer(state, action);
		default:
			return state;
	}
};

export default sessionsReducer;
