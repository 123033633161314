import React from 'react';

import Link from 'pkg/router/Link';

import MaterialSymbol from 'components/material-symbols';

import { MaterialSymbolVariant } from 'components/material-symbols/symbols';

import * as css from './styles.css';

interface ButtonTriggerProps {
	href?: string;
	icon: MaterialSymbolVariant;
	[key: string]: any;
}

export const ButtonTrigger = ({
	href,
	icon,
	...rest
}: ButtonTriggerProps): JSX.Element => {
	if (href) {
		return (
			<Link
				href={href}
				className={css.buttonWrap}
				data-testid="page_actions_trigger"
				{...rest}>
				<MaterialSymbol variant={icon} className={css.icon} scale={1.2} />
			</Link>
		);
	}

	return (
		<div
			className={css.buttonWrap}
			data-testid="page_actions_trigger"
			{...rest}>
			<MaterialSymbol variant={icon} className={css.icon} scale={1.2} />
		</div>
	);
};
