import { ReactNode } from 'react';

export const symbol_lte_plus_mobiledata: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M193-320H70q-12.75 0-21.375-8.625T40-350v-260q0-12.75 8.675-21.375Q57.351-640 70.175-640 83-640 91.5-631.375T100-610v230h93q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T193-320Zm133.825 0Q314-320 305.5-328.625T297-350v-230h-54q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T243-640h168q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T411-580h-54v230q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM650-320H527q-12.75 0-21.375-8.625T497-350v-260q0-12.75 8.625-21.375T527-640h123q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T650-580h-93v70h93q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T650-450h-93v70h93q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T650-320Zm166-130h-54q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T762-510h54v-54q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T876-564v54h54q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T930-450h-54v54q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T816-396v-54Z"
		/>
	</svg>
);
