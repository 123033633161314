import { ReactNode } from 'react';

export const symbol_sound_sampler: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M80-480q0-148 94-257.5T408-873q15-3 25.5 4t10.5 21q0 15-9.5 24T409-812q-115 25-192 116.5T140-480q0 49 13.5 93.5T191-303q8 13 8 25t-11 23q-10 10-22 8t-20-14q-32-48-49-103T80-480ZM480-80q-64 0-122.5-19T250-153q-11-8-12.5-20t7.5-22q10-11 23.5-10t25.5 9q41 27 87.5 41.5T480-140q52 0 99-15t88-42q12-8 25.5-9t23.5 10q10 10 7.5 22.5T709-153q-49 34-107 53.5T480-80Zm400-400q0 61-17 116t-48 103q-8 12-20 14t-22-8q-11-11-11-23t8-25q24-39 37-83.5t13-93.5q0-123-76-215T552-812q-16-3-25.5-12t-9.5-24q0-14 10.5-21t25.5-4q140 26 233.5 135.5T880-480ZM387-346v-288q0-9 7.5-13t15.5 1l225 143q7 5 7 13t-7 13L410-334q-8 5-15.5 1t-7.5-13Z"
		/>
	</svg>
);
