import { ReactNode } from 'react';

export const symbol_start: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M109.825-240Q97-240 88.5-248.625T80-270v-420q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-690v420q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM765-450H269q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T269-510h496L618-658q-9-9-9-21t9-21q9-9 21-9t21 9l199 199q5 5 7 10.133 2 5.134 2 11Q868-474 866-469q-2 5-7 10L660-260q-9 9-21.158 9-12.158 0-21-8.609Q609-269 609-281.467q0-12.466 9-21.533l147-147Z"
		/>
	</svg>
);
