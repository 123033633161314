import * as React from 'react';
import { useSelector } from 'react-redux';

import User from 'pkg/models/user';

import * as selectors from 'pkg/selectors';
import { RootState } from 'pkg/reducers';

import Stats from 'components/user/profile/statistics/Season';

type StatisticsProps = {
	user: User;
	groupId: number;
	isSelfOrParentToUserOrAdmin: boolean;
};

const Statistics: React.FC<React.PropsWithChildren<StatisticsProps>> = ({
	user,
	groupId,
	isSelfOrParentToUserOrAdmin,
}) => {
	const averageStats = useSelector((state: RootState) =>
		selectors.groups.getSquadAverageStats(state, {
			stats: [
				'season-activity-count',
				'season-matches-player',
				'season-started-matches',
			],
			groupId,
		})
	);

	return (
		<Stats
			user={user}
			averageStats={averageStats}
			isSelfOrParentToUserOrAdmin={isSelfOrParentToUserOrAdmin}
		/>
	);
};

export default Statistics;
