import { ReactNode } from 'react';

export const symbol_handheld_controller: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M439-243q-66 0-111-48.5T274-408q-100-1-167-74T40-656q0-24.75 17.625-42.375T100-716h186v-64h-52q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T234-840h164q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T398-780h-52v64h145q12.444 0 23.722 5T534-698l346 348q40 40 40.5 96T880-159q-39 39-94.5 39T691-159l-82.588-84H439Zm5-165h-97q-4 42 23 73.5t69 31.5h109L444-408Zm48-248H100q0 77 50 132.5T275-468h194l265 267q22 22 52 22t52-22.5q22-22.5 22-53.174T838-307L492-656Zm0 0Z"
		/>
	</svg>
);
