import React from 'react';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import { CollectionSelection } from 'pkg/api/use_collection';

import * as Input from 'components/form/inputs';

import * as Table from 'design/table';

interface TableRowProps {
	contact: models.user.User;

	selection: CollectionSelection;
}

const TableRow: React.FC<React.PropsWithChildren<TableRowProps>> = ({
	contact,
	selection,
}) => {
	const billingEmail = models.user.getBillingEmail(contact);

	const handleSelect = (): void => {
		if (!!billingEmail) {
			selection.selectSingle(contact.id);
		}
	};

	return (
		<Table.Row
			key={contact.id}
			tooltipText={
				!billingEmail && t('Contacts require an email adress to be billed')
			}
			disabled={!billingEmail}
			onClick={handleSelect}>
			<Table.Cell>
				<Input.Control
					type="checkbox"
					checked={selection.isSelected(contact.id)}
					onChange={handleSelect}
				/>
			</Table.Cell>
			<Table.Cell>{models.user.fullName(contact)}</Table.Cell>
			<Table.Cell>{billingEmail}</Table.Cell>
		</Table.Row>
	);
};

interface ContactTableProps {
	users: models.user.User[];
	sort: { by: string; order: 'asc' | 'desc' };
	isLoading: boolean;
	onSort: (field: string, sortOrder: 'asc' | 'desc') => void;
	selection: CollectionSelection;
}

const ContactTable: React.FC<React.PropsWithChildren<ContactTableProps>> = ({
	users,
	sort,
	isLoading,
	onSort,
	selection,
}) => {
	const handleSelectAll = (): void => {
		selection.selectAll();
	};

	return (
		<Table.Table
			isLoading={isLoading}
			sortBy={sort.by}
			sortOrder={sort.order}
			onSort={onSort}
			columns={[
				{
					content: (
						<Input.Control
							type="checkbox"
							standalone
							checked={selection.isAllSelected}
							onChange={handleSelectAll}
						/>
					),
					width: 'max-content',
				},
				{
					content: t('Name'),
					sortKey: 'name',
				},
				{
					content: t('Billing name'),
					sortKey: 'billing_email',
				},
			]}>
			{users.map((contact) => (
				<TableRow key={contact.id} contact={contact} selection={selection} />
			))}
		</Table.Table>
	);
};

export default ContactTable;
