import { t } from '@transifex/native';
import styled from 'styled-components';

import * as palette from 'pkg/config/palette';

import UserGoal from 'pkg/models/user_goal';

import Icon from 'components/icon';

import * as iconStyles from 'components/icon/styles.css';
import ProgressBar from 'components/form/ProgressBar';

const Progress = styled.div<{ completed: boolean }>`
	display: flex;
	flex-flow: row;
	align-items: center;
	display: grid;
	grid-gap: var(--spacing-4);
	grid-template-columns: ${(props) =>
		props.completed ? '1fr' : '20px 1fr 20px'};
`;

const ProgressAction = styled.div`
	display: flex;
	width: 20px;
	height: 20px;
	position: relative;
	align-items: center;
	justify-content: center;
	padding: var(--spacing-2);
	background: ${palette.blue[500]};
	border-radius: var(--radius-5);
	cursor: pointer;
	font-size: var(--font-size-sm);

	.${iconStyles.icon} {
		color: ${palette.white};
	}
`;

type ProgressProps = {
	goal: UserGoal;
	handleIncrement: () => void;
	handleDecrement: () => void;
};

const GoalProgress = ({
	goal,
	handleIncrement,
	handleDecrement,
}: ProgressProps): JSX.Element => {
	let completedLabel: string = '';

	if (goal.completedAt) {
		completedLabel = t(`Completed at {date}`, {
			date: new Date(goal.completedAt * 1000).toISOString().slice(0, 10),
		});
	}

	return (
		<Progress completed={!!goal.completedAt}>
			{goal.completedAt > 0 ? null : (
				<ProgressAction onClick={handleDecrement}>
					<Icon name="remove" />
				</ProgressAction>
			)}

			<ProgressBar
				completed={!!goal.completedAt}
				progress={goal.completedAt ? 100 : Math.min(goal.progress, 100)}
				label={completedLabel}
			/>

			{goal.completedAt > 0 ? null : (
				<ProgressAction onClick={handleIncrement}>
					<Icon name="add" />
				</ProgressAction>
			)}
		</Progress>
	);
};

export default GoalProgress;
