import { ReactNode } from 'react';

export const symbol_colors: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M348-138 98-388q-9-9.118-13.5-20.059T80-430.7q0-11.7 4.5-22.5T98-473l250-250-93-93q-9-9-9-21t9-21q9-9 21.423-9 12.423 0 21.577 9l385 385q9.474 9 13.737 19.8T701-430.7q0 11.7-4.263 22.641Q692.474-397.118 683-388L433-138q-9 9-19.8 13.5t-22.5 4.5q-11.7 0-22.641-4.5Q357.118-129 348-138Zm43-542L141-430h500L391-680Zm408.656 560q-33.345 0-56.5-23.182Q720-166.364 720-200q0-26.28 10-49.64T756-293l26-34q7-9 18-9t18 9l26 34q15 20 25.5 43.36T880-200q0 33.636-23.5 56.818Q833-120 799.656-120Z"
		/>
	</svg>
);
