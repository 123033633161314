import { ReactNode } from 'react';

export const symbol_texture_add: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M700-210h-90q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T610-270h90v-90q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T760-360v90h90q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-210h-90v90q0 12.75-8.675 21.375Q742.649-90 729.825-90 717-90 708.5-98.625T700-120v-90Zm-571 79q-8-8-9.5-20t9.5-23l659-656q11-11 22.852-9.538 11.852 1.461 20 9.5Q839-822 840.5-810t-9.5 23L172-131q-10.889 11-22.944 9.5Q137-123 129-131Zm0-319q-8-8-9.5-20t9.5-23l338-338q10.889-11 22.944-9.5Q502-839 510-831q8 8 9.5 20.056Q521-798.889 510-788L172-450q-10.889 11-22.944 9.5Q137-442 129-450Zm17-294q-7 7-16.5 3.458Q120-744.083 120-754v-55.82q0-13.18 8.625-21.68T150-840h56q9.917 0 13.458 9.5Q223-821 216-814l-70 70Zm663.825 286Q797-458 788-466.675q-9-8.676-9-21.5Q779-501 788-510t21.825-9q12.824 0 21.5 9Q840-501 840-488.175q0 12.824-8.675 21.5-8.676 8.675-21.5 8.675Zm-338 338Q459-120 450-128.675q-9-8.676-9-21.5Q441-163 450-172t21.825-9q12.824 0 21.5 9Q502-163 502-150.175q0 12.824-8.675 21.5-8.676 8.675-21.5 8.675Z"
		/>
	</svg>
);
