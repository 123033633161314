import styled, { keyframes, css } from 'styled-components';

import * as palette from 'pkg/config/palette';
import * as breakpoints from 'pkg/config/breakpoints';

import rgba from 'pkg/rgba';

const fadeIn = keyframes`
	0% {
		background: ${rgba(palette.gray[800], 0)};
	}

	100% {
		background: ${rgba(palette.gray[800], 1)};
	}
`;

const fadeOut = keyframes`
	0% {
		background: ${rgba(palette.gray[800], 1)};
	}

	100% {
		background: ${rgba(palette.gray[800], 0)};
	}
`;

const Backdrop = styled.div<{ index: number }>`
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: ${({ index }) => index};
	overflow: auto;
	-webkit-overflow-scrolling: auto;

	background: ${rgba(palette.gray[800], 1)};
	animation: ${(props) =>
			props.theme.animationDirection === 'out' ? fadeOut : fadeIn}
		300ms ease-in-out;

	${(props) =>
		props.theme.animationDirection === 'out'
			? css`
					pointer-events: none;
					background-color: rgb(0, 0, 0, 0);
					animation-name: ${fadeOut};
				`
			: css`
					animation-name: ${fadeIn};
				`};

	@media ${breakpoints.small} {
		align-items: flex-start;
		overflow: hidden;
		animation: none;
	}
`;

export default Backdrop;
