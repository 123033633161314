const icon = {
	name: 'drawing-polygon-dashed',
	objects: [
		{
			type: 'path',
			attributes: {
				d: 'M19.3649 4V5.98419H18.342H18.2152H18.1819H18.0649V5.75555L17.6227 4.89199L19.3649 4ZM14.837 6.31829L16.4289 5.50327L17.0213 6.66041L15.4295 7.47544L14.837 6.31829ZM12.0513 7.74459L13.6432 6.92957L14.2356 8.08671L12.6438 8.90174L12.0513 7.74459ZM19.3649 7.3659V9.20817H18.0649V7.3659H19.3649ZM10.8575 8.35587L11.4499 9.51301L10.8356 9.82753L10.5999 10.2703L9.45238 9.65943L9.87991 8.85638L10.8575 8.35587ZM19.3649 10.5899V12.4322H18.0649V10.5899H19.3649ZM8.29165 11.8397L8.95493 10.5938L10.1024 11.2047L9.43917 12.4506L8.29165 11.8397ZM7.13093 14.02L7.7942 12.7741L8.94171 13.385L8.27844 14.6309L7.13093 14.02ZM19.3649 13.8139V15.2396L18.1332 15.5549L17.8108 14.2955L18.0649 14.2304V13.8139H19.3649ZM17.0189 15.8401L15.5332 16.2205L15.2108 14.9611L16.6965 14.5807L17.0189 15.8401ZM5.9702 16.2003L6.63347 14.9544L7.78099 15.5653L7.11772 16.8112L5.9702 16.2003ZM14.4189 16.5057L12.9332 16.8861L12.6108 15.6267L14.0965 15.2464L14.4189 16.5057ZM11.8189 17.1714L10.3332 17.5517L10.0108 16.2923L11.4965 15.912L11.8189 17.1714ZM9.21893 17.837L7.73322 18.2173L7.41081 16.9579L8.89652 16.5776L9.21893 17.837ZM5.47275 17.1347L5.8779 17.3504L6.3 17.57L6.39435 17.6253L6.49 18L6.4885 17.9931L6.61893 18.5026L4.44873 19.0581L5.47275 17.1347Z',
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				fill: 'currentColor',
			},
		},
	],
};

export default icon;
