import { useEffect, useState } from 'react';

import {
	Collection,
	hasNext,
	hasPrev,
	next,
	prev,
	byPage,
} from 'pkg/api/models/collection';
import useMixedState from 'pkg/hooks/useMixedState';
import * as sdk from 'pkg/core/sdk';
import { filterDuplicatesByKey } from 'pkg/arrays';
import { pushState } from 'pkg/router/state';
import { tlog } from 'pkg/tlog';

import { useApiConfig } from 'components/application/apiconfig';

interface RequestHeaders {
	[key: string]: string;
}

export interface CollectionPagination {
	hasPrev: boolean;
	hasNext: boolean;
	currentPage: number;
	totalCount: number;
	count?: number;
	fetchNext: () => void;
	fetchPrev: () => void;
	fetchPage: (page: number) => void;
}

type CollectionSortOrder = 'asc' | 'desc';

export interface CollectionSort {
	setColumn: (column: string) => void;
	column?: string;
	setOrder: (order: CollectionSortOrder) => void;
	order?: CollectionSortOrder;
	setSort: (column: string, order: CollectionSortOrder) => void;
}

export interface CollectionSelection {
	selectedRecords: (number | string)[];
	selectSingle: (id: number) => void;
	selectOnlyOne: (id: number) => void;
	selectAll: () => void;
	isSelected: (id: number) => boolean;
	isAllSelected: boolean;
	deselectAll: () => void;
	selectMultiple: (ids: number[]) => void;
}

export interface CollectionResponse<T> {
	records: T[];
	reset: () => void;
	refresh: () => Promise<void>;
	includesRecord: (recordId: number) => boolean;
	removeRecord: (recordId: number) => void;
	// Key parameter is if you want to find a record by passed key instead of id
	replaceRecord: (record: T, key?: keyof T) => void;
	replaceMultipleRecords: (records: T[], key?: keyof T) => void;
	// Replaces a nested record inside current record, for example updating a ChatUser inside Chat.ChatUsers
	replaceInRecord: (
		// ID of the "top level" record that we want to select
		recordId: number,
		key: keyof T,
		// The new nested record we want to replace
		newRecord: any
	) => void;
	addRecord: (record: T) => void;
	response?: Response;
	isLoading: boolean;
	hasError?: boolean;
	sort: CollectionSort;
	pagination: CollectionPagination;
	selection: CollectionSelection;
	selectedRecords?: T[];
}

interface CollectionOptions {
	queryParams?: URLSearchParams;
	// set showAllResults to true to return all fetched data, instead of just the current page.
	showAllResults?: boolean;
	showOnlySelected?: boolean;
	defaultSelectedRecords?: number[];
	count?: number;
	requestHeaders?: RequestHeaders;
	useLocationPagination?: boolean;
	defaultSortBy?: string;
	defaultSortByOrder?: CollectionSortOrder;
}

interface CurrentCollection<T> {
	url: string;
	queryParams?: URLSearchParams;
	isLoading: boolean;
	hasError?: boolean;
	results: Collection<T>[];
	collection?: Collection<T>;
	selectedRecordIds?: (number | string)[];
	selectedRecords: T[];
	response?: Response;
	page: number;
	sortBy: string;
	sortByOrder: CollectionSortOrder;
}

function buildEndpointURL(endpoint: string, options?: CollectionOptions) {
	if (!endpoint) {
		return '';
	}

	const params = options?.queryParams || new URLSearchParams();

	if (options?.useLocationPagination) {
		const qs = new URLSearchParams(window.location.search);
		const ineritableParams = ['page', 'sort_by', 'sort_by_order'];

		ineritableParams.map((param: string) => {
			if (qs.has(param)) {
				params.set(param, qs.get(param));
			}
		});
	}

	if (Array.from(params.keys()).length === 0) {
		return endpoint;
	}

	return endpoint + '?' + params.toString();
}

export function useCollection<T extends { id?: number | string }>(
	endpoint: string,
	options: CollectionOptions = {}
): CollectionResponse<T> {
	const apiConfig = useApiConfig();
	const queryParams: URLSearchParams =
		options.queryParams || new URLSearchParams();

	if (options.count && !queryParams.has('count')) {
		queryParams.set('count', options.count.toString());
	}

	const endpointUrl = buildEndpointURL(endpoint, options);
	const qs = new URLSearchParams(endpointUrl);

	let initialPage: number = 1;

	let sortBy: string = options?.defaultSortBy ?? '';
	let sortByOrder: CollectionSortOrder = options?.defaultSortByOrder ?? 'desc';

	const usesQueryState = options?.useLocationPagination === true;

	if (usesQueryState && qs.has('page')) {
		initialPage = Number.parseInt(qs.get('page'), 10);
	}

	['sort_by', 'sort_by_order'].map((param: string) => {
		if (qs.has(param)) {
			if (param === 'sort_by') {
				sortBy = qs.get(param);
			}

			if (param === 'sort_by_order') {
				sortByOrder = qs.get(param) as CollectionSortOrder;
			}

			if (usesQueryState) {
				queryParams.set(param, qs.get(param));
			}
		}
	});

	const [current, setCurrent] = useMixedState<CurrentCollection<T>>({
		url: endpointUrl,
		queryParams,
		isLoading: true,
		hasError: false,
		results: [],
		collection: null,
		response: null,
		selectedRecordIds: options.defaultSelectedRecords || [],
		selectedRecords: [],
		page: initialPage,
		sortBy,
		sortByOrder,
	});

	const [appendedRecords, setAppendedRecords] = useState<T[]>([]);

	const getFetchUrl = (): string => {
		const [path, query] = current.url.split('?');
		const q = new URLSearchParams(query);

		q.set('sort_by', current.sortBy);
		q.set('sort_by_order', current.sortByOrder);

		return `${path}?${q}`;
	};

	const fetchData = async () => {
		if (!current.isLoading) {
			setCurrent({ isLoading: true });
		}

		if (!current.url || current.url.includes('undefined')) {
			setCurrent({ isLoading: false });
			return;
		}

		let r: Response;
		let d;
		try {
			r = await sdk.get(getFetchUrl(), {}, {}, options.requestHeaders || {});
		} catch (e) {
			tlog.error('failed to make request', {
				error: e,
			});
			setCurrent({
				hasError: true,
				isLoading: false,
			});
			throw new Error(e);
		}

		if (r?.status >= 500 && r?.status < 600) {
			tlog.error('server error', {
				error: new Error(`server error ${r.statusText}`),
			});
			setCurrent({
				hasError: true,
				isLoading: false,
			});
			return;
		}

		// client version is too low
		if (r?.status === 426) {
			apiConfig.refreshConfig();
			return;
		}

		try {
			d = await r.json();
		} catch (e) {
			tlog.error('failed to parse json', {
				error: e,
			});
			setCurrent({
				hasError: true,
				isLoading: false,
			});
			throw new Error(e);
		}

		const newState: Partial<CurrentCollection<T>> = {
			results: current.results,
			collection: current.collection,
			response: r,
			isLoading: false,
			hasError: false,
		};

		const hasRecords = 'records' in d;

		if (r.ok && !hasRecords) {
			tlog.error(`Non-collection response from endpoint: ${getFetchUrl()}`);
		}

		if (r.ok && hasRecords) {
			if (options.showAllResults) {
				const newCollection = d as Collection<T>;
				newCollection.records = newCollection.records.filter(
					(item: T) =>
						!current.results.find((c) =>
							c.records.find((existingItem) => existingItem.id === item.id)
						)
				);

				newState.results = [...newState.results, newCollection];
			} else {
				newState.results[current.page - 1] = d as Collection<T>;
			}

			newState.collection = d as Collection<T>;
		} else {
			newState.hasError = true;
		}

		setCurrent(newState);
	};

	const reset = () => {
		if (options.queryParams && options.queryParams.has('page')) {
			options.queryParams.set('page', initialPage.toString());
		}

		if (current.url) {
			setCurrent({
				page: initialPage,
				results: [],
				collection: {} as Collection<T>,
				url: buildEndpointURL(endpoint, options),
			});
			setAppendedRecords([]);
		}
	};

	useEffect(() => {
		fetchData();
	}, [current.url, current.sortBy, current.sortByOrder]);

	useEffect(() => {
		setCurrent({
			url: buildEndpointURL(endpoint, options),
		});
	}, [endpoint]);

	// reset back to page 1 when filters/params change
	useEffect(() => {
		reset();
	}, [options.queryParams?.toString(), endpoint]);

	const updateLocation = (
		page: number,
		sortBy: string,
		sortByOrder: string
	) => {
		if (options?.useLocationPagination) {
			const qs = new URLSearchParams(window.location.search);

			if (page >= 1) {
				qs.set('page', page.toString());
			} else {
				qs.delete('page');
			}

			qs.set('sort_by_order', sortByOrder);

			if (sortBy !== '') {
				qs.set('sort_by', sortBy);
			} else {
				qs.delete('sort_by');
				qs.delete('sort_by_order');
			}

			pushState(`${window.location.pathname}?${qs}`);
		}
	};

	const pageRecords = current.results[current.page - 1]?.records || [];

	const isAllSelected = options.showOnlySelected
		? true
		: pageRecords.filter((record) =>
				current.selectedRecordIds.includes(record.id)
			).length === pageRecords.length;

	let records = options.showAllResults
		? current.results.map((col) => col.records).flat()
		: pageRecords || [];

	if (appendedRecords.length) {
		records = [...records, ...appendedRecords];
	}

	const firstViableResult = current.results?.filter((n) => n)?.[0];
	const totalCount = firstViableResult?.meta?.totalCount || 0;
	const recordsPerPage =
		(firstViableResult?.meta?.recordsPerPage as number) || undefined;

	const pagination: CollectionPagination = {
		currentPage: current.page,
		totalCount: totalCount as number,
		count: recordsPerPage,
		hasPrev: current.collection && hasPrev(current.collection),
		hasNext: current.collection && hasNext(current.collection),
		fetchNext: () => {
			const nextPage = current.page + 1;

			updateLocation(nextPage, sortBy, sortByOrder);
			setCurrent({
				isLoading: true,
				page: nextPage,
				url: next(current.collection),
			});
		},
		fetchPrev: () => {
			const prevPage = current.page - 1;

			updateLocation(prevPage, sortBy, sortByOrder);
			setCurrent({
				isLoading: true,
				page: prevPage,
				url: prev(current.collection),
			});
		},
		fetchPage: (page: number) => {
			updateLocation(page, sortBy, sortByOrder);
			setCurrent({
				page,
				url: byPage(current.collection, page),
			});
		},
	};

	if (current.queryParams?.get('count')) {
		pagination.count = Number.parseInt(current.queryParams.get('count'), 10);
	}

	const includesRecord = (recordId: number): boolean => {
		return records.findIndex((item: T) => item.id === recordId) > -1;
	};

	const removeRecord = (recordId: number) => {
		const newResults: Collection<T>[] = current.results.map(
			(c: Collection<T>) => {
				const filteredRecord = c.records.filter(
					(item: T) => item.id !== recordId
				);

				c.records = filteredRecord;

				return c;
			}
		);

		setCurrent({ results: newResults });

		// Go through appended records to see if we need to remove anything from there
		setAppendedRecords((prev) => {
			if (prev.map((r) => r.id).includes(recordId)) {
				return prev.filter((r) => r.id !== recordId);
			}

			return prev;
		});
	};

	const replaceRecord = (newRecord: T, key: keyof T = 'id') => {
		const newResults: Collection<T>[] = current.results.map(
			(c: Collection<T>) => {
				const newRecords = c.records.map((oldRecord: T) => {
					if (key && oldRecord[key] === newRecord[key]) {
						return newRecord;
					}

					return oldRecord;
				});

				c.records = newRecords;

				return c;
			}
		);

		setAppendedRecords((prev) => {
			return prev.map((r) => {
				if (key && r[key] === newRecord[key]) {
					return newRecord;
				}

				return r;
			});
		});

		setCurrent({ results: newResults });
	};

	const replaceMultipleRecords = (records: T[], key: keyof T = 'id') => {
		const newResults: Collection<T>[] = current.results.map(
			(c: Collection<T>) => {
				const newRecords = c.records.map((oldRecord: T) => {
					const newRecord = records.find(
						(record) => key && oldRecord[key] === record[key]
					);

					if (!newRecord) {
						return oldRecord;
					}

					if (key && oldRecord[key] === newRecord[key]) {
						return newRecord;
					}
				});

				c.records = newRecords;

				return c;
			}
		);

		setAppendedRecords((prev) => {
			return prev.map((r) => {
				const newRecord = records.find(
					(record) => key && r[key] === record[key]
				);

				if (!newRecord) {
					return r;
				}

				if (key && r[key] === newRecord[key]) {
					return newRecord;
				}
			});
		});

		setCurrent({ results: newResults });
	};

	const replaceInRecord = (recordId: number, key: keyof T, newRecord: any) => {
		const newResults: Collection<T>[] = current.results.map(
			(c: Collection<T>) => {
				const newRecords = c.records.map((oldRecord: T) => {
					if (oldRecord.id === recordId) {
						// This should only be done if the key is an array - there may be cases when we want to
						// replace only a object
						const innerRecord = oldRecord[key] as any[any];

						const index = innerRecord.findIndex(
							(inner: any) => inner.id === newRecord.id
						);
						innerRecord[index] = newRecord;

						oldRecord[key] = innerRecord;

						return oldRecord;
					}
					return oldRecord;
				});

				c.records = newRecords;

				return c;
			}
		);

		setCurrent({ results: newResults });
	};

	const addRecord = (newRecord: T) => {
		setAppendedRecords((prev) => {
			if (!prev.map((r) => r.id).includes(newRecord.id)) {
				return [...prev, newRecord];
			} else {
				return prev;
			}
		});
	};

	const selectOnlyOne = (id: number) => {
		if (current.selectedRecordIds.includes(id)) {
			setCurrent({
				selectedRecordIds: [],
				selectedRecords: [],
			});
		} else {
			setCurrent({
				selectedRecordIds: [id],
				selectedRecords: [pageRecords.find((result: T) => result.id === id)],
			});
		}
	};

	const selectSingle = (id: number) => {
		if (current.selectedRecordIds.includes(id)) {
			setCurrent({
				selectedRecordIds: current.selectedRecordIds.filter(
					(recordId) => recordId !== id
				),
				selectedRecords: current.selectedRecords.filter(
					(record) => record.id !== id
				),
			});
		} else {
			setCurrent({
				selectedRecordIds: [...current.selectedRecordIds, id],
				selectedRecords: [
					...current.selectedRecords,
					pageRecords.find((record) => record.id === id),
				],
			});
		}
	};

	const selectMultiple = (ids: number[]) => {
		setCurrent({
			selectedRecordIds: ids,
			selectedRecords: pageRecords.filter((record) =>
				ids.includes(record.id as number)
			),
		});
	};

	const selectAll = () => {
		if (options.showOnlySelected) {
			setCurrent({ selectedRecordIds: [], selectedRecords: [] });
		} else {
			if (isAllSelected) {
				const pageRecordIds = pageRecords.map((record) => record.id);
				setCurrent({
					selectedRecordIds: current.selectedRecordIds.filter(
						(recordId) => !pageRecordIds.includes(recordId)
					),
					selectedRecords: pageRecords.filter(
						(record) => !pageRecords.includes(record)
					),
				});
			} else {
				setCurrent({
					selectedRecordIds: [
						...new Set([
							...current.selectedRecordIds,
							...pageRecords.map((record) => record.id),
						]),
					],
					selectedRecords: [...current.selectedRecords, ...pageRecords],
				});
			}
		}
	};

	const deselectAll = () =>
		setCurrent({ selectedRecordIds: [], selectedRecords: [] });

	if (current.queryParams.get('count')) {
		pagination.count = Number.parseInt(current.queryParams.get('count'), 10);
	}

	const setColumn = (column: string) => {
		const next: Partial<CurrentCollection<T>> = {
			sortBy: column,
		};

		if (usesQueryState) {
			current.queryParams.set('sort_by', next.sortBy);
			next.queryParams = current.queryParams;
		}

		setCurrent(next);
		updateLocation(current.page, next.sortBy, current.sortByOrder.toString());
	};

	const setOrder = (sortByOrder: CollectionSortOrder) => {
		const next: Partial<CurrentCollection<T>> = {
			sortByOrder,
		};

		if (usesQueryState) {
			current.queryParams.set('sort_by_order', next.sortByOrder);
			next.queryParams = current.queryParams;
		}

		setCurrent(next);
		updateLocation(current.page, current.sortBy, next.sortByOrder.toString());
	};

	const setSort = (column: string, sortByOrder: CollectionSortOrder) => {
		const next: Partial<CurrentCollection<T>> = {
			sortBy: column,
			sortByOrder,
		};

		if (usesQueryState) {
			current.queryParams.set('sort_by', next.sortBy);
			current.queryParams.set('sort_by_order', next.sortByOrder);
			next.queryParams = current.queryParams;
		}

		setCurrent(next);
		updateLocation(current.page, next.sortBy, next.sortByOrder.toString());
	};

	const sort: CollectionSort = {
		setColumn,
		column: current.sortBy,
		setOrder,
		order: current.sortByOrder,
		setSort,
	};

	return {
		records: options.showOnlySelected
			? current.results
					.flat()
					.map((collection) => filterDuplicatesByKey(collection.records, 'id'))
					.flat()
					.filter((record) => current.selectedRecordIds.includes(record.id))
			: filterDuplicatesByKey(records, 'id'),
		reset,
		refresh: fetchData,
		includesRecord,
		removeRecord,
		replaceRecord,
		replaceMultipleRecords,
		replaceInRecord,
		addRecord,
		response: current.response,
		isLoading: current.isLoading,
		sort,
		pagination,
		selection: {
			selectedRecords: current.selectedRecordIds,
			selectSingle,
			selectOnlyOne,
			selectAll,
			isSelected: (id: number) => current.selectedRecordIds.includes(id),
			isAllSelected,
			deselectAll,
			selectMultiple,
		},
		selectedRecords: current.selectedRecords,
		hasError: current.hasError,
	};
}
