import { ReactNode } from 'react';

export const symbol_tv_gen: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m220-200-24 72q-2 4-4.609 6-2.608 2-6.261 2H182q-4.8 0-8.4-3.6-3.6-3.6-3.6-8.4v-68h-30q-24.75 0-42.375-17.625T80-260v-480q0-24.75 17.625-42.375T140-800h680q24.75 0 42.375 17.625T880-740v480q0 24.75-17.625 42.375T820-200h-30v69q0 4.632-3.173 7.816T779.038-120H775q-4 0-6.5-2.154T765-128l-24-72H220Zm-80-60h680v-480H140v480Zm340-240Z"
		/>
	</svg>
);
