import { ReactNode } from 'react';

export const symbol_mixture_med: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M250-280h-70q-24.75 0-42.375-17.625T120-340v-340h-10q-12 0-21-9t-9-21.5q0-12.5 9-21t21-8.5h140v-80h-30q-12 0-21-9t-9-21.5q0-12.5 9-21t21-8.5h120q12.75 0 21.375 8.625T370-850q0 12-8.625 21T340-820h-30v80h140q12.75 0 21.375 8.625T480-710q0 12-8.625 21T450-680h-10v340q0 24.75-17.625 42.375T380-280h-70v189q0 8.5-8 12.75T286-79l-24-18q-6-4.5-9-10.5t-3-13.5v-159ZM680-80q-66 0-113-47t-47-113v-320q0-66 47-113t113-47q66 0 113 47t47 113v320q0 66-47 113T680-80ZM180-340h200v-80h-90q-12 0-21-9t-9-21q0-12 9-21t21-9h90v-60h-90q-12 0-21-9t-9-21q0-12 9-21t21-9h90v-80H180v340Zm500-320q-42 0-71 29t-29 71v10h200v-10q0-42-29-71t-71-29ZM580-310h200v-180H580v180Zm100 170q42 0 71-29t29-71v-10H580v10q0 42 29 71t71 29Zm0-410Zm0 300Z"
		/>
	</svg>
);
