import { t } from '@transifex/native';
import { ChangeEvent, Fragment, ReactNode } from 'react';
import { useMediaQuery } from 'react-responsive';

import { breakpoint } from 'pkg/config/styles';

import * as routes from 'pkg/router/routes';
import { useCurrentOrganization } from 'pkg/identity';

import Pagination from 'components/pagination';
import Icon from 'components/icon';
import AssetImage from 'components/AssetImage';
import { LargeScreen } from 'components/MediaQuery';

import * as Input from 'components/form/inputs';
import Row from 'components/layout/row';
import Column from 'components/layout/column';

import useTableSet from 'design/table/use_table_set';
import * as Table from 'design/table';
import * as Card from 'design/card';
import * as Chart from 'design/chart';

import * as css from './styles.css';

interface DataItem {
	id?: number;
	name: string;
	count: number;
	minutes: number;
}

interface DashboardItemProps {
	chartVariant: 'donut' | 'horizontal-bar';
	data: DataItem[];
}

export default function DashboardItem({
	chartVariant,
	data,
}: DashboardItemProps): JSX.Element {
	const org = useCurrentOrganization();
	const isSmallScreen = useMediaQuery({
		maxWidth: breakpoint.toMedium,
	});

	const exercises = useTableSet<DataItem>(data, {
		defaultSortKey: 'minutes',
		defaultSortOrder: 'desc',
		itemsPerPage: 10,
	});

	const tableColumns = [
		{ content: t('Title'), sortKey: 'name', width: '50%' },
		{ content: t('Times used'), align: 'right' },
		{ content: t('Minutes used'), align: 'right', sortKey: 'minutes' },
	];

	const handleFilter = (event: ChangeEvent<HTMLInputElement>) => {
		exercises.filter('name', event.target.value);
	};

	const emptyState = {
		title: t('No exercise data found!'),
		image: <AssetImage src="img/missing-entities/match-statistics.svg" />,
	};

	const chartData: Chart.ChartItem[] = exercises.unsorted.map(
		(item: DataItem) => ({
			title: item.name,
			value: item.minutes,
		})
	);

	const chartSortBy = exercises.sortBy === 'name' ? 'title' : 'value';

	const renderChart = () => {
		switch (chartVariant) {
			case 'horizontal-bar':
				return (
					<Chart.HorizontalBar
						items={chartData}
						sortKey={chartSortBy}
						sortOrder={exercises.sortOrder}
					/>
				);
			case 'donut':
				return (
					<Chart.Donut
						items={chartData}
						sortKey={chartSortBy}
						sortOrder={exercises.sortOrder}
					/>
				);
		}
	};

	const renderWrapper = (children: ReactNode) => {
		if (isSmallScreen) {
			return <Fragment>{children}</Fragment>;
		}
		return <Row align="stretch">{children}</Row>;
	};

	return renderWrapper(
		<Fragment>
			<Card.Base className={css.wrapper}>
				<div className={css.filter}>
					<Input.Field
						name="search"
						changeDelay={500}
						placeholder={t('Filter')}
						onChange={handleFilter}>
						<Input.Prefix inline>
							<Icon name="search" />
						</Input.Prefix>
					</Input.Field>
				</div>
				<Table.Table
					emptyState={emptyState}
					columns={tableColumns}
					sortBy={exercises.sortBy}
					sortOrder={exercises.sortOrder}
					onSort={exercises.sort}>
					{exercises.items.map((exercise) => (
						<Table.Row key={exercise.name}>
							<Table.LinkCell
								linkStyle
								href={routes.Exercise.Show(org.id, exercise.id)}>
								{exercise.name}
							</Table.LinkCell>
							<Table.Cell align="right">{exercise.count}</Table.Cell>
							<Table.Cell align="right">{exercise.minutes}</Table.Cell>
						</Table.Row>
					))}
				</Table.Table>
				<Pagination
					currentPage={exercises.currentPage}
					totalCount={exercises.totalCount}
					count={exercises.itemsPerPage}
					fetchNext={exercises.next}
					fetchPrev={exercises.prev}
					fetchPage={exercises.goTo}
					hasNext={exercises.hasNext}
					hasPrev={exercises.hasPrev}
				/>
			</Card.Base>

			{exercises.empty ? (
				<LargeScreen>
					<Card.Base>
						<Column className={css.emptystate}>
							{emptyState.image}
							<span className={css.emptystateTitle}>{emptyState.title}</span>
						</Column>
					</Card.Base>
				</LargeScreen>
			) : (
				<Card.Base className={css.chartWrapper}>{renderChart()}</Card.Base>
			)}
		</Fragment>
	);
}
