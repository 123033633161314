import { ReactNode } from 'react';

export const symbol_tab_recent: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M727-47q79 0 136-57t57-136q0-79-57-136t-136-57q-79 0-136 57t-57 136q0 79 57 136t136 57Zm17-195 69 63q6 5 6.5 13t-5.5 14q-5 5-13 5.5t-13-4.5l-76-70q-5-4.552-7.5-10.241-2.5-5.69-2.5-11.759v-102.03q0-8.97 6-14.97t15-6q9 0 15 6t6 14.96V-242Zm-604 82q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v251q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.462Q820-475.923 820-489v-71H550q-12.75 0-21.375-8.625T520-590v-150H140v520h305q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T445-160H140Zm0-60v-520 520-19 19Z"
		/>
	</svg>
);
