import Link from 'pkg/router/Link';

import { addFormatter } from 'components/formatted-content/FormattedContent';

addFormatter('emails', {
	defaultIgnored: true,
	selector: /(\w+([\.-]?\w+)*@\w+([\.-]?\w+)*\.\w{2,})/gi,
	decorator: (guid: string, match: string): JSX.Element => {
		const props = {};

		return (
			<Link key={guid} href={`mailto:${match}`} {...props}>
				{match}
			</Link>
		);
	},
});
