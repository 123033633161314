import { Map, Record, List } from 'immutable';
import { schema } from 'normalizr';

import Tag from 'pkg/models/tag';

import * as models from 'pkg/api/models';

export const exerciseRatingItemsSchema = new schema.Entity(
	'ratingItems',
	{},
	{
		idAttribute: (entry) => entry.ratingItemId,
	}
);

export class ExerciseRatingItems {
	static normalizr(): schema.Entity {
		return exerciseRatingItemsSchema;
	}
}

export const exerciseSchema = new schema.Entity('exercises', {
	tags: [Tag.normalizr()],
	ratingItems: [ExerciseRatingItems.normalizr()],
});

interface IExercise {
	id: number;
	groupId: number;
	authorId: number;
	userCreated: boolean;
	title: string;
	introduction: string;
	description: string;
	keyPoints: string;
	materialRequirements: string;
	areaWidth: number;
	areaLength: number;
	minAge: number;
	maxAge: number;
	minTime: number;
	maxTime: number;
	minParticipants: number;
	maxParticipants: number;
	tags: List<Tag>;
	meta: Map<string, any>;
	attachments: models.attachment.Attachment[];
	ratingItems: number[];
	links: { [key: string]: string };
	createdAt: number;
	updatedAt: number;
	thumbnail: models.attachment.Attachment;
	exerciseDrawings: any;
	author?: models.author.Author;
}

const exerciseProps: IExercise = {
	id: 0,
	groupId: 0,
	authorId: 0,
	userCreated: false,
	title: '',
	introduction: '',
	description: '',
	keyPoints: '',
	materialRequirements: '',
	areaWidth: 0,
	areaLength: 0,
	minAge: 0,
	maxAge: 0,
	minTime: 0,
	maxTime: 0,
	minParticipants: 0,
	maxParticipants: 0,
	tags: List(),
	meta: Map({}),
	attachments: [],
	ratingItems: [],
	links: {},
	createdAt: 0,
	updatedAt: 0,
	thumbnail: null,
	exerciseDrawings: null,
	author: null,
};

class Exercise
	extends Record(exerciseProps, 'ExerciseRecord')
	implements IExercise
{
	static normalizr(): schema.Entity {
		return exerciseSchema;
	}

	getPayload() {
		const payload = {
			title: this.title,
			description: this.description,
			materialRequirements: this.materialRequirements,
			keyPoints: this.keyPoints,
			introduction: this.introduction,
			minTime: this.minTime,
			maxTime: this.maxTime,
			minParticipants: this.minParticipants,
			maxParticipants: this.maxParticipants,
			areaWidth: this.areaWidth,
			areaLength: this.areaLength,
			minAge: this.minAge,
			maxAge: this.maxAge,
			exerciseDrawings: this.exerciseDrawings,
			tags: List<string>(),
		};

		if (this.tags.size > 0) {
			payload.tags = this.tags.map((n) => n.get('name'));
		}

		return payload;
	}
}

export default Exercise;
