import { ReactNode } from 'react';

export const symbol_user_attributes: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M562-700q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T562-760h288q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-700H562Zm0 160q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T562-600h288q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-540H562Zm0 160q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T562-440h288q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-380H562Zm-242-23q-51 0-84.5-33.5T202-521q0-51 33.5-84.5T320-639q51 0 84.5 33.5T438-521q0 51-33.5 84.5T320-403ZM80-190v-36q0-18.864 9-35.932T113-286q45-32 98.5-47.5T320-349q55 0 108 17t99 46q14 10 23.5 25.5T560-226v36q0 12.75-8.625 21.375T530-160H110q-12.75 0-21.375-8.625T80-190Zm55-41v11h370v-11q-39-26-90-42t-95-16q-44 0-95.5 16T135-231Zm185-232q26 0 42-16t16-42q0-26-16-42t-42-16q-26 0-42 16t-16 42q0 26 16 42t42 16Zm0-58Zm0 301Z"
		/>
	</svg>
);
