import { ReactNode } from 'react';

export const symbol_stat_0: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M438-242 242-438q-9-9-13-20t-4-22q0-11 4-22t13-20l196-196q9-9 20-13t22-4q11 0 22 4t20 13l196 196q9 9 13 20t4 22q0 11-4 22t-13 20L522-242q-9 9-20 13t-22 4q-11 0-22-4t-20-13Zm42-39 199-199-199-199-199 199 199 199Zm0-199Z"
		/>
	</svg>
);
