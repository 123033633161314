import { ReactNode } from 'react';

export const symbol_sauna: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-80q-24 0-42-18t-18-42v-680q0-24 18-42t42-18h680q24 0 42 18t18 42v680q0 24-18 42t-42 18H140Zm0-250v190h100v-100h-25q-6 0-10.5-4.5T200-255v-75h-60Zm0-60h60v-20q0-12.75 8.625-21.375T230-440h10v-95q0-25 17.5-42.5T300-595h80q25 0 42.5 17.5T440-535v95h10q12.75 0 21.375 8.625T480-410v20h340v-430H140v430Zm120 90h160v-80H260v80Zm80-335q-25 0-42.5-17.5T280-695q0-25 17.5-42.5T340-755q25 0 42.5 17.5T400-695q0 25-17.5 42.5T340-635Zm-40 495h80v-100h-80v100Zm140 0h380v-190H480v75q0 6-4.5 10.5T465-240h-25v100Zm34-504q-14-18-21.5-36.457t-7.5-37.886V-730q1-11 8.914-18T473-755q9.645 0 16.323 7.5Q496-740 495-730v12q0 11.688 5 22.844T516-671q17 21 25 39.85t8 39.15q0 10.969-8.5 18.984Q532-565 521-565t-17-8.016q-6-8.015-6-18.984 0-13.125-5-24.062Q488-627 474-644Zm100 0q-14-18-21.5-36.457t-7.5-37.886V-730q1-11 8.914-18T573-755q9.645 0 16.323 7.5Q596-740 595-730v12q0 11.688 5 22.844T616-671q17 21 25 39.85t8 39.15q0 10.969-8.5 18.984Q632-565 621-565t-17-8.016q-6-8.015-6-18.984 0-13.125-5-24.062Q588-627 574-644Zm102 0q-14-18-21.5-36.457t-7.5-37.886V-730q1-11 8.914-18T675-755q9.645 0 16.323 7.5Q698-740 697-730v12q0 11.688 5 22.844T718-671q17 21 25 39.85t8 39.15q0 10.969-8.5 18.984Q734-565 723-565t-17-8.016q-6-8.015-6-18.984 0-13.125-5-24.062Q690-627 676-644ZM260-300v-80 80Z"
		/>
	</svg>
);
