import { ReactNode } from 'react';

export const symbol_mode_heat_cool: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M356-563Zm0 0Zm-44-243q15-11 31.5-2t16.5 27v40q0 25 18 42.5t43 17.5q14 0 26-6t20-17q5-7 14-8.5t17 3.5q32 22 54.5 52.5T587-590q4 11-2.5 20.5t-18 13.5q-11.5 4-23-2T527-576q-8-19-17.5-34.5T486-641q-15 8-31.489 14-16.49 6-33.511 6-45 0-76-31.5T303-729q-49 45-86 99t-37 120q0 41 16.5 75.5T241-375q-1-4-1-7v-7q0-23 9.5-44.5T276-472l63-63q9-9 21-8.5t21 8.5l66 65q9 9 9.5 21t-8.5 21q-9 9-21.5 9t-21.5-9l-45-44-42 43q-8 8-13 18.5t-5 21.5q0 14 7 24.5t15 21.5q8 11 5.5 23.5T316-298q-11 11-25.5 12t-28.5-5q-27-12-50-30.5T171-363q-24-31-37.5-68T120-510q0-105 57-172.5T312-806Zm222 460-30 30v216q0 12.75-8.675 21.375Q486.649-70 473.825-70 461-70 452.5-78.625T444-100v-156L279-91q-9 9-21 9t-21-9q-9-9-9-21t9-21l550-550q9-9 21-9t21 9q9 9 9 21t-9 21L666-478h154q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T820-418H606l-30 30 70 70h174q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T820-258H706l94 94q9 9 9 21t-9 21q-9 9-21 9t-21-9l-94-94v116q0 12.75-8.675 21.375Q646.649-70 633.825-70 621-70 612.5-78.625T604-100v-176l-70-70Z"
		/>
	</svg>
);
