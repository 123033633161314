import { ReactNode } from 'react';

export const symbol_event_list: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M640-120q-33 0-56.5-23.5T560-200v-160q0-33 23.5-56.5T640-440h160q33 0 56.5 23.5T880-360v160q0 33-23.5 56.5T800-120H640Zm-20-60h200v-200H620v200Zm-510-70q-12.75 0-21.375-8.675Q80-267.351 80-280.175 80-293 88.625-301.5T110-310h300q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T410-250H110Zm530-270q-33 0-56.5-23.5T560-600v-160q0-33 23.5-56.5T640-840h160q33 0 56.5 23.5T880-760v160q0 33-23.5 56.5T800-520H640Zm-20-60h200v-200H620v200Zm-510-70q-12.75 0-21.375-8.675Q80-667.351 80-680.175 80-693 88.625-701.5T110-710h300q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T410-650H110Zm610 370Zm0-400Z"
		/>
	</svg>
);
