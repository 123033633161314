import { ReactNode } from 'react';

export const symbol_nest_wake_on_press: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M830-441q-20 0-35-15t-15-35v-259q0-20.833 15-35.417Q810-800 830.5-800t35 14.583Q880-770.833 880-750v259q0 20-14.583 35-14.584 15-35.417 15ZM301.634-120Q285-120 270-126.5T244-144L59-329q-9-9-9-21t8-21l36-37q14-14 31.5-21.5T162-432l78 18v-326q0-42 29-71t71-29q42 0 71 29t29 71v180h26q5 0 9.308 1 4.307 1 9.692 3l147 66q24 11 35.5 35.324Q679-430.351 674-404l-36.119 217.882Q633-157 610.196-138.5 587.392-120 558-120H301.634ZM294-180h281l43-249-183-83h-55v-228q0-18-11-29t-29-11q-18 0-29 11t-11 29v399l-154-33-23 23 171 171Zm0 0h281-281Z"
		/>
	</svg>
);
