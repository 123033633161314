import { ReactNode } from 'react';

export const symbol_location_city: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M120-180v-438q0-24.75 17.625-42.375T180-678h187v-67q0-12.444 4.5-23.722T385-788l53-51q16.934-17 41.967-17Q505-856 522-839l53 51q9 8 13.5 19.278Q593-757.444 593-745v233h187q24.75 0 42.375 17.625T840-452v272q0 24.75-17.625 42.375T780-120H180q-24.75 0-42.375-17.625T120-180Zm60 0h106v-106H180v106Zm0-166h106v-106H180v106Zm0-166h106v-106H180v106Zm247 332h106v-106H427v106Zm0-166h106v-106H427v106Zm0-166h106v-106H427v106Zm0-166h106v-106H427v106Zm247 498h106v-106H674v106Zm0-166h106v-106H674v106Z"
		/>
	</svg>
);
