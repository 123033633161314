import { Fragment, useContext, useMemo, useRef } from 'react';

import { eventTypeLabels } from 'pkg/models/event';

import DateTime from 'pkg/datetime';
import { getWeekDates, isSameDay } from 'pkg/date';
import useLongTouchPress from 'pkg/hooks/useLongTouchPress';
import * as models from 'pkg/api/models';
import { cssClasses } from 'pkg/css/utils';
import { useCurrentMembership } from 'pkg/identity';

import useCreateEventDrag, {
	CreateEventDragContext,
	getPositionFromDate,
	ICreateEventDrag,
} from 'routes/group/calendar/hooks/useCreateEventDrag';
import { useCalendarContext, ViewProps } from 'routes/group/calendar';
import { getHours, hourHeight } from 'routes/group/calendar/config';
import EventWrapper from 'routes/group/calendar/components/events/single';
import Day from 'routes/group/calendar/components/day-item';
import { getEventsForDate, groupByDate } from 'routes/group/calendar/utils';

import EventTypeContextMenuItem from 'components/event/TypeContextMenuItem';

import * as ContextMenu from 'design/context_menu';

import * as css from './styles.css';

interface DaysProps {
	day: Date;
	createEventDrag: ICreateEventDrag;
	events: models.event.Event[];
	currentAccount: models.account.Account;
}

function Days({ day, createEventDrag, events, currentAccount }: DaysProps) {
	const now = new DateTime(new Date());
	const currentDateTime = new DateTime(day);
	const isToday = DateTime.isToday(currentDateTime.startOfDay);

	const m = useCurrentMembership();
	const isAdminOrStaff = models.membership.isAdminOrStaff(m);

	const startDateTime = new DateTime(
		new Date(
			createEventDrag.eventData.start < createEventDrag.eventData.end
				? createEventDrag.eventData.start * 1000
				: createEventDrag.eventData.end * 1000
		)
	);
	const endDateTime = new DateTime(
		new Date(
			createEventDrag.eventData.end > createEventDrag.eventData.start
				? createEventDrag.eventData.end * 1000
				: createEventDrag.eventData.start * 1000
		)
	);

	const createEventDragContext = useContext(CreateEventDragContext);

	const showContextMenuAtStart =
		createEventDrag.eventData.end < createEventDrag.eventData.start;
	const contextMenuDay = isSameDay(
		day,
		new DateTime(new Date(createEventDrag.eventData.end * 1000))
	);

	const handleMouseUp = (e: any) => createEventDrag.handleMouseUp(e, day);

	const handleMove = (e: any) => createEventDrag.handleMove(e, day);

	const handleMouseDown = (e: any) => createEventDrag.handleMouseDown(e, day);

	const handleLongPress = (e: any) => createEventDrag.handleLongPress(e, day);

	const handleTouchMove = (e: any) => createEventDrag.handleTouchMove(e, day);

	const handleTouchEnd = () => createEventDrag.handleTouchEnd();

	let topStyle = getPositionFromDate(startDateTime);

	if (startDateTime.endOfDay < currentDateTime.startOfDay) {
		topStyle = 0;
	}

	let heightStyle: string | number =
		createEventDrag.mousePosition.current - topStyle;

	if (createEventDrag.eventData.end < createEventDrag.eventData.start) {
		heightStyle = getPositionFromDate(endDateTime);
	}

	if (
		createEventDrag.eventData.end < createEventDrag.eventData.start &&
		startDateTime.sameDay(endDateTime.getTimestamp())
	) {
		heightStyle =
			getPositionFromDate(endDateTime) - getPositionFromDate(startDateTime);
	}

	if (
		!startDateTime.sameDay(endDateTime.getTimestamp()) &&
		endDateTime.getTimestamp() > currentDateTime.endOfDay
	) {
		heightStyle = `calc(100% - ${topStyle}px)`;
	}

	const showEvent =
		startDateTime.isBetween(
			currentDateTime.startOfDay,
			currentDateTime.endOfDay
		) ||
		endDateTime.isBetween(
			currentDateTime.startOfDay,
			currentDateTime.endOfDay
		) ||
		endDateTime.getTimestamp() > currentDateTime.endOfDay;

	const hideEvent = startDateTime.getTimestamp() > currentDateTime.endOfDay;
	const nowPosition = getPositionFromDate(now);

	let longPressEvents = useLongTouchPress({ onLongPress: handleLongPress });

	if (!isAdminOrStaff) {
		longPressEvents = {};
	}

	const createDragEvents =
		isAdminOrStaff && !createEventDragContext.disabled
			? {
					onMouseDown: handleMouseDown,
					onMouseUp: handleMouseUp,
					onMouseMove: handleMove,
					onTouchMove: handleTouchMove,
					onTouchEndCapture: handleTouchEnd,
				}
			: {};

	const startTimeStamp = startDateTime.getUnixTimestamp();
	const endTimeStamp = endDateTime.getUnixTimestamp();

	return (
		<div
			style={{ gridAutoRows: `${hourHeight}px` }}
			className={css.innerGrid}
			{...createDragEvents}
			{...longPressEvents}>
			{showEvent &&
				!hideEvent &&
				createEventDrag.eventData.start > 0 &&
				createEventDrag.mousePosition.start !==
					createEventDrag.mousePosition.current && (
					<div
						className={css.newEvent}
						style={{
							top: topStyle,
							height: heightStyle,
						}}>
						{startDateTime.endOfDay === currentDateTime.endOfDay && (
							<span>
								{!startDateTime.sameDay(endDateTime.getTimestamp())
									? startDateTime.toLocaleTimeString({
											hour: 'numeric',
											minute: 'numeric',
											day: 'numeric',
											month: 'short',
										})
									: startDateTime.toLocaleTimeString({
											hour: 'numeric',
											minute: 'numeric',
										})}{' '}
								-{' '}
								{!startDateTime.sameDay(endDateTime.getTimestamp())
									? endDateTime.toLocaleTimeString({
											hour: 'numeric',
											minute: 'numeric',
											day: 'numeric',
											month: 'short',
										})
									: endDateTime.toLocaleTimeString({
											hour: 'numeric',
											minute: 'numeric',
										})}
							</span>
						)}
						{createEventDrag.eventData.showContextMenu &&
							!createEventDrag.mousePosition.onTouchDevice &&
							contextMenuDay && (
								<ContextMenu.Menu
									defaultOpen={true}
									toggleWith={<span></span>}
									togglePosition={{
										top: !showContextMenuAtStart && '100%',
										left: '100%',
									}}
									onClose={createEventDrag.closeModal}>
									{eventTypeLabels.map((eventType) => (
										<EventTypeContextMenuItem
											eventType={eventType}
											startsAt={startTimeStamp.toString()}
											endsAt={endTimeStamp.toString()}
											key={eventType}
										/>
									))}
								</ContextMenu.Menu>
							)}
					</div>
				)}
			{isToday && (
				<div className={css.currentTime} style={{ top: `${nowPosition}px` }} />
			)}
			<div className={css.eventsContainer}>
				<Day calculateHeight calculateOverlap dateTime={currentDateTime}>
					{events.map((e) => (
						<EventWrapper key={e.id} event={e} />
					))}
				</Day>
			</div>
			{getHours(currentAccount.hourFormat).map((h, index) => (
				<div className={css.innerGridItem} key={index} />
			))}
			{createEventDrag.eventData.showContextMenu &&
				createEventDrag.mousePosition.onTouchDevice &&
				contextMenuDay && (
					<ContextMenu.Menu
						defaultOpen={true}
						toggleWith={<span></span>}
						onClose={createEventDrag.closeModal}>
						{eventTypeLabels.map((eventType) => (
							<EventTypeContextMenuItem
								eventType={eventType}
								startsAt={startTimeStamp.toString()}
								endsAt={endTimeStamp.toString()}
								key={eventType}
							/>
						))}
					</ContextMenu.Menu>
				)}
		</div>
	);
}

export default function WeekView({
	currentDate,
	changeView,
	scrollItemRef,
	currentAccount,
}: ViewProps) {
	const calendarCtx = useCalendarContext();

	const groupedEvents = groupByDate(calendarCtx.eventsCollection.records);

	const gridRef = useRef<HTMLDivElement>(null);
	const createEventDrag = useCreateEventDrag(gridRef, scrollItemRef);

	const weekDates = useMemo(() => getWeekDates(currentDate), [currentDate]);

	const displayDay = (currentDateTime: DateTime) =>
		changeView(currentDateTime.startOfDay, 'day');

	return (
		<Fragment>
			<div className={css.weekDatesContainer}>
				{weekDates.map((day, index) => {
					const currentDateTime = new DateTime(new Date(day));
					return (
						<div
							key={index}
							className={css.dates}
							onClick={() => displayDay(currentDateTime)}>
							<div className={css.dateLabel}>
								{currentDateTime.toLocaleDateString({
									weekday: 'short',
								})}
							</div>
							<div
								className={cssClasses(
									css.dateNumber,
									DateTime.isToday(currentDateTime.startOfDay)
										? css.isToday
										: ''
								)}>
								{currentDateTime.toLocaleDateString({
									day: 'numeric',
								})}
							</div>
						</div>
					);
				})}
			</div>
			<div className={css.weekGrid} ref={gridRef}>
				<div className={css.timestamps}>
					{getHours(currentAccount.hourFormat).map((h, index) => (
						<div className={css.hour} key={index}>
							<span>{h}</span>
						</div>
					))}
				</div>
				<div className={css.dayContainer}>
					{weekDates.map((day, index) => {
						const newDate = new Date(day.setHours(12));
						const dateTime = new DateTime(newDate);
						const events = getEventsForDate(groupedEvents, dateTime);

						return (
							<Days
								key={index}
								day={day}
								events={events}
								createEventDrag={createEventDrag}
								currentAccount={currentAccount}
							/>
						);
					})}
				</div>
			</div>
		</Fragment>
	);
}
