import { ReactNode } from 'react';

export const symbol_tab_duplicate: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M305-245q-24.75 0-42.375-17.625T245-305v-515q0-24.75 17.625-42.375T305-880h515q24.75 0 42.375 17.625T880-820v515q0 24.75-17.625 42.375T820-245H305Zm0-60h515v-335H550q-12.75 0-21.375-8.625T520-670v-150H305v515Zm0-515v515-515Zm-195.175 80Q97-740 88.5-748.675q-8.5-8.676-8.5-21.5Q80-783 88.675-791.5q8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Zm165 660Q262-80 253.5-88.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5Q305-97 296.325-88.5q-8.676 8.5-21.5 8.5Zm165 0Q427-80 418.5-88.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5Q470-97 461.325-88.5q-8.676 8.5-21.5 8.5Zm165 0Q592-80 583.5-88.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5Q635-97 626.325-88.5q-8.676 8.5-21.5 8.5Zm-495-495Q97-575 88.5-583.675q-8.5-8.676-8.5-21.5Q80-618 88.675-626.5q8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Zm0 165Q97-410 88.5-418.675q-8.5-8.676-8.5-21.5Q80-453 88.675-461.5q8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Zm0 165Q97-245 88.5-253.675q-8.5-8.676-8.5-21.5Q80-288 88.675-296.5q8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Zm0 165Q97-80 88.5-88.675q-8.5-8.676-8.5-21.5Q80-123 88.675-131.5q8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5Q140-97 131.325-88.5q-8.676 8.5-21.5 8.5Zm660 0Q757-80 748.5-88.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5Q800-97 791.325-88.5q-8.676 8.5-21.5 8.5Z"
		/>
	</svg>
);
