import { ReactNode } from 'react';

export const symbol_line_end_square: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M500-320h320v-320H500v320Zm-30 60q-12.75 0-21.375-8.625T440-290v-160H110q-12.75 0-21.375-8.675Q80-467.351 80-480.175 80-493 88.625-501.5T110-510h330v-160q0-12.75 8.625-21.375T470-700h380q12.75 0 21.375 8.625T880-670v380q0 12.75-8.625 21.375T850-260H470Zm190-220Z"
		/>
	</svg>
);
