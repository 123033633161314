import { Component } from 'react';
import { connect } from 'react-redux';
import { t } from '@transifex/native';

import {
	getMatchesPlayed,
	getGoals,
	getWins,
	getDraws,
	getLosses,
	getGoalsConceded,
	getGoalsDifference,
	getCleanSheets,
} from 'pkg/selectors/matches.selector';

import GroupMatchStatisticsSummary from 'components/group/statistics/MatchSummary';

class MatchSummaryContainer extends Component {
	render() {
		const statistics = {
			matchesPlayed: {
				label: t('Matches played'),
				number: this.props.matchesPlayed,
			},
			wins: {
				label: t('Wins'),
				number: this.props.wins,
			},
			losses: {
				label: t('Losses'),
				number: this.props.losses,
			},
			draws: {
				label: t('Draws'),
				number: this.props.draws,
			},
			goals: {
				label: t('Scored goals'),
				number: this.props.goals,
			},
			goalsConceded: {
				label: t('Goals conceded'),
				number: this.props.goalsConceded,
			},
			goalsDifference: {
				label: t('Goal difference'),
				number: this.props.goalsDifference,
			},
			cleanSheets: {
				label: t('Clean sheets'),
				number: this.props.cleanSheets,
			},
		};

		return (
			<GroupMatchStatisticsSummary
				statistics={statistics}
				groupId={this.props.groupId}
				showButton={this.props.showButton}
			/>
		);
	}
}

const mapStateToProps = (state, props) => {
	const statProps = { ...props.filters.toJS(), groupId: props.groupId };

	return {
		matchesPlayed: getMatchesPlayed(state, statProps).size,
		goals: getGoals(state, statProps),
		wins: getWins(state, statProps),
		draws: getDraws(state, { withGoalData: true, ...statProps }),
		losses: getLosses(state, statProps),
		goals: getGoals(state, statProps),
		goalsDifference: getGoalsDifference(state, statProps),
		goalsConceded: getGoalsConceded(state, statProps),
		cleanSheets: getCleanSheets(state, statProps),
	};
};

export default connect(mapStateToProps)(MatchSummaryContainer);
