import { ReactNode } from 'react';

export const symbol_track_changes: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q13 0 21.5 8.5T510-850v296q22 9 36 29t14 45q0 33-23.5 56.5T480-400q-33 0-56.5-23.5T400-480q0-25 14-45t36-29v-104q-65 11-107.5 60.5T300-480q0 75 52.5 127.5T480-300q75 0 127.5-52.5T660-480q0-32-10.5-60T621-591q-8-10-8-23t9-22q9-9 21.5-8.5T664-634q26 32 41 70.5t15 83.5q0 100-70 170t-170 70q-100 0-170-70t-70-170q0-93 60-160.5T450-719v-100q-131 11-220.5 108T140-480q0 142 99 241t241 99q142 0 241-99t99-241q0-65-22-122t-62-102q-9-10-9.5-23t8.5-22q9-9 22-8.5t21 10.5q48 54 75 121.5T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z"
		/>
	</svg>
);
