import { useReducer, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { List, Record, Set } from 'immutable';

import { PageWidths } from 'pkg/config/sizes';
import * as styles from 'pkg/config/styles';

import match from 'pkg/models/match';
import MatchCompetition from 'pkg/models/match_competition';

import { fetchMatches } from 'pkg/actions/matches_deprecated';

import { RootState } from 'pkg/reducers';
import * as selectors from 'pkg/selectors';
import DateTime from 'pkg/datetime';
import { useCurrentGroup, useCurrentMembership } from 'pkg/identity';
import * as models from 'pkg/api/models';

import Cards from 'containers/group/statistics/Cards';
import MatchSummary from 'containers/group/statistics/MatchSummary';
import GoalTimeline from 'containers/group/statistics/GoalTimeline';
import MatchHistory from 'containers/group/statistics/MatchHistory';
import ScoringLeague from 'containers/group/statistics/ScoringLeague';
import PlayTime from 'containers/group/statistics/PlayTime';
import LastGameResult from 'containers/group/statistics/LastGameResult';

import {
	LargeScreen,
	SmallScreen,
	useSmallScreen,
} from 'components/MediaQuery';

import Row from 'components/layout/row';
import Filters from 'components/group/statistics/Filters';
import Column from 'components/layout/column';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';

export interface IFilters {
	before: number;
	after: number;
	matchCompetitionIds: number[];
	types: string[];
	isHome: boolean;
}

const filters = Record({
	before: DateTime.now().getUnixTimestamp(),
	after: DateTime.now().setMonth(1).setDay(1).getUnixTimestamp(),
	matchCompetitionIds: [],
	types: ['default', 'practice'],
	isHome: null,
});

export enum ActionTypes {
	ToggleFriendly = 'toggleFriendly',
	ToggleHomeChange = 'toggleHomeChange',
	ToggleAwayChange = 'toggleAwayChange',
	HandleDateChange = 'handleDateChange',
	ChangeCompetitions = 'changeCompetitions',
}

export type Action =
	| { type: ActionTypes.ToggleFriendly }
	| { type: ActionTypes.ToggleHomeChange }
	| { type: ActionTypes.ToggleAwayChange }
	| {
			type: ActionTypes.HandleDateChange;
			payload: { before: number; after: number };
	  }
	| { type: ActionTypes.ChangeCompetitions; payload: number[] };

function reducer(state: Record<IFilters>, action: Action) {
	switch (action.type) {
		case ActionTypes.ToggleFriendly:
			if (state.get('types').includes('default')) {
				return state.set('types', ['practice']);
			} else {
				return state.set('types', ['default', 'practice']);
			}
		case ActionTypes.ToggleHomeChange:
			if (state.get('isHome') === true) {
				return state.set('isHome', null);
			} else {
				return state.set('isHome', true);
			}

		case ActionTypes.ToggleAwayChange:
			if (state.get('isHome') === false) {
				return state.set('isHome', null);
			} else {
				return state.set('isHome', false);
			}

		case ActionTypes.HandleDateChange:
			return state.merge({
				before: action.payload.before,
				after: action.payload.after,
			});

		case ActionTypes.ChangeCompetitions:
			return state.set('matchCompetitionIds', action.payload);

		default:
			return state;
	}
}

interface MatchStatisticsProps {
	groupId: number;
}

const MatchStatistics: React.FC<
	React.PropsWithChildren<MatchStatisticsProps>
> = ({ groupId }) => {
	const group = useCurrentGroup();
	const dispatch = useDispatch();

	const currentSeason = useSelector((state: RootState) =>
		selectors.groups.getCurrentSeason(state, { groupId })
	);
	const lastSeason = useSelector((state: RootState) =>
		selectors.groups.getLastSeason(state, { groupId })
	);
	const currentMembership = useCurrentMembership();

	const [filterState, localDispatch] = useReducer(
		reducer,
		filters({
			after: currentSeason.start,
		})
	);

	const matches = useSelector(
		(state: RootState) =>
			selectors.matches.getMatchesPlayed(state, {
				groupId: groupId,
				before: filterState.get('before'),
				after: filterState.get('after'),
			}) as any
	)
		.map((m: match) => m.id)
		.toList()
		.toArray();

	const competitions: Set<MatchCompetition> = useSelector((state: RootState) =>
		selectors.matchCompetitions.getMatchCompetitionsByMatchIDs(state, {
			matchIDs: matches,
		})
	);

	const matchCompetitions =
		matches.length === 0 ? List<MatchCompetition>() : competitions.toList();

	const isSmallScreen = useSmallScreen();

	useEffect(() => {
		dispatch(
			fetchMatches({
				group_id: groupId,
				before: filterState.get('before') || DateTime.now().getUnixTimestamp(),
				after: filterState.get('after') || currentSeason.start,
				events: 1,
			})
		);
	}, [currentSeason.start, dispatch, groupId, filterState]);

	const contentTop = (
		<Column spacing={styles.spacing._5}>
			<MatchSummary groupId={groupId} filters={filterState} />
		</Column>
	);

	const content = (
		<Column spacing={isSmallScreen ? styles.spacing._8 : styles.spacing._5}>
			<LastGameResult groupId={groupId} />
			<MatchHistory groupId={groupId} filters={filterState} />
		</Column>
	);

	const contentRight = (
		<Column spacing={isSmallScreen ? styles.spacing._8 : styles.spacing._5}>
			<GoalTimeline groupId={groupId} filters={filterState} parts="6" />
			{(group.showRatingResults ||
				models.membership.isAdminOrStaff(currentMembership)) && (
				<ScoringLeague groupId={groupId} filters={filterState} />
			)}
			{models.membership.isAdminOrStaff(currentMembership) && (
				<PlayTime groupId={groupId} filters={filterState} />
			)}
			<Cards groupId={groupId} filters={filterState} />
		</Column>
	);

	return (
		<Fragment>
			<Filters
				filters={filterState}
				changeFilter={localDispatch}
				currentSeason={currentSeason}
				lastSeason={lastSeason}
				competitions={matchCompetitions}
			/>
			<LargeScreen>
				<LargeScreenContent.Inner maxWidth={PageWidths.EXTRA_WIDE}>
					<Column spacing={styles.spacing._8}>
						<Row>
							<Column>{contentTop}</Column>
						</Row>
						<Row spacing={styles.spacing._8}>
							<Column>{content}</Column>
							<Column>{contentRight}</Column>
						</Row>
					</Column>
				</LargeScreenContent.Inner>
			</LargeScreen>
			<SmallScreen>
				<LargeScreenContent.Inner>
					<Column spacing={styles.spacing._8}>
						{contentTop}
						{content}
						{contentRight}
					</Column>
				</LargeScreenContent.Inner>
			</SmallScreen>
		</Fragment>
	);
};

export default MatchStatistics;
