import styled from 'styled-components';

import * as palette from 'pkg/config/palette';
import spacing from 'pkg/config/spacing';
import * as breakpoints from 'pkg/config/breakpoints';

export const ResponsiveTable = styled.div`
	display: block;
	width: 100%;
	overflow: auto;
	flex: 1;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: -ms-autohiding-scrollbar;
`;

export const Table = styled.table`
	width: 100%;
	border-spacing: 0px;
	border-collapse: collapse;
	background-color: ${palette.white};

	${(props) =>
		props.fixed &&
		`
		table-layout: fixed;
	`};
`;

export const TableCell = styled.td`
	text-align: ${(props) => props.textAlign};
	padding: ${spacing._4};
	opacity: 0.6;
	font-size: var(--font-size-base);
	font-weight: var(--font-weight-normal);

	b {
		font-weight: var(--font-weight-semibold);
	}

	${(props) =>
		props.divider &&
		`
		border-left: 1px solid rgba( 100, 100, 100, .1 );
	`};

	${(props) =>
		props.active &&
		`
		opacity: 1;
	`}

	@media ${breakpoints.small} {
		padding: 0 ${spacing._3};
	}
`;

TableCell.defaultProps = {
	textAlign: 'right',
	divider: false,
	active: false,
};

export const TableHeadCell = styled.th`
	font-weight: var(--font-weight-normal);
	font-size: var(--font-size-base);
	text-align: ${(props) => props.textAlign};
	padding: ${spacing._4};
	opacity: 0.6;

	@media ${breakpoints.small} {
		padding: ${spacing._3};
	}

	${(props) =>
		props.divider &&
		`
		border-left: 1px solid rgba( 100, 100, 100, .1 );
	`} ${(props) =>
		props.highlight &&
		`
		font-weight: var(--font-weight-semibold);
		opacity: 1;
	`};

	${({ fixedWidth }) =>
		fixedWidth &&
		fixedWidth !== 0 &&
		`
		width: ${fixedWidth}px;
	`};

	@media (hover: hover) {
		&:hover {
			cursor: pointer;
		}
	}
`;

TableHeadCell.defaultProps = {
	textAlign: 'right',
	highlight: false,
	divider: false,
	fixedWidth: 0,
};

export const TableRow = styled.tr`
	cursor: ${(props) => (props.actionable ? 'pointer' : 'initial')};

	${(props) =>
		props.fixedHeight &&
		`
		height: 50px;
	`};
`;

TableRow.defaultProps = {
	fixedHeight: false,
	actionable: false,
};

export const TableBody = styled.tbody`
	${TableRow} {
		border-top: 1px solid rgba(206, 206, 206, 0.2);

		@media (hover: hover) {
			&:hover {
				background: ${(props) =>
					!props.assessmentPreview && 'rgba(189, 198, 207, 0.1)'};
			}
		}
	}
`;

export const TableHead = styled.thead``;
