import { ReactNode } from 'react';

export const symbol_send_and_archive: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m675-213-67-67q-5-5-10-5t-10 5q-5 5-5 10.5t5 10.5l81 82q9 9 21 9t21-9l82-82q5-5 5-10.5t-5-10.5q-5-5-10.5-5t-10.5 5l-67 67v-156q0-6-4.5-10.5T690-384q-6 0-10.5 4.5T675-369v156Zm-555 8v-550q0-16 13.5-24.5T162-782l622 262h-59q-24 0-45.5 1t-37.5 4L180-710v168l242 62-242 60v167l282-118q-8 17-13.5 39.5T441-295L162-178q-15 6-28.5-2.5T120-205ZM690-80q-78 0-134-55.5T500-269q0-79 56-135t134-56q78 0 134 56t56 135q0 78-56 133.5T690-80ZM180-371v-339 457-118Z"
		/>
	</svg>
);
