import { t } from '@transifex/native';
import { normalize } from 'normalizr';

import Video from 'pkg/models/video';

import { forAccountHeaders, normalizedDispatch } from 'pkg/actions/utils';
import { show } from 'pkg/actions/flashes';
import { triggerError } from 'pkg/actions/app';
import * as service from 'pkg/actions/services/videos';
import { UPDATE_VIDEO, REMOVE_VIDEO } from 'pkg/actions/action-types';

import store from 'pkg/store/createStore';
import * as sdk from 'pkg/core/sdk';
import { getOrganizationIdentityContextValues } from 'pkg/identity/organization';

const actionUpdateVideo = (videoId, payload) => ({
	type: UPDATE_VIDEO,
	videoId,
	payload,
});

const actionRemoveVideo = (videoId) => ({
	type: REMOVE_VIDEO,
	videoId,
});

/**
 * Fetches videos the authenticated user has uploaded
 */
export const fetchPersonalVideos =
	(limit = 10, linkOverride = '', forAccount = null) =>
	async (dispatch) => {
		let request;
		const { user } = getOrganizationIdentityContextValues();
		const accountId = user.accountId;
		const headers = forAccountHeaders(forAccount);

		if (!linkOverride) {
			request = await service.getAccountVideos(accountId, limit);
		} else {
			request = await sdk.get(
				linkOverride,
				{ account_id: accountId, limit },
				{},
				headers
			);
		}

		if (!request.ok) {
			dispatch(triggerError(request));
			return;
		}

		const response = await request.json();

		dispatch(normalizedDispatch(response.records, [Video.normalizr()]));

		return [request, response];
	};

export const fetch = async (videoId, showFlash = true) => {
	const request = await service.getVideo(videoId);

	if (request.ok) {
		const result = await request.json();

		normalizedDispatch(result, Video.normalizr())(store.dispatch);
	} else if (showFlash) {
		triggerError(request, null)(store.dispatch);
	}
};

export const updateVideo =
	(videoId, payload, displayFlash = true) =>
	async (dispatch) => {
		const request = await service.updateVideo(videoId, payload);

		if (request.ok) {
			const result = await request.json();
			const data = normalize([result], [Video.normalizr()]);

			dispatch(actionUpdateVideo(videoId, data));

			if (displayFlash === true) {
				show(
					{
						title: t(`Video updated`),
						message: t(`Successfully updated video`),
					},
					200
				);
			}
		}
	};

export const removeVideo = (videoId) => async (dispatch) => {
	const request = await service.destroyVideo(videoId);

	if (request.ok) {
		dispatch(actionRemoveVideo(videoId));

		show(
			{
				title: t(`Video removed`),
				message: t(`Successfully removed video.`),
			},
			200
		);
	}
};
