import { Record } from 'immutable';
import { schema } from 'normalizr';

import Order from 'pkg/models/order';
import { userProducts } from 'pkg/models/schemas';

interface IUserProduct {
	id: number;
	userId: number;
	productId: number;
	orderID: number;
	validUntil: number;
	status: string;
	stripeStatus: string;
	createdAt: number;
	updatedAt: number;
	order: Order;
	productPriceId: number;
	type: string;
}

const userProductProps: IUserProduct = {
	id: 0,
	userId: 0,
	productId: 0,
	orderID: null,
	validUntil: 0,
	status: '',
	stripeStatus: '',
	createdAt: 0,
	updatedAt: 0,
	order: null,
	productPriceId: null,
	type: '',
};

class UserProduct
	extends Record(userProductProps, 'UserProductRecord')
	implements IUserProduct
{
	static normalizr(): schema.Entity {
		return userProducts;
	}

	getStatus(): { name: string } {
		return { name: this.status };
	}
}

export default UserProduct;
