import { css } from 'styled-components';

export const family = {
	default: '"system-ui", "Helvetica Neue", "Helvetica", sans-serif',
};

interface sizeMap {
	[key: string]: string;
}

// size maps pixel values for font sizes
export const size: sizeMap = {
	'2xs': '9px',
	xs: '11px',
	sm: '13px',
	base: '15px',
	lg: '17px',
	xl: '20px',
	'2xl': '24px',
	'3xl': '30px',
	'4xl': '36px',
	'5xl': '48px',
};

// lineHeight maps pixel values for lineHeights
export const lineHeight: sizeMap = {
	'2xs': '12px',
	xs: '13px',
	sm: '17px',
	base: '20px',
	lg: '20px',
	xl: '23px',
	'2xl': '30px',
	'3xl': '34px',
	'4xl': '38px',
	'5xl': '50px',
};

export enum FontWeights {
	Thin = 300,
	Normal = 450,
	SemiBold = 600,
	Bold = 700,
}

interface FontWeight {
	thin: FontWeights;
	normal: FontWeights;
	semibold: FontWeights;
	bold: FontWeights;
}

export const weight: FontWeight = {
	thin: FontWeights.Thin,
	normal: FontWeights.Normal,
	semibold: FontWeights.SemiBold,
	bold: FontWeights.Bold,
};

// pair returns a finished CSS string of font size and line height
// any return because the type definitions for styled components are insane.
export const pair = (weight: string = 'base'): any => css`
	font-size: ${size[weight]};
	line-height: ${lineHeight[weight]};
`;
