import { ReactNode } from 'react';

export const symbol_trip_origin: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-147q106 0 179.5-73.5T733-480q0-106-73.5-179.5T480-733q-106 0-179.5 73.5T227-480q0 106 73.5 179.5T480-227Z"
		/>
	</svg>
);
