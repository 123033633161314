import { t } from '@transifex/native';
import { Fragment, useState, useEffect, ChangeEvent } from 'react';
import { useFormContext } from 'react-hook-form';

import * as Input from 'components/form/inputs';
import Section from 'components/form/Section';
import Column from 'components/layout/column';

interface EmailProps {
	data: {
		submissionEmailEnabled: boolean;
		submissionEmailSubject?: string;
		submissionEmailContent?: string;
	};
	onChange: (field: string, value: string) => void;
	onToggle: () => void;
	resetEmailCustomization: () => void;
}

const SubmissionEmail = ({
	data,
	onChange,
	onToggle,
	resetEmailCustomization,
}: EmailProps) => {
	const { reset } = useFormContext();

	const hasSubject = !!data.submissionEmailSubject;
	const hasContent = !!data.submissionEmailContent;

	const hasCustomizedContent = hasSubject || hasContent;

	const [isCustomize, setIsCustomize] = useState<boolean>(false);

	useEffect(() => {
		setIsCustomize(hasCustomizedContent);
	}, [hasCustomizedContent]);

	const handleToggleCustomize = () => {
		setIsCustomize((prev) => !prev);

		if (isCustomize) {
			resetEmailCustomization();

			// reset cusomization inputs
			reset(
				{ submissionEmailSubject: '', submissionEmailContent: '' },
				{ keepDirty: true }
			);
		}
	};

	const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
		onChange(event.target.name, event.target.value);

	return (
		<Section
			icon="mail"
			title={`4. ${t(`Submission email`)}`}
			description={t(
				`You may add a custom text to send to registrants of this form`
			)}>
			<Column>
				<Input.Control
					name="submissionEmailEnabled"
					type="checkbox"
					checked={data.submissionEmailEnabled}
					onChange={onToggle}
					label={t(`Send email upon submission`)}
					optional
				/>
				<Input.Control
					name="submissionEmailCustomize"
					type="checkbox"
					checked={isCustomize}
					label={t(`Customize email`)}
					onChange={handleToggleCustomize}
					optional
				/>
				{isCustomize && (
					<Fragment>
						<Input.Group label={t(`Subject`)}>
							<Input.Field
								name="submissionEmailSubject"
								value={data.submissionEmailSubject}
								placeholder={t(`Your registration is submitted`)}
								required={hasContent}
								onChange={handleChange}
							/>
						</Input.Group>
						<Input.Group label={t(`Content`)}>
							<Input.Area
								name="submissionEmailContent"
								minRows={4}
								value={data.submissionEmailContent}
								placeholder={t(`Thank you for your submission.`)}
								required={hasSubject}
								onChange={handleChange}
							/>
						</Input.Group>
					</Fragment>
				)}
			</Column>
		</Section>
	);
};

export default SubmissionEmail;
