import { ReactNode } from 'react';

export const symbol_mark_email_unread: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h399q12.75 0 21.375 9T568-769q-1 12-9.338 20.5Q550.325-740 538-740H140v520h680v-325q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T880-545v325q0 24-18 42t-42 18H140Zm0-580v520-520Zm340 218 137-89q10-7 19.375-3.85T651-604q5 8 4.5 17.5T644-570l-148 97q-7.5 5-15.75 5T464-473L140-681v-59l340 218Zm279.882-128Q714-650 682-682.118q-32-32.117-32-78Q650-806 682.118-838q32.117-32 78-32Q806-870 838-837.882q32 32.117 32 78Q870-714 837.882-682q-32.117 32-78 32Z"
		/>
	</svg>
);
