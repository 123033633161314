import { ReactNode } from 'react';

export const symbol_lock_clock: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M350-634h260v-93q0-57-37.5-95T480-860q-55 0-92.5 38T350-727v93ZM220-80q-23 0-41.5-18.5T160-140v-434q0-23 18.5-41.5T220-634h70v-93q0-81 55-137t135-56q80 0 135 56t55 137v93h70q23 0 41.5 18.5T800-574v49q0 14.5-9.318 21.75t-20.5 7.25Q759-496 749.5-503.5 740-511 740-526v-48H220v434h298q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q548-97 539.375-88.5T518-80H220Zm533 34q-79 0-136-57t-57-136q0-79 57-136t136-57q79 0 136 57t57 136q0 79-57 136T753-46Zm17-195v-103.04q0-8.96-6-14.96t-15-6q-9 0-15 6t-6 14.97V-242q0 6.069 2.5 11.759Q733-224.552 738-220l76 70q5 5 13 4.5t13-5.5q6-6 5.5-14t-6.5-13l-69-63ZM220-574v434-434Z"
		/>
	</svg>
);
