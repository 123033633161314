// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vQTh566OWf9Re_WhZ6x5,
.fX3M15s1cbW0eGsEcAxF,
.S3fHY0eQKJieOnexzGo7 {
	margin: 0;
	padding: 0;
	text-indent: 0;
}

.vQTh566OWf9Re_WhZ6x5 {
	display: grid;
	grid-template-columns: 1fr 4fr;
	grid-gap: var(--spacing-4);
	gap: var(--spacing-4);
}

.fX3M15s1cbW0eGsEcAxF {
	padding-top: var(--spacing-1);
	grid-column-start: 1;
	text-align: right;
	word-break: break-word;
	font-size: var(--font-size-sm);
	color: var(--palette-gray-500);
}

.S3fHY0eQKJieOnexzGo7 {
	grid-column-start: 2;
	word-break: break-word;
}
`, "",{"version":3,"sources":["webpack://./routes/organization/user-profile/data-list/styles.css"],"names":[],"mappings":"AAAA;;;CAGC,SAAS;CACT,UAAU;CACV,cAAc;AACf;;AAEA;CACC,aAAa;CACb,8BAA8B;CAC9B,0BAAqB;CAArB,qBAAqB;AACtB;;AAEA;CACC,6BAA6B;CAC7B,oBAAoB;CACpB,iBAAiB;CACjB,sBAAsB;CACtB,8BAA8B;CAC9B,8BAA8B;AAC/B;;AAEA;CACC,oBAAoB;CACpB,sBAAsB;AACvB","sourcesContent":[".wrapper,\n.label,\n.value {\n\tmargin: 0;\n\tpadding: 0;\n\ttext-indent: 0;\n}\n\n.wrapper {\n\tdisplay: grid;\n\tgrid-template-columns: 1fr 4fr;\n\tgap: var(--spacing-4);\n}\n\n.label {\n\tpadding-top: var(--spacing-1);\n\tgrid-column-start: 1;\n\ttext-align: right;\n\tword-break: break-word;\n\tfont-size: var(--font-size-sm);\n\tcolor: var(--palette-gray-500);\n}\n\n.value {\n\tgrid-column-start: 2;\n\tword-break: break-word;\n}\n"],"sourceRoot":""}]);
// Exports
export var wrapper = `vQTh566OWf9Re_WhZ6x5`;
export var label = `fX3M15s1cbW0eGsEcAxF`;
export var value = `S3fHY0eQKJieOnexzGo7`;
export default ___CSS_LOADER_EXPORT___;
