import { ReactNode } from 'react';

export const symbol_no_flash: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m812-63-92-91v30q0 18-13.2 31T676-80H124q-18.333 0-31.167-12.833Q80-105.667 80-124v-416q0-18.333 12.833-31.167Q105.667-584 124-584h126l19-21L47-827q-9-9.067-9-21.533Q38-861 47.053-870q9.052-9 21.5-9Q81-879 90-870l765 765q9 9 9 21t-9.053 21q-9.052 9-21.5 9Q821-54 812-63Zm-672-77h520v-74L540-334q-2 57-42.5 95.5T400-200q-58 0-99-41t-41-99q0-57 38.5-97.5T394-480l-44-44H140v384Zm259.765-120Q433-260 456.5-283.265q23.5-23.264 23.5-56.5Q480-373 456.735-396.5q-23.264-23.5-56.5-23.5Q367-420 343.5-396.735q-23.5 23.264-23.5 56.5Q320-307 343.265-283.5q23.264 23.5 56.5 23.5ZM720-540v142q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.5T660-398v-126H522l-65-65q-11-11-9-22.5t10-19.5q8-8 20.056-9.5Q490.111-642 501-631l47 47h128q18.333 0 31.167 12.833Q720-558.333 720-540Zm54-170h-15q-6 0-10.5-4.5T744-725v-140q0-6 4.5-10.5T759-880h98q7.792 0 12.396 6.5Q874-867 871-859l-50 113h34q8.5 0 12.75 7t.25 15l-80 151q-3 5-8.5 4t-5.5-7v-134ZM660-386Zm-155 17Z"
		/>
	</svg>
);
