export default {
	name: 'clapperboard',
	viewBox: '0 0 23 21',
	objects: [
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M22.892 4.11534L22.0576 0.33313L0.953857 4.52197L1.02888 4.89994L1.01588 4.9021L1.12649 5.3917L1.181 5.66631L1.1882 5.66488L1.5718 7.36283L1.57176 18.2653C1.57176 19.554 2.78861 20.5987 4.28966 20.5987H20.2663C21.7673 20.5987 22.9842 19.554 22.9842 18.2653L22.9842 8.24056H22.9915V7.07389H7.72574L22.892 4.11534ZM20.9887 8.24056H17.0971L15.8377 9.3217H19.7311L20.9887 8.24056ZM14.6626 8.24056H10.7683L9.50897 9.3217H13.4033L14.6626 8.24056ZM8.33384 8.24056H4.44371L3.18439 9.3217H7.07451L8.33384 8.24056ZM1.60396 7.36283H1.5718L1.60345 7.35756L1.60396 7.36283ZM4.89618 6.43721L8.57432 5.7197L6.61966 4.58681L2.95765 5.31367L4.89618 6.43721ZM8.91221 4.13176L10.8792 5.27007L14.5582 4.55239L12.5773 3.40428L8.91221 4.13176ZM14.869 2.94941L18.535 2.22176L20.5421 3.38508L16.86 4.10336L14.869 2.94941ZM3.33839 18.2653V10.8384H21.2175V18.2653C21.2175 18.7164 20.7917 19.082 20.2663 19.082H4.28966C3.76429 19.082 3.33839 18.7164 3.33839 18.2653Z',
			},
		},
	],
};
