import { ReactNode } from 'react';

export const symbol_food_bank: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M220-120q-24.75 0-42.375-17.625T160-180v-390q0-14.25 6.375-27T184-618l260-195q15.68-12 35.84-12Q500-825 516-813l260 195q11.25 8.25 17.625 21T800-570v390q0 24.75-17.625 42.375T740-120H220Zm0-60h520v-394L480-763 220-574v394Zm260-292Zm-60 232q8 0 14-6t6-14v-140q25 0 42.5-17.5T500-460v-100q0-8-6-14t-14-6q-8 0-14 6t-6 14v100h-20v-100q0-8-6-14t-14-6q-8 0-14 6t-6 14v100h-20v-100q0-8-6-14t-14-6q-8 0-14 6t-6 14v100q0 25 17.5 42.5T400-400v140q0 8 6 14t14 6Zm160 0q8 0 14-6t6-14v-294q0-11-7.5-18.5T574-580q-27 0-40.5 25T520-500v90q0 13 8.5 21.5T550-380h10v120q0 8 6 14t14 6Z"
		/>
	</svg>
);
