export function Create(userID: number): string {
	return `/v1/users/${userID}/ratings`;
}

export function Show(ratingID: number): string {
	return `/v1/user-ratings/${ratingID}`;
}

export function ShowPrimaryRating(userID: number): string {
	return `/v1/users/${userID}/primary-rating`;
}

export function TogglePrimaryUserRating(ratingID: number): string {
	return `/v1/user-ratings/${ratingID}`;
}

export function UserIndex(userID: number): string {
	return `/v1/users/${userID}/ratings`;
}
