import { ReactNode } from 'react';

export const symbol_currency_ruble: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M290-150v-100h-60q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T230-310h60v-110h-60q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T230-480h60v-330q0-12.75 8.625-21.375T320-840h230q87.818 0 148.909 61.128Q760-717.744 760-629.872T698.909-481Q637.818-420 550-420H350v110h140q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T490-250H350v100q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T290-150Zm60-330h200q63 0 106.5-43.5T700-630q0-63-43.5-106.5T550-780H350v300Z"
		/>
	</svg>
);
