import { ReactNode } from 'react';

export const symbol_person_celebrate: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M373-150v-183q-56-5-111-12t-110-18q-13-3-20.5-13.5T127-400q3-13 15.5-19t26.5-3q77 16 155 22.5t156 6.5q78 0 156-6.5T791-422q14-3 26 3t15 19q3 13-5 24t-22 14q-54 11-108.5 17.5T587-333v183q0 13-8.5 21.5T557-120H403q-13 0-21.5-8.5T373-150Zm107-304q-30 0-51.5-21.5T407-527q0-30 21.5-51.5T480-600q30 0 51.5 21.5T553-527q0 30-21.5 51.5T480-454ZM170-570q-21 0-35.5-14.5T120-620q0-20 14.5-35t35.5-15q20 0 35 15t15 35q0 21-15 35.5T170-570Zm620 0q-21 0-35.5-14.5T740-620q0-20 14.5-35t35.5-15q20 0 35 15t15 35q0 21-15 35.5T790-570ZM290-720q-21 0-35.5-14.5T240-770q0-20 14.5-35t35.5-15q20 0 35 15t15 35q0 21-15 35.5T290-720Zm380 0q-21 0-35.5-14.5T620-770q0-20 14.5-35t35.5-15q20 0 35 15t15 35q0 21-15 35.5T670-720Zm-190-60q-21 0-35.5-14.5T430-830q0-20 14.5-35t35.5-15q20 0 35 15t15 35q0 21-15 35.5T480-780Z"
		/>
	</svg>
);
