import { ReactNode } from 'react';

export const symbol_unsubscribe: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-160q-24 0-42-18.5T80-220v-520q0-23 18-41.5t42-18.5h680q23 0 41.5 18.5T880-740v237q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T820-503v-177L496-473q-4 2-7.5 3t-8.5 1q-5 0-8.5-1t-7.5-3L140-680v460h356q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T496-160H140Zm340-367 340-213H140l340 213ZM140-220v-254 5-211 7-43.5.5-24 24.094-.376V-673v-7 460ZM773-40q-77.605 0-132.302-54Q586-148 586-226.014q0-78.014 54.698-133Q695.395-414 773-414q77.605 0 132.302 54.986Q960-304.028 960-226.014 960-148 905.302-94 850.605-40 773-40Zm-93-170h186q8 0 14-6t6-14q0-8-6-14t-14-6H680q-8 0-14 6t-6 14q0 8 6 14t14 6Z"
		/>
	</svg>
);
