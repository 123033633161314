import { ReactNode } from 'react';

export const symbol_media_output_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M310-560ZM470-80q-28.875 0-49.438-20.562Q400-121.125 400-150v-170q0-49 17.5-92t49.5-75l29 29q-26 27-41 62.5T440-320v60h70q12.75 0 21.375 8.625T540-230v120q0 12.75-8.625 21.375T510-80h-40Zm-130-80H139q-25 0-42-17.625T80-220v-595h59v595h201v60Zm204-335-29-30q27-17 58.5-26t66.5-9q100 0 170 70t70 170v160l-40-40v-120q0-83.333-58.333-141.667Q723.333-520 640-520q-26.163 0-50.581 6.5Q565-507 544-495ZM340-280q-58 0-99.5-41.145T199-420q0-58 41-99t99-41q25 0 46.5 8t39.5 22q-11 11-20 22.5T388-483q-11-8-23-12.5t-26-4.5q-33.333 0-56.667 23.333Q259-453.333 259-420q0 33 24.5 56.5T341-340q0 5-.5 9.922-.5 4.922-.5 10.078v40ZM219-820l-60-60h381q24.75 0 42.375 17.625T600-820v202.5q-16 2.5-30.915 5.974Q554.169-608.053 540-603v-217H219Zm186 126ZM813-57 49-821q-9-9-9-21t9-21q9-9 21-9t21 9L855-99q9 9 9 21t-9 21q-9 9-21 9t-21-9Z"
		/>
	</svg>
);
