import { t } from '@transifex/native';
import { Fragment, useMemo } from 'react';
import styled from 'styled-components';

import * as styles from 'pkg/config/styles';

import DateTime from 'pkg/datetime';
import { useCurrentGroup, useCurrentUser } from 'pkg/identity';
import * as models from 'pkg/api/models';

import {
	FormattedContent,
	FormattedContentMaxLength,
} from 'components/formatted-content';
import CardWrapperImport, * as Card from 'components/Card';
import Badge from 'components/Badge';
import Icon from 'components/icon';
import Avatar from 'components/avatar';

import GalleryPreview from 'components/attachment/gallery/preview';

const Wrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
`;

const Title = styled.div`
	flex: 0 0 100%;
	text-align: center;
	color: var(--palette-black);

	& > *:last-child {
		margin-bottom: var(--spacing-7);
	}

	strong {
		display: block;
		font-weight: var(--font-weight-semibold);
		margin-bottom: var(--spacing-2);
	}

	span {
		display: block;
		color: var(--palette-gray-500);
	}

	span + span {
		margin-top: var(--spacing-3);
	}
`;

const CardWrapper = styled(CardWrapperImport)`
	max-width: 375px;
	width: 375px;
`;

const AuthorWrapper = styled.div`
	margin-bottom: var(--spacing-5);
	display: grid;
	grid-template-areas:
		'avatar author'
		'avatar timestamp';
	grid-template-columns: 40px 1fr;
	grid-column-gap: var(--spacing-4);
	align-items: center;
	align-content: center;
`;

const AuthorName = styled.div`
	margin-bottom: 0;
	grid-area: author;
	text-decoration: none;
	font-size: var(--font-size-base);
	font-weight: var(--font-weight-semibold);
	display: inline-block;
	color: var(--palette-blue-500);

	@media (hover: hover) {
		&:hover {
			color: var(--palette-blue-400);
		}
	}
`;

const AvatarWrapper = styled.div`
	grid-area: avatar;
`;

const AuthorTimestamp = styled.div`
	display: block;
	grid-area: timestamp;
	text-decoration: none;
	font-weight: var(--font-weight-normal);
	font-size: var(--font-size-sm);
	color: var(--palette-gray-500);
`;
const StickyLabelIcon = styled(Icon)`
	margin-right: var(--spacing-2);
	transform: scale(1) rotate(45deg);
`;

const CardInlineLabeledHeading = styled.div`
	font-size: var(--font-size-sm);
	text-transform: uppercase;
	letter-spacing: 0.8px;
	color: var(--palette-gray-500);
	font-weight: var(--font-weight-semibold);

	@media ${styles.breakpoint.small} {
		font-size: var(--font-size-sm);
	}
`;

const ItemBody = styled.div`
	padding: var(--spacing-5);
`;

const Preview = ({ post, numSelectedGroups, isMultiGroupPost }) => {
	const postAuthor = useCurrentUser();
	const authorGroup = useCurrentGroup();

	const publishDate = useMemo(() => {
		return new DateTime(new Date(post.publishedAt * 1000)).toLocaleDateString({
			hour: 'numeric',
			minute: 'numeric',
		});
	}, [post.publishedAt]);

	const isScheduled = post.status === 'scheduled';

	return (
		<Wrapper>
			<Title>
				{isMultiGroupPost && (
					<Fragment>
						<strong>
							{t(`{num} groups selected`, {
								num: numSelectedGroups,
							})}
						</strong>
					</Fragment>
				)}

				{isScheduled && (
					<span>{t(`Scheduled for {date}`, { date: publishDate })}</span>
				)}
			</Title>

			<CardWrapper>
				{post.isSticky && (
					<Card.Header $slim>
						<CardInlineLabeledHeading key="card-heading-sticky">
							<StickyLabelIcon name="pin" />
							{t(`Sticky post`)}
						</CardInlineLabeledHeading>
					</Card.Header>
				)}

				<ItemBody>
					<AuthorWrapper>
						<AvatarWrapper>
							{post.authorGroupId ? (
								<Badge badgeUrl={authorGroup.profileImageUrl} />
							) : (
								<Avatar user={postAuthor} />
							)}
						</AvatarWrapper>

						<AuthorName>
							{post.authorGroupId
								? authorGroup.name
								: models.user.fullName(postAuthor)}
						</AuthorName>

						<AuthorTimestamp>{t(`Not published yet`)}</AuthorTimestamp>
					</AuthorWrapper>

					{post.title ? <Card.SubHeading>{post.title}</Card.SubHeading> : null}

					<FormattedContent
						key={`formated-content-${post.id}`}
						raw={post.content}
						maxLength={FormattedContentMaxLength.Standard}
					/>
				</ItemBody>

				<GalleryPreview
					disableGallery
					attachments={post.newAttachments?.toList().toArray()}
				/>
			</CardWrapper>
		</Wrapper>
	);
};

export default Preview;
