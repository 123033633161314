import { ReactNode } from 'react';

export const symbol_7k: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m570-450 77 82q2 2 17 8 16 0 22.5-15t-4.5-26l-74-79 74-80q11-11 4.5-25.5T664-600q-4.789 0-8.895 2-4.105 2-8.105 6l-77 82v-65q0-10.833-7.116-17.917-7.117-7.083-18-7.083Q534-600 527-592.917q-7 7.084-7 17.917v190q0 10.833 7.116 17.917 7.117 7.083 18 7.083Q556-360 563-367.083q7-7.084 7-17.917v-65ZM373-550l-53 154q-5 14 3.333 25 8.334 11 22.5 11Q355-360 362-365.5q7-5.5 10-13.5l59-173q6-18.462-7-33.231Q411-600 393-600H281q-10.833 0-17.917 7.116-7.083 7.117-7.083 18Q256-564 263.083-557q7.084 7 17.917 7h92ZM180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-60h600v-600H180v600Zm0-600v600-600Z"
		/>
	</svg>
);
