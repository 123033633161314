import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';

import * as palette from 'pkg/config/palette';
import { small } from 'pkg/config/breakpoints';

import { getPositionScores } from 'pkg/selectors/ratings.selector';

import { changePosition } from 'pkg/actions/ratings';

import Icon from 'components/icon';
import Avatar from 'components/avatar';

import PositionMap from 'components/user/rating/PositionMap';

const ComparisonAvatar = styled(Avatar)``;

const PositionSlug = styled.span`
	font-size: var(--font-size-xs);
	text-align: center;
	text-transform: uppercase;
	letter-spacing: -0.5px;

	@media ${small} {
		font-size: var(--font-size-2xs);
	}

	color: ${palette.gray[800]};
	border-radius: var(--radius-5);

	width: 100%;
	height: 100%;

	display: flex;
	justify-content: center;
	align-items: center;

	position: absolute;
	top: 0;
	left: 0;
`;

const BestPositionIcon = styled(Icon)`
	z-index: 10;
	height: 25px;
	width: 25px;
	top: -13px;
	position: absolute;
	right: -10px;

	color: ${palette.white};
	background: ${palette.blue[500]};
	border-radius: 50%;
	padding: 3px;

	${(props) =>
		props.comparison &&
		css`
			right: -10px;
			left: auto;
			top: -13px;
		`};

	@media ${small} {
		height: 20px;
		width: 20px;
	}
`;

const Spot = styled.div`
	${(props) =>
		props.split
			? css`
					background-image: linear-gradient(
						to right,
						hsl(${props.color[0]}, ${props.color[1]}%, ${props.color[2]}%),
						hsl(${props.color[0]}, ${props.color[1]}%, ${props.color[2]}%)
							calc(50% - 1px),
						rgb(255, 255, 255) calc(50% - 1px),
						rgb(255, 255, 255) calc(50% + 1px),
						hsl(${props.split[0]}, ${props.split[1]}%, ${props.split[2]}%)
							calc(50% + 1px)
					);
				`
			: css`
					background-color: hsl(
						${props.color[0]},
						${props.color[1]}%,
						${props.color[2]}%
					);
				`};

	width: 13%;
	height: 0;
	padding-bottom: 6.5%;
	border-radius: var(--radius-2);
	cursor: pointer;
	font-weight: var(--font-weight-semibold);

	${(props) =>
		props.active &&
		css`
			box-shadow:
				inset 0 0 0 5px var(--palette-white),
				0 0 0 15px rgba(255, 255, 255, 0),
				0 3px 5px 2px rgba(0, 0, 0, 0.4);

			@media ${small} {
				box-shadow:
					inset 0 0 0 2px var(--palette-white),
					0 0 0 15px rgba(255, 255, 255, 0),
					0 3px 5px 2px rgba(0, 0, 0, 0.4);
			}
		`};

	${Avatar}, ${ComparisonAvatar} {
		font-size: 0.6rem;
		width: 35%;
		bottom: -5px;
		position: absolute;

		@media ${small} {
			width: 40%;
		}
	}

	${Avatar} {
		left: -5px;
		${(props) =>
			!props.hasComparison &&
			css`
				height: 20px;
				width: 20px;
				right: -10px;
				left: auto;
				top: -13px;
				bottom: auto;
			`};
	}

	${ComparisonAvatar} {
		right: -5px;
		left: unset;
	}

	@media ${small} {
		width: 19%;
		padding-bottom: 7%;
	}
`;

class CurrentPosition extends Component {
	isMaturityAdjusted() {
		return this.props.maturityAdjusted;
	}

	clickPosition = (positionId) => {
		this.props.changePosition(positionId, this.props.rating.id);
	};

	// Returns a HSL set for the given position
	getColorForPosition(scoreSet, id) {
		scoreSet = [...scoreSet];

		scoreSet.sort((a, b) =>
			this.isMaturityAdjusted()
				? a.maturityAdjustedValue - b.maturityAdjustedValue
				: a.value - b.value
		);

		let positionAverages = scoreSet
			.map((item) =>
				this.isMaturityAdjusted() ? item.maturityAdjustedValue : item.value
			)
			.sort();

		let max = Math.max(...positionAverages);
		let min = Math.min(...positionAverages);
		let median = positionAverages[Math.round(positionAverages.length / 2)];

		let medianDiff = 1 - (median - min) / (max - min);

		const score = scoreSet.find((i) => parseInt(i.positionId, 10) === id);

		if (!score) {
			return null;
		}

		let avg = this.isMaturityAdjusted()
			? score.maturityAdjustedValue
			: score.value;
		let diff = 1 - (avg - min) / (max - min);

		let hue = 360; // Red
		let saturation = 100 * diff;

		if (diff < medianDiff) {
			hue = 120; // Green
			saturation = 100 - 100 * diff;
		}

		return [hue, saturation, Math.min(100 * (1 - Math.abs(diff - 0.5)), 85)];
	}

	get bestPositions() {
		const { positionScores, comparisonScores } = this.props;

		const positionValue = this.isMaturityAdjusted()
			? 'maturityAdjustedValue'
			: 'value';

		let bestPosition = {};
		let bestComparisonPosition = {};

		positionScores.forEach((item) => {
			if (!bestPosition[positionValue]) {
				bestPosition = item;
			}

			if (bestPosition[positionValue] < item[positionValue]) {
				bestPosition = item;
			}
		});

		comparisonScores.forEach((item) => {
			if (!bestComparisonPosition[positionValue]) {
				bestComparisonPosition = item;
			}

			if (bestComparisonPosition[positionValue] < item[positionValue]) {
				bestComparisonPosition = item;
			}
		});

		return {
			positionId: bestPosition.positionId,
			comparisonId: bestComparisonPosition.positionId,
		};
	}

	get positions() {
		const positions = {};

		const { positionScores, comparisonScores } = this.props;

		positionScores.forEach((item) => {
			const id = parseInt(item.positionId, 10);

			positions[id] = {
				id,
				slug: item.position.slug,
				name: item.position.name,
				mainColor: this.getColorForPosition(positionScores, id),
				score: this.isMaturityAdjusted()
					? item.maturityAdjustedValue
					: item.value,
			};
		});

		if (comparisonScores) {
			comparisonScores.forEach((item) => {
				let position;

				if (positions[parseInt(item.positionId, 10)]) {
					position = positions[parseInt(item.positionId, 10)];
				} else {
					position = {
						slug: item.position.slug,
						name: item.position.name,
						id: parseInt(item.positionId, 10),
						mainColor: [0, 0, 30],
					};
				}

				position.secondColor = this.getColorForPosition(
					comparisonScores,
					position.id
				);

				positions[position.id] = position;
			});
		}

		return positions;
	}

	get spots() {
		const spots = Object.values(this.positions).map((position) => (
			<Spot
				className={position.slug}
				color={position.mainColor}
				split={position.secondColor}
				key={position.id}
				hasComparison={this.props.comparison?.suggestedPositionId}
				onClick={this.clickPosition.bind(this, position.id)}
				loading={
					this.props.loading && this.props.currentPositionId == position.id
						? 1
						: 0
				}
				active={this.props.currentPositionId == position.id}>
				<PositionSlug>
					{position.name}{' '}
					{!this.props.hiddenScores && Math.trunc(position.score)}
				</PositionSlug>

				{this.bestPositions?.positionId === position.id && (
					<BestPositionIcon name="star" />
				)}

				{position.id === this.props.rating?.suggestedPositionId && (
					<Avatar user={this.props.rating.user} />
				)}

				{position.id === this.props.comparison?.suggestedPositionId && (
					<ComparisonAvatar user={this.props.comparison.user} />
				)}

				{position.id === this.bestPositions?.comparisonId && (
					<BestPositionIcon comparison name="star" />
				)}
			</Spot>
		));

		return spots;
	}

	render() {
		return (
			<Fragment>
				<PositionMap>
					{this.spots}
					{this.fetchingIndicator}
				</PositionMap>
			</Fragment>
		);
	}
}

const mapState = () => {
	return (state, props) => {
		const positionScores = getPositionScores(state, {
			ratingId: props.rating.id,
		});
		const comparisonScores = getPositionScores(state, {
			ratingId: props.comparison.id,
		});

		return {
			maturityAdjusted: state.ratings.maturityAdjustment,
			positionScores,
			comparisonScores,
			hiddenScores: props.rating?.hiddenScores,
			loading: state.ratings.positionFetchingStatus,
			currentPositionId: state.ratings.currentPosition,
		};
	};
};

const actions = {
	changePosition,
};

export default connect(mapState, actions)(CurrentPosition);
