import { Component } from 'react';
import styled, { css } from 'styled-components';

import { small } from 'pkg/config/breakpoints';
import * as palette from 'pkg/config/palette';
import * as styles from 'pkg/config/styles';

export const SlideText = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: column wrap;
	text-align: center;
	padding: 0 20%;
	color: ${palette.black};

	@media ${small} {
		padding: 0 10% 20px 10%;
	}
`;

export const SliderOuter = styled.div`
	display: grid;
	grid-template-rows: 400px 1fr;

	opacity: 1;
	width: ${styles.size.PageWidths.STANDARD}px;
	overflow: hidden;

	@media ${small} {
		width: 100vw;
		grid-template-rows: 1fr 1fr;
		height: 100%;
	}

	${SlideText} {
		h1,
		p {
			will-change: opacity;
			opacity: 0;
		}

		h1 {
			font-size: var(--font-size-2xl);
			font-weight: var(--font-weight-semibold);
		}
	}

	${(props) =>
		props.active &&
		css`
			opacity: 1;

			${SlideText} {
				h1 {
					opacity: 1;
					transition: all 200ms 350ms ease-in-out;
				}

				p {
					opacity: 1;
					transition: all 200ms 450ms ease-in-out;
				}
			}
		`};
`;

export const SlideImageWrapper = styled.div`
	display: grid;

	width: 100%;
	min-width: 0;
	position: relative;
	justify-content: center;
	align-items: center;

	@media ${small} {
		align-self: end;
	}

	> svg,
	> img {
		width: 100%;
	}
`;

SlideImageWrapper.defaultProps = {
	align: 'center',
	flex: 'false',
};

export default class Slide extends Component {
	render() {
		return (
			<SliderOuter active={this.props.active}>
				{this.props.children}
			</SliderOuter>
		);
	}
}
