import { ReactNode } from 'react';

export const symbol_repeat_on: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M100-40q-24 0-42-18t-18-42v-761q0-23 18-41.5t42-18.5h761q23 0 41.5 18.5T921-861v761q0 24-18.5 42T861-40H100Zm136-170h464q24.75 0 42.375-17.625T760-270v-130q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T700-400v130H236l65-65q9-9 8.5-21t-8.75-21.12q-9-9.12-21.375-9.5Q267-387 258-378L141-261q-5 5-7 10.133-2 5.134-2 11Q132-234 134-229q2 5 7 10l117 117q9.067 9 21.533 8.5Q292-94 301-103q8.25-9 8.625-21T301-145l-65-65Zm488-480-65 65q-9 9-8.5 21t8.75 21.12q9 9.12 21.375 9.5Q693-573 702-582l117-117q5-5 7-10.133 2-5.134 2-11Q828-726 826-731q-2-5-7-10L702-858q-9.067-9-21.533-8.5Q668-866 659-857q-8.25 9-8.625 21T659-815l65 65H260q-24.75 0-42.375 17.625T200-690v130q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T260-560v-130h464Z"
		/>
	</svg>
);
