import { ReactNode } from 'react';

export const symbol_speech_to_text: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M689.567-570Q661-570 640.5-590.5T620-640v-160q0-29 20.562-49.5Q661.125-870 690-870q29 0 49.5 20.5T760-800v160q0 29-20.5 49.5T689.567-570ZM180-80q-24.75 0-42.375-17.625T120-140v-680q0-24.75 17.625-42.375T180-880h310q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T490-820H180v680h480v-80q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T720-220v80q0 24.75-17.625 42.375T660-80H180Zm350-170H310q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T310-310h220q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T530-250Zm-80-120H310q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T310-430h140q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T450-370Zm240-120q54 0 94.5-33.5T836-607q3-14 12.103-23.5 9.104-9.5 22-9.5Q883-640 891-630q8 10 6 24-11 68-59.5 116T720-432v72q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T660-360v-72q-68-10-117-58t-60-116q-2-14 6-24t20.897-10q12.896 0 22 9.5Q541-621 544-607q11 50 51.5 83.5T690-490Z"
		/>
	</svg>
);
