import { ReactNode } from 'react';

export const symbol_wheelchair_pickup: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M260.08-734q-30.08 0-51.58-21.42-21.5-21.421-21.5-51.5 0-30.08 21.42-51.58 21.421-21.5 51.5-21.5 30.08 0 51.58 21.42 21.5 21.421 21.5 51.5 0 30.08-21.42 51.58-21.421 21.5-51.5 21.5ZM200-110v-250h-50q-12.75 0-21.375-8.625T120-390v-223q0-24.75 17.625-42.375T180-673h160q24.75 0 42.375 17.625T400-613v123q-51 33-83.5 90T284-276q0 40 11 73.5t25 56.5v66h-90q-12.75 0-21.375-8.625T200-110Zm340 30q-81.34 0-138.67-57Q344-194 344-276q0-60 37.5-115.5T490-465v66q-41 14-63.5 50T404-275.686q0 56.536 39.667 96.111Q483.333-140 540-140q60 0 100.5-47T670-300h60q13 85-45.5 152.5T540-80Zm297-111-94-139H580q-24.75 0-42.375-17.625T520-390v-210q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T580-600v210h165q14.925 0 28.358 7.125T795-363l92 139q7 11 5 23t-13 19q-11 7-23.098 4.6Q843.805-179.8 837-191Z"
		/>
	</svg>
);
