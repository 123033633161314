export function AddUserToGroups(userID: number): string {
	return `/v1/users/${userID}/groups`;
}

export function AddUsersToGroup(groupID: number): string {
	return `/v1/groups/${groupID}/users`;
}

export function BanUser(groupID: number, userID: number): string {
	return `/v1/groups/${groupID}/users/${userID}/ban`;
}

export function DemoteUser(groupID: number, userID: number): string {
	return `/v1/groups/${groupID}/users/${userID}/demote`;
}

export function GroupMemberships(groupID: number, userID: number): string {
	return `/v1/user-groups/${groupID}/${userID}`;
}

export function Index(): string {
	return `/v1/user-groups`;
}

export function Index1(userID: number): string {
	return `/v1/users/${userID}/groups`;
}

export function RemoveUser(groupID: number, userID: number): string {
	return `/v1/groups/${groupID}/users/${userID}`;
}

export function RemoveUsers(groupID: number): string {
	return `/v1/groups/${groupID}/users`;
}

export function Show(ID: number): string {
	return `/v1/user-groups/${ID}`;
}
