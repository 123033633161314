import { Fragment, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import UUID from 'pkg/uuid';

// 1px width/height to fix a bug in Safari
const Input = styled.input`
	opacity: 0;
	width: 1px;
	height: 1px;
`;

/**
 * @type {React.Element<any>}
 */

const FileInput = ({
	children,
	onChange,
	className,
	allowDrop = false,
	multiple = true,
	accept = 'image/*',
	inputRef,
}) => {
	const id = `file-input-${UUID()}`;

	const [willDrop, setWillDrop] = useState(false);

	const handleFileChange = (event) => {
		const files = event.target.files;

		if (files.length > 0) {
			onChange(files);
		}
	};

	const handleDrag = (event) => {
		event.preventDefault();
		event.stopPropagation();
	};

	const handleDragEnter = useCallback(
		(event) => {
			event.preventDefault();
			event.stopPropagation();

			const items = event.dataTransfer?.items;

			if (items && items.length > 0) {
				setWillDrop(true);
			}
		},
		[setWillDrop]
	);

	const handleDragLeave = useCallback(
		(event) => {
			event.preventDefault();
			event.stopPropagation();

			setWillDrop(false);
		},
		[setWillDrop]
	);

	const handleDrop = useCallback(
		(event) => {
			event.preventDefault();
			event.stopPropagation();

			const files = event.dataTransfer?.files;

			if (files && files.length > 0) {
				event.dataTransfer.clearData();

				onChange(files);
				setWillDrop(false);
			}
		},
		[setWillDrop, onChange]
	);

	const dropProps = allowDrop
		? {
				onDrop: handleDrop,
				onDragOver: handleDrag,
				onDragEnter: handleDragEnter,
				onDragLeave: handleDragLeave,
			}
		: {};

	return (
		<Fragment>
			<label
				htmlFor={id}
				data-will-drop={willDrop}
				className={className}
				{...dropProps}>
				{children}
			</label>

			<Input
				ref={inputRef}
				type="file"
				id={id}
				multiple={multiple}
				accept={accept}
				onChange={handleFileChange}
			/>
		</Fragment>
	);
};

FileInput.propTypes = {
	multiple: PropTypes.bool,
	accept: PropTypes.string,
	onChange: PropTypes.func,
};

FileInput.defaultProps = {
	multiple: false,
	accept: null,
	onChange: () => {},
};

export default FileInput;
