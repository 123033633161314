import { ReactNode } from 'react';

export const symbol_av_timer: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-120q-75 0-140.271-28.4-65.271-28.401-114.1-77.229-48.828-48.829-77.229-114.1Q120-405 120-480q0-75 30.5-142T235-738q11-10 25-13t24 7l228 228q9 9 9 21t-9 21q-9 9-21.5 9t-21.5-9L261-682q-39 42-60 93.5T180-480q0 125 87.5 212.5T480-180q125 0 212.5-87.5T780-480q0-118-79-204.5T504-779v70q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T444-709v-101q0-12.75 8.625-21.375T474-840q75.233 0 141.825 28.4 66.592 28.401 116.408 77.229 49.817 48.829 78.792 114.1Q840-555 840-480q0 75-28.4 140.271-28.401 65.271-77.229 114.1-48.829 48.828-114.1 77.229Q555-120 480-120ZM260.105-444Q244-444 233-454.895q-11-10.894-11-27Q222-498 232.895-509q10.894-11 27-11Q276-520 287-509.105q11 10.894 11 27Q298-466 287.105-455q-10.894 11-27 11Zm218 221Q462-223 451-233.895q-11-10.894-11-27Q440-277 450.895-288q10.894-11 27-11Q494-299 505-288.105q11 10.894 11 27Q516-245 505.105-234q-10.894 11-27 11Zm221-221Q683-444 672-454.895q-11-10.894-11-27Q661-498 671.895-509q10.894-11 27-11Q715-520 726-509.105q11 10.894 11 27Q737-466 726.105-455q-10.894 11-27 11Z"
		/>
	</svg>
);
