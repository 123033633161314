import { ReactNode } from 'react';

export const symbol_front_hand: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M523.175-920q12.825 0 21.325 8.625T553-890v370q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T493-520v-370q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625Zm-167 40q12.825 0 21.325 8.625T386-850v330q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T326-520v-330q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625ZM495.062-40q-139.938 0-237.5-97Q160-234 160-374v-396q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T220-770v396q0 114.869 79.797 194.435Q379.594-100 494.797-100T691-180.5Q772-261 780-376v-154h-10q-21.25 0-35.625 14.375T720-480v120q0 12.75-8.625 21.375T690-330h-80q-38 0-64 26t-26 64v30q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T460-210v-30q0-63 43.5-106.5T610-390h50v-420q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T720-810v232q11-6 24-9t26-3h40q12.75 0 21.375 8.625T840-560v184q-8 141-106.5 238.5T495.062-40ZM530-345Z"
		/>
	</svg>
);
