import * as styles from 'pkg/config/styles';

import Column from 'components/layout/column';

import * as css from './styles.css';

function Typography() {
	return (
		<Column spacing={styles.spacing._6}>
			<div className={css['3xs']}>
				This is a 3xs. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
				This is a paragraph. Lorem ipsum dolor sit amet, consectetur adipiscing
				elit.
			</div>
			<div className={css['2xs']}>
				This is a 2xs. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
				This is a paragraph. Lorem ipsum dolor sit amet, consectetur adipiscing
				elit.
			</div>
			<div className={css.xs}>
				This is an xs. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
				This is a paragraph. Lorem ipsum dolor sit amet, consectetur adipiscing
				elit.
			</div>
			<div className={css.sm}>
				This is an sm. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
				This is a paragraph. Lorem ipsum dolor sit amet, consectetur adipiscing
				elit.
			</div>
			<div className={css.base}>
				This is a base. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
				This is a paragraph. Lorem ipsum dolor sit amet, consectetur adipiscing
				elit.
			</div>
			<div className={css.lg}>
				This is an lg. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
				This is a paragraph. Lorem ipsum dolor sit amet, consectetur adipiscing
				elit.
			</div>
			<div className={css.xl}>
				This is an xl. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
				This is a paragraph. Lorem ipsum dolor sit amet, consectetur adipiscing
				elit.
			</div>
			<div className={css['2xl']}>
				This is a 2xl. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
				This is a paragraph. Lorem ipsum dolor sit amet, consectetur adipiscing
				elit.
			</div>
			<div className={css['3xl']}>
				This is a 3xl. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
				This is a paragraph. Lorem ipsum dolor sit amet, consectetur adipiscing
				elit.
			</div>
			<div className={css['4xl']}>
				This is a 4xl. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
				This is a paragraph. Lorem ipsum dolor sit amet, consectetur adipiscing
				elit.
			</div>
			<div className={css['5xl']}>
				This is a 5xl. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
				This is a paragraph. Lorem ipsum dolor sit amet, consectetur adipiscing
				elit.
			</div>
		</Column>
	);
}

export default Typography;
