import { Fragment, useContext } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as routes from 'pkg/router/routes';
import useConfirm from 'pkg/hooks/useConfirm';
import * as models from 'pkg/api/models';
import { useCurrentOrganization } from 'pkg/identity';

import { useCalendarContext } from 'routes/group/calendar';
import { useEventContext } from 'routes/event/single';

import SectionTitle from 'components/SectionTitle';
import IconBackground from 'components/IconBackground';
import Icon from 'components/icon';
import Label from 'components/label';
import CloseButton from 'components/close-button';

import { MaterialSymbolVariant } from 'components/material-symbols/symbols';
import InfoBox from 'components/form/info-box';
import Column from 'components/layout/column';
import Row from 'components/layout/row';

import * as ContextMenu from 'design/context_menu';
import Button from 'design/button';

import * as css from './styles.css';

interface HeadingProps {
	preview?: boolean;
	event: models.event.Event;
}

export default function Heading({ event, preview = false }: HeadingProps) {
	const ctx = useContext(ContextMenu.MenuContext);
	const calendarCtx = useCalendarContext();
	const eventCtx = useEventContext();
	const group = event?.group;
	const org = useCurrentOrganization();

	const handleDelete = async () => {
		const userConsent = window.confirm(
			t('Do you want to delete this?', { _context: 'confirm messages' })
		);

		if (userConsent) {
			const req = await models.destroy(event);
			if (req) {
				calendarCtx?.eventsCollection?.removeRecord(event.id);
				eventCtx?.setIsDeleted?.(true);
			}
		}
	};

	const handleCancel = useConfirm({
		message: t('Do you want to cancel this event?'),
		onConfirm: async () => {
			const [req] = await models.update(event, {
				status: models.event.EventStatus.Canceled,
			});

			if (req.ok) {
				if (eventCtx) {
					eventCtx.eventEndpointResponse.refresh();
				}
				if (calendarCtx.eventsCollection) {
					calendarCtx.eventsCollection.replaceRecord({
						...event,
						status: models.event.EventStatus.Canceled,
					});
				}
			}
		},
	});

	const handleCloseContext = () => ctx.close();

	const handleRepublish = useConfirm({
		message: t('Republish the event and notify all participants?'),
		onConfirm: async () => {
			const [req] = await models.update(event, {
				status: models.event.EventStatus.Published,
			});

			if (req.ok) {
				if (eventCtx) {
					eventCtx.eventEndpointResponse.refresh();
				}
				if (calendarCtx.eventsCollection) {
					calendarCtx.eventsCollection.replaceRecord({
						...event,
						status: models.event.EventStatus.Published,
					});
				}
			}
		},
	});

	const isCanceled = models.event.isCanceled(event);
	const canEdit = models.event.canEdit(event);
	const canDelete = models.event.canDelete(event);
	const hasSeries = models.event.hasEventSeries(event);

	const eventActions = (
		<Row align="center">
			{(canEdit && !preview) || (canDelete && !preview) ? (
				<ContextMenu.Menu
					toggleWith={
						<Button secondary>
							<Icon name="context-menu" size={1.3} />
						</Button>
					}>
					{canEdit && !isCanceled && (
						<Fragment>
							<ContextMenu.LinkItem href={routes.Event.Edit(org.id, event.id)}>
								<ContextMenu.ItemIcon name="edit" />
								{t('Edit')}
							</ContextMenu.LinkItem>
							{hasSeries && (
								<ContextMenu.LinkItem
									href={routes.EventSeries.Edit(org.id, event.eventSeriesId)}>
									<ContextMenu.ItemIcon name="edit" />
									{t('Edit series')}
								</ContextMenu.LinkItem>
							)}
							<ContextMenu.Item
								onClick={handleCancel}
								testid="event.single.cancel_event">
								<ContextMenu.ItemIcon name="cancel" />
								{t('Cancel event')}
							</ContextMenu.Item>
						</Fragment>
					)}
					{isCanceled && (
						<ContextMenu.Item onClick={handleRepublish}>
							<ContextMenu.ItemIcon name="redo" />
							{t('Republish event')}
						</ContextMenu.Item>
					)}
					{canDelete && (
						<Fragment>
							<ContextMenu.Divider />
							<ContextMenu.Item
								onClick={handleDelete}
								caution
								testid="event.single.context.delete">
								<ContextMenu.ItemIcon name="delete" />
								{t('Delete')}
							</ContextMenu.Item>
						</Fragment>
					)}
				</ContextMenu.Menu>
			) : null}

			{(canEdit && preview) || (canDelete && preview) ? (
				<Row
					spacing={styles.spacing._3}
					autoColumns="min-content"
					align="center">
					{canEdit && (
						<ContextMenu.Menu
							toggleWith={
								<IconBackground>
									<Icon name="context-menu" size={1.3} />
								</IconBackground>
							}>
							<ContextMenu.LinkItem href={routes.Event.Edit(org.id, event.id)}>
								<ContextMenu.ItemIcon name="edit" />
								{t('Edit')}
							</ContextMenu.LinkItem>

							{hasSeries && (
								<Fragment>
									<ContextMenu.LinkItem
										href={routes.EventSeries.Edit(org.id, event.eventSeriesId)}>
										<ContextMenu.ItemIcon name="edit" />
										{t('Edit series')}
									</ContextMenu.LinkItem>
								</Fragment>
							)}
							{!isCanceled && (
								<Fragment>
									<ContextMenu.Item onClick={handleCancel}>
										<ContextMenu.ItemIcon name="cancel" />
										{t('Cancel event')}
									</ContextMenu.Item>
								</Fragment>
							)}
							{isCanceled && (
								<Fragment>
									<ContextMenu.Item onClick={handleRepublish}>
										<ContextMenu.ItemIcon name="redo" />
										{t('Republish event')}
									</ContextMenu.Item>
								</Fragment>
							)}
							{canDelete && (
								<Fragment>
									<ContextMenu.Divider />
									<ContextMenu.Item caution onClick={handleDelete}>
										<ContextMenu.ItemIcon name="delete" />
										{t('Delete')}
									</ContextMenu.Item>
								</Fragment>
							)}
						</ContextMenu.Menu>
					)}
				</Row>
			) : null}

			{preview ? <CloseButton onClick={handleCloseContext} /> : null}
		</Row>
	);

	const icons: MaterialSymbolVariant[] = [];

	if (hasSeries) {
		icons.push('sync');
	}

	if (event.isPrivate) {
		icons.push('visibility_off');
	}

	return (
		<Fragment>
			<Column spacing={styles.spacing._4}>
				<div className={css.eventInfo}>
					<Row spacing={styles.spacing._2} autoColumns="auto">
						<Label eventType={event.type} data-testid="event.single.title">
							{models.event.getTypeTranslation(event.type)}
						</Label>
						{group?.name && (
							<div className={css.groupNameLabelWrapper}>
								<Label color="gray" className={css.groupNameLabel}>
									{group?.name}
								</Label>
							</div>
						)}
					</Row>
					{preview && eventActions}
				</div>
				<SectionTitle icon={icons} large={!preview} medium={preview}>
					{!isCanceled && event.title}
					{isCanceled && (
						<div className={css.strikeThrough}> {event.title}</div>
					)}
					{!preview && eventActions}
				</SectionTitle>
				{isCanceled && (
					<InfoBox color="yellow">{t('This event has been canceled')} </InfoBox>
				)}
			</Column>
		</Fragment>
	);
}
