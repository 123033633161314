import { Record } from 'pkg/api/models/record';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { Linkable } from 'pkg/api/models/linkable';
import * as sdk from 'pkg/core/sdk';

import { IconName } from 'components/icon';

export interface ResourceCategory extends Record, Linkable {
	groupId: number;
	title: string;
	icon?: IconName;
}

export interface ResourceCategoryCreatePayload {
	title: string;
	groupId: number;
	icon?: IconName;
}

export async function create(
	payload: ResourceCategoryCreatePayload
): Promise<[Response, ResourceCategory, models.APIError?]> {
	if (!payload.title) {
		return Promise.resolve([new Response(null, { status: 400 }), null]);
	}

	return models.create(endpoints.ResourceCategory.Create(), payload);
}

export interface ResourceCategoryUpdatePayload {
	title?: string;
	icon?: IconName | '';
}

export async function update(
	category: ResourceCategory,
	payload: ResourceCategoryUpdatePayload
): Promise<[Response, ResourceCategory]> {
	if (Object.keys(payload).length === 0) {
		return Promise.resolve([new Response(null, { status: 400 }), null]);
	}

	const request = await sdk.patch(
		endpoints.ResourceCategory.Update(category.id),
		{},
		payload
	);

	if (!request.ok) {
		return [request, null];
	}

	const response: ResourceCategory = await request.json();

	return [request, response];
}

export async function remove(category: ResourceCategory): Promise<boolean> {
	const request = await sdk.destroy(
		endpoints.ResourceCategory.Delete(category.id)
	);

	if (request.ok) {
		return true;
	}

	return false;
}
