import { ReactNode } from 'react';

export const symbol_high_quality: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M595-357v40q0 10.833 7.116 17.917 7.117 7.083 18 7.083Q631-292 638-299.083q7-7.084 7-17.917v-40h41q14.45 0 24.225-9.487Q720-375.975 720-390v-179q0-14.45-9.775-24.225Q700.45-603 686-603H560q-14 0-27 9.775T520-569v179q0 14.025 13 23.513Q546-357 560-357h35Zm-305-89h100v64q0 10.833 7.116 17.917 7.117 7.083 18 7.083Q426-357 433-364.083q7-7.084 7-17.917v-196q0-10.833-7.116-17.917-7.117-7.083-18-7.083Q404-603 397-595.917q-7 7.084-7 17.917v82H290v-82q0-10.833-7.116-17.917-7.117-7.083-18-7.083Q254-603 247-595.917q-7 7.084-7 17.917v196q0 10.833 7.116 17.917 7.117 7.083 18 7.083Q276-357 283-364.083q7-7.084 7-17.917v-64Zm280 39v-146h100v146H570ZM140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm0-60h680v-520H140v520Zm0 0v-520 520Z"
		/>
	</svg>
);
