import styled from 'styled-components';

import FormRow from 'components/form/Row';

export default styled(FormRow)`
	display: grid;
	grid-auto-flow: column;
	align-items: start;
	justify-content: start;
	grid-column-gap: 10px;
	width: 100%;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
`;
