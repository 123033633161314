import { ReactNode } from 'react';

export const symbol_home_iot_device: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M211-220q47 0 78.5-31.5T321-330q0-26.057-12-51.029Q297-406 273-422l-32-21v-267q0-13.5-8.25-21.75T211-740q-13.5 0-21.75 8.25T181-710v267l-32 21q-24 16.525-36 40.763Q101-357 101-329.6q0 46.032 31.9 77.816Q164.8-220 211-220Zm0 60q-72 0-121-49.035T41-329.737Q41-375 63-412.24T121-473v-237q0-37.8 26.141-63.9t64-26.1Q249-800 275-773.9t26 63.9v237q36 23.52 58 60.76 22 37.24 22 82.503 0 70.724-49.583 120.23Q281.833-160 211-160Zm340-200q-45-32-78-83.5T440-560q0-99 70.5-169.5T680-800q101 0 170.5 70T920-560q0 65-33 116.5T809-360H551Zm21-60h216q30-23 51-62.5t21-77.5q0-75-52-127.5T680-740q-74.25 0-127.125 52.875T500-560q0 38 21 77.5t51 62.5Zm108 260q-18 0-31.5-11.5T631-200h98q-4 17-17.5 28.5T680-160Zm-80-80q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T600-300h160q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T760-240H600Zm-389-90Zm469-250Z"
		/>
	</svg>
);
