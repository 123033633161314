import { useMediaQuery } from 'react-responsive';

import { toMedium } from 'pkg/config/breakpoints';

import { VideoSequenceRecordingId } from 'pkg/video';
import { useQueryState } from 'pkg/hooks/query-state';

import { usePlayerState } from 'components/video-analytics/PlayerState';
import { useClipState } from 'components/video-analytics/ClipState';
import { usePlaybackState } from 'components/video-analytics/PlaybackState';
import { useCueState } from 'components/video-analytics/CueState';

interface ClipRecordingHook {
	start: () => Promise<void>;
	stop: () => Promise<void>;
	cancel: () => Promise<void>;
}

export default function useClipRecording(): ClipRecordingHook {
	const cueState = useCueState();
	const clipState = useClipState();
	const playerState = usePlayerState();
	const { currentTime } = usePlaybackState();
	const queryState = useQueryState();

	const isSmallScreen = useMediaQuery({
		maxWidth: toMedium,
	});

	const start = async () => {
		await clipState.reset({
			id: VideoSequenceRecordingId,
			startsAt: currentTime,
			endsAt: currentTime + 0.1,
		});

		queryState.flush();
		cueState.setCueId(VideoSequenceRecordingId);
		playerState.setRecording(true);
		playerState.controller.play();

		if (!isSmallScreen) {
			playerState.setEditing(true);
		}
	};

	const stop = async () => {
		playerState.setJustRecorded(true);
		playerState.setEditing(true);

		playerState.controller.pause();

		if (!isSmallScreen) {
			playerState.setTrimming(true);
		}
	};

	const cancel = async () => {
		playerState.setJustRecorded(false);
		playerState.setRecording(false);
		playerState.setTrimming(false);
		playerState.setEditing(false);
	};

	return {
		start,
		stop,
		cancel,
	};
}
