import { ReactNode } from 'react';

export const symbol_settings_input_hdmi: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M380-80q-24.75 0-42.375-17.625T320-140v-60L206-426q-3-6-4.5-13.125T200-453v-167q0-18 11.5-33.5T240-676v-144q0-23 18.5-41.5T300-880h360q23 0 41.5 18.5T720-820v144q17 7 28.5 22.5T760-620v167q0 6.75-1.5 13.875T754-426L640-200v60q0 24.75-17.625 42.375T580-80H380Zm-80-600h98v-65q0-6.8 5.143-11.9 5.143-5.1 12-5.1T427-756.9q5 5.1 5 11.9v65h96v-65q0-6.8 5.143-11.9 5.143-5.1 12-5.1T557-756.9q5 5.1 5 11.9v65h98v-140H300v140Zm80 540h200v-75l120-241v-164H260v164l120 241v75Zm100-316Z"
		/>
	</svg>
);
