import { t } from '@transifex/native';
import { useRef, useState } from 'react';

import * as flashActions from 'pkg/actions/flashes';

import * as models from 'pkg/api/models';
import { ResourceCreatePayload } from 'pkg/api/models/resource';
import useMixedState from 'pkg/hooks/useMixedState';
import { crash } from 'pkg/errors/errors';

import ResourceForm from 'routes/scheduling/resources/forms/ResourceForm';
import CreateCategory from 'routes/scheduling/categories/modals/Create';
import CreateLocationModal from 'routes/scheduling/locations/modals/Create';

import * as StepModal from 'components/step-modal';

import Form, { asNumber, asString, FormPayload } from 'components/form/Form';
import useSelectResource, {
	SelectableOptions,
} from 'components/resources/select_modal';

type Modals = '' | 'category' | 'location' | 'selectResource';

interface CreateResourceModalProps {
	groupId: number;
	resourceId?: number;
	categoryId?: number;
	locationId?: number;

	resources: models.resource.Resource[];

	refresh: () => Promise<void>;
	refreshCategories: () => Promise<void>;
	hideModal: () => void;
}

const CreateResourceModal = ({
	groupId,
	resources,
	resourceId,
	categoryId = 0,
	locationId = 0,
	refresh,
	refreshCategories,
	hideModal,
}: CreateResourceModalProps) => {
	const [resource, setResource] = useMixedState({
		title: '',
		description: '',
		resourceLocationId: locationId,
		resourceCategoryId: categoryId,
		parentResourceId: resourceId,
	});
	const [modal, setModal] = useState<Modals>('');
	const formRef = useRef(null);

	const hideInnerModal = () => setModal('');
	const showCategoryModal = () => setModal('category');
	const showLocationModal = () => setModal('location');
	const showResourceModal = () => setModal('selectResource');

	const SelectResource = useSelectResource({
		groupId,
		selectable: SelectableOptions.single,
		hideModal: hideInnerModal,
		resources,
		preSelectedResources: resourceId ? [resourceId] : [],
	});

	const handleSubmit = async (data: FormPayload) => {
		const payload: ResourceCreatePayload = {
			groupId,
			title: asString(data.title),
			description: asString(data.description),
		};

		if (data.category !== '') {
			payload.resourceCategoryId = asNumber(data.category);
		}

		if (data.location !== '') {
			payload.resourceLocationId = asNumber(data.location);
		}

		if (!!SelectResource.selectedResources[0]) {
			payload.parentResourceId = SelectResource.selectedResources[0];
		}

		const [req] = await models.resource.create(payload);

		if (req.ok) {
			flashActions.show({
				title: t(`Successfully created resource`),
			});
			hideModal();
			refresh();
			refreshCategories();
		} else {
			const err = crash();
			flashActions.show({
				title: err.title,
				message: err.description,
			});
		}
	};

	const handleNext = async () =>
		await formRef.current.dispatchEvent(
			new Event('submit', {
				cancelable: true,
				bubbles: true,
			})
		);

	const handleCreateLocation = async (
		location: models.resourceLocation.ResourceLocation
	) => {
		setResource({
			resourceLocationId: location.id,
		});
		hideInnerModal();
	};

	const handleCreateCategory = (
		category: models.resourceCategory.ResourceCategory
	) => {
		setResource({
			resourceCategoryId: category.id,
		});
		hideInnerModal();
	};

	return (
		<StepModal.Base onClose={hideModal}>
			<StepModal.Step
				title={t(`Create resource`)}
				nextLabel={t('Save')}
				onNext={handleNext}>
				<Form formRef={formRef} onSubmit={handleSubmit}>
					<ResourceForm
						groupId={groupId}
						resource={resource}
						showCategoryModal={showCategoryModal}
						showLocationModal={showLocationModal}
						selectedResources={SelectResource.selectedResources}
						selectResource={SelectResource.select}
						resources={resources}
						showSelectResourceModal={showResourceModal}
					/>
				</Form>
				{modal === 'category' && (
					<CreateCategory
						groupId={groupId}
						hideModal={hideInnerModal}
						onCreate={handleCreateCategory}
					/>
				)}
				{modal === 'location' && (
					<CreateLocationModal
						groupId={groupId}
						onClose={hideInnerModal}
						onCreate={handleCreateLocation}
					/>
				)}
				{modal === 'selectResource' && SelectResource.Modal}
			</StepModal.Step>
		</StepModal.Base>
	);
};

export default CreateResourceModal;
