import { ReactNode } from 'react';

export const symbol_pinch: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M590-40q-21.882 0-41.941-8T512-72L322-262q-8-8-9.5-20t6.5-20q14-14 30.5-21.5T384-326l96 26v-340q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T540-640v381q0 15-12 24t-26 5l-86-23 139.178 139.178Q562-107 571.13-103.5q9.131 3.5 18.87 3.5h170q42 0 71-29t29-71v-180q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T920-380v180q0 66-47 113T760-40H590Zm108-185ZM400-852 108-560h82q8 0 14 6t6 14q0 8-6 14t-14 6H70q-12.75 0-21.375-8.625T40-550v-120q0-8 6-14t14-6q8 0 14 6t6 14v82l292-292h-82q-8 0-14-6t-6-14q0-8 6-14t14-6h120q12.75 0 21.375 8.625T440-890v120q0 8-6 14t-14 6q-8 0-14-6t-6-14v-82Zm237.175 322q12.825 0 21.325 8.625T667-500v140q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T607-360v-140q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625Zm126 40q12.825 0 21.325 8.625T793-460v100q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T733-360v-100q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625Z"
		/>
	</svg>
);
