import { ReactNode } from 'react';

export const symbol_play_lesson: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-79q-24 0-42-18t-18-42v-681q0-24 18-42t42-18h520q24 0 42 18t18 42v305q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T700-515v-305H462v251q0 8.609-7.5 13.304Q447-551 439-556l-73-45-73 45q-8 5-15.5.304Q270-560.391 270-569v-251h-90v681h267q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q477-96 468.375-87.5T447-79H180Zm548 39q-81 0-136.5-55.5T536-232q0-81 55.5-136.5T728-424q81 0 136.5 55.5T920-232q0 81-55.5 136.5T728-40Zm-26-115 100-64q7-4.5 7-12.75T802-245l-100-64q-8-5-15.5-.935Q679-305.87 679-297v130q0 8.87 7.5 12.935Q694-150 702-155ZM270-820h192-192Zm-90 681v-681 264.696V-601v45.696V-820v681Z"
		/>
	</svg>
);
