import { Children, cloneElement, memo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Icon from 'components/icon';

import * as iconStyles from 'components/icon/styles.css';

import * as ContextMenu from 'design/context_menu';

import FieldStyles from './FieldStyles';

const Trigger = styled.div`
	${FieldStyles}

	display: grid;
	grid-auto-flow: column;
	gap: 1rem;
	justify-content: start;
	align-items: center;
	grid-template-columns: 1fr auto;
	gap: 5px;

	.${iconStyles.icon} {
		font-size: 22px;
	}
`;

export const OptionImage = styled.div``;

export const Option = styled.div`
	display: grid;
	grid-auto-flow: column;
	gap: 1rem;
	justify-content: start;
	align-items: center;

	${OptionImage} {
		width: 22px;
		height: 22px;
	}
`;

Option.propTypes = {
	value: PropTypes.any,
};

// DecoratedSelect is a "select" with images and other decorations. It opens a ContextMenu over a normal form element.
export const Select = memo(({ children, value, onChange }) => {
	let activeChild = null;

	Children.forEach(children, (c) => {
		if (c.type === Option && c.props.value === value) {
			activeChild = cloneElement(c);
		}
	});

	return (
		<ContextMenu.Menu
			appearFrom={ContextMenu.AppearFrom.TopLeft}
			offsetHorizontal={0}
			offsetVertical={45}
			toggleWith={
				<Trigger>
					{activeChild} <Icon name="chevron" rotate="90deg" />
				</Trigger>
			}>
			{Children.map(children, (c) => {
				if (c.type !== Option) {
					return c;
				}

				const handleClick = () => {
					onChange(c.props.value);
				};

				return (
					<ContextMenu.Item onClick={handleClick}>
						{cloneElement(c, {
							selected: value === c.props.value,
						})}
					</ContextMenu.Item>
				);
			})}
		</ContextMenu.Menu>
	);
});
