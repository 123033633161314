import {
	useState,
	Fragment,
	useMemo,
	createContext,
	useContext,
	useRef,
	ReactNode,
	MutableRefObject,
} from 'react';
import DOM from 'react-dom';
import styled, { ThemeProvider, useTheme } from 'styled-components';

import * as breakpoints from 'pkg/config/breakpoints';

import uuid from 'pkg/uuid';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { useNewTopIndex } from 'pkg/hooks/useTopIndex';
import { cssClasses } from 'pkg/css/utils';

import DefaultBackdrop from 'components/Backdrop';
import CardBase, * as Card from 'components/Card';
import Icon from 'components/icon';

import * as css from './styles.css';

/**
 * Keep as styled component to handle the card styling overrides
 */
const ModalBase = styled.div`
	${Card.Heading} {
		color: var(--palette-gray-800);
		font-size: var(--font-size-lg);
		font-weight: var(--font-weight-semibold);
		text-align: left;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		vertical-align: top;
	}

	@media all and (min-width: ${breakpoints.fromSmall}px) {
		${Card.Body} {
			padding: 28px var(--spacing-8) var(--spacing-8) var(--spacing-8);
		}
	}

	> ${CardBase} {
		padding: 0;
		margin: 0;
		display: flex;
		flex-flow: column;
		overflow: hidden;
		max-height: 100%;
	}

	${Card.Body} {
		overflow: auto;
		-webkit-overflow-scrolling: touch;
	}

	@media ${breakpoints.small} {
		${Card.Heading}:last-child:first-child {
			text-align: center;
			margin: 0;
			width: 100%;
		}

		${Card.Footer} {
			padding: var(--spacing-5);
			border-radius: 0;
		}

		> ${CardBase} {
			height: 100%;
			width: 100%;
			max-height: 100%;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
	}
`;

interface ModalContext {
	inModal: boolean;
	activeModalId: string;
	addModal?: (id: string) => void;
	removeModal?: (id: string) => void;
}

const modalsContext = createContext<ModalContext>({
	inModal: false,
	activeModalId: '',
});

export function useModalContext() {
	return useContext(modalsContext);
}

interface ModalContextProps {
	modalId: string;
	children: ReactNode;
}

function ModalContextProvider({ modalId, children }: ModalContextProps) {
	const modals = useRef<Set<string>>(new Set<string>().add(modalId));
	const [activeId, setActiveId] = useState(modalId);
	const addModal = (id: string) => {
		modals.current = modals.current.add(id);
		setActiveId(id);
	};
	const removeModal = (id: string) => {
		modals.current.delete(id);
		setActiveId([...modals.current].pop());
	};

	const values = useMemo(
		() => ({
			activeModalId: activeId,
			inModal: activeId !== '',
			addModal,
			removeModal,
		}),
		[activeId]
	);

	return (
		<modalsContext.Provider value={values}>{children}</modalsContext.Provider>
	);
}

const ModalContextConsumer = ({ modalId, children }: ModalContextProps) => {
	const ctx = useContext(modalsContext);

	useComponentDidMount(
		() => {
			ctx.addModal(modalId);
		},
		() => {
			ctx.removeModal(modalId);
		}
	);

	return children;
};

interface InnerProps {
	thin: boolean;
	wide: boolean;
	centered: boolean;
	fullScreen: boolean;
	hideClose: boolean;
	handleAnimationEnd: () => void;
	animationDirection: 'in' | 'out';
	close: () => void;
	children: ReactNode;
	modalId: string;
	currentIndex: number;
}

function Inner({
	thin,
	wide,
	centered,
	fullScreen,
	hideClose,
	handleAnimationEnd,
	animationDirection,
	close,
	children,
	modalId,
	currentIndex,
}: InnerProps) {
	const ctx = useContext(modalsContext);
	const theme = useTheme();

	return (
		<ThemeProvider
			theme={{
				...theme,
				animationDirection,
			}}>
			<div
				className={cssClasses(
					css.modalWrapper,
					ctx.activeModalId !== modalId && css.isPassive
				)}
				role="dialog"
				id="modal-wrapper">
				<ModalBase
					className={cssClasses(
						css.modalBase,
						wide && css.wide,
						thin && css.thin,
						centered && css.centered,
						fullScreen && css.fullScreen,
						animationDirection === 'in' ? css.animatingIn : css.animatingOut
					)}
					style={{ zIndex: currentIndex + 1 }}
					onAnimationEnd={handleAnimationEnd}>
					{!hideClose && (
						<span
							className={css.closeButton}
							onClick={close}
							data-testid="modal.close">
							<Icon name="close" />
						</span>
					)}
					{children}
				</ModalBase>
			</div>
		</ThemeProvider>
	);
}

interface ModalProps {
	onClose?: () => void;
	customBackdrop?: ReactNode;
	children?: ReactNode;
	thin?: boolean;
	wide?: boolean;
	fullScreen?: boolean;
	hideClose?: boolean;
	centered?: boolean;
	forwardedRef?: MutableRefObject<unknown>;
}

function Modal({
	onClose,
	customBackdrop,
	children,
	thin,
	wide,
	fullScreen,
	hideClose,
	centered,
	forwardedRef,
}: ModalProps) {
	const [animationDirection, setAnimationDirection] = useState<'in' | 'out'>(
		'in'
	);
	const ctx = useContext(modalsContext);
	const isFirstModal = ctx.activeModalId === '';
	const [modalId] = useState(() => {
		const id = uuid();
		return id;
	});

	const currentIndex = useNewTopIndex();

	const close = () => {
		setAnimationDirection('out');
	};

	if (forwardedRef) {
		forwardedRef.current = { close };
	}

	const handleAnimationEnd = () => {
		if (animationDirection === 'out') {
			onClose();
		}
	};

	useComponentDidMount(
		() => {
			document.body.classList.add('modal-open');
		},
		() => {
			if (isFirstModal) {
				document.body.classList.remove('modal-open');
			}
		}
	);

	const backdrop = customBackdrop || (
		<DefaultBackdrop
			animationDirection={animationDirection}
			index={currentIndex}
		/>
	);

	const content = (
		<Inner
			handleAnimationEnd={handleAnimationEnd}
			thin={thin}
			wide={wide}
			centered={centered}
			fullScreen={fullScreen}
			hideClose={hideClose}
			animationDirection={animationDirection}
			close={close}
			modalId={modalId}
			currentIndex={currentIndex}>
			{children}
		</Inner>
	);

	return DOM.createPortal(
		<Fragment>
			{isFirstModal && backdrop}
			{isFirstModal ? (
				<ModalContextProvider modalId={modalId}>
					<ModalContextConsumer modalId={modalId}>
						{content}
					</ModalContextConsumer>
				</ModalContextProvider>
			) : (
				<ModalContextConsumer modalId={modalId}>{content}</ModalContextConsumer>
			)}
		</Fragment>,
		document.getElementById('modal-portal-container')
	);
}

export default Modal;
