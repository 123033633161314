import { ReactNode } from 'react';

export const symbol_group_work: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M387-320q31 0 52-21.42 21-21.421 21-51.5 0-30.08-21-51.58T387.5-466q-30.5 0-52 21.42-21.5 21.421-21.5 51.5 0 30.08 21.444 51.58T387-320Zm185.92 0q30.08 0 51.58-21.42 21.5-21.421 21.5-51.5 0-30.08-21.42-51.58-21.421-21.5-51.5-21.5-30.08 0-51.58 21.42-21.5 21.421-21.5 51.5 0 30.08 21.42 51.58 21.421 21.5 51.5 21.5Zm-93-187q30.08 0 51.58-21t21.5-51.5q0-30.5-21.42-52-21.421-21.5-51.5-21.5-30.08 0-51.58 21.444T407-580q0 31 21.42 52 21.421 21 51.5 21Zm.346 427q-82.734 0-155.5-31.5t-127.266-86q-54.5-54.5-86-127.341Q80-397.681 80-480.5q0-82.819 31.5-155.659Q143-709 197.5-763t127.341-85.5Q397.681-880 480.5-880q82.819 0 155.659 31.5Q709-817 763-763t85.5 127Q880-563 880-480.266q0 82.734-31.5 155.5T763-197.684q-54 54.316-127 86Q563-80 480.266-80Zm.234-60Q622-140 721-239.5t99-241Q820-622 721.188-721 622.375-820 480-820q-141 0-240.5 98.812Q140-622.375 140-480q0 141 99.5 240.5t241 99.5Zm-.5-340Z"
		/>
	</svg>
);
