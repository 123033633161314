import { ReactNode } from 'react';

export const symbol_ramen_dining: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M316-158q-94-37-165-105.5T80-430q0-13 8.5-21.5T110-460h50v-313q0-11 7.5-19.5T186-802l666-74q11-2 19.5 5.5T880-852q0 10-6.5 17t-16.5 8l-447 49v87h445q11 0 18 7t7 18q0 11-7 18t-18 7H410v181h440q13 0 21.5 8.5T880-430q0 98-71.5 166T643-158v48q0 13-8.5 21.5T613-80H346q-13 0-21.5-8.5T316-110v-48Zm-6-533h50v-81l-50 5v76Zm-100 0h50v-70l-50 5v65Zm100 231h50v-181h-50v181Zm-100 0h50v-181h-50v181Zm166 320h207v-56l36-15q65-27 116-75t73-114H152q22 66 72.5 114T340-211l36 15v56Zm104-260Z"
		/>
	</svg>
);
