import React, { Fragment, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { Outer, Inner } from 'components/physical-strain/Fill';

const Bar = styled(Outer)<{ barColor: string }>`
	height: 12px;

	${Inner} {
		background-color: ${(props) => props.barColor};
	}
`;

const Text = styled.div<{
	left: string;
	childWidth: number;
}>`
	white-space: nowrap;

	margin-left: ${(props) => props.left};

	&:first-child {
		margin-bottom: var(--spacing-2);

		span {
			color: var(--palette-black);
			font-weight: var(--font-weight-semibold);
		}
	}

	&:last-child {
		margin-top: var(--spacing-2);
	}

	span {
		opacity: ${(props) => (props.childWidth ? '1' : '0')};
	}
`;

export const EmptyIndicator = styled.div`
	width: 2px;
	height: 20px;
	margin-top: -6px;
	background-color: var(--palette-blue-400);
`;

type IndicatorProps = {
	strainLevel: number;
	text: string;
	positionBelow?: boolean;
	barColor?: string;
};

const Indicator: React.FC<React.PropsWithChildren<IndicatorProps>> = ({
	strainLevel,
	text,
	positionBelow = false,
	barColor = '',
}) => {
	const innerTextRef = useRef(null);

	const [innerTextWidth, setInnerTextWidth] = useState(0);

	useEffect(() => {
		if (innerTextRef.current)
			setInnerTextWidth(innerTextRef.current.offsetWidth);
	}, [innerTextRef]);

	const getTextLeftPos = (): string => {
		if (strainLevel < 4) {
			return '0px';
		}

		if (strainLevel > 90) {
			return `calc(${strainLevel}% - ${innerTextWidth}px - 2.5px)`;
		}

		return `calc(${strainLevel}% - ${innerTextWidth}px / 2 - 2.5px)`;
	};

	const IndicatorText: JSX.Element = (
		<Text left={getTextLeftPos()} childWidth={innerTextWidth}>
			<span ref={innerTextRef}>{text}</span>
		</Text>
	);

	return (
		<Fragment>
			{!positionBelow && IndicatorText}

			<Bar barColor={barColor}>
				<Inner percentage={strainLevel} left />
				<Inner percentage={100 - strainLevel} />
			</Bar>

			{positionBelow && IndicatorText}
		</Fragment>
	);
};

export default Indicator;
