export default {
	name: 'statistics',
	objects: [
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M5.17901 15.0345V18.3139H6.80621V15.0345H5.17901ZM4.89483 13.803C4.37167 13.803 3.94757 14.2271 3.94757 14.7503V18.5981C3.94757 19.1213 4.37167 19.5454 4.89483 19.5454H7.09038C7.61354 19.5454 8.03764 19.1213 8.03764 18.5981V14.7503C8.03764 14.2271 7.61354 13.803 7.09038 13.803H4.89483Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M17.1938 6.01781V18.314H18.821V6.01781H17.1938ZM16.9096 4.78638C16.3864 4.78638 15.9623 5.21048 15.9623 5.73364V18.5981C15.9623 19.1213 16.3864 19.5454 16.9096 19.5454H19.1051C19.6283 19.5454 20.0524 19.1213 20.0524 18.5981V5.73364C20.0524 5.21048 19.6283 4.78638 19.1051 4.78638H16.9096Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M11.1864 9.58807V18.314H12.8136V9.58807H11.1864ZM10.9022 8.35663C10.3791 8.35663 9.95495 8.78074 9.95495 9.30389V18.5982C9.95495 19.1213 10.3791 19.5454 10.9022 19.5454H13.0978C13.6209 19.5454 14.045 19.1213 14.045 18.5982V9.30389C14.045 8.78074 13.6209 8.35663 13.0978 8.35663H10.9022Z',
			},
		},
	],
};
