import { HTMLProps, ReactNode } from 'react';

import * as styles from 'pkg/config/styles';

import { cssClasses, cssVarList } from 'pkg/css/utils';

import { JustifyContentValues } from 'styles/types';

import * as css from './styles.css';

export interface RowProps extends HTMLProps<HTMLDivElement> {
	spacing?: styles.spacing | '0px';
	align?: 'start' | 'center' | 'end' | 'stretch';
	justify?: 'normal' | 'start' | 'center' | 'end';
	autoColumns?: string;
	justifyContent?: JustifyContentValues;
	// Sets grid-template-columns, takes precedence over autoColumn
	columns?: string;
	collapseOnSmallScreens?: boolean;
	collapsedSpacing?: styles.spacing;
	children: ReactNode;
	className?: string;
}

// Default wrapper for a row of items with spacing between each item.

// Example layout:
// [ item ]  [ item ]  [ item ]
const Row = ({
	children,
	spacing = styles.spacing._4,
	align = 'start',
	justify = 'normal',
	justifyContent = 'normal',
	autoColumns,
	columns = 'none',
	collapseOnSmallScreens,
	collapsedSpacing,
	className,
	...props
}: RowProps) => {
	const v: { [key: string]: string | number } = {
		spacing,
		align,
		justify,
		columns,
		['justify-content']: justifyContent,
		['auto-columns']: autoColumns,
		['collapsed-spacing']: collapsedSpacing,
	};

	return (
		<div
			{...props}
			style={{ ...props.style, ...cssVarList(v) }}
			className={cssClasses(
				css.row,
				className,
				collapseOnSmallScreens ? css['collapse-on-small-screens'] : ''
			)}>
			{children}
		</div>
	);
};

export default Row;
