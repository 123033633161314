import { useT } from '@transifex/react';
import { Fragment } from 'react/jsx-runtime';

import * as styles from 'pkg/config/styles';

import { useEndpoint } from 'pkg/api/use_endpoint';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';

import Section from 'components/section';
import Avatar from 'components/avatar';
import * as Card from 'components/Card';

import Row from 'components/layout/row';
import Column from 'components/layout/column';

import Button from 'design/button';

import * as css from './styles.css';

interface RelationshipsProps {
	user: models.user.User;
}

export default function Relationships({
	user,
}: RelationshipsProps): JSX.Element {
	const allowedToListRelationships: boolean = models.hasAllowedAction(
		user,
		models.Action.UserListRelationships
	);

	const { record: relationships, isLoading } =
		useEndpoint<models.user.UserRelationshipUsers>(
			allowedToListRelationships ? endpoints.Users.ListRelatives(user.id) : null
		);

	if (isLoading || !allowedToListRelationships) {
		return null;
	}

	return (
		<Fragment>
			{relationships.parents && (
				<ParentRelationships user={user} users={relationships.parents} />
			)}
			{relationships.children && (
				<ChildRelationships user={user} users={relationships.children} />
			)}
		</Fragment>
	);
}

interface RelationshipSection {
	user: models.user.User;
	users: models.user.User[];
}

function ParentRelationships({ users }: RelationshipSection): JSX.Element {
	const t = useT();

	return (
		<Section title={t('Connected legal guardians')} icon="supervisor_account">
			<Column>
				{users.map((user) => (
					<Card.Base $noBorder key={user.id}>
						<Card.Body className={css.relationshipRowCard}>
							<Row columns="40px 1fr auto" align="center">
								<Avatar user={user} />
								<Column spacing={styles.spacing._1}>
									<strong>{models.user.fullName(user)}</strong>
									<span>{user.email}</span>
								</Column>
								<Button secondary icon="more_horiz" />
							</Row>
						</Card.Body>
					</Card.Base>
				))}
			</Column>
		</Section>
	);
}

function ChildRelationships({ users }: RelationshipSection): JSX.Element {
	const t = useT();

	return (
		<Section
			title={t('Connected wards')}
			icon="guardian"
			action={
				<Button small inline icon="add">
					{t('Add ward')}
				</Button>
			}>
			<Column>
				{users.map((user) => (
					<Card.Base $noBorder key={user.id}>
						<Card.Body className={css.relationshipRowCard}>
							<Row columns="40px 1fr auto" align="center">
								<Avatar user={user} />
								<Column className={css.relationshipRowUser}>
									<strong>{models.user.fullName(user)}</strong>
									<span>{user.email}</span>
								</Column>
								<Button secondary icon="more_horiz" />
							</Row>
						</Card.Body>
					</Card.Base>
				))}
			</Column>
		</Section>
	);
}
