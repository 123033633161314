import { Map, Record } from 'immutable';

import VideoPlaylistSequence, {
	playlistSequenceId,
} from 'pkg/models/video_playlist_sequence';

import * as actionTypes from 'pkg/actions/action-types';

interface IPlaylistSequenceReducer {
	entities: Map<number, VideoPlaylistSequence>;
}

class PlaylistSequenceReducer
	extends Record<IPlaylistSequenceReducer>(
		{
			entities: Map<number, VideoPlaylistSequence>(),
		},
		'videoPlaylistSequences'
	)
	implements IPlaylistSequenceReducer
{
	entities: Map<number, VideoPlaylistSequence>;
}

const initialState = new PlaylistSequenceReducer({
	entities: Map<number, VideoPlaylistSequence>(),
});

interface NormalizrPayload {
	result: number[];
	entities: {
		videoPlaylistSequences: { [key: string]: VideoPlaylistSequence };
	};
}

interface PlaylistSequenceAction {
	type: string;
	payload?: NormalizrPayload;
	playlistId?: number;
	sequenceId?: number;
}

const setItems = (
	state = initialState,
	action: PlaylistSequenceAction
): PlaylistSequenceReducer => {
	const videoPlaylistSequences = action.payload;

	Object.values(videoPlaylistSequences).forEach(
		(playlistSequence: VideoPlaylistSequence) => {
			state = state.updateIn(
				['entities', playlistSequenceId(playlistSequence)],
				(existing: VideoPlaylistSequence) => {
					if (!existing) {
						existing = new VideoPlaylistSequence(playlistSequence);
					}

					existing = existing.merge(playlistSequence);

					return existing;
				}
			);
		}
	);

	return state;
};

const deleteItem = (
	state = initialState,
	action: PlaylistSequenceAction
): PlaylistSequenceReducer => {
	if (action.playlistId && action.sequenceId) {
		state = state.deleteIn([
			'entities',
			`p${action.playlistId}s${action.sequenceId}`,
		]);
	}

	return state;
};

const videoCollectionsReducer = (
	state = initialState,
	action: PlaylistSequenceAction
): PlaylistSequenceReducer => {
	switch (action.type) {
		case actionTypes.VideoPlaylistSequences.SET_ITEMS:
		case actionTypes.VideoPlaylistSequences.UPDATE_ITEM:
			return setItems(state, action);
		case actionTypes.VideoPlaylistSequences.DELETE_ITEM:
			return deleteItem(state, action);
		default:
			return state;
	}
};

export default videoCollectionsReducer;
