import * as models from 'pkg/api/models';
import * as objects from 'pkg/objects';

import Avatar from 'components/avatar';

import * as css from './styles.css';

interface UserHeaderProps {
	user: models.user.User;
}

export default function UserHeader({ user }: UserHeaderProps): JSX.Element {
	if (objects.empty(user)) {
		return null;
	}

	return (
		<div className={css.userHeader}>
			<Avatar user={user} className={css.avatar} />
			<span className={css.firstName}>{user.firstName}</span>
			<strong className={css.lastName}>{user.lastName}</strong>
		</div>
	);
}
