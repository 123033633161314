import { Record } from 'immutable';
import { schema } from 'normalizr';

import { Sports } from 'pkg/models/schemas';

import { SportType } from 'pkg/api/models/sport';

interface ISport {
	type: SportType;
	name?: string;
	slug?: string;
	defaultMatchDuration: number;
	defaultMatchPeriods: number;
	hasMatchModel: boolean;
	hasRatingModel: boolean;
}

const SportProps: ISport = {
	type: SportType.Unknown,
	name: '',
	slug: '',
	defaultMatchDuration: 0,
	defaultMatchPeriods: 0,
	hasMatchModel: false,
	hasRatingModel: false,
};

class Sport extends Record(SportProps, 'SportRecord') implements ISport {
	hasMatchesEnabled(): boolean {
		return this.get('hasMatchModel');
	}

	hasRatingsEnabled(): boolean {
		return this.get('hasRatingModel');
	}

	isFootball(): boolean {
		return this.get('type') === SportType.Football;
	}

	static normalizr(): schema.Entity {
		return Sports;
	}
}

export default Sport;
