import PerformanceReview from 'pkg/models/performance_review';

const emptyPerformanceReview = new PerformanceReview();

const findAllPerformanceReviewEntities = (state) =>
	state.performanceReviews.entities;

export const findAllUserPerformanceReviews = (state, props) => {
	return findAllPerformanceReviewEntities(state)
		.filter((performanceReview) => +performanceReview.userId === +props.userId)
		.sort((a, b) => b.createdAt - a.createdAt);
};

export const findLatestPerformanceReview = (state, props) => {
	return (
		findAllUserPerformanceReviews(state, { userId: props })
			.filter((performanceReview) => {
				return (
					performanceReview.get('createdAt') < Math.ceil(Date.now() / 1000)
				);
			})
			.maxBy((performanceReview) => performanceReview.get('createdAt')) ||
		emptyPerformanceReview
	);
};
