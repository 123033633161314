import styled from 'styled-components';

import Placeholder, { CardHeader } from 'components/Placeholder';
import * as Card from 'components/Card';

import { ParagraphRow } from 'components/loaders/skeleton/placeholders/ParagraphRow';

const Wrapper = styled(Card.Base)`
	margin-bottom: 1rem;
`;

export default class CardPlaceholder extends Placeholder {
	getPlaceholderItem(key) {
		return (
			<Wrapper key={key}>
				<Card.Header centered>
					<CardHeader />
				</Card.Header>
				<Card.Divider />

				<Card.Body>
					<ParagraphRow height={14} width={180} />
					<ParagraphRow height={14} width={150} />
					<ParagraphRow height={14} width={110} />
				</Card.Body>
				<Card.Divider />
			</Wrapper>
		);
	}
}
