import { ReactNode } from 'react';

export const symbol_document_scanner: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M80-775v-85q0-24.75 17.625-42.375T140-920h85q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T225-860h-85v85q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Q97-745 88.5-753.625T80-775Zm740 0v-85h-85q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T735-920h85q24.75 0 42.375 17.625T880-860v85q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T820-775ZM80-100v-85q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-185v85h85q12.75 0 21.375 8.675Q255-82.649 255-69.825 255-57 246.375-48.5T225-40h-85q-24.75 0-42.375-17.625T80-100Zm740 60h-85q-12.75 0-21.375-8.675Q705-57.351 705-70.175 705-83 713.625-91.5T735-100h85v-85q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T880-185v85q0 24.75-17.625 42.375T820-40ZM260-220h440v-520H260v520Zm0 60q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h440q24 0 42 18t18 42v520q0 24-18 42t-42 18H260Zm140-410h160q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T560-630H400q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T400-570Zm0 120h160q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T560-510H400q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T400-450Zm0 120h160q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T560-390H400q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T400-330ZM260-220v-520 520Z"
		/>
	</svg>
);
