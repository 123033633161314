import { ReactNode } from 'react';

export const symbol_account_balance: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M212-271v-279q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T272-550v279q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T212-271Zm242 0v-279q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T514-550v279q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T454-271ZM110-121q-12.75 0-21.375-8.675Q80-138.351 80-151.175 80-164 88.625-172.5T110-181h740q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-121H110Zm578-150v-279q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T748-550v279q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T688-271Zm166-369H103q-9.583 0-16.292-6.708Q80-653.417 80-663v-17q0-6 3.5-11.5T92-700l358-204q14.167-8 30-8t30 8l357 203q6 4 9.5 10t3.5 13.5v10.227q0 11.591-7.475 19.432Q865.05-640 854-640Zm-640-60h532-532Zm0 0h532L480-852 214-700Z"
		/>
	</svg>
);
