import { ChangeEvent, PointerEvent, useState } from 'react';

import spacing from 'pkg/config/spacing';

import { cssClasses } from 'pkg/css/utils';
import copyString from 'pkg/copyString';
import * as actions from 'pkg/actions';

import Icon, { IconName } from 'components/icon';

import Column from 'components/layout/column';
import * as Input from 'components/form/inputs';

import iconLibrary from 'assets/icons/index';

import * as css from './styles.css';

export default function Icons(): JSX.Element {
	const [keyword, setKeyword] = useState<string>('');

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		setKeyword(event.target.value);
	};

	const handleReset = () => {
		setKeyword('');
	};

	const handleItemClick = (event: PointerEvent<HTMLDivElement>) => {
		const { iconName } = event.currentTarget.dataset;

		copyString(iconName);

		actions.flashes.show(
			{
				title: `Copied '${iconName}' to clip board`,
			},
			0,
			'scissors'
		);
	};

	const icons = iconLibrary.filter((icon) => {
		if (keyword === '') return true;

		return icon.name.includes(keyword);
	});

	return (
		<Column spacing={spacing._7}>
			<Input.Field
				placeholder="Search for icons, e.g. 'filter'..."
				value={keyword}
				onChange={handleChange}>
				<Input.Prefix inline>
					<Icon name="search" />
				</Input.Prefix>
				{keyword !== '' && (
					<Input.Suffix inline onClick={handleReset}>
						<Icon name="close" />
					</Input.Suffix>
				)}
			</Input.Field>
			<div className={css.wrapper}>
				{icons.map((icon) => (
					<Column
						key={icon.name}
						data-icon-name={icon.name}
						onClick={handleItemClick}
						className={cssClasses(css.item, icon.viewBox ? css.verbose : '')}>
						<Icon name={icon.name as IconName} className={css.icon} />
						<var className={css.name}>{icon.name}</var>
						{icon.viewBox && <pre className={css.box}>{icon.viewBox}</pre>}
					</Column>
				))}
			</div>
		</Column>
	);
}
