import { t } from '@transifex/native';

import { FilterOperator, createFilter } from 'pkg/filters';
import { Filters, useFilters } from 'pkg/filters/use_filters';

import AppliedToModal from 'routes/payments/subscriptions/filters/applied_modal';
import ProductsSelectModal from 'routes/payments/products/modals/select';

export default function useSubscriptionFilters(): Filters {
	return useFilters({
		groups: {
			[t('Has product')]: {
				filters: {
					HasProduct: createFilter({
						type: 'modal',
						property: 'product_id',
						operator: FilterOperator.Includes,
						settings: {
							modal: <ProductsSelectModal />,
							buttonLabel: t('Select product'),
							hideButton: false,
						},
					}),
				},
			},
			[t('Status')]: {
				filters: {
					Status: createFilter({
						type: 'checkbox',
						property: 'status',
						operator: FilterOperator.Includes,
						values: {
							[t('Active')]: 'active',
							[t('Scheduled')]: 'scheduled',
							[t('Pending')]: 'pending',
							[t('Completed')]: 'completed',
							[t('Past due')]: 'past_due',
							[t('Canceled')]: 'canceled',
							[t('Uncollectible')]: 'uncollectible',
						},
					}),
				},
			},
			[t('Created')]: {
				filters: {
					DueDate: createFilter({
						type: 'date',
						property: 'created_at',
					}),
				},
			},
			[t('Next invoice date')]: {
				filters: {
					TrialEndsAt: createFilter({
						type: 'date',
						property: 'current_period_end',
					}),
				},
			},
			[t('Collection method')]: {
				filters: {
					CollectionMethod: createFilter({
						type: 'checkbox',
						property: 'collection_method',
						operator: FilterOperator.Includes,
						values: {
							[t('Auto charge')]: 'automatic',
							[t('Email invoice')]: 'manual',
						},
					}),
				},
			},
			[t('Has discount')]: {
				filters: {
					Applied: createFilter({
						type: 'modal',
						property: 'discount_id',
						operator: FilterOperator.Includes,
						settings: {
							modal: <AppliedToModal />,
							buttonLabel: t('Select discounts'),
							hideButton: false,
						},
					}),
				},
			},
		},
		filterBarMobileTrigger: true,
	});
}
