import * as endpoints from 'pkg/api/endpoints/auto';
import * as sdk from 'pkg/core/sdk';

export const fetchNotifications = (
	accountId: number,
	queryParams: { 'only-meta': number }
): Promise<Response> => {
	return sdk.get(endpoints.AccountNotifications.Index(accountId), queryParams);
};

export const markAsRead = (notificationId: number): Promise<Response> => {
	return sdk.patch(
		endpoints.AccountNotifications.Update(notificationId),
		{},
		{ isRead: true }
	);
};

export const markAllAsRead = (accountId: number): Promise<Response> => {
	return sdk.post(endpoints.AccountNotifications.MarkAllAsRead(accountId));
};
