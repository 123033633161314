import { Record } from 'immutable';
import { schema } from 'normalizr';

const productCategories = new schema.Entity('productCategory');

interface IProductCategory {
	id: number;
	groupId: number;
	name: string;
	description: string;
	links?: { [key: string]: string };
}

const productCategoryprops: IProductCategory = {
	id: 0,
	groupId: 0,
	name: '',
	description: '',
	links: {},
};

class ProductCategory
	extends Record(productCategoryprops, 'ProductCategoryRecord')
	implements IProductCategory
{
	static normalizr(): schema.Entity {
		return productCategories;
	}
}

export default ProductCategory;
