import { ReactNode } from 'react';

export const symbol_stop: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M240-300v-360q0-24.75 17.625-42.375T300-720h360q24.75 0 42.375 17.625T720-660v360q0 24.75-17.625 42.375T660-240H300q-24.75 0-42.375-17.625T240-300Zm60 0h360v-360H300v360Zm180-180Z"
		/>
	</svg>
);
