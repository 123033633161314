import { ReactNode } from 'react';

export const symbol_cast_for_education: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m605-497 122-70q8-4.5 8-12.75T727-593l-122-70q-6.677-4-14.839-4Q582-667 575-663l-122 70q-8 4.5-8 12.75t8 13.25l122 70q6.677 4 14.839 4Q598-493 605-497Zm0 122 80-46q6.75-3.714 10.875-10.771Q700-438.829 700-447v-67l-95 55q-6.677 4-14.839 4Q582-455 575-459l-95-55v67q0 8.171 4.125 15.229Q488.25-424.714 495-421l80 46q6.677 4 14.839 4Q598-371 605-375ZM481-480Zm400-260v520q0 24.75-17.625 42.375T821-160H630q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T630-220h191v-520H141v30q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Q98-680 89.5-688.625T81-710v-30q0-24.75 17.625-42.375T141-800h680q24.75 0 42.375 17.625T881-740ZM310.533-160Q298.8-160 290-167.329q-8.8-7.328-11-19.671-9-69-56.5-117.5T107-362q-11-2-18.5-11.052-7.5-9.051-7.5-20.638Q81-406 88.5-415q7.5-9 18.5-8 93 9 158 75.5T340-188q2 11.789-7.5 19.895Q323-160 310.533-160ZM471-160q-12 0-21-8t-10-20q-11-134-103.5-229T111-523q-13-1-21.5-10.053-8.5-9.052-8.5-21.122Q81-567 91.5-575.5T116-583q154.76 12.835 263.38 123.418Q488-349 500-193q1 14-7.625 23.5T471-160Zm-352.141 0Q102-160 90.5-171.641t-11.5-28.5Q79-217 90.64-228.5q11.642-11.5 28.501-11.5t28.359 11.641q11.5 11.641 11.5 28.5T147.359-171.5q-11.641 11.5-28.5 11.5Z"
		/>
	</svg>
);
