// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JmINZHSIbaaWWMGLglCW {
	border: 2px dashed var(--palette-gray-300);
}

.dGtN0O_gRmkqYJ_o1f12 {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	gap: var(--spacing-4);
}
`, "",{"version":3,"sources":["webpack://./routes/group/settings/user-fields/modal/styles.css"],"names":[],"mappings":"AAAA;CACC,0CAA0C;AAC3C;;AAEA;CACC,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CACnB,qBAAqB;AACtB","sourcesContent":[".dashed_border {\n\tborder: 2px dashed var(--palette-gray-300);\n}\n\n.edit_option {\n\tdisplay: flex;\n\talign-items: center;\n\tgap: var(--spacing-4);\n}\n"],"sourceRoot":""}]);
// Exports
export var dashed_border = `JmINZHSIbaaWWMGLglCW`;
export var edit_option = `dGtN0O_gRmkqYJ_o1f12`;
export default ___CSS_LOADER_EXPORT___;
