import { Map, Record } from 'immutable';

import Order from 'pkg/models/order';
import Price from 'pkg/models/price';

import * as actionTypes from 'pkg/actions/action-types';

interface IOrderReducer {
	entities: Map<number, Order>;
	links: { previous: ''; next: '' };
}

class OrderReducer
	extends Record<IOrderReducer>(
		{
			entities: Map<number, Order>(),
			links: { previous: '', next: '' },
		},
		'orderReducer'
	)
	implements IOrderReducer
{
	entities: Map<number, Order>;
	links: { previous: ''; next: '' };
}

const initialState = new OrderReducer({
	entities: Map<number, Order>(),
	links: { previous: '', next: '' },
});

interface NormalizrPayload {
	entities: {
		order: { [key: string]: Order };
		price: { [key: string]: Price };
	};
	result: number[];
}

interface Action {
	type: string;
	payload?: NormalizrPayload;
	links?: { prev?: string; next?: string };
	ids: number[];
	status: string;
	orderIds: number[];
}

const setItems = (state = initialState, action: Action) => {
	const entities = Object.values(action.payload.entities.order);

	entities.map((entity: Order) => {
		state = state.setIn(['entities', entity.id], new Order(entity));
	});

	return state;
};

const setLinks = (state = initialState, action: Action) => {
	if (action.links) {
		state = state.setIn(['links'], action.links);
	}

	return state;
};

const setStatus = (state = initialState, action: Action) => {
	action.ids.forEach((id) => {
		state = state.setIn(['entities', id, 'status'], action.status);
	});

	return state;
};

const deleteItems = (state = initialState, action: Action) => {
	action.orderIds.forEach((id) => {
		state = state.deleteIn(['entities', id]);
	});

	return state;
};

const orderReducer = (state = initialState, action: Action): OrderReducer => {
	switch (action.type) {
		case actionTypes.Orders.SET_ITEMS:
			return setItems(state, action);
		case actionTypes.Orders.SET_LINKS:
			return setLinks(state, action);
		case actionTypes.Orders.SET_STATUS:
			return setStatus(state, action);
		case actionTypes.Orders.DELETE_ITEMS:
			return deleteItems(state, action);
		default:
			return state;
	}
};

export default orderReducer;
