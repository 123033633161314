import { Fragment, MouseEvent, ReactNode, useState } from 'react';
import { t } from '@transifex/native';

import spacing from 'pkg/config/spacing';

import * as models from 'pkg/api/models';
import { CollectionResponse, useCollection } from 'pkg/api/use_collection';
import * as endpoints from 'pkg/api/endpoints/auto';
import {
	useCurrentAccount,
	useCurrentGroup,
	useCurrentOrganization,
} from 'pkg/identity';

import {
	AggregatedCollectionResponse,
	useUserCollections,
	useUserExercises,
	useAggregatedCollections,
	useAggregatedExercises,
	useClubCollections,
	useClubExercises,
	useCuratedCollections,
	useCuratedExercises,
	useGroupCollections,
	useGroupExercises,
} from 'routes/training/library/hooks';
import CurrentFilter from 'routes/training/library/CurrentFilter';

import Icon from 'components/icon';
import * as StepModal from 'components/step-modal';

import TrainingCollectionGroupItem from 'components/library/group-items/TrainingCollection';
import ExerciseGroupItem from 'components/library/group-items/Exercise';
import Group from 'components/library/Group';
import * as ActionBar from 'components/layout/ActionBar';
import Row from 'components/layout/row';
import EmptyState from 'components/library/TrainingEmptyState';
import GroupItemButton from 'components/library/GroupItemButton';
import { Spinner } from 'components/loaders/spinner';
import Column from 'components/layout/column';
import * as Input from 'components/form/inputs';

import * as ContextMenu from 'design/context_menu';
import Button from 'design/button';

import * as css from './styles.css';

interface ExerciseGroupProps {
	onSelect: (exerciseId: number) => void;
}

interface ExerciseProps extends ExerciseGroupProps {
	onClose: () => void;
}

enum ContentType {
	Aggregated = 'all',
	Group = 'team',
	Club = 'club',
	Curated = 'curated',
	Account = 'my',
}

function contentTypeTitle(type: ContentType): string {
	switch (type) {
		case ContentType.Aggregated:
			return t('Browse');
		case ContentType.Group:
			return t('Team');
		case ContentType.Club:
			return t('Club');
		case ContentType.Curated:
			return t('Curated content');
		case ContentType.Account:
			return t('My');
	}
}

interface ExerciseAndCollectionItemsProps extends ExerciseGroupProps {
	exercises?:
		| CollectionResponse<models.exercise.Exercise>
		| AggregatedCollectionResponse<models.exercise.Exercise>;
	collections?:
		| CollectionResponse<models.trainingCollection.TrainingCollection>
		| AggregatedCollectionResponse<models.trainingCollection.TrainingCollection>;
}

function ExerciseAndCollectionItems({
	exercises,
	collections,

	onSelect,
}: ExerciseAndCollectionItemsProps): JSX.Element {
	const filteredCollections = collections.records?.filter(
		(collection: models.trainingCollection.TrainingCollection) =>
			collection.exerciseCount > 0
	);

	const isLoading = exercises?.isLoading || collections?.isLoading;
	const hasExercies = exercises?.records.length > 0;
	const hasCollections = filteredCollections?.length > 0;
	const hasRecords = !isLoading && (hasExercies || hasCollections);

	const [collectionId, setCollectionId] = useState<number>(0);

	if (isLoading) {
		return <Spinner />;
	}

	if (!hasRecords) {
		return <EmptyState />;
	}

	const handleExerciseClick = (item: models.exercise.Exercise) => {
		onSelect(item.id);
	};

	const handleCollectionClick = (
		item: models.trainingCollection.TrainingCollection
	) => {
		setCollectionId(item.id);
	};

	const closeCollection = () => {
		setCollectionId(0);
	};

	if (collectionId !== 0) {
		return (
			<Column spacing={spacing._8}>
				<CurrentFilter />
				<div>
					<Button
						icon="arrow_back"
						label={t('Back')}
						onClick={closeCollection}
					/>
				</div>
				<CollectionExerciseItems
					onSelect={onSelect}
					collectionId={collectionId}
				/>
			</Column>
		);
	}

	return (
		<Column spacing={spacing._8}>
			<CurrentFilter />
			{hasExercies && (
				<Group title={t('Exercises')} context="training-library">
					{exercises.records.map(
						(item: models.exercise.Exercise, n: number) => (
							<ExerciseGroupItem
								item={item}
								key={`${item.id}:${n}`}
								onClick={handleExerciseClick}
							/>
						)
					)}
					{exercises.pagination.hasNext && exercises.pagination.fetchNext && (
						<GroupItemButton
							label={t('Load more')}
							onClick={exercises.pagination.fetchNext}
						/>
					)}
				</Group>
			)}
			{hasCollections && (
				<Group title={t('Collections')} context="training-library">
					{collections.records.map(
						(item: models.trainingCollection.TrainingCollection, n: number) => (
							<TrainingCollectionGroupItem
								item={item}
								key={`${item.id}:${n}`}
								onClick={handleCollectionClick}
							/>
						)
					)}
					{collections.pagination.hasNext &&
						collections.pagination.fetchNext && (
							<GroupItemButton
								label={t('Load more')}
								onClick={collections.pagination.fetchNext}
							/>
						)}
				</Group>
			)}
		</Column>
	);
}

interface CollectionExerciseItemsProps extends ExerciseGroupProps {
	collectionId: number;
}

function CollectionExerciseItems({
	collectionId,
	onSelect,
}: CollectionExerciseItemsProps): JSX.Element {
	const org = useCurrentOrganization();
	const exercises =
		useCollection<models.trainingCollection.TrainingCollectionExercise>(
			endpoints.TrainingCollections.ShowExercises(org.id, collectionId),
			{ showAllResults: true }
		);

	const records: models.exercise.Exercise[] = exercises.records.map(
		(item: models.trainingCollection.TrainingCollectionExercise) =>
			item.exercise
	);

	const handleExerciseClick = (item: models.exercise.Exercise) => {
		onSelect(item.id);
	};

	return (
		<Group title={t('Exercises')} context="training-library">
			{records.map((item: models.exercise.Exercise, n: number) => (
				<ExerciseGroupItem
					item={item}
					key={`${item.id}:${n}`}
					onClick={handleExerciseClick}
				/>
			))}
		</Group>
	);
}

function AggregatedExercises({ onSelect }: ExerciseGroupProps): JSX.Element {
	const exercises = useAggregatedExercises();
	const collections = useAggregatedCollections();

	return (
		<ExerciseAndCollectionItems
			exercises={exercises}
			collections={collections}
			onSelect={onSelect}
		/>
	);
}

function GroupExercises({ onSelect }: ExerciseGroupProps): JSX.Element {
	const group = useCurrentGroup();

	const exercises = useGroupExercises(group.id);
	const collections = useGroupCollections(group.id);

	return (
		<ExerciseAndCollectionItems
			exercises={exercises}
			collections={collections}
			onSelect={onSelect}
		/>
	);
}

function ClubExercises({ onSelect }: ExerciseGroupProps): JSX.Element {
	const group = useCurrentGroup();
	const exercises = useClubExercises(group.id);
	const collections = useClubCollections(group.id);

	return (
		<ExerciseAndCollectionItems
			exercises={exercises}
			collections={collections}
			onSelect={onSelect}
		/>
	);
}

function CuratedExercises({ onSelect }: ExerciseGroupProps): JSX.Element {
	const exercises = useCuratedExercises();
	const collections = useCuratedCollections();

	return (
		<ExerciseAndCollectionItems
			exercises={exercises}
			collections={collections}
			onSelect={onSelect}
		/>
	);
}

function AccountExercises({ onSelect }: ExerciseGroupProps): JSX.Element {
	const account = useCurrentAccount();
	const exercises = useUserExercises(account.id);
	const collections = useUserCollections();

	return (
		<ExerciseAndCollectionItems
			exercises={exercises}
			collections={collections}
			onSelect={onSelect}
		/>
	);
}

export default function Exercise({
	onSelect,
	onClose,
}: ExerciseProps): JSX.Element {
	const [contentType, setContentType] = useState<ContentType>(
		ContentType.Aggregated
	);

	const handleContentChange = (event: MouseEvent<HTMLDivElement>) => {
		setContentType(
			(event.currentTarget as HTMLDivElement).dataset.contentType as ContentType
		);
	};

	const trigger: ReactNode = (
		<div className={css.trigger}>
			<Row align="center" justify="center" spacing={spacing._3}>
				<span>{contentTypeTitle(contentType)}</span>
				<Icon name="select-trigger" size={1.5} />
			</Row>
		</div>
	);

	const contentTypes: ReactNode[] = Object.values(ContentType).map(
		(type: string) => {
			const isActive = (type as ContentType) === contentType;

			return (
				<ContextMenu.Item
					key={type}
					columns={isActive ? '1fr auto' : 'auto'}
					data-content-type={type}
					onClick={handleContentChange}>
					<span>{contentTypeTitle(type as ContentType)}</span>
					{isActive && <ContextMenu.ItemIcon name="check" />}
				</ContextMenu.Item>
			);
		}
	);

	let library: ReactNode = null;

	switch (contentType) {
		case ContentType.Aggregated:
			library = <AggregatedExercises onSelect={onSelect} />;
			break;
		case ContentType.Group:
			library = <GroupExercises onSelect={onSelect} />;
			break;
		case ContentType.Club:
			library = <ClubExercises onSelect={onSelect} />;
			break;
		case ContentType.Curated:
			library = <CuratedExercises onSelect={onSelect} />;
			break;
		case ContentType.Account:
			library = <AccountExercises onSelect={onSelect} />;
			break;
		default:
			library = null;
	}

	return (
		<StepModal.Base wide onClose={onClose}>
			<StepModal.StepWithoutBody hideNext title={t(`Add exercise`)}>
				<ActionBar.FilterBar>
					<ActionBar.PrimaryAction>
						<ActionBar.Search placeholder={t('Search')} queryName="title">
							<Input.Suffix interactable>
								<ContextMenu.Menu toggleWith={trigger}>
									<Fragment>{contentTypes}</Fragment>
								</ContextMenu.Menu>
							</Input.Suffix>
						</ActionBar.Search>
					</ActionBar.PrimaryAction>
				</ActionBar.FilterBar>
				<StepModal.Body>{library}</StepModal.Body>
			</StepModal.StepWithoutBody>
		</StepModal.Base>
	);
}
