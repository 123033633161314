import { ReactNode } from 'react';

export const symbol_important_devices: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M680-120q-17 0-28.5-11.5T640-160v-334q0-17 11.5-28.5T680-534h160q17 0 28.5 11.5T880-494v334q0 17-11.5 28.5T840-120H680Zm0-60h160v-294H680v294Zm-330 60q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T350-180h60v-100H140q-24.75 0-42.375-17.625T80-340v-440q0-24.75 17.625-42.375T140-840h599q26 0 43.5 17.625T800-780v156q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T740-624v-156h.5H140v440h410q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T550-280h-80v100h60q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T530-120H350Zm90-364 67 51q5 3 9.5.067 4.5-2.934 2.5-8.067l-25-84.397L563-581q5-3 3-8t-7-5h-86l-26-81q-2-5-7-5t-7 5l-26 81h-86q-5 0-7 5t3 8l69 55.603L361-441q-2 5.133 2.5 8.067Q368-430 373-433l67-51Zm1-76Z"
		/>
	</svg>
);
