import { ReactNode } from 'react';

export const symbol_shopping_cart_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M640-452h-35l-59-60h85l126-228H316l-60-60h529q26 0 38 21.5t-2 46.5L680-476q-6 11-15 17.5t-25 6.5ZM286.788-81Q257-81 236-102.212q-21-21.213-21-51Q215-183 236.212-204q21.213-21 51-21Q317-225 338-203.788q21 21.213 21 51Q359-123 337.788-102q-21.213 21-51 21ZM830-56 595-289H277q-38 0-56-27.5t1-59.5l70-117-86-187L67-819q-9-9-8.5-21t9.5-21q9-9 21.5-9t21.5 9L873-99q9 9 8.5 21.5T872-56q-9 9-21 9t-21-9ZM535-349 434-453h-95l-63 104h259Zm96-163h-85 85Zm57 431q-29 0-50.5-21.212-21.5-21.213-21.5-51Q616-183 637.5-204q21.5-21 50.5-21t50.5 21.212q21.5 21.213 21.5 51Q760-123 738.5-102 717-81 688-81Z"
		/>
	</svg>
);
