import { ReactNode } from 'react';

export const symbol_lasso_select: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M488-82q-38 0-73-5.5T348-104q-9.778-3.127-15.889-10.945Q326-122.764 326-133.709 326-147 334.913-156q8.912-9 22.087-9 5 0 11 2 27 10 59 15t61 6q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q518-99 509.375-90.5T488-82Zm163.825-36Q639-118 630.5-126.625T622-148v-172q0-12.75 8.625-21.375T652-350h172q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T824-290H724l110 110q9 9 9 21t-9 21q-9 9-21 9t-21-9L682-248v100q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM218-192q-6 0-11.5-2t-10.368-6.865Q164-232 139.5-269.5T101-352l-2-11q0-12.75 8.944-21.375Q116.889-393 130.111-393q10.889 0 18.389 6t10.5 15q12.72 37.211 32.86 69.105Q212-271 238.839-244.161 244-239 246-234.5t2 11.5q0 13.175-8.625 22.087Q230.75-192 218-192Zm630.825-262Q836-454 827.5-462.625T819-484q-1-27-5-55.5T799-594q-1-2-3-15 0-12.75 8.731-21.375Q813.463-639 826.37-639q10.63 0 18.13 5.833Q852-627.333 855-618q12 32 18 65.5t6 68.5q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Zm-737.843-37Q98-491 89.5-499.488T81-520.524V-525q5-46 20-89.5t40-81.5q3.824-5.895 10.706-9.947Q158.588-710 167-710q13 0 21.5 8.75t8.5 21.685q0 4.565-1.429 8.8-1.428 4.236-3.571 7.765-19 34-32.037 70.471Q146.926-556.057 141-518q-1 11-9.4 19t-20.618 8ZM742-708q-6 0-11.5-2t-10.5-7q-28.721-28.844-61.86-49.922Q625-788 588-801q-9-3.127-15-10.945-6-7.819-6-18.764 0-13.291 8.912-21.791Q584.825-861 598-861q4 0 10 2 47 16 85.5 41.5t69.635 57.632Q768-755 770-749.783q2 5.218 2 10.783 0 13.175-8.625 22.088Q754.75-708 742-708Zm-459.696-56Q269-764 260-772.794q-9-8.794-9-21.794 0-8.412 4.053-15.064Q259.105-816.304 265-820q40-25 82-39.5t89-20.5h5.213q12.659 0 21.223 8.625Q471-862.75 471-850q0 12-8 20.5t-19 9.5q-41 5-75.5 17.5T298-769q-4 2-7.5 3.5t-8.196 1.5Z"
		/>
	</svg>
);
