import { ReactNode } from 'react';

export const symbol_network_manage: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M201-389q-14-14-13-32t15-30q59-50 129-79.5T480-560q35 0 68 6.5t65 17.5q17 6 25 23t2 34q-6 17-23.5 25t-34.5 2q-25-9-50.5-13.5T480-470q-60 0-114 22.5T266-387q-15 12-33.5 11.5T201-389Zm279-411q125 0 238.5 47T925-623q14 13 16 32t-12 33q-14 14-33 13.5T862-558q-81-69-178.5-110.5T480-710q-106 0-203.5 41.5T98-558q-15 13-33.5 13.5T32-558q-14-14-12.5-33T35-623q93-83 206.5-130T480-800ZM691-89q-23-7-42-18.5T616-132l-17 8q-11 5-22 1.5T559-136l-4-6q-7-11-5-22.5t12-19.5l15-12q-5-19-5-41.5t5-41.5l-15-12q-10-8-12-20t5-23l4-6q7-11 18.5-14t22.5 2l16 8q14-12 33-23.5t42-18.5l2-22q2-11 10.5-19t19.5-8h7q11 0 19 7.5t10 18.5l3 23q23 7 42 18.5t33 23.5l16-8q11-5 22.5-2t18.5 14l4 6q7 11 5 23t-12 20l-15 12q5 19 5 41.5t-5 41.5l15 12q10 8 12 19.5t-5 22.5l-4 7q-7 10-18 13t-22-2l-17-8q-14 13-33 24.5T762-89l-3 22q-2 11-10 18.5T730-41h-7q-11 0-19.5-8T693-68l-2-21Zm35-251q-44 0-73 29t-29 73q0 44 29 73t73 29q44 0 73-29t29-73q0-44-29-73t-73-29ZM392-209q0-35 21.5-61.5T469-297q5 0 8 3t3 8v155q0 5-3 7.5t-8 2.5q-34 0-55.5-26.5T392-209Z"
		/>
	</svg>
);
