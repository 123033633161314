import { ReactNode } from 'react';

import { cssClasses } from 'pkg/css/utils';

import { useFullScreenTableContext } from 'components/layout/page-templates';

import * as css from './styles.css';

interface TableInfoProps {
	children: ReactNode;
}

export default function TableInfo({ children }: TableInfoProps) {
	const { fullPageTable } = useFullScreenTableContext();

	return (
		<div
			className={cssClasses(
				css.wrapper,
				fullPageTable ? css.fullPageTable : ''
			)}>
			{children}
		</div>
	);
}
