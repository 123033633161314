import { ChangeEvent, useState } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as actions from 'pkg/actions';

import { useOnboardingContext } from 'routes/public/onboarding';
import Button from 'routes/public/styles/Button';
import ThreeSixtyLogo from 'routes/public/styles/logo';
import Title from 'routes/public/styles/Title';
import Form from 'routes/public/styles/forms';
import Field from 'routes/public/styles/inputs/field';

import Column from 'components/layout/column';

export default function ResetPasswordRequest(): JSX.Element {
	const { goTo } = useOnboardingContext();

	const [isSaving, setIsSaving] = useState<boolean>(false);
	const [email, setEmail] = useState<string>();

	const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
		setEmail(event.target.value);

	const handlePasswordReset = async () => {
		setIsSaving(true);

		const ok = await actions.users.requestPasswordChange(email);

		if (!ok) {
			setIsSaving(false);
			return;
		}

		goTo('start');
	};

	return (
		<Column spacing={styles.spacing._8}>
			<ThreeSixtyLogo />

			<Form onSubmit={handlePasswordReset}>
				<Column spacing={styles.spacing._8}>
					<Title
						title={t('Forgot your password?')}
						description={t(
							`Don't worry! Just fill in your email, if we can match it we'll send you a link to reset your password.`
						)}
						thinDescription
						center
					/>

					<Column>
						<Field
							name="email"
							type="email"
							placeholder={t('Your email')}
							message={t('A reset link will be sent here')}
							errorMessage={t('Please enter a valid email')}
							value={email}
							onChange={handleChange}
						/>

						<Button
							primary
							label={t('Send password reset link')}
							busy={isSaving}
							type="submit"
							disabled={!email}
						/>
					</Column>
				</Column>
			</Form>
		</Column>
	);
}
