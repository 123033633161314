import { ReactNode } from 'react';

export const symbol_nest_hello_doorbell: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-80q-83 0-141.5-58.5T280-280v-400q0-83 58.5-141.5T480-880q83 0 141.5 58.5T680-680v400q0 83-58.5 141.5T480-80Zm-.235-60Q538-140 579-180.833q41-40.834 41-99.167v-400q0-58.333-40.765-99.167-40.764-40.833-99-40.833Q422-820 381-779.167 340-738.333 340-680v400q0 58.333 40.765 99.167 40.764 40.833 99 40.833ZM480-560q33 0 56.5-23.5T560-640q0-33-23.5-56.5T480-720q-33 0-56.5 23.5T400-640q0 33 23.5 56.5T480-560Zm0 80Zm0 240q33 0 56.5-23.5T560-320q0-33-23.5-56.5T480-400q-33 0-56.5 23.5T400-320q0 33 23.5 56.5T480-240Zm.088-35Q461-275 448-287.912q-13-12.913-13-32Q435-339 447.912-352q12.913-13 32-13Q499-365 512-352.088q13 12.913 13 32Q525-301 512.088-288q-12.913 13-32 13Z"
		/>
	</svg>
);
