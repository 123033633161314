import { ReactNode } from 'react';

export const symbol_transcribe: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m862-733 1-1 1-1q5-5 11-7.5t14-2.5q15 0 25 10t10 25q0 23-24 43t-24 76q0 29 10 51.5t28 42.5q5 5 7.5 11t2.5 14q0 15-10 25t-25 10q-8 0-15-3t-12-9q-26-28-41-65t-15-77q0-40 15-77t41-65ZM724-876l.5-.5.5-.5q5-5 11-7.5t14-2.5q24 0 33 21.5t-8 38.5q-48 51-73.5 104.5T676-591q0 78 25.5 131.5T775-355q5 5 7.5 11t2.5 14q0 15-10 25t-25 10q-8 0-14.5-3t-11.5-8q-55-58-86.5-126.5T606-591q0-90 31.5-158.5T724-876ZM360-441q-66 0-108-42t-42-108q0-66 42-108t108-42q66 0 108 42t42 108q0 66-42 108t-108 42ZM40-180v-34q0-38 19-64.5t49-41.5q51-26 120.5-43T360-380q62 0 131 17t120 43q30 15 49.5 41.5T680-214v34q0 25-17.5 42.5T620-120H100q-25 0-42.5-17.5T40-180Zm60 0h520v-34q0-16-8.5-29.5T587-266q-48-27-109-40.5T360-320q-57 0-118.5 14.5T132-266q-14 7-23 21.5t-9 30.5v34Zm260-321q39 0 64.5-25.5T450-591q0-39-25.5-64.5T360-681q-39 0-64.5 25.5T270-591q0 39 25.5 64.5T360-501Zm0-90Zm0 411Z"
		/>
	</svg>
);
