import { ReactNode } from 'react';

export const symbol_smb_share: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M457-430h181q24.323 0 41.161-16.773 16.839-16.772 16.839-41Q696-512 679.161-529.5 662.323-547 638-547h-12l-2-10q-4-31-27.776-52-23.775-21-56.467-21Q514-630 492-617q-22 13-30 36l-2 7h-8q-29 0-48.5 21.5T384-502.143q0 28.857 21.5 50.5T457-430ZM100-120q-24 0-42-18t-18-42v-540q0-12.75 8.675-21.375Q57.351-750 70.175-750 83-750 91.5-741.375T100-720v540h700q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T800-120H100Zm120-120q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h235q12.444 0 23.722 5t19.366 13.088L540-820h320q24 0 42 18t18 42v460q0 24-18 42t-42 18H220Zm0-60h640v-460H515l-60-60H220v520Zm0 0v-520 520Z"
		/>
	</svg>
);
