import { ReactNode } from 'react';

export const symbol_church: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M80-140v-210q0-17.905 9.5-32.452Q99-397 116-405l129-57v-94q0-17.493 9-31.563 9-14.071 24-22.437l172-88v-82h-50q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T400-840h50v-50q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-890v50h50q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T560-780h-50v82l172 88q15 8.366 24 22.437 9 14.07 9 31.563v94l129 57q17 8 26.5 22.548Q880-367.905 880-350v210q0 24.75-17.625 42.375T820-80H560q-12.75 0-21.375-8.625T530-110v-130q0-21.25-14.325-35.625Q501.351-290 480.175-290 459-290 444.5-275.625 430-261.25 430-240v130q0 12.75-8.625 21.375T400-80H140q-24.75 0-42.375-17.625T80-140Zm60 0h230v-102q0-46 32.118-79 32.117-33 78-33Q526-354 558-321q32 33 32 79v102h230v-214l-165-73v-133l-175-91-175 91v133l-165 73v214Zm340.059-285Q503-425 519-441.059q16-16.059 16-39T518.941-519q-16.059-16-39-16T441-518.941q-16 16.059-16 39T441.059-441q16.059 16 39 16ZM480-396Z"
		/>
	</svg>
);
