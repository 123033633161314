import { useState } from 'react';

import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import * as models from 'pkg/api/models';
import { useCurrentRoute } from 'pkg/router/hooks';
import { parse } from 'pkg/json';
import * as actions from 'pkg/actions';
import { LoginTypes, storeLoginInfo } from 'pkg/local_storage/login-info';

import { useOnboardingState } from 'routes/public/hooks';
import { OnboardingSlug, useOnboardingContext } from 'routes/public/onboarding';
import { GenericUnableToAuthError } from 'routes/public/steps/login/sso/redirect/error';

import { Spinner } from 'components/loaders/spinner';

export default function RegistrationSSORedirect() {
	const [state, setState] = useState<models.APIError>(null);
	const onboardingCtx = useOnboardingContext();
	const onboardingState = useOnboardingState();
	const account = onboardingState.get<models.onboardingInfo.Account>('account');
	const group = onboardingState.get<models.onboardingInfo.Group>('group');

	const { query } = useCurrentRoute();

	const parsedQueryState = parse(query?.state);
	const nextSlug = (parsedQueryState?.nextSlug as OnboardingSlug) || '';

	useComponentDidMount(async () => {
		const [req, resp, err] = await actions.sso.validate({
			code: query?.code as string,
		});

		if (!req.ok) {
			setState(err);
			onboardingCtx.overrideBackSlug('start');

			return;
		}

		const accountCopy = { ...account };

		// Check if we don't have anything filled in on first/last name then we use
		// the response information
		if (!accountCopy?.firstName) {
			accountCopy.firstName = resp.profile.firstName;
		}

		if (!accountCopy?.lastName) {
			accountCopy.lastName = resp.profile.lastName;
		}

		onboardingState.set({
			account: {
				...accountCopy,
				email: resp.profile.email,
				ssoToken: resp.token,
			},
		});

		storeLoginInfo(
			{
				firstName: accountCopy.firstName,
				lastName: accountCopy.lastName,
				email: resp.profile.email,
			},
			LoginTypes.SSO,
			parsedQueryState?.identityProvider as unknown as models.identityProvider.ScrubbedIdentityProvider,
			group.organizationId
		);

		onboardingCtx.goTo(nextSlug);
	});

	if (state) {
		return <GenericUnableToAuthError />;
	}

	return <Spinner />;
}
