import { ReactNode } from 'react';

export const symbol_book_3: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M290-80q-53.857 0-91.929-38.071Q160-156.143 160-210v-540q0-53.857 38.071-91.929Q236.143-880 290-880h450q24.75 0 42.375 17.625T800-820v515q0 9.143-5.5 16.571Q789-281 781-277q-20 8-30.5 26.523Q740-231.953 740-210q0 21.091 10.5 39.545Q761-152 781-144q10 4 14.5 13.5T800-110q0 12.439-8.625 21.22Q782.75-80 770-80H290Zm-70-240q16-10 33.609-15 17.608-5 36.391-5h450v-480H290q-29.167 0-49.583 20.417Q220-779.167 220-750v430Zm203-191h113l20 56q2 7 7.5 11t13.5 4q12 0 19-10t2-21l-88-234q-2-7-8-11t-14-4h-18q-8 0-14 4t-8 11l-88 235q-5 11 2.5 20.5T382-440q8 0 13.5-4t7.5-11l20-56Zm14-41 41-116h3l40 116h-84ZM220-320v-500 500Zm69.541 180H699q-9-15-14-33t-5-37q0-20 5-37.5t15-32.5H289.607Q261-280 240.5-259.583 220-239.167 220-210q0 29 20.5 49.5t49.041 20.5Z"
		/>
	</svg>
);
