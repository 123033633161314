import { t } from '@transifex/native';
import { normalize } from 'normalizr';

import Match from 'pkg/models/match';

import { getMatchById } from 'pkg/selectors/matches.selector';

import {
	MATCH_UPDATE_PROPERTY,
	ADD_MATCH_VIDEOS,
} from 'pkg/actions/action-types';
import { show } from 'pkg/actions/flashes';
import { matchVideosSchema } from 'pkg/actions/entities/video';
import * as services from 'pkg/actions/services/matches.service';

import store from 'pkg/store/createStore';
import * as sdk from 'pkg/core/sdk';

import { normalizedDispatch } from './utils';

const formatNumber = (number, defaultValue = null) =>
	isNaN(+number) ? defaultValue : Number.parseInt(number, 10);

const formatMatch = (match) => ({
	title: match.title,
	description: match.description,
	type: match.type,
	location: match.location.trim(),
	report: match.report ? match.report : null,
	overtime: match.overtime,
	notes: match.notes ? match.notes : null,
	halfTimeAddition: match.halfTimeAddition,
	fullTimeAddition: match.fullTimeAddition,
	goalCount: formatNumber(match.goalCount),
	goalCountHalfTime: formatNumber(match.goalCountHalfTime),
	opponentGoalCount: formatNumber(match.opponentGoalCount),
	opponentGoalCountHalfTime: formatNumber(match.opponentGoalCountHalfTime),
	opponentName: match.opponentName,
	externalId: match.externalId,
	startsAt: formatNumber(match.startsAt),
	isHome: match.isHome,
	duration: formatNumber(match.duration, 90),
	overtime: formatNumber(match.overtime, 0),
	matchCompetitionId: formatNumber(match.matchCompetitionId),
});

export const fetchMatchById = (matchId) => async (dispatch) => {
	const request = await services.fetchSingleMatch(matchId);
	const match = await request.json();

	if (!request.ok) {
		return;
	}

	dispatch(normalizedDispatch(match, Match.normalizr()));

	return match;
};

export const fetchUserLatestMatch = (userId, groupId) => async (dispatch) => {
	const request = await services.fetchUserLatestMatch(userId, {
		group_id: groupId,
	});
	const response = await request.json();

	if (!request.ok) {
		return;
	}

	dispatch(normalizedDispatch(response, Match.normalizr()));

	return response.id;
};

export const fetchMatches =
	(queryParams, urlOverride = '', autoFetch = true) =>
	async (dispatch) => {
		let request;

		if (urlOverride.length > 0) {
			request = await sdk.get(urlOverride);
		} else {
			request = await services.fetchGroupMatches(queryParams);
		}

		const matches = await request.json();

		if (!request.ok) {
			return;
		}

		if (matches.links?.next && autoFetch) {
			dispatch(fetchMatches({}, matches.links?.next));
		}

		dispatch(normalizedDispatch(matches.records, [Match.normalizr()]));

		// @NOTE When fetching matches for current video, make sure it dispatches correctly.
		if (queryParams.hasOwnProperty('video_id') && matches.records.length > 0) {
			let payload = {};

			Object.values(matches.records).map((match) => {
				payload[match.id] = {
					id: `mv:${match.id}`,
					matchId: match.id,
					videoId: queryParams.video_id,
					video: queryParams.video_id,
				};
			});

			payload = normalize(payload, [matchVideosSchema]);

			dispatch({
				type: ADD_MATCH_VIDEOS,
				payload: payload.entities.matchVideos,
			});
		}
	};

export const updateMatch =
	(matchId, showFlash = true) =>
	async (dispatch) => {
		const data = formatMatch(
			getMatchById(store.getState(), { matchId }).toJS()
		);

		data.goalCount =
			!data.goalCount && data.opponentGoalCount ? 0 : data.goalCount;
		data.opponentGoalCount =
			!data.opponentGoalCount && data.goalCount ? 0 : data.opponentGoalCount;

		const request = await services.patchMatch(matchId, data);
		const match = await request.json();

		if (request.ok) {
			dispatch(normalizedDispatch(match, Match.normalizr()));

			if (!showFlash) {
				return;
			}

			const message = t(`{match_title} has been updated`, {
				match_title: match.title,
			});

			show({
				title: t(`Updated`),
				message: message,
			});
		}
	};

const MATCH_PROPERTIES_INTEGERS_KEYS = [
	'goalCount',
	'goalCountHalfTime',
	'opponentGoalCount',
	'opponentGoalCountHalfTime',
	'matchCompetitionId',
	'duration',
	'overtime',
];

export const updateMatchProperty = (matchId, name, value) => (dispatch) => {
	if (MATCH_PROPERTIES_INTEGERS_KEYS.includes(name)) {
		value = Number.parseInt(value, 10);
	}

	if (Number.isNaN(value)) {
		value = 0;
	}

	dispatch({
		type: MATCH_UPDATE_PROPERTY,
		matchId,
		name,
		value,
	});
};

export const increaseScore = (matchId, isOpponent) => (dispatch, getState) => {
	const events = getState().matchEvents.entities;

	const matchGoals = events.filter(
		(event) =>
			event.matchId === matchId &&
			event.type === 'goal' &&
			event.isOpponent === isOpponent
	);

	dispatch({
		type: MATCH_UPDATE_PROPERTY,
		matchId,
		name: isOpponent ? 'opponentGoalCount' : 'goalCount',
		value: matchGoals.size + 1,
	});
};

export const decreaseScore = (matchId, isOpponent) => (dispatch, getState) => {
	const events = getState().matchEvents.entities;

	const matchGoals = events.filter(
		(event) =>
			event.matchId === matchId &&
			event.type === 'goal' &&
			event.isOpponent === isOpponent
	);

	dispatch({
		type: MATCH_UPDATE_PROPERTY,
		matchId,
		name: isOpponent ? 'opponentGoalCount' : 'goalCount',
		value: matchGoals.size - 1,
	});
};
