import { ReactNode } from 'react';

export const symbol_downloading: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M392-89q-137-32-224.5-141T80-480q0-141 87.5-250T392-870q14-3 25 6t11 23q0 11-6 19t-17 10q-117 26-191 119t-74 213q0 120 74 213t191 119q11 2 17 10t6 19q0 14-11 23.5T392-89Zm176-1q-14 4-25-5.5T532-119q0-11 6-19t16-10q29-7 56-17.5t52-27.5q8-5 18-3.5t17 8.5q11 11 9.5 24.5T693-142q-29 18-60.5 31T568-90Zm204-172q-8-7-9.5-17.5T767-298q16-25 27-51.5t18-55.5q2-10 10-16t19-6q14 0 23 11t6 25q-8 33-21 64.5T818-266q-8 12-21.5 13.5T772-262Zm69-270q-11 0-19.5-6T811-554q-6-29-17-55.5T767-661q-6-9-4.5-19t9.5-18q10-10 24-8.5t22 13.5q18 29 31 60.5t21 64.5q3 14-6 25t-23 11ZM662-767q-25-16-52-27t-56-18q-10-2-16-10t-6-19q0-14 11-23t25-6q33 8 64.5 20.5T693-818q12 8 13.5 22t-8.5 24q-8 8-18 9.5t-18-4.5ZM510-396l106-106q9-9 21-8.5t21 9.5q9 9 9 21.5t-9 21.5L501-301q-9 9-21 9t-21-9L301-459q-9-9-8.5-21.5T302-502q9-9 21.5-9t21.5 9l105 106v-254q0-13 8.5-21.5T480-680q13 0 21.5 8.5T510-650v254Z"
		/>
	</svg>
);
