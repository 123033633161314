import { t } from '@transifex/native';
import { Fragment } from 'react';

import * as models from 'pkg/api/models';
import {
	useCurrentAccountPendingWards,
	useCurrentMemberships,
	useCurrentOrganization,
} from 'pkg/identity';
import { AccountRelationTypes } from 'pkg/api/models/account_relation';

import SectionTitle from 'components/SectionTitle';
import Badge from 'components/Badge';
import Label from 'components/label';
import Avatar from 'components/avatar';

import Column from 'components/layout/column';
import Row from 'components/layout/row';

import * as Card from 'design/card';

import * as css from './styles.css';

interface PendingChildRequestProps {
	accountRelation: models.accountRelation.AccountRelation;
}

function PendingChildRequest({
	accountRelation,
}: PendingChildRequestProps): JSX.Element {
	const label =
		accountRelation.type === AccountRelationTypes.LegalGuardian
			? t('Pending child request')
			: t('Pending parent request');

	return (
		<Row columns="35px 1fr auto" align="center" justifyContent="start">
			<Avatar account={accountRelation.targetAccount} />
			<span
				data-testid="club_lobby.pending_child.child_name"
				className={css.name}>
				{models.account.fullName(accountRelation.targetAccount)}
			</span>
			<Label color="gray">{label}</Label>
		</Row>
	);
}

interface PendingMembershipProps {
	membership: models.membership.Membership;
}

function PendingMembership({
	membership,
}: PendingMembershipProps): JSX.Element {
	return (
		<Row columns="35px 1fr auto" align="center" justifyContent="start">
			<Badge badgeUrl={membership.group.profileImageUrl} />
			<span
				data-testid="club_lobby.pending_membership.group_name"
				className={css.name}>
				{membership.group.name}
			</span>
			<Label color="gray">{t('Pending staff request')}</Label>
		</Row>
	);
}

export default function PendingRequests(): JSX.Element {
	const currentOrganizationId = useCurrentOrganization().id;
	const pendingWards = useCurrentAccountPendingWards();
	const pendingMembershipsInOrganization =
		useCurrentMemberships()?.filter(
			(membership) =>
				models.membership.isPending(membership) &&
				!models.group.isOrganization(membership.group) &&
				membership.group.organizationId === currentOrganizationId
		) || [];

	if (
		pendingWards.length === 0 &&
		pendingMembershipsInOrganization.length === 0
	) {
		return null;
	}

	return (
		<Column>
			<SectionTitle>{t('Your pending requests')}</SectionTitle>
			<Card.Base $noBorder>
				{pendingWards.map(
					(
						accountRelation: models.accountRelation.AccountRelation,
						index: number
					) => (
						<Fragment key={index}>
							{index !== 0 && <Card.Divider />}
							<Card.Body $narrowBody>
								<PendingChildRequest
									key={index}
									accountRelation={accountRelation}
								/>
							</Card.Body>
						</Fragment>
					)
				)}

				{pendingWards.length > 0 &&
					pendingMembershipsInOrganization.length > 0 && <Card.Divider />}

				{pendingMembershipsInOrganization.map(
					(membership: models.membership.Membership, index: number) => (
						<Fragment key={index}>
							{index !== 0 && <Card.Divider />}
							<Card.Body $narrowBody>
								<PendingMembership key={index} membership={membership} />
							</Card.Body>
						</Fragment>
					)
				)}
			</Card.Base>
		</Column>
	);
}
