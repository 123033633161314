import { t } from '@transifex/native';
import styled from 'styled-components';
import { useState } from 'react';

import { useCurrentOrganization } from 'pkg/identity';
import { TrainingCollection } from 'pkg/api/models/training_collection';
import * as models from 'pkg/api/models';
import * as actions from 'pkg/actions';
import { useEventDispatcher } from 'pkg/hooks/events';

import { useAggregatedCollections } from 'routes/training/library/hooks';

import RelativeDateTime from 'components/RelativeDateTime';
import * as StepModal from 'components/step-modal';

import * as Input from 'components/form/inputs';

import * as Table from 'design/table';

const Collection = styled.div`
	display: grid;
	grid-template-rows: repeat(2, auto);
	gap: var(--spacing-2);
	font-size: var(--font-size-sm);

	span {
		font-weight: var(--font-weight-semibold);
	}
`;

interface AddToCollectionProps {
	exerciseId?: number;
	sessionId?: number;
	onClose: () => void;
}

export default function AddToCollection({
	exerciseId,
	sessionId,
	onClose,
}: AddToCollectionProps): JSX.Element {
	const org = useCurrentOrganization();
	const collections = useAggregatedCollections();
	const [selectedCollections, setSelectedCollections] = useState<number[]>([]);

	const dispatchTrainingCollectionSaved = useEventDispatcher(
		'training-collection-saved'
	);

	const validCollections = collections.records.filter(
		(collection: TrainingCollection) => models.canEdit(collection)
	);

	const allSelected = selectedCollections.length === validCollections.length;

	const handleToggle = (collectionId: number) => {
		if (selectedCollections.includes(collectionId)) {
			setSelectedCollections(
				selectedCollections.filter((id: number) => id !== collectionId)
			);
		} else {
			setSelectedCollections([...selectedCollections, collectionId]);
		}
	};

	const handleToggleAll = () => {
		if (allSelected) {
			setSelectedCollections([]);
		} else {
			setSelectedCollections(
				collections.records.map((c: TrainingCollection) => c.id)
			);
		}
	};

	const save = async () => {
		if (exerciseId) {
			await Promise.all([
				selectedCollections.map((collectionId: number) =>
					models.trainingCollection.addExercise(
						org.id,
						collectionId,
						exerciseId
					)
				),
			]);
		}

		if (sessionId) {
			await Promise.all([
				selectedCollections.map((collectionId: number) =>
					models.trainingCollection.addSession(org.id, collectionId, sessionId)
				),
			]);
		}

		actions.flashes.show({
			title: t(`Added video to {num} collections`, {
				num: selectedCollections.length,
			}),
		});

		dispatchTrainingCollectionSaved();
	};

	const columns = [
		{
			content: (
				<Input.Control standalone type="checkbox" checked={allSelected} />
			),
			width: 'max-content',
			onClick: handleToggleAll,
		},
		{
			content: t(`Collection`),
		},
		{
			content: t(`Exercises`),
			align: 'center',
		},
		{
			content: t(`Sessions`),
			align: 'center',
		},
		{
			content: t(`Shared with`),
			align: 'right',
		},
	];

	return (
		<StepModal.Base onClose={onClose}>
			<StepModal.Step
				skipBody
				slug="addToCollection"
				title={t(`Add to collection`)}
				nextLabel={t('Add')}
				canGoNext={selectedCollections.length > 0}
				onNext={save}
				closeOnNext>
				<Table.Table columns={columns}>
					{validCollections.map((collection: TrainingCollection) => {
						let sharedWith = t(`Only you`);

						if (collection.groupId && collection.inherit) {
							sharedWith = t(`Club`);
						} else if (collection.groupId) {
							sharedWith = t(`Team`);
						}

						return (
							<Table.Row key={collection.id}>
								<Table.Cell>
									<Input.Control
										standalone
										type="checkbox"
										onChange={handleToggle}
										value={collection.id}
										checked={selectedCollections.includes(collection.id)}
									/>
								</Table.Cell>
								<Table.Cell>
									<Collection>
										<span>{collection.name}</span>
										<RelativeDateTime dateTime={collection.createdAt} />
									</Collection>
								</Table.Cell>
								<Table.Cell align="center">
									{collection.exerciseCount}
								</Table.Cell>
								<Table.Cell align="center">
									{collection.sessionCount}
								</Table.Cell>
								<Table.Cell align="right">{sharedWith}</Table.Cell>
							</Table.Row>
						);
					})}
				</Table.Table>
			</StepModal.Step>
		</StepModal.Base>
	);
}
