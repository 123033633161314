import { ReactNode } from 'react';

export const symbol_chrome_reader_mode: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm0-60h310v-520H140v520Zm370 0h310v-520H510v520Zm242-138q10.833 0 17.917-7.116 7.083-7.117 7.083-18Q777-394 769.917-401q-7.084-7-17.917-7H579q-10.833 0-17.917 7.116-7.083 7.117-7.083 18Q554-372 561.083-365q7.084 7 17.917 7h173Zm0-109q10.833 0 17.917-7.116 7.083-7.117 7.083-18Q777-503 769.917-510q-7.084-7-17.917-7H579q-10.833 0-17.917 7.116-7.083 7.117-7.083 18Q554-481 561.083-474q7.084 7 17.917 7h173Zm0-109q10.833 0 17.917-7.116 7.083-7.117 7.083-18Q777-612 769.917-619q-7.084-7-17.917-7H579q-10.833 0-17.917 7.116-7.083 7.117-7.083 18Q554-590 561.083-583q7.084 7 17.917 7h173ZM140-220v-520 520Z"
		/>
	</svg>
);
