import { ReactNode } from 'react';

export const symbol_system_update: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M260-40q-24 0-42-18t-18-42v-760q0-24 18-42t42-18h440q24 0 42 18t18 42v760q0 24-18 42t-42 18H260Zm0-90v30h440v-30H260Zm0-60h440v-580H260v580Zm190-244v-164q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-598v164l61-61q9-9 21.1-9 12.1 0 20.9 9 9 9 9 21.5t-9 21.5L501-339q-9 9-21 9t-21-9L346-452q-9-9-8.5-21.5T347-495q9-9 21.5-9t21.5 9l60 61ZM260-830h440v-30H260v30Zm0 0v-30 30Zm0 700v30-30Z"
		/>
	</svg>
);
