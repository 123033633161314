import { useMemo } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import { getTimeZoneFromString, getGmtString } from 'pkg/timezone';
import { getEventDate } from 'pkg/date';
import * as routes from 'pkg/router/routes';
import DateTime, { Granularity } from 'pkg/datetime';
import RouterLink from 'pkg/router/Link';
import * as models from 'pkg/api/models';
import { useCurrentOrganization } from 'pkg/identity';
import { EventTypes } from 'pkg/api/models/event';

import { DescriptionRow } from 'routes/event/components/DescriptionRow';
import TimezoneTime from 'routes/group/calendar/components/events/TimezoneTime';
import EventLocation from 'routes/event/components/location';

import { FormattedContent } from 'components/formatted-content';
import Icon from 'components/icon';
import Avatar from 'components/avatar';

import Column from 'components/layout/column';
import UserLink from 'components/user/link';
import GalleryPreview from 'components/attachment/gallery/preview';

import * as css from './styles.css';

interface DescriptionProps {
	event: models.event.Event;
	currentGroupMemberships: models.membership.Membership[];
}

export default function Description({
	event,
	currentGroupMemberships,
}: DescriptionProps): JSX.Element {
	const org = useCurrentOrganization();
	const match = event.matches?.[0];
	const startsAt = new DateTime(new Date(event.startsAt * 1000));
	const endsAt = new DateTime(new Date(event.endsAt * 1000));
	const meetingTime = startsAt.prev(
		Granularity.minute,
		event.meetBeforeMinutes
	);
	const showTimezone = useMemo(
		() => models.event.timezoneIsDifferent(event),
		[event?.timezone]
	);
	const isMatchEvent = event.type === EventTypes.Match;

	const competitions = event.matches
		?.filter((m) => m.matchCompetitionId !== null)
		.map((m) => m.competition);

	const createdByUser = event?.user;
	const eventDate = getEventDate(event.startsAt, event.endsAt);

	const matchStart = () => {
		if (!isMatchEvent || !match?.startsAt || !match) {
			return null;
		}

		const matchStart = new DateTime(new Date(match.startsAt * 1000));
		const matchStartTime = matchStart.toTimeString();
		const timezoneString = getGmtString(getTimeZoneFromString(event.timezone));

		const eventTimezoneKickoff = showTimezone ? (
			<small>{`${matchStart.toLocaleTimeString({
				hour: 'numeric',
				minute: 'numeric',
				timeZone: event.timezone,
			})} ${timezoneString}`}</small>
		) : null;

		return (
			<DescriptionRow>
				<Icon name="whistle" />
				<div>
					{t('Kickoff time: {time}', {
						time: matchStartTime,
					})}

					{eventTimezoneKickoff}
				</div>
			</DescriptionRow>
		);
	};

	const eventSeries = () => {
		if (!models.event.hasEventSeries(event)) {
			return null;
		}

		return (
			<DescriptionRow>
				<Icon name="reload" />
				<div>
					{t('You are viewing an instance of a series.')}{' '}
					<RouterLink
						className={css.seriesLink}
						href={routes.EventSeries.View(org.id, event.eventSeriesId)}>
						{t('Show series')}
					</RouterLink>
				</div>
			</DescriptionRow>
		);
	};

	const matchLocation = () => {
		if (!isMatchEvent || match?.location === event.location || !match) {
			return null;
		}

		return (
			<DescriptionRow>
				<Icon name="nav-formation" />
				<div>{match.location}</div>
			</DescriptionRow>
		);
	};

	const matchCompetitions = () => {
		if (!isMatchEvent || competitions?.length === 0) {
			return null;
		}

		return (
			<DescriptionRow>
				<Icon name="trophy" />
				<div>
					{competitions?.map((competition, i) => (
						<span key={`competition-${i}`}>
							{+i > 0 && ', '}
							{competition.title}
						</span>
					))}
				</div>
			</DescriptionRow>
		);
	};

	const createdBy = () => {
		if (!createdByUser?.id) {
			return null;
		}

		return (
			<DescriptionRow>
				<Avatar user={createdByUser} size={24} />

				<div>
					{t('Created by')}{' '}
					<UserLink
						user={createdByUser}
						memberships={currentGroupMemberships}
					/>
				</div>
			</DescriptionRow>
		);
	};

	const privateEvent = () => {
		if (!event.isPrivate) {
			return null;
		}

		return (
			<DescriptionRow>
				<Icon name="eye-hidden" />
				<div>{t('This event is only visible to invited users')}</div>
			</DescriptionRow>
		);
	};

	return (
		<Column spacing={styles.spacing._5}>
			{privateEvent()}
			<DescriptionRow>
				<Icon name="nav-events" />
				<div>
					{eventDate}{' '}
					{showTimezone && (
						<TimezoneTime
							timezone={event.timezone}
							starts={startsAt}
							ends={endsAt}
						/>
					)}
				</div>
			</DescriptionRow>
			{event.meetBeforeMinutes > 0 && (
				<DescriptionRow>
					<Icon name="clock" />
					<div>
						{t('Meet time {time}', {
							time: meetingTime.toTimeString(),
						})}
						{showTimezone && (
							<TimezoneTime timezone={event.timezone} starts={meetingTime} />
						)}
					</div>
				</DescriptionRow>
			)}

			<EventLocation item={event} />

			{eventSeries()}

			{matchLocation()}

			{matchStart()}

			{matchCompetitions()}

			{event.description && (
				<DescriptionRow iconTop>
					<Icon name="note" />
					<FormattedContent raw={event.description} />
				</DescriptionRow>
			)}

			{createdBy()}

			{event.attachments?.length > 0 && (
				<GalleryPreview
					tight
					attachments={event.attachments}
					disableGallery={false}
				/>
			)}
		</Column>
	);
}
