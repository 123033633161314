import { ReactNode } from 'react';

export const symbol_offline_pin_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M350-280q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T350-340h270l60 60H350ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-64.175 19-125.088Q118-666 156-718l-74-74q-9-9.067-9-21.533Q73-826 82.053-835q9.052-9 21.5-9Q116-844 125-835l710 710q9 9 9 21t-9.053 21q-9.052 9-21.5 9Q801-74 792-83l-74-73q-52 38-112.912 57Q544.175-80 480-80Zm195-119L199-675q-29 44-44 93.528Q140-531.944 140-480q0 142.375 98.812 241.188Q337.625-140 480-140q51.944 0 101.472-15Q631-170 675-199ZM553-553ZM408-408Zm231-245.161q9 8.84 9 22.5Q648-617 639-608l-78 78q-8.8 9-22.4 9-13.6 0-22.6-8.839-9-8.84-9-22.5Q507-566 516-575l78-78q9.209-9 22.605-9Q630-662 639-653.161ZM275-824q47-28 98.63-42 51.63-14 106.37-14 83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 54.394-13.5 105.697Q853-323 827-277q-6 11-18 13.5t-23-4.5q-11-7-13-19.5t4-23.5q21-39 32-81.5t11-87.5q0-142.375-98.812-241.188Q622.375-820 480-820q-45 0-88 11t-82 33q-11 6-22.5 4T268-784q-8-10-6-22t13-18Z"
		/>
	</svg>
);
