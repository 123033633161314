import { Record } from 'immutable';

import { matchEventsSchema } from 'pkg/models/schemas';

export default class extends Record({
	id: null,
	matchId: null,
	leadingMatchEventId: null,
	substitutionUserId: null,
	authorId: null,
	userId: null,
	positionId: null,
	type: null,
	isReviewed: true,
	isOpponent: false,
	comment: '',
	minute: null,
	extraMinute: null,
	x: null,
	y: null,

	user: null,

	guid: null,
	leadingGuid: null,
	placeholder: null,
}) {
	static normalizr() {
		return matchEventsSchema;
	}
}
