import { ReactNode } from 'react';

export const symbol_chat_error: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m480-518 83 83q9 9 21 9t21-9q9-9 9-21t-9-21l-83-83 83-83q9-9 9-21t-9-21q-9-9-21-9t-21 9l-83 83-83-83q-9-9-21-9t-21 9q-9 9-9 21t9 21l83 83-83 83q-9 9-9 21t9 21q9 9 21 9t21-9l83-83ZM240-240 131-131q-14 14-32.5 6.344T80-152v-668q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H240Zm-26-60h606v-520H140v600l74-80Zm-74 0v-520 520Z"
		/>
	</svg>
);
