import styled, { css } from 'styled-components';

import * as palette from 'pkg/config/palette';

import rgba from 'pkg/rgba';
import Link from 'pkg/router/Link';

/**
 * @type {React.Element<any>}
 */

export const Wrapper = styled.div`
	margin-bottom: 15px;
	grid-template-columns: 30px 1fr;
	grid-template-areas: 'avatar container';
	grid-gap: 0.5rem;
	display: grid;

	&:last-child {
		margin-bottom: 0;
	}

	font-size: var(--font-size-sm);

	${(props) =>
		props.isEditing &&
		css`
			display: block;
		`}
`;

export const AuthorAvatar = styled.div`
	grid-area: 'avatar';
`;

export const Container = styled.div`
	grid-area: 'container';
`;

export const Body = styled.div`
	font-size: var(--font-size-sm);
	margin: 0;
	padding: 0.5rem 0.7rem;
	display: inline-block;
	background: var(--palette-gray-200);
	border-radius: 1rem;

	&:not([data-num-embeds='0']) {
		width: 100%;
		display: block;

		img,
		iframe {
			border-radius: var(--radius-4);
		}
	}
`;

export const Actions = styled.div`
	margin-top: 4px;
	display: grid;
	grid-auto-flow: column;
	align-items: start;
	justify-content: start;
	grid-gap: var(--spacing-2);
`;

export const Timestamp = styled.time`
	margin-left: var(--spacing-2);
	display: inline-block;
	font-size: var(--font-size-xs);
	color: ${rgba(palette.card.timestampColor)};

	&::after {
		margin-left: var(--spacing-1);
		content: '•';
		color: ${rgba(palette.gray[400])};
		display: inline-block;
	}
`;

export const Action = styled.span`
	text-decoration: none;
	display: inline-block;
	font-size: var(--font-size-xs);
	cursor: pointer;

	&::after {
		margin-left: var(--spacing-2);
		content: '•';
		color: ${rgba(palette.gray[400])};
		display: inline-block;
	}

	&:last-child::after {
		display: none;
	}

	&:link {
		color: ${rgba(palette.card.timestampColor, 0.7)};
	}

	@media (hover: hover) {
		&:hover {
			color: ${rgba(palette.card.timestampColor, 0.7)};
		}
	}
`;

export const AuthorLink = styled(Link)`
	margin-right: 0.3rem;
	display: inline;
	margin-bottom: 0;
	font-size: var(--font-size-sm);
	font-weight: var(--font-weight-semibold);

	&:link {
		color: ${rgba(palette.main.actionableLinkColor)};
	}

	@media (hover: hover) {
		&:hover {
			color: ${rgba(palette.main.actionableLinkColor, 0.7)};
		}
	}
`;
