import { t } from '@transifex/native';
import { useMediaQuery } from 'react-responsive';

import spacing from 'pkg/config/spacing';
import { breakpoint } from 'pkg/config/styles';

import { chunk } from 'pkg/arrays';

import { FormattedContent } from 'components/formatted-content';
import SectionTitle from 'components/SectionTitle';

import Column from 'components/layout/column';
import Row from 'components/layout/row';

const _context = 'payments dashboard help';

interface Section {
	heading: string;
	description: string;
}

const sections: Section[] = [
	{
		heading: t('Created invoices', { _context }),
		description: t(
			'Created invoices displays the current status of all invoices that have been created through you sending invoices, or payments being generated through checkouts and registrations.',
			{ _context }
		),
	},
	{
		heading: t('Invoice status', { _context }),
		description: t(
			'Invoice status is a visual breakdown of the distribution of the status of all invoices created in your filtered date span.',
			{ _context }
		),
	},
	{
		heading: t('Paid on time', { _context }),
		description: t(
			'Displays the distribution of paid invoices that have been paid on time, as well as paid invoices that were past their due date.',
			{ _context }
		),
	},
	{
		heading: t('Average payment time', { _context }),
		description: t(
			'A breakdown of the time it takes for each invoice to be paid, based on previously paid invoices. The time breakdown shows how this time has trended for earlier periods based on your current date filters.',
			{ _context }
		),
	},
	{
		heading: t('Average payments overdue', { _context }),
		description: t(
			'A breakdown of the average time your past due invoices have been overdue, based on previously paid invoices, and current ones that are overdue. The time breakdow shows how this time has trended for earlier periods based on your current date filters.',
			{ _context }
		),
	},
	{
		heading: t('Payments', { _context }),
		description: t(
			'The amount and total volume of payments received for the filtered time period.',
			{ _context }
		),
	},
	{
		heading: t('Refund ratio', { _context }),
		description: t(
			'The percentage of refunds that have been made in the current date span, compared to all sent invoices for the same span.',
			{ _context }
		),
	},
	{
		heading: t('New subscriptions', { _context }),
		description: t(
			'All new subscriptions that have been created within the filtered date span.',
			{ _context }
		),
	},
	{
		heading: t('Total net', { _context }),
		description: t(
			'The actual cashflow of the filtered date period, meaning all paid transactions minus all the refunds that have been made.',
			{ _context }
		),
	},
	{
		heading: t('Subscriptions', { _context }),
		description: t(
			'A breakdown of your active subscriptions and how they have changed during the filtered date span.',
			{ _context }
		),
	},
];

export default function Help(): JSX.Element {
	const [colA, colB] = chunk<Section>(sections, 5);
	const isSmallScreen = useMediaQuery({
		maxWidth: breakpoint.toMedium,
	});

	return (
		<Column spacing={spacing._7}>
			<FormattedContent
				raw={t(
					"Familiarize yourself with what you're looking at on the payments report overview.",
					{ _context }
				)}
			/>

			<Row
				collapseOnSmallScreens
				align="stretch"
				spacing={isSmallScreen ? spacing._5 : spacing._8}>
				<Column spacing={spacing._5}>
					{colA.map(({ heading, description }: Section, index: number) => (
						<Column key={index} spacing={spacing._2}>
							<SectionTitle>{heading}</SectionTitle>
							<FormattedContent raw={description} />
						</Column>
					))}
				</Column>
				<Column spacing={spacing._5}>
					{colB.map(({ heading, description }: Section, index: number) => (
						<Column key={index} spacing={spacing._2}>
							<SectionTitle>{heading}</SectionTitle>
							<FormattedContent raw={description} />
						</Column>
					))}
				</Column>
			</Row>
		</Column>
	);
}
