import { ReactNode } from 'react';

export const symbol_work_update: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-180v-21.75V-180v-480 480Zm0 60q-24 0-42-18t-18-42v-480q0-24 18-42t42-18h180v-100q0-23 18-41.5t42-18.5h200q24 0 42 18.5t18 41.5v100h180q24 0 42 18t18 42v177q0 12.641-8.675 20.82-8.676 8.18-21.5 8.18-12.825 0-21.325-8.625T820-484v-176H140v480h304q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T444-120H140Zm240-600h200v-100H380v100ZM720-47q-79 0-136-57t-57-136q0-79 57-136t136-57q79 0 136 57t57 136q0 79-57 136T720-47Zm-15-136-67-67q-5-5-10-5t-10 5q-5 5-5 10.5t5 10.5l81 82q9 9 21 9t21-9l82-82q5-5 5-10.5t-5-10.5q-5-5-10.5-5t-10.5 5l-67 67v-156q0-6-4.5-10.5T720-354q-6 0-10.5 4.5T705-339v156Z"
		/>
	</svg>
);
