import { ReactNode } from 'react';

export const symbol_devices_other: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-160q-24.75 0-42.375-17.625T80-220v-520q0-24.75 17.625-42.375T140-800h630q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T770-740H140v520h130q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T270-160H140Zm309.882-80Q479-240 499.5-260.382q20.5-20.383 20.5-49.5Q520-339 499.618-359.5q-20.383-20.5-49.5-20.5Q421-380 400.5-359.618q-20.5 20.383-20.5 49.5Q380-281 400.382-260.5q20.383 20.5 49.5 20.5ZM850-160H630q-12.75 0-21.375-8.625T600-190v-380q0-12.75 8.625-21.375T630-600h220q12.75 0 21.375 8.625T880-570v380q0 12.75-8.625 21.375T850-160Zm-190-60h160v-320H660v320Zm-280 30v-35q-18-15-29-37t-11-48q0-26 11-48t29-37v-35q0-12.75 8.625-21.375T410-460h80q12.75 0 21.375 8.625T520-430v35q18 15 29 37t11 48q0 26-11 48t-29 37v35q0 12.75-8.625 21.375T490-160h-80q-12.75 0-21.375-8.625T380-190Zm280-30h160-160Z"
		/>
	</svg>
);
