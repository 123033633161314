import ProductTable from 'routes/payments/products/list/ProductTable';

interface ArchivedProps {
	organizationId: number;
}

const Archived: React.FC<React.PropsWithChildren<ArchivedProps>> = ({
	organizationId,
}) => <ProductTable organizationId={organizationId} archived />;

export default Archived;
