import { ReactNode } from 'react';

export const symbol_trip: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-120q-24.75 0-42.375-17.625T80-180v-480q0-24.75 17.625-42.375T140-720h180v-100q0-24.75 17.625-42.375T380-880h200q24.75 0 42.375 17.625T640-820v100h180q24.75 0 42.375 17.625T880-660v480q0 24.75-17.625 42.375T820-120H140Zm240-600h200v-100H380v100Zm-133 60H140v480h107v-480Zm407 480v-480H307v480h347Zm60-480v480h106v-480H714ZM480-425Z"
		/>
	</svg>
);
