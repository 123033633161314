import { ReactNode } from 'react';

export const symbol_oil_barrel: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M160-120q-12 0-21-9t-9-21.5q0-12.5 9-21t21-8.5h40v-270h-50q-12 0-21-9t-9-21.5q0-12.5 9-21t21-8.5h50v-270h-40q-12 0-21-9t-9-21.5q0-12.5 9-21t21-8.5h640q12.75 0 21.375 8.625T830-810q0 12-8.625 21T800-780h-40v270h50q12.75 0 21.375 8.625T840-480q0 12-8.625 21T810-450h-50v270h40q12.75 0 21.375 8.625T830-150q0 12-8.625 21T800-120H160Zm100-60h440v-270q-12 0-21-9t-9-21.5q0-12.5 9-21t21-8.5v-270H260v270q12.75 0 21.375 8.625T290-480q0 12-8.625 21T260-450v270Zm219.882-153q45.883 0 78-31.5Q590-396 590-440.739 590-459 585.5-472T569-502l-66-78q-9.061-11-23.031-11Q466-591 457-580l-66 77q-12 17-16.5 30.284-4.5 13.285-4.5 31.608 0 44.892 32 76.5Q434-333 479.882-333ZM260-180v-600 600Z"
		/>
	</svg>
);
