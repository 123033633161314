import { cloneElement, ElementType, ReactNode } from 'react';

import Link from 'pkg/router/Link';
import { cssClasses, cssVarList } from 'pkg/css/utils';

import * as Card from 'components/Card';
import Icon, { IconName } from 'components/icon';

import * as css from './styles.css';

interface Children {
	children: ReactNode;
}

interface ActionsProps extends Children {
	justify?: 'start' | 'center' | 'end';
}

export function Actions({ children, justify }: ActionsProps) {
	return (
		<div
			className={css.actions}
			style={cssVarList({
				justify,
			})}>
			{children}
		</div>
	);
}

interface TextContentProps extends Children {
	align?: 'start' | 'center' | 'end';
	className?: string;
}

export function TextContent({ children, align, className }: TextContentProps) {
	return (
		<div
			className={cssClasses(css.textContent, className)}
			style={cssVarList({
				align,
			})}>
			{children}
		</div>
	);
}

interface SmallTextProps extends Children {
	className?: string;
}

export function SmallText({ children }: SmallTextProps) {
	return <span className={cssClasses(css.smallText)}>{children}</span>;
}

interface TitleProps extends Children {
	className?: string;
}

export function Title({ children, className }: TitleProps) {
	return (
		<TextContent className={cssClasses(css.title, className)}>
			{children}
		</TextContent>
	);
}

interface SubTitleProps extends Children {
	className?: string;
}

export function SubTitle({ children, className }: SubTitleProps) {
	return (
		<span className={cssClasses(css.subTitle, className)}>{children}</span>
	);
}

export function Col({ children, align }: TextContentProps) {
	return (
		<TextContent className={css.col} align={align}>
			{children}
		</TextContent>
	);
}

export function Image({ children }: Children) {
	return <div className={css.image}>{children}</div>;
}

interface IconWrapperProps extends Children {
	caution?: boolean;
}

export function IconWrapper({ caution, children }: IconWrapperProps) {
	return (
		<div className={cssClasses(css.iconWrapper, caution ? css.caution : '')}>
			{children}
		</div>
	);
}

interface BaseProps {
	children: JSX.Element[];
	className?: string;
	path?: string;
	href?: string;
	to?: string;
	params?: { [key: string]: any };
	cautious?: boolean;
	noBorder?: boolean;
	columns?: string;
	onClick?: () => void;
	label?: {
		text: string;
		icon?: IconName;
	};
	testid?: string;
}

export const Base: React.FC<React.PropsWithChildren<BaseProps>> = ({
	children,
	className,
	path,
	href,
	to,
	params,
	noBorder,
	label,
	cautious = false,
	onClick,
	columns,
	testid,
	...props
}) => {
	const name = children.find((child) => child.type === Title);
	const cols = children.filter((child) => child.type === Col);
	const actions = children.find((child) => child.type === Actions);
	const image = children.find((child) => child.type === Image);
	const icon = children.find((child) => child.type === IconWrapper);

	const clonedIconWrapper =
		icon && cautious ? cloneElement(icon, { caution: cautious }) : icon;

	let setColumns = '1fr auto';

	if (cols.length > 0 && !columns) {
		setColumns = '1fr auto auto';
	} else if (columns) {
		setColumns = columns;
	}

	const linkProps: {
		to?: string;
		params?: { [key: string]: any };
		as?: ElementType;
		href?: string;
		isLink?: boolean;
	} = {};

	if (to && params) {
		linkProps.to = to;
		linkProps.params = params;
		linkProps.as = Link;
		linkProps.isLink = true;
	}

	if (href || path) {
		linkProps.href = href || path;
		linkProps.as = Link;
		linkProps.isLink = true;
	}

	const component = (
		<div
			data-testid={testid}
			onClick={onClick}
			className={cssClasses(
				css.wrapper,
				cautious ? css.cautious : '',
				noBorder ? css.noBorder : '',
				onClick ? css.clickable : ''
			)}>
			{label && (
				<div
					className={cssClasses(
						css.labelWrapper,
						cautious ? css.cautious : ''
					)}>
					{label.icon && <Icon name={label.icon} />}
					{label.text}
				</div>
			)}
			<Card.Base
				{...props}
				className={cssClasses(
					css.baseStyle,
					label ? css.hasLabel : '',
					className
				)}
				style={cssVarList({
					columns: setColumns,
				})}>
				<div className={css.info}>
					{clonedIconWrapper}
					{image}
					{name}
				</div>
				{cols.length > 0 && <div className={css.columns}>{cols}</div>}
				{actions}
			</Card.Base>
		</div>
	);

	if (to && params) {
		return (
			<Link to={to} params={params}>
				{component}
			</Link>
		);
	}

	if (href || path) {
		return <Link href={href || path}>{component}</Link>;
	}

	return component;
};
