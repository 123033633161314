import { ReactNode } from 'react';

export const symbol_surround_sound: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M482.824-368Q529-368 561.5-400.324q32.5-32.323 32.5-78.5Q594-525 561.677-557.5 529.353-590 483.176-590 437-590 404.5-557.677 372-525.353 372-479.176 372-433 404.324-400.5q32.323 32.5 78.5 32.5ZM694-479q0 35-11.5 66.5T651-353q-8 11-7.5 23t9.5 21q9 9 21 9t20-10q28.929-36 44.464-79Q754-432 754-479t-15.536-90Q722.929-612 694-648q-8-10-20-10t-21 9q-9 9-9.5 21t7.5 23q20 28 31.5 59.5T694-479Zm-422 0q0-35 11.5-66.5T315-605q8-11 7.5-23t-9.5-21q-9-9-21.5-9T271-648q-29 36-44 79t-15 90q0 47 15 90t44 79q8 10 20.5 10t21.5-9q9-9 9.5-21t-7.5-23q-20-28-31.5-59.5T272-479ZM140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm0-60h680v-520H140v520Zm0 0v-520 520Z"
		/>
	</svg>
);
