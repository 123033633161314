import { ReactNode } from 'react';

export const symbol_battery_2_bar: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M310-80q-12.75 0-21.375-8.625T280-110v-676q0-12.75 8.625-21.375T310-816h90v-34q0-12.75 8.625-21.375T430-880h100q12.75 0 21.375 8.625T560-850v34h90q12.75 0 21.375 8.625T680-786v676q0 12.75-8.625 21.375T650-80H310Zm30-236h280v-440H340v440Z"
		/>
	</svg>
);
