import styled from 'styled-components';

import { generatePath } from 'components/annotations/AnnotationHooks';
import { ToolProps } from 'components/annotations/tools/ToolProps';
import { MULTIPOINT } from 'components/annotations/constants';
import { getPreferenceValue } from 'components/annotations/tools/ToolConfig';

const SVG = styled.svg`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;

export default function Line({
	annotationId,
	annotation,
	config,
	multipointRef,
	coverSize,
}: ToolProps): JSX.Element {
	const d = generatePath(annotation.keyframes[0].points, {
		raised: annotation.raised,
	});

	const strokeWidth =
		getPreferenceValue(annotation, 'strokeWidth') * (coverSize.width / 1000);

	const arrowScaling = 0.18 + strokeWidth / 30;

	return (
		<SVG
			viewBox="0 0 100 100"
			preserveAspectRatio="none"
			xmlns="http://www.w3.org/2000/SVG">
			<defs>
				<marker
					id={'arrowhead-start-' + annotationId}
					viewBox={`0 0 ${10 * arrowScaling} ${7 * arrowScaling}`}
					markerWidth={16 * arrowScaling}
					markerHeight={16 * arrowScaling}
					markerUnits="userSpaceOnUse"
					refX={2 * arrowScaling}
					refY={3.5 * arrowScaling}
					orient="auto">
					<polygon
						points={`${10 * arrowScaling} 0, 0 ${3.5 * arrowScaling}, ${
							10 * arrowScaling
						} ${7 * arrowScaling}`}
						fill={annotation.color || config.preferences.color.defaultValue}
						vectorEffect="non-scaling-stroke"
					/>
				</marker>
				<marker
					id={'arrowhead-end-' + annotationId}
					viewBox={`0 0 ${10 * arrowScaling} ${7 * arrowScaling}`}
					markerWidth={16 * arrowScaling}
					markerHeight={16 * arrowScaling}
					markerUnits="userSpaceOnUse"
					refX={8 * arrowScaling}
					refY={3.5 * arrowScaling}
					orient="auto">
					<polygon
						points={`0 0, ${10 * arrowScaling} ${3.5 * arrowScaling}, 0 ${
							7 * arrowScaling
						}`}
						fill={annotation.color || config.preferences.color.defaultValue}
						vectorEffect="non-scaling-stroke"
					/>
				</marker>
			</defs>
			<path
				ref={multipointRef}
				d={d}
				stroke={annotation.color || config.preferences.color.defaultValue}
				strokeWidth={strokeWidth}
				strokeLinecap="round"
				strokeLinejoin="round"
				vectorEffect="non-scaling-stroke"
				fill="none"
				markerStart={
					annotation.doubleArrow
						? 'url(#arrowhead-start-' + annotationId + ')'
						: undefined
				}
				markerEnd={'url(#arrowhead-end-' + annotationId + ')'}
				className={MULTIPOINT}
			/>
		</SVG>
	);
}
