import { ReactNode } from 'react';

export const symbol_sell: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M863-404 557-97q-9 8.5-20.25 12.75T514.25-80Q503-80 492-84.5T472-97L98-472q-8-8-13-18.956-5-10.955-5-23.044v-306q0-24.75 17.625-42.375T140-880h307q12.07 0 23.386 4.875Q481.702-870.25 490-862l373 373q9.391 9 13.696 20.25Q881-457.5 881-446.25t-4.5 22.75Q872-412 863-404ZM516-138l306-307-375-375H140v304l376 378ZM245-664q21 0 36.5-15.5T297-716q0-21-15.5-36.5T245-768q-21 0-36.5 15.5T193-716q0 21 15.5 36.5T245-664Zm236 185Z"
		/>
	</svg>
);
