import { ReactNode } from 'react';

export const symbol_tools_level: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-280q-24.75 0-42.375-17.625T80-340v-280q0-24.75 17.625-42.375T140-680h680q24.75 0 42.375 17.625T880-620v280q0 24.75-17.625 42.375T820-280H140Zm0-60h680v-280H694q12 17 19 37.543 7 20.544 7 42.457 0 57-41.5 98.5T580-400H380q-57 0-98.5-41.5T240-540q0-21.913 7-42.457Q254-603 266-620H140v280Zm240-120h70v-160h-70q-32 0-56 24t-24 56q0 32 24 56t56 24Zm130 0h70q32 0 56-24t24-56q0-32-24-56t-56-24h-70v160Zm310 120H140h680Z"
		/>
	</svg>
);
