import { ReactNode } from 'react';

export const symbol_call_merge: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M251-182q-9-9-9-21t9-21l197-197v-303L347-623q-9 9-21 8.5t-21-9.5q-9-9-9-21.5t9-21.5l152-152q5-5 10-7t11-2q6 0 11 2t10 7l153 153q9 9 9 21t-9 21q-9 9-21.5 9t-21.5-9L508-724v303q0 12-5 23.5T490-378L293-181q-9 9-21 8.5t-21-9.5Zm452 0q-9 9-21.5 9t-21.5-9L540-301q-9-9-8.5-21t9.5-21q9-9 21.5-9t21.5 9l119 119q9 9 9 21t-9 21Z"
		/>
	</svg>
);
