import { t } from '@transifex/native';

import { Linkable } from 'pkg/api/models/linkable';
import { Record } from 'pkg/api/models/record';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import uuid from 'pkg/uuid';
import DateTime from 'pkg/datetime';
import * as sdk from 'pkg/core/sdk';

export interface ChatMessage extends Record, Linkable {
	attachmentId: number;
	chatId: number;
	content: string;
	createdAt: number;
	delivered: boolean;
	failed: boolean;
	temporary: boolean;
	quotedMessageId: number;
	type: string;
	userId: number;
	clientMessageId: number;
	archivedAt: number;

	quotedMessage: ChatMessage;
	reactions: models.chatReaction.ChatReaction[];
	attachment: models.attachment.Attachment;
	user: models.user.User;
}

export function showMessageContent(
	chatUser: models.chatUser.ChatUser,
	chatMessage: ChatMessage
) {
	// If the user is null it is a legal guardian
	if (!chatUser?.user) {
		return chatMessage.content;
	}

	if (chatUser?.user.id === chatMessage.userId && chatMessage.archivedAt) {
		return t(`You've archived this message.`);
	}

	if (chatMessage.archivedAt) {
		return t('This message has been archived.');
	}

	return chatMessage.content;
}

interface CreatePayload {
	quotedMessageId: number;

	content?: string;
	attachmentId?: number;

	chatId?: number;
	clientMessageId?: number;
}

export async function create({
	attachmentId,
	quotedMessageId,
	content,
	clientMessageId,
	chatId,
}: CreatePayload) {
	return await models.create<CreatePayload, ChatMessage>(
		endpoints.Chat.CreateMessage(chatId),
		{
			attachmentId,
			quotedMessageId,
			content,
			clientMessageId,
		}
	);
}

interface CreateChatMessageProps {
	chatId?: number;
	content?: string;

	chat: models.chat.Chat;
	chatUser: models.chatUser.ChatUser;
	chatMessage?: models.chatMessage.ChatMessage;

	quotedMessage?: models.chatMessage.ChatMessage;
	attachments?: models.attachment.Attachment[];

	onCreatedTemporaryMessage: (
		temporaryMessage: models.chatMessage.ChatMessage
	) => void;
	onComplete: (chatMessage: models.chatMessage.ChatMessage) => void;
}

export async function createChatMessage({
	chatId = null,
	chatMessage = null,
	chat,
	chatUser,
	quotedMessage = null,
	attachments = [],
	onCreatedTemporaryMessage,
	onComplete,
	content = '',
}: CreateChatMessageProps) {
	if (!chatId) {
		chatId = chat.id;
	}

	const createdAt = DateTime.now().getUnixTimestamp();

	const makeTemporaryMessage = (
		partial: Partial<models.chatMessage.ChatMessage>
	): models.chatMessage.ChatMessage => {
		const isRetry = !!chatMessage?.id;
		const temporaryMessageId = isRetry ? chatMessage.id : Date.now();
		const clientMessageId = uuid();

		const template: models.chatMessage.ChatMessage = {
			id: temporaryMessageId,
			clientMessageId,
			userId: chatUser.userId,
			user: chatUser.user,
			chatId: chat.id,
			content,
			createdAt,
			type: 'message',
			attachmentId: null,
			attachment: null,
			delivered: false,
			failed: false,
			temporary: true,
			quotedMessageId: quotedMessage?.id || null,
			quotedMessage,
			reactions: [],
			archivedAt: null,
		};

		if (chatMessage !== null) {
			return { ...chatMessage, ...partial };
		}

		return { ...template, ...partial };
	};

	if (attachments.length > 0) {
		for (const attachment of attachments) {
			const message = makeTemporaryMessage({
				attachmentId: attachment.id,
				attachment,
			});

			await onCreatedTemporaryMessage(message);

			const [req, resp] = await models.chatMessage.create({
				attachmentId: attachment.id,
				chatId,
				quotedMessageId: message.quotedMessageId,
				clientMessageId: message.clientMessageId,
			});

			if (req.ok) {
				await onComplete(resp);
			} else {
				message.failed = true;
				await onComplete(message);
			}
		}
	}

	if (content?.length > 0) {
		const message = makeTemporaryMessage({});

		await onCreatedTemporaryMessage(message);

		const [req, resp] = await models.chatMessage.create({
			content: message.content,
			chatId,
			quotedMessageId: message.quotedMessageId,
			clientMessageId: message.clientMessageId,
		});

		if (req.ok) {
			message.temporary = false;
			onComplete(resp);
		} else {
			message.failed = true;
			onComplete(message);
		}
	}
}

interface UpdatePayload {
	chatMessage: ChatMessage;
	archive: boolean;
}

export async function update({
	chatMessage,
	archive,
}: UpdatePayload): Promise<[boolean, ChatMessage]> {
	const request = await sdk.patch(
		endpoints.Chat.UpdateMessage(chatMessage.chatId, chatMessage.id),
		{},
		{ archive }
	);

	if (!request.ok) {
		return [false, null];
	}

	const result = await request.json();

	return [true, result];
}
