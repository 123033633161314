import { ReactNode } from 'react';

export const symbol_file_save_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m824-218-42-42 31-31q9-9 21-9t21.391 9Q864-282 864-270t-9 21l-31 31Zm-64-63-60-60v-48q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T760-389v108Zm52 221L220-652v432h270q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T490-160H220q-24 0-42-18t-18-42v-492L59-813q-9-9-9-21t9-21q9-9 21-9t21 9L886.803-69.197Q892-64 894-59q2 5 2 11.084v17.493q0 12.93-8.625 21.677Q878.75 0 866 0H610q-12.75 0-21.375-8.675Q580-17.351 580-30.175 580-43 588.625-51.5T610-60h202ZM700-620v111q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T760-509v-112q0-12.444-5-23.722T742-664L544-862q-8-8-19.278-13-11.278-5-23.722-5H229q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T229-820h271v155q0 18.75 13.125 31.875T545-620h155ZM439-433Zm82-87Z"
		/>
	</svg>
);
