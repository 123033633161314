import styled from 'styled-components';
import { Fragment } from 'react';

import DateTime from 'pkg/datetime';

const Wrapper = styled.div`
	border-right: 1px solid var(--palette-gray-400);
	padding: var(--spacing-4);
	font-weight: var(--font-weight-semibold);
	text-align: center;
	font-size: var(--font-size-lg);
	border-bottom: 1px solid var(--palette-gray-400);
	background-color: var(--palette-white);

	&:last-of-type {
		border-right: none;
	}

	@media print {
		border-right: 1px solid var(--palette-black);
		border-bottom: 1px solid var(--palette-black);

		&:first-of-type {
			border-left: 1px solid var(--palette-black);
		}

		&:last-of-type {
			border-right: 1px solid var(--palette-black);
		}
	}
`;

const Day = styled.div`
	font-size: var(--font-size-sm);

	font-weight: var(--font-weight-normal);
`;

interface WeekDayProps {
	day?: Date;
}

const WeekDay = ({ day }: WeekDayProps) => {
	const dateTime = day && new DateTime(new Date(day));

	return (
		<Wrapper>
			{day && (
				<Fragment>
					<Day>
						{dateTime.toLocaleDateString({
							weekday: 'short',
						})}
					</Day>
					{dateTime.toLocaleDateString({
						day: 'numeric',
					})}
				</Fragment>
			)}
		</Wrapper>
	);
};

export default WeekDay;
