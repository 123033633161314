import { ReactNode } from 'react';

export const symbol_grain: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M229.911-160Q201-160 180.5-180.589q-20.5-20.588-20.5-49.5Q160-259 180.589-279.5q20.588-20.5 49.5-20.5Q259-300 279.5-279.411q20.5 20.588 20.5 49.5Q300-201 279.411-180.5q-20.588 20.5-49.5 20.5Zm335 0Q536-160 515.5-180.589q-20.5-20.588-20.5-49.5Q495-259 515.589-279.5q20.588-20.5 49.5-20.5Q594-300 614.5-279.411q20.5 20.588 20.5 49.5Q635-201 614.411-180.5q-20.588 20.5-49.5 20.5Zm-170-165Q366-325 345.5-345.589q-20.5-20.588-20.5-49.5Q325-424 345.589-444.5q20.588-20.5 49.5-20.5Q424-465 444.5-444.411q20.5 20.588 20.5 49.5Q465-366 444.411-345.5q-20.588 20.5-49.5 20.5Zm335 0Q701-325 680.5-345.589q-20.5-20.588-20.5-49.5Q660-424 680.589-444.5q20.588-20.5 49.5-20.5Q759-465 779.5-444.411q20.5 20.588 20.5 49.5Q800-366 779.411-345.5q-20.588 20.5-49.5 20.5Zm-500-170Q201-495 180.5-515.589q-20.5-20.588-20.5-49.5Q160-594 180.589-614.5q20.588-20.5 49.5-20.5Q259-635 279.5-614.411q20.5 20.588 20.5 49.5Q300-536 279.411-515.5q-20.588 20.5-49.5 20.5Zm335 0Q536-495 515.5-515.589q-20.5-20.588-20.5-49.5Q495-594 515.589-614.5q20.588-20.5 49.5-20.5Q594-635 614.5-614.411q20.5 20.588 20.5 49.5Q635-536 614.411-515.5q-20.588 20.5-49.5 20.5Zm-170-165Q366-660 345.5-680.589q-20.5-20.588-20.5-49.5Q325-759 345.589-779.5q20.588-20.5 49.5-20.5Q424-800 444.5-779.411q20.5 20.588 20.5 49.5Q465-701 444.411-680.5q-20.588 20.5-49.5 20.5Zm335 0Q701-660 680.5-680.589q-20.5-20.588-20.5-49.5Q660-759 680.589-779.5q20.588-20.5 49.5-20.5Q759-800 779.5-779.411q20.5 20.588 20.5 49.5Q800-701 779.411-680.5q-20.588 20.5-49.5 20.5Z"
		/>
	</svg>
);
