import { ReactNode } from 'react';

export const symbol_tools_installation_kit: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M360-160q-134 0-227-93T40-480q0-134 93-227t227-93h240q134 0 227 93t93 227q0 134-93 227t-227 93H360Zm0-60h240q108.333 0 184.167-75.765Q860-371.529 860-479.765 860-588 784.167-664 708.333-740 600-740H360q-108.333 0-184.167 75.765Q100-588.471 100-480.235 100-372 175.833-296 251.667-220 360-220Zm120.089-190Q509-410 529.5-430.589q20.5-20.588 20.5-49.5Q550-509 529.411-529.5q-20.588-20.5-49.5-20.5Q451-550 430.5-529.411q-20.5 20.588-20.5 49.5Q410-451 430.589-430.5q20.588 20.5 49.5 20.5ZM440-610h80q12.75 0 21.375-9T550-640.5q0-12.5-8.625-21T520-670h-80q-12 0-21 8.625T410-640q0 12 9 21t21 9Zm0 320h80q12.75 0 21.375-9T550-320.5q0-12.5-8.625-21T520-350h-80q-12 0-21 8.625T410-320q0 12 9 21t21 9Zm200.5-120q12.5 0 21-9t8.5-21v-80q0-12.75-8.625-21.375T640-550q-12 0-21 8.625T610-520v80q0 12 9 21t21.5 9Zm-320 0q12.5 0 21-9t8.5-21v-80q0-12.75-8.625-21.375T320-550q-12 0-21 8.625T290-520v80q0 12 9 21t21.5 9ZM480-480Z"
		/>
	</svg>
);
