import { ReactNode } from 'react';

export const symbol_sick: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-880q66 0 126 20t110 57q10 8 11.5 20t-6.5 22q-8 10-20.5 11t-23.5-7q-42-30-92-46.5T480-820q-103 0-186.5 55.5T170-620q-5 12-16.5 17.5T130-601q-12-4-17-15.5t0-22.5q47-107 145-174t222-67Zm0 800q-119 0-218.5-65T114-319q-5-11 0-22t17-16q12-5 23.5 1t16.5 17q42 91 125.5 145T480-140q142 0 241-99.5T820-480v-14q0-7-1-14-2-13 5.5-23.5T844-544q12-2 22 5.5t12 19.5q1 10 1.5 19.5t.5 19.5q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80ZM343-581l-25 25q-8 8-8 18t8 18q8 8 18 7.5t18-8.5l38-38q9-9 9-21t-9-21l-40-40q-8-8-17-8t-17 8q-8 8-8 17.5t8 17.5l25 25Zm497-24q-31 0-53-22t-22-53q0-35 22-69.5t41-62.5q2-3 5-5t7-2q4 0 7 2t5 5q19 28 41 62.5t22 69.5q0 31-22 53t-53 22Zm-222 24 25-25q8-8 8-17t-8-17q-8-8-17.5-8t-17.5 8l-40 39q-9 9-9 21t9 21l39 39q8 8 18 8t18-8q8-8 8-18t-8-18l-25-25ZM480-480ZM315-285q7 0 13.5-4t10.5-10q23-34 61-51.5t81-17.5q42 0 79.5 18t61.5 52q4 5 11 9t13 4q14 0 20-11.5t-2-23.5q-29-45-78-71t-106-26q-27 0-52.5 6T379-394l-118-68q0-16-6.5-29.5T234-513q-20-11-42-5.5T159-493q-11 20-5.5 42t25.5 33q14 8 28.5 7.5T236-419l98 56q-11 9-19.5 18.5T299-324q-10 15-3.5 27t19.5 12Z"
		/>
	</svg>
);
