import { ReactNode } from 'react';

export const symbol_editor_choice: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-117 329-66q-5 2-9.56 2.5-4.56.5-9.12.5Q286-63 268-79.875T250-123v-260L141-558q-5-8-6.5-15.814t-1.5-16Q133-598 134.5-606t6.5-16l142-230q8-14 21.579-21T334-880h292q15.842 0 29.421 7Q669-866 677-852l142 230q5 8 6.5 15.814t1.5 16Q827-582 825.5-574t-6.5 16L710-383v260q0 26.25-18.14 43.125Q673.721-63 649.535-63q-4.535 0-9.035-.5T631-66l-151-51Zm0-62 170 55v-176H310v176l170-55ZM333.885-360H626l142-230-141.885-230H334L192-590l141.885 230ZM438-526l148-149q9-9 21-9t21 8.609q9 9.391 9.5 21.858.5 12.466-8.5 21.533L459-462q-9 9-21 9t-21-9l-85-85q-9-9-9.5-21t8.5-21q9-9 21.833-9 12.834 0 22.167 9l63 63Zm42 226H310h340-170Z"
		/>
	</svg>
);
