import styled, { css } from 'styled-components';

import * as breakpoints from 'pkg/config/breakpoints';
import * as palette from 'pkg/config/palette';

import rgba from 'pkg/rgba';

const sliderThumb = css`
	width: ${(props) => (props.narrow ? '15px' : '25px')};
	height: ${(props) => (props.narrow ? '15px' : '25px')};
	padding: ${(props) => (props.narrow ? '10px' : '0')};

	background: ${rgba(palette.slider.thumbColor)};
	border-radius: 50%;
	box-sizing: border-box;
	border: 1px solid rgba(0, 0, 0, 0.05);
	cursor: pointer;
	margin-top: ${(props) => (props.narrow ? '-7px' : '-9px')};
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
	appearance: none;

	@media ${breakpoints.small} {
		width: ${(props) => (props.narrow ? '20px' : '30px')};
		height: ${(props) => (props.narrow ? '20px' : '30px')};
		margin-top: ${(props) => (props.narrow ? '-7px' : '-12px')};
	}
`;

const Slider = styled.input`
	appearance: none;
	width: 100%;
	outline: none;
	background: transparent;
	border-style: none;
	padding: 0;
	margin: 0;
	height: auto;

	&::-webkit-slider-thumb {
		${sliderThumb};
	}
	&::-moz-range-thumb {
		${sliderThumb};
	}

	&::-ms-thumb {
		${sliderThumb} margin-top: -6px;
	}

	&::-webkit-slider-runnable-track {
		width: 100%;
		height: 10px;
		background: ${palette.gray[200]};
		border-radius: var(--radius-3);
		border: 1px solid rgba(0, 0, 0, 0.05);
		box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
	}

	&::-moz-range-track {
		width: 100%;
		height: 10px;
		background: ${palette.gray[200]};
		border-radius: var(--radius-3);
		border: 1px solid rgba(0, 0, 0, 0.05);
		box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
	}

	&::-ms-track {
		width: 100%;
		height: 10px;
		background: ${palette.gray[200]};
		border-radius: var(--radius-3);
		border: 1px solid rgba(0, 0, 0, 0.05);
		box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
	}

	&::-ms-fill-lower,
	&::-ms-fill-upper {
		background: transparent;
		border-radius: var(--radius-3);
	}
`;

export default Slider;
