import { ReactNode } from 'react';

export const symbol_signal_cellular_connected_no_internet_0_bar: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m131-131 698-698q14-14 32.5-6.344T880-808v123q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T820-685v-50L224-140h486q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q740-97 731.375-88.5T710-80H152q-19.688 0-27.344-18.5Q117-117 131-131Zm719 51q-12 0-21-9t-9-21q0-12 9-21t21-9q12 0 21 9t9 21q0 12-9 21t-21 9Zm-30-150v-315q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T880-545v315q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T820-230Z"
		/>
	</svg>
);
