import { ReactNode } from 'react';

export const symbol_share_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M727.035-712Q750-712 765.5-727.535q15.5-15.535 15.5-38.5T765.465-804.5q-15.535-15.5-38.5-15.5T688.5-804.465q-15.5 15.535-15.5 38.5t15.535 38.465q15.535 15.5 38.5 15.5Zm0 572Q750-140 765.5-155.535q15.5-15.535 15.5-38.5T765.465-232.5q-15.535-15.5-38.5-15.5T688.5-232.465q-15.5 15.535-15.5 38.5t15.535 38.465q15.535 15.5 38.5 15.5ZM727-766Zm0 572ZM230-40q-78 0-134-56T40-230q0-78 56-134t134-56q78 0 134 56t56 134q0 78-56 134T230-40Zm86-519 303-174q-3-7.071-4.5-15.911Q613-757.75 613-766q0-47.5 33.25-80.75T727-880q47.5 0 80.75 33.25T841-766q0 47.5-33.25 80.75T727-652q-23.354 0-44.677-7.5T646-684L316-501q-8 5-17.5 6.5t-19.125-.5q-12.375-2-24.598-3.5Q242.554-500 230-500q-23.397 0-45.198 4Q163-492 143-485q-8 3-15-2.5t-5-13.5q8-38 39-65.5t72-27.5q23 0 44 9t38 26Zm-86 354 57 57q5 5 11.5 4.5t11.5-5.259q5-4.758 5-11.5Q315-167 310-172l-58-57 60-60q5-4.909 5-11.455Q317-307 312.071-312q-4.928-5-11.5-5Q294-317 289-312l-59 59-59-59q-4.636-5-10.818-5Q154-317 149-312.071q-5 4.928-5 11.5Q144-294 149-289l58 59-58 58q-5 5.182-5 12.091Q144-153 149.143-148q5.143 5 12 5T173-148l57-57ZM727-80q-47.5 0-80.75-33.346Q613-146.693 613-194.331q0-6.669 1.5-16.312T619-228l-129-75q-9.905-6.353-12.952-16.676Q474-330 480-340q6-10 16.5-12.5T517-350l129 73q15-14 35-22.5t46-8.5q47.5 0 80.75 33.25T841-194q0 47.5-33.25 80.75T727-80Z"
		/>
	</svg>
);
