import { ReactNode } from 'react';

export const symbol_syringe: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M149.13-519.609Q141-529 141-541.467q0-12.466 8-21.533l126-125-57-58-26 26q-9.067 9-21.533 9-12.467 0-21.337-8.842-8.13-8.105-8.13-20.632Q141-753 149-762l94-94q9.067-9 21.533-9 12.467 0 21.337 9.391Q294-847 294-834.533q0 12.466-8 20.533l-26 27 57 57 126-127q9.067-8 21.533-8Q477-865 486-857q8 9.067 8 21.533Q494-823 486-814l-34 34 339 338q17 18 17 42.939T791-357l-50 50 146 146q7 7 3.429 16.5-3.572 9.5-13.572 9.5h-29.286q-8.571 0-17.103-3.611Q821.935-142.222 816-148L699-265l-50 50q-17.122 17-42.061 17T564-215L226-554l-34 34q-9.067 9-21.533 9-12.467 0-21.337-8.609ZM267-596l339 339 142-142-85-85-70 70q-8 9-20.5 9t-20.63-8.25q-8.87-9-8.87-21.375T552-456l70-70-85-85-70 70q-9.067 9-21.533 9Q433-532 424-541q-8-8-8-20.467 0-12.466 8-21.533l70-70-85-85-142 142Zm-7-7 142-142-142 142Z"
		/>
	</svg>
);
