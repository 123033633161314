import { useT } from '@transifex/react';

import { CollectionSelection } from 'pkg/api/use_collection';
import * as actions from 'pkg/actions';

import Modal from 'components/modal';
import * as Card from 'components/Card';

import * as Input from 'components/form/inputs';
import { TrainingCollectionShareEntryResponse } from 'components/training-library/modals/share';

import Button from 'design/button';
import * as Table from 'design/table';

interface OrganizationStepProps {
	organizationId: number;
	collectionId: number;
	records: TrainingCollectionShareEntryResponse[];
	selection: CollectionSelection;
	selectedRecords: TrainingCollectionShareEntryResponse[];
	onClose: () => void;
	refreshRecords: () => void;
}

export default function SelectOrganizationsModal({
	organizationId,
	collectionId,
	records,
	selection,
	onClose,
	selectedRecords,
	refreshRecords,
}: OrganizationStepProps) {
	const t = useT();

	const handleSelectSingle = (id: number) => selection.selectSingle(id);

	const handleDone = async () => {
		const ok = await actions.trainingCollections.shareSession(
			organizationId,
			collectionId,
			{
				targetOrganizationIds: selectedRecords.map((r) => r.id),
			}
		);

		if (ok) {
			refreshRecords();
			onClose();
		}
	};

	return (
		<Modal onClose={onClose}>
			<Card.Base>
				<Card.Header>
					<Card.Heading>{t('Select organizations')}</Card.Heading>
				</Card.Header>
				<Card.Divider />
				<Table.Table
					columns={[
						{
							content: (
								<Input.Control
									type="checkbox"
									onChange={selection.selectAll}
									standalone
									checked={selection.isAllSelected}
								/>
							),
							width: 'max-content',
						},
						{ content: t('Organization name') },
					]}>
					{records.map((r) => (
						<Table.Row onClick={() => handleSelectSingle(r.id)}>
							<Table.Cell>
								<Input.Control
									type="checkbox"
									checked={selection.isSelected(r.id)}
									onChange={() => handleSelectSingle(r.id)}
								/>
							</Table.Cell>
							<Table.Cell>{r.organization.name}</Table.Cell>
						</Table.Row>
					))}
				</Table.Table>
				<Card.Divider />
				<Card.Footer>
					<Button label={t('Cancel')} onClick={onClose} />
					<Button
						label={t('Share with selected ({num})', {
							num: selection.selectedRecords.length,
						})}
						primary
						disabled={selection.selectedRecords.length === 0}
						onClick={handleDone}
					/>
				</Card.Footer>
			</Card.Base>
		</Modal>
	);
}
