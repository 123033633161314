import { ReactNode } from 'react';

export const symbol_aod: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M382-380q-10.833 0-17.917-7.116-7.083-7.117-7.083-18Q357-416 364.083-423q7.084-7 17.917-7h196q10.833 0 17.917 7.116 7.083 7.117 7.083 18Q603-394 595.917-387q-7.084 7-17.917 7H382Zm-41-130q-10.833 0-17.917-7.116-7.083-7.117-7.083-18Q316-546 323.083-553q7.084-7 17.917-7h278q10.833 0 17.917 7.116 7.083 7.117 7.083 18Q644-524 636.917-517q-7.084 7-17.917 7H341ZM260-40q-24 0-42-18t-18-42v-760q0-24 18-42t42-18h440q24 0 42 18t18 42v760q0 24-18 42t-42 18H260Zm0-90v30h440v-30H260Zm0-60h440v-580H260v580Zm0-640h440v-30H260v30Zm0 0v-30 30Zm0 700v30-30Z"
		/>
	</svg>
);
