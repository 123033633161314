import { MAX_FILE_SIZE } from 'pkg/config/files';

import { useEventListener } from 'pkg/hooks/events';

export type ClipboardAttachmentCallback = (files: File[]) => void;

export function useClipboardAttachments(
	onPaste: ClipboardAttachmentCallback,
	deps: any[] = []
): void {
	useEventListener(
		'paste',
		(event: ClipboardEvent) => {
			const pastedFiles = Array.from(event.clipboardData.files);

			if (pastedFiles.length > 0) {
				event.preventDefault();

				const validFiles = pastedFiles.filter(
					(file: File) => file.size < MAX_FILE_SIZE
				);

				onPaste(validFiles);
			}
		},
		deps
	);
}
