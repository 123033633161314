import { Fragment, useContext } from 'react';
import { useT } from '@transifex/react';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import { SubscriptionTypes } from 'pkg/api/models/subscription';

import AssignProducts from 'routes/payments/orders/create/components/AssignProducts';
import InstallmentCount from 'routes/payments/subscriptions/edit/installments';
import AdvancedSettings from 'routes/payments/subscriptions/edit/advanced-settings';
import { AddOrderContext } from 'routes/payments/orders/create';

import Section from 'components/form/Section';
import * as FormTable from 'components/form/Table';
import Row from 'components/form/Row';
import Column from 'components/layout/column';
import DiscountInput from 'components/discount/form/input';

interface CustomerProps {
	user: models.user.User;
}

function Customer({ user }: CustomerProps) {
	return (
		<Row>
			<FormTable.Table>
				<tbody>
					<FormTable.TableRow>
						<FormTable.Cell>
							{models.user.fullName(user)} - {user.email}
						</FormTable.Cell>
					</FormTable.TableRow>
				</tbody>
			</FormTable.Table>
		</Row>
	);
}

interface EditFormProps {
	subscription: models.subscription.Subscription;
}

const EditForm: React.FC<React.PropsWithChildren<EditFormProps>> = ({
	subscription,
}) => {
	const t = useT();
	const OrderContext = useContext(AddOrderContext);

	const handleAddDiscount = (discount: models.discount.Discount) => {
		OrderContext.setFormState({
			discount,
		});
	};

	const handleRemoveDiscount = () => {
		OrderContext.setFormState({
			discount: null,
		});
	};

	return (
		<Fragment>
			<Section
				title={t('{step}. Customer', { step: 1 })}
				description={t('Customer information')}>
				<Customer user={subscription.customerUser} />
			</Section>
			<Section
				title={t('{step}. Products and prices', { step: 2 })}
				description={t('Select the products you would like to invoice.')}>
				<Column spacing={styles.spacing._6}>
					<Column>
						<AssignProducts subscriptionId={subscription.id} />
						{subscription.type === SubscriptionTypes.Installment && (
							<InstallmentCount subscription={subscription} />
						)}
					</Column>
					<DiscountInput
						discount={OrderContext.formState.discount}
						handleAddDiscount={handleAddDiscount}
						handleRemoveDiscount={handleRemoveDiscount}
					/>
				</Column>
			</Section>
			<AdvancedSettings
				user={subscription.customerUser}
				subscription={subscription}
			/>
		</Fragment>
	);
};

export default EditForm;
