import { ReactNode } from 'react';

export const symbol_bomb: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M345.833-108Q445-108 515.5-178.364 586-248.727 586-348q0-33-9.5-66.103Q567-447.207 548-477l-19-30 46-79-139-80-44.567 78h-46.689Q245-588 175-517.5T105-350q0 101 70.288 171.5Q245.577-108 345.833-108ZM850-618q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T850-678h40q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T890-618h-40ZM619.825-848Q607-848 598.5-856.625T590-878v-40q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T650-918v40q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM764-792q-9-9-9-21t9-21l29-29q9-9 21-9t21 9q9 9 9 21t-9 21l-29 29q-9 9-21 9t-21-9ZM346-348Zm325-324q-5 0-8-1t-6.75-3.217L630-691q-18-11-38.524-5.326Q570.951-690.652 561-673l-4 8 61 35q16 10 21 27.5t-5 33.5l-34 61q23 36 34.5 76.5T646-348q0 125-87.5 212.5T346-48q-125 0-212.5-88.5T46-350q0-125 86.787-211.5Q219.575-648 344.99-648H357l34-60q9-17 27-21.5t35 4.5l52 30 5-8q23-39 66.5-50.5T660-743l26.093 15.042Q693-724 697-716.926q4 7.075 4 15.266 0 12.66-8.625 21.16T671-672Z"
		/>
	</svg>
);
