import { ReactNode } from 'react';

export const symbol_real_estate_agent: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m562-110 248-78q0-20-15.5-34T758-235H549q-16 1-31.5-2t-30.5-8l-66-20q-12-4-18-16t-2-24q4-12 15.278-18T440-324l64 21q11 4 22.5 6t22.5 2h56q2 0 0 0 0-21-14-36.5T557-355l-218-83h-84v238l307 90Zm-13 57-294-84q-2 27-22.5 42T195-80h-95q-24.75 0-42.375-17.625T40-140v-298q0-24.75 17.625-42.375T100-498h238q5.333 0 10.667 1Q354-496 359-494l218 82q42 16 66.5 46t24.5 71h90q50.833 0 86.417 37Q880-221 880-170q0 11-5.5 20T859-138L583-53q-8.171 2-17.086 2Q557-51 549-53Zm-449-87h94v-298h-94v298Zm469.809-756Q579-896 588-893q9 3 17 8l210 150q12 8 18.5 21.5T840-686v291q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T780-395v-292L570-834 360-687v99q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T300-588v-98q0-14 6.5-27.5T325-735l210-150q8-5 16.808-8 8.809-3 18.001-3Zm.191 62Zm-40 204q8 0 14-6t6-14q0-8-6-14t-14-6q-8 0-14 6t-6 14q0 8 6 14t14 6Zm80 0q8 0 14-6t6-14q0-8-6-14t-14-6q-8 0-14 6t-6 14q0 8 6 14t14 6Zm-80 80q8 0 14-6t6-14q0-8-6-14t-14-6q-8 0-14 6t-6 14q0 8 6 14t14 6Zm80 0q8 0 14-6t6-14q0-8-6-14t-14-6q-8 0-14 6t-6 14q0 8 6 14t14 6Z"
		/>
	</svg>
);
