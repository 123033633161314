import { ReactNode } from 'react';

export const symbol_personal_bag_question: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M403-763q13-1 22.5-1.5t22.5-.5q13 0 22.5.5T493-763v-2q0-20-12.5-35T448-815q-20 0-32.5 15T403-765v2ZM228-75q-24 0-42-18t-18-42v-350q0-88 49-158.5T343-746v-19q0-44 30.5-77t74.5-33q44 0 74.5 33t30.5 77v19q72 29 122.5 96.5T728-487q-14-1-30.5-1.5T668-489q-5-99-71.5-157.5T448.438-705q-91.482 0-155.96 64.35Q228-576.3 228-485v350h229q3 14 13 31t23 29H228Zm60-340h220q17-18 37.5-33.5T594-475H288q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T288-415Zm407-14q80.51 0 137.255 56.745Q889-315.51 889-235q0 80.51-56.745 137.255Q775.51-41 695-41q-80.51 0-137.255-56.745Q501-154.49 501-235q0-80.51 56.745-137.255Q614.49-429 695-429Zm1.108 318Q708-111 716-119.108t8-20Q724-151 715.892-159t-20-8Q684-167 676-158.892t-8 20Q668-127 676.108-119t20 8ZM508-415Zm188 226q8 0 14-6t6-14q0-8 6.5-17.5t15.227-17.9Q753-257 762.5-269.5 772-282 772-302q0-31.719-21.5-50.859Q729-372 695.703-372q-21.088 0-38.846 10.043Q639.099-351.914 628-335q-5 7-1.526 14.184 3.473 7.184 11.579 10.5Q645-307 651.5-310q6.5-3 11.5-9 5-8 13.75-12t19.25-4q16.875 0 26.438 8.25Q732-318.5 732-302q0 11-7 19.5T710-266q-8 7-15.5 14T682-237q-4 7-5 13.3-1 6.3-1 14.7 0 8 6 14t14 6Z"
		/>
	</svg>
);
