import { ReactNode } from 'react';

export const symbol_settings_ethernet: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M834-480 659-657q-8-9-8.5-21t8.5-21q9-9 21-9t21 9l198 198q5 5 7 10t2 11q0 6-2 11t-7 10L701-261q-9 9-21 9t-21-9q-9-9-8.5-21t8.5-21l175-177Zm-708 0 175 177q8 9 8.5 21t-8.5 21q-9 9-21 9t-21-9L61-459q-5-5-7-10t-2-11q0-6 2-11t7-10l198-198q9-9 21-9t21 9q9 9 8.5 21t-8.5 21L126-480Zm194 35q-14 0-24.5-10.5T285-480q0-14 10.5-24.5T320-515q14 0 24.5 10.5T355-480q0 14-10.5 24.5T320-445Zm160 0q-14 0-24.5-10.5T445-480q0-14 10.5-24.5T480-515q14 0 24.5 10.5T515-480q0 14-10.5 24.5T480-445Zm160 0q-14 0-24.5-10.5T605-480q0-14 10.5-24.5T640-515q14 0 24.5 10.5T675-480q0 14-10.5 24.5T640-445Z"
		/>
	</svg>
);
