import { Fragment, ReactNode } from 'react';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useEndpoint } from 'pkg/api/use_endpoint';

interface LoginMethodsProp {
	userId: number;
	accountId?: number;
}

export default function LoginMethods({
	userId,
	accountId,
}: LoginMethodsProp): JSX.Element {
	const loginMethods: ReactNode[] = [];

	const { record: info } = useEndpoint<models.user.LoginInformation>(
		!!accountId ? endpoints.Users.ShowLoginInformation(userId) : null
	);

	if (info.accountEmail) {
		loginMethods.push(
			<a href={`mailto:${info.accountEmail}`}>{info.accountEmail}</a>
		);
	}

	if (info.usesSignInWithApple) {
		loginMethods.push(<span>{t('Uses sign in with Apple')}</span>);
	}

	if (loginMethods.length === 0) {
		return <Fragment>{t('None')}</Fragment>;
	}

	return (
		<Fragment>
			{loginMethods.map((node: ReactNode, index: number) => (
				<Fragment key={index}>
					{node}
					{index < loginMethods.length - 1 && <span>,</span>}
				</Fragment>
			))}
		</Fragment>
	);
}
