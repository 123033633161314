import { ReactNode } from 'react';

export const symbol_missed_video_call: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M429.133-343Q435-343 440-345q5-2 10-7l133-133q9-9 9-21.5t-9-21.5q-9-9-21.5-9t-21.5 9L429-417 306-540h60q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T366-600H230q-12.75 0-21.375 8.625T200-570v130q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T260-440v-60l148 148q5 5 10.133 7 5.134 2 11 2ZM140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h520q24 0 42 18t18 42v215l134-134q7-7 16.5-3.458Q880-658.917 880-649v338q0 9.917-9.5 13.458Q861-294 854-301L720-435v215q0 24-18 42t-42 18H140Zm0-60h520v-520H140v520Zm0 0v-520 520Z"
		/>
	</svg>
);
