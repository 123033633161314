import { ReactNode } from 'react';

export const symbol_perm_scan_wifi: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M438-162 47-553q-9-9.081-13.5-20.432-4.5-11.352-4.5-22.703 0-12.865 5.5-24.76Q40-632.791 51-642q91-76 200.5-117T480-800q119 0 228.5 41T909-642q11 9.12 16.5 20.9t5.5 24.7q0 11.4-4.5 22.829Q922-562.143 913-553L522-162q-9 9-20 13t-22 4q-11 0-22-4t-20-13Zm42-40 392-392q-87-68-184.5-107T480-740q-110 0-207.5 39T88-594l392 392Zm-30-173q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T510-375v-160q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T450-535v160Zm30-240q12 0 21-9t9-21q0-12-9-21t-21-9q-12 0-21 9t-9 21q0 12 9 21t21 9Zm0 413Z"
		/>
	</svg>
);
