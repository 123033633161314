export function Create(): string {
	return `/v1/user-products`;
}

export function Delete(ID: number): string {
	return `/v1/user-products/${ID}`;
}

export function Update(ID: number): string {
	return `/v1/user-products/${ID}`;
}
