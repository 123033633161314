import { ReactNode } from 'react';

export const symbol_assist_walker: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m540-143 34-325q-45-11-83.5-33T420-555l-13-13-109 108 80 80q5 5 7 10t2 11v209q0 13-8.5 21.5T357-120q-13 0-21.5-8.5T327-150v-194l-65-57 10 82q1 6-.5 11.5T266-297L152-151q-8 10-20 12t-22-6q-10-8-11.5-20.5T105-188l106-137-50-128q-9-23-7-43t13-31l134-132q11-11 23-16t26-5q14 0 27.5 5.5T400-660l65 64q33 32 74 53t88 23h92q15 0 25 10t12 25l30 286q15 5 25 18.5t10 30.5q0 21-15 35.5T770-100q-21 0-36-14.5T719-150q0-15 7-26.5t19-18.5l-7-75H595l-13 131q-1 8-7 13.5t-14 5.5q-9 0-15.5-7t-5.5-16Zm-30-537q-29 0-49.5-20.5T440-750q0-29 20.5-49.5T510-820q29 0 49.5 20.5T580-750q0 29-20.5 49.5T510-680Zm89 370h135l-17-170H616l-17 170Z"
		/>
	</svg>
);
