import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';
import { PageWidths } from 'pkg/config/sizes';

import * as selectors from 'pkg/selectors';
import useDateFilter from 'pkg/hooks/use-date-filter';
import * as models from 'pkg/api/models';
import { useEndpoint } from 'pkg/api/use_endpoint';
import * as endpoints from 'pkg/api/endpoints/auto';
import DateTime, { Granularity } from 'pkg/datetime';
import { RootState } from 'pkg/reducers';
import { useCurrentOrganization } from 'pkg/identity';

import ActivitySummary from 'containers/group/statistics/training-dashboard/ActivitySummary';
import LatestActivities from 'containers/group/statistics/training-dashboard/LatestActivities';
import DashboardItem from 'containers/group/statistics/training-dashboard/DashboardItem';
import IndividualTable from 'containers/group/statistics/training-dashboard/Individual';

import SectionTitle from 'components/SectionTitle';
import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import UserActivityTrends from 'components/group/UserActivityTrends';
import GroupPhysicalStrain from 'components/group/PhysicalStrain';
import Row from 'components/layout/row';
import { Spinner } from 'components/loaders/spinner';
import Column from 'components/layout/column';
import * as ActionBar from 'components/layout/ActionBar';
import { Inner } from 'components/layout/LargeScreenContent';

export type TableProps = {
	id: number;
	name: string;
	count: number;
	minutes: number;
};

export type User = {
	firstName: string;
	lastName: string;
	id: number;
	tags: { [key: number]: TableProps };
};

interface TrainingStatSummary {
	userActivities: {
		totalEvents: number;
		totalAttendance: number;
		individual: number;
	};
	users: {
		[key: number]: User;
	};
	usedExercises: { [key: number]: TableProps };
	exerciseTags: {
		[key: number]: TableProps;
	};
	sessionTags: {
		[key: number]: TableProps;
	};
	sessions: models.session.Session[];
}

interface SummaryStatProps {
	groupId: number;
}

const Training = ({ groupId }: SummaryStatProps) => {
	const dateTimeNow = DateTime.now();
	const org = useCurrentOrganization();

	const currentSeason = useSelector((state: RootState) =>
		selectors.groups.getCurrentSeason(state, { groupId })
	);
	const lastSeason: { [key: string]: number } = useSelector(
		(state: RootState) => selectors.groups.getLastSeason(state, { groupId })
	);

	const { DateFilter, dates } = useDateFilter({
		customDatePresets: [
			{
				name: t('Past 90 days'),
				startOfRangeDate: dateTimeNow
					.prev(Granularity.day, 90)
					.getUnixTimestamp(),
			},
			{
				name: t('Past 180 days'),
				startOfRangeDate: dateTimeNow
					.prev(Granularity.day, 180)
					.getUnixTimestamp(),
			},
			{
				name: t('This season'),
				startOfRangeDate: currentSeason.start,
				default: true,
			},
			{
				name: t('Last Season'),
				endOfRangeDate: lastSeason.end,
				startOfRangeDate: lastSeason.start,
			},
		],
	});

	const params = {
		from_date: dates.startOfRange.toString(),
		to_date: dates.endOfRange.toString(),
	};

	const { record: summary, isLoading } = useEndpoint<TrainingStatSummary>(
		endpoints.Groups.TrainingSessionSummary(groupId),
		{
			queryParams: new URLSearchParams(params),
		}
	);

	if (isLoading) {
		return <Spinner />;
	}

	const usedExercises = Object.values(summary.usedExercises || {});
	const exerciseTags = Object.values(summary.exerciseTags || {});
	const sessionTags = Object.values(summary.sessionTags || {});

	return (
		<Fragment>
			<ActionBar.FilterBar maxWidth={PageWidths.EXTRA_WIDE}>
				<ActionBar.PrimaryAction>{DateFilter}</ActionBar.PrimaryAction>
			</ActionBar.FilterBar>
			<Inner topSpacing={16} maxWidth={PageWidths.EXTRA_WIDE}>
				<Column spacing={styles.spacing._7}>
					<Column>
						<SectionTitle>{t('Exercises')}</SectionTitle>
						<DashboardItem data={usedExercises} chartVariant="horizontal-bar" />
					</Column>
					<Column>
						<SectionTitle>{t('Exercise tags')}</SectionTitle>
						<DashboardItem data={exerciseTags} chartVariant="donut" />
					</Column>
					<Column>
						<SectionTitle>{t('Session tags')}</SectionTitle>
						<DashboardItem data={sessionTags} chartVariant="donut" />
					</Column>
					<Column>
						<SectionTitle>{t('Individual Tags')}</SectionTitle>
						<IndividualTable
							data={Object.values(summary.users || {})}
							exerciseTagData={Object.values(summary.exerciseTags || {})}
						/>
					</Column>
					<ActivitySummary {...summary.userActivities} />
					<LargeScreen>
						<Row>
							<Column>
								<UserActivityTrends groupId={groupId} />
								<GroupPhysicalStrain
									groupId={groupId}
									organizationId={org.id}
									showMaxStrains
									showAllPlayersButton
								/>
							</Column>
							<LatestActivities groupId={groupId} />
						</Row>
					</LargeScreen>
					<SmallScreen>
						<Column>
							<UserActivityTrends groupId={groupId} />
							<GroupPhysicalStrain
								groupId={groupId}
								organizationId={org.id}
								showMaxStrains
								showAllPlayersButton
							/>
						</Column>
						<LatestActivities groupId={groupId} />
					</SmallScreen>
				</Column>
			</Inner>
		</Fragment>
	);
};

export default Training;
