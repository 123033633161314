import { Fragment } from 'react';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import { useCollection } from 'pkg/api/use_collection';
import * as endpoints from 'pkg/api/endpoints/auto';

import { Trigger, ScrollSpy } from 'components/ScrollSpy';
import CardWrapper, * as Card from 'components/Card';
import Modal from 'components/modal';

import * as UserList from 'components/user/List';

import * as css from './style.css';

interface BaseUsersModalProps {
	endpoint: string;
	title: string;
	onClose: () => void;
}

interface UsersModalProps {
	postId: number;
	onClose: () => void;
}

export function LikesModal({ postId, onClose }: UsersModalProps) {
	return (
		<UsersModal
			title={t('Likes')}
			onClose={onClose}
			endpoint={endpoints.GroupPosts.ShowLikes(postId)}
		/>
	);
}

export function ViewsModal({ postId, onClose }: UsersModalProps) {
	return (
		<UsersModal
			title={t('Seen by')}
			onClose={onClose}
			endpoint={endpoints.GroupPosts.ShowViews(postId)}
		/>
	);
}

function UsersModal({ title, endpoint, onClose }: BaseUsersModalProps) {
	const {
		records: users,
		isLoading,
		pagination,
	} = useCollection<models.user.User>(endpoint, { showAllResults: true });

	const loadMoreUsers = async (event: IntersectionObserverEntry) => {
		if (!pagination.hasNext || !event.isIntersecting || isLoading) {
			return;
		}

		pagination.fetchNext();
	};

	return (
		<Modal thin onClose={onClose}>
			<CardWrapper>
				{title && (
					<Fragment>
						<Card.Header>
							<Card.Heading>{title}</Card.Heading>
						</Card.Header>
						<Card.Divider />
					</Fragment>
				)}
				<ScrollSpy className={css.wrapper}>
					<Card.Body $flex $noSpacing>
						<UserList.Base>
							{users.map((user) => (
								<UserList.Item key={user.id}>
									<UserList.Avatar user={user} />
									<UserList.Name>{models.user.fullName(user)}</UserList.Name>
								</UserList.Item>
							))}
							{pagination.hasNext && !isLoading && (
								<Trigger
									key="next-page"
									offsetY={-10}
									onTrigger={loadMoreUsers}
								/>
							)}
						</UserList.Base>
					</Card.Body>
				</ScrollSpy>
			</CardWrapper>
		</Modal>
	);
}

export default UsersModal;
