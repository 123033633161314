import { ReactNode } from 'react';

export const symbol_contract_delete: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m762-191-64 64q-9 9-21 9t-21-9q-9-9-9-21t9-21l64-64-64-64q-9-9-9-21t9-21q9-9 21-9t21 9l64 64 64-64q9-9 21-9t21 9q9 9 9 21t-9 21l-64 64 64 64q9 9 9 21t-9 21q-9 9-21 9t-21-9l-64-64ZM225-80q-43.75 0-74.375-30.625T120-185v-75q0-24.75 17.625-42.375T180-320h60v-500q0-24.75 17.625-42.375T300-880h480q24.75 0 42.375 17.625T840-820v350q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T780-470v-350H300v500h241q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T541-260H180v75q0 19.125 13 32.062Q206-140 224-140h317q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q571-97 562.375-88.5T541-80H225Zm345-60H180h371-2 21ZM390-640q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T390-700h300q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T690-640H390Zm0 120q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T390-580h300q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T690-520H390Z"
		/>
	</svg>
);
