import FormCategory from 'pkg/models/form_category';

import * as service from 'pkg/actions/services/form_categories';
import { normalizedDispatch } from 'pkg/actions/utils';
import * as flashActions from 'pkg/actions/flashes';

import store from 'pkg/store/createStore';
import { crash } from 'pkg/errors/errors';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as sdk from 'pkg/core/sdk';

export const create = async (
	payload: service.FormCategoryPayload
): Promise<FormCategory> => {
	const request = await sdk.post(
		endpoints.FormCategories.Create(),
		{},
		payload
	);
	const response = await request.json();

	if (!request.ok) {
		flashActions.show({
			title: crash().title,
			message: response.error,
		});

		return;
	}

	normalizedDispatch(response, FormCategory.normalizr())(store.dispatch);

	return response;
};
