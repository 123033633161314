import { ChangeEvent, useState } from 'react';
import { t } from '@transifex/native';

import store from 'pkg/store/createStore';
import * as models from 'pkg/api/models';
import * as actions from 'pkg/actions';

import StepModal, { Step } from 'components/step-modal';

import TextInput from 'components/form/TextInput';

interface TitleModalProps {
	membership: models.membership.Membership;
	onClose: () => void;
	replaceRecord: (membership: models.membership.Membership) => void;
}

const TitleModal = ({
	membership,
	onClose,
	replaceRecord,
}: TitleModalProps) => {
	const [title, setTitle] = useState(membership.title);

	const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
		setTitle(event.target.value);

	const handleSave = async () => {
		const updatedMembership = await actions.groups.deprecatedUpdateGroupMember(
			membership.groupId,
			membership.userId,
			{
				title,
			}
		)(store.dispatch, store.getState);

		replaceRecord(updatedMembership);

		onClose();
	};

	return (
		<StepModal onClose={onClose}>
			<Step title={t(`Set role`)} onNext={handleSave} nextLabel={t('Save')}>
				<TextInput
					value={title}
					onChange={handleChange}
					placeholder={t(`E.g. Head Coach`)}
				/>
			</Step>
		</StepModal>
	);
};

export default TitleModal;
