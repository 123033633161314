import { ReactNode } from 'react';

export const symbol_earbuds: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M314.947-120Q234-120 177-177.038 120-234.075 120-315v-423q0-42 36-72t78-30q42 0 78 36t36 78q0 46-34 80t-80 34h-54v297q0 57 39 96t96 39q57 0 96-39t39-96v-331q0-80.51 57.053-137.255Q564.106-840 645.053-840T783-783.255Q840-726.51 840-646v411q0 47-28 81t-74.5 34q-46.5 0-86-34T612-234.5q0-46.5 33.5-80.5t79.5-34h55v-297q0-55.833-39.323-94.917Q701.353-780 645.176-780 589-780 549.5-740.917 510-701.833 510-646v331q0 80.925-57.053 137.962Q395.894-120 314.947-120ZM180-672h54q21 0 38-16.5t17-37.5q0-21-17-37.5T234-780q-21 0-37.5 16.5T180-726v54Zm545.5 492q21.5 0 37.5-16.5t16-38.5v-54h-54q-21 0-37 16.5t-16 38q0 21.5 16 38t37.5 16.5Zm-1.5-56ZM236-728Z"
		/>
	</svg>
);
