import * as sdk from 'pkg/core/sdk';
import * as endpoints from 'pkg/api/endpoints/auto';

export interface PricePayload {
	productId: number;
	cost: number;
	currency: string;
	description?: string;
	recurring: boolean;
	recurringInterval?: string;
	recurringIntervalCount?: number;
	taxRateId: number;
	title: string;
}

export interface IUpdatePricePayload {
	description?: string;
	taxRateId?: number;
	title?: string;
	archived?: boolean;
}

export interface UpdateProductPayload {
	description?: string;
	name?: string;
	productCategoryId?: number;
	isDefault?: boolean;
	archived?: boolean;
}

export const update = (
	productId: number,
	payload: UpdateProductPayload
): Promise<Response> =>
	sdk.patch(endpoints.Products.Update(productId), {}, payload);

export const createProductPrice = (payload: PricePayload): Promise<Response> =>
	sdk.post(endpoints.ProductPrices.Create(), {}, payload);

export const updateProductPrice = (
	priceId: number,
	payload: IUpdatePricePayload
): Promise<Response> =>
	sdk.patch(endpoints.ProductPrices.Update(priceId), {}, payload);
