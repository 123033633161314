import styled from 'styled-components';

import * as styles from 'pkg/config/styles';

import { EventTypes, eventTypeToTranslatedString } from 'pkg/models/event';

import rgba from 'pkg/rgba';
import { pushState } from 'pkg/router/state';
import * as routes from 'pkg/router/routes';
import { useOrganizationIdentity } from 'pkg/identity/organization';

import * as ContextMenu from 'design/context_menu';

const Circle = styled.div<{ eventType: string }>`
	width: 9px;
	height: 9px;
	background-color: ${({ eventType }) =>
		rgba(styles.palette.events[eventType].foregroundColor)};
	border-radius: 100%;
`;

interface EventTypeContextMenItemProps {
	eventType: string;
	startsAt?: string;
	endsAt?: string;
}

const EventTypeContextMenuItem = ({
	eventType,
	startsAt,
	endsAt,
}: EventTypeContextMenItemProps): JSX.Element => {
	const { organization } = useOrganizationIdentity();
	const linkParams: { eventType: string; startsAt?: string; endsAt?: string } =
		{ eventType };

	if (startsAt) {
		linkParams.startsAt = startsAt;
	}

	if (endsAt) {
		linkParams.endsAt = endsAt;
	}

	const handleClick = () => {
		pushState(
			`${routes.Event.Create(organization.id)}?${new URLSearchParams(
				linkParams
			)}`
		);
	};

	return (
		<ContextMenu.LinkItem onClick={handleClick}>
			<Circle eventType={eventType} />
			{eventTypeToTranslatedString(eventType as EventTypes)}
		</ContextMenu.LinkItem>
	);
};

export default EventTypeContextMenuItem;
