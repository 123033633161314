import { ReactNode } from 'react';

export const symbol_sports_golf: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-320q-117 0-198.5-81.5T200-600q0-117 81.5-198.5T480-880q117 0 198.5 81.5T760-600q0 117-81.5 198.5T480-320Zm0-60q92 0 156-64t64-156q0-92-64-156t-156-64q-92 0-156 64t-64 156q0 92 64 156t156 64Zm-84-234q12 0 21-9t9-21q0-12-9-21t-21-9q-12 0-21 9t-9 21q0 12 9 21t21 9Zm168 0q12 0 21-9t9-21q0-12-9-21t-21-9q-12 0-21 9t-9 21q0 12 9 21t21 9Zm-84-90q12 0 21-9t9-21q0-12-9-21t-21-9q-12 0-21 9t-9 21q0 12 9 21t21 9ZM450-80v-48q0-36-23-59t-59-23h-58q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T310-270h340q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T650-210h-58q-36 0-59 23t-23 59v48h-60Zm30-520Z"
		/>
	</svg>
);
