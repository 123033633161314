import { ReactNode } from 'react';

export const symbol_line_end_arrow: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M486-229q-15 10-30.5 1.191Q440-236.617 440-255v-195H110q-12.75 0-21.375-8.675Q80-467.351 80-480.175 80-493 88.625-501.5T110-510h330v-195q0-18.383 15.5-27.192Q471-741 486-731l354 226q14 8.786 14 24.893T840-455L486-229Zm14-80 268-171-268-171v342Zm0-171Z"
		/>
	</svg>
);
