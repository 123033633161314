import { Capacitor } from '@capacitor/core';
import { Haptics, ImpactStyle } from '@capacitor/haptics';

import { isApp } from 'pkg/platform';

const hapticsAvailable: boolean =
	Capacitor.isPluginAvailable('Haptics') && isApp();

const AsyncNoOp = async (): Promise<void> => null;

type HapticVibrateCallback = (duration?: number) => Promise<void>;

export function useHapticVibrate(): HapticVibrateCallback {
	if (!hapticsAvailable) return AsyncNoOp;

	return async (duration: number = 300) => Haptics.vibrate({ duration });
}

// @note For naming concistensy
export enum HapticFeedbackImpact {
	Heavy = ImpactStyle.Heavy,
	Medium = ImpactStyle.Medium,
	Light = ImpactStyle.Light,
}

type HapticFeedbackCallback = (impact?: HapticFeedbackImpact) => Promise<void>;

export function useHapticFeedback(): HapticFeedbackCallback {
	if (!hapticsAvailable) return AsyncNoOp;

	return async (impact?: HapticFeedbackImpact) =>
		Haptics.impact({ style: impact as unknown as ImpactStyle });
}
