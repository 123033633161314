import { ReactNode } from 'react';

export const symbol_do_not_step: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m713-319-42-42 207-207q17 17.315 17 42.157Q895-501 878-484L713-319Zm-70-70-42-42 165-165-200-200-165 165-42-42 165-165q17.122-17 42.061-17T608-838l242 242-207 207ZM785-77 558-304 424-170H90q-24.75 0-42.375-17.625T30-230v-55q0-22.88 12.827-40.48Q55.654-343.08 76-351q17-6 42.5-19t53.5-36l46.885 46.885Q224-354 230.182-354T241-359q5-4.636 5-10.818 0-6.182-4.898-11.273L198-425.212Q206-432 216-442t19-21l42.947 42.947Q283-415 289.182-415q6.182 0 10.818-5 4-4 4.5-10.5t-4.339-11.339L255-487q9-11 17.5-24t11.5-23l52.133 54.28q4.6 4.72 10.734 4.72Q353-475 358-480q5-5 5-11.5t-4-10.5L77-784q-9-9-9-21.158 0-12.158 8.609-21Q86-835 98.467-835q12.466 0 21.533 9l707 707q9 9 9 21t-9 21q-9 9-21 9t-21-9ZM92-230h307l117-117-104-104-133 133q-19 19-52.5 19T175-318l-10-10q-21 12-38.5 20T92-293v63Zm320-221Zm116-53Z"
		/>
	</svg>
);
