import { ReactNode } from 'react';

export const symbol_switch_right: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M590-242q-11 0-20.5-8t-9.5-22v-416q0-14 9.5-22t20.5-8q5 0 10.5 2t10.5 7l208 208q5 5 7 10t2 11q0 6-2 11t-7 10L611-251q-5 5-10.5 7t-10.5 2Zm25-91 147-147-147-147v294Zm-245 91q-5 0-10.5-2t-10.5-7L141-459q-5-5-7-10t-2-11q0-6 2-11t7-10l208-208q5-5 10.5-7t10.5-2q11 0 20.5 8t9.5 22v416q0 14-9.5 22t-20.5 8Z"
		/>
	</svg>
);
