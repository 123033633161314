import * as endpoints from 'pkg/api/endpoints/auto';
import * as sdk from 'pkg/core/sdk';

export const postActivity = (id, data) => {
	return sdk.post(endpoints.UserActivities.Create(id), {}, data);
};

export const updateActivity = (id, data) => {
	return sdk.patch(endpoints.UserActivities.Update(id), {}, data);
};

export const removeActivity = (id) => {
	return sdk.destroy(endpoints.UserActivities.Delete(id));
};
