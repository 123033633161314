import { t } from '@transifex/native';
import { Fragment, MouseEvent, useState } from 'react';

import MaterialSymbol from 'components/material-symbols';

import IconSearch from 'components/material-symbols/icon-context-menu/IconSearch';
import Column from 'components/layout/column';
import Row from 'components/layout/row';
import {
	MaterialSymbols,
	MaterialSymbolVariant,
} from 'components/material-symbols/symbols';

import Button from 'design/button';
import * as Context from 'design/context_menu';

import * as css from './styles.css';

interface IconContextMenuProps {
	iconName: MaterialSymbolVariant;
	iconSearch?: string;
	setIconSearch?: React.Dispatch<React.SetStateAction<string>>;
	onIconSelect: (iconName: string) => void;
}

type IconLibraryProps = Omit<IconContextMenuProps, 'iconName'>;

function IconLibrary({
	onIconSelect,
	iconSearch,
	setIconSearch,
}: IconLibraryProps): JSX.Element {
	const icons = Object.keys(MaterialSymbols).filter((icon) =>
		icon.includes(iconSearch)
	);
	const ctx = Context.useContextMenu();

	const handleClick = (event: MouseEvent<HTMLElement>) => {
		onIconSelect(event.currentTarget.dataset.iconName);
		ctx.close();
	};
	return (
		<Fragment>
			<Context.Pane padding="var(--spacing-5) var(--spacing-6) var(--spacing-4) var(--spacing-6)">
				<IconSearch onChange={setIconSearch} />
			</Context.Pane>
			<Context.Divider />
			<Context.Pane>
				<div className={css.iconWrapper}>
					{icons.map((variant: MaterialSymbolVariant, i) => {
						return (
							<Column>
								<div
									className={css.iconItem}
									key={i}
									data-icon-name={variant}
									onClick={handleClick}>
									<MaterialSymbol
										className={css.symbol}
										variant={variant}
										scale={1.5}
										actualSize
									/>
									<div className={css.iconName}>
										{variant.replace(/_/g, ' ')}
									</div>
								</div>
							</Column>
						);
					})}
				</div>
			</Context.Pane>
		</Fragment>
	);
}

export function IconContextMenu({
	iconName,
	onIconSelect: handleIconNameChange,
}: IconContextMenuProps): JSX.Element {
	const [iconSearch, setIconSearch] = useState('');

	const resetIconSearch = () => setIconSearch('');

	return (
		<Context.Menu
			width="500px"
			onClose={resetIconSearch}
			toggleWith={
				<Row>
					<Button secondary label={t('Select Icon')} />
					<MaterialSymbol className={css.symbol} variant={iconName} />
				</Row>
			}>
			<IconLibrary
				onIconSelect={handleIconNameChange}
				iconSearch={iconSearch}
				setIconSearch={setIconSearch}
			/>
		</Context.Menu>
	);
}
