import { t } from '@transifex/native';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import { useEndpoint } from 'pkg/api/use_endpoint';
import { pushState } from 'pkg/router/state';
import * as routes from 'pkg/router/routes';
import { useCurrentRoute } from 'pkg/router/hooks';
import { useCurrentMembership } from 'pkg/identity';
import { newChatParams } from 'pkg/chat';

import SectionTitle from 'components/SectionTitle';
import Icon from 'components/icon';
import Avatar from 'components/avatar';

import * as UserCard from 'components/user/UserCard';
import Column from 'components/layout/column';

import * as ContextMenu from 'design/context_menu';

interface ListRelatives {
	parents: models.user.User[];
	children: models.user.User[];
}

interface RelativeViewProps {
	userId: number;
	isUserProfile?: boolean;
}

interface RelativeViewItemProps {
	user: models.user.User;
	isUserProfile: boolean;
}

function RelativeViewItem({
	user,
	isUserProfile,
}: RelativeViewItemProps): JSX.Element {
	const { groupId, organizationId } = useCurrentRoute();
	const activeMembership = useCurrentMembership();

	const viewProfile = () => {
		pushState(
			routes.User.Profile.Show(organizationId, groupId, user.id, 'overview')
		);
	};

	const viewAccount = () => {
		pushState(
			routes.Management.Contact.Show(organizationId, user.id, 'overview')
		);
	};

	const handleNewChat = () =>
		pushState(routes.Chat.New(organizationId), newChatParams([user]));

	const userActions = isUserProfile
		? [
				<ContextMenu.Item onClick={viewProfile} key="show-profile">
					<ContextMenu.ItemIcon name="contact_page" />
					{t(`View Profile`)}
				</ContextMenu.Item>,
			]
		: [];

	const newChatItem = (
		<ContextMenu.LinkItem onClick={handleNewChat} key="new-chat">
			<ContextMenu.ItemIcon name="add_comment" />
			{t(`Message`)}
		</ContextMenu.LinkItem>
	);

	const sendMailItem = (
		<ContextMenu.LinkItem href={`mailto:${user.email}`} key="send-mail">
			<ContextMenu.ItemIcon name="mail" />
			{t(`Send Email`)}
		</ContextMenu.LinkItem>
	);

	if (activeMembership.userId !== user.id) {
		userActions.push(newChatItem, sendMailItem);
	}

	return (
		<UserCard.Base clickable>
			{!isUserProfile && (
				<UserCard.User onClick={viewAccount}>
					<Avatar user={user} size={40} />
					<strong>{models.user.fullName(user)}</strong>
				</UserCard.User>
			)}

			{isUserProfile && (
				<UserCard.User onClick={viewProfile}>
					<Avatar user={user} size={40} />
					<strong>
						{models.user.fullName(user)}
						{user.id === activeMembership?.userId && ` (${t('You')})`}
					</strong>
				</UserCard.User>
			)}

			{userActions.length > 0 && (
				<UserCard.Actions>
					<ContextMenu.Menu
						toggleWith={
							<ContextMenu.ButtonTrigger>
								<Icon name="context-menu" />
							</ContextMenu.ButtonTrigger>
						}>
						{userActions}
					</ContextMenu.Menu>
				</UserCard.Actions>
			)}
		</UserCard.Base>
	);
}

export default function RelativeView({
	userId,
	isUserProfile = false,
}: RelativeViewProps): JSX.Element {
	const { record: summary } = useEndpoint<ListRelatives>(
		endpoints.Users.ListRelatives(userId)
	);

	return (
		(summary.children || summary.parents) && (
			<Column>
				{summary.children && (
					<Column>
						<SectionTitle icon="supervisor_account">
							{t(`Children`)}
						</SectionTitle>
						{summary.children.map((user) => (
							<RelativeViewItem
								user={user}
								isUserProfile={isUserProfile}
								key={user.id}
							/>
						))}
					</Column>
				)}

				{summary.parents && (
					<Column>
						<SectionTitle icon="supervisor_account">
							{t(`Parents`)}
						</SectionTitle>
						{summary.parents.map((user) => (
							<RelativeViewItem
								user={user}
								isUserProfile={isUserProfile}
								key={user.id}
							/>
						))}
					</Column>
				)}
			</Column>
		)
	);
}
