import * as flashActions from 'pkg/actions/flashes';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as sdk from 'pkg/core/sdk';
import { crash } from 'pkg/errors/errors';

export const exportPayoutRows = async (
	id: string
): Promise<[boolean, string]> => {
	const req = await sdk.post(endpoints.PayoutRow.Export(), {
		payout_id: Number.parseInt(id, 10),
	});

	if (req.status === 501) {
		flashActions.show({
			title: 'This export is coming soon!',
		});
		return [false, null];
	}

	if (req.ok) {
		const text = await req.text();
		return [true, text];
	}

	flashActions.show({
		title: crash().title,
	});

	return [false, null];
};
