import { ReactNode } from 'react';

export const symbol_emoticon: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M249.902-280Q229-280 214.5-294.5T200-329.902Q200-350 214.5-365t35.402-15Q270-380 285-365t15 35.098Q300-309 285-294.5T249.902-280Zm0-300Q229-580 214.5-594.5T200-629.902Q200-650 214.5-665t35.402-15Q270-680 285-665t15 35.098Q300-609 285-594.5T249.902-580ZM430-450q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T430-510h100q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T530-450H430Zm270-30q0-57.582-16.535-110.898Q666.93-644.214 635-689q-8-11-7.5-24t10.5-21q10-8 22-6.5t20 12.5q37.895 53.215 58.947 115.968Q760-549.279 760-480q0 57.418-14.5 109.709Q731-318 705-272q-5.8 11.087-17.4 14.043Q676-255 665-262q-11-7-13-19.5t4-23.5q20.842-40.217 32.421-83.696Q700-432.174 700-480Z"
		/>
	</svg>
);
