import { ReactNode } from 'react';

export const symbol_wounds_injuries: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m221-179-72 72q-9 9-21 9t-21-9q-9-9-9-21.5t9-21.5l72-71-66-18q-12-3-18-14t-3-23q3-12 14-18t23-3l65 18-17-66q-3-12 3-23t18-14q12-3 23 3t14 18l17 66 170-169-66-18q-12-3-18-14t-3-23q3-12 14-18t23-3l66 18-18-66q-3-12 3-23t18-14q12-3 23 3t14 18l18 66 169-170-66-17q-12-3-18-14t-3-23q3-12 14-18t23-3l66 17-18-65q-3-12 3-23t18-14q12-3 23 3t14 18l18 66 72-72q9-9 21-9t21 9q9 9 9 21.5t-9 21.5l-72 71 66 18q12 3 18 14t3 23q-3 12-14 18t-23 3l-65-18 17 66q3 12-3 23t-18 14q-12 3-23-3t-14-18l-17-66-170 169 66 18q12 3 18 14t3 23q-3 12-14 18t-23 3l-66-18 18 66q3 12-3 23t-18 14q-12 3-23-3t-14-18l-18-66-169 170 66 17q12 3 18 14t3 23q-3 12-14 18t-23 3l-66-17 18 65q3 12-3 23t-18 14q-12 3-23-3t-14-18l-18-66Z"
		/>
	</svg>
);
