import { ReactNode } from 'react';

export const symbol_tenancy: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M160-120q-50 0-85-35t-35-85q0-50 35-85t85-35q11.423 0 22.212 2Q193-356 204-352l160-219q-20-22-32-49.5T320-680q0-66 47-113t113-47q66 0 113 47t47 113q0 32-11.892 59.425Q616.216-593.151 596-571l160 220q11-5 21.5-7t22.5-2q50 0 85 35t35 85q0 50-35 85t-85 35q-50 0-85-35t-35-85q0-22 7.5-41t20.5-35L548-535q-9 5-18.5 7.5t-19.5 4.443V-356q39 10 64.5 42t25.5 74q0 50-35 85t-85 35q-50 0-85-35t-35-85q0-42 25.5-74t64.5-42v-167.057Q440-525 430.5-527.5 421-530 412-535L252-316q13 16 20.5 35t7.5 41q0 50-35 85t-85 35Zm0-60q25.5 0 42.75-17.25T220-240q0-25.5-17.25-42.75T160-300q-25.5 0-42.75 17.25T100-240q0 25.5 17.25 42.75T160-180Zm320-500Zm0 500q25.5 0 42.75-17.25T540-240q0-25.5-17.25-42.75T480-300q-25.5 0-42.75 17.25T420-240q0 25.5 17.25 42.75T480-180Zm320 0q25.5 0 42.75-17.25T860-240q0-25.5-17.25-42.75T800-300q-25.5 0-42.75 17.25T740-240q0 25.5 17.25 42.75T800-180Zm-640-60Zm320 0Zm320 0ZM480-580q42 0 71-29t29-71q0-42-29-71t-71-29q-42 0-71 29t-29 71q0 42 29 71t71 29Z"
		/>
	</svg>
);
