import { ReactNode } from 'react';

export const symbol_nest_tag: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M479.874-80Q367-80 288.5-158.667t-78.5-191.6q0-90.733 55-163.457Q320-586.448 410-612v-238q0-12 8.625-21T440-880h80q12 0 21 9t9 21v238q89 25.552 144.5 98.276Q750-441 750-350.267q0 112.933-79.108 191.6Q591.783-80 479.874-80Zm.02-60Q567-140 628.5-200.869q61.5-60.87 61.5-149.025Q690-437 628.616-498.5T480.141-560Q392-560 331-498.616q-61 61.384-61 148.475Q270-262 330.869-201q60.87 61 149.025 61Z"
		/>
	</svg>
);
