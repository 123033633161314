import { ReactNode } from 'react';

export const symbol_edit_notifications: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-80q-33 0-56.5-23.5T400-160h160q0 33-23.5 56.5T480-80Zm290-120H190q-13 0-21.5-8.5T160-230q0-13 8.5-21.5T190-260h50v-304q0-83 49-150t131-84v-22q0-25 17.5-42.5T480-880q25 0 42.5 17.5T540-820v24q8 2 15 3.5t12 3.5q11 3 17 14t4 23q-2 12-13 19t-23 3q-23-7-37-10.5t-35-3.5q-75 0-127.5 52.5T300-564v304h360v-153q0-15 9.5-22.5T690-443q11 0 20.5 7.5T720-413v153h50q13 0 21.5 8.5T800-230q0 13-8.5 21.5T770-200ZM480-440Zm0-30v-81q0-6 2-11t7-10l212-211q9-9 20-13t22-4q12 0 23 4.5t20 13.5l37 37q9 9 13 20t4 22q0 11-4.5 22.5T822-660L612-449q-5 5-10 7t-11 2h-81q-13 0-21.5-8.5T480-470Zm300-233-37-37 37 37ZM540-500h38l121-122-18-19-19-18-122 121v38Zm141-141-19-18 37 37-18-19Z"
		/>
	</svg>
);
