import * as endpoints from 'pkg/api/endpoints/auto';
import * as sdk from 'pkg/core/sdk';

export const fetchAccountRelations = async (
	includeRequests = false
): Promise<[Response, any]> => {
	const payload: {
		requests: string;
	} = { requests: '0' };

	if (includeRequests) {
		payload.requests = '1';
	}

	const req = await sdk.get(endpoints.AccountRelations.Index(), payload);
	const resp = await req.json();

	return [req, resp];
};

export const createAccountRelation = async (
	type: string,
	email: string
): Promise<[Response, any]> => {
	const req = await sdk.post(
		endpoints.AccountRelations.Create(),
		{},
		{
			type,
			email,
		}
	);

	const resp = await req.json();

	return [req, resp];
};

export const approveAccountRelationRequest = async (
	accountRelationId: number
): Promise<[Response, any]> => {
	const req = await sdk.post(
		endpoints.AccountRelations.Approve(accountRelationId)
	);
	const resp = await req.json();
	return [req, resp];
};

export const removeAccountRelation = async (
	accountRelationId: number
): Promise<[Response, any]> => {
	const req = await sdk.destroy(
		endpoints.AccountRelations.Delete(accountRelationId)
	);
	const resp = await req.json();
	return [req, resp];
};
