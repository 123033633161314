import { ChangeEvent, Fragment, useState } from 'react';
import { RRule, Options, rrulestr } from 'rrule';
import { t } from '@transifex/native';

import {
	RRuleToText,
	ruleFreqString,
	ruleStrings,
} from 'routes/event/rRuleConfig';
import CustomRecurringOptions from 'routes/event/components/CustomRecurringOptions';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import * as Input from 'components/form/inputs';
import Column from 'components/layout/column';
import Row from 'components/layout/row';

interface RecurringSettingsProps {
	startsAt: number;
	rruleString: string;
	onDay: boolean;
	setOnDay: (onDay: boolean) => void;
	setRuleString: (rRuleString: string) => void;
	// disables the "does not repeat" option (for when editing
	// an event series)
	disableNoRepeat?: boolean;
}

const RecurringSettings = ({
	startsAt,
	rruleString,
	onDay,
	setOnDay,
	setRuleString,
	disableNoRepeat,
}: RecurringSettingsProps): JSX.Element => {
	const startsAtDate = new Date(startsAt * 1000);
	const untilDate = new Date(new Date(startsAt * 1000).setHours(23, 59));

	const rule =
		rruleString !== ''
			? rrulestr(rruleString)
			: new RRule({
					freq: RRule.DAILY,
					interval: 1,
					dtstart: new Date(
						Date.UTC(
							startsAtDate.getUTCFullYear(),
							startsAtDate.getUTCMonth(),
							startsAtDate.getUTCDate(),
							startsAtDate.getUTCHours(),
							startsAtDate.getUTCMinutes()
						)
					),
					until: new Date(
						Date.UTC(
							untilDate.getUTCFullYear(),
							untilDate.getUTCMonth(),
							untilDate.getUTCDate(),
							untilDate.getUTCHours(),
							untilDate.getUTCMinutes()
						)
					),
				});

	const [repeat, setRepeat] = useState(
		rruleString !== '' ? ruleFreqString(rule) : ''
	);
	const recurringEndsAtDate = new Date(rule.options.until);

	const onRepeatChange = (
		e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
	) => {
		const repeatOption = e.target.value;
		const freq = ruleStrings[repeatOption];

		const newRuleOptions: Partial<Options> = {
			dtstart: rule.options.dtstart,
			until: rule.options.until,
			freq,
		};

		if (repeatOption === '') {
			setRuleString('');
			setRepeat('');
			return;
		} else if (repeatOption === 'custom') {
			newRuleOptions.freq = RRule.DAILY;
			newRuleOptions.interval = 1;
		} else {
			newRuleOptions.interval = repeatOption === 'bi_weekly' ? 2 : 1;
		}

		setRepeat(repeatOption);
		setRuleString(new RRule(newRuleOptions).toString());
	};

	const onEndDateChange = (date: Date) => {
		setRuleString(
			new RRule({
				...rule.origOptions,
				until: new Date(
					Date.UTC(
						date.getUTCFullYear(),
						date.getUTCMonth(),
						date.getUTCDate(),
						date.getUTCHours(),
						date.getUTCMinutes()
					)
				),
			}).toString()
		);
	};

	const translatedInterval = (key: string): string => {
		switch (key) {
			case 'bi_weekly':
				return t('Bi-weekly');
			case 'daily':
				return t('Daily');
			case 'monthly':
				return t('Monthly');
			case 'weekly':
				return t('Weekly');
			case 'yearly':
				return t('Yearly');
		}
	};

	const recurringInputs = (
		<Fragment>
			<Input.Group label={t(`Repeat`)}>
				<Input.Select
					value={repeat}
					onChange={onRepeatChange}
					testid="events.form.recurring.select">
					{!disableNoRepeat && <option value="">{t(`Does not repeat`)}</option>}
					{Object.keys(ruleStrings).map((key) => {
						return (
							<option key={key} value={key}>
								{translatedInterval(key)}
							</option>
						);
					})}
					<option value="custom">{t('Custom')}</option>
				</Input.Select>
			</Input.Group>

			<Input.Group label={t(`Until`)} disabled={!repeat}>
				<div>
					<Input.DateTimePicker
						date={recurringEndsAtDate}
						onDateChange={onEndDateChange}
						disableDatesBefore={startsAtDate}
						placeholder={t('Select date')}
						hideValue={!repeat}
						hideTimeInput
					/>
				</div>
			</Input.Group>
		</Fragment>
	);

	return (
		<Fragment>
			<Input.Group>
				<LargeScreen>
					<Row>{recurringInputs}</Row>
				</LargeScreen>
				<SmallScreen>
					<Column>{recurringInputs}</Column>
				</SmallScreen>
				{repeat !== 'custom' && repeat !== '' && (
					<span>{RRuleToText(rule)}</span>
				)}
			</Input.Group>
			{repeat === 'custom' && (
				<CustomRecurringOptions
					rruleString={rruleString}
					onDay={onDay}
					setOnDay={setOnDay}
					setRuleString={setRuleString}
				/>
			)}
		</Fragment>
	);
};

export default RecurringSettings;
