import { ReactNode } from 'react';

export const symbol_living: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-80q-24 0-42-18t-18-42v-680q0-24 18-42t42-18h680q24 0 42 18t18 42v680q0 24-18 42t-42 18H140Zm0-60h680v-680H140v680Zm127-101h426q32 0 51-19t19-51v-160q0-28-16-49t-38-32v-46q0-47.083-32.958-80.042Q643.083-711 596-711H364q-47.083 0-80.042 32.958Q251-645.083 251-598v47q-22 11-38 32t-16 49v159q0 32 19 51t51 19Zm0-50q-8 0-14-6t-6-14v-159.618Q247-490 258.5-501.5T289-513q19 0 30.5 11.5t11.5 30.428V-382h298v-89.072Q629-490 640.5-501.5T671-513q19 0 30.5 11.5t11.5 30.882V-311q0 8-6 14t-14 6H267Zm114-141v-38q0-38-23.5-61.5T301-557v-41q0-27.3 17.889-45.15Q336.778-661 364.137-661h231.504Q623-661 641-643.15T659-598v41q-33 2-56.5 25.5T579-470v38H381ZM140-140v-680 680Z"
		/>
	</svg>
);
