import { t } from '@transifex/native';

import { Record } from 'pkg/api/models/record';
import { Attachment } from 'pkg/api/models/attachment';
import { Group } from 'pkg/api/models/group';
import { Dateable } from 'pkg/api/models/dateable';
import { Linkable } from 'pkg/api/models/linkable';
import * as models from 'pkg/api/models';
import { getTimeZoneFromSessionStorage } from 'pkg/timezone';
import { Session } from 'pkg/api/models/session';
import { Granularity } from 'pkg/datetime';

export enum EventTypes {
	Camp = 'camp',
	Cup = 'cup',
	Match = 'match',
	Meeting = 'meeting',
	Practice = 'practice',
	Uncategorized = 'uncategorized',
}

export enum EventStatus {
	Canceled = 'canceled',
	Published = 'published',
	Draft = 'draft',
}

export enum RSVPInterval {
	RSVPIntervalHour = 'hour',
	RSVPIntervalDay = 'day',
	RSVPIntervalWeek = 'week',
}

export enum EventFlags {
	EventFlagsAutoInviteUsers = 'autoInviteUsers',
	EventFlagsAutoInviteAdminAndStaff = 'autoInviteAdminAndStaff',
}

export const rsvpIntervalMappedToGranularity = {
	[RSVPInterval.RSVPIntervalDay]: Granularity.day,
	[RSVPInterval.RSVPIntervalHour]: Granularity.hour,
	[RSVPInterval.RSVPIntervalWeek]: Granularity.week,
};

export const eventTypeLabels = [
	EventTypes.Match,
	EventTypes.Practice,
	EventTypes.Meeting,
	EventTypes.Camp,
	EventTypes.Cup,
	EventTypes.Uncategorized,
];

export const sortEventTypesByLabels = (a: EventTypes, b: EventTypes) => {
	if (eventTypeLabels.indexOf(a) > eventTypeLabels.indexOf(b)) {
		return 1;
	}

	if (eventTypeLabels.indexOf(a) < eventTypeLabels.indexOf(b)) {
		return -1;
	}

	return 0;
};

export interface Event extends Record, Dateable, Linkable {
	eventSeriesId: number;
	userId: number;
	startsAt: number;
	endsAt: number;
	startsAtLocal?: string;
	endsAtLocal?: string;
	updatedAt: number;
	deletedAt: number;
	timezone: string;
	status: EventStatus;
	location: string;
	title: string;
	groupId: number;
	description: string;
	notes: string;
	isPrivate: boolean;
	isPublic: boolean;
	hideParticipants: boolean;
	physicalStrainDefault: number;
	lokLastReportedDate: number;
	meetBeforeMinutes: number;
	// rsvpIntervalCount is the number of RSVPInterval to require rsvp before. This is in relation to startsAt.
	rsvpIntervalCount: number;
	// rsvpInterval is the time unit of rsvpIntervalCount
	rsvpInterval: RSVPInterval;
	// rsvpBefore is the timestamp that a user must rsvp before
	rsvpBefore: number;
	flags: EventFlags[];

	// User that has created the event
	user: models.user.User;
	type: EventTypes;
	activities?: models.userActivity.UserActivity[];

	bookings?: models.booking.Booking[];
	users?: models.eventUser.EventUser[];
	eventSeries?: models.eventSeries.EventSeries;
	group?: Group;
	matches?: models.match.Match[];
	attachments?: Attachment[];
	sessions?: Session[];
}

const _context = 'eventTypes';

export function getTypeTranslation(eventType: EventTypes) {
	switch (eventType) {
		case EventTypes.Camp: {
			return t('Camp');
		}
		case EventTypes.Cup: {
			return t('Cup');
		}
		case EventTypes.Match: {
			return t('Match');
		}
		case EventTypes.Meeting: {
			return t('Meeting');
		}
		case EventTypes.Practice: {
			return t('Practice');
		}
		case EventTypes.Uncategorized: {
			return t('Other', { _context });
		}
	}
}

export function hasStarted(event: Event): boolean {
	const now = Date.now() / 1000;

	return now > event.startsAt;
}

export function hasEnded(event: Event): boolean {
	const now = Date.now() / 1000;

	return now > event.endsAt;
}

export function rsvpHasPassed(event: Event): boolean {
	const now = Date.now() / 1000;

	return now > event.rsvpBefore;
}

export function isCanceled(event: Event): boolean {
	return event.status === EventStatus.Canceled;
}

export function canEdit(event: Event) {
	return !!event.links?.edit;
}

export function canDelete(event: Event) {
	return !!event.links?.delete;
}

export function hasEventSeries(event: Event) {
	return !!event.eventSeriesId;
}

export function timezoneIsDifferent(event: Event) {
	const g = getTimeZoneFromSessionStorage();
	return event.timezone ? g?.name !== event.timezone : false;
}

export function canUpdateAveragePhysicalStrain(event: Event): boolean {
	if (event.activities.length === 0) {
		return true;
	}

	const canUpdate = event.activities
		.map(
			(activity: models.userActivity.UserActivity) => activity.physicalStrain
		)
		.every((strain: number) => strain === event.physicalStrainDefault);

	return canUpdate;
}

export function getStartsAtIncludingMeetBeforeMinutes(event: Event) {
	if (event.meetBeforeMinutes > 0) {
		const mins = event.meetBeforeMinutes * 60;
		return Math.round(event.startsAt - mins);
	} else {
		return event.startsAt;
	}
}
