import { ReactNode } from 'react';

export const symbol_water_do: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M300-370q13 0 21.5-9t8.5-21q0-13-8.5-21.5T300-430q-12 0-21 8.5t-9 21.5q0 12 9 21t21 9Zm60-120q13 0 21.5-9t8.5-21q0-13-8.5-21.5T360-550q-12 0-21 8.5t-9 21.5q0 12 9 21t21 9Zm0 240q13 0 21.5-9t8.5-21q0-13-8.5-21.5T360-310q-12 0-21 8.5t-9 21.5q0 12 9 21t21 9Zm60-120q13 0 21.5-9t8.5-21q0-13-8.5-21.5T420-430q-12 0-21 8.5t-9 21.5q0 12 9 21t21 9Zm0-240q13 0 21.5-9t8.5-21q0-13-8.5-21.5T420-670q-12 0-21 8.5t-9 21.5q0 12 9 21t21 9Zm60 120q13 0 21.5-9t8.5-21q0-13-8.5-21.5T480-550q-12 0-21 8.5t-9 21.5q0 12 9 21t21 9Zm0 240q13 0 21.5-9t8.5-21q0-13-8.5-21.5T480-310q-12 0-21 8.5t-9 21.5q0 12 9 21t21 9Zm60-120q13 0 21.5-9t8.5-21q0-13-8.5-21.5T540-430q-12 0-21 8.5t-9 21.5q0 12 9 21t21 9Zm0-240q13 0 21.5-9t8.5-21q0-13-8.5-21.5T540-670q-12 0-21 8.5t-9 21.5q0 12 9 21t21 9Zm60 120q13 0 21.5-9t8.5-21q0-13-8.5-21.5T600-550q-12 0-21 8.5t-9 21.5q0 12 9 21t21 9Zm0 240q13 0 21.5-9t8.5-21q0-13-8.5-21.5T600-310q-12 0-21 8.5t-9 21.5q0 12 9 21t21 9Zm60-120q13 0 21.5-9t8.5-21q0-13-8.5-21.5T660-430q-12 0-21 8.5t-9 21.5q0 12 9 21t21 9ZM480-80q-137 0-228.5-94T160-408q0-64 29-127t72.5-121q43.5-58 94-108.5T450-854q7-6 14.5-8.5T480-865q8 0 15.5 2.5T510-854q44 39 94.5 89.5t94 108.5Q742-598 771-535t29 127q0 140-91.5 234T480-80Zm0-60q112 0 186-76.5T740-408q0-79-66.5-179.5T480-800Q353-688 286.5-587.5T220-408q0 115 74 191.5T480-140Zm0-340Z"
		/>
	</svg>
);
