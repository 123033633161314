import { ReactNode } from 'react';

export const symbol_skip_previous: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M220-270v-420q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T280-690v420q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T220-270Zm473-3L430-455q-7-5-10-11.296-3-6.297-3-13.704 0-7.407 3-13.704Q423-500 430-505l263-182q4-3 8-4.5t9-1.5q12 0 21 8.25t9 21.75v366q0 13.5-9 21.75T710-267q-5 0-9-1.5t-8-4.5Zm-13-207Zm0 125v-250L499-480l181 125Z"
		/>
	</svg>
);
