import { ReactNode } from 'react';

export const symbol_align_horizontal_left: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M109.825-80Q97-80 88.5-88.625T80-110v-740q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-850v740q0 12.75-8.675 21.375Q122.649-80 109.825-80ZM290-290q-20.833 0-35.417-14.618Q240-319.235 240-340.118 240-361 254.583-375.5 269.167-390 290-390h300q20.833 0 35.417 14.618Q640-360.765 640-339.882 640-319 625.417-304.5 610.833-290 590-290H290Zm0-280q-20.833 0-35.417-14.618Q240-599.235 240-620.118 240-641 254.583-655.5 269.167-670 290-670h540q20.833 0 35.417 14.618Q880-640.765 880-619.882 880-599 865.417-584.5 850.833-570 830-570H290Z"
		/>
	</svg>
);
