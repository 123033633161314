// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HSqanz8T9nAImrL9DMig {
	font-weight: var(--font-weight-semibold);
}
.HSqanz8T9nAImrL9DMig span {
		color: var(--palette-gray-500);
		font-weight: var(--font-weight-normal);
		font-size: var(--font-size-sm);
	}
`, "",{"version":3,"sources":["webpack://./routes/user/billing/payment_methods/styles.css"],"names":[],"mappings":"AAAA;CACC,wCAAwC;AAOzC;AALC;EACC,8BAA8B;EAC9B,sCAAsC;EACtC,8BAA8B;CAC/B","sourcesContent":[".title {\n\tfont-weight: var(--font-weight-semibold);\n\n\tspan {\n\t\tcolor: var(--palette-gray-500);\n\t\tfont-weight: var(--font-weight-normal);\n\t\tfont-size: var(--font-size-sm);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var title = `HSqanz8T9nAImrL9DMig`;
export default ___CSS_LOADER_EXPORT___;
