import { ReactNode } from 'react';

export const symbol_face_2: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-40q-23 0-46-3t-46-8Q302 16 195.5-4.5T33-119q-44-74-30-158t78-142v-3Q17-479 3.5-563T33-721q37-63 101.5-94.5T271-838q32-57 87.5-89.5T480-960q66 0 121.5 32.5T689-838q72-9 136.5 22.5T927-721q43 74 29.5 158T879-422v3q64 58 78 142t-30 158Q871-25 764.5-4.5T571.586-51Q549-46 526-43t-46 3ZM312-77q-41-19-75.5-45.5T174-183q-28-34-48.5-73T93-338q-32 43-33 94t26 96q35 58 100.5 79T312-77Zm336 0q60 29 125.5 8T874-148q27-45 26-96t-33-94q-11 43-32 82t-49 73q-28 34-62.5 60.5T648-77Zm-168-23q142.375 0 241.188-98.812Q820-297.625 820-440q0-34-6.5-66.5T795-569q-29 25-67 39.5T645-515q-97.837 0-166.418-68.582Q410-652.163 410-750v-11.5q0-5.5 1-11.5-117 24-194 116.5T140-440q0 142.375 98.812 241.188Q337.625-100 480-100ZM353.775-343Q331-343 315.5-358.725q-15.5-15.726-15.5-38.5Q300-420 315.725-435.5q15.726-15.5 38.5-15.5Q377-451 392.5-435.275q15.5 15.726 15.5 38.5Q408-374 392.275-358.5q-15.726 15.5-38.5 15.5Zm253 0Q584-343 568.5-358.725q-15.5-15.726-15.5-38.5Q553-420 568.725-435.5q15.726-15.5 38.5-15.5Q630-451 645.5-435.275q15.5 15.726 15.5 38.5Q661-374 645.275-358.5q-15.726 15.5-38.5 15.5ZM87-513q8-42 23.5-80t38.5-72q23-34 52-62t64-50q-54-9-103 14.5T85-691q-26 44-25 89.5T87-513Zm786 0q26-43 27-88.5T875-691q-28-48-77-71.5T695-777q35 22 64 50t52 62q23 34 38.5 72t23.5 80Zm-228-62q40 0 70-12.5t52-34.5q-23-36-53.5-65t-67.608-49.966q-37.107-20.965-78.58-32Q525.839-780 480-780h-7q-2 8-2.5 15t-.5 15q0 72 51.5 123.5T645-575Zm-13-235q-29-38-67-64t-85-26q-47 0-85 26t-67 64q35-14 73-22t79-8q41 0 79 8t73 22ZM149-665Zm662 0ZM473-780Zm7-60ZM174-183Zm612 0Z"
		/>
	</svg>
);
