import { t } from '@transifex/native';

import * as routes from 'pkg/router/routes';
import { useNewTopIndex } from 'pkg/hooks/useTopIndex';
import { useQueryState } from 'pkg/hooks/query-state';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';

import { Spinner } from 'components/loaders/spinner';

import * as css from './styles.css';
export default function LoginRedirect(): JSX.Element {
	const qs = useQueryState();
	const zIndex = useNewTopIndex();

	useComponentDidMount(async () => {
		if (qs.is('return', 'forms')) {
			window.location.assign(
				routes.Registration.Form(qs.get('organizationSlug'), qs.get('guid'))
			);
		} else {
			window.history.pushState({}, '', '/');
			window.location.reload();
		}
	});

	return (
		<div className={css.wrapper} style={{ zIndex }}>
			<Spinner />
			<span>{t('Redirecting to form...')}</span>
		</div>
	);
}
