import { ReactNode } from 'react';

export const symbol_folder_copy: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M100-120q-24.75 0-42.375-17.625T40-180v-530q0-12.75 8.675-21.375Q57.351-740 70.175-740 83-740 91.5-731.375T100-710v530h710q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-120H100Zm120-120q-24.75 0-42.375-17.625T160-300v-520q0-24.75 17.625-42.375T220-880h215q12.444 0 23.722 5T478-862l62 62h320q24.75 0 42.375 17.625T920-740v440q0 24.75-17.625 42.375T860-240H220Zm0-60h640v-440H515l-80-80H220v520Zm0 0v-520 520Z"
		/>
	</svg>
);
