import { t } from '@transifex/native';
import styled from 'styled-components';

import * as routes from 'pkg/router/routes';
import { useCurrentOrganization } from 'pkg/identity';

import StatusScreen from 'components/status-screen';
import { LargeScreen } from 'components/MediaQuery';

import Button from 'design/button';

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
`;

interface SavedContactProps {
	userId: number;
	email: string;
	onAssignProduct: () => void;
	handleCreateAnother?: () => void;
}

const SavedContact: React.FC<React.PropsWithChildren<SavedContactProps>> = ({
	userId,
	email,
	onAssignProduct,
	handleCreateAnother,
}) => {
	const org = useCurrentOrganization();

	return (
		<Wrapper>
			<StatusScreen
				status="success"
				title={t('Contact saved successfully!')}
				description={t(
					'You can now invoice this contact right away {emailUser}',
					{ emailUser: email }
				)}>
				<LargeScreen>
					<Button
						large
						block
						primary
						href={routes.Management.Contacts.Home(org.id)}>
						{t('Finish')}
					</Button>
				</LargeScreen>

				<Button
					large
					block
					href={routes.Management.Contact.Show(org.id, userId, 'overview')}>
					{t('View contact')}
				</Button>
				<Button large block onClick={onAssignProduct}>
					{t('Assign product')}
				</Button>
				{email && (
					<Button large block href={routes.Invoice.New(org.id)}>
						{t('Create invoice')}
					</Button>
				)}
				<Button large block onClick={handleCreateAnother}>
					{t('Add another contact')}
				</Button>
			</StatusScreen>
		</Wrapper>
	);
};

export default SavedContact;
