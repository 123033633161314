import styled from 'styled-components';
import { t } from '@transifex/native';

import * as palette from 'pkg/config/palette';

const Wrapper = styled.div`
	grid-column: 1;
	grid-row: 2;

	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	padding-bottom: 4px;
	width: 100%;
`;

const LabelWrapper = styled.div`
	font-size: 0.7rem;
	display: flex;
	justify-content: space-between;
	padding-bottom: 4px;
	z-index: 6;
`;

const Label = styled.span`
	color: ${palette.gray[800]};
	font-weight: var(--font-weight-semibold);
`;

const Status = styled.span`
	font-weight: var(--font-weight-semibold);
	color: ${palette.gray[500]};
	margin-left: auto;

	${(props) =>
		props.level > 60 &&
		`
		color: ${palette.red[400]};
	`};
`;

const heights = {
	narrow: '4px',
	standard: '8px',
	thick: '12px',
};

const IndicatorWrapper = styled.div`
	border-radius: var(--radius-5);
	overflow: hidden;
	height: ${(props) => heights[props.thickness]};
`;

const Indicator = styled.span`
	background: linear-gradient(
		90deg,
		#0b78e3 10%,
		#05cf95 38%,
		#ffe800 64%,
		#ff8220 80%,
		#ff004a 88%
	);
	display: block;
	width: 100%;
	position: relative;
	vertical-align: middle;
	height: 100%;
	position: relative;
`;

const Bar = styled.div`
	position: absolute;
	height: 100%;
	right: 0;
	transition: width 200ms ease-out;
	transition-delay: 100ms;
	width: calc(100% - ${(props) => props.strain}%);

	background-color: ${palette.gray[300]};
`;

const physicalStrain = (strainLevel) => {
	if (strainLevel < 20) {
		return {
			level: strainLevel,
			status: t('Very low'),
		};
	}

	if (strainLevel === 100) {
		return {
			level: strainLevel,
			status: t('Max'),
		};
	}

	if (strainLevel > 90) {
		return {
			level: strainLevel,
			status: t('Very high'),
		};
	}

	if (strainLevel > 70) {
		return {
			level: strainLevel,
			status: t('High'),
		};
	}

	if (strainLevel > 40) {
		return {
			level: strainLevel,
			status: t('Moderate'),
		};
	}

	if (strainLevel > 20) {
		return {
			level: strainLevel,
			status: t('Low'),
		};
	}

	return { level: 0, status: t('Not available') };
};

const PhysicalStrainIndicator = ({
	strainLevel = 0,
	narrow,
	thick,
	className,
	hideLabels,
	label,
}) => {
	const level = physicalStrain(strainLevel).level;
	const status = physicalStrain(strainLevel).status;

	let barStyle = 'standard';

	if (narrow) {
		barStyle = 'narrow';
	}

	if (thick) {
		barStyle = 'thick';
	}

	return (
		<Wrapper className={className}>
			<LabelWrapper>
				{!hideLabels && <Label>{label ? label : t('Physical strain')}:</Label>}

				<Status level={level}>{status}</Status>
			</LabelWrapper>

			<IndicatorWrapper thickness={barStyle}>
				<Indicator key="strain-indicator">
					<Bar strain={level} />
				</Indicator>
			</IndicatorWrapper>
		</Wrapper>
	);
};

export default PhysicalStrainIndicator;
