import { HTMLProps, ReactNode } from 'react';

import * as styles from 'pkg/config/styles';

import rgba from 'pkg/rgba';
import { cssClasses, cssVarList } from 'pkg/css/utils';
import useTooltip from 'pkg/hooks/useTooltip';

import * as css from './styles.css';

interface LabelColorsProps {
	[key: string]: {
		bg: string;
		fg: string;
	};
}

const LabelColors: LabelColorsProps = {
	green: {
		bg: styles.palette.green[200],
		fg: styles.palette.green[700],
	},
	gray: {
		bg: styles.palette.gray[200],
		fg: styles.palette.gray[700],
	},
	orange: {
		bg: styles.palette.orange[100],
		fg: styles.palette.orange[700],
	},
	red: {
		bg: styles.palette.red[100],
		fg: styles.palette.red[700],
	},
	blue: {
		bg: styles.palette.blue[200],
		fg: styles.palette.blue[500],
	},
	darkblue: {
		bg: styles.palette.blue[500],
		fg: styles.palette.white,
	},
	yellow: {
		bg: styles.palette.brand,
		fg: styles.palette.black,
	},
	purple: {
		bg: styles.palette.purple[200],
		fg: styles.palette.purple[500],
	},
};

const DarkModeLabelColors: LabelColorsProps = {
	green: {
		bg: styles.palette.green[300],
		fg: styles.palette.green[800],
	},
	gray: {
		bg: styles.palette.gray[600],
		fg: styles.palette.gray[400],
	},
	orange: {
		bg: styles.palette.orange[200],
		fg: styles.palette.orange[600],
	},
	red: {
		bg: styles.palette.red[500],
		fg: styles.palette.white,
	},
	blue: {
		bg: styles.palette.blue[200],
		fg: styles.palette.blue[500],
	},
	darkblue: {
		bg: styles.palette.blue[500],
		fg: styles.palette.white,
	},
	yellow: {
		bg: styles.palette.brand,
		fg: styles.palette.black,
	},
	purple: {
		bg: styles.palette.purple[200],
		fg: styles.palette.purple[500],
	},
};

interface LabelProps extends HTMLProps<HTMLSpanElement> {
	color?: string;
	variant?: 'label' | 'bullet';
	eventType?: string;
	noWrap?: boolean;
	small?: boolean;
	children?: ReactNode;
	className?: string;
	tooltipText?: string;
}

const Label = ({
	children,
	variant = 'label',
	noWrap,
	small,
	color,
	eventType,
	className,
	tooltipText,
	...props
}: LabelProps) => {
	const { onMouseEnter, tooltip } = useTooltip(tooltipText);

	const vars: {
		background?: string;
		color?: string;
		'darkmode-bg'?: string;
		'darkmode-fg'?: string;
		'event-bg'?: string;
		'event-fg'?: string;
	} = {};

	if (color) {
		vars.background = LabelColors[color].bg;
		vars.color = LabelColors[color].fg;
		vars['darkmode-bg'] = DarkModeLabelColors[color].bg;
		vars['darkmode-fg'] = DarkModeLabelColors[color].fg;
	}

	if (eventType) {
		vars['event-bg'] = rgba(styles.palette.events[eventType].backgroundColor);
		vars['event-fg'] = rgba(styles.palette.events[eventType].foregroundColor);
	}

	if (variant === 'bullet') {
		return (
			<span className={cssClasses(className, css.bullet)}>
				<span data-bullet-color={color}>●</span>
				{children}
			</span>
		);
	}

	return (
		<span
			style={cssVarList(vars)}
			className={cssClasses(
				className,
				css.label,
				small ? css.small : '',
				noWrap ? css.noWrap : '',
				eventType ? css.eventType : ''
			)}
			onMouseEnter={onMouseEnter}
			{...props}>
			{children}
			{tooltip}
		</span>
	);
};

export default Label;
