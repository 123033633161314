import { t } from '@transifex/native';
import { ChangeEvent, Fragment } from 'react';

import { PageWidths } from 'pkg/config/sizes';

import * as models from 'pkg/api/models';
import { useCurrentMembership } from 'pkg/identity';
import useMixedState from 'pkg/hooks/useMixedState';
import * as actions from 'pkg/actions';

import { useEventContext } from 'routes/event/single';

import SectionTitle from 'components/SectionTitle';
import CardBase, * as Card from 'components/Card';
import { FormattedContent } from 'components/formatted-content';

import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import Column from 'components/layout/column';
import * as Input from 'components/form/inputs';

import Button from 'design/button';

interface MatchNotesProps {
	match: models.match.Match;
}

export default function MatchNotes({ match }: MatchNotesProps) {
	const currentMembership = useCurrentMembership();
	const eventCtx = useEventContext();

	const [form, setForm] = useMixedState({
		editing: false,
		noteValue: match.notes,
	});

	const handleEdit = () => setForm({ editing: true });

	const closeEdit = () => setForm({ editing: false });

	const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) =>
		setForm({ noteValue: e.target.value });

	const handleSave = async () => {
		const [ok, updatedMatch] = await actions.matches.update(match, {
			notes: form.noteValue,
		});

		if (ok) {
			eventCtx.eventEndpointResponse.replaceInRecord(
				eventCtx.eventEndpointResponse.record,
				'matches',
				(match: any) => {
					match.notes = updatedMatch.notes;
					return match;
				},
				updatedMatch.id
			);

			closeEdit();
		}
	};

	let content = (
		<Card.Body>
			<FormattedContent
				raw={match.notes || t('You have not entered any notes yet')}
			/>
		</Card.Body>
	);

	if (form.editing) {
		content = (
			<Fragment>
				<Card.Body>
					<Input.Group
						label={t('Match notes')}
						description={t('Only visible for team admins')}>
						<Input.Area
							minRows={4}
							value={form.noteValue}
							onChange={handleInputChange}
						/>
					</Input.Group>
				</Card.Body>
				<Card.Footer>
					<Button label={t('Cancel')} onClick={closeEdit} />
					<Button primary label={t('Save')} onClick={handleSave} />
				</Card.Footer>
			</Fragment>
		);
	}

	return (
		<LargeScreenContent.Inner maxWidth={PageWidths.WIDE}>
			<Column>
				<SectionTitle>
					{t('Notes')}
					{models.membership.isAdminOrStaff(currentMembership) && (
						<Button inline noPadding icon="edit" onClick={handleEdit}>
							{t('Edit')}
						</Button>
					)}
				</SectionTitle>
				<CardBase>{content}</CardBase>
			</Column>
		</LargeScreenContent.Inner>
	);
}
