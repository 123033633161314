const icon = {
	name: 'drawing-squiggly-arrow',
	objects: [
		{
			type: 'path',
			attributes: {
				d: 'M13.5 4.5H19.15V9.95001H17.85V6.71924L16.9307 5.8H13.5V4.5Z',
				fill: 'currentColor',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M5.97937 17.656L6.38921 19.7735L7.66552 19.5265L7.25568 17.409C7.14703 16.8476 7.64032 16.3543 8.20169 16.4629C9.66703 16.7466 10.9547 15.4589 10.671 13.9936C10.5624 13.4322 11.0557 12.9389 11.6171 13.0476C13.0824 13.3312 14.37 12.0436 14.0864 10.5782L14.0093 10.18C13.8949 9.58892 14.2853 9.01823 14.8776 8.91057L14.9799 8.89198C15.4771 8.80162 15.9349 8.56177 16.2922 8.20445L18.387 6.10961L17.4678 5.19038L15.3729 7.28521C15.2026 7.45553 14.9844 7.56986 14.7474 7.61294L14.6452 7.63152C13.3407 7.86861 12.4811 9.12538 12.733 10.427L12.8101 10.8252C12.9188 11.3866 12.4255 11.8799 11.8641 11.7713C10.3988 11.4876 9.11112 12.7753 9.39473 14.2406C9.50339 14.802 9.01009 15.2953 8.44872 15.1866C6.98339 14.903 5.69575 16.1906 5.97937 17.656Z',
				fill: 'currentColor',
			},
		},
	],
};

export default icon;
