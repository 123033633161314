import { ReactNode } from 'react';

export const symbol_add_triangle: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M118-120q-26.377 0-39.188-22.5Q66-165 79-188l362-625q6.716-11 17.164-16.5t21.642-5.5q11.194 0 21.789 5.5Q512.189-824 519-813l362 625q13 23 .188 45.5Q868.377-120 842-120H118Zm26-60h672L480-760 144-180Zm306-149v53q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T510-276v-53h54q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T564-389h-54v-54q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T450-443v54h-54q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T396-329h54Zm30-30Z"
		/>
	</svg>
);
