// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eozlSU2JyAoVFJoEXVNg {
	height: 100%;
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
}

@media (max-width: 768px) {

.eozlSU2JyAoVFJoEXVNg {
		padding-top: var(--spacing-6);
}
	}

.bMtqIUbTQsy7N0SwAg0O {
	text-align: center;
}

.bMtqIUbTQsy7N0SwAg0O div {
		font-size: var(--font-size-2xl);
		font-weight: var(--font-weight-semibold);
	}
`, "",{"version":3,"sources":["webpack://./routes/statistics/attendance/overview/styles.css"],"names":[],"mappings":"AAAA;CACC,YAAY;CACZ,WAAW;CACX,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,wBAAuB;KAAvB,qBAAuB;SAAvB,uBAAuB;CACvB,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;AAKpB;;AAHC;;AAPD;EAQE,6BAA6B;AAE/B;CADC;;AAGD;CACC,kBAAkB;AAMnB;;AAJC;EACC,+BAA+B;EAC/B,wCAAwC;CACzC","sourcesContent":[".wrapper {\n\theight: 100%;\n\twidth: 100%;\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\n\t@media (--small-viewport) {\n\t\tpadding-top: var(--spacing-6);\n\t}\n}\n\n.textWrapper {\n\ttext-align: center;\n\n\t& div {\n\t\tfont-size: var(--font-size-2xl);\n\t\tfont-weight: var(--font-weight-semibold);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var wrapper = `eozlSU2JyAoVFJoEXVNg`;
export var textWrapper = `bMtqIUbTQsy7N0SwAg0O`;
export default ___CSS_LOADER_EXPORT___;
