import { ReactNode } from 'react';

export const symbol_more_down: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M260-260h350q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T610-200H230q-12.75 0-21.375-8.625T200-230v-380q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T260-610v350Zm200-200h350q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-400H430q-12.75 0-21.375-8.625T400-430v-380q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T460-810v350Z"
		/>
	</svg>
);
