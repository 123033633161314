import { ReactNode } from 'react';

export const symbol_hdr_off_select: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M831-177h-56q-10.4 0-17.2-7.116-6.8-7.117-6.8-18 0-10.884 7.083-17.384Q765.167-226 776-226h55v-56q0-10.4 7.116-17.2 7.117-6.8 18-6.8 10.884 0 17.384 6.5T880-282v56h56q10.4 0 17.2 6.5 6.8 6.5 6.8 17.384 0 10.883-6.5 18Q947-177 936-177h-56v56q0 10.4-6.5 17.2Q867-97 856.116-97q-10.883 0-18-7.083Q831-111.167 831-122v-55Zm-304 73v-203q0-10.4 6.8-17.2 6.8-6.8 17.2-6.8h119q22 0 35.5 13t13.5 35v52q0 22-11.5 35T676-175l25 62q5 12-1.75 22.5T679-80q-8 0-14-4.5T656-96l-29-73h-52v65q0 10.4-6.8 17.2Q561.4-80 551-80q-10.4 0-17.2-6.8-6.8-6.8-6.8-17.2Zm48-113h96v-66h-96v66ZM48-196v92q0 10.4-6.8 17.2Q34.4-80 24-80q-10.4 0-17.2-6.8Q0-93.6 0-104v-203q0-10.4 6.8-17.2Q13.6-331 24-331q10.4 0 17.2 6.8Q48-317.4 48-307v63h93v-63q0-10.4 6.8-17.2 6.8-6.8 17.2-6.8 10.4 0 17.2 6.8 6.8 6.8 6.8 17.2v203q0 10.4-6.8 17.2Q175.4-80 165-80q-10.4 0-17.2-6.8-6.8-6.8-6.8-17.2v-92H48Zm215 92v-203q0-10.4 6.8-17.2 6.8-6.8 17.2-6.8h116q22 0 35 13t13 35v155q0 22-13 35t-35 13H287q-10.4 0-17.2-6.8-6.8-6.8-6.8-17.2Zm48-24h92v-155h-92v155Zm368-412-44-44q7-17 10.5-33.129Q649-633.258 649-651q0-69-50-119t-119-50q-18 0-35.5 4T413-806l-45-45q23-12 50.5-20.5T480-880q95.417 0 162.208 66.792Q709-746.417 709-651q0 30-7.5 59T679-540ZM480-422q-95.417 0-162.208-66.792Q251-555.583 251-651q0-29 7.5-59t24.5-57l-39-39q-9-9-9-21t9-21q9-9 21-9t21 9l399 399q9 9 9 21t-9 21q-9 9-21 9t-21-9l-47-47q-23 15-54.574 23.5Q509.852-422 480-422Zm73-77L328-724q-9 18-13 37t-4 36q0 69 50 119t119 50q18.441 0 37.721-4.5Q537-491 553-499Zm-29-196Zm-83 83Z"
		/>
	</svg>
);
