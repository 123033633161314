import { useState } from 'react';

import * as styles from 'pkg/config/styles';

import SelectedStatus from 'components/form/SelectedStatus';

const FormSelectedStatus = (): JSX.Element => {
	const [selected, setSelected] = useState<boolean>(false);

	const handleSelect = (): void => setSelected((prev: boolean) => !prev);

	return (
		<SelectedStatus
			isSelected={selected}
			color={styles.palette.blue[400]}
			onClick={handleSelect}
		/>
	);
};

export default FormSelectedStatus;
