import { ReactNode } from 'react';

export const symbol_location_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M550-560q0-28.875-20.562-49.438Q508.875-630 480-630q-14 0-25.5 5T434-612l98 98q8-9 13-20.5t5-25.5Zm167 231-44-44q34-51 50.5-96t16.5-83q0-117.79-75.292-192.895Q589.417-820 480-820q-54 0-100.5 18.5T298-748l-42-42q45-44 103.229-67T480-880q127 0 223.5 89T800-552q0 51-20.5 106.5T717-329Zm-120 52L233-641q-7 20-10 42.5t-3 46.5q0 75 65 173.5T480-159q34-31 63-60.5t54-57.5ZM479.75-100q-10.75 0-21.25-3.5T440-115q-42-38-91-87.5T258-309q-42-57-70-119t-28-124q0-37.689 6.5-71.844Q173-658 186-688L47-827q-9-9.067-9-21.533Q38-861 47.053-870q9.052-9 21.5-9Q81-879 90-870l765 765q9 9 9 21.5T854.947-62q-9.052 9-21.5 9Q821-53 812-62L640-234q-18 20-42.5 45T520-115q-8 8-18.75 11.5t-21.5 3.5ZM415-459Zm71-101Z"
		/>
	</svg>
);
