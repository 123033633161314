import { Fragment, useState } from 'react';
import { useT } from '@transifex/react';

import { useCurrentUser } from 'pkg/identity';

import Footer from 'components/context-switch-footer';
import MaterialSymbol from 'components/material-symbols';

import OrganizationSwitcher from 'components/navigation/menus/organization_switcher';
import GroupSwitcher from 'components/navigation/menus/group_switcher';
import GroupSwitcherProvider from 'components/navigation/menus/group_switcher/Provider';
import JoinGroupModal from 'components/group/join-modal';

import * as Context from 'design/context_menu';

import * as css from './styles.css';

export function useContextSwitchFooter() {
	const [modalOpen, setModalOpen] = useState<boolean>(false);

	const openModal = () => setModalOpen(true);
	const closeModal = () => setModalOpen(false);

	return {
		Footer: (
			<Fragment>
				<Context.Divider tight />
				<Footer openJoinModal={openModal} />
			</Fragment>
		),
		JoinModal: (
			<Fragment>
				{modalOpen && <JoinGroupModal onClose={closeModal} />}
			</Fragment>
		),
	};
}

export default function ContextSwitcher() {
	const t = useT();
	const [modalOpen, setModalOpen] = useState<boolean>(false);

	const currentUser = useCurrentUser();

	const openJoinGroup = () => setModalOpen(true);
	const closeModal = () => setModalOpen(false);

	return currentUser ? (
		<div className={css.wrapper}>
			<div className={css.organizationSwitcher}>
				<OrganizationSwitcher />
			</div>
			<div className={css.groupSwitcher}>
				<GroupSwitcherProvider>
					<GroupSwitcher />
				</GroupSwitcherProvider>
			</div>
		</div>
	) : (
		<Fragment>
			<div className={css.joinOrganizationWrapper}>
				<div className={css.joinOrganization} onClick={openJoinGroup}>
					<div className={css.joinOrganizationIcon}>
						<MaterialSymbol actualSize scale={1.7} variant="add" />
					</div>
					<span className={css.joinOrganizationAction}>
						{t('Join organization')}
					</span>
				</div>
			</div>
			{modalOpen && <JoinGroupModal onClose={closeModal} />}
		</Fragment>
	);
}
