import { Dispatch } from 'redux';

import * as actionTypes from 'pkg/actions/action-types';
import * as authActions from 'pkg/actions/auth';
import * as groupActions from 'pkg/actions/groups';
import * as userActions from 'pkg/actions/users';

import { CustomHeaders } from 'pkg/api/headers';
import * as sdk from 'pkg/core/sdk';
import * as localStorageHelpers from 'pkg/local_storage';
import { setAppleMeta } from 'pkg/meta_tags';
import { replaceState } from 'pkg/router/state';
import store from 'pkg/store/createStore';
import { OrganizationsLocalStorageData } from 'pkg/identity/account';
import * as models from 'pkg/api/models';
import { getOrganizationIdentityContextValues } from 'pkg/identity/organization';

export const resetApiError = () =>
	store.dispatch({ type: actionTypes.APP_RESET_API_ERROR });

export const triggerError =
	(request: any = {}, response?: any) =>
	(dispatch: Dispatch<any>) => {
		if (request.status === 401) {
			dispatch(authActions.logout());
			return;
		}

		dispatch({
			type: actionTypes.APP_API_ERROR,
			request,
			response,
		});
	};

export const setUrl = (url: string) => {
	setAppleMeta(url);

	return {
		type: actionTypes.APP_SET_URL,
		url,
	};
};

export const setDrawerVisibility = (visibleDrawer: boolean) => {
	if (visibleDrawer) {
		document.body.classList.remove('no-drawer');
	} else {
		document.body.classList.add('no-drawer');
	}
};

export const setNavBarHeight = (navBarHeight: number) => ({
	type: actionTypes.APP_SET_NAVBAR_HEIGHT,
	navBarHeight,
});

export const setTabBarHeight = (height: number) => ({
	type: actionTypes.APP_SET_TABBAR_HEIGHT,
	height,
});

export const setForceNavBarBackLink = (url: string) => {
	return (dispatch: Dispatch) => {
		dispatch({
			type: actionTypes.APP_SET_FORCE_NAVIGATE_BACK_LINK,
			payload: url,
		});
	};
};

export const forcedNavigation = (url: string) => (dispatch: Dispatch) => {
	replaceState(url);
	setForceNavBarBackLink('')(dispatch);
};

export const setInitialMembership =
	(orgId: number) => async (dispatch: Dispatch) => {
		let storedMembership = JSON.parse('{"error":true}');

		const parsedLocalStorageOrgData =
			localStorageHelpers.getJsonItem<OrganizationsLocalStorageData>(
				localStorageHelpers.LocalStorageKeys.Organizations
			) || {};

		if (parsedLocalStorageOrgData?.[orgId]?.activeMembership) {
			storedMembership = parsedLocalStorageOrgData[orgId].activeMembership;
		}

		const setFromLocalStorage = async () => {
			// Make sure the user id header is set before making api calls
			sdk.setDefaultRequestHeader(
				CustomHeaders.CurrentUserId,
				storedMembership.userId?.toString()
			);

			const group = await groupActions.fetchGroup(storedMembership.groupId);

			if (!group?.id) {
				return false;
			}

			if (
				storedMembership.hasOwnProperty('isOrganizationMembership') &&
				storedMembership.isOrganizationMembership
			) {
				const ok = await userActions.setActiveOrganization(
					storedMembership.groupId
				)(dispatch, () => store.getState());

				if (!ok) {
					localStorage.removeItem('activeMembership');
					return false;
				}
			} else {
				const ok = await userActions.setActiveGroup(
					group,
					storedMembership.targetUserId || 0,
					storedMembership.isInherited ? storedMembership : null
				)(dispatch, () => store.getState());

				if (!ok) {
					localStorage.removeItem('activeMembership');
					return false;
				}
			}

			return true;
		};

		if (!storedMembership.error) {
			const ok = await setFromLocalStorage();

			if (ok) {
				return;
			}
		}

		const organizationStateValues = getOrganizationIdentityContextValues();
		const memberships = organizationStateValues.user?.groups;

		if (memberships?.length > 0) {
			const ok = await userActions.setActiveGroup(memberships[0].group)(
				dispatch,
				() => store.getState()
			);
			if (ok) {
				return;
			}
		}

		const wards = organizationStateValues.wards;

		if (wards.length > 0) {
			const accountWardsMembership = wards[0].groups?.[0];

			if (accountWardsMembership?.id) {
				const ok = await userActions.setActiveGroup(
					accountWardsMembership.group,
					accountWardsMembership.userId
				)(dispatch, () => store.getState());
				if (ok) {
					return;
				}
			}

			const accountWardsOrganization = wards[0].organization;

			if (accountWardsOrganization?.id) {
				await userActions.setActiveOrganization(accountWardsOrganization.id)(
					dispatch,
					() => store.getState()
				);

				return;
			}
		}

		const users = organizationStateValues.users;

		if (users.length > 0) {
			await userActions.setActiveOrganization(users[0].organizationId)(
				dispatch,
				() => store.getState()
			);
		}
	};

export const setDarkMode = (darkMode: boolean) => (dispatch: Dispatch) => {
	dispatch({
		type: actionTypes.APP_SET_DARK_MODE,
		darkMode,
	});
};

/**
 * Sets up all provided orgs in the local storage with default states for lastView
 * and activeMembership. This checks if a user has been removed from the org as well and clears that key value pair from the storage.
 *
 * @param organizations orgs to include in the local storage setup
 */

export function setupOrganizationLocalStorage(
	organizations: models.group.OrganizationData[]
) {
	const organizationsLocalStorage =
		localStorageHelpers.getJsonItem<OrganizationsLocalStorageData>(
			localStorageHelpers.LocalStorageKeys.Organizations
		) || {};
	const providedOrgIds = organizations.map((o) => o.id);
	const localStorageOrgIds = Object.keys(organizationsLocalStorage).map((v) =>
		Number.parseInt(v, 10)
	);

	// Migrate old activeMembership data to be set if it exist
	const storedMembership =
		localStorageHelpers.getJsonItem<models.membership.Membership>(
			localStorageHelpers.LocalStorageKeys.ActiveMembership
		) || null;
	let storedMembershipOrgId = 0;

	if (storedMembership?.group) {
		storedMembershipOrgId = models.group.getOrganizationId(
			storedMembership.group
		);
	}

	organizations.forEach(async (org) => {
		if (!organizationsLocalStorage[org.id]) {
			organizationsLocalStorage[org.id] = {
				activeMembership: null,
				orgToken: '',
			};

			// If we're in the org that's stored on the membership we set that orgs
			// activeMembership to the stored membership and active organization
			// to the orgs ID
			if (storedMembershipOrgId && org.id === storedMembershipOrgId) {
				organizationsLocalStorage[org.id].activeMembership = storedMembership;

				localStorageHelpers.setItem(
					localStorageHelpers.LocalStorageKeys.ActiveOrganizationId,
					storedMembershipOrgId.toString()
				);
			}
		}
	});

	// At this point it's safe to remove the old activeMembership item
	localStorageHelpers.removeItem(
		localStorageHelpers.LocalStorageKeys.ActiveMembership
	);

	localStorageOrgIds.forEach((id) => {
		// If there's a local storage id that doesn't exist in the provided
		// we've been removed from that org
		if (!providedOrgIds.includes(id)) {
			delete organizationsLocalStorage[id];
		}
	});

	localStorageHelpers.setJsonItem(
		localStorageHelpers.LocalStorageKeys.Organizations,
		organizationsLocalStorage
	);
}
