import { ReactNode } from 'react';

export const symbol_bedroom_parent: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M244-345h472v31.395Q716-300 725.093-291t23 9Q762-282 771-291.067q9-9.066 9-22.933v-144q0-18-13.5-38.5T732-528v-68q0-32-18.5-50.5T663-665H527q-16 0-28 5t-20 15q-8-10-20-15t-28-5H297q-32 0-50.5 18.5T228-596v68q-21 11-34.5 31.5T180-458v144q0 13.867 9.093 22.933 9.093 9.067 23 9.067T235-290.925q9-8.925 9-22.575V-345Zm0-50v-42q0-19 10.5-29.5t29.053-10.5h392.894Q695-477 705.5-466.5T716-437v42H244Zm34-132v-88h177v88H278Zm227 0v-88h177v88H505ZM140-80q-24 0-42-18t-18-42v-680q0-24 18-42t42-18h680q24 0 42 18t18 42v680q0 24-18 42t-42 18H140Zm0-60h680v-680H140v680Zm0 0v-680 680Z"
		/>
	</svg>
);
