import { ReactNode } from 'react';

export const symbol_table_lamp: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M550-120q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T550-180h260q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-120H550ZM191-580h189v-200H278l-87 200Zm488.825 360Q667-220 658.5-228.625T650-250v-390q0-21.25-14.375-35.625T600-690H440v140q0 12.75-8.625 21.375T410-520H145q-16.739 0-25.87-13.5Q110-547 117-562l106-242q8-17 22.548-26.5Q260.095-840 278-840h102q24.75 0 42.375 17.625T440-780v30h160q45.833 0 77.917 32.083Q710-685.833 710-640v390q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM191-580h189-189Z"
		/>
	</svg>
);
