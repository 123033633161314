export function Approve(ID: number): string {
	return `/v1/account-relations/${ID}`;
}

export function Create(): string {
	return `/v1/account-relations`;
}

export function Delete(ID: number): string {
	return `/v1/account-relations/${ID}`;
}

export function Index(): string {
	return `/v1/account-relations`;
}
