import { ReactNode } from 'react';

export const symbol_healing: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M483-277 303-98q-17 19-43 19t-43-19L98-217q-19-17-18.5-42.5T98-303l178-179L98-662q-17-17-19.5-40.5T93-743l124-124q17.573-18 42.786-18Q285-885 303-867l180 180 179-180q18-18 43-17.5t43 17.5l119 119q17 18 17.5 43T867-662L688-482l179 179q18 17.573 18 42.786Q885-235 867-217L743-93q-17 17-40.5 14.5T662-98L483-277Zm0-240q16.575 0 27.788-11.213Q522-539.425 522-556t-11.212-27.787Q499.575-595 483-595q-16.575 0-27.788 11.213Q444-572.575 444-556t11.212 27.787Q466.425-517 483-517Zm-168-4 127-126-180-179-124 124 177 181Zm94 77q16.575 0 27.788-11.212Q448-466.425 448-483q0-16.575-11.212-27.788Q425.575-522 409-522q-16.575 0-27.788 11.212Q370-499.575 370-483q0 16.575 11.212 27.788Q392.425-444 409-444Zm74 74q16.575 0 27.788-11.212Q522-392.425 522-409q0-16.575-11.212-27.788Q499.575-448 483-448q-16.575 0-27.788 11.212Q444-425.575 444-409q0 16.575 11.212 27.788Q466.425-370 483-370Zm73-74q16.575 0 27.787-11.212Q595-466.425 595-483q0-16.575-11.213-27.788Q572.575-522 556-522t-27.787 11.212Q517-499.575 517-483q0 16.575 11.213 27.788Q539.425-444 556-444Zm-34 126 180 180 124-124-179-179-125 123ZM358-606Zm248 248Z"
		/>
	</svg>
);
