import { ReactNode } from 'react';

export const symbol_playlist_add_check: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M150-331q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-391h246q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T396-331H150Zm0-165q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-556h413q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T563-496H150Zm0-165q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-721h413q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T563-661H150Zm501.867 448Q646-213 641-215q-5-2-10-7l-94-94q-9-9-9-21t9-21q9-9 21.467-9.5Q570.933-368 580-359l72 72 164-164q8.8-9 20.9-8.5 12.1.5 21.491 9.5Q867-441 867-428.5t-9 21.5L673-222q-5 5-10.133 7-5.134 2-11 2Z"
		/>
	</svg>
);
