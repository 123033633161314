import { ChangeEvent, Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as actions from 'pkg/actions';
import useMixedState from 'pkg/hooks/useMixedState';
import { useCurrentRoute } from 'pkg/router/hooks';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';

import { useOnboardingContext } from 'routes/public/onboarding';
import Button from 'routes/public/styles/Button';
import ThreeSixtyLogo from 'routes/public/styles/logo';
import Title from 'routes/public/styles/Title';
import Form from 'routes/public/styles/forms';
import Field from 'routes/public/styles/inputs/field';
import CancelButton from 'routes/public/styles/CancelButton';
import * as publicStyles from 'routes/public/styles/styles.css';

import Column from 'components/layout/column';

export default function ResetPassword(): JSX.Element {
	const { goTo } = useOnboardingContext();

	const [token, setToken] = useState<string>('');
	const [isSaving, setIsSaving] = useState<boolean>(false);
	const [payload, setPayload] = useMixedState({
		password: '',
		passwordConfirmation: '',
	});

	const dispatch = useDispatch();
	const route = useCurrentRoute();

	useComponentDidMount(() => {
		const tokenFromUrl = route.query.token;

		if (!tokenFromUrl) {
			window.history.replaceState({}, '', '/');
			goTo('start');
			return;
		}

		setToken(tokenFromUrl);
	});

	const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
		setPayload({ [event.target.name]: event.target.value });
	};

	const handlePasswordReset = async () => {
		setIsSaving(true);

		const ok = await dispatch(
			actions.users.passwordChange(payload.password, token)
		);

		if (!ok) {
			setIsSaving(false);
			return;
		}

		goTo('login-email');
	};

	const canContinue =
		payload.password !== '' &&
		payload.password === payload.passwordConfirmation;

	return (
		<Fragment>
			<Column spacing={styles.spacing._8} className={publicStyles.main}>
				<ThreeSixtyLogo />

				<Form onSubmit={handlePasswordReset}>
					<Column spacing={styles.spacing._8}>
						<Title title={t('Set a new password')} center />

						<Column>
							<Field
								name="password"
								type="password"
								placeholder={t('New password')}
								minLength={8}
								value={payload.password}
								onChange={handlePasswordChange}
								tabIndex={1}
							/>
							<Field
								name="passwordConfirmation"
								type="password"
								placeholder={t('Repeat new password')}
								errorMessage={t('Passwords do not match')}
								minLength={8}
								validateOnChange
								data-must-match={payload.password}
								value={payload.passwordConfirmation}
								onChange={handlePasswordChange}
								tabIndex={2}
							/>
						</Column>

						<Button
							primary
							disabled={!canContinue}
							label={t('Save')}
							busy={isSaving}
							type="submit"
						/>
					</Column>
				</Form>
			</Column>

			<div className={publicStyles.footer}>
				<CancelButton disabled={isSaving} />
			</div>
		</Fragment>
	);
}
