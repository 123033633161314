import { Fragment } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as flashActions from 'pkg/actions/flashes';
import { setFieldAsRequired } from 'pkg/actions/user_fields';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as sdk from 'pkg/core/sdk';
import { cssClasses } from 'pkg/css/utils';
import * as models from 'pkg/api/models';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import * as Card from 'components/Card';
import Label from 'components/label';
import Icon, { IconName } from 'components/icon';

import * as ContextMenu from 'design/context_menu';

import * as css from './styles.css';

const Field = ({
	item,
	custom,
	isReordering,
	dragElement,
	onReplace,
	onRefresh,
	onRemove,
	onEdit,
}: {
	item: models.userFields.UserField;
	isEditingSort?: boolean;
	custom?: boolean;
	isReordering?: boolean;
	dragElement?: any;
	onReplace: (record: models.userFields.UserField) => void;
	onEdit?: (record: models.userFields.UserField) => void;
	onRefresh?: () => void;
	onRemove?: (recordId: number) => void;
}) => {
	const handleEdit = () => onEdit(item);

	const handleSetAsRequired = async () => {
		const field = await setFieldAsRequired(item);

		if (!item.id) {
			onRefresh();
		} else {
			onReplace(field);
			onRefresh();
		}
	};

	const handleSetAsNotRequired = async () => {
		const [ok, res] = await models.update<
			{ required: boolean },
			models.userFields.UserField
		>(item, {
			required: false,
		});

		if (ok) {
			onReplace(res);
			onRefresh();
		}
	};

	const handleDelete = async () => {
		const req = await sdk.destroy(endpoints.UserField.Delete(item.id), {}, {});

		if (req.ok) {
			onRemove(item.id);
			onRefresh();
		}
		if (!req.ok) {
			flashActions.show({ title: t('Something went wrong') }, req.status);
		}
	};

	return (
		<Card.Base $noBorder>
			<div className={cssClasses(css['wrapper'])}>
				<div>
					<div
						className={cssClasses(
							css['left_col'],
							isReordering ? css['can_reorder'] : ''
						)}>
						{dragElement}
						<div className={css['icon_wrapper']}>
							<Icon
								name={
									fieldIcons[
										item.type === models.userFields.UserFieldType.Reserved
											? item.key
											: item.type
									]
								}
								size={1.5}
								fill={styles.palette.blue[500]}
							/>
						</div>

						<div>
							<div>
								<div className={css.heading}>{item.label}</div>
								{item.description && <p>{item.description}</p>}
							</div>
							<SmallScreen>
								<div className={css.meta}>
									{item.visibility?.length > 0 && (
										<div className={cssClasses(css['visible'])}>
											<Icon size={1.2} name="eye-visible" />{' '}
											<span>{t('Visible')}</span>
										</div>
									)}
									{item.required && (
										<Label small color="gray">
											{t('Required')}
										</Label>
									)}
								</div>
							</SmallScreen>
						</div>
					</div>
				</div>
				<div className={css['right_col']}>
					<LargeScreen>
						{item.visibility?.length > 0 && (
							<div className={cssClasses(css['visible'])}>
								<Icon size={1.5} name="eye-visible" />{' '}
								<span>{t('Visible')}</span>
							</div>
						)}
						{item.required && <Label color="gray">{t('Required')}</Label>}
					</LargeScreen>
					<ContextMenu.Menu
						toggleWith={<ContextMenu.ButtonTrigger icon="more_horiz" small />}>
						{custom && (
							<Fragment>
								<ContextMenu.Item onClick={handleEdit}>
									<ContextMenu.ItemIcon name="edit" />
									{t('Edit')}
								</ContextMenu.Item>
								<ContextMenu.Divider />
							</Fragment>
						)}
						<ContextMenu.Label>{t('Required')}</ContextMenu.Label>

						<ContextMenu.ControlItem
							onClick={handleSetAsRequired}
							type="radio"
							checked={item.required}>
							{t('Yes')}
						</ContextMenu.ControlItem>

						<ContextMenu.ControlItem
							onClick={handleSetAsNotRequired}
							type="radio"
							checked={!item.required}>
							{t('No')}
						</ContextMenu.ControlItem>

						{custom && (
							<Fragment>
								<ContextMenu.Divider />
								<ContextMenu.ConfirmItem
									caution
									message={t(
										'Are you sure you want to delete this user field?'
									)}
									onConfirm={() => handleDelete()}>
									<ContextMenu.ItemIcon name="delete" />
									{t('Delete')}
								</ContextMenu.ConfirmItem>
							</Fragment>
						)}
					</ContextMenu.Menu>
				</div>
			</div>
		</Card.Base>
	);
};

const customFieldIcons: { [key: string]: IconName } = {
	[models.userFields.UserFieldType.ShortText]: 'short-text',
	[models.userFields.UserFieldType.LongText]: 'long-text',
	[models.userFields.UserFieldType.Number]: 'number',
	[models.userFields.UserFieldType.Email]: 'mail',
	[models.userFields.UserFieldType.Phone]: 'long-text',
	[models.userFields.UserFieldType.Choice]: 'single-choice',
	[models.userFields.UserFieldType.MultiChoice]: 'checkbox-checked',
	[models.userFields.UserFieldType.Date]: 'nav-events',
	[models.userFields.UserFieldType.Attachment]: 'attachment',
	[models.userFields.UserFieldType.Reserved]: 'lock',
};

const reservedFieldIcons: { [key: string]: IconName } = {
	[`user.${models.userFields.ReservedUserField.FirstName}`]: 'short-text',
	[`user.${models.userFields.ReservedUserField.LastName}`]: 'short-text',
	[`user.${models.userFields.ReservedUserField.BirthDate}`]: 'nav-events',
	[`user.${models.userFields.ReservedUserField.Email}`]: 'mail',
	[`user.${models.userFields.ReservedUserField.Sex}`]: 'single-choice',
	[`user.${models.userFields.ReservedUserField.PhoneNumber}`]: 'long-text',
	[`user.${models.userFields.ReservedUserField.City}`]: 'short-text',
	[`user.${models.userFields.ReservedUserField.Country}`]: 'single-choice',
	[`user.${models.userFields.ReservedUserField.PostalCode}`]: 'number',
	[`user.${models.userFields.ReservedUserField.CompanyName}`]: 'short-text',
	[`user.${models.userFields.ReservedUserField.Address}`]: 'short-text',
	[`user.${models.userFields.ReservedUserField.Region}`]: 'short-text',
	[`user.${models.userFields.ReservedUserField.Nationality}`]: 'single-choice',
	[`user.${models.userFields.ReservedUserField.LokSwedishPersonalId}`]:
		'number',
};

export const fieldIcons: { [key: string]: IconName } = {
	...reservedFieldIcons,
	...customFieldIcons,
};

export default Field;
