import styled, { css } from 'styled-components';

const Row = styled.div`
	margin: var(--spacing-7) 0;

	${({ tight }) =>
		tight &&
		css`
			margin: var(--spacing-4) 0;
		`}

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
`;

export default Row;
