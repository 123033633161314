// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZrhDjXQx10lyQiK5WyeK {
	background-color: var(--palette-gray-200);
	padding: var(--spacing-6);
	border-radius: var(--radius-3);
}

.guCL4O0sD3MIWTSOpKKQ {
	max-width: 200px;
}

.Dj1NfqopUYX2NqEXOnK6 {
	display: grid;
	grid-template-columns: 200px auto;
	grid-gap: var(--spacing-4);
	gap: var(--spacing-4);
}

.FdI2asvGjdloBhsVNFeQ {
	max-width: 100px;
}
`, "",{"version":3,"sources":["webpack://./routes/forms/hooks/modals/price_option/styles.css"],"names":[],"mappings":"AAAA;CACC,yCAAyC;CACzC,yBAAyB;CACzB,8BAA8B;AAC/B;;AAEA;CACC,gBAAgB;AACjB;;AAEA;CACC,aAAa;CACb,iCAAiC;CACjC,0BAAqB;CAArB,qBAAqB;AACtB;;AAEA;CACC,gBAAgB;AACjB","sourcesContent":[".schedule_wrapper {\n\tbackground-color: var(--palette-gray-200);\n\tpadding: var(--spacing-6);\n\tborder-radius: var(--radius-3);\n}\n\n.schedule_input_wrapper {\n\tmax-width: 200px;\n}\n\n.installments_schedule_wrapper {\n\tdisplay: grid;\n\tgrid-template-columns: 200px auto;\n\tgap: var(--spacing-4);\n}\n\n.installments_amount_input {\n\tmax-width: 100px;\n}\n"],"sourceRoot":""}]);
// Exports
export var schedule_wrapper = `ZrhDjXQx10lyQiK5WyeK`;
export var schedule_input_wrapper = `guCL4O0sD3MIWTSOpKKQ`;
export var installments_schedule_wrapper = `Dj1NfqopUYX2NqEXOnK6`;
export var installments_amount_input = `FdI2asvGjdloBhsVNFeQ`;
export default ___CSS_LOADER_EXPORT___;
