import { ChangeEvent, Fragment, useMemo, useState } from 'react';
import { useT } from '@transifex/react';

import spacing from 'pkg/config/spacing';

import * as flashActions from 'pkg/actions/flashes';

import { useEndpoint } from 'pkg/api/use_endpoint';
import { useCurrentGroupId, useCurrentMembership } from 'pkg/identity';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCollection } from 'pkg/api/use_collection';
import * as models from 'pkg/api/models';
import useMixedState from 'pkg/hooks/useMixedState';
import DateTime, { Granularity } from 'pkg/datetime';
import * as actions from 'pkg/actions';
import { crash } from 'pkg/errors/errors';
import { getAmountOfDaysBetweenDates } from 'pkg/date';

import * as Card from 'components/Card';
import Icon from 'components/icon';
import { LargeScreen, useSmallScreen } from 'components/MediaQuery';
import Modal from 'components/modal';

import Column from 'components/layout/column';
import * as Input from 'components/form/inputs';
import Row from 'components/layout/row';
import { Spinner } from 'components/loaders/spinner';
import InfoBox from 'components/form/info-box';

import Button from 'design/button';

import * as css from './styles.css';

interface ExportModalProps {
	onClose: () => void;
}

function ExportModal({ onClose }: ExportModalProps) {
	const t = useT();
	const groupId = useCurrentGroupId();

	const today = new Date();
	const from = today;
	const to = new DateTime(today).next(Granularity.day).dateTime;

	const { record: root, isLoading: isLoadingRoot } =
		useEndpoint<models.group.Group>(endpoints.Groups.Show(groupId), {
			queryParams: new URLSearchParams({
				recursive: '1',
				include_sub_group_count: '1',
			}),
		});

	const { records: children, isLoading: isLoadingChildren } =
		useCollection<models.group.Group>(endpoints.Groups.ShowChildren(groupId), {
			queryParams: new URLSearchParams({
				recursive: '1',
				include_sub_group_count: '1',
			}),
		});

	const groupTree = models.group.buildGroupTree(
		root,
		children,
		true
	) as models.group.Group[];

	const [state, setState] = useMixedState({
		from,
		to,
		groupId: 0,
	});

	const daysBetween = useMemo(
		() => getAmountOfDaysBetweenDates(state.from, state.to),
		[state.from, state.to]
	);
	const dateSpanIsToLong = daysBetween > 365;

	const selectedGroup = groupTree.find((g) => g.id === state.groupId);

	const handleStartDateChange = (from: Date) => {
		if (+from > +to) {
			setState({
				from,
				to: from,
			});

			return;
		}

		setState({
			from,
		});
	};

	const handleEndDateChange = (to: Date) =>
		setState({
			to,
		});

	const handleGroupChange = (e: ChangeEvent<HTMLSelectElement>) =>
		setState({
			groupId: Number.parseInt(e.currentTarget.value, 10),
		});

	const handleExport = async () => {
		const [ok, csvData] = await actions.events.eventExport({
			from: Math.ceil(new DateTime(state.from).startOfDay / 1000),
			to: Math.floor(new DateTime(state.to).endOfDay / 1000),
			group_ids: selectedGroup.id.toString(),
		});

		handleExportResult(ok, csvData);
	};

	const handleExportResult = (ok: boolean, csvData: string) => {
		if (ok) {
			const fileName = `${selectedGroup.name}-events.csv`;

			const url = URL.createObjectURL(new Blob([csvData]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', fileName);
			document.body.appendChild(link);
			link.click();
			link.remove();
		} else {
			const err = crash();
			flashActions.show({
				title: err.title,
				message: err.description,
			});
		}
	};

	let content = <Spinner />;

	if (!isLoadingRoot && !isLoadingChildren) {
		content = (
			<Column>
				<Input.Select onChange={handleGroupChange}>
					<option value={0}>{t('Select group')}</option>
					{groupTree.map((g) => (
						<option value={g.id}>{g.name}</option>
					))}
				</Input.Select>
				{dateSpanIsToLong && (
					<InfoBox
						color="red"
						text={t(
							'The selected date range for exporting events must be less than one year. Please adjust your selection accordingly.'
						)}
					/>
				)}
				<Row collapseOnSmallScreens columns="1fr auto 1fr" spacing={spacing._5}>
					<Input.Group label={t('From')} hasError={dateSpanIsToLong}>
						<div>
							<Input.DateTimePicker
								hideTimeInput
								date={state.from}
								onDateChange={handleStartDateChange}
							/>
						</div>
					</Input.Group>
					<LargeScreen>
						<Icon name="arrow-right" className={css.icon} size={1.6} />
					</LargeScreen>
					<Input.Group label={t('To')} hasError={dateSpanIsToLong}>
						<div>
							<Input.DateTimePicker
								date={state.to}
								disableDatesBefore={state.from}
								hideTimeInput
								onDateChange={handleEndDateChange}
							/>
						</div>
					</Input.Group>
				</Row>
			</Column>
		);
	}

	return (
		<Modal onClose={onClose}>
			<Card.Base>
				<Card.Header>
					<Card.Heading>{t('Calendar export')}</Card.Heading>
				</Card.Header>
				<Card.Divider />
				<Card.Body $flex>{content}</Card.Body>
				<Card.Footer>
					<Button label={t('Cancel')} onClick={onClose} />
					<Button
						label={t('Export')}
						primary
						onClick={handleExport}
						disabled={!state.groupId || dateSpanIsToLong}
					/>
				</Card.Footer>
			</Card.Base>
		</Modal>
	);
}

export default function CalendarExport() {
	const t = useT();
	const isSmallScreen = useSmallScreen();
	const currentMembership = useCurrentMembership();

	const [modal, setModal] = useState('');

	const handleExportClick = () => setModal('export');

	const closeModal = () => setModal('');

	if (!models.membership.isAdminOrStaff(currentMembership)) {
		return null;
	}

	return (
		<Fragment>
			<Button
				label={t('Export')}
				onClick={handleExportClick}
				icon="download"
				block={isSmallScreen}
			/>

			{modal === 'export' && <ExportModal onClose={closeModal} />}
		</Fragment>
	);
}
