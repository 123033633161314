import { ReactNode } from 'react';

export const symbol_transgender: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M479.798-340Q546-340 593-386.798t47-113Q640-566 593.202-613t-113-47Q414-660 367-613.202t-47 113Q320-434 366.798-387t113 47ZM450-120h-50q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T400-180h50v-101q-82.333-14.103-136.167-75.551Q260-418 260-500q0-36 11-70.11T304-633l-42-42-35 35q-9 9-21 9t-21-9q-9-9-9-21.1 0-12.1 9-20.9l35-35-100-100v87q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Q77-700 68.5-708.625T60-730v-160q0-12.75 8.625-21.375T90-920h160q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T250-860h-87l100 100 35-35q9-9 21.1-9 12.1 0 20.9 9 9 9 9 21.1 0 12.1-9 20.9l-35 35 43 43q29-22 62.5-33.5T480-720q36 0 70 11t63 33l184-184h-87q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T710-920h160q12.75 0 21.375 8.625T900-890v160q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T840-730v-87L656-633q22 29 33 62.773T700-500q0 82-53.833 143.449Q592.333-295.103 510-281v101h50q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T560-120h-50v50q0 12.75-8.675 21.375Q492.649-40 479.825-40 467-40 458.5-48.625T450-70v-50Z"
		/>
	</svg>
);
