import { ReactNode } from 'react';

export const symbol_compare: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M422-120H180q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h242v-50q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T482-890v820q0 12.75-8.675 21.375Q464.649-40 451.825-40 439-40 430.5-48.625T422-70v-50ZM180-222h242v-277L180-222Zm362 102v-375l238 273v-558H542v-60h238q24 0 42 18t18 42v600q0 24-18 42t-42 18H542Z"
		/>
	</svg>
);
