import { t } from '@transifex/native';

import * as sdk from 'pkg/core/sdk';
import * as endpoints from 'pkg/api/endpoints/auto';
import { flashes } from 'pkg/actions';
import { crash } from 'pkg/errors/errors';

export async function remove(id: number, deleteEvent = true) {
	const q: { [key: string]: string } = {};

	if (deleteEvent) {
		q.deleteEvent = 'true';
	}

	const req = await sdk.destroy(endpoints.Booking.Delete(id), q);

	if (!req.ok) {
		flashes.show({
			title: crash().title,
		});

		return false;
	}

	flashes.show({
		title: t('Successfully removed booking'),
	});

	return true;
}
