import { ReactNode } from 'react';

export const symbol_attach_file: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M728-326q0 103-72.175 174.5t-175 71.5Q378-80 305.5-151.5 233-223 233-326v-380q0-72.5 51.5-123.25T408-880q72 0 123.5 50.75T583-706v360q0 42-30 72t-72.5 30q-42.5 0-72.5-29.673-30-29.672-30-72.327v-340q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T438-686v340q0 17 12.5 29.5t30.64 12.5q18.139 0 30-12.5Q523-329 523-346v-360q0-48-33.5-81t-81.711-33q-48.212 0-81.5 33.06Q293-753.88 293-706v380q0 78 54.971 132T481-140q77.917 0 132.458-54Q668-248 668-326v-360q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T728-686v360Z"
		/>
	</svg>
);
