import { ReactNode } from 'react';

export const symbol_align_vertical_bottom: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M110-80q-12.75 0-21.375-8.675Q80-97.351 80-110.175 80-123 88.625-131.5T110-140h740q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q880-97 871.375-88.5T850-80H110Zm229.882-160Q319-240 304.5-254.583 290-269.167 290-290v-540q0-20.833 14.618-35.417Q319.235-880 340.118-880 361-880 375.5-865.417 390-850.833 390-830v540q0 20.833-14.618 35.417Q360.765-240 339.882-240Zm280 0Q599-240 584.5-254.583 570-269.167 570-290v-300q0-20.833 14.618-35.417Q599.235-640 620.118-640 641-640 655.5-625.417 670-610.833 670-590v300q0 20.833-14.618 35.417Q640.765-240 619.882-240Z"
		/>
	</svg>
);
