import { ReactNode } from 'react';

export const symbol_mintmark: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M738-180 541-377q-8-8-8-20t8-21q8-9 20-9.5t21 8.5l197 197v-176q0-12.75 8.877-21.375t22-8.625q13.123 0 21.623 8.625Q840-410.75 840-398v248q0 12.75-8.625 21.375T810-120H562q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T562-180h176ZM249-326h-97q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T152-386h218v-146H182q-24.75 0-42.375-17.625T122-592v-146q0-24.75 17.625-42.375T182-798h67v-12q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T309-810v12h92q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T401-738H182v146h188q26 0 43.5 17.625T431-532v146q0 24.75-17.5 42.375T370-326h-61v12q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T249-314v-12Z"
		/>
	</svg>
);
