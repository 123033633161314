import { createSelector } from 'reselect';
import { Record, Map } from 'immutable';

const activitySelector = (state) => state.activities.entities;
const likesSelector = (state) => state.activities.likes;

const userIdSelector = (_, props) => props.userId;
const activityIdSelector = (_, props) => props.id;

export const makeGetSingleActivity = () =>
	createSelector(
		activitySelector,
		activityIdSelector,
		(state) => state.users.entities,
		(entities, id, users) => {
			let activity = entities[id];

			if (Record.isRecord(activity)) {
				return activity.set('user', users.get(activity.userId));
			}

			return null;
		}
	);

export const makeGetActivityLikes = () =>
	createSelector(likesSelector, activityIdSelector, (likes, id) => likes[id]);

export const getActivities = createSelector(activitySelector, (entities) =>
	Object.keys(entities).map((id) => entities[id])
);

// @TODO: get sort order from the api
const getSortedActivities = createSelector(getActivities, (activities) =>
	activities.sort((a, b) => b.completedAt - a.completedAt)
);

export const makeGetActivitiesByUser = () =>
	createSelector(getSortedActivities, userIdSelector, (activities, userId) =>
		activities.filter((activity) => activity.userId === userId)
	);

export const findAllComments = createSelector(
	(state) => new Map(state.activities.comments),
	(_, { activity }) => activity,
	(comments, activity) => comments.getIn([activity.id.toString(), 'records'])
);
