import { Fragment, useState } from 'react';
import { useT } from '@transifex/react';

import { PageWidths } from 'pkg/config/sizes';
import * as styles from 'pkg/config/styles';

import * as endpoints from 'pkg/api/endpoints/auto';
import { useAccountIdentity } from 'pkg/identity/account';
import * as models from 'pkg/api/models';
import { useCollection } from 'pkg/api/use_collection';
import { MessageType, useWebSocketClient } from 'pkg/websocket';
import Link from 'pkg/router/Link';
import copyString from 'pkg/copyString';
import * as actions from 'pkg/actions';

import IdentityProviderCard from 'routes/group/settings/single-sign-on/identity-provider/card';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import SectionTitle from 'components/SectionTitle';

import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import SmallScreenContent from 'components/layout/SmallScreenContent';
import * as ActionBar from 'components/layout/ActionBar';
import CreateIdentityProviderModal from 'components/identity-provider/create-modal';
import { Spinner } from 'components/loaders/spinner';
import Column from 'components/layout/column';
import InfoBox from 'components/form/info-box';

import MissingEntities from 'design/placeholders/block';
import Button from 'design/button';

enum Modals {
	NewIdentityProvider = 'newIdentityProvider',
	Hide = '',
}

export default function SingleSignOnSettings() {
	const t = useT();
	const { organization } = useAccountIdentity();
	const [modal, setModal] = useState<Modals>();

	const {
		records: providers,
		isLoading,
		refresh,
		replaceRecord,
		removeRecord,
		addRecord,
	} = useCollection<models.identityProvider.IdentityProvider>(
		endpoints.IdentityProviders.Index(organization.id)
	);

	const ws = useWebSocketClient();

	ws.onMessage<models.identityProvider.IdentityProvider>(
		MessageType.IdentityProviderCreated,
		(message) => {
			addRecord(message.data);
		}
	);

	const availableUserTraits =
		models.identityProvider.getAvailableUserTraits(providers);

	const showNewIdentityProviderModal = () =>
		setModal(Modals.NewIdentityProvider);

	const hideModal = () => setModal(Modals.Hide);

	const emptyState = (
		<MissingEntities
			noBorder
			title={t('No connected identity providers yet')}
			description={t(
				'Connecting an external identity provider simplifies the way your organization accesses our platform by allowing you to use your existing enterprise single sign-on solutions and user directory sync.'
			)}
			helpUrl="https://help.360player.com/article/209-set-up-your-identity-provider-and-sso"
			helpUrlText={t('How to configure your identity provider connection')}
			icon="key"
			actions={
				<Button
					primary
					icon="add"
					label={t('Add identity provider')}
					onClick={showNewIdentityProviderModal}
				/>
			}
		/>
	);

	if (isLoading) {
		return <Spinner />;
	}

	const showEmptyState = providers.length === 0;
	let content = emptyState;
	const hasActiveProvider = providers.some((p) => p.connectionActive);
	const loginUrl = `${window.location.origin}/sso-login/${organization.slug}`;

	const copy = () => {
		copyString(loginUrl);

		actions.flashes.show({
			title: t('Login URL copied'),
		});
	};

	if (!showEmptyState) {
		content = (
			<Column>
				{hasActiveProvider && (
					<InfoBox>
						<span>
							{t('Login URL')} <Link onClick={copy}>{loginUrl}</Link>
						</span>
					</InfoBox>
				)}
				<SectionTitle>{t('Identity providers')}</SectionTitle>
				<Column spacing={styles.spacing._7}>
					{providers.map((p, i) => (
						<IdentityProviderCard
							key={i}
							provider={p}
							organization={organization}
							replaceRecord={replaceRecord}
							removeRecord={removeRecord}
						/>
					))}
				</Column>
			</Column>
		);
	}

	return (
		<Fragment>
			{!showEmptyState && (
				<ActionBar.Bar maxWidth={PageWidths.STANDARD}>
					<Button
						label={t('Add a identity provider')}
						primary
						icon="add"
						onClick={showNewIdentityProviderModal}
					/>
				</ActionBar.Bar>
			)}
			<LargeScreen>
				<LargeScreenContent.Wrapper>
					<LargeScreenContent.Inner maxWidth={PageWidths.STANDARD}>
						{content}
					</LargeScreenContent.Inner>
				</LargeScreenContent.Wrapper>
			</LargeScreen>

			<SmallScreen>
				<SmallScreenContent>
					<LargeScreenContent.Inner>{content}</LargeScreenContent.Inner>
				</SmallScreenContent>
			</SmallScreen>
			{modal === Modals.NewIdentityProvider && (
				<CreateIdentityProviderModal
					organizationId={organization.id}
					refresh={refresh}
					hideModal={hideModal}
					availableUserTraits={availableUserTraits}
				/>
			)}
		</Fragment>
	);
}
