import { ReactNode } from 'react';

export const symbol_decimal_increase: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M765-210H510q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T510-270h255l-52-53q-9-9-9-21t9-21q9-9 21-9t21 9l104 104q9 9 9 21t-9 21L755-115q-9 9-21 9t-21-9q-9-9-9-21.5t9-21.5l52-52ZM150-440h-40q-12.75 0-21.375-8.625T80-470v-40q0-12.75 8.625-21.375T110-540h40q12.75 0 21.375 8.625T180-510v40q0 12.75-8.625 21.375T150-440Zm230 0q-58 0-99-41t-41-99v-160q0-58 41-99t99-41q58 0 99 41t41 99v160q0 58-41 99t-99 41Zm360 0q-58 0-99-41t-41-99v-160q0-58 41-99t99-41q58 0 99 41t41 99v160q0 58-41 99t-99 41Zm-360.235-60Q413-500 436.5-523.333 460-546.667 460-580v-160q0-33.333-23.265-56.667Q413.471-820 380.235-820 347-820 323.5-796.667 300-773.333 300-740v160q0 33.333 23.265 56.667Q346.529-500 379.765-500Zm360 0Q773-500 796.5-523.333 820-546.667 820-580v-160q0-33.333-23.265-56.667Q773.471-820 740.235-820 707-820 683.5-796.667 660-773.333 660-740v160q0 33.333 23.265 56.667Q706.529-500 739.765-500Z"
		/>
	</svg>
);
