import { ReactNode } from 'react';

export const symbol_flex_wrap: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M40-110v-300q0-12.75 8.625-21.375T70-440h180q12.75 0 21.375 8.625T280-410v300q0 12.75-8.625 21.375T250-80H70q-12.75 0-21.375-8.625T40-110Zm320 0v-300q0-12.75 8.625-21.375T390-440h180q12.75 0 21.375 8.625T600-410v300q0 12.75-8.625 21.375T570-80H390q-12.75 0-21.375-8.625T360-110Zm320 0v-300q0-12.75 8.625-21.375T710-440h180q12.75 0 21.375 8.625T920-410v300q0 12.75-8.625 21.375T890-80H710q-12.75 0-21.375-8.625T680-110Zm-260-30h120v-240H420v240ZM40-550v-300q0-12.75 8.625-21.375T70-880h180q12.75 0 21.375 8.625T280-850v300q0 12.75-8.625 21.375T250-520H70q-12.75 0-21.375-8.625T40-550Zm320 0v-300q0-12.75 8.625-21.375T390-880h180q12.75 0 21.375 8.625T600-850v300q0 12.75-8.625 21.375T570-520H390q-12.75 0-21.375-8.625T360-550Zm320 0v-300q0-12.75 8.625-21.375T710-880h180q12.75 0 21.375 8.625T920-850v300q0 12.75-8.625 21.375T890-520H710q-12.75 0-21.375-8.625T680-550Zm-580-30h120v-240H100v240Zm640 0h120v-240H740v240Z"
		/>
	</svg>
);
