import { ReactNode } from 'react';

export const symbol_format_letter_spacing_wider: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M109.825-160Q97-160 88.5-168.625T80-190v-580q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-770v580q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Zm740 0Q837-160 828.5-168.625T820-190v-580q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T880-770v580q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM339-284q-11.027 0-17.514-9.5Q315-303 319-314l131-353q2-7 8-11t13.104-4h16.792Q495-682 501-678q6 4 8 11l132 353q4 11-2 20.5t-18.364 9.5q-8 0-13.818-4T599-299l-33-96H394l-34 97q-2.172 6.667-7.966 10.333Q346.241-284 339-284Zm70-151h142l-70-196h-2l-70 196Z"
		/>
	</svg>
);
