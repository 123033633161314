import { ChangeEvent } from 'react';

import { FilterRow } from 'pkg/filters/use_filters';
import { BaseFilterProps } from 'pkg/filters/types';

import * as Input from 'components/form/inputs';

type TextProps = BaseFilterProps;

export function Text({
	filter,
	setFilter,
	currentFilters,
}: TextProps): JSX.Element {
	const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
		setFilter(filter, [event.target.value], false);

	return (
		<FilterRow>
			<Input.Field
				onChange={handleChange}
				changeDelay={350}
				defaultValue={currentFilters?.[0]?.values[0]}
			/>
		</FilterRow>
	);
}
