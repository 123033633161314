import { ReactNode } from 'react';

export const symbol_not_accessible: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M792-83 585-289H482q-31 0-51.5-20.5T410-361v-103L82-792q-9-9-9-21.5t9-21.5q9-9 21.5-9t21.5 9l710 710q9 9 9 21t-9 21q-9 9-21.5 9T792-83Zm-396 3q-83 0-139.5-56.5T200-276q0-73 53-128.5T380-468v61q-54 5-87 45.5T260-276q0 57 39.5 96.5T396-140q49 0 88-33.5t43-85.5h61q-8 78-65 128.5T396-80Zm86-654q-30 0-51.5-21.5T409-807q0-30 21.5-51.5T482-880q30 0 51.5 21.5T555-807q0 30-21.5 51.5T482-734Zm278 245q0 13-9 21.5t-21 6.5q-47-7-93.5-31.5T551-551v57L413-632q8-22 25.5-35t41.5-13q21 0 35 6.5t31 23.5l38 41q26 29 65.5 54t80.5 33q13 2 21.5 11t8.5 22Z"
		/>
	</svg>
);
