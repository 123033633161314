import { ReactNode } from 'react';

export const symbol_tactic: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m735-183-43 42q-9 8-21 8.5t-21-8.5q-9-9-8.5-21t8.5-21l42-43-42-42q-9-9-9-21t9-21q9-9 21-9t21 9l43 42 42-42q9-9 21-9.5t21 8.5q9 9 9 21.5t-9 21.5l-42 42 42 43q9 9 9 21t-9 21q-9 9-21 9t-21-9l-42-42Zm-505 3q21 0 35.5-14.5T280-230q0-21-14.5-35.5T230-280q-21 0-35.5 14.5T180-230q0 21 14.5 35.5T230-180Zm0 60q-46 0-78-32t-32-78q0-46 32-78t78-32q38 0 67 22.5t39 58.5q48-2 81-37t33-83v-181q0-79 55.5-134.5T640-750h84l-52-52q-9-9-8.5-21t9.5-21q9-9 21-9t21 9l104 103q9 9 9 21t-9 21L715-595q-9 9-21 9t-21-9q-9-9-9.5-21.5T672-638l52-52h-84q-54 0-92 38t-38 92v181q0 73-50.5 125.5T336-199q-11 35-40 57t-66 22Zm-4-571-43 42q-9 8-21 8.5t-21-8.5q-9-9-8.5-21t8.5-21l42-43-42-42q-9-9-9-21t9-21q9-9 21-9t21 9l43 42 42-42q9-9 21-9.5t21 8.5q9 9 9 21.5t-9 21.5l-42 42 42 43q9 9 9 21t-9 21q-9 9-21 9t-21-9l-42-42Z"
		/>
	</svg>
);
