import { ReactNode } from 'react';

export const symbol_view_compact: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M80-170v-620h800v620H80Zm60-433h125v-127H140v127Zm185 0h125v-127H325v127Zm185 0h125v-127H510v127Zm185 0h125v-127H695v127Zm0 187h125v-127H695v127Zm-185 0h125v-127H510v127Zm-185 0h125v-127H325v127Zm-60-127H140v127h125v-127Zm430 313h125v-126H695v126Zm-185 0h125v-126H510v126Zm-185 0h125v-126H325v126Zm-185 0h125v-126H140v126Z"
		/>
	</svg>
);
