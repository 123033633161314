import { ReactNode } from 'react';

export const symbol_move_down: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M100-520q0 91 67 152.5T326-308l-68-68q-9-9-9-21t9-21q9-9 21.5-9t21.5 9l118 117q9 9 9 21t-9 21L301-141q-9 9-21 8.5t-21-9.5q-9-9-9-21.5t9-21.5l64-64q-116 6-199.5-73.5T40-520q0-117 81.5-198.5T320-800h90q13 0 21.5 8.5T440-770q0 13-8.5 21.5T410-740h-90q-92 0-156 64t-64 156Zm450 360q-13 0-21.5-8.5T520-190v-220q0-13 8.5-21.5T550-440h300q13 0 21.5 8.5T880-410v220q0 13-8.5 21.5T850-160H550Zm0-360q-13 0-21.5-8.5T520-550v-220q0-13 8.5-21.5T550-800h300q13 0 21.5 8.5T880-770v220q0 13-8.5 21.5T850-520H550Zm30-60h240v-160H580v160Z"
		/>
	</svg>
);
