// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TqNYk8Pqw5sqRQuF62wt {
	position: absolute;
	right: 0;
	top: 0;
	-webkit-box-shadow:
		0 4px 8px rgba(0, 0, 0, 0.2),
		0 6px 20px rgba(0, 0, 0, 0.19);
	        box-shadow:
		0 4px 8px rgba(0, 0, 0, 0.2),
		0 6px 20px rgba(0, 0, 0, 0.19);
	background-color: var(--palette-red-500);
	color: var(--palette-white);
	padding: 0 6px;
	z-index: 1;
	font-size: var(--font-size-2xs);
	font-weight: var(--font-weight-semibold);
	border-radius: 55px;
	-webkit-transform: translate(50%, -40%);
	        transform: translate(50%, -40%);
	line-height: 15px;
}
.TqNYk8Pqw5sqRQuF62wt.DD4kw88d4mnIbTjJEKbv {
		padding: 0;
		height: 11px;
		width: 11px;
	}
.TqNYk8Pqw5sqRQuF62wt._0dNwX0HFcVozbNqOLBcA {
		border: 3px solid var(--palette-white);
	}
`, "",{"version":3,"sources":["webpack://./components/navigation/notification_badge/styles.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,QAAQ;CACR,MAAM;CACN;;gCAE4B;SAF5B;;gCAE4B;CAC5B,wCAAwC;CACxC,2BAA2B;CAC3B,cAAc;CACd,UAAU;CACV,+BAA+B;CAC/B,wCAAwC;CACxC,mBAAmB;CACnB,uCAA+B;SAA/B,+BAA+B;CAC/B,iBAAiB;AAWlB;AATC;EACC,UAAU;EACV,YAAY;EACZ,WAAW;CACZ;AAEA;EACC,sCAAsC;CACvC","sourcesContent":[".notifications {\n\tposition: absolute;\n\tright: 0;\n\ttop: 0;\n\tbox-shadow:\n\t\t0 4px 8px rgb(0 0 0 / 20%),\n\t\t0 6px 20px rgb(0 0 0 / 19%);\n\tbackground-color: var(--palette-red-500);\n\tcolor: var(--palette-white);\n\tpadding: 0 6px;\n\tz-index: 1;\n\tfont-size: var(--font-size-2xs);\n\tfont-weight: var(--font-weight-semibold);\n\tborder-radius: 55px;\n\ttransform: translate(50%, -40%);\n\tline-height: 15px;\n\n\t&.pill {\n\t\tpadding: 0;\n\t\theight: 11px;\n\t\twidth: 11px;\n\t}\n\n\t&.amount {\n\t\tborder: 3px solid var(--palette-white);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var notifications = `TqNYk8Pqw5sqRQuF62wt`;
export var pill = `DD4kw88d4mnIbTjJEKbv`;
export var amount = `_0dNwX0HFcVozbNqOLBcA`;
export default ___CSS_LOADER_EXPORT___;
