import styled, { css } from 'styled-components';

import * as styles from 'pkg/config/styles';

import Logo from 'components/Logo';
import Icon, { IconName } from 'components/icon';

interface WrapperProps {
	relative?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: var(--spacing-8) var(--spacing-6);
	overflow: hidden;

	${({ relative }) =>
		relative
			? css`
					position: relative !important;
					height: 100%;
				`
			: css`
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
				`}
`;

const BackgroundImage = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	img {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
`;

const BackgroundGradient = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: linear-gradient(
		0deg,
		rgba(35, 29, 79, 1) 0%,
		rgba(35, 29, 79, 0.75) 100%
	);
`;

const ContentWrapper = styled.div`
	display: grid;
	row-gap: var(--spacing-4);
	position: relative;
	padding-bottom: 80px;
	text-align: center;
`;

const CustomIcon = styled(Icon)`
	justify-self: center;
	font-size: 40px;
	color: ${styles.palette.brand};
`;

const Heading = styled.h1`
	font-size: var(--font-size-3xl);
	line-height: var(--font-line-height-3xl);
	color: ${styles.palette.brand};
`;

const Message = styled.p`
	font-size: var(--font-size-base);
	line-height: var(--font-line-height-xl);
	font-weight: var(--font-weight-semibold);
	color: var(--palette-white);
`;

const Children = styled.div`
	margin-top: var(--spacing-6);
`;

const StyledLogo = styled(Logo)`
	position: absolute;
	bottom: 40px;
	left: 50%;
	transform: translateX(-50%);
	width: 80px;
	height: auto;
`;

interface InfoScreenProps {
	icon?: IconName;
	heading?: string;
	message?: string;
	relative?: boolean;
	children?: React.ReactNode;
}

export default function InfoScreen({
	icon,
	heading,
	message,
	relative,
	children,
}: InfoScreenProps): JSX.Element {
	return (
		<Wrapper relative={relative}>
			<BackgroundImage>
				<picture>
					<source
						media={`${styles.breakpoint.small} and (orientation: portrait)`}
						srcSet={`${window.TS.assetUrl}/img/StartImg-small.jpg`}
					/>
					<source
						media="(max-width:1920px)"
						srcSet={`${window.TS.assetUrl}/img/StartImg-medium.jpg`}
					/>
					<img src={`${window.TS.assetUrl}/img/StartImg-large.jpg`} />
				</picture>
				<BackgroundGradient />
			</BackgroundImage>
			<ContentWrapper>
				{icon && <CustomIcon name={icon} />}
				{heading && <Heading>{heading}</Heading>}
				{message && <Message>{message}</Message>}
				{children && <Children>{children}</Children>}
			</ContentWrapper>
			{!relative && <StyledLogo />}
		</Wrapper>
	);
}
