import { ReactNode } from 'react';

export const symbol_congenital: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M392-200q-100 0-185.5-51T74-391l-25-46q-11-22-8.5-46T59-526l212-257q6.818-8.36 15.909-13.68Q296-802 306-804q10-2 20-.5t20 6.5l134 72 98-54q17-10 36-7t33 17q14 13 17.5 31.5T660-703L536-414q-11 26-36 39t-54 7l-328-69q39 80 112.5 128.5T392-260h179q64 0 120.5-27.5T794-360h-61q-37 0-60.5-28T654-452l27-178q3-19 15-31t28-17q16-5 32.5-.5T786-660l114 134q15 18 18.5 41.5T912-440l-15 32q-45 95-132.5 151.5T571-200H392Zm446-221 26-56-124-143-30 199h128Zm-723-79 360 77 131-304-126 70-164-89-201 246Zm245-84Zm427 107Z"
		/>
	</svg>
);
