import { ReactNode } from 'react';

export const symbol_partly_cloudy_day: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M479.825-770Q467-770 458.5-778.625T450-800v-90q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-890v90q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM685-685q-9-9-9-21.5t9-21.5l63-63q9.067-9 21.533-9Q782-800 791-791q9 9 9 21t-9 21l-64 64q-9 9-21 9t-21-9Zm115 235q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T800-510h90q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T890-450h-90Zm-51 281-63-63q-9-9-9-21.5t9-21.5q9-9 21.5-9t21.5 9l63 63q9 9.067 9 21.533Q801-178 791.947-169q-9.052 9-21.5 9Q758-160 749-169ZM233-685l-64-64q-9-9-9-21t9-21q8.8-9 20.9-9 12.1 0 21.1 9l64 64q9 9 9 21t-9 21q-9 9-21 9t-21-9Zm7 505h180q33.333 0 56.667-23.265Q500-226.529 500-259.765 500-293 477.237-317 454.475-341 421-341h-44l-18-41q-15.145-35.75-47.6-56.875T240-460q-58.333 0-99.167 40.765-40.833 40.764-40.833 99Q100-262 140.833-221q40.834 41 99.167 41Zm0 60q-83 0-141.5-58.5T40-320q0-83 58.5-141.5T240-520q57.736 0 105.368 32.5T416-401q60.117 0 101.058 43.589Q558-313.822 558-253q-5 56-44.033 94.5Q474.935-120 420-120H240Zm318-133q-3-15.385-6-30t-6-30q52-20 83-65.538 31-45.539 31-101.324Q660-555 607.5-607.5T480-660q-67.215 0-117.627 42.674Q311.962-574.652 303-509q-16-3-31.5-5.5T240-520q14-88 82.5-144T480-720q100 0 170 70t70 170.342Q720-402 675.5-340 631-278 558-253Zm-77-227Z"
		/>
	</svg>
);
