import { ReactNode } from 'react';

export const symbol_pending_actions: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M691-80q-78.435 0-133.718-55.283Q502-190.565 502-269q0-78.435 55.282-133.717Q612.565-458 691-458q78.435 0 133.718 55.283Q880-347.435 880-269q0 78.435-55.282 133.717Q769.435-80 691-80Zm11-191v-92.92q0-8.08-5.7-13.58T683-383q-8 0-14 6t-6 14v94q0 5.565 2 10.783Q667-253 671-248l64 66q6 6 14 6t14-6q6-6 6-14t-6-14l-61-61ZM180-120q-24.75 0-42.375-17.625T120-180v-600q0-26 17-43t43-17h202q7-35 34.5-57.5T480-920q36 0 63.5 22.5T578-840h202q26 0 43 17t17 43v239q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T780-541v-239h-60v60q0 12.75-8.625 21.375T690-690H270q-12.75 0-21.375-8.625T240-720v-60h-60v600h258q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T438-120H180Zm300-660q17 0 28.5-11.5T520-820q0-17-11.5-28.5T480-860q-17 0-28.5 11.5T440-820q0 17 11.5 28.5T480-780Z"
		/>
	</svg>
);
