import { ReactNode } from 'react';

export const symbol_nest_thermostat_e_eu: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M191-90q-26 0-43-17t-17-43h202q-110-48-171.5-144.5T100-500q0-78 30-147t82-121q52-52 121-82t147-30q78 0 147 30t121 82q52 52 82 121t30 147q0 109-61.5 205.5T627-150h202q0 26-17 43t-43 17H191Zm289-90q132 0 226-94t94-226q0-132-94-226t-226-94q-132 0-226 94t-94 226q0 132 94 226t226 94Zm103-168q49 0 73.5-37.5T681-480q0-57-25-94.5T583-612q-49 0-73 37.5T486-480q0 57 24 94.5t73 37.5Zm0-30q-28 0-47.5-24.5T516-480q0-46 17-74t50-28q33 0 50.5 28t17.5 74q0 53-19.5 77.5T583-378ZM480-500Zm-55 146q6 0 10.5-4.5T440-369q0-6-4.5-10.5T425-384H319l-1-3q6-5 2.5-2t-2 1.5q1.5-1.5 15-13T387-445q28-24 39.5-45.5T438-538q0-31-23-52.5T359-612q-31 0-54 15t-34 43q-1 2-1 6 0 6 4 10.5t10 4.5q5 0 8.5-2.5t5.5-7.5q8-19 23.5-29t36.5-10q23 0 36.5 12t13.5 32q0 21-10.5 37.5T357-458q11-9-6.5 5.5T295-407l-17 15q-1 1-5 11v12q0 6 4.5 10.5T288-354h137Z"
		/>
	</svg>
);
