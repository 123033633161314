import { ReactNode } from 'react';

export const symbol_household_supplies: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M300-80q-24 0-42-18t-18-42v-185q0-81 24-156.5T342-631q-45 2-73.5-27.5T240-728v-48q0-45 34.5-74t76.5-25l377 40q16 2 24.5 15.5T761-795v86q0 17-9 27.5T728-669l-44 4q8 41 28 75t46 59q9 9 11 21t-4 23q-7 11-19 13.5t-21-5.5q-37-31-63.5-80.5T624-659l-77 9q8 60 31 113.5T637-426q19 29 28.5 66.5T675-286v146q0 24-18 42t-42 18H300Zm1-696v48q0 20 15.5 30.5T349-690l352-37v-51l-352-37q-17-2-32.5 8.5T301-776Zm-1 636h315v-146q0-27-7-54.5T587-392q-41-69-63.5-122T487-644l-68 7-31 44q-45 63-66.5 128T300-325v185Zm0 0h315-315Z"
		/>
	</svg>
);
