import { ReactNode } from 'react';

export const symbol_request_quote: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M450-241v25q0 6 4.5 10.5T465-201h30q6 0 10.5-4.5T510-216v-25h60q12.75 0 21.375-8.625T600-271v-130q0-12.75-8.625-21.375T570-431H420v-70h150q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T570-561h-60v-25q0-6-4.5-10.5T495-601h-30q-6 0-10.5 4.5T450-586v25h-60q-12.75 0-21.375 8.625T360-531v130q0 12.75 8.625 21.375T390-371h150v70H390q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T390-241h60ZM220-80q-24 0-42-18t-18-42v-680q0-24 18-42t42-18h361l219 219v521q0 24-18 42t-42 18H220Zm0-60h520v-494H584q-12.75 0-21.375-8.625T554-664v-156H220v680Zm0-680v186-186 680-680Z"
		/>
	</svg>
);
