import { symbol_10k } from 'components/material-symbols/symbols/10k';
import { symbol_10mp } from 'components/material-symbols/symbols/10mp';
import { symbol_11mp } from 'components/material-symbols/symbols/11mp';
import { symbol_123 } from 'components/material-symbols/symbols/123';
import { symbol_12mp } from 'components/material-symbols/symbols/12mp';
import { symbol_13mp } from 'components/material-symbols/symbols/13mp';
import { symbol_14mp } from 'components/material-symbols/symbols/14mp';
import { symbol_15mp } from 'components/material-symbols/symbols/15mp';
import { symbol_16mp } from 'components/material-symbols/symbols/16mp';
import { symbol_17mp } from 'components/material-symbols/symbols/17mp';
import { symbol_18_up_rating } from 'components/material-symbols/symbols/18_up_rating';
import { symbol_18mp } from 'components/material-symbols/symbols/18mp';
import { symbol_19mp } from 'components/material-symbols/symbols/19mp';
import { symbol_1k } from 'components/material-symbols/symbols/1k';
import { symbol_1k_plus } from 'components/material-symbols/symbols/1k_plus';
import { symbol_1x_mobiledata } from 'components/material-symbols/symbols/1x_mobiledata';
import { symbol_1x_mobiledata_badge } from 'components/material-symbols/symbols/1x_mobiledata_badge';
import { symbol_20mp } from 'components/material-symbols/symbols/20mp';
import { symbol_21mp } from 'components/material-symbols/symbols/21mp';
import { symbol_22mp } from 'components/material-symbols/symbols/22mp';
import { symbol_23mp } from 'components/material-symbols/symbols/23mp';
import { symbol_24mp } from 'components/material-symbols/symbols/24mp';
import { symbol_2d } from 'components/material-symbols/symbols/2d';
import { symbol_2k } from 'components/material-symbols/symbols/2k';
import { symbol_2k_plus } from 'components/material-symbols/symbols/2k_plus';
import { symbol_2mp } from 'components/material-symbols/symbols/2mp';
import { symbol_30fps } from 'components/material-symbols/symbols/30fps';
import { symbol_30fps_select } from 'components/material-symbols/symbols/30fps_select';
import { symbol_360 } from 'components/material-symbols/symbols/360';
import { symbol_3d_rotation } from 'components/material-symbols/symbols/3d_rotation';
import { symbol_3g_mobiledata } from 'components/material-symbols/symbols/3g_mobiledata';
import { symbol_3g_mobiledata_badge } from 'components/material-symbols/symbols/3g_mobiledata_badge';
import { symbol_3k } from 'components/material-symbols/symbols/3k';
import { symbol_3k_plus } from 'components/material-symbols/symbols/3k_plus';
import { symbol_3mp } from 'components/material-symbols/symbols/3mp';
import { symbol_3p } from 'components/material-symbols/symbols/3p';
import { symbol_4g_mobiledata } from 'components/material-symbols/symbols/4g_mobiledata';
import { symbol_4g_mobiledata_badge } from 'components/material-symbols/symbols/4g_mobiledata_badge';
import { symbol_4g_plus_mobiledata } from 'components/material-symbols/symbols/4g_plus_mobiledata';
import { symbol_4k } from 'components/material-symbols/symbols/4k';
import { symbol_4k_plus } from 'components/material-symbols/symbols/4k_plus';
import { symbol_4mp } from 'components/material-symbols/symbols/4mp';
import { symbol_50mp } from 'components/material-symbols/symbols/50mp';
import { symbol_5g } from 'components/material-symbols/symbols/5g';
import { symbol_5g_mobiledata_badge } from 'components/material-symbols/symbols/5g_mobiledata_badge';
import { symbol_5k } from 'components/material-symbols/symbols/5k';
import { symbol_5k_plus } from 'components/material-symbols/symbols/5k_plus';
import { symbol_5mp } from 'components/material-symbols/symbols/5mp';
import { symbol_60fps } from 'components/material-symbols/symbols/60fps';
import { symbol_60fps_select } from 'components/material-symbols/symbols/60fps_select';
import { symbol_6_ft_apart } from 'components/material-symbols/symbols/6_ft_apart';
import { symbol_6k } from 'components/material-symbols/symbols/6k';
import { symbol_6k_plus } from 'components/material-symbols/symbols/6k_plus';
import { symbol_6mp } from 'components/material-symbols/symbols/6mp';
import { symbol_7k } from 'components/material-symbols/symbols/7k';
import { symbol_7k_plus } from 'components/material-symbols/symbols/7k_plus';
import { symbol_7mp } from 'components/material-symbols/symbols/7mp';
import { symbol_8k } from 'components/material-symbols/symbols/8k';
import { symbol_8k_plus } from 'components/material-symbols/symbols/8k_plus';
import { symbol_8mp } from 'components/material-symbols/symbols/8mp';
import { symbol_9k } from 'components/material-symbols/symbols/9k';
import { symbol_9k_plus } from 'components/material-symbols/symbols/9k_plus';
import { symbol_9mp } from 'components/material-symbols/symbols/9mp';
import { symbol_abc } from 'components/material-symbols/symbols/abc';
import { symbol_ac_unit } from 'components/material-symbols/symbols/ac_unit';
import { symbol_accessibility } from 'components/material-symbols/symbols/accessibility';
import { symbol_accessibility_new } from 'components/material-symbols/symbols/accessibility_new';
import { symbol_accessible } from 'components/material-symbols/symbols/accessible';
import { symbol_accessible_forward } from 'components/material-symbols/symbols/accessible_forward';
import { symbol_account_balance } from 'components/material-symbols/symbols/account_balance';
import { symbol_account_balance_wallet } from 'components/material-symbols/symbols/account_balance_wallet';
import { symbol_account_box } from 'components/material-symbols/symbols/account_box';
import { symbol_account_child } from 'components/material-symbols/symbols/account_child';
import { symbol_account_child_invert } from 'components/material-symbols/symbols/account_child_invert';
import { symbol_account_circle } from 'components/material-symbols/symbols/account_circle';
import { symbol_account_circle_off } from 'components/material-symbols/symbols/account_circle_off';
import { symbol_account_tree } from 'components/material-symbols/symbols/account_tree';
import { symbol_action_key } from 'components/material-symbols/symbols/action_key';
import { symbol_activity_zone } from 'components/material-symbols/symbols/activity_zone';
import { symbol_acute } from 'components/material-symbols/symbols/acute';
import { symbol_ad } from 'components/material-symbols/symbols/ad';
import { symbol_ad_group } from 'components/material-symbols/symbols/ad_group';
import { symbol_ad_group_off } from 'components/material-symbols/symbols/ad_group_off';
import { symbol_ad_off } from 'components/material-symbols/symbols/ad_off';
import { symbol_ad_units } from 'components/material-symbols/symbols/ad_units';
import { symbol_adaptive_audio_mic } from 'components/material-symbols/symbols/adaptive_audio_mic';
import { symbol_adaptive_audio_mic_off } from 'components/material-symbols/symbols/adaptive_audio_mic_off';
import { symbol_adb } from 'components/material-symbols/symbols/adb';
import { symbol_add } from 'components/material-symbols/symbols/add';
import { symbol_add_a_photo } from 'components/material-symbols/symbols/add_a_photo';
import { symbol_add_ad } from 'components/material-symbols/symbols/add_ad';
import { symbol_add_alert } from 'components/material-symbols/symbols/add_alert';
import { symbol_add_box } from 'components/material-symbols/symbols/add_box';
import { symbol_add_business } from 'components/material-symbols/symbols/add_business';
import { symbol_add_call } from 'components/material-symbols/symbols/add_call';
import { symbol_add_card } from 'components/material-symbols/symbols/add_card';
import { symbol_add_chart } from 'components/material-symbols/symbols/add_chart';
import { symbol_add_circle } from 'components/material-symbols/symbols/add_circle';
import { symbol_add_comment } from 'components/material-symbols/symbols/add_comment';
import { symbol_add_diamond } from 'components/material-symbols/symbols/add_diamond';
import { symbol_add_home } from 'components/material-symbols/symbols/add_home';
import { symbol_add_home_work } from 'components/material-symbols/symbols/add_home_work';
import { symbol_add_link } from 'components/material-symbols/symbols/add_link';
import { symbol_add_location } from 'components/material-symbols/symbols/add_location';
import { symbol_add_location_alt } from 'components/material-symbols/symbols/add_location_alt';
import { symbol_add_moderator } from 'components/material-symbols/symbols/add_moderator';
import { symbol_add_notes } from 'components/material-symbols/symbols/add_notes';
import { symbol_add_photo_alternate } from 'components/material-symbols/symbols/add_photo_alternate';
import { symbol_add_reaction } from 'components/material-symbols/symbols/add_reaction';
import { symbol_add_road } from 'components/material-symbols/symbols/add_road';
import { symbol_add_shopping_cart } from 'components/material-symbols/symbols/add_shopping_cart';
import { symbol_add_task } from 'components/material-symbols/symbols/add_task';
import { symbol_add_to_drive } from 'components/material-symbols/symbols/add_to_drive';
import { symbol_add_to_home_screen } from 'components/material-symbols/symbols/add_to_home_screen';
import { symbol_add_to_photos } from 'components/material-symbols/symbols/add_to_photos';
import { symbol_add_to_queue } from 'components/material-symbols/symbols/add_to_queue';
import { symbol_add_triangle } from 'components/material-symbols/symbols/add_triangle';
import { symbol_adf_scanner } from 'components/material-symbols/symbols/adf_scanner';
import { symbol_adjust } from 'components/material-symbols/symbols/adjust';
import { symbol_admin_meds } from 'components/material-symbols/symbols/admin_meds';
import { symbol_admin_panel_settings } from 'components/material-symbols/symbols/admin_panel_settings';
import { symbol_ads_click } from 'components/material-symbols/symbols/ads_click';
import { symbol_agender } from 'components/material-symbols/symbols/agender';
import { symbol_agriculture } from 'components/material-symbols/symbols/agriculture';
import { symbol_air } from 'components/material-symbols/symbols/air';
import { symbol_air_freshener } from 'components/material-symbols/symbols/air_freshener';
import { symbol_air_purifier } from 'components/material-symbols/symbols/air_purifier';
import { symbol_air_purifier_gen } from 'components/material-symbols/symbols/air_purifier_gen';
import { symbol_airline_seat_flat } from 'components/material-symbols/symbols/airline_seat_flat';
import { symbol_airline_seat_flat_angled } from 'components/material-symbols/symbols/airline_seat_flat_angled';
import { symbol_airline_seat_individual_suite } from 'components/material-symbols/symbols/airline_seat_individual_suite';
import { symbol_airline_seat_legroom_extra } from 'components/material-symbols/symbols/airline_seat_legroom_extra';
import { symbol_airline_seat_legroom_normal } from 'components/material-symbols/symbols/airline_seat_legroom_normal';
import { symbol_airline_seat_legroom_reduced } from 'components/material-symbols/symbols/airline_seat_legroom_reduced';
import { symbol_airline_seat_recline_extra } from 'components/material-symbols/symbols/airline_seat_recline_extra';
import { symbol_airline_seat_recline_normal } from 'components/material-symbols/symbols/airline_seat_recline_normal';
import { symbol_airline_stops } from 'components/material-symbols/symbols/airline_stops';
import { symbol_airlines } from 'components/material-symbols/symbols/airlines';
import { symbol_airplane_ticket } from 'components/material-symbols/symbols/airplane_ticket';
import { symbol_airplanemode_active } from 'components/material-symbols/symbols/airplanemode_active';
import { symbol_airplanemode_inactive } from 'components/material-symbols/symbols/airplanemode_inactive';
import { symbol_airplay } from 'components/material-symbols/symbols/airplay';
import { symbol_airport_shuttle } from 'components/material-symbols/symbols/airport_shuttle';
import { symbol_airware } from 'components/material-symbols/symbols/airware';
import { symbol_airwave } from 'components/material-symbols/symbols/airwave';
import { symbol_alarm } from 'components/material-symbols/symbols/alarm';
import { symbol_alarm_add } from 'components/material-symbols/symbols/alarm_add';
import { symbol_alarm_off } from 'components/material-symbols/symbols/alarm_off';
import { symbol_alarm_on } from 'components/material-symbols/symbols/alarm_on';
import { symbol_alarm_smart_wake } from 'components/material-symbols/symbols/alarm_smart_wake';
import { symbol_album } from 'components/material-symbols/symbols/album';
import { symbol_align_center } from 'components/material-symbols/symbols/align_center';
import { symbol_align_end } from 'components/material-symbols/symbols/align_end';
import { symbol_align_flex_center } from 'components/material-symbols/symbols/align_flex_center';
import { symbol_align_flex_end } from 'components/material-symbols/symbols/align_flex_end';
import { symbol_align_flex_start } from 'components/material-symbols/symbols/align_flex_start';
import { symbol_align_horizontal_center } from 'components/material-symbols/symbols/align_horizontal_center';
import { symbol_align_horizontal_left } from 'components/material-symbols/symbols/align_horizontal_left';
import { symbol_align_horizontal_right } from 'components/material-symbols/symbols/align_horizontal_right';
import { symbol_align_items_stretch } from 'components/material-symbols/symbols/align_items_stretch';
import { symbol_align_justify_center } from 'components/material-symbols/symbols/align_justify_center';
import { symbol_align_justify_flex_end } from 'components/material-symbols/symbols/align_justify_flex_end';
import { symbol_align_justify_flex_start } from 'components/material-symbols/symbols/align_justify_flex_start';
import { symbol_align_justify_space_around } from 'components/material-symbols/symbols/align_justify_space_around';
import { symbol_align_justify_space_between } from 'components/material-symbols/symbols/align_justify_space_between';
import { symbol_align_justify_space_even } from 'components/material-symbols/symbols/align_justify_space_even';
import { symbol_align_justify_stretch } from 'components/material-symbols/symbols/align_justify_stretch';
import { symbol_align_self_stretch } from 'components/material-symbols/symbols/align_self_stretch';
import { symbol_align_space_around } from 'components/material-symbols/symbols/align_space_around';
import { symbol_align_space_between } from 'components/material-symbols/symbols/align_space_between';
import { symbol_align_space_even } from 'components/material-symbols/symbols/align_space_even';
import { symbol_align_start } from 'components/material-symbols/symbols/align_start';
import { symbol_align_stretch } from 'components/material-symbols/symbols/align_stretch';
import { symbol_align_vertical_bottom } from 'components/material-symbols/symbols/align_vertical_bottom';
import { symbol_align_vertical_center } from 'components/material-symbols/symbols/align_vertical_center';
import { symbol_align_vertical_top } from 'components/material-symbols/symbols/align_vertical_top';
import { symbol_all_inbox } from 'components/material-symbols/symbols/all_inbox';
import { symbol_all_inclusive } from 'components/material-symbols/symbols/all_inclusive';
import { symbol_all_match } from 'components/material-symbols/symbols/all_match';
import { symbol_all_out } from 'components/material-symbols/symbols/all_out';
import { symbol_allergies } from 'components/material-symbols/symbols/allergies';
import { symbol_allergy } from 'components/material-symbols/symbols/allergy';
import { symbol_alt_route } from 'components/material-symbols/symbols/alt_route';
import { symbol_alternate_email } from 'components/material-symbols/symbols/alternate_email';
import { symbol_altitude } from 'components/material-symbols/symbols/altitude';
import { symbol_ambulance } from 'components/material-symbols/symbols/ambulance';
import { symbol_amend } from 'components/material-symbols/symbols/amend';
import { symbol_amp_stories } from 'components/material-symbols/symbols/amp_stories';
import { symbol_analytics } from 'components/material-symbols/symbols/analytics';
import { symbol_anchor } from 'components/material-symbols/symbols/anchor';
import { symbol_android } from 'components/material-symbols/symbols/android';
import { symbol_animated_images } from 'components/material-symbols/symbols/animated_images';
import { symbol_animation } from 'components/material-symbols/symbols/animation';
import { symbol_aod } from 'components/material-symbols/symbols/aod';
import { symbol_aod_tablet } from 'components/material-symbols/symbols/aod_tablet';
import { symbol_aod_watch } from 'components/material-symbols/symbols/aod_watch';
import { symbol_apartment } from 'components/material-symbols/symbols/apartment';
import { symbol_api } from 'components/material-symbols/symbols/api';
import { symbol_apk_document } from 'components/material-symbols/symbols/apk_document';
import { symbol_apk_install } from 'components/material-symbols/symbols/apk_install';
import { symbol_app_badging } from 'components/material-symbols/symbols/app_badging';
import { symbol_app_blocking } from 'components/material-symbols/symbols/app_blocking';
import { symbol_app_promo } from 'components/material-symbols/symbols/app_promo';
import { symbol_app_registration } from 'components/material-symbols/symbols/app_registration';
import { symbol_app_shortcut } from 'components/material-symbols/symbols/app_shortcut';
import { symbol_apparel } from 'components/material-symbols/symbols/apparel';
import { symbol_approval } from 'components/material-symbols/symbols/approval';
import { symbol_approval_delegation } from 'components/material-symbols/symbols/approval_delegation';
import { symbol_apps } from 'components/material-symbols/symbols/apps';
import { symbol_apps_outage } from 'components/material-symbols/symbols/apps_outage';
import { symbol_aq } from 'components/material-symbols/symbols/aq';
import { symbol_aq_indoor } from 'components/material-symbols/symbols/aq_indoor';
import { symbol_ar_on_you } from 'components/material-symbols/symbols/ar_on_you';
import { symbol_ar_stickers } from 'components/material-symbols/symbols/ar_stickers';
import { symbol_architecture } from 'components/material-symbols/symbols/architecture';
import { symbol_archive } from 'components/material-symbols/symbols/archive';
import { symbol_area_chart } from 'components/material-symbols/symbols/area_chart';
import { symbol_arming_countdown } from 'components/material-symbols/symbols/arming_countdown';
import { symbol_arrow_and_edge } from 'components/material-symbols/symbols/arrow_and_edge';
import { symbol_arrow_back } from 'components/material-symbols/symbols/arrow_back';
import { symbol_arrow_back_ios } from 'components/material-symbols/symbols/arrow_back_ios';
import { symbol_arrow_back_ios_new } from 'components/material-symbols/symbols/arrow_back_ios_new';
import { symbol_arrow_circle_down } from 'components/material-symbols/symbols/arrow_circle_down';
import { symbol_arrow_circle_left } from 'components/material-symbols/symbols/arrow_circle_left';
import { symbol_arrow_circle_right } from 'components/material-symbols/symbols/arrow_circle_right';
import { symbol_arrow_circle_up } from 'components/material-symbols/symbols/arrow_circle_up';
import { symbol_arrow_cool_down } from 'components/material-symbols/symbols/arrow_cool_down';
import { symbol_arrow_downward } from 'components/material-symbols/symbols/arrow_downward';
import { symbol_arrow_downward_alt } from 'components/material-symbols/symbols/arrow_downward_alt';
import { symbol_arrow_drop_down } from 'components/material-symbols/symbols/arrow_drop_down';
import { symbol_arrow_drop_down_circle } from 'components/material-symbols/symbols/arrow_drop_down_circle';
import { symbol_arrow_drop_up } from 'components/material-symbols/symbols/arrow_drop_up';
import { symbol_arrow_forward } from 'components/material-symbols/symbols/arrow_forward';
import { symbol_arrow_forward_ios } from 'components/material-symbols/symbols/arrow_forward_ios';
import { symbol_arrow_insert } from 'components/material-symbols/symbols/arrow_insert';
import { symbol_arrow_left } from 'components/material-symbols/symbols/arrow_left';
import { symbol_arrow_left_alt } from 'components/material-symbols/symbols/arrow_left_alt';
import { symbol_arrow_or_edge } from 'components/material-symbols/symbols/arrow_or_edge';
import { symbol_arrow_outward } from 'components/material-symbols/symbols/arrow_outward';
import { symbol_arrow_range } from 'components/material-symbols/symbols/arrow_range';
import { symbol_arrow_right } from 'components/material-symbols/symbols/arrow_right';
import { symbol_arrow_right_alt } from 'components/material-symbols/symbols/arrow_right_alt';
import { symbol_arrow_selector_tool } from 'components/material-symbols/symbols/arrow_selector_tool';
import { symbol_arrow_split } from 'components/material-symbols/symbols/arrow_split';
import { symbol_arrow_top_left } from 'components/material-symbols/symbols/arrow_top_left';
import { symbol_arrow_top_right } from 'components/material-symbols/symbols/arrow_top_right';
import { symbol_arrow_upward } from 'components/material-symbols/symbols/arrow_upward';
import { symbol_arrow_upward_alt } from 'components/material-symbols/symbols/arrow_upward_alt';
import { symbol_arrow_warm_up } from 'components/material-symbols/symbols/arrow_warm_up';
import { symbol_arrows_more_down } from 'components/material-symbols/symbols/arrows_more_down';
import { symbol_arrows_more_up } from 'components/material-symbols/symbols/arrows_more_up';
import { symbol_arrows_outward } from 'components/material-symbols/symbols/arrows_outward';
import { symbol_art_track } from 'components/material-symbols/symbols/art_track';
import { symbol_article } from 'components/material-symbols/symbols/article';
import { symbol_article_shortcut } from 'components/material-symbols/symbols/article_shortcut';
import { symbol_artist } from 'components/material-symbols/symbols/artist';
import { symbol_aspect_ratio } from 'components/material-symbols/symbols/aspect_ratio';
import { symbol_assignment } from 'components/material-symbols/symbols/assignment';
import { symbol_assignment_add } from 'components/material-symbols/symbols/assignment_add';
import { symbol_assignment_ind } from 'components/material-symbols/symbols/assignment_ind';
import { symbol_assignment_late } from 'components/material-symbols/symbols/assignment_late';
import { symbol_assignment_return } from 'components/material-symbols/symbols/assignment_return';
import { symbol_assignment_returned } from 'components/material-symbols/symbols/assignment_returned';
import { symbol_assignment_turned_in } from 'components/material-symbols/symbols/assignment_turned_in';
import { symbol_assist_walker } from 'components/material-symbols/symbols/assist_walker';
import { symbol_assistant_device } from 'components/material-symbols/symbols/assistant_device';
import { symbol_assistant_direction } from 'components/material-symbols/symbols/assistant_direction';
import { symbol_assistant_navigation } from 'components/material-symbols/symbols/assistant_navigation';
import { symbol_assistant_on_hub } from 'components/material-symbols/symbols/assistant_on_hub';
import { symbol_assured_workload } from 'components/material-symbols/symbols/assured_workload';
import { symbol_asterisk } from 'components/material-symbols/symbols/asterisk';
import { symbol_atm } from 'components/material-symbols/symbols/atm';
import { symbol_atr } from 'components/material-symbols/symbols/atr';
import { symbol_attach_email } from 'components/material-symbols/symbols/attach_email';
import { symbol_attach_file } from 'components/material-symbols/symbols/attach_file';
import { symbol_attach_file_add } from 'components/material-symbols/symbols/attach_file_add';
import { symbol_attach_file_off } from 'components/material-symbols/symbols/attach_file_off';
import { symbol_attach_money } from 'components/material-symbols/symbols/attach_money';
import { symbol_attachment } from 'components/material-symbols/symbols/attachment';
import { symbol_attractions } from 'components/material-symbols/symbols/attractions';
import { symbol_attribution } from 'components/material-symbols/symbols/attribution';
import { symbol_audio_description } from 'components/material-symbols/symbols/audio_description';
import { symbol_audio_file } from 'components/material-symbols/symbols/audio_file';
import { symbol_audio_video_receiver } from 'components/material-symbols/symbols/audio_video_receiver';
import { symbol_auto_awesome_mosaic } from 'components/material-symbols/symbols/auto_awesome_mosaic';
import { symbol_auto_awesome_motion } from 'components/material-symbols/symbols/auto_awesome_motion';
import { symbol_auto_delete } from 'components/material-symbols/symbols/auto_delete';
import { symbol_auto_read_pause } from 'components/material-symbols/symbols/auto_read_pause';
import { symbol_auto_read_play } from 'components/material-symbols/symbols/auto_read_play';
import { symbol_auto_stories } from 'components/material-symbols/symbols/auto_stories';
import { symbol_auto_towing } from 'components/material-symbols/symbols/auto_towing';
import { symbol_auto_transmission } from 'components/material-symbols/symbols/auto_transmission';
import { symbol_autofps_select } from 'components/material-symbols/symbols/autofps_select';
import { symbol_autopause } from 'components/material-symbols/symbols/autopause';
import { symbol_autoplay } from 'components/material-symbols/symbols/autoplay';
import { symbol_autorenew } from 'components/material-symbols/symbols/autorenew';
import { symbol_autostop } from 'components/material-symbols/symbols/autostop';
import { symbol_av1 } from 'components/material-symbols/symbols/av1';
import { symbol_av_timer } from 'components/material-symbols/symbols/av_timer';
import { symbol_avc } from 'components/material-symbols/symbols/avc';
import { symbol_avg_pace } from 'components/material-symbols/symbols/avg_pace';
import { symbol_avg_time } from 'components/material-symbols/symbols/avg_time';
import { symbol_award_star } from 'components/material-symbols/symbols/award_star';
import { symbol_azm } from 'components/material-symbols/symbols/azm';
import { symbol_baby_changing_station } from 'components/material-symbols/symbols/baby_changing_station';
import { symbol_back_hand } from 'components/material-symbols/symbols/back_hand';
import { symbol_back_to_tab } from 'components/material-symbols/symbols/back_to_tab';
import { symbol_background_dot_large } from 'components/material-symbols/symbols/background_dot_large';
import { symbol_background_dot_small } from 'components/material-symbols/symbols/background_dot_small';
import { symbol_background_grid_small } from 'components/material-symbols/symbols/background_grid_small';
import { symbol_background_replace } from 'components/material-symbols/symbols/background_replace';
import { symbol_backlight_high } from 'components/material-symbols/symbols/backlight_high';
import { symbol_backlight_high_off } from 'components/material-symbols/symbols/backlight_high_off';
import { symbol_backlight_low } from 'components/material-symbols/symbols/backlight_low';
import { symbol_backpack } from 'components/material-symbols/symbols/backpack';
import { symbol_backspace } from 'components/material-symbols/symbols/backspace';
import { symbol_backup } from 'components/material-symbols/symbols/backup';
import { symbol_backup_table } from 'components/material-symbols/symbols/backup_table';
import { symbol_badge } from 'components/material-symbols/symbols/badge';
import { symbol_badge_critical_battery } from 'components/material-symbols/symbols/badge_critical_battery';
import { symbol_bakery_dining } from 'components/material-symbols/symbols/bakery_dining';
import { symbol_balance } from 'components/material-symbols/symbols/balance';
import { symbol_balcony } from 'components/material-symbols/symbols/balcony';
import { symbol_ballot } from 'components/material-symbols/symbols/ballot';
import { symbol_bar_chart } from 'components/material-symbols/symbols/bar_chart';
import { symbol_bar_chart_4_bars } from 'components/material-symbols/symbols/bar_chart_4_bars';
import { symbol_barcode } from 'components/material-symbols/symbols/barcode';
import { symbol_barcode_reader } from 'components/material-symbols/symbols/barcode_reader';
import { symbol_barcode_scanner } from 'components/material-symbols/symbols/barcode_scanner';
import { symbol_barefoot } from 'components/material-symbols/symbols/barefoot';
import { symbol_batch_prediction } from 'components/material-symbols/symbols/batch_prediction';
import { symbol_bath_outdoor } from 'components/material-symbols/symbols/bath_outdoor';
import { symbol_bath_private } from 'components/material-symbols/symbols/bath_private';
import { symbol_bath_public_large } from 'components/material-symbols/symbols/bath_public_large';
import { symbol_bathroom } from 'components/material-symbols/symbols/bathroom';
import { symbol_bathtub } from 'components/material-symbols/symbols/bathtub';
import { symbol_battery_0_bar } from 'components/material-symbols/symbols/battery_0_bar';
import { symbol_battery_1_bar } from 'components/material-symbols/symbols/battery_1_bar';
import { symbol_battery_2_bar } from 'components/material-symbols/symbols/battery_2_bar';
import { symbol_battery_3_bar } from 'components/material-symbols/symbols/battery_3_bar';
import { symbol_battery_4_bar } from 'components/material-symbols/symbols/battery_4_bar';
import { symbol_battery_5_bar } from 'components/material-symbols/symbols/battery_5_bar';
import { symbol_battery_6_bar } from 'components/material-symbols/symbols/battery_6_bar';
import { symbol_battery_alert } from 'components/material-symbols/symbols/battery_alert';
import { symbol_battery_change } from 'components/material-symbols/symbols/battery_change';
import { symbol_battery_charging_20 } from 'components/material-symbols/symbols/battery_charging_20';
import { symbol_battery_charging_30 } from 'components/material-symbols/symbols/battery_charging_30';
import { symbol_battery_charging_50 } from 'components/material-symbols/symbols/battery_charging_50';
import { symbol_battery_charging_60 } from 'components/material-symbols/symbols/battery_charging_60';
import { symbol_battery_charging_80 } from 'components/material-symbols/symbols/battery_charging_80';
import { symbol_battery_charging_90 } from 'components/material-symbols/symbols/battery_charging_90';
import { symbol_battery_charging_full } from 'components/material-symbols/symbols/battery_charging_full';
import { symbol_battery_error } from 'components/material-symbols/symbols/battery_error';
import { symbol_battery_full } from 'components/material-symbols/symbols/battery_full';
import { symbol_battery_full_alt } from 'components/material-symbols/symbols/battery_full_alt';
import { symbol_battery_horiz_000 } from 'components/material-symbols/symbols/battery_horiz_000';
import { symbol_battery_horiz_050 } from 'components/material-symbols/symbols/battery_horiz_050';
import { symbol_battery_horiz_075 } from 'components/material-symbols/symbols/battery_horiz_075';
import { symbol_battery_low } from 'components/material-symbols/symbols/battery_low';
import { symbol_battery_plus } from 'components/material-symbols/symbols/battery_plus';
import { symbol_battery_profile } from 'components/material-symbols/symbols/battery_profile';
import { symbol_battery_saver } from 'components/material-symbols/symbols/battery_saver';
import { symbol_battery_share } from 'components/material-symbols/symbols/battery_share';
import { symbol_battery_status_good } from 'components/material-symbols/symbols/battery_status_good';
import { symbol_battery_unknown } from 'components/material-symbols/symbols/battery_unknown';
import { symbol_battery_very_low } from 'components/material-symbols/symbols/battery_very_low';
import { symbol_beach_access } from 'components/material-symbols/symbols/beach_access';
import { symbol_bed } from 'components/material-symbols/symbols/bed';
import { symbol_bedroom_baby } from 'components/material-symbols/symbols/bedroom_baby';
import { symbol_bedroom_child } from 'components/material-symbols/symbols/bedroom_child';
import { symbol_bedroom_parent } from 'components/material-symbols/symbols/bedroom_parent';
import { symbol_bedtime } from 'components/material-symbols/symbols/bedtime';
import { symbol_bedtime_off } from 'components/material-symbols/symbols/bedtime_off';
import { symbol_beenhere } from 'components/material-symbols/symbols/beenhere';
import { symbol_bento } from 'components/material-symbols/symbols/bento';
import { symbol_bia } from 'components/material-symbols/symbols/bia';
import { symbol_bid_landscape } from 'components/material-symbols/symbols/bid_landscape';
import { symbol_bid_landscape_disabled } from 'components/material-symbols/symbols/bid_landscape_disabled';
import { symbol_bigtop_updates } from 'components/material-symbols/symbols/bigtop_updates';
import { symbol_bike_scooter } from 'components/material-symbols/symbols/bike_scooter';
import { symbol_biotech } from 'components/material-symbols/symbols/biotech';
import { symbol_blanket } from 'components/material-symbols/symbols/blanket';
import { symbol_blender } from 'components/material-symbols/symbols/blender';
import { symbol_blind } from 'components/material-symbols/symbols/blind';
import { symbol_blinds } from 'components/material-symbols/symbols/blinds';
import { symbol_blinds_closed } from 'components/material-symbols/symbols/blinds_closed';
import { symbol_block } from 'components/material-symbols/symbols/block';
import { symbol_blood_pressure } from 'components/material-symbols/symbols/blood_pressure';
import { symbol_bloodtype } from 'components/material-symbols/symbols/bloodtype';
import { symbol_bluetooth } from 'components/material-symbols/symbols/bluetooth';
import { symbol_bluetooth_connected } from 'components/material-symbols/symbols/bluetooth_connected';
import { symbol_bluetooth_disabled } from 'components/material-symbols/symbols/bluetooth_disabled';
import { symbol_bluetooth_drive } from 'components/material-symbols/symbols/bluetooth_drive';
import { symbol_bluetooth_searching } from 'components/material-symbols/symbols/bluetooth_searching';
import { symbol_blur_circular } from 'components/material-symbols/symbols/blur_circular';
import { symbol_blur_linear } from 'components/material-symbols/symbols/blur_linear';
import { symbol_blur_medium } from 'components/material-symbols/symbols/blur_medium';
import { symbol_blur_off } from 'components/material-symbols/symbols/blur_off';
import { symbol_blur_on } from 'components/material-symbols/symbols/blur_on';
import { symbol_blur_short } from 'components/material-symbols/symbols/blur_short';
import { symbol_body_fat } from 'components/material-symbols/symbols/body_fat';
import { symbol_body_system } from 'components/material-symbols/symbols/body_system';
import { symbol_bolt } from 'components/material-symbols/symbols/bolt';
import { symbol_bomb } from 'components/material-symbols/symbols/bomb';
import { symbol_book } from 'components/material-symbols/symbols/book';
import { symbol_book_2 } from 'components/material-symbols/symbols/book_2';
import { symbol_book_3 } from 'components/material-symbols/symbols/book_3';
import { symbol_book_4 } from 'components/material-symbols/symbols/book_4';
import { symbol_book_5 } from 'components/material-symbols/symbols/book_5';
import { symbol_book_online } from 'components/material-symbols/symbols/book_online';
import { symbol_bookmark } from 'components/material-symbols/symbols/bookmark';
import { symbol_bookmark_add } from 'components/material-symbols/symbols/bookmark_add';
import { symbol_bookmark_added } from 'components/material-symbols/symbols/bookmark_added';
import { symbol_bookmark_manager } from 'components/material-symbols/symbols/bookmark_manager';
import { symbol_bookmark_remove } from 'components/material-symbols/symbols/bookmark_remove';
import { symbol_bookmarks } from 'components/material-symbols/symbols/bookmarks';
import { symbol_border_all } from 'components/material-symbols/symbols/border_all';
import { symbol_border_bottom } from 'components/material-symbols/symbols/border_bottom';
import { symbol_border_clear } from 'components/material-symbols/symbols/border_clear';
import { symbol_border_color } from 'components/material-symbols/symbols/border_color';
import { symbol_border_horizontal } from 'components/material-symbols/symbols/border_horizontal';
import { symbol_border_inner } from 'components/material-symbols/symbols/border_inner';
import { symbol_border_left } from 'components/material-symbols/symbols/border_left';
import { symbol_border_outer } from 'components/material-symbols/symbols/border_outer';
import { symbol_border_right } from 'components/material-symbols/symbols/border_right';
import { symbol_border_style } from 'components/material-symbols/symbols/border_style';
import { symbol_border_top } from 'components/material-symbols/symbols/border_top';
import { symbol_border_vertical } from 'components/material-symbols/symbols/border_vertical';
import { symbol_bottom_app_bar } from 'components/material-symbols/symbols/bottom_app_bar';
import { symbol_bottom_drawer } from 'components/material-symbols/symbols/bottom_drawer';
import { symbol_bottom_navigation } from 'components/material-symbols/symbols/bottom_navigation';
import { symbol_bottom_panel_close } from 'components/material-symbols/symbols/bottom_panel_close';
import { symbol_bottom_panel_open } from 'components/material-symbols/symbols/bottom_panel_open';
import { symbol_bottom_right_click } from 'components/material-symbols/symbols/bottom_right_click';
import { symbol_bottom_sheets } from 'components/material-symbols/symbols/bottom_sheets';
import { symbol_box } from 'components/material-symbols/symbols/box';
import { symbol_box_add } from 'components/material-symbols/symbols/box_add';
import { symbol_box_edit } from 'components/material-symbols/symbols/box_edit';
import { symbol_boy } from 'components/material-symbols/symbols/boy';
import { symbol_brand_awareness } from 'components/material-symbols/symbols/brand_awareness';
import { symbol_brand_family } from 'components/material-symbols/symbols/brand_family';
import { symbol_branding_watermark } from 'components/material-symbols/symbols/branding_watermark';
import { symbol_breakfast_dining } from 'components/material-symbols/symbols/breakfast_dining';
import { symbol_breaking_news } from 'components/material-symbols/symbols/breaking_news';
import { symbol_breaking_news_alt_1 } from 'components/material-symbols/symbols/breaking_news_alt_1';
import { symbol_breastfeeding } from 'components/material-symbols/symbols/breastfeeding';
import { symbol_brightness_1 } from 'components/material-symbols/symbols/brightness_1';
import { symbol_brightness_2 } from 'components/material-symbols/symbols/brightness_2';
import { symbol_brightness_3 } from 'components/material-symbols/symbols/brightness_3';
import { symbol_brightness_4 } from 'components/material-symbols/symbols/brightness_4';
import { symbol_brightness_5 } from 'components/material-symbols/symbols/brightness_5';
import { symbol_brightness_6 } from 'components/material-symbols/symbols/brightness_6';
import { symbol_brightness_7 } from 'components/material-symbols/symbols/brightness_7';
import { symbol_brightness_alert } from 'components/material-symbols/symbols/brightness_alert';
import { symbol_brightness_auto } from 'components/material-symbols/symbols/brightness_auto';
import { symbol_brightness_empty } from 'components/material-symbols/symbols/brightness_empty';
import { symbol_brightness_high } from 'components/material-symbols/symbols/brightness_high';
import { symbol_brightness_low } from 'components/material-symbols/symbols/brightness_low';
import { symbol_brightness_medium } from 'components/material-symbols/symbols/brightness_medium';
import { symbol_bring_your_own_ip } from 'components/material-symbols/symbols/bring_your_own_ip';
import { symbol_broadcast_on_home } from 'components/material-symbols/symbols/broadcast_on_home';
import { symbol_broadcast_on_personal } from 'components/material-symbols/symbols/broadcast_on_personal';
import { symbol_broken_image } from 'components/material-symbols/symbols/broken_image';
import { symbol_browse } from 'components/material-symbols/symbols/browse';
import { symbol_browse_activity } from 'components/material-symbols/symbols/browse_activity';
import { symbol_browse_gallery } from 'components/material-symbols/symbols/browse_gallery';
import { symbol_browser_updated } from 'components/material-symbols/symbols/browser_updated';
import { symbol_brunch_dining } from 'components/material-symbols/symbols/brunch_dining';
import { symbol_brush } from 'components/material-symbols/symbols/brush';
import { symbol_bubble } from 'components/material-symbols/symbols/bubble';
import { symbol_bubble_chart } from 'components/material-symbols/symbols/bubble_chart';
import { symbol_bubbles } from 'components/material-symbols/symbols/bubbles';
import { symbol_bug_report } from 'components/material-symbols/symbols/bug_report';
import { symbol_build } from 'components/material-symbols/symbols/build';
import { symbol_build_circle } from 'components/material-symbols/symbols/build_circle';
import { symbol_bungalow } from 'components/material-symbols/symbols/bungalow';
import { symbol_burst_mode } from 'components/material-symbols/symbols/burst_mode';
import { symbol_bus_alert } from 'components/material-symbols/symbols/bus_alert';
import { symbol_business_center } from 'components/material-symbols/symbols/business_center';
import { symbol_business_chip } from 'components/material-symbols/symbols/business_chip';
import { symbol_business_messages } from 'components/material-symbols/symbols/business_messages';
import { symbol_buttons_alt } from 'components/material-symbols/symbols/buttons_alt';
import { symbol_cabin } from 'components/material-symbols/symbols/cabin';
import { symbol_cable } from 'components/material-symbols/symbols/cable';
import { symbol_cached } from 'components/material-symbols/symbols/cached';
import { symbol_cadence } from 'components/material-symbols/symbols/cadence';
import { symbol_cake } from 'components/material-symbols/symbols/cake';
import { symbol_cake_add } from 'components/material-symbols/symbols/cake_add';
import { symbol_calculate } from 'components/material-symbols/symbols/calculate';
import { symbol_calendar_add_on } from 'components/material-symbols/symbols/calendar_add_on';
import { symbol_calendar_apps_script } from 'components/material-symbols/symbols/calendar_apps_script';
import { symbol_calendar_clock } from 'components/material-symbols/symbols/calendar_clock';
import { symbol_calendar_month } from 'components/material-symbols/symbols/calendar_month';
import { symbol_calendar_today } from 'components/material-symbols/symbols/calendar_today';
import { symbol_calendar_view_day } from 'components/material-symbols/symbols/calendar_view_day';
import { symbol_calendar_view_month } from 'components/material-symbols/symbols/calendar_view_month';
import { symbol_calendar_view_week } from 'components/material-symbols/symbols/calendar_view_week';
import { symbol_call } from 'components/material-symbols/symbols/call';
import { symbol_call_end } from 'components/material-symbols/symbols/call_end';
import { symbol_call_log } from 'components/material-symbols/symbols/call_log';
import { symbol_call_made } from 'components/material-symbols/symbols/call_made';
import { symbol_call_merge } from 'components/material-symbols/symbols/call_merge';
import { symbol_call_missed } from 'components/material-symbols/symbols/call_missed';
import { symbol_call_missed_outgoing } from 'components/material-symbols/symbols/call_missed_outgoing';
import { symbol_call_quality } from 'components/material-symbols/symbols/call_quality';
import { symbol_call_received } from 'components/material-symbols/symbols/call_received';
import { symbol_call_split } from 'components/material-symbols/symbols/call_split';
import { symbol_call_to_action } from 'components/material-symbols/symbols/call_to_action';
import { symbol_camera } from 'components/material-symbols/symbols/camera';
import { symbol_camera_front } from 'components/material-symbols/symbols/camera_front';
import { symbol_camera_indoor } from 'components/material-symbols/symbols/camera_indoor';
import { symbol_camera_outdoor } from 'components/material-symbols/symbols/camera_outdoor';
import { symbol_camera_rear } from 'components/material-symbols/symbols/camera_rear';
import { symbol_camera_roll } from 'components/material-symbols/symbols/camera_roll';
import { symbol_camera_video } from 'components/material-symbols/symbols/camera_video';
import { symbol_cameraswitch } from 'components/material-symbols/symbols/cameraswitch';
import { symbol_campaign } from 'components/material-symbols/symbols/campaign';
import { symbol_camping } from 'components/material-symbols/symbols/camping';
import { symbol_cancel } from 'components/material-symbols/symbols/cancel';
import { symbol_cancel_presentation } from 'components/material-symbols/symbols/cancel_presentation';
import { symbol_cancel_schedule_send } from 'components/material-symbols/symbols/cancel_schedule_send';
import { symbol_candle } from 'components/material-symbols/symbols/candle';
import { symbol_candlestick_chart } from 'components/material-symbols/symbols/candlestick_chart';
import { symbol_captive_portal } from 'components/material-symbols/symbols/captive_portal';
import { symbol_capture } from 'components/material-symbols/symbols/capture';
import { symbol_car_crash } from 'components/material-symbols/symbols/car_crash';
import { symbol_car_rental } from 'components/material-symbols/symbols/car_rental';
import { symbol_car_repair } from 'components/material-symbols/symbols/car_repair';
import { symbol_car_tag } from 'components/material-symbols/symbols/car_tag';
import { symbol_card_membership } from 'components/material-symbols/symbols/card_membership';
import { symbol_card_travel } from 'components/material-symbols/symbols/card_travel';
import { symbol_cardio_load } from 'components/material-symbols/symbols/cardio_load';
import { symbol_cardiology } from 'components/material-symbols/symbols/cardiology';
import { symbol_cards } from 'components/material-symbols/symbols/cards';
import { symbol_carpenter } from 'components/material-symbols/symbols/carpenter';
import { symbol_carry_on_bag } from 'components/material-symbols/symbols/carry_on_bag';
import { symbol_carry_on_bag_checked } from 'components/material-symbols/symbols/carry_on_bag_checked';
import { symbol_carry_on_bag_inactive } from 'components/material-symbols/symbols/carry_on_bag_inactive';
import { symbol_carry_on_bag_question } from 'components/material-symbols/symbols/carry_on_bag_question';
import { symbol_cases } from 'components/material-symbols/symbols/cases';
import { symbol_casino } from 'components/material-symbols/symbols/casino';
import { symbol_cast } from 'components/material-symbols/symbols/cast';
import { symbol_cast_connected } from 'components/material-symbols/symbols/cast_connected';
import { symbol_cast_for_education } from 'components/material-symbols/symbols/cast_for_education';
import { symbol_cast_pause } from 'components/material-symbols/symbols/cast_pause';
import { symbol_cast_warning } from 'components/material-symbols/symbols/cast_warning';
import { symbol_castle } from 'components/material-symbols/symbols/castle';
import { symbol_category } from 'components/material-symbols/symbols/category';
import { symbol_celebration } from 'components/material-symbols/symbols/celebration';
import { symbol_cell_merge } from 'components/material-symbols/symbols/cell_merge';
import { symbol_cell_tower } from 'components/material-symbols/symbols/cell_tower';
import { symbol_cell_wifi } from 'components/material-symbols/symbols/cell_wifi';
import { symbol_center_focus_strong } from 'components/material-symbols/symbols/center_focus_strong';
import { symbol_center_focus_weak } from 'components/material-symbols/symbols/center_focus_weak';
import { symbol_chair } from 'components/material-symbols/symbols/chair';
import { symbol_chair_alt } from 'components/material-symbols/symbols/chair_alt';
import { symbol_chalet } from 'components/material-symbols/symbols/chalet';
import { symbol_change_circle } from 'components/material-symbols/symbols/change_circle';
import { symbol_change_history } from 'components/material-symbols/symbols/change_history';
import { symbol_charger } from 'components/material-symbols/symbols/charger';
import { symbol_charging_station } from 'components/material-symbols/symbols/charging_station';
import { symbol_chart_data } from 'components/material-symbols/symbols/chart_data';
import { symbol_chat } from 'components/material-symbols/symbols/chat';
import { symbol_chat_add_on } from 'components/material-symbols/symbols/chat_add_on';
import { symbol_chat_apps_script } from 'components/material-symbols/symbols/chat_apps_script';
import { symbol_chat_bubble } from 'components/material-symbols/symbols/chat_bubble';
import { symbol_chat_error } from 'components/material-symbols/symbols/chat_error';
import { symbol_chat_info } from 'components/material-symbols/symbols/chat_info';
import { symbol_chat_paste_go } from 'components/material-symbols/symbols/chat_paste_go';
import { symbol_check } from 'components/material-symbols/symbols/check';
import { symbol_check_box } from 'components/material-symbols/symbols/check_box';
import { symbol_check_box_outline_blank } from 'components/material-symbols/symbols/check_box_outline_blank';
import { symbol_check_circle } from 'components/material-symbols/symbols/check_circle';
import { symbol_check_in_out } from 'components/material-symbols/symbols/check_in_out';
import { symbol_check_indeterminate_small } from 'components/material-symbols/symbols/check_indeterminate_small';
import { symbol_check_small } from 'components/material-symbols/symbols/check_small';
import { symbol_checkbook } from 'components/material-symbols/symbols/checkbook';
import { symbol_checked_bag } from 'components/material-symbols/symbols/checked_bag';
import { symbol_checked_bag_question } from 'components/material-symbols/symbols/checked_bag_question';
import { symbol_checklist } from 'components/material-symbols/symbols/checklist';
import { symbol_checklist_rtl } from 'components/material-symbols/symbols/checklist_rtl';
import { symbol_checkroom } from 'components/material-symbols/symbols/checkroom';
import { symbol_cheer } from 'components/material-symbols/symbols/cheer';
import { symbol_chess } from 'components/material-symbols/symbols/chess';
import { symbol_chevron_left } from 'components/material-symbols/symbols/chevron_left';
import { symbol_chevron_right } from 'components/material-symbols/symbols/chevron_right';
import { symbol_child_care } from 'components/material-symbols/symbols/child_care';
import { symbol_child_friendly } from 'components/material-symbols/symbols/child_friendly';
import { symbol_chip_extraction } from 'components/material-symbols/symbols/chip_extraction';
import { symbol_chips } from 'components/material-symbols/symbols/chips';
import { symbol_chrome_reader_mode } from 'components/material-symbols/symbols/chrome_reader_mode';
import { symbol_chromecast_2 } from 'components/material-symbols/symbols/chromecast_2';
import { symbol_chromecast_device } from 'components/material-symbols/symbols/chromecast_device';
import { symbol_chronic } from 'components/material-symbols/symbols/chronic';
import { symbol_church } from 'components/material-symbols/symbols/church';
import { symbol_cinematic_blur } from 'components/material-symbols/symbols/cinematic_blur';
import { symbol_circle } from 'components/material-symbols/symbols/circle';
import { symbol_circle_notifications } from 'components/material-symbols/symbols/circle_notifications';
import { symbol_circles } from 'components/material-symbols/symbols/circles';
import { symbol_circles_ext } from 'components/material-symbols/symbols/circles_ext';
import { symbol_clarify } from 'components/material-symbols/symbols/clarify';
import { symbol_clean_hands } from 'components/material-symbols/symbols/clean_hands';
import { symbol_cleaning } from 'components/material-symbols/symbols/cleaning';
import { symbol_cleaning_bucket } from 'components/material-symbols/symbols/cleaning_bucket';
import { symbol_cleaning_services } from 'components/material-symbols/symbols/cleaning_services';
import { symbol_clear_all } from 'components/material-symbols/symbols/clear_all';
import { symbol_clear_day } from 'components/material-symbols/symbols/clear_day';
import { symbol_clear_night } from 'components/material-symbols/symbols/clear_night';
import { symbol_climate_mini_split } from 'components/material-symbols/symbols/climate_mini_split';
import { symbol_clinical_notes } from 'components/material-symbols/symbols/clinical_notes';
import { symbol_clock_loader_10 } from 'components/material-symbols/symbols/clock_loader_10';
import { symbol_clock_loader_20 } from 'components/material-symbols/symbols/clock_loader_20';
import { symbol_clock_loader_40 } from 'components/material-symbols/symbols/clock_loader_40';
import { symbol_clock_loader_60 } from 'components/material-symbols/symbols/clock_loader_60';
import { symbol_clock_loader_80 } from 'components/material-symbols/symbols/clock_loader_80';
import { symbol_clock_loader_90 } from 'components/material-symbols/symbols/clock_loader_90';
import { symbol_close } from 'components/material-symbols/symbols/close';
import { symbol_close_fullscreen } from 'components/material-symbols/symbols/close_fullscreen';
import { symbol_close_small } from 'components/material-symbols/symbols/close_small';
import { symbol_closed_caption } from 'components/material-symbols/symbols/closed_caption';
import { symbol_closed_caption_add } from 'components/material-symbols/symbols/closed_caption_add';
import { symbol_closed_caption_disabled } from 'components/material-symbols/symbols/closed_caption_disabled';
import { symbol_cloud } from 'components/material-symbols/symbols/cloud';
import { symbol_cloud_circle } from 'components/material-symbols/symbols/cloud_circle';
import { symbol_cloud_done } from 'components/material-symbols/symbols/cloud_done';
import { symbol_cloud_download } from 'components/material-symbols/symbols/cloud_download';
import { symbol_cloud_off } from 'components/material-symbols/symbols/cloud_off';
import { symbol_cloud_sync } from 'components/material-symbols/symbols/cloud_sync';
import { symbol_cloud_upload } from 'components/material-symbols/symbols/cloud_upload';
import { symbol_cloudy_snowing } from 'components/material-symbols/symbols/cloudy_snowing';
import { symbol_co2 } from 'components/material-symbols/symbols/co2';
import { symbol_co_present } from 'components/material-symbols/symbols/co_present';
import { symbol_code } from 'components/material-symbols/symbols/code';
import { symbol_code_blocks } from 'components/material-symbols/symbols/code_blocks';
import { symbol_code_off } from 'components/material-symbols/symbols/code_off';
import { symbol_coffee } from 'components/material-symbols/symbols/coffee';
import { symbol_coffee_maker } from 'components/material-symbols/symbols/coffee_maker';
import { symbol_cognition } from 'components/material-symbols/symbols/cognition';
import { symbol_collapse_all } from 'components/material-symbols/symbols/collapse_all';
import { symbol_collapse_content } from 'components/material-symbols/symbols/collapse_content';
import { symbol_collections_bookmark } from 'components/material-symbols/symbols/collections_bookmark';
import { symbol_colorize } from 'components/material-symbols/symbols/colorize';
import { symbol_colors } from 'components/material-symbols/symbols/colors';
import { symbol_comedy_mask } from 'components/material-symbols/symbols/comedy_mask';
import { symbol_comic_bubble } from 'components/material-symbols/symbols/comic_bubble';
import { symbol_comment } from 'components/material-symbols/symbols/comment';
import { symbol_comment_bank } from 'components/material-symbols/symbols/comment_bank';
import { symbol_comments_disabled } from 'components/material-symbols/symbols/comments_disabled';
import { symbol_commit } from 'components/material-symbols/symbols/commit';
import { symbol_communication } from 'components/material-symbols/symbols/communication';
import { symbol_communities } from 'components/material-symbols/symbols/communities';
import { symbol_commute } from 'components/material-symbols/symbols/commute';
import { symbol_compare } from 'components/material-symbols/symbols/compare';
import { symbol_compare_arrows } from 'components/material-symbols/symbols/compare_arrows';
import { symbol_compass_calibration } from 'components/material-symbols/symbols/compass_calibration';
import { symbol_component_exchange } from 'components/material-symbols/symbols/component_exchange';
import { symbol_compost } from 'components/material-symbols/symbols/compost';
import { symbol_compress } from 'components/material-symbols/symbols/compress';
import { symbol_computer } from 'components/material-symbols/symbols/computer';
import { symbol_concierge } from 'components/material-symbols/symbols/concierge';
import { symbol_conditions } from 'components/material-symbols/symbols/conditions';
import { symbol_confirmation_number } from 'components/material-symbols/symbols/confirmation_number';
import { symbol_congenital } from 'components/material-symbols/symbols/congenital';
import { symbol_connect_without_contact } from 'components/material-symbols/symbols/connect_without_contact';
import { symbol_connected_tv } from 'components/material-symbols/symbols/connected_tv';
import { symbol_connecting_airports } from 'components/material-symbols/symbols/connecting_airports';
import { symbol_construction } from 'components/material-symbols/symbols/construction';
import { symbol_contact_emergency } from 'components/material-symbols/symbols/contact_emergency';
import { symbol_contact_mail } from 'components/material-symbols/symbols/contact_mail';
import { symbol_contact_page } from 'components/material-symbols/symbols/contact_page';
import { symbol_contact_phone } from 'components/material-symbols/symbols/contact_phone';
import { symbol_contact_support } from 'components/material-symbols/symbols/contact_support';
import { symbol_contactless } from 'components/material-symbols/symbols/contactless';
import { symbol_contactless_off } from 'components/material-symbols/symbols/contactless_off';
import { symbol_contacts } from 'components/material-symbols/symbols/contacts';
import { symbol_contacts_product } from 'components/material-symbols/symbols/contacts_product';
import { symbol_content_copy } from 'components/material-symbols/symbols/content_copy';
import { symbol_content_cut } from 'components/material-symbols/symbols/content_cut';
import { symbol_content_paste } from 'components/material-symbols/symbols/content_paste';
import { symbol_content_paste_go } from 'components/material-symbols/symbols/content_paste_go';
import { symbol_content_paste_off } from 'components/material-symbols/symbols/content_paste_off';
import { symbol_content_paste_search } from 'components/material-symbols/symbols/content_paste_search';
import { symbol_contract } from 'components/material-symbols/symbols/contract';
import { symbol_contract_delete } from 'components/material-symbols/symbols/contract_delete';
import { symbol_contract_edit } from 'components/material-symbols/symbols/contract_edit';
import { symbol_contrast } from 'components/material-symbols/symbols/contrast';
import { symbol_contrast_circle } from 'components/material-symbols/symbols/contrast_circle';
import { symbol_contrast_rtl_off } from 'components/material-symbols/symbols/contrast_rtl_off';
import { symbol_contrast_square } from 'components/material-symbols/symbols/contrast_square';
import { symbol_control_camera } from 'components/material-symbols/symbols/control_camera';
import { symbol_control_point_duplicate } from 'components/material-symbols/symbols/control_point_duplicate';
import { symbol_controller_gen } from 'components/material-symbols/symbols/controller_gen';
import { symbol_conversion_path } from 'components/material-symbols/symbols/conversion_path';
import { symbol_conversion_path_off } from 'components/material-symbols/symbols/conversion_path_off';
import { symbol_conveyor_belt } from 'components/material-symbols/symbols/conveyor_belt';
import { symbol_cookie } from 'components/material-symbols/symbols/cookie';
import { symbol_cookie_off } from 'components/material-symbols/symbols/cookie_off';
import { symbol_cooking } from 'components/material-symbols/symbols/cooking';
import { symbol_cool_to_dry } from 'components/material-symbols/symbols/cool_to_dry';
import { symbol_copy_all } from 'components/material-symbols/symbols/copy_all';
import { symbol_copyright } from 'components/material-symbols/symbols/copyright';
import { symbol_coronavirus } from 'components/material-symbols/symbols/coronavirus';
import { symbol_corporate_fare } from 'components/material-symbols/symbols/corporate_fare';
import { symbol_cottage } from 'components/material-symbols/symbols/cottage';
import { symbol_counter_0 } from 'components/material-symbols/symbols/counter_0';
import { symbol_counter_1 } from 'components/material-symbols/symbols/counter_1';
import { symbol_counter_2 } from 'components/material-symbols/symbols/counter_2';
import { symbol_counter_3 } from 'components/material-symbols/symbols/counter_3';
import { symbol_counter_4 } from 'components/material-symbols/symbols/counter_4';
import { symbol_counter_5 } from 'components/material-symbols/symbols/counter_5';
import { symbol_counter_6 } from 'components/material-symbols/symbols/counter_6';
import { symbol_counter_7 } from 'components/material-symbols/symbols/counter_7';
import { symbol_counter_8 } from 'components/material-symbols/symbols/counter_8';
import { symbol_counter_9 } from 'components/material-symbols/symbols/counter_9';
import { symbol_countertops } from 'components/material-symbols/symbols/countertops';
import { symbol_create_new_folder } from 'components/material-symbols/symbols/create_new_folder';
import { symbol_credit_card } from 'components/material-symbols/symbols/credit_card';
import { symbol_credit_card_gear } from 'components/material-symbols/symbols/credit_card_gear';
import { symbol_credit_card_heart } from 'components/material-symbols/symbols/credit_card_heart';
import { symbol_credit_card_off } from 'components/material-symbols/symbols/credit_card_off';
import { symbol_credit_score } from 'components/material-symbols/symbols/credit_score';
import { symbol_crib } from 'components/material-symbols/symbols/crib';
import { symbol_crisis_alert } from 'components/material-symbols/symbols/crisis_alert';
import { symbol_crop } from 'components/material-symbols/symbols/crop';
import { symbol_crop_16_9 } from 'components/material-symbols/symbols/crop_16_9';
import { symbol_crop_3_2 } from 'components/material-symbols/symbols/crop_3_2';
import { symbol_crop_5_4 } from 'components/material-symbols/symbols/crop_5_4';
import { symbol_crop_7_5 } from 'components/material-symbols/symbols/crop_7_5';
import { symbol_crop_9_16 } from 'components/material-symbols/symbols/crop_9_16';
import { symbol_crop_free } from 'components/material-symbols/symbols/crop_free';
import { symbol_crop_landscape } from 'components/material-symbols/symbols/crop_landscape';
import { symbol_crop_portrait } from 'components/material-symbols/symbols/crop_portrait';
import { symbol_crop_rotate } from 'components/material-symbols/symbols/crop_rotate';
import { symbol_crop_square } from 'components/material-symbols/symbols/crop_square';
import { symbol_crossword } from 'components/material-symbols/symbols/crossword';
import { symbol_crowdsource } from 'components/material-symbols/symbols/crowdsource';
import { symbol_cruelty_free } from 'components/material-symbols/symbols/cruelty_free';
import { symbol_css } from 'components/material-symbols/symbols/css';
import { symbol_csv } from 'components/material-symbols/symbols/csv';
import { symbol_currency_bitcoin } from 'components/material-symbols/symbols/currency_bitcoin';
import { symbol_currency_exchange } from 'components/material-symbols/symbols/currency_exchange';
import { symbol_currency_franc } from 'components/material-symbols/symbols/currency_franc';
import { symbol_currency_lira } from 'components/material-symbols/symbols/currency_lira';
import { symbol_currency_pound } from 'components/material-symbols/symbols/currency_pound';
import { symbol_currency_ruble } from 'components/material-symbols/symbols/currency_ruble';
import { symbol_currency_rupee } from 'components/material-symbols/symbols/currency_rupee';
import { symbol_currency_yen } from 'components/material-symbols/symbols/currency_yen';
import { symbol_currency_yuan } from 'components/material-symbols/symbols/currency_yuan';
import { symbol_curtains } from 'components/material-symbols/symbols/curtains';
import { symbol_curtains_closed } from 'components/material-symbols/symbols/curtains_closed';
import { symbol_custom_typography } from 'components/material-symbols/symbols/custom_typography';
import { symbol_cut } from 'components/material-symbols/symbols/cut';
import { symbol_cycle } from 'components/material-symbols/symbols/cycle';
import { symbol_cyclone } from 'components/material-symbols/symbols/cyclone';
import { symbol_dangerous } from 'components/material-symbols/symbols/dangerous';
import { symbol_dark_mode } from 'components/material-symbols/symbols/dark_mode';
import { symbol_dashboard } from 'components/material-symbols/symbols/dashboard';
import { symbol_dashboard_customize } from 'components/material-symbols/symbols/dashboard_customize';
import { symbol_data_alert } from 'components/material-symbols/symbols/data_alert';
import { symbol_data_array } from 'components/material-symbols/symbols/data_array';
import { symbol_data_check } from 'components/material-symbols/symbols/data_check';
import { symbol_data_exploration } from 'components/material-symbols/symbols/data_exploration';
import { symbol_data_info_alert } from 'components/material-symbols/symbols/data_info_alert';
import { symbol_data_loss_prevention } from 'components/material-symbols/symbols/data_loss_prevention';
import { symbol_data_object } from 'components/material-symbols/symbols/data_object';
import { symbol_data_saver_on } from 'components/material-symbols/symbols/data_saver_on';
import { symbol_data_table } from 'components/material-symbols/symbols/data_table';
import { symbol_data_thresholding } from 'components/material-symbols/symbols/data_thresholding';
import { symbol_data_usage } from 'components/material-symbols/symbols/data_usage';
import { symbol_database } from 'components/material-symbols/symbols/database';
import { symbol_dataset } from 'components/material-symbols/symbols/dataset';
import { symbol_dataset_linked } from 'components/material-symbols/symbols/dataset_linked';
import { symbol_date_range } from 'components/material-symbols/symbols/date_range';
import { symbol_deblur } from 'components/material-symbols/symbols/deblur';
import { symbol_deceased } from 'components/material-symbols/symbols/deceased';
import { symbol_decimal_decrease } from 'components/material-symbols/symbols/decimal_decrease';
import { symbol_decimal_increase } from 'components/material-symbols/symbols/decimal_increase';
import { symbol_deck } from 'components/material-symbols/symbols/deck';
import { symbol_dehaze } from 'components/material-symbols/symbols/dehaze';
import { symbol_delete } from 'components/material-symbols/symbols/delete';
import { symbol_delete_forever } from 'components/material-symbols/symbols/delete_forever';
import { symbol_delete_history } from 'components/material-symbols/symbols/delete_history';
import { symbol_delete_sweep } from 'components/material-symbols/symbols/delete_sweep';
import { symbol_demography } from 'components/material-symbols/symbols/demography';
import { symbol_density_large } from 'components/material-symbols/symbols/density_large';
import { symbol_density_medium } from 'components/material-symbols/symbols/density_medium';
import { symbol_density_small } from 'components/material-symbols/symbols/density_small';
import { symbol_dentistry } from 'components/material-symbols/symbols/dentistry';
import { symbol_departure_board } from 'components/material-symbols/symbols/departure_board';
import { symbol_deployed_code } from 'components/material-symbols/symbols/deployed_code';
import { symbol_deployed_code_account } from 'components/material-symbols/symbols/deployed_code_account';
import { symbol_deployed_code_alert } from 'components/material-symbols/symbols/deployed_code_alert';
import { symbol_deployed_code_history } from 'components/material-symbols/symbols/deployed_code_history';
import { symbol_deployed_code_update } from 'components/material-symbols/symbols/deployed_code_update';
import { symbol_dermatology } from 'components/material-symbols/symbols/dermatology';
import { symbol_description } from 'components/material-symbols/symbols/description';
import { symbol_deselect } from 'components/material-symbols/symbols/deselect';
import { symbol_design_services } from 'components/material-symbols/symbols/design_services';
import { symbol_desk } from 'components/material-symbols/symbols/desk';
import { symbol_deskphone } from 'components/material-symbols/symbols/deskphone';
import { symbol_desktop_access_disabled } from 'components/material-symbols/symbols/desktop_access_disabled';
import { symbol_desktop_mac } from 'components/material-symbols/symbols/desktop_mac';
import { symbol_desktop_windows } from 'components/material-symbols/symbols/desktop_windows';
import { symbol_destruction } from 'components/material-symbols/symbols/destruction';
import { symbol_details } from 'components/material-symbols/symbols/details';
import { symbol_detection_and_zone } from 'components/material-symbols/symbols/detection_and_zone';
import { symbol_detector } from 'components/material-symbols/symbols/detector';
import { symbol_detector_alarm } from 'components/material-symbols/symbols/detector_alarm';
import { symbol_detector_battery } from 'components/material-symbols/symbols/detector_battery';
import { symbol_detector_co } from 'components/material-symbols/symbols/detector_co';
import { symbol_detector_offline } from 'components/material-symbols/symbols/detector_offline';
import { symbol_detector_smoke } from 'components/material-symbols/symbols/detector_smoke';
import { symbol_detector_status } from 'components/material-symbols/symbols/detector_status';
import { symbol_developer_board } from 'components/material-symbols/symbols/developer_board';
import { symbol_developer_board_off } from 'components/material-symbols/symbols/developer_board_off';
import { symbol_developer_guide } from 'components/material-symbols/symbols/developer_guide';
import { symbol_developer_mode } from 'components/material-symbols/symbols/developer_mode';
import { symbol_developer_mode_tv } from 'components/material-symbols/symbols/developer_mode_tv';
import { symbol_device_hub } from 'components/material-symbols/symbols/device_hub';
import { symbol_device_reset } from 'components/material-symbols/symbols/device_reset';
import { symbol_device_thermostat } from 'components/material-symbols/symbols/device_thermostat';
import { symbol_device_unknown } from 'components/material-symbols/symbols/device_unknown';
import { symbol_devices } from 'components/material-symbols/symbols/devices';
import { symbol_devices_fold } from 'components/material-symbols/symbols/devices_fold';
import { symbol_devices_off } from 'components/material-symbols/symbols/devices_off';
import { symbol_devices_other } from 'components/material-symbols/symbols/devices_other';
import { symbol_devices_wearables } from 'components/material-symbols/symbols/devices_wearables';
import { symbol_dew_point } from 'components/material-symbols/symbols/dew_point';
import { symbol_diagnosis } from 'components/material-symbols/symbols/diagnosis';
import { symbol_dialer_sip } from 'components/material-symbols/symbols/dialer_sip';
import { symbol_dialogs } from 'components/material-symbols/symbols/dialogs';
import { symbol_dialpad } from 'components/material-symbols/symbols/dialpad';
import { symbol_diamond } from 'components/material-symbols/symbols/diamond';
import { symbol_dictionary } from 'components/material-symbols/symbols/dictionary';
import { symbol_difference } from 'components/material-symbols/symbols/difference';
import { symbol_digital_out_of_home } from 'components/material-symbols/symbols/digital_out_of_home';
import { symbol_digital_wellbeing } from 'components/material-symbols/symbols/digital_wellbeing';
import { symbol_dining } from 'components/material-symbols/symbols/dining';
import { symbol_dinner_dining } from 'components/material-symbols/symbols/dinner_dining';
import { symbol_directions } from 'components/material-symbols/symbols/directions';
import { symbol_directions_alt } from 'components/material-symbols/symbols/directions_alt';
import { symbol_directions_alt_off } from 'components/material-symbols/symbols/directions_alt_off';
import { symbol_directions_bike } from 'components/material-symbols/symbols/directions_bike';
import { symbol_directions_boat } from 'components/material-symbols/symbols/directions_boat';
import { symbol_directions_bus } from 'components/material-symbols/symbols/directions_bus';
import { symbol_directions_car } from 'components/material-symbols/symbols/directions_car';
import { symbol_directions_off } from 'components/material-symbols/symbols/directions_off';
import { symbol_directions_railway } from 'components/material-symbols/symbols/directions_railway';
import { symbol_directions_run } from 'components/material-symbols/symbols/directions_run';
import { symbol_directions_subway } from 'components/material-symbols/symbols/directions_subway';
import { symbol_directions_walk } from 'components/material-symbols/symbols/directions_walk';
import { symbol_directory_sync } from 'components/material-symbols/symbols/directory_sync';
import { symbol_dirty_lens } from 'components/material-symbols/symbols/dirty_lens';
import { symbol_disabled_by_default } from 'components/material-symbols/symbols/disabled_by_default';
import { symbol_disabled_visible } from 'components/material-symbols/symbols/disabled_visible';
import { symbol_disc_full } from 'components/material-symbols/symbols/disc_full';
import { symbol_discover_tune } from 'components/material-symbols/symbols/discover_tune';
import { symbol_dishwasher } from 'components/material-symbols/symbols/dishwasher';
import { symbol_dishwasher_gen } from 'components/material-symbols/symbols/dishwasher_gen';
import { symbol_display_external_input } from 'components/material-symbols/symbols/display_external_input';
import { symbol_display_settings } from 'components/material-symbols/symbols/display_settings';
import { symbol_distance } from 'components/material-symbols/symbols/distance';
import { symbol_diversity_1 } from 'components/material-symbols/symbols/diversity_1';
import { symbol_diversity_2 } from 'components/material-symbols/symbols/diversity_2';
import { symbol_diversity_3 } from 'components/material-symbols/symbols/diversity_3';
import { symbol_diversity_4 } from 'components/material-symbols/symbols/diversity_4';
import { symbol_dns } from 'components/material-symbols/symbols/dns';
import { symbol_do_not_disturb_off } from 'components/material-symbols/symbols/do_not_disturb_off';
import { symbol_do_not_disturb_on } from 'components/material-symbols/symbols/do_not_disturb_on';
import { symbol_do_not_disturb_on_total_silence } from 'components/material-symbols/symbols/do_not_disturb_on_total_silence';
import { symbol_do_not_step } from 'components/material-symbols/symbols/do_not_step';
import { symbol_do_not_touch } from 'components/material-symbols/symbols/do_not_touch';
import { symbol_dock } from 'components/material-symbols/symbols/dock';
import { symbol_dock_to_bottom } from 'components/material-symbols/symbols/dock_to_bottom';
import { symbol_dock_to_left } from 'components/material-symbols/symbols/dock_to_left';
import { symbol_dock_to_right } from 'components/material-symbols/symbols/dock_to_right';
import { symbol_docs_add_on } from 'components/material-symbols/symbols/docs_add_on';
import { symbol_docs_apps_script } from 'components/material-symbols/symbols/docs_apps_script';
import { symbol_document_scanner } from 'components/material-symbols/symbols/document_scanner';
import { symbol_domain } from 'components/material-symbols/symbols/domain';
import { symbol_domain_add } from 'components/material-symbols/symbols/domain_add';
import { symbol_domain_disabled } from 'components/material-symbols/symbols/domain_disabled';
import { symbol_domain_verification } from 'components/material-symbols/symbols/domain_verification';
import { symbol_domain_verification_off } from 'components/material-symbols/symbols/domain_verification_off';
import { symbol_domino_mask } from 'components/material-symbols/symbols/domino_mask';
import { symbol_done } from 'components/material-symbols/symbols/done';
import { symbol_done_all } from 'components/material-symbols/symbols/done_all';
import { symbol_done_outline } from 'components/material-symbols/symbols/done_outline';
import { symbol_donut_large } from 'components/material-symbols/symbols/donut_large';
import { symbol_donut_small } from 'components/material-symbols/symbols/donut_small';
import { symbol_door_back } from 'components/material-symbols/symbols/door_back';
import { symbol_door_front } from 'components/material-symbols/symbols/door_front';
import { symbol_door_open } from 'components/material-symbols/symbols/door_open';
import { symbol_door_sensor } from 'components/material-symbols/symbols/door_sensor';
import { symbol_door_sliding } from 'components/material-symbols/symbols/door_sliding';
import { symbol_doorbell } from 'components/material-symbols/symbols/doorbell';
import { symbol_doorbell_3p } from 'components/material-symbols/symbols/doorbell_3p';
import { symbol_doorbell_chime } from 'components/material-symbols/symbols/doorbell_chime';
import { symbol_double_arrow } from 'components/material-symbols/symbols/double_arrow';
import { symbol_downhill_skiing } from 'components/material-symbols/symbols/downhill_skiing';
import { symbol_download } from 'components/material-symbols/symbols/download';
import { symbol_download_2 } from 'components/material-symbols/symbols/download_2';
import { symbol_download_done } from 'components/material-symbols/symbols/download_done';
import { symbol_download_for_offline } from 'components/material-symbols/symbols/download_for_offline';
import { symbol_downloading } from 'components/material-symbols/symbols/downloading';
import { symbol_draft } from 'components/material-symbols/symbols/draft';
import { symbol_draft_orders } from 'components/material-symbols/symbols/draft_orders';
import { symbol_drafts } from 'components/material-symbols/symbols/drafts';
import { symbol_drag_click } from 'components/material-symbols/symbols/drag_click';
import { symbol_drag_handle } from 'components/material-symbols/symbols/drag_handle';
import { symbol_drag_indicator } from 'components/material-symbols/symbols/drag_indicator';
import { symbol_drag_pan } from 'components/material-symbols/symbols/drag_pan';
import { symbol_draw } from 'components/material-symbols/symbols/draw';
import { symbol_draw_abstract } from 'components/material-symbols/symbols/draw_abstract';
import { symbol_draw_collage } from 'components/material-symbols/symbols/draw_collage';
import { symbol_dresser } from 'components/material-symbols/symbols/dresser';
import { symbol_drive_file_move } from 'components/material-symbols/symbols/drive_file_move';
import { symbol_drive_folder_upload } from 'components/material-symbols/symbols/drive_folder_upload';
import { symbol_dropdown } from 'components/material-symbols/symbols/dropdown';
import { symbol_dry } from 'components/material-symbols/symbols/dry';
import { symbol_dry_cleaning } from 'components/material-symbols/symbols/dry_cleaning';
import { symbol_dual_screen } from 'components/material-symbols/symbols/dual_screen';
import { symbol_duo } from 'components/material-symbols/symbols/duo';
import { symbol_dvr } from 'components/material-symbols/symbols/dvr';
import { symbol_dynamic_feed } from 'components/material-symbols/symbols/dynamic_feed';
import { symbol_dynamic_form } from 'components/material-symbols/symbols/dynamic_form';
import { symbol_e911_avatar } from 'components/material-symbols/symbols/e911_avatar';
import { symbol_e911_emergency } from 'components/material-symbols/symbols/e911_emergency';
import { symbol_e_mobiledata } from 'components/material-symbols/symbols/e_mobiledata';
import { symbol_e_mobiledata_badge } from 'components/material-symbols/symbols/e_mobiledata_badge';
import { symbol_earbuds } from 'components/material-symbols/symbols/earbuds';
import { symbol_earbuds_battery } from 'components/material-symbols/symbols/earbuds_battery';
import { symbol_early_on } from 'components/material-symbols/symbols/early_on';
import { symbol_earthquake } from 'components/material-symbols/symbols/earthquake';
import { symbol_east } from 'components/material-symbols/symbols/east';
import { symbol_ecg } from 'components/material-symbols/symbols/ecg';
import { symbol_ecg_heart } from 'components/material-symbols/symbols/ecg_heart';
import { symbol_eco } from 'components/material-symbols/symbols/eco';
import { symbol_eda } from 'components/material-symbols/symbols/eda';
import { symbol_edgesensor_high } from 'components/material-symbols/symbols/edgesensor_high';
import { symbol_edgesensor_low } from 'components/material-symbols/symbols/edgesensor_low';
import { symbol_edit } from 'components/material-symbols/symbols/edit';
import { symbol_edit_attributes } from 'components/material-symbols/symbols/edit_attributes';
import { symbol_edit_calendar } from 'components/material-symbols/symbols/edit_calendar';
import { symbol_edit_document } from 'components/material-symbols/symbols/edit_document';
import { symbol_edit_location } from 'components/material-symbols/symbols/edit_location';
import { symbol_edit_location_alt } from 'components/material-symbols/symbols/edit_location_alt';
import { symbol_edit_note } from 'components/material-symbols/symbols/edit_note';
import { symbol_edit_notifications } from 'components/material-symbols/symbols/edit_notifications';
import { symbol_edit_off } from 'components/material-symbols/symbols/edit_off';
import { symbol_edit_road } from 'components/material-symbols/symbols/edit_road';
import { symbol_edit_square } from 'components/material-symbols/symbols/edit_square';
import { symbol_editor_choice } from 'components/material-symbols/symbols/editor_choice';
import { symbol_egg } from 'components/material-symbols/symbols/egg';
import { symbol_egg_alt } from 'components/material-symbols/symbols/egg_alt';
import { symbol_eject } from 'components/material-symbols/symbols/eject';
import { symbol_elderly } from 'components/material-symbols/symbols/elderly';
import { symbol_elderly_woman } from 'components/material-symbols/symbols/elderly_woman';
import { symbol_electric_bike } from 'components/material-symbols/symbols/electric_bike';
import { symbol_electric_bolt } from 'components/material-symbols/symbols/electric_bolt';
import { symbol_electric_car } from 'components/material-symbols/symbols/electric_car';
import { symbol_electric_meter } from 'components/material-symbols/symbols/electric_meter';
import { symbol_electric_moped } from 'components/material-symbols/symbols/electric_moped';
import { symbol_electric_rickshaw } from 'components/material-symbols/symbols/electric_rickshaw';
import { symbol_electric_scooter } from 'components/material-symbols/symbols/electric_scooter';
import { symbol_electrical_services } from 'components/material-symbols/symbols/electrical_services';
import { symbol_elevation } from 'components/material-symbols/symbols/elevation';
import { symbol_elevator } from 'components/material-symbols/symbols/elevator';
import { symbol_emergency } from 'components/material-symbols/symbols/emergency';
import { symbol_emergency_heat } from 'components/material-symbols/symbols/emergency_heat';
import { symbol_emergency_heat_2 } from 'components/material-symbols/symbols/emergency_heat_2';
import { symbol_emergency_home } from 'components/material-symbols/symbols/emergency_home';
import { symbol_emergency_recording } from 'components/material-symbols/symbols/emergency_recording';
import { symbol_emergency_share } from 'components/material-symbols/symbols/emergency_share';
import { symbol_emergency_share_off } from 'components/material-symbols/symbols/emergency_share_off';
import { symbol_emoji_events } from 'components/material-symbols/symbols/emoji_events';
import { symbol_emoji_flags } from 'components/material-symbols/symbols/emoji_flags';
import { symbol_emoji_food_beverage } from 'components/material-symbols/symbols/emoji_food_beverage';
import { symbol_emoji_language } from 'components/material-symbols/symbols/emoji_language';
import { symbol_emoji_nature } from 'components/material-symbols/symbols/emoji_nature';
import { symbol_emoji_objects } from 'components/material-symbols/symbols/emoji_objects';
import { symbol_emoji_people } from 'components/material-symbols/symbols/emoji_people';
import { symbol_emoji_symbols } from 'components/material-symbols/symbols/emoji_symbols';
import { symbol_emoji_transportation } from 'components/material-symbols/symbols/emoji_transportation';
import { symbol_emoticon } from 'components/material-symbols/symbols/emoticon';
import { symbol_empty_dashboard } from 'components/material-symbols/symbols/empty_dashboard';
import { symbol_enable } from 'components/material-symbols/symbols/enable';
import { symbol_encrypted } from 'components/material-symbols/symbols/encrypted';
import { symbol_endocrinology } from 'components/material-symbols/symbols/endocrinology';
import { symbol_energy } from 'components/material-symbols/symbols/energy';
import { symbol_energy_program_saving } from 'components/material-symbols/symbols/energy_program_saving';
import { symbol_energy_program_time_used } from 'components/material-symbols/symbols/energy_program_time_used';
import { symbol_energy_savings_leaf } from 'components/material-symbols/symbols/energy_savings_leaf';
import { symbol_engineering } from 'components/material-symbols/symbols/engineering';
import { symbol_enhanced_encryption } from 'components/material-symbols/symbols/enhanced_encryption';
import { symbol_ent } from 'components/material-symbols/symbols/ent';
import { symbol_enterprise } from 'components/material-symbols/symbols/enterprise';
import { symbol_enterprise_off } from 'components/material-symbols/symbols/enterprise_off';
import { symbol_equal } from 'components/material-symbols/symbols/equal';
import { symbol_equalizer } from 'components/material-symbols/symbols/equalizer';
import { symbol_error } from 'components/material-symbols/symbols/error';
import { symbol_error_med } from 'components/material-symbols/symbols/error_med';
import { symbol_escalator } from 'components/material-symbols/symbols/escalator';
import { symbol_escalator_warning } from 'components/material-symbols/symbols/escalator_warning';
import { symbol_euro } from 'components/material-symbols/symbols/euro';
import { symbol_euro_symbol } from 'components/material-symbols/symbols/euro_symbol';
import { symbol_ev_charger } from 'components/material-symbols/symbols/ev_charger';
import { symbol_ev_mobiledata_badge } from 'components/material-symbols/symbols/ev_mobiledata_badge';
import { symbol_ev_shadow } from 'components/material-symbols/symbols/ev_shadow';
import { symbol_ev_shadow_add } from 'components/material-symbols/symbols/ev_shadow_add';
import { symbol_ev_shadow_minus } from 'components/material-symbols/symbols/ev_shadow_minus';
import { symbol_ev_station } from 'components/material-symbols/symbols/ev_station';
import { symbol_event } from 'components/material-symbols/symbols/event';
import { symbol_event_available } from 'components/material-symbols/symbols/event_available';
import { symbol_event_busy } from 'components/material-symbols/symbols/event_busy';
import { symbol_event_list } from 'components/material-symbols/symbols/event_list';
import { symbol_event_note } from 'components/material-symbols/symbols/event_note';
import { symbol_event_repeat } from 'components/material-symbols/symbols/event_repeat';
import { symbol_event_seat } from 'components/material-symbols/symbols/event_seat';
import { symbol_event_upcoming } from 'components/material-symbols/symbols/event_upcoming';
import { symbol_exclamation } from 'components/material-symbols/symbols/exclamation';
import { symbol_exercise } from 'components/material-symbols/symbols/exercise';
import { symbol_exit_to_app } from 'components/material-symbols/symbols/exit_to_app';
import { symbol_expand } from 'components/material-symbols/symbols/expand';
import { symbol_expand_all } from 'components/material-symbols/symbols/expand_all';
import { symbol_expand_circle_down } from 'components/material-symbols/symbols/expand_circle_down';
import { symbol_expand_circle_right } from 'components/material-symbols/symbols/expand_circle_right';
import { symbol_expand_circle_up } from 'components/material-symbols/symbols/expand_circle_up';
import { symbol_expand_content } from 'components/material-symbols/symbols/expand_content';
import { symbol_expand_less } from 'components/material-symbols/symbols/expand_less';
import { symbol_expand_more } from 'components/material-symbols/symbols/expand_more';
import { symbol_experiment } from 'components/material-symbols/symbols/experiment';
import { symbol_explicit } from 'components/material-symbols/symbols/explicit';
import { symbol_explore } from 'components/material-symbols/symbols/explore';
import { symbol_explore_nearby } from 'components/material-symbols/symbols/explore_nearby';
import { symbol_explore_off } from 'components/material-symbols/symbols/explore_off';
import { symbol_explosion } from 'components/material-symbols/symbols/explosion';
import { symbol_export_notes } from 'components/material-symbols/symbols/export_notes';
import { symbol_exposure } from 'components/material-symbols/symbols/exposure';
import { symbol_exposure_neg_1 } from 'components/material-symbols/symbols/exposure_neg_1';
import { symbol_exposure_neg_2 } from 'components/material-symbols/symbols/exposure_neg_2';
import { symbol_exposure_plus_1 } from 'components/material-symbols/symbols/exposure_plus_1';
import { symbol_exposure_plus_2 } from 'components/material-symbols/symbols/exposure_plus_2';
import { symbol_exposure_zero } from 'components/material-symbols/symbols/exposure_zero';
import { symbol_extension } from 'components/material-symbols/symbols/extension';
import { symbol_extension_off } from 'components/material-symbols/symbols/extension_off';
import { symbol_eye_tracking } from 'components/material-symbols/symbols/eye_tracking';
import { symbol_eyeglasses } from 'components/material-symbols/symbols/eyeglasses';
import { symbol_face } from 'components/material-symbols/symbols/face';
import { symbol_face_2 } from 'components/material-symbols/symbols/face_2';
import { symbol_face_3 } from 'components/material-symbols/symbols/face_3';
import { symbol_face_4 } from 'components/material-symbols/symbols/face_4';
import { symbol_face_5 } from 'components/material-symbols/symbols/face_5';
import { symbol_face_6 } from 'components/material-symbols/symbols/face_6';
import { symbol_face_retouching_off } from 'components/material-symbols/symbols/face_retouching_off';
import { symbol_fact_check } from 'components/material-symbols/symbols/fact_check';
import { symbol_factory } from 'components/material-symbols/symbols/factory';
import { symbol_falling } from 'components/material-symbols/symbols/falling';
import { symbol_familiar_face_and_zone } from 'components/material-symbols/symbols/familiar_face_and_zone';
import { symbol_family_history } from 'components/material-symbols/symbols/family_history';
import { symbol_family_home } from 'components/material-symbols/symbols/family_home';
import { symbol_family_link } from 'components/material-symbols/symbols/family_link';
import { symbol_family_restroom } from 'components/material-symbols/symbols/family_restroom';
import { symbol_family_star } from 'components/material-symbols/symbols/family_star';
import { symbol_farsight_digital } from 'components/material-symbols/symbols/farsight_digital';
import { symbol_fast_forward } from 'components/material-symbols/symbols/fast_forward';
import { symbol_fast_rewind } from 'components/material-symbols/symbols/fast_rewind';
import { symbol_fastfood } from 'components/material-symbols/symbols/fastfood';
import { symbol_faucet } from 'components/material-symbols/symbols/faucet';
import { symbol_favorite } from 'components/material-symbols/symbols/favorite';
import { symbol_fax } from 'components/material-symbols/symbols/fax';
import { symbol_feature_search } from 'components/material-symbols/symbols/feature_search';
import { symbol_featured_play_list } from 'components/material-symbols/symbols/featured_play_list';
import { symbol_featured_seasonal_and_gifts } from 'components/material-symbols/symbols/featured_seasonal_and_gifts';
import { symbol_featured_video } from 'components/material-symbols/symbols/featured_video';
import { symbol_feed } from 'components/material-symbols/symbols/feed';
import { symbol_feedback } from 'components/material-symbols/symbols/feedback';
import { symbol_female } from 'components/material-symbols/symbols/female';
import { symbol_femur } from 'components/material-symbols/symbols/femur';
import { symbol_femur_alt } from 'components/material-symbols/symbols/femur_alt';
import { symbol_fence } from 'components/material-symbols/symbols/fence';
import { symbol_fertile } from 'components/material-symbols/symbols/fertile';
import { symbol_festival } from 'components/material-symbols/symbols/festival';
import { symbol_fiber_dvr } from 'components/material-symbols/symbols/fiber_dvr';
import { symbol_fiber_manual_record } from 'components/material-symbols/symbols/fiber_manual_record';
import { symbol_fiber_new } from 'components/material-symbols/symbols/fiber_new';
import { symbol_fiber_pin } from 'components/material-symbols/symbols/fiber_pin';
import { symbol_fiber_smart_record } from 'components/material-symbols/symbols/fiber_smart_record';
import { symbol_file_copy } from 'components/material-symbols/symbols/file_copy';
import { symbol_file_copy_off } from 'components/material-symbols/symbols/file_copy_off';
import { symbol_file_download_done } from 'components/material-symbols/symbols/file_download_done';
import { symbol_file_download_off } from 'components/material-symbols/symbols/file_download_off';
import { symbol_file_map } from 'components/material-symbols/symbols/file_map';
import { symbol_file_open } from 'components/material-symbols/symbols/file_open';
import { symbol_file_present } from 'components/material-symbols/symbols/file_present';
import { symbol_file_save } from 'components/material-symbols/symbols/file_save';
import { symbol_file_save_off } from 'components/material-symbols/symbols/file_save_off';
import { symbol_file_upload_off } from 'components/material-symbols/symbols/file_upload_off';
import { symbol_filter } from 'components/material-symbols/symbols/filter';
import { symbol_filter_1 } from 'components/material-symbols/symbols/filter_1';
import { symbol_filter_2 } from 'components/material-symbols/symbols/filter_2';
import { symbol_filter_3 } from 'components/material-symbols/symbols/filter_3';
import { symbol_filter_4 } from 'components/material-symbols/symbols/filter_4';
import { symbol_filter_5 } from 'components/material-symbols/symbols/filter_5';
import { symbol_filter_6 } from 'components/material-symbols/symbols/filter_6';
import { symbol_filter_7 } from 'components/material-symbols/symbols/filter_7';
import { symbol_filter_8 } from 'components/material-symbols/symbols/filter_8';
import { symbol_filter_9 } from 'components/material-symbols/symbols/filter_9';
import { symbol_filter_9_plus } from 'components/material-symbols/symbols/filter_9_plus';
import { symbol_filter_alt } from 'components/material-symbols/symbols/filter_alt';
import { symbol_filter_alt_off } from 'components/material-symbols/symbols/filter_alt_off';
import { symbol_filter_b_and_w } from 'components/material-symbols/symbols/filter_b_and_w';
import { symbol_filter_center_focus } from 'components/material-symbols/symbols/filter_center_focus';
import { symbol_filter_drama } from 'components/material-symbols/symbols/filter_drama';
import { symbol_filter_frames } from 'components/material-symbols/symbols/filter_frames';
import { symbol_filter_hdr } from 'components/material-symbols/symbols/filter_hdr';
import { symbol_filter_list } from 'components/material-symbols/symbols/filter_list';
import { symbol_filter_list_off } from 'components/material-symbols/symbols/filter_list_off';
import { symbol_filter_none } from 'components/material-symbols/symbols/filter_none';
import { symbol_filter_retrolux } from 'components/material-symbols/symbols/filter_retrolux';
import { symbol_filter_tilt_shift } from 'components/material-symbols/symbols/filter_tilt_shift';
import { symbol_filter_vintage } from 'components/material-symbols/symbols/filter_vintage';
import { symbol_finance } from 'components/material-symbols/symbols/finance';
import { symbol_finance_chip } from 'components/material-symbols/symbols/finance_chip';
import { symbol_finance_mode } from 'components/material-symbols/symbols/finance_mode';
import { symbol_find_in_page } from 'components/material-symbols/symbols/find_in_page';
import { symbol_find_replace } from 'components/material-symbols/symbols/find_replace';
import { symbol_fingerprint } from 'components/material-symbols/symbols/fingerprint';
import { symbol_fingerprint_off } from 'components/material-symbols/symbols/fingerprint_off';
import { symbol_fire_extinguisher } from 'components/material-symbols/symbols/fire_extinguisher';
import { symbol_fire_hydrant } from 'components/material-symbols/symbols/fire_hydrant';
import { symbol_fire_truck } from 'components/material-symbols/symbols/fire_truck';
import { symbol_fireplace } from 'components/material-symbols/symbols/fireplace';
import { symbol_first_page } from 'components/material-symbols/symbols/first_page';
import { symbol_fit_page } from 'components/material-symbols/symbols/fit_page';
import { symbol_fit_screen } from 'components/material-symbols/symbols/fit_screen';
import { symbol_fit_width } from 'components/material-symbols/symbols/fit_width';
import { symbol_fitness_center } from 'components/material-symbols/symbols/fitness_center';
import { symbol_flag } from 'components/material-symbols/symbols/flag';
import { symbol_flag_circle } from 'components/material-symbols/symbols/flag_circle';
import { symbol_flaky } from 'components/material-symbols/symbols/flaky';
import { symbol_flare } from 'components/material-symbols/symbols/flare';
import { symbol_flash_auto } from 'components/material-symbols/symbols/flash_auto';
import { symbol_flash_off } from 'components/material-symbols/symbols/flash_off';
import { symbol_flash_on } from 'components/material-symbols/symbols/flash_on';
import { symbol_flashlight_off } from 'components/material-symbols/symbols/flashlight_off';
import { symbol_flashlight_on } from 'components/material-symbols/symbols/flashlight_on';
import { symbol_flatware } from 'components/material-symbols/symbols/flatware';
import { symbol_flex_direction } from 'components/material-symbols/symbols/flex_direction';
import { symbol_flex_no_wrap } from 'components/material-symbols/symbols/flex_no_wrap';
import { symbol_flex_wrap } from 'components/material-symbols/symbols/flex_wrap';
import { symbol_flight } from 'components/material-symbols/symbols/flight';
import { symbol_flight_class } from 'components/material-symbols/symbols/flight_class';
import { symbol_flight_land } from 'components/material-symbols/symbols/flight_land';
import { symbol_flight_takeoff } from 'components/material-symbols/symbols/flight_takeoff';
import { symbol_flights_and_hotels } from 'components/material-symbols/symbols/flights_and_hotels';
import { symbol_flightsmode } from 'components/material-symbols/symbols/flightsmode';
import { symbol_flip } from 'components/material-symbols/symbols/flip';
import { symbol_flip_camera_android } from 'components/material-symbols/symbols/flip_camera_android';
import { symbol_flip_camera_ios } from 'components/material-symbols/symbols/flip_camera_ios';
import { symbol_flip_to_back } from 'components/material-symbols/symbols/flip_to_back';
import { symbol_flip_to_front } from 'components/material-symbols/symbols/flip_to_front';
import { symbol_flood } from 'components/material-symbols/symbols/flood';
import { symbol_floor } from 'components/material-symbols/symbols/floor';
import { symbol_floor_lamp } from 'components/material-symbols/symbols/floor_lamp';
import { symbol_flowsheet } from 'components/material-symbols/symbols/flowsheet';
import { symbol_fluid } from 'components/material-symbols/symbols/fluid';
import { symbol_fluid_balance } from 'components/material-symbols/symbols/fluid_balance';
import { symbol_fluid_med } from 'components/material-symbols/symbols/fluid_med';
import { symbol_fluorescent } from 'components/material-symbols/symbols/fluorescent';
import { symbol_flutter } from 'components/material-symbols/symbols/flutter';
import { symbol_flutter_dash } from 'components/material-symbols/symbols/flutter_dash';
import { symbol_fmd_bad } from 'components/material-symbols/symbols/fmd_bad';
import { symbol_foggy } from 'components/material-symbols/symbols/foggy';
import { symbol_folded_hands } from 'components/material-symbols/symbols/folded_hands';
import { symbol_folder } from 'components/material-symbols/symbols/folder';
import { symbol_folder_copy } from 'components/material-symbols/symbols/folder_copy';
import { symbol_folder_data } from 'components/material-symbols/symbols/folder_data';
import { symbol_folder_delete } from 'components/material-symbols/symbols/folder_delete';
import { symbol_folder_limited } from 'components/material-symbols/symbols/folder_limited';
import { symbol_folder_managed } from 'components/material-symbols/symbols/folder_managed';
import { symbol_folder_off } from 'components/material-symbols/symbols/folder_off';
import { symbol_folder_open } from 'components/material-symbols/symbols/folder_open';
import { symbol_folder_shared } from 'components/material-symbols/symbols/folder_shared';
import { symbol_folder_special } from 'components/material-symbols/symbols/folder_special';
import { symbol_folder_supervised } from 'components/material-symbols/symbols/folder_supervised';
import { symbol_folder_zip } from 'components/material-symbols/symbols/folder_zip';
import { symbol_follow_the_signs } from 'components/material-symbols/symbols/follow_the_signs';
import { symbol_font_download } from 'components/material-symbols/symbols/font_download';
import { symbol_font_download_off } from 'components/material-symbols/symbols/font_download_off';
import { symbol_food_bank } from 'components/material-symbols/symbols/food_bank';
import { symbol_foot_bones } from 'components/material-symbols/symbols/foot_bones';
import { symbol_footprint } from 'components/material-symbols/symbols/footprint';
import { symbol_for_you } from 'components/material-symbols/symbols/for_you';
import { symbol_forest } from 'components/material-symbols/symbols/forest';
import { symbol_fork_left } from 'components/material-symbols/symbols/fork_left';
import { symbol_fork_right } from 'components/material-symbols/symbols/fork_right';
import { symbol_forklift } from 'components/material-symbols/symbols/forklift';
import { symbol_format_align_center } from 'components/material-symbols/symbols/format_align_center';
import { symbol_format_align_justify } from 'components/material-symbols/symbols/format_align_justify';
import { symbol_format_align_left } from 'components/material-symbols/symbols/format_align_left';
import { symbol_format_align_right } from 'components/material-symbols/symbols/format_align_right';
import { symbol_format_bold } from 'components/material-symbols/symbols/format_bold';
import { symbol_format_clear } from 'components/material-symbols/symbols/format_clear';
import { symbol_format_color_fill } from 'components/material-symbols/symbols/format_color_fill';
import { symbol_format_color_reset } from 'components/material-symbols/symbols/format_color_reset';
import { symbol_format_color_text } from 'components/material-symbols/symbols/format_color_text';
import { symbol_format_h1 } from 'components/material-symbols/symbols/format_h1';
import { symbol_format_h2 } from 'components/material-symbols/symbols/format_h2';
import { symbol_format_h3 } from 'components/material-symbols/symbols/format_h3';
import { symbol_format_h4 } from 'components/material-symbols/symbols/format_h4';
import { symbol_format_h5 } from 'components/material-symbols/symbols/format_h5';
import { symbol_format_h6 } from 'components/material-symbols/symbols/format_h6';
import { symbol_format_image_left } from 'components/material-symbols/symbols/format_image_left';
import { symbol_format_image_right } from 'components/material-symbols/symbols/format_image_right';
import { symbol_format_indent_decrease } from 'components/material-symbols/symbols/format_indent_decrease';
import { symbol_format_indent_increase } from 'components/material-symbols/symbols/format_indent_increase';
import { symbol_format_ink_highlighter } from 'components/material-symbols/symbols/format_ink_highlighter';
import { symbol_format_italic } from 'components/material-symbols/symbols/format_italic';
import { symbol_format_letter_spacing } from 'components/material-symbols/symbols/format_letter_spacing';
import { symbol_format_letter_spacing_2 } from 'components/material-symbols/symbols/format_letter_spacing_2';
import { symbol_format_letter_spacing_standard } from 'components/material-symbols/symbols/format_letter_spacing_standard';
import { symbol_format_letter_spacing_wide } from 'components/material-symbols/symbols/format_letter_spacing_wide';
import { symbol_format_letter_spacing_wider } from 'components/material-symbols/symbols/format_letter_spacing_wider';
import { symbol_format_line_spacing } from 'components/material-symbols/symbols/format_line_spacing';
import { symbol_format_list_bulleted } from 'components/material-symbols/symbols/format_list_bulleted';
import { symbol_format_list_bulleted_add } from 'components/material-symbols/symbols/format_list_bulleted_add';
import { symbol_format_list_numbered } from 'components/material-symbols/symbols/format_list_numbered';
import { symbol_format_list_numbered_rtl } from 'components/material-symbols/symbols/format_list_numbered_rtl';
import { symbol_format_overline } from 'components/material-symbols/symbols/format_overline';
import { symbol_format_paint } from 'components/material-symbols/symbols/format_paint';
import { symbol_format_paragraph } from 'components/material-symbols/symbols/format_paragraph';
import { symbol_format_quote } from 'components/material-symbols/symbols/format_quote';
import { symbol_format_shapes } from 'components/material-symbols/symbols/format_shapes';
import { symbol_format_size } from 'components/material-symbols/symbols/format_size';
import { symbol_format_strikethrough } from 'components/material-symbols/symbols/format_strikethrough';
import { symbol_format_text_clip } from 'components/material-symbols/symbols/format_text_clip';
import { symbol_format_text_overflow } from 'components/material-symbols/symbols/format_text_overflow';
import { symbol_format_text_wrap } from 'components/material-symbols/symbols/format_text_wrap';
import { symbol_format_textdirection_l_to_r } from 'components/material-symbols/symbols/format_textdirection_l_to_r';
import { symbol_format_textdirection_r_to_l } from 'components/material-symbols/symbols/format_textdirection_r_to_l';
import { symbol_format_textdirection_vertical } from 'components/material-symbols/symbols/format_textdirection_vertical';
import { symbol_format_underlined } from 'components/material-symbols/symbols/format_underlined';
import { symbol_format_underlined_squiggle } from 'components/material-symbols/symbols/format_underlined_squiggle';
import { symbol_forms_add_on } from 'components/material-symbols/symbols/forms_add_on';
import { symbol_forms_apps_script } from 'components/material-symbols/symbols/forms_apps_script';
import { symbol_fort } from 'components/material-symbols/symbols/fort';
import { symbol_forum } from 'components/material-symbols/symbols/forum';
import { symbol_forward } from 'components/material-symbols/symbols/forward';
import { symbol_forward_10 } from 'components/material-symbols/symbols/forward_10';
import { symbol_forward_30 } from 'components/material-symbols/symbols/forward_30';
import { symbol_forward_5 } from 'components/material-symbols/symbols/forward_5';
import { symbol_forward_circle } from 'components/material-symbols/symbols/forward_circle';
import { symbol_forward_media } from 'components/material-symbols/symbols/forward_media';
import { symbol_forward_to_inbox } from 'components/material-symbols/symbols/forward_to_inbox';
import { symbol_foundation } from 'components/material-symbols/symbols/foundation';
import { symbol_frame_inspect } from 'components/material-symbols/symbols/frame_inspect';
import { symbol_frame_person } from 'components/material-symbols/symbols/frame_person';
import { symbol_frame_person_mic } from 'components/material-symbols/symbols/frame_person_mic';
import { symbol_frame_person_off } from 'components/material-symbols/symbols/frame_person_off';
import { symbol_frame_reload } from 'components/material-symbols/symbols/frame_reload';
import { symbol_frame_source } from 'components/material-symbols/symbols/frame_source';
import { symbol_free_cancellation } from 'components/material-symbols/symbols/free_cancellation';
import { symbol_front_hand } from 'components/material-symbols/symbols/front_hand';
import { symbol_front_loader } from 'components/material-symbols/symbols/front_loader';
import { symbol_full_coverage } from 'components/material-symbols/symbols/full_coverage';
import { symbol_full_hd } from 'components/material-symbols/symbols/full_hd';
import { symbol_full_stacked_bar_chart } from 'components/material-symbols/symbols/full_stacked_bar_chart';
import { symbol_fullscreen } from 'components/material-symbols/symbols/fullscreen';
import { symbol_fullscreen_exit } from 'components/material-symbols/symbols/fullscreen_exit';
import { symbol_function } from 'components/material-symbols/symbols/function';
import { symbol_functions } from 'components/material-symbols/symbols/functions';
import { symbol_g_mobiledata } from 'components/material-symbols/symbols/g_mobiledata';
import { symbol_g_mobiledata_badge } from 'components/material-symbols/symbols/g_mobiledata_badge';
import { symbol_g_translate } from 'components/material-symbols/symbols/g_translate';
import { symbol_gallery_thumbnail } from 'components/material-symbols/symbols/gallery_thumbnail';
import { symbol_gamepad } from 'components/material-symbols/symbols/gamepad';
import { symbol_garage } from 'components/material-symbols/symbols/garage';
import { symbol_garage_door } from 'components/material-symbols/symbols/garage_door';
import { symbol_garage_home } from 'components/material-symbols/symbols/garage_home';
import { symbol_garden_cart } from 'components/material-symbols/symbols/garden_cart';
import { symbol_gas_meter } from 'components/material-symbols/symbols/gas_meter';
import { symbol_gastroenterology } from 'components/material-symbols/symbols/gastroenterology';
import { symbol_gate } from 'components/material-symbols/symbols/gate';
import { symbol_gavel } from 'components/material-symbols/symbols/gavel';
import { symbol_general_device } from 'components/material-symbols/symbols/general_device';
import { symbol_genetics } from 'components/material-symbols/symbols/genetics';
import { symbol_genres } from 'components/material-symbols/symbols/genres';
import { symbol_gesture } from 'components/material-symbols/symbols/gesture';
import { symbol_gesture_select } from 'components/material-symbols/symbols/gesture_select';
import { symbol_gif } from 'components/material-symbols/symbols/gif';
import { symbol_gif_box } from 'components/material-symbols/symbols/gif_box';
import { symbol_girl } from 'components/material-symbols/symbols/girl';
import { symbol_gite } from 'components/material-symbols/symbols/gite';
import { symbol_glass_cup } from 'components/material-symbols/symbols/glass_cup';
import { symbol_globe } from 'components/material-symbols/symbols/globe';
import { symbol_globe_asia } from 'components/material-symbols/symbols/globe_asia';
import { symbol_globe_uk } from 'components/material-symbols/symbols/globe_uk';
import { symbol_glucose } from 'components/material-symbols/symbols/glucose';
import { symbol_glyphs } from 'components/material-symbols/symbols/glyphs';
import { symbol_go_to_line } from 'components/material-symbols/symbols/go_to_line';
import { symbol_golf_course } from 'components/material-symbols/symbols/golf_course';
import { symbol_google_home_devices } from 'components/material-symbols/symbols/google_home_devices';
import { symbol_google_tv_remote } from 'components/material-symbols/symbols/google_tv_remote';
import { symbol_google_wifi } from 'components/material-symbols/symbols/google_wifi';
import { symbol_gpp_bad } from 'components/material-symbols/symbols/gpp_bad';
import { symbol_gpp_maybe } from 'components/material-symbols/symbols/gpp_maybe';
import { symbol_grade } from 'components/material-symbols/symbols/grade';
import { symbol_gradient } from 'components/material-symbols/symbols/gradient';
import { symbol_grading } from 'components/material-symbols/symbols/grading';
import { symbol_grain } from 'components/material-symbols/symbols/grain';
import { symbol_graphic_eq } from 'components/material-symbols/symbols/graphic_eq';
import { symbol_grass } from 'components/material-symbols/symbols/grass';
import { symbol_grid_3x3 } from 'components/material-symbols/symbols/grid_3x3';
import { symbol_grid_3x3_off } from 'components/material-symbols/symbols/grid_3x3_off';
import { symbol_grid_4x4 } from 'components/material-symbols/symbols/grid_4x4';
import { symbol_grid_goldenratio } from 'components/material-symbols/symbols/grid_goldenratio';
import { symbol_grid_guides } from 'components/material-symbols/symbols/grid_guides';
import { symbol_grid_off } from 'components/material-symbols/symbols/grid_off';
import { symbol_grid_on } from 'components/material-symbols/symbols/grid_on';
import { symbol_grid_view } from 'components/material-symbols/symbols/grid_view';
import { symbol_grocery } from 'components/material-symbols/symbols/grocery';
import { symbol_group } from 'components/material-symbols/symbols/group';
import { symbol_group_add } from 'components/material-symbols/symbols/group_add';
import { symbol_group_off } from 'components/material-symbols/symbols/group_off';
import { symbol_group_remove } from 'components/material-symbols/symbols/group_remove';
import { symbol_group_work } from 'components/material-symbols/symbols/group_work';
import { symbol_grouped_bar_chart } from 'components/material-symbols/symbols/grouped_bar_chart';
import { symbol_groups } from 'components/material-symbols/symbols/groups';
import { symbol_groups_2 } from 'components/material-symbols/symbols/groups_2';
import { symbol_groups_3 } from 'components/material-symbols/symbols/groups_3';
import { symbol_guardian } from 'components/material-symbols/symbols/guardian';
import { symbol_gynecology } from 'components/material-symbols/symbols/gynecology';
import { symbol_h_mobiledata } from 'components/material-symbols/symbols/h_mobiledata';
import { symbol_h_mobiledata_badge } from 'components/material-symbols/symbols/h_mobiledata_badge';
import { symbol_h_plus_mobiledata } from 'components/material-symbols/symbols/h_plus_mobiledata';
import { symbol_h_plus_mobiledata_badge } from 'components/material-symbols/symbols/h_plus_mobiledata_badge';
import { symbol_hail } from 'components/material-symbols/symbols/hail';
import { symbol_hallway } from 'components/material-symbols/symbols/hallway';
import { symbol_hand_bones } from 'components/material-symbols/symbols/hand_bones';
import { symbol_hand_gesture } from 'components/material-symbols/symbols/hand_gesture';
import { symbol_handheld_controller } from 'components/material-symbols/symbols/handheld_controller';
import { symbol_handshake } from 'components/material-symbols/symbols/handshake';
import { symbol_handyman } from 'components/material-symbols/symbols/handyman';
import { symbol_hangout_video } from 'components/material-symbols/symbols/hangout_video';
import { symbol_hangout_video_off } from 'components/material-symbols/symbols/hangout_video_off';
import { symbol_hard_drive } from 'components/material-symbols/symbols/hard_drive';
import { symbol_hard_drive_2 } from 'components/material-symbols/symbols/hard_drive_2';
import { symbol_hardware } from 'components/material-symbols/symbols/hardware';
import { symbol_hd } from 'components/material-symbols/symbols/hd';
import { symbol_hdr_auto } from 'components/material-symbols/symbols/hdr_auto';
import { symbol_hdr_auto_select } from 'components/material-symbols/symbols/hdr_auto_select';
import { symbol_hdr_enhanced_select } from 'components/material-symbols/symbols/hdr_enhanced_select';
import { symbol_hdr_off } from 'components/material-symbols/symbols/hdr_off';
import { symbol_hdr_off_select } from 'components/material-symbols/symbols/hdr_off_select';
import { symbol_hdr_on } from 'components/material-symbols/symbols/hdr_on';
import { symbol_hdr_on_select } from 'components/material-symbols/symbols/hdr_on_select';
import { symbol_hdr_plus } from 'components/material-symbols/symbols/hdr_plus';
import { symbol_hdr_plus_off } from 'components/material-symbols/symbols/hdr_plus_off';
import { symbol_hdr_strong } from 'components/material-symbols/symbols/hdr_strong';
import { symbol_hdr_weak } from 'components/material-symbols/symbols/hdr_weak';
import { symbol_head_mounted_device } from 'components/material-symbols/symbols/head_mounted_device';
import { symbol_headphones } from 'components/material-symbols/symbols/headphones';
import { symbol_headphones_battery } from 'components/material-symbols/symbols/headphones_battery';
import { symbol_headset_mic } from 'components/material-symbols/symbols/headset_mic';
import { symbol_headset_off } from 'components/material-symbols/symbols/headset_off';
import { symbol_healing } from 'components/material-symbols/symbols/healing';
import { symbol_health_and_beauty } from 'components/material-symbols/symbols/health_and_beauty';
import { symbol_health_and_safety } from 'components/material-symbols/symbols/health_and_safety';
import { symbol_health_metrics } from 'components/material-symbols/symbols/health_metrics';
import { symbol_heap_snapshot_large } from 'components/material-symbols/symbols/heap_snapshot_large';
import { symbol_heap_snapshot_multiple } from 'components/material-symbols/symbols/heap_snapshot_multiple';
import { symbol_heap_snapshot_thumbnail } from 'components/material-symbols/symbols/heap_snapshot_thumbnail';
import { symbol_hearing } from 'components/material-symbols/symbols/hearing';
import { symbol_hearing_disabled } from 'components/material-symbols/symbols/hearing_disabled';
import { symbol_heart_broken } from 'components/material-symbols/symbols/heart_broken';
import { symbol_heart_check } from 'components/material-symbols/symbols/heart_check';
import { symbol_heart_minus } from 'components/material-symbols/symbols/heart_minus';
import { symbol_heart_plus } from 'components/material-symbols/symbols/heart_plus';
import { symbol_heat } from 'components/material-symbols/symbols/heat';
import { symbol_heat_pump } from 'components/material-symbols/symbols/heat_pump';
import { symbol_heat_pump_balance } from 'components/material-symbols/symbols/heat_pump_balance';
import { symbol_height } from 'components/material-symbols/symbols/height';
import { symbol_helicopter } from 'components/material-symbols/symbols/helicopter';
import { symbol_help } from 'components/material-symbols/symbols/help';
import { symbol_help_center } from 'components/material-symbols/symbols/help_center';
import { symbol_help_clinic } from 'components/material-symbols/symbols/help_clinic';
import { symbol_hematology } from 'components/material-symbols/symbols/hematology';
import { symbol_hevc } from 'components/material-symbols/symbols/hevc';
import { symbol_hexagon } from 'components/material-symbols/symbols/hexagon';
import { symbol_hide } from 'components/material-symbols/symbols/hide';
import { symbol_hide_image } from 'components/material-symbols/symbols/hide_image';
import { symbol_hide_source } from 'components/material-symbols/symbols/hide_source';
import { symbol_high_density } from 'components/material-symbols/symbols/high_density';
import { symbol_high_quality } from 'components/material-symbols/symbols/high_quality';
import { symbol_high_res } from 'components/material-symbols/symbols/high_res';
import { symbol_highlight } from 'components/material-symbols/symbols/highlight';
import { symbol_highlight_keyboard_focus } from 'components/material-symbols/symbols/highlight_keyboard_focus';
import { symbol_highlight_mouse_cursor } from 'components/material-symbols/symbols/highlight_mouse_cursor';
import { symbol_highlight_text_cursor } from 'components/material-symbols/symbols/highlight_text_cursor';
import { symbol_highlighter_size_1 } from 'components/material-symbols/symbols/highlighter_size_1';
import { symbol_highlighter_size_2 } from 'components/material-symbols/symbols/highlighter_size_2';
import { symbol_highlighter_size_3 } from 'components/material-symbols/symbols/highlighter_size_3';
import { symbol_highlighter_size_4 } from 'components/material-symbols/symbols/highlighter_size_4';
import { symbol_highlighter_size_5 } from 'components/material-symbols/symbols/highlighter_size_5';
import { symbol_hiking } from 'components/material-symbols/symbols/hiking';
import { symbol_history } from 'components/material-symbols/symbols/history';
import { symbol_history_edu } from 'components/material-symbols/symbols/history_edu';
import { symbol_history_off } from 'components/material-symbols/symbols/history_off';
import { symbol_history_toggle_off } from 'components/material-symbols/symbols/history_toggle_off';
import { symbol_hive } from 'components/material-symbols/symbols/hive';
import { symbol_hls } from 'components/material-symbols/symbols/hls';
import { symbol_hls_off } from 'components/material-symbols/symbols/hls_off';
import { symbol_holiday_village } from 'components/material-symbols/symbols/holiday_village';
import { symbol_home } from 'components/material-symbols/symbols/home';
import { symbol_home_and_garden } from 'components/material-symbols/symbols/home_and_garden';
import { symbol_home_app_logo } from 'components/material-symbols/symbols/home_app_logo';
import { symbol_home_health } from 'components/material-symbols/symbols/home_health';
import { symbol_home_improvement_and_tools } from 'components/material-symbols/symbols/home_improvement_and_tools';
import { symbol_home_iot_device } from 'components/material-symbols/symbols/home_iot_device';
import { symbol_home_max } from 'components/material-symbols/symbols/home_max';
import { symbol_home_max_dots } from 'components/material-symbols/symbols/home_max_dots';
import { symbol_home_mini } from 'components/material-symbols/symbols/home_mini';
import { symbol_home_pin } from 'components/material-symbols/symbols/home_pin';
import { symbol_home_repair_service } from 'components/material-symbols/symbols/home_repair_service';
import { symbol_home_speaker } from 'components/material-symbols/symbols/home_speaker';
import { symbol_home_storage } from 'components/material-symbols/symbols/home_storage';
import { symbol_home_work } from 'components/material-symbols/symbols/home_work';
import { symbol_horizontal_distribute } from 'components/material-symbols/symbols/horizontal_distribute';
import { symbol_horizontal_rule } from 'components/material-symbols/symbols/horizontal_rule';
import { symbol_horizontal_split } from 'components/material-symbols/symbols/horizontal_split';
import { symbol_hot_tub } from 'components/material-symbols/symbols/hot_tub';
import { symbol_hotel } from 'components/material-symbols/symbols/hotel';
import { symbol_hotel_class } from 'components/material-symbols/symbols/hotel_class';
import { symbol_hourglass } from 'components/material-symbols/symbols/hourglass';
import { symbol_hourglass_bottom } from 'components/material-symbols/symbols/hourglass_bottom';
import { symbol_hourglass_disabled } from 'components/material-symbols/symbols/hourglass_disabled';
import { symbol_hourglass_empty } from 'components/material-symbols/symbols/hourglass_empty';
import { symbol_hourglass_top } from 'components/material-symbols/symbols/hourglass_top';
import { symbol_house } from 'components/material-symbols/symbols/house';
import { symbol_house_siding } from 'components/material-symbols/symbols/house_siding';
import { symbol_house_with_shield } from 'components/material-symbols/symbols/house_with_shield';
import { symbol_houseboat } from 'components/material-symbols/symbols/houseboat';
import { symbol_household_supplies } from 'components/material-symbols/symbols/household_supplies';
import { symbol_how_to_reg } from 'components/material-symbols/symbols/how_to_reg';
import { symbol_how_to_vote } from 'components/material-symbols/symbols/how_to_vote';
import { symbol_hr_resting } from 'components/material-symbols/symbols/hr_resting';
import { symbol_html } from 'components/material-symbols/symbols/html';
import { symbol_http } from 'components/material-symbols/symbols/http';
import { symbol_hub } from 'components/material-symbols/symbols/hub';
import { symbol_humerus } from 'components/material-symbols/symbols/humerus';
import { symbol_humerus_alt } from 'components/material-symbols/symbols/humerus_alt';
import { symbol_humidity_high } from 'components/material-symbols/symbols/humidity_high';
import { symbol_humidity_indoor } from 'components/material-symbols/symbols/humidity_indoor';
import { symbol_humidity_low } from 'components/material-symbols/symbols/humidity_low';
import { symbol_humidity_mid } from 'components/material-symbols/symbols/humidity_mid';
import { symbol_humidity_percentage } from 'components/material-symbols/symbols/humidity_percentage';
import { symbol_hvac } from 'components/material-symbols/symbols/hvac';
import { symbol_ice_skating } from 'components/material-symbols/symbols/ice_skating';
import { symbol_icecream } from 'components/material-symbols/symbols/icecream';
import { symbol_id_card } from 'components/material-symbols/symbols/id_card';
import { symbol_ifl } from 'components/material-symbols/symbols/ifl';
import { symbol_iframe } from 'components/material-symbols/symbols/iframe';
import { symbol_iframe_off } from 'components/material-symbols/symbols/iframe_off';
import { symbol_image } from 'components/material-symbols/symbols/image';
import { symbol_image_aspect_ratio } from 'components/material-symbols/symbols/image_aspect_ratio';
import { symbol_image_search } from 'components/material-symbols/symbols/image_search';
import { symbol_imagesearch_roller } from 'components/material-symbols/symbols/imagesearch_roller';
import { symbol_imagesmode } from 'components/material-symbols/symbols/imagesmode';
import { symbol_immunology } from 'components/material-symbols/symbols/immunology';
import { symbol_import_contacts } from 'components/material-symbols/symbols/import_contacts';
import { symbol_important_devices } from 'components/material-symbols/symbols/important_devices';
import { symbol_in_home_mode } from 'components/material-symbols/symbols/in_home_mode';
import { symbol_inactive_order } from 'components/material-symbols/symbols/inactive_order';
import { symbol_inbox } from 'components/material-symbols/symbols/inbox';
import { symbol_inbox_customize } from 'components/material-symbols/symbols/inbox_customize';
import { symbol_incomplete_circle } from 'components/material-symbols/symbols/incomplete_circle';
import { symbol_indeterminate_check_box } from 'components/material-symbols/symbols/indeterminate_check_box';
import { symbol_indeterminate_question_box } from 'components/material-symbols/symbols/indeterminate_question_box';
import { symbol_info } from 'components/material-symbols/symbols/info';
import { symbol_info_i } from 'components/material-symbols/symbols/info_i';
import { symbol_infrared } from 'components/material-symbols/symbols/infrared';
import { symbol_ink_eraser } from 'components/material-symbols/symbols/ink_eraser';
import { symbol_ink_eraser_off } from 'components/material-symbols/symbols/ink_eraser_off';
import { symbol_ink_highlighter } from 'components/material-symbols/symbols/ink_highlighter';
import { symbol_ink_highlighter_move } from 'components/material-symbols/symbols/ink_highlighter_move';
import { symbol_ink_marker } from 'components/material-symbols/symbols/ink_marker';
import { symbol_ink_pen } from 'components/material-symbols/symbols/ink_pen';
import { symbol_inpatient } from 'components/material-symbols/symbols/inpatient';
import { symbol_input } from 'components/material-symbols/symbols/input';
import { symbol_input_circle } from 'components/material-symbols/symbols/input_circle';
import { symbol_insert_chart } from 'components/material-symbols/symbols/insert_chart';
import { symbol_insert_page_break } from 'components/material-symbols/symbols/insert_page_break';
import { symbol_insert_text } from 'components/material-symbols/symbols/insert_text';
import { symbol_install_desktop } from 'components/material-symbols/symbols/install_desktop';
import { symbol_install_mobile } from 'components/material-symbols/symbols/install_mobile';
import { symbol_instant_mix } from 'components/material-symbols/symbols/instant_mix';
import { symbol_integration_instructions } from 'components/material-symbols/symbols/integration_instructions';
import { symbol_interactive_space } from 'components/material-symbols/symbols/interactive_space';
import { symbol_interests } from 'components/material-symbols/symbols/interests';
import { symbol_interpreter_mode } from 'components/material-symbols/symbols/interpreter_mode';
import { symbol_inventory } from 'components/material-symbols/symbols/inventory';
import { symbol_inventory_2 } from 'components/material-symbols/symbols/inventory_2';
import { symbol_invert_colors } from 'components/material-symbols/symbols/invert_colors';
import { symbol_invert_colors_off } from 'components/material-symbols/symbols/invert_colors_off';
import { symbol_ios } from 'components/material-symbols/symbols/ios';
import { symbol_ios_share } from 'components/material-symbols/symbols/ios_share';
import { symbol_iron } from 'components/material-symbols/symbols/iron';
import { symbol_jamboard_kiosk } from 'components/material-symbols/symbols/jamboard_kiosk';
import { symbol_javascript } from 'components/material-symbols/symbols/javascript';
import { symbol_join } from 'components/material-symbols/symbols/join';
import { symbol_join_inner } from 'components/material-symbols/symbols/join_inner';
import { symbol_join_left } from 'components/material-symbols/symbols/join_left';
import { symbol_join_right } from 'components/material-symbols/symbols/join_right';
import { symbol_joystick } from 'components/material-symbols/symbols/joystick';
import { symbol_jump_to_element } from 'components/material-symbols/symbols/jump_to_element';
import { symbol_kayaking } from 'components/material-symbols/symbols/kayaking';
import { symbol_kebab_dining } from 'components/material-symbols/symbols/kebab_dining';
import { symbol_keep } from 'components/material-symbols/symbols/keep';
import { symbol_keep_off } from 'components/material-symbols/symbols/keep_off';
import { symbol_keep_public } from 'components/material-symbols/symbols/keep_public';
import { symbol_kettle } from 'components/material-symbols/symbols/kettle';
import { symbol_key } from 'components/material-symbols/symbols/key';
import { symbol_key_off } from 'components/material-symbols/symbols/key_off';
import { symbol_key_vertical } from 'components/material-symbols/symbols/key_vertical';
import { symbol_key_visualizer } from 'components/material-symbols/symbols/key_visualizer';
import { symbol_keyboard } from 'components/material-symbols/symbols/keyboard';
import { symbol_keyboard_alt } from 'components/material-symbols/symbols/keyboard_alt';
import { symbol_keyboard_arrow_down } from 'components/material-symbols/symbols/keyboard_arrow_down';
import { symbol_keyboard_arrow_left } from 'components/material-symbols/symbols/keyboard_arrow_left';
import { symbol_keyboard_arrow_right } from 'components/material-symbols/symbols/keyboard_arrow_right';
import { symbol_keyboard_arrow_up } from 'components/material-symbols/symbols/keyboard_arrow_up';
import { symbol_keyboard_backspace } from 'components/material-symbols/symbols/keyboard_backspace';
import { symbol_keyboard_capslock } from 'components/material-symbols/symbols/keyboard_capslock';
import { symbol_keyboard_capslock_badge } from 'components/material-symbols/symbols/keyboard_capslock_badge';
import { symbol_keyboard_command_key } from 'components/material-symbols/symbols/keyboard_command_key';
import { symbol_keyboard_control_key } from 'components/material-symbols/symbols/keyboard_control_key';
import { symbol_keyboard_double_arrow_down } from 'components/material-symbols/symbols/keyboard_double_arrow_down';
import { symbol_keyboard_double_arrow_left } from 'components/material-symbols/symbols/keyboard_double_arrow_left';
import { symbol_keyboard_double_arrow_right } from 'components/material-symbols/symbols/keyboard_double_arrow_right';
import { symbol_keyboard_double_arrow_up } from 'components/material-symbols/symbols/keyboard_double_arrow_up';
import { symbol_keyboard_external_input } from 'components/material-symbols/symbols/keyboard_external_input';
import { symbol_keyboard_full } from 'components/material-symbols/symbols/keyboard_full';
import { symbol_keyboard_hide } from 'components/material-symbols/symbols/keyboard_hide';
import { symbol_keyboard_keys } from 'components/material-symbols/symbols/keyboard_keys';
import { symbol_keyboard_lock } from 'components/material-symbols/symbols/keyboard_lock';
import { symbol_keyboard_lock_off } from 'components/material-symbols/symbols/keyboard_lock_off';
import { symbol_keyboard_off } from 'components/material-symbols/symbols/keyboard_off';
import { symbol_keyboard_onscreen } from 'components/material-symbols/symbols/keyboard_onscreen';
import { symbol_keyboard_option_key } from 'components/material-symbols/symbols/keyboard_option_key';
import { symbol_keyboard_previous_language } from 'components/material-symbols/symbols/keyboard_previous_language';
import { symbol_keyboard_return } from 'components/material-symbols/symbols/keyboard_return';
import { symbol_keyboard_tab } from 'components/material-symbols/symbols/keyboard_tab';
import { symbol_keyboard_tab_rtl } from 'components/material-symbols/symbols/keyboard_tab_rtl';
import { symbol_kid_star } from 'components/material-symbols/symbols/kid_star';
import { symbol_king_bed } from 'components/material-symbols/symbols/king_bed';
import { symbol_kitchen } from 'components/material-symbols/symbols/kitchen';
import { symbol_kitesurfing } from 'components/material-symbols/symbols/kitesurfing';
import { symbol_lab_panel } from 'components/material-symbols/symbols/lab_panel';
import { symbol_lab_profile } from 'components/material-symbols/symbols/lab_profile';
import { symbol_lab_research } from 'components/material-symbols/symbols/lab_research';
import { symbol_label } from 'components/material-symbols/symbols/label';
import { symbol_label_important } from 'components/material-symbols/symbols/label_important';
import { symbol_label_off } from 'components/material-symbols/symbols/label_off';
import { symbol_labs } from 'components/material-symbols/symbols/labs';
import { symbol_lan } from 'components/material-symbols/symbols/lan';
import { symbol_landscape } from 'components/material-symbols/symbols/landscape';
import { symbol_landscape_2 } from 'components/material-symbols/symbols/landscape_2';
import { symbol_landscape_2_off } from 'components/material-symbols/symbols/landscape_2_off';
import { symbol_landslide } from 'components/material-symbols/symbols/landslide';
import { symbol_language } from 'components/material-symbols/symbols/language';
import { symbol_language_chinese_array } from 'components/material-symbols/symbols/language_chinese_array';
import { symbol_language_chinese_cangjie } from 'components/material-symbols/symbols/language_chinese_cangjie';
import { symbol_language_chinese_dayi } from 'components/material-symbols/symbols/language_chinese_dayi';
import { symbol_language_chinese_pinyin } from 'components/material-symbols/symbols/language_chinese_pinyin';
import { symbol_language_chinese_quick } from 'components/material-symbols/symbols/language_chinese_quick';
import { symbol_language_chinese_wubi } from 'components/material-symbols/symbols/language_chinese_wubi';
import { symbol_language_french } from 'components/material-symbols/symbols/language_french';
import { symbol_language_gb_english } from 'components/material-symbols/symbols/language_gb_english';
import { symbol_language_international } from 'components/material-symbols/symbols/language_international';
import { symbol_language_japanese_kana } from 'components/material-symbols/symbols/language_japanese_kana';
import { symbol_language_korean_latin } from 'components/material-symbols/symbols/language_korean_latin';
import { symbol_language_pinyin } from 'components/material-symbols/symbols/language_pinyin';
import { symbol_language_spanish } from 'components/material-symbols/symbols/language_spanish';
import { symbol_language_us } from 'components/material-symbols/symbols/language_us';
import { symbol_language_us_colemak } from 'components/material-symbols/symbols/language_us_colemak';
import { symbol_language_us_dvorak } from 'components/material-symbols/symbols/language_us_dvorak';
import { symbol_laps } from 'components/material-symbols/symbols/laps';
import { symbol_laptop_chromebook } from 'components/material-symbols/symbols/laptop_chromebook';
import { symbol_laptop_mac } from 'components/material-symbols/symbols/laptop_mac';
import { symbol_laptop_windows } from 'components/material-symbols/symbols/laptop_windows';
import { symbol_lasso_select } from 'components/material-symbols/symbols/lasso_select';
import { symbol_last_page } from 'components/material-symbols/symbols/last_page';
import { symbol_laundry } from 'components/material-symbols/symbols/laundry';
import { symbol_layers } from 'components/material-symbols/symbols/layers';
import { symbol_layers_clear } from 'components/material-symbols/symbols/layers_clear';
import { symbol_lda } from 'components/material-symbols/symbols/lda';
import { symbol_leaderboard } from 'components/material-symbols/symbols/leaderboard';
import { symbol_leak_add } from 'components/material-symbols/symbols/leak_add';
import { symbol_leak_remove } from 'components/material-symbols/symbols/leak_remove';
import { symbol_left_click } from 'components/material-symbols/symbols/left_click';
import { symbol_left_panel_close } from 'components/material-symbols/symbols/left_panel_close';
import { symbol_left_panel_open } from 'components/material-symbols/symbols/left_panel_open';
import { symbol_legend_toggle } from 'components/material-symbols/symbols/legend_toggle';
import { symbol_lens } from 'components/material-symbols/symbols/lens';
import { symbol_lens_blur } from 'components/material-symbols/symbols/lens_blur';
import { symbol_letter_switch } from 'components/material-symbols/symbols/letter_switch';
import { symbol_library_add } from 'components/material-symbols/symbols/library_add';
import { symbol_library_add_check } from 'components/material-symbols/symbols/library_add_check';
import { symbol_library_books } from 'components/material-symbols/symbols/library_books';
import { symbol_library_music } from 'components/material-symbols/symbols/library_music';
import { symbol_license } from 'components/material-symbols/symbols/license';
import { symbol_lift_to_talk } from 'components/material-symbols/symbols/lift_to_talk';
import { symbol_light } from 'components/material-symbols/symbols/light';
import { symbol_light_group } from 'components/material-symbols/symbols/light_group';
import { symbol_light_mode } from 'components/material-symbols/symbols/light_mode';
import { symbol_light_off } from 'components/material-symbols/symbols/light_off';
import { symbol_lightbulb } from 'components/material-symbols/symbols/lightbulb';
import { symbol_lightbulb_circle } from 'components/material-symbols/symbols/lightbulb_circle';
import { symbol_lightning_stand } from 'components/material-symbols/symbols/lightning_stand';
import { symbol_line_axis } from 'components/material-symbols/symbols/line_axis';
import { symbol_line_curve } from 'components/material-symbols/symbols/line_curve';
import { symbol_line_end } from 'components/material-symbols/symbols/line_end';
import { symbol_line_end_arrow } from 'components/material-symbols/symbols/line_end_arrow';
import { symbol_line_end_arrow_notch } from 'components/material-symbols/symbols/line_end_arrow_notch';
import { symbol_line_end_circle } from 'components/material-symbols/symbols/line_end_circle';
import { symbol_line_end_diamond } from 'components/material-symbols/symbols/line_end_diamond';
import { symbol_line_end_square } from 'components/material-symbols/symbols/line_end_square';
import { symbol_line_start } from 'components/material-symbols/symbols/line_start';
import { symbol_line_start_arrow } from 'components/material-symbols/symbols/line_start_arrow';
import { symbol_line_start_arrow_notch } from 'components/material-symbols/symbols/line_start_arrow_notch';
import { symbol_line_start_circle } from 'components/material-symbols/symbols/line_start_circle';
import { symbol_line_start_diamond } from 'components/material-symbols/symbols/line_start_diamond';
import { symbol_line_start_square } from 'components/material-symbols/symbols/line_start_square';
import { symbol_line_style } from 'components/material-symbols/symbols/line_style';
import { symbol_line_weight } from 'components/material-symbols/symbols/line_weight';
import { symbol_linear_scale } from 'components/material-symbols/symbols/linear_scale';
import { symbol_link } from 'components/material-symbols/symbols/link';
import { symbol_link_off } from 'components/material-symbols/symbols/link_off';
import { symbol_linked_camera } from 'components/material-symbols/symbols/linked_camera';
import { symbol_linked_services } from 'components/material-symbols/symbols/linked_services';
import { symbol_liquor } from 'components/material-symbols/symbols/liquor';
import { symbol_list } from 'components/material-symbols/symbols/list';
import { symbol_list_alt } from 'components/material-symbols/symbols/list_alt';
import { symbol_list_alt_add } from 'components/material-symbols/symbols/list_alt_add';
import { symbol_lists } from 'components/material-symbols/symbols/lists';
import { symbol_live_help } from 'components/material-symbols/symbols/live_help';
import { symbol_live_tv } from 'components/material-symbols/symbols/live_tv';
import { symbol_living } from 'components/material-symbols/symbols/living';
import { symbol_local_activity } from 'components/material-symbols/symbols/local_activity';
import { symbol_local_atm } from 'components/material-symbols/symbols/local_atm';
import { symbol_local_bar } from 'components/material-symbols/symbols/local_bar';
import { symbol_local_cafe } from 'components/material-symbols/symbols/local_cafe';
import { symbol_local_car_wash } from 'components/material-symbols/symbols/local_car_wash';
import { symbol_local_convenience_store } from 'components/material-symbols/symbols/local_convenience_store';
import { symbol_local_dining } from 'components/material-symbols/symbols/local_dining';
import { symbol_local_drink } from 'components/material-symbols/symbols/local_drink';
import { symbol_local_fire_department } from 'components/material-symbols/symbols/local_fire_department';
import { symbol_local_florist } from 'components/material-symbols/symbols/local_florist';
import { symbol_local_gas_station } from 'components/material-symbols/symbols/local_gas_station';
import { symbol_local_hospital } from 'components/material-symbols/symbols/local_hospital';
import { symbol_local_laundry_service } from 'components/material-symbols/symbols/local_laundry_service';
import { symbol_local_library } from 'components/material-symbols/symbols/local_library';
import { symbol_local_mall } from 'components/material-symbols/symbols/local_mall';
import { symbol_local_parking } from 'components/material-symbols/symbols/local_parking';
import { symbol_local_pharmacy } from 'components/material-symbols/symbols/local_pharmacy';
import { symbol_local_pizza } from 'components/material-symbols/symbols/local_pizza';
import { symbol_local_police } from 'components/material-symbols/symbols/local_police';
import { symbol_local_post_office } from 'components/material-symbols/symbols/local_post_office';
import { symbol_local_see } from 'components/material-symbols/symbols/local_see';
import { symbol_local_shipping } from 'components/material-symbols/symbols/local_shipping';
import { symbol_local_taxi } from 'components/material-symbols/symbols/local_taxi';
import { symbol_location_away } from 'components/material-symbols/symbols/location_away';
import { symbol_location_chip } from 'components/material-symbols/symbols/location_chip';
import { symbol_location_city } from 'components/material-symbols/symbols/location_city';
import { symbol_location_disabled } from 'components/material-symbols/symbols/location_disabled';
import { symbol_location_home } from 'components/material-symbols/symbols/location_home';
import { symbol_location_off } from 'components/material-symbols/symbols/location_off';
import { symbol_location_on } from 'components/material-symbols/symbols/location_on';
import { symbol_location_searching } from 'components/material-symbols/symbols/location_searching';
import { symbol_lock } from 'components/material-symbols/symbols/lock';
import { symbol_lock_clock } from 'components/material-symbols/symbols/lock_clock';
import { symbol_lock_open } from 'components/material-symbols/symbols/lock_open';
import { symbol_lock_open_right } from 'components/material-symbols/symbols/lock_open_right';
import { symbol_lock_person } from 'components/material-symbols/symbols/lock_person';
import { symbol_lock_reset } from 'components/material-symbols/symbols/lock_reset';
import { symbol_login } from 'components/material-symbols/symbols/login';
import { symbol_logo_dev } from 'components/material-symbols/symbols/logo_dev';
import { symbol_logout } from 'components/material-symbols/symbols/logout';
import { symbol_looks } from 'components/material-symbols/symbols/looks';
import { symbol_looks_3 } from 'components/material-symbols/symbols/looks_3';
import { symbol_looks_4 } from 'components/material-symbols/symbols/looks_4';
import { symbol_looks_5 } from 'components/material-symbols/symbols/looks_5';
import { symbol_looks_6 } from 'components/material-symbols/symbols/looks_6';
import { symbol_looks_one } from 'components/material-symbols/symbols/looks_one';
import { symbol_looks_two } from 'components/material-symbols/symbols/looks_two';
import { symbol_loupe } from 'components/material-symbols/symbols/loupe';
import { symbol_low_density } from 'components/material-symbols/symbols/low_density';
import { symbol_low_priority } from 'components/material-symbols/symbols/low_priority';
import { symbol_lowercase } from 'components/material-symbols/symbols/lowercase';
import { symbol_loyalty } from 'components/material-symbols/symbols/loyalty';
import { symbol_lte_mobiledata } from 'components/material-symbols/symbols/lte_mobiledata';
import { symbol_lte_mobiledata_badge } from 'components/material-symbols/symbols/lte_mobiledata_badge';
import { symbol_lte_plus_mobiledata } from 'components/material-symbols/symbols/lte_plus_mobiledata';
import { symbol_lte_plus_mobiledata_badge } from 'components/material-symbols/symbols/lte_plus_mobiledata_badge';
import { symbol_luggage } from 'components/material-symbols/symbols/luggage';
import { symbol_lunch_dining } from 'components/material-symbols/symbols/lunch_dining';
import { symbol_lyrics } from 'components/material-symbols/symbols/lyrics';
import { symbol_macro_auto } from 'components/material-symbols/symbols/macro_auto';
import { symbol_macro_off } from 'components/material-symbols/symbols/macro_off';
import { symbol_magnification_large } from 'components/material-symbols/symbols/magnification_large';
import { symbol_magnification_small } from 'components/material-symbols/symbols/magnification_small';
import { symbol_magnify_docked } from 'components/material-symbols/symbols/magnify_docked';
import { symbol_magnify_fullscreen } from 'components/material-symbols/symbols/magnify_fullscreen';
import { symbol_mail } from 'components/material-symbols/symbols/mail';
import { symbol_mail_lock } from 'components/material-symbols/symbols/mail_lock';
import { symbol_mail_off } from 'components/material-symbols/symbols/mail_off';
import { symbol_male } from 'components/material-symbols/symbols/male';
import { symbol_man } from 'components/material-symbols/symbols/man';
import { symbol_man_2 } from 'components/material-symbols/symbols/man_2';
import { symbol_man_3 } from 'components/material-symbols/symbols/man_3';
import { symbol_man_4 } from 'components/material-symbols/symbols/man_4';
import { symbol_manage_accounts } from 'components/material-symbols/symbols/manage_accounts';
import { symbol_manage_history } from 'components/material-symbols/symbols/manage_history';
import { symbol_manage_search } from 'components/material-symbols/symbols/manage_search';
import { symbol_manga } from 'components/material-symbols/symbols/manga';
import { symbol_manufacturing } from 'components/material-symbols/symbols/manufacturing';
import { symbol_map } from 'components/material-symbols/symbols/map';
import { symbol_maps_ugc } from 'components/material-symbols/symbols/maps_ugc';
import { symbol_margin } from 'components/material-symbols/symbols/margin';
import { symbol_mark_as_unread } from 'components/material-symbols/symbols/mark_as_unread';
import { symbol_mark_chat_read } from 'components/material-symbols/symbols/mark_chat_read';
import { symbol_mark_chat_unread } from 'components/material-symbols/symbols/mark_chat_unread';
import { symbol_mark_email_read } from 'components/material-symbols/symbols/mark_email_read';
import { symbol_mark_email_unread } from 'components/material-symbols/symbols/mark_email_unread';
import { symbol_mark_unread_chat_alt } from 'components/material-symbols/symbols/mark_unread_chat_alt';
import { symbol_markdown } from 'components/material-symbols/symbols/markdown';
import { symbol_markdown_copy } from 'components/material-symbols/symbols/markdown_copy';
import { symbol_markdown_paste } from 'components/material-symbols/symbols/markdown_paste';
import { symbol_markunread_mailbox } from 'components/material-symbols/symbols/markunread_mailbox';
import { symbol_masked_transitions } from 'components/material-symbols/symbols/masked_transitions';
import { symbol_masks } from 'components/material-symbols/symbols/masks';
import { symbol_match_case } from 'components/material-symbols/symbols/match_case';
import { symbol_match_word } from 'components/material-symbols/symbols/match_word';
import { symbol_matter } from 'components/material-symbols/symbols/matter';
import { symbol_maximize } from 'components/material-symbols/symbols/maximize';
import { symbol_measuring_tape } from 'components/material-symbols/symbols/measuring_tape';
import { symbol_media_bluetooth_off } from 'components/material-symbols/symbols/media_bluetooth_off';
import { symbol_media_bluetooth_on } from 'components/material-symbols/symbols/media_bluetooth_on';
import { symbol_media_link } from 'components/material-symbols/symbols/media_link';
import { symbol_media_output } from 'components/material-symbols/symbols/media_output';
import { symbol_media_output_off } from 'components/material-symbols/symbols/media_output_off';
import { symbol_mediation } from 'components/material-symbols/symbols/mediation';
import { symbol_medical_information } from 'components/material-symbols/symbols/medical_information';
import { symbol_medical_mask } from 'components/material-symbols/symbols/medical_mask';
import { symbol_medical_services } from 'components/material-symbols/symbols/medical_services';
import { symbol_medication } from 'components/material-symbols/symbols/medication';
import { symbol_medication_liquid } from 'components/material-symbols/symbols/medication_liquid';
import { symbol_meeting_room } from 'components/material-symbols/symbols/meeting_room';
import { symbol_memory } from 'components/material-symbols/symbols/memory';
import { symbol_memory_alt } from 'components/material-symbols/symbols/memory_alt';
import { symbol_menstrual_health } from 'components/material-symbols/symbols/menstrual_health';
import { symbol_menu } from 'components/material-symbols/symbols/menu';
import { symbol_menu_book } from 'components/material-symbols/symbols/menu_book';
import { symbol_menu_open } from 'components/material-symbols/symbols/menu_open';
import { symbol_merge } from 'components/material-symbols/symbols/merge';
import { symbol_merge_type } from 'components/material-symbols/symbols/merge_type';
import { symbol_metabolism } from 'components/material-symbols/symbols/metabolism';
import { symbol_mfg_nest_yale_lock } from 'components/material-symbols/symbols/mfg_nest_yale_lock';
import { symbol_mic } from 'components/material-symbols/symbols/mic';
import { symbol_mic_double } from 'components/material-symbols/symbols/mic_double';
import { symbol_mic_external_off } from 'components/material-symbols/symbols/mic_external_off';
import { symbol_mic_external_on } from 'components/material-symbols/symbols/mic_external_on';
import { symbol_mic_off } from 'components/material-symbols/symbols/mic_off';
import { symbol_microbiology } from 'components/material-symbols/symbols/microbiology';
import { symbol_microwave } from 'components/material-symbols/symbols/microwave';
import { symbol_microwave_gen } from 'components/material-symbols/symbols/microwave_gen';
import { symbol_military_tech } from 'components/material-symbols/symbols/military_tech';
import { symbol_mimo } from 'components/material-symbols/symbols/mimo';
import { symbol_mimo_disconnect } from 'components/material-symbols/symbols/mimo_disconnect';
import { symbol_mindfulness } from 'components/material-symbols/symbols/mindfulness';
import { symbol_minimize } from 'components/material-symbols/symbols/minimize';
import { symbol_minor_crash } from 'components/material-symbols/symbols/minor_crash';
import { symbol_mintmark } from 'components/material-symbols/symbols/mintmark';
import { symbol_missed_video_call } from 'components/material-symbols/symbols/missed_video_call';
import { symbol_missing_controller } from 'components/material-symbols/symbols/missing_controller';
import { symbol_mist } from 'components/material-symbols/symbols/mist';
import { symbol_mitre } from 'components/material-symbols/symbols/mitre';
import { symbol_mixture_med } from 'components/material-symbols/symbols/mixture_med';
import { symbol_mms } from 'components/material-symbols/symbols/mms';
import { symbol_mobile_friendly } from 'components/material-symbols/symbols/mobile_friendly';
import { symbol_mobile_off } from 'components/material-symbols/symbols/mobile_off';
import { symbol_mobile_screen_share } from 'components/material-symbols/symbols/mobile_screen_share';
import { symbol_mobiledata_off } from 'components/material-symbols/symbols/mobiledata_off';
import { symbol_mode_comment } from 'components/material-symbols/symbols/mode_comment';
import { symbol_mode_cool } from 'components/material-symbols/symbols/mode_cool';
import { symbol_mode_cool_off } from 'components/material-symbols/symbols/mode_cool_off';
import { symbol_mode_dual } from 'components/material-symbols/symbols/mode_dual';
import { symbol_mode_fan } from 'components/material-symbols/symbols/mode_fan';
import { symbol_mode_fan_off } from 'components/material-symbols/symbols/mode_fan_off';
import { symbol_mode_heat } from 'components/material-symbols/symbols/mode_heat';
import { symbol_mode_heat_cool } from 'components/material-symbols/symbols/mode_heat_cool';
import { symbol_mode_heat_off } from 'components/material-symbols/symbols/mode_heat_off';
import { symbol_mode_night } from 'components/material-symbols/symbols/mode_night';
import { symbol_mode_of_travel } from 'components/material-symbols/symbols/mode_of_travel';
import { symbol_mode_off_on } from 'components/material-symbols/symbols/mode_off_on';
import { symbol_mode_standby } from 'components/material-symbols/symbols/mode_standby';
import { symbol_model_training } from 'components/material-symbols/symbols/model_training';
import { symbol_monetization_on } from 'components/material-symbols/symbols/monetization_on';
import { symbol_money } from 'components/material-symbols/symbols/money';
import { symbol_money_off } from 'components/material-symbols/symbols/money_off';
import { symbol_monitor } from 'components/material-symbols/symbols/monitor';
import { symbol_monitor_heart } from 'components/material-symbols/symbols/monitor_heart';
import { symbol_monitor_weight } from 'components/material-symbols/symbols/monitor_weight';
import { symbol_monitor_weight_gain } from 'components/material-symbols/symbols/monitor_weight_gain';
import { symbol_monitor_weight_loss } from 'components/material-symbols/symbols/monitor_weight_loss';
import { symbol_monitoring } from 'components/material-symbols/symbols/monitoring';
import { symbol_monochrome_photos } from 'components/material-symbols/symbols/monochrome_photos';
import { symbol_mood } from 'components/material-symbols/symbols/mood';
import { symbol_mood_bad } from 'components/material-symbols/symbols/mood_bad';
import { symbol_mop } from 'components/material-symbols/symbols/mop';
import { symbol_more } from 'components/material-symbols/symbols/more';
import { symbol_more_down } from 'components/material-symbols/symbols/more_down';
import { symbol_more_horiz } from 'components/material-symbols/symbols/more_horiz';
import { symbol_more_time } from 'components/material-symbols/symbols/more_time';
import { symbol_more_up } from 'components/material-symbols/symbols/more_up';
import { symbol_more_vert } from 'components/material-symbols/symbols/more_vert';
import { symbol_mosque } from 'components/material-symbols/symbols/mosque';
import { symbol_motion_blur } from 'components/material-symbols/symbols/motion_blur';
import { symbol_motion_mode } from 'components/material-symbols/symbols/motion_mode';
import { symbol_motion_photos_auto } from 'components/material-symbols/symbols/motion_photos_auto';
import { symbol_motion_photos_off } from 'components/material-symbols/symbols/motion_photos_off';
import { symbol_motion_photos_on } from 'components/material-symbols/symbols/motion_photos_on';
import { symbol_motion_photos_paused } from 'components/material-symbols/symbols/motion_photos_paused';
import { symbol_motion_sensor_active } from 'components/material-symbols/symbols/motion_sensor_active';
import { symbol_motion_sensor_alert } from 'components/material-symbols/symbols/motion_sensor_alert';
import { symbol_motion_sensor_idle } from 'components/material-symbols/symbols/motion_sensor_idle';
import { symbol_motion_sensor_urgent } from 'components/material-symbols/symbols/motion_sensor_urgent';
import { symbol_motorcycle } from 'components/material-symbols/symbols/motorcycle';
import { symbol_mountain_flag } from 'components/material-symbols/symbols/mountain_flag';
import { symbol_mouse } from 'components/material-symbols/symbols/mouse';
import { symbol_mouse_lock } from 'components/material-symbols/symbols/mouse_lock';
import { symbol_mouse_lock_off } from 'components/material-symbols/symbols/mouse_lock_off';
import { symbol_move } from 'components/material-symbols/symbols/move';
import { symbol_move_down } from 'components/material-symbols/symbols/move_down';
import { symbol_move_group } from 'components/material-symbols/symbols/move_group';
import { symbol_move_item } from 'components/material-symbols/symbols/move_item';
import { symbol_move_location } from 'components/material-symbols/symbols/move_location';
import { symbol_move_selection_down } from 'components/material-symbols/symbols/move_selection_down';
import { symbol_move_selection_left } from 'components/material-symbols/symbols/move_selection_left';
import { symbol_move_selection_right } from 'components/material-symbols/symbols/move_selection_right';
import { symbol_move_selection_up } from 'components/material-symbols/symbols/move_selection_up';
import { symbol_move_to_inbox } from 'components/material-symbols/symbols/move_to_inbox';
import { symbol_move_up } from 'components/material-symbols/symbols/move_up';
import { symbol_moved_location } from 'components/material-symbols/symbols/moved_location';
import { symbol_movie } from 'components/material-symbols/symbols/movie';
import { symbol_movie_edit } from 'components/material-symbols/symbols/movie_edit';
import { symbol_movie_info } from 'components/material-symbols/symbols/movie_info';
import { symbol_movie_off } from 'components/material-symbols/symbols/movie_off';
import { symbol_moving } from 'components/material-symbols/symbols/moving';
import { symbol_moving_beds } from 'components/material-symbols/symbols/moving_beds';
import { symbol_moving_ministry } from 'components/material-symbols/symbols/moving_ministry';
import { symbol_mp } from 'components/material-symbols/symbols/mp';
import { symbol_multicooker } from 'components/material-symbols/symbols/multicooker';
import { symbol_multiline_chart } from 'components/material-symbols/symbols/multiline_chart';
import { symbol_multiple_stop } from 'components/material-symbols/symbols/multiple_stop';
import { symbol_museum } from 'components/material-symbols/symbols/museum';
import { symbol_music_cast } from 'components/material-symbols/symbols/music_cast';
import { symbol_music_note } from 'components/material-symbols/symbols/music_note';
import { symbol_music_off } from 'components/material-symbols/symbols/music_off';
import { symbol_music_video } from 'components/material-symbols/symbols/music_video';
import { symbol_my_location } from 'components/material-symbols/symbols/my_location';
import { symbol_mystery } from 'components/material-symbols/symbols/mystery';
import { symbol_nat } from 'components/material-symbols/symbols/nat';
import { symbol_nature } from 'components/material-symbols/symbols/nature';
import { symbol_nature_people } from 'components/material-symbols/symbols/nature_people';
import { symbol_navigate_before } from 'components/material-symbols/symbols/navigate_before';
import { symbol_navigate_next } from 'components/material-symbols/symbols/navigate_next';
import { symbol_navigation } from 'components/material-symbols/symbols/navigation';
import { symbol_near_me } from 'components/material-symbols/symbols/near_me';
import { symbol_near_me_disabled } from 'components/material-symbols/symbols/near_me_disabled';
import { symbol_nearby } from 'components/material-symbols/symbols/nearby';
import { symbol_nearby_error } from 'components/material-symbols/symbols/nearby_error';
import { symbol_nearby_off } from 'components/material-symbols/symbols/nearby_off';
import { symbol_nephrology } from 'components/material-symbols/symbols/nephrology';
import { symbol_nest_audio } from 'components/material-symbols/symbols/nest_audio';
import { symbol_nest_cam_floodlight } from 'components/material-symbols/symbols/nest_cam_floodlight';
import { symbol_nest_cam_indoor } from 'components/material-symbols/symbols/nest_cam_indoor';
import { symbol_nest_cam_iq } from 'components/material-symbols/symbols/nest_cam_iq';
import { symbol_nest_cam_iq_outdoor } from 'components/material-symbols/symbols/nest_cam_iq_outdoor';
import { symbol_nest_cam_magnet_mount } from 'components/material-symbols/symbols/nest_cam_magnet_mount';
import { symbol_nest_cam_outdoor } from 'components/material-symbols/symbols/nest_cam_outdoor';
import { symbol_nest_cam_stand } from 'components/material-symbols/symbols/nest_cam_stand';
import { symbol_nest_cam_wall_mount } from 'components/material-symbols/symbols/nest_cam_wall_mount';
import { symbol_nest_cam_wired_stand } from 'components/material-symbols/symbols/nest_cam_wired_stand';
import { symbol_nest_clock_farsight_analog } from 'components/material-symbols/symbols/nest_clock_farsight_analog';
import { symbol_nest_clock_farsight_digital } from 'components/material-symbols/symbols/nest_clock_farsight_digital';
import { symbol_nest_connect } from 'components/material-symbols/symbols/nest_connect';
import { symbol_nest_detect } from 'components/material-symbols/symbols/nest_detect';
import { symbol_nest_display } from 'components/material-symbols/symbols/nest_display';
import { symbol_nest_display_max } from 'components/material-symbols/symbols/nest_display_max';
import { symbol_nest_doorbell_visitor } from 'components/material-symbols/symbols/nest_doorbell_visitor';
import { symbol_nest_eco_leaf } from 'components/material-symbols/symbols/nest_eco_leaf';
import { symbol_nest_farsight_weather } from 'components/material-symbols/symbols/nest_farsight_weather';
import { symbol_nest_found_savings } from 'components/material-symbols/symbols/nest_found_savings';
import { symbol_nest_gale_wifi } from 'components/material-symbols/symbols/nest_gale_wifi';
import { symbol_nest_heat_link_e } from 'components/material-symbols/symbols/nest_heat_link_e';
import { symbol_nest_heat_link_gen_3 } from 'components/material-symbols/symbols/nest_heat_link_gen_3';
import { symbol_nest_hello_doorbell } from 'components/material-symbols/symbols/nest_hello_doorbell';
import { symbol_nest_mini } from 'components/material-symbols/symbols/nest_mini';
import { symbol_nest_multi_room } from 'components/material-symbols/symbols/nest_multi_room';
import { symbol_nest_protect } from 'components/material-symbols/symbols/nest_protect';
import { symbol_nest_remote } from 'components/material-symbols/symbols/nest_remote';
import { symbol_nest_remote_comfort_sensor } from 'components/material-symbols/symbols/nest_remote_comfort_sensor';
import { symbol_nest_secure_alarm } from 'components/material-symbols/symbols/nest_secure_alarm';
import { symbol_nest_sunblock } from 'components/material-symbols/symbols/nest_sunblock';
import { symbol_nest_tag } from 'components/material-symbols/symbols/nest_tag';
import { symbol_nest_thermostat } from 'components/material-symbols/symbols/nest_thermostat';
import { symbol_nest_thermostat_e_eu } from 'components/material-symbols/symbols/nest_thermostat_e_eu';
import { symbol_nest_thermostat_gen_3 } from 'components/material-symbols/symbols/nest_thermostat_gen_3';
import { symbol_nest_thermostat_sensor } from 'components/material-symbols/symbols/nest_thermostat_sensor';
import { symbol_nest_thermostat_sensor_eu } from 'components/material-symbols/symbols/nest_thermostat_sensor_eu';
import { symbol_nest_thermostat_zirconium_eu } from 'components/material-symbols/symbols/nest_thermostat_zirconium_eu';
import { symbol_nest_true_radiant } from 'components/material-symbols/symbols/nest_true_radiant';
import { symbol_nest_wake_on_approach } from 'components/material-symbols/symbols/nest_wake_on_approach';
import { symbol_nest_wake_on_press } from 'components/material-symbols/symbols/nest_wake_on_press';
import { symbol_nest_wifi_point } from 'components/material-symbols/symbols/nest_wifi_point';
import { symbol_nest_wifi_pro } from 'components/material-symbols/symbols/nest_wifi_pro';
import { symbol_nest_wifi_pro_2 } from 'components/material-symbols/symbols/nest_wifi_pro_2';
import { symbol_nest_wifi_router } from 'components/material-symbols/symbols/nest_wifi_router';
import { symbol_network_cell } from 'components/material-symbols/symbols/network_cell';
import { symbol_network_check } from 'components/material-symbols/symbols/network_check';
import { symbol_network_intelligence_history } from 'components/material-symbols/symbols/network_intelligence_history';
import { symbol_network_intelligence_update } from 'components/material-symbols/symbols/network_intelligence_update';
import { symbol_network_locked } from 'components/material-symbols/symbols/network_locked';
import { symbol_network_manage } from 'components/material-symbols/symbols/network_manage';
import { symbol_network_node } from 'components/material-symbols/symbols/network_node';
import { symbol_network_ping } from 'components/material-symbols/symbols/network_ping';
import { symbol_network_wifi } from 'components/material-symbols/symbols/network_wifi';
import { symbol_network_wifi_1_bar } from 'components/material-symbols/symbols/network_wifi_1_bar';
import { symbol_network_wifi_1_bar_locked } from 'components/material-symbols/symbols/network_wifi_1_bar_locked';
import { symbol_network_wifi_2_bar } from 'components/material-symbols/symbols/network_wifi_2_bar';
import { symbol_network_wifi_2_bar_locked } from 'components/material-symbols/symbols/network_wifi_2_bar_locked';
import { symbol_network_wifi_3_bar } from 'components/material-symbols/symbols/network_wifi_3_bar';
import { symbol_network_wifi_3_bar_locked } from 'components/material-symbols/symbols/network_wifi_3_bar_locked';
import { symbol_network_wifi_locked } from 'components/material-symbols/symbols/network_wifi_locked';
import { symbol_neurology } from 'components/material-symbols/symbols/neurology';
import { symbol_new_label } from 'components/material-symbols/symbols/new_label';
import { symbol_new_releases } from 'components/material-symbols/symbols/new_releases';
import { symbol_new_window } from 'components/material-symbols/symbols/new_window';
import { symbol_news } from 'components/material-symbols/symbols/news';
import { symbol_newsmode } from 'components/material-symbols/symbols/newsmode';
import { symbol_newspaper } from 'components/material-symbols/symbols/newspaper';
import { symbol_newsstand } from 'components/material-symbols/symbols/newsstand';
import { symbol_next_plan } from 'components/material-symbols/symbols/next_plan';
import { symbol_next_week } from 'components/material-symbols/symbols/next_week';
import { symbol_nfc } from 'components/material-symbols/symbols/nfc';
import { symbol_night_shelter } from 'components/material-symbols/symbols/night_shelter';
import { symbol_night_sight_auto } from 'components/material-symbols/symbols/night_sight_auto';
import { symbol_night_sight_auto_off } from 'components/material-symbols/symbols/night_sight_auto_off';
import { symbol_night_sight_max } from 'components/material-symbols/symbols/night_sight_max';
import { symbol_nightlife } from 'components/material-symbols/symbols/nightlife';
import { symbol_nightlight } from 'components/material-symbols/symbols/nightlight';
import { symbol_nights_stay } from 'components/material-symbols/symbols/nights_stay';
import { symbol_no_accounts } from 'components/material-symbols/symbols/no_accounts';
import { symbol_no_adult_content } from 'components/material-symbols/symbols/no_adult_content';
import { symbol_no_backpack } from 'components/material-symbols/symbols/no_backpack';
import { symbol_no_crash } from 'components/material-symbols/symbols/no_crash';
import { symbol_no_drinks } from 'components/material-symbols/symbols/no_drinks';
import { symbol_no_encryption } from 'components/material-symbols/symbols/no_encryption';
import { symbol_no_flash } from 'components/material-symbols/symbols/no_flash';
import { symbol_no_food } from 'components/material-symbols/symbols/no_food';
import { symbol_no_luggage } from 'components/material-symbols/symbols/no_luggage';
import { symbol_no_meals } from 'components/material-symbols/symbols/no_meals';
import { symbol_no_meeting_room } from 'components/material-symbols/symbols/no_meeting_room';
import { symbol_no_photography } from 'components/material-symbols/symbols/no_photography';
import { symbol_no_sim } from 'components/material-symbols/symbols/no_sim';
import { symbol_no_sound } from 'components/material-symbols/symbols/no_sound';
import { symbol_no_stroller } from 'components/material-symbols/symbols/no_stroller';
import { symbol_no_transfer } from 'components/material-symbols/symbols/no_transfer';
import { symbol_noise_aware } from 'components/material-symbols/symbols/noise_aware';
import { symbol_noise_control_off } from 'components/material-symbols/symbols/noise_control_off';
import { symbol_noise_control_on } from 'components/material-symbols/symbols/noise_control_on';
import { symbol_nordic_walking } from 'components/material-symbols/symbols/nordic_walking';
import { symbol_north } from 'components/material-symbols/symbols/north';
import { symbol_north_east } from 'components/material-symbols/symbols/north_east';
import { symbol_north_west } from 'components/material-symbols/symbols/north_west';
import { symbol_not_accessible } from 'components/material-symbols/symbols/not_accessible';
import { symbol_not_accessible_forward } from 'components/material-symbols/symbols/not_accessible_forward';
import { symbol_not_listed_location } from 'components/material-symbols/symbols/not_listed_location';
import { symbol_not_started } from 'components/material-symbols/symbols/not_started';
import { symbol_note_add } from 'components/material-symbols/symbols/note_add';
import { symbol_note_alt } from 'components/material-symbols/symbols/note_alt';
import { symbol_note_stack } from 'components/material-symbols/symbols/note_stack';
import { symbol_note_stack_add } from 'components/material-symbols/symbols/note_stack_add';
import { symbol_notes } from 'components/material-symbols/symbols/notes';
import { symbol_notification_add } from 'components/material-symbols/symbols/notification_add';
import { symbol_notification_important } from 'components/material-symbols/symbols/notification_important';
import { symbol_notification_multiple } from 'components/material-symbols/symbols/notification_multiple';
import { symbol_notifications } from 'components/material-symbols/symbols/notifications';
import { symbol_notifications_active } from 'components/material-symbols/symbols/notifications_active';
import { symbol_notifications_off } from 'components/material-symbols/symbols/notifications_off';
import { symbol_notifications_paused } from 'components/material-symbols/symbols/notifications_paused';
import { symbol_notifications_unread } from 'components/material-symbols/symbols/notifications_unread';
import { symbol_numbers } from 'components/material-symbols/symbols/numbers';
import { symbol_nutrition } from 'components/material-symbols/symbols/nutrition';
import { symbol_ods } from 'components/material-symbols/symbols/ods';
import { symbol_odt } from 'components/material-symbols/symbols/odt';
import { symbol_offline_bolt } from 'components/material-symbols/symbols/offline_bolt';
import { symbol_offline_pin } from 'components/material-symbols/symbols/offline_pin';
import { symbol_offline_pin_off } from 'components/material-symbols/symbols/offline_pin_off';
import { symbol_offline_share } from 'components/material-symbols/symbols/offline_share';
import { symbol_oil_barrel } from 'components/material-symbols/symbols/oil_barrel';
import { symbol_on_device_training } from 'components/material-symbols/symbols/on_device_training';
import { symbol_on_hub_device } from 'components/material-symbols/symbols/on_hub_device';
import { symbol_oncology } from 'components/material-symbols/symbols/oncology';
import { symbol_online_prediction } from 'components/material-symbols/symbols/online_prediction';
import { symbol_onsen } from 'components/material-symbols/symbols/onsen';
import { symbol_opacity } from 'components/material-symbols/symbols/opacity';
import { symbol_open_in_browser } from 'components/material-symbols/symbols/open_in_browser';
import { symbol_open_in_full } from 'components/material-symbols/symbols/open_in_full';
import { symbol_open_in_new } from 'components/material-symbols/symbols/open_in_new';
import { symbol_open_in_new_down } from 'components/material-symbols/symbols/open_in_new_down';
import { symbol_open_in_new_off } from 'components/material-symbols/symbols/open_in_new_off';
import { symbol_open_in_phone } from 'components/material-symbols/symbols/open_in_phone';
import { symbol_open_jam } from 'components/material-symbols/symbols/open_jam';
import { symbol_open_run } from 'components/material-symbols/symbols/open_run';
import { symbol_open_with } from 'components/material-symbols/symbols/open_with';
import { symbol_ophthalmology } from 'components/material-symbols/symbols/ophthalmology';
import { symbol_oral_disease } from 'components/material-symbols/symbols/oral_disease';
import { symbol_order_approve } from 'components/material-symbols/symbols/order_approve';
import { symbol_order_play } from 'components/material-symbols/symbols/order_play';
import { symbol_orders } from 'components/material-symbols/symbols/orders';
import { symbol_orthopedics } from 'components/material-symbols/symbols/orthopedics';
import { symbol_other_admission } from 'components/material-symbols/symbols/other_admission';
import { symbol_other_houses } from 'components/material-symbols/symbols/other_houses';
import { symbol_outbound } from 'components/material-symbols/symbols/outbound';
import { symbol_outbox } from 'components/material-symbols/symbols/outbox';
import { symbol_outbox_alt } from 'components/material-symbols/symbols/outbox_alt';
import { symbol_outdoor_garden } from 'components/material-symbols/symbols/outdoor_garden';
import { symbol_outdoor_grill } from 'components/material-symbols/symbols/outdoor_grill';
import { symbol_outgoing_mail } from 'components/material-symbols/symbols/outgoing_mail';
import { symbol_outlet } from 'components/material-symbols/symbols/outlet';
import { symbol_outpatient } from 'components/material-symbols/symbols/outpatient';
import { symbol_outpatient_med } from 'components/material-symbols/symbols/outpatient_med';
import { symbol_output } from 'components/material-symbols/symbols/output';
import { symbol_output_circle } from 'components/material-symbols/symbols/output_circle';
import { symbol_oven } from 'components/material-symbols/symbols/oven';
import { symbol_oven_gen } from 'components/material-symbols/symbols/oven_gen';
import { symbol_overview } from 'components/material-symbols/symbols/overview';
import { symbol_overview_key } from 'components/material-symbols/symbols/overview_key';
import { symbol_oxygen_saturation } from 'components/material-symbols/symbols/oxygen_saturation';
import { symbol_p2p } from 'components/material-symbols/symbols/p2p';
import { symbol_pace } from 'components/material-symbols/symbols/pace';
import { symbol_pacemaker } from 'components/material-symbols/symbols/pacemaker';
import { symbol_package } from 'components/material-symbols/symbols/package';
import { symbol_package_2 } from 'components/material-symbols/symbols/package_2';
import { symbol_padding } from 'components/material-symbols/symbols/padding';
import { symbol_page_control } from 'components/material-symbols/symbols/page_control';
import { symbol_page_info } from 'components/material-symbols/symbols/page_info';
import { symbol_pageless } from 'components/material-symbols/symbols/pageless';
import { symbol_pages } from 'components/material-symbols/symbols/pages';
import { symbol_pageview } from 'components/material-symbols/symbols/pageview';
import { symbol_paid } from 'components/material-symbols/symbols/paid';
import { symbol_palette } from 'components/material-symbols/symbols/palette';
import { symbol_pallet } from 'components/material-symbols/symbols/pallet';
import { symbol_pan_tool } from 'components/material-symbols/symbols/pan_tool';
import { symbol_pan_tool_alt } from 'components/material-symbols/symbols/pan_tool_alt';
import { symbol_pan_zoom } from 'components/material-symbols/symbols/pan_zoom';
import { symbol_panorama } from 'components/material-symbols/symbols/panorama';
import { symbol_panorama_fish_eye } from 'components/material-symbols/symbols/panorama_fish_eye';
import { symbol_panorama_horizontal } from 'components/material-symbols/symbols/panorama_horizontal';
import { symbol_panorama_photosphere } from 'components/material-symbols/symbols/panorama_photosphere';
import { symbol_panorama_vertical } from 'components/material-symbols/symbols/panorama_vertical';
import { symbol_panorama_wide_angle } from 'components/material-symbols/symbols/panorama_wide_angle';
import { symbol_paragliding } from 'components/material-symbols/symbols/paragliding';
import { symbol_park } from 'components/material-symbols/symbols/park';
import { symbol_partly_cloudy_day } from 'components/material-symbols/symbols/partly_cloudy_day';
import { symbol_partly_cloudy_night } from 'components/material-symbols/symbols/partly_cloudy_night';
import { symbol_partner_exchange } from 'components/material-symbols/symbols/partner_exchange';
import { symbol_partner_reports } from 'components/material-symbols/symbols/partner_reports';
import { symbol_party_mode } from 'components/material-symbols/symbols/party_mode';
import { symbol_passkey } from 'components/material-symbols/symbols/passkey';
import { symbol_password } from 'components/material-symbols/symbols/password';
import { symbol_password_2 } from 'components/material-symbols/symbols/password_2';
import { symbol_password_2_off } from 'components/material-symbols/symbols/password_2_off';
import { symbol_patient_list } from 'components/material-symbols/symbols/patient_list';
import { symbol_pattern } from 'components/material-symbols/symbols/pattern';
import { symbol_pause } from 'components/material-symbols/symbols/pause';
import { symbol_pause_circle } from 'components/material-symbols/symbols/pause_circle';
import { symbol_pause_presentation } from 'components/material-symbols/symbols/pause_presentation';
import { symbol_payments } from 'components/material-symbols/symbols/payments';
import { symbol_pedal_bike } from 'components/material-symbols/symbols/pedal_bike';
import { symbol_pediatrics } from 'components/material-symbols/symbols/pediatrics';
import { symbol_pen_size_1 } from 'components/material-symbols/symbols/pen_size_1';
import { symbol_pen_size_2 } from 'components/material-symbols/symbols/pen_size_2';
import { symbol_pen_size_3 } from 'components/material-symbols/symbols/pen_size_3';
import { symbol_pen_size_4 } from 'components/material-symbols/symbols/pen_size_4';
import { symbol_pen_size_5 } from 'components/material-symbols/symbols/pen_size_5';
import { symbol_pending } from 'components/material-symbols/symbols/pending';
import { symbol_pending_actions } from 'components/material-symbols/symbols/pending_actions';
import { symbol_pentagon } from 'components/material-symbols/symbols/pentagon';
import { symbol_percent } from 'components/material-symbols/symbols/percent';
import { symbol_pergola } from 'components/material-symbols/symbols/pergola';
import { symbol_perm_camera_mic } from 'components/material-symbols/symbols/perm_camera_mic';
import { symbol_perm_contact_calendar } from 'components/material-symbols/symbols/perm_contact_calendar';
import { symbol_perm_data_setting } from 'components/material-symbols/symbols/perm_data_setting';
import { symbol_perm_device_information } from 'components/material-symbols/symbols/perm_device_information';
import { symbol_perm_media } from 'components/material-symbols/symbols/perm_media';
import { symbol_perm_phone_msg } from 'components/material-symbols/symbols/perm_phone_msg';
import { symbol_perm_scan_wifi } from 'components/material-symbols/symbols/perm_scan_wifi';
import { symbol_person } from 'components/material-symbols/symbols/person';
import { symbol_person_2 } from 'components/material-symbols/symbols/person_2';
import { symbol_person_3 } from 'components/material-symbols/symbols/person_3';
import { symbol_person_4 } from 'components/material-symbols/symbols/person_4';
import { symbol_person_add } from 'components/material-symbols/symbols/person_add';
import { symbol_person_add_disabled } from 'components/material-symbols/symbols/person_add_disabled';
import { symbol_person_alert } from 'components/material-symbols/symbols/person_alert';
import { symbol_person_apron } from 'components/material-symbols/symbols/person_apron';
import { symbol_person_book } from 'components/material-symbols/symbols/person_book';
import { symbol_person_cancel } from 'components/material-symbols/symbols/person_cancel';
import { symbol_person_celebrate } from 'components/material-symbols/symbols/person_celebrate';
import { symbol_person_check } from 'components/material-symbols/symbols/person_check';
import { symbol_person_edit } from 'components/material-symbols/symbols/person_edit';
import { symbol_person_off } from 'components/material-symbols/symbols/person_off';
import { symbol_person_pin } from 'components/material-symbols/symbols/person_pin';
import { symbol_person_pin_circle } from 'components/material-symbols/symbols/person_pin_circle';
import { symbol_person_play } from 'components/material-symbols/symbols/person_play';
import { symbol_person_raised_hand } from 'components/material-symbols/symbols/person_raised_hand';
import { symbol_person_remove } from 'components/material-symbols/symbols/person_remove';
import { symbol_person_search } from 'components/material-symbols/symbols/person_search';
import { symbol_personal_bag } from 'components/material-symbols/symbols/personal_bag';
import { symbol_personal_bag_off } from 'components/material-symbols/symbols/personal_bag_off';
import { symbol_personal_bag_question } from 'components/material-symbols/symbols/personal_bag_question';
import { symbol_personal_injury } from 'components/material-symbols/symbols/personal_injury';
import { symbol_personal_places } from 'components/material-symbols/symbols/personal_places';
import { symbol_pest_control } from 'components/material-symbols/symbols/pest_control';
import { symbol_pest_control_rodent } from 'components/material-symbols/symbols/pest_control_rodent';
import { symbol_pet_supplies } from 'components/material-symbols/symbols/pet_supplies';
import { symbol_pets } from 'components/material-symbols/symbols/pets';
import { symbol_phishing } from 'components/material-symbols/symbols/phishing';
import { symbol_phone_android } from 'components/material-symbols/symbols/phone_android';
import { symbol_phone_bluetooth_speaker } from 'components/material-symbols/symbols/phone_bluetooth_speaker';
import { symbol_phone_callback } from 'components/material-symbols/symbols/phone_callback';
import { symbol_phone_disabled } from 'components/material-symbols/symbols/phone_disabled';
import { symbol_phone_enabled } from 'components/material-symbols/symbols/phone_enabled';
import { symbol_phone_forwarded } from 'components/material-symbols/symbols/phone_forwarded';
import { symbol_phone_in_talk } from 'components/material-symbols/symbols/phone_in_talk';
import { symbol_phone_iphone } from 'components/material-symbols/symbols/phone_iphone';
import { symbol_phone_locked } from 'components/material-symbols/symbols/phone_locked';
import { symbol_phone_missed } from 'components/material-symbols/symbols/phone_missed';
import { symbol_phone_paused } from 'components/material-symbols/symbols/phone_paused';
import { symbol_phonelink_erase } from 'components/material-symbols/symbols/phonelink_erase';
import { symbol_phonelink_lock } from 'components/material-symbols/symbols/phonelink_lock';
import { symbol_phonelink_off } from 'components/material-symbols/symbols/phonelink_off';
import { symbol_phonelink_ring } from 'components/material-symbols/symbols/phonelink_ring';
import { symbol_phonelink_ring_off } from 'components/material-symbols/symbols/phonelink_ring_off';
import { symbol_phonelink_setup } from 'components/material-symbols/symbols/phonelink_setup';
import { symbol_photo } from 'components/material-symbols/symbols/photo';
import { symbol_photo_album } from 'components/material-symbols/symbols/photo_album';
import { symbol_photo_auto_merge } from 'components/material-symbols/symbols/photo_auto_merge';
import { symbol_photo_camera } from 'components/material-symbols/symbols/photo_camera';
import { symbol_photo_camera_back } from 'components/material-symbols/symbols/photo_camera_back';
import { symbol_photo_camera_front } from 'components/material-symbols/symbols/photo_camera_front';
import { symbol_photo_frame } from 'components/material-symbols/symbols/photo_frame';
import { symbol_photo_library } from 'components/material-symbols/symbols/photo_library';
import { symbol_photo_prints } from 'components/material-symbols/symbols/photo_prints';
import { symbol_photo_size_select_large } from 'components/material-symbols/symbols/photo_size_select_large';
import { symbol_photo_size_select_small } from 'components/material-symbols/symbols/photo_size_select_small';
import { symbol_php } from 'components/material-symbols/symbols/php';
import { symbol_physical_therapy } from 'components/material-symbols/symbols/physical_therapy';
import { symbol_piano } from 'components/material-symbols/symbols/piano';
import { symbol_piano_off } from 'components/material-symbols/symbols/piano_off';
import { symbol_picture_as_pdf } from 'components/material-symbols/symbols/picture_as_pdf';
import { symbol_picture_in_picture } from 'components/material-symbols/symbols/picture_in_picture';
import { symbol_picture_in_picture_alt } from 'components/material-symbols/symbols/picture_in_picture_alt';
import { symbol_picture_in_picture_center } from 'components/material-symbols/symbols/picture_in_picture_center';
import { symbol_picture_in_picture_large } from 'components/material-symbols/symbols/picture_in_picture_large';
import { symbol_picture_in_picture_medium } from 'components/material-symbols/symbols/picture_in_picture_medium';
import { symbol_picture_in_picture_mobile } from 'components/material-symbols/symbols/picture_in_picture_mobile';
import { symbol_picture_in_picture_off } from 'components/material-symbols/symbols/picture_in_picture_off';
import { symbol_picture_in_picture_small } from 'components/material-symbols/symbols/picture_in_picture_small';
import { symbol_pie_chart } from 'components/material-symbols/symbols/pie_chart';
import { symbol_pill } from 'components/material-symbols/symbols/pill';
import { symbol_pill_off } from 'components/material-symbols/symbols/pill_off';
import { symbol_pin } from 'components/material-symbols/symbols/pin';
import { symbol_pin_drop } from 'components/material-symbols/symbols/pin_drop';
import { symbol_pin_end } from 'components/material-symbols/symbols/pin_end';
import { symbol_pin_invoke } from 'components/material-symbols/symbols/pin_invoke';
import { symbol_pinch } from 'components/material-symbols/symbols/pinch';
import { symbol_pinch_zoom_in } from 'components/material-symbols/symbols/pinch_zoom_in';
import { symbol_pinch_zoom_out } from 'components/material-symbols/symbols/pinch_zoom_out';
import { symbol_pip } from 'components/material-symbols/symbols/pip';
import { symbol_pip_exit } from 'components/material-symbols/symbols/pip_exit';
import { symbol_pivot_table_chart } from 'components/material-symbols/symbols/pivot_table_chart';
import { symbol_place_item } from 'components/material-symbols/symbols/place_item';
import { symbol_plagiarism } from 'components/material-symbols/symbols/plagiarism';
import { symbol_planner_banner_ad_pt } from 'components/material-symbols/symbols/planner_banner_ad_pt';
import { symbol_planner_review } from 'components/material-symbols/symbols/planner_review';
import { symbol_play_arrow } from 'components/material-symbols/symbols/play_arrow';
import { symbol_play_circle } from 'components/material-symbols/symbols/play_circle';
import { symbol_play_disabled } from 'components/material-symbols/symbols/play_disabled';
import { symbol_play_for_work } from 'components/material-symbols/symbols/play_for_work';
import { symbol_play_lesson } from 'components/material-symbols/symbols/play_lesson';
import { symbol_play_pause } from 'components/material-symbols/symbols/play_pause';
import { symbol_playing_cards } from 'components/material-symbols/symbols/playing_cards';
import { symbol_playlist_add } from 'components/material-symbols/symbols/playlist_add';
import { symbol_playlist_add_check } from 'components/material-symbols/symbols/playlist_add_check';
import { symbol_playlist_add_check_circle } from 'components/material-symbols/symbols/playlist_add_check_circle';
import { symbol_playlist_add_circle } from 'components/material-symbols/symbols/playlist_add_circle';
import { symbol_playlist_play } from 'components/material-symbols/symbols/playlist_play';
import { symbol_playlist_remove } from 'components/material-symbols/symbols/playlist_remove';
import { symbol_plumbing } from 'components/material-symbols/symbols/plumbing';
import { symbol_podcasts } from 'components/material-symbols/symbols/podcasts';
import { symbol_podiatry } from 'components/material-symbols/symbols/podiatry';
import { symbol_podium } from 'components/material-symbols/symbols/podium';
import { symbol_point_of_sale } from 'components/material-symbols/symbols/point_of_sale';
import { symbol_point_scan } from 'components/material-symbols/symbols/point_scan';
import { symbol_poker_chip } from 'components/material-symbols/symbols/poker_chip';
import { symbol_policy } from 'components/material-symbols/symbols/policy';
import { symbol_polyline } from 'components/material-symbols/symbols/polyline';
import { symbol_polymer } from 'components/material-symbols/symbols/polymer';
import { symbol_pool } from 'components/material-symbols/symbols/pool';
import { symbol_portable_wifi_off } from 'components/material-symbols/symbols/portable_wifi_off';
import { symbol_position_bottom_left } from 'components/material-symbols/symbols/position_bottom_left';
import { symbol_position_bottom_right } from 'components/material-symbols/symbols/position_bottom_right';
import { symbol_position_top_right } from 'components/material-symbols/symbols/position_top_right';
import { symbol_post } from 'components/material-symbols/symbols/post';
import { symbol_post_add } from 'components/material-symbols/symbols/post_add';
import { symbol_potted_plant } from 'components/material-symbols/symbols/potted_plant';
import { symbol_power } from 'components/material-symbols/symbols/power';
import { symbol_power_input } from 'components/material-symbols/symbols/power_input';
import { symbol_power_off } from 'components/material-symbols/symbols/power_off';
import { symbol_power_settings_new } from 'components/material-symbols/symbols/power_settings_new';
import { symbol_prayer_times } from 'components/material-symbols/symbols/prayer_times';
import { symbol_precision_manufacturing } from 'components/material-symbols/symbols/precision_manufacturing';
import { symbol_pregnancy } from 'components/material-symbols/symbols/pregnancy';
import { symbol_pregnant_woman } from 'components/material-symbols/symbols/pregnant_woman';
import { symbol_preliminary } from 'components/material-symbols/symbols/preliminary';
import { symbol_prescriptions } from 'components/material-symbols/symbols/prescriptions';
import { symbol_present_to_all } from 'components/material-symbols/symbols/present_to_all';
import { symbol_preview } from 'components/material-symbols/symbols/preview';
import { symbol_preview_off } from 'components/material-symbols/symbols/preview_off';
import { symbol_price_change } from 'components/material-symbols/symbols/price_change';
import { symbol_price_check } from 'components/material-symbols/symbols/price_check';
import { symbol_print } from 'components/material-symbols/symbols/print';
import { symbol_print_add } from 'components/material-symbols/symbols/print_add';
import { symbol_print_connect } from 'components/material-symbols/symbols/print_connect';
import { symbol_print_disabled } from 'components/material-symbols/symbols/print_disabled';
import { symbol_print_error } from 'components/material-symbols/symbols/print_error';
import { symbol_print_lock } from 'components/material-symbols/symbols/print_lock';
import { symbol_priority } from 'components/material-symbols/symbols/priority';
import { symbol_priority_high } from 'components/material-symbols/symbols/priority_high';
import { symbol_privacy } from 'components/material-symbols/symbols/privacy';
import { symbol_privacy_tip } from 'components/material-symbols/symbols/privacy_tip';
import { symbol_private_connectivity } from 'components/material-symbols/symbols/private_connectivity';
import { symbol_problem } from 'components/material-symbols/symbols/problem';
import { symbol_procedure } from 'components/material-symbols/symbols/procedure';
import { symbol_process_chart } from 'components/material-symbols/symbols/process_chart';
import { symbol_production_quantity_limits } from 'components/material-symbols/symbols/production_quantity_limits';
import { symbol_productivity } from 'components/material-symbols/symbols/productivity';
import { symbol_progress_activity } from 'components/material-symbols/symbols/progress_activity';
import { symbol_prompt_suggestion } from 'components/material-symbols/symbols/prompt_suggestion';
import { symbol_propane } from 'components/material-symbols/symbols/propane';
import { symbol_propane_tank } from 'components/material-symbols/symbols/propane_tank';
import { symbol_psychiatry } from 'components/material-symbols/symbols/psychiatry';
import { symbol_psychology } from 'components/material-symbols/symbols/psychology';
import { symbol_psychology_alt } from 'components/material-symbols/symbols/psychology_alt';
import { symbol_public } from 'components/material-symbols/symbols/public';
import { symbol_public_off } from 'components/material-symbols/symbols/public_off';
import { symbol_publish } from 'components/material-symbols/symbols/publish';
import { symbol_published_with_changes } from 'components/material-symbols/symbols/published_with_changes';
import { symbol_pulmonology } from 'components/material-symbols/symbols/pulmonology';
import { symbol_pulse_alert } from 'components/material-symbols/symbols/pulse_alert';
import { symbol_punch_clock } from 'components/material-symbols/symbols/punch_clock';
import { symbol_qr_code } from 'components/material-symbols/symbols/qr_code';
import { symbol_qr_code_2 } from 'components/material-symbols/symbols/qr_code_2';
import { symbol_qr_code_2_add } from 'components/material-symbols/symbols/qr_code_2_add';
import { symbol_qr_code_scanner } from 'components/material-symbols/symbols/qr_code_scanner';
import { symbol_query_stats } from 'components/material-symbols/symbols/query_stats';
import { symbol_question_exchange } from 'components/material-symbols/symbols/question_exchange';
import { symbol_question_mark } from 'components/material-symbols/symbols/question_mark';
import { symbol_queue_music } from 'components/material-symbols/symbols/queue_music';
import { symbol_queue_play_next } from 'components/material-symbols/symbols/queue_play_next';
import { symbol_quick_phrases } from 'components/material-symbols/symbols/quick_phrases';
import { symbol_quick_reference } from 'components/material-symbols/symbols/quick_reference';
import { symbol_quick_reference_all } from 'components/material-symbols/symbols/quick_reference_all';
import { symbol_quick_reorder } from 'components/material-symbols/symbols/quick_reorder';
import { symbol_quickreply } from 'components/material-symbols/symbols/quickreply';
import { symbol_quiet_time } from 'components/material-symbols/symbols/quiet_time';
import { symbol_quiet_time_active } from 'components/material-symbols/symbols/quiet_time_active';
import { symbol_quiz } from 'components/material-symbols/symbols/quiz';
import { symbol_r_mobiledata } from 'components/material-symbols/symbols/r_mobiledata';
import { symbol_radar } from 'components/material-symbols/symbols/radar';
import { symbol_radio } from 'components/material-symbols/symbols/radio';
import { symbol_radio_button_checked } from 'components/material-symbols/symbols/radio_button_checked';
import { symbol_radio_button_partial } from 'components/material-symbols/symbols/radio_button_partial';
import { symbol_radio_button_unchecked } from 'components/material-symbols/symbols/radio_button_unchecked';
import { symbol_radiology } from 'components/material-symbols/symbols/radiology';
import { symbol_railway_alert } from 'components/material-symbols/symbols/railway_alert';
import { symbol_rainy } from 'components/material-symbols/symbols/rainy';
import { symbol_rainy_heavy } from 'components/material-symbols/symbols/rainy_heavy';
import { symbol_rainy_light } from 'components/material-symbols/symbols/rainy_light';
import { symbol_rainy_snow } from 'components/material-symbols/symbols/rainy_snow';
import { symbol_ramen_dining } from 'components/material-symbols/symbols/ramen_dining';
import { symbol_ramp_left } from 'components/material-symbols/symbols/ramp_left';
import { symbol_ramp_right } from 'components/material-symbols/symbols/ramp_right';
import { symbol_range_hood } from 'components/material-symbols/symbols/range_hood';
import { symbol_rate_review } from 'components/material-symbols/symbols/rate_review';
import { symbol_raven } from 'components/material-symbols/symbols/raven';
import { symbol_raw_off } from 'components/material-symbols/symbols/raw_off';
import { symbol_raw_on } from 'components/material-symbols/symbols/raw_on';
import { symbol_read_more } from 'components/material-symbols/symbols/read_more';
import { symbol_readiness_score } from 'components/material-symbols/symbols/readiness_score';
import { symbol_real_estate_agent } from 'components/material-symbols/symbols/real_estate_agent';
import { symbol_rear_camera } from 'components/material-symbols/symbols/rear_camera';
import { symbol_rebase } from 'components/material-symbols/symbols/rebase';
import { symbol_rebase_edit } from 'components/material-symbols/symbols/rebase_edit';
import { symbol_receipt } from 'components/material-symbols/symbols/receipt';
import { symbol_receipt_long } from 'components/material-symbols/symbols/receipt_long';
import { symbol_recent_actors } from 'components/material-symbols/symbols/recent_actors';
import { symbol_recent_patient } from 'components/material-symbols/symbols/recent_patient';
import { symbol_recenter } from 'components/material-symbols/symbols/recenter';
import { symbol_recommend } from 'components/material-symbols/symbols/recommend';
import { symbol_record_voice_over } from 'components/material-symbols/symbols/record_voice_over';
import { symbol_rectangle } from 'components/material-symbols/symbols/rectangle';
import { symbol_recycling } from 'components/material-symbols/symbols/recycling';
import { symbol_redeem } from 'components/material-symbols/symbols/redeem';
import { symbol_redo } from 'components/material-symbols/symbols/redo';
import { symbol_reduce_capacity } from 'components/material-symbols/symbols/reduce_capacity';
import { symbol_refresh } from 'components/material-symbols/symbols/refresh';
import { symbol_regular_expression } from 'components/material-symbols/symbols/regular_expression';
import { symbol_relax } from 'components/material-symbols/symbols/relax';
import { symbol_release_alert } from 'components/material-symbols/symbols/release_alert';
import { symbol_remember_me } from 'components/material-symbols/symbols/remember_me';
import { symbol_reminder } from 'components/material-symbols/symbols/reminder';
import { symbol_remote_gen } from 'components/material-symbols/symbols/remote_gen';
import { symbol_remove } from 'components/material-symbols/symbols/remove';
import { symbol_remove_done } from 'components/material-symbols/symbols/remove_done';
import { symbol_remove_from_queue } from 'components/material-symbols/symbols/remove_from_queue';
import { symbol_remove_moderator } from 'components/material-symbols/symbols/remove_moderator';
import { symbol_remove_road } from 'components/material-symbols/symbols/remove_road';
import { symbol_remove_selection } from 'components/material-symbols/symbols/remove_selection';
import { symbol_remove_shopping_cart } from 'components/material-symbols/symbols/remove_shopping_cart';
import { symbol_reopen_window } from 'components/material-symbols/symbols/reopen_window';
import { symbol_reorder } from 'components/material-symbols/symbols/reorder';
import { symbol_repartition } from 'components/material-symbols/symbols/repartition';
import { symbol_repeat } from 'components/material-symbols/symbols/repeat';
import { symbol_repeat_on } from 'components/material-symbols/symbols/repeat_on';
import { symbol_repeat_one } from 'components/material-symbols/symbols/repeat_one';
import { symbol_repeat_one_on } from 'components/material-symbols/symbols/repeat_one_on';
import { symbol_replay } from 'components/material-symbols/symbols/replay';
import { symbol_replay_10 } from 'components/material-symbols/symbols/replay_10';
import { symbol_replay_30 } from 'components/material-symbols/symbols/replay_30';
import { symbol_replay_5 } from 'components/material-symbols/symbols/replay_5';
import { symbol_reply } from 'components/material-symbols/symbols/reply';
import { symbol_reply_all } from 'components/material-symbols/symbols/reply_all';
import { symbol_report } from 'components/material-symbols/symbols/report';
import { symbol_report_off } from 'components/material-symbols/symbols/report_off';
import { symbol_request_page } from 'components/material-symbols/symbols/request_page';
import { symbol_request_quote } from 'components/material-symbols/symbols/request_quote';
import { symbol_reset_image } from 'components/material-symbols/symbols/reset_image';
import { symbol_reset_tv } from 'components/material-symbols/symbols/reset_tv';
import { symbol_reset_wrench } from 'components/material-symbols/symbols/reset_wrench';
import { symbol_resize } from 'components/material-symbols/symbols/resize';
import { symbol_respiratory_rate } from 'components/material-symbols/symbols/respiratory_rate';
import { symbol_responsive_layout } from 'components/material-symbols/symbols/responsive_layout';
import { symbol_restart_alt } from 'components/material-symbols/symbols/restart_alt';
import { symbol_restaurant } from 'components/material-symbols/symbols/restaurant';
import { symbol_restaurant_menu } from 'components/material-symbols/symbols/restaurant_menu';
import { symbol_restore_from_trash } from 'components/material-symbols/symbols/restore_from_trash';
import { symbol_restore_page } from 'components/material-symbols/symbols/restore_page';
import { symbol_resume } from 'components/material-symbols/symbols/resume';
import { symbol_reviews } from 'components/material-symbols/symbols/reviews';
import { symbol_rewarded_ads } from 'components/material-symbols/symbols/rewarded_ads';
import { symbol_rheumatology } from 'components/material-symbols/symbols/rheumatology';
import { symbol_rib_cage } from 'components/material-symbols/symbols/rib_cage';
import { symbol_rice_bowl } from 'components/material-symbols/symbols/rice_bowl';
import { symbol_right_click } from 'components/material-symbols/symbols/right_click';
import { symbol_right_panel_close } from 'components/material-symbols/symbols/right_panel_close';
import { symbol_right_panel_open } from 'components/material-symbols/symbols/right_panel_open';
import { symbol_ring_volume } from 'components/material-symbols/symbols/ring_volume';
import { symbol_ripples } from 'components/material-symbols/symbols/ripples';
import { symbol_robot } from 'components/material-symbols/symbols/robot';
import { symbol_robot_2 } from 'components/material-symbols/symbols/robot_2';
import { symbol_rocket } from 'components/material-symbols/symbols/rocket';
import { symbol_rocket_launch } from 'components/material-symbols/symbols/rocket_launch';
import { symbol_roller_shades } from 'components/material-symbols/symbols/roller_shades';
import { symbol_roller_shades_closed } from 'components/material-symbols/symbols/roller_shades_closed';
import { symbol_roller_skating } from 'components/material-symbols/symbols/roller_skating';
import { symbol_roofing } from 'components/material-symbols/symbols/roofing';
import { symbol_room_preferences } from 'components/material-symbols/symbols/room_preferences';
import { symbol_room_service } from 'components/material-symbols/symbols/room_service';
import { symbol_rotate_90_degrees_ccw } from 'components/material-symbols/symbols/rotate_90_degrees_ccw';
import { symbol_rotate_90_degrees_cw } from 'components/material-symbols/symbols/rotate_90_degrees_cw';
import { symbol_rotate_left } from 'components/material-symbols/symbols/rotate_left';
import { symbol_rotate_right } from 'components/material-symbols/symbols/rotate_right';
import { symbol_roundabout_left } from 'components/material-symbols/symbols/roundabout_left';
import { symbol_roundabout_right } from 'components/material-symbols/symbols/roundabout_right';
import { symbol_rounded_corner } from 'components/material-symbols/symbols/rounded_corner';
import { symbol_route } from 'components/material-symbols/symbols/route';
import { symbol_router } from 'components/material-symbols/symbols/router';
import { symbol_routine } from 'components/material-symbols/symbols/routine';
import { symbol_rowing } from 'components/material-symbols/symbols/rowing';
import { symbol_rss_feed } from 'components/material-symbols/symbols/rss_feed';
import { symbol_rsvp } from 'components/material-symbols/symbols/rsvp';
import { symbol_rtt } from 'components/material-symbols/symbols/rtt';
import { symbol_rubric } from 'components/material-symbols/symbols/rubric';
import { symbol_rule } from 'components/material-symbols/symbols/rule';
import { symbol_rule_folder } from 'components/material-symbols/symbols/rule_folder';
import { symbol_rule_settings } from 'components/material-symbols/symbols/rule_settings';
import { symbol_run_circle } from 'components/material-symbols/symbols/run_circle';
import { symbol_running_with_errors } from 'components/material-symbols/symbols/running_with_errors';
import { symbol_rv_hookup } from 'components/material-symbols/symbols/rv_hookup';
import { symbol_safety_check } from 'components/material-symbols/symbols/safety_check';
import { symbol_safety_check_off } from 'components/material-symbols/symbols/safety_check_off';
import { symbol_safety_divider } from 'components/material-symbols/symbols/safety_divider';
import { symbol_sailing } from 'components/material-symbols/symbols/sailing';
import { symbol_salinity } from 'components/material-symbols/symbols/salinity';
import { symbol_sanitizer } from 'components/material-symbols/symbols/sanitizer';
import { symbol_satellite } from 'components/material-symbols/symbols/satellite';
import { symbol_satellite_alt } from 'components/material-symbols/symbols/satellite_alt';
import { symbol_sauna } from 'components/material-symbols/symbols/sauna';
import { symbol_save } from 'components/material-symbols/symbols/save';
import { symbol_save_as } from 'components/material-symbols/symbols/save_as';
import { symbol_saved_search } from 'components/material-symbols/symbols/saved_search';
import { symbol_savings } from 'components/material-symbols/symbols/savings';
import { symbol_scale } from 'components/material-symbols/symbols/scale';
import { symbol_scan } from 'components/material-symbols/symbols/scan';
import { symbol_scan_delete } from 'components/material-symbols/symbols/scan_delete';
import { symbol_scanner } from 'components/material-symbols/symbols/scanner';
import { symbol_scatter_plot } from 'components/material-symbols/symbols/scatter_plot';
import { symbol_scene } from 'components/material-symbols/symbols/scene';
import { symbol_schedule } from 'components/material-symbols/symbols/schedule';
import { symbol_schedule_send } from 'components/material-symbols/symbols/schedule_send';
import { symbol_schema } from 'components/material-symbols/symbols/schema';
import { symbol_school } from 'components/material-symbols/symbols/school';
import { symbol_science } from 'components/material-symbols/symbols/science';
import { symbol_science_off } from 'components/material-symbols/symbols/science_off';
import { symbol_score } from 'components/material-symbols/symbols/score';
import { symbol_scoreboard } from 'components/material-symbols/symbols/scoreboard';
import { symbol_screen_lock_landscape } from 'components/material-symbols/symbols/screen_lock_landscape';
import { symbol_screen_lock_portrait } from 'components/material-symbols/symbols/screen_lock_portrait';
import { symbol_screen_lock_rotation } from 'components/material-symbols/symbols/screen_lock_rotation';
import { symbol_screen_record } from 'components/material-symbols/symbols/screen_record';
import { symbol_screen_rotation } from 'components/material-symbols/symbols/screen_rotation';
import { symbol_screen_rotation_alt } from 'components/material-symbols/symbols/screen_rotation_alt';
import { symbol_screen_rotation_up } from 'components/material-symbols/symbols/screen_rotation_up';
import { symbol_screen_search_desktop } from 'components/material-symbols/symbols/screen_search_desktop';
import { symbol_screen_share } from 'components/material-symbols/symbols/screen_share';
import { symbol_screenshot } from 'components/material-symbols/symbols/screenshot';
import { symbol_screenshot_frame } from 'components/material-symbols/symbols/screenshot_frame';
import { symbol_screenshot_keyboard } from 'components/material-symbols/symbols/screenshot_keyboard';
import { symbol_screenshot_monitor } from 'components/material-symbols/symbols/screenshot_monitor';
import { symbol_screenshot_region } from 'components/material-symbols/symbols/screenshot_region';
import { symbol_screenshot_tablet } from 'components/material-symbols/symbols/screenshot_tablet';
import { symbol_scrollable_header } from 'components/material-symbols/symbols/scrollable_header';
import { symbol_scuba_diving } from 'components/material-symbols/symbols/scuba_diving';
import { symbol_sd } from 'components/material-symbols/symbols/sd';
import { symbol_sd_card } from 'components/material-symbols/symbols/sd_card';
import { symbol_sd_card_alert } from 'components/material-symbols/symbols/sd_card_alert';
import { symbol_sdk } from 'components/material-symbols/symbols/sdk';
import { symbol_search } from 'components/material-symbols/symbols/search';
import { symbol_search_check } from 'components/material-symbols/symbols/search_check';
import { symbol_search_hands_free } from 'components/material-symbols/symbols/search_hands_free';
import { symbol_search_insights } from 'components/material-symbols/symbols/search_insights';
import { symbol_search_off } from 'components/material-symbols/symbols/search_off';
import { symbol_security } from 'components/material-symbols/symbols/security';
import { symbol_security_key } from 'components/material-symbols/symbols/security_key';
import { symbol_security_update_good } from 'components/material-symbols/symbols/security_update_good';
import { symbol_security_update_warning } from 'components/material-symbols/symbols/security_update_warning';
import { symbol_segment } from 'components/material-symbols/symbols/segment';
import { symbol_select } from 'components/material-symbols/symbols/select';
import { symbol_select_all } from 'components/material-symbols/symbols/select_all';
import { symbol_select_check_box } from 'components/material-symbols/symbols/select_check_box';
import { symbol_select_to_speak } from 'components/material-symbols/symbols/select_to_speak';
import { symbol_select_window } from 'components/material-symbols/symbols/select_window';
import { symbol_select_window_2 } from 'components/material-symbols/symbols/select_window_2';
import { symbol_select_window_off } from 'components/material-symbols/symbols/select_window_off';
import { symbol_self_care } from 'components/material-symbols/symbols/self_care';
import { symbol_self_improvement } from 'components/material-symbols/symbols/self_improvement';
import { symbol_sell } from 'components/material-symbols/symbols/sell';
import { symbol_send } from 'components/material-symbols/symbols/send';
import { symbol_send_and_archive } from 'components/material-symbols/symbols/send_and_archive';
import { symbol_send_money } from 'components/material-symbols/symbols/send_money';
import { symbol_send_time_extension } from 'components/material-symbols/symbols/send_time_extension';
import { symbol_send_to_mobile } from 'components/material-symbols/symbols/send_to_mobile';
import { symbol_sensor_door } from 'components/material-symbols/symbols/sensor_door';
import { symbol_sensor_occupied } from 'components/material-symbols/symbols/sensor_occupied';
import { symbol_sensor_window } from 'components/material-symbols/symbols/sensor_window';
import { symbol_sensors } from 'components/material-symbols/symbols/sensors';
import { symbol_sensors_krx } from 'components/material-symbols/symbols/sensors_krx';
import { symbol_sensors_krx_off } from 'components/material-symbols/symbols/sensors_krx_off';
import { symbol_sensors_off } from 'components/material-symbols/symbols/sensors_off';
import { symbol_sentiment_calm } from 'components/material-symbols/symbols/sentiment_calm';
import { symbol_sentiment_content } from 'components/material-symbols/symbols/sentiment_content';
import { symbol_sentiment_dissatisfied } from 'components/material-symbols/symbols/sentiment_dissatisfied';
import { symbol_sentiment_excited } from 'components/material-symbols/symbols/sentiment_excited';
import { symbol_sentiment_extremely_dissatisfied } from 'components/material-symbols/symbols/sentiment_extremely_dissatisfied';
import { symbol_sentiment_frustrated } from 'components/material-symbols/symbols/sentiment_frustrated';
import { symbol_sentiment_neutral } from 'components/material-symbols/symbols/sentiment_neutral';
import { symbol_sentiment_sad } from 'components/material-symbols/symbols/sentiment_sad';
import { symbol_sentiment_satisfied } from 'components/material-symbols/symbols/sentiment_satisfied';
import { symbol_sentiment_stressed } from 'components/material-symbols/symbols/sentiment_stressed';
import { symbol_sentiment_very_dissatisfied } from 'components/material-symbols/symbols/sentiment_very_dissatisfied';
import { symbol_sentiment_very_satisfied } from 'components/material-symbols/symbols/sentiment_very_satisfied';
import { symbol_sentiment_worried } from 'components/material-symbols/symbols/sentiment_worried';
import { symbol_serif } from 'components/material-symbols/symbols/serif';
import { symbol_service_toolbox } from 'components/material-symbols/symbols/service_toolbox';
import { symbol_set_meal } from 'components/material-symbols/symbols/set_meal';
import { symbol_settings } from 'components/material-symbols/symbols/settings';
import { symbol_settings_accessibility } from 'components/material-symbols/symbols/settings_accessibility';
import { symbol_settings_account_box } from 'components/material-symbols/symbols/settings_account_box';
import { symbol_settings_alert } from 'components/material-symbols/symbols/settings_alert';
import { symbol_settings_applications } from 'components/material-symbols/symbols/settings_applications';
import { symbol_settings_b_roll } from 'components/material-symbols/symbols/settings_b_roll';
import { symbol_settings_backup_restore } from 'components/material-symbols/symbols/settings_backup_restore';
import { symbol_settings_bluetooth } from 'components/material-symbols/symbols/settings_bluetooth';
import { symbol_settings_brightness } from 'components/material-symbols/symbols/settings_brightness';
import { symbol_settings_cell } from 'components/material-symbols/symbols/settings_cell';
import { symbol_settings_cinematic_blur } from 'components/material-symbols/symbols/settings_cinematic_blur';
import { symbol_settings_ethernet } from 'components/material-symbols/symbols/settings_ethernet';
import { symbol_settings_heart } from 'components/material-symbols/symbols/settings_heart';
import { symbol_settings_input_antenna } from 'components/material-symbols/symbols/settings_input_antenna';
import { symbol_settings_input_component } from 'components/material-symbols/symbols/settings_input_component';
import { symbol_settings_input_hdmi } from 'components/material-symbols/symbols/settings_input_hdmi';
import { symbol_settings_input_svideo } from 'components/material-symbols/symbols/settings_input_svideo';
import { symbol_settings_motion_mode } from 'components/material-symbols/symbols/settings_motion_mode';
import { symbol_settings_night_sight } from 'components/material-symbols/symbols/settings_night_sight';
import { symbol_settings_overscan } from 'components/material-symbols/symbols/settings_overscan';
import { symbol_settings_panorama } from 'components/material-symbols/symbols/settings_panorama';
import { symbol_settings_phone } from 'components/material-symbols/symbols/settings_phone';
import { symbol_settings_photo_camera } from 'components/material-symbols/symbols/settings_photo_camera';
import { symbol_settings_power } from 'components/material-symbols/symbols/settings_power';
import { symbol_settings_remote } from 'components/material-symbols/symbols/settings_remote';
import { symbol_settings_slow_motion } from 'components/material-symbols/symbols/settings_slow_motion';
import { symbol_settings_system_daydream } from 'components/material-symbols/symbols/settings_system_daydream';
import { symbol_settings_timelapse } from 'components/material-symbols/symbols/settings_timelapse';
import { symbol_settings_video_camera } from 'components/material-symbols/symbols/settings_video_camera';
import { symbol_settings_voice } from 'components/material-symbols/symbols/settings_voice';
import { symbol_settop_component } from 'components/material-symbols/symbols/settop_component';
import { symbol_severe_cold } from 'components/material-symbols/symbols/severe_cold';
import { symbol_shadow } from 'components/material-symbols/symbols/shadow';
import { symbol_shadow_add } from 'components/material-symbols/symbols/shadow_add';
import { symbol_shadow_minus } from 'components/material-symbols/symbols/shadow_minus';
import { symbol_shape_line } from 'components/material-symbols/symbols/shape_line';
import { symbol_shapes } from 'components/material-symbols/symbols/shapes';
import { symbol_share } from 'components/material-symbols/symbols/share';
import { symbol_share_location } from 'components/material-symbols/symbols/share_location';
import { symbol_share_off } from 'components/material-symbols/symbols/share_off';
import { symbol_share_reviews } from 'components/material-symbols/symbols/share_reviews';
import { symbol_share_windows } from 'components/material-symbols/symbols/share_windows';
import { symbol_sheets_rtl } from 'components/material-symbols/symbols/sheets_rtl';
import { symbol_shelf_auto_hide } from 'components/material-symbols/symbols/shelf_auto_hide';
import { symbol_shelf_position } from 'components/material-symbols/symbols/shelf_position';
import { symbol_shelves } from 'components/material-symbols/symbols/shelves';
import { symbol_shield } from 'components/material-symbols/symbols/shield';
import { symbol_shield_lock } from 'components/material-symbols/symbols/shield_lock';
import { symbol_shield_locked } from 'components/material-symbols/symbols/shield_locked';
import { symbol_shield_moon } from 'components/material-symbols/symbols/shield_moon';
import { symbol_shield_person } from 'components/material-symbols/symbols/shield_person';
import { symbol_shield_question } from 'components/material-symbols/symbols/shield_question';
import { symbol_shield_with_heart } from 'components/material-symbols/symbols/shield_with_heart';
import { symbol_shield_with_house } from 'components/material-symbols/symbols/shield_with_house';
import { symbol_shift } from 'components/material-symbols/symbols/shift';
import { symbol_shift_lock } from 'components/material-symbols/symbols/shift_lock';
import { symbol_shop } from 'components/material-symbols/symbols/shop';
import { symbol_shop_two } from 'components/material-symbols/symbols/shop_two';
import { symbol_shopping_bag } from 'components/material-symbols/symbols/shopping_bag';
import { symbol_shopping_basket } from 'components/material-symbols/symbols/shopping_basket';
import { symbol_shopping_cart } from 'components/material-symbols/symbols/shopping_cart';
import { symbol_shopping_cart_checkout } from 'components/material-symbols/symbols/shopping_cart_checkout';
import { symbol_shopping_cart_off } from 'components/material-symbols/symbols/shopping_cart_off';
import { symbol_shoppingmode } from 'components/material-symbols/symbols/shoppingmode';
import { symbol_short_stay } from 'components/material-symbols/symbols/short_stay';
import { symbol_short_text } from 'components/material-symbols/symbols/short_text';
import { symbol_show_chart } from 'components/material-symbols/symbols/show_chart';
import { symbol_shower } from 'components/material-symbols/symbols/shower';
import { symbol_shuffle } from 'components/material-symbols/symbols/shuffle';
import { symbol_shuffle_on } from 'components/material-symbols/symbols/shuffle_on';
import { symbol_shutter_speed } from 'components/material-symbols/symbols/shutter_speed';
import { symbol_shutter_speed_add } from 'components/material-symbols/symbols/shutter_speed_add';
import { symbol_shutter_speed_minus } from 'components/material-symbols/symbols/shutter_speed_minus';
import { symbol_sick } from 'components/material-symbols/symbols/sick';
import { symbol_side_navigation } from 'components/material-symbols/symbols/side_navigation';
import { symbol_sign_language } from 'components/material-symbols/symbols/sign_language';
import { symbol_signal_cellular_0_bar } from 'components/material-symbols/symbols/signal_cellular_0_bar';
import { symbol_signal_cellular_1_bar } from 'components/material-symbols/symbols/signal_cellular_1_bar';
import { symbol_signal_cellular_2_bar } from 'components/material-symbols/symbols/signal_cellular_2_bar';
import { symbol_signal_cellular_3_bar } from 'components/material-symbols/symbols/signal_cellular_3_bar';
import { symbol_signal_cellular_4_bar } from 'components/material-symbols/symbols/signal_cellular_4_bar';
import { symbol_signal_cellular_add } from 'components/material-symbols/symbols/signal_cellular_add';
import { symbol_signal_cellular_alt } from 'components/material-symbols/symbols/signal_cellular_alt';
import { symbol_signal_cellular_alt_1_bar } from 'components/material-symbols/symbols/signal_cellular_alt_1_bar';
import { symbol_signal_cellular_alt_2_bar } from 'components/material-symbols/symbols/signal_cellular_alt_2_bar';
import { symbol_signal_cellular_connected_no_internet_0_bar } from 'components/material-symbols/symbols/signal_cellular_connected_no_internet_0_bar';
import { symbol_signal_cellular_connected_no_internet_4_bar } from 'components/material-symbols/symbols/signal_cellular_connected_no_internet_4_bar';
import { symbol_signal_cellular_nodata } from 'components/material-symbols/symbols/signal_cellular_nodata';
import { symbol_signal_cellular_null } from 'components/material-symbols/symbols/signal_cellular_null';
import { symbol_signal_cellular_off } from 'components/material-symbols/symbols/signal_cellular_off';
import { symbol_signal_cellular_pause } from 'components/material-symbols/symbols/signal_cellular_pause';
import { symbol_signal_disconnected } from 'components/material-symbols/symbols/signal_disconnected';
import { symbol_signal_wifi_0_bar } from 'components/material-symbols/symbols/signal_wifi_0_bar';
import { symbol_signal_wifi_4_bar } from 'components/material-symbols/symbols/signal_wifi_4_bar';
import { symbol_signal_wifi_bad } from 'components/material-symbols/symbols/signal_wifi_bad';
import { symbol_signal_wifi_off } from 'components/material-symbols/symbols/signal_wifi_off';
import { symbol_signal_wifi_statusbar_not_connected } from 'components/material-symbols/symbols/signal_wifi_statusbar_not_connected';
import { symbol_signal_wifi_statusbar_null } from 'components/material-symbols/symbols/signal_wifi_statusbar_null';
import { symbol_signature } from 'components/material-symbols/symbols/signature';
import { symbol_signpost } from 'components/material-symbols/symbols/signpost';
import { symbol_sim_card } from 'components/material-symbols/symbols/sim_card';
import { symbol_sim_card_download } from 'components/material-symbols/symbols/sim_card_download';
import { symbol_single_bed } from 'components/material-symbols/symbols/single_bed';
import { symbol_sip } from 'components/material-symbols/symbols/sip';
import { symbol_skateboarding } from 'components/material-symbols/symbols/skateboarding';
import { symbol_skeleton } from 'components/material-symbols/symbols/skeleton';
import { symbol_skillet } from 'components/material-symbols/symbols/skillet';
import { symbol_skillet_cooktop } from 'components/material-symbols/symbols/skillet_cooktop';
import { symbol_skip_next } from 'components/material-symbols/symbols/skip_next';
import { symbol_skip_previous } from 'components/material-symbols/symbols/skip_previous';
import { symbol_skull } from 'components/material-symbols/symbols/skull';
import { symbol_slab_serif } from 'components/material-symbols/symbols/slab_serif';
import { symbol_sledding } from 'components/material-symbols/symbols/sledding';
import { symbol_sleep_score } from 'components/material-symbols/symbols/sleep_score';
import { symbol_slide_library } from 'components/material-symbols/symbols/slide_library';
import { symbol_sliders } from 'components/material-symbols/symbols/sliders';
import { symbol_slideshow } from 'components/material-symbols/symbols/slideshow';
import { symbol_slow_motion_video } from 'components/material-symbols/symbols/slow_motion_video';
import { symbol_smart_card_reader } from 'components/material-symbols/symbols/smart_card_reader';
import { symbol_smart_card_reader_off } from 'components/material-symbols/symbols/smart_card_reader_off';
import { symbol_smart_display } from 'components/material-symbols/symbols/smart_display';
import { symbol_smart_outlet } from 'components/material-symbols/symbols/smart_outlet';
import { symbol_smart_screen } from 'components/material-symbols/symbols/smart_screen';
import { symbol_smart_toy } from 'components/material-symbols/symbols/smart_toy';
import { symbol_smartphone } from 'components/material-symbols/symbols/smartphone';
import { symbol_smb_share } from 'components/material-symbols/symbols/smb_share';
import { symbol_smoke_free } from 'components/material-symbols/symbols/smoke_free';
import { symbol_smoking_rooms } from 'components/material-symbols/symbols/smoking_rooms';
import { symbol_sms } from 'components/material-symbols/symbols/sms';
import { symbol_snippet_folder } from 'components/material-symbols/symbols/snippet_folder';
import { symbol_snooze } from 'components/material-symbols/symbols/snooze';
import { symbol_snowboarding } from 'components/material-symbols/symbols/snowboarding';
import { symbol_snowing } from 'components/material-symbols/symbols/snowing';
import { symbol_snowing_heavy } from 'components/material-symbols/symbols/snowing_heavy';
import { symbol_snowmobile } from 'components/material-symbols/symbols/snowmobile';
import { symbol_snowshoeing } from 'components/material-symbols/symbols/snowshoeing';
import { symbol_soap } from 'components/material-symbols/symbols/soap';
import { symbol_social_distance } from 'components/material-symbols/symbols/social_distance';
import { symbol_social_leaderboard } from 'components/material-symbols/symbols/social_leaderboard';
import { symbol_solar_power } from 'components/material-symbols/symbols/solar_power';
import { symbol_sort } from 'components/material-symbols/symbols/sort';
import { symbol_sort_by_alpha } from 'components/material-symbols/symbols/sort_by_alpha';
import { symbol_sos } from 'components/material-symbols/symbols/sos';
import { symbol_sound_detection_dog_barking } from 'components/material-symbols/symbols/sound_detection_dog_barking';
import { symbol_sound_detection_glass_break } from 'components/material-symbols/symbols/sound_detection_glass_break';
import { symbol_sound_detection_loud_sound } from 'components/material-symbols/symbols/sound_detection_loud_sound';
import { symbol_sound_sampler } from 'components/material-symbols/symbols/sound_sampler';
import { symbol_soup_kitchen } from 'components/material-symbols/symbols/soup_kitchen';
import { symbol_source_environment } from 'components/material-symbols/symbols/source_environment';
import { symbol_source_notes } from 'components/material-symbols/symbols/source_notes';
import { symbol_south } from 'components/material-symbols/symbols/south';
import { symbol_south_america } from 'components/material-symbols/symbols/south_america';
import { symbol_south_east } from 'components/material-symbols/symbols/south_east';
import { symbol_south_west } from 'components/material-symbols/symbols/south_west';
import { symbol_spa } from 'components/material-symbols/symbols/spa';
import { symbol_space_bar } from 'components/material-symbols/symbols/space_bar';
import { symbol_space_dashboard } from 'components/material-symbols/symbols/space_dashboard';
import { symbol_spatial_audio } from 'components/material-symbols/symbols/spatial_audio';
import { symbol_spatial_audio_off } from 'components/material-symbols/symbols/spatial_audio_off';
import { symbol_spatial_speaker } from 'components/material-symbols/symbols/spatial_speaker';
import { symbol_spatial_tracking } from 'components/material-symbols/symbols/spatial_tracking';
import { symbol_speaker } from 'components/material-symbols/symbols/speaker';
import { symbol_speaker_group } from 'components/material-symbols/symbols/speaker_group';
import { symbol_speaker_notes } from 'components/material-symbols/symbols/speaker_notes';
import { symbol_speaker_notes_off } from 'components/material-symbols/symbols/speaker_notes_off';
import { symbol_speaker_phone } from 'components/material-symbols/symbols/speaker_phone';
import { symbol_special_character } from 'components/material-symbols/symbols/special_character';
import { symbol_specific_gravity } from 'components/material-symbols/symbols/specific_gravity';
import { symbol_speech_to_text } from 'components/material-symbols/symbols/speech_to_text';
import { symbol_speed } from 'components/material-symbols/symbols/speed';
import { symbol_speed_0_25 } from 'components/material-symbols/symbols/speed_0_25';
import { symbol_speed_0_2x } from 'components/material-symbols/symbols/speed_0_2x';
import { symbol_speed_0_5 } from 'components/material-symbols/symbols/speed_0_5';
import { symbol_speed_0_5x } from 'components/material-symbols/symbols/speed_0_5x';
import { symbol_speed_0_75 } from 'components/material-symbols/symbols/speed_0_75';
import { symbol_speed_0_7x } from 'components/material-symbols/symbols/speed_0_7x';
import { symbol_speed_1_2 } from 'components/material-symbols/symbols/speed_1_2';
import { symbol_speed_1_25 } from 'components/material-symbols/symbols/speed_1_25';
import { symbol_speed_1_2x } from 'components/material-symbols/symbols/speed_1_2x';
import { symbol_speed_1_5 } from 'components/material-symbols/symbols/speed_1_5';
import { symbol_speed_1_5x } from 'components/material-symbols/symbols/speed_1_5x';
import { symbol_speed_1_75 } from 'components/material-symbols/symbols/speed_1_75';
import { symbol_speed_1_7x } from 'components/material-symbols/symbols/speed_1_7x';
import { symbol_speed_2x } from 'components/material-symbols/symbols/speed_2x';
import { symbol_spellcheck } from 'components/material-symbols/symbols/spellcheck';
import { symbol_splitscreen } from 'components/material-symbols/symbols/splitscreen';
import { symbol_splitscreen_add } from 'components/material-symbols/symbols/splitscreen_add';
import { symbol_splitscreen_bottom } from 'components/material-symbols/symbols/splitscreen_bottom';
import { symbol_splitscreen_left } from 'components/material-symbols/symbols/splitscreen_left';
import { symbol_splitscreen_right } from 'components/material-symbols/symbols/splitscreen_right';
import { symbol_splitscreen_top } from 'components/material-symbols/symbols/splitscreen_top';
import { symbol_splitscreen_vertical_add } from 'components/material-symbols/symbols/splitscreen_vertical_add';
import { symbol_spo2 } from 'components/material-symbols/symbols/spo2';
import { symbol_spoke } from 'components/material-symbols/symbols/spoke';
import { symbol_sports } from 'components/material-symbols/symbols/sports';
import { symbol_sports_and_outdoors } from 'components/material-symbols/symbols/sports_and_outdoors';
import { symbol_sports_bar } from 'components/material-symbols/symbols/sports_bar';
import { symbol_sports_baseball } from 'components/material-symbols/symbols/sports_baseball';
import { symbol_sports_basketball } from 'components/material-symbols/symbols/sports_basketball';
import { symbol_sports_cricket } from 'components/material-symbols/symbols/sports_cricket';
import { symbol_sports_esports } from 'components/material-symbols/symbols/sports_esports';
import { symbol_sports_football } from 'components/material-symbols/symbols/sports_football';
import { symbol_sports_golf } from 'components/material-symbols/symbols/sports_golf';
import { symbol_sports_gymnastics } from 'components/material-symbols/symbols/sports_gymnastics';
import { symbol_sports_handball } from 'components/material-symbols/symbols/sports_handball';
import { symbol_sports_hockey } from 'components/material-symbols/symbols/sports_hockey';
import { symbol_sports_kabaddi } from 'components/material-symbols/symbols/sports_kabaddi';
import { symbol_sports_martial_arts } from 'components/material-symbols/symbols/sports_martial_arts';
import { symbol_sports_mma } from 'components/material-symbols/symbols/sports_mma';
import { symbol_sports_motorsports } from 'components/material-symbols/symbols/sports_motorsports';
import { symbol_sports_rugby } from 'components/material-symbols/symbols/sports_rugby';
import { symbol_sports_score } from 'components/material-symbols/symbols/sports_score';
import { symbol_sports_soccer } from 'components/material-symbols/symbols/sports_soccer';
import { symbol_sports_tennis } from 'components/material-symbols/symbols/sports_tennis';
import { symbol_sports_volleyball } from 'components/material-symbols/symbols/sports_volleyball';
import { symbol_sprinkler } from 'components/material-symbols/symbols/sprinkler';
import { symbol_sprint } from 'components/material-symbols/symbols/sprint';
import { symbol_square } from 'components/material-symbols/symbols/square';
import { symbol_square_foot } from 'components/material-symbols/symbols/square_foot';
import { symbol_ssid_chart } from 'components/material-symbols/symbols/ssid_chart';
import { symbol_stack } from 'components/material-symbols/symbols/stack';
import { symbol_stack_off } from 'components/material-symbols/symbols/stack_off';
import { symbol_stack_star } from 'components/material-symbols/symbols/stack_star';
import { symbol_stacked_bar_chart } from 'components/material-symbols/symbols/stacked_bar_chart';
import { symbol_stacked_email } from 'components/material-symbols/symbols/stacked_email';
import { symbol_stacked_inbox } from 'components/material-symbols/symbols/stacked_inbox';
import { symbol_stacked_line_chart } from 'components/material-symbols/symbols/stacked_line_chart';
import { symbol_stacks } from 'components/material-symbols/symbols/stacks';
import { symbol_stadia_controller } from 'components/material-symbols/symbols/stadia_controller';
import { symbol_stadium } from 'components/material-symbols/symbols/stadium';
import { symbol_stairs } from 'components/material-symbols/symbols/stairs';
import { symbol_star } from 'components/material-symbols/symbols/star';
import { symbol_star_half } from 'components/material-symbols/symbols/star_half';
import { symbol_star_rate } from 'components/material-symbols/symbols/star_rate';
import { symbol_star_rate_half } from 'components/material-symbols/symbols/star_rate_half';
import { symbol_stars } from 'components/material-symbols/symbols/stars';
import { symbol_start } from 'components/material-symbols/symbols/start';
import { symbol_stat_0 } from 'components/material-symbols/symbols/stat_0';
import { symbol_stat_1 } from 'components/material-symbols/symbols/stat_1';
import { symbol_stat_2 } from 'components/material-symbols/symbols/stat_2';
import { symbol_stat_3 } from 'components/material-symbols/symbols/stat_3';
import { symbol_stat_minus_1 } from 'components/material-symbols/symbols/stat_minus_1';
import { symbol_stat_minus_2 } from 'components/material-symbols/symbols/stat_minus_2';
import { symbol_stat_minus_3 } from 'components/material-symbols/symbols/stat_minus_3';
import { symbol_stay_current_landscape } from 'components/material-symbols/symbols/stay_current_landscape';
import { symbol_stay_current_portrait } from 'components/material-symbols/symbols/stay_current_portrait';
import { symbol_stay_primary_landscape } from 'components/material-symbols/symbols/stay_primary_landscape';
import { symbol_stay_primary_portrait } from 'components/material-symbols/symbols/stay_primary_portrait';
import { symbol_step } from 'components/material-symbols/symbols/step';
import { symbol_step_into } from 'components/material-symbols/symbols/step_into';
import { symbol_step_out } from 'components/material-symbols/symbols/step_out';
import { symbol_step_over } from 'components/material-symbols/symbols/step_over';
import { symbol_steppers } from 'components/material-symbols/symbols/steppers';
import { symbol_steps } from 'components/material-symbols/symbols/steps';
import { symbol_stethoscope } from 'components/material-symbols/symbols/stethoscope';
import { symbol_stethoscope_arrow } from 'components/material-symbols/symbols/stethoscope_arrow';
import { symbol_stethoscope_check } from 'components/material-symbols/symbols/stethoscope_check';
import { symbol_sticky_note } from 'components/material-symbols/symbols/sticky_note';
import { symbol_sticky_note_2 } from 'components/material-symbols/symbols/sticky_note_2';
import { symbol_stock_media } from 'components/material-symbols/symbols/stock_media';
import { symbol_stockpot } from 'components/material-symbols/symbols/stockpot';
import { symbol_stop } from 'components/material-symbols/symbols/stop';
import { symbol_stop_circle } from 'components/material-symbols/symbols/stop_circle';
import { symbol_stop_screen_share } from 'components/material-symbols/symbols/stop_screen_share';
import { symbol_storage } from 'components/material-symbols/symbols/storage';
import { symbol_store } from 'components/material-symbols/symbols/store';
import { symbol_storefront } from 'components/material-symbols/symbols/storefront';
import { symbol_storm } from 'components/material-symbols/symbols/storm';
import { symbol_straight } from 'components/material-symbols/symbols/straight';
import { symbol_straighten } from 'components/material-symbols/symbols/straighten';
import { symbol_strategy } from 'components/material-symbols/symbols/strategy';
import { symbol_stream } from 'components/material-symbols/symbols/stream';
import { symbol_stream_apps } from 'components/material-symbols/symbols/stream_apps';
import { symbol_streetview } from 'components/material-symbols/symbols/streetview';
import { symbol_stress_management } from 'components/material-symbols/symbols/stress_management';
import { symbol_strikethrough_s } from 'components/material-symbols/symbols/strikethrough_s';
import { symbol_stroke_full } from 'components/material-symbols/symbols/stroke_full';
import { symbol_stroke_partial } from 'components/material-symbols/symbols/stroke_partial';
import { symbol_stroller } from 'components/material-symbols/symbols/stroller';
import { symbol_style } from 'components/material-symbols/symbols/style';
import { symbol_styler } from 'components/material-symbols/symbols/styler';
import { symbol_stylus } from 'components/material-symbols/symbols/stylus';
import { symbol_stylus_laser_pointer } from 'components/material-symbols/symbols/stylus_laser_pointer';
import { symbol_stylus_note } from 'components/material-symbols/symbols/stylus_note';
import { symbol_subdirectory_arrow_left } from 'components/material-symbols/symbols/subdirectory_arrow_left';
import { symbol_subdirectory_arrow_right } from 'components/material-symbols/symbols/subdirectory_arrow_right';
import { symbol_subheader } from 'components/material-symbols/symbols/subheader';
import { symbol_subject } from 'components/material-symbols/symbols/subject';
import { symbol_subscript } from 'components/material-symbols/symbols/subscript';
import { symbol_subscriptions } from 'components/material-symbols/symbols/subscriptions';
import { symbol_subtitles } from 'components/material-symbols/symbols/subtitles';
import { symbol_subtitles_off } from 'components/material-symbols/symbols/subtitles_off';
import { symbol_subway } from 'components/material-symbols/symbols/subway';
import { symbol_summarize } from 'components/material-symbols/symbols/summarize';
import { symbol_sunny } from 'components/material-symbols/symbols/sunny';
import { symbol_sunny_snowing } from 'components/material-symbols/symbols/sunny_snowing';
import { symbol_superscript } from 'components/material-symbols/symbols/superscript';
import { symbol_supervised_user_circle } from 'components/material-symbols/symbols/supervised_user_circle';
import { symbol_supervised_user_circle_off } from 'components/material-symbols/symbols/supervised_user_circle_off';
import { symbol_supervisor_account } from 'components/material-symbols/symbols/supervisor_account';
import { symbol_support } from 'components/material-symbols/symbols/support';
import { symbol_support_agent } from 'components/material-symbols/symbols/support_agent';
import { symbol_surfing } from 'components/material-symbols/symbols/surfing';
import { symbol_surgical } from 'components/material-symbols/symbols/surgical';
import { symbol_surround_sound } from 'components/material-symbols/symbols/surround_sound';
import { symbol_swap_calls } from 'components/material-symbols/symbols/swap_calls';
import { symbol_swap_driving_apps } from 'components/material-symbols/symbols/swap_driving_apps';
import { symbol_swap_driving_apps_wheel } from 'components/material-symbols/symbols/swap_driving_apps_wheel';
import { symbol_swap_horiz } from 'components/material-symbols/symbols/swap_horiz';
import { symbol_swap_horizontal_circle } from 'components/material-symbols/symbols/swap_horizontal_circle';
import { symbol_swap_vert } from 'components/material-symbols/symbols/swap_vert';
import { symbol_swap_vertical_circle } from 'components/material-symbols/symbols/swap_vertical_circle';
import { symbol_sweep } from 'components/material-symbols/symbols/sweep';
import { symbol_swipe } from 'components/material-symbols/symbols/swipe';
import { symbol_swipe_down } from 'components/material-symbols/symbols/swipe_down';
import { symbol_swipe_down_alt } from 'components/material-symbols/symbols/swipe_down_alt';
import { symbol_swipe_left } from 'components/material-symbols/symbols/swipe_left';
import { symbol_swipe_left_alt } from 'components/material-symbols/symbols/swipe_left_alt';
import { symbol_swipe_right } from 'components/material-symbols/symbols/swipe_right';
import { symbol_swipe_right_alt } from 'components/material-symbols/symbols/swipe_right_alt';
import { symbol_swipe_up } from 'components/material-symbols/symbols/swipe_up';
import { symbol_swipe_up_alt } from 'components/material-symbols/symbols/swipe_up_alt';
import { symbol_swipe_vertical } from 'components/material-symbols/symbols/swipe_vertical';
import { symbol_switch } from 'components/material-symbols/symbols/switch';
import { symbol_switch_access } from 'components/material-symbols/symbols/switch_access';
import { symbol_switch_access_2 } from 'components/material-symbols/symbols/switch_access_2';
import { symbol_switch_access_shortcut } from 'components/material-symbols/symbols/switch_access_shortcut';
import { symbol_switch_access_shortcut_add } from 'components/material-symbols/symbols/switch_access_shortcut_add';
import { symbol_switch_account } from 'components/material-symbols/symbols/switch_account';
import { symbol_switch_camera } from 'components/material-symbols/symbols/switch_camera';
import { symbol_switch_left } from 'components/material-symbols/symbols/switch_left';
import { symbol_switch_right } from 'components/material-symbols/symbols/switch_right';
import { symbol_switch_video } from 'components/material-symbols/symbols/switch_video';
import { symbol_switches } from 'components/material-symbols/symbols/switches';
import { symbol_sword_rose } from 'components/material-symbols/symbols/sword_rose';
import { symbol_swords } from 'components/material-symbols/symbols/swords';
import { symbol_symptoms } from 'components/material-symbols/symbols/symptoms';
import { symbol_synagogue } from 'components/material-symbols/symbols/synagogue';
import { symbol_sync } from 'components/material-symbols/symbols/sync';
import { symbol_sync_alt } from 'components/material-symbols/symbols/sync_alt';
import { symbol_sync_disabled } from 'components/material-symbols/symbols/sync_disabled';
import { symbol_sync_lock } from 'components/material-symbols/symbols/sync_lock';
import { symbol_sync_problem } from 'components/material-symbols/symbols/sync_problem';
import { symbol_sync_saved_locally } from 'components/material-symbols/symbols/sync_saved_locally';
import { symbol_syringe } from 'components/material-symbols/symbols/syringe';
import { symbol_system_update } from 'components/material-symbols/symbols/system_update';
import { symbol_system_update_alt } from 'components/material-symbols/symbols/system_update_alt';
import { symbol_tab } from 'components/material-symbols/symbols/tab';
import { symbol_tab_close } from 'components/material-symbols/symbols/tab_close';
import { symbol_tab_close_right } from 'components/material-symbols/symbols/tab_close_right';
import { symbol_tab_duplicate } from 'components/material-symbols/symbols/tab_duplicate';
import { symbol_tab_group } from 'components/material-symbols/symbols/tab_group';
import { symbol_tab_move } from 'components/material-symbols/symbols/tab_move';
import { symbol_tab_new_right } from 'components/material-symbols/symbols/tab_new_right';
import { symbol_tab_recent } from 'components/material-symbols/symbols/tab_recent';
import { symbol_tab_unselected } from 'components/material-symbols/symbols/tab_unselected';
import { symbol_table } from 'components/material-symbols/symbols/table';
import { symbol_table_bar } from 'components/material-symbols/symbols/table_bar';
import { symbol_table_chart } from 'components/material-symbols/symbols/table_chart';
import { symbol_table_chart_view } from 'components/material-symbols/symbols/table_chart_view';
import { symbol_table_lamp } from 'components/material-symbols/symbols/table_lamp';
import { symbol_table_restaurant } from 'components/material-symbols/symbols/table_restaurant';
import { symbol_table_rows } from 'components/material-symbols/symbols/table_rows';
import { symbol_table_rows_narrow } from 'components/material-symbols/symbols/table_rows_narrow';
import { symbol_table_view } from 'components/material-symbols/symbols/table_view';
import { symbol_tablet } from 'components/material-symbols/symbols/tablet';
import { symbol_tablet_android } from 'components/material-symbols/symbols/tablet_android';
import { symbol_tablet_mac } from 'components/material-symbols/symbols/tablet_mac';
import { symbol_tabs } from 'components/material-symbols/symbols/tabs';
import { symbol_tactic } from 'components/material-symbols/symbols/tactic';
import { symbol_tag } from 'components/material-symbols/symbols/tag';
import { symbol_takeout_dining } from 'components/material-symbols/symbols/takeout_dining';
import { symbol_tamper_detection_off } from 'components/material-symbols/symbols/tamper_detection_off';
import { symbol_tamper_detection_on } from 'components/material-symbols/symbols/tamper_detection_on';
import { symbol_tap_and_play } from 'components/material-symbols/symbols/tap_and_play';
import { symbol_tapas } from 'components/material-symbols/symbols/tapas';
import { symbol_target } from 'components/material-symbols/symbols/target';
import { symbol_task } from 'components/material-symbols/symbols/task';
import { symbol_task_alt } from 'components/material-symbols/symbols/task_alt';
import { symbol_taunt } from 'components/material-symbols/symbols/taunt';
import { symbol_taxi_alert } from 'components/material-symbols/symbols/taxi_alert';
import { symbol_team_dashboard } from 'components/material-symbols/symbols/team_dashboard';
import { symbol_temp_preferences_eco } from 'components/material-symbols/symbols/temp_preferences_eco';
import { symbol_temple_buddhist } from 'components/material-symbols/symbols/temple_buddhist';
import { symbol_temple_hindu } from 'components/material-symbols/symbols/temple_hindu';
import { symbol_tenancy } from 'components/material-symbols/symbols/tenancy';
import { symbol_terminal } from 'components/material-symbols/symbols/terminal';
import { symbol_text_ad } from 'components/material-symbols/symbols/text_ad';
import { symbol_text_decrease } from 'components/material-symbols/symbols/text_decrease';
import { symbol_text_fields } from 'components/material-symbols/symbols/text_fields';
import { symbol_text_fields_alt } from 'components/material-symbols/symbols/text_fields_alt';
import { symbol_text_format } from 'components/material-symbols/symbols/text_format';
import { symbol_text_increase } from 'components/material-symbols/symbols/text_increase';
import { symbol_text_rotate_up } from 'components/material-symbols/symbols/text_rotate_up';
import { symbol_text_rotate_vertical } from 'components/material-symbols/symbols/text_rotate_vertical';
import { symbol_text_rotation_angledown } from 'components/material-symbols/symbols/text_rotation_angledown';
import { symbol_text_rotation_angleup } from 'components/material-symbols/symbols/text_rotation_angleup';
import { symbol_text_rotation_down } from 'components/material-symbols/symbols/text_rotation_down';
import { symbol_text_rotation_none } from 'components/material-symbols/symbols/text_rotation_none';
import { symbol_text_select_end } from 'components/material-symbols/symbols/text_select_end';
import { symbol_text_select_jump_to_beginning } from 'components/material-symbols/symbols/text_select_jump_to_beginning';
import { symbol_text_select_jump_to_end } from 'components/material-symbols/symbols/text_select_jump_to_end';
import { symbol_text_select_move_back_character } from 'components/material-symbols/symbols/text_select_move_back_character';
import { symbol_text_select_move_back_word } from 'components/material-symbols/symbols/text_select_move_back_word';
import { symbol_text_select_move_down } from 'components/material-symbols/symbols/text_select_move_down';
import { symbol_text_select_move_forward_character } from 'components/material-symbols/symbols/text_select_move_forward_character';
import { symbol_text_select_move_forward_word } from 'components/material-symbols/symbols/text_select_move_forward_word';
import { symbol_text_select_move_up } from 'components/material-symbols/symbols/text_select_move_up';
import { symbol_text_select_start } from 'components/material-symbols/symbols/text_select_start';
import { symbol_text_snippet } from 'components/material-symbols/symbols/text_snippet';
import { symbol_text_to_speech } from 'components/material-symbols/symbols/text_to_speech';
import { symbol_text_up } from 'components/material-symbols/symbols/text_up';
import { symbol_texture } from 'components/material-symbols/symbols/texture';
import { symbol_texture_add } from 'components/material-symbols/symbols/texture_add';
import { symbol_texture_minus } from 'components/material-symbols/symbols/texture_minus';
import { symbol_theater_comedy } from 'components/material-symbols/symbols/theater_comedy';
import { symbol_theaters } from 'components/material-symbols/symbols/theaters';
import { symbol_thermometer } from 'components/material-symbols/symbols/thermometer';
import { symbol_thermometer_add } from 'components/material-symbols/symbols/thermometer_add';
import { symbol_thermometer_gain } from 'components/material-symbols/symbols/thermometer_gain';
import { symbol_thermometer_loss } from 'components/material-symbols/symbols/thermometer_loss';
import { symbol_thermometer_minus } from 'components/material-symbols/symbols/thermometer_minus';
import { symbol_thermostat } from 'components/material-symbols/symbols/thermostat';
import { symbol_thermostat_auto } from 'components/material-symbols/symbols/thermostat_auto';
import { symbol_thermostat_carbon } from 'components/material-symbols/symbols/thermostat_carbon';
import { symbol_things_to_do } from 'components/material-symbols/symbols/things_to_do';
import { symbol_thread_unread } from 'components/material-symbols/symbols/thread_unread';
import { symbol_thumb_down } from 'components/material-symbols/symbols/thumb_down';
import { symbol_thumb_up } from 'components/material-symbols/symbols/thumb_up';
import { symbol_thumbnail_bar } from 'components/material-symbols/symbols/thumbnail_bar';
import { symbol_thumbs_up_down } from 'components/material-symbols/symbols/thumbs_up_down';
import { symbol_thunderstorm } from 'components/material-symbols/symbols/thunderstorm';
import { symbol_tibia } from 'components/material-symbols/symbols/tibia';
import { symbol_tibia_alt } from 'components/material-symbols/symbols/tibia_alt';
import { symbol_time_auto } from 'components/material-symbols/symbols/time_auto';
import { symbol_timelapse } from 'components/material-symbols/symbols/timelapse';
import { symbol_timeline } from 'components/material-symbols/symbols/timeline';
import { symbol_timer } from 'components/material-symbols/symbols/timer';
import { symbol_timer_10 } from 'components/material-symbols/symbols/timer_10';
import { symbol_timer_10_alt_1 } from 'components/material-symbols/symbols/timer_10_alt_1';
import { symbol_timer_10_select } from 'components/material-symbols/symbols/timer_10_select';
import { symbol_timer_3 } from 'components/material-symbols/symbols/timer_3';
import { symbol_timer_3_alt_1 } from 'components/material-symbols/symbols/timer_3_alt_1';
import { symbol_timer_3_select } from 'components/material-symbols/symbols/timer_3_select';
import { symbol_timer_5 } from 'components/material-symbols/symbols/timer_5';
import { symbol_timer_5_shutter } from 'components/material-symbols/symbols/timer_5_shutter';
import { symbol_timer_off } from 'components/material-symbols/symbols/timer_off';
import { symbol_timer_pause } from 'components/material-symbols/symbols/timer_pause';
import { symbol_timer_play } from 'components/material-symbols/symbols/timer_play';
import { symbol_tire_repair } from 'components/material-symbols/symbols/tire_repair';
import { symbol_title } from 'components/material-symbols/symbols/title';
import { symbol_titlecase } from 'components/material-symbols/symbols/titlecase';
import { symbol_toast } from 'components/material-symbols/symbols/toast';
import { symbol_toc } from 'components/material-symbols/symbols/toc';
import { symbol_today } from 'components/material-symbols/symbols/today';
import { symbol_toggle_off } from 'components/material-symbols/symbols/toggle_off';
import { symbol_toggle_on } from 'components/material-symbols/symbols/toggle_on';
import { symbol_token } from 'components/material-symbols/symbols/token';
import { symbol_toll } from 'components/material-symbols/symbols/toll';
import { symbol_tonality } from 'components/material-symbols/symbols/tonality';
import { symbol_toolbar } from 'components/material-symbols/symbols/toolbar';
import { symbol_tools_flat_head } from 'components/material-symbols/symbols/tools_flat_head';
import { symbol_tools_installation_kit } from 'components/material-symbols/symbols/tools_installation_kit';
import { symbol_tools_ladder } from 'components/material-symbols/symbols/tools_ladder';
import { symbol_tools_level } from 'components/material-symbols/symbols/tools_level';
import { symbol_tools_phillips } from 'components/material-symbols/symbols/tools_phillips';
import { symbol_tools_pliers_wire_stripper } from 'components/material-symbols/symbols/tools_pliers_wire_stripper';
import { symbol_tools_power_drill } from 'components/material-symbols/symbols/tools_power_drill';
import { symbol_tooltip } from 'components/material-symbols/symbols/tooltip';
import { symbol_top_panel_close } from 'components/material-symbols/symbols/top_panel_close';
import { symbol_top_panel_open } from 'components/material-symbols/symbols/top_panel_open';
import { symbol_topic } from 'components/material-symbols/symbols/topic';
import { symbol_tornado } from 'components/material-symbols/symbols/tornado';
import { symbol_total_dissolved_solids } from 'components/material-symbols/symbols/total_dissolved_solids';
import { symbol_touch_app } from 'components/material-symbols/symbols/touch_app';
import { symbol_touchpad_mouse } from 'components/material-symbols/symbols/touchpad_mouse';
import { symbol_touchpad_mouse_off } from 'components/material-symbols/symbols/touchpad_mouse_off';
import { symbol_tour } from 'components/material-symbols/symbols/tour';
import { symbol_toys } from 'components/material-symbols/symbols/toys';
import { symbol_toys_and_games } from 'components/material-symbols/symbols/toys_and_games';
import { symbol_toys_fan } from 'components/material-symbols/symbols/toys_fan';
import { symbol_track_changes } from 'components/material-symbols/symbols/track_changes';
import { symbol_trackpad_input } from 'components/material-symbols/symbols/trackpad_input';
import { symbol_traffic } from 'components/material-symbols/symbols/traffic';
import { symbol_trail_length } from 'components/material-symbols/symbols/trail_length';
import { symbol_trail_length_medium } from 'components/material-symbols/symbols/trail_length_medium';
import { symbol_trail_length_short } from 'components/material-symbols/symbols/trail_length_short';
import { symbol_train } from 'components/material-symbols/symbols/train';
import { symbol_tram } from 'components/material-symbols/symbols/tram';
import { symbol_transcribe } from 'components/material-symbols/symbols/transcribe';
import { symbol_transfer_within_a_station } from 'components/material-symbols/symbols/transfer_within_a_station';
import { symbol_transform } from 'components/material-symbols/symbols/transform';
import { symbol_transgender } from 'components/material-symbols/symbols/transgender';
import { symbol_transit_enterexit } from 'components/material-symbols/symbols/transit_enterexit';
import { symbol_transition_chop } from 'components/material-symbols/symbols/transition_chop';
import { symbol_transition_dissolve } from 'components/material-symbols/symbols/transition_dissolve';
import { symbol_transition_fade } from 'components/material-symbols/symbols/transition_fade';
import { symbol_transition_push } from 'components/material-symbols/symbols/transition_push';
import { symbol_transition_slide } from 'components/material-symbols/symbols/transition_slide';
import { symbol_translate } from 'components/material-symbols/symbols/translate';
import { symbol_transportation } from 'components/material-symbols/symbols/transportation';
import { symbol_travel } from 'components/material-symbols/symbols/travel';
import { symbol_travel_explore } from 'components/material-symbols/symbols/travel_explore';
import { symbol_travel_luggage_and_bags } from 'components/material-symbols/symbols/travel_luggage_and_bags';
import { symbol_trending_down } from 'components/material-symbols/symbols/trending_down';
import { symbol_trending_flat } from 'components/material-symbols/symbols/trending_flat';
import { symbol_trending_up } from 'components/material-symbols/symbols/trending_up';
import { symbol_trip } from 'components/material-symbols/symbols/trip';
import { symbol_trip_origin } from 'components/material-symbols/symbols/trip_origin';
import { symbol_trolley } from 'components/material-symbols/symbols/trolley';
import { symbol_trophy } from 'components/material-symbols/symbols/trophy';
import { symbol_troubleshoot } from 'components/material-symbols/symbols/troubleshoot';
import { symbol_tsunami } from 'components/material-symbols/symbols/tsunami';
import { symbol_tsv } from 'components/material-symbols/symbols/tsv';
import { symbol_tty } from 'components/material-symbols/symbols/tty';
import { symbol_tune } from 'components/material-symbols/symbols/tune';
import { symbol_turn_left } from 'components/material-symbols/symbols/turn_left';
import { symbol_turn_right } from 'components/material-symbols/symbols/turn_right';
import { symbol_turn_sharp_left } from 'components/material-symbols/symbols/turn_sharp_left';
import { symbol_turn_sharp_right } from 'components/material-symbols/symbols/turn_sharp_right';
import { symbol_turn_slight_left } from 'components/material-symbols/symbols/turn_slight_left';
import { symbol_turn_slight_right } from 'components/material-symbols/symbols/turn_slight_right';
import { symbol_tv } from 'components/material-symbols/symbols/tv';
import { symbol_tv_gen } from 'components/material-symbols/symbols/tv_gen';
import { symbol_tv_guide } from 'components/material-symbols/symbols/tv_guide';
import { symbol_tv_off } from 'components/material-symbols/symbols/tv_off';
import { symbol_tv_options_edit_channels } from 'components/material-symbols/symbols/tv_options_edit_channels';
import { symbol_tv_options_input_settings } from 'components/material-symbols/symbols/tv_options_input_settings';
import { symbol_tv_remote } from 'components/material-symbols/symbols/tv_remote';
import { symbol_tv_signin } from 'components/material-symbols/symbols/tv_signin';
import { symbol_tv_with_assistant } from 'components/material-symbols/symbols/tv_with_assistant';
import { symbol_two_pager } from 'components/material-symbols/symbols/two_pager';
import { symbol_two_wheeler } from 'components/material-symbols/symbols/two_wheeler';
import { symbol_type_specimen } from 'components/material-symbols/symbols/type_specimen';
import { symbol_u_turn_left } from 'components/material-symbols/symbols/u_turn_left';
import { symbol_u_turn_right } from 'components/material-symbols/symbols/u_turn_right';
import { symbol_ulna_radius } from 'components/material-symbols/symbols/ulna_radius';
import { symbol_ulna_radius_alt } from 'components/material-symbols/symbols/ulna_radius_alt';
import { symbol_umbrella } from 'components/material-symbols/symbols/umbrella';
import { symbol_unarchive } from 'components/material-symbols/symbols/unarchive';
import { symbol_undo } from 'components/material-symbols/symbols/undo';
import { symbol_unfold_less } from 'components/material-symbols/symbols/unfold_less';
import { symbol_unfold_less_double } from 'components/material-symbols/symbols/unfold_less_double';
import { symbol_unfold_more } from 'components/material-symbols/symbols/unfold_more';
import { symbol_unfold_more_double } from 'components/material-symbols/symbols/unfold_more_double';
import { symbol_ungroup } from 'components/material-symbols/symbols/ungroup';
import { symbol_universal_currency } from 'components/material-symbols/symbols/universal_currency';
import { symbol_universal_currency_alt } from 'components/material-symbols/symbols/universal_currency_alt';
import { symbol_universal_local } from 'components/material-symbols/symbols/universal_local';
import { symbol_unknown_2 } from 'components/material-symbols/symbols/unknown_2';
import { symbol_unknown_5 } from 'components/material-symbols/symbols/unknown_5';
import { symbol_unknown_7 } from 'components/material-symbols/symbols/unknown_7';
import { symbol_unknown_document } from 'components/material-symbols/symbols/unknown_document';
import { symbol_unknown_med } from 'components/material-symbols/symbols/unknown_med';
import { symbol_unlicense } from 'components/material-symbols/symbols/unlicense';
import { symbol_unpublished } from 'components/material-symbols/symbols/unpublished';
import { symbol_unsubscribe } from 'components/material-symbols/symbols/unsubscribe';
import { symbol_upcoming } from 'components/material-symbols/symbols/upcoming';
import { symbol_update } from 'components/material-symbols/symbols/update';
import { symbol_update_disabled } from 'components/material-symbols/symbols/update_disabled';
import { symbol_upgrade } from 'components/material-symbols/symbols/upgrade';
import { symbol_upload } from 'components/material-symbols/symbols/upload';
import { symbol_upload_2 } from 'components/material-symbols/symbols/upload_2';
import { symbol_upload_file } from 'components/material-symbols/symbols/upload_file';
import { symbol_uppercase } from 'components/material-symbols/symbols/uppercase';
import { symbol_urology } from 'components/material-symbols/symbols/urology';
import { symbol_usb } from 'components/material-symbols/symbols/usb';
import { symbol_usb_off } from 'components/material-symbols/symbols/usb_off';
import { symbol_user_attributes } from 'components/material-symbols/symbols/user_attributes';
import { symbol_vaccines } from 'components/material-symbols/symbols/vaccines';
import { symbol_vacuum } from 'components/material-symbols/symbols/vacuum';
import { symbol_valve } from 'components/material-symbols/symbols/valve';
import { symbol_vape_free } from 'components/material-symbols/symbols/vape_free';
import { symbol_vaping_rooms } from 'components/material-symbols/symbols/vaping_rooms';
import { symbol_variable_add } from 'components/material-symbols/symbols/variable_add';
import { symbol_variable_insert } from 'components/material-symbols/symbols/variable_insert';
import { symbol_variable_remove } from 'components/material-symbols/symbols/variable_remove';
import { symbol_variables } from 'components/material-symbols/symbols/variables';
import { symbol_ventilator } from 'components/material-symbols/symbols/ventilator';
import { symbol_verified } from 'components/material-symbols/symbols/verified';
import { symbol_verified_user } from 'components/material-symbols/symbols/verified_user';
import { symbol_vertical_align_bottom } from 'components/material-symbols/symbols/vertical_align_bottom';
import { symbol_vertical_align_center } from 'components/material-symbols/symbols/vertical_align_center';
import { symbol_vertical_align_top } from 'components/material-symbols/symbols/vertical_align_top';
import { symbol_vertical_distribute } from 'components/material-symbols/symbols/vertical_distribute';
import { symbol_vertical_shades } from 'components/material-symbols/symbols/vertical_shades';
import { symbol_vertical_shades_closed } from 'components/material-symbols/symbols/vertical_shades_closed';
import { symbol_vertical_split } from 'components/material-symbols/symbols/vertical_split';
import { symbol_vibration } from 'components/material-symbols/symbols/vibration';
import { symbol_video_call } from 'components/material-symbols/symbols/video_call';
import { symbol_video_camera_back } from 'components/material-symbols/symbols/video_camera_back';
import { symbol_video_camera_front } from 'components/material-symbols/symbols/video_camera_front';
import { symbol_video_camera_front_off } from 'components/material-symbols/symbols/video_camera_front_off';
import { symbol_video_chat } from 'components/material-symbols/symbols/video_chat';
import { symbol_video_file } from 'components/material-symbols/symbols/video_file';
import { symbol_video_label } from 'components/material-symbols/symbols/video_label';
import { symbol_video_library } from 'components/material-symbols/symbols/video_library';
import { symbol_video_search } from 'components/material-symbols/symbols/video_search';
import { symbol_video_settings } from 'components/material-symbols/symbols/video_settings';
import { symbol_video_stable } from 'components/material-symbols/symbols/video_stable';
import { symbol_videocam } from 'components/material-symbols/symbols/videocam';
import { symbol_videocam_off } from 'components/material-symbols/symbols/videocam_off';
import { symbol_videogame_asset } from 'components/material-symbols/symbols/videogame_asset';
import { symbol_videogame_asset_off } from 'components/material-symbols/symbols/videogame_asset_off';
import { symbol_view_agenda } from 'components/material-symbols/symbols/view_agenda';
import { symbol_view_array } from 'components/material-symbols/symbols/view_array';
import { symbol_view_carousel } from 'components/material-symbols/symbols/view_carousel';
import { symbol_view_column } from 'components/material-symbols/symbols/view_column';
import { symbol_view_column_2 } from 'components/material-symbols/symbols/view_column_2';
import { symbol_view_comfy } from 'components/material-symbols/symbols/view_comfy';
import { symbol_view_comfy_alt } from 'components/material-symbols/symbols/view_comfy_alt';
import { symbol_view_compact } from 'components/material-symbols/symbols/view_compact';
import { symbol_view_compact_alt } from 'components/material-symbols/symbols/view_compact_alt';
import { symbol_view_cozy } from 'components/material-symbols/symbols/view_cozy';
import { symbol_view_day } from 'components/material-symbols/symbols/view_day';
import { symbol_view_headline } from 'components/material-symbols/symbols/view_headline';
import { symbol_view_in_ar } from 'components/material-symbols/symbols/view_in_ar';
import { symbol_view_in_ar_off } from 'components/material-symbols/symbols/view_in_ar_off';
import { symbol_view_kanban } from 'components/material-symbols/symbols/view_kanban';
import { symbol_view_list } from 'components/material-symbols/symbols/view_list';
import { symbol_view_module } from 'components/material-symbols/symbols/view_module';
import { symbol_view_quilt } from 'components/material-symbols/symbols/view_quilt';
import { symbol_view_real_size } from 'components/material-symbols/symbols/view_real_size';
import { symbol_view_sidebar } from 'components/material-symbols/symbols/view_sidebar';
import { symbol_view_stream } from 'components/material-symbols/symbols/view_stream';
import { symbol_view_timeline } from 'components/material-symbols/symbols/view_timeline';
import { symbol_view_week } from 'components/material-symbols/symbols/view_week';
import { symbol_vignette } from 'components/material-symbols/symbols/vignette';
import { symbol_villa } from 'components/material-symbols/symbols/villa';
import { symbol_visibility } from 'components/material-symbols/symbols/visibility';
import { symbol_visibility_lock } from 'components/material-symbols/symbols/visibility_lock';
import { symbol_visibility_off } from 'components/material-symbols/symbols/visibility_off';
import { symbol_vital_signs } from 'components/material-symbols/symbols/vital_signs';
import { symbol_vo2_max } from 'components/material-symbols/symbols/vo2_max';
import { symbol_voice_chat } from 'components/material-symbols/symbols/voice_chat';
import { symbol_voice_over_off } from 'components/material-symbols/symbols/voice_over_off';
import { symbol_voice_selection } from 'components/material-symbols/symbols/voice_selection';
import { symbol_voicemail } from 'components/material-symbols/symbols/voicemail';
import { symbol_volcano } from 'components/material-symbols/symbols/volcano';
import { symbol_volume_down } from 'components/material-symbols/symbols/volume_down';
import { symbol_volume_down_alt } from 'components/material-symbols/symbols/volume_down_alt';
import { symbol_volume_mute } from 'components/material-symbols/symbols/volume_mute';
import { symbol_volume_off } from 'components/material-symbols/symbols/volume_off';
import { symbol_volume_up } from 'components/material-symbols/symbols/volume_up';
import { symbol_volunteer_activism } from 'components/material-symbols/symbols/volunteer_activism';
import { symbol_voting_chip } from 'components/material-symbols/symbols/voting_chip';
import { symbol_vpn_key } from 'components/material-symbols/symbols/vpn_key';
import { symbol_vpn_key_alert } from 'components/material-symbols/symbols/vpn_key_alert';
import { symbol_vpn_key_off } from 'components/material-symbols/symbols/vpn_key_off';
import { symbol_vpn_lock } from 'components/material-symbols/symbols/vpn_lock';
import { symbol_vr180_create2d } from 'components/material-symbols/symbols/vr180_create2d';
import { symbol_vr180_create2d_off } from 'components/material-symbols/symbols/vr180_create2d_off';
import { symbol_vrpano } from 'components/material-symbols/symbols/vrpano';
import { symbol_wall_art } from 'components/material-symbols/symbols/wall_art';
import { symbol_wall_lamp } from 'components/material-symbols/symbols/wall_lamp';
import { symbol_wallet } from 'components/material-symbols/symbols/wallet';
import { symbol_wallpaper } from 'components/material-symbols/symbols/wallpaper';
import { symbol_wallpaper_slideshow } from 'components/material-symbols/symbols/wallpaper_slideshow';
import { symbol_ward } from 'components/material-symbols/symbols/ward';
import { symbol_warehouse } from 'components/material-symbols/symbols/warehouse';
import { symbol_warning } from 'components/material-symbols/symbols/warning';
import { symbol_warning_off } from 'components/material-symbols/symbols/warning_off';
import { symbol_wash } from 'components/material-symbols/symbols/wash';
import { symbol_watch } from 'components/material-symbols/symbols/watch';
import { symbol_watch_button_press } from 'components/material-symbols/symbols/watch_button_press';
import { symbol_watch_off } from 'components/material-symbols/symbols/watch_off';
import { symbol_watch_screentime } from 'components/material-symbols/symbols/watch_screentime';
import { symbol_watch_wake } from 'components/material-symbols/symbols/watch_wake';
import { symbol_water } from 'components/material-symbols/symbols/water';
import { symbol_water_bottle } from 'components/material-symbols/symbols/water_bottle';
import { symbol_water_bottle_large } from 'components/material-symbols/symbols/water_bottle_large';
import { symbol_water_damage } from 'components/material-symbols/symbols/water_damage';
import { symbol_water_do } from 'components/material-symbols/symbols/water_do';
import { symbol_water_drop } from 'components/material-symbols/symbols/water_drop';
import { symbol_water_ec } from 'components/material-symbols/symbols/water_ec';
import { symbol_water_full } from 'components/material-symbols/symbols/water_full';
import { symbol_water_heater } from 'components/material-symbols/symbols/water_heater';
import { symbol_water_lock } from 'components/material-symbols/symbols/water_lock';
import { symbol_water_loss } from 'components/material-symbols/symbols/water_loss';
import { symbol_water_lux } from 'components/material-symbols/symbols/water_lux';
import { symbol_water_medium } from 'components/material-symbols/symbols/water_medium';
import { symbol_water_orp } from 'components/material-symbols/symbols/water_orp';
import { symbol_water_ph } from 'components/material-symbols/symbols/water_ph';
import { symbol_water_pump } from 'components/material-symbols/symbols/water_pump';
import { symbol_water_voc } from 'components/material-symbols/symbols/water_voc';
import { symbol_waterfall_chart } from 'components/material-symbols/symbols/waterfall_chart';
import { symbol_waves } from 'components/material-symbols/symbols/waves';
import { symbol_waving_hand } from 'components/material-symbols/symbols/waving_hand';
import { symbol_wb_auto } from 'components/material-symbols/symbols/wb_auto';
import { symbol_wb_incandescent } from 'components/material-symbols/symbols/wb_incandescent';
import { symbol_wb_iridescent } from 'components/material-symbols/symbols/wb_iridescent';
import { symbol_wb_shade } from 'components/material-symbols/symbols/wb_shade';
import { symbol_wb_sunny } from 'components/material-symbols/symbols/wb_sunny';
import { symbol_wb_twilight } from 'components/material-symbols/symbols/wb_twilight';
import { symbol_wc } from 'components/material-symbols/symbols/wc';
import { symbol_weather_hail } from 'components/material-symbols/symbols/weather_hail';
import { symbol_weather_mix } from 'components/material-symbols/symbols/weather_mix';
import { symbol_weather_snowy } from 'components/material-symbols/symbols/weather_snowy';
import { symbol_web } from 'components/material-symbols/symbols/web';
import { symbol_web_asset } from 'components/material-symbols/symbols/web_asset';
import { symbol_web_asset_off } from 'components/material-symbols/symbols/web_asset_off';
import { symbol_web_stories } from 'components/material-symbols/symbols/web_stories';
import { symbol_web_traffic } from 'components/material-symbols/symbols/web_traffic';
import { symbol_webhook } from 'components/material-symbols/symbols/webhook';
import { symbol_weekend } from 'components/material-symbols/symbols/weekend';
import { symbol_weight } from 'components/material-symbols/symbols/weight';
import { symbol_west } from 'components/material-symbols/symbols/west';
import { symbol_whatshot } from 'components/material-symbols/symbols/whatshot';
import { symbol_wheelchair_pickup } from 'components/material-symbols/symbols/wheelchair_pickup';
import { symbol_where_to_vote } from 'components/material-symbols/symbols/where_to_vote';
import { symbol_widgets } from 'components/material-symbols/symbols/widgets';
import { symbol_width } from 'components/material-symbols/symbols/width';
import { symbol_width_full } from 'components/material-symbols/symbols/width_full';
import { symbol_width_normal } from 'components/material-symbols/symbols/width_normal';
import { symbol_width_wide } from 'components/material-symbols/symbols/width_wide';
import { symbol_wifi } from 'components/material-symbols/symbols/wifi';
import { symbol_wifi_1_bar } from 'components/material-symbols/symbols/wifi_1_bar';
import { symbol_wifi_2_bar } from 'components/material-symbols/symbols/wifi_2_bar';
import { symbol_wifi_add } from 'components/material-symbols/symbols/wifi_add';
import { symbol_wifi_calling } from 'components/material-symbols/symbols/wifi_calling';
import { symbol_wifi_calling_1 } from 'components/material-symbols/symbols/wifi_calling_1';
import { symbol_wifi_calling_2 } from 'components/material-symbols/symbols/wifi_calling_2';
import { symbol_wifi_calling_3 } from 'components/material-symbols/symbols/wifi_calling_3';
import { symbol_wifi_channel } from 'components/material-symbols/symbols/wifi_channel';
import { symbol_wifi_find } from 'components/material-symbols/symbols/wifi_find';
import { symbol_wifi_home } from 'components/material-symbols/symbols/wifi_home';
import { symbol_wifi_lock } from 'components/material-symbols/symbols/wifi_lock';
import { symbol_wifi_notification } from 'components/material-symbols/symbols/wifi_notification';
import { symbol_wifi_off } from 'components/material-symbols/symbols/wifi_off';
import { symbol_wifi_password } from 'components/material-symbols/symbols/wifi_password';
import { symbol_wifi_protected_setup } from 'components/material-symbols/symbols/wifi_protected_setup';
import { symbol_wifi_proxy } from 'components/material-symbols/symbols/wifi_proxy';
import { symbol_wifi_tethering } from 'components/material-symbols/symbols/wifi_tethering';
import { symbol_wifi_tethering_error } from 'components/material-symbols/symbols/wifi_tethering_error';
import { symbol_wifi_tethering_off } from 'components/material-symbols/symbols/wifi_tethering_off';
import { symbol_wind_power } from 'components/material-symbols/symbols/wind_power';
import { symbol_window } from 'components/material-symbols/symbols/window';
import { symbol_window_closed } from 'components/material-symbols/symbols/window_closed';
import { symbol_window_open } from 'components/material-symbols/symbols/window_open';
import { symbol_window_sensor } from 'components/material-symbols/symbols/window_sensor';
import { symbol_wine_bar } from 'components/material-symbols/symbols/wine_bar';
import { symbol_woman } from 'components/material-symbols/symbols/woman';
import { symbol_woman_2 } from 'components/material-symbols/symbols/woman_2';
import { symbol_work } from 'components/material-symbols/symbols/work';
import { symbol_work_alert } from 'components/material-symbols/symbols/work_alert';
import { symbol_work_history } from 'components/material-symbols/symbols/work_history';
import { symbol_work_update } from 'components/material-symbols/symbols/work_update';
import { symbol_workspace_premium } from 'components/material-symbols/symbols/workspace_premium';
import { symbol_workspaces } from 'components/material-symbols/symbols/workspaces';
import { symbol_wounds_injuries } from 'components/material-symbols/symbols/wounds_injuries';
import { symbol_wrap_text } from 'components/material-symbols/symbols/wrap_text';
import { symbol_wrist } from 'components/material-symbols/symbols/wrist';
import { symbol_wrong_location } from 'components/material-symbols/symbols/wrong_location';
import { symbol_wysiwyg } from 'components/material-symbols/symbols/wysiwyg';
import { symbol_yard } from 'components/material-symbols/symbols/yard';
import { symbol_your_trips } from 'components/material-symbols/symbols/your_trips';
import { symbol_youtube_activity } from 'components/material-symbols/symbols/youtube_activity';
import { symbol_youtube_searched_for } from 'components/material-symbols/symbols/youtube_searched_for';
import { symbol_zone_person_alert } from 'components/material-symbols/symbols/zone_person_alert';
import { symbol_zone_person_idle } from 'components/material-symbols/symbols/zone_person_idle';
import { symbol_zone_person_urgent } from 'components/material-symbols/symbols/zone_person_urgent';
import { symbol_zoom_in } from 'components/material-symbols/symbols/zoom_in';
import { symbol_zoom_in_map } from 'components/material-symbols/symbols/zoom_in_map';
import { symbol_zoom_out } from 'components/material-symbols/symbols/zoom_out';
import { symbol_zoom_out_map } from 'components/material-symbols/symbols/zoom_out_map';
export const MaterialSymbols = Object.freeze({
	'10k': symbol_10k,
	'10mp': symbol_10mp,
	'11mp': symbol_11mp,
	'123': symbol_123,
	'12mp': symbol_12mp,
	'13mp': symbol_13mp,
	'14mp': symbol_14mp,
	'15mp': symbol_15mp,
	'16mp': symbol_16mp,
	'17mp': symbol_17mp,
	'18_up_rating': symbol_18_up_rating,
	'18mp': symbol_18mp,
	'19mp': symbol_19mp,
	'1k': symbol_1k,
	'1k_plus': symbol_1k_plus,
	'1x_mobiledata': symbol_1x_mobiledata,
	'1x_mobiledata_badge': symbol_1x_mobiledata_badge,
	'20mp': symbol_20mp,
	'21mp': symbol_21mp,
	'22mp': symbol_22mp,
	'23mp': symbol_23mp,
	'24mp': symbol_24mp,
	'2d': symbol_2d,
	'2k': symbol_2k,
	'2k_plus': symbol_2k_plus,
	'2mp': symbol_2mp,
	'30fps': symbol_30fps,
	'30fps_select': symbol_30fps_select,
	'360': symbol_360,
	'3d_rotation': symbol_3d_rotation,
	'3g_mobiledata': symbol_3g_mobiledata,
	'3g_mobiledata_badge': symbol_3g_mobiledata_badge,
	'3k': symbol_3k,
	'3k_plus': symbol_3k_plus,
	'3mp': symbol_3mp,
	'3p': symbol_3p,
	'4g_mobiledata': symbol_4g_mobiledata,
	'4g_mobiledata_badge': symbol_4g_mobiledata_badge,
	'4g_plus_mobiledata': symbol_4g_plus_mobiledata,
	'4k': symbol_4k,
	'4k_plus': symbol_4k_plus,
	'4mp': symbol_4mp,
	'50mp': symbol_50mp,
	'5g': symbol_5g,
	'5g_mobiledata_badge': symbol_5g_mobiledata_badge,
	'5k': symbol_5k,
	'5k_plus': symbol_5k_plus,
	'5mp': symbol_5mp,
	'60fps': symbol_60fps,
	'60fps_select': symbol_60fps_select,
	'6_ft_apart': symbol_6_ft_apart,
	'6k': symbol_6k,
	'6k_plus': symbol_6k_plus,
	'6mp': symbol_6mp,
	'7k': symbol_7k,
	'7k_plus': symbol_7k_plus,
	'7mp': symbol_7mp,
	'8k': symbol_8k,
	'8k_plus': symbol_8k_plus,
	'8mp': symbol_8mp,
	'9k': symbol_9k,
	'9k_plus': symbol_9k_plus,
	'9mp': symbol_9mp,
	abc: symbol_abc,
	ac_unit: symbol_ac_unit,
	accessibility: symbol_accessibility,
	accessibility_new: symbol_accessibility_new,
	accessible: symbol_accessible,
	accessible_forward: symbol_accessible_forward,
	account_balance: symbol_account_balance,
	account_balance_wallet: symbol_account_balance_wallet,
	account_box: symbol_account_box,
	account_child: symbol_account_child,
	account_child_invert: symbol_account_child_invert,
	account_circle: symbol_account_circle,
	account_circle_off: symbol_account_circle_off,
	account_tree: symbol_account_tree,
	action_key: symbol_action_key,
	activity_zone: symbol_activity_zone,
	acute: symbol_acute,
	ad: symbol_ad,
	ad_group: symbol_ad_group,
	ad_group_off: symbol_ad_group_off,
	ad_off: symbol_ad_off,
	ad_units: symbol_ad_units,
	adaptive_audio_mic: symbol_adaptive_audio_mic,
	adaptive_audio_mic_off: symbol_adaptive_audio_mic_off,
	adb: symbol_adb,
	add: symbol_add,
	add_a_photo: symbol_add_a_photo,
	add_ad: symbol_add_ad,
	add_alert: symbol_add_alert,
	add_box: symbol_add_box,
	add_business: symbol_add_business,
	add_call: symbol_add_call,
	add_card: symbol_add_card,
	add_chart: symbol_add_chart,
	add_circle: symbol_add_circle,
	add_comment: symbol_add_comment,
	add_diamond: symbol_add_diamond,
	add_home: symbol_add_home,
	add_home_work: symbol_add_home_work,
	add_link: symbol_add_link,
	add_location: symbol_add_location,
	add_location_alt: symbol_add_location_alt,
	add_moderator: symbol_add_moderator,
	add_notes: symbol_add_notes,
	add_photo_alternate: symbol_add_photo_alternate,
	add_reaction: symbol_add_reaction,
	add_road: symbol_add_road,
	add_shopping_cart: symbol_add_shopping_cart,
	add_task: symbol_add_task,
	add_to_drive: symbol_add_to_drive,
	add_to_home_screen: symbol_add_to_home_screen,
	add_to_photos: symbol_add_to_photos,
	add_to_queue: symbol_add_to_queue,
	add_triangle: symbol_add_triangle,
	adf_scanner: symbol_adf_scanner,
	adjust: symbol_adjust,
	admin_meds: symbol_admin_meds,
	admin_panel_settings: symbol_admin_panel_settings,
	ads_click: symbol_ads_click,
	agender: symbol_agender,
	agriculture: symbol_agriculture,
	air: symbol_air,
	air_freshener: symbol_air_freshener,
	air_purifier: symbol_air_purifier,
	air_purifier_gen: symbol_air_purifier_gen,
	airline_seat_flat: symbol_airline_seat_flat,
	airline_seat_flat_angled: symbol_airline_seat_flat_angled,
	airline_seat_individual_suite: symbol_airline_seat_individual_suite,
	airline_seat_legroom_extra: symbol_airline_seat_legroom_extra,
	airline_seat_legroom_normal: symbol_airline_seat_legroom_normal,
	airline_seat_legroom_reduced: symbol_airline_seat_legroom_reduced,
	airline_seat_recline_extra: symbol_airline_seat_recline_extra,
	airline_seat_recline_normal: symbol_airline_seat_recline_normal,
	airline_stops: symbol_airline_stops,
	airlines: symbol_airlines,
	airplane_ticket: symbol_airplane_ticket,
	airplanemode_active: symbol_airplanemode_active,
	airplanemode_inactive: symbol_airplanemode_inactive,
	airplay: symbol_airplay,
	airport_shuttle: symbol_airport_shuttle,
	airware: symbol_airware,
	airwave: symbol_airwave,
	alarm: symbol_alarm,
	alarm_add: symbol_alarm_add,
	alarm_off: symbol_alarm_off,
	alarm_on: symbol_alarm_on,
	alarm_smart_wake: symbol_alarm_smart_wake,
	album: symbol_album,
	align_center: symbol_align_center,
	align_end: symbol_align_end,
	align_flex_center: symbol_align_flex_center,
	align_flex_end: symbol_align_flex_end,
	align_flex_start: symbol_align_flex_start,
	align_horizontal_center: symbol_align_horizontal_center,
	align_horizontal_left: symbol_align_horizontal_left,
	align_horizontal_right: symbol_align_horizontal_right,
	align_items_stretch: symbol_align_items_stretch,
	align_justify_center: symbol_align_justify_center,
	align_justify_flex_end: symbol_align_justify_flex_end,
	align_justify_flex_start: symbol_align_justify_flex_start,
	align_justify_space_around: symbol_align_justify_space_around,
	align_justify_space_between: symbol_align_justify_space_between,
	align_justify_space_even: symbol_align_justify_space_even,
	align_justify_stretch: symbol_align_justify_stretch,
	align_self_stretch: symbol_align_self_stretch,
	align_space_around: symbol_align_space_around,
	align_space_between: symbol_align_space_between,
	align_space_even: symbol_align_space_even,
	align_start: symbol_align_start,
	align_stretch: symbol_align_stretch,
	align_vertical_bottom: symbol_align_vertical_bottom,
	align_vertical_center: symbol_align_vertical_center,
	align_vertical_top: symbol_align_vertical_top,
	all_inbox: symbol_all_inbox,
	all_inclusive: symbol_all_inclusive,
	all_match: symbol_all_match,
	all_out: symbol_all_out,
	allergies: symbol_allergies,
	allergy: symbol_allergy,
	alt_route: symbol_alt_route,
	alternate_email: symbol_alternate_email,
	altitude: symbol_altitude,
	ambulance: symbol_ambulance,
	amend: symbol_amend,
	amp_stories: symbol_amp_stories,
	analytics: symbol_analytics,
	anchor: symbol_anchor,
	android: symbol_android,
	animated_images: symbol_animated_images,
	animation: symbol_animation,
	aod: symbol_aod,
	aod_tablet: symbol_aod_tablet,
	aod_watch: symbol_aod_watch,
	apartment: symbol_apartment,
	api: symbol_api,
	apk_document: symbol_apk_document,
	apk_install: symbol_apk_install,
	app_badging: symbol_app_badging,
	app_blocking: symbol_app_blocking,
	app_promo: symbol_app_promo,
	app_registration: symbol_app_registration,
	app_shortcut: symbol_app_shortcut,
	apparel: symbol_apparel,
	approval: symbol_approval,
	approval_delegation: symbol_approval_delegation,
	apps: symbol_apps,
	apps_outage: symbol_apps_outage,
	aq: symbol_aq,
	aq_indoor: symbol_aq_indoor,
	ar_on_you: symbol_ar_on_you,
	ar_stickers: symbol_ar_stickers,
	architecture: symbol_architecture,
	archive: symbol_archive,
	area_chart: symbol_area_chart,
	arming_countdown: symbol_arming_countdown,
	arrow_and_edge: symbol_arrow_and_edge,
	arrow_back: symbol_arrow_back,
	arrow_back_ios: symbol_arrow_back_ios,
	arrow_back_ios_new: symbol_arrow_back_ios_new,
	arrow_circle_down: symbol_arrow_circle_down,
	arrow_circle_left: symbol_arrow_circle_left,
	arrow_circle_right: symbol_arrow_circle_right,
	arrow_circle_up: symbol_arrow_circle_up,
	arrow_cool_down: symbol_arrow_cool_down,
	arrow_downward: symbol_arrow_downward,
	arrow_downward_alt: symbol_arrow_downward_alt,
	arrow_drop_down: symbol_arrow_drop_down,
	arrow_drop_down_circle: symbol_arrow_drop_down_circle,
	arrow_drop_up: symbol_arrow_drop_up,
	arrow_forward: symbol_arrow_forward,
	arrow_forward_ios: symbol_arrow_forward_ios,
	arrow_insert: symbol_arrow_insert,
	arrow_left: symbol_arrow_left,
	arrow_left_alt: symbol_arrow_left_alt,
	arrow_or_edge: symbol_arrow_or_edge,
	arrow_outward: symbol_arrow_outward,
	arrow_range: symbol_arrow_range,
	arrow_right: symbol_arrow_right,
	arrow_right_alt: symbol_arrow_right_alt,
	arrow_selector_tool: symbol_arrow_selector_tool,
	arrow_split: symbol_arrow_split,
	arrow_top_left: symbol_arrow_top_left,
	arrow_top_right: symbol_arrow_top_right,
	arrow_upward: symbol_arrow_upward,
	arrow_upward_alt: symbol_arrow_upward_alt,
	arrow_warm_up: symbol_arrow_warm_up,
	arrows_more_down: symbol_arrows_more_down,
	arrows_more_up: symbol_arrows_more_up,
	arrows_outward: symbol_arrows_outward,
	art_track: symbol_art_track,
	article: symbol_article,
	article_shortcut: symbol_article_shortcut,
	artist: symbol_artist,
	aspect_ratio: symbol_aspect_ratio,
	assignment: symbol_assignment,
	assignment_add: symbol_assignment_add,
	assignment_ind: symbol_assignment_ind,
	assignment_late: symbol_assignment_late,
	assignment_return: symbol_assignment_return,
	assignment_returned: symbol_assignment_returned,
	assignment_turned_in: symbol_assignment_turned_in,
	assist_walker: symbol_assist_walker,
	assistant_device: symbol_assistant_device,
	assistant_direction: symbol_assistant_direction,
	assistant_navigation: symbol_assistant_navigation,
	assistant_on_hub: symbol_assistant_on_hub,
	assured_workload: symbol_assured_workload,
	asterisk: symbol_asterisk,
	atm: symbol_atm,
	atr: symbol_atr,
	attach_email: symbol_attach_email,
	attach_file: symbol_attach_file,
	attach_file_add: symbol_attach_file_add,
	attach_file_off: symbol_attach_file_off,
	attach_money: symbol_attach_money,
	attachment: symbol_attachment,
	attractions: symbol_attractions,
	attribution: symbol_attribution,
	audio_description: symbol_audio_description,
	audio_file: symbol_audio_file,
	audio_video_receiver: symbol_audio_video_receiver,
	auto_awesome_mosaic: symbol_auto_awesome_mosaic,
	auto_awesome_motion: symbol_auto_awesome_motion,
	auto_delete: symbol_auto_delete,
	auto_read_pause: symbol_auto_read_pause,
	auto_read_play: symbol_auto_read_play,
	auto_stories: symbol_auto_stories,
	auto_towing: symbol_auto_towing,
	auto_transmission: symbol_auto_transmission,
	autofps_select: symbol_autofps_select,
	autopause: symbol_autopause,
	autoplay: symbol_autoplay,
	autorenew: symbol_autorenew,
	autostop: symbol_autostop,
	av1: symbol_av1,
	av_timer: symbol_av_timer,
	avc: symbol_avc,
	avg_pace: symbol_avg_pace,
	avg_time: symbol_avg_time,
	award_star: symbol_award_star,
	azm: symbol_azm,
	baby_changing_station: symbol_baby_changing_station,
	back_hand: symbol_back_hand,
	back_to_tab: symbol_back_to_tab,
	background_dot_large: symbol_background_dot_large,
	background_dot_small: symbol_background_dot_small,
	background_grid_small: symbol_background_grid_small,
	background_replace: symbol_background_replace,
	backlight_high: symbol_backlight_high,
	backlight_high_off: symbol_backlight_high_off,
	backlight_low: symbol_backlight_low,
	backpack: symbol_backpack,
	backspace: symbol_backspace,
	backup: symbol_backup,
	backup_table: symbol_backup_table,
	badge: symbol_badge,
	badge_critical_battery: symbol_badge_critical_battery,
	bakery_dining: symbol_bakery_dining,
	balance: symbol_balance,
	balcony: symbol_balcony,
	ballot: symbol_ballot,
	bar_chart: symbol_bar_chart,
	bar_chart_4_bars: symbol_bar_chart_4_bars,
	barcode: symbol_barcode,
	barcode_reader: symbol_barcode_reader,
	barcode_scanner: symbol_barcode_scanner,
	barefoot: symbol_barefoot,
	batch_prediction: symbol_batch_prediction,
	bath_outdoor: symbol_bath_outdoor,
	bath_private: symbol_bath_private,
	bath_public_large: symbol_bath_public_large,
	bathroom: symbol_bathroom,
	bathtub: symbol_bathtub,
	battery_0_bar: symbol_battery_0_bar,
	battery_1_bar: symbol_battery_1_bar,
	battery_2_bar: symbol_battery_2_bar,
	battery_3_bar: symbol_battery_3_bar,
	battery_4_bar: symbol_battery_4_bar,
	battery_5_bar: symbol_battery_5_bar,
	battery_6_bar: symbol_battery_6_bar,
	battery_alert: symbol_battery_alert,
	battery_change: symbol_battery_change,
	battery_charging_20: symbol_battery_charging_20,
	battery_charging_30: symbol_battery_charging_30,
	battery_charging_50: symbol_battery_charging_50,
	battery_charging_60: symbol_battery_charging_60,
	battery_charging_80: symbol_battery_charging_80,
	battery_charging_90: symbol_battery_charging_90,
	battery_charging_full: symbol_battery_charging_full,
	battery_error: symbol_battery_error,
	battery_full: symbol_battery_full,
	battery_full_alt: symbol_battery_full_alt,
	battery_horiz_000: symbol_battery_horiz_000,
	battery_horiz_050: symbol_battery_horiz_050,
	battery_horiz_075: symbol_battery_horiz_075,
	battery_low: symbol_battery_low,
	battery_plus: symbol_battery_plus,
	battery_profile: symbol_battery_profile,
	battery_saver: symbol_battery_saver,
	battery_share: symbol_battery_share,
	battery_status_good: symbol_battery_status_good,
	battery_unknown: symbol_battery_unknown,
	battery_very_low: symbol_battery_very_low,
	beach_access: symbol_beach_access,
	bed: symbol_bed,
	bedroom_baby: symbol_bedroom_baby,
	bedroom_child: symbol_bedroom_child,
	bedroom_parent: symbol_bedroom_parent,
	bedtime: symbol_bedtime,
	bedtime_off: symbol_bedtime_off,
	beenhere: symbol_beenhere,
	bento: symbol_bento,
	bia: symbol_bia,
	bid_landscape: symbol_bid_landscape,
	bid_landscape_disabled: symbol_bid_landscape_disabled,
	bigtop_updates: symbol_bigtop_updates,
	bike_scooter: symbol_bike_scooter,
	biotech: symbol_biotech,
	blanket: symbol_blanket,
	blender: symbol_blender,
	blind: symbol_blind,
	blinds: symbol_blinds,
	blinds_closed: symbol_blinds_closed,
	block: symbol_block,
	blood_pressure: symbol_blood_pressure,
	bloodtype: symbol_bloodtype,
	bluetooth: symbol_bluetooth,
	bluetooth_connected: symbol_bluetooth_connected,
	bluetooth_disabled: symbol_bluetooth_disabled,
	bluetooth_drive: symbol_bluetooth_drive,
	bluetooth_searching: symbol_bluetooth_searching,
	blur_circular: symbol_blur_circular,
	blur_linear: symbol_blur_linear,
	blur_medium: symbol_blur_medium,
	blur_off: symbol_blur_off,
	blur_on: symbol_blur_on,
	blur_short: symbol_blur_short,
	body_fat: symbol_body_fat,
	body_system: symbol_body_system,
	bolt: symbol_bolt,
	bomb: symbol_bomb,
	book: symbol_book,
	book_2: symbol_book_2,
	book_3: symbol_book_3,
	book_4: symbol_book_4,
	book_5: symbol_book_5,
	book_online: symbol_book_online,
	bookmark: symbol_bookmark,
	bookmark_add: symbol_bookmark_add,
	bookmark_added: symbol_bookmark_added,
	bookmark_manager: symbol_bookmark_manager,
	bookmark_remove: symbol_bookmark_remove,
	bookmarks: symbol_bookmarks,
	border_all: symbol_border_all,
	border_bottom: symbol_border_bottom,
	border_clear: symbol_border_clear,
	border_color: symbol_border_color,
	border_horizontal: symbol_border_horizontal,
	border_inner: symbol_border_inner,
	border_left: symbol_border_left,
	border_outer: symbol_border_outer,
	border_right: symbol_border_right,
	border_style: symbol_border_style,
	border_top: symbol_border_top,
	border_vertical: symbol_border_vertical,
	bottom_app_bar: symbol_bottom_app_bar,
	bottom_drawer: symbol_bottom_drawer,
	bottom_navigation: symbol_bottom_navigation,
	bottom_panel_close: symbol_bottom_panel_close,
	bottom_panel_open: symbol_bottom_panel_open,
	bottom_right_click: symbol_bottom_right_click,
	bottom_sheets: symbol_bottom_sheets,
	box: symbol_box,
	box_add: symbol_box_add,
	box_edit: symbol_box_edit,
	boy: symbol_boy,
	brand_awareness: symbol_brand_awareness,
	brand_family: symbol_brand_family,
	branding_watermark: symbol_branding_watermark,
	breakfast_dining: symbol_breakfast_dining,
	breaking_news: symbol_breaking_news,
	breaking_news_alt_1: symbol_breaking_news_alt_1,
	breastfeeding: symbol_breastfeeding,
	brightness_1: symbol_brightness_1,
	brightness_2: symbol_brightness_2,
	brightness_3: symbol_brightness_3,
	brightness_4: symbol_brightness_4,
	brightness_5: symbol_brightness_5,
	brightness_6: symbol_brightness_6,
	brightness_7: symbol_brightness_7,
	brightness_alert: symbol_brightness_alert,
	brightness_auto: symbol_brightness_auto,
	brightness_empty: symbol_brightness_empty,
	brightness_high: symbol_brightness_high,
	brightness_low: symbol_brightness_low,
	brightness_medium: symbol_brightness_medium,
	bring_your_own_ip: symbol_bring_your_own_ip,
	broadcast_on_home: symbol_broadcast_on_home,
	broadcast_on_personal: symbol_broadcast_on_personal,
	broken_image: symbol_broken_image,
	browse: symbol_browse,
	browse_activity: symbol_browse_activity,
	browse_gallery: symbol_browse_gallery,
	browser_updated: symbol_browser_updated,
	brunch_dining: symbol_brunch_dining,
	brush: symbol_brush,
	bubble: symbol_bubble,
	bubble_chart: symbol_bubble_chart,
	bubbles: symbol_bubbles,
	bug_report: symbol_bug_report,
	build: symbol_build,
	build_circle: symbol_build_circle,
	bungalow: symbol_bungalow,
	burst_mode: symbol_burst_mode,
	bus_alert: symbol_bus_alert,
	business_center: symbol_business_center,
	business_chip: symbol_business_chip,
	business_messages: symbol_business_messages,
	buttons_alt: symbol_buttons_alt,
	cabin: symbol_cabin,
	cable: symbol_cable,
	cached: symbol_cached,
	cadence: symbol_cadence,
	cake: symbol_cake,
	cake_add: symbol_cake_add,
	calculate: symbol_calculate,
	calendar_add_on: symbol_calendar_add_on,
	calendar_apps_script: symbol_calendar_apps_script,
	calendar_clock: symbol_calendar_clock,
	calendar_month: symbol_calendar_month,
	calendar_today: symbol_calendar_today,
	calendar_view_day: symbol_calendar_view_day,
	calendar_view_month: symbol_calendar_view_month,
	calendar_view_week: symbol_calendar_view_week,
	call: symbol_call,
	call_end: symbol_call_end,
	call_log: symbol_call_log,
	call_made: symbol_call_made,
	call_merge: symbol_call_merge,
	call_missed: symbol_call_missed,
	call_missed_outgoing: symbol_call_missed_outgoing,
	call_quality: symbol_call_quality,
	call_received: symbol_call_received,
	call_split: symbol_call_split,
	call_to_action: symbol_call_to_action,
	camera: symbol_camera,
	camera_front: symbol_camera_front,
	camera_indoor: symbol_camera_indoor,
	camera_outdoor: symbol_camera_outdoor,
	camera_rear: symbol_camera_rear,
	camera_roll: symbol_camera_roll,
	camera_video: symbol_camera_video,
	cameraswitch: symbol_cameraswitch,
	campaign: symbol_campaign,
	camping: symbol_camping,
	cancel: symbol_cancel,
	cancel_presentation: symbol_cancel_presentation,
	cancel_schedule_send: symbol_cancel_schedule_send,
	candle: symbol_candle,
	candlestick_chart: symbol_candlestick_chart,
	captive_portal: symbol_captive_portal,
	capture: symbol_capture,
	car_crash: symbol_car_crash,
	car_rental: symbol_car_rental,
	car_repair: symbol_car_repair,
	car_tag: symbol_car_tag,
	card_membership: symbol_card_membership,
	card_travel: symbol_card_travel,
	cardio_load: symbol_cardio_load,
	cardiology: symbol_cardiology,
	cards: symbol_cards,
	carpenter: symbol_carpenter,
	carry_on_bag: symbol_carry_on_bag,
	carry_on_bag_checked: symbol_carry_on_bag_checked,
	carry_on_bag_inactive: symbol_carry_on_bag_inactive,
	carry_on_bag_question: symbol_carry_on_bag_question,
	cases: symbol_cases,
	casino: symbol_casino,
	cast: symbol_cast,
	cast_connected: symbol_cast_connected,
	cast_for_education: symbol_cast_for_education,
	cast_pause: symbol_cast_pause,
	cast_warning: symbol_cast_warning,
	castle: symbol_castle,
	category: symbol_category,
	celebration: symbol_celebration,
	cell_merge: symbol_cell_merge,
	cell_tower: symbol_cell_tower,
	cell_wifi: symbol_cell_wifi,
	center_focus_strong: symbol_center_focus_strong,
	center_focus_weak: symbol_center_focus_weak,
	chair: symbol_chair,
	chair_alt: symbol_chair_alt,
	chalet: symbol_chalet,
	change_circle: symbol_change_circle,
	change_history: symbol_change_history,
	charger: symbol_charger,
	charging_station: symbol_charging_station,
	chart_data: symbol_chart_data,
	chat: symbol_chat,
	chat_add_on: symbol_chat_add_on,
	chat_apps_script: symbol_chat_apps_script,
	chat_bubble: symbol_chat_bubble,
	chat_error: symbol_chat_error,
	chat_info: symbol_chat_info,
	chat_paste_go: symbol_chat_paste_go,
	check: symbol_check,
	check_box: symbol_check_box,
	check_box_outline_blank: symbol_check_box_outline_blank,
	check_circle: symbol_check_circle,
	check_in_out: symbol_check_in_out,
	check_indeterminate_small: symbol_check_indeterminate_small,
	check_small: symbol_check_small,
	checkbook: symbol_checkbook,
	checked_bag: symbol_checked_bag,
	checked_bag_question: symbol_checked_bag_question,
	checklist: symbol_checklist,
	checklist_rtl: symbol_checklist_rtl,
	checkroom: symbol_checkroom,
	cheer: symbol_cheer,
	chess: symbol_chess,
	chevron_left: symbol_chevron_left,
	chevron_right: symbol_chevron_right,
	child_care: symbol_child_care,
	child_friendly: symbol_child_friendly,
	chip_extraction: symbol_chip_extraction,
	chips: symbol_chips,
	chrome_reader_mode: symbol_chrome_reader_mode,
	chromecast_2: symbol_chromecast_2,
	chromecast_device: symbol_chromecast_device,
	chronic: symbol_chronic,
	church: symbol_church,
	cinematic_blur: symbol_cinematic_blur,
	circle: symbol_circle,
	circle_notifications: symbol_circle_notifications,
	circles: symbol_circles,
	circles_ext: symbol_circles_ext,
	clarify: symbol_clarify,
	clean_hands: symbol_clean_hands,
	cleaning: symbol_cleaning,
	cleaning_bucket: symbol_cleaning_bucket,
	cleaning_services: symbol_cleaning_services,
	clear_all: symbol_clear_all,
	clear_day: symbol_clear_day,
	clear_night: symbol_clear_night,
	climate_mini_split: symbol_climate_mini_split,
	clinical_notes: symbol_clinical_notes,
	clock_loader_10: symbol_clock_loader_10,
	clock_loader_20: symbol_clock_loader_20,
	clock_loader_40: symbol_clock_loader_40,
	clock_loader_60: symbol_clock_loader_60,
	clock_loader_80: symbol_clock_loader_80,
	clock_loader_90: symbol_clock_loader_90,
	close: symbol_close,
	close_fullscreen: symbol_close_fullscreen,
	close_small: symbol_close_small,
	closed_caption: symbol_closed_caption,
	closed_caption_add: symbol_closed_caption_add,
	closed_caption_disabled: symbol_closed_caption_disabled,
	cloud: symbol_cloud,
	cloud_circle: symbol_cloud_circle,
	cloud_done: symbol_cloud_done,
	cloud_download: symbol_cloud_download,
	cloud_off: symbol_cloud_off,
	cloud_sync: symbol_cloud_sync,
	cloud_upload: symbol_cloud_upload,
	cloudy_snowing: symbol_cloudy_snowing,
	co2: symbol_co2,
	co_present: symbol_co_present,
	code: symbol_code,
	code_blocks: symbol_code_blocks,
	code_off: symbol_code_off,
	coffee: symbol_coffee,
	coffee_maker: symbol_coffee_maker,
	cognition: symbol_cognition,
	collapse_all: symbol_collapse_all,
	collapse_content: symbol_collapse_content,
	collections_bookmark: symbol_collections_bookmark,
	colorize: symbol_colorize,
	colors: symbol_colors,
	comedy_mask: symbol_comedy_mask,
	comic_bubble: symbol_comic_bubble,
	comment: symbol_comment,
	comment_bank: symbol_comment_bank,
	comments_disabled: symbol_comments_disabled,
	commit: symbol_commit,
	communication: symbol_communication,
	communities: symbol_communities,
	commute: symbol_commute,
	compare: symbol_compare,
	compare_arrows: symbol_compare_arrows,
	compass_calibration: symbol_compass_calibration,
	component_exchange: symbol_component_exchange,
	compost: symbol_compost,
	compress: symbol_compress,
	computer: symbol_computer,
	concierge: symbol_concierge,
	conditions: symbol_conditions,
	confirmation_number: symbol_confirmation_number,
	congenital: symbol_congenital,
	connect_without_contact: symbol_connect_without_contact,
	connected_tv: symbol_connected_tv,
	connecting_airports: symbol_connecting_airports,
	construction: symbol_construction,
	contact_emergency: symbol_contact_emergency,
	contact_mail: symbol_contact_mail,
	contact_page: symbol_contact_page,
	contact_phone: symbol_contact_phone,
	contact_support: symbol_contact_support,
	contactless: symbol_contactless,
	contactless_off: symbol_contactless_off,
	contacts: symbol_contacts,
	contacts_product: symbol_contacts_product,
	content_copy: symbol_content_copy,
	content_cut: symbol_content_cut,
	content_paste: symbol_content_paste,
	content_paste_go: symbol_content_paste_go,
	content_paste_off: symbol_content_paste_off,
	content_paste_search: symbol_content_paste_search,
	contract: symbol_contract,
	contract_delete: symbol_contract_delete,
	contract_edit: symbol_contract_edit,
	contrast: symbol_contrast,
	contrast_circle: symbol_contrast_circle,
	contrast_rtl_off: symbol_contrast_rtl_off,
	contrast_square: symbol_contrast_square,
	control_camera: symbol_control_camera,
	control_point_duplicate: symbol_control_point_duplicate,
	controller_gen: symbol_controller_gen,
	conversion_path: symbol_conversion_path,
	conversion_path_off: symbol_conversion_path_off,
	conveyor_belt: symbol_conveyor_belt,
	cookie: symbol_cookie,
	cookie_off: symbol_cookie_off,
	cooking: symbol_cooking,
	cool_to_dry: symbol_cool_to_dry,
	copy_all: symbol_copy_all,
	copyright: symbol_copyright,
	coronavirus: symbol_coronavirus,
	corporate_fare: symbol_corporate_fare,
	cottage: symbol_cottage,
	counter_0: symbol_counter_0,
	counter_1: symbol_counter_1,
	counter_2: symbol_counter_2,
	counter_3: symbol_counter_3,
	counter_4: symbol_counter_4,
	counter_5: symbol_counter_5,
	counter_6: symbol_counter_6,
	counter_7: symbol_counter_7,
	counter_8: symbol_counter_8,
	counter_9: symbol_counter_9,
	countertops: symbol_countertops,
	create_new_folder: symbol_create_new_folder,
	credit_card: symbol_credit_card,
	credit_card_gear: symbol_credit_card_gear,
	credit_card_heart: symbol_credit_card_heart,
	credit_card_off: symbol_credit_card_off,
	credit_score: symbol_credit_score,
	crib: symbol_crib,
	crisis_alert: symbol_crisis_alert,
	crop: symbol_crop,
	crop_16_9: symbol_crop_16_9,
	crop_3_2: symbol_crop_3_2,
	crop_5_4: symbol_crop_5_4,
	crop_7_5: symbol_crop_7_5,
	crop_9_16: symbol_crop_9_16,
	crop_free: symbol_crop_free,
	crop_landscape: symbol_crop_landscape,
	crop_portrait: symbol_crop_portrait,
	crop_rotate: symbol_crop_rotate,
	crop_square: symbol_crop_square,
	crossword: symbol_crossword,
	crowdsource: symbol_crowdsource,
	cruelty_free: symbol_cruelty_free,
	css: symbol_css,
	csv: symbol_csv,
	currency_bitcoin: symbol_currency_bitcoin,
	currency_exchange: symbol_currency_exchange,
	currency_franc: symbol_currency_franc,
	currency_lira: symbol_currency_lira,
	currency_pound: symbol_currency_pound,
	currency_ruble: symbol_currency_ruble,
	currency_rupee: symbol_currency_rupee,
	currency_yen: symbol_currency_yen,
	currency_yuan: symbol_currency_yuan,
	curtains: symbol_curtains,
	curtains_closed: symbol_curtains_closed,
	custom_typography: symbol_custom_typography,
	cut: symbol_cut,
	cycle: symbol_cycle,
	cyclone: symbol_cyclone,
	dangerous: symbol_dangerous,
	dark_mode: symbol_dark_mode,
	dashboard: symbol_dashboard,
	dashboard_customize: symbol_dashboard_customize,
	data_alert: symbol_data_alert,
	data_array: symbol_data_array,
	data_check: symbol_data_check,
	data_exploration: symbol_data_exploration,
	data_info_alert: symbol_data_info_alert,
	data_loss_prevention: symbol_data_loss_prevention,
	data_object: symbol_data_object,
	data_saver_on: symbol_data_saver_on,
	data_table: symbol_data_table,
	data_thresholding: symbol_data_thresholding,
	data_usage: symbol_data_usage,
	database: symbol_database,
	dataset: symbol_dataset,
	dataset_linked: symbol_dataset_linked,
	date_range: symbol_date_range,
	deblur: symbol_deblur,
	deceased: symbol_deceased,
	decimal_decrease: symbol_decimal_decrease,
	decimal_increase: symbol_decimal_increase,
	deck: symbol_deck,
	dehaze: symbol_dehaze,
	delete: symbol_delete,
	delete_forever: symbol_delete_forever,
	delete_history: symbol_delete_history,
	delete_sweep: symbol_delete_sweep,
	demography: symbol_demography,
	density_large: symbol_density_large,
	density_medium: symbol_density_medium,
	density_small: symbol_density_small,
	dentistry: symbol_dentistry,
	departure_board: symbol_departure_board,
	deployed_code: symbol_deployed_code,
	deployed_code_account: symbol_deployed_code_account,
	deployed_code_alert: symbol_deployed_code_alert,
	deployed_code_history: symbol_deployed_code_history,
	deployed_code_update: symbol_deployed_code_update,
	dermatology: symbol_dermatology,
	description: symbol_description,
	deselect: symbol_deselect,
	design_services: symbol_design_services,
	desk: symbol_desk,
	deskphone: symbol_deskphone,
	desktop_access_disabled: symbol_desktop_access_disabled,
	desktop_mac: symbol_desktop_mac,
	desktop_windows: symbol_desktop_windows,
	destruction: symbol_destruction,
	details: symbol_details,
	detection_and_zone: symbol_detection_and_zone,
	detector: symbol_detector,
	detector_alarm: symbol_detector_alarm,
	detector_battery: symbol_detector_battery,
	detector_co: symbol_detector_co,
	detector_offline: symbol_detector_offline,
	detector_smoke: symbol_detector_smoke,
	detector_status: symbol_detector_status,
	developer_board: symbol_developer_board,
	developer_board_off: symbol_developer_board_off,
	developer_guide: symbol_developer_guide,
	developer_mode: symbol_developer_mode,
	developer_mode_tv: symbol_developer_mode_tv,
	device_hub: symbol_device_hub,
	device_reset: symbol_device_reset,
	device_thermostat: symbol_device_thermostat,
	device_unknown: symbol_device_unknown,
	devices: symbol_devices,
	devices_fold: symbol_devices_fold,
	devices_off: symbol_devices_off,
	devices_other: symbol_devices_other,
	devices_wearables: symbol_devices_wearables,
	dew_point: symbol_dew_point,
	diagnosis: symbol_diagnosis,
	dialer_sip: symbol_dialer_sip,
	dialogs: symbol_dialogs,
	dialpad: symbol_dialpad,
	diamond: symbol_diamond,
	dictionary: symbol_dictionary,
	difference: symbol_difference,
	digital_out_of_home: symbol_digital_out_of_home,
	digital_wellbeing: symbol_digital_wellbeing,
	dining: symbol_dining,
	dinner_dining: symbol_dinner_dining,
	directions: symbol_directions,
	directions_alt: symbol_directions_alt,
	directions_alt_off: symbol_directions_alt_off,
	directions_bike: symbol_directions_bike,
	directions_boat: symbol_directions_boat,
	directions_bus: symbol_directions_bus,
	directions_car: symbol_directions_car,
	directions_off: symbol_directions_off,
	directions_railway: symbol_directions_railway,
	directions_run: symbol_directions_run,
	directions_subway: symbol_directions_subway,
	directions_walk: symbol_directions_walk,
	directory_sync: symbol_directory_sync,
	dirty_lens: symbol_dirty_lens,
	disabled_by_default: symbol_disabled_by_default,
	disabled_visible: symbol_disabled_visible,
	disc_full: symbol_disc_full,
	discover_tune: symbol_discover_tune,
	dishwasher: symbol_dishwasher,
	dishwasher_gen: symbol_dishwasher_gen,
	display_external_input: symbol_display_external_input,
	display_settings: symbol_display_settings,
	distance: symbol_distance,
	diversity_1: symbol_diversity_1,
	diversity_2: symbol_diversity_2,
	diversity_3: symbol_diversity_3,
	diversity_4: symbol_diversity_4,
	dns: symbol_dns,
	do_not_disturb_off: symbol_do_not_disturb_off,
	do_not_disturb_on: symbol_do_not_disturb_on,
	do_not_disturb_on_total_silence: symbol_do_not_disturb_on_total_silence,
	do_not_step: symbol_do_not_step,
	do_not_touch: symbol_do_not_touch,
	dock: symbol_dock,
	dock_to_bottom: symbol_dock_to_bottom,
	dock_to_left: symbol_dock_to_left,
	dock_to_right: symbol_dock_to_right,
	docs_add_on: symbol_docs_add_on,
	docs_apps_script: symbol_docs_apps_script,
	document_scanner: symbol_document_scanner,
	domain: symbol_domain,
	domain_add: symbol_domain_add,
	domain_disabled: symbol_domain_disabled,
	domain_verification: symbol_domain_verification,
	domain_verification_off: symbol_domain_verification_off,
	domino_mask: symbol_domino_mask,
	done: symbol_done,
	done_all: symbol_done_all,
	done_outline: symbol_done_outline,
	donut_large: symbol_donut_large,
	donut_small: symbol_donut_small,
	door_back: symbol_door_back,
	door_front: symbol_door_front,
	door_open: symbol_door_open,
	door_sensor: symbol_door_sensor,
	door_sliding: symbol_door_sliding,
	doorbell: symbol_doorbell,
	doorbell_3p: symbol_doorbell_3p,
	doorbell_chime: symbol_doorbell_chime,
	double_arrow: symbol_double_arrow,
	downhill_skiing: symbol_downhill_skiing,
	download: symbol_download,
	download_2: symbol_download_2,
	download_done: symbol_download_done,
	download_for_offline: symbol_download_for_offline,
	downloading: symbol_downloading,
	draft: symbol_draft,
	draft_orders: symbol_draft_orders,
	drafts: symbol_drafts,
	drag_click: symbol_drag_click,
	drag_handle: symbol_drag_handle,
	drag_indicator: symbol_drag_indicator,
	drag_pan: symbol_drag_pan,
	draw: symbol_draw,
	draw_abstract: symbol_draw_abstract,
	draw_collage: symbol_draw_collage,
	dresser: symbol_dresser,
	drive_file_move: symbol_drive_file_move,
	drive_folder_upload: symbol_drive_folder_upload,
	dropdown: symbol_dropdown,
	dry: symbol_dry,
	dry_cleaning: symbol_dry_cleaning,
	dual_screen: symbol_dual_screen,
	duo: symbol_duo,
	dvr: symbol_dvr,
	dynamic_feed: symbol_dynamic_feed,
	dynamic_form: symbol_dynamic_form,
	e911_avatar: symbol_e911_avatar,
	e911_emergency: symbol_e911_emergency,
	e_mobiledata: symbol_e_mobiledata,
	e_mobiledata_badge: symbol_e_mobiledata_badge,
	earbuds: symbol_earbuds,
	earbuds_battery: symbol_earbuds_battery,
	early_on: symbol_early_on,
	earthquake: symbol_earthquake,
	east: symbol_east,
	ecg: symbol_ecg,
	ecg_heart: symbol_ecg_heart,
	eco: symbol_eco,
	eda: symbol_eda,
	edgesensor_high: symbol_edgesensor_high,
	edgesensor_low: symbol_edgesensor_low,
	edit: symbol_edit,
	edit_attributes: symbol_edit_attributes,
	edit_calendar: symbol_edit_calendar,
	edit_document: symbol_edit_document,
	edit_location: symbol_edit_location,
	edit_location_alt: symbol_edit_location_alt,
	edit_note: symbol_edit_note,
	edit_notifications: symbol_edit_notifications,
	edit_off: symbol_edit_off,
	edit_road: symbol_edit_road,
	edit_square: symbol_edit_square,
	editor_choice: symbol_editor_choice,
	egg: symbol_egg,
	egg_alt: symbol_egg_alt,
	eject: symbol_eject,
	elderly: symbol_elderly,
	elderly_woman: symbol_elderly_woman,
	electric_bike: symbol_electric_bike,
	electric_bolt: symbol_electric_bolt,
	electric_car: symbol_electric_car,
	electric_meter: symbol_electric_meter,
	electric_moped: symbol_electric_moped,
	electric_rickshaw: symbol_electric_rickshaw,
	electric_scooter: symbol_electric_scooter,
	electrical_services: symbol_electrical_services,
	elevation: symbol_elevation,
	elevator: symbol_elevator,
	emergency: symbol_emergency,
	emergency_heat: symbol_emergency_heat,
	emergency_heat_2: symbol_emergency_heat_2,
	emergency_home: symbol_emergency_home,
	emergency_recording: symbol_emergency_recording,
	emergency_share: symbol_emergency_share,
	emergency_share_off: symbol_emergency_share_off,
	emoji_events: symbol_emoji_events,
	emoji_flags: symbol_emoji_flags,
	emoji_food_beverage: symbol_emoji_food_beverage,
	emoji_language: symbol_emoji_language,
	emoji_nature: symbol_emoji_nature,
	emoji_objects: symbol_emoji_objects,
	emoji_people: symbol_emoji_people,
	emoji_symbols: symbol_emoji_symbols,
	emoji_transportation: symbol_emoji_transportation,
	emoticon: symbol_emoticon,
	empty_dashboard: symbol_empty_dashboard,
	enable: symbol_enable,
	encrypted: symbol_encrypted,
	endocrinology: symbol_endocrinology,
	energy: symbol_energy,
	energy_program_saving: symbol_energy_program_saving,
	energy_program_time_used: symbol_energy_program_time_used,
	energy_savings_leaf: symbol_energy_savings_leaf,
	engineering: symbol_engineering,
	enhanced_encryption: symbol_enhanced_encryption,
	ent: symbol_ent,
	enterprise: symbol_enterprise,
	enterprise_off: symbol_enterprise_off,
	equal: symbol_equal,
	equalizer: symbol_equalizer,
	error: symbol_error,
	error_med: symbol_error_med,
	escalator: symbol_escalator,
	escalator_warning: symbol_escalator_warning,
	euro: symbol_euro,
	euro_symbol: symbol_euro_symbol,
	ev_charger: symbol_ev_charger,
	ev_mobiledata_badge: symbol_ev_mobiledata_badge,
	ev_shadow: symbol_ev_shadow,
	ev_shadow_add: symbol_ev_shadow_add,
	ev_shadow_minus: symbol_ev_shadow_minus,
	ev_station: symbol_ev_station,
	event: symbol_event,
	event_available: symbol_event_available,
	event_busy: symbol_event_busy,
	event_list: symbol_event_list,
	event_note: symbol_event_note,
	event_repeat: symbol_event_repeat,
	event_seat: symbol_event_seat,
	event_upcoming: symbol_event_upcoming,
	exclamation: symbol_exclamation,
	exercise: symbol_exercise,
	exit_to_app: symbol_exit_to_app,
	expand: symbol_expand,
	expand_all: symbol_expand_all,
	expand_circle_down: symbol_expand_circle_down,
	expand_circle_right: symbol_expand_circle_right,
	expand_circle_up: symbol_expand_circle_up,
	expand_content: symbol_expand_content,
	expand_less: symbol_expand_less,
	expand_more: symbol_expand_more,
	experiment: symbol_experiment,
	explicit: symbol_explicit,
	explore: symbol_explore,
	explore_nearby: symbol_explore_nearby,
	explore_off: symbol_explore_off,
	explosion: symbol_explosion,
	export_notes: symbol_export_notes,
	exposure: symbol_exposure,
	exposure_neg_1: symbol_exposure_neg_1,
	exposure_neg_2: symbol_exposure_neg_2,
	exposure_plus_1: symbol_exposure_plus_1,
	exposure_plus_2: symbol_exposure_plus_2,
	exposure_zero: symbol_exposure_zero,
	extension: symbol_extension,
	extension_off: symbol_extension_off,
	eye_tracking: symbol_eye_tracking,
	eyeglasses: symbol_eyeglasses,
	face: symbol_face,
	face_2: symbol_face_2,
	face_3: symbol_face_3,
	face_4: symbol_face_4,
	face_5: symbol_face_5,
	face_6: symbol_face_6,
	face_retouching_off: symbol_face_retouching_off,
	fact_check: symbol_fact_check,
	factory: symbol_factory,
	falling: symbol_falling,
	familiar_face_and_zone: symbol_familiar_face_and_zone,
	family_history: symbol_family_history,
	family_home: symbol_family_home,
	family_link: symbol_family_link,
	family_restroom: symbol_family_restroom,
	family_star: symbol_family_star,
	farsight_digital: symbol_farsight_digital,
	fast_forward: symbol_fast_forward,
	fast_rewind: symbol_fast_rewind,
	fastfood: symbol_fastfood,
	faucet: symbol_faucet,
	favorite: symbol_favorite,
	fax: symbol_fax,
	feature_search: symbol_feature_search,
	featured_play_list: symbol_featured_play_list,
	featured_seasonal_and_gifts: symbol_featured_seasonal_and_gifts,
	featured_video: symbol_featured_video,
	feed: symbol_feed,
	feedback: symbol_feedback,
	female: symbol_female,
	femur: symbol_femur,
	femur_alt: symbol_femur_alt,
	fence: symbol_fence,
	fertile: symbol_fertile,
	festival: symbol_festival,
	fiber_dvr: symbol_fiber_dvr,
	fiber_manual_record: symbol_fiber_manual_record,
	fiber_new: symbol_fiber_new,
	fiber_pin: symbol_fiber_pin,
	fiber_smart_record: symbol_fiber_smart_record,
	file_copy: symbol_file_copy,
	file_copy_off: symbol_file_copy_off,
	file_download_done: symbol_file_download_done,
	file_download_off: symbol_file_download_off,
	file_map: symbol_file_map,
	file_open: symbol_file_open,
	file_present: symbol_file_present,
	file_save: symbol_file_save,
	file_save_off: symbol_file_save_off,
	file_upload_off: symbol_file_upload_off,
	filter: symbol_filter,
	filter_1: symbol_filter_1,
	filter_2: symbol_filter_2,
	filter_3: symbol_filter_3,
	filter_4: symbol_filter_4,
	filter_5: symbol_filter_5,
	filter_6: symbol_filter_6,
	filter_7: symbol_filter_7,
	filter_8: symbol_filter_8,
	filter_9: symbol_filter_9,
	filter_9_plus: symbol_filter_9_plus,
	filter_alt: symbol_filter_alt,
	filter_alt_off: symbol_filter_alt_off,
	filter_b_and_w: symbol_filter_b_and_w,
	filter_center_focus: symbol_filter_center_focus,
	filter_drama: symbol_filter_drama,
	filter_frames: symbol_filter_frames,
	filter_hdr: symbol_filter_hdr,
	filter_list: symbol_filter_list,
	filter_list_off: symbol_filter_list_off,
	filter_none: symbol_filter_none,
	filter_retrolux: symbol_filter_retrolux,
	filter_tilt_shift: symbol_filter_tilt_shift,
	filter_vintage: symbol_filter_vintage,
	finance: symbol_finance,
	finance_chip: symbol_finance_chip,
	finance_mode: symbol_finance_mode,
	find_in_page: symbol_find_in_page,
	find_replace: symbol_find_replace,
	fingerprint: symbol_fingerprint,
	fingerprint_off: symbol_fingerprint_off,
	fire_extinguisher: symbol_fire_extinguisher,
	fire_hydrant: symbol_fire_hydrant,
	fire_truck: symbol_fire_truck,
	fireplace: symbol_fireplace,
	first_page: symbol_first_page,
	fit_page: symbol_fit_page,
	fit_screen: symbol_fit_screen,
	fit_width: symbol_fit_width,
	fitness_center: symbol_fitness_center,
	flag: symbol_flag,
	flag_circle: symbol_flag_circle,
	flaky: symbol_flaky,
	flare: symbol_flare,
	flash_auto: symbol_flash_auto,
	flash_off: symbol_flash_off,
	flash_on: symbol_flash_on,
	flashlight_off: symbol_flashlight_off,
	flashlight_on: symbol_flashlight_on,
	flatware: symbol_flatware,
	flex_direction: symbol_flex_direction,
	flex_no_wrap: symbol_flex_no_wrap,
	flex_wrap: symbol_flex_wrap,
	flight: symbol_flight,
	flight_class: symbol_flight_class,
	flight_land: symbol_flight_land,
	flight_takeoff: symbol_flight_takeoff,
	flights_and_hotels: symbol_flights_and_hotels,
	flightsmode: symbol_flightsmode,
	flip: symbol_flip,
	flip_camera_android: symbol_flip_camera_android,
	flip_camera_ios: symbol_flip_camera_ios,
	flip_to_back: symbol_flip_to_back,
	flip_to_front: symbol_flip_to_front,
	flood: symbol_flood,
	floor: symbol_floor,
	floor_lamp: symbol_floor_lamp,
	flowsheet: symbol_flowsheet,
	fluid: symbol_fluid,
	fluid_balance: symbol_fluid_balance,
	fluid_med: symbol_fluid_med,
	fluorescent: symbol_fluorescent,
	flutter: symbol_flutter,
	flutter_dash: symbol_flutter_dash,
	fmd_bad: symbol_fmd_bad,
	foggy: symbol_foggy,
	folded_hands: symbol_folded_hands,
	folder: symbol_folder,
	folder_copy: symbol_folder_copy,
	folder_data: symbol_folder_data,
	folder_delete: symbol_folder_delete,
	folder_limited: symbol_folder_limited,
	folder_managed: symbol_folder_managed,
	folder_off: symbol_folder_off,
	folder_open: symbol_folder_open,
	folder_shared: symbol_folder_shared,
	folder_special: symbol_folder_special,
	folder_supervised: symbol_folder_supervised,
	folder_zip: symbol_folder_zip,
	follow_the_signs: symbol_follow_the_signs,
	font_download: symbol_font_download,
	font_download_off: symbol_font_download_off,
	food_bank: symbol_food_bank,
	foot_bones: symbol_foot_bones,
	footprint: symbol_footprint,
	for_you: symbol_for_you,
	forest: symbol_forest,
	fork_left: symbol_fork_left,
	fork_right: symbol_fork_right,
	forklift: symbol_forklift,
	format_align_center: symbol_format_align_center,
	format_align_justify: symbol_format_align_justify,
	format_align_left: symbol_format_align_left,
	format_align_right: symbol_format_align_right,
	format_bold: symbol_format_bold,
	format_clear: symbol_format_clear,
	format_color_fill: symbol_format_color_fill,
	format_color_reset: symbol_format_color_reset,
	format_color_text: symbol_format_color_text,
	format_h1: symbol_format_h1,
	format_h2: symbol_format_h2,
	format_h3: symbol_format_h3,
	format_h4: symbol_format_h4,
	format_h5: symbol_format_h5,
	format_h6: symbol_format_h6,
	format_image_left: symbol_format_image_left,
	format_image_right: symbol_format_image_right,
	format_indent_decrease: symbol_format_indent_decrease,
	format_indent_increase: symbol_format_indent_increase,
	format_ink_highlighter: symbol_format_ink_highlighter,
	format_italic: symbol_format_italic,
	format_letter_spacing: symbol_format_letter_spacing,
	format_letter_spacing_2: symbol_format_letter_spacing_2,
	format_letter_spacing_standard: symbol_format_letter_spacing_standard,
	format_letter_spacing_wide: symbol_format_letter_spacing_wide,
	format_letter_spacing_wider: symbol_format_letter_spacing_wider,
	format_line_spacing: symbol_format_line_spacing,
	format_list_bulleted: symbol_format_list_bulleted,
	format_list_bulleted_add: symbol_format_list_bulleted_add,
	format_list_numbered: symbol_format_list_numbered,
	format_list_numbered_rtl: symbol_format_list_numbered_rtl,
	format_overline: symbol_format_overline,
	format_paint: symbol_format_paint,
	format_paragraph: symbol_format_paragraph,
	format_quote: symbol_format_quote,
	format_shapes: symbol_format_shapes,
	format_size: symbol_format_size,
	format_strikethrough: symbol_format_strikethrough,
	format_text_clip: symbol_format_text_clip,
	format_text_overflow: symbol_format_text_overflow,
	format_text_wrap: symbol_format_text_wrap,
	format_textdirection_l_to_r: symbol_format_textdirection_l_to_r,
	format_textdirection_r_to_l: symbol_format_textdirection_r_to_l,
	format_textdirection_vertical: symbol_format_textdirection_vertical,
	format_underlined: symbol_format_underlined,
	format_underlined_squiggle: symbol_format_underlined_squiggle,
	forms_add_on: symbol_forms_add_on,
	forms_apps_script: symbol_forms_apps_script,
	fort: symbol_fort,
	forum: symbol_forum,
	forward: symbol_forward,
	forward_10: symbol_forward_10,
	forward_30: symbol_forward_30,
	forward_5: symbol_forward_5,
	forward_circle: symbol_forward_circle,
	forward_media: symbol_forward_media,
	forward_to_inbox: symbol_forward_to_inbox,
	foundation: symbol_foundation,
	frame_inspect: symbol_frame_inspect,
	frame_person: symbol_frame_person,
	frame_person_mic: symbol_frame_person_mic,
	frame_person_off: symbol_frame_person_off,
	frame_reload: symbol_frame_reload,
	frame_source: symbol_frame_source,
	free_cancellation: symbol_free_cancellation,
	front_hand: symbol_front_hand,
	front_loader: symbol_front_loader,
	full_coverage: symbol_full_coverage,
	full_hd: symbol_full_hd,
	full_stacked_bar_chart: symbol_full_stacked_bar_chart,
	fullscreen: symbol_fullscreen,
	fullscreen_exit: symbol_fullscreen_exit,
	function: symbol_function,
	functions: symbol_functions,
	g_mobiledata: symbol_g_mobiledata,
	g_mobiledata_badge: symbol_g_mobiledata_badge,
	g_translate: symbol_g_translate,
	gallery_thumbnail: symbol_gallery_thumbnail,
	gamepad: symbol_gamepad,
	garage: symbol_garage,
	garage_door: symbol_garage_door,
	garage_home: symbol_garage_home,
	garden_cart: symbol_garden_cart,
	gas_meter: symbol_gas_meter,
	gastroenterology: symbol_gastroenterology,
	gate: symbol_gate,
	gavel: symbol_gavel,
	general_device: symbol_general_device,
	genetics: symbol_genetics,
	genres: symbol_genres,
	gesture: symbol_gesture,
	gesture_select: symbol_gesture_select,
	gif: symbol_gif,
	gif_box: symbol_gif_box,
	girl: symbol_girl,
	gite: symbol_gite,
	glass_cup: symbol_glass_cup,
	globe: symbol_globe,
	globe_asia: symbol_globe_asia,
	globe_uk: symbol_globe_uk,
	glucose: symbol_glucose,
	glyphs: symbol_glyphs,
	go_to_line: symbol_go_to_line,
	golf_course: symbol_golf_course,
	google_home_devices: symbol_google_home_devices,
	google_tv_remote: symbol_google_tv_remote,
	google_wifi: symbol_google_wifi,
	gpp_bad: symbol_gpp_bad,
	gpp_maybe: symbol_gpp_maybe,
	grade: symbol_grade,
	gradient: symbol_gradient,
	grading: symbol_grading,
	grain: symbol_grain,
	graphic_eq: symbol_graphic_eq,
	grass: symbol_grass,
	grid_3x3: symbol_grid_3x3,
	grid_3x3_off: symbol_grid_3x3_off,
	grid_4x4: symbol_grid_4x4,
	grid_goldenratio: symbol_grid_goldenratio,
	grid_guides: symbol_grid_guides,
	grid_off: symbol_grid_off,
	grid_on: symbol_grid_on,
	grid_view: symbol_grid_view,
	grocery: symbol_grocery,
	group: symbol_group,
	group_add: symbol_group_add,
	group_off: symbol_group_off,
	group_remove: symbol_group_remove,
	group_work: symbol_group_work,
	grouped_bar_chart: symbol_grouped_bar_chart,
	groups: symbol_groups,
	groups_2: symbol_groups_2,
	groups_3: symbol_groups_3,
	guardian: symbol_guardian,
	gynecology: symbol_gynecology,
	h_mobiledata: symbol_h_mobiledata,
	h_mobiledata_badge: symbol_h_mobiledata_badge,
	h_plus_mobiledata: symbol_h_plus_mobiledata,
	h_plus_mobiledata_badge: symbol_h_plus_mobiledata_badge,
	hail: symbol_hail,
	hallway: symbol_hallway,
	hand_bones: symbol_hand_bones,
	hand_gesture: symbol_hand_gesture,
	handheld_controller: symbol_handheld_controller,
	handshake: symbol_handshake,
	handyman: symbol_handyman,
	hangout_video: symbol_hangout_video,
	hangout_video_off: symbol_hangout_video_off,
	hard_drive: symbol_hard_drive,
	hard_drive_2: symbol_hard_drive_2,
	hardware: symbol_hardware,
	hd: symbol_hd,
	hdr_auto: symbol_hdr_auto,
	hdr_auto_select: symbol_hdr_auto_select,
	hdr_enhanced_select: symbol_hdr_enhanced_select,
	hdr_off: symbol_hdr_off,
	hdr_off_select: symbol_hdr_off_select,
	hdr_on: symbol_hdr_on,
	hdr_on_select: symbol_hdr_on_select,
	hdr_plus: symbol_hdr_plus,
	hdr_plus_off: symbol_hdr_plus_off,
	hdr_strong: symbol_hdr_strong,
	hdr_weak: symbol_hdr_weak,
	head_mounted_device: symbol_head_mounted_device,
	headphones: symbol_headphones,
	headphones_battery: symbol_headphones_battery,
	headset_mic: symbol_headset_mic,
	headset_off: symbol_headset_off,
	healing: symbol_healing,
	health_and_beauty: symbol_health_and_beauty,
	health_and_safety: symbol_health_and_safety,
	health_metrics: symbol_health_metrics,
	heap_snapshot_large: symbol_heap_snapshot_large,
	heap_snapshot_multiple: symbol_heap_snapshot_multiple,
	heap_snapshot_thumbnail: symbol_heap_snapshot_thumbnail,
	hearing: symbol_hearing,
	hearing_disabled: symbol_hearing_disabled,
	heart_broken: symbol_heart_broken,
	heart_check: symbol_heart_check,
	heart_minus: symbol_heart_minus,
	heart_plus: symbol_heart_plus,
	heat: symbol_heat,
	heat_pump: symbol_heat_pump,
	heat_pump_balance: symbol_heat_pump_balance,
	height: symbol_height,
	helicopter: symbol_helicopter,
	help: symbol_help,
	help_center: symbol_help_center,
	help_clinic: symbol_help_clinic,
	hematology: symbol_hematology,
	hevc: symbol_hevc,
	hexagon: symbol_hexagon,
	hide: symbol_hide,
	hide_image: symbol_hide_image,
	hide_source: symbol_hide_source,
	high_density: symbol_high_density,
	high_quality: symbol_high_quality,
	high_res: symbol_high_res,
	highlight: symbol_highlight,
	highlight_keyboard_focus: symbol_highlight_keyboard_focus,
	highlight_mouse_cursor: symbol_highlight_mouse_cursor,
	highlight_text_cursor: symbol_highlight_text_cursor,
	highlighter_size_1: symbol_highlighter_size_1,
	highlighter_size_2: symbol_highlighter_size_2,
	highlighter_size_3: symbol_highlighter_size_3,
	highlighter_size_4: symbol_highlighter_size_4,
	highlighter_size_5: symbol_highlighter_size_5,
	hiking: symbol_hiking,
	history: symbol_history,
	history_edu: symbol_history_edu,
	history_off: symbol_history_off,
	history_toggle_off: symbol_history_toggle_off,
	hive: symbol_hive,
	hls: symbol_hls,
	hls_off: symbol_hls_off,
	holiday_village: symbol_holiday_village,
	home: symbol_home,
	home_and_garden: symbol_home_and_garden,
	home_app_logo: symbol_home_app_logo,
	home_health: symbol_home_health,
	home_improvement_and_tools: symbol_home_improvement_and_tools,
	home_iot_device: symbol_home_iot_device,
	home_max: symbol_home_max,
	home_max_dots: symbol_home_max_dots,
	home_mini: symbol_home_mini,
	home_pin: symbol_home_pin,
	home_repair_service: symbol_home_repair_service,
	home_speaker: symbol_home_speaker,
	home_storage: symbol_home_storage,
	home_work: symbol_home_work,
	horizontal_distribute: symbol_horizontal_distribute,
	horizontal_rule: symbol_horizontal_rule,
	horizontal_split: symbol_horizontal_split,
	hot_tub: symbol_hot_tub,
	hotel: symbol_hotel,
	hotel_class: symbol_hotel_class,
	hourglass: symbol_hourglass,
	hourglass_bottom: symbol_hourglass_bottom,
	hourglass_disabled: symbol_hourglass_disabled,
	hourglass_empty: symbol_hourglass_empty,
	hourglass_top: symbol_hourglass_top,
	house: symbol_house,
	house_siding: symbol_house_siding,
	house_with_shield: symbol_house_with_shield,
	houseboat: symbol_houseboat,
	household_supplies: symbol_household_supplies,
	how_to_reg: symbol_how_to_reg,
	how_to_vote: symbol_how_to_vote,
	hr_resting: symbol_hr_resting,
	html: symbol_html,
	http: symbol_http,
	hub: symbol_hub,
	humerus: symbol_humerus,
	humerus_alt: symbol_humerus_alt,
	humidity_high: symbol_humidity_high,
	humidity_indoor: symbol_humidity_indoor,
	humidity_low: symbol_humidity_low,
	humidity_mid: symbol_humidity_mid,
	humidity_percentage: symbol_humidity_percentage,
	hvac: symbol_hvac,
	ice_skating: symbol_ice_skating,
	icecream: symbol_icecream,
	id_card: symbol_id_card,
	ifl: symbol_ifl,
	iframe: symbol_iframe,
	iframe_off: symbol_iframe_off,
	image: symbol_image,
	image_aspect_ratio: symbol_image_aspect_ratio,
	image_search: symbol_image_search,
	imagesearch_roller: symbol_imagesearch_roller,
	imagesmode: symbol_imagesmode,
	immunology: symbol_immunology,
	import_contacts: symbol_import_contacts,
	important_devices: symbol_important_devices,
	in_home_mode: symbol_in_home_mode,
	inactive_order: symbol_inactive_order,
	inbox: symbol_inbox,
	inbox_customize: symbol_inbox_customize,
	incomplete_circle: symbol_incomplete_circle,
	indeterminate_check_box: symbol_indeterminate_check_box,
	indeterminate_question_box: symbol_indeterminate_question_box,
	info: symbol_info,
	info_i: symbol_info_i,
	infrared: symbol_infrared,
	ink_eraser: symbol_ink_eraser,
	ink_eraser_off: symbol_ink_eraser_off,
	ink_highlighter: symbol_ink_highlighter,
	ink_highlighter_move: symbol_ink_highlighter_move,
	ink_marker: symbol_ink_marker,
	ink_pen: symbol_ink_pen,
	inpatient: symbol_inpatient,
	input: symbol_input,
	input_circle: symbol_input_circle,
	insert_chart: symbol_insert_chart,
	insert_page_break: symbol_insert_page_break,
	insert_text: symbol_insert_text,
	install_desktop: symbol_install_desktop,
	install_mobile: symbol_install_mobile,
	instant_mix: symbol_instant_mix,
	integration_instructions: symbol_integration_instructions,
	interactive_space: symbol_interactive_space,
	interests: symbol_interests,
	interpreter_mode: symbol_interpreter_mode,
	inventory: symbol_inventory,
	inventory_2: symbol_inventory_2,
	invert_colors: symbol_invert_colors,
	invert_colors_off: symbol_invert_colors_off,
	ios: symbol_ios,
	ios_share: symbol_ios_share,
	iron: symbol_iron,
	jamboard_kiosk: symbol_jamboard_kiosk,
	javascript: symbol_javascript,
	join: symbol_join,
	join_inner: symbol_join_inner,
	join_left: symbol_join_left,
	join_right: symbol_join_right,
	joystick: symbol_joystick,
	jump_to_element: symbol_jump_to_element,
	kayaking: symbol_kayaking,
	kebab_dining: symbol_kebab_dining,
	keep: symbol_keep,
	keep_off: symbol_keep_off,
	keep_public: symbol_keep_public,
	kettle: symbol_kettle,
	key: symbol_key,
	key_off: symbol_key_off,
	key_vertical: symbol_key_vertical,
	key_visualizer: symbol_key_visualizer,
	keyboard: symbol_keyboard,
	keyboard_alt: symbol_keyboard_alt,
	keyboard_arrow_down: symbol_keyboard_arrow_down,
	keyboard_arrow_left: symbol_keyboard_arrow_left,
	keyboard_arrow_right: symbol_keyboard_arrow_right,
	keyboard_arrow_up: symbol_keyboard_arrow_up,
	keyboard_backspace: symbol_keyboard_backspace,
	keyboard_capslock: symbol_keyboard_capslock,
	keyboard_capslock_badge: symbol_keyboard_capslock_badge,
	keyboard_command_key: symbol_keyboard_command_key,
	keyboard_control_key: symbol_keyboard_control_key,
	keyboard_double_arrow_down: symbol_keyboard_double_arrow_down,
	keyboard_double_arrow_left: symbol_keyboard_double_arrow_left,
	keyboard_double_arrow_right: symbol_keyboard_double_arrow_right,
	keyboard_double_arrow_up: symbol_keyboard_double_arrow_up,
	keyboard_external_input: symbol_keyboard_external_input,
	keyboard_full: symbol_keyboard_full,
	keyboard_hide: symbol_keyboard_hide,
	keyboard_keys: symbol_keyboard_keys,
	keyboard_lock: symbol_keyboard_lock,
	keyboard_lock_off: symbol_keyboard_lock_off,
	keyboard_off: symbol_keyboard_off,
	keyboard_onscreen: symbol_keyboard_onscreen,
	keyboard_option_key: symbol_keyboard_option_key,
	keyboard_previous_language: symbol_keyboard_previous_language,
	keyboard_return: symbol_keyboard_return,
	keyboard_tab: symbol_keyboard_tab,
	keyboard_tab_rtl: symbol_keyboard_tab_rtl,
	kid_star: symbol_kid_star,
	king_bed: symbol_king_bed,
	kitchen: symbol_kitchen,
	kitesurfing: symbol_kitesurfing,
	lab_panel: symbol_lab_panel,
	lab_profile: symbol_lab_profile,
	lab_research: symbol_lab_research,
	label: symbol_label,
	label_important: symbol_label_important,
	label_off: symbol_label_off,
	labs: symbol_labs,
	lan: symbol_lan,
	landscape: symbol_landscape,
	landscape_2: symbol_landscape_2,
	landscape_2_off: symbol_landscape_2_off,
	landslide: symbol_landslide,
	language: symbol_language,
	language_chinese_array: symbol_language_chinese_array,
	language_chinese_cangjie: symbol_language_chinese_cangjie,
	language_chinese_dayi: symbol_language_chinese_dayi,
	language_chinese_pinyin: symbol_language_chinese_pinyin,
	language_chinese_quick: symbol_language_chinese_quick,
	language_chinese_wubi: symbol_language_chinese_wubi,
	language_french: symbol_language_french,
	language_gb_english: symbol_language_gb_english,
	language_international: symbol_language_international,
	language_japanese_kana: symbol_language_japanese_kana,
	language_korean_latin: symbol_language_korean_latin,
	language_pinyin: symbol_language_pinyin,
	language_spanish: symbol_language_spanish,
	language_us: symbol_language_us,
	language_us_colemak: symbol_language_us_colemak,
	language_us_dvorak: symbol_language_us_dvorak,
	laps: symbol_laps,
	laptop_chromebook: symbol_laptop_chromebook,
	laptop_mac: symbol_laptop_mac,
	laptop_windows: symbol_laptop_windows,
	lasso_select: symbol_lasso_select,
	last_page: symbol_last_page,
	laundry: symbol_laundry,
	layers: symbol_layers,
	layers_clear: symbol_layers_clear,
	lda: symbol_lda,
	leaderboard: symbol_leaderboard,
	leak_add: symbol_leak_add,
	leak_remove: symbol_leak_remove,
	left_click: symbol_left_click,
	left_panel_close: symbol_left_panel_close,
	left_panel_open: symbol_left_panel_open,
	legend_toggle: symbol_legend_toggle,
	lens: symbol_lens,
	lens_blur: symbol_lens_blur,
	letter_switch: symbol_letter_switch,
	library_add: symbol_library_add,
	library_add_check: symbol_library_add_check,
	library_books: symbol_library_books,
	library_music: symbol_library_music,
	license: symbol_license,
	lift_to_talk: symbol_lift_to_talk,
	light: symbol_light,
	light_group: symbol_light_group,
	light_mode: symbol_light_mode,
	light_off: symbol_light_off,
	lightbulb: symbol_lightbulb,
	lightbulb_circle: symbol_lightbulb_circle,
	lightning_stand: symbol_lightning_stand,
	line_axis: symbol_line_axis,
	line_curve: symbol_line_curve,
	line_end: symbol_line_end,
	line_end_arrow: symbol_line_end_arrow,
	line_end_arrow_notch: symbol_line_end_arrow_notch,
	line_end_circle: symbol_line_end_circle,
	line_end_diamond: symbol_line_end_diamond,
	line_end_square: symbol_line_end_square,
	line_start: symbol_line_start,
	line_start_arrow: symbol_line_start_arrow,
	line_start_arrow_notch: symbol_line_start_arrow_notch,
	line_start_circle: symbol_line_start_circle,
	line_start_diamond: symbol_line_start_diamond,
	line_start_square: symbol_line_start_square,
	line_style: symbol_line_style,
	line_weight: symbol_line_weight,
	linear_scale: symbol_linear_scale,
	link: symbol_link,
	link_off: symbol_link_off,
	linked_camera: symbol_linked_camera,
	linked_services: symbol_linked_services,
	liquor: symbol_liquor,
	list: symbol_list,
	list_alt: symbol_list_alt,
	list_alt_add: symbol_list_alt_add,
	lists: symbol_lists,
	live_help: symbol_live_help,
	live_tv: symbol_live_tv,
	living: symbol_living,
	local_activity: symbol_local_activity,
	local_atm: symbol_local_atm,
	local_bar: symbol_local_bar,
	local_cafe: symbol_local_cafe,
	local_car_wash: symbol_local_car_wash,
	local_convenience_store: symbol_local_convenience_store,
	local_dining: symbol_local_dining,
	local_drink: symbol_local_drink,
	local_fire_department: symbol_local_fire_department,
	local_florist: symbol_local_florist,
	local_gas_station: symbol_local_gas_station,
	local_hospital: symbol_local_hospital,
	local_laundry_service: symbol_local_laundry_service,
	local_library: symbol_local_library,
	local_mall: symbol_local_mall,
	local_parking: symbol_local_parking,
	local_pharmacy: symbol_local_pharmacy,
	local_pizza: symbol_local_pizza,
	local_police: symbol_local_police,
	local_post_office: symbol_local_post_office,
	local_see: symbol_local_see,
	local_shipping: symbol_local_shipping,
	local_taxi: symbol_local_taxi,
	location_away: symbol_location_away,
	location_chip: symbol_location_chip,
	location_city: symbol_location_city,
	location_disabled: symbol_location_disabled,
	location_home: symbol_location_home,
	location_off: symbol_location_off,
	location_on: symbol_location_on,
	location_searching: symbol_location_searching,
	lock: symbol_lock,
	lock_clock: symbol_lock_clock,
	lock_open: symbol_lock_open,
	lock_open_right: symbol_lock_open_right,
	lock_person: symbol_lock_person,
	lock_reset: symbol_lock_reset,
	login: symbol_login,
	logo_dev: symbol_logo_dev,
	logout: symbol_logout,
	looks: symbol_looks,
	looks_3: symbol_looks_3,
	looks_4: symbol_looks_4,
	looks_5: symbol_looks_5,
	looks_6: symbol_looks_6,
	looks_one: symbol_looks_one,
	looks_two: symbol_looks_two,
	loupe: symbol_loupe,
	low_density: symbol_low_density,
	low_priority: symbol_low_priority,
	lowercase: symbol_lowercase,
	loyalty: symbol_loyalty,
	lte_mobiledata: symbol_lte_mobiledata,
	lte_mobiledata_badge: symbol_lte_mobiledata_badge,
	lte_plus_mobiledata: symbol_lte_plus_mobiledata,
	lte_plus_mobiledata_badge: symbol_lte_plus_mobiledata_badge,
	luggage: symbol_luggage,
	lunch_dining: symbol_lunch_dining,
	lyrics: symbol_lyrics,
	macro_auto: symbol_macro_auto,
	macro_off: symbol_macro_off,
	magnification_large: symbol_magnification_large,
	magnification_small: symbol_magnification_small,
	magnify_docked: symbol_magnify_docked,
	magnify_fullscreen: symbol_magnify_fullscreen,
	mail: symbol_mail,
	mail_lock: symbol_mail_lock,
	mail_off: symbol_mail_off,
	male: symbol_male,
	man: symbol_man,
	man_2: symbol_man_2,
	man_3: symbol_man_3,
	man_4: symbol_man_4,
	manage_accounts: symbol_manage_accounts,
	manage_history: symbol_manage_history,
	manage_search: symbol_manage_search,
	manga: symbol_manga,
	manufacturing: symbol_manufacturing,
	map: symbol_map,
	maps_ugc: symbol_maps_ugc,
	margin: symbol_margin,
	mark_as_unread: symbol_mark_as_unread,
	mark_chat_read: symbol_mark_chat_read,
	mark_chat_unread: symbol_mark_chat_unread,
	mark_email_read: symbol_mark_email_read,
	mark_email_unread: symbol_mark_email_unread,
	mark_unread_chat_alt: symbol_mark_unread_chat_alt,
	markdown: symbol_markdown,
	markdown_copy: symbol_markdown_copy,
	markdown_paste: symbol_markdown_paste,
	markunread_mailbox: symbol_markunread_mailbox,
	masked_transitions: symbol_masked_transitions,
	masks: symbol_masks,
	match_case: symbol_match_case,
	match_word: symbol_match_word,
	matter: symbol_matter,
	maximize: symbol_maximize,
	measuring_tape: symbol_measuring_tape,
	media_bluetooth_off: symbol_media_bluetooth_off,
	media_bluetooth_on: symbol_media_bluetooth_on,
	media_link: symbol_media_link,
	media_output: symbol_media_output,
	media_output_off: symbol_media_output_off,
	mediation: symbol_mediation,
	medical_information: symbol_medical_information,
	medical_mask: symbol_medical_mask,
	medical_services: symbol_medical_services,
	medication: symbol_medication,
	medication_liquid: symbol_medication_liquid,
	meeting_room: symbol_meeting_room,
	memory: symbol_memory,
	memory_alt: symbol_memory_alt,
	menstrual_health: symbol_menstrual_health,
	menu: symbol_menu,
	menu_book: symbol_menu_book,
	menu_open: symbol_menu_open,
	merge: symbol_merge,
	merge_type: symbol_merge_type,
	metabolism: symbol_metabolism,
	mfg_nest_yale_lock: symbol_mfg_nest_yale_lock,
	mic: symbol_mic,
	mic_double: symbol_mic_double,
	mic_external_off: symbol_mic_external_off,
	mic_external_on: symbol_mic_external_on,
	mic_off: symbol_mic_off,
	microbiology: symbol_microbiology,
	microwave: symbol_microwave,
	microwave_gen: symbol_microwave_gen,
	military_tech: symbol_military_tech,
	mimo: symbol_mimo,
	mimo_disconnect: symbol_mimo_disconnect,
	mindfulness: symbol_mindfulness,
	minimize: symbol_minimize,
	minor_crash: symbol_minor_crash,
	mintmark: symbol_mintmark,
	missed_video_call: symbol_missed_video_call,
	missing_controller: symbol_missing_controller,
	mist: symbol_mist,
	mitre: symbol_mitre,
	mixture_med: symbol_mixture_med,
	mms: symbol_mms,
	mobile_friendly: symbol_mobile_friendly,
	mobile_off: symbol_mobile_off,
	mobile_screen_share: symbol_mobile_screen_share,
	mobiledata_off: symbol_mobiledata_off,
	mode_comment: symbol_mode_comment,
	mode_cool: symbol_mode_cool,
	mode_cool_off: symbol_mode_cool_off,
	mode_dual: symbol_mode_dual,
	mode_fan: symbol_mode_fan,
	mode_fan_off: symbol_mode_fan_off,
	mode_heat: symbol_mode_heat,
	mode_heat_cool: symbol_mode_heat_cool,
	mode_heat_off: symbol_mode_heat_off,
	mode_night: symbol_mode_night,
	mode_of_travel: symbol_mode_of_travel,
	mode_off_on: symbol_mode_off_on,
	mode_standby: symbol_mode_standby,
	model_training: symbol_model_training,
	monetization_on: symbol_monetization_on,
	money: symbol_money,
	money_off: symbol_money_off,
	monitor: symbol_monitor,
	monitor_heart: symbol_monitor_heart,
	monitor_weight: symbol_monitor_weight,
	monitor_weight_gain: symbol_monitor_weight_gain,
	monitor_weight_loss: symbol_monitor_weight_loss,
	monitoring: symbol_monitoring,
	monochrome_photos: symbol_monochrome_photos,
	mood: symbol_mood,
	mood_bad: symbol_mood_bad,
	mop: symbol_mop,
	more: symbol_more,
	more_down: symbol_more_down,
	more_horiz: symbol_more_horiz,
	more_time: symbol_more_time,
	more_up: symbol_more_up,
	more_vert: symbol_more_vert,
	mosque: symbol_mosque,
	motion_blur: symbol_motion_blur,
	motion_mode: symbol_motion_mode,
	motion_photos_auto: symbol_motion_photos_auto,
	motion_photos_off: symbol_motion_photos_off,
	motion_photos_on: symbol_motion_photos_on,
	motion_photos_paused: symbol_motion_photos_paused,
	motion_sensor_active: symbol_motion_sensor_active,
	motion_sensor_alert: symbol_motion_sensor_alert,
	motion_sensor_idle: symbol_motion_sensor_idle,
	motion_sensor_urgent: symbol_motion_sensor_urgent,
	motorcycle: symbol_motorcycle,
	mountain_flag: symbol_mountain_flag,
	mouse: symbol_mouse,
	mouse_lock: symbol_mouse_lock,
	mouse_lock_off: symbol_mouse_lock_off,
	move: symbol_move,
	move_down: symbol_move_down,
	move_group: symbol_move_group,
	move_item: symbol_move_item,
	move_location: symbol_move_location,
	move_selection_down: symbol_move_selection_down,
	move_selection_left: symbol_move_selection_left,
	move_selection_right: symbol_move_selection_right,
	move_selection_up: symbol_move_selection_up,
	move_to_inbox: symbol_move_to_inbox,
	move_up: symbol_move_up,
	moved_location: symbol_moved_location,
	movie: symbol_movie,
	movie_edit: symbol_movie_edit,
	movie_info: symbol_movie_info,
	movie_off: symbol_movie_off,
	moving: symbol_moving,
	moving_beds: symbol_moving_beds,
	moving_ministry: symbol_moving_ministry,
	mp: symbol_mp,
	multicooker: symbol_multicooker,
	multiline_chart: symbol_multiline_chart,
	multiple_stop: symbol_multiple_stop,
	museum: symbol_museum,
	music_cast: symbol_music_cast,
	music_note: symbol_music_note,
	music_off: symbol_music_off,
	music_video: symbol_music_video,
	my_location: symbol_my_location,
	mystery: symbol_mystery,
	nat: symbol_nat,
	nature: symbol_nature,
	nature_people: symbol_nature_people,
	navigate_before: symbol_navigate_before,
	navigate_next: symbol_navigate_next,
	navigation: symbol_navigation,
	near_me: symbol_near_me,
	near_me_disabled: symbol_near_me_disabled,
	nearby: symbol_nearby,
	nearby_error: symbol_nearby_error,
	nearby_off: symbol_nearby_off,
	nephrology: symbol_nephrology,
	nest_audio: symbol_nest_audio,
	nest_cam_floodlight: symbol_nest_cam_floodlight,
	nest_cam_indoor: symbol_nest_cam_indoor,
	nest_cam_iq: symbol_nest_cam_iq,
	nest_cam_iq_outdoor: symbol_nest_cam_iq_outdoor,
	nest_cam_magnet_mount: symbol_nest_cam_magnet_mount,
	nest_cam_outdoor: symbol_nest_cam_outdoor,
	nest_cam_stand: symbol_nest_cam_stand,
	nest_cam_wall_mount: symbol_nest_cam_wall_mount,
	nest_cam_wired_stand: symbol_nest_cam_wired_stand,
	nest_clock_farsight_analog: symbol_nest_clock_farsight_analog,
	nest_clock_farsight_digital: symbol_nest_clock_farsight_digital,
	nest_connect: symbol_nest_connect,
	nest_detect: symbol_nest_detect,
	nest_display: symbol_nest_display,
	nest_display_max: symbol_nest_display_max,
	nest_doorbell_visitor: symbol_nest_doorbell_visitor,
	nest_eco_leaf: symbol_nest_eco_leaf,
	nest_farsight_weather: symbol_nest_farsight_weather,
	nest_found_savings: symbol_nest_found_savings,
	nest_gale_wifi: symbol_nest_gale_wifi,
	nest_heat_link_e: symbol_nest_heat_link_e,
	nest_heat_link_gen_3: symbol_nest_heat_link_gen_3,
	nest_hello_doorbell: symbol_nest_hello_doorbell,
	nest_mini: symbol_nest_mini,
	nest_multi_room: symbol_nest_multi_room,
	nest_protect: symbol_nest_protect,
	nest_remote: symbol_nest_remote,
	nest_remote_comfort_sensor: symbol_nest_remote_comfort_sensor,
	nest_secure_alarm: symbol_nest_secure_alarm,
	nest_sunblock: symbol_nest_sunblock,
	nest_tag: symbol_nest_tag,
	nest_thermostat: symbol_nest_thermostat,
	nest_thermostat_e_eu: symbol_nest_thermostat_e_eu,
	nest_thermostat_gen_3: symbol_nest_thermostat_gen_3,
	nest_thermostat_sensor: symbol_nest_thermostat_sensor,
	nest_thermostat_sensor_eu: symbol_nest_thermostat_sensor_eu,
	nest_thermostat_zirconium_eu: symbol_nest_thermostat_zirconium_eu,
	nest_true_radiant: symbol_nest_true_radiant,
	nest_wake_on_approach: symbol_nest_wake_on_approach,
	nest_wake_on_press: symbol_nest_wake_on_press,
	nest_wifi_point: symbol_nest_wifi_point,
	nest_wifi_pro: symbol_nest_wifi_pro,
	nest_wifi_pro_2: symbol_nest_wifi_pro_2,
	nest_wifi_router: symbol_nest_wifi_router,
	network_cell: symbol_network_cell,
	network_check: symbol_network_check,
	network_intelligence_history: symbol_network_intelligence_history,
	network_intelligence_update: symbol_network_intelligence_update,
	network_locked: symbol_network_locked,
	network_manage: symbol_network_manage,
	network_node: symbol_network_node,
	network_ping: symbol_network_ping,
	network_wifi: symbol_network_wifi,
	network_wifi_1_bar: symbol_network_wifi_1_bar,
	network_wifi_1_bar_locked: symbol_network_wifi_1_bar_locked,
	network_wifi_2_bar: symbol_network_wifi_2_bar,
	network_wifi_2_bar_locked: symbol_network_wifi_2_bar_locked,
	network_wifi_3_bar: symbol_network_wifi_3_bar,
	network_wifi_3_bar_locked: symbol_network_wifi_3_bar_locked,
	network_wifi_locked: symbol_network_wifi_locked,
	neurology: symbol_neurology,
	new_label: symbol_new_label,
	new_releases: symbol_new_releases,
	new_window: symbol_new_window,
	news: symbol_news,
	newsmode: symbol_newsmode,
	newspaper: symbol_newspaper,
	newsstand: symbol_newsstand,
	next_plan: symbol_next_plan,
	next_week: symbol_next_week,
	nfc: symbol_nfc,
	night_shelter: symbol_night_shelter,
	night_sight_auto: symbol_night_sight_auto,
	night_sight_auto_off: symbol_night_sight_auto_off,
	night_sight_max: symbol_night_sight_max,
	nightlife: symbol_nightlife,
	nightlight: symbol_nightlight,
	nights_stay: symbol_nights_stay,
	no_accounts: symbol_no_accounts,
	no_adult_content: symbol_no_adult_content,
	no_backpack: symbol_no_backpack,
	no_crash: symbol_no_crash,
	no_drinks: symbol_no_drinks,
	no_encryption: symbol_no_encryption,
	no_flash: symbol_no_flash,
	no_food: symbol_no_food,
	no_luggage: symbol_no_luggage,
	no_meals: symbol_no_meals,
	no_meeting_room: symbol_no_meeting_room,
	no_photography: symbol_no_photography,
	no_sim: symbol_no_sim,
	no_sound: symbol_no_sound,
	no_stroller: symbol_no_stroller,
	no_transfer: symbol_no_transfer,
	noise_aware: symbol_noise_aware,
	noise_control_off: symbol_noise_control_off,
	noise_control_on: symbol_noise_control_on,
	nordic_walking: symbol_nordic_walking,
	north: symbol_north,
	north_east: symbol_north_east,
	north_west: symbol_north_west,
	not_accessible: symbol_not_accessible,
	not_accessible_forward: symbol_not_accessible_forward,
	not_listed_location: symbol_not_listed_location,
	not_started: symbol_not_started,
	note_add: symbol_note_add,
	note_alt: symbol_note_alt,
	note_stack: symbol_note_stack,
	note_stack_add: symbol_note_stack_add,
	notes: symbol_notes,
	notification_add: symbol_notification_add,
	notification_important: symbol_notification_important,
	notification_multiple: symbol_notification_multiple,
	notifications: symbol_notifications,
	notifications_active: symbol_notifications_active,
	notifications_off: symbol_notifications_off,
	notifications_paused: symbol_notifications_paused,
	notifications_unread: symbol_notifications_unread,
	numbers: symbol_numbers,
	nutrition: symbol_nutrition,
	ods: symbol_ods,
	odt: symbol_odt,
	offline_bolt: symbol_offline_bolt,
	offline_pin: symbol_offline_pin,
	offline_pin_off: symbol_offline_pin_off,
	offline_share: symbol_offline_share,
	oil_barrel: symbol_oil_barrel,
	on_device_training: symbol_on_device_training,
	on_hub_device: symbol_on_hub_device,
	oncology: symbol_oncology,
	online_prediction: symbol_online_prediction,
	onsen: symbol_onsen,
	opacity: symbol_opacity,
	open_in_browser: symbol_open_in_browser,
	open_in_full: symbol_open_in_full,
	open_in_new: symbol_open_in_new,
	open_in_new_down: symbol_open_in_new_down,
	open_in_new_off: symbol_open_in_new_off,
	open_in_phone: symbol_open_in_phone,
	open_jam: symbol_open_jam,
	open_run: symbol_open_run,
	open_with: symbol_open_with,
	ophthalmology: symbol_ophthalmology,
	oral_disease: symbol_oral_disease,
	order_approve: symbol_order_approve,
	order_play: symbol_order_play,
	orders: symbol_orders,
	orthopedics: symbol_orthopedics,
	other_admission: symbol_other_admission,
	other_houses: symbol_other_houses,
	outbound: symbol_outbound,
	outbox: symbol_outbox,
	outbox_alt: symbol_outbox_alt,
	outdoor_garden: symbol_outdoor_garden,
	outdoor_grill: symbol_outdoor_grill,
	outgoing_mail: symbol_outgoing_mail,
	outlet: symbol_outlet,
	outpatient: symbol_outpatient,
	outpatient_med: symbol_outpatient_med,
	output: symbol_output,
	output_circle: symbol_output_circle,
	oven: symbol_oven,
	oven_gen: symbol_oven_gen,
	overview: symbol_overview,
	overview_key: symbol_overview_key,
	oxygen_saturation: symbol_oxygen_saturation,
	p2p: symbol_p2p,
	pace: symbol_pace,
	pacemaker: symbol_pacemaker,
	package: symbol_package,
	package_2: symbol_package_2,
	padding: symbol_padding,
	page_control: symbol_page_control,
	page_info: symbol_page_info,
	pageless: symbol_pageless,
	pages: symbol_pages,
	pageview: symbol_pageview,
	paid: symbol_paid,
	palette: symbol_palette,
	pallet: symbol_pallet,
	pan_tool: symbol_pan_tool,
	pan_tool_alt: symbol_pan_tool_alt,
	pan_zoom: symbol_pan_zoom,
	panorama: symbol_panorama,
	panorama_fish_eye: symbol_panorama_fish_eye,
	panorama_horizontal: symbol_panorama_horizontal,
	panorama_photosphere: symbol_panorama_photosphere,
	panorama_vertical: symbol_panorama_vertical,
	panorama_wide_angle: symbol_panorama_wide_angle,
	paragliding: symbol_paragliding,
	park: symbol_park,
	partly_cloudy_day: symbol_partly_cloudy_day,
	partly_cloudy_night: symbol_partly_cloudy_night,
	partner_exchange: symbol_partner_exchange,
	partner_reports: symbol_partner_reports,
	party_mode: symbol_party_mode,
	passkey: symbol_passkey,
	password: symbol_password,
	password_2: symbol_password_2,
	password_2_off: symbol_password_2_off,
	patient_list: symbol_patient_list,
	pattern: symbol_pattern,
	pause: symbol_pause,
	pause_circle: symbol_pause_circle,
	pause_presentation: symbol_pause_presentation,
	payments: symbol_payments,
	pedal_bike: symbol_pedal_bike,
	pediatrics: symbol_pediatrics,
	pen_size_1: symbol_pen_size_1,
	pen_size_2: symbol_pen_size_2,
	pen_size_3: symbol_pen_size_3,
	pen_size_4: symbol_pen_size_4,
	pen_size_5: symbol_pen_size_5,
	pending: symbol_pending,
	pending_actions: symbol_pending_actions,
	pentagon: symbol_pentagon,
	percent: symbol_percent,
	pergola: symbol_pergola,
	perm_camera_mic: symbol_perm_camera_mic,
	perm_contact_calendar: symbol_perm_contact_calendar,
	perm_data_setting: symbol_perm_data_setting,
	perm_device_information: symbol_perm_device_information,
	perm_media: symbol_perm_media,
	perm_phone_msg: symbol_perm_phone_msg,
	perm_scan_wifi: symbol_perm_scan_wifi,
	person: symbol_person,
	person_2: symbol_person_2,
	person_3: symbol_person_3,
	person_4: symbol_person_4,
	person_add: symbol_person_add,
	person_add_disabled: symbol_person_add_disabled,
	person_alert: symbol_person_alert,
	person_apron: symbol_person_apron,
	person_book: symbol_person_book,
	person_cancel: symbol_person_cancel,
	person_celebrate: symbol_person_celebrate,
	person_check: symbol_person_check,
	person_edit: symbol_person_edit,
	person_off: symbol_person_off,
	person_pin: symbol_person_pin,
	person_pin_circle: symbol_person_pin_circle,
	person_play: symbol_person_play,
	person_raised_hand: symbol_person_raised_hand,
	person_remove: symbol_person_remove,
	person_search: symbol_person_search,
	personal_bag: symbol_personal_bag,
	personal_bag_off: symbol_personal_bag_off,
	personal_bag_question: symbol_personal_bag_question,
	personal_injury: symbol_personal_injury,
	personal_places: symbol_personal_places,
	pest_control: symbol_pest_control,
	pest_control_rodent: symbol_pest_control_rodent,
	pet_supplies: symbol_pet_supplies,
	pets: symbol_pets,
	phishing: symbol_phishing,
	phone_android: symbol_phone_android,
	phone_bluetooth_speaker: symbol_phone_bluetooth_speaker,
	phone_callback: symbol_phone_callback,
	phone_disabled: symbol_phone_disabled,
	phone_enabled: symbol_phone_enabled,
	phone_forwarded: symbol_phone_forwarded,
	phone_in_talk: symbol_phone_in_talk,
	phone_iphone: symbol_phone_iphone,
	phone_locked: symbol_phone_locked,
	phone_missed: symbol_phone_missed,
	phone_paused: symbol_phone_paused,
	phonelink_erase: symbol_phonelink_erase,
	phonelink_lock: symbol_phonelink_lock,
	phonelink_off: symbol_phonelink_off,
	phonelink_ring: symbol_phonelink_ring,
	phonelink_ring_off: symbol_phonelink_ring_off,
	phonelink_setup: symbol_phonelink_setup,
	photo: symbol_photo,
	photo_album: symbol_photo_album,
	photo_auto_merge: symbol_photo_auto_merge,
	photo_camera: symbol_photo_camera,
	photo_camera_back: symbol_photo_camera_back,
	photo_camera_front: symbol_photo_camera_front,
	photo_frame: symbol_photo_frame,
	photo_library: symbol_photo_library,
	photo_prints: symbol_photo_prints,
	photo_size_select_large: symbol_photo_size_select_large,
	photo_size_select_small: symbol_photo_size_select_small,
	php: symbol_php,
	physical_therapy: symbol_physical_therapy,
	piano: symbol_piano,
	piano_off: symbol_piano_off,
	picture_as_pdf: symbol_picture_as_pdf,
	picture_in_picture: symbol_picture_in_picture,
	picture_in_picture_alt: symbol_picture_in_picture_alt,
	picture_in_picture_center: symbol_picture_in_picture_center,
	picture_in_picture_large: symbol_picture_in_picture_large,
	picture_in_picture_medium: symbol_picture_in_picture_medium,
	picture_in_picture_mobile: symbol_picture_in_picture_mobile,
	picture_in_picture_off: symbol_picture_in_picture_off,
	picture_in_picture_small: symbol_picture_in_picture_small,
	pie_chart: symbol_pie_chart,
	pill: symbol_pill,
	pill_off: symbol_pill_off,
	pin: symbol_pin,
	pin_drop: symbol_pin_drop,
	pin_end: symbol_pin_end,
	pin_invoke: symbol_pin_invoke,
	pinch: symbol_pinch,
	pinch_zoom_in: symbol_pinch_zoom_in,
	pinch_zoom_out: symbol_pinch_zoom_out,
	pip: symbol_pip,
	pip_exit: symbol_pip_exit,
	pivot_table_chart: symbol_pivot_table_chart,
	place_item: symbol_place_item,
	plagiarism: symbol_plagiarism,
	planner_banner_ad_pt: symbol_planner_banner_ad_pt,
	planner_review: symbol_planner_review,
	play_arrow: symbol_play_arrow,
	play_circle: symbol_play_circle,
	play_disabled: symbol_play_disabled,
	play_for_work: symbol_play_for_work,
	play_lesson: symbol_play_lesson,
	play_pause: symbol_play_pause,
	playing_cards: symbol_playing_cards,
	playlist_add: symbol_playlist_add,
	playlist_add_check: symbol_playlist_add_check,
	playlist_add_check_circle: symbol_playlist_add_check_circle,
	playlist_add_circle: symbol_playlist_add_circle,
	playlist_play: symbol_playlist_play,
	playlist_remove: symbol_playlist_remove,
	plumbing: symbol_plumbing,
	podcasts: symbol_podcasts,
	podiatry: symbol_podiatry,
	podium: symbol_podium,
	point_of_sale: symbol_point_of_sale,
	point_scan: symbol_point_scan,
	poker_chip: symbol_poker_chip,
	policy: symbol_policy,
	polyline: symbol_polyline,
	polymer: symbol_polymer,
	pool: symbol_pool,
	portable_wifi_off: symbol_portable_wifi_off,
	position_bottom_left: symbol_position_bottom_left,
	position_bottom_right: symbol_position_bottom_right,
	position_top_right: symbol_position_top_right,
	post: symbol_post,
	post_add: symbol_post_add,
	potted_plant: symbol_potted_plant,
	power: symbol_power,
	power_input: symbol_power_input,
	power_off: symbol_power_off,
	power_settings_new: symbol_power_settings_new,
	prayer_times: symbol_prayer_times,
	precision_manufacturing: symbol_precision_manufacturing,
	pregnancy: symbol_pregnancy,
	pregnant_woman: symbol_pregnant_woman,
	preliminary: symbol_preliminary,
	prescriptions: symbol_prescriptions,
	present_to_all: symbol_present_to_all,
	preview: symbol_preview,
	preview_off: symbol_preview_off,
	price_change: symbol_price_change,
	price_check: symbol_price_check,
	print: symbol_print,
	print_add: symbol_print_add,
	print_connect: symbol_print_connect,
	print_disabled: symbol_print_disabled,
	print_error: symbol_print_error,
	print_lock: symbol_print_lock,
	priority: symbol_priority,
	priority_high: symbol_priority_high,
	privacy: symbol_privacy,
	privacy_tip: symbol_privacy_tip,
	private_connectivity: symbol_private_connectivity,
	problem: symbol_problem,
	procedure: symbol_procedure,
	process_chart: symbol_process_chart,
	production_quantity_limits: symbol_production_quantity_limits,
	productivity: symbol_productivity,
	progress_activity: symbol_progress_activity,
	prompt_suggestion: symbol_prompt_suggestion,
	propane: symbol_propane,
	propane_tank: symbol_propane_tank,
	psychiatry: symbol_psychiatry,
	psychology: symbol_psychology,
	psychology_alt: symbol_psychology_alt,
	public: symbol_public,
	public_off: symbol_public_off,
	publish: symbol_publish,
	published_with_changes: symbol_published_with_changes,
	pulmonology: symbol_pulmonology,
	pulse_alert: symbol_pulse_alert,
	punch_clock: symbol_punch_clock,
	qr_code: symbol_qr_code,
	qr_code_2: symbol_qr_code_2,
	qr_code_2_add: symbol_qr_code_2_add,
	qr_code_scanner: symbol_qr_code_scanner,
	query_stats: symbol_query_stats,
	question_exchange: symbol_question_exchange,
	question_mark: symbol_question_mark,
	queue_music: symbol_queue_music,
	queue_play_next: symbol_queue_play_next,
	quick_phrases: symbol_quick_phrases,
	quick_reference: symbol_quick_reference,
	quick_reference_all: symbol_quick_reference_all,
	quick_reorder: symbol_quick_reorder,
	quickreply: symbol_quickreply,
	quiet_time: symbol_quiet_time,
	quiet_time_active: symbol_quiet_time_active,
	quiz: symbol_quiz,
	r_mobiledata: symbol_r_mobiledata,
	radar: symbol_radar,
	radio: symbol_radio,
	radio_button_checked: symbol_radio_button_checked,
	radio_button_partial: symbol_radio_button_partial,
	radio_button_unchecked: symbol_radio_button_unchecked,
	radiology: symbol_radiology,
	railway_alert: symbol_railway_alert,
	rainy: symbol_rainy,
	rainy_heavy: symbol_rainy_heavy,
	rainy_light: symbol_rainy_light,
	rainy_snow: symbol_rainy_snow,
	ramen_dining: symbol_ramen_dining,
	ramp_left: symbol_ramp_left,
	ramp_right: symbol_ramp_right,
	range_hood: symbol_range_hood,
	rate_review: symbol_rate_review,
	raven: symbol_raven,
	raw_off: symbol_raw_off,
	raw_on: symbol_raw_on,
	read_more: symbol_read_more,
	readiness_score: symbol_readiness_score,
	real_estate_agent: symbol_real_estate_agent,
	rear_camera: symbol_rear_camera,
	rebase: symbol_rebase,
	rebase_edit: symbol_rebase_edit,
	receipt: symbol_receipt,
	receipt_long: symbol_receipt_long,
	recent_actors: symbol_recent_actors,
	recent_patient: symbol_recent_patient,
	recenter: symbol_recenter,
	recommend: symbol_recommend,
	record_voice_over: symbol_record_voice_over,
	rectangle: symbol_rectangle,
	recycling: symbol_recycling,
	redeem: symbol_redeem,
	redo: symbol_redo,
	reduce_capacity: symbol_reduce_capacity,
	refresh: symbol_refresh,
	regular_expression: symbol_regular_expression,
	relax: symbol_relax,
	release_alert: symbol_release_alert,
	remember_me: symbol_remember_me,
	reminder: symbol_reminder,
	remote_gen: symbol_remote_gen,
	remove: symbol_remove,
	remove_done: symbol_remove_done,
	remove_from_queue: symbol_remove_from_queue,
	remove_moderator: symbol_remove_moderator,
	remove_road: symbol_remove_road,
	remove_selection: symbol_remove_selection,
	remove_shopping_cart: symbol_remove_shopping_cart,
	reopen_window: symbol_reopen_window,
	reorder: symbol_reorder,
	repartition: symbol_repartition,
	repeat: symbol_repeat,
	repeat_on: symbol_repeat_on,
	repeat_one: symbol_repeat_one,
	repeat_one_on: symbol_repeat_one_on,
	replay: symbol_replay,
	replay_10: symbol_replay_10,
	replay_30: symbol_replay_30,
	replay_5: symbol_replay_5,
	reply: symbol_reply,
	reply_all: symbol_reply_all,
	report: symbol_report,
	report_off: symbol_report_off,
	request_page: symbol_request_page,
	request_quote: symbol_request_quote,
	reset_image: symbol_reset_image,
	reset_tv: symbol_reset_tv,
	reset_wrench: symbol_reset_wrench,
	resize: symbol_resize,
	respiratory_rate: symbol_respiratory_rate,
	responsive_layout: symbol_responsive_layout,
	restart_alt: symbol_restart_alt,
	restaurant: symbol_restaurant,
	restaurant_menu: symbol_restaurant_menu,
	restore_from_trash: symbol_restore_from_trash,
	restore_page: symbol_restore_page,
	resume: symbol_resume,
	reviews: symbol_reviews,
	rewarded_ads: symbol_rewarded_ads,
	rheumatology: symbol_rheumatology,
	rib_cage: symbol_rib_cage,
	rice_bowl: symbol_rice_bowl,
	right_click: symbol_right_click,
	right_panel_close: symbol_right_panel_close,
	right_panel_open: symbol_right_panel_open,
	ring_volume: symbol_ring_volume,
	ripples: symbol_ripples,
	robot: symbol_robot,
	robot_2: symbol_robot_2,
	rocket: symbol_rocket,
	rocket_launch: symbol_rocket_launch,
	roller_shades: symbol_roller_shades,
	roller_shades_closed: symbol_roller_shades_closed,
	roller_skating: symbol_roller_skating,
	roofing: symbol_roofing,
	room_preferences: symbol_room_preferences,
	room_service: symbol_room_service,
	rotate_90_degrees_ccw: symbol_rotate_90_degrees_ccw,
	rotate_90_degrees_cw: symbol_rotate_90_degrees_cw,
	rotate_left: symbol_rotate_left,
	rotate_right: symbol_rotate_right,
	roundabout_left: symbol_roundabout_left,
	roundabout_right: symbol_roundabout_right,
	rounded_corner: symbol_rounded_corner,
	route: symbol_route,
	router: symbol_router,
	routine: symbol_routine,
	rowing: symbol_rowing,
	rss_feed: symbol_rss_feed,
	rsvp: symbol_rsvp,
	rtt: symbol_rtt,
	rubric: symbol_rubric,
	rule: symbol_rule,
	rule_folder: symbol_rule_folder,
	rule_settings: symbol_rule_settings,
	run_circle: symbol_run_circle,
	running_with_errors: symbol_running_with_errors,
	rv_hookup: symbol_rv_hookup,
	safety_check: symbol_safety_check,
	safety_check_off: symbol_safety_check_off,
	safety_divider: symbol_safety_divider,
	sailing: symbol_sailing,
	salinity: symbol_salinity,
	sanitizer: symbol_sanitizer,
	satellite: symbol_satellite,
	satellite_alt: symbol_satellite_alt,
	sauna: symbol_sauna,
	save: symbol_save,
	save_as: symbol_save_as,
	saved_search: symbol_saved_search,
	savings: symbol_savings,
	scale: symbol_scale,
	scan: symbol_scan,
	scan_delete: symbol_scan_delete,
	scanner: symbol_scanner,
	scatter_plot: symbol_scatter_plot,
	scene: symbol_scene,
	schedule: symbol_schedule,
	schedule_send: symbol_schedule_send,
	schema: symbol_schema,
	school: symbol_school,
	science: symbol_science,
	science_off: symbol_science_off,
	score: symbol_score,
	scoreboard: symbol_scoreboard,
	screen_lock_landscape: symbol_screen_lock_landscape,
	screen_lock_portrait: symbol_screen_lock_portrait,
	screen_lock_rotation: symbol_screen_lock_rotation,
	screen_record: symbol_screen_record,
	screen_rotation: symbol_screen_rotation,
	screen_rotation_alt: symbol_screen_rotation_alt,
	screen_rotation_up: symbol_screen_rotation_up,
	screen_search_desktop: symbol_screen_search_desktop,
	screen_share: symbol_screen_share,
	screenshot: symbol_screenshot,
	screenshot_frame: symbol_screenshot_frame,
	screenshot_keyboard: symbol_screenshot_keyboard,
	screenshot_monitor: symbol_screenshot_monitor,
	screenshot_region: symbol_screenshot_region,
	screenshot_tablet: symbol_screenshot_tablet,
	scrollable_header: symbol_scrollable_header,
	scuba_diving: symbol_scuba_diving,
	sd: symbol_sd,
	sd_card: symbol_sd_card,
	sd_card_alert: symbol_sd_card_alert,
	sdk: symbol_sdk,
	search: symbol_search,
	search_check: symbol_search_check,
	search_hands_free: symbol_search_hands_free,
	search_insights: symbol_search_insights,
	search_off: symbol_search_off,
	security: symbol_security,
	security_key: symbol_security_key,
	security_update_good: symbol_security_update_good,
	security_update_warning: symbol_security_update_warning,
	segment: symbol_segment,
	select: symbol_select,
	select_all: symbol_select_all,
	select_check_box: symbol_select_check_box,
	select_to_speak: symbol_select_to_speak,
	select_window: symbol_select_window,
	select_window_2: symbol_select_window_2,
	select_window_off: symbol_select_window_off,
	self_care: symbol_self_care,
	self_improvement: symbol_self_improvement,
	sell: symbol_sell,
	send: symbol_send,
	send_and_archive: symbol_send_and_archive,
	send_money: symbol_send_money,
	send_time_extension: symbol_send_time_extension,
	send_to_mobile: symbol_send_to_mobile,
	sensor_door: symbol_sensor_door,
	sensor_occupied: symbol_sensor_occupied,
	sensor_window: symbol_sensor_window,
	sensors: symbol_sensors,
	sensors_krx: symbol_sensors_krx,
	sensors_krx_off: symbol_sensors_krx_off,
	sensors_off: symbol_sensors_off,
	sentiment_calm: symbol_sentiment_calm,
	sentiment_content: symbol_sentiment_content,
	sentiment_dissatisfied: symbol_sentiment_dissatisfied,
	sentiment_excited: symbol_sentiment_excited,
	sentiment_extremely_dissatisfied: symbol_sentiment_extremely_dissatisfied,
	sentiment_frustrated: symbol_sentiment_frustrated,
	sentiment_neutral: symbol_sentiment_neutral,
	sentiment_sad: symbol_sentiment_sad,
	sentiment_satisfied: symbol_sentiment_satisfied,
	sentiment_stressed: symbol_sentiment_stressed,
	sentiment_very_dissatisfied: symbol_sentiment_very_dissatisfied,
	sentiment_very_satisfied: symbol_sentiment_very_satisfied,
	sentiment_worried: symbol_sentiment_worried,
	serif: symbol_serif,
	service_toolbox: symbol_service_toolbox,
	set_meal: symbol_set_meal,
	settings: symbol_settings,
	settings_accessibility: symbol_settings_accessibility,
	settings_account_box: symbol_settings_account_box,
	settings_alert: symbol_settings_alert,
	settings_applications: symbol_settings_applications,
	settings_b_roll: symbol_settings_b_roll,
	settings_backup_restore: symbol_settings_backup_restore,
	settings_bluetooth: symbol_settings_bluetooth,
	settings_brightness: symbol_settings_brightness,
	settings_cell: symbol_settings_cell,
	settings_cinematic_blur: symbol_settings_cinematic_blur,
	settings_ethernet: symbol_settings_ethernet,
	settings_heart: symbol_settings_heart,
	settings_input_antenna: symbol_settings_input_antenna,
	settings_input_component: symbol_settings_input_component,
	settings_input_hdmi: symbol_settings_input_hdmi,
	settings_input_svideo: symbol_settings_input_svideo,
	settings_motion_mode: symbol_settings_motion_mode,
	settings_night_sight: symbol_settings_night_sight,
	settings_overscan: symbol_settings_overscan,
	settings_panorama: symbol_settings_panorama,
	settings_phone: symbol_settings_phone,
	settings_photo_camera: symbol_settings_photo_camera,
	settings_power: symbol_settings_power,
	settings_remote: symbol_settings_remote,
	settings_slow_motion: symbol_settings_slow_motion,
	settings_system_daydream: symbol_settings_system_daydream,
	settings_timelapse: symbol_settings_timelapse,
	settings_video_camera: symbol_settings_video_camera,
	settings_voice: symbol_settings_voice,
	settop_component: symbol_settop_component,
	severe_cold: symbol_severe_cold,
	shadow: symbol_shadow,
	shadow_add: symbol_shadow_add,
	shadow_minus: symbol_shadow_minus,
	shape_line: symbol_shape_line,
	shapes: symbol_shapes,
	share: symbol_share,
	share_location: symbol_share_location,
	share_off: symbol_share_off,
	share_reviews: symbol_share_reviews,
	share_windows: symbol_share_windows,
	sheets_rtl: symbol_sheets_rtl,
	shelf_auto_hide: symbol_shelf_auto_hide,
	shelf_position: symbol_shelf_position,
	shelves: symbol_shelves,
	shield: symbol_shield,
	shield_lock: symbol_shield_lock,
	shield_locked: symbol_shield_locked,
	shield_moon: symbol_shield_moon,
	shield_person: symbol_shield_person,
	shield_question: symbol_shield_question,
	shield_with_heart: symbol_shield_with_heart,
	shield_with_house: symbol_shield_with_house,
	shift: symbol_shift,
	shift_lock: symbol_shift_lock,
	shop: symbol_shop,
	shop_two: symbol_shop_two,
	shopping_bag: symbol_shopping_bag,
	shopping_basket: symbol_shopping_basket,
	shopping_cart: symbol_shopping_cart,
	shopping_cart_checkout: symbol_shopping_cart_checkout,
	shopping_cart_off: symbol_shopping_cart_off,
	shoppingmode: symbol_shoppingmode,
	short_stay: symbol_short_stay,
	short_text: symbol_short_text,
	show_chart: symbol_show_chart,
	shower: symbol_shower,
	shuffle: symbol_shuffle,
	shuffle_on: symbol_shuffle_on,
	shutter_speed: symbol_shutter_speed,
	shutter_speed_add: symbol_shutter_speed_add,
	shutter_speed_minus: symbol_shutter_speed_minus,
	sick: symbol_sick,
	side_navigation: symbol_side_navigation,
	sign_language: symbol_sign_language,
	signal_cellular_0_bar: symbol_signal_cellular_0_bar,
	signal_cellular_1_bar: symbol_signal_cellular_1_bar,
	signal_cellular_2_bar: symbol_signal_cellular_2_bar,
	signal_cellular_3_bar: symbol_signal_cellular_3_bar,
	signal_cellular_4_bar: symbol_signal_cellular_4_bar,
	signal_cellular_add: symbol_signal_cellular_add,
	signal_cellular_alt: symbol_signal_cellular_alt,
	signal_cellular_alt_1_bar: symbol_signal_cellular_alt_1_bar,
	signal_cellular_alt_2_bar: symbol_signal_cellular_alt_2_bar,
	signal_cellular_connected_no_internet_0_bar:
		symbol_signal_cellular_connected_no_internet_0_bar,
	signal_cellular_connected_no_internet_4_bar:
		symbol_signal_cellular_connected_no_internet_4_bar,
	signal_cellular_nodata: symbol_signal_cellular_nodata,
	signal_cellular_null: symbol_signal_cellular_null,
	signal_cellular_off: symbol_signal_cellular_off,
	signal_cellular_pause: symbol_signal_cellular_pause,
	signal_disconnected: symbol_signal_disconnected,
	signal_wifi_0_bar: symbol_signal_wifi_0_bar,
	signal_wifi_4_bar: symbol_signal_wifi_4_bar,
	signal_wifi_bad: symbol_signal_wifi_bad,
	signal_wifi_off: symbol_signal_wifi_off,
	signal_wifi_statusbar_not_connected:
		symbol_signal_wifi_statusbar_not_connected,
	signal_wifi_statusbar_null: symbol_signal_wifi_statusbar_null,
	signature: symbol_signature,
	signpost: symbol_signpost,
	sim_card: symbol_sim_card,
	sim_card_download: symbol_sim_card_download,
	single_bed: symbol_single_bed,
	sip: symbol_sip,
	skateboarding: symbol_skateboarding,
	skeleton: symbol_skeleton,
	skillet: symbol_skillet,
	skillet_cooktop: symbol_skillet_cooktop,
	skip_next: symbol_skip_next,
	skip_previous: symbol_skip_previous,
	skull: symbol_skull,
	slab_serif: symbol_slab_serif,
	sledding: symbol_sledding,
	sleep_score: symbol_sleep_score,
	slide_library: symbol_slide_library,
	sliders: symbol_sliders,
	slideshow: symbol_slideshow,
	slow_motion_video: symbol_slow_motion_video,
	smart_card_reader: symbol_smart_card_reader,
	smart_card_reader_off: symbol_smart_card_reader_off,
	smart_display: symbol_smart_display,
	smart_outlet: symbol_smart_outlet,
	smart_screen: symbol_smart_screen,
	smart_toy: symbol_smart_toy,
	smartphone: symbol_smartphone,
	smb_share: symbol_smb_share,
	smoke_free: symbol_smoke_free,
	smoking_rooms: symbol_smoking_rooms,
	sms: symbol_sms,
	snippet_folder: symbol_snippet_folder,
	snooze: symbol_snooze,
	snowboarding: symbol_snowboarding,
	snowing: symbol_snowing,
	snowing_heavy: symbol_snowing_heavy,
	snowmobile: symbol_snowmobile,
	snowshoeing: symbol_snowshoeing,
	soap: symbol_soap,
	social_distance: symbol_social_distance,
	social_leaderboard: symbol_social_leaderboard,
	solar_power: symbol_solar_power,
	sort: symbol_sort,
	sort_by_alpha: symbol_sort_by_alpha,
	sos: symbol_sos,
	sound_detection_dog_barking: symbol_sound_detection_dog_barking,
	sound_detection_glass_break: symbol_sound_detection_glass_break,
	sound_detection_loud_sound: symbol_sound_detection_loud_sound,
	sound_sampler: symbol_sound_sampler,
	soup_kitchen: symbol_soup_kitchen,
	source_environment: symbol_source_environment,
	source_notes: symbol_source_notes,
	south: symbol_south,
	south_america: symbol_south_america,
	south_east: symbol_south_east,
	south_west: symbol_south_west,
	spa: symbol_spa,
	space_bar: symbol_space_bar,
	space_dashboard: symbol_space_dashboard,
	spatial_audio: symbol_spatial_audio,
	spatial_audio_off: symbol_spatial_audio_off,
	spatial_speaker: symbol_spatial_speaker,
	spatial_tracking: symbol_spatial_tracking,
	speaker: symbol_speaker,
	speaker_group: symbol_speaker_group,
	speaker_notes: symbol_speaker_notes,
	speaker_notes_off: symbol_speaker_notes_off,
	speaker_phone: symbol_speaker_phone,
	special_character: symbol_special_character,
	specific_gravity: symbol_specific_gravity,
	speech_to_text: symbol_speech_to_text,
	speed: symbol_speed,
	speed_0_25: symbol_speed_0_25,
	speed_0_2x: symbol_speed_0_2x,
	speed_0_5: symbol_speed_0_5,
	speed_0_5x: symbol_speed_0_5x,
	speed_0_75: symbol_speed_0_75,
	speed_0_7x: symbol_speed_0_7x,
	speed_1_2: symbol_speed_1_2,
	speed_1_25: symbol_speed_1_25,
	speed_1_2x: symbol_speed_1_2x,
	speed_1_5: symbol_speed_1_5,
	speed_1_5x: symbol_speed_1_5x,
	speed_1_75: symbol_speed_1_75,
	speed_1_7x: symbol_speed_1_7x,
	speed_2x: symbol_speed_2x,
	spellcheck: symbol_spellcheck,
	splitscreen: symbol_splitscreen,
	splitscreen_add: symbol_splitscreen_add,
	splitscreen_bottom: symbol_splitscreen_bottom,
	splitscreen_left: symbol_splitscreen_left,
	splitscreen_right: symbol_splitscreen_right,
	splitscreen_top: symbol_splitscreen_top,
	splitscreen_vertical_add: symbol_splitscreen_vertical_add,
	spo2: symbol_spo2,
	spoke: symbol_spoke,
	sports: symbol_sports,
	sports_and_outdoors: symbol_sports_and_outdoors,
	sports_bar: symbol_sports_bar,
	sports_baseball: symbol_sports_baseball,
	sports_basketball: symbol_sports_basketball,
	sports_cricket: symbol_sports_cricket,
	sports_esports: symbol_sports_esports,
	sports_football: symbol_sports_football,
	sports_golf: symbol_sports_golf,
	sports_gymnastics: symbol_sports_gymnastics,
	sports_handball: symbol_sports_handball,
	sports_hockey: symbol_sports_hockey,
	sports_kabaddi: symbol_sports_kabaddi,
	sports_martial_arts: symbol_sports_martial_arts,
	sports_mma: symbol_sports_mma,
	sports_motorsports: symbol_sports_motorsports,
	sports_rugby: symbol_sports_rugby,
	sports_score: symbol_sports_score,
	sports_soccer: symbol_sports_soccer,
	sports_tennis: symbol_sports_tennis,
	sports_volleyball: symbol_sports_volleyball,
	sprinkler: symbol_sprinkler,
	sprint: symbol_sprint,
	square: symbol_square,
	square_foot: symbol_square_foot,
	ssid_chart: symbol_ssid_chart,
	stack: symbol_stack,
	stack_off: symbol_stack_off,
	stack_star: symbol_stack_star,
	stacked_bar_chart: symbol_stacked_bar_chart,
	stacked_email: symbol_stacked_email,
	stacked_inbox: symbol_stacked_inbox,
	stacked_line_chart: symbol_stacked_line_chart,
	stacks: symbol_stacks,
	stadia_controller: symbol_stadia_controller,
	stadium: symbol_stadium,
	stairs: symbol_stairs,
	star: symbol_star,
	star_half: symbol_star_half,
	star_rate: symbol_star_rate,
	star_rate_half: symbol_star_rate_half,
	stars: symbol_stars,
	start: symbol_start,
	stat_0: symbol_stat_0,
	stat_1: symbol_stat_1,
	stat_2: symbol_stat_2,
	stat_3: symbol_stat_3,
	stat_minus_1: symbol_stat_minus_1,
	stat_minus_2: symbol_stat_minus_2,
	stat_minus_3: symbol_stat_minus_3,
	stay_current_landscape: symbol_stay_current_landscape,
	stay_current_portrait: symbol_stay_current_portrait,
	stay_primary_landscape: symbol_stay_primary_landscape,
	stay_primary_portrait: symbol_stay_primary_portrait,
	step: symbol_step,
	step_into: symbol_step_into,
	step_out: symbol_step_out,
	step_over: symbol_step_over,
	steppers: symbol_steppers,
	steps: symbol_steps,
	stethoscope: symbol_stethoscope,
	stethoscope_arrow: symbol_stethoscope_arrow,
	stethoscope_check: symbol_stethoscope_check,
	sticky_note: symbol_sticky_note,
	sticky_note_2: symbol_sticky_note_2,
	stock_media: symbol_stock_media,
	stockpot: symbol_stockpot,
	stop: symbol_stop,
	stop_circle: symbol_stop_circle,
	stop_screen_share: symbol_stop_screen_share,
	storage: symbol_storage,
	store: symbol_store,
	storefront: symbol_storefront,
	storm: symbol_storm,
	straight: symbol_straight,
	straighten: symbol_straighten,
	strategy: symbol_strategy,
	stream: symbol_stream,
	stream_apps: symbol_stream_apps,
	streetview: symbol_streetview,
	stress_management: symbol_stress_management,
	strikethrough_s: symbol_strikethrough_s,
	stroke_full: symbol_stroke_full,
	stroke_partial: symbol_stroke_partial,
	stroller: symbol_stroller,
	style: symbol_style,
	styler: symbol_styler,
	stylus: symbol_stylus,
	stylus_laser_pointer: symbol_stylus_laser_pointer,
	stylus_note: symbol_stylus_note,
	subdirectory_arrow_left: symbol_subdirectory_arrow_left,
	subdirectory_arrow_right: symbol_subdirectory_arrow_right,
	subheader: symbol_subheader,
	subject: symbol_subject,
	subscript: symbol_subscript,
	subscriptions: symbol_subscriptions,
	subtitles: symbol_subtitles,
	subtitles_off: symbol_subtitles_off,
	subway: symbol_subway,
	summarize: symbol_summarize,
	sunny: symbol_sunny,
	sunny_snowing: symbol_sunny_snowing,
	superscript: symbol_superscript,
	supervised_user_circle: symbol_supervised_user_circle,
	supervised_user_circle_off: symbol_supervised_user_circle_off,
	supervisor_account: symbol_supervisor_account,
	support: symbol_support,
	support_agent: symbol_support_agent,
	surfing: symbol_surfing,
	surgical: symbol_surgical,
	surround_sound: symbol_surround_sound,
	swap_calls: symbol_swap_calls,
	swap_driving_apps: symbol_swap_driving_apps,
	swap_driving_apps_wheel: symbol_swap_driving_apps_wheel,
	swap_horiz: symbol_swap_horiz,
	swap_horizontal_circle: symbol_swap_horizontal_circle,
	swap_vert: symbol_swap_vert,
	swap_vertical_circle: symbol_swap_vertical_circle,
	sweep: symbol_sweep,
	swipe: symbol_swipe,
	swipe_down: symbol_swipe_down,
	swipe_down_alt: symbol_swipe_down_alt,
	swipe_left: symbol_swipe_left,
	swipe_left_alt: symbol_swipe_left_alt,
	swipe_right: symbol_swipe_right,
	swipe_right_alt: symbol_swipe_right_alt,
	swipe_up: symbol_swipe_up,
	swipe_up_alt: symbol_swipe_up_alt,
	swipe_vertical: symbol_swipe_vertical,
	switch: symbol_switch,
	switch_access: symbol_switch_access,
	switch_access_2: symbol_switch_access_2,
	switch_access_shortcut: symbol_switch_access_shortcut,
	switch_access_shortcut_add: symbol_switch_access_shortcut_add,
	switch_account: symbol_switch_account,
	switch_camera: symbol_switch_camera,
	switch_left: symbol_switch_left,
	switch_right: symbol_switch_right,
	switch_video: symbol_switch_video,
	switches: symbol_switches,
	sword_rose: symbol_sword_rose,
	swords: symbol_swords,
	symptoms: symbol_symptoms,
	synagogue: symbol_synagogue,
	sync: symbol_sync,
	sync_alt: symbol_sync_alt,
	sync_disabled: symbol_sync_disabled,
	sync_lock: symbol_sync_lock,
	sync_problem: symbol_sync_problem,
	sync_saved_locally: symbol_sync_saved_locally,
	syringe: symbol_syringe,
	system_update: symbol_system_update,
	system_update_alt: symbol_system_update_alt,
	tab: symbol_tab,
	tab_close: symbol_tab_close,
	tab_close_right: symbol_tab_close_right,
	tab_duplicate: symbol_tab_duplicate,
	tab_group: symbol_tab_group,
	tab_move: symbol_tab_move,
	tab_new_right: symbol_tab_new_right,
	tab_recent: symbol_tab_recent,
	tab_unselected: symbol_tab_unselected,
	table: symbol_table,
	table_bar: symbol_table_bar,
	table_chart: symbol_table_chart,
	table_chart_view: symbol_table_chart_view,
	table_lamp: symbol_table_lamp,
	table_restaurant: symbol_table_restaurant,
	table_rows: symbol_table_rows,
	table_rows_narrow: symbol_table_rows_narrow,
	table_view: symbol_table_view,
	tablet: symbol_tablet,
	tablet_android: symbol_tablet_android,
	tablet_mac: symbol_tablet_mac,
	tabs: symbol_tabs,
	tactic: symbol_tactic,
	tag: symbol_tag,
	takeout_dining: symbol_takeout_dining,
	tamper_detection_off: symbol_tamper_detection_off,
	tamper_detection_on: symbol_tamper_detection_on,
	tap_and_play: symbol_tap_and_play,
	tapas: symbol_tapas,
	target: symbol_target,
	task: symbol_task,
	task_alt: symbol_task_alt,
	taunt: symbol_taunt,
	taxi_alert: symbol_taxi_alert,
	team_dashboard: symbol_team_dashboard,
	temp_preferences_eco: symbol_temp_preferences_eco,
	temple_buddhist: symbol_temple_buddhist,
	temple_hindu: symbol_temple_hindu,
	tenancy: symbol_tenancy,
	terminal: symbol_terminal,
	text_ad: symbol_text_ad,
	text_decrease: symbol_text_decrease,
	text_fields: symbol_text_fields,
	text_fields_alt: symbol_text_fields_alt,
	text_format: symbol_text_format,
	text_increase: symbol_text_increase,
	text_rotate_up: symbol_text_rotate_up,
	text_rotate_vertical: symbol_text_rotate_vertical,
	text_rotation_angledown: symbol_text_rotation_angledown,
	text_rotation_angleup: symbol_text_rotation_angleup,
	text_rotation_down: symbol_text_rotation_down,
	text_rotation_none: symbol_text_rotation_none,
	text_select_end: symbol_text_select_end,
	text_select_jump_to_beginning: symbol_text_select_jump_to_beginning,
	text_select_jump_to_end: symbol_text_select_jump_to_end,
	text_select_move_back_character: symbol_text_select_move_back_character,
	text_select_move_back_word: symbol_text_select_move_back_word,
	text_select_move_down: symbol_text_select_move_down,
	text_select_move_forward_character: symbol_text_select_move_forward_character,
	text_select_move_forward_word: symbol_text_select_move_forward_word,
	text_select_move_up: symbol_text_select_move_up,
	text_select_start: symbol_text_select_start,
	text_snippet: symbol_text_snippet,
	text_to_speech: symbol_text_to_speech,
	text_up: symbol_text_up,
	texture: symbol_texture,
	texture_add: symbol_texture_add,
	texture_minus: symbol_texture_minus,
	theater_comedy: symbol_theater_comedy,
	theaters: symbol_theaters,
	thermometer: symbol_thermometer,
	thermometer_add: symbol_thermometer_add,
	thermometer_gain: symbol_thermometer_gain,
	thermometer_loss: symbol_thermometer_loss,
	thermometer_minus: symbol_thermometer_minus,
	thermostat: symbol_thermostat,
	thermostat_auto: symbol_thermostat_auto,
	thermostat_carbon: symbol_thermostat_carbon,
	things_to_do: symbol_things_to_do,
	thread_unread: symbol_thread_unread,
	thumb_down: symbol_thumb_down,
	thumb_up: symbol_thumb_up,
	thumbnail_bar: symbol_thumbnail_bar,
	thumbs_up_down: symbol_thumbs_up_down,
	thunderstorm: symbol_thunderstorm,
	tibia: symbol_tibia,
	tibia_alt: symbol_tibia_alt,
	time_auto: symbol_time_auto,
	timelapse: symbol_timelapse,
	timeline: symbol_timeline,
	timer: symbol_timer,
	timer_10: symbol_timer_10,
	timer_10_alt_1: symbol_timer_10_alt_1,
	timer_10_select: symbol_timer_10_select,
	timer_3: symbol_timer_3,
	timer_3_alt_1: symbol_timer_3_alt_1,
	timer_3_select: symbol_timer_3_select,
	timer_5: symbol_timer_5,
	timer_5_shutter: symbol_timer_5_shutter,
	timer_off: symbol_timer_off,
	timer_pause: symbol_timer_pause,
	timer_play: symbol_timer_play,
	tire_repair: symbol_tire_repair,
	title: symbol_title,
	titlecase: symbol_titlecase,
	toast: symbol_toast,
	toc: symbol_toc,
	today: symbol_today,
	toggle_off: symbol_toggle_off,
	toggle_on: symbol_toggle_on,
	token: symbol_token,
	toll: symbol_toll,
	tonality: symbol_tonality,
	toolbar: symbol_toolbar,
	tools_flat_head: symbol_tools_flat_head,
	tools_installation_kit: symbol_tools_installation_kit,
	tools_ladder: symbol_tools_ladder,
	tools_level: symbol_tools_level,
	tools_phillips: symbol_tools_phillips,
	tools_pliers_wire_stripper: symbol_tools_pliers_wire_stripper,
	tools_power_drill: symbol_tools_power_drill,
	tooltip: symbol_tooltip,
	top_panel_close: symbol_top_panel_close,
	top_panel_open: symbol_top_panel_open,
	topic: symbol_topic,
	tornado: symbol_tornado,
	total_dissolved_solids: symbol_total_dissolved_solids,
	touch_app: symbol_touch_app,
	touchpad_mouse: symbol_touchpad_mouse,
	touchpad_mouse_off: symbol_touchpad_mouse_off,
	tour: symbol_tour,
	toys: symbol_toys,
	toys_and_games: symbol_toys_and_games,
	toys_fan: symbol_toys_fan,
	track_changes: symbol_track_changes,
	trackpad_input: symbol_trackpad_input,
	traffic: symbol_traffic,
	trail_length: symbol_trail_length,
	trail_length_medium: symbol_trail_length_medium,
	trail_length_short: symbol_trail_length_short,
	train: symbol_train,
	tram: symbol_tram,
	transcribe: symbol_transcribe,
	transfer_within_a_station: symbol_transfer_within_a_station,
	transform: symbol_transform,
	transgender: symbol_transgender,
	transit_enterexit: symbol_transit_enterexit,
	transition_chop: symbol_transition_chop,
	transition_dissolve: symbol_transition_dissolve,
	transition_fade: symbol_transition_fade,
	transition_push: symbol_transition_push,
	transition_slide: symbol_transition_slide,
	translate: symbol_translate,
	transportation: symbol_transportation,
	travel: symbol_travel,
	travel_explore: symbol_travel_explore,
	travel_luggage_and_bags: symbol_travel_luggage_and_bags,
	trending_down: symbol_trending_down,
	trending_flat: symbol_trending_flat,
	trending_up: symbol_trending_up,
	trip: symbol_trip,
	trip_origin: symbol_trip_origin,
	trolley: symbol_trolley,
	trophy: symbol_trophy,
	troubleshoot: symbol_troubleshoot,
	tsunami: symbol_tsunami,
	tsv: symbol_tsv,
	tty: symbol_tty,
	tune: symbol_tune,
	turn_left: symbol_turn_left,
	turn_right: symbol_turn_right,
	turn_sharp_left: symbol_turn_sharp_left,
	turn_sharp_right: symbol_turn_sharp_right,
	turn_slight_left: symbol_turn_slight_left,
	turn_slight_right: symbol_turn_slight_right,
	tv: symbol_tv,
	tv_gen: symbol_tv_gen,
	tv_guide: symbol_tv_guide,
	tv_off: symbol_tv_off,
	tv_options_edit_channels: symbol_tv_options_edit_channels,
	tv_options_input_settings: symbol_tv_options_input_settings,
	tv_remote: symbol_tv_remote,
	tv_signin: symbol_tv_signin,
	tv_with_assistant: symbol_tv_with_assistant,
	two_pager: symbol_two_pager,
	two_wheeler: symbol_two_wheeler,
	type_specimen: symbol_type_specimen,
	u_turn_left: symbol_u_turn_left,
	u_turn_right: symbol_u_turn_right,
	ulna_radius: symbol_ulna_radius,
	ulna_radius_alt: symbol_ulna_radius_alt,
	umbrella: symbol_umbrella,
	unarchive: symbol_unarchive,
	undo: symbol_undo,
	unfold_less: symbol_unfold_less,
	unfold_less_double: symbol_unfold_less_double,
	unfold_more: symbol_unfold_more,
	unfold_more_double: symbol_unfold_more_double,
	ungroup: symbol_ungroup,
	universal_currency: symbol_universal_currency,
	universal_currency_alt: symbol_universal_currency_alt,
	universal_local: symbol_universal_local,
	unknown_2: symbol_unknown_2,
	unknown_5: symbol_unknown_5,
	unknown_7: symbol_unknown_7,
	unknown_document: symbol_unknown_document,
	unknown_med: symbol_unknown_med,
	unlicense: symbol_unlicense,
	unpublished: symbol_unpublished,
	unsubscribe: symbol_unsubscribe,
	upcoming: symbol_upcoming,
	update: symbol_update,
	update_disabled: symbol_update_disabled,
	upgrade: symbol_upgrade,
	upload: symbol_upload,
	upload_2: symbol_upload_2,
	upload_file: symbol_upload_file,
	uppercase: symbol_uppercase,
	urology: symbol_urology,
	usb: symbol_usb,
	usb_off: symbol_usb_off,
	user_attributes: symbol_user_attributes,
	vaccines: symbol_vaccines,
	vacuum: symbol_vacuum,
	valve: symbol_valve,
	vape_free: symbol_vape_free,
	vaping_rooms: symbol_vaping_rooms,
	variable_add: symbol_variable_add,
	variable_insert: symbol_variable_insert,
	variable_remove: symbol_variable_remove,
	variables: symbol_variables,
	ventilator: symbol_ventilator,
	verified: symbol_verified,
	verified_user: symbol_verified_user,
	vertical_align_bottom: symbol_vertical_align_bottom,
	vertical_align_center: symbol_vertical_align_center,
	vertical_align_top: symbol_vertical_align_top,
	vertical_distribute: symbol_vertical_distribute,
	vertical_shades: symbol_vertical_shades,
	vertical_shades_closed: symbol_vertical_shades_closed,
	vertical_split: symbol_vertical_split,
	vibration: symbol_vibration,
	video_call: symbol_video_call,
	video_camera_back: symbol_video_camera_back,
	video_camera_front: symbol_video_camera_front,
	video_camera_front_off: symbol_video_camera_front_off,
	video_chat: symbol_video_chat,
	video_file: symbol_video_file,
	video_label: symbol_video_label,
	video_library: symbol_video_library,
	video_search: symbol_video_search,
	video_settings: symbol_video_settings,
	video_stable: symbol_video_stable,
	videocam: symbol_videocam,
	videocam_off: symbol_videocam_off,
	videogame_asset: symbol_videogame_asset,
	videogame_asset_off: symbol_videogame_asset_off,
	view_agenda: symbol_view_agenda,
	view_array: symbol_view_array,
	view_carousel: symbol_view_carousel,
	view_column: symbol_view_column,
	view_column_2: symbol_view_column_2,
	view_comfy: symbol_view_comfy,
	view_comfy_alt: symbol_view_comfy_alt,
	view_compact: symbol_view_compact,
	view_compact_alt: symbol_view_compact_alt,
	view_cozy: symbol_view_cozy,
	view_day: symbol_view_day,
	view_headline: symbol_view_headline,
	view_in_ar: symbol_view_in_ar,
	view_in_ar_off: symbol_view_in_ar_off,
	view_kanban: symbol_view_kanban,
	view_list: symbol_view_list,
	view_module: symbol_view_module,
	view_quilt: symbol_view_quilt,
	view_real_size: symbol_view_real_size,
	view_sidebar: symbol_view_sidebar,
	view_stream: symbol_view_stream,
	view_timeline: symbol_view_timeline,
	view_week: symbol_view_week,
	vignette: symbol_vignette,
	villa: symbol_villa,
	visibility: symbol_visibility,
	visibility_lock: symbol_visibility_lock,
	visibility_off: symbol_visibility_off,
	vital_signs: symbol_vital_signs,
	vo2_max: symbol_vo2_max,
	voice_chat: symbol_voice_chat,
	voice_over_off: symbol_voice_over_off,
	voice_selection: symbol_voice_selection,
	voicemail: symbol_voicemail,
	volcano: symbol_volcano,
	volume_down: symbol_volume_down,
	volume_down_alt: symbol_volume_down_alt,
	volume_mute: symbol_volume_mute,
	volume_off: symbol_volume_off,
	volume_up: symbol_volume_up,
	volunteer_activism: symbol_volunteer_activism,
	voting_chip: symbol_voting_chip,
	vpn_key: symbol_vpn_key,
	vpn_key_alert: symbol_vpn_key_alert,
	vpn_key_off: symbol_vpn_key_off,
	vpn_lock: symbol_vpn_lock,
	vr180_create2d: symbol_vr180_create2d,
	vr180_create2d_off: symbol_vr180_create2d_off,
	vrpano: symbol_vrpano,
	wall_art: symbol_wall_art,
	wall_lamp: symbol_wall_lamp,
	wallet: symbol_wallet,
	wallpaper: symbol_wallpaper,
	wallpaper_slideshow: symbol_wallpaper_slideshow,
	ward: symbol_ward,
	warehouse: symbol_warehouse,
	warning: symbol_warning,
	warning_off: symbol_warning_off,
	wash: symbol_wash,
	watch: symbol_watch,
	watch_button_press: symbol_watch_button_press,
	watch_off: symbol_watch_off,
	watch_screentime: symbol_watch_screentime,
	watch_wake: symbol_watch_wake,
	water: symbol_water,
	water_bottle: symbol_water_bottle,
	water_bottle_large: symbol_water_bottle_large,
	water_damage: symbol_water_damage,
	water_do: symbol_water_do,
	water_drop: symbol_water_drop,
	water_ec: symbol_water_ec,
	water_full: symbol_water_full,
	water_heater: symbol_water_heater,
	water_lock: symbol_water_lock,
	water_loss: symbol_water_loss,
	water_lux: symbol_water_lux,
	water_medium: symbol_water_medium,
	water_orp: symbol_water_orp,
	water_ph: symbol_water_ph,
	water_pump: symbol_water_pump,
	water_voc: symbol_water_voc,
	waterfall_chart: symbol_waterfall_chart,
	waves: symbol_waves,
	waving_hand: symbol_waving_hand,
	wb_auto: symbol_wb_auto,
	wb_incandescent: symbol_wb_incandescent,
	wb_iridescent: symbol_wb_iridescent,
	wb_shade: symbol_wb_shade,
	wb_sunny: symbol_wb_sunny,
	wb_twilight: symbol_wb_twilight,
	wc: symbol_wc,
	weather_hail: symbol_weather_hail,
	weather_mix: symbol_weather_mix,
	weather_snowy: symbol_weather_snowy,
	web: symbol_web,
	web_asset: symbol_web_asset,
	web_asset_off: symbol_web_asset_off,
	web_stories: symbol_web_stories,
	web_traffic: symbol_web_traffic,
	webhook: symbol_webhook,
	weekend: symbol_weekend,
	weight: symbol_weight,
	west: symbol_west,
	whatshot: symbol_whatshot,
	wheelchair_pickup: symbol_wheelchair_pickup,
	where_to_vote: symbol_where_to_vote,
	widgets: symbol_widgets,
	width: symbol_width,
	width_full: symbol_width_full,
	width_normal: symbol_width_normal,
	width_wide: symbol_width_wide,
	wifi: symbol_wifi,
	wifi_1_bar: symbol_wifi_1_bar,
	wifi_2_bar: symbol_wifi_2_bar,
	wifi_add: symbol_wifi_add,
	wifi_calling: symbol_wifi_calling,
	wifi_calling_1: symbol_wifi_calling_1,
	wifi_calling_2: symbol_wifi_calling_2,
	wifi_calling_3: symbol_wifi_calling_3,
	wifi_channel: symbol_wifi_channel,
	wifi_find: symbol_wifi_find,
	wifi_home: symbol_wifi_home,
	wifi_lock: symbol_wifi_lock,
	wifi_notification: symbol_wifi_notification,
	wifi_off: symbol_wifi_off,
	wifi_password: symbol_wifi_password,
	wifi_protected_setup: symbol_wifi_protected_setup,
	wifi_proxy: symbol_wifi_proxy,
	wifi_tethering: symbol_wifi_tethering,
	wifi_tethering_error: symbol_wifi_tethering_error,
	wifi_tethering_off: symbol_wifi_tethering_off,
	wind_power: symbol_wind_power,
	window: symbol_window,
	window_closed: symbol_window_closed,
	window_open: symbol_window_open,
	window_sensor: symbol_window_sensor,
	wine_bar: symbol_wine_bar,
	woman: symbol_woman,
	woman_2: symbol_woman_2,
	work: symbol_work,
	work_alert: symbol_work_alert,
	work_history: symbol_work_history,
	work_update: symbol_work_update,
	workspace_premium: symbol_workspace_premium,
	workspaces: symbol_workspaces,
	wounds_injuries: symbol_wounds_injuries,
	wrap_text: symbol_wrap_text,
	wrist: symbol_wrist,
	wrong_location: symbol_wrong_location,
	wysiwyg: symbol_wysiwyg,
	yard: symbol_yard,
	your_trips: symbol_your_trips,
	youtube_activity: symbol_youtube_activity,
	youtube_searched_for: symbol_youtube_searched_for,
	zone_person_alert: symbol_zone_person_alert,
	zone_person_idle: symbol_zone_person_idle,
	zone_person_urgent: symbol_zone_person_urgent,
	zoom_in: symbol_zoom_in,
	zoom_in_map: symbol_zoom_in_map,
	zoom_out: symbol_zoom_out,
	zoom_out_map: symbol_zoom_out_map,
});
export type MaterialSymbolVariant = keyof typeof MaterialSymbols;
