import { ReactNode } from 'react';

export const symbol_tools_phillips: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M310-120q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T310-180h340q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T650-120H310Zm0-120q-12.75 0-21.375-8.625T280-270v-350q0-9.75 3-18.75t9-17.25l125.812-167.766Q424-832 432-836t18-4h60q10 0 18 4t14 12l126 168q6 8.25 9 17.25t3 18.75v350q0 12.75-8.625 21.375T650-240H310Zm30-60h280v-111L480-551 340-411v111Zm0-196 110-110v-161L340-620v124Zm280 0v-124L510-767v161l110 110Zm0 196H340h280Z"
		/>
	</svg>
);
