import { ReactNode } from 'react';

export const symbol_my_location: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M450-72v-45q-137-14-228-105T117-450H72q-12.75 0-21.375-8.675Q42-467.351 42-480.175 42-493 50.625-501.5T72-510h45q14-137 105-228t228-105v-45q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-888v45q137 14 228 105t105 228h45q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T888-450h-45q-14 137-105 228T510-117v45q0 12.75-8.675 21.375Q492.649-42 479.825-42 467-42 458.5-50.625T450-72Zm30-104q125 0 214.5-89.5T784-480q0-125-89.5-214.5T480-784q-125 0-214.5 89.5T176-480q0 125 89.5 214.5T480-176Zm0-154q-63 0-106.5-43.5T330-480q0-63 43.5-106.5T480-630q63 0 106.5 43.5T630-480q0 63-43.5 106.5T480-330Zm0-60q38 0 64-26t26-64q0-38-26-64t-64-26q-38 0-64 26t-26 64q0 38 26 64t64 26Zm0-90Z"
		/>
	</svg>
);
