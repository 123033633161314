import {
	InputHTMLAttributes,
	Children,
	ReactNode,
	ChangeEvent,
	useState,
	SyntheticEvent,
	useCallback,
	useMemo,
	cloneElement,
	ReactElement,
	Fragment,
	useRef,
	forwardRef,
	SelectHTMLAttributes,
	WheelEvent,
	useEffect,
} from 'react';
import { DefaultTheme } from 'styled-components';
import { useFormContext } from 'react-hook-form';
import { t } from '@transifex/native';

import { debounce, useDebounce } from 'pkg/timings';
import withForwardedRef from 'pkg/withForwardedRef';
import DateTime from 'pkg/datetime';
import useUuid from 'pkg/hooks/useUuid';
import ColorConverter from 'pkg/colorconverter';

import { FormattedContent } from 'components/formatted-content';
import Toggle from 'components/Toggle';
import Icon from 'components/icon';

import Calendar from 'components/form/calendar';

import * as ContextMenu from 'design/context_menu';

import * as InputStyles from './styles';

interface PrefixSuffixProps extends InputStyles.PrefixProps {
	onClick?: () => void;
	interactable?: boolean;
	className?: string;
	children?: ReactNode;
}

export const Prefix = ({
	children,
	onClick,
	interactable,
	...rest
}: PrefixSuffixProps): JSX.Element => (
	<InputStyles.Prefix
		{...rest}
		onClick={onClick}
		interactable={interactable || !!onClick}>
		{children}
	</InputStyles.Prefix>
);

export const Suffix = ({
	children,
	onClick,
	interactable,
	...rest
}: PrefixSuffixProps): JSX.Element => (
	<InputStyles.Suffix
		{...rest}
		onClick={onClick}
		interactable={interactable || !!onClick}>
		{children}
	</InputStyles.Suffix>
);

export type InputChangeEvent = (
	event:
		| SyntheticEvent
		| ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
) => void;

export interface FieldProps
	extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
	rounded?: boolean;
	large?: boolean;
	small?: boolean;
	filled?: boolean;
	groupCode?: boolean;
	transparent?: boolean;
	theme?: DefaultTheme;
	active?: boolean;
	hidden?: boolean;

	value?: string | number;
	defaultValue?: string | number;
	disabled?: boolean;
	className?: string;
	testid?: string;
	onChange?: InputChangeEvent;
	onTyping?: InputChangeEvent;
	changeDelay?: number;
	readOnly?: boolean;
	// Callback for custom validation
	validate?: (
		value: string | number
	) => string | string[] | boolean | undefined;

	// This has the any type because typescript doesn't like forwardedRef.current
	// when we used Ref<HTMLlInputElement> type
	forwardedRef?: any;
	children?: ReactNode;
}

function FieldComponent({
	rounded,
	large,
	small,
	filled,
	groupCode,
	transparent,
	theme,
	active,
	hidden,

	value,
	defaultValue,
	disabled,
	className,
	testid,
	readOnly,

	onChange,
	onTyping,
	changeDelay,
	validate,

	children,
	forwardedRef,

	...rest
}: FieldProps): JSX.Element {
	const methods = useFormContext();

	const validationRules =
		rest.name &&
		methods &&
		methods.register(rest.name, {
			required: rest.required,
			minLength: rest.minLength,
			maxLength: rest.maxLength,
			disabled,
			min: rest.min,
			max: rest.max,
			pattern: new RegExp(rest.pattern),
			validate,
		});

	const emitChange = useDebounce(
		(event: ChangeEvent<HTMLInputElement> | SyntheticEvent) => {
			if (validationRules) {
				validationRules.onChange(event);
			}
			onChange(event);
		},
		changeDelay
	);

	let changeHandler: InputChangeEvent;
	let inputHandler: InputChangeEvent;

	if (onChange && !changeDelay) {
		changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
			if (validationRules) {
				validationRules.onChange(event);
			}

			onChange(event);
		};
	} else if (onChange && changeDelay) {
		inputHandler = (event: SyntheticEvent) => {
			if (onTyping) {
				onTyping(event);
			}

			emitChange(event);
		};
	} else if (!onChange && validationRules) {
		changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
			validationRules.onChange(event);
		};
	}

	let ref = forwardedRef;

	if (forwardedRef && validationRules) {
		ref = (e: HTMLInputElement) => {
			validationRules.ref(e);
			forwardedRef.current = e; // you can still assign to ref
		};
	} else if (validationRules) {
		ref = validationRules.ref;
	}

	let onScrollHandler;
	if (rest.type === 'number') {
		onScrollHandler = (e: WheelEvent<HTMLInputElement>) => {
			if (document.activeElement === e.currentTarget) {
				e.currentTarget.blur();
			}
		};
	}
	if (rest.type === 'hidden') {
		hidden = true;
	}

	return (
		<InputStyles.Wrapper
			theme={theme}
			rounded={rounded}
			large={large}
			small={small}
			filled={filled}
			groupCode={groupCode}
			transparent={transparent}
			hidden={hidden}
			data-disabled={disabled}
			className={className}
			active={active}>
			{children}
			<InputStyles.Field
				{...rest}
				ref={ref}
				data-testid={testid}
				disabled={disabled}
				readOnly={readOnly}
				onChange={changeHandler}
				onInput={inputHandler}
				onWheel={onScrollHandler}
				defaultValue={defaultValue}
				value={value}
				theme={theme}
			/>
		</InputStyles.Wrapper>
	);
}

export const Field = withForwardedRef(FieldComponent);

interface AreaProps
	extends Omit<InputHTMLAttributes<HTMLTextAreaElement>, 'onChange'> {
	rounded?: boolean;
	large?: boolean;
	small?: boolean;
	transparent?: boolean;
	theme?: DefaultTheme;

	minRows?: number;
	maxRows?: number;
	lineHeight?: number;
	value?: string;
	defaultValue?: string;
	disabled?: boolean;
	className?: string;
	testid?: string;

	onChange?: InputChangeEvent;
	onTyping?: InputChangeEvent;
	changeDelay?: number;

	// This has the any type because typescript doesn't like forwardedRef.current
	// when we used Ref<HTMLTextAreaElement> type
	forwardedRef?: any;
	children?: ReactNode;
}

function AreaComponent({
	large,
	small,
	transparent,
	theme,

	minRows = 1,
	maxRows = 5,
	lineHeight = 20,
	value,
	defaultValue,
	disabled,
	className,
	testid,

	onChange,
	onTyping,
	changeDelay,

	forwardedRef,
	children,
	...rest
}: AreaProps): JSX.Element {
	const methods = useFormContext();

	const [numRows, setNumRows] = useState<number>(minRows);

	const validationRules =
		rest.name &&
		methods &&
		methods.register(rest.name, {
			required: rest.required,
		});

	const emitChange = useMemo(
		() =>
			debounce((event: ChangeEvent<HTMLTextAreaElement> | SyntheticEvent) => {
				if (validationRules) {
					validationRules.onChange(event);
				}
				onChange(event);
			}, changeDelay),
		[changeDelay, onChange]
	);

	let changeHandler: InputChangeEvent;
	let inputHandler: InputChangeEvent;

	const calcRows = useCallback(
		(target: HTMLTextAreaElement): void => {
			const previousRows = target.rows;

			target.rows = minRows;

			let currentRows = ~~(target.scrollHeight / lineHeight) - 1;

			if (currentRows <= 0) {
				currentRows = 1;
			}

			if (currentRows === previousRows) {
				target.rows = currentRows;
			}

			if (currentRows >= maxRows) {
				target.rows = maxRows;
				target.scrollTop = target.scrollHeight;
			}

			setNumRows(currentRows < maxRows ? currentRows : maxRows);
		},
		[numRows]
	);

	useEffect(() => {
		if (!value) {
			setNumRows(1);
		}
	}, [value]);

	if (onChange && !changeDelay) {
		changeHandler = (event: ChangeEvent<HTMLTextAreaElement>) => {
			if (validationRules) {
				validationRules.onChange(event);
			}

			onChange(event);

			calcRows(event.currentTarget as HTMLTextAreaElement);
		};
	} else if (onChange && changeDelay) {
		inputHandler = (event: SyntheticEvent) => {
			if (onTyping) {
				onTyping(event);
			}

			emitChange(event);

			calcRows(event.currentTarget as HTMLTextAreaElement);
		};
	} else if (!onChange && validationRules) {
		changeHandler = (event: ChangeEvent<HTMLTextAreaElement>) => {
			validationRules.onChange(event);

			calcRows(event.currentTarget as HTMLTextAreaElement);
		};
	}

	if (!changeHandler && !inputHandler) {
		changeHandler = (event: ChangeEvent<HTMLTextAreaElement>) => {
			calcRows(event.currentTarget as HTMLTextAreaElement);
		};
	}

	let ref = forwardedRef;

	if (forwardedRef && validationRules) {
		ref = (e: HTMLTextAreaElement) => {
			validationRules.ref(e);
			forwardedRef.current = e; // you can still assign to ref
		};
	} else if (validationRules) {
		ref = validationRules.ref;
	}

	return (
		<InputStyles.Wrapper
			theme={theme}
			large={large}
			small={small}
			transparent={transparent}
			data-disabled={disabled}
			data-textarea="true"
			className={className}>
			{children}
			<InputStyles.Area
				{...rest}
				ref={ref}
				data-testid={testid}
				disabled={disabled}
				onChange={changeHandler}
				onInput={inputHandler}
				theme={theme}
				rows={numRows}
				value={value}
				defaultValue={defaultValue}
			/>
		</InputStyles.Wrapper>
	);
}

export const Area = withForwardedRef(AreaComponent);

interface ColorPickerProps {
	theme?: DefaultTheme;
	name?: string;
	value: string;
	onChange: (value: string) => void;
}

export function ColorPicker({
	theme,
	name,
	value,
	onChange,
}: ColorPickerProps): JSX.Element {
	const iconColor = ColorConverter.from(value).getAccentColor().toHEX();

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		onChange(event.target.value);
	};

	return (
		<InputStyles.ColorPickerWrapper theme={theme} style={{ color: value }}>
			<span style={{ color: iconColor }}>
				{value}
				<Icon name="color-picker" size={1.2} />
			</span>
			<input name={name} type="color" value={value} onChange={handleChange} />
		</InputStyles.ColorPickerWrapper>
	);
}

interface GroupProps {
	label?: string;
	description?: string;
	hint?: string;
	hasError?: boolean;
	errorMessage?: string;
	optional?: boolean;
	required?: boolean;
	disabled?: boolean;
	readOnly?: boolean;
	justify?: string;

	onClick?: () => void;
	children?: ReactNode;

	className?: string;
	testid?: string;
}

export function Group({
	label,
	description,
	hint,
	hasError,
	errorMessage,
	optional,
	required,
	disabled,
	readOnly,
	justify,

	onClick,
	children,

	className,
	testid,
}: GroupProps): JSX.Element {
	const formContext = useFormContext();

	const hasValidationError =
		formContext &&
		Children.toArray(children).some((child: ReactElement) => {
			if (!child.props.name) {
				return false;
			}

			let errLvl = formContext.formState.errors;

			const nameParts = (child.props.name as string).split('.');
			const inputName = nameParts.pop();

			for (let i = 0; i < nameParts.length; i++) {
				errLvl = errLvl?.[nameParts[i]] as any;
			}

			return errLvl?.[inputName];
		});

	if (disabled) {
		children = Children.map(children, (child: ReactElement) =>
			cloneElement(child, {
				disabled,
			})
		);
	}

	return (
		<InputStyles.Group
			disabled={disabled}
			readOnly={readOnly}
			onClick={onClick}
			className={className}
			hasError={hasError || hasValidationError || !!errorMessage}
			justify={justify}
			data-testid={testid}>
			{label && (
				<InputStyles.Label>
					{label}
					{optional && <span>{t('Optional')}</span>}
					{required && <span>{t('Required')}</span>}
				</InputStyles.Label>
			)}

			{description && (
				<InputStyles.Description>
					<FormattedContent raw={description} allowedFormatters={['links']} />
				</InputStyles.Description>
			)}
			{children}
			{errorMessage && <InputStyles.Error>{errorMessage}</InputStyles.Error>}
			{hint && <InputStyles.Hint>{hint}</InputStyles.Hint>}
		</InputStyles.Group>
	);
}

interface ControlProps extends Omit<GroupProps, 'onClick'> {
	type: 'toggle' | 'radio' | 'checkbox';
	position?: 'before' | 'after';
	checked?: boolean;
	defaultChecked?: boolean;
	color?: InputStyles.ControlColor;
	standalone?: boolean;
	rounded?: boolean;
	large?: boolean;

	name?: string;
	value?: string | number | readonly string[];

	onChange?: (
		value: string | number | readonly string[],
		target?: HTMLInputElement,
		event?: ChangeEvent<HTMLInputElement>
	) => void;
	// Callback for custom validation
	validate?: (
		value: string | number
	) => string | string[] | boolean | undefined;

	className?: string;
	testid?: string;
	required?: boolean;

	noRequiredText?: boolean;
}

export function Control({
	type,
	label,
	description,
	optional,
	required,
	disabled,
	position = 'before',
	checked,
	defaultChecked,
	color = 'blue',
	standalone,
	rounded,
	large,

	name,
	value,
	onChange,
	children,
	className,
	testid,
	validate,

	noRequiredText,

	...rest
}: ControlProps): JSX.Element {
	const methods = useFormContext();

	const validationRules =
		!onChange &&
		name &&
		methods &&
		methods.register(name, {
			required: required,
			validate,
		});

	const inputRef = useRef<HTMLInputElement>();

	let control;

	switch (type) {
		case 'toggle':
			control = <Toggle active={checked} data-testid={testid} />;
			break;
		case 'radio':
			control = (
				<InputStyles.Control
					color={color}
					large={large}
					rounded
					data-checked={checked}
					checked={checked}
					data-testid={testid}>
					<svg viewBox="0 0 14 14">
						<circle cx="7" cy="7" r="4" fill="currentColor" />
					</svg>
				</InputStyles.Control>
			);
			break;
		case 'checkbox':
			control = (
				<InputStyles.Control
					color={color}
					large={large}
					rounded={rounded}
					data-checked={checked}
					checked={checked}
					data-testid={testid}>
					<Icon name="check" size={1.5} />
				</InputStyles.Control>
			);
			break;
	}

	const handleClick = () => {
		inputRef.current?.click();
	};

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (onChange) {
			onChange(value, inputRef.current, e);
		} else if (validationRules) {
			validationRules.onChange(e);
		}
	};

	const ref = (e: HTMLInputElement) => {
		if (validationRules) {
			validationRules.ref(e);
		}
		inputRef.current = e;
	};

	const controlValue = (
		<input
			{...rest}
			ref={ref}
			type={type === 'radio' ? 'radio' : 'checkbox'}
			name={name}
			value={value}
			defaultChecked={defaultChecked}
			checked={checked}
			onChange={handleChange}
			onClick={(e) => {
				e.stopPropagation();
			}}
		/>
	);

	if (standalone) {
		const standaloneControl = (
			<Fragment>
				{controlValue}
				{control}
			</Fragment>
		);

		return (
			<InputStyles.ControlGroup
				standalone
				color={color}
				className={className}
				onClick={handleClick}
				disabled={disabled}>
				{standaloneControl}
			</InputStyles.ControlGroup>
		);
	}

	return (
		<InputStyles.ControlGroup
			color={color}
			position={position}
			onClick={handleClick}
			className={className}
			disabled={disabled}>
			{controlValue}
			{position === 'before' && control}
			<div>
				<InputStyles.Label>
					{children || label}
					{optional && <span>{t('Optional')}</span>}
					{required && !noRequiredText && <span>{t('Required')}</span>}
				</InputStyles.Label>
				{description && (
					<InputStyles.Description>{description}</InputStyles.Description>
				)}
			</div>
			{position === 'after' && control}
		</InputStyles.ControlGroup>
	);
}

interface DateTimePickerProps {
	hideTimeInput?: boolean;
	onTimeChange?: (date: Date) => void;
	onDateChange?: (date: Date) => void;
	onRenderedDateChange?: (date: Date) => void;
	onTimeBlur?: () => void;
	date?: Date;
	disableDatesBefore?: Date;
	placeholder?: string;
	hideValue?: boolean;

	highlightedDates?: Date[][];
}

export const DateTimePicker = ({
	hideTimeInput = false,
	onTimeChange,
	onDateChange,
	onRenderedDateChange,
	onTimeBlur,
	date = new Date(),
	disableDatesBefore,
	placeholder,
	hideValue,
	highlightedDates,
}: DateTimePickerProps): JSX.Element => {
	const [showCalendar, setShowCalendar] = useState(false);
	const [isNavigating, setIsNavigating] = useState(false);
	const dateTime = new DateTime(date);
	const timePickerValue = [date.getHours(), date.getMinutes()]
		.map((p) => p.toString().padStart(2, '0'))
		.join(':');
	const dateRef = useRef(null);

	const handleFocus = () => {
		setShowCalendar(true);
	};

	const timeChange = (event: ChangeEvent<HTMLInputElement>) => {
		const nextDate = date;
		const [h, m] = event.target.value
			.split(':')
			.map((p) => Number.parseInt(p, 10));
		nextDate.setHours(h ? h : 0, m ? m : 0, 0);

		onTimeChange(nextDate);
	};

	const calendarChange = (dates: Date[]) => {
		const newDate = dates[0].setHours(date.getHours(), date.getMinutes());
		onDateChange(new Date(newDate));
		closeCalendar();
	};

	const closeCalendar = () => {
		if (document.activeElement === dateRef.current) {
			dateRef.current.blur();
		}

		setShowCalendar(false);
	};

	const handleBlur = () => {
		if (isNavigating) {
			setIsNavigating(false);
			dateRef.current.focus();
			return;
		} else {
			closeCalendar();
		}
	};

	const handleRenderedDateChange = (date: Date) => {
		if (onRenderedDateChange) {
			onRenderedDateChange(date);
		}
	};

	return (
		<Fragment>
			<InputStyles.DateInputsWrapper singleInput={hideTimeInput}>
				<Field
					value={
						hideValue
							? ''
							: dateTime.toLocaleDateString({
									day: 'numeric',
									month: 'short',
									year: 'numeric',
								})
					}
					readOnly
					onFocus={handleFocus}
					onBlur={handleBlur}
					ref={dateRef}
					placeholder={placeholder}
				/>
				{!hideTimeInput && (
					<Field
						placeholder={t(`Select time`)}
						onChange={timeChange}
						onBlur={onTimeBlur}
						value={timePickerValue}
						type="time"
					/>
				)}
			</InputStyles.DateInputsWrapper>
			{showCalendar && (
				<ContextMenu.Menu
					toggleWith={<span></span>}
					defaultOpen={true}
					appearFrom={ContextMenu.AppearFrom.TopLeft}
					onClose={closeCalendar}>
					<ContextMenu.Pane padding="var(--spacing-5) 0">
						<Calendar
							selectedDates={[date]}
							onDateChange={calendarChange}
							disableDatesBefore={disableDatesBefore}
							setIsNavigating={setIsNavigating}
							onRenderedDateChange={handleRenderedDateChange}
							highlightedDates={highlightedDates}
						/>
					</ContextMenu.Pane>
				</ContextMenu.Menu>
			)}
		</Fragment>
	);
};

interface SearchableListProps {
	items: string[];
	value: string;
	onChange: (value: string | number) => void;
	onBlur?: (value: string) => void;
	onClick?: () => void;
}

export const SearchableList = forwardRef<HTMLInputElement, SearchableListProps>(
	({ items, value, onChange, onBlur, onClick }, ref) => {
		const listId = useUuid();

		const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
			onChange(event.target.value);

		const handleBlur = (event: ChangeEvent<HTMLInputElement>) =>
			onBlur(event.target.value);

		return (
			<InputStyles.SearchContainer>
				<Field
					value={value}
					onChange={handleChange}
					onBlur={handleBlur}
					onClick={onClick}
					list={listId}
					ref={ref}>
					<Prefix>
						<Icon name="search" />
					</Prefix>
				</Field>

				<datalist id={listId}>
					{items.map((item, index) => (
						<option key={index} value={item} />
					))}
				</datalist>
			</InputStyles.SearchContainer>
		);
	}
);

interface SelectProps
	extends Omit<SelectHTMLAttributes<HTMLSelectElement>, 'onChange'> {
	name?: string;
	children: ReactNode;

	large?: boolean;
	small?: boolean;
	transparent?: boolean;
	theme?: DefaultTheme;

	value?: string | number;
	defaultValue?: string | number;
	disabled?: boolean;

	onChange?: InputChangeEvent;
	validate?: (
		value: string | number
	) => string | string[] | boolean | undefined;

	// This has the any type because typescript doesn't like forwardedRef.current
	// when we used Ref<HTMLlInputElement> type
	forwardedRef?: any;
	// used in e2e frontend tests
	testid?: string;
}

export const Select = withForwardedRef(
	({
		name,
		children,
		small,
		validate,
		onChange,
		forwardedRef,
		testid,
		className,
		disabled,
		...rest
	}: SelectProps): JSX.Element => {
		const methods = useFormContext();

		const validationRules =
			name &&
			methods &&
			methods.register(name, {
				required: rest.required,
				validate,
			});

		let changeHandler: InputChangeEvent;
		if (onChange) {
			changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
				if (validationRules) {
					validationRules.onChange(event);
				}

				onChange(event);
			};
		} else if (!onChange && validationRules) {
			changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
				validationRules.onChange(event);
			};
		}

		let ref = forwardedRef;
		if (forwardedRef && validationRules) {
			ref = (e: HTMLInputElement) => {
				validationRules.ref(e);
				forwardedRef.current = e; // you can still assign to ref
			};
		} else if (validationRules) {
			ref = validationRules.ref;
		}

		return (
			<InputStyles.Wrapper small={small} className={className}>
				<InputStyles.SelectIconWrapper>
					<Icon name="chevron" />
					<Icon name="chevron" />
				</InputStyles.SelectIconWrapper>
				<InputStyles.SelectInput
					name={name}
					ref={ref}
					small={small}
					data-testid={testid}
					onChange={changeHandler}
					disabled={disabled}
					{...rest}>
					{children}
				</InputStyles.SelectInput>
			</InputStyles.Wrapper>
		);
	}
);
