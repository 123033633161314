import { Fragment } from 'react';
import { t } from '@transifex/native';

import { Field } from 'pkg/models/form';

import { FormSubmission } from 'pkg/api/models/form_submission';
import DateTime from 'pkg/datetime';
import * as routes from 'pkg/router/routes';
import * as models from 'pkg/api/models';
import { ISO3166Alpha2Codes } from 'pkg/countries';
import { useCurrentMembership, useCurrentOrganization } from 'pkg/identity';
import { UserSex, translatedSexString } from 'pkg/api/models/user';
import { newChatParams } from 'pkg/chat';
import { link } from 'pkg/router/utils';

import Icon from 'components/icon';
import Avatar from 'components/avatar';

import CheckBox from 'components/form/CheckBox';
import OrderStatusLabel from 'components/payment_platform/status/label';
import Row from 'components/layout/row';

import * as Table from 'design/table';
import * as ContextMenu from 'design/context_menu';

interface ListRowProps {
	groupId: number;
	submission: FormSubmission;
	formFields: Field[];
	isSelected: boolean;
	onSelect: (submittedForUserId: number) => void;
	handleOpenProductModal?: (userId: number) => void;
	onAddUserToGroup?: (userId: number) => void;
	showSubmittedForColumn?: models.formSubmission.FormSubmission;
	showSubmittedByColumn?: models.formSubmission.FormSubmission;
	showStatusColumn?: models.formSubmission.FormSubmission;
	removeRecord: (id: number) => void;
}

const ListRow: React.FC<React.PropsWithChildren<ListRowProps>> = ({
	groupId,
	submission,
	formFields,
	isSelected,
	onSelect,
	handleOpenProductModal,
	onAddUserToGroup,
	showSubmittedByColumn,
	showSubmittedForColumn,
	showStatusColumn,
	removeRecord,
}) => {
	const org = useCurrentOrganization();

	const handleSelect = () => onSelect(submission.id);
	const handleAssignProduct = () => {
		handleOpenProductModal(submission.submittedForUserId);
	};

	const createdAtAsDateTime = DateTime.fromTimestamp(submission.createdAt);

	const submittedForContactLink = routes.Management.Contact.Show(
		org.id,
		submission.submittedForUserId,
		'overview'
	);
	const currentMembership = useCurrentMembership();
	const handleAddUserToGroup = () =>
		onAddUserToGroup(submission.submittedForUserId);

	const canChat =
		submission?.submittedForUserId &&
		submission?.submittedForUser?.accountId &&
		submission?.submittedForUserId !== currentMembership.user?.id;

	const submittedForUserCell = (
		<Table.LinkCell
			href={routes.Management.Contact.Show(
				org.id,
				submission.submittedForUserId,
				'overview'
			)}>
			<Row align="center" autoColumns="max-content">
				{submission.submittedForUser && (
					<Fragment>
						<Avatar user={submission.submittedForUser} size={25} />
						{models.user.fullName(submission.submittedForUser)}
					</Fragment>
				)}
			</Row>
		</Table.LinkCell>
	);

	const submittedByUserCell = (
		<Table.LinkCell
			href={routes.Management.Contact.Show(
				org.id,
				submission.submittedByUserId,
				'overview'
			)}>
			<Row align="center" autoColumns="max-content">
				{submission.submittedByUser && (
					<Fragment>
						<Avatar user={submission.submittedByUser} size={25} />
						{models.user.fullName(submission.submittedByUser)}
					</Fragment>
				)}
			</Row>
		</Table.LinkCell>
	);

	const handleDelete = async () => {
		const success = await models.destroy(submission);

		if (success) {
			removeRecord(submission.id);
		}
	};

	return (
		<Table.Row>
			<Table.Cell>
				<CheckBox
					id={submission.id.toString()}
					selected={isSelected}
					disabled={false}
					onClick={handleSelect}
				/>
			</Table.Cell>
			{showSubmittedForColumn &&
				submission.submittedForUser &&
				submittedForUserCell}
			{showSubmittedForColumn && !submission.submittedForUser && <Table.Cell />}

			{showSubmittedByColumn &&
				submission.submittedByUser &&
				submittedByUserCell}
			{showSubmittedByColumn && !submission.submittedByUser && <Table.Cell />}

			{showStatusColumn && (
				<Table.Cell>
					{submission.order && (
						<OrderStatusLabel
							status={models.order.getStatus(submission.order)}
						/>
					)}
				</Table.Cell>
			)}
			{getSortedSubmissionData(formFields, submission).map(
				(data: { key: string; value: string | number }, index: number) => {
					const field = formFields.find((field) => field.key === data.key);

					// if a new field is added after a submission, the field may be undefined
					if (!field) {
						return (
							<Table.LinkCell
								key={`empty${index}`}
								href={routes.Form.Submissions.Single(
									org.id,
									groupId,
									submission.formId,
									submission.id
								)}>
								-
							</Table.LinkCell>
						);
					}

					// Show user_sex as translated string instead of the numeric value
					if (data.key === 'user_sex') {
						data.value = translatedSexString(
							Number.parseInt(data.value as string, 10) as UserSex
						);
					}

					if (data.key === 'user_country') {
						const countryCode = data.value as string;

						data.value = ISO3166Alpha2Codes().find(
							(c) => c.code === countryCode.toLowerCase()
						)?.country;
					}

					const isChoice =
						data.key !== 'user_sex' &&
						(field.type === models.form.FieldTypes.Choice ||
							field.type === models.form.FieldTypes.MultiChoice);

					return (
						<Table.LinkCell
							key={`${data.key}${index}`}
							href={routes.Form.Submissions.Single(
								org.id,
								groupId,
								submission.formId,
								submission.id
							)}>
							{isChoice
								? models.form.getFormatedChoices(
										data.value as string,
										field.values
									)
								: data.value || '-'}
						</Table.LinkCell>
					);
				}
			)}
			<Table.LinkCell
				key="createdAt"
				align="left"
				href={routes.Form.Submissions.Single(
					org.id,
					groupId,
					submission.formId,
					submission.id
				)}>
				{createdAtAsDateTime.toLocaleDateString()}
			</Table.LinkCell>
			<Table.ActionCell>
				<ContextMenu.Menu
					toggleWith={
						<ContextMenu.TableTrigger>
							<Icon name="context-menu" />
						</ContextMenu.TableTrigger>
					}>
					{submission.submittedForUser && (
						<Fragment>
							<ContextMenu.LinkItem
								href={submittedForContactLink}
								icon="arrow_forward">
								{t('View user')}
							</ContextMenu.LinkItem>
							<ContextMenu.LinkItem
								href={
									routes.Invoice.New(org.id) +
									'?userIds=' +
									submission.submittedForUserId
								}
								icon="add">
								{t('New order')}
							</ContextMenu.LinkItem>
							<ContextMenu.Item onClick={handleAssignProduct} icon="tag">
								{t('Assign product')}
							</ContextMenu.Item>

							{canChat && (
								<ContextMenu.LinkItem
									href={link(
										routes.Chat.New(org.id),
										newChatParams([submission.submittedForUser])
									)}
									icon="comment">
									{t('Chat')}
								</ContextMenu.LinkItem>
							)}

							{submission?.submittedForUser?.email &&
								submission.submittedForUserId !==
									currentMembership.user?.id && (
									<ContextMenu.LinkItem
										href={`mailto:${submission.submittedForUser.email}`}
										icon="mail">
										{t('Send Email')}
									</ContextMenu.LinkItem>
								)}

							{submission.submittedByUserId !== submission.submittedForUserId &&
								submission.submittedByUserId !== currentMembership.user?.id && (
									<ContextMenu.LinkItem
										href={`mailto:${submission.submittedByUser.email}`}
										icon="mail">
										{t('Send email to billing address')}
									</ContextMenu.LinkItem>
								)}
							<ContextMenu.LinkItem
								onClick={handleAddUserToGroup}
								icon="groups">
								{t('Add to group')}
							</ContextMenu.LinkItem>
							<ContextMenu.Divider />
						</Fragment>
					)}

					<ContextMenu.ConfirmItem
						caution
						icon="delete"
						message={t('Are you sure you wish to delete this submission?')}
						onConfirm={handleDelete}>
						{t('Delete')}
					</ContextMenu.ConfirmItem>
				</ContextMenu.Menu>
			</Table.ActionCell>
		</Table.Row>
	);
};

const getSortedSubmissionData = (
	formFields: Field[],
	submission: FormSubmission
) => {
	const sortedArray: Array<{ key: string; value: string | number }> = Array(
		formFields.length
	).fill({ key: '', value: '' });

	// sort fields by the sortOrder prop
	const sortedFields = formFields.sort((a, b) => {
		if (a.sortOrder === 0 && b.sortOrder !== 0) {
			return -1;
		}

		if (a.sortOrder !== 0 && b.sortOrder === 0) {
			return 1;
		}

		return a.sortOrder - b.sortOrder;
	});

	// find the (form) field with the same key as the item in submission.data and add the data to that index in the new array
	if (submission.data) {
		for (const [key, value] of Object.entries(submission.data)) {
			sortedArray[
				sortedFields.indexOf(sortedFields.find((field) => field.key === key))
			] = { key, value } as { key: string; value: string | number };
		}
	}

	return sortedArray;
};

export default ListRow;
