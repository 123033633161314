import { ReactNode } from 'react';

export const symbol_hdr_plus_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-820q-51 0-94.5 13T306-773q-11 7-23.5 5T262-781q-8-11-5.5-23t13.5-19q48-29 101.5-43T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 60-14.5 111.5T824-272q-7 11-19.5 14t-23.5-5q-11-7-13.5-19.5T772-306q23-40 35.5-82.5T820-480q0-142-99-241t-241-99Zm-9 255-45-45v-97q0-9 7-15.5t16-6.5q9 0 15.5 6.5T471-707v142Zm238-119v150q0 19-13 32t-32 13H547l-14-14v-205q0-9 6.5-15t15.5-6h109q19 0 32 13t13 32ZM578-534h86v-150h-86v150ZM429-317v-65H329v65h100Zm92-202ZM329-272v65q0 9-6.5 15.5T307-185q-9 0-16-7t-7-16v-196q0-9 6.5-15t15.5-6h125q18 0 30.5 12.5T474-382v67q0 17-11.5 29T434-272l23 60q4 10-2 18.5t-17 8.5q-6 0-11.5-3.5T419-198l-28-74h-62Zm-45-353 45 45v73q0 9-6.5 15.5T307-485q-9 0-16-7t-7-16v-117Zm155 188ZM173-736l44 44q-35 44-56 100.5T140-479q0 142 99.5 240.5T481-140q66 0 121-23t92-52l43 43q-55 45-120 68.5T480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-75 24.5-141.5T173-736ZM859-50q-9 9-21.5 9T816-50L49-816q-9-9-9-21.5t9-21.5q9-9 21.5-9t21.5 9L859-92q9 9 9 21t-9 21Z"
		/>
	</svg>
);
