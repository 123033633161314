import * as models from 'pkg/api/models';

import {
	ConflictingItems,
	conflictingTimes,
	ConflictMap,
} from 'routes/scheduling/utils';

/**
 * Goes through two bookings and checks wether they have any conflicts
 */
export function findConflicts(
	booking: models.booking.Booking,
	innerBooking: models.booking.Booking,
	conflictMap: ConflictMap
) {
	const bookingTimes: [number, number] = [booking.startsAt, booking.endsAt];
	let hasSameGroup = false;
	let hasRelatedResource = false;

	if (booking.forGroupId === innerBooking.forGroupId) {
		hasSameGroup = true;
	}

	if (booking.resourceId && innerBooking.resourceId) {
		if (conflictMap[booking.resourceId]?.has(innerBooking.resourceId)) {
			hasRelatedResource = true;
		}
	}

	if (booking.resources?.length > 0 && innerBooking.resources?.length > 0) {
		booking.resources.forEach((br) => {
			innerBooking.resources.forEach((ibr) => {
				if (conflictMap[br.resourceId]?.has(ibr.resourceId)) {
					hasRelatedResource = true;
				}
			});
		});
	}

	if (!hasSameGroup && !hasRelatedResource) {
		return false;
	}

	// can't collide with self
	if (innerBooking.id === booking.id) {
		return false;
	}

	const innerBookingTimes: [number, number] = [
		innerBooking.startsAt,
		innerBooking.endsAt,
	];

	const hasConflictingTimes = conflictingTimes(innerBookingTimes, bookingTimes);

	if (!hasConflictingTimes) {
		return false;
	}

	return true;
}

/**
 * Populates the conflicts object with the booking groupConflicts and resourceConflicts
 */
export function populateConflicts(
	conflicts: ConflictingItems,
	booking: models.booking.Booking,
	innerBooking: models.booking.Booking,
	conflictMap: ConflictMap
) {
	// If the booking doesn't exist in the conflicts object we create a "empty" item
	if (!conflicts[booking.id]) {
		conflicts[booking.id] = {
			groupConflicts: [],
			resourceConflicts: [],
		};
	}

	// If bookings is created for the same group they conflict
	if (booking.forGroupId === innerBooking.forGroupId) {
		conflicts[booking.id].groupConflicts.push(innerBooking.id);
	}

	// Go through each of the bookings "main" resource and check if the resource "overlap" in the
	// conflictMap
	if (booking.resourceId && innerBooking.resourceId) {
		if (conflictMap[booking.resourceId]?.has(innerBooking.resourceId)) {
			conflicts[booking.id].resourceConflicts.push(innerBooking.resourceId);
		}
	}

	// Go through each of the bookings resources and check if the resources "overlap" in the
	// conflictMap
	if (booking.resources?.length > 0 && innerBooking.resources?.length > 0) {
		booking.resources.forEach((br) => {
			innerBooking.resources.forEach((ibr) => {
				if (conflictMap[br.resourceId]?.has(ibr.resourceId)) {
					conflicts[booking.id].resourceConflicts.push(ibr.id);
				}
			});
		});
	}
}
