import {
	ChangeEvent,
	Fragment,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import { eventTypeLabels, eventTypeToTranslatedString } from 'pkg/models/event';

import * as actions from 'pkg/actions';
import { validateTimeZone } from 'pkg/timezone';
import DateTime, { Granularity } from 'pkg/datetime';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { useGroup } from 'pkg/hooks/selectors';
import store from 'pkg/store/createStore';
import { isApp } from 'pkg/platform';
import { useCurrentGroup } from 'pkg/identity';
import { EventFlags, RSVPInterval } from 'pkg/api/models/event';

import RecurringSettings from 'routes/event/components/RecurringSettings';
import { DateSelectWrapper, TimeZoneText } from 'routes/event/components/Form';
import MeetingTime from 'routes/event/components/meeting-time';
import { EventFormContext } from 'routes/event/create';
import RSVPInputs from 'routes/event/components/rsvp/form-inputs';
import AutoInvite from 'routes/event/components/auto-invite';
import HideParticipants from 'routes/event/components/hide-participants';

import Icon from 'components/icon';

import TimeZoneSearch from 'components/form/TimeZoneSelect';
import * as Input from 'components/form/inputs';
import { UseAttachmentsResults } from 'components/attachment/hooks/useAttachments';
import Column from 'components/layout/column';
import { SelectEventType } from 'components/event/SelectType';

interface EventInformationProps {
	useAttachments: UseAttachmentsResults;
	edit?: boolean;
}

const EventInformation = ({
	useAttachments,
	edit = false,
}: EventInformationProps): JSX.Element => {
	const EventContext = useContext(EventFormContext);

	const [showTimeZone, setShowTimeZone] = useState(false);
	const startsAtDate = new Date(EventContext.formState.startsAt * 1000);
	const startsAtDateTime = new DateTime(startsAtDate);

	const titleRef = useRef(null);

	const currentGroup = useCurrentGroup();
	const group = useGroup(EventContext.formState.groupId);

	useEffect(() => {
		if (!edit) {
			actions.groups.fetchChildGroups(currentGroup.id, { recursive: true })(
				store.dispatch
			);
		}
	}, [edit, currentGroup.id]);

	useComponentDidMount(() => {
		if (!isApp()) {
			titleRef.current.focus();
		}

		if (EventContext.formState.timezone === '') {
			setShowTimeZone(true);
		}
	});

	const handleFormChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		if ((name === 'title' || name === 'location') && value !== '') {
			EventContext.setFormState({
				[name]: value,
				errors: { ...EventContext.formState.errors, [name]: false },
			});
			return;
		}

		EventContext.setFormState({ [name]: value });
	};

	const handleEventSelect = (event: ChangeEvent<HTMLSelectElement>) => {
		let physicalStrainDefault = EventContext.formState.physicalStrainDefault;

		if (event.target.value === 'match') {
			physicalStrainDefault = 80;
		} else if (event.target.value === 'practice') {
			physicalStrainDefault = 50;
		}

		if (event.target.value !== '' && EventContext.formState.errors.type) {
			EventContext.setFormState({
				type: event.target.value,
				errors: { ...EventContext.formState.errors, type: false },
				physicalStrainDefault,
			});

			return;
		}

		EventContext.setFormState({
			type: event.target.value,
			physicalStrainDefault,
		});
	};

	const handleTimeZoneChange = (timeZone: string) =>
		EventContext.formState.errors.timezone
			? EventContext.setFormState({
					timezone: timeZone,
					errors: {
						...EventContext.formState.errors,
						timezone: timeZone.length > 0 && !validateTimeZone(timeZone),
					},
				})
			: EventContext.setFormState({
					timezone: timeZone,
				});

	const handleStartTimeChange = (date: Date) => {
		const startsAt = new DateTime(date).getUnixTimestamp();
		const eventDuration =
			EventContext.formState.endsAt - EventContext.formState.startsAt;

		const matchDate = new Date(EventContext.formState.match.startsAt * 1000);
		const nextMatchDate = new Date(startsAt * 1000);
		nextMatchDate.setHours(matchDate.getHours(), matchDate.getMinutes(), 0);
		const newMatchData = {
			...EventContext.formState.match,
			startsAt: new DateTime(nextMatchDate).getUnixTimestamp(),
		};

		EventContext.setFormState({
			startsAt,
			endsAt: Math.ceil(startsAt + eventDuration),
			match: newMatchData,
		});
	};

	const handleEndTimeChange = (date: Date) => {
		const endsAt = new DateTime(date).getUnixTimestamp();
		EventContext.setFormState({ endsAt });
	};

	const handleEndTimeBlur = () => {
		if (EventContext.formState.endsAt < EventContext.formState.startsAt) {
			EventContext.setFormState({
				endsAt: new DateTime(new Date(EventContext.formState.startsAt * 1000))
					.next(Granularity.hour, 1)
					.getUnixTimestamp(),
			});
		}
	};

	const handleShowTimeZone = async () => {
		setShowTimeZone(true);
	};

	const handleTimeZoneBlur = () => {
		if (
			EventContext.formState.timezone.length > 0 &&
			!validateTimeZone(EventContext.formState.timezone)
		) {
			EventContext.setFormState({
				errors: {
					...EventContext.formState.errors,
					timezone: true,
				},
			});

			return;
		}

		setShowTimeZone(false);
	};

	const setRuleString = (rRuleString: string) => {
		EventContext.setFormState({
			rRuleString,
		});
	};

	const setOnDay = (onDay: boolean) => EventContext.setFormState({ onDay });

	const handleRSVPChange = () =>
		EventContext.setFormState({
			rsvpBefore: !EventContext.formState.rsvpBefore,
		});

	const handleIntervalChange = (e: ChangeEvent<HTMLSelectElement>) =>
		EventContext.setFormState({ rsvpInterval: e.target.value as RSVPInterval });

	const handleIntervalCountChange = (e: ChangeEvent<HTMLInputElement>) => {
		EventContext.setFormState({
			[e.target.name]: Number.parseInt(e.target.value, 10),
		});
	};

	const handleAutoInviteChange = (name: EventFlags) => {
		const flags = EventContext.formState.flags;

		if (flags.includes(name)) {
			EventContext.setFormState({ flags: flags.filter((f) => f !== name) });
		} else {
			EventContext.setFormState({
				flags: [...EventContext.formState.flags, name],
			});
		}
	};

	const handleHideParticipantsChange = () =>
		EventContext.setFormState({
			hideParticipants: !EventContext.formState.hideParticipants,
		});

	return (
		<Fragment>
			{!edit && (
				<Column spacing={styles.spacing._3}>
					<Input.Group
						hasError={EventContext.formState.errors.type}
						label={t('Event type')}>
						<SelectEventType
							name="eventType"
							value={EventContext.formState.type}
							onChange={handleEventSelect}
							eventType={EventContext.formState.type}
							testid="events.form.event_type.select">
							<option value="">{t('Select event type')}</option>
							{eventTypeLabels.map((eventType) => (
								<option
									key={eventType}
									value={eventType}
									data-testid={`events.type.${eventType}`}>
									{eventTypeToTranslatedString(eventType)}
								</option>
							))}
						</SelectEventType>
					</Input.Group>
				</Column>
			)}
			<Input.Group
				required
				label={t('Event title')}
				errorMessage={
					EventContext.formState.errors.title &&
					t('"{field}" is required.', {
						field: t('Event title'),
					})
				}>
				<Input.Field
					name="title"
					placeholder={t('Event title')}
					defaultValue={EventContext.formState.title}
					onChange={handleFormChange}
					autoComplete="off"
					ref={titleRef}
					changeDelay={100}
					testid="events.form.title"
					maxLength={255}
				/>
			</Input.Group>
			<Input.Group
				required
				label={t('Location')}
				errorMessage={
					EventContext.formState.errors.location &&
					t('"{field}" is required.', {
						field: t('Location'),
					})
				}>
				<Input.Field
					name="location"
					placeholder={t('Location')}
					defaultValue={EventContext.formState.location}
					onChange={handleFormChange}
					autoComplete="off"
					changeDelay={100}
					maxLength={255}
					testid="events.form.location">
					<Input.Prefix>
						<Icon name="location" />
					</Input.Prefix>
				</Input.Field>
			</Input.Group>
			<Column spacing={styles.spacing._3} justify="stretch">
				<DateSelectWrapper>
					<Input.Group label={t('Start time')}>
						<div>
							<Input.DateTimePicker
								date={startsAtDate}
								onTimeChange={handleStartTimeChange}
								onDateChange={handleStartTimeChange}
							/>
						</div>
					</Input.Group>
					<Icon name="arrow-right" />
					<Input.Group label={t('End time')}>
						<div>
							<Input.DateTimePicker
								date={new Date(EventContext.formState.endsAt * 1000)}
								onTimeChange={handleEndTimeChange}
								onDateChange={handleEndTimeChange}
								onTimeBlur={handleEndTimeBlur}
								disableDatesBefore={startsAtDate}
							/>
						</div>
					</Input.Group>
				</DateSelectWrapper>
				{!showTimeZone && (
					<TimeZoneText onClick={handleShowTimeZone}>
						{EventContext.formState.timezone || t('Select time zone')}
					</TimeZoneText>
				)}
			</Column>

			{showTimeZone && (
				<Input.Group
					label={t('Time zone')}
					required
					errorMessage={
						EventContext.formState.errors.timezone && t('Incorrect time zone')
					}>
					<TimeZoneSearch
						timezone={EventContext.formState.timezone}
						onSelect={handleTimeZoneChange}
						onBlur={handleTimeZoneBlur}
					/>
				</Input.Group>
			)}
			<MeetingTime
				starts={startsAtDateTime}
				meetBeforeMinutes={EventContext.formState.meetBeforeMinutes}
				handleFormChange={handleFormChange}
			/>
			<RSVPInputs
				rsvpBefore={EventContext.formState.rsvpBefore}
				interval={EventContext.formState.rsvpInterval}
				intervalCount={EventContext.formState.rsvpIntervalCount}
				starts={EventContext.formState.startsAt}
				hasError={EventContext.formState.errors.rsvpBefore}
				handleIntervalChange={handleIntervalChange}
				handleIntervalCountChange={handleIntervalCountChange}
				handleRSVPChange={handleRSVPChange}
			/>
			{EventContext.formState.hasOwnProperty('rRuleString') && !edit && (
				<RecurringSettings
					startsAt={EventContext.formState.startsAt}
					rruleString={EventContext.formState.rRuleString}
					onDay={EventContext.formState.onDay}
					setOnDay={setOnDay}
					setRuleString={setRuleString}
				/>
			)}

			<AutoInvite
				flags={EventContext.formState.flags}
				handleChange={handleAutoInviteChange}
			/>

			<HideParticipants
				value={EventContext.formState.hideParticipants}
				handleHideParticipantsChange={handleHideParticipantsChange}
			/>

			<Input.Group optional label={t('Description')}>
				<Input.Area
					name="description"
					defaultValue={EventContext.formState.description}
					onChange={handleFormChange}
					minRows={3}
					changeDelay={100}
					testid="events.form.description"
				/>
			</Input.Group>
			<Column>
				<Input.Group label={t('Visibility')}>
					<Input.Select
						value={EventContext.formState.isPrivate ? 'private' : 'public'}
						onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
							const isPrivate = e.target.value === 'private';
							EventContext.setFormState({ isPrivate });
						}}>
						<option value="public">
							{t('Visible to everyone in {group}', {
								group: group.name,
							})}
						</option>
						<option value="private">{t('Only visible to participants')}</option>
					</Input.Select>
					<Input.Select
						value={EventContext.formState.isPublic ? 'public' : 'notPublic'}
						onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
							const isPublic = e.target.value === 'public';
							EventContext.setFormState({ isPublic });
						}}>
						<option value="public">{t('Show on website')}</option>
						<option value="notPublic">{t('Do not show on website')}</option>
					</Input.Select>
				</Input.Group>
			</Column>
			<Column>
				<div>{useAttachments.FileUploader}</div>
				{useAttachments.AttachmentItems}
			</Column>
		</Fragment>
	);
};

export default EventInformation;
