import EventSeriesUser from 'pkg/models/event_series_user';
import EventUser from 'pkg/models/event_user';

import { normalizedDispatch } from 'pkg/actions/utils';
import * as eventSeriesUsersServices from 'pkg/actions/services/event_series_users';

import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import store from 'pkg/store/createStore';
import * as sdk from 'pkg/core/sdk';

export interface IAddUsersPayload {
	userIDs: number[];
	sendInvites: boolean;
	organizer: {
		[key: number]: boolean;
	};
}

export const add = async (id: number, payload: IAddUsersPayload) => {
	const request = await eventSeriesUsersServices.add(id, payload);
	const response = await request.json();

	if (!response) {
		return;
	}

	normalizedDispatch(response, [EventSeriesUser.normalizr()])(store.dispatch);
};

export interface UpdateEventSeriesUserPayload {
	userIDs: number[];
	status: models.eventSeriesUser.EventSeriesUserResponseStatus;
	role?: models.eventSeriesUser.EventSeriesUserRole;
}

export async function update(
	eventSeriesId: number,
	payload: UpdateEventSeriesUserPayload
): Promise<[boolean, models.eventSeriesUser.EventSeriesUser[]]> {
	const req = await sdk.patch(
		endpoints.EventSeries.UpdateUsers(eventSeriesId),
		{},
		payload
	);

	if (req.ok) {
		const resp = await req.json();

		return [true, resp];
	}

	return [false, null];
}

export interface IUpdatePayload {
	userIDs: number[];
	status?: string;
	eventUsers?: EventUser[];
}

export async function remove(id: number, userIds: number[]) {
	const request = await sdk.destroy(
		endpoints.EventSeries.RemoveUsers(id),
		{},
		{ userIDs: userIds }
	);

	if (request.ok) {
		return true;
	}

	return false;
}
