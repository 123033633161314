import { ReactNode } from 'react';

export const symbol_frame_person_mic: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M524-300Zm-286 60q-15.96 0-26.98-11.02T200-278v-38q0-27 17.5-47.5T260-397q50-26 105-36t111-5q13 1 21.5 10.483t8.5 22.379q0 12.138-9 19.638-9 7.5-21 6.5-60-5-114 8.5T260-328v28h262q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T522-240H238Zm202-240q-51 0-85.5-34.5T320-600q0-50 34.5-85t85.5-35q50 0 85 35t35 85q0 51-35 85.5T440-480Zm-.351-60Q465-540 482.5-557.149t17.5-42.5Q500-625 482.649-642.5t-43-17.5Q414-660 397-642.649t-17 43Q380-574 397.149-557t42.5 17Zm.351-60Zm240 260q28 0 49-17t28-43q2-8 8.5-14t14.5-6q8 0 14 6t4 14q-7 38-33.5 65T700-302v42q0 8-6 14t-14 6q-8 0-14-6t-6-14v-42q-38-6-64.5-33T562-400q-2-8 4-14t14-6q8 0 14.5 6t8.5 14q7 26 28 43t49 17Zm0-40q-21 0-35.5-14.5T630-430v-80q0-21 14.5-35.5T680-560q21 0 35.5 14.5T730-510v80q0 21-14.5 35.5T680-380ZM140-80q-24 0-42-18t-18-42v-142q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-282v142h142q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q312-97 303.375-88.5T282-80H140ZM80-678v-142q0-24 18-42t42-18h142q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T282-820H140v142q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Q97-648 88.5-656.625T80-678ZM820-80H678q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T678-140h142v-142q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T880-282v142q0 24-18 42t-42 18Zm0-598v-142H678q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T678-880h142q24 0 42 18t18 42v142q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T820-678Z"
		/>
	</svg>
);
