import { ReactNode, Fragment, CSSProperties } from 'react';

import * as styles from 'pkg/config/styles';
import { ColorSwatch } from 'pkg/config/palette';

import { cssClasses, cssVarList } from 'pkg/css/utils';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import { FormattedContent } from 'components/formatted-content';
import Icon, { IconName } from 'components/icon';

import * as css from './styles.css';

export type Color = 'blue' | 'green' | 'red' | 'yellow' | 'orange' | 'gray';

interface InfoBoxProps {
	children?: ReactNode;
	text?: string;
	color?: Color;
	colorSwatch?: keyof ColorSwatch;
	icon?: IconName;
	withAction?: boolean;
	style?: CSSProperties;
	title?: string;
	noRounding?: boolean;
	className?: string;
}

const InfoBox = ({
	children,
	text,
	color = 'blue',
	colorSwatch = 200,
	icon = 'info-circle',
	style,
	title,
	noRounding = false,
	className,
}: InfoBoxProps): JSX.Element => {
	const varList = cssVarList({
		backgroundColor: styles.palette.getColorSwatch(color)[colorSwatch],
		darkModeBackgroundColor: styles.palette.getColorSwatch(color)['500'],
		borderColor: styles.palette.getColorSwatch(color)['300'],
		darkModeBorderColor: styles.palette.getColorSwatch(color)['500'],
		linkColor: styles.palette.getColorSwatch(color)['600'],
		linkHoverColor: styles.palette.getColorSwatch(color)['400'],
	});

	const displayedIcon = (
		<Icon
			name={icon}
			className={css.infoBoxIcon}
			fill={styles.palette.getColorSwatch(color)[500]}
		/>
	);

	const setRounding = noRounding ? css.noRounding : '';

	const classes = cssClasses(
		css.wrapper,
		css.rowWrapper,
		setRounding,
		className
	);

	return (
		<Fragment>
			<LargeScreen>
				<div className={classes} style={{ ...varList, ...style }}>
					{displayedIcon}
					<div className={css.container}>
						{title && <div className={css.title}>{title}</div>}
						{text && (
							<FormattedContent raw={text} allowedFormatters={['links']} />
						)}
						{children}
					</div>
				</div>
			</LargeScreen>
			<SmallScreen>
				<div className={classes} style={{ ...varList, ...style }}>
					{displayedIcon}
					<div className={css.container}>
						{title && <div className={css.title}>{title}</div>}
						{text && (
							<FormattedContent raw={text} allowedFormatters={['links']} />
						)}
						{children}
					</div>
				</div>
			</SmallScreen>
		</Fragment>
	);
};

export default InfoBox;
