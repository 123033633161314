// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.q1QEPk09ajiZBiRev8bo {
	height: 35px;
	width: 35px;
	border-radius: var(--radius-5);
	background-color: var(--palette-black);
	background-color: var(--palette-group-primary-color, var(--palette-black));

	/* Not using spacing here because spacing-2 is to little and -3 a bit to much */
	padding: 6px;
}
`, "",{"version":3,"sources":["webpack://./components/badge-wrapper/styles.css"],"names":[],"mappings":"AAAA;CACC,YAAY;CACZ,WAAW;CACX,8BAA8B;CAC9B,sCAA0E;CAA1E,0EAA0E;;CAE1E,+EAA+E;CAC/E,YAAY;AACb","sourcesContent":[".wrapper {\n\theight: 35px;\n\twidth: 35px;\n\tborder-radius: var(--radius-5);\n\tbackground-color: var(--palette-group-primary-color, var(--palette-black));\n\n\t/* Not using spacing here because spacing-2 is to little and -3 a bit to much */\n\tpadding: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
export var wrapper = `q1QEPk09ajiZBiRev8bo`;
export default ___CSS_LOADER_EXPORT___;
