export const getMembershipItems = (state) => state.memberships.get('items');

export const findAllByIds = (state, { membershipIds }) =>
	getMembershipItems(state)
		.filter((m) => membershipIds.includes(m.id) && m.status !== 2)
		.map((m) => {
			const group = state.groups.entities.get(m.groupId);

			m = m.set('group', group);

			return m;
		})
		.sort((a, b) => {
			if (a.group.name < b.group.name) {
				return -1;
			}
			if (a.group.name > b.group.name) {
				return 1;
			}
			return 0;
		})
		.toList();
