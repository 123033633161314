import { ReactNode } from 'react';

export const symbol_flash_auto: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m666-626-37 95q-3 8-9.562 12.5Q612.875-514 605-514q-13.594 0-21.297-11.5Q576-537 581-550l114-291q6-16 18.5-26t29.5-11q17-1 30 10t19 27l114 292q5 12.895-2.529 23.947Q895.943-514 882.229-514q-8.229 0-14.729-4.5T858-531l-37-95H666Zm16-47h123l-54-148h-15l-54 148ZM260-272l186-268H320l81-280H140v360h120v188Zm-43.9 140.844q-6.9-2.156-11.5-7.547T200-152v-248h-60q-24.75 0-42.375-17.625T80-460v-360q0-24.75 17.625-42.375T140-880h278q23 0 38 18.5t8 41.5l-64 220h74q26.873 0 39.937 24Q527-552 511-529L241-139q-5 7-11.5 8.5t-13.4-.656ZM260-460H140h120Z"
		/>
	</svg>
);
