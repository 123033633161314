import { Fragment } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import VideoSequenceComment from 'pkg/models/video_sequence_comment';

import * as actionTypes from 'pkg/actions/action-types';
import * as commentActions from 'pkg/actions/video_sequence_comments';
import { normalizedDispatch } from 'pkg/actions/utils';

import * as selectors from 'pkg/selectors';
import { RootState } from 'pkg/reducers';
import { MessageType, useWebSocketClient } from 'pkg/websocket';
import store from 'pkg/store/createStore';
import { useCurrentMembership } from 'pkg/identity';

import ReplyForm from 'containers/comment/ReplyForm';
import Comment from 'containers/comment/Comment';

import { useCueState } from 'components/video-analytics/CueState';

const Wrapper = styled.div`
	background: var(--palette-gray-800);
	grid-area: clip;
	padding: var(--spacing-6);
	display: grid;
	grid-auto-flow: row;
	row-gap: var(--spacing-4);
	align-items: start;
	align-content: start;
	position: relative;

	@media not ${styles.breakpoint.small} {
		margin: var(--spacing-5) 0;
		border-radius: var(--radius-3);
	}

	@media ${styles.breakpoint.small} {
		margin-top: var(--spacing-5);
	}
`;

const Empty = styled(Wrapper)`
	align-items: center;
	align-content: center;
	justify-items: center;
	justify-content: center;

	@media ${styles.breakpoint.small} {
		display: none;
	}
`;

const CommentFormWrapper = styled.div`
	width: 100%;
`;

const Heading = styled.div`
	font-size: var(--font-size-base);
	color: var(--palette-white);
`;

interface CommentControllerProps {
	sequenceId: number;
	commentId: number;
	canComment?: boolean;
}

function CommentController({
	sequenceId,
	commentId,
	canComment,
}: CommentControllerProps): JSX.Element {
	const dispatch = useDispatch();
	const ws = useWebSocketClient();

	ws.onMessage(MessageType.SequenceCommentCreated, (event) => {
		normalizedDispatch(
			event.data,
			VideoSequenceComment.normalizr()
		)(store.dispatch);
	});

	ws.onMessage(MessageType.SequenceCommentUpdated, (event) => {
		normalizedDispatch(
			event.data,
			VideoSequenceComment.normalizr()
		)(store.dispatch);
	});

	ws.onMessage<VideoSequenceComment>(
		MessageType.SequenceCommentDeleted,
		(event) => {
			store.dispatch({
				type: actionTypes.VideoSequenceComments.DELETE_ITEM,
				commentId: event.data.id,
			});
		}
	);

	const comment = useSelector((state: RootState) =>
		selectors.videoSequenceComments.find(state, commentId)
	);

	const replies = useSelector((state: RootState) =>
		selectors.videoSequenceComments.findAllReplies(state, comment.id)
	).sortBy((reply: VideoSequenceComment) => reply.createdAt);

	if (!comment) return null;

	const onDelete = (commentId: number) => {
		dispatch(commentActions.deleteComment(commentId));
	};

	const onEdit = (commentId: number, content: string) => {
		dispatch(commentActions.updateComment(commentId, { content }));
	};

	const onReply = (replyCommentId: number, content: string) => {
		dispatch(
			commentActions.createComment(sequenceId, { replyCommentId, content })
		);
	};

	return (
		<Comment
			commentId={commentId}
			commentType="VideoSequenceComment"
			userId={comment.userId}
			accountId={comment.accountId}
			content={comment.content}
			createdAt={comment.createdAt}
			onDelete={comment.hasIn(['links', 'delete']) && onDelete}
			onEdit={comment.hasIn(['links', 'edit']) && onEdit}
			onReply={canComment ? onReply : undefined}>
			{replies.valueSeq().map((reply: VideoSequenceComment) => (
				<Comment
					key={reply.id}
					compact
					commentId={reply.id}
					userId={reply.userId}
					accountId={reply.accountId}
					content={reply.content}
					createdAt={reply.createdAt}
					onDelete={reply.hasIn(['links', 'delete']) && onDelete}
					onEdit={reply.hasIn(['links', 'edit']) && onEdit}
				/>
			))}
		</Comment>
	);
}

export default function ClipDiscussion(): JSX.Element {
	const dispatch = useDispatch();
	const membership = useCurrentMembership();
	const { currentCue } = useCueState();

	const sequenceId = currentCue?.cueId ?? 0;

	const clip = useSelector((state: RootState) =>
		selectors.videoSequences.find(state, sequenceId as number)
	);

	const comments = useSelector((state: RootState) =>
		selectors.videoSequenceComments.findAllTopLevelComments(
			state,
			sequenceId as number
		)
	).sortBy((reply: VideoSequenceComment) => reply.createdAt);

	const activeSequenceUser = useSelector((state: RootState) =>
		selectors.videoSequenceUsers.findActiveSequenceUser(
			state,
			membership.targetUserId || membership.userId,
			sequenceId
		)
	);

	const adjustedCommentCount =
		activeSequenceUser?.description.length > 0 ? 1 : 0;

	const numComments =
		useSelector((state: RootState) =>
			selectors.videoSequenceComments.findAllBySequenceId(
				state,
				sequenceId as number
			)
		).size + adjustedCommentCount;

	const canComment = clip.hasIn(['links', 'create:video_sequence_comment']);

	const addComment = (content: string) => {
		dispatch(commentActions.createComment(clip.id, { content }));
	};

	if (!currentCue || !sequenceId || !clip)
		return <Empty>{t('No clip selected')}</Empty>;

	const conversation = (
		<Fragment>
			<Heading>{t('All comments')}</Heading>
			{canComment && (
				<CommentFormWrapper>
					<ReplyForm
						autoFocus={false}
						onSend={addComment}
						placeholder={t('Comment')}
					/>
				</CommentFormWrapper>
			)}
			{comments
				.valueSeq()
				.reverse()
				.map((comment: VideoSequenceComment) => (
					<CommentController
						key={comment.id}
						sequenceId={sequenceId as number}
						commentId={comment.id}
						canComment={canComment}
					/>
				))}
		</Fragment>
	);

	if (!numComments && !canComment) {
		return null;
	}

	return <Fragment>{conversation}</Fragment>;
}
