import { Dispatch } from 'redux';

import VideoSequenceComment, {
	VideoSequenceCommentPayload,
} from 'pkg/models/video_sequence_comment';

import * as services from 'pkg/actions/services';
import { normalizedDispatch } from 'pkg/actions/utils';
import * as app from 'pkg/actions/app';
import * as actionTypes from 'pkg/actions/action-types';

export const createComment =
	(sequenceId: number, payload: VideoSequenceCommentPayload) =>
	async (dispatch: Dispatch): Promise<VideoSequenceComment> => {
		const [request, response] = await services.video.createSequenceComment(
			sequenceId,
			payload
		);

		if (!request.ok) {
			app.triggerError(request)(dispatch);
			return;
		}

		normalizedDispatch(response, VideoSequenceComment.normalizr())(dispatch);

		return response;
	};

export const updateComment =
	(commentId: number, payload: VideoSequenceCommentPayload) =>
	async (dispatch: Dispatch): Promise<VideoSequenceComment> => {
		const [request, response] = await services.video.updateSequenceComment(
			commentId,
			payload
		);

		if (!request.ok) {
			app.triggerError(request)(dispatch);
			return;
		}

		normalizedDispatch(response, VideoSequenceComment.normalizr())(dispatch);

		return response;
	};

export const deleteComment =
	(commentId: number) =>
	async (dispatch: Dispatch): Promise<VideoSequenceComment> => {
		const [request, response] =
			await services.video.destroySequenceComment(commentId);

		if (!request.ok) {
			app.triggerError(request)(dispatch);
			return;
		}

		dispatch({
			type: actionTypes.VideoSequenceComments.DELETE_ITEM,
			commentId,
		});

		return response;
	};
