import { ReactNode } from 'react';

export const symbol_assistant_direction: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M482-41q-91 0-171-35t-140-95q-60-60-95-140T41-482q0-90 35-170t95-140q60-60 140-94.5T482-921q90 0 170 34.5T792-792q60 60 94.5 140T921-482q0 91-34.5 171T792-171q-60 60-140 95T482-41Zm-1-440Zm-29 287q11 11 26.5 11t26.5-11l266-260q11-12 11-28t-11-27L505-769q-11-11-26.5-11T452-769L192-509q-11 11-11 27.5t11 27.5l260 260Zm-70-300v94q0 13-8.5 21.5T352-370q-13 0-21.5-8.5T322-400v-121q0-11 11-22t22-11h209l-38-38q-9-9-8.5-21t9.5-21q9-9 21-9t21 9l87 87q9 9 9 21t-9 21l-92 94q-8 9-20.5 9t-21.5-9q-9-9-9-21.5t9-21.5l40-40H382Zm100 393q158 0 268.5-111T861-482q0-158-110.5-268.5T482-861q-159 0-270 110.5T101-482q0 159 111 270t270 111Z"
		/>
	</svg>
);
