import { ReactNode } from 'react';

export const symbol_position_bottom_left: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-60h600v-600H180v600Zm0-600v600-600Zm95 493h270q18.75 0 31.875-13.176 13.125-13.177 13.125-32Q590-351 576.875-364T545-377H275q-18.75 0-31.875 13.176-13.125 13.177-13.125 32Q230-313 243.125-300T275-287Z"
		/>
	</svg>
);
