import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t } from '@transifex/native';

import PerformanceReview from 'pkg/models/performance_review';

import { RootState } from 'pkg/reducers';
import * as actions from 'pkg/actions';
import * as selectors from 'pkg/selectors';
import { useCheckRelationToUser, useUser } from 'pkg/hooks/selectors';
import * as routes from 'pkg/router/routes';
import {
	useCurrentGroup,
	useCurrentMembership,
	useCurrentOrganization,
} from 'pkg/identity';
import * as models from 'pkg/api/models';

import LatestReview from 'containers/user/profile/development/performance-review/Single';

import SectionTitle from 'components/SectionTitle';
import AssetImage from 'components/AssetImage';

import EditModal from 'components/user/profile/performance-review/EditModal';
import Column from 'components/layout/column';
import ButtonWrapper from 'components/user/profile/development/ButtonWrapper';

import * as ContextMenu from 'design/context_menu';
import MissingEntities from 'design/placeholders/block';
import Button from 'design/button';

type Props = {
	groupId: number;
	userId: number;
};

const Review = ({ groupId, userId }: Props): JSX.Element => {
	const dispatch = useDispatch();
	const org = useCurrentOrganization();
	const [showEditForm, setShowEditForm] = useState(false);
	const [performanceReview, setPerformanceReview] = useState('');

	const latestPerformanceReview = useSelector((state: RootState) =>
		selectors.performanceReviews.findLatestPerformanceReview(state, userId)
	);

	const activeMembership = useCurrentMembership();
	const user = useUser(userId);
	const group = useCurrentGroup();

	const template = models.group.getPerformanceReviewTemplate(group);
	const templateExists = template.length > 0;

	const { isParentToUser } = useCheckRelationToUser(userId);
	const forAccount = isParentToUser ? user.accountId : null;

	const handleSave = async (text: string): Promise<void> => {
		await dispatch(
			actions.performanceReview.savePerformanceReview(
				{
					performanceReview: text,
					groupId: groupId,
					userId: userId,
				},
				forAccount
			)
		);
		setShowEditForm(false);
	};

	const showDialogueModal = (): void => setShowEditForm(true);
	const hideEditModal = (): void => setShowEditForm(false);

	const showTemplateDialogue = () => {
		showDialogueModal();
		setPerformanceReview(template);
	};

	const showBlankDialogue = () => {
		showDialogueModal();
		setPerformanceReview('');
	};

	return (
		<Column>
			<SectionTitle>
				{t(`Latest review`)}
				{latestPerformanceReview.createdAt > 0 && (
					<ContextMenu.Menu
						toggleWith={
							<Button inline noPadding icon="add" label={t(`New dialogue`)} />
						}>
						<ContextMenu.Item onClick={showBlankDialogue}>
							{t('New blank dialogue')}
						</ContextMenu.Item>
						<ContextMenu.Item
							disabled={!templateExists}
							noLink={!templateExists}
							closeOnClick={templateExists}
							onClick={templateExists && showTemplateDialogue}>
							{t('Use template')}
						</ContextMenu.Item>
					</ContextMenu.Menu>
				)}
			</SectionTitle>
			{latestPerformanceReview.createdAt > 0 ? (
				<LatestReview
					review={latestPerformanceReview}
					groupId={groupId}
					userId={userId}
					forAccount={forAccount}
				/>
			) : (
				<MissingEntities
					noBorder
					helpUrl="https://help.360player.com/article/41-write-a-performance-review"
					title={
						activeMembership.role === 2
							? t(`Performance reviews will help your players get better!`)
							: t(`Performance reviews will help you get better!`)
					}
					description={
						activeMembership.role === 2
							? t(
									`Periodic reviews of performance will help your players stay focused and understand their own development.`
								)
							: t(
									`Periodic reviews of performance will help you get better and keep focus.`
								)
					}
					actions={
						<Button
							icon="add"
							secondary
							label={t(`Create a review`)}
							onClick={showDialogueModal}
						/>
					}
					image={
						<AssetImage
							className="temporary"
							src="img/missing-entities/reviews.svg"
						/>
					}
				/>
			)}
			{latestPerformanceReview.createdAt > 0 && (
				<ButtonWrapper>
					<Button
						block
						href={routes.User.Profile.PerformanceReviews(
							org.id,
							groupId,
							userId
						)}
						label={t(`View all`)}
					/>
				</ButtonWrapper>
			)}
			{showEditForm && (
				<EditModal
					review={new PerformanceReview({ performanceReview })}
					onClose={hideEditModal}
					onSave={handleSave}
				/>
			)}
		</Column>
	);
};

export default Review;
