import { ReactNode } from 'react';

export const symbol_action_key: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M666-140q-63.112 0-108.056-44.944Q513-229.887 513-293q0-63.525 44.944-108.763Q602.888-447 666-447q63.525 0 108.763 45.237Q820-356.525 820-293q0 23-7 44.5T794-209l98 99q5 5 7 10.08 2 5.081 2 10.5 0 5.42-2 10.92T892-68q-5 5-10.081 7-5.08 2-10.5 2Q866-59 860.5-61q-5.5-2-10.5-7l-99-98q-18 13-39.802 19.5Q689.397-140 666-140Zm-454 1q-63.113 0-108.056-44.944Q59-228.887 59-292q0-65 44.944-110Q148.887-447 212-447q65 0 110 45t45 110q0 63.113-45 108.056Q277-139 212-139Zm0-60q40 0 67.5-27.319Q307-253.637 307-292q0-40-27.5-67.5T212-387q-38.363 0-65.681 27.5Q119-332 119-292q0 38.363 27.319 65.681Q173.637-199 212-199Zm453 0q40 0 67.5-27.319Q760-253.637 760-292q0-40-27.5-67.5T665-387q-38.362 0-65.681 27.5Q572-332 572-292q0 38.363 27.319 65.681Q626.638-199 665-199ZM212-592q-63.113 0-108.056-44.944Q59-681.888 59-745q0-65 44.944-110Q148.887-900 212-900q65 0 110 45t45 110q0 63.112-45 108.056Q277-592 212-592Zm453 0q-63.112 0-108.056-44.944Q512-681.888 512-745q0-65 44.944-110T665-900q65 0 110 45t45 110q0 63.112-45 108.056Q730-592 665-592Zm-453-60q40 0 67.5-27.319T307-745q0-40-27.5-67.5T212-840q-38.363 0-65.681 27.5Q119-785 119-745q0 38.362 27.319 65.681Q173.637-652 212-652Zm453 0q40 0 67.5-27.319T760-745q0-40-27.5-67.5T665-840q-38.362 0-65.681 27.5Q572-785 572-745q0 38.362 27.319 65.681Q626.638-652 665-652ZM213-293Zm0-453Zm453 0Z"
		/>
	</svg>
);
