import { t } from '@transifex/native';
import { Fragment } from 'react';

import { FormSummary } from 'routes/forms/single/overview';

import * as DataCard from 'design/data_card';

interface Props {
	summary: FormSummary;
}

const SubmissionSummary = ({ summary }: Props) => {
	return (
		<Fragment>
			{summary.Settings.SubmissionSummary && (
				<DataCard.WithLargeIcon
					icon="registration"
					title={t('Submissions')}
					heading={summary.SubmissionSummary.Total.toString()}
					headingSuffix={t('Total amount')}>
					<DataCard.Breakdown
						items={[
							{
								title: t('Today'),
								value: summary.SubmissionSummary.Today.toString(),
							},
							{
								title: t('Week'),
								value: summary.SubmissionSummary.SevenDays.toString(),
							},
							{
								title: t('30 days'),
								value: summary.SubmissionSummary.ThirtyDays.toString(),
							},
						]}
					/>
				</DataCard.WithLargeIcon>
			)}
		</Fragment>
	);
};

export default SubmissionSummary;
