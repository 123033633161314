import { ReactNode } from 'react';

export const symbol_newsstand: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M110-160q-12.75 0-21.375-8.675Q80-177.351 80-190.175 80-203 88.625-211.5T110-220h740q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-160H110Zm88.825-160Q186-320 177.5-328.625T169-350v-256q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T229-606v256q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Zm159 0Q345-320 336.5-328.625T328-350v-420q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T388-770v420q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Zm159 0Q504-320 495.5-328.625T487-350v-420q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T547-770v420q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM787-335q-11 6-22.5 3.5T747-345L613-573q-7-11-3.473-23.459t14.5-18.5Q635-621 647.5-618q12.5 3 18.5 14l132 229q6 11 3 22.5T787-335Z"
		/>
	</svg>
);
