import { ReactNode } from 'react';

export const symbol_pip_exit: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-160q-24 0-42-18t-18-42v-270q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-490v270h680v-520H470q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T470-800h350q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm407-290 129 129q9 9 21 9t21-9.053q9-9.052 9-21.5Q727-355 718-364L589-493h88q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T677-553H517q-12.75 0-21.375 8.625T487-523v160q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T547-363v-87ZM110-580q-12.75 0-21.375-8.625T80-610v-160q0-12.75 8.625-21.375T110-800h240q12.75 0 21.375 8.625T380-770v160q0 12.75-8.625 21.375T350-580H110Zm370 100Z"
		/>
	</svg>
);
