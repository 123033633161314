import { ReactNode } from 'react';

export const symbol_right_click: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M179-100q-17 17-39.5 17T100-100q-17-17-17-39t17-39l132-132-106-35q-11-4-10.5-15t11.5-14l327-98q8-2 14.5 4.5T473-453l-98 327q-3 11-14 11.5T346-125l-35-106-132 131Zm542-380q0 79-46 142t-120 87q-12 4-23-2t-15-18q-4-12 2-23t18-15q54-17 89-64t35-107q0-75-52.5-127.5T481-660q-61 0-107.5 35T310-536q-4 12-15 18t-23 2q-12-4-17.5-15t-1.5-23q24-73 85.5-119.5T481-720q100 0 170 70t70 170Z"
		/>
	</svg>
);
