import { ReactNode } from 'react';

export const symbol_directions_run: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M535-70v-209l-108-99-36 159q-2.968 12.121-12.984 18.561Q368-194 356-196l-208-43q-11-2-18-12t-5-22q2-12 12.5-18t21.5-4l171 34 73-369-100 47v104q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T243-479v-125q0-9.333 4.875-16.722Q252.75-628.111 261-632l146-61q32-14 45.5-17.5t27.1-3.5q20.4 0 35.9 8.5T542-680l42 67q23 37 60 65.5t86 36.5q12.75 2.164 21.375 10.82Q760-491.525 760-479.262 760-467 751.449-458q-8.552 9-20.449 7-57-6-102.5-36.5T543-573l-39 158 81 75q5 4.552 7.5 10.241 2.5 5.69 2.5 11.759v248q0 12.75-8.675 21.375Q577.649-40 564.825-40 552-40 543.5-48.625T535-70Zm5.08-684q-30.08 0-51.58-21.42-21.5-21.421-21.5-51.5 0-30.08 21.42-51.58 21.421-21.5 51.5-21.5 30.08 0 51.58 21.42 21.5 21.421 21.5 51.5 0 30.08-21.42 51.58-21.421 21.5-51.5 21.5Z"
		/>
	</svg>
);
