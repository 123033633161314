import { GroupsProps } from 'pkg/models/user';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as sdk from 'pkg/core/sdk';

export interface UserPayload {
	organizationId: number;
	billingUserId?: number;
	firstName: string;
	lastName?: string;
	companyName?: string;
	email?: string;
	sex?: number;
	address?: string;
	city?: string;
	region?: string;
	postal_code?: string;
	country?: string;
	phoneNumber?: string;
	birthDate?: string;
	profileImage?: string;
	taxIdType?: string;
	taxIdValue?: string;
	groups?: GroupsProps[];
	postalCode?: string;
	nationality?: string;
	fields?: { key: string; value?: string; attachmentId?: number }[];

	lokSwedishPersonalId?: string;
	meta?: { [key: string]: any };
}

export const getUser = (
	userId: number,
	params?: { include_user_products: boolean }
): Promise<Response> => sdk.get(endpoints.Users.Show(userId), params);

export const createUser = (payload: UserPayload): Promise<Response> =>
	sdk.post(endpoints.Users.Create(), {}, payload);

export const updateUser = (userId: number, payload: any): Promise<Response> =>
	sdk.patch(endpoints.Users.Update(userId), {}, payload);

// getStripeCustomePortalLink sets up a session for the Stripe Customer Portal

export const mergeUsers = async (
	userId: number,
	sourceUserId: number
): Promise<Response> => sdk.post(endpoints.Users.Merge(userId, sourceUserId));

export const archiveUsers = async (ids: number[]): Promise<Response> =>
	sdk.destroy(endpoints.Users.Delete(), {}, { ids });

export const restoreUser = async (userId: number): Promise<Response> =>
	sdk.post(endpoints.Users.Undelete(userId));

export const fetchPaymentMethod = async (userId: number): Promise<Response> =>
	sdk.get(endpoints.Users.DefaultPaymentSource(userId));

export const setBillingUsers = async (userIds: number[]): Promise<Response> =>
	sdk.post(
		endpoints.Users.SetBillingUserToLegalGuardian(),
		{},
		{ userIDs: userIds }
	);
