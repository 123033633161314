import { ReactNode } from 'react';

export const symbol_move_location: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M782-236H607q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T607-296h175l-52-53q-8-9-8.5-21t8.5-21q9-9 21-9t21 9l104 104q9 9 9 21t-9 21L772-141q-9 9-21 9t-21-9q-9-9-8.5-21t8.5-21l52-53ZM400-370q51.397 0 92.699-24Q534-418 559-458q-34-26-74.215-39t-85-13Q355-510 315-497t-74 39q25 40 66.301 64 41.302 24 92.699 24Zm.089-200Q429-570 449.5-590.589q20.5-20.588 20.5-49.5Q470-669 449.411-689.5q-20.588-20.5-49.5-20.5Q371-710 350.5-689.411q-20.5 20.588-20.5 49.5Q330-611 350.589-590.5q20.588 20.5 49.5 20.5ZM400-490Zm-.25 390q-10.75 0-21.25-3.5T360-115q-42-38-91-87.5T178-309q-42-57-70-119T80-552q0-150 96.5-239T400-880q127 0 223.5 89T720-552q0 15.632-1.333 32.132-1.334 16.5-6.667 33.868h-63q5-17 8-33.625T660-552q0-117.79-75.292-192.895Q509.417-820 400-820t-184.708 75.105Q140-669.79 140-552q0 75 65 173.5T400-159q23-20 42.5-40t38.5-39l6.923 6.462 15 14Q511-210 518-203.5l7 6.5q-21 22-43 43.5T440-115q-8 8-18.75 11.5t-21.5 3.5Z"
		/>
	</svg>
);
