import { t } from '@transifex/native';
import { Fragment, useContext } from 'react';
import { useFormContext } from 'react-hook-form';

import * as styles from 'pkg/config/styles';

import { getAmountOfDaysBetweenDates } from 'pkg/date';
import * as models from 'pkg/api/models';
import { usePaymentProviderContext } from 'pkg/contexts/provider_settings';

import { AddOrderContext } from 'routes/payments/orders/create';

import Icon from 'components/icon';

import * as Input from 'components/form/inputs';
import Column from 'components/layout/column';
import DiscountInput from 'components/discount/form/input';

function General() {
	const formContext = useFormContext();
	const OrderContext = useContext(AddOrderContext);
	const paymentProviderContext = usePaymentProviderContext();

	let daysUntilDue = 30;

	if (OrderContext.order?.id) {
		const created = new Date(OrderContext.order.createdAt * 1000);
		const due = new Date(OrderContext.order.dueDate * 1000);

		daysUntilDue = getAmountOfDaysBetweenDates(created, due);
	}
	const description = OrderContext.order.description || '';

	const dueDateLabel = t('Days until due');
	let descriptionMaxLength =
		paymentProviderContext.settings.orderDescriptionMaxLength.toString();
	let paymentDueHint = t('Payment due is when the invoice has to be paid.');

	if (OrderContext.isSubscription) {
		descriptionMaxLength =
			paymentProviderContext.settings.subscriptionDescriptionMaxLength.toString();
		paymentDueHint = t(
			'All invoices generated by the subscription will have this due setting.'
		);
	}

	const handleAddDiscount = (discount: models.discount.Discount) =>
		OrderContext.setFormState({ discount });

	const handleRemoveDiscount = () =>
		OrderContext.setFormState({ discount: null });

	return (
		<Fragment>
			<Column spacing={styles.spacing._5}>
				<Input.Group
					hint={paymentDueHint}
					label={dueDateLabel}
					errorMessage={
						formContext.formState.errors?.daysUntilDue &&
						t('Please enter a value between 1 and 365 for days until due.')
					}>
					<Input.Field
						name="daysUntilDue"
						type="number"
						defaultValue={daysUntilDue}
						min={1}
						max={365}
						required={OrderContext.formState.collectionMethod === 'manual'}>
						<Input.Prefix>
							<Icon name="nav-events" />
						</Input.Prefix>
					</Input.Field>
				</Input.Group>
				<Input.Group label={t('Description')} optional>
					<Input.Area
						name="description"
						defaultValue={description}
						minRows={4}
						maxLength={descriptionMaxLength}
					/>
				</Input.Group>

				<DiscountInput
					discount={OrderContext.formState.discount}
					handleAddDiscount={handleAddDiscount}
					handleRemoveDiscount={handleRemoveDiscount}
				/>
			</Column>
		</Fragment>
	);
}

export default General;
