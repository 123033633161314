import { ReactNode } from 'react';

export const symbol_restore_page: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M373-521q16-23 46.5-37.5T482-573q60 0 98 38t38 98q0 60-38 98.5T482-300q-41 0-74-19.5T357-371q-5-8-14.5-11.5T325-381q-10 5-12.5 15t2.5 19q25 45 68.5 70.5T482-251q78 0 132-54t54-132q0-78-54-132t-132-54q-45 0-82 19t-61 45v-47q0-9-6.5-15.5T317-628q-9 0-16 6.5t-7 15.5v107q0 9 7 16t16 7h109q9 0 15.5-7t6.5-16q0-9-6.5-15.5T426-521h-53ZM220-80q-24 0-42-18t-18-42v-680q0-24 18-42t42-18h316q12 0 23.5 5t19.5 13l203 203q8 8 13 19.5t5 23.5v476q0 24-18 42t-42 18H220Zm0-60h520v-474L534-820H220v680Zm0 0v-680 680Z"
		/>
	</svg>
);
