import {
	AccountFlags,
	AccountClearFlags,
	AccountNotificationSettings,
	AccountClearNotificationSettings,
} from 'pkg/models/account';

import * as api from 'pkg/api';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import * as sdk from 'pkg/core/sdk';
import { ShowResponse } from 'pkg/api/endpoints/self';

export interface AccountPayload {
	firstName?: string;
	lastName?: string;
	email?: string;
	birthDate?: string;
	countryId?: number;
	languageCode?: string;
	hourFormat?: '12h' | '24h';
	profileImage?: string;
	password?: string;
	passwordConfirm?: string;
	currentPassword?: string;
	flags?: AccountFlags[] | AccountClearFlags[];
	appleAuthCode?: string;
	notificationSettings?:
		| AccountNotificationSettings[]
		| AccountClearNotificationSettings[];
}

export const getSelf = async (): Promise<
	[Response, models.account.Account]
> => {
	const [response, self] = await api.get<ShowResponse>(
		endpoints.Self.ShowSelf()
	);

	// App update required
	if (response.status === 426) {
		return [response, null];
	}

	return [response, self.account];
};

export const patch = async (
	accountId: number,
	payload: AccountPayload,
	headers?: { [key: string]: string }
): Promise<[Response, any]> => {
	const request = await sdk.patch(
		endpoints.Accounts.Update(accountId),
		{},
		payload,
		headers
	);

	const response = await request.json();

	return [request, response];
};

export async function requestParentalConsent(
	email: string
): Promise<[Response, any]> {
	const request = await sdk.post(
		endpoints.AccountToken.CreateParentalConsentToken(),
		{},
		{ email }
	);

	const response = await request.json();

	return [request, response];
}

export async function grantParentalConsent(
	accountId: number
): Promise<[Response, any]> {
	const request = await sdk.post(
		endpoints.Accounts.GrantParentalConsent(accountId)
	);

	const response = await request.json();

	return [request, response];
}

export async function revokeParentalConsent(
	accountId: number
): Promise<[Response, any]> {
	const request = await sdk.destroy(
		endpoints.Accounts.RemoveParentalConsent(accountId)
	);

	const response = await request.json();

	return [request, response];
}

export const connectApple = (
	accountId: number,
	accessToken: string
): Promise<[Response, any]> => patch(accountId, { appleAuthCode: accessToken });

export const disconnectApple = (accountId: number): Promise<[Response, any]> =>
	patch(accountId, { appleAuthCode: '' });
