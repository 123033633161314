import { ReactNode } from 'react';

export const symbol_agender: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-120q-100 0-170-70t-70-170q0-93 60.5-160T450-598v-212q0-13 8.5-21.5T480-840q13 0 21.5 8.5T510-810v212q90 11 150 78t60 160q0 100-70 170t-170 70Zm0-60q68 0 117-43t60-107H303q11 64 60.5 107T480-180ZM302-390h356q-11-65-60.5-107.5T480-540q-67 0-117 42.5T302-390Z"
		/>
	</svg>
);
