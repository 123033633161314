const navPayments = {
	name: 'nav-payments',
	objects: [
		{
			type: 'path',
			attributes: {
				d: 'M18.545 6.8914H7.26792C6.88132 6.8914 6.56792 7.2048 6.56792 7.5914V14.776C6.56792 15.1626 6.88132 15.476 7.26792 15.476H18.545C18.9316 15.476 19.245 15.1626 19.245 14.7761V7.5914C19.245 7.2048 18.9316 6.8914 18.545 6.8914ZM7.26792 5.5914C6.16335 5.5914 5.26792 6.48683 5.26792 7.5914V14.776C5.26792 15.8806 6.16335 16.776 7.26792 16.776H18.545C19.6496 16.776 20.545 15.8806 20.545 14.7761V7.5914C20.545 6.48683 19.6496 5.5914 18.545 5.5914H7.26792Z',
				fillRule: 'evenodd',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M4.10493 9.04129V15.1285C4.10493 16.7025 5.38092 17.9785 6.95493 17.9785H17.1346V19.2785H6.95493C4.66295 19.2785 2.80493 17.4204 2.80493 15.1285V9.04129H4.10493Z',
				fillRule: 'evenodd',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M5.26792 8.33023H20.545V10.4069H5.26792V8.33023Z',
			},
		},
	],
};
export default navPayments;
