import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';

import * as UserRowStyles from 'routes/event/components/UserRowStyles';
import StatusPicker from 'routes/event/series/event_series_users/status-picker';

import Avatar from 'components/avatar';

import Column from 'components/layout/column';

interface UserRowProps {
	eventSeries: models.eventSeries.EventSeries;
	eventSeriesUser: models.eventSeriesUser.EventSeriesUser;

	isOrganizer?: boolean;
}

export default function UserRow({
	eventSeries,
	eventSeriesUser,

	isOrganizer,
}: UserRowProps): JSX.Element {
	const canEditUser = models.canEdit(eventSeriesUser);

	return (
		<Column spacing={styles.spacing._2}>
			<UserRowStyles.ListItem>
				<UserRowStyles.InfoWrapper>
					<Avatar user={eventSeriesUser.user} size={30} />
					<span>
						<UserRowStyles.Name>
							{models.user.fullName(eventSeriesUser.user)}
						</UserRowStyles.Name>
					</span>
				</UserRowStyles.InfoWrapper>

				{canEditUser && (
					<StatusPicker
						eventSeries={eventSeries}
						eventSeriesUser={eventSeriesUser}
						isOrganizer={isOrganizer}
					/>
				)}
			</UserRowStyles.ListItem>
		</Column>
	);
}
