import styled from 'styled-components';
import TextAreaAutosize from 'react-textarea-autosize';

import FieldStyles from './FieldStyles';

/**
 * @type {React.Element<any>}
 * @deprecated
 */

const TextArea = styled(TextAreaAutosize)`
	${FieldStyles};
	line-height: var(--font-line-height-base);
`;

export default TextArea;
