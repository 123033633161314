import { ReactNode } from 'react';

export const symbol_fluid_med: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M750-80q-24.75 0-42.375-17.625T690-140v-62q-73-11-121.5-66.5T520-400v-220q0-24.75 17.25-42.375T580-680h280q24.75 0 42.375 17.625T920-620v220q0 76-49 131.5T750-202v62h100q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q880-97 871.375-88.5T850-80H750Zm40-350h75v-195H575v115h55q30.489 0 57.745 14Q715-482 734-458q10 14 24.5 21t31.5 7Zm-70 175q53 0 92-32.5t50-82.5h-72q-30.848 0-58.424-13.5T686-422q-10.588-12.833-25.294-20.417Q646-450 630-450h-55v50q0 61 42 103t103 42Zm-34-203ZM250-280h-70q-24.75 0-42.375-17.625T120-340v-340h-10q-12 0-21-9t-9-21.5q0-12.5 9-21t21-8.5h140v-80h-30q-12 0-21-9t-9-21.5q0-12.5 9-21t21-8.5h120q12.75 0 21.375 8.625T370-850q0 12-8.625 21T340-820h-30v80h140q12.75 0 21.375 8.625T480-710q0 12-8.625 21T450-680h-10v340q0 24.75-17.625 42.375T380-280h-70v189q0 8.5-8 12.75T286-79l-24-18q-6-4.5-9-10.5t-3-13.5v-159Zm-70-60h200v-80h-90q-12 0-21-9t-9-21q0-12 9-21t21-9h90v-60h-90q-12 0-21-9t-9-21q0-12 9-21t21-9h90v-80H180v340Z"
		/>
	</svg>
);
