import { ReactNode } from 'react';

export const symbol_near_me_disabled: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M520-137 413-413 137-520q-10-4-14.5-12t-4.5-17q0-9 4.5-16t14.5-11l200-75-198-198q-9-9-9-21.5t9-21.5q9-9 21.5-9t21.5 9l710 710q9 9 9 21t-9 21q-9 9-21.5 9t-21.5-9L651-337l-75 200q-4 10-11 14.5t-16 4.5q-9 0-17-4.5T520-137Zm290-673q6 6 8.5 14.5T817-778L715-507q-6 16-22.5 19.5T664-496q-7-7-8.5-16t1.5-17l80-208-208 80q-8 3-17 1.5t-16-8.5q-12-12-8.5-28.5T507-715l271-102q9-4 17.5-1.5T810-810ZM545-234l58-151-218-218-150 58 224 86 86 225Zm35-346Zm-86 86Z"
		/>
	</svg>
);
