import { ReactNode } from 'react';

export const symbol_social_distance: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m220-238 45 45q10 10 10.5 23.5T265-145q-11 11-24.5 11t-24.883-11.287L108-252q-9-9-9-21t9-21l108-108q10-10 24-10.5t25 10.5q11 11 11 24.5T265-353l-45 45h520l-45-45q-10-10-10.5-23.5T695-401q11-11 24.5-11t24.883 11.287L852-294q9 9 9 21t-9 21L744-144q-10 10-23.5 10T696-145q-11-11-11-24.5t11-24.5l44-44H220ZM99-535v-13q0-21.081 11.408-38.649Q121.816-604.216 142-613q25.078-10.522 51.603-16.261Q220.128-635 249.064-635t55.417 5.739Q330.963-623.522 356-613q20.184 8.784 31.592 26.351Q399-569.081 399-548v13H99Zm462 0v-13q0-21.081 11.408-38.649Q583.816-604.216 604-613q25.078-10.522 51.603-16.261Q682.128-635 711.064-635t55.417 5.739Q792.963-623.522 818-613q20.184 8.784 31.592 26.351Q861-569.081 861-548v13H561ZM248.956-690Q218-690 196-712.044q-22-22.045-22-53Q174-796 196.044-818q22.045-22 53-22Q280-840 302-817.956q22 22.045 22 53Q324-734 301.956-712q-22.045 22-53 22Zm462 0Q680-690 658-712.044q-22-22.045-22-53Q636-796 658.044-818q22.045-22 53-22Q742-840 764-817.956q22 22.045 22 53Q786-734 763.956-712q-22.045 22-53 22Z"
		/>
	</svg>
);
