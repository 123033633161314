import styled, { css } from 'styled-components';

import * as breakpoints from 'pkg/config/breakpoints';

import Link from 'pkg/router/Link';

import CardWrapper from 'routes/dashboard/components/Card';

import Icon from 'components/icon';

const Card = styled(CardWrapper)`
	display: grid;
	grid-template-columns: 1fr 1fr;
	width: 100%;
	flex: 0 0 100%;
	min-width: 0;
	margin: 0;
	padding: var(--spacing-5);
	line-height: 1;

	@media ${breakpoints.small} {
		grid-template-columns: 1fr;
		grid-row-gap: var(--spacing-8);
	}

	@media ${breakpoints.large}, ${breakpoints.nolimit} {
		width: 100%;
		flex: 0 0 100%;
	}
`;

const CardColumn = styled.div`
	&:last-child {
		text-align: right;
	}
`;

const CardTitle = styled.div`
	font-size: var(--font-size-base);
	font-weight: var(--font-weight-semibold);
	margin-bottom: 0.3rem;
	color: var(--palette-gray-900);
`;

const CardTimeSpan = styled.div`
	font-size: var(--font-size-sm);
	font-weight: var(--font-weight-normal);
	color: var(--palette-gray-500);
`;

const TrendArrow = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	content: '';
	width: 25px;
	height: 25px;
	background: red;
	border-radius: 50%;
	margin-right: 0.5rem;
	font-size: var(--font-size-lg);
`;

const CardValue = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	font-size: 1.8rem;
	line-height: 1.8rem;
	font-weight: var(--font-weight-semibold);
	margin-bottom: 0.35rem;

	${(props) =>
		props.$trend &&
		props.$trend === 'up' &&
		css`
			color: var(--palette-green-500);

			${TrendArrow} {
				background: var(--palette-green-100);
				color: var(--palette-green-500);
			}
		`}

	${(props) =>
		props.$trend &&
		props.$trend === 'flat' &&
		css`
			color: var(--palette-gray-500);

			${TrendArrow} {
				background: var(--palette-gray-100);
				color: var(--palette-gray-500);
			}
		`}

	${(props) =>
		props.$trend &&
		props.$trend === 'down' &&
		css`
			color: var(--palette-red-400);

			${TrendArrow} {
				background: var(--palette-red-100);
				color: var(--palette-red-400);
			}
		`}

	${(props) =>
		props.$trend &&
		props.$trend === 'none' &&
		css`
			color: var(--palette-gray-400);
		`}
`;

const CardDescription = styled.div`
	font-size: 0.7rem;
	font-weight: var(--font-weight-normal);
	color: var(--palette-gray-500);
`;

const TeamStatsItem = ({ data }) => {
	const {
		title,
		timeSpan,
		value,
		description,
		trend,
		showArrow,
		linkHref,
		reverseTrendArrow,
	} = data;

	let rotation = 'deg';
	if (trend && trend === 'up') {
		if (reverseTrendArrow) {
			rotation = '45deg';
		} else {
			rotation = '-45deg';
		}
	} else if (trend && trend === 'down') {
		if (reverseTrendArrow) {
			rotation = '-45deg';
		} else {
			rotation = '45deg';
		}
	}

	return (
		<Card as={Link} href={linkHref} noBorder>
			<CardColumn>
				<CardTitle>{title}</CardTitle>
				<CardTimeSpan>{timeSpan}</CardTimeSpan>
			</CardColumn>
			<CardColumn>
				<CardValue $trend={trend}>
					{showArrow && (
						<TrendArrow>
							<Icon name="arrow-right" rotate={rotation} />
						</TrendArrow>
					)}
					<span>{value}</span>
				</CardValue>
				<CardDescription>{description}</CardDescription>
			</CardColumn>
		</Card>
	);
};

export default TeamStatsItem;
