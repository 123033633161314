import { ReactNode } from 'react';

export const symbol_party_mode: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-120q-24 0-42-18t-18-42v-513q0-23 18-41.5t42-18.5h147l55-66q8-11 20-16t26-5h184q14 0 26 5t20 16l55 66h147q23 0 41.5 18.5T880-693v513q0 24-18.5 42T820-120H140Zm0-60h680v-513H645l-73-87H388l-73 87H140v513Zm340-257Zm0 171q69 0 119.5-46T650-426q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T590-426q0 43-32.5 71.5T480-326h-97q-8.909 0-11.455 10Q369-306 379-298q22 16 47.894 24 25.895 8 53.106 8ZM340.175-416q12.825 0 21.325-8.625T370-446q0-43 32.5-71.5T480-546h97q8.909 0 11.455-10Q591-566 581-574q-22-16-47.894-24-25.895-8-53.106-8-69 0-119.5 46T310-446q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625Z"
		/>
	</svg>
);
