import { t } from '@transifex/native';

import { FinePointerDevice, TouchDevice } from 'components/MediaQuery';

import * as ContextMenu from 'design/context_menu';
import Button from 'design/button';

const DisplayActions = ({
	edit,
	duplicate,
	remove,
	exportImage,
	setThumbnail,
}: any) => {
	return (
		<div style={{ float: 'right' }}>
			<ContextMenu.Menu toggleWith={<Button small icon="more_horiz" />}>
				<TouchDevice>
					<ContextMenu.Item>
						<ContextMenu.ItemIcon name="edit" />
						{t(
							`Visit 360Player on the desktop to create or edit your drawings.`
						)}
					</ContextMenu.Item>
				</TouchDevice>
				<FinePointerDevice>
					<ContextMenu.Item onClick={edit}>
						<ContextMenu.ItemIcon name="edit" />
						{t('Edit')}
					</ContextMenu.Item>
				</FinePointerDevice>
				<ContextMenu.Item onClick={duplicate}>
					<ContextMenu.ItemIcon name="content_copy" />
					{t('Duplicate')}
				</ContextMenu.Item>
				<ContextMenu.Item onClick={exportImage}>
					<ContextMenu.ItemIcon name="ios_share" />
					{t(`Export image`)}
				</ContextMenu.Item>
				{setThumbnail && (
					<ContextMenu.Item onClick={setThumbnail}>
						<ContextMenu.ItemIcon name="image" />
						{t(`Set as exercise thumbnail`)}
					</ContextMenu.Item>
				)}
				<ContextMenu.ConfirmItem
					caution
					message={t(
						`Are you sure you want to remove this drawing from the exercise? This action can not be undone.`
					)}
					confirmLabel={t('Remove')}
					onConfirm={remove}>
					<ContextMenu.ItemIcon name="delete" />
					<span>{t('Remove')}</span>
				</ContextMenu.ConfirmItem>
			</ContextMenu.Menu>
		</div>
	);
};

export default DisplayActions;
