import { EventReportPayload } from 'pkg/actions/event_reports';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as sdk from 'pkg/core/sdk';

export const fetchGroupedByUser = async (
	payload: EventReportPayload
): Promise<[Response, any]> => {
	const request = await sdk.post(
		endpoints.EventReport.UserSummaryReport(),
		{},
		payload
	);

	const response = await request.json();

	return [request, response];
};

export const fetchByUser = async (
	payload: EventReportPayload
): Promise<[Response, any]> => {
	const request = await sdk.post(
		endpoints.EventReport.UserDetailReport(payload.userId),
		{},
		payload
	);

	const response = await request.json();

	return [request, response];
};
