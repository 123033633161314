import { ReactNode } from 'react';

export const symbol_picture_in_picture_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M736-443q18.75 0 31.875-13.125T781-488v-167q0-18.75-13.125-31.875T736-700H476q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T476-640h245v137H615q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.5 21.325T615-443h121Zm114.182 245Q839-198 829.5-205.683 820-213.366 820-228v-512H308q-15 0-22.5-9.318t-7.5-20.5q0-11.182 7.5-20.682Q293-800 308-800h512q24 0 42 18t18 42v512q0 15-9.318 22.5t-20.5 7.5ZM563-483Zm-166 6ZM792-82.974 714-160H140q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h20l60 60h-80v520h514L47-827q-9-9.067-9-21.533Q38-861 47.053-870q9.052-9 21.5-9Q81-879 90-870l745 745q9 9 9 21t-9.053 21q-9.052 9-21.5 9Q801-74 792-82.974Z"
		/>
	</svg>
);
