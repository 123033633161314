import { ReactNode } from 'react';

export const symbol_text_increase: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m172-350-49 128q-4 10-12.556 16-8.555 6-19.444 6-19 0-29-15.5T59-248l192-488q5-11 14.158-17.5T285-760h30q11.447 0 20.224 6.5Q344-747 349-736l192 489q7 17-3.547 32t-28.515 15q-10.938 0-20.041-6.133Q479.793-212.267 476-223l-48-127H172Zm24-64h208L302-685h-4L196-414Zm534-36H630q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T630-510h100v-100q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T790-610v100h100q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T890-450H790v100q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T730-350v-100Z"
		/>
	</svg>
);
