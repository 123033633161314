import { ReactNode } from 'react';

export const symbol_child_care: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M584-502q-19 0-32-13t-13-32q0-19 13-32t32-13q19 0 32 13t13 32q0 19-13 32t-32 13Zm-209 0q-19 0-32-13t-13-32q0-19 13-32t32-13q19 0 32 13t13 32q0 19-13 32t-32 13Zm105 216q-56 0-102-30t-72-79h348q-26 49-72 79t-102 30Zm0 166q-74 0-139.5-28.5T226-226q-49-49-77.5-114.5T120-480q0-74 28.5-139.5T226-734q49-49 114.5-77.5T480-840q74 0 139.5 28.5T734-734q49 49 77.5 114.5T840-480q0 74-28.5 139.5T734-226q-49 49-114.5 77.5T480-120Zm0-60q123 0 211.5-87.5T780-478q0-123-87-215t-215-92h-18q-7 0-18 1-2 8-3.5 19t-1.5 19q0 25 16.5 41.5T495-688q12 0 20-1.5t15-1.5q10 0 17 5t7 15q0 16-15 24.5t-44 8.5q-46 0-77-31t-31-77q0-5 .5-13t2.5-13q-91 33-150.5 110T180-482q0 123 88.5 212.5T480-180Zm0-302Z"
		/>
	</svg>
);
