import { ReactNode } from 'react';

export const symbol_wifi_tethering_error: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M224-172q-9 9-22.5 9T180-173q-48.875-56.481-75.438-124.771Q78-366.062 78-440q0-83 31.5-156T195-723q54-54 127-85.5T478-840q101 0 189 47.5T812-660q7 11 5 22.5T804-619q-11 7-23 5t-19-13q-48-72-123-112.5T478-780q-142.375 0-241.188 98.812Q138-582.375 138-440q0 61.95 21 119.475Q180-263 222-216q9 10 10 22.5t-8 21.5Zm113-113q-9 9-22 9t-21-10q-25.76-32.181-40.88-70.59Q238-395 238-440q0-100 70-170t170-70q100 0 170 70t70 170q0 45-15 83.5T662-286q-8 10-20.565 10.5Q628.87-275 620-284q-9-9-9-22t8-23q18-23 28.5-51t10.5-60q0-75-52.5-127.5T478-620q-75 0-127.5 52.5T298-440q0 32 10.5 60t28.5 51q8 10 8.5 22.5T337-285Zm141-75q-33 0-56.5-23.5T398-440q0-33 23.5-56.5T478-520q33 0 56.5 23.5T558-440q0 33-23.5 56.5T478-360Zm370 215q-14 0-24.5-10.5T813-180q0-14 10.5-24.5T848-215q14 0 24.5 10.5T883-180q0 14-10.5 24.5T848-145Zm-30-172v-180q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T878-497v180q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T818-317Z"
		/>
	</svg>
);
