import { t } from '@transifex/native';
import { Fragment } from 'react';

import * as models from 'pkg/api/models';
import { formatPriceIntervalToText } from 'pkg/utils';
import { formatToCurrency } from 'pkg/i18n/formatters';
import { useCurrentGroup } from 'pkg/identity';

import AssetImage from 'components/AssetImage';

import * as Inputs from 'components/form/inputs';

import * as Table from 'design/table';

interface ProductRowProps {
	product: models.product.Product;
	selectedProductId: number;
	currency: string;
	onSelect: (productId: number) => void;
	showPrices: boolean;
	productCategory: models.productCategory.ProductCategory;
}

const ProductRow: React.FC<React.PropsWithChildren<ProductRowProps>> = ({
	product,
	currency,
	onSelect,
	selectedProductId,
	showPrices,
	productCategory,
}) => {
	let priceString = t('No active prices');
	const activePrices = models.product.getActiveProductPrices(product);

	if (activePrices?.length > 1) {
		priceString = `${activePrices.length} ${t('prices')}`;
	} else if (activePrices?.length === 1) {
		const price = activePrices[0];

		priceString = `${formatToCurrency(
			price.cost / 100,
			currency
		)}/${formatPriceIntervalToText(
			price.recurringInterval,
			price.recurringIntervalCount
		)}`;
	}

	const handleClick = () => onSelect(product.id);

	return (
		<Table.Row onClick={handleClick}>
			<Table.Cell>
				<Inputs.Control
					type="radio"
					standalone
					checked={selectedProductId === product.id}
				/>
			</Table.Cell>
			<Table.Cell emphasized>{product.name}</Table.Cell>
			<Table.Cell>
				{productCategory?.name ? productCategory.name : t('No category')}
			</Table.Cell>
			{showPrices && <Table.Cell>{priceString}</Table.Cell>}
		</Table.Row>
	);
};

interface ProductListProps {
	products: models.product.Product[];
	selectedProductId: number;
	onSelect: (productId: number) => void;
	showPrices?: boolean;
	onSort: (value: string, sortOrder: string) => void;
	sortBy?: string;
	sortOrder?: 'asc' | 'desc';
	productCategories: models.productCategory.ProductCategory[];
}

const emptyState = {
	title: t('No products found'),
	content: t('No products found'),
	image: <AssetImage src="img/missing-entities/post.svg" />,
};

const ProductList: React.FC<React.PropsWithChildren<ProductListProps>> = ({
	products,
	selectedProductId,
	onSelect,
	showPrices,
	onSort,
	sortBy,
	sortOrder,
	productCategories,
}) => {
	const group = useCurrentGroup();

	return (
		<Fragment>
			<Table.Table
				emptyState={emptyState}
				sortBy={sortBy}
				sortOrder={sortOrder}
				onSort={onSort}
				columns={[
					{ content: ' ', width: 'max-content' },
					{
						content: t('Title'),
						width: 'minmax(50%, 1fr)',
						sortKey: 'name',
					},
					{
						content: t('Category'),
						sortKey: 'category',
					},
					{
						content: t('Price(s)'),
						hide: !showPrices,
					},
				]}>
				{products.map((product) => {
					const productCategory = productCategories.find(
						(category) => category.id === product.productCategoryId
					);
					return (
						<ProductRow
							key={product.id}
							product={product}
							currency={group.currency}
							onSelect={onSelect}
							selectedProductId={selectedProductId}
							showPrices={showPrices}
							productCategory={productCategory}
						/>
					);
				})}
			</Table.Table>
		</Fragment>
	);
};

export default ProductList;
