import { ReactNode } from 'react';

export const symbol_picture_in_picture_mobile: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M800-140q0 24-18 42t-42 18H220q-24 0-42-18t-18-42v-680q0-24 18-42t42-18h520q24 0 42 18t18 42v680Zm-60 0v-680H220v680h520Zm0-680H220h520Zm-45 344v-275q0-12.75-8.625-21.375T665-781H468q-12.75 0-21.375 8.625T438-751v275q0 12.75 8.625 21.375T468-446h197q12.75 0 21.375-8.625T695-476Zm-60-30H498v-215h137v215Z"
		/>
	</svg>
);
