import { ReactNode } from 'react';

export const symbol_contactless_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M561-283q-12-5-17-15.5t0-22.5q8-19 12.5-37.5T565-396l50 50q-3 11-6.5 22.5T600-300q-5 11-16.5 16.5t-22.5.5Zm-131-55q-12-4-17-15t-1-23q8-25 12-51.5t4-52.5q0-14-1-28.5t-3-28.5l64 64q0 30-4.5 59T469-356q-4 11-15.5 17t-23.5 1Zm-134-56q-13-5-18.5-15.5T277-433q4-11 6-23t2-24q0-12-1-23.5t-5-22.5q-5-14 0-25t16-15q11-4 22 .5t15 15.5q6 17 9.5 34.5T345-480q0 18-3.5 35t-9.5 34q-4 11-14.5 16t-21.5 1Zm332-25-57-56q0-42-6-83t-21-80q-5-12 .5-22.5T561-676q12-5 23 .5t16 16.5q16 43 23.5 88t7.5 91q0 15-.5 30.5T628-419ZM480-80q-85 0-158-30.5T195-195q-54-54-84.5-127T80-480q0-68 20-128t56-110L48-827q-9-9-9-21.5t9-21.5q9-9 21.5-9t21.5 9l764 764q9 9 9 21.5T855-63q-9 9-21.5 9T812-63l-94-93q-50 36-110 56T480-80Zm0-60q55 0 104-15.5t91-43.5L199-675q-28 42-43.5 91T140-480q0 145 97.5 242.5T480-140Zm-43-297Zm116-116ZM275-824q47-28 99-42t106-14q80 0 153 30t130 87q57 57 87 130t30 153q0 54-13.5 105T827-277q-6 11-18 13.5t-23-4.5q-11-7-13-19.5t4-23.5q21-39 32-81.5t11-87.5q0-142-99-241t-241-99q-45 0-88 11t-82 33q-11 6-22.5 4T268-784q-8-10-6-22t13-18Z"
		/>
	</svg>
);
