import { ReactNode } from 'react';

export const symbol_language_french: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M189.825-280Q177-280 168.5-288.625T160-310v-340q0-12.75 8.625-21.375T190-680h220q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T410-620H220v120h150q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T370-440H220v130q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Zm360 0Q537-280 528.5-288.625T520-310v-340q0-12.75 8.625-21.375T550-680h190q24.75 0 42.375 17.625T800-620v120q0 24.935-17.5 42.468Q765-440 740-440h-26l63 116q8 15-.5 29.5T751-280q-8.345 0-15.172-4Q729-288 725-296l-78-144h-67v130q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM580-500h160v-120H580v120Z"
		/>
	</svg>
);
