import { ReactNode } from 'react';

export const symbol_bigtop_updates: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M130-560q0 63 21.5 121T214-333q8 8 8.5 19t-7.5 19q-8 8-18 6t-18-10q-48-54-73.5-121.5T80-560q0-72 25.5-139.5T179-821q8-8 18-9.5t18 6.5q8 8 7.5 18.5T214-787q-41 48-62.5 106T130-560Zm132 0q0 37 11.5 71.5T308-425q7 8 7.5 18.5T308-388q-8 8-18 7.5t-17-8.5q-29-36-45-80t-16-91q0-47 16-91t45-80q7-8 17-9t18 7q8 8 7.5 18.5T308-696q-23 29-34.5 64T262-560Zm188 410v-324q-28-9-44.5-33T389-560q0-38 26.5-64.5T480-651q38 0 64.5 26.5T571-560q0 29-16.5 53T510-474v324q0 13-8.5 21.5T480-120q-13 0-21.5-8.5T450-150Zm248-410q0-37-11.5-71.5T652-695q-7-8-7.5-18.5T652-732q8-8 18-7.5t17 8.5q29 36 45 80t16 91q0 47-16 91t-45 80q-7 8-16.5 8.5T653-388q-8-8-8-18.5t7-18.5q23-29 34.5-63.5T698-560Zm132 0q0-63-21.5-121T746-787q-8-8-8.5-19t7.5-19q8-8 18-6t18 10q48 54 73.5 121.5T880-560q0 72-25.5 139.5T781-299q-8 8-18 9.5t-18-6.5q-8-8-7.5-18.5T746-333q41-48 62.5-106T830-560Z"
		/>
	</svg>
);
