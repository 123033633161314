import { ReactNode } from 'react';

export const symbol_speed_1_25: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M700-277q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T700-337h140v-115H700q-12.75 0-21.375-8.625T670-482v-171q0-12.75 8.625-21.375T700-683h170q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T870-623H730v111h110q24 0 42 18t18 42v115q0 24-18 42t-42 18H700Zm-119 0H411q-12.75 0-21.375-8.625T381-307v-145q0-24 18-42t42-18h110v-111H411q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T411-683h140q24 0 42 18t18 42v111q0 24-18 42t-42 18H441v115h140q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T581-277Zm-289.175 0Q279-277 270.5-285.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5ZM143-623H90q-12.75 0-21.375-8.675Q60-640.351 60-653.175 60-666 68.625-674.5T90-683h83q12.75 0 21.375 8.625T203-653v346q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T143-307v-316Z"
		/>
	</svg>
);
