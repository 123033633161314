import { ReactNode } from 'react';

export const symbol_roundabout_right: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M290-150v-212q-91-11-150.5-79T80-600q0-100 70-170t170-70q91 0 159 59.5T558-630h208l-69-69q-9-9-9-21t9-21q9-9 21-9t21 9l120 120q5 5 7 10t2 11q0 6-2 11t-7 10L739-459q-9 9-21 9t-21-9q-9-9-9-21t9-21l69-69H559q-23 0-40.5-13.5T499-619q-8-69-59-115t-120-46q-75 0-127.5 52.5T140-600q0 69 45.5 119.5T300-422q22 3 36 20t14 40v212q0 13-8.5 21.5T320-120q-13 0-21.5-8.5T290-150Z"
		/>
	</svg>
);
