import { t } from '@transifex/native';

import * as Input from 'components/form/inputs';
import Section from 'components/form/Section';
import Column from 'components/layout/column';

interface VisibilityProps {
	data: { visibility: string };
	visibilities: string[];
}

const Visibilities = ({ data, visibilities }: VisibilityProps) => {
	const content: any = {
		public: {
			label: t('Public'),
			description: t(
				'The form will be published on your public form site and in your club lobby, open for anyone to submit'
			),
		},
		organization: {
			label: t('Club lobby'),
			description: t(
				'The form will be published in your club lobby, open for members of your club to submit'
			),
		},
		unlisted: {
			label: t('Unlisted'),
			description: t('Only available with a direct link'),
		},
	};

	return (
		<Section
			icon="visibility"
			title={`5. ${t('Visibility')}`}
			description={t('Who is this registration available to?')}>
			<Column>
				{visibilities.map((visibility: string) => (
					<Input.Control
						name="visibility"
						key={visibility}
						type="radio"
						value={visibility}
						defaultChecked={data.visibility === visibility}
						label={content[visibility].label}
						description={content[visibility].description}
					/>
				))}
			</Column>
		</Section>
	);
};

export default Visibilities;
