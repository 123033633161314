import { ReactNode } from 'react';

export const symbol_memory_alt: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M280.175-370q12.825 0 21.325-8.625T310-400v-160q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T250-560v160q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625Zm200 0q12.825 0 21.325-8.625T510-400v-160q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T450-560v160q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625Zm200 0q12.825 0 21.325-8.625T710-400v-160q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T650-560v160q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625ZM140-260h680v-440H140v440Zm0 0v-440 440Zm0 60q-24 0-42-18t-18-42v-440q0-24 18-42t42-18h70v-50q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T270-810v50h180v-50q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-810v50h180v-50q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T750-810v50h70q24 0 42 18t18 42v440q0 24-18 42t-42 18h-70v50q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T690-150v-50H510v50q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T450-150v-50H270v50q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T210-150v-50h-70Z"
		/>
	</svg>
);
