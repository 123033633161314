import { ReactNode } from 'react';

export const symbol_perm_data_setting: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M731-89q-23-7-42-18.5T656-132l-17 8q-11 5-22 1.5T599-136l-4-6q-7-11-5-22.5t12-19.5l15-12q-5-19-5-41.5t5-41.5l-15-12q-10-8-12-20t5-23l4-6q7-11 18.5-14t22.5 2l16 8q14-12 33-23.5t42-18.5l2-22q2-11 10.5-19t19.5-8h7q11 0 19 7.5t10 18.5l3 23q23 7 42 18.5t33 23.5l16-8q11-5 22.5-2t18.5 14l4 6q7 11 5 23t-12 20l-15 12q5 19 5 41.5t-5 41.5l15 12q10 8 12 19.5t-5 22.5l-4 7q-7 10-18 13t-22-2l-17-8q-14 13-33 24.5T802-89l-3 22q-2 11-10 18.5T770-41h-7q-11 0-19.5-8T733-68l-2-21Zm35-47q44 0 73-29t29-73q0-44-29-73t-73-29q-44 0-73 29t-29 73q0 44 29 73t73 29ZM152-80q-20 0-27.5-18.5T131-131l698-698q14-14 32.5-6.5T880-808v284q0 12-9 20.5t-21 8.5q-12 0-21-8.5t-9-20.5v-212L224-140h284q12 0 20.5 9t8.5 21q0 13-8.5 21.5T508-80H152Zm72-60 596-596-298 298-298 298Z"
		/>
	</svg>
);
