import { ReactNode } from 'react';

export const symbol_recycling: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m383-618 74-121-59-98q-12-20-34.5-20T329-837l-83 137q-7 11-3.5 22.5T257-659l85 52q11 6 22.5 3t18.5-14Zm372 298-73-122q-7-11-3.5-23t14.5-18l86-50q11-6 23-3.5t18 13.5l49 82q11 17 12 38t-9 39q-10 20-29.5 32T800-320h-45ZM614-66 501-179q-9-9-9-21t9-21l113-113q7-7 16.5-3.5T640-324v44h190l-58 116q-11 20-30 32t-42 12h-60v44q0 10-9.5 13.5T614-66Zm-361-54q-20 0-36.5-10.5T192-158q-8-16-7.5-33.5T194-224l34-56h142q13 0 21.5 8.5T400-250v100q0 13-8.5 21.5T370-120H253Zm-99-114L89-364q-9-18-8.5-38.5T92-441l16-27-37-22q-8-5-7-15.5T75-518l155-39q12-3 22.5 3.5T266-535l39 156q2 10-6 15.5t-16 .5l-38-23-91 152Zm511-349-155-39q-10-2-11.5-12.5T506-650l38-22-125-208h141q21 0 39.5 10.5T629-841l52 87 37-23q8-5 16 1t6 16l-39 155q-3 12-13.5 18.5T665-583Z"
		/>
	</svg>
);
