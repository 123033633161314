import { ReactNode } from 'react';

export const symbol_language_chinese_pinyin: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M321-464h77v-99h-77v99ZM149-260h-32q-6 0-10-4t-6-9q-2-8 2.846-14.5T117-294h18q6 0 10.5-4.667Q150-303.333 150-310v-121l-33 13q-7 2-13-1.5t-8-10.357q-2-6.172 1.5-11.657Q101-447 107-449l43-15v-95h-27.045Q116-559 111-563.8t-5-12q0-7.2 4.857-12.2 4.857-5 12.143-5h27v-70q0-6.955 5.032-11.977 5.033-5.023 12-5.023Q174-680 179-674.977q5 5.022 5 11.977v70h18.909Q210-593 215-588.2q5 4.8 5 12t-4.857 12.2q-4.857 5-12.143 5h-19v84l30-11q7-2 13 2t6 12q0 5-2 8.5t-7 5.5l-40 15v147q0 15-10 25.5T149-260Zm170-170q-5 50-24.5 93.5T239-263q-6 5-13.5 4.5t-12.885-6.367Q208-270 210-277.5q2-7.5 7-12.5 30-26 47-62t21-78h-39q-6.955 0-11.977-5.25-5.023-5.25-5.023-12t5.174-11.75q5.174-5 11.826-5h41v-99h-27q-6.8 0-11.9-5-5.1-5-5.1-11.857 0-6.857 5.1-12T260-597h199q6.955 0 11.977 5.032 5.023 5.033 5.023 12Q476-573 470.982-568q-5.017 5-11.964 5H432v99h42.123Q481-464 486-459q5 5 5 12t-4.921 12q-4.921 5-12.079 5h-42v153q0 6.955-5.032 11.977-5.033 5.023-12 5.023Q408-260 403-265.023q-5-5.022-5-11.977v-153h-79Zm82.882-166q-5.882-2-7.382-8t1.5-11q7-11 13-21t12-21q2.909-5.824 9.455-8.412Q437-668 442.885-666q5.884 2 8.5 8 2.615 6-.385 12-6 11.243-12 21.622Q433-614 425-603q-4 5-10.618 7-6.617 2-12.5 0ZM298-611q-7-10-13.632-18.667Q277.737-638.333 270-647q-5-5-3.095-10.87 1.905-5.869 8-9 6.095-3.13 13.616-1.201Q296.042-666.143 300-661q7 8.571 13 17.143 6 8.571 12 17.857 4 5 2.5 11t-6.5 9q-5 3-12 1.5t-11-6.5Zm406 291q32.494 0 63.247-15T824-377.508V-499q-29 4-51 8.5T731-480q-50 15.782-75 39.455-25 23.672-25 55.236Q631-356 650.909-338q19.909 18 53.091 18Zm-14 51q-54 0-86.5-31T571-383q0-50 34-83.5T709.719-520Q732-526 760.5-531.5T824-542q0-56-18.5-82.5T739-651q-22 0-44 6.5T644-622q-10 5-19.849 2.667Q614.303-621.667 609-631q-5-10-2.5-20t12.5-15q31-17 64.5-27t67.5-10q69 0 101 42.5T884-540v237q0 10.606-7.078 17.803-7.079 7.197-17.51 7.197h-3.726q-9.686 0-16.186-6.5T831-300l-3-27q-30 28-66.5 43T690-269Z"
		/>
	</svg>
);
