import styled, { DefaultTheme, css } from 'styled-components';

import * as palette from 'pkg/config/palette';

const Toggle = styled.div<{ active: boolean; theme?: DefaultTheme }>`
	width: 34px;
	height: 20px;
	border-radius: 20px;
	background: ${palette.gray[400]};
	box-shadow: ${({ active }) =>
		active
			? `inset 0 0 15px 15px ${palette.blue[500]}`
			: `inset 0 0 1px 1px ${palette.gray[400]}`};
	position: relative;
	display: inline-block;
	transition:
		background 100ms ease-in-out,
		box-shadow 100ms ease-in-out;

	&::before {
		content: '';
		box-sizing: border-box;
		position: absolute;
		height: 18px;
		width: 18px;
		border-radius: 9px;
		background: ${palette.white};
		top: 1px;
		left: 1px;
		z-index: 1;
		transition:
			transform 150ms ease-in-out,
			left 150ms ease-in-out;
	}

	${({ active }) =>
		active &&
		css`
			&::before {
				transform: translateX(-100%);
				left: calc(100% - 1px);
			}
		`}

	${({ theme, active }) =>
		theme.darkMode &&
		css`
			background: ${palette.gray[600]};
			box-shadow: ${active
				? `inset 0 0 15px 15px ${palette.blue[500]}`
				: `inset 0 0 1px 1px ${palette.gray[600]}`};
		`}
`;

export default Toggle;
