import { ReactNode } from 'react';

export const symbol_format_list_numbered_rtl: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M710-80q-13 0-21.5-8.5T680-110q0-13 8.5-21.5T710-140h70v-30h-30q-13 0-21.5-8.5T720-200q0-13 8.5-21.5T750-230h30v-30h-70q-13 0-21.5-8.5T680-290q0-13 8.5-21.5T710-320h90q17 0 28.5 11.5T840-280v40q0 17-11.5 28.5T800-200q17 0 28.5 11.5T840-160v40q0 17-11.5 28.5T800-80h-90Zm-7-280q-9 0-16-7t-7-16v-87q0-17 11.5-28.5T720-510h60v-30h-70q-13 0-21.5-8.5T680-570q0-13 8.5-21.5T710-600h90q17 0 28.5 11.5T840-560v70q0 17-11.5 28.5T800-450h-60v30h70q13 0 21.5 8.5T840-390q0 13-8.5 21.5T810-360H703Zm67-280q-13 0-21.5-8.5T740-670v-150h-30q-13 0-21.5-8.5T680-850q0-13 8.5-21.5T710-880h68q9 0 15.5 6.5T800-858v188q0 13-8.5 21.5T770-640ZM150-209q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-269h411q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T561-209H150Zm0-243q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-512h411q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T561-452H150Zm0-243q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-755h411q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T561-695H150Z"
		/>
	</svg>
);
