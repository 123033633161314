import { ReactNode } from 'react';

export const symbol_trail_length_medium: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M305-280q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T305-340h193q-21-21-36-49.5T441.809-450H230q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T230-510h211.809Q447-539 461.5-569.5T498-620H310q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T310-680h330q83 0 141.5 58.5T840-480q0 83-58.5 141.5T640-280H305Zm334.765-60Q698-340 739-380.765q41-40.764 41-99Q780-538 739.235-579q-40.764-41-99-41Q582-620 541-579.235q-41 40.764-41 99Q500-422 540.765-381q40.764 41 99 41ZM150-280q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-340h65q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T215-280h-65Zm490-200Z"
		/>
	</svg>
);
