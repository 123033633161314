export default {
	name: 'field',
	objects: [
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M3.73353 9.36214V14.6932H4.4553C4.67075 14.6932 4.87028 14.625 5.03353 14.5091C5.28876 14.3279 5.4553 14.03 5.4553 13.6932V10.3621C5.4553 10.0253 5.28876 9.72736 5.03353 9.54616C4.87028 9.43027 4.67075 9.36214 4.4553 9.36214H3.73353ZM3.88551 15.9932H4.4553C5.72556 15.9932 6.7553 14.9634 6.7553 13.6932V10.3621C6.7553 9.09188 5.72556 8.06214 4.4553 8.06214H3.88551V7.42688C3.88551 7.04028 4.19891 6.72688 4.58551 6.72688H19.4145C19.8011 6.72688 20.1145 7.04028 20.1145 7.42688V8.06209H19.6118C18.3416 8.06209 17.3118 9.09184 17.3118 10.3621V13.6931C17.3118 14.9634 18.3416 15.9931 19.6118 15.9931H20.1145V16.8459C20.1145 17.2325 19.8011 17.5459 19.4145 17.5459H4.58551C4.19891 17.5459 3.88551 17.2325 3.88551 16.8459V15.9932ZM2.58551 7.42688C2.58551 6.32231 3.48094 5.42688 4.58551 5.42688H19.4145C20.5191 5.42688 21.4145 6.32231 21.4145 7.42688V16.8459C21.4145 17.9505 20.5191 18.8459 19.4145 18.8459H4.58551C3.48094 18.8459 2.58551 17.9505 2.58551 16.8459V7.42688ZM20.3336 14.6931V9.36209H19.6118C19.3964 9.36209 19.1969 9.43023 19.0336 9.54612C18.7784 9.72731 18.6118 10.0253 18.6118 10.3621V13.6931C18.6118 14.0299 18.7784 14.3279 19.0336 14.5091C19.1969 14.625 19.3964 14.6931 19.6118 14.6931H20.3336Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M11.35 18.2573V6.01544H12.65V18.2573H11.35Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M12.0001 14.0142C13.0372 14.0142 13.878 13.1734 13.878 12.1362C13.878 11.0991 13.0372 10.2583 12.0001 10.2583C10.9629 10.2583 10.1221 11.0991 10.1221 12.1362C10.1221 13.1734 10.9629 14.0142 12.0001 14.0142ZM12.0001 15.3142C13.7552 15.3142 15.178 13.8914 15.178 12.1362C15.178 10.3811 13.7552 8.95831 12.0001 8.95831C10.2449 8.95831 8.82214 10.3811 8.82214 12.1362C8.82214 13.8914 10.2449 15.3142 12.0001 15.3142Z',
			},
		},
	],
};
