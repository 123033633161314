import styled from 'styled-components';

import Icon, { IconName } from 'components/icon';

import * as iconStyles from 'components/icon/styles.css';

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 90px;
	height: 90px;
	background-color: var(--palette-blue-100);
	color: var(--palette-blue-500);
	justify-self: center;
	border-radius: 100%;

	.${iconStyles.icon} {
		width: 50px;
		height: 50px;
	}
`;

const SideBarIcon: React.FC<React.PropsWithChildren<{ name: IconName }>> = ({
	name,
}) => {
	return (
		<Wrapper>
			<Icon name={name} />
		</Wrapper>
	);
};

export default SideBarIcon;
