import { ReactNode } from 'react';

export const symbol_orthopedics: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-72q-88 0-150.5-20.5T264-115q-11-5-17.5-15t-6.5-22v-50h-50q-13 0-21.5-8.5T160-232v-60q0-13 8.5-21.5T190-322h50v-100h-50q-13 0-21.5-8.5T160-452v-60q0-13 8.5-21.5T190-542h50v-100h-50q-13 0-21.5-8.5T160-672v-60q0-13 8.5-21.5T190-762h50v-70q0-22 18-33t38-3q1 0 55 18t129 18q75 0 129-18t55-18q20-8 38 3t18 33v70h50q13 0 21.5 8.5T800-732v60q0 13-8.5 21.5T770-642h-50v100h50q13 0 21.5 8.5T800-512v60q0 13-8.5 21.5T770-422h-50v100h50q13 0 21.5 8.5T800-292v60q0 13-8.5 21.5T770-202h-50v50q0 12-6.5 22T696-115q-3 2-65.5 22.5T480-72Zm0-527q56 0 103.5-9.5T660-628v-175q-38 11-86 21t-94 10q-46 0-94-10t-86-21v175q29 10 77 19.5t103 9.5Zm0 234q56 0 103.5-10t76.5-20v-170q-35 9-80.5 17.5T480-539q-54 0-99.5-8T300-565v170q29 10 76.5 20T480-365Zm0 233q54 0 102.5-11.5T660-165v-166q-35 10-81 18t-99 8q-53 0-99-8t-81-18v166q29 10 78 21.5T480-132Z"
		/>
	</svg>
);
