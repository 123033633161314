import { ReactNode } from 'react';

export const symbol_line_axis: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m117-254 234-234q17-17 42.5-17.5T436-488l118 118 93-104-249-238-235 235q-10 10-23 10t-23-10q-10-10-10-23t10-23l236-236q17-17 41-17t42 17l251 239 145-162q8-9 20.5-10t21.5 8q8 8 8.5 20t-7.5 21L730-479l141 135q9 9 9 21.5t-9 21.5q-9 9-21 8.5t-21-8.5L690-433l-94 106q-17 20-43 20t-44-18L394-439 163-208q-10 10-23 10t-23-10q-10-10-10-23t10-23Z"
		/>
	</svg>
);
