import {
	ReactNode,
	createContext,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useMediaQuery } from 'react-responsive';
import { t } from '@transifex/native';

import { toMedium } from 'pkg/config/breakpoints';

import * as flashActions from 'pkg/actions/flashes';

import * as json from 'pkg/json';
import * as routes from 'pkg/router/routes';
import { useQueryState } from 'pkg/hooks/query-state';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useEndpoint } from 'pkg/api/use_endpoint';
import { useCollection } from 'pkg/api/use_collection';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { SlotConfig } from 'pkg/api/models/schedule';
import DateTime from 'pkg/datetime';
import { cssVarList } from 'pkg/css/utils';
import { getConflictingItems } from 'pkg/api/models/schedule_item';
import {
	MessageType,
	useWebSocketClient,
	useWebSocketSubscription,
} from 'pkg/websocket';
import { pushState } from 'pkg/router/state';
import { useCurrentGroup, useCurrentOrganization } from 'pkg/identity';

import CreateScheduleItem from 'routes/scheduling/templates/modals/CreateScheduleItem';
import {
	ConflictingItems,
	getWeekDaysOrder,
	resourceConflictMap,
} from 'routes/scheduling/utils';
import SlotConfigModal from 'routes/scheduling/templates/modals/SlotConfig';
import TemplateHeader from 'routes/scheduling/templates/single/header';
import { GroupGrid } from 'routes/scheduling/templates/single/groups/GroupGrid';
import ScheduleFilters, {
	FilterState,
	defaultFilters,
} from 'routes/scheduling/templates/filters';
import ResourceGrid from 'routes/scheduling/templates/single/resources/ResourceGrid';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import * as ActionBar from 'components/layout/ActionBar';
import { Spinner } from 'components/loaders/spinner';
import * as SchedulingLayout from 'components/scheduling/PageLayout';
import SmallScreenContent from 'components/layout/SmallScreenContent';
import SelectView, { SchedulingViews } from 'components/scheduling/SelectView';
import * as headerRowCss from 'components/scheduling/grid/header-row/styles.css';

import * as ContextMenu from 'design/context_menu';
import Button from 'design/button';

import * as css from './styles.css';

interface ITemplateContext {
	showSlots: boolean;
	isSmallScreen: boolean;
	view: SchedulingViews;

	schedule: models.schedule.Schedule;

	scheduleItems: models.scheduleItem.ScheduleItem[];
	resources: models.resource.Resource[];
	groups: models.group.Group[];
	flattenedGroups: models.group.Group[];
	search: string;
	filters?: FilterState;

	convertedSlotTimes: SlotConfig;
	conflicts: ConflictingItems;

	onBeforeScheduleItemSave: (
		scheduleItems: models.scheduleItem.ScheduleItem[]
	) => void;
	onBeforeScheduleItemUpdate: (
		scheduleItem: models.scheduleItem.ScheduleItem
	) => void;
	onSuccessScheduleItemSave: () => void;
	onFailScheduleItemSave: (
		scheduleItem: models.scheduleItem.ScheduleItem
	) => void;
	onRemoveScheduleItems: (
		scheduleItems: models.scheduleItem.ScheduleItem[]
	) => void;
}

const DefaultTemplateContext: ITemplateContext = {
	schedule: null,
	view: SchedulingViews.Group,
	scheduleItems: [],
	resources: [],
	groups: [],
	flattenedGroups: [],
	showSlots: true,
	isSmallScreen: false,
	search: '',
	convertedSlotTimes: { sameSlotsAllDays: true, days: [], times: {} },
	conflicts: {},
	onBeforeScheduleItemSave: () => {
		return;
	},
	onBeforeScheduleItemUpdate: () => {
		return;
	},
	onSuccessScheduleItemSave: () => {
		return;
	},
	onFailScheduleItemSave: () => {
		return;
	},
	onRemoveScheduleItems: () => {
		return;
	},
};

export const TemplateContext = createContext<ITemplateContext>(
	DefaultTemplateContext
);

export function useScheduleTemplateContext(): ITemplateContext {
	return useContext(TemplateContext);
}

interface SingleScheduleProps {
	groupId: number;
	id: string;
}

const SingleSchedule = ({ groupId, id }: SingleScheduleProps) => {
	const qs = useQueryState();
	const view: SchedulingViews =
		(qs.get('view') as SchedulingViews) ||
		(sessionStorage.getItem('schedulingView') as SchedulingViews);

	const isGroupView = view === SchedulingViews.Group;
	const isSmallScreen = useMediaQuery({ maxWidth: toMedium });

	const setGroupView = () => {
		qs.set('view', SchedulingViews.Group);
		qs.commit();
	};

	useComponentDidMount(() => {
		if (!sessionStorage.getItem('schedulingView')) {
			setGroupView();
		} else {
			qs.set('view', sessionStorage.getItem('schedulingView'));
			qs.commit();
		}
	});

	const {
		record: schedule,
		isLoading: scheduleLoading,
		refresh: refreshSchedule,
		replaceRecord,
	} = useEndpoint<models.schedule.Schedule>(
		endpoints.Schedule.Show(Number.parseInt(id, 10))
	);

	// Convert the slot times to local time strings, I save this in the context
	// so I can use it in each slot to display local time.
	// All other calculations that has to do with slot times is handled in a 24h format
	const convertedSlotTimes = useMemo(() => {
		const slotConfig = models.schedule.parseSlots(schedule);

		// I use this random dates just to figure out at what time of the day
		// the slot was created
		slotConfig.days.forEach((day, index) => {
			Object.entries(day).forEach(([key, value]) => {
				slotConfig.days[index][key] = [
					new DateTime(
						new Date('1970-01-01T' + value[0] + 'Z')
					).toLocaleTimeString({
						timeZone: 'UTC',
						hour: 'numeric',
						minute: 'numeric',
					}),
					new DateTime(
						new Date('1970-01-01T' + value[1] + 'Z')
					).toLocaleTimeString({
						timeZone: 'UTC',
						hour: 'numeric',
						minute: 'numeric',
					}),
				];
			});
		});

		Object.entries(slotConfig.times).forEach(([key, value]) => {
			slotConfig.times[key] = [
				new DateTime(
					new Date('1970-01-01T' + value[0] + 'Z')
				).toLocaleTimeString({
					timeZone: 'UTC',
					hour: 'numeric',
					minute: 'numeric',
				}),
				new DateTime(
					new Date('1970-01-01T' + value[1] + 'Z')
				).toLocaleTimeString({
					timeZone: 'UTC',
					hour: 'numeric',
					minute: 'numeric',
				}),
			];
		});

		return slotConfig;
	}, [schedule]);

	const resourceCollection = useCollection<models.resource.Resource>(
		endpoints.Resource.Index(),
		{
			queryParams: new URLSearchParams({
				group_id: groupId.toString(),
			}),
		}
	);
	const { records: resourceCategories } =
		useCollection<models.resourceCategory.ResourceCategory>(
			endpoints.ResourceCategory.Index(),
			{
				queryParams: new URLSearchParams({
					group_id: groupId.toString(),
				}),
			}
		);

	const sortedResources = models.resource.buildResourceTree(
		resourceCollection.records,
		resourceCategories,
		true
	);

	const group = useCurrentGroup();
	const organization = useCollection<models.group.Group>(
		endpoints.Groups.ShowChildren(groupId),
		{
			queryParams: new URLSearchParams({
				recursive: 'true',
			}),
		}
	);

	const flattenedGroups = models.group.buildGroupTree(
		group,
		organization.records,
		true
	) as models.group.Group[];
	// We need non flattened groups for the select groups filter
	const groups = models.group.buildGroupTree(group, organization.records);

	const [scheduleItems, setScheduleItems] = useState<
		models.scheduleItem.ScheduleItem[]
	>([]);

	const conflictMap = useMemo(() => {
		return resourceConflictMap(sortedResources);
	}, [sortedResources.length]);
	const conflicts = getConflictingItems(scheduleItems, conflictMap);
	const org = useCurrentOrganization();

	const [modal, setModal] = useState('');
	const [showSlots, setShowSlots] = useState(true);

	const ws = useWebSocketClient();

	const showCreateScheduleItemModal = () => setModal('scheduleItem');
	const showSlotConfigModal = () => setModal('slotConfig');
	const hideModal = () => setModal('');

	useEffect(() => {
		if (schedule.scheduleItems?.length > 0) {
			setScheduleItems(schedule.scheduleItems);
		}
	}, [schedule.id]);

	useEffect(() => {
		if (view) {
			sessionStorage.setItem('schedulingView', view);
		}
	}, [view]);

	useWebSocketSubscription(`p.schedule.${schedule.id}`, {
		dependencies: [schedule.id],
	});

	ws.onMessage<models.schedule.Schedule>(
		MessageType.ScheduleUpdated,
		(message) => {
			replaceRecord(message.data);
		}
	);

	ws.onMessage<models.scheduleItem.ScheduleItem>(
		MessageType.ScheduleItemCreated,
		async (message) => {
			if (!message.data.clientId) {
				return;
			}

			setScheduleItems((items) => {
				const arr = [...items];
				const item = items.find((i) => i.clientId === message.data.clientId);
				const index = items.findIndex(
					(i) => i.clientId === message.data.clientId
				);
				const newItem = { ...item, ...message.data };

				if (index !== -1) {
					arr.splice(index, 1, newItem);
				}

				return arr;
			});
		}
	);

	ws.onMessage<models.scheduleItem.ScheduleItem>(
		MessageType.ScheduleItemUpdated,
		(message) => {
			setScheduleItems((items) => {
				const item = message.data;
				const arr = [...items];
				const index = items.findIndex((i) => i.id === item.id);
				arr[index] = item;

				return arr;
			});
		}
	);

	ws.onMessage<models.scheduleItem.ScheduleItem>(
		MessageType.ScheduleItemDeleted,
		(message) => {
			setScheduleItems((items) => {
				const arr = [...items];
				const filteredItems = arr.filter((item) => item.id !== message.data.id);

				return filteredItems;
			});
		}
	);

	const handleShowSlots = () => {
		setShowSlots(!showSlots);
	};

	const onBeforeScheduleItemSave = async (
		newScheduleItems: models.scheduleItem.ScheduleItem[]
	) => {
		await setScheduleItems([...scheduleItems, ...newScheduleItems]);
	};

	const onBeforeScheduleItemUpdate = async (
		newScheduleItem: models.scheduleItem.ScheduleItem
	) => {
		const arr = [...scheduleItems];
		const index = arr.findIndex((i) => i.id === newScheduleItem.id);
		arr[index] = newScheduleItem;
		setScheduleItems(arr);
	};

	const onSuccessScheduleItemSave = () => {
		flashActions.show({
			title: 'Successfully created schedule item',
		});
	};

	const onFailScheduleItemSave = (
		scheduleItem: models.scheduleItem.ScheduleItem
	) => {
		setScheduleItems((prev) =>
			prev.filter((s) => s.clientId === scheduleItem.clientId)
		);

		flashActions.show({
			title: 'Something went wrong',
		});
	};

	const onRemoveScheduleItems = (
		removeScheduleItems: models.scheduleItem.ScheduleItem[]
	) => {
		const arr = [...scheduleItems];
		const ids = removeScheduleItems.map((s) => s.id);
		const filteredItems = arr.filter((item) => !ids.includes(item.id));

		setScheduleItems(filteredItems);
	};

	if (scheduleLoading || resourceCollection.isLoading) {
		return <Spinner />;
	}

	const scheduleDays = getWeekDaysOrder(schedule.startingDay, schedule.days);

	if (!schedule) {
		return null;
	}

	const selectViewContextMenu = <SelectView />;

	const goToPrintableUrl = (): void => {
		const href = routes.Templates.Print(
			org.id,
			group.id,
			Number.parseInt(id, 10)
		);

		pushState(href, qs.toQueryString());

		return;
	};

	const actionBar = (
		<ActionBar.FilterBar>
			<ActionBar.PrimaryAction>
				<ActionBar.Search
					placeholder={t('Search')}
					value={qs.get('search') as string}
				/>
			</ActionBar.PrimaryAction>
			<ScheduleFilters
				resources={resourceCollection.records}
				groups={groups}
				scheduleItems={scheduleItems}
				groupId={groupId}
			/>
			<SmallScreen>{selectViewContextMenu}</SmallScreen>
			<LargeScreen>
				<Button icon="print" onClick={goToPrintableUrl}>
					{t('Print')}
				</Button>
			</LargeScreen>
			<ContextMenu.Menu
				toggleWith={
					<Button label={t('Slots')} icon="schedule" active={showSlots} />
				}>
				<ContextMenu.ControlItem
					onClick={handleShowSlots}
					type="checkbox"
					icon="visibility"
					checked={showSlots}>
					{t('Show slots')}
				</ContextMenu.ControlItem>
				<ContextMenu.Item onClick={showSlotConfigModal}>
					<ContextMenu.ItemIcon name="settings" />
					{t('Configure slots')}
				</ContextMenu.Item>
			</ContextMenu.Menu>
			<Button
				secondary
				icon="add"
				label={t('New booking')}
				onClick={showCreateScheduleItemModal}
			/>
		</ActionBar.FilterBar>
	);

	const content = (
		<SchedulingLayout.Container>
			<div className={css['print-title']}>{schedule.title}</div>
			<div
				style={cssVarList({
					cols: schedule.days,
				})}
				className={headerRowCss.wrapper}>
				<LargeScreen>
					<div className={headerRowCss.day}>
						<LargeScreen>{selectViewContextMenu}</LargeScreen>
					</div>
				</LargeScreen>
				{scheduleDays.map((day) => (
					<div key={day} className={headerRowCss.day}>
						{day}
					</div>
				))}
			</div>
			{isGroupView ? <GroupGrid /> : <ResourceGrid />}
		</SchedulingLayout.Container>
	);

	return (
		<TemplateContext.Provider
			value={{
				view,
				scheduleItems,
				resources: sortedResources,
				flattenedGroups,
				groups,
				search: '',
				schedule,
				showSlots,
				onBeforeScheduleItemSave,
				onSuccessScheduleItemSave,
				onBeforeScheduleItemUpdate,
				onFailScheduleItemSave,
				onRemoveScheduleItems,
				convertedSlotTimes,
				isSmallScreen,
				conflicts,
			}}>
			<TemplateHeader
				schedule={schedule}
				scheduleItems={scheduleItems}
				refresh={refreshSchedule}
				groupId={groupId}
			/>
			<LargeScreen>
				<LargeScreenContent.Wrapper disableScroll>
					<SchedulingLayout.ContentWrapper>
						{actionBar}
						{content}
					</SchedulingLayout.ContentWrapper>
				</LargeScreenContent.Wrapper>
			</LargeScreen>
			<SmallScreen>
				<SmallScreenContent noPadding noBorderRadius noScroll>
					{actionBar}
					{content}
				</SmallScreenContent>
			</SmallScreen>
			{modal === 'scheduleItem' && (
				<CreateScheduleItem
					groups={groups}
					resources={sortedResources}
					scheduleItems={scheduleItems}
					schedule={schedule}
					onBeforeSave={onBeforeScheduleItemSave}
					hideModal={hideModal}
					onSuccess={onSuccessScheduleItemSave}
					onFail={onFailScheduleItemSave}
				/>
			)}
			{modal === 'slotConfig' && (
				<SlotConfigModal
					refreshSchedule={refreshSchedule}
					hideModal={hideModal}
					schedule={schedule}
				/>
			)}
		</TemplateContext.Provider>
	);
};

export default SingleSchedule;

interface ScheduleTemplateProviderProps {
	id: string;
	groupId: number;
	children: ReactNode | ReactNode[];
}

export function ScheduleTemplateProvider({
	id,
	groupId,
	children,
}: ScheduleTemplateProviderProps): JSX.Element {
	const qs = useQueryState();
	const view: SchedulingViews =
		(qs.get('view') as SchedulingViews) ||
		(sessionStorage.getItem('schedulingView') as SchedulingViews);

	const isSmallScreen = useMediaQuery({ maxWidth: toMedium });

	const qsFilters = qs.get('schedulingFilters', '').toString();
	const search = qs.get('search', '').toString().toLowerCase();
	const filters = json.parse<FilterState>(qsFilters) || defaultFilters;

	const setGroupView = () => {
		qs.set('view', SchedulingViews.Group);
		qs.commit();
	};

	useComponentDidMount(() => {
		if (!sessionStorage.getItem('schedulingView')) {
			setGroupView();
		} else {
			qs.set('view', sessionStorage.getItem('schedulingView'));
			qs.commit();
		}
	});

	const { record: schedule, isLoading } = useEndpoint<models.schedule.Schedule>(
		endpoints.Schedule.Show(Number.parseInt(id, 10))
	);

	const resourceCollection = useCollection<models.resource.Resource>(
		endpoints.Resource.Index(),
		{
			queryParams: new URLSearchParams({
				group_id: groupId.toString(),
			}),
		}
	);
	const { records: resourceCategories } =
		useCollection<models.resourceCategory.ResourceCategory>(
			endpoints.ResourceCategory.Index(),
			{
				queryParams: new URLSearchParams({
					group_id: groupId.toString(),
				}),
			}
		);

	const sortedResources = models.resource.buildResourceTree(
		resourceCollection.records,
		resourceCategories,
		true
	);

	const group = useCurrentGroup();

	const organization = useCollection<models.group.Group>(
		endpoints.Groups.ShowChildren(groupId),
		{
			queryParams: new URLSearchParams({
				recursive: 'true',
			}),
		}
	);

	const flattenedGroups = models.group.buildGroupTree(
		group,
		organization.records,
		true
	) as models.group.Group[];

	// We need non flattened groups for the select groups filter
	const groups = models.group.buildGroupTree(group, organization.records);

	const scheduleItems = schedule?.scheduleItems ?? [];

	const conflictMap = useMemo(() => {
		return resourceConflictMap(sortedResources);
	}, [sortedResources.length]);

	const conflicts = getConflictingItems(scheduleItems, conflictMap);

	if (isLoading) {
		return <Spinner />;
	}

	return (
		<TemplateContext.Provider
			value={{
				...DefaultTemplateContext,
				view,
				scheduleItems,
				resources: sortedResources,
				flattenedGroups,
				groups,
				search,
				filters,
				schedule,
				showSlots: true,
				isSmallScreen,
				conflicts,
			}}>
			{children}
		</TemplateContext.Provider>
	);
}
