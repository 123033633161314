import { AUTH_LOGOUT_SUCCESS } from 'pkg/actions/action-types';

const initialState = {
	entities: {},
	reports: {},
};

const reports = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_EVENT_REPORTS': {
			const { reports } = action;
			const newReports = {};

			for (let i = 0; i < reports.length; i++) {
				const eventReport = reports[i];
				newReports[eventReport.userId] = {};
				newReports[eventReport.userId] = eventReport;
			}

			return {
				...state,
				entities: {
					...state.entities,
					...newReports,
				},
			};
		}

		case 'SET_SINGLE_EVENT_REPORT': {
			if (!state.reports[action.userId]) {
				state.reports[action.userId] = [];
			}

			state.reports[action.userId] = action.eventReport;

			return {
				...state,
			};
		}

		case AUTH_LOGOUT_SUCCESS:
			return initialState;

		default: {
			return state;
		}
	}
};

export default reports;
