import { ReactNode } from 'react';

export const symbol_csv: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M221-357h102q10.833 0 17.917-7.116 7.083-7.117 7.083-18Q348-393 340.917-400q-7.084-7-17.917-7h-85v-146h85q10.833 0 17.917-7.116 7.083-7.117 7.083-18Q348-589 340.917-596q-7.084-7-17.917-7H221q-14.025 0-23.512 9.775Q188-583.45 188-569v179q0 14.025 9.488 23.513Q206.975-357 221-357Zm191 0h112q14.025 0 23.513-9.487Q557-375.975 557-390v-78q0-13-9.487-22-9.488-9-23.513-9h-87v-54h95q10.833 0 17.917-7.116 7.083-7.117 7.083-18Q557-589 549.917-596q-7.084-7-17.917-7H420q-14.025 0-23.513 9.487Q387-584.025 387-570v78q0 14 9.487 23.5Q405.975-459 420-459h87v52h-95q-10.833 0-17.917 7.116-7.083 7.117-7.083 18Q387-371 394.083-364q7.084 7 17.917 7Zm275-63-42-165q-2-8-8.346-13t-14.808-5Q610-603 602.5-593t-4.5 21l54 195q2 9 10 14.5t17.444 5.5h15.112q9.444 0 16.944-5.5Q719-368 721-376l60-197q4-11.351-3.446-20.676Q770.108-603 758.378-603q-8.378 0-14.878 4.5T735-586l-48 166ZM140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm0-60h680v-520H140v520Zm0 0v-520 520Z"
		/>
	</svg>
);
