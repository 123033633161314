import { ReactNode } from 'react';

export const symbol_pageless: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M380-80H100q-24.75 0-42.375-17.625T40-140v-240q0-12.75 8.675-21.375Q57.351-410 70.175-410 83-410 91.5-401.375T100-380v240h280q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q410-97 401.375-88.5T380-80Zm200-60h280v-240q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T920-380v240q0 24.75-17.625 42.375T860-80H580q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T580-140ZM40-580v-240q0-24.75 17.625-42.375T100-880h280q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T380-820H100v240q0 12.75-8.675 21.375Q82.649-550 69.825-550 57-550 48.5-558.625T40-580Zm820 0v-240H580q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T580-880h280q24.75 0 42.375 17.625T920-820v240q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T860-580Z"
		/>
	</svg>
);
