import { ReactNode } from 'react';

export const symbol_switch_account: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M539.882-480Q589-480 623.5-514.382q34.5-34.383 34.5-83.5Q658-647 623.618-681.5q-34.383-34.5-83.5-34.5Q491-716 456.5-681.618q-34.5 34.383-34.5 83.5Q422-549 456.382-514.5q34.383 34.5 83.5 34.5ZM260-259q51-65 124-103t156-38q83 0 156 38t124 103v-561H260v561Zm0 59q-24.75 0-42.375-17.625T200-260v-560q0-24.75 17.625-42.375T260-880h560q24.75 0 42.375 17.625T880-820v560q0 24.75-17.625 42.375T820-200H260ZM140-80q-24.75 0-42.375-17.625T80-140v-540q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-680v540h540q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q710-97 701.375-88.5T680-80H140Zm400-460q-24 0-41-17t-17-41q0-24 17-41t41-17q24 0 41 17t17 41q0 24-17 41t-41 17ZM353-260h374q-36-39-84.5-59.5T540-340q-54 0-102.5 20.5T353-260Zm187-280Z"
		/>
	</svg>
);
