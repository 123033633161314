import { ReactNode } from 'react';

export const symbol_leak_add: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M555-811q-11 163-125 278T154-405q-14 1-24-7.5T120-434q0-12 8.5-21t20.5-10q138-11 236.5-108.5T495-809q1-13 9.5-22t21.5-9q12 0 21 8.5t8 20.5Zm-120 2q-11 114-91.5 193.5T149-525q-12 1-20.5-7.5T120-554q0-12 7.5-20.5T147-585q89-10 152.5-73.5T374-811q2-12 11-20.5t21-8.5q13 0 22 9t7 22Zm-1 689q-13 0-21.5-10t-7.5-24q13-162 128-276t278-125q12-1 20.5 8t8.5 21q0 13-9 21.5t-22 9.5q-138 11-235.5 109.5T465-149q-1 12-10 20.5t-21 8.5Zm120 0q-13 0-21.5-8.5T525-149q11-114 90.5-194.5T809-435q13-2 22 7t9 22q0 12-8.5 21T811-374q-89 11-152.5 74.5T585-147q-2 12-10.5 19.5T554-120ZM283-760q0 34-24.5 58.5T200-677q-34 0-58-24.5T118-760q0-34 24-58t58-24q34 0 58.5 24t24.5 58Zm559 560q0 34-24 58t-58 24q-34 0-58.5-24T677-200q0-34 24.5-58.5T760-283q34 0 58 24.5t24 58.5Z"
		/>
	</svg>
);
