import { ReactNode } from 'react';

export const symbol_replay_30: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M408-310H306q-10.833 0-17.917-7.116-7.083-7.117-7.083-18Q281-346 288.083-353q7.084-7 17.917-7h96v-55h-56.667Q334-415 327-422q-7-7-7-18t7-18q7-7 18.333-7H402v-56h-96q-10.833 0-17.917-7.116-7.083-7.117-7.083-18Q281-557 288.083-564q7.084-7 17.917-7h101.776Q427-571 439.5-558.35T452-527v173q0 18.7-12.65 31.35Q426.7-310 408-310Zm145 0q-18.7 0-31.35-12.65Q509-335.3 509-354v-173q0-18.7 12.65-31.35Q534.3-571 553-571h83q18.7 0 31.35 12.65Q680-545.7 680-527v173q0 18.7-12.65 31.35Q654.7-310 636-310h-83Zm6-50h71v-161h-71v161ZM480-80q-75 0-140.5-28T225-185q-49-49-77-114.5T120-440q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T180-440q0 125.357 87.321 212.679Q354.643-140 480-140t212.679-87.321Q780-314.643 780-440t-85-212.679Q610-740 485-740h-22l52 52q9 9 9 21t-8.609 21Q506-637 494-637q-12 0-21-9L368-751q-9-9-9-21t9-21l106-106q8-8 20.5-8t20.848 8Q523-891 523-878.5t-8 20.5l-58 58h23q75 0 140.5 28T735-695q49 49 77 114.5T840-440q0 75-28 140.5T735-185q-49 49-114.5 77T480-80Z"
		/>
	</svg>
);
