import { ReactNode } from 'react';

export const symbol_notification_multiple: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M110-80q-24 0-42-18t-18-42v-504q0-12.75 8.675-21.375Q67.351-674 80.175-674 93-674 101.5-665.375T110-644v504h535q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q675-97 666.375-88.5T645-80H110Zm740-180v-338L573-412q-15 11-33 11t-33-11L230-598v338h620Zm-620 60q-24 0-42-18t-18-42v-392q0-19.22 10-36.61T204-715l283-173q11-7 23.5-4.5t19.088 13.5Q537-868 534-855.5T520-836L239-661l301 199 300-198q11.413-8 23.207-7.5Q875-667 886-662t17.5 15q6.5 10 6.5 24v363q0 24-18 42t-42 18H230Zm302-441 163-163q9-9 21-8.5t21 9.5q9 9 9 21.5t-9 21.5L553-576q-9 9-21 9t-21-9l-74-74q-9-9-8.5-21.5T438-693q9-9 21.5-9t21.5 9l51 52ZM230-260h620-620Z"
		/>
	</svg>
);
