import { ReactNode } from 'react';

export const symbol_signal_cellular_connected_no_internet_4_bar: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m131-131 697-697q14-14 33-6.344T880-807v152h-30q-45.833 0-77.917 32.083Q740-590.833 740-545v465H152q-20 0-27.5-18.5T131-131Zm718.825 51Q837-80 828.5-88.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5Q880-97 871.325-88.5q-8.676 8.5-21.5 8.5ZM820-230v-315q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T880-545v316q0 12-9 20.5t-21.5 8.5q-12.5 0-21-8.625T820-230Z"
		/>
	</svg>
);
