import { ReactNode } from 'react';

export const symbol_umbrella: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m510-284 117-336-55 12-62-40v364Zm-60 0v-364l-62 41-56-14 118 337Zm30 204q-9 0-17-5t-12-15L258-652q-6-17 6-30t29-9l81 19 76-46v-116q0-35 26.5-60.5T540-920q38 0 64 25.5t26 60.5q0 13-8.5 21.5T600-804q-13 0-21.5-8.5T570-834q0-13-8.5-21t-21.5-8q-12 0-21 9t-9 20v116l76 46 81-19q17-4 29 9t6 30L509-101q-4 11-12 16t-17 5Z"
		/>
	</svg>
);
