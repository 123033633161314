import { createRef, Component } from 'react';
import styled from 'styled-components';

import * as shadow from 'pkg/config/box-shadow';
import { small } from 'pkg/config/breakpoints';

import CardBase from 'components/Card';
import Icon from 'components/icon';

import { Dots, Dot } from 'components/swiper/Dots';
import Swipeable from 'components/swiper/Swipeable';

import Button from 'design/button';

const BackIcon = styled(Icon)`
	transform: rotate(180deg);
`;

const DotsWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
`;

const PaginatorArrow = styled.div`
	opacity: ${(props) => (props.active ? '1' : '0')};
	border-radius: 30px;
	cursor: pointer;
	height: 30px;
	width: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: ${shadow.flat};
	visibility: ${(props) => (props.active ? 'visible' : 'hidden')};
	padding-bottom: 1px;
`;

const PaginatorArrowRight = styled(PaginatorArrow)`
	margin-left: 5px;
	padding-left: 1px;
`;

const PaginatorArrowLeft = styled(PaginatorArrow)`
	margin-right: 5px;
	padding-right: 1px;
`;

const SwiperWrapper = styled.div`
	display: grid;
	grid-template-areas: 'top' 'footer';
	grid-template-rows: 1fr 150px;
	height: 100%;
	padding-bottom: var(--spacing-9);

	@media ${small} {
		grid-template-rows: 1fr auto;
	}
`;

const SwiperFooter = styled.div`
	grid-area: footer;

	display: flex;
	justify-content: flex-end;
	align-items: center;
	flex-flow: column wrap;

	@media ${small} {
		justify-content: flex-start;
	}

	${Button} {
		margin-bottom: 30px;
		margin-bottom: calc(30px + env(safe-area-inset-bottom));
	}

	${DotsWrapper} {
		margin-bottom: 30px;
	}

	${Button} {
		font-size: var(--font-size-lg);
	}
`;

export default class SwiperBody extends Component {
	topSwiper = createRef();

	state = {
		currentIndex: 0,
	};

	handleChangedSlide = (slideIndex, scrollToIndex = false) => {
		if (Number.isNaN(slideIndex)) {
			return;
		}

		let slide = this.props.slides[slideIndex];

		// When the dots are clicked
		if (!slide) {
			slideIndex = this.props.slides.findIndex(
				(slide) => slide.slideId === slideIndex
			);

			slide = this.props.slides[slideIndex !== -1 ? slideIndex : 0];
		}

		this.setState({ currentIndex: slideIndex });
		this.props.onChangedSlide(slide.slideId);

		if (scrollToIndex) {
			this.topSwiper.current.scrollToIndex(slideIndex);
		}
	};

	renderDot = ({ props }) => {
		const { id } = props;
		const setActive = () => this.handleChangedSlide(id, true);

		return (
			<Dot
				key={id}
				active={id === this.props.activeSlide}
				onClick={setActive}
			/>
		);
	};

	get completeButton() {
		if (this.props.onComplete) {
			return (
				<Button
					large
					primary
					onClick={this.props.onComplete}
					testid="assessment_modal.ok_button">
					{this.props.completeText}
				</Button>
			);
		}

		return null;
	}

	handlePrevButton = () => {
		this.topSwiper.current.scrollToPrev();
	};

	get prevButton() {
		const active = this.state.currentIndex !== 0;

		return (
			<PaginatorArrowLeft
				active={active}
				onClick={this.handlePrevButton}
				data-testid="swiper.prev">
				<BackIcon name="chevron" />
			</PaginatorArrowLeft>
		);
	}

	handleNextButton = () => {
		this.topSwiper.current.scrollToNext();
	};

	get nextButton() {
		const active = this.state.currentIndex < this.props.children.length - 1;

		return (
			<PaginatorArrowRight
				active={active}
				onClick={this.handleNextButton}
				data-testid="swiper.next">
				<Icon name="chevron" />
			</PaginatorArrowRight>
		);
	}

	render() {
		return (
			<CardBase>
				<SwiperWrapper>
					<Swipeable
						onChangedSlide={this.handleChangedSlide}
						ref={this.topSwiper}>
						{this.props.children}
					</Swipeable>

					<SwiperFooter>
						<DotsWrapper>
							{this.prevButton}
							<Dots>{this.props.children.map(this.renderDot)}</Dots>
							{this.nextButton}
						</DotsWrapper>

						{this.completeButton}
					</SwiperFooter>
				</SwiperWrapper>
			</CardBase>
		);
	}
}
