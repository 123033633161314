import { ReactNode } from 'react';

export const symbol_timer_3: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M591-200H369q-17 0-28-11.735-11-11.736-11-28.5Q330-257 341.667-268.5 353.333-280 370-280h221v-160H411q-17 0-28-11.735-11-11.736-11-28.5Q372-497 383.667-508.5 395.333-520 412-520h179v-160H369q-17 0-28-11.735-11-11.736-11-28.5Q330-737 341.667-748.5 353.333-760 370-760h221q33.333 0 56.667 23.333Q671-713.333 671-680v131q0 29-19.25 50T605-478q27.5 0 46.75 19.542Q671-438.917 671-411v131q0 33.333-23.333 56.667Q624.333-200 591-200Z"
		/>
	</svg>
);
