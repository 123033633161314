import { t } from '@transifex/native';
import { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';

import * as palette from 'pkg/config/palette';

import MatchEvent from 'pkg/models/match_event';

import * as matchEventActions from 'pkg/actions/match_events_deprecated';
import { increaseScore } from 'pkg/actions/matches_deprecated';

import uuid from 'pkg/uuid';
import * as selectors from 'pkg/selectors';
import { getEventTypeTranslation } from 'pkg/api/models/match_event';

import TypeBody from 'containers/match/edit/event/TypeBody';

import * as Card from 'components/Card';
import AddButton from 'components/add-button';
import Icon from 'components/icon';

import FieldStyles from 'components/form/FieldStyles';
import * as iconStyles from 'components/icon/styles.css';

import InlinePlaceholder from 'design/placeholders/inline';

const Header = styled(Card.Header)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 70px;
	padding: 0 16px;

	${(props) =>
		props.isOpen &&
		css`
			${Chevron} {
				transform: rotateZ(90deg) rotateY(180deg);
			}
		`};
`;
const ItemsGroup = styled.div`
	display: flex;
	align-items: center;
	font-weight: var(--font-weight-semibold);
	height: 100%;

	.${iconStyles.icon} {
		cursor: pointer;
		font-size: 24px;
	}

	${(props) =>
		props.clickable &&
		css`
			flex: 1;
			cursor: pointer;
		`}
`;

const Chevron = styled(Icon)`
	transition: transform 300ms ease-in-out;
	transform: rotateZ(90deg) rotateY(0);
`;

const EventTitle = styled.span`
	margin-left: 1rem;
`;
const AmountInput = styled.input`
	${FieldStyles};

	width: 45px;
	height: 35px;
	text-align: center;
	flex: none;
	margin-right: var(--spacing-5);

	-moz-appearance: textfield;
	::-webkit-outer-spin-button,
	::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
`;

const EmptyPlaceholder = styled(InlinePlaceholder)`
	border-left: 3px solid ${palette.blue[400]};
`;

const EventType = ({ isOpponent, matchId, type, handleSave }) => {
	const dispatch = useDispatch();

	const [showEventTypeItems, setShowEventTypeItems] = useState(false);

	const events = useSelector((state) =>
		selectors.matchEvents.getMatchEvents(state, {
			matchId,
			type,
			isOpponent,
		})
	);
	const modes = useSelector((state) =>
		selectors.matchEvents.getMatchEventModes(state)
	);

	const AMOUNT_OF_EVENT_TYPE = events.size;

	const OPEN_MODAL_ON_ADD_TYPES = [
		'substitution_in',
		'substitution_out',
		'position_swap',
	];

	const persistedEvents = events
		.filter((event) => !modes.get(event.id).get('newEntity'))
		.sort((a, b) => a.minute - b.minute);

	const newEvents = events.filter((event) =>
		modes.get(event.id).get('newEntity')
	);

	const generateNewEvent = (type) => {
		const id = uuid();

		return new MatchEvent({
			id,
			guid: id,
			matchId,
			type,
			isOpponent,
		});
	};

	const addEvent = (e) => {
		e.preventDefault();
		e.stopPropagation();

		if (type === 'goal') {
			dispatch(increaseScore(matchId, isOpponent));
		}

		const event = generateNewEvent(type);

		dispatch(matchEventActions.addMatchEventGuids(type, [event]));

		if (OPEN_MODAL_ON_ADD_TYPES.includes(type)) {
			dispatch(matchEventActions.openEventEditModal(type, event.id));
		}
	};

	const handleAmountFocus = ({ target }) => {
		if (parseInt(target.value, 10) === 0) {
			target.value = null;
		}
	};

	const handleAmountBlur = (e) => {
		if (!e.target.value) {
			e.target.value = 0;
		}

		if (AMOUNT_OF_EVENT_TYPE > e.target.value) {
			e.target.value = AMOUNT_OF_EVENT_TYPE;
			return;
		}

		const inputValue = parseInt(e.target.value);

		if (inputValue > AMOUNT_OF_EVENT_TYPE) {
			for (let i = 0; inputValue - AMOUNT_OF_EVENT_TYPE > i; i++) {
				addEvent(e);
			}
		}
	};

	const handleAddEventOnClick = (e) => addEvent(e);

	const handleOpenBody = () => setShowEventTypeItems((prev) => !prev);

	return (
		<Fragment>
			<Header isOpen={showEventTypeItems}>
				<ItemsGroup clickable onClick={handleOpenBody}>
					<Chevron name="chevron" />
					<EventTitle>{getEventTypeTranslation(type)}</EventTitle>
				</ItemsGroup>
				<ItemsGroup key={AMOUNT_OF_EVENT_TYPE}>
					<AmountInput
						type="number"
						defaultValue={AMOUNT_OF_EVENT_TYPE}
						onFocus={handleAmountFocus}
						onBlur={handleAmountBlur}
					/>
					<AddButton tabIndex="-1" onClick={handleAddEventOnClick} />
				</ItemsGroup>
			</Header>

			{showEventTypeItems &&
				(AMOUNT_OF_EVENT_TYPE === 0 ? (
					<EmptyPlaceholder>
						{t(`No {type} registered`, {
							type: getEventTypeTranslation(type),
						})}
					</EmptyPlaceholder>
				) : (
					<Fragment>
						<TypeBody
							items={persistedEvents}
							matchId={matchId}
							generateNewEvent={generateNewEvent}
							handleSave={handleSave}
						/>
						<TypeBody
							items={newEvents}
							matchId={matchId}
							generateNewEvent={generateNewEvent}
							persisted={false}
						/>
					</Fragment>
				))}

			<Card.Divider />
		</Fragment>
	);
};

export default EventType;
