import { ReactNode } from 'react';

export const symbol_add_business: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M726-161h-90q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T636-221h90v-90q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T786-311v90h90q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T876-161h-90v91q0 12.75-8.675 21.375Q768.649-40 755.825-40 743-40 734.5-48.625T726-70v-91Zm-592 0q-12.75 0-21.375-8.625T104-191v-227H78q-14.14 0-23.07-11T49-454l44-202q2-11 10.25-17.5T122-680h554q10.5 0 18.75 6.5T705-656l44 202q3 14-5.93 25T720-418h-24v137q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T636-281v-137H420v227q0 12.75-8.625 21.375T390-161H134Zm30-60h196v-197H164v197Zm-50-257h572-572Zm14-262q-12.75 0-21.375-8.675Q98-757.351 98-770.175 98-783 106.625-791.5T128-800h543q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T671-740H128Zm-14 262h572l-31-142H145l-31 142Z"
		/>
	</svg>
);
