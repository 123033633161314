import { Fragment, useState } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';
import { ErrorBoundary } from '@sentry/react';

import * as routes from 'pkg/router/routes';
import { pushState } from 'pkg/router/state';
import * as api from 'pkg/api';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as actions from 'pkg/actions';
import { useCurrentRoute } from 'pkg/router/hooks';
import {
	useCurrentGroup,
	useCurrentMembership,
	useCurrentOrganization,
} from 'pkg/identity';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';

import SmallScreenHeader from 'routes/dashboard/components/SmallScreenHeader';
import GroupWidgets from 'routes/dashboard/group';
import OrganizationWidgets from 'routes/dashboard/organization';
import Wall from 'routes/wall';

import { ScrollSpy } from 'components/ScrollSpy';
import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import { MaterialSymbolVariant } from 'components/material-symbols/symbols';
import JoinGroupModal from 'components/group/join-modal';
import LargeScreenHeader from 'components/navigation/header/large_screen';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import ClaimInviteModal from 'components/account/claim-invite-modal';
import { ViewCrash } from 'components/crashes/view-crash';

const LargeScreenWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 0.7fr;
	grid-gap: var(--spacing-3);
	align-items: stretch;
	max-width: 1280px;
	margin: 0 auto;
	height: 100%;
	padding: 0 var(--spacing-8) 0 var(--spacing-7);
`;

const SmallScreenWrapper = styled(ScrollSpy)`
	background: var(--palette-gray-200);
	padding-top: 170px;
	padding-bottom: var(--spacing-6);
	padding-top: calc(env(safe-area-inset-top) + 180px);
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	max-height: 100%;
	min-height: 0;
	grid-area: content;
`;

const SmallScreenContentWrapper = styled.div`
	position: relative;
	z-index: 2;
	min-height: 100%;
	background: var(--palette-gray-200);
	border-radius: 20px 20px 0 0;

	${
		'' /* This box-shadow fixes some strange border-radius bug in iOS Safari  */
	}
	box-shadow: 0px -1px 0px 0px var(--palette-gray-200);
	padding: var(--spacing-7) var(--spacing-5) 0;
	display: flex;
	flex-flow: column nowrap;
	align-items: stretch;
`;

interface DashboardProps {
	title: string;
	icon: MaterialSymbolVariant;
}

const Dashboard = ({ title, icon }: DashboardProps): JSX.Element => {
	const route = useCurrentRoute();
	const membership = useCurrentMembership();
	const group = useCurrentGroup();
	const org = useCurrentOrganization();

	const [joinModalOpen, setJoinModalOpen] = useState(
		route?.groupCode ? true : false
	);

	const [inviteModalOpen, setInviteModalOpen] = useState(
		route?.inviteId ? true : false
	);

	const groupId = group?.id;
	const isOrganization = membership.isOrganizationMembership;

	const closeJoinModal = () => setJoinModalOpen(false);
	const closeInviteModal = () => setInviteModalOpen(false);

	useComponentDidMount(async () => {
		// Here we check if the URL is `/claim/{inviteKey}`, if it is
		// we check if that token is a valid invite and then send the user
		// to `/invite/{id}` where they can accept/decline the invite.
		if (!route.inviteKey) {
			return;
		}

		const [request, response] = await api.get<any>(
			endpoints.Accounts.OnboardingInfo() +
				`?account_invite_key=${route.inviteKey}`
		);

		if (!request.ok) {
			actions.flashes.show(
				{
					title: t('Something went wrong'),
					message: t('This invite code is not in use.'),
				},
				request.status
			);

			pushState(routes.Organization.Home(org.id));
		} else {
			const querystring = `?inviteKey=${route.inviteKey}`;
			pushState(routes.Invite.Show(response.inviteId) + querystring);
		}
	});

	if (!groupId) {
		return null;
	}

	return (
		<Fragment>
			{joinModalOpen && (
				<JoinGroupModal
					autoJoinWithGroupCode
					groupCode={route?.groupCode}
					onClose={closeJoinModal}
				/>
			)}
			{inviteModalOpen && (
				<ClaimInviteModal
					inviteId={route?.inviteId}
					onClose={closeInviteModal}
				/>
			)}
			<LargeScreen>
				<LargeScreenHeader title={title} icon={icon} />
				<LargeScreenContent.Wrapper disableScroll key="dashboard">
					<ErrorBoundary fallback={<ViewCrash />}>
						<LargeScreenWrapper>
							{isOrganization ? <OrganizationWidgets /> : <GroupWidgets />}
							<Wall isDashboard />
						</LargeScreenWrapper>
					</ErrorBoundary>
				</LargeScreenContent.Wrapper>
			</LargeScreen>
			<SmallScreen>
				<SmallScreenWrapper>
					<SmallScreenHeader />
					<SmallScreenContentWrapper>
						<ErrorBoundary fallback={<ViewCrash />}>
							{isOrganization ? <OrganizationWidgets /> : <GroupWidgets />}
						</ErrorBoundary>
					</SmallScreenContentWrapper>
				</SmallScreenWrapper>
			</SmallScreen>
		</Fragment>
	);
};

export default Dashboard;
