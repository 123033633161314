import styled from 'styled-components';
import { t } from '@transifex/native';

import * as supportChat from 'pkg/support_chat';
import * as routes from 'pkg/router/routes';
import { useCurrentAccount, useCurrentMembership } from 'pkg/identity';

import InfoScreen from 'components/InfoScreen';

import Button from 'design/button';

const ButtonsWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const OpenSupport = styled.span`
	color: var(--palette-white);
	text-decoration: underline;
	cursor: pointer;
	margin-top: var(--spacing-8);

	&::first-letter {
		text-transform: uppercase;
	}
`;

export function ErrorPage({ error }) {
	const account = useCurrentAccount();
	const membership = useCurrentMembership();

	return (
		<InfoScreen
			icon="error"
			heading={error.title}
			message={error.body}
			relative>
			<ButtonsWrapper>
				<Button href={routes.Home()} large block>
					{t('Go to start')}
				</Button>
				<OpenSupport
					onClick={() => {
						supportChat.openSupport({ account, membership });
					}}>
					{t('Contact support')}
				</OpenSupport>
			</ButtonsWrapper>
		</InfoScreen>
	);
}
