import { ReactNode } from 'react';

export const symbol_timer_10: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M535-280h185v-400H535v400Zm0 80q-33.333 0-56.667-23.333Q455-246.667 455-280v-400q0-33.333 23.333-56.667Q501.667-760 535-760h185q33.333 0 56.667 23.333Q800-713.333 800-680v400q0 33.333-23.333 56.667Q753.333-200 720-200H535ZM255-680h-54q-16.667 0-28.333-11.735Q161-703.471 161-720.235 161-737 172.667-748.5 184.333-760 201-760h104q12.75 0 21.375 8.625T335-730v490q0 16.667-11.735 28.333Q311.529-200 294.765-200 278-200 266.5-211.667 255-223.333 255-240v-440Z"
		/>
	</svg>
);
