import {
	IAddUsersPayload,
	IUpdatePayload,
} from 'pkg/actions/event_series_users';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as sdk from 'pkg/core/sdk';

export const add = (id: number, payload: IAddUsersPayload) =>
	sdk.post(endpoints.EventSeries.AddUsers(id), {}, payload);

export const update = (id: number, payload: IUpdatePayload) =>
	sdk.patch(endpoints.EventSeries.UpdateUsers(id), {}, payload);

export const remove = (id: number, users: number[]) =>
	sdk.destroy(endpoints.EventSeries.RemoveUsers(id), {}, { userIDs: users });
