import { ReactNode } from 'react';

export const symbol_orders: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M164-220v-457L95-826q-5-11-1-23t15-17q12-6 24.5-1.5T151-851l77 174h505l77-174q5.342-11.667 17.171-16.333Q839-872 851-866q11 5 15.5 17.5T866-825l-69 148v457q0 24.75-17.625 42.375T737-160H224q-24.75 0-42.375-17.625T164-220Zm232-237h170q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T566-517H396q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T396-457ZM224-220h513v-397H224v397Zm0 0v-397 397Z"
		/>
	</svg>
);
