import { ReactNode } from 'react';

export const symbol_unknown_7: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M470-200q-13 0-21.5-8.5T440-230q0-13 8.5-21.5T470-260h340q13 0 21.5 8.5T840-230q0 13-8.5 21.5T810-200H470Zm170-160q-13 0-21.5-8.5T610-390v-256l-53 53q-9 9-21 9t-21-9q-9-9-9-21t9-21l104-104q9-9 21-9t21 9l104 104q9 9 9 21t-9 21q-9 9-21 9t-21-9l-53-53v256q0 13-8.5 21.5T640-360Zm-490 0q-11 0-17-9t-2-19l131-320q2-5 7-8.5t11-3.5h16q6 0 11 3.5t7 8.5l131 320q4 10-2 19t-17 9q-6 0-11.5-3.5T407-373l-34-87H203l-34 87q-2 6-7.5 9.5T150-360Zm67-136h142l-69-178h-4l-69 178Z"
		/>
	</svg>
);
