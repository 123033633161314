import { ReactNode } from 'react';

export const symbol_transition_chop: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm0-116 495-129-86-335H140v464Zm23 56h657v-520H611l83 321q6 24-6.438 45.485Q675.123-352.031 651-346L163-220Zm-23-520Z"
		/>
	</svg>
);
