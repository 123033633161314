import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import DateTime from 'pkg/datetime';
import { Record } from 'pkg/api/models/record';
import { Dateable } from 'pkg/api/models/dateable';
import { User } from 'pkg/api/models/user';
import * as endpoints from 'pkg/api/endpoints/auto';
import { ProviderSettings } from 'pkg/api/models/provider_settings';
import { AllowedActionable } from 'pkg/api/models/allowed_actionable';

export enum TransactionExportTypes {
	PaidBased = '1',
	SentBased = '0',
}

export interface ExportOrdersPayload {
	recipientName?: boolean;
	recipientUserId?: boolean;
	email?: boolean;
	assignedContacts?: boolean;
	currency?: boolean;
	amountDue?: boolean;
	invoiceNumber?: boolean;
	externalId?: boolean;
	status?: boolean;
	createdAt?: boolean;
	dueDate?: boolean;
	paidAt?: boolean;
	type?: boolean;
	companyName?: boolean;
	addressData?: boolean;
	products?: boolean;
	productMetadata?: boolean;
	orderId?: boolean;
	subscriptionId?: boolean;
}

export interface ExportTransactionsPayload {
	from: number;
	to: number;
	exportType: number;
}

export enum Statuses {
	Draft = 'draft',
	Open = 'open',
	Paid = 'paid',
	PastDue = 'past_due',
	Void = 'void',
	Uncollectible = 'uncollectible',
	Refunded = 'refunded',
}

export enum OrderType {
	Unknown = 'unknown',
	Invoice = 'invoice',
	Payment = 'payment',
}

enum OrderJobStatus {
	Unknown = 'unknown',
	Processing = 'processing',
	Failed = 'failed',
}

export interface Order extends Record, Dateable, AllowedActionable {
	accountId: number;
	address: string;
	amountDue: number;
	amountRemaining: number;
	city: string;
	companyName: string;
	country: string;
	currency: string;
	customerUserId: number;
	description: string;
	dueDate: number;
	email: string;
	firstName: string;
	flags: string[];
	group: models.group.Group;
	groupId: number;
	lastName: string;
	phoneNumber: string;
	postalCode: string;
	region: string;
	sentAt: number;
	paidAt: number;
	jobStatus: OrderJobStatus;
	stripeCouponId: number;
	paymentProviderId: string;
	status: string;
	subscriptionId: number;
	invoiceNumber: number;
	type: OrderType;
	paymentUrl?: string;
	amountRefunded?: number;
	refundedAt?: number;

	customerUser?: User;
	subscription?: models.subscription.Subscription;
	userProducts: models.userProduct.UserProduct[];
	rows: models.orderRow.OrderRow[];
	refunds: models.orderRefund.OrderRefund[];
}

export function getPaymentTerms(order: Order) {
	const timeBetween = order.dueDate - order.createdAt;
	let terms = Math.round(timeBetween / (3600 * 24));

	if (terms < 0) {
		terms = 0;
	}

	return terms;
}

export function getOrdersTotalAmountDue(orders: Order[]) {
	let amount = 0;

	orders.forEach((o) => (amount += o.amountDue));

	return amount;
}

export function isFirstOrderOfSubscription(order: Order) {
	return order.subscription?.orders?.[0].id === order.id;
}

function isPartiallyRefunded(order: Order) {
	return order.amountRefunded > 0 && order.amountRefunded < order.amountDue;
}

export function getActions(
	order: Order,
	providerSettings: ProviderSettings
): string[] {
	const actions = [];
	const validActions = {
		finalize: ['draft'],
		reminder: ['uncollectible', 'open'],
		paid: ['uncollectible', 'open'],
		uncollectible: ['open'],
		receipt: ['paid'],
		refundable: ['paid'],
		void: ['open', 'uncollectible'],
		delete: ['draft'],
	};

	for (const [key, value] of Object.entries(validActions)) {
		if (
			value.includes(order.status) &&
			key !== 'delete' &&
			key !== 'reminder'
		) {
			if (
				key === 'paid' &&
				order.subscription?.status ===
					models.subscription.SubscriptionStatus.Pending
			) {
				continue;
			}

			if (
				key === 'finalize' &&
				order.subscriptionId &&
				order.status === Statuses.Draft
			) {
				continue;
			}

			actions.push(key);
		} else if (
			key === 'delete' &&
			!order.subscriptionId &&
			order.status === Statuses.Draft
		) {
			actions.push(key);
		} else if (key === 'reminder') {
			if (
				getStatus(order).name === 'past_due' &&
				order.status !== Statuses.Void &&
				getStatus(order).name !== 'refunded'
			) {
				actions.push(key);
			}

			if (
				(!order.subscriptionId &&
					order.status !== Statuses.Void &&
					getStatus(order).name !== 'refunded') ||
				(order.subscription &&
					order.status !== Statuses.Void &&
					getStatus(order).name !== 'refunded' &&
					(order.subscription.collectionMethod !== 'automatic' ||
						providerSettings.allowReminderActionOnAutoChargedOrders))
			) {
				actions.push(key);
			}
		}
	}

	if (order.status === Statuses.Draft || order.status === Statuses.Paid) {
		return actions.filter((item: string) => item !== 'reminder');
	}

	return actions;
}

export function getStatus(order: Order): { name: string } {
	const now = new DateTime(new Date()).getUnixTimestamp();

	if (order.dueDate < now && order.status === 'open') {
		return { name: 'past_due' };
	}

	if (order.status === 'paid' && isPartiallyRefunded(order)) {
		return { name: 'partially_refunded' };
	}

	return { name: order.status === 'unknown' ? '' : order.status };
}

export function getTypeString(order: Order): string {
	switch (order.type) {
		case OrderType.Payment:
			return t('Checkout');
		case OrderType.Invoice:
			return t('Invoice');
		default:
			return '';
	}
}

export function getExportFields() {
	return [
		{
			key: 'recipientName',
			label: t(`Recipient name`),
		},
		{
			key: 'recipientUserId',
			label: t(`Recipient user ID`),
		},
		{
			key: 'email',
			label: t(`Email`),
		},
		{
			key: 'assignedContacts',
			label: t(`Assigned contacts`),
		},
		{
			key: 'currency',
			label: t(`Currency`),
		},
		{
			key: 'amountDue',
			label: t(`Amount due`),
		},
		{
			key: 'invoiceNumber',
			label: t(`Invoice number`),
		},
		{
			key: 'externalId',
			label: t(`External ID`),
		},
		{
			key: 'status',
			label: t(`Status`),
		},
		{
			key: 'createdAt',
			label: t(`Created`),
		},
		{
			key: 'dueDate',
			label: t(`Due date`),
		},
		{
			key: 'paidAt',
			label: t('Paid at'),
		},
		{
			key: 'type',
			label: t(`Type`),
		},
		{
			key: 'companyName',
			label: t(`Company name`),
		},
		{
			key: 'addressData',
			label: t(`Address data`),
		},
		{
			key: 'products',
			label: t(`Products`),
		},
		{
			key: 'productMetadata',
			label: t('Product metadata'),
		},
		{
			key: 'orderId',
			label: t(`Order ID`),
		},
		{
			key: 'subscriptionId',
			label: t(`Subscription ID`),
		},
	];
}

export async function exportOrders(
	filters: unknown,
	payload: ExportOrdersPayload
): Promise<[boolean, string]> {
	return models.createExport(endpoints.Orders.ExportOrders(), payload, filters);
}

export async function exportItemizedOrders(
	filters: unknown
): Promise<[boolean, string]> {
	return models.createExport(
		endpoints.Orders.ExportItemizedOrders(),
		null,
		filters
	);
}

export async function exportTransactionOrders(
	filters: unknown,
	payload: ExportTransactionsPayload
): Promise<[boolean, string]> {
	return models.createExport(
		endpoints.Orders.ExportTransactions(),
		payload,
		filters
	);
}
