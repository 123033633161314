import { Component, useState } from 'react';
import styled from 'styled-components';

import * as palette from 'pkg/config/palette';

import Link from 'pkg/router/Link';
import * as models from 'pkg/api/models';

import CardBase from 'components/Card';
import Icon from 'components/icon';

import * as iconStyles from 'components/icon/styles.css';

const Item = styled(CardBase)`
	display: block;
	margin: 0;

	/* Override card styling in small devices */
	border-radius: 3px !important;
`;

const FileInfo = styled.figure`
	display: grid;
	padding: 1rem 0.8rem;
	margin: 0;

	grid-template-areas: 'graphic meta download';
	grid-template-columns: 60px 1fr 30px;
	grid-gap: 0.6rem;

	figcaption {
		grid-area: 'meta';
		display: flex;
		flex-flow: column;
		justify-content: space-between;
		padding: 3px 0;
		min-width: 0;
	}
`;

const FileIcon = styled.div`
	grid-area: 'graphic';
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: var(--radius-3);
	background: ${palette.blue[500]};
	overflow: hidden;
	height: 60px;

	.${iconStyles.icon} {
		font-size: 30px;
		color: ${palette.white};
		flex: 0;
	}
`;

const ImageSource = styled.img`
	object-fit: cover;
	display: block;
	width: 100%;
	height: 100%;
	transition: opacity 250ms ease-in-out;
	opacity: ${(props) => (props.loaded ? 1 : 0)};
	z-index: 1;
`;

const FileName = styled.div`
	font-size: var(--font-size-sm);
	font-weight: var(--font-weight-semibold);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: ${palette.blue[500]};
	line-height: 1.1;
`;

const FileMeta = styled.div`
	font-size: 0.65rem;
	font-weight: var(--font-weight-semibold);
	line-height: 1.1;
	margin-top: 3px;
	color: ${palette.gray[600]};
	text-transform: uppercase;
`;

const DownloadIcon = styled(Icon)`
	grid-area: 'download';
	align-self: center;
	justify-self: center;
	height: 25px;
	color: ${palette.blue[500]};
`;

const Image = ({ src }) => {
	const [isLoaded, setIsLoaded] = useState(false);
	const handleDidLoad = () => setIsLoaded(true);

	return (
		<ImageSource
			src={src}
			onLoad={handleDidLoad}
			loaded={isLoaded}
			loading="lazy"
		/>
	);
};

export default class extends Component {
	static defaultProps = {
		link: false,
	};

	get extension() {
		return models.attachment.getExtension(this.props.attachment);
	}

	get fileSize() {
		return models.attachment.getHumanFileSize(this.props.attachment);
	}

	get fileInfo() {
		const { attachment } = this.props;

		let shouldHaveIcon = true;
		let graphic = null;

		if (attachment.previewUrl !== '') {
			shouldHaveIcon = false;
		} else if (
			attachment.type === 'image' &&
			attachment.size < 5 * 1024 * 1024
		) {
			shouldHaveIcon = false;
		}

		if (shouldHaveIcon) {
			switch (attachment.type) {
				case 'image':
					graphic = <Icon name="file-image" />;
				default:
					graphic = <Icon name="file-generic" />;
			}
		} else {
			graphic = <Image src={attachment.previewUrl || attachment.url} />;
		}

		graphic = <FileIcon>{graphic}</FileIcon>;

		const content = (
			<FileInfo>
				{graphic}

				<figcaption>
					<FileName>{attachment.title}</FileName>
					<FileMeta>
						{this.extension} - {this.fileSize}
					</FileMeta>
				</figcaption>

				<DownloadIcon name="download-simple" />
			</FileInfo>
		);

		return !this.props.link ? (
			<div>{content}</div>
		) : (
			<Link href={attachment.url} target="_blank">
				{content}
			</Link>
		);
	}

	render() {
		return <Item>{this.fileInfo}</Item>;
	}
}
