import { ReactNode } from 'react';

export const symbol_credit_card_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m865-181-45-45v-276H544L415-631h405v-109H306l-60-60h574q24 0 42 18t18 42v520.163Q880-209 876-199t-11 18ZM401-474Zm162-9Zm-191-19H140v282h514L372-502ZM140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h20l60 60h-80v109h103L47-827q-9-9.067-9-21.533Q38-861 47.053-870q9.052-9 21.5-9Q81-879 90-870l745 745q9 9 9 21t-9.053 21q-9.052 9-21.5 9Q801-74 792-82.974L714-160H140Z"
		/>
	</svg>
);
