import { ReactNode } from 'react';

export const symbol_cards: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M431-500H250q-12.75 0-21.375-8.625T220-530v-181q0-12.75 8.625-21.375T250-741h181q12.75 0 21.375 8.625T461-711v181q0 12.75-8.625 21.375T431-500Zm0 281H250q-12.75 0-21.375-8.625T220-249v-181q0-12.75 8.625-21.375T250-460h181q12.75 0 21.375 8.625T461-430v181q0 12.75-8.625 21.375T431-219Zm280-281H530q-12.75 0-21.375-8.625T500-530v-181q0-12.75 8.625-21.375T530-741h181q12.75 0 21.375 8.625T741-711v181q0 12.75-8.625 21.375T711-500Zm0 281H530q-12.75 0-21.375-8.625T500-249v-181q0-12.75 8.625-21.375T530-460h181q12.75 0 21.375 8.625T741-430v181q0 12.75-8.625 21.375T711-219ZM280-560h121v-121H280v121Zm280 0h121v-121H560v121ZM280-279h121v-121H280v121Zm280 0h121v-121H560v121ZM401-560Zm159 0Zm0 160Zm-159 0ZM180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-60h600v-600H180v600Z"
		/>
	</svg>
);
