import { T, useT } from '@transifex/react';

import spacing from 'pkg/config/spacing';

import { StartLoginStateFlows } from 'pkg/actions/sso';

import * as models from 'pkg/api/models';
import * as actions from 'pkg/actions';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';

import Title from 'routes/public/styles/Title';
import { useOnboardingState } from 'routes/public/hooks';
import Button from 'routes/public/styles/Button';
import { OnboardingSlug, useOnboardingContext } from 'routes/public/onboarding';

import Column from 'components/layout/column';

export default function SSOSelectAuthMethod() {
	const t = useT();
	const onboardingCtx = useOnboardingContext();
	const onboardingState = useOnboardingState();
	const accountFromState =
		onboardingState.get<models.onboardingInfo.Account>('account');
	const inviteFromState = onboardingState.get<string>('inviteKey');
	const identityProviders =
		onboardingState.get<models.identityProvider.ScrubbedIdentityProvider[]>(
			'identityProviders'
		);

	let loginState = {
		nextSlug: 'user-information',
		flow: StartLoginStateFlows.Registration,
	};

	// Dependant on flow we need to set different prev/next slugs for the login method
	if (onboardingCtx.currentSlug === 'account-invite-sso-select') {
		loginState = {
			nextSlug: 'account-invite-user-information',
			flow: StartLoginStateFlows.Registration,
		};
	} else if (onboardingCtx.currentSlug === 'join-group-sso-select') {
		loginState = {
			nextSlug: 'join-group-user-information',
			flow: StartLoginStateFlows.Registration,
		};
	}

	useComponentDidMount(() => {
		// If we're in the account invite flow we need to override the prev step
		if (onboardingCtx.currentSlug === 'account-invite-sso-select') {
			onboardingCtx.overrideBackSlug(
				`signup?invite_key=${inviteFromState}` as OnboardingSlug
			);
		}
	});

	let role = t('player');

	if (accountFromState?.role === 'parent') {
		role = t(`parent`);
	} else if (accountFromState?.role === 'staff') {
		role = t(`coach or staff`);
	}

	const handleIdpClick = (
		identityProvider: models.identityProvider.ScrubbedIdentityProvider
	) => {
		actions.sso.startLogin({
			identityProvider,
			state: loginState,
		});
	};

	return (
		<Column spacing={spacing._8}>
			<Title
				title={
					(
						<T
							_str="Please choose your authentication method below and create your {role} account with ease."
							role={<span style={{ textTransform: 'uppercase' }}>{role}</span>}
						/>
					) as any
				}
			/>
			<Column spacing={spacing._2}>
				{identityProviders?.map((idp) => (
					<Button
						key={idp.id}
						label={t('Continue with {displayName}', {
							displayName: idp.displayName,
						})}
						onClick={() => handleIdpClick(idp)}
					/>
				))}
			</Column>
		</Column>
	);
}
