import { ReactNode } from 'react';

export const symbol_currency_bitcoin: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M370-150v-60H270q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T270-270h60v-420h-60q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T270-750h100v-60q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T430-810v60h110v-60q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T600-810v63q54 11 87 53t33 94q0 28-11 55.5T677-495q39 20 61 56.5t22 78.1q0 62.4-43.5 106.4Q673-210 610-210h-10v60q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T540-150v-60H430v60q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T370-150Zm20-360h180q38 0 64-26.438 26-26.437 26-63.562 0-38-26-64t-64-26H390v180Zm0 240h220q38 0 64-26.438 26-26.437 26-63.562 0-38-26-64t-64-26H390v180Z"
		/>
	</svg>
);
