import { ReactNode } from 'react';

export const symbol_outgoing_mail: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M765-280H614q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T614-340h151l-52-53q-8-9-8.5-21t8.5-21q9-9 21-9t21 9l104 104q5 5 7 10.217 2 5.218 2 10.783 0 5.565-2 10.783Q864-294 859-289L755-185q-9 9-21 9t-21-9q-9-9-8.5-21t8.5-21l52-53Zm-625 0q-24.75 0-42.375-17.625T80-340v-440q0-24.75 17.625-42.375T140-840h560q24.75 0 42.375 17.625T760-780v202q0 12-9.053 21-9.052 9-21.122 9-12.825 0-21.325-8.625T700-578v-155L416-532 140-732v392h321q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T461-280H140Zm36-500 240 174 246-174H176Zm-36 440v-440 440Z"
		/>
	</svg>
);
