import { t } from '@transifex/native';
import React, { useContext, useState } from 'react';

import * as models from 'pkg/api/models';

import { AddOrderContext } from 'routes/payments/orders/create';

import StepModal, { Step } from 'components/step-modal';

import SearchInput from 'components/form/SearchInput';
import * as ActionBar from 'components/layout/ActionBar';

import * as Table from 'design/table';

import * as css from './styles.css';

interface UserRowProps {
	user: models.user.User;
	handleRemoveContact: (userId: number) => void;
}

const UserRow: React.FC<React.PropsWithChildren<UserRowProps>> = ({
	user,
	handleRemoveContact,
}) => {
	return (
		<Table.Row>
			<Table.Cell>{models.user.fullName(user)}</Table.Cell>
			<Table.Cell>{models.user.getBillingEmail(user)}</Table.Cell>
			<Table.Cell onClick={() => handleRemoveContact(user.id)}>
				<div className={css.removeContact}>{t('Remove')}</div>
			</Table.Cell>
		</Table.Row>
	);
};

interface EditAssignedContactsProps {
	users: models.user.User[];
	hideModal: () => void;
}

function EditAssignedContacts({ users, hideModal }: EditAssignedContactsProps) {
	const OrderContext = useContext(AddOrderContext);
	const [searchValue, setSearchValue] = useState('');
	const [showingUsers, setShowingUsers] = useState(users);

	const handleSearch = (value: string) => setSearchValue(value);

	const handleNext = async () => {
		OrderContext.setFormState({
			assignedContacts: showingUsers,
		});

		return true;
	};

	const handleRemoveContact = (userId: number) =>
		setShowingUsers((prev) => prev.filter((item) => item.id !== userId));

	return (
		<StepModal onClose={hideModal} wide>
			<Step
				title={t('Edit assigned contacts')}
				skipBody
				onNext={handleNext}
				nextLabel={t('Save')}>
				<ActionBar.Bar>
					<ActionBar.PrimaryAction>
						<SearchInput
							value={searchValue}
							rounded
							onChange={handleSearch}
							placeholder={t('Search')}
						/>
					</ActionBar.PrimaryAction>
				</ActionBar.Bar>
				<Table.Table
					columns={[
						{
							content: t('Name'),
						},
						{
							content: t('Billing email'),
						},
						{
							content: '',
							width: 'max-content',
						},
					]}>
					{showingUsers
						.filter((u) =>
							models.user
								.fullName(u)
								.toLocaleLowerCase()
								.includes(searchValue.toLocaleLowerCase())
						)
						.map((user, index) => (
							<UserRow
								key={index}
								user={user}
								handleRemoveContact={handleRemoveContact}
							/>
						))}
				</Table.Table>
			</Step>
		</StepModal>
	);
}

export default EditAssignedContacts;
