import { ReactNode } from 'react';

export const symbol_single_bed: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M160-494q0-25.899 17-43.949Q194-556 220-556h26v-144q0-24.75 17.625-42.375T306-760h347q24.75 0 42.375 17.625T713-700v144h27q24.75 0 42.375 17.625T800-496v170q0 19-13.5 32.5T754-280l-17 69q-1.556 5.077-5.444 8.038Q727.667-200 723-200h-8q-4.667 0-8.556-2.962-3.888-2.961-5.444-8.038l-17-69H277l-18 69q-1.556 5.077-5.444 8.038Q249.667-200 245-200h-8q-4.667 0-8.556-2.962-3.888-2.961-5.444-8.038l-17-69q-19 0-32.5-13.5T160-326v-168Zm350-62h143v-144H510v144Zm-204 0h144v-144H306v144Zm-86 216h520v-156H220v156Zm520 0H220h520Z"
		/>
	</svg>
);
