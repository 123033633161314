import { useState } from 'react';

export const useToggleBoolean = (
	intialState = false
): [value: boolean, toggle: () => void] => {
	const [x, setX] = useState<boolean>(intialState);

	const toggleX = () => setX((x: boolean) => !x);

	return [x, toggleX];
};
