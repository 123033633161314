import { Fragment, useState } from 'react';
import { t } from '@transifex/native';

import * as routes from 'pkg/router/routes';
import { pushState } from 'pkg/router/state';
import { useCurrentAccountWards, useCurrentOrganization } from 'pkg/identity';
import * as models from 'pkg/api/models';

import Badge from 'components/Badge';
import { useSmallScreen } from 'components/MediaQuery';
import Avatar from 'components/avatar';

import JoinGroupModal from 'components/group/join-modal';
import Row from 'components/layout/row';
import InviteParentModal from 'components/account/invite-parent-modal';
import Column from 'components/layout/column';
import { MissingInformation } from 'components/user/missing-information';

import Button from 'design/button';
import * as Context from 'design/context_menu';

import * as css from './styles.css';

function useWardAccount(accountId: number): models.account.Account {
	const wards = useCurrentAccountWards();

	return wards
		.map((rel: models.accountRelation.AccountRelation) => rel.targetAccount)
		.find((account: models.account.Account) => account.id === accountId);
}

interface MembershipProps {
	membership: models.membership.Membership;
}

function Membership({ membership }: MembershipProps): JSX.Element {
	const activateMembership = () => {
		pushState(routes.Group.Change(), {
			groupId: membership.groupId.toString(),
			userId: membership.userId.toString(),
		});
	};

	return (
		<Context.Item onClick={activateMembership}>
			<Row columns="24px 1fr" align="center">
				<Badge badgeUrl={membership.group?.profileImageUrl} />
				{membership.group?.name}
			</Row>
		</Context.Item>
	);
}

interface WardProps {
	user: models.user.User;
}

export function Ward({ user }: WardProps): JSX.Element {
	const isSmallScreen = useSmallScreen();

	const org = useCurrentOrganization();
	const targetAccount = useWardAccount(user.accountId);

	const [modalOpen, setModalOpen] = useState<
		'add-to-group' | 'invite-parent' | 'missing-information'
	>(null);

	const close = () => setModalOpen(null);

	const userGroups = (
		<Fragment>
			{user.groups?.length > 0 && (
				<Fragment>
					<Context.Label>{t('Groups')}</Context.Label>
					{user.groups?.map((membership: models.membership.Membership) => (
						<Membership key={membership.id} membership={membership} />
					))}
					<Context.Divider />
				</Fragment>
			)}
		</Fragment>
	);

	const isMissingInformation = models.user.isMissingRequiredInformation(user);

	const editUserButton = (
		<Button
			onClick={() => setModalOpen('missing-information')}
			label={t('Complete profile')}
			icon="edit"
			primary
		/>
	);

	return (
		<Fragment>
			<Column>
				<Row columns="35px 1fr auto" align="center" justifyContent="start">
					<Avatar user={user} />
					<span
						className={css.userName}
						data-testid="club_lobby.children.child_name">
						{models.user.fullName(user)}
					</span>
					<Row autoColumns="max-content" align="center">
						{isMissingInformation && !isSmallScreen && editUserButton}

						<Context.Menu
							toggleWith={<Context.ButtonTrigger icon="more_horiz" />}>
							{userGroups}
							<Context.Item
								icon="add"
								onClick={() => setModalOpen('add-to-group')}>
								{t('Add {name} to a group', { name: user.firstName })}
							</Context.Item>
							<Context.LinkItem
								icon="edit"
								href={routes.User.Settings(org.id, user.id)}>
								{t('Edit profile')}
							</Context.LinkItem>
							{!models.user.isAnAdult(user) && (
								<Context.LinkItem
									icon="add_comment"
									href={routes.Chat.Monitored.Index(org.id, user.accountId)}>
									{t('Show monitored chats')}
								</Context.LinkItem>
							)}
							<Context.Item onClick={() => setModalOpen('invite-parent')}>
								<Context.ItemIcon name="supervisor_account" />
								{t('Connect a parent to {child_first_name}', {
									child_first_name: user.firstName,
								})}
							</Context.Item>
						</Context.Menu>
					</Row>
				</Row>

				{isMissingInformation && isSmallScreen && editUserButton}
			</Column>

			{modalOpen === 'add-to-group' && (
				<JoinGroupModal
					showCurrentAccount={false}
					targetAccount={targetAccount}
					onClose={close}
				/>
			)}

			{modalOpen === 'invite-parent' && (
				<InviteParentModal targetAccounts={[targetAccount]} onClose={close} />
			)}

			{modalOpen === 'missing-information' && (
				<MissingInformation modal targetUser={user} onClose={close} />
			)}
		</Fragment>
	);
}
