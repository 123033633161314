import { ReactNode } from 'react';

export const symbol_settings_motion_mode: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M680-80v-400q16 0 31 2.5t29 7.5v380q-14 5-29 7.5T680-80Zm-100-28v-344q14-8 28.5-14.5T640-476v392q-17-3-31.5-9.5T580-108Zm200 0v-344q45 26 72.5 71T880-280q0 56-27.5 101T780-108Zm-240-30q-28-27-44-63.5T480-280q0-42 16-79t44-64v285ZM421-80q-14 0-25-9t-13-23l-15-94q-19-7-40-19t-37-25l-86 40q-14 6-28 1.5T155-226L97-330q-8-13-4.5-27t15.5-23l80-59q-2-9-2.5-20.5T185-480q0-9 .5-20.5T188-521l-80-59q-12-9-15.5-23t4.5-27l58-104q8-13 22-17.5t28 1.5l86 40q16-13 37-25t40-18l15-95q2-14 13-23t25-9h118q14 0 25 9t13 23l15 94q19 7 40.5 18.5T669-710l86-40q14-6 27.5-1.5T804-734l59 104q8 13 4.5 27.5T852-580l-61 43q-9 8-21 7t-20-10q-9-11-7-24t13-20l44-32-40-72-106 46q-23-26-52-43.5T538-708l-14-112h-88l-14 112q-34 7-63.5 24T306-642l-106-46-40 72 94 69q-4 17-6.5 33.5T245-480q0 17 2.5 33.5T254-413l-94 69 40 72 106-46q20 20 44 35.5t51 24.5q3 40 16.5 76t35.5 66q8 11 2.5 23.5T437-80h-16Zm59-400Zm0 0Zm0-130q-54 0-92 38t-38 92q0 23 7.5 44t21.5 38q9 12 22.5 14t24.5-6q10-8 11-20t-7-20q-10-9-15-22t-5-28q0-29 20.5-49.5T480-550q14 0 26.5 5t22.5 15q8 8 19.5 6.5T568-535q8-11 5.5-24T560-582q-16-14-36.5-21t-43.5-7Z"
		/>
	</svg>
);
