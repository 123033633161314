import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import { useCurrentGroup } from 'pkg/identity';
import { formatToCurrency } from 'pkg/i18n/formatters';

import Icon from 'components/icon';

import * as Table from 'components/form/Table';

interface DiscountFormTableProps {
	handleRemoveDiscount: () => void;

	discount: models.discount.Discount;
}

// Displays a form table with a discount
export function DiscountFormTable({
	discount,
	handleRemoveDiscount,
}: DiscountFormTableProps) {
	const group = useCurrentGroup();

	return (
		<Table.Table>
			<thead>
				<Table.TableHeadRow>
					<Table.HeadCell>{t('Title')}</Table.HeadCell>
					<Table.HeadCell>{t('Code')}</Table.HeadCell>
					<Table.HeadCell>{t('Amount')}</Table.HeadCell>
					<Table.HeadCell></Table.HeadCell>
				</Table.TableHeadRow>
			</thead>
			<tbody>
				<Table.TableRow>
					<Table.Cell>{discount.title}</Table.Cell>
					<Table.Cell>{discount.code}</Table.Cell>
					<Table.Cell>
						{discount.amountOff
							? formatToCurrency(discount.amountOff / 100, group.currency)
							: `${discount.percentOff}%`}
					</Table.Cell>
					<Table.IconCell onClick={handleRemoveDiscount || undefined}>
						<Icon name="close-circle" />
					</Table.IconCell>
				</Table.TableRow>
			</tbody>
		</Table.Table>
	);
}
