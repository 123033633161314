import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';

import AccountDelete from 'routes/account/settings/privacy/AccountDelete';
import ThirdPartyServices from 'routes/account/settings/privacy/ThirdPartyServices';

import Column from 'components/layout/column';

interface PrivacyProps {
	account?: models.account.Account;
}

export default function Privacy({ account }: PrivacyProps): JSX.Element {
	return (
		<Column spacing={styles.spacing._6}>
			<ThirdPartyServices account={account} />
			<AccountDelete account={account} />
		</Column>
	);
}
