import { ReactNode } from 'react';

export const symbol_category: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m289-572 167-267q5-7 11-10.5t14-3.5q8 0 14 3.5t11 10.5l167 267q5 8 4.5 16t-4.5 15q-4 7-10.6 11t-15.4 4H315q-8.914 0-15.6-4.125T289-541q-4-7-4.5-15t4.5-16ZM706-80q-74 0-124-50t-50-124q0-74 50-124t124-50q74 0 124 50t50 124q0 74-50 124T706-80Zm-586-55v-244q0-12.75 8.625-21.375T150-409h244q12.75 0 21.375 8.625T424-379v244q0 12.75-8.625 21.375T394-105H150q-12.75 0-21.375-8.625T120-135Zm586.085-5Q754-140 787-173.085q33-33.084 33-81Q820-302 786.916-335q-33.085-33-81.001-33Q658-368 625-334.915q-33 33.084-33 81Q592-206 625.084-173q33.085 33 81.001 33ZM180-165h184v-184H180v184Zm189-421h224L481-767 369-586Zm112 0ZM364-349Zm342 95Z"
		/>
	</svg>
);
