import { ReactNode } from 'react';

export const symbol_ev_station: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M220-552h269v-228H220v228Zm0 372h269v-312H220v312Zm269 0H220h269Zm30 60H190q-12.75 0-21.375-8.625T160-150v-630q0-24 18-42t42-18h269q24 0 42 18t18 42v288h65q20.714 0 35.357 14.643T664-442v219q0 22 17.5 36.5T722-172q23 0 43-14.5t20-36.5v-387h-15q-12.75 0-21.375-8.625T740-640v-75q0-6 4.5-10.5T755-730h5v-35q0-6 4.5-10.5T775-780q6 0 10.5 4.5T790-765v35h40v-35q0-6 4.5-10.5T845-780q6 0 10.5 4.5T860-765v35h5q6 0 10.5 4.5T880-715v75q0 12.75-8.625 21.375T850-610h-15v386.691Q835-180 801-150t-79.181 30q-44.155 0-75.987-30Q614-180 614-223v-219h-65v292q0 12.75-8.625 21.375T519-120Zm-182-94 88-140h-53v-105l-87 140h52v105Z"
		/>
	</svg>
);
