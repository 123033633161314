import { ReactNode } from 'react';

export const symbol_clock_loader_60: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80ZM240-240l240-240v-340q-142 0-241 98.812Q140-622.375 140-480q0 68 26 130t74 110Z"
		/>
	</svg>
);
