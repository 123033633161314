import * as styles from 'pkg/config/styles';

import Markdown from 'routes/kitchen_sink/components/Markdown';

import Icon from 'components/icon';
import Label from 'components/label';
import * as CardList from 'components/card-list';

import Column from 'components/layout/column';

import * as Context from 'design/context_menu';

import readme from './README.md';

const products = [
	{
		title: 'Training kit',
		subTitle: 'Farham FC',
		category: 'shirts',
		quantity: 25,
	},
	{
		title: 'Match kit',
		subTitle: 'Away shirt',
		category: 'shirts',
		quantity: 10,
	},
];

export default function CardListItem() {
	return (
		<Column spacing={styles.spacing._7}>
			<Markdown raw={readme} />
			<Column>
				{products.map((p) => (
					<CardList.Base>
						<CardList.IconWrapper>
							<Icon name="tag" />
						</CardList.IconWrapper>
						<CardList.Title>
							{p.title}
							<CardList.SubTitle>{p.subTitle}</CardList.SubTitle>
						</CardList.Title>
						<CardList.Col>
							<Label>{p.category}</Label>
						</CardList.Col>
						<CardList.Col>
							<CardList.TextContent align="center">
								{p.quantity}
								<CardList.SmallText>Quantity</CardList.SmallText>
							</CardList.TextContent>
						</CardList.Col>
						<CardList.Actions>
							<Context.Menu
								toggleWith={
									<Context.ButtonTrigger>
										<Icon name="context-menu" />
									</Context.ButtonTrigger>
								}>
								<Context.Item icon="arrow_forward">View</Context.Item>
								<Context.Item icon="edit">Edit</Context.Item>
								<Context.Item icon="cancel">Remove</Context.Item>
							</Context.Menu>
						</CardList.Actions>
					</CardList.Base>
				))}
			</Column>

			<Column>
				{products.map((p) => (
					<CardList.Base>
						<CardList.IconWrapper>
							<Icon name="tag" />
						</CardList.IconWrapper>
						<CardList.Title>
							{p.title}
							<em>{p.subTitle}</em>
						</CardList.Title>
						<CardList.Col>
							<Label>{p.category}</Label>
						</CardList.Col>
						<CardList.Col>
							<CardList.TextContent align="end">
								{p.quantity}
								<CardList.SmallText>Quantity</CardList.SmallText>
							</CardList.TextContent>
						</CardList.Col>
						<CardList.Actions>
							<Context.Menu
								toggleWith={
									<Context.ButtonTrigger>
										<Icon name="context-menu" />
									</Context.ButtonTrigger>
								}>
								<Context.Item icon="arrow_forward">View</Context.Item>
								<Context.Item icon="edit">Edit</Context.Item>
								<Context.Item icon="cancel">Remove</Context.Item>
							</Context.Menu>
						</CardList.Actions>
					</CardList.Base>
				))}
			</Column>

			<Column>
				{products.map((p) => (
					<CardList.Base>
						<CardList.Title>
							{p.title}
							<CardList.SubTitle>{p.subTitle}</CardList.SubTitle>
						</CardList.Title>
						<CardList.Actions>
							<Context.Menu
								toggleWith={
									<Context.ButtonTrigger>
										<Icon name="context-menu" />
									</Context.ButtonTrigger>
								}>
								<Context.Item icon="arrow_forward">View</Context.Item>
								<Context.Item icon="edit">Edit</Context.Item>
								<Context.Item icon="cancel">Remove</Context.Item>
							</Context.Menu>
						</CardList.Actions>
					</CardList.Base>
				))}
			</Column>

			<Column>
				{products.map((p) => (
					<CardList.Base label={{ text: 'Card label' }}>
						<CardList.Title>
							{p.title}
							<CardList.SubTitle>{p.subTitle}</CardList.SubTitle>
						</CardList.Title>
						<CardList.Actions>
							<Context.Menu
								toggleWith={
									<Context.ButtonTrigger>
										<Icon name="context-menu" />
									</Context.ButtonTrigger>
								}>
								<Context.Item icon="arrow_forward">View</Context.Item>
								<Context.Item icon="edit">Edit</Context.Item>
								<Context.Item icon="cancel">Remove</Context.Item>
							</Context.Menu>
						</CardList.Actions>
					</CardList.Base>
				))}
			</Column>

			<Column>
				{products.map((p) => (
					<CardList.Base label={{ text: 'Cautios Card', icon: 'tag' }} cautious>
						<CardList.IconWrapper>
							<Icon name="tag" />
						</CardList.IconWrapper>
						<CardList.Title>
							{p.title}
							<CardList.SubTitle>{p.subTitle}</CardList.SubTitle>
						</CardList.Title>
						<CardList.Actions>
							<Context.Menu
								toggleWith={
									<Context.ButtonTrigger>
										<Icon name="context-menu" />
									</Context.ButtonTrigger>
								}>
								<Context.Item icon="arrow_forward">View</Context.Item>
								<Context.Item icon="edit">Edit</Context.Item>
								<Context.Item icon="cancel">Remove</Context.Item>
							</Context.Menu>
						</CardList.Actions>
					</CardList.Base>
				))}
			</Column>
		</Column>
	);
}
