import { ReactNode } from 'react';

export const symbol_garden_cart: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M282.275-120Q248-120 224-144t-24-59v-381l-81-196H70q-12.75 0-21.375-8.675Q40-797.351 40-810.175 40-823 48.625-831.5T70-840h69q9.081 0 16.649 5.04Q163.216-829.92 167-822l59.462 142H854q17.128 0 26.064 14.5T881-636L756-390q51 6 85 43.968 34 37.969 34 89.566Q875-200 835.932-160.5 796.864-121 740.914-121 684-121 645-160.536 606-200.071 606-256q0-20 5.5-39t16.5-35l-153-13-124 185q-11 17-29.49 27.5T282.275-120ZM686-384l120-236H250l62 148q11 26 32 42t50 19l292 27ZM283-181q2 0 18-10l106-158q-41-4-78.5-20T273-425l-13-28v251q0 9 7.5 15t15.5 6Zm456.651-4Q772-185 793.5-206.773q21.5-21.774 21.5-52.878Q815-292 793.75-313.5T740-335q-31.25 0-53.125 21.25T665-260q0 31.25 21.773 53.125Q708.547-185 739.651-185ZM686-384l-292-27 292 27Z"
		/>
	</svg>
);
