import { useRef } from 'react';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import { pushState } from 'pkg/router/state';
import * as routes from 'pkg/router/routes';
import { useCurrentOrganization } from 'pkg/identity';

import * as StepModal from 'components/step-modal';

import * as Input from 'components/form/inputs';

interface DuplicateModalProps {
	schedule: models.schedule.Schedule;

	hideModal: () => void;
}

const DuplicateModal = ({ schedule, hideModal }: DuplicateModalProps) => {
	const titleRef = useRef(null);
	const org = useCurrentOrganization();

	const handleCopySchedule = async () => {
		const [req, resp] = await models.schedule.copy(schedule.id, {
			title: titleRef.current.value,
		});

		if (req) {
			pushState(routes.Templates.Show(org.id, schedule.groupId, resp.id));
		}
	};

	return (
		<StepModal.Base onClose={hideModal}>
			<StepModal.Step
				title={t(`Duplicate template`)}
				nextLabel={t('Duplicate')}
				onNext={handleCopySchedule}>
				<Input.Group label={t('Title')}>
					<Input.Field ref={titleRef} defaultValue={schedule.title} />
				</Input.Group>
			</StepModal.Step>
		</StepModal.Base>
	);
};

export default DuplicateModal;
