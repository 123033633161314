import { useRef } from 'react';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import { ScheduleUpdatePayload } from 'pkg/api/models/schedule';

import TemplateForm from 'routes/scheduling/templates/modals/Form';

import * as StepModal from 'components/step-modal';

import Form, { FormPayload } from 'components/form/Form';

interface EditTemplateModalProps {
	hideModal: () => void;
	refresh: () => Promise<void>;

	schedule: models.schedule.Schedule;
}

const EditTemplateModal = ({
	schedule,
	refresh,
	hideModal,
}: EditTemplateModalProps) => {
	const formRef = useRef(null);

	const handleSubmit = async (data: FormPayload) => {
		const payload: ScheduleUpdatePayload = {
			title: data.title as string,
			description: data.description as string,
		};

		if (
			!schedule.scheduleItems &&
			Number.parseInt(data.startingDay as string, 10) !== schedule.startingDay
		) {
			payload.startingDay = Number.parseInt(data.startingDay as string, 10);
		}

		const [ok] = await models.schedule.update(schedule, payload);

		if (ok) {
			refresh();
		}
	};

	const onNext = () =>
		formRef.current.dispatchEvent(
			new Event('submit', {
				cancelable: true,
				bubbles: true,
			})
		);

	return (
		<StepModal.Base onClose={hideModal}>
			<StepModal.Step
				title={t(`Edit template`)}
				onNext={onNext}
				nextLabel={t('Save')}>
				<Form formRef={formRef} onSubmit={handleSubmit}>
					<TemplateForm schedule={schedule} />
				</Form>
			</StepModal.Step>
		</StepModal.Base>
	);
};

export default EditTemplateModal;
