import { ReactNode } from 'react';

export const symbol_developer_guide: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-660v600h600v-600H676v252q0 9.391-7.5 13.696Q661-510 653-515l-60-36q-8-5-15-5t-15 5l-60 36q-8 5-15.5.696Q480-518.609 480-528v-252H180Zm0 600v-600 600Z"
		/>
	</svg>
);
