import { ReactNode } from 'react';

export const symbol_settings_accessibility: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480.08-814q-30.08 0-51.58-21.42-21.5-21.421-21.5-51.5 0-30.08 21.42-51.58 21.421-21.5 51.5-21.5 30.08 0 51.58 21.42 21.5 21.421 21.5 51.5 0 30.08-21.42 51.58-21.421 21.5-51.5 21.5ZM376-230v-464q-54-5-108-12t-104-18q-14-3-21.5-14t-4.5-24.5q3-13.5 15.5-19T180-784q72 16 148 23t152 7q76 0 152-7t148-23q14-3 27 2.5t16 19q3 13.5-5 24.5t-22 14q-50 11-104 18t-108 12v464q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T524-230v-214h-88v214q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T376-230ZM317 0q-17 0-27.5-10.5T279-38q0-17 10.5-27.5T317-76q17 0 27.5 10.5T355-38q0 17-10.5 27.5T317 0Zm164 0q-17 0-27.5-10.5T443-38q0-17 10.5-27.5T481-76q17 0 27.5 10.5T519-38q0 17-10.5 27.5T481 0Zm164 0q-17 0-27.5-10.5T607-38q0-17 10.5-27.5T645-76q17 0 27.5 10.5T683-38q0 17-10.5 27.5T645 0Z"
		/>
	</svg>
);
