import { Map, Record } from 'immutable';

import VideoSequenceUser from 'pkg/models/video_sequence_user';

import * as actionTypes from 'pkg/actions/action-types';

interface ISequenceReducer {
	entities: Map<number, VideoSequenceUser>;
}

class SequenceReducer
	extends Record<ISequenceReducer>(
		{
			entities: Map<number, VideoSequenceUser>(),
		},
		'videoSequenceUsers'
	)
	implements ISequenceReducer
{
	entities: Map<number, VideoSequenceUser>;
}

const initialState = new SequenceReducer({
	entities: Map<number, VideoSequenceUser>(),
});

interface NormalizrPayload {
	result: number[];
	entities: {
		videoSequenceUsers: { [key: string]: VideoSequenceUser };
	};
}

interface SequenceAction {
	type: string;
	payload?: NormalizrPayload;
	id?: number;
	sequenceUserId?: number;
}

const setItems = (
	state = initialState,
	action: SequenceAction
): SequenceReducer => {
	const { videoSequenceUsers } = action.payload.entities;

	Object.values(videoSequenceUsers).forEach((sequence: VideoSequenceUser) => {
		state = state.updateIn(
			['entities', sequence.id],
			(existing: VideoSequenceUser) => {
				if (!existing) {
					existing = new VideoSequenceUser(sequence);
				}

				existing = existing.merge(sequence);

				return existing;
			}
		);
	});

	return state;
};

const deleteItem = (
	state = initialState,
	action: SequenceAction
): SequenceReducer => {
	if (action.sequenceUserId) {
		state = state.deleteIn(['entities', action.sequenceUserId]);
	}

	return state;
};

const videoCollectionsReducer = (
	state = initialState,
	action: SequenceAction
): SequenceReducer => {
	switch (action.type) {
		case actionTypes.VideoSequenceUsers.SET_ITEMS:
		case actionTypes.VideoSequenceUsers.UPDATE_ITEM:
			return setItems(state, action);
		case actionTypes.VideoSequenceUsers.DELETE_ITEM:
			return deleteItem(state, action);
		default:
			return state;
	}
};

export default videoCollectionsReducer;
