import { ReactNode } from 'react';

export const symbol_comedy_mask: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-285q56.376 0 98.188-35Q620-355 631-409q2-7.917-3.25-13.458Q622.5-428 614-428H345q-7.5 0-12.75 5.5T329-409q11 54 52.812 89T480-285Zm0 205q-75 0-140.5-28.5t-114-77q-48.5-48.5-77-114T120-439v-381q0-24.75 17.625-42.375T180-880h600q24.75 0 42.375 17.625T840-820v381q0 74-28.5 139.5t-77 114q-48.5 48.5-114 77T480-80Zm0-60q125 0 212.5-87T780-439v-381H180v381q0 125 87.5 212T480-140Zm0-340ZM356.011-674Q331-674 312-659q-19 15-25 38-2 7 3 12.5t13 5.5h106q8 0 13-5.625T425-621q-6-23-24.989-38-18.99-15-44-15Zm247.999 0q-25.01 0-44.51 15-19.5 15-25.5 38-2 7 3 12.5t13 5.5h107q8 0 13-5.625T673-621q-6-23-24.99-38-18.989-15-44-15Z"
		/>
	</svg>
);
