import { t } from '@transifex/native';
import * as React from 'react';
import { List } from 'immutable';

import Video from 'pkg/models/video';

import { useCurrentRoute } from 'pkg/router/hooks';
import { useCurrentOrganization } from 'pkg/identity';
import * as routes from 'pkg/router/routes';
import { link } from 'pkg/router/utils';

import VideoItem from 'routes/dashboard/components/VideoItem';

import { ScrollableWrapper } from 'components/scrollable-wrapper';
import CardAnatomy, * as Card from 'components/Card';
import MissingEntities from 'components/missing-entities';
import RelativeDateTime from 'components/RelativeDateTime';
import Icon from 'components/icon';

type VideosProps = {
	videos: List<Video>;
};

const Videos: React.FC<React.PropsWithChildren<VideosProps>> = ({ videos }) => {
	const route = useCurrentRoute();
	const org = useCurrentOrganization();

	if (videos.size === 0) {
		return (
			<CardAnatomy $noBorder>
				<Card.Body>
					<MissingEntities centered>
						<Icon name="videos" />
						<p>{t(`You haven't been tagged in any videos yet`)}</p>
					</MissingEntities>
				</Card.Body>
			</CardAnatomy>
		);
	}

	return (
		<ScrollableWrapper chevrons>
			{videos.map((video: Video) => (
				<VideoItem
					href={link(routes.Video.Show(org.id, video.id), {
						tagged: route.userId,
					})}
					key={video.id}
					title={video.title}
					sourceUrl={video.thumbnailUrl}
					duration={video.duration}
					caption={<RelativeDateTime dateTime={video.createdAt} />}
				/>
			))}
		</ScrollableWrapper>
	);
};

export default Videos;
