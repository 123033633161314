import { ReactNode } from 'react';

export const symbol_local_cafe: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M190-120q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T190-180h580q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T770-120H190Zm121-140q-63 0-107-43.5T160-410v-370q0-24.75 17.625-42.375T220-840h600q24.75 0 42.375 17.625T880-780v160q0 24.75-17.625 42.375T820-560h-96v150q0 63-44 106.5T573-260H311Zm0-60h261.978Q609-320 636.5-347.5T664-410v-370H220v370q0 35 28 62.5t63 27.5Zm413-300h96v-160h-96v160ZM311-320h-91 444-353Z"
		/>
	</svg>
);
