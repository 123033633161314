// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rP4t7rRMYlAVObMhpW4C {
	font-size: var(--font-size-sm);
	color: var(--palette-gray-500);
	margin-top: var(--spacing-4);
}
`, "",{"version":3,"sources":["webpack://./routes/settings/payments/service-fee/styles.css"],"names":[],"mappings":"AAAA;CACC,8BAA8B;CAC9B,8BAA8B;CAC9B,4BAA4B;AAC7B","sourcesContent":[".serviceFeeDescription {\n\tfont-size: var(--font-size-sm);\n\tcolor: var(--palette-gray-500);\n\tmargin-top: var(--spacing-4);\n}\n"],"sourceRoot":""}]);
// Exports
export var serviceFeeDescription = `rP4t7rRMYlAVObMhpW4C`;
export default ___CSS_LOADER_EXPORT___;
