import { ReactNode } from 'react';

export const symbol_switch_access_2: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M40-220v-520q0-24.75 17.625-42.375T100-800h268q24.75 0 42.375 17.625T428-740v53q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T368-687v-53H100v520h268v-53q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T428-273v53q0 24.75-17.625 42.375T368-160H100q-24.75 0-42.375-17.625T40-220Zm492 0v-53q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T592-273v53h52q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T644-160h-52q-24.75 0-42.375-17.625T532-220Zm276 0h52v-53q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T920-273v53q0 24.75-17.625 42.375T860-160h-52q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T808-220ZM532-687v-53q0-24.75 17.625-42.375T592-800h52q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T644-740h-52v53q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T532-687Zm328 0v-53h-52q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T808-800h52q24.75 0 42.375 17.625T920-740v53q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T860-687ZM100-220v-520 520Zm626-230H230q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T230-510h496l-55-56q-8-9-8-21.1 0-12.1 9-20.9 9-9 21-9t21 9l106 107q9 9 9 21t-9 21L714-351q-9 9-21 9t-20.87-8.87Q664-359 663.5-371.5 663-384 671-393l55-57Z"
		/>
	</svg>
);
