import { useT } from '@transifex/react';
import { useMemo, useState } from 'react';

import { cssClasses } from 'pkg/css/utils';
import DateTime, { Granularity } from 'pkg/datetime';
import { isToday } from 'pkg/date';

import { useCalendarContext } from 'routes/group/calendar';

import { LargeScreen } from 'components/MediaQuery';
import Icon from 'components/icon';

import Row from 'components/layout/row';
import Calendar from 'components/form/calendar';

import Button from 'design/button';
import * as Context from 'design/context_menu';

import * as css from './styles.css';

type FromDateOptions = 'today' | 'threeMonths' | 'month' | 'week' | 'custom';

export default function ScheduleDateSelect() {
	const calendarCtx = useCalendarContext();
	const [fromDateSelect, setFromDateSelect] =
		useState<FromDateOptions>('today');
	const [showCalendarSelectDate, setShowCalendarSelectDate] = useState(false);

	const selectedDateIsToday = isToday(
		new DateTime(calendarCtx.calendarState.scheduleDate)
	);

	const todayDate = useMemo(() => {
		return new DateTime(new Date());
	}, []);

	const t = useT();

	let fromDateString = t('From today');

	switch (fromDateSelect) {
		case 'threeMonths':
			fromDateString = t('From 3 months ago');
			break;
		case 'month':
			fromDateString = t('From 1 month ago');
			break;
		case 'week':
			fromDateString = t('1 week ago');
			break;
		case 'custom':
			fromDateString = t('From {date}', {
				date: new DateTime(
					calendarCtx.calendarState.scheduleDate
				).toLocaleDateString(),
			});
			break;
	}

	const toggleToday = () => {
		calendarCtx.scrollItemRef.current.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	const handleDateChange = (dates: Date[]) => {
		const newDate = new Date(Math.round(new DateTime(dates[0]).startOfDay));

		calendarCtx.setCalendarState({ scheduleDate: newDate });
	};

	const handleMonthDateChange = (amount: number) => {
		const newDate = new DateTime(new Date()).prev(
			Granularity.month,
			amount
		).startOfDay;

		calendarCtx.setCalendarState({
			scheduleDate: new Date(Math.round(newDate)),
		});
	};

	const handleFromDateChange = (value: FromDateOptions) => {
		setFromDateSelect(value);

		switch (value) {
			case 'today':
				handleDateChange([new Date()]);
				break;
			case 'threeMonths':
				handleMonthDateChange(3);
				break;
			case 'month':
				handleMonthDateChange(1);
				break;
			case 'week':
				const newDate = todayDate.prev(Granularity.week, 1).startOfDay;

				calendarCtx.setCalendarState({
					scheduleDate: new Date(Math.round(newDate)),
				});

				break;
			case 'custom':
				setShowCalendarSelectDate(true);
				break;
		}
	};

	return (
		<Row align="center">
			<Context.Menu
				onClose={() => setShowCalendarSelectDate(false)}
				toggleWith={
					<div className={css.dateContextToggle}>
						<div className={css.dateString}>{fromDateString}</div>
						<Icon name="chevron-down" actualSize size={1.8} />
					</div>
				}>
				<Context.Heading>{t('Viewing events from')}</Context.Heading>
				<Context.Item onClick={() => handleFromDateChange('today')}>
					{t('Today')}
				</Context.Item>
				<Context.Item onClick={() => handleFromDateChange('week')}>
					{t('1 week ago')}
				</Context.Item>
				<Context.Item onClick={() => handleFromDateChange('month')}>
					{t('1 month ago')}
				</Context.Item>
				<Context.Item onClick={() => handleFromDateChange('threeMonths')}>
					{t('3 months ago')}
				</Context.Item>
				<Context.Divider />
				<Context.Item
					onClick={() => handleFromDateChange('custom')}
					closeOnClick={false}
					icon="edit_calendar">
					{t('Custom')}
				</Context.Item>
				{showCalendarSelectDate && (
					<Context.Pane>
						<Calendar
							selectedDates={[calendarCtx.calendarState.scheduleDate]}
							onDateChange={handleDateChange}
						/>
					</Context.Pane>
				)}
			</Context.Menu>
			<LargeScreen>
				<div
					className={cssClasses(
						css.todayWrapper,
						calendarCtx.calendarState.showToday && selectedDateIsToday
							? css.show
							: ''
					)}>
					<Button label={t('Today')} onClick={toggleToday} />
				</div>
			</LargeScreen>
		</Row>
	);
}
