import { memo, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { t } from '@transifex/native';

import * as palette from 'pkg/config/palette';

import * as models from 'pkg/api/models';
import { useCurrentAccountUserIds } from 'pkg/identity';
import { MessageType, useWebSocketClient } from 'pkg/websocket';

const dotAnim = keyframes`
	0% {
		transform: translateY(0);
	}

	20% {
		transform: translateY(-3px);
	}

	40% {
		transform: translateY(3px);
	}

	60%, 100% {
		transform: translateY(0);
	}
`;

const Dot = css`
	display: block;
	content: '';
	width: 4px;
	height: 4px;
	border-radius: 50%;
	background: ${palette.gray[800]};
	animation-name: ${dotAnim};
	animation-duration: 1.5s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
`;

const Dots = styled.div`
	display: grid;
	grid-gap: 2.5px;
	grid-auto-flow: column;
	align-items: center;
	align-content: center;
	margin-left: 8px;

	&::before,
	&::after,
	& > div {
		${Dot};
	}

	&::before {
		animation-delay: 0;
	}

	& > div {
		animation-delay: 150ms;
	}

	&::after {
		animation-delay: 300ms;
	}
`;

const Outer = styled.div`
	margin: 0 1rem;
	flex: 0 0 25px;
	overflow: hidden;
`;

const Inner = styled.div<{ hasEvents: boolean }>`
	font-size: 14px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	align-content: center;
	transform: translateY(${({ hasEvents }) => (hasEvents ? 0 : 50)}px);
	opacity: ${({ hasEvents }) => (hasEvents ? 1 : 0)};
	transition: all 150ms;
`;

interface TypingStatusProps {
	chat: models.chat.Chat;
}

const TypingStatus = memo(({ chat }: TypingStatusProps) => {
	const currentAccountUserIds = useCurrentAccountUserIds();
	const [userIds, setUserIds] = useState<number[]>([]);
	const typingUsers: string[] = chat.users
		.filter((u) => userIds.includes(u.userId))
		.map((u) => u.user.firstName);

	const hasEvents = typingUsers.length > 0;
	let string = ' ';

	const ws = useWebSocketClient();

	ws.onMessage<{
		userId: number;
		chatId: number;
		type: 'started-typing' | 'stopped-typing';
	}>(MessageType.ChatEvent, (event) => {
		if (
			chat.id !== event.data.chatId ||
			currentAccountUserIds.includes(event.data.userId)
		) {
			return;
		}

		if (
			event.data.type === 'started-typing' &&
			!userIds.includes(event.data.userId)
		) {
			setUserIds([...userIds, event.data.userId]);
		} else if (event.data.type === 'stopped-typing') {
			const index = userIds.findIndex((id) => id === event.data.userId);
			const copyUserIds = [...userIds];

			copyUserIds.splice(index, 1);
			setUserIds(copyUserIds);
		}
	});

	if (typingUsers.length === 1) {
		string = t('{name} is typing', {
			name: typingUsers[0],
		});
	} else if (typingUsers.length === 2) {
		string = t('{names} are typing', {
			names: typingUsers.join(', '),
		});
	} else if (typingUsers.length > 2) {
		string = t('Several people are typing');
	}

	return (
		<Outer>
			<Inner hasEvents={hasEvents}>
				{string}
				{hasEvents && (
					<Dots>
						<div />
					</Dots>
				)}
			</Inner>
		</Outer>
	);
});

export default TypingStatus;
