import { Users } from 'pkg/actions/action-types';
import { triggerError } from 'pkg/actions/app';
import * as logService from 'pkg/actions/services/user_log.services';

import * as selectors from 'pkg/selectors';
import * as sdk from 'pkg/core/sdk';

const setUserLogs = (userId, logs) => ({
	type: 'SET_USER_LOGS',
	userId,
	logs,
});

const premendUserLog = (userId, log) => ({
	type: 'PREPEND_USER_LOG',
	userId,
	log,
});

export const removeUserLog = (userId, logId) => {
	return async (dispatch) => {
		const req = await logService.deleteUserLog(userId, logId);

		if (!req.ok) {
			return;
		}

		const res = await req.json();

		dispatch({
			type: Users.USER_LOG_REMOVE_SUCCESS,
			payload: res,
			userId,
			logId,
		});
	};
};

export const fetchLogs =
	(userId, groupId, fetchNext = true) =>
	async (dispatch, getState) => {
		if (!groupId) {
			return;
		}

		const { userLogs } = getState();
		let url = `/users/${userId}/logs?group_id=${groupId}`;
		const currentUserLogs = userLogs.entities[userId];

		if (currentUserLogs?.links?.next && fetchNext) {
			url = currentUserLogs.links?.next;
		}

		const response = await sdk.get(url);

		if (!response.ok) {
			return dispatch(triggerError(response));
		}

		const logs = await response.json();
		dispatch(setUserLogs(userId, logs));
	};

export const postUserLog = (userId, data) => async (dispatch, getState) => {
	const response = await sdk.post('users/:userId/logs', { userId }, data);

	if (!response.ok) {
		return new Promise((res, rej) => rej(response));
	}

	const userLog = await response.json();

	userLog.author = selectors.users.find(getState(), data.authorId);

	dispatch(premendUserLog(userId, userLog));
};
