import { ReactNode } from 'react';

export const symbol_mode_fan_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M640-781q0 28-12 53t-35 40q-34 23-46 46t-23 59q-5 17-21.5 20t-27.615-9q-5.885-6-7.385-14.5t.5-17.5q8-41 33-76.5t59-58.5q11-7 15.5-18t4.5-24q0-17-13.5-28T535-820q-50 0-96 18.5T380-733q-5 17-21 23t-28-7q-6-6-8-15t0-18q20-72 81.5-101t130.227-29Q578-880 609-851.5q31 28.5 31 70.5Zm79 141q76 0 118.5 66.5T880-424q0 43.827-30 74.913Q820-318 777-320l-38-1q-7 0-12.5-2.5T716-331q-14-14-7-32t26-18l42 1q18 1 30.5-12.5T820-424q0-58-25-106t-77.462-50q-9.538 0-19.038 1.5T680-573l-122 53q-10 4-19.5 3t-16.738-8.273Q510-538 516.5-553.5 523-569 537-575l117-52q15-7 31.327-10 16.326-3 33.673-3ZM424-80q-51 0-77.5-30.5T320-180q0-26 11.5-50.5T367-271q22-14 36.5-37.5T425-373q-1-2-1.706-2H422l-116 41q-17 6-33 10t-33 4q-63 0-111.5-55T80-536q0-51 30.5-77.5T179-640q22.667 0 45.333 5.5Q247-629 262-612L89-785q-9-9.067-9-21.533Q80-819 89.053-828q9.052-9 21.5-9Q123-837 132-828l696 696q9 9 9 21t-9.053 21q-9.052 9-21.5 9Q794-81 785-90L640-235q-4 62-58.5 108.5T424-80ZM240-380q14 0 40-8l95-34q-8-14-11.5-28t-3.5-26q-45-8-80.5-30T221-560q-6-11-18.5-15.5T179-580q-19 0-29 10.5T140-536q0 61.941 25.625 108.971Q191.25-380 240-380Zm184 240q69 0 112.5-28.5T580-242q0-16-5-35t-15-38l-70-73q-7 49-26 93.5T400-221q-8 5-14 17.333-6 12.334-6 24.667 1 17 12.5 28t31.5 11ZM258-480Zm222 216Zm-77-381Zm216 217Z"
		/>
	</svg>
);
