import { ReactNode } from 'react';

export const symbol_highlighter_size_2: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m282-198-84-84q-9-9-9-21.5t9-21.5l437-437q9-9 21.5-9t21.5 9l84 85q8 9 8.5 21t-8.5 21L325-198q-9 9-21.5 9t-21.5-9Z"
		/>
	</svg>
);
