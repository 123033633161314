import * as models from 'pkg/api/models';
import { MatchEventType } from 'pkg/api/models/match_event';

import * as css from './styles.css';

interface SummarisedEventsProps {
	matchEvents: models.matchEvent.MatchEvent[];
	isHome: boolean;
}

interface RenderRowProps {
	homeScore: number;
	awayScore: number;

	type: models.matchEvent.MatchEventType;
}

const RenderRow = ({ type, homeScore = 0, awayScore = 0 }: RenderRowProps) => {
	return (
		<tr className={css.tableRow} key={`statistics-row--${type}`}>
			<td>
				<strong>{homeScore}</strong>
			</td>
			<td>{models.matchEvent.getEventTypeTranslation(type)}</td>
			<td>
				<strong>{awayScore}</strong>
			</td>
		</tr>
	);
};

export default function SummarisedEvents({
	matchEvents,
	isHome,
}: SummarisedEventsProps) {
	const eventOrder = [
		MatchEventType.MatchEventTypeShotOnGoal,
		MatchEventType.MatchEventTypeShotOffGoal,
		MatchEventType.MatchEventTypeFreeKick,
		MatchEventType.MatchEventTypePenalty,
		MatchEventType.MatchEventTypePass,
		MatchEventType.MatchEventTypeOffside,
		MatchEventType.MatchEventTypeCorner,
		MatchEventType.MatchEventTypeCardYellow,
		MatchEventType.MatchEventTypeCardRed,
	];

	const events = matchEvents.filter(
		(event) => eventOrder.indexOf(event.type) !== -1
	);

	const groupedMatchEvents = events.reduce<{
		[key in MatchEventType]?: models.matchEvent.MatchEvent[];
	}>((result, item) => {
		const { type, ...rest } = item;

		if (!result[type]) {
			result[type] = [];
		}

		result[type].push(rest as models.matchEvent.MatchEvent);
		return result;
	}, {});

	return eventOrder.map((type, index) => {
		const list = groupedMatchEvents[type];

		let home: number;
		let away: number;

		if (isHome) {
			home = list ? list.filter((e) => !e.isOpponent).length : 0;
			away = list ? list.filter((e) => e.isOpponent).length : 0;
		} else {
			home = list ? list.filter((e) => e.isOpponent).length : 0;
			away = list ? list.filter((e) => !e.isOpponent).length : 0;
		}

		return (
			<RenderRow key={index} type={type} homeScore={home} awayScore={away} />
		);
	});
}
