import { useState } from 'react';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';

import StepModal, { Step } from 'components/step-modal';

import * as Input from 'components/form/inputs';
import * as ActionBar from 'components/layout/ActionBar';

import * as Table from 'design/table';

interface MatchCompetitionModalProps {
	matchCompetitions: models.matchCompetition.MatchCompetition[];
	handleMatchCompetitionChange: (id: number) => void;
	closeModal: () => void;
	selectedMatchCompetition: models.matchCompetition.MatchCompetition;
}

const MatchCompetitionModal = ({
	matchCompetitions,
	handleMatchCompetitionChange,
	closeModal,
	selectedMatchCompetition,
}: MatchCompetitionModalProps): JSX.Element => {
	const [searchValue, setSearchValue] = useState('');
	const [id, setId] = useState(
		selectedMatchCompetition ? selectedMatchCompetition.id : null
	);

	const handleNext = async () => handleMatchCompetitionChange(id);
	const handleSearch = (value: string) => setSearchValue(value);

	const searchedMatchCompetitions = matchCompetitions
		.sort((a, b) => a.title.localeCompare(b.title))
		.filter((c) => c.title.toLowerCase().includes(searchValue));

	return (
		<StepModal onClose={closeModal}>
			<Step
				title={t('Select competition')}
				skipBody
				onNext={handleNext}
				canGoNext={id !== null}>
				<ActionBar.FilterBar>
					<ActionBar.PrimaryAction>
						<ActionBar.Search
							onChange={handleSearch}
							placeholder={t('Search')}
						/>
					</ActionBar.PrimaryAction>
				</ActionBar.FilterBar>
				<Table.Table
					columns={[
						{
							content: '',
							width: 'min-content',
						},
						{
							content: t(`Name`),
						},
					]}>
					{searchedMatchCompetitions.map((competition) => (
						<Table.Row
							key={competition.id}
							onClick={() => setId(competition.id)}>
							<Table.Cell>
								<Input.Control
									type="radio"
									checked={competition.id === id}
									standalone
								/>
							</Table.Cell>
							<Table.Cell>{competition.title}</Table.Cell>
						</Table.Row>
					))}
				</Table.Table>
			</Step>
		</StepModal>
	);
};

export default MatchCompetitionModal;
