import { useEffect, useRef, Fragment } from 'react';
import styled from 'styled-components';

import { ToolProps } from 'components/annotations/tools/ToolProps';
import { getPreferenceValue } from 'components/annotations/tools/ToolConfig';
import { RotatingCircle } from 'components/annotations/tools/Circle';
import { generatePath } from 'components/annotations/AnnotationHooks';
import {
	MULTIPOINT,
	MULTIPOINT_CIRCLE_PREFIX,
} from 'components/annotations/constants';

const SVG = styled.svg`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;

const squigglify = (
	followPath: any,
	squigglePath: any,
	squiggleStep: any,
	squiggleAmplitude: any
) => {
	const pathLen = followPath.getTotalLength();

	// Adjust step so that there are a whole number of steps along the path
	const numSteps = Math.round(pathLen / squiggleStep);

	let pos = followPath.getPointAtLength(0);
	let newPath = 'M' + [pos.x, pos.y].join(',');
	let side = -1;
	for (let i = 1; i <= numSteps; i++) {
		const last = pos;
		pos = followPath.getPointAtLength((i * pathLen) / numSteps);

		// Find a point halfway between last and pos. Then find the point that is
		// perpendicular to that line segment, and is squiggleAmplitude away from
		// it on the side of the line designated by 'side' (-1 or +1).
		// This point will be the control point of the quadratic curve forming the
		// squiggle step.

		// The vector from the last point to this one
		const vector = { x: pos.x - last.x, y: pos.y - last.y };
		// The length of this vector
		const vectorLen = Math.sqrt(vector.x * vector.x + vector.y * vector.y);
		// The point halfwasy between last point and tis one
		const half = { x: last.x + vector.x / 2, y: last.y + vector.y / 2 };
		// The vector that is perpendicular to 'vector'
		const perpVector = {
			x: -((squiggleAmplitude * vector.y) / vectorLen),
			y: (squiggleAmplitude * vector.x) / vectorLen,
		};
		// No calculate the control point position
		const controlPoint = {
			x: half.x + perpVector.x * side,
			y: half.y + perpVector.y * side,
		};
		newPath += 'Q' + [controlPoint.x, controlPoint.y, pos.x, pos.y].join(',');
		// Switch the side (for next step)
		side = -side;
	}
	squigglePath.setAttribute('d', newPath);
};

export default function Line({
	annotationId,
	annotation,
	config,
	multipointRef,
	coverSize,
}: ToolProps): JSX.Element {
	const points = annotation.keyframes[0].points;
	const d = generatePath(points);

	const strokeWidth =
		getPreferenceValue(annotation, 'strokeWidth') * (coverSize.width / 1000);

	const rotating = getPreferenceValue(annotation, 'rotating');
	const circleCorners = getPreferenceValue(annotation, 'circleCorners');
	const circleSize = getPreferenceValue(annotation, 'circleSize');

	// get path, update squiggle
	const squiggle = useRef();
	const currentPath = multipointRef.current?.getAttribute('d');
	useEffect(() => {
		if (annotation.squiggly)
			squigglify(multipointRef.current, squiggle.current, 5, 2);
	}, [annotation.squiggly, currentPath]);

	return (
		<SVG
			viewBox="0 0 100 100"
			preserveAspectRatio="none"
			xmlns="http://www.w3.org/2000/SVG">
			{!annotation.squiggly ? (
				// straight line OR connected line
				<g mask={'url(#mask-' + annotationId + ')'}>
					<path
						ref={multipointRef}
						d={d}
						stroke={annotation.color || config.preferences.color.defaultValue}
						strokeWidth={strokeWidth}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeDasharray={annotation.dashed ? strokeWidth * 2 : 'none'}
						vectorEffect="non-scaling-stroke"
						fill="none"
						className={MULTIPOINT}
						mask={'url(#gradient-' + annotationId + ')'}
					/>
					{circleCorners &&
						Object.values(points).map((p: any, i) =>
							rotating ? (
								<svg
									key={i}
									viewBox="0 0 100 100"
									x={p.left * 100 - circleSize}
									y={p.top * 100 - circleSize}
									width={2 * circleSize}
									height={2 * circleSize}
									className={MULTIPOINT_CIRCLE_PREFIX + i}>
									<RotatingCircle annotation={annotation} config={config} />
								</svg>
							) : (
								<ellipse
									key={i}
									cx={p.left * 100}
									cy={p.top * 100}
									rx={0.9 * circleSize}
									ry={1.6 * circleSize}
									fill={
										annotation.color || config.preferences.color.defaultValue
									}
									className={MULTIPOINT_CIRCLE_PREFIX + i}
								/>
							)
						)}
					{rotating ? (
						<Fragment>
							<defs>
								<radialGradient
									id="line-gradient"
									cx="50%"
									cy="50%"
									r="50%"
									fx="50%"
									fy="50%">
									<stop offset="50%" stopColor="#000000" stopOpacity="1" />
									<stop offset="100%" stopColor="#000000" stopOpacity="0" />
								</radialGradient>
							</defs>
							<mask id={'gradient-' + annotationId}>
								<rect x="0" y="0" width="100" height="100" fill="white" />
								{circleCorners &&
									Object.values(points).map((p: any, i) => (
										<ellipse
											key={i}
											cx={p.left * 100}
											cy={p.top * 100}
											rx={circleSize * 2}
											ry={circleSize * 2}
											fill="url(#line-gradient)"
											vectorEffect="non-scaling-stroke"
											className={MULTIPOINT_CIRCLE_PREFIX + i}
										/>
									))}
							</mask>
						</Fragment>
					) : (
						<mask id={'mask-' + annotationId} maskUnits="userSpaceOnUse">
							<rect
								xmlns="http://www.w3.org/2000/svg"
								x="0"
								y="0"
								width="100%"
								height="100%"
								fill="white"
							/>
							{circleCorners &&
								Object.values(points).map((p: any, i) => (
									<ellipse
										key={i}
										cx={p.left * 100}
										cy={p.top * 100}
										rx={0.9 * circleSize}
										ry={1.6 * circleSize}
										strokeWidth={strokeWidth * 2}
										stroke="white"
										fill="black"
										vectorEffect="non-scaling-stroke"
										className={MULTIPOINT_CIRCLE_PREFIX + i}
									/>
								))}
						</mask>
					)}
				</g>
			) : (
				// squiggly line
				<g>
					<path
						ref={multipointRef}
						d={d}
						stroke="none"
						fill="none"
						className={MULTIPOINT}
					/>
					<path
						ref={squiggle}
						d="M0,0"
						stroke={annotation.color || config.preferences.color.defaultValue}
						strokeWidth={strokeWidth}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeDasharray={annotation.dashed ? strokeWidth * 2 : 'none'}
						vectorEffect="non-scaling-stroke"
						fill="none"
					/>
				</g>
			)}
		</SVG>
	);
}
