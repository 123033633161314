import * as React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	margin: var(--spacing-5) auto var(--spacing-3) auto;
`;

const Item = styled.div`
	display: flex;
	align-items: center;

	font-weight: var(--font-weight-semibold);
	color: ${(props) => props.color};

	&:first-child {
		margin-right: var(--spacing-4);
	}

	div {
		width: 10px;
		height: 10px;
		margin-right: var(--spacing-3);
		background-color: ${(props) => props.color};
		border-radius: 50%;
	}
`;

const Primary = styled(Item)`
	color: var(--palette-blue-500);

	div {
		background-color: var(--palette-blue-500);
	}
`;

const Secondary = styled(Item)`
	color: var(--palette-gray-600);

	div {
		background-color: var(--palette-gray-600);
	}
`;

type ColorHintsProps = {
	primary: string;
	secondary: string;
};

const ColorHints: React.FC<React.PropsWithChildren<ColorHintsProps>> = ({
	primary,
	secondary,
}) => (
	<Wrapper>
		<Primary>
			<div /> {primary}
		</Primary>
		<Secondary>
			<div />
			{secondary}
		</Secondary>
	</Wrapper>
);

export default ColorHints;
