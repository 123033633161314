import styled from 'styled-components';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import rgba from 'pkg/rgba';
import useConfirm from 'pkg/hooks/useConfirm';

import {
	OnboardingAnimationDirection,
	useOnboardingContext,
} from 'routes/public/onboarding';

const ButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

const CancelButtonStyles = styled.button`
	color: var(--palette-white);
	background: ${rgba(styles.palette.white, 0.15)};
	padding: 0 var(--spacing-7);
	font-family: var(--font-family-default);
	font-size: var(--font-size-sm);
	font-weight: var(--font-weight-semibold);
	border: none;
	outline: none;
	cursor: pointer;
	text-decoration: none;
	appearance: none;
	user-select: none;
	height: 30px;
	border-radius: 15px;
	transition: box-shadow 120ms ease-in-out;

	@media ${styles.breakpoint.small} {
		font-size: var(--font-size-xs);
	}

	@media (hover: hover) {
		&:hover:not([disabled]) {
			box-shadow: ${rgba(styles.palette.white, 0.5)} 0 0 0 4px;
		}
	}
`;

interface CancelButtonProps {
	disabled?: boolean;
	refresh?: boolean;
}

export default function CancelButton({
	disabled,
	refresh,
}: CancelButtonProps): JSX.Element {
	const { goTo, resetStore } = useOnboardingContext();

	const handleClick = useConfirm({
		message: t(
			'Are you sure you want to cancel? All information you have submitted will be lost.'
		),
		onConfirm: async () => {
			resetStore();
			window.history.pushState({}, '', '/');

			if (refresh) {
				window.location.reload();
			} else {
				goTo('start', OnboardingAnimationDirection.Out);
			}
		},
	});

	return (
		<ButtonWrapper>
			<CancelButtonStyles onClick={handleClick} disabled={disabled}>
				{t('Cancel')}
			</CancelButtonStyles>
		</ButtonWrapper>
	);
}
