import { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import * as palette from 'pkg/config/palette';
import * as zIndex from 'pkg/config/zindex';

const Wrapper = styled.div`
	width: 100%;
	max-width: 100%;
	z-index: ${zIndex.navigation};
	display: flex;
	flex: 1 1 0;
	padding-bottom: env(safe-area-inset-bottom);
	padding-bottom: constant(safe-area-inset-bottom);
	background-color: ${palette.white};
	border-top: 1px solid ${palette.gray[300]};
	justify-content: center;
	transition: transform 150ms linear;
	grid-area: tabbar;

	${(props) =>
		!props.theme.tabBarVisible &&
		css`
			display: none;
		`};

	@media print {
		display: none;
	}
`;

const Container = styled.div`
	width: 100%;
	max-width: 100%;
	display: grid;
	grid-auto-flow: column;
	align-items: center;
	justify-content: stretch;
	grid-auto-columns: 1fr;
`;

/**
 * @type {React.Element<any>}
 */
const TabMenu = forwardRef(({ children }, ref) => (
	<Wrapper ref={ref} id="tab-bar">
		<Container>{children}</Container>
	</Wrapper>
));

export default TabMenu;
