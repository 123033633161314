// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.k2zccP4piEXeQU7mzsOI {
	margin-top: var(--spacing-3);
	display: grid;
	grid-template-columns: 2rem 1fr 1.5rem;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-ms-flex-line-pack: center;
	    align-content: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	grid-gap: var(--spacing-3);
}

.Hrmsc1rf0fz4Nb4ISUnv {
	color: var(--palette-blue-500);
	font-size: var(--font-size-xl);
	-webkit-transition: color 250ms ease-in-out;
	transition: color 250ms ease-in-out;
}

.Hrmsc1rf0fz4Nb4ISUnv.Ml6HqQxCnlYzadNjXOAC {
		color: var(--palette-gray-300);
	}
`, "",{"version":3,"sources":["webpack://./routes/wall/post/comment_form/styles.css"],"names":[],"mappings":"AAAA;CACC,4BAA4B;CAC5B,aAAa;CACb,sCAAsC;CACtC,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CACnB,0BAAqB;KAArB,qBAAqB;CACrB,wBAAuB;KAAvB,qBAAuB;SAAvB,uBAAuB;CACvB,0BAA0B;AAC3B;;AAEA;CACC,8BAA8B;CAC9B,8BAA8B;CAC9B,2CAAmC;CAAnC,mCAAmC;AAKpC;;AAHC;EACC,8BAA8B;CAC/B","sourcesContent":[".wrapper {\n\tmargin-top: var(--spacing-3);\n\tdisplay: grid;\n\tgrid-template-columns: 2rem 1fr 1.5rem;\n\talign-items: center;\n\talign-content: center;\n\tjustify-content: center;\n\tgrid-gap: var(--spacing-3);\n}\n\n.sendIcon {\n\tcolor: var(--palette-blue-500);\n\tfont-size: var(--font-size-xl);\n\ttransition: color 250ms ease-in-out;\n\n\t&.disabled {\n\t\tcolor: var(--palette-gray-300);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var wrapper = `k2zccP4piEXeQU7mzsOI`;
export var sendIcon = `Hrmsc1rf0fz4Nb4ISUnv`;
export var disabled = `Ml6HqQxCnlYzadNjXOAC`;
export default ___CSS_LOADER_EXPORT___;
