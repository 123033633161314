import { ReactNode } from 'react';

export const symbol_dynamic_form: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-510q-24.75 0-42.375-17.625T80-570v-170q0-24.75 17.625-42.375T140-800h370q12.75 0 21.375 8.625T540-770v230q0 12.75-8.625 21.375T510-510H140Zm0-60h340v-170H140v170Zm0 410q-24.75 0-42.375-17.625T80-220v-170q0-24.75 17.625-42.375T140-450h450q12.75 0 21.375 8.625T620-420v230q0 12.75-8.625 21.375T590-160H140Zm0-60h420v-170H140v170Zm540-290h-50q-12.75 0-21.375-8.625T600-540v-230q0-12.75 8.625-21.375T630-800h207q16 0 24.5 12.5T865-760l-65 180h32q17.5 0 26.25 14t1.75 29L709-170q-2 5-7 7.5t-10 1.5q-5-1-8.5-4.889Q680-169.778 680-176v-334Zm-540-60v-170 170Zm0 350v-170 170Zm115-435q0-13-8.5-21.5T225-685q-13 0-21.5 8.5T195-655q0 13 8.5 21.5T225-625q13 0 21.5-8.5T255-655Zm-30 380q13 0 21.5-8.5T255-305q0-13-8.5-21.5T225-335q-13 0-21.5 8.5T195-305q0 13 8.5 21.5T225-275Z"
		/>
	</svg>
);
