import { Map, Record } from 'immutable';

import Membership, { membershipId } from 'pkg/models/membership';

import {
	Users,
	RATING_ADD_SINGLE_USER_RATING,
	AUTH_LOGOUT_SUCCESS,
	Memberships as membershipsType,
} from 'pkg/actions/action-types';

const initialState = Record({ items: new Map({}) }, 'membershipReducer');

const setMemberships = (state, action) => {
	const { memberships } = action.payload.entities;

	if (!memberships) {
		return state;
	}

	Object.entries(memberships).forEach(([, membership]) => {
		membership = new Membership(membership);

		if (Number.isInteger(membership.group)) {
			membership = membership.set('groupId', membership.group);
		}

		state = state.setIn(['items', membershipId(membership)], membership);
	});

	return state;
};

const setActiveMembership = (state, action) => {
	const { membership, targetUserId } = action;

	if (targetUserId !== 0) {
		state = state.setIn(
			['items', membershipId(membership)],
			new Membership(membership)
		);
	}

	return state;
};

const removeMembership = (state, action) => {
	const membership = state
		.get('items')
		.find(
			(m) =>
				m.get('userId') == action.userId && m.get('groupId') == action.groupId
		);

	if (!membership) return state;

	return state.removeIn(['items', membershipId(membership)]);
};

const setMembershipRating = (state, action) => {
	let membership = state
		.get('items')
		.find((m) => m.get('userId') === action.userId);

	if (!membership) return state;

	if (action.rating?.isPrimaryRating) {
		membership = membership.set('lastRating', action.rating);
	}

	if (action.rating?.raterUserId === action.rating?.userId) {
		membership = membership.set('lastSelfRating', action.rating);
	}

	return state.setIn(['items', membershipId(membership)], membership);
};

const memberships = (state = initialState(), action = {}) => {
	switch (action.type) {
		case Users.SET_ACTIVE_MEMBERSHIP:
			return setActiveMembership(state, action);
		case membershipsType.DELETE_ITEM:
			return removeMembership(state, action);
		case membershipsType.SET_ITEMS:
			return setMemberships(state, action);
		case RATING_ADD_SINGLE_USER_RATING:
			return setMembershipRating(state, action);
		case AUTH_LOGOUT_SUCCESS:
			return initialState();
	}

	return state;
};

export default memberships;
