import { ReactNode } from 'react';

export const symbol_burst_mode: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M40-230v-500q0-12.75 8.675-21.375Q57.351-760 70.175-760 83-760 91.5-751.375T100-730v500q0 12.75-8.675 21.375Q82.649-200 69.825-200 57-200 48.5-208.625T40-230Zm160 0v-500q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T260-730v500q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T200-230Zm220 30q-24 0-42-18t-18-42v-440q0-24 18-42t42-18h440q24 0 42 18t18 42v440q0 24-18 42t-42 18H420Zm0-60h440v-440H420v440Zm200-120-49-66q-5-6-12-6t-12 6l-49 65q-5 8-1 16t13 8h264q8.5 0 12.75-8t-.75-16l-70-93q-5-6-12-6t-12 6l-72 94ZM420-260v-440 440Z"
		/>
	</svg>
);
