import { ReactNode } from 'react';

export const symbol_raw_on: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M120-384v-194q0-9 7-15.5t16-6.5h121q23 0 35.5 12.5T312-552v63q0 20-12 30.5T271-446l24 55q5 11-2 21t-19 10q-8 0-13.5-4t-8.5-11l-26-66h-58v57q0 10-7 17t-17 7q-10 0-17-7t-7-17Zm235-7 49-179q4-13 14.5-21.5T443-600h23q14 0 24 8t14 21l52 178q4 13-4 23t-21 10q-8 0-15-5.5t-9-13.5l-13-42h-82l-11 43q-2 8-8.5 13t-14.5 5q-12 0-19-9.5t-4-21.5Zm277 14-50-194q-3-11 4.5-20t19.5-9q8 0 14.5 5t8.5 13l32 124 30-125q2-8 7.5-12.5T712-600h11q8 0 13.5 4t7.5 12l33 126 29-123q2-8 8.5-13.5T829-600q12 0 19.5 9.5T853-570l-47 192q-2 8-8.5 13t-14.5 5h-12q-8 0-15-5t-9-13l-28-113-32 115q-2 8-8 12t-14 4h-12q-8 0-13.5-4.5T632-377Zm-206-92h53l-18-67h-17l-18 67Zm-258-20h96v-63h-96v63Z"
		/>
	</svg>
);
