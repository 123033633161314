import { ReactNode } from 'react';

export const symbol_scuba_diving: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m205-222 46-195q5-21 18-36t35-22l383-117 82-171q1-2 6-9l90-90q8-8 18.5-7.5T902-861q8 8 8 18t-7 18l-84 92-64 178q-2 5-4.5 8t-6.5 6L556-409l-238 75-60 140-1.5 3-1.5 3-93 124q-8 10-20 12t-22-6q-10-8-12-20t6-22l91-122Zm-92-145q-30 0-51.5-21.5T40-440q0-30 21.5-51.5T113-513q30 0 51.5 21.5T186-440q0 30-21.5 51.5T113-367Zm220-166q-20 6-37.5-4.5T272-568q-5-20 4.5-37.5T306-629l153-46q12-4 23.5 2.5T497-654l10 39q3 12-2.5 22T487-579l-154 46Z"
		/>
	</svg>
);
