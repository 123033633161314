import { ReactNode } from 'react';

export const symbol_lab_panel: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M100-120q-24.75 0-42.375-17.625T40-180v-240q0-24.75 17.625-42.375T100-480h20v-163q-17-6-28.5-21.388Q80-679.776 80-700v-80q0-24.75 17.625-42.375T140-840h240q24.75 0 42.375 17.625T440-780v80q0 20.224-11.5 35.612Q417-649 400-643v163h160v-163q-17-6-28.5-21.388Q520-679.776 520-700v-80q0-24.75 17.625-42.375T580-840h240q24.75 0 42.375 17.625T880-780v80q0 20.224-11.5 35.612Q857-649 840-643v163h20q24.75 0 42.375 17.625T920-420v240q0 24.75-17.625 42.375T860-120H100Zm480-580h240v-80H580v80Zm-440 0h240v-80H140v80Zm480 220h160v-160H620v160Zm-440 0h160v-160H180v160Zm-80 300h760v-240H100v240Zm40-520v-80 80Zm440 0v-80 80ZM100-180v-240 240Z"
		/>
	</svg>
);
