import { ReactNode } from 'react';

export const symbol_special_character: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-780q-100 0-170 70t-70 170q0 75 41.5 136T392-316q20 8 34 24.5t14 38.5v88q0 19-13 32t-32 13H170q-21 0-35.5-14.5T120-170q0-21 14.5-35.5T170-220h195q-102-37-163.5-124.5T140-540q0-142 99-241t241-99q142 0 241 99t99 241q0 108-61.5 195.5T595-220h195q21 0 35.5 14.5T840-170q0 21-14.5 35.5T790-120H565q-19 0-32-13t-13-32v-88q0-22 14-38.5t34-24.5q69-27 110.5-88T720-540q0-100-70-170t-170-70Z"
		/>
	</svg>
);
