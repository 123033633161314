import { ReactNode } from 'react';

export const symbol_perm_phone_msg: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M496-524v-286q0-12.75 8.625-21.375T526-840h284q12.75 0 21.375 8.625T840-810v192q0 12.75-8.625 21.375T810-588H600l-79 75q-8 7-16.5 3.25T496-524Zm60-124h224v-132H556v132Zm0 0v-132 132Zm239 528q-116 0-236.5-56T335-335Q232-438 176-558.5T120-795q0-19.286 12.857-32.143T165-840h140q14 0 24 10t14 25l26.929 125.641Q372-665 369.5-653.5q-2.5 11.5-10.729 19.726L259-533q26 44 55 82t64 72q37 38 78 69.5t86 55.5l95-98q10-11 23.151-15T686-369l119 26q15 4 25 16.044T840-300v135q0 19.286-12.857 32.143T795-120ZM229-588l81-82-23-110H180q2 42 13.5 88.5T229-588Zm369 363q41 19 89 31t93 14v-107l-103-21-79 83ZM229-588Zm369 363Z"
		/>
	</svg>
);
