import { ReactNode } from 'react';

export const symbol_web_asset_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-160q-24.75 0-42.375-17.625T80-220v-520q0-24.75 17.625-42.375T140-800h20v86L47-827q-9-9.067-9-21.533Q38-861 47.053-870q9.052-9 21.5-9Q81-879 90-870l765 765q9 9 9 21t-9.053 21q-9.052 9-21.5 9Q821-54 812-63l-98-97H140Zm0-60h514L218-656h-78v436Zm740-520v512q0 15-9.318 22.5t-20.5 7.5Q839-198 829.5-205.683 820-213.366 820-228v-428H415q-12.444 0-23.722-5T372-674l-75-75q-6.923-7.812-8.462-16.406Q287-774 290-782q3-8 10.5-13t17.5-5h502q24.75 0 42.375 17.625T880-740Z"
		/>
	</svg>
);
