import { Fragment } from 'react';

import * as models from 'pkg/api/models';

import Icon, { IconName } from 'components/icon';

interface EventIconsProps {
	size?: number;

	event: models.event.Event;
}

export default function EventIcons({ event, size = 1.4 }: EventIconsProps) {
	const icons: IconName[] = [];

	if (models.event.hasEventSeries(event)) icons.push('sync');

	if (event.isPrivate) icons.push('eye-hidden');

	return (
		<Fragment>
			{icons.map((name, index) => (
				<Fragment key={index}>
					<Icon name={name} actualSize size={size} />
				</Fragment>
			))}
		</Fragment>
	);
}
