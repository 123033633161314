export enum AuthorType {
	User = 'user',
	Organization = 'organization',
	TSPlayer = '360Player',
}

export interface Author {
	type: AuthorType;
	name: string;
	profileImageUrl: string;
	initials: string;
	firstName?: string;
	lastName?: string;
}
