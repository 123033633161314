import { normalize } from 'normalizr';
import { t } from '@transifex/native';

import * as flashes from 'pkg/actions/flashes';
import * as service from 'pkg/actions/services/match_videos';
import { matchVideosSchema } from 'pkg/actions/entities/video';
import {
	ADD_VIDEOS,
	ADD_MATCH_VIDEOS,
	REMOVE_MATCH_VIDEO,
} from 'pkg/actions/action-types';

import * as sdk from 'pkg/core/sdk';
import * as endpoints from 'pkg/api/endpoints/auto';

/* Dispatches */

const actionAddVideos = (payload: any) => ({
	type: ADD_VIDEOS,
	payload,
});

const actionAddMatchVideos = (matchId: number, payload: any) => ({
	type: ADD_MATCH_VIDEOS,
	matchId,
	payload,
});

const actionRemoveMatchVideo = (matchId: number, videoId: any) => ({
	type: REMOVE_MATCH_VIDEO,
	matchId,
	videoId,
});

/* Actions */

export const removeMatchVideo = (matchId: number, videoId: number) => {
	const ok = sdk.destroy(endpoints.Match.DeleteVideo(matchId, videoId));

	if (ok) {
		flashes.show({
			title: t('Successfully removed connection.'),
		});

		return true;
	}

	flashes.show({
		title: t('Something went wrong.'),
	});

	return false;
};

export const deprecatedRemoveMatchVideo =
	(matchId: number, videoId: number) => async (dispatch: any) => {
		const response = await service.destroyMatchVideo(matchId, videoId);

		if (response.ok) {
			dispatch(actionRemoveMatchVideo(matchId, videoId));

			flashes.show({
				title: t('Video hidden'),
				message: t('Successfully hid video from match'),
			});
		}
	};

export const createMatchVideo =
	(matchId: number, videoId = 0) =>
	async (dispatch: any, getState: any) => {
		if (videoId === 0) {
			return;
		}

		const video = getState().videos.entities.get(videoId.toString());

		const request = await service.createMatchVideo(matchId, videoId);

		if (request.ok) {
			let response = await request.json();
			response = { ...response, video: video.toJS() };

			const data = normalize([response], [matchVideosSchema]);

			dispatch(actionAddVideos(data));
			dispatch(actionAddMatchVideos(matchId, data.entities.matchVideos));

			flashes.show(
				{
					title: t('Video created'),
					message: t('Video was successfully created!'),
				},
				200
			);
		}
	};
