// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AZ7XPxVsEtoLXWg3dKh8 {
	font-size: var(--font-size-sm);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	    -ms-flex-direction: row;
	        flex-direction: row;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	gap: var(--spacing-2);
}
`, "",{"version":3,"sources":["webpack://./components/event-user/schedule-event/styles.css"],"names":[],"mappings":"AAAA;CACC,8BAA8B;CAC9B,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,8BAAmB;CAAnB,6BAAmB;KAAnB,uBAAmB;SAAnB,mBAAmB;CACnB,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CACnB,qBAAqB;AACtB","sourcesContent":[".name {\n\tfont-size: var(--font-size-sm);\n\tdisplay: flex;\n\tflex-direction: row;\n\talign-items: center;\n\tgap: var(--spacing-2);\n}\n"],"sourceRoot":""}]);
// Exports
export var name = `AZ7XPxVsEtoLXWg3dKh8`;
export default ___CSS_LOADER_EXPORT___;
