import * as models from 'pkg/api/models';
import { EndpointResponse } from 'pkg/api/use_endpoint';

import EventUserActions from 'routes/event/user-actions';

import EventUserCard from 'components/event/user-card';

interface EventUserAttendanceProps {
	eventUser: models.eventUser.EventUser;
	event: models.event.Event;

	eventEndpoint: EndpointResponse<models.event.Event>;
}

export default function EventUserAttendance({
	eventUser,
	event,
	eventEndpoint,
}: EventUserAttendanceProps) {
	const hasEnded = models.event.hasEnded(event);
	const hasPendingInvite =
		eventUser?.status === models.eventUser.EventUserStatus.Pending;

	if (hasEnded) {
		return <EventUserCard eventUser={eventUser} event={event} />;
	}

	if (hasPendingInvite) {
		return (
			<EventUserCard eventUser={eventUser} event={event}>
				<EventUserActions
					event={event}
					eventUser={eventUser}
					refresh={eventEndpoint.refresh}
				/>
			</EventUserCard>
		);
	}

	if (!hasPendingInvite && eventUser?.userId) {
		return (
			<div>
				<EventUserActions
					event={event}
					eventUser={eventUser}
					refresh={eventEndpoint.refresh}
					toggleWith={<EventUserCard eventUser={eventUser} event={event} />}
				/>
			</div>
		);
	}

	return null;
}
