import { ReactNode } from 'react';

export const symbol_hdr_strong: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M185-330q-61 0-103-42T40-475q0-62 42-103.5T185-620q62 0 103.5 41.5T330-475q0 61-41.5 103T185-330Zm0-60q39 0 62-23t23-62q0-39-23-62t-62-23q-39 0-62 23t-23 62q0 39 23 62t62 23Zm495 150q-99 0-169.5-70.5T440-480q0-99 70.5-169.5T680-720q99 0 169.5 70.5T920-480q0 99-70.5 169.5T680-240Z"
		/>
	</svg>
);
