import { useEventListener } from 'pkg/hooks/events';
import { useViewportFetchLimit } from 'pkg/hooks/library';
import { useCurrentGroup } from 'pkg/identity';

import { useGroupLibrary } from 'routes/video/library/hooks';
import Library from 'routes/video/library/Library';

export default function Group(): JSX.Element {
	const group = useCurrentGroup();

	const fetchLimit = useViewportFetchLimit();
	const library = useGroupLibrary(group.id, fetchLimit);

	useEventListener('video-collection-saved', () => {
		library.refresh();
	});

	return <Library library={library} />;
}
