import { ReactNode } from 'react';

export const symbol_dermatology: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M110-120q-12.75 0-21.375-8.625T80-150v-350q0-24.75 17.625-42.375T140-560h190q5.882 0 11.397 2.294 5.515 2.294 9.559 6.5Q355-547 357.5-541.457 360-535.913 360-530v50q0 50 35 85t85 35q50 0 85-35t35-85v-50q0-5.882 2.294-11.397 2.294-5.515 6.5-9.559Q613-555 618.543-557.5 624.087-560 630-560h190q24.75 0 42.375 17.625T880-500v350q0 12.75-8.625 21.375T850-120H110Zm30-60h680v-320H660v20q0 74.7-52.712 127.35Q554.576-300 479.788-300T352.5-352.65Q300-405.3 300-480v-20H140v320Zm339.825-280Q467-460 458.5-468.625T450-490q0-107 24.5-210T580-872q10-8 21.907-6.969Q613.814-877.938 622-869q8 10 7.5 22T619-827q-72 60-90.5 151.307Q510-584.385 510-490q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM230-340q13 0 21.5-8.5T260-370q0-13-8.5-21.5T230-400q-13 0-21.5 8.5T200-370q0 13 8.5 21.5T230-340Zm40 100q13 0 21.5-8.5T300-270q0-13-8.5-21.5T270-300q-13 0-21.5 8.5T240-270q0 13 8.5 21.5T270-240Zm460-100q13 0 21.5-8.5T760-370q0-13-8.5-21.5T730-400q-13 0-21.5 8.5T700-370q0 13 8.5 21.5T730-340ZM140-180h680-680Z"
		/>
	</svg>
);
