import { ReactNode } from 'react';

export const symbol_checkroom: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M200-220h560L480-443 200-220Zm206-472q-3 8-10 13.5t-17 5.5q-13 0-21.5-8.5T349-703v-6q0-3 2-6 17-38 52-61.5t77-23.5q58 0 99 40.5t41 98.5q0 49-31 86.5T510-523v28l358 281q6 5 9 11t3 13q0 13-8.5 21.5T850-160H110q-13 0-21.5-8.5T80-190q0-7 3-13t9-11l358-281v-55q0-12 8.5-21t20.5-9q34 0 57.5-23.5T560-661q0-33-23.5-56T480-740q-24 0-44 13t-30 35Z"
		/>
	</svg>
);
