import { ReactNode } from 'react';

export const symbol_circles: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M438-80q-74 0-139.5-28t-114-76.5Q136-233 108-298.5T80-438q0-113 63-206.5T313-776q22-8 41.5 4.5t24.738 35.457Q384-715 372-696t-33 27q-70 29-112 92.5T185-438q0 106 73.5 179.5T438-185q77 0 142-41.5T674-340q8-20 25.5-32.5t37.684-7.794Q760-375 772-356q12 19 4 39-38 108-131 172.5T438-80Zm247-840q97.5 0 165.75 68.353t68.25 166Q919-588 850.647-519.5q-68.353 68.5-166 68.5T518.5-519.542Q450-588.083 450-686q0-97.5 68.542-165.75Q587.083-920 685-920Zm-.5 364q51.5 0 90.5-39t39-90.5q0-51.5-39-90.5t-90.5-39q-51.5 0-90.5 39t-39 90.5q0 51.5 39 90.5t90.5 39Zm.5-130ZM439-437Z"
		/>
	</svg>
);
