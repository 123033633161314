import { link } from 'pkg/router/utils';

function route(
	type: 'push' | 'pop' | 'replace',
	location: string,
	state: object | string = {}
) {
	location = link(location, state);

	if (type === 'pop' || location === '?') {
		location = '';
	}

	window.dispatchEvent(
		new CustomEvent(`router:${type}`, {
			bubbles: true,
			detail: {
				location,
				state,
			},
		})
	);
}

export function pushState(location: string, state: object | string = {}) {
	route('push', location, state);
}

export function popState(state: object | string = {}) {
	route('pop', '', state);
}

export function replaceState(location: string, state: object | string = {}) {
	route('replace', location, state);
}
