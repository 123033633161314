import { ReactNode } from 'react';

export const symbol_flatware: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M230-120q-13 0-21.5-8.5T200-150v-381q-33 0-56.5-23.5T120-611v-206q0-9 7-16t16-7q9 0 16.5 7t7.5 16v142h40v-142q0-9 7-16t16-7q9 0 16 7t7 16v142h40v-142q0-9 7.5-16t16.5-7q9 0 16 7t7 16v206q0 33-23.5 56.5T260-531v381q0 13-8.5 21.5T230-120Zm280 0q-13 0-21.5-8.5T480-150v-383q-41-23-62-62t-21-90q0-60 30.5-107.5T511-840q53 0 83.5 47.5T625-685q0 51-22 90t-63 62v383q0 13-8.5 21.5T510-120Zm214 0q-13 0-21.5-8.5T694-150v-653q0-12 9-21t21-9q43 0 79.5 43.5T840-694v214q0 13-8.5 21.5T810-450h-56v300q0 13-8.5 21.5T724-120Z"
		/>
	</svg>
);
