import { ReactNode } from 'react';

export const symbol_pulse_alert: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M459-490ZM98-530q-9-23-13.5-46T80-625q0-91 61-153t149-62q57 0 106 26.5t84 77.5q36-51 84.5-77.5T670-840q88 0 149 62t61 153q0 5-.5 9.5t-.5 9.5q-14-13-28.5-23.5T819-648q-8-58-48.5-95T670-780q-51 0-95 31.5T505-661h-50q-26-55-70-87t-95-32q-64 0-107 44.5T140-625q0 25.244 6 48.122T165-530H98Zm283 318q-47-43-83-77t-63-61h84q30 29 70 65.5t91 84.5q23.283-19.95 43.141-38.475Q543-257 561-274q11 11 22.5 19t24.5 16q-7 7-14 13.5T579-212l-58 54q-8.462 8-19.231 12Q491-142 480-142q-11 0-21.5-4t-19.274-12.158L381-212Zm309-228v-120q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T750-560v120q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T690-440ZM80-410q-12.75 0-21.375-8.675Q50-427.351 50-440.175 50-453 58.625-461.5T80-470h144l72-107q4.545-6.882 10.985-9.941Q313.424-590 321-590q7.576 0 14.015 3.5Q341.455-583 346-576l70 106h76q-1 7.5-1.5 14.625T490-440q0 8.25.5 15.375T492-410h-92q-8 0-14-3.5t-11-9.5l-55-83-55 83q-5 6-11 9.5t-14 3.5H80Zm639.825 120Q707-290 698.5-298.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Z"
		/>
	</svg>
);
