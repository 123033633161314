import { ReactNode } from 'react';

export const symbol_skip_next: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M680-270v-420q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T740-690v420q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T680-270Zm-460-27v-366q0-13.5 9-21.75t21-8.25q5 0 9 1.5t8 4.5l263 182q7 5 10 11.296 3 6.297 3 13.704 0 7.407-3 13.704Q537-460 530-455L267-273q-4 3-8 4.5t-9 1.5q-12 0-21-8.25T220-297Zm60-183Zm0 125 181-125-181-125v250Z"
		/>
	</svg>
);
