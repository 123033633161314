import { ReactNode } from 'react';

export const symbol_swap_driving_apps_wheel: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-80q-84 0-157-31t-127-85q-54-54-85-127T80-480q0-83.728 31-156.864Q142-710 196-764t127-85q73-31 157-31 83.728 0 156.864 31Q710-818 764-764t85 127.136Q880-563.728 880-480q0 84-31 157t-85 127q-54 54-127.136 85T480-80Zm.205-217Q542-297 600.5-280T711-229q52-49 80.5-113.929Q820-407.857 820-480q0-142.375-98.812-241.188Q622.375-820 480-820t-241.188 98.812Q140-622.375 140-480q0 72.104 28.5 137.552T250-229q51-34 109.705-51t120.5-17ZM480-237q-45 0-89.5 12.5T304-188q42 23 86.5 35.5T480-140q45 0 89.5-12.5T656-188q-42-24-86.5-36.5T480-237ZM267.175-538q12.825 0 21.325-8.675 8.5-8.676 8.5-21.5 0-12.825-8.675-21.325-8.676-8.5-21.5-8.5-12.825 0-21.325 8.675-8.5 8.676-8.5 21.5 0 12.825 8.675 21.325 8.676 8.5 21.5 8.5Zm131-126q12.825 0 21.325-8.675 8.5-8.676 8.5-21.5 0-12.825-8.675-21.325-8.676-8.5-21.5-8.5-12.825 0-21.325 8.675-8.5 8.676-8.5 21.5 0 12.825 8.675 21.325 8.676 8.5 21.5 8.5Zm296 126q12.825 0 21.325-8.675 8.5-8.676 8.5-21.5 0-12.825-8.675-21.325-8.676-8.5-21.5-8.5-12.825 0-21.325 8.675-8.5 8.676-8.5 21.5 0 12.825 8.675 21.325 8.676 8.5 21.5 8.5ZM479.92-407q30.08 0 51.58-21.444T553-480q0-15.053-6-29.526Q541-524 530-534l67-156q5-11 1.5-22t-14.855-16q-10.645-5-22.794-.069Q548.703-723.138 544-711l-69 158q-29 2-48.5 23T407-480q0 30.112 21.42 51.556Q449.841-407 479.92-407ZM481-297Zm-1-228Zm0 288Z"
		/>
	</svg>
);
