import { ReactNode } from 'react';

export const symbol_campaign: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M850-450h-90q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T760-510h90q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-450ZM677-274q8-10 19.826-12T719-280l73 54q10 8 12 19.826T798-184q-8 10-19.826 12T756-178l-73-54q-10-8-12-19.826T677-274Zm115-460-70 53q-10.348 8-22.174 6Q688-677 680-687t-6-22q2-12 12-20l70-53q10.348-8 22.174-6Q790-786 798-776t6 22q-2 12-12 20ZM210-360h-70q-24.75 0-42.375-17.625T80-420v-120q0-24.75 17.625-42.375T140-600h180l155-93q15-9 30-.064T520-667v374q0 17.128-15 26.064T475-267l-155-93h-50v130q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T210-230v-130Zm250 14v-268l-124 74H140v120h196l124 74Zm100 0v-268q27 24 43.5 58.5T620-480q0 41-16.5 75.5T560-346ZM300-480Z"
		/>
	</svg>
);
