import { useState } from 'react';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import {
	useCurrentAccountUserIds,
	useCurrentGroupId,
	useCurrentMembership,
} from 'pkg/identity';
import { useCollection } from 'pkg/api/use_collection';
import * as endpoints from 'pkg/api/endpoints/auto';

import Contacts from 'containers/chat/Contacts';

import StepModal, { Step } from 'components/step-modal';

import { Spinner } from 'components/loaders/spinner';

interface AddChatUsersModalProps {
	chat: models.chat.Chat;
	onClose: () => void;
}

function AddChatUsersModal({ chat, onClose }: AddChatUsersModalProps) {
	const activeGroupId = useCurrentGroupId();
	const activeUserIds = useCurrentAccountUserIds();
	const activeMembership = useCurrentMembership();

	const { records, isLoading } = useCollection<models.membership.Membership>(
		endpoints.Groups.ShowUsers(activeGroupId),
		{
			queryParams: new URLSearchParams({ includeLegalGuardians: '1' }),
		}
	);

	const contacts = models.membership.filterActiveMembership(
		records,
		activeUserIds,
		activeMembership
	);

	const chatUsers = models.chatUser.filterDeleted(chat.users);

	const chatUserIds: number[] = [];
	chatUsers.map((chatUser) => chatUserIds.push(chatUser.userId));

	const [selectedUsers, setSelectedUsers] = useState<models.user.User[]>([]);

	const selectUser = (users: models.user.User[]) => {
		setSelectedUsers([...users]);
	};

	const updateGroupMembers = async () => {
		for (const user of selectedUsers) {
			await models.create<
				models.chatUser.CreateChatUserPayload,
				models.chatUser.ChatUser
			>(endpoints.Chat.CreateUser(chat.id), {
				userId: user.id,
			});
		}

		onClose();
	};

	let content = (
		<Contacts
			userClick={selectUser}
			selectedUsers={selectedUsers}
			hiddenUserIds={chatUserIds}
			multipleChoice
			showPreview
			contacts={contacts}
		/>
	);

	if (isLoading) {
		content = <Spinner />;
	}

	return (
		<StepModal onClose={onClose}>
			<Step
				title={t('Add chat members')}
				prevLabel={t('Cancel')}
				onNext={updateGroupMembers}
				nextLabel={t('Save')}
				canGoNext={selectedUsers.length > 0}
				skipBody>
				{content}
			</Step>
		</StepModal>
	);
}

export default AddChatUsersModal;
