import { ReactNode } from 'react';

export const symbol_domain_disabled: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M880-615v402q0 15-9.318 22.5t-20.5 7.5Q839-183 829.5-190.683 820-198.366 820-213v-402H460q-24.75 0-42.375-17.625T400-675v-105H254q-15 0-22.5-9.318t-7.5-20.5q0-11.182 7.5-20.682Q239-840 254-840h146q24.75 0 42.375 17.625T460-780v105h360q24.75 0 42.375 17.625T880-615ZM813-49l-71-71H140q-24.75 0-42.375-17.625T80-180v-601l-31-31q-9-9-9-21t9-21q9-9 21.5-9t21.5 9L855-91q9 9 9 21t-9 21q-9 9-21 9t-21-9ZM140-180h105v-105H140v105Zm0-165h105v-105H140v105Zm0-165h105v-105H140v105Zm165 330h105v-105H305v105Zm0-165h105v-105H305v105Zm165 165h211L576-285H470v105Zm245-270h-60v-60h60v60Z"
		/>
	</svg>
);
