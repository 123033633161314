import { t } from '@transifex/native';

import User from 'pkg/models/user';

import DataRow from 'containers/user/profile/info-contact/DataRow';

import { FormattedContent } from 'components/formatted-content';
import * as Card from 'components/Card';
import SectionTitle from 'components/SectionTitle';

import Column from 'components/layout/column';
import { DataRowProps } from 'components/user-address/DataList';

interface OptionalInformationProps {
	user: User;
}

export default function OptionalInformation({
	user,
}: OptionalInformationProps): JSX.Element {
	const labels = {
		allergies: t(`Allergies`),
		misc: t(`Other`),
	};

	const rows: DataRowProps[] = [];

	// Allergies
	if (user.allergies) {
		rows.push({
			label: labels.allergies,
			value: user.allergies,
		});
	} else if (!user.isScrubbed()) {
		rows.push({
			label: labels.allergies,
		});
	}

	// Other/misc
	if (user.misc) {
		rows.push({
			label: labels.misc,
			value: user.misc,
		});
	} else if (!user.isScrubbed()) {
		rows.push({
			label: labels.misc,
		});
	}

	if (rows.length === 0) {
		return null;
	}

	return (
		<Column>
			<SectionTitle icon="contact_page">
				{t(`Optional information`)}
			</SectionTitle>
			<Card.Base $noBorder>
				<Card.Body>
					{rows.map((row, i) => (
						<DataRow
							key={i}
							label={row.label}
							value={
								row.value && <FormattedContent raw={row.value.toString()} />
							}
						/>
					))}
				</Card.Body>
			</Card.Base>
		</Column>
	);
}
