import { ComponentType, createElement, memo } from 'react';

import * as models from 'pkg/api/models';

interface UserContainerProps {
	item: ComponentType;

	user: models.user.User;
}

const UserContainer = memo(({ user, item }: UserContainerProps) => {
	if (!user) {
		return null;
	}

	return createElement<any>(item, { user });
});

export default UserContainer;
