import { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import VideoSequence from 'pkg/models/video_sequence';
import Account from 'pkg/models/account';

import { useCurrentFilteredClips } from 'pkg/hooks/selectors';
import { useCurrentRoute } from 'pkg/router/hooks';
import { RootState } from 'pkg/reducers';
import * as selectors from 'pkg/selectors';
import { cuePointsFromSequences } from 'pkg/video';

import Group from 'routes/video/shared/clips/Group';
import ListWrapper from 'routes/video/shared/clips/List';
import ListItem from 'routes/video/shared/clips/ListItem';

import Icon from 'components/icon';

import PlaylistModal from 'components/video-library/modals/Playlist';
import * as Input from 'components/form/inputs';
import Row from 'components/layout/row';
import { CueGroupType } from 'components/video-analytics/CueState';

import Button from 'design/button';

const SelectWrapper = styled(Row)`
	font-size: var(--font-size-sm);
	cursor: pointer;
	align-items: center;
`;

export type ClipListContext = 'play-mode' | 'edit-mode';

interface ClipListProps {
	context: ClipListContext;
	groupByAccount?: boolean;
	onItemClick?: () => Promise<void>;
}

export default function ClipList({
	context,
	groupByAccount,
	onItemClick,
}: ClipListProps): JSX.Element {
	const { videoId } = useCurrentRoute();
	const items = useCurrentFilteredClips();
	const itemCuePoints = cuePointsFromSequences(items);

	const [addModalOpen, setAddModalOpen] = useState<boolean>(false);
	const [isSelecting, setSelecting] = useState<boolean>(false);
	const [selectedIds, setSelectedIds] = useState<number[]>([]);

	const allItemIds = items.map((clip: VideoSequence) => clip.id).toArray();
	const allItemsSelected = allItemIds.length === selectedIds.length;
	const groupedItems = items.groupBy((item: VideoSequence) => item.accountId);

	const highlights = useSelector((state: RootState) =>
		selectors.videoSequences.findAllHighlights(state, videoId)
	);

	const highlightCuePoints = cuePointsFromSequences(highlights);

	const accounts = useSelector((state: RootState) =>
		selectors.videoSequences.findAllAvailableAuthors(state, videoId)
	).toList();

	const activateSelecting = () => setSelecting(true);

	const deactivateSelecting = () => {
		setSelectedIds([]);
		setSelecting(false);
	};

	const handleToggleSelectAll = () => {
		if (!allItemsSelected) {
			setSelectedIds(allItemIds);
		} else {
			setSelectedIds([]);
		}
	};

	const handleItemToggle = (clipId: number) => {
		if (selectedIds.includes(clipId)) {
			setSelectedIds(selectedIds.filter((id: number) => id !== clipId));
		} else {
			setSelectedIds([...selectedIds, clipId]);
		}
	};

	const openAddModal = () => {
		setAddModalOpen(true);
	};

	const closeAddModal = () => {
		setAddModalOpen(false);
		deactivateSelecting();
	};

	const actions = isSelecting ? (
		<Row columns="1fr auto auto" align="center">
			<SelectWrapper
				columns="20px auto"
				spacing={styles.spacing._3}
				onClick={handleToggleSelectAll}>
				<Input.Control type="checkbox" standalone checked={allItemsSelected} />
				{t('Select all')}
			</SelectWrapper>
			<Button
				small
				icon="playlist_add"
				onClick={openAddModal}
				disabled={selectedIds.length === 0}>
				{t('Add to playlist')}
			</Button>
			<Button small onClick={deactivateSelecting}>
				{t('Cancel')}
			</Button>
		</Row>
	) : (
		<SelectWrapper
			columns="20px auto"
			spacing={styles.spacing._2}
			onClick={activateSelecting}>
			<Icon name="mark-all-complete" size={1.2} />
			{t('Select clips…')}
		</SelectWrapper>
	);

	let list = (
		<Fragment>
			{items.map((clip: VideoSequence) => (
				<ListItem
					context={context}
					selectable={isSelecting}
					selected={selectedIds.includes(clip.id)}
					key={clip.id}
					clip={clip}
					onClick={onItemClick}
					onToggle={handleItemToggle}
				/>
			))}
		</Fragment>
	);

	if (groupByAccount && groupedItems.size > 0) {
		list = (
			<Fragment>
				{groupedItems.entrySeq().map(([accountId, clipItems]) => {
					const account = accounts.find((a: Account) => a.id === accountId);

					return (
						<Group
							key={accountId}
							isCollapsible
							account={account}
							title={account.fullName}>
							{clipItems.toList().map((clip: VideoSequence) => (
								<ListItem
									context={context}
									selectable={isSelecting}
									selected={selectedIds.includes(clip.id)}
									key={clip.id}
									clip={clip}
									onClick={onItemClick}
									onToggle={handleItemToggle}
									cueGroup={CueGroupType.Account}
									cuePoints={itemCuePoints}
								/>
							))}
						</Group>
					);
				})}
			</Fragment>
		);
	}

	if (groupByAccount && highlights.size > 0) {
		list = (
			<Fragment>
				<Group isCollapsible isHighlightGroup title={t('Highlights')}>
					{highlights.map((clip: VideoSequence) => (
						<ListItem
							context={context}
							selectable={isSelecting}
							selected={selectedIds.includes(clip.id)}
							key={clip.id}
							clip={clip}
							onClick={onItemClick}
							onToggle={handleItemToggle}
							cueGroup={CueGroupType.Highlight}
							cuePoints={highlightCuePoints}
						/>
					))}
				</Group>
				{list}
			</Fragment>
		);
	}

	return (
		<Fragment>
			<ListWrapper numItems={items.size} actions={actions}>
				{list}
			</ListWrapper>
			{addModalOpen && (
				<PlaylistModal clipIds={selectedIds} onClose={closeAddModal} />
			)}
		</Fragment>
	);
}
