import { ReactNode } from 'react';

export const symbol_rate_review: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M270-400h81q5.565 0 10.783-2Q367-404 372-409l189.825-190.564q9.088-9.122 13.631-20.779Q580-632 580-643t-4-22q-4-11-13-20l-36.8-37.191Q517-731 506-735.5t-23-4.5q-11 0-22 4t-20.183 13.137L249-532q-5 5-7 10.217-2 5.218-2 10.783v81q0 12.75 8.625 21.375T270-400Zm250-243-37-37 37 37ZM300-460v-38l102-101 19 18 18 19-101 102h-38Zm121-121 18 19-37-37 19 18Zm14 181h255q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T690-460H495l-60 60ZM240-240 131-131q-14 14-32.5 6.344T80-152v-668q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H240Zm-26-60h606v-520H140v600l74-80Zm-74 0v-520 520Z"
		/>
	</svg>
);
