import styled, { css } from 'styled-components';

import * as styles from 'pkg/config/styles';

import Avatar from 'components/avatar';

type BaseProps = {
	clickable?: boolean;
	rows?: boolean;
};

export const Base = styled.div.withConfig({
	shouldForwardProp: (prop) => !['clickable', 'rows'].includes(prop),
})<BaseProps>`
	display: grid;
	grid-template-areas: 'user actions';
	grid-template-columns: 1fr auto;
	align-items: center;
	background-color: var(--palette-white);
	border-radius: var(--radius-5);
	transition: box-shadow 150ms ease-in-out;

	${({ rows }) =>
		rows &&
		css`
			@media ${styles.breakpoint.small} {
				grid-template-areas:
					'user'
					'actions';
				grid-template-columns: 1fr;
			}
		`}

	${({ clickable }) =>
		clickable &&
		css`
			cursor: pointer;

			&:hover {
				box-shadow: ${styles.boxShadow.cardHover};
			}
		`}
`;

export const User = styled.div`
	grid-area: user;
	display: grid;
	grid-template-areas: 'avatar text';
	grid-template-columns: 40px minmax(0, 1fr);
	align-items: center;
	gap: var(--spacing-4);
	padding: var(--spacing-4);

	${Avatar} {
		grid-area: avatar;
	}

	div {
		grid-area: text;

		strong {
			display: block;
			font-weight: var(--font-weight-semibold);
		}

		span {
			display: block;
			font-size: var(--font-size-sm);
			color: var(--palette-gray-500);
		}
	}
`;

type ActionsProps = {
	noPadding?: boolean;
};

export const Actions = styled.div<ActionsProps>`
	grid-area: actions;
	display: grid;
	grid-auto-flow: column;
	align-items: center;
	gap: var(--spacing-3);
	padding: var(--spacing-4);

	${({ noPadding }) =>
		noPadding &&
		css`
			@media ${styles.breakpoint.small} {
				padding-top: 0;
			}
		`}
`;
