import { ReactNode } from 'react';

export const symbol_avg_time: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M181-470h139q8 0 15.5 4t11.5 12l53 107 133-267q8-17 27-17t27 17l72 144h120q-11-115-96-193t-203-78q-118 0-203 78t-96 193Zm299 329q118 0 202.5-77.5T778-410H640q-8 0-15.5-4T613-426l-53-107-133 267q-8 17-27 17t-27-17l-72-144H182q11 114 95.5 191.5T480-141Zm0 60q-74 0-139.5-28.5T226-187q-49-49-77.5-114.5T120-441h60q0 125 87.5 212.5T480-141q125 0 212.5-87.5T780-441h60q0 74-28.5 139.5T734-187q-49 49-114.5 77.5T480-81ZM120-441q0-74 28.5-139.5T226-695q49-49 114.5-77.5T480-801q67 0 126 22.5T711-716l30-30q9-9 21-9t21 9q9 9 9 21t-9 21l-30 30q36 40 61.5 97T840-441h-60q0-125-87.5-212.5T480-741q-125 0-212.5 87.5T180-441h-60Zm270-419q-13 0-21.5-8.5T360-890q0-13 8.5-21.5T390-920h180q13 0 21.5 8.5T600-890q0 13-8.5 21.5T570-860H390Zm90 719q-125 0-212.5-87.5T180-441q0-125 87.5-212.5T480-741q125 0 212.5 87.5T780-441q0 125-87.5 212.5T480-141Zm0-299Z"
		/>
	</svg>
);
