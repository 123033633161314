import { ReactNode } from 'react';

export const symbol_sim_card: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M304.175-193q12.825 0 21.325-8.675 8.5-8.676 8.5-21.5 0-12.825-8.675-21.325-8.676-8.5-21.5-8.5-12.825 0-21.325 8.675-8.5 8.676-8.5 21.5 0 12.825 8.675 21.325 8.676 8.5 21.5 8.5Zm0-156q12.825 0 21.325-8.625T334-379v-106q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T274-485v106q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625Zm174 156q12.825 0 21.325-8.625T508-223v-110q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T448-333v110q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625Zm0-262q12.825 0 21.325-8.675 8.5-8.676 8.5-21.5 0-12.825-8.675-21.325-8.676-8.5-21.5-8.5-12.825 0-21.325 8.675-8.5 8.676-8.5 21.5 0 12.825 8.675 21.325 8.676 8.5 21.5 8.5Zm182 262q12.825 0 21.325-8.675 8.5-8.676 8.5-21.5 0-12.825-8.675-21.325-8.676-8.5-21.5-8.5-12.825 0-21.325 8.675-8.5 8.676-8.5 21.5 0 12.825 8.675 21.325 8.676 8.5 21.5 8.5Zm0-156q12.825 0 21.325-8.625T690-379v-106q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T630-485v106q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625ZM220-80q-24 0-42-18t-18-42v-476q0-12.444 5-23.722T178-659l203-203q8-8 19.278-13 11.278-5 23.722-5h316q24 0 42 18t18 42v680q0 24-18 42t-42 18H220Zm0-60h520v-680H426L220-613.875V-140Zm0 0h520-520Z"
		/>
	</svg>
);
