import { ReactNode } from 'react';

export const symbol_water_full: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M444-610q-60 0-119 19.5T218-536l44 396h436l47-430h-48q-37 0-68-5.296-31-5.297-85-20.704-25-7-49.5-10.5T444-610Zm-233 7q51-32 111.5-49T444-670q30 0 59.5 4t56.5 12q51.128 14 78.226 19 27.097 5 57.774 5h56l21-190H187l24 217Zm51 523q-23 0-40.344-14.93Q204.312-109.859 202-133l-74-673q-3-30 17.106-52 20.107-22 49.894-22h570q29.787 0 49.894 22Q835-836 832-806l-74 673q-2.312 23.141-19.656 38.07Q721-80 698-80H262Zm182-60h254-436 182Z"
		/>
	</svg>
);
