import { ReactNode } from 'react';

export const symbol_trending_up: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M102-252q-9-9-9-21.5t9-21.5l228-227q16.934-17 41.967-17Q397-539 414-522l125 125 241-241h-97q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T683-698h167q12.75 0 21.375 8.625T880-668v167q0 12-8.272 21t-20.5 9Q839-471 830-480q-9-9-9-21v-93L580-354q-16.934 17-41.967 17Q513-337 496-354L371-478 145-252q-9 9-21.5 9t-21.5-9Z"
		/>
	</svg>
);
