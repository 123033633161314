import { ReactNode } from 'react';

export const symbol_laundry: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m188-561 112-62v225q-15 2-30.5 6.5T240-380v-141l-49 27q-11 6-23 3t-18-14L60-662q-6-11-3-23t14-18l214-123q11-7 23.5-10.5T334-840q12 0 21.5 7t14.5 18q17 38 44 66.5t66 28.5q39 0 66-28.5t44-66.5q5-11 15-18t22-7q13 0 25 3.5t23 10.5l214 123q11 6 14 18t-3 23l-90 157q-6 11-18 14t-23-3l-49-27v216l-60 51v-369l113 62 59-104-188-110q-26 53-70 84t-94 31q-49 0-93.5-31T316-775L127-665l61 104Zm292-99ZM167-212q-8-10-7-22.5t11-20.5l56-47q23-19 51-29t57-10q29 0 56.5 10t50.5 29l116 99q14 12 31.5 17.5T626-180q18 0 36-5.5t32-17.5l56-49q10-8 22.5-7t20.5 11q8 10 7 22.5T789-205l-56 47q-23 19-50.5 28.5T626-120q-29 0-57-9.5T518-158l-115-99q-14-12-32-17.5t-36-5.5q-19 0-36.5 5.5T267-257l-57 49q-10 8-22.5 7T167-212Z"
		/>
	</svg>
);
