import { ReactNode } from 'react';

export const symbol_temp_preferences_eco: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M495.426-730q-6.426 0-10.926-4.5t-4.5-10.926v-64.255q0-33.734 23.293-57.026Q526.585-890 560.319-890h64.255q6.426 0 10.926 4.5t4.5 10.926v64.255q0 33.734-23.293 57.026Q593.415-730 559.681-730h-64.255ZM200-490q-49.714 0-84.857-35.143T80-610v-90q0-12.75 8.625-21.375T110-730h90q49.714 0 84.857 35.143T320-610v90q0 12.75-8.625 21.375T290-490h-90ZM640-90q-41.525 0-79.324-13.6Q522.878-117.2 492-141l-31 31q-9 9-20.5 9t-20.5-9q-9-9-9-20.5t9-20.5l31-31q-23.8-30.878-37.4-68.676Q400-288.475 400-330q0-100 70-170.5T640-571h181q24.75 0 42.375 17.625T881-511v181q0 100-70.5 170T640-90Zm0-60q75.428 0 127.214-53.169Q819-256.337 819-331v-179H641q-74.662 0-127.831 52.312Q460-405.375 460-330q0 29.469 9 55.734Q478-248 494-225l126-126q9-9 20.5-9t20.5 9q9 9 9 20.5t-9 20.5L535-184q23 16 49.266 25 26.265 9 55.734 9Zm0-180Z"
		/>
	</svg>
);
