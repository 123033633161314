import { ReactNode } from 'react';

export const symbol_speaker_group: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M339-220h441v-640H339v640Zm0 60q-23 0-41-18.5T280-220v-640q0-23 18-41.5t41-18.5h441q23 0 41.5 18.5T840-860v640q0 23-18.5 41.5T780-160H339Zm221-473q33.75 0 57.375-23T641-714q0-32.917-23.625-55.958Q593.75-793 560-793q-32.917 0-55.958 23.042Q481-746.917 481-714q0 35 23.042 58 23.041 23 55.958 23Zm1 346q57.171 0 97.586-40.5Q699-368 699-427t-40.414-99.5Q618.171-567 561-567q-58.829 0-100.414 40.5Q419-486 419-427t41.586 99.5Q502.171-287 561-287Zm-2.471-60Q525-347 502-370.265q-23-23.264-23-56.5Q479-460 502-483.5t56.529-23.5q33.53 0 57 23.265Q639-460.471 639-427.235 639-394 615.529-370.5q-23.47 23.5-57 23.5ZM489.1-40H220q-23 0-41.5-18.5T160-100v-670q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T220-770v670h269.284Q502-100 510.5-91.325q8.5 8.676 8.5 21.5Q519-57 510.404-48.5 501.807-40 489.1-40ZM339-860v640-640Z"
		/>
	</svg>
);
