import { ReactNode } from 'react';

export const symbol_detector_co: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M331-380q-20 0-35-14.583-15-14.584-15-35.417 0-20 15-35t35.5-15q20.5 0 35 15t14.5 35.5q0 20.5-14.583 35Q351.833-380 331-380Zm150 130q-20 0-35-15t-15-35q0-20 15-35t35.5-15q20.5 0 35 15t14.5 35q0 20-14.583 35-14.584 15-35.417 15ZM335-120q-20 0-35-14.583-15-14.584-15-35.417 0-20 15-35t35.5-15q20.5 0 35 15t14.5 35q0 20-14.583 35-14.584 15-35.417 15Zm294.882-260Q609-380 594.5-394.583 580-409.167 580-430q0-20 14.618-35 14.617-15 35.5-15Q651-480 665.5-465q14.5 15 14.5 35.5t-14.618 35q-14.617 14.5-35.5 14.5ZM625-120q-20.833 0-35.417-14.5Q575-149 575-169.5t14.618-35.5q14.617-15 35.5-15Q646-220 660.5-205q14.5 15 14.5 35t-14.583 35Q645.833-120 625-120Zm154.5-130q-20.5 0-35-15T730-300q0-20 14.583-35 14.584-15 35.417-15 20 0 35 15t15 35q0 20-15 35t-35.5 15Zm-599 0q-20.5 0-35-15T131-300.5q0-20.5 14.618-35 14.617-14.5 35.5-14.5Q202-350 216.5-335q14.5 15 14.5 35t-15 35q-15 15-35.5 15ZM319-580q-19.5 0-35.25-11.625T262-622l-25-78h-57q-24.75 0-42.375-17.625T120-760v-60q0-24.75 17.625-42.375T180-880h600q24.75 0 42.375 17.625T840-820v60q0 24.75-17.625 42.375T780-700h-57l-30 81q-6.932 17.25-22.338 28.125Q655.257-580 636-580H319ZM180-820v60h600v-60H180Zm121 120 18 60h322l18-60H301ZM180-820v60-60Z"
		/>
	</svg>
);
