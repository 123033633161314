import { ReactNode } from 'react';

export const symbol_archive: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M479.825-581Q467-581 458.5-572.375T450-551v171l-66-66q-7.822-8-19.911-8Q352-454 344-446.143q-8 7.857-8 20T344-406l115 115q9 9 21 9t21-9l115-115q8-7.822 8-19.911Q624-438 616.143-446q-7.857-8-20-8T576-446l-66 66v-171q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625ZM180-674v494h600v-494H180Zm0 554q-24.75 0-42.375-17.625T120-180v-529q0-9.882 3-19.059 3-9.176 9-16.941l52-71q8-11 20.941-17.5Q217.882-840 232-840h495q14.118 0 27.059 6.5T775-816l53 71q6 7.765 9 16.941 3 9.177 3 19.059v529q0 24.75-17.625 42.375T780-120H180Zm17-614h565l-36.409-46H233l-36 46Zm283 307Z"
		/>
	</svg>
);
