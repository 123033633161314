import { Record } from 'immutable';
import { schema } from 'normalizr';

export const position = new schema.Entity('position');

interface IPosition {
	description: string;
	id: number;
	idealUserRatingId: number;
	longName: string;
	name: string;
	slug: string;
}

const positionProps: IPosition = {
	description: '',
	id: 0,
	idealUserRatingId: 0,
	longName: '',
	name: '',
	slug: '',
};

export default class Position
	extends Record(positionProps, 'PositionRecord')
	implements IPosition
{
	static normalizr() {
		return position;
	}
}
