import { Map, Record } from 'immutable';

import TaxRate from 'pkg/models/tax_rates';

import { TaxRates } from 'pkg/actions/action-types';

interface ITaxRateReducer {
	entities: Map<number, TaxRate>;
}

class TaxRateReducer
	extends Record<ITaxRateReducer>(
		{
			entities: Map<number, TaxRate>(),
		},
		'taxRateReducer'
	)
	implements ITaxRateReducer
{
	entities: Map<number, TaxRate>;
}

const initialState = new TaxRateReducer({
	entities: Map<number, TaxRate>(),
});

interface NormalizrPayload {
	result: number[];
	entities: {
		taxRate: { [key: number]: TaxRate };
	};
}

interface Action {
	type: string;
	payload: NormalizrPayload;
	id: number;
}

const setItems = (state = initialState, action: Action): TaxRateReducer => {
	const entities = Object.values(action.payload.entities.taxRate);

	entities.map((entity: TaxRate) => {
		const tr = new TaxRate(entity);
		state = state.setIn(['entities', tr.id], tr);
	});

	return state;
};

const setItemInactive = (
	state = initialState,
	action: Action
): TaxRateReducer => {
	state = state.setIn(['entities', action.id, 'active'], false);

	return state;
};

const taxRateReducer = (
	state = initialState,
	action: Action
): TaxRateReducer => {
	switch (action.type) {
		case TaxRates.SET_ITEMS:
			return setItems(state, action);
		case TaxRates.DELETE_ITEM:
			return setItemInactive(state, action);
		default:
			return state;
	}
};

export default taxRateReducer;
