import * as models from 'pkg/api/models';

interface UserData {
	id: number;
	firstName: string;
	lastName: string;
}

interface NewChatParams {
	userIds: number[] | string;
	userData: UserData[] | string;
}

export function newChatParams(users: models.user.User[]): NewChatParams {
	const userIds = users.map((u) => u.id);
	const userData = users.map((u) => ({
		id: u.id,
		firstName: u.firstName,
		lastName: u.lastName,
	}));

	return {
		userIds,
		userData,
	};
}
