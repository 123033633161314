import { ReactNode } from 'react';

export const symbol_airlines: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M194-220h517l97-520H551L194-220Zm-98 37 406-591q8-12 21.364-19 13.363-7 28.636-7h256q28.612 0 46.306 21.5T867-729L765-184q-2 11-10.556 17.5Q745.889-160 735-160H109q-9.391 0-13.696-7.5Q91-175 96-183Zm483.577-237Q613-420 636.5-443.077q23.5-23.078 23.5-56.5Q660-533 636.718-556.5q-23.281-23.5-57-23.5Q546-580 523-556.718q-23 23.281-23 57Q500-466 523.077-443q23.078 23 56.5 23ZM194-220h517-517Z"
		/>
	</svg>
);
