import { ReactNode } from 'react';

export const symbol_low_priority: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-494q0 88 62.5 146.5T352-289l-46-47q-8-9-8-21t9-21q9-9 21-9t21 9l94 97q8 9 8.5 21t-8.5 21l-97 98q-9 9-21 9t-21-9q-8-8-8.5-20.5T303-183l44-46q-110 0-188.5-76.5T80-494q0-109 74.338-187.5Q228.677-760 336-760h122q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T458-700H336q-81.667 0-138.833 60.5Q140-579 140-494Zm462 205h248q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-229H602q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T602-289Zm1-236h247q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q880-482 871.375-473T850-464H602q-12.75 0-21.375-8.877t-8.625-22q0-13.123 8.912-21.623Q589.825-525 603-525Zm-25-235h272q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-700H578q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T578-760Z"
		/>
	</svg>
);
