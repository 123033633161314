import React, {
	useMemo,
	useReducer,
	useCallback,
	createContext,
	useContext,
	ReactNode,
} from 'react';

interface PlaybackState {
	currentTime?: number;
}

export enum PlaybackAction {
	Reset = 'reset',
	CurrentTime = 'currentTime',
}

export type Action =
	| { type: PlaybackAction.Reset; payload: PlaybackState }
	| { type: PlaybackAction.CurrentTime; payload: PlaybackState };

type PlaybackPayload = number | string | null;

interface PlaybackStateDispatch {
	state: PlaybackState;
	dispatch?: React.Dispatch<Action>;
	emit?: (type: PlaybackAction, payload: PlaybackPayload) => void;
}

const initialState: PlaybackState = {
	currentTime: 0,
};

const PlaybackContext = createContext<PlaybackStateDispatch>({
	state: initialState,
});

interface PlaybackHookResponse extends PlaybackState {
	reset: () => void;
	setCurrentTime: (currentTime: number) => void;
}

export const usePlaybackState = (): PlaybackHookResponse => {
	const { state, emit } = useContext(PlaybackContext);

	const reset = (): void => emit(PlaybackAction.Reset, null);

	const setCurrentTime = (currentTime: number): void =>
		emit(PlaybackAction.CurrentTime, currentTime);

	return {
		...state,

		reset,
		setCurrentTime,
	} as PlaybackHookResponse;
};

interface StateProviderProps {
	children: ReactNode;
}

const reducer = (state: PlaybackState, action: Action) => {
	const mutateState = (
		state: PlaybackState,
		nextState: PlaybackState
	): PlaybackState => ({
		...state,
		...nextState,
	});

	const actionType = action.type;

	switch (actionType) {
		case PlaybackAction.Reset:
			return { ...initialState };
		case PlaybackAction.CurrentTime:
			return mutateState(state, action.payload);
		default:
			throw new Error(`Invalid action '${actionType}'.`);
	}
};

const PlaybackStateProvider = ({
	children,
}: StateProviderProps): JSX.Element => {
	const [state, dispatch] = useReducer(reducer, initialState);

	const emit = useCallback(
		(type: PlaybackAction, payload: PlaybackPayload) =>
			dispatch({
				type,
				payload: {
					[type]: payload,
				},
			}),
		[dispatch]
	);

	const store: PlaybackStateDispatch = useMemo(
		() => ({
			state,
			dispatch,
			emit,
		}),
		[state, dispatch, emit]
	);

	return (
		<PlaybackContext.Provider value={store}>
			{children}
		</PlaybackContext.Provider>
	);
};

export default PlaybackStateProvider;
