import { ReactNode } from 'react';

export const symbol_spatial_audio_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M927-522q-9 0-14.5-3.5T902-535q-25-29-38.5-62.917-13.5-33.916-13.5-71.5Q850-707 864.5-743t40.5-64q3.778-4.091 9.444-6.545Q920.111-816 926.156-816q12.844 0 21.344 8.426 8.5 8.425 8.5 21.446 0 6.128-2.5 11.628T947-764q-19 19-28 44.5t-9 51.5q0 26 10 50.5t27 44.5q5 5 7 9.891 2 4.892 2 10.109 0 14-9 22.5t-20 8.5ZM786-380q-6.609 0-12.804-3Q767-386 762-391q-53-57-82.5-128.69T650-669q0-77.592 30.5-149.796T765-948q5-5 10.217-7 5.218-2 10.783-2 13.175 0 22.088 8.625Q817-939.75 817-927q0 6-2 11.5t-7 10.5q-45 49-71.5 109.5T710-669q0 66 26 127t71 110q5 5 7 10.217 2 5.218 2 10.783 0 13.175-8.625 22.087Q798.75-380 786-380Zm-386-61q-66 0-108-42t-42-108q0-66 42-108t108-42q66 0 108 42t42 108q0 66-42 108t-108 42ZM80-180v-34q0-38 19-64.5t49-41.5q51-26 120.5-43T400-380q62 0 131 17t120 43q30 15 49.5 41.5T720-214v34q0 24.75-17.625 42.375T660-120H140q-24.75 0-42.375-17.625T80-180Zm60 0h520v-34q0-16.31-8.5-29.655Q643-257 627-266q-48-27-109-40.5T400-320q-57 0-118.5 14.5T172-266q-14 7-23 21.5t-9 30.5v34Zm260-321q39 0 64.5-25.5T490-591q0-39-25.5-64.5T400-681q-39 0-64.5 25.5T310-591q0 39 25.5 64.5T400-501Zm0-90Zm0 411Z"
		/>
	</svg>
);
