import styled from 'styled-components';

import { small } from 'pkg/config/breakpoints';

interface ButtonRowProps {
	topMargin?: boolean;
}

export const ButtonRow = styled.div<ButtonRowProps>`
	display: flex;
	margin: 7px 0;
	margin-top: ${(props) => props.topMargin && '20px'};
	width: 100%;
	justify-content: center;

	@media ${small} {
		margin: 5px 0;
	}

	&:first-of-type {
		margin-top: 0;
	}
`;

export const ContextMenuBody = styled.div`
	padding: 40px 30px 60px 30px;
`;
