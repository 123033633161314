import { Fragment, ReactNode } from 'react';

import * as routes from 'pkg/router/routes';
import Link from 'pkg/router/Link';
import * as models from 'pkg/api/models';
import { useCurrentOrganization } from 'pkg/identity';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import {
	FormattedContent,
	FormattedContentMaxLength,
} from 'components/formatted-content';
import RelativeDateTime from 'components/RelativeDateTime';
import * as Card from 'components/Card';
import Avatar from 'components/avatar';

import Thumbnail from 'components/video/Thumbnail';

import * as css from './styles.css';

interface VideoProps {
	actions?: ReactNode;

	video: models.video.Video;
}

export default function Video({ video, actions = null }: VideoProps) {
	const org = useCurrentOrganization();

	return (
		<Card.Base className={css.item} data-testid="video_library.list.item">
			<Link
				className={css.thumbnailBox}
				href={routes.Video.Show(org.id, video.id)}>
				<Thumbnail
					sourceUrl={models.video.thumbnail(video)}
					duration={video.duration}
				/>
			</Link>
			<div className={css.textBox}>
				<Link className={css.title} href={routes.Video.Show(org.id, video.id)}>
					{video.title}
				</Link>
				<RelativeDateTime
					className={css.videoItemDate}
					dateTime={video.createdAt}
				/>
				<div className={css.author}>
					{video.account?.id && (
						<Fragment>
							<Avatar account={video.account} size={16} />
							<span>{models.account.fullName(video.account)}</span>
						</Fragment>
					)}
				</div>
				{video.description !== '' && (
					<div className={css.description}>
						<LargeScreen>
							<FormattedContent
								raw={video.description}
								maxLength={FormattedContentMaxLength.Standard}
							/>
						</LargeScreen>
						<SmallScreen>
							<FormattedContent raw={video.description} maxLength={70} />
						</SmallScreen>
					</div>
				)}
			</div>
			{actions}
		</Card.Base>
	);
}
