import VimeoPlayer from '@vimeo/player';

import Controller from 'components/video-analytics/Controller';
import { VimeoUrl } from 'components/video-analytics/source/SourceUrl';

let __playing: boolean = false;
let __volume: number = 1;

export default class VimeoController extends Controller {
	public signature: string;

	constructor(protected controllerInterface: VimeoPlayer) {
		super(controllerInterface);
	}

	public setSource(source: VimeoUrl): void {
		this.controllerInterface.loadVideo(source.getSourceId());
	}

	public async getCurrentTime(): Promise<number> {
		return await this.controllerInterface.getCurrentTime();
	}

	public async getDuration(): Promise<number> {
		return await this.controllerInterface.getDuration();
	}

	public async play(): Promise<void> {
		if (this.isPlaying()) return;

		__playing = true;

		return await this.controllerInterface.play();
	}

	public async pause(): Promise<void> {
		if (!this.isPlaying()) return;

		__playing = false;

		return await this.controllerInterface.pause();
	}

	public isPlaying(): boolean {
		return __playing;
	}

	public async skip(
		fraction: number,
		resumeAfterSkip: boolean = false
	): Promise<number> {
		const currentTime: number = await this.getCurrentTime();
		const nextFraction: number = currentTime + fraction;

		await this.seekTo(nextFraction, resumeAfterSkip);

		return Promise.resolve(nextFraction);
	}

	public async seekTo(
		fraction: number,
		resumeAfterSeek: boolean = false
	): Promise<number> {
		const duration: number = await this.getDuration();

		let nextFraction: number = fraction;

		if (nextFraction > duration) {
			nextFraction = duration;
		}

		await this.controllerInterface.setCurrentTime(nextFraction);

		if (!this.isPlaying() && resumeAfterSeek) {
			await this.play();
		} else if (this.isPlaying() && !resumeAfterSeek) {
			await this.pause();
		}

		return Promise.resolve(nextFraction);
	}

	public async setVolume(volume: number): Promise<void> {
		await this.controllerInterface.setVolume(volume);

		return Promise.resolve();
	}

	public async getVolume(): Promise<number> {
		const volume: number = await this.controllerInterface.getVolume();

		return Promise.resolve(volume);
	}

	public async mute(): Promise<void> {
		__volume = await this.getVolume();

		await this.setVolume(0);
	}

	public async unmute(): Promise<void> {
		await this.setVolume(__volume);
	}

	public async getAvailablePlaybackRates(): Promise<readonly number[]> {
		return Promise.resolve([0.5, 0.75, 1, 1.25, 1.5, 1.75, 2]);
	}

	public async setPlaybackRate(playbackRate: number): Promise<void> {
		const availableRates = await this.getAvailablePlaybackRates();

		if (availableRates.includes(playbackRate)) {
			await this.controllerInterface.setPlaybackRate(playbackRate);
		}

		return Promise.resolve();
	}

	public async getPlaybackRate(): Promise<number> {
		return Promise.resolve(this.controllerInterface.getPlaybackRate());
	}
}
