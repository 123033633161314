import { ReactNode } from 'react';

export const symbol_storage: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M194-160q-30.525 0-52.262-21.738Q120-203.475 120-234q0-30.525 21.738-52.263Q163.475-308 194-308h572q30.525 0 52.263 21.737Q840-264.525 840-234t-21.737 52.262Q796.525-160 766-160H194Zm0-492q-30.525 0-52.262-21.737Q120-695.475 120-726t21.738-52.263Q163.475-800 194-800h572q30.525 0 52.263 21.737Q840-756.525 840-726t-21.737 52.263Q796.525-652 766-652H194Zm0 246q-30.525 0-52.262-21.737Q120-449.475 120-480t21.738-52.263Q163.475-554 194-554h572q30.525 0 52.263 21.737Q840-510.525 840-480t-21.737 52.263Q796.525-406 766-406H194Zm21.789-284Q231-690 241.5-700.289q10.5-10.29 10.5-25.5Q252-741 241.711-751.5q-10.29-10.5-25.5-10.5Q201-762 190.5-751.711q-10.5 10.29-10.5 25.5Q180-711 190.289-700.5q10.29 10.5 25.5 10.5Zm0 246Q231-444 241.5-454.289q10.5-10.29 10.5-25.5Q252-495 241.711-505.5q-10.29-10.5-25.5-10.5Q201-516 190.5-505.711q-10.5 10.29-10.5 25.5Q180-465 190.289-454.5q10.29 10.5 25.5 10.5Zm0 246Q231-198 241.5-208.289q10.5-10.29 10.5-25.5Q252-249 241.711-259.5q-10.29-10.5-25.5-10.5Q201-270 190.5-259.711q-10.5 10.29-10.5 25.5Q180-219 190.289-208.5q10.29 10.5 25.5 10.5Z"
		/>
	</svg>
);
