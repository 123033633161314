import { ReactNode } from 'react';

export const symbol_deployed_code: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M450-154v-309L180-619v309l270 156Zm60 0 270-156v-310L510-463.162V-154Zm-30-360 266-155-266-154-267 154 267 155ZM150-258q-14.25-8.426-22.125-22.213T120-310v-340q0-16 7.875-29.787Q135.75-693.574 150-702l300-173q14.328-8 30.164-8Q496-883 510-875l300 173q14.25 8.426 22.125 22.213T840-650v340q0 16-7.875 29.787Q824.25-266.426 810-258L510-85q-14.328 8-30.164 8Q464-77 450-85L150-258Zm330-222Z"
		/>
	</svg>
);
