import { ReactNode } from 'react';

export const symbol_mosque: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M40-180v-449q-18-11-29-27T0-692.125q0-20.125 20.5-49T70-798q29 28 49.5 56.875t20.5 49Q140-672 129-656t-29 27v189h110v-102q0-24 18.5-43t47.5-19q-13-22-19.5-43t-6.5-40.623Q250-725 267.5-757.5 285-790 316-810l131-88q14.571-11 32.786-11Q498-909 513-898l131 88q31 20 48.5 52.5t17.5 69.877Q710-668 703.5-647q-6.5 21-19.5 43 29 0 47.5 19t18.5 43v102h110v-189q-18-11-29-27t-11-36.125q0-20.125 20.5-49T890-798q29 28 49.5 56.875t20.5 49Q960-672 949-656t-29 27v449q0 24.75-17.625 42.375T860-120H560q-12.75 0-21.375-8.625T530-150v-130q0-21.25-14.325-35.625Q501.351-330 480.175-330 459-330 444.5-315.625 430-301.25 430-280v130q0 12.75-8.625 21.375T400-120H100q-24.75 0-42.375-17.625T40-180Zm356-422h168q36.211 0 61.105-24.75Q650-651.5 650-687.5q0-22.5-10.5-41.5t-27.289-30.579L480-848l-132.211 88.421Q331-748 320.5-729T310-687.5q0 36 24.895 60.75Q359.789-602 396-602ZM100-180h270v-100q0-45.833 32.118-77.917 32.117-32.083 78-32.083Q526-390 558-357.917q32 32.084 32 77.917v100h270v-200H750q-24.75 0-42.375-17.625T690-440v-102H270v102q0 24.75-17.625 42.375T210-380H100v200Zm380-362Zm0-60Zm0-2Z"
		/>
	</svg>
);
