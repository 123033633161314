import { useT } from '@transifex/react';

import * as styles from 'pkg/config/styles';

import { useCurrentUser, useCurrentGroupId } from 'pkg/identity';
import { useCollection } from 'pkg/api/use_collection';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as actions from 'pkg/actions';
import * as models from 'pkg/api/models';

import MaterialSymbol from 'components/material-symbols';
import MissingEntities from 'components/missing-entities';
import AssetImage from 'components/AssetImage';
import * as Card from 'components/Card';
import Label from 'components/label';

import Row from 'components/layout/row';
import { Spinner } from 'components/loaders/spinner';
import Column from 'components/layout/column';

import * as ContextMenu from 'design/context_menu';

import * as css from './styles.css';

const PaymentMethods = () => {
	const t = useT();
	const currentUser = useCurrentUser();
	const currentGroupId = useCurrentGroupId();

	const { records, isLoading, refresh } =
		useCollection<models.paymentMethod.PaymentMethod>(
			endpoints.Users.ListPaymentMethods(currentUser.id)
		);

	if (isLoading) {
		return <Spinner />;
	}

	const sortedMethods = records.sort((a, b) => {
		if (a.primary) {
			return -1;
		}

		if (b.primary) {
			return 1;
		}

		return a.paymentMethodData.cardBrand.localeCompare(
			b.paymentMethodData.cardBrand
		);
	});

	if (sortedMethods.length <= 0) {
		return (
			<Card.Base $noBorder>
				<Card.Body>
					<MissingEntities centered>
						<MaterialSymbol variant="credit_card_gear" scale={2} />
						<p>{t('No payment methods found')}</p>
					</MissingEntities>
				</Card.Body>
			</Card.Base>
		);
	}

	return sortedMethods.map((method) => (
		<Item groupId={currentGroupId} paymentMethod={method} refresh={refresh} />
	));
};

const Item = ({
	groupId,
	paymentMethod,
	refresh,
}: {
	groupId: number;
	paymentMethod: models.paymentMethod.PaymentMethod;
	refresh: () => void;
}) => {
	const t = useT();

	const handleSetAsPreferred = async () => {
		const isOk = await actions.paymentMethod.setAsPrimary(
			groupId,
			paymentMethod.id
		);

		if (isOk) {
			refresh();
		}
	};

	const handleRemove = async () => {
		const isOk = await actions.paymentMethod.remove(groupId, paymentMethod.id);

		if (isOk) {
			refresh();
		}
	};

	const hasActions =
		models.hasAllowedAction(
			paymentMethod,
			models.Action.PaymentMethodMakePrimary
		) ||
		models.hasAllowedAction(paymentMethod, models.Action.PaymentMethodArchive);

	return (
		<Card.Base $noBorder>
			<Card.Body $narrowBody>
				<Row columns="1fr auto" align="center">
					<Row columns="auto 1fr">
						<AssetImage
							src={models.paymentMethod.getCardImgUrl(paymentMethod)}
						/>
						<Column>
							<div className={css.title}>
								<div>
									{models.paymentMethod.getCardBrandWithDigits(paymentMethod)}
								</div>
								<span>
									{`${t('Expires')} ${models.paymentMethod.getFormattedExpirationDate(paymentMethod)}`}
								</span>
							</div>
						</Column>
					</Row>

					<Row
						align="center"
						columns="auto 1fr"
						spacing={paymentMethod.primary ? styles.spacing._4 : '0px'}>
						{paymentMethod.primary && (
							<Label color="green">{t('Preferred')}</Label>
						)}
						{hasActions && (
							<ContextMenu.Menu
								toggleWith={
									<ContextMenu.ButtonTrigger icon="more_horiz" small />
								}>
								{models.hasAllowedAction(
									paymentMethod,
									models.Action.PaymentMethodMakePrimary
								) && (
									<ContextMenu.Item icon="edit" onClick={handleSetAsPreferred}>
										{t('Set as preferred')}
									</ContextMenu.Item>
								)}
								{models.hasAllowedAction(
									paymentMethod,
									models.Action.PaymentMethodArchive
								) && (
									<ContextMenu.ConfirmItem
										caution
										icon="delete"
										message={t(
											'Are you sure you want to remove this payment method?'
										)}
										onConfirm={handleRemove}>
										{t('Remove')}
									</ContextMenu.ConfirmItem>
								)}
							</ContextMenu.Menu>
						)}
					</Row>
				</Row>
			</Card.Body>
		</Card.Base>
	);
};

export default PaymentMethods;
