import styled from 'styled-components';

import * as styles from 'pkg/config/styles';

export const Outer = styled.div`
	background: linear-gradient(
		90deg,
		var(--palette-blue-500) 10%,
		var(--palette-green-400) 38%,
		${styles.palette.brand} 64%,
		var(--palette-orange-500) 80%,
		var(--palette-red-500) 88%
	);
`;

/*
	<Indicator /> uses two Inner. -px calc determines size of the |,
	by creating a gap between the two Inner.
	if percentage is 100% only one Inner will be shown.
*/
export const Inner = styled.div<{ percentage: number; left?: boolean }>`
	width: calc(
		${(props) => props.percentage}% -
			${(props) => (props.percentage === 100 ? '6px' : '3px')}
	);
	height: 100%;

	float: ${(props) => (props.left ? 'left' : 'right')};
`;
