import { ReactNode } from 'react';

export const symbol_airport_shuttle: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M229.882-200Q184-200 152-232.083 120-264.167 120-310h-20q-24.75 0-42.375-17.625T40-370v-330q0-24.75 17.625-42.375T100-760h561q12.444 0 23.722 5T704-742l198 198q8 8 13 19.278 5 11.278 5 23.722v131q0 24.75-17.625 42.375T860-310h-20q0 46-32.118 78-32.117 32-78 32Q684-200 652-232.083 620-264.167 620-310H340q0 46-32.118 78-32.117 32-78 32ZM600-560h194L654-700h-54v140Zm-250 0h190v-140H350v140Zm-250 0h190v-140H100v140Zm129.859 310Q255-250 272.5-267.359t17.5-42.5Q290-335 272.641-352.5t-42.5-17.5Q205-370 187.5-352.641t-17.5 42.5Q170-285 187.359-267.5t42.5 17.5Zm500 0Q755-250 772.5-267.359t17.5-42.5Q790-335 772.641-352.5t-42.5-17.5Q705-370 687.5-352.641t-17.5 42.5Q670-285 687.359-267.5t42.5 17.5ZM323-370h314q17-26 42.5-38t50.5-12q25 0 50.5 12t42.5 38h37v-130H100v130h37q17-26 42.5-38t50.5-12q25 0 50.5 12t42.5 38Zm537-130H100h760Z"
		/>
	</svg>
);
