import { ReactNode } from 'react';

export const symbol_ads_click: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-660q-75 0-127.5 52.5T300-480q0 57 32.5 103.5T419-311q13 5 20 16t3 24q-3 11-12.5 17.5T409-250q-76-23-122.5-87T240-480q0-100 70-170t170-70q79 0 142.5 46.5T709-551q3 11-3 20.5T689-518q-13 4-24-3t-16-20q-19-54-65.5-86.5T480-660Zm0-160q-142 0-241 99t-99 241q0 141 94.5 236T468-141q13 1 21.5 10t8.5 22q0 12-9 20t-21 8q-81-2-151.5-34t-123-86q-52.5-54-83-126T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q163 0 278.5 112.5T879-492q0 12-8 20t-20 9q-13 1-22-7.5T819-492q-10-140-103-234t-236-94Zm302 720L650-231l-35 106q-4 11-15 10.5T586-126l-98-327q-2-9 4-15t15-4l327 98q11 3 11.5 14T835-345l-106 35 132 132q17 17 17 39t-17 39q-17 17-39.5 17T782-100Z"
		/>
	</svg>
);
