import { ReactNode } from 'react';

export const symbol_closed_caption_add: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-480ZM180-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h600q24 0 42 18t18 42v339q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T780-401v-339H180v520h419q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T599-160H180Zm600 0h-50q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T730-220h50v-50q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T840-270v50h50q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T890-160h-50v50q0 12.75-8.675 21.375Q822.649-80 809.825-80 797-80 788.5-88.625T780-110v-50ZM549-361h142q12.75 0 21.375-8.625T721-391v-22q0-10.833-7.116-17.917-7.117-7.083-18-7.083Q685-438 678-430.828t-7 18.14V-411H569v-138h102v1.733Q671-536 678.116-529q7.117 7 18 7Q707-522 714-529.083q7-7.084 7-17.917v-22q0-12.75-8.625-21.375T691-599H549q-12.75 0-21.375 8.625T519-569v178q0 12.75 8.625 21.375T549-361Zm-279 0h142q12.75 0 21.375-8.625T442-391v-22q0-10.833-7.116-17.917-7.117-7.083-18-7.083Q406-438 399-430.828t-7 18.14V-411H290v-138h102v1.733Q392-536 399.116-529q7.117 7 18 7Q428-522 435-529.083q7-7.084 7-17.917v-22q0-12.75-8.625-21.375T412-599H270q-12.75 0-21.375 8.625T240-569v178q0 12.75 8.625 21.375T270-361Z"
		/>
	</svg>
);
