import { ReactNode } from 'react';

export const symbol_format_h5: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M149.825-280Q137-280 128.5-288.625T120-310v-340q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T180-650v140h180v-140q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T420-650v340q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T360-310v-140H180v140q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM570-280q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T570-340h210v-110H570q-12.75 0-21.375-8.625T540-480v-170q0-12.75 8.625-21.375T570-680h240q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-620H600v110h180q24.75 0 42.375 17.625T840-450v110q0 24.75-17.625 42.375T780-280H570Z"
		/>
	</svg>
);
