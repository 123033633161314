import { ReactNode } from 'react';

export const symbol_browse_gallery: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M390-491.869V-640q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T330-640v160q0 5.565 2 10.783Q334-464 339-459l112 112q9 9 21 9t21-9q9-9 9-21t-9-21L390-491.869ZM900-480.5q0-79.5-39.5-147T754-735q-11.083-6.585-15.042-18.293Q735-765 740-777t18-16q13-4 24 3 81 49 129.5 130.5T960-481q0 97-48.5 179T782-171q-11 7-24 3t-18-16q-5-12-1.042-24.098Q742.917-220.195 754-227q67-40 106.5-107T900-480.5ZM360-121q-75 0-140.5-28.5t-114-77q-48.5-48.5-77-114T0-481q0-75 28.5-140.5t77-114q48.5-48.5 114-77T360-841q75 0 140.5 28.5t114 77q48.5 48.5 77 114T720-481q0 75-28.5 140.5t-77 114q-48.5 48.5-114 77T360-121Zm0-60q124 0 212-87.321Q660-355.643 660-481q0-124-88-212t-212-88q-125.357 0-212.679 88Q60-605 60-481q0 125.357 87.321 212.679Q234.643-181 360-181Zm0-300Z"
		/>
	</svg>
);
