import { ReactNode } from 'react';

export const symbol_article_shortcut: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-480Zm300 360H390q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T390-180h390v-600H180v210q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T120-570v-210q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18ZM255-80q-74.842 0-127.421-52.579Q75-185.158 75-260q0-53 27-95.5t72-64.5h-64q-12.75 0-21.375-8.675Q80-437.351 80-450.175 80-463 88.625-471.5T110-480h160q12.75 0 21.375 8.625T300-450v160q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T240-290v-89q-45 5-75 39t-30 80q0 50.182 35 85.091Q205-140 255-140q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q285-97 276.375-88.5T255-80Zm135-199h132q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T522-339H390q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T390-279Zm0-171h263q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T653-510H390q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T390-450Zm-83-171h346q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T653-681H307q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T307-621Z"
		/>
	</svg>
);
