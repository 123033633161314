import { ReactNode } from 'react';

export const symbol_collections_bookmark: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M260-260h560v-560h-60v252q0 8.5-7.5 12.75T737-556l-54-33q-7.576-4-16.288-4T651-589l-54 33q-8 5-15.5.75T574-568v-252H260v560Zm0 60q-24 0-42-18t-18-42v-560q0-24 18-42t42-18h560q24 0 42 18t18 42v560q0 24-18 42t-42 18H260ZM140-80q-24 0-42-18t-18-42v-590q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-730v590h590q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q760-97 751.375-88.5T730-80H140Zm120-740v560-560Zm314 252q0 8.5 7.5 12.75T597-556l54-33q6.75-4 15.375-4T683-589l54 33q8 5 15.5.75T760-568q0 8.5-7.5 12.75T737-556l-54-33q-7.576-4-16.288-4T651-589l-54 33q-8 5-15.5.75T574-568Z"
		/>
	</svg>
);
