import ColorConverter, * as conv from 'pkg/colorconverter';

interface GroupPalette {
	primaryColor: string;
	primaryColorDark: string;
	primaryColorLight: string;

	accentColor: string;
	accentColorFaded: string;
	accentColorHover: string;
	accentColorLight: string;
	accentColorTextHover: string;

	colorProfileGradient: string;
	backgroundColor: string;
}

function generateColorProfileGradient(color: string): string {
	color = ColorConverter.from(color).toHSL(true) as string;

	color = conv.primaryColor(color).replace('hsl(', '').replace(')', '');

	const gradientColors: string[] = [];

	const hue = color.split(',')[0];
	const saturation = color.split(',')[1];
	const lum = parseInt(color.split(',')[2].replace('%', ''));

	if (lum > 15 && lum < 90) {
		gradientColors.push(`hsl(${hue}, ${saturation}, ${lum + 10}%)`);
		gradientColors.push(`hsl(${hue}, ${saturation}, ${lum}%)`);
		gradientColors.push(`hsl(${hue}, ${saturation}, ${lum - 15}%)`);
	} else if (lum < 15) {
		gradientColors.push(
			`hsl(${hue}, ${saturation}, ${lum + (15 - lum) + 10}%)`
		);
		gradientColors.push(`hsl(${hue}, ${saturation}, ${lum}%)`);
	} else {
		gradientColors.push(`hsl(${hue}, ${saturation}, ${lum}%)`);
		gradientColors.push(
			`hsl(${hue}, ${saturation}, ${lum - (100 - lum) - 15}%)`
		);
	}

	return gradientColors.join(', ');
}

export function getPaletteFromColor(color: string): GroupPalette {
	color = ColorConverter.from(color).toHSL(true) as string;

	const primaryColor = conv.primaryColor(color);

	const primaryColorDark = ColorConverter.from(color)
		.setLightness(42)
		.toStylesheetString();

	const primaryColorLight = ColorConverter.from(color)
		.setLightness(95)
		.desaturate(30)
		.toStylesheetString();

	const accentColor = conv.accentColor(color);
	const accentColorFaded = conv.accentColor(color, 0.17);
	const accentColorHover = conv.accentColor(color, 0.1);
	const accentColorLight = conv.accentColor(color, 0.06);
	const accentColorTextHover = conv.accentColor(color, 0.7);

	const colorProfileGradient = generateColorProfileGradient(color);
	const backgroundColor = conv.backgroundColor(color);

	return {
		primaryColor,
		primaryColorDark,
		primaryColorLight,

		accentColor,
		accentColorFaded,
		accentColorHover,
		accentColorLight,
		accentColorTextHover,

		colorProfileGradient,
		backgroundColor,
	};
}

export function applyGroupPalette(color: string): void {
	const styles = document.documentElement.style;

	const toVariableName = (colorName: string): string => {
		const variable = colorName.replace(
			/[A-Z]/g,
			(match: string) => '-' + match.toLowerCase()
		);

		return `--palette-group-${variable}`;
	};

	const palette = getPaletteFromColor(color);

	Object.entries(palette).forEach(([name, color]) => {
		styles.setProperty(toVariableName(name), color);
	});
}
