import React, {
	useRef,
	useState,
	useCallback,
	Children,
	createContext,
	useContext,
	ReactNode,
	Fragment,
	useMemo,
} from 'react';
import styled, { css, DefaultTheme } from 'styled-components';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import rgba from 'pkg/rgba';
import { addCssVar } from 'pkg/cssvars';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';

import CardAnatomy, * as Card from 'components/Card';
import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import Icon from 'components/icon';
import CloseButton from 'components/close-button';
import Modal from 'components/modal';

import { useAppState } from 'components/application/state';

import Button from 'design/button';

import * as styling from './styles.css';

const Wrapper = styled(CardAnatomy)`
	&[aria-busy='true'] {
		cursor: wait;
	}
`;

const Heading = styled(Card.Heading)<{ theme?: DefaultTheme }>`
	color: var(--palette-gray-800);
	font-size: var(--font-size-lg);
	font-weight: var(--font-weight-semibold);
	text-align: left;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	user-select: none;
	grid-area: title;
	max-width: calc(100% - 40px); // Adjust for long titles
	padding: var(--spacing-5);
	display: block;

	${({ theme }) =>
		theme.darkMode &&
		css`
			color: var(--palette-gray-300);
		`}
`;

const Description = styled.p`
	padding: var(--spacing-5);
	padding-top: 0;
	color: var(--palette-gray-600);
`;

const Header = styled(Card.Header)`
	padding: 0;

	@media ${styles.breakpoint.small} {
		padding-top: 0 !important;
		display: grid;
		grid-template-areas: 'prev title close';
		grid-template-columns: 35px 1fr 35px;
		grid-column-gap: var(--spacing-5);

		${Heading} {
			text-align: center;
			max-width: 100%;
		}
	}
`;

const PrevButton = styled.span<{ disabled: boolean; cautious?: boolean }>`
	padding: var(--spacing-4);
	color: ${addCssVar(
		(theme: DefaultTheme) => styles.palette.gray[theme.darkMode ? 200 : 800]
	)};
	font-size: var(--font-size-lg);
	font-weight: var(--font-weight-semibold);
	user-select: none;
	justify-self: start;
	grid-area: prev;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	cursor: pointer;

	@media (hover: hover) {
		&:not([disabled]):hover {
			color: ${rgba(styles.palette.blue[500], 0.7)};
		}
	}

	&[disabled] {
		color: var(--palette-gray-400);
		pointer-events: none;
		cursor: not-allowed;
	}

	@media ${styles.breakpoint.small} {
		font-size: var(--font-size-base);
	}

	${({ cautious }) =>
		cautious &&
		css`
			color: var(--palette-red-500);

			@media (hover: hover) {
				&:not([disabled]):hover {
					color: ${rgba(styles.palette.red[500], 0.7)};
				}
			}
		`}
`;

export const Body = styled(Card.Body)<{ busy?: boolean; table?: boolean }>`
	position: relative;
	padding-bottom: 140px;

	@supports (env(safe-area-inset-bottom)) {
		padding-bottom: calc(20px + env(safe-area-inset-bottom));

		@media ${styles.breakpoint.small} {
			padding-bottom: calc(140px + env(safe-area-inset-bottom));
		}
	}

	${({ busy }) =>
		busy &&
		css`
			overflow: hidden !important;
		`};

	${({ table }) =>
		table &&
		css`
			display: flex;
			flex-flow: column nowrap;
		`}
`;

const Footer = styled.div<{
	hasContent?: boolean;
	hasPrevButton?: boolean;
	hasNextButton?: boolean;
}>`
	display: flex;
	justify-content: flex-end;
	padding: var(--spacing-5);

	@media ${styles.breakpoint.small} {
		flex-direction: column;
		justify-content: stretch;
		gap: var(--spacing-5);
		padding: ${addCssVar(({ keyboardOpen }) =>
			!keyboardOpen
				? `var(--spacing-5) var(--spacing-5) calc(env(safe-area-inset-bottom) + var(--spacing-5)) var(--spacing-5)`
				: styles.spacing._5
		)};
	}
`;

const ButtonGroup = styled.div`
	display: flex;
	gap: var(--spacing-3);

	@media ${styles.breakpoint.small} {
		justify-content: stretch;
	}
`;

type PromiseFunction = () => Promise<boolean>;

type StepContext = {
	prev: () => void;
	withPrev: (callback: PromiseFunction) => void;
	setCanGoPrev: (canGoPrev: boolean) => void;
	next: () => void;
	withNext: (callback: PromiseFunction) => void;
	setCanGoNext: (canGoNext: boolean) => void;
	close: () => void;
	goTo: (slug: string) => void;
	busy: boolean;
	setBusy: (isBusy: boolean) => void;
};

export const StepModalContext = createContext<StepContext>(null);

export const useStepModalContext = () =>
	useContext<StepContext>(StepModalContext);

export interface StepProps {
	children: ReactNode;
	skipBody?: boolean;
	flexBody?: boolean;
	spaciousBody?: boolean;
	tableStep?: boolean;
	busy?: boolean;
	onPrev?: () => Promise<boolean | void>;
	canGoPrev?: boolean;
	prevLabel?: string;
	hidePrev?: boolean;
	prevSlug?: string;
	onNext?: () => Promise<boolean | void>;
	canGoNext?: boolean;
	nextLabel?: string;
	hideNext?: boolean;
	nextSlug?: string;
	slug?: string;
	title?: string;
	description?: string;
	hideLargeScreenTitle?: boolean;
	closeOnNext?: boolean;
	closeOnPrev?: boolean;
	cautiousPrev?: boolean;
	cautiousNext?: boolean;
	canClose?: boolean;
	footerContent?: ReactNode;
}

export const Step = ({
	children,
	skipBody,
	flexBody = true,
	spaciousBody,
	busy,
	tableStep = false,
}: StepProps): JSX.Element => (
	<Body
		$flex={flexBody}
		$noSpacing={skipBody}
		$spaciousBody={spaciousBody}
		busy={busy}
		table={tableStep}>
		{children}
	</Body>
);

export const StepWithoutBody = ({ children }: StepProps): JSX.Element => (
	<Fragment>{children}</Fragment>
);

export interface StepModalProps {
	children: ReactNode;
	onOpen?: () => void;
	onClose?: () => void;
	onBeforeClose?: () => boolean;
	// onFinish is called after the entire modal is completed
	onFinish?: () => void;
	canNavigate?: boolean;
	wide?: boolean;
	thin?: boolean;
	customBackdrop?: ReactNode;
	initialIndex?: number;
	hideCloseButton?: boolean;
	deleteButton?: boolean;
	handleDelete?: () => void;
}

export const Base = ({
	onOpen,
	onClose,
	onBeforeClose,
	children,
	canNavigate = true,
	wide = false,
	thin = false,
	customBackdrop = null,
	onFinish = null,
	initialIndex = 0,
	hideCloseButton = false,
	deleteButton = false,
	handleDelete: handleDeleteCategory,
}: StepModalProps): JSX.Element => {
	// @NOTE Filter out conditional children (null children)
	const filteredChildren = Children.toArray(children).filter((n) => n);

	const { keyboardOpen } = useAppState();

	const modalRef = useRef(null);
	const [busy, setBusy] = useState(false);
	const [currentIndex, setCurrentIndex] = useState<number>(initialIndex);
	const [__canGoPrev, setCanGoPrev] = useState<boolean>(true);
	const [__canGoNext, setCanGoNext] = useState<boolean>(true);

	const __prevCallback = useRef<PromiseFunction>();
	const __nextCallback = useRef<PromiseFunction>();

	useComponentDidMount(() => {
		if (onOpen) {
			onOpen();
		}
	});

	const currentStep = useMemo(
		() =>
			filteredChildren.find(
				(child: ReactNode, n: number) => n === currentIndex
			) as React.ReactElement<StepProps>,
		[currentIndex, filteredChildren]
	);

	const {
		title = '',
		description = '',
		hideLargeScreenTitle = false,

		canGoPrev = true,
		onPrev = null,
		hidePrev = false,
		prevSlug = '',

		canGoNext = true,
		onNext = null,
		hideNext = false,
		nextSlug = '',

		closeOnNext = false,
		closeOnPrev = false,

		cautiousPrev = false,

		canClose = true,

		footerContent = null,
	} = currentStep ? currentStep.props : {};

	let { prevLabel = '', nextLabel = '' } = currentStep ? currentStep.props : {};

	if (currentIndex === 0 && prevLabel === '') {
		prevLabel = t('Cancel', { _context: 'modal' });
	} else if (prevLabel === '') {
		prevLabel = t('Back', { _context: 'modal' });
	}

	if (nextLabel === '') {
		nextLabel = t('Continue', { _context: 'modal' });
	}

	const withPrev = useCallback((callback: PromiseFunction) => {
		__prevCallback.current = callback;
	}, []);

	const withNext = useCallback((callback: PromiseFunction) => {
		__nextCallback.current = callback;
	}, []);

	const exit = () => {
		let canCloseModal = true;

		if (onBeforeClose) {
			canCloseModal = onBeforeClose();
		}

		if (canCloseModal) {
			close();
		}
	};

	const close = () => modalRef.current?.close();

	const goTo = useCallback(
		(slug: string) => {
			const index = filteredChildren.findIndex(
				(child: React.ReactElement<StepProps>) => child.props?.slug === slug
			);

			if (index >= 0) {
				setCurrentIndex(index);
			}
		},
		[filteredChildren]
	);

	const prev = useCallback(async () => {
		if (__prevCallback.current) {
			setBusy(true);

			const canContinue = await __prevCallback.current();

			setBusy(false);

			if (canContinue === false) {
				return;
			}
		}

		if (onPrev) {
			setBusy(true);

			const canContinue = await onPrev();

			setBusy(false);

			if (canContinue === false) {
				return;
			}
		}

		if (closeOnPrev === true) {
			close();
			return;
		}

		if (prevSlug) {
			goTo(prevSlug);
		} else if (currentIndex > 0) {
			setCurrentIndex(currentIndex - 1);
		} else if (currentIndex === 0) {
			close();
		}

		__prevCallback.current = () => Promise.resolve(true);
	}, [currentIndex, onPrev, setBusy, prevSlug, close, goTo, closeOnPrev]);

	const next = useCallback(async () => {
		if (__nextCallback.current) {
			setBusy(true);

			const canContinue = await __nextCallback.current();

			setBusy(false);

			if (canContinue === false) {
				return;
			}
		}

		if (onNext) {
			setBusy(true);

			const canContinue = await onNext();

			setBusy(false);

			if (canContinue === false) {
				return;
			}
		}

		const isLast: boolean = currentIndex + 1 === filteredChildren.length;

		__nextCallback.current = () => Promise.resolve(true);

		if (closeOnNext === true) {
			close();
			return;
		}

		if (nextSlug) {
			goTo(nextSlug);
		} else if (!isLast) {
			setCurrentIndex(currentIndex + 1);
		} else if (isLast) {
			if (onFinish) {
				onFinish();
			}

			close();
		}
	}, [
		onNext,
		currentIndex,
		filteredChildren.length,
		closeOnNext,
		nextSlug,
		close,
		goTo,
		onFinish,
	]);

	if (!currentStep) return null;

	let canRenderPrev = true;
	if (hidePrev) {
		canRenderPrev = false;
	}

	const backDisabled = busy || !__canGoPrev || !canGoPrev;
	const nextDisabled = busy || !__canGoNext || !canGoNext;

	const modalContext = {
		prev,
		withPrev,
		setCanGoPrev,
		next,
		withNext,
		setCanGoNext,
		goTo,
		close,
		busy,
		setBusy,
	};

	let showCloseButton = true;

	if (hideCloseButton || !canClose) {
		showCloseButton = false;
	}

	const showDivider =
		(canNavigate && canRenderPrev && currentIndex !== 0) ||
		!!title ||
		showCloseButton;

	return (
		<Modal
			hideClose
			forwardedRef={modalRef}
			wide={wide}
			thin={thin}
			onClose={onClose}
			customBackdrop={customBackdrop}>
			<Wrapper aria-busy={busy}>
				{!hideLargeScreenTitle && (
					<LargeScreen>
						<Header>
							<Heading data-testid="step_modal.heading">{title}</Heading>
							{showCloseButton && (
								<CloseButton className={styling.closeButton} onClick={exit} />
							)}
						</Header>
						{description && <Description>{description}</Description>}
						<Card.Divider />
					</LargeScreen>
				)}

				<SmallScreen>
					<Header>
						{canNavigate && canRenderPrev && currentIndex !== 0 && (
							<PrevButton
								onClick={prev}
								aria-busy={busy}
								disabled={backDisabled}
								cautious={cautiousPrev}
								data-testid="step_modal.prev">
								<Icon name="chevron" rotate="180deg" size={2} />
							</PrevButton>
						)}
						<Heading>{title}</Heading>
						{showCloseButton && (
							<CloseButton className={styling.closeButton} onClick={exit} />
						)}
					</Header>
					{description && <Description>{description}</Description>}
					{showDivider && <Card.Divider />}
				</SmallScreen>

				<StepModalContext.Provider value={modalContext}>
					{currentStep}
				</StepModalContext.Provider>

				{canNavigate && (canRenderPrev || !hideNext) && (
					<Fragment>
						<LargeScreen>
							<Card.Divider />
							<Footer
								hasContent={!!footerContent}
								hasPrevButton={canRenderPrev}
								hasNextButton={!hideNext}>
								{deleteButton && (
									<Button
										caution
										large
										onClick={handleDeleteCategory}
										busy={busy}
										testid="step_modal.delete">
										{t('Delete')}
									</Button>
								)}
								<ButtonGroup>
									{footerContent}
									{canRenderPrev && (
										<Button
											large
											onClick={prev}
											busy={busy}
											disabled={backDisabled}
											testid="step_modal.prev">
											{prevLabel}
										</Button>
									)}
									{!hideNext && (
										<Button
											large
											primary
											onClick={next}
											busy={busy}
											disabled={nextDisabled}
											isLoading={busy}
											testid="step_modal.next">
											{nextLabel}
										</Button>
									)}
								</ButtonGroup>
							</Footer>
						</LargeScreen>
						{!hideNext && !keyboardOpen && (
							<SmallScreen>
								<Card.Divider />
								<Footer>
									{deleteButton && (
										<Button
											caution
											large
											block
											onClick={handleDeleteCategory}
											busy={busy}
											testid="step_modal.delete">
											{t('Delete')}
										</Button>
									)}
									<ButtonGroup>
										{footerContent}
										<Button
											large
											block
											primary
											onClick={next}
											busy={busy}
											disabled={nextDisabled}
											isLoading={busy}
											testid="step_modal.next">
											{nextLabel}
										</Button>
									</ButtonGroup>
								</Footer>
							</SmallScreen>
						)}
					</Fragment>
				)}
			</Wrapper>
		</Modal>
	);
};

export default Base;
