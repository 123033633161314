export function Create(): string {
	return `/v1/schedules`;
}

export function Delete(ID: number): string {
	return `/v1/schedules/${ID}`;
}

export function DuplicateSchedule(ID: number): string {
	return `/v1/schedules/${ID}/duplicate`;
}

export function Index(): string {
	return `/v1/schedules`;
}

export function Publish(ID: number): string {
	return `/v1/schedules/publish/${ID}`;
}

export function PublishedPeriods(): string {
	return `/v1/schedules/published-periods`;
}

export function Show(ID: number): string {
	return `/v1/schedules/${ID}`;
}

export function Update(ID: number): string {
	return `/v1/schedules/${ID}`;
}
