import { t } from '@transifex/native';
import { Fragment, useState } from 'react';

import * as models from 'pkg/api/models';

import JoinModal, { useAlreadyMemberOf } from 'components/group/join-modal';
import JoinWidget from 'components/group/join-widget';

import Button from 'design/button';

interface JoinEmbedProps {
	groupCode: string;
}

export default function JoinEmbed({ groupCode }: JoinEmbedProps): JSX.Element {
	const [modalOpen, setModalOpen] = useState<boolean>(false);
	const [groupInfo, setGroupInfo] = useState<models.onboardingInfo.Group>();

	const alreadyMemberOfGroup = useAlreadyMemberOf(groupCode);

	const openModal = () => setModalOpen(true);
	const closeModal = () => setModalOpen(false);

	const handleDidLoad = (groupInfo: models.onboardingInfo.Group) => {
		setGroupInfo(groupInfo);
	};

	return (
		<Fragment>
			{modalOpen && <JoinModal groupCode={groupCode} onClose={closeModal} />}
			<JoinWidget
				groupCode={groupCode}
				onDidLoad={handleDidLoad}
				heading={
					alreadyMemberOfGroup
						? t('You and your children are all a member of')
						: null
				}>
				{!alreadyMemberOfGroup && (
					<Button primary={!groupInfo?.primaryColor} onClick={openModal}>
						{t('Join now')}
					</Button>
				)}
			</JoinWidget>
		</Fragment>
	);
}
