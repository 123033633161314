import styled, { css } from 'styled-components';

import * as iconStyles from 'components/icon/styles.css';

const SharedStyles = css`
	text-align: right;
	padding: var(--spacing-3) var(--spacing-4);
`;

export const Cell = styled.td`
	${SharedStyles}

	&:first-child {
		color: var(--palette-gray-800);
		font-weight: var(--font-weight-semibold);
		text-align: left;
	}
`;

export const CellInnerText = styled.div`
	font-weight: var(--font-weight-normal);
	font-size: var(--font-size-xs);
	color: var(--palette-gray-500);
`;

export const IconCell = styled.td`
	width: 40px;
	padding: var(--spacing-3) var(--spacing-2);
	cursor: pointer;
	width: 30px;
	text-align: center;

	.${iconStyles.icon} {
		width: 23px;
		height: 23px;
		color: var(--palette-blue-500);
	}
`;

export const HeadCell = styled.th`
	${SharedStyles}

	&:first-child {
		padding-left: 0px;
		text-align: left;
	}
`;

export const TableHeadRow = styled.tr`
	background-color: var(--palette-white);
`;

export const TableRow = styled.tr`
	background-color: var(--palette-blue-100);

	> *:first-child {
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
	}

	> *:last-child {
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
		padding-right: var(--spacing-4);
	}
`;

export const Table = styled.table`
	width: 100%;
	font-size: var(--font-size-sm);
	border-collapse: separate;
	border-spacing: 0 2px;
`;
