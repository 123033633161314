import { Dateable } from 'pkg/api/models/dateable';
import { Linkable } from 'pkg/api/models/linkable';
import { Record } from 'pkg/api/models/record';
import * as models from 'pkg/api/models';
import { EventFlags, EventTypes } from 'pkg/api/models/event';
import * as endpoints from 'pkg/api/endpoints/auto';

import { ConflictingItems, ConflictMap } from 'routes/scheduling/utils';
import {
	findConflicts,
	populateConflicts,
} from 'routes/scheduling/bookings/utils';

export interface Booking extends Record, Linkable, Dateable {
	scheduleId: number;
	scheduleItemId: number;
	eventId: number;
	userId: number;
	resourceId: number;
	forGroupId: number;
	byGroupId: number;
	title: string;
	description: string;
	timezone: string;
	endsAt: number;
	startsAt: number;
	startsAtLocal?: string;
	endsAtLocal?: string;

	type: EventTypes;
	scheduleItem: models.scheduleItem.ScheduleItem;
	resource: models.resource.Resource;
	resources: BookingResource[];
	schedule: models.schedule.Schedule;
	event?: models.event.Event;
}

export interface BookingResource extends Record {
	resourceId: number;

	resource: models.resource.Resource;
}

export interface BookingCreatePayload {
	eventId?: number;
	userId: number;
	resourceId?: number;
	resourceIds?: number[];
	forGroupId: number;
	byGroupId: number;
	title?: string;
	description?: string;
	timezone: string;
	endsAt: number;
	startsAt: number;
	/**
	 * startsAtLocal is the date time string for the event in the booking's timezone.
	 * this is basically a direct representation of the values in the form inputs, as opposed
	 * to the UNIX timestamp that startsAt is
	 */
	startsAtLocal?: string;
	/**
	 * endsAtLocal is the date time string for the event in the booking's timezone.
	 * this is basically a direct representation of the values in the form inputs, as opposed
	 * to the UNIX timestamp that endsAt is
	 */
	endsAtLocal?: string;
	type: EventTypes;
	inviteUsersToEvent?: boolean;
	inviteAdminAndStaffToEvent?: boolean;
	eventFlags?: models.event.EventFlags[];
}

export function getConflictingItems(
	bookings: Booking[],
	conflictMap: ConflictMap
) {
	const conflicts: ConflictingItems = {};

	bookings.forEach((booking) => {
		bookings.forEach((innerBooking) => {
			const hasConflicts = findConflicts(booking, innerBooking, conflictMap);
			if (!hasConflicts) {
				return;
			}

			populateConflicts(conflicts, booking, innerBooking, conflictMap);
		});
	});

	return conflicts;
}

export async function create(
	payload: BookingCreatePayload,
	createEvents: boolean = false
): Promise<[Response, Booking, models.APIError?]> {
	return models.create(
		createEvents
			? endpoints.Booking.Create() + '?createEvents=true'
			: endpoints.Booking.Create(),
		payload
	);
}

export interface BookingUpdatePayload {
	eventId?: number;
	resourceId: number;
	forGroupId: number;
	title?: string;
	description?: string;
	timezone: string;
	endsAt: number;
	startsAt: number;
	/**
	 * startsAtLocal is the date time string for the event in the booking's timezone.
	 * this is basically a direct representation of the values in the form inputs, as opposed
	 * to the UNIX timestamp that startsAt is
	 */
	startsAtLocal?: string;
	/**
	 * endsAtLocal is the date time string for the event in the booking's timezone.
	 * this is basically a direct representation of the values in the form inputs, as opposed
	 * to the UNIX timestamp that endsAt is
	 */
	endsAtLocal?: string;
	resourceIds?: number[];
	eventFlags?: EventFlags[];
}

export async function update(
	booking: Booking,
	payload: BookingUpdatePayload,
	createEvent: boolean = false
): Promise<[Response, Booking, models.APIError?]> {
	if (createEvent) {
		return models.update(booking, payload, { createEvents: true });
	}

	return models.update(booking, payload, createEvent);
}

export async function remove(booking: Booking): Promise<boolean> {
	return models.destroy(booking);
}
