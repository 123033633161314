import styled from 'styled-components';

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const Row = styled.div`
	font-size: var(--font-size-sm);
`;

export const Name = styled.div`
	color: var(--palette-gray-500);
	margin-bottom: var(--spacing-1);
`;

export const Title = styled.div`
	font-weight: var(--font-weight-semibold);
`;
