import { ReactNode } from 'react';

export const symbol_markdown_copy: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M300-200q-24 0-42-18t-18-42v-560q0-24 18-42t42-18h440q24 0 42 18t18 42v560q0 24-18 42t-42 18H300Zm0-60h440v-560H300v560ZM180-80q-24 0-42-18t-18-42v-590q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T180-730v590h470q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q680-97 671.375-88.5T650-80H180Zm259-530h57v102q0 10.833 7.116 17.917 7.117 7.083 18 7.083Q532-483 539-490.083q7-7.084 7-17.917v-102h56v165q0 10.833 7.116 17.917 7.117 7.083 18 7.083Q638-420 645-427.083q7-7.084 7-17.917v-181q0-14.45-9.487-24.225Q633.025-660 619-660H422q-14.025 0-23.513 9.775Q389-640.45 389-626v181q0 10.833 7.116 17.917 7.117 7.083 18 7.083Q425-420 432-427.083q7-7.084 7-17.917v-165ZM300-260v-560 560Z"
		/>
	</svg>
);
