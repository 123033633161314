import { ReactNode } from 'react';

export const symbol_tv_guide: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-60h600v-600H180v600Zm0-600v600-600Zm140 210v220q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T380-350v-220h123l90 232q2.971 8.25 10.029 13.125Q610.086-320 619-320h21.7q9.3 0 16.322-4.875Q664.043-329.75 667-338l96-248q6-16-3.818-30t-27.273-14Q721-630 713.5-624.5 706-619 702-609l-72 188-73-190q-3-9-11-14t-17-5H210q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T210-570h110Z"
		/>
	</svg>
);
