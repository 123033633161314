import styled, { css } from 'styled-components';

import * as breakpoints from 'pkg/config/breakpoints';
import * as palette from 'pkg/config/palette';

import {
	TableHeadCell,
	TableHead as THead,
	TableRow as TR,
	TableBody,
	TableCell,
} from 'components/legacy/Table';

export const TH = styled(TableHeadCell)`
	background: transparent;
	font-size: var(--font-size-base);
	padding: 8px 10px;
	text-align: left;
	opacity: 1;

	@media ${breakpoints.small} {
		padding: 8px 10px;
	}
`;

export const TD = styled(TableCell)`
	padding: 8px 10px;
	font-size: var(--font-size-base);
	font-weight: var(--font-weight-normal);
	width: 80px;
	opacity: 1;
	color: #545b6c;

	@media ${breakpoints.small} {
		padding: 8px 10px;
	}
`;

export const TBody = styled(TableBody)`
	${TR} {
		&:first-of-type {
			border-top: 0px solid;

			${TH}, ${TD} {
				padding-top: 20px;

				@media ${breakpoints.small} {
					padding-top: 20px;
				}
			}
		}
	}

	${TR} {
		@media (hover: hover) {
			&:hover {
				background: rgba(189, 198, 207, 0);
			}
		}
	}

	${TH}, ${TD} {
		padding-left: 0;
		padding-right: 0;

		&:first-child {
			padding-left: 0;
		}
		&:last-child {
			padding-right: 0;
		}
	}
`;

export const Categories = styled.div`
	margin: 15px auto;

	${(props) =>
		props.single &&
		css`
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-column-gap: 40px;
			grid-row-gap: 40px;

			@media ${breakpoints.small} {
				grid-template-columns: 1fr;
				width: 100%;
			}
		`};
`;

Categories.defaultProps = {
	single: false,
};

export const CategoryHeader = styled(THead)`
	position: relative;
`;

export const HeaderRow = styled(TR)`
	position: relative;
`;

export const CategoryLabel = styled.th`
	line-height: 1.15;
	font-size: var(--font-size-lg);
	font-weight: var(--font-weight-semibold);
	color: ${palette.gray[800]};
	text-align: left;
	padding-left: 0;
	padding-right: 0;
	padding-top: 0;
	padding-bottom: 1rem;
`;

export const ComparisonIndicator = styled.div`
	display: inline-block;
	height: 6px;
	width: 6px;
	background: ${(props) =>
		props.comparison ? 'rgb(0, 234, 157)' : 'rgba(11, 120, 227, 1)'};
	border-radius: var(--radius-3);
	margin-right: 7px;
	margin-bottom: 4px;
`;

export const CategoryAverage = styled.th`
	font-size: var(--font-size-lg);
	font-weight: var(--font-weight-semibold);
	color: ${palette.gray[800]};
	text-align: ${(props) => props.textAlign};
	position: relative;
	padding-top: 0;
	padding-bottom: 1rem;
	width: 80px;

	h4 {
		font-size: 11px;
		color: ${palette.black};
		width: 100%;
		text-align: center;
		order: -1;
		margin-bottom: 0.5rem;
		font-weight: var(--font-weight-normal);
		letter-spacing: 1px;
		text-transform: uppercase;
		position: absolute;
		left: 0;
		right: 0;
		top: 25px;
	}
`;

CategoryAverage.defaultProps = {
	average: 0,
	textAlign: 'center',
};

export const CategoryAverageBar = styled.th`
	height: 8px;

	${(props) =>
		props.secondRow &&
		css`
			margin-top: 3px;
		`};

	background: rgba(230, 230, 230, 0.5);

	&::after {
		content: '';
		display: block;
		height: 100%;
		width: ${(props) => `${props.average}%`};

		background: ${(props) =>
			props.comparison ? 'rgb(0, 234, 157)' : 'rgba(11, 120, 227, 1)'};
	}
`;

CategoryAverageBar.defaultProps = {
	average: 0,
	secondRow: false,
};
