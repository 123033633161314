import * as endpoints from 'pkg/api/endpoints/auto';
import { EventFlags, RSVPInterval } from 'pkg/api/models/event';
import * as sdk from 'pkg/core/sdk';

export interface CreateEventSeriesUser {
	userId: number;
	role?: string;
}

export interface CreateEventSeriesPayload {
	groupId: number;
	timezone?: string;
	title: string;
	description?: string;
	location?: string;
	type?: string;
	duration: number;
	rRuleString: string;
	isPrivate: boolean;
	isPublic: boolean;
	hideParticipants: boolean;
	meetBeforeMinutes?: number;
	rsvpInterval?: RSVPInterval;
	rsvpIntervalCount?: number;
	users?: CreateEventSeriesUser[];
	sendInvites: boolean;
	flags: EventFlags[];
}

export const create = (payload: CreateEventSeriesPayload): Promise<Response> =>
	sdk.post(endpoints.EventSeries.Create(), {}, payload);

export const addAttachment = (seriesId: number, attachmentId: number) => {
	sdk.post(endpoints.EventSeries.AddAttachment(seriesId, attachmentId));
};

export const removeAttachment = (
	seriesId: number,
	attachmentId: number
): Promise<Response> =>
	sdk.destroy(endpoints.EventSeries.RemoveAttachment(seriesId, attachmentId));

export interface UpdateEventSeriesPayload {
	timezone: string;
	title: string;
	description: string;
	location: string;
	duration: number;
	rRuleString: string;
	isPrivate: boolean;
	isPublic: boolean;
	hideParticipants: boolean;
	meetBeforeMinutes: number;
	rsvpInterval?: RSVPInterval | '';
	rsvpIntervalCount?: number;
	users?: CreateEventSeriesUser[];
	sendInvites: boolean;
	flags: EventFlags[];
}

export const update = (id: number, payload: UpdateEventSeriesPayload) =>
	sdk.patch(endpoints.EventSeries.Update(id), {}, payload);
