import { ReactNode } from 'react';

export const symbol_letter_switch: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M640-80q-12.75 0-21.375-8.625T610-110v-170q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T670-280v101q53-64 81.5-141T780-480q0-83-28.5-160.5T670-782v102q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T610-680v-170q0-12.75 8.625-21.375T640-880h170q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-820h-94q60 72 92 159t32 181q0 94-32 181t-92 159h94q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q840-97 831.375-88.5T810-80H640ZM236-234h70v-48h-70v48Zm118 0h70v-48h-70v48Zm-96-108q-9 0-15.5-6.5T236-364v-13h-57q-9 0-15.5-6.5T157-399q0-9 6.5-15.5T179-421h57v-9q0-9 6.5-15t15.5-6q9 0 15 6t6 15v9h101v-9q0-9 6.5-15t15.5-6q9 0 15 6t6 15v9h57q9 0 15.5 6.5T502-399q0 9-6.5 15.5T480-377h-57v14q0 9-6 15t-15 6q-9 0-15.5-6.5T380-364v-13H279v14q0 9-6 15t-15 6Zm74 174q-21 34-56 52t-74 29q-12 3-24-.5T159-102q-5-8-1-16.5t14-10.5q35-6 66-19t50-42H173q-9 0-15-6.5t-6-15.5q0-9 6.5-15t15.5-6h18v-77q0-6 4.5-10.5T207-325h98v-15q0-10 7-17t17-7q10 0 17 7t7 17v15h103q6 0 10.5 4.5T471-310v77h14q9 0 15 6t6 15q0 9-6 15.5t-15 6.5H370q24 24 53.5 39.5T487-128q10 2 15 10t0 16q-6 11-17.5 16T461-84q-38-9-71.5-30.5T332-168Zm-14-371-32-6q-8-2-12.5-7.5T267-568q-2-11 5-19.5t18-8.5h20q9 0 18-2.5t12-10.5q9-26 11.5-53.5T353-718q0-14-10.5-24.5T318-753h-26q-11 48-28.5 93.5T223-570q-6 11-16.5 15.5T185-555q-10-5-12-15.5t4-20.5q22-36 38-74.5t25-79.5h-53q-11 0-18.5-7.5T161-771q0-11 7.5-18.5T187-797h65q2-14 3.5-27t3.5-27q1-11 10-17t19-3q12 4 18.5 14.5T311-834q-2 11-4.5 21t-5.5 20h31q29 0 49 22.5t21 52.5q1 36-4.5 71T380-578q-3 9-9.5 17T356-548q-8 5-18 8t-20 1Zm176-134q-11 5-20.5-1T460-691q-8-24-18-47t-24-44q-6-9-4-19.5t12-15.5q11-5 22-1.5t17 14.5q13 22 23.5 45.5T509-712q5 11 .5 22.5T494-673Z"
		/>
	</svg>
);
