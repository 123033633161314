import { ReactNode } from 'react';

export const symbol_thermometer_add: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M690-650H590q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T590-710h100v-100q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T750-810v100h100q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-650H750v100q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T690-550v-100ZM320-120q-75.53 0-128.765-53.235Q138-226.47 138-302q0-49.099 24-91.55Q186-436 228-462v-286q0-38.333 26.765-65.167 26.764-26.833 65-26.833Q358-840 385-813.167q27 26.834 27 65.167v286q42 26 66 68.45 24 42.451 24 91.55 0 75.53-53.235 128.765Q395.53-120 320-120Zm-32-429h64v-199q0-13.6-9.2-22.8-9.2-9.2-22.8-9.2-13.6 0-22.8 9.2-9.2 9.2-9.2 22.8v199Z"
		/>
	</svg>
);
