import { ReactNode } from 'react';

export const symbol_clear_day: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M479.825-770Q467-770 458.5-778.625T450-800v-90q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-890v90q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM685-685q-9-9-9-21.1 0-12.1 9-20.9l63-64q9.067-9 21.533-9Q782-800 791-791q9 9 9 21t-9 21l-64 64q-9 9-21 9t-21-9Zm115 235q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T800-510h90q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T890-450h-90ZM479.825-40Q467-40 458.5-48.625T450-70v-90q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-160v90q0 12.75-8.675 21.375Q492.649-40 479.825-40ZM233-685l-64-63q-9-8.87-9-21.435T169.391-791Q178-800 190-800q12 0 21 9l64 64q9 9 9 21t-9 21q-9.273 8-21.636 8Q241-677 233-685Zm516 516-64-64q-9-8.8-9-20.9 0-12.1 9-21.1 8.25-8 20.625-8T727-275l65 63q9 9 8.629 21.603-.37 12.604-8.526 21.5-8.896 8.897-21.5 8.897Q758-160 749-169ZM70-450q-12.75 0-21.375-8.675Q40-467.351 40-480.175 40-493 48.625-501.5T70-510h90q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T160-450H70Zm99 280.609Q160-178 160-190q0-12 9-21l64-64q8.25-8 20.625-8T275-274.75q9 9 9 21.375T275-232l-63 63q-8.87 9-21.435 9T169-169.391ZM480-240q-100 0-170-70t-70-170q0-100 70-170t170-70q100 0 170 70t70 170q0 100-70 170t-170 70Zm0-60q75 0 127.5-52.5T660-480q0-75-52.5-127.5T480-660q-75 0-127.5 52.5T300-480q0 75 52.5 127.5T480-300Zm0-180Z"
		/>
	</svg>
);
