import { ReactNode } from 'react';

export const symbol_play_for_work: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-160q-92 0-159.5-57T242-360q-2-11.2 6.5-19.6 8.5-8.4 21.25-8.4t21.375 6.855Q299.75-374.29 302-363q10 62 60 102.5T480-220q68 0 118-40.5T658-363q2.25-11.29 10.875-18.145T690.25-388q12.75 0 21.25 8.4T718-360q-11 86-78.5 143T480-160Zm-33-313v-255q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T507-728v254l67-67q9.273-9 21.636-8.5 12.364.5 21.755 9.5Q626-531 626-518.5t-9 21.5L498-379q-5 5-10.133 7-5.134 2-11 2Q471-370 466-372q-5-2-10-7L337-498q-9-9-9-21.158 0-12.158 8.609-21Q346-549 358.467-549q12.466 0 21.533 9l67 67Z"
		/>
	</svg>
);
