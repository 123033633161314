import { ReactNode } from 'react';

export const symbol_web_traffic: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M151-533h66q13 0 21.5 8.5T247-503q0 13-8.5 21.5T217-473h-66q-13 0-21.5-8.5T121-503q0-13 8.5-21.5T151-533Zm64 231 48-48q8-8 20-8t20 8q8 8 8 20t-8 20l-48 48q-8 8-20 8t-20-8q-8-8-8-20t8-20Zm48-357-48-48q-8-8-8-20t8-20q8-8 20-8t20 8l48 48q8 8 8 20t-8 20q-8 8-20 8t-20-8Zm456 479L552-347l-29 89q-2 5-5.5 8t-8.5 3q-5 0-9-3t-6-8l-78-273q-2-7-.5-12.5t6.5-9.5q5-4 10-5.5t12 .5l269 85q5 2 8 5.5t3 8.5q0 5-2.5 8.5T714-445l-96 34 166 166q9 9 9 21t-9 21l-23 23q-9 9-21 9t-21-9ZM437-744v-66q0-13 8.5-21.5T467-840q13 0 21.5 8.5T497-810v66q0 13-8.5 21.5T467-714q-13 0-21.5-8.5T437-744Zm194 45 48-48q8-8 20-8t20 8q8 8 8 20t-8 20l-48 48q-8 8-20 8t-20-8q-8-8-8-20t8-20Z"
		/>
	</svg>
);
