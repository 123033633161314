import { ReactNode } from 'react';

export const symbol_boy: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M479.899-680Q455-680 437.5-697.601t-17.5-42.5Q420-765 437.601-782.5t42.5-17.5Q505-800 522.5-782.399t17.5 42.5Q540-715 522.399-697.5t-42.5 17.5ZM420-190v-170h-10q-12.75 0-21.375-8.625T380-390v-170q0-24.75 17.625-42.375T440-620h80q24.75 0 42.375 17.625T580-560v170q0 12.75-8.625 21.375T550-360h-10v170q0 12.75-8.646 21.375T509.927-160h-60.146Q437-160 428.5-168.625 420-177.25 420-190Z"
		/>
	</svg>
);
