import { ReactNode } from 'react';

export const symbol_do_not_disturb_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m593-453-60-60h118q12 0 20.5 9t8.5 21.5q0 12.5-8.625 21T650-453h-57Zm-172-60v60H310q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T310-513h111Zm59 433q-85 0-158-30.5T195-195q-54-54-84.5-127T80-480q0-68 20-128t56-110L48-827q-9-9.067-9-21.533Q39-861 48.053-870q9.052-9 21.5-9Q82-879 91-870l764 764q9 9.067 9 21.533Q864-72 854.947-63q-9.052 9-21.5 9Q821-54 812-63l-94-93q-50 36-110 56T480-80Zm0-60q54.643 0 103.821-15.5Q633-171 675-199L199-675q-28 42-43.5 91.179Q140-534.643 140-480q0 145 97.5 242.5T480-140Zm-43-297Zm116-116ZM275-824q47-28 99-42t106-14q80 0 153 30t130 87q57 57 87 130t30 153q0 54-13.5 105T827-277q-6 11-18 13.5t-23-4.5q-11-7-13-19.5t4-23.5q21-39 32-81.5t11-87.5q0-142.375-98.812-241.188Q622.375-820 480-820q-45 0-88 11t-82 33q-11 6-22.5 4T268-784q-8-10-6-22t13-18Z"
		/>
	</svg>
);
