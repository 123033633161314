import { ReactNode } from 'react';

export const symbol_device_thermostat: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-80q-83 0-141.5-58.5T280-280q0-51 23-96t68-67v-328q0-45.417 31.792-77.208Q434.583-880 480-880t77.208 31.792Q589-816.417 589-771v328q45 22 68 67t23 96q0 83-58.5 141.5T480-80Zm-49-435h98v-53h-49v-39h49v-87h-49v-38.5h49V-771q0-20.825-14.123-34.912-14.123-14.088-35-14.088Q459-820 445-805.912 431-791.825 431-771v256Z"
		/>
	</svg>
);
