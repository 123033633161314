import { ReactNode } from 'react';

export const symbol_edit_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M823-694 605-476q-9 9-21 9t-21-9q-9-9-9-21t9-21l133-133-44-44-133 133q-9 9-21 9t-21-9q-9-9-9-21t9-21l218-218q8-8 19-12.5t23-4.5q11 0 22 4.5t20 12.5l44 44q9 9 13 20t4 22q0 11-4.5 22.5T823-694ZM181-180h44l233-233-22-22-22-22-233 233v44ZM796-76 500-371 267-138q-8 8-19.5 13t-23.5 5h-73q-13 0-21.5-8.5T121-150v-73q0-12 5-23.5t13-19.5l233-233L76-796q-9-9-9-21t9-21q9-9 21-9t21 9l720 720q9 9 9 21t-9 21q-9 9-21 9t-21-9Zm-17-661-41-41 41 41Zm-127 42 44 44-44-44ZM436-435l-22-22 44 44-22-22Z"
		/>
	</svg>
);
