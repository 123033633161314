import { ReactNode } from 'react';

export const symbol_tv_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M880-780v495q0 15-9.318 22.5t-20.5 7.5Q839-255 829.5-262.683 820-270.366 820-285v-495H304q-15 0-22.5-9.318t-7.5-20.5q0-11.182 7.5-20.682Q289-840 304-840h516q24 0 42 18t18 42Zm-722-60 60 60h-78v520h512L50-862q-9-9-8.5-21.5T51-905q9-9 21-9t21 9l765 764q9 9 9 21t-9 21q-9 9-21.5 9T815-99L713-200h-83v50q0 12.75-8.625 21.375T600-120H360q-12.75 0-21.375-8.625T330-150v-50H140q-24 0-42-18t-18-42v-520q0-24 18.5-42t41.5-18h18Zm263 350Zm143-28Z"
		/>
	</svg>
);
