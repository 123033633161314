import { ReactNode } from 'react';

export const symbol_price_change: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm0-60h680v-520H140v520Zm0 0v-520 520Zm267-159H267q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T267-319h57v15q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T384-304v-15h48q16 0 25.5-12t9.5-27v-114.745q0-16.255-9.5-27.755T432-512H297v-69h140q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T437-641h-53v-15q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T324-656v15h-48q-16 0-27.5 12T237-600.883v113.766Q237-471 248.5-461.5T276-452h131v73Zm248.25 64.75 42-42q3.75-3.75 1.88-8.25t-7.107-4.5h-83.786Q603-369 601-364.5t1.75 8.25l42 42Q647-312 650-312t5.25-2.25ZM607.977-581h83.786q5.237 0 7.237-4.5t-1.75-8.25l-42-42Q653-638 650-638t-5.25 2.25l-42 42q-3.75 3.75-1.88 8.25t7.107 4.5Z"
		/>
	</svg>
);
