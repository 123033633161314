import { ReactNode } from 'react';

export const symbol_do_not_touch: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M780-278v-492q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T840-770v492q0 15-9.318 22.5t-20.5 7.5Q799-248 789.5-255.683 780-263.366 780-278ZM280-770v-60q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T340-830v60q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T280-770Zm167 159v-279q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T507-890v280q0 12.641-9 20.821Q489-581 477-581t-21-9q-9-9-9-21Zm167 121v-360q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T674-850v360q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T614-490Zm137 367L340-534v192q0 19-16.5 27t-31.5-3l-126-92 195 288q7 11 17.5 16.5T402-100h288q16.897 0 32.948-6Q739-112 751-123ZM402-40q-27 0-51.5-12.5T311-88L68-446q-6-9-4-20t10-18q17-15 39.5-19t44.573 13.188L280-397v-197L47-827q-9-9.067-9-21.533Q38-861 47.053-870q9.052-9 21.5-9Q81-879 90-870l765 765q9 9 9 21t-9.053 21q-9.052 9-21.5 9Q821-54 812-63l-19-18q-20 19-46.56 30Q719.88-40 690-40H402Zm144-289Zm20-151Z"
		/>
	</svg>
);
