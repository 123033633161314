import { ReactNode } from 'react';

export const symbol_data_saver_on: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M481-80q-83 0-156-31.5t-127.5-86q-54.5-54.5-86-126.862Q80-396.725 80-479q0-136.923 84.5-246.462Q249-835 382-868q26-6 45.5 8.5t19.5 40.541Q447-801 437-787.5T410-771q-97 18-162.5 101.5T182-479q0 123 87.03 210 87.031 87 211.97 87 60.027 0 116.013-24Q653-230 699-276q12-12 29-14t30.026 8Q778-267 780-243t-15 44q-51 57-124.5 88T481-80Zm298-400q0-104-63-185T554-769q-17-4-27.5-18.085-10.5-14.086-10.5-31.159Q516-843 536-858t46-9q131 32 215 140.32Q881-618.359 881-484q0 28-3.5 55.5T866-374q-7 24-28 34t-44-1q-17-8-24.5-23.5T767-394q6-17 9-37.5t3-48.5Zm-328 28h-91q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T360-512h91v-91q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T511-603v91h90q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T601-452h-90v91q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T451-361v-91Z"
		/>
	</svg>
);
