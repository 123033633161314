import { Map } from 'immutable';
import { t } from '@transifex/native';

import Tag from 'pkg/models/tag';

import { fromEntries } from 'pkg/utils';

const aliased = [
	'1v1',
	'attacking_play',
	'attacking',
	'ball_lost',
	'ball_won',
	'chance_against',
	'chance_to_score',
	'corner',
	'defending',
	'defensive_play',
	'finish',
	'finishing',
	'free_kick',
	'goal_conceded',
	'goal_kick',
	'goal',
	'goalkeeping',
	'passing',
	'penalty',
	'physical',
	'possession',
	'pressing',
	'red_card',
	'rondos',
	'set_pieces',
	'small_sided_games',
	'tactical',
	'technical',
	'warm-up',
	'yellow_card',
];

export const isAliased = (tag: string) => aliased.includes(tag);

export const getAlias = (tag: string) => {
	if (!isAliased(tag)) {
		return tag;
	}

	switch (tag) {
		case '1v1':
			return t('1v1', { _context: 'default tags' });
		case 'attacking_play':
			return t('Attacking', { _context: 'default tags' });
		case 'attacking':
			return t('Attacking play', { _context: 'default tags' });
		case 'ball_lost':
			return t('Ball lost', { _context: 'default tags' });
		case 'ball_won':
			return t('Ball won', { _context: 'default tags' });
		case 'chance_against':
			return t('Chance against', { _context: 'default tags' });
		case 'chance_to_score':
			return t('Change to score', { _context: 'default tags' });
		case 'corner':
			return t('Corner', { _context: 'default tags' });
		case 'defending':
			return t('Defending', { _context: 'default tags' });
		case 'defensive_play':
			return t('Defensive play', { _context: 'default tags' });
		case 'finish':
			return t('Finish', { _context: 'default tags' });
		case 'finishing':
			return t('Finishing', { _context: 'default tags' });
		case 'free_kick':
			return t('Free kick', { _context: 'default tags' });
		case 'goal_conceded':
			return t('Goal', { _context: 'default tags' });
		case 'goal_kick':
			return t('Goal conceded', { _context: 'default tags' });
		case 'goal':
			return t('Goal kick', { _context: 'default tags' });
		case 'goalkeeping':
			return t('Goalkeeping', { _context: 'default tags' });
		case 'passing':
			return t('Passing', { _context: 'default tags' });
		case 'penalty':
			return t('Penalty', { _context: 'default tags' });
		case 'physical':
			return t('Physical', { _context: 'default tags' });
		case 'possession':
			return t('Possession', { _context: 'default tags' });
		case 'pressing':
			return t('Pressing', { _context: 'default tags' });
		case 'red_card':
			return t('Red card', { _context: 'default tags' });
		case 'rondos':
			return t('Rondos', { _context: 'default tags' });
		case 'set_pieces':
			return t('Set pieces', { _context: 'default tags' });
		case 'small_sided_games':
			return t('Small sided games', { _context: 'default tags' });
		case 'tactical':
			return t('Tactical', { _context: 'default tags' });
		case 'technical':
			return t('Technical', { _context: 'default tags' });
		case 'warm-up':
			return t('Warmup', { _context: 'default tags' });
		case 'yellow_card':
			return t('Yellow card', { _context: 'default tags' });
	}
};

export const getName = (tag = '') => {
	if (isAliased(tag)) {
		return getAlias(tag);
	}

	return tag.replace(/\_/g, ' ');
};

export const defaultTags = () =>
	Map<string, string>(
		fromEntries(aliased.map((tag) => [tag, new Tag({ name: getName(tag) })]))
	);
