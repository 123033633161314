import { ReactNode } from 'react';

export const symbol_local_pizza: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-840q97 0 187 32t167 89q12 9 18.5 21.952 6.5 12.953 6.5 26.667 0 8.381-2.5 16.837T849-637L530-156q-9 13.5-22.5 20.25T479.878-129q-14.121 0-27.5-6.5Q439-142 430-156L111-637q-5.385-8.25-7.692-16.5Q101-661.75 101-670q0-13.846 6.5-26.923Q114-710 126-719q77-57 167-89t187-32Zm0 60q-92 0-168 31.5T159-671l321 484 321-484q-77-46-153.045-77.5Q571.91-780 480-780ZM370.059-565Q393-565 409-581.059q16-16.059 16-39T408.941-659q-16.059-16-39-16T331-658.941q-16 16.059-16 39T331.059-581q16.059 16 39 16Zm110 215Q503-350 519-366.059q16-16.059 16-39T518.941-444q-16.059-16-39-16T441-443.941q-16 16.059-16 39T441.059-366q16.059 16 39 16ZM480-187Z"
		/>
	</svg>
);
