import { ReactNode } from 'react';

export const symbol_forest: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M303-110v-119H57q-18 0-27-16t2-31l157-227h-37q-18 0-27-16t2-31l208-295q9-13 25-13t25 13l95 135 95-135q9-13 25-13t25 13l208 295q11 15 2 31t-27 16h-36l156 227q11 15 2 31t-27 16H658v119q0 13-8.5 21.5T628-80h-55q-13 0-21.5-8.5T543-110v-119H418v119q0 13-8.5 21.5T388-80h-55q-13 0-21.5-8.5T303-110Zm377-179h165L656-563h89L600-769l-80 115 73 104q11 15 2 31t-27 16h-36l148 214Zm-564 0h489L416-563h89L360-769 215-563h90L116-289Zm0 0h189-90 290-89 189-489Zm564 0H532h94-106 225-89 189-165Zm-137 60h115-115Zm178 0Z"
		/>
	</svg>
);
