import { ReactNode } from 'react';

export const symbol_request_page: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M450-280v25q0 6 4.5 10.5T465-240h30q6 0 10.5-4.5T510-255v-25h60q12.75 0 21.375-8.625T600-310v-130q0-12.75-8.625-21.375T570-470H420v-70h150q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T570-600h-60v-25q0-6-4.5-10.5T495-640h-30q-6 0-10.5 4.5T450-625v25h-60q-12.75 0-21.375 8.625T360-570v130q0 12.75 8.625 21.375T390-410h150v70H390q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T390-280h60ZM220-80q-24 0-42-18t-18-42v-680q0-24 18-42t42-18h316q12.444 0 23.722 5T579-862l203 203q8 8 13 19.278 5 11.278 5 23.722v476q0 24-18 42t-42 18H220Zm0-60h520v-474L534-820H220v680Zm0 0v-680 680Z"
		/>
	</svg>
);
