import { ReactNode } from 'react';

export const symbol_nest_cam_floodlight: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M630-587 520-697q-34-34-78-53.5T350-770q-67 0-122.5 34.5T140-646q31-55 87-89.5T350-770q48 0 92 19.5t78 53.5l110 110ZM0-260v-700h70.088Q99-960 119.5-939.438 140-918.875 140-890v87q29-57 84-92t123-35h223q24.75 0 42.375 17.625T630-870v283l43 43q17 16.934 17 41.967Q690-477 673-460L418-205q-16.934 17-41.967 17Q351-188 334-205L181-357q-12-12-22.5-25.5T140-411v81q0 28.875-20.562 49.438Q98.875-260 70-260H0Zm347-610q-73 0-123.5 52.5T173-691q33-36 78.5-57.5T350-770q48 0 92 19.5t78 53.5l50 50v-223H347ZM170-527.245Q170-491 184-458.5q14 32.5 40 58.5l152 152 254-254-154-154q-26-26-58.5-40T350-710q-76 0-128 54t-52 128.755ZM800-330H690v-60h110v60ZM695-143l-77-77 42-42 77 77-42 42ZM490-80v-110h60v110h-60Zm-90-399Z"
		/>
	</svg>
);
