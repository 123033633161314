export default {
	name: 'add-post',
	objects: [
		{
			type: 'path',
			attributes: {
				d: 'M20.115 12.056V16.7634C20.115 17.15 19.8016 17.4634 19.415 17.4634L4.58497 17.4634C4.19838 17.4634 3.88497 17.15 3.88497 16.7634L3.88498 7.34339C3.88498 6.95679 4.19838 6.64339 4.58498 6.64339L13.5383 6.64339L14.8619 5.34339H4.58498C3.48041 5.34339 2.58498 6.23882 2.58498 7.34339V16.7634C2.58498 17.868 3.48041 18.7634 4.58497 18.7634L19.415 18.7634C20.5195 18.7634 21.415 17.868 21.415 16.7634V10.7793L20.115 12.056Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M19.2954 3.68898C20.1893 2.823 21.6159 2.84561 22.4819 3.73948C23.3479 4.63335 23.3253 6.05999 22.4314 6.92597L14.5729 14.5392C14.2232 14.878 13.7869 15.1139 13.3119 15.221L10.8566 15.7746C10.6355 15.8245 10.4044 15.7556 10.2467 15.5928C10.0891 15.4301 10.0275 15.1969 10.0844 14.9776L10.7155 12.541C10.8376 12.0696 11.0872 11.641 11.437 11.3022L19.2954 3.68898ZM21.5482 4.64404C21.1818 4.26583 20.5782 4.25626 20.2 4.62267L19.7823 5.02729L21.1092 6.3969L21.5268 5.99228C21.905 5.62588 21.9146 5.02225 21.5482 4.64404ZM20.1755 7.30146L18.8486 5.93184L13.1362 11.466L14.463 12.8356L20.1755 7.30146ZM13.5197 13.7302L12.2122 12.3806C12.1012 12.5245 12.0199 12.6898 11.974 12.867L11.61 14.2721L13.026 13.9529C13.2045 13.9126 13.3724 13.8366 13.5197 13.7302Z',
			},
		},
	],
};
