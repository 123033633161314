import { ReactNode } from 'react';

export const symbol_altitude: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M759.825-490Q747-490 738.5-498.625T730-520v-151l-54 52q-8.186 8.25-20.093 8.625t-20.777-8.678q-8.13-8.298-8.13-21.122Q627-653 635-661l104-104q9-9 21-9t21 9l104 104q9 9 9 21t-9.053 21q-9.052 9-21.5 9Q851-610 842-619l-52-52v151q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM100-80q-18.617 0-26.808-16.5Q65-113 76-128l180-240q4.5-6 10.875-9T280-380q6.75 0 13.125 3T304-368l171 228h325L560-459 459-325q-9 12.235-21 12.618-12 .382-21-6.414-9-6.796-12-18.5T411-361l125-167q4.5-6 10.875-9T560-540q6.75 0 13.125 3T584-528l300 400q11 15 2.808 31.5Q878.617-80 860-80H100Zm375-60Z"
		/>
	</svg>
);
