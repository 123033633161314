import { ReactNode } from 'react';

export const symbol_folder_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M880-680v429q0 15-9.318 22.5t-20.5 7.5Q839-221 829.5-228.683 820-236.366 820-251v-429H451q-12.444 0-23.722-5t-19.493-13.215L357-749q-11-11-9.5-22.5T357-791q8-8 20.07-9.5t22.632 9.715L451-740h369q23 0 41.5 18.5T880-680ZM140-160q-24 0-42-18.5T80-220v-520q0-23 18-41.5t42-18.5h34l60 60h-94v520h530L77-813q-9-9-9-21t9-21q9-9 21-9t21 9L883-91q9 9 9 21t-9 21.391Q874-40 862-40t-21-9L730-160H140Zm270-320Zm160-10Z"
		/>
	</svg>
);
