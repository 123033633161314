// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ylPKtX0pjEdx1tlbhwrg {
	padding: var(--spacing-8) var(--spacing-7);
}
@media (max-width: 768px) {
	.ylPKtX0pjEdx1tlbhwrg {
		padding: var(--spacing-7) var(--spacing-5);
}
	}
`, "",{"version":3,"sources":["webpack://./components/event/preview/styles.css"],"names":[],"mappings":"AAAA;CACC,0CAA0C;AAK3C;AAHC;CAHD;EAIE,0CAA0C;AAE5C;CADC","sourcesContent":[".previewCard {\n\tpadding: var(--spacing-8) var(--spacing-7);\n\n\t@media (--small-viewport) {\n\t\tpadding: var(--spacing-7) var(--spacing-5);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var previewCard = `ylPKtX0pjEdx1tlbhwrg`;
export default ___CSS_LOADER_EXPORT___;
