import { Fragment, useState, useContext } from 'react';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import { cssClasses } from 'pkg/css/utils';

import CreateScheduleItem from 'routes/scheduling/templates/modals/CreateScheduleItem';
import TemplateScheduleItem from 'routes/scheduling/templates/schedule_items/template_item';
import { SlotItem } from 'routes/scheduling/templates/schedule_items/slot_item';
import {
	getAvailableSlots,
	getTotalHeightOfDay,
	ScheduleGridItem,
} from 'routes/scheduling/utils';
import { FilterState } from 'routes/scheduling/templates/filters';
import GroupColumn from 'routes/scheduling/templates/single/groups/GroupColumn';
import { TemplateContext } from 'routes/scheduling/templates/single';

import Column from 'components/layout/column';
import GridRow from 'components/scheduling/grid/row';
import * as dayStyles from 'components/scheduling/grid/day-item/styles.css';
import NewBooking from 'components/scheduling/grid/new-booking';

interface DayProps {
	dayNumber: number;
	groupId: number;
	filters: FilterState;

	scheduleItems: models.scheduleItem.ScheduleItem[];
	isInView: boolean;
}

const Day = ({
	dayNumber,
	groupId,
	scheduleItems,
	filters,
	isInView,
}: DayProps) => {
	const templateContext = useContext(TemplateContext);
	const [modal, setModal] = useState('');

	const showBookingModal = () => setModal('create');
	const hideModal = () => setModal('');

	const slots = models.schedule.parseSlots(templateContext.schedule);

	const availableSlots = getAvailableSlots(slots, scheduleItems, dayNumber);

	if (!isInView) {
		const totalHeight = getTotalHeightOfDay(
			scheduleItems as ScheduleGridItem[],
			templateContext.conflicts,
			templateContext.isSmallScreen,
			templateContext.view,
			availableSlots
		);

		return <div style={{ height: `${totalHeight}px` }} />;
	}

	return (
		<Fragment>
			<div className={cssClasses(dayStyles.itemWrapper)}>
				<Column spacing={styles.spacing._2}>
					{templateContext.showSlots &&
						availableSlots.map((slot, index) => (
							<SlotItem
								key={index}
								slot={slot}
								dayNumber={dayNumber}
								groupId={groupId}
								slots={slots}
							/>
						))}
					{scheduleItems.length > 0 &&
						scheduleItems.map((item, index) => (
							<TemplateScheduleItem
								key={index}
								scheduleItem={item}
								schedule={templateContext.schedule}
								filters={filters}
							/>
						))}
				</Column>

				<NewBooking onClick={showBookingModal} />
			</div>
			{modal === 'create' && (
				<CreateScheduleItem
					groups={templateContext.groups}
					resources={templateContext.resources}
					schedule={templateContext.schedule}
					day={dayNumber}
					groupId={groupId}
					onBeforeSave={templateContext.onBeforeScheduleItemSave}
					onSuccess={templateContext.onSuccessScheduleItemSave}
					onFail={templateContext.onFailScheduleItemSave}
					hideModal={hideModal}
					scheduleItems={templateContext.scheduleItems}
				/>
			)}
		</Fragment>
	);
};

interface GroupRowProps {
	filters: FilterState;

	group: models.group.Group;
	items: models.scheduleItem.ScheduleItem[];
}

const GroupRow = ({ group, items, filters }: GroupRowProps) => {
	const [isInView, setIsInView] = useState(false);
	const templateContext = useContext(TemplateContext);

	const days = [];
	for (let i = 1; i <= templateContext.schedule.days; i++) {
		days.push(
			<div className={dayStyles.gridItem} key={i}>
				<Day
					dayNumber={i}
					groupId={group.id}
					scheduleItems={items.filter(
						(item) => item.day === i && item.groupId === group.id
					)}
					filters={filters}
					isInView={isInView}
				/>
			</div>
		);
	}

	return (
		<GridRow setIsInView={setIsInView} cols={7}>
			{isInView && <GroupColumn group={group} />}
			{days}
		</GridRow>
	);
};

export default GroupRow;
