import styled, { css } from 'styled-components';

import * as styles from 'pkg/config/styles';

import Badge from 'components/Badge';

export const Base = styled.div<{ clickable?: boolean }>`
	display: grid;
	grid-template-areas: 'group actions';
	grid-template-columns: 1fr auto;
	align-items: center;
	background-color: var(--palette-white);
	border-radius: var(--radius-5);
	transition: box-shadow 150ms ease-in-out;
	border: 1px solid var(--palette-gray-300);

	${({ clickable }) =>
		clickable &&
		css`
			cursor: pointer;

			&:hover {
				box-shadow: ${styles.boxShadow.cardHover};
			}
		`}
`;

export const Group = styled.div`
	grid-area: group;
	display: grid;
	grid-template-areas: 'avatar text';
	grid-template-columns: 40px 1fr;
	align-items: center;
	gap: var(--spacing-4);
	padding: var(--spacing-4);

	${Badge} {
		grid-area: avatar;
	}

	div {
		grid-area: text;
		word-break: break-all;

		strong {
			display: block;
			font-weight: var(--font-weight-semibold);
		}

		span {
			display: block;
			font-size: var(--font-size-sm);
			color: var(--palette-gray-500);
		}
	}
`;

export const Actions = styled.div`
	grid-area: actions;
	display: grid;
	grid-auto-flow: column;
	align-items: center;
	gap: var(--spacing-3);
	padding: var(--spacing-4);
`;
