import { ReactNode } from 'react';

export const symbol_conditions: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M400.08-734q-30.08 0-51.58-21.42-21.5-21.421-21.5-51.5 0-30.08 21.42-51.58 21.421-21.5 51.5-21.5 30.08 0 51.58 21.42 21.5 21.421 21.5 51.5 0 30.08-21.42 51.58-21.421 21.5-51.5 21.5Zm259.779 504Q706-230 738-261.859q32-31.859 32-78T738.141-418q-31.859-32-78-32T582-418.141q-32 31.859-32 78T581.859-262q31.859 32 78 32ZM757-201q-22 15-46.324 23-24.325 8-50.676 8-70.833 0-120.417-49.618Q490-269.235 490-340.118 490-411 539.618-460.5q49.617-49.5 120.5-49.5Q731-510 780.5-460.417 830-410.833 830-340q0 26.351-8 50.676Q814-265 799-243l86 86q9 9 9 21t-9 21q-9 9-21 9t-21-9l-86-86Zm-310-52q10 25 25 46.5t35 38.5v58q0 12.75-8.675 21.375Q489.649-80 476.825-80 464-80 455.5-88.625T447-110v-143Zm-94-87v230q0 12.75-8.675 21.375Q335.649-80 322.825-80 310-80 301.5-88.625T293-110v-503q-56-5-111-11.5T72-643q-13-3-20-13.5T48-680q3-13 15-19t26-3q77 16 154.826 22.5T400-673q78.348 0 156.174-6.5Q634-686 711-702q14-3 26.5 3t15.5 19q3 13-5.566 24.029Q738.868-644.941 725-642q-54 11-108.5 17.5T507-613v101q-35 32-56 76.5T430-340h-77Z"
		/>
	</svg>
);
