import { ReactNode } from 'react';

export const symbol_landscape_2: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M191-449q7.872-15 22.436-24t31.484-9q17.655 0 32.367 10Q292-462 300-445l37 88q6 13 21.312 11.929Q373.625-346.143 378-359l89-298q14-45 52-73t85.475-28Q651-758 688.5-731q37.5 27 52.5 72l160 459q5 14-3.5 27t-24.793 13q-9.31 0-17.069-5.556Q847.879-171.111 844-180L684-640q-9-26-31-42t-49-16q-27 0-49 16t-30 42l-89 298q-8 26-30 42t-49.368 16Q332-284 311.5-297.5 291-311 282-334l-39-92-123 249q-3.644 7.727-10.932 12.364Q101.78-160 93.763-160 77-160 68-174.5T67-203l124-246Zm41.5-125q-46.5 0-79.5-33t-33-80q0-46 32.958-79.5Q185.917-800 233-800q46 0 79.5 33.5T346-687q0 47-33.5 80t-80 33Zm.5-60q22 0 37.5-15.132 15.5-15.131 15.5-37.5Q286-709 270.5-724.5 255-740 233-740t-37.5 15.333q-15.5 15.334-15.5 38Q180-664 195.5-649t37.5 15Zm124 350ZM233-687Z"
		/>
	</svg>
);
