import { ReactNode } from 'react';

export const symbol_text_select_move_down: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M160-780q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T160-840h640q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T800-780H160Zm299 465L355-419q-9-9-9-21t9-21q9-9 21-8.5t21 8.5l53 52v-231q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-640v231l53-52q9-8 21-8.5t21 8.5q9 9 9 21t-9 21L501-315q-9 9-21 9t-21-9ZM160-120q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T160-180h640q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T800-120H160Z"
		/>
	</svg>
);
