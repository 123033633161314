import { t } from '@transifex/native';
import styled from 'styled-components';
import { Fragment, useMemo } from 'react';

import * as styles from 'pkg/config/styles';

import {
	createQueryFilter,
	FilterOperator,
	QueryFilter,
	QueryFilterObject,
} from 'pkg/filters';
import { useQueryState } from 'pkg/hooks/query-state';
import { DateSelect, OperatorKeys } from 'pkg/filters/types/Date';
import { Checkbox } from 'pkg/filters/types/Checkbox';
import { Radio } from 'pkg/filters/types/Radio';
import { Text } from 'pkg/filters/types/Text';
import { NumberFilter } from 'pkg/filters/types/Number';
import { Apply } from 'pkg/filters/controls/Apply';
import { Reset } from 'pkg/filters/controls/Reset';
import { ModalFilter } from 'pkg/filters/types/Modal';
import { UrlParser } from 'pkg/url';
import { useFakeQueryState } from 'pkg/hooks/fake-query-state';
import { GroupRoleFilter } from 'pkg/filters/types/GroupRole';
import * as arrays from 'pkg/arrays';

import { useSmallScreen } from 'components/MediaQuery';

import FilterGroup from 'components/filters/FilterGroup';
import Row from 'components/layout/row';

import * as Context from 'design/context_menu';
import Button, { FilterButton } from 'design/button';

export interface Filters {
	/**
	 * URL Search Parameters with the filters. Build on this object for your request after using the hook.
	 */
	queryParam: URLSearchParams;
	/**
	 * Sets the value of a filter, used when applying filters from outside the provided component of the hook.
	 */
	setFilter: FilterSetter;
	/**
	 * Clear group removes a group of filters from the state.
	 */
	clearGroup: (g: FilterGroup) => void;
	/**
	 * Reset removes all filters.
	 */
	reset: () => void;
	/**
	 * Current filters in the state, not necessarily applied yet (not in the URL). Can be used to preview filter changes.
	 */
	currentFilters: QueryFilter[];
	/**
	 * Filter groups that currently have at least one filter active.
	 */
	activeGroups: FilterGroups;
	/**
	 * Component for the filters, this is a context menu with a trigger, including presentation for all the filters.
	 */
	Component: JSX.Element;
}

const changeableOperatorFilters = ['date', 'number'];

export type FilterValue = string | number | boolean;

export type FilterType =
	| 'date'
	| 'checkbox'
	| 'radio'
	| 'number'
	| 'text'
	| 'modal'
	| 'groupRole';

export interface ModalFilterSettings {
	// Which modal should be opened.
	modal: JSX.Element;
	// Label of the button that opens the modal
	buttonLabel: string;
	// If the modal button should be hidden.
	hideButton?: boolean;
}
export interface NumberFilterSettings {
	// If the filter needs to account for decimal places
	// in money handling and displaying
	money?: boolean;
}

export interface CheckboxFilterSettings {
	transformValuesToArray?: boolean;
}

export interface DateFilterSettings {
	// Will only list the passed operators
	dateFilterOperators?: OperatorKeys[];
}

export interface Filter {
	id: string;
	type: FilterType;
	operator?: FilterOperator;
	property: string;
	values?: { [key: string]: FilterValue };

	// Component specific settings for various filters
	settings?:
		| ModalFilterSettings
		| CheckboxFilterSettings
		| DateFilterSettings
		| NumberFilterSettings;
}

interface FilterGroup {
	filters: { [key: string]: Filter };
	hidden?: boolean;
}

export interface FilterGroups {
	[key: string]: FilterGroup;
}

export interface FilterConfig {
	groups: FilterGroups;

	// remove label and increase size for mobile devices to match with filter bars.
	filterBarMobileTrigger?: boolean;

	// bigger filter button (looks better when used in modals)
	largeFilterButton?: boolean;
	hideFiltersInUrl?: boolean;
}

export const FilterRow = styled.div`
	padding: var(--spacing-2) 0;
`;

export type FilterSetter = (
	// The filter object to set in the query state
	filter: Filter,
	// An array of any size of values to store in the filter
	values: FilterValue[],
	// Set to true to append to current values, false to ovewrite entirely
	append?: boolean,
	// Set to true to commit filter directly
	commit?: boolean
) => void;

export type FilterReplacer = (
	// Existing filter in state, if it does not exist, next filter is appended either way
	a: Filter,
	// Add this in the place of the existing one
	b: Filter,
	// An array of any size of values to store in the filter
	values: FilterValue[],
	// Set to true to commit filter directly
	commit?: boolean
) => void;

export function useFilters(config: FilterConfig): Filters {
	const { hideFiltersInUrl } = config;

	const queryState = useQueryState();
	const fakeQueryState = useFakeQueryState();

	const qs = hideFiltersInUrl ? fakeQueryState : queryState;

	const isSmallScreen = useSmallScreen();

	let currentFilters = useMemo(() => {
		return JSON.parse(qs.get('filters', '[]', false) as string);
	}, [qs.get('filters', '[]', false)]) as QueryFilter[];

	if (!Array.isArray(currentFilters)) {
		currentFilters = [];
	}

	// Filters than have been activated, but not necessarily applied yet.
	const getCurrentFilter = (): QueryFilter[] => {
		let currentFilters: QueryFilter[];

		currentFilters = JSON.parse(
			qs.get('filters', '[]', false) as string
		) as QueryFilter[];

		if (!Array.isArray(currentFilters)) {
			currentFilters = [];
		}

		return currentFilters;
	};

	// Filters that have been applied, and not just activated.
	const getAppliedFilters = (): QueryFilter[] => {
		let appliedFilters = JSON.parse(
			qs.get('filters', '[]', true) as string
		) as QueryFilter[];

		if (!Array.isArray(appliedFilters)) {
			appliedFilters = [];
		}

		return appliedFilters;
	};

	const setFilter: FilterSetter = (
		filter: Filter,
		values: FilterValue[],
		append: boolean = false,
		commit: boolean = false
	) => {
		const qf = [...getCurrentFilter()];
		const existing = qf.findIndex((q) => q.id === filter.id);

		const f: QueryFilter = qf[existing] || {
			id: filter.id,
			property: filter.property,
			operator: filter.operator,
			values: [],
		};

		if (append) {
			for (const value of values) {
				// remove from filter
				if (f.values.includes(value)) {
					f.values = f.values.filter((v) => v !== value);
				} else {
					f.values.push(value);
				}
			}
		} else {
			f.values = values;
		}

		if (existing !== -1) {
			if (
				qf[existing].operator !== filter.operator &&
				changeableOperatorFilters.includes(filter.type)
			) {
				qf[existing] = { ...f, operator: filter.operator };
			} else {
				qf[existing] = f;
			}
		} else {
			qf.push(f);
		}

		// all values in the filter is empty, remove
		if (f.values.every((v) => v.toString().length === 0) && existing !== -1) {
			qf.splice(existing, 1);
		}

		if (qf.length === 0) {
			qs.remove('filters');
		} else {
			qs.set('filters', JSON.stringify(qf));
		}

		if (commit) {
			qs.commit();
		}
	};

	const replaceFilter: FilterReplacer = (
		a: Filter,
		b: Filter,
		values: FilterValue[],
		commit: boolean = false
	) => {
		const qf = [...getCurrentFilter()];

		const existingFilterIndex = qf.findIndex((q) => q.id === a.id);
		const nextFilterIndex = qf.findIndex((q) => q.id === b.id);

		const next: QueryFilter = {
			id: b.id,
			property: b.property,
			operator: b.operator,
			values,
		};

		if (existingFilterIndex !== -1) {
			qf.splice(existingFilterIndex, 1, next);
		} else if (nextFilterIndex !== -1) {
			qf.splice(nextFilterIndex, 1, next);
		} else {
			qf.push(next);
		}

		if (qf.length === 0) {
			qs.remove('filters');
		} else {
			qs.set('filters', JSON.stringify(qf));
		}

		if (commit) {
			qs.commit();
		}
	};

	const onApply = () => {
		qs.commit();
	};

	const onClear = () => {
		qs.remove('filters');
		qs.commit();
	};

	const getActiveCurrentFilterIds = (fg: FilterGroup) => {
		const currentFilterIds = currentFilters.map((qf) => qf.id);
		const currentFilterGroupIds = Object.values(fg.filters).map((f) => f.id);

		return arrays.intersects(currentFilterIds, currentFilterGroupIds);
	};

	const activeGroups = (): FilterGroups => {
		const groups: FilterGroups = {};

		Object.entries(config.groups).forEach(([label, fg]) => {
			const active = getActiveCurrentFilterIds(fg);

			if (active.length > 0) {
				groups[label] = fg;
			}
		});

		return groups;
	};

	const groupHasActiveFilter = (fg: FilterGroup): boolean => {
		return getActiveCurrentFilterIds(fg).length > 0;
	};

	const clearGroup = (fg: FilterGroup): void => {
		const filtersInGroup = Object.values(fg.filters);
		const filterGroupIds = filtersInGroup.map((f) => f.id);

		const filteredGroups = currentFilters.filter((qf: QueryFilter) => {
			return !filterGroupIds.includes(qf.id);
		});

		qs.set('filters', JSON.stringify(filteredGroups));
		qs.commit();
	};

	const getFiltersForProperty = (p: string): QueryFilter[] => {
		return currentFilters.filter((cf: QueryFilter) => cf.property === p);
	};

	const amountOfActiveFilters = getAppliedFilters().length;

	let filterTrigger = (
		<Button
			active={amountOfActiveFilters > 0}
			icon="tune"
			large={config.largeFilterButton}
			label={
				amountOfActiveFilters > 0
					? t('Filters ({amount})', {
							amount: amountOfActiveFilters,
						})
					: t('Filters')
			}
		/>
	);

	if (isSmallScreen && config.filterBarMobileTrigger) {
		filterTrigger = <FilterButton active={amountOfActiveFilters > 0} />;
	}

	const visibleGroups = Object.entries(config.groups).filter(
		([, g]) => !g.hidden
	);

	let filterComponent = (
		<Context.Menu toggleWith={filterTrigger}>
			<Context.Heading>
				{t('Filters')}
				<Row spacing={styles.spacing._3}>
					{currentFilters.length > 0 && <Reset onReset={onClear} />}
					<Apply onApply={onApply} disable={currentFilters.length === 0} />
				</Row>
			</Context.Heading>

			{visibleGroups.map(([groupLabel, filterGroup]) => {
				return (
					<FilterGroup
						key={groupLabel}
						label={groupLabel}
						isActive={groupHasActiveFilter(filterGroup)}
						onClearFilter={() => {
							clearGroup(filterGroup);
						}}>
						{Object.entries(filterGroup.filters).map(
							([filterLabel, filter]) => {
								switch (filter.type) {
									case 'date':
										return (
											<DateSelect
												key={filterLabel}
												filter={filter}
												setFilter={setFilter}
												currentFilters={getFiltersForProperty(filter.property)}
											/>
										);
									case 'checkbox':
										return (
											<Checkbox
												key={filterLabel}
												filter={filter}
												setFilter={setFilter}
												currentFilters={getFiltersForProperty(filter.property)}
											/>
										);
									case 'radio':
										return (
											<Radio
												key={filterLabel}
												filter={filter}
												setFilter={setFilter}
												currentFilters={getFiltersForProperty(filter.property)}
											/>
										);
									case 'number':
										return (
											<NumberFilter
												key={filterLabel}
												filter={filter}
												setFilter={setFilter}
												currentFilters={getFiltersForProperty(filter.property)}
											/>
										);
									case 'text':
										return (
											<Text
												key={filterLabel}
												filter={filter}
												setFilter={setFilter}
												currentFilters={getFiltersForProperty(filter.property)}
											/>
										);
									case 'modal':
										return (
											<ModalFilter
												key={filterLabel}
												filter={filter}
												setFilter={setFilter}
												currentFilters={getFiltersForProperty(filter.property)}
												filterLabel={filterLabel}
											/>
										);
									case 'groupRole':
										return (
											<Fragment>
												<GroupRoleFilter
													key={filterLabel}
													filter={filter}
													setFilter={setFilter}
													replaceFilter={replaceFilter}
													appliedFilters={getAppliedFilters()}
													currentFilters={currentFilters}
												/>
											</Fragment>
										);
								}
							}
						)}
					</FilterGroup>
				);
			})}
		</Context.Menu>
	);

	if (visibleGroups.length === 0) {
		filterComponent = null;
	}

	return {
		queryParam: new URLSearchParams(
			qs.has('filters')
				? {
						filters: qs.get('filters') as string,
					}
				: {}
		),
		currentFilters,
		activeGroups: activeGroups(),
		setFilter: setFilter,
		clearGroup: clearGroup,
		reset: onClear,
		Component: filterComponent,
	};
}

export function toFilterQuery(qf: QueryFilterObject): string {
	const query = [];

	for (const [property, { operator, values, type, id }] of Object.entries(qf)) {
		query.push(
			createQueryFilter({
				id,
				property,
				type,
				operator,
				values,
			})
		);
	}

	return JSON.stringify(query);
}

export function toFilterQueryUrl(url: string, qf: QueryFilterObject): string {
	const parser = new UrlParser();

	return parser.transform(url, { filters: toFilterQuery(qf) });
}
