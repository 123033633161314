import { ReactNode } from 'react';

export const symbol_glyphs: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M144-528q-15 0-23-12.5t-3-26.5l106-282q3-9 11-14t17-5h22q9 0 17 5t11 14l107 283q5 14-3.5 26T382-528q-9 0-16.5-5.5T355-547l-20-57H191l-20 57q-3 8-10.5 13.5T144-528Zm55 451q-41 0-70-27.5T100-173q0-23 9-43.5t27-34.5l43-33-21-21q-14-14-22-32.5t-8-38.5q0-42 29.5-71t71.5-29q42 0 71 29t29 71q0 25-11 46.5T287-293l-18 14 44 44 50-49q9-9 21.5-9t21.5 9q9 9 9 21.5t-9 21.5l-49 49 35 36q9 9 8.5 21t-9.5 21q-9 9-21 9t-21-9l-35-35-35 35q-17 17-37 27t-43 10Zm22-165-43 33q-8 7-12.5 16t-4.5 20q0 15 11.5 25t26.5 10q11 0 19.5-5.5T235-157l36-35-50-50Zm7-174q-17 0-28.5 11.5T188-376q0 8 3.5 15.5T200-348l27 27 16-13q11-8 18.5-18.5T269-376q0-17-12-28.5T228-416Zm33-385-50 142h104l-50-142h-4Zm288 265q-11 4-21.5-2.5T514-557q-3-12 4-22t18-14q28-9 54-22.5t49-30.5q-26-29-48-61t-34-70h-47q-13 0-21.5-8.5T480-807q0-13 8.5-21.5T510-837h340q13 0 21.5 8.5T880-807q0 13-8.5 21.5T850-777h-45q-11 38-33.5 70.5T723-645q23 17 49 30t54 22q11 4 17.5 14t3.5 22q-3 12-13.5 18.5T812-536q-36-11-69-28.5T681-606q-29 24-62.5 41.5T549-536Zm-28 414q-9-9-9-21.5t9-21.5l269-269q9-9 21-9t21 9q9 9 9 21.5t-9 21.5L563-122q-9 9-21 9t-21-9Zm59-206q-21 0-35.5-14.5T530-378q0-21 14.5-35.5T580-428q21 0 35.5 14.5T630-378q0 21-14.5 35.5T580-328Zm42-449q8 27 23 50.5t37 41.5q21-18 35.5-42t22.5-50H622Zm158 649q-21 0-35.5-14.5T730-178q0-21 14.5-35.5T780-228q21 0 35.5 14.5T830-178q0 21-14.5 35.5T780-128ZM661-834q-11 3-21-1.5T627-851q-3-11 2-21t16-13l58-16q11-4 20.5.5T737-885q4 11-.5 20.5T721-851l-60 17Z"
		/>
	</svg>
);
