import { ReactNode } from 'react';

export const symbol_hdr_weak: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M189-331q-63 0-106.5-43.5T39-481q0-63 43.5-106.5T189-631q63 0 106.5 43.5T339-481q0 63-43.5 106.5T189-331Zm492 89q-99.583 0-169.292-69.708Q442-381.417 442-481t69.708-169.292Q581.417-720 681-720t169.292 69.708Q920-580.583 920-481t-69.708 169.292Q780.583-242 681-242Zm0-60q75 0 127-52t52-127q0-75-52-127t-127-52q-75 0-127 52t-52 127q0 75 52 127t127 52Z"
		/>
	</svg>
);
