import { ReactNode } from 'react';

export const symbol_captive_portal: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M710-207v73q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T650-134v-146q0-12.5 8.75-21.25T680-310h146q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T826-250h-74l107 107q9 9 9 21.158 0 12.158-8.609 21Q850-92 837.533-92q-12.466 0-21.533-9L710-207ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-126.362Q80-395.725 80-478q0-83.493 31.5-156.926Q143-708.359 197-762.68 251-817 324-848.5 397-880 480-880t156 31.5q73 31.5 127 85.82 54 54.321 85.5 127.754T880-478q0 14-1 28.5t-3 28.5q-2 14-12 22.5t-23.765 8.5Q828-390 820.5-399.5T815-421q2-14 3.5-28.5T820-478q0-22.75-2.945-45.5t-8.836-45.5H648q2 23 4 45.667 2 22.666 2 45.333 0 14-1 28.5t-2 28.5q-1 13-10.263 22t-22.386 9Q606-390 598-399q-8-9-7-21 2-14 2.5-29t.5-29q0-22.75-1.5-45.5T588-569H373.424Q370-546 368.5-523.5q-1.5 22.5-1.5 45t1.5 44.5q1.5 22 4.5 44h167q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T540-330H384q14 53 34 104t62 86q15.333 0 30.667-1.5Q526-143 541-145q11-2 20 6t9 19q0 14.143-8 23.571Q554-87 541-85q-15 2-30.5 3.5T480-80ZM151.781-390H312q-2.5-22-3.75-44T307-478q0-22.75 1-45.5t3-45.5H151.712q-5.856 22.8-8.784 45.6Q140-500.6 140-477.8t2.945 44.467q2.945 21.666 8.836 43.333ZM394-151q-26-41-43.5-86T323-330H172q33 67 91 114t131 65ZM172-629h149.591Q333-677 350.5-722.5T395-810q-71 24-129.5 69.5T172-629Zm212 0h193q-13-54-36-104t-61-89q-38 40-61 89.5T384-629Zm255.339 0H788q-35-66-93-112t-129-68q27 41 44.5 86.5t28.839 93.5Z"
		/>
	</svg>
);
