import { ReactNode } from 'react';

export const symbol_picture_as_pdf: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M368-514h48q15.725 0 26.362-10.638Q453-535.275 453-551v-48q0-15.725-10.638-26.362Q431.725-636 416-636h-66q-7.6 0-13.3 5-5.7 5-5.7 13.409v168.182Q331-441 336.7-436t13.3 5q8 0 13-5.7t5-13.3v-64Zm0-37v-48h48v48h-48Zm213 120q15 0 26-10.638 11-10.637 11-26.362v-131q0-15.725-11-26.362Q596-636 581-636h-65q-7.6 0-13.3 5-5.7 5-5.7 13.364v167.272q0 8.364 5.7 13.864T516-431h65Zm-47-37v-131h47v131h-47Zm170-46h32q8 0 13-5.7t5-13.3q0-8-5-13t-13-5h-32v-48h32q8 0 13-5.7t5-13.3q0-8-5-13t-13-5h-50q-7.6 0-13.3 5-5.7 5-5.7 13.409v168.182Q667-441 672.7-436t13.3 5q8 0 13-5.7t5-13.3v-64ZM260-200q-24 0-42-18t-18-42v-560q0-24 18-42t42-18h560q24 0 42 18t18 42v560q0 24-18 42t-42 18H260Zm0-60h560v-560H260v560ZM140-80q-24 0-42-18t-18-42v-590q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-730v590h590q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q760-97 751.375-88.5T730-80H140Zm120-740v560-560Z"
		/>
	</svg>
);
