import Link from 'pkg/router/Link';
import { isLocalPathOrUrl } from 'pkg/router/utils';

import { addFormatter } from 'components/formatted-content/FormattedContent';

addFormatter('links', {
	selector:
		/(\b(?:https?|ftp):\/\/[a-z0-9-+&@#/%?=~_|!:,.;]*[a-z0-9-+&@#/%=~_|])/gi,
	decorator: (guid: string, match: string): JSX.Element => {
		let href = match;

		if (isLocalPathOrUrl(match)) {
			href = new URL(match).pathname;
		}

		return (
			<Link key={guid} href={href}>
				{match}
			</Link>
		);
	},
});
