import { Record } from 'pkg/api/models/record';
import { Dateable } from 'pkg/api/models/dateable';
import { Account } from 'pkg/api/models/account';
import * as models from 'pkg/api/models';

export enum AccountRelationTypes {
	LegalGuardian = 'legalGuardian',
	Ward = 'ward',
}

export interface AccountRelation extends Record, Dateable {
	accountId: number;
	account: Account;
	targetAccountId: number;
	targetAccount: Account;
	type: AccountRelationTypes;
	approvedAt?: number;
}

export function findAllUserIds(accountRelations: AccountRelation[]) {
	const wardUserIds: number[] = [];

	accountRelations.forEach((ar) => {
		ar.targetAccount.users.forEach((u) => {
			wardUserIds.push(u.id);
		});
	});

	return wardUserIds;
}

export function isApproved(relation: AccountRelation): boolean {
	return relation?.approvedAt > 0;
}

export function findAllApproved(
	relations: AccountRelation[]
): AccountRelation[] {
	return relations.filter((r) => isApproved(r));
}

export function isPending(relation: AccountRelation): boolean {
	return !isApproved(relation);
}

export function hasParentalConsent(relation: AccountRelation): boolean {
	return (
		relation.targetAccount?.parentalConsentGranted > 0 &&
		!relation.targetAccount?.parentalConsentRevoked
	);
}

export function parentalConsentMissing(
	relation: AccountRelation,
	consentAgeThreshold: number = 16
): boolean {
	if (
		!models.account.needsParentalConsent(
			relation.targetAccount,
			consentAgeThreshold
		)
	) {
		return false;
	} else if (!hasParentalConsent(relation)) {
		return true;
	}

	return false;
}
