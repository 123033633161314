import { ReactNode } from 'react';

export const symbol_browser_updated: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-240q-24 0-42-18t-18-42v-480q0-24 18-42t42-18h367q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T507-780H140v480h680v-109q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T880-409v109q0 24-18 42t-42 18H652l39 38q5 5 7 10.543 2 5.544 2 11.457v30q0 12.75-8.625 21.375T670-120H290q-12.75 0-21.375-8.625T260-150v-31q0-5.565 2-10.783Q264-197 269-202l38-38H140Zm457-221v-349q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T657-810v349l100-99q9-8 21.1-8.5 12.1-.5 20.9 8.5 9 9 9 21t-9 21L627-346 455-518q-9-9-9-21t9-21q9-9 21-9t21 9l100 99Z"
		/>
	</svg>
);
