import { Fragment, useContext, useState } from 'react';
import { t } from '@transifex/native';

import spacing from 'pkg/config/spacing';
import * as styles from 'pkg/config/styles';

import useConfirm from 'pkg/hooks/useConfirm';
import * as models from 'pkg/api/models';

import { AddOrderContext } from 'routes/payments/orders/create';
import ContactModal from 'routes/payments/orders/create/components/ContactModal';
import NewContactModal from 'routes/payments/orders/create/components/NewContactModal';
import EditAssignedContacts from 'routes/payments/orders/create/modals/edit-assigned';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import Icon from 'components/icon';

import * as Table from 'components/form/Table';
import InfoBox from 'components/form/info-box';
import Column from 'components/layout/column';

import Button, { ButtonGroup } from 'design/button';

interface UserRowProps {
	contact: models.user.User;
	handleRemoveContact: (id: number) => void;
}

const UserRow: React.FC<React.PropsWithChildren<UserRowProps>> = ({
	contact,
	handleRemoveContact,
}) => {
	const email = models.user.getBillingEmail(contact);
	const fullName = models.user.fullName(contact);

	return (
		<Table.TableRow>
			<Table.Cell>
				{fullName} - {email}
			</Table.Cell>
			<Table.IconCell onClick={() => handleRemoveContact(contact.id)}>
				<Icon name="close-circle" />
			</Table.IconCell>
		</Table.TableRow>
	);
};

interface AssignContactsProps {
	organizationId: number;
}

const AssignContacts: React.FC<
	React.PropsWithChildren<AssignContactsProps>
> = ({ organizationId }) => {
	const OrderContext = useContext(AddOrderContext);

	const assignedContacts = OrderContext.formState.assignedContacts;

	const [modal, setModal] = useState('');

	const hideModal = () => setModal('');
	const showModal = (type: string) => setModal(type);

	const handleRemoveContacts = useConfirm({
		message: t('Are you sure you want to remove these contacts?'),
		onConfirm: () => {
			OrderContext.setFormState({
				assignedContacts: [],
			});
		},
	});

	const handleRemoveContact = (id: number) => {
		const confirm = window.confirm(
			t('Are you sure you want to remove this contact?')
		);

		if (!confirm) return;

		OrderContext.setFormState({
			assignedContacts: assignedContacts.filter((user) => user.id !== id),
		});
	};

	const handleSelectContact = () => showModal('selectContact');
	const handleEditAssignedContacts = () => showModal('editAssigned');
	const handleNewContact = () => showModal('newContact');

	const missingBillingEmail = assignedContacts
		.filter((user) => user.createdAt)
		.some((user) => !models.user.getBillingEmail(user));

	const tableRow =
		assignedContacts.length > 5 ? (
			<Table.TableRow>
				<Table.Cell>
					{t('{num} contacts selected', { num: assignedContacts.length })}
				</Table.Cell>
				<Table.IconCell>
					<Icon name="edit" onClick={handleEditAssignedContacts} />
				</Table.IconCell>
				<Table.IconCell onClick={handleRemoveContacts}>
					<Icon name="close-circle" />
				</Table.IconCell>
			</Table.TableRow>
		) : (
			assignedContacts
				.filter((user) => user.createdAt)
				.map((contact) => (
					<UserRow
						key={contact.id}
						contact={contact}
						handleRemoveContact={handleRemoveContact}
					/>
				))
		);

	return (
		<Fragment>
			<Column spacing={styles.spacing._5}>
				<Fragment>
					{assignedContacts.length > 0 && (
						<Table.Table>
							<tbody>{tableRow}</tbody>
						</Table.Table>
					)}
					{missingBillingEmail && (
						<InfoBox color="red">
							{t(
								'There are assigned contacts without a billing email. Remove them to be able to continue.'
							)}
						</InfoBox>
					)}
				</Fragment>
				<LargeScreen>
					<ButtonGroup>
						<Button
							secondary
							icon="add"
							testid="order.form.select_contact"
							onClick={handleSelectContact}>
							{t('Add contacts')}
						</Button>
						<Button
							secondary
							icon="add"
							testid="order.form.new_contact"
							onClick={handleNewContact}>
							{t('New contact')}
						</Button>
					</ButtonGroup>
				</LargeScreen>
				<SmallScreen>
					<Column spacing={spacing._3}>
						<Button
							secondary
							block
							icon="add"
							testid="order.form.select_contact"
							onClick={handleSelectContact}>
							{t('Add contacts')}
						</Button>
						<Button
							secondary
							block
							icon="add"
							testid="order.form.new_contact"
							onClick={handleNewContact}>
							{t('New contact')}
						</Button>
					</Column>
				</SmallScreen>
			</Column>
			{modal === 'selectContact' && <ContactModal hideModal={hideModal} />}
			{modal === 'newContact' && (
				<NewContactModal hideModal={hideModal} groupId={organizationId} />
			)}
			{modal === 'editAssigned' && (
				<EditAssignedContacts
					users={assignedContacts.filter((user) => user.createdAt)}
					hideModal={hideModal}
				/>
			)}
		</Fragment>
	);
};

export default AssignContacts;
