import styled, { css } from 'styled-components';
import { t } from '@transifex/native';

import * as palette from 'pkg/config/palette';
import { small } from 'pkg/config/breakpoints';

import rgba from 'pkg/rgba';

const Card = styled.div`
	border-radius: 13px;
	height: 100%;
	width: 100%;
	box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
	background: ${palette.white};
	position: absolute;
	z-index: ${(props) => props.index};
	top: ${(props) => `${props.topOffset}px`};
	transform: ${(props) => `scale(${props.scale})`};
	display: flex;
	overflow: hidden;

	&:nth-of-type(3) {
		transition: opacity 300ms ease-in-out 200ms;
	}

	&:nth-of-type(2) {
		transition: opacity 300ms ease-in-out 250ms;
	}

	&:nth-of-type(1) {
		transition: opacity 300ms ease-in-out 300ms;
	}

	@media ${small} {
		top: ${(props) => `calc(20px + ${props.topOffset}px)`};
	}
`;

const Wrapper = styled.div`
	height: 300px;
	width: 350px;
	position: relative;

	${Card} {
		opacity: ${(props) => (props.active ? '1' : '0')};
	}

	@media ${small} {
		width: 300px;
	}
`;

const Item = styled.li`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid ${palette.gray[200]};
	font-weight: var(--font-weight-normal);

	p {
		font-size: 0.73rem;
		text-transform: uppercase;
		color: ${palette.blue[500]};
	}

	span {
		font-size: 0.73rem;
		color: ${palette.gray[400]};
	}
`;

const ItemList = styled.ul`
	display: flex;
	height: 100%;
	flex-flow: row wrap;
	justify-content: space-evenly;
	padding: 40px 24px;
	position: relative;
	z-index: 1;

	${Item} {
		opacity: 0;

		${(props) =>
			props.active &&
			css`
				opacity: 1;

				&:nth-of-type(1) {
					transition: opacity 300ms 400ms ease-in-out;
				}

				&:nth-of-type(2) {
					transition: opacity 300ms 450ms ease-in-out;
				}

				&:nth-of-type(3) {
					transition: opacity 300ms 500ms ease-in-out;
				}

				&:nth-of-type(4) {
					transition: opacity 300ms 550ms ease-in-out;
				}

				&:nth-of-type(5) {
					transition: opacity 300ms 600ms ease-in-out;
				}
			`}
	}
`;

const Gradient = styled.div`
	background: linear-gradient(
		${rgba(palette.white, 0)} 30%,
		${rgba(palette.white, 0.5)} 50%,
		${rgba(palette.white, 1)} 100%
	);
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 10;
	border-radius: 13px;
`;

const IndividualTraining = (props) => {
	const ITEMS = [
		{
			title: t(`Attacking`),
			value: '100',
		},
		{
			title: t(`Physique`),
			value: '80',
		},
		{
			title: t(`Heading`),
			value: '70',
		},
		{
			title: t(`Pace `),
			value: '60',
		},
		{
			title: t(`Vision`),
			value: '50',
		},
	];

	return (
		<Wrapper active={props.active}>
			<Card index={3} topOffset={0} scale={1}>
				<ItemList active={props.active}>
					{ITEMS.map((item, i) => {
						return (
							<Item key={i}>
								<p>{`${i + 1}. ${item.title}`}</p>
								<span>{item.value}%</span>
							</Item>
						);
					})}
				</ItemList>
				<Gradient />
			</Card>
			<Card index={2} topOffset={10} scale={0.97} />
			<Card index={1} topOffset={20} scale={0.94} />
		</Wrapper>
	);
};

export default IndividualTraining;
