import { Record } from 'immutable';
import { schema } from 'normalizr';

export const performanceReview = new schema.Entity('performanceReview');

interface IPerformanceReview {
	createdAt: number;
	groupId: number;
	id: number;
	links?: { [key: string]: string };
	performanceReview: string;
	updatedAt: number;
	userGroupId: number;
	userId: number;
}

const performanceReviewProps: IPerformanceReview = {
	createdAt: 0,
	groupId: 0,
	id: 0,
	links: {},
	performanceReview: '',
	updatedAt: 0,
	userGroupId: 0,
	userId: 0,
};

export default class PerformanceReview
	extends Record(performanceReviewProps, 'PerformanceReviewRecord')
	implements IPerformanceReview
{
	static normalizr() {
		return performanceReview;
	}
}
