import { Fragment, memo, useReducer, useCallback } from 'react';
import styled from 'styled-components';
import { List } from 'immutable';
import { t } from '@transifex/native';

import Modal, { Step } from 'components/step-modal';

import SelectableList from 'components/user/SelectableList';
import AvatarStack from 'components/avatar/Stack';

import Button from 'design/button';

const Trigger = styled.span``;

const TagButton = styled(Button)`
	padding: 3px 16px 3px 8px;
`;

const EmptyTagButton = styled(Button)`
	svg {
		margin-right: 10px;
		transform: scale(1.7);
	}
`;

const NOOP = () => {};

export default memo(
	({
		users,
		selectedUserIds = [],
		onChange = NOOP,
		defaultOpen = false,
		selectable = true,
		renderAsAvatarStack = false,
		renderTriggerWith = null,
		renderEmptyWith = null,
	}) => {
		const [state, dispatch] = useReducer(
			(state, { type, payload }) => {
				switch (type) {
					case 'userIds':
						return {
							...state,
							userIds: payload,
						};
					case 'visibility':
						return { ...state, isOpen: payload };
				}
			},
			{
				isOpen: defaultOpen,
				userIds: selectedUserIds,
			}
		);

		const open = useCallback(
			(event) => {
				event.stopPropagation();

				dispatch({
					type: 'visibility',
					payload: true,
				});
			},
			[dispatch]
		);

		const close = useCallback(
			() =>
				dispatch({
					type: 'visibility',
					payload: false,
				}),
			[dispatch]
		);

		const tagUsers = useCallback(
			(userIds) => {
				dispatch({
					type: 'userIds',
					payload: userIds,
				});
			},
			[dispatch]
		);

		const selectedUsers = new List(
			state.userIds.map((userId) => users.find((user) => user.id === userId))
		);

		const handleDone = useCallback(() => {
			onChange(selectedUsers);
		}, [onChange, selectedUsers]);

		if (users.size === 0) return null;

		if (!renderTriggerWith) {
			renderTriggerWith = (
				<TagButton>
					<AvatarStack
						users={selectedUsers}
						size={20}
						renderEmptyWith={<span>{t('Tag users')}</span>}
						renderRestWith={t(`{num} tagged players`, {
							num: state.userIds.length,
						})}
						selectedUserIds={state.userIds}
					/>
				</TagButton>
			);
		}

		if (state.userIds.length === 0) {
			if (!renderEmptyWith) {
				renderTriggerWith = (
					<EmptyTagButton icon="user-add">{t('Tag users')}</EmptyTagButton>
				);
			} else {
				renderTriggerWith = renderEmptyWith;
			}
		}

		if (renderAsAvatarStack) {
			renderTriggerWith = (
				<AvatarStack
					users={selectedUsers}
					selectedUserIds={state.userIds}
					size={24}
				/>
			);
		}

		return (
			<Fragment>
				<Trigger onClick={open}>{renderTriggerWith}</Trigger>
				{state.isOpen && (
					<Modal onClose={close}>
						<Step
							skipBody
							title={t('Tag users')}
							nextLabel={t('Done')}
							hidePrev
							onNext={handleDone}>
							<SelectableList
								selectable={selectable}
								users={users}
								multipleChoice
								showControls={selectable}
								onChange={tagUsers}
								selectedUserIds={state.userIds}
							/>
						</Step>
					</Modal>
				)}
			</Fragment>
		);
	}
);
