import { MessageType, useWebSocketClient } from 'pkg/websocket';

import { useApiConfig } from 'components/application/apiconfig';

// This component is only responsible for listening to WS updates about maintenance mode and
// updating the maintenance mode component with the potential new state
export default function MaintenanceModeObserver(): JSX.Element {
	const ws = useWebSocketClient();
	const apiConfig = useApiConfig();

	ws.onMessage<{ key: string }>(MessageType.ConfigVariable, (message) => {
		if (message.data.key === 'server_maintenance_mode_enabled') {
			apiConfig.refreshConfig();
		}
	});

	return null;
}
