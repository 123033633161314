const icon = {
	name: 'collapse-reverse',
	objects: [
		{
			type: 'path',
			attributes: {
				d: 'M16.5 11.8891L13.1813 15.0978L12.2415 14.1891L14.6204 11.8891L12.2415 9.58915L13.1813 8.6805L16.5 11.8891Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M17.2427 4.18213C18.3473 4.18213 19.2427 5.07756 19.2427 6.18213V17.2319C19.2427 18.3365 18.3473 19.2319 17.2427 19.2319H7C5.89543 19.2319 5 18.3365 5 17.2319V6.18213C5 5.07756 5.89543 4.18213 7 4.18213H17.2427ZM17.9427 6.18213V17.2319C17.9427 17.6185 17.6293 17.9319 17.2427 17.9319H10.4276V5.48213L17.2427 5.48213C17.6293 5.48213 17.9427 5.79553 17.9427 6.18213ZM7 17.9319H9.07761V5.48213H7C6.6134 5.48213 6.3 5.79553 6.3 6.18213L6.3 17.2319C6.3 17.6185 6.6134 17.9319 7 17.9319Z',
			},
		},
	],
};

export default icon;
