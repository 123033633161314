import { t } from '@transifex/native';

import { ArchivedContactsData } from 'pkg/api/dashboards/management';
import { pushState } from 'pkg/router/state';
import { toFilterQueryUrl } from 'pkg/filters/use_filters';
import * as routes from 'pkg/router/routes';
import { FilterOperator, createQueryFilter } from 'pkg/filters';
import { UserProductStatuses } from 'pkg/api/models/user_product';
import { useCurrentOrganization } from 'pkg/identity';

import * as DataCard from 'design/data_card';

interface ArchivedContactsWidgetProps {
	hasPaymentsEnabled: boolean;

	data: ArchivedContactsData;
}

export default function ArchivedContactsWidget({
	hasPaymentsEnabled,
	data,
}: ArchivedContactsWidgetProps) {
	const org = useCurrentOrganization();

	const handleClick = () => {
		pushState(
			toFilterQueryUrl(routes.Management.Contacts.Home(org.id), {
				product_status: createQueryFilter({
					type: 'checkbox',
					property: 'product_status',
					operator: FilterOperator.Includes,
					values: [UserProductStatuses.PastDue, UserProductStatuses.Open],
				}),
				archived: createQueryFilter({
					type: 'checkbox',
					property: 'archived',
					operator: FilterOperator.Set,
					values: ['archived'],
				}),
			})
		);
	};

	const breakdownItems: DataCard.BreakdownItem[] = [
		{
			title: t('With unpaid invoices'),
			value: data.unpaidInvoicesCount.toString(),
			onClick: handleClick,
		},
	];

	return (
		<DataCard.Base
			title={t('Archived contacts')}
			titleIcon="archive"
			heading={data.totalCount.toString()}
			headingSuffix={t('Total amount')}>
			{hasPaymentsEnabled && <DataCard.Breakdown items={breakdownItems} />}
		</DataCard.Base>
	);
}
