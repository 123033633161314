import { ReactNode } from 'react';

export const symbol_calendar_apps_script: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M586-80q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T586-140h145l-118-83q-10-7-12.5-19t4.5-22q7-10 18.826-12.5T646-272l119 82-50-136q-5-11 1-22t18-16q12-5 23 .5t16 17.5l48 136 39-139q3-11 13.5-17.5t22-3.5q11.5 3 18 13t3.5 22l-62 233q-2.88 10.267-10.44 16.133Q837-80 826-80H586Zm-406-80q-24 0-42-18t-18-42v-540q0-24 18-42t42-18h65v-28q0-13.6 9.2-22.8 9.2-9.2 22.8-9.2 14.025 0 23.513 9.2Q310-861.6 310-848v28h260v-28q0-13.6 9.2-22.8 9.2-9.2 22.8-9.2 14.025 0 23.513 9.2Q635-861.6 635-848v28h65q24 0 42 18t18 42v269q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T700-491v-79H180v350h290q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T470-160H180Zm0-470h520v-130H180v130Zm0 0v-130 130Z"
		/>
	</svg>
);
