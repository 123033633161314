import { ReactNode } from 'react';

export const symbol_settings_cell: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M309.5 0q-12.5 0-21-8.625T280-30q0-12 8.625-21T310-60q12 0 21 9t9 21.5q0 12.5-9 21T309.5 0Zm170.325 0Q467 0 458.5-8.675q-8.5-8.676-8.5-21.5Q450-43 458.675-51.5q8.676-8.5 21.5-8.5Q493-60 501.5-51.325q8.5 8.676 8.5 21.5Q510-17 501.325-8.5q-8.676 8.5-21.5 8.5Zm170 0Q637 0 628.5-8.625T620-30q0-12 8.675-21 8.676-9 21.5-9Q663-60 671.5-51t8.5 21.5q0 12.5-8.675 21-8.676 8.5-21.5 8.5ZM300-160q-23 0-41.5-18.5T240-220v-680q0-24 18.5-42t41.5-18h360q23 0 41.5 18t18.5 42v680q0 23-18.5 41.5T660-160H300Zm0-60h360v-60H300v60Zm0-120h360v-440H300v440Zm0-500h360v-60H300v60Zm0 0v-60 60Zm0 620v-60 60Z"
		/>
	</svg>
);
