import { ReactNode } from 'react';

export const symbol_sentiment_satisfied: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-261q55.507 0 105.253-25Q635-311 664-358q8-11 1.5-23T646-393q-8 0-13.5 3.5T622-380q-24 34-61.542 52-37.541 18-79.5 18Q439-310 401-327t-62-52q-4-6-10.19-10-6.191-4-13.81-4-12.706 0-18.353 12.5Q291-368 299-355q29.489 45.978 77.789 69.989Q425.09-261 480-261Zm146-272q22.5 0 38.25-15.75T680-587q0-22.5-15.75-38.25T626-641q-22.5 0-38.25 15.75T572-587q0 22.5 15.75 38.25T626-533Zm-292 0q22.5 0 38.25-15.75T388-587q0-22.5-15.75-38.25T334-641q-22.5 0-38.25 15.75T280-587q0 22.5 15.75 38.25T334-533ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-400Zm0 340q142.375 0 241.188-98.812Q820-337.625 820-480t-98.812-241.188Q622.375-820 480-820t-241.188 98.812Q140-622.375 140-480t98.812 241.188Q337.625-140 480-140Z"
		/>
	</svg>
);
