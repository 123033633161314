import { ReactNode } from 'react';

export const symbol_south_america: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-60-30-30q-7-7-9.5-14.427t-2.5-15.914V-438q-35.062 0-60.031-24.675T353-522v-42.667L220-698q-38 45-59 100.151-21 55.152-21 117.728Q140-338 238.812-239 337.625-140 480-140Zm42-2q127-17 212.5-112T820-479.774q0-141.407-99.408-240.816Q621.184-820 479.78-820q-46.78 0-89.28 12T310-774v82h150.875q19.125 0 36.625 8t29.5 24l59.379 74H650q17 0 29.5 12.5T692-544v45.134q0 9.672-2.5 18.269Q687-472 682-464L522-224.891V-142Z"
		/>
	</svg>
);
