import { ReactNode } from 'react';

export const symbol_stream: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M130-430q-20 0-35-15t-15-35q0-20 15-35t35-15q20 0 35 15t15 35q0 20-15 35t-35 15Zm52 204 130-130q9-9 21-8.5t21 9.5q9 9 9 21.5t-9 21.5L224-182q-9 9-21 8.5t-21-9.5q-9-9-9-21.5t9-21.5Zm130-381L182-736q-9-9-8.5-21t9.5-21q9-9 21.5-9t21.5 9l129 129q9 9 9 21t-9 21q-9 9-21.5 9t-21.5-9ZM480-80q-20 0-35-15t-15-35q0-20 15-35t35-15q20 0 35 15t15 35q0 20-15 35t-35 15Zm0-700q-20 0-35-15t-15-35q0-20 15-35t35-15q20 0 35 15t15 35q0 20-15 35t-35 15Zm126 130 129-129q9-9 21-8.5t21 9.5q9 9 9 21.5t-9 21.5L648-606q-9 9-21 8.5t-21-9.5q-9-9-9-21.5t9-21.5Zm129 466L605-313q-9-9-8.5-21t9.5-21q9-9 21.5-9t21.5 9l129 129q9 9 9 21t-9 21q-9 9-21.5 9t-21.5-9Zm95-246q-20 0-35-15t-15-35q0-20 15-35t35-15q20 0 35 15t15 35q0 20-15 35t-35 15Z"
		/>
	</svg>
);
