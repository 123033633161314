import { ReactNode } from 'react';

export const symbol_type_specimen: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M455-460h170l34 87q2 6 7.385 9.5 5.384 3.5 11.846 3.5Q689-360 695-369q6-9 2-19L566-708q-2-5-7.12-8.5T548-720h-15.613Q526-720 521-716.5q-5 3.5-7 8.5L383-388q-4 10 1.924 19 5.924 9 17.076 9 6 0 11.417-3.575Q418.833-367.15 421-373l34-87Zm14-36 69-178h4l69 178H469ZM260-200q-24 0-42-18t-18-42v-560q0-24 18-42t42-18h560q24 0 42 18t18 42v560q0 24-18 42t-42 18H260Zm0-60h560v-560H260v560ZM140-80q-24 0-42-18t-18-42v-590q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-730v590h590q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q760-97 751.375-88.5T730-80H140Zm120-740v560-560Z"
		/>
	</svg>
);
