import { ReactNode } from 'react';

export const symbol_ecg_heart: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-480Zm0 360q-18 0-34.5-6.5T416-146L148-415q-35-35-51.5-80T80-589q0-103 67-177t167-74q48 0 90.5 19t75.5 53q32-34 74.5-53t90.5-19q100 0 167.5 74T880-590q0 49-17 94t-51 80L543-146q-13 13-29 19.5t-34 6.5Zm40.292-510Q528-630 534-626t11 10l71 106h188.133q7.934-19.429 11.9-39.429Q820-569.429 820-590q0-77-49.946-133.5T645.189-780Q610-780 577.5-765.5 545-751 521-725l-27 29q-3 3-6 5t-8 2q-5 0-8.636-1.885-3.637-1.884-6.364-5.115l-27-29q-24.273-25.816-56.636-40.408Q349-780 314-780q-74.571 0-124.286 56.441Q140-667.118 140-590q0 20.717 4 40.859Q148-529 155.649-510H360q7.576 0 14.394 3.611Q381.212-502.778 385-497l46 70 60-182q3.083-9 11.177-15t18.115-6ZM529-533l-61 182q-2.974 9-11.154 15-8.179 6-17.846 6-8 0-14-4t-10-10l-71-106H198l261 261q5 5 9.8 7 4.8 2 11.2 2 6.4 0 11.2-2 4.8-2 9.8-7l260-261H600q-8 0-14-4t-11-10l-46-69Z"
		/>
	</svg>
);
