import { ReactNode } from 'react';

export const symbol_record_voice_over: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M920-591q0 80-24.5 142T826-333q-10 12-24.5 13T776-330q-11-11-10.5-26t10.5-27q36-44 55-92.5T850-591q0-67-19-115.5T776-799q-10-12-10.5-27t10.5-26q11-11 25.5-10t24.5 13q45 54 69.5 116T920-591Zm-200 0q0 32-9.5 61T684-475q-8 12-22 13t-25-10q-11-11-12-25t7-27q8-14 13-31t5-36q0-19-5-36t-13-31q-8-13-7-27t12-25q11-11 25-10t22 13q17 26 26.5 55t9.5 61ZM360-441q-66 0-108-42t-42-108q0-66 42-108t108-42q66 0 108 42t42 108q0 66-42 108t-108 42ZM40-180v-34q0-38 19-64.5t49-41.5q51-26 120.5-43T360-380q62 0 131 17t120 43q30 15 49.5 41.5T680-214v34q0 25-17.5 42.5T620-120H100q-25 0-42.5-17.5T40-180Zm60 0h520v-34q0-16-8.5-29.5T587-266q-48-27-109-40.5T360-320q-57 0-118.5 14.5T132-266q-14 7-23 21.5t-9 30.5v34Zm260-321q39 0 64.5-25.5T450-591q0-39-25.5-64.5T360-681q-39 0-64.5 25.5T270-591q0 39 25.5 64.5T360-501Zm0-90Zm0 411Z"
		/>
	</svg>
);
