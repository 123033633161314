import { ReactNode } from 'react';

export const symbol_mic_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M658-381q-10-8-11-20t7-22q10.889-16.837 17.889-35.969 7-19.133 10.111-39.031 2-13 10.675-22 8.676-9 21.5-9 12.825 0 21.325 9.073T742-498q-4 30.209-14 59.104Q718-410 700-385q-8 10-19.936 11T658-381ZM461-586Zm2.941-294Q506-880 535.5-850.542 565-821.083 565-779v226q0 15-9.318 22.5t-20.5 7.5Q524-523 514.5-530.939 505-538.878 505-554v-225q0-17.425-11.788-29.213Q481.425-820 464-820q-17.425 0-29.212 11.787Q423-796.425 423-779v83q0 15-9.318 22.5t-20.5 7.5Q382-666 372.5-673.683 363-681.366 363-696v-83q0-42.083 29.441-71.542Q421.882-880 463.941-880ZM434-150v-106q-96-11-166.5-77.5T186-498q-2-12.854 6.593-21.927 8.592-9.073 21.5-9.073 12.907 0 21.351 8.912Q243.889-511.175 246-498q12 81 75 132.5T464.151-314q37.849 0 72.959-12.337Q572.221-338.675 601-361l43 43q-31 26-69.014 41.568Q536.972-260.865 494-256v106q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T434-150Zm378 76L55-831q-8-8-8-19t8-19q8-8 19-8t19 8l757 757q8 8 8 19t-8 19q-8 8-19 8t-19-8Z"
		/>
	</svg>
);
