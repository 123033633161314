import { t } from '@transifex/native';
import { ChangeEvent, useState } from 'react';
import styled from 'styled-components';

import * as models from 'pkg/api/models';

import StepModal, { Step } from 'components/step-modal';

const GroupNameInput = styled.input`
	display: block;
	font-size: 1.7rem;
	border: 0;
	height: 3.2rem;
	line-height: 3.2rem;
	padding: 0 0.8rem;
	border-left: 0.2rem solid var(--palette-gray-400);
	appearance: none;
	resize: none;
	color: var(--palette-black);
	margin: 1rem;

	::placeholder {
		color: var(--palette-gray-400);
	}

	&:focus {
		outline: none;
	}
`;

interface ChatTitleModalProps {
	chat: models.chat.Chat;

	onClose: () => void;
}

function ChatTitleModal({ chat, onClose }: ChatTitleModalProps) {
	const [groupName, setGroupName] = useState(chat.title || '');

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		setGroupName(event.target.value);
	};

	const saveGroupName = async () => {
		await models.chat.update(chat, {
			title: groupName,
		});

		onClose();
	};

	return (
		<StepModal onClose={onClose}>
			<Step
				title={t('Rename group chat')}
				nextLabel={t('Save')}
				prevLabel={t('Cancel')}
				onNext={saveGroupName}
				canGoNext
				skipBody>
				<GroupNameInput
					value={groupName}
					onChange={handleChange}
					placeholder={t('Group name (optional)')}
					data-testid="chat.group_name_input"
				/>
			</Step>
		</StepModal>
	);
}

export default ChatTitleModal;
