import { ReactNode } from 'react';

export const symbol_motion_blur: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M300-280q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T300-340h237q-21-21-36-51t-19-59H380q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T380-510h102q4-28 18.5-57.5T537-620H150q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-680h530q83 0 141.5 58.5T880-480q0 83-58.5 141.5T680-280H300Zm379.765-60Q738-340 779-380.765q41-40.764 41-99Q820-538 779.235-579q-40.764-41-99-41Q622-620 581-579.235q-41 40.764-41 99Q540-422 580.765-381q40.764 41 99 41ZM110-450q-12.75 0-21.375-8.675Q80-467.351 80-480.175 80-493 88.625-501.5T110-510h180q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T290-450H110Zm40 170q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-340h60q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T210-280h-60Z"
		/>
	</svg>
);
