const clock = {
	name: 'clock',
	objects: [
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M12 18.679C15.6887 18.679 18.679 15.6887 18.679 12C18.679 8.31129 15.6887 5.32099 12 5.32099C8.31128 5.32099 5.32099 8.31129 5.32099 12C5.32099 15.6887 8.31128 18.679 12 18.679ZM12 20.0928C16.4696 20.0928 20.0928 16.4696 20.0928 12C20.0928 7.53045 16.4696 3.90717 12 3.90717C7.53045 3.90717 3.90717 7.53045 3.90717 12C3.90717 16.4696 7.53045 20.0928 12 20.0928Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M11.35 13.3941V6.97188H12.65V13.3941H11.35Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M12.2692 12.4749L14.9707 15.1764L14.0515 16.0956L11.35 13.3941L12.2692 12.4749Z',
			},
		},
	],
};

export default clock;
