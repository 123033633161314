import { ReactNode } from 'react';

export const symbol_lightning_stand: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-80q-122 0-201-31t-79-107q0-76 79-107t201-31q122 0 201 31t79 107q0 76-79 107T480-80Zm0-86q-122 0-178-21t-64-72q-5 8-6.5 20t-1.5 21q0 52 64.5 80T480-110q121 0 185.5-28t64.5-80q0-9-1.5-21t-6.5-20q-9 51-64.5 72T480-166Zm0-30q106 0 157-16.5t51-49.5q0-33-51-48.5T480-326q-106 0-157.5 15.5T271-262q0 33 51.5 49.5T480-196Zm-37-412h-52q-8 0-13-7t0-15l125-226q3-5 8.5-4t5.5 7v168h52q8 0 13 7t0 15L457-437q-3 5-8.5 4t-5.5-7v-168Zm37 412Zm0 86Zm0-56Z"
		/>
	</svg>
);
