import { t } from '@transifex/native';
import { Fragment } from 'react';

import { DescriptionRow } from 'routes/event/components/DescriptionRow';

import Icon from 'components/icon';

import Button from 'design/button';

import * as css from './styles.css';

interface EventLocationProps {
	item: {
		location: string;
	};
}

const EventLocation = ({ item }: EventLocationProps) => {
	const locationQuery = encodeURIComponent(item.location);

	const locationSearch = (
		<Fragment>
			<div>{item.location}</div>
			<Button
				small
				icon="map"
				href={`https://www.google.com/maps/search/?api=1&query=${locationQuery}`}
				label={t(`Search on Google Maps`)}
			/>
		</Fragment>
	);

	if (!item.location) {
		return null;
	}

	return (
		<DescriptionRow>
			<Icon name="location" />
			<div className={css.wrapper}>{locationSearch}</div>
		</DescriptionRow>
	);
};

export default EventLocation;
