import { ReactNode } from 'react';

export const symbol_personal_bag: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M260-80q-24 0-42-18t-18-42v-350q0-88 49-158.5T375-751v-19q0-44 30.676-77 30.677-33 74.5-33Q524-880 554.5-847t30.5 77v19q77 32 126 102.5T760-490v350q0 24-18 42t-42 18H260Zm0-60h440v-350.174Q700-581 635.712-645.5t-155.5-64.5Q389-710 324.5-645.65 260-581.3 260-490v350Zm380-190q12 0 21-9t9-21v-60q0-24.75-17.625-42.375T610-480H320q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T320-420h290v60q0 12 9 21t21 9ZM435-768q5-1 20.5-1.5t24.5-.5q9 0 24.5.5T525-768v-2q0-20-12.5-35T480-820q-20 0-32.5 15T435-770v2ZM260-140h440-440Z"
		/>
	</svg>
);
