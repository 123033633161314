import { ReactNode } from 'react';

export const symbol_battery_alert: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M310-80q-12.75 0-21.375-8.625T280-110v-676q0-12.75 8.625-21.375T310-816h90v-34q0-12.75 8.625-21.375T430-880h100q12.75 0 21.375 8.625T560-850v34h90q12.75 0 21.375 8.625T680-786v676q0 12.75-8.625 21.375T650-80H310Zm30-60h280v-616H340v616Zm0 0h280-280Zm140.175-263q12.825 0 21.325-8.625T510-433v-180q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T450-613v180q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625Zm.325 150q12.5 0 21-9t8.5-21.5q0-12.5-8.625-21T480-313q-12 0-21 8.625T450-283q0 12 9 21t21.5 9Z"
		/>
	</svg>
);
