import { t } from '@transifex/native';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import * as selectors from 'pkg/selectors';

import Modals from 'containers/match/edit/event/modals';
import EventType from 'containers/match/edit/event/Type';

import CardContainer, * as Card from 'components/Card';
import TabSwitcher from 'components/TabSwitcher';
import SectionTitle from 'components/SectionTitle';

import Column from 'components/layout/column';

const Form = styled.form``;

const TabSwitcherWrapper = styled.div`
	padding: 15px 0;
	display: flex;
`;

const EditEvents = ({ matchId, handleSave }) => {
	const [activeTeam, setActiveTeam] = useState('our');

	const matchEventTypes = useSelector((state) =>
		selectors.matchEvents.getFilteredMatchEventTypes(state)
	);
	const matchEventEdit = useSelector((state) =>
		selectors.matchEvents.getMatchEventEdit(state)
	);

	const eventTypeWeight = {
		goal: -1,
		shot_on_goal: 0,
		shot_off_goal: 1,
		free_kick: 2,
		corner: 3,
		offside: 4,
		penalty: 5,
		card_yellow: 7,
		card_red: 8,
		pass: 9,
		position_swap: 10,
		substitution_in: 11,
		other: 12,
	};

	const eventTypes = matchEventTypes?.sort((typeA, typeB) => {
		const a = eventTypeWeight[typeA];
		const b = eventTypeWeight[typeB];
		if (a > b) return 1;
		if (a < b) return -1;
		return 0;
	});

	const filteredEventTypes =
		activeTeam === 'opponent'
			? eventTypes.filter(
					(type) => type !== 'substitution_in' && type !== 'position_swap'
				)
			: eventTypes;

	const onToggleActiveTeam = () =>
		setActiveTeam(activeTeam === 'our' ? 'opponent' : 'our');

	return (
		<Column>
			<SectionTitle>{t(`Statistics`)}</SectionTitle>
			<CardContainer>
				<TabSwitcherWrapper>
					<TabSwitcher
						active={activeTeam}
						tabs={{
							our: t(`Our team`),
							opponent: t(`Opponent`),
						}}
						onChange={onToggleActiveTeam}
					/>
				</TabSwitcherWrapper>
				<Card.Divider />
				<Form>
					{filteredEventTypes.map((type) => (
						<EventType
							key={type}
							matchId={matchId}
							type={type}
							isOpponent={activeTeam === 'opponent'}
							handleSave={handleSave}
						/>
					))}
				</Form>
			</CardContainer>

			{matchEventEdit.activeModal && <Modals matchId={matchId} />}
		</Column>
	);
};

export default EditEvents;
