import { ReactNode } from 'react';

export const symbol_earbuds_battery: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M737-687v-18q0-6 4.5-10.5T752-720h30q6 0 10.5 4.5T797-705v18h50q14.025 0 23.513 9.487Q880-668.025 880-654v381q0 14.025-9.487 23.512Q861.025-240 847-240H692q-14.025 0-23.513-9.488Q659-258.975 659-273v-381q0-14.025 9.487-23.513Q677.975-687 692-687h45Zm-18 387h101-101Zm-509 59q-54 0-92-36.111T80-366v-284q0-28.875 20.589-49.438Q121.177-720 150.089-720 179-720 199.5-699.411q20.5 20.588 20.5 49.5Q220-621 199.438-600.5 178.875-580 150-580q-8 0-15-2t-15-5v221q0 36.267 26.5 60.633Q173-281 210-281t63.5-24.367Q300-329.733 300-366v-229q0-52.778 38-88.889T430-720q54 0 92 36.111T560-595v284q0 28.875-20.589 49.438Q518.823-241 489.911-241 461-241 440.5-261.589q-20.5-20.588-20.5-49.5Q420-340 440.562-360.5 461.125-381 490-381q7.5 0 15 1.5t15 4.5v-220q0-36.267-26.5-60.633Q467-680 430-680t-63.5 24.367Q340-631.267 340-595v229q0 52.778-38 88.889T210-241Zm509-59h101v-327H719v327Z"
		/>
	</svg>
);
