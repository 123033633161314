import { ReactNode } from 'react';

export const symbol_remove_moderator: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M800-720v196q0 47-9.5 93.5T763-339q-7 17-18.5 21t-22.5 0q-11-4-17-14t0-22q17-36 26-85.5t9-84.5v-196l-260-96-170 63q-8 3-16.5 1t-14.5-8q-11-11-8-27t18-22l170-64q11-4 21-4t21 4l260 96q17 7 28 22.5t11 34.5ZM480-84q-7 0-19-4-140-50-220.5-169T160-524v-183l-84-84q-9-9-9-21.5t9-21.5q9-9 21.5-9t21.5 9l713 713q9 9 8.5 21.5T831-78q-9 9-21 9t-21-9L670-195q-38 38-78.5 64.5T500-87q-5 2-10 2.5t-10 .5Zm-54-356Zm73-101Zm-19 398q42-14 80.5-39.5T628-238L220-647v123q0 130 73 236.5T480-143Z"
		/>
	</svg>
);
