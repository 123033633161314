import { t } from '@transifex/native';
import styled from 'styled-components';

import * as palette from 'pkg/config/palette';

import Link from 'pkg/router/Link';
import * as routes from 'pkg/router/routes';
import { useCurrentOrganization } from 'pkg/identity';

import CardBase, * as Card from 'components/Card';
import RelativeTime from 'components/RelativeDateTime';
import ModalBase from 'components/modal';
import Avatar from 'components/avatar';

import { Spinner } from 'components/loaders/spinner';

import Button from 'design/button';

const List = styled.ul`
	list-style: none;
	margin: 0;
	padding: 0;

	li:first-child {
		margin-top: 0;
	}

	li:last-child {
		margin-bottom: 0;
	}
`;

const ListItem = styled.li`
	display: flex;
	flex-direction: row;
	margin: 10px 0;
	align-items: center;
`;

const ListItemLink = styled(Link)`
	@media (hover: hover) {
		&:hover {
			cursor: pointer;
			color: ${palette.black};
		}
	}
`;

const ListItemImage = styled(ListItemLink)`
	width: 60px;
	padding-right: 15px;
`;

const ListItemContent = styled(ListItemLink)`
	flex: 1;
	font-size: var(--font-size-base);
	line-height: 1.3;
	text-decoration: none;
	font-weight: var(--font-weight-semibold);
	width: 100%;
`;

const LikeUserWrapper = styled.div`
	display: flex;
	justify-content: center;
	flex-flow: row wrap;
	align-items: center;
`;

const LikeTime = styled.span`
	width: 100%;
	color: ${palette.gray[400]};
	font-size: var(--font-size-sm);
	margin-top: 3px;
	display: inline-flex;
	align-items: center;
`;

const LikesModal = ({
	collection,
	closeModal,
	fetchLikes,
	fetching,
	groupId,
}) => {
	const org = useCurrentOrganization();
	return (
		<ModalBase key="modal" thin scrolling open onClose={closeModal}>
			<CardBase>
				<Card.Header>
					<Card.Heading>{t(`Likes`)}</Card.Heading>
				</Card.Header>
				<Card.Divider />
				<Card.Body $flex>
					<List>
						{collection.records
							.sort((a, b) => b.createdAt - a.createdAt)
							.map((like, n) => {
								return (
									<ListItem key={'user-like-' + n}>
										<ListItemImage
											href={routes.User.Profile.Show(
												org.id,
												groupId,
												like.user.id,
												'overview'
											)}>
											<Avatar user={like.user} />
										</ListItemImage>
										<LikeUserWrapper>
											<ListItemContent
												href={routes.User.Profile.Show(
													org.id,
													groupId,
													like.user.id,
													'overview'
												)}>
												{`${like.user.firstName} ${like.user.lastName}`}
											</ListItemContent>
											<LikeTime>
												<RelativeTime dateTime={like.createdAt} />
											</LikeTime>
										</LikeUserWrapper>
									</ListItem>
								);
							})}
					</List>
					{(() => {
						if (fetching) {
							return <Spinner />;
						}

						if (!collection?.links?.next) {
							return null;
						}

						return <Button onClick={fetchLikes}>{t('Load more')}</Button>;
					})()}
				</Card.Body>
			</CardBase>
		</ModalBase>
	);
};

export default LikesModal;
