import { t } from '@transifex/native';
import * as React from 'react';
import styled from 'styled-components';

import * as styles from 'pkg/config/styles';

import Match from 'pkg/models/match';

import DateTime from 'pkg/datetime';
import Link from 'pkg/router/Link';
import * as routes from 'pkg/router/routes';
import { useCurrentOrganization } from 'pkg/identity';

import Result from 'containers/match/Result';

import MissingEntities from 'components/missing-entities';
import CardAnatomy, * as Card from 'components/Card';
import Icon from 'components/icon';

const CardHeader = styled(Card.Header)`
	color: var(--palette-gray-500);
	justify-content: center;
`;

const CardWrapper = styled(Link)`
	${CardAnatomy} {
		@media (hover: hover) {
			&:hover {
				box-shadow: ${styles.boxShadow.cardHover};
			}
		}
	}
`;

type LatestMatchProps = { match: Match };

const LatestMatch: React.FC<React.PropsWithChildren<LatestMatchProps>> = ({
	match,
}) => {
	const org = useCurrentOrganization();

	if (!match.id) {
		return (
			<CardAnatomy $noBorder>
				<Card.Body>
					<MissingEntities centered>
						<Icon name="field" />
						<p>{t(`No matches found`)}</p>
					</MissingEntities>
				</Card.Body>
			</CardAnatomy>
		);
	}

	return (
		<CardWrapper
			href={routes.Match.View(org.id, match.eventId, match.id, 'summary')}>
			<CardAnatomy $noBorder>
				<CardHeader>
					{new DateTime(new Date(match.startsAt * 1000)).toLocaleDateString({
						weekday: 'short',
						month: 'short',
						day: 'numeric',
					})}{' '}
					- {match.location}
				</CardHeader>
				<Card.Divider />
				<Card.Body>
					<Result matchId={match.id} />
				</Card.Body>
			</CardAnatomy>
		</CardWrapper>
	);
};

export default LatestMatch;
