import { useSelector } from 'react-redux';
import { createGlobalStyle } from 'styled-components';

import { useCurrentGroup, useCurrentMembership } from 'pkg/identity';
import { RootState } from 'pkg/reducers';
import * as selectors from 'pkg/selectors';
import * as models from 'pkg/api/models';
import { getCssVars } from 'pkg/cssvars';
import { accentColor, primaryColor } from 'pkg/colorconverter';

import { useAppState } from 'components/application/state';

const CSSVariableStyles = createGlobalStyle`
	:root {
		${({ vars }: { vars: any }) => vars}
	}
`;

export function CSSVariables() {
	const activeMembership = useCurrentMembership();
	const group = useCurrentGroup();
	const appState = useAppState();

	const collapsedDrawer = appState.collapsedDrawer;
	const visibleDrawer = useSelector<RootState, boolean>(
		(state) => state.app.visibleDrawer
	);
	const darkMode = useSelector<RootState, boolean>(
		(state) => state.app.darkMode
	);

	const colorProfileGradient = useSelector<RootState, string>((state) =>
		selectors.groups.getColorProfileGradient(state, {
			groupId: activeMembership.groupId,
		})
	);

	const groupPrimaryColor = models.group.getPrimaryColor(group);
	const vars = getCssVars({
		collapsedDrawer,
		visibleDrawer,
		darkMode,
		primaryColor: groupPrimaryColor && primaryColor(groupPrimaryColor),
		accentColor: groupPrimaryColor && accentColor(groupPrimaryColor),
		colorProfileGradient: groupPrimaryColor && colorProfileGradient,
	});

	return <CSSVariableStyles vars={vars} />;
}
