import * as Placeholder from 'components/Placeholder';

import AssessmentPlaceholder from './placeholders/AssessmentPlaceholder.placeholder';
import CardPlaceholder from './placeholders/Card.placeholder';
import DashboardEventsPlaceholder from './placeholders/DashboardEvents.placeholder';
import DashboardLastGamesPlaceholder from './placeholders/DashboardLastGames.placeholder';
import DashboardVideosPlaceholder from './placeholders/DashboardVideos.placeholder';
import ListPlaceholder from './placeholders/List.placeholder';

export enum PlaceholderType {
	FeedItem = 'feedItem',
	UserRating = 'userRating',
	Card = 'card',
	List = 'list',
	Assessment = 'assessment',
	DashboardEvents = 'dashboardEvents',
	DashboardLastGames = 'dashboardLastGames',
	DashboardVideos = 'dashboardVideos',
}

interface SkeletonProps {
	numItems?: number;
	placeholderType: PlaceholderType;
}

export function Skeleton({ numItems, placeholderType }: SkeletonProps) {
	switch (placeholderType) {
		case PlaceholderType.FeedItem:
			return <Placeholder.FeedItem numItems={numItems || 6} />;
		case PlaceholderType.UserRating:
			return <Placeholder.UserRating numItems={numItems || 10} />;
		case PlaceholderType.Card:
			return <CardPlaceholder numItems={numItems || 1} />;
		case PlaceholderType.List:
			return <ListPlaceholder numItems={numItems || 1} />;
		case PlaceholderType.Assessment:
			return <AssessmentPlaceholder numItems={numItems || 1} />;
		case PlaceholderType.DashboardEvents:
			return <DashboardEventsPlaceholder numItems={numItems || 1} />;
		case PlaceholderType.DashboardLastGames:
			return <DashboardLastGamesPlaceholder numItems={numItems || 1} />;
		case PlaceholderType.DashboardVideos:
			return <DashboardVideosPlaceholder numItems={numItems || 1} />;
	}
}
