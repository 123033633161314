import { ReactNode } from 'react';

export const symbol_tapas: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M279.825-40Q267-40 258.5-48.625T250-70v-340H150q-37.8 0-63.9-26.141t-26.1-64Q60-538 86.1-564t63.9-26h100v-60H150q-37.8 0-63.9-26.141t-26.1-64Q60-778 86.1-804t63.9-26h100v-60q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T310-890v60h100q37.8 0 63.9 26.141t26.1 64Q500-702 473.9-676T410-650H310v60h100q37.8 0 63.9 26.141t26.1 64Q500-462 473.9-436T410-410H310v340q0 12.75-8.675 21.375Q292.649-40 279.825-40ZM150-470h260q13 0 21.5-8.5T440-500q0-13-8.5-21.5T410-530H150q-13 0-21.5 8.5T120-500q0 13 8.5 21.5T150-470Zm0-240h260q13 0 21.5-8.5T440-740q0-13-8.5-21.5T410-770H150q-13 0-21.5 8.5T120-740q0 13 8.5 21.5T150-710Zm540 610v-304q-57-11-93.5-54.886T560-559.565V-890q0-12.75 8.625-21.375T590-920h260q12.75 0 21.375 8.625T880-890v330.435q0 56.793-36.5 100.679T750-404v304h60q12.75 0 21.375 8.675Q840-82.649 840-69.825 840-57 831.375-48.5T810-40H630q-12.75 0-21.375-8.675Q600-57.351 600-70.175 600-83 608.625-91.5T630-100h60Zm30-360q42 0 71-29t29-71v-100H620v100q0 42 29 71t71 29ZM620-700h200v-160H620v160ZM120-470v-60 60Zm0-240v-60 60Zm600 50Z"
		/>
	</svg>
);
