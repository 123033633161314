import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { t } from '@transifex/native';

import { useCollection } from 'pkg/api/use_collection';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';

import * as Input from 'components/form/inputs';
import Column from 'components/layout/column';
import ResourceCardItem from 'components/scheduling/resources';

import * as Context from 'design/context_menu';
import Button from 'design/button';

interface ResourceFormProps {
	groupId: number;
	selectedResources: number[];
	resources: models.resource.Resource[];

	selectResource: (resourceId: number) => void;
	showSelectResourceModal: () => void;
	showCategoryModal: () => void;
	showLocationModal: () => void;

	resource?: Partial<models.resource.Resource>;
}

const ResourceForm = ({
	groupId,
	selectedResources,
	resource,
	selectResource,
	showSelectResourceModal,
	showCategoryModal,
	showLocationModal,
	resources,
}: ResourceFormProps) => {
	const formContext = useFormContext();
	const category = formContext.watch('category');
	const location = formContext.watch('location');

	useEffect(() => {
		if (category === 'createCategory') {
			showCategoryModal();
		}
	}, [category]);

	useEffect(() => {
		if (location === 'createLocation') {
			showLocationModal();
		}
	}, [location]);

	const defaultValues = {
		title: resource ? resource.title : '',
		description: resource ? resource.description : '',
		location: resource ? resource.resourceLocationId : '',
		category: resource ? resource.resourceCategoryId : '',
		parentResourceId: resource ? resource.parentResourceId : '',
	};

	const hasParentSelected = selectedResources.length > 0;

	const {
		records: rc,
		isLoading: loadingCategories,
		refresh: refreshCategories,
	} = useCollection<models.resourceCategory.ResourceCategory>(
		endpoints.ResourceCategory.Index(),
		{
			queryParams: new URLSearchParams({
				group_id: groupId.toString(),
			}),
		}
	);

	const resourceCategories = rc.sort((a, b) => a.title.localeCompare(b.title));

	const {
		records: resourceLocations,
		isLoading: loadingLocations,
		refresh: refreshLocations,
	} = useCollection<models.resourceLocation.ResourceLocation>(
		endpoints.ResourceLocation.Index(),
		{
			queryParams: new URLSearchParams({
				group_id: groupId.toString(),
			}),
		}
	);

	useEffect(() => {
		if (resource.parentResourceId) {
			formContext.setValue('parentResourceId', resource.parentResourceId);
		}
	}, [resource.parentResourceId]);

	useEffect(() => {
		if (!!selectedResources[0]) {
			const parentResource = resources.find(
				(pr) => pr.id === selectedResources[0]
			);

			if (parentResource) {
				formContext.setValue('category', parentResource.resourceCategoryId);
			}
		}
	}, [selectedResources[0]]);

	useEffect(() => {
		if (resource.resourceLocationId !== 0) {
			refreshLocations();
			formContext.setValue('location', resource.resourceLocationId);
		}
	}, [resource.resourceLocationId]);

	useEffect(() => {
		if (resource.resourceCategoryId !== 0) {
			refreshCategories();
			formContext.setValue('category', resource.resourceCategoryId);
		}
	}, [resource.resourceCategoryId]);

	const handleRemoveResource = (resourceId: number) => {
		selectResource(resourceId);
	};

	return (
		<Column>
			<Input.Group label={t('Title')}>
				<Input.Field name="title" required defaultValue={defaultValues.title} />
			</Input.Group>
			<Input.Group label={t('Description')}>
				<Input.Area
					name="description"
					minRows={4}
					defaultValue={defaultValues.description}
				/>
			</Input.Group>
			<Input.Group label={t(`Parent resource`)}>
				{selectedResources.length > 0 && (
					<Column>
						{resources &&
							selectedResources.map((id) => (
								<ResourceCardItem
									key={id}
									resource={resources.find((r) => r.id === id)}
									actions={
										<Context.Menu toggleWith={<Button icon="more_horiz" />}>
											<Context.Item onClick={() => handleRemoveResource(id)}>
												<Context.ItemIcon name="cancel" />
												{t('Remove')}
											</Context.Item>
										</Context.Menu>
									}
								/>
							))}
					</Column>
				)}

				<Button
					icon="location_on"
					secondary
					large
					label={t(`Select resource`)}
					onClick={showSelectResourceModal}
				/>
			</Input.Group>
			{!loadingCategories && (
				<Input.Group label={t(`Resource category`)}>
					<Input.Select
						name="category"
						defaultValue={defaultValues.category}
						disabled={!!hasParentSelected}>
						<option value="">{t(`Select category`)}</option>
						<option value="createCategory">{t(`Create category`)}</option>
						{resourceCategories.map((item) => (
							<option key={item.id} value={item.id}>
								{item.title}
							</option>
						))}
					</Input.Select>
				</Input.Group>
			)}
			{!loadingLocations && (
				<Input.Group label={t(`Resource location`)}>
					<Input.Select name="location" defaultValue={defaultValues.location}>
						<option value="">{t(`Select location`)}</option>
						<option value="createLocation">{t(`Create location`)}</option>
						{resourceLocations.map((item) => (
							<option key={item.id} value={item.id}>
								{item.title}
							</option>
						))}
					</Input.Select>
				</Input.Group>
			)}
		</Column>
	);
};

export default ResourceForm;
