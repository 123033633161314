import { ReactNode } from 'react';

export const symbol_edit_road: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M690.182-498Q679-498 669.5-505.5 660-513 660-528v-242q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T720-770v242q0 15-9.318 22.5t-20.5 7.5ZM160-190v-580q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T220-770v580q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T160-190Zm250-484v-96q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T470-770v96q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T410-674Zm0 242v-96q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T470-528v96q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T410-432Zm0 242v-96q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T470-286v96q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T410-190Zm180 30q-12.75 0-21.375-8.625T560-190v-81q0-5.565 2-10.783Q564-287 569-292l211.612-210.773q9.113-9.12 20.25-13.174Q812-520 823-520q12 0 23 4.5t20 13.5l37 37q9 9 13 20t4 22q0 11-4.5 22.5t-13.583 20.624L692-169q-5 5-10.217 7-5.218 2-10.783 2h-81Zm270-263-37-37 37 37ZM620-220h38l121-122-18-19-19-18-122 121v38Zm141-141-19-18 37 37-18-19Z"
		/>
	</svg>
);
