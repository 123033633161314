import { fromEntries } from 'pkg/utils';

import emojiData from './emoji-data.json';
import slugifiedEmojiData from './emoji-data-flat.json';

export enum EmojiSkinToneVariant {
	None = 'none',
	Light = 'light',
	MediumLight = 'medium-light',
	Medium = 'medium',
	MediumDark = 'medium-dark',
	Dark = 'dark',
}

export interface EmojiDefinition {
	description: string;
	variants: {
		none: string;
		light?: string;
		'medium-light'?: string;
		medium?: string;
		'medium-dark'?: string;
		dark?: string;
	};
}

export interface EmojiDefinitionGroup {
	[key: string]: EmojiDefinition;
}

export function containsEmojis(raw: string): boolean {
	return /[\p{Extended_Pictographic}\u{1F3FB}-\u{1F3FF}\u{1F9B0}-\u{1F9B3}]/u.test(
		raw
	);
}

export function onlyEmojis(raw: string): boolean {
	return /^[\p{Extended_Pictographic}\u{1F3FB}-\u{1F3FF}\u{1F9B0}-\u{1F9B3}]{1,}$/u.test(
		raw
	);
}

export function filterEmojis(dirty: string): string {
	return dirty.replace(
		/(?![*#0-9]+)[\p{Emoji}\p{Emoji_Modifier}\p{Emoji_Component}\p{Emoji_Modifier_Base}\p{Emoji_Presentation}]/gu,
		''
	);
}

export function fromSlug(slug: string): string {
	// Override type to supress keyof-issues by TS linter
	const data: { [key: string]: string } = slugifiedEmojiData;

	return (data?.[slug] as string) || '';
}

export function toSlug(emoji: string): string {
	// Override type to supress keyof-issues by TS linter
	const data: { [key: string]: string } = slugifiedEmojiData;

	return Object.keys(data).find((key: string) => data[key] === emoji);
}

export function getFilteredEmojis(filter: string): EmojiDefinitionGroup {
	let allEmojis: EmojiDefinitionGroup = {};

	[...Object.values(emojiData)].flat().forEach((item: EmojiDefinitionGroup) => {
		allEmojis = { ...allEmojis, ...item };
	});

	return fromEntries(
		Object.entries(allEmojis).filter(([, emoji]) =>
			emoji.description.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
		)
	);
}
