import { ReactNode } from 'react';

export const symbol_variable_remove: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M150-280q-13 0-21.5-8.5T120-310v-340q0-13 8.5-21.5T150-680h660q13 0 21.5 8.5T840-650v111q0 13-8.5 21.5T810-509q-13 0-21.5-8.5T780-539v-81H180v280h401q13 0 21.5 8.5T611-310q0 13-8.5 21.5T581-280H150Zm30-60v-280 280Zm618 60-64 64q-9 9-21 9t-21-9q-9-9-9-21t9-21l64-64-64-64q-9-9-9-21t9-21q9-9 21-9t21 9l64 64 64-64q9-9 21-9t21 9q9 9 9 21t-9 21l-64 64 64 64q9 9 9 21t-9 21q-9 9-21 9t-21-9l-64-64Z"
		/>
	</svg>
);
