import styled from 'styled-components';

import * as styles from 'pkg/config/styles';

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: var(--spacing-3);
	font-size: var(--font-size-sm);
`;

const Title = styled.div`
	font-size: var(--font-size-sm);
	font-weight: var(--font-weight-semibold);
	margin-bottom: var(--spacing-4);
`;

const Item = styled.div<{ spaceBetween: boolean }>`
	display: grid;
	grid-template-columns: 150px 1fr;
	justify-items: start;

	@media all and (max-width: ${styles.breakpoint.toMedium}px) {
		grid-template-columns: 1fr;
	}
`;

const Name = styled.div`
	color: var(--palette-gray-500);
	display: flex;
	align-items: center;
`;

interface RowProps {
	translation?: string;
	spaceBetween?: boolean;
}

export const Row: React.FC<React.PropsWithChildren<RowProps>> = ({
	children,
	translation = '',
	spaceBetween,
}) => (
	<Item spaceBetween={spaceBetween}>
		<Name>{translation}</Name>
		{children}
	</Item>
);

interface InfoListProps {
	title?: string;
}

export const List: React.FC<React.PropsWithChildren<InfoListProps>> = ({
	title,
	children,
}) => {
	return (
		<div>
			{!!title && <Title>{title}</Title>}
			<Wrapper>{children}</Wrapper>
		</div>
	);
};
