// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gYEpyhNxkIbak0svjMaQ {
	font-size: var(--font-size-lg);
	line-height: var(--font-line-height-lg);
	font-weight: var(--font-weight-semibold);
	margin-bottom: var(--spacing-1);
}

.ZbqyFpGNHVCCEitvbcWM {
	font-size: var(--font-size-sm);
	line-height: var(--font-line-height-sm);
}

.PdvxP5x0me0A6NlkCEyw {
	margin-right: var(--spacing-3);
}

.mNgkgv2hB27q05mkqifN {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	margin-bottom: var(--spacing-1);
}

.KS8NkKQ9D1U46VT8ed9s {
	margin-right: var(--spacing-1);
}

.Rj0QMBxAqXlJ1tJi0JEW {
	margin-left: var(--spacing-1);
}
`, "",{"version":3,"sources":["webpack://./routes/payments/orders/single/side_bar/styles.css"],"names":[],"mappings":"AAAA;CACC,8BAA8B;CAC9B,uCAAuC;CACvC,wCAAwC;CACxC,+BAA+B;AAChC;;AAEA;CACC,8BAA8B;CAC9B,uCAAuC;AACxC;;AAEA;CACC,8BAA8B;AAC/B;;AAEA;CACC,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CACnB,wBAAuB;KAAvB,qBAAuB;SAAvB,uBAAuB;CACvB,+BAA+B;AAChC;;AAEA;CACC,8BAA8B;AAC/B;;AAEA;CACC,6BAA6B;AAC9B","sourcesContent":[".orderId {\n\tfont-size: var(--font-size-lg);\n\tline-height: var(--font-line-height-lg);\n\tfont-weight: var(--font-weight-semibold);\n\tmargin-bottom: var(--spacing-1);\n}\n\n.dateString {\n\tfont-size: var(--font-size-sm);\n\tline-height: var(--font-line-height-sm);\n}\n\n.price {\n\tmargin-right: var(--spacing-3);\n}\n\n.priceWrapper {\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\tmargin-bottom: var(--spacing-1);\n}\n\n.subscriptionIcon {\n\tmargin-right: var(--spacing-1);\n}\n\n.copyLink {\n\tmargin-left: var(--spacing-1);\n}\n"],"sourceRoot":""}]);
// Exports
export var orderId = `gYEpyhNxkIbak0svjMaQ`;
export var dateString = `ZbqyFpGNHVCCEitvbcWM`;
export var price = `PdvxP5x0me0A6NlkCEyw`;
export var priceWrapper = `mNgkgv2hB27q05mkqifN`;
export var subscriptionIcon = `KS8NkKQ9D1U46VT8ed9s`;
export var copyLink = `Rj0QMBxAqXlJ1tJi0JEW`;
export default ___CSS_LOADER_EXPORT___;
