import { ReactNode } from 'react';

export const symbol_cancel_presentation: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M438-480 337-379q-9 9-9 21t9 21q9 9 21 9t21-9l101-101 101 101q9 9 21 9t21-9q9-9 9-21t-9-21L522-480l101-101q9-9 9-21t-9-21q-9-9-21-9t-21 9L480-522 379-623q-9-9-21-9t-21 9q-9 9-9 21t9 21l101 101ZM140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm0-60h680v-520H140v520Zm0 0v-520 520Z"
		/>
	</svg>
);
