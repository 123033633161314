import { t } from '@transifex/native';

import * as flashActions from 'pkg/actions/flashes';

import * as sdk from 'pkg/core/sdk';
import * as models from 'pkg/api/models';
import { Record } from 'pkg/api/models/record';
import * as endpoints from 'pkg/api/endpoints/auto';

export interface ChatUser extends Record {
	chatId: number;
	isAdmin: boolean;
	lastReadMessageId: number;
	hasMuted: boolean;
	hasArchived: boolean;
	notifications: boolean;
	userId: number;
	deletedAt?: number;
	createdAt: number;

	user: models.user.User;
}

export interface CreateChatUserPayload {
	userId: number;
	isAdmin?: boolean;
	hasMuted?: boolean;
}

interface UpdateChatUserPayload {
	isAdmin?: boolean;
	hasMuted?: boolean;
	hasArchived?: boolean;
	lastReadMessageId?: number;
}

export async function update(
	chat: models.chat.Chat,
	chatUser: ChatUser,
	payload: UpdateChatUserPayload
): Promise<[boolean, ChatUser]> {
	const request = await sdk.patch(
		endpoints.Chat.UpdateUser(chat.id, chatUser.userId),
		{},
		payload
	);

	if (request.ok) {
		const response = await request.json();

		return [true, response];
	}

	return [false, null];
}

export async function remove(chat: models.chat.Chat, chatUser: ChatUser) {
	const response = await sdk.destroy(
		endpoints.Chat.DeleteUser(chat.id, chatUser.userId)
	);

	if (!response.ok) {
		const flashTitle = t('Could not leave chat');

		flashActions.show({ title: flashTitle }, response.status);

		return false;
	}

	return true;
}

export function filterDeleted(chatUsers: ChatUser[]) {
	return chatUsers.filter((cu) => !cu.deletedAt);
}
