import { memo } from 'react';
import styled, { css } from 'styled-components';

import * as styles from 'pkg/config/styles';

import rgba from 'pkg/rgba';

import Icon from 'components/icon';

import * as iconStyles from 'components/icon/styles.css';

const CheckBoxWrap = styled.div`
	input[type='checkbox'] {
		display: none;
	}

	@media (hover: hover) {
		&:hover {
			background-color: ${rgba(styles.palette.gray[200], 0.3)};
		}
	}
`;

const Row = styled.div<{ standalone?: boolean }>`
	display: grid;
	grid-template-columns: auto 1fr;
	grid-template-areas:
		'icon label'
		'. description';
	align-items: center;

	${({ standalone }) =>
		standalone &&
		css`
			grid-template-areas: 'icon';
		`}

	div:first-child {
		grid-area: label;
	}

	@media (hover: hover) {
		&:hover {
			cursor: pointer;
		}
	}
`;

const Description = styled.div`
	grid-area: description;
	color: var(--palette-gray-500);
	font-size: var(--font-size-sm);
	font-weight: 400;
`;

const IconWrap = styled.span<{ disabled?: boolean; selected?: boolean }>`
	box-shadow: inset 0 0 0 1px var(--palette-gray-400);
	border-radius: var(--radius-3);
	transition: all 20ms ease-in-out;
	height: 20px;
	width: 20px;
	margin-right: 7px;

	.${iconStyles.icon} {
		width: 100%;
		height: 100%;
		opacity: 0;
		transition: opacity 20ms ease-in-out
			${({ disabled }) =>
				disabled
					? css`
							color: var(--palette-gray-400);
						`
					: css`
							color: ${rgba(styles.palette.main.actionableLinkColor)};
						`};
	}

	${({ selected, disabled }) =>
		selected &&
		css`
			${disabled
				? css`
						color: var(--palette-gray-400);
						box-shadow: inset 0 0 0 3px var(--palette-gray-400);
						cursor: not-allowed;
					`
				: css`
						color: ${rgba(styles.palette.main.actionableLinkColor)};
						box-shadow: inset 0 0 0 3px
							${rgba(styles.palette.main.actionableLinkColor)};
					`}

			.${iconStyles.icon} {
				opacity: 1;
			}
		`};
`;

interface Props {
	id?: string;
	label?: string | JSX.Element;
	name?: string;
	value?: string | number | readonly string[];
	selected: boolean;
	standalone?: boolean;
	disabled?: boolean;
	description?: string;
	className?: string;
	onClick?: () => void;
}

const CheckBox: React.FC<React.PropsWithChildren<Props>> = memo((props) => (
	<CheckBoxWrap className={props.className}>
		<input
			type="checkbox"
			id={props.id}
			name={props.name}
			value={props.value}
			onClick={props.onClick}
		/>
		<label htmlFor={props.id}>
			<Row standalone={props.standalone}>
				<IconWrap selected={props.selected} disabled={props.disabled}>
					<Icon name="checkbox-checked" />
				</IconWrap>
				<div>{props.label}</div>
				{props.description && <Description>{props.description}</Description>}
			</Row>
		</label>
	</CheckBoxWrap>
));

CheckBox.defaultProps = {
	value: 0,
	selected: false,
	description: '',
	onClick: () => null,
};

export default styled(CheckBox)``;
