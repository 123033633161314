import styled from 'styled-components';
import {
	ChangeEvent,
	ReactNode,
	Fragment,
	useState,
	useEffect,
	useRef,
} from 'react';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import Tag from 'pkg/models/tag';

import * as actions from 'pkg/actions';
import { getEndsAt, getStartsAt, VideoSequenceRecordingId } from 'pkg/video';
import useMixedState from 'pkg/hooks/useMixedState';
import { formatTime } from 'pkg/timeline';
import useConfirm from 'pkg/hooks/useConfirm';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { useDebounce } from 'pkg/timings';
import { RootState } from 'pkg/reducers';
import * as selectors from 'pkg/selectors';
import { useEventListener } from 'pkg/hooks/events';
import { msToSeconds } from 'pkg/date';

import { useTimelineOffset } from 'routes/video/shared/hooks/timeline';
import {
	useClipActions,
	useClipChanges,
} from 'routes/video/shared/hooks/clip-actions';
import UserTagsForm from 'routes/video/shared/forms/UserTags';
import RecordButton from 'routes/video/shared/RecordButton';
import useClipRecording from 'routes/video/shared/hooks/use-clip-recording';
import useTaggedUsers, {
	UserTag,
} from 'routes/video/shared/hooks/use-tagged-users';
import { getSymbol, ToolSymbol } from 'routes/video/analyze/tool-symbols';
import Tags from 'routes/video/shared/Tags';

import TextOverflow from 'components/TextOverflow';
import ToggleSwitch from 'components/Toggle';
import Icon from 'components/icon';
import Label from 'components/label';
import Modal, { Step } from 'components/step-modal';
import Avatar from 'components/avatar';

import * as labelStyles from 'components/label/styles.css';
import * as Input from 'components/form/inputs';
import { useCueState } from 'components/video-analytics/CueState';
import { usePlayerState } from 'components/video-analytics/PlayerState';
import { useClipState } from 'components/video-analytics/ClipState';
import Column from 'components/layout/column';
import Row from 'components/layout/row';
import {
	getAnnotationName,
	getAnnotationPreferenceName,
} from 'components/annotations/utils';
import { Annotation, Keyframe } from 'components/annotations/AnnotationHooks';
import {
	toolConfig,
	ToolPreference,
	ToolPreferences,
} from 'components/annotations/tools/ToolConfig';
import RangeSlider from 'components/form/RangeSlider';
import AutoComplete, {
	AutoCompleteSuggestions,
} from 'components/form/inputs/autocomplete';

import Button from 'design/button';

const headerHeight = 50;

const Wrapper = styled.div``;

const Header = styled.div`
	padding: 0 var(--spacing-3);
	padding-left: var(--spacing-5);
	background-color: var(--palette-gray-700);
	border-radius: 5px 5px 0 0;
	height: ${headerHeight}px;
	position: sticky;
	z-index: 120;
	top: 0px;
	display: grid;
	grid-template-columns: 1fr repeat(3, auto);
	align-items: center;
	gap: var(--spacing-3);
`;

const Container = styled.div``;

const GroupWrapper = styled.div`
	background-color: var(--palette-gray-800);

	&:last-child {
		border-radius: 0 0 5px 5px;
	}
`;

const Heading = styled.div`
	padding: var(--spacing-4);
	background-color: var(--palette-gray-800);
	position: sticky;
	top: ${headerHeight}px;
	display: grid;
	height: 53px;
	grid-template-columns: 20px 1fr auto;
	align-items: center;
	font-weight: var(--font-weight-bold);
	box-shadow: inset 0 1px 0 0 var(--palette-gray-900);
	cursor: pointer;
	z-index: 110;

	.${labelStyles.label} {
		transform: translate(14px, -1px);
	}
`;

const LabelWrapper = styled.div`
	display: flex;
`;

const Contents = styled(motion.div)`
	overflow: hidden;

	> div {
		padding: var(--spacing-4);
		padding-top: var(--spacing-2);
		padding-bottom: var(--spacing-7);
	}
`;

const Toggle = styled(Icon)`
	transition: transform 250ms ease-in-out;

	&[data-is-open='true'] {
		transform: rotate(90deg);
	}
`;

const TimestampWrapper = styled.div`
	padding: var(--spacing-4);
	font-size: var(--font-size-sm);
	background: var(--palette-gray-700);
	color: var(--palette-gray-500);
	border-radius: var(--radius-3);
`;

const _context = 'video/annotations';

function Timestamps(): JSX.Element {
	const clipState = useClipState();

	return (
		<TimestampWrapper>
			{t(
				'Clip is {duration} seconds long, starts at {startsAt} and ends at {endsAt}.',
				{
					duration: clipState.getDuration(),
					startsAt: formatTime(clipState.getStartsAt()),
					endsAt: formatTime(clipState.getEndsAt()),
				}
			)}
		</TimestampWrapper>
	);
}

interface GroupProps {
	open: boolean;
	label: string;
	isBetaFeature?: boolean;
	onToggle?: (open: boolean) => void;
	action?: ReactNode;
	children: ReactNode | ReactNode[];
}

function Group({
	open,
	label,
	isBetaFeature,
	onToggle,
	action,
	children,
}: GroupProps): JSX.Element {
	const handleToggle = () => {
		if (onToggle) {
			onToggle(!open);
		}
	};

	const variant = open ? 'open' : 'collapsed';

	const variants = {
		open: {
			height: 'auto',
		},
		collapsed: {
			height: 0,
		},
	};

	return (
		<GroupWrapper>
			<Heading onClick={handleToggle}>
				<Toggle name="chevron" data-is-open={open} />
				<LabelWrapper>
					{label}
					{isBetaFeature && <Label color="gray">{t('Beta')}</Label>}
				</LabelWrapper>
				{action || <span />}
			</Heading>
			<Contents
				variants={variants}
				initial={variant}
				animate={variant}
				transition={{ ease: 'easeInOut' }}>
				<div>{children}</div>
			</Contents>
		</GroupWrapper>
	);
}

const FormItem = styled(Column)`
	background: var(--palette-gray-700);
	padding: var(--spacing-3);
	border-radius: var(--radius-3);
	cursor: pointer;
	transition: box-shadow 150ms ease-in-out;
	box-shadow: 0 0 0 1px var(--palette-gray-700);

	@media (hover: hover) {
		&:hover {
			box-shadow: 0 0 0 1px var(--palette-gray-500);
		}
	}

	&[data-active='true'] {
		box-shadow: 0 0 0 1px var(--palette-blue-500);
	}
`;

const FormRowActions = styled(Row)`
	margin-right: var(--spacing-3);
	width: auto;
	min-width: 0;
`;

const FormRowAction = styled(Icon)`
	padding: var(--spacing-1);
	cursor: pointer;
	color: var(--palette-gray-500);
	border-radius: var(--radius-2);

	@media (hover: hover) {
		&:hover {
			background: var(--palette-gray-800);
			color: var(--palette-white);
		}
	}
`;

const AnnotationPreferences = styled.div`
	display: grid;
	grid-auto-rows: auto;
	gap: var(--spacing-3);
`;

const PreferenceRow = styled.div`
	padding: var(--spacing-2);
	display: grid;
	grid-template-columns: auto 1fr;
	align-items: center;
	justify-items: end;
	gap: var(--spacing-5);
	font-size: var(--font-size-sm);
`;

const VisibilityItem = styled(Row)`
	background: var(--palette-gray-700);
	padding: var(--spacing-3);
	border-radius: var(--radius-3);
	cursor: pointer;
	transition: box-shadow 150ms ease-in-out;
	box-shadow: 0 0 0 1px var(--palette-gray-700);

	@media (hover: hover) {
		&:hover {
			box-shadow: 0 0 0 1px var(--palette-gray-500);
		}
	}

	&[data-active='true'] {
		box-shadow: 0 0 0 1px var(--palette-blue-500);
	}
	cursor: pointer;
	font-size: var(--font-size-sm);

	div:last-child {
		transform: scale(0.85) translateX(2px);
	}
`;

const Comment = styled.div`
	margin-top: var(--spacing-3);
	padding-top: var(--spacing-3);
	border-top: 1px solid var(--palette-gray-600);
	color: var(--palette-gray-500);
	font-size: var(--font-size-sm);
`;

const Instruction = styled.div`
	padding: var(--spacing-4);
	font-size: var(--font-size-sm);
	background: var(--palette-gray-700);
	color: var(--palette-gray-500);
	border-radius: var(--radius-3);
`;

interface GroupState {
	clipInfo: boolean;
	userTags: boolean;
	annotations: boolean;
	timecontrol: boolean;
	tags: boolean;
}

interface UserTagRowProps {
	userTag: UserTag;
	onDelete: (userId: number) => void;
	onCommentChange: (userId: number, comment: string) => void;
}

function UserTagRow({
	userTag,
	onDelete,
	onCommentChange,
}: UserTagRowProps): JSX.Element {
	const [isEditing, setEditing] = useState<boolean>(false);

	const edit = () => setEditing(true);
	const save = () => setEditing(false);
	const remove = () => onDelete(userTag.user.id);

	const observeKeys = (event: KeyboardEvent) => {
		switch (event.key) {
			case 'Enter':
				save();
				break;
		}
	};

	const observeChange = (event: ChangeEvent<HTMLInputElement>) => {
		onCommentChange(userTag.user.id, event.target.value);
	};

	const handleRemove = useConfirm({
		message: t('Untag {name}?', {
			_context,
			name: userTag.user.fullName,
		}),
		onConfirm: remove,
	});

	return (
		<FormItem spacing={styles.spacing._1}>
			<Row columns="24px 1fr auto" align="center">
				<Avatar user={userTag.user} size={24} />
				<TextOverflow>{userTag.user.fullName}</TextOverflow>
				<FormRowActions>
					{!isEditing ? (
						<FormRowAction name="edit" size={1.8} onClick={edit} />
					) : (
						<FormRowAction name="check-circle" size={1.8} onClick={save} />
					)}
					<FormRowAction name="delete" size={1.8} onClick={handleRemove} />
				</FormRowActions>
			</Row>
			{(isEditing || userTag.comment) && (
				<Comment>
					{!isEditing ? (
						<TextOverflow maxLines={3}>{userTag.comment}</TextOverflow>
					) : (
						<Input.Field
							autoFocus
							defaultValue={userTag.comment}
							onChange={observeChange}
							onKeyDown={observeKeys}
						/>
					)}
				</Comment>
			)}
		</FormItem>
	);
}

type PreferenceValueType = string;

interface PreferenceProps {
	tool: string;
	annotation: Annotation;
	preference: ToolPreference;
	onPreferenceChange: (changes: Partial<Annotation>) => void;
}

function Preference({
	tool,
	annotation,
	preference,
	onPreferenceChange,
}: PreferenceProps): JSX.Element {
	const [value, setValue] = useState<PreferenceValueType>('');

	useComponentDidMount(() => {
		const storedValue = annotation[tool as keyof Annotation];
		switch (preference.input) {
			case 'text':
				setValue((storedValue || preference.defaultValue) as string);
				break;
			case 'colorPicker':
				setValue((storedValue || preference.defaultValue) as string);
				break;
			case 'range':
				setValue((storedValue || preference.defaultValue) as string);
				break;
			case 'toggle':
				setValue(
					(storedValue !== undefined
						? storedValue
						: preference.defaultValue) as string
				);
				break;
		}
	});

	const updateProperty = useDebounce((value: PreferenceValueType) => {
		const changes: Partial<Annotation> = {
			[tool]: value,
		};

		onPreferenceChange(changes);
	}, 350);

	const handleChange = (nextValue: PreferenceValueType) => {
		setValue(nextValue);
		updateProperty(nextValue);

		// store last color choice and use as default when adding new annotations (requested by Mats)
		if (preference.input === 'colorPicker') {
			window.localStorage.setItem(
				`annotation_${annotation.tool}_${tool}`,
				nextValue
			);
		}
	};

	const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
		handleChange(event.target.value as string);
	};

	const handleToggleChange = () => {
		handleChange((!!value as boolean) ? '' : '1');
	};

	switch (preference.input) {
		case 'text':
			return <Input.Field value={value} onChange={handleTextChange} />;
		case 'colorPicker':
			return <Input.ColorPicker value={value} onChange={handleChange} />;
		case 'range':
			return (
				<RangeSlider
					min={preference.min}
					max={preference.max}
					step={preference.step}
					value={value ? Number.parseFloat(value) : 0}
					onChange={handleChange}
					trackColor={styles.palette.gray[600]}
					progressColor={styles.palette.blue[500]}
				/>
			);
		case 'toggle':
			return <ToggleSwitch active={!!value} onClick={handleToggleChange} />;
		default:
			return null;
	}
}

interface AnnotationRowProps {
	annotationId: string;
	annotation: Annotation;
	keyframe?: Keyframe;
	index?: number;

	title: string;
	isActive: boolean;

	onActivate: (annotationId: string, index?: number) => void;
	onDelete: (annotationId: string, index?: number) => void;
	onUpdate: (
		annotationId: string,
		changes: Partial<Annotation>,
		index?: number
	) => void;
}

const useScrollOnActive = (el: HTMLDivElement, isActive: boolean) => {
	useEffect(() => {
		if (el && isActive) el.scrollIntoView({ behavior: 'smooth' });
	}, [el, isActive]);
};

function AnnotationRow({
	annotationId,
	annotation,

	title,
	isActive,

	onActivate,
	onUpdate,
	onDelete,
}: AnnotationRowProps) {
	const el = useRef<HTMLDivElement>();
	useScrollOnActive(el.current, isActive);

	const activate = () => onActivate(annotationId);

	const remove = useConfirm({
		message: t('Remove this annotation?', { _context }),
		onConfirm: () => onDelete(annotationId),
	});

	const update = (changes: Partial<Annotation>) => {
		onUpdate(annotationId, changes);
	};

	const preferences = toolConfig.hasOwnProperty(annotation.tool)
		? toolConfig[annotation.tool].preferences
		: null;

	return (
		<FormItem ref={el} data-active={isActive} onClick={activate}>
			<Row columns="24px 1fr auto" align="center">
				{getSymbol(annotation.tool as ToolSymbol)}
				<span>{title}</span>
				<FormRowActions>
					<FormRowAction
						data-testid="annotations.item.remove"
						size={1.8}
						name="delete"
						onClick={remove}
					/>
				</FormRowActions>
			</Row>
			{isActive && preferences && (
				<AnnotationPreferences>
					{Object.entries(preferences)
						.filter(([, preference]: [string, ToolPreference]) => {
							const value =
								annotation[preference.condition as keyof Annotation];

							const defaultValue =
								preference.condition &&
								preferences[preference.condition as keyof ToolPreferences]
									.defaultValue;

							const falseValue =
								annotation[preference.conditionFalse as keyof Annotation];

							const defaultValueFalse =
								preference.conditionFalse &&
								preferences[preference.conditionFalse as keyof ToolPreferences]
									.defaultValue;

							return (
								!preference.hidden &&
								(!preference.condition ||
									(value !== undefined ? value : defaultValue)) &&
								(!preference.conditionFalse ||
									(falseValue !== undefined ? !falseValue : !defaultValueFalse))
							);
						})
						.map(([tool, preference]: [string, ToolPreference]) => (
							<PreferenceRow key={tool}>
								<span>{getAnnotationPreferenceName(tool)}</span>
								<Preference
									tool={tool}
									annotation={annotation}
									preference={preference}
									onPreferenceChange={update}
								/>
							</PreferenceRow>
						))}
				</AnnotationPreferences>
			)}
		</FormItem>
	);
}

function PlaybackRow({
	annotationId,
	keyframe,
	index,

	title,
	isActive,

	onActivate,
	onUpdate,
	onDelete,
}: AnnotationRowProps) {
	const el = useRef<HTMLDivElement>();
	useScrollOnActive(el.current, isActive);
	const activate = () => onActivate(annotationId, index);

	const remove = useConfirm({
		message: t('Remove this annotation?', { _context }),
		onConfirm: () => onDelete('playback', index),
	});

	const update = (event: ChangeEvent<HTMLInputElement>) => {
		const ms = +event.target.value * 1000;
		onUpdate('playback', { keyframes: [{ ...keyframe, duration: ms }] }, index);
	};

	return (
		<FormItem ref={el} data-active={isActive} onClick={activate}>
			<Row columns="24px 1fr auto" align="center">
				{keyframe.action === 'pause' ? (
					<Icon name="av-pause" size={1.2} />
				) : (
					<Icon name="av-playback-speed" size={1.2} />
				)}
				<span>{title}</span>
				<FormRowActions>
					<FormRowAction
						data-testid="annotations.item.remove"
						size={1.8}
						name="delete"
						onClick={remove}
					/>
				</FormRowActions>
			</Row>
			{isActive && (
				<AnnotationPreferences>
					<PreferenceRow>
						<span>
							{keyframe.action === 'pause'
								? t('Resume', { _context })
								: t('Duration', { _context })}
						</span>
						<Input.Field
							type="number"
							value={keyframe.duration ? keyframe.duration / 1000 : 0}
							min={0}
							step="0.1"
							onChange={update}
						/>
					</PreferenceRow>
					{keyframe.action === 'pause' && (
						<PreferenceRow>
							{t('Set duration to 0 seconds to disable automatic resume.', {
								_context,
							})}
						</PreferenceRow>
					)}
				</AnnotationPreferences>
			)}
		</FormItem>
	);
}

interface AnnotationsProps {
	type: string;
}

function Annotations({ type }: AnnotationsProps): JSX.Element {
	const clipState = useClipState();

	const annotations = clipState.getParsedAnnotations();

	const { controller } = usePlayerState();
	const timelineOffset = useTimelineOffset();

	const handleActivate = (annotationId: string) => {
		clipState.setActiveAnnotation(annotationId);

		const annotation = clipState.getAnnotation(annotationId);
		const firstKeyframe = annotation.keyframes[0];

		if (firstKeyframe) {
			controller.seekTo(msToSeconds(firstKeyframe.time));
			timelineOffset.set(msToSeconds(firstKeyframe.time));
		}
	};

	const handleUpdate = (annotationId: string, changes: Partial<Annotation>) => {
		const annotation = clipState.getAnnotation(annotationId);

		clipState.setAnnotation(annotationId, {
			...annotation,
			...changes,
		});
	};

	const handleDelete = (annotationId: string) => {
		clipState.removeAnnotation(annotationId);
	};

	const handleActivatePlayback = (annotationId: string, index: number) => {
		clipState.setActiveAnnotation(null);
		clipState.setActiveKeyframe(index);

		const annotation = clipState.getAnnotation('playback');
		const keyframe = annotation.keyframes[index];

		if (keyframe) {
			controller.seekTo(msToSeconds(keyframe.time));
			timelineOffset.set(msToSeconds(keyframe.time));
		}
	};

	const handleDeletePlayback = (annotationId: string, index: number) => {
		const keyframes = [...(annotations.playback?.keyframes || [])];
		keyframes.splice(index, 1);
		clipState.setAnnotation('playback', { ...annotations.playback, keyframes });
		clipState.setActiveKeyframe(null);
	};

	const handleUpdatePlayback = (
		annotationId: string,
		changes: Partial<Annotation>,
		index: number
	) => {
		const keyframes = [...(annotations.playback?.keyframes || [])];
		keyframes[index].duration = changes.keyframes[0].duration;
		clipState.setAnnotation(annotationId, {
			...annotations.playback,
			keyframes,
		});
	};

	return (
		<Fragment>
			{Object.entries(annotations).map(([annotationId, annotation]) => {
				if (annotation.tool && type === 'tool') {
					return (
						<AnnotationRow
							key={annotationId}
							annotationId={annotationId}
							annotation={annotation}
							title={getAnnotationName(annotation.tool)}
							isActive={annotationId === clipState.activeAnnotationId}
							onActivate={handleActivate}
							onUpdate={handleUpdate}
							onDelete={handleDelete}
						/>
					);
				}
				if (!annotation.tool && type === 'playback') {
					return annotation.keyframes
						.map((keyframe, index) => ({ index, keyframe }))
						.sort((a, b) => (a.keyframe.time > b.keyframe.time ? 1 : -1))
						.map(({ keyframe, index }) => (
							<PlaybackRow
								key={annotationId + index}
								index={index}
								annotationId={annotationId}
								annotation={annotation}
								keyframe={keyframe}
								title={
									keyframe.action === 'pause'
										? t('Pause', { _context })
										: keyframe.speed > 1
											? t('High speed', { _context })
											: t('Slowmotion', { _context })
								}
								isActive={
									!clipState.activeAnnotationId &&
									index === clipState.activeKeyframeIndex
								}
								onActivate={handleActivatePlayback}
								onUpdate={handleUpdatePlayback}
								onDelete={handleDeletePlayback}
							/>
						));
				}
			})}
		</Fragment>
	);
}

interface LargeScreenAnnotationsFormProps {
	onSave?: () => void;
}

export default function LargeScreenAnnotationsForm({
	onSave,
}: LargeScreenAnnotationsFormProps): JSX.Element {
	const dispatch = useDispatch();
	const { isEditing, isRecording, justRecorded } = usePlayerState();

	const { currentCue } = useCueState();
	const clipState = useClipState();

	const clipId = isRecording
		? VideoSequenceRecordingId
		: (currentCue?.cueId as number);

	const { cancel, destroy } = useClipActions(clipId);
	const { save } = useClipChanges(clipId);
	const record = useClipRecording();

	const comments = useSelector((state: RootState) =>
		selectors.videoSequenceUsers.findAllComments(state, clipId)
	);

	const clip = useSelector((state: RootState) =>
		selectors.videoSequences.find(state, clipId)
	);

	const persistentTags = useSelector((state: RootState) =>
		selectors.tags.findAllByIds(state, clip?.tags || [])
	);

	const persistentTagStrings = persistentTags
		?.map((tag: Tag) => tag.name)
		.toArray();

	useEffect(() => {
		const startsAt = getStartsAt(currentCue);
		const endsAt = getEndsAt(currentCue);

		if (!isRecording) {
			clipState.setStartsAt(startsAt);
			clipState.setEndsAt(endsAt);
		}

		clipState.setTaggedUsers(comments);
	}, [clipId]);

	const groupState: GroupState = {
		clipInfo: true,
		userTags: false,
		annotations: false,
		timecontrol: false,
		tags: false,
	};

	const setUserComment = (userId: number, comment: string) => {
		if (comment === '') return;

		clipState.setTaggedUsers({
			...clipState.taggedUsers,
			[userId]: comment,
		});
	};

	const removeUserTag = (userId: number) => {
		const nextTaggedUsers = clipState.taggedUsers;
		delete nextTaggedUsers[userId];

		clipState.setTaggedUsers(nextTaggedUsers);
	};

	const taggedUsers = useTaggedUsers();
	const [userTagsModalOpen, setUserTagsModalOpen] = useState<boolean>(false);
	const [clipTags, setClipTags] = useState<string[]>(
		persistentTagStrings || []
	);

	const closeUserTagsModal = () => setUserTagsModalOpen(false);

	const [
		{ clipInfo, userTags, annotations, timecontrol, tags },
		setMixedState,
	] = useMixedState<GroupState>(groupState);

	const parsedAnnotations = clipState.getParsedAnnotations();
	const playbackKeyframes = parsedAnnotations?.playback?.keyframes || [];

	useEffect(() => {
		setMixedState({
			userTags: clipState.numTaggedUsers > 0,
		});
	}, [clipState.numTaggedUsers]);

	useEffect(() => {
		setMixedState({
			annotations: clipState.numAnnotations > 0,
			timecontrol: playbackKeyframes.length > 0,
		});
	}, [clipState.numAnnotations, playbackKeyframes.length]);

	const handleSave = async () => {
		const deleteTagIds = persistentTags
			.filter((tag: Tag) => !clipTags.includes(tag.name))
			.map((tag: Tag) => tag.id);

		if (deleteTagIds.size > 0) {
			await Promise.all(
				deleteTagIds.map((tagId: number) =>
					actions.videoSequences.deleteTag(clipId, tagId)
				)
			);
		}

		save({
			tags: clipTags,
		});

		if (onSave) {
			onSave();
		}
	};

	useEventListener('video-save', handleSave);

	const handleSelectTag = (tag: Tag) => {
		setClipTags((tags) => [...new Set([...tags, tag.name])]);
	};

	const handleSelect = (inputValue: string) => {
		setClipTags((tags) => [...new Set([...tags, inputValue])]);
	};

	const handleSelectPredefinedTag = (tag: string) => {
		setClipTags((tags) => [...new Set([...tags, tag])]);
	};

	const handleRemoveTag = (tag: string) => {
		setClipTags((tags) => tags.filter((t: string) => t !== tag));
	};

	const handleSearchTag = async (
		keyword: string
	): Promise<AutoCompleteSuggestions> => {
		if (keyword) {
			const result: any = await dispatch(actions.tags.searchTag(keyword));
			const items: Tag[] = Object.values(result?.entities.tags || {});

			return {
				[t('Tags')]: {
					items,
					renderWith: (item: Tag) => (
						<Fragment>{item.name.replace(/\_/g, ' ')}</Fragment>
					),
				},
			} as AutoCompleteSuggestions;
		}

		return Promise.resolve({});
	};

	const setTitle = (event: ChangeEvent<HTMLInputElement>) =>
		clipState.setTitle(event.target.value);

	const setDescription = (event: ChangeEvent<HTMLInputElement>) =>
		clipState.setDescription(event.target.value);

	const setReviewed = () => clipState.setIsHighlight(!clipState.reviewed);

	const setPrivate = () => clipState.setIsPrivate(!clipState.private);

	const showUserTagsModal = (event: PointerEvent) => {
		event.stopPropagation();

		if (!userTags) {
			setMixedState({ userTags: true });
		}

		setUserTagsModalOpen(true);
	};

	const handleDestroy = useConfirm({
		message: t('Remove clip "{name}"? This action cannot be undone.', {
			name: clipState.title,
		}),
		onConfirm: destroy,
	});

	if (!isEditing || !clipId) return null;

	let commitButton = (
		<Button
			small
			primary
			onClick={handleSave}
			testid="video.clip_form.save_button">
			{t('Save')}
		</Button>
	);

	if (isRecording && !justRecorded) {
		commitButton = (
			<RecordButton small onClick={record.stop}>
				{t('Stop recording')}
			</RecordButton>
		);
	}

	return (
		<Fragment>
			<Wrapper>
				<Header>
					<strong>{isRecording ? t('Save as…') : t('Edit')}</strong>
					{isRecording ? (
						<span />
					) : (
						<Button small transparent icon="delete" onClick={handleDestroy} />
					)}
					<Button small transparent onClick={cancel}>
						{t('Cancel')}
					</Button>
					{commitButton}
				</Header>
				<Container>
					<Group
						open={clipInfo}
						label={t('Clip info')}
						onToggle={(open: boolean) => setMixedState({ clipInfo: open })}>
						<Column>
							<Input.Group label={t('Clip name')}>
								<Input.Field
									name="name"
									value={clipState.title}
									onChange={setTitle}
									placeholder={t('Clip name')}
									testid="video.clip_form.title_field"
								/>
							</Input.Group>

							<Input.Group label={t('Description')}>
								<Input.Area
									minRows={2}
									maxRows={5}
									name="description"
									value={clipState.description}
									onChange={setDescription}
									placeholder={t('Description')}
									testid="video.clip_form.description_field"
								/>
							</Input.Group>

							<Column>
								<strong>{t('Visibility options')}</strong>
								<Row>
									<VisibilityItem
										align="center"
										columns="1fr 34px"
										spacing={styles.spacing._5}
										onClick={setReviewed}>
										<Row
											align="center"
											columns="16px 1fr"
											spacing={styles.spacing._1}>
											<Icon name="star" />
											<TextOverflow>{t('Highlight')}</TextOverflow>
										</Row>
										<Input.Control
											standalone
											type="toggle"
											checked={clipState.reviewed}
										/>
									</VisibilityItem>

									<VisibilityItem
										align="center"
										columns="1fr 34px"
										spacing={styles.spacing._5}
										onClick={setPrivate}>
										<Row
											align="center"
											columns="16px 1fr"
											spacing={styles.spacing._1}>
											<Icon name="eye-hidden" />
											<TextOverflow>{t('Private')}</TextOverflow>
										</Row>
										<Input.Control
											standalone
											type="toggle"
											checked={clipState.private}
										/>
									</VisibilityItem>
								</Row>
							</Column>

							<Timestamps />
						</Column>
					</Group>
					<Group
						open={userTags}
						label={t('Tagged users ({num})', {
							num: clipState.numTaggedUsers,
						})}
						onToggle={(open: boolean) => {
							if (clipState.numTaggedUsers !== 0) {
								setMixedState({ userTags: open });
							}
						}}
						action={
							<Button
								small
								icon="person_add"
								iconSize={1.3}
								onClick={showUserTagsModal}
							/>
						}>
						<Column spacing={styles.spacing._2}>
							{Array.from(taggedUsers).map((userTag: UserTag) => (
								<UserTagRow
									key={userTag.user.id}
									userTag={userTag}
									onCommentChange={setUserComment}
									onDelete={removeUserTag}
								/>
							))}
						</Column>
					</Group>

					<Group
						open={tags}
						label={t('Tags ({num})', {
							num: clipTags.length || persistentTags.size,
						})}
						onToggle={(open: boolean) => setMixedState({ tags: open })}>
						<Column spacing={styles.spacing._4}>
							<AutoComplete
								inline
								suggestions={{
									[t('Tags')]: {
										onSelect: handleSelectTag,
										renderWith: (item: Tag) => (
											<Fragment>{item.name.replace(/\_/g, ' ')}</Fragment>
										),
									},
								}}
								onSelectFallback={handleSelect}
								onDidType={handleSearchTag}>
								<Input.Prefix inline>
									<Icon name="tag" size={1.35} />
								</Input.Prefix>
							</AutoComplete>
							<Tags
								tags={clipTags}
								onAdd={handleSelectPredefinedTag}
								onRemove={handleRemoveTag}
							/>
						</Column>
					</Group>

					<Group
						isBetaFeature
						open={annotations}
						label={t('Annotations ({num})', {
							num: clipState.numAnnotations,
						})}
						onToggle={(open: boolean) => setMixedState({ annotations: open })}>
						<Column spacing={styles.spacing._2}>
							{clipState.numAnnotations ? (
								<Annotations type="tool" />
							) : (
								<Instruction>
									{t(
										'Add drawings and animations from the toolbar on the left side of the video.'
									)}
								</Instruction>
							)}
						</Column>
					</Group>
					<Group
						isBetaFeature
						open={timecontrol}
						label={t('Time control ({num})', {
							num: playbackKeyframes.length,
						})}
						onToggle={(open: boolean) => setMixedState({ timecontrol: open })}>
						<Column spacing={styles.spacing._2}>
							{playbackKeyframes.length ? (
								<Annotations type="playback" />
							) : (
								<Instruction>
									{t(
										'Add pauses and playback speed keyframes using the tools in the timeline.'
									)}
								</Instruction>
							)}
						</Column>
					</Group>
				</Container>
			</Wrapper>
			{userTagsModalOpen && (
				<Modal onClose={closeUserTagsModal}>
					<Step title={t('Tag users')} nextLabel={t('Done')} skipBody>
						<UserTagsForm />
					</Step>
				</Modal>
			)}
		</Fragment>
	);
}
