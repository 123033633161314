import { ReactNode } from 'react';

export const symbol_pest_control_rodent: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M482-120q-96 0-165-62t-77-154q-68-17-114-67T80-522q0-81 60-134.5T282-710h106q14 0 23-10.5t9-24.5q0-14-9-24.5T388-780h-65q-14.025 0-23.513-8.675-9.487-8.676-9.487-21.5 0-12.825 9.5-21.325T322-840h63q39 0 67 28t28 67.5q0 39.5-27.84 67T384-650H278q-57.5 0-97.75 37.5T140-519.944Q140-475 169.5-442t72.5 46q13-88 79.101-146T475-600q21.356 0 42.178 3Q538-594 555-588q8-3 15.984-5.286 7.984-2.285 16.5-4Q596-599 604-599.5q8-.5 17.11-.5 73.89 0 126.39 51.5T800-423v12q0 7-2 15l36 35q22 21 33.5 46.5t11.5 54.673q0 58.345-40 99.086T741-120H482Zm0-60h257q32 0 56.5-24t24.5-56q0-16.343-7-30.171Q806-304 792-318l-59-58q4-12 5.5-23.5T740-422q0-49-34.51-83.5T621-540q-46 0-78.5 33.35T510-425q0 17 2 30.5t9 27.5q5 12 2 24.5T509-325q-11 5-23 .5T469-340q-11-20-15-41.868-4-21.868-4-45.132 0-31.27 10-60.135T490-539q-8-2-13.5-1.5t-10.5.5q-67 0-117.5 55T298-357q0 35.87 14.5 67.935Q327-257 352.451-232.654q25.451 24.345 59 38.5Q445-180 482-180Zm198-70q-12 0-21-9t-9-21.5q0-12.5 9-21t21.5-8.5q12.5 0 21 8.625T710-280q0 12-8.625 21T680-250ZM559-390Z"
		/>
	</svg>
);
