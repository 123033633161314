import { ReactNode } from 'react';

export const symbol_table_view: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M340-120q-24.75 0-42.375-17.625T280-180v-440q0-24.75 17.625-42.375T340-680h440q24.75 0 42.375 17.625T840-620v440q0 24.75-17.625 42.375T780-120H340Zm53-394h334q22 0 37.5-15.5T780-567q0-22-15.5-37.5T727-620H393q-22 0-37.5 15.5T340-567q0 22 15.5 37.5T393-514Zm113 168h108v-108H506v108Zm0 166h108v-106H506v106ZM340-346h106v-108h-91q-6.375 0-10.688 4.312Q340-445.375 340-439v93Zm334 0h106v-93q0-6.375-4.312-10.688Q771.375-454 765-454h-91v108Zm-228 60H340v91q0 6.375 4.312 10.688Q348.625-180 355-180h91v-106Zm228 0v106h91q6.375 0 10.688-4.312Q780-188.625 780-195v-91H674ZM120-780q0-24.75 17.625-42.375T180-840h470q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T650-780H180v470q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T120-310v-470Z"
		/>
	</svg>
);
