import { combineReducers } from 'redux';

import taxRates from 'pkg/reducers/tax_rates';
import sports from 'pkg/reducers/sports';
import accounts from 'pkg/reducers/accounts';
import accountRelations from 'pkg/reducers/account_relations';
import activities from 'pkg/reducers/activities';
import activityList from 'pkg/reducers/activity_list';
import app from 'pkg/reducers/app';
import auth from 'pkg/reducers/auth';
import countries from 'pkg/reducers/countries';
import performanceReviews from 'pkg/reducers/performance_reviews';
import eventUsers from 'pkg/reducers/event_users';
import exercises from 'pkg/reducers/exercises';
import flashes from 'pkg/reducers/flashes';
import formations from 'pkg/reducers/group_formations';
import groups from 'pkg/reducers/groups';
import groupVideos from 'pkg/reducers/group_videos';
import matchCompetitions from 'pkg/reducers/match_competitions';
import matchOpponents from 'pkg/reducers/match_opponents';
import matches from 'pkg/reducers/matches';
import matchEvents from 'pkg/reducers/match_events';
import matchTypes from 'pkg/reducers/match_types';
import matchVideos from 'pkg/reducers/match_videos';
import memberships from 'pkg/reducers/memberships';
import notifications from 'pkg/reducers/notifications';
import positions from 'pkg/reducers/positions';
import ratingItems from 'pkg/reducers/rating_items';
import ratings from 'pkg/reducers/ratings';
import reportsEvents from 'pkg/reducers/reports/events';
import sessionBlocks from 'pkg/reducers/session_blocks';
import sessionItems from 'pkg/reducers/session_items';
import sessions from 'pkg/reducers/sessions';
import tags from 'pkg/reducers/tags';
import trainingCollections from 'pkg/reducers/training_collections';
import userLogs from 'pkg/reducers/user_logs';
import userGoals from 'pkg/reducers/user_goals';
import users from 'pkg/reducers/users';
import userVideos from 'pkg/reducers/user_videos';
import userProducts from 'pkg/reducers/user_product';
import videos from 'pkg/reducers/videos';
import videoSequences from 'pkg/reducers/video_sequences';
import videoSequenceUsers from 'pkg/reducers/video_sequence_users';
import videoSequenceComments from 'pkg/reducers/video_sequence_comments';
import videoPlaylists from 'pkg/reducers/video_playlists';
import videoPlaylistSequences from 'pkg/reducers/video_playlist_sequences';
import views from 'pkg/reducers/views';
import productCategories from 'pkg/reducers/product_categories';
import products from 'pkg/reducers/products';
import prices from 'pkg/reducers/prices';
import currencies from 'pkg/reducers/currencies';
import orders from 'pkg/reducers/order';

const reports = combineReducers({
	events: reportsEvents,
});

const rootReducer = combineReducers({
	accounts,
	accountRelations,
	activities,
	activityList,
	app,
	auth,
	countries,
	eventUsers,
	exercises,
	flashes,
	formations,
	groups,
	groupVideos,
	matchCompetitions,
	matchOpponents,
	matches,
	matchEvents,
	matchTypes,
	matchVideos,
	memberships,
	notifications,
	orders,
	performanceReviews,
	positions,
	productCategories,
	products,
	prices,
	ratingItems,
	ratings,
	reports,
	sessionBlocks,
	sessionItems,
	sessions,
	sports,
	tags,
	trainingCollections,
	userLogs,
	users,
	userGoals,
	userVideos,
	userProducts,
	videos,
	videoSequences,
	videoSequenceUsers,
	videoSequenceComments,
	videoPlaylists,
	videoPlaylistSequences,
	views,
	taxRates,
	currencies,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
