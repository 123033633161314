import { Fragment, ReactNode, useEffect, useRef } from 'react';

import { cssClasses } from 'pkg/css/utils';
import { isCypress, isDevServer } from 'pkg/flags';
import {
	useCurrentAccountWards,
	useCurrentGroup,
	useCurrentMembership,
	useCurrentMemberships,
	useCurrentOrganization,
	useCurrentSport,
	useCurrentUser,
} from 'pkg/identity';
import * as models from 'pkg/api/models';
import { useWardsInCurrentGroup } from 'pkg/hooks/useWardsInCurrentGroup';

import { CustomEvent, trackEvent } from 'components/analytics';

import { useApiConfig } from 'components/application/apiconfig';

import * as css from './styles.css';

export function useDefaultAdslotData(): AdslotData {
	const membership = useCurrentMembership();
	const user = useCurrentUser();
	const wards = useCurrentAccountWards();
	const org = useCurrentOrganization();
	const sport = useCurrentSport();

	useEffect(() => {
		window.holid_keyvalues = {};
		if (membership) {
			window.holid_keyvalues.tsrl = models.membership.roleString(membership);
		}

		window.holid_keyvalues.tssp = sport.slug;
		window.holid_keyvalues.tssp = sport.slug;
		window.holid_keyvalues.tsct = user?.country;
		window.holid_keyvalues.tsag = models.user.age(user).toString();
		window.holid_keyvalues.tscr = (wards?.length || 0).toString();
		window.holid_keyvalues.tsgn = user
			? models.user.sexString(user)
			: 'unknown';
		window.holid_keyvalues.tsoi = (org?.id || 0).toString();

		return () => {
			window.holid_keyvalues = {};
		};
	}, [membership, sport, user, org]);

	return null;
}

interface AdslotData {
	tsas?: string; // Adslot Name
	tssp?: string; // Sport
	tsct?: string; // Country
	tsrl?: string; // Membership User Role
	tsag?: string; // Membership User Age
	tscr?: string; // Number of Account Wards
	tsgn?: string; // Membership Gender
	tsoi?: string; // Organization ID
}

interface AdslotProps {
	width?: number;
	height?: number;
	/**
	 * If true the given `height` will be max height instead of fixed height
	 */
	dynamicHeight?: boolean;
}

export default function Adslot({
	width,
	height = 320,
	dynamicHeight,
}: AdslotProps): JSX.Element {
	const adRef = useRef<HTMLDivElement>();
	const styleProps: React.CSSProperties = {
		width: width || '100%',
	};

	useEffect(() => {
		const vcheck = setInterval(() => {
			const b = adRef.current?.getBoundingClientRect();
			if (b && b.top > 0 && b.top < window.innerHeight) {
				trackEvent(CustomEvent.AdView);
				clearInterval(vcheck);
			}
		}, 200);

		return () => {
			clearInterval(vcheck);
		};
	}, []);

	if (dynamicHeight) {
		styleProps.maxHeight = height;
	} else {
		styleProps.height = height;
	}

	return (
		<div
			ref={adRef}
			className={cssClasses(
				'holidAds',
				css.adslot,
				isDevServer() ? css['adslot--dev'] : null
			)}
			style={styleProps}
		/>
	);
}

interface AdslotBoundaryProps {
	children: ReactNode | ReactNode[];
	criteria?: boolean[];
}

export function AdslotBoundary({
	children,
	criteria,
}: AdslotBoundaryProps): JSX.Element {
	const user = useCurrentUser();
	const group = useCurrentGroup();
	const wards = useWardsInCurrentGroup();
	const memberships = useCurrentMemberships();
	const { adsEnabled } = useApiConfig();

	const canShowAds = (criteria: boolean[]) =>
		criteria.every((n: boolean) => n === true);

	// Break out early if account users isn't loaded
	if (!user) return null;

	if (!adsEnabled) {
		return null;
	}

	if (isCypress()) {
		return null;
	}

	// Enforced criteria
	const groupHasAdsEnabled =
		group &&
		models.group.hasFeature(group, models.group.Features.Advertisements);

	const hasWardsInCurrentGroup = wards.length > 0;

	const isNotAnAdminOrStaff = memberships.every(
		(membership: models.membership.Membership) =>
			models.membership.isAdminOrStaff(membership) === false
	);

	// Validate enforced criteria
	if (
		!canShowAds([
			groupHasAdsEnabled,
			hasWardsInCurrentGroup,
			isNotAnAdminOrStaff,
		])
	) {
		return null;
	}

	// Validate additional criteria
	if (criteria?.length > 0 && !canShowAds(criteria)) {
		return null;
	}

	return <Fragment>{children}</Fragment>;
}
