import styled from 'styled-components';
import { t } from '@transifex/native';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import SearchInput from 'components/form/SearchInput';

const Wrapper = styled.div`
	padding: var(--spacing-5);
`;

interface SearchProps {
	onChange: (value: string) => void;
}

export default function Search({ onChange }: SearchProps) {
	const handleChange = (value: string) => {
		onChange(value);
	};

	return (
		<Wrapper>
			<LargeScreen>
				<SearchInput
					small
					filled
					onChange={handleChange}
					placeholder={t('Search conversations...')}
				/>
			</LargeScreen>
			<SmallScreen>
				<SearchInput
					filled
					onChange={handleChange}
					placeholder={t('Search conversations...')}
				/>
			</SmallScreen>
		</Wrapper>
	);
}
