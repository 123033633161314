import { t } from '@transifex/native';
import { T } from '@transifex/react';
import { ChangeEvent, useState } from 'react';

import * as flashActions from 'pkg/actions/flashes';

import Link from 'pkg/router/Link';
import * as models from 'pkg/api/models';
import * as sdk from 'pkg/core/sdk';
import * as endpoints from 'pkg/api/endpoints/auto';

import FogisImportModal from 'containers/group/settings/Fogis/FogisImportModal';

import * as StepModal from 'components/step-modal';

import Column from 'components/layout/column';
import * as Input from 'components/form/inputs';
import InfoBox from 'components/form/info-box';

interface FogisLinkModalProps {
	group: models.group.Group;
	handleClose: () => void;
	onSuccess: () => void;
	refresh: () => void;
}

export default function FogisLinkModal({
	group,
	handleClose,
	refresh,
	onSuccess,
}: FogisLinkModalProps): JSX.Element {
	const [inputValue, setInputValue] = useState('');
	const [patternError, setPatternError] = useState(false);

	const link = (
		<Link href="https://www.svenskfotboll.se">svenskfotboll.se</Link>
	);

	const handleInputChange = (
		event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
	) => {
		setInputValue(event.target.value);
		if (event.target.validity.patternMismatch) {
			setPatternError(true);
		} else {
			setPatternError(false);
		}
	};
	const arr = inputValue.split('/');

	const fogisTeamCode = Number(arr.filter((n) => n).pop());

	const canGoNext = () => {
		if (!patternError && inputValue.length > 1) {
			return true;
		}
		return false;
	};

	const onNext = async (): Promise<boolean> => {
		const response = await sdk.post(
			endpoints.Groups.ConnectWithFogis(group.id),
			{},
			{ fogisTeamId: fogisTeamCode }
		);
		if (!response.ok) {
			flashActions.show(
				{
					title: t(
						'Something went wrong. Please check you have entered a valid url.'
					),
				},
				response.status
			);
			return null;
		}
		refresh();
		onSuccess();
	};

	return (
		<StepModal.Base onClose={handleClose}>
			<StepModal.Step
				title={t('Link SvFF Fogis')}
				nextLabel={t('Next')}
				canGoNext={canGoNext()}>
				<Column>
					<InfoBox color="blue">
						<div>
							<T
								_str="Get the SvFF Fogis link from {link} and paste it here."
								link={link}
							/>
						</div>
						<Link href="https://help.360player.com/article/158-connect-your-team-to-svensk-fotboll">
							{t('Guide - How to find the link')}
						</Link>
					</InfoBox>
					<Input.Group
						label={t('Paste link from svenskfotball.se')}
						required
						hasError={patternError}
						errorMessage={
							patternError && t('There was a problem with your link')
						}>
						<Input.Field
							type="url"
							placeholder={t('Paste link here')}
							pattern="^https://www.svenskfotboll.se/lagsida/(.+)/\d+/?$"
							value={inputValue}
							onChange={handleInputChange}
						/>
					</Input.Group>
				</Column>
			</StepModal.Step>
			<StepModal.Step
				title={t('Link SvFF Fogis')}
				nextLabel={t('Confirm')}
				onNext={onNext}>
				<FogisImportModal group={group} inputValue={inputValue} />
			</StepModal.Step>
		</StepModal.Base>
	);
}
