import * as models from 'pkg/api/models';
import { OrderRowTypes } from 'pkg/api/models/order_row';

interface Taxes {
	taxRateId: number;
	amount: number;
	taxRate: models.taxRate.TaxRate;
}

const getAmount = (cost: number, taxRate: models.taxRate.TaxRate) => {
	return taxRate?.inclusive
		? (cost * taxRate.percentage) / (taxRate.percentage + 100)
		: cost * (taxRate.percentage / 100);
};

export interface PriceDetailsRow {
	productPrice?: models.productPrice.ProductPrice;
	taxRate?: models.taxRate.TaxRate;
	quantity: number;
	type: models.orderRow.OrderRowTypes;
	amount: number;
}

export interface OrderCostDetails {
	oneTimePricesTotal: number;
	recurringPricesTotal: number;
	subtotal: number;
	total: number;
	excludedTax: number;
	recurringPricesExcludedTax: number;
	oneTimePricesExcludedTax: number;
	serviceFee: number;
	discount: number;
	totalExcludingTax: number;
	adjustedTotal: number;
	recurringPayment: number;
	initialPayment: number;
	// Can validate if the order with discounts etc is valid to be created
	validOrder: boolean;
	productCosts: { [key: number]: number };

	taxes: Taxes[];
}

interface OrderCostDetailsProps {
	installmentCount?: number;
	// Pass if the group has a service fee that you want calculations for
	groupServiceFee?: number;
	// If there's anything already paid that should be subtracted
	amountPaid?: number;

	rows: PriceDetailsRow[];
	discount?: models.discount.Discount;
	refunds?: models.orderRefund.OrderRefund[];
}

export function getOrderCostDetails({
	rows = [],
	installmentCount = 1,
	groupServiceFee = 0,
	discount = null,
	refunds = [],
	amountPaid = 0,
}: OrderCostDetailsProps): OrderCostDetails {
	// If installmentCount is passed as null it should still be 1
	if (!installmentCount) {
		installmentCount = 1;
	}

	const productRows = rows.filter((row) => row.type === OrderRowTypes.Product);
	const feeRow = rows.find((row) => row.type === OrderRowTypes.Fee);
	const discountRow = rows.find(
		(row) => row.type === OrderRowTypes.Discount
	) as models.orderRow.OrderRow;

	// If there's a discountRow with a discount we should use that
	if (discountRow?.discount) {
		discount = discountRow?.discount;
	}

	let oneTimePricesTotal = 0;
	let recurringPricesTotal = 0;
	let recurringPricesExcludedTax = 0;
	let oneTimePricesExcludedTax = 0;
	let excludedTax = 0;
	let listTotalPrice = 0;
	let totalExcludingTax = 0;
	const productCosts: { [key: number]: number } = {};

	const taxes: Taxes[] = [];
	const hasRecurringProducts = productRows.some(
		(r) => r.productPrice?.recurring
	);
	const hasOneTimeProducts = productRows.some(
		(r) => !r.productPrice?.recurring
	);

	let subtotal = 0;
	productRows?.forEach((row) => {
		const cost = row.amount * row.quantity;
		subtotal += cost;

		if (row.productPrice) {
			productCosts[row.productPrice.productId] = cost;
		}
	});

	productRows?.forEach((row) => {
		const rowAmount = row.amount;
		let itemCost = rowAmount * row.quantity;
		listTotalPrice += itemCost;

		if (discount?.amountOff) {
			const discountAmount = discount.amountOff;
			itemCost -= ((rowAmount * row.quantity) / subtotal) * discountAmount;
		} else if (discount?.percentOff) {
			itemCost -= rowAmount * row.quantity * (discount.percentOff / 100);
		}

		const isRecurring = row.productPrice?.recurring;

		if (!isRecurring) {
			oneTimePricesTotal += rowAmount * row.quantity;
		} else {
			recurringPricesTotal += rowAmount * row.quantity;
		}

		let hasTaxRate = false;

		if (row.taxRate && !!Object.keys(row.taxRate).length) {
			hasTaxRate = true;
		}

		if (hasTaxRate) {
			const taxRate = row.taxRate;
			const includes = taxes.some(
				(taxItem) => taxItem.taxRateId === taxRate.id
			);
			const newAmount = getAmount(itemCost, taxRate);

			if (includes) {
				const index = taxes
					.map((taxItem) => taxItem.taxRateId)
					.indexOf(taxRate.id);

				taxes[index].amount = taxes[index].amount + newAmount;
				if (!taxRate.inclusive) {
					excludedTax += newAmount;
					totalExcludingTax += itemCost;

					if (isRecurring) {
						recurringPricesExcludedTax += newAmount;
					} else {
						oneTimePricesExcludedTax += newAmount;
					}
				} else {
					totalExcludingTax += itemCost - newAmount;
				}
			} else {
				const tax: Taxes = {
					taxRateId: null,
					amount: null,
					taxRate,
				};

				tax.taxRateId = taxRate.id;
				tax.amount = newAmount > 0 ? newAmount : 0;

				if (!taxRate.inclusive) {
					excludedTax = excludedTax + itemCost * (taxRate.percentage / 100);
					totalExcludingTax += itemCost;

					if (isRecurring) {
						recurringPricesExcludedTax += itemCost * (taxRate.percentage / 100);
					} else {
						oneTimePricesExcludedTax += itemCost * (taxRate.percentage / 100);
					}
				} else {
					totalExcludingTax += itemCost - newAmount;
				}

				taxes.push(tax);
			}
		}
	});

	let recurringPayment = recurringPricesTotal;
	// If we have a recurring product we should subtract the discount for the recurringPricesTotal
	if (hasRecurringProducts && discount) {
		if (discount.amountOff) {
			recurringPayment -= discount.amountOff;
		} else if (discount.percentOff) {
			recurringPayment -= recurringPayment * (discount.percentOff / 100);
		}
	}

	if (recurringPayment < 0) {
		recurringPayment = 0;
	}

	let returnedDiscount = 0;

	if (discount?.amountOff) {
		returnedDiscount =
			discount.amountOff > listTotalPrice ? listTotalPrice : discount.amountOff;
	} else if (discount?.percentOff) {
		returnedDiscount = listTotalPrice * (discount.percentOff / 100);
	}

	let total =
		recurringPricesTotal * installmentCount + oneTimePricesTotal + excludedTax;
	total -= returnedDiscount;

	if (total < 0) {
		total = 0;
	}

	let serviceFee = 0;

	let initialPayment = 0;

	const hasInstallments =
		hasRecurringProducts && hasOneTimeProducts && installmentCount > 1;
	if (hasInstallments) {
		initialPayment =
			oneTimePricesTotal +
			recurringPricesTotal +
			excludedTax -
			returnedDiscount;
	} else {
		initialPayment = total;
	}

	// If there's a groupServiceFee passed we calculate the service fee from that
	if (groupServiceFee) {
		if (hasInstallments) {
			serviceFee = initialPayment * (groupServiceFee / 100);
		} else {
			serviceFee = total * (groupServiceFee / 100);
		}
	}
	// If there's a row which is a fee we use that amount to set the service fee
	if (feeRow) {
		serviceFee = feeRow.amount;
	}

	initialPayment += serviceFee;
	total += amountPaid;

	let adjustedTotal = total + serviceFee;

	refunds?.forEach((refund) => {
		adjustedTotal -= refund.amount;
	});

	let validOrder = true;

	if (hasRecurringProducts && recurringPayment < 0) {
		validOrder = false;
	} else if (!hasRecurringProducts && total < 0) {
		validOrder = false;
	}

	if (totalExcludingTax < 0) {
		totalExcludingTax = 0;
	}

	return {
		initialPayment,
		validOrder,
		oneTimePricesTotal,
		recurringPricesTotal,
		recurringPayment,
		subtotal,
		total: total + serviceFee,
		totalExcludingTax,
		serviceFee,
		excludedTax,
		recurringPricesExcludedTax,
		oneTimePricesExcludedTax,
		taxes,
		discount: returnedDiscount,
		adjustedTotal,
		productCosts,
	};
}
