import { ReactNode } from 'react';

export const symbol_keyboard_capslock: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M270-240q-13 0-21.5-8.5T240-270q0-13 8.5-21.5T270-300h420q13 0 21.5 8.5T720-270q0 13-8.5 21.5T690-240H270Zm210-410L313-483q-9 9-21 8.5t-21-9.5q-9-9-9-21.5t9-21.5l188-188q9-9 21-9t21 9l193 193q9 9 9 21t-9 21q-9 9-21.5 9t-21.5-9L480-650Z"
		/>
	</svg>
);
