import { ReactNode } from 'react';

export const symbol_weather_hail: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m542-84-31 31q-5 5-11 5t-11-5l-31-31q-5-5-5-11t5-11l31-31q5-5 11-5t11 5l31 31q5 5 5 11t-5 11Zm-197-36q-8-8-8-17.5t8-17.5l76-76q8-8 17.5-8t17.5 8q8 8 8 17.5t-8 17.5l-77 77q-8 8-17 7.5t-17-8.5Zm317-84-31 31q-5 5-11 5t-11-5l-31-31q-5-5-5-11t5-11l31-31q5-5 11-5t11 5l31 31q5 5 5 11t-5 11Zm-360 0-31 31q-5 5-11 5t-11-5l-31-31q-5-5-5-11t5-11l31-31q5-5 11-5t11 5l31 31q5 5 5 11t-5 11Zm-12-136q-87 0-148.5-61.5T80-550q0-79 56.5-141T277-759q32-56 84.5-88.5T480-880q91 0 152.5 57.5T708-680q79 4 125.5 53.5T880-510q0 70-49.5 120T710-340H290Zm0-60h420q46 0 78-32.5t32-77.5q0-46-32-78t-78-32h-60v-30q0-71-49.5-120.5T480-820q-51 0-93.5 27.5T324-718l-8 18h-28q-62 2-105 45.5T140-550q0 62 44 106t106 44Zm190-210Z"
		/>
	</svg>
);
