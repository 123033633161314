import { Fragment, useState } from 'react';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import { useCollection } from 'pkg/api/use_collection';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useQueryState } from 'pkg/hooks/query-state';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';

import { LargeScreen } from 'components/MediaQuery';

import * as ActionBar from 'components/layout/ActionBar';
import MatchCompetitionsTable from 'components/match-competition/table';
import MatchCompetitionModal from 'components/match-competition/modal';
import { asString, FormPayload } from 'components/form/Form';
import { ButtonTrigger } from 'components/navigation/header/small_screen/page_actions/ButtonTrigger';
import { useTemplateContext } from 'components/layout/page-templates/context';

import Button from 'design/button';

interface CompetitionsViewProps {
	groupId: number;
}

export default function CompetitionsView({ groupId }: CompetitionsViewProps) {
	const queryState = useQueryState();
	const queryTitle = queryState.get('title')?.toString() || '';
	const { setPageActions } = useTemplateContext();

	const [modal, setModal] = useState(false);

	const competitionCollection =
		useCollection<models.matchCompetition.MatchCompetition>(
			endpoints.MatchCompetition.List(),
			{
				queryParams: new URLSearchParams({
					group_id: groupId.toString(),
					title: queryTitle,
				}),
			}
		);

	const handleCreate = async (data: FormPayload) => {
		const payload: models.matchCompetition.CreatePayload = {
			groupId,
			title: asString(data.title),
			description: asString(data.description),
		};

		const [req, resp] = await models.create<
			models.matchCompetition.MatchCompetitionPayload,
			models.matchCompetition.MatchCompetition
		>(endpoints.MatchCompetition.Create(), payload);

		if (req.ok) {
			competitionCollection.addRecord(resp);
			hideModal();
		}
	};

	const showModal = () => setModal(true);
	const hideModal = () => setModal(false);

	useComponentDidMount(() => {
		setPageActions(<ButtonTrigger icon="add" onClick={showModal} />);
	});

	return (
		<Fragment>
			<ActionBar.FilterBar>
				<ActionBar.PrimaryAction>
					<ActionBar.Search
						placeholder={t('Search')}
						queryName="title"
						value={queryTitle}
					/>
				</ActionBar.PrimaryAction>
				<LargeScreen>
					<Button
						primary
						label={t('Create new')}
						icon="add"
						onClick={showModal}
					/>
				</LargeScreen>
			</ActionBar.FilterBar>
			<MatchCompetitionsTable
				showMatchCompetitionModal={showModal}
				competitionCollection={competitionCollection}
			/>

			{modal && (
				<MatchCompetitionModal handleSave={handleCreate} onClose={hideModal} />
			)}
		</Fragment>
	);
}
