import { t } from '@transifex/native';
import { useRef } from 'react';

import spacing from 'pkg/config/spacing';

import * as flashActions from 'pkg/actions/flashes';

import useMixedState from 'pkg/hooks/useMixedState';
import { useCurrentOrganization } from 'pkg/identity';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { CollectionResponse } from 'pkg/api/use_collection';

import CreateDiscountForm from 'routes/payments/discounts/create/form';
import {
	defaultDiscountState,
	DiscountFormState,
	makeDiscountPayload,
} from 'routes/payments/discounts/create';
import ButtonActions from 'routes/payments/discounts/create/form/button-actions';

import * as Card from 'components/Card';
import Modal from 'components/modal';

import Form, { FormPayload, submitForm } from 'components/form/Form';
import Column from 'components/layout/column';

interface CreateDiscountModalProps {
	discountsCollection: CollectionResponse<models.discount.Discount>;

	onClose: (discount?: models.discount.Discount) => void;
}

export default function CreateDiscountModal({
	discountsCollection,
	onClose,
}: CreateDiscountModalProps) {
	const org = useCurrentOrganization();

	const formRef = useRef(null);
	const [formState, setFormState] =
		useMixedState<DiscountFormState>(defaultDiscountState);

	const handleSubmit = async (data: FormPayload) => {
		const payload = makeDiscountPayload(org.id, formState, data);

		setFormState({ showPreview: true, payload });
	};

	const handleCreateDiscount = async () => {
		setFormState({ saving: true });

		const [req, discount] = await models.create<
			models.discount.CreateDiscountPayload,
			models.discount.Discount
		>(endpoints.Discounts.Create(), formState.payload);

		if (req.ok) {
			flashActions.show({
				title: t('Successfully created discount'),
			});

			discountsCollection.addRecord(discount);
			onClose(discount);
		}

		setFormState({ saving: false });
	};

	const handleShowPreview = () => {
		submitForm(formRef);
	};

	const handleClose = () => onClose();

	const buttonActions = (
		<ButtonActions
			formState={formState}
			setFormState={setFormState}
			handleNextAction={handleCreateDiscount}
			onCancel={handleClose}
			onPreview={handleShowPreview}
		/>
	);

	return (
		<Modal onClose={handleClose}>
			<Card.Base>
				<Card.Header>
					<Card.Heading>{t('Create discount')}</Card.Heading>
				</Card.Header>
				<Card.Divider />
				<Card.Body>
					<Form formRef={formRef} onSubmit={handleSubmit} stopPropagation>
						<Column spacing={spacing._7}>
							<CreateDiscountForm
								formState={formState}
								setFormState={setFormState}
							/>
						</Column>
					</Form>
				</Card.Body>
				<Card.Divider />
				<Card.Footer>{buttonActions}</Card.Footer>
			</Card.Base>
		</Modal>
	);
}
