import { ReactNode } from 'react';

export const symbol_location_searching: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M450-70v-45q-137-14-228-105T117-448H72q-12.75 0-21.375-8.675Q42-465.351 42-478.175 42-491 50.625-499.5T72-508h45q14-137 105-228t228-105v-45q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-886v45q137 14 228 105t105 228h45q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T888-448h-45q-14 137-105 228T510-115v45q0 12.75-8.675 21.375Q492.649-40 479.825-40 467-40 458.5-48.625T450-70Zm30-104q125 0 214.5-89.5T784-478q0-125-89.5-214.5T480-782q-125 0-214.5 89.5T176-478q0 125 89.5 214.5T480-174Z"
		/>
	</svg>
);
