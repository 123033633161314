import { ReactNode } from 'react';

export const symbol_charger: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m476-212 146-309q4-8-1-14.5t-13-6.5H502v-207q0-6-6-7t-8 4L342-443q-4 8 1 14.5t13 6.5h106v207q0 6 6 7t8-4Zm4 132q-85 0-158-30.5T195-195q-54-54-84.5-127T80-480q0-84 30.5-157T195-764q54-54 127-85t158-31q84 0 157 31t127 85q54 54 85 127t31 157q0 85-31 158t-85 127q-54 54-127 84.5T480-80Zm0-400Zm0 340q145 0 242.5-97.5T820-480q0-145-97.5-242.5T480-820q-145 0-242.5 97.5T140-480q0 145 97.5 242.5T480-140Z"
		/>
	</svg>
);
