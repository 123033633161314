import { Fragment, useState } from 'react';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import { useCollection } from 'pkg/api/use_collection';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useQueryState } from 'pkg/hooks/query-state';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';

import ScheduleRow from 'routes/scheduling/templates/list/ScheduleRow';
import CreateTemplateModal from 'routes/scheduling/templates/modals/CreateTemplate';

import AssetImage from 'components/AssetImage';
import { LargeScreen } from 'components/MediaQuery';

import * as ActionBar from 'components/layout/ActionBar';
import { ButtonTrigger } from 'components/navigation/header/small_screen/page_actions/ButtonTrigger';
import { useTemplateContext } from 'components/layout/page-templates/context';

import * as Table from 'design/table';
import Button from 'design/button';

interface SchedulesProps {
	groupId: number;
}

const ScheduleTemplates = ({ groupId }: SchedulesProps) => {
	const qs = useQueryState();
	const search = qs.get('search') as string;
	const { setPageActions } = useTemplateContext();

	const [modal, setModal] = useState('');
	const [sort, setSort] = useState<{ by: string; order: 'asc' | 'desc' }>({
		by: 'updated_at',
		order: 'desc',
	});

	const {
		records: schedules,
		isLoading,
		refresh,
	} = useCollection<models.schedule.Schedule>(endpoints.Schedule.Index(), {
		queryParams: new URLSearchParams({
			group_id: groupId.toString(),
			sort_by: sort.by,
			sort_by_order: sort.order,
		}),
	});

	const setCreateModal = () => setModal('create');
	const hideModal = () => setModal('');

	const handleSortClick = (field: string, sortOrder: 'asc' | 'desc') => {
		setSort({
			by: field,
			order: sortOrder,
		});
	};

	const tableColumns = [
		{
			content: t(`Title`),
			sortKey: 'title',
		},
		{
			content: t(`Status`),
			width: 'max-content',
		},
		{
			content: t(`Last published`),
			sortKey: 'published_at',
			width: 'max-content',
		},
		{
			content: t(`Updated`),
			sortKey: 'updated_at',
			width: 'max-content',
		},
		{
			content: t(`Created`),
			sortKey: 'created_at',
			width: 'max-content',
		},
		{
			content: '',
			width: '50px',
		},
	];

	const emptyState = {
		title: t(`No scheduling templates`),
		content: t(`There are currently no scheduling templates to view`),
		helpUrl:
			'https://help.360player.com/article/142-how-to-create-a-schedule-template',
		image: <AssetImage src="img/missing-entities/calendar.svg" />,
		button: (
			<Button icon="add" secondary onClick={setCreateModal}>
				{t(`Add template`)}
			</Button>
		),
	};

	const listedSchedules = search
		? schedules.filter((s) =>
				s.title.toLocaleLowerCase().includes(search.toLocaleLowerCase())
			)
		: schedules;

	const content = (
		<Table.Table
			sortBy={sort.by}
			sortOrder={sort.order}
			onSort={handleSortClick}
			isLoading={isLoading}
			columns={tableColumns}
			emptyState={emptyState}>
			{listedSchedules.map((schedule) => (
				<ScheduleRow
					key={schedule.id}
					groupId={groupId}
					schedule={schedule}
					refreshList={refresh}
				/>
			))}
		</Table.Table>
	);

	useComponentDidMount(() =>
		setPageActions(<ButtonTrigger icon="add" onClick={setCreateModal} />)
	);

	return (
		<Fragment>
			<ActionBar.FilterBar>
				<ActionBar.PrimaryAction>
					<ActionBar.Search placeholder={t('Search')} />
				</ActionBar.PrimaryAction>
				<LargeScreen>
					<Button
						label={t('Create new')}
						icon="add"
						primary
						onClick={setCreateModal}
					/>
				</LargeScreen>
			</ActionBar.FilterBar>
			{content}
			{modal === 'create' && (
				<CreateTemplateModal groupId={groupId} hideModal={hideModal} />
			)}
		</Fragment>
	);
};

export default ScheduleTemplates;
