import { ReactNode } from 'react';

export const symbol_water_medium: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m235-388 27.235 248H698l30-270h-31q-37.247 0-68.124-5.5Q598-421 544-436q-25-7-49.5-10.5T444-450q-56 0-109.5 16T235-388Zm-8-65q48-27 104.5-42T444-510q30 0 59.5 4t56.5 12q51.128 14 78.226 19 27.097 5 57.774 5h38l39-350H187l40 367Zm35 373q-23 0-40.5-15T202-133l-75-680q-3-27 14.837-47 17.838-20 45.163-20h586q27.325 0 45.163 20Q836-840 833-813l-75 680q-2 23-19.5 38T698-80H262Zm0-60h436-436Z"
		/>
	</svg>
);
