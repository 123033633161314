import { ReactNode } from 'react';

export const symbol_lunch_dining: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-120q-24.75 0-42.375-17.625T80-180v-98q0-12.75 8.625-21.375T110-308h740q12.75 0 21.375 8.625T880-278v98q0 24.75-17.625 42.375T820-120H140Zm0-128v68h680v-68H140Zm340-178q-41 0-60.5 22T346-382q-54 0-71.5-22T216-426q-34 0-52.5 16.5T110-385q-12 2-21-6t-9-20.966q0-12.966 9-22.5T110-449q21-9 42.795-23T216-486q54 0 71.5 22t58.5 22q41 0 60.5-22t73.5-22q54 0 73.5 22t60.5 22q41 0 58.5-22t71.5-22q41 0 63 14t43 23q12 5 21 14.75t9 22.5q0 12.75-9 20.75t-21 6q-35-8-53.5-24.5T744-426q-41 0-58.5 22T614-382q-54 0-73.5-22T480-426Zm0-414q74.341 0 146.171 15Q698-810 754.5-779q56.5 31 91 78.5T880-588q0 12.75-8.625 21.375T850-558H110q-12.75 0-21.375-8.625T80-588q0-65 34.5-112.5t91-78.5q56.5-31 128.329-46 71.83-15 146.171-15Zm0 60q-148 0-242.5 46.5T142-618h676q-3-69-96.5-115.5T480-780Zm0 532Zm0-370Z"
		/>
	</svg>
);
