import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import * as routes from 'pkg/router/routes';
import { useCurrentOrganization } from 'pkg/identity';

import SectionTitle from 'components/SectionTitle';

import ListItem from 'components/products/PriceListItem';
import Column from 'components/layout/column';

import Button from 'design/button';

interface PricingList {
	prices: models.productPrice.ProductPrice[];
	productId: number;
	archived: boolean;
	refreshProduct: () => void;
}

const PricingListItem: React.FC<React.PropsWithChildren<PricingList>> = ({
	prices,
	productId,
	archived,
	refreshProduct,
}) => {
	const org = useCurrentOrganization();

	return (
		<Column>
			<SectionTitle>
				{t('Pricing')}
				{!archived && (
					<Button
						icon="add"
						inline
						noPadding
						href={routes.Product.Edit(org.id, productId)}>
						{t('Add another price')}
					</Button>
				)}
			</SectionTitle>
			<Column spacing={styles.spacing._3}>
				{prices?.map((price, index: number) => (
					<ListItem
						key={index}
						price={price}
						productId={productId}
						refreshProduct={refreshProduct}
					/>
				))}
			</Column>
		</Column>
	);
};

export default PricingListItem;
