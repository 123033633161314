import { ReactNode } from 'react';

export const symbol_align_center: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M110-450q-12.75 0-21.375-8.675Q80-467.351 80-480.175 80-493 88.625-501.5T110-510h740q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T850-450H110Zm210-120q-12.75 0-21.375-8.625T290-600v-40q0-12.75 8.625-21.375T320-670h320q12.75 0 21.375 8.625T670-640v40q0 12.75-8.625 21.375T640-570H320Zm0 280q-12.75 0-21.375-8.625T290-320v-40q0-12.75 8.625-21.375T320-390h320q12.75 0 21.375 8.625T670-360v40q0 12.75-8.625 21.375T640-290H320Z"
		/>
	</svg>
);
