import { palette } from 'pkg/config/styles';
import { size } from 'pkg/config/fonts';

import Markdown from 'routes/kitchen_sink/components/Markdown';

import * as Card from 'components/Card';

import AvatarStack from 'components/avatar/avatar-stack';
import Column from 'components/layout/column';

import readme from './README.md';

const users: any[] = [
	{
		firstName: 'Liam',
		lastName: 'Öberg',
	},
	{
		firstName: 'Zak',
		lastName: 'Eklund',
	},
	{
		firstName: 'Robin',
		lastName: 'Grass',
	},
	{
		firstName: 'Marcus',
		lastName: 'Nurmmela',
	},
	{
		firstName: 'John',
		lastName: 'Mick',
	},
	{
		firstName: 'David',
		lastName: 'Dufberg Töttrup',
	},
	{
		firstName: 'Flynn',
		lastName: 'Moore',
	},
	{
		firstName: 'Tobias',
		lastName: 'Magnusson',
	},
	{
		firstName: 'Christoffer',
		lastName: 'Sundqvist',
	},
	{
		firstName: 'Ricky',
		lastName: 'Rydén',
	},
];

export default function AvatarStackView() {
	const c = [...users].splice(0, 1);

	return (
		<Column>
			<Markdown raw={readme} />

			<Card.Base>
				<Card.Body>
					<Column>
						<AvatarStack users={c} renderRestWith={<span>Single item</span>} />
						<AvatarStack users={users} />
						<AvatarStack users={users} renderTriggerWith={<span>+</span>} />
						<AvatarStack users={users} threshold={10} />
						<AvatarStack users={users} threshold={7} />
						<AvatarStack
							users={users}
							threshold={4}
							renderRestWith={
								<span>
									<div style={{ color: palette.gray[400], fontSize: size.xs }}>
										Registered
									</div>
									<div style={{ fontSize: size.xs }}>
										{users.map((u) => u.firstName).join(', ')}
									</div>
								</span>
							}
						/>
					</Column>
				</Card.Body>
			</Card.Base>
		</Column>
	);
}
