import { ReactNode } from 'react';

export const symbol_carry_on_bag: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M633-280q50 0 85 35t35 85q0 50-35 85t-85 35q-44 0-77.5-26.5T516-135H292q-24.75 0-42.375-17.625T232-195v-409q0-24.75 17.625-42.375T292-664h281v-156h-54q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T519-880h84q12.75 0 21.375 8.625T633-850v570Zm-275 85v-409h-66v409h66Zm60-409v409h99q6.545-22.125 21.273-40.562Q553-254 573-265v-339H418ZM632.882-90Q662-90 682.5-110.382q20.5-20.383 20.5-49.5Q703-189 682.618-209.5q-20.383-20.5-49.5-20.5Q604-230 583.5-209.618q-20.5 20.383-20.5 49.5Q563-131 583.382-110.5q20.383 20.5 49.5 20.5Zm.118-75ZM418-400Zm-60 205v-409 409Zm60-409v409-409Z"
		/>
	</svg>
);
