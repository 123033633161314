import { t } from '@transifex/native';
import { useState } from 'react';
import styled from 'styled-components';

import DateTime, { Granularity } from 'pkg/datetime';
import * as routes from 'pkg/router/routes';
import { useCurrentMembership } from 'pkg/identity';
import * as models from 'pkg/api/models';
import { useCollection } from 'pkg/api/use_collection';
import * as endpoints from 'pkg/api/endpoints/auto';

import RelativeTime from 'components/RelativeDateTime';
import AddButton from 'components/add-button';
import Icon from 'components/icon';
import { LargeScreen } from 'components/MediaQuery';

import { Spinner } from 'components/loaders/spinner';
import { UserActionListItem } from 'components/group/UserActionList';
import Column from 'components/layout/column';
import UpdateDialogueTemplateModal from 'components/group/update-dialogue-template-modal';
import { usePageActions } from 'components/navigation/header/small_screen/page_actions/Context';
import Row from 'components/layout/row';

import * as ContextMenu from 'design/context_menu';
import InlinePlaceholder from 'design/placeholders/inline';
import Button from 'design/button';

const MissingWrapper = styled.div`
	display: flex;
	height: 100%;
	justify-content: center;
	align-items: center;
`;

export default function Dialogue({ groupId, organizationId }) {
	const [templateModalVisible, setTemplateModalVisible] = useState(false);
	const { records: groupUsers, isLoading } = useCollection(
		endpoints.Groups.ShowUsersWithLatestPerformanceReview(groupId)
	);
	const membership = useCurrentMembership();

	const openTemplateModal = () => {
		setTemplateModalVisible(true);
	};

	const closeModal = async (p) => {
		await p;
		setTemplateModalVisible(false);
	};

	usePageActions(
		models.membership.isAdmin(membership)
			? [
					{
						icon: 'settings',
						onClick: openTemplateModal,
					},
				]
			: []
	);

	let content;
	if (isLoading) {
		content = <Spinner center />;
	} else if (groupUsers.length === 0) {
		content = (
			<MissingWrapper>
				<InlinePlaceholder>
					<Icon name="nav-dialogue" />
					{t(`No players in team`)}
				</InlinePlaceholder>
			</MissingWrapper>
		);
	} else {
		content = groupUsers.map((groupUser) => {
			let now = Date.now();
			let lastUpdatedAtString = t(`Never updated`);
			let needsActionLabel = null;

			if (!groupUser?.lastPerformanceReviewAt) {
				needsActionLabel = t(`Dialogue missing`);
			} else {
				lastUpdatedAtString = (
					<RelativeTime dateTime={groupUser.lastPerformanceReviewAt} />
				);
			}

			const adjustedDate = DateTime.now()
				.setUnixTimestamp(groupUser.lastPerformanceReviewAt)
				.next(Granularity.day, 180);

			if (adjustedDate.isBefore(now)) {
				needsActionLabel = t(`Outdated`);
			}

			const props = {
				user: groupUser.user,
				userLabel: lastUpdatedAtString,
				needsActionLabel,
				key: `list-item-${groupUser.id}`,
				primaryRating: {},
				userId: groupUser.userId,
				href: routes.User.Profile.PerformanceReviews(
					organizationId,
					groupUser.groupId,
					groupUser.userId
				),
			};

			return (
				<UserActionListItem {...props}>
					<ContextMenu.Menu
						title={`${groupUser?.user.firstName} ${groupUser?.user.lastName}`}
						toggleWith={<AddButton />}>
						<ContextMenu.LinkItem
							href={routes.User.Profile.PerformanceReviews(
								organizationId,
								groupUser.groupId,
								groupUser.userId
							)}>
							<ContextMenu.ItemIcon name="visibility" />
							{t(`View`)}
						</ContextMenu.LinkItem>

						<ContextMenu.LinkItem
							href={routes.User.Profile.Goals(
								organizationId,
								groupUser.groupId,
								groupUser.userId
							)}>
							<ContextMenu.ItemIcon name="flag" />
							{t(`View goals`)}
						</ContextMenu.LinkItem>
					</ContextMenu.Menu>
				</UserActionListItem>
			);
		});
	}

	return (
		<Column>
			<LargeScreen>
				<Row justify="end">
					<Button
						icon="settings"
						inline
						noPadding
						onClick={openTemplateModal}
						testid="performance_review.update_template_button">
						{t(`Update template`)}
					</Button>
				</Row>
			</LargeScreen>
			{templateModalVisible && (
				<UpdateDialogueTemplateModal
					closeModal={closeModal}
					groupId={groupId}
				/>
			)}

			{content}
		</Column>
	);
}
