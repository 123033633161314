import { ReactNode } from 'react';

export const symbol_chat_add_on: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m240-280-94 94q-7 7-16.5 3.458Q120-186.083 120-196v-584q0-24.75 17.625-42.375T180-840h520q24.75 0 42.375 17.625T760-780v191q0 12-9 20.5t-21.5 8.5q-12.5 0-21-8.625T700-590v-190H180v440h270q12 0 21 9t9 21.5q0 12.5-9 21t-21 8.5H240Zm70-340h260q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T570-680H310q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T310-620Zm0 170h140q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T450-510H310q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T310-450Zm380 160h-90q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T600-350h90v-90q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T750-440v90h90q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T840-290h-90v90q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T690-200v-90Zm-510-50v-440 440Z"
		/>
	</svg>
);
