import { ReactNode } from 'react';

export const symbol_dentistry: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M680-875q66 0 113 47t47 113q0 11-1.5 29.5T834-643l-55 403q-5 41-36 63.5T677-154q-21 0-41-9t-34-29L495-348q-2-3-6.5-5t-9.5-2q-2 0-16 9L359-195q-14 21-35 31t-43 10q-35 0-65.5-22.5T180-241l-54-402q-3-24-4.5-42.5T120-715q0-66 47-113t113-47q36 0 57.5 9.5T379-845q20 11 42.5 20.5T480-815q36 0 58.5-9.5T581-845q20-11 42-20.5t57-9.5Zm0 60q-23 0-40 7t-35 18q-32 18-59.5 26.5T480-755q-38 0-65-8.5T356-790q-18-11-35.5-18t-40.5-7q-42 0-71 29t-29 71q0 8 1 24t4 37l55 404q2 17 14.5 26t26.5 9q9 0 17-4.5t14-12.5l101-148q12-17 30-26t37-9q19 0 36.5 9t29.5 26l103 151q5 8 13 11.5t16 3.5q14 0 26.5-9.5T719-248l56-406q3-21 4-37t1-24q0-42-29-71t-71-29ZM480-515Z"
		/>
	</svg>
);
