export default {
	name: 'filter',
	objects: [
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M8.65398 12.0588C9.14129 12.0588 9.53633 12.4539 9.53633 12.9412V18.173C9.53633 18.6603 9.14129 19.0554 8.65398 19.0554C8.16667 19.0554 7.77162 18.6603 7.77162 18.173V16.4394H4.88235C4.39504 16.4394 4 16.0444 4 15.5571C4 15.0698 4.39504 14.6747 4.88235 14.6747H7.77162V12.9412C7.77162 12.4539 8.16667 12.0588 8.65398 12.0588ZM10.8353 15.5571C10.8353 15.0698 11.2303 14.6747 11.7176 14.6747H19.1176C19.6049 14.6747 20 15.0698 20 15.5571C20 16.0444 19.6049 16.4394 19.1176 16.4394H11.7176C11.2303 16.4394 10.8353 16.0444 10.8353 15.5571Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M15.346 5C14.8587 5 14.4637 5.39504 14.4637 5.88235V11.1142C14.4637 11.6015 14.8587 11.9965 15.346 11.9965C15.8333 11.9965 16.2284 11.6015 16.2284 11.1142V9.38062H19.1176C19.605 9.38062 20 8.98558 20 8.49827C20 8.01096 19.605 7.61592 19.1176 7.61592H16.2284V5.88235C16.2284 5.39504 15.8333 5 15.346 5ZM13.1647 8.49827C13.1647 8.01096 12.7697 7.61592 12.2823 7.61592H4.88237C4.39506 7.61592 4.00001 8.01096 4.00001 8.49827C4.00001 8.98558 4.39506 9.38062 4.88237 9.38062H12.2823C12.7697 9.38062 13.1647 8.98558 13.1647 8.49827Z',
			},
		},
	],
};
