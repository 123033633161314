import { ReactNode } from 'react';

export const symbol_speed_0_25: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M600-277q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T600-337h140v-115H600q-12.75 0-21.375-8.625T570-482v-171q0-12.75 8.625-21.375T600-683h170q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T770-623H630v111h110q24 0 42 18t18 42v115q0 24-18 42t-42 18H600Zm-120 0H310q-12.75 0-21.375-8.625T280-307v-145q0-24 18-42t42-18h110v-111H310q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T310-683h140q24 0 42 18t18 42v111q0 24-18 42t-42 18H340v115h140q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T480-277Zm-290.175 0Q177-277 168.5-285.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Z"
		/>
	</svg>
);
