import { Record } from 'immutable';
import { schema } from 'normalizr';

const normalizrSchema = new schema.Entity('tags');

interface ITag {
	id: number;
	name: string;
}

const tagProps: ITag = {
	id: 0,
	name: '',
};

class Tag extends Record(tagProps, 'TagRecord') implements ITag {
	static normalizr(): schema.Entity {
		return normalizrSchema;
	}
}

export default Tag;
