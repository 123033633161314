import { ReactNode } from 'react';

export const symbol_buttons_alt: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-240q-24 0-42-18t-18-42v-360q0-24 18-42t42-18h680q24 0 42 18t18 42v360q0 24-18 42t-42 18H140Zm0-60h680v-360H140v360Zm147-155v71q0 10.4 7.116 17.2 7.117 6.8 18 6.8 10.884 0 17.384-7.083Q336-374.167 336-385v-70h70q10.833 0 17.917-7.116 7.083-7.117 7.083-18Q431-491 423.917-498q-7.084-7-17.917-7h-70v-71q0-10.4-6.5-17.2-6.5-6.8-17.384-6.8-10.883 0-18 6.5Q287-587 287-576v71h-70q-10.833 0-17.917 7.116-7.083 7.117-7.083 18Q192-469 199.083-462q7.084 7 17.917 7h70ZM140-300v-360 360Z"
		/>
	</svg>
);
