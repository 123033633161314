import { ReactNode } from 'react';

export const symbol_subscriptions: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-80q-24 0-42-18t-18-42v-440q0-24 18-42t42-18h680q24 0 42 18t18 42v440q0 24-18 42t-42 18H140Zm0-60h680v-440H140v440Zm290-87 179-121q7-5 7-12t-7-12L430-492q-8-5-15.5-.935Q407-488.87 407-480v241q0 8.87 7.5 12.935Q422-222 430-227ZM179-700q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T179-760h602q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T781-700H179Zm131-120q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T310-880h340q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T650-820H310ZM140-140v-440 440Z"
		/>
	</svg>
);
