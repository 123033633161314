import { ReactNode } from 'react';

export const symbol_toys_and_games: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M120-241q0-12.211 8.114-20.605Q136.227-270 147.818-270 154-270 159-267.5t10 5.5q12 8 26 12.5t29 4.5q45 0 75.5-30.5T330-351q0-45-30.5-75.5T224-457q-14.651 0-28.326 4.5Q182-448 169-440q-5 3-10 5.5t-11.182 2.5q-11.591 0-19.704-8.395Q120-448.789 120-461v-131q0-14 10.5-24.5T155-627h184q-13-20-18.5-42.667Q315-692.333 315-715q0-68.75 48.176-116.875Q411.353-880 480.176-880 549-880 597-831.875T645-715q0 22.667-5.5 45.333Q634-647 621-627h184q14 0 24.5 10.5T840-592v131q0 12.325-8.146 20.663Q823.708-432 811.667-432 806-432 801-434.333q-5-2.334-10-5.667-12-8-26-12.5t-29-4.5q-45 0-75.5 30.5T630-351q0 45 30.5 75.5T736-245q15 0 29-4.5t26-12.5q5-3 9.952-5.5Q805.905-270 812-270q11.9 0 19.95 8.337Q840-253.325 840-241v125.917Q840-101 829.5-90.5 819-80 805-80H155q-14 0-24.5-10.5T120-115.083V-241Zm60 101h600v-53q-11 5-22 6.5t-22.386 1.5q-68.316 0-116.965-48.5T570-351q0-69 48.649-117.5T735.614-517Q747-517 758-515.5t22 3.5v-55H568q-11 0-19.5-8.5T540-595q0-14 8.5-26t16.5-24q11-16 15.5-33.55Q585-696.1 585-715q0-44.1-30.43-74.55Q524.141-820 480.07-820q-44.07 0-74.57 30.45Q375-759.1 375-715q0 18.9 4.5 36.45Q384-661 395-645q8 12 16.5 24t8.5 26q0 11-8.5 19.5T392-567H180v55q11-2 22-3.5t22.386-1.5q68.316 0 116.965 48.5T390-351q0 69-48.649 117.5T224.386-185Q213-185 202-186.5t-22-6.5v53Zm300-340Z"
		/>
	</svg>
);
