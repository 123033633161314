import { ReactNode } from 'react';

export const symbol_settings_panorama: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M566-82q-19 5-34.5-6.5T516-120v-210q0-20 15.5-31.5T567-368q37 10 74 14t75 4q38 0 75.5-3.5T866-368q19-5 34.5 6.5T916-330v210q0 20-15.5 31.5T866-82q-37-11-74.5-14.5T716-100q-38 0-75.5 4T566-82Zm-86-398Zm0 0ZM436-80h-22q-11 0-19.5-7T384-105l-16-101q-19-7-40-19t-37-25l-86 40q-14 6-28 1.5T155-226L97-330q-8-13-5-27t15-23l81-59q-2-9-2.5-20.5T185-480q0-9 .5-20.5T188-521l-81-58q-12-9-15-23t5-27l58-104q8-13 22-17.5t28 1.5l86 39q16-13 37-25t40-18l15-94q2-14 13-23.5t25-9.5h118q14 0 25 9.5t13 23.5l15 93q19 7 40.5 18.5T669-710l86-39q14-6 27.5-1.5T804-733l59 103q8 13 4.5 27.5T852-580l-80 56q1 14 1.5 27t.5 27q0 13-8.5 21.5T744-440q-13 0-21.5-8.5T714-470q0-20-1.5-39t-6.5-38l94-69-40-72-106 46q-23-26-52-43.5T538-708l-14-112h-88l-14 112q-34 7-63.5 24T306-642l-106-46-40 72 94 69q-4 17-6.5 33.5T245-480q0 17 2.5 33.5T254-413l-94 69 40 72 106-46q26 26 59.5 43.5T436-249v169Zm43-530q55 0 92.5 38.5T609-474q0 14-8.5 23t-22.5 8q-12 0-20.5-9t-8.5-22q0-35-20-55.5T480-550q-29 0-49.5 20T410-481q0 17 7.5 31.5T439-424q9 7 9 19t-8 20q-10 10-24.5 9T390-387q-20-18-30-42.5T350-481q0-54 37.5-91.5T479-610Z"
		/>
	</svg>
);
