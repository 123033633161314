import { ReactNode } from 'react';

export const symbol_directions_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M812-63 652-222 523-93q-8 8-19.551 12-11.551 4-23.5 4T456.5-81Q445-85 437-93L93-437q-8-8-12-19.551-4-11.551-4-23.5t4-23.449Q85-515 93-523l129-129L47-827q-9-9.067-8.5-21.533Q39-861 48.053-870q9.052-9 21.5-9Q82-879 91-870l764 765q9 9 9 21t-9.053 21q-9.052 9-21.5 9Q821-54 812-63ZM609-265 265-609 136-480l344 344 129-129Zm274-214.949q0 11.949-4 23.449-4 11.5-12 19.5L759-329q-9 9-21 8.5t-21-9.5q-9-9-9-21.5t9-21.5l107-107-344-344-108 108q-9 9-21 8.5t-21-9.5q-9-9-9-21.5t9-21.5l107-107q8-8 19.551-12 11.551-4 23.5-4t23.449 4q11.5 4 19.5 12l344 344q8 8 12 19.551 4 11.551 4 23.5ZM523-523Zm-86 86Zm-117 47v-130q0-12.75 8.625-21.375T350-550h60l60 60h-90v100q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T320-390Zm283-53 56-56q9-9.273 9-21.636Q668-533 659-542l-69-69q-7-7-16.5-3.25T564-600v50h-68l107 107Z"
		/>
	</svg>
);
