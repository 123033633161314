import { Fragment } from 'react';
import { T, useT } from '@transifex/react';

import { PageWidths } from 'pkg/config/sizes';

import * as flashActions from 'pkg/actions/flashes';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import { useEndpoint } from 'pkg/api/use_endpoint';
import * as routes from 'pkg/router/routes';
import { pushState } from 'pkg/router/state';
import { EditDiscountPayload } from 'pkg/api/models/discount';
import { useCurrentOrganization } from 'pkg/identity';
import useMixedState from 'pkg/hooks/useMixedState';
import Link from 'pkg/router/Link';

import DiscountLargeScreenContentInner from 'routes/payments/discounts/create/form/inner-layout';
import {
	defaultDiscountState,
	DiscountFormState,
	DiscountFormStateInterface,
	DiscountType,
	makeDiscountPayload,
} from 'routes/payments/discounts/create';
import CreateDiscountForm from 'routes/payments/discounts/create/form';
import ButtonActions from 'routes/payments/discounts/create/form/button-actions';
import ContentHeading from 'routes/payments/discounts/create/form/content-heading';

import {
	LargeScreen,
	SmallScreen,
	useSmallScreen,
} from 'components/MediaQuery';

import Form, { FormPayload } from 'components/form/Form';
import * as ActionBar from 'components/layout/ActionBar';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import { Spinner } from 'components/loaders/spinner';

import { FormActionFooter } from 'styles/Form';

interface EditDiscountProps {
	id: number;
}

export default function EditDiscount({ id }: EditDiscountProps) {
	const org = useCurrentOrganization();
	const [formState, setFormState] =
		useMixedState<DiscountFormState>(defaultDiscountState);

	const { record: discount, isLoading } = useEndpoint<models.discount.Discount>(
		endpoints.Discounts.Show(id),
		{},
		(discount) => {
			const newState: Partial<DiscountFormState> = {
				maxRecurringCount: discount.maxRecurringCount,
				active: models.discount.isActive(discount),
				splitAmount: discount.splitAmount,
			};

			if (discount.percentOff) {
				newState.type = DiscountType.Percentage;
			} else {
				newState.type = DiscountType.FixedAmount;
			}

			if (discount.maxUsageCount) {
				newState.showUsageCount = true;
			}

			if (discount.maxRecurringCount > 1) {
				newState.maxRecurringCount = discount.maxRecurringCount;
			}

			setFormState(newState);
		}
	);

	const handleSubmit = async (data: FormPayload) => {
		const payload = makeDiscountPayload(org.id, formState, data);

		setFormState({
			showPreview: true,
			payload,
		});
	};

	if (isLoading) {
		return <Spinner />;
	}

	return (
		<Form onSubmit={handleSubmit}>
			<View
				formState={formState}
				setFormState={setFormState}
				discount={discount}
			/>
		</Form>
	);
}

interface ViewProps extends DiscountFormStateInterface {
	discount: models.discount.Discount;
}

function View({ formState, setFormState, discount }: ViewProps) {
	const org = useCurrentOrganization();
	const t = useT();

	const isSmallScreen = useSmallScreen();

	const handleEditDiscount = async () => {
		setFormState({ saving: true });

		const editPayload: EditDiscountPayload = {
			title: formState.payload.title,
			description: formState.payload.description,
			isActive: formState.active,
		};

		if (formState.showUsageCount) {
			editPayload.maxUsageCount = formState.payload.maxUsageCount;
		} else {
			editPayload.maxUsageCount = 0;
		}

		const [req] = await models.discount.update(discount, editPayload);

		if (req) {
			flashActions.show({
				title: t('Successfully edited discount'),
			});

			pushState(routes.Discounts.Index(org.id));
		}

		setFormState({ saving: false });
	};

	const content = (
		<CreateDiscountForm
			formState={formState}
			setFormState={setFormState}
			discount={discount}
		/>
	);

	const buttonActions = (
		<ButtonActions
			formState={formState}
			setFormState={setFormState}
			handleNextAction={handleEditDiscount}
			edit
		/>
	);

	const contentHeading = (
		<ContentHeading
			title={t('Edit discount code')}
			description={
				!formState.showPreview && (
					<T
						_str="Discounts can be used either in a registration or when creating invoices. {learn}"
						learn={
							<Link href="https://help.360player.com/article/164-how-to-work-with-discount-codes">
								{t('Learn more.')}
							</Link>
						}
					/>
				)
			}
		/>
	);

	return (
		<Fragment>
			{(!formState.showPreview || isSmallScreen) && (
				<ActionBar.SaveBar maxWidth={PageWidths.WIDE}>
					{buttonActions}
				</ActionBar.SaveBar>
			)}
			<LargeScreen>
				{contentHeading}
				<DiscountLargeScreenContentInner formState={formState}>
					{content}
					<FormActionFooter>{buttonActions}</FormActionFooter>
				</DiscountLargeScreenContentInner>
			</LargeScreen>
			<SmallScreen>
				<LargeScreenContent.Inner>
					{contentHeading}
					{content}
				</LargeScreenContent.Inner>
			</SmallScreen>
		</Fragment>
	);
}
