import { ReactNode } from 'react';

export const symbol_arrow_top_right: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M686-575H260v385q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T200-190v-385q0-24.75 17.625-42.375T260-635h425L544-776q-9-9-9-21t9.053-21q9.052-9 21.5-9Q578-827 587-818l192 194q5 5 7 10.133 2 5.134 2 11Q788-597 786-592q-2 5-7 10L588-391q-9 9-21 8.5t-21.391-9.5Q537-401 537-413.5t9-21.5l140-140Z"
		/>
	</svg>
);
