import { ReactNode } from 'react';

export const symbol_store: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M190-800h582q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T772-740H190q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T190-800Zm5 640q-12.75 0-21.375-8.625T165-190v-228h-25q-14.14 0-23.07-11T111-454l44-202q2-11 10.25-17.5T184-680h593q10.5 0 18.75 6.5T806-656l44 202q3 14-5.93 25T821-418h-25v228q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T736-190v-228H547v228q0 12.75-8.625 21.375T517-160H195Zm30-60h262v-198H225v198Zm-50-258h611-611Zm0 0h611l-31-142H206l-31 142Z"
		/>
	</svg>
);
