import { ReactNode } from 'react';

export const symbol_nest_cam_wall_mount: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M154.956-263Q124-263 102-285.031 80-307.062 80-338v-304.589Q80-674 102.044-696t53-22Q186-718 208-696.5q22 21.5 22 52.5 33-62 94.23-100 61.229-38 135.77-38 52 0 99.5 19.5T644-706l187 187q8 8 12.5 19.5T848-477q0 11-4.5 22.5T831-435L548-152q-9 9-20 13.5t-22 4.5q-11 0-22.5-4.5T463-152L276-338q-14-14-25.5-29.5T230-400v62q0 30.938-22.044 52.969-22.045 22.031-53 22.031ZM270-523q0 38 14.5 73t41.545 62.045L506-208l268-269-179.955-179.955Q567-684 532.653-698.5 498.307-713 460.261-713 381-713 325.5-657.583 270-602.167 270-523Zm252 63Z"
		/>
	</svg>
);
