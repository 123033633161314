import { ReactNode } from 'react';

export const symbol_currency_exchange: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M487-293q40 0 66.5-19.5T580-364q0-33-25-55.5T463-470q-60-25-90-54t-30-78q0-44 30-75t80-38v-24q0-11 8-19t19-8h1q11 0 19 8t8 19v24q32 3 57 17.5t42 40.5q6 8 2 18t-14 15q-10 5-20 2t-17-11q-14-18-33-27t-43-9q-39 0-61.5 18T398-602q0 32 26 51t84 43q69 29 98 61t29 83q0 25-9 46t-25.5 36Q584-267 560-257.5T506-245v25q0 11-8 19t-19 8q-11 0-19.5-8.5T451-221v-26q-38-11-66.5-32.5T336-336q-5-9-1-17.5t15-12.5q11-4 20.5 0t14.5 14q16 28 42.5 43.5T487-293Zm-7 253q-112 0-216-66T100-257v107q0 13-8.5 21.5T70-120q-13 0-21.5-8.5T40-150v-180q0-13 8.5-21.5T70-360h180q13 0 21.5 8.5T280-330q0 13-8.5 21.5T250-300H143q51 77 145.5 138.5T480-100q150 0 258.5-101T859-449q1-13 9.5-22t21.5-9q12 0 21 8.5t8 20.5q-6 86-42.5 161T781-159.5Q722-104 644.5-72T480-40Zm0-820q-150 0-258.5 101T101-511q-1 13-9.5 22T70-480q-12 0-21-8.5T41-509q6-86 42.5-161T179-800.5Q238-856 315.5-888T480-920q112 0 216 66t164 151v-107q0-13 8.5-21.5T890-840q13 0 21.5 8.5T920-810v180q0 13-8.5 21.5T890-600H710q-13 0-21.5-8.5T680-630q0-13 8.5-21.5T710-660h107q-51-77-145-138.5T480-860Z"
		/>
	</svg>
);
