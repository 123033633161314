import { ReactNode } from 'react';

export const symbol_escalator_warning: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M260.08-727q-30.08 0-51.58-21.42-21.5-21.421-21.5-51.5 0-30.08 21.42-51.58 21.421-21.5 51.5-21.5 30.08 0 51.58 21.42 21.5 21.421 21.5 51.5 0 30.08-21.42 51.58-21.421 21.5-51.5 21.5ZM695-500q-25 0-42.5-17.5T635-560q0-25 17.5-42.5T695-620q25 0 42.5 17.5T755-560q0 25-17.5 42.5T695-500ZM190-110v-260h-40q-12.75 0-21.375-8.625T120-400v-220q0-24.75 17.625-42.375T180-680h152q17.448 0 31.724 9T386-646l136 278 73-78q6-7 14.357-10.5T627-460h129q18.333 0 31.167 12.833Q800-434.333 800-416v136q0 13-8.5 21.5T770-250h-20v140q0 12.75-8.625 21.375T720-80h-80q-12.75 0-21.375-8.625T610-110v-273l-50 53q-5 5-10.5 7.5t-12.727 2.5h-36.94q-8.833 0-16.174-4.474Q476.318-328.947 472-337L350-583v473q0 12.75-8.625 21.375T320-80H220q-12.75 0-21.375-8.625T190-110Z"
		/>
	</svg>
);
