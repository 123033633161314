import { ReactNode } from 'react';

export const symbol_flip_camera_android: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-80q-127.616 0-229.808-72.5T106-343q-5-12.439 1-23.22 6-10.78 18-14.28t23.186 3.141Q159.371-370.719 164-359q37 99 123.256 159Q373.511-140 480-140q98 0 180.5-50.5T788-328H687q-12.75 0-21.375-8.877t-8.625-22q0-13.123 8.912-21.623Q674.825-389 688-389h162q12.75 0 21.375 8.625T880-359v170q0 12.325-8.272 20.663-8.272 8.337-20.5 8.337T830-169q-9-9-9-21v-79q-57 88-147 138.5T480-80Zm0-741q-97 0-179 51T173-633h101q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q304-590 295.375-581T274-572H110q-12.75 0-21.375-8.625T80-602v-168q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-770v77q57-88 146.5-137.5T480-880q127.616 0 229.808 71.5T854-620q5 12-1 23.323-6 11.322-18 15.677-12 4-22.5-2.5T797-602q-37-99-123.565-159T480-821Zm1 442q-42.5 0-72.25-29.75T379-481q0-42.5 29.75-72.25T481-583q42.5 0 72.25 29.75T583-481q0 42.5-29.75 72.25T481-379Z"
		/>
	</svg>
);
