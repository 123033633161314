import { ReactNode } from 'react';

export const symbol_ramp_left: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M452-150v-576l-69 69q-9 9-21 9t-21-9q-9-9-9-21t9-21l120-120q5-5 10-7t11-2q6 0 11 2t10 7l120 120q9 9 9 21t-9 21q-9 9-21 9t-21-9l-69-69v146q0 95 51 161.5T721-284q12 8 16.5 20.5T733-242q-10 10-24 11t-27-7q-54-32-97.5-70.5T512-387v237q0 13-8.5 21.5T482-120q-13 0-21.5-8.5T452-150Z"
		/>
	</svg>
);
