import { ReactNode } from 'react';

export const symbol_desk: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M80-270v-390q0-24.75 17.625-42.375T140-720h680q24.75 0 42.375 17.625T880-660v390q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T820-270v-70H630v70q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T570-270v-390H140v390q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Q97-240 88.5-248.625T80-270Zm550-290h190v-100H630v100Zm0 160h190v-100H630v100Z"
		/>
	</svg>
);
