import { ReactNode } from 'react';

export const symbol_settings_slow_motion: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M747-93q-11 4-21-2.5T714-115q-2-11 4-22t17-16q38-17 60.5-51t22.5-76q0-42-22.5-76T735-407q-12-5-17.5-16t-3.5-22q2-13 12.5-19.5T748-467q59 22 94.5 73T878-280q0 63-36 114t-95 73Zm-140-1q-20-8-37-19t-32-26q-15-15-26-32.5T493-209q-5-11 1.5-21t19.5-13q11-3 22 2.5t16 17.5q5 11 12 21.5t16 19.5q9 9 19.5 16.5T621-153q12 5 17.5 16t2.5 22q-3 13-13 19.5T607-94Zm43-113q-4 2-8 0t-4-7v-132q0-5 4-7t8 0l102 67q4 2 4 6t-4 6l-102 67ZM514-317q-13-3-19.5-13t-1.5-21q8-20 19-37.5t26-32.5q15-15 32-26t37-19q11-5 21 1.5t13 19.5q3 11-2.5 22T621-407q-11 5-21.5 12.5T580-378q-9 9-16 19.5T552-337q-5 12-16 17.5t-22 2.5ZM421-80q-14 0-25-9t-13-23l-15-94q-19-7-40-19t-37-25l-86 40q-14 6-28 1.5T155-226L97-330q-8-13-4.5-27t15.5-23l80-59q-2-9-2.5-20.5T185-480q0-9 .5-20.5T188-521l-80-59q-12-9-15.5-23t4.5-27l58-104q8-13 22-17.5t28 1.5l86 40q16-13 37-25t40-18l15-95q2-14 13-23t25-9h118q14 0 25 9t13 23l15 94q19 7 40.5 18.5T669-710l86-40q14-6 27.5-1.5T804-734l59 104q8 13 4.5 27.5T852-580l-61 43q-9 8-21 7t-20-10q-9-11-7-24t13-20l44-32-40-72-106 46q-23-26-52-43.5T538-708l-14-112h-88l-14 112q-34 7-63.5 24T306-642l-106-46-40 72 94 69q-4 17-6.5 33.5T245-480q0 17 2.5 33.5T254-413l-94 69 40 72 106-46q20 20 44 35.5t51 24.5q3 40 16.5 76t35.5 66q8 11 2.5 23.5T437-80h-16Zm59-400Zm0 0Zm0-130q-54 0-92 38t-38 92q0 23 7.5 44t21.5 38q9 12 22.5 14t24.5-6q10-8 11-20t-7-20q-10-9-15-22t-5-28q0-29 20.5-49.5T480-550q14 0 26.5 5t22.5 15q8 8 19.5 6.5T568-535q8-11 5.5-24T560-582q-16-14-36.5-21t-43.5-7Z"
		/>
	</svg>
);
