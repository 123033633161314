import { t } from '@transifex/native';
import { useState } from 'react';

import * as models from 'pkg/api/models';
import * as actions from 'pkg/actions';

import SectionTitle from 'components/SectionTitle';
import CardAnatomy, * as Card from 'components/Card';

import Toggle from 'components/form/Toggle';
import Column from 'components/layout/column';
import { FullWidthSettingRow } from 'components/settings/Settings';

interface DisplaySettingsProps {
	group: models.group.Group;
}

export default function DisplaySettings({
	group,
}: DisplaySettingsProps): JSX.Element {
	const [showRatingResults, setShowRatingResults] = useState(
		group.showRatingResults
	);
	const handleShowRatingResults = () => {
		setShowRatingResults(!showRatingResults);
		actions.groups.update(group.id, { showRatingResults: !showRatingResults });
	};
	return (
		<Column>
			<SectionTitle>{t('Visibility settings')}</SectionTitle>
			<CardAnatomy $noBorder>
				<Card.Body>
					<FullWidthSettingRow>
						<Toggle
							standAlone
							label={t('Show data to players')}
							description={t(
								'Show PositionScores and match scoring tables to players.'
							)}
							isActive={showRatingResults}
							onChange={handleShowRatingResults}
						/>
					</FullWidthSettingRow>
				</Card.Body>
			</CardAnatomy>
		</Column>
	);
}
