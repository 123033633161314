import { CSSProperties, Fragment, useContext, useState } from 'react';

import { SlotConfig } from 'pkg/api/models/schedule';

import CreateScheduleItem from 'routes/scheduling/templates/modals/CreateScheduleItem';
import { timesAsNumbers } from 'routes/scheduling/utils';
import { TemplateContext } from 'routes/scheduling/templates/single';

import Icon from 'components/icon';

import * as css from './styles.css';

interface SlotProps {
	slot: [string, string];
	slots: SlotConfig;
	dayNumber: number;

	groupId?: number;
	resourceId?: number;
}

export function SlotItem({
	slot,
	slots,
	dayNumber,
	groupId,
	resourceId,
}: SlotProps): JSX.Element {
	const [showModal, setShowModal] = useState(false);

	const templateContext = useContext(TemplateContext);

	// Find the key of the current slot so I can use the same key towards the converted
	// slot times
	const decrementedDayNumber = dayNumber - 1;
	const slotKey = !slots.sameSlotsAllDays
		? Object.keys(slots.days[decrementedDayNumber]).find(
				(key) => slots.days[decrementedDayNumber][key] === slot
			)
		: Object.keys(slots.times).find((key) => slots.times[key] === slot);
	const times = !templateContext.convertedSlotTimes.sameSlotsAllDays
		? templateContext.convertedSlotTimes.days[decrementedDayNumber][slotKey]
		: templateContext.convertedSlotTimes.times[slotKey];

	const handleShowModal = () => {
		setShowModal(true);
	};

	const handleHideModal = () => {
		setShowModal(false);
	};

	return (
		<Fragment>
			<div
				className={css.slot}
				onClick={handleShowModal}
				style={
					{
						'--order': timesAsNumbers(slot)[0],
					} as CSSProperties
				}>
				<div>{`${times?.[0]} - ${times?.[1]}`}</div>
				<Icon name="add" size={1.3} />
			</div>

			{showModal && (
				<CreateScheduleItem
					groups={templateContext.groups}
					resources={templateContext.resources}
					schedule={templateContext.schedule}
					day={dayNumber}
					startsAt={slot[0]}
					endsAt={slot[1]}
					groupId={groupId}
					hideModal={handleHideModal}
					onBeforeSave={templateContext.onBeforeScheduleItemSave}
					onFail={templateContext.onFailScheduleItemSave}
					onSuccess={templateContext.onSuccessScheduleItemSave}
					scheduleItems={templateContext.scheduleItems}
					resourceId={resourceId}
				/>
			)}
		</Fragment>
	);
}
