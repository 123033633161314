export function Create(): string {
	return `/v1/match-competitions`;
}

export function Delete(ID: number): string {
	return `/v1/match-competitions/${ID}`;
}

export function List(): string {
	return `/v1/match-competitions`;
}

export function Update(ID: number): string {
	return `/v1/match-competitions/${ID}`;
}
