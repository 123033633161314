import { t } from '@transifex/native';

export function range(from, to) {
	let items = [];
	for (var n = from; n <= to; n++) {
		items.push(n);
	}
	return items;
}

export function NOOP() {}

export function fromEntries(entries = {}) {
	if (typeof Object.fromEntries === 'function') {
		return Object.fromEntries(entries);
	} else {
		return Object.entries(entries).reduce((obj, [key, val]) => {
			obj[key] = val;
			return obj;
		}, {});
	}
}

// This helper is needed because some currencies in stripe can't
// be provided in their smallest value e.g. 10 USD needs to be
// provided as 1000 cents.
export const decimalToCentsByCurrency = (cost, currency) => {
	const zeroDecimal = [
		'BIF',
		'CLP',
		'DJF',
		'GNF',
		'JPY',
		'KMF',
		'KRW',
		'MGA',
		'PYG',
		'RWF',
		'UGX',
		'VND',
		'VUV',
		'XAF',
		'XOF',
		'XPF',
	];

	return zeroDecimal.includes(currency.toUpperCase())
		? cost
		: Math.round(cost * 100);
};

// This should probably be moved to the price model in the future
export const formatPriceIntervalToText = (
	interval,
	intervalCount,
	upperCase = false
) => {
	if (!intervalCount) {
		return upperCase ? t('One time') : t('one time');
	}

	switch (interval) {
		case 'year': {
			if (intervalCount === 1) {
				return upperCase ? t('Annually') : t('annually');
			} else {
				return upperCase ? t('Custom') : t('custom');
			}
		}
		case 'month':
			if (intervalCount === 12) {
				return upperCase ? t('Annually') : t('annually');
			} else if (intervalCount === 6) {
				return upperCase ? t('Half-yearly') : t('half-yearly');
			} else if (intervalCount === 3) {
				return upperCase ? t('Quarterly') : t('quarterly');
			} else if (intervalCount === 2) {
				return upperCase ? t('Bi-monthly') : t('bi-monthly');
			} else if (intervalCount === 1) {
				return upperCase ? t('Monthly') : t('monthly');
			} else {
				return upperCase ? t('Custom') : t('custom');
			}
		case 'week':
			if (intervalCount === 1) {
				return upperCase ? t('Weekly') : t('weekly');
			} else {
				return upperCase ? t('Custom') : t('custom');
			}
		case 'day':
			if (intervalCount === 1) {
				return upperCase ? t('Daily') : t('daily');
			} else {
				return upperCase ? t('Custom') : t('custom');
			}
		case '':
			return upperCase ? t('One time') : t('one time');
		default: {
			return upperCase ? t('Custom') : t('custom');
		}
	}
};
