import { ReactNode } from 'react';
import styled from 'styled-components';

import * as styles from 'pkg/config/styles';

import Icon, { IconName } from 'components/icon';

import * as iconStyles from 'components/icon/styles.css';
import Column from 'components/layout/column';

const Wrapper = styled.div`
	padding: var(--spacing-8) var(--spacing-7);
	border-radius: var(--radius-5);
	border: 1px dashed var(--palette-gray-500);
	color: var(--palette-gray-500);

	.${iconStyles.icon} {
		margin-bottom: var(--spacing-3);
	}
`;

const Title = styled.span`
	font-weight: var(--font-weight-semibold);
`;

interface OutlineProps {
	icon?: IconName;
	title: string;
	description?: string;
	children?: ReactNode | ReactNode[];
}

export default function Outline({
	icon,
	title,
	description,
	children,
}: OutlineProps): JSX.Element {
	return (
		<Wrapper>
			<Column justify="center" spacing={styles.spacing._4}>
				{icon && <Icon name={icon} size={2} />}
				<Title>{title}</Title>
				{description && <span>{description}</span>}
				{children}
			</Column>
		</Wrapper>
	);
}
