import { ReactNode } from 'react';

export const symbol_sports_baseball: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M215-264q51-37 79-95t28-121q0-63-28-121.5T215-697q-38 46-56.5 101.5T140-480q0 60 18.5 115T215-264Zm265 124q62 0 118.5-19.5T703-220q-59-48-91.5-116.5T579-480q0-75 32.5-144T703-741q-48-40-104.5-59.5T480-820q-62 0-118.5 19.5T257-741q59 48 92 117t33 144q0 75-33 143.5T257-220q48 41 104.5 60.5T480-140Zm266-124q38-46 56-101t18-115q0-60-18-115.5T746-697q-51 37-79 95.5T639-480q0 63 28 121t79 95ZM480-480Zm0 400q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-155.5t86-127Q252-817 325-848.5T480-880q83 0 155.5 31.5t127 86q54.5 54.5 86 127T880-480q0 82-31.5 155t-86 127.5q-54.5 54.5-127 86T480-80Z"
		/>
	</svg>
);
