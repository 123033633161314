import { ReactNode } from 'react';

export const symbol_ev_shadow: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-120q-75 0-140.5-28.5t-114-77q-48.5-48.5-77-114T120-480q0-75 28.5-140.5t77-114q48.5-48.5 114-77T480-840q75 0 140.5 28.5t114 77q48.5 48.5 77 114T840-480q0 75-28.5 140.5t-77 114q-48.5 48.5-114 77T480-120ZM180-480q0 92 51 167.5T368-202q-43-53-65.5-124.5T280-480q0-82 23-153.5T368-758q-86 35-137 110.5T180-480Zm327 299q109-10 185.5-86.5T779-453L507-181Zm-44-13 316-316q-2-20-6.5-39T760-587L414-241q11 14 23 25.5t26 21.5Zm-72-81 351-351q-8-14-18-27.5T703-680L362-339q5 16 12 31.5t17 32.5Zm-41-107 325-326q-14-11-28-20.5T618-746L340-468q1 23 3 44t7 42Zm-8-145 237-236q-22-8-44.5-12t-45.5-5q-62 33-100 99t-47 154Zm-62 47Z"
		/>
	</svg>
);
