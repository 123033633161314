import { ReactNode } from 'react';

export const symbol_allergies: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M390-513v233q0 13 8.5 21.5T420-250q13 0 21.5-8.5T450-280v-237q0-5-1.5-10t-3.5-10l-60-121q-5-11-17.5-15t-23.5 2q-11 5-14.5 17t1.5 23l59 118Zm180 0 59-118q5-11 1.5-23T616-671q-11-5-23.5-1.5T575-658l-60 121q-2 5-3.5 10t-1.5 10v237q0 13 8.5 21.5T540-250q13 0 21.5-8.5T570-280v-233Zm74 64q11 5 23.5 1.5T685-462l44-89q5-11 1-23t-15-17q-11-5-23-1t-17 15l-44 88q-5 11-1.5 23t14.5 17Zm-329 0q11-5 15-17t-1-23l-44-89q-5-11-17.5-14.5T244-591q-11 5-14.5 17t1.5 23l44 89q5 11 17 14.5t23-1.5ZM480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm0-60q142 0 241-99.5T820-480q0-142-99-241t-241-99q-141 0-240.5 99T140-480q0 141 99.5 240.5T480-140Zm0-340Z"
		/>
	</svg>
);
