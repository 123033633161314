export default function Circle(): JSX.Element {
	return (
		<svg
			viewBox="0 0 25 25"
			width="25"
			height="25"
			preserveAspectRatio="xMidYMid meet"
			fill="none">
			<circle
				cx="12.675"
				cy="12.1099"
				r="6.96905"
				stroke="currentColor"
				strokeWidth="1.3"
			/>
		</svg>
	);
}
