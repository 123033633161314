import { ReactNode } from 'react';

export const symbol_more: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M360-200q-22 0-40-11.5T289-241L144-445q-11-15.972-11-34.986Q133-499 144-515l145-204q13-18 31-29.5t40-11.5h420q24.75 0 42.375 17.625T840-700v440q0 24.75-17.625 42.375T780-200H360Zm420-60v-440 440Zm-431 0h431v-440H349L195-480l154 220Zm26-179q17.425 0 29.212-11.788Q416-462.575 416-480q0-17.425-11.788-29.212Q392.425-521 375-521q-17.425 0-29.212 11.788Q334-497.425 334-480q0 17.425 11.788 29.212Q357.575-439 375-439Zm152 0q17.425 0 29.213-11.788Q568-462.575 568-480q0-17.425-11.787-29.212Q544.425-521 527-521t-29.212 11.788Q486-497.425 486-480q0 17.425 11.788 29.212Q509.575-439 527-439Zm152 0q17.425 0 29.213-11.788Q720-462.575 720-480q0-17.425-11.787-29.212Q696.425-521 679-521t-29.213 11.788Q638-497.425 638-480q0 17.425 11.787 29.212Q661.575-439 679-439Z"
		/>
	</svg>
);
