import { ReactNode } from 'react';

export const symbol_qr_code: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M120-540v-270q0-12.75 8.625-21.375T150-840h270q12.75 0 21.375 8.625T450-810v270q0 12.75-8.625 21.375T420-510H150q-12.75 0-21.375-8.625T120-540Zm60-30h210v-210H180v210Zm-60 420v-270q0-12.75 8.625-21.375T150-450h270q12.75 0 21.375 8.625T450-420v270q0 12.75-8.625 21.375T420-120H150q-12.75 0-21.375-8.625T120-150Zm60-30h210v-210H180v210Zm330-360v-270q0-12.75 8.625-21.375T540-840h270q12.75 0 21.375 8.625T840-810v270q0 12.75-8.625 21.375T810-510H540q-12.75 0-21.375-8.625T510-540Zm60-30h210v-210H570v210Zm188 450v-82h82v82h-82ZM510-367v-83h82v83h-82Zm82 82v-82h83v82h-83Zm-82 83v-83h82v83h-82Zm82 82v-82h83v82h-83Zm83-82v-83h83v83h-83Zm0-165v-83h83v83h-83Zm83 82v-82h82v82h-82Z"
		/>
	</svg>
);
