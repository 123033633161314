import { ReactNode } from 'react';

export const symbol_vo2_max: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M172-160q-55.629 0-93.814-39.958Q40-239.917 40-297v-184q0-45 19.5-85t54.5-68l175-140q17-13 26-32.412T324-847v-72h60v72q0 21.194 9.032 40.621Q402.065-786.952 419-774l175 140q34.579 28.216 53.789 68.108Q667-526 667-481v13q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T607-468v-13q0-31.273-13.5-58.636Q580-567 557-587l-93-73v364q0 12.325-8.272 20.663-8.272 8.337-20.5 8.337T414-276q-9-9-9-21v-410l-51-43-51 43v410q0 57-38.092 97T172-160Zm-.483-60Q202-220 222.5-243q20.5-23 20.5-54v-363l-92 73q-24 20-37.5 47.364Q100-512.273 100-481v184q0 32 20.517 54.5t51 22.5ZM566-160q-14.45 0-24.225-9.775Q532-179.55 532-194v-160q0-14.45 9.775-24.225Q551.55-388 566-388h100q14.45 0 24.225 9.775Q700-368.45 700-354v160q0 14.45-9.775 24.225Q680.45-160 666-160H566Zm14-48h72v-132h-72v132Zm184-64q-10.4 0-17.2-6.8-6.8-6.8-6.8-17.2 0-10.4 6.8-17.2 6.8-6.8 17.2-6.8h122q15 0 24.5 9.5T920-286v76q0 15-9.5 24.5T886-176h-98v48h108q10.4 0 17.2 6.8 6.8 6.8 6.8 17.2 0 10.4-6.8 17.2Q906.4-80 896-80H774q-14.45 0-24.225-9.775Q740-99.55 740-114v-76q0-15 9.5-24.5T774-224h98v-48H764ZM172-440Zm364 0Z"
		/>
	</svg>
);
