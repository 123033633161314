import { ReactNode } from 'react';

export const symbol_nest_doorbell_visitor: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M729.911-240Q701-240 680.5-260.589q-20.5-20.588-20.5-49.5Q660-339 680.589-359.5q20.588-20.5 49.5-20.5Q759-380 779.5-359.411q20.5 20.588 20.5 49.5Q800-281 779.411-260.5q-20.588 20.5-49.5 20.5ZM580-80v-13q0-40 40.5-63.5T730-180q69 0 109.5 23.5T880-93v13H580ZM480-560q33 0 56.5-23.5T560-640q0-33-23.5-56.5T480-720q-33 0-56.5 23.5T400-640q0 33 23.5 56.5T480-560Zm0 80Zm-80 160q0 33 23.5 56.5T480-240q33 0 56.5-23.5T560-320q0-33-23.5-56.5T480-400q-33 0-56.5 23.5T400-320Zm80.088 45Q461-275 448-287.912q-13-12.913-13-32Q435-339 447.912-352q12.913-13 32-13Q499-365 512-352.088q13 12.913 13 32Q525-301 512.088-288q-12.913 13-32 13ZM280-680q0-85 57.5-142.5T480-880q85 0 142.5 57.5T680-680v170q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T620-510v-170q0-59.5-41.5-99.75T480-820q-57 0-98.5 40.25T340-680v400q0 48 31 86.5t77 49.5q10.606 2.288 17.803 10.678 7.197 8.39 7.197 19.068Q473-99 461-90.5 449-82 435-85q-68-16-111.5-69.203T280-280v-400Z"
		/>
	</svg>
);
