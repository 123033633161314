import { Record } from 'immutable';
import { schema } from 'normalizr';

import Form from 'pkg/models/form';
import Order from 'pkg/models/order';
import User from 'pkg/models/user';

export enum Visibilities {
	Unlisted = 'unlisted',
	Public = 'public',
}

export enum Statuses {
	Draft = 'draft',
	Open = 'open',
	Closed = 'closed',
}

interface IFormSubmission {
	id: number;
	form?: number;
	order?: number;
	submittedByUser: number;
	submittedForUser: number;
	formId: number;
	submittedByUserId: number;
	submittedForUserId: number;
	orderId: number;
	subscriptionId: number;
	data: { [key: string]: any };
	status: Statuses;
}

const formSubmissionProps: IFormSubmission = {
	id: 0,
	form: 0,
	order: 0,
	submittedByUser: null,
	submittedForUser: null,
	formId: 0,
	submittedByUserId: 0,
	submittedForUserId: 0,
	orderId: 0,
	subscriptionId: 0,
	data: {},
	status: Statuses.Draft,
};

const FormSubmissionSchema = new schema.Entity('formSubmissions', {
	form: Form.normalizr(),
	order: Order.normalizr(),
	submittedByUser: User.normalizr(),
	submittedForUser: User.normalizr(),
});

class FormSubmission
	extends Record(formSubmissionProps, 'FormSubmissionRecord')
	implements IFormSubmission
{
	static normalizr(): schema.Entity {
		return FormSubmissionSchema;
	}
}

export default FormSubmission;
