import { Fragment, useContext } from 'react';
import { t } from '@transifex/native';

import {
	AddOrderContext,
	AssignedProductProps,
} from 'routes/payments/orders/create';
import RecurringOptions from 'routes/payments/orders/create/components/subscription_settings';
import AssignProducts from 'routes/payments/orders/create/components/AssignProducts';

import Section from 'components/form/Section';
import AssignContacts from 'components/invoices/add_form/select_contacts';
import GeneralOptions from 'components/invoices/add_form/general';
import Column from 'components/layout/column';

interface AddFormProps {
	groupId: number;
}

const Sections: React.FC<React.PropsWithChildren<AddFormProps>> = ({
	groupId,
}) => {
	const OrderContext = useContext(AddOrderContext);

	const recurringAssignedProducts = OrderContext.formState.rows.filter(
		(item: AssignedProductProps) => item.productPrice.recurring === true
	);

	let generalDescription = t('Select the terms for your invoice');

	if (OrderContext.isSubscription) {
		generalDescription = t('Select the terms for your subscription');
	}

	return (
		<Fragment>
			<Section
				icon="person_add"
				title={t('{step}. Select contacts', { step: 1 })}
				description={t('Who will the product be assigned to?')}>
				<AssignContacts organizationId={groupId} />
			</Section>

			<Section
				icon="sell"
				title={t('{step}. Select products', { step: 2 })}
				description={t(
					'Select the products you would like to assign per contact.'
				)}>
				<Column>
					<AssignProducts />
					{OrderContext.isSubscription && (
						<Section title={t('Recurring payment options')} hideDivider>
							<RecurringOptions assignedProducts={recurringAssignedProducts} />
						</Section>
					)}
				</Column>
			</Section>
			<Section
				icon="settings"
				title={t('{step}. General', { step: 3 })}
				description={generalDescription}
				hideDivider={!OrderContext.isSubscription}>
				<GeneralOptions />
			</Section>
		</Fragment>
	);
};

export default Sections;
