import { t } from '@transifex/native';

import * as models from 'pkg/api/models';

import StepModal, { Step } from 'components/step-modal';

import * as UserList from 'components/user/List';

interface LastReadUsersModalProps {
	users: models.user.User[];
	onClose: () => void;
}

function LastReadUsersModal({ users, onClose }: LastReadUsersModalProps) {
	const renderedUsers = users
		.sort((a, b) =>
			`${a.firstName} ${a.lastName}`.localeCompare(
				`${b.firstName} ${b.lastName}`
			)
		)
		.map((user) => (
			<UserList.Item key={user.id}>
				<UserList.Avatar user={user} />
				<UserList.Name>{models.user.fullName(user)}</UserList.Name>
			</UserList.Item>
		));

	return (
		<StepModal onClose={onClose}>
			<Step
				title={t('People who read this message')}
				skipBody
				canGoNext={false}
				hideNext
				prevLabel={t('Close')}>
				<UserList.Base>{renderedUsers}</UserList.Base>
			</Step>
		</StepModal>
	);
}

export default LastReadUsersModal;
