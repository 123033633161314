import { ReactNode } from 'react';

export const symbol_chronic: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M470-532v-148q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T410-680v160q0 5.818 2 10.909Q414-504 419-499l80 80q9 9 21 9t21-9q9-9 9-21t-9-21l-71-71ZM363-230q11-56 54-93t101-37h176q22-35 34-75.179 12-40.178 12-84.821 0-125.357-87.321-212.679Q565.357-820 440-820t-212.679 87.321Q140-645.357 140-520q0 105 63 184.5T363-230ZM520-40q-58 0-102-36.5T363-168q-122-26-202.5-124T80-520q0-150 105-255t255-105q150 0 255 105t105 255q0 43-9.5 83.5T763-360q66 0 111.5 47T920-200q0 66-47 113T760-40H520Zm-80-485Zm200 325ZM520-100h240q42 0 71-29t29-71q0-42-29-71t-71-29H520q-42 0-71 29t-29 71q0 42 29 71t71 29Zm-.175-70Q507-170 498.5-178.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Zm120 0Q627-170 618.5-178.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Zm120 0Q747-170 738.5-178.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Z"
		/>
	</svg>
);
