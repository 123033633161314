import styled, { css } from 'styled-components';

import * as styles from 'pkg/config/styles';

import rgba from 'pkg/rgba';

export const ItemResult = styled.span`
	padding: 0.2rem 0.5rem;
	background: var(--palette-orange-100);
	color: var(--palette-orange-700);
	border-radius: var(--radius-circle);
	display: inline-block;
	font-weight: var(--font-weight-semibold);

	${(props) =>
		props.outcome === 1 &&
		css`
			background: var(--palette-green-200);
			color: var(--palette-green-700);
		`}

	${(props) =>
		props.outcome === -1 &&
		css`
			background: var(--palette-red-100);
			color: var(--palette-red-700);
		`}
`;

export const Table = styled.table`
	width: 100%;
	border-spacing: 0px;
	border-collapse: collapse;
	background-color: rgb(255, 255, 255);
	color: var(--palette-gray-600);
	font-size: var(--font-size-sm);
`;

export const TableRow = styled.tr`
	height: 48px;
`;

export const TableHead = styled.thead`
	${TableRow} {
		border-bottom: 2px solid ${rgba(styles.palette.card.innerDividerColor)};
	}
`;

export const TableHeadCell = styled.th`
	padding: 0 var(--spacing-3);
	font-weight: var(--font-weight-normal);
	text-align: right;
	position: relative;

	${(props) =>
		props.isSticky &&
		css`
			position: sticky;
			left: 0;
			background: rgb(255, 255, 255);
			z-index: 1;
		`};

	${(props) =>
		props.alignLeft &&
		css`
			text-align: left;
		`};

	${(props) =>
		props.grow &&
		css`
			width: 100%;
			max-width: 0;
		`};

	&:first-child {
		padding-left: var(--spacing-5);
	}

	&:last-child {
		padding-right: var(--spacing-5);
	}
`;

export const TableBody = styled.tbody`
	${TableRow} {
		border-bottom: 1px solid ${rgba(styles.palette.card.innerDividerColor)};

		&:last-child {
			border-bottom: 0;
		}
	}
`;

export const TableBodyCell = styled.td`
	font-weight: ${(props) => (props.prominent ? 700 : 500)};
	padding: 0 var(--spacing-3);
	text-align: right;
	white-space: nowrap;

	${(props) =>
		props.numerical &&
		css`
			font-feature-settings:
				'tnum' 1,
				'ss01' 1;
		`}

	${(props) =>
		props.isSticky &&
		css`
			position: sticky;
			left: 0;
			background: rgb(255, 255, 255);
			z-index: 1;
		`};

	${(props) =>
		props.alignLeft &&
		css`
			text-align: left;
		`};

	${(props) =>
		props.grow &&
		css`
			width: 100%;
			max-width: 0;
		`};

	&:first-child {
		padding-left: var(--spacing-5);
	}

	&:last-child {
		padding-right: var(--spacing-5);
	}
`;
