import { ReactNode } from 'react';

export const symbol_eda: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M317.175-880q12.825 0 21.325 8.625T347-850v330q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T287-520v-330q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625Zm166-40q12.825 0 21.325 8.625T513-890v370q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T453-520v-370q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625ZM620-302v-508q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T680-810v473l-60 35Zm-440-18v130q0 38 26 64t64 26h287q9.818 0 18.409-4T592-115l235-236-298 174q-20 11-42 6.5T451-193l-96-127H180Zm90 280q-63 0-106.5-43.5T120-189.774V-380h235q14.25 0 27 6.375T403-356l94 126 183-107 110-64q16-9 34.5-8t32.5 12l3 3q22 17 24.5 43.5T867-304L635-72q-16 16-36.059 24-20.059 8-41.941 8H270Zm-90-340h-60v-390q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T180-770v390Zm0-110h440-440Zm90 390h322-322Z"
		/>
	</svg>
);
