import { ReactNode } from 'react';

export const symbol_landscape_2_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M821-54 54-820q-9-9.067-9-21.533Q45-854 54.053-863q9.052-9 21.5-9Q88-872 97-863L864-96q9 9 9 21t-9.053 21q-9.052 9-21.5 9Q830-45 821-54ZM229-574q-47.083 0-80.042-33Q116-640 116-687q0-32 16.5-58t42.5-40l153 153q-14 26-40.686 42T229-574Zm128 290Zm233-172Zm151-203 146 419q5 14-1.5 24T868-202q-11 4-22-.5T830-221L684-640q-10-26-31.5-42T604-698q-27 0-48.767 15.98Q533.465-666.041 525-640l-13 43q-3.824 12-14.912 18Q486-573 474-577q-12-4-18-14.5t-2-22.5l13-43q14-46 51.5-73.5t85.795-27.5q46.364 0 84.035 27Q726-704 741-659ZM191-449q7.872-15 22.436-24t31.484-9q17.655 0 32.367 10Q292-462 300-445l37 88q6 13 21.312 11.929Q373.625-346.143 378-359l51-172 49 49-42 140q-8 26-30 42t-49.368 16Q332-284 311.5-297.5 291-311 282-334l-39-92-123 249q-3.707 7.727-10.75 12.364Q102.207-160 94.052-160 77-160 68.5-174t-.5-29l123-246Z"
		/>
	</svg>
);
