import { ReactNode } from 'react';

export const symbol_signal_cellular_pause: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m131-131 698-698q14-14 32.5-6.344T880-808v388q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T820-420v-315L224-140h316q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q570-97 561.375-88.5T540-80H152q-19.688 0-27.344-18.5Q117-117 131-131Zm548.825 51Q667-80 658.5-88.625T650-110v-170q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T710-280v170q0 12.75-8.675 21.375Q692.649-80 679.825-80Zm140 0Q807-80 798.5-88.625T790-110v-170q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T850-280v170q0 12.75-8.675 21.375Q832.649-80 819.825-80ZM522-438Z"
		/>
	</svg>
);
