import { ReactNode } from 'react';

export const symbol_infrared: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M670-479.821q0 80.821-27.616 151.896-27.617 71.076-75.946 127.936Q558-190 545-189q-13 1-22-8t-8.308-21.5Q515.385-231 523-241q41-48 64-109.084 23-61.085 23-130Q610-549 587-610t-64-109q-7.615-10-8.308-22.5Q514-754 523-763t21.844-7.875Q557.689-769.75 566-760q48.533 56.959 76.267 128.159Q670-560.642 670-479.821ZM550-480q0 56.442-18.5 106.221T480-284q-8.067 9.625-20.533 10.312Q447-273 438-281.897q-9-8.896-9-21.5Q429-316 437-326q25-32 39-71.214 14-39.215 14-82.786t-14-82.786Q462-602 437-634q-8-10.208-8-22.604Q429-669 438-678t21.467-8.312Q471.933-685.625 480-676q33 40 51.5 89.779Q550-536.442 550-480Zm-120 0q0 31.555-10 60.277Q410-391 393-368q-7.209 10-19.105 10Q362-358 353-367t-9.5-21q-.5-12 6.5-23 9.565-15.146 14.783-32.396Q370-460.646 370-480t-5.217-36.604Q359.565-533.854 350-549q-7-11-6.5-23t9.5-21q8.8-9 20.9-9 12.1 0 19.1 10 17 23 27 51.723 10 28.722 10 60.277Zm-120 .089Q310-451 289.438-430.5 268.875-410 240-410v220q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T180-190v-580q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T240-770v220q29 0 49.5 20.589 20.5 20.588 20.5 49.5Z"
		/>
	</svg>
);
