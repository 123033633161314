import {
	CSSProperties,
	Dispatch,
	ReactNode,
	SetStateAction,
	useContext,
	useRef,
} from 'react';

import useUuid from 'pkg/hooks/useUuid';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';

import { SpyContext } from 'components/ScrollSpy';

import * as css from './styles.css';

interface GridRowProps {
	cols: number;

	setIsInView: Dispatch<SetStateAction<boolean>>;

	children: ReactNode;
}

const GridRow = ({ children, cols, setIsInView }: GridRowProps) => {
	const ref = useRef(null);
	const spy = useContext(SpyContext);
	const id = useUuid();

	const onTrigger = (e: IntersectionObserverEntry) => {
		setIsInView((show) => {
			if (e.isIntersecting && !show) {
				return true;
			}
			if (!e.isIntersecting && show) {
				return false;
			}
		});
	};

	useComponentDidMount(() => {
		if (ref.current) {
			spy.observe(ref.current, id, onTrigger);
			const r = ref.current;
			return () => {
				spy.unobserve(r, id);
			};
		}
	});

	return (
		<div
			className={css.wrapper}
			ref={ref}
			data-id={id}
			style={
				{
					'--cols': cols,
				} as CSSProperties
			}>
			{children}
		</div>
	);
};

export default GridRow;
