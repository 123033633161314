import { ReactNode } from 'react';

export const symbol_quick_reference: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M655-150q8 0 14-6t6-14v-110q0-8-6-14t-14-6q-8 0-14 6t-6 14v110q0 8 6 14t14 6Zm0-189q8 0 14-6.15t6-14.35q0-8.2-6-14.35T655-380q-9 0-14.5 6t-5.5 14.286q0 8.285 5.5 14.5Q646-339 655-339ZM180-820v295-4 389-680 186-186Zm129 400h134q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T443-480H309q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T309-420Zm0 170h74q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T383-310h-74q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T309-250ZM180-80q-24 0-42-18t-18-42v-680q0-24 18-42t42-18h336q12.444 0 23.722 5T559-862l183 183q8 8 13 19.278 5 11.278 5 23.722v91q0 13-8.675 21-8.676 8-21.5 8-12.825 0-21.325-8.625T700-546v-88H541q-12.75 0-21.375-8.625T511-664v-156H180v680h264q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q474-97 465.375-88.5T444-80H180Zm475-389q80.51 0 137.255 56.745Q849-355.51 849-275q0 80.51-56.745 137.255Q735.51-81 655-81q-80.51 0-137.255-56.745Q461-194.49 461-275q0-80.51 56.745-137.255Q574.49-469 655-469Z"
		/>
	</svg>
);
