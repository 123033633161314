import { ReactNode } from 'react';

export const symbol_dew_point: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M620-530q-21 0-35.5-14.5T570-580q0-13 4.5-24t12-21.5q7.5-10.5 16.5-20t17-19.5q8 10 17 19.5t16.5 20q7.5 10.5 12 21.5t4.5 24q0 21-14.5 35.5T620-530Zm170-132q-21 0-35.5-14.5T740-712q0-13 4.5-24t12-21.5q7.5-10.5 16.5-20t17-19.5q8 10 17 19.5t16.5 20q7.5 10.5 12 21.5t4.5 24q0 21-14.5 35.5T790-662Zm0 262q-21 0-35.5-14.5T740-450q0-13 4.5-24t12-21.5q7.5-10.5 16.5-20t17-19.5q8 10 17 19.5t16.5 20q7.5 10.5 12 21.5t4.5 24q0 21-14.5 35.5T790-400ZM336-121q-76 0-129-53t-53-129q0-48 24-90.5t66-68.5v-286q0-38 27-65t65-27q38 0 65 27t27 65v286q42 26 66 68.5t24 90.5q0 76-53 129t-129 53ZM214-303h244q0-40-19-71.5T388-420l-20-9v-319q0-14-9-23t-23-9q-14 0-23 9t-9 23v319l-20 9q-32 14-51 46t-19 71Z"
		/>
	</svg>
);
