import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import { AllowedActionable } from 'pkg/api/models/allowed_actionable';
import { Dateable } from 'pkg/api/models/dateable';

export enum UserProductStatuses {
	Active = 'active',
	Expired = 'expired',
	PastDue = 'past_due',
	Open = 'open',
}

export interface UserProduct extends Dateable, AllowedActionable {
	id: number;
	userId: number;
	productId: number;
	orderID: number;
	validUntil: number;
	status: string;
	stripeStatus: string;
	productPriceId: number;

	order: models.order.Order;
	user: models.user.User;
	product: models.product.Product;
	productPrice: models.productPrice.ProductPrice;
}

export function getUserProductStatusTranslation(
	status: UserProductStatuses
): string {
	switch (status) {
		case UserProductStatuses.Active:
			return t('Active');
		case UserProductStatuses.Open:
			return t('Open');
		case UserProductStatuses.PastDue:
			return t('Past due');
		case UserProductStatuses.Expired:
			return t('Expired');
	}
}
