import { ReactNode } from 'react';

export const symbol_nature: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M450-140v-180h-90q-83 0-141.5-58.5T160-520q0-60 33-110.5t89-73.5q9-75 65.5-125.5T480-880q76 0 132.5 50.5T678-704q56 23 89 73.5T800-520q0 83-58.5 141.5T600-320h-90v180h200q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q740-97 731.375-88.5T710-80H260q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T260-140h190Zm-90-240h240q58.333 0 99.167-40.833Q740-461.667 740-520q0-42-24-76.5T654-648l-32-14-4-35q-7-53-46.183-88T480-820q-52.634 0-91.817 35Q349-750 342-697l-4 35-32 14q-38 17-62 51.726t-24 76.397Q220-462 260.833-421q40.834 41 99.167 41Zm120-220Z"
		/>
	</svg>
);
