// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PVBR676WyvxioKY30cIa {
	font-size: var(--font-size-3xs);
	line-height: var(--font-line-height-2xs);
}

.aRCkSuPB1PUt56S9eHA6 {
	font-size: var(--font-size-2xs);
	line-height: var(--font-line-height-2xs);
}

.ocEZ2EKNEsC0Zhm_J9mV {
	font-size: var(--font-size-xs);
	line-height: var(--font-line-height-xs);
}

.a7upi6oAfNntPa7WoWmQ {
	font-size: var(--font-size-sm);
	line-height: var(--font-line-height-sm);
}

.AqLpwC4wJEP5gkTuX1v3 {
	font-size: var(--font-size-base);
	line-height: var(--font-line-height-base);
}

.wflS3E5IRaJm9VHjGedV {
	font-size: var(--font-size-lg);
	line-height: var(--font-line-height-lg);
}

.g6qauNYtjfY6NG3xdaiw {
	font-size: var(--font-size-xl);
	line-height: var(--font-line-height-xl);
}

.jHwv8GU4vR1BuiIwSaH3 {
	font-size: var(--font-size-2xl);
	line-height: var(--font-line-height-2xl);
}

.xttVCr6fshw0ZFG35g2c {
	font-size: var(--font-size-3xl);
	line-height: var(--font-line-height-3xl);
}

.x0bvcoPEp62KcH_peD1W {
	font-size: var(--font-size-4xl);
	line-height: var(--font-line-height-4xl);
}

._11fI6STWjdTMlsTatSwC {
	font-size: var(--font-size-5xl);
	line-height: var(--font-line-height-5xl);
}
`, "",{"version":3,"sources":["webpack://./routes/kitchen_sink/views/typography/styles.css"],"names":[],"mappings":"AAAA;CACC,+BAA+B;CAC/B,wCAAwC;AACzC;;AAEA;CACC,+BAA+B;CAC/B,wCAAwC;AACzC;;AAEA;CACC,8BAA8B;CAC9B,uCAAuC;AACxC;;AAEA;CACC,8BAA8B;CAC9B,uCAAuC;AACxC;;AAEA;CACC,gCAAgC;CAChC,yCAAyC;AAC1C;;AAEA;CACC,8BAA8B;CAC9B,uCAAuC;AACxC;;AAEA;CACC,8BAA8B;CAC9B,uCAAuC;AACxC;;AAEA;CACC,+BAA+B;CAC/B,wCAAwC;AACzC;;AAEA;CACC,+BAA+B;CAC/B,wCAAwC;AACzC;;AAEA;CACC,+BAA+B;CAC/B,wCAAwC;AACzC;;AAEA;CACC,+BAA+B;CAC/B,wCAAwC;AACzC","sourcesContent":[".3xs {\n\tfont-size: var(--font-size-3xs);\n\tline-height: var(--font-line-height-2xs);\n}\n\n.2xs {\n\tfont-size: var(--font-size-2xs);\n\tline-height: var(--font-line-height-2xs);\n}\n\n.xs {\n\tfont-size: var(--font-size-xs);\n\tline-height: var(--font-line-height-xs);\n}\n\n.sm {\n\tfont-size: var(--font-size-sm);\n\tline-height: var(--font-line-height-sm);\n}\n\n.base {\n\tfont-size: var(--font-size-base);\n\tline-height: var(--font-line-height-base);\n}\n\n.lg {\n\tfont-size: var(--font-size-lg);\n\tline-height: var(--font-line-height-lg);\n}\n\n.xl {\n\tfont-size: var(--font-size-xl);\n\tline-height: var(--font-line-height-xl);\n}\n\n.2xl {\n\tfont-size: var(--font-size-2xl);\n\tline-height: var(--font-line-height-2xl);\n}\n\n.3xl {\n\tfont-size: var(--font-size-3xl);\n\tline-height: var(--font-line-height-3xl);\n}\n\n.4xl {\n\tfont-size: var(--font-size-4xl);\n\tline-height: var(--font-line-height-4xl);\n}\n\n.5xl {\n\tfont-size: var(--font-size-5xl);\n\tline-height: var(--font-line-height-5xl);\n}\n"],"sourceRoot":""}]);
// Exports
var _1 = `PVBR676WyvxioKY30cIa`;
export { _1 as "3xs" };
var _2 = `aRCkSuPB1PUt56S9eHA6`;
export { _2 as "2xs" };
export var xs = `ocEZ2EKNEsC0Zhm_J9mV`;
export var sm = `a7upi6oAfNntPa7WoWmQ`;
export var base = `AqLpwC4wJEP5gkTuX1v3`;
export var lg = `wflS3E5IRaJm9VHjGedV`;
export var xl = `g6qauNYtjfY6NG3xdaiw`;
var _3 = `jHwv8GU4vR1BuiIwSaH3`;
export { _3 as "2xl" };
var _4 = `xttVCr6fshw0ZFG35g2c`;
export { _4 as "3xl" };
var _5 = `x0bvcoPEp62KcH_peD1W`;
export { _5 as "4xl" };
var _6 = `_11fI6STWjdTMlsTatSwC`;
export { _6 as "5xl" };
export default ___CSS_LOADER_EXPORT___;
