import { t } from '@transifex/native';
import { useContext } from 'react';

import * as Context from 'design/context_menu';
import Button from 'design/button';

interface ResetProps {
	onReset: () => void;
}

export function Reset({ onReset }: ResetProps): JSX.Element {
	const contextMenuContext = useContext(Context.MenuContext);

	const handleClick = () => {
		onReset();
		contextMenuContext.close();
	};

	return (
		<Button inline onClick={handleClick} testid="filters.clear">
			{t('Clear all')}
		</Button>
	);
}
