import { ReactNode } from 'react';

export const symbol_travel_explore: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q137 0 241.5 80T863-595q4 13-2 24.5t-18.879 14.773Q830-553 820-560.5q-10-7.5-14-19.5-22-74-74-131.5T607-799v18q0 35-24 61t-59 26h-87v87q0 16.575-13.5 27.787Q410-568 393-568h-83v88h80q12.75 0 21.375 8.625T420-450v95h-67L149-559q-5 20-7 39.667-2 19.666-2 39.333 0 128.02 82.5 223.51Q305-161 431-144q11.676 1.68 19.338 11.34Q458-123 458-110.5T448.859-90q-9.141 8-21.859 6-148-20-247.5-131.5T80-480Zm749 351L716-241q-21 15-45.5 23t-50.065 8q-71.015 0-120.725-49.618Q450-309.235 450-380.118 450-451 499.618-500.5q49.617-49.5 120.5-49.5Q691-550 740.5-500.29T790-379.565q0 25.565-8.5 50.065Q773-305 759-283l112 112q9 9 9.5 21t-8.5 21q-9 9-21.5 9t-21.5-9ZM619.859-270Q666-270 698-301.859q32-31.859 32-78T698.141-458q-31.859-32-78-32T542-458.141q-32 31.859-32 78T541.859-302q31.859 32 78 32Z"
		/>
	</svg>
);
