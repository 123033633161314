import { ReactNode } from 'react';

export const symbol_format_underlined_squiggle: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M294-120q-14 0-28.5-5.5T240-139q-9-6-19-13.5t-21-7.5q-12 0-21-9t-9-21q0-13 9-21.5t21-8.5q14 0 29 5t26 13q8 6 18 14t21 8q11 0 21-8t18-14q11-8 26-13t28-5q14 0 28.5 5t25.5 13q9 6 19 14t21 8q11 0 21-8t18-14q11-8 26-13t28-5q14 0 28.5 5t25.5 13q9 6 19 14t21 8q11 0 21-8t19-14q11-8 25.5-13t28.5-5q12 0 21 8.5t9 21.5q0 12-9 21t-21 9q-11 0-21 7.5T722-139q-11 8-26 13.5t-29 5.5q-14 0-28.5-5.5T613-139q-9-6-19-13.5t-21-7.5q-11 0-21 7.5L534-139q-11 8-26 13.5t-28 5.5q-14 0-28.5-5.5T426-139q-9-6-19-13.5t-21-7.5q-11 0-21 7.5L347-139q-11 8-25.5 13.5T294-120Zm186-180q-101 0-170.5-69T240-538v-283q0-17 11.5-28t28.5-11q17 0 28.5 11.5T320-820v284q0 66 46 111t114 45q68 0 114-45t46-111v-285q0-17 11.5-28t28.5-11q17 0 28.5 11.5T720-820v282q0 100-69.5 169T480-300Z"
		/>
	</svg>
);
