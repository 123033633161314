const icon = {
	name: 'discount',
	objects: [
		{
			type: 'path',
			attributes: {
				d: 'M14.7285 9.15328C14.9655 9.39033 14.9655 9.77467 14.7285 10.0117L9.89347 14.8467C9.65642 15.0838 9.27209 15.0838 9.03504 14.8467C8.79799 14.6097 8.79799 14.2253 9.03504 13.9883L13.87 9.15328C14.1071 8.91623 14.4914 8.91623 14.7285 9.15328Z',
				fillRule: 'evenodd',
				clipRule: 'evenodd',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M9.76732 9.58327C9.60035 9.58327 9.46498 9.71864 9.46498 9.88561C9.46498 10.0526 9.60033 10.1879 9.76732 10.1879C9.9343 10.1879 10.0696 10.0526 10.0696 9.88561C10.0696 9.71862 9.93428 9.58327 9.76732 9.58327ZM8.25097 9.88561C8.25097 9.04816 8.92987 8.36926 9.76732 8.36926C10.6048 8.36926 11.2837 9.04818 11.2837 9.88561C11.2837 10.7231 10.6048 11.4019 9.76732 11.4019C8.92989 11.4019 8.25097 10.7231 8.25097 9.88561Z',
				fillRule: 'evenodd',
				clipRule: 'evenodd',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M14.6024 13.8121C14.4354 13.8121 14.3 13.9474 14.3 14.1144C14.3 14.2814 14.4354 14.4167 14.6024 14.4167C14.7693 14.4167 14.9047 14.2814 14.9047 14.1144C14.9047 13.9474 14.7693 13.8121 14.6024 13.8121ZM13.086 14.1144C13.086 13.2769 13.7649 12.5981 14.6024 12.5981C15.4398 12.5981 16.1187 13.2769 16.1187 14.1144C16.1187 14.9518 15.4398 15.6307 14.6024 15.6307C13.7649 15.6307 13.086 14.9518 13.086 14.1144Z',
				fillRule: 'evenodd',
				clipRule: 'evenodd',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M12 5.21401C8.2522 5.21401 5.21401 8.2522 5.21401 12C5.21401 15.7478 8.2522 18.786 12 18.786C15.7478 18.786 18.786 15.7478 18.786 12C18.786 8.2522 15.7478 5.21401 12 5.21401ZM4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12Z',
				fillRule: 'evenodd',
				clipRule: 'evenodd',
			},
		},
	],
};

export default icon;
