import { ReactNode } from 'react';

export const symbol_face_3: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M479.736-220Q622-220 721-319.344T820-560q0-39.102-8.5-75.051Q803-671 788-704q-28 21-60.609 32.5Q694.783-660 660-660q-55 0-101.5-28T480-761q-32 45-78.5 73T300-660q-34.783 0-67.391-11.5Q200-683 172-704q-15 33-23.5 69.5T140-560q0 141.312 99.267 240.656Q338.533-220 479.736-220ZM354.225-463Q377-463 392.5-478.725q15.5-15.726 15.5-38.5Q408-540 392.275-555.5q-15.726-15.5-38.5-15.5Q331-571 315.5-555.275q-15.5 15.726-15.5 38.5Q300-494 315.725-478.5q15.726 15.5 38.5 15.5Zm253 0Q630-463 645.5-478.725q15.5-15.726 15.5-38.5Q661-540 645.275-555.5q-15.726-15.5-38.5-15.5Q584-571 568.5-555.275q-15.5 15.726-15.5 38.5Q553-494 568.725-478.5q15.726 15.5 38.5 15.5ZM300-720q63 0 106.5-43.5T450-870v-29q-77 7-141 44.5T203-757q20 17 44.839 27 24.838 10 52.161 10Zm360 0q27.323 0 52.161-10Q737-740 757-757q-42-60-106-97.5T510-899v29q0 63 43.5 106.5T660-720ZM66-80q-26.145 0-44.072-19.5Q4-119 6-145l38-417q8-84 45.5-157t96-126.5q58.5-53.5 134-84T480-960q85 0 160.5 30.5t134 84Q833-792 870.5-719T916-562l38 417q2 26-15.928 45.5Q920.145-80 894-80H66Zm413.948-80Q342-160 235.5-243T94-454L66-140h828l-28-314q-35 128-141 211t-245.052 83ZM510-899Zm-60 0Zm29.948 759H894 66h413.948Z"
		/>
	</svg>
);
