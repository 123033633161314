import { ReactNode } from 'react';

export const symbol_ungroup: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M530-488 369-327q-9 9-21 9t-21-9q-9-9-9-21t9-21l161-161H360q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T360-590h200q12.75 0 21.375 8.625T590-560v200q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T530-360v-128Zm-20-292q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T510-840h270q24.75 0 42.375 17.625T840-780v270q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T780-510v-270H510ZM180-120q-24 0-42-18t-18-42v-630q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T180-810v630h630q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-120H180Z"
		/>
	</svg>
);
