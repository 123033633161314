import { ReactNode } from 'react';

export const symbol_data_loss_prevention: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M430.089-580Q459-580 479.5-600.589q20.5-20.588 20.5-49.5Q500-679 479.411-699.5q-20.588-20.5-49.5-20.5Q401-720 380.5-699.411q-20.5 20.588-20.5 49.5Q360-621 380.589-600.5q20.588 20.5 49.5 20.5Zm.411 160q38.5 0 74.708-15.877Q541.417-451.753 568-481q-32-20-67-29.5t-71-9.5q-36 0-71 9.5T292-481q28 29 64 45t74.5 16Zm-.437 160Q301-260 210.5-350.5T120-570q0-129 90.5-219.5T430-880q129 0 219.5 90.5T740-569.937Q740-516 720.5-464.5T669-373l190 190q9 9 9 21t-9 21q-9 9-21 9t-21-9L627-331q-40 32-91.5 51.5T430.063-260Zm.437-60Q534-320 607-393.5t73-177Q680-674 607.083-747 534.167-820 430-820q-103 0-176.5 72.917Q180-674.167 180-570q0 103 73.5 176.5t177 73.5Zm-.5-250Z"
		/>
	</svg>
);
