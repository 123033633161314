import { ReactNode } from 'react';

export const symbol_devices: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M125-160q-18.75 0-31.875-13.176Q80-186.353 80-205.176 80-224 93.125-237T125-250h35v-490q0-24.75 17.625-42.375T220-800h590q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-740H220v490h195q18.75 0 31.875 13.176 13.125 13.177 13.125 32Q460-186 446.875-173T415-160H125Zm425 0q-12.75 0-21.375-8.625T520-190v-460q0-12.75 8.625-21.375T550-680h300q12.75 0 21.375 8.625T880-650v460q0 12.75-8.625 21.375T850-160H550Zm30-90h240v-370H580v370Zm0 0h240-240Z"
		/>
	</svg>
);
