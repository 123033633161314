// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.czqDMOnRzESPkX37bCZV {
	font-weight: var(--font-weight-semibold);
	font-size: var(--font-size-xl);
	line-height: var(--font-line-height-xl);
}

.LiMTgfpyZDkXNm2aY9Ib {
	font-weight: var(--font-weight-semibold);
}

.xFRtT4E4BK46ChMlXp1S {
	color: var(--palette-gray-500);
	font-size: var(--font-size-sm);
	line-height: var(--font-line-height-sm);
}
`, "",{"version":3,"sources":["webpack://./routes/payments/products/form/preview/styles.css"],"names":[],"mappings":"AAAA;CACC,wCAAwC;CACxC,8BAA8B;CAC9B,uCAAuC;AACxC;;AAEA;CACC,wCAAwC;AACzC;;AAEA;CACC,8BAA8B;CAC9B,8BAA8B;CAC9B,uCAAuC;AACxC","sourcesContent":[".title {\n\tfont-weight: var(--font-weight-semibold);\n\tfont-size: var(--font-size-xl);\n\tline-height: var(--font-line-height-xl);\n}\n\n.cost {\n\tfont-weight: var(--font-weight-semibold);\n}\n\n.grayText {\n\tcolor: var(--palette-gray-500);\n\tfont-size: var(--font-size-sm);\n\tline-height: var(--font-line-height-sm);\n}\n"],"sourceRoot":""}]);
// Exports
export var title = `czqDMOnRzESPkX37bCZV`;
export var cost = `LiMTgfpyZDkXNm2aY9Ib`;
export var grayText = `xFRtT4E4BK46ChMlXp1S`;
export default ___CSS_LOADER_EXPORT___;
