import * as models from 'pkg/api/models';
import { isSameDay } from 'pkg/date';
import DateTime from 'pkg/datetime';
import { QueryStateInterface } from 'pkg/hooks/query-state';

/**
 * Group events by the days it spans, returns a object with a start of day timestamp as key and array ov elements as value
 *
 * @param events
 * @param addToday optional - set as false if you don't want the current day to be returned even if there's no events on that day
 * @returns Object with timestamp key and array of events
 */

export function groupByDate(events: models.event.Event[], addToday = true) {
	const groupedEvents: { [key: string]: models.event.Event[] } = {};

	events.forEach((event) => {
		// Convert the Unix timestamp to a Date object
		const startsAtDate = new Date(event.startsAt * 1000);
		const startsAtDateTime = new DateTime(startsAtDate);
		const endsAtDate = new Date(event.endsAt * 1000);
		const identifier = startsAtDateTime.startOfDay;

		if (!isSameDay(endsAtDate, startsAtDateTime)) {
			const initialDate = new Date(startsAtDate);

			while (initialDate <= endsAtDate) {
				const id = new DateTime(initialDate).startOfDay;
				if (groupedEvents[id]) {
					groupedEvents[id].push(event);
				} else {
					groupedEvents[id] = [event];
				}

				initialDate.setDate(initialDate.getDate() + 1);
			}
		} else {
			// Group the objects by the day
			if (groupedEvents[identifier]) {
				groupedEvents[identifier].push(event);
			} else {
				groupedEvents[identifier] = [event];
			}
		}
	});

	// Add today's date if it doesn't exist
	const today = new DateTime(new Date()).startOfDay;
	if (!groupedEvents[today] && addToday) {
		groupedEvents[today] = [];
	}

	// Sort the dates in ascending order
	const sortedEntries = Object.entries(groupedEvents).sort(
		(a, b) => Number.parseInt(a[0], 10) - Number.parseInt(b[0], 10)
	);

	// Convert the sorted entries back to an object
	const sortedGroupedObjects: { [key: string]: models.event.Event[] } = {};
	sortedEntries.forEach(([date, group]) => {
		sortedGroupedObjects[date] = group;
	});

	Object.values(sortedGroupedObjects).forEach((group) => {
		group.sort((a, b) => a.startsAt - b.startsAt);
	});

	return sortedGroupedObjects;
}

/**
 * Returns the events for the date that is provided
 * @param groupedEvents
 * @param dateTime
 * @returns models.event.Event[]
 */
export function getEventsForDate(
	groupedEvents: { [key: string]: models.event.Event[] },
	dateTime: DateTime
) {
	return groupedEvents[dateTime.startOfDay.toString()] || [];
}

export function getCalendarTimestamp(qs: QueryStateInterface) {
	return (
		Number.parseInt(qs.get('timestamp') as string, 10) ||
		Number.parseInt(sessionStorage.getItem('calendarTimestamp')) ||
		Math.round(new DateTime(new Date()).startOfDay / 1000)
	);
}
