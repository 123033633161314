import { ReactNode } from 'react';

export const symbol_night_sight_auto_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M392-567 263-694q29-20 63-32t69-17q14.211-2 22.105 9.5Q425-722 418-710q-14 26-21 53.325-7 27.326-7 56.675 0 8 .5 16.5T392-567Zm305 300-86-85q20 2 38.5 1.5T687-356q17-5 29.5 8t7.5 30q-6 14-12.682 26.333Q704.636-279.333 697-267Zm-136 43L224-561q-17 28.431-25.5 58.715Q190-472 190-440q0 104.167 72.917 177.083Q335.833-190 440-190q32 0 62.5-9t58.5-25Zm-121 94q-129 0-219.5-90.5T130-440q0-45.15 12.5-87.075Q155-569 179-607L50-736q-9-8.8-9-20.9 0-12.1 9-21.1 9-9 21.833-9 12.834 0 22.167 9l676 677q9 9 9 21t-9.053 21q-9.052 9-21.5 9Q736-50 727-59L607-179q-38 24-79.925 36.5T440-130Zm-47-263Zm265-211-20 56.836Q634-536 625.31-530q-8.689 6-19.31 6-17 0-27.5-14t-4.554-31l101.09-287q3.964-11 13.652-17.5Q698.375-880 710-880h26q12 0 21.583 6.72Q767.167-866.56 771-856l101.056 287q5.944 17-4.556 31T840-524q-10.667 0-19.394-6.229Q811.879-536.458 808-547l-20.27-57H658Zm16-50h98l-49-155-49 155Z"
		/>
	</svg>
);
