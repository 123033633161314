import { Fragment, memo } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import rgba from 'pkg/rgba';

import AvatarStack from 'components/avatar/Stack';

const Wrapper = styled.div`
	padding: 0 var(--spacing-5);
	height: 33px;
	display: grid;
	grid-template-columns: auto 1fr;
	align-items: center;
	font-size: var(--font-size-sm);
	font-weight: var(--font-weight-normal);
	color: var(--palette-gray-500);
	border-bottom: 1px solid ${rgba(styles.palette.card.innerDividerColor)};
`;

const Likes = styled.div`
	cursor: pointer;

	strong {
		margin-right: var(--spacing-2);
	}
`;

const Comments = styled.div`
	text-align: right;

	strong {
		margin-right: var(--spacing-2);
	}
`;

/**
 * @type {React.Element<any>}
 */
const Impressions = memo(
	({ commentCount, likeCount, likeUsers, showLikes }) => {
		if (commentCount === 0 && likeCount === 0) return null;

		return (
			<Wrapper>
				<Likes>
					{likeCount > 0 && (
						<AvatarStack
							size={20}
							users={likeUsers}
							onClick={showLikes}
							showRest={false}
							renderRestWith={
								<Fragment>
									<strong>{likeCount}</strong>
									{likeCount === 1 ? t('Like') : t('Likes')}
								</Fragment>
							}
						/>
					)}
				</Likes>
				{commentCount > 0 && (
					<Comments>
						<strong>{commentCount}</strong>
						{commentCount === 1 ? t('Comment') : t('Comments')}
					</Comments>
				)}
			</Wrapper>
		);
	}
);

export default Impressions;
