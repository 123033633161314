import { ReactNode } from 'react';

export const symbol_interests: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m105-565 149-268q5-8 11.525-11.5t14.5-3.5q7.975 0 14.475 3.5T306-833l149 268q5 7.692 4.5 15.385-.5 7.692-4.5 14.615-3.824 6.75-10.706 10.875T429-520H131q-8.462 0-15.385-4.172-6.923-4.173-10.769-11Q101-542 100.5-549.667 100-557.333 105-565Zm176 445q-66 0-113-47t-47-113q0-67 47-113.5T281-440q66 0 113 47t47 113q0 66-47 113t-113 47Zm0-60q42 0 71-29t29-71q0-42-29-71t-71-29q-42 0-71 29t-29 71q0 42 29 71t71 29Zm-99-400h196l-98-176-98 176Zm338 430v-260q0-12.75 8.625-21.375T550-440h260q12.75 0 21.375 8.625T840-410v260q0 12.75-8.625 21.375T810-120H550q-12.75 0-21.375-8.625T520-150Zm60-30h200v-200H580v200Zm81-356-60-51q-69-58-95-91.5T480-753q0-45 31.5-76t78.5-31q26 0 50 12t40 35q16-23 40-35t50-12q47 0 78.5 31t31.5 76q0 41-26 74.5T759-587l-60 51q-8.049 7-19.024 7Q669-529 661-536Zm19-63q85-70 112.5-100t27.5-52q0-21.966-12.857-35.483Q794.286-800 772-800q-13.103 0-25.552 7Q734-786 715-769l-35 33-35-33q-18.667-17.36-31.333-24.18Q601-800 588-800q-22.286 0-35.143 13.517T540-751q0 22 27.5 52T680-599Zm0-101Zm-400 32Zm1 388Zm399 0Z"
		/>
	</svg>
);
