import { t } from '@transifex/native';
import { Component } from 'react';
import styled from 'styled-components';

import * as styles from 'pkg/config/styles';

import Icon from 'components/icon';

import BigSelectButton from 'components/rating/create/stacks/big-select-button';

const ShowIconRight = styled(Icon)`
	transform: rotateY(180deg);
`;

const FootWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: column;
	height: 100%;
	width: 100%;
	padding: 20px 16px;

	@media ${styles.breakpoint.small} {
		padding: 16px;
		justify-content: flex-start;
	}
`;

const ButtonWrapper = styled.div`
	display: flex;
	margin: 1rem 0;
`;

export default class FootComponent extends Component {
	componentDidMount() {
		if (this.props.primaryFoot) {
			this.props.onSelect('primaryFoot', this.props.primaryFoot);
		}
	}

	handleLeftFoot = () => {
		this.props.onSelect('primaryFoot', 'left');
	};

	handleRightFoot = () => {
		this.props.onSelect('primaryFoot', 'right');
	};

	render() {
		return (
			<FootWrapper>
				<ButtonWrapper>
					<BigSelectButton
						onClick={this.handleLeftFoot}
						active={this.props.primaryFoot === 'left'}
						text={t(`Left`)}>
						<Icon name="shoe" />
					</BigSelectButton>
					<BigSelectButton
						onClick={this.handleRightFoot}
						active={this.props.primaryFoot === 'right'}
						text={t(`Right`)}>
						<ShowIconRight name="shoe" />
					</BigSelectButton>
				</ButtonWrapper>
			</FootWrapper>
		);
	}
}
