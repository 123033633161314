import { ReactNode } from 'react';

export const symbol_chair: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M193-120q-12 0-21-9t-9-21v-42h-13q-45.833 0-77.917-32.083Q40-256.167 40-302v-244q0-51 31-77.5t73-26.5v-86q0-46 29-75t75-29h465q46 0 74.5 29t28.5 75v86q42 0 73 26.5t31 77.5v244q0 45.833-32.083 77.917Q855.833-192 810-192h-13v42q0 12-9 21t-21 9q-12 0-21-9t-9-21v-42H223v42q0 12-9 21t-21 9Zm-43-132h660q20 0 35-15t15-35v-244q0-20-12-32t-32-12q-20 0-32 12t-12 32v176H188v-176q0-20-12-32t-32-12q-20 0-32 12t-12 32v244q0 20 15 35t35 15Zm98-178h464v-116q0-31 14-51.5t30-32.5v-106q0-20-12-32t-31.742-12H247.742Q228-780 216-768t-12 32v106q16 12 30 32.5t14 51.5v116Zm232 0Zm0 178Zm0-118Z"
		/>
	</svg>
);
