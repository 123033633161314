import { ReactNode } from 'react';

export const symbol_full_hd: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M435-446h80v64q0 10.833 7.116 17.917 7.117 7.083 18 7.083Q551-357 558-364.083q7-7.084 7-17.917v-196q0-10.833-7.116-17.917-7.117-7.083-18-7.083Q529-603 522-595.917q-7 7.084-7 17.917v82h-80v-82q0-10.833-7.116-17.917-7.117-7.083-18-7.083Q399-603 392-595.917q-7 7.084-7 17.917v196q0 10.833 7.116 17.917 7.117 7.083 18 7.083Q421-357 428-364.083q7-7.084 7-17.917v-64Zm195 89h110q18.75 0 31.875-13.125T785-402v-156q0-18.75-13.125-31.875T740-603H630q-10.833 0-17.917 7.083Q605-588.833 605-578v196q0 10.833 7.083 17.917Q619.167-357 630-357Zm25-50v-146h80v146h-80Zm-430-39h75q10.833 0 17.917-7.116 7.083-7.117 7.083-18Q325-482 317.917-489q-7.084-7-17.917-7h-75v-57h95q10.833 0 17.917-7.116 7.083-7.117 7.083-18Q345-589 337.917-596q-7.084-7-17.917-7H200q-10.833 0-17.917 7.083Q175-588.833 175-578v196q0 10.833 7.116 17.917 7.117 7.083 18 7.083Q211-357 218-364.083q7-7.084 7-17.917v-64ZM100-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h760q24.75 0 42.375 18T920-740v520q0 24-17.625 42T860-160H100Zm0-60h760v-520H100v520Zm0 0v-520 520Z"
		/>
	</svg>
);
