import { t } from '@transifex/native';
import { Fragment, useState, ChangeEvent } from 'react';

import spacing from 'pkg/config/spacing';

import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as sdk from 'pkg/core/sdk';

import * as StepModal from 'components/step-modal';
import { useSmallScreen } from 'components/MediaQuery';

import { MaterialSymbolVariant } from 'components/material-symbols/symbols';
import * as Input from 'components/form/inputs';
import Column from 'components/layout/column';
import { IconContextMenu } from 'components/material-symbols/icon-context-menu';

import Button from 'design/button';
import { useDialog } from 'design/dialog';

interface CustomLinksEditModalProps {
	groupId: number;
	folder: models.groupLinkFolder.GroupLinkFolder;
	hideModal: () => void;
	refresh: () => void;
}

interface FolderPayload {
	groupId: number;
	title: string;
	iconName: string;
}

export default function EditModal({
	groupId,
	folder,
	hideModal,
	refresh,
}: CustomLinksEditModalProps): JSX.Element {
	const [iconName, setIconName] = useState<MaterialSymbolVariant>(
		folder.iconName as MaterialSymbolVariant
	);
	const [folderTitle, setFolderTitle] = useState<string>();

	const handleIconNameChange = (iconName: string) => {
		setIconName(iconName as MaterialSymbolVariant);
	};

	const handlefolderTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
		setFolderTitle(event.currentTarget.value);
	};

	const performDelete = async (folderId: number) => {
		await sdk.destroy(endpoints.GroupLinksFolder.Delete(folderId));
		refresh();
	};

	const removeDialog = useDialog({
		message: t('Are you sure you want to remove this link?'),
		onConfirm: () => performDelete(folder.id),
	});

	const isSmallScreen = useSmallScreen();

	const onUpdate = async (folderId: number) => {
		const payload: FolderPayload = {
			groupId: groupId,
			title: folderTitle,
			iconName: iconName,
		};

		const request = await sdk.patch(
			endpoints.GroupLinksFolder.UpdateFolder(folderId),
			{},
			payload
		);

		if (!request.ok) {
			return false;
		}
		refresh();
		return true;
	};
	return (
		<Fragment>
			<StepModal.Base onClose={hideModal}>
				<StepModal.Step
					footerContent={
						<div>
							<Button
								label={t('Delete')}
								large
								block={isSmallScreen}
								caution
								onClick={removeDialog}
							/>
						</div>
					}
					title={t('Update folder')}
					onNext={() => onUpdate(folder.id)}
					nextLabel={t('Update')}>
					<Column spacing={spacing._5}>
						<Input.Group
							label={t('Folder title')}
							hint={t('This title will appear in the menu for the user')}
							required>
							<Input.Field
								onChange={handlefolderTitleChange}
								defaultValue={folder.title}
							/>
						</Input.Group>
						<Input.Group
							label={t('Icon')}
							hint={t('This icon will appear in the menu for the user')}
							required>
							<IconContextMenu
								iconName={iconName}
								onIconSelect={handleIconNameChange}
							/>
						</Input.Group>
					</Column>
				</StepModal.Step>
			</StepModal.Base>
		</Fragment>
	);
}
