import { ReactNode } from 'react';

export const symbol_keyboard_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M857-56 127-784q-9-9.067-8.5-21.533Q119-818 128.053-827q9.052-9 21.5-9Q162-836 171-827L899-98l-42 42ZM330-325q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T330-385h282l60 60H330Zm550-375v425q0 15-9.318 22.5t-20.5 7.5Q839-245 829.5-252.5 820-260 820-275v-425H395q-15 0-22.5-9.318t-7.5-20.5q0-11.182 7.5-20.682Q380-760 395-760h425q24 0 42 18t18 42ZM140-200q-24 0-42-18.5T80-260v-440q0-24 18-42t42-18h97l60 60H140v440h597l60 60H140Zm292-280Zm169-2Zm-368.175-93Q220-575 211.5-583.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5ZM501-584l-42-42q3.778-3.75 9.444-6.375Q474.111-635 480.156-635q12.844 0 21.344 8.625Q510-617.75 510-605q0 6-2.625 11.625T501-584Zm102.825 9Q591-575 582.5-583.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Zm123 0Q714-575 705.5-583.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Zm-494 125Q220-450 211.5-458.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Zm124 0Q344-450 335.5-458.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5ZM625-459l-42-42q3.778-3.75 9.444-6.375Q598.111-510 604.156-510q12.844 0 21.344 8.625Q634-492.75 634-480q0 6-2.625 11.625T625-459Zm101.825 9Q714-450 705.5-458.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Z"
		/>
	</svg>
);
