const order = {
	name: 'order',
	objects: [
		{
			type: 'path',
			attributes: {
				d: 'M16.75 4.35H7.75C7.39102 4.35 7.1 4.64102 7.1 5V9H5.75V5C5.75 3.89543 6.64543 3 7.75 3H16.75C17.8546 3 18.75 3.89543 18.75 5V18C18.75 19.1046 17.8546 20 16.75 20H10.75V18.65H16.75C17.109 18.65 17.4 18.359 17.4 18V5C17.4 4.64101 17.109 4.35 16.75 4.35Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M6.25 10.25C6.66421 10.25 7 10.5858 7 11V11.24H7.09C8.45527 11.24 9.51001 12.3876 9.51001 13.74C9.51001 14.1542 9.17422 14.49 8.76001 14.49C8.3458 14.49 8.01001 14.1542 8.01001 13.74C8.01001 13.1525 7.56472 12.74 7.09 12.74H7V14.9606L8.01151 15.3134C8.36391 15.4388 8.76659 15.6357 9.06738 16.0209C9.37482 16.4146 9.5 16.9057 9.5 17.47C9.5 18.6977 8.54981 19.75 7.29999 19.75H7V20C7 20.4142 6.66421 20.75 6.25 20.75C5.83579 20.75 5.5 20.4142 5.5 20V19.75H5.42001C4.05474 19.75 3 18.6025 3 17.25C3 16.8358 3.33579 16.5 3.75 16.5C4.16421 16.5 4.5 16.8358 4.5 17.25C4.5 17.8375 4.94529 18.25 5.42001 18.25H5.5V16.026L4.4985 15.6766C4.15083 15.5528 3.74626 15.3595 3.4439 14.9745C3.13434 14.5803 3.01001 14.0874 3.01001 13.52C3.01001 12.3056 3.95704 11.24 5.20999 11.24H5.5V11C5.5 10.5858 5.83579 10.25 6.25 10.25ZM5.20999 12.74H5.5V14.4373L4.99941 14.2627C4.75864 14.1768 4.66861 14.1053 4.62361 14.048C4.58567 13.9997 4.51001 13.8726 4.51001 13.52C4.51001 13.0544 4.86294 12.74 5.20999 12.74ZM7 18.25V16.5492L7.51065 16.7273C7.74667 16.8116 7.83856 16.8845 7.88513 16.9441C7.92518 16.9954 8 17.1243 8 17.47C8 17.9423 7.65016 18.25 7.29999 18.25H7Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M15.6486 7.00974L8.74855 6.99026C8.47241 6.98948 8.24918 6.76499 8.24996 6.48885C8.25074 6.21271 8.47523 5.98948 8.75137 5.99026L15.6514 6.00974C15.9275 6.01052 16.1507 6.23501 16.15 6.51115C16.1492 6.78729 15.9247 7.01051 15.6486 7.00974Z',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M15.7486 9.01199C16.0247 9.01273 16.2492 8.78948 16.2499 8.51334C16.2507 8.23719 16.0274 8.01273 15.7513 8.01199L11.7513 8.0012C11.4752 8.00046 11.2507 8.22371 11.25 8.49985C11.2492 8.77599 11.4725 9.00045 11.7486 9.0012L15.7486 9.01199Z',
			},
		},
	],
};

export default order;
