import { Fragment, useState } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import useConfirm from 'pkg/hooks/useConfirm';
import * as models from 'pkg/api/models';

import ReplyForm from 'containers/comment/ReplyForm';
import UserLink from 'containers/user/Link';

import { FormattedContent } from 'components/formatted-content';
import RelativeDateTime from 'components/RelativeDateTime';
import Icon from 'components/icon';
import Avatar from 'components/avatar';

import * as Context from 'design/context_menu';

import * as CommentStyles from 'styles/Comment';

const Wrapper = styled.div``;

interface CommentProps {
	commentId?: number;
	commentType?: string;

	content: string;
	createdAt?: number;
	user: models.user.User;
	footerLabel?: string;
	linkUser?: boolean;

	onDelete?: (commentId: number) => void;
	onEdit?: (commentId: number, content: string) => void;
}

export default function Comment({
	commentId,
	commentType,

	content,
	createdAt,

	footerLabel,
	linkUser,

	user,

	onDelete,
	onEdit,
}: CommentProps): JSX.Element {
	const [editMode, setEditMode] = useState<boolean>(false);

	const activateEditMode = () => setEditMode(true);
	const deactivateEditMode = () => setEditMode(false);

	const handleDelete = useConfirm({
		message: t('Delete this comment? This action cannot be undone.'),
		onConfirm: () => onDelete(commentId),
	});

	const handleEdit = (content: string) => {
		onEdit(commentId, content);
		setEditMode(false);
	};

	if (!user) return null;

	return (
		<Wrapper>
			<CommentStyles.Wrapper $isEditing={editMode}>
				<CommentStyles.Avatar>
					<Avatar user={user} />
				</CommentStyles.Avatar>
				<CommentStyles.Container $isEditing={editMode}>
					<div>
						{!editMode ? (
							<FormattedContent
								raw={content}
								maxLength={280}
								prefixWith={
									<Fragment>
										{linkUser ? (
											<UserLink userId={user.id} />
										) : (
											<CommentStyles.UserName>
												{models.user.fullName(user)}
											</CommentStyles.UserName>
										)}
									</Fragment>
								}
								wrapWith={<CommentStyles.Body />}
							/>
						) : (
							<CommentStyles.Body data-editing={editMode}>
								<ReplyForm isEditing onSend={handleEdit} content={content} />
							</CommentStyles.Body>
						)}
					</div>
					<CommentStyles.Footer>
						{footerLabel && (
							<Fragment>
								<span>{footerLabel}</span>
								{createdAt && <CommentStyles.FooterActionDivider />}
							</Fragment>
						)}
						{createdAt && <RelativeDateTime dateTime={createdAt} />}
						{!editMode ? (
							<Fragment>
								<Fragment>
									{(onEdit || onDelete || commentType) && (
										<Context.Menu
											toggleWith={
												<CommentStyles.FooterContextTrigger>
													<Icon name="context-menu" size={1.25} />
												</CommentStyles.FooterContextTrigger>
											}>
											{onEdit && (
												<Context.Item icon="edit" onClick={activateEditMode}>
													{t('Edit')}
												</Context.Item>
											)}
											{onDelete && (
												<Context.ConfirmItem
													caution
													closeOnClick
													icon="delete"
													onConfirm={async () => handleDelete()}>
													{t('Delete')}
												</Context.ConfirmItem>
											)}
											<Context.ReportItem
												contentType={commentType}
												contentId={commentId}
											/>
										</Context.Menu>
									)}
								</Fragment>
							</Fragment>
						) : (
							<Fragment>
								<CommentStyles.FooterActionDivider />
								<CommentStyles.FooterAction
									caution
									onClick={deactivateEditMode}>
									{t('Cancel')}
								</CommentStyles.FooterAction>
							</Fragment>
						)}
					</CommentStyles.Footer>
				</CommentStyles.Container>
			</CommentStyles.Wrapper>
		</Wrapper>
	);
}
