import { ReactNode } from 'react';

export const symbol_ent: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M460.062-330H560q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T560-390h-94l5.714-55.186Q474-464 487.973-477T521-490h119q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T640-550H522q-43.083 0-74.25 28.417Q416.583-493.167 412-451l-35 338q-2 14 6.5 23.5t22.682 9.5Q418-80 426.5-88q8.5-8 10.5-19l23.062-223ZM240-252q-57-52-88.5-121.5T120-520q0-150 105-255t255-105q125 0 221.5 73.5T827-615l55 218q4 14-5 25.5T853-360h-93v140q0 24.75-17.625 42.375T700-160H600v50q0 12.75-8.675 21.375Q582.649-80 569.825-80 557-80 548.5-88.625T540-110v-80q0-12.75 8.625-21.375T570-220h130v-170q0-12.75 8.625-21.375T730-420h84l-45-180q-24-97-105-158.5T480-820q-125 0-212.5 86.5T180-522.46q0 64.417 26.324 122.392Q232.649-342.092 281-297l19 18v169q0 12.75-8.675 21.375Q282.649-80 269.825-80 257-80 248.5-88.625T240-110v-142Zm257-198Z"
		/>
	</svg>
);
