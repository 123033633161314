import { ReactNode } from 'react';

export const symbol_onsen: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M241-598q-22-31-35.5-66T192-737v-16.5q0-8.5 2-16.5 2-12 10-21t20-9q13 0 22 9t7 21q-1 8-1.5 14.5T251-741q0 34 14.5 64t34.5 57q21 29 35 62.5t14 69.5q0 5-.5 9t-.5 9q-1 12-9 21t-20 9q-13 0-22.5-9t-7.5-21q1-5 1-10v-10q0-31-15.5-57T241-598Zm160 0q-22-31-35.5-66T352-737v-16.5q0-8.5 2-16.5 2-12 10-21t20-9q13 0 22 9t7 21q-1 8-1.5 14.5T411-741q0 34 14.5 64t34.5 57q21 29 35 62.5t14 69.5q0 5-.5 9t-.5 9q-1 12-9 21t-20 9q-13 0-22.5-9t-7.5-21q1-5 1-10v-10q0-31-15.5-57T401-598Zm160 0q-22-31-35.5-66T512-737v-16.5q0-8.5 2-16.5 2-12 10-21t20-9q13 0 22 9t7 21q-1 8-1.5 14.5T571-741q0 34 14.5 64t34.5 57q21 29 35 62.5t14 69.5q0 5-.5 9t-.5 9q-1 12-9 21t-20 9q-13 0-22.5-9t-7.5-21q1-5 1-10v-10q0-31-15.5-57T561-598Zm-81 438q-150 0-255-58.5T120-359q0-38 25.5-71.5T220-492v74q-20 14-30 28.5T180-359q0 52 93 95.5T480-220q114 0 207-43.5t93-95.5q0-17-10.5-32T740-418v-74q49 28 74.5 61.5T840-359q0 82-105 140.5T480-160Z"
		/>
	</svg>
);
