import { ReactNode } from 'react';

export const symbol_left_panel_open: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M527-580v200q0 9.917 9.5 13.458Q546-363 553-370l89-89q9-9 9-21t-9-21l-89-89q-7-7-16.5-3.458Q527-589.917 527-580ZM180-120q-24.75 0-42.375-17.625T120-180v-600q0-24.75 17.625-42.375T180-840h600q24.75 0 42.375 17.625T840-780v600q0 24.75-17.625 42.375T780-120H180Zm147-60v-600H180v600h147Zm60 0h393v-600H387v600Zm-60 0H180h147Z"
		/>
	</svg>
);
