import { ReactNode } from 'react';

export const symbol_settings_system_daydream: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M354-333h273q36 0 62-24.099 26-24.098 26-59Q715-451 689-474.5T627-498h-15q-5-53-39-91t-85-38q-42 0-76 24.5T364-538h-18q-39.239 0-65.119 30Q255-478 255-436t28.5 72.5Q312-333 354-333Zm0-60q-16.575 0-27.788-12Q315-417 315-436t11.212-30.5Q337.425-478 354-478h55v-1q0-35 23.5-61.5T488-567q29 0 46.5 25t17.5 52v52h75q14 0 21 5.5t7 16.5q0 11-7 17t-21 6H354ZM140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm0-60h680v-520H140v520Zm0 0v-520 520Z"
		/>
	</svg>
);
