import { ReactNode } from 'react';

export const symbol_p2p: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-160q-24.75 0-42.375-17.625T80-220v-600q0-24.75 17.625-42.375T140-880h230q24.75 0 42.375 17.625T430-820v204h-60v-81H140v354h290v123q0 24.75-17.625 42.375T370-160H140Zm461 80q-29.287 0-50.144-20.856Q530-121.713 530-151v-192h60v80h230v-353H530v-124q0-24.75 17.625-42.375T590-800h230q24.75 0 42.375 17.625T880-740v600q0 24.75-17.625 42.375T820-80H601ZM140-283v63h230v-63H140Zm450 80v63h230v-63H590ZM140-757h230v-63H140v63Zm450 81h230v-64H590v64ZM319.825-450Q307-450 298.5-458.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Zm160 0Q467-450 458.5-458.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Zm160 0Q627-450 618.5-458.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5ZM140-283v63-63Zm450 80v63-63ZM140-757v-63 63Zm450 81v-64 64Z"
		/>
	</svg>
);
