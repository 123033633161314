import { ReactNode } from 'react';

export const symbol_wc: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M212-110v-270h-20q-12.75 0-21.375-8.625T162-410v-198q0-29 21.5-50.5t50.242-21.5h104.516Q367-680 388.5-658.5 410-637 410-608v198q0 12.75-8.625 21.375T380-380h-20v270q0 12.75-8.625 21.375T330-80h-88q-12.75 0-21.375-8.625T212-110Zm74.08-624q-30.08 0-51.58-21.42-21.5-21.421-21.5-51.5 0-30.08 21.42-51.58 21.421-21.5 51.5-21.5 30.08 0 51.58 21.42 21.5 21.421 21.5 51.5 0 30.08-21.42 51.58-21.421 21.5-51.5 21.5ZM630-110v-210h-65q-14.545 0-23.273-12Q533-344 537-358l75-273q7.216-23.593 26.608-36.296Q658-680 684-680q26 0 45.392 12.704Q748.784-654.593 756-631l75 273q4 14-4.727 26-8.728 12-23.273 12h-65v210q0 12.75-8.625 21.375T708-80h-48q-12.75 0-21.375-8.625T630-110Zm54.08-624q-30.08 0-51.58-21.42-21.5-21.421-21.5-51.5 0-30.08 21.42-51.58 21.421-21.5 51.5-21.5 30.08 0 51.58 21.42 21.5 21.421 21.5 51.5 0 30.08-21.42 51.58-21.421 21.5-51.5 21.5Z"
		/>
	</svg>
);
