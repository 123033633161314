import { ReactNode } from 'react';

export const symbol_home_max_dots: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M420-445q12 0 21-8.625T450-475q0-12-9-21t-21.5-9q-12.5 0-21 9t-8.5 21.5q0 12.5 8.625 21T420-445Zm-120 0q12 0 21-8.625T330-475q0-12-9-21t-21.5-9q-12.5 0-21 9t-8.5 21.5q0 12.5 8.625 21T300-445Zm240 0q12 0 21-8.625T570-475q0-12-9-21t-21.5-9q-12.5 0-21 9t-8.5 21.5q0 12.5 8.625 21T540-445Zm120 0q12 0 21-8.625T690-475q0-12-9-21t-21.5-9q-12.5 0-21 9t-8.5 21.5q0 12.5 8.625 21T660-445ZM180-250h600q33 0 56.5-23.5T860-330v-290q0-33-23.5-56.5T780-700H180q-33 0-56.5 23.5T100-620v290q0 33 23.5 56.5T180-250Zm100 60H180q-59 0-99.5-40.5T40-330v-290q0-59 40.5-99.5T180-760h600q59 0 99.5 40.5T920-620v290q0 59-40.5 99.5T780-190H680q0 13-8.625 21.5T650-160H310q-12.75 0-21.375-8.625T280-190Zm200-285Z"
		/>
	</svg>
);
