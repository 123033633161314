import { ChangeEvent, Fragment, MouseEvent, createRef, useState } from 'react';
import { useT } from '@transifex/react';

import { updatePerformanceReviewTemplate } from 'pkg/actions/groups';

import * as models from 'pkg/api/models';
import { useEndpoint } from 'pkg/api/use_endpoint';
import * as endpoints from 'pkg/api/endpoints/auto';

import Base, * as Cards from 'components/Card';
import ModalBase from 'components/modal';

import * as Input from 'components/form/inputs';
import { Spinner } from 'components/loaders/spinner';

import Button from 'design/button';

interface UpdateDialogueTemplateModalProps {
	groupId: number;
	closeModal: () => void;
}

export default function UpdateDialogueTemplateModal({
	groupId,
	closeModal,
}: UpdateDialogueTemplateModalProps) {
	const t = useT();
	const [value, setValue] = useState('');
	const modal = createRef<any>();
	const { record: group, isLoading } = useEndpoint<models.group.Group>(
		endpoints.Groups.Show(groupId),
		{},
		(data) => {
			const templateValue = models.group.getPerformanceReviewTemplate(data);

			setValue(templateValue);
		}
	);

	const save = async (e: MouseEvent) => {
		e.preventDefault();

		const ok = await updatePerformanceReviewTemplate({
			groupId: group.id,
			template: value,
		});

		if (!ok) {
			return;
		}

		modal.current.close();
	};

	const handleFormChange = (e: ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value);
	};

	let content = <Spinner />;

	if (!isLoading) {
		content = (
			<Fragment>
				<Cards.Header>
					<Cards.Heading>{t(`Player dialogue template`)}</Cards.Heading>
				</Cards.Header>

				<Cards.Divider />

				<Cards.Body>
					<p>
						{t(
							`This is where you set the template for the player dialogue. For the players to access and answer to the template, they go into their dialogue and press "Use template".`
						)}
					</p>
				</Cards.Body>

				<Cards.Divider />

				<Cards.Body $flex>
					<Input.Area
						name="performance-review-template"
						minRows={15}
						onChange={handleFormChange}
						value={value}
					/>
				</Cards.Body>
				<Cards.Divider key="divider" />

				<Cards.Footer key="form-actions">
					<Button
						primary
						key="action-0"
						onClick={save}
						testid="step_modal.next">
						{t('Save')}
					</Button>
				</Cards.Footer>
			</Fragment>
		);
	}

	return (
		<ModalBase forwardedRef={modal} onClose={closeModal}>
			<Base>{content}</Base>
		</ModalBase>
	);
}
