import { ReactNode } from 'react';

export const symbol_markdown: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m610-475-27-27q-9-9-21-8.5t-21 9.5q-9 9-9 21t9 21l78 78q9 9 21 9t21-9l78-78q9-9 9-21t-9-21q-9-9-21.5-9t-21.5 9l-26 26v-95q0-13-8.5-21.5T640-600q-13 0-21.5 8.5T610-570v95ZM140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm0-60h680v-520H140v520Zm0 0v-520 520Zm129-330h53v102q0 10.833 7.116 17.917 7.117 7.083 18 7.083Q358-423 365-430.083q7-7.084 7-17.917v-102h60v165q0 10.833 7.116 17.917 7.117 7.083 18 7.083Q468-360 475-367.083q7-7.084 7-17.917v-175q0-14-13-27t-27-13H259q-14 0-27 13t-13 27v175q0 10.833 7.116 17.917 7.117 7.083 18 7.083Q255-360 262-367.083q7-7.084 7-17.917v-165Z"
		/>
	</svg>
);
