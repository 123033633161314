export default {
	name: 'drag-handle',
	objects: [
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M7.5 6.7C7.5 7.52843 8.17157 8.2 9 8.2C9.82843 8.2 10.5 7.52843 10.5 6.7C10.5 5.87158 9.82843 5.2 9 5.2C8.17157 5.2 7.5 5.87158 7.5 6.7ZM7.5 12C7.5 12.8284 8.17157 13.5 9 13.5C9.82843 13.5 10.5 12.8284 10.5 12C10.5 11.1716 9.82843 10.5 9 10.5C8.17157 10.5 7.5 11.1716 7.5 12ZM9 18.7999C8.17157 18.7999 7.5 18.1284 7.5 17.2999C7.5 16.4715 8.17157 15.7999 9 15.7999C9.82843 15.7999 10.5 16.4715 10.5 17.2999C10.5 18.1284 9.82843 18.7999 9 18.7999ZM13.0001 6.7C13.0001 7.52843 13.6717 8.2 14.5001 8.2C15.3285 8.2 16.0001 7.52843 16.0001 6.7C16.0001 5.87158 15.3285 5.2 14.5001 5.2C13.6717 5.2 13.0001 5.87158 13.0001 6.7ZM14.5001 13.5C13.6717 13.5 13.0001 12.8284 13.0001 12C13.0001 11.1716 13.6717 10.5 14.5001 10.5C15.3285 10.5 16.0001 11.1716 16.0001 12C16.0001 12.8284 15.3285 13.5 14.5001 13.5ZM13.0001 17.2999C13.0001 18.1284 13.6717 18.7999 14.5001 18.7999C15.3285 18.7999 16.0001 18.1284 16.0001 17.2999C16.0001 16.4715 15.3285 15.7999 14.5001 15.7999C13.6717 15.7999 13.0001 16.4715 13.0001 17.2999Z',
			},
		},
	],
};
