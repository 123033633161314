import * as React from 'react';
import styled from 'styled-components';

import AssetImage from 'components/AssetImage';

import Default, { DefaultProps } from 'components/missing-entities/Default';

const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	img {
		max-width: 50%;
	}
`;

type ImageProps = DefaultProps & {
	imgSrc: string;
};

const WithImage: React.FC<React.PropsWithChildren<ImageProps>> = ({
	centered,
	imgSrc,
	children,
}) => (
	<Wrapper>
		<Default centered={centered}>{children}</Default>
		<AssetImage src={imgSrc} />
	</Wrapper>
);

export default WithImage;
