import { ReactNode } from 'react';

export const symbol_motorcycle: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M447-540H343h213-109ZM200-200q-83 0-141.5-58.5T0-400q0-83 58.5-141.5T200-600h474L574-700H453q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T453-760h121q12.444 0 23.722 5T617-742l142 142q90 0 145.5 61T960-400q0 83-58.5 141.5T760-200q-83 0-141.5-58.5T560-400q0-18 2.5-35.5T572-470L471-369q-5 5-10.217 7-5.218 2-10.783 2h-54q-14 70-69 115t-127 45Zm559.765-60Q818-260 859-300.765q41-40.764 41-99Q900-458 859.235-499q-40.764-41-99-41Q702-540 661-499.235q-41 40.764-41 99Q620-342 660.765-301q40.764 41 99 41ZM200-260q45 0 84-28t53-82h-87q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T250-430h87q-14-54-53-82t-84-28q-58.333 0-99.167 40.765Q60-458.471 60-400.235 60-342 100.833-301q40.834 41 99.167 41Zm198-170h49l109-110H343q20 20 35 48.5t20 61.5Z"
		/>
	</svg>
);
