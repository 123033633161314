import { Fragment, useState } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as actions from 'pkg/actions';
import * as models from 'pkg/api/models';

import AttendedList from 'routes/event/single/event-users/AttendedList';
import InvitedList from 'routes/event/single/event-users/InvitedList';
import { useEventContext } from 'routes/event/single';
import ResendInvites from 'routes/event/single/event-users/ResendInvites';
import AutoSaveMessage from 'routes/event/single/event-users/AutoSaveMessage';

import { useUserSelectModal } from 'components/user-select-modal';

import * as Input from 'components/form/inputs';
import Column from 'components/layout/column';
import Row from 'components/layout/row';

import { DialogOptions, useDialog } from 'design/dialog';

import * as css from './styles.css';

interface EventUsersProps {
	event: models.event.Event;
}

export default function EventUsers({ event }: EventUsersProps): JSX.Element {
	const eventContext = useEventContext();

	const defaultSendInvites: boolean =
		event.type === models.event.EventTypes.Practice ? false : true;

	const [sendInvites, setSendInvites] = useState<boolean>(defaultSendInvites);

	const [selectedOrganizers, setSelectedOrganizers] = useState<
		models.user.User[]
	>([]);
	const [selectedParticipants, setSelectedParticipants] = useState<
		models.user.User[]
	>([]);

	const handleSetInvites = () => {
		setSendInvites(!sendInvites);
	};

	const eventUsers = event.users || [];

	const organizers = eventUsers.filter(
		(eventUser) => eventUser.role === 'organizer'
	);

	const participants = eventUsers.filter(
		(eventUser) => eventUser.role === 'participant'
	);

	const invitedUserIds = eventUsers.map((eventUser) => eventUser.userId);

	const saveParticipants = async (users: models.user.User[]) => {
		if (users.length > 0) {
			await actions.eventUsers.inviteEventUsers(event.id, {
				sendInvites: !models.event.hasStarted(event) && sendInvites,
				users: users.map((user: models.user.User) => user.id),
			});

			eventContext.eventEndpointResponse.refresh();
		}

		setSelectedParticipants([]);
	};

	const saveOrganizers = async (users: models.user.User[]) => {
		if (users.length > 0) {
			const organizer: { [key: number]: boolean } = {};

			users.forEach((user) => {
				organizer[user.id] = true;
			});

			await actions.eventUsers.inviteEventUsers(event.id, {
				sendInvites: !models.event.hasStarted(event) && sendInvites,
				users: users.map((user: models.user.User) => user.id),
				organizer,
			});

			eventContext.eventEndpointResponse.refresh();
		}

		setSelectedOrganizers([]);
	};

	const sendInvitesOptions: Partial<DialogOptions> = {
		wide: true,
		symbol: 'calendar_add_on',
		title: t('Send invites?'),
		confirmLabel: t('Finish'),
		onCancel: () => {
			setSendInvites(defaultSendInvites);
			setSelectedOrganizers([]);
			setSelectedParticipants([]);
		},
		message: (
			<Column>
				<span>
					{t(
						'If you send invites, organizers and participants will be notified to take action. Otherwise invited users will automatically be added as "Going".'
					)}
				</span>
				<label className={css.sendInvites} onClick={handleSetInvites}>
					<Row columns="auto 1fr" align="center" spacing={styles.spacing._3}>
						<Input.Control standalone type="checkbox" checked={sendInvites} />
						<strong>{t('Yes, send invites')}</strong>
					</Row>
				</label>
			</Column>
		),
	};

	const confirmSendInvitesToOrganizers = useDialog(
		{
			...sendInvitesOptions,
			onConfirm: async () => saveOrganizers(selectedOrganizers),
		} as DialogOptions,
		[sendInvites, selectedOrganizers]
	);

	const confirmSendInvitesToParticipants = useDialog(
		{
			...sendInvitesOptions,
			onConfirm: async () => saveParticipants(selectedParticipants),
		} as DialogOptions,
		[sendInvites, selectedParticipants]
	);

	const organizersSelect = useUserSelectModal({
		title: t('Add organizers'),
		saveLabel: t('Add organizers'),
		ignoredUserIds: invitedUserIds,
		defaultActiveTabId: 'admin',
		onSave: async (users: models.user.User[]) => {
			if (users.length > 0) {
				setSelectedOrganizers(users);

				if (!models.event.hasEnded(event)) {
					confirmSendInvitesToOrganizers();
				} else {
					await saveOrganizers(users);
				}
			}
		},
		onCancel: () => {
			setSelectedOrganizers([]);
		},
	});

	const participantsSelect = useUserSelectModal({
		title: t('Add participants'),
		saveLabel: t('Add participants'),
		ignoredUserIds: invitedUserIds,
		defaultActiveTabId: 'user',
		onSave: async (users: models.user.User[]) => {
			if (users.length > 0) {
				setSelectedParticipants(users);

				if (!models.event.hasEnded(event)) {
					confirmSendInvitesToParticipants();
				} else {
					await saveParticipants(users);
				}
			}
		},
	});

	return (
		<Fragment>
			{models.event.hasEnded(event) ? (
				<Column spacing={styles.spacing._8}>
					<AttendedList
						variant="organizers"
						event={event}
						toggleInviteModal={organizersSelect.open}
						eventUsers={organizers}
					/>
					<AttendedList
						variant="participants"
						event={event}
						toggleInviteModal={participantsSelect.open}
						eventUsers={participants}
					/>
				</Column>
			) : (
				<Column spacing={styles.spacing._8}>
					<InvitedList
						variant="organizers"
						event={event}
						toggleInviteModal={organizersSelect.open}
						eventUsers={organizers}
					/>
					<InvitedList
						variant="participants"
						event={event}
						toggleInviteModal={participantsSelect.open}
						eventUsers={participants}
					/>
					<ResendInvites event={event} eventUsers={eventUsers} />
				</Column>
			)}
			{models.canEdit(event) && (
				<AutoSaveMessage event={event} eventUsers={eventUsers} />
			)}
			{organizersSelect.Component}
			{participantsSelect.Component}
		</Fragment>
	);
}
