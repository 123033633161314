import { ReactNode } from 'react';

export const symbol_accessible: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M396-80q-83 0-139.5-56.5T200-276q0-54 32.5-103t88.5-73q11-5 22-.5t16 15.5q5 11 .5 22T344-399q-38 16-60.5 50.5T261-276q0 58 38.5 97t96.5 39q38 0 72.5-21.5T518-221q5-12 16.5-17t22.5 0q11 5 15.5 16t-.5 22q-24 55-73 87.5T396-80Zm330 0q-13 0-21.5-8.5T696-110v-179H482q-30 0-51-21t-21-51v-247q0-30 21-51t51-21q23 0 39 9t38 35q37 43 79.5 74t91.5 40q13 2 21.5 11t8.5 22q0 13-9.5 21.5T729-461q-45-7-90.5-30.5T551-551v183h133q30 0 51 21t21 51v186q0 13-8.5 21.5T726-80ZM482-734q-30 0-51.5-21.5T409-807q0-30 21.5-51.5T482-880q30 0 51.5 21.5T555-807q0 30-21.5 51.5T482-734Z"
		/>
	</svg>
);
