// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Uhgh0l1DESsNxJieP8qN {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	grid-auto-rows: auto;
	grid-gap: var(--spacing-3);
	gap: var(--spacing-3);
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	justify-items: center;
}

.Uhgh0l1DESsNxJieP8qN .Y0QYBx5sdKQgVA6dnQkp {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		    -ms-flex-direction: column;
		        flex-direction: column;
		-webkit-box-align: center;
		    -ms-flex-align: center;
		        align-items: center;
		-webkit-box-pack: center;
		    -ms-flex-pack: center;
		        justify-content: center;
		border: 1px solid var(--palette-gray-300);
		padding: var(--spacing-3);
		border-radius: var(--radius-3);
		height: 80px;
		width: 80px;
	}

@media (hover: hover) {
			.Uhgh0l1DESsNxJieP8qN .Y0QYBx5sdKQgVA6dnQkp:hover {
				background: var(--palette-blue-100);
				color: var(--palette-blue-500);
				cursor: pointer;
				border: 1px solid var(--palette-gray-300);
			}

				.Uhgh0l1DESsNxJieP8qN .Y0QYBx5sdKQgVA6dnQkp:hover .CKuSsOtvtoczxlXV6Sdy {
					color: var(--palette-blue-500);
				}
		}

.Uhgh0l1DESsNxJieP8qN .Y0QYBx5sdKQgVA6dnQkp:first-child {
			margin-top: 0;
		}

.Uhgh0l1DESsNxJieP8qN .Y0QYBx5sdKQgVA6dnQkp:last-child {
			margin-bottom: 0;
		}

.CKuSsOtvtoczxlXV6Sdy {
	font-size: var(--font-size-2xs);
	color: var(--palette-gray-800);
	text-align: center;
	text-overflow: ellipsis;
}

.ITSka1PFMpInO5M04La1 {
	padding-top: var(--spacing-2);
}

.tycCEtMGX26OrzM6inOq {
	-ms-flex-item-align: center;
	    align-self: center;
}
`, "",{"version":3,"sources":["webpack://./components/material-symbols/icon-context-menu/styles.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,qCAAqC;CACrC,oBAAoB;CACpB,0BAAqB;CAArB,qBAAqB;CACrB,wBAAuB;KAAvB,qBAAuB;SAAvB,uBAAuB;CACvB,qBAAqB;AAkCtB;;AAhCC;EACC,oBAAa;EAAb,oBAAa;EAAb,aAAa;EACb,4BAAsB;EAAtB,6BAAsB;MAAtB,0BAAsB;UAAtB,sBAAsB;EACtB,yBAAmB;MAAnB,sBAAmB;UAAnB,mBAAmB;EACnB,wBAAuB;MAAvB,qBAAuB;UAAvB,uBAAuB;EACvB,yCAAyC;EACzC,yBAAyB;EACzB,8BAA8B;EAC9B,YAAY;EACZ,WAAW;CAsBZ;;AApBC;GACC;IACC,mCAAmC;IACnC,8BAA8B;IAC9B,eAAe;IACf,yCAAyC;GAK1C;;IAHC;KACC,8BAA8B;IAC/B;EAEF;;AAEA;GACC,aAAa;EACd;;AAEA;GACC,gBAAgB;EACjB;;AAIF;CACC,+BAA+B;CAC/B,8BAA8B;CAC9B,kBAAkB;CAClB,uBAAuB;AACxB;;AAEA;CACC,6BAA6B;AAC9B;;AAEA;CACC,2BAAkB;KAAlB,kBAAkB;AACnB","sourcesContent":[".iconWrapper {\n\tdisplay: grid;\n\tgrid-template-columns: repeat(5, 1fr);\n\tgrid-auto-rows: auto;\n\tgap: var(--spacing-3);\n\tjustify-content: center;\n\tjustify-items: center;\n\n\t& .iconItem {\n\t\tdisplay: flex;\n\t\tflex-direction: column;\n\t\talign-items: center;\n\t\tjustify-content: center;\n\t\tborder: 1px solid var(--palette-gray-300);\n\t\tpadding: var(--spacing-3);\n\t\tborder-radius: var(--radius-3);\n\t\theight: 80px;\n\t\twidth: 80px;\n\n\t\t@media (hover: hover) {\n\t\t\t&:hover {\n\t\t\t\tbackground: var(--palette-blue-100);\n\t\t\t\tcolor: var(--palette-blue-500);\n\t\t\t\tcursor: pointer;\n\t\t\t\tborder: 1px solid var(--palette-gray-300);\n\n\t\t\t\t.iconName {\n\t\t\t\t\tcolor: var(--palette-blue-500);\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\n\t\t&:first-child {\n\t\t\tmargin-top: 0;\n\t\t}\n\n\t\t&:last-child {\n\t\t\tmargin-bottom: 0;\n\t\t}\n\t}\n}\n\n.iconName {\n\tfont-size: var(--font-size-2xs);\n\tcolor: var(--palette-gray-800);\n\ttext-align: center;\n\ttext-overflow: ellipsis;\n}\n\n.searchPane {\n\tpadding-top: var(--spacing-2);\n}\n\n.symbol {\n\talign-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
export var iconWrapper = `Uhgh0l1DESsNxJieP8qN`;
export var iconItem = `Y0QYBx5sdKQgVA6dnQkp`;
export var iconName = `CKuSsOtvtoczxlXV6Sdy`;
export var searchPane = `ITSka1PFMpInO5M04La1`;
export var symbol = `tycCEtMGX26OrzM6inOq`;
export default ___CSS_LOADER_EXPORT___;
