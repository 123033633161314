import { ReactNode } from 'react';

export const symbol_language_spanish: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M220-340h190q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T410-280H190q-12.75 0-21.375-8.625T160-310v-340q0-12.75 8.625-21.375T190-680h220q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T410-620H220v110h150q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T370-450H220v110Zm360 60q-24.75 0-42.375-17.625T520-340v-10q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T580-350v10h160v-110H580q-24.75 0-42.375-17.625T520-510v-110q0-24.75 17.625-42.375T580-680h160q24.75 0 42.375 17.625T800-620v10q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T740-610v-10H580v110h160q24.75 0 42.375 17.625T800-450v110q0 24.75-17.625 42.375T740-280H580Z"
		/>
	</svg>
);
