import { useEffect } from 'react';
import { t } from '@transifex/native';

import spacing from 'pkg/config/spacing';

import {
	ConnectionStatus,
	ExternalServiceProvider,
	useExternalServiceConnections,
} from 'pkg/api/models/external_service';
import useInterval from 'pkg/hooks/useInterval';
import * as actions from 'pkg/actions';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as api from 'pkg/api';

import AssetImage from 'components/AssetImage';
import Connection from 'components/connection';
import { useStepModalContext } from 'components/step-modal';

import Column from 'components/layout/column';

import { useDialog } from 'design/dialog';
import Button from 'design/button';

const __pollInterval: number = 5000;

interface PendingConnectionProps {
	currentUserId: number;
	setSelectedUserId: (selectedUserId: number) => void;
	setConnectionStatus: (connectionStatus: string) => void;
}

export default function PendingConnection({
	currentUserId,
	setSelectedUserId,
	setConnectionStatus,
}: PendingConnectionProps): JSX.Element {
	const modalContext = useStepModalContext();

	const { isLoading, records, fetchConnections } =
		useExternalServiceConnections({
			userId: currentUserId,
			serviceProvider: ExternalServiceProvider.Wingfield,
			connectionStatus: ConnectionStatus.Pending,
		});

	useInterval(fetchConnections, __pollInterval);

	useEffect(() => {
		if (!isLoading && records.length === 0) {
			actions.flashes.show(
				{
					title: t('Successful Wingfield connection', {
						_context: 'wingfield video modal',
					}),
					message: t(
						'You have successfully connected your 360Player user and Wingfield account.',
						{ _context: 'wingfield video modal' }
					),
				},
				200
			);

			setConnectionStatus('active');
			setSelectedUserId(currentUserId);
		}
	}, [records]);

	const currentConnectionId = records[0]?.id ?? 0;

	const handleRemoveConnection = useDialog({
		message: t('Are you sure you want to remove the connection request?', {
			_context: 'wingfield video modal',
		}),
		destructive: true,
		confirmLabel: t('Yes, remove'),
		onConfirm: async () => {
			if (currentConnectionId) {
				await api.delete(
					endpoints.Users.DeleteExternalConnection(currentConnectionId)
				);

				actions.flashes.show(
					{
						title: t('Successfully removed Wingfield connection', {
							_context: 'wingfield video modal',
						}),
					},
					200
				);
			}

			modalContext.goTo('start');
		},
	});

	return (
		<Column
			spacing={spacing._9}
			blockSpacing={spacing._9}
			inlineSpacing={spacing._8}>
			<Connection
				pending
				leftComponent={<AssetImage src="img/video-options/wingfield.svg" />}
				rightComponent={
					<AssetImage src="img/video-options/hosted-by-360.svg" />
				}
			/>

			<Column spacing={spacing._7} justify="center">
				<strong>
					{t('Awaiting confirmation', { _context: 'wingfield video modal' })}
				</strong>
				<p>
					{t(
						'A connection request has been sent to your email inbox and it will arrive shortly. Once you have confirmed the connection you can add Wingfield videos.',
						{ _context: 'wingfield video modal' }
					)}
				</p>
				<p>
					{t(`Once you've confirmed the connection, this view will update.`, {
						_context: 'wingfield video modal',
					})}
				</p>
				<p>
					<Button
						inline
						onClick={handleRemoveConnection}
						disabled={!currentConnectionId}>
						{t('Remove connection request')}
					</Button>
				</p>
			</Column>
		</Column>
	);
}
