import styled, { css } from 'styled-components';

import * as styles from 'pkg/config/styles';

interface TitleStylesProps {
	center?: boolean;
	thinDescription?: boolean;
}

const TitleStyles = styled.div<TitleStylesProps>`
	color: var(--palette-white);

	h1 {
		font-size: var(--font-size-2xl);
		@media ${styles.breakpoint.small} {
			font-size: var(--font-size-xl);
		}
	}

	p {
		font-size: var(--font-size-lg);
		font-weight: var(--font-weight-semibold);
		margin: var(--spacing-2) 0 0;

		@media ${styles.breakpoint.small} {
			font-size: var(--font-size-base);
		}
	}

	${({ center }) =>
		center &&
		css`
			text-align: center;
		`}

	${({ thinDescription }) =>
		thinDescription &&
		css`
			p {
				font-size: var(--font-size-base);
				font-weight: var(--font-weight-normal);
				margin: var(--spacing-6) 0 0;

				@media ${styles.breakpoint.small} {
					font-size: var(--font-size-sm);
				}
			}
		`}
`;

interface TitleProps extends TitleStylesProps {
	title: string;
	description?: string;
	testid?: string;
}

export default function Title({
	title,
	description,
	center,
	thinDescription,
	testid = 'onboarding.title',
}: TitleProps): JSX.Element {
	return (
		<TitleStyles
			center={center}
			thinDescription={thinDescription}
			data-testid={testid}>
			<h1>{title}</h1>
			{description && <p>{description}</p>}
		</TitleStyles>
	);
}
