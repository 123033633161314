import { ReactNode } from 'react';

export const symbol_airline_seat_individual_suite: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M860-280H100q-24.75 0-42.375-17.625T40-340v-280q0-12.75 8.675-21.375Q57.351-650 70.175-650 83-650 91.5-641.375T100-620v280h353v-250q0-24.75 17.625-42.375T513-650h262q59.812 0 102.406 42.594Q920-564.812 920-505v165q0 24.75-17.625 42.375T860-280Zm-347-60h347v-165q0-35.062-24.969-60.031T775-590H513v250Zm0-250v250-250ZM270-399q45 0 75.5-30.5T376-505q0-45-30.5-75.5T270-611q-45 0-75.5 30.5T164-505q0 45 30.5 75.5T270-399Zm0-60q-19 0-32.5-13.5T224-505q0-19 13.5-32.5T270-551q19 0 32.5 13.5T316-505q0 19-13.5 32.5T270-459Zm0-46Z"
		/>
	</svg>
);
