import { ReactNode } from 'react';

export const symbol_foundation: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M261-286v136q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T201-150v-136h-53q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T148-346h53v-206L99-475q-10.043 8-21.522 6.5Q66-470 58-480q-8-10-6-22t12-20l380-290q8-6 17.211-9.5 9.21-3.5 18.789-3.5t18.789 3.5Q508-818 516-812l380 290q10 8 11.5 20t-6.5 22q-8 10-19.478 11.5Q870.043-467 860-475l-102-78v207h53q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T811-286h-53v136q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T698-150v-136H510v136q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T450-150v-136H261Zm0-60h189v-396L261-598v252Zm249 0h188v-252L510-742v396Z"
		/>
	</svg>
);
