import styled from 'styled-components';

import * as styles from 'pkg/config/styles';

import rgba from 'pkg/rgba';

import * as iconStyles from 'components/icon/styles.css';

export const TimeZoneText = styled.div`
	color: ${rgba(styles.palette.main.actionableLinkColor)};
	font-size: var(--font-size-sm);
	font-weight: var(--font-weight-semibold);
	cursor: pointer;
`;

export const DateSelectWrapper = styled.div`
	display: flex;
	column-gap: 60px;
	position: relative;

	@media ${styles.breakpoint.small} {
		flex-direction: column;
		gap: var(--spacing-7) 0;

		.${iconStyles.icon} {
			display: none;
		}
	}

	.${iconStyles.icon} {
		position: absolute;
		left: 50%;
		top: calc(50% + 12px);
		transform: translate(-50%, -50%) scale(1.6);
	}
`;

export const InvitesNotice = styled.span`
	color: var(--palette-gray-500);
	font-size: var(--font-size-sm);
`;
