import { ReactNode } from 'react';

export const symbol_filter_drama: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M251-160q-88 0-149.5-61.5T40-371q0-81 50.5-137.5T217-579q18-94 92.5-157.5T481-800q111 0 189 81.5T748-522v24q75 0 123.5 48.5T920-329q0 69-50 119t-119 50H251Zm0-60h500q45 0 77-32t32-77q0-45-32-77t-77-32h-63v-84q0-88-59.5-153T481-740q-70 0-129 43.5T275-580q72 10 122 54t60 114q2 13-7 22.5t-23 9.5q-11 0-20-8t-11-20q-10-52-51-83t-97-31q-63 0-105.5 44T100-371q0 63 44 107t107 44Z"
		/>
	</svg>
);
