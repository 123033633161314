import * as styles from 'pkg/config/styles';

import ColorConverter from 'pkg/colorconverter';
import { cssVarList } from 'pkg/css/utils';
import rgba from 'pkg/rgba';
import { EventTypes } from 'pkg/api/models/event';

export function getGridItemCssVars(type: EventTypes) {
	const bgTypeColor = styles.palette.events[type].backgroundColor.join(',');
	const saturation = Math.floor(
		ColorConverter.from(bgTypeColor).getSaturation()
	);

	return cssVarList({
		'bg-color': rgba(styles.palette.events[type].backgroundColor, 0.8),
		'border-color':
			saturation < 90
				? ColorConverter.from(bgTypeColor).darken(8).toStylesheetString()
				: ColorConverter.from(bgTypeColor)
						.setSaturation(80)
						.darken(8)
						.toStylesheetString(),
		'border-hover-color':
			saturation < 90
				? ColorConverter.from(bgTypeColor).darken(8).toStylesheetString()
				: ColorConverter.from(bgTypeColor)
						.setSaturation(80)
						.darken(20)
						.toStylesheetString(),
		'text-color': rgba(styles.palette.events[type].foregroundColor),
		'bg-hover-color': ColorConverter.from(bgTypeColor)
			.darken(10)
			.toStylesheetString(),
	});
}
