import Product from 'pkg/models/product';

import * as productServices from 'pkg/actions/services/products';
import { normalizedDispatch } from 'pkg/actions/utils';

import store from 'pkg/store/createStore';

export const update = async (
	productId: number,
	payload: productServices.UpdateProductPayload
): Promise<Response> => {
	const request = await productServices.update(productId, payload);

	if (!request.ok) {
		return request;
	}

	const response = await request.json();
	normalizedDispatch(response, Product.normalizr())(store.dispatch);

	return request;
};
