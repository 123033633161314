import { ReactNode } from 'react';

export const symbol_ulna_radius: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M720-82q-23 0-45.5-8T634-116q-2-2-3.5-4t-2.5-2h-88q-46 0-73-31.5T440-223q0-22 10-42.5t30-37.5l40-32v-222l-62-88q-9-14-13.5-28.5T440-702q0-39 27.5-69.5T540-802h200q43 0 71.5 29.5T840-703q0 14-4 28t-14 28l-62 90v224l39 39q19 19 30 42.5t11 49.5q0 50-35 85t-85 35Zm0-60q25 0 42.5-17.5T780-202q0-19-9-31.5T757-251l-39-39q-8-8-13-20t-5-23v-223q0-9 3-18t8-17l61-89q2-3 8-23 0-16-11.5-27.5T740-742H540q-18 0-29 12.5T500-702q0 6 2 11.5t6 10.5l61 88q5 8 8 17.5t3 18.5v221q0 13-6 25.5T558-289l-40 33q-9 8-13.5 16.5T500-222q0 16 11 28t29 12h88q19 0 30 7.5t19 15.5q11 11 23 14t20 3Zm-367 8q-11 5-23 8t-23 3q-42 0-74.5-30.5T200-229v-364l-70-147q-5-11-7.5-21.5T120-783q0-40 28.5-69.5T220-882h180v80q42 0 71 29t29 71q0 15-4.5 30T480-643l-40 54v200l43 65q8 13 12.5 27.5T500-268q0 27-14 51t-41 38l-92 45Zm-26-54 91-45q11-5 16.5-14.5T440-268q0-3-7-23l-36-55q-8-13-12.5-27.5T380-402v-174q0-17 5-32t15-28l32-42q4-5 8-24 0-16-11.5-28T400-742q-22 0-41-16t-19-44v-20H220q-17 0-28.5 12T180-782q0 3 4 17l66 138q2 5 6 15.5t4 27.5v355q0 20 14.5 33t32.5 13q6 0 20-5Zm313-254Zm-330-61Z"
		/>
	</svg>
);
