import { Fragment, ReactNode, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { setNavBarHeight } from 'pkg/actions/app';

import useComponentDidMount from 'pkg/hooks/useComponentDidMount';

import Back from 'components/navigation/Back';
import {
	Header,
	HeaderWrapper,
	FixedSubPageHeader,
	TitleWrapper,
	PageTitle,
} from 'components/navigation/header/small_screen/Styles';
import { usePageActionsContext } from 'components/navigation/header/small_screen/page_actions/Context';
import { ButtonTrigger } from 'components/navigation/header/small_screen/page_actions/ButtonTrigger';

import * as Context from 'design/context_menu';

interface Props {
	title?: string;
	subTitle?: string;
	actionTrigger?: ReactNode;
}

const SmallScreenHeader = ({
	title = '',
	subTitle = '',
	actionTrigger,
}: Props): JSX.Element => {
	const navBar = useRef(null);
	const dispatch = useDispatch();

	useComponentDidMount(
		() => {
			dispatch(setNavBarHeight(navBar.current.offsetHeight));
		},
		() => {
			dispatch(setNavBarHeight(0));
		}
	);

	const pageActions = usePageActionsContext();

	// prefer page actions from the context over the manually set ones in the template
	if (pageActions.actions?.length === 1) {
		// single action, add a direct action button
		actionTrigger = (
			<ButtonTrigger
				icon={pageActions.icon}
				href={pageActions.actions[0].href}
				onClick={pageActions.actions[0].onClick}
			/>
		);
	} else if (pageActions.actions?.length > 1) {
		actionTrigger = (
			<Context.Menu toggleWith={<ButtonTrigger icon={pageActions.icon} />}>
				{pageActions.actions.map((action) => (
					<Fragment key={action.label}>
						{action.href ? (
							<Context.LinkItem icon={action.icon} href={action.href}>
								{action.label}
							</Context.LinkItem>
						) : action.contextMenuItems?.length > 0 ? (
							<Context.Menu
								toggleWith={
									<Context.Item
										icon={action.icon}
										onClick={action.onClick}
										closeOnClick={false}>
										{action.label}
									</Context.Item>
								}>
								{action.contextMenuItems}
							</Context.Menu>
						) : (
							<Context.Item icon={action.icon} onClick={action.onClick}>
								{action.label}
							</Context.Item>
						)}
					</Fragment>
				))}
			</Context.Menu>
		);
	}

	return (
		<Header>
			<HeaderWrapper>
				<FixedSubPageHeader ref={navBar}>
					<TitleWrapper>
						<Back />
						<PageTitle context={subTitle}>{title}</PageTitle>
						{actionTrigger}
					</TitleWrapper>
				</FixedSubPageHeader>
			</HeaderWrapper>
		</Header>
	);
};

export default SmallScreenHeader;
