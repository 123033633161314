// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vCJJ7jGfv23Wb_Hn6tF5 {
	grid-template-columns: 1fr auto !important;
}

.QWJ2SuOq7jpZ63qrQlQw {
	gap: var(--spacing-1);
}

.vBr6hhLalolN6yiKP1o9 {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	gap: var(--spacing-2);
	margin-top: var(--spacing-2);
}

.k17CPcd740TWncwlSOGo {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	gap: var(--spacing-4);
}

.EBl1aomLtdYudFcJiyGy {
	font-weight: var(--font-weight-normal);
	font-size: var(--font-size-xs);
	line-height: var(--font-line-height-xs);
}
`, "",{"version":3,"sources":["webpack://./components/scheduling/resources/styles.css"],"names":[],"mappings":"AAAA;CACC,0CAA0C;AAC3C;;AAEA;CACC,qBAAqB;AACtB;;AAEA;CACC,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,qBAAqB;CACrB,4BAA4B;AAC7B;;AAEA;CACC,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,qBAAqB;AACtB;;AAEA;CACC,sCAAsC;CACtC,8BAA8B;CAC9B,uCAAuC;AACxC","sourcesContent":[".cardBase {\n\tgrid-template-columns: 1fr auto !important;\n}\n\n.titleWrapper {\n\tgap: var(--spacing-1);\n}\n\n.tags {\n\tdisplay: flex;\n\tgap: var(--spacing-2);\n\tmargin-top: var(--spacing-2);\n}\n\n.actionsContent {\n\tdisplay: flex;\n\tgap: var(--spacing-4);\n}\n\n.location {\n\tfont-weight: var(--font-weight-normal);\n\tfont-size: var(--font-size-xs);\n\tline-height: var(--font-line-height-xs);\n}\n"],"sourceRoot":""}]);
// Exports
export var cardBase = `vCJJ7jGfv23Wb_Hn6tF5`;
export var titleWrapper = `QWJ2SuOq7jpZ63qrQlQw`;
export var tags = `vBr6hhLalolN6yiKP1o9`;
export var actionsContent = `k17CPcd740TWncwlSOGo`;
export var location = `EBl1aomLtdYudFcJiyGy`;
export default ___CSS_LOADER_EXPORT___;
