import { Fragment, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as routes from 'pkg/router/routes';
import * as models from 'pkg/api/models';
import { pushState } from 'pkg/router/state';
import DateTime from 'pkg/datetime';
import { TemplateModals } from 'pkg/api/models/schedule';
import { useCurrentOrganization } from 'pkg/identity';

import EditTemplateModal from 'routes/scheduling/templates/modals/EditTemplate';
import PublishTemplateModal from 'routes/scheduling/templates/modals/publish';
import DuplicateModal from 'routes/scheduling/templates/modals/Duplicate';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import RelativeDateTime from 'components/RelativeDateTime';
import Icon from 'components/icon';

import Back from 'components/navigation/Back';
import {
	Header,
	PageIcon,
	Wrap,
} from 'components/navigation/header/large_screen/Styles';
import Column from 'components/layout/column';
import Row from 'components/layout/row';

import * as ContextMenu from 'design/context_menu';
import Button from 'design/button';

import * as css from './styles.css';

interface TemplateHeaderProps {
	groupId: number;

	refresh: () => Promise<void>;

	schedule: models.schedule.Schedule;
	scheduleItems: models.scheduleItem.ScheduleItem[];
}

const TemplateHeader = ({
	schedule,
	scheduleItems,
	refresh,
	groupId,
}: TemplateHeaderProps) => {
	const [modal, setModal] = useState<TemplateModals | ''>('');
	const org = useCurrentOrganization();

	const hasBeenUpdated = schedule.publishedAt
		? schedule.updatedAt > schedule.publishedAt
		: false;

	const midRangeScreens = useMediaQuery({
		minWidth: styles.breakpoint.toMedium,
		maxWidth: styles.breakpoint.toLarge,
	});

	const showEditModal = () => setModal('edit');
	const showPublishModal = () => setModal('publish');
	const showDuplicateModal = () => setModal('duplicate');
	const hideModal = () => setModal('');
	const publishedDate = new DateTime(new Date(schedule.publishedAt * 1000));

	const handleDelete = async () => {
		const confirm = window.confirm(
			t(
				`Are you absolutely sure you want to remove this template? This action can't be undone`
			)
		);

		if (confirm) {
			const ok = await models.schedule.remove(schedule);

			if (ok) {
				pushState(routes.Templates.Index(org.id, groupId));
			}
		}
	};

	let deleteConfirm = t('Are you sure you want to delete this template?');

	if (schedule.publishedAt) {
		deleteConfirm = t(
			'Are you sure you want to delete this template? This templates connected events/bookings will be removed.'
		);
	}

	const latestSavedSheduleItem = scheduleItems.sort(
		(a, b) => b.updatedAt - a.updatedAt
	)[0];
	const latestTimestamp =
		latestSavedSheduleItem?.updatedAt > schedule.updatedAt
			? latestSavedSheduleItem?.updatedAt
			: schedule.updatedAt;

	const titleContextMenu = (
		<ContextMenu.Menu toggleWith={<Icon name="context-menu" />}>
			<ContextMenu.Item onClick={showEditModal}>
				<ContextMenu.ItemIcon name="edit" />
				{t('Edit')}
			</ContextMenu.Item>
			<ContextMenu.Item onClick={showDuplicateModal}>
				<ContextMenu.ItemIcon name="content_copy" />
				{t('Duplicate')}
			</ContextMenu.Item>
			<ContextMenu.Divider />
			<ContextMenu.ConfirmItem
				caution
				onConfirm={handleDelete}
				message={deleteConfirm}>
				<ContextMenu.ItemIcon name="delete" />
				{t('Delete')}
			</ContextMenu.ConfirmItem>
		</ContextMenu.Menu>
	);

	const changesMadeText = hasBeenUpdated && (
		<Row
			align="center"
			autoColumns="auto"
			spacing={styles.spacing._2}
			justify="end">
			<Icon
				size={1.3}
				actualSize
				name="info-circle"
				tooltip={midRangeScreens && t('Changes made since last published')}
				fill={styles.palette.orange[500]}
			/>
			<span className={css['changes-made']}>
				{t('Changes made since last published')}
			</span>
		</Row>
	);

	const autoSaved = (
		<div className={css['auto-saved']}>
			<Icon
				name="check-filled"
				size={1.3}
				actualSize
				fill={styles.palette.green[400]}
			/>
			<RelativeDateTime dateTime={latestTimestamp} prefix={t('Autosaved')} />
		</div>
	);

	const publishedAt = (
		<span className={css['status-label']}>
			{schedule.publishedAt !== 0
				? t('Last published {date}', {
						date: publishedDate.toLocaleDateString({
							year: 'numeric',
							month: 'numeric',
							day: 'numeric',
						}),
					})
				: t('Never published')}
		</span>
	);

	return (
		<Fragment>
			<LargeScreen>
				<Wrap>
					<Header>
						<Back largeLayout />
						{!midRangeScreens && <PageIcon variant="table_chart" />}
						<Row align="center" autoColumns="max-content">
							<span className={css['page-title']}>{schedule.title}</span>
							{titleContextMenu}
							{autoSaved}
						</Row>
						<Row
							className={css['actions-row']}
							autoColumns="auto"
							align="center">
							<Column
								spacing={styles.spacing._1}
								className={css['published-column']}>
								{changesMadeText}
								{publishedAt}
							</Column>
							<Button
								iconSize={0.8}
								icon="upload"
								label={t('Review and publish')}
								large={!midRangeScreens}
								small={midRangeScreens}
								primary
								onClick={showPublishModal}
							/>
						</Row>
					</Header>
				</Wrap>
			</LargeScreen>
			<SmallScreen>
				<div className={css.wrapper}>
					<div className={css['title-wrapper']}>
						<Back largeLayout />
						<Row align="center">
							<span className={css['page-title']}>{schedule.title}</span>
							{titleContextMenu}
						</Row>
					</div>
					<Row align="center">
						<ContextMenu.Menu
							toggleWith={
								<Icon
									size={1.3}
									actualSize
									name="info-circle"
									fill={styles.palette.orange[500]}
								/>
							}>
							<ContextMenu.Label>{t('Template information')}</ContextMenu.Label>
							<ContextMenu.Divider />
							<ContextMenu.Item>
								<Column spacing={styles.spacing._3}>
									{autoSaved}
									{changesMadeText}
									{publishedAt}
								</Column>
							</ContextMenu.Item>
						</ContextMenu.Menu>
						<Button icon="ios_share" primary onClick={showPublishModal} />
					</Row>
				</div>
			</SmallScreen>
			{modal === 'duplicate' && (
				<DuplicateModal schedule={schedule} hideModal={hideModal} />
			)}

			{modal === 'edit' && (
				<EditTemplateModal
					schedule={schedule}
					refresh={refresh}
					hideModal={hideModal}
				/>
			)}
			{modal === 'publish' && (
				<PublishTemplateModal
					schedule={schedule}
					hideModal={hideModal}
					publishedPeriods={schedule.schedulePublishedPeriods || []}
					refreshSchedule={refresh}
				/>
			)}
		</Fragment>
	);
};

export default TemplateHeader;
