import { useRef, useEffect, useLayoutEffect } from 'react';
import DOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'pkg/reducers';
import * as actions from 'pkg/actions';
import * as selectors from 'pkg/selectors';
import { useCurrentGroup } from 'pkg/identity';

import FormationWrapper from 'components/group/formation/single/Wrapper';
import Users from 'components/group/formation/single/UserList';
import Canvas from 'components/group/formation/single/Canvas';
import Positions from 'components/group/formation/single/Positions';
import Toolbar from 'components/group/formation/single/Toolbar';
import GroupFormationController from 'components/group/formation/single/GroupFormationController';

interface ViewProps {
	formationId: number;
	query: { key: string };
}

const View: React.FC<React.PropsWithChildren<ViewProps>> = ({
	formationId,
	query,
}) => {
	const domNode = useRef(null);
	const playerOptionNode = useRef(null);
	const group = useCurrentGroup();

	const dispatch = useDispatch();

	const formation = useSelector((state: RootState) =>
		selectors.groupFormations.getCurrentFormation(state)
	);
	const saving = useSelector((state: RootState) => state.formations.saving);
	const positions = useSelector((state: RootState) => state.positions.entities);
	const users = useSelector((state: RootState) =>
		selectors.groups.getUsersByRole(state, { groupId: group.id, role: 1 })
	);

	useEffect(() => {
		actions.positions.deprecatedFetchPositions()(dispatch);
	}, [dispatch]);

	useEffect(() => {
		actions.groupFormations.setFormation(
			formationId || 'new',
			group.id,
			query
		)(dispatch);
	}, [dispatch, formationId, query]);

	useLayoutEffect(() => {
		const controller = new GroupFormationController();

		controller.onPlayerOptionsOpen = (options) => {
			DOM.render(options, playerOptionNode.current);
		};

		controller.onPlayerOptionsClose = () => {
			DOM.unmountComponentAtNode(playerOptionNode.current);
		};

		controller.onSave = (formationData) => {
			actions.groupFormations.persistFormation(formationData)(dispatch);
		};

		if (!saving && positions) {
			controller.init(domNode.current, formation?.maturityAdjusted);
		}
	}, [dispatch, formation?.maturityAdjusted, positions]);

	useEffect(() => {
		if (formation?.groupId) {
			const fetchData = async () => {
				await actions.groups.fetchSquad(formation.groupId);
			};

			fetchData();
		}
	}, [dispatch, formation]);

	useEffect(() => {
		if (playerOptionNode.current) {
			DOM.unmountComponentAtNode(playerOptionNode.current);
		}
	});

	const handleDelete = () => actions.groupFormations.deleteFormation()();

	return (
		<FormationWrapper
			type={formation?.formationType}
			className="group-formation"
			ref={domNode}>
			<Toolbar
				key={`toolbar-${formation?.id}`}
				saving={saving}
				formation={formation}
				handleDelete={handleDelete}
			/>
			{formation?.formationType === 'squad' && (
				<Users users={users.toList()} className />
			)}
			<Canvas formation={formation} />
			<Positions positions={positions} className />
			<div ref={playerOptionNode} />
		</FormationWrapper>
	);
};

export default View;
