import { Dateable } from 'pkg/api/models/dateable';
import { Product } from 'pkg/api/models/product';
import { Record } from 'pkg/api/models/record';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { decimalToCentsByCurrency } from 'pkg/utils';
import { RecurringInterval } from 'pkg/api/models/provider_settings';

export interface ProductPrice extends Dateable, Record {
	archivedAt: number;
	cost: number;
	currency: string;
	description: string;
	paymentProviderId: string;
	productId: number;
	recurring?: boolean;
	recurringInterval?: RecurringInterval;
	recurringIntervalCount?: number;
	taxRateId: number;
	title: string;

	product: Product;
	taxRate: models.taxRate.TaxRate;
}

export interface CreateProductPricePayload {
	productId: number;
	cost: number;
	currency: string;
	description?: string;
	recurring: boolean;
	recurringInterval?: string;
	recurringIntervalCount?: number;
	taxRateId?: number;
	title: string;
}

export function create(
	payload: CreateProductPricePayload,
	recurringOptionString: models.providerSettings.RecurringOptionStrings,
	providerSettings: models.providerSettings.ProviderSettings
) {
	if (payload.recurring && recurringOptionString !== 'custom') {
		const recurringOption =
			providerSettings.availableRecurringOptions[recurringOptionString];

		payload.recurringInterval = recurringOption.recurringInterval;
		payload.recurringIntervalCount = recurringOption.recurringIntervalCount;
	}

	payload.cost = decimalToCentsByCurrency(payload.cost, payload.currency);

	return models.create<CreateProductPricePayload, ProductPrice>(
		endpoints.ProductPrices.Create(),
		payload
	);
}
