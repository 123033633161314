export default {
	name: 'nav-assess',
	objects: [
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M12.465 7.6135H3.98529V6.3135H12.465V7.6135Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M11.4558 9.24469V4.68225L12.7558 4.68225V9.24469H11.4558Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M15.9887 14.4473V9.88489H17.2887V14.4473L15.9887 14.4473Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M9.137 19.6498L9.137 15.0874H10.437V19.6498L9.137 19.6498Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M17.0649 12.8974H3.98529V11.5974H17.0649V12.8974Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M10.1651 18.0187H3.98529V16.7187H10.1651V18.0187Z',
			},
		},
		{
			type: 'path',
			attributes: { d: 'M14.1149 6.38489H15.4149V7.68489H14.1149V6.38489Z' },
		},
		{
			type: 'path',
			attributes: { d: 'M16.4149 6.38489H17.7149V7.68489H16.4149V6.38489Z' },
		},
		{
			type: 'path',
			attributes: { d: 'M18.7148 6.38489H20.0148V7.68489H18.7148V6.38489Z' },
		},
		{
			type: 'path',
			attributes: { d: 'M11.815 16.7186H13.115V18.0186H11.815V16.7186Z' },
		},
		{
			type: 'path',
			attributes: { d: 'M14.1149 16.7186H15.4149V18.0186H14.1149V16.7186Z' },
		},
		{
			type: 'path',
			attributes: { d: 'M16.4149 16.7186H17.7149V18.0186H16.4149V16.7186Z' },
		},
		{
			type: 'path',
			attributes: { d: 'M18.7148 16.7186H20.0148V18.0186H18.7148V16.7186Z' },
		},
		{
			type: 'path',
			attributes: { d: 'M18.7148 11.5974H20.0148V12.8974H18.7148V11.5974Z' },
		},
	],
};
