import { ReactNode } from 'react';

export const symbol_turn_sharp_right: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M279.825-120Q267-120 258.5-128.625T250-150v-220q0-24.75 17.625-42.375T310-430h340v-296l-69 69q-9 9-21 9t-21-9q-9-9-9-21t9-21l120-120q9-9 21-9t21 9l120 120q9 9 9 21t-9 21q-9 9-21 9t-21-9l-69-69v296q0 24.75-17.625 42.375T650-370H310v220q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Z"
		/>
	</svg>
);
