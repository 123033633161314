import { useContext, Fragment } from 'react';
import { t } from '@transifex/native';
import styled from 'styled-components';

import {
	PageIcon,
	Actions,
	PageTitle,
	Back,
} from 'components/navigation/header/large_screen/Styles';
import * as ActionBar from 'components/layout/ActionBar';
import { FabricContext } from 'components/drawing/FabricContext';
import { getConfig, getPrefs } from 'components/drawing/config';
import { PlaybackControls } from 'components/drawing/display/PlaybackControls';

import Button from 'design/button';

export default function TopBar({
	saveAndClose,
	className,
	isPreviewing,
	setIsPreviewing,
}: any) {
	const {
		drawing,
		canvas,
		slides,
		setTriggeredTool,
		setSetting,
		setZoom,
		setSlide,
	} = useContext(FabricContext);

	const save = () => {
		setSetting('snappingVisible', false);
		setZoom(1);
		setTriggeredTool(null);

		const objects = canvas.current.getObjects();

		const saveData = objects.reduce((data: any, obj: any) => {
			// get save function and preferences from config
			const { save, prefs: prefNames } = getConfig(obj);
			const prefs = getPrefs(obj);

			if (save) {
				data[obj.id] = save(obj);
				data[obj.id].tool = obj.tool;

				// get each preference value
				const prefValues = prefs?.reduce((data: any, pref: any, i: number) => {
					data[prefNames[i]] = pref.get({ obj });

					return data;
				}, {});

				if (prefs?.length) data[obj.id].prefs = prefValues;
			}
			return data;
		}, {});

		setTimeout(() => {
			const thumbnail = canvas.current
				.toDataURL({ multiplier: 1.2 })
				.replace('image/png', 'image/octet-stream');

			saveAndClose({ ...drawing, objects: saveData, slides, thumbnail });
		}, 0);
	};

	const back = () => {
		if (
			window.confirm(
				t('Are you sure you want to leave without saving the drawing?', {
					_context: 'training_library/drawing',
				})
			)
		) {
			saveAndClose();
		}
	};

	const togglePreview = () => {
		if (!isPreviewing) {
			canvas.current.discardActiveObject?.();
			canvas.current.renderAll();
			setZoom(1);
			setSlide(0);
		}
		setIsPreviewing(!isPreviewing);
	};

	return (
		<div className={className}>
			<ActionBar.Bar smallScreenFullWidth>
				<ActionBar.PrimaryAction>
					<Fragment>
						<Back name="chevron" onClick={back} />
						<PageIcon variant="attachment" />
						<PageTitle>
							{t('Exercise', { _context: 'training_library' })} /{' '}
							{drawing.exerciseId
								? t('Edit drawing', { _context: 'training_library/drawing' })
								: t('New drawing', { _context: 'training_library/drawing' })}
						</PageTitle>
					</Fragment>
				</ActionBar.PrimaryAction>
				<Actions>
					<Button
						secondary
						icon={isPreviewing ? 'edit' : 'visibility'}
						onClick={togglePreview}>
						{isPreviewing ? t('Edit') : t('Preview')}
					</Button>
					<ExportButton />
					<Button primary onClick={save}>
						{t('Save and add to exercise', {
							_context: 'training_library/drawing',
						})}
					</Button>
				</Actions>
				{isPreviewing && (
					<PlaybackWrapper>
						<PlaybackControls />
					</PlaybackWrapper>
				)}
			</ActionBar.Bar>
		</div>
	);
}

const PlaybackWrapper = styled.div`
	position: absolute;
	top: 20px;
	left: 50%;
	transform: translateX(-50%);
`;

const ExportButton = () => {
	const { canvas, slide, setSetting } = useContext(FabricContext);

	const download = () => {
		setSetting('snappingVisible', false);
		setTimeout(() => {
			const image = canvas.current
				.toDataURL({ multiplier: 4 })
				.replace('image/png', 'image/octet-stream');
			const link = document.createElement('a');
			link.download =
				'exercise-slide-' +
				(slide + 1) +
				' - ' +
				new Date().toLocaleString('sv') +
				'.png';
			link.href = image;
			link.click();
			setSetting('snappingVisible', true);
		}, 0);
	};

	return (
		<Button secondary icon="share" onClick={download}>
			{t('Export image', { _context: 'training_library/drawing' })}
		</Button>
	);
};
