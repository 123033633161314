import { forAccountHeaders } from 'pkg/actions/utils';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as sdk from 'pkg/core/sdk';

export const getTaggedVideos = (
	taggedUserId: number,
	groupId?: number,
	forAccount?: number
): Promise<Response> => {
	const headers = forAccountHeaders(forAccount);

	if (!groupId) {
		return sdk.get(
			endpoints.Video.Index() + `?tagged_user_id=${taggedUserId}`,
			{},
			{},
			headers
		);
	}

	return sdk.get(
		endpoints.Video.Index() +
			`?group_id=${groupId}&tagged_user_id=${taggedUserId}`,
		{},
		{},
		headers
	);
};
