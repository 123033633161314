import { ReactNode } from 'react';

export const symbol_personal_injury: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-580q-66 0-108-42t-42-108q0-66 42-108t108-42q66 0 108 42t42 108q0 66-42 108t-108 42Zm0-60q39 0 64.5-25.5T570-730q0-39-25.5-64.5T480-820q-39 0-64.5 25.5T390-730q0 39 25.5 64.5T480-640Zm.107 130q74.107 0 137 18.5T731-450q31 14 50 41t19 65v204q0 24-18 42t-42 18H390q-41.818 0-70.909-29.128-29.091-29.128-29.091-71T319.091-251q29.091-29 70.909-29h118l72-160q-26-5-51.5-7.5t-48.611-2.5q-72.348 0-128.618 16.5Q295-417 252-396q-14 7-23 21.25T220-344v234q0 12.75-8.675 21.375Q202.649-80 189.825-80 177-80 168.5-88.625T160-110v-234q0-38 19-65t49-41.095Q279-473 342.5-491.5T480.107-510ZM390-140h54l37-80h-91q-16 0-28 12t-12 28q0 16 12 28t28 12Zm120 0h230v-204q0-16.5-9.5-30.75T707-396q-12-6-29-13t-39-15L510-140Zm-30-590Zm0 443Z"
		/>
	</svg>
);
