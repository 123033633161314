import { useCallback, useRef } from 'react';

function preventDefault(event: Event) {
	if (!isTouchEvent(event)) return;

	if (event.touches.length < 2 && event.preventDefault) {
		event.preventDefault();
	}
}

function isTouchEvent(event: Event): event is TouchEvent {
	return event && 'touches' in event;
}

interface PressHandlers<T> {
	onLongPress?: (event: React.TouchEvent<T>) => void;
	onClick?: (event: React.TouchEvent<T>) => void;
}

interface Options {
	delay?: number;
	shouldPreventDefault?: boolean;
	blurActiveElement?: boolean;
}

export default function useLongTouchPress<T>(
	{ onLongPress, onClick }: PressHandlers<T>,
	{
		delay = 300,
		shouldPreventDefault = false,
		blurActiveElement = false,
	}: Options = {}
): any {
	const longPressTriggered = useRef(false);

	const timeout = useRef<number>();
	const target = useRef<EventTarget>();

	const start = useCallback(
		(event: React.TouchEvent<T>) => {
			if (shouldPreventDefault && event.target) {
				event.target.addEventListener('touchend', preventDefault, {
					passive: false,
				});

				target.current = event.target;
			}

			const cancel = () => {
				window.clearTimeout(timeout.current);
			};

			event.target.addEventListener('touchmove', cancel, {
				once: true,
			});

			timeout.current = window.setTimeout(() => {
				if (
					blurActiveElement &&
					'activeElement' in document &&
					document.activeElement instanceof HTMLElement
				) {
					document.activeElement.blur();
				}

				onLongPress?.(event);
				longPressTriggered.current = true;
				event.target.removeEventListener('touchmove', cancel);
			}, delay);
		},
		[onLongPress, delay, shouldPreventDefault, blurActiveElement]
	);

	const clear = useCallback(
		(event: React.TouchEvent<T>, shouldTriggerClick = true) => {
			if (timeout.current) window.clearTimeout(timeout.current);
			if (shouldTriggerClick && !longPressTriggered.current) onClick?.(event);

			longPressTriggered.current = false;

			if (shouldPreventDefault && target.current) {
				target.current.removeEventListener('touchend', preventDefault);
			}
		},
		[shouldPreventDefault, onClick]
	);

	return {
		onTouchStart: (event: React.TouchEvent<T>): void => start(event),
		onTouchEnd: (event: React.TouchEvent<T>): void => clear(event),
	};
}
