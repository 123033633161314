import { ReactNode, useContext, createContext, useMemo, useRef } from 'react';

import * as json from 'pkg/json';
import { useCurrentRoute } from 'pkg/router/hooks';
import { replaceState } from 'pkg/router/state';
import { fromEntries } from 'pkg/utils';
import useMixedState from 'pkg/hooks/useMixedState';

/**
 * todo: types and type conversions in this hook is off, which is why we have a few "as any" sprinkled.
 * Refactor to avoid those workarounds.
 */

export type QueryValue = string | number | QueryValue[];

export interface QueryObject {
	[key: string]: QueryValue;
}

type QueryCommitHandler = () => void;

function unserializeValue(serialized: string): QueryValue {
	if (serialized.includes(',')) {
		return serialized
			.split(',')
			.map((value: string) => unserializeValue(value));
	}

	let value: QueryValue = serialized;

	if (!Number.isNaN(+value)) {
		value = Number.isInteger(value)
			? Number.parseInt(value, 10)
			: Number.parseFloat(value);
	}

	return value;
}

/**
 * Unserializes a string into a QueryValue.
 *
 * @param serialized string
 * @returns QueryValue
 */
export function unserializeArrayValue(serialized: string): QueryValue[] {
	return serialized.split(',').map((value: string) => unserializeValue(value));
}

/**
 * @deprecatred
 * @todo Remove this class when all qs functions use hooks.
 */
export class QueryState {
	protected previousQueryState: string;

	protected commitHandler: QueryCommitHandler;

	protected currentState: URLSearchParams;

	constructor(currentState: URLSearchParams) {
		this.currentState = currentState;
	}

	public get hasChanges(): boolean {
		const current = this.currentState;
		current.sort();

		const previous = new URLSearchParams(this.previousQueryState);
		previous.sort();

		return current.toString() !== previous.toString();
	}

	public addChangeHandler(commitHandler: QueryCommitHandler) {
		this.commitHandler = commitHandler;
	}

	protected getNextUrl(): string {
		let nextUrl: string = document.location.pathname;

		if (Array.from(this.currentState.keys()).length > 0) {
			nextUrl += `?${this.currentState}`;
		}

		return nextUrl;
	}

	/**
	 *	Updates state from current search params
	 */
	public refresh(): void {
		this.previousQueryState = this.currentState.toString();
		this.currentState = new URLSearchParams(document.location.search);
	}

	public commit(upateUrlParameters: boolean = true): void {
		if (upateUrlParameters) {
			replaceState(this.getNextUrl());

			if (this.commitHandler) {
				this.commitHandler();
			}
		}
	}

	public rollBack(upateUrlParameters: boolean = true): void {
		if (this.previousQueryState.length > 0) {
			this.currentState = new URLSearchParams(this.previousQueryState);

			this.previousQueryState = '';

			if (upateUrlParameters) {
				replaceState(this.getNextUrl());
			}
		}
	}

	public flush(upateUrlParameters: boolean = true): void {
		this.currentState = new URLSearchParams();

		if (upateUrlParameters) {
			replaceState(document.location.pathname);
		}
	}

	public has(key: string): boolean {
		return this.currentState.has(key);
	}

	public is(key: string, value: QueryValue): boolean {
		return this.has(key) && this.get(key) === value;
	}

	public set(key: string, value: QueryValue): void {
		this.currentState.set(key, encodeURIComponent(value as string));
	}

	public get(key: string, defaultReturn: QueryValue = null): QueryValue | null {
		return this.has(key)
			? decodeURIComponent(this.currentState.get(key))
			: defaultReturn;
	}

	public getArray(
		key: string,
		defaultReturn: QueryValue[] = []
	): QueryValue[] | null {
		return this.has(key)
			? unserializeArrayValue(this.currentState.get(key))
			: defaultReturn;
	}

	public remove(key: string): void {
		this.currentState.delete(key);
	}

	public setAll(params: QueryObject): void {
		for (const key in params) {
			this.set(key, params[key]);
		}
	}

	/**
	 * @NOTE Does *not* work as URLSearchParams.getAll does, this returns an object of current params.
	 *
	 * @returns QueryObject
	 */
	public getAll(): QueryObject {
		return fromEntries(this.currentState);
	}

	public get size(): number {
		return Array.from(this.currentState.keys()).length;
	}

	public toString(): string {
		return this.currentState.toString();
	}
}

export interface QueryStateInterface {
	has: (key: string, fromURL?: boolean) => boolean;
	is: (key: string, value: QueryValue) => boolean;

	set: (key: string, value: QueryValue) => void;
	setAll: (items: Partial<QueryObject>) => void;
	get: <T = QueryValue | null>(
		key: string,
		defaultReturn?: T,
		fromURL?: boolean
	) => T;
	getAll: () => QueryObject;
	getArray: <T = QueryValue>(
		key: string,
		defaultReturn?: T[],
		fromURL?: boolean
	) => T[];
	remove: (key: string) => void;

	refresh: () => void;
	flush: (upateUrlParameters?: boolean) => void;
	commit: () => void;

	toQueryString: () => string;
}

const QueryStateContext = createContext<QueryStateInterface>(null);

interface QueryStateProviderProps {
	children?: ReactNode | ReactNode[];
}

export function useQueryState(): QueryStateInterface {
	return useContext(QueryStateContext);
}

export function useQueryParams(): QueryObject {
	return fromEntries(new URLSearchParams(document.location.search));
}

export function QueryStateProvider({
	children,
}: QueryStateProviderProps): JSX.Element {
	const route = useCurrentRoute();

	const getCurrentLocationState = () =>
		Object.fromEntries(new URLSearchParams(document.location.search));

	const locationState = useMemo(getCurrentLocationState, [route.query]);

	// used to trigger updates for filters without changing the URL
	const [currentState, setCurrentState, resetCurrentState] =
		useMixedState<QueryObject>(locationState);
	// used for the commit method so that it can be safely called directly after other methods.
	const instantState = useRef(currentState);

	const has = (key: string, fromURL: boolean = true): boolean => {
		if (fromURL) {
			return key in getCurrentLocationState();
		}

		return key in currentState;
	};

	const is = (key: string, value: QueryValue): boolean => {
		if (key in currentState) {
			return currentState[key] === value;
		}

		return false;
	};

	const set = (key: string, value: QueryValue) => {
		if (Array.isArray(value)) {
			value = json.stringify(value);
		}

		setCurrentState({ [key]: value as string });
		instantState.current = { ...currentState, [key]: value as string };
	};

	const setAll = (newSet: Partial<QueryObject>) => {
		const newState: QueryObject = { ...currentState, ...newSet };

		setCurrentState(newState);
		instantState.current = newState;
	};

	function get<T = QueryValue | null>(
		key: string,
		defaultReturn: T = null,
		fromURL: boolean = true
	): T {
		if (!has(key, fromURL)) {
			return defaultReturn;
		}

		if (fromURL) {
			return getCurrentLocationState()[key] as T;
		}

		return currentState[key] as T;
	}

	const getAll = () => currentState;

	function getArray<T = QueryValue>(
		key: string,
		defaultReturn: T[] = [],
		fromURL: boolean = true
	): T[] {
		if (!has(key, fromURL)) {
			return defaultReturn;
		}

		if (fromURL) {
			const entry = getCurrentLocationState()[key];

			return json.parse(entry);
		}

		return json.parse((currentState as any).get(key));
	}

	const remove = (key: string) => {
		const s = { ...currentState };
		delete s[key];
		resetCurrentState({ ...s });
		instantState.current = { ...s };
	};

	const refresh = () => {
		resetCurrentState({ ...getCurrentLocationState() });
		instantState.current = { ...getCurrentLocationState() };
	};

	const flush = (upateUrlParameters: boolean = true) => {
		resetCurrentState({});
		instantState.current = {};

		if (upateUrlParameters) {
			replaceState(document.location.pathname);
		}
	};

	const commit = () => {
		const url = document.location.pathname;
		const qs = toQueryString();

		if (qs.length === 0) {
			replaceState(url);
			return;
		}

		replaceState(url, qs);
	};

	const toQueryString = () =>
		new URLSearchParams(instantState.current as any).toString();

	const queryState: QueryStateInterface = {
		has,
		is,

		set,
		setAll,
		get,
		getAll,
		getArray,
		remove,

		refresh,
		flush,
		commit,

		toQueryString,
	};

	return (
		<QueryStateContext.Provider value={queryState}>
			{children}
		</QueryStateContext.Provider>
	);
}
