export default {
	name: 'add-folder',
	objects: [
		{
			type: 'path',
			attributes: {
				d: 'M12.1777 9.97721C12.6196 9.97721 12.9777 10.3354 12.9777 10.7772V12.2602H14.4607C14.9025 12.2602 15.2607 12.6184 15.2607 13.0602C15.2607 13.502 14.9025 13.8602 14.4607 13.8602H12.9777V15.3432C12.9777 15.785 12.6196 16.1432 12.1777 16.1432C11.7359 16.1432 11.3777 15.785 11.3777 15.3432V13.8602H9.89473C9.4529 13.8602 9.09473 13.502 9.09473 13.0602C9.09473 12.6184 9.4529 12.2602 9.89473 12.2602H11.3777V10.7772C11.3777 10.3354 11.7359 9.97721 12.1777 9.97721Z',
			},
		},
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M11.3896 5.09936L13.6419 7.2198L18 7.2198C19.1046 7.2198 20 8.11523 20 9.2198L20 16.9006C20 18.0052 19.1046 18.9006 18 18.9006L6 18.9006C4.89543 18.9006 4 18.0052 4 16.9006L4.0001 7.09936C4.0001 5.99479 4.89553 5.09936 6.0001 5.09936H11.3896ZM18.7 16.9006V9.2198C18.7 8.8332 18.3866 8.5198 18 8.5198L5.3 8.5198L5.3 16.9006C5.3 17.2872 5.6134 17.6006 6 17.6006L18 17.6006C18.3866 17.6006 18.7 17.2872 18.7 16.9006Z',
			},
		},
	],
};
