import { ReactNode } from 'react';

export const symbol_card_membership: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-443v103h680v-103H140Zm0-437h680q24.75 0 42.375 17.625T880-820v480q0 24.75-17.625 42.375T820-280H626v151q0 17.5-14.5 26.25T582-102l-88-45q-7-3-14-3t-14 3l-88 45q-15 8-29.5-.75T334-129v-151H140q-24.75 0-42.375-17.625T80-340v-480q0-24.75 17.625-42.375T140-880Zm0 329h680v-269H140v269Zm0 211v-480 480Z"
		/>
	</svg>
);
