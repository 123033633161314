import { ReactNode } from 'react';

export const symbol_5g: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M340-280H150q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-340h190v-110H150q-12.75 0-21.375-8.625T120-480v-170q0-12.75 8.625-21.375T150-680h220q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T370-620H180v110h160q24.75 0 42.375 17.625T400-450v110q0 24.75-17.625 42.375T340-280Zm210 0q-24.75 0-42.375-17.625T490-340v-280q0-24.75 17.625-42.375T550-680h260q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-620H550v280h231v-112h-73q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T708-512h102q12.75 0 21.375 8.625T840-482v142q0 24.75-17 42.375T781-280H550Z"
		/>
	</svg>
);
