import { ReactNode } from 'react';

export const symbol_highlight: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m204-676-43-42q-9-9-9-21t8.609-21q9.391-9 21.891-9 12.5 0 21.5 9l42 42q8 9 8.5 21.1.5 12.1-8.5 20.9-9 9-21 8.5t-21-8.5Zm246-114v-60q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-850v60q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T450-790Zm264 73 43-43q9-8 21.1-8 12.1 0 20.9 9 9 9 9 21t-9 21l-43 43q-9 9-21 9t-21-8.609q-9-9.391-9-21.956T714-717ZM360-140v-140L258-382q-8-8-13-19.278-5-11.278-5-23.722v-115q0-24.75 17.625-42.375T300-600h360q24.75 0 42.375 17.625T720-540v115q0 12.444-5 23.722T702-382L600-280v140q0 24.75-17.625 42.375T540-80H420q-24.75 0-42.375-17.625T360-140Zm60 0h120v-165l120-120v-115H300v115l120 120v165Zm60-200Z"
		/>
	</svg>
);
