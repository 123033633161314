import { Fragment, useEffect, useRef, useState } from 'react';
import { T, useT } from '@transifex/react';

import { PageWidths } from 'pkg/config/sizes';
import spacing from 'pkg/config/spacing';

import { useCurrentOrganization } from 'pkg/identity';
import * as routes from 'pkg/router/routes';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import { replaceState } from 'pkg/router/state';
import { useEndpoint } from 'pkg/api/use_endpoint';
import { useCollection } from 'pkg/api/use_collection';
import * as actions from 'pkg/actions';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';

import {
	LargeScreen,
	SmallScreen,
	useSmallScreen,
} from 'components/MediaQuery';
import MaterialSymbol from 'components/material-symbols';
import * as StepModal from 'components/step-modal';

import Form, { FormPayload, submitForm } from 'components/form/Form';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import * as ActionBar from 'components/layout/ActionBar';
import * as Input from 'components/form/inputs';
import Section from 'components/form/Section';
import { Spinner } from 'components/loaders/spinner';
import InfoBox from 'components/form/info-box';
import Column from 'components/layout/column';
import Row from 'components/layout/row';

import * as Table from 'design/table';
import Button from 'design/button';
import useTableSet from 'design/table/use_table_set';

import { FormActionFooter } from 'styles/Form';

import * as css from './styles.css';

const MappableUserFieldTypes: models.userFields.UserFieldType[] = [
	models.userFields.UserFieldType.ShortText,
	models.userFields.UserFieldType.LongText,
	models.userFields.UserFieldType.Number,
	models.userFields.UserFieldType.Phone,
	models.userFields.UserFieldType.Email,
	models.userFields.UserFieldType.Date,
];

interface AttributeMappingProps {
	providerId: number;
}

interface Field {
	userFieldId: number;
	key: string;
}

interface MappingsPayload {
	mappings: Field[];
}

export default function AttributeMapping({
	providerId,
}: AttributeMappingProps): JSX.Element {
	const formRef = useRef(null);
	const isSmallScreen = useSmallScreen();
	const organization = useCurrentOrganization();
	const t = useT();

	const [busy, setBusy] = useState<boolean>(false);
	const [hasChanges, setHasChanges] = useState<boolean>(false);
	const [fields, setFields] = useState<Field[]>([]);

	const { record: provider, isLoading } =
		useEndpoint<models.identityProvider.IdentityProvider>(
			endpoints.IdentityProviders.Show(organization.id, providerId)
		);

	const { records: userFields, isLoading: isLoadingUserFields } =
		useCollection<models.userFields.UserField>(endpoints.UserField.Index(), {
			queryParams: new URLSearchParams({
				group_id: organization.id.toString(),
			}),
		});

	const { record: attributeMappings, isLoading: isLoadingAttributeMappings } =
		useEndpoint<models.identityProvider.AttributeMapping[]>(
			endpoints.IdentityProviders.IndexMapping(organization.id, providerId)
		);

	const availableUserFields = userFields?.filter(
		(field: models.userFields.UserField) =>
			MappableUserFieldTypes.includes(field.type)
	);

	const handleMappingsChange = (changedFields: Field[]) => {
		setFields(changedFields);

		if (!hasChanges) {
			setHasChanges(true);
		}
	};

	const handleSubmit = async (formPayload: FormPayload) => {
		setBusy(true);

		let mappings: Field[] = [];

		let raw = formPayload.mappings as unknown as Field[];
		raw = raw.filter((raw: Field) => raw && raw.key !== '');

		fields.forEach((field: Field) => {
			mappings.push({
				key:
					raw.find(
						(item) =>
							field.userFieldId ===
							Number.parseInt(item.userFieldId as unknown as string, 10)
					)?.key ?? '',
				userFieldId: field.userFieldId,
			});

			mappings = mappings.filter((item) => item.key !== '');
		});

		const mappingsPayload: MappingsPayload = { mappings };

		const [mappingsRequest] = await actions.identityProviders.mappings(
			organization.id,
			providerId,
			mappingsPayload
		);

		if (mappingsRequest.ok) {
			actions.flashes.show(
				{
					title: t('Updated Attribute Mappings'),
					message: t('Successfully updated attribute mappings for {org}', {
						org: organization.name,
					}),
				},
				mappingsRequest.status
			);
		} else {
			actions.flashes.show(
				{
					title: t('Something went wrong'),
					message: t('Could not update attribute mappings for {org}', {
						org: organization.name,
					}),
				},
				mappingsRequest.status
			);
		}

		setBusy(false);

		replaceState(routes.Organization.Settings.Sso(organization.id));
	};

	const handleCancel = () => {
		replaceState(routes.Organization.Settings.Sso(organization.id));
	};

	const handleFormSubmit = () => submitForm(formRef);

	const formComponent = (
		<Fragment>
			<Column spacing={spacing._6}>
				<InfoBox color="blue">
					<p>
						<T
							_str="Editing directory attribute mappings for {name}."
							name={<strong>{provider.directoryType}</strong>}
						/>
					</p>
				</InfoBox>
				<div>
					<Section
						icon="rebase_edit"
						title={t('Attribute mapping')}
						description={t(
							'Match your directory user fields with the corresponding fields in 360Player. Fields without a match, or empty will not be synced.'
						)}>
						{isLoadingUserFields || isLoadingAttributeMappings ? (
							<Spinner />
						) : (
							<AttributeMappingFields
								userFields={availableUserFields}
								mappings={attributeMappings}
								onChange={handleMappingsChange}
							/>
						)}
					</Section>
				</div>
			</Column>
		</Fragment>
	);

	if (isLoading) {
		return <Spinner />;
	}

	return (
		<Form formRef={formRef} onSubmit={handleSubmit}>
			<ActionBar.SaveBar maxWidth={PageWidths.STANDARD}>
				<Button
					label={t('Cancel')}
					block={isSmallScreen}
					large={isSmallScreen}
					onClick={handleCancel}
				/>
				<Button
					label={t('Save')}
					primary
					onClick={handleFormSubmit}
					block={isSmallScreen}
					large={isSmallScreen}
					isLoading={busy}
				/>
			</ActionBar.SaveBar>
			<LargeScreen>
				<LargeScreenContent.Inner maxWidth={PageWidths.STANDARD}>
					{formComponent}
					<FormActionFooter>
						<Button label={t('Cancel')} onClick={handleCancel} />
						<Button
							label={t('Save')}
							primary
							isLoading={busy}
							onClick={handleFormSubmit}
						/>
					</FormActionFooter>
				</LargeScreenContent.Inner>
			</LargeScreen>
			<SmallScreen>
				<LargeScreenContent.Inner>{formComponent}</LargeScreenContent.Inner>
			</SmallScreen>
		</Form>
	);
}

interface AttributeMappingFieldsProps {
	userFields: models.userFields.UserField[];
	mappings?: models.identityProvider.AttributeMapping[];

	onChange: (fields: Field[]) => void;
}

function AttributeMappingFields({
	userFields,
	mappings = [],

	onChange,
}: AttributeMappingFieldsProps): JSX.Element {
	const layout = '1fr 25px 1fr 40px';

	const t = useT();
	const [fields, setFields] = useState<Field[]>([]);
	const fieldIds = fields.map((field) => field.userFieldId);

	const userFieldIds = userFields.map((field) => field.id);
	const availableUserFieldIds = userFieldIds.filter(
		(id) => !fieldIds.includes(id)
	);

	const availableUserFields: models.userFields.UserField[] =
		availableUserFieldIds
			?.map((fieldId: number) =>
				userFields.find((uf: models.userFields.UserField) => uf.id === fieldId)
			)
			.filter((n) => n);

	const addField = (...payload: Field[]) => {
		setFields(fields.concat(payload).filter((n) => n));
	};

	const removeField = (userFieldId: number) => {
		setFields(fields.filter((field) => field.userFieldId !== userFieldId));
	};

	useComponentDidMount(() => {
		const fields: Field[] = mappings.map(
			(mapping: models.identityProvider.AttributeMapping) => ({
				key: mapping.key,
				userFieldId: mapping.userFieldId,
			})
		);

		addField(...fields);
	});

	useEffect(() => {
		onChange(fields);
	}, [fields]);

	const handleSelectedUserFields = (
		userFieldIds: number[],
		keys: string[] = []
	) => {
		const fields: Field[] = userFieldIds?.map((userFieldId, index) => ({
			userFieldId,
			key: keys?.[index] ?? '',
		}));

		addField(...fields);
	};

	const getUserFieldLabel = (userFieldId: number) =>
		userFields.find(
			(field: models.userFields.UserField) => field.id === userFieldId
		)?.label ?? '';

	return (
		<Column justify="start">
			{fields.length > 0 && (
				<Row align="center" columns={layout} className={css.attributeRow}>
					<strong>{t('Directory Provider Value', { _context: 'sso' })}</strong>
					<span />
					<strong>{t('User Field', { _context: 'sso' })}</strong>
					<span />
				</Row>
			)}

			{fields.map((field: Field) => {
				return (
					<Row
						key={`field:${field.userFieldId}`}
						align="center"
						columns={layout}
						className={css.attributeRow}>
						<Input.Field
							name={`mappings.${field.userFieldId}.key`}
							defaultValue={field.key}
							placeholder={t('attribute_field', {
								_context: 'sso',
								_comment: 'Attribute field placeholder',
							})}
						/>
						<MaterialSymbol variant="arrow_forward" actualSize scale={1.5} />
						<div className={css.readonlyField}>
							{getUserFieldLabel(field.userFieldId)}
						</div>
						<Input.Field
							readonly
							type="hidden"
							value={field.userFieldId}
							name={`mappings.${field.userFieldId}.userFieldId`}
						/>
						<Button
							secondary
							icon="delete"
							onClick={() => {
								removeField(field.userFieldId);
							}}
						/>
					</Row>
				);
			})}

			<MappableAttributesModal
				userFields={userFields}
				availableUserFields={availableUserFields}
				onSelect={handleSelectedUserFields}
			/>
		</Column>
	);
}

interface MappableAttributesModalProps {
	userFields: models.userFields.UserField[];
	availableUserFields: models.userFields.UserField[];
	onSelect: (userFieldIds: number[]) => void;
}

function MappableAttributesModal({
	userFields,
	availableUserFields,
	onSelect,
}: MappableAttributesModalProps): JSX.Element {
	const t = useT();
	const [open, setOpen] = useState<boolean>(false);

	const [selectedUserFieldIds, setSelectedUserFieldIds] = useState<number[]>(
		[]
	);

	const canAddMappableAttributes = availableUserFields.length > 0;
	const hasAddedMappableAttributes =
		availableUserFields.length !== userFields.length;

	const allSelected =
		availableUserFields.length > 0 &&
		availableUserFields.length === selectedUserFieldIds.length;

	const isSelected = (id: number) => selectedUserFieldIds.includes(id);

	const select = (fieldId: number) => {
		setSelectedUserFieldIds([...selectedUserFieldIds, fieldId]);
	};

	const deselect = (fieldId: number) => {
		setSelectedUserFieldIds(
			selectedUserFieldIds.filter((id: number) => id !== fieldId)
		);
	};

	const toggle = (fieldId: number) => {
		if (isSelected(fieldId)) {
			deselect(fieldId);
		} else {
			select(fieldId);
		}
	};

	const selectAll = () => {
		setSelectedUserFieldIds(
			availableUserFields.map((field: models.userFields.UserField) => field.id)
		);
	};

	const deselectAll = () => {
		setSelectedUserFieldIds([]);
	};

	const toggleAll = () => {
		if (allSelected) {
			deselectAll();
		} else {
			selectAll();
		}
	};

	const show = () => setOpen(true);

	const hide = () => {
		setOpen(false);
		deselectAll();
	};

	const handleNext = async () => {
		onSelect(selectedUserFieldIds);
		return true;
	};

	const attributes = useTableSet<models.userFields.UserField>(
		availableUserFields,
		{
			defaultSortKey: 'id',
			pagination: false,
			sortable: false,
			columns: [
				{
					width: 'max-content',
					content: (
						<Input.Control
							standalone
							type="checkbox"
							checked={allSelected}
							onChange={toggleAll}
						/>
					),
					columnKey: 'id',
					renderItemWith: (field: models.userFields.UserField) => (
						<Table.Cell onClick={() => toggle(field.id)}>
							<Input.Control
								standalone
								type="checkbox"
								value={field.id}
								checked={isSelected(field.id)}
							/>
						</Table.Cell>
					),
				},
				{
					content: 'Attribute',
					columnKey: 'label',
					renderItemWith: (field: models.userFields.UserField) => (
						<Table.Cell onClick={() => toggle(field.id)}>
							<Column spacing={spacing._1}>
								<strong>{field.label}</strong>
								{field.description && <span>{field.description}</span>}
							</Column>
						</Table.Cell>
					),
				},
			],
		}
	);

	return (
		<Fragment>
			{canAddMappableAttributes && (
				<Fragment>
					{hasAddedMappableAttributes ? (
						<Button inline icon="add" onClick={show}>
							{t('Add another mapping', { _context: 'sso' })}
						</Button>
					) : (
						<Button secondary block icon="add" onClick={show}>
							{t('Add field mapping', { _context: 'sso' })}
						</Button>
					)}
				</Fragment>
			)}
			{open && (
				<StepModal.Base onClose={hide}>
					<StepModal.Step
						skipBody
						onNext={handleNext}
						canGoNext={selectedUserFieldIds.length > 0}
						nextLabel={t('Add field(s)', { _context: 'sso' })}
						title={t('Mappable Attributes', { _context: 'sso' })}>
						{attributes.Table}
					</StepModal.Step>
				</StepModal.Base>
			)}
		</Fragment>
	);
}
