import { ReactNode } from 'react';

export const symbol_language_chinese_cangjie: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M190-411q-3 43-19 82t-46 68q-6 6-15 7t-15-5q-6-5-5-13.5t7-14.5q29-31 43.5-63t14.5-77v-111q0-6 4.5-10.5T170-553h275q6 0 10.5 4.5T460-538v112q0 6-4.5 10.5T445-411H190Zm3-28h229v-86H193v86Zm111-236q-38 48-85.5 85T115-528q-8 4-16.5 2T85-535q-5-6-2-12.5T94-558q52-26 98.5-59.5T275-696q5-7 13-9.5t16-2.5q8 0 16 2.5t13 8.5q38 44 86.5 75T521-566q8 4 12 11.5t-1 14.5q-5 8-15 9.5t-19-3.5q-55-26-105.5-59.5T304-675Zm-99 425q-8 0-13.5-5.5T186-269v-97q0-6 4.5-10.5T201-381h254q6 0 10.5 4.5T470-366v97q0 8-5.5 13.5T451-250q-8 0-13.5-5.5T432-269v-84H224v84q0 8-5.5 13.5T205-250Zm189-323H226q-5 0-9-4t-4-9q0-5 4-9t9-4h168q5 0 9 4t4 9q0 5-4 9t-9 4ZM176-469v-26h267v26H176Zm18 204v-28h252v28H194Zm510-55q32 0 63-15t57-43v-121q-29 4-51 8.5T731-480q-50 16-75 39.5T631-385q0 29 20 47t53 18Zm-14 51q-54 0-86.5-31T571-383q0-50 34-83.5T710-520q22-6 50.5-11.5T824-542q0-56-18.5-82.5T739-651q-22 0-44 6.5T644-622q-10 5-20 2.5T609-631q-5-10-2.5-20t12.5-15q31-17 64.5-27t67.5-10q69 0 101 42.5T884-540v237q0 11-7 18t-18 7h-3q-10 0-16.5-6.5T831-300l-3-27q-30 28-66.5 43T690-269Z"
		/>
	</svg>
);
