export function AddAttachment(postID: number, attachmentID: number): string {
	return `/v1/group-posts/${postID}/attachments/${attachmentID}`;
}

export function AddComment(postID: number): string {
	return `/v1/group-posts/${postID}/comments`;
}

export function AddLike(postID: number): string {
	return `/v1/group-posts/${postID}/likes`;
}

export function AddView(postID: number): string {
	return `/v1/group-posts/${postID}/views`;
}

export function Create(groupID: number): string {
	return `/v1/groups/${groupID}/posts`;
}

export function Delete(postID: number): string {
	return `/v1/group-posts/${postID}`;
}

export function DeleteComment(commentID: number): string {
	return `/v1/group-post-comments/${commentID}`;
}

export function Index(groupID: number): string {
	return `/v1/groups/${groupID}/posts`;
}

export function ListCopies(postID: number): string {
	return `/v1/group-posts/${postID}/copies`;
}

export function RemoveAttachment(postID: number, attachmentID: number): string {
	return `/v1/group-posts/${postID}/attachments/${attachmentID}`;
}

export function RemoveLike(postID: number): string {
	return `/v1/group-posts/${postID}/likes`;
}

export function Show(postID: number): string {
	return `/v1/group-posts/${postID}`;
}

export function ShowComments(postID: number): string {
	return `/v1/group-posts/${postID}/comments`;
}

export function ShowLikes(postID: number): string {
	return `/v1/group-posts/${postID}/likes`;
}

export function ShowViews(postID: number): string {
	return `/v1/group-posts/${postID}/views`;
}

export function Update(postID: number): string {
	return `/v1/group-posts/${postID}`;
}

export function UpdateComment(commentID: number): string {
	return `/v1/group-post-comments/${commentID}`;
}
