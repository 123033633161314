import { ReactNode } from 'react';

export const symbol_currency_yen: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M450-150v-120H270q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T270-330h180v-100H270q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T270-490h151L229-794q-10-15-1.474-30.5t26.319-15.5q8.155 0 15.198 3.684Q276.087-832.632 280-826l200 317 200-317q3.824-6.632 10.706-10.316Q697.588-840 706-840q17.872 0 26.436 15.5T731-794L539-490h151q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T690-430H510v100h180q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T690-270H510v120q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T450-150Z"
		/>
	</svg>
);
