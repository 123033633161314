import { ReactNode } from 'react';

export const symbol_filter_hdr: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M100-240q-18.617 0-26.808-16.5Q65-273 76-288l180-240q4.5-6 10.875-9T280-540q6.75 0 13.125 3T304-528l171 228h325L560-619 459-485q-9 12.235-21 12.618-12 .382-21-6.414-9-6.796-12-18.5T411-521l125-167q4.5-6 10.875-9T560-700q6.75 0 13.125 3T584-688l300 400q11 15 2.808 31.5Q878.617-240 860-240H100Zm335-60h365-334 51-82Zm-275 0h240L280-460 160-300Zm0 0h240-240Z"
		/>
	</svg>
);
