import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import { useCollection } from 'pkg/api/use_collection';
import * as endpoints from 'pkg/api/endpoints/auto';
import { UserAddress } from 'pkg/api/models/user_address';

import * as SideBar from 'components/sidebar/List';
import Column from 'components/layout/column';
import UserAddressDataList from 'components/user-address/DataList';

interface UserAddressFieldProps {
	value: string;
	label: string;
}

const UserAddressField = ({ value, label }: UserAddressFieldProps) => {
	return (
		<SideBar.Row>
			<SideBar.Name>{label}</SideBar.Name>
			<span>{value}</span>
		</SideBar.Row>
	);
};

const BillingAddress = ({ user }: { user: models.user.User }) => {
	const { records } = useCollection(endpoints.UserAddress.Index(), {
		queryParams: new URLSearchParams({ user_id: user.id.toString() }),
	});

	if (records.length === 0) return null;

	return (
		<Column>
			<SideBar.Title>{t('Billing address')}</SideBar.Title>
			{records.map((ua: UserAddress) => (
				<UserAddressDataList
					key={ua.id}
					userAddress={ua}
					item={UserAddressField}
				/>
			))}
		</Column>
	);
};

export default BillingAddress;
