import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import { Group } from 'pkg/api/models/onboarding_info';
import * as actions from 'pkg/actions';
import * as routes from 'pkg/router/routes';

import Title from 'routes/public/styles/Title';
import Button from 'routes/public/styles/Button';
import {
	fetchCurrentAccount,
	useOnboardingContext,
} from 'routes/public/onboarding';
import { useOnboardingState } from 'routes/public/hooks';
import Divider from 'routes/public/styles/Divider';
import InviteParent from 'routes/public/steps/finished/invite-parent';
import { useFetchAccountRelations } from 'routes/public/steps/Start';

import Column from 'components/layout/column';
import { Spinner } from 'components/loaders/spinner';

export default function ParentEmailSent() {
	const { account, resetStore, goTo, invitedEmail } = useOnboardingContext();
	const onboardingState = useOnboardingState();
	const returnToForms = onboardingState.get<string>('returnToForms');
	const group = onboardingState.get<Group>('group');

	const { wards } = useFetchAccountRelations();

	const wardAccount = wards.find(
		(accountRelation) => accountRelation.approvedAt
	)?.targetAccount;

	const handleGoToEmailInvite = () => goTo('parent-email-invite');

	const save = async () => {
		const [, account] = await fetchCurrentAccount();

		if (account.id) {
			const hasReturnToForms = returnToForms;

			resetStore();

			if (hasReturnToForms) {
				window.history.pushState(
					{},
					'',
					routes.Registration.Form(
						group.organizationSlug,
						onboardingState.get<string>('formGUID')
					)
				);
				window.location.reload();
			} else {
				window.history.pushState({}, '', '/');
				window.location.reload();
			}
		} else {
			resetStore();

			goTo('start');

			actions.flashes.show({
				title: t('Something went wrong'),
				message: t(
					"If you think this error isn't supposed to happen, please contact 360Player support."
				),
			});
		}
	};

	if (!wardAccount) {
		return <Spinner />;
	}

	return (
		<Column spacing={styles.spacing._8}>
			<Title
				title={t('Invite sent!')}
				description={t(
					'An invite has now been sent to {invitedEmail}! You will receive a notification once the invite has been claimed.',
					{ invitedEmail }
				)}
			/>
			<Column spacing={styles.spacing._4}>
				<Button primary label={t('Go to team')} onClick={save} />
				<Divider />
				<InviteParent
					account={account}
					wardAccount={wardAccount}
					handleGoToEmailInvite={handleGoToEmailInvite}
				/>
			</Column>
		</Column>
	);
}
