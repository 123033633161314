import { Component } from 'react';
import styled from 'styled-components';

const Outer = styled.div`
	position: relative;

	> svg {
		width: 100%;
		height: 100%;
		position: relative;
		z-index: 0;
	}
`;

const Positions = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	& > * {
		position: absolute;
		transform: translate(-50%, -50%);
	}

	.cam {
		left: 50%;
		top: 29%;
	}

	.cb {
		left: 50%;
		bottom: 15%;
	}

	.cdm {
		left: 50%;
		top: 58%;
	}

	.cf {
		left: 50%;
		top: 15%;
	}

	.cm {
		left: 50%;
		top: 44%;
	}

	.lb {
		left: 17%;
		bottom: 20%;
	}

	.lm {
		left: 23%;
		top: 36%;
	}

	.lw {
		left: 27%;
		top: 10%;
	}

	.rb {
		left: 83%;
		bottom: 20%;
	}

	.rm {
		left: 77%;
		top: 36%;
	}

	.rw {
		left: 73%;
		top: 10%;
	}

	.st {
		top: 0%;
		left: 50%;
	}

	.gk {
		bottom: -5%;
		left: 50%;
	}
`;

class PositionMap extends Component {
	render() {
		return (
			<Outer className={this.props.className}>
				<svg
					preserveAspectRatio="none"
					viewBox="0 0 493 346"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<path d="M0 335.916L94 0H399L492.5 335.916H0Z" fill="#44A055" />
					<path
						d="M138 0.65737L69 335.916H140.5L181 0L138 0.65737Z"
						fill="#328A43"
					/>
					<path d="M356 0L425 335.916H353.5L313 0H356Z" fill="#328A43" />
					<path d="M224 0L210.5 335.916H282L269.5 0H224Z" fill="#328A43" />
					<path
						d="M110.5 331.972H6L61.0631 128.963M110.5 331.972L124.5 240.056H367L381.5 331.972M110.5 331.972H187M381.5 331.972H487L431.014 128.963M381.5 331.972H305.5M187 331.972L190.5 293.619H302.5L305.5 331.972M187 331.972H305.5M61.0631 128.963L95.5 2H161M61.0631 128.963H431.014M431.014 128.963L396 2H331M161 2L155 42.9985H337.5L331 2M161 2H209M331 2H283M283 2L284 17.8704H207.5L209 2M283 2H209"
						stroke="#80CE8E"
						strokeWidth="1.5"
					/>
					<path
						d="M279.25 128.5C279.25 144.291 264.68 157.25 246.5 157.25C228.32 157.25 213.75 144.291 213.75 128.5C213.75 112.709 228.32 99.75 246.5 99.75C264.68 99.75 279.25 112.709 279.25 128.5Z"
						stroke="#80CE8E"
						strokeWidth="1.5"
					/>
					<path
						d="M280 240.056C280 229.1 265.002 220.218 246.5 220.218C227.998 220.218 213 229.1 213 240.056"
						stroke="#80CE8E"
						strokeWidth="1.5"
					/>
					<path
						d="M220 42.9985C220 50.3027 231.864 56.2239 246.5 56.2239C261.136 56.2239 273 50.3027 273 42.9986"
						stroke="#80CE8E"
						strokeWidth="1.5"
					/>
					<path
						d="M8.5 345.37L0 335.916H492.5L484 346L8.5 345.37Z"
						fill="#286935"
					/>
					<defs>
						<linearGradient
							id="paint0_linear"
							x1="246.25"
							y1="0"
							x2="246.25"
							y2="335.916"
							gradientUnits="userSpaceOnUse">
							<stop stopColor="#729531" />
							<stop offset="1" stopColor="#528426" />
						</linearGradient>
						<linearGradient
							id="paint1_linear"
							x1="125"
							y1="0"
							x2="125"
							y2="335.916"
							gradientUnits="userSpaceOnUse">
							<stop stopColor="#6A8E2D" />
							<stop offset="1" stopColor="#497D21" />
						</linearGradient>
						<linearGradient
							id="paint2_linear"
							x1="369"
							y1="0"
							x2="369"
							y2="335.916"
							gradientUnits="userSpaceOnUse">
							<stop stopColor="#6A8E2D" />
							<stop offset="1" stopColor="#497D21" />
						</linearGradient>
						<linearGradient
							id="paint3_linear"
							x1="246.25"
							y1="0"
							x2="246.25"
							y2="335.916"
							gradientUnits="userSpaceOnUse">
							<stop stopColor="#6A8E2D" />
							<stop offset="1" stopColor="#497D21" />
						</linearGradient>
					</defs>
				</svg>

				<Positions>{this.props.children}</Positions>
			</Outer>
		);
	}
}

export default styled(PositionMap)``;
