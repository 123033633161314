import { useRef, useMemo, memo, useCallback } from 'react';

import DateTime, { CalendarDayType } from 'pkg/datetime';
import { cssClasses } from 'pkg/css/utils';
import * as models from 'pkg/api/models';

import { ViewProps, useCalendarContext } from 'routes/group/calendar';
import EventWrapper from 'routes/group/calendar/components/events/single';
import Day from 'routes/group/calendar/components/day-item';
import { getEventsForDate, groupByDate } from 'routes/group/calendar/utils';

import * as css from './styles.css';

interface DaysProps {
	dateTime: DateTime;
	calendarDayType: CalendarDayType;

	onClick: (timestamp: number, view: string) => void;

	events: models.event.Event[];
}

const Days = memo(
	({ onClick, dateTime, calendarDayType, events }: DaysProps) => {
		const displayDay = () => {
			onClick(dateTime.startOfDay, 'day');
		};

		const isFirstOfMonth = calendarDayType.day === 1;

		return (
			<div className={css.day}>
				<div className={css.click} onClick={displayDay}></div>
				<div
					className={cssClasses(
						css.dayNumber,
						calendarDayType.isToday ? css.isToday : '',
						calendarDayType.isCurrentMonth ? css.isCurrentMonth : '',
						isFirstOfMonth ? css.isFirstOfMonth : ''
					)}>
					{isFirstOfMonth && !calendarDayType.isToday
						? new DateTime(
								new Date(calendarDayType.timestamp)
							).toLocaleDateString({
								month: 'short',
								day: 'numeric',
							})
						: calendarDayType.day}
				</div>
				<Day fixedHeight dateTime={dateTime}>
					{events.map((e) => (
						<EventWrapper key={e.id} event={e} />
					))}
				</Day>
			</div>
		);
	}
);

const MonthView = memo(({ changeView }: ViewProps): JSX.Element => {
	const calendarCtx = useCalendarContext();
	const calendar = calendarCtx.calendar;

	const groupedEvents = groupByDate(calendarCtx.eventsCollection.records);

	const getWeekNumberForDay = (d: Date) => {
		d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
		d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
		const yearStart = new DateTime(new Date(Date.UTC(d.getUTCFullYear(), 0, 1)))
			.startOfDay;
		const dTimestamp = new DateTime(d).startOfDay;
		const weekNo = Math.ceil(((dTimestamp - yearStart) / 86400000 + 1) / 7);
		return weekNo;
	};

	const getUniqueWeek = useCallback(() => {
		const allWeeks = calendar.map((day) =>
			getWeekNumberForDay(new Date(day.timestamp))
		);
		return [...new Set(allWeeks.map((item) => item))];
	}, [calendar]);

	const gridRef = useRef(null);
	const weeks = useMemo(() => {
		return getUniqueWeek();
	}, [getUniqueWeek]);

	return (
		<div className={css.calendarWrap}>
			<div className={css.daysWrap}>
				{calendar.slice(0, 7).map((day: CalendarDayType, index: number) => (
					<div className={css.dayName} key={index}>
						<span>
							{new DateTime(new Date(day.timestamp)).toWeekdayString()}
						</span>
					</div>
				))}
			</div>
			<div className={css.gridContainer} ref={gridRef}>
				<div className={css.weekWrap}>
					{weeks.map((weekNumber: number) => (
						<div className={css.weekNumber} key={weekNumber}>
							{weekNumber}
						</div>
					))}
				</div>
				<div className={css.datesWrap}>
					{calendar.map((day: CalendarDayType, index: number) => {
						const dayDate = new Date(day.timestamp);
						const dayDateTime = new DateTime(dayDate);

						const events = getEventsForDate(groupedEvents, dayDateTime);

						return (
							<Days
								calendarDayType={day}
								key={index}
								onClick={changeView}
								dateTime={dayDateTime}
								events={events}
							/>
						);
					})}
				</div>
			</div>
		</div>
	);
});

export default MonthView;
