import { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import Icon from 'components/icon';

import { FabricContext } from 'components/drawing/FabricContext';

import Button from 'design/button';

export const PlaybackControls = () => {
	const { slide, slides, setSlide, duration } = useContext(FabricContext);
	const previous = (e: any) => {
		e.preventDefault();
		setPlaying(false);
		if (slide > 0) setSlide(slide - 1);
	};

	const next = (e: any) => {
		e.preventDefault();
		setPlaying(false);
		if (slide < slides.length - 1) setSlide(slide + 1);
	};

	const [playing, setPlaying] = useState(false);

	const toggle = () => {
		if (!playing && slide + 1 === slides.length) {
			// start from beginning if playing from last slide
			setSlide(0);
		}
		setPlaying(!playing);
	};

	const timer = useRef(null);
	useEffect(() => {
		if (playing && slide + 1 < slides.length) {
			timer.current = setInterval(() => {
				setSlide(slide + 1);
			}, duration);
		} else {
			setPlaying(false);
		}

		return () => {
			clearInterval(timer.current);
		};
	}, [slide, playing]);
	return slides.length > 1 ? (
		<ButtonGroup>
			<Button small onClick={previous} disabled={!slide}>
				&larr;
			</Button>
			<Button small onClick={toggle}>
				<Icon name={playing ? 'av-pause' : 'av-play'} />{' '}
				{slide + 1 + '/' + slides.length}
			</Button>
			<Button small onClick={next} disabled={slide + 1 === slides.length}>
				&rarr;
			</Button>
		</ButtonGroup>
	) : null;
};

export const ButtonGroup = styled.div`
	display: flex;
	font-size: var(--font-size-sm);

	span {
		display: inline-block;
		padding: 8px;
		font-weight: var(--font-weight-bold);
		text-align: center;
	}

	button:first-child {
		border-radius: 6px 0 0 6px;
	}
	button:last-child {
		border-radius: 0 6px 6px 0;
	}
	button:first-child,
	button:last-child {
		font-size: var(--font-size-base);
	}
	button {
		border-radius: 0;
		margin: 0 -1px;

		&:hover:not([disabled]) {
			z-index: 1;
			border-radius: var(--radius-3);
		}

		&[disabled] {
			opacity: 1;
			color: var(--palette-gray-500);
		}
	}
`;
