import { ReactNode } from 'react';

export const symbol_laps: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M80-520q0-116 80.5-198T357-800h246q116 0 196.5 82T880-520q0 106-69 185t-174 93q-14 2-25.5-6T600-270q0-14 11-22.5t25-10.5q80-13 132-74.5T820-520q0-91-62.5-155.5T604-740H356q-91 0-153.5 64.5T140-520q0 91 63.5 155.5T358-300h33l-52-53q-9-9-9-21t9-21q9-9 21-9t21 9l104 104q9 9 9 21t-9 21L381-145q-9 9-21 8.5t-21-8.5q-9-9-9-21.5t9-21.5l52-52h-33q-116 0-197-82T80-520Z"
		/>
	</svg>
);
