import { t } from '@transifex/native';

import useMixedState from 'pkg/hooks/useMixedState';

import ProductDetails from 'routes/forms/create/products/ProductDetails';
import { SelectedProductInfo } from 'routes/forms/hooks/useFormEngine';

import StepModal, { Step } from 'components/step-modal';

interface EditProductModalProps {
	data: SelectedProductInfo;
	onDone: (formState: SelectedProductInfo) => void;
	onClose: () => void;
}
const EditProductModal: React.FC<
	React.PropsWithChildren<EditProductModalProps>
> = ({ data, onDone, onClose }) => {
	const [formState, setFormState] = useMixedState(data);

	const handleSave = async () => {
		await onDone(formState);
	};

	return (
		<StepModal onClose={onClose}>
			<Step title={t('Edit product')} onNext={handleSave}>
				<ProductDetails formState={formState} setFormState={setFormState} />
			</Step>
		</StepModal>
	);
};

export default EditProductModal;
