import { ReactNode } from 'react';

export const symbol_flex_direction: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M80-190v-220q0-13 8.5-21.5T110-440h300q13 0 21.5 8.5T440-410v220q0 13-8.5 21.5T410-160H110q-13 0-21.5-8.5T80-190Zm0-360v-220q0-13 8.5-21.5T110-800h300q13 0 21.5 8.5T440-770v220q0 13-8.5 21.5T410-520H110q-13 0-21.5-8.5T80-550Zm60-30h240v-160H140v160Zm594 408q-6 0-11-2t-10-7L569-325q-9-9-9-21t9-21q9-9 21-9t21 9l93 92v-495q0-13 8.5-21.5T734-800q13 0 21.5 8.5T764-770v495l93-92q9-8 21-8.5t21 8.5q9 9 9 21t-9 21L755-181q-5 5-10 7t-11 2Z"
		/>
	</svg>
);
