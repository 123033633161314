import { t } from '@transifex/native';

import { useCurrentGroup, useCurrentUser } from 'pkg/identity';
import * as models from 'pkg/api/models';

import Badge from 'components/Badge';
import Avatar from 'components/avatar';

import { Select, Option, OptionImage } from 'components/form/DecoratedSelect';
import FormRow from 'components/form/Row';
import Label from 'components/form/Label';

const SendAs = ({ dispatch, data }) => {
	const user = useCurrentUser();
	const group = useCurrentGroup();

	const handleAuthorChange = (value) => {
		dispatch({
			type: 'set_value',
			payload: {
				key: 'authorGroupId',
				value,
			},
		});
	};

	return (
		<FormRow>
			<Label>{t(`Send as`)}</Label>

			<Select value={data.authorGroupId} onChange={handleAuthorChange}>
				<Option value={null}>
					<OptionImage>
						<Avatar user={user} />
					</OptionImage>
					{models.user.fullName(user)}
				</Option>
				<Option value={group.id}>
					<OptionImage>
						<Badge badgeUrl={group.profileImageUrl} />
					</OptionImage>
					{group.name}
				</Option>
			</Select>
		</FormRow>
	);
};

export default SendAs;
