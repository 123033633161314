import { ReactNode } from 'react';

export const symbol_details: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M131-120q-17 0-25.5-15t-.5-30l349-628q8-16 26-16t26 16l349 628q8 15-.5 30T829-120H131Zm51-60h268v-482L182-180Zm328 0h268L510-662v482Z"
		/>
	</svg>
);
