import { ReactNode } from 'react';

export const symbol_public_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-67 20-127t57-110L47-827q-9-9-9-21.5t9-21.5q9-9 21.5-9t21.5 9l765 765q9 9 9 21t-9 21q-9 9-21.5 9T812-63l-95-94q-50 37-110 57T480-80Zm-43-61v-82q-35 0-59-26t-24-61v-44L149-559q-5 20-7 39.5t-2 39.5q0 130 84.5 227T437-141Zm443-339q0 57-14.5 108T825-276q-6 11-17.5 12.5T785-269q-10-7-13-19t3-23q22-39 33.5-81.5T820-480q0-106-58-192.5T607-799v18q0 35-24 61t-59 26h-67v69q0 10-9.5 13.5T431-615L278-768q-14-14-12.5-33t18.5-29q44-24 93-37t103-13q83 0 156 31.5T763-763q54 54 85.5 127T880-480Z"
		/>
	</svg>
);
