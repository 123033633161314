import { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import { useCollection } from 'pkg/api/use_collection';
import {
	useCurrentAccountUserIds,
	useCurrentGroupId,
	useCurrentMembership,
} from 'pkg/identity';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';

import Contacts from 'containers/chat/Contacts';

import StepModal, { Step } from 'components/step-modal';

import { Spinner } from 'components/loaders/spinner';

const GroupName = styled.div`
	padding: 1rem 1rem 0;

	input {
		display: block;
		font-size: 1.7rem;
		border: 0;
		height: 3.2rem;
		line-height: 3.2rem;
		padding: 0 0.8rem;
		border-left: 0.2rem solid var(--palette-gray-400);
		width: 100%;
		appearance: none;
		resize: none;
		color: var(--palette-black);

		::placeholder {
			color: var(--palette-gray-400);
		}

		&:focus {
			outline: none;
		}
	}
`;

interface NewGroupChatModalProps {
	onClose: () => void;
	onNext: (data: {
		title: string;
		users: models.user.User[];
		type: string;
	}) => void;
}

export default function NewGroupChatModal({
	onClose,
	onNext,
}: NewGroupChatModalProps) {
	const [selectedUsers, setSelectedUsers] = useState<models.user.User[]>([]);
	const [groupName, setGroupName] = useState('');

	const activeGroupId = useCurrentGroupId();
	const activeUserIds = useCurrentAccountUserIds();
	const activeMembership = useCurrentMembership();

	const { records, isLoading } = useCollection<models.membership.Membership>(
		endpoints.Groups.ShowUsers(activeGroupId),
		{
			queryParams: new URLSearchParams({ includeLegalGuardians: '1' }),
		}
	);

	const contacts = models.membership.filterActiveMembership(
		records,
		activeUserIds,
		activeMembership
	);

	const handleChangeGroupName = (event: ChangeEvent<HTMLInputElement>) => {
		setGroupName(event.target.value);
	};

	const selectUser = (users: models.user.User[]) => {
		setSelectedUsers([...users]);
	};

	const addChat = async () => {
		const chat = {
			title: groupName,
			users: selectedUsers,
			type: 'group',
		};

		onNext(chat);
	};

	const groupNameInputProps = {
		value: groupName,
		onChange: handleChangeGroupName,
		placeholder: t('Group name (optional)'),
	};

	let content = (
		<Contacts
			contacts={contacts}
			userClick={selectUser}
			selectedUsers={selectedUsers}
			multipleChoice
			showPreview
		/>
	);

	if (isLoading) {
		content = <Spinner />;
	}

	return (
		<StepModal onClose={onClose}>
			<Step
				title={t('New group chat')}
				nextLabel={t('Create group')}
				prevLabel={t('Cancel')}
				onNext={addChat}
				canGoNext={selectedUsers.length > 0}
				skipBody>
				<GroupName>
					<input {...groupNameInputProps} data-testid="chat.group_name_input" />
				</GroupName>
				{content}
			</Step>
		</StepModal>
	);
}
