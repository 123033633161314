import { ReactNode } from 'react';

export const symbol_tools_flat_head: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M350-120q-13 0-21.5-8.5T320-150q0-13 8.5-21.5T350-180h260q13 0 21.5 8.5T640-150q0 13-8.5 21.5T610-120H350Zm-4-120q-11 0-19.5-7.5T316-266l-34-242q-1-6-1-11.5t2-11.5l71-286q2-10 10-16.5t19-6.5h194q11 0 19 6.5t10 16.5l71 286q2 6 2 11.5t-1 11.5l-34 242q-2 11-10.5 18.5T614-240H346Zm26-60h216l27-190H344l28 190Zm-23-250h262l-57-230H406l-57 230Zm239 250H372h216Z"
		/>
	</svg>
);
