import ColorConverter from 'pkg/colorconverter';

import ColorPickerController from 'components/form/ColorPickerController';

const FormColorPickerController = (): JSX.Element => {
	const onSelect = (color: { hex: string; hsl: string; rgb: string }) => {
		console.log(color);
	};

	return (
		<ColorPickerController
			hsl={ColorConverter.from('55,100%,50%').toHSL()}
			onSelect={onSelect}
		/>
	);
};

export default FormColorPickerController;
