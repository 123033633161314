import { useRef } from 'react';

import useMixedState from 'pkg/hooks/useMixedState';
import {
	QueryObject,
	QueryStateInterface,
	QueryValue,
} from 'pkg/hooks/query-state';
import * as json from 'pkg/json';

// This hook can be used in the same way as `useQueryState`.
// It never stores anything in the URL, instead it uses `useMixedState`.
// The `fromActiveState` parameter behaves like `fromURL` in `useQueryState`
// and will only send back the commited state.
export function useFakeQueryState(): QueryStateInterface {
	const [currentState, setCurrentState, resetCurrentState] =
		useMixedState<QueryObject>({});

	const [activeState, setActiveState, resetActiveState] =
		useMixedState<QueryObject>({});

	const instantCurrentState = useRef(currentState);
	const instantActiveState = useRef(activeState);

	const has = (key: string, fromActiveState: boolean = true): boolean => {
		if (fromActiveState) {
			return key in activeState;
		}

		return key in currentState;
	};

	const is = (key: string, value: QueryValue): boolean => {
		if (key in currentState) {
			return currentState[key] === value;
		}

		return false;
	};

	const set = (key: string, value: QueryValue) => {
		setCurrentState({ [key]: value as string });
		instantCurrentState.current = { ...currentState, [key]: value as string };
	};

	const setAll = (newSet: Partial<QueryObject>) => {
		const newState: QueryObject = { ...currentState, ...newSet };

		setCurrentState(newState);
		instantCurrentState.current = newState;
	};

	function get<T = QueryValue | null>(
		key: string,
		defaultReturn: T = null,
		fromActiveState: boolean = true
	): T {
		if (!has(key, fromActiveState)) {
			return defaultReturn;
		}

		if (fromActiveState) {
			return activeState[key] as T;
		}

		return currentState[key] as T;
	}

	const getAll = () => currentState;

	function getArray<T = QueryValue>(
		key: string,
		defaultReturn: T[] = [],
		fromURL: boolean = true
	): T[] {
		if (!has(key, fromURL)) {
			return defaultReturn;
		}

		if (fromURL) {
			const entry = activeState[key] as string;

			return json.parse(entry);
		}

		return json.parse((currentState as any).get(key));
	}

	const remove = (key: string) => {
		const s = { ...currentState };
		delete s[key];

		resetCurrentState({ ...s });
		instantCurrentState.current = { ...s };

		resetActiveState({ ...s });
		instantActiveState.current = { ...s };
	};

	const refresh = () => {
		resetCurrentState({ ...activeState });
		instantCurrentState.current = { ...activeState };
	};

	const flush = (upateUrlParameters: boolean = true) => {
		resetCurrentState({});
		instantCurrentState.current = {};

		if (upateUrlParameters) {
			resetActiveState({});
			instantActiveState.current = {};
		}
	};

	const commit = () => {
		if (instantCurrentState.current.length === 0) {
			resetActiveState({});
			instantActiveState.current = {};
			return;
		}

		const newActiveState = { ...instantCurrentState.current };

		setActiveState(newActiveState);
		instantActiveState.current = newActiveState;
	};

	const toQueryString = () =>
		new URLSearchParams(instantCurrentState.current as any).toString();

	return {
		has,
		is,

		set,
		setAll,
		get,
		getAll,
		getArray,
		remove,

		refresh,
		flush,
		commit,

		toQueryString,
	};
}
