import { List, Map } from 'immutable';

import Exercise from 'pkg/models/exercise';
import Tag from 'pkg/models/tag';

import { RootState } from 'pkg/reducers';

const emptyExercise = new Exercise({});

export const findAll = (state: RootState): Map<number, Exercise> =>
	state.exercises.entities;

export const find = (state: RootState, exerciseId: number): Exercise =>
	state.exercises.entities.get(exerciseId) || emptyExercise;

export const findAllTags = (state: RootState, id: number): List<Tag> => {
	const exercise = find(state, id);

	return List(
		exercise.get('tags').map((tagId) => state.tags.entities.get(tagId))
	);
};
