import { t } from '@transifex/native';
import { Fragment, useState } from 'react';

import * as models from 'pkg/api/models';
import { CollectionResponse } from 'pkg/api/use_collection';

import Icon from 'components/icon';
import AssetImage from 'components/AssetImage';

import MatchCompetitionModal from 'components/match-competition/modal';
import { asString, FormPayload } from 'components/form/Form';

import Button from 'design/button';
import * as Table from 'design/table';
import * as Context from 'design/context_menu';
import { useDialog } from 'design/dialog';

interface RowProps {
	competition: models.matchCompetition.MatchCompetition;
	competitionCollection: CollectionResponse<models.matchCompetition.MatchCompetition>;
}

function Row({ competition, competitionCollection }: RowProps) {
	const [modal, setModal] = useState(false);

	const handleRemove = async () => {
		const ok = await models.destroy(competition);

		if (ok) {
			competitionCollection.removeRecord(competition.id);
		}
	};

	const confirm = useDialog({
		message: t('Are you sure you want to remove this competition?'),
		onConfirm: handleRemove,
	});

	const showModal = () => setModal(true);
	const hideModal = () => setModal(false);

	const handleEdit = async (data: FormPayload) => {
		const payload = {
			title: asString(data.title),
			description: asString(data.description),
		};

		const [req, resp] = await models.update<
			models.matchCompetition.MatchCompetitionPayload,
			models.matchCompetition.MatchCompetition
		>(competition, payload);

		if (req.ok) {
			competitionCollection.replaceRecord(resp);
			hideModal();
		}
	};

	return (
		<Fragment>
			<Table.Row>
				<Table.Cell>{competition.title}</Table.Cell>
				<Table.Cell>{competition.description || '-'}</Table.Cell>
				<Table.ActionCell>
					<Context.Menu
						toggleWith={
							<Context.TableTrigger>
								<Icon name="context-menu" />
							</Context.TableTrigger>
						}>
						<Context.Item onClick={showModal}>
							<Context.ItemIcon name="edit" />
							{t('Edit')}
						</Context.Item>
						<Context.Item caution onClick={confirm}>
							<Context.ItemIcon name="cancel" />
							{t('Remove')}
						</Context.Item>
					</Context.Menu>
				</Table.ActionCell>
			</Table.Row>
			{modal && (
				<MatchCompetitionModal
					type="edit"
					handleSave={handleEdit}
					competition={competition}
					onClose={hideModal}
				/>
			)}
		</Fragment>
	);
}

interface MatchCompetitionsTableProps {
	showMatchCompetitionModal: () => void;
	competitionCollection: CollectionResponse<models.matchCompetition.MatchCompetition>;
}

export default function MatchCompetitionsTable({
	competitionCollection,
	showMatchCompetitionModal,
}: MatchCompetitionsTableProps) {
	const columns: Table.HeaderColumn[] = [
		{
			content: t('Title'),
		},
		{
			content: t('Description'),
		},
		{
			content: '',
			width: 'max-content',
		},
	];

	const emptyState = {
		title: t('No competitions available yet'),
		content: t('Add your first competition to get started'),
		helpUrl:
			'https://help.360player.com/article/88-how-to-configure-your-club-settings-admins',
		image: <AssetImage src="img/missing-entities/match-statistics.svg" />,
		button: (
			<Button secondary icon="add" onClick={showMatchCompetitionModal}>
				{t('Create new')}
			</Button>
		),
	};

	return (
		<Table.Table columns={columns} emptyState={emptyState}>
			{competitionCollection.records.map((competition) => (
				<Row
					key={competition.id}
					competition={competition}
					competitionCollection={competitionCollection}
				/>
			))}
		</Table.Table>
	);
}
