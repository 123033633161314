export default {
	name: 'mail',
	objects: [
		{
			type: 'path',
			attributes: {
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				d: 'M4.02319 6.23161V17.7684H19.9578V6.23161H4.02319ZM6.27978 7.53161L11.8314 12.968C11.9685 13.1022 12.1881 13.1011 12.3238 12.9654L17.7576 7.53161H6.27978ZM18.6578 8.4699L15.1181 12.0095L18.6578 15.5492V8.4699ZM17.7385 16.4684L14.1989 12.9288L13.243 13.8847C12.6034 14.5243 11.5681 14.5297 10.9219 13.8968L9.85688 12.854L6.24243 16.4684H17.7385ZM5.32319 15.5492L8.928 11.9444L5.32319 8.41438V15.5492Z',
			},
		},
	],
};
