import { ReactNode } from 'react';

export const symbol_call_missed: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-571v182q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T120-389v-258q0-12.75 8.625-21.375T150-677h251q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T401-617H219l266 266 293-293q9-9 21-8.5t21.391 9.5Q829-634 829-622t-9 21L527-308q-9 9-20 13t-22 4q-11 0-22-4t-20-13L180-571Z"
		/>
	</svg>
);
