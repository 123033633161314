import { ReactNode } from 'react';

export const symbol_all_match: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M501-480ZM175-200q-18.261 0-26.63-15.5Q140-231 150-246l150-234-150-234q-10-15-1.63-30.5Q156.739-760 175-760h423q22 0 40 11.5t31 28.5l142 200q8 11 5.5 22.5T805-480.176q-9 6.176-21 5.676T764-487L610-700H230l121 187q10 15 10 33t-10 33L230-260h227q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T457-200H175Zm529-24-17-17q-9-9-21.5-9t-21.5 9q-9 9-9 21t9 21l44 44q6.818 7 15.909 7Q713-148 720-155l102-100q9-9 9-21.5t-9-21.5q-9-9-21.5-9t-21.5 9l-75 74Zm28.5 183Q655-41 600-96.5T545-228q0-78.435 54.99-133.717Q654.98-417 733-417q77 0 132.5 55.283Q921-306.435 921-228q0 76-55.5 131.5T732.5-41Z"
		/>
	</svg>
);
