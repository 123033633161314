import { useDispatch } from 'react-redux';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import Link from 'pkg/router/Link';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { useCurrentRoute } from 'pkg/router/hooks';
import * as actions from 'pkg/actions';
import * as language from 'pkg/i18n/language';
import { useFetchIdentity } from 'pkg/identity';
import { Group } from 'pkg/api/models/onboarding_info';

import { useOnboardingContext } from 'routes/public/onboarding';
import Button from 'routes/public/styles/Button';
import FooterNote from 'routes/public/styles/FooterNote';
import ThreeSixtyLogo from 'routes/public/styles/logo';
import Title from 'routes/public/styles/Title';
import { useConnectWithApple } from 'routes/public/connect';
import ForgotPasswordLink from 'routes/public/styles/ForgotPasswordLink';
import { useOnboardingState } from 'routes/public/hooks';

import Column from 'components/layout/column';

export default function Login(): JSX.Element {
	const { goTo } = useOnboardingContext();
	const route = useCurrentRoute();
	const dispatch = useDispatch();
	const onboardingState = useOnboardingState();
	const fetchIdentity = useFetchIdentity();

	const apple = useConnectWithApple({
		failure: () => {
			actions.flashes.show({
				title: t('Failed to log in'),
				message: t('Have you connected your 360Player account to Apple?'),
			});
		},
	});

	useComponentDidMount(async () => {
		const authToken = route.query?.code;
		const urlParams = new URLSearchParams(document.location.search);
		const returnToForms = urlParams.get('return');
		const formGUID = urlParams.get('guid');
		const organizationSlug = urlParams.get('organizationSlug');

		if (returnToForms === 'forms') {
			const group = onboardingState.get<Group>('group');
			onboardingState.set({
				returnToForms: true,
				formGUID: formGUID,
				group: { ...group, organizationSlug },
			});
		}

		if (!authToken) {
			return;
		}

		await dispatch(actions.auth.authWithApple(authToken, language.get(), true));
		await fetchIdentity();
	});

	const handleEmailLogin = () => {
		goTo('login-email');
	};

	const handleSingleSignOn = () => goTo('sso-org-slug');

	return (
		<Column spacing={styles.spacing._8}>
			<ThreeSixtyLogo />

			<Column spacing={styles.spacing._8}>
				<Title title={t('Sign in')} center />

				<Column>
					<Button
						label={t('Log in with email')}
						onClick={handleEmailLogin}
						testid="onboarding.email_login_button"
					/>

					<Button
						apple
						label={t('Continue with Apple')}
						onClick={apple.login}
					/>

					<Button
						label={t('Continue with single-sign on')}
						onClick={handleSingleSignOn}
					/>
				</Column>

				<ForgotPasswordLink />

				<FooterNote>
					<Link href="https://www.360player.com/">
						{t('Is your club not using 360Player?')}
					</Link>
				</FooterNote>
			</Column>
		</Column>
	);
}
