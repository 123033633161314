import { ReactNode } from 'react';

export const symbol_info_i: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M479.911-700Q451-700 430.5-720.589q-20.5-20.588-20.5-49.5Q410-799 430.589-819.5q20.588-20.5 49.5-20.5Q509-840 529.5-819.411q20.5 20.588 20.5 49.5Q550-741 529.411-720.5q-20.588 20.5-49.5 20.5Zm-.029 580Q459-120 444.5-134.583 430-149.167 430-170v-380q0-20.833 14.618-35.417Q459.235-600 480.118-600 501-600 515.5-585.417 530-570.833 530-550v380q0 20.833-14.618 35.417Q500.765-120 479.882-120Z"
		/>
	</svg>
);
