import { ReactNode } from 'react';

export const symbol_wrap_text: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M190-470q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T190-530h505q60 0 102.5 42.5T840-385q0 60-42.5 103T695-239H554l46 45q9 8 8.625 20.286-.375 12.285-8.625 21.5Q591-143 578.5-143t-21.5-9l-96-97q-5-5-7-10.133-2-5.134-2-11Q452-276 454-281q2-5 7-10l97-98q8.8-9 20.9-9 12.1 0 21.1 8.842 8.25 8.842 8.625 21T601-347l-47 48h141q35.214 0 60.107-25.5T780-385.152q0-35.151-24.893-60Q730.214-470 695-470H190Zm0 231q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T190-299h140q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T330-239H190Zm0-461q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T190-760h580q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T770-700H190Z"
		/>
	</svg>
);
