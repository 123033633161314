import { ReactNode } from 'react';

export const symbol_task: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m434-333-84-84q-4.114-4-8.914-6t-9.943-2Q326-425 321-422.75q-5 2.25-9 6.75-8 8.267-8 19.633Q304-385 312-377l101 101q5 5 10.133 7 5.134 2 11 2Q440-267 445-269q5-2 10-7l189-189q8-8.087 8-19.543Q652-496 643.931-504t-19.5-8Q613-512 605-504L434-333ZM220-80q-24 0-42-18t-18-42v-680q0-24 18-42t42-18h336q12.444 0 23.722 5T599-862l183 183q8 8 13 19.278 5 11.278 5 23.722v496q0 24-18 42t-42 18H220Zm331-584v-156H220v680h520v-494H581q-12.75 0-21.375-8.625T551-664ZM220-820v186-186 680-680Z"
		/>
	</svg>
);
