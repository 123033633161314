import { ReactNode } from 'react';

export const symbol_label_important: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M230-200q-35 0-53-31t2-61l121-188-121-188q-20-30-2-61t53-31h367.904Q620-760 638-748.5q18 11.5 31 28.5l146 205q11 15.972 11 34.986Q826-461 815-445L669-240q-13 17-31 28.5T597.904-200H230Zm380-60 156-220-156-220H230l121 187q10 15 10 33t-10 33L230-260h380ZM498-480Z"
		/>
	</svg>
);
