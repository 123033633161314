import { Fragment, useState } from 'react';
import { t } from '@transifex/native';

import { PageWidths } from 'pkg/config/sizes';

import * as flashActions from 'pkg/actions/flashes';
import { updateSortOrder } from 'pkg/actions/user_fields';
import {
	createUserFieldCategory,
	updateUserFieldCategory,
	updateUserFieldCategorySortOrder,
} from 'pkg/actions/user_field_categories';

import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCollection } from 'pkg/api/use_collection';
import { useFilters, FilterGroups } from 'pkg/filters/use_filters';
import { FilterOperator, createFilterGroups } from 'pkg/filters';
import { flashes } from 'pkg/actions';
import { userFieldCategoriesEnabled } from 'pkg/flags';
import * as sdk from 'pkg/core/sdk';

import FieldModal from 'routes/group/settings/user-fields/modal';
import FieldLists, {
	OtherFields,
	ReservedFields,
} from 'routes/group/settings/user-fields/list';
import CategoryFields from 'routes/user_fields/settings/category_fields';
import CategoryModal from 'routes/group/settings/user-fields/modal/CategoryModal';

import { Spinner } from 'components/loaders/spinner';
import FormComponent from 'components/form/Form';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import * as ActionBar from 'components/layout/ActionBar';
import Column from 'components/layout/column';

import Button from 'design/button';

interface UserFieldsProps {
	group: models.group.Group;
}

const UserFields = ({ group }: UserFieldsProps) => {
	const [showModal, setShowModal] = useState<boolean>(false);
	const [fieldInEdit, setFieldInEdit] =
		useState<models.userFields.UserField>(null);
	const [isEditingSort, setIsEditingSort] = useState<boolean>(false);
	const [sortedCustomFields, setSortedCustomFields] = useState<
		models.userFields.UserField[]
	>([]);
	const [sortedCategories, setSortedCategories] = useState<
		models.userFieldCategories.UserFieldCategory[]
	>([]);

	const [showDefaultFields, setShowDefaultFields] = useState<boolean>(false);

	const [showCategoryModal, setShowCategoryModal] = useState<boolean>(false);
	const [isEditingCategory, setIsEditingCategory] = useState<boolean>(false);
	const [categoryInEdit, setCategoryInEdit] =
		useState<models.userFieldCategories.UserFieldCategory | null>(null);

	const [hasFieldOrderChanged, setHasFieldOrderChanged] = useState(false);
	const [hasCategoryOrderChanged, setHasCategoryOrderChanged] = useState(false);

	const filterGroups: FilterGroups = createFilterGroups({
		[t('Label')]: {
			hidden: true,
			filters: {
				Label: {
					type: 'text',
					operator: FilterOperator.Contains,
					property: 'label',
				},
			},
		},
	});

	const filters = useFilters({
		groups: filterGroups,
	});

	filters.queryParam.set('group_id', group.id.toString());
	const {
		records: organizationFields,
		refresh,
		replaceRecord,
		removeRecord,
		isLoading,
	} = useCollection<models.userFields.UserField>(endpoints.UserField.Index(), {
		queryParams: filters.queryParam,
	});

	const handleShowModal = () => {
		setShowModal(true);
	};

	const handleCloseModal = () => {
		setFieldInEdit(null);
		setShowModal(false);
	};

	const handleEditField = (field: models.userFields.UserField) => {
		setFieldInEdit(field);
		handleShowModal();
	};

	const handleToggleDefaultFields = () =>
		setShowDefaultFields(!showDefaultFields);

	const handleSaveField = async (
		data: models.userFields.UserField,
		isEdit: boolean
	) => {
		const payload: Partial<models.userFields.UserField> = {
			groupId: group.id,
			label: data.label,
			description: data.description,
			required: !!data.required,
			visibility: data.visibility,
			type: data.type,
		};

		if (payload.type === models.userFields.UserFieldType.Reserved) {
			payload.key = data.key;
		}

		if (data.attachmentType) {
			payload.attachmentType = data.attachmentType;
		}

		if (data.values?.length > 0) {
			payload.values = data.values;
		}

		if (data.sortOrder) {
			payload.sortOrder = data.sortOrder;
		}

		const [request, , err] = isEdit
			? await models.update(fieldInEdit, payload)
			: await models.create(endpoints.UserField.Create(), payload);

		if (request.ok) {
			refresh();
			return;
		}

		if (
			err?.error ===
			'cannot update user field due to potential modification of existing user field values'
		) {
			flashes.show(
				{
					title: t('Failed to save custom field'),
					message: t(
						'Cannot update field or attachment type when data is already saved on contacts.'
					),
				},
				400
			);
		}
	};

	const handleDrag = (fields: models.userFields.UserField[]) => {
		setHasFieldOrderChanged(true);
		setSortedCustomFields(
			fields.map((field, index) => {
				field.sortOrder = index;
				return field;
			})
		);
	};

	const handleCategoryDrag = (
		categories: models.userFieldCategories.UserFieldCategory[]
	) => {
		setHasCategoryOrderChanged(true);
		setSortedCategories(
			categories.map((category, index) => {
				category.sortOrder = index;
				return category;
			})
		);
	};

	const handleUpdateCategoryOrders = async () => {
		const categoryIds = sortedCategories.map((category) => category.id);

		const [ok] = await updateUserFieldCategorySortOrder(group.id, categoryIds);

		if (ok) {
			refresh();
			setIsEditingSort(false);
			return ok;
		}
	};

	const handleUpdateSortChanges = async () => {
		if (hasFieldOrderChanged && hasCategoryOrderChanged) {
			await handleUpdateSortOrders();
			await handleUpdateCategoryOrders();
		} else if (hasFieldOrderChanged) {
			await handleUpdateSortOrders();
		} else if (hasCategoryOrderChanged) {
			await handleUpdateCategoryOrders();
		}
		setIsEditingSort(false);
		setHasFieldOrderChanged(false);
		setHasCategoryOrderChanged(false);
	};

	const handleUpdateSortOrders = async () => {
		const sortOrders: { [key: number]: number } = {};

		for (const field of sortedCustomFields) {
			sortOrders[field.id] = field.sortOrder + 1;
		}

		const [ok] = await updateSortOrder(sortOrders);
		if (ok) {
			refresh();
			setIsEditingSort(false);
			return ok;
		}
	};

	const handleEditSort = () => setIsEditingSort(true);

	const handleCancelSort = () => {
		setIsEditingSort(false);
		setHasFieldOrderChanged(false);
		setHasCategoryOrderChanged(false);
	};

	const handleShowCategoryModal = () => {
		setShowCategoryModal(true);
		setIsEditingCategory(false);
		setCategoryInEdit(null);
	};

	const handleCloseCategoryModal = () => {
		setShowCategoryModal(false);
	};

	const handleSaveCategory = async (payload: { title: string }) => {
		const requestPayload: models.userFieldCategories.UserFieldCategory = {
			title: payload.title,
			groupId: group.id,
		};

		if (!isEditingCategory) {
			const [ok] = await createUserFieldCategory(group.id, requestPayload);

			if (ok) {
				refresh();
				return ok;
			}
		}
		if (isEditingCategory) {
			const [ok] = await updateUserFieldCategory(
				group.id,
				payload,
				categoryInEdit.id
			);

			if (ok) {
				refresh();
				return ok;
			}
		}
	};

	const handleDeleteCategory = async () => {
		const req = await sdk.destroy(
			endpoints.UserFieldCategories.Delete(group.id, categoryInEdit.id),
			{},
			{}
		);

		if (req.ok) {
			setShowCategoryModal(false);
			refresh();
			return true;
		}
		if (!req.ok) {
			flashActions.show({ title: t('Something went wrong') }, req.status);
			return false;
		}
	};

	const handleEditCategory = (
		category: models.userFieldCategories.UserFieldCategory
	) => {
		setCategoryInEdit(category);
		setShowCategoryModal(true);
		setIsEditingCategory(true);
	};

	return (
		<Fragment>
			<ActionBar.IntegratedFilterBar
				pageActionIcon="more_horiz"
				filters={filters}
				searchFilter={{
					type: 'text',
					operator: FilterOperator.Contains,
					property: 'label',
				}}
				actions={[
					isEditingSort
						? { label: t('Cancel'), onClick: handleCancelSort }
						: {
								label: t('Edit sort order'),
								onClick: handleEditSort,
								icon: 'settings',
							},
					isEditingSort
						? {
								label: t('Save sort order'),
								onClick: handleUpdateSortChanges,
								type: 'primary',
							}
						: {
								label: t('Create new custom field'),
								icon: 'add',
								onClick: handleShowModal,
								type: 'primary',
							},
				]}
			/>

			<LargeScreenContent.Inner maxWidth={PageWidths.STANDARD}>
				<Column>
					<FieldLists>
						{isLoading ? (
							<Spinner />
						) : (
							<Fragment>
								<ReservedFields
									group={group}
									userFields={organizationFields}
									showList={showDefaultFields}
									onToggle={handleToggleDefaultFields}
									onReplace={replaceRecord}
									onRefresh={refresh}
								/>
								{userFieldCategoriesEnabled() && (
									<CategoryFields
										isEditingSort={isEditingSort}
										group={group}
										onReplace={replaceRecord}
										onRemove={removeRecord}
										onDrag={handleDrag}
										onCategoryDrag={handleCategoryDrag}
										onCategoryEdit={handleEditCategory}
										fieldRefresh={refresh}
									/>
								)}
								<OtherFields
									userFields={
										sortedCustomFields.length > 0
											? sortedCustomFields
											: organizationFields
									}
									isEditingSort={isEditingSort}
									onReplace={replaceRecord}
									onRemove={removeRecord}
									onEdit={handleEditField}
									onDrag={handleDrag}
									onCreate={handleShowModal}
								/>
							</Fragment>
						)}
					</FieldLists>
					{userFieldCategoriesEnabled() && (
						<Button icon="add" onClick={handleShowCategoryModal}>
							{t('New category')}
						</Button>
					)}
				</Column>
			</LargeScreenContent.Inner>

			<FormComponent>
				{showModal && (
					<FieldModal
						existingFields={organizationFields}
						prevData={fieldInEdit}
						group={group}
						onDone={handleSaveField}
						onClose={handleCloseModal}
					/>
				)}
				{showCategoryModal && (
					<CategoryModal
						handleCategoryModalClose={handleCloseCategoryModal}
						handleCreateNewCategory={handleSaveCategory}
						isEditingCategory={isEditingCategory}
						handleDeleteCategory={handleDeleteCategory}
						categoryInEdit={categoryInEdit}
					/>
				)}
			</FormComponent>
		</Fragment>
	);
};
export default UserFields;
