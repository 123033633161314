import { ReactNode } from 'react';

export const symbol_top_panel_close: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m459-412-89 89q-7 7-3.458 16.5Q370.083-297 380-297h200q9.917 0 13.458-9.5Q597-316 590-323l-89-89q-9-9-21-9t-21 9ZM180-120q-24.75 0-42.375-17.625T120-180v-600q0-24.75 17.625-42.375T180-840h600q24.75 0 42.375 17.625T840-780v600q0 24.75-17.625 42.375T780-120H180Zm600-513v-147H180v147h600Zm-600 60v393h600v-393H180Zm0-60v-147 147Z"
		/>
	</svg>
);
