import { Map, Record } from 'immutable';

import MatchOpponent from 'pkg/models/match_opponent';

import { MatchOpponents as actionTypes } from 'pkg/actions/action-types';

const initialState = Record(
	{
		entities: new Map(),
	},
	'matchOpponents'
);

const addMatchOpponents = (state, { payload }) => {
	Object.values(payload).forEach((matchOpponent) => {
		state = state.setIn(
			['entities', matchOpponent.id],
			new MatchOpponent(matchOpponent)
		);
	});

	return state;
};

const matchOpponents = (state = initialState(), action = {}) => {
	switch (action.type) {
		case actionTypes.SET_ITEMS: {
			return addMatchOpponents(state, action);
		}

		default:
			return state;
	}
};

export default matchOpponents;
