// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gpuLsCNk9QMjD5SvplMM {
	font-size: var(--font-size-2xs);
	line-height: var(--font-line-height-2xs);
	color: var(--palette-gray-500);
}

.xW5Fo8p5crTHiHf8DopL {
	font-weight: var(--font-weight-semibold);
}
`, "",{"version":3,"sources":["webpack://./routes/group/show/overdue_products/styles.css"],"names":[],"mappings":"AAAA;CACC,+BAA+B;CAC/B,wCAAwC;CACxC,8BAA8B;AAC/B;;AAEA;CACC,wCAAwC;AACzC","sourcesContent":[".validUntil {\n\tfont-size: var(--font-size-2xs);\n\tline-height: var(--font-line-height-2xs);\n\tcolor: var(--palette-gray-500);\n}\n\n.overdueProductsTitle {\n\tfont-weight: var(--font-weight-semibold);\n}\n"],"sourceRoot":""}]);
// Exports
export var validUntil = `gpuLsCNk9QMjD5SvplMM`;
export var overdueProductsTitle = `xW5Fo8p5crTHiHf8DopL`;
export default ___CSS_LOADER_EXPORT___;
