import { ReactNode } from 'react';

export const symbol_input_circle: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M450-515v406q0 13 8.5 21.5T480-79q13 0 21.5-8.5T510-109v-406l93 92q9 8 21 8.5t21-8.5q9-9 9-21t-9-21L501-609q-9-9-21-9t-21 9L315-465q-9 9-9 21t9 21q9 9 21 9t21-9l93-92Zm30-305q-142 0-241 99t-99 241q0 40 8.5 78.5T175-328q6 12 4.5 25T168-281q-9 8-20.5 7T130-286q-25-45-37.5-94T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 51-12.5 100T830-286q-6 11-17.5 12t-20.5-7q-10-9-11.5-22t4.5-25q17-35 26-73.5t9-78.5q0-142-99-241t-241-99Z"
		/>
	</svg>
);
