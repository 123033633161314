import { ReactNode } from 'react';

export const symbol_local_atm: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M535-379H395q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T395-319h58v15q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625 12.825 0 21.325-8.625T513-304v-15h48q15 0 24.5-11.5t9.5-27.727v-114.546q0-16.227-9.5-27.727T561-512H425v-69h140q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T565-641h-52v-15q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T453-656v15h-49q-16.575 0-27.788 11.5Q365-618 365-601v114q0 14.875 11.5 24.938Q388-452 404-452h131v73ZM140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm0-60h680v-520H140v520Zm0 0v-520 520Z"
		/>
	</svg>
);
