import { ReactNode } from 'react';

export const symbol_taxi_alert: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-224v-210 210Zm496.765-50Q660-274 675.5-289.75T691-328q0-23.333-15.75-39.667Q659.5-384 637-384q-23.333 0-39.667 16.265Q581-351.471 581-328.235 581-305 597.265-289.5q16.264 15.5 39.5 15.5Zm-391 0Q269-274 284.5-289.75T300-328q0-23.333-15.75-39.667Q268.5-384 246-384q-23.333 0-39.667 16.265Q190-351.471 190-328.235 190-305 206.265-289.5q16.264 15.5 39.5 15.5Zm443.422-224Q610-498 554-554.011q-56-56.01-56-135.478Q498-768 554-824t135-56q80 0 135.5 55.5T880-689q0 80-55.813 135.5-55.812 55.5-135 55.5ZM692-659q8 0 12.5-5t4.5-13v-108q0-8-5-12.5t-13-4.5q-8 0-12.5 5t-4.5 13v108q0 8 5 12.5t13 4.5Zm-.5 85q9.5 0 16-6t6.5-16q0-10-6.6-16t-15.4-6q-11 1-16.5 7t-5.5 15.5q0 9.5 6 15.5t15.5 6ZM80-424q0-4.667.5-9.333Q81-438 83-443l82-247q5-14 16.5-22t26.5-8h125v-30.175q0-12.825 8.625-21.325T363-780h61q10.5 0 15.75 8t1.917 18.242Q437-738 435.5-721.792 434-705.585 434-689v4.6q0 10.4-6.069 17.4-6.069 7-15.931 7H218l-55 166h321q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T484-434H140v210h600v-181q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T800-405v285q0 16.667-11.735 28.333Q776.529-80 759.765-80 743-80 731.5-91.667 720-103.333 720-120v-44H160v44q0 16.667-11.735 28.333Q136.529-80 119.765-80 103-80 91.5-91.667 80-103.333 80-120v-304Z"
		/>
	</svg>
);
