import styled from 'styled-components';

const Divider = styled.hr`
	border: none;
	border-top: 1px solid var(--palette-gray-300);
	margin: var(--spacing-8) 0;
`;

export default Divider;

export const ColumnDivider = styled.hr`
	border: none;
	border-right: 1px solid var(--palette-gray-300);
	height: 100%;
`;
