import styled from 'styled-components';

export const Wrapper = styled.form`
	display: grid;
	grid-auto-flow: row;
	gap: var(--spacing-6);
`;

export const FormActionFooter = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: var(--spacing-3);
	padding: var(--spacing-4);
	margin: var(--spacing-7) 0 var(--spacing-9) 0;
	background-color: var(--palette-blue-100);
	color: var(--palette-blue-500);
	border-radius: var(--radius-3);
`;
