import { ReactNode } from 'react';

export const symbol_electric_bike: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M193-280q-81 0-137.5-57T-1-475q0-81 57.038-137.5Q113.075-669 194-669q71 0 124.5 45.5T383-508h51l-82-232h-43q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T309-800h128q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T437-740h-22l26 71h222l-71-191h-75q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T517-920h69q24 0 40.5 11t24.5 33l76 207h38q80.51 0 137.255 56.234Q959-556.532 959-476.746 959-396 902.74-338 846.48-280 765-280q-71.602 0-125.801-48T573-448H383q-11 72-64.5 120T193-280Zm0-60q48 0 83.5-31t47.5-77h-89q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T235-508h89q-12-45-48-73t-82-28q-56 0-95.5 39T59-475q0 56.25 39 95.625T193-340Zm305-168h76q4-23 15.5-51t31.5-50H462l36 101Zm267 168q56 0 95-39.375T899-475q0-56-39-95t-95-39h-16l29 85q4 11-1 22t-18 16q-11 4-21.5-1.5T722-504l-33-85q-29 17-43.5 48T631-475q0 56.25 39 95.625T765-340ZM516-115v51q0 8.13-7 12.565Q502-47 494-51l-173-88q-5-3-3.909-8.5 1.091-5.5 6.909-5.5h116v-52q0-8.13 7-12.565 7-4.435 15-.435l177 89q5 2.947 3.5 8.474Q641-115 635-115H516ZM192-475Zm573 0Z"
		/>
	</svg>
);
