// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RV2CM_LCpl3Gamh9TlAe {
	text-decoration: underline;
	color: var(--palette-blue-500);
}
`, "",{"version":3,"sources":["webpack://./routes/forms/list/active/row/styles.css"],"names":[],"mappings":"AAAA;CACC,0BAA0B;CAC1B,8BAA8B;AAC/B","sourcesContent":[".link {\n\ttext-decoration: underline;\n\tcolor: var(--palette-blue-500);\n}\n"],"sourceRoot":""}]);
// Exports
export var link = `RV2CM_LCpl3Gamh9TlAe`;
export default ___CSS_LOADER_EXPORT___;
