import { ReactNode } from 'react';

export const symbol_app_shortcut: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M260-130v30h440v-30H260Zm0-700h440v-30H260v30Zm0 0v-30 30Zm0 700v30-30Zm466-321H460v121q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T400-330v-121q0-24.75 17.625-42.375T460-511h266l-61-60q-9-8.25-9-20.625T665-613q9-9 21-9t21 9l111 111q9 9 9 21t-9 21L707-349q-8.25 8.25-20.625 8.625T665-349q-9-9-9-21t9-21l61-60ZM260-40q-24.75 0-42.375-17.625T200-100v-760q0-24.75 17.625-42.375T260-920h440q24.75 0 42.375 17.625T760-860v116q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T700-744v-26H260v580h440v-26q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T760-216v116q0 24.75-17.625 42.375T700-40H260Z"
		/>
	</svg>
);
