import { t } from '@transifex/native';
import { Fragment, useState, memo } from 'react';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import { Map } from 'immutable';

import * as tagConfig from 'pkg/config/tags';

import Tag from 'pkg/models/tag';

import { searchTag } from 'pkg/actions/tags';

import { clean } from 'pkg/strings';

import TagItem, { Tags } from 'components/Tag';

import AutocompleteInput from 'components/form/AutocompleteInput';

const CreateTag = styled.div`
	display: inline-grid;
	grid-auto-flow: column;
	align-items: center;
	grid-gap: 0.5rem;
`;

const CreateLabel = styled.span`
	font-weight: var(--font-weight-normal);
`;

const AttachedTags = styled(Tags)`
	display: flex;
	flex-flow: wrap;
	gap: var(--spacing-2);

	${(props) =>
		props.location === 'above' &&
		css`
			margin-bottom: 1rem;
		`}

	${(props) =>
		props.location === 'below' &&
		css`
			margin-top: 1rem;
		`}
`;

const AutoCompleteItem = memo(({ value, isActive }) => {
	let name = tagConfig.getName(value.get('name'));

	return (
		<TagItem
			addable
			type="input"
			key={`tag-${value.get('id')}`}
			active={isActive}
			name={name}
		/>
	);
});

const renderCreateWith = (unsavedTag, isActive) =>
	unsavedTag &&
	!tagConfig.isAliased(unsavedTag) && (
		<CreateTag>
			<CreateLabel>{t(`Create new tag`)}</CreateLabel>
			<TagItem addable large type="input" active={isActive} name={unsavedTag} />
		</CreateTag>
	);

const TagForm = ({
	searchTag,
	tags,
	type,
	onSelect,
	onCreate,
	onRemove,
	location = 'above',
	inline = false,
}) => {
	const [selectedTags, setSelectedTags] = useState([]);
	const [items, setItems] = useState(tagConfig.defaultTags());
	const [isFetching, setFetching] = useState(false);

	const handleSearch = async (search) => {
		if (!search.trim()) {
			return;
		}

		setFetching(true);

		const data = await searchTag(search);
		const items = Object.values(data?.entities.tags || {});

		if (items.length > 0) {
			const tags = {};

			items.forEach((tag) => {
				tags[tag.name] = new Tag(tag);
			});

			setItems(new Map(tags));
		} else {
			setItems(new Map({}));
		}

		setFetching(false);
	};

	const setDefaultTags = (a) => {
		if (a.length === 0) {
			setItems(tagConfig.defaultTags());
		}
	};

	const handleCreate = (name) => {
		name = clean(name).replace(/\s+/g, '_');

		if (!tags.find((tag) => tag.get('name') === name)) {
			onCreate(new Tag({ name }));
		}
	};

	const handleSelect = (selectedTag) => {
		if (!tags.find((tag) => tag.get('name') === selectedTag.get('name'))) {
			onSelect(selectedTag);
			setSelectedTags([...selectedTags, selectedTag]);
		}
	};

	const handleTagRemove = (event) => {
		const { id, name } = event.currentTarget.dataset;

		onRemove(new Tag({ id: Number.parseInt(id, 10), name }));

		let t = [...selectedTags];
		t.splice(selectedTags.indexOf(name), 1);
		setSelectedTags(t);
	};

	const tagsComponent =
		tags.size > 0 ? (
			<AttachedTags align="left" location={location}>
				{tags.map((tag) => (
					<TagItem
						key={`tag-${tag.id || tag.get('id')}-${
							tag.name || tag.get('name')
						}`}
						data-id={tag.id || tag.get('id')}
						data-name={tag.name || tag.get('name')}
						id={tag.id || tag.get('id')}
						name={tag.name || tag.get('name')}
						type={type}
						removable
						onClick={handleTagRemove}
					/>
				))}
			</AttachedTags>
		) : null;

	return (
		<Fragment>
			{location === 'above' && tagsComponent}
			<AutocompleteInput
				name="tag"
				icon="tag"
				items={items}
				isFetching={isFetching}
				placeholder={t(`Type to find or create tags`)}
				renderCreateWith={renderCreateWith}
				resultItem={AutoCompleteItem}
				onChange={handleSearch}
				onActive={setDefaultTags}
				onSelect={handleSelect}
				onCreate={handleCreate}
				inline={inline}
			/>
			{location === 'below' && tagsComponent}
		</Fragment>
	);
};

const propsFromState = () => ({});

const componentActions = {
	searchTag,
};

export default connect(propsFromState, componentActions)(TagForm);
