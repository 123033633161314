import { ReactNode } from 'react';

export const symbol_styler: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M209-389h71v-21q0-13 8.5-21.5T310-440h340q13 0 21.5 8.5T680-410v21h71q11 0 20-7.5t9-18.5q0-9-5-16t-13-11L483-568 198-442q-8 4-13 11t-5 15q0 11 8.5 19t20.5 8Zm131 249h280v-240H340v240Zm229-633q-11 5-23 0t-17-16q-6-14-19-22.5t-29-8.5q-21 0-36.5 14.5T429-770q0 23 15.5 38.5T483-716q12 0 21 8.5t9 20.5v68l275 120q25 11 38.5 34.5T840-414q0 36-26.5 60.5T751-329h-71v219q0 13-8.5 21.5T650-80H310q-13 0-21.5-8.5T280-110v-219h-71q-36 0-62.5-24.5T120-414q0-27 13.5-50.5T172-499l281-120v-42q-37-10-60.5-40.5T369-770q0-46 33-78t79-32q35 0 62.5 19t40.5 49q5 11 .5 22.5T569-773ZM340-380h280-280Z"
		/>
	</svg>
);
