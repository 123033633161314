import { t } from '@transifex/native';
import { useRef } from 'react';

import * as flashActions from 'pkg/actions/flashes';

import * as models from 'pkg/api/models';
import { crash } from 'pkg/errors/errors';

import LocationForm from 'routes/scheduling/locations/LocationForm';

import * as StepModal from 'components/step-modal';

import Form, { FormPayload } from 'components/form/Form';

interface CreateLocationModalProps {
	groupId: number;

	onClose: () => void;
	onCreate: (location: models.resourceLocation.ResourceLocation) => void;
}

const CreateLocationModal = ({
	groupId,
	onClose,
	onCreate,
}: CreateLocationModalProps) => {
	const formRef = useRef(null);

	const handleSubmit = async (data: FormPayload) => {
		const payload = {
			groupId,
			title: data.title as string,
		};

		const [req, location] = await models.resourceLocation.create(payload);

		if (req.ok) {
			flashActions.show({
				title: t(`Location created.`),
			});
			onCreate(location);
			onClose();
		} else {
			const err = crash();
			flashActions.show({
				title: err.title,
				message: err.description,
			});
		}
	};

	const handleNext = async () =>
		await formRef.current.dispatchEvent(
			new Event('submit', {
				cancelable: true,
				bubbles: true,
			})
		);

	return (
		<StepModal.Base onClose={onClose}>
			<StepModal.Step
				title={t(`Create location`)}
				onNext={handleNext}
				nextLabel={t('Save')}>
				<Form formRef={formRef} onSubmit={handleSubmit}>
					<LocationForm />
				</Form>
			</StepModal.Step>
		</StepModal.Base>
	);
};

export default CreateLocationModal;
