import { useT } from '@transifex/react';

import * as models from 'pkg/api/models';
import * as actions from 'pkg/actions';

import { useEventContext } from 'routes/event/single';

import { useDialog } from 'design/dialog';
import Button from 'design/button';

interface ResendInvitesProps {
	event: models.event.Event;
	eventUsers: models.eventUser.EventUser[];
}

export default function ResendInvites({
	event,
	eventUsers,
}: ResendInvitesProps): JSX.Element {
	const t = useT();
	const eventCtx = useEventContext();

	const hasPendingUsers =
		eventUsers.filter(
			(eu) => eu.status === models.eventUser.EventUserStatus.Pending
		)?.length > 0;

	const confirmSendInvites = useDialog({
		title: t('Resend invites to users?'),
		message: t(`Notify all users that have not responded to this event?`),

		confirmLabel: t('Resend invites'),

		onConfirm: async () => {
			const pendingUsers = eventUsers
				.filter(
					(eventUser) =>
						eventUser.status === models.eventUser.EventUserStatus.Pending
				)
				.map((eventUser) => eventUser.userId);

			await actions.eventUsers.deleteEventUsers(event.id, pendingUsers);

			await actions.eventUsers.inviteEventUsers(event.id, {
				sendInvites: true,
				users: pendingUsers,
			});

			eventCtx.eventEndpointResponse.refresh();

			return true;
		},
	});

	if (
		hasPendingUsers &&
		models.canEdit(event) &&
		!models.event.hasStarted(event) &&
		!models.event.rsvpHasPassed(event)
	) {
		return (
			<Button
				icon="redo"
				iconPosition="left"
				label={t('Resend invites')}
				onClick={confirmSendInvites}
			/>
		);
	}

	return null;
}
