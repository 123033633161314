import { css } from 'styled-components';

import * as styles from 'pkg/config/styles';

import rgba from 'pkg/rgba';
import ColorConverter from 'pkg/colorconverter';
import { cssVarList } from 'pkg/css/utils';
import DateTime, { Granularity } from 'pkg/datetime';
import * as models from 'pkg/api/models';

export const hourHeight = 45;
export const dayHeight = 45 * 24;
export const minutesInDay = 1440;
export const minuteHeight = dayHeight / minutesInDay;

export const MonthEventLargeScreenHeight = 28;
export const MonthEventSmallDesktopHeight = 23;
export const MonthEventSmallScreenHeight = 17;

const hours24h = [
	'00:00',
	'01:00',
	'02:00',
	'03:00',
	'04:00',
	'05:00',
	'06:00',
	'07:00',
	'08:00',
	'09:00',
	'10:00',
	'11:00',
	'12:00',
	'13:00',
	'14:00',
	'15:00',
	'16:00',
	'17:00',
	'18:00',
	'19:00',
	'20:00',
	'21:00',
	'22:00',
	'23:00',
];

const hours12h = [
	'12:00',
	'1:00',
	'2:00',
	'3:00',
	'4:00',
	'5:00',
	'6:00',
	'7:00',
	'8:00',
	'9:00',
	'10:00',
	'11:00',
	'12:00',
	'1:00',
	'2:00',
	'3:00',
	'4:00',
	'5:00',
	'6:00',
	'7:00',
	'8:00',
	'9:00',
	'10:00',
	'11:00',
];

export const getHours = (hourFormat: number) =>
	hourFormat === 1 ? hours12h : hours24h;

export const EventColorStyles = css<{
	eventType: string;
	hasFinished: boolean;
	unHandled: boolean;
}>`
	overflow: hidden;

	${({ eventType, hasFinished, unHandled }) =>
		eventType && !hasFinished
			? css`
					background-color: ${rgba(
						styles.palette.events[eventType].backgroundColor
					)};
					color: ${rgba(styles.palette.events[eventType].foregroundColor)};

					&::before {
						background: ${unHandled
							? `repeating-linear-gradient(45deg,
			${rgba(styles.palette.events[eventType].foregroundColor)},
			${rgba(styles.palette.events[eventType].foregroundColor)} 2px,
			transparent 2px,
			transparent 4px
		)`
							: rgba(styles.palette.events[eventType].foregroundColor)};
					}
				`
			: css`
					background-color: ${ColorConverter.from(
						styles.palette.events[eventType].backgroundColor.join(',')
					)
						.brighten(5)
						.toStylesheetString()};
					color: ${ColorConverter.from(
						styles.palette.events[eventType].foregroundColor.join(',')
					)
						.brighten(30)
						.toStylesheetString()};

					&::before {
						background: ${unHandled
							? `repeating-linear-gradient(45deg,
								${ColorConverter.from(
									styles.palette.events[eventType].backgroundColor.join(',')
								)
									.darken(10)
									.toStylesheetString()},
									${ColorConverter.from(
										styles.palette.events[eventType].backgroundColor.join(',')
									)
										.darken(10)
										.toStylesheetString()} 2px,
			transparent 2px,
			transparent 4px
		)`
							: rgba(styles.palette.events[eventType].backgroundColor)};
					}
				`};

	&::before {
		content: '';
		display: block;
		position: relative;
		width: 3px;
		min-width: 3px;
		height: 100%;
	}

	@media (hover: hover) {
		&:hover {
			background-color: ${({ eventType }) =>
				eventType &&
				ColorConverter.from(
					styles.palette.events[eventType].backgroundColor.join(',')
				)
					.darken(10)
					.toStylesheetString()};
			color: ${({ eventType }) =>
				eventType &&
				ColorConverter.from(
					styles.palette.events[eventType].foregroundColor.join(',')
				)
					.darken(10)
					.toStylesheetString()};

			&::before {
				background: ${({ unHandled, eventType }) =>
					unHandled
						? `repeating-linear-gradient(45deg,
			${rgba(styles.palette.events[eventType].foregroundColor)},
			${rgba(styles.palette.events[eventType].foregroundColor)} 2px,
			transparent 2px,
			transparent 4px
		)`
						: rgba(styles.palette.events[eventType].foregroundColor)};
			}
		}
	}
`;

const calcEventPos = (startsAt: number, dayTimestamp: DateTime) => {
	if (startsAt * 1000 < dayTimestamp.startOfDay) {
		return 0;
	} else {
		return (
			Math.round((startsAt - dayTimestamp.startOfDay / 1000) / 60) *
			minuteHeight
		);
	}
};

const calcEventHeight = (startsAt: number, endsAt: number, day: DateTime) => {
	const startOfDay = day.startOfDay;
	const endOfDay = day.endOfDay;
	if (endsAt * 1000 > endOfDay) {
		endsAt = endOfDay / 1000;
	}

	if (startsAt * 1000 < startOfDay) {
		startsAt = startOfDay / 1000;
	}
	const minutesOfEvent = (endsAt - startsAt) / 60;
	const heightOfItem = (minutesOfEvent / 60) * hourHeight;

	return heightOfItem;
};

// Returns variables that dictate how the event item should be displayed
// in the calendar
export function getCalendarEventDisplayVars(
	event: models.event.Event,
	dateObjDay: DateTime,
	transformAmount: number,
	overlapCount: number
) {
	const startsAt = new DateTime(new Date(event.startsAt * 1000));
	const meetingTimeStartsAt = startsAt.prev(
		Granularity.minute,
		event.meetBeforeMinutes
	);
	const eventItemStartTime =
		event.meetBeforeMinutes > 0
			? meetingTimeStartsAt.getUnixTimestamp()
			: event.startsAt;

	return cssVarList({
		height: `${calcEventHeight(
			eventItemStartTime,
			event.endsAt,
			dateObjDay
		)}px`,
		top: `${calcEventPos(eventItemStartTime, dateObjDay)}px`,
		'overlap-count': overlapCount,
		'transform-percent': `${transformAmount}00%`,
		'transform-pixels': `${transformAmount}px`,
	});
}

// Returns a bunch of variables that can be used to style
// events in the calendar
export function getCalendarEventColorVars(eventType: string) {
	return cssVarList({
		bg: rgba(styles.palette.events[eventType].backgroundColor),
		'bg-darken-5': ColorConverter.from(
			styles.palette.events[eventType].backgroundColor.join(',')
		)
			.darken(5)
			.toStylesheetString(),
		'bg-darken-10': ColorConverter.from(
			styles.palette.events[eventType].backgroundColor.join(',')
		)
			.darken(10)
			.toStylesheetString(),
		'bg-darken-15': ColorConverter.from(
			styles.palette.events[eventType].backgroundColor.join(',')
		)
			.darken(15)
			.toStylesheetString(),
		'bg-brighten-2': ColorConverter.from(
			styles.palette.events[eventType].backgroundColor.join(',')
		)
			.brighten(2)
			.toStylesheetString(),
		'bg-brighten-5': ColorConverter.from(
			styles.palette.events[eventType].backgroundColor.join(',')
		)
			.brighten(5)
			.toStylesheetString(),
		fg: rgba(styles.palette.events[eventType].foregroundColor),
		'fg-darken-10': ColorConverter.from(
			styles.palette.events[eventType].foregroundColor.join(',')
		)
			.darken(10)
			.toStylesheetString(),
		'fg-brighten-30': ColorConverter.from(
			styles.palette.events[eventType].foregroundColor.join(',')
		)
			.brighten(30)
			.toStylesheetString(),
	});
}
