export function Create(groupID: number): string {
	return `/v1/organizations/${groupID}/user-field-categories`;
}

export function Delete(groupID: number, ID: number): string {
	return `/v1/organizations/${groupID}/user-field-categories/${ID}`;
}

export function Index(groupID: number): string {
	return `/v1/organizations/${groupID}/user-field-categories`;
}

export function Update(groupID: number, ID: number): string {
	return `/v1/organizations/${groupID}/user-field-categories/${ID}`;
}

export function UpdateSortOrder(groupID: number): string {
	return `/v1/organizations/${groupID}/user-field-categories/sort-order`;
}
