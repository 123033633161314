import { ReactNode } from 'react';

export const symbol_nest_heat_link_gen_3: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M320-120q-83 0-141.5-58.5T120-320v-320q0-83 58.5-141.5T320-840h320q83 0 141.5 58.5T840-640v320q0 83-58.5 141.5T640-120H320Zm0-60h320q58.333 0 99.167-40.833Q780-261.667 780-320v-320q0-58.333-40.833-99.167Q698.333-780 640-780H320q-58.333 0-99.167 40.833Q180-698.333 180-640v320q0 58.333 40.833 99.167Q261.667-180 320-180Zm160-100q75 0 127.5-52.5T660-460q0-75-52.5-127.5T480-640q-75 0-127.5 52.5T300-460q0 75 52.5 127.5T480-280Zm0-60q-50.4 0-85.2-34.8Q360-409.6 360-460q0-50.4 34.8-85.2Q429.6-580 480-580q50.4 0 85.2 34.8Q600-510.4 600-460q0 50.4-34.8 85.2Q530.4-340 480-340Zm.5-340q12.5 0 21-9t8.5-21.5q0-12.5-8.625-21T480-740q-12 0-21 8.625T450-710q0 12 9 21t21.5 9Zm-.5 220Z"
		/>
	</svg>
);
