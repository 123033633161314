import { ReactNode } from 'react';

export const symbol_bakery_dining: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M805-261q27 14 45.5-9t4.5-48l-71-134-54 147 75 44Zm-212-42h70l97-242q6-14 4.5-27.5T748-593l-83-36q-14-7-28 0t-15 21l-29 305Zm-296 0h69l-28-305q-1-15-13.5-22t-29.5 1l-79 34q-13 5-14.5 22.5T205-541l92 238Zm-142 42 75-44-54-147-71 139q-14 28 6 45.5t44 6.5Zm271-42h107l33-364q2-14-7.5-23.5T535-700H425q-16 0-24 9.5t-7 23.5l32 364ZM133-200q-39 0-66-28t-27-67q0-12 4.5-23.5T54-341l92-179q-15-39-3-77.5t48-54.5l83-34q16-6 32.5-7t30.5 8q8-32 32-53.5t56-21.5h110q32 0 56 20t32 51q15-6 31-5t31 8l84 34q37 14 49.5 52t-4.5 74l92 184q4 11 9 21.5t5 21.5q0 42-30 70.5T818-200q-11 0-20.5-4.5T778-213l-61-30H242l-58 30q-12 5-24.5 9t-26.5 4Zm347-280Z"
		/>
	</svg>
);
