import { ReactNode } from 'react';
import styled, { css } from 'styled-components';

interface DescriptionProps {
	centered?: boolean;
	maxWidth?: string;
}

const Description = styled.p<DescriptionProps>`
	color: var(--palette-gray-600);
	font-weight: var(--font-weight-normal);
	font-size: var(--font-size-base);
	line-height: var(--font-line-height-base);

	${({ centered }) =>
		centered &&
		css`
			text-align: center;
		`}

	${({ maxWidth }) =>
		maxWidth &&
		css`
			max-width: ${maxWidth};
			margin: 0 auto;
		`}
`;

interface SectionDescriptionComponentProps extends DescriptionProps {
	children: ReactNode;
	className?: string;
}

function SectionDescriptionComponent({
	children,
	centered,
	maxWidth,
	className,
}: SectionDescriptionComponentProps): JSX.Element {
	return (
		<Description centered={centered} maxWidth={maxWidth} className={className}>
			{children}
		</Description>
	);
}

const SectionDescription = styled(
	SectionDescriptionComponent
)<SectionDescriptionComponentProps>``;

export default SectionDescription;
