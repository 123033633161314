import { normalize } from 'normalizr';

import * as actionTypes from 'pkg/actions/action-types';
import * as ratingItemsService from 'pkg/actions/services/rating_items';
import { ratingItems } from 'pkg/actions/entities/rating_items';

export const setRatingItems = (payload) => ({
	type: actionTypes.RatingItems.SET_ITEMS,
	payload,
});

export const fetchRatingItems = (forAccount) => async (dispatch) => {
	const response = await ratingItemsService.fetchRatingItem(forAccount);

	const result = await response.json();
	const payload = normalize(result.records, [ratingItems]);

	if (!response.ok) {
		return;
	}

	dispatch(setRatingItems(payload));
};
