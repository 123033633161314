import { ReactNode } from 'react';

export const symbol_align_justify_flex_start: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M109.825-80Q97-80 88.5-88.625T80-110v-740q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-850v740q0 12.75-8.675 21.375Q122.649-80 109.825-80ZM560-290q-12.75 0-21.375-8.625T530-320v-320q0-12.75 8.625-21.375T560-670h40q12.75 0 21.375 8.625T630-640v320q0 12.75-8.625 21.375T600-290h-40Zm-240 0q-12.75 0-21.375-8.625T290-320v-320q0-12.75 8.625-21.375T320-670h40q12.75 0 21.375 8.625T390-640v320q0 12.75-8.625 21.375T360-290h-40Z"
		/>
	</svg>
);
