import { ReactNode } from 'react';

export const symbol_animation: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M354-80q-56 0-106-20.5T159-160q-38-38-58.5-87.5T80-354q0-80 41.5-146T232-598q20-42 53-75t77-55q32-71 97.5-111.5T607-880q56 0 105.5 20.5T800-800q38 39 59 88t21 105q0 84-38.5 146.5T728-362q-20 44-52.5 76.5T598-232q-32 71-97.5 111.5T354-80Zm0-60q45 0 86.5-19.5T508-211q-63 7-120.5-12.5T286-286q-44-43-63-101t-12-121q-33 26-52 67.5T140-354q0 45 16 84t46 68q30 29 68.5 45.5T354-140Zm126-127q45 0 86-19t69-52q-63 7-120-13.5T415-416q-44-45-64-102.5T339-639q-40 38-56 73.5T267-480q0 44 16 83t46 69q29 29 68 45t83 16Zm131-129q17 0 34.5-3.5T683-411q21-59 7-116.5T631-630q-42-42-105.5-57T408-683q-5 17-7.5 35t-2.5 41q0 42 16 81t46 70q30 32 69 46t82 14Zm143-54q34-29 50-67t16-90q0-43-16-81.5T758-757q-31-31-69.5-47T607-820q-49 0-89 16.5T446-752q63-7 122 13.5T673-673q46 45 67 102.5T754-450Z"
		/>
	</svg>
);
