import { ReactNode } from 'react';

export const symbol_hdr_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M797-79 91-784q-9-9-9-21.5t9-21.5q9-9 21.5-9t21.5 9l706 706q9 9 9 21t-9 21q-9 9-21.5 9T797-79ZM684-441v79l-48-48v-168q0-9 7-15.5t16-6.5h132q23 0 36 13t13 36v61q0 17-11 29t-28 14l23 56q5 11-2 21t-19 10q-7 0-13.5-4t-8.5-11l-25-66h-72Zm0-48h108v-63H684v63Zm-102 25L446-600h87q23 0 36 13t13 36v87Zm-414 1v79q0 10-7 17t-17 7q-10 0-17-7t-7-17v-192q0-10 7-17t17-7q10 0 17 7t7 17v65h98v-65q0-10 7-17t17-7q10 0 17 7t7 17v192q0 10-7 17t-17 7q-10 0-17-7t-7-17v-79h-98Zm210-65 48 48v72h72l47 47q-3 1-6 1H401q-9 0-16-7t-7-16v-145Z"
		/>
	</svg>
);
