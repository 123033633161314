import { ReactNode } from 'react';

export const symbol_nephrology: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M320-280q-99 0-169.5-66.5T80-510v-100q0-97 70.5-163.5T320-840q45.833 0 77.917 32.118 32.083 32.117 32.083 78Q430-684 397.917-652 365.833-620 320-620h-40q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T280-680h40q21.25 0 35.625-14.325Q370-708.649 370-729.825 370-751 355.625-765.5 341.25-780 320-780q-74.25 0-127.125 49T140-610v100q0 72 52.875 121T320-340q21.25 0 35.625-14.325Q370-368.649 370-389.825 370-411 355.625-425.5 341.25-440 320-440h-40q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T280-500h40q45.833 0 77.917 32.083Q430-435.833 430-390v240q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T370-150v-142q-11 6-24 9t-26 3Zm320 0q-13 0-26-3t-24-9v142q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T530-150v-240q0-45.833 32.083-77.917Q594.167-500 640-500h40q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T680-440h-40q-21.25 0-35.625 14.325Q590-411.351 590-390.175 590-369 604.375-354.5 618.75-340 640-340q74.25 0 127.125-49T820-510v-100q0-72-52.875-121T640-780q-21.25 0-35.625 14.325Q590-751.351 590-730.175 590-709 604.375-694.5 618.75-680 640-680h40q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T680-620h-40q-45.833 0-77.917-32.118-32.083-32.117-32.083-78Q530-776 562.083-808q32.084-32 77.917-32 99 0 169.5 66.5T880-610v100q0 97-70.5 163.5T640-280ZM140-510v-100 100Zm680-100v100-100Z"
		/>
	</svg>
);
