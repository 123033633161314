import { useState, ReactNode, TouchEvent } from 'react';

import * as models from 'pkg/api/models';
import useLongTouchPress from 'pkg/hooks/useLongTouchPress';
import { cssClasses } from 'pkg/css/utils';

import ChatMessageActions from 'containers/chat/chat_message_actions';

import * as chatMessageStyles from 'components/chat/message/styles.css';

export interface MessageContentProps {
	isFirstGroupItem: boolean;
	isLastGroupItem: boolean;
	align: string;
	isAttachment: boolean;
	attachment: models.attachment.Attachment;
	emojiOnly: boolean;
	isMonitoredByLegalGuardian: boolean;
	item: models.chatMessage.ChatMessage;
	onClick: () => void;
	children?: ReactNode;
	testid?: string;
	'data-has-embeds'?: boolean;
	'data-num-embeds'?: number;
	'data-num-blocks'?: number;

	chat: models.chat.Chat;
}

export default function MessageContent({
	isFirstGroupItem = false,
	isLastGroupItem = false,
	align = 'left',
	isAttachment = false,
	attachment = null,
	emojiOnly = false,
	isMonitoredByLegalGuardian = false,
	item,
	onClick,
	children,
	testid,
	chat,
	'data-has-embeds': hasEmbeds,
	'data-num-embeds': numEmbeds,
	'data-num-blocks': numBlocks,
}: MessageContentProps): JSX.Element {
	const [showContextMenu, setShowContextMenu] = useState(false);

	const handleCloseReplyOptions = () => setShowContextMenu(false);

	const handleOpenReplyOptions = (event: TouchEvent<HTMLElement>) => {
		event.nativeEvent.stopPropagation();
		event.nativeEvent.preventDefault();

		setShowContextMenu(true);
	};

	const longPressEvents = useLongTouchPress(
		{ onLongPress: handleOpenReplyOptions },
		{ blurActiveElement: true }
	);

	return (
		<div
			className={cssClasses(
				chatMessageStyles.wrapper,
				isFirstGroupItem ? chatMessageStyles.isFirstGroupItem : '',
				isLastGroupItem ? chatMessageStyles.isLastGroupItem : '',
				align === 'right' ? chatMessageStyles.rightAligned : '',
				isAttachment ? chatMessageStyles.isAttachment : '',
				models.attachment.hasThumbnail(attachment)
					? chatMessageStyles.hasThumbnail
					: '',
				item.archivedAt ? chatMessageStyles.isArchived : '',
				emojiOnly ? chatMessageStyles.emojiOnly : ''
			)}
			{...longPressEvents}
			onClick={onClick}
			data-has-embeds={hasEmbeds}
			data-num-embeds={numEmbeds}
			data-num-blocks={numBlocks}
			data-testid={testid}>
			{children}
			{!isMonitoredByLegalGuardian ? (
				<ChatMessageActions
					chatMessage={item}
					align={align}
					showContextMenu={showContextMenu}
					handleCloseContextMenu={handleCloseReplyOptions}
					chat={chat}
				/>
			) : null}
		</div>
	);
}
