import { ReactNode } from 'react';

export const symbol_check_in_out: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M258-200q-49 0-82.5-34T141-319q-1-51 33-85t84-34q51 0 84 34t33 85q0 51-33 85t-84 34Zm-85-321q-8 0-14-6t-6-14v-199q0-8 6-14t14-6q8 0 14 6t6 14v199q0 8-6 14t-14 6Zm84 279q32 0 53.5-21.5T332-319q0-35-21.5-56.5T257-397q-32 0-52.5 22T184-319q0 33 20.5 55t52.5 22Zm18-279q-8 0-14-6t-6-14v-197q0-9 6.5-15.5T277-760h15q6 0 11 3t8 8l90 166-2-37v-120q0-8 6-14t14-6q8 0 14 6t6 14v199q0 8-6 14t-14 6h-14q-5 0-10-3t-8-8l-95-174 3 37v128q0 8-6 14t-14 6Zm234 321q-42 0-67-27t-25-71v-120q0-8 6-14t14-6q8 0 14 6t6 14v124q0 23 14.5 38t37.5 15q23 0 37.5-15t14.5-38v-124q0-8 6-14t14-6q8 0 14 6t6 14v120q0 44-25.5 71T509-200Zm221 0q-8 0-14.5-6t-6.5-14v-177h-47q-8 0-14-6.5t-6-14.5q0-8 6-14t14-6h136q8 0 14 6t6 14q0 8-6 14.5t-14 6.5h-48v177q0 8-6 14t-14 6Z"
		/>
	</svg>
);
