import { t } from '@transifex/native';
import styled from 'styled-components';
import { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as styles from 'pkg/config/styles';

import * as actions from 'pkg/actions';
import { useEndpoint } from 'pkg/api/use_endpoint';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import useInterval from 'pkg/hooks/useInterval';
import * as objects from 'pkg/objects';
import * as api from 'pkg/api';
import { MembershipRole } from 'pkg/api/models/membership';
import { useCurrentAccount } from 'pkg/identity';

import Button from 'routes/public/styles/Button';
import Title from 'routes/public/styles/Title';
import { useOnboardingState } from 'routes/public/hooks';
import { useOnboardingContext } from 'routes/public/onboarding';
import LogoutButton from 'routes/public/styles/LogoutButton';
import * as publicStyles from 'routes/public/styles/styles.css';

import Column from 'components/layout/column';
import { Spinner } from 'components/loaders/spinner';

const Card = styled.div`
	display: grid;
	grid-template-rows: auto auto;
	gap: var(--spacing-4);
	background: var(--palette-white);
	padding: var(--spacing-4);
	border-radius: var(--radius-5);
`;

const AccountWrapper = styled.div`
	display: grid;
	grid-template-areas: 'avatar name' 'avatar text';
	grid-template-columns: auto 1fr auto;
	grid-template-rows: auto auto;
	gap: var(--spacing-1) var(--spacing-4);

	strong {
		grid-area: name;
		align-self: flex-end;
		font-size: var(--font-size-base);
		font-weight: var(--font-weight-semibold);
		color: var(--palette-gray-900);
		word-break: break-all;
		word-wrap: break-word;
	}

	span {
		grid-area: text;
		align-self: flex-start;
		font-size: var(--font-size-sm);
		color: var(--palette-gray-500);
		word-break: break-all;
		word-wrap: break-word;
	}
`;

const Pending = styled.div`
	grid-area: avatar;
	align-self: center;
	display: grid;
	align-items: center;
	justify-items: center;
	width: 44px;
	height: 44px;
	border-radius: var(--radius-5);
	background: var(--palette-blue-200);
	color: var(--palette-blue-500);
	position: relative;

	svg {
		color: var(--palette-blue-500);
	}
`;

export default function GdprRequestPending(): JSX.Element {
	const dispatch = useDispatch();

	const pollingInterval = 10000;

	const { goTo, resetStore } = useOnboardingContext();
	const onboardingState = useOnboardingState();

	const groupCode = onboardingState.get<string>('groupCode');

	const currentAccount = useCurrentAccount();

	let parentalConsentEmail = onboardingState.get<string>(
		'parentalConsentEmail'
	);

	if (!parentalConsentEmail) {
		parentalConsentEmail = currentAccount?.parentalConsentEmail;
	}

	const handleLogout = async () => {
		resetStore();

		dispatch(actions.auth.logout());

		window.history.pushState({}, '', '/');
		window.location.reload();
	};

	const {
		record: relation,
		response,
		refresh,
		isLoading,
		hasError,
	} = useEndpoint<models.accountRelation.AccountRelation>(
		endpoints.AccountRelations.Index()
	);

	useInterval(() => {
		if (!isLoading) {
			refresh();
		}
	}, pollingInterval);

	useEffect(() => {
		if (hasError && response.status === 401) {
			handleLogout();
		}
	}, [hasError]);

	useEffect(() => {
		const joinGroup = async () => {
			const [joinRequest] = await api.post(
				endpoints.Groups.JoinFromInviteCode(),
				{
					code: groupCode,
					role: MembershipRole.User,
				}
			);

			if (joinRequest.ok) {
				goTo('player-finished');
				return;
			}
		};

		if (!objects.empty(relation)) {
			joinGroup();
		}
	}, [response]);

	return (
		<Fragment>
			<Column spacing={styles.spacing._8} className={publicStyles.main}>
				<Title
					title={t('Waiting for GDPR consent...')}
					description={t(
						'Once your parent has approved the request, you will be able to continue.'
					)}
				/>

				<Card>
					<AccountWrapper>
						<Pending>
							<Spinner size="32px" />
						</Pending>
						<strong data-testid="onboarding.account_card.name">
							{t('GDPR request pending')}
						</strong>
						<span data-testid="onboarding.account_card.email">
							{parentalConsentEmail}
						</span>
					</AccountWrapper>
					<Button
						secondary
						small
						label={t('Remove')}
						onClick={() => goTo('gdpr-request')}
					/>
				</Card>
			</Column>

			<div className={publicStyles.footer}>
				<LogoutButton />
			</div>
		</Fragment>
	);
}
