import { memo } from 'react';

import * as models from 'pkg/api/models';
import { MatchEventType } from 'pkg/api/models/match_event';

import { AvatarWrapper } from 'components/football-field';
import Avatar from 'components/avatar';

import {
	PlayerItem,
	PlayerNameWrapper,
	PlayerPositionName,
} from 'components/match/StartingPlayersBySection';

interface PlayerProps {
	position: models.position.Position;
	user: models.user.User;
}

const Player = memo(({ position, user }: PlayerProps) => {
	return (
		<PlayerItem>
			<PlayerPositionName>{position.name}</PlayerPositionName>
			<AvatarWrapper>
				<Avatar user={user} />
			</AvatarWrapper>
			<PlayerNameWrapper>{models.user.fullName(user)}</PlayerNameWrapper>
		</PlayerItem>
	);
});

interface StartingPlayersBySectionProps {
	matchEvents: models.matchEvent.MatchEvent[];
	position: models.position.Position;
}

export default function StartingPlayersBySection({
	matchEvents,
	position,
}: StartingPlayersBySectionProps) {
	const positionMatchEvents = matchEvents.filter(
		(event) =>
			event.type === MatchEventType.MatchEventTypeStartingUser &&
			event.positionId === position.id
	);
	const startingUsers = positionMatchEvents.map((event) => event.user);

	return startingUsers.map((player) => (
		<Player key={player.id} position={position} user={player} />
	));
}
