import { useT } from '@transifex/react';
import { Fragment, ReactNode } from 'react';

import * as routes from 'pkg/router/routes';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCollection } from 'pkg/api/use_collection';
import DateTime from 'pkg/datetime';
import { link } from 'pkg/router/utils';
import { useCurrentOrganization } from 'pkg/identity';

import { useRemoveSubmission } from 'routes/organization/user-profile/overview/actions';

import Section from 'components/section';
import MaterialSymbol from 'components/material-symbols';

import * as ContextMenu from 'design/context_menu';
import * as Table from 'design/table';
import * as Card from 'design/card';

import * as css from './styles.css';

interface RegistrationsProps {
	user: models.user.User;
}

export default function Registrations({
	user,
}: RegistrationsProps): JSX.Element {
	const t = useT();

	const allowedToListFormSubmissions: boolean = models.hasAllowedAction(
		user,
		models.Action.UserListFormSubmissions
	);

	const {
		records: registrations,
		isLoading,
		refresh,
	} = useCollection<models.formSubmission.FormSubmission>(
		allowedToListFormSubmissions ? endpoints.FormSubmissions.Index() : null,
		{
			count: 5,
			showAllResults: true,
			queryParams: new URLSearchParams({
				relations: 'Form',
				submitted_for_user_ids: [user.id].toString(),
			}),
		}
	);

	if (!allowedToListFormSubmissions) {
		return null;
	}

	const columns: Table.HeaderColumn[] = [
		{ content: t('Title') },
		{ content: t('Submitted at'), width: 'max-content', align: 'right' },
		{ content: '', width: 'max-content' },
	];

	const emptyState = {
		title: t('No submissions found'),
		image: (
			<MaterialSymbol
				actualSize
				scale={3}
				variant="assignment"
				className={css.emptyStateIcon}
			/>
		),
		className: css.emptyState,
	};

	return (
		<Section title={t('Registrations')} icon="assignment">
			<Card.Base $noBorder>
				<Card.Body $noSpacing>
					<Table.Table
						columns={columns}
						isLoading={isLoading}
						emptyState={emptyState}>
						{registrations.map((registration, index) => (
							<Registration
								key={index}
								user={user}
								refresh={refresh}
								registration={registration}
							/>
						))}
					</Table.Table>
				</Card.Body>
			</Card.Base>
		</Section>
	);
}

interface RegistrationProps {
	user: models.user.User;
	registration: models.formSubmission.FormSubmission;

	refresh: () => Promise<void>;
}

function Registration({
	user,
	registration,
	refresh,
}: RegistrationProps): JSX.Element {
	const t = useT();
	const org = useCurrentOrganization();

	const removeRegistration = useRemoveSubmission(registration, refresh);

	const registrationUrl = link(
		routes.Form.Submissions.Single(
			org.id,
			registration.form.groupId,
			registration.formId,
			registration.id
		),
		{
			returnUrl: routes.Organization.User.Profile.Show(org.id, user.id),
		}
	);

	const submittedAt = DateTime.fromTimestamp(
		registration.createdAt
	).toLocaleDateString();

	const actions: ReactNode[] = [
		<ContextMenu.LinkItem
			key="registration-url"
			href={registrationUrl}
			className={css.contextItemWithExtraIcon}>
			<ContextMenu.ItemIcon name="assignment" />
			<span>{t('View form submission')}</span>
			<ContextMenu.ItemIcon name="arrow_forward" />
		</ContextMenu.LinkItem>,
	];

	if (models.canDelete(registration)) {
		actions.push(
			<ContextMenu.Divider />,
			<ContextMenu.Item
				caution
				key="delete-submission"
				onClick={removeRegistration}>
				<ContextMenu.ItemIcon name="contract_delete" />
				<span>{t('Delete submission')}</span>
			</ContextMenu.Item>
		);
	}

	return (
		<Fragment>
			<Table.Row>
				<Table.LinkCell href={registrationUrl}>
					{registration.form.title}
				</Table.LinkCell>
				<Table.Cell align="right">{submittedAt}</Table.Cell>
				<Table.ActionCell>
					<ContextMenu.Menu
						toggleWith={
							<ContextMenu.TableTrigger>
								<MaterialSymbol actualSize variant="more_horiz" scale={1.3} />
							</ContextMenu.TableTrigger>
						}>
						{actions}
					</ContextMenu.Menu>
				</Table.ActionCell>
			</Table.Row>
		</Fragment>
	);
}
