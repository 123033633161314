import { List, Map } from 'immutable';

import Session from 'pkg/models/session';
import Tag from 'pkg/models/tag';

import { RootState } from 'pkg/reducers';

const emptySession = new Session();

export const findAll = (state: RootState): Map<number, Session> =>
	state.sessions.get('entities');

const getSessionModes = (state: RootState) => state.sessions.get('modes');

export const getSessionId = ({ sessionId }: { sessionId: number }): number =>
	sessionId;

export const find = (
	state: RootState,
	{ sessionId }: { sessionId: number }
): Session => {
	const entities = findAll(state);
	return entities.get(sessionId) || emptySession;
};

export const findSessionMode = (
	state: RootState,
	sessionId: { sessionId: number }
): Map<string, boolean> => {
	const modes = getSessionModes(state);
	const id = getSessionId(sessionId);

	return modes.get(id);
};

export const findAllTags = (
	state: RootState,
	sessionId: { sessionId: number }
): List<Tag> => {
	const session = find(state, sessionId);
	const tags = state.tags.entities;

	return List(session?.get('tags')).map((tagId) => tags.get(tagId));
};
