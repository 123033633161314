import { ReactNode } from 'react';

export const symbol_text_rotate_vertical: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m555-442-38 105q-3 8-9.5 12.5T493-320q-14 0-21.5-11t-2.5-24l145-387q3-8 10.5-13t15.5-5h20q8 0 15.5 5t10.5 13l145 388q5 13-2.5 23.5T807-320q-8 0-14.5-4.5T783-337l-38-105H555Zm15-44h160l-78-222h-4l-78 222ZM196-277v-533q0-13 8.5-21.5T226-840q13 0 21.5 8.5T256-810v533l33-31q9-8 21-8t21 9q9 9 9 21t-9 21l-84 84q-9 9-21 9t-21-9l-84-84q-9-9-8.5-21.5T122-308q8-8 20-8t21 8l33 31Z"
		/>
	</svg>
);
