import { t } from '@transifex/native';

import {
	useLocalizedCurrencyFormatter,
	useOrderReport,
} from 'routes/payments/overview/hooks';
import { Widget } from 'routes/payments/overview/widgets';

import * as DataCard from 'design/data_card';

export default function EstimatedPaymentsWidget(): JSX.Element {
	const { estimatedPaymentsSum } = useOrderReport();
	const fmt = useLocalizedCurrencyFormatter();

	const { total, invoices, subscriptions } = estimatedPaymentsSum;

	return (
		<Widget>
			<DataCard.Base
				titleIcon="send_money"
				title={t('Est. payments coming 30 days')}
				heading={fmt(total)}>
				<DataCard.Breakdown
					items={[
						{
							title: t('Invoices'),
							value: fmt(invoices),
						},
						{
							title: t('Subscriptions'),
							value: fmt(subscriptions),
						},
					]}
				/>
			</DataCard.Base>
		</Widget>
	);
}
