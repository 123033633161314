import { ReactNode } from 'react';

export const symbol_zone_person_alert: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-160q-24 0-42-18t-18-42v-102q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-322v102h102q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T242-160H140Zm217-155 46-235-100 47v104q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T243-399v-125q0-9.333 4.875-16.722Q252.75-548.111 261-552l146-61q32-14 45.5-17.5t27.1-3.5q20.4 0 35.9 8.5T542-600l42 67 4.667 6.667Q591-523 593-520q-51 33-82 86.5T478-315H357Zm183.08-359q-30.08 0-51.58-21.42-21.5-21.421-21.5-51.5 0-30.08 21.42-51.58 21.421-21.5 51.5-21.5 30.08 0 51.58 21.42 21.5 21.421 21.5 51.5 0 30.08-21.42 51.58-21.421 21.5-51.5 21.5ZM80-798v-102q0-24 18-42t42-18h102q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T242-900H140v102q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Q97-768 88.5-776.625T80-798Zm740 0v-102H718q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T718-960h102q24 0 42 18t18 42v102q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T820-798Zm-90.5 680Q650-118 594-173.867 538-229.735 538-309q0-80 55.867-135.5Q649.735-500 729-500q80 0 135.5 55.5t55.5 135q0 79.5-55.5 135.5t-135 56Zm2.5-161q8 0 12.5-5t4.5-13v-108q0-8-5-12.5t-13-4.5q-8 0-12.5 5t-4.5 13v108q0 8 5 12.5t13 4.5Zm-.5 85q9.5 0 16-6t6.5-16q0-10-6.6-16t-15.4-6q-10 0-16 6t-6 16q0 10 6 16t15.5 6Z"
		/>
	</svg>
);
