import styled from 'styled-components';
import { t } from '@transifex/native';
import { useState } from 'react';

import * as palette from 'pkg/config/palette';

import Icon from 'components/icon';

import TextField from 'components/form/TextInput';

import Button from 'design/button';

const getNamePlaceHolder = (type) =>
	type === 'squad' ? t('New squad') : t('New formation');

const Outer = styled.div`
	background: ${palette.white};
	padding: 1rem 1.5rem;
	display: grid;
	justify-content: space-between;
	align-items: center;
	grid-area: toolbar;
	width: 100%;
	grid-gap: 1rem;
	grid-auto-flow: column;
	border-bottom: 1px solid ${palette.gray[200]};

	@media all and (max-width: 640px),
		all and (min-width: 641px) and (max-width: 768px) {
		margin-top: 0;
	}

	@media all and (max-width: 640px) {
		grid-auto-flow: row;
		align-items: stretch;
		justify-content: start;
		padding: 0.5rem;
	}

	.form {
		display: grid;
		grid-template-columns: 1fr auto auto;
		align-items: center;
		grid-gap: 10px;
		flex-flow: row;
		flex: 1 1 auto;
		width: auto;
		max-width: 500px;
	}

	.delete-formation {
		font-size: 1.8rem;
		color: ${palette.red[400]};
		padding: 5px;
	}

	@media all and (max-width: 640px) {
		.form {
			max-width: 100%;
			width: 100%;
		}
	}

	.form .formation-name {
		background: transparent;
		border: none;
		outline: none;
		font-size: var(--font-size-base);
		border-bottom: none;
		padding: 8px 12px;
		border-radius: var(--radius-2);

		background: ${palette.gray[200]};
	}
`;

const Options = styled.div`
	left: 0;
	right: 0;
	width: auto;
	z-index: 2;
	background: #fff;

	.formation-option-toggles {
		display: grid;
		grid-gap: 1rem;
		grid-template-columns: auto auto;
	}

	.toggle-switch {
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		background: #fff;

		&:first-child {
			justify-self: start;
		}

		&:last-child {
			justify-self: end;
		}
	}

	@media all and (min-width: 641px) and (max-width: 768px) and (orientation: landscape),
		all and (max-width: 640px) and (orientation: landscape) {
		.toggle-switch {
			flex-direction: column;
			align-items: flex-start;
		}
	}

	.toggle-switch__label {
		margin-bottom: 0;
		margin-right: 10px;
		font-weight: var(--font-weight-normal);
		font-size: var(--font-size-sm);
		text-align: right;
	}

	@media all and (min-width: 641px) and (max-width: 768px) and (orientation: landscape),
		all and (max-width: 640px) and (orientation: landscape) {
		.toggle-switch__label {
			font-size: 7px;
			white-space: nowrap;
		}
	}
	.toggle-switch__toggle {
		height: 20px;
		width: 40px;
	}

	@media all and (min-width: 641px) and (max-width: 768px) and (orientation: landscape),
		all and (max-width: 640px) and (orientation: landscape) {
		.toggle-switch__toggle {
			height: 15px;
			width: 40px;
		}
	}

	.toggle-switch .toggle-switch__toggle::before,
	.toggle-switch .toggle-switch__toggle::after,
	.toggle-switch input:checked ~ .toggle-switch__toggle::after {
		content: '';
	}

	.toggle-switch__toggle::before {
		height: 20px;
		width: 20px;
	}

	@media all and (min-width: 641px) and (max-width: 768px) and (orientation: landscape),
		all and (max-width: 640px) and (orientation: landscape) {
		.toggle-switch__toggle::before {
			width: 15px;
			height: 15px;
		}
	}

	.toggle-switch__toggle::after {
		padding: 0 15px 0 30px;
	}

	.toggle-switch input:checked ~ .toggle-switch__toggle::before {
		margin-left: -20px;
	}

	@media all and (min-width: 641px) and (max-width: 768px) and (orientation: landscape),
		all and (max-width: 640px) and (orientation: landscape) {
		.toggle-switch input:checked ~ .toggle-switch__toggle::before {
			margin-left: -15px;
		}
	}
`;

const Toolbar = ({ formation, saving, handleDelete }) => {
	const [formationName, setFormationName] = useState(formation?.name);

	const handleChange = (event) => {
		setFormationName(event.target.value);
	};

	const deleteLink = (
		<Icon name="delete" onClick={handleDelete} className="delete-formation" />
	);

	return (
		<Outer>
			<div className="form">
				<TextField
					type="text"
					className="formation-name"
					placeholder={
						formation ? getNamePlaceHolder(formation.formationType) : ''
					}
					value={formationName}
					onChange={handleChange}
					data-testid="formations.name_input"
				/>
				<div
					className="help-block formation-name-error"
					style={{ display: 'none' }}>
					{t(`Formation name cannot be empty.`)}
				</div>
				<input
					type="hidden"
					value={formation?.groupId}
					className="formation-group"
				/>
				<input
					type="hidden"
					value={formation?.formationType}
					className="formation-type"
				/>

				<Button
					className="save-formation"
					disabled={saving}
					testid="formations.save_button">
					{saving ? '...' : t('Save')}
				</Button>

				{formation?.id === 'new' ? null : deleteLink}
			</div>

			{formation?.formationType === 'squad' && (
				<Options>
					<div className="formation-option-toggles">
						<label className="toggle-switch">
							<input
								type="checkbox"
								defaultChecked={formation?.showScores}
								className="toggle-position-scores"
							/>
							<div className="toggle-switch__label">
								{t(`Show position scores`)}
							</div>
							<div
								className="toggle-switch__toggle"
								data-on-label={t('On')}
								data-off-label={t('Off')}
							/>
						</label>

						<label className="toggle-switch">
							<input
								type="checkbox"
								defaultChecked={formation?.maturityAdjusted}
								className="toggle-physical-adjustment"
							/>
							<div className="toggle-switch__label">
								{t(`Physical development`)}
							</div>
							<div
								className="toggle-switch__toggle"
								data-on-label={t('On')}
								data-off-label={t('Off')}
							/>
						</label>
					</div>
				</Options>
			)}
		</Outer>
	);
};

export default Toolbar;
