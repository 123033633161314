import styled from 'styled-components';
import { t } from '@transifex/native';

import * as palette from 'pkg/config/palette';

import { FormattedContent } from 'components/formatted-content';

const Wrapper = styled.div`
	display: grid;
	grid-auto-flow: row;
	grid-gap: 5px;
`;

const Duration = styled.h3`
	color: ${palette.gray[500]};
`;

const Title = styled.h2``;

const ExerciseWrapper = styled(Wrapper)`
	margin-top: 1rem;
	grid-gap: 30px;
`;

export default ({ item, children }) => (
	<Wrapper>
		<Duration>
			{t('{num} min', {
				num: item.duration,
				_context: 'training_library/session_builder',
			})}
		</Duration>
		<Title>{item.title}</Title>
		{item.description && <FormattedContent raw={item.description} />}
		<ExerciseWrapper>{children}</ExerciseWrapper>
	</Wrapper>
);
