import { ReactNode } from 'react';

export const symbol_point_scan: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M479.911-410Q451-410 430.5-430.589q-20.5-20.588-20.5-49.5Q410-509 430.589-529.5q20.588-20.5 49.5-20.5Q509-550 529.5-529.411q20.5 20.588 20.5 49.5Q550-451 529.411-430.5q-20.588 20.5-49.5 20.5ZM450-670v-140q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-810v140q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T450-670Zm0 520v-140q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T510-290v140q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T450-150Zm220-360h140q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-450H670q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T670-510Zm-520 0h140q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T290-450H150q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-510Z"
		/>
	</svg>
);
