import { ReactNode } from 'react';

export const symbol_thunderstorm: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m519-50 53-60-45-23q-14-7-17-21t7-25l79-91q5-5 10.5-7.5T619-280q20 0 27.5 18t-5.5 32l-53 60 45 23q14 7 17 21t-7 25l-79 91q-5 5-10.5 7.5T541 0q-20 0-27.5-18t5.5-32Zm-240 0 53-60-45-23q-14-7-17-21t7-25l79-91q5-5 10.5-7.5T379-280q20 0 27.5 18t-5.5 32l-53 60 45 23q14 7 17 21t-7 25l-79 91q-5 5-10.5 7.5T301 0q-20 0-27.5-18t5.5-32Zm11-290q-87 0-148.5-61.5T80-550q0-79 56.5-141T277-759q32-56 84.5-88.5T480-880q91 0 152.5 57.5T708-680q79 4 125.5 53.5T880-510q0 70-49.5 120T710-340H290Zm0-60h420q46 0 78-32.5t32-77.5q0-46-32-78t-78-32h-60v-30q0-71-49.5-120.5T480-820q-51 0-93.5 27.5T324-718l-8 18h-28q-62 2-105 45.5T140-550q0 62 44 106t106 44Zm190-210Z"
		/>
	</svg>
);
