import { ReactNode } from 'react';

export const symbol_keyboard_alt: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M100-120q-24 0-42-18t-18-42v-560q0-24 18-42t42-18h760q24 0 42 18t18 42v560q0 24-18 42t-42 18H100Zm0-60h760v-560H100v560Zm232-82h296q14 0 23-9t9-23q0-14-9-23t-23-9H332q-14 0-23 9t-9 23q0 14 9 23t23 9Zm-232 82v-560 560Zm126-394q17 0 29.5-13t12.5-30q0-17-12.5-29.5T226-659q-17 0-30 12.5T183-617q0 17 13 30t30 13Zm170 0q17 0 29.5-13t12.5-30q0-17-12.5-29.5T396-659q-17 0-30 12.5T353-617q0 17 13 30t30 13Zm169 0q17 0 29.5-13t12.5-30q0-17-12.5-29.5T565-659q-17 0-30 12.5T522-617q0 17 13 30t30 13Zm170 0q17 0 29.5-13t12.5-30q0-17-12.5-29.5T735-659q-17 0-30 12.5T692-617q0 17 13 30t30 13ZM225.807-407Q244-407 256.5-419.307t12.5-30.5Q269-468 256.693-480.5t-30.5-12.5Q208-493 195.5-480.693t-12.5 30.5Q183-432 195.307-419.5t30.5 12.5Zm170 0Q414-407 426.5-419.307t12.5-30.5Q439-468 426.693-480.5t-30.5-12.5Q378-493 365.5-480.693t-12.5 30.5Q353-432 365.307-419.5t30.5 12.5Zm169 0Q583-407 595.5-419.307t12.5-30.5Q608-468 595.693-480.5t-30.5-12.5Q547-493 534.5-480.693t-12.5 30.5Q522-432 534.307-419.5t30.5 12.5Zm170 0Q753-407 765.5-419.307t12.5-30.5Q778-468 765.693-480.5t-30.5-12.5Q717-493 704.5-480.693t-12.5 30.5Q692-432 704.307-419.5t30.5 12.5Z"
		/>
	</svg>
);
