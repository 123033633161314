import { ReactNode } from 'react';

export const symbol_line_weight: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M135-160q-6 0-10.5-4.5T120-175q0-6 4.5-10.5T135-190h690q6 0 10.5 4.5T840-175q0 6-4.5 10.5T825-160H135Zm15-90q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-310h660q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-250H150Zm0-120q-12.75 0-21.375-8.655Q120-387.309 120-400.103v-30.103q0-12.794 8.625-21.294T150-460h660q12.75 0 21.375 8.655Q840-442.691 840-429.897v30.103q0 12.794-8.625 21.294T810-370H150Zm0-150q-12.75 0-21.375-8.625T120-550v-220q0-12.75 8.625-21.375T150-800h660q12.75 0 21.375 8.625T840-770v220q0 12.75-8.625 21.375T810-520H150Z"
		/>
	</svg>
);
