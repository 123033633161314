import { ReactNode } from 'react';

export const symbol_font_download_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M812-62.914 794-80H144q-26.4 0-45.2-18.506Q80-117.012 80-143v-651l-33-33q-9-9.067-9-21.533Q38-861 47.053-870q9.052-9 21.5-9Q81-879 90-870l765 765q9 9 9 21t-9.053 21q-9.052 9-21.5 9Q821-54 812-62.914ZM141-140h593L141-733v593Zm739-676v579q0 20.106-18 27.553Q844-202 830-216q-5-5-7-10.217-2-5.218-2-10.783v-583H238q-5.565 0-10.783-2Q222-824 217-829q-14-14-6.344-32.5T238-880h579q25.987 0 44.494 18.8Q880-842.4 880-816ZM628-418 528-518l-47-131h-2l-22 60-43-43 26-68q3-9 10.742-14.5 7.742-5.5 17.032-5.5H491q10 0 17 5t11 14l109 283Zm-190-19Zm86-86Zm55 142 102 102q6 14-3.5 26.5T651-240q-10 0-17.5-5.6T623-260l-44-121Zm-203 17-38 104q-3.222 8.8-11.278 14.4Q318.667-240 309-240q-17 0-26-13.5t-3-28.5l110-288 45 45-38 106h144l55 55H376Z"
		/>
	</svg>
);
