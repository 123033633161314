import { Account } from 'pkg/api/models/account';
import { Linkable } from 'pkg/api/models/linkable';
import { Dateable } from 'pkg/api/models/dateable';
import { Video } from 'pkg/api/models/video';
import { Record } from 'pkg/api/models/record';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import * as sdk from 'pkg/core/sdk';

export interface VideoCollectionItem extends Dateable {
	video: Video;
	videoId: number;
	videoCollectionId: number;
}

export interface VideoCollection extends Linkable, Record, Dateable {
	groupId: number;
	userId: number;
	accountId: number;
	account: Account;
	name: string;
	isClubCollection: boolean;
	videoCount: number;
	group: models.group.Group;
	videos: VideoCollectionItem[];
}

export function isNew(collection: VideoCollection): boolean {
	return collection.createdAt + 3600 * 24 > Math.floor(Date.now() / 1000);
}

export interface VideoCollectionCreatePayload {
	name: string;
	groupId?: number;
	inherit?: boolean;
}

export async function create(
	payload: VideoCollectionCreatePayload
): Promise<[Response, VideoCollection, models.APIError?]> {
	return models.create(endpoints.VideoCollections.Create(), payload);
}

export interface VideoCollectionUpdatePayload {
	name?: string;
	inherit?: boolean;
}

export async function update(
	collection: Linkable,
	payload: VideoCollectionUpdatePayload
): Promise<[Response, VideoCollection, models.APIError?]> {
	return models.update(collection, payload);
}

export async function addVideo(
	collectionId: number,
	videoId: number
): Promise<[Response, VideoCollectionItem, models.APIError?]> {
	return models.create<null, VideoCollectionItem>(
		endpoints.VideoCollections.AddVideo(collectionId, videoId),
		null
	);
}

export async function removeVideo(
	collectionId: number,
	videoId: number
): Promise<boolean> {
	const request = await sdk.destroy(
		endpoints.VideoCollections.RemoveVideo(collectionId, videoId)
	);

	return request.ok;
}
