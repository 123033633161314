import { ReactNode } from 'react';

export const symbol_hot_tub: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M266-627q-29 0-49-20t-20-49q0-29 20-49t49-20q29 0 49 20t20 49q0 29-20 49t-49 20ZM140-80q-25 0-42.5-17.5T80-140v-283q0-13 8.5-21.5T110-453h92v-56q0-31 21.5-53t52.5-22q18 0 34.5 8t28.5 22l57 64q10 11 21 20t23 17h410q13 0 21.5 8.5T880-423v283q0 25-17.5 42.5T820-80H140Zm630-480q-11 0-19-8.5t-9-19.5q-2-28-13-52.5T702-688q-20-27-31-58.5T660-812q0-11 8.5-19.5T688-840q11 0 18.5 8.5T715-812q1 26 10 50.5t25 44.5q22 28 34.5 61t12.5 68q0 11-8 19.5t-19 8.5Zm-160 0q-11 0-19-8.5t-9-19.5q-2-28-13-52.5T542-688q-20-27-31-58.5T500-812q0-11 8.5-19.5T528-840q11 0 18.5 8.5T555-812q1 26 10 50.5t25 44.5q22 28 34.5 61t12.5 68q0 11-8 19.5t-19 8.5Zm70 420h110v-253H680v253Zm-170 0h110v-253H510v253Zm-170 0h110v-253H340v253Zm-170 0h110v-253H170v253Z"
		/>
	</svg>
);
