import * as styles from 'pkg/config/styles';

import useTooltip from 'pkg/hooks/useTooltip';

import * as formatting from 'routes/kitchen_sink/formatting.css';

import Icon from 'components/icon';

import Column from 'components/layout/column';

import Button from 'design/button';
import * as Context from 'design/context_menu';

import * as css from './styles.css';

function TooltipView() {
	const tooltipText = `I'm a tooltip 😎`;
	const { tooltip, onMouseEnter } = useTooltip(tooltipText);

	return (
		<Column spacing={styles.spacing._7}>
			<h1 className={formatting.h2}>useTooltip</h1>

			<p>
				<code className={formatting.code}>useTooltip</code> is a hook that can
				be used to create tooltips for all sort of elements, there's some
				components with a tooltip prop. These are{' '}
				<code className={formatting.code}>Icon</code>,{' '}
				<code className={formatting.code}>Button</code> and{' '}
				<code className={formatting.code}>ContextMenu.Item</code>.
			</p>

			<hr className={formatting.divider} />

			<h2 className={formatting.h2}>Examples</h2>

			<div className={css.examplesWrapper}>
				<div>
					<h3 className={css.hoverText} onMouseEnter={onMouseEnter}>
						Hover me!
						{tooltip}
					</h3>
				</div>
				<div>
					<Button label="Button with tooltip" tooltip={tooltipText} />
				</div>
				<div>
					<Context.Menu toggleWith={<Button icon="more_horiz" />}>
						<Context.Item tooltip={tooltipText}>Tooltip</Context.Item>
					</Context.Menu>
				</div>
				<div>
					<Icon
						name="lock"
						fill={styles.palette.red[500]}
						tooltip={tooltipText}
						size={1.5}
						actualSize
					/>
				</div>
			</div>
		</Column>
	);
}

export default TooltipView;
