import { ReactNode } from 'react';

export const symbol_bluetooth_disabled: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M450-408 277-235q-9 9-21 9t-21-9q-9-9-9-21t9-21l175-175L77-785q-9-9-9-21t9-21q9-9 21-9t21 9l708 708q9 9 9 21t-9 21q-9 9-21 9t-21-9L624-238 501-115q-5 5-10.5 7t-10.5 2q-11 0-20.5-8t-9.5-22v-272Zm60 198 70-70-70-70v140Zm26-284-42-43 116-115-100-98v229l-60-60v-243q0-14 9.5-22t20.5-8q5 0 10.5 2t10.5 7l172 172q5 5 7 10t2 11q0 6-2 11t-7 10L536-494Z"
		/>
	</svg>
);
