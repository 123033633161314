import { Fragment, ReactNode, useRef, useState } from 'react';
import { t } from '@transifex/native';

import {
	ImageEditorFormat,
	SaveableImage,
	useImageEditor,
} from 'pkg/hooks/image-editor';
import * as actions from 'pkg/actions';
import * as models from 'pkg/api/models';
import { crash } from 'pkg/errors/errors';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';

import MaterialSymbol from 'components/material-symbols';
import { useStepModalContext } from 'components/step-modal';
import { EditableSplashScreen } from 'components/splash-screen';

import FileInput from 'components/form/File';
import RangeSlider from 'components/form/RangeSlider';

import Button from 'design/button';

import * as css from './styles.css';

interface SplashScreenPreviewProps {
	group: models.group.Group;
	refresh: () => void;
}

export default function SplashScreenPreview({
	group,
	refresh,
}: SplashScreenPreviewProps): JSX.Element {
	const { withNext } = useStepModalContext();
	const splashScreen = models.group.getSplashScreenSettings(group);

	const fileInputRef = useRef<HTMLInputElement>(null);
	const [scale, setScale] = useState<number>(1);

	const editor = useImageEditor({
		width: 310,
		height: 218,
		imageWidth: 600,
		transparentCanvas: true,
		saveFormat: ImageEditorFormat.Base64,
		onSave: async (image: SaveableImage) => {
			const [success] = await actions.organizations.updateSplashScreen(
				group.id,
				{
					image: image.toString(),
				}
			);

			if (!success) {
				const { title } = crash();

				actions.flashes.show({
					title,
					message: t('Could not update splash screen.'),
				});
			} else {
				actions.flashes.show({
					title: t('Updated Splash Screen'),
					message: t('Organization splash screen sucessfully updated.'),
				});

				actions.organizations.hydrateSplashScreenStateFromGroup(group);
			}

			refresh();
		},
	});

	const handleImageSelect = async (files: File[]) => {
		await editor.load(files[0]);
	};

	const handleImageScaleChange = (scale: number) => {
		setScale(scale);
		editor.scale(scale);
	};

	const handleImageRemove = async () => {
		const success = await actions.organizations.deleteSplashScreen(group.id);

		if (!success) {
			const { title } = crash();

			actions.flashes.show({
				title,
				message: t('Could not delete splash screen.'),
			});
		} else {
			editor.unload();
		}

		refresh();
	};

	withNext(async () => {
		await editor.save();

		return true;
	});

	useComponentDidMount(() => {
		if (editor && splashScreen?.imageUrl) {
			editor.load(splashScreen?.imageUrl);
		}
	});

	let canvas: ReactNode = (
		<div className={css.canvas}>
			{editor.canvas}
			<span className={css.remove} onClick={handleImageRemove}>
				<MaterialSymbol variant="close" />
			</span>
		</div>
	);

	if (!editor.hasImage) {
		canvas = (
			<FileInput
				accept="image/*"
				allowDrop
				onChange={handleImageSelect}
				inputRef={fileInputRef}
				className={css.upload}>
				<div className={css.uploadArea}>
					<p>{t('Image will show up here.')}</p>
					<Button primary icon="image" className={css.uploadButton}>
						{t('Upload image')}
					</Button>
				</div>
			</FileInput>
		);
	}

	return (
		<Fragment>
			<EditableSplashScreen group={group}>{canvas}</EditableSplashScreen>
			{editor.hasImage && (
				<RangeSlider
					min={0.25}
					max={3}
					step={0.01}
					value={scale}
					onChange={handleImageScaleChange}
					className={css.scale}
				/>
			)}
		</Fragment>
	);
}
