import styled from 'styled-components';

import * as styles from 'pkg/config/styles';

import rgba from 'pkg/rgba';

const FooterLinks = styled.div`
	display: inline-flex;
	flex-wrap: wrap;
	gap: var(--spacing-5);
	align-items: center;
	justify-content: center;
	font-size: var(--font-size-sm);
	color: var(--palette-white);
	line-height: var(--font-line-height-lg);

	@media ${styles.breakpoint.small} {
		font-size: var(--font-size-xs);
	}

	a {
		display: inline-block;
		color: ${rgba(styles.palette.white, 0.3)};
		border-bottom: 1px solid ${rgba(styles.palette.white, 0.3)};
		font-weight: var(--font-weight-normal);
		cursor: pointer;

		@media (hover: hover) {
			&:hover {
				color: ${rgba(styles.palette.white, 0.5)};
				border-bottom: 1px solid ${rgba(styles.palette.white, 0.5)};
			}
		}
	}
`;

export default FooterLinks;
