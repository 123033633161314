import { ReactNode } from 'react';

export const symbol_diversity_1: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-743q40-45 96.678-71T696-840q110 0 187 77t77 187q0 35-8 68t-22 64q-6 13-18 18.5t-25 .5q-11-5-16-15.5t0-20.5q14-27 21.5-56t7.5-59q0-85.37-59.315-144.685T696-780q-57 0-105.214 30.5Q542.571-719 504-677q-9.5 11-23.75 11T456-677q-39-42-87-72.5T264-780q-85.37 0-144.685 59.315T60-576q0 30 7.5 59T89-461q5 10 0 20t-16 15q-12 5-24.5-.5T30-445q-14-31-22-64.036Q0-542.071 0-576q0-110 77-187t187-77q63 0 119.5 26t96.5 71ZM30-80q-12.75 0-21.375-8.625T0-110v-23q0-27.779 21-48.39Q42-202 80-212q26-8 48-8t47 2q7 1 11 6.5t1.647 12.724q-4.118 12.842-5.882 26.085Q180-159.447 180-145v35q0 12.75-8.625 21.375T150-80H30Zm210-30v-35q0-65 66.5-105T480-290q108 0 174 40t66 105v35q0 12.75-8.625 21.375T690-80H270q-12.75 0-21.375-8.625T240-110Zm570 30q-12.75 0-21.375-8.625T780-110v-35q0-14.447-1.765-27.691-1.764-13.243-5.882-26.085Q770-206 774-211.5t11-6.5q25-2 47-2t48 8q38 10 59 30.61 21 20.611 21 48.39v23q0 12.75-8.625 21.375T930-80H810ZM479.8-230Q400-230 350-206q-50 24-50 61v5h360v-6q0-36-49.5-60t-130.7-24Zm-330.233-20Q121-250 100.5-270.562 80-291.125 80-320q0-29 20.562-49.5Q121.125-390 150-390q29 0 49.5 20.5t20.5 49.933Q220-291 199.5-270.5T149.567-250Zm660 0Q781-250 760.5-270.562 740-291.125 740-320q0-29 20.562-49.5Q781.125-390 810-390q29 0 49.5 20.5t20.5 49.933Q880-291 859.5-270.5T809.567-250ZM480-320q-50 0-85-35t-35-85q0-51 35-85.5t85-34.5q51 0 85.5 34.5T600-440q0 50-34.5 85T480-320Zm.351-180Q455-500 437.5-482.851t-17.5 42.5Q420-415 437.351-397.5t43 17.5Q506-380 523-397.351t17-43Q540-466 522.851-483t-42.5-17ZM480-440Zm0 300Z"
		/>
	</svg>
);
