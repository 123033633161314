import * as styles from 'pkg/config/styles';

import Markdown from 'routes/kitchen_sink/components/Markdown';

import Column from 'components/layout/column';

import readme from './README.md';

export default function Ads(): JSX.Element {
	return (
		<Column spacing={styles.spacing._7}>
			<Markdown raw={readme} />
		</Column>
	);
}
