import { ReactNode } from 'react';

export const symbol_battery_charging_90: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M310-80q-12.75 0-21.375-8.625T280-110v-676q0-12.75 8.625-21.375T310-816h90v-34q0-12.75 8.625-21.375T430-880h100q12.75 0 21.375 8.625T560-850v34h90q12.75 0 21.375 8.625T680-786v313q0 7.966-5.926 13.759T660-452q-38 4-71 20.026-33 16.027-58.667 41.669Q501-361 484-322.543 467-284.087 467-239q0 35 11 66.5t30 57.5q8 11 2.5 23T492-80H310Zm30-556h280v-120H340v120Zm320 436h-53q-9.391 0-13.696-7.5Q589-215 594-223l92-147q3-5 8.5-3.5t5.5 7.5v86h53q9.391 0 13.696 7.5Q771-265 766-257l-92 148q-3 5-8.5 3.5T660-113v-87Z"
		/>
	</svg>
);
