import { ReactNode } from 'react';

export const symbol_redeem: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M140-277v97h680v-97H140Zm0-443h125q-5-9-8-22.5t-3-24.5q0-47.083 33-80.042Q320-880 366-880q30.741 0 56.87 15.5Q449-849 464-825l16.5 26 16.5-26q16-25 41.015-40t53.879-15Q640-880 673-847.5q33 32.5 33 80.861Q706-756 703.5-745t-7.5 25h124q24 0 42 18t18 42v480q0 24-18 42t-42 18H140q-24 0-42-18t-18-42v-480q0-24 18-42t42-18Zm0 336h680v-276H571l83 115q8 10 5.5 22T647-503q-10 8-22.098 5.917Q612.805-499.167 605-510L480-683 355-510q-7.561 10.833-19.28 12.917Q324-495 313.5-503q-10.5-8-13-20t5.5-22l83-115H140v276Zm227-330q22 0 37.5-15.5T420-767q0-22-15.5-37.5T367-820q-22 0-37.5 15.5T314-767q0 22 15.5 37.5T367-714Zm225 0q22.95 0 38.475-15.5Q646-745 646-767t-15.525-37.5Q614.95-820 592-820q-21 0-36.5 15.5T540-767q0 22 15.5 37.5T592-714Z"
		/>
	</svg>
);
