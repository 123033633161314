import { ReactNode } from 'react';

export const symbol_shapes: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M600-360ZM380-80q-24.75 0-42.375-17.625T320-140v-90q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T380-230v90h440v-440h-90q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T730-640h90q24.75 0 42.375 17.625T880-580v440q0 24.75-17.625 42.375T820-80H380Zm-20-240q-117 0-198.5-81.5T80-600q0-117 81.5-198.5T360-880q117 0 198.5 81.5T640-600q0 117-81.5 198.5T360-320Zm-.212-60Q451-380 515.5-444.288q64.5-64.288 64.5-155.5T515.712-755.5q-64.288-64.5-155.5-64.5T204.5-755.712q-64.5 64.288-64.5 155.5T204.288-444.5q64.288 64.5 155.5 64.5ZM360-600Z"
		/>
	</svg>
);
