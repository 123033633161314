import { ReactNode } from 'react';

export const symbol_select_window_2: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M656-140v-392H140v392h516Zm60-228v-60h104v-392H304v228h-60v-228q0-24 18-42t42-18h516q24 0 42 18t18 42v392q0 24-18 42t-42 18H716ZM140-80q-24 0-42-18t-18-42v-392q0-24 18-42t42-18h504q29.7 0 50.85 20.856Q716-550.287 716-521v381q0 24-18 42t-42 18H140Zm422-617ZM398-336Z"
		/>
	</svg>
);
