import { Component } from 'react';
import styled from 'styled-components';

import * as palette from 'pkg/config/palette';
import { small } from 'pkg/config/breakpoints';

import RangeSlider from 'components/form/RangeSlider';

const Item = styled.div`
	display: grid;
	grid-template-areas: 'label input' 'slider slider';
	grid-template-columns: 1fr 45px;
	grid-column-gap: 1rem;
	grid-row-gap: 0.5rem;
	align-items: flex-end;
	padding: 0;
	width: 100%;
	max-width: 60%;
	margin-top: ${(props) => (props.hasDescription ? '1rem' : '.5rem')};
	margin-left: auto;
	margin-right: auto;

	@media ${small} {
		max-width: 65%;
	}
`;

const Slider = styled(RangeSlider)`
	grid-area: slider;

	@media ${small} {
		width: calc(100% + 14px);
		margin-left: -7px;
	}
`;

const TextField = styled.input`
	grid-area: input;
	width: auto;
	height: auto;
	padding: 10px 0 11px;
	border: 2px solid #dce3eb;
	background: ${palette.white};
	border-radius: var(--radius-3);
	text-align: center;
	font-size: var(--font-size-base);

	-webkit-appearance: none;
	-moz-appearance: textfield;

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	@media ${small} {
		padding: 4px 0 5px;
	}
`;

const Label = styled.div`
	grid-area: label;
	font-weight: var(--font-weight-semibold);
`;

const ShortDescription = styled.span`
	color: ${palette.gray[600]};
	display: inline-block;
	width: 100%;
	font-size: var(--font-size-sm);
	line-height: 1.5;
	margin-top: 0.1rem;

	@media ${small} {
		font-size: 0.7rem;
	}
`;

export default class extends Component {
	shouldComponentUpdate(nextProps) {
		if (nextProps.value !== this.props.value) return true;

		return false;
	}

	checkConstraints(val) {
		if (parseFloat(val) > 10) {
			val = 10;
		}

		if (parseFloat(val) < 0) {
			val = 0;
		}

		if (isNaN(val)) {
			val = this.props.value;
		}

		if (val.length === 0) {
			val = 0;
		}

		return parseFloat(val);
	}

	increase(e) {
		e.preventDefault();
		let val = (parseFloat(this.props.value) + 0.1).toFixed(1);
		val = this.checkConstraints(val);
		this.props.onInput(this.props.item.id, val);
	}

	decrease(e) {
		e.preventDefault();
		let val = (parseFloat(this.props.value) - 0.1).toFixed(1);
		val = this.checkConstraints(val);
		this.props.onInput(this.props.item.id, val);
	}

	setValue = (e) => {
		let val = this.checkConstraints(e.target.value);
		this.props.onInput(this.props.item.id, val);
	};

	handleSliderInput = (value) => {
		this.props.onInput(this.props.item.id, Number.parseFloat(value));
	};

	get shortDescription() {
		if (this.props.item.shortDescription) {
			return (
				<ShortDescription>{this.props.item.shortDescription}</ShortDescription>
			);
		}

		return null;
	}

	render() {
		return (
			<Item hasDescription={this.props.item.shortDescription}>
				<Label>
					{this.props.item.name}
					{this.shortDescription}
				</Label>

				<TextField
					type="number"
					onChange={this.setValue}
					value={this.props.value}
				/>

				<Slider
					value={this.props.value}
					step={0.1}
					min={0}
					max={10}
					onChange={this.handleSliderInput}
				/>
			</Item>
		);
	}
}
