import { ReactNode } from 'react';

import {
	MessageType,
	useWebSocketClient,
	useWebSocketSubscription,
} from 'pkg/websocket';
import * as models from 'pkg/api/models';

interface IdentityProviderObserverProps {
	providerId: number;
	children: ReactNode;
	replaceRecord: (idp: models.identityProvider.IdentityProvider) => void;
	removeRecord: (id: number) => void;
}

export default function IdentityProviderObserver({
	providerId,
	children,
	replaceRecord,
	removeRecord,
}: IdentityProviderObserverProps) {
	const ws = useWebSocketClient();

	useWebSocketSubscription(`p.identity_provider.${providerId}`);

	ws.onMessage<models.identityProvider.IdentityProvider>(
		MessageType.IdentityProviderUpdated,
		(message) => {
			replaceRecord(message.data);
		}
	);

	ws.onMessage(MessageType.IdentityProviderDeleted, () => {
		removeRecord(providerId);
	});

	return children;
}
