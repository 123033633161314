import { ReactNode } from 'react';

export const symbol_60fps: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M335-200H160q-33.333 0-56.667-23.333Q80-246.667 80-280v-400q0-33.333 23.333-56.667Q126.667-760 160-760h172q16.667 0 28.333 11.735Q372-736.529 372-719.765 372-703 360.333-691.5 348.667-680 332-680H160v138h175q33.333 0 56.667 23.333Q415-495.333 415-462v182q0 33.333-23.333 56.667Q368.333-200 335-200ZM160-462v182h175v-182H160Zm422 182h218v-400H582v400Zm0 80q-33.333 0-56.667-23.333Q502-246.667 502-280v-400q0-33.333 23.333-56.667Q548.667-760 582-760h218q33.333 0 56.667 23.333Q880-713.333 880-680v400q0 33.333-23.333 56.667Q833.333-200 800-200H582Z"
		/>
	</svg>
);
