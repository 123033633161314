import { ReactNode } from 'react';

export const symbol_pest_control: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-120q-63 0-114.5-35.5T282-247l-75 43q-11 6-22.5 3T167-215q-6-11-3-23t14-18l79-46q-5-17-9-34t-6-34h-92q-13 0-21.5-8.5T120-400q0-13 8.5-21.5T150-430h92q2-17 6-34t9-34l-80-47q-11-6-13.5-18t3.5-23q6-11 17.5-13.5T207-596l74 43q9-16 20-29.5t23-26.5q-2-8-3-15.5t-1-15.5q0-28 10.5-54.5T360-742l-45-44q-9-9-9-21t9-21q9-9 21.5-9.5T358-829l49 49q17-10 35-15t38-5q20 0 38 5t35 15l50-49q9-9 21-8.5t21 9.5q9 9 9 21t-9 21l-45 45q18 21 28.5 47t10.5 54q0 8-.5 15t-2.5 15q12 13 22.5 26.5T678-554l75-42q11-6 22.5-3t17.5 14q6 11 3 23t-14 18l-79 45q6 17 9.5 34t5.5 35h92q13 0 21.5 8.5T840-400q0 13-8.5 21.5T810-370h-92q-2 17-5.5 34.5T703-301l79 46q11 6 14 18t-3 23q-6 11-17.5 13.5T753-204l-75-43q-32 56-83.5 91.5T480-120Zm-99-534q23-13 48-19.5t51-6.5q26 0 50.5 6.5T578-655q-6-33-35-59t-63-26q-34 0-63.5 26T381-654Zm99 474q77 0 128.5-69T660-400q0-75-48.5-147.5T480-620q-83 0-131.5 72.5T300-400q0 82 51.5 151T480-180Zm0-100q-13 0-21.5-8.5T450-310v-180q0-13 8.5-21.5T480-520q13 0 21.5 8.5T510-490v180q0 13-8.5 21.5T480-280Z"
		/>
	</svg>
);
