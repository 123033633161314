import { ReactNode } from 'react';

export const symbol_arrow_top_left: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m274-575 141 140q9 9 9 21.5t-8.6 21.62q-9.382 9.12-21.891 9.5Q381-382 372-391L181-582q-5-5-7-10.133-2-5.134-2-11Q172-609 174-614q2-5 7-10l192-194q9.067-9 21.533-9Q407-827 416-818q9 9 9 21t-9 21L275-635h425q24.75 0 42.375 17.625T760-575v385q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T700-190v-385H274Z"
		/>
	</svg>
);
