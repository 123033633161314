import { ReactNode } from 'react';

export const symbol_face_retouching_off: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="m831-86-89-89q-57 47-121 71T483-80q-84 0-157-31t-127-85q-54-54-85-127T83-480q0-76 25.5-141.5T178-739l-90-90q-9-9-8.5-21t9.5-21q9-9 21-9t21 9l743 743q9 9 8.5 21T873-86q-9 9-21 9t-21-9Zm52-394q0 48-10 92.5T843-304q-3 6-7.5 9.5t-10 4.5q-5.5 1-11.5 0t-11-4q-11-7-13.5-19.5T792-337q14-31 22.5-68t8.5-75q0-28-3.5-49.5T809-577q-22 5-44 7t-48 2q-82 0-166.5-31T403-684l-98-98q-14-14-11-33t20-27q38-18 80.5-28t88.5-10q83 0 156 31.5T766-763q54 54 85.5 127T883-480ZM579-724ZM152-558q33-15 58.5-34.5T268-649l-47-47q-25 31-40.5 62.5T152-558Zm219-243q69 88 164.5 130.5T717-628q20 0 37-1t32-6q-46-83-124.5-134T474-820q-11 0-44 4t-59 15ZM210-627Zm147 244q-23 0-38.5-15.5T303-437q0-23 15.5-38.5T357-491q23 0 38.5 15.5T411-437q0 23-15.5 38.5T357-383Zm126 243q61 0 118-21.5t98-56.5L312-605q-32 37-77 68t-92 50v7q0 140 100 240t240 100Z"
		/>
	</svg>
);
