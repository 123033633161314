import { t } from '@transifex/native';

import { crash } from 'pkg/errors/errors';

import InfoScreen from 'components/InfoScreen';

import Button from 'design/button';

interface CrashGuardProps {
	reset: () => void;
}

/**
 * FullPageCrash is used when we can't recover any further, it will take over the entire app.
 * If you can catch a crash before it gets here end users will have a nicer experience.
 */
export function FullPageCrash({ reset }: CrashGuardProps): JSX.Element {
	const err = crash();
	return (
		<InfoScreen icon="error" heading={err.title} message={err.description}>
			<Button large onClick={reset}>
				{t('Back to start')}
			</Button>
		</InfoScreen>
	);
}
