import { ReactNode } from 'react';

export const symbol_body_fat: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M713-113 88-425q-17.25-8.25-25.125-22.875T55-478.25q0-15.75 7.875-30.652Q70.75-523.804 88-532l625-312q15.082-8 30.541-6.5T772-841q13 8 20.5 20.825Q800-807.351 800-790v170h10q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-560h-80q-12 0-21-8.675-9-8.676-9-21.5 0-12.825 8.625-21.325T730-620h10v-168L521-680q32 44 50.5 94.939Q590-534.122 590-480q0 56-19 107t-52 96l220 109v-171h-9q-13.077 0-21.538-8.877-8.462-8.877-8.462-22t8.625-21.623Q717.25-400 730-400h80q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q840-357 831.088-348q-8.913 9-22.088 9h-9v172q0 17.351-7.5 30.175Q785-124 772-116t-28.5 9.5Q728-105 713-113ZM464-305q29-38 47.5-82.723T530-480q0-48-18-91t-47-81L115-479l349 174Z"
		/>
	</svg>
);
