import { ReactNode } from 'react';

export const symbol_margin: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-60h600v-600H180v600Zm0-600v600-600Zm134 173q16.575 0 27.788-11.213Q353-629.425 353-646t-11.212-27.787Q330.575-685 314-685q-16.575 0-27.788 11.213Q275-662.575 275-646t11.212 27.787Q297.425-607 314-607Zm166 0q17 0 28.5-11.213Q520-629.425 520-646t-11.5-27.787Q497-685 480-685q-16 0-27 11.5T442-646q0 16 10.925 27.5T480-607Zm167 0q16.575 0 27.787-11.213Q686-629.425 686-646t-11.213-27.787Q663.575-685 647-685t-27.787 11.213Q608-662.575 608-646t11.213 27.787Q630.425-607 647-607Zm0 166q16.575 0 27.787-11.212Q686-463.425 686-480q0-16.575-11.213-27.788Q663.575-519 647-519t-27.787 11.212Q608-496.575 608-480q0 16.575 11.213 27.788Q630.425-441 647-441Zm-167 0q17 0 28.5-11.212Q520-463.425 520-480q0-16.575-11.5-27.788Q497-519 480-519q-16 0-27 11.5T442-480q0 16 10.925 27.5T480-441Zm-166 0q16.575 0 27.788-11.212Q353-463.425 353-480q0-16.575-11.212-27.788Q330.575-519 314-519q-16.575 0-27.788 11.212Q275-496.575 275-480q0 16.575 11.212 27.788Q297.425-441 314-441Z"
		/>
	</svg>
);
