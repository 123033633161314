import { ReactNode } from 'react';

export const symbol_local_car_wash: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M483-769q-24 0-41.5-17.5T424-827.837q0-17.163 8-29.663t40-49.5q5-5 11-5t11 5q32 37 40 49.5t8 29.663Q542-804 524.5-786.5 507-769 483-769Zm-211 0q-24 0-41.5-17.5T213-827.837q0-17.163 8-29.663t40-49.5q5-5 11-5t11 5q32 37 40 49.5t8 29.663Q331-804 313.5-786.5 296-769 272-769Zm420 0q-24 0-41.5-17.5T633-827.837q0-17.163 8-29.663t40-49.5q5-5 11-5t11 5q32 37 40 49.5t8 29.663Q751-804 733.5-786.5 716-769 692-769ZM200-124v44q0 16.667-11.735 28.333Q176.529-40 159.765-40 143-40 131.5-51.667 120-63.333 120-80v-304q0-4.667.5-9.333Q121-398 123-403l78-236q6-19 21.75-30T258-680h444q19.5 0 35.25 11T759-639l78 236q2 5 2.5 9.667.5 4.666.5 9.333v304q0 16.667-11.735 28.333Q816.529-40 799.765-40 783-40 771-51.958 759-63.917 759-81v-43H200Zm3-330h554l-55-166H258l-55 166Zm-23 60v210-210Zm105.765 160Q309-234 324.5-249.75T340-288q0-23.333-15.75-39.667Q308.5-344 286-344q-23.333 0-39.667 16.265Q230-311.471 230-288.235 230-265 246.265-249.5q16.264 15.5 39.5 15.5ZM675-234q23.333 0 39.667-15.75Q731-265.5 731-288q0-23.333-16.265-39.667Q698.471-344 675.235-344 652-344 636.5-327.735q-15.5 16.264-15.5 39.5Q621-265 636.75-249.5T675-234Zm-495 50h600v-210H180v210Z"
		/>
	</svg>
);
