import { t } from '@transifex/native';

import MaterialSymbol from 'components/material-symbols';

import Column from 'components/layout/column';

import Button from 'design/button';

import * as css from './styles.css';

interface OrganizationSearchEmptyStateProps {
	search: string;
	clearSearch: () => void;
}

export default function OrganizationSearchEmptyState({
	search,
	clearSearch,
}: OrganizationSearchEmptyStateProps) {
	return (
		<div className={css.emptyStateWrapper}>
			<Column justify="center">
				<MaterialSymbol actualSize scale={6.5} variant="manage_search" />
				<div className={css.text}>
					<span>
						{t('No organisations found for "{searchString}"', {
							searchString: search,
						})}
					</span>
				</div>
				<Button
					label={t('Clear your search and try again')}
					secondary
					onClick={clearSearch}
				/>
			</Column>
		</div>
	);
}
