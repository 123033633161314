import React, { Fragment } from 'react';
import styled from 'styled-components';

import * as styles from 'pkg/config/styles';

import { useMembership, useUser, useGroup } from 'pkg/hooks/selectors';

import InfoContact from 'containers/user/profile/info-contact';
import PlayerOverview from 'containers/user/profile/overview/Player';

import { LargeScreen } from 'components/MediaQuery';

import Column from 'components/layout/column';
import Header from 'components/user/profile/overview/Header';

const ProfileWrapper = styled.div`
	width: ${styles.size.PageWidths.STANDARD}px;
	max-width: 100%;
	margin: 0 auto var(--spacing-9) auto;
`;

type ProfileOverviewProps = {
	groupId: number;
	userId: number;
};

const ProfileOverview: React.FC<
	React.PropsWithChildren<ProfileOverviewProps>
> = ({ groupId, userId }) => {
	const group = useGroup(groupId);
	const user = useUser(userId);
	const profileMembership = useMembership(groupId, userId);

	return (
		<Fragment>
			<LargeScreen>
				<Header group={group} user={user} />
			</LargeScreen>
			<ProfileWrapper>
				<Column>
					{profileMembership.isAdminOrStaff() ? (
						<InfoContact userId={user.id} />
					) : (
						<PlayerOverview groupId={groupId} user={user} />
					)}
				</Column>
			</ProfileWrapper>
		</Fragment>
	);
};

export default ProfileOverview;
