import { ReactNode } from 'react';

export const symbol_passkey: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M180-160q-24.75 0-42.375-17.625T120-220v-34q0-38 19-65t49-41q67-30 128.5-45T440-420q21 0 42 1.5t42 5.5q8 2 12 7.192 4 5.193 4 12.808 0 50 23 93.5t64 71.5q6.067 3.966 9.533 9.914Q640-212.138 640-205v15q0 12.75-8.625 21.375T610-160H180Zm260-340q-63 0-106.5-43.5T290-650q0-63 43.5-106.5T440-800q63 0 106.5 43.5T590-650q0 63-43.5 106.5T440-500Zm300 127q17 0 28.5-11.5T780-413q0-17-11.5-28.5T740-453q-17 0-28.5 11.5T700-413q0 17 11.5 28.5T740-373Zm9 349-44.762-44.762Q702-71 700-79v-179.864Q656-272 628-308.5T600-393q0-58 41-99t99-41q58 0 99 41t41 99q0 45-25.5 80T790-263l39 39q5 4.636 5 10.818Q834-207 829-202l-38 38q-5 4.636-5 10.818Q786-147 791-142l38 38q5 4.636 5 10.818Q834-87 829-82l-58 58q-4.636 5-10.818 5Q754-19 749-24Z"
		/>
	</svg>
);
