import { t } from '@transifex/native';
import { Fragment, useMemo } from 'react';

import DateTimePicker from 'components/form/DateTimePicker';
import FormRow from 'components/form/Row';
import Label from 'components/form/Label';
import Toggle from 'components/form/Toggle';

const Sticky = ({ dispatch, data }) => {
	const handleStickyChange = () => {
		dispatch({
			type: 'set_value',
			payload: { key: 'isSticky', value: !data.isSticky },
		});
	};

	const handleStickyUntilChange = (value) => {
		dispatch({
			type: 'set_value',
			payload: {
				key: 'stickyUntil',
				value: Math.round(+value / 1000),
			},
		});
	};

	const stickyUntilDate = useMemo(() => {
		if (!data.stickyUntil) {
			return new Date();
		}

		return new Date(data.stickyUntil * 1000);
	}, [data.stickyUntil]);

	return (
		<FormRow>
			<Toggle
				label={t(`Sticky post`)}
				onChange={handleStickyChange}
				isActive={data.isSticky}
				description={t(
					`Sticky posts are always visible on the top of the feed.`
				)}>
				{data.isSticky && (
					<Fragment>
						<Label>{t(`Sticky until`)}</Label>

						<DateTimePicker
							onDateChange={handleStickyUntilChange}
							disableDatesBefore={new Date()}
							selectedDate={stickyUntilDate}
						/>
					</Fragment>
				)}
			</Toggle>
		</FormRow>
	);
};

export default Sticky;
