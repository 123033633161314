import { ReactNode } from 'react';

export const symbol_directions_bike: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M619.08-754q-30.08 0-51.58-21.42-21.5-21.421-21.5-51.5 0-30.08 21.42-51.58 21.421-21.5 51.5-21.5 30.08 0 51.58 21.42 21.5 21.421 21.5 51.5 0 30.08-21.42 51.58-21.421 21.5-51.5 21.5ZM422-548l73 78q8 8.462 12 19.231Q511-440 511-429v193q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T451-236v-170L315-525q-9-8-15-21.5t-6-27.5q0-14 6.5-27t14.5-21l119-119q8-8 22.5-14.5T487-762q16 0 30.5 6.5T540-741l78 78q23 23 52.5 40.5T736-599q13 2 22 10.5t9 21.407q0 12.908-8.781 21.5Q749.439-537 737-539q-49-5-90-26.5T573-620l-40-40-111 112Zm-221.912 78Q283-470 339-413.912q56 56.087 56 139Q395-192 338.912-136q-56.087 56-139 56Q117-80 61-136.088q-56-56.087-56-139Q5-358 61.088-414q56.087-56 139-56ZM200-129q62 0 104-42t42-104q0-62-42-104t-104-42q-62 0-104 42T54-275q0 62 42 104t104 42Zm560.088-341Q843-470 899-413.912q56 56.087 56 139Q955-192 898.912-136q-56.087 56-139 56Q677-80 621-136.088q-56-56.087-56-139Q565-358 621.088-414q56.087-56 139-56ZM760-129q62 0 104-42t42-104q0-62-42-104t-104-42q-62 0-104 42t-42 104q0 62 42 104t104 42Z"
		/>
	</svg>
);
