import * as models from 'pkg/api/models';
import { RecurringInterval } from 'pkg/api/models/provider_settings';
import { formatToCurrency } from 'pkg/i18n/formatters';

import { ExtraPreviewValues } from 'components/payment_platform/preview';

export enum PaymentRowType {
	PaymentRowTypeProduct = 0,
	PaymentRowTypeFee,
	PaymentRowTypeDiscount,
}

export interface LineItem {
	name: string;
	quantity: number;
	amount: number;
	totalListPrice: number;
	type: PaymentRowType;
	productPrice: models.productPrice.ProductPrice;
	taxRate: models.taxRate.TaxRate;
	discount: models.discount.Discount;
}

export interface PaymentPeriodInfo {
	salePrice: number;
	originalSalePrice: number;
	lineItems: LineItem[];
	recurringCount: number;
}

export interface PaymentPreview {
	initialPayment: PaymentPeriodInfo;
	recurringDiscountedPayment: PaymentPeriodInfo;
	recurringPayment: PaymentPeriodInfo;
	recurringInterval: RecurringInterval;
	installmentCount: number;
	recurringDiscountCount: number;
	subscriptionStartAt: number;
	totalInstallmentAmount: number;
	bundleNumber: number;
	delayedSubscriptionStart: boolean;
	recurringIntervalCount: number;
	discountedPayments: number;
}

export function paymentPreviewTotal(
	preview: PaymentPreview,
	currency: string,
	extraPreviewValues?: ExtraPreviewValues
) {
	if (!preview) {
		return '';
	}

	if (preview.totalInstallmentAmount) {
		let total = preview.totalInstallmentAmount;

		if (extraPreviewValues?.paidInstallments) {
			total += extraPreviewValues.paidInstallments;
		}

		return formatToCurrency(total / 100, currency);
	}

	return formatToCurrency(preview.initialPayment.salePrice / 100, currency);
}
