import { ReactNode } from 'react';

export const symbol_respiratory_rate: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M480-480ZM80-600v-140q0-24 18-42t42-18h680q24 0 42 18t18 42v230q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T820-510v-230H140v140q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Q97-570 88.5-578.625T80-600Zm60 440q-24 0-42-18t-18-42v-140q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-360v140h230q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T370-160H140Zm140-130q-9 0-16-4t-12-12l-71-144h-71q-12.75 0-21.375-8.675Q80-467.351 80-480.175 80-493 88.625-501.5T110-510h90q8.171 0 15.229 4.636Q222.286-500.727 226-493l54 106 133-266q4-8 11.381-11.5t15.5-3.5q8.119 0 15.619 3.5T467-653l87 176q-15.931 1.875-29.966 8.438Q510-462 497-452l-57-120-134 266q-3.611 8-10.833 12-7.223 4-15.167 4ZM510-40q-20.833 0-35.417-14.583Q460-69.167 460-90v-134q0-5 1-10.5t3-10.5l48-129q7.8-20.346 25.567-33.173Q555.333-420 577-420h83v-50q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T720-470v50h82q21.667 0 39.433 12.827Q859.2-394.346 867-374l49 129q2 5 3 10.5t1 10.5v134q0 20.833-15 35.417Q890-40 870-40h-80q-20.833 0-35.417-14.583Q740-69.167 740-90v-20q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T800-110v10h60v-124l-51-136h-89v52l40 41q8 9 8.5 21t-8.5 21q-9 9-21 9t-21-9l-28-28-29 29q-9 9-21.5 8.5T618-225q-9-9-8.5-21t9.5-21l41-41v-52h-90l-50 136v124h60v-10q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T640-110v20q0 20.833-15 35.417Q610-40 590-40h-80Zm180-190Zm-71-37 41-41v-52 52l-41 41Zm141 0-40-41v-52 52l40 41Z"
		/>
	</svg>
);
