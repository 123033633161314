import { ReactNode } from 'react';

export const symbol_fort: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M40-180v-71q0-12 5-23.5T58-294l62-62v-258l-62-62q-8-8-13-19.5T40-719v-91q0-13 8.5-21.5T70-840q13 0 21.5 8.5T100-810v50h100v-50q0-13 8.5-21.5T230-840q13 0 21.5 8.5T260-810v50h100v-50q0-13 8.5-21.5T390-840q13 0 21.5 8.5T420-810v91q0 12-5 23.5T402-676l-62 62v74h280v-74l-62-62q-8-8-13-19.5t-5-23.5v-91q0-13 8.5-21.5T570-840q13 0 21.5 8.5T600-810v50h100v-50q0-13 8.5-21.5T730-840q13 0 21.5 8.5T760-810v50h100v-50q0-13 8.5-21.5T890-840q13 0 21.5 8.5T920-810v91q0 12-5 23.5T902-676l-62 62v258l62 62q8 8 13 19.5t5 23.5v71q0 25-17.5 42.5T860-120H600q-13 0-21.5-8.5T570-150v-90q0-38-26-64t-64-26q-38 0-64 26t-26 64v90q0 13-8.5 21.5T360-120H100q-25 0-42.5-17.5T40-180Zm60 0h230v-60q0-63 44-106.5T480-390q63 0 106.5 43.5T630-240v60h230v-71l-80-80v-308l61-61H619l61 61v159H280v-159l61-61H119l61 61v308l-80 80v71Zm380-260Z"
		/>
	</svg>
);
