import { ReactNode } from 'react';

export const symbol_electric_moped: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M520-119v54q0 8.13-7 12.565Q506-48 498-52l-179-91q-5-3-3.909-8.5 1.091-5.5 6.909-5.5h122v-53q0-8.13 7-12.565 7-4.435 15-.435l179 90q5 2.947 3.5 8.474Q647-119 641-119H520ZM274.235-281Q226-281 192-315.5T158-398h-48q-12.75 0-21.375-8.625T80-428v-90q0-65 45-110.5T235-674h95q24.75 0 42.375 17.625T390-614v156h161l147-185v-138h-93q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T605-841h93q24.75 0 42.375 17.625T758-781v138q0 11-3.441 20.028Q751.118-613.944 745-606L598-421q-8.067 10.733-20.533 16.867Q565-398 551-398H390q0 48-33.765 82.5-33.764 34.5-82 34.5ZM330-458Zm-56 117q23 0 39.5-17t16.5-40H218q0 23 16.5 40t39.5 17Zm86-393H230q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T230-794h130q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T360-734Zm404.235 453Q716-281 682-314.765q-34-33.764-34-82Q648-445 681.765-479q33.764-34 82-34Q812-513 846-479.235q34 33.764 34 82Q880-349 846.235-315q-33.764 34-82 34ZM764-341q23 0 39.5-16.5T820-397q0-23-16.5-39.5T764-453q-23 0-39.5 16.5T708-397q0 23 16.5 39.5T764-341ZM140-458h190v-156h-95q-40 0-67.5 28.2T140-518v60Z"
		/>
	</svg>
);
