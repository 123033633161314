import { ReactNode } from 'react';

export const symbol_open_in_full: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M150-120q-12.75 0-21.375-8.625T120-150v-240q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T180-390v168l558-558H570q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T570-840h240q12.75 0 21.375 8.625T840-810v240q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T780-570v-168L222-180h168q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T390-120H150Z"
		/>
	</svg>
);
