import { ReactNode } from 'react';

export const symbol_memory: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M377-407v-145q0-12.75 8.625-21.375T407-582h145q12.75 0 21.375 8.625T582-552v145q0 12.75-8.625 21.375T552-377H407q-12.75 0-21.375-8.625T377-407Zm60-30h85v-85h-85v85Zm-77 287v-50H260q-24 0-42-18t-18-42v-100h-50q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-420h50v-124h-50q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-604h50v-100q0-24 18-42t42-18h100v-46q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T420-810v46h124v-46q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T604-810v46h100q24 0 42 18t18 42v100h46q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-544h-46v124h46q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-360h-46v100q0 24-18 42t-42 18H604v50q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T544-150v-50H420v50q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T360-150Zm344-110v-444H260v444h444ZM480-480Z"
		/>
	</svg>
);
