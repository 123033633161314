import { ReactNode } from 'react';

export const symbol_signal_cellular_add: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M740-200h-90q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T650-260h90v-90q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T800-350v90h90q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T890-200h-90v90q0 12.75-8.675 21.375Q782.649-80 769.825-80 757-80 748.5-88.625T740-110v-90ZM152-80q-13.5 0-21.75-9.5T122-109.667q0-5.333 2.045-10.933 2.046-5.6 6.955-10.4l698-698q5-5 10.5-7t10.833-2q10.667 0 20.167 8.25Q880-821.5 880-808v338q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T820-470v-266L224-140h306q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q560-97 551.375-88.5T530-80H152Zm72-60 596-596-183 183-140.5 140.5-128 128L224-140Z"
		/>
	</svg>
);
