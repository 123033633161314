import { ReactNode } from 'react';

export const symbol_fast_forward: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M104-297v-366q0-14 9-22t21-8q5 0 9 1.5t8 4.5l263 182q7 5 10 11.5t3 13.5q0 7-3 13.5T414-455L151-273q-4 3-8 4.5t-9 1.5q-12 0-21-8t-9-22Zm407 0v-366q0-14 9-22t21-8q5 0 9 1.5t8 4.5l263 182q7 5 10 11.5t3 13.5q0 7-3 13.5T821-455L558-273q-4 3-8 4.5t-9 1.5q-12 0-21-8t-9-22ZM164-480Zm407 0ZM164-355l181-125-181-125v250Zm407 0 181-125-181-125v250Z"
		/>
	</svg>
);
