import { ReactNode } from 'react';

export const symbol_valve: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M450-780H320q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T320-840h320q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T640-780H510v130q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T450-650v-130ZM160-159v-232q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.695t8.5 21.549v.756h150v-140h-1q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T369-590h222q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.695 21.325t-21.549 8.5H590v140h150v-1q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T800-391v232q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.695t-8.5-21.549V-160H220v.778q0 13.222-8.675 21.722-8.676 8.5-21.5 8.5-12.825 0-21.325-8.625T160-159Zm60-61h520v-110H530v-200H430v200H220v110Zm260 0Z"
		/>
	</svg>
);
