const icon = {
	name: 'drawing-line-dashed',
	objects: [
		{
			type: 'path',
			attributes: {
				d: 'M6 17.6L7.41421 16.1858L8.33345 17.105L6.91924 18.5192L6 17.6Z',
				fill: 'currentColor',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M8.12132 15.4787L9.53553 14.0645L10.4548 14.9837L9.04056 16.3979L8.12132 15.4787Z',
				fill: 'currentColor',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M10.2426 13.3574L11.6569 11.9432L12.5761 12.8624L11.1619 14.2766L10.2426 13.3574Z',
				fill: 'currentColor',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M12.364 11.236L13.7782 9.82183L14.6974 10.7411L13.2832 12.1553L12.364 11.236Z',
				fill: 'currentColor',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M14.4853 9.11473L15.8995 7.70051L16.8187 8.61975L15.4045 10.034L14.4853 9.11473Z',
				fill: 'currentColor',
			},
		},
		{
			type: 'path',
			attributes: {
				d: 'M16.6066 6.99341L18.0208 5.57919L18.9401 6.49843L17.5258 7.91265L16.6066 6.99341Z',
				fill: 'currentColor',
			},
		},
	],
};

export default icon;
