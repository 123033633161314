import { t } from '@transifex/native';

import { Account } from 'pkg/api/models/account';
import { Group } from 'pkg/api/models/group';
import { Linkable } from 'pkg/api/models/linkable';
import { Dateable } from 'pkg/api/models/dateable';
import { Record } from 'pkg/api/models/record';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';

export enum VideoPlaylistSequencePermissions {
	GroupMember = 'groupMember',
	GroupAdmin = 'groupAdmin',
}

export type VideoPlaylistPermission =
	| 'only_you'
	| 'group_admins'
	| 'group_members';

export interface VideoPlaylist extends Linkable, Record, Dateable {
	accountId: number;
	account: Account;
	groupId: number;
	group: Group;
	title: string;
	isPrivate: boolean;
	sequences?: number[];
	sequenceCount?: number;
	sequencePermissions?: VideoPlaylistSequencePermissions[];
}

export function isNew(playlist: VideoPlaylist): boolean {
	return playlist.createdAt + 3600 * 24 > Math.floor(Date.now() / 1000);
}

export function permissionLabel(perm: VideoPlaylistPermission) {
	switch (perm) {
		case 'only_you':
			return t('Only you', {
				_context: 'video playlist permissions',
			});
		case 'group_admins':
			return t('Group admins', {
				_context: 'video playlist permissions',
			});
		case 'group_members':
			return t('Group members', {
				_context: 'video playlist permissions',
			});
	}
}

export interface VideoPlaylistCreatePayload {
	title: string;
	groupId: number;
	sequencePermissions: VideoPlaylistSequencePermissions[];
}

export async function create(
	payload: VideoPlaylistCreatePayload
): Promise<[Response, VideoPlaylist, models.APIError?]> {
	return models.create(endpoints.VideoPlaylist.Create(), payload);
}

export interface VideoPlaylistUpdatePayload {
	title?: string;
	sequencePermissions?: VideoPlaylistSequencePermissions[];
}

export async function update(
	playlist: VideoPlaylist,
	payload: VideoPlaylistUpdatePayload
): Promise<[Response, VideoPlaylist, models.APIError?]> {
	return models.update(playlist, payload);
}
