export interface AverageTimeBreakdown {
	average: number;
	lastMonth: number;
	lastQuarter: number;
	lastYear: number;
}

export interface GraphData {
	showAs: 'months' | 'weeks' | 'days';
	total: { currentPeriodTotal: number };
	datePeriods: { [date: string]: { currentPeriod: number } };
}

export interface EstimatedPaymentSum {
	total: number;
	invoices: number;
	installments: number;
	subscriptions: number;
}

export interface RequiresAction {
	pastDueOrders: number;
	pastDueSum: number;
	uncollectibleOrders: number;
	uncollectibleSum: number;
}

export interface StatusSum {
	amount: number;
	count: number;
}

export interface CreatedInvoicesGraph {
	graphData: GraphData;
	statuses: {
		total: StatusSum;
		open: StatusSum;
		paid: StatusSum;
		pastDue: StatusSum;
		uncollectible: StatusSum;
	};
}

export interface PaymentDueTimings {
	paidAfterDueDate: number;
	paidAfterDueDateSum: number;
	paidInTime: number;
	paidInTimeSum: number;
}

export interface RefundRatio {
	paid: number;
	refunded: number;
}

export interface SubscriptionsGraph {
	graphData: GraphData;
	statuses: {
		total: number;
		active: number;
		scheduled: number;
		pending: number;
		completed: number;
		canceled: number;
		uncollectible: number;
	};
}

export interface TotalNetGraph {
	graphData: GraphData;
	totalNet: {
		paid: number;
		paidOrders: number;
		refunded: number;
		refundedOrders: number;
		totalSum: number;
	};
}

export interface TotalPayments {
	ordersPaid: number;
	paidAmount: number;
}

export interface SoldProductsItem {
	id: number;
	name: string;
	quantity: number;
	amount: number;
}

export interface SoldProducts {
	products: {
		[id: number]: SoldProductsItem;
	};
}

export interface OrderReport {
	averageOverdueTime: AverageTimeBreakdown;
	averagePaymentTime: AverageTimeBreakdown;
	createdInvoicesGraph: CreatedInvoicesGraph;
	estimatedMonthlyRecurringRevenue: number;
	estimatedPaymentsSum: EstimatedPaymentSum;
	installments: string; // @NOTE Not implemented
	newSubscriptions: number;
	paymentDueTimings: PaymentDueTimings;
	refundRatio: RefundRatio;
	requiresAction: RequiresAction;
	soldProducts: SoldProducts;
	subscriptionsGraph: SubscriptionsGraph;
	totalNetGraph: TotalNetGraph;
	totalPayments: TotalPayments;
}

export const EmptyOrderReport: OrderReport = {
	averageOverdueTime: {
		average: 0,
		lastMonth: 0,
		lastQuarter: 0,
		lastYear: 0,
	},
	averagePaymentTime: {
		average: 0,
		lastMonth: 0,
		lastQuarter: 0,
		lastYear: 0,
	},
	createdInvoicesGraph: {
		graphData: {
			showAs: 'months',
			total: { currentPeriodTotal: 0 },
			datePeriods: {},
		},
		statuses: {
			total: { count: 0, amount: 0 },
			open: { count: 0, amount: 0 },
			paid: { count: 0, amount: 0 },
			pastDue: { count: 0, amount: 0 },
			uncollectible: { count: 0, amount: 0 },
		},
	},
	estimatedMonthlyRecurringRevenue: 0,
	estimatedPaymentsSum: {
		total: 0,
		invoices: 0,
		installments: 0,
		subscriptions: 0,
	},
	installments: '',
	newSubscriptions: 0,
	paymentDueTimings: {
		paidAfterDueDate: 0,
		paidAfterDueDateSum: 0,
		paidInTime: 0,
		paidInTimeSum: 0,
	},
	refundRatio: {
		paid: 0,
		refunded: 0,
	},
	requiresAction: {
		pastDueOrders: 0,
		pastDueSum: 0,
		uncollectibleOrders: 0,
		uncollectibleSum: 0,
	},
	soldProducts: {
		products: {},
	},
	subscriptionsGraph: {
		graphData: {
			showAs: 'months',
			total: { currentPeriodTotal: 0 },
			datePeriods: {},
		},
		statuses: {
			total: 0,
			active: 0,
			scheduled: 0,
			pending: 0,
			completed: 0,
			canceled: 0,
			uncollectible: 0,
		},
	},
	totalNetGraph: {
		graphData: {
			showAs: 'months',
			total: { currentPeriodTotal: 0 },
			datePeriods: {},
		},
		totalNet: {
			paid: 0,
			paidOrders: 0,
			refunded: 0,
			refundedOrders: 0,
			totalSum: 0,
		},
	},
	totalPayments: {
		ordersPaid: 0,
		paidAmount: 0,
	},
};
