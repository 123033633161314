import { ReactNode } from 'react';

export const symbol_10k: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M286-550v165q0 10.833 7.116 17.917 7.117 7.083 18 7.083Q322-360 329-367.083q7-7.084 7-17.917v-190q0-10.833-7.083-17.917Q321.833-600 311-600H205q-10.833 0-17.917 7.116-7.083 7.117-7.083 18Q180-564 187.083-557q7.084 7 17.917 7h81Zm120 190h104q14.025 0 23.513-9.487Q543-378.975 543-393v-173q0-14.45-9.487-24.225Q524.025-600 510-600H406q-14.025 0-23.513 9.775Q373-580.45 373-566v173q0 14.025 9.487 23.513Q391.975-360 406-360Zm17-50v-140h70v140h-70Zm207-40 77 82q2 2 17 8 16 0 22.5-15t-4.5-26l-74-79 74-80q11-11 4.5-25.5T724-600q-4.789 0-8.895 2-4.105 2-8.105 6l-77 82v-65q0-10.833-7.116-17.917-7.117-7.083-18-7.083Q594-600 587-592.917q-7 7.084-7 17.917v190q0 10.833 7.116 17.917 7.117 7.083 18 7.083Q616-360 623-367.083q7-7.084 7-17.917v-65ZM180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-60h600v-600H180v600Zm0-600v600-600Z"
		/>
	</svg>
);
