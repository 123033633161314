import { ReactNode } from 'react';

export const symbol_euro: ReactNode = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
		<path
			fill="currentColor"
			d="M600-120q-115 0-212.5-67.5T255-370H150q-13 0-21.5-8.5T120-400q0-13 8.5-21.5T150-430h93q-4-28-3.5-50t3.5-50h-93q-13 0-21.5-8.5T120-560q0-13 8.5-21.5T150-590h105q35-115 132.5-182.5T600-840q60 0 114.5 18.5T816-768q10 8 11 20t-8 21q-9 9-22 9.5t-24-7.5q-38-27-82-41t-91-14q-94 0-171.5 52.5T319-590h251q13 0 21.5 8.5T600-560q0 13-8.5 21.5T570-530H304q-5 27-5 50t5 50h266q13 0 21.5 8.5T600-400q0 13-8.5 21.5T570-370H319q32 85 109.5 137.5T600-180q45 0 89.5-13.5T773-235q11-8 24-7.5t22 9.5q9 9 8 21t-11 20q-43 32-99 52t-117 20Z"
		/>
	</svg>
);
